import React from 'react';
import PropTypes from 'prop-types';

import reviewIcon from './review.svg';
import reviewFilledIcon from './review-filled.svg';

const Stars = ({ review }) => (
  <div>
    {[0, 1, 2, 3, 4].map((val, index) => (
      <svg
        key={val}
        className="review__icon"
        viewBox={review.stars > index ? reviewFilledIcon.viewBox : reviewIcon.viewBox}
      >
        <use xlinkHref={`#${review.stars > index ? reviewFilledIcon.id : reviewIcon.id}`} />
      </svg>
    ))}
  </div>
);

Stars.propTypes = {
  review: PropTypes.object.isRequired
};

export default Stars;
