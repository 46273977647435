import Box from '@mui/system/Box';
import { Text, useStacklineTheme } from '@stackline/ui';
import React, { useState } from 'react';
import { NewSettingsLayoutProps } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/NewSettingsLayout';

import ProfileSecurityLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Profile/ProfileSecurityLayout';
import ProfileSummaryLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Profile/ProfileSummaryLayout';
import { GenericNavigationTabs } from 'src/components/BeaconRedesignComponents/SubtabNav/SubtabNavigation';
import { useHistory } from 'src/utils/Hooks';

enum ProfileTab {
  Profile = 'profile',
  Security = 'security'
}

const ProfileDisplayName = {
  profile: 'Summary',
  security: 'Security'
};

interface ProfileNavigationTabsProps {
  tab: ProfileTab;
  setTab: (tab: ProfileTab) => void;
}

const ProfileNavigationTabs = ({ tab, setTab }: ProfileNavigationTabsProps) => {
  const history = useHistory();
  return (
    <GenericNavigationTabs
      tab={tab}
      tabs={[
        {
          label: 'Summary',
          onClick: () => {
            setTab(ProfileTab.Profile);
            history.push('/account/profile');
          },
          value: ProfileTab.Profile
        },
        {
          label: 'Security',
          onClick: () => {
            setTab(ProfileTab.Security);
            history.push('/account/security');
          },
          value: ProfileTab.Security
        }
      ]}
    />
  );
};

const ProfileContainerLayout = (props: NewSettingsLayoutProps) => {
  const {
    user,
    handleProfileChange,
    onSubmit,
    handlePhoneNumberChange,
    handleNewPasswordChange,
    handlePasswordConfirmationChange,
    profile
  } = props;

  const [tab, setTab] = useState(ProfileTab.Profile);
  const theme = useStacklineTheme();
  return (
    <Box width="100%">
      <Text variant="h2">{ProfileDisplayName[tab]}</Text>

      <Box marginBottom={theme.spacing.lg}>
        <ProfileNavigationTabs tab={tab} setTab={setTab} />
      </Box>
      {tab === ProfileTab.Profile ? (
        <ProfileSummaryLayout
          user={user}
          handleProfileChange={handleProfileChange}
          onSubmit={onSubmit}
          handlePhoneNumberChange={handlePhoneNumberChange}
          profile={profile}
        />
      ) : (
        <ProfileSecurityLayout
          handleNewPasswordChange={handleNewPasswordChange}
          handlePasswordConfirmationChange={handlePasswordConfirmationChange}
          onSubmit={onSubmit}
        />
      )}
    </Box>
  );
};

export default ProfileContainerLayout;
