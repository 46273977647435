import { AD_STATUS_DERIVED } from 'sl-ad-campaign-manager-data-model';

const RECEIVE_AD_PORTFOLIO_STATES = 'src/adCampaignStates/RECEIVE_AD_PORTFOLIO_STATES' as const;
const CLEAR_AD_PORTFOLIO_STATES = 'src/adCampaignStates/CLEAR_AD_PORTFOLIO_STATES' as const;

export interface AdPortfolioState {
  id: AD_STATUS_DERIVED;
  portfolioState: AD_STATUS_DERIVED;
  name: string;
  displayName: string;
  type: 'adPortfolioState';
}

export default {
  RECEIVE_AD_PORTFOLIO_STATES,
  CLEAR_AD_PORTFOLIO_STATES
};
