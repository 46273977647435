import { useMemo } from 'react';
import { SearchBarConditionsForAdvancedSearchComputer } from 'src/components/AdManager/Search/GridDataFetchers/SearchBarConditionsForAdvancedSearchComputer';
import { SearchGridConditionsAndFiltersGenerator } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConditionsAndFiltersGenerator';
import useFetchEntityMetricsArgs from './useFetchEntityMetricsArgs';
import { Location } from 'history';
import { Widget } from 'src/types/application/widgetTypes';
import { AggregationField } from 'src/types/application/types';
import useTimePeriods from './useTimePeriods';
import useFormData from './useFormData';
import { useAppSelector } from 'src/utils/Hooks';
import { DataForGroupByField } from 'src/components/AdManager/Search/Models/DataForGroupByField';
import { SearchGridConstants } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConstants';
import { PortfoliosDataFetcher } from 'src/components/AdManager/Search/GridDataFetchers/Portfolios/PortfoliosDataFetcher';
import { useDispatch } from 'react-redux';

interface UseSearchBarConditionsArgs {
  location: Location;
  widget: Widget;
  groupByField: AggregationField;
}
const useSearchBarConditions = ({ location, widget, groupByField }: UseSearchBarConditionsArgs) => {
  const { app, entity: mainEntity, retailer } = useFetchEntityMetricsArgs();
  const { mainTimePeriod, comparisonTimePeriod } = useTimePeriods();
  const formData = useFormData();
  const adCampaigns = useAppSelector((state) => state.adCampaigns);
  const dispatch = useDispatch();

  return useMemo(() => {
    const {
      searchSideBarConditions,
      aggregationFilters,
      indexName,
      entityConditions,
      aggregationFields,
      aggregationFieldsForResultCount,
      comparisonTimePeriodRangeFilters,
      customConditionTermFilters,
      customTermFilters,
      mainTimePeriodRangeFilters
    } = SearchGridConditionsAndFiltersGenerator.generateSearchConditionsAndFilters(
      { location },
      app,
      retailer,
      widget,
      mainEntity,
      groupByField,
      formData,
      mainTimePeriod,
      comparisonTimePeriod,
      '', // TODO handle SA action
      adCampaigns
    );

    const dataForGroupByField = new DataForGroupByField();
    if (groupByField.name === SearchGridConstants.GROUP_BY_PORTFOLIO_ID) {
      const portfoliosDataFetcher = new PortfoliosDataFetcher(dispatch, undefined);
      const gridIntermediateData = portfoliosDataFetcher.fetchPortfolioDataThatMatchMetadataFilters(
        searchSideBarConditions,
        { location }
      );
      dataForGroupByField.portfolioGridIntermediateData = gridIntermediateData;
    }

    const searchBarConditionsForASRComputer = new SearchBarConditionsForAdvancedSearchComputer();
    const searchBarConditionsForAdvancedSearch =
      searchBarConditionsForASRComputer.getSearchSideBarConditionsForAdvancedSearchRequest(
        searchSideBarConditions,
        groupByField,
        dataForGroupByField
      );

    return {
      searchSideBarConditions,
      searchBarConditionsForAdvancedSearch,
      aggregationFilters,
      indexName,
      entityConditions,
      aggregationFields,
      aggregationFieldsForResultCount,
      comparisonTimePeriodRangeFilters,
      customConditionTermFilters,
      customTermFilters,
      mainTimePeriodRangeFilters
    };
    // widget and groupByField cause problems in this array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adCampaigns, app, comparisonTimePeriod, dispatch, formData, location, mainEntity, mainTimePeriod, retailer]);
};

export default useSearchBarConditions;
