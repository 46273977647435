import React, { useMemo } from 'react';
import DonutChartV2 from 'src/components/BeaconRedesignComponents/DonutChart/DonutChartV2';
import DonutChartLoading from 'src/components/Charts/Donut/DonutChartLoading';
import { useMetricByWeekId } from 'src/serverProxy/useMetricByWeekId';
import { useStacklineTheme } from '@stackline/ui';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';
import { getVolumeStringParts } from 'src/utils/stringFormatting';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

/**
 * Displays the donut chart and spline chart for the chargeback metrics page
 */
const ChargebackMetrics = () => {
  const theme = useStacklineTheme();
  const formatMetric = useMetricFormatter();

  /** ********* Donut chart metrics ********* */
  const { primaryTotal: prepCharges, isLoading: prepChargesLoading } = useMetricByWeekId({
    indexName: BEACON_INDEX_NAMES.CHARGEBACKS,
    fieldName: 'prepCharges'
  });
  const { primaryTotal: logisticCharges, isLoading: logisticChargesLoading } = useMetricByWeekId({
    indexName: BEACON_INDEX_NAMES.CHARGEBACKS,
    fieldName: 'logisticCharges'
  });
  const { primaryTotal: labelingCharges, isLoading: isLabelingChargesLoading } = useMetricByWeekId({
    indexName: BEACON_INDEX_NAMES.CHARGEBACKS,
    fieldName: 'labelingCharges'
  });

  const { primaryTotal: totalFinancialCharges, isLoading: isTotalFinancialChargesLoading } = useMetricByWeekId({
    indexName: BEACON_INDEX_NAMES.CHARGEBACKS,
    fieldName: 'financialCharge'
  });

  const donutLoading = useMemo(() => {
    return prepChargesLoading || logisticChargesLoading || isLabelingChargesLoading || isTotalFinancialChargesLoading;
  }, [isLabelingChargesLoading, logisticChargesLoading, prepChargesLoading, isTotalFinancialChargesLoading]);

  const renderDonut = () => {
    if (donutLoading) {
      return <DonutChartLoading />;
    }

    const formattedCharges = `${formatMetric(totalFinancialCharges, METRICTYPE.MONEY, {
      showFullValue: false,
      decimalPlaces: 1
    })}`;

    const { value, label, currencySign } = getVolumeStringParts(formattedCharges);

    return (
      <DonutChartV2
        title={
          <Flex alignItems="center" gap="sm">
            <Text variant="h4">{currencySign}</Text>
            <Text
              sx={{
                fontSize: '48px',
                fontWeight: 'bold'
              }}
            >
              {value}
            </Text>
            <Text variant="h4">{label}</Text>
          </Flex>
        }
        getData={[
          {
            fieldId: 'prepCharges',
            name: 'Prep Charges',
            y: prepCharges
          },
          {
            fieldId: 'logisticCharges',
            name: 'Logistic Charges',
            y: logisticCharges
          },
          {
            fieldId: 'labelingCharges',
            name: 'Labeling Charges',
            y: labelingCharges
          }
        ]}
      />
    );
  };

  return (
    <div style={{ marginTop: theme.spacing.lg }}>
      <div>
        <CommonSummaryInfoSubtitle title="Chargeback Metrics" />
        <Flex marginTop={theme.spacing.xl} alignItems="center" justifyContent="space-between">
          <div>{renderDonut()}</div>
          <div style={{ width: '624px' }}>
            <BeaconComparableSplineChart
              showTitleSummary={false}
              indexName="chargebacks"
              fieldName="financialCharge"
              splineChartLoadingProps={{
                width: 543
              }}
              chartOptionsOverride={{
                chart: {
                  height: 230
                }
              }}
            />
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default ChargebackMetrics;
