// ------------------------------------
// Types
// ------------------------------------
const REQUEST_ENTITY = 'src/entityService/REQUEST_ENTITY';
const RECEIVE_ENTITY = 'src/entityService/RECEIVE_ENTITY';
const CLEAR_ENTITY = 'src/entityService/CLEAR_ENTITY';
const REMOVE_ENTITY = 'src/entityService/REMOVE_ENTITY';
const UPDATE_SBA_PROFILE = 'src/entityService/UPDATE_SBA_PROFILE';

export default {
  RECEIVE_ENTITY,
  REQUEST_ENTITY,
  REMOVE_ENTITY,
  CLEAR_ENTITY,
  UPDATE_SBA_PROFILE
};
