import React, { Fragment, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';

import GenericDialog from '../../common/Dialog/GenericDialog';
import KeywordInputForm from './KeywordForm';

const KeywordInput = (
  assignKeywords,
  titleKeywordSuggestions,
  bulletsKeywordSuggestions,
  descriptionKeywordSuggestions
) => {
  const [isOpen, setIsOpen] = useState(true);

  const keywordSubmit = ({
    titleKeywords,
    bulletKeywords,
    descriptionKeywords,
    titleCount,
    bulletCount,
    descriptionCount,
    contentVoice
  }) => {
    setIsOpen(false);
    assignKeywords(
      titleKeywords,
      descriptionKeywords,
      bulletKeywords,
      titleCount,
      bulletCount,
      descriptionCount,
      contentVoice
    );
  };

  return (
    <Fragment>
      <Tooltip
        title="Select Keywords"
        placement="top"
        onClick={() => setIsOpen(!isOpen)}
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: '0.84px',
          textAlign: 'left',
          textTransform: 'uppercase',
          cursor: 'pointer'
        }}
      >
        {/* we can control the filter icon display in the omni according to the visibility */}

        <div
          style={{
            height: '19px',
            margin: '0 0 10 0',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.84px',
            textAlign: 'right',
            color: '#052849'
          }}
        >
          Keywords Input
        </div>
      </Tooltip>
      <GenericDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Keywords Input"
        textContent="Please enter the keywords to boost the content rewrite"
      >
        <KeywordInputForm
          onSubmit={keywordSubmit}
          titleKeywordSuggestion={titleKeywordSuggestions}
          bulletsKeywordSuggestion={bulletsKeywordSuggestions}
          descriptionKeywordSuggestion={descriptionKeywordSuggestions}
        />
      </GenericDialog>
    </Fragment>
  );
};

export default KeywordInput;
