import { AppName } from 'sl-api-connector/types';

export function parseCategories(appName, categories, subCategories, retailer) {
  categories = categories
    .map((category) => ({
      ...category,
      id: category.categoryId,
      type: 'category',
      displayName: category.name,
      childEntities: subCategories[category.categoryId] || [],
      conditions: {
        termFilters: [
          {
            fieldName: 'categoryId',
            values: [category.categoryId]
          }
        ]
      },
      value: 0
    }))
    .sort((a, b) => b.relevancyScore - a.relevancyScore || a.name.localeCompare(b.name));

  const retailerMap = {
    0: categories
  };

  categories.forEach((cat) => {
    const { retailerIds } = cat;
    retailerIds.forEach((id) => {
      if (retailerMap[id]) {
        retailerMap[id].push(cat);
      } else {
        retailerMap[id] = [cat];
      }
    });
  });

  Object.keys(retailerMap).forEach((retailerId) => {
    if ([AppName.Beacon, AppName.Atlas].includes(appName)) {
      retailerMap[retailerId].unshift({
        categoryName: 'All Categories',
        categoryId: 0,
        id: 0,
        childEntities: [],
        retailerIds: retailer.availableRetailers.map((item) => parseInt(item.id, 10)).sort((a, b) => a - b),
        displayName: `All Categories (${retailerMap[retailerId].length})`,
        conditions: {
          termFilters: [
            {
              fieldName: 'categoryId',
              condition: 'should',
              values: retailerMap[retailerId].map((val) => val.categoryId)
            }
          ]
        },
        value: 0,
        type: 'category'
      });
    }
  });

  return retailerMap;
}

export function filterCategoriesByRetailerId(categoriesByRetailerId, retailer) {
  //  parse all unfiltered categories by retailer id
  const categoriesForRetailer = categoriesByRetailerId[+retailer.id];

  if (categoriesForRetailer) {
    return categoriesForRetailer;
  }

  return [];
}
