import React from 'react';
import Box from '@mui/material/Box';
import { useStacklineTheme } from '@stackline/ui';
import { BEACON_PRO_PAGE_MARGIN_BOTTOM } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { SxProps } from '@mui/system';

interface BeaconPageContainerProps {
  sx?: SxProps;
  children: React.ReactNode;
}
export default function BeaconPageContainer({ sx = {}, children }: BeaconPageContainerProps) {
  const theme = useStacklineTheme();

  return (
    <Box marginTop={theme.spacing.lg} marginBottom={BEACON_PRO_PAGE_MARGIN_BOTTOM} sx={{ ...sx }}>
      {children}
    </Box>
  );
}
