import Box from '@mui/material/Box';
import { useStacklineTheme } from '@stackline/ui';
import React, { useMemo } from 'react';
import KeyMetricCards, {
  KeyMetricCardDefinition
} from 'src/components/BeaconRedesignComponents/KeyMetricCards/KeyMetricCards';
import { KEY_METRICS_CONTAINER_ADJUSTED_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { useHistory } from 'src/utils/Hooks';
import { updateUrlQueryParams } from 'src/utils/app';

const titleOverrides = {
  advertising: {
    clicks: 'Paid Traffic'
  },
  sales: {
    purchaseRate: 'Conversion'
  },
  reviews: {
    stars: 'Rating'
  },
  buybox: {
    winPercentage: 'Buy Box'
  },
  traffic: {
    stacklineSku: 'Products'
  }
};

/**
 * Shows all the key metric summary cards for the home page
 */
const SummaryKeyMetricCards = () => {
  const theme = useStacklineTheme();
  const history = useHistory();

  const cardDefinitions: KeyMetricCardDefinition[][] = useMemo(
    () =>
      [
        [
          ['aggregated-traffic', 'totalClicks', 'traffic', 'totalClicks'],
          ['aggregated-traffic', 'organicClicks', 'traffic', 'organicClicks'],
          ['advertising', 'clicks', 'traffic', 'adClicks'],
          ['advertising', 'spend', 'traffic', 'adClicks'],
          ['advertising', 'sales', 'traffic', 'adClicks'],
          ['advertising', 'returnOnAdSpend', 'traffic', 'adClicks']
        ],
        [
          ['sales', 'purchaseRate', 'conversion', 'purchaseRate'],
          ['sales', 'retailPrice', 'sales', 'retailPrice'],
          ['new-content-metrics', 'contentScore', 'content', 'contentScore'],
          ['sales', 'instockRate', 'sales', 'inventory'],
          ['reviews', 'stars', 'reviews', 'reviewTrends'],
          ['buybox', 'winPercentage', 'buybox', 'winPercentage']
        ],
        [
          ['sales', 'retailSales', 'sales', 'retailSales'],
          ['sales', 'unitsSold', 'sales', 'unitsSold'],
          ['sales', 'wholesaleSales', 'sales', 'retailSales'],
          ['sales', 'retailerGrossMargin', 'sales', 'retailerGrossMargin'],
          ['sales', 'brandGrossMargin', 'sales', 'retailerGrossMargin'],
          ['sales', 'stacklineSku', 'sales', 'unitsSold']
        ]
      ].map((columnDefinitions) =>
        columnDefinitions.map(([indexName, fieldName, tab, subtab]) => ({
          indexName,
          fieldName,
          title: titleOverrides[indexName] ? titleOverrides[indexName][fieldName] : undefined,
          onClick: () => history.push(updateUrlQueryParams({ tab, subtab })),
          primaryAggregationBuilder:
            indexName === 'sales' && fieldName === 'stacklineSku'
              ? (aggregationBuilder) => {
                  return aggregationBuilder.clearConditionRangeFilters().addConditionRangeFilter('retailSales', 1);
                }
              : undefined,
          secondaryAggregationBuilder:
            indexName === 'sales' && fieldName === 'stacklineSku'
              ? (aggregationBuilder) => {
                  return aggregationBuilder.clearConditionRangeFilters().addConditionRangeFilter('retailSales', 1);
                }
              : undefined
        }))
      ),
    [history]
  );

  return (
    <Box
      marginTop={theme.spacing.lg}
      marginBottom={theme.spacing.lg}
      sx={{
        transform: 'translateX(-16px)',
        width: KEY_METRICS_CONTAINER_ADJUSTED_WIDTH
      }}
    >
      <KeyMetricCards cardDefinitionsByColumn={cardDefinitions} />
    </Box>
  );
};

export default SummaryKeyMetricCards;
