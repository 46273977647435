import React, { useState, useEffect } from 'react';
import Select from 'src/components/common/Form/Select';
import { OmniSelectedField } from 'src/components/Omni/OmniExportService/OmniExportGroupBy';
import { ColoredCheckbox } from 'src/components/Export/ExportDialog';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

interface OmniShareOfShelfExportGroupProps {
  classes: { [key: string]: any };
  setShareOfShelfSelection: (opt: OmniSelectedField) => void;
  entityType: string;
}

const OmniShareOfShelfExportGroup: React.FC<OmniShareOfShelfExportGroupProps> = ({
  setShareOfShelfSelection,
  classes
}) => {
  const firstOpt = [
    {
      displayName: 'Product',
      id: 'stacklineSku.keyword',
      value: 'stacklineSku.keyword'
    },
    {
      displayName: 'Brand',
      id: 'brandId',
      value: 'brandId'
    }
  ];
  const [firstGroupByValue, setFirstGroupByValue] = useState(firstOpt[0].value);
  const [aggByWeek, setSggByWeek] = useState(false);
  const [includeComparison, setIncludeComparison] = useState(false);

  const handleCheckSeparateWeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSggByWeek(e.target.checked);
  };

  const handleCheckComparison = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeComparison(e.target.checked);
  };

  const handleGroupByChange = (firstValue: string) => {
    setFirstGroupByValue(firstValue);
  };

  useEffect(() => {
    setShareOfShelfSelection({ firstGroupByValue, aggByWeek, includeComparison });
  }, [firstGroupByValue, aggByWeek, includeComparison]);

  return (
    <>
      <div className="setting_row">
        <div className="label" style={{ width: 100 }}>
          Group by:
        </div>
        <div>
          <Select
            items={firstOpt}
            value={firstGroupByValue}
            onChange={(evt: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
              handleGroupByChange(evt.target.value as string)
            }
            style={{ width: 300 }}
            classes={classes}
          />
        </div>
      </div>
      <Flex flexDirection="row">
        <div className="label" style={{ width: 100, marginTop: 7 }}>
          Customize:
        </div>
        <div>
          <Flex flexDirection="row">
            <ColoredCheckbox
              checked={aggByWeek}
              onChange={handleCheckSeparateWeek}
              style={{ marginLeft: -9 }}
              disabled={includeComparison}
              id="weekId-checkbox"
            />
            <div
              style={{
                paddingTop: 9
              }}
            >
              Separate data by week
            </div>
          </Flex>
          <Flex flexDirection="row">
            <ColoredCheckbox
              checked={includeComparison}
              onChange={handleCheckComparison}
              disabled={aggByWeek}
              style={{ marginLeft: -9 }}
              id="comparison-checkbox"
            />
            <div
              style={{
                paddingTop: 9
              }}
            >
              Include comparison time period
            </div>
          </Flex>
        </div>
      </Flex>
    </>
  );
};

export default OmniShareOfShelfExportGroup;
