/* eslint-disable react/prop-types */
import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import _cloneDeep from 'lodash/cloneDeep';
import { AutoSizer, List as VirtualizedList } from 'react-virtualized';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';
import _prop from 'lodash/property';
import _sortBy from 'lodash/sortBy';
import invariant from 'invariant';
import moment from 'moment';
import queryString from 'qs';
import { Option } from 'funfix-core';
import { RangeFilter, SavedSearch, BusinessUnit, AppName } from 'sl-api-connector/types';
import { mergeConditions } from 'sl-api-connector/search/conditions';

import colors from 'src/utils/colors';
import { extractQueryConditions } from 'src/utils/segments';
import { store } from 'src/main';
import { buildMetricValue } from 'src/utils/metrics';
import { EditIcon, ChevronIcon, TrashcanIcon } from 'src/components/SvgIcons';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import DeleteSavedSearchDialog from 'src/routes/HomePage/SummaryData/DeleteSavedSearchDialog';
import { getUnassignedSegments, getUnassignedBusinessUnits } from 'src/store/modules/segments/selectors';
import BusinessUnitBuilderDialog, {
  FormState as BUBFormState
} from 'src/components/BusinessUnitBuilder/BusinessUnitBuilderDialog';
import { buildAggregations } from 'src/components/AdManager/Search';
import './BusinessUnitListing.scss';
import { buildBUEntityConditions } from 'src/components/EntityPage/Filters/businessUnitFilters';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { updateConditionsToCurrentRetailer } from 'src/utils/conditions';
import * as genericFormActions from 'src/store/modules/genericForm/actions';
import { addPersistentQueryParams } from 'src/utils/browser';
import { prop } from 'src/utils/fp';
import { mkActionCellFormatter } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import { withPush } from 'src/utils/hoc';
import ReduxStore from 'src/types/store/reduxStore';
import { PushFn, AggregationField } from 'src/types/application/types';
import { panic, warn, error } from 'src/utils/mixpanel';
import { curUserIdSelector } from 'src/store/modules/user/selectors';
import isEmpty from 'lodash/isEmpty';
import { entitySearchServiceOperations } from 'src/store/modules/entitySearchService';
import Loading from 'src/components/common/Loading';
import { CreateNewSegmentIcon } from '../SubHeader/CreateNewSavedSearchButton';

const styles: { [key: string]: React.CSSProperties } = {
  dropdownIcon: {
    stroke: colors.darkBlue,
    height: 24,
    width: 24,
    cursor: 'pointer'
  },
  icon: {
    width: 28,
    height: 28,
    cursor: 'pointer',
    margin: 4
  }
} as const;

const typeNameMapping = { campaignId: 'Campaign', stacklineSku: 'Product', targetingText: 'Target' };
const metricFieldNamesByAppName: { [Key: string]: string[] } = {
  [AppName.Advertising]: ['clicks', 'spend'],
  [AppName.Atlas]: ['retailSales', 'unitsSold', 'retailPrice'],
  [AppName.Beacon]: ['retailSales', 'unitsSold', 'wholesaleSales', 'retailPrice', 'instockRate', 'unitsOnHand']
};

const SAVED_SEARCH_SALES_DATA_KEY_BASE = 'savedSearchRetailSales' as const;
const buildSavedSearchSalesDataKey = (id: string) => `${SAVED_SEARCH_SALES_DATA_KEY_BASE}-${id}`;

const fetchSalesForSavedSearch = (
  { app, retailer, mainTimePeriod }: Pick<ReduxStore, 'app' | 'retailer' | 'mainTimePeriod'>,
  mainEntity: NonNullable<ReduxStore['entityService']['mainEntity']>,
  segments: ReduxStore['segments'] & { savedSearchesById: NonNullable<ReduxStore['segments']['savedSearchesById']> },
  savedSearchId: string,
  metricFields: AggregationField[],
  fetchEntityMetrics: any
): Promise<any> => {
  const savedSearchOpt = segments.savedSearchesById.get(savedSearchId);
  invariant(savedSearchOpt, `Tried to fetch sales data for saved search id ${savedSearchId} but it wasn't found`);
  const savedSearch = savedSearchOpt!;

  const savedSearchConditions =
    savedSearch.type === 'businessunit'
      ? buildBUEntityConditions(segments.savedSearchesById, savedSearch)
      : savedSearch.conditions;

  let mainTimePeriodRangeFilter: RangeFilter = {
    fieldName: 'weekId',
    minValue: mainTimePeriod.startWeek,
    maxValue: mainTimePeriod.endWeek
  };

  if (app.name === AppName.Advertising) {
    mainTimePeriodRangeFilter = {
      fieldName: 'dayId',
      minValue: mainTimePeriod.startDayId,
      maxValue: mainTimePeriod.endDayId
    };
  }

  const retailerIdTermFilter = { fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] };

  // Aggregate all metrics into a single data point
  const groupByField = INDEX_FIELDS.getField(app.name, 'sales', 'retailerId');

  const [{ aggregations: aggregationFields }] = buildAggregations(metricFields);

  const dataKey = buildSavedSearchSalesDataKey(savedSearchId);

  let indexName = 'sales';
  if (app.name === AppName.Advertising) {
    if (_get(savedSearchOpt, ['segment', 'segmentType']) === 'campaignId') {
      indexName = 'adCampaignDailyMetrics';
    } else {
      indexName = 'adCampaignAdGroupProductTargetDailyMetrics';
    }
  }
  return fetchEntityMetrics(
    dataKey,
    {
      entity: mainEntity,
      retailer,
      app,
      indexName
    },
    [
      {
        doAggregation: true,
        aggregations: [
          {
            aggregationFields,
            conditions: {
              termFilters: [retailerIdTermFilter],
              rangeFilters: [mainTimePeriodRangeFilter]
            },
            groupByFieldName: groupByField.name
          }
        ],
        conditions: mergeConditions(updateConditionsToCurrentRetailer(savedSearchConditions, retailer), {
          rangeFilters: [mainTimePeriodRangeFilter],
          termFilters: [retailerIdTermFilter]
        }),
        pageSize: 10000,
        processDocuments: true
      }
    ]
  );
};

const mapBusinessUnitFolderItemStateToProps = (
  {
    segments: { savedSearchesById },
    entityService: { mainEntity },
    entitySearchService,
    app,
    retailer,
    segments,
    mainTimePeriod
  }: ReduxStore,
  {
    savedSearchId,
    isHeader = false,
    metricFields
  }: { savedSearchId?: string; isHeader?: boolean; metricFields: AggregationField[] }
) => {
  const metricFieldValuesFormatted: any = {};

  metricFields.forEach((metricField: AggregationField) => {
    metricFieldValuesFormatted[metricField.name] = isHeader
      ? metricField.displayName
      : Option.of(savedSearchId)
          .map(buildSavedSearchSalesDataKey)
          .flatMap((dataKey) =>
            Option.of(
              _get(entitySearchService, [dataKey, `${metricField.name}_by_retailerId`, 'data']) as {
                value: number;
              }[]
            ).flatMap((data) => Option.of(data[0]).map(prop('value')))
          )
          .map((metricValue) => {
            const {
              prefix = '',
              value = '',
              suffix = ''
            } = buildMetricValue(
              metricValue || 0,
              metricField.metricType!,
              retailer.currencySymbol,
              true,
              metricField.dataType!,
              retailer.locale
            );
            return `${prefix}${value}${suffix}`;
          })
          .orNull();
  });

  return {
    savedSearch: savedSearchesById && savedSearchId ? savedSearchesById.get(savedSearchId) : null,
    metricFields,
    metricFieldValuesFormatted,
    mainEntity,
    entitySearchService,
    app,
    retailer,
    segments,
    mainTimePeriod
  };
};

const mapSavedSearchTitleStateToProps = (state: ReduxStore) => _pick(state, ['retailer', 'mainTimePeriod']);

const SavedSearchTitleInner: React.FC<
  { savedSearch: SavedSearch } & ReturnType<typeof mapSavedSearchTitleStateToProps>
> = ({ savedSearch, retailer, mainTimePeriod }) => {
  if (['segment', 'businessunit', 'searchtermlist'].includes(savedSearch.type)) {
    const to = `/${savedSearch.type}/${savedSearch.id}?${addPersistentQueryParams(retailer, mainTimePeriod)}`;
    return <Link to={to}>{savedSearch.displayName}</Link>;
  } else {
    return panic(`Received invalid saved search of type ${(savedSearch as any).type} in \`SavedSearchTitle\``);
  }
};

const SavedSearchTitle = connect(mapSavedSearchTitleStateToProps)(SavedSearchTitleInner);

const BUFolderItemInner: React.FC<
  {
    app: any;
    i: number;
    savedSearchId?: string;
    isHeader?: boolean;
    style?: React.CSSProperties;
    setEditingBusinessUnitId: (buId: string) => void;
    setDeletingSavedSearch: (buId: string) => void;
    toggleSelectedType?: Function;
    selectAllType?: Function;
    push: PushFn;
    sortField: string;
    setSortField: (s: string) => void;
    sortDirection: 'desc' | 'asc';
    setSortDirection: (s: 'desc' | 'asc') => void;
    sortableFields: string[];
  } & ReturnType<typeof mapBusinessUnitFolderItemStateToProps>
> = ({
  app,
  i,
  savedSearch,
  isHeader = false,
  style,
  metricFieldValuesFormatted,
  retailer,
  mainTimePeriod,
  setEditingBusinessUnitId,
  setDeletingSavedSearch,
  metricFields,
  push,
  sortField,
  setSortField,
  sortDirection,
  setSortDirection,
  sortableFields,
  toggleSelectedType,
  selectAllType,
  typeDropdown,
  notAllTypeSelect,
  toggleSelectedUser,
  selectAllUser,
  userDropdown,
  notAllUserSelect
}) => {
  const lastMainTimePeriodId = useRef<string | null>(null);
  const [typeAnchorEl, setTypeAnchorEl] = useState(null);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  lastMainTimePeriodId.current = mainTimePeriod.id;

  if (!savedSearch && !isHeader) {
    return null;
  }

  const buttonDef = [
    {
      onClick: () => {
        if (!savedSearch) {
          return;
        }

        if (savedSearch.type === 'businessunit') {
          setEditingBusinessUnitId(savedSearch!.id);
        } else if (savedSearch.type === 'segment') {
          const conditions = extractQueryConditions(savedSearch.segment, true, true);
          const groupByField =
            app.name === AppName.Advertising ? `&groupByField=${savedSearch.segment.segmentType}` : '';
          push(
            `/search?${addPersistentQueryParams(retailer, mainTimePeriod)}&doAggregation=true&entityType=${
              savedSearch.entityType
            }${groupByField}&${queryString.stringify(conditions)}
            &dn=${savedSearch.displayName}&id=${savedSearch.id}&segmentStage=segmentEdit`
          );
        }
      },
      label: 'Edit'
    },
    {
      onClick: () => {
        if (!savedSearch) {
          return;
        }
        setDeletingSavedSearch(savedSearch.id);
      },
      label: 'Delete'
    }
  ];
  // if (app.name === AppName.Advertising) {
  //   buttonDef = [
  //     {
  //       onClick: () => {
  //         if (!savedSearch) {
  //           return;
  //         }
  //         setDeletingSavedSearch(savedSearch.id);
  //       },
  //       label: 'Delete'
  //     }
  //   ]
  // }

  const ActionButton = mkActionCellFormatter(
    buttonDef,
    {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      paddingTop: 4
    },
    null
  );

  const lastUpdatedTime = Option.of(_get(savedSearch, 'lastUpdatedTime'))
    .map((ts) => moment(ts).format('YYYY-MM-DD'))
    .getOrElse('-');
  const owner = Option.of(_get(savedSearch, ['segment', 'owner']))
    .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
    .getOrElse('-');

  const handleClickType = (event) => {
    setTypeAnchorEl(event.currentTarget);
  };

  const handleCloseType = () => {
    setTypeAnchorEl(null);
  };

  const handleClickUser = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleCloseUser = () => {
    setUserAnchorEl(null);
  };

  const segmentType = _get(savedSearch, ['segment', 'segmentType']);
  const canEdit = _get(savedSearch, ['segment', 'canEdit']);
  const segmentTypeDisplay = segmentType ? typeNameMapping[segmentType] : '';
  return (
    <div className={`business-unit-folder-item${isHeader ? ' business-unit-folder-items-header' : ''}`} style={style}>
      <div className="business-unit-folder-item-cell" style={{ flex: 4 }}>
        {isHeader ? 'Name' : <SavedSearchTitle savedSearch={savedSearch!} />}
      </div>
      {metricFields.map((metricField: AggregationField) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            key={metricField.name}
            className="business-unit-folder-item-cell"
            style={{ flex: 1.5 }}
            role={isHeader ? 'button' : 'row'}
            onClick={() => {
              if (isHeader && sortableFields.includes(metricField.name)) {
                if (sortField === metricField.name) {
                  // switch the direction if clicking on the key we are already sorting on
                  setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                } else {
                  setSortField(metricField.name);
                }
              }
            }}
          >
            {isHeader
              ? metricField.displayName
              : isEmpty(metricFieldValuesFormatted[metricField.name])
              ? '-'
              : metricFieldValuesFormatted[metricField.name]}
          </div>
        );
      })}
      {app.name === AppName.Advertising ? (
        <div className="business-unit-folder-item-cell" style={{ flex: 1.5 }}>
          {isHeader ? (
            <div>
              <div
                role="button"
                onClick={handleClickType}
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                Segment Type{' '}
                <ChevronIcon style={{ position: 'relative', width: 28, height: 28, stroke: colors.labelGrey }} />
              </div>
              <Popover
                open={Boolean(typeAnchorEl)}
                anchorEl={typeAnchorEl}
                onClose={handleCloseType}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <div className="platform_drowdown">
                  <MenuItem onClick={() => selectAllType()}>
                    <Checkbox color="primary" checked={!notAllTypeSelect} style={{ marginLeft: -9 }} />
                    Select All
                  </MenuItem>
                  {typeDropdown.map((item, idx) => {
                    return (
                      <MenuItem key={idx} onClick={() => toggleSelectedType(item.type)}>
                        <Checkbox color="primary" checked={item.selected} style={{ marginLeft: -9 }} />
                        {item.displayName}
                      </MenuItem>
                    );
                  })}
                </div>
              </Popover>
            </div>
          ) : (
            segmentTypeDisplay
          )}
        </div>
      ) : null}
      <div className="business-unit-folder-item-cell" style={{ flex: 1.5 }}>
        {isHeader ? 'Last Updated' : lastUpdatedTime}
      </div>
      <div className="business-unit-folder-item-cell" style={{ flex: 1.5 }}>
        {isHeader ? (
          <div>
            <div
              role="button"
              onClick={handleClickUser}
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              Owner <ChevronIcon style={{ position: 'relative', width: 28, height: 28, stroke: colors.labelGrey }} />
            </div>
            <Popover
              open={Boolean(userAnchorEl)}
              anchorEl={userAnchorEl}
              onClose={handleCloseUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
            >
              <div className="platform_drowdown">
                <MenuItem onClick={() => selectAllUser()}>
                  <Checkbox color="primary" checked={!notAllUserSelect} style={{ marginLeft: -9 }} />
                  Select All
                </MenuItem>
                {userDropdown.map((item, idx) => {
                  return (
                    <MenuItem key={idx} onClick={() => toggleSelectedUser(item.email)}>
                      <Checkbox color="primary" checked={item.selected} style={{ marginLeft: -9 }} />
                      {`${item.firstName} ${item.lastName}(${item.email})`}
                    </MenuItem>
                  );
                })}
              </div>
            </Popover>
          </div>
        ) : (
          owner
        )}
      </div>
      <div
        className="business-unit-folder-item-cell"
        style={{
          flex: 1.5,
          zIndex: 1000 - i,
          height: '100%'
          // paddingRight: isHeader ? 15 : undefined
        }}
      >
        {isHeader ? <>Action&nbsp;&nbsp;</> : canEdit ? <ActionButton data={{}} colDef={{}} /> : '-'}
      </div>
    </div>
  );
};

const BUFolderItem = withPush(connect(mapBusinessUnitFolderItemStateToProps)(BUFolderItemInner));

const mapBusinessUnitFolderStateToProps = (state: ReduxStore) =>
  _pick(state, ['retailer', 'mainTimePeriod', 'app', 'entityService', 'entitySearchService', 'segments']);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

const BusinessUnitFolderInner: React.FC<
  {
    businessUnit?: BusinessUnit;
    unassignedSegments?: string[];
    setDeletingSavedSearch: (savedSearchId: string) => void;
    setEditingBusinessUnitId: (buId: string) => void;
    i: number;
    fetchEntityMetrics: Function;
  } & ReturnType<typeof mapBusinessUnitFolderStateToProps>
> = ({
  businessUnit,
  unassignedSegments,
  setDeletingSavedSearch,
  setEditingBusinessUnitId,
  i,
  retailer,
  mainTimePeriod,
  app,
  entityService: { mainEntity },
  entitySearchService,
  segments,
  fetchEntityMetrics
}) => {
  invariant(
    businessUnit || unassignedSegments,
    'You must supply either a business unit of a list of unassigned segments'
  );
  const [isExpanded, setIsExpanded] = useState(i < 5);
  const sortableFields = ['retailSales', 'unitsSold', 'retailPrice'];

  const { displayName, id, children } = businessUnit || {
    displayName: 'Business Units & Segments',
    children: unassignedSegments!,
    id: null
  };

  const [typeDropdown, setTypeDropdown] = useState([]);
  const [userDropdown, setUserDropdown] = useState([]);
  const [sortedChildren, setSortedChildren] = useState(children);
  const [sortField, setSortField] = useState(sortableFields[0]);
  const [sortDirection, setSortDirection] = useState<'desc' | 'asc'>('desc');
  const [buBuilderDialogOpen, setBUBuilderDialogOpen] = useState(false);
  const tooManyChildrenToLoadSalesData = children.length > 100;

  const metricFields: AggregationField[] = metricFieldNamesByAppName[app.name].map((metricFieldName) => {
    const index = app.name === AppName.Advertising ? 'adCampaignAdGroupProductTargetDailyMetrics' : 'sales';
    return INDEX_FIELDS.getField(app.name, index, metricFieldName);
  });

  // children: saved search IDs
  const [didFetchData, setDidFetchData] = useState(false);

  useEffect(() => {
    const { savedSearchesById } = segments;
    if (!mainEntity || !savedSearchesById || tooManyChildrenToLoadSalesData) {
      console.warn('Unable to fetch sales data for the segments: ', {
        children,
        mainEntity,
        savedSearchesById
      });
      // consistent the return type
      return () => {};
    }

    const typeSets: any[] = [];
    const userSets: any[] = [];
    const promises: Promise<any>[] = [];
    children.forEach((savedSearchId: string) => {
      // need to build saved search now
      const savedSearch = savedSearchesById && savedSearchId ? savedSearchesById.get(savedSearchId) : null;

      if (savedSearch && _get(savedSearch, 'id')) {
        const type = _get(savedSearch, ['segment', 'segmentType']);
        const user = _get(savedSearch, ['segment', 'owner']);
        if (type && !typeSets.find((item) => item.type === type)) {
          typeSets.push({ type, displayName: typeNameMapping[type], selected: true });
        }

        if (user && !userSets.find((item) => item.email === user.email)) {
          userSets.push({ ...user, selected: true });
        }

        promises.push(
          fetchSalesForSavedSearch(
            { app, retailer, mainTimePeriod },
            mainEntity,
            { ...segments, savedSearchesById },
            savedSearch.id,
            metricFields,
            fetchEntityMetrics
          )
        );
      }
    });
    let isActive = true;
    Promise.all(promises)
      .then(() => {
        if (isActive) {
          setDidFetchData(true);
          setTypeDropdown(typeSets);
          setUserDropdown(userSets);
        }
      })
      .catch((err) => {
        console.error(err);
        if (isActive) {
          setDidFetchData(true);
          setTypeDropdown(typeSets);
          setUserDropdown(userSets);
        }
      });

    // When the component unmount, not to allow update state
    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segments.savedSearchesById]);

  useEffect(() => {
    if (didFetchData) {
      const keysWithValues = children.map((childId: string) => {
        const dataKey = buildSavedSearchSalesDataKey(childId);
        const value = _get(entitySearchService, [dataKey, `${sortField}_by_retailerId`, 'data', '0', 'value']);
        if (value) {
          return { childId, value };
        }

        return { childId, value: 0 };
      });

      const sorted = _sortBy(keysWithValues, 'value').map((k) => k.childId);

      if (sortDirection === 'desc') {
        sorted.reverse();
        setSortedChildren(sorted);
      } else {
        setSortedChildren(sorted);
      }
    }
  }, [sortField, sortDirection, entitySearchService, children, didFetchData]);

  const propsForSorting = {
    sortField,
    setSortField,
    sortDirection,
    setSortDirection,
    sortableFields
  };

  const notAllTypeSelect = typeDropdown.find((item) => !item.selected);
  const selectAllType = () => {
    const typeDropdownCopy = _cloneDeep(typeDropdown);
    const newStatus = !!notAllTypeSelect;
    typeDropdownCopy.forEach((item) => {
      item.selected = newStatus;
    });
    setTypeDropdown(typeDropdownCopy);
  };

  const toggleSelectedType = (type) => {
    const typeDropdownCopy = _cloneDeep(typeDropdown);
    typeDropdownCopy.forEach((item) => {
      if (item.type === type) {
        item.selected = !item.selected;
      }
    });
    setTypeDropdown(typeDropdownCopy);
  };

  const notAllUserSelect = userDropdown.find((item) => !item.selected);
  const selectAllUser = () => {
    const userDropdownCopy = _cloneDeep(userDropdown);
    const newStatus = !!notAllUserSelect;
    userDropdownCopy.forEach((item) => {
      item.selected = newStatus;
    });
    setUserDropdown(userDropdownCopy);
  };

  const toggleSelectedUser = (userEmail) => {
    const userDropdownCopy = _cloneDeep(userDropdown);
    userDropdownCopy.forEach((item) => {
      if (item.email === userEmail) {
        item.selected = !item.selected;
      }
    });
    setUserDropdown(userDropdownCopy);
  };

  const filteredChildren = sortedChildren.filter((item) => {
    const { savedSearchesById } = segments;
    const savedSearch = savedSearchesById ? savedSearchesById.get(item) : null;
    const type = typeDropdown.find((p) => p.type === _get(savedSearch, ['segment', 'segmentType']));
    const user = userDropdown.find((p) => p.email === _get(savedSearch, ['segment', 'owner', 'email']));
    if (app.name === AppName.Advertising) {
      return type && type.selected && user && user.selected;
    }
    return user && user.selected;
  });

  return (
    <div className="business-unit-folder">
      <div className="business-unit-folder-header">
        <div className="business-unit-folder-title">
          {id && app.name !== AppName.Advertising ? (
            <Link
              to={`/businessunit/${id}?${addPersistentQueryParams(
                retailer,
                mainTimePeriod.availableMainTimePeriods.find((tp) => tp.id === '4w') || mainTimePeriod
              )}`}
            >
              {displayName}
            </Link>
          ) : (
            displayName
          )}
          <ChevronIcon
            style={{ ...styles.dropdownIcon, transform: `rotate(-${isExpanded ? 180 : 0}deg)` }}
            onClick={() => setIsExpanded(!isExpanded)}
          />
          {app.name === AppName.Advertising && unassignedSegments && (
            <>
              <CreateNewSegmentIcon style={{ cursor: 'pointer' }} onClick={() => setBUBuilderDialogOpen(true)} />
              <BusinessUnitBuilderDialog open={buBuilderDialogOpen} onClose={() => setBUBuilderDialogOpen(false)} />
            </>
          )}
        </div>
        <div className="business-unit-folder-buttons">
          {id ? (
            <EditIcon
              style={{ ...styles.icon, stroke: colors.darkBlue }}
              onClick={() => setEditingBusinessUnitId(id)}
            />
          ) : null}
          {id ? <TrashcanIcon style={styles.icon} onClick={() => setDeletingSavedSearch(id)} /> : null}
        </div>
      </div>

      {isExpanded ? (
        <div className="business-unit-folder-items">
          <>
            {sortedChildren.length > 0 ? (
              <BUFolderItem
                i={-1}
                key="header"
                setDeletingSavedSearch={setDeletingSavedSearch}
                setEditingBusinessUnitId={setEditingBusinessUnitId}
                isHeader
                metricFields={metricFields}
                typeDropdown={typeDropdown}
                toggleSelectedType={toggleSelectedType}
                notAllTypeSelect={notAllTypeSelect}
                selectAllType={selectAllType}
                userDropdown={userDropdown}
                toggleSelectedUser={toggleSelectedUser}
                notAllUserSelect={notAllUserSelect}
                selectAllUser={selectAllUser}
                {...propsForSorting}
              />
            ) : (
              'No segments or BUs assigned'
            )}
            {didFetchData || tooManyChildrenToLoadSalesData ? (
              <>
                {filteredChildren.length > 6 ? (
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <VirtualizedList
                        width={width}
                        height={Math.min(filteredChildren.length * 48, 295)}
                        rowCount={children.length + 2}
                        rowHeight={48}
                        style={{ marginBottom: 10 }}
                        rowRenderer={({ index, style }) => {
                          const childId = filteredChildren[index];

                          if (!childId) {
                            return <div style={style} />;
                          }

                          return (
                            <BUFolderItem
                              setDeletingSavedSearch={setDeletingSavedSearch}
                              setEditingBusinessUnitId={setEditingBusinessUnitId}
                              i={index}
                              key={childId}
                              savedSearchId={childId}
                              style={style ? { ...style, width: width - 15 } : style}
                              metricFields={metricFields}
                              {...propsForSorting}
                            />
                          );
                        }}
                        headerRowRenderer={{}}
                      />
                    )}
                  </AutoSizer>
                ) : (
                  <>
                    {filteredChildren.map((childId, index) => (
                      <BUFolderItem
                        setDeletingSavedSearch={setDeletingSavedSearch}
                        setEditingBusinessUnitId={setEditingBusinessUnitId}
                        i={index}
                        key={childId}
                        savedSearchId={childId}
                        metricFields={metricFields}
                        {...propsForSorting}
                      />
                    ))}
                  </>
                )}
              </>
            ) : (
              <div style={{ display: 'flex', verticalAlign: 'center', justifyContent: 'center', padding: 40 }}>
                <Loading style={{ textAlign: 'center' }} size={75} thickness={2} />
              </div>
            )}
          </>
        </div>
      ) : null}
    </div>
  );
};

const showCantEditMessage = (ownerEmail: string) => {
  // eslint-disable-next-line no-alert
  alert(`You can't edit this business unit because it belongs to a different user: ${ownerEmail}`);
};

const BusinessUnitFolder = connect(mapBusinessUnitFolderStateToProps, mapDispatchToProps)(BusinessUnitFolderInner);

const mapStateToProps = (state: ReduxStore) => ({
  segments: state.segments,
  curUserId: curUserIdSelector(state)
});

const BusinessUnitListing: React.FC<{ style?: React.CSSProperties } & ReturnType<typeof mapStateToProps>> = ({
  style,
  segments,
  curUserId
}) => {
  const [deletingSavedSearch, setDeletingSavedSearchInner] = useState<string | null>(null);
  const [editingBusinessUnitId, setEditingBusinessUnitId] = useState<string | null>(null);
  const [showAllBUs, setShowAllBUs] = useState(false);

  const setDeletingSavedSearch = useCallback(
    (savedSearchId: string | null) => {
      if (_isNil(savedSearchId)) {
        setDeletingSavedSearchInner(savedSearchId);
        return;
      }

      if (!segments.savedSearchesById) {
        warn('Tried to delete saved search before segments loaded');
        return;
      }

      const savedSearch = segments.savedSearchesById.get(savedSearchId);
      if (!savedSearch) {
        error(`Tried to delete saved search with ID ${savedSearchId} but it wasn't found in the mapping`);
        return;
      } else if (savedSearch.type === 'businessunit' && savedSearch.userId !== curUserId) {
        showCantEditMessage(savedSearch.owner.email); // This is the email at the time of creation, so it may be with the old format (username+company instead of username-company)
        return;
      }

      setDeletingSavedSearchInner(savedSearchId);
    },
    [segments.savedSearchesById, curUserId]
  );

  const unassignedSegments = useMemo(() => getUnassignedSegments(segments), [segments]);
  const unassignedBusinessUnits = useMemo(() => getUnassignedBusinessUnits(segments), [segments]);

  if (!segments.businessUnits) {
    return <GridLoading />;
  }

  const topLevelBUs = segments.businessUnits.filter(_prop('isTopLevel'));

  const handleEditingBUIDChange = (newBUID: string) => {
    if (!segments.savedSearchesById) {
      console.warn("Trying to edit BU before we've fetched saved searches; skipping.");
      return;
    }
    const editingBusinessUnit = segments.savedSearchesById.get(newBUID);
    if (!editingBusinessUnit) {
      throw panic(`Trying to edit BU ID ${newBUID} but it's not found`);
    } else if (editingBusinessUnit.type !== 'businessunit') {
      throw panic('Trying to edit a segment as a business unit');
    } else if (editingBusinessUnit.userId !== curUserId) {
      showCantEditMessage(editingBusinessUnit.owner.email);
      return;
    }

    const parentBusinessUnitId = Option.of(
      segments.businessUnits.find((bu) => bu.children.includes(editingBusinessUnit.id))
    )
      .map(prop('id' as const))
      .getOrElse('0');

    const initialState: BUBFormState = {
      name: editingBusinessUnit.displayName,
      parentBusinessUnit: parentBusinessUnitId,
      children: editingBusinessUnit.children,
      isTopLevel: editingBusinessUnit.isTopLevel,
      id: editingBusinessUnit.id
    };

    store.dispatch(genericFormActions.initForm('businessUnitBuilder', initialState));
    setEditingBusinessUnitId(newBUID);
  };

  return (
    <>
      <div style={{ marginTop: 10, paddingBottom: 10 }}>
        <Button
          className="sl-secondary-button"
          style={{ float: 'right' }}
          onClick={() => {
            setShowAllBUs(!showAllBUs);
          }}
        >
          {showAllBUs ? 'Show Top Level' : 'Show All'}
        </Button>
      </div>
      <div className="business-unit-listing" style={style}>
        {(showAllBUs ? segments.businessUnits : topLevelBUs).map((bu, i) => (
          <BusinessUnitFolder
            key={bu.id}
            businessUnit={bu}
            setDeletingSavedSearch={setDeletingSavedSearch}
            setEditingBusinessUnitId={handleEditingBUIDChange}
            i={i}
          />
        ))}
        <BusinessUnitFolder
          key="unassignedSegments"
          unassignedSegments={[...unassignedBusinessUnits, ...unassignedSegments]}
          setDeletingSavedSearch={setDeletingSavedSearch}
          setEditingBusinessUnitId={handleEditingBUIDChange}
          i={topLevelBUs.length}
        />

        <DeleteSavedSearchDialog
          isOpen={!!deletingSavedSearch}
          setIsClosed={() => setDeletingSavedSearch(null)}
          savedSearchId={deletingSavedSearch}
        />
        <BusinessUnitBuilderDialog
          open={!!editingBusinessUnitId}
          editingBUId={editingBusinessUnitId}
          onClose={() => setEditingBusinessUnitId(null)}
        />
      </div>
    </>
  );
};

const EnhancedBusinessUnitListing = connect(mapStateToProps)(BusinessUnitListing);

export default EnhancedBusinessUnitListing;
