import types from './types';
import parseBrandsFollowed from 'src/store/modules/brands/selectors';

const receiveBrandsFollowing = (brands: ReturnType<typeof parseBrandsFollowed>) => ({
  type: types.RECEIVE_ALL_BRANDS_FOLLOWING,
  brands
});

const clearBrandsFollowing = () => ({
  type: types.CLEAR_ALL_BRANDS_FOLLOWING
});

export default {
  clearBrandsFollowing,
  receiveBrandsFollowing
};
