/**
 * An `EntityGrid` component that can be rendered by itself without a wrapping page container.
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';
import _identity from 'lodash/identity';
import _merge from 'lodash/merge';
import { withRouter } from 'react-router';
import queryString from 'qs';

import { getMainConditions } from 'src/utils/conditions';
import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import EntityGrid from '.';

const getDefaultDataConfig = (indexName) => ({
  indexName,
  retailerEntity: _cloneDeep(ENTITIES.beacon.retailer),
  categoryEntity: _cloneDeep(ENTITIES.beacon.category),
  relatedEntity: _cloneDeep(ENTITIES.beacon.brand),
  weekIdField: _cloneDeep(INDEX_FIELDS.getField('beacon', 'sales', 'weekId')),
  retailerIdField: _cloneDeep(INDEX_FIELDS.getField('beacon', 'sales', 'retailerId'))
});

const StandaloneEntityGrid = ({
  indexName,
  mergeCustomFilters,
  dataConfig,
  widget,
  location,
  aggregationConditions,
  inverseComparisonTimePeriod,
  ...props
}) => {
  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });

  // This emulates the behavior of `EntityPage` in building the API request conditions from
  // the application's global Redux state.
  const { baseAggregationConditions, entityConditions, mainEntityConditions } = getMainConditions(props);

  // Combine the base filters from the global application environment/Redux with those from the special filters that
  // apply only to this grid.
  const mergedMainEntityConditions = mergeCustomFilters(mainEntityConditions);
  return (
    <EntityGrid
      {...props}
      dataConfig={dataConfig || getDefaultDataConfig(indexName)}
      conditions={entityConditions}
      entity={props.entityService.mainEntity}
      selectedEntity={null}
      queryConditions={mergedMainEntityConditions}
      aggregationConditions={_merge(baseAggregationConditions, aggregationConditions)}
      queryParams={queryParams}
      widget={widget}
      inverseComparisonTimePeriod={inverseComparisonTimePeriod}
    />
  );
};

StandaloneEntityGrid.propTypes = {
  indexName: PropTypes.string.isRequired,
  mergeCustomFilters: PropTypes.func,
  dataConfig: PropTypes.object,
  widget: PropTypes.object.isRequired,
  aggregationConditions: PropTypes.object,
  inverseComparisonTimePeriod: PropTypes.bool,
  // From `withRouter`
  location: PropTypes.object.isRequired,
  // Redux props
  app: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  subCategories: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  mainTimePeriod: PropTypes.object.isRequired,
  comparisonTimePeriod: PropTypes.object.isRequired,
  entityService: PropTypes.object.isRequired,
  entitySearchService: PropTypes.object.isRequired
};

StandaloneEntityGrid.defaultProps = {
  mergeCustomFilters: _identity,
  dataConfig: undefined,
  aggregationConditions: { termFilters: [], rangeFilters: [] },
  inverseComparisonTimePeriod: false
};

const mapStateToProps = (state) =>
  _pick(state, [
    'app',
    'entityService',
    'entitySearchService',
    'comparisonTimePeriod',
    'mainTimePeriod',
    'segments',
    'retailer',
    'categories',
    'subCategories',
    'filters'
  ]);

const EnhancedStandaloneEntityGrid = withRouter(connect(mapStateToProps)(StandaloneEntityGrid));

export default EnhancedStandaloneEntityGrid;
