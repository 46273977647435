import { RangeFilter, TermFilter } from 'sl-api-connector/types';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import { ISearchRequestOverride } from 'src/components/AdManager/Search/Models/ISearchRequestOverride';
import ReduxStore from 'src/types/store/reduxStore';

function getRetailerId(retailerId: string) {
  return Number.parseInt(retailerId, 10);
}

function getGroupByFieldName(groupByField: any, targetingGroupBy: string) {
  if (groupByField.name === 'targetingText') {
    return targetingGroupBy;
  } else if (groupByField.name === 'portfolioId') {
    return 'portfolioId'; // 'campaignId';
  } else {
    return groupByField.name;
  }
}

export function constructAdvancedSearchRequest(
  aggregationFields: any,
  retailer: Pick<ReduxStore['retailer'], 'id'>,
  customConditionTermFilters: TermFilter[],
  mainTimePeriodRangeFilters: RangeFilter[],
  aggregationFilters: ISearchFilterConditions,
  searchSideBarConditions: ISearchFilterConditions,
  sortDirection: string | undefined,
  currentSortFieldName: any,
  comparisonTimePeriodRangeFilters: RangeFilter[],
  groupByField: any,
  targetingGroupBy: string,
  scheduledActionTermFilters: TermFilter[],
  customTermFilters: any,
  pageNumber: number,
  pageSize: any
): ISearchRequestOverride {
  const retailerId = getRetailerId(retailer.id);
  const groupByFieldName = getGroupByFieldName(groupByField, targetingGroupBy);
  const sortByAggregationField = aggregationFields.find((x) => x.aggregateByFieldName === currentSortFieldName);
  const searchRequestOverride: ISearchRequestOverride = {
    doAggregation: true,
    aggregations: [
      {
        aggregationFields,
        conditions: {
          termFilters: [
            { fieldName: 'retailerId', values: [retailerId] },
            {
              fieldName: 'isProjected',
              condition: 'must',
              values: [0]
            },
            ...customConditionTermFilters
          ],
          rangeFilters: [...mainTimePeriodRangeFilters, ...aggregationFilters.rangeFilters],
          computeFilters: [...searchSideBarConditions.computeFilters, ...aggregationFilters.computeFilters]
        },
        sortDirection,
        sortByAggregationField,
        comparisonRangeFilters: comparisonTimePeriodRangeFilters,
        groupByFieldName
      }
    ],
    conditions: {
      termFilters: [...searchSideBarConditions.termFilters, ...scheduledActionTermFilters, ...customTermFilters],
      rangeFilters: [...mainTimePeriodRangeFilters, ...searchSideBarConditions.rangeFilters],
      computeFilters: [...searchSideBarConditions.computeFilters]
    },
    pageNumber,
    pageSize,
    processDocuments: false
  };
  return searchRequestOverride;
}

export function constructAdvancedSearchRequestForResultCount(
  aggregationFields: any,
  retailer: Pick<ReduxStore['retailer'], 'id'>,
  mainTimePeriodRangeFilters: RangeFilter[],
  aggregationFilters: ISearchFilterConditions,
  searchSideBarConditions: ISearchFilterConditions,
  scheduledActionTermFilters: TermFilter[],
  customTermFilters: any,
  sortDirection: string | undefined,
  currentSortFieldName: any,
  pageNumber: number,
  pageSize: any
): ISearchRequestOverride {
  const retailerId = getRetailerId(retailer.id);
  const sortByAggregationField = aggregationFields.find((x) => x.aggregateByFieldName === currentSortFieldName);
  const searchRequestOverride: ISearchRequestOverride = {
    doAggregation: true,
    aggregations: [
      {
        aggregationFields,
        conditions: {
          computeFilters: [...aggregationFilters.computeFilters],
          termFilters: [
            { fieldName: 'retailerId', values: [retailerId] },
            {
              fieldName: 'isProjected',
              condition: 'must',
              values: [0]
            }
          ],
          rangeFilters: [...mainTimePeriodRangeFilters, ...aggregationFilters.rangeFilters]
        },
        sortDirection,
        sortByAggregationField,
        groupByFieldName: 'retailerId'
      }
    ],
    conditions: {
      computeFilters: [...aggregationFilters.computeFilters],
      termFilters: [...searchSideBarConditions.termFilters, ...scheduledActionTermFilters, ...customTermFilters],
      rangeFilters: [...mainTimePeriodRangeFilters, ...searchSideBarConditions.rangeFilters]
    },
    pageNumber,
    pageSize,
    processDocuments: false
  };
  return searchRequestOverride;
}
