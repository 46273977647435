import { getEmptyFormData, FormData } from 'src/utils/segments';
import { AppName } from 'sl-api-connector/types';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';

/**
 * Returns true if the user has selected any filters that are different from
 * the default
 * @param formData The form data from the sidebar
 */
export const shouldFilterOutBlankCampaigns = (formData: FormData): boolean => {
  const emptyFormData = getEmptyFormData({ appName: AppName.Advertising });

  if (Object.keys(formData).length === 0) {
    return false;
  }

  // Updating a filter and then resetting adds a 'fieldName' key, so strip
  // it and just compare values next
  const rangeFilters = Object.entries(formData.rangeFilters).reduce((prev, [key, value]) => {
    return {
      ...prev,
      [key]: _pick(value, ['minValue', 'maxValue'])
    };
  }, {} as Partial<FormData['rangeFilters']>);

  const hasRangeFilters = !_isEqual(rangeFilters, emptyFormData.rangeFilters);
  const hasTermFilters = !_isEqual(formData.termFilters, emptyFormData.termFilters);

  // The filters are back to their default state, so include everything
  if (!hasRangeFilters && !hasTermFilters) {
    return false;
  }

  // If there are any range filters then filter out the blank campaigns unless they are bid filters
  if (hasRangeFilters) {
    // If there are any range filters besides bid, then filter out blank campaigns
    if (!_isEqual(_omit(rangeFilters, 'bid'), _omit(emptyFormData.rangeFilters, 'bid'))) {
      return true;
    }

    // If we are here it means there are ONLY bid filters, do not filter out empty campaigns
    return false;
  }

  // Do not filter out blank campaigns with term filters because
  // they still apply
  if (hasTermFilters) {
    return false;
  }

  return true;
};
