import { useCallback } from 'react';
import { useAppSelector } from 'src/utils/Hooks';
import AdvancedSearchRequestBuilder, {
  useCreateAdvancedSearchRequestBuilder
} from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import {
  UseAggregationBuilderWithMetricsArgs,
  useCreateAggregationBuilderWithMetrics
} from './useAggregationBuilderWithMetrics';
import { getAppName } from 'src/utils/app';
import { useBeaconFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBeaconFilters';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import _get from 'lodash/get';

export interface UseBaseMetricRequestBuilderProps extends UseAggregationBuilderWithMetricsArgs {
  requestId: string;
  startWeekId?: number;
  endWeekId?: number;
  sortFieldName?: string;
  buildAggregationBuilder?: (aggregationBuilder: AggregationBuilder) => AggregationBuilder;
}

/**
 * Factory for creating a function to remove price range filters.
 * Can be added to any advanced search request with the `.apply` method
 */
export const useCreateRemoveRetailPriceRangeFilters = () => {
  return useCallback((indexName?: string) => {
    return (builder: AdvancedSearchRequestBuilder) => {
      if (
        !indexName ||
        [
          'new-content-metrics',
          'content',
          'advertising',
          'reviews',
          'buybox',
          'advertisingDisplay',
          'chargebacks',
          'aggregated-traffic'
        ].includes(indexName)
      ) {
        builder.apply((request) => {
          return request.removeConditionRangeFilters((rangeFilter) => rangeFilter.fieldName === 'retailPrice');
        });
      }
      return builder;
    };
  }, []);
};

/**
 * Segments can include a filter for retail price. We want to filter
 * this condition out for certain indices because it will cause them
 * to return nothing.
 */
export const useRemoveRetailPriceRangeFilters = (indexName: string) => {
  const createRemoveRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();
  return createRemoveRetailPriceRangeFilters(indexName);
};

/**
 * Returns a function that applies filters depending on the entity type.
 */
export const useApplyEntityFilters = () => {
  const entityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const entityId = useAppSelector((state) => state.entityService.mainEntity.id);
  const categoryIds = useAppSelector((state) => {
    const allCategoryIds = state.categories.map((category) => category.id);
    if (entityType === 'segment') {
      return allCategoryIds;
    }
    const entityCategoryIds: number[] = _get(state, ['entityService', 'mainEntity', 'categoryIds'], []);

    return entityCategoryIds.filter((id) => allCategoryIds.includes(id));
  });
  const { hasActiveFilters, appFilters } = useBeaconFilters();
  const segments = useAppSelector((state) => {
    const { mySegments, teamSegments, teamSearchTermLists, mySearchTermLists } = state.segments;
    return [...mySegments, ...teamSegments, ...teamSearchTermLists, ...mySearchTermLists];
  });

  return useCallback(
    (requestBuilder: AdvancedSearchRequestBuilder) => {
      requestBuilder.addConditionTermFilter('categoryId', 'should', categoryIds);

      if (entityType === 'product') {
        requestBuilder.addConditionTermFilter('stacklineSku', 'should', [entityId]);
      } else if (entityType === 'brand') {
        requestBuilder.addConditionTermFilter('brandId', 'should', [entityId]);
      } else if (entityType === 'category') {
        requestBuilder.addConditionTermFilter('categoryId', 'should', [entityId]);
      } else if (entityType === 'subcategory') {
        requestBuilder.addConditionTermFilter('subCategoryId', 'should', [entityId]);
      } else if (entityType === 'segment' || entityType === 'searchtermlist') {
        const segment = segments.find((seg) => seg.id === entityId);
        if (segment) {
          if (segment.conditions.rangeFilters) {
            segment.conditions.rangeFilters.forEach((rangeFilter) => {
              requestBuilder.replaceConditionRangeFilter(
                rangeFilter.fieldName,
                rangeFilter.minValue,
                rangeFilter.maxValue
              );
            });
          }
          if (segment.conditions.termFilters) {
            segment.conditions.termFilters.forEach((termFilter) => {
              requestBuilder.replaceConditionTermFilter(termFilter.fieldName, termFilter.condition, termFilter.values);
            });
          }
        }
      }

      if (hasActiveFilters) {
        const { categoryFilters, subcategoryFilters, segmentFilters } = appFilters;

        if (categoryFilters) {
          requestBuilder.replaceConditionTermFilter('categoryId', 'should', categoryFilters);
        }
        if (subcategoryFilters) {
          requestBuilder.replaceConditionTermFilter('subCategoryId', 'should', subcategoryFilters);
        }
        if (segmentFilters) {
          // TODO update segment filters to use range filters too, @Jonny
          const { termFilters } = segmentFilters;
          termFilters.forEach(({ fieldName, condition, values }) =>
            requestBuilder.replaceConditionTermFilter(fieldName, condition, values)
          );
        }
      }

      return requestBuilder;
    },
    [appFilters, categoryIds, entityId, entityType, hasActiveFilters, segments]
  );
};

/**
 * Returns a factory function for getting a new request builder.
 */
export function useCreateBaseMetricRequestBuilder() {
  const applyEntityFilters = useApplyEntityFilters();

  const createRemoveRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();
  const createAdvancedSearchRequestBuilder = useCreateAdvancedSearchRequestBuilder();
  const createAggregationBuilderWithMetrics = useCreateAggregationBuilderWithMetrics();

  return useCallback(
    ({
      requestId,
      startWeekId,
      endWeekId,
      indexName,
      fields,
      groupByFieldName,
      sortFieldName,
      buildAggregationBuilder
    }: UseBaseMetricRequestBuilderProps) => {
      const requestBuilder = createAdvancedSearchRequestBuilder(requestId, `${getAppName()}-${indexName}`);
      const aggregationBuilder = createAggregationBuilderWithMetrics({
        indexName,
        fields,
        groupByFieldName
      });

      if (startWeekId) {
        aggregationBuilder.addConditionRangeFilter('weekId', startWeekId, endWeekId);
      }

      const sortField = sortFieldName ? INDEX_FIELDS.getField(getAppName(), indexName, sortFieldName) : undefined;
      if (sortField) {
        aggregationBuilder.setSortByAggregationField(
          sortField.displayName,
          sortField.name,
          sortField.aggregationFunction,
          true
        );
      }

      const aggBuilderToUse = buildAggregationBuilder
        ? buildAggregationBuilder(aggregationBuilder)
        : aggregationBuilder;

      requestBuilder
        .setPageNumber(1)
        .setPageSize(1200)
        .setPeriod('year')
        .setDoAggregation(true)
        .setReturnDocuments(false)
        .addAggregation(aggBuilderToUse.build())
        .setSearchBy('parent');

      if (startWeekId) {
        requestBuilder.addConditionRangeFilter('weekId', startWeekId, endWeekId);
      }

      if (sortField) {
        requestBuilder.addSortField(sortField.displayName, sortField.name, sortField.aggregationFunction, true);
      }

      requestBuilder.apply(applyEntityFilters).apply(createRemoveRetailPriceRangeFilters(indexName));

      return requestBuilder;
    },
    [
      applyEntityFilters,
      createAdvancedSearchRequestBuilder,
      createAggregationBuilderWithMetrics,
      createRemoveRetailPriceRangeFilters
    ]
  );
}

/**
 * Creates the base request builder for a given
 * list of metric fields.
 *
 * Should be used multiple times when there are metrics with different indices.
 * For example, 'sales -> unitsSold' and 'traffic -> totalClicks' are under
 * two different APIs, so they should be requested separately.
 */
export const useBaseMetricRequestBuilder = (args: UseBaseMetricRequestBuilderProps): AdvancedSearchRequestBuilder => {
  const createBaseMetricRequestBuilder = useCreateBaseMetricRequestBuilder();
  return createBaseMetricRequestBuilder(args);
};
