import './CalendarRangePickerStyles.scss';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import React, { FC, useRef, useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import moment from 'moment-timezone';
import { styled } from '@mui/system';
import { AppInputProps, SlColumn, useStacklineTheme } from '@stackline/ui';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { getWeekId, getStartAndEndOfWeek } from 'src/utils/dateUtils';
import { CalendarError } from 'src/components/BeaconRedesignComponents/common/CalendarRangePicker/CalendarError';
import { RightArrow, CalendarV2Icon } from 'src/components/SvgIcons/SvgIcons';
import NumberFormat from 'react-number-format';
import { ReadOnlyCustomTextField } from 'src/components/BeaconRedesignComponents/common/SlCalendarPicker/SlCalendarPicker';

const CustomTextField = styled(TextField)({
  // override the styles of the MUI classes
  '& .MuiInputBase-root': {
    height: '32px',
    width: '118px',
    borderRadius: '4px',
    border: '1px solid #e8e8ed',
    backgroundColor: '#fff',
    paddingLeft: '8px',
    '.Mui-disabled': {
      color: '#7e8fa8',
      opacity: 0.5,
      '-webkit-text-fill-color': '#7e8fa8'
    }
  },
  '& .MuiInputLabel-root': {
    display: 'none'
  },

  input: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    padding: '0px',
    paddingLeft: '4px'
  },
  // Date Picker's Placeholder will set custom opacity by default.
  '& input::placeholder': {
    color: '#7e8fa8',
    opacity: 0.5
  }
});

export const handleDisableDate = (day: Date, dayOffset = 0): boolean => {
  const targetDay = moment().subtract(dayOffset, 'day');
  return moment(day).isAfter(moment([targetDay.year(), targetDay.month(), targetDay.date()]));
};

export interface CalendarRangePickerCustomProps {
  showSelector?: boolean;
  appInputProps?: AppInputProps;
}

const dateTimePaperPropsStyles = {
  sx: {
    '.MuiPickersCalendarHeader-root': {
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center'
    },
    '.MuiPickersCalendarHeader-root:first-child': {
      order: 0,
      paddingRight: '20px',
      paddingLeft: '20px'
    },
    '.MuiPickersArrowSwitcher-root': {
      display: 'inline-flex',
      color: '#052849',
      marginLeft: '-2px'
    },
    '.MuiPickersCalendarHeader-label': {
      textAlign: 'center'
    },
    '.MuiPickersArrowSwitcher-spacer': {
      width: '220px'
    },
    '.MuiPickersFadeTransitionGroup-root': {
      display: 'flex',
      position: 'absolute',
      paddingLeft: '80px'
    },

    '.MuiPickersArrowSwitcher-button': {
      color: '#052849',
      paddingRight: '7px'
    }
  }
};

export const shouldDisableDate = ({ date, conflictingDates = [] }: { date: Date; conflictingDates?: string[] }) => {
  if (!conflictingDates) {
    return true;
  }
  // Calculate the start of this week
  const currentWeekId = getWeekId(new Date());
  const { startDate: startDayOfCurrentWeek } = getStartAndEndOfWeek(currentWeekId);

  // Check if the date is before the start of this week
  if (moment(date).isBefore(startDayOfCurrentWeek, 'day')) {
    return true; // Disable dates before the start of this week
  }
  let shouldDisable = false;

  // Iterate through each conflicting date and check if the provided date falls within those weeks
  conflictingDates.forEach((conflictingDate) => {
    const { startDate: firstDayOfWeek, endDate: lastDayOfWeek } = getStartAndEndOfWeek(conflictingDate);

    if (moment(date).isBetween(firstDayOfWeek, lastDayOfWeek, 'day', '[]')) {
      shouldDisable = true; // Disable dates within conflicting weeks
    }
  });

  return shouldDisable; // Enable other dates
};

interface CalendarRangePickerProps {
  disabled: boolean;
  planStartDate?: string | null;
  planEndDate?: string | null;
  allConflictingWeekIds: string[];
  handleDateChange: (selectedDates: string[] | number[]) => void;
}

/**
 * @deprecated Use SlCalendarPicker instead.
 */
const CalendarRangePicker: FC<CalendarRangePickerProps> = ({
  disabled = false,
  planStartDate = null,
  planEndDate = null,
  handleDateChange,
  allConflictingWeekIds
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [calendarErrorOpen, setCalendarErrorOpen] = useState(false);

  const theme = useStacklineTheme();

  const inputRef = useRef(null);
  const startRef = useRef(null);
  const endRef = useRef(null);

  // If we are viewing an adjustment, we can set the preselected dates
  useEffect(() => {
    if (planStartDate && planEndDate) {
      const { startDate: adjustmentStartWeek } = getStartAndEndOfWeek(planStartDate);
      const { endDate: adjustmentEndWeek } = getStartAndEndOfWeek(planEndDate);
      const startOfAdjustmentStartWeek = moment(adjustmentStartWeek, 'YYYYMMDD').toDate();
      const endOfAdjustmentEndWeek = moment(adjustmentEndWeek, 'YYYYMMDD').toDate();
      setSelectedDateRange([startOfAdjustmentStartWeek, endOfAdjustmentEndWeek]);
    } else if (!planStartDate && !planEndDate) {
      setSelectedDateRange([null, null]);
    }
  }, [planStartDate, planEndDate]);

  /**
   * Accepts an array of date(s). If the user is selecting a start date, we preselect the entire week for them.
   * Otherwise, the user has selected a start date already, in which case we get the start day of the start week
   * and the end day of the end week of the selected date range.
   * We still check the selection before updating state because the user might be typing a random date range in the inputs.
   * @param selectedDates An array of 2 elements, each can be either a Date object or null
   */
  const handleChange = (selectedDates: [Date | null, Date | null]) => {
    // Start date selection
    if (!selectedDates[1]) {
      const selectedStartWeek = getWeekId(selectedDates[0]);
      const { startDate: selectedStartDate, endDate: selectedEndDate } = getStartAndEndOfWeek(selectedStartWeek);
      const startOfSelectedWeek = moment(selectedStartDate, 'YYYYMMDD').toDate();
      const endOfSelectedWeek = moment(selectedEndDate, 'YYYYMMDD').toDate();

      if (!shouldDisableDate({ date: selectedDates[0] })) {
        setSelectedDateRange([startOfSelectedWeek, endOfSelectedWeek]);
        // Note that we pass the start and end week IDs instead of the date objects.
        handleDateChange([selectedStartWeek, selectedStartWeek]);
        startRef.current = startOfSelectedWeek;
        endRef.current = endOfSelectedWeek;
        setCalendarErrorOpen(false);
      }
    } else {
      const selectedStartWeek = getWeekId(selectedDates[0]);
      const selectedEndWeek = getWeekId(selectedDates[1]);
      const { startDate: selectedStartDate } = getStartAndEndOfWeek(selectedStartWeek);
      const { endDate: selectedEndDate } = getStartAndEndOfWeek(selectedEndWeek);
      const startOfSelectedWeek = moment(selectedStartDate, 'YYYYMMDD').toDate();
      const endOfSelectedWeek = moment(selectedEndDate, 'YYYYMMDD').toDate();

      if (!shouldDisableDate({ date: selectedDates[0] }) && !shouldDisableDate({ date: selectedDates[1] })) {
        /**
         * If the user is selecting a date range with invalid week Ids in the middle, do not allow selection.
         */
        if (
          !allConflictingWeekIds.find((conflictedWeekId) => {
            return selectedStartWeek <= Number(conflictedWeekId) && Number(conflictedWeekId) <= selectedEndWeek;
          })
        ) {
          setSelectedDateRange([startOfSelectedWeek, endOfSelectedWeek]);
          // Note that we pass the start and end week IDs instead of the date objects.
          handleDateChange([selectedStartWeek, selectedEndWeek]);
          startRef.current = startOfSelectedWeek;
          endRef.current = endOfSelectedWeek;
          setCalendarErrorOpen(false);
        } else {
          setCalendarErrorOpen(true);
        }
      }
    }
  };
  const [selectedStartDate, selectedEndDate] = selectedDateRange;
  const formattedStartDate = selectedStartDate ? moment(selectedStartDate).format('MM/DD/YYYY') : '';
  const formattedEndDate = selectedEndDate ? moment(selectedEndDate).format('MM/DD/YYYY') : '';

  return (
    <div>
      <DateRangePicker
        disabled={disabled}
        dayOfWeekFormatter={(day) => day}
        shouldDisableDate={(date) => shouldDisableDate({ date, conflictingDates: allConflictingWeekIds })}
        closeOnSelect={false}
        value={selectedDateRange}
        onChange={handleChange}
        onClose={() => setCalendarErrorOpen(false)}
        components={{
          PaperContent: ({ children }) => {
            return (
              <SlColumn horizontalPosition="center">
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    backgroundColor: '#ffffff',
                    alignItems: 'center',
                    height: '46px',
                    borderBottom: `1px solid ${theme.colors.primaryGray}`
                  }}
                >
                  <div style={{ marginTop: '14px' }}>
                    <Flex gap="sm" alignItems="center">
                      <NumberFormat
                        value={formattedStartDate}
                        format="##/##/####"
                        placeholder="MM/DD/YYYY"
                        customInput={ReadOnlyCustomTextField}
                      ></NumberFormat>
                      <RightArrow style={{ width: '12px', height: '12px', color: theme.colors.primary }} />
                      <NumberFormat
                        value={formattedEndDate}
                        format="##/##/####"
                        placeholder="MM/DD/YYYY"
                        customInput={ReadOnlyCustomTextField}
                      ></NumberFormat>
                    </Flex>
                  </div>
                  {children}
                  <div style={{ marginTop: '20px' }}>
                    <CalendarError
                      message="The date range you selected conflicts with an existing adjustment. Please try again."
                      open={calendarErrorOpen}
                    />
                  </div>
                </div>
              </SlColumn>
            );
          }
        }}
        PopperProps={{
          ref: inputRef.current,
          placement: 'bottom',
          popperOptions: {
            placement: 'bottom'
          },
          sx: dateTimePaperPropsStyles.sx,
          // for some reason PaperProps and not being applied
          // accessing Paper from Popper
          className: 'app-date-range-picker-popper'
        }}
        PaperProps={dateTimePaperPropsStyles}
        renderInput={(startProps, endProps) => {
          startProps.inputProps.placeholder = 'Start Date';
          endProps.inputProps.placeholder = 'End Date';
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <CustomTextField
                InputProps={{
                  autoComplete: 'off', // Fix for lastpass bug
                  id: 'type-search', // Fix for lastpass bug
                  startAdornment: (
                    <CalendarV2Icon
                      style={{
                        color: theme.colors.primary,
                        width: '15px',
                        marginRight: '4px'
                      }}
                    />
                  )
                }}
                {...startProps}
              ></CustomTextField>
              <span
                style={{ color: theme.colors.primary, marginLeft: theme.spacing.md, marginRight: theme.spacing.md }}
              >
                to
              </span>
              <CustomTextField
                InputProps={{
                  autoComplete: 'off', // Fix for lastpass bug
                  id: 'type-search', // Fix for lastpass bug
                  startAdornment: (
                    <CalendarV2Icon
                      style={{
                        color: theme.colors.primary,
                        width: '15px',
                        marginRight: '4px'
                      }}
                    />
                  )
                }}
                {...endProps}
              ></CustomTextField>
            </div>
          );
        }}
      />
    </div>
  );
};

export default CalendarRangePicker;
