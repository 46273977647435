/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { AutoSizer, List as VirtualizedList, ListRowProps, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { BusinessUnit, SavedSearch } from 'sl-api-connector/types';

import { store } from 'src/main';
import colors from 'src/utils/colors';
import filtersActions from 'src/store/modules/filters/actions';
import { ListFilterRow } from './common';
import { filterNils } from 'src/utils/fp';
import ReduxStore from 'src/types/store/reduxStore';

interface BusinessUnitFilterProps {
  parentBusinessUnit: BusinessUnit;
  level: number;
  onFilterChange: (selectedFilters: string[], filterName: string) => void;
  value?: string[];
}

const mapRowRendererStateToProps = ({ filters }: ReduxStore) => ({ businessUnitFilters: filters.businessUnits });

const mkRowRenderer = ({
  onFilterChange,
  value,
  childSavedSearches
}: Pick<BusinessUnitFilterProps, 'onFilterChange' | 'value'> & { childSavedSearches: SavedSearch[] }) => {
  const RowRenderer: React.FC<ListRowProps & ReturnType<typeof mapRowRendererStateToProps>> = ({
    style,
    index,
    businessUnitFilters
  }) => (
    <ListFilterRow
      key={index}
      style={style}
      index={index}
      value={childSavedSearches[index].displayName}
      isChecked={
        value
          ? value.includes(childSavedSearches[index].id)
          : !!businessUnitFilters && !!businessUnitFilters.find((buId) => buId === childSavedSearches[index].id)
      }
      onCheck={(evt, isChecked, checkedIndex) => {
        const toggledBUId = childSavedSearches[checkedIndex].id;
        const allFilters: ReduxStore['filters'] = store.getState().filters;

        const newBusinessUnitFilters = isChecked
          ? [...(value || allFilters.businessUnits || []), toggledBUId]
          : [...(value || allFilters.businessUnits || []).filter((id) => id !== toggledBUId)];
        onFilterChange(newBusinessUnitFilters, 'businessunit');
        const newFilters = { ...allFilters, businessUnits: newBusinessUnitFilters };

        store.dispatch(filtersActions.updateFilters(newFilters));
      }}
    />
  );

  return connect(mapRowRendererStateToProps)(RowRenderer);
};

const mapStateToProps = ({ segments: { savedSearchesById } }: ReduxStore) => ({ savedSearchesById });

const BusinessUnitFilter: React.FC<BusinessUnitFilterProps & ReturnType<typeof mapStateToProps>> = ({
  parentBusinessUnit,
  savedSearchesById,
  level,
  onFilterChange,
  value
}) => {
  const childSavedSearches = useMemo(
    () =>
      savedSearchesById
        ? filterNils(
            parentBusinessUnit.children.map((id) => {
              const childSavedSearch = savedSearchesById.get(id);
              if (!childSavedSearch) {
                console.warn(`Tried to lookup child BU ${id} for ${parentBusinessUnit.id} but it wasn't found.`);
              }
              return childSavedSearch;
            })
          )
        : [],
    [parentBusinessUnit.children, parentBusinessUnit.id, savedSearchesById]
  );

  const RowRenderer = useMemo(
    () => mkRowRenderer({ onFilterChange, value, childSavedSearches }),
    [onFilterChange, value, childSavedSearches]
  );

  if (!savedSearchesById) {
    return null;
  }

  // Currently exhibits weird behavior when placed outside of component; we may not be receiving performance benefits with the way it is setup currently.
  // React Docs advise against using Refs for rendering but we may be able to expose the ref properly with a wrapper function that returns just the "current" property.
  const cache = new CellMeasurerCache({
    defaultHeight: 60,
    fixedWidth: true
  });

  return (
    <div style={{ marginTop: 30, paddingLeft: level * 6 }}>
      <div
        style={{
          marginBottom: 10,
          borderBottom: `1px solid ${colors.lightGrey}`,
          paddingBottom: 4
        }}
      >
        {parentBusinessUnit.displayName}
      </div>

      <AutoSizer disableHeight>
        {({ width }: { width: number }) => (
          <VirtualizedList
            width={width}
            height={175}
            rowCount={childSavedSearches.length}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            style={{ marginBottom: 10 }}
            rowRenderer={({ ...props }) => {
              const { key, parent, index } = props;
              return (
                <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                  <RowRenderer {...props} />
                </CellMeasurer>
              );
            }}
          />
        )}
      </AutoSizer>
    </div>
  );
};

const EnhancedBusinessUnitFilter = connect(mapStateToProps)(BusinessUnitFilter);

export default EnhancedBusinessUnitFilter;
