/* eslint-disable no-restricted-syntax */
// /* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import queryString from 'qs';
import { filterSelectors } from 'src/store/modules/filters';
import Dialog from '@mui/material/Dialog';
import CloseButton from '../../common/Buttons/CloseButton';
import colors from 'src/utils/colors';
import ReduxStore from 'src/types/store/reduxStore';
import OmniFilterSection from './OmniFilterSection';
import { EventBus } from 'src/types/utils';
import _cloneDeep from 'lodash/cloneDeep';
import { destructurePathName } from 'src/utils/urlParsing';
import OmniFilterRadioSelection from 'src/components/Omni/OmniFilter/OmniFilterRadioButton';
import { omniDynamicFilterOperations } from 'src/store/modules/omni/omniDynamicFilter';
import {
  filterKeyMapping,
  updateDynamicFilterApiBody,
  matchingInParsedFilterParamWithDynamicFilter
} from 'src/components/Omni/OmniFilter/omniFilterUtils';
import './OmniFilterPopUp.scss';
import { useInstacartPageFilter } from 'src/utils/Hooks';

interface OmniFilterPopUpProps extends RouteComponentProps {
  open: boolean;
  eventBus: EventBus;
  onClose: any;
  shouldShowSegmentFilter: boolean;
  shouldShowKeywordSegmentFilter?: boolean;
  setBadgeNumber: React.Dispatch<React.SetStateAction<number>>;
  shouldShowOnlyRetailerFilter?: boolean;
}

const styles = {
  root: {
    color: colors.darkBlue,
    width: '916px',
    minHeight: '963px'
  },
  sticky: {
    position: 'sticky',
    top: '0',
    zIndex: 1,
    backgroundColor: 'white'
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '22',
    height: '82.5px'
  },
  filterHeaderName: {
    width: '62px',
    height: '29px',
    fontFamily: 'Roboto',
    fontSize: '22px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#052849'
  },

  closeButton: {
    color: colors.red,
    width: 19,
    margin: '0 39px',
    marginTop: '-14px',
    height: 19,
    border: 'none',
    outline: 'none',
    background: 'none',
    padding: 0
  },
  clearButton: {
    margin: '0 39px',
    border: 'none',
    outline: 'none',
    background: 'none',
    width: '38px',
    height: '21px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#6cbaec'
  },
  noFilter: {
    color: 'white',
    margin: '0 39px',
    fontSize: '18px',
    border: 'none',
    outline: 'none',
    background: 'none',
    width: 40,
    height: 40,
    cursor: 'auto'
  },
  headerBoarder: {
    width: '100%',
    height: '1px',
    backgroundColor: '#dedede',
    margin: 0
  },
  footerBoarder: {
    width: '100%',
    height: '1px',
    backgroundColor: '#dedede',
    marginTop: '25px',
    marginBottom: 0
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '82.5px',
    position: 'sticky',
    zIndex: 1,
    bottom: '0',
    marginTop: '25px',
    borderTop: '1px solid #dedede',
    backgroundColor: 'white'
  },
  saveButton: {
    width: '162px',
    height: '37px',
    borderRadius: '23px',
    backgroundColor: colors.darkBlue,
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: colors.white,
    fontFamily: 'Roboto',
    letterSpacing: '0.84px',
    border: 0
  }
};

const paperStyle = {
  '.MuiDialog-paper::-webkit-scrollbar': {
    // backgroundColor: 'transparent'
  },
  '.MuiDialog-paper': {
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#eff1f5',
        width: '8px',
        borderRadius: '15px'
      },

      '&::-webkit-scrollbar-track': {
        backgroundColor: '#eff1f500'
      }
    }
  }
};

// based on filterParam, we check if the box should be checked or not.
const isChecked = (
  parsedFilterParams: any,
  keyMapping: string,
  currentNodeId: string | number,
  filterParam: string
) => {
  const encodedQueryString = new URLSearchParams(filterParam);
  const filterParamObj = JSON.parse(encodedQueryString.get('filter'));
  const checkedNodes = filterParamObj && filterParamObj[filterKeyMapping[keyMapping]];

  const checkedNode = checkedNodes ? checkedNodes.find(({ i }) => i === String(currentNodeId)) : undefined;
  return checkedNode !== undefined;
};

const OmniFilterPopUp: React.FC<OmniFilterPopUpProps> = ({
  open,
  onClose,
  location,
  history,
  eventBus,
  shouldShowSegmentFilter,
  shouldShowKeywordSegmentFilter = false,
  setBadgeNumber,
  shouldShowOnlyRetailerFilter = false
}) => {
  const { pathname } = location;
  const [entityType, entityId] = destructurePathName(pathname);
  const omniRegions = useSelector((state: ReduxStore) => state.omniRegionsFollowing);
  const omniCountries = useSelector((state: ReduxStore) => state.omniCountriesFollowing);
  const omniBrands = useSelector((state: ReduxStore) => state.omniBrandsFollowing);
  const omniRetailerState = useSelector((state: ReduxStore) => state.omniRetailers);
  const omniInstacartRetailerState = useSelector((state: ReduxStore) => state.omniInstacartRetailers);
  const omniCategories = useSelector((state: ReduxStore) => state.omniCategories);
  const omniSubcategories = useSelector((state: ReduxStore) => state.omniSubcategories);

  const [omniCountriesSuggestion, setOmniCountriesSuggestion] = useState(omniCountries);
  const [omniRetailersSuggestion, setOmniRetailersSuggestion] = useState(omniRetailerState.data);
  const [omniInstacartRetailersSuggestion, setOmniInstacartRetailersSuggestion] = useState(
    omniInstacartRetailerState ? omniInstacartRetailerState.data : []
  );
  const [omniBrandSuggestion, setOmniBrandSuggestion] = useState(omniBrands);
  const [omniCategorySuggestion, setOmniCategorySuggestion] = useState(omniCategories);
  const [omniSubCategorySuggestion, setOmniSubCategorySuggestion] = useState(omniSubcategories);
  // const omniKeywordCategoriesState = useSelector((state: ReduxStore) => state.omniKeywordCategoriesService);
  const [currentSelectedFilter, setCurrentSelectedFilter] = useState('');
  // const omniKeywordCategories = omniKeywordCategoriesState.data;

  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const app = useSelector((state: ReduxStore) => state.app);
  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const [filterParam, setFilterParam] = useState(queryParams.filter ? `&filter=${queryParams.filter}` : '&filter={}');
  const [prevFilterParam, setPrevFilterParam] = useState(queryParams.filter ? `&filter=${queryParams.filter}` : '');
  const segmentListData = useSelector((state: ReduxStore) => state.omniSegmentService);

  const { data: allSegmentData } = segmentListData;
  const { data: keywordListData } = useSelector((state: ReduxStore) => state.omniKeywordSegmentService);
  const { fetchOmniDynamicFilter } = omniDynamicFilterOperations;
  // if user clicks clear button and exit with X button, we will history.push with selected entity.
  const [clearClicked, setClearClicked] = useState(false);
  const omniRegionDynamicState = useSelector((state: ReduxStore) => state.omniDynamicFilters);
  const { data: dynamicFiltersValue } = omniRegionDynamicState;
  const [shouldShowInstacartFilter, setShouldShowInstacartFilter] = useState(false);
  const isInstacartRetailerPage = useInstacartPageFilter();
  const showInstacartRetailerFilters = isInstacartRetailerPage && queryParams.tab !== 'content';

  useEffect(() => {
    setShouldShowInstacartFilter(showInstacartRetailerFilters);
  }, [showInstacartRetailerFilters]);

  const [dynamicFilterApiBody, setDynamicFilterApiBody] = useState(updateDynamicFilterApiBody(entityType, entityId));

  const dispatch = useDispatch();

  const parsedFilterParams = useMemo(() => {
    // {"rg":[{"i":"NA"}],"co":[{"i":"US"}],"r":[{"i":"94"}],"b":[{"i":"617526"}]}
    return queryParams.filter && queryString.parse(JSON.parse(queryParams.filter));
  }, [queryParams.filter]);

  useEffect(() => {
    if (open) {
      dispatch(fetchOmniDynamicFilter(updateDynamicFilterApiBody(entityType, entityId)));
      const clonedQueryParam = _cloneDeep(queryParams);

      // const parsedFilterParam = queryString.parse(filterParam);
      if (clonedQueryParam.filter) {
        setFilterParam(`&filter=${clonedQueryParam.filter}`);
      } else {
        dispatch(fetchOmniDynamicFilter(updateDynamicFilterApiBody(entityType, entityId)));
        setFilterParam('');
      }
    }
  }, [dispatch, fetchOmniDynamicFilter, open]);

  useEffect(() => {
    // if filterParam does not available in  dynamicFilterValue. remove it and set new filter param.
    const parsedFilterParam = queryString.parse(filterParam);

    const filterInParsedFilterParam = parsedFilterParam.filter ? JSON.parse(parsedFilterParam.filter) : {};
    if (currentSelectedFilter === 'region') {
      // dynamic filter won't return all available return.. since it is the top level, we concat the retailer so, it will trigger filter
      const matchedFilterParam = JSON.stringify(
        matchingInParsedFilterParamWithDynamicFilter(filterInParsedFilterParam, {
          ...dynamicFiltersValue,
          regions: omniRegions
        })
      );

      if (Object.keys(dynamicFiltersValue).length > 0 && parsedFilterParam.filter && matchedFilterParam) {
        setFilterParam(`&filter=${matchedFilterParam}`);
      }

      setOmniCountriesSuggestion(dynamicFiltersValue.countries);
      setOmniRetailersSuggestion(dynamicFiltersValue.retailers);
      setOmniBrandSuggestion(dynamicFiltersValue.brands);
      setOmniCategorySuggestion(dynamicFiltersValue.categories);
      setOmniSubCategorySuggestion(dynamicFiltersValue.subCategories);
      setOmniInstacartRetailersSuggestion(dynamicFiltersValue.instacartRetailers);
    }

    if (currentSelectedFilter === 'country') {
      const matchedFilterParam = JSON.stringify(
        matchingInParsedFilterParamWithDynamicFilter(filterInParsedFilterParam, {
          ...dynamicFiltersValue,
          countries: omniCountriesSuggestion
        })
      );

      if (Object.keys(dynamicFiltersValue).length > 0 && parsedFilterParam.filter && matchedFilterParam) {
        setFilterParam(`&filter=${matchedFilterParam}`);
      }
      setOmniRetailersSuggestion(dynamicFiltersValue.retailers);
      setOmniBrandSuggestion(dynamicFiltersValue.brands);
      setOmniCategorySuggestion(dynamicFiltersValue.categories);
      setOmniSubCategorySuggestion(dynamicFiltersValue.subCategories);
      setOmniInstacartRetailersSuggestion(dynamicFiltersValue.instacartRetailers);
    }

    if (currentSelectedFilter === 'retailer') {
      const matchedFilterParam = JSON.stringify(
        matchingInParsedFilterParamWithDynamicFilter(filterInParsedFilterParam, {
          ...dynamicFiltersValue,
          retailers: omniRetailersSuggestion
        })
      );

      if (
        Object.keys(dynamicFiltersValue).length > 0 &&
        parsedFilterParam.filter &&
        matchedFilterParam &&
        omniRetailersSuggestion
      ) {
        setFilterParam(`&filter=${matchedFilterParam}`);
      }
      setOmniBrandSuggestion(dynamicFiltersValue.brands);
      setOmniCategorySuggestion(dynamicFiltersValue.categories);
      setOmniSubCategorySuggestion(dynamicFiltersValue.subCategories);
      setOmniInstacartRetailersSuggestion(dynamicFiltersValue.instacartRetailers);
    }

    if (currentSelectedFilter === 'brand') {
      const matchedFilterParam = JSON.stringify(
        matchingInParsedFilterParamWithDynamicFilter(filterInParsedFilterParam, {
          ...dynamicFiltersValue,
          brands: omniBrandSuggestion
        })
      );

      if (
        Object.keys(dynamicFiltersValue).length > 0 &&
        parsedFilterParam.filter &&
        matchedFilterParam &&
        omniBrandSuggestion
      ) {
        setFilterParam(`&filter=${matchedFilterParam}`);
      }
      setOmniCategorySuggestion(dynamicFiltersValue.categories);
      setOmniSubCategorySuggestion(dynamicFiltersValue.subCategories);
      setOmniInstacartRetailersSuggestion(dynamicFiltersValue.instacartRetailers);
    }

    if (currentSelectedFilter === 'category') {
      const matchedFilterParam = JSON.stringify(
        matchingInParsedFilterParamWithDynamicFilter(filterInParsedFilterParam, {
          ...dynamicFiltersValue,
          categories: omniCategorySuggestion
        })
      );

      if (
        Object.keys(dynamicFiltersValue).length > 0 &&
        parsedFilterParam.filter &&
        matchedFilterParam &&
        omniCategorySuggestion
      ) {
        setFilterParam(`&filter=${matchedFilterParam}`);
      }
      setOmniSubCategorySuggestion(dynamicFiltersValue.subCategories);
      setOmniInstacartRetailersSuggestion(dynamicFiltersValue.instacartRetailers);
    }
    if (currentSelectedFilter === 'instacartRetailer') {
      const matchedFilterParam = JSON.stringify(
        matchingInParsedFilterParamWithDynamicFilter(filterInParsedFilterParam, {
          ...dynamicFiltersValue,
          instacartRetailers: omniInstacartRetailersSuggestion
        })
      );

      if (
        Object.keys(dynamicFiltersValue).length > 0 &&
        parsedFilterParam.filter &&
        matchedFilterParam &&
        omniInstacartRetailersSuggestion
      ) {
        setFilterParam(`&filter=${matchedFilterParam}`);
      }
      setOmniBrandSuggestion(dynamicFiltersValue.brands);
      setOmniCategorySuggestion(dynamicFiltersValue.categories);
      setOmniSubCategorySuggestion(dynamicFiltersValue.subCategories);
    }
  }, [
    dynamicFiltersValue.countries,
    dynamicFiltersValue.retailers,
    dynamicFiltersValue.brands,
    dynamicFiltersValue.categories,
    dynamicFiltersValue.subCategories,
    dynamicFiltersValue.instacartRetailers,
    filterParam
  ]);

  useEffect(() => {
    dispatch(fetchOmniDynamicFilter(dynamicFilterApiBody));
  }, [dynamicFilterApiBody]);

  const onFilterChange = (filterValue: any, filterType: string) => {
    // onFilterChange is also triggering when user uncheck the box,
    if (!filterValue) {
      setClearClicked(false);
    }
    setPrevFilterParam(filterParam);
    let valueToArrayParser = null;

    // These filterTypes are radio buttons, so those are not iterative.
    if (!['keywordSegment', 'segment'].includes(filterType)) {
      valueToArrayParser = filterValue.map((value) => String(value.id));
    }

    const clonedQueryParam = _cloneDeep(queryParams);
    const parsedFilterParam = queryString.parse(filterParam);

    clonedQueryParam.filter = parsedFilterParam.filter;

    const { generatedFilterParams } = filterSelectors.generateFilterUrlString(
      filterValue,
      filterType,
      clonedQueryParam
    );

    switch (filterType) {
      case 'region':
        setDynamicFilterApiBody((prev) => ({
          ...prev,
          includeLocationRegionCode: valueToArrayParser,
          includeLocationCountryCode: [],
          retailerIds: [],
          includeBrandIds: [],
          includeCategoryIds: [],
          includeSubCategoryIds: []
        }));
        break;
      case 'country':
        setDynamicFilterApiBody((prev) => ({
          ...prev,
          includeLocationCountryCode: valueToArrayParser,
          retailerIds: [],
          includeBrandIds: [],
          includeCategoryIds: [],
          includeSubCategoryIds: []
        }));
        break;
      case 'retailer':
        setDynamicFilterApiBody((prev) => ({
          ...prev,
          retailerIds: valueToArrayParser.map((id) => +id),
          includeBrandIds: [],
          includeCategoryIds: [],
          includeSubCategoryIds: []
        }));
        break;
      case 'instacartRetailer':
        setDynamicFilterApiBody((prev) => ({
          ...prev,
          instacartRetailerIds: valueToArrayParser,
          includeBrandIds: [],
          includeCategoryIds: [],
          includeSubCategoryIds: []
        }));
        break;

      case 'brand':
        setDynamicFilterApiBody((prev) => ({
          ...prev,
          includeBrandIds: valueToArrayParser,
          includeCategoryIds: [],
          includeSubCategoryIds: []
        }));
        break;
      case 'category':
        setDynamicFilterApiBody((prev) => ({
          ...prev,
          includeCategoryIds: valueToArrayParser,
          includeSubCategoryIds: []
        }));
        break;
      default:
        break;
    }
    setFilterParam(generatedFilterParams || `&filter={}`);
    setCurrentSelectedFilter(filterType);
  };

  const handleClear = () => {
    setClearClicked(true);
    eventBus.emit('clearFilter', {});
    setFilterParam('&filter={}');
    dispatch(fetchOmniDynamicFilter(updateDynamicFilterApiBody(entityType, entityId)));
  };

  const onSaveChange = useCallback(() => {
    const { selectedEntityName } = queryParams;
    const { searchParams, additionalParams } = app.queryParams;

    const parsedFilterParam = queryString.parse(filterParam);
    let filterApplied = 0;

    if (parsedFilterParam.filter) {
      const filterObject = queryString.parse(JSON.parse(parsedFilterParam.filter));
      filterApplied = Object.keys(filterObject).length;
    }

    setBadgeNumber(filterApplied);

    history.push(
      selectedEntityName
        ? `${pathname}${searchParams}${additionalParams}&selectedEntityName=${selectedEntityName}${filterParam}`
        : `${pathname}${searchParams}${additionalParams}${filterParam}`
    );
    onClose();
  }, [queryParams, app.queryParams, filterParam, onClose, history, location]);

  const onCloseButtonClicked = () => {
    if (clearClicked) {
      const { selectedEntityName } = queryParams;
      const { searchParams, additionalParams } = app.queryParams;

      setBadgeNumber(0);
      history.push(
        selectedEntityName
          ? `${pathname}${searchParams}${additionalParams}&selectedEntityName=${selectedEntityName}`
          : `${pathname}${searchParams}${additionalParams}`
      );
    }
    onClose();
  };

  const regionFilters = useMemo(() => {
    return {
      name: 'Continents',
      mappingName: 'region',
      pluralName: 'Regions',
      originalOptions: omniRegions.map((region: { regionCode: string; regionName: string }) => {
        return {
          isChecked: false,
          id: region.regionCode,
          name: region.regionName
        };
      }),
      filterOptions: omniRegions.map((region: { regionCode: string; regionName: string }) => {
        return {
          isChecked: isChecked(parsedFilterParams, 'region', region.regionCode, filterParam),
          id: region.regionCode,
          name: region.regionName
        };
      })
    };
  }, [omniRegions, parsedFilterParams, filterParam, dynamicFiltersValue]);

  const countriesFilter = useMemo(() => {
    return {
      name: 'Countries',
      mappingName: 'country',
      pluralName: 'Countries',
      originalOptions: omniCountries.map((country: { countryCode: string; countryName: string }) => {
        return {
          isChecked: false,
          id: country.countryCode,
          name: country.countryName
        };
      }),

      filterOptions: omniCountriesSuggestion
        ? omniCountriesSuggestion.map((country: { countryCode: string; countryName: string }) => {
            return {
              isChecked: isChecked(parsedFilterParams, 'country', country.countryCode, filterParam),
              id: country.countryCode,
              name: country.countryName
            };
          })
        : []
    };
  }, [omniCountriesSuggestion, parsedFilterParams, filterParam, prevFilterParam, dynamicFiltersValue]);

  const retailerFilters = useMemo(() => {
    const retailerList = entityType === 'product' ? omniRetailerState.data : omniRetailersSuggestion;
    return {
      name: 'Retailers',
      mappingName: 'retailer',
      pluralName: 'Retailers',
      originalOptions: omniRetailerState.data.map((omniRetailer) => {
        let retailerName = '';
        const retailerConfig = retailer.availableRetailers.find(
          (retailerEntity: any) => Number(retailerEntity.id) === omniRetailer.retailerId
        );
        if (retailerConfig) {
          const { displayName } = retailerConfig;
          retailerName = displayName;
        }
        return {
          isChecked: false,
          id: omniRetailer.retailerId,
          name: retailerName
        };
      }),
      filterOptions: retailerList
        ? retailerList.map((omniRetailer) => {
            let retailerName = '';
            const retailerConfig = retailer.availableRetailers.find(
              (retailerEntity: any) => Number(retailerEntity.id) === omniRetailer.retailerId
            );
            if (retailerConfig) {
              const { displayName } = retailerConfig;
              retailerName = displayName;
            }
            return {
              isChecked: isChecked(parsedFilterParams, 'retailer', omniRetailer.retailerId, filterParam),
              id: omniRetailer.retailerId,
              name: retailerName
            };
          })
        : []
    };
  }, [omniRetailerState.data, omniRetailersSuggestion, retailer.availableRetailers, parsedFilterParams, filterParam]);

  const brandFilters = useMemo(() => {
    return {
      name: 'Brands',
      mappingName: 'brand',
      pluralName: 'Brands',
      originalOptions: omniBrands.map((brand: { brandId: number; brandName: string }) => {
        return {
          isChecked: false,
          id: brand.brandId,
          name: brand.brandName
        };
      }),
      filterOptions: omniBrandSuggestion
        ? omniBrandSuggestion.map((brand: { brandId: string; brandName: string }) => {
            return {
              isChecked: isChecked(parsedFilterParams, 'brand', brand.brandId, filterParam),
              id: brand.brandId,
              name: brand.brandName
            };
          })
        : []
    };
  }, [filterParam, omniBrandSuggestion, omniBrands, parsedFilterParams]);

  const categoryFilters = useMemo(() => {
    return {
      name: 'Categories',
      mappingName: 'category',
      pluralName: 'Categories',
      originalOptions: omniCategories.map((category: { categoryId: any; categoryName: string }) => ({
        isChecked: false,
        id: category.categoryId,
        name: category.categoryName
      })),
      filterOptions: omniCategorySuggestion
        ? omniCategorySuggestion.map((category: { categoryId: string; categoryName: string }) => {
            return {
              isChecked: isChecked(parsedFilterParams, 'category', category.categoryId, filterParam),
              id: category.categoryId,
              name: category.categoryName
            };
          })
        : []
    };
  }, [filterParam, omniCategories, omniCategorySuggestion, parsedFilterParams]);

  const subcategoryFilters = useMemo(() => {
    return {
      name: 'Subcategories',
      mappingName: 'subcategory',
      pluralName: 'Subcategories',
      originalOptions: omniSubcategories.map((subcategory) => ({
        isChecked: false,
        id: subcategory.subCategoryId,
        name: subcategory.subCategoryName
      })),
      filterOptions: omniSubCategorySuggestion
        ? omniSubCategorySuggestion.map((subcategory: { subCategoryId: string; subCategoryName: string }) => {
            return {
              isChecked: isChecked(parsedFilterParams, 'subcategory', subcategory.subCategoryId, filterParam),
              id: subcategory.subCategoryId,
              name: subcategory.subCategoryName
            };
          })
        : []
    };
  }, [filterParam, omniSubCategorySuggestion, omniSubcategories, parsedFilterParams]);

  const segmentFilters = useMemo(() => {
    return {
      name: 'Segments',
      mappingName: 'segment',
      pluralName: 'Segments',
      originalOptions: allSegmentData.map((segmentObj) => ({
        isChecked: false,
        id: segmentObj.queryId,
        name: segmentObj.name
      })),
      filterOptions: allSegmentData.map((segmentObj) => ({
        isChecked: isChecked(parsedFilterParams, 'segment', segmentObj.queryId, filterParam),
        id: segmentObj.queryId,
        name: segmentObj.name
      }))
    };
  }, [allSegmentData, filterParam, parsedFilterParams]);

  const keyWordSegmentFilters = useMemo(() => {
    return {
      name: 'Keywords',
      mappingName: 'keywordSegment',
      pluralName: 'Keywords Segments',
      originalOptions: keywordListData.map((segmentObj) => ({
        isChecked: false,
        id: segmentObj.queryId,
        name: segmentObj.name
      })),
      filterOptions: keywordListData.map((segmentObj) => ({
        isChecked: isChecked(parsedFilterParams, 'keywordSegment', segmentObj.queryId, filterParam),
        id: segmentObj.queryId,
        name: segmentObj.name
      }))
    };
  }, [filterParam, keywordListData, parsedFilterParams]);

  // Keyword category is temp disabled for data issue as of Apr 6th.

  // const keywordCategoriesFilters = useMemo(() => {
  //   return {
  //     name: 'Categories',
  //     mappingName: 'keywordCategories',
  //     pluralName: 'Categories',
  //     originalOptions: omniKeywordCategories.map(({ keywordCategoryId, keywordCategoryName }) => ({
  //       isChecked: false,
  //       id: keywordCategoryId,
  //       name: keywordCategoryName
  //     })),
  //     filterOptions: omniKeywordCategories.map(({ keywordCategoryId, keywordCategoryName }) => ({
  //       isChecked: isChecked(parsedFilterParams, 'keywordCategories', keywordCategoryId, filterParam),
  //       id: keywordCategoryId,
  //       name: keywordCategoryName
  //     }))
  //   };
  // }, [omniKeywordCategories, parsedFilterParams]);

  // As of Mar 14, 2023, content score and review is not supporting filter for region and country.
  // This requires crawl pipeline and publishing change. until then, we disable those filters visible on the tab

  const instacartRetailerFilter = useMemo(() => {
    const instacartRetailerList = entityType === 'retailer' ? omniInstacartRetailersSuggestion : [];
    return {
      name: 'Instacart Retailers',
      mappingName: 'instacartRetailer',
      pluralName: 'Instacart Retailers',
      originalOptions: omniInstacartRetailerState.data.map((omniRetailer) => {
        let retailerName = '';
        const retailerConfig = retailer.availableRetailers.find(
          (retailerEntity: any) => retailerEntity.id === omniRetailer.instacartRetailerId
        );
        if (retailerConfig) {
          const { displayName } = retailerConfig;
          retailerName = displayName;
        }
        return {
          isChecked: false,
          id: omniRetailer.instacartRetailerId,
          name: retailerName
        };
      }),
      filterOptions: instacartRetailerList
        ? instacartRetailerList.map((omniInstacartRetailer) => {
            let retailerName = '';
            const retailerConfig = retailer.availableRetailers.find(
              (retailerEntity: any) => retailerEntity.id === omniInstacartRetailer.instacartRetailerId
            );
            if (retailerConfig) {
              const { displayName } = retailerConfig;
              retailerName = displayName;
            }
            return {
              isChecked: isChecked(
                parsedFilterParams,
                'instacartRetailer',
                omniInstacartRetailer.instacartRetailerId,
                filterParam
              ),
              id: omniInstacartRetailer.instacartRetailerId,
              name: retailerName
            };
          })
        : []
    };
  }, [
    omniInstacartRetailerState.data,
    omniInstacartRetailersSuggestion,
    retailer.availableRetailers,
    parsedFilterParams,
    filterParam
  ]);

  let filterConfigs = [
    ...(shouldShowInstacartFilter ? [instacartRetailerFilter] : []),
    ...(omniRegions && omniRegions.length > 1 ? [regionFilters] : []),
    ...(omniCountries && omniCountries.length > 1 ? [countriesFilter] : []),
    retailerFilters,
    brandFilters,
    categoryFilters,
    subcategoryFilters
  ];

  filterConfigs = filterConfigs.filter((ele) => ele.mappingName !== entityType);

  // only showing the retailer in filter when we are in the retailer page
  if (entityType === 'product') {
    filterConfigs = [retailerFilters];
  }
  // in retailer entity, region and countries filters are already filtered
  if (entityType === 'retailer') {
    filterConfigs = filterConfigs.filter((ele) => {
      return !['region', 'country'].includes(ele.mappingName);
    });
  }

  if (shouldShowOnlyRetailerFilter) {
    filterConfigs = [retailerFilters];
  }

  let notShowBaseFilter = false;

  if (queryParams.tab === 'shareOfShelf' && entityType === 'retailer') {
    if (shouldShowInstacartFilter) {
      filterConfigs = [instacartRetailerFilter];
      notShowBaseFilter = false;
    } else {
      notShowBaseFilter = true;
    }
  }

  if (queryParams.tab === 'shareOfShelf') {
    filterConfigs = filterConfigs.filter((ele) => {
      return ele.name !== 'Categories' && ele.name !== 'Subcategories';
    });
  }

  return (
    <Dialog open={open} onClose={onClose} sx={paperStyle}>
      <div style={styles.root}>
        <div style={styles.sticky}>
          <div style={styles.header}>
            <CloseButton onClick={onCloseButtonClicked} style={styles.closeButton} />
            <h1 style={styles.filterHeaderName}>Filter</h1>

            <button
              style={!filterParam || filterParam === '&filter={}' ? styles.noFilter : styles.clearButton}
              onClick={handleClear}
              disabled={!filterParam || filterParam === '&filter={}'}
            >
              Clear
            </button>
          </div>

          <hr className="omni-filter-header-hr" style={styles.headerBoarder}></hr>
        </div>
        {!notShowBaseFilter &&
          filterConfigs.map((filterConfig, index) => (
            <OmniFilterSection
              key={filterConfig.name}
              // we have another index in the OmniFilterSection, so we named it order
              order={index}
              filterConfig={filterConfig}
              currentSelectedFilter={currentSelectedFilter}
              onFilterChange={onFilterChange}
            />
          ))}
        {shouldShowSegmentFilter && (
          <OmniFilterRadioSelection filterConfig={segmentFilters} onFilterChange={onFilterChange} />
        )}
        {shouldShowKeywordSegmentFilter && (
          <OmniFilterRadioSelection filterConfig={keyWordSegmentFilters} onFilterChange={onFilterChange} />
        )}
        <div style={styles.footer}>
          {/* <hr style={styles.footerBoarder}></hr> */}
          <button style={styles.saveButton} onClick={onSaveChange}>
            Save
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default withRouter(withBus('eventBus')(OmniFilterPopUp));
