import { UseMutationOptions, useMutation } from 'react-query';
import { PublishBulkAdjustmentPayload } from '../serverProxy/types';
import useServerProxy from './useServerProxy';

/**
 * Publish a bulk adjustment. Must poll for its status after
 * calling this function in order to know if it has been successfully
 * published
 */
export default function usePublishBulkAdjustment(
  options: UseMutationOptions<unknown, unknown, PublishBulkAdjustmentPayload> = {}
) {
  const { publishBulkAdjustment } = useServerProxy();
  return useMutation(publishBulkAdjustment, options);
}
