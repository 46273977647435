import React from 'react';
import { connect } from 'react-redux';
import { withBus } from 'react-bus';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'qs';
import axios, { CancelTokenSource } from 'axios';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';

// data store
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { entityOperations } from '../../store/modules/entityService';

// layout
import { getLayoutForEntity } from 'src/components/Layout/EntityPageLayout';

// rendering
import {
  buildDefaultConditions,
  buildEntityConditions,
  buildMainEntityConditions
} from './Renderer/EntityPageRenderer';

// import Loading from '../common/Loading';
import { Conditions } from 'sl-api-connector/types';
import ReduxStore from 'src/types/store/reduxStore';
import { PageLayout, Widget } from 'src/types/application/widgetTypes';
import { ActivatedActionCreators, EventBus } from 'src/types/utils';
import CompareInner from 'src/components/EntityPage/Compare/Compare';

const Compare = CompareInner as any;

const { CancelToken } = axios;

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['app', 'retailer', 'user', 'categories', 'filters', 'entityService']);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics,
  fetchEntity: entityOperations.fetchEntity,
  setEntity: entityOperations.setEntity
};

type GenericPageContainerProps = {
  conditions?: Conditions;
  pageLayout?: PageLayout;
  aggregationConditions?: Conditions;
} & RouteComponentProps & { eventBus: EventBus } & ReturnType<typeof mapStateToProps> &
  ActivatedActionCreators<typeof mapDispatchToProps>;

interface GenericPageContainerState {
  isLoading: boolean;
  pageLayout: PageLayout | undefined;
  comparisonConfig: unknown | undefined;
  queryParams: { [key: string]: any } | undefined;
}

/**
 * `GenericPageContainer` is a container component into which a widget is rendered.  It handles waiting until necessary
 * application state has been initialized before rendering it, and then creates the widget's `CustomComponent`s and
 * passes in a variety of props from Redux and other sources.
 *
 * A **widget** is a definition of a component of the application.  The idea is that the pages of the application are
 * defined in terms of sets of widgets and configuration for them.  Depending on things like the current URL (route),
 * query parameters, and user configuration, the application generates up a layout for the current view.  This layout
 * is defined in files like `src/components/Layout/AtlasPageLayout.js`.
 *
 * Once the layout has been computed, its widgets are passed off into page containers like this one.
 */
class GenericPageContainer extends React.Component<GenericPageContainerProps, GenericPageContainerState> {
  public static defaultProps = {
    conditions: { termFilters: [], rangeFilters: [] },
    aggregationConditions: { termFilters: [], rangeFilters: [] },
    pageLayout: undefined
  };

  public state: GenericPageContainerState = {
    isLoading: true,
    pageLayout: undefined,
    comparisonConfig: undefined,
    queryParams: undefined
  };

  private source: CancelTokenSource | undefined = undefined;

  public componentDidMount() {
    this.source = CancelToken.source();
    this.buildCommonProps(this.props);
  }

  public componentWillReceiveProps(nextProps: GenericPageContainerProps) {
    const { location, entityService, filters, conditions } = nextProps;
    if (
      location.pathname !== this.props.location.pathname ||
      location.search !== this.props.location.search ||
      !_isEqual(this.props.pageLayout, nextProps.pageLayout) ||
      !_isEqual(this.props.conditions, conditions) ||
      !_isEqual(this.props.entityService.mainEntity, entityService.mainEntity) ||
      !_isEqual(this.props.filters, filters)
    ) {
      this.buildCommonProps(nextProps);
    }
  }

  public componentWillUnmount() {
    if (this.source) {
      this.source.cancel('Cancel network request GPC');
    }
  }

  private buildCommonProps(props: GenericPageContainerProps) {
    const { app, entityService, retailer, user, location, match, pageLayout: explicitPageLayout, eventBus } = props;
    const { mainEntity } = entityService;
    const { id } = match.params as any;

    // Fix: ADBUGS-413. Without this we don't have platformType from api for some reason.
    if (retailer && mainEntity && retailer.platformType && !mainEntity.platformType) {
      mainEntity.platformType = retailer.platformType;
    }

    if (app && !_isNil(id) && mainEntity) {
      const mainEntityId = mainEntity && `${mainEntity.id || mainEntity.stacklineSku}`; // in case id is missing
      // the main entity has to match with what is in the current url
      // Note: hashId not always presented as well
      const comparisonEntityId =
        id === mainEntityId
          ? mainEntityId
          : id === mainEntity.stacklineSku
          ? mainEntity.stacklineSku
          : _get(mainEntity, 'hashId');
      if (!app || !mainEntity || id !== comparisonEntityId) {
        this.setState({ isLoading: true });
        return;
      }
    } else if (!app || !mainEntity) {
      this.setState({ isLoading: true });
      return;
    }

    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    if (!queryParams.tab) {
      queryParams.tab = app.defaultQueryParams.tab;
    }
    if (!queryParams.subtab) {
      queryParams.subtab = app.defaultQueryParams.subtab;
    }
    const { tab, subtab } = queryParams;
    const newPageLayout =
      explicitPageLayout ||
      getLayoutForEntity({
        app,
        retailer,
        user,
        tab,
        metricType: subtab,
        entity: mainEntity,
        pageType: tab === 'summary' ? 'summaryPage' : 'entityPage',
        eventBus
      });

    const comparisonConfig = {
      type: queryParams.ctype,
      indexName: queryParams.ctab || tab,
      metricName: queryParams.csubtab || subtab,
      entityId: queryParams.cid,
      entityType: queryParams.ctype
    };

    this.setState({
      pageLayout: newPageLayout,
      queryParams,
      comparisonConfig,
      isLoading: false
    });
  }

  public render() {
    const {
      entityService,
      conditions: filterConditions,
      app,
      categories,
      retailer,
      aggregationConditions
    } = this.props;
    const { isLoading, pageLayout, comparisonConfig, queryParams } = this.state;
    const { mainEntity } = entityService;
    if (
      isLoading ||
      !pageLayout ||
      !mainEntity ||
      // eslint-disable-next-line no-use-before-define
      !(_get(pageLayout, 'CustomPageContainer') === EnhancedGenericPageContainer)
    ) {
      // we should should less Loading animation in our app, because they are floting at different level,
      // return null here instead of <Loading /> because Loading should be showing at the upper level (EntityPage)
      return null;
    }

    const defaultConditions = buildDefaultConditions(filterConditions, queryParams);
    const entityConditions = buildEntityConditions(app, categories, defaultConditions, mainEntity);
    const mainEntityConditions = buildMainEntityConditions(entityConditions, mainEntity, app, retailer);

    // pageLayout!.widgets[1].data.widgets = pageLayout!.widgets[1].data.widgets.slice(0, 2);

    return (
      <div className="entity-page-container">
        {pageLayout.enableComparison ? (
          <Compare
            entity={entityService.mainEntity as any}
            conditions={entityConditions}
            aggregationConditions={aggregationConditions}
          />
        ) : null}
        <div style={{ display: 'flex', ...(pageLayout!.containerStyle || {}) }}>
          {pageLayout!.widgets.map((widget: Widget, index: number) => {
            const { data, view, CustomComponent } = widget;
            return (
              <React.Fragment key={index}>
                {CustomComponent ? (
                  <CustomComponent
                    dataConfig={pageLayout!.dataConfig}
                    key={`entitypagecontainer_${view.name}_${index}`}
                    uniqueName={`entitypagecontainer_${view.name}_${index}`}
                    className={view.className}
                    conditions={filterConditions}
                    entityConditions={entityConditions}
                    entity={mainEntity}
                    selectedEntity={data ? data.entity : undefined}
                    queryConditions={mainEntityConditions}
                    aggregationConditions={aggregationConditions}
                    comparisonConfig={comparisonConfig}
                    queryParams={queryParams}
                    widget={widget}
                    pageLayout={pageLayout}
                  />
                ) : null}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }
}

const EnhancedGenericPageContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withBus('eventBus')(GenericPageContainer))
);

export default EnhancedGenericPageContainer;
