import _get from 'lodash/get';
import _merge from 'lodash/merge';

import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import {
  mkManualSortHCF,
  mkPercentChangeCRF,
  mkValueChangeCRF,
  mkActionCellFormatter,
  WaterfallDefaultHeaderComponentFramework,
  WaterfallNonSortableHeaderComponentFramework,
  WaterfallValueCellFormatter,
  viewKeywordInApp,
  WaterfallFirstColumnHCF,
  withManualBrHeader
} from '../CellRendererFrameworks';
import { buildWidgetUniqueName } from 'src/components/EntityPage/Widget';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import EntityGrid, { buildEntityGridDataKey } from 'src/components/EntityGrid/EntityGrid/EntityGrid';
import mkFeaturedProductsDialogButtons from 'src/components/EntityPage/WaterfallChart/Insights/FeaturedProductsDialogButtons';
import { NumberUnitsCRF } from 'src/components/AdCampaignBuilder/Widgets/TargetEntitiesGrid/GridFrameworks';
import MetricsImpactGrid from '../MetricsImpact/MetricsImpactGrid';
import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';

const BASE_ACTION_CELL_DEFINITION = {
  name: 'action',
  displayName: 'Action',
  entity: {},
  dataType: null,
  metricType: null,
  headerComponentFramework: WaterfallNonSortableHeaderComponentFramework
};

const buildOrganicTrafficActionColumns = ({ app }) => [
  {
    ...BASE_ACTION_CELL_DEFINITION,
    cellRendererFramework: mkActionCellFormatter(
      [
        {
          label: 'View in Keyword List',
          onClick: (data) => viewKeywordInApp({ name: 'beacon', stage: app.stage }, data)
        },
        { label: 'View in Atlas', onClick: (data) => viewKeywordInApp({ name: 'atlas', stage: app.stage }, data) }
      ],
      { position: 'relative' },
      '',
      true
    ),
    cellStyle: { 'text-align': 'right', 'flex-direction': 'row-reverse' }
  }
];

const buildMarketOrganicTrafficColumnDefinitions = ({ app, indexName }) => [
  {
    ...INDEX_FIELDS.getField(app.name, indexName, 'organicClicks', 'product', 'stacklineSku'),
    displayName: 'Market Organic|Traffic',
    name: 'market_organicClicks_by_searchTerm_current',
    cellRendererFramework: WaterfallValueCellFormatter,
    headerComponentFramework: withManualBrHeader(mkManualSortHCF('waterfallInsightsSortData')),
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  },
  {
    ...INDEX_FIELDS.getField(app.name, indexName, 'organicClicks', 'product', 'stacklineSku'),
    displayName: 'Market Organic|Traffic (Change)',
    name: 'market_organicClicks_by_searchTerm_change',
    cellRendererFramework: mkPercentChangeCRF(
      'market_organicClicks_by_searchTerm_change',
      'market_organicClicks_by_searchTerm_changePercent'
    ),
    headerComponentFramework: withManualBrHeader(mkManualSortHCF('waterfallInsightsSortData')),
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  }
];

/**
 * These fields are used to actually render the data into the grid.  They include a custom `cellRendererFramework`
 * that provides custom rendering logic for the individual cells.
 *
 * We can also provide fully custom columns here to display data other than what is fetched from the API, or containing
 * custom displays of the fetched data.
 */
const buildOrganicColumnDefinitions = ({ app, indexName }) => {
  return [
    ...buildMarketOrganicTrafficColumnDefinitions({ app, indexName }),
    {
      ...INDEX_FIELDS.getField(app.name, indexName, 'organicClicks', 'product', 'stacklineSku'),
      cellRendererFramework: WaterfallValueCellFormatter,
      name: 'organicClicks_by_searchTerm_current',
      headerComponentFramework: mkManualSortHCF('waterfallInsightsSortData')
    },
    {
      ...INDEX_FIELDS.getField(app.name, indexName, 'organicClicks', 'product', 'stacklineSku'),
      cellRendererFramework: mkPercentChangeCRF(
        'organicClicks_by_searchTerm_change',
        'organicClicks_by_searchTerm_changePercent'
      ),
      name: 'organicClicks_by_searchTerm_change',
      displayName: 'Organic Traffic|(Change)',
      headerComponentFramework: withManualBrHeader(mkManualSortHCF('waterfallInsightsSortData')),
      cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
    },
    {
      name: 'featuredProducts',
      displayName: 'Featured Products',
      entity: {},
      dataType: null,
      metricType: null,
      cellRendererFramework: mkValueChangeCRF(
        'stacklineSku_by_searchTerm_current',
        'stacklineSku_by_searchTerm_change'
      ),
      headerComponentFramework: WaterfallNonSortableHeaderComponentFramework,
      cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
    },
    ...buildOrganicTrafficActionColumns({ app })
  ];
};

const getFeaturedProductsGridWidget = ({ app, indexName }) => {
  const widgetName = 'waterfallFeaturedProductsGrid';

  const requestAggregationFields = [
    INDEX_FIELDS.getField(app.name, indexName, 'organicClicks', 'product', 'stacklineSku')
  ];

  const columnRendererFields = [
    {
      ...INDEX_FIELDS.getField(app.name, indexName, 'organicClicks', 'product', 'stacklineSku'),
      cellRendererFramework: WaterfallValueCellFormatter,
      headerComponentFramework: WaterfallDefaultHeaderComponentFramework
    },
    {
      ...INDEX_FIELDS.getField(app.name, indexName, 'organicClicks', 'product', 'stacklineSku'),
      cellRendererFramework: mkPercentChangeCRF('cardView.organicClicksPeriodChange', 'organicClicksPercentChange'),
      displayName: 'Organic Traffic Change',
      headerComponentFramework: WaterfallNonSortableHeaderComponentFramework
    }
  ];

  const featuredProductsUniqueName = buildWidgetUniqueName(widgetName);
  const featuredProductsDataKey = buildEntityGridDataKey(featuredProductsUniqueName);
  const DialogButtonsComponent = mkFeaturedProductsDialogButtons(featuredProductsDataKey);

  return {
    CustomComponent: EntityGrid,
    view: _merge({}, VIEWS.entityGrid, {
      gridOptions: {
        enableGroupBy: false,
        defaultLayout: 'table',
        pageSize: 10e8, // There should never be a huge number of these, so we just fetch them all.
        disableMetricFormatting: true,
        filterRows: (rowDatum) => {
          // Filter out rows that have no title, meaning that there is no metadata and that they're likely a 3P product
          // not included in the current client's set of owned products
          if (!_get(rowDatum, ['entity', 'title'])) {
            return false;
          }

          // Filter out rows that have no organic traffic in either the main or comparison time period
          // const mainTimePeriodValue = rowDatum.
          const { organicClicksCurrentValue, organicClicksPreviousValue } = rowDatum.cardView || {};
          if (!organicClicksCurrentValue && !organicClicksPreviousValue) {
            return false;
          }

          return true;
        }
      },
      DialogButtonsComponent,
      name: widgetName,
      title: 'Featured Products',
      getTextContent: (selectedEntity, name) =>
        `These products are receiving traffic from the entity "${name || selectedEntity}"`
    }),
    name: widgetName,
    data: {
      indexName,
      // These group by fields are used as the groups into which the metrics under `gridAggregationFields` are grouped.
      // Switching the group by field on the dropdown will change which of these are used to group by.
      groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
      // These config values are used conditionally depending on the current group-by field.  The `name` attribute of
      // the group by field is used to pick which one of these configs is used.
      configByGroupByFieldName: {
        stacklineSku: {
          indexName,
          entity: ENTITIES.beacon.product,
          mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'organicClicks', 'product', 'stacklineSku'),
          aggregationFields: requestAggregationFields,
          tableView: {
            metricFields: columnRendererFields
          }
        }
      }
    }
  };
};

export const getSelectedCampaignsGridWidget = (
  { app, indexName },
  getTextContent = (selectedEntity, name) => `These campaigns are included in "${name || selectedEntity}"`
) => {
  const widgetName = 'waterfallSelectedCampaignsGrid';

  const campaignIdField = {
    ...INDEX_FIELDS.getField(app.name, indexName, 'campaignId', 'campaign'),
    displayName: 'Campaign'
  };

  const unitsSoldField = INDEX_FIELDS.getField(app.name, indexName, 'unitsSold', 'campaign', 'campaignId');

  const requestAggregationFields = [unitsSoldField];

  const columnRendererFields = [
    {
      ...unitsSoldField,
      cellRendererFramework: WaterfallValueCellFormatter
    },
    {
      ...unitsSoldField,
      cellRendererFramework: mkPercentChangeCRF('cardView.unitsSoldCurrentValue', 'cardView.unitsSoldPercentChange'),
      displayName: 'Ad Units Sold Change'
    }
  ].map((column) => ({ ...column, headerComponentFramework: WaterfallNonSortableHeaderComponentFramework }));

  return {
    CustomComponent: EntityGrid,
    view: _merge({}, VIEWS.entityGrid, {
      gridOptions: {
        enableGroupBy: false,
        defaultLayout: 'table',
        pageSize: 10e8, // There should never be a huge number of these, so we just fetch them all.
        filterRows: (rowDatum) => !!Object.entries(rowDatum).find(([key, val]) => key.includes('value') && !!val),
        computePerRowAggregatedMetrics: true,
        disableMetricFormatting: true
      },
      name: widgetName,
      title: 'Featured Campaigns',
      getTextContent
    }),
    name: widgetName,
    data: {
      indexName,
      groupByFields: [campaignIdField],
      configByGroupByFieldName: {
        campaignId: {
          indexName,
          entity: ENTITIES.beacon.campaign,
          mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'unitsSold', 'campaign', 'campaignId'),
          aggregationFields: requestAggregationFields,
          tableView: {
            metricFields: columnRendererFields
          }
        }
      }
    }
  };
};

const ALL_ADVERTISING_FIELDS = [
  'impressions',
  'clicks',
  'spend',
  'costPerClick',
  'returnOnAdSpend',
  'sales',
  'conversionRate',
  'costPerAcquisition',
  'adCostOfSales'
];

export const PAID_TRAFFIC_CAMPAIGNS_COLUMN = {
  ...INDEX_FIELDS.getField('beacon', 'advertising', 'campaignId'),
  headerName: 'Campaign(s)',
  displayName: 'Campaign(s)',
  field: 'campaignId',
  cellStyle: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  headerComponentFramework: WaterfallFirstColumnHCF,
  cellRendererFramework: mkValueChangeCRF('campaignId_by_searchKeyword_current', 'campaignId_by_searchKeyword_change'),
  minWidth: 100,
  maxWidth: 110
};

const columnDisplayNameOverrides = {
  costPerClick: 'CPC',
  costPerAcquisition: 'CPA',
  clicks: 'Ad Clicks',
  returnOnAdSpend: 'ROAS'
};

const buildPaidTrafficActionColumns = ({ app }) => [
  {
    ...BASE_ACTION_CELL_DEFINITION,
    cellRendererFramework: mkActionCellFormatter(
      [
        {
          label: 'View in Keyword List',
          onClick: (data) => viewKeywordInApp({ name: 'beacon', stage: app.stage }, data)
        },
        { label: 'View in Atlas', onClick: (data) => viewKeywordInApp({ name: 'atlas', stage: app.stage }, data) }
      ],
      { position: 'relative' },
      '',
      true
    )
  }
];

const buildPaidColumnDefinitions = ({ app, indexName, retailer }) => {
  const columnDefinitions = [
    // We have a custom first column that isn't actually the group by field, so we have to manually create a column for
    // the group by field here
    {
      ...INDEX_FIELDS.getField(app.name, indexName, 'searchKeyword', 'product'),
      name: 'name',
      displayName: 'Keyword',
      headerComponentFramework: WaterfallFirstColumnHCF
    },
    {
      ...INDEX_FIELDS.getField(app.name, indexName, 'unitsSold', 'product'),
      name: 'unitsSold_by_searchKeyword_current',
      headerComponentFramework: mkManualSortHCF('waterfallInsightsSortData'),
      valueFormatter: NumberUnitsCRF
    },
    {
      ...INDEX_FIELDS.getField(app.name, indexName, 'unitsSold', 'product'),
      name: 'unitsSold_by_searchKeyword_change',
      displayName: 'Ad Units Sold Change',
      headerComponentFramework: mkManualSortHCF('waterfallInsightsSortData'),
      cellRendererFramework: mkPercentChangeCRF(
        'unitsSold_by_searchKeyword_change',
        'unitsSold_by_searchKeyword_changePercent'
      )
    },
    // Create a column and a %change column for each of the selected chart metrics
    ...ALL_ADVERTISING_FIELDS.map((fieldName) => {
      const field = INDEX_FIELDS.getField(app.name, indexName, fieldName, 'product', 'searchKeyword');

      const displayNameOverride = columnDisplayNameOverrides[fieldName];

      return {
        ...field,
        cellRendererFramework: mkPercentChangeCRF(
          `${field.name}_by_searchKeyword_current`,
          `${field.name}_by_searchKeyword_changePercent`,
          (periodChange) =>
            convertMetricToDisplayValue(retailer, periodChange, field.metricType, retailer.currencySymbol, true)
        ),
        name: `${field.name}_by_searchKeyword_current`,
        ...(displayNameOverride ? { displayName: displayNameOverride } : {}),
        swappable: true
      };
    }),
    // Add in the final "Action" column
    ...buildPaidTrafficActionColumns({ app })
  ];
  return columnDefinitions;
};

const buildMarketPaidTrafficColumnDefinitions = ({ app, indexName }) => [
  {
    ...INDEX_FIELDS.getField(app.name, indexName, 'adClicks', 'product', 'stacklineSku'),
    displayName: 'Market Paid|Traffic (Current)',
    name: 'market_adClicks_by_searchTerm_current',
    cellRendererFramework: WaterfallValueCellFormatter,
    headerComponentFramework: withManualBrHeader(mkManualSortHCF('waterfallInsightsSortData')),
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  },
  {
    ...INDEX_FIELDS.getField(app.name, indexName, 'adClicks', 'product', 'stacklineSku'),
    displayName: 'Market Paid|Traffic (Prior)',
    name: 'market_adClicks_by_searchTerm_previous',
    cellRendererFramework: WaterfallValueCellFormatter,
    headerComponentFramework: withManualBrHeader(mkManualSortHCF('waterfallInsightsSortData')),
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  },
  {
    ...INDEX_FIELDS.getField(app.name, indexName, 'adClicks', 'product', 'stacklineSku'),
    displayName: 'Market Paid|Traffic (Change)',
    name: 'market_adClicks_by_searchTerm_change',
    cellRendererFramework: mkPercentChangeCRF(
      'market_adClicks_by_searchTerm_change',
      'market_adClicks_by_searchTerm_changePercent'
    ),
    headerComponentFramework: withManualBrHeader(mkManualSortHCF('waterfallInsightsSortData')),
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  }
];

export const buildWidgetConfig = ({ app, entity, retailer }) => {
  const indexName = 'traffic';
  const marketOrganicColumnDefinitions = [
    ...buildMarketOrganicTrafficColumnDefinitions({ app, indexName }),
    // Add in the final "Action" column
    ...buildOrganicTrafficActionColumns({ app })
  ];
  const marketPaidColumnDefinitions = [
    ...buildMarketPaidTrafficColumnDefinitions({ app, indexName }),
    // Add in the final "Action" column
    ...buildPaidTrafficActionColumns({ app })
  ];
  const organicColumnDefinitions = buildOrganicColumnDefinitions({ app, indexName });
  const paidColumnDefinitions = buildPaidColumnDefinitions({
    app,
    retailer,
    indexName: 'advertising',
    marketIndexName: 'traffic'
  });
  const searchTermField = {
    ...INDEX_FIELDS.getField(app.name, 'traffic', 'searchTerm', entity.type),
    displayName: 'Keyword'
  };
  const stacklineSkuField = {
    ...INDEX_FIELDS.getField(app.name, 'traffic', 'stacklineSku', entity.type)
  };
  const searchKeywordField = {
    ...INDEX_FIELDS.getField(app.name, 'advertising', 'searchKeyword', 'product'),
    displayName: 'Keyword'
  };
  const advertisingFields = ['campaignId', ...ALL_ADVERTISING_FIELDS].map((fieldName) =>
    INDEX_FIELDS.getField(app.name, 'advertising', fieldName, 'product', 'searchKeyword')
  );
  const defaultActiveFieldNames = ['clicks', 'spend', 'sales', 'returnOnAdSpend', 'costPerClick'].map(
    (fieldName) => `${fieldName}_by_searchKeyword_current`
  );

  return {
    widgetConfigByMetric: {
      trafficOrganicClicks: {
        view: {
          title: 'Organic Traffic'
        },
        groupByFields: [{ ...searchTermField, isSelected: true }, stacklineSkuField],
        widgetConfigByGroupByField: {
          searchTerm: {
            type: 'TrafficInsights',
            name: 'OrganicTrafficInsights',
            view: {
              title: 'Organic Clicks',
              tabs: [
                {
                  name: 'increasing',
                  displayName: 'Increasing(...)',
                  displayNamePrefix: 'Increasing',
                  gridFirstColumnDefinition: { headerComponentFramework: WaterfallFirstColumnHCF },
                  gridMetricFields: organicColumnDefinitions,
                  defaultSortField: {
                    name: 'organicClicks_by_searchTerm_change',
                    direction: 'desc'
                  }
                },
                {
                  name: 'decreasing',
                  displayName: 'Decreasing(...)',
                  displayNamePrefix: 'Decreasing',
                  gridFirstColumnDefinition: { headerComponentFramework: WaterfallFirstColumnHCF },
                  gridMetricFields: organicColumnDefinitions,
                  defaultSortField: {
                    name: 'organicClicks_by_searchTerm_change',
                    direction: 'asc'
                  }
                },
                {
                  name: 'opportunity',
                  displayName: 'Opportunity(...)',
                  gridFirstColumnDefinition: { headerComponentFramework: WaterfallFirstColumnHCF },
                  displayNamePrefix: 'Opportunity',
                  disableGroupBy: true,
                  gridMetricFields: marketOrganicColumnDefinitions,
                  defaultSortField: {
                    name: 'market_organicClicks_by_searchTerm_change',
                    direction: 'desc'
                  }
                }
              ]
            },
            data: {
              marketShareConfig: {
                prefix: 'market_',
                mainMetricFieldName: 'organicClicks',
                mainMetricGroupByFieldName: 'searchTerm',
                marketShareMetricFieldName: 'organicClicks',
                marketShareMetricGroupByFieldName: 'searchTerm'
              },
              weekIdField: INDEX_FIELDS.getField(app.name, indexName, 'weekId', entity.type),
              groupByFields: [{ ...searchTermField }],
              configByGroupByFieldName: {
                searchTerm: [
                  {
                    indexName,
                    entity,
                    entityQueryConditions: {
                      rangeFilters: [{ fieldName: 'organicClicks', minValue: 1 }]
                    },
                    aggregationFields: [
                      INDEX_FIELDS.getField(app.name, indexName, 'organicClicks', entity.type, searchTermField.name),
                      INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', entity.type, searchTermField.name)
                    ]
                  },
                  {
                    indexName,
                    indexNameOverride: `${indexName}-all`,
                    entity,
                    aggregationFields: [
                      {
                        ...INDEX_FIELDS.getField(
                          app.name,
                          indexName,
                          'organicClicks',
                          entity.type,
                          searchTermField.name
                        ),
                        displayName: 'Category Organic Traffic'
                      }
                    ],
                    isMarketShare: true
                  }
                ]
              }
            },
            childWidgets: {
              featuredEntitiesGridWidget: getFeaturedProductsGridWidget({ app, indexName })
            },
            enableFilters: {
              enableCampaign: false,
              enableRange: false
            }
          }
        }
      },
      trafficAdClicks: {
        view: {
          title: 'Paid Traffic'
        },
        groupByFields: [{ ...searchKeywordField, isSelected: true }, stacklineSkuField],
        widgetConfigByGroupByField: {
          searchKeyword: {
            type: 'TrafficInsights',
            name: 'PaidTrafficInsights',
            view: {
              title: 'Paid Traffic',
              tabs: [
                {
                  name: 'increasing',
                  displayName: 'Increasing(...)',
                  displayNamePrefix: 'Increasing',
                  gridFirstColumnDefinition: PAID_TRAFFIC_CAMPAIGNS_COLUMN,
                  gridMetricFields: paidColumnDefinitions,
                  defaultActiveFieldNames,
                  defaultSortField: {
                    name: 'unitsSold_by_searchKeyword_change',
                    direction: 'desc'
                  }
                },
                {
                  name: 'decreasing',
                  displayName: 'Decreasing(...)',
                  displayNamePrefix: 'Decreasing',
                  gridFirstColumnDefinition: PAID_TRAFFIC_CAMPAIGNS_COLUMN,
                  gridMetricFields: paidColumnDefinitions,
                  defaultActiveFieldNames,
                  defaultSortField: {
                    name: 'unitsSold_by_searchKeyword_change',
                    direction: 'asc'
                  }
                },
                {
                  name: 'opportunity',
                  displayName: 'Opportunity(...)',
                  displayNamePrefix: 'Opportunity',
                  gridFirstColumnDefinition: { headerComponentFramework: WaterfallFirstColumnHCF },
                  disableGroupBy: true,
                  gridMetricFields: marketPaidColumnDefinitions,
                  defaultSortField: {
                    name: 'market_adClicks_by_searchTerm_current',
                    direction: 'desc'
                  }
                }
              ]
            },
            data: {
              marketShareConfig: {
                prefix: 'market_',
                mainMetricFieldName: 'clicks',
                mainMetricGroupByFieldName: 'searchKeyword',
                marketShareMetricFieldName: 'adClicks',
                marketShareMetricGroupByFieldName: 'searchTerm'
              },
              weekIdField: INDEX_FIELDS.getField(app.name, indexName, 'weekId', entity.type),
              groupByFields: [{ ...searchKeywordField }, { ...searchTermField }],
              configByGroupByFieldName: {
                searchKeyword: [
                  {
                    indexName: 'advertising',
                    entity,
                    aggregationFields: advertisingFields
                  }
                ],
                searchTerm: [
                  {
                    indexName,
                    indexNameOverride: `${indexName}-all`,
                    entity,
                    aggregationFields: [
                      {
                        ...INDEX_FIELDS.getField(app.name, indexName, 'adClicks', entity.type, searchTermField.name),
                        displayName: 'Market Paid Traffic'
                      }
                    ],
                    isMarketShare: true
                  }
                ]
              }
            },
            childWidgets: {
              featuredEntitiesGridWidget: getSelectedCampaignsGridWidget({ app, indexName: 'advertising' })
            },
            enableFilters: {
              enableCampaign: true,
              enableRange: true
            }
          }
        }
      },
      defaultWidget: {
        CustomComponent: MetricsImpactGrid,
        view: VIEWS.entityGrid
      }
    }
  };
};
