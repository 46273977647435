/* eslint-disable react/prop-types */
import React from 'react';
import Reclassify from 'src/components/Reclassify';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';

const ReclassifyHeader: React.FC<{
  handleSelectAllCheck: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  isSelectAllChecked: boolean;
  showOnlyBreadcrumbMismatches: boolean;
  toggleShowOnlyBreadcrumbMismatches: () => void;
  products: any[];
  reclassifyRequest: any[];
  showPriceData: boolean;
  toggleShowPriceData: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleSortDirectionChange: () => void;
  sortDirection: 'asc' | 'desc';
  hideAlreadyClassifiedEntities: boolean;
  toggleHideAlreadyClassifiedEntities: () => void;
  clearAllSelections: () => void;
  numSelectedItems: number;
}> = ({
  handleSelectAllCheck,
  isSelectAllChecked,
  showPriceData,
  showOnlyBreadcrumbMismatches,
  toggleShowOnlyBreadcrumbMismatches,
  toggleShowPriceData,
  products,
  reclassifyRequest,
  handleSortDirectionChange,
  sortDirection,
  hideAlreadyClassifiedEntities,
  toggleHideAlreadyClassifiedEntities,
  clearAllSelections,
  numSelectedItems
}) => (
  <>
    <div className="grid-header" style={{ paddingTop: '0px', paddingRight: '10px', marginTop: '-3px' }}>
      <div className="grid-header__title" style={{ flex: 2 }}>
        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>Reclassify Products</div>
        <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '20px' }}>
          <FormControlLabel
            control={
              <Checkbox
                id="select-all-checkbox"
                checked={isSelectAllChecked}
                onChange={(e) => handleSelectAllCheck(e)}
                value="selectAll"
                color="primary"
              />
            }
            label="Select All"
          />
        </div>

        <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '12px' }}>
          <FormControlLabel
            control={
              <Checkbox
                id="select-all-checkbox"
                checked={showPriceData}
                onChange={toggleShowPriceData}
                value="selectAll"
                color="primary"
              />
            }
            label="Show Price Data"
          />
        </div>

        <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '12px' }}>
          <FormControlLabel
            onClick={() => handleSortDirectionChange()}
            control={sortDirection === 'desc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
            label="Sales ($)"
          />
        </div>

        <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '12px' }}>
          <FormControlLabel
            control={
              <Checkbox
                id="select-all-checkbox"
                checked={showOnlyBreadcrumbMismatches}
                onChange={toggleShowOnlyBreadcrumbMismatches}
                value="selectAll"
                color="primary"
              />
            }
            label="Show Only Breadcrumb Mismatches"
          />
        </div>

        <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '12px' }}>
          <FormControlLabel
            control={
              <Checkbox
                id="select-all-checkbox"
                checked={hideAlreadyClassifiedEntities}
                onChange={toggleHideAlreadyClassifiedEntities}
                value="selectAll"
                color="primary"
              />
            }
            label="Hide Already Classified Products"
          />
        </div>
        <div>
          <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '12px' }}>
            <p style={{ fontSize: '15px' }}>Selected items : {numSelectedItems}</p>
          </div>
          <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '12px' }}>
            <LargeMuiButton secondary style={{ minHeight: 20 }} onClick={clearAllSelections}>
              <p style={{ color: 'Black' }}>Clear All Selections</p>
            </LargeMuiButton>
          </div>
        </div>
      </div>

      <div className="grid-header__icon-menu">
        <Reclassify
          className="reclassify--inline"
          isVertical={false}
          products={products}
          reclassifyRequest={reclassifyRequest}
        />
      </div>
    </div>
    <hr className="sl-divider sl-divider--no-margin-top" />
  </>
);

export default ReclassifyHeader;
