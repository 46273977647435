/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import SubmitScreen from 'src/components/AdManager/SubmitScreen/SubmitScreen';

import ReduxStore from 'src/types/store/reduxStore';
import { SectionWrapper } from 'src/components/AdCampaignBuilder/Widgets/AdCampaignBuilderCommonWidgets';

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, ['adBudgetEditor'])
  };
};

const Submit: React.FC<ReturnType<typeof mapStateToProps>> = ({ adBudgetEditor }) => {
  const respond = _get(adBudgetEditor, ['apiResponse']);
  return (
    <div className="ad-manager-container">
      <SectionWrapper header="Submitted" subheader="Your request has been submitted." layer={0}>
        <div className="submitted">
          <SubmitScreen respondResult={respond} />
        </div>
      </SectionWrapper>
    </div>
  );
};

export default connect(mapStateToProps)(Submit);
