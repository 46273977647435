import { Parser } from 'expr-eval';

import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

import { ADVERTISING_METRICS_FIELDS } from 'src/utils/entityDefinitions/fields/beaconAdvertisingFieldDefinitions';

export const PRODUCT_WEEKLY_METRICS_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  impressions: ADVERTISING_METRICS_FIELDS.impressions,
  clicks: ADVERTISING_METRICS_FIELDS.clicks,
  conversionRate: ADVERTISING_METRICS_FIELDS.conversionRate,
  adSpend: {
    name: 'adSpend',
    displayName: 'Ad Spend',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  adSales: {
    name: 'adSales',
    displayName: 'Ad Sales',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  adUnitsSold: {
    name: 'adUnitsSold',
    displayName: 'Paid Ad Units Sold',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  asp: {
    // seems this is same as retailPrice
    name: 'asp',
    displayName: 'Avg Sell Price',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold>0?retailSales/unitsSold:0')
  },
  cpa: {
    name: 'cpa',
    displayName: 'Cost per Acquisition',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold>0?adSpend/unitsSold:0')
  },
  cpc: {
    name: 'cpc',
    displayName: 'Cost per Click',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('clicks>0?adSpend/clicks:0')
  },
  ctr: {
    // click Through Rate
    name: 'ctr',
    displayName: 'CTR %',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('impressions>0?clicks/impressions:0')
  },
  roas: {
    name: 'roas',
    displayName: 'ROAS', // 'Return on Ad Spend',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('adSpend>0?adSales/adSpend:0')
  },
  buyBoxWins: {
    name: 'buyBoxWins',
    displayName: 'Buy Box - Rate',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum'
  },
  buyBoxWinsByClient: {
    name: 'buyBoxWinsByClient',
    displayName: 'Buy Box - Client',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum'
  },
  buyBoxWinsBySeller: {
    name: 'buyBoxWinsBySeller',
    displayName: 'Buy Box - Seller',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum'
  },
  buyBoxWinPercentage: {
    name: 'buyBoxWinPercentage',
    displayName: 'Buy Box - Percentage',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('buyBoxWins>0?(buyBoxWinsByClient/buyBoxWins):1'),
    timePeriodAggregationFunctionType: 'simple',
    timePeriodAggregationFunction: 'avg'
  },
  trafficSearchVolume: {
    name: 'trafficSearchVolume',
    displayName: 'Impressions',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum',
    canBeExported: false
  },
  trafficOrganicClicks: {
    name: 'trafficOrganicClicks',
    displayName: 'Organic Traffic',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  trafficTotalClicks: {
    name: 'trafficTotalClicks',
    displayName: 'Total Traffic',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  trafficAdClicks: {
    name: 'trafficAdClicks',
    displayName: 'Paid Traffic',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum',
    canBeExported: false
  },
  trafficOtherClicks: {
    name: 'trafficOtherClicks',
    displayName: 'Other Traffic',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  trafficOtherUnitsSold: {
    name: 'trafficOtherUnitsSold',
    displayName: 'Other Units Sold',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  avgStars: {
    name: 'avgStars',
    displayName: 'Avg. Star Rating',
    metricType: METRICTYPE.DECIMAL,
    aggregationFunction: 'stats',
    dataType: DATATYPE.DECIMAL,
    overrides: {
      weekId_Override: {
        timePeriodAggregationFunctionType: 'trueAvg',
        timePeriodAggregationFunction: 'avg'
      }
    }
  },
  dataPointCount: {
    name: 'dataPointCount',
    displayName: 'Data Points',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  }
};
