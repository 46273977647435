/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';
import _pick from 'lodash/pick';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import StyledComponentWithPlaceHolder from 'src/components/common/Form/WrapWithPlaceholder';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DayPickerSingleDateController, isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';

import InfoTooltip from 'src/components/AdCampaignBuilder/Widgets/InfoTooltip';
import { store } from 'src/main';
import { adPlatformSettingsByClientOperations } from 'src/store/modules/adManager/adPlatformSettingsByClient';
import { AdCampaignBuilderRadio } from 'src/components/AdCampaignBuilder/Widgets/Checkbox';
import { AD_BUDGET_TYPE } from 'sl-ad-campaign-manager-data-model';
import { portfolioBudgetTypes } from 'src/components/Layout/Advertising/AdManagerPageLayout/AdManagerSetting';
import CalendarClearButton from 'src/components/common/DatePicker/CalendarClearButton';
import { ChevronIcon } from 'src/components/SvgIcons';
import './CreatePortfolio.scss';
import Picker from 'react-month-picker';
import { yearMonthToMoment } from 'src/utils/dateUtils';
import MonthPicker from 'src/components/common/DatePicker/MonthPicker';
import { UTC_TIMEZONE } from 'src/utils/constants';

export const muiStyles = {
  root: {
    margin: '0 0 0 0',
    fontSize: '16px',
    width: 350
  },
  selectRoot: {
    margin: 0,
    width: 300,
    maxWidth: 300
  },
  select: {
    padding: '7px 27px 7px 0px',
    width: 300,
    maxWidth: 300
  },
  dateRoot: {
    margin: '0 0 0 0',
    fontSize: '16px',
    width: 110
  }
};

interface CreatePortfolioForm {
  classes: { [key: string]: any };
  user: any;
  onCancel: Function;
  onSubmit: Function;
  isSubmitting: boolean;
  portfolioName: string;
  setPortfolioName: Function;
  selectedPlatform: any;
  setSelectPlatform?: Function;
  adPlatforms: any[];
  selectedEntity: any;
  setSelectEntity?: Function;
  adEntities?: any[];
  budgetType: any;
  handleBudgetTypeChange: Function;
  budgetAmount: number | null;
  setBudgetAmount: Function;
  startDate: any | null;
  setStartDate: Function;
  startDatePickerOpen: boolean;
  setStartDatePickerOpen: Function;
  startDateLimit: Function;
  endDate: any | null;
  setEndDate: Function;
  setEndDatePickerOpen: Function;
  endDatePickerOpen: boolean;
  endDateLimit: Function;
  adStrategies: any[];
  selectedStrategy: any;
  handleStrategyChange: Function;
  enableSelect: boolean;
  retailer: ReduxStore['retailer'];
}

const CreatePortfolioForm: React.FC<CreatePortfolioForm> = ({
  classes,
  onCancel,
  onSubmit,
  isSubmitting,
  portfolioName,
  setPortfolioName,
  selectedPlatform,
  setSelectPlatform,
  adPlatforms,
  selectedEntity,
  setSelectEntity,
  adEntities,
  budgetType,
  handleBudgetTypeChange,
  budgetAmount,
  setBudgetAmount,
  startDate,
  setStartDate,
  startDatePickerOpen,
  setStartDatePickerOpen,
  startDateLimit,
  endDate,
  setEndDate,
  setEndDatePickerOpen,
  endDatePickerOpen,
  endDateLimit,
  adStrategies,
  selectedStrategy,
  handleStrategyChange,
  enableSelect,
  retailer
}) => {
  const monthPickerRef = useRef<Picker>(null);
  const isMonthlyRecurring = budgetType.name === AD_BUDGET_TYPE.MONTHLY_RECURRING;

  const getInitEndMonth = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const isLastMonthOfYear = month === 12;
    return [
      {
        year,
        month
      },
      {
        year: isLastMonthOfYear ? year + 1 : year,
        month: isLastMonthOfYear ? 1 : month + 1
      }
    ];
  };

  const [initEndMonth, defaultEndMonth] = getInitEndMonth();

  const enableSubmit = () => {
    if ([AD_BUDGET_TYPE.DATE_RANGE, AD_BUDGET_TYPE.MONTHLY_RECURRING].includes(budgetType.name)) {
      if (!budgetAmount) {
        return false;
      }
    }

    // If no budget, can only be manual
    const compatibleStrategy = budgetType.name === 'noBudget' ? selectedStrategy.id === 'Manual' : true;

    return (
      !isSubmitting &&
      portfolioName &&
      selectedPlatform &&
      selectedPlatform.name &&
      selectedEntity &&
      selectedEntity.name &&
      selectedStrategy &&
      selectedStrategy.id &&
      compatibleStrategy
    );
  };

  const showPicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      setEndDatePickerOpen(true);
      monthPickerRef.current.show();
    }
  };

  return (
    <div className="create_portfolio_container">
      <div className="header underline">
        <div>Create Portfolio</div>
        <div>
          <LargeMuiButton
            secondary
            style={{ width: 88, height: 35, marginLeft: 0, marginRight: 10 }}
            label="Cancel"
            onClick={onCancel}
          />
          <LargeMuiButton
            disabled={!enableSubmit()}
            style={{ width: 88, height: 35, marginRight: 0 }}
            label="Submit"
            onClick={onSubmit}
          />
        </div>
      </div>
      <div className="setting_container">
        <div className="setting_row">
          <div className="label">Portfolio Name:</div>
          <div>
            <TextField
              variant="standard"
              autoComplete="off"
              id="outlined-bare"
              classes={{ root: classes.root }}
              placeholder="Portfolio Name"
              value={portfolioName}
              InputProps={{ classes: { root: classes.root } }}
              onChange={({ target: { value } }) => setPortfolioName(value)}
              margin="normal"
            />
          </div>
        </div>

        <div className="setting_row">
          <div className="label">Platform:</div>
          {enableSelect ? (
            <div className="select-wrapper">
              <StyledComponentWithPlaceHolder valueToCheck={selectedPlatform.id} placeholder="Platform Type">
                <Select
                  variant="standard"
                  style={{ marginRight: 20 }}
                  value={selectedPlatform ? selectedPlatform.id : null}
                  onChange={({ target: { value } }) => setSelectPlatform(value)}
                  classes={{ root: classes.selectRoot, select: classes.select }}
                  IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
                >
                  {adPlatforms.map((opt) => (
                    <MenuItem key={opt.id} value={opt.id}>
                      {opt.name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledComponentWithPlaceHolder>
            </div>
          ) : (
            <div>{selectedPlatform.name}</div>
          )}
        </div>
        <div className="setting_row">
          <div className="label">Entity:</div>
          {enableSelect ? (
            <div className="select-wrapper">
              <StyledComponentWithPlaceHolder valueToCheck={selectedEntity.id} placeholder="Entity Name">
                <Select
                  variant="standard"
                  style={{ marginRight: 20 }}
                  value={selectedEntity ? selectedEntity.id : null}
                  onChange={({ target: { value } }) => {
                    setSelectEntity(value);
                  }}
                  classes={{ root: classes.selectRoot, select: classes.select }}
                  IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
                >
                  {adEntities.map((opt) => (
                    <MenuItem key={opt.id} value={opt.id}>
                      {opt.name} (ID: {opt.id})
                    </MenuItem>
                  ))}
                </Select>
              </StyledComponentWithPlaceHolder>
            </div>
          ) : (
            <div>{selectedEntity.name}</div>
          )}
        </div>
        <div className="setting_row">
          <div className="label">Budget Type:</div>
          <div>
            <Select
              variant="standard"
              style={{ marginRight: 20 }}
              value={budgetType.name || null}
              onChange={({ target: { value } }) => {
                handleBudgetTypeChange(value);
                setEndDate(null);
              }}
              classes={{ root: classes.selectRoot, select: classes.select }}
              IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
            >
              {portfolioBudgetTypes.map((opt, idx) => (
                <MenuItem key={idx} value={opt.name}>
                  {opt.displayName}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className="setting_row">
          <div className="label">{budgetType.fieldName}:</div>
          {budgetType.name !== 'noBudget' ? (
            <div>
              <NumberFormat
                value={budgetAmount}
                thousandSeparator
                prefix={retailer.currencySymbol}
                decimalScale={2}
                fixedDecimalScale
                allowNegative={false}
                onValueChange={({ floatValue }) => setBudgetAmount(floatValue)}
                // Mimic the style of the MUI `TextInput`
                style={{
                  height: '32px',
                  outline: 'none',
                  border: '0',
                  borderBottomColor: 'currentcolor',
                  borderBottomStyle: 'none',
                  borderBottomWidth: '0px',
                  borderBottom: '1px solid #eee',
                  width: '100px',
                  fontSize: '16px',
                  color: 'currentColor',
                  fontFamily: 'Roboto'
                }}
              />
            </div>
          ) : (
            'No Budget Cap'
          )}
          {isMonthlyRecurring ? (
            <span>
              restarts on the 1<sup>st</sup> of every month
            </span>
          ) : null}
        </div>
        <div className="setting_row">
          <div className="label">Schedule:</div>
          {budgetType.name !== 'noBudget' ? (
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
              {budgetType.name === AD_BUDGET_TYPE.DATE_RANGE && (
                <div className="datepicker_container">
                  {`Budget starts on `}&nbsp;
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    classes={{ root: classes.dateRoot }}
                    InputProps={{ classes: { root: classes.dateRoot } }}
                    value={startDate ? moment(startDate).format('MM/DD/YYYY') : 'No Start Date'}
                    id="outlined-bare"
                    onClick={() => setStartDatePickerOpen(!startDatePickerOpen)}
                    margin="normal"
                  />
                  {startDatePickerOpen && (
                    <div style={{ position: 'absolute', zIndex: 1 }}>
                      <DayPickerSingleDateController
                        hideKeyboardShortcutsPanel
                        monthFormat="MMMM YYYY"
                        date={startDate} // momentPropTypes.momentObj or null
                        onDateChange={(date) => {
                          setStartDate(date);
                        }} // PropTypes.func.isRequired
                        focused
                        isOutsideRange={startDateLimit}
                        onFocusChange={() => {}} // PropTypes.func.isRequired
                        onOutsideClick={() => setStartDatePickerOpen(!startDatePickerOpen)}
                        id="adPortfolioSettingStartDatePicker"
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="datepicker_container">
                {isMonthlyRecurring ? (
                  <p>
                    <span>Recurring budget ends on&nbsp;</span>
                  </p>
                ) : (
                  <span> and ends on&nbsp;</span>
                )}
                <TextField
                  variant="standard"
                  autoComplete="off"
                  classes={{ root: classes.dateRoot }}
                  InputProps={{ classes: { root: classes.dateRoot } }}
                  value={endDate ? moment(endDate).format('MM/DD/YYYY') : 'No End Date'}
                  id="outlined-bare"
                  onClick={() => (isMonthlyRecurring ? showPicker() : setEndDatePickerOpen(!endDatePickerOpen))}
                  margin="normal"
                />
                {!isMonthlyRecurring ? (
                  endDatePickerOpen && (
                    <div style={{ position: 'absolute', zIndex: 1 }}>
                      <DayPickerSingleDateController
                        hideKeyboardShortcutsPanel
                        monthFormat="MMMM YYYY"
                        date={endDate} // momentPropTypes.momentObj or null
                        onDateChange={(date) => {
                          setEndDate(date);
                        }} // PropTypes.func.isRequired
                        focused
                        isOutsideRange={endDateLimit}
                        onFocusChange={() => {}} // PropTypes.func.isRequired
                        onOutsideClick={() => setEndDatePickerOpen(!endDatePickerOpen)}
                        renderCalendarInfo={() => (
                          <CalendarClearButton
                            hasEndDate={!!endDate}
                            handleClear={() => {
                              setEndDate(null);
                              handleStrategyChange('Manual');
                            }}
                          />
                        )}
                        id="adPortfolioSettingEndDatePicker"
                      />
                    </div>
                  )
                ) : (
                  <MonthPicker
                    endDate={endDate || yearMonthToMoment(defaultEndMonth.year, defaultEndMonth.month)}
                    setEndDate={(val) => setEndDate(val)}
                    monthPickerRef={monthPickerRef}
                    calendarRange={{
                      min: initEndMonth,
                      max: { year: initEndMonth.year + 4, month: initEndMonth.month } // four years in future
                    }}
                    onDismiss={() => setEndDatePickerOpen(false)}
                    hasEndDate={endDatePickerOpen}
                    containerClass="createPortfolio"
                  />
                )}
              </div>
            </div>
          ) : (
            'No starting or ending dates are needed for portfolios with no budget cap'
          )}
        </div>
        <div className="setting_row" style={{ marginBottom: 0 }}>
          <div className="label">Optimization:</div>
          <div>
            <div>Select your strategy below</div>
          </div>
        </div>
        <div className="setting_row" style={{ marginTop: 0 }}>
          <div className="label"></div>
          <div className="radio-group">
            <RadioGroup
              aria-label="strategy"
              name="strategy"
              value={selectedStrategy.id}
              style={{ paddingLeft: '5px' }}
            >
              {adStrategies.map(({ id: strategyId, extendedAttributes }) => {
                const shouldDisable = !(
                  strategyId === 'Manual' ||
                  ([AD_BUDGET_TYPE.DATE_RANGE].includes(budgetType.name) && endDate && budgetAmount) ||
                  ([AD_BUDGET_TYPE.MONTHLY_RECURRING].includes(budgetType.name) && budgetAmount)
                );
                return (
                  <div
                    key={strategyId}
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <FormControlLabel
                      value={strategyId}
                      control={
                        <AdCampaignBuilderRadio
                          disableRipple
                          style={{ padding: 9, marginLeft: -18 }}
                          checked={selectedStrategy.id === strategyId}
                          onClick={() => {
                            if (!shouldDisable) {
                              handleStrategyChange(strategyId);
                            }
                          }}
                          value={strategyId}
                        />
                      }
                      disabled={shouldDisable}
                      label={
                        <InfoTooltip customNode={<label className="radio-label">{extendedAttributes.name}</label>}>
                          <span className="info"> {extendedAttributes.description || ''}</span>
                        </InfoTooltip>
                      }
                    />
                  </div>
                );
              })}
            </RadioGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToPropsForPortfolio = (state: ReduxStore) => ({
  ..._pick(state, ['adPlatforms', 'adEntities', 'adPlatformSettings', 'adCampaignBuilder', 'retailer']),
  mainEntity: state.entityService.mainEntity
});

const CreatePortfolioMainInner: React.FC<{
  classes: { [key: string]: any };
  open: boolean;
  onClose: () => void;
  adPlatforms: ReduxStore['adPlatforms'];
  adEntities: ReduxStore['adEntities'];
  adPlatformSettings: ReduxStore['adPlatformSettings'];
  retailer: ReduxStore['retailer'];
}> = ({ adPlatforms, adEntities, adPlatformSettings, retailer, classes, open, onClose }) => {
  const [portfolioName, setPortfolioName] = useState('');
  const [selectedPlatform, setPlatform] = useState({});
  const [selectedEntity, setEntity] = useState({});
  const [selectedStrategy, setStrategy] = useState({});
  const [budgetType, setBudgetType] = useState({});
  const [budgetAmount, setBudgetAmount] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const adStrategies = adPlatformSettings.filter((item) => item.settingType === 'campaignStrategy');

  const initState = () => {
    setPortfolioName('');
    setPlatform({});
    setEntity({});
    setStrategy({});
    setBudgetType(portfolioBudgetTypes[0]);
    setBudgetAmount(null);
    setStartDate(null);
    setEndDate(null);
    setStartDatePickerOpen(false);
    setEndDatePickerOpen(false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (budgetType.name === 'noBudget') {
      const newStrategy = adStrategies.find((item) => item.id === 'Manual');
      if (newStrategy) {
        setStrategy(newStrategy);
      }
    }
  }, [budgetType, adStrategies]);

  useEffect(() => {
    initState();
  }, []);

  useEffect(() => {
    initState();
  }, [open]);

  const setSelectPlatform = (id: string | number) => {
    const newPlatform = adPlatforms.find((platform) => platform.id === id);
    if (newPlatform) {
      setPlatform(newPlatform);
    }
  };

  const setSelectEntity = (id: string | number) => {
    const newEntity = adEntities.find((entity) => entity.id === id);
    if (newEntity) {
      setEntity(newEntity);
    }
  };

  const handleBudgetTypeChange = (typeName) => {
    const newBudgetType = portfolioBudgetTypes.find((item) => item.name === typeName);
    if (typeName === AD_BUDGET_TYPE.DATE_RANGE && !startDate) {
      setStartDate(moment().tz(retailer.processInTimezone || UTC_TIMEZONE));
    }
    if (newBudgetType) {
      setBudgetType(newBudgetType);
    }
  };

  const handleStrategyChange = (strategyId: string) => {
    const newStrategy = adStrategies.find((item) => item.id === strategyId);
    if (newStrategy) {
      setStrategy(newStrategy);
    }
  };

  const startDateLimit = (day) => {
    const todayDate = moment().tz(retailer.processInTimezone || UTC_TIMEZONE);

    if (!endDate) {
      // if endDate not set, use could set startDate to be any day after today.
      return !isInclusivelyAfterDay(day, todayDate);
    } else {
      // if endDate set, use could set startDate to be any day between today and endDate.
      return !isInclusivelyAfterDay(day, todayDate) || !isInclusivelyBeforeDay(day, endDate);
    }
  };

  const endDateLimit = (day) => {
    const todayDate = moment().tz(retailer.processInTimezone || UTC_TIMEZONE);

    let latestDate = todayDate;
    if (budgetType.name === AD_BUDGET_TYPE.DATE_RANGE && todayDate.isBefore(moment(startDate))) {
      latestDate = moment(startDate);
    }
    // disable all dates before today
    if (!isInclusivelyAfterDay(day, latestDate)) {
      return true;
    }

    return false;
  };

  const formatAndSubmit = async () => {
    if (!isSubmitting) {
      const formState = {
        name: portfolioName,
        budgetAmount,
        budgetType,
        strategy: selectedStrategy,
        platform: selectedPlatform,
        entity: selectedEntity,
        startDate: startDate ? startDate.tz(retailer.processInTimezone || UTC_TIMEZONE) : startDate,
        endDate: endDate ? endDate.tz(retailer.processInTimezone || UTC_TIMEZONE) : endDate
      };
      setIsSubmitting(true);
      try {
        await store.dispatch(adPlatformSettingsByClientOperations.createPortfolio(formState));
        onClose();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const filterPlatforms = adPlatforms.filter(
    (platform) => `${platform.extendedAttributes.retailerId}` === `${retailer.id}`
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <CreatePortfolioForm
        classes={classes}
        onCancel={onClose}
        onSubmit={formatAndSubmit}
        isSubmitting={isSubmitting}
        portfolioName={portfolioName}
        setPortfolioName={setPortfolioName}
        selectedPlatform={selectedPlatform}
        setSelectPlatform={setSelectPlatform}
        adPlatforms={filterPlatforms}
        selectedEntity={selectedEntity}
        setSelectEntity={setSelectEntity}
        adEntities={adEntities}
        budgetType={budgetType}
        handleBudgetTypeChange={handleBudgetTypeChange}
        budgetAmount={budgetAmount}
        setBudgetAmount={setBudgetAmount}
        startDate={startDate}
        setStartDate={setStartDate}
        startDatePickerOpen={startDatePickerOpen}
        setStartDatePickerOpen={setStartDatePickerOpen}
        startDateLimit={startDateLimit}
        endDate={endDate}
        setEndDate={setEndDate}
        setEndDatePickerOpen={setEndDatePickerOpen}
        endDatePickerOpen={endDatePickerOpen}
        endDateLimit={endDateLimit}
        adStrategies={adStrategies}
        selectedStrategy={selectedStrategy}
        handleStrategyChange={handleStrategyChange}
        enableSelect
        retailer={retailer}
      />
    </Dialog>
  );
};

export const CreatePortfolioMain = connect(mapStateToPropsForPortfolio)(
  withStyles(muiStyles)(CreatePortfolioMainInner)
);

const CreatePortfolioAdBuilderInner: React.FC<{
  classes: { [key: string]: any };
  open: boolean;
  onClose: () => void;
}> = ({ adPlatforms, adCampaignBuilder, retailer, adPlatformSettings, classes, open, onClose }) => {
  const [portfolioName, setPortfolioName] = useState('');
  const [selectedPlatform, setPlatform] = useState({});
  const [selectedEntity, setEntity] = useState({});
  const [selectedStrategy, setStrategy] = useState({});
  const [budgetType, setBudgetType] = useState({});
  const [budgetAmount, setBudgetAmount] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const adStrategies = adPlatformSettings.filter((item) => item.settingType === 'campaignStrategy');

  const initState = () => {
    const initPlatform = adPlatforms.find((item) => adCampaignBuilder.platformId === item.id);
    const initEntity = adCampaignBuilder.platformSettings.entity;
    setPortfolioName('');
    setPlatform(initPlatform);
    setEntity(initEntity);
    setStrategy({});
    setBudgetType(portfolioBudgetTypes[0]);
    setBudgetAmount(null);
    setStartDate(null);
    setEndDate(null);
    setStartDatePickerOpen(false);
    setEndDatePickerOpen(false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    initState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleBudgetTypeChange = (typeName) => {
    const newBudgetType = portfolioBudgetTypes.find((item) => item.name === typeName);
    if (typeName === AD_BUDGET_TYPE.DATE_RANGE && !startDate) {
      setStartDate(moment());
    }
    setBudgetType(newBudgetType);
  };

  const handleStrategyChange = (strategyId: string) => {
    const newStrategy = adStrategies.find((item) => item.id === strategyId);
    setStrategy(newStrategy);
  };

  const startDateLimit = (day) => {
    const todayDate = moment();

    if (!endDate) {
      // if endDate not set, use could set startDate to be any day after today.
      return !isInclusivelyAfterDay(day, todayDate);
    } else {
      // if endDate set, use could set startDate to be any day between today and endDate.
      return !isInclusivelyAfterDay(day, todayDate) || !isInclusivelyBeforeDay(day, endDate);
    }
  };
  const endDateLimit = (day) => {
    const todayDate = moment();

    let latestDate = todayDate;
    if (budgetType.name === AD_BUDGET_TYPE.DATE_RANGE && todayDate.isBefore(moment(startDate))) {
      latestDate = moment(startDate);
    }
    // disable all dates before today
    if (!isInclusivelyAfterDay(day, latestDate)) {
      return true;
    }

    return false;
  };
  const formatAndSubmit = async () => {
    if (!isSubmitting) {
      const formState = {
        name: portfolioName,
        budgetAmount,
        budgetType,
        strategy: selectedStrategy,
        platform: selectedPlatform,
        entity: selectedEntity,
        startDate,
        endDate
      };
      setIsSubmitting(true);
      try {
        await store.dispatch(adPlatformSettingsByClientOperations.createPortfolio(formState));
        onClose();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const filterPlatforms = adPlatforms.filter(
    (platform) => `${platform.extendedAttributes.retailerId}` === `${retailer.id}`
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <CreatePortfolioForm
        classes={classes}
        onCancel={onClose}
        onSubmit={formatAndSubmit}
        isSubmitting={isSubmitting}
        portfolioName={portfolioName}
        setPortfolioName={setPortfolioName}
        selectedPlatform={selectedPlatform}
        adPlatforms={filterPlatforms}
        selectedEntity={selectedEntity}
        budgetType={budgetType}
        handleBudgetTypeChange={handleBudgetTypeChange}
        budgetAmount={budgetAmount}
        setBudgetAmount={setBudgetAmount}
        startDate={startDate}
        setStartDate={setStartDate}
        startDatePickerOpen={startDatePickerOpen}
        setStartDatePickerOpen={setStartDatePickerOpen}
        startDateLimit={startDateLimit}
        endDate={endDate}
        setEndDate={setEndDate}
        setEndDatePickerOpen={setEndDatePickerOpen}
        endDatePickerOpen={endDatePickerOpen}
        endDateLimit={endDateLimit}
        adStrategies={adStrategies}
        selectedStrategy={selectedStrategy}
        handleStrategyChange={handleStrategyChange}
        enableSelect={false}
        retailer={retailer}
      />
    </Dialog>
  );
};

export const CreatePortfolioAdBuilder = connect(mapStateToPropsForPortfolio)(
  withStyles(muiStyles)(CreatePortfolioAdBuilderInner)
);
