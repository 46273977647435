/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { connect } from 'react-redux';
import pluralize from 'pluralize';

import { getPluralVerb } from 'src/utils/stringFormatting';
import ReduxStore from 'src/types/store/reduxStore';
import { Widget } from 'src/types/application/widgetTypes';
import { buildMetricValue } from 'src/utils/metrics';
import { AggregationField } from 'src/types/application/types';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { store } from 'src/main';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { Entity } from 'sl-api-connector/types';
import Tabs from 'src/components/common/Tabs/Tabs';
import Badge from 'src/components/AdManager/Badge';
import BudgetPacingGrid from 'src/components/AdManager/BudgetPacingPortfolioGrid';
import { buildMainEntityConditions } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import {
  NoDataForStablePacing,
  NoDataForUnderPacing,
  NoDataForOutOfBudget,
  NoDataForPendingStart
} from 'src/components/Grids/Data/NoResultTemplate';
import { parseEntityMetrics } from 'src/store/modules/entitySearchService/selectors';
import colors from 'src/utils/colors';
import { getTotalMonthlyBudgetForCurrentEntity } from 'src/components/AdManager/budgetCalculation';
import { fetchDailySpendAndProjections } from 'src/components/AdManager/utils';
import { buildAggregations } from 'src/components/AdManager/Search';

const yesterday = moment().subtract(1, 'days');

const pacingTermFilters = [
  {
    fieldName: 'pendingStart',
    condition: 'should',
    values: [1]
  },
  {
    fieldName: 'outOfBudget',
    condition: 'should',
    values: [1]
  },
  {
    fieldName: 'pacing',
    condition: 'should',
    values: [0, 1]
  },
  {
    fieldName: 'pacing',
    condition: 'should',
    values: [2]
  }
];

const computeSpendAndBudget = (adSpendMetricsActual, mainEntity, adCampaigns, adPortfolios, adEntities, retailer) => {
  const dataSet = _get(adSpendMetricsActual, ['spend_by_dayId'], []);
  let totalSpendMtd = 0;
  dataSet.data.forEach((dataPoint: any) => {
    totalSpendMtd += dataPoint.value;
  });
  const totalMonthlyBudgetRaw = getTotalMonthlyBudgetForCurrentEntity(
    mainEntity,
    adCampaigns,
    adPortfolios,
    adEntities
  );
  const percent = totalMonthlyBudgetRaw === 0 ? 0 : totalSpendMtd / totalMonthlyBudgetRaw;
  const spendDisplay = buildMetricValue(
    totalSpendMtd,
    dataSet.metricType,
    dataSet.currencySymbol,
    true,
    dataSet.dataType,
    dataSet.locale
  );
  const totalMonthlyBudget = buildMetricValue(
    totalMonthlyBudgetRaw,
    dataSet.metricType,
    dataSet.currencySymbol,
    true,
    dataSet.dataType,
    dataSet.locale
  );
  const percentDisplay = buildMetricValue(percent, 'PERCENT', retailer.currencySymbol);
  return {
    totalSpendMtd: `${spendDisplay.prefix}${spendDisplay.value}${spendDisplay.suffix}`,
    percentDisplay: `${percentDisplay.value}${percentDisplay.suffix}`,
    totalMonthlyBudget: `${totalMonthlyBudget.prefix}${totalMonthlyBudget.value}${totalMonthlyBudget.suffix}`
  };
};
const getTabTemplate = (name: string, count: number | string) => {
  return (
    <div className="nav-item">
      <div
        style={{ fontWeight: 500, whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        {name}
        {count > 0 ? (
          <Badge text="" color={colors.red} backgroundColor="#f3537940" amount={count} onClick={() => {}} />
        ) : null}
      </div>
    </div>
  );
};

const style = {
  marginBottom: 12
};

const defaultValue = {
  count: '-',
  monthlyBudget: '-',
  spendMtd: '-'
};

const defaultInfo = {
  daysDiff: 0,
  monthDisplay: '-',
  totalCount: 0,
  totalMonthlyBudget: 0,
  stablePacing: defaultValue,
  underPacing: defaultValue,
  outOfBudget: defaultValue,
  pendingStart: defaultValue
};

const pacingParser = (action: any) => {
  const { groupByFieldName: groupByField } = action.apiRequest[0].aggregations[0];
  const allPortfolioIds: string[] = [];
  const unStablePortfolioIds: string[] = [];
  const result: any[] = [];
  action.apiRequest.forEach((request: any, index: number) => {
    const portfolioIds: string[] = [];
    let spendProjected = 0;
    let monthlyBudget = 0;

    const currentRequestAction = {
      ...action,
      apiRequest: [action.apiRequest[index]],
      apiResponse: { data: [action.apiResponse.data[index]] }
    };
    const data = parseEntityMetrics(currentRequestAction);
    const monthlyBudgetData = data[`monthlyBudget_by_${groupByField}`];
    const spendProjectedData = data[`spendProjected_by_${groupByField}`];

    monthlyBudgetData.data.forEach((dataPoint: any, idx: number) => {
      const portfolioId = dataPoint.entity.id;
      const spendProjectedDataPoint = spendProjectedData.data[idx];
      if (index <= 1 || ([2, 3].includes(index) && !result[1].portfolioIds.includes(portfolioId))) {
        if (!allPortfolioIds.includes(portfolioId)) {
          allPortfolioIds.push(portfolioId);
          if (index !== 3) {
            unStablePortfolioIds.push(portfolioId);
          }
        }
        portfolioIds.push(portfolioId);
        monthlyBudget += dataPoint.value;
        spendProjected += spendProjectedDataPoint.value;
      }
    });

    const spendMtdDisplay = buildMetricValue(
      spendProjected,
      spendProjectedData.metricType,
      spendProjectedData.currencySymbol,
      true,
      spendProjectedData.dataType,
      spendProjectedData.locale
    );
    const monthlyBudgetDisplay = buildMetricValue(
      monthlyBudget,
      monthlyBudgetData.metricType,
      monthlyBudgetData.currencySymbol,
      true,
      monthlyBudgetData.dataType,
      monthlyBudgetData.locale
    );
    result.push({
      count: portfolioIds.length,
      spendMtd: `${spendMtdDisplay.prefix}${spendMtdDisplay.value}${spendMtdDisplay.suffix}`,
      monthlyBudget: `${monthlyBudgetDisplay.prefix}${monthlyBudgetDisplay.value}${monthlyBudgetDisplay.suffix}`,
      portfolioIds
      // sale: `${saleDisplay.prefix}${saleDisplay.value}${saleDisplay.suffix}`,
      // spend: `${spendDisplay.prefix}${spendDisplay.value}${spendDisplay.suffix}`
    });
  });

  return {
    totalCount: allPortfolioIds.length,
    unstableCount: unStablePortfolioIds.length,
    stablePacing: result[3],
    underPacing: result[2],
    outOfBudget: result[1],
    pendingStart: result[0]
  };
};

const fetchLatestPublishDay = (entity) => {
  const { app, retailer } = store.getState();
  const dataKey = `budgetPacing-publishDay-${entity.type}-${entity.id}`;
  const indexName = 'budgetPacingDailyMetrics';
  const entityConditions = buildMainEntityConditions({ termFilters: [], rangeFilters: [] }, entity, app, retailer);
  const aggregationFields = [
    {
      aggregateByFieldDisplayName: 'Day',
      aggregateByFieldName: 'dayId',
      function: 'max',
      canBeExported: true
    }
  ];
  const searchRequestOverrides = [
    {
      doAggregation: true,
      aggregations: [
        {
          aggregationFields,
          conditions: {
            termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }]
          },
          sortDirection: 'desc',
          sortByAggregationField: aggregationFields[0],
          groupByFieldName: 'retailerId'
        }
      ],
      conditions: {
        termFilters: [...entityConditions.termFilters]
      },
      pageNumber: 1,
      pageSize: 5000,
      searchBy: 'child',
      shouldCache: false,
      processDocuments: false
    }
  ];
  return new Promise((resolve) => {
    store.dispatch(
      fetchEntityMetrics(
        dataKey,
        {
          entity,
          retailer,
          app,
          indexName,
          customResponseParser: (res: any) => {
            const dayId = _get(
              res,
              ['apiResponse', 'data', 0, 'aggregations', 'by_retailerId', 0, 'additionalValues', 'dayId_max_value'],
              +yesterday.format('YYYYMMDD')
            );
            resolve(dayId);
            return dayId;
          }
        },
        searchRequestOverrides
      )
    );
  }) as Promise<number>;
};

export const fetchBudgetPacingCountForEntity = async (entity: Entity) => {
  const { app, retailer } = store.getState();
  const dataKey = `budgetPacingCount-${entity.type}-${entity.id}`;
  const indexName = 'budgetPacingDailyMetrics';
  const entityConditions = buildMainEntityConditions({ termFilters: [], rangeFilters: [] }, entity, app, retailer);

  const lastPublishDate = await fetchLatestPublishDay(entity);

  const timeRangeFilter = {
    fieldName: 'dayId',
    minValue: lastPublishDate,
    maxValue: lastPublishDate
  };

  const fields: AggregationField[] = ['monthlyBudget', 'pacing', 'outOfBudget', 'pendingStart', 'spendProjected'].map(
    (fieldName) => INDEX_FIELDS.getField(app.name, indexName, fieldName)
  );

  const [{ aggregations: aggregationFields }] = buildAggregations(fields);

  const searchRequestOverrides = pacingTermFilters.map((termFilter) => {
    return {
      doAggregation: true,
      aggregations: [
        {
          aggregationFields,
          conditions: {
            termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }]
          },
          sortDirection: 'desc',
          sortByAggregationField: aggregationFields[0],
          groupByFieldName: 'portfolioId'
        }
      ],
      conditions: {
        termFilters: [
          ...entityConditions.termFilters,
          {
            condition: 'should',
            fieldName: 'statusDerived',
            values: ['delivering', 'outOfBudget']
          },
          termFilter
        ],
        rangeFilters: [
          timeRangeFilter,
          {
            fieldName: 'monthlyBudget',
            minValue: 1
          }
        ]
      },
      pageNumber: 1,
      pageSize: 5000,
      searchBy: 'child',
      shouldCache: false,
      processDocuments: false
    };
  });

  return new Promise((resolve) => {
    store.dispatch(
      fetchEntityMetrics(
        dataKey,
        {
          entity,
          retailer,
          app,
          indexName,
          customResponseParser: (res: any) => {
            const computedRes = pacingParser(res);
            resolve(computedRes.unstableCount);
            return {
              count: computedRes.unstableCount
            };
          }
        },
        searchRequestOverrides
      )
    );
  }) as Promise<number>;
};

const mapStateToProps = ({
  adCampaigns,
  adPortfolios,
  adEntities,
  entitySearchService,
  entityService: { mainEntity },
  retailer,
  app
}: ReduxStore) => ({
  adCampaigns,
  adPortfolios,
  adEntities,
  retailer,
  mainEntity,
  entitySearchService,
  app
});

const AdBudgetPacing: React.FC<
  {
    widget: Widget;
    queryConditions: any;
  } & ReturnType<typeof mapStateToProps>
> = ({
  app,
  adCampaigns,
  adPortfolios,
  adEntities,
  entitySearchService,
  queryConditions,
  mainEntity,
  retailer,
  widget
}) => {
  const [budgetPacingInfo, setBudgetPacingInfo] = useState(defaultInfo);
  const [loading, setLoading] = useState(true);
  const [actualSpendInfo, setActualSpend] = useState({});
  const [activeTabIx, setActiveTabIx] = useState(0);

  const fetchData = async () => {
    const indexName = 'budgetPacingDailyMetrics';
    const entityConditions = buildMainEntityConditions(
      { termFilters: [], rangeFilters: [] },
      mainEntity,
      app,
      retailer
    );

    const lastPublishDate = await fetchLatestPublishDay(mainEntity);

    const lastPublishDay = moment(lastPublishDate, 'YYYYMMDD');
    const startDate = moment(lastPublishDay).startOf('month');
    const daysDiff = lastPublishDay.diff(startDate, 'days') + 1;
    const monthDisplay = lastPublishDay.format('MMMM');

    const timeRangeFilter = {
      fieldName: 'dayId',
      minValue: lastPublishDate,
      maxValue: lastPublishDate
    };

    const fields: AggregationField[] = ['monthlyBudget', 'pacing', 'outOfBudget', 'pendingStart', 'spendProjected'].map(
      (fieldName) => INDEX_FIELDS.getField(app.name, indexName, fieldName)
    );

    const [{ aggregations: aggregationFields }] = buildAggregations(fields);

    const searchRequestOverrides = pacingTermFilters.map((termFilter) => {
      return {
        doAggregation: true,
        aggregations: [
          {
            aggregationFields,
            conditions: {
              termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }]
            },
            sortDirection: 'desc',
            sortByAggregationField: aggregationFields[0],
            groupByFieldName: 'portfolioId'
          }
        ],
        conditions: {
          termFilters: [
            ...entityConditions.termFilters,
            {
              condition: 'should',
              fieldName: 'statusDerived',
              values: ['delivering', 'outOfBudget']
            },
            termFilter
          ],
          rangeFilters: [
            timeRangeFilter,
            {
              fieldName: 'monthlyBudget',
              minValue: 1
            }
          ]
        },
        pageNumber: 1,
        pageSize: 5000,
        searchBy: 'child',
        shouldCache: false,
        processDocuments: false
      };
    });

    const result = await store.dispatch(
      fetchEntityMetrics(
        widget.data.statePropertyName,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName,
          customResponseParser: (action: any) => {
            return pacingParser(action);
          }
        },
        searchRequestOverrides,
        null,
        true
      )
    );

    setBudgetPacingInfo({ daysDiff, monthDisplay, ...result });
    setLoading(false);
  };

  useEffect(() => {
    if (mainEntity && retailer) {
      setLoading(true);
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainEntity, retailer]);

  useEffect(() => {
    if (!mainEntity) {
      return;
    }

    fetchDailySpendAndProjections('budgetPacingTotal', { mainEntity, retailer, app }, queryConditions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainEntity]);

  const { [`budgetPacingTotal-actual`]: adSpendMetricsActual } = entitySearchService;

  useEffect(() => {
    if (!adSpendMetricsActual || _isEmpty(adCampaigns) || _isEmpty(adPortfolios) || _isEmpty(adEntities)) {
      return;
    }
    const { totalSpendMtd, percentDisplay, totalMonthlyBudget } = computeSpendAndBudget(
      adSpendMetricsActual,
      mainEntity,
      adCampaigns,
      adPortfolios,
      adEntities,
      retailer
    );
    setActualSpend({
      totalSpendMtd,
      percentDisplay,
      totalMonthlyBudget
    });
  }, [adSpendMetricsActual, mainEntity, adCampaigns, adPortfolios, adEntities, retailer]);

  const { totalSpendMtd, percentDisplay, totalMonthlyBudget } = actualSpendInfo;
  const { totalCount, stablePacing, underPacing, outOfBudget, pendingStart, daysDiff, monthDisplay } = budgetPacingInfo;
  const subHeader = `In the first ${pluralize(
    'day',
    daysDiff,
    true
  )} of ${monthDisplay}, you've spent ${totalSpendMtd} (${percentDisplay}) of your ${totalMonthlyBudget} total budget for the month.`;
  const count = `You currently have ${pluralize('active portfolio', totalCount, true)} with a budget set.`;
  const stablePacingStr = `${pluralize('portfolio', stablePacing.count, true)} ${getPluralVerb(
    stablePacing.count,
    'is',
    'are'
  )} on pace to spend above 80% of their monthly budgets (${stablePacing.spendMtd} of ${
    stablePacing.monthlyBudget
  } budget).`;
  const underPacingStr = `${pluralize('portfolio', underPacing.count, true)} ${getPluralVerb(
    underPacing.count,
    'is',
    'are'
  )} on pace to spend less then 80% of their monthly budgets `;
  const outOfBudgetStr = `${pluralize('portfolio', outOfBudget.count, true)} ${getPluralVerb(
    outOfBudget.count,
    'is',
    'are'
  )} out of budget.`;
  const pendingStartStr = `${pluralize('portfolio', pendingStart.count, true)} ${getPluralVerb(
    pendingStart.count,
    'is',
    'are'
  )} pending start.`;
  const tabs = [
    { displayTemplate: getTabTemplate('Stable Pacing', 0), Nodata: NoDataForStablePacing, ...stablePacing },
    {
      displayTemplate: getTabTemplate('Under Pacing', underPacing.count),
      Nodata: NoDataForUnderPacing,
      ...underPacing
    },
    {
      displayTemplate: getTabTemplate('Out of Budget', outOfBudget.count),
      Nodata: NoDataForOutOfBudget,
      ...outOfBudget
    },
    {
      displayTemplate: getTabTemplate('Pending Start', pendingStart.count),
      Nodata: NoDataForPendingStart,
      ...pendingStart
    }
  ];
  const portfolioIds = _get(tabs, [activeTabIx, 'portfolioIds'], []);
  const Nodata = _get(tabs, [activeTabIx, 'Nodata'], '');

  if (!totalMonthlyBudget || !totalSpendMtd) {
    return null;
  }
  return (
    <>
      <div className="recommendations_container">
        <div className="header">Budget Pacing</div>
        <div style={style}>{subHeader}</div> <br />
        <div style={style}>{count}</div>
        <div style={style}>
          <strong>Stable Pacing: </strong>
          <span>{stablePacingStr}</span>
        </div>
        <div style={style}>
          <strong>Under Pacing: </strong>
          <span>{underPacingStr}</span>{' '}
          <span>{`(${underPacing.spendMtd} of ${underPacing.monthlyBudget} budget).`}</span>
        </div>
        <div style={style}>
          <strong>Out of Budget: </strong>
          <span>{outOfBudgetStr}</span>
        </div>
        <div style={{ marginBottom: 45 }}>
          <strong>Pending Start: </strong>
          <span>{pendingStartStr}</span>
        </div>
      </div>
      {!loading && (
        <div className="ad-manager-top-nav">
          <Tabs
            value={activeTabIx}
            tabs={tabs}
            tabStyle={{ padding: '0 0 0 0', fontSize: 18, minWidth: 40, marginRight: 40 }}
            onTabChange={(_event, tabIx: number) => setActiveTabIx(tabIx)}
          />
          <BudgetPacingGrid portfolioIds={portfolioIds} Nodata={Nodata} />
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps)(AdBudgetPacing);
