/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import { connect } from 'react-redux';

import colors from 'src/utils/colors';
import ReduxStore from 'src/types/store/reduxStore';
import { NULL_UUID } from 'src/utils/constants';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';

const ErrorMsg: React.FC<{ msg: string }> = ({ msg }) => <span style={{ color: colors.red }}>{msg}</span>;

const mapStateToProps = (state: ReduxStore) => ({ appName: state.app.name });

const RawSegmentImport: React.FC<{} & ReturnType<typeof mapStateToProps>> = ({ appName }) => {
  const [textboxContent, setTextboxContent] = useState('');
  const [errorText, setErrorText] = useState<string | null>(null);
  const [successText, setSuccessText] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    let parsedSegmentDef;
    let parsedQuery;
    setErrorText(null);

    if (_isEmpty(textboxContent)) {
      setErrorText('You must supply segment definition JSON');
      return;
    }

    try {
      parsedSegmentDef = JSON.parse(textboxContent);
      parsedQuery = JSON.parse(parsedSegmentDef.query);
    } catch (_err) {
      setErrorText('Error parsing provided JSON');
      return;
    }

    const payload = {
      appName,
      entityType: parsedSegmentDef.entityType,
      query: JSON.stringify({ ...parsedQuery, id: NULL_UUID }),
      queryId: NULL_UUID
    };

    setIsSubmitting(true);
    let res;
    try {
      res = await axios.post(`/api/${appName}/UpdateSavedSearch`, payload);
    } catch (err) {
      console.error(err);
      setErrorText('Error submitting request; a JavaScript error occured');
      return;
    } finally {
      setIsSubmitting(false);
    }
    if (res.status !== 200) {
      setErrorText(`Error submitting segment: ${res.data}`);
      return;
    }

    setSuccessText(`Successfully created segment with id: ${res.data}`);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', paddingTop: 40 }}>
      <h2>Segment Import</h2>
      <p>
        This tool is meant for developers. It allows a segment from another user to be imported via its JSON definition.
        <br />
        Go to DynamoDB, select the segment, copy its text representation, paste here, and the ID of the created segment
        will be displayed.
      </p>

      <textarea
        value={textboxContent}
        onChange={(evt) => setTextboxContent(evt.target.value)}
        style={{ width: 400, height: 200 }}
      />
      <LargeMuiButton
        label={isSubmitting ? 'Submitting...' : 'Submit'}
        disabled={isSubmitting}
        onClick={handleSubmit}
        style={{ marginTop: 20, marginBottom: 20 }}
      />
      {errorText ? <ErrorMsg msg={errorText} /> : null}
      {successText ? <span color={colors.green}>{successText}</span> : null}
    </div>
  );
};

export default connect(mapStateToProps)(RawSegmentImport);
