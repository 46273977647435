import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

import colors from 'src/utils/colors';

class Password extends React.Component {
  static defaultProps = {
    id: 'password',
    validateLength: false,
    hintText: 'Password',
    floatingLabelText: 'Password',
    inputStyle: { marginTop: '8px' },
    style: { width: '100%', height: '58px' },
    className: '',
    error: null,
    clearInput: false,
    floatingLabelFixed: false
  };

  static propTypes = {
    isFormSubmitted: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    clearInput: PropTypes.bool,
    inputStyle: PropTypes.object,
    style: PropTypes.object,
    className: PropTypes.string,
    error: PropTypes.string,
    validateLength: PropTypes.bool,
    hintText: PropTypes.string,
    floatingLabelText: PropTypes.string,
    id: PropTypes.string,
    floatingLabelFixed: PropTypes.bool
  };

  state = {
    isPasswordValid: false,
    value: ''
  };

  componentWillReceiveProps(nextProps) {
    const { clearInput } = nextProps;

    if (!clearInput) {
      return;
    }

    this.clearInput();
  }

  clearInput() {
    this.setState({ value: '' });
  }

  isPasswordValid = (value) => {
    const { validateLength } = this.props;
    // Minimum eight characters
    return validateLength ? value.length >= 8 : value && value.length > 0;
  };

  handlePasswordChange = (event) => {
    const { onChange } = this.props;
    const { value, id } = event.target;
    const isPasswordValid = this.isPasswordValid(value);
    this.setState({
      value,
      isPasswordValid
    });
    onChange({ password: value, isPasswordValid, id });
  };

  showErrorText = () => {
    const { isFormSubmitted, validateLength, error } = this.props;
    const { isPasswordValid, value } = this.state;

    let errorValue = null;
    if (isFormSubmitted) {
      if (error) {
        return error;
      }
      if (!isPasswordValid) {
        if (value === '') {
          errorValue = 'This field is required';
        } else if (validateLength) {
          errorValue = 'Password must be at least 8 characters.';
        }
      }
    }
    return errorValue;
  };

  render() {
    const { id, hintText, floatingLabelText, inputStyle, style, className, floatingLabelFixed } = this.props;
    return (
      <TextField
        variant="standard"
        floatingLabelFixed={floatingLabelFixed}
        id={id}
        className={`${className} ${this.showErrorText() ? 'sl-form-input--error' : ''}`}
        placeholder={hintText}
        floatingLabelText={floatingLabelText}
        type="password"
        value={this.state.value}
        onChange={this.handlePasswordChange}
        floatingLabelStyle={{ top: '24px', fontSize: '16px', fontWeight: 400 }}
        floatingLabelFocusStyle={{ color: colors.stacklineBlue }}
        inputStyle={inputStyle}
        errorStyle={{ bottom: '9px', fontWeight: 400, marginTop: '10px' }}
        underlineFocusStyle={{ borderBottom: `1px solid ${colors.stacklineBlue}` }}
        style={style}
        errorText={this.showErrorText()}
      />
    );
  }
}

export default Password;
