import numeral from 'numeral';

export function volumeConverter(val: number, showTruncatedValue?: boolean) {
  // This can cause rounding issues if we round here and then round when formatting the chart later
  const numberOfDecimalPlaces = 2;
  if (showTruncatedValue) {
    if (!val || (val > 0 && val < 0.001) || (val < 0 && val > -0.001)) {
      return ['0.00', ''];
    } else if ((val >= 1000 && val < 1000000) || (val < -1000 && val > -1000000)) {
      const num = Math.round(val * 100) / 100000;
      const rounded = parseFloat(parseFloat(num.toString()).toFixed(numberOfDecimalPlaces));
      return [rounded, 'K'];
    } else if ((val >= 1000000 && val < 1000000000) || (val <= -1000000 && val > -1000000000)) {
      const num = Math.round(val * 100) / 100000000;
      const rounded = parseFloat(parseFloat(num.toString()).toFixed(numberOfDecimalPlaces));
      return [rounded, 'M'];
    } else if (val >= 1000000000 || val <= -1000000000) {
      const num = val / 1000000000;
      const rounded = parseFloat(parseFloat(num.toString()).toFixed(numberOfDecimalPlaces));
      return [rounded, 'B'];
    }
  }
  return [val, ''];
}

export function moneyConverter(val: number | undefined, currencySymbol?: string | null, showTruncatedValue?: boolean) {
  if (typeof val === 'undefined') {
    return ['', '', ''];
  }
  const moneyCurrency = currencySymbol || '$';
  const amount = volumeConverter(val, showTruncatedValue);
  return [moneyCurrency, amount[0], amount[1]];
}

export function stripNonNumberCharacters(value: number | string) {
  let number = value;
  if (typeof value !== 'number') {
    number = value.replace(/[^\d.-]/g, '');
  }

  return +number;
}

export const formatToDollar = (number: string | number) => {
  if (number) {
    const num = Number(number);
    if (!Number.isNaN(num)) {
      return numeral(num).format('$0,0.00');
    }
  }
  return '$0.00';
};
