import ReduxStore from 'src/types/store/reduxStore';

export const transferKeywordSegmentToExpReqFormat = (
  segmentId: string,
  omniKeywordSegmentService: ReduxStore['omniKeywordSegmentService']
) => {
  const { data } = omniKeywordSegmentService;
  const keywordSegmentObj = data.find((obj) => obj.queryId === segmentId);
  if (!keywordSegmentObj) {
    return [];
  }
  const { includeKeywords, includePhrases, excludePhrases, excludeKeywords } = keywordSegmentObj.query;
  const transferBody = [];
  if (Array.isArray(includeKeywords) && includeKeywords.length > 0) {
    const includeKeywordObj = {
      fieldName: 'searchTerm',
      condition: 'should',
      values: [...includeKeywords]
    };
    transferBody.push(includeKeywordObj);
  }
  if (Array.isArray(includePhrases) && includePhrases.length > 0) {
    const includePhraseObj = {
      fieldName: 'searchTerm.fuzzy',
      condition: 'should',
      values: [...includePhrases]
    };
    transferBody.push(includePhraseObj);
  }
  if (Array.isArray(excludePhrases) && excludePhrases.length > 0) {
    const excludePhraseObj = {
      fieldName: 'searchTerm.fuzzy',
      condition: 'must_not',
      values: [...excludePhrases]
    };
    transferBody.push(excludePhraseObj);
  }
  if (Array.isArray(excludeKeywords) && excludeKeywords.length > 0) {
    const excludeKeywordObj = {
      fieldName: 'searchTerm',
      condition: 'must_not',
      values: [...excludeKeywords]
    };
    transferBody.push(excludeKeywordObj);
  }
  return transferBody;
};

export enum SubtabToDocTypes {
  retailPrice = 'RetailPriceTab',
  priceViolations = 'ViolationsTab',
  inStock = 'InstockRateTab',
  availability = 'AvailabilityTab',
  delivery = 'DeliveryTab',
  shipping = 'ShippingTab',
  pickup = 'PickupTab'
}
