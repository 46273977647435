import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Loading from '../../components/common/Loading';
import Button from '@mui/material/Button';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const propTypes = {
  user: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired
};

function StacklineDataAdmin({ user, retailer }) {
  const [clearDevCacheButtonText, setClearDevCacheButtonText] = useState('Clear Dev Cache');
  const [clearProdCacheButtonText, setClearProdCacheButtonText] = useState('Clear Prod Cache');
  const [enableSuperUserButtonText, setEnableSuperUserButtonText] = useState('Enable Super User');
  const [disableSuperUserButtonText, setDisableSuperUserButtonText] = useState('Disable Super User');
  const [releaseAtlasButtonText, setReleaseAtlasButtonText] = useState('Release Atlas Sales');
  const [loadPublishIdDevButtonText, setLoadPublishIdDevButtonText] = useState('Load Dev Publish IDs');
  const [loadPublishIdProdButtonText, setLoadPublishIdProdButtonText] = useState('Load Prod Publish IDs');
  const [rollbackPublishIdDev, setRollbackPublishIdDev] = useState('');
  const [rollbackPublishIdProd, setRollbackPublishIdProd] = useState('');
  const [revertPublishIdButtonDevText, setRevertPublishIdButtonDevText] = useState('Revert Dev to ID');
  const [revertPublishIdButtonProdText, setRevertPublishIdButtonProdText] = useState('Revert Prod to ID');
  const [publishIdsDev, setPublishIdsDev] = useState([]);
  const [publishIdsProd, setPublishIdsProd] = useState([]);
  const [disableRevertDevButton, setDisableRevertDevButton] = useState(true);
  const [disableRevertProdButton, setDisableRevertProdButton] = useState(true);
  const [currentPublishIdForDevText, setCurrentPublishIdForDevText] = useState('');
  const [currentPublishIdForProdText, setCurrentPublishIdForProdText] = useState('');
  const [revertStage, setRevertStage] = useState('');

  const buttonClassName = `reclassify__button sl-delete-button sl-delete-button--sm`;

  const handleClearDevCacheClick = async () => {
    setClearDevCacheButtonText('processing...');
    await axios.post('/api/admin/ClearCacheByStage?stage=dev');
    setClearDevCacheButtonText('Done!');
  };

  const handleClearProdCacheClick = async () => {
    setClearProdCacheButtonText('processing...');
    await axios.post('/api/admin/ClearCacheByStage?stage=prod');
    setClearProdCacheButtonText('Done!');
  };

  const handleReleaseAtlasSalesClick = async () => {
    setReleaseAtlasButtonText('processing...');
    await axios.post(`/api/admin/ReleaseAtlasSales?retailerId=${retailer.id}`);
    setReleaseAtlasButtonText('Done!');
  };

  const handleEnableSuperUserClick = async () => {
    setEnableSuperUserButtonText('processing...');
    await axios.post('/api/admin/EnableAtlasSuperUser');
    setEnableSuperUserButtonText('Done!');
  };

  const handleDisableSuperUserClick = async () => {
    setDisableSuperUserButtonText('processing...');
    await axios.post('/api/admin/DisableAtlasSuperUser');
    setDisableSuperUserButtonText('Done!');
  };

  const handleLoadPublishIdDevClick = async () => {
    setLoadPublishIdDevButtonText('processing...');
    const res = await axios.post(`/api/admin/GetAtlasSalesPublishIds?retailerId=${retailer.id}&stage=dev`);
    setPublishIdsDev(res.data.past);
    setRevertStage('dev');
    setCurrentPublishIdForDevText(`Current publish ID for stage dev is ${res.data.current}.`);
    setLoadPublishIdDevButtonText('Load Dev Publish IDs');
  };

  const handleLoadPublishIdProdClick = async () => {
    setLoadPublishIdProdButtonText('processing...');
    const res = await axios.post(`/api/admin/GetAtlasSalesPublishIds?retailerId=${retailer.id}&stage=prod`);
    setPublishIdsProd(res.data.past);
    setRevertStage('prod');
    setCurrentPublishIdForProdText(`Current publish ID for stage prod is ${res.data.current}.`);
    setLoadPublishIdProdButtonText('Load Prod Publish IDs');
  };

  const handleRevertSalesToPublishIdDevClick = async () => {
    setRevertPublishIdButtonDevText('processing...');
    if (revertStage === 'prod' || revertStage === 'dev') {
      await axios.post(
        `/api/admin/RevertAtlasSalesToPublishId?retailerId=${retailer.id}&stage=dev&publishId=${rollbackPublishIdDev}`
      );
      setRevertPublishIdButtonDevText('Done!');
    }
  };

  const handleRevertSalesToPublishIdProdClick = async () => {
    setRevertPublishIdButtonProdText('processing...');
    if (revertStage === 'prod' || revertStage === 'dev') {
      await axios.post(
        `/api/admin/RevertAtlasSalesToPublishId?retailerId=${retailer.id}&stage=prod&publishId=${rollbackPublishIdProd}`
      );
      setRevertPublishIdButtonProdText('Done!');
    }
  };

  if (!user) {
    return <Loading className="spinner" />;
  }

  return (
    <div className="team">
      <h4 className="sl-header-text">Clear Cache</h4>
      <hr className="sl-divider sl-divider--no-margin-top" />
      <Button
        className={buttonClassName}
        onClick={async () => {
          handleClearDevCacheClick();
        }}
      >
        {clearDevCacheButtonText}
      </Button>
      <br />
      <br />
      <br />
      <Button
        className={buttonClassName}
        onClick={async () => {
          handleClearProdCacheClick();
        }}
      >
        {clearProdCacheButtonText}
      </Button>
      <br />
      <br />
      <Button
        className={buttonClassName}
        onClick={async () => {
          handleEnableSuperUserClick();
        }}
      >
        {enableSuperUserButtonText}
      </Button>
      <br />
      <br />
      <Button
        className={buttonClassName}
        onClick={async () => {
          handleDisableSuperUserClick();
        }}
      >
        {disableSuperUserButtonText}
      </Button>
      <br />
      <br />
      <h4 className="sl-header-text"> Release sales for {retailer.name}</h4>
      <hr className="sl-divider sl-divider--no-margin-top" />
      <br />
      <br />
      <Button
        className={buttonClassName}
        onClick={async () => {
          handleReleaseAtlasSalesClick();
        }}
      >
        {releaseAtlasButtonText}
      </Button>
      <br />
      <br />
      <div style={{ display: 'flex' }}>
        <Button
          className={buttonClassName}
          onClick={async () => {
            handleLoadPublishIdDevClick();
          }}
        >
          {loadPublishIdDevButtonText}
        </Button>
        <div style={{ width: '300px' }}>
          <Select
            variant="standard"
            style={{ border: '1px solid black' }}
            value={rollbackPublishIdDev || null}
            onChange={({ target: { value } }) => {
              setRollbackPublishIdDev(value);
              setDisableRevertDevButton(false);
            }}
          >
            {publishIdsDev.map((publishId) => (
              <MenuItem value={publishId} key={publishId} style={{ width: '100%' }}>
                {publishId}
              </MenuItem>
            ))}
          </Select>
          <br />
          <div className={{ marginLeft: '20px' }}>{currentPublishIdForDevText}</div>
        </div>
        <Button
          disabled={disableRevertDevButton}
          className={buttonClassName}
          onClick={async () => {
            handleRevertSalesToPublishIdDevClick();
          }}
        >
          {revertPublishIdButtonDevText}
        </Button>
      </div>
      <div style={{ display: 'flex' }}>
        <Button
          className={buttonClassName}
          onClick={async () => {
            handleLoadPublishIdProdClick();
          }}
        >
          {loadPublishIdProdButtonText}
        </Button>
        <div style={{ width: '300px' }}>
          <Select
            variant="standard"
            style={{ border: '1px solid black' }}
            value={rollbackPublishIdProd || null}
            onChange={({ target: { value } }) => {
              setRollbackPublishIdProd(value);
              setDisableRevertProdButton(false);
            }}
          >
            {publishIdsProd.map((publishId) => (
              <MenuItem value={publishId} key={publishId} style={{ width: '100%' }}>
                {publishId}
              </MenuItem>
            ))}
          </Select>
          <br />
          <div className={{ marginLeft: '20px' }}>{currentPublishIdForProdText}</div>
        </div>
        <Button
          disabled={disableRevertProdButton}
          className={buttonClassName}
          onClick={async () => {
            handleRevertSalesToPublishIdProdClick();
          }}
        >
          {revertPublishIdButtonProdText}
        </Button>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

StacklineDataAdmin.propTypes = propTypes;

export default StacklineDataAdmin;
