import React, { useState } from 'react';
import Tabs from 'src/components/common/Tabs/Tabs';
import colors from 'src/utils/colors';

interface OmniGeoCardHeaderProps {
  tabOptions: any[];
  handleCardContainerTabChange: (tabIndex: number) => void;
  style: React.CSSProperties;
}

const styles = {
  tabs: { borderBottom: `1px solid ${colors.lightestGrey}` },
  tab: { padding: '13px 0px 8px 0px', fontSize: 18 }
};

const OmniGeoCardHeader: React.FC<OmniGeoCardHeaderProps> = ({
  tabOptions,
  handleCardContainerTabChange,
  style
}: OmniGeoCardHeaderProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleTabChange = (tabIndex: number) => {
    handleCardContainerTabChange(tabIndex);
    setActiveTabIndex(tabIndex);
  };
  return (
    <div style={style}>
      <Tabs
        tabs={tabOptions}
        value={activeTabIndex}
        tabStyle={styles.tab}
        tabRootStyle={styles.tabs}
        onTabChange={(_event: React.ChangeEvent<HTMLInputElement>, tabIndex: number) => handleTabChange(tabIndex)}
      />
    </div>
  );
};

export default OmniGeoCardHeader;
