import React, { useCallback, useMemo } from 'react';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import DonutChartLoading from 'src/components/Charts/Donut/DonutChartLoading';
import { useAppSelector } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import TopEntityChartV2 from 'src/components/BeaconRedesignComponents/TopEntityChartV2/TopEntityChartV2';
import BeaconChartWithLegend from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import SplineChartLoading from 'src/components/BeaconRedesignComponents/SplineChartLoading/SplineChartLoading';
import saveAs from 'file-saver';
import { useTableData } from 'src/serverProxy/useTableData';
import { safeDivide } from 'src/utils/app';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import ContentScoreByProductGrid from './ContentScoreByProductGrid';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import CircularProgressPercentage from 'src/components/common/UploadAnimation/CircularProgressPercentage';

enum ContentScoreField {
  Title = 'weightedTitleScoreByUnits',
  Bullets = 'weightedBulletScoreByUnits',
  Image = 'weightedImageScoreByUnits',
  Video = 'weightedVideoScoreByUnits',
  APlus = 'weightedAplusScoreByUnits'
}

/**
 * Displays visualizations for the content score page
 */
const ContentScorePage = () => {
  const legendLabel = useAppSelector((state) => state.mainTimePeriod.displayName);
  const legendComparisonLabel = useAppSelector((state) => state.comparisonTimePeriod.displayName);

  const { getValuesByFieldName, isLoading, getValueDifferencesByFieldName } = useTableData({
    fields: [
      {
        name: 'unitsSoldForContentScore'
      },
      {
        name: ContentScoreField.APlus
      },
      {
        name: ContentScoreField.Bullets
      },
      {
        name: 'weightedContentScoreByUnits'
      },
      {
        name: ContentScoreField.Image
      },
      {
        name: ContentScoreField.Title
      },
      {
        name: ContentScoreField.Video
      },
      {
        name: 'contentScore'
      }
    ],
    groupByFieldName: 'retailerId',
    indexName: 'new-content-metrics',
    sortFieldName: 'weightedContentScoreByUnits',
    itemsPerPage: 1200,
    pageNumber: 1,
    requestId: 'weightedContentScorePage'
  });

  const getContentValue = useCallback(
    (fieldName: string): number => getValuesByFieldName(fieldName)[0] || 0,
    [getValuesByFieldName]
  );

  const getComparisonContentValue = useCallback(
    (fieldName: string): number => {
      const comparisonDifference = getValueDifferencesByFieldName(fieldName)[0] || 0;
      return getContentValue(fieldName) - comparisonDifference;
    },
    [getContentValue, getValueDifferencesByFieldName]
  );

  const unitsSoldForContentScoreComparison = useMemo(
    () => getComparisonContentValue('unitsSoldForContentScore'),
    [getComparisonContentValue]
  );
  const unitsSoldForContentScore = useMemo(() => getContentValue('unitsSoldForContentScore'), [getContentValue]);

  const contentScore = useMemo(() => getContentValue('contentScore'), [getContentValue]);

  const contentScores = [
    ContentScoreField.Title,
    ContentScoreField.Bullets,
    ContentScoreField.Image,
    ContentScoreField.Video,
    ContentScoreField.APlus
  ].reduce(
    (acc, fieldName) => ({
      ...acc,
      [fieldName]: {
        value: safeDivide(getContentValue(fieldName), unitsSoldForContentScore),
        comparisonValue: safeDivide(getComparisonContentValue(fieldName), unitsSoldForContentScoreComparison)
      }
    }),
    {} as Record<ContentScoreField, { value: number; comparisonValue: number }>
  );

  const data = [
    ['Title Score', contentScores[ContentScoreField.Title].value],
    ['Bullets Score', contentScores[ContentScoreField.Bullets].value],
    ['Image Score', contentScores[ContentScoreField.Image].value],
    ['Video Score', contentScores[ContentScoreField.Video].value],
    ['A-Plus Score', contentScores[ContentScoreField.APlus].value]
  ];

  const comparisonData = [
    ['Title Score', contentScores[ContentScoreField.Title].comparisonValue],
    ['Bullets Score', contentScores[ContentScoreField.Bullets].comparisonValue],
    ['Image Score', contentScores[ContentScoreField.Image].comparisonValue],
    ['Video Score', contentScores[ContentScoreField.Video].comparisonValue],
    ['A-Plus Score', contentScores[ContentScoreField.APlus].comparisonValue]
  ];

  return (
    <BeaconPageContainer>
      <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
        <Flex flexDirection="column" gap="xxl">
          <CommonSummaryInfoSubtitle title="Content Score" />
          <Flex alignItems="center" gap={108}>
            <div style={{ width: '308px', height: '308px' }}>
              {isLoading ? <DonutChartLoading /> : <CircularProgressPercentage percentage={contentScore} />}
            </div>
            <div style={{ flex: 1 }}>
              {isLoading ? (
                <SplineChartLoading width={543} />
              ) : (
                <BeaconChartWithLegend
                  showTitleSummary={false}
                  convertSeriesToDelimitedData={() => {
                    const csvContent = data.map((rowElements) => rowElements.join(',')).join('\n');
                    const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' });
                    saveAs(blob, `Content Score.csv`);
                    return Promise.resolve();
                  }}
                  primaryLegendProps={{
                    displayName: legendLabel
                  }}
                  comparisonLegendProps={{
                    displayName: legendComparisonLabel
                  }}
                  title="Content Score"
                >
                  <TopEntityChartV2
                    chartSeries={[
                      {
                        type: 'column',
                        data: comparisonData,
                        metricType: METRICTYPE.PERCENT
                      },
                      {
                        type: 'column',
                        data,
                        metricType: METRICTYPE.PERCENT
                      }
                    ]}
                    chartOptionsOverride={{
                      chart: {
                        height: 240,
                        marginBottom: 39, // Adjust this value as needed
                        spacingBottom: 0 // Adjust this value as needed
                      }
                    }}
                    leftAlignBars={false}
                  />
                </BeaconChartWithLegend>
              )}
            </div>
          </Flex>
        </Flex>
        <BeaconComparableSplineChart indexName="new-content-metrics" fieldName="contentScore" />
        <ContentScoreByProductGrid />
      </Flex>
    </BeaconPageContainer>
  );
};

export default ContentScorePage;
