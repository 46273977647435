import React from 'react';
import { ProductSalesDatum } from './serverProxy/types';
import _chunk from 'lodash/chunk';
import { SlColumn, SlRow } from '@stackline/ui';
import ProductCard from '../../ProductCard/ProductCard';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { getProductImageUrl } from 'src/utils/image';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import ProductGridLoading from 'src/components/BeaconRedesignComponents/ProductGrid/ProductGridLoading';
import { getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';

interface ForecastProductGridProps {
  productSalesData: ProductSalesDatum[];
  loading?: boolean;
  page: number;
}

const ITEMS_PER_PAGE = 20;

/**
 * Displays a grid of products
 */
const ForecastProductGrid = ({ productSalesData, loading, page }: ForecastProductGridProps) => {
  const retailerId = useAppSelector((state) => state.retailer.id);
  const { targetUrl, name: appName } = useAppSelector((state) => state.app);
  const formatMetric = useMetricFormatter();

  const { searchParams, additionalParams } = useAppSelector((state) => state.app.queryParams);

  return (
    <SlColumn spacing="md" widths="full">
      <SlColumn spacing="cardGrid" widths="full">
        {loading ? (
          <ProductGridLoading />
        ) : (
          _chunk(
            productSalesData.slice((page - 1) * ITEMS_PER_PAGE, (page - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE),
            4
          ).map((items) => (
            <SlRow key={items.map((i) => i.stacklineSku).join('')} spacing="cardGrid">
              {items.map((item) => (
                <ProductCard
                  key={item.productTitle}
                  availabilityStatusCode={item.product.availabilityStatusCode}
                  imageUri={getProductImageUrl(item.stacklineSku)}
                  mainDisplayTitle={item.product.brandName}
                  mainMetric={formatMetric(item.retailSales_sum_value, METRICTYPE.MONEY, {
                    showFullValue: false,
                    decimalPlaces: 1
                  })}
                  secondaryDisplayTitle={item.productTitle}
                  secondaryMetric={`${formatMetric(item.retailSalesChange, METRICTYPE.MONEY, {
                    showFullValue: false,
                    decimalPlaces: 1
                  })} (${formatMetric(item.retailSalesPercentChange, METRICTYPE.PERCENT, {
                    formatString: '0,0%',
                    showFullValue: true
                  })})`}
                  direction={getDirection(item.retailSalesChange)}
                  stacklineSku={item.stacklineSku.toUpperCase()}
                  retailerSku={item.product.retailerSku}
                  categoryName={item.product.categoryName}
                  subCategoryName={item.product.subCategoryName}
                  statusColor={item.product.availabilityStatusCode === 'In stock' ? 'success' : 'error'}
                  productUrl={`/product/${item.stacklineSku.toUpperCase()}${searchParams}${additionalParams}`}
                  brandUrl={`/brand/${item.product.brandId}${searchParams}${additionalParams}`}
                  retailerUrl={`https://${targetUrl}/api/utility/OpenRetailerProductPage?appName=${appName}&retailerId=${retailerId}&stacklineSku=${item.stacklineSku.toUpperCase()}`}
                  categoryUrl={`/category/${item.product.categoryId}${searchParams}${additionalParams}`}
                  subcategoryUrl={`/subcategory/${item.product.subCategoryId}${searchParams}${additionalParams}`}
                />
              ))}
            </SlRow>
          ))
        )}
      </SlColumn>
    </SlColumn>
  );
};

export default ForecastProductGrid;
