import types from './types';
import parseChargeBackStatuses from 'src/store/modules/chargeBackStatus/selectors';

const receiveChargeBackStatuses = (chargeBackStatuses: ReturnType<typeof parseChargeBackStatuses>) => ({
  type: types.RECEIVE_ALL_CHARGEBACK_STATUSES,
  chargeBackStatuses
});

const clearChargeBackStatuses = () => ({
  type: types.CLEAR_ALL_CHARGEBACK_STATUSES
});

export default {
  receiveChargeBackStatuses,
  clearChargeBackStatuses
};
