import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Widget } from 'src/types/application/widgetTypes';
import { omniSegmentOperation } from 'src/store/modules/omni/omniSegment';
import { useDispatch, useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import ListSegmentComponent from 'src/components/Omni/OmniSegmentList/ListSegmentComponent';
import OmniSegmentDeleteDialog from 'src/components/Omni/OmniSegmentList/OmniSegmentDeleteDialog';

const { fetchSegmentListData, updateTheOmniSegment } = omniSegmentOperation;

interface OmniSegmentListProps extends RouteComponentProps {
  widget: Widget;
}

const OmniSegmentList: React.FC<OmniSegmentListProps> = ({ history }: OmniSegmentListProps) => {
  const [selected, setSelected] = useState({ queryId: '', name: '' });
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const segmentListData = useSelector((state: ReduxStore) => state.omniSegmentService);
  const app = useSelector((state: ReduxStore) => state.app);
  const { searchParams } = app.queryParams;
  const isFetching = segmentListData ? segmentListData.isFetching : true;
  const data = segmentListData ? segmentListData.data : [];
  const displayData = isFetching
    ? []
    : data.map((e) => ({ name: e.name, id: e.queryId, canEdit: e.canEdit, ownerProfile: e.ownerProfile }));

  const handleListItemClick = (id: string) => {
    history.push(`segment/${id}${searchParams}&tab=scorecard&subtab=all`);
  };

  const handleDeleteButtonClick = (queryId: string, name: string) => {
    setSelected({
      queryId,
      name
    });
    setIsOpen(true);
  };

  const handleDelete = () => {
    const { queryId, name } = selected;
    updateTheOmniSegment({ queryId, name, isActive: false }).then(() => {
      dispatch(fetchSegmentListData());
      setIsOpen(false);
    });
  };

  return isFetching ? (
    <div>Loading</div>
  ) : (
    <>
      <ListSegmentComponent
        title="Segments"
        data={displayData}
        handleListItemClick={handleListItemClick}
        handleDeleteButtonClick={handleDeleteButtonClick}
        searchBarInfo={{
          name: 'Segment',
          searchHintText: 'Search Segment Name'
        }}
      />
      <OmniSegmentDeleteDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selected={selected}
        deleteButtonText="DELETE"
        handleDelete={handleDelete}
      />
    </>
  );
};

export default withRouter(OmniSegmentList);
