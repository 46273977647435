import _isNil from 'lodash/isNil';
import _sum from 'lodash/sum';
import _get from 'lodash/get';
import moment from 'moment';
import { Entity, AdManagerAdCampaignMetadata } from 'sl-api-connector/types';
import {
  IAdPortfolioAdPlatformSettingsByClient,
  IAdEntityAdPlatformSettingsByClient
} from 'sl-ad-campaign-manager-data-model';
import { Option } from 'funfix-core';

import ReduxStore from 'src/types/store/reduxStore';
import { getCampaignDisplayStatus } from 'src/components/AdManager/AmsUtils';
import { panic, error, warn } from 'src/utils/mixpanel';

const getMonthlyAggregatedBudgetFromCampaigns = (
  adCampaigns: ReduxStore['adCampaigns'],
  portfolioId?: string
): number => {
  const allCampaignsForPortfolio = _isNil(portfolioId)
    ? adCampaigns
    : adCampaigns.filter((campaign) => campaign.extendedAttributes.portfolioId === portfolioId);
  // Filter out campaigns that have a status of archived or ended
  const filteredCampaigns = allCampaignsForPortfolio.filter((campaign) =>
    ['Delivering', 'Out of Budget'].includes(getCampaignDisplayStatus(campaign.extendedAttributes).displayName)
  );

  return (
    filteredCampaigns.reduce((acc, campaign) => {
      const campaignBudget = Option.of(
        [
          campaign.extendedAttributes.currentMonthBudgetSetting
            ? campaign.extendedAttributes.currentMonthBudgetSetting.amount
            : null
        ].find((b) => !_isNil(b))
      ).orNull();
      if (_isNil(campaignBudget)) {
        error(
          `Campaign ID "${campaign.extendedAttributes.campaignId}" has unset \`extendedAttributes.currentMonthBudgetSetting.amount\``
        );
        return acc;
      }

      return acc + campaignBudget;
    }, 0) * moment().daysInMonth()
  );
};

const sumMonthlyBudgetForPortfolios = (
  portfolioSettings: IAdPortfolioAdPlatformSettingsByClient[],
  adCampaigns: ReduxStore['adCampaigns']
): number =>
  portfolioSettings.reduce((acc, portfolioSetting) => {
    const budget = Option.of(
      [
        portfolioSetting.extendedAttributes.liveBudgetSetting
          ? portfolioSetting.extendedAttributes.liveBudgetSetting.amount
          : null,
        portfolioSetting.extendedAttributes.currentMonthBudgetSetting
          ? portfolioSetting.extendedAttributes.currentMonthBudgetSetting.amount
          : null
      ].find((b) => !_isNil(b))
    ).orNull();
    if (_isNil(budget)) {
      warn(
        `\`extendedAttributes.liveBudgetSetting.amount\` and \`extendedAttributes.currentMonthBudgetSetting.amount\` were both null/undefined for portfolio id "${portfolioSetting.extendedAttributes.portfolioId}"; falling back to aggregating up from constituent campaign budgets`,
        portfolioSetting.extendedAttributes
      );

      const aggregatedBudget = getMonthlyAggregatedBudgetFromCampaigns(adCampaigns, portfolioSetting.settingId);
      return acc + aggregatedBudget;
    }

    return acc + budget;
  }, 0);

const computeMonthlyBudgetForEntity = (
  entityEntity: IAdEntityAdPlatformSettingsByClient,
  adCampaigns: ReduxStore['adCampaigns'],
  allAdPortfolioSettings: IAdPortfolioAdPlatformSettingsByClient[]
): number => {
  // If we have a buget set for this entity directly, use that.  Otherwise, sum up the budgets for all portfolios
  // that make it up
  const entityBudget = entityEntity.extendedAttributes.currentMonthBudgetSetting
    ? entityEntity.extendedAttributes.currentMonthBudgetSetting.amount
    : null;
  if (!_isNil(entityBudget)) {
    return entityBudget;
  }

  // Sum up the budgets for all portfolios that belong to this entity
  const allAdPortfoliosForEntity = allAdPortfolioSettings.filter(
    (portfolioSetting) => portfolioSetting.extendedAttributes.entityId === entityEntity.extendedAttributes.entityId
  );

  // Add the sum of the budgets of all portfolios in the entity to the sum of the budgets for all campaigns in that
  // entity that don't have an assigned portfolio
  const unassignedCampaignsForEntity = adCampaigns.filter(
    (campaign) =>
      campaign.extendedAttributes.entityId === entityEntity.extendedAttributes.entityId &&
      (_isNil(campaign.extendedAttributes.portfolioId) || campaign.extendedAttributes.portfolioId === 'unassigned')
  );
  const unassignedCampaignsForEntityMonthlyBudget =
    getMonthlyAggregatedBudgetFromCampaigns(unassignedCampaignsForEntity);

  return (
    sumMonthlyBudgetForPortfolios(allAdPortfoliosForEntity, adCampaigns) + unassignedCampaignsForEntityMonthlyBudget
  );
};

/**
 * Computes the current monthly budget for the provided `mainEntity`.  The algorithm for determining this is quite
 * complex, mostly due to the fact that it needs to handle many situations where data is missing.  In brief, it will
 * try to use an explicitly provided budget for each entity.  If one isn't provided, it will fall back to aggregating up
 * the total budgets for all child entities recursively (company -> adEntity -> adPortfolio -> adCampaign).
 */
export const getTotalMonthlyBudgetForCurrentEntity = (
  mainEntity: Entity,
  adCampaigns: ReduxStore['adCampaigns'],
  allAdPortfolioSettings: IAdPortfolioAdPlatformSettingsByClient[],
  allEntitySettings: IAdEntityAdPlatformSettingsByClient[]
): number => {
  switch (mainEntity.type) {
    case 'adCampaign': {
      const campaignEntity = mainEntity as AdManagerAdCampaignMetadata;

      const campaignBudget = _get(campaignEntity, ['extendedAttributes', 'currentMonthBudgetSetting', 'amount'], null);

      if (_isNil(campaignBudget)) {
        error(
          `Campaign ID "${campaignEntity.extendedAttributes.campaignId}" has unset \`extendedAttributes.currentMonthBudgetSetting.amount\``
        );
        return 0;
      }

      return campaignBudget * moment().daysInMonth(); // We multiply since campaign budgets are daily and others are monthly
    }
    case 'adPortfolio': {
      const portfolioEntity = mainEntity as IAdPortfolioAdPlatformSettingsByClient;
      const portfolioBudget =
        _get(portfolioEntity, ['extendedAttributes', 'liveBudgetSetting', 'amount'], null) ||
        _get(portfolioEntity, ['extendedAttributes', 'currentMonthBudgetSetting', 'amount'], null);

      if (_isNil(portfolioBudget)) {
        warn(
          `\`extendedAttributes.liveBudgetSetting.amount\` and \`extendedAttributes.currentMonthBudgetSetting.amount\` were both null/undefined for portfolio id "${portfolioEntity.extendedAttributes.portfolioId}"; falling back to aggregating up from constituent campaign budgets`,
          portfolioEntity.extendedAttributes
        );

        return getMonthlyAggregatedBudgetFromCampaigns(adCampaigns, portfolioEntity.settingId);
      }

      return portfolioBudget;
    }
    case 'adEntity': {
      return computeMonthlyBudgetForEntity(
        mainEntity as IAdEntityAdPlatformSettingsByClient,
        adCampaigns,
        allAdPortfolioSettings
      );
    }
    case 'client': {
      if (_isNil(allEntitySettings)) {
        throw panic('No ad entity platform settings by client found'); // crash is here?
      }

      // We don't have budgets set for all entities, so we sum up the budgets for all portfolios instead
      return _sum(
        allEntitySettings.map((entityEntity) =>
          computeMonthlyBudgetForEntity(entityEntity, adCampaigns, allAdPortfolioSettings)
        )
      );
    }
    default: {
      return panic(`Unable to get total budget for unknown entity type: "${mainEntity.type}"`);
    }
  }
};
