import { useStacklineTheme } from '@stackline/ui';
import React, { Dispatch, SetStateAction, createContext, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { CloseIcon } from 'src/components/SvgIcons';
import { clearMessage } from 'src/store/modules/auth/operations';

interface ShowNotificationArgs {
  header: React.ReactNode;
  message: React.ReactNode;
}

interface NotificationContextValue {
  showNotification: (args: ShowNotificationArgs) => void;
  closeNotification: () => void;
  notificationOpen: boolean;
  setNotificationOpen: Dispatch<SetStateAction<boolean>>;
}

interface NotificationPopUpProps extends ShowNotificationArgs {
  onClose: () => void;
}

interface NotificationProviderProps {
  children: React.ReactNode;
}

export const NotificationContext = createContext<NotificationContextValue>(null);

/**
 *
 * This component is used for a notification bottom right side - most of the time you can find the component Auth flow.
 */
const NotificationPopUp = ({ header, message, onClose }: NotificationPopUpProps) => {
  const theme = useStacklineTheme();
  return (
    <Flex
      flexDirection="column"
      borderRadius={theme.spacing.sm}
      width="310px"
      padding={theme.spacing.md}
      sx={{
        position: 'absolute',
        backgroundColor: '#fff',
        bottom: theme.spacing.lg,
        right: theme.spacing.lg
      }}
    >
      <Flex alignItems="flex-start" justifyContent="space-between" width="100%">
        <Text variant="h4">{header}</Text>
        <CloseIcon onClick={onClose} style={{ cursor: 'pointer', width: '12px', height: '12px' }}></CloseIcon>
      </Flex>

      <Flex marginTop={theme.spacing.sm}>
        <Text variant="body2">{message}</Text>
      </Flex>
    </Flex>
  );
};

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationHeader, setNotificationHeader] = useState('Error');
  const [notificationMessage, setNotificationMessage] = useState<React.ReactNode>(null);
  const dispatch = useDispatch();

  const showNotification = useCallback(({ message, header, open }) => {
    setNotificationMessage(message);
    setNotificationHeader(header);
    setNotificationOpen(open);
  }, []);

  const closeNotification = useCallback(() => {
    // We are handling the auth error in redux.
    dispatch(clearMessage());
    setNotificationOpen(false);
  }, []);

  return (
    <NotificationContext.Provider
      value={{ showNotification, closeNotification, notificationOpen, setNotificationOpen }}
    >
      {children}
      {notificationOpen ? (
        <NotificationPopUp header={notificationHeader} message={notificationMessage} onClose={closeNotification} />
      ) : null}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
