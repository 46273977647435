import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import SummaryHeader from 'src/components/AdManager/SummaryHeader';
import EntityGrid from 'src/components/EntityGrid';
import MarketShareGrid from 'src/components/EntityGrid/Table/MarketShareGrid';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import {
  addLeftNavToLayout,
  buildMultiGroupByTopEntitiesChartWidgetConfig,
  buildMultiMetricDonutChartWidgetConfig,
  buildMultiMetricTrendChartWidgetConfig,
  buildTopEntitiesWidgetConfig,
  buildWidgetGroup
} from 'src/components/Layout/LayoutUtil';
import buildAggregatedRetailerGridWidget from 'src/components/Layout/util/AggregatedRetailerGrid';
import SummaryTileList from 'src/components/SummaryTile/SummaryTileList';
import Homepage from 'src/routes/HomePage/HomePage';
import { shouldShowNewBeacon } from 'src/utils/app';
import { ENTITIES, INDEX_FIELDS } from 'src/utils/entityDefinitions';
import BrandSummary from '../BeaconRedesignComponents/ExperimentalLayout/BrandSummary/BrandSummary';
import Segments from '../BeaconRedesignComponents/ExperimentalLayout/Segments/Segments';
import Topics from '../BeaconRedesignComponents/ExperimentalLayout/Topics/Topics';
import VIEWS from './ViewDefaultConfig';

const noOfTopEntities = 50;
const getInnerWidget = (app, indexName, metricType, entity) => ({
  view: _merge({}, VIEWS.weeklyTrendChart, {
    chartPropsOverride: {
      chart: { height: 420, marginTop: 30 },
      title: '',
      legend: {
        enabled: false
      },
      subtitle: {
        style: {
          borderBottom: 'none'
        }
      }
    }
  }),
  data: {
    chartMainField: INDEX_FIELDS.getField(app.name, indexName, metricType, entity.type, 'weekId'),
    aggregationFields: [INDEX_FIELDS.getField(app.name, indexName, metricType, entity.type, 'weekId')],
    groupByField: INDEX_FIELDS.getField(app.name, indexName, 'weekId', entity.type)
  }
});

export default function getSummaryPageLayout({ app, entity, entityType }) {
  let indexName = 'sales';
  if (app.name === 'atlas' && entityType === 'retailer') {
    indexName = 'multiretailer';
  }
  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.atlas.retailer),
    categoryEntity: _cloneDeep(ENTITIES.atlas.category),
    relatedEntity: _cloneDeep(ENTITIES.atlas.brand),
    weekIdField: _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId')),
    retailerIdField: _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'))
  };
  let widgets = [];
  if (entityType === 'campaign') {
    const nInOneMetricFieldNames = ['clicks', 'spend', 'sales', 'returnOnAdSpend'];
    const weekIdField = _cloneDeep(INDEX_FIELDS.getField('beacon', 'sales', 'weekId'));
    const topFiveAggregationMetricFields = [
      INDEX_FIELDS.getField('beacon', 'advertising', 'spend', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField('beacon', 'advertising', 'sales', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField('beacon', 'advertising', 'returnOnAdSpend', 'product', 'stacklineSku')
    ];

    // Summary header

    const headerTabOpts = [
      { name: 'searchKeyword', displayName: 'Keywords' },
      { name: 'stacklineSku', displayName: 'Products' },
      { name: 'brandId', displayName: 'Brands' },
      { name: 'categoryId', displayName: 'Categories' },
      { name: 'subCategoryId', displayName: 'Subcategories' },
      { name: 'campaignType', displayName: 'Ad Type' }
    ];

    widgets.push({
      CustomComponent: SummaryHeader,
      name: 'campaign_summary_header',
      view: {
        name: 'campaign_summary_header',
        summaryHeader: {
          title: 'Campaigns',
          headerTabOpts
        }
      },
      data: {}
    });

    // summary tile list with search bar

    const tableGridFields = nInOneMetricFieldNames.map((fieldName) =>
      INDEX_FIELDS.getField('beacon', 'advertising', fieldName)
    );
    const tableGridConfig = {
      name: 'entityGrid',
      CustomComponent: EntityGrid,
      view: _merge({}, VIEWS.entityGrid, {
        gridOptions: {
          hideGridHeader: true
        },
        container: { style: { marginTop: 0 } }
      }),
      data: {
        defaultSortField: INDEX_FIELDS.getField('beacon', 'advertising', 'clicks'),
        groupByFields: [INDEX_FIELDS.getField('beacon', 'advertising', 'campaignId')],
        configByGroupByFieldName: {
          campaignId: {
            indexName: 'traffic',
            entity: ENTITIES.atlas.product,
            mainMetricField: INDEX_FIELDS.getField('beacon', 'advertising', 'clicks'),
            aggregationFields: tableGridFields,
            tableView: {
              metricFields: tableGridFields
            }
          }
        }
      }
    };

    widgets.push({
      CustomComponent: SummaryTileList,
      name: `summaryTileList_campaign`,
      view: {},
      data: {
        weekIdField,
        entity,
        nInOneMetricFieldNames,
        topFiveAggregationMetricFields,
        indexName: 'advertising',
        listType: 'campaign',
        tableGridConfig
      }
    });
  } else if (app.name === 'atlas' && entityType === 'retailer') {
    // for multi-retailer page in the atals
    widgets = [];
    const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
    /*
      Header
      - trend chart
      - donut chart
    */
    const donutFields = ['unitsSold', 'retailSales_convertedUSD'];
    widgets.push(
      buildWidgetGroup(
        [
          buildMultiMetricTrendChartWidgetConfig(
            app,
            indexName,
            entity,
            ['unitsSold', 'retailSales_convertedUSD'],
            'weekId',
            dataConfig.weekIdField,
            {
              view: {
                chartPropsOverride: {
                  title: {
                    text: ''
                  },
                  chart: {
                    height: 450,
                    marginTop: 100
                  },
                  // legend: { enabled: false },
                  yAxis: [
                    {
                      visible: false
                    }
                  ]
                },
                container: {
                  style: {
                    width: '100%',
                    display: 'inline-block',
                    marginBottom: '100px'
                  }
                }
              }
            }
          ),
          buildMultiMetricDonutChartWidgetConfig(
            app,
            indexName,
            entity,
            donutFields,
            'retailerId',
            dataConfig.weekIdField,
            {
              view: {
                chartPropsOverride: {
                  hideFieldDropdowns: true
                }
              }
            }
          )
        ],
        {
          view: {
            container: {
              style: {
                height: '630px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around'
              }
            }
          }
        }
      )
    );

    /* ----------------------
      Retail Sales (Top Retailers)
    -------------------------- */

    const retailSalesFields = [
      'unitsSold',
      'retailSales_convertedUSD',
      'unitsSoldGrowth',
      'retailSales_convertedUSD_Growth'
    ];
    const retailSalesMetricFields = retailSalesFields.map((fieldName) => {
      const field = INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type);
      return {
        ...field,
        displayName: field.displayName
      };
    });

    widgets.push(
      buildMultiGroupByTopEntitiesChartWidgetConfig(
        app,
        indexName,
        entity,
        [
          {
            groupByFieldName: 'retailerId',
            indexName,
            aggregationFieldNames: ['unitsSold']
          }
        ],
        10,
        retailSalesMetricFields,
        weekIdField,
        {
          view: {
            chartPropsOverride: {
              enableSwitchingGroupBy: false,
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            },
            hideTooltipFor: ['unitsSoldGrowth', 'retailSales_convertedUSD_Growth'],
            anchorName: 'multiGroupByTopEntitiesChartForMultiGroup'
          },
          data: {
            sortByAggregationField: {
              aggregateByFieldDisplayName: 'Retail Sales',
              aggregateByFieldName: 'retailSales_converted.USD',
              function: 'sum',
              canBeExported: true
            }
          }
        }
      )
    );

    /* ----------------------
        Retail Price
    -------------------------- */
    widgets.push(
      buildTopEntitiesWidgetConfig(
        app,
        indexName,
        entity,
        [
          {
            groupByFieldName: 'retailerId',
            indexName,
            aggregationFieldNames: ['retailPrice_convertedUSD']
          }
        ],
        10,
        weekIdField,
        {
          view: {
            chartPropsOverride: {
              enableSwitchingGroupBy: false,
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            },
            anchorName: 'TopEntitiesChartForRetailPrice'
          },
          data: {
            sortByAggregationField: {
              aggregateByFieldDisplayName: 'Retail Sales',
              aggregateByFieldName: 'retailSales_converted.USD',
              function: 'sum',
              canBeExported: true
            }
          }
        }
      )
    );
    /* ----------------------
       Market share By retailers
    -------------------------- */
    const marketShareField = ['retailSales', 'unitsSold'];
    const marketShareMetricFields = marketShareField.map((fieldName) => {
      const field = INDEX_FIELDS.getField(app.name, indexName, `${fieldName}MarketShare`, entity.type);
      return {
        ...field,
        displayName: field.displayName
      };
    });

    widgets.push(
      buildMultiGroupByTopEntitiesChartWidgetConfig(
        app,
        indexName,
        entity,
        [
          {
            groupByFieldName: 'retailerId',
            indexName,
            aggregationFieldNames: ['retailSales']
          }
        ],
        10, // this is page size
        marketShareMetricFields,
        weekIdField,
        {
          isMarketShare: true,
          view: {
            enableComparison: true,
            chartPropsOverride: {
              title: { text: 'Market Share by' },
              horizontalScrolling: {
                enabled: true,
                step: 3
              },
              xAxis: [
                {
                  min: 0,
                  max: 9
                }
              ],
              enableSwitchingGroupBy: false,
              anchorName: 'multiGroupByTopEntitiesChartForMarketShare'
            }
          },
          data: {
            sortByAggregationField: {
              aggregateByFieldDisplayName: 'Units Sold',
              aggregateByFieldName: 'unitsSold',
              function: 'sum',
              canBeExported: true
            }
          }
        }
      )
    );

    /* ----------------------
       Market share by brand grid
    -------------------------- */
    widgets.push({
      name: 'marketShareGrid',
      view: VIEWS.marketShareGrid,
      CustomComponent: MarketShareGrid,
      data: {
        lazyLoad: true,
        groupByFields: [INDEX_FIELDS.getField(app.name, indexName, dataConfig.relatedEntity.keyFieldName)],
        configByGroupByFieldName: {
          brandId: {
            indexName,
            entity: ENTITIES.atlas.brand,
            mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'retailSales', entity.type),
            aggregationFields: [INDEX_FIELDS.getField(app.name, indexName, 'retailSales', entity.type)],
            isPrimarySource: true
          }
        },
        weekIdField: INDEX_FIELDS.getField(app.name, indexName, 'weekId')
      }
    });

    /* ----------------------
       Market share By category
    -------------------------- */
    widgets.push(
      buildMultiGroupByTopEntitiesChartWidgetConfig(
        app,
        indexName,
        entity,
        [
          {
            groupByFieldName: 'categoryId',
            indexName,
            aggregationFieldNames: ['retailSales']
          }
        ],
        10, // this is page size
        marketShareMetricFields,
        weekIdField,
        {
          isMarketShare: true,
          view: {
            enableComparison: true,
            chartPropsOverride: {
              title: { text: 'Market Share by' },
              horizontalScrolling: {
                enabled: true,
                step: 3
              },
              xAxis: [
                {
                  min: 0,
                  max: 9
                }
              ],
              enableSwitchingGroupBy: false,
              anchorName: 'multiGroupByTopEntitiesChartForMarketShareByCategory'
            }
          },
          data: {
            lazyLoad: true,
            sortByAggregationField: {
              aggregateByFieldDisplayName: 'Units Sold',
              aggregateByFieldName: 'unitsSold',
              function: 'sum',
              canBeExported: true
            }
          }
        }
      )
    );
    /* ----------------------
        traffic
    -------------------------- */
    const trafficFields = ['totalClicks', 'organicClicks', 'adClicks'];
    const trafficFieldsMetricFields = trafficFields.map((fieldName) => {
      const field = INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type);
      return {
        ...field,
        displayName: field.displayName
      };
    });
    widgets.push(
      buildMultiGroupByTopEntitiesChartWidgetConfig(
        app,
        indexName,
        entity,
        [
          {
            groupByFieldName: 'retailerId',
            indexName,
            aggregationFieldNames: ['totalClicks']
          }
        ],
        10,
        trafficFieldsMetricFields,
        weekIdField,
        {
          view: {
            chartPropsOverride: {
              enableSwitchingGroupBy: false,
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            },
            anchorName: 'multiGroupByTopEntitiesChartForTraffic'
          },
          data: {
            lazyLoad: true,
            sortByAggregationField: {
              // aggregateByFieldDisplayName: 'Total Traffic',
              // aggregateByFieldName: 'totalClicks',
              // function: 'sum',
              // canBeExported: true
              aggregateByFieldDisplayName: 'Units Sold',
              aggregateByFieldName: 'unitsSold',
              function: 'sum',
              canBeExported: true
            }
          }
        }
      )
    );

    /* ----------------------
        convert rate
    -------------------------- */
    widgets.push(
      buildTopEntitiesWidgetConfig(
        app,
        indexName,
        entity,
        [
          {
            groupByFieldName: 'retailerId',
            indexName,
            aggregationFieldNames: ['convertRate']
          }
        ],
        10,
        weekIdField,
        {
          view: {
            chartPropsOverride: {
              enableSwitchingGroupBy: false,
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            },
            anchorName: 'TopEntitiesChartForConverRate'
          },
          data: {
            lazyLoad: true,
            sortByAggregationField: {
              // aggregateByFieldDisplayName: 'Conversion Rate',
              // aggregateByFieldName: 'convertRate',
              // function: 'sum',
              // canBeExported: true
              aggregateByFieldDisplayName: 'Units Sold',
              aggregateByFieldName: 'unitsSold',
              function: 'sum',
              canBeExported: true
            }
          }
        }
      )
    );

    /* ----------------------
        table view
     -------------------------- */
    widgets.push(buildAggregatedRetailerGridWidget(app, entity));
  } else if (entityType === 'retailer') {
    // All Retailer View
    widgets = [];
    const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
    /* ----------------------
      Header
      - trend chart
      - donut chart
    -------------------------- */

    const donutFields = ['unitsSold', 'retailSales_convertedUSD'];

    widgets.push(
      buildWidgetGroup(
        [
          buildMultiMetricTrendChartWidgetConfig(
            app,
            indexName,
            entity,
            ['unitsSold', 'retailSales_convertedUSD'],
            'weekId',
            dataConfig.weekIdField,
            {
              view: {
                chartPropsOverride: {
                  title: {
                    text: ''
                  },
                  chart: {
                    height: 450,
                    marginTop: 100
                  },
                  // legend: { enabled: false },
                  yAxis: [
                    {
                      visible: false
                    }
                  ]
                },
                container: { style: { width: '100%', display: 'inline-block', marginBottom: '100px' } }
              }
            }
          ),
          buildMultiMetricDonutChartWidgetConfig(
            app,
            indexName,
            entity,
            donutFields,
            'retailerId',
            dataConfig.weekIdField,
            {
              view: {
                chartPropsOverride: {
                  hideFieldDropdowns: true
                }
              }
            }
          )
        ],
        {
          view: {
            container: {
              style: {
                height: '630px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around'
              }
            }
          }
        }
      )
    );

    /* ----------------------
      Retail Sales (Top Retailers)
    -------------------------- */

    const retailSalesFields = [
      'unitsSold',
      'retailSales_convertedUSD',
      'unitsSoldGrowth',
      'retailSales_convertedUSD_Growth'
    ];
    const retailSalesMetricFields = retailSalesFields.map((fieldName) => {
      const field = INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type);
      return {
        ...field,
        displayName: field.displayName
      };
    });
    widgets.push(
      buildMultiGroupByTopEntitiesChartWidgetConfig(
        app,
        indexName,
        entity,
        [
          {
            groupByFieldName: 'retailerId',
            indexName,
            aggregationFieldNames: ['unitsSold']
          }
        ],
        10,
        retailSalesMetricFields,
        weekIdField,
        {
          view: {
            chartPropsOverride: {
              enableSwitchingGroupBy: false,
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            },
            hideTooltipFor: ['unitsSoldGrowth', 'retailSales_convertedUSD_Growth'],
            anchorName: 'multiGroupByTopEntitiesChart'
          },
          data: {
            sortByAggregationField: {
              aggregateByFieldDisplayName: 'Retail Sales',
              aggregateByFieldName: 'retailSales_converted.USD',
              function: 'sum',
              canBeExported: true
            }
          }
        }
      )
    );

    /* ----------------------
      Reviews
      - Options: stars, reviews count
    -------------------------- */

    const reviewFields = ['stars', 'reviewId'];
    const reviewMetricFields = reviewFields.map((fieldName) => {
      const field = INDEX_FIELDS.getField(app.name, 'reviews', fieldName, entity.type);
      return {
        ...field,
        displayName: field.displayName
      };
    });
    const reviewGroupByFields = ['retailerId'].map((groupByFieldName) => ({
      groupByFieldName,
      name: groupByFieldName,
      indexName: 'reviews',
      aggregationFieldNames: reviewFields
    }));
    widgets.push(
      buildMultiGroupByTopEntitiesChartWidgetConfig(
        app,
        'reviews',
        entity,
        reviewGroupByFields,
        10,
        reviewMetricFields,
        weekIdField,
        {
          view: {
            chartPropsOverride: {
              enableSwitchingGroupBy: false,
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            },
            anchorName: 'multiGroupByTopEntitiesChart'
          },
          data: {
            groupByFields: reviewGroupByFields,
            sortFromESS: [
              'multiGroupByTopEntitiesChart_mainEntity_sales_retailerId',
              'retailSales_converted.USD_by_retailerId'
            ]
          }
        }
      )
    );

    /* ----------------------
        Retail Price
    -------------------------- */
    const retailPriceFields = [
      'retailPrice_convertedUSD',
      'wholesalePrice_convertedUSD',
      'retailerGrossMargin_convertedUSD'
    ];
    const retailPriceMetricFields = retailPriceFields.map((fieldName) => {
      const field = INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type);
      return {
        ...field,
        displayName: field.displayName
      };
    });
    widgets.push(
      buildMultiGroupByTopEntitiesChartWidgetConfig(
        app,
        indexName,
        entity,
        [
          {
            groupByFieldName: 'retailerId',
            indexName,
            aggregationFieldNames: ['retailPrice_convertedUSD']
          }
        ],
        10,
        retailPriceMetricFields,
        weekIdField,
        {
          view: {
            chartPropsOverride: {
              enableSwitchingGroupBy: false,
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            },
            anchorName: 'multiGroupByTopEntitiesChartForRetailPrice'
          },
          data: {
            lazyLoad: true,
            sortByAggregationField: {
              aggregateByFieldDisplayName: 'Retail Sales',
              aggregateByFieldName: 'retailSales_converted.USD',
              function: 'sum',
              canBeExported: true
            }
          }
        }
      )
    );

    /* ----------------------
      Content
      - Content Score
      - Content Accuracy
    -------------------------- */
    // The content indexes are slow which slows down the whole page, so we
    // are lazy loading for now
    widgets.push(
      buildTopEntitiesWidgetConfig(
        app,
        'content',
        entity,
        [
          {
            groupByFieldName: 'retailerId',
            aggregationFieldNames: ['contentScore']
          }
        ],
        noOfTopEntities,
        weekIdField,
        {
          replaceSubCategoryWithParent: true,
          view: {
            chartPropsOverride: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              },
              xAxis: [
                {
                  min: 0,
                  max: 9
                }
              ]
            },
            hideTooltip: true
          },
          data: {
            lastWeekOnly: true,
            lazyLoad: true,
            sortFromESS: [
              'multiGroupByTopEntitiesChart_mainEntity_sales_retailerId',
              'retailSales_converted.USD_by_retailerId'
            ]
          }
        }
      )
    );

    widgets.push(
      buildTopEntitiesWidgetConfig(
        app,
        'contentChanged',
        entity,
        [
          {
            groupByFieldName: 'retailerId',
            aggregationFieldNames: ['contentAccuracy']
          }
        ],
        noOfTopEntities,
        weekIdField,
        {
          // singleTimePeriodOverride: false,
          replaceSubCategoryWithParent: true,
          view: {
            chartPropsOverride: {
              title: { text: 'Content Accuracy' },
              horizontalScrolling: {
                enabled: true,
                step: 3
              },
              xAxis: [
                {
                  min: 0,
                  max: 10
                }
              ]
            },
            hideTooltip: true
          },
          data: {
            lastWeekOnly: true,
            lazyLoad: true,
            sortFromESS: [
              'multiGroupByTopEntitiesChart_mainEntity_sales_retailerId',
              'retailSales_converted.USD_by_retailerId'
            ]
          }
        }
      )
    );

    /* ----------------------
      Table View
    -------------------------- */

    widgets.push(buildAggregatedRetailerGridWidget(app, entity));
  } else if (shouldShowNewBeacon() && entityType === 'segment') {
    widgets = [
      {
        name: 'segments',
        CustomComponent: Segments,
        view: {}
      }
    ];

    return {
      CustomPageContainer: GenericPageContainer,
      widgets,
      containerStyle: {}
    };
  } else if (shouldShowNewBeacon() && entityType === 'brand') {
    widgets = [
      {
        name: 'Brands',
        CustomComponent: BrandSummary,
        view: {}
      }
    ];

    return {
      CustomPageContainer: GenericPageContainer,
      widgets,
      containerStyle: {}
    };
  } else if (shouldShowNewBeacon() && entityType === 'searchtermlist') {
    widgets = [
      {
        name: 'Topics',
        CustomComponent: Topics,
        view: {}
      }
    ];

    return {
      CustomPageContainer: GenericPageContainer,
      widgets,
      containerStyle: {}
    };
  } else {
    // TODO: Refactor and move the Homepage logic to here to make it configurable
    // And remove Homepage
    widgets = [
      {
        name: 'home page',
        CustomComponent: Homepage,
        getInnerWidget,
        view: {}
      }
    ];
  }

  const layout = {
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    widgets,
    dataConfig,
    containerStyle: {
      flex: '1 1',
      minWidth: 'initial',
      flexDirection: 'column',
      maxWidth: 1400,
      margin: '30px auto 0 auto',
      padding: 15
    }
  };

  if (shouldShowNewBeacon()) {
    return {
      CustomPageContainer: GenericPageContainer,
      enableComparison: false,
      widgets,
      dataConfig,
      containerStyle: {
        flex: '1 1',
        minWidth: '1200',
        maxWidth: 1400
      }
    };
  }

  if (layout) {
    if (entityType === 'retailer') {
      return addLeftNavToLayout(layout);
    }
  }

  return layout;
}
