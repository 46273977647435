import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FC } from 'react';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface DiscoverFilterDialogProps {
  header: React.ReactNode;
  body: React.ReactNode;
  footer: React.ReactNode;
  showFilter: boolean;
  setShowFilter: (val: boolean) => void;
}

export const DiscoverFilterDialog: FC<DiscoverFilterDialogProps> = ({
  setShowFilter,
  header,
  body,
  footer,
  showFilter
}) => {
  return (
    <div>
      <Dialog open={showFilter} fullWidth maxWidth="md" onClose={() => setShowFilter(false)}>
        <DialogTitle id="customized-dialog-title">{header}</DialogTitle>
        <DialogContent dividers style={{ paddingLeft: '60px' }}>
          {body}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', padding: '16px 24px' }}>{footer}</DialogActions>
      </Dialog>
    </div>
  );
};
