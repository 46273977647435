import React, { FC } from 'react';
import {
  TakeHomeIcon,
  FastBackwardIcon,
  FastForwardIcon,
  FastBackwardSingleIcon,
  FastForwardSingleIcon
} from 'src/components/SvgIcons';
import './DiscoverPaginationGroup.scss';

interface DiscoverPaginationGroupPropTypes {
  onBtFirst: () => void;
  onBtPageBackTen: () => void;
  onBtPrevious: () => void;
  currentPage: number;
  totalPage: number;
  onBtNext: () => void;
  onBtPageTen: () => void;
}

const DiscoverPaginationGroup: FC<DiscoverPaginationGroupPropTypes> = ({
  onBtFirst,
  onBtPageBackTen,
  onBtPrevious,
  currentPage,
  totalPage,
  onBtNext,
  onBtPageTen
}) => {
  return (
    <div className="paginationContainer">
      <div role="button" onClick={onBtFirst} className="takeHomeButtonContainer">
        <TakeHomeIcon style={{ width: '16px' }} />
      </div>
      <div role="button" onClick={onBtPageBackTen}>
        <FastBackwardIcon style={{ width: '9.8px', height: '9.9px', objectFit: 'contain', verticalAlign: 'middle' }} />
      </div>
      <div role="button" onClick={onBtPrevious}>
        <FastBackwardSingleIcon
          style={{ width: '9.8px', height: '9.9px', objectFit: 'contain', verticalAlign: 'middle' }}
        />
      </div>

      <span style={{ marginTop: '.1%', fontWeight: 500, fontSize: '14px', color: '#0f2847' }}>
        Page {currentPage} of {totalPage}{' '}
      </span>
      <div role="button" onClick={onBtNext}>
        <FastForwardSingleIcon
          style={{ width: '9.8px', height: '9.9px', objectFit: 'contain', verticalAlign: 'middle' }}
        />
      </div>
      <div role="button" onClick={onBtPageTen}>
        <FastForwardIcon style={{ width: '9.8px', height: '9.9px', objectFit: 'contain', verticalAlign: 'middle' }} />
      </div>
    </div>
  );
};

export default DiscoverPaginationGroup;
