import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import { startFetchData, receiveOmniShareOfShelfShards, endFetchData } from './actions';
import ReduxStore from 'src/types/store/reduxStore';
import { OmniBaseRequestBody } from 'src/components/Omni/omniRequestUtils';

export interface OmniShareOfShelfShardsBody extends OmniBaseRequestBody {
  shareOfShelfType: string;
  topSearchResults?: string;
}

const getData = (retailerData: any[]) => {
  return retailerData.buckets.map((ele) => {
    const brandData = [];
    ele.ownedBrandsFilter.brandId.buckets.forEach((ownedBrandData) => {
      if (ownedBrandData && ownedBrandData.shareOfShelf && ownedBrandData.shareOfShelf.value) {
        brandData.push({
          brandId: ownedBrandData.key,
          value: ownedBrandData.shareOfShelf.value
        });
      }
    });
    return {
      retailerId: `${ele.key}`,
      brandData
    };
  });
};

export const fetchOmniShareOfShelfShardsData =
  (apiBody: OmniShareOfShelfShardsBody, name: string) => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
    dispatch(startFetchData({ name, data: [] }));
    try {
      const response = await axios.post('/omni/shareOfShelf/getShareOfShelfShards', apiBody);
      if (response && response.status === 200) {
        const { data } = response.data;
        const { instacartRetailerId, retailerId } = data;
        const processedData = [];
        if (retailerId) {
          let retailerData = getData(retailerId);
          // temp disable showing the instacart
          retailerData = retailerData.filter((ele) => ele.retailerId !== '63');

          const findInstacartIndex = retailerData.findIndex((ele) => ele.retailerId === '63');
          if (findInstacartIndex !== -1) {
            const temp = retailerData[findInstacartIndex];
            retailerData[findInstacartIndex] = retailerData[retailerData.length - 1];
            retailerData[retailerData.length - 1] = temp;
          }
          // sort shards data retailer part should be sorted
          retailerData.sort((ele1, ele2) => {
            return Number(ele1.retailerId) < Number(ele2.retailerId) ? -1 : 1;
          });

          processedData.push(...retailerData);
        }
        if (instacartRetailerId) {
          processedData.push(...getData(instacartRetailerId));
        }

        dispatch(receiveOmniShareOfShelfShards({ name, data: processedData }));
        dispatch(endFetchData({ name, data: [] }));
      }
    } catch (e) {
      console.warn(e);
    }
  };
