import { Box } from '@mui/system';
import React from 'react';
import { useConvertPaidTrafficFieldName } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Traffic/PaidSearch/paidSearchUtil';
import KeyMetricCards, {
  KeyMetricCardDefinition
} from 'src/components/BeaconRedesignComponents/KeyMetricCards/KeyMetricCards';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { KEY_METRICS_CONTAINER_ADJUSTED_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { useAppSelector } from 'src/utils/Hooks';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { useCreateRemoveRetailPriceRangeFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';

/**
 * Card title overrides
 */
const titleOverrides = {
  impressions: 'Ad Impressions',
  searchKeyword: 'Keywords',
  costPerClick: 'CPC',
  costPerAcquisition: 'CPA',
  conversionRate: 'Conversion',
  clicks: 'Ad Clicks',
  spend: 'Ad Spend',
  sales: 'Ad Sales',
  unitsSold: 'Ad Units Sold'
};

/**
 * Overrides for getting the field
 */
const fieldOverrides = {
  searchKeyword: 'weekId'
};

/**
 * Aggregation builders for each card by field name
 */
const aggregationBuilders = {
  searchKeyword: (aggregationBuilder: AggregationBuilder) => aggregationBuilder.addConditionRangeFilter('clicks', 1)
};

const indexNameOverrides = {
  searchKeyword: 'advertising'
};

const useCardDefinitionsByColumn = () => {
  const removeRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();
  // TODO: Use ad-product-metrics index when available for topics
  const mainEntityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const indexName = mainEntityType === 'searchtermlist' ? 'advertising' : 'ad-product-metrics';
  const { convertedBuilderFunc } = useConvertPaidTrafficFieldName();
  return [
    ['clicks', 'impressions', 'clickThroughRate', 'searchKeyword'],
    ['spend', 'costPerClick', 'costPerMilli', 'costPerAcquisition'],
    ['sales', 'conversionRate', 'unitsSold', 'returnOnAdSpend']
  ].map((indexNames) =>
    indexNames.map((fieldName) => {
      const def: KeyMetricCardDefinition = {
        indexName: indexNameOverrides[fieldName] || indexName,
        fieldName,
        title: titleOverrides[fieldName],
        entityType: fieldOverrides[fieldName],
        primaryAggregationBuilder: aggregationBuilders[fieldName],
        secondaryAggregationBuilder: aggregationBuilders[fieldName],
        requestBuilder: (builder) => {
          return convertedBuilderFunc(builder.apply(removeRetailPriceRangeFilters()));
        }
      };

      return def;
    })
  );
};

/**
 * Shows the key metric cards on the traffic paid search page
 */
const PaidSearchKeyMetricCards = () => {
  const cardDefinitionsByColumn = useCardDefinitionsByColumn();
  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" gap="lg">
        <CommonSummaryInfoSubtitle title="Paid Search" includeLegend />
        <Box
          sx={{
            width: KEY_METRICS_CONTAINER_ADJUSTED_WIDTH,
            transform: 'translate(-16px)'
          }}
        >
          <KeyMetricCards cardDefinitionsByColumn={cardDefinitionsByColumn} />
        </Box>
      </Flex>
    </Flex>
  );
};

export default PaidSearchKeyMetricCards;
