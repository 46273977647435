import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import ReduxStore from 'src/types/store/reduxStore';
import Creators from './actions';
import { parseAdBusinessUnits } from './selectors';

export const { clearAdBusinessUnits, receiveAdBusinessUnits } = Creators;

export const fetchAdBusinessUnits = () => (dispatch: ThunkDispatch<ReduxStore, void, any>) =>
  axios
    .get(`/apiAdManager/adBusinessUnits`)
    .then((response) => dispatch(receiveAdBusinessUnits(parseAdBusinessUnits(response.data))));
