import React from 'react';
import { useStacklineTheme } from '@stackline/ui';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

export interface EntityPillProps {
  children: React.ReactNode;
}

/**
 * Small pill for use in the entity card to be displayed
 * above the left nav. It displays the type of the main entity,
 * such as "Category", "Product", etc.
 */
const EntityPill = ({ children }: EntityPillProps) => {
  const theme = useStacklineTheme();

  return (
    <div
      style={{
        backgroundColor: theme.colors.secondaryPorcelain,
        borderRadius: '12px',
        padding: '4px 24px',
        display: 'inline-block'
      }}
    >
      {/* used to offset Zeplin translation issue */}
      <Text variant="subtitle3" sx={{ lineHeight: '16px' }}>
        {children}
      </Text>
    </div>
  );
};

export default EntityPill;
