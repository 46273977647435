import { getCampaignDisplayStatus } from 'src/components/AdManager/AmsUtils';
import { adCampaignTypesAvailable } from 'src/store/modules/adManager/adCampaignTypes/reducers';
import _get from 'lodash/get';
import { getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS } from '../../parentPlatform/platformUtils';
import { shouldShowCriteo } from 'src/utils/app';

const campaignTypeDisplayNameMapping = {};

adCampaignTypesAvailable.forEach((campaignType) => {
  campaignTypeDisplayNameMapping[campaignType.campaignType] = campaignType.displayName;
});

export const parseAdCampaigns = (adCampaigns, retailerId, tagsArray = []) => {
  const parentPlatform = getParentPlatform();
  let filterRes = [];

  if (shouldShowCriteo() && parentPlatform && parentPlatform === PARENT_PLATFORMS.CRITEO) {
    filterRes = adCampaigns.filter((campaign) => campaign.parentPlatform === PARENT_PLATFORMS.CRITEO);
  } else {
    const checkPortfolioTag = tagsArray.length > 0;
    filterRes = adCampaigns.filter((item) => {
      const ret = `${_get(item, ['extendedAttributes', 'retailerId'], '')}` === retailerId;
      const shouldIncludeCampaign = checkPortfolioTag
        ? item.extendedAttributes &&
          item.extendedAttributes.portfolioName &&
          tagsArray.some((tag) => item.extendedAttributes.portfolioName.includes(tag))
        : true;
      return ret && shouldIncludeCampaign;
    });
  }
  const res2 = filterRes.map((adCampaign) => {
    const statusDisplay = getCampaignDisplayStatus(adCampaign.extendedAttributes);
    const statusDerived = statusDisplay.value;
    // sbv has campaignType as sb so identify sbv using this condition
    const campaignType =
      _get(adCampaign, 'extendedAttributes.amsApiModel.adFormat') === 'video'
        ? 'sponsoredBrandsVideo'
        : adCampaign.extendedAttributes.campaignType;
    return {
      ...adCampaign,
      extendedAttributes: {
        ...adCampaign.extendedAttributes,
        statusDisplay,
        statusDerived,
        campaignTypeDisplayName: campaignTypeDisplayNameMapping[campaignType] || '-NA'
      },
      name: adCampaign.campaignName,
      displayName: adCampaign.campaignName,
      id: adCampaign.campaignId,
      type: 'adCampaign'
    };
  });

  return res2;
};
