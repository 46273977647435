import axios from 'axios';

interface ChangeUserRoleInCurrentDivisionArgs {
  action: 'setAdmin' | 'setOwner' | 'setBasic';
  currentEmail: string;
  targetEmail: string;
}

/**
 * Set the role of a user within a division. Used on the Settings
 * Team page.
 */
export default function ChangeUserRoleInCurrentDivision({
  action,
  currentEmail,
  targetEmail
}: ChangeUserRoleInCurrentDivisionArgs) {
  return axios.post(
    'api/user/ChangeUserRoleInCurrentDivision',
    {},
    {
      params: {
        activeUserEmail: currentEmail,
        targetUserEmail: targetEmail,
        action
      }
    }
  );
}
