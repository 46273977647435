import React, { CSSProperties } from 'react';

const CalendarClearButton = ({
  hasEndDate,
  handleClear,
  styles
}: {
  hasEndDate: boolean;
  handleClear: Function;
  styles?: CSSProperties;
}) => {
  return (
    <div className="calendarButtons" style={{ display: hasEndDate ? 'flex' : 'none', ...styles }}>
      <button onClick={handleClear}>
        <span>CLEAR</span>
      </button>
    </div>
  );
};

export default CalendarClearButton;
