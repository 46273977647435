import React from 'react';
import { Conditions } from 'sl-api-connector/search/conditions';

import Widget from 'src/components/EntityPage/Widget';
import _get from 'lodash/get';
import { PageLayout, WidgetProps, Widget as WidgetType } from 'src/types/application/widgetTypes';

interface WidgetGroupProps {
  pageLayout: PageLayout;
  WidgetComponent?: React.ComponentType<WidgetProps>;
  widget: WidgetType;
  queryConditions: Conditions;
  i: number;
}

/**
 * This component serves as a branch in the widget hierarchy.  It takes an array of widgets as a prop and renders them
 * together inside of a container.  The container that they are rendered inside can be styled via the
 * `widget.view.container.style` prop on the widget group's widget.  It passes extra props that may have been provided
 * from places like `GenericPageContainer`'s `render()` function to all of its child widgets.
 *
 * Since this is just a normal widget, it can also render widget groups as children, creating an arbitrarily nested
 * hierarchy of widgets.
 */
const WidgetGroup = ({ pageLayout, WidgetComponent = Widget as any, widget, ...rest }: WidgetGroupProps) => {
  return (
    <div
      style={_get(widget, 'view.container.style')}
      className={`${_get(widget, 'view.container.className', '')} widget-group-wrapper`}
    >
      {(widget.data.widgets as WidgetType[]).map((childWidget, i) => (
        <WidgetComponent
          pageLayout={pageLayout}
          mainEntityConditions={rest.queryConditions}
          {...rest}
          key={`entitypagecontainer_${childWidget.view.name}_${i}`}
          i={i}
          widget={childWidget}
          noInnerContainer={childWidget.view.noInnerContainer}
        />
      ))}
    </div>
  );
};

export default WidgetGroup;
