import types from './types';

const receiveKeywordOmniCategories = (
  keywordCategories: { keywordCategoryId: string; keywordCategoryName: string }[]
) => ({
  type: types.RECEIVE_ALL_OMNI_KEYWORD_CATEGORIES,
  data: keywordCategories
});

export default { receiveKeywordOmniCategories };
