import { AdvancedSearchQueryAggregation } from 'sl-api-connector/search';
import _cloneDeep from 'lodash/cloneDeep';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { getAppName } from 'src/utils/app';

/**
 * Builds an aggregation for an advanced search request.
 */
export default class AggregationBuilder {
  private aggregation: AdvancedSearchQueryAggregation;

  public constructor(groupByFieldName: string, initialAggregation?: AdvancedSearchQueryAggregation) {
    if (initialAggregation) {
      this.aggregation = initialAggregation;
      this.aggregation.groupByFieldName = groupByFieldName;
      return;
    }

    this.aggregation = {
      aggregationFields: [],
      groupByFieldName,
      sortDirection: null,
      sortByAggregationField: null,
      conditions: {
        termFilters: [],
        rangeFilters: []
      }
    };
  }

  public clone(): AggregationBuilder {
    return new AggregationBuilder(this.aggregation.groupByFieldName, _cloneDeep(this.aggregation));
  }

  public setGroupByFieldName(groupByFieldName: string): AggregationBuilder {
    this.aggregation.groupByFieldName = groupByFieldName;
    return this;
  }

  public clearConditionRangeFilters(): AggregationBuilder {
    this.aggregation.conditions.rangeFilters = [];
    return this;
  }

  public clearAggregationFields(): AggregationBuilder {
    this.aggregation.aggregationFields = [];
    return this;
  }

  public addAggregationField(
    aggregateByFieldDisplayName: string,
    aggregateByFieldName: string,
    functionType: string,
    canBeExported: boolean,
    aggregateByFormula?: string
  ): AggregationBuilder {
    // Advanced search will break if we add the same aggregation field twice.
    this.aggregation.aggregationFields = this.aggregation.aggregationFields.filter(
      ({ aggregateByFieldName: aggFieldName }) => {
        return aggregateByFieldName !== aggFieldName;
      }
    );

    this.aggregation.aggregationFields.push({
      aggregateByFieldDisplayName,
      aggregateByFieldName,
      function: functionType,
      canBeExported,
      ...(aggregateByFormula ? { aggregateByFormula } : {})
    });

    return this;
  }

  /**
   * Given an index name and field name, add the aggregation field to the aggregation.
   * If the field is computed, it will add the fields' dependencies as they will be
   * required by advanced search.
   */
  public addAggregationFieldsByIndexAndField(indexName: string, fieldName: string): AggregationBuilder {
    const field = INDEX_FIELDS.getField(getAppName(), indexName, fieldName);

    this.addAggregationField(
      field.displayName,
      field.name,
      field.aggregationFunction,
      true,
      field.aggregationFunctionExpression
    );

    if (field.dependentFields) {
      field.dependentFields.forEach((dependentField) => {
        this.addAggregationField(
          dependentField.displayName,
          dependentField.name,
          dependentField.aggregationFunction,
          true
        );
      });
    }

    return this;
  }

  public setSortDirection(sortDirection: string): AggregationBuilder {
    this.aggregation.sortDirection = sortDirection;
    return this;
  }

  public setSortByAggregationField(
    aggregateByFieldDisplayName: string,
    aggregateByFieldName: string,
    func: string,
    canBeExported: boolean
  ): AggregationBuilder {
    this.aggregation.sortByAggregationField = {
      aggregateByFieldName,
      function: func,
      aggregateByFieldDisplayName,
      canBeExported
    };
    return this;
  }

  // Reviews need to have null value as sort by Aggregation
  public setSortByAggregationFieldAsNull(): AggregationBuilder {
    this.aggregation.sortByAggregationField = null;
    return this;
  }

  public addConditionTermFilter(fieldName: string, values: string[]): AggregationBuilder {
    this.aggregation.conditions.termFilters.push({
      fieldName,
      values
    });
    return this;
  }

  public addConditionTermFilterWithCondition(
    fieldName: string,
    condition: string,
    values: string[]
  ): AggregationBuilder {
    this.aggregation.conditions.termFilters.push({
      fieldName,
      condition,
      values
    });
    return this;
  }

  public addComparisonRangeFilter(fieldName: string, minValue: number, maxValue: number): AggregationBuilder {
    if (!this.aggregation.comparisonRangeFilters) {
      this.aggregation.comparisonRangeFilters = [];
    }
    this.aggregation.comparisonRangeFilters.push({
      fieldName,
      minValue,
      maxValue
    });
    return this;
  }

  public replaceComparisonRangeFilter(fieldName: string, minValue: number, maxValue: number): AggregationBuilder {
    this.aggregation.comparisonRangeFilters = (this.aggregation.comparisonRangeFilters || []).filter((row) => {
      const { fieldName: filterFieldName } = row;
      return filterFieldName !== fieldName;
    });

    this.addComparisonRangeFilter(fieldName, minValue, maxValue);
    return this;
  }

  public replaceConditionRangeFilter(fieldName: string, minValue: number, maxValue?: number): AggregationBuilder {
    this.aggregation.conditions.rangeFilters = (this.aggregation.conditions.rangeFilters || []).filter((row) => {
      const { fieldName: filterFieldName } = row;
      return filterFieldName !== fieldName;
    });

    this.addConditionRangeFilter(fieldName, minValue, maxValue);

    return this;
  }

  public addConditionRangeFilter(fieldName: string, minValue: number, maxValue?: number): AggregationBuilder {
    this.aggregation.conditions.rangeFilters.push({
      fieldName,
      minValue,
      ...(maxValue ? { maxValue } : {})
    });
    return this;
  }

  /**
   * Applies a function to the request builder. It allows for a more functional approach to building the request,
   * as opposed to calling a function that directly modifies the builder
   */
  public apply(builder: (requestBuilder: AggregationBuilder) => AggregationBuilder): AggregationBuilder {
    return builder(this);
  }

  public build(): AdvancedSearchQueryAggregation {
    return this.aggregation;
  }
}
