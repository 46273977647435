import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

export const OMNI_PRICE_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  retailPrice: {
    name: 'retailPrice',
    displayName: 'Retail Price',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY
  },
  avgRetailPrice: {
    name: 'avgRetailPrice',
    displayName: 'Average Retail Price',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY
  },
  deliveryRetailPrice: {
    name: 'deliveryRetailPrice',
    displayName: 'Delivery Retail Price',
    fulfillmentType: 'delivery',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY
  },
  shippingRetailPrice: {
    name: 'shippingRetailPrice',
    displayName: 'Shipping Retail Price',
    aggregationFunction: 'avg',
    fulfillmentType: 'shipping',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY
  },
  pickUpRetailPrice: {
    name: 'pickUpRetailPrice',
    displayName: 'Pickup Retail Price',
    aggregationFunction: 'avg',
    fulfillmentType: 'pickUp',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY
  },
  totalCountOfPromotions: {
    name: 'totalCountOfPromotions',
    displayName: 'Promotions Count',
    aggregationFunction: 'aggregate',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  countOfPromotionsSummaryTopEntities: {
    name: 'countOfPromotionsSummaryTopEntities',
    displayName: 'Promotions Count ',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },

  totalCountOfPromotionsTopEntities: {
    name: 'countOfPromotionsSummaryTopEntities',
    displayName: 'Products with Promotion',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },

  countOfPromotions: {
    name: 'countOfPromotions',
    displayName: 'Delivery Product Count with Promotion',
    aggregationFunction: 'avg',
    fulfillmentType: 'delivery',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },

  countOfPromotionsShipping: {
    name: 'countOfPromotionsShipping',
    displayName: 'Shipping Product Count with Promotion',
    aggregationFunction: 'avg',
    fulfillmentType: 'shipping',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  countOfPromotionsPickUp: {
    name: 'countOfPromotionsPickUp',
    displayName: 'Pickup Product Count with Promotion',
    aggregationFunction: 'avg',
    fulfillmentType: 'pickUp',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  storesWithPromotions: {
    name: 'storesWithPromotions',
    displayName: 'Stores With Delivery Promotions',
    aggregationFunction: 'avg',
    fulfillmentType: 'delivery',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  storesWithPromotionsShipping: {
    name: 'storesWithPromotions',
    displayName: 'Stores With Shipping Promotions',
    aggregationFunction: 'avg',
    fulfillmentType: 'shipping',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  storesWithPromotionsPickUp: {
    name: 'storesWithPromotions',
    displayName: 'Stores With Pickup Promotions',
    aggregationFunction: 'avg',
    fulfillmentType: 'pickUp',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },

  promotionPrice: {
    name: 'promotionsPrice',
    displayName: 'Promotion Price',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY
  },

  avgPromotionPrice: {
    name: 'promotionsPrice',
    displayName: 'Average Promotion Price',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY
  },
  promotionsPrice: {
    name: 'promotionsPrice',
    displayName: 'Delivery Promotion Price',
    aggregationFunction: 'avg',
    fulfillmentType: 'delivery',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY
  },
  promotionsPriceShipping: {
    name: 'promotionsPrice',
    displayName: 'Shipping Promotion Price',
    aggregationFunction: 'avg',
    fulfillmentType: 'shipping',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY
  },
  promotionsPricePickup: {
    name: 'promotionsPrice',
    displayName: 'Pickup Promotion Price',
    aggregationFunction: 'avg',
    fulfillmentType: 'pickUp',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY
  },
  priceViolationsCountSummaryTrend: {
    name: 'priceViolationsCountSummaryTrend',
    displayName: 'Violations Count',
    aggregationFunction: 'aggregate',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  priceViolationsCountSummaryTopEntities: {
    name: 'priceViolationsCount',
    displayName: 'Violations Count',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },

  totalPriceViolationCount: {
    name: 'priceViolationsCount',
    displayName: 'Total Violation Count ',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },

  priceViolationsCount: {
    name: 'priceViolationsCount',
    displayName: 'Delivery Violations Count',
    aggregationFunction: 'avg',
    fulfillmentType: 'delivery',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  priceViolationsCountShipping: {
    name: 'priceViolationsCount',
    displayName: 'Shipping Violations Count',
    aggregationFunction: 'avg',
    fulfillmentType: 'shipping',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  priceViolationsCountPickUp: {
    name: 'priceViolationsCount',
    displayName: 'Pickup Violations Count',
    aggregationFunction: 'avg',
    fulfillmentType: 'pickUp',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  storesWithPriceViolation: {
    name: 'storesWithPriceViolation',
    displayName: 'Stores With Violations Delivery',
    aggregationFunction: 'avg',
    fulfillmentType: 'delivery',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  storesWithPriceViolationShipping: {
    name: 'storesWithPriceViolation',
    displayName: 'Stores With Violations Shipping',
    aggregationFunction: 'avg',
    fulfillmentType: 'shipping',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  storesWithPriceViolationPickUp: {
    name: 'storesWithPriceViolation',
    displayName: 'Stores With Violations Pickup',
    aggregationFunction: 'avg',
    fulfillmentType: 'pickUp',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  retailProductCount: {
    name: 'retailProductCount',
    displayName: 'retailProductCount',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  }
};
