import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import moment from 'moment';

import './EntityTableContainer.scss';
import convertMetricToDisplayValue from '../gridUtils';
import CustomAgGridHeaderTemplate from './CustomAgGridHeaderTemplate';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import EntityGridHeader from '../Header/EntityGridHeader';

class WeeklyMetricsGridContainer extends Component {
  static propTypes = {
    allWeekIdsByRetailerId: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    entitySearchService: PropTypes.object.isRequired,
    entitySearchServicePropertyName: PropTypes.string,
    location: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    queryConditions: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    widget: PropTypes.object.isRequired,
    entity: PropTypes.object.isRequired
  };

  static defaultProps = {
    entitySearchServicePropertyName: 'mainEntityMetrics'
  };

  constructor(props) {
    super(props);
    const dataTable = this.buildDataTable('desc');
    this.state = { dataTable, sortDirection: 'desc' };
  }

  componentWillReceiveProps(nextProps) {
    const { entitySearchService } = nextProps;
    if (!_isEqual(entitySearchService, this.props.entitySearchService)) {
      const dataTable = this.buildDataTable('desc', nextProps);
      this.setState({ dataTable });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { entitySearchService, entitySearchServicePropertyName } = nextProps;
    return !!entitySearchService[`${entitySearchServicePropertyName}`];
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    params.api.sizeColumnsToFit();
  };

  onSortClick = () => {
    const { dataTable } = this.state;
    const sortDirection = this.state.sortDirection === 'desc' ? 'asc' : 'desc';
    dataTable.rows =
      sortDirection === 'desc'
        ? dataTable.rows.sort((a, b) => b.weekId - a.weekId)
        : dataTable.rows.sort((a, b) => a.weekId - b.weekId);

    this.setState({ sortDirection, dataTable });
  };

  //  Build the array data set needed for the table from Redux (should ultimately be a selector)
  buildDataTable(sortDirection, nextProps) {
    const { entitySearchService, entitySearchServicePropertyName, widget } = nextProps || this.props;
    const mainEntityMetrics = entitySearchService[entitySearchServicePropertyName];

    if (!widget || !_get(mainEntityMetrics, [`${widget.data.chartMainField.name}_by_weekId`, 'data'])) {
      return null;
    }

    const columns = [
      {
        headerName: 'Week Ending',
        field: 'weekEnding',
        headerComponentFramework: CustomAgGridHeaderTemplate,
        onSortFunction: this.onSortClick,
        sortDirection: sortDirection || 'desc'
      },
      ...widget.data.aggregationFields.map((field) => ({
        headerName: field.displayName,
        field: field.name,
        metricField: field
      }))
    ];

    const groupByFieldName = widget.data.groupByField.name;
    const mainFieldMetrics = mainEntityMetrics[`${widget.data.aggregationFields[0].name}_by_${groupByFieldName}`];
    let rows = mainFieldMetrics.data.map(({ weekId, weekEnding }, i) => ({
      weekId,
      weekEnding,
      ...widget.data.aggregationFields.reduce(
        (acc, { name }) => ({
          ...acc,
          [name]: mainEntityMetrics[`${name}_by_${groupByFieldName}`].data[i].value
        }),
        {}
      )
    }));
    rows =
      sortDirection === 'desc' ? rows.sort((a, b) => b.weekId - a.weekId) : rows.sort((a, b) => a.weekId - b.weekId);
    return { columns, rows };
  }

  render() {
    const { dataTable } = this.state;
    const { app, retailer, entitySearchService, entitySearchServicePropertyName } = this.props;
    if (!dataTable || !dataTable.columns || !dataTable.rows) {
      return null;
    }
    const { apiRequest } = entitySearchService[`${entitySearchServicePropertyName}`];

    const buildColumn = (dataRow, column) =>
      column.field === 'weekEnding'
        ? moment(dataRow.weekEnding).format('MMM D, YYYY')
        : convertMetricToDisplayValue(
            retailer,
            dataRow[`${column.field}`],
            column.metricField.metricType,
            `${retailer.currencySymbol}`,
            true,
            column.metricField.dataType
          );

    const buildRow = (dataRow) =>
      dataTable.columns.reduce(
        (acc, column) => ({
          ...acc,
          [column.field]: buildColumn(dataRow, column)
        }),
        {}
      );

    const buildRows = () => dataTable.rows.map(buildRow);

    const gridOptions = { columnDefs: dataTable.columns, buildRows };

    return (
      <>
        <EntityGridHeader app={app} enableSwitchingLayouts={false} title="Weeks" exportRequest={apiRequest} />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ overflow: 'hidden', flexGrow: '1' }}>
            <CustomAgMaterial
              buildRows={gridOptions.buildRows}
              columnDefs={gridOptions.columnDefs}
              onGridReady={this.onGridReady}
              onCellClicked={this.onGridReady}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, [
    'allWeekIdsByRetailerId',
    'app',
    'comparisonTimePeriod',
    'entityService',
    'entitySearchService',
    'mainTimePeriod',
    'retailer'
  ]);

export default withRouter(connect(mapStateToProps)(WeeklyMetricsGridContainer));
