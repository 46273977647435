import { useCallback, useMemo } from 'react';
import { useAdvancedSearchRequestBuilder } from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import {
  useForecastStartAndEndWeekIds,
  useForecastPeriod,
  useForecastType,
  useLatestForecastModel
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import {
  ForecastPeriod,
  ForecastType
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import {
  getFirstWeekIdOfYear,
  getPreviousWeekId,
  getWeekIdRange,
  weekIdToTimestamp,
  yearPartOfWeekId
} from 'src/utils/dateUtils';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { getAppName } from 'src/utils/app';
import { useAppSelector } from 'src/utils/Hooks';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import {
  AdvancedSearchByForecastWeekIdResponse,
  YoyUnitsChangeUnadjustedAdditionalValues,
  AdvancedSearchByWeekIdResponse,
  YoyUnitsChangeAdjustedAdditionalValues
} from './types';
import { FORECAST_SUMMARY_KEYMETRIC_QUERY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastQueryKeys';
import {
  useApplyEntityFilters,
  useCreateRemoveRetailPriceRangeFilters
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';

const unadjustedFields = [
  'organicTrafficScaledContribution',
  'paidTrafficScaledContribution',
  'otherTrafficScaledContribution',
  'retailPriceScaledContribution',
  'contentScoreScaledContribution',
  'inStockRateScaledContribution',
  'ratingScaledContribution',
  'buyBoxScaledContribution',
  'actualTotalUnitsSoldChange'
];

const fieldNameToYoyUnitMap = {
  organicClicks: 'organicTrafficScaledContribution',
  otherClicks: 'otherTrafficScaledContribution',
  contentScore: 'contentScoreScaledContribution',
  weightedRating: 'ratingScaledContribution',
  clicks: 'paidTrafficScaledContribution',
  retailPrice: 'retailPriceScaledContribution',
  instockRate: 'inStockRateScaledContribution',
  winPercentage: 'buyBoxScaledContribution'
};

/**
 * Returns the column chart data for units changed for a given field
 */
const useBeaconUnitsChange = () => {
  const actualYoyChangeRequestBuilder = useAdvancedSearchRequestBuilder(
    'unitsChangeForecastByweekId',
    'beacon-actual-yoy-units-change'
  );
  const unadjustedYoyChangeRequestBuilder = useAdvancedSearchRequestBuilder(
    'unadjustedYoyUnitsChangeByweekId_weekId',
    'beacon-unadjusted-yoy-units-change'
  );
  const adjustedYoyChangeRequestBuilder = useAdvancedSearchRequestBuilder(
    'adjustedYoyUnitsChangeByweekId_weekId',
    'beacon-adjusted-yoy-units-change'
  );

  const forecastPeriod = useForecastPeriod();
  const forecastType = useForecastType();
  const retailerId = useAppSelector((state) => state.retailer.id);

  const { modelVersion, loading: modelVersionLoading, currentPublishVersion } = useLatestForecastModel();
  const { startWeekId: forecastStartWeekId, endWeekId: forecastEndWeekId } = useForecastStartAndEndWeekIds();
  const removeRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();

  // To avoid gaps from the published data, we fetch the actual data up until the published week ID
  const actualEndWeekId = useMemo(() => {
    return getPreviousWeekId(forecastStartWeekId);
  }, [forecastStartWeekId]);

  const actualStartWeekId = useMemo(
    () =>
      forecastPeriod === ForecastPeriod.FULL_YEAR
        ? getFirstWeekIdOfYear(yearPartOfWeekId(forecastStartWeekId))
        : forecastStartWeekId,
    [forecastPeriod, forecastStartWeekId]
  );

  const applyEntityFilters = useApplyEntityFilters();

  const parseUnitsChangeResponse = useCallback(
    (
      fieldName: string,
      actualResponse: AdvancedSearchByWeekIdResponse<YoyUnitsChangeUnadjustedAdditionalValues>,
      unadjustedResponse: AdvancedSearchByForecastWeekIdResponse<YoyUnitsChangeUnadjustedAdditionalValues>,
      adjustedResponse?: AdvancedSearchByForecastWeekIdResponse<YoyUnitsChangeAdjustedAdditionalValues>
    ): number[][] => {
      const actualWeekIdToValuesMap = actualResponse.aggregations.by_weekId.reduce(
        (acc, row) => ({
          ...acc,
          [row.fieldId]: row.additionalValues
        }),
        {} as { [key: string]: YoyUnitsChangeUnadjustedAdditionalValues }
      );

      // Map the week ID to the values so we can add it to the actual values
      const unadjustedWeekIdToValuesMap = unadjustedResponse.aggregations.by_forecastWeekId.reduce(
        (acc, row) => ({
          ...acc,
          [row.fieldId]: row.additionalValues
        }),
        {} as { [key: string]: YoyUnitsChangeUnadjustedAdditionalValues }
      );

      const adjustedWeekIdToValuesMap = adjustedResponse
        ? adjustedResponse.aggregations.by_forecastWeekId.reduce(
            (acc, row) => ({
              ...acc,
              [row.fieldId]: row.additionalValues
            }),
            {} as { [key: string]: YoyUnitsChangeAdjustedAdditionalValues }
          )
        : {};

      return getWeekIdRange(actualStartWeekId, forecastEndWeekId).map((weekId) => {
        const actualValue = actualWeekIdToValuesMap[weekId]
          ? actualWeekIdToValuesMap[weekId][`${fieldName}_sum_value`] || 0
          : 0;
        const unadjustedValue = unadjustedWeekIdToValuesMap[weekId]
          ? unadjustedWeekIdToValuesMap[weekId][`${fieldName}_sum_value`] || 0
          : 0;
        const adjustedValue = adjustedWeekIdToValuesMap[weekId]
          ? adjustedWeekIdToValuesMap[weekId][`${fieldName}Delta_sum_value`] || 0
          : 0;

        return [weekIdToTimestamp(weekId), actualValue + unadjustedValue + adjustedValue];
      });
    },
    [actualStartWeekId, forecastEndWeekId]
  );

  const actualYoyChangeRequest = useMemo(() => {
    const aggregationBuilder = new AggregationBuilder('weekId').addConditionTermFilter('retailerId', [retailerId]);

    unadjustedFields.forEach((unadjustedFieldName) => {
      const field = INDEX_FIELDS.getField(getAppName(), 'actual-yoy-units-change', unadjustedFieldName);
      aggregationBuilder.addAggregationField(field.displayName, unadjustedFieldName, field.aggregationFunction, true);
    });

    const field = INDEX_FIELDS.getField(getAppName(), 'actual-yoy-units-change', 'organicTrafficScaledContribution');
    actualYoyChangeRequestBuilder
      .setPageNumber(1)
      .setPageSize(1200)
      .setPeriod('year')
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .addConditionRangeFilter('weekId', actualStartWeekId, actualEndWeekId)
      .setSearchBy('parent')
      .addAggregation(aggregationBuilder.build())
      .addSortField(field.displayName, field.name, field.aggregationFunction, true)
      .apply(applyEntityFilters)
      .apply(removeRetailPriceRangeFilters());

    return actualYoyChangeRequestBuilder.build();
  }, [
    retailerId,
    actualYoyChangeRequestBuilder,
    actualStartWeekId,
    actualEndWeekId,
    applyEntityFilters,
    removeRetailPriceRangeFilters
  ]);

  const unadjustedYoyChangeRequest = useMemo(() => {
    const aggregationBuilder = new AggregationBuilder('forecastWeekId').addConditionTermFilter('retailerId', [
      retailerId
    ]);

    unadjustedFields.forEach((unadjustedFieldName) => {
      const field = INDEX_FIELDS.getField(getAppName(), 'unadjusted-yoy-units-change', unadjustedFieldName);
      aggregationBuilder.addAggregationField(field.displayName, unadjustedFieldName, field.aggregationFunction, true);
    });

    const field = INDEX_FIELDS.getField(
      getAppName(),
      'unadjusted-yoy-units-change',
      'organicTrafficScaledContribution'
    );
    unadjustedYoyChangeRequestBuilder
      .setPageNumber(1)
      .setPageSize(1200)
      .setPeriod('year')
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
      .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
      .addConditionRangeFilter('forecastWeekId', forecastStartWeekId, forecastEndWeekId)
      .setSearchBy('parent')
      .addAggregation(aggregationBuilder.build())
      .addSortField(field.displayName, field.name, field.aggregationFunction, true)
      .apply(applyEntityFilters)
      .apply(removeRetailPriceRangeFilters());

    return unadjustedYoyChangeRequestBuilder.build();
  }, [
    applyEntityFilters,
    currentPublishVersion,
    forecastEndWeekId,
    forecastStartWeekId,
    modelVersion,
    removeRetailPriceRangeFilters,
    retailerId,
    unadjustedYoyChangeRequestBuilder
  ]);

  const adjustedYoyChangeRequest = useMemo(() => {
    const aggregationBuilder = new AggregationBuilder('forecastWeekId').addConditionTermFilter('retailerId', [
      retailerId
    ]);

    unadjustedFields.forEach((unadjustedFieldName) => {
      const field = INDEX_FIELDS.getField(getAppName(), 'adjusted-yoy-units-change', `${unadjustedFieldName}Delta`);
      aggregationBuilder.addAggregationField(field.displayName, field.name, field.aggregationFunction, true);
    });

    const field = INDEX_FIELDS.getField(getAppName(), 'adjusted-yoy-units-change', 'actualTotalUnitsSoldChangeDelta');
    adjustedYoyChangeRequestBuilder
      .setPageNumber(1)
      .setPageSize(1200)
      .setPeriod('year')
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
      .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
      .addConditionRangeFilter('forecastWeekId', forecastStartWeekId, forecastEndWeekId)
      .setSearchBy('parent')
      .addAggregation(aggregationBuilder.build())
      .addSortField(field.displayName, field.name, field.aggregationFunction, true)
      .apply(applyEntityFilters)
      .apply(removeRetailPriceRangeFilters());

    return adjustedYoyChangeRequestBuilder.build();
  }, [
    retailerId,
    adjustedYoyChangeRequestBuilder,
    modelVersion,
    currentPublishVersion,
    forecastStartWeekId,
    forecastEndWeekId,
    applyEntityFilters,
    removeRetailPriceRangeFilters
  ]);

  const { data: unitsChangeResponse, ...rest } = useGenericAdvancedSearch<
    [
      AdvancedSearchByWeekIdResponse<YoyUnitsChangeUnadjustedAdditionalValues>,
      AdvancedSearchByForecastWeekIdResponse<YoyUnitsChangeUnadjustedAdditionalValues>,
      AdvancedSearchByForecastWeekIdResponse<YoyUnitsChangeAdjustedAdditionalValues>
    ]
  >({
    queryKeys: [
      FORECAST_SUMMARY_KEYMETRIC_QUERY,
      actualYoyChangeRequest,
      unadjustedYoyChangeRequest,
      adjustedYoyChangeRequest
    ],
    requestBody: [actualYoyChangeRequest, unadjustedYoyChangeRequest, adjustedYoyChangeRequest],
    shouldPerformFetch: !modelVersionLoading,
    requestId: 'unitsChangeForecastByweekId',
    allowCancel: false // Gets invalided after creating an adjustment
  });

  const getData = useCallback(
    (fieldName: string) => {
      const fieldNameToUse = fieldNameToYoyUnitMap[fieldName] || 'actualTotalUnitsSoldChange';
      return unitsChangeResponse && unitsChangeResponse.data[0]
        ? parseUnitsChangeResponse(
            fieldNameToUse,
            unitsChangeResponse.data[0],
            unitsChangeResponse.data[1],
            forecastType === ForecastType.ADJUSTED ? unitsChangeResponse.data[2] : undefined
          )
        : [];
    },
    [forecastType, parseUnitsChangeResponse, unitsChangeResponse]
  );

  const getMetricTotal = useCallback(
    (fieldName: string) => {
      return getData(fieldName).reduce((acc, [_, value]) => acc + value, 0);
    },
    [getData]
  );

  return {
    ...rest,
    getData,
    isLoading: modelVersionLoading || rest.isLoading,
    getMetricTotal
  };
};

export default useBeaconUnitsChange;
