/**
 * Defines the translations of the sign in container when indicating a failed sign in
 */
const shakeTranslations = (shakeky: number) => [
  { value: -1 * shakeky },
  { value: 2 * shakeky },
  { value: -4 * shakeky },
  { value: 4 * shakeky },
  { value: -4 * shakeky },
  { value: 4 * shakeky },
  { value: -4 * shakeky },
  { value: 2 * shakeky },
  { value: -1 * shakeky },
  { value: 0 * shakeky }
];

export default shakeTranslations;
