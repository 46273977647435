import {
  FirstTouchAttributionAdvancedSearchResponse,
  FirstTouchAttributionData
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/FirstTouchAttribution/types';
import _get from 'lodash/get';

/**
 * Parses advanced search response from First Touch Attribution request
 */
export const adFirstTouchAttributionAdvancedSearchParser = (response: FirstTouchAttributionAdvancedSearchResponse) => {
  const firstTouchAttributionData: FirstTouchAttributionData[] = _get(
    response,
    ['aggregations', 'by_adProductType.keyword'],
    []
  );
  return firstTouchAttributionData;
};

interface BuildRequestForFirstTouchAttributionParameters {
  startDayId: string | number;
  endDayId: string | number;
  retailerId?: string | number;
}

export const buildRequestForFirstTouchAttribution = ({
  startDayId,
  endDayId,
  retailerId = 1
}: BuildRequestForFirstTouchAttributionParameters) => {
  return [
    {
      name: 'amc_first_touch_attribution',
      id: 'amc_first_touch_attribution',
      pageNumber: 1,
      pageSize: 100,
      doAggregation: true,
      returnDocuments: false,
      conditions: {
        condition: 'must',
        termFilters: [
          {
            fieldName: 'periodStartDate',
            condition: 'should',
            values: [startDayId]
          },
          {
            fieldName: 'periodEndDate',
            condition: 'should',
            values: [endDayId]
          },
          {
            fieldName: 'retailerId',
            condition: 'should',
            values: [retailerId]
          }
        ],
        computeFilters: []
      },
      searchBy: 'parent',
      aggregations: [
        {
          aggregationFields: [
            {
              aggregateByFieldName: 'beaconClientId',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'adSales',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'adUnitsSold',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'orders',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'impressions',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'clicks',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'uniqueShoppersReached',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'usersThatConverted',
              function: 'sum',
              canBeExported: true
            }
          ],
          groupByFieldName: 'adProductType.keyword'
        }
      ],
      searchType: 'advertising-amc-first-touch-attribution',
      processDocuments: false
    }
  ];
};

export const convertFirstTouchAttributionDataToCSV = ({ data, dateRange, primaryMetric, secondaryMetric }): string => {
  const adTypes = ['Sponsored Products', 'DSP', 'Sponsored Brands', 'Sponsored Display'];
  const date = [`${dateRange}`].map((val) => `"${val}"`).join(',');
  const headers = ['Ad Type', `${primaryMetric}`, `${secondaryMetric}`].map((header) => `"${header}"`).join(',');

  const values = data.primaryMetricSeries.map((_, index) => {
    const primaryMetricValue = data.primaryMetricSeries[index] / 100;
    const secondaryMetricValue = data.secondaryMetricSeries[index] / 100;
    const adType = adTypes[index];
    return [`${adType}`, `${primaryMetricValue}`, `${secondaryMetricValue}`].map((val) => `"${val}"`).join(',');
  });

  return `${date}\n${headers}\n${values.join('\n')}`;
};
