import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import CompareOptionCard from './CompareOptionCard';
import { CompareScreens } from './types';
import { useChartComparison } from '../../BeaconMetricSplineChart/ChartComparisonProvider';

interface CompareLandingProps {
  setScreen: React.Dispatch<React.SetStateAction<CompareScreens>>;
}
/**
 * First rendered content for the compare popup, displays
 * the options for Metrics, Groups, and Retailers comparisons
 */
export default function CompareLanding({ setScreen }: CompareLandingProps) {
  const { metricComparator, groupComparator } = useChartComparison();

  return (
    <Flex flexDirection="column" gap="mdl">
      <Flex flexDirection="column" gap="sm">
        <Text variant="h4">Compare</Text>
        <Text variant="body1">Compare metrics and groups to unlock new insights.</Text>
      </Flex>
      <Flex flexDirection="column" gap="md">
        <CompareOptionCard
          title="Metrics"
          subtitle="Compare to metrics within traffic, sales, and conversion."
          onClick={() => setScreen(CompareScreens.Metrics)}
          selected={!!metricComparator}
        />
        <CompareOptionCard
          title="Groups"
          subtitle="Compare to brands, categories, subcategories, segments, and products."
          onClick={() => setScreen(CompareScreens.Groups)}
          selected={!!groupComparator}
        />
      </Flex>
    </Flex>
  );
}
