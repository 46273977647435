/* eslint-disable react/prop-types */

import React, { useState, ReactNode } from 'react';
import { SpeedDial, SpeedDialAction } from '@mui/material';

interface Props {
  menuInfo: {
    mainButton: {
      direction: 'up' | 'down' | 'left' | 'right';
      icon: ReactNode;
    };
    subItems: {
      text: string;
      icon: ReactNode;
      onClick: () => {};
    }[];
    disabled?: boolean;
  };
}

const MenuButton: React.FC<Props> = ({ menuInfo }) => {
  const [open, setOpen] = useState(false);
  const [className, setClassName] = useState('menu-button');

  const handleOpen = () => {
    setOpen(true);
    setClassName('menu-button opened-button');
  };

  const handleClose = () => {
    setOpen(false);
    setClassName('menu-button');
  };

  const handleToggle = () => (open ? handleClose : handleOpen)();

  const getExtraSpeedDialProps = () =>
    'ontouchstart' in document.documentElement
      ? {
          onMouseEnter: handleOpen,
          onBlur: handleClose,
          onClose: handleClose,
          onFocus: handleOpen
        }
      : {};

  const {
    mainButton: { direction, icon },
    subItems
  } = menuInfo;

  return (
    <div className="menu-button-container">
      <SpeedDial
        ariaLabel="speedDial"
        className={className}
        icon={icon}
        open={open}
        FabProps={{
          disabled: menuInfo.disabled
        }}
        onClick={handleToggle}
        onMouseLeave={handleClose}
        direction={direction}
        {...getExtraSpeedDialProps()}
      >
        {subItems.map((item) => {
          const handleClick = () => {
            item.onClick();
            setTimeout(() => handleClose(), 400);
          };

          return (
            <SpeedDialAction
              key={item.text}
              icon={item.icon}
              tooltipOpen={false}
              tooltipTitle={item.text}
              onClick={handleClick}
              ButtonProps={{ onClick: handleClick }}
            />
          );
        })}
      </SpeedDial>
    </div>
  );
};

export default MenuButton;
