import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { UploadedAdjustment } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkAdjustmentProvider';
import usePublishBulkAdjustment from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/hooks/usePublishBulkAdjustment';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { GenericErrorMessage } from 'src/components/BeaconRedesignComponents/common/CommonSnackbarMessages/GenericErrorMessage';
import { useAppSelector, useSnackbar } from 'src/utils/Hooks';
import { trackMixpanelEvent } from 'src/utils/mixpanel';

export default function useSaveAsDraft({
  bulkAdjustmentIdRef,
  closeModal
}: {
  bulkAdjustmentIdRef: React.MutableRefObject<string | null>;
  closeModal: () => void;
}) {
  const { mutateAsync: publishBulkAdjustment } = usePublishBulkAdjustment();
  const { showSnackbar, closeSnackbar } = useSnackbar();
  const { queryParams } = useAppSelector((state) => state.app);

  const handleSaveAsDraft = useCallback(
    async (uploadedAdjustment: UploadedAdjustment) => {
      try {
        trackMixpanelEvent({
          eventName: 'save bulk adjustment as draft',
          data: {
            bulkAdjustmentId: bulkAdjustmentIdRef.current!,
            description: uploadedAdjustment.bulkAdjustmentDescription,
            title: uploadedAdjustment.bulkAdjustmentTitle,
            planType: uploadedAdjustment.planType
          }
        });

        const initialPublishRequest = await publishBulkAdjustment({
          bulkAdjustmentId: bulkAdjustmentIdRef.current!,
          bulkAdjustmentChangeType: 'Absolute',
          bulkAdjustmentDescription: uploadedAdjustment.bulkAdjustmentDescription,
          bulkAdjustmentTitle: uploadedAdjustment.bulkAdjustmentTitle,
          planType: uploadedAdjustment.planType,
          saveAsDraft: true
        });

        if (initialPublishRequest[0].isSuccess) {
          closeModal();
          showSnackbar({
            message: (
              <Flex>
                Your adjustment was successfully saved as a draft. View all adjustments{' '}
                <Link
                  to={`/account/adjustments${queryParams.searchParams}`}
                  onClick={() => closeSnackbar()}
                  style={{
                    textDecoration: 'underline',
                    marginLeft: '4px'
                  }}
                >
                  here
                </Link>
                .
              </Flex>
            ),
            type: 'success'
          });
        } else {
          closeModal();
          showSnackbar({
            message: <GenericErrorMessage />,
            type: 'error'
          });
          trackMixpanelEvent({
            eventName: 'error saving bulk adjustment as draft',
            data: {
              bulkAdjustmentId: bulkAdjustmentIdRef.current!
            }
          });
        }
      } catch (error) {
        closeModal();
        showSnackbar({
          message: <GenericErrorMessage />,
          type: 'error'
        });
        trackMixpanelEvent({
          eventName: 'error saving bulk adjustment as draft',
          data: {
            bulkAdjustmentId: bulkAdjustmentIdRef.current!
          }
        });
      }
    },
    [bulkAdjustmentIdRef, closeModal, closeSnackbar, publishBulkAdjustment, queryParams.searchParams, showSnackbar]
  );

  return handleSaveAsDraft;
}
