import React, { Fragment, useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { withBus } from 'react-bus';
import { compose } from 'redux';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { percentColor } from 'src/utils/metrics';
import { getMetricFieldForSelectedField } from 'src/components/EntityPage/WaterfallChart/waterfallCompute';
import EntityGridRenderer from 'src/components/EntityGrid/EntityGrid/EntityGridRenderer';
import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import { cellRendererFrameworkPropTypes } from 'src/components/EntityGrid/HeaderComponentFrameworks';
import { invertSortDirection } from 'src/components/EntityPage/WaterfallChart/Insights/TrafficInsights/TrafficInsights';
import { mkManualSortHCF, WaterfallFirstColumnHCF } from '../CellRendererFrameworks';
import './MetricsImpactGrid.scss';
import { useBus } from 'src/utils/Hooks';

const mkMetricFieldValueFormatter =
  (retailer, indexFieldOverride) =>
  ({ data, colDef: { field } }) => {
    const value = _get(data, ['cardView', field], 0);
    const indexField = indexFieldOverride || _get(data, ['cardView', 'metricField']);
    return convertMetricToDisplayValue(retailer, value, indexField.metricType, retailer.currencySymbol, true);
  };

const mkPercentChangeFieldCRF = (retailer, indexFieldOverride) => {
  const PercentChangeFieldCRF = ({ data, colDef: { field } }) => {
    const metricFieldName = field.replace('PeriodChange', '');
    const values = _get(data, ['cardView'], {});
    const { [`${metricFieldName}PercentChange`]: percentChange, [`${metricFieldName}PeriodChange`]: periodChange } =
      values;
    const formattedPercentChange = numeral(Math.min(percentChange, 10)).format('+1,000%');
    const indexField = indexFieldOverride || _get(data, ['cardView', 'metricField']);

    const formattedPeriodChange = convertMetricToDisplayValue(
      retailer,
      periodChange,
      indexField.metricType,
      retailer.currencySymbol,
      true
    );

    return (
      <Fragment>
        {formattedPeriodChange}
        <br />
        <span style={{ color: percentColor(formattedPercentChange) }}>{formattedPercentChange}</span>
      </Fragment>
    );
  };
  PercentChangeFieldCRF.propTypes = cellRendererFrameworkPropTypes;

  return PercentChangeFieldCRF;
};

const TotalUnitsSoldCRF = ({ data }) => {
  const { unitsSoldCurrentValue, unitsSoldPercentChange } = _get(data, ['rawData', data.srcIx, 'cardView'], {});
  const formattedPercentChange = numeral(Math.min(unitsSoldPercentChange, 10)).format('+1,000%');

  return (
    <Fragment>
      {numeral(unitsSoldCurrentValue).format('1,000.0a')}
      <br />
      <span style={{ color: percentColor(formattedPercentChange) }}>{formattedPercentChange}</span>
    </Fragment>
  );
};

TotalUnitsSoldCRF.propTypes = cellRendererFrameworkPropTypes;

const UnitsSoldContributionCRF = ({ data, colDef: { field } }) => {
  const value = _get(data, ['rawData', data.srcIx, 'unitSoldImpact', field.replace('Impact', '')], 0);

  return numeral(value).format('+1,000.0a');
};

UnitsSoldContributionCRF.propTypes = cellRendererFrameworkPropTypes;

const MetricsImpactGridHCF = mkManualSortHCF('waterfallMetricsImpactGridSortData');

const buildUnitsSoldMetricFields = (retailer) => {
  const MetricFieldValueFormatter = mkMetricFieldValueFormatter(retailer);
  const PercentChangeFieldCRF = mkPercentChangeFieldCRF(retailer);
  const retailSalesField = INDEX_FIELDS.getField('beacon', 'sales', 'retailSales', 'product', 'stacklineSku');

  return [
    {
      name: 'retailSalesCurrentValue',
      displayName: `Retail Sales|(Current)`,
      valueFormatter: mkMetricFieldValueFormatter(retailer, retailSalesField)
    },
    {
      name: 'retailSalesPreviousValue',
      displayName: `Retail Sales|(Prior)`,
      valueFormatter: mkMetricFieldValueFormatter(retailer, retailSalesField)
    },
    {
      name: 'retailSalesPeriodChange',
      displayName: `Retail Sales|(Change)`,
      cellRendererFramework: mkPercentChangeFieldCRF(retailer, retailSalesField)
    },
    {
      name: 'unitsSoldCurrentValue',
      displayName: `Units Sold|(Current)`,
      valueFormatter: MetricFieldValueFormatter
    },
    {
      name: 'unitsSoldPreviousValue',
      displayName: `Units Sold|(Prior)`,
      valueFormatter: MetricFieldValueFormatter
    },
    {
      name: 'unitsSoldPeriodChange',
      displayName: `Units Sold|(Change)`,
      cellRendererFramework: PercentChangeFieldCRF
    }
  ].map((field) => ({
    ...field,
    minWidth: 100,
    maxWidth: 120,
    headerComponentFramework: MetricsImpactGridHCF,
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  }));
};

const buildOrganicTrafficMetricFields = (retailer, baseColumnDef) =>
  [
    {
      name: 'organicClicksCurrentValue',
      displayName: 'Organic Traffic|(Current)',
      valueFormatter: mkMetricFieldValueFormatter(retailer)
    },
    {
      name: 'organicClicksPreviousValue',
      displayName: 'Organic Traffic|(Prior)',
      valueFormatter: mkMetricFieldValueFormatter(retailer)
    },
    {
      name: 'organicClicksPeriodChange',
      displayName: 'Organic Traffic|(Change)',
      cellRendererFramework: mkPercentChangeFieldCRF(retailer)
    }
  ].map((colDef) => ({
    ...baseColumnDef,
    cellRendererFramework: colDef.valueFormatter ? undefined : baseColumnDef.cellRendererFramework,
    ...colDef,
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  }));

const buildPaidTrafficMetricFields = (retailer, baseColumnDef) =>
  [
    {
      name: 'adClicksCurrentValue',
      displayName: 'Ad Clicks',
      valueFormatter: mkMetricFieldValueFormatter(retailer)
    },
    {
      name: 'adClicksPeriodChange',
      displayName: 'Ad Clicks|(Change)',
      cellRendererFramework: mkPercentChangeFieldCRF(retailer)
    },
    {
      name: 'unitsSoldCurrentValue',
      displayName: 'Total|Units Sold',
      valueFormatter: mkMetricFieldValueFormatter(retailer)
    },
    {
      name: 'adUnitsSoldCurrentValue',
      displayName: 'Ad|Units Sold',
      valueFormatter: mkMetricFieldValueFormatter(retailer)
    },
    {
      name: 'adUnitsSoldPeriodChange',
      displayName: 'Ad Units|Sold (Change)',
      cellRendererFramework: mkPercentChangeFieldCRF(retailer)
    }
  ].map((colDef) => ({
    ...baseColumnDef,
    cellRendererFramework: colDef.valueFormatter ? undefined : baseColumnDef.cellRendererFramework,
    ...colDef,
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  }));

const buildMetricFields = ({ selectedFieldName, title, retailer }) => {
  const baseColumnDef = {
    minWidth: 100,
    maxWidth: 120,
    headerComponentFramework: MetricsImpactGridHCF,
    selectedField: selectedFieldName,
    name: selectedFieldName
  };

  if (selectedFieldName === 'organicClicks') {
    return buildOrganicTrafficMetricFields(retailer, baseColumnDef);
  } else if (selectedFieldName === 'adClicks') {
    return buildPaidTrafficMetricFields(retailer, baseColumnDef);
  } else if (['unitsSoldComparisonPeriod', 'unitsSoldMainPeriod'].includes(selectedFieldName)) {
    return buildUnitsSoldMetricFields(retailer);
  }

  return [
    {
      name: `${selectedFieldName}CurrentValue`,
      displayName: `${title}|(Current)`,
      valueFormatter: mkMetricFieldValueFormatter(retailer)
    },
    {
      name: `${selectedFieldName}PreviousValue`,
      displayName: `${title}|(Prior)`,
      valueFormatter: mkMetricFieldValueFormatter(retailer)
    },
    {
      name: `${selectedFieldName}PeriodChange`,
      displayName: `${title}|(Change)`,
      cellRendererFramework: mkPercentChangeFieldCRF(retailer)
    },
    {
      name: 'unitsSoldPeriodChange',
      displayName: 'Total Units Sold|(Change)',
      cellRendererFramework: TotalUnitsSoldCRF
    },
    {
      name: `${selectedFieldName}Impact`,
      displayName: 'Contribution to|Units Sold',
      cellRendererFramework: UnitsSoldContributionCRF
    }
  ].map((colDef) => ({
    ...baseColumnDef,
    cellRendererFramework: colDef.valueFormatter ? undefined : baseColumnDef.cellRendererFramework,
    ...colDef,
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  }));
};

const buildRowSortConfig = (selectedFieldName, headerFieldName, sortConfig) => {
  const { currentlySortedMetric, sortDirection: curSortDirection } = sortConfig;

  // If the currently sorted metric is clicked, just invert the sort direction.
  const sortDirection = currentlySortedMetric === headerFieldName ? invertSortDirection(curSortDirection) : 'desc';

  const sortRows = headerFieldName.includes('Impact')
    ? (rowDatum) => _get(rowDatum, ['unitSoldImpact', selectedFieldName]) || 0
    : (rowDatum) => _get(rowDatum, ['cardView', headerFieldName]) || 0;

  return { sortDirection, sortRows };
};

const getDefaultSortField = (selectedFieldName) => {
  if (selectedFieldName === 'organicClicks') {
    return 'organicClicksPeriodChange';
  } else if (selectedFieldName === 'adClicks') {
    return 'adUnitsSoldPeriodChange';
  }

  return selectedFieldName.includes('unitsSold') ? 'unitsSoldPeriodChange' : `${selectedFieldName}Impact`;
};

const getDefaultSortConfig = (selectedFieldName, unitsSoldImpactConsolidated) => {
  if (!unitsSoldImpactConsolidated) {
    return null;
  }

  const totalImpactForSelectedMetric = unitsSoldImpactConsolidated[selectedFieldName].y;
  const currentlySortedMetric = getDefaultSortField(selectedFieldName);
  const sortConfig = { currentlySortedMetric, sortDirection: 'asc' };

  return {
    ...buildRowSortConfig(selectedFieldName, currentlySortedMetric, sortConfig),
    sortDirection: totalImpactForSelectedMetric > 0 ? 'desc' : 'asc',
    currentlySortedMetric
  };
};

const WaterfallMetricsImpactGrid = ({
  waterfallImpactData,
  waterfallImpactGridTitle,
  selectedField: { name: selectedFieldName },
  unitsSoldImpactConsolidated,
  parentWidget,
  // Redux props
  app,
  retailer,
  // From `withBus`
  eventBus
}) => {
  const [layout, setLayout] = useState('table');
  const [pageNum, setPageNum] = useState(1);
  const [sortConfig, setSortConfig] = useState(null);

  useEffect(() => {
    if (!sortConfig) {
      setSortConfig(getDefaultSortConfig(selectedFieldName, unitsSoldImpactConsolidated));
    }
  }, [sortConfig, selectedFieldName, unitsSoldImpactConsolidated]);

  useEffect(() => {
    setSortConfig(getDefaultSortConfig(selectedFieldName, unitsSoldImpactConsolidated));
    return () => {
      setPageNum(1);
    };
  }, [selectedFieldName, waterfallImpactData, unitsSoldImpactConsolidated]);

  useBus(eventBus, 'waterfallMetricsImpactGridSortData', ({ fieldName: headerFieldName }) =>
    setSortConfig({
      ...buildRowSortConfig(selectedFieldName, headerFieldName, sortConfig),
      currentlySortedMetric: headerFieldName
    })
  );

  const handleChangeLayout = useCallback(
    (showTableView) => {
      if (!sortConfig) {
        return;
      }

      // If we're showing product cards, we want to switch the default sort to be the main metric.  If we're showing the
      // table, we want to switch the main metric to be the impact to units sold.
      const newSortConfig = showTableView
        ? getDefaultSortConfig(selectedFieldName, unitsSoldImpactConsolidated)
        : {
            ...buildRowSortConfig(selectedFieldName, `${selectedFieldName}CurrentValue`, sortConfig),
            sortDirection: 'desc',
            currentlySortedMetric: `${selectedFieldName}CurrentValue`
          };
      setSortConfig(newSortConfig);

      setLayout(showTableView ? 'table' : 'tile');
    },
    [selectedFieldName, sortConfig, unitsSoldImpactConsolidated]
  );

  const sortedData = useMemo(
    () =>
      sortConfig && sortConfig.sortRows
        ? _orderBy(waterfallImpactData, [sortConfig.sortRows], [sortConfig.sortDirection])
        : waterfallImpactData,
    [sortConfig, waterfallImpactData]
  );

  const pageSize = 20;
  const emulatePagination = (metric, pageNumber) => {
    const itemCount = pageSize * pageNumber;
    return metric.slice(0, itemCount);
  };

  const metricFields = useMemo(
    () => buildMetricFields({ selectedFieldName, title: waterfallImpactGridTitle, retailer }),
    [retailer, selectedFieldName, waterfallImpactGridTitle]
  );

  if (!sortConfig) {
    return null;
  }

  const widget = { data: { configByGroupByFieldName: { stacklineSku: { tableView: { metricFields } } } } };

  const activeGroupByField = INDEX_FIELDS.getField(app.name, 'traffic', 'stacklineSku', 'product');
  const groupByFields = _get(parentWidget, 'groupByFields', []).map((field) => {
    if (field.name === 'stacklineSku') {
      return { ...field, isSelected: true };
    }

    return field;
  });

  return (
    <div className="waterfall-other-insight">
      <EntityGridRenderer
        firstColumnDefOverrides={{ headerComponentFramework: WaterfallFirstColumnHCF }}
        enableGroupBy
        groupByFields={groupByFields}
        handleGroupByChange={(evt) => eventBus.emit('waterfallTrafficInsightsToggleGroupBy', evt)}
        dataToRender={emulatePagination(sortedData, pageNum)}
        handleWaypointEntered={() => setPageNum(pageNum + 1)}
        layoutToUse={layout}
        title={waterfallImpactGridTitle}
        handleChangeLayout={handleChangeLayout}
        mainMetricField={getMetricFieldForSelectedField(selectedFieldName)}
        enableSwitchingLayouts
        uniqueName="waterfall-entity-table"
        pageNumber={1}
        sortByMetric={sortConfig.currentlySortedMetric}
        sortDirection={sortConfig.sortDirection}
        isLoading={false}
        widget={widget}
        metricFields={metricFields}
        groupByField={activeGroupByField}
      />
    </div>
  );
};

WaterfallMetricsImpactGrid.propTypes = {
  waterfallImpactData: PropTypes.array.isRequired,
  waterfallImpactGridTitle: PropTypes.string.isRequired,
  selectedField: PropTypes.object.isRequired,
  parentWidget: PropTypes.object,
  unitsSoldImpactConsolidated: PropTypes.object.isRequired,
  // Redux Props
  retailer: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  // From `withBus`
  eventBus: PropTypes.object.isRequired
};

WaterfallMetricsImpactGrid.defaultProps = {
  parentWidget: undefined
};

const enhance = compose(
  withBus('eventBus'),
  connect(({ retailer, app }) => ({ retailer, app }))
);

const WaterfallMetricsImpactGridWithRedux = enhance(WaterfallMetricsImpactGrid);

export default WaterfallMetricsImpactGridWithRedux;
