import { styled } from '@mui/material';
import { hexToRgba } from '@stackline/ui';
import { Option } from 'funfix-core';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import PropTypes from 'prop-types';
import queryString from 'qs';
import React, { useMemo } from 'react';
import { withBus } from 'react-bus';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { AppName } from 'sl-api-connector';
import { SegmentBulkUploadModal } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Segments/SegmentBulkUpload/SegmentBulkUploadModal';
import { SegmentCreationSnackbar } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Segments/SegmentCreationSnackbar';
import {
  BEACON_SIDEBAR_FILTER_CONTAINER_WIDTH,
  BEACON_SIDEBAR_FILTER_WIDTH,
  convertToPx
} from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { mkExportAllSegments } from 'src/components/SegmentPowerTools/SegmentExport';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import { store } from 'src/main';
import { withSnackbar } from 'src/utils/Hooks/wrappers';
import { getQueryParamValue, shouldShowNewBeacon } from 'src/utils/app';
import { ENTITIES } from 'src/utils/entityDefinitions';
import { prop } from 'src/utils/fp';
import { prefillFormData } from 'src/utils/segments';
import '../AdvancedSearchSideBar.scss';
import BulkUploadDialog from '../BulkUpload/BulkUploadDialog';
import '../UpdatedAdvancedSearch/UpdatedAdvancedSearch.scss';
import { mapFilterNameToFilterComponent } from './Filters';
import './NewBeaconSearchStyles.scss';
import { Box } from '@mui/system';
import SimpleScrollBar from 'simplebar-react';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

const StyledSlButtonBulkUpload = styled(SlButton)({
  backgroundColor: hexToRgba('#a5cfff', 0.4),
  border: 'none',
  ':hover': {
    backgroundColor: hexToRgba('#a5cfff', 0.4)
  }
});

const StyledSlButtonCriteriaExport = styled(SlButton)({
  border: 'solid 1px #e8e8ed',
  backgroundColor: '#f6f8fa',
  ':hover': {
    backgroundColor: '#f6f8fa'
  }
});

const addKeyToValues = (param) => param.map(({ ...item }) => ({ ...item, isChecked: false }));

/**
 * This component serves simply as a memoization helper for the built filters.  It memoizes the filter definitions to
 * avoid re-creating the filter components on every render and also memoizes the `filterProps` object passed in to the
 * created filters.
 */
const FilterComponentsInner = ({
  getFilterComponentDefinitions,
  state,
  handleFilterChange,
  app,
  retailer,
  user,
  categories,
  subCategories,
  segments,
  location,
  allSuperUserCategories,
  allSuperUserSubCategories,
  eventBus
}) => {
  const filterComponents = useMemo(() => {
    const items = getFilterComponentDefinitions(state.entityDefinition, state.categories, {
      app,
      user,
      categories,
      subCategories,
      segments,
      location,
      allSuperUserCategories,
      allSuperUserSubCategories
    });

    return items.map(([filterName, stateKey, { keyOverride, ...props } = {}]) => ({
      Comp: React.memo(mapFilterNameToFilterComponent(filterName, props)),
      stateKey,
      keyOverride,
      eventBus
    }));
  }, [
    state.entityDefinition,
    state.categories,
    user,
    app,
    categories,
    subCategories,
    segments,
    getFilterComponentDefinitions,
    location,
    allSuperUserCategories,
    allSuperUserSubCategories,
    eventBus
  ]);

  // We parse the URL into form data to determine which filter key should drive our component state. Enables us to switch between included/excluded values in each UpdatedKeywordSearch component.
  const currentFormData = prefillFormData({ queryParams: app.queryParams, appName: app.name });
  const { termFilters } = currentFormData;
  const { excludedCategoryId, excludedSubCategoryId } = termFilters;

  const categoryDriver = excludedCategoryId.values.length > 0 ? 'excludedCategories' : 'categories';
  const subCategoryDriver = excludedSubCategoryId.values.length > 0 ? 'excludedSubCategories' : 'subcategories';

  const filterProps = useMemo(
    () => ({
      app,
      retailer,
      id: state.id,
      isFormSubmitted: state.isFormSubmitted,
      entityDefinition: state.entityDefinition
    }),
    [app, retailer, state.id, state.isFormSubmitted, state.entityDefinition]
  );

  // We override categories/subcategories here, otherwise the components are locked to using one type of term filter
  return (
    <Flex flexDirection="column" gap={shouldShowNewBeacon() ? 5 : 0}>
      {filterComponents.map(({ Comp, stateKey, keyOverride }) => {
        // Only use filter key drivers if we're on Prod and if it's for category/subcategory
        if (user.config.isStacklineSuperUser) {
          return (
            <>
              <Comp
                key={keyOverride || stateKey}
                {...filterProps}
                value={state[stateKey]}
                onChange={(...args) => handleFilterChange(stateKey, ...args)}
                eventBus={eventBus}
              />
            </>
          );
        } else {
          if (stateKey === 'categories') {
            return (
              <Comp
                key={categoryDriver}
                {...filterProps}
                value={state[categoryDriver]}
                onChange={(...args) => handleFilterChange(categoryDriver, ...args)}
                eventBus={eventBus}
              />
            );
          } else if (stateKey === 'subcategories') {
            return (
              <Comp
                key={subCategoryDriver}
                {...filterProps}
                value={state[subCategoryDriver]}
                onChange={(...args) => handleFilterChange(subCategoryDriver, ...args)}
                eventBus={eventBus}
              />
            );
          } else {
            return (
              <>
                <Comp
                  key={keyOverride || stateKey}
                  {...filterProps}
                  value={state[stateKey]}
                  onChange={(...args) => handleFilterChange(stateKey, ...args)}
                  eventBus={eventBus}
                />
              </>
            );
          }
        }
      })}
    </Flex>
  );
};

FilterComponentsInner.propTypes = {
  getFilterComponentDefinitions: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  app: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  subCategories: PropTypes.array.isRequired,
  segments: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  allSuperUserCategories: PropTypes.array.isRequired,
  allSuperUserSubCategories: PropTypes.array.isRequired,
  eventBus: PropTypes.object.isRequired
};

const FilterComponents = React.memo(
  connect((state) =>
    _pick(state, [
      'app',
      'retailer',
      'user',
      'categories',
      'subCategories',
      'segments',
      'allSuperUserCategories',
      'allSuperUserSubCategories'
    ])
  )(compose(withRouter, withBus('eventBus'))(FilterComponentsInner))
);

const SaveButton = styled(LargeMuiButton)(() => ({
  height: '40px',
  color: 'white',
  lineHeight: 'normal',
  borderRadius: '23px',
  backgroundColor: '#052849',
  textTransform: 'none',
  ':hover': {
    backgroundColor: '#052849',
    opacity: '90%'
  }
}));

const BulkUploadButton = styled(SaveButton)(() => ({
  backgroundColor: '#46a7f5',
  '&:hover': {
    backgroundColor: '#46a7f5',
    opacity: '90%'
  }
}));

const ClearFiltersButton = styled(SaveButton)(() => ({
  color: '#052849',
  border: '1px solid #052849',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: '90%'
  }
}));

const CriteriaExportButton = styled(SaveButton)(() => ({
  backgroundColor: '#7e8fa8',
  '&:hover': {
    backgroundColor: '#7e8fa8',
    opacity: '90%'
  }
}));

class AdvancedSearchSideBar extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    subCategories: PropTypes.array.isRequired,
    eventBus: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    getFilterComponentDefinitions: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    hideActionButtons: PropTypes.bool,
    formData: PropTypes.object,
    style: PropTypes.object,
    enabledSegment: PropTypes.bool,
    ExtraContentComponent: PropTypes.any,
    mainEntity: PropTypes.any,
    widget: PropTypes.any,
    showSnackbar: PropTypes.func
  };

  static defaultProps = {
    hideActionButtons: false,
    mainEntity: {},
    widget: { data: {} },
    formData: null,
    style: undefined,
    ExtraContentComponent: null,
    enabledSegment: false
  };

  state = {
    bulkUploadDialogOpen: false,
    editingSegment: false
  };

  componentWillMount() {
    this.updateFormData(this.props);
  }

  componentDidMount() {
    this.props.eventBus.on('addToExcludeSearchTerm', this.handleExcludeSearchTerm);
    this.props.eventBus.on('addToExcludeKeyword', this.handleExcludeKeyword);
    this.props.eventBus.on('handleEntityTypeSwitching', this.handleEntityTypeSwitching);
    this.enableExports();
  }

  componentWillReceiveProps(nextProps) {
    this.updateFormData(nextProps);
  }

  componentWillUnmount() {
    this.props.eventBus.off('addToExcludeSearchTerm', this.handleExcludeSearchTerm);
    this.props.eventBus.off('addToExcludeKeyword', this.handleExcludeKeyword);
    this.props.eventBus.off('handleEntityTypeSwitching', this.handleEntityTypeSwitching);
  }

  enableExports() {
    const allSegments = store.getState().segments;
    const doesSegmentExist = !!(
      allSegments.mySegments.find((ele) => _get(ele, ['id']) === this.state.id) ||
      allSegments.teamSegments.find((ele) => _get(ele, ['segment', 'dn']) === this.state.dn)
    );
    this.setState({ editingSegment: doesSegmentExist });
  }

  exportSegment() {
    const allSegments = store.getState().segments;
    const { app, retailer, categories, subCategories } = this.props;
    const filteredSegment = {
      mySegments: allSegments.mySegments.filter((ele) => _get(ele, ['id']) === this.state.id),
      mySearchTermLists: [],
      teamSegments: allSegments.teamSegments.filter((ele) => _get(ele, ['segment', 'dn']) === this.state.dn),
      teamSearchTermLists: []
    };

    const exportTheSegment = mkExportAllSegments(
      { segments: filteredSegment, categories, subCategories, retailer, app },
      `${this.state.dn}-saved-search.csv`
    );

    exportTheSegment();
  }

  updateFormData({ location, app, formData }) {
    const params = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 10e8 });

    this.formData = prefillFormData({ queryParams: params, appName: app.name });

    if (formData) {
      this.formData = {
        ...this.formData,
        termFilters: {
          ...this.formData.termFilters,
          ...formData.termFilters
        },
        rangeFilters: {
          ...this.formData.rangeFilters,
          ...formData.rangeFilters
        }
      };
    }
    this.doAggregation = !!params.doAggregation;
    const entityType = params.entityType ? params.entityType : 'segment';
    const entityDefinition = ENTITIES[app.name][entityType];
    this.setState({
      entityDefinition,
      saveButtonText: `Save ${entityDefinition.displayName}`,
      dn: this.formData.dn,
      id: this.formData.id,
      isFormValid: !_isEmpty(this.formData.dn),
      isFormSubmitted: false,
      searchTerms: this.formData.termFilters.searchTerm.values,
      searchTermsFuzzy: this.formData.termFilters.searchTermFuzzy.values,
      searchKeyword: this.formData.termFilters.searchKeyword.values,
      searchKeywordFuzzy: this.formData.termFilters.searchKeywordFuzzy.values,
      excludedSearchKeyword: addKeyToValues(this.formData.termFilters.excludedSearchKeyword.values),
      excludedSearchKeywordFuzzy: addKeyToValues(this.formData.termFilters.excludedSearchKeywordFuzzy.values),
      excludedSearchTerms: addKeyToValues(this.formData.termFilters.excludedSearchTerm.values),
      excludedSearchTermFuzzy: addKeyToValues(this.formData.termFilters.excludedSearchTermFuzzy.values),
      targetingText: this.formData.termFilters.targetingText.values,
      targetingTextFuzzy: this.formData.termFilters.targetingTextFuzzy.values,
      excludedTargetingText: addKeyToValues(this.formData.termFilters.excludedTargetingText.values),
      excludedTargetingTextFuzzy: addKeyToValues(this.formData.termFilters.excludedTargetingTextFuzzy.values),
      keywords: this.formData.termFilters.keyword.values,
      keywordPhrase: this.formData.termFilters.keywordPhrase.values,
      keywordExact: this.formData.termFilters.keywordExact.values,
      excludedKeywords: addKeyToValues(this.formData.termFilters.excludedKeyword.values),
      excludedKeywordsExact: addKeyToValues(this.formData.termFilters.excludedKeywordExact.values),
      brandId: addKeyToValues(this.formData.termFilters.brandId.values),
      excludedBrandId: addKeyToValues(this.formData.termFilters.excludedBrandId.values),
      parentBrandId: addKeyToValues(this.formData.termFilters.parentBrandId.values),
      categories: addKeyToValues(this.formData.termFilters.categoryId.values),
      subcategories: addKeyToValues(this.formData.termFilters.subCategoryId.values),
      excludedCategories: addKeyToValues(this.formData.termFilters.excludedCategoryId.values),
      excludedSubCategories: addKeyToValues(this.formData.termFilters.excludedSubCategoryId.values),
      segment: params.segment || null,
      businessUnitsByParentId: Option.of(_isEmpty(params.businessUnits) ? null : params.businessUnits)
        .map(JSON.parse)
        .getOrElse({}),
      campaignName: this.formData.termFilters.campaignName.values,
      campaignNameFuzzy: this.formData.termFilters.campaignNameFuzzy.values,
      excludedCampaignNameFuzzy: this.formData.termFilters.excludedCampaignNameFuzzy.values,
      adGroupNameFuzzy: this.formData.termFilters.adGroupNameFuzzy.values,
      retailerNameFuzzy: this.formData.termFilters.retailerNameFuzzy.values,
      portfolioName: this.formData.termFilters.portfolioName.values,
      portfolioNameFuzzy: this.formData.termFilters.portfolioNameFuzzy.values,
      excludedPortfolioName: this.formData.termFilters.excludedPortfolioName.values,
      excludedPortfolioNameFuzzy: this.formData.termFilters.excludedPortfolioNameFuzzy.values,
      entityName: this.formData.termFilters.entityName.values,
      entityNameFuzzy: this.formData.termFilters.entityNameFuzzy.values,
      excludedEntityName: this.formData.termFilters.excludedEntityName.values,
      excludedEntityNameFuzzy: this.formData.termFilters.excludedEntityNameFuzzy.values,
      entityId: addKeyToValues(this.formData.termFilters.entityId.values),
      campaignId: addKeyToValues(this.formData.termFilters.campaignId.values),
      portfolioId: addKeyToValues(this.formData.termFilters.portfolioId.values),
      automationStrategyId: addKeyToValues(this.formData.termFilters.automationStrategyId.values),
      derivedCampaignType: addKeyToValues(this.formData.termFilters.derivedCampaignType.values),
      statusDerived: addKeyToValues(this.formData.termFilters.statusDerived.values),
      portfolioState: addKeyToValues(this.formData.termFilters.portfolioState.values),
      targetingType: addKeyToValues(this.formData.termFilters.targetingType.values),
      targetState: addKeyToValues(this.formData.termFilters.targetState.values),
      isAutoAdded: addKeyToValues(this.formData.termFilters.isAutoAdded.values),
      campaignProductState: addKeyToValues(this.formData.termFilters.campaignProductState.values),
      costPerClick: this.formData.rangeFilters.costPerClick,
      returnOnAdSpend: this.formData.rangeFilters.returnOnAdSpend,
      spendComputed: this.formData.rangeFilters.spendComputed,
      retailPrice: this.formData.rangeFilters.retailPrice,
      bid: this.formData.rangeFilters.bid,
      breadCrumbText: addKeyToValues(this.formData.termFilters.breadCrumbText.values),
      excludedBreadCrumbText: this.formData.termFilters.excludedBreadCrumbText.values
    });
  }

  clearAllFilters = () => {
    const keysToClear = [
      'searchTerm',
      'searchTermFuzzy',
      'searchKeyword',
      'searchKeywordFuzzy',
      'excludedSearchTerm',
      'excludedSearchTermFuzzy',
      'targetingText',
      'targetingTextFuzzy',
      'excludedTargetingText',
      'keyword',
      'keywordPhrase',
      'keywordExact',
      'excludedKeyword',
      'excludedKeywordExact',
      'brandId',
      'excludedBrandId',
      'categoryId',
      'subCategoryId',
      'excludedCategoryId',
      'excludedSubCategoryId',
      'parentBrandId',
      'entityId',
      'portfolioId',
      'campaignId',
      'campaignName',
      'campaignNameFuzzy',
      'excludedCampaignName',
      'excludedCampaignNameFuzzy',
      'adGroupNameFuzzy',
      'retailerNameFuzzy',
      'portfolioName',
      'portfolioNameFuzzy',
      'excludedPortfolioName',
      'excludedPortfolioNameFuzzy',
      'entityName',
      'excludedEntityName',
      'automationStrategyId',
      'derivedCampaignType',
      'statusDerived',
      'portfolioState',
      'targetingType',
      'targetState',
      'campaignProductState',
      'isAutoAdded',
      'costPerClick',
      'returnOnAdSpend',
      'spendComputed',
      'retailPrice',
      'breadCrumbText',
      'bid'
    ];

    this.formData.termFilters = keysToClear.reduce((acc, key) => ({ ...acc, [key]: { ...acc[key], values: [] } }), {
      ...this.formData.termFilters
    });
    this.formData.rangeFilters = [];
    this.props.onChange({
      doAggregation: this.doAggregation,
      props: this.props,
      state: this.state,
      formData: this.formData
    });
  };

  handleSegmentFilterChange = (segment) => {
    const newState = {
      ...this.state,
      segment: Option.of(segment).map(prop('id')).orNull()
    };

    this.props.onChange({
      doAggregation: this.doAggregation,
      props: this.props,
      state: newState,
      formData: this.formData
    });

    this.setState(newState);
  };

  handleBusinessUnitFilterChange = ({ parentBusinessUnitId, selectedFilters }) => {
    const newState = {
      ...this.state,
      businessUnitsByParentId: { ...this.state.businessUnitsByParentId, [parentBusinessUnitId]: selectedFilters }
    };

    this.props.onChange({
      doAggregation: this.doAggregation,
      props: this.props,
      state: newState,
      formData: this.formData
    });

    this.setState(newState);
  };

  handleFilterChange = (stateKey, ...args) => {
    if (stateKey === 'excludedCategories') {
      this.handleExcludedCategoriesChange(...args);
    } else if (stateKey === 'excludedSubCategories') {
      this.handleExcludedSubCategoriesChange(...args);
    } else if (stateKey === 'dn') {
      this.handleDisplayNameChange(...args);
    } else if (stateKey === 'segment') {
      this.handleSegmentFilterChange(...args);
    } else if (stateKey === 'businessUnitsByParentId') {
      this.handleBusinessUnitFilterChange(...args);
    } else if (args && args.length > 0 && args[0].type === 'rangeFilter') {
      this.handleRangeFilters(args[0]);
    } else {
      this.handleTermFilters(...args);
    }
  };

  handleExcludedCategoriesChange = (obj, filterOptions = null) => {
    if (filterOptions !== null) {
      this.doAggregation = true;
      this.formData.termFilters[filterOptions.includeKey].values = obj.values;
      this.formData.termFilters[filterOptions.excludeKey].values = [];

      this.props.onChange({
        doAggregation: this.doAggregation,
        props: this.props,
        state: this.state,
        formData: this.formData
      });
      this.setState({ [obj.key]: obj.values });
      return;
    }
    this.formData.termFilters.excludedCategoryId.values = obj.values;
    this.props.onChange({
      doAggregation: this.doAggregation,
      props: this.props,
      state: this.state,
      formData: this.formData
    });
    this.setState({ excludedCategories: obj.values });
  };

  handleExcludedSubCategoriesChange = (obj, filterOptions = null) => {
    if (filterOptions !== null) {
      this.doAggregation = true;
      this.formData.termFilters[filterOptions.includeKey].values = obj.values;
      this.formData.termFilters[filterOptions.excludeKey].values = [];

      this.props.onChange({
        doAggregation: this.doAggregation,
        props: this.props,
        state: this.state,
        formData: this.formData
      });
      this.setState({ [obj.key]: obj.values });
      return;
    }

    this.formData.termFilters.excludedSubCategoryId.values = obj.values;
    this.props.onChange({
      doAggregation: this.doAggregation,
      props: this.props,
      state: this.state,
      formData: this.formData
    });
    this.setState({ excludedSubCategories: obj.values });
  };

  handleDisplayNameChange = (values) => {
    this.setState(values);
    this.formData.dn = values.dn;
  };

  handleTermFilters = (obj, filterOptions = null) => {
    if (filterOptions !== null) {
      this.doAggregation = true;
      this.formData.termFilters[filterOptions.includeKey].values = obj.values;
      this.formData.termFilters[filterOptions.excludeKey].values = [];

      this.props.onChange({
        doAggregation: this.doAggregation,
        props: this.props,
        state: this.state,
        formData: this.formData
      });
      this.setState({ [obj.key]: obj.values });
      return;
    }
    this.doAggregation = true;
    this.formData.termFilters[obj.key].values = obj.values;
    this.props.onChange({
      doAggregation: this.doAggregation,
      props: this.props,
      state: this.state,
      formData: this.formData
    });
    this.setState({ [obj.key]: obj.values });
  };

  handleRangeFilters = (obj) => {
    this.doAggregation = true;
    this.formData.rangeFilters[obj.key] = {
      fieldName: obj.key,
      minValue: obj.minValue,
      maxValue: obj.maxValue
    };
    this.props.onChange({
      doAggregation: this.doAggregation,
      props: this.props,
      state: this.state,
      formData: this.formData
    });
    this.setState({ [obj.key]: obj });
  };

  handleEntityTypeSwitching = (entityType) =>
    this.props.onChange({
      doAggregation: this.doAggregation,
      entityType: entityType.value,
      props: this.props,
      state: this.state,
      formData: this.formData
    });

  handleExcludeSearchTerm = (item) => {
    this.formData.termFilters.excludedSearchTerm.values.push({ i: item.name });
    this.handleTermFilters({
      key: 'excludedSearchTerm',
      values: this.formData.termFilters.excludedSearchTerm.values
    });
  };

  handleExcludeKeyword = (item) => {
    this.formData.termFilters.excludedKeyword.values.push({
      i: _get(item, ['cardView', 'retailerSku', this.props.retailer.id], item.name)
    });
    this.handleTermFilters({
      key: 'excludedKeyword',
      values: this.formData.termFilters.excludedKeyword.values
    });
  };

  handleBulkUploadClick = () => this.setState({ bulkUploadDialogOpen: !this.state.bulkUploadDialogOpen });

  render() {
    const { app, getFilterComponentDefinitions, hideActionButtons, style, ExtraContentComponent } = this.props;
    const { entityDefinition } = this.state;

    // Only update the styling for regular users, default styling for superusers
    const isSuperUser = this.props.user.config.isStacklineSuperUser;
    const appName = this.props.app.name || '';

    const renderUpdatedFilters = isSuperUser || appName === 'beacon' || appName === 'atlas';
    const isTopicBuilder = getQueryParamValue('entityType') === 'searchtermlist';

    if (shouldShowNewBeacon()) {
      return (
        <div className="updated-beacon-container" style={{ width: '200px' }}>
          <div style={{ marginTop: '50px', marginBottom: '32px' }}>
            <Text variant="h4">Filter</Text>
          </div>
          <Box sx={{ width: convertToPx(BEACON_SIDEBAR_FILTER_WIDTH) }}>
            <FilterComponents
              getFilterComponentDefinitions={getFilterComponentDefinitions}
              state={this.state}
              handleFilterChange={this.handleFilterChange}
            />
          </Box>
          <div
            className="main-button-container"
            style={{
              gap: '16px',
              padding: 0,
              width: shouldShowNewBeacon()
                ? convertToPx(BEACON_SIDEBAR_FILTER_WIDTH)
                : BEACON_SIDEBAR_FILTER_CONTAINER_WIDTH
            }}
          >
            {(app.name !== AppName.Advertising || this.props.enabledSegment) && (
              <SlButton
                variant="contained"
                onClick={async () => {
                  const success = await this.props.onSubmit(
                    this.props,
                    this.state,
                    this.formData,
                    this.setState.bind(this)
                  );
                  if (success) {
                    this.props.showSnackbar({
                      type: 'success',
                      message: <SegmentCreationSnackbar isTopicBuilder={isTopicBuilder} />
                    });
                  }
                }}
              >
                Save {isTopicBuilder ? 'Topic' : 'Segment'}
              </SlButton>
            )}
            {!hideActionButtons && (
              <>
                <StyledSlButtonBulkUpload onClick={this.handleBulkUploadClick}>Bulk Upload</StyledSlButtonBulkUpload>
                {this.state.editingSegment ? (
                  <StyledSlButtonCriteriaExport onClick={() => this.exportSegment()}>
                    Criteria Export
                  </StyledSlButtonCriteriaExport>
                ) : null}
                <SlButton onClick={this.clearAllFilters}>Clear Filters</SlButton>
                <SegmentBulkUploadModal
                  open={this.state.bulkUploadDialogOpen}
                  handleClose={this.handleBulkUploadClick}
                  handleTermFilterChange={this.handleTermFilters}
                  currentFilters={this.state}
                />
              </>
            )}
          </div>

          {ExtraContentComponent ? <ExtraContentComponent context={this} /> : null}
          <br />
        </div>
      );
    } else {
      return renderUpdatedFilters ? (
        <div className="search-spacer">
          <div className="updated-search-nav-container" style={style}>
            <SimpleScrollBar style={{ height: '99%' }}>
              <form className="form-flex-container">
                <FilterComponents
                  getFilterComponentDefinitions={getFilterComponentDefinitions}
                  state={this.state}
                  handleFilterChange={this.handleFilterChange}
                />
                <div className="main-button-container">
                  {(app.name !== AppName.Advertising || this.props.enabledSegment) && (
                    <SaveButton
                      onClick={() =>
                        this.props.onSubmit(this.props, this.state, this.formData, this.setState.bind(this))
                      }
                    >
                      {app.name === AppName.Atlas ? 'Save' : this.state.saveButtonText}
                    </SaveButton>
                  )}
                  {!hideActionButtons && (
                    <>
                      <BulkUploadButton onClick={this.handleBulkUploadClick}>Bulk Upload</BulkUploadButton>
                      {this.state.editingSegment ? (
                        <CriteriaExportButton onClick={() => this.exportSegment()}>
                          Criteria Export
                        </CriteriaExportButton>
                      ) : null}
                      <ClearFiltersButton onClick={this.clearAllFilters}>Clear Filters</ClearFiltersButton>

                      <BulkUploadDialog
                        open={this.state.bulkUploadDialogOpen}
                        onClose={this.handleBulkUploadClick}
                        uploadType={entityDefinition.type}
                        handleTermFilters={this.handleTermFilters}
                        itemLists={this.state}
                      />
                    </>
                  )}
                </div>

                {ExtraContentComponent ? <ExtraContentComponent context={this} /> : null}
              </form>
            </SimpleScrollBar>
          </div>
        </div>
      ) : (
        <div className="search-nav-container" style={style}>
          <Box>
            <form>
              <FilterComponents
                getFilterComponentDefinitions={getFilterComponentDefinitions}
                state={this.state}
                handleFilterChange={this.handleFilterChange}
              />
              {(app.name !== AppName.Advertising || this.props.enabledSegment) && (
                <LargeMuiButton
                  style={{ marginTop: 14 }}
                  onClick={() => this.props.onSubmit(this.props, this.state, this.formData, this.setState.bind(this))}
                >
                  {this.state.saveButtonText}
                </LargeMuiButton>
              )}
              {!hideActionButtons && (
                <>
                  <LargeMuiButton secondary style={{ marginTop: 14 }} onClick={this.handleBulkUploadClick}>
                    Bulk Upload
                  </LargeMuiButton>
                  <LargeMuiButton secondary style={{ marginTop: 14 }} onClick={this.clearAllFilters}>
                    Clear Filters
                  </LargeMuiButton>

                  <BulkUploadDialog
                    open={this.state.bulkUploadDialogOpen}
                    onClose={this.handleBulkUploadClick}
                    uploadType={entityDefinition.type}
                    handleTermFilters={this.handleTermFilters}
                    itemLists={this.state}
                  />
                </>
              )}

              {ExtraContentComponent ? <ExtraContentComponent context={this} /> : null}
              <br />
            </form>
          </Box>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ..._pick(state, [
      'app',
      'retailer',
      'user',
      'mainTimePeriod',
      'categories',
      'subCategories',
      'location',
      'allSuperUserCategories',
      'allSuperUserSubCategories'
    ]),
    mainEntity: state.entityService.mainEntity
  };
};

export default compose(withRouter, connect(mapStateToProps), withBus('eventBus'), withSnackbar)(AdvancedSearchSideBar);
