import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { withRouter } from 'react-router';
import 'src/components/EntityPage/LeftNav/Cards/Cards.scss';

const propTypes = {
  history: PropTypes.any.isRequired,
  app: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired
};

function UserAccountNavItems({ app, location, routes, history }) {
  const appName = app.name;

  const { searchParams } = app.queryParams;
  return (
    <div className="account-nav-list-container">
      <List className="nav-list-item-container">
        {routes.map((item) => {
          const Icon = item.sidebarIcon;
          if ((appName === 'atlas' || appName === 'advertising' || appName === 'omni') && item.app === 'beacon') {
            return null;
          }
          return (
            <ListItem
              button
              key={item.path}
              selected={location.pathname.indexOf(item.path) !== -1}
              onClick={() => {
                history.push(`${item.path}${searchParams}`);
              }}
            >
              <div style={{ width: 33, height: 33, marginRight: 8 }}>
                <Icon
                  className={`nav-list-item__icon ${
                    location.pathname.indexOf(item.path) !== -1 ? 'nav-list-item__icon--active' : ''
                  }`}
                />
              </div>
              <ListItemText primary={item.sidebarName} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

UserAccountNavItems.propTypes = propTypes;

export default withRouter(UserAccountNavItems);
