import { makeStyles } from '@mui/styles';
import { Moment } from 'moment';
import React, { Ref, SetStateAction, useEffect, useRef } from 'react';
import Picker from 'react-month-picker';
import CalendarClearButton from 'src/components/common/DatePicker/CalendarClearButton';
import { extractMonthYear, lang, yearMonthToMoment } from 'src/utils/dateUtils';
import './MonthPicker.scss';

export interface MonthPickerProps {
  endDate: Moment;
  setEndDate: (value: SetStateAction<Moment | null>) => void;
  monthPickerRef: Ref<Picker>;
  calendarRange: {
    min: { year: number; month: number };
    max: { year: number; month: number };
  };
  onDismiss: () => void;
  hasEndDate: boolean;
  containerClass: 'createPortfolio' | 'portfolioSettings' | 'budgetAmount';
}

const useStyles = makeStyles({
  createPortfolio: {
    top: 23,
    left: -110
  },
  portfolioSettings: {
    top: 23,
    left: -118
  },
  budgetAmount: {
    '& .rmp-popup.show ': {
      paddingBottom: '5.6px !important'
    }
  }
});

const MonthPicker = (props: MonthPickerProps) => {
  const { endDate, setEndDate, monthPickerRef, calendarRange, onDismiss, hasEndDate, containerClass } = props;
  const resetDate = useRef<Moment | null>(null);
  const classes = useStyles();

  useEffect(() => {
    resetDate.current = containerClass === 'portfolioSettings' ? endDate : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`month-picker ${classes[containerClass]}`}>
      <Picker
        ref={monthPickerRef}
        lang={lang}
        years={calendarRange}
        value={extractMonthYear(endDate)}
        onChange={(year: number, month: number) => setEndDate(yearMonthToMoment(year, month))}
        onDismiss={onDismiss}
      />
      <div className="clear-container">
        <CalendarClearButton
          styles={{ position: 'relative', top: '256px', left: '103px', zIndex: 101 }}
          hasEndDate={hasEndDate}
          handleClear={() => setEndDate(resetDate.current)}
        />
      </div>
    </div>
  );
};

export default MonthPicker;
