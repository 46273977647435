import { Dialog, Divider, SxProps, styled } from '@mui/material';
import React, { RefObject, useMemo, useState } from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import AppProfileIcon from 'src/components/BeaconRedesignComponents/Header/AppProfileIcon';
import { SnackbarCancel, SnackbarCheckmark } from 'src/components/SvgIcons';
import { useAppSelector } from 'src/utils/Hooks';
import _capitalize from 'lodash/capitalize';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import StyledInput from 'src/components/BeaconRedesignComponents/common/StyledInput';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import { useStacklineTheme } from '@stackline/ui';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import useRemainingCharacters from 'src/components/BeaconRedesignComponents/hooks/useRemainingCharacters';
import GenericBulkAdjustmentModal, {
  GenericBulkAdjustmentModalProps
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/GenericBulkAdjustmentModal';
import { useBulkAdjustmentContext } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/hooks/useBulkAdjustmentContext';
import { trackMixpanelEvent } from 'src/utils/mixpanel';
import { UploadedAdjustment } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkAdjustmentProvider';

export const GenericStyledDialogue = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '757px',
    height: '641px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px'
  },
  textarea: {
    color: '#052849 !important'
  },
  overflowX: 'hidden',
  '&::-webkit-scrollbar:': {
    width: '5px'
  },

  '&:hover': {
    '&::-webkit-scrollbar-thumb': {
      'background-color': '#7e8fa8',
      'border-radius': '15px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff'
    }
  }
});

const UploadMessage = ({
  messageType,
  message,
  sx = {}
}: {
  messageType: 'success' | 'error';
  message: string;
  sx?: SxProps;
}) => {
  const theme = useStacklineTheme();
  const icon =
    messageType === 'success' ? (
      <SnackbarCheckmark style={{ fill: theme.colors.success, width: '24px' }} />
    ) : (
      <SnackbarCancel style={{ fill: theme.colors.error, width: '24px' }} />
    );
  return (
    <Flex sx={{ ...sx }} justifyContent="center">
      <Flex alignItems="center" gap="xs">
        {icon}
        <Text color={messageType} variant="subtitle3">
          {message}
        </Text>
      </Flex>
    </Flex>
  );
};

const titleCharacterLimit = 50;
const descriptionCharacterLimit = 250;
const textHeight = '19px';

export const planTypeDropdownOptions = [
  { label: 'Organic Traffic', id: PlanTypeOption.OrganicTraffic },
  { label: 'Paid Traffic', id: PlanTypeOption.PaidTraffic },
  { label: 'Other Traffic', id: PlanTypeOption.OtherTraffic },
  { label: 'Retail Price', id: PlanTypeOption.RetailPrice },
  { label: 'Content Score', id: PlanTypeOption.ContentScore },
  { label: 'Ratings & Reviews', id: PlanTypeOption.RatingsReviews },
  { label: 'In-Stock Rate', id: PlanTypeOption.InStockRate },
  { label: 'Buy Box', id: PlanTypeOption.BuyBox }
];

export interface BulkTemplateUploadState {
  title: string;
  description: string;
  planType: PlanTypeOption;
  uploadedFile: File | null;
  uploadStatus: { message: string; status: 'success' | 'error' } | null;
}

// eslint-disable-next-line react/display-name
const BulkTemplateUploadModal = React.forwardRef(
  (
    props: Omit<GenericBulkAdjustmentModalProps, 'children' | 'header'> & {
      bulkAdjustmentIdRef: RefObject<string | null>;
    } & { uploadedAdjustment: UploadedAdjustment },
    ref: React.Ref<HTMLDivElement>
  ): JSX.Element => {
    const { firstName, lastName, role } = useAppSelector((state) => state.user.config.profile);
    const { uploadedAdjustment } = props;
    const [title, setTitle] = useState(uploadedAdjustment.bulkAdjustmentTitle);
    const [description, setDescription] = useState(uploadedAdjustment.bulkAdjustmentDescription);
    const [selectedOption, setSelectedOption] = useState(planTypeDropdownOptions[0]);

    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [uploadStatus, setUploadStatus] = useState<{ message: string; status: 'success' | 'error' } | null>(null);

    const fileRef = React.useRef<HTMLInputElement>(null);

    // Character limit counts
    const remainingTitleCharacters = useRemainingCharacters(title, titleCharacterLimit);
    const remainingDescriptionCharacters = useRemainingCharacters(description, descriptionCharacterLimit);

    const { uploadBulkAdjustment } = useBulkAdjustmentContext();

    const formValid = useMemo(() => {
      return title.length > 0 && description.length > 0 && uploadedFile;
    }, [description.length, title.length, uploadedFile]);

    const onClickNext = () => {
      trackMixpanelEvent({
        eventName: 'upload bulk adjustment template',
        data: {
          description,
          title,
          planType: selectedOption.id
        }
      });
      uploadBulkAdjustment({
        bulkAdjustmentFile: uploadedFile,
        bulkAdjustmentDescription: description,
        bulkAdjustmentTitle: title,
        planType: selectedOption.id,
        ...(props.bulkAdjustmentIdRef.current! ? { bulkAdjustmentId: props.bulkAdjustmentIdRef.current } : {})
      });
    };

    return (
      <GenericBulkAdjustmentModal
        header="Upload Template"
        footer={
          <>
            <Divider />
            <Flex justifyContent="space-between" alignItems="center" paddingX="48px" paddingY="20px">
              <SlButton onClick={props.onClose}>Cancel</SlButton>
              <SlButton onClick={onClickNext} variant="contained" disabled={!formValid}>
                Next
              </SlButton>
            </Flex>
          </>
        }
        ref={ref}
        defaultContentContainer={false}
        {...props}
      >
        {/* Content */}
        <Flex flexDirection="column" paddingX="48px" height="495px">
          <Flex flexDirection="column" marginTop="24px" gap="sm">
            <Text sx={{ height: textHeight }} variant="subtitle2">
              New Bulk Adjustment
            </Text>
            <Text sx={{ height: textHeight }} variant="body2">
              Upload a completed bulk adjustment template.
            </Text>
          </Flex>

          <Flex marginTop="24px">
            {/* User metadata */}
            <Flex width="270px" flexDirection="column" gap="md">
              <Text sx={{ height: textHeight }} variant="subtitle2">
                Owner
              </Text>
              <Flex gap="md" alignItems="center">
                <AppProfileIcon size={67} fontSize="32px" />
                <Flex flexDirection="column" gap="xs">
                  <Text sx={{ height: textHeight }} variant="subtitle2">{`${firstName} ${lastName}`}</Text>
                  <Text sx={{ height: textHeight }} variant="body2">
                    {_capitalize(role)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            {/* Upload file */}
            <Flex flexDirection="column" gap="md">
              <Text sx={{ height: textHeight }} variant="subtitle2">
                Upload File
              </Text>
              <Flex gap="xs">
                <Text sx={{ height: textHeight }} variant="subtitle2">
                  Note:
                </Text>
                <Text sx={{ height: textHeight }} variant="body2">
                  Excel files only
                </Text>
              </Flex>
              <SlButton
                onClick={() => (fileRef ? fileRef.current.click() : undefined)}
                buttonSx={{ width: '84px' }}
                variant="contained"
              >
                Upload
                <input
                  ref={fileRef}
                  onChange={(evt) => {
                    const file = evt.target.files[0];
                    if (file) {
                      try {
                        setUploadedFile(file);
                        setUploadStatus({ message: 'Your file was successfully uploaded.', status: 'success' });
                      } catch (e) {
                        setUploadStatus({ message: 'We’ve encountered an error. Please try again.', status: 'error' });
                      }
                    }
                  }}
                  style={{ display: 'none' }}
                  id="file-input"
                  type="file"
                  accept=".xlsx"
                />
              </SlButton>
              {uploadStatus && (
                <UploadMessage
                  sx={{ position: 'absolute', top: '269px', marginTop: '20px' }}
                  messageType={uploadStatus.status}
                  message={uploadStatus.message}
                />
              )}
            </Flex>
          </Flex>

          <Flex marginTop="64px">
            {/* Plan dropdown */}
            <Flex width="250px" flexDirection="column" gap="sm">
              {/* Plan Type */}
              <Text sx={{ height: textHeight }} variant="subtitle2" title="Plan Type">
                Plan Type
              </Text>
              <SlDropdownMenu
                // Target the dropdown menu font weight
                menuItemTextStyles={{ fontWeight: 'normal' }}
                // Target the width and text font weight within the SlButton
                stacklineButtonProps={{
                  buttonSx: {
                    width: '152px',
                    justifyContent: 'space-between'
                  },
                  textSx: {
                    fontWeight: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }
                }}
                selectedId={selectedOption.id}
                options={planTypeDropdownOptions}
                defaultLabel="Select a plan type"
                onChange={(option) => setSelectedOption(option)}
              />
            </Flex>

            {/* Title Input */}
            <Flex width="100%" flexDirection="column" gap="sm">
              <Text sx={{ height: textHeight }} variant="subtitle2" title="Title">
                Title
              </Text>
              <StyledInput
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value.slice(0, titleCharacterLimit));
                }}
                width="100%"
                placeholder="Name your adjustment"
                sx={{ height: '32px' }}
              />
              <Flex justifyContent="flex-end">
                <Text variant="subtitle4" sx={{ fontSize: '10px' }}>
                  {remainingTitleCharacters} characters remaining
                </Text>
              </Flex>
            </Flex>
          </Flex>
          {/* Description */}
          <Flex flexDirection="column" gap="sm" marginTop="15px">
            <Text sx={{ height: textHeight }} variant="subtitle2" title="Description">
              Description
            </Text>
            <StyledInput
              value={description}
              onChange={(e) => {
                setDescription(e.target.value.slice(0, descriptionCharacterLimit));
              }}
              width="100%"
              placeholder="Enter a short description"
              sx={{ height: '32px' }}
            />
            <Flex justifyContent="flex-end">
              <Text variant="subtitle4" sx={{ fontSize: '10px' }}>
                {remainingDescriptionCharacters} characters remaining
              </Text>
            </Flex>
          </Flex>
          {/* End of inner container */}
        </Flex>
      </GenericBulkAdjustmentModal>
    );
  }
);

export default BulkTemplateUploadModal;
