import React, { memo } from 'react';
import { Text, useStacklineTheme } from '@stackline/ui';
import { useAppSelector, useQueryParamValue } from 'src/utils/Hooks';
import { useBuildForecastInsightText } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/hooks';
import ForecastPlansKeyMetricCards from './Forecasts/ForecastPlansKeyMetricCards';
import ForecastsLineChart from './Forecasts/ForecastsLineChart';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { CreateAdjustmentButton } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/CreateAdjustmentButton';
import ForecastChangeBarChart from './Forecasts/ForecastChangeBarChart';
import { getCardTitle } from 'src/components/BeaconRedesignComponents/KeyMetricCards/SmartKeyMetricCard';
import useForecastMetricByWeekId from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useForecastMetricByWeekId';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import {
  BEACON_CHART_SPACING,
  BEACON_KEY_METRIC_CARD_SPACING,
  BEACON_GRID_TOP_SPACING
} from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import ForecastPlansAdjustmentsTable from './ForecastPlansAdjustmentsTable';
import ForecastAdjustmentsTypeProvider from 'src/components/BeaconRedesignComponents/ExperimentalLayout/ForecastAdjustmentsTypeProvider';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';

const titleOverrides = {
  'ratings-reviews-metrics': {
    weightedRating: 'Ratings & Reviews'
  },
  advertising: {
    clicks: 'Paid Traffic'
  },
  buybox: {
    winPercentage: 'Buy Box'
  }
};

export default memo(function ForecastsPlansPage() {
  const theme = useStacklineTheme();
  const entity = useAppSelector((state) => state.entityService.mainEntity);

  const selectedField = useQueryParamValue('keyMetric', 'organicClicks');
  const selectedIndex = useQueryParamValue('indexName', 'traffic');

  const { percentChange, formattedPrimaryTotal } = useForecastMetricByWeekId({
    indexName: selectedIndex,
    fieldName: selectedField
  });

  const splineChartInsight = useBuildForecastInsightText({
    changePercent: percentChange,
    fieldName: selectedField,
    fieldTitle: getCardTitle(selectedIndex, selectedField, titleOverrides),
    formattedTotal: formattedPrimaryTotal
  });

  return (
    <>
      <ForecastAdjustmentsTypeProvider>
        <BeaconPageContainer>
          <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
            <Flex flexDirection="column" gap={BEACON_KEY_METRIC_CARD_SPACING}>
              <Flex flexDirection="column" gap="lg">
                <Flex flexDirection="column" gap="md">
                  <Flex justifyContent="space-between">
                    <CommonSummaryInfoSubtitle title="Business Plans" />
                    <CreateAdjustmentButton />
                  </Flex>
                  <Text>
                    {`The metrics below summarize the business plans that impact the sales forecast for ${
                      ['client', 'brand'].includes(entity.type) ? entity.name : `this ${entity.type}`
                    }.`}
                  </Text>
                </Flex>
                <ForecastPlansKeyMetricCards titleOverrides={titleOverrides} />
              </Flex>
              <ForecastsLineChart
                fieldName={selectedField}
                indexName={selectedIndex}
                subtitle={splineChartInsight}
                titleOverrides={titleOverrides}
              />
            </Flex>
            <ForecastChangeBarChart fieldName={selectedField} showInsight />
          </Flex>
          <div
            style={{
              marginBottom: theme.spacing.xxl,
              marginTop: `${BEACON_GRID_TOP_SPACING}px`
            }}
          >
            <ForecastPlansAdjustmentsTable />
          </div>
        </BeaconPageContainer>
      </ForecastAdjustmentsTypeProvider>
    </>
  );
});
