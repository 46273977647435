import { useStacklineTheme } from '@stackline/ui';
import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import StyledDialog from 'src/components/BeaconRedesignComponents/common/StyledDialog/StyledDialog';
import { CloseIcon } from 'src/components/SvgIcons';

type StyledDialogProps = React.ComponentPropsWithRef<typeof StyledDialog>;
export interface BulkAdjustmentUnsavedChangesDialogProps extends Partial<StyledDialogProps> {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}

/**
 * A dialog to confirm exiting the bulk adjustment modal when there are unsaved changes.
 * If the user clicks Cancel, it will take them back to the dialog they were on
 */
export default function BulkAdjustmentUnsavedChangesDialog({
  onClose,
  onCancel,
  ...rest
}: BulkAdjustmentUnsavedChangesDialogProps) {
  const theme = useStacklineTheme();

  return (
    <StyledDialog padding="24px" width={500} {...rest}>
      <Flex justifyContent="space-between" marginBottom={theme.spacing.md} height="26px">
        <Text variant="h4">Are you sure you want to exit?</Text>
        <CloseIcon width={16} height={16} cursor="pointer" onClick={() => onClose()} />
      </Flex>
      <Flex height="38px">
        <Text variant="body2">
          You have unsaved changes. If you exit, these changes will be lost. Press exit to continue, or cancel to stay
          on this page.
        </Text>
      </Flex>

      <Flex marginTop={theme.spacing.md} justifyContent="flex-end" gap="md" marginBottom="2px">
        <SlButton onClick={() => onCancel()}>Cancel</SlButton>
        <SlButton variant="contained" onClick={() => onClose()}>
          Exit
        </SlButton>
      </Flex>
    </StyledDialog>
  );
}
