import React, { useState, useMemo } from 'react';
import Visualization from '../../components/Visualization';
import VisualizationInsight from '../../components/VisualizationInsight';
import { ESSRequestContext, SearchRequestOverride } from 'src/store/modules/entitySearchService/operationTypes';
import useConnectFetchEntityMetrics from '../hooks/useConnectFetchEntityMetrics';
import useAmcDateRange from '../hooks/useAmcDateRange';
import { AdFrequencyDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import { useAppSelector, useMetricFormatter, useBus } from 'src/utils/Hooks';
import AdFrequencyBarLineChart from './DspAdFrequencyBarLineChart';
import { AD_MANAGER_STATE_PROPERTY_NAME } from '../constants';
import { advancedSearchResponseToAdFrequencyResponse, getChartMetricsByDropdownMetric } from './utils';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import _sum from 'lodash/sum';
import { AdFrequencyLineMetric } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/DspAdFrequency/types';
import { withBus } from 'react-bus';
import { AD_FREQUENCY_EVENT } from './constants';
import { EventBus } from 'src/types/utils';

interface DspAdFrequencyVisualizationProps {
  eventBus: EventBus;
}

/**
 * Shows the ad frequency visualization along with a bar
 * chart of selected metrics.
 */
const DspAdFrequencyVisualization = ({ eventBus }: DspAdFrequencyVisualizationProps) => {
  const [metric, setMetric] = useState<AdFrequencyDropdownOption>(AdFrequencyDropdownOption.Awareness);
  const { startDayId, endDayId } = useAmcDateRange();
  const retailer = useAppSelector((state) => state.retailer);
  const formatMetric = useMetricFormatter();

  useBus(eventBus, AD_FREQUENCY_EVENT, setMetric);

  const searchRequestOverrides: SearchRequestOverride[] = useMemo(
    () => [
      {
        doAggregation: true,
        returnDocuments: false,
        pageNumber: 1,
        pageSize: 50,
        processDocuments: false,
        conditions: {
          condition: 'must',
          termFilters: [
            {
              fieldName: 'periodStartDate',
              condition: 'should',
              values: [`${startDayId}`]
            },
            {
              fieldName: 'retailerId',
              condition: 'should',
              values: [retailer.id]
            },
            {
              fieldName: 'periodEndDate',
              condition: 'should',
              values: [`${endDayId}`]
            },
            {
              condition: 'should',
              fieldName: 'segmentId',
              values: ['DEFAULT']
            }
          ],
          computeFilters: []
        },
        aggregations: [
          {
            aggregationFields: [
              'retailerId',
              'adSales',
              'usersInBucket',
              'detailPageView',
              'impressions',
              'totalUnitsSold',
              'addToCart',
              'orders',
              'clicks'
            ].map((fieldName) => ({
              aggregateByFieldName: fieldName,
              canBeExported: true,
              function: 'sum'
            })),
            groupByFieldName: 'frequencyBucket.keyword'
          }
        ]
      }
    ],
    [endDayId, startDayId, retailer.id]
  );

  const requestContextOverrides: Partial<ESSRequestContext> = useMemo(
    () => ({
      customResponseParser: ({ apiResponse, state }) => {
        return {
          ...state[AD_MANAGER_STATE_PROPERTY_NAME],
          adFrequencyData: advancedSearchResponseToAdFrequencyResponse(apiResponse.data[0])
        };
      }
    }),
    []
  );

  const { data } = useConnectFetchEntityMetrics({
    indexName: 'retargeting-frequency-metrics',
    visualizationKey: 'adFrequencyData',
    searchRequestOverrides,
    requestContextOverrides
  });

  /**
   * Safely access ad frequency data, because while fetching
   * it will be undefined in Redux
   */
  const adFrequencyData = useMemo(() => {
    return data && data.adFrequencyData ? data.adFrequencyData : null;
  }, [data]);

  /**
   * Insight text to display above the graph.
   */
  const insightText = useMemo(() => {
    if (!adFrequencyData) {
      return '';
    }

    const oneDspAdMetric = adFrequencyData[metric].barChartMetrics[0];
    const dspAdMetricTotal = _sum(adFrequencyData[metric].barChartMetrics);
    const { barMetric, lineMetric } = getChartMetricsByDropdownMetric(metric);
    const oneAdDisplayValue = `${formatMetric(oneDspAdMetric, METRICTYPE.VOLUME, {
      showFullValue: false,
      decimalPlaces: 2
    })} ${barMetric.toLowerCase()}`;
    const dspMetricTotalPercent = formatMetric(oneDspAdMetric / dspAdMetricTotal, METRICTYPE.PERCENT, {
      decimalPlaces: 2
    });

    const oneDspAdRate = adFrequencyData[metric].lineChartMetrics[0];
    const oneDspAdRateDisplay = formatMetric(oneDspAdRate, METRICTYPE.PERCENT, { decimalPlaces: 2 });
    const metricDisplay = lineMetric === AdFrequencyLineMetric.DPVR ? 'DPVR' : 'conversion rate';
    const { totalMetricRate } = adFrequencyData[metric];
    const totalMetricRateDisplay = formatMetric(totalMetricRate, METRICTYPE.PERCENT, { decimalPlaces: 2 });
    const comparison = oneDspAdRate < totalMetricRate ? 'lower' : 'higher';

    return `Shoppers exposed to one DSP ad generated ${oneAdDisplayValue}, accounting for ${dspMetricTotalPercent} of the total. These shoppers had a ${metricDisplay} of ${oneDspAdRateDisplay}, which was ${comparison} than the overall ${metricDisplay} of ${totalMetricRateDisplay}.`;
  }, [adFrequencyData, metric, formatMetric]);

  return (
    <Visualization
      titleProps={{
        title: ''
      }}
    >
      <VisualizationInsight>{insightText}</VisualizationInsight>
      <div style={{ marginTop: '24px' }}>
        <AdFrequencyBarLineChart metric={metric} data={adFrequencyData} />
      </div>
    </Visualization>
  );
};

export default withBus('eventBus')(DspAdFrequencyVisualization);
