import React from 'react';
import EntityMetricDropDown from 'src/components/common/DropDown/EntityMetricDropDown';
import EntityIconButtonGroup from 'src/components/common/Buttons/EntityIconButtonGroup';

interface OmniEntityHeaderProps {
  view: string;
  handleChangeLayout: (isTableView: boolean) => void;
  shouldSupportTableView: boolean;
  handleGroupByChange: () => void;
  shouldDisableDropDown: boolean;
  groupByFields: {
    name: string;
    displayName: string;
    isSelected: boolean;
  }[];
  selectedGroupByField: {
    name: string;
    displayName: string;
    isSelected: boolean;
    groupByKey: string;
  };
}

const OmniEntityGridHeader: React.FC<OmniEntityHeaderProps> = ({
  view,
  handleChangeLayout,
  handleGroupByChange,
  groupByFields,
  shouldSupportTableView,
  shouldDisableDropDown,
  selectedGroupByField
}: OmniEntityHeaderProps) => {
  return (
    <div>
      <div className="omni-grid-header">
        <div style={{ display: 'flex', visibility: shouldDisableDropDown ? 'hidden' : 'visible' }}>
          <EntityMetricDropDown
            handleMetricsFieldChange={handleGroupByChange}
            metricFields={groupByFields}
            style={{ marginTop: '-34px', paddingBottom: '4px', marginRight: '15px' }}
          />
          <div
            className="omni-grid-header__icon-menu"
            style={{
              margin: '-23px 0 0 -6px',
              visibility: shouldSupportTableView ? 'visible' : 'hidden'
            }}
          >
            <EntityIconButtonGroup
              defaultView={view}
              handleChangeLayout={handleChangeLayout}
              groupByFields={groupByFields}
              selectedGroupByField={selectedGroupByField}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OmniEntityGridHeader;
