import React, { useState, useMemo } from 'react';
import Visualization from '../../components/Visualization';
import VisualizationInsight from '../../components/VisualizationInsight';
import { AdvertisingAnalyticsDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import AdvertisingAnalysisBarPointChart from './AdvertisingAnalyticsBarPointChart';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import useAdAnalyticsQuery from './useAdAnalyticsQuery';
import { useMetricFormatter, useBus } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import _sum from 'lodash/sum';
import { withBus } from 'react-bus';
import { ADVERTISING_ANALYTICS_EVENT } from './constants';
import { EventBus } from 'src/types/utils';

interface AdvertisingAnalyticsVisualizationProps {
  eventBus: EventBus;
}

const AdvertisingAnalyticsVisualization = ({ eventBus }: AdvertisingAnalyticsVisualizationProps) => {
  const [metric, setMetric] = useState(AdvertisingAnalyticsDropdownOption.Awareness);
  const { data, isLoading } = useAdAnalyticsQuery();
  const formatMetric = useMetricFormatter();

  useBus(eventBus, ADVERTISING_ANALYTICS_EVENT, setMetric);

  const insightText = useMemo(() => {
    if (!data) {
      return '';
    }

    /**
     * Ad type with highest metric
     */
    const highestAdType = data[metric].adTypes[0]; // Metrics are sorted in descending order so the first item for each metric will be the largest

    /**
     * Highest metric value
     */
    const highestMetric = data[metric].columnMetrics[0];

    /**
     * Metric formatted for display
     */
    const formattedHighestMetric = formatMetric(
      highestMetric,
      metric === AdvertisingAnalyticsDropdownOption.Spend ? METRICTYPE.MONEY : METRICTYPE.VOLUME,
      { showFullValue: false, decimalPlaces: 2 }
    );

    /**
     * Total of all the column metrics so we can calculate percentage
     */
    const columnMetricTotal = _sum(data[metric].columnMetrics);

    /**
     * Percentage of all column metrics that the highest metric represent
     */
    const formattedMetricPercent = formatMetric(
      columnMetricTotal > 0 ? highestMetric / columnMetricTotal : 0,
      METRICTYPE.PERCENT,
      { decimalPlaces: 2 }
    );

    switch (metric) {
      case AdvertisingAnalyticsDropdownOption.Awareness:
        return `${highestAdType} generated the most impressions at ${formattedHighestMetric}, which represented ${formattedMetricPercent} of all impressions across DSP and Sponsored Ads.`;
      case AdvertisingAnalyticsDropdownOption.Engagement:
        return `${highestAdType} generated the most clicks at ${formattedHighestMetric}, which represented ${formattedMetricPercent} of all clicks across DSP and Sponsored Ads.`;
      case AdvertisingAnalyticsDropdownOption.Spend:
        return `${highestAdType} generated the highest ad spend at ${formattedHighestMetric}, which represented ${formattedMetricPercent} of all ad spend across DSP and Sponsored Ads.`;
      default:
        return '';
    }
  }, [data, metric, formatMetric]);

  return (
    <Visualization
      titleProps={{
        title: ''
      }}
    >
      <VisualizationInsight>{insightText}</VisualizationInsight>
      <div style={{ marginTop: '24px' }}>
        {isLoading && <GenericChartLoading />}
        {!isLoading && data && <AdvertisingAnalysisBarPointChart data={data} metric={metric} />}
      </div>
    </Visualization>
  );
};

export default withBus('eventBus')(AdvertisingAnalyticsVisualization);
