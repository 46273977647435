import { useForecastPeriod } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import {
  useDisplayedForecastData,
  useMetricByWeekIdArgsForForecast
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useForecastMetricByWeekId';
import { useKeyMetricForecastSummaryData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useKeyMetricForecastSummaryData';
import { ForecastPeriod } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import useDerivedMetricByWeekId from 'src/serverProxy/useDerivedMetricByWeekId';
import { UseMetricByWeekIdArgs } from 'src/serverProxy/useMetricByWeekId';

/**
 * Special case for purchase rate because it relies on metrics
 * from two different indexes, which we can't get with the
 * normal useMetricByWeekId or useDerivedMetricByWeekId hooks.
 *
 * TODO we want to deprecate this when backend can optimize the traffic
 * index and potentially allow purchase rate to be queried from a single
 * index
 */
export default function useForecastDerivedMetricByWeekId(args: UseMetricByWeekIdArgs) {
  const { getSplineData, isLoading } = useKeyMetricForecastSummaryData();
  const metricByWeekIdArgs = useMetricByWeekIdArgsForForecast();
  const forecastPeriod = useForecastPeriod();

  const metricsByWeekId = useDerivedMetricByWeekId(args.indexName, args.fieldName, {
    ...metricByWeekIdArgs
  });
  const forecastData = useDisplayedForecastData({
    primaryData: metricsByWeekId.primaryData,
    secondaryData: metricsByWeekId.secondaryData,
    forecastData: getSplineData(args.indexName, args.fieldName)
  });

  return {
    ...metricsByWeekId,
    forecastData,
    isLoading: isLoading || metricsByWeekId.isLoading,
    primaryData: forecastPeriod === ForecastPeriod.FULL_YEAR ? metricsByWeekId.primaryData : []
  };
}
