import React, { useMemo, useState } from 'react';
import { AdConversionPathwaysDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { useBus, useAppSelector } from 'src/utils/Hooks';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import Visualization from 'src/components/Layout/Advertising/ConnectPageLayout/components/Visualization';
import ConversionPathwaysBarChart from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/ConversionPathways/ConversionPathwaysBarchart';
import useConnectFetchEntityMetrics from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/hooks/useConnectFetchEntityMetrics';
import { AD_PATHWAYS_EVENT } from './constants';
import {
  adConversionPathwaysAdvancedSearchParser,
  buildRequestForConversionPathways
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/ConversionPathways/utils';
import useAmcDateRange from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/hooks/useAmcDateRange';
import { ESSRequestContext, SearchRequestOverride } from 'src/store/modules/entitySearchService/operationTypes';
import { AD_MANAGER_STATE_PROPERTY_NAME } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/constants';
import _get from 'lodash/get';
import { AdConversionPathwaysDocument } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/ConversionPathways/types';

interface ConversionPathwaysVisualizationProps {
  eventBus: EventBus;
}

const ConversionPathwaysVisualization = ({ eventBus }: ConversionPathwaysVisualizationProps) => {
  const [metric, setMetric] = useState(AdConversionPathwaysDropdownOption.Impressions);
  const { startDayId, endDayId } = useAmcDateRange();
  const retailer = useAppSelector((state) => state.retailer);

  useBus(eventBus, AD_PATHWAYS_EVENT, setMetric);

  // Advanced Search Request Overrides
  const searchRequestOverrides: SearchRequestOverride[] = useMemo(
    () => buildRequestForConversionPathways({ startDayId, endDayId, retailerId: retailer.id }),
    [endDayId, startDayId, retailer.id]
  );

  // ESS Context Overrides
  const requestContextOverrides: Partial<ESSRequestContext> = useMemo(
    () => ({
      customResponseParser: ({ apiResponse, state }) => {
        return {
          ...state[AD_MANAGER_STATE_PROPERTY_NAME],
          adConversionPathwaysData: adConversionPathwaysAdvancedSearchParser(apiResponse.data[0])
        };
      }
    }),
    []
  );

  // Fetching
  const { data, isLoading } = useConnectFetchEntityMetrics({
    indexName: 'conversion-pathways',
    visualizationKey: 'conversionPathwaysBarchart',
    searchRequestOverrides,
    requestContextOverrides
  });

  // Data Access
  const chartData: AdConversionPathwaysDocument[] = useMemo(() => {
    return _get(data, 'adConversionPathwaysData', []);
  }, [data]);

  return (
    <Visualization
      titleProps={{
        title: ''
      }}
    >
      <div style={{ marginTop: '24px' }}>
        {isLoading && <GenericChartLoading />}
        {!isLoading && chartData && <ConversionPathwaysBarChart data={chartData} metric={metric} />}
      </div>
    </Visualization>
  );
};

export default withBus('eventBus')(ConversionPathwaysVisualization);
