import React from 'react';
import { useAppSelector } from 'src/utils/Hooks';
import { getProductImageUrl } from 'src/utils/image';
import EntityRow from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/EntityRow';
import { ForecastsAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';

export const EntityRowContainer = ({
  productEntity = null
}: {
  productEntity?: ForecastsAdjustmentData['product'];
}) => {
  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);

  const entityData = productEntity || mainEntity;
  const propType = productEntity ? 'productFromProps' : 'productFromMainEntity';

  const entityTypeToRowProps = {
    productFromProps: {
      title: entityData.brandName,
      header: (
        <img
          src={getProductImageUrl(entityData.stacklineSku)}
          style={{
            maxWidth: '112px',
            maxHeight: '112px'
          }}
          alt={entityData.title}
        />
      ),
      subtitleText: entityData.title,
      retailerSku: entityData.retailerSku
    },
    productFromMainEntity: {
      title: entityData.brandName,
      header: (
        <img
          src={getProductImageUrl(entityData.stacklineSku)}
          style={{
            maxWidth: '112px',
            maxHeight: '112px'
          }}
          alt={entityData.name}
        />
      ),
      subtitleText: entityData.name,
      retailerSku: entityData.retailerSku
    }
  };

  return <EntityRow {...entityTypeToRowProps[propType]} />;
};
