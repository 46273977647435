import types from './types';
import { appTypes } from '../app';
// ------------------------------------
// Reducers
// ------------------------------------

// ------------------------------------
// APP object
// ------------------------------------

const initialUserState = {
  session: null,
  profile: {},
  team: [],
  billing: {},
  isUserFecthing: false,
  adCampaigns: null
};

// ------------------------------------
// User
// ------------------------------------
export default function user(state = initialUserState, action) {
  switch (action.type) {
    case types.CLEAR_USER_CONFIG:
      return {
        ...state,
        config: null,
        session: null,
        lastUpdated: action.receivedAt
      };
    case types.RECEIVE_USER_CONFIG:
      return {
        ...state,
        config: {
          secondaryRetailerIds: state.config && state.config.secondaryRetailerIds,
          secondaryRetailersLoading: state.config && state.config.secondaryRetailersLoading,
          ...action.userConfig
        },
        session: action.userSession,
        lastUpdated: action.receivedAt
      };
    case types.SAVE_USER_PROFILE:
      return Object.assign(
        {},
        state,
        { lastUpdated: action.receivedAt },
        { profile: action.profile },
        { isUserFecthing: false }
      );
    case types.SET_USER_TRANSLATION:
      return Object.assign(
        {},
        state,
        { lastUpdated: action.receivedAt },
        { config: { ...state.config, translationEnabled: action.translationEnabled } },
        { isUserFecthing: false }
      );

    case types.SAVE_USER_BILLING:
      return Object.assign(
        {},
        state,
        { lastUpdated: action.receivedAt },
        { billing: action.billing.billingInfo },
        { isUserFecthing: false },
        { session: { ...state.session, isAdmin: action.billing.isAdmin } }
      );

    case types.SAVE_USER_TEAM:
      return Object.assign(
        {},
        state,
        { lastUpdated: action.receivedAt },
        { team: action.team },
        { isUserFecthing: false }
      );

    case types.RECEIVE_AD_CAMPAIGNS:
      return { ...state, adCampaigns: action.adCampaigns };

    case types.REQUEST_USER_INFO:
      return Object.assign({}, state, { isUserFecthing: true });

    case types.UPDATE_USER_DISPLAY_COLUMNS:
      return Object.assign({}, state, {
        config: {
          ...state.config,
          uiPreference: {
            ...state.config.uiPreference,
            advertisingDisplayColumns: action.displayColumns,
            advertisingDisplayColumnsByPlatformType: {
              ...(state.config.uiPreference.advertisingDisplayColumnsByPlatformType || {}),
              [action.platformType]: action.displayColumns
            }
          }
        }
      });

    case types.SET_USER_REDIRECTION_URL:
      // redirect to this url after user saves their column preferences
      return Object.assign({}, state, { config: { ...state.config, redirectionUrl: action.redirectionUrl } });

    case types.SET_AD_AUDIT_USER_PREFERENCE:
      return Object.assign({}, state, {
        config: {
          ...state.config,
          uiPreference: {
            ...state.config.uiPreference,
            adAudit: {
              hasRequestedDemo: action.payload.hasRequestedDemo,
              nextReminder: action.payload.nextReminder,
              hasDisabledPrompt: action.payload.hasDisabledPrompt
            }
          }
        }
      });

    case types.SET_USER_SECONDARY_RETAILERS:
      return {
        ...state,
        config: {
          ...state.config,
          secondaryRetailerIds: action.payload.secondaryRetailerIds,
          secondaryRetailersLoading: false
        }
      };
    case types.REQUEST_SECONDARY_RETAILERS:
      return {
        ...state,
        config: {
          ...state.config,
          secondaryRetailersLoading: true
        }
      };
    case types.SAVE_LAST_UPDATED:
      return {
        ...state,
        lastUpdated: Date.now()
      };
    case appTypes.REQUEST_CONFIG:
    default:
      return state;
  }
}
