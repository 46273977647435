// ------------------------------------
// Constants/Types
// ------------------------------------

const CLEAR_USER_CONFIG = 'src/user/CLEAR_USER_CONFIG';
const RECEIVE_USER_CONFIG = 'src/user/RECEIVE_USER_CONFIG';
const SAVE_USER_PROFILE = 'src/user/SAVE_USER_PROFILE';
const SAVE_USER_BILLING = 'src/user/SAVE_USER_BILLING';
const SAVE_USER_TEAM = 'src/user/SAVE_USER_TEAM';
const REQUEST_USER_INFO = 'src/user/REQUEST_USER_INFO';
const RECEIVE_AD_CAMPAIGNS = 'src/user/RECEIVE_AD_CAMPAIGNS';
const SET_USER_TRANSLATION = 'src/user/SET_USER_TRANSLATION';
const SET_USER_REDIRECTION_URL = 'src/user/SET_USER_REDIRECTION_URL';
const UPDATE_USER_DISPLAY_COLUMNS = 'src/user/UPDATE_USER_DISPLAY_COLUMNS';
const SET_AD_AUDIT_USER_PREFERENCE = 'src/user/SET_AD_AUDIT_USER_PREFERENCE';
const SET_USER_SECONDARY_RETAILERS = 'src/user/SET_USER_SECONDARY_RETAILERS';
const REQUEST_SECONDARY_RETAILERS = 'src/user/REQUEST_SECONDARY_RETAILERS';
const SAVE_LAST_UPDATED = 'src/user/SAVE_LAST_UDPATED';

export default {
  CLEAR_USER_CONFIG,
  RECEIVE_USER_CONFIG,
  SAVE_USER_PROFILE,
  SET_USER_TRANSLATION,
  SAVE_USER_BILLING,
  SAVE_USER_TEAM,
  REQUEST_USER_INFO,
  RECEIVE_AD_CAMPAIGNS,
  SET_USER_REDIRECTION_URL,
  UPDATE_USER_DISPLAY_COLUMNS,
  SET_AD_AUDIT_USER_PREFERENCE,
  SET_USER_SECONDARY_RETAILERS,
  REQUEST_SECONDARY_RETAILERS,
  SAVE_LAST_UPDATED
};
