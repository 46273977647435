import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { parse, stringify, ParsedQs } from 'qs';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import _pick from 'lodash/pick';

import * as appOperations from 'src/store/modules/app/operations';
import { updateAllTimePeriods } from 'src/store/modules/main-time-period/operations';
import { computeMainTimePeriod, computeComparisonTimePeriod } from 'src/utils/dateformatting';
import { ChevronIcon } from '../SvgIcons';
import ReduxStore from 'src/types/store/reduxStore';
import { QueryResponse } from 'src/types/application/types';
import { AvailableRetailer } from 'src/types/store/storeTypes';
import { usesCustomTimePeriods, usesSecondaryRetailers } from 'src/utils/app';
import { getInitialState as getDefaultTimePeriods } from 'src/store/modules/main-time-period/reducers';
import { fetchParentPlatforms, setParentPlatform } from 'src/store/modules/parentPlatform/operations';
import {
  PARENT_PLATFORMS,
  ParentPlatformUtils,
  SingleParentPlatform
} from 'src/store/modules/parentPlatform/platformUtils';
import { track } from 'src/utils/mixpanel';

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, [
    'allWeekIdsByRetailerId',
    'app',
    'comparisonTimePeriod',
    'mainTimePeriod',
    'retailer',
    'user',
    'parentPlatform'
  ]);

const mapDispatchToProps = {
  updateQueryParams: appOperations.updateQueryParams,
  updateAllAppTimePeriods: updateAllTimePeriods,
  updateParentPlatform: setParentPlatform
};

function splitUrl(url) {
  const questionMarkPosition = url.indexOf('?');
  if (questionMarkPosition === -1) {
    return { path: url, queryString: '' };
  }

  return {
    path: url.slice(0, questionMarkPosition),
    queryString: url.slice(questionMarkPosition + 1)
  };
}

interface AdRetailerDropDownProps extends RouteComponentProps {
  allWeekIdsByRetailerId: any;
  app: any;
  comparisonTimePeriod: any;
  mainTimePeriod: any;
  retailer: any;
  parentPlatform: any;
  user: any;
  labelStyle?: { color: string; fontWeight: number };
  updateQueryParams: typeof appOperations.updateQueryParams;
  updateAllAppTimePeriods: typeof updateAllTimePeriods;
  updateParentPlatform: typeof setParentPlatform;
}

const AdRetailerDropDown: React.FC<AdRetailerDropDownProps> = ({ ...props }) => {
  const {
    parentPlatform,
    retailer,
    user,
    allWeekIdsByRetailerId,
    app,
    location,
    mainTimePeriod,
    comparisonTimePeriod,
    updateQueryParams,
    updateAllAppTimePeriods,
    updateParentPlatform
  } = props;
  const { availableRetailers } = retailer;
  const { current: currentParentPlatform, availablePlatforms, parentPlatformsLoading } = parentPlatform;
  const dispatch = useDispatch();

  const isRetailerActive = (r: AvailableRetailer): boolean => {
    if (user.config.secondaryRetailerIds && usesSecondaryRetailers()) {
      return (
        user.config.secondaryRetailerIds.includes(parseInt(r.id, 10)) &&
        user.config.allWeekIdsByRetailerId[+r.id] &&
        r.supportedAppNames.includes(app.name)
      );
    }

    return (
      user.config.allRetailerIds &&
      user.config.allRetailerIds.includes(parseInt(r.id, 10)) &&
      user.config.allWeekIdsByRetailerId[+r.id] &&
      r.supportedAppNames.includes(app.name)
    );
  };

  const availableRetailersWithActiveProp = availableRetailers.map((val) => ({
    ...val,
    active: isRetailerActive(val)
  }));

  const IDMapOfRetailers = useMemo(() => {
    if (!currentParentPlatform || (currentParentPlatform.retailers && currentParentPlatform.retailers.length === 0)) {
      return [];
    }
    const hash = {};
    currentParentPlatform.retailers.forEach((i) => {
      hash[i.extendedAttributes.retailerId] = i;
    });

    return hash;
  }, [currentParentPlatform]);

  const orderedRetailers = availableRetailersWithActiveProp.filter((i) => i.active);
  // 0 - represents allRetailers, we want to add it to the list if parentPlatform = Criteo
  const orderedRetailersForPick = useMemo(() => {
    if (!currentParentPlatform) {
      return availableRetailersWithActiveProp.filter((i) => i.active);
    }
    return availableRetailersWithActiveProp.filter(
      (i) =>
        (i.active && IDMapOfRetailers[i.id]) || (i.id === '0' && currentParentPlatform.id === PARENT_PLATFORMS.CRITEO)
    );
  }, [availableRetailersWithActiveProp, IDMapOfRetailers, currentParentPlatform]);

  const availableRetailersWithActivePropMap = orderedRetailers.map((r) => r.id);

  const availablePlatformsExtended = ParentPlatformUtils.setAvailabilityForParentPlatforms(
    availablePlatforms,
    availableRetailersWithActivePropMap
  );

  const handleRetailerChange = (retailerId: string, _parentPlatform = null) => {
    const queryParams = parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    if (_parentPlatform) {
      queryParams.pp = `${_parentPlatform.id}`;
    }
    const { availableMainTimePeriods } = usesCustomTimePeriods() ? getDefaultTimePeriods() : mainTimePeriod;
    const { availableComparisonTimePeriods } = comparisonTimePeriod;
    const allWeekIds = allWeekIdsByRetailerId[retailerId] || [];

    const comparisonTimePeriodId =
      comparisonTimePeriod && comparisonTimePeriod.id !== undefined
        ? comparisonTimePeriod.id
        : availableComparisonTimePeriods[0].id;

    // Default to first app default time period if using secondary retailers
    const mainPeriodIndex = usesCustomTimePeriods()
      ? availableMainTimePeriods.findIndex((period) => period.default)
      : mainTimePeriod.availableMainTimePeriods.findIndex((x) => x.id === mainTimePeriod.id);
    const newTimePeriod = computeMainTimePeriod(allWeekIds, availableMainTimePeriods[mainPeriodIndex], app);
    const compPeriodId = availableComparisonTimePeriods.find((x) => x.id === comparisonTimePeriodId)!.id;
    const updatedComparisonPeriod = computeComparisonTimePeriod(allWeekIds, newTimePeriod, compPeriodId);

    updatedComparisonPeriod.comparisonIndex = availableComparisonTimePeriods.findIndex(
      (x) => x.id === comparisonTimePeriodId
    );
    updateAllAppTimePeriods(allWeekIds, newTimePeriod, updatedComparisonPeriod);

    updateQueryParams(
      app,
      { id: retailerId },
      newTimePeriod,
      updatedComparisonPeriod,
      queryParams,
      _parentPlatform || currentParentPlatform
    ).then((response: QueryResponse) => {
      const { additionalParams, dropDownSelectionParams, nonPersistentParams, searchParams } = response.params;
      let customParams = queryParams.tab || queryParams.entityType ? dropDownSelectionParams : additionalParams;
      // Go back to home page if retailer changes on ad manager
      location.pathname = '/overview';
      customParams = '&tab=adManager&subtab=keyMetrics';
      const url = `${location.pathname}${searchParams}${customParams}${nonPersistentParams}`;

      // Separate the path and the query string.
      const { path, queryString } = splitUrl(url);

      // Parse the query string part.
      const queryStringParsed: ParsedQs = parse(queryString);

      // Update 'pp' parameter.
      if (_parentPlatform) {
        (queryStringParsed as Record<string, unknown>).pp = _parentPlatform.id;
      }

      // Stringify the updated query parameters.
      const updatedQueryString: string = stringify(queryStringParsed);

      // Combine the original path and the updated query string.
      const updatedUrl = `${path}?${updatedQueryString}`;

      return window.open(updatedUrl, '_self');
    });
  };

  const handlePlatformChange = (event) => {
    const platformId = event.target.value as string;
    const platform = availablePlatforms.find((e) => e.id === platformId) as SingleParentPlatform;

    if (platform && platform.id === PARENT_PLATFORMS.CRITEO) {
      // For Criteo use All retailers as default option
      handleRetailerChange(`0`, platform);
    } else {
      const firstAvailableRetailer = ParentPlatformUtils.getFirstRetailerWithConfig(
        platform,
        availableRetailersWithActivePropMap
      );
      handleRetailerChange(`${firstAvailableRetailer.extendedAttributes.retailerId || 1}`, platform);
    }

    updateParentPlatform(platform);
  };

  useEffect(() => {
    if ((!availablePlatformsExtended || availablePlatformsExtended.length === 0) && !parentPlatformsLoading) {
      dispatch(fetchParentPlatforms());
      console.error('Missing parent platforms, Trying to fetch more:');
      track('Missing parent platforms', {});
    }
  }, [parentPlatformsLoading, availablePlatformsExtended]);

  return (
    <>
      {/* Platform */}
      <Select
        variant="standard"
        value={(currentParentPlatform && currentParentPlatform.id) || ''}
        // The type given by Material UI disagrees with the type of the actual event.
        onChange={handlePlatformChange as any}
        input={<OutlinedInput labelWidth={0} id="outlined-age-simple" />}
        IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
        className="retailer-dropdown"
      >
        {availablePlatformsExtended.map((val) => (
          <MenuItem disabled={!val.active} key={val.id} value={val.id}>
            {val.name}
          </MenuItem>
        ))}
      </Select>
      {/* Retailer */}
      <Select
        variant="standard"
        value={retailer.id}
        // The type given by Material UI disagrees with the type of the actual event.
        onChange={(e) => handleRetailerChange(e.target.value)}
        input={<OutlinedInput labelWidth={0} id="outlined-age-simple" />}
        IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
        className="retailer-dropdown"
      >
        {orderedRetailersForPick.map((val) => {
          return (
            <MenuItem
              key={val.name}
              value={val.id}
              disabled={!val.active}
              title={
                !user.config.allRetailerIds.includes(parseInt(val.id, 10))
                  ? `It appears that you don't currently have ${val.displayName} data integrated with Stackline.\nPlease contact us at support@stackline.com to get started.`
                  : ''
              }
            >
              {val.displayName}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdRetailerDropDown));
