import { ValueOf } from 'sl-api-connector/types';

import actions from './actions';
import types from './types';

const initialState = {
  availablePlatforms: [],
  current: null,
  parentPlatformsLoading: false
};

export default function parentPlatform(state = initialState, action: ReturnType<ValueOf<typeof actions>>) {
  switch (action.type) {
    case types.SET_PARENT_PLATFORM:
      return {
        ...state,
        current: action.parentPlatform
      };
    case types.SET_ALL_PARENT_PLATFORMS:
      return {
        ...state,
        availablePlatforms: action.availablePlatforms,
        parentPlatformsLoading: false
      };

    case types.REQUEST_PARENT_PLATFORMS:
      return {
        ...state,
        parentPlatformsLoading: true
      };
    default:
      return state;
  }
}
