import React, { Fragment, useState, useMemo, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import { FilterIcon } from 'src/components/SvgIcons';
import OmniFilterPopUp from 'src/components/Omni/OmniFilter/OmniFilterPopUp';
import { makeStyles } from '@mui/styles';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import queryString from 'qs';
import colors from 'src/utils/colors';

interface OmniFilterProps extends RouteComponentProps {
  shouldShowSegmentFilter: boolean;
  shouldShowFilter: boolean;
  shouldShowKeywordSegmentFilter?: boolean;
  shouldShowOnlyRetailerFilter?: boolean;
}

const useStyles = makeStyles({
  badge: {
    backgroundColor: colors.darkBlue
  }
});

const OmniFilter: React.FC<OmniFilterProps> = ({
  shouldShowSegmentFilter,
  shouldShowFilter,
  shouldShowKeywordSegmentFilter = false,
  location,
  shouldShowOnlyRetailerFilter = false
}: OmniFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);

  // calculate the filter applied
  const queryParams = useMemo(
    () => queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 }),
    [location.search]
  );
  const fieldApplied = useMemo(() => {
    let count = 0;
    if (queryParams.filter) {
      const filterObject = queryString.parse(JSON.parse(queryParams.filter));
      count = Object.keys(filterObject).length;
    }

    return count;
  }, [queryParams.filter]);

  const [badgeNumber, setBadgeNumber] = useState(fieldApplied);
  useEffect(() => {
    setBadgeNumber(fieldApplied);
  }, [fieldApplied]);

  const classes = useStyles();

  return (
    <Fragment>
      <Tooltip
        title="Select Filters"
        placement="top"
        onClick={() => setIsOpen(!isOpen)}
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: '0.84px',
          textAlign: 'left',
          textTransform: 'uppercase',
          cursor: 'pointer'
        }}
      >
        {/* we can control the filter icon display in the omni according to the visibility */}
        <div
          style={{
            visibility: shouldShowFilter ? 'visible' : 'hidden',
            display: 'flex',
            gap: 12,
            cursor: 'pointer'
          }}
        >
          <Badge variant="dot" overlap="circular" classes={{ badge: classes.badge }} invisible={badgeNumber === 0}>
            <FilterIcon
              style={{
                height: '13px',
                width: '15px',
                transform: 'translateY(2px)'
              }}
            />
          </Badge>
          <div
            style={{
              width: '48px',
              height: '19px',
              margin: '0 0 0 0',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 'bold',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: '0.84px',
              textAlign: 'left',
              color: '#052849'
            }}
          >
            FILTER
          </div>
        </div>
      </Tooltip>
      <OmniFilterPopUp
        open={isOpen}
        onClose={() => setIsOpen(false)}
        shouldShowSegmentFilter={shouldShowSegmentFilter}
        shouldShowKeywordSegmentFilter={shouldShowKeywordSegmentFilter}
        setBadgeNumber={setBadgeNumber}
        shouldShowOnlyRetailerFilter={shouldShowOnlyRetailerFilter}
      />
    </Fragment>
  );
};

export default withRouter(OmniFilter);
