import React, { useEffect, useMemo, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _get from 'lodash/get';
import _pick from 'lodash/pick';

import { userOperations } from 'src/store/modules/user';
import BeaconSignInContainer from '../SignIn/BeaconSignIn';
import AtlasSignInContainer from '../SignIn/AtlasSignIn';
import billingFieldDefinitions from '../Forms/FieldDefinitions/Billing';
import LoggingIn from './LoggingIn';
import GenericForm, { formComponentPropTypes } from '../Forms/GenericForm';
import { panic } from 'src/utils/mixpanel';

const INPUT_BOX_HEIGHT = 84;

const styles = {
  inputsWrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    width: 650
  },
  twoFieldsWrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    width: 305
  },
  submitButtonWrapper: {
    marginTop: 28,
    marginBottom: 30,
    textAlign: 'center'
  },
  inputBox: { height: INPUT_BOX_HEIGHT },
  errorMessage: {
    textAlign: 'center',
    fontWeight: 400,
    color: 'red',
    marginBottom: 20,
    backgroundColor: '#e1e1e1',
    padding: 8,
    borderRadius: 9
  },
  halfWidthStyle: {
    maxWidth: '45%'
  }
};

// TODO: pull out and make separate
const childrenPropType = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]);

const StyledInputSectionWrapper = ({ wrapperStyle, children }) => <div style={wrapperStyle}>{children}</div>;

StyledInputSectionWrapper.propTypes = {
  children: childrenPropType.isRequired,
  wrapperStyle: PropTypes.object.isRequired
};

const formatExpirationDate = (value = '') => {
  const [month, year] = value.split('/');
  return {
    expirationMonth: month || '',
    expirationYear: year ? `20${year}` : ''
  };
};

const buildRequestBody = (state) => {
  const { expirationMonth, expirationYear } = formatExpirationDate(state.expiration);

  return {
    creditCard: {
      name: state.cardName,
      cardNumber: state.cardNumber,
      expirationMonth,
      expirationYear,
      cvv: state.cvv
    },
    address: {
      country: 'US',
      addressLine: state.addressLine,
      addressLine2: state.addressLine2 || '',
      city: state.city,
      state: state.state,
      zip: state.zip
    }
  };
};

const SubmissionError = ({ errMsg }) => <div style={styles.errorMessage}>{errMsg}</div>;

SubmissionError.propTypes = {
  errMsg: PropTypes.string.isRequired
};

const defaultFieldDefinition = { variant: 'outlined' };

// For each key here, the listed props will be combined with those from `billingFieldDefinitions` and used as
// overrides for the base props there.
const fieldDefinitionOverrides = {
  expiration: { id: 'formatted-numberformat-input', style: styles.halfWidthStyle },
  cvv: { style: styles.halfWidthStyle },
  state: { style: styles.halfWidthStyle },
  zip: { style: styles.halfWidthStyle }
};

const mapStateToProps = (state) => _pick(state, ['app', 'user']);

const getSignInContainer = (appName) => (appName === 'atlas' ? AtlasSignInContainer : BeaconSignInContainer);

/**
 * The main form component containing all of the input elements.  Its form state is stored in Redux, and it handles
 * the intermediary state of the form as well as submission and error states.
 */
const BillingInformation = ({ app: { name: appName }, submissionError, onSubmit, isSubmitting, TextInput }) => {
  const Container = getSignInContainer(appName);

  return (
    <Container appName={appName}>
      <h1 className="signup-form-head" style={{ fontSize: '41px' }}>
        Please enter a payment method
      </h1>

      <div style={styles.inputsWrapper}>
        <div style={{ maxWidth: '45%' }}>
          <TextInput name="cardNumber" />
          <TextInput name="cardName" />

          <div style={styles.twoFieldsWrapper}>
            <TextInput name="expiration" wrapperProps={{ useInputBox: false }} />
            <TextInput name="cvv" wrapperProps={{ useInputBox: false }} />
          </div>
        </div>

        <div>
          <TextInput name="addressLine" />
          <TextInput name="addressLine2" />
          <TextInput name="city" />

          <div style={styles.twoFieldsWrapper}>
            <TextInput name="state" wrapperProps={{ useInputBox: false }} />
            <TextInput name="zip" wrapperProps={{ useInputBox: false }} />
          </div>

          <div style={styles.submitButtonWrapper}>
            <button className="signin-button" type="submit" disabled={isSubmitting} onClick={onSubmit}>
              SUBMIT PAYMENT
            </button>
          </div>
        </div>
      </div>

      {submissionError ? <SubmissionError errMsg={submissionError} /> : null}
    </Container>
  );
};

BillingInformation.propTypes = { ...formComponentPropTypes };

const withReduxProps = connect(mapStateToProps, { updateUserAccount: userOperations.updateUserAccount });

const EnhancedBillingInformation = withReduxProps(BillingInformation);

BillingInformation.propTypes = {
  app: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
  user: PropTypes.shape({ session: PropTypes.shape({ isAdmin: PropTypes.bool.isRequired }).isRequired }).isRequired,
  updateUserAccount: PropTypes.func.isRequired,
  submissionError: PropTypes.string
};

BillingInformation.defaultProps = {
  submissionError: null
};

const InputBox = ({ children }) => <div style={styles.inputBox}>{children}</div>;

InputBox.propTypes = {
  children: childrenPropType.isRequired
};

const TextInputWrapper = ({ style, useInputBox, children }) => {
  if (useInputBox) {
    return <InputBox>{children}</InputBox>;
  }

  return style ? <div style={style}>{children}</div> : <Fragment>{children}</Fragment>;
};

TextInputWrapper.propTypes = {
  style: PropTypes.object,
  useInputBox: PropTypes.bool,
  children: childrenPropType.isRequired
};

TextInputWrapper.defaultProps = {
  useInputBox: true,
  style: undefined
};

const fieldDefinitions = _pick(billingFieldDefinitions, [
  'cardNumber',
  'cardName',
  'expiration',
  'cvv',
  'addressLine',
  'addressLine2',
  'city',
  'state',
  'zip'
]);

export default withRouter(
  withReduxProps(({ user, app: { name: appName }, history, updateUserAccount }) => {
    const Container = getSignInContainer(appName);

    const [loginSuccessful, setLoginSuccessful] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);

    useEffect(() => {
      if (!_get(user, 'session.isAdmin')) {
        history.push('/sign_in');
      }
    }, [history, user]);

    const FormComponent = useMemo(() => {
      const FormComponentInner = ({ ...props }) => (
        <EnhancedBillingInformation submissionError={submissionError} {...props} />
      );
      return FormComponentInner;
    }, [submissionError]);

    const handleSubmit = useMemo(
      () => async (completeFormState) => {
        try {
          const response = await updateUserAccount('/api/user/updateBillingInfo', buildRequestBody(completeFormState));
          if (response.data === 'beaconRedirect') {
            window.location.href = 'https://beacon.stackline.com';
            return;
          }

          if (response.data === 'success') {
            setTimeout(() => setLoginSuccessful(true), 1000);
          } else {
            throw panic(`Error while submitting data: ${response.data}`);
          }
        } catch (err) {
          setSubmissionError(err.message);
        }
      },
      [setLoginSuccessful, setSubmissionError, updateUserAccount]
    );

    if (loginSuccessful) {
      return (
        <Container appName={appName}>
          <LoggingIn />
        </Container>
      );
    }

    return (
      <Fragment>
        <GenericForm
          name="billingInformation"
          fieldDefinitions={fieldDefinitions}
          defaultFieldDefinition={defaultFieldDefinition}
          fieldDefinitionOverrides={fieldDefinitionOverrides}
          FormComponent={FormComponent}
          TextInputWrapper={TextInputWrapper}
          onSubmit={handleSubmit}
        />
      </Fragment>
    );
  })
);
