import React from 'react';
import OrganicTrafficSplineChart from './OrganicTrafficSplineChart';
import OrganicTrafficBarChart from './OrganicTrafficBarChart';
import ProductsWithOrganicTrafficSplineChart from './ProductsWithOrganicTrafficSplineChart';
import KeywordsWithOrganicTrafficSplineChart from './KeywordsWithOrganicTrafficSplineChart';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { useMainEntityType } from 'src/utils/Hooks/reduxSelectors';
import OrganicTrafficGrid from './OrganicTrafficGrid';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';

/**
 * All the visualizations on the organic traffic page
 */
export default function OrganicTrafficPage() {
  const mainEntityType = useMainEntityType();

  return (
    <BeaconPageContainer>
      <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
        <OrganicTrafficSplineChart />
        <OrganicTrafficBarChart />
        {mainEntityType !== 'product' && <ProductsWithOrganicTrafficSplineChart />}
        <KeywordsWithOrganicTrafficSplineChart />
        <OrganicTrafficGrid />
      </Flex>
    </BeaconPageContainer>
  );
}
