import React from 'react';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { stripNonNumberCharacters } from '../../../utils/money';

function TextMaskCustom(props) {
  const { ...other } = props;

  return (
    <MaskedInput
      // ref={(ref) => {
      //   // other.inputRef(ref ? ref.inputElement : null);
      // }}
      className="range_selector_input"
      mask={other.numberMask}
      type="text"
      name={other.name}
      id={other.filterName}
      value={other.value}
      onKeyPress={other.handleInputChange}
    />
  );
}

class RangeSelector extends React.Component {
  static propTypes = {
    filterName: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    retailer: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    const {
      value: { minValue, maxValue }
    } = props;
    this.state = {
      minValue,
      maxValue
    };
  }

  componentWillMount() {
    this.debounce = null;
    let numberMask = createNumberMask({
      prefix: this.props.retailer.currencySymbol,
      allowDecimal: true
    });
    if (this.props.retailer.isRTLLanguage) {
      // Override the number mask, since direction is flipped with UAE (Arabic) input
      // which causes the input to be a combination of LTR and RTL input, causing issues
      numberMask = createNumberMask({
        prefix: ``,
        allowDecimal: true
      });
    }

    this.setState({ numberMask });
  }

  componentWillReceiveProps(nextProps) {
    const {
      value: { minValue, maxValue }
    } = nextProps;
    this.setState({
      minValue,
      maxValue
    });
  }

  handleMinInputChange = (event) => {
    if (event.key === 'Enter') {
      const { value, name } = event.target;

      this.handleInputChange({ value, name }, { minValue: stripNonNumberCharacters(value) });
    }
  };

  handleMaxInputChange = (event) => {
    if (event.key === 'Enter') {
      const { value, name } = event.target;

      this.handleInputChange({ value, name }, { maxValue: stripNonNumberCharacters(value) });
    }
  };

  handleInputChange = ({ name, value }, obj) => {
    const { filterName, onChange } = this.props;
    if (value === '') {
      return;
    }
    const valueNumerical = stripNonNumberCharacters(value);
    const callbackValues = {
      minValue: stripNonNumberCharacters(this.state.minValue),
      maxValue: stripNonNumberCharacters(this.state.maxValue),
      ...obj
    };

    if (name === 'min') {
      callbackValues.minValue = valueNumerical;
      if (callbackValues.maxValue < valueNumerical) {
        callbackValues.maxValue = valueNumerical;
      }
    } else if (name === 'max') {
      callbackValues.maxValue = valueNumerical;
      if (callbackValues.minValue > valueNumerical) {
        callbackValues.minValue = valueNumerical;
      }
    }

    this.setState({ minValue: callbackValues.minValue, maxValue: callbackValues.maxValue });
    onChange({
      type: 'rangeFilter',
      key: filterName,
      ...callbackValues
    });
  };

  render() {
    const { displayName, filterName, retailer } = this.props;
    const { numberMask, minValue, maxValue } = this.state;
    return (
      <div className="search-form-container">
        <h4 className="sl-form-label">
          {displayName}
          {retailer.id === '53' && ` (${retailer.currencySymbol})`}
        </h4>
        <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: '8px' }}>
          <div>
            {/* <TextField id={`${filterName}MinText`} className="sl-form-input" style={{ width: 60 }}>
              <MaskedInput
                mask={numberMask}
                type="text"
                name="min"
                id={`${filterName}Min`}
                value={minValue}
                onKeyPress={this.handleMinInputChange}
              />
            </TextField> */}
            <Input
              value={minValue}
              className="sl-form-input"
              id={`${filterName}MinText`}
              inputComponent={TextMaskCustom}
              inputProps={{
                numberMask,
                name: 'min',
                filterName: `${filterName}MinText`,
                value: minValue,
                handleInputChange: this.handleMinInputChange
              }}
              sx={{ padding: 0 }}
            />
          </div>
          <div style={{ margin: '0 10px' }}>to</div>
          <div>
            <Input
              value={maxValue}
              className="sl-form-input"
              id={`${filterName}MaxText`}
              inputComponent={TextMaskCustom}
              inputProps={{
                name: 'max',
                numberMask,
                filterName: `${filterName}MaxText`,
                value: maxValue,
                handleInputChange: this.handleMaxInputChange
              }}
              sx={{ padding: 0 }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RangeSelector;
