import { Box } from '@mui/system';
import { SlColumn } from '@stackline/ui';
import React from 'react';
import KeyMetricCards, {
  KeyMetricCardDefinition
} from 'src/components/BeaconRedesignComponents/KeyMetricCards/KeyMetricCards';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { KEY_METRICS_CONTAINER_ADJUSTED_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

/**
 * Card title overrides
 */
const titleOverrides = {
  impressions: 'Ad Impressions',
  spend: 'Ad Spend',
  costPerClick: 'CPC',
  sales: 'Ad Sales',
  unitsSold: 'Ad Units Sold'
};

/**
 * Overrides for getting the field
 */
const fieldOverrides = {
  searchKeyword: 'weekId'
};

/**
 * Aggregation builders for each card by field name
 */
const aggregationBuilders = {
  searchKeyword: (aggregationBuilder: AggregationBuilder) => aggregationBuilder.addConditionRangeFilter('clicks', 1)
};

const indexName = 'advertisingDisplay';
const cardDefinitionsByColumn: KeyMetricCardDefinition[][] = [
  ['impressions', 'dpv', 'clickThroughRate', 'detailPageViewRate'],
  ['spend', 'costPerClick', 'costPerMilli', 'costPerAcquisition'],
  ['sales', 'percentNewToBrand', 'unitsSold', 'returnOnAdSpend']
].map((indexNames) =>
  indexNames.map((fieldName) => ({
    indexName,
    fieldName,
    title: titleOverrides[fieldName],
    entityType: fieldOverrides[fieldName],
    primaryAggregationBuilder: aggregationBuilders[fieldName],
    secondaryAggregationBuilder: aggregationBuilders[fieldName]
  }))
);

/**
 * Shows the key metric cards on the traffic display page
 */
const TrafficDisplayKeyMetricCards = () => {
  return (
    <SlColumn widths="full">
      <SlColumn spacing="lg" widths="full">
        <CommonSummaryInfoSubtitle title="Display Ad Summary" includeLegend />
        <Box
          sx={{
            width: KEY_METRICS_CONTAINER_ADJUSTED_WIDTH,
            transform: 'translate(-16px)'
          }}
        >
          <KeyMetricCards cardDefinitionsByColumn={cardDefinitionsByColumn} />
        </Box>
      </SlColumn>
    </SlColumn>
  );
};

export default TrafficDisplayKeyMetricCards;
