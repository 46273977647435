import React from 'react';
import OmniFilter from 'src/components/Omni/OmniFilter/OmniFilter';
import { Widget } from 'src/types/application/widgetTypes';

interface OmniFilterWrapperProps {
  widget: Widget;
}

const OmniFilterWrapper: React.FC<OmniFilterWrapperProps> = ({ widget }: OmniFilterWrapperProps) => {
  const {
    style,
    shouldShowSegmentFilter,
    shouldShowFilter,
    shouldShowKeywordSegmentFilter,
    shouldShowOnlyRetailerFilter
  } = widget.view;
  return (
    <div style={style}>
      <OmniFilter
        shouldShowSegmentFilter={Boolean(shouldShowSegmentFilter)}
        shouldShowFilter={shouldShowFilter}
        shouldShowKeywordSegmentFilter={Boolean(shouldShowKeywordSegmentFilter)}
        shouldShowOnlyRetailerFilter={Boolean(shouldShowOnlyRetailerFilter)}
      />
    </div>
  );
};

export default OmniFilterWrapper;
