import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import colors from 'src/utils/colors';
import { createNonComparisonLegendDivs } from 'src/utils/chartUtils';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { getSimpleTooltipDiv } from 'src/components/EntityPage/Renderer/tooltipDiv';
import { AdvertisingAnalyticsData } from './types';
import {
  AdvertisingAnalyticsDropdownOption,
  AdvertisingAnalyticsMetricName
} from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import _cloneDeep from 'lodash/cloneDeep';
import _sum from 'lodash/sum';
import { advertisingAnalyticsDataToCsv } from './utils';
import ColumnWithPointChart from 'src/components/Charts/ColumnWithPoint/ColumnWithPointChart';
import { ADVERTISING_ANALYTICS_EVENT } from './constants';

interface AdvertisingAnalysisBarPointChartProps {
  data: AdvertisingAnalyticsData;
  metric: AdvertisingAnalyticsDropdownOption;
}

/**
 * Displays the column and point data for advertising analytics visualization
 */
const AdvertisingAnalysisBarPointChart = ({ data, metric }: AdvertisingAnalysisBarPointChartProps) => {
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const formatMetric = useMetricFormatter();
  const barMetricName = useMemo(() => {
    switch (metric) {
      case AdvertisingAnalyticsDropdownOption.Awareness:
        return AdvertisingAnalyticsMetricName.Impressions;
      case AdvertisingAnalyticsDropdownOption.Engagement:
        return AdvertisingAnalyticsMetricName.Clicks;
      case AdvertisingAnalyticsDropdownOption.Spend:
        return AdvertisingAnalyticsMetricName.AdSpend;
      default:
        return '';
    }
  }, [metric]);

  const buttons = useMemo(() => {
    return [
      [
        {
          displayName: 'Awareness',
          eventId: AdvertisingAnalyticsDropdownOption.Awareness,
          eventName: ADVERTISING_ANALYTICS_EVENT,
          isSelected: metric === AdvertisingAnalyticsDropdownOption.Awareness
        },
        {
          displayName: 'Engagement',
          eventId: AdvertisingAnalyticsDropdownOption.Engagement,
          eventName: ADVERTISING_ANALYTICS_EVENT,
          isSelected: metric === AdvertisingAnalyticsDropdownOption.Engagement
        },
        {
          displayName: 'Spend',
          eventId: AdvertisingAnalyticsDropdownOption.Spend,
          eventName: ADVERTISING_ANALYTICS_EVENT,
          isSelected: metric === AdvertisingAnalyticsDropdownOption.Spend
        }
      ],
      [
        {
          isSelected: true,
          displayName: 'Ad Type'
        }
      ]
    ];
  }, [metric]);

  const chartProps: Highcharts.Options = useMemo(
    () => ({
      chart: {
        spacingLeft: 8
      },
      title: {
        y: -95 // Move the title up to make space for the insight text
      },
      exporting: {
        enabled: true,
        buttons
      },
      legend: {
        labelFormatter() {
          let label: string;
          if (metric === AdvertisingAnalyticsDropdownOption.Spend) {
            label = `Ad Spend (${formatMetric(_sum(data[metric].columnMetrics), METRICTYPE.MONEY, {
              showFullValue: false,
              decimalPlaces: 2
            })})`;
          } else {
            const columnMetricTotal = formatMetric(_sum(data[metric].columnMetrics), METRICTYPE.VOLUME, {
              showFullValue: false,
              decimalPlaces: 2
            });
            const formattedCtr = formatMetric(data[metric].totalClickThruRate, METRICTYPE.PERCENT, {
              decimalPlaces: 2
            });
            label = `${this.name} (${
              this.name === AdvertisingAnalyticsMetricName.CTR ? formattedCtr : columnMetricTotal
            })`;
          }

          const legendDivs: string[] = [];
          createNonComparisonLegendDivs({
            chartDisplayTimePeriod: mainTimePeriod,
            mainLegendMetricValue: {
              value: label
            },
            isSameMetricComparison: true,
            metricsDataByTimeSeries: null,
            legendDivs
          });
          return legendDivs[0];
        }
      },
      xAxis: {
        categories: data[metric].adTypes
      },
      yAxis: [
        {
          labels: {
            formatter() {
              return `${formatMetric(
                this.value as number,
                metric === AdvertisingAnalyticsDropdownOption.Spend ? METRICTYPE.MONEY : METRICTYPE.VOLUME,
                {
                  decimalPlaces: 2,
                  showFullValue: false
                }
              )}`;
            }
          }
        },
        {
          labels: {
            formatter() {
              return `${formatMetric(this.value as number, METRICTYPE.PERCENT, { decimalPlaces: 2 })}`;
            }
          },
          opposite: true
        }
      ],
      tooltip: {
        shared: true,
        enabled: true,
        positioner(boxWidth, boxHeight, point) {
          return {
            x: point.plotX + this.chart.plotLeft - boxWidth / 2, // center the tooltip horizontally
            y: point.plotY + this.chart.plotTop - boxHeight - 10 // position the tooltip above the column
          };
        },
        formatter() {
          if (metric === AdvertisingAnalyticsDropdownOption.Spend) {
            return getSimpleTooltipDiv([
              {
                label: `${barMetricName}: ${formatMetric(this.points[0].y, METRICTYPE.MONEY, {
                  showFullValue: false,
                  decimalPlaces: 2
                })}`
              }
            ]);
          }

          const barValue = this.points[0].y;
          const pointValue = this.points[1].y;
          return getSimpleTooltipDiv([
            {
              label: `${barMetricName}: ${formatMetric(barValue, METRICTYPE.VOLUME, {
                decimalPlaces: 2,
                showFullValue: false
              })}`
            },
            {
              label: `CTR: ${formatMetric(pointValue, METRICTYPE.PERCENT, { decimalPlaces: 2 })}`,
              color: colors.darkBlue
            }
          ]);
        }
      },
      plotOptions: {
        line: {
          marker: {
            lineColor: 'transparent'
          }
        }
      }
    }),
    [barMetricName, data, formatMetric, mainTimePeriod, metric, buttons]
  );

  const columnSeries: Highcharts.SeriesColumnOptions = useMemo(
    () => ({
      type: 'column',
      name: barMetricName,
      data: _cloneDeep(data[metric].columnMetrics)
    }),
    [data, metric, barMetricName]
  );

  const scatterSeries: Highcharts.SeriesLineOptions = useMemo(
    () => ({
      type: 'line',
      data: metric !== AdvertisingAnalyticsDropdownOption.Spend ? _cloneDeep(data[metric].pointMetrics) : [],
      color: colors.darkBlue,
      name: 'CTR'
    }),
    [data, metric]
  );

  return (
    <ColumnWithPointChart
      columnData={columnSeries}
      pointData={metric !== AdvertisingAnalyticsDropdownOption.Spend ? scatterSeries : undefined}
      chartProps={chartProps}
      convertSeriesToDelimitedData={() =>
        advertisingAnalyticsDataToCsv({ data, dateRange: mainTimePeriod.displayName, metric })
      }
    />
  );
};

export default AdvertisingAnalysisBarPointChart;
