import axios from 'axios';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import { AppButton } from '@stackline/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import { AppName } from 'sl-api-connector';
import Card from 'src/components/Card';
import DeleteConfirmationDialog from 'src/components/common/Dialog/DeleteConfirmationDialog';
import { segmentOperations } from 'src/store/modules/segments';
import { isPhone } from 'src/utils/browser';
import { getProductImageUrl } from 'src/utils/image';
import { trackRetailerProductPageVisit } from 'src/utils/mixpanel';
import './Cards.scss';
import { withBus } from 'react-bus';
import { determineTheDefault } from '../../Content/OmniContentAccuracy';
import { decode } from 'html-entities';

class ProductSidebarCard extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    entity: PropTypes.object.isRequired,
    eventBus: PropTypes.object.isRequired,
    omniRetailers: PropTypes.object.isRequired
  };

  state = {
    buttonText: 'Remove Product',
    isRemoveProductDialogOpen: false,
    logoAvailable: true,
    omniRetailerId: '0'
  };

  handleImageError = () => this.setState({ logoAvailable: true });

  showLogo(product, match, logoAvailable) {
    const { app } = this.props;
    const { shouldUseStacklineSkU } = app;
    if (logoAvailable) {
      return (
        <div className="brand__image-container sl-image-container">
          <div className="brand__image-content sl-image-center">
            <img
              className="sl-image"
              alt="logo"
              src={getProductImageUrl(shouldUseStacklineSkU ? product.stacklineSku : match.params.id)}
              onError={this.handleImageError}
            />
          </div>
        </div>
      );
    }
    const shortName = product.title ? product.title.slice(0, 2) : '';
    return (
      <div className="segment__short-name-container" style={{ backgroundColor: segmentOperations.backcolors[0] }}>
        <div className="segment__short-name">{shortName}</div>
      </div>
    );
  }

  componentDidMount() {
    this.props.eventBus.on('omniDropDownSelected', this.handleRetailerDropDown);
  }

  componentWillUnmount() {
    this.props.eventBus.off('OmniDropDownSelected', this.handleRetailerDropDown);
  }

  handleRetailerDropDown = ({ retailerSelected }) => {
    this.setState({ omniRetailerId: retailerSelected });
  };

  buildCardFront() {
    const { app, entityService, match, user, retailer, entity } = this.props;
    const { searchParams, additionalParams } = app.queryParams;
    const product = app.name === AppName.Omni ? entity : entityService.mainEntity;
    const { translatedTitles } = product;
    const { logoAvailable } = this.state;
    const subtab = app.name === 'atlas' ? 'retailSales' : 'keymetrics';

    let { title } = product;
    let translatedTitle;

    const translationEnabled = _get(user, ['config', 'translationEnabled', +retailer.id], []);
    translationEnabled.forEach((locale) => {
      if (!translatedTitle && translatedTitles && translatedTitles[locale]) {
        translatedTitle = translatedTitles[locale];
      }
    });

    if (translatedTitle) {
      title = translatedTitle;
    }

    return (
      <div style={{ padding: '0 15px' }}>
        <div className="sl-image-container">
          <div className="sl-image-center">
            <Link to={`${searchParams}&tab=sales&subtab=${subtab}`}>
              {this.showLogo(product, match, logoAvailable)}
            </Link>
          </div>
        </div>
        <div className="nav-name-container">
          <Link
            className="nav-name-container__brand-name"
            to={`/brand/${product.brandId}${searchParams}${additionalParams}`}
            title={product.brandName}
          >
            <Truncate lines={1} ellipsis="...">
              {product.brandName}
            </Truncate>
          </Link>
          <div className="nav-name-container__product-name" title={product.title}>
            <Truncate lines={2} ellipsis="...">
              {decode(title)}
            </Truncate>
          </div>
        </div>
      </div>
    );
  }

  // Refactor the axios call and put it in Redux
  handleRemoveProduct = () => {
    const { app, entityService, history } = this.props;
    const { searchParams, additionalParams } = app.queryParams;
    const product = entityService.mainEntity;
    this.setState({ buttonText: 'Removing Product...' });

    axios.post(`/api/${app.name}/DisableProduct?stacklineSku=${product.stacklineSku}`).then(
      () => {
        this.setState({ buttonText: 'Removed Product!' });
        setTimeout(() => history.push(`/${searchParams}${additionalParams}`), 1000);
      },
      () => {
        this.setState({ buttonText: 'Remove Product' });
      }
    );
  };

  toggleRemoveProductDialog = () => this.setState({ isRemoveProductDialogOpen: !this.state.isRemoveProductDialogOpen });

  showRemoveProductDialog() {
    const { match, entity } = this.props;
    const product = entity;
    const { buttonText, logoAvailable, isRemoveProductDialogOpen } = this.state;

    return (
      <DeleteConfirmationDialog
        title="Remove Product"
        open={isRemoveProductDialogOpen}
        onClose={this.toggleRemoveProductDialog}
        onDelete={this.handleRemoveProduct}
        deleteButtonText={buttonText}
      >
        <div>
          <p style={{ marginBottom: 40 }}>Are you sure you want to delete this product from your account?</p>
          {this.showLogo(product, match, logoAvailable)}
          <p className="product-card__label">{product.title}</p>
        </div>
      </DeleteConfirmationDialog>
    );
  }

  showRemoveProductButton() {
    const { app, user } = this.props;
    if (user.session.userMetaData.companyName !== 'Stackline' || app.name === 'atlas') {
      return null;
    }

    return (
      <div>
        <AppButton backgroundColor="#fff" sx={{ marginLeft: '-8px' }} onClick={this.toggleRemoveProductDialog}>
          Remove Product
        </AppButton>
        {this.showRemoveProductDialog()}
      </div>
    );
  }

  buildCardBack() {
    const { app, retailer, entity, omniRetailers, filters, history } = this.props;
    const { searchParams, filter, subtab } = app.queryParams;
    const { location } = history;
    const { pathname } = location;
    const product = entity;

    let omniShouldShowRetailerSKU = false;
    let retailerId = '0';

    const isProductContentAccuracy = entity.type === 'product' && subtab === 'contentAccuracy';

    // Omni does not have a global retailer set, so Omni will render retailerSKU when there is only one retailer selected via filter or dropdown in content Accuracy (product level)
    if ((filter || isProductContentAccuracy) && app.name === AppName.Omni) {
      try {
        const parsedFilterParams = JSON.parse(filter);
        const retailerFilterParams = parsedFilterParams.r;

        if (retailerFilterParams.length === 1) {
          retailerId = String(retailerFilterParams[0].i);
          omniShouldShowRetailerSKU = true;
        }
      } catch {
        omniShouldShowRetailerSKU = false;
      }

      // Use the default retailer if no omniRetailerId is provided from eventBus
      if (isProductContentAccuracy) {
        retailerId =
          this.state.omniRetailerId === '0'
            ? String(determineTheDefault(filters, omniRetailers.data, pathname))
            : this.state.omniRetailerId;
        omniShouldShowRetailerSKU = true;
      }
    }

    const retailerUrl = `https://${app.targetUrl}/api/utility/OpenRetailerProductPage?appName=${app.name}&retailerId=${
      omniShouldShowRetailerSKU ? retailerId : retailer.id
    }&stacklineSku=${product.stacklineSku}`;

    const retailerInfo = {
      appName: app.name,
      retailerId: omniShouldShowRetailerSKU ? retailerId : retailer.id,
      retailerSku: product.retailerSku,
      stacklineSku: product.stacklineSku,
      categoryId: product.categoryId,
      categoryName: product.categoryName,
      subCategoryId: product.subCategoryId,
      subCategoryName: product.subCategoryName,
      brandId: product.brandId,
      brandName: product.brandName,
      title: product.title
    };

    return (
      <div className="product-card__info-text" style={{ textAlign: 'left' }}>
        <div className="product-card__small-metric-change">
          <div className="product-card__block-text">
            <p className="product-card__label">Product Id: {product.id}</p>
            {app.name === AppName.Omni && omniShouldShowRetailerSKU && (
              <p className="product-card__label">
                Retailer Sku:{' '}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={retailerUrl}
                  onClick={(e) => {
                    trackRetailerProductPageVisit(retailerUrl, retailerInfo);
                    if (this.stopPropagation) {
                      this.stopPropagation(e);
                    }
                  }}
                >
                  {product.retailerSku}
                </a>
              </p>
            )}
            {app.name !== AppName.Omni && (
              <p className="product-card__label">
                Retailer Sku:{' '}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={retailerUrl}
                  onClick={(e) => {
                    trackRetailerProductPageVisit(retailerUrl, retailerInfo);
                    if (this.stopPropagation) {
                      this.stopPropagation(e);
                    }
                  }}
                >
                  {product.retailerSku}
                </a>
              </p>
            )}
            <p className="product-card__label">
              Brand: <Link to={`/brand/${product.brandId}${searchParams}`}>{product.brandName}</Link>
            </p>
            {product.parentBrandName && <p className="product-card__label">Manufacturer: {product.parentBrandName}</p>}
            <p className="product-card__label">
              Category: <Link to={`/category/${product.categoryId}${searchParams}`}>{product.categoryName}</Link>
            </p>
            <p className="product-card__label">
              Subcategory:{' '}
              <Link to={`/subcategory/${product.subCategoryId}${searchParams}`}>{product.subCategoryName}</Link>
            </p>
            <p className="product-card__label">Title: {decode(product.title)}</p>
            {this.showRemoveProductButton()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.app.name !== AppName.Omni && _isEmpty(this.props.entityService.mainEntity)) {
      return null;
    }
    return (
      <div className="nav-header-product-container">
        <Card front={this.buildCardFront()} back={this.buildCardBack()} isFlippable={!isPhone()} />
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, ['app', 'entityService', 'retailer', 'filters', 'user', 'omniRetailers']);

export default withRouter(withBus('eventBus')(connect(mapStateToProps, null)(ProductSidebarCard)));
