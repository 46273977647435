/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import { getPluralVerb } from 'src/utils/stringFormatting';
import ReduxStore from 'src/types/store/reduxStore';
import Widget from 'src/components/EntityPage/Widget';
import { buildMetricValue } from 'src/utils/metrics';
import { AggregationField } from 'src/types/application/types';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { store } from 'src/main';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { Entity } from 'sl-api-connector/types';

import { NoDataForBudgetConstraints } from 'src/components/Grids/Data/NoResultTemplate';
import { buildMainEntityConditions } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { parseEntityMetrics } from 'src/store/modules/entitySearchService/selectors';
import { buildAggregations } from 'src/components/AdManager/Search';

const yesterday = moment().subtract(1, 'days').format('YYYYMMDD');

const mainTimePeriodRangeFilter = {
  fieldName: 'dayId',
  minValue: yesterday,
  maxValue: yesterday
};

const groupByFieldNameByEntityType = {
  client: 'retailerId',
  adEntity: 'entityId',
  adPortfolio: 'portfolioId',
  adCampaign: 'campaignId'
};

const style = {
  marginBottom: 12
};

const defaultInfo = {
  totalCount: 0,
  totalSpend: '-',
  campaignIds: []
};

const budgetConstraintsParser = (action: any, retailer: ReduxStore['retailer']) => {
  const { groupByFieldName: groupByField } = action.apiRequest[0].aggregations[0];
  const data = parseEntityMetrics({
    ...action,
    apiRequest: [action.apiRequest[0]],
    apiResponse: { data: [action.apiResponse.data[0]] }
  });
  const spendMetric = data[`spend_by_${groupByField}`];
  const count = _get(data[`campaignId_by_${groupByField}`], ['data', 0, 'value'], 0);
  const spend = _get(spendMetric, ['data', 0, 'value'], 0);
  const spendDisplay = buildMetricValue(
    spend,
    spendMetric.metricType,
    spendMetric.currencySymbol,
    true,
    spendMetric.dataType,
    spendMetric.locale
  );

  const totalData = parseEntityMetrics({
    ...action,
    apiRequest: [action.apiRequest[1]],
    apiResponse: { data: [action.apiResponse.data[1]] }
  });
  const totalSpendMetric = _get(totalData, [`spend_by_${groupByField}`]);
  const totalSpend = _get(totalSpendMetric, ['data', 0, 'value'], 0);
  const totalSpendDisplay = buildMetricValue(
    totalSpend,
    totalSpendMetric.metricType,
    totalSpendMetric.currencySymbol,
    true,
    totalSpendMetric.dataType,
    totalSpendMetric.locale
  );

  const campaignArray = parseEntityMetrics({
    ...action,
    apiRequest: [action.apiRequest[2]],
    apiResponse: { data: [action.apiResponse.data[2]] }
  });
  const percent = totalSpend === 0 ? 0 : spend / totalSpend;
  const percentDisplay = buildMetricValue(percent, 'PERCENT', retailer.currencySymbol);

  const campaignIds = _get(campaignArray, [`spend_by_campaignId`, 'data'], []).map((item: any) => item.entity.id);

  return {
    totalCount: count,
    percentDisplay: `${percentDisplay.value}${percentDisplay.suffix}`,
    currentSpend: `${spendDisplay.prefix}${spendDisplay.value}${spendDisplay.suffix}`,
    totalSpend: `${totalSpendDisplay.prefix}${totalSpendDisplay.value}${totalSpendDisplay.suffix}`,
    campaignIds
  };
};

export const fetchBudgetConstraintsCountForEntity = (entity: Entity) => {
  const { app, retailer } = store.getState();
  const dataKey = `budget_constraints_count-${entity.type}-${entity.id}`;
  const indexName = 'adCampaignDailyMetrics';
  const groupByField = INDEX_FIELDS.getField(app.name, indexName, groupByFieldNameByEntityType[entity.type]);

  const metricFields = [
    {
      ...INDEX_FIELDS.getField(app.name, indexName, 'campaignId')
    }
  ];
  const [{ aggregations: aggregationFields }] = buildAggregations(metricFields);

  const conditions = buildMainEntityConditions({ termFilters: [], rangeFilters: [] }, entity, app, retailer);

  return new Promise((resolve) => {
    store.dispatch(
      fetchEntityMetrics(
        dataKey,
        {
          entity,
          retailer,
          app,
          indexName,
          customResponseParser: (res: any) => {
            const count = _get(
              res,
              ['apiResponse', 'data', 0, 'aggregations', `by_${groupByField.name}`, 0, 'value'],
              0
            ) as number;

            resolve(count);
            return {
              count
            };
          }
        },
        [
          {
            doAggregation: true,
            aggregations: [
              {
                aggregationFields,
                conditions: {
                  termFilters: [
                    { fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] },
                    {
                      fieldName: 'isProjected',
                      condition: 'must',
                      values: [0]
                    }
                  ],
                  rangeFilters: [mainTimePeriodRangeFilter]
                },
                groupByFieldName: groupByField.name
              }
            ],
            conditions: {
              termFilters: [
                ...conditions.termFilters,
                {
                  fieldName: 'isConsistentlyOutOfBudget',
                  condition: 'should',
                  values: [1]
                }
              ],
              rangeFilters: [mainTimePeriodRangeFilter]
            },
            pageSize: 1000,
            processDocuments: false,
            useAggregationSort: false
          }
        ]
      )
    );
  }) as Promise<number>;
};

const mapStateToProps = ({ entityService: { mainEntity }, retailer, app }: ReduxStore) => ({
  retailer,
  mainEntity,
  app
});

const AdBudgetConstraints: React.FC<
  {
    widget: any;
  } & ReturnType<typeof mapStateToProps>
> = ({ app, mainEntity, retailer, widget, ...props }) => {
  const [constrainstInfo, setConstrainstInfo] = useState(defaultInfo);
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = async () => {
    setIsLoading(true);
    const indexName = 'adCampaignDailyMetrics';
    const { groupByFieldName } = widget.data;
    const entityConditions = buildMainEntityConditions(
      { termFilters: [], rangeFilters: [] },
      mainEntity,
      app,
      retailer
    );

    const fields: AggregationField[] = ['campaignId', 'spend'].map((fieldName) =>
      INDEX_FIELDS.getField(app.name, indexName, fieldName)
    );

    const [{ aggregations: aggregationFields }] = buildAggregations(fields);

    const searchRequestOverrides = [
      {
        doAggregation: true,
        aggregations: [
          {
            aggregationFields,
            conditions: {
              termFilters: [
                { fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] },
                {
                  fieldName: 'isProjected',
                  condition: 'must',
                  values: [0]
                }
              ],
              rangeFilters: [mainTimePeriodRangeFilter]
            },
            sortDirection: 'desc',
            sortByAggregationField: aggregationFields[0],
            groupByFieldName
          }
        ],
        conditions: {
          termFilters: [
            ...entityConditions.termFilters,
            {
              fieldName: 'isConsistentlyOutOfBudget',
              condition: 'should',
              values: [1]
            }
          ],
          rangeFilters: [mainTimePeriodRangeFilter]
        },
        pageNumber: 1,
        pageSize: 50,
        processDocuments: false
      },
      {
        doAggregation: true,
        aggregations: [
          {
            aggregationFields,
            conditions: {
              termFilters: [
                { fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] },
                {
                  fieldName: 'isProjected',
                  condition: 'must',
                  values: [0]
                }
              ],
              rangeFilters: [mainTimePeriodRangeFilter]
            },
            sortDirection: 'desc',
            sortByAggregationField: aggregationFields[0],
            groupByFieldName
          }
        ],
        conditions: {
          termFilters: entityConditions.termFilters,
          rangeFilters: [mainTimePeriodRangeFilter]
        },
        pageNumber: 1,
        pageSize: 50,
        processDocuments: false
      },
      {
        doAggregation: true,
        aggregations: [
          {
            aggregationFields,
            conditions: {
              termFilters: [
                { fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] },
                {
                  fieldName: 'isProjected',
                  condition: 'must',
                  values: [0]
                }
              ],
              rangeFilters: [mainTimePeriodRangeFilter]
            },
            sortDirection: 'desc',
            sortByAggregationField: aggregationFields[0],
            groupByFieldName: 'campaignId'
          }
        ],
        conditions: {
          termFilters: [
            ...entityConditions.termFilters,
            {
              fieldName: 'isConsistentlyOutOfBudget',
              condition: 'should',
              values: [1]
            }
          ],
          rangeFilters: [mainTimePeriodRangeFilter]
        },
        pageNumber: 1,
        pageSize: 10000,
        processDocuments: false
      }
    ];

    const result = await store.dispatch(
      fetchEntityMetrics(
        widget.data.statePropertyName,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName,
          customResponseParser: (action: any) => {
            return budgetConstraintsParser(action, retailer);
          }
        },
        searchRequestOverrides,
        null,
        true
      )
    );

    setConstrainstInfo({ ...result });
    setIsLoading(false);
  };

  useEffect(() => {
    if (mainEntity && retailer) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainEntity, retailer]);
  const subHeader = `${constrainstInfo.totalCount} of your ${pluralize(
    'campaign',
    constrainstInfo.totalCount
  )} ${getPluralVerb(
    constrainstInfo.totalCount,
    'has',
    'have'
  )} been consistently running out of daily budget. These ${pluralize(
    'campaign',
    constrainstInfo.totalCount,
    true
  )} ${getPluralVerb(constrainstInfo.totalCount, 'spends', 'spent')} ${constrainstInfo.currentSpend} (${
    constrainstInfo.percentDisplay
  }) of your ${constrainstInfo.totalSpend} total spend yesterday.`;
  const { gridWidget } = widget.data;
  const { campaignIds } = constrainstInfo;
  gridWidget.data.widgets[1].data.widgets[1].view.tabDefinitions[0].widget.data.customTermFilters = [
    {
      fieldName: 'campaignId',
      condition: 'should',
      values: campaignIds
    }
  ];
  if (isLoading) {
    return null;
  }
  if (constrainstInfo.totalCount > 0) {
    return (
      <>
        <div className="recommendations_container">
          <div className="header">Budget Constraints</div>
          <div style={style}>{subHeader}</div>
          {(parseInt(constrainstInfo.totalCount, 10) > 0 || constrainstInfo.totalCount === '-') && (
            <div style={{ marginBottom: -25 }}></div>
          )}
        </div>
        <Widget {...props} noInnerContainer widget={gridWidget} />
      </>
    );
  }

  return <NoDataForBudgetConstraints />;
};

export default connect(mapStateToProps)(AdBudgetConstraints);
