import React, { createContext, useCallback, useRef, useState } from 'react';
import BulkTemplateUploadModal from './BulkTemplateUploadModal';
import BulkTemplateProcessingModal, {
  TemplateProcessingState,
  TemplateProcessingStatus
} from './BulkTemplateProcessingModal';
import BulkSubmissionReviewModal, { ViewBulkAdjustmentState } from './BulkSubmissionReviewModal';
import BulkTemplateSubmittingModal, {
  TemplateSubmittingState,
  TemplateSubmittingStatus
} from './BulkTemplateSubmittingModal';
import useProcessBulkAdjustment from './modalHooks/useProcessBulkAdjustment';
import { PollBulkAdjustmentResponse, UploadBulkAdjustmentPayload } from './serverProxy/types';
import usePublishAndPollBulkAdjustment from './modalHooks/usePublishAndPollBulkAdjustment';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { BulkTemplateDownloadModal } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkTemplateDownloadModal';
import { useQueryClient } from 'react-query';
import {
  ADJUSTMENT_TABLE_QUERY,
  FORECAST_SUMMARY_KEYMETRIC_QUERY
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastQueryKeys';
import BulkAdjustmentUnsavedChangesDialog from './BulkAdjustmentUnsavedChangesDialog';
import useSaveAsDraft from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/modalHooks/useSaveAsDraft';
import { GET_BULK_ADJUSTMENT_UPLOADS_QUERY_KEY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Adjustments/constants';
import { useSnackbar } from 'src/utils/Hooks';
import { GenericErrorMessage } from 'src/components/BeaconRedesignComponents/common/CommonSnackbarMessages/GenericErrorMessage';

export enum BulkAdjustmentModalType {
  DownloadTemplate = 'DownloadTemplate',
  UploadTemplate = 'UploadTemplate',
  TemplateProcessing = 'TemplateProcessing',
  SubmissionReview = 'SubmissionReview',
  TemplateSubmitting = 'TemplateSubmitting',
  UnsavedChanges = 'UnsavedChanges',
  ViewSummary = 'ViewSummary'
}

interface BulkAdjustmentContextProps {
  openBulkAdjustmentModal: (args: OpenBulkAdjustmentModalArgs) => void;
  uploadBulkAdjustment: (payload: UploadBulkAdjustmentPayload) => void;
  closeModal: () => void;
  bulkAdjustmentId: string | null;
}

export const BulkAdjustmentContext = createContext<BulkAdjustmentContextProps | null>(null);

export type OpenBulkAdjustmentModalArgs =
  | {
      type: BulkAdjustmentModalType.DownloadTemplate;
      planType?: PlanTypeOption;
    }
  | {
      type: BulkAdjustmentModalType.UploadTemplate;
      isUpdateAdjustment?: boolean;
      uploadedAdjustment?: Omit<UploadBulkAdjustmentPayload, 'bulkAdjustmentFile' | 'bulkAdjustmentId' | 'planType'>;
    }
  | {
      type: BulkAdjustmentModalType.TemplateProcessing;
      state: TemplateProcessingState;
    }
  | {
      type: BulkAdjustmentModalType.SubmissionReview;
    }
  | {
      type: BulkAdjustmentModalType.TemplateSubmitting;
      state: TemplateSubmittingState;
    }
  | {
      /**
       * Used for viewing the summary of a bulk adjustment.
       * This will open the BulkSubmissionReviewModal with the summary metrics.
       */
      type: BulkAdjustmentModalType.ViewSummary;
      state: ViewBulkAdjustmentState;
    };

export type UploadedAdjustment = Omit<UploadBulkAdjustmentPayload, 'bulkAdjustmentFile' | 'planType'> & {
  planType: PlanTypeOption | null;
};

export type AdjustmentMetaData = {
  validation: PollBulkAdjustmentResponse | null; // Used for the validation metadata such as fileUri for downloading the invalid results or getting the summary metrics when the template is valid
  compute: PollBulkAdjustmentResponse | null; // Used for downloading the net impact file
} | null;

/**
 * Provides a context for the bulk adjustment modals. It controls navigation between
 * each modal and provides functions for uploading a bulk adjustment.
 */
export const BulkAdjustmentProvider = ({ children }: { children: React.ReactNode }) => {
  // Refs because we need to check these values in the poller
  const bulkAdjustmentIdRef = useRef<string | null>(null);
  const bulkAdjustmentModalRef = useRef<BulkAdjustmentModalType | null>(null);
  const { showSnackbar } = useSnackbar();

  const [bulkAdjustmentModal, _setBulkAdjustmentModal] = useState<BulkAdjustmentModalType | null>(null);
  const [templateProcessingState, setTemplateProcessingState] = useState<TemplateProcessingState | null>(null);
  const [templateSubmittingState, setTemplateSubmittingState] = useState<TemplateSubmittingState | null>(null);
  const queryClient = useQueryClient();
  const [adjustmentMetadata, setAdjustmentMetaData] = useState<AdjustmentMetaData>(null);
  const [initialDownloadPlanType, setInitialDownloadPlanType] = useState<PlanTypeOption | null>(null);

  // Keeps track of the modal that was open after trying to exit, so when we show the "Are you sure you want to exit"
  // modal we can go back
  const [lastOpenedModal, setLastOpenedModal] = useState<BulkAdjustmentModalType | null>(null);

  const [uploadedAdjustment, setUploadedAdjustment] = useState<UploadedAdjustment>({
    bulkAdjustmentDescription: '',
    bulkAdjustmentTitle: '',
    planType: null
  });

  const setBulkAdjustmentModal = useCallback((type: BulkAdjustmentModalType | null) => {
    bulkAdjustmentModalRef.current = type;
    _setBulkAdjustmentModal(type);
  }, []);

  const closeModal = useCallback(() => {
    setBulkAdjustmentModal(null);

    // Reset state
    setTemplateProcessingState(null);
    setUploadedAdjustment({ bulkAdjustmentDescription: '', bulkAdjustmentTitle: '', planType: null });
    setAdjustmentMetaData(null);
  }, [setBulkAdjustmentModal, setTemplateProcessingState]);

  /**
   * Shows the unsaved changes dialog
   */
  const showUnsavedChanges = useCallback(() => {
    setLastOpenedModal(bulkAdjustmentModal);
    setBulkAdjustmentModal(BulkAdjustmentModalType.UnsavedChanges);
  }, [bulkAdjustmentModal, setBulkAdjustmentModal]);

  const { publishAndPollBulkAdjustment, pollBulkAdjustment } = usePublishAndPollBulkAdjustment({
    closeModal,
    setState: setTemplateSubmittingState,
    bulkAdjustmentIdRef,
    bulkAdjustmentModalRef
  });

  const handleSaveAsDraft = useSaveAsDraft({
    closeModal,
    bulkAdjustmentIdRef
  });

  // Workaround so that processing a bulk adjustment can open a snackbar if the modal isn't open.
  // The hook needs a reference to openBulkAdjustmentModal, but that function calls processUploadBulkAdjustment
  // so we use a ref to get around the chicken-egg problem
  const processUploadBulkAdjustmentRef = useRef<((payload: UploadBulkAdjustmentPayload) => Promise<void>) | null>(null);
  const validateAndComputeNetImpactRef = useRef<
    ((bulkAdjustmentId: string, planType: PlanTypeOption, computeNetImpact: boolean) => Promise<void>) | null
  >(null);
  const getMetricsForViewingAdjustmentRef = useRef<
    ((bulkAdjustmentId: string, planType: PlanTypeOption) => Promise<AdjustmentMetaData>) | null
  >(null);

  const openBulkAdjustmentModal = useCallback(
    async (args: OpenBulkAdjustmentModalArgs) => {
      setBulkAdjustmentModal(args.type);

      if (args.type === BulkAdjustmentModalType.ViewSummary) {
        bulkAdjustmentIdRef.current = args.state.bulkAdjustmentId;
        setUploadedAdjustment(args.state.uploadedAdjustment);
        const response = await getMetricsForViewingAdjustmentRef.current(
          args.state.bulkAdjustmentId,
          args.state.uploadedAdjustment.planType
        );

        // If we have valid data, set the adjustment metadata
        if (response) {
          setAdjustmentMetaData(response);
        } else {
          // Otherwise there was an error trying to display the summary metrics
          closeModal();
          showSnackbar({
            message: <GenericErrorMessage />,
            type: 'error'
          });
        }
      }

      if (args.type === BulkAdjustmentModalType.DownloadTemplate && args.planType) {
        setInitialDownloadPlanType(args.planType);
      }

      if (args.type === BulkAdjustmentModalType.UploadTemplate) {
        // when validation fails, we would like to update the adjustment, otherwise current ref will be null
        if (!args.isUpdateAdjustment) {
          bulkAdjustmentIdRef.current = null;
          setAdjustmentMetaData(null);
          setUploadedAdjustment({ bulkAdjustmentDescription: '', bulkAdjustmentTitle: '', planType: null });
        }
      }

      if (args.type === BulkAdjustmentModalType.TemplateProcessing) {
        setTemplateProcessingState(args.state);
        if (
          processUploadBulkAdjustmentRef.current &&
          args.state.status === TemplateProcessingStatus.UploadAdjustments
        ) {
          processUploadBulkAdjustmentRef.current(args.state.payload);
          setUploadedAdjustment(args.state.payload);
        } else if (
          validateAndComputeNetImpactRef.current &&
          args.state.status === TemplateProcessingStatus.ResumePolling
        ) {
          bulkAdjustmentIdRef.current = args.state.bulkAdjustmentId;
          setUploadedAdjustment(args.state.uploadedAdjustment);
          await validateAndComputeNetImpactRef.current(
            args.state.bulkAdjustmentId,
            args.state.uploadedAdjustment.planType,
            args.state.computeNetImpact
          );
          queryClient.invalidateQueries([GET_BULK_ADJUSTMENT_UPLOADS_QUERY_KEY]);
        }
      } else if (args.type === BulkAdjustmentModalType.TemplateSubmitting) {
        setTemplateSubmittingState(args.state);
        if (args.state.status === TemplateSubmittingStatus.Submitting) {
          publishAndPollBulkAdjustment({
            bulkAdjustmentId: bulkAdjustmentIdRef.current!,
            bulkAdjustmentChangeType: 'Absolute',
            bulkAdjustmentDescription: uploadedAdjustment.bulkAdjustmentDescription,
            bulkAdjustmentTitle: uploadedAdjustment.bulkAdjustmentTitle,
            planType: uploadedAdjustment.planType,
            saveAsDraft: args.state.saveAsDraft
          }).then(() => {
            // Invalidate the queries so that the forecast summary and adjustment table will update
            queryClient.invalidateQueries([ADJUSTMENT_TABLE_QUERY]);
            queryClient.invalidateQueries([FORECAST_SUMMARY_KEYMETRIC_QUERY]);
          });
        } else if (args.state.status === TemplateSubmittingStatus.SubmissionResume) {
          bulkAdjustmentIdRef.current = args.state.bulkAdjustmentId;
          pollBulkAdjustment(args.state.bulkAdjustmentId, args.state.planType).then(() => {
            // Invalidate the queries so that the forecast summary and adjustment table will update
            queryClient.invalidateQueries([ADJUSTMENT_TABLE_QUERY]);
            queryClient.invalidateQueries([FORECAST_SUMMARY_KEYMETRIC_QUERY]);
          });
        }
      }
    },
    [
      pollBulkAdjustment,
      publishAndPollBulkAdjustment,
      queryClient,
      setBulkAdjustmentModal,
      uploadedAdjustment.bulkAdjustmentDescription,
      uploadedAdjustment.bulkAdjustmentTitle,
      uploadedAdjustment.planType,
      closeModal
    ]
  );

  const { processUploadBulkAdjustment, validateAndComputeNetImpact, getMetricsForViewingAdjustment } =
    useProcessBulkAdjustment({
      bulkAdjustmentIdRef,
      bulkAdjustmentModalRef,
      setState: setTemplateProcessingState,
      openBulkAdjustmentModal,
      closeModal,
      setAdjustmentMetaData
    });
  processUploadBulkAdjustmentRef.current = processUploadBulkAdjustment;
  validateAndComputeNetImpactRef.current = validateAndComputeNetImpact;
  getMetricsForViewingAdjustmentRef.current = getMetricsForViewingAdjustment;

  const uploadBulkAdjustment = async (payload: UploadBulkAdjustmentPayload) => {
    openBulkAdjustmentModal({
      type: BulkAdjustmentModalType.TemplateProcessing,
      state: {
        status: TemplateProcessingStatus.UploadAdjustments,
        payload,
        percent: 0
      }
    });
  };

  return (
    <BulkAdjustmentContext.Provider
      value={{
        openBulkAdjustmentModal,
        uploadBulkAdjustment,
        closeModal,
        bulkAdjustmentId: bulkAdjustmentIdRef.current
      }}
    >
      {children}

      {bulkAdjustmentModal === BulkAdjustmentModalType.DownloadTemplate && (
        <BulkTemplateDownloadModal open initialPlanType={initialDownloadPlanType} onClose={() => closeModal()} />
      )}
      {[BulkAdjustmentModalType.UploadTemplate, BulkAdjustmentModalType.UnsavedChanges].includes(
        bulkAdjustmentModal
      ) && (
        <BulkTemplateUploadModal
          bulkAdjustmentIdRef={bulkAdjustmentIdRef}
          uploadedAdjustment={uploadedAdjustment}
          open={bulkAdjustmentModal === BulkAdjustmentModalType.UploadTemplate}
          onClose={() => {
            showUnsavedChanges();
          }}
        />
      )}
      {bulkAdjustmentModal === BulkAdjustmentModalType.TemplateProcessing && templateProcessingState && (
        <BulkTemplateProcessingModal
          state={templateProcessingState}
          open
          onClose={() => {
            closeModal();
          }}
          uploadedAdjustment={uploadedAdjustment}
        />
      )}
      {bulkAdjustmentModal === BulkAdjustmentModalType.SubmissionReview && uploadedAdjustment.planType && (
        <BulkSubmissionReviewModal
          open
          planType={uploadedAdjustment.planType}
          onClose={() => {
            showUnsavedChanges();
          }}
          handleSaveAsDraft={handleSaveAsDraft}
          uploadedAdjustment={uploadedAdjustment}
          adjustmentMetadata={adjustmentMetadata}
        />
      )}
      {bulkAdjustmentModal === BulkAdjustmentModalType.ViewSummary && uploadedAdjustment.planType && (
        <BulkSubmissionReviewModal
          loading={!adjustmentMetadata}
          options={{ disableButtons: true }}
          open
          planType={uploadedAdjustment.planType}
          onClose={() => {
            closeModal();
          }}
          handleSaveAsDraft={handleSaveAsDraft}
          uploadedAdjustment={uploadedAdjustment}
          adjustmentMetadata={adjustmentMetadata}
        />
      )}
      {bulkAdjustmentModal === BulkAdjustmentModalType.TemplateSubmitting && templateSubmittingState && (
        <BulkTemplateSubmittingModal
          open
          onClose={() => {
            closeModal();
          }}
          state={templateSubmittingState}
        />
      )}
      <BulkAdjustmentUnsavedChangesDialog
        open={bulkAdjustmentModal === BulkAdjustmentModalType.UnsavedChanges}
        onClose={() => closeModal()}
        onCancel={() => setBulkAdjustmentModal(lastOpenedModal)}
      />
    </BulkAdjustmentContext.Provider>
  );
};
