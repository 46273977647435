/* eslint react/prop-types: 0 */
import React, { useState, useCallback, useEffect, forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import _debounce from 'lodash/debounce';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
// eslint-disable-next-line
import Slider from '@mui/material/Slider';
import _cloneDeep from 'lodash/cloneDeep';
import { AD_TARGETING_TYPE } from 'sl-ad-campaign-manager-data-model';
import { ValueOf } from 'sl-api-connector/types';
import { Option } from 'funfix-core';

import PillFilters from 'src/components/Search/AdvancedSearch/AdvancedSearchSideBar/PillFilters';
import { store } from 'src/main';
import colors from 'src/utils/colors';
import { SectionWrapper, OperationButtons } from '../Widgets/AdCampaignBuilderCommonWidgets';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import NumFlip from '../Widgets/NumFlip';
import TargetEntitiesGrid from '../Widgets/TargetEntitiesGrid';
import { withPush } from 'src/utils/hoc';
import { getIsEditing } from '../util';
import ReduxStore from 'src/types/store/reduxStore';
import { WidgetProps, Widget } from 'src/types/application/widgetTypes';
import { PushFn } from 'src/types/application/types';
import { panic } from 'src/utils/mixpanel';
import { propEq } from 'src/utils/fp';
import { formatMoney } from 'src/utils/stringFormatting';
import AutoTargetingProductAssign from 'src/components/AdCampaignBuilder/Widgets/AutoTargetingBidAssign';
import Switch from '@mui/material/Switch';
import NumberFormat from 'react-number-format';
import { isInstacart } from 'src/utils/app';
import { INSTACART_MIN_DAILY_BUDGET } from 'src/components/Layout/Advertising/AdManagerPageLayout/AdManagerSetting/utils';

const styles = {
  slider: {},
  track: {
    backgroundColor: colors.blue
  },
  checked: {},
  lifetimeBudgetSubheader: {
    fontSize: 16,
    padding: '15px 0 10px'
  },
  lifetimeBudgetInputDiv: {
    display: 'grid',
    gridTemplateColumns: '180px 150px auto',
    alignItems: 'center',
    padding: '10px 0'
  },
  label: {
    fontSize: 16,
    fontWeight: 500
  },
  error: {
    color: colors.red,
    padding: '0 15px'
  }
};

const roundToHundreds = (number: number) => {
  if (number < 50) {
    return 30;
  }
  return Math.round(number / 100) * 100;
};

const clearKeywordList = () => store.dispatch(adCampaignBuilderActions.setKeywordList(null));

const setBudget = (budget: number | string, minimumBid: number, maximumBid: number) =>
  store.dispatch(adCampaignBuilderActions.setCampaignBudget(budget.toString(), minimumBid, maximumBid));

interface BudgetSliderHandle {
  dailyBudget: number;
}

interface BudgetSliderProps {
  classes: any;
  retailer: ReduxStore['retailer'];
  budget: {
    total: number;
    daily: number;
    displayName: string;
  };
}

// eslint-disable-next-line react/display-name
const BudgetSliderInner = forwardRef<BudgetSliderHandle, BudgetSliderProps>(({ classes, retailer, budget }, ref) => {
  const { adPlatformSettings, adCampaignBuilder } = store.getState();
  const campaignType = _get(adCampaignBuilder, 'campaignType.id', '');
  const adPlatformSetting = adPlatformSettings.find(
    (platform: any) =>
      _get(platform, 'settingType') === 'apiLimit' && _get(platform, 'extendedAttributes.campaignType') === campaignType
  );
  const minDailyBudget = _get(adPlatformSetting, 'extendedAttributes.campaignLimits.budget.minimumAmount', 1);
  const minTargetBid = _get(adPlatformSetting, 'extendedAttributes.targetLimits.minimumBid', 0);
  const maxTargetBid = _get(adPlatformSetting, 'extendedAttributes.targetLimits.maximumBid', 0);
  const minBudgetByRetailer = retailer.id === '2' ? 100 : 1; // only for walmart and instacart
  const [localBudget, setLocalBudget] = useState(
    budget.total < minDailyBudget * 30 ? minDailyBudget * 30 : budget.total
  );
  const dailyBudget = useMemo(() => parseFloat((localBudget / 30).toFixed(2)), [localBudget]);
  const [lifetimeBudgetChecked, setLifetimeBudgetChecked] = useState(false);
  const [lifetimeBudget, setLifetimeBudget] = useState(Math.max(dailyBudget, minBudgetByRetailer));
  const [lifetimeBudgetError, setLifetimeBudgetError] = useState('');
  const debouncedSetBudget = useCallback(_debounce(setBudget, 500), [setBudget]);
  const showLifetimeBudget = ['2', '63', '25'].includes(retailer.id);
  const MAX_LIFETIME_BUDGET = 1000000;

  /**
   * Update Redux state is debounced by 500 ms to avoid lag.
   * Expose the actual daily budget so we can show a warning before
   * proceeding if Instacart daily budget is too low
   */
  useImperativeHandle(
    ref,
    () => ({
      dailyBudget
    }),
    [dailyBudget]
  );

  const getMaxBudgetByRetailer = () => {
    switch (retailer.id) {
      case '16': // amazon japan
        return 500000;
      default:
        return 50000;
    }
  };

  useEffect(() => {
    if (budget.total < minDailyBudget) {
      setLocalBudget(roundToHundreds(minDailyBudget));
      debouncedSetBudget(roundToHundreds(minDailyBudget), minTargetBid, maxTargetBid);
    }
  }, [budget.total, minDailyBudget, debouncedSetBudget, minTargetBid, maxTargetBid]);

  useEffect(() => {
    if (lifetimeBudgetChecked) {
      store.dispatch(adCampaignBuilderActions.setCampaignLifetimeBudget(lifetimeBudget, lifetimeBudgetError));
    } else {
      store.dispatch(adCampaignBuilderActions.clearCampaignLifetimeBudget());
    }
  }, [dailyBudget, lifetimeBudget, lifetimeBudgetChecked, lifetimeBudgetError]);

  useEffect(() => {
    if (dailyBudget >= minBudgetByRetailer) {
      setLifetimeBudget(dailyBudget);
    }
  }, [dailyBudget, localBudget, minBudgetByRetailer]);

  useEffect(() => {
    if (lifetimeBudget === undefined) {
      setLifetimeBudgetError('*Please enter a lifetime budget.');
    } else if (lifetimeBudget < minBudgetByRetailer) {
      setLifetimeBudgetError(`*Lifetime budget should be at least ${retailer.currencySymbol}${minBudgetByRetailer}`);
    } else if (Math.trunc(lifetimeBudget) < Math.trunc(dailyBudget)) {
      setLifetimeBudgetError('*Lifetime budget should be greater than daily budget.');
    } else {
      setLifetimeBudgetError('');
    }
  }, [dailyBudget, lifetimeBudget, localBudget, minBudgetByRetailer, retailer.currencySymbol]);

  return (
    <div className="small-block">
      <h5 className="inner-header">Monthly Budget</h5>
      <div className="small-block">
        <Slider
          min={minDailyBudget * 30}
          max={getMaxBudgetByRetailer()}
          style={{ width: 500 }}
          classes={{ container: classes.slider, track: classes.track }}
          value={Number(localBudget)}
          valueReducer={(v) => +v.toFixed(0)}
          onChange={(event, value) => {
            setLocalBudget(roundToHundreds(value));
            debouncedSetBudget(roundToHundreds(value), minTargetBid, maxTargetBid);
          }}
        />
      </div>
      <br />
      <div className="budget-block">
        <NumFlip text={formatMoney(localBudget, retailer.currencySymbol)} textClassName="number_flip_data" />
        <div className="budget-block-detail">({formatMoney(dailyBudget, retailer.currencySymbol)} daily)</div>
      </div>
      {showLifetimeBudget && (
        <>
          <br />
          <div className="small-block">
            <h5 className="inner-header">Lifetime Budget</h5>
            <div className={classes.lifetimeBudgetSubheader}>
              Please specify if you want to set lifetime budget for this campaign
            </div>
          </div>
          <div className={classes.lifetimeBudgetInputDiv}>
            <div className={classes.label}>Lifetime Budget:</div>
            <Switch
              checked={lifetimeBudgetChecked}
              onChange={() => setLifetimeBudgetChecked((prev) => !prev)}
              color="primary"
            />
            {lifetimeBudgetChecked && (
              <div>
                <NumberFormat
                  value={lifetimeBudget}
                  thousandSeparator
                  prefix={retailer.currencySymbol}
                  decimalScale={0}
                  fixedDecimalScale
                  allowNegative={false}
                  isAllowed={({ floatValue }) => {
                    return floatValue <= MAX_LIFETIME_BUDGET || floatValue === undefined;
                  }}
                  onValueChange={({ floatValue }) => setLifetimeBudget(floatValue)}
                  // Mimic the style of the MUI `TextInput`
                  style={{
                    height: '32px',
                    outline: 'none',
                    border: '0',
                    borderBottomColor: 'currentcolor',
                    borderBottomStyle: 'none',
                    borderBottomWidth: '0px',
                    borderBottom: '1px solid #eee',
                    width: '120px',
                    fontSize: '16px',
                    color: 'currentColor',
                    fontFamily: 'Roboto'
                  }}
                />
                <span className={classes.error}>{lifetimeBudgetError}</span>
              </div>
            )}
          </div>
          <br />
          <br />
        </>
      )}
    </div>
  );
});

const BudgetSlider = withStyles(styles)(BudgetSliderInner);

const mapStateToProps = (state: ReduxStore) => _pick(state, ['retailer', 'adCampaignBuilder']);

const TargetDetail: React.FC<
  WidgetProps &
    ReturnType<typeof mapStateToProps> & {
      push: PushFn;
      widget: Widget & { configByTargetType: { [K in ValueOf<typeof AD_TARGETING_TYPE>]: Widget } };
      retailer: ReduxStore['retailer'];
    }
> = ({ widget, retailer, adCampaignBuilder, push }) => {
  const budgetSliderRef = useRef<BudgetSliderHandle>();

  const lifetimeBudgetError = _get(adCampaignBuilder, 'target.budget.lifetimeBudgetError', '');
  if (adCampaignBuilder.campaignType === undefined) {
    return null;
  }
  const {
    target: { selectedTargetEntities, budget, targetingTypeId },
    campaignType: { settingId }
  } = adCampaignBuilder;

  if (!targetingTypeId) {
    if (!getIsEditing()) {
      console.error('No `targetingType` is set on the target detail page; going back to start of flow');
      push('/home?tab=adCampaignBuilder&subtab=platformSelect');
      return null;
    } else {
      return panic("On the target detail page but we don't have any `targetingType` set");
    }
  }

  const widgetForTargetingType = widget.configByTargetType[targetingTypeId];
  if (!widgetForTargetingType) {
    return panic(`No widget for targeting type \`targetingTypeId\` "${targetingTypeId}" found in settings`);
  }
  const {
    view: { headerText, subHeaderText }
  } = widgetForTargetingType;

  const isSponsoredBrands = settingId === 'sponsoredBrands';
  const isSponsoredProducts = settingId === 'sponsoredProducts';
  const isSponsoredDisplay = settingId === 'sponsoredDisplay';
  const isAutoTargeting = targetingTypeId === 'autoTargeting';

  let pageSize = 1000; // default
  let maxSelected = 1000; // default

  // Override page size and max selected
  if (isSponsoredBrands) {
    pageSize = 100;
    maxSelected = 100;
  } else if (isSponsoredProducts) {
    pageSize = 500;
    maxSelected = 500;
  } else if (isSponsoredDisplay) {
    pageSize = 500;
    maxSelected = 500;
  }

  if (retailer.id === '2' || retailer.id === '25') {
    pageSize = 100;
    maxSelected = 100;
  }

  let canContinue = Option.of(selectedTargetEntities)
    .map((entities) => !!entities.find(propEq('selected', true)))
    .getOrElse(false);

  if (selectedTargetEntities) {
    const noOfSelected = selectedTargetEntities.filter((ent) => ent.selected).length;
    canContinue = noOfSelected > 0; // there is an upper limit, but we will handle this by cutting off any above the limit
  }

  if (isAutoTargeting) {
    canContinue = true;
  }

  if (lifetimeBudgetError) {
    canContinue = false;
  }

  const generateSubHeaderText = () => {
    if (isAutoTargeting) {
      return subHeaderText;
    }
    if (isSponsoredBrands || isSponsoredProducts || isSponsoredDisplay) {
      return `The table below displays four-week projected target economics for selected criteria. Select up to ${maxSelected} targets.`;
    }
    return subHeaderText;
  };

  return (
    <div className="ad-manager-container">
      <SectionWrapper header={headerText} subheader={generateSubHeaderText()} layer={0}>
        <BudgetSlider budget={budget} retailer={retailer} ref={budgetSliderRef} />
        {isAutoTargeting ? (
          <>
            <AutoTargetingProductAssign />
          </>
        ) : (
          <>
            <PillFilters />
            <TargetEntitiesGrid pageSize={pageSize} widget={widgetForTargetingType} />
          </>
        )}
      </SectionWrapper>
      <br />
      <OperationButtons
        subtab="targetDetail"
        canContinue={canContinue}
        openDialog={
          isInstacart(retailer.id) &&
          budgetSliderRef.current &&
          budgetSliderRef.current.dailyBudget < INSTACART_MIN_DAILY_BUDGET
        }
        dialogTitle="Information"
        dialogContent={`To opt into Instacart's Optimized Bidding, a minimum daily budget of $${INSTACART_MIN_DAILY_BUDGET} is required. Do you want to proceed?`}
        onBeforeLeave={() => {
          // Deselect all targets with index > the max # of targets allowed for campaign
          // This is temporary until we begin chunking groups of 100 targets
          if (selectedTargetEntities) {
            const allSelected = selectedTargetEntities.filter((ent) => ent.selected);
            if (allSelected.length > maxSelected) {
              // unselect those after
              const unselect = allSelected.slice(maxSelected);
              store.dispatch(
                adCampaignBuilderActions.bulkSetTargetEntitiesSelected(
                  unselect.map((t) => t.id),
                  false // uncheck those targets over the limit
                )
              );
            }
          }
          if (isAutoTargeting) {
            // asign the bid mapping to products
            const productsList = _cloneDeep(_get(adCampaignBuilder, ['target', 'autoCampaignProducts'], []));
            const mapping = _cloneDeep(_get(adCampaignBuilder, ['target', 'autoBidMapping'], new Map()));

            productsList.forEach((product) => {
              const bid = mapping.get(product.id) || 0.75;
              product.bid = bid;
            });
            store.dispatch(adCampaignBuilderActions.addAutoCompaignProducts(productsList));
          }
          clearKeywordList();
          return true;
        }}
      />
    </div>
  );
};

const EnhancedTargetDetail = withPush(connect(mapStateToProps)(TargetDetail));

export default EnhancedTargetDetail;
