import React, { useState } from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from '@stackline/ui';
import { useAppSelector, useRetailerUrl } from 'src/utils/Hooks';
import { getProductImageUrl } from 'src/utils/image';
import { Link } from 'react-router-dom';
import IconMore from '!svg-react-loader!../ProductCard/assets/iconMore.svg'; //eslint-disable-line
import IconLess from '!svg-react-loader!../ProductCard/assets/iconLess.svg'; //eslint-disable-line
import { ProductImage } from 'src/components/BeaconRedesignComponents/ProductCard';
import {
  BEACON_PRO_SIDEBAR_IMAGE_MAX_HEIGHT,
  BEACON_PRO_SIDEBAR_IMAGE_WIDTH
} from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

const HoverButton = ({ onClick, hovered, side }: { onClick: () => void; hovered: boolean; side: 'front' | 'back' }) => {
  const icon = side === 'front' ? <IconMore onClick={onClick} /> : <IconLess onClick={onClick} />;
  return (
    hovered && (
      <div style={{ cursor: 'pointer', position: 'absolute', top: '-4px', right: '27px', zIndex: 2 }}>{icon}</div>
    )
  );
};

const FLIP_TIME = 0.4;
/**
 * Product card that appears in top left corner when on the product view
 */
export default function EntityProductCard() {
  const [hovered, setHovered] = useState(false);
  const [side, setSide] = useState<'front' | 'back'>('front');
  // Controls card content - used to hide content while the flip animation happens
  const [cardSide, setCardSide] = useState<'front' | 'back'>('front');

  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);
  const { searchParams, additionalParams } = useAppSelector((state) => state.app.queryParams);
  const retailerUrl = useRetailerUrl(mainEntity.stacklineSku);

  const flipCard = () => {
    const newSide = side === 'front' ? 'back' : 'front';
    setCardSide(null);
    setSide(newSide);
    setTimeout(() => setCardSide(newSide), (FLIP_TIME * 1000) / 2);
  };

  const cardFront = (
    <Flex flexDirection="column" alignItems="center">
      <ProductImage
        imageUri={getProductImageUrl(mainEntity.stacklineSku)}
        mainDisplayTitle={mainEntity.name}
        secondaryDisplayTitle={mainEntity.shortDisplayName}
        width={BEACON_PRO_SIDEBAR_IMAGE_WIDTH}
        height={BEACON_PRO_SIDEBAR_IMAGE_MAX_HEIGHT}
        fallbackImageStyle={{ marginBottom: 0 }}
      />
      <Flex marginTop="8px" gap="sm">
        <Link target="_blank" rel="noreferrer" to={`/brand/${mainEntity.brandId}${searchParams}${additionalParams}`}>
          <Text variant="subtitle2">{mainEntity.brandName}</Text>
        </Link>
      </Flex>
      <div style={{ marginTop: '12px' }}>
        <Link to={`/product/${mainEntity.stacklineSku}${searchParams}${additionalParams}`}>
          <Text variant="body3" textAlign="center" truncateLines={2} title={mainEntity.name}>
            {mainEntity.name}
          </Text>
        </Link>
      </div>
    </Flex>
  );

  const cardBackItems = [
    {
      title: 'Product ID',
      value: mainEntity.stacklineSku,
      link: `/product/${mainEntity.stacklineSku}${searchParams}${additionalParams}`
    },
    {
      title: 'Retailer Sku',
      value: mainEntity.retailerSku,
      link: retailerUrl,
      newTab: true
    },
    {
      title: 'Category',
      value: mainEntity.categoryName,
      link: `/category/${mainEntity.categoryId}${searchParams}${additionalParams}`
    },
    {
      title: 'Subcategory',
      value: mainEntity.subCategoryName,
      link: `/subcategory/${mainEntity.subCategoryId}${searchParams}${additionalParams}`
    }
  ];

  const cardBack = (
    <Flex
      sx={{
        transform: 'rotateY(180deg)'
      }}
    >
      <Flex flexDirection="column" gap="sm" width="124px">
        {cardBackItems.map(({ title, value, link, newTab }) => (
          <Flex flexDirection="column" gap="xxs" key={title}>
            <Text transform="uppercase" variant="subtitle4">
              {title}
            </Text>
            {newTab ? (
              <a href={link} target="_blank" rel="noreferrer">
                <Text variant="body3">{value}</Text>
              </a>
            ) : (
              <Link to={link}>
                <Text variant="body3">{value}</Text>
              </Link>
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );

  return (
    <div style={{ position: 'relative' }} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <HoverButton onClick={() => flipCard()} hovered={hovered} side={side} />
      <Flex
        sx={{
          height: '145px',
          cursor: 'pointer',
          transform: side === 'back' ? 'rotateY(180deg)' : 'rotateY(0deg)',
          transition: `${FLIP_TIME}s`
        }}
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        {cardSide === 'front' ? cardFront : cardSide === 'back' ? cardBack : null}
      </Flex>
    </div>
  );
}
