import React, { FC } from 'react';

import { Chip, FormControl, InputAdornment, TextField, Autocomplete } from '@mui/material';
import { Search } from '@mui/icons-material';

interface DiscoverSubCategorySearch {
  isSuperUser: boolean;
  handleSubCategoryChange: (event: any, values: any) => void;
  selectedSubCategory: any;
  subCategoryBasedOnCategorySelection: any;
  superUserSubCategories: any;
  onDeleteSubcategories: (value: string) => void;
}
const DiscoverSubCategorySearch: FC<DiscoverSubCategorySearch> = ({
  isSuperUser,
  handleSubCategoryChange,
  superUserSubCategories,
  onDeleteSubcategories,
  selectedSubCategory,
  subCategoryBasedOnCategorySelection
}) => {
  let options = !isSuperUser
    ? subCategoryBasedOnCategorySelection.map((subCat) => subCat.value)
    : superUserSubCategories;
  options = options === undefined ? [] : options;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
      <>
        <div
          className="categoryFilter"
          style={{
            marginTop: '1em',
            border: '1px solid #f6f9fd',
            borderRadius: '5px',
            padding: '7px 11px',
            width: '100%',
            background: '#f6f9fd',
            height: '60px'
          }}
        >
          <FormControl style={{ width: '100%' }}>
            <Autocomplete
              id="tags-outlined-subcat"
              multiple
              options={options}
              getOptionLabel={(option) => (option ? option.subCategoryName : '')}
              value={selectedSubCategory}
              onChange={handleSubCategoryChange}
              renderTags={() => null}
              renderInput={(selected) => {
                return (
                  <TextField
                    hiddenLabel
                    variant="standard"
                    {...selected}
                    style={{ width: '100%' }}
                    InputProps={{
                      ...selected.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search className="filter-blue" />
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search Subcategories"
                    color="primary"
                  />
                );
              }}
            ></Autocomplete>
          </FormControl>
        </div>
      </>
      <>
        <div className="chipContainer">
          {selectedSubCategory &&
            selectedSubCategory.map((value) => {
              return (
                <Chip
                  key={value.subCategoryName}
                  label={value.subCategoryName}
                  onDelete={() => onDeleteSubcategories(value.subCategoryName)}
                  className="search-chip discover-search-chip"
                />
              );
            })}
        </div>
      </>
    </div>
  );
};

export default DiscoverSubCategorySearch;
