import React, { useState } from 'react';
import BeaconChartWithLegend from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import SplineChartLoading from 'src/components/BeaconRedesignComponents/SplineChartLoading/SplineChartLoading';
import { getAppName } from 'src/utils/app';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import _cloneDeep from 'lodash/cloneDeep';
import {
  useForecastComparisonPeriod,
  useForecastComparisonStartAndEndWeekIds,
  useForecastPeriod,
  useForecastStartAndEndWeekIds
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import {
  FORECAST_COMPARISON_TO_LABEL_MAP,
  FORECAST_PERIOD_TO_LABEL_MAP
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/dropdowns';
import TopEntityChartV2 from 'src/components/BeaconRedesignComponents/TopEntityChartV2/TopEntityChartV2';
import { useForecastSummaryTopEntityChartData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastSummaryTopEntityChartData';
import convertBarChartSeriesToDelimitedData from 'src/components/Charts/GenericChart/SeriesConverters/barChart';
import { Series } from 'highcharts';
import { useAppSelector } from 'src/utils/Hooks';
import { getWeekLastDay } from 'src/utils/dateformatting';
import saveAs from 'file-saver';

enum ForecastSummaryTopEntityMainDropDownOption {
  retailSales = 'retailSales',
  unitsSold = 'unitsSold',
  wholesaleSales = 'wholesaleSales',
  retailerGrossMargin = 'retailerGrossMargin',
  brandGrossMargin = 'brandGrossMargin',
  totalClicks = 'totalClicks',
  organicClicks = 'organicClicks'
}

enum ForecastSummaryTopEntityGroupByOption {
  subCategoryId = 'subCategoryId',
  categoryId = 'categoryId',
  brandId = 'brandId'
}

const ForecastSummaryTopEntityChart = () => {
  const indexName = 'sales';
  const groupByFieldOptions = Object.values(ForecastSummaryTopEntityGroupByOption);

  const mainMetricFieldOptions = Object.values(ForecastSummaryTopEntityMainDropDownOption);
  const retailer = useAppSelector((state) => state.retailer);

  const { startWeekId: forecastStartWeekId, endWeekId: forecastEndWeekId } = useForecastStartAndEndWeekIds();
  const { startWeekId: comparisonStartWeekId, endWeekId: comparisonEndWeekId } =
    useForecastComparisonStartAndEndWeekIds();

  const forecastPeriod = useForecastPeriod();
  const forecastComparisonPeriod = useForecastComparisonPeriod();
  const [selectedGroupByField, setSelectedGroupByField] = useState('subCategoryId');
  const [selectedMainMetricField, setSelectedMainMetricField] = useState('retailSales');
  const selectedMainMetricFieldIndex = _cloneDeep(
    INDEX_FIELDS.getField(getAppName(), 'sales', selectedMainMetricField)
  );

  const { clonedOriginalData, isLoading } = useForecastSummaryTopEntityChartData(
    selectedGroupByField,
    selectedMainMetricField
  );

  const handleGroupByChange = (e) => {
    setSelectedGroupByField(e.target.value);
  };

  const handleMainMetricChange = (e) => {
    setSelectedMainMetricField(e.target.value);
  };

  const convertSeriesToDelimitedData = () => {
    try {
      const comparisonStartDate = getWeekLastDay(comparisonStartWeekId, true);
      const comparisonLastDate = getWeekLastDay(comparisonEndWeekId, true);
      const mainStartDate = getWeekLastDay(forecastStartWeekId, true);
      const mainEndDate = getWeekLastDay(forecastEndWeekId, true);
      const csvData = convertBarChartSeriesToDelimitedData(
        [
          {
            type: 'column',
            data: clonedOriginalData.originalComparisonData,
            timePeriod: `${comparisonStartDate} - ${comparisonLastDate}`,
            name: selectedMainMetricFieldIndex.displayName,
            categories: clonedOriginalData ? clonedOriginalData.originalComparisonData : []
          } as unknown as Series,
          {
            type: 'column',
            data: clonedOriginalData ? clonedOriginalData.originalCurrentData : [],
            timePeriod: `${mainStartDate} - ${mainEndDate}`,
            name: selectedMainMetricFieldIndex.displayName,
            categories: clonedOriginalData ? clonedOriginalData.originalCurrentData : []
          } as unknown as Series
        ],
        retailer
      );
      const blob = new Blob([csvData], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, `${selectedMainMetricFieldIndex.displayName} projections.csv`);
    } catch (e) {
      console.error('error: ', e);
    }
    return Promise.resolve();
  };

  return (
    <BeaconChartWithLegend
      loading={isLoading || !clonedOriginalData}
      loadingComponent={<SplineChartLoading />}
      groupByFields={groupByFieldOptions.map((groupByField) =>
        _cloneDeep(INDEX_FIELDS.getField(getAppName(), indexName, groupByField))
      )}
      selectedGroupBy={_cloneDeep(INDEX_FIELDS.getField(getAppName(), 'sales', selectedGroupByField))}
      mainMetricFields={mainMetricFieldOptions.map((mainMetricField) =>
        _cloneDeep(INDEX_FIELDS.getField(getAppName(), 'sales', mainMetricField))
      )}
      selectedMainMetric={selectedMainMetricFieldIndex}
      primaryLegendProps={{
        displayName: FORECAST_PERIOD_TO_LABEL_MAP[forecastPeriod]
      }}
      comparisonLegendProps={{
        displayName: FORECAST_COMPARISON_TO_LABEL_MAP[forecastComparisonPeriod]
      }}
      handleGroupBy={handleGroupByChange}
      handleMainMetric={handleMainMetricChange}
      convertSeriesToDelimitedData={convertSeriesToDelimitedData}
    >
      <TopEntityChartV2
        redirectToSamePage={false}
        chartSeries={[
          {
            metricType: selectedMainMetricFieldIndex.metricType,
            type: 'column',
            data: clonedOriginalData ? clonedOriginalData.originalComparisonData : [],
            categories: clonedOriginalData ? clonedOriginalData.originalMetaData : []
          },
          {
            type: 'column',
            data: clonedOriginalData ? clonedOriginalData.originalCurrentData : []
          }
        ]}
      />
    </BeaconChartWithLegend>
  );
};

export default ForecastSummaryTopEntityChart;
