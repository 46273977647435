/* eslint-disable react/prop-types */
import React from 'react';
// import { MoreInfo } from 'src/components/SvgIcons';
import './InfoTooltip.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import colors from 'src/utils/colors';

const InfoTooltip: React.FC<
  { style?: React.CSSProperties } & { customNode?: HTMLLabelElement | Node | string; interactive?: boolean; props: any }
> = ({ children, customNode, interactive, ...props }) => {
  if (!children) {
    return null;
  }
  return (
    <Tooltip title={children} interactive={interactive} placement="top" {...props}>
      <div className="infoTooltip-node">{customNode || <InfoOutlinedIcon style={{ color: colors.grey }} />}</div>
    </Tooltip>
  );
};

export default InfoTooltip;
