/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import { connect, useDispatch } from 'react-redux';
import { store } from 'src/main';
import _pick from 'lodash/pick';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import ReduxStore from 'src/types/store/reduxStore';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import { validIndexForIncrementality } from 'src/utils/incrementalityColumn';
import {
  reachColums,
  spendColums,
  salesColums,
  incrementalityColumns
} from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';
import { checkIsAmazonRetailer, getParentPlatform, parseQueryString } from 'src/utils/browser';
import userOperations from 'src/store/modules/user/actions';
import { AD_PLATFORM_TYPE } from 'sl-ad-campaign-manager-data-model';
import UserCustomColumnButtonGroup from './components/UserCustomColumnButtonGroup';
import { TOTAL_USER_COLUMNS } from './utils';
import makeStyles from '@mui/styles/makeStyles';
import colors from 'src/utils/colors';
import { shouldShowCriteo } from 'src/utils/app';

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, ['user'])
  };
};

export const DEFAULT_USER_COLUMNS = [
  'clicks',
  'spend',
  'sales',
  'costPerClick',
  'returnOnAdSpend',
  checkIsAmazonRetailer() ? 'avgIncrementality' : 'clickThroughRate'
];

export enum AdPlatformDisplayColumn {
  Amazon = 'Amazon',
  Instacart = 'Instacart',
  Walmart = 'Walmart',
  SamsClub = 'SamsClub'
}

export const getAdPlatformDisplayColumn = (platformType: AD_PLATFORM_TYPE, retailer: any): string => {
  if (platformType) {
    if (platformType.includes(AdPlatformDisplayColumn.Instacart)) {
      return AdPlatformDisplayColumn.Instacart;
    }
    if (platformType.includes(AdPlatformDisplayColumn.Walmart)) {
      return AdPlatformDisplayColumn.Walmart;
    }
    if (platformType.includes(AdPlatformDisplayColumn.Amazon)) {
      return AdPlatformDisplayColumn.Amazon;
    }
  }
  if (shouldShowCriteo() && retailer && retailer.parentPlatform) {
    return retailer.parentPlatform;
  }

  console.error('platformType: Not find');

  return AdPlatformDisplayColumn.Amazon;
};

export const getUserColumns = () => {
  const urlParams = parseQueryString();
  const retailerId = _get(urlParams, 'rid', '1');
  const { user } = store.getState();
  const uiPreference = _get(user, ['config', 'uiPreference'], {});
  const parentPlatform = getParentPlatform();

  if (!uiPreference.advertisingDisplayColumnsByPlatformType) {
    // after enabling custom columns by platform type,
    // advertisingDisplayColumnsByPlatformType will be null
    // for all users who haven't updated custom columns
    // Upd2: _get doesn't apply default value for null case, so additional check added.
    return _get(uiPreference, 'advertisingDisplayColumns', DEFAULT_USER_COLUMNS) || DEFAULT_USER_COLUMNS;
  }
  switch (retailerId) {
    case '63':
    case '100':
      return _get(
        uiPreference,
        `advertisingDisplayColumnsByPlatformType.${AdPlatformDisplayColumn.Instacart}`,
        DEFAULT_USER_COLUMNS
      );
    case '2':
      return _get(
        uiPreference,
        `advertisingDisplayColumnsByPlatformType.${AdPlatformDisplayColumn.Walmart}`,
        DEFAULT_USER_COLUMNS
      );
    case '25':
      return _get(
        uiPreference,
        `advertisingDisplayColumnsByPlatformType.${AdPlatformDisplayColumn.SamsClub}`,
        DEFAULT_USER_COLUMNS
      );
    default:
      if (shouldShowCriteo() && parentPlatform) {
        return _get(uiPreference, `advertisingDisplayColumnsByPlatformType.${parentPlatform}`, DEFAULT_USER_COLUMNS);
      } else {
        return _get(
          uiPreference,
          `advertisingDisplayColumnsByPlatformType.${AdPlatformDisplayColumn.Amazon}`,
          DEFAULT_USER_COLUMNS
        );
      }
  }
};

export const useStyles = makeStyles({
  errorMessage: {
    color: colors.red
  }
});

const UserCustomColumn: React.FC<ReturnType<typeof mapStateToProps>> = ({ user }) => {
  const [selectedReachColumns, setSelectedReachColumns] = useState([]);
  const [selectedSpendColumns, setSelectedSpendColumns] = useState([]);
  const [selectedSalesColumns, setSelectedSalesColumns] = useState([]);
  const [selectedIncrementalityColumns, setSelectedIncrementalityColumns] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const { retailer, app } = store.getState();
  const retailerId = _get(retailer, ['id']);
  const stage = _get(app, ['stage']);
  const validIncrementalityIndex = validIndexForIncrementality(retailerId, stage);
  const userSettingColumns = validIncrementalityIndex
    ? [...selectedReachColumns, ...selectedSpendColumns, ...selectedSalesColumns, ...selectedIncrementalityColumns]
    : [...selectedReachColumns, ...selectedSpendColumns, ...selectedSalesColumns];
  const isAmazonRetailer = checkIsAmazonRetailer();
  const dispatch = useDispatch();
  const classes = useStyles();

  const saveAndReloadUserConfig = () => {
    setIsloading(true);
    const platformType = getAdPlatformDisplayColumn(retailer.platformTyp, retailer);
    const SET_COLUMNS_API = `/api/user/SetAdvertisingDisplayColumns?platformType=${platformType}`;
    axios
      .post(SET_COLUMNS_API, userSettingColumns)
      .then((response) => {
        dispatch(userOperations.updateUserDisplayColumns(response.data, platformType));
        const redirectionUrl = _get(user.config, 'redirectionUrl', window.location.origin);
        window.location.href = redirectionUrl;
      })
      .catch((err) => console.error('error setting custom columns: ', err.message));
  };

  const saveChange = () => {
    saveAndReloadUserConfig();
  };

  const setDefault = () => {
    setIsloading(true);
    const platformType = getAdPlatformDisplayColumn(retailer.platformType, retailer);
    const SET_COLUMNS_API = `/api/user/SetAdvertisingDisplayColumns?platformType=${platformType}`;
    axios
      .post(SET_COLUMNS_API, DEFAULT_USER_COLUMNS)
      .then((response) => {
        dispatch(userOperations.updateUserDisplayColumns(response.data, platformType));
        setIsloading(false);
      })
      .catch((err) => console.error('error setting custom columns: ', err.message));
  };

  useEffect(() => {
    let userColumns = getUserColumns();
    if (
      !validIncrementalityIndex &&
      _intersection(userColumns, [
        'brandTotalClicksIncrementalSales',
        'brandTotalClicksIncrementalClicks',
        'brandTotalClicksIncrementalROAS',
        'brandTotalClicksIncrementalUnitsOrders',
        'avgIncrementality'
      ]).length > 0
    ) {
      userColumns = DEFAULT_USER_COLUMNS;
    }
    const reach = userColumns.filter((str) => reachColums.find((item) => item.field === str));
    const spend = userColumns.filter((str) => spendColums.find((item) => item.field === str));
    const sales = userColumns.filter((str) => salesColums.find((item) => item.field === str));
    setSelectedReachColumns(reach);
    setSelectedSpendColumns(spend);
    setSelectedSalesColumns(sales);
    if (validIncrementalityIndex) {
      const incrementality = userColumns.filter((str) => incrementalityColumns.find((item) => item.field === str));
      setSelectedIncrementalityColumns(incrementality);
    }
  }, [user, validIncrementalityIndex]);

  return (
    <form className="custom-columns" onSubmit={() => {}} style={{ maxWidth: `${isAmazonRetailer ? 1018 : 860}px` }}>
      <h4 className="sl-header-text">Custom columns</h4>
      <hr className="sl-divider sl-divider--no-margin-top sl-divider--lg-margin-bottom" />
      <h4 className="sl-form-label">
        Customize columns in the metric tables for {getAdPlatformDisplayColumn(retailer.platformType, retailer)}.{' '}
        {userSettingColumns.length === TOTAL_USER_COLUMNS && (
          <strong className={classes.errorMessage}>You can select up to {TOTAL_USER_COLUMNS} metrics.</strong>
        )}
      </h4>
      <br /> <br />
      {isLoading ? (
        <GridLoading />
      ) : !validIncrementalityIndex ? (
        <>
          <div>
            <div style={{ marginBottom: 10 }}>
              <strong>Reach :</strong>
            </div>
            <UserCustomColumnButtonGroup
              columns={reachColums}
              selectedColumnValues={selectedReachColumns}
              userSettingColumns={userSettingColumns}
              onSelectColumns={(_event, newColumns) =>
                [...newColumns, ...selectedSpendColumns, ...selectedSalesColumns].length <= TOTAL_USER_COLUMNS &&
                setSelectedReachColumns(newColumns)
              }
            />
          </div>
          <div style={{ marginTop: 25 }}>
            <div style={{ marginBottom: 10 }}>
              <strong>Ad Spend :</strong>
            </div>
            <UserCustomColumnButtonGroup
              columns={spendColums}
              selectedColumnValues={selectedSpendColumns}
              userSettingColumns={userSettingColumns}
              onSelectColumns={(_event, newColumns) =>
                [...selectedReachColumns, ...newColumns, ...selectedSalesColumns].length <= TOTAL_USER_COLUMNS &&
                setSelectedSpendColumns(newColumns)
              }
            />
          </div>
          <div style={{ marginTop: 25 }}>
            <div style={{ marginBottom: 10 }}>
              <strong>Ad Sales :</strong>
            </div>
            <UserCustomColumnButtonGroup
              columns={salesColums}
              selectedColumnValues={selectedSalesColumns}
              userSettingColumns={userSettingColumns}
              onSelectColumns={(_event, newColumns) =>
                [...selectedReachColumns, ...selectedSpendColumns, ...newColumns].length <= TOTAL_USER_COLUMNS &&
                setSelectedSalesColumns(newColumns)
              }
            />
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <LargeMuiButton
              style={{ width: 110, height: 35, marginRight: 10 }}
              label="Reset"
              secondary
              onClick={setDefault}
            />
            <LargeMuiButton
              style={{ width: 110, height: 35, marginLeft: 10 }}
              label="Save"
              disabled={userSettingColumns.length === 0}
              onClick={saveChange}
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <div style={{ marginBottom: 10 }}>
              <strong>Reach :</strong>
            </div>
            <div className="select_area">
              <UserCustomColumnButtonGroup
                columns={reachColums}
                selectedColumnValues={selectedReachColumns}
                userSettingColumns={userSettingColumns}
                onSelectColumns={(event, newColumns) =>
                  [...newColumns, ...selectedSpendColumns, ...selectedSalesColumns, ...selectedIncrementalityColumns]
                    .length <= TOTAL_USER_COLUMNS && setSelectedReachColumns(newColumns)
                }
              />
            </div>
          </div>
          <div style={{ marginTop: 25 }}>
            <div style={{ marginBottom: 10 }}>
              <strong>Ad Spend :</strong>
            </div>
            <UserCustomColumnButtonGroup
              columns={spendColums}
              selectedColumnValues={selectedSpendColumns}
              userSettingColumns={userSettingColumns}
              onSelectColumns={(_event, newColumns) =>
                [...selectedReachColumns, ...newColumns, ...selectedSalesColumns, ...selectedIncrementalityColumns]
                  .length <= TOTAL_USER_COLUMNS && setSelectedSpendColumns(newColumns)
              }
            />
          </div>
          <div style={{ marginTop: 25 }}>
            <div style={{ marginBottom: 10 }}>
              <strong>Ad Sales :</strong>
            </div>
            <UserCustomColumnButtonGroup
              columns={salesColums}
              selectedColumnValues={selectedSalesColumns}
              userSettingColumns={userSettingColumns}
              onSelectColumns={(_event, newColumns) =>
                [...selectedReachColumns, ...selectedSpendColumns, ...newColumns, ...selectedIncrementalityColumns]
                  .length <= TOTAL_USER_COLUMNS && setSelectedSalesColumns(newColumns)
              }
            />
          </div>
          <div style={{ marginTop: 25 }}>
            <div style={{ marginBottom: 10 }}>
              <strong>Incrementality :</strong>
            </div>
            <UserCustomColumnButtonGroup
              columns={incrementalityColumns}
              selectedColumnValues={selectedIncrementalityColumns}
              userSettingColumns={userSettingColumns}
              onSelectColumns={(_event, newColumns) =>
                [...selectedReachColumns, ...selectedSpendColumns, ...selectedSalesColumns, ...newColumns].length <=
                  TOTAL_USER_COLUMNS && setSelectedIncrementalityColumns(newColumns)
              }
            />
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <LargeMuiButton
              style={{ width: 110, height: 35, marginRight: 10 }}
              label="Reset"
              secondary
              onClick={setDefault}
            />
            <LargeMuiButton
              style={{ width: 110, height: 35, marginLeft: 10 }}
              label="Save"
              disabled={userSettingColumns.length === 0}
              onClick={saveChange}
            />
          </div>
        </>
      )}
    </form>
  );
};
export default connect(mapStateToProps)(UserCustomColumn);
