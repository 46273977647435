import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _identity from 'lodash/identity';
import _isNil from 'lodash/isNil';
import _zip from 'lodash/zip';

import { percentColor, formatPercent } from 'src/utils/metrics';

const cellContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // textAlign: 'right',
  // paddingRight: 20,
  height: 65
};

// These should probably be pulled in from `entityDefinitions`
const columnTitles = [
  'Retail Sales',
  'Units Sold',
  'Retail Price',
  'Rating',
  'Reviews',
  'Reviews',
  'Content Score',
  'Title Score',
  'Bullets Score',
  'Image Score',
  'Video Score',
  'A-Plus Score',
  'Wholesale|Price',
  'Wholesale Price',
  'Wholesale Sales',
  'In-Stock Rate',
  'Units on Hand',
  'On Hand - Units',
  'Retailer Margin per Unit',
  'Retailer Margin|per Unit',
  'Retailer|Margin',
  'Retailer Margin',
  'Retailer Margin %',
  'Return Rate %',
  'Units|Returned',
  'Units Returned',
  'Returns - Retail Value',
  'Impressions',
  'Clicks',
  'Ad Spend',
  'Ad Sales',
  'Brand COGs|per Unit',
  'Brand COGs per Unit',
  'Brand COGs',
  'Brand Margin|per Unit',
  'Brand Margin per Unit',
  'Brand Margin',
  'MAP Price',
  'Weeks on|Hand',
  'On Hand -|Retail',
  'Buy Box - Win|Rate',
  'Buy Box - Loss|Rate',
  'Cost per Acquisition',
  'Cost per Click',
  'Conversion Rate',
  'ROAS',
  'Conversion Rate',
  'Ingredients Score',
  'Warnings Score',
  'Directions Score',
  'Description Score'
];

const dataKeys = [
  'retailSales',
  'unitsSold',
  'retailPrice',
  'reviewsRating',
  'reviewsCount',
  'reviewId',
  'contentScore',
  'titleScore',
  'bulletScore',
  'imageScore',
  'videoScore',
  'aplusScore',
  'wholesalePrice',
  'wholesalePrice',
  'wholesaleSales',
  'instockRate',
  'unitsOnHand',
  'unitsOnHand',
  'retailerMarginPerUnit',
  'retailerMarginPerUnit',
  'retailerGrossMargin',
  'retailerGrossMargin',
  'retailerGrossMarginPercent',
  'returnRate',
  'unitsReturned',
  'unitsReturned',
  'returnRetailValue',
  'impressions',
  'clicks',
  'spend',
  'sales',
  'brandCogsPerUnit',
  'brandCogsPerUnit',
  'brandCogs',
  'brandGrossMarginPerUnit',
  'brandGrossMarginPerUnit',
  'brandGrossMargin',
  'mapPrice',
  'inventoryWeeksOnHandGrid',
  'inventoryRetailValue',
  'winPercentage',
  'lossPercentage',
  'costPerAcquisition',
  'costPerClick',
  'conversionRate',
  'returnOnAdSpend',
  'purchaseRate',
  'ingredientsScore',
  'warningsScore',
  'directionsScore',
  'descriptionScore'
];

const percentChangeDataKeys = dataKeys.map((str) => `${str}PercentChange`);

const rowProps = _zip(columnTitles, dataKeys, percentChangeDataKeys);

export const AtlasProductCell = ({ formattedValue, percentChange }) => (
  <div style={cellContentStyle}>
    <div>{formattedValue}</div>
    <div>
      <span style={{ color: percentColor(percentChange) }}>{formatPercent(percentChange)}</span>
    </div>
  </div>
);

AtlasProductCell.propTypes = {
  formattedValue: PropTypes.node.isRequired,
  percentChange: PropTypes.string.isRequired
};

// TODO: Refactor this:
// - We shouldn't be doing linear lookups through an array to find the data key to use
// - We shouldn't be hard-coding the column titles/display names
// - This needs to be renamed since it's used in Beacon as well
export const mkAtlasProductCellFormatter = (formatValue) => {
  const AtlasProductCellFormatterInner = ({
    data,
    colDef: { headerName },
    headerNameOverride,
    getValue,
    getPercentChange
  }) => (
    <>
      {rowProps.map(([columnTitle, dataKey, percentChangeKey], i) => {
        const percentChange = getPercentChange ? getPercentChange(data) : _get(data, percentChangeKey);
        if (!(headerNameOverride || headerName).includes(columnTitle) || _isNil(percentChange)) {
          return null;
        }

        const value = getValue ? getValue(data) : _get(data, dataKey);

        return <AtlasProductCell formattedValue={formatValue(value)} percentChange={percentChange} key={i} />;
      })}
    </>
  );
  AtlasProductCellFormatterInner.propTypes = {
    data: PropTypes.object.isRequired,
    colDef: PropTypes.object.isRequired,
    headerNameOverride: PropTypes.string,
    getValue: PropTypes.func,
    getPercentChange: PropTypes.func
  };
  AtlasProductCellFormatterInner.defaultProps = {
    headerNameOverride: null,
    getValue: null,
    getPercentChange: null
  };

  return AtlasProductCellFormatterInner;
};

const AtlasProductCellFormatter = mkAtlasProductCellFormatter(_identity);

export default AtlasProductCellFormatter;
