import React from 'react';
import ProfilePictureWithUpload from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Profile/ProfilePictureWithUpload';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import AuthContainer from 'src/components/SignIn/AuthContainer';
import LogoContainer from 'src/components/SignIn/LogoContainer';
import AddIcon from '@mui/icons-material/Add';
import { useAppSelector } from 'src/utils/Hooks';
import AuthButton from 'src/components/SignIn/AuthButton';
import Box from '@mui/material/Box';
import { AnimatedEllipsis } from 'src/components/common/Loading/AnimatedEllipsis';
import * as serverProxy from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/serverProxy';
import { useDispatch } from 'react-redux';
import { setErrorMessage } from 'src/store/modules/auth/operations';

/**
 * Component that will render user to upload profile image in sign in container.
 *
 */
const UploadProfile = ({
  email,
  clearErrorMessage,
  handleFormSubmit
}: {
  email: string;
  clearErrorMessage: () => void;
  setErrorMessage: (msg: string) => void;
  handleFormSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  const app = useAppSelector((state) => state.app);
  const dispatch = useDispatch();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [profilePicture, setProfilePicture] = React.useState<File | null>(null);

  return (
    <AuthContainer>
      <LogoContainer title={app.displayName} />
      <p
        style={{
          fontSize: '34px',
          fontWeight: 'bold',
          marginTop: '24px',
          marginBottom: '48px',
          textAlign: 'center'
        }}
      >
        Upload profile photo
      </p>
      <Flex justifyContent="center" marginBottom="48px">
        <ProfilePictureWithUpload
          email={email}
          appProfileIconProps={{
            size: 256,
            backgroundColor: '#f6f8fa'
          }}
          fabSize={56}
          uploadImage={(file, userEmail) => {
            setProfilePicture(file);
            serverProxy.uploadUserImage(file, userEmail);
            clearErrorMessage();
          }}
          fabIcon={<AddIcon sx={{ color: 'white', fontSize: '32px' }} />}
        />
      </Flex>
      <AuthButton
        type="submit"
        onClick={(event) => {
          if (!profilePicture) {
            event.preventDefault();
            dispatch(setErrorMessage('Please upload a profile photo to complete your account setup.'));
          } else {
            setIsFormSubmitted(true);
            handleFormSubmit(event);
          }
        }}
      >
        {isFormSubmitted ? (
          <Box display="flex" flexDirection="row" maxHeight="100%" alignItems="center" justifyContent="center">
            Upload Photo
            <AnimatedEllipsis width={40} height={40} style={{ margin: 0, transform: 'translate(-8px,5px)' }} />
          </Box>
        ) : (
          <>Upload Photo</>
        )}
      </AuthButton>
    </AuthContainer>
  );
};

export default UploadProfile;
