import { AD_PLATFORM_TYPE } from 'sl-ad-campaign-manager-data-model';
import types from './types';

export default {
  setRetailer: (retailerId: string | number, parentPlatform?: string) => ({
    type: types.SET_RETAILER,
    retailerId,
    parentPlatform
  }),
  setRetailerPlatformDetails: (platformType: AD_PLATFORM_TYPE, processInTimezone: string) => ({
    type: types.SET_RETAILER_PLATFORM_DETAILS,
    platformType,
    processInTimezone
  })
};
