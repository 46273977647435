import React, { useState } from 'react';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import Box from '@mui/material/Box';
import {
  ProductColumnDefinition,
  CategoryColumnDefinition,
  SubcategoryColumnDefinition,
  BrandColumnDefinition
} from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import { useTotalProductCount } from 'src/components/BeaconRedesignComponents/MetricList/entityCountHooks';
import ProductGrid from 'src/components/BeaconRedesignComponents/ProductGrid/ProductGrid';
import { useAppSelector } from 'src/utils/Hooks';
import { IconsList } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';
import { BEACON_KEY_METRIC_CARD_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

enum DropdownOption {
  Product = 'stacklineSku',
  Category = 'categoryId',
  Subcategory = 'subCategoryId',
  Brand = 'brandId'
}

/**
 * Table definition by the selected dropdown
 */
const dropdownOptionToColumnDefinition = {
  [DropdownOption.Product]: ProductColumnDefinition,
  [DropdownOption.Category]: CategoryColumnDefinition,
  [DropdownOption.Subcategory]: SubcategoryColumnDefinition,
  [DropdownOption.Brand]: BrandColumnDefinition
};

/**
 * Dropdown option names
 */
const dropdownOptionToDisplayName = {
  [DropdownOption.Product]: 'Product',
  [DropdownOption.Category]: 'Category',
  [DropdownOption.Subcategory]: 'Subcategory',
  [DropdownOption.Brand]: 'Brand'
};

/**
 * Shows the dropdown grid on the summary page
 */
const SummaryKeyMetricGrid = () => {
  const [dropdownOption, setDropdownOption] = useState<DropdownOption>(DropdownOption.Product);
  const { count: productCount, isLoading: productCountLoading } = useTotalProductCount();
  const entityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const totalSubcategories = useAppSelector((state) => state.subCategories.length);
  const totalCategories = useAppSelector((state) => state.categories.length);
  const totalBrands = useAppSelector((state) => state.brandsFollowing.length);

  const rowCountByDropdownOption = {
    [DropdownOption.Product]: productCount
  };

  const loadingByDropdownOption = {
    [DropdownOption.Product]: productCountLoading
  };

  const pageSizeByDropdownOption = {
    [DropdownOption.Subcategory]: totalSubcategories,
    [DropdownOption.Category]: totalCategories,
    [DropdownOption.Brand]: totalBrands
  };

  /**
   * Dropdown options by the main entity type
   */
  const dropdownOptionsByEntityType = {
    category: [DropdownOption.Product, DropdownOption.Subcategory, DropdownOption.Brand],
    subcategory: [DropdownOption.Product, DropdownOption.Brand]
  };

  return (
    <Box sx={{ marginTop: `${BEACON_KEY_METRIC_CARD_SPACING}px` }}>
      <MetricListContainer
        loading={loadingByDropdownOption[dropdownOption]}
        rowCount={rowCountByDropdownOption[dropdownOption]}
        entityTableHeaderProps={{
          prefixTitle: 'Retail Sales by',
          title: '',
          groupByFields: (
            dropdownOptionsByEntityType[entityType] || [
              DropdownOption.Product,
              DropdownOption.Category,
              DropdownOption.Subcategory,
              DropdownOption.Brand
            ]
          ).map((option) => ({
            name: option,
            displayName: dropdownOptionToDisplayName[option]
          })),
          handleGroupByChange: (event) => setDropdownOption(event.target.value as DropdownOption)
        }}
        fields={[
          {
            name: 'retailSales'
          },
          {
            name: 'unitsSold'
          },
          {
            name: 'wholesaleSales'
          },
          {
            name: 'retailPrice'
          },
          {
            name: 'instockRate'
          },
          {
            name: 'unitsOnHand'
          }
        ]}
        indexName="sales"
        sortFieldName="retailSales"
        showGrid={dropdownOption === DropdownOption.Product}
        useTableDataProps={{
          groupByFieldName: dropdownOption,
          filterResponse: () => true,
          itemsPerPage: pageSizeByDropdownOption[dropdownOption] || 100
        }}
        headColumnDefinition={dropdownOptionToColumnDefinition[dropdownOption]}
        renderGrid={dropdownOption === DropdownOption.Product ? (args) => <ProductGrid {...args} /> : undefined}
        showSortOptions
        iconList={[IconsList.export, IconsList.tile, IconsList.table]}
      />
    </Box>
  );
};

export default SummaryKeyMetricGrid;
