/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import _isNil from 'lodash/isNil';
import _pickBy from 'lodash/pickBy';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { AD_CAMPAIGN_TYPE, AD_BUDGET_TYPE } from 'sl-ad-campaign-manager-data-model';
import axios from 'axios';
import colors from 'src/utils/colors';

import { store } from 'src/main';
import * as adBudgetEditorActions from 'src/store/modules/adManager/adBudgetEditor/actions';

import { getTimelineOpt } from 'src/store/modules/adManager/adBudgetEditor/selectors';
import {
  SectionWrapper,
  OperationButtons
} from 'src/components/AdCampaignBuilder/Widgets/AdCampaignBuilderCommonWidgets';
import ReduxStore from 'src/types/store/reduxStore';
import './BudgetEditor.scss';

// const today = moment();
const getRemoteToday = (timezone: string) => {
  return moment().tz(timezone).format('YYYY-MM-DD');
};

const endOfCurrentMonth = moment().endOf('month').format('YYYY-MM-DD');
const startOfNextMonth = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD');
const endOfNextMonth = moment().add(1, 'M').endOf('month').format('YYYY-MM-DD');

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, ['adBudgetEditor']),
    timelineOpt: getTimelineOpt()
  };
};

const Table = ({ rows }) => {
  const remakeRows = {
    Campaign: {
      oldSum: 0,
      newSum: 0,
      rows: []
    },
    Portfolio: {
      oldSum: 0,
      newSum: 0,
      rows: []
    },
    Entity: {
      oldSum: 0,
      newSum: 0,
      rows: []
    }
  };

  rows.forEach((row) => {
    remakeRows[row.type].oldSum += +row.oldBudget || 0;
    remakeRows[row.type].newSum += +row.newBudget || 0;
    remakeRows[row.type].rows.push(row);
  });

  const rowToRender = [remakeRows.Entity, remakeRows.Portfolio, remakeRows.Campaign];

  return (
    <>
      {rowToRender.map((item, idx) => {
        if (item.rows.length === 0) {
          return null;
        }
        return (
          <div key={idx}>
            <div
              className="summary_row"
              style={{
                fontWeight: 500,
                display: 'flex',
                height: 30,
                alignItems: 'center',
                borderBottom: `1px solid ${colors.lightestGrey}`
              }}
            >
              <div className="name" style={{ flex: 4, fontWeight: 'inherit' }}>
                {item.rows[0].type}
              </div>
              <div className="type" style={{ flex: 1, fontWeight: 'inherit' }}>
                Type
              </div>
              <div className="oldValue" style={{ flex: 1, fontWeight: 'inherit' }}>
                Current Budget
              </div>
              <div className="newValue" style={{ flex: 1, fontWeight: 'inherit' }}>
                New Monthly Budget
              </div>
            </div>
            {item.rows.map((row, ridx) => {
              return (
                <div
                  key={ridx}
                  className="summary_row"
                  style={{
                    display: 'flex',
                    height: 30,
                    alignItems: 'center',
                    borderTop: `1px solid ${colors.lightestGrey}`
                  }}
                >
                  <div className="name" style={{ flex: 4 }}>
                    {row.name}
                  </div>
                  <div className="type" style={{ flex: 1 }}>
                    {row.type}
                  </div>
                  <div className="oldValue" style={{ flex: 1 }}>
                    {row.oldBudget === 'Not Set' ? row.oldBudget : numeral(+row.oldBudget).format('$1,000')}
                  </div>
                  <div className="newValue" style={{ flex: 1 }}>
                    {row.newBudget === 'Not Set' ? row.newBudget : numeral(+row.newBudget).format('$1,000')}
                  </div>
                </div>
              );
            })}

            {item.rows[0].type !== 'Entity' && (
              <div
                className="summary_row"
                style={{
                  fontWeight: 500,
                  display: 'flex',
                  height: 30,
                  alignItems: 'center',
                  borderBottom: `1px solid ${colors.lightestGrey}`
                }}
              >
                <div className="name" style={{ flex: 4, fontWeight: 'inherit' }}>
                  Total
                </div>
                <div className="type" style={{ flex: 1, fontWeight: 'inherit' }}></div>
                <div className="oldValue" style={{ flex: 1, fontWeight: 'inherit' }}>
                  {numeral(+item.oldSum).format('$1,000')}
                </div>
                <div className="newValue" style={{ flex: 1, fontWeight: 'inherit' }}>
                  {numeral(+item.newSum).format('$1,000')}
                </div>
              </div>
            )}
            <br></br>
          </div>
        );
      })}
    </>
  );
};

const BudgetConfirmation: React.FC<ReturnType<typeof mapStateToProps>> = ({ adBudgetEditor }) => {
  const { timeline, endMonth } = adBudgetEditor;

  const formatAndSubmitData = () => {
    const { entity } = adBudgetEditor.entity;
    const timelineName = timeline.name;
    const { timeZone } = adBudgetEditor.platform;
    const totalAmount = adBudgetEditor.entityBudget;
    const SUBMIT_API = '/apiAdManager/adUpdateRequests/bulkBudgetUpdate';

    const bulkBudgetUpdateRequest = {
      beaconClientId: entity.beaconClientId,
      platformType: entity.platformType,
      updateType: 'bulkBudgetUpdate',
      extendedAttributes: {
        adEntitiesBudgetSetting: [
          {
            beaconClientLoginId: entity.extendedAttributes.beaconClientLoginId,
            entityId: entity.extendedAttributes.entityId,
            entityIdApi: entity.extendedAttributes.entityIdApi,
            entityIdUi: entity.extendedAttributes.entityIdUi,
            [`${timelineName}`]: {
              amount: totalAmount,
              budgetType: AD_BUDGET_TYPE.MONTHLY
            }
          }
        ],
        adPortfoliosBudgetSetting: [] as {
          beaconClientLoginId: number;
          entityId: string;
          entityIdApi: string;
          entityIdUi: string;
          portfolioId: string;
          portfolioIdApi: string;
          portfolioIdUi?: string;
          currentMonthBudgetSetting?: {
            amount: number;
            startDate?: Date;
            endDate?: Date;
            budgetType: AD_BUDGET_TYPE.MONTHLY;
          };
          nextMonthBudgetSetting?: {
            amount: number;
            startDate?: Date;
            endDate?: Date;
            budgetType: AD_BUDGET_TYPE.MONTHLY;
          };
        }[],
        adCampaignsBudgetSetting: [] as {
          beaconClientLoginId: number;
          entityId: string;
          entityIdApi: string;
          entityIdUi: string;
          portfolioId: string;
          portfolioIdApi: string;
          portfolioIdUi?: string;
          campaignId: string;
          campaignIdApi: string;
          campaignIdUi?: string;
          campaignType: AD_CAMPAIGN_TYPE;
          campaignTypeApi: string;
          currentMonthBudgetSetting?: {
            amount: number;
            startDate?: Date;
            endDate?: Date;
            budgetType: 'daily';
          };
          nextMonthBudgetSetting?: {
            amount: number;
            startDate?: Date;
            endDate?: Date;
            budgetType: 'daily';
          };
        }[]
      }
    };

    adBudgetEditor.portfolioArray.forEach(({ entity: portfolio }) => {
      const dateOverwrite = {
        endDate: timeline.id === 0 ? endOfCurrentMonth : endOfNextMonth
      };
      if (endMonth && endMonth.year && endMonth.month) {
        dateOverwrite.endDate = moment([endMonth.year, endMonth.month - 1])
          .endOf('month')
          .format('YYYY-MM-DD');
      }
      if (timeline.id !== 0) {
        dateOverwrite.startDate = startOfNextMonth;
      }

      if (timeline.id === 0) {
        const oldStartDate = _get(
          portfolio,
          ['extendedAttributes', 'currentMonthBudgetSetting', 'startDate'],
          undefined
        );
        if (!oldStartDate) {
          const remoteToday = getRemoteToday(timeZone);
          dateOverwrite.startDate = remoteToday;
        } else {
          dateOverwrite.startDate = undefined;
        }
      }

      const newBudgetAmount = _get(portfolio, ['extendedAttributes', 'newBudgetSetting', 'amount'], undefined);
      const isSelected = _get(portfolio, ['extendedAttributes', 'isSelected']);
      if (!portfolio.isUnassigned && isSelected && newBudgetAmount !== undefined) {
        const {
          extendedAttributes: {
            beaconClientLoginId,
            entityId,
            entityIdApi,
            entityIdUi,
            portfolioId,
            portfolioIdApi,
            portfolioIdUi,
            currentMonthBudgetSetting
          }
        } = portfolio;
        bulkBudgetUpdateRequest.extendedAttributes.adPortfoliosBudgetSetting.push({
          beaconClientLoginId,
          entityId,
          entityIdApi,
          entityIdUi,
          portfolioId,
          portfolioIdApi,
          portfolioIdUi,
          [`${timelineName}`]: _isNil(newBudgetAmount)
            ? null
            : _pickBy(
                {
                  ...currentMonthBudgetSetting,
                  ...dateOverwrite,
                  amount: newBudgetAmount,
                  budgetType: AD_BUDGET_TYPE.MONTHLY_RECURRING
                },
                (item) => item !== undefined
              )
        });
      }
    });
    adBudgetEditor.campaignArray.forEach(({ entity: campaign }) => {
      const oldBudgetAmount = _get(campaign, ['extendedAttributes', timeline.name, 'amount'], undefined);
      const newBudgetAmount = _get(campaign, ['extendedAttributes', 'newBudgetSetting', 'amount'], undefined);
      if (oldBudgetAmount !== newBudgetAmount && newBudgetAmount !== undefined) {
        const {
          extendedAttributes: {
            beaconClientLoginId,
            entityId,
            entityIdApi,
            entityIdUi,
            portfolioId,
            portfolioIdApi,
            portfolioIdUi,
            campaignId,
            campaignIdApi,
            campaignIdUi,
            campaignType,
            campaignTypeApi,
            currentMonthBudgetSetting
          }
        } = campaign;
        bulkBudgetUpdateRequest.extendedAttributes.adCampaignsBudgetSetting.push({
          beaconClientLoginId,
          entityId,
          entityIdApi,
          entityIdUi,
          portfolioId,
          portfolioIdApi,
          portfolioIdUi,
          campaignId,
          campaignIdApi,
          campaignIdUi,
          campaignType,
          campaignTypeApi,
          [`${timelineName}`]: { ...currentMonthBudgetSetting, amount: newBudgetAmount }
        });
      }
    });
    return axios.post(SUBMIT_API, bulkBudgetUpdateRequest);
  };

  if (_isEmpty(adBudgetEditor.platform)) {
    return null;
  }
  const mtdAdSpend = _get(adBudgetEditor, ['entity', 'spend', 'value'], 0);

  const formatTable = () => {
    const { entity, campaignArray, portfolioArray, entityBudget } = adBudgetEditor;
    // const timelineName = timeline.name;
    const result = [];
    result.push({
      name: _get(entity, ['entity', 'extendedAttributes', 'name']),
      type: 'Entity',
      oldBudget: _get(entity, ['entity', 'extendedAttributes', 'currentMonthBudgetSetting', 'amount']),
      newBudget: entityBudget
    });

    const allSelectedPortfolio = portfolioArray.filter((item) => {
      return !!_get(item, ['entity', 'extendedAttributes', 'isSelected']);
    });

    allSelectedPortfolio.forEach((item) => {
      if (_get(item, ['entity', 'extendedAttributes', 'newBudgetSetting', 'amount'], 'Not Set') !== 'Not Set') {
        result.push({
          name: _get(item, ['entity', 'extendedAttributes', 'name']),
          type: 'Portfolio',
          oldBudget: _get(item, ['entity', 'extendedAttributes', 'currentMonthBudgetSetting', 'amount'], 'Not Set'),
          newBudget: _get(item, ['entity', 'extendedAttributes', 'newBudgetSetting', 'amount'], 'Not Set')
        });
      }
    });
    campaignArray.forEach(({ entity: campaign }) => {
      const oldCompareBudget = _get(campaign, ['extendedAttributes', timeline.name, 'amount'], 'Not Set');
      const oldBudgetAmount = _get(campaign, ['extendedAttributes', 'currentMonthBudgetSetting', 'amount'], 'Not Set');
      const newBudgetAmount = _get(campaign, ['extendedAttributes', 'newBudgetSetting', 'amount'], 'Not Set');
      if (oldCompareBudget !== newBudgetAmount && newBudgetAmount !== 'Not Set') {
        result.push({
          name: _get(campaign, ['name']),
          type: 'Campaign',
          oldBudget: oldBudgetAmount,
          newBudget: newBudgetAmount
        });
      }
    });
    return result;
  };

  const summaryTable = formatTable();
  let periodDisplay = '';
  if (endMonth && endMonth.year && endMonth.month) {
    periodDisplay = moment([endMonth.year, endMonth.month - 1]).format('MMMM YYYY');
  }
  return (
    <div className="ad-manager-container">
      <OperationButtons
        subtab="confirmation"
        onSubmit={async () => {
          const res = await formatAndSubmitData();
          store.dispatch(
            adBudgetEditorActions.replaceState({
              ...adBudgetEditor,
              apiResponse: res
            })
          );
        }}
        customFlow={adBudgetEditor.flow}
      />
      <SectionWrapper header="Confirm Budget" subheader="Review and confirm details below before submitting." layer={0}>
        <div className="platform-select">
          <div className="amount_container">
            <div className="amount_row">
              <div className="disc_side">Platform:</div>
              <div className="amount_side">{adBudgetEditor.platform.platformName}</div>
            </div>
            <div className="amount_row">
              <div className="disc_side">Entity:</div>
              <div className="amount_side">{adBudgetEditor.entity.entity.displayName}</div>
            </div>
            <div className="amount_row">
              <div className="disc_side">Budget Time Period:</div>
              <div className="amount_side">
                {timeline.displayName}
                {periodDisplay === timeline.displayName ? '' : !periodDisplay ? '' : ` - ${periodDisplay}`}
              </div>
            </div>
            <div className="amount_row">
              <div className="disc_side">New Monthly Budget:</div>
              <div className="amount_side">{numeral(adBudgetEditor.entityBudget).format('$1,000')}</div>
            </div>
            {timeline.id === 0 && (
              <div className="amount_row">
                <div className="disc_side">MTD Ad Spend:</div>
                <div className="amount_side">{numeral(mtdAdSpend).format('$1,000')}</div>
              </div>
            )}
            {timeline.id === 0 && (
              <div className="amount_row">
                <div className="disc_side">Remaining Budget:</div>
                <div className="amount_side">{numeral(+adBudgetEditor.entityBudget - mtdAdSpend).format('$1,000')}</div>
              </div>
            )}
            <div>
              <div className="amount_row">
                <div className="disc_side">Summary of changes:</div>
              </div>
              <Table rows={summaryTable} />
            </div>
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};

const EnhancedBudgetConfirmation = connect(mapStateToProps)(BudgetConfirmation);

export default EnhancedBudgetConfirmation;
