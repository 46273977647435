import React, { useState } from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { hexToRgba, useStacklineTheme } from '@stackline/ui';
import { Text } from '../../Generic/Text';
import _sortBy from 'lodash/sortBy';
import { SxProps } from '@mui/system';
import mergeSx from 'src/utils/mergeSx';

export interface SlDropdownMenuOption {
  /**
   * Unique identifier of the menu option
   */
  id: string;
  /**
   * Label to apply to the dropdown option
   */
  label: string;
  /**
   * Boolean to gray out/disable the option
   */
  disabled?: boolean;
}

export interface SlMenuProps extends Omit<MenuProps, 'onChange'> {
  /**
   * List of options to display
   */
  options: SlDropdownMenuOption[];
  /**
   * ID of the selected item, or null if nothing
   * is selected
   */
  selectedId: string | null;
  /**
   * Callback for when an item is selected
   */
  onChange: (option: SlDropdownMenuOption) => void;
  /**
   * If set, we will show n number of options
   * with a "+ View More" option at the bottom
   */
  amountOfOptionsToShow?: number;
  /**
   * Optional styles to apply to the dropdown menu items text component
   */
  menuItemTextStyles?: SxProps;
}

export const SlMenu = ({
  options,
  onChange,
  selectedId,
  onClose,
  amountOfOptionsToShow,
  menuItemTextStyles,
  sx,
  ...rest
}: SlMenuProps) => {
  const theme = useStacklineTheme();
  const hoverBgColor = hexToRgba(theme.colors.secondaryPorcelain, 1);
  const selectedBackground = hexToRgba(theme.colors.primaryLight, 0.3);

  const [showAll, setShowAll] = useState(!amountOfOptionsToShow);

  const sortedOptions = _sortBy(options, (o) => (o.id === selectedId ? 0 : 1));

  return (
    <Menu
      // Default styles -- can be overridden via SlMenuProps
      sx={mergeSx(
        {
          marginTop: '8px',
          width: '100%',
          padding: 0,
          boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.15)',
          '& ul': {
            paddingY: 0,
            paddingRight: 0 // Try setting the right padding to 0 to remove the mystery padding
          }
        },
        sx
      )}
      onClose={(...args) => {
        setShowAll(false);
        onClose(...args);
      }}
      {...rest}
    >
      {(showAll ? sortedOptions : sortedOptions.slice(0, amountOfOptionsToShow)).map((option, index) => {
        const isSelected = selectedId === option.id;
        const isDisabled = !!option.disabled;
        return (
          <MenuItem
            key={`retailer:${option.id}`}
            disabled={isDisabled}
            sx={{
              '&:hover': {
                backgroundColor: isSelected ? selectedBackground : hoverBgColor
              },
              backgroundColor: isSelected ? selectedBackground : undefined,
              borderBottom: index !== options.length - 1 ? `1px solid ${theme.colors.primaryGray}` : undefined,
              width: '100%',
              '&.MuiMenuItem-root': {
                paddingLeft: '12px',
                paddingRight: '24px'
              }
            }}
            onClick={() => {
              onChange(option);
              if (onClose) {
                onClose({}, 'backdropClick');
              }
            }}
          >
            <Text
              color={isDisabled ? 'secondary' : undefined}
              sx={{
                textOverflow: 'ellipsis',
                width: '100%',
                maxWidth: '100%',
                overflowX: 'hidden',
                ...menuItemTextStyles
              }}
              variant="subtitle2"
            >
              {option.label}
            </Text>
          </MenuItem>
        );
      })}
      {!showAll && amountOfOptionsToShow && options.length > amountOfOptionsToShow ? (
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: selectedBackground
            },
            width: '100%',
            '.MuiMenuItem-root': {
              paddingLeft: '12px',
              paddingRight: '24px'
            }
          }}
          onClick={() => {
            setShowAll(true);
          }}
        >
          <Text sx={{ ...menuItemTextStyles }} variant="subtitle2">
            + View More
          </Text>
        </MenuItem>
      ) : null}
    </Menu>
  );
};
