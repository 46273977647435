import React from 'react';
/* eslint-disable react/prop-types */

const ExcludeButton = ({ onClick }) => {
  return (
    <button className="exclude-btn" onClick={onClick}>
      Exclude
    </button>
  );
};

export default ExcludeButton;
