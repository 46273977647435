import { useState, useEffect, useCallback } from 'react';
import * as serverProxy from 'src/routes/UserAccount/UserTeamManagement/serverProxy';
import { useAppSelector } from 'src/utils/Hooks';

/**
 * Handles state for fetching approved domains
 */
export default function useApprovedDomains() {
  const currentUserEmail = useAppSelector((state) => state.user.config.profile.email);
  const [approvedDomains, setApprovedDomains] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchApprovedDomains = useCallback(async () => {
    setLoading(true);
    const domains = await serverProxy.GetUserDivisionAccountConfig(currentUserEmail);
    setApprovedDomains(domains);
    setLoading(false);
  }, [currentUserEmail]);

  useEffect(() => {
    fetchApprovedDomains();
  }, [fetchApprovedDomains]);

  return {
    approvedDomains,
    fetchApprovedDomains,
    loading
  };
}
