import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _prop from 'lodash/property';
import _get from 'lodash/get';
import Masonry from 'react-masonry-component';
import Waypoint from 'react-waypoint';

import { entitySearchServiceOperations } from 'src/store/modules/entitySearchService';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { anyNotEq } from 'src/utils/equality';
import { propEq } from 'src/utils/fp';
import TabsWidget from 'src/components/common/Tabs/TabsWidget';
import EntityGridHeader from '../Header/EntityGridHeader';
import TileContainer from '../Tiles/TileContainer';
import Loading from '../../common/Loading';
import ReviewTile from '../../ReviewTile';
import { ListGrid } from '../../Grids';

import './EntityTableContainer.scss';
import { parseReviewsMetrics } from 'src/store/modules/entitySearchService/selectors';

const REPLY_STATUS_TABS = [
  { displayName: 'All', value: 'none' },
  { displayName: 'Replied', value: 'replied' },
  { displayName: 'Unreplied', value: 'unreplied' }
];

const ReplyStatusTabs = ({ value, onChange }) => (
  <TabsWidget
    widget={{
      view: {
        tabs: REPLY_STATUS_TABS,
        tabStyle: { fontSize: 20, padding: 10, fontWeight: 400 },
        tabRootStyle: { border: 'none' },
        value: REPLY_STATUS_TABS.findIndex(propEq('value', value)),
        onChange
      }
    }}
  />
);

ReplyStatusTabs.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

class ReviewsGridContainer extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    dataConfig: PropTypes.object.isRequired,
    entitySearchService: PropTypes.object.isRequired,
    fetchEntityMetrics: PropTypes.func.isRequired,
    keywords: PropTypes.array,
    keyWordQueryObj: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    queryConditions: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    reviewStars: PropTypes.array.isRequired,
    selectedEntity: PropTypes.object.isRequired,
    widget: PropTypes.object.isRequired,
    selectedHighRiskWeekId: PropTypes.number
  };

  static defaultProps = {
    selectedHighRiskWeekId: null,
    keywords: []
  };

  constructor(props) {
    super(props);
    const { app, dataConfig } = props;

    this.state = {
      groupByField: {
        ...INDEX_FIELDS.getField(app.name, dataConfig.indexName, 'stars'),
        displayName: 'Reviews'
      },
      groupByFields: [
        {
          ...INDEX_FIELDS.getField(app.name, dataConfig.indexName, 'stars'),
          displayName: 'Reviews'
        },
        INDEX_FIELDS.getField(app.name, dataConfig.indexName, 'stacklineSku')
      ],
      reviewsPageNumber: 1,
      productsPageNumber: 1,
      replyStatusFilter: 'none'
    };
  }

  componentDidMount() {
    this.fetchReviewDetails(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (anyNotEq(['reviewStars', 'keywords', 'selectedHighRiskWeekId', 'mainTimePeriod'], this.props, nextProps)) {
      this.setState({ reviewsPageNumber: 1 }, () => this.fetchReviewDetails(nextProps));
    }
  }

  handleGroupByChange = (evt) => {
    const { value } = evt.target;
    const { groupByFields } = this.state;
    groupByFields.forEach((metricsField) => {
      metricsField.isSelected = metricsField.name === value;
    });
    const groupByField = groupByFields.filter((metric) => metric.isSelected)[0];
    this.setState({ groupByField });
  };

  handleWaypointEntered = () => {
    const { groupByField, isLoading } = this.state;
    const selectedPageNumber = groupByField.name === 'stacklineSku' ? 'productsPageNumber' : 'reviewsPageNumber';
    if (!isLoading) {
      this.setState((prevState) => ({ [selectedPageNumber]: prevState[selectedPageNumber] + 1 }));
      this.fetchReviewDetails(this.props);
    }
  };

  fetchReviewDetails = (
    {
      app,
      comparisonTimePeriod,
      retailer,
      fetchEntityMetrics,
      keywords,
      keyWordQueryObj,
      mainTimePeriod,
      queryConditions,
      selectedEntity,
      selectedHighRiskWeekId,
      reviewStars
    } = this.props
  ) => {
    const clonedConditions = _cloneDeep(queryConditions);
    const selectedRangeFilters = {
      fieldName: 'weekId',
      minValue: mainTimePeriod.startWeek,
      maxValue: mainTimePeriod.endWeek
    };

    if (keywords && keywords.length > 0) {
      clonedConditions.termFilters.push(keyWordQueryObj);
    }
    if (selectedHighRiskWeekId !== null) {
      selectedRangeFilters.minValue = selectedHighRiskWeekId;
      selectedRangeFilters.maxValue = selectedHighRiskWeekId;
    }
    const productsConditions = {
      termFilters: [
        ...clonedConditions.termFilters,
        {
          fieldName: 'stars',
          values: reviewStars
        }
      ],
      rangeFilters: [selectedRangeFilters]
    };

    const reviewsConditions = _cloneDeep(productsConditions);

    // Reply status filter only applies when showing individual reviews
    if (this.state.replyStatusFilter !== 'none') {
      reviewsConditions.termFilters.push({
        fieldName: 'hasManufacturerResponded',
        condition: this.state.replyStatusFilter === 'replied' ? 'must' : 'must_not',
        values: [true]
      });
    }

    const { reviewsPageNumber, productsPageNumber } = this.state;
    const requestReviewOverrides = {
      additionalFieldsToReturn: [],
      aggregations: null,
      doAggregation: false,
      conditions: reviewsConditions,
      pageNumber: reviewsPageNumber,
      pageSize: 20,
      returnDocuments: true,
      searchBy: 'child',
      period: comparisonTimePeriod.id.split('-')[1],
      sortFilter: {
        sortFields: [
          {
            fieldName: 'timestamp'
          },
          {
            fieldName: 'stars'
          }
        ]
      }
    };

    const requestProductOverrides = {
      pageNumber: productsPageNumber,
      pageSize: 200,
      period: comparisonTimePeriod.id.split('-')[1],
      doAggregation: true,
      additionalFieldsToReturn: [],
      conditions: productsConditions,
      aggregations: [
        {
          groupByFieldName: 'stacklineSku',
          aggregationFields: [{ aggregateByFieldName: 'stars', function: 'sum' }],
          sortDirection: null,
          sortByAggregationField: null
        }
      ],
      sortFilter: { sortFields: [{ fieldName: 'timestamp' }, { fieldName: 'stars' }] }
    };

    this.setState({ isLoading: true });
    const promises = [];
    promises.push(
      fetchEntityMetrics(
        'reviewsGridDocuments',
        { entity: selectedEntity, retailer, app, indexName: 'reviews', customResponseParser: parseReviewsMetrics },
        [requestReviewOverrides]
      ),
      fetchEntityMetrics(
        'reviewsGridProducts',
        { entity: selectedEntity, retailer, app, indexName: 'reviews', customResponseParser: parseReviewsMetrics },
        [requestProductOverrides]
      )
    );

    Promise.all(promises)
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (!err.message.includes('Canceled network request')) {
          console.error(err);
        }
        this.setState({ isLoading: false });
      });
  };

  handleReplyStatusFilterChange = (val) => {
    if (this.state.replyStatusFilter === val) {
      return;
    }

    this.setState(
      {
        replyStatusFilter: val,
        reviewsPageNumber: 1,
        productsPageNumber: 1
      },
      () => {
        this.fetchReviewDetails();
      }
    );
  };

  render() {
    const { app, entitySearchService, widget } = this.props;
    const { groupByField, groupByFields, isLoading } = this.state;
    const entitySearchServicePropertyName =
      groupByField.name === 'stacklineSku' ? 'reviewsGridProducts' : 'reviewsGridDocuments';
    const dataToRender =
      !_isEmpty(entitySearchService) && !_isEmpty(entitySearchService[`${entitySearchServicePropertyName}`])
        ? entitySearchService[`${entitySearchServicePropertyName}`]
        : [];

    const apiRequest = _get(entitySearchService, `${entitySearchServicePropertyName}.apiRequest`, []);

    return (
      <div className="reviews-grid-container" style={{ marginBottom: 50 }}>
        <EntityGridHeader
          hideTitle={this.state.groupByField.name === 'stars'}
          title="Reviews"
          app={app}
          enableSwitchingLayouts={false}
          enableGroupBy
          exportRequest={apiRequest}
          groupByFields={groupByFields}
          handleGroupByChange={this.handleGroupByChange}
          exportFileName={`${app.name}_reviews_download.tsv`}
          style={{ marginTop: 70 }}
        >
          {this.state.groupByField.name === 'stars' ? (
            <ReplyStatusTabs value={this.state.replyStatusFilter} onChange={this.handleReplyStatusFilterChange} />
          ) : null}
        </EntityGridHeader>

        {isLoading && dataToRender.length === 0 ? (
          <div className="spinner-wrapper">
            <Loading className="spinner__table--entity" />
          </div>
        ) : null}
        {!isLoading && dataToRender.length === 0 ? (
          <div className="grid__col grid__col--12-of-12">
            <div className="no-results">
              <div>
                <div className="sl-no-data">{widget.view.gridOptions.noDataAvailableMessage}</div>
              </div>
            </div>
          </div>
        ) : null}

        {dataToRender.length > 0 ? (
          <div className="entityGrid" style={{ marginBottom: 40 }}>
            {groupByField.name === 'stars' ? (
              <>
                <Masonry
                  options={{
                    itemSelector: '.reviews__grid-item',
                    columnWidth: '.reviews__grid-sizer',
                    percentPosition: true
                  }}
                >
                  {dataToRender.filter(_prop('product')).map((review, idx) => (
                    <div key={`${review.threadId}-${review.timestamp}-${idx}`}>
                      <div className="reviews__grid-sizer" />
                      <div className="reviews__grid-item">
                        <ReviewTile review={review} />
                      </div>
                    </div>
                  ))}
                </Masonry>
                <div style={{ paddingBottom: 30, paddingTop: 30 }}>
                  <Waypoint onEnter={this.handleWaypointEntered} />
                  <br />
                  <br />
                </div>
              </>
            ) : null}

            {groupByField.name === 'stacklineSku' ? (
              <ListGrid className="entityGrid-list-grid" onWaypointEntered={this.handleWaypointEntered}>
                {dataToRender.map((item) => (
                  <TileContainer
                    key={item.fieldId}
                    dataItem={item}
                    showStatusBar={false}
                    isFlippable={false}
                    isPromotionsPage
                  />
                ))}
              </ListGrid>
            ) : null}
            {isLoading ? (
              <div className="spinner-wrapper">
                <Loading className="spinner__table--entity" />{' '}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, ['app', 'comparisonTimePeriod', 'entitySearchService', 'filters', 'mainTimePeriod', 'retailer']);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewsGridContainer));
