/**
 * Allows users to export the list of distinct products that exist within a provided saved search, optinally providing
 * a different saved search as a filter.  Downloads the product list as a CSV of `stacklineSku`s.
 */

/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import { Option } from 'funfix-core';
import saveAs from 'file-saver';
import { SavedSearch, Conditions, BusinessUnit, AppName, RangeFilter } from 'sl-api-connector/types';

import { store } from 'src/main';
import ReduxStore from 'src/types/store/reduxStore';
import SegmentDropdown from 'src/components/SegmentPowerTools/SegmentDropdown';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { panic, error } from 'src/utils/mixpanel';
import { buildConditionsForBU } from 'src/components/EntityPage/Filters/businessUnitFilters';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { buildAggregations } from 'src/components/AdManager/Search';
import { prop } from 'src/utils/fp';
import colors from 'src/utils/colors';

const fetchData = async (
  { segments, retailer, mainTimePeriod, app }: Pick<ReduxStore, 'segments' | 'retailer' | 'mainTimePeriod' | 'app'>,
  mainSavedSearch: SavedSearch,
  filterSavedSearch: SavedSearch | null
) => {
  const mainConditions: Conditions =
    mainSavedSearch.type === 'segment'
      ? mainSavedSearch.conditions
      : buildConditionsForBU(segments.savedSearchesById!, () => true, mainSavedSearch as BusinessUnit, false);
  const conditions: Conditions = filterSavedSearch
    ? {
        condition: 'must',
        termFilters: [],
        rangeFilters: [],
        nestedFilterConditions: [
          mainConditions,
          filterSavedSearch.type === 'segment'
            ? filterSavedSearch.conditions
            : buildConditionsForBU(segments.savedSearchesById!, () => true, filterSavedSearch as BusinessUnit, false)
        ]
      }
    : mainConditions;
  if (!conditions.rangeFilters) {
    conditions.rangeFilters = [];
  }
  conditions.rangeFilters.push({
    fieldName: 'retailSales',
    minValue: 1
  });

  const metricFields = [INDEX_FIELDS.getField(app.name, 'sales', 'retailSales')];
  const [{ aggregations: aggregationFields }] = buildAggregations(metricFields);
  const groupByFieldName = 'stacklineSku';
  const timePeriodRangeFilter: RangeFilter = {
    fieldName: 'weekId',
    minValue: mainTimePeriod.startWeek,
    maxValue: mainTimePeriod.endWeek
  };

  return store.dispatch(
    fetchEntityMetrics(
      'savedSearchProductExport',
      {
        entity: { type: 'whoecares', id: 'doesntmatter' },
        retailer,
        app,
        indexName: 'sales'
      },
      [
        {
          doAggregation: true,
          aggregations: [
            {
              aggregationFields,
              conditions: {
                termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
                rangeFilters: [timePeriodRangeFilter]
              },
              groupByFieldName
            }
          ],
          conditions,
          pageSize: 10e7,
          additionalRequestMetaData: {
            returnAdditionalMetaData: false
          },
          processDocuments: true
        }
      ],
      undefined,
      true
    )
  );
};

/**
 * Returns a list of `stacklineSku`s for each product matching the query.
 */
const parseDataSet = (dataSet: any): string[] => {
  const dataPoints = dataSet.retailSales_by_stacklineSku.data;
  return dataPoints.map(prop('name'));
};

const downloadParsedDataSet = (stacklineSkus: string[]) => {
  const csvData = `Stackline SKU\n${stacklineSkus.join('\n')}`;
  const blob = new Blob([csvData], { type: 'text/plain;charset=utf-8' });
  (saveAs as any)(blob, 'stacklineSku-listing.csv');
};

const mapStateToProps = (state: ReduxStore) => _pick(state, ['mainTimePeriod', 'segments', 'retailer', 'app']);

const SavedSearchProductExport: React.FC<{} & ReturnType<typeof mapStateToProps>> = ({
  mainTimePeriod,
  segments,
  retailer,
  app
}) => {
  const [mainSavedSearchId, setMainSavedSearchId] = useState<string | null>(null);
  const [filterSavedSearchId, setFilterSavedSearchId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  if (!segments.savedSearchesById) {
    return <GridLoading />;
  }

  if (app.name !== AppName.Atlas) {
    return <p>Saved Search Product Export only available on Atlas.</p>;
  }

  return (
    <div>
      <h2>Export Product List for Saved Search</h2>
      <p>
        This tool allows you to export the full list of products that exist within a segment or business unit while
        optionally using a second segment or business unit as a filter. It will use the `mainTimePeriod` provided at the
        top of the page as a bound.
      </p>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <h3>Primary Saved Search</h3>
          <SegmentDropdown
            allowCreateNew={false}
            includeBusinessUnits
            value={mainSavedSearchId}
            onChange={setMainSavedSearchId}
          />
        </div>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <h3>Filter Saved Search (Optional)</h3>
          <SegmentDropdown
            allowCreateNew={false}
            includeBusinessUnits
            value={filterSavedSearchId}
            onChange={setFilterSavedSearchId}
          />
        </div>
      </div>

      <LargeMuiButton
        disabled={!mainSavedSearchId || isLoading}
        label={isLoading ? 'Working...' : 'Perform Query'}
        onClick={async () => {
          const mainSavedSearch = segments.savedSearchesById!.get(mainSavedSearchId!);
          if (!mainSavedSearch) {
            throw panic(`No entry in \`savedSearchesById\` for id "${mainSavedSearchId}"`);
          }

          const filterSavedSearch = Option.of(filterSavedSearchId)
            // eslint-disable-next-line no-shadow
            .map((filterSavedSearchId) => {
              // eslint-disable-next-line no-shadow
              const filterSavedSearch = segments.savedSearchesById!.get(filterSavedSearchId);
              if (!filterSavedSearch) {
                throw panic(`No entry in \`savedSearchesById\` for id "${filterSavedSearchId}"`);
              }
              return filterSavedSearch;
            })
            .orNull();

          setIsLoading(true);
          setErrorText('');
          try {
            const dataSet = await fetchData(
              { segments, retailer, mainTimePeriod, app },
              mainSavedSearch,
              filterSavedSearch
            );
            const parsedDataSet = parseDataSet(dataSet);
            downloadParsedDataSet(parsedDataSet);
          } catch (err) {
            setErrorText('Error performing request to the search service.  No matches found?');
            error('Error fetching saved search product export data', { err });
          } finally {
            setIsLoading(false);
          }
        }}
        style={{ marginTop: 14 }}
      />
      <span style={{ color: colors.red }}>{errorText}</span>
    </div>
  );
};

export default connect(mapStateToProps)(SavedSearchProductExport);
