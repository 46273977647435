/* eslint react/prop-types: 0 */
import React from 'react';
import OmniSelector from 'src/components/Omni/Search/OmniSelector';
import { SegmentState, KeyWordSegment, StoreSegment, generateQueryParamObj } from 'src/components/Omni/OmniSegmentUtil';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { RouteComponentProps } from 'react-router-dom';
import { addPersistentQueryParams } from 'src/utils/browser';
import queryString from 'qs';
import { getRetailerIdDisplayName } from 'src/utils/stringFormatting';

const selectorsTypeMapping = {
  country: {
    title: 'Countries',
    hintText: 'Refine by countries',
    idKey: 'countryCode',
    nameField: 'countryName',
    include: 'include',
    exclude: 'exclude',
    keyForSegment: 'LocationCountryCode',
    rowListDataKey: 'omniCountriesFollowing',
    showIncludeExclude: false
  },
  retailer: {
    title: 'Retailers',
    hintText: 'Refine by retailers',
    idKey: 'retailerId',
    nameField: 'retailerName',
    include: '',
    exclude: '',
    keyForSegment: 'retailerIds',
    rowListDataKey: 'omniRetailers',
    showIncludeExclude: false
  },
  brand: {
    title: 'Brands',
    hintText: 'Refine by brands',
    idKey: 'brandId',
    nameField: 'brandName',
    include: 'include',
    exclude: 'exclude',
    keyForSegment: 'BrandIds',
    rowListDataKey: 'omniBrandsFollowing',
    showIncludeExclude: true
  },
  category: {
    title: 'Categories',
    hintText: 'Refine by categories',
    idKey: 'categoryId', // use for get the id value from redux
    nameField: 'categoryName', // use for get the name of the category from redux
    include: 'include',
    exclude: 'exclude',
    keyForSegment: 'CategoryIds', // use for get the data from the include or exclude
    rowListDataKey: 'omniCategories', // use for get data from the redux
    showIncludeExclude: true
  },
  subCategory: {
    title: 'Subcategories',
    hintText: 'Refine by subcategories',
    idKey: 'subCategoryId',
    nameField: 'subCategoryName',
    include: 'include',
    exclude: 'exclude',
    keyForSegment: 'SubCategoryIds',
    rowListDataKey: 'omniSubcategories',
    showIncludeExclude: true
  }
};

export interface OmniIncludeExcludeFuncWrapperProps {
  parsedState: SegmentState | KeyWordSegment | StoreSegment;
  selectorType: string;
  retailer: ReduxStore['retailer'];
  mainTimePeriod: ReduxStore['mainTimePeriod'];
  history: RouteComponentProps['history'];
  handleChange: (newChange: { key: string; values: { i: string }[] }) => void;
  nameForSegment: string;
  navEndPoint: string;
  handleRequestChange?: (
    newReqContent: { [key: string]: any },
    pageInfo: { pageNumber: number; pageSize: number }
  ) => void;
}

const OmniSelectorWrapper: React.FC<OmniIncludeExcludeFuncWrapperProps> = ({
  parsedState,
  selectorType,
  history,
  retailer,
  mainTimePeriod,
  handleChange,
  nameForSegment,
  navEndPoint,
  handleRequestChange = () => {}
}) => {
  const selectorEntity = selectorsTypeMapping[selectorType];
  const { include, exclude, keyForSegment, rowListDataKey, idKey } = selectorEntity;
  let rawListData = useSelector((store: ReduxStore) => store[rowListDataKey]);

  // give the retailer name
  if (rowListDataKey === 'omniRetailers') {
    rawListData = rawListData.data.map((r) => ({
      ...r,
      retailerName: getRetailerIdDisplayName(String(r.retailerId), retailer)
    }));
  }
  // countryCodes
  const includeDataKey = `${include}${keyForSegment}`;
  const excludeDataKey = `${exclude}${keyForSegment}`;

  let includeData = parsedState[includeDataKey];
  includeData = includeData || [];
  let excludeData = parsedState[excludeDataKey];
  excludeData = excludeData || [];
  let shouldInclude = true;

  const bothUncheck = (!includeData || includeData.length === 0) && (!excludeData || excludeData.length === 0);
  if (excludeData && excludeData.length > 0) {
    shouldInclude = false;
  }

  const checkedValues = shouldInclude ? includeData.map(String) : excludeData.map(String);

  const handleIncludeExclude = (checkedType: string, checkedValue: boolean) => {
    if (!checkedValue) {
      return;
    }
    if (checkedType === 'include') {
      parsedState[includeDataKey] = [...includeData, ...excludeData];
      parsedState[excludeDataKey] = [];
    } else {
      parsedState[excludeDataKey] = [...includeData, ...excludeData];
      parsedState[includeDataKey] = [];
    }
    parsedState.name = nameForSegment;
    const newParamObj = generateQueryParamObj(parsedState, { key: '', values: [] });
    history.push(
      `/${navEndPoint}?${addPersistentQueryParams(retailer, mainTimePeriod)}&entityType=segment&${queryString.stringify(
        newParamObj
      )}`
    );
  };

  const initialListData = rawListData.map((d: { [key: string]: string | number }) => {
    const keyValue = d[idKey];
    if (keyValue && checkedValues.includes(String(keyValue))) {
      return { ...d, isChecked: true };
    }
    return { ...d, isChecked: false };
  });

  const handleClearAll = () => {
    parsedState[includeDataKey] = [];
    parsedState[excludeDataKey] = [];
    parsedState.name = nameForSegment;
    const newParamObj = generateQueryParamObj(parsedState, { key: '', values: [] });
    // newParamObj need name and queryId but for new requestBody don't need
    delete parsedState.name;
    delete parsedState.queryId;
    handleRequestChange(parsedState, { pageNumber: 1, pageSize: 20 });
    history.push(
      `/${navEndPoint}?${addPersistentQueryParams(retailer, mainTimePeriod)}&entityType=segment&${queryString.stringify(
        newParamObj
      )}`
    );
  };

  return (
    <OmniSelector
      bothUncheck={bothUncheck}
      initialListData={initialListData}
      handleIncludeExclude={handleIncludeExclude}
      {...selectorEntity}
      shouldInclude={shouldInclude}
      handleChange={handleChange}
      handleClearAll={handleClearAll}
    />
  );
};

export default OmniSelectorWrapper;
