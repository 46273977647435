import { useCallback, useEffect, useRef } from 'react';
import { CancelToken } from 'axios';

export * from './typedHooks';

export * from './useUpdateTimePeriodDropdown';

export * from './useUpdateQueryParams';

// refer: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

/**
 * NOTE: This function hasn't been tested; please verify that it works before using it!!
 *
 * A React hook that creates an `axios` `CancelToken` and handles canceling it when the component unmounts.
 */
export const useCancelToken = () => {
  const cancelToken = useRef();

  useEffect(() => {
    if (cancelToken.current) {
      return undefined;
    }

    cancelToken.current = CancelToken.source();

    return () => cancelToken.current.cancel('API request cancelled via axios cancel token');
  }, [cancelToken]);

  return cancelToken.current;
};

/**
 * Handles registering a subscription to an event on the event bus and unsubscribing when the component mounts.
 *
 * @param {EventBus} eventBus The event bus instance provided via the `eventBus` prop to components warpped with `withBus`
 * @param {string} eventName The name of the event to subscribe to
 * @param {function} cb The function that will be called when the event is received
 */
export const useBus = (eventBus, eventName, cb) => {
  useEffect(() => {
    eventBus.on(eventName, cb);
    return () => eventBus.off(eventName, cb);
  });
};

// From: https://usehooks.com/useWhyDidYouUpdate/
export const useWhyDidYouUpdate = (name, props) => {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef();

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key]
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        // eslint-disable-next-line no-console
        console.log('[why-did-you-update]', name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
};

/**
 * A helper hook that calls the provided `cb` exactly once time, the first time the component mounts.  This only
 * applies through the current lifecycle of the component; other components of the same type will call their callback
 * once as well, and unmounting+remounting the component will call it again as well.
 *
 * @param {func} cb The function that will be called once the first time the component renders.
 */
export const useOnce = (cb) => {
  const called = useRef(false);

  useEffect(() => {
    if (called.current) {
      return;
    }

    called.current = true;
    cb();
  });
};

/**
 * Hook that calls function when user clicks outside of the passed ref
 */
export function useOutsideAlerter(onClickOutside) {
  const ref = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    },
    [onClickOutside]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [onClickOutside, handleClickOutside]);
  return { ref };
}
