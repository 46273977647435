import React, { useCallback, useState } from 'react';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';

/**
 * Displays the traffic trend chart in the traffic display tab
 */
const TrafficDisplayTrendChart = () => {
  const [fieldName, setFieldName] = useState('impressions');

  const aggregationBuilder = useCallback(
    (aggBuilder: AggregationBuilder): AggregationBuilder => {
      if (fieldName === 'stacklineSku') {
        return aggBuilder.addConditionRangeFilter('clicks', 1);
      }
      return aggBuilder;
    },
    [fieldName]
  );

  return (
    <BeaconComparableSplineChart
      indexName="advertisingDisplay"
      fieldName={fieldName}
      primaryAggregationBuilder={aggregationBuilder}
      secondaryAggregationBuilder={aggregationBuilder}
      singleDropdownProps={{
        options: [
          {
            id: 'impressions',
            label: 'Ad Impressions'
          },
          {
            id: 'clickThroughRate',
            label: 'CTR %'
          },
          {
            id: 'spend',
            label: 'Ad Spend'
          },
          {
            id: 'costPerClick',
            label: 'CPC'
          },
          {
            id: 'costPerMilli',
            label: 'CPM'
          },
          {
            id: 'costPerAcquisition',
            label: 'CPA'
          },
          {
            id: 'atc',
            label: 'ATC'
          },
          {
            id: 'dpv',
            label: 'DPV'
          },
          {
            id: 'sales',
            label: 'Ad Sales'
          },
          {
            id: 'conversionRate',
            label: 'Conversion Rate'
          },
          {
            id: 'purchases',
            label: 'Purchases'
          },
          {
            id: 'unitsSold',
            label: 'Ad Units Sold'
          },
          {
            id: 'returnOnAdSpend',
            label: 'ROAS'
          },
          {
            id: 'stacklineSku',
            label: 'Products with Advertising'
          }
        ],
        defaultLabel: '',
        onChange: (option) => setFieldName(option.id),
        selectedId: fieldName
      }}
    />
  );
};

export default TrafficDisplayTrendChart;
