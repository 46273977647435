import _get from 'lodash/get';
import { BEACON_SUBTABS, BEACON_TABS } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';

/**
 * tab > subtab
 */
export const TABLE_TITLE_OVERRIDES = {
  // "Sales/Summary" tab + related subtabs
  [BEACON_TABS.SALES]: {
    [BEACON_SUBTABS.KEY_METRICS]: {
      prefix: 'Retail Sales by ',
      suffix: null,
      completeTitleOverride: null
    },
    [BEACON_SUBTABS.GROWTH_MATRIX]: {
      prefix: null,
      suffix: null,
      completeTitleOverride: null
    },
    [BEACON_SUBTABS.RETAIL_SALES]: {
      prefix: null,
      suffix: null,
      completeTitleOverride: 'Retail Sales by Product'
    },
    [BEACON_SUBTABS.UNITS_SOLD]: {
      prefix: null,
      suffix: null,
      completeTitleOverride: 'Units Sold by Product'
    },
    [BEACON_SUBTABS.MARGINS]: {
      prefix: null,
      suffix: null,
      completeTitleOverride: 'Retailer Margin by Product'
    },
    [BEACON_SUBTABS.RETURNS]: {
      prefix: null,
      suffix: null,
      completeTitleOverride: 'Units Returned by Product'
    },

    [BEACON_SUBTABS.RETAIL_PRICE]: {
      prefix: null,
      suffix: null,
      completeTitleOverride: 'Retail Price by Product'
    },
    [BEACON_SUBTABS.MAP_VIOLATIONS]: {
      prefix: null,
      suffix: null,
      completeTitleOverride: 'MAP Violations by Product'
    },
    [BEACON_SUBTABS.INVENTORY]: {
      prefix: null,
      suffix: null,
      completeTitleOverride: 'Units on Hand by Product'
    }
  },
  // "Traffic" tab + related subtabs
  [BEACON_TABS.TRAFFIC]: {
    [BEACON_SUBTABS.TOTAL_CLICKS]: {
      prefix: 'Total Traffic by ',
      suffix: null,
      completeTitleOverride: null
    },
    [BEACON_SUBTABS.ORGANIC_CLICKS]: {
      prefix: 'Organic Traffic by ',
      suffix: null,
      completeTitleOverride: null
    },
    [BEACON_SUBTABS.AD_CLICKS]: {
      prefix: 'Ad Clicks by ',
      suffix: null,
      completeTitleOverride: null
    },
    [BEACON_SUBTABS.DISPLAY_CLICKS]: {
      prefix: 'DPV by',
      suffix: null,
      completeTitleOverride: null
    }
  },
  // "Traffic > Conversion" tab + subtab
  [BEACON_TABS.CONVERSION]: {
    [BEACON_SUBTABS.PURCHASE_RATE]: {
      prefix: null,
      suffix: null,
      completeTitleOverride: 'Conversion Rate by Product'
    }
  },
  // "Traffic/Conversion" tab + related subtabs
  [BEACON_TABS.BUY_BOX]: {
    [BEACON_SUBTABS.WIN_PERCENTAGE]: {
      prefix: null,
      suffix: null,
      completeTitleOverride: 'Win Rate by Product '
    },
    [BEACON_SUBTABS.SELLERS]: {
      prefix: null,
      suffix: null,
      completeTitleOverride: 'Loss Rate by Product '
    }
  },
  [BEACON_TABS.OPERATIONS]: {
    [BEACON_SUBTABS.CHARGEBACKS]: {
      prefix: null,
      suffix: null,
      completeTitleOverride: 'Financial Charge by Product '
    }
  },
  [BEACON_TABS.COMPLIANCE]: {
    [BEACON_SUBTABS.KEY_METRICS]: {
      prefix: 'YTD by',
      suffix: null,
      completeTitleOverride: null
    },
    [BEACON_SUBTABS.KEYWORD_COMPLIANCE]: {
      prefix: 'Violations by',
      suffix: null,
      completeTitleOverride: null
    },
    [BEACON_SUBTABS.SELLER_COMPLIANCE]: {
      prefix: 'Violations by',
      suffix: null,
      completeTitleOverride: null
    }
  }
};

/**
 * Returns a prefix, suffix, or complete title override to be used in the table headers
 */
export const getTitleOverrides = ({
  tab,
  subtab
}: {
  tab: string;
  subtab: string;
}): { prefix: string | null; suffix: string | null; completeTitleOverride: string | null } => {
  return _get(TABLE_TITLE_OVERRIDES, [tab, subtab], { prefix: null, suffix: null, completeTitleOverride: null });
};
