// Assuming once discover merged, utilize pagination
import React from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { TakeHomeIcon } from 'src/components/SvgIcons';
import IconButton from '@mui/material/IconButton';

interface AdPaginationGroupPropTypes {
  onBtFirst: () => void;
  onBtPageBackTen?: () => void;
  onBtPrevious: () => void;
  currentPage: number;
  totalPage: number;
  onBtNext: () => void;
  onBtPageTen?: () => void;
  isLoading: boolean;
}
const AdPaginationGroup = (props: AdPaginationGroupPropTypes) => {
  const { onBtFirst, onBtPrevious, currentPage, totalPage, onBtNext } = props;
  return (
    <div className="ad-pagination">
      <IconButton disableRipple className="ad-pagination__item" onClick={onBtFirst} size="large">
        <TakeHomeIcon style={{ width: '16px', objectFit: 'contain', verticalAlign: 'middle' }} />
      </IconButton>

      <IconButton disableRipple className="ad-pagination__item" onClick={onBtPrevious} size="large">
        <ChevronLeft style={{ objectFit: 'contain', verticalAlign: 'middle' }} />
      </IconButton>

      <span className="ad-pagination__item" style={{ paddingTop: '1.2px', fontWeight: 500, alignSelf: 'center' }}>
        Page {currentPage} of {totalPage}{' '}
      </span>

      <IconButton disableRipple className="ad-pagination__item" onClick={onBtNext} size="large">
        <ChevronRight style={{ objectFit: 'contain', verticalAlign: 'middle' }} />
      </IconButton>
    </div>
  );
};

export default AdPaginationGroup;
