import { getCampaignDisplayStatus } from 'src/components/AdManager/AmsUtils';
import _get from 'lodash/get';

export const sortAdPortfoliosByBudget = (adPortfolios) => {
  return adPortfolios.sort((x, y) => {
    const budgetAmountX =
      x.extendedAttributes.liveBudgetSetting && x.extendedAttributes.liveBudgetSetting.amount
        ? x.extendedAttributes.liveBudgetSetting.amount
        : 0;
    const budgetAmountY =
      y.extendedAttributes.liveBudgetSetting && y.extendedAttributes.liveBudgetSetting.amount
        ? y.extendedAttributes.liveBudgetSetting.amount
        : 0;

    if (budgetAmountX !== budgetAmountY) {
      if (budgetAmountX > budgetAmountY) {
        return -1;
      } else {
        return 1;
      }
    }
    return x.displayName > y.displayName ? 1 : -1;
  });
};

export const parseAdPortfolio = (adPortfolios, retailerId, tagsArray = []) => {
  const checkPortfolioTag = tagsArray.length > 0;
  // Custom Filtering Logic Update (for CPO - SBD) - Updating filters to check if the portfolio has to be included in the response. If tags to filter are present on dynamoDb,
  // then check the portfolio name for the tag, else include the portfolio
  const parsedAdPortfolios = adPortfolios
    .filter((item) => {
      const retailerIdMatch = `${_get(item, ['extendedAttributes', 'retailerId'], '')}` === retailerId;
      const shouldIncludePortfolio = checkPortfolioTag
        ? item.extendedAttributes &&
          item.extendedAttributes.name &&
          tagsArray.some((tag) => item.extendedAttributes.name.includes(tag))
        : true;
      return retailerIdMatch && shouldIncludePortfolio;
    })
    .map((adPortfolio) => {
      const statusDisplay = getCampaignDisplayStatus(adPortfolio.extendedAttributes);
      const statusDerived = statusDisplay.value;
      return {
        ...adPortfolio,
        extendedAttributes: {
          ...adPortfolio.extendedAttributes,
          statusDisplay,
          statusDerived
        },
        id: adPortfolio.settingId,
        displayName: adPortfolio.name,
        type: 'adPortfolio'
      };
    });

  return sortAdPortfoliosByBudget(parsedAdPortfolios);
};
