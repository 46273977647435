import React, { useCallback, useState, useRef } from 'react';
import { compose } from 'redux';
import { withBus } from 'react-bus';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';
import _omit from 'lodash/omit';
import _orderBy from 'lodash/orderBy';
import { AppName } from 'sl-api-connector/types';

import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import { buildEntityGridDataKey } from 'src/components/EntityGrid/EntityGrid/EntityGrid';
import { buildWidgetUniqueName } from 'src/components/EntityPage/Widget';
import { WaterfallFirstColumnHCF } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import { useBus } from 'src/utils/Hooks';
import { buildTabsWidgetConfig } from 'src/components/common/Tabs/TabsWidget';
import entitySearchServiceActions from 'src/store/modules/entitySearchService/actions';
import EntityGrid from 'src/components/EntityGrid';
import { getRetailSalesAggregationFields as getBeaconRetailSalesAggregationFields } from 'src/components/Layout/Beacon/SalesPageLayout';
import { getRetailSalesAggregationFields as getAtlasRetailSalesAggregationFields } from 'src/components/Layout/Atlas/SalesPageLayout';
import MultiLineHCF, {
  EntityGridNoclipHeaderWrapper
} from 'src/components/EntityGrid/HeaderComponentFrameworks/MultiLineHCF';
import { panic } from 'src/utils/mixpanel';

const ACTIVE_GROUP_BY_FIELD_NAMES_BY_ENTITY_TYPE = {
  client: ['stacklineSku', 'categoryId', 'subCategoryId', 'brandId'],
  company: ['stacklineSku', 'categoryId', 'subCategoryId', 'brandId'],
  brand: ['stacklineSku', 'categoryId', 'subCategoryId', 'brandId'],
  category: ['stacklineSku', 'subCategoryId', 'brandId'],
  subcategory: ['stacklineSku', 'brandId'],
  segment: ['stacklineSku', 'brandId', 'categoryId', 'subCategoryId'],
  keywordlist: ['stacklineSku', 'brandId'],
  businessunit: ['stacklineSku', 'brandId', 'categoryId', 'subCategoryId'],
  product: ['stacklineSku'],
  adCampaign: ['stacklineSku', 'categoryId', 'subCategoryId', 'brandId'],
  searchtermlist: ['stacklineSku', 'categoryId', 'subCategoryId', 'brandId']
};

/**
 * This is a side effect-ful wrapper that goes around the trending products entity grid.  Its only reason for existing
 * is as a consumer of the 'trendingProductsTabSelect' event triggered when the tabs are changed.  In response to
 * receiving this event, it sorts the featured products dataset stored in `entitySearchService` and updates it.
 *
 * @param {string} trendingProductsWidgetUniqueName
 * @param {{id: string}[]} tabs
 */
const mkTrendingProductsWidgetWrapper = (trendingProductsWidgetUniqueName) => {
  const trendingProductsWidgetDataKey = buildEntityGridDataKey(trendingProductsWidgetUniqueName);

  return compose(
    connect(({ entitySearchService }) => ({ dataSet: entitySearchService[trendingProductsWidgetDataKey] }), {
      setKey: entitySearchServiceActions.setKey
    }),
    withBus('eventBus')
  )(({ widget, children, eventBus, dataSet, setKey }) => {
    const [activeGroupByFieldName, setActiveGroupByFieldName] = useState('stacklineSku');
    const currentlySelectedTab = useRef('topSelling');

    const handleTabSelect = useCallback(
      (selectedTab) => {
        currentlySelectedTab.current = selectedTab;
        const mainMetricDataKey = `retailSales_by_${activeGroupByFieldName}`;
        if (_isEmpty(_get(dataSet, [mainMetricDataKey, 'data']))) {
          return;
        }

        const sortRows = {
          topSelling: (rowDatum) => _get(rowDatum, ['cardView', 'retailSalesCurrentValue']) || 0,
          increasing: (rowDatum) => _get(rowDatum, ['cardView', 'retailSalesPeriodChange']) || 0,
          decreasing: (rowDatum) => -(_get(rowDatum, ['cardView', 'retailSalesPeriodChange']) || 0)
        }[selectedTab.id];

        // We assign a source index prop to each row that has been sorted to allow us to retain the original sort
        // order of the dataset before we sort it.
        dataSet[mainMetricDataKey].data.forEach((datum, i) => {
          datum.srcIx = i;
        });

        const newDataSet = Object.entries(_omit(dataSet, ['apiRequest', 'classifiedSkus', mainMetricDataKey])).reduce(
          (acc, [key, val]) => ({ ...acc, [key]: { ...val, data: [] } }),
          {}
        );

        // copy the new ordering of the main metric data set (which we're sorting by) to all of the other metrics.
        const sortedMainMetricData = _orderBy(dataSet[mainMetricDataKey].data, [sortRows], ['desc']);
        sortedMainMetricData.forEach(({ srcIx }, i) => {
          Object.keys(newDataSet).forEach((key) => {
            newDataSet[key].data[i] = dataSet[key].data[srcIx];
          });
        });

        newDataSet[mainMetricDataKey] = { ...dataSet[mainMetricDataKey], data: sortedMainMetricData };
        newDataSet.apiRequest = dataSet.apiRequest;

        setKey(trendingProductsWidgetDataKey, newDataSet);
      },
      [dataSet, setKey, activeGroupByFieldName]
    );

    useBus(eventBus, 'trendingProductsTabSelect', handleTabSelect);

    useBus(eventBus, 'trendingProductsDataSetUpdated', () => {
      if (currentlySelectedTab.current.id !== 'topSelling') {
        handleTabSelect(currentlySelectedTab.current);
      }
    });

    useBus(eventBus, 'entityGridGroupByChange', ({ uniqueName, groupByFieldName }) => {
      if (uniqueName !== trendingProductsWidgetUniqueName) {
        return;
      }

      setActiveGroupByFieldName(groupByFieldName);
    });

    return <EntityGridNoclipHeaderWrapper widget={widget}>{children}</EntityGridNoclipHeaderWrapper>;
  });
};

export const buildTrendingProductsWidgets = ({ app, retailer, entity }) => {
  const tabs = [
    { displayName: 'Top-Selling', id: 'topSelling' },
    { displayName: 'Increasing', id: 'increasing' },
    { displayName: 'Decreasing', id: 'decreasing' }
  ];

  const trendingProductsTabsWidget = buildTabsWidgetConfig({
    eventName: 'trendingProductsTabSelect',
    tabs,
    tabStyle: { fontSize: 20, padding: 10, fontWeight: 400 },
    style: { marginBottom: -56, marginTop: 80, width: 500 }
  });

  const retailSalesField = INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', 'product');

  const trendingProductsWidgetName = 'trendingProducts';
  const trendingProductsWidgetUniqueName = buildWidgetUniqueName(trendingProductsWidgetName, 3);

  const activeGroupByFieldNames = ACTIVE_GROUP_BY_FIELD_NAMES_BY_ENTITY_TYPE[entity.type];
  if (!activeGroupByFieldNames) {
    return panic(`No trending products grid config for entity type "${entity.type}"`);
  }

  const { aggregationFields, metricFields } = (
    app.name === AppName.Beacon ? getBeaconRetailSalesAggregationFields : getAtlasRetailSalesAggregationFields
  )({ app, retailer });

  const configByGroupByFieldName = activeGroupByFieldNames.reduce((acc, groupByFieldName) => {
    const field = INDEX_FIELDS.getField(app.name, 'sales', groupByFieldName);
    const entityForGroupByField = ENTITIES[app.name][field.entity.type];

    return {
      ...acc,
      [groupByFieldName]: {
        mainMetricField: retailSalesField,
        aggregationFields,
        tableView: {
          metricFields: metricFields.map((metricField) => ({
            ...metricField,
            headerComponentFramework: MultiLineHCF,
            cellStyle: {
              'text-align': 'right',
              'padding-right': '20px',
              display: 'flex',
              'flex-direction': 'row-reverse'
            }
          }))
        },
        entity: entityForGroupByField
      }
    };
  }, {});

  const trendingProductsWidget = {
    CustomComponent: EntityGrid,
    name: trendingProductsWidgetName,
    view: _merge({}, VIEWS.entityGrid, {
      name: trendingProductsWidgetName,
      WrapperComponent: mkTrendingProductsWidgetWrapper(trendingProductsWidgetUniqueName),
      gridOptions: {
        enableGroupBy: true,
        enableExport: false,
        title: ' ',
        pageSize: 100,
        viewPageSize: 100,
        firstColumnDefOverrides: {
          headerComponentFramework: WaterfallFirstColumnHCF
        },
        onDataFetched: (_uniqueName, { eventBus }) => {
          eventBus.emit('trendingProductsDataSetUpdated');
        }
      }
    }),
    data: {
      groupByFields: activeGroupByFieldNames.map((fieldName) =>
        INDEX_FIELDS.getField(app.name, 'sales', fieldName, 'product')
      ),
      configByGroupByFieldName
    }
  };

  return [trendingProductsTabsWidget, trendingProductsWidget];
};
