import { useMemo } from 'react';
import { AdjustmentChangeType } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAdSpendProjection';
import { useAppSelector } from 'src/utils/Hooks';

interface AdjustmentRequestBuilderParams {
  adjustmentId?: string;
  beaconClientId: number;
  retailerId: string;
  retailerSku: string;
  userId: string;
  planType: string;
  startWeekId: string | number;
  endWeekId: string | number;
  ignoreConflictWithAdjustmentIds?: string[];
  stacklineSku: string;
  categoryId: number;
  subCategoryId: number;
  adjustmentChangeValue: number;
}

type UseAdjustmentRequestBuilderParams = Omit<
  AdjustmentRequestBuilderParams,
  'beaconClientId' | 'retailerId' | 'userId'
> & { additionalProperties?: any };

/**
 * An adjustment request object for multiple adjustment api requests
 */
class AdjustmentRequestBuilder {
  private adjustmentRequest: any;

  public constructor({
    adjustmentId = '',
    beaconClientId,
    retailerId,
    retailerSku,
    userId,
    planType,
    startWeekId,
    endWeekId,
    ignoreConflictWithAdjustmentIds = [],
    stacklineSku,
    categoryId,
    subCategoryId,
    adjustmentChangeValue
  }: AdjustmentRequestBuilderParams) {
    this.adjustmentRequest = {
      baseAdjustmentRequest: {
        adjustmentId,
        beaconClientId,
        retailerId,
        retailerSku,
        userId,
        planType,
        startWeekId,
        endWeekId,
        ignoreConflictWithAdjustmentIds
      },
      additionalProperties: {},
      adjustmentChangeType: AdjustmentChangeType.Absolute,
      adjustmentChangeValue,
      stacklineSku,
      categoryId,
      subCategoryId,
      requestType: 'Create',
      shouldPublish: false
    };
  }

  public setAdditionalProperties(additionalProperties: any) {
    this.adjustmentRequest.additionalProperties = additionalProperties;
    return this;
  }

  public build() {
    return this.adjustmentRequest;
  }
}

/**
 * Hook to create an adjustment request object for adjustment api requests with certain properties prefilled
 * @returns Returns a base adjustment request object
 */
export const useBaseAdjustmentRequestBuilder = ({
  adjustmentId,
  ignoreConflictWithAdjustmentIds,
  stacklineSku,
  retailerSku,
  categoryId,
  subCategoryId,
  planType,
  startWeekId,
  endWeekId,
  additionalProperties = null,
  adjustmentChangeValue
}: UseAdjustmentRequestBuilderParams) => {
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const retailerId = useAppSelector((state) => state.retailer.id);
  const userId = useAppSelector((state) => state.user.session.userId);

  return useMemo(() => {
    const adjustmentRequestBuilder = new AdjustmentRequestBuilder({
      adjustmentId,
      ignoreConflictWithAdjustmentIds,
      beaconClientId,
      retailerId,
      retailerSku,
      userId,
      planType,
      startWeekId,
      endWeekId,
      stacklineSku,
      categoryId,
      subCategoryId,
      adjustmentChangeValue
    });

    if (additionalProperties) {
      adjustmentRequestBuilder.setAdditionalProperties(additionalProperties);
    }

    return adjustmentRequestBuilder.build();
  }, [
    adjustmentId,
    ignoreConflictWithAdjustmentIds,
    adjustmentChangeValue,
    beaconClientId,
    retailerId,
    userId,
    stacklineSku,
    retailerSku,
    categoryId,
    subCategoryId,
    planType,
    startWeekId,
    endWeekId,
    additionalProperties
  ]);
};
