/* eslint-disable prefer-destructuring */
import _ident from 'lodash/identity';
import _merge from 'lodash/merge';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import { store } from 'src/main';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import { buildTabbedWidget, buildWidgetGroup } from 'src/components/Layout/LayoutUtil';
import { WaterfallNonSortableHeaderComponentFramework } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import ReduxStore from 'src/types/store/reduxStore';
import { MetricField, AggregationField } from 'src/types/application/types';
import { PageLayout } from 'src/types/application/widgetTypes';
import { EventBus } from 'src/types/utils';
import AdManagerSearchSideBar from 'src/components/AdManager/Search/SearchSideBar';
import EntityColumn from 'src/components/AdManager/Search/CustomColumns/EntityColumn';
import CampaignPortfolioColumn from 'src/components/AdManager/Search/CustomColumns/CampaignPortfolioColumn';
import PortfolioColumn from 'src/components/AdManager/Search/CustomColumns/PortfolioColumn';
import _intersection from 'lodash/intersection';
import SelectEntityColumn from 'src/components/AdManager/Search/CustomColumns/SelectEntityColumn';
import EntityStatusColumn from 'src/components/AdManager/Search/CustomColumns/EntityStatusColumn';
import DataColumn from 'src/components/AdManager/Search/CustomColumns/DataColumn';
import BudgetColumn from 'src/components/AdManager/Search/CustomColumns/BudgetColumn';
import ProjectedBudgetColumn from 'src/components/AdManager/Search/CustomColumns/ProjectedBudgetColumn';
import { AdManagerEvents } from 'src/types/application/event';
import { withProps } from 'src/utils/hoc';
import SearchResultsHeader from 'src/components/AdManager/Search/SearchResultsHeader';
import { splitSnakeCase } from 'src/utils/stringFormatting';
import axios from 'axios';
import { validIndexForIncrementality } from 'src/utils/incrementalityColumn';
import { DEFAULT_USER_COLUMNS, getUserColumns } from 'src/routes/UserAccount/UserCustomColumn';
import {
  checkIsAllowedParentPlatform,
  checkIsAmazonRetailer,
  checkIsSamsClubRetailer,
  getParentPlatform,
  getRetailerId
} from 'src/utils/browser';
import { UserCustomColumn } from './types';
import { isCriteo, shouldShowCriteo } from 'src/utils/app';
import CustomAgGridHeaderTemplate from 'src/components/EntityGrid/Table/CustomAgGridHeaderTemplate';
import SearchResultsGridContainer from 'src/components/AdManager/Search/SearchResultsGridContainer';
import { Widget } from '@stackline/ui';
import { RouteChildrenProps } from 'react-router';

export type Props = RouteChildrenProps & { widget: Widget; fetchEntityMetrics: Function } & { eventBus: EventBus };

const indexNameByGroupByFieldByEntityType: {
  [key: string]: {
    [key: string]:
      | string
      | (({
          parentPlatform,
          entity,
          groupByFieldName,
          retailer,
          parsedTags
        }: {
          parentPlatform: string;
          entity: any;
          groupByFieldName: string;
          retailer: any;
          parsedTags?: string[];
        }) => string);
  };
} = {
  adTarget: {
    adGroupId: ({ parentPlatform }) => {
      if (shouldShowCriteo() && ['Criteo'].includes(parentPlatform)) {
        return 'adCampaignAdGroupTargetDailyMetrics';
      }
      return 'adCampaignAdGroupTargetDailyMetrics';
    },
    campaignId: () => 'adCampaignAdGroupTargetDailyMetrics',
    portfolioId: () => 'adCampaignAdGroupTargetDailyMetrics',
    stacklineSku: () => 'adCampaignAdGroupProductTargetDailyMetrics',
    targetingText: () => 'adCampaignAdGroupTargetDailyMetrics',
    entityId: () => 'adCampaignAdGroupTargetDailyMetrics'
  },
  product: {
    adGroupId: ({ parentPlatform, retailer }) => {
      if (shouldShowCriteo() && ['Criteo'].includes(parentPlatform)) {
        if (retailer.id === '0') {
          return 'adCampaignAdGroupProductDailyMetrics';
        }
        return 'adCampaignAdGroupProductTargetDailyMetrics';
      }
      return 'adCampaignAdGroupTargetDailyMetrics';
    },
    campaignId: () => 'adCampaignAdGroupProductDailyMetrics',
    portfolioId: () => 'adCampaignAdGroupProductDailyMetrics',
    stacklineSku: () => 'adCampaignAdGroupProductDailyMetrics',
    targetingText: () => 'adCampaignAdGroupProductTargetDailyMetrics',
    entityId: () => 'adCampaignAdGroupProductDailyMetrics'
  },
  brand: {
    campaignId: () => 'adCampaignAdGroupProductDailyMetrics',
    stacklineSku: () => 'adCampaignAdGroupProductDailyMetrics',
    targetingText: () => 'adCampaignAdGroupProductTargetDailyMetrics'
  },
  default: {
    campaignId: () => 'adCampaignDailyMetrics',
    portfolioId: () => 'adPortfolioDailyMetrics',
    stacklineSku: () => 'adCampaignAdGroupProductDailyMetrics',
    targetingText: () => 'adCampaignAdGroupTargetDailyMetrics',
    adGroupId: () => 'adCampaignAdGroupDailyMetrics',
    entityId: ({ parsedTags = [] }) => {
      if (parsedTags.length > 0) {
        return 'adCampaignDailyMetrics';
      } else {
        return 'adEntityDailyMetrics';
      }
    },
    retailerId: () => 'adCampaignAdGroupDailyMetrics'
  }
};

// ntbMetrics and offlineMEtrics for SamsClub
const ntbMetrics: UserCustomColumn[] = [
  {
    field: 'newToBrandUnitsSold',
    displayName: 'NTB Units Sold'
  },
  {
    field: 'newToBrandSales',
    displayName: 'NTB Sales'
  }
];

const offlineMEtrics: UserCustomColumn[] = [
  {
    field: 'offlineSales',
    displayName: 'Offline Sales'
  },
  {
    field: 'sameCategorySales',
    displayName: 'Same Category Sales'
  }
];

export const reachColums: UserCustomColumn[] = [
  {
    field: 'impressions',
    displayName: 'Ad Impressions'
  },
  {
    field: 'clicks',
    displayName: 'Ad Clicks'
  },
  {
    field: 'clickThroughRate',
    displayName: 'CTR%'
  }
];
const incrementality = [
  {
    field: 'brandTotalClicksIncrementalSales',
    displayName: 'Incremental Sales'
  },
  {
    field: 'brandTotalClicksIncrementalClicks',
    displayName: 'Incremental Clicks'
  },
  {
    field: 'brandTotalClicksIncrementalROAS',
    displayName: 'Incremental ROAS'
  },
  {
    field: 'brandTotalClicksIncrementalUnitsOrders',
    displayName: 'Incremental Units'
  },
  {
    field: 'avgIncrementality',
    displayName: 'Incrementality'
  }
];
export const incrementalityColumns: UserCustomColumn[] = [
  ...(checkIsAllowedParentPlatform('incrementality') ? incrementality : [])
];

export const spendColums: UserCustomColumn[] = [
  {
    field: 'spend',
    displayName: 'Ad Spend'
  },
  {
    field: 'costPerClick',
    displayName: 'CPC'
  },
  {
    field: 'costPerAcquisition',
    displayName: 'CPA'
  },
  {
    field: 'advertisingCostOfSale',
    displayName: 'ACOS'
  }
];

const sameSKUMetricsColumns: UserCustomColumn[] = [
  {
    field: 'sameSKUSales',
    displayName: 'Same SKU Sales'
  },
  {
    field: 'sameSKUConversions',
    displayName: 'Same SKU Conversions'
  }
];

const sameSKUMetricsExpanded: UserCustomColumn[] = [
  {
    field: 'sameSKUSales',
    displayName: 'Same SKU Sales'
  },
  {
    field: 'sameSKUConversions',
    displayName: 'Same SKU Conversions'
  },
  {
    field: 'sameCategorySales',
    displayName: 'Same Category Sales'
  },
  {
    field: 'sameCategoryConversions',
    displayName: 'Same Category Conversions'
  }
];

export const salesColums: UserCustomColumn[] = [
  {
    field: 'sales',
    displayName: 'Ad Sales'
  },
  {
    field: 'returnOnAdSpend',
    displayName: 'ROAS'
  },
  {
    field: 'conversionRate',
    displayName: 'Conversion Rate'
  },
  {
    field: 'conversions',
    displayName: 'Units/Orders'
  },
  ...(checkIsAmazonRetailer()
    ? sameSKUMetricsColumns
    : !checkIsAllowedParentPlatform('sameSKUSales')
    ? sameSKUMetricsExpanded
    : []),
  ...(checkIsSamsClubRetailer() ? ntbMetrics : []),
  ...(checkIsSamsClubRetailer() ? offlineMEtrics : [])
];

export const sortOrder = [
  'impressions',
  'clicks',
  'spend',
  'sales',
  'conversions',
  'clickThroughRate',
  'costPerClick',
  'costPerAcquisition',
  'advertisingCostOfSale',
  'returnOnAdSpend',
  'conversionRate',
  'sameSKUSales',
  'sameCategorySales',
  'sameSKUConversions',
  'brandTotalClicksIncrementalSales',
  'brandTotalClicksIncrementalClicks',
  'brandTotalClicksIncrementalROAS',
  'brandTotalClicksIncrementalUnitsOrders',
  'avgIncrementality',
  'newToBrandSales',
  'newToBrandUnitsSold',
  'offlineSales'
];

export const getFieldDisplayName = (fieldName: string): string => {
  const allColumns = [...reachColums, ...spendColums, ...salesColums, ...incrementalityColumns];
  // eslint-disable-next-line no-restricted-syntax
  for (const col of allColumns) {
    if (col.field === fieldName) {
      return col.displayName;
    }
  }
  return '';
};

const indexNameByGroupByField: { [key: string]: string } = {
  campaignId: 'adCampaignDailyMetrics', // 'adCampaignAdGroupDailyMetrics',
  portfolioId: 'adPortfolioDailyMetrics',
  stacklineSku: 'adCampaignAdGroupProductDailyMetrics',
  targetingText: 'adCampaignAdGroupTargetDailyMetrics',
  adGroupId: 'adCampaignAdGroupDailyMetrics',
  entityId: 'adEntityDailyMetrics',
  retailerId: 'adCampaignAdGroupDailyMetrics'
};

const tabDefinitionTarget = {
  displayName: 'Targets',
  groupByFieldName: 'targetingText',
  entityName: 'targetingText',
  addCampaignColumn: true,
  valueFormatter: (({ data }) => data.name) as MetricField['valueFormatter']
};

const getTabDefinitions = (platformId: string, entity) => {
  const tabDefinitionsByEntityType = {
    client: [
      {
        displayName: 'Retailers',
        groupByFieldName: 'retailerId',
        entityName: 'retailer',
        // cellRendererFramework: EntityColumn,
        allowedPlatforms: ['Criteo'],
        allowedRetailers: ['0']
      },
      // Criteo is using different rules for campaign
      {
        displayName: 'Campaigns',
        groupByFieldName: 'campaignId',
        entityName: 'adCampaign',
        allowedRetailers: ['0'],
        allowedPlatforms: ['Criteo']
      },
      {
        displayName: 'Campaigns',
        groupByFieldName: 'campaignId',
        entityName: 'adCampaign',
        notAllowedPlatforms: ['Criteo']
      },

      {
        displayName: 'Line Items',
        groupByFieldName: 'adGroupId',
        entityName: 'adGroup',
        cellRendererFramework: EntityColumn,
        allowedPlatforms: ['Criteo']
      },
      {
        displayName: 'Portfolios',
        groupByFieldName: 'portfolioId',
        entityName: 'adPortfolio',
        notAllowedPlatforms: ['Criteo']
      },
      tabDefinitionTarget,
      {
        displayName: 'Products',
        groupByFieldName: 'stacklineSku',
        entityName: 'product',
        addCampaignColumn: true,
        cellRendererFramework: EntityColumn
      },
      // Criteo only && all retailer lvl
      {
        displayName: 'Accounts',
        groupByFieldName: 'entityId',
        entityName: 'adEntity',
        allowedPlatforms: ['Criteo'],
        allowedRetailers: ['0']
      },
      {
        displayName: 'Entities',
        groupByFieldName: 'entityId',
        entityName: 'adEntity',
        notAllowedPlatforms: ['Criteo']
      }
    ],
    segment: [
      { displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' },
      { displayName: 'Portfolios', groupByFieldName: 'portfolioId', entityName: 'adPortfolio' },
      tabDefinitionTarget,
      {
        displayName: 'Products',
        groupByFieldName: 'stacklineSku',
        entityName: 'product',
        addCampaignColumn: true,
        cellRendererFramework: EntityColumn
      },
      { displayName: 'Entities', groupByFieldName: 'entityId', entityName: 'adEntity' }
    ],
    adEntity: [
      { displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' },
      { displayName: 'Portfolios', groupByFieldName: 'portfolioId', entityName: 'adPortfolio' },
      tabDefinitionTarget,
      {
        displayName: 'Products',
        groupByFieldName: 'stacklineSku',
        entityName: 'product',
        addCampaignColumn: true,
        cellRendererFramework: EntityColumn
      }
    ],
    adPortfolio: [
      { displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' },
      tabDefinitionTarget,
      {
        displayName: 'Products',
        groupByFieldName: 'stacklineSku',
        entityName: 'product',
        addCampaignColumn: true,
        cellRendererFramework: EntityColumn
      }
    ],
    adCampaign: [
      {
        displayName: 'Products',
        groupByFieldName: 'stacklineSku',
        entityName: 'product',
        addCampaignColumn: true,
        cellRendererFramework: EntityColumn
      },
      tabDefinitionTarget,
      {
        displayName: isCriteo() ? 'Line Items' : 'Ad Groups',
        groupByFieldName: 'adGroupId',
        entityName: 'adGroup',
        cellRendererFramework: EntityColumn
      }
    ],
    adGroup: [
      {
        displayName: 'Products',
        groupByFieldName: 'stacklineSku',
        entityName: 'product',
        addCampaignColumn: true,
        cellRendererFramework: EntityColumn
      },
      tabDefinitionTarget
    ],
    adTarget: [
      {
        displayName: 'Campaigns',
        groupByFieldName: 'campaignId',
        entityName: 'adCampaign',
        notAllowedPlatforms: ['Criteo']
      },
      {
        displayName: isCriteo() ? 'Line Items' : 'Ad Groups',
        groupByFieldName: 'adGroupId',
        entityName: 'adGroup',
        cellRendererFramework: EntityColumn,
        allowedPlatforms: ['Criteo']
      },
      {
        displayName: 'Products',
        groupByFieldName: 'stacklineSku',
        entityName: 'product',
        addCampaignColumn: true,
        cellRendererFramework: EntityColumn
      }
    ],
    product: [
      {
        displayName: 'Campaigns',
        groupByFieldName: 'campaignId',
        entityName: 'adCampaign',
        notAllowedPlatforms: ['Criteo']
      },
      {
        displayName: isCriteo() ? 'Line Items' : 'Ad Groups',
        groupByFieldName: 'adGroupId',
        entityName: 'adGroup',
        cellRendererFramework: EntityColumn,
        allowedPlatforms: ['Criteo']
      },
      {
        displayName: 'Targets',
        groupByFieldName: 'targetingText',
        entityName: 'targetingText',
        addCampaignColumn: true,
        valueFormatter: (({ data }) => data.name) as MetricField['valueFormatter']
      }
    ],
    scheduledActionsCreate: [
      { displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' },
      tabDefinitionTarget,
      {
        displayName: 'Products',
        groupByFieldName: 'stacklineSku',
        entityName: 'product',
        addCampaignColumn: true,
        cellRendererFramework: EntityColumn
      }
    ],
    ineligibleProduct: [
      {
        displayName: 'Products',
        groupByFieldName: 'stacklineSku',
        entityName: 'product',
        addCampaignColumn: true,
        cellRendererFramework: EntityColumn
      }
    ],
    budgetConstraints: [{ displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' }],
    brand: [
      { displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' },
      {
        displayName: 'Products',
        groupByFieldName: 'stacklineSku',
        entityName: 'product',
        addCampaignColumn: true,
        cellRendererFramework: EntityColumn
      },
      tabDefinitionTarget
    ]
  };

  // Extract the filtering logic into a separate function
  const filterTabsForPlatformAndEntity = (tabDefinitions, entityType) => {
    const retailerId = getRetailerId();

    return tabDefinitions.filter((tabDef) => {
      const allowedPlatforms = tabDef.allowedPlatforms;
      const allowedRetailers = tabDef.allowedRetailers;
      const notAllowedPlatforms = tabDef.notAllowedPlatforms;
      const notAllowedLevels = tabDef.notAllowedLevel;

      // Check if the platform is in the allowedPlatforms list, if defined
      const isPlatformAllowed = !allowedPlatforms || allowedPlatforms.includes(platformId);
      // Check if the platform is in the allowedPlatforms list, if defined
      const isRetailerAllowed = !allowedRetailers || allowedRetailers.includes(retailerId);

      // Check if the platform is not in the notAllowedPlatforms list, if defined
      const isPlatformNotAllowed = !notAllowedPlatforms || !notAllowedPlatforms.includes(platformId);

      // Check if the entity type is not in the notAllowedLevels list
      const isEntityTypeAllowed = !notAllowedLevels || !notAllowedLevels.includes(entityType);

      return isPlatformAllowed && isPlatformNotAllowed && isEntityTypeAllowed && isRetailerAllowed;
    });
  };

  const filteredTabDefinitions = {};

  Object.keys(tabDefinitionsByEntityType).forEach((entityType) => {
    filteredTabDefinitions[entityType] = filterTabsForPlatformAndEntity(
      tabDefinitionsByEntityType[entityType],
      entity.type
    );
  });

  return filteredTabDefinitions;
};

const baseColDef = {
  disableSort: true,
  width: 100
};

export const termFiltersForGroupByField = {
  campaignId: [
    { filterName: 'campaignNameFuzzy', fieldName: 'campaignNameFuzzy' },
    { filterName: 'excludedCampaignNameFuzzy', fieldName: 'excludedCampaignNameFuzzy' },
    { filterName: 'entityId', fieldName: 'entityId' },
    { filterName: 'portfolioId', fieldName: 'portfolioId' },
    { filterName: 'derivedCampaignType', fieldName: 'derivedCampaignType' },
    { filterName: 'statusDerived', fieldName: 'statusDerived' },
    { filterName: 'automationStrategyId', fieldName: 'automationStrategyId' }
  ],
  portfolioId: [
    { filterName: 'portfolioNameFuzzy', fieldName: 'portfolioNameFuzzy' },
    { filterName: 'excludedPortfolioNameFuzzy', fieldName: 'excludedPortfolioNameFuzzy' },
    { filterName: 'entityId', fieldName: 'entityId' },
    { filterName: 'portfolioState', fieldName: 'statusDerived' },
    { filterName: 'automationStrategyId', fieldName: 'automationStrategyId' }
  ],
  targetingText: [
    { filterName: 'entityId', fieldName: 'entityId' },
    { filterName: 'portfolioId', fieldName: 'portfolioId' },
    { filterName: 'campaignId', fieldName: 'campaignId' },
    { filterName: 'derivedCampaignType', fieldName: 'derivedCampaignType' },
    { filterName: 'targetingType', fieldName: 'targetingType' },
    { filterName: 'targetingText', fieldName: 'targetingText' },
    { filterName: 'targetingTextFuzzy', fieldName: 'targetingTextFuzzy' },
    { filterName: 'excludedTargetingText', fieldName: 'excludedTargetingText' },
    { filterName: 'excludedTargetingTextFuzzy', fieldName: 'excludedTargetingTextFuzzy' },
    { filterName: 'isAutoAdded', fieldName: 'isAutoAdded' },
    { filterName: 'targetState', fieldName: 'status' },
    { filterName: 'bid', fieldName: 'cpcMinBidAmount' }
  ],
  stacklineSku: [
    { filterName: 'entityId', fieldName: 'entityId' },
    { filterName: 'portfolioId', fieldName: 'portfolioId' },
    { filterName: 'campaignId', fieldName: 'campaignId' },
    { filterName: 'derivedCampaignType', fieldName: 'derivedCampaignType' },
    { filterName: 'keyword', fieldName: 'keyword' },
    { filterName: 'keywordPhrase', fieldName: 'keywordPhrase' },
    { filterName: 'excludedKeyword', fieldName: 'excludedKeyword' },
    { filterName: 'campaignProductState', fieldName: 'status' }
  ],
  entityId: [
    { filterName: 'entityNameFuzzy', fieldName: 'entityNameFuzzy' },
    { filterName: 'excludedEntityNameFuzzy', fieldName: 'excludedEntityNameFuzzy' }
  ],
  retailerId: [{ filterName: 'retailerNameFuzzy', fieldName: 'retailerNameFuzzy' }],
  adGroupId: [
    { filterName: 'adGroupNameFuzzy', fieldName: 'adGroupNameFuzzy' },
    { filterName: 'campaignId', fieldName: 'campaignId' },
    { filterName: 'entityId', fieldName: 'entityId' },
    { filterName: 'portfolioId', fieldName: 'portfolioId' }
  ]
};

export const computeFiltersForGroupByField = {
  campaignId: [
    { filterName: 'costPerClick', fieldName: 'costPerClick' },
    { filterName: 'returnOnAdSpend', fieldName: 'returnOnAdSpend' },
    { filterName: 'spendComputed', fieldName: 'spendComputed' }
  ],
  portfolioId: [
    { filterName: 'costPerClick', fieldName: 'costPerClick' },
    { filterName: 'returnOnAdSpend', fieldName: 'returnOnAdSpend' },
    { filterName: 'spendComputed', fieldName: 'spendComputed' }
  ],
  targetingText: [
    { filterName: 'costPerClick', fieldName: 'costPerClick' },
    { filterName: 'returnOnAdSpend', fieldName: 'returnOnAdSpend' },
    { filterName: 'spendComputed', fieldName: 'spendComputed' }
  ],
  stacklineSku: [
    { filterName: 'costPerClick', fieldName: 'costPerClick' },
    { filterName: 'returnOnAdSpend', fieldName: 'returnOnAdSpend' },
    { filterName: 'spendComputed', fieldName: 'spendComputed' }
  ],
  entityId: [
    { filterName: 'costPerClick', fieldName: 'costPerClick' },
    { filterName: 'returnOnAdSpend', fieldName: 'returnOnAdSpend' },
    { filterName: 'spendComputed', fieldName: 'spendComputed' }
  ],
  retailerId: [
    { filterName: 'costPerClick', fieldName: 'costPerClick' },
    { filterName: 'returnOnAdSpend', fieldName: 'returnOnAdSpend' },
    { filterName: 'spendComputed', fieldName: 'spendComputed' }
  ]
};

/**
 * Returns the list of custom columns the user has configured,
 * e.g. Ad Clicks, Ad Spend, ROAS, etc.
 */
export const getCommonColumns = (
  sortByMetric: string,
  sortDirection: string,
  onSort: (sortByMetric: string, sortDirection: string) => void
) => {
  const fields = getUserColumns();

  const userSettingColumns = [...reachColums, ...spendColums, ...salesColums, ...incrementalityColumns].sort((a, b) => {
    const aIdx = sortOrder.indexOf(a.field);
    const bIdx = sortOrder.indexOf(b.field);

    if (aIdx === -1 && bIdx === -1) {
      return 0;
    }
    // Handling cases where a field is not found in the sortOrder array
    if (aIdx === -1) {
      return 1;
    } // Push a to the end if not found
    if (bIdx === -1) {
      return -1;
    } // Push b to the end if not found

    return aIdx - bIdx;
  });

  // TODO smart calculation (fields) instead of DEFAULT_USER_COLUMNS
  // see below
  return userSettingColumns
    .filter((column) => fields.includes(column.field))
    .map((item) => {
      return {
        headerName: item.displayName,
        disableSort: false,
        headerComponentFramework: CustomAgGridHeaderTemplate,
        cellRendererFramework: DataColumn,
        cellStyle: {
          textAlign: 'right',
          paddingRight: '20px',
          display: 'flex',
          flexDirection: 'row-reverse'
        },
        field: item.field,
        minWidth: 105,
        maxWidth: 200,
        sortByMetric,
        sortDirection,
        onSortFunction: () => {
          // Invert the sort direction if we're clicking on a column that already has a sort direction applied.
          // Otherwise, default to descending order.
          const newSortDirection = item.field === sortByMetric ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'desc';
          return onSort(item.field, newSortDirection);
        }
      };
    });
};

async function setColumnsFunc(fields: string[]) {
  const SET_COLUMNS_API = '/api/user/SetAdvertisingDisplayColumns';
  await axios.post(SET_COLUMNS_API, fields);
}

const getLinkOverride = ({ type, id, searchParams }) => {
  return `/${type}/${id}${searchParams}`;
};

export const EnhanceEntityColumn = withProps({ getLinkOverride })(EntityColumn);

function getIndexNameOld(entity: { type: string; [p: string]: any }, groupByFieldName: string) {
  const indexName =
    indexNameByGroupByFieldByEntityType[entity.type] &&
    indexNameByGroupByFieldByEntityType[entity.type][groupByFieldName]
      ? indexNameByGroupByFieldByEntityType[entity.type][groupByFieldName]
      : indexNameByGroupByField[groupByFieldName];

  if (groupByFieldName === 'portfolioId') {
    return 'adCampaignDailyMetrics';
  }
  return indexName;
}

function getIndexNameNew(
  entity: { type: string; [p: string]: any },
  groupByFieldName: string,
  user: any,
  _retailer: any
) {
  const parentPlatform = getParentPlatform();
  const indexMapping =
    indexNameByGroupByFieldByEntityType[entity.type] &&
    indexNameByGroupByFieldByEntityType[entity.type][groupByFieldName];

  const parsedTags = user.config.TagsForGranularViews ? JSON.parse(user.config.TagsForGranularViews) : [];

  let indexName =
    typeof indexMapping === 'function'
      ? indexMapping({ parentPlatform, entity, groupByFieldName, retailer: _retailer })
      : indexMapping;

  if (!indexName) {
    indexName = indexNameByGroupByFieldByEntityType.default[groupByFieldName]({
      parsedTags
    });
  }

  if (groupByFieldName === 'portfolioId') {
    return 'adCampaignDailyMetrics';
  }
  return indexName;
}

export const buildSearchResultGridWidget = ({
  app,
  entity,
  groupByFieldName,
  user,
  fieldsOverride
}: {
  app: ReduxStore['app'];
  entity: { type: string; [key: string]: any };
  groupByFieldName: string;
  user: ReduxStore['user'];
  fieldsOverride?: string[];
}) => {
  const { retailer } = store.getState();
  const currentRetailerId = _get(retailer, ['id']);
  const stage = _get(app, ['stage']);
  const isValidIndexForIncrementality = validIndexForIncrementality(currentRetailerId, stage);
  let fields = fieldsOverride || getUserColumns();
  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);
  const isSearchPage = window.location.pathname === '/search';

  if (
    !isValidIndexForIncrementality &&
    _intersection(fields, [
      'brandTotalClicksIncrementalSales',
      'brandTotalClicksIncrementalClicks',
      'brandTotalClicksIncrementalROAS',
      'brandTotalClicksIncrementalUnitsOrders',
      'avgIncrementality'
    ]).length > 0
  ) {
    fields = DEFAULT_USER_COLUMNS;
    // add changes to default back to these fields
    setColumnsFunc(fields);
  }
  const sortedFields = fields.sort((a, b) => {
    const aIdx = sortOrder.indexOf(a);
    const bIdx = sortOrder.indexOf(b);
    return aIdx - bIdx;
  });

  const getIndexName = shouldShowCriteo() ? getIndexNameNew : getIndexNameOld;

  const indexName = getIndexName(entity, groupByFieldName, user, retailer);
  const aggregationFields: AggregationField[] = sortedFields.map((fieldName) =>
    INDEX_FIELDS.getField(app.name, indexName, fieldName)
  );
  const gridAggregationFields = aggregationFields.map((field) => ({
    ...field,
    headerComponentFramework: WaterfallNonSortableHeaderComponentFramework
  }));

  const metricFields = [...gridAggregationFields].filter(_ident);

  const configByGroupByFieldName = {
    [groupByFieldName]: {
      indexName,
      entity,
      mainMetricField: INDEX_FIELDS.getField(app.name, indexName, sortedFields[0], entity.type, 'stacklineSku'),
      aggregationFields,
      tableView: {
        metricFields
      }
    }
  };

  const userSettingColumns = [...reachColums, ...spendColums, ...salesColums, ...incrementalityColumns].sort((a, b) => {
    const aIdx = sortOrder.indexOf(a.field);
    const bIdx = sortOrder.indexOf(b.field);
    return aIdx - bIdx;
  });

  const metricColDef = userSettingColumns
    .filter((column) => fields.includes(column.field))
    .map((item) => {
      return {
        headerName: item.displayName,
        disableSort: false,
        cellRendererFramework: DataColumn,
        field: item.field,
        minWidth: 105,
        maxWidth: 200
      };
    });

  return {
    name: 'entityGrid',
    CustomComponent: SearchResultsGridContainer,
    view: _merge({}, VIEWS.entityGrid, {
      gridOptions: {
        hideGridHeader: true,
        defaultLayout: 'table',
        pageSize: 50,
        columnDefinitionsByGroupByField: {
          campaignId: [
            ...(isAdAuditUser || isSearchPage
              ? []
              : [
                  {
                    headerName: 'Campaign',
                    field: 'campaignId',
                    width: undefined,
                    enableRtl: true,
                    cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
                    minWidth: 65,
                    maxWidth: 65,
                    cellRendererFramework: SelectEntityColumn,
                    headerComponentFramework: SelectEntityColumn,
                    pinnedRowCellRenderer: 'emptyColumn',
                    pinned: 'left'
                  }
                ]),
            {
              headerName: 'Campaign',
              field: 'campaignId',
              width: undefined,
              enableRtl: true,
              cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
              minWidth: 250,
              maxWidth: 800,
              cellRendererFramework: EntityColumn,
              headerClass: 'align-left',
              pinnedRowCellRenderer: 'simpleColumn',
              pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
              pinned: 'left'
            },
            {
              headerName: 'Portfolio',
              field: 'portfolioId',
              width: undefined,
              enableRtl: false,
              cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important' },
              minWidth: 200,
              maxWidth: 200,
              cellRendererFramework: CampaignPortfolioColumn,
              headerClass: 'align-left',
              isOptional: true,
              pinned: 'left'
            },
            {
              headerName: 'Budget',
              field: 'projectedSpend',
              width: undefined,
              enableRtl: false,
              cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important' },
              minWidth: 150,
              maxWidth: 150,
              cellRendererFramework: BudgetColumn,
              headerClass: 'align-left',
              isOptional: true,
              pinnedRowCellRenderer: 'emptyColumn',
              pinned: 'left'
            },
            ...metricColDef,
            ...(isAdAuditUser || isSearchPage
              ? []
              : [
                  {
                    headerName: 'Status',
                    field: 'campaignId',
                    width: undefined,
                    enableRtl: true,
                    cellStyle: { 'justify-content': 'flex-end', 'text-align': 'left' },
                    minWidth: 65,
                    maxWidth: 100,
                    cellRendererFramework: EntityStatusColumn,
                    headerClass: 'align-right',
                    pinnedRowCellRenderer: 'emptyColumn',
                    pinned: 'right'
                  }
                ])
          ].map(({ headerName, ...colDef }) => ({ ...baseColDef, ...colDef, headerName })),
          adGroupId: [
            {
              headerName: isCriteo() ? 'Line Item' : 'Ad Group',
              field: 'adGroupId',
              width: undefined,
              enableRtl: true,
              cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
              minWidth: 300,
              maxWidth: 800,
              cellRendererFramework: EntityColumn,
              headerClass: 'align-left',
              pinnedRowCellRenderer: 'simpleColumn',
              pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
              pinned: 'left'
            },
            {
              headerName: 'Campaign',
              field: 'campaignId',
              width: undefined,
              enableRtl: true,
              cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
              minWidth: 120,
              maxWidth: 120,
              cellRendererFramework: CampaignPortfolioColumn,
              headerClass: 'align-left',
              pinnedRowCellRenderer: 'simpleColumn',
              pinnedRowCellRendererParams: { text: '', style: { fontWeight: 'bold' } },
              pinned: 'left'
            },
            ...metricColDef
          ].map(({ headerName, ...colDef }) => ({ ...baseColDef, ...colDef, headerName })),
          portfolioId: [
            {
              headerName: 'Portfolio',
              field: 'portfolioId',
              width: undefined,
              enableRtl: true,
              cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
              minWidth: 300,
              maxWidth: 800,
              cellRendererFramework: PortfolioColumn,
              headerClass: 'align-left',
              pinnedRowCellRenderer: 'simpleColumn',
              pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
              pinned: 'left'
            },
            {
              headerName: 'Budget',
              field: 'projectedSpend',
              width: undefined,
              enableRtl: false,
              cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important' },
              minWidth: 150,
              maxWidth: 150,
              cellRendererFramework: BudgetColumn,
              headerClass: 'align-left',
              isOptional: true,
              pinned: 'left'
            },
            {
              headerName: 'Projected Spend',
              field: 'projectedSpend',
              width: undefined,
              enableRtl: false,
              cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important' },
              minWidth: 150,
              maxWidth: 150,
              cellRendererFramework: ProjectedBudgetColumn,
              headerClass: 'align-left',
              isOptional: true,
              pinned: 'left'
            },
            ...metricColDef
          ].map(({ headerName, ...colDef }) => ({ ...baseColDef, ...colDef, headerName })),
          targetingText: [
            ...(isAdAuditUser || isSearchPage
              ? []
              : [
                  {
                    headerName: 'Target',
                    field: 'targetingText',
                    width: undefined,
                    enableRtl: true,
                    cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
                    minWidth: 100,
                    maxWidth: 100,
                    cellRendererFramework: SelectEntityColumn,
                    headerComponentFramework: SelectEntityColumn,
                    pinned: 'left'
                  }
                ]),
            {
              headerName: 'Target',
              field: 'targetingText',
              width: undefined,
              enableRtl: true,
              cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
              minWidth: 300,
              maxWidth: 800,
              cellRendererFramework: EntityColumn,
              headerClass: 'align-left',
              pinnedRowCellRenderer: 'simpleColumn',
              pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
              pinned: 'left'
            },
            {
              headerName: 'Match',
              field: 'entity',
              width: undefined,
              enableRtl: true,
              cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
              minWidth: 100,
              maxWidth: 100,
              valueFormatter: ({ value }) =>
                _upperFirst(splitSnakeCase(_get(value, 'targetingType', '').toLowerCase())),
              headerClass: 'align-left',
              pinnedRowCellRenderer: 'emptyColumn',
              pinned: 'left'
            },
            ...metricColDef,
            ...(!(window.location.pathname.includes('product') || isAdAuditUser || isSearchPage) // Don't show this column on the product page
              ? [
                  {
                    headerName: 'Status',
                    field: 'targetingText',
                    width: undefined,
                    enableRtl: true,
                    cellStyle: { 'justify-content': 'flex-end', 'text-align': 'left' },
                    minWidth: 100,
                    maxWidth: 100,
                    cellRendererFramework: EntityStatusColumn,
                    pinned: 'right'
                  }
                ]
              : [])
          ].map(({ headerName, ...colDef }) => ({ ...baseColDef, ...colDef, headerName })),
          stacklineSku: [
            ...(isAdAuditUser || isSearchPage
              ? []
              : [
                  {
                    headerName: 'Product',
                    field: 'stacklineSku',
                    width: undefined,
                    enableRtl: true,
                    cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
                    minWidth: 100,
                    maxWidth: 100,
                    cellRendererFramework: SelectEntityColumn,
                    headerComponentFramework: SelectEntityColumn,
                    pinned: 'left'
                  }
                ]),
            {
              headerName: 'Product',
              field: 'stacklineSku',
              width: undefined,
              enableRtl: true,
              cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
              minWidth: 300,
              maxWidth: 800,
              cellRendererFramework: EntityColumn,
              headerClass: 'align-left',
              pinnedRowCellRenderer: 'simpleColumn',
              pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
              pinned: 'left'
            },
            ...metricColDef,
            ...(!(window.location.pathname.includes('adTarget') || isAdAuditUser || isSearchPage)
              ? [
                  {
                    headerName: 'Status',
                    field: 'stacklineSku',
                    width: undefined,
                    enableRtl: true,
                    cellStyle: { 'justify-content': 'flex-end', 'text-align': 'left' },
                    minWidth: 100,
                    maxWidth: 100,
                    cellRendererFramework: EntityStatusColumn,
                    pinned: 'right'
                  }
                ]
              : [])
          ].map(({ headerName, ...colDef }) => ({ ...baseColDef, ...colDef, headerName })),
          entityId: [
            {
              headerName: 'Entity',
              field: 'entityId',
              width: undefined,
              enableRtl: true,
              cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
              minWidth: 300,
              maxWidth: 800,
              cellRendererFramework: EnhanceEntityColumn,
              headerClass: 'align-left',
              pinnedRowCellRenderer: 'simpleColumn',
              pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
              pinned: 'left'
            },
            ...metricColDef
          ].map(({ headerName, ...colDef }) => ({ ...baseColDef, ...colDef, headerName }))
        }
      }
    }),
    data: {
      statePropertyName: 'adManagerSearchResultData',
      defaultSortField: INDEX_FIELDS.getField(app.name, indexName, sortedFields[0], entity.type, 'stacklineSku'),
      groupByFields: [INDEX_FIELDS.getField(app.name, indexName, groupByFieldName, entity.type)],
      configByGroupByFieldName,
      termFiltersForGroupByField,
      aggregationFilters: {
        computeFiltersForGroupByField
      },
      lazyLoad: true,
      groupByFieldName,
      entityType: entity.type
    }
  };
};

export const buildAdvancedSearchSideBarWidget = () => ({
  CustomComponent: AdManagerSearchSideBar,
  name: 'adManagerSearchSideBar',
  view: {
    name: 'adManagerSearchSideBar',
    container: { style: { width: 'auto', paddingTop: 42 } },
    hideByDefault: false
  },
  data: { useInternalState: false }
});

const tabDefinitionsByEntityTypeOld = {
  client: [
    { displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' },
    { displayName: 'Portfolios', groupByFieldName: 'portfolioId', entityName: 'adPortfolio' },
    tabDefinitionTarget,
    {
      displayName: 'Products',
      groupByFieldName: 'stacklineSku',
      entityName: 'product',
      addCampaignColumn: true,
      cellRendererFramework: EntityColumn
    },
    { displayName: 'Entities', groupByFieldName: 'entityId', entityName: 'adEntity' }
  ],
  segment: [
    { displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' },
    { displayName: 'Portfolios', groupByFieldName: 'portfolioId', entityName: 'adPortfolio' },
    tabDefinitionTarget,
    {
      displayName: 'Products',
      groupByFieldName: 'stacklineSku',
      entityName: 'product',
      addCampaignColumn: true,
      cellRendererFramework: EntityColumn
    },
    { displayName: 'Entities', groupByFieldName: 'entityId', entityName: 'adEntity' }
  ],
  adEntity: [
    { displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' },
    { displayName: 'Portfolios', groupByFieldName: 'portfolioId', entityName: 'adPortfolio' },
    tabDefinitionTarget,
    {
      displayName: 'Products',
      groupByFieldName: 'stacklineSku',
      entityName: 'product',
      addCampaignColumn: true,
      cellRendererFramework: EntityColumn
    }
  ],
  adPortfolio: [
    { displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' },
    tabDefinitionTarget,
    {
      displayName: 'Products',
      groupByFieldName: 'stacklineSku',
      entityName: 'product',
      addCampaignColumn: true,
      cellRendererFramework: EntityColumn
    }
  ],
  adCampaign: [
    {
      displayName: 'Products',
      groupByFieldName: 'stacklineSku',
      entityName: 'product',
      addCampaignColumn: true,
      cellRendererFramework: EntityColumn
    },
    tabDefinitionTarget,
    {
      displayName: 'Ad Groups',
      groupByFieldName: 'adGroupId',
      entityName: 'adGroup',
      cellRendererFramework: EntityColumn
    }
  ],
  adGroup: [
    {
      displayName: 'Products',
      groupByFieldName: 'stacklineSku',
      entityName: 'product',
      addCampaignColumn: true,
      cellRendererFramework: EntityColumn
    },
    tabDefinitionTarget
  ],
  adTarget: [
    { displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' },
    {
      displayName: 'Products',
      groupByFieldName: 'stacklineSku',
      entityName: 'product',
      addCampaignColumn: true,
      cellRendererFramework: EntityColumn
    }
  ],
  product: [
    { displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' },
    tabDefinitionTarget
  ],
  scheduledActionsCreate: [
    { displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' },
    tabDefinitionTarget,
    {
      displayName: 'Products',
      groupByFieldName: 'stacklineSku',
      entityName: 'product',
      addCampaignColumn: true,
      cellRendererFramework: EntityColumn
    }
  ],
  ineligibleProduct: [
    {
      displayName: 'Products',
      groupByFieldName: 'stacklineSku',
      entityName: 'product',
      addCampaignColumn: true,
      cellRendererFramework: EntityColumn
    }
  ],
  budgetConstraints: [{ displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' }],
  brand: [
    { displayName: 'Campaigns', groupByFieldName: 'campaignId', entityName: 'adCampaign' },
    {
      displayName: 'Products',
      groupByFieldName: 'stacklineSku',
      entityName: 'product',
      addCampaignColumn: true,
      cellRendererFramework: EntityColumn
    },
    tabDefinitionTarget
  ]
};

export const buildTabbedSearchResultGridWidget = ({
  app,
  entity,
  eventBus,
  tabActions,
  searchResultsGridWidgetDataOverride,
  searchResultsGridWidgetGridOptOverride,
  headerWidgetDataOverride = {},
  hideTab = [],
  fieldsOverride
}: {
  app: ReduxStore['app'];
  entity: { type: string; [key: string]: any };
  eventBus: EventBus;
  tabActions?: { icon: any; name: string }[];
  searchResultsGridWidgetDataOverride: { [key: string]: any };
  searchResultsGridWidgetGridOptOverride?: { [key: string]: any };
  headerWidgetDataOverride?: { [key: string]: any };
  hideTab?: string[];
  fieldsOverride?: string[];
}) => {
  //  OLD code

  //
  const isCriteoAllowed = shouldShowCriteo();
  const { retailer, user } = store.getState();
  const pp = getParentPlatform();

  const tabDefinitionsByEntityType = isCriteoAllowed ? getTabDefinitions(pp, entity) : tabDefinitionsByEntityTypeOld;

  let tabDefinitions = tabDefinitionsByEntityType[entity.type].filter((item) => !hideTab.includes(item.displayName));

  if (retailer.id === '63') {
    if (['client', 'adEntity', 'adPortfolio'].includes(entity.type)) {
      tabDefinitions.push({
        displayName: 'Ad Groups',
        groupByFieldName: 'adGroupId',
        entityName: 'adGroup',
        cellRendererFramework: EntityColumn
      });
    }
    tabDefinitions = tabDefinitions.filter((item) => item.displayName !== 'Entities');
  }

  if (entity.type === 'segment') {
    const querys = {
      ...JSON.parse(entity.query)
    };

    if (querys.segmentType) {
      tabDefinitions = tabDefinitions.filter((tabDef) => tabDef.groupByFieldName === querys.segmentType);
    }
  }
  const tabbedWidget = buildTabbedWidget(
    tabDefinitions.map(({ displayName, groupByFieldName }: { displayName: string; groupByFieldName: string }) => {
      const searchResultsGridWidget = buildSearchResultGridWidget({
        app,
        entity,
        groupByFieldName,
        user,
        fieldsOverride
      });
      searchResultsGridWidget.data = {
        ...searchResultsGridWidget.data,
        ...searchResultsGridWidgetDataOverride
      };
      searchResultsGridWidget.view.gridOptions = {
        ...searchResultsGridWidget.view.gridOptions,
        ...searchResultsGridWidgetGridOptOverride
      };
      return {
        displayName,
        widget: searchResultsGridWidget
      };
    }),
    {
      view: {
        container: {
          style: {
            width: '100%',
            maxWidth: 1370
          }
        }
      }
    },
    { marginTop: 13, marginBottom: 30 },
    (newTabIx) => eventBus.emit('entityTabChange', { ...tabDefinitions[newTabIx] }),
    tabActions,
    (action) => eventBus.emit(AdManagerEvents.ENTITY_EVENT_METRICS_GRID_ACTION_CLICKED, { name: action.name })
  );

  const headerWidget = {
    name: 'search_header',
    CustomComponent: SearchResultsHeader,
    view: {
      container: {
        className: 'advertising-sticky-search_header_widget_wrapper'
      }
    },
    data: headerWidgetDataOverride
  };

  return buildWidgetGroup([headerWidget, tabbedWidget], {
    view: {
      noInnerContainer: true,
      container: {
        style: {
          width: '100%',
          maxWidth: 1370,
          margin: 'auto',
          marginTop: 45
        }
      }
    }
  });
};

export const getLayoutForEntity = ({
  app,
  entity,
  eventBus
}: {
  app: ReduxStore['app'];
  entity: { type: string; [key: string]: any };
  eventBus: EventBus;
}): PageLayout => {
  const tabbedSearchResultGridWidget = buildTabbedSearchResultGridWidget({
    app,
    entity,
    eventBus,
    searchResultsGridWidgetDataOverride: { useInternalState: false }
  });

  return {
    CustomPageContainer: GenericPageContainer,
    containerStyle: { flexDirection: 'row' },
    widgets: [buildAdvancedSearchSideBarWidget(), tabbedSearchResultGridWidget]
  };
};
