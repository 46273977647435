import ArrowRight from '!svg-react-loader!./assets/arrowRight.svg'; // eslint-disable-line
import { SlRow, SlStatusChip, SlStatusChipColor, Text, useStacklineTheme } from '@stackline/ui';
import { History } from 'history';
import { withRouter } from 'react-router';
import { border_grey } from 'src/components/BeaconRedesignComponents/styleUtils';
import React from 'react';
import {
  Direction,
  getColorForMetricChangeValue,
  getIconForMetricChange
} from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import { SlColumn } from 'src/components/BeaconRedesignComponents/Generic/SlColumn';

export interface ProductGrowthMatrixCardProps {
  imageUri: string;
  mainDisplayTitle: string;
  secondaryDisplayTitle: string;
  mainMetric: string;
  secondaryMetric: string;
  trafficIndex: string;
  conversionIndex: string;
  direction: Direction;
  statusCode: string;
  statusColor: SlStatusChipColor;
  detailsLink: string;
}

export const ProductGrowthMatrixCardInner = ({
  imageUri,
  mainDisplayTitle,
  secondaryDisplayTitle,
  mainMetric,
  secondaryMetric,
  trafficIndex,
  conversionIndex,
  direction,
  statusCode,
  statusColor,
  history,
  detailsLink
}: ProductGrowthMatrixCardProps & { history: History }) => {
  const theme = useStacklineTheme();

  return (
    <div
      style={{
        border: `1px solid ${border_grey}`,
        borderRadius: '4px',
        width: '185px',
        height: '235px',
        backgroundColor: '#fff',
        padding: '12px'
      }}
    >
      <SlColumn horizontalInset="none" verticalInset="none" widths="full" spacing={12}>
        <SlStatusChip color={statusColor} variant="large">
          {statusCode}
        </SlStatusChip>
        <SlRow spacing="sm">
          <img
            key={imageUri}
            src={imageUri}
            style={{ maxHeight: '40px', maxWidth: '40px' }}
            alt={secondaryDisplayTitle || mainDisplayTitle}
          />
          <SlColumn spacing="xs">
            <Text variant="subtitle3">{mainDisplayTitle}</Text>
            <Text variant="body4" truncateLines={2} title={secondaryDisplayTitle}>
              {secondaryDisplayTitle}
            </Text>
          </SlColumn>
        </SlRow>
        <SlColumn spacing="xxs">
          <Text transform="uppercase" variant="subtitle4" color="secondary">
            Retail Sales
          </Text>
          <SlRow spacing="xs" verticalPosition="center">
            <Text variant="body2">{mainMetric}</Text>
            <SlRow verticalPosition="center" spacing="xxs">
              {getIconForMetricChange(null, direction)}
              <Text variant="subtitle4" color={getColorForMetricChangeValue(null, direction)}>
                {secondaryMetric}
              </Text>
            </SlRow>
          </SlRow>
        </SlColumn>
        <SlRow spacing="md">
          <SlColumn spacing="xxs">
            <Text transform="uppercase" variant="subtitle4" color="secondary">
              Traffic Index
            </Text>
            <Text variant="body2">{trafficIndex}</Text>
          </SlColumn>
          <SlColumn spacing="xxs">
            <Text transform="uppercase" variant="subtitle4" color="secondary">
              Conversion Index
            </Text>
            <Text variant="body2">{conversionIndex}</Text>
          </SlColumn>
        </SlRow>
        <Text variant="subtitle4" link onClick={() => history.push(detailsLink)}>
          <SlRow verticalPosition="center" spacing="xs">
            {/* @ts-ignore */}
            <span style={{ fontWeight: theme.text.variants.subtitle4.fontWeight }}>View details</span>
            <ArrowRight
              style={{
                position: 'relative',
                top: '1px'
              }}
            />
          </SlRow>
        </Text>
      </SlColumn>
    </div>
  );
};
export default withRouter(ProductGrowthMatrixCardInner);
