import { StacklineTextVariant } from '@stackline/ui';
import React, { createContext, useState, useCallback } from 'react';
import ProfileInfoPopup, {
  ProfileSuccessPopupProps
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Profile/ProfileInfoPopup';

interface PopupProviderProps {
  children: React.ReactNode;
}

interface PopupContextValue {
  showPopup: (args: ProfileSuccessPopupProps) => void;
  closePopup: () => void;
}

export const PopupContext = createContext<PopupContextValue>(null);

/**
 * Provides an interface for opening a snackbar from anywhere in the app.
 */
export default function PopupProvider({ children }: PopupProviderProps) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupTitleMessage, setPopupTitleMessage] = useState<string>(null);
  const [popupMessage, setPopupMessage] = useState<string>(null);
  const [popupIcon, setPopupIcon] = useState<React.ReactNode>(null);
  const [popupHeight, setPopupHeight] = useState<number>(null);
  const [popupWidth, setPopupWidth] = useState<number>(null);
  const [popupBodyTextVariant, setPopupBodyTextVariant] = useState<StacklineTextVariant>(null);

  const showPopup = useCallback(
    ({ message, icon, width, height, titleMessage, bodyTextVariant }: ProfileSuccessPopupProps) => {
      setPopupMessage(message);
      setPopupOpen(true);
      setPopupIcon(icon);
      setPopupWidth(width);
      setPopupHeight(height);
      setPopupTitleMessage(titleMessage);
      setPopupBodyTextVariant(bodyTextVariant);
    },
    []
  );

  const closePopup = useCallback(() => {
    setPopupOpen(false);
  }, []);

  return (
    <PopupContext.Provider
      value={{
        showPopup,
        closePopup
      }}
    >
      {children}
      <ProfileInfoPopup
        icon={popupIcon}
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        message={popupMessage}
        titleMessage={popupTitleMessage}
        width={popupWidth}
        height={popupHeight}
        bodyTextVariant={popupBodyTextVariant}
      />
    </PopupContext.Provider>
  );
}
