import { useEffect, useMemo, useState } from 'react';
import { useTableData } from './useTableData';
import { ProductEntity } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/types';
import { FETCH_CHUNK_SIZE } from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';

export interface UseRetailSalesSortedSkusArgs {
  itemsPerPage?: number;
  pageNumber?: number;
  /**
   * If true, maintains a running list of all pages of data. If false, only returns the current page of data.
   */
  aggregatePages?: boolean;
}
/**
 * Returns a list of skus sorted by retail sales
 */
export default function useRetailSalesSortedSkus({
  pageNumber = 1,
  aggregatePages = false,
  itemsPerPage = FETCH_CHUNK_SIZE
}: UseRetailSalesSortedSkusArgs = {}) {
  const [pageToData, setPageToData] = useState<Record<number, string[]>>({});
  const { data, isLoading } = useTableData<ProductEntity>({
    fields: [
      {
        name: 'retailSales'
      }
    ],
    indexName: 'sales',
    groupByFieldName: 'stacklineSku',
    itemsPerPage,
    pageNumber,
    requestId: 'sortedSkus'
  });

  useEffect(() => {
    if (isLoading) {
      // Delete the data from pageNumber if it's there
      setPageToData((prevMap) => {
        const newPageToData = { ...prevMap };
        delete newPageToData[pageNumber];
        return newPageToData;
      });
    } else {
      setPageToData((prevMap) => ({
        ...prevMap,
        [pageNumber]: data ? data.map((row) => row.metadata.stacklineSku) : []
      }));
    }
  }, [pageNumber, data, isLoading]);

  const combinedData = useMemo(() => {
    const keys = Object.keys(pageToData).sort((a, b) => Number(a) - Number(b));
    return keys.reduce((acc, key) => {
      return [...acc, ...pageToData[Number(key)]];
    }, []);
  }, [pageToData]);

  const sortedSkus = useMemo(
    () => (aggregatePages ? combinedData : pageToData[pageNumber] || []),
    [aggregatePages, combinedData, pageNumber, pageToData]
  );

  /**
   * Maps the sku to its index in the sorted skus array so that we
   * can display the products in the same order as the sorted skus
   */
  const skuIndexMap = useMemo(
    () =>
      sortedSkus.reduce<Record<string, number>>((acc, sku, index) => {
        acc[sku] = index;
        return {
          [sku.toLowerCase()]: index,
          ...acc
        };
      }, {}),
    [sortedSkus]
  );

  const slicedSkus = useMemo(
    () => sortedSkus.slice((pageNumber - 1) * itemsPerPage, pageNumber * itemsPerPage),
    [itemsPerPage, pageNumber, sortedSkus]
  );

  return {
    data: sortedSkus,
    isLoading: isLoading || !pageToData[pageNumber],
    skuIndexMap,
    slicedSkus
  };
}
