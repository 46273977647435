import React, { FC } from 'react';
import GenericChart from 'src/components/Charts/GenericChart/GenericChart';
import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions/entityDefinitionTypes';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import convertBarChartSeriesToDelimitedData from 'src/components/Charts/GenericChart/SeriesConverters/barChart';
import { Series } from 'highcharts';

export interface OmniContentColumnData {
  fieldValue: string;
  fieldId: string;
  y: number;
  selected: boolean;
}

interface OmniContentColumnProps {
  data: OmniContentColumnData[];
}

const CATEGORY_ORDERS = ['Title', 'Description', 'Bullet', 'Image', 'Video', 'Enhanced', 'Health', 'Label'];

const generateTheChartProps = (retailer: ReduxStore['retailer'], categories: string[]) => ({
  chart: {
    type: 'column',
    height: 350,
    marginTop: 50
  },
  title: {
    text: '',
    align: 'left',
    x: 0,
    y: 0,
    style: {
      color: '#052849',
      'font-size': '22px',
      'font-weight': '400',
      'font-family': 'Roboto, sans-serif'
    }
  },
  xAxis: [
    {
      dateTimeLabelFormats: {
        month: '%b',
        week: '%b %e'
      },
      type: 'categories',
      categories,
      labels: {
        formatter: function defaultColumnChartFormatter(this: { value: number | string }) {
          const { value } = this;
          const valueArray = String(value).split(' ');

          return valueArray[0].length > 8 && valueArray.length > 1
            ? `${valueArray[0]}<br/><div>${valueArray[1]}</div>`
            : value;
        },
        rotation: 0,
        style: {
          color: '#052849',
          'font-size': '11px',
          'font-weight': '400',
          'font-family': 'Roboto, sans-serif'
        }
      },
      crosshair: false,
      lineWidth: 0,
      tickWidth: 0
    }
  ],
  yAxis: [
    {
      gridLineWidth: 0,
      index: 0,
      labels: {
        enabled: false
      },
      rotate: 0,
      title: {
        text: ''
      }
    }
  ],
  tooltip: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  exporting: {
    enabled: true
  },
  credits: false,
  plotOptions: {
    series: {
      borderRadius: 3,
      borderWidth: 0,
      allowPointSelect: true,
      cursor: 'pointer',
      states: {
        select: {
          color: '#052849'
        },
        hover: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: true,
        crop: false,
        overflow: 'none',
        style: {
          color: '#052849',
          'font-size': '11px',
          fontWeight: '400',
          'font-family': 'Roboto, sans-serif'
        },
        formatter: function formatter(this: { y: number }): string {
          const metric = convertMetricToDisplayValue(retailer, this.y, METRICTYPE.PERCENT, '$', true);
          return `${metric}`;
        }
      },
      point: {
        events: {}
      }
    },
    column: {
      fillColor: '#46a8f6',
      fillOpacity: 0.7,
      color: '#46a8f6',
      colorByPoint: false,
      colors: ['#46a8f6'],
      pointWidth: 36
    },
    bar: {
      fillColor: '#46a8f6',
      fillOpacity: 0.7,
      color: '#46a8f6',
      colorByPoint: false,
      colors: ['#46a8f6'],
      pointWidth: 12
    }
  },
  size: 100,
  lang: {
    noData: '<div class="sl-no-data">No data available</div>'
  },
  noData: {
    style: {
      fontWeight: '400',
      fontSize: '20px',
      color: '#d7d9e9'
    }
  },
  disablePointSelect: false,
  ignoreZeroValues: true,
  subtitle: {
    style: {
      'border-bottom': 'none'
    }
  },
  dataLabels: {
    x: 15
  }
});

const OmniContentColumn: FC<OmniContentColumnProps> = ({ data }) => {
  const retailer = useSelector((state: ReduxStore) => state.retailer);

  const orderedData = data.sort((a, b) => CATEGORY_ORDERS.indexOf(a.fieldId) - CATEGORY_ORDERS.indexOf(b.fieldId));

  const simpleChart = [
    {
      type: 'column',
      data: orderedData
    }
  ];

  const categories = data.map((d) => d.fieldId).sort();

  const sortedCategories = categories.sort((a, b) => CATEGORY_ORDERS.indexOf(a) - CATEGORY_ORDERS.indexOf(b));

  return (
    <div style={{ width: '98%' }}>
      <GenericChart
        chartSeries={simpleChart}
        chartProps={generateTheChartProps(retailer, sortedCategories)}
        convertSeriesToDelimitedData={(series: Series[]) => convertBarChartSeriesToDelimitedData(series, retailer)}
      />
    </div>
  );
};

export default OmniContentColumn;
