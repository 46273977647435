import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppName } from 'sl-api-connector';
import colors from 'src/utils/colors';
import { userOperations } from 'src/store/modules/user';
import UserAccountUpload from './UserAccountUpload/UserAccountUpload';
import { isContentUploadEnabled } from 'src/utils/app';

const UserAccountContent = ({ app, entityService, retailer, updateUserAccount, getUploadPageDetail, user }) => {
  if (!entityService.mainEntity) {
    return null;
  }

  const isOmni = app.name === AppName.Omni;
  const uploadDetailPage = isOmni ? 'omniContent' : 'content';
  const uploadType = isOmni ? 'master-content-upload-omni' : 'master-content-upload';

  const { searchParams, hashId, retailers, buttons, getLatestApi } = getUploadPageDetail(uploadDetailPage);
  const contentAccuracyUrl = isOmni
    ? `/client/${entityService.mainEntity.id}${searchParams}&tab=content&subtab=contentAccuracy`
    : `/client/${hashId}${searchParams}&tab=content&subtab=contentAccuracy`;

  const defaultGuideMessage = () => {
    return (
      <div>
        Upload content for products in your catalog. After successfully uploading, you can visit the{' '}
        <a // eslint-disable-line
          style={{ color: colors.blue, cursor: 'pointer' }}
          onClick={() => window.location.assign(contentAccuracyUrl)}
        >
          Content Accuracy
        </a>{' '}
        page to measure how this compares to the live content on the retailer’s website.
      </div>
    );
  };

  let guideMessage;

  if (isOmni) {
    if (isContentUploadEnabled(user.session.email)) {
      guideMessage = defaultGuideMessage();
    } else {
      guideMessage = <div>Reach out to admin users to upload master content.</div>;
    }
  } else {
    guideMessage = defaultGuideMessage();
  }

  return (
    <UserAccountUpload
      headerText="Master Content"
      retailers={retailers}
      currRetailer={retailer}
      buttons={buttons}
      updateUserAccount={updateUserAccount}
      getLatestApi={getLatestApi}
      uploadType={uploadType}
    >
      {guideMessage}
    </UserAccountUpload>
  );
};
UserAccountContent.propTypes = {
  entityService: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired,
  updateUserAccount: PropTypes.func.isRequired,
  getUploadPageDetail: PropTypes.func.isRequired,
  app: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  updateUserAccount: userOperations.updateUserAccount
};

export default connect((state) => state, mapDispatchToProps)(UserAccountContent);
