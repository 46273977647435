// As of Oct 4th 2022, we are still debating that a piwheel or stackline user should have an admin status by default.

import { USER_ROLES as roles, USER_SCOPES as permission } from 'src/utils/constants';

export const userPermissionMap = new Map();
userPermissionMap.set(permission.canViewStacklineUser, [roles.stackline, roles.support]);

userPermissionMap.set(permission.canAddUser, [roles.admin, roles.piwheel, roles.owner, roles.support, roles.stackline]);

userPermissionMap.set(permission.canDeleteUsers, [
  roles.admin,
  roles.piwheel,
  roles.owner,
  roles.support,
  roles.stackline
]);

userPermissionMap.set(permission.canAssignAdmins, [roles.owner, roles.support]);

userPermissionMap.set(permission.canDeleteAdmin, [roles.owner, roles.support]);

userPermissionMap.set(permission.canTransferOwnership, [roles.owner, roles.support]);

userPermissionMap.set(permission.canViewApprovedDomainTab, [
  roles.admin,
  roles.piwheel,
  roles.owner,
  roles.support,
  roles.stackline
]);
userPermissionMap.set(permission.canEditApprovedDomain, [roles.support]);

// taking email argument, to see if we will distinguish piwheel or stackline user
export const roleConverter = (email, role) => {
  switch (role) {
    case 'support':
      return roles.support;
    case 'owner':
      return roles.owner;
    case 'admin':
      return roles.admin;
    default:
      return roles.user;
  }
};

export const hasPermission = (action, user) => {
  if (userPermissionMap.has(action)) {
    return userPermissionMap.get(action).includes(user);
  }
  return false;
};
