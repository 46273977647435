import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _cloneDeep from 'lodash/cloneDeep';
import MenuItem from '@mui/material/MenuItem';
import { comparisonParams, filterParams } from '../../../utils/segments';
import { addQueryParams } from '../../../utils/browser';

class Entities extends React.Component {
  static defaultProps = {
    searchText: '',
    togglePanel: () => {}
  };

  static propTypes = {
    adEntities: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    searchText: PropTypes.string,
    togglePanel: PropTypes.func
  };

  static contextTypes = {
    router: PropTypes.object
  };

  componentWillMount() {
    const { adEntities } = this.props;
    this.entities = _cloneDeep(adEntities);

    this.setState({ entities: this.entities });
  }

  componentWillReceiveProps(nextProps) {
    const { searchText } = nextProps;

    const filteredEntities = this.entities.filter(
      (val) => val.displayName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    );
    this.setState({
      entities: filteredEntities
    });
  }

  handleTouchTap = (selection) => {
    const { togglePanel, location, retailer, mainTimePeriod } = this.props;
    const entity = {
      id: selection.id,
      type: 'adEntity'
    };

    const existingParams = addQueryParams(location, retailer, mainTimePeriod, [...filterParams, ...comparisonParams]);

    togglePanel();
    this.context.router.history.push(`?ctype=${entity.type}&cid=${entity.id}&${existingParams}`);
  };

  showNoEntities() {
    const { entities } = this.state;

    if (entities.length > 0) {
      return null;
    }

    return <div style={{ padding: '10px' }}>No entities found.</div>;
  }

  render() {
    const { entities } = this.state;
    return (
      <div style={{ padding: '10px' }}>
        {entities.map((val) => (
          <MenuItem key={val.categoryId} style={{ fontSize: '14px' }} onClick={() => this.handleTouchTap(val)}>
            {val.displayName}
          </MenuItem>
        ))}
        {this.showNoEntities()}
      </div>
    );
  }
}

export default withRouter(connect((state) => state)(Entities));
