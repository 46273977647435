/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Map, Set } from 'immutable';
import TextField from '@mui/material/TextField';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _orderBy from 'lodash/orderBy';

import './ColumnSelect.scss';

export interface SelectItem {
  displayName: string | number;
  id: string | number;
}

export const Column: React.FC<{ items: SelectItem[]; onSelect: (item: SelectItem) => void; remove?: boolean }> = ({
  items,
  onSelect,
  remove
}) => (
  <div className={`two-column-select-column${remove ? ' remove' : ''}`}>
    {items.map((item) => (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <div
        key={item.id}
        title={item.id.toString()}
        role="listitem"
        className="select-item"
        onClick={() => onSelect(item)}
      >
        {item.displayName}
      </div>
    ))}
  </div>
);

export const sortByDisplayName = (items: SelectItem[]): SelectItem[] => _orderBy(items, ['displayName'], ['asc']);

const TwoColumnSelect: React.FC<{
  corpus: Map<string | number, SelectItem>;
  selected: Set<string | number>;
  onChange: (newSelected: Set<string | number>) => void;
}> = ({ corpus, selected, onChange }) => {
  const [unselectedFilter, setUnselectedFilter] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');

  return (
    <div className="two-column-select">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h4>Unselected</h4>
        <TextField
          variant="standard"
          autoComplete="off"
          value={unselectedFilter}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUnselectedFilter(event.target.value.toLowerCase());
          }}
          placeholder="Search by ID or Name"
          style={{ paddingBottom: 10, width: 220 }}
        />
        <Column
          items={sortByDisplayName(
            [...corpus.values()].filter(({ id, displayName }) => {
              if (selected.has(id)) {
                return false;
              }

              if (_isEmpty(unselectedFilter)) {
                return true;
              }

              return (
                id.toString() === unselectedFilter || displayName.toString().toLowerCase().includes(unselectedFilter)
              );
            })
          )}
          onSelect={({ id }) => onChange(selected.add(id))}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 16 }}>
        <h4>Selected</h4>
        <TextField
          variant="standard"
          autoComplete="off"
          value={selectedFilter}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedFilter(event.target.value.toLowerCase());
          }}
          placeholder="Search by ID or Name"
          style={{ paddingBottom: 10, width: 220 }}
        />
        <Column
          remove
          items={selected
            .toArray()
            .map((id) => {
              const item = corpus.get(id);
              return _isNil(item) ? { displayName: `Category ID ${id}`, id } : item;
            })
            .filter(({ id, displayName }) => {
              if (_isEmpty(selectedFilter)) {
                return true;
              }

              return id.toString() === selectedFilter || displayName.toString().toLowerCase().includes(selectedFilter);
            })}
          onSelect={({ id }) => onChange(selected.remove(id))}
        />
      </div>
    </div>
  );
};

export default TwoColumnSelect;
