import axios from 'axios';

/**
 * Gets a presigned URL for uploading a user's profile image.
 * This is only to be used on the signup page.
 */
export default async function userPreSignedUrl(file: File, userEmail: string) {
  const response = await axios.post<{ url: string }>('/api/userprofiledocument/UserPreSignedUrl', {
    userEmail,
    documentType: 'ProfileImage'
  });

  const { url } = response.data;
  await axios.put(url, file);
}
