/* eslint-disable react/prop-types */
import React from 'react';
import queryString from 'qs';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import _isEmpty from 'lodash/isEmpty';

import { addPersistentQueryParams } from 'src/utils/browser';
import { Search } from '.';
import ReduxStore from 'src/types/store/reduxStore';
import { AppName } from 'sl-api-connector/types';
import { ADVANCED_SEARCH_KEYWORD_PARAMS } from 'src/utils/constants';

const instacartUpcSearchStringPreprocessing = (searchString: string, retailer: ReduxStore['retailer']): string[] => {
  if (retailer.id === '63') {
    // all instarcartUpc is 10 to 13 length digit
    // if the user input the upc include leading 0, it's ok
    const instarcartUpcReg = /^\d{10,14}$/;

    // support multi upc search, get a valid upc array from input
    // to use the multi upc search the input should be like example:00044600324920 00071100005783
    // space is Delimiter;
    const searchStringArr = searchString.split(' ');
    const searchStringArrFilterExtraSpace = searchStringArr.filter((s) => s !== '');
    let seenInvalidUPC = false;
    const PreprocessedStringArr = searchStringArrFilterExtraSpace.map((s) => {
      if (instarcartUpcReg.test(s)) {
        // add leading 0, because the backend upc has leading 0.
        const zeroNeedToAdd = 14 - s.length;
        return `${'0'.repeat(zeroNeedToAdd)}${s}`;
      } else {
        seenInvalidUPC = true;
        return s;
      }
    });
    if (seenInvalidUPC) {
      return [searchString];
    }
    return PreprocessedStringArr;
  }
  return [searchString];
};

export const handleSearchChange = (
  selection: Event & { id: number },
  index: number,
  appName: ReduxStore['app']['name'],
  retailer: ReduxStore['retailer'],
  mainTimePeriod: ReduxStore['mainTimePeriod'],
  push: RouteComponentProps['history']['push'],
  isAtlas: boolean
) => {
  const persistentQueryParams = addPersistentQueryParams(retailer, mainTimePeriod);
  if (index !== -1) {
    // Exact match found for product ID, so pull up its product page.
    push(`/product/${selection.id}?${persistentQueryParams}`);
  } else if (_isEmpty(selection)) {
    // If an empty search string is provided, just bring up the advanced search page with no filters.
    push(`/search?${persistentQueryParams}&entityType=segment`);
  } else {
    if (appName === AppName.Advertising) {
      // No exact match found, so pull up advanced search page with the entry as an inclusive campaign name filter.
      const selectionQueryParams = queryString.stringify(
        ADVANCED_SEARCH_KEYWORD_PARAMS.reduce<{ [key: string]: { i: string }[] }>((prevObj, keyword) => {
          return {
            ...prevObj,
            [keyword]: [{ i: selection }]
          };
        }, {})
      );
      push(`/search?${persistentQueryParams}&entityType=segment&${selectionQueryParams}`);
      return;
    }
    {
      // No exact match found, so pull up advanced search page with the entry as an inclusive keyword filter.
      // Use keyword phrase matching on atlas dev
      const searchString = String(selection);
      const PreprocessedStringArr = instacartUpcSearchStringPreprocessing(searchString, retailer);
      const selectionQueryParams = queryString.stringify(
        isAtlas
          ? {
              kp: PreprocessedStringArr.map((oneString) => ({
                i: oneString
              }))
            }
          : {
              k: PreprocessedStringArr.map((oneString) => ({
                i: oneString
              }))
            }
      );
      push(`/search?${persistentQueryParams}&entityType=segment&${selectionQueryParams}`);
    }
  }
};

const mapStateToProps = ({ app, retailer, mainTimePeriod }: ReduxStore) => ({
  appName: app.name,
  isAtlas: app.name === 'atlas',
  retailer,
  mainTimePeriod
});

type HeaderSearchContainerProps = RouteComponentProps &
  ReturnType<typeof mapStateToProps> & {
    appName: string;
  };

const HeaderSearchContainer: React.FC<HeaderSearchContainerProps> = ({
  appName,
  retailer,
  mainTimePeriod,
  isAtlas,
  history
}) => (
  <Search
    handleSearchChange={(selection, index) =>
      handleSearchChange(selection as any, index, appName, retailer, mainTimePeriod, history.push, isAtlas)
    }
  />
);

const WrappedHeaderSearchContainer = withRouter(connect(mapStateToProps)(HeaderSearchContainer));
export default WrappedHeaderSearchContainer;
