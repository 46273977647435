/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { store } from 'src/main';
import { AggregationField } from 'src/types/application/types';
import PortfolioColumn from 'src/components/AdManager/Search/CustomColumns/PortfolioColumn';
import PacingColumn from 'src/components/AdManager/Search/CustomColumns/PacingColumn';
import DataColumn from 'src/components/AdManager/Search/CustomColumns/DataColumn';
import { buildFullMonthRangeFilters } from 'src/utils/dateformatting';
import ReduxStore from 'src/types/store/reduxStore';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import CustomAgGridHeaderTemplate from 'src/components/EntityGrid/Table/CustomAgGridHeaderTemplate';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import './AdOptimizationHistory.scss';
import { adPortfolioOperations } from 'src/store/modules/adManager/adPortfolios';
import {
  buildAggregations,
  buildTimePeriodRangeFilters,
  zipMetricsResponseIntoArray
} from 'src/components/AdManager/Search';

const onGridReady = (params: any) => {
  params.api.sizeColumnsToFit();
  params.api.refreshHeader();
};

const baseColDef = {
  disableSort: true,
  width: 100
};

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['app', 'comparisonTimePeriod', 'mainTimePeriod', 'retailer']),
  mainEntity: state.entityService.mainEntity
});

const mapDispatchToProps = {
  fetchAllAdPortfolios: adPortfolioOperations.fetchAdPortfolios
};

const BudgetPacingGrid: React.FunctionComponent<
  ReturnType<typeof mapStateToProps> & { portfolioIds: string[]; Nodata: any }
> = ({ ...props }) => {
  const [isLoading, setLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortFieldName, setSortFieldName] = useState('clicks');
  const [dataSet, setDataSet] = useState([]);

  const { mainEntity, retailer, app, mainTimePeriod, comparisonTimePeriod, portfolioIds, Nodata } = props;
  const groupByFieldName = 'portfolioId';

  const handleSortClick = (field: string) => {
    let newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    if (sortFieldName !== field) {
      newSortDirection = 'desc';
    }
    // @ts-ignore
    setSortDirection(newSortDirection);
    setSortFieldName(field);
  };

  const GRID_COLUMN_DEFINITIONS: any[] = [
    {
      headerName: 'Portfolio',
      field: 'portfolioId',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 200,
      maxWidth: 800,
      cellRendererFramework: PortfolioColumn,
      headerClass: 'align-left'
    },
    {
      headerName: 'Budget Pacing',
      field: 'mtdSpend',
      width: undefined,
      enableRtl: false,
      disableSort: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important' },
      minWidth: 180,
      maxWidth: 180,
      cellRendererFramework: PacingColumn,
      headerClass: 'align-left',
      isOptional: true
    },
    {
      headerName: 'Ad Clicks',
      cellRendererFramework: DataColumn,
      field: 'clicks',
      minWidth: 130,
      maxWidth: 130,
      disableSort: false,
      sortDirection,
      sortByMetric: sortFieldName,
      headerComponentFramework: CustomAgGridHeaderTemplate,
      cellStyle: { 'justify-content': 'flex-end', 'text-align': 'right !important', 'padding-right': '20px' },
      onSortFunction: () => {
        handleSortClick('clicks');
      }
    },
    {
      headerName: 'Ad Spend',
      cellRendererFramework: DataColumn,
      field: 'spend',
      disableSort: false,
      sortDirection,
      sortByMetric: sortFieldName,
      headerComponentFramework: CustomAgGridHeaderTemplate,
      cellStyle: { 'justify-content': 'flex-end', 'text-align': 'right !important', 'padding-right': '20px' },
      onSortFunction: () => {
        handleSortClick('spend');
      }
    },
    {
      headerName: 'Ad Sales',
      cellRendererFramework: DataColumn,
      field: 'sales',
      sortDirection,
      sortByMetric: sortFieldName,
      disableSort: false,
      headerComponentFramework: CustomAgGridHeaderTemplate,
      cellStyle: { 'justify-content': 'flex-end', 'text-align': 'right !important', 'padding-right': '20px' },
      onSortFunction: () => {
        handleSortClick('sales');
      }
    },
    {
      headerName: 'CPC',
      cellRendererFramework: DataColumn,
      field: 'costPerClick',
      sortDirection,
      sortByMetric: sortFieldName,
      disableSort: false,
      headerComponentFramework: CustomAgGridHeaderTemplate,
      cellStyle: { 'justify-content': 'flex-end', 'text-align': 'right !important', 'padding-right': '20px' },
      onSortFunction: () => {
        handleSortClick('costPerClick');
      }
    },
    {
      headerName: 'ROAS',
      cellRendererFramework: DataColumn,
      field: 'returnOnAdSpend',
      sortDirection,
      sortByMetric: sortFieldName,
      disableSort: false,
      headerComponentFramework: CustomAgGridHeaderTemplate,
      cellStyle: { 'justify-content': 'flex-end', 'text-align': 'right !important', 'padding-right': '20px' },
      onSortFunction: () => {
        handleSortClick('returnOnAdSpend');
      }
    }
  ].map(({ headerName, ...colDef }) => ({ ...baseColDef, ...colDef, displayName: headerName }));

  const fetchData = async () => {
    if (portfolioIds.length === 0) {
      setDataSet([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    const indexName = 'adPortfolioDailyMetrics';
    const pageSize = 2000;
    const statePropertyName = `budgetPacingGrid_${mainEntity.id}`;
    const widget = {
      view: {
        gridOptions: { pageSize }
      },
      data: { statePropertyName }
    };
    const fields: AggregationField[] = [
      'clicks',
      'spend',
      'sales',
      'unitsSold',
      'costPerClick',
      'returnOnAdSpend',
      'costPerAcquisition',
      'adCostOfSales'
    ].map((fieldName) => INDEX_FIELDS.getField(app.name, indexName, fieldName));
    const [{ aggregations: aggregationFields }] = buildAggregations(fields);
    const entityConditions = {
      termFilters: []
    };
    entityConditions.termFilters.push({
      fieldName: 'portfolioId',
      condition: 'must',
      values: portfolioIds
    });
    const { mainTimePeriodRangeFilters, comparisonTimePeriodRangeFilters } = buildTimePeriodRangeFilters({
      app,
      indexName,
      mainTimePeriod,
      comparisonTimePeriod
    });

    const searchRequestOverrides = [
      {
        doAggregation: true,
        aggregations: [
          {
            aggregationFields,
            conditions: {
              termFilters: [
                { fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] },
                {
                  fieldName: 'isProjected',
                  condition: 'must',
                  values: [0]
                }
              ],
              rangeFilters: [...mainTimePeriodRangeFilters]
            },
            sortDirection,
            sortByAggregationField: aggregationFields.find((x) => x.aggregateByFieldName === sortFieldName),
            comparisonRangeFilters: comparisonTimePeriodRangeFilters,
            groupByFieldName
          }
        ],
        conditions: {
          termFilters: entityConditions.termFilters,
          rangeFilters: [...mainTimePeriodRangeFilters]
        },
        pageSize,
        processDocuments: false
      }
    ];
    const allAdPortfolioDocs = await props.fetchAllAdPortfolios();
    const adPortfolioDocs = allAdPortfolioDocs.adPortfolios.filter((x: any) => portfolioIds.includes(x.id));

    const result = await store.dispatch(
      fetchEntityMetrics(
        statePropertyName,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName,
          customResponseParser: (action: any) => zipMetricsResponseIntoArray(action, widget)
        },
        searchRequestOverrides,
        null,
        true
      )
    );
    // add documents for each portfolio that doesn't exist over here
    const adPortfolioMetricDoc = new Map();
    result.data.forEach((metricDoc: any) => {
      if (!adPortfolioMetricDoc.has(metricDoc.id)) {
        adPortfolioMetricDoc.set(metricDoc.id, metricDoc);
      }
    });
    adPortfolioDocs.forEach((adPortfolioDoc: any) => {
      if (!adPortfolioMetricDoc.has(adPortfolioDoc.portfolioId || adPortfolioDoc.id)) {
        result.data.push({
          entity: { ...adPortfolioDoc, name: adPortfolioDoc.name, type: 'adPortfolio' },
          id: adPortfolioDoc.id.toLowerCase()
        });
      }
    });

    const mtdSpendIndexName = 'adCampaignDailyMetrics';
    const mtdSpendField = [INDEX_FIELDS.getField(app.name, mtdSpendIndexName, 'spend')];
    const [{ aggregations: mtdSpendAggregations }] = buildAggregations(mtdSpendField);
    const currentMonthRangeFilters = buildFullMonthRangeFilters();

    const mtdSpend = await store.dispatch(
      fetchEntityMetrics(
        `${statePropertyName}_documents`,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName: mtdSpendIndexName,
          customResponseParser: (action: any) => zipMetricsResponseIntoArray(action, widget)
        },
        [
          {
            doAggregation: true,
            aggregations: [
              {
                aggregationFields: mtdSpendAggregations,
                conditions: {
                  termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
                  rangeFilters: currentMonthRangeFilters
                },
                groupByFieldName
              }
            ],
            conditions: {
              termFilters: entityConditions.termFilters,
              rangeFilters: currentMonthRangeFilters
            },
            pageSize,
            processDocuments: false
          }
        ],
        null,
        true
      )
    );

    const mtdSpendData = _get(mtdSpend, ['data']);
    result.data.forEach((dataPoint: any) => {
      const portfolioId = dataPoint.id;
      const mtdSpendMetric = mtdSpendData.find((item) => item.id === portfolioId);
      dataPoint.mtdSpend = _get(mtdSpendMetric, 'spend', {
        name: _get(dataPoint, ['entity', 'portfolioName'], ''),
        value: 0
      });
    });

    const cloneResult = result;

    if (cloneResult && cloneResult.data) {
      setDataSet(cloneResult.data);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainEntity, mainTimePeriod, retailer.id, sortDirection, sortFieldName, portfolioIds]);

  const renderGrid = () => {
    return (
      <div className="recommendations_grid">
        <CustomAgMaterial
          onGridReady={onGridReady}
          onCellValueChanged={onGridReady}
          onModelUpdated={onGridReady}
          onRowValueChanged={onGridReady}
          onRowDataChanged={onGridReady}
          buildRows={() => dataSet}
          suppressNoRowsOverlay
          columnDefs={GRID_COLUMN_DEFINITIONS}
          getRowNodeId={(datum: any) => escape(datum.id)}
          domLayout="autoHeight"
          rowHeight={120}
          containerStyle={{ width: '100%' }}
        />
        {_isEmpty(dataSet) && <Nodata />}
      </div>
    );
  };

  if (!mainEntity) {
    return null;
  }

  return <div className="recommendations_container">{isLoading ? <GridLoading /> : renderGrid()}</div>;
};

const EnhancedBudgetPacingGrid = connect(mapStateToProps, mapDispatchToProps)(BudgetPacingGrid);

export default EnhancedBudgetPacingGrid;
