/**
 * Collection of functions that make calls to the server
 * for creating bulk adjustments
 */

import axios from 'axios';
import { ServerProxy, UploadBulkAdjustmentResponse } from './types';

export default function createBulkAdjustmentServerProxy(args: {
  userId: string;
  retailerId: number;
  beaconClientId: number;
}): ServerProxy {
  const { beaconClientId, retailerId, userId } = args;

  return {
    uploadBulkAdjustment: async (data) => {
      const { bulkAdjustmentFile, ...rest } = data;
      const response = await axios.post<UploadBulkAdjustmentResponse>(
        '/api/beaconbulkadjustment/UploadBulkAdjustment',
        {
          ...rest,
          userId,
          retailerId,
          beaconClientId
        }
      );

      // Upload the file to the presigned URL
      const { url } = response.data;
      await axios.put(url, bulkAdjustmentFile); // TODO should this be PUT or POST?

      return response.data;
    },
    updateUserUploadBulkAdjustmentStatus: async (data) => {
      const response = await axios.post('/api/beaconbulkadjustment/UpdateBulkAdjustmentUploadStatus', {
        ...data,
        userId,
        retailerId,
        beaconClientId
      });
      return response.data;
    },
    validateBulkAdjustment: async (data) => {
      const response = await axios.post('/api/beaconbulkadjustment/ValidateBulkAdjustment', {
        ...data,
        userId,
        retailerId,
        beaconClientId
      });
      return response.data;
    },
    pollBulkAdjustments: async (data) => {
      const response = await axios.post('/api/beaconbulkadjustment/GetBulkAdjustmentStatusForId', {
        ...data,
        userId,
        retailerId,
        beaconClientId
      });
      return response.data;
    },
    publishBulkAdjustment: async (data) => {
      const response = await axios.post('/api/beaconbulkadjustment/PublishBulkAdjustment', {
        ...data,
        userId,
        retailerId,
        beaconClientId
      });
      return response.data;
    },
    computeNetImpactForBulkAdjustment: async (data) => {
      const response = await axios.post('/api/beaconbulkadjustment/ComputeNetImpactForBulk', {
        ...data,
        userId,
        retailerId,
        beaconClientId
      });
      return response.data;
    },
    revertBulkAdjustment: async (data) => {
      const response = await axios.post('/api/beaconbulkadjustment/RevertBulkAdjustment', {
        ...data,
        userId,
        retailerId,
        beaconClientId
      });
      return response.data;
    }
  };
}
