import React from 'react';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import SummaryKeyMetricCards from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Summary/SummaryKeyMetricCards';
import SummaryKeyMetricGrid from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Summary/SummaryKeyMetricGrid';
import { EnhancedCommonSummaryInfoWithLegend } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { useMainEntityType } from 'src/utils/Hooks/reduxSelectors';
import { buildSubtitleDisplayName } from 'src/utils/filters';

/**
 * The first group of visualizations on the Key Metrics page
 * @returns a component that renders the legend, key metric cards, and key metric grid
 */
export const KeyMetricsPageLayout = () => {
  const entityType = useMainEntityType();

  return (
    <BeaconPageContainer>
      <EnhancedCommonSummaryInfoWithLegend
        widget={{
          view: {
            buildSubTitle: ({ retailer, mainEntity, filters, categories, app }) =>
              mainEntity ? buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app) : 'Summary',

            title: 'Key Metrics'
          }
        }}
      />
      <SummaryKeyMetricCards />
      {entityType !== 'product' && <SummaryKeyMetricGrid />}
    </BeaconPageContainer>
  );
};
