/* eslint-disable react/prop-types */
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { AppButton, AppInput, AppSnackbar, brandclub_colors } from '@stackline/ui';
import axios from 'axios';
import colors from 'src/utils/colors';
import _get from 'lodash/get';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import Loading from 'src/components/common/Loading';
import pluralize from 'pluralize';
import {
  parseUploadedItems,
  TextureBackgroundArea,
  validateFile,
  validateFileSize
} from 'src/components/Search/AdvancedSearch/BulkUpload/BulkUploadDialog';
import { CrossIcon } from 'src/components/SvgIcons';
import { Div } from 'src/types/application/types';
import ReduxStore from 'src/types/store/reduxStore';
import { DOMAIN_VALIDATOR_REGEX, EMAIL_VALIDATOR_REGEX } from 'src/utils/validators';
import { Operations } from './UserTeamManagement';

type Dispatcher<S> = Dispatch<SetStateAction<S>>;

type ParsedUserData = any;

export interface UserRow {
  email: string;
  firstName: string;
  lastName: string;
  role: 'support' | 'owner' | 'admin' | 'basic' | 'user';
  emailVerified: boolean;
  userOption: JSX.Element;
}

export interface DomainRow {
  domain: string;
  userOption: JSX.Element;
}

/**
 * Takes the contents of a CSV file and returns an array of objects
 * representing new users. Used for bulk uploading users in the
 * Settings page.
 */
export const bulkUserUploadFileParser = (userArray: string[]) => {
  const result = [];
  for (let i = 1; i < userArray.length; i++) {
    const currentUser = userArray[i].split(',');
    const userFirstName = currentUser[0];
    const userLastName = currentUser[1];
    const userEmail = currentUser[2];

    result.push({ firstName: userFirstName, lastName: userLastName, email: userEmail });
  }

  return result;
};

/**
 * Returns true if the uploaded CSV file is valid, meaning each row
 * contains a first name, last name, and valid email
 */
export function isValidBulkUserCsvFile(csvRows: string[]) {
  if (csvRows.length < 1) {
    return false;
  }
  const firstRowValues = csvRows[0].split(',');
  const validHeaders = ['FirstName', 'LastName', 'Email'].reduce((validSoFar, headerName, index) => {
    if (!validSoFar) {
      return false;
    }
    return firstRowValues[index] === headerName;
  }, true);

  if (!validHeaders) {
    return false;
  }

  return csvRows.slice(1).reduce((validSoFar, row) => {
    if (!validSoFar) {
      return false;
    }
    const rowValues = row.split(',');
    if (rowValues.length !== 3) {
      return false;
    }
    const [firstName, lastName, email] = rowValues;
    if (!firstName || !lastName || !EMAIL_VALIDATOR_REGEX.test(email)) {
      return false;
    }
    return true;
  }, true);
}

const UserTeamManagementForm = ({
  tab,
  handleCloseBtn,
  isBulkUpload,
  setIsBulkUpload,
  setAllApprovedDomains,
  setAllUserInDivision
}: {
  tab: 'team' | 'domain';
  handleCloseBtn: () => void;
  isBulkUpload: boolean;
  setIsBulkUpload: Dispatcher<boolean>;
  // TODO fix this
  allApprovedDomains: any;
  setAllApprovedDomains: Dispatcher<any>;
  setAllUserInDivision: Dispatcher<any>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isMultiEmailValid, setIsMultiEmailValid] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [multiUserEmail, setMultiUserEmail] = useState<string>('');
  const [domain, setDomain] = useState<string>('');

  const [isMultiAccount, setIsMultiAccount] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [bulkErrorMessage, setBulkErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [bulkSuccessMessage, setBulkSuccessMessage] = useState<string | null>(null);
  const { user: userFromRedux } = useSelector(({ user }: ReduxStore) => ({ user }));

  const currentUserEmail = _get(userFromRedux, ['config', 'profile', 'email']);
  const storeUserRole = _get(userFromRedux, ['config', 'profile', 'role']);
  // bulk upload =======================
  const [pasteContent, setPasteContent] = useState<string>('');
  const [selectedFile, setSelectedFileInner] = useState<File | undefined>(undefined);
  const [isUploaded, setIsUploaded] = useState(false);
  // const downloadButtonIsDisabled = (!selectedFile && _isEmpty(pasteContent)) || isUploaded;
  const [{ enlarge, shrink, upload }, setAnimationCallbacks] = useState<Record<string, any>>({});
  const [draggingCount, setDraggingCount] = useState(0);
  const [parsedUserData, setParsedUserData] = useState<ParsedUserData[]>([]);
  const [bulkBtnDisabled, setBulkBtnDisabled] = useState(false);

  interface CreateUserInCurrentDivisionResponse {
    response: number;
  }

  const CSV_TEMPLATE = [['FirstName', 'LastName', 'Email']];

  // bulk upload
  const handleBulkSubmit = async (file = selectedFile) => {
    try {
      if (file) {
        const newItems = await parseUploadedItems(pasteContent, file);

        setPasteContent('');
        setDraggingCount(0);
        if (!isValidBulkUserCsvFile(newItems)) {
          setBulkErrorMessage('The uploaded file contains an incorrect format. Please try again.');
          setBulkSuccessMessage('');
          setIsUploaded(false);
          return;
        }
        setBulkBtnDisabled(false);
        setParsedUserData(bulkUserUploadFileParser(newItems));
      }
    } catch (err) {
      console.error('error', err);

      setIsUploaded(false);
    }
  };

  const setSelectedFile = (file: File) => {
    setBulkBtnDisabled(true);
    if (errorMessage) {
      setErrorMessage('');
    }

    const fileSizeError = validateFileSize(file, 500);
    if (fileSizeError) {
      shrink();
      setErrorMessage(fileSizeError);
    }
    setIsUploaded(true);
    upload(() => handleBulkSubmit(file));

    setPasteContent('');
    setDraggingCount(0);

    setSelectedFileInner(file);
  };

  // bulk user upload api call
  const createMultipleUsersInCurrentDivision = async () => {
    setLoading(true);
    setBulkErrorMessage('');
    setSuccessMessage('');
    const numberOfRows = parsedUserData.length;

    try {
      const res = await axios.post('api/user/CreateMultipleUsersInCurrentDivision', [...parsedUserData], {
        params: {
          activeUserEmail: currentUserEmail
        }
      });

      if (res.status === 200) {
        setBulkErrorMessage('');
        setSuccessMessage(`Invitations have been successfully sent.`);

        const newParsedRow = parsedUserData.map((user) => {
          const { email: parsedEmail, firstName: parsedFirstName, lastName: parsedLastName } = user;
          return {
            email: parsedEmail,
            firstName: parsedFirstName,
            lastName: parsedLastName,
            role: 'user',
            emailVerified: false,
            userOption: (
              <Operations
                tab="team"
                key={email}
                user={{ email, firstName, lastName, role: 'user', emailVerified: false }}
              />
            )
          };
        });

        setAllUserInDivision((prev: UserRow[]) => [...newParsedRow, ...prev]);
      }
    } catch (error) {
      const userCreationResponses = _get(error, 'response.data', []);
      const userCreationErrorResponses = userCreationResponses.filter(
        (userCreationResponse) => !userCreationResponse.success
      );

      // if number of row is bigger, that means some success
      if (userCreationErrorResponses.length > 0) {
        const successInvitationNumber = numberOfRows - userCreationErrorResponses.length;
        setBulkSuccessMessage(
          `${numberOfRows - userCreationErrorResponses.length} account ${pluralize(
            'invitation',
            successInvitationNumber
          )}  ${pluralize('was', successInvitationNumber)} successfully sent.`
        );
      }
      setBulkErrorMessage(
        `Bulk upload has failed for ${userCreationErrorResponses.length} of the uploaded ${pluralize(
          'user',
          userCreationErrorResponses.length
        )}.`
      );
    }
    setLoading(false);
  };

  const createUserInCurrentDivision = async () => {
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const res = await axios.post<CreateUserInCurrentDivisionResponse>(
        'api/user/CreateUserInCurrentDivision',
        {
          email,
          firstName,
          lastName,
          ...(isMultiAccount && { emailAlias: multiUserEmail })
        },
        {
          params: {
            activeUserEmail: currentUserEmail
          }
        }
      );

      if (res.status === 200) {
        setFirstName('');
        setLastName('');
        setSuccessMessage(`You've sent an invitation to ${email}.`);
        setEmail('');
        setMultiUserEmail('');
        setIsMultiAccount(false);
        setErrorMessage('');
        setAllUserInDivision((prev: UserRow[]) => [
          {
            email,
            firstName,
            lastName,
            role: 'user',
            emailVerified: false,
            userOption: (
              <Operations
                tab="team"
                key={email}
                user={{ email, firstName, lastName, role: 'user', emailVerified: false }}
                setAllApprovedDomains={setAllApprovedDomains}
                setAllUserInDivision={setAllUserInDivision}
              />
            )
          },
          ...prev
        ]);
      }
    } catch (error) {
      setErrorMessage(_get(error, 'response.data.error') || 'An error occurred');
    }

    setLoading(false);
  };

  const addAuthorizedClientDomain = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      const res = await axios.post(
        'api/user/AddAuthorizedClientDomain',
        {},
        {
          params: {
            activeUserEmail: currentUserEmail,
            domain
          }
        }
      );

      if (res.status === 200) {
        setSuccessMessage(`${domain} has successfully saved.`);
        setDomain('');
        setErrorMessage('');

        setAllApprovedDomains((prev: DomainRow[]) => [
          {
            domain,
            userOption: (
              <Operations
                tab="domain"
                key={domain}
                domain={domain}
                setAllApprovedDomains={setAllApprovedDomains}
                setAllUserInDivision={setAllUserInDivision}
              />
            )
          },
          ...prev
        ]);
      }
    } catch (error) {
      setErrorMessage(_get(error, 'response.data', 'An error occurred'));
    }

    setLoading(false);
  };

  const handleErrorSuccessClose = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  const handleMultiAccountCheckboxChange = () => {
    setIsMultiAccount((prev) => !prev);
    setMultiUserEmail('');
  };
  const renderTitle = () => (
    <div
      style={{
        fontSize: '2.2rem',
        fontWeight: 'bold',
        marginBottom: 27,
        position: 'relative'
      }}
    >
      {tab === 'team' ? (isBulkUpload ? 'Bulk Upload' : 'Add New User') : 'Add New Domain'}
      <div
        style={{
          position: 'absolute',
          right: -17,
          top: -2
        }}
      >
        <AppButton disabled={loading} onClick={handleCloseBtn} backgroundColor="white">
          <CrossIcon style={{ width: '30px' }} />
        </AppButton>
      </div>
    </div>
  );

  const renderEmail = () => {
    return (
      <div>
        {tab === 'team' ? (
          <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 20 }}>
            <AppInput
              className="add-team-user-firstName"
              id="add-team-user-firstName"
              label="First Name"
              value={firstName}
              disabled={loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(event.target.value);
              }}
              fullWidth
              sx={{ marginBottom: 1 }}
              placeholder="Enter first name"
            />
            <AppInput
              className="add-team-user-lastName"
              id="add-team-user-lastName"
              label="Last Name"
              value={lastName}
              disabled={loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLastName(event.target.value);
              }}
              sx={{ width: '100%', marginBottom: 1 }}
              placeholder="Enter last name"
            />
            <AppInput
              id="add-team-user-email"
              className="add-team-user-email"
              label="Email Address"
              value={email}
              disabled={loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value.toLowerCase());
                setIsEmailValid(EMAIL_VALIDATOR_REGEX.test(event.target.value.toLowerCase()));
              }}
              sx={{ width: '100%', marginBottom: 1 }}
              placeholder="Enter an email address"
            />
            {isMultiAccount && (
              <AppInput
                id="add-team-user-multi"
                className="add-team-user-multi"
                label="Base Email Address"
                value={multiUserEmail}
                disabled={loading}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setMultiUserEmail(event.target.value.toLowerCase());
                  setIsMultiEmailValid(EMAIL_VALIDATOR_REGEX.test(event.target.value.toLowerCase()));
                }}
                sx={{ width: '100%', marginBottom: 1 }}
                placeholder="Enter a base email address"
              />
            )}
          </div>
        ) : (
          <AppInput
            className="add-user-domain"
            id="add-user-domain"
            label="Domain Name"
            value={domain}
            disabled={loading}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDomain(event.target.value);
            }}
            sx={{ width: '100%', marginBottom: 1 }}
            placeholder="Enter a Domain name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <AlternateEmailIcon />
                </InputAdornment>
              )
            }}
          />
        )}
      </div>
    );
  };

  const renderButton = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>
        {tab === 'team' ? (
          <>
            {isBulkUpload ? (
              <>
                <AppButton
                  sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    borderRadius: '5px',
                    padding: '6px 15px',
                    backgroundColor: '#ffffff',
                    fontWeight: 400,
                    color: '#052849',
                    '&:hover': {
                      backgroundColor: '#cfd7df'
                    }
                  }}
                >
                  <CSVLink
                    style={{ textDecoration: 'none', fontWeight: '500' }}
                    data={CSV_TEMPLATE}
                    filename="DefaultTemplate_BulkAddUser.csv"
                  >
                    Download Template
                  </CSVLink>
                </AppButton>

                <AppButton
                  sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    marginLeft: 2,
                    borderRadius: '5px',
                    padding: '6px 15px',
                    fontWeight: 400,
                    width: '132px'
                  }}
                  invertStyle
                  disabled={!selectedFile || loading || bulkBtnDisabled}
                  onClick={createMultipleUsersInCurrentDivision}
                >
                  {loading ? <Loading size={20} thickness={3.6} color="white" /> : '+ Send Invite'}
                </AppButton>
              </>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                {storeUserRole !== 'support' ? null : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      transform: 'translateX(-13px)'
                    }}
                  >
                    <Checkbox color="primary" checked={isMultiAccount} onChange={handleMultiAccountCheckboxChange} />
                    <div>
                      <p style={{ margin: 0 }}>Multi-Account</p>
                      <p style={{ margin: 0 }}>Email</p>
                    </div>
                  </div>
                )}

                <AppButton
                  sx={{
                    paddingLeft: 1,
                    paddingRight: 1,
                    borderRadius: '5px',
                    padding: '6px 15px',
                    '&:hover': {
                      backgroundColor: '#46a7f5'
                    },
                    ...(loading
                      ? { backgroundColor: 'white', color: '46a7f5' }
                      : { backgroundColor: '#46a7f5', color: 'white' }),
                    fontWeight: 400
                  }}
                  onClick={() => {
                    setIsBulkUpload(true);
                  }}
                  disabled={loading}
                >
                  Bulk Upload
                </AppButton>

                <AppButton
                  sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    marginLeft: 2,
                    borderRadius: '5px',
                    padding: '6px 15px',
                    fontWeight: 400,
                    width: '132px'
                  }}
                  invertStyle
                  disabled={
                    !firstName || !lastName || !isEmailValid || loading || (isMultiAccount && !isMultiEmailValid)
                  }
                  onClick={createUserInCurrentDivision}
                >
                  {loading ? <Loading size={20} thickness={3.6} color="white" /> : '+ Send Invite'}
                </AppButton>
              </div>
            )}
          </>
        ) : (
          <>
            <AppButton
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                marginLeft: 2,
                borderRadius: '5px',
                padding: '6px 15px',
                fontWeight: 400,
                width: '80px'
              }}
              invertStyle
              disabled={!DOMAIN_VALIDATOR_REGEX.test(domain) || loading}
              onClick={addAuthorizedClientDomain}
            >
              {loading ? <Loading size={20} thickness={3.6} color="white" /> : 'Save'}
            </AppButton>
          </>
        )}
      </div>
    );
  };

  const renderBulkUpload = () => {
    const eventHandlers: Record<string, any> & {
      onDrop: Div['onDrop'];
      onDragOver: Div['onDragOver'];
      onDragStart: Div['onDragStart'];
      onDragLeave: Div['onDragLeave'];
      onDragEnter: Div['onDragEnter'];
    } = {
      onDrop: (evt) => {
        setBulkErrorMessage('');
        evt.preventDefault();
        evt.stopPropagation();

        const file = evt.dataTransfer.files[0];
        // TODO:: change validateFile to specific name, now we need to validate file size and etc.
        const extensionError = validateFile(file, ['csv']);
        if (extensionError) {
          shrink();
          setBulkErrorMessage(extensionError);
          return;
        }

        const fileSizeError = validateFileSize(file, 500);
        if (fileSizeError) {
          shrink();
          setBulkErrorMessage(fileSizeError);
        }

        setSelectedFile(evt.dataTransfer.files[0]);
        // setIsUploaded(true);
        // upload(() => handleBulkSubmit(file));

        // setPasteContent('');
        // setDraggingCount(0);
      },
      onDragOver: (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
      },
      onDragStart: () => {},
      onDragLeave: () => {
        if (draggingCount === 1) {
          shrink();
        }
        setDraggingCount(draggingCount - 1);
      },
      onDragEnter: () => {
        if (draggingCount === 0) {
          enlarge();
        }
        setDraggingCount(draggingCount + 1);
      },
      onDragExit: shrink
    };

    return (
      <>
        {/* <p> has some default css props, so used div */}
        <div className="bulk-upload-description">
          Please download the template below. All columns must be populated for
        </div>
        <div className="bulk-upload-description">
          each user you wish to add. One user per line with no additional formatting.
        </div>
        <div className="bulk-upload-description" style={{ marginBottom: '12px' }}>
          Please upload the template with the same download name.
        </div>

        <div {...eventHandlers} style={{ border: '1px solid #adbac6', borderRadius: '10px' }}>
          <TextureBackgroundArea
            pasteContent={false}
            onlyFileUpload
            fileTitle="Upload user spreadsheet here"
            itemType="User"
            isUploaded={isUploaded}
            setAnimationCallbacks={setAnimationCallbacks}
            setErrorMsg={setBulkErrorMessage}
            setSelectedFile={setSelectedFile}
            enlarge={enlarge}
            shrink={shrink}
            customClass="user_management_bulk_background"
          />
        </div>
        <div style={{ marginTop: '25px' }}>
          {bulkSuccessMessage && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '0.5rem'
              }}
            >
              <CheckCircleOutlineIcon style={{ marginRight: '0.5rem' }} />
              {bulkSuccessMessage}
            </div>
          )}
          {bulkErrorMessage && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: colors.userManagementBulkErrorPink,
                marginTop: '0.5rem'
              }}
            >
              <HighlightOffIcon style={{ marginRight: '0.5rem' }} />
              {bulkErrorMessage}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div
      style={{
        padding: '27px 30px 27px 30px',
        minWidth: 442,
        fontFamily: 'Roboto',
        color: brandclub_colors.darkBlue
      }}
    >
      {renderTitle()}
      {isBulkUpload ? renderBulkUpload() : renderEmail()}
      {renderButton()}
      {errorMessage && (
        <div className="user-management-error-snackbar">
          <AppSnackbar
            open
            setOpen={handleErrorSuccessClose}
            title="Error"
            message={errorMessage}
            alertProps={{ icon: <HighlightOffIcon fontSize="inherit" /> }}
          />
        </div>
      )}

      {successMessage && (
        <div className="user-management-success-snackbar">
          <AppSnackbar
            onClick={() => setSuccessMessage('')}
            open={!!successMessage}
            // autoHideDuration={5000}
            setOpen={handleErrorSuccessClose}
            title="Success"
            message={successMessage}
            // severity="success"
            alertProps={{ icon: <CheckCircleOutlineIcon fontSize="inherit" /> }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          />
        </div>
      )}
    </div>
  );
};

export default UserTeamManagementForm;
