import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Tabs from 'src/components/common/Tabs/Tabs';
import OmniFilter from 'src/components/Omni/OmniFilter/OmniFilter';
import { AddIcon } from 'src/components/SvgIcons';
import { PushFn } from 'src/types/application/types';
import { WidgetProps } from 'src/types/application/widgetTypes';
import { withPush } from 'src/utils/hoc';

const styles: { [key: string]: React.CSSProperties } = {
  title: { fontSize: 31, fontWeight: 400 },
  headerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: { width: 20, height: 20 },
  tabs: {
    borderWidth: 0
  },
  tab: { padding: '14.5px 0px 8px 0px', fontSize: 24, fontWeight: 'bold', borderWidth: 0 },
  plusIcon: { width: '35px', height: '35px', marginLeft: '20px', verticalAlign: 'middle', cursor: 'pointer' }
};

interface BuildNavTitleProps {
  to: string;
  displayName: string;
}

const BuildNavTitle: React.FC<BuildNavTitleProps> = ({ to, displayName }: BuildNavTitleProps) => {
  return (
    <Link to={to} style={{ fontWeight: 'bold', letterSpacing: '0.84px' }}>
      <AddIcon style={{ width: '14px', height: '14px', marginRight: '7px', transform: 'translateY(2px)' }} />
      {displayName}
    </Link>
  );
};

const displayRightTitle = (headerTabOpt: { name: string; displayName: string; tooltipText: string }) => {
  switch (headerTabOpt.name) {
    case 'segment':
      return <BuildNavTitle to="/search" displayName={headerTabOpt.tooltipText} />;
    case 'searchtermlist':
      return <BuildNavTitle to="/searchtermlist" displayName={headerTabOpt.tooltipText} />;
    case 'storeLists':
      return <BuildNavTitle to="/storeListsSearch" displayName={headerTabOpt.tooltipText} />;
    default:
      return <></>;
  }
};

const OmniSummaryHeader: React.FC<WidgetProps & { push: PushFn }> = ({ push, widget }) => {
  const {
    view: {
      summaryHeader: { headerTabOpts }
    }
  } = widget;

  const searchParams = new URLSearchParams(window.location.search);
  const currentTab = searchParams.get('selectedEntityName');

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      const { name } = headerTabOpts[tabIndex];
      searchParams.set('selectedEntityName', name);
      const newParams = searchParams.toString();
      push(`?${newParams}`);
    },
    [headerTabOpts, searchParams, push]
  );

  const activeTabIndex = headerTabOpts.findIndex((item: { name: string }) => item.name === currentTab);

  const tabPropsForGeoMap = { value: 0, onTabChange: () => {} };
  return (
    <>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        <div style={{ marginRight: 'auto' }}>
          <Tabs
            tabs={headerTabOpts}
            // Might want to disable this for all?
            style={{ borderBottom: 'none' }}
            value={activeTabIndex >= 0 ? activeTabIndex : 1}
            tabStyle={styles.tab}
            tabRootStyle={styles.tabs}
            onTabChange={(_event: React.ChangeEvent<HTMLInputElement>, tabIndex: number) => handleTabChange(tabIndex)}
            {...(widget.name === 'summaryHeaderForMap' ? tabPropsForGeoMap : {})}
          />
        </div>
        <OmniFilter
          shouldShowSegmentFilter={headerTabOpts[0].name === 'region'}
          shouldShowFilter={!['searchtermlist', 'segment', 'storeLists'].includes(headerTabOpts[0].name)}
        />
        <div
          style={{
            fontSize: '14px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.84px',
            textAlign: 'left',
            textTransform: 'uppercase',
            cursor: 'pointer'
          }}
        >
          {displayRightTitle(headerTabOpts[0])}
        </div>
      </div>
      <hr className="sl-divider sl-divider--no-margin-top" />
    </>
  );
};

export default withPush(OmniSummaryHeader);
