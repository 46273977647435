import {
  AdFrequencyResponse,
  AdFrequencyAdvancedSearchResponse,
  AdFrequencyBarMetric,
  AdFrequencyLineMetric
} from './types';
import { AdFrequencyDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import _cloneDeep from 'lodash/cloneDeep';
import _sum from 'lodash/sum';
import { MAX_ADS_AGGREGATION } from './constants';

/**
 * Take a raw ES query response for the ad frequency metrics
 * and format it so it can be more easily consumed
 * @param response Raw response from ES query
 * @returns A formatted response with totals for each
 * ad frequency metric that can be displayed in
 * the graph
 */
export const advancedSearchResponseToAdFrequencyResponse = (
  response: AdFrequencyAdvancedSearchResponse
): AdFrequencyResponse => {
  const metrics = _cloneDeep(response)
    .aggregations['by_frequencyBucket.keyword'].sort((a, b) => a.fieldId.localeCompare(b.fieldId))
    .map(({ additionalValues }) => additionalValues)
    .slice(1);

  const totalImpressions = _sum(metrics.map(({ impressions_sum_value: impressions }) => impressions));
  const totalDpv = _sum(metrics.map(({ detailPageView_sum_value: dpv }) => dpv));
  const totalOrders = _sum(metrics.map(({ orders_sum_value }) => orders_sum_value));
  const totalUsers = _sum(metrics.map(({ usersInBucket_sum_value: users }) => users));

  return {
    [AdFrequencyDropdownOption.Awareness]: {
      barChartMetrics: metrics.map(({ impressions_sum_value }) => impressions_sum_value),
      lineChartMetrics: metrics.map(({ detailPageView_sum_value: dpv, impressions_sum_value: impressions }) =>
        impressions > 0 ? dpv / impressions : 0
      ),
      totalMetricRate: totalImpressions > 0 ? totalDpv / totalImpressions : 0
    },
    [AdFrequencyDropdownOption.Conversion]: {
      barChartMetrics: metrics.map(({ orders_sum_value }) => orders_sum_value),
      lineChartMetrics: metrics.map(({ usersInBucket_sum_value: usersInBucket, orders_sum_value: orders }) =>
        usersInBucket > 0 ? orders / usersInBucket : 0
      ),
      totalMetricRate: totalUsers > 0 ? totalOrders / totalUsers : 0
    },
    [AdFrequencyDropdownOption.Engagement]: {
      barChartMetrics: metrics.map(({ detailPageView_sum_value }) => detailPageView_sum_value),
      lineChartMetrics: metrics.map(({ detailPageView_sum_value: dpv, impressions_sum_value: impressions }) =>
        impressions > 0 ? dpv / impressions : 0
      ),
      totalMetricRate: totalImpressions > 0 ? totalDpv / totalImpressions : 0
    }
  };
};

interface AdFrequencyChartMetrics {
  barMetric: AdFrequencyBarMetric;
  lineMetric: AdFrequencyLineMetric;
}

type AdFrequencyChartMetricMapping = {
  [key in AdFrequencyDropdownOption]: AdFrequencyChartMetrics;
};
export const getChartMetricsByDropdownMetric = (metric: AdFrequencyDropdownOption): AdFrequencyChartMetrics => {
  const chartMetricMapping: AdFrequencyChartMetricMapping = {
    [AdFrequencyDropdownOption.Awareness]: {
      barMetric: AdFrequencyBarMetric.Impressions,
      lineMetric: AdFrequencyLineMetric.DPVR
    },
    [AdFrequencyDropdownOption.Engagement]: {
      barMetric: AdFrequencyBarMetric.DetailPageViews,
      lineMetric: AdFrequencyLineMetric.DPVR
    },
    [AdFrequencyDropdownOption.Conversion]: {
      barMetric: AdFrequencyBarMetric.Conversions,
      lineMetric: AdFrequencyLineMetric.ConversionRate
    }
  };

  return chartMetricMapping[metric];
};

interface AdFrequencyDataToCsvArgs {
  data: AdFrequencyResponse;
  dateRange: string;
  metric: AdFrequencyDropdownOption;
}

/**
 * Converts ad frequency bar/line chart data
 * to CSV
 */
export const convertAdFrequencyDataToCsv = ({ data, dateRange, metric }: AdFrequencyDataToCsvArgs): string => {
  const { barChartMetrics, lineChartMetrics } = data[metric];

  const { barLabel, lineLabel } =
    metric === AdFrequencyDropdownOption.Awareness
      ? { barLabel: 'Impressions', lineLabel: 'Detail Page View Rate' }
      : metric === AdFrequencyDropdownOption.Conversion
      ? { barLabel: 'Detail Page Views', lineLabel: 'Detail Page View Rate' }
      : { barLabel: 'Conversions', lineLabel: 'Shopper Conversion Rate' };

  const headers: string = ['Frequency Bucket', `${barLabel}: ${dateRange}`, `${lineLabel}: ${dateRange}`]
    .map((val) => `"${val}"`)
    .join(',');

  const values = barChartMetrics.map((barVal, index) => {
    return [index < MAX_ADS_AGGREGATION - 1 ? index + 1 : `${MAX_ADS_AGGREGATION}+`, barVal, lineChartMetrics[index]]
      .map((val) => `"${val}"`)
      .join(',');
  });

  return `${headers}\n${values.join('\n')}`;
};
