import axios from 'axios';
import { InvoiceRequestParameters, buildRequestForInvoiceData } from 'src/components/ShortageDisputes/requestBuilders';

/**
 * Fetches for disputes that are queued for submission or have a submission in progress
 */
export const fetchSubmissionStatus = async (retailerId: string) => {
  const invoiceRequestParameters: InvoiceRequestParameters = {
    retailerId,
    status: ['Queued for Submission', 'Submission in Progress']
  };
  const pollRequest = buildRequestForInvoiceData(invoiceRequestParameters);
  const response = await axios.post(`/api/beacon/AdvancedSearch?_id=pollingForUpdate`, pollRequest);
  return response;
};
