import React from 'react';
import BeaconDoubleDropdownMetricColumnChart from 'src/components/BeaconRedesignComponents/BeaconMetricColumnChart/BeaconDoubleDropdownMetricColumnChart';
import { useCreateRemoveRetailPriceRangeFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { useConvertPaidTrafficFieldName } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Traffic/PaidSearch/paidSearchUtil';
import { useMainEntityType } from 'src/utils/Hooks/reduxSelectors';

/**
 * Bar chart for the paid search page
 */
export default function PaidSearchDoubleOptionBarChart() {
  // TODO: (Topics) Use new indexes when available for topics
  const mainEntityType = useMainEntityType();
  const { convertedBuilderFunc } = useConvertPaidTrafficFieldName();
  const removeRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();

  return (
    <BeaconDoubleDropdownMetricColumnChart
      fields={[
        {
          fieldName: 'spend',
          label: 'Ad Spend'
        },
        {
          fieldName: 'clicks',
          label: 'Ad Clicks'
        },
        {
          fieldName: 'impressions',
          label: 'Impressions'
        },
        {
          fieldName: 'clickThroughRate'
        },
        {
          fieldName: 'costPerClick',
          label: 'CPC'
        },
        {
          fieldName: 'costPerMilli'
        },
        {
          fieldName: 'costPerAcquisition',
          label: 'CPA'
        },
        {
          fieldName: 'sales',
          label: 'Ad Sales'
        },
        {
          fieldName: 'conversionRate'
        },
        {
          fieldName: 'unitsSold',
          label: 'Ad Units Sold'
        },
        {
          fieldName: 'returnOnAdSpend'
        }
      ]}
      groupByFields={[
        {
          id: 'searchKeyword',
          label: 'Keyword',
          indexName: 'advertising'
        },
        {
          id: 'campaignId',
          label: 'Campaign',
          indexName: mainEntityType === 'searchtermlist' ? 'advertising' : 'ad-entity-campaign-product-metrics'
        },
        {
          id: 'entityId',
          label: 'Entity',
          indexName: mainEntityType === 'searchtermlist' ? 'advertising' : 'ad-entity-campaign-product-metrics'
        },
        {
          id: 'categoryId',
          label: 'Category',
          indexName: mainEntityType === 'searchtermlist' ? 'advertising' : 'ad-product-metrics'
        },
        {
          id: 'subCategoryId',
          label: 'Subcategory',
          indexName: mainEntityType === 'searchtermlist' ? 'advertising' : 'ad-product-metrics'
        },
        {
          id: 'brandId',
          label: 'Brand',
          indexName: mainEntityType === 'searchtermlist' ? 'advertising' : 'ad-product-metrics'
        },
        {
          id: 'stacklineSku',
          label: 'Product',
          indexName: mainEntityType === 'searchtermlist' ? 'advertising' : 'ad-product-metrics'
        }
      ].filter((option) =>
        mainEntityType === 'product'
          ? ['searchKeyword', 'campaignId', 'entityId'].includes(option.id)
          : !option.id.toLowerCase().startsWith(mainEntityType)
      )}
      useAdvancedSearchDataArgs={{
        requestBuilder: (builder) => convertedBuilderFunc(builder.apply(removeRetailPriceRangeFilters()))
      }}
    />
  );
}
