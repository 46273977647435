import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

export const OMNI_CONTENT_FIELDS: { [key: string]: FieldDefinition } = {
  contentScore: {
    name: 'contentScore',
    displayName: 'Content Score',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL
  },
  contentAccuracy: {
    name: 'contentAccuracy',
    displayName: 'Content Accuracy',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL
  },
  contentRewrite: {
    name: 'contentRewrite',
    displayName: 'Content Rewrite',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL
  }
};
