import axios from 'axios';
import { ServerProxy } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/serverProxy/useAdjustmentDownloadServerProxy';

export default function adjustmentDownloadServerProxy(userId: string, pageSize: number): ServerProxy {
  return {
    fetchAdjustmentsList: async (paginationToken: string) => {
      const response = await axios.post('/api/beaconbulkadjustment/GetBulkAdjustmentDownloads', {
        userId,
        pageSize,
        paginationToken
      });
      return response.data;
    }
  };
}
