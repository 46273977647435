import types from './types';

export const receiveOmniKeywordList = (entity: { data: any[] }) => ({
  type: types.RECEIVE_OMNI_KEYWORD_LIST_DATA,
  entity
});

export const startFetchOmniKeywordList = (entity: { data: any[] }) => ({
  type: types.START_FETCH,
  entity
});

export const endFetchOmniKeywordList = (entity: { data: any[] }) => ({
  type: types.END_FETCH,
  entity
});
