import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { PlusIcon } from 'src/components/SvgIcons';

const propTypes = {
  entityType: PropTypes.string,
  retailer: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired
};

const defaultProps = {
  entityType: 'segment'
};

export const CreateNewSegmentIcon = ({ style = {}, ...props }) => (
  <PlusIcon
    className="summary-page__segment-icon"
    style={{ width: '35px', height: '35px', marginLeft: '20px', verticalAlign: 'middle', ...style }}
    {...props}
  />
);

CreateNewSegmentIcon.propTypes = {
  style: PropTypes.object
};
CreateNewSegmentIcon.defaultProps = {
  style: undefined
};

function CreateNewSavedSearchButton({ entityType, app }) {
  const toolTipText = entityType === 'searchtermlist' ? 'Create New Keyword List' : 'Create New Segment';
  return (
    <NavLink to={`/search${app.queryParams.searchParams}&entityType=${entityType}`}>
      <CreateNewSegmentIcon data-for="create-new-segment" data-tip={toolTipText} />

      <ReactTooltip id="create-new-segment" effect="solid" class="sl-tooltip" />
    </NavLink>
  );
}

CreateNewSavedSearchButton.propTypes = propTypes;
CreateNewSavedSearchButton.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const { app } = state;
  return { app };
};

export default connect(mapStateToProps)(CreateNewSavedSearchButton);
