import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Text, useStacklineTheme } from '@stackline/ui';
import { useHistory, useLocation } from 'src/utils/Hooks';
import { GenericNavigationTabs } from 'src/components/BeaconRedesignComponents/SubtabNav/SubtabNavigation';
import { bulkAdjustmentsEnabled } from 'src/utils/app';
import { AdjustmentDownloadTable } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/AdjustmentDownloadTable';
import ExportDownloadTable from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/ExportDownloadTable';
import { BEACON_PRO_PAGE_MARGIN_BOTTOM } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

export enum DownloadsTab {
  ExportsDownload = 'exportsDownload',
  AdjustmentsDownload = 'adjustmentsDownload'
}

interface DownloadTabsProps {
  tab: DownloadsTab;
  setTab: (tab: DownloadsTab) => void;
}

const isAdjustmentPage = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('tab') === 'adjustmentsDownload';
};

const DownloadsTabs = ({ tab, setTab }: DownloadTabsProps) => {
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);

  const handleNavigation = (tabInfo: DownloadsTab.AdjustmentsDownload | DownloadsTab.ExportsDownload) => {
    setTab(tabInfo);
    urlParams.set('tab', tabInfo);
    history.push({ search: urlParams.toString() });
  };

  return (
    <GenericNavigationTabs
      tab={tab}
      tabs={[
        {
          label: 'General',
          onClick: () => handleNavigation(DownloadsTab.ExportsDownload),
          value: DownloadsTab.ExportsDownload
        },
        {
          label: 'Adjustments',
          onClick: () => handleNavigation(DownloadsTab.AdjustmentsDownload),
          value: DownloadsTab.AdjustmentsDownload
        }
      ]}
    />
  );
};

const DownloadsPageLayout = () => {
  const [tab, setTab] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const theme = useStacklineTheme();

  useEffect(() => {
    if (isAdjustmentPage()) {
      setTab(DownloadsTab.AdjustmentsDownload);
    } else {
      setTab(DownloadsTab.ExportsDownload);
    }
  }, [searchParams]);

  return (
    <Box width="100%">
      <Text variant="h2">Download List</Text>
      {bulkAdjustmentsEnabled() && <DownloadsTabs tab={tab} setTab={setTab} />}
      <Box marginTop={theme.spacing.lg} marginBottom={BEACON_PRO_PAGE_MARGIN_BOTTOM}>
        {isAdjustmentPage() ? <AdjustmentDownloadTable /> : <ExportDownloadTable />}
      </Box>
    </Box>
  );
};

export default DownloadsPageLayout;
