export enum PlanTypeOption {
  OrganicTraffic = 'OrganicTraffic',
  PaidTraffic = 'PaidTraffic',
  OtherTraffic = 'OtherTraffic',
  RetailPrice = 'RetailPrice',
  ContentScore = 'ContentScore',
  RatingsReviews = 'RatingsReviews',
  InStockRate = 'InstockRate',
  BuyBox = 'Buybox'
}

/**
 * Each view is a step in creating a new adjustment
 */
export enum View {
  /**
   * Set the plan type, title, description, and date range for the adjustment
   */
  Initializing = 'Initializing',
  /**
   * Set the plan amounts for the adjustment and see the current projections
   */
  Finalizing = 'Finalizing',
  /**
   * Confirm the plan for the adjustment and see the summarized projections for the forecast
   */
  Confirming = 'Confirming',
  /**
   * View the current adjustment
   */
  Viewing = 'Viewing',
  /**
   * Show the submission loading state
   */
  Submitting = 'Submitting'
}

/**
 * Fields used to derive total content score
 */
export enum ContentScoreFields {
  TitleScore = 'titleScore',
  BulletScore = 'bulletScore',
  ImageScore = 'imageScore',
  VideoScore = 'videoScore',
  A_Score = 'aplusScore'
}

/**
 * Fields used in combination for ratings & reviews adjustments
 */
export enum RatingsReviewFields {
  /**
   * The additional amount of reviews to add
   */
  ReviewQuantity = 'reviewQuantityAmount',
  /**
   * The additional new average star rating
   */
  AverageRating = 'averageStarRatingAmount'
}

/**
 * Fields used to create a paid traffic adjustment
 */
export enum PaidTrafficFields {
  AdSpend = 'adSpend',
  CostPerClick = 'costPerClick',
  PaidTraffic = 'paidTraffic'
}

/**
 * The input values for each promotion type
 */
export enum PromotionType {
  LightningDeal = 'LightningDeal',
  DealOfTheDay = 'DealOfTheDay',
  BestDeal = 'BestDeal',
  Coupon = 'Coupon',
  TopDeal = 'TopDeal',
  PrimeMemberDeal = 'PrimeMember',
  None = 'None'
}

export enum FORM_FIELD {
  TYPE = 'planType',
  TITLE = 'title',
  DESCRIPTION = 'description',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  INPUT_VALUES = 'planInputAmounts',
  PROMOTION_TYPE = 'promotionType',
  IS_PROMOTION_ADJUSTMENT = 'isPromotionAdjustment'
}

export const planTypeToDisplayName = {
  [PlanTypeOption.BuyBox]: 'Buy Box',
  [PlanTypeOption.ContentScore]: 'Content Score',
  [PlanTypeOption.RatingsReviews]: 'Ratings & Reviews',
  [PlanTypeOption.InStockRate]: 'In-Stock Rate',
  [PlanTypeOption.RetailPrice]: 'Retail Price',
  [PlanTypeOption.OrganicTraffic]: 'Organic Traffic',
  [PlanTypeOption.OtherTraffic]: 'Other Traffic',
  [PlanTypeOption.PaidTraffic]: 'Paid Traffic'
};

export const ADJUSTMENT_POLL_DURATION_MS = 35000;
export const ADJUSTMENT_INTERVAL_DURATION_MS = 5000;
