import _capitalize from 'lodash/capitalize';

export const exportOmniContentAccuracyAggregationFields = [
  {
    aggregateByFieldDisplayName: 'Approved Title Count',
    aggregateByFieldName: 'titleStats.approvedContentCount',
    function: 'sum',
    canBeExported: false
  },
  {
    aggregateByFieldDisplayName: 'Matched Title Count',
    aggregateByFieldName: 'titleStats.matchedContentCount',
    function: 'sum',
    canBeExported: false
  },
  {
    aggregateByFieldDisplayName: 'Title Accuracy',
    aggregateByFieldName: 'titleAccuracy',
    function: 'computed',
    aggregateByFormula:
      '((titleStats.approvedContentCount_sum > 0) ? ((titleStats.matchedContentCount_sum / titleStats.approvedContentCount_sum)) : (1))',
    canBeExported: true
  },
  {
    aggregateByFieldDisplayName: 'Approved Bullets Count',
    aggregateByFieldName: 'bulletsStats.approvedContentCount',
    function: 'sum',
    canBeExported: false
  },
  {
    aggregateByFieldDisplayName: 'Matched Bullets Count',
    aggregateByFieldName: 'bulletsStats.matchedContentCount',
    function: 'sum',
    canBeExported: false
  },
  {
    aggregateByFieldDisplayName: 'Bullets Accuracy',
    aggregateByFieldName: 'bulletsAccuracy',
    function: 'computed',
    aggregateByFormula:
      '((bulletsStats.approvedContentCount_sum > 0) ? ((bulletsStats.matchedContentCount_sum / bulletsStats.approvedContentCount_sum)) : (1))',
    canBeExported: true
  },
  {
    aggregateByFieldDisplayName: 'Approved Images Count',
    aggregateByFieldName: 'imageUrlsStats.approvedContentCount',
    function: 'sum',
    canBeExported: false
  },
  {
    aggregateByFieldDisplayName: 'Matched Images Count',
    aggregateByFieldName: 'imageUrlsStats.matchedContentCount',
    function: 'sum',
    canBeExported: false
  },
  {
    aggregateByFieldDisplayName: 'Images Accuracy',
    aggregateByFieldName: 'imageUrlsAccuracy',
    function: 'computed',
    aggregateByFormula:
      '((imageUrlsStats.approvedContentCount_sum > 0) ? ((imageUrlsStats.matchedContentCount_sum / imageUrlsStats.approvedContentCount_sum)) : (1))',
    canBeExported: true
  },
  {
    aggregateByFieldDisplayName: 'Approved Videos Count',
    aggregateByFieldName: 'videoUrlsStats.approvedContentCount',
    function: 'sum',
    canBeExported: false
  },
  {
    aggregateByFieldDisplayName: 'Matched Videos Count',
    aggregateByFieldName: 'videoUrlsStats.matchedContentCount',
    function: 'sum',
    canBeExported: false
  },
  {
    aggregateByFieldDisplayName: 'Videos Accuracy',
    aggregateByFieldName: 'videoUrlsAccuracy',
    function: 'computed',
    aggregateByFormula:
      '((videoUrlsStats.approvedContentCount_sum > 0) ? ((videoUrlsStats.matchedContentCount_sum / videoUrlsStats.approvedContentCount_sum)) : (1))',
    canBeExported: true
  },
  {
    aggregateByFieldDisplayName: 'Approved Description Count',
    aggregateByFieldName: 'descriptionStats.approvedContentCount',
    function: 'sum',
    canBeExported: false
  },
  {
    aggregateByFieldDisplayName: 'Matched Description Count',
    aggregateByFieldName: 'descriptionStats.matchedContentCount',
    function: 'sum',
    canBeExported: false
  },
  {
    aggregateByFieldDisplayName: 'Description Accuracy',
    aggregateByFieldName: 'descriptionAccuracy',
    function: 'computed',
    aggregateByFormula:
      '((descriptionStats.approvedContentCount_sum > 0) ? ((descriptionStats.matchedContentCount_sum / descriptionStats.approvedContentCount_sum)) : (1))',
    canBeExported: true
  }
];

export const exportOmniShareOfShelfAggregationFieldsGenerator = (subtab: string) => [
  {
    aggregateByFieldDisplayName: `${_capitalize(subtab)} Share of Shelf`,
    aggregateByFieldName: `${subtab}ShareOfShelf`,
    function: 'sum',
    canBeExported: false
  },
  {
    aggregateByFieldDisplayName: `${_capitalize(subtab)} Top 3 Share of Shelf`,
    aggregateByFieldName: `${subtab}Top3ShareOfShelf`,
    function: 'sum',
    canBeExported: false
  },
  {
    aggregateByFieldDisplayName: `${_capitalize(subtab)} Top 5 Share of Shelf`,
    aggregateByFieldName: `${subtab}Top5ShareOfShelf`,
    function: 'sum',
    canBeExported: false
  },
  {
    aggregateByFieldDisplayName: `${_capitalize(subtab)} Top 10 Share of Shelf`,
    aggregateByFieldName: `${subtab}Top10ShareOfShelf`,
    function: 'sum',
    canBeExported: false
  }
];

export const exportDataOmniAggregationFieldsGenerator = (
  thirdGroupByValue: string,
  shouldUseFulfillmentAgg: boolean,
  subtab?: string
) => {
  const inputMappings = {
    retailPrice: {
      displayName: 'Retail Price',
      fieldName: 'RetailPrice',
      function: 'avg'
    },
    inStock: {
      displayName: 'In Stock Rate',
      fieldName: 'InStockRate',
      function: 'avg'
    },
    outOfStock: {
      displayName: 'Out of Stock Rate',
      fieldName: 'OutOfStockRate',
      function: 'computed'
    },
    promotions: {
      displayName: 'Promotions',
      fieldName: 'Promotion',
      function: 'sum'
    },
    priceViolations: {
      displayName: 'Violations',
      fieldName: 'Violation',
      function: 'sum'
    },
    fulfillment: {
      displayName: 'Time',
      fieldName: 'Time',
      function: 'avg'
    }
  };

  if (subtab && !(subtab in inputMappings)) {
    if (subtab !== 'all') {
      return [];
    }
  }
  const inputKeys = subtab === 'all' ? Object.keys(inputMappings).filter((key) => key !== 'outOfStock') : [subtab];
  if (subtab === 'outOfStock') {
    inputKeys.unshift('inStock');
  }
  const fulfillmentTypes = ['Delivery', 'PickUp', 'Shipping'];

  return inputKeys.flatMap((key) => {
    const mapping = inputMappings[key];
    if (shouldUseFulfillmentAgg) {
      const fulfillmentResult = [];
      fulfillmentTypes.forEach((type) => {
        const aggregateByFieldName = `product${type}${mapping.fieldName}${
          key === 'promotions' || key === 'priceViolations' ? 'Count' : ''
        }`;
        const result: any = {
          aggregateByFieldDisplayName: `${type} ${mapping.displayName}`,
          aggregateByFieldName,
          function: mapping.function,
          canBeExported: subtab !== 'outOfStock'
        };
        if (key === 'outOfStock') {
          result.aggregateByFormula = `(1-product${type}InStockRate_avg)`;
          result.canBeExported = true;
        }
        fulfillmentResult.push(result);
      });
      return fulfillmentResult;
    } else {
      if (key === 'outOfStock') {
        return [
          {
            aggregateByFieldDisplayName: `${_capitalize(thirdGroupByValue)} ${mapping.displayName}`,
            aggregateByFieldName: `${thirdGroupByValue}${mapping.fieldName}`,
            function: mapping.function,
            aggregateByFormula: `(1-${thirdGroupByValue}InStockRate_avg)`,
            canBeExported: true
          }
        ];
      }
      return [
        {
          aggregateByFieldDisplayName: `${_capitalize(thirdGroupByValue)} ${mapping.displayName}`,
          aggregateByFieldName: `${thirdGroupByValue}${key === 'priceViolations' ? 'Price' : ''}${mapping.fieldName}`,
          function: mapping.function,
          canBeExported: subtab !== 'outOfStock'
        }
      ];
    }
  });
};

export const exportOmniContentScoreAggregationFields = [
  {
    aggregateByFieldDisplayName: 'Content Score',
    aggregateByFieldName: 'contentScore',
    function: 'avg',
    canBeExported: true
  },
  {
    aggregateByFieldDisplayName: 'Title Score',
    aggregateByFieldName: 'titleScore',
    function: 'avg',
    canBeExported: true
  },
  {
    aggregateByFieldDisplayName: 'Bullets Score',
    aggregateByFieldName: 'bulletScore',
    function: 'avg',
    canBeExported: true
  },
  {
    aggregateByFieldDisplayName: 'Image Score',
    aggregateByFieldName: 'imageScore',
    function: 'avg',
    canBeExported: true
  },
  {
    aggregateByFieldDisplayName: 'Video Score',
    aggregateByFieldName: 'videoScore',
    function: 'avg',
    canBeExported: true
  },
  {
    aggregateByFieldDisplayName: 'Description Score',
    aggregateByFieldName: 'descriptionScore',
    function: 'avg',
    canBeExported: true
  },
  {
    aggregateByFieldDisplayName: 'Health Score',
    aggregateByFieldName: 'healthScore',
    function: 'avg',
    canBeExported: true
  },
  {
    aggregateByFieldDisplayName: 'Enhanced Score',
    aggregateByFieldName: 'enhancedScore',
    function: 'avg',
    canBeExported: true
  }
];
