import { useMemo, useCallback } from 'react';
import {
  useAppSelector,
  useComparisonPeriod,
  ComparisonPeriod,
  useMetricFormatter,
  useWeekRange,
  WeekRange
} from 'src/utils/Hooks';
import useGenericAdvancedSearch, { UseGenericAdvancedSearchParameters } from 'src/utils/Hooks/useGenericAdvancedSearch';
import { AdvancedSearchByWeekIdResponse } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/types';
import { calculatePercentChange, getAppName } from 'src/utils/app';
import { INDEX_FIELDS, METRICTYPE } from 'src/utils/entityDefinitions';
import AdvancedSearchRequestBuilder, {
  useAdvancedSearchRequestBuilder
} from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import {
  getFirstWeekIdOfYear,
  getNextYearWeekId,
  getPreviousWeekId,
  getPreviousYearWeekId,
  timestampToWeekId,
  weekIdToTimestamp
} from 'src/utils/dateUtils';
import { ValueOf } from 'sl-api-connector';
import _get from 'lodash/get';
import {
  useApplyEntityFilters,
  useRemoveRetailPriceRangeFilters
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';

export interface UseMetricByWeekIdArgs {
  indexName: string;
  fieldName: string;
  comparisonIndexName?: string;
  comparisonFieldName?: string;
  /**
   * True if we want to shift the prior year comparison
   * data up to match the current year
   */
  isComparisonDataCustom?: boolean;
  entityType?: string;
  /**
   * Will use most recent week's data instead of full time period
   */
  useLatestWeek?: boolean;
  /**
   * Allows optinonally modifying the primary aggregation builder
   * before the request is fired
   */
  primaryAggregationBuilder?: (aggregationBuilder: AggregationBuilder) => AggregationBuilder;
  /**
   * Allows optinonally modifying the secondary aggregation builder
   * before the request is fired
   */
  secondaryAggregationBuilder?: (aggregationBuilder: AggregationBuilder) => AggregationBuilder;

  requestBuilder?: (requestBuilder: AdvancedSearchRequestBuilder, comparison: boolean) => AdvancedSearchRequestBuilder;
  /**
   * Sometimes we want to display the metric differently than the default
   */
  metricTypeDisplay?: ValueOf<typeof METRICTYPE>;

  /**
   * Sometimes we want to compute the total value differently than the default
   */
  aggregationFunctionForTotal?: string;

  /**
   * Allows optinonally modifying the data set before it is used
   * to compute the total value
   */
  buildDataSetForMetricTotal?: (
    defaultDataSet: number[][],
    indexNameToUse,
    fieldNameToUse,
    isComparison: boolean
  ) => number[][];

  /**
   * Typically we compare YTD data against comparison up until the current week.
   * This allows a custom comparator function if needed.
   */
  customFilterComparisonForTotal?: (row: number[]) => boolean;

  useGenericAdvancedSearchArgs?: Partial<UseGenericAdvancedSearchParameters>;
}

type TDatum<TAdditionalValues> = TAdditionalValues & {
  weekId: string;
};

/**
 * helper function to get a field by index name and field name
 */
const getField = (indexName: string, fieldName: string, entityType?: string) => {
  return INDEX_FIELDS.getField(getAppName(), indexName, fieldName, entityType);
};

/**
 * Get the key from the response
 */
const getMetricKey = (indexName: string, fieldName: string, entityType?: string) => {
  const field = getField(indexName, fieldName, entityType);
  const keyEnding = field.aggregationFunction === 'stats' ? 'avg' : 'value';
  return `${fieldName}_${field.aggregationFunction}_${keyEnding}`;
};

/**
 * Returns data formatted as a 2D array with x and y values
 * (x as a unix timestamp) for displaying in a spline chart
 */
const getSplineData = (indexName: string, fieldName: string, data: any[], entityType?: string): number[][] => {
  return [...data]
    .sort((a, b) => a.weekId - b.weekId)
    .map((row) => [weekIdToTimestamp(Number(row.weekId)), row[getMetricKey(indexName, fieldName, entityType)]]);
};

/**
 * Gets all the spline chart data and legend data given an index name and field name in order
 * to display a spline chart
 */
export const useMetricByWeekId = <TAdditionalValues>({
  indexName,
  fieldName,
  comparisonFieldName,
  comparisonIndexName,
  entityType,
  primaryAggregationBuilder,
  secondaryAggregationBuilder,
  metricTypeDisplay,
  aggregationFunctionForTotal,
  requestBuilder,
  useLatestWeek = false,
  isComparisonDataCustom,
  buildDataSetForMetricTotal,
  customFilterComparisonForTotal,
  useGenericAdvancedSearchArgs = {}
}: UseMetricByWeekIdArgs) => {
  const applyEntityFilters = useApplyEntityFilters();
  const removeRetailPriceRangeFilters = useRemoveRetailPriceRangeFilters(indexName);
  const formatMetric = useMetricFormatter();

  const comparisonPeriod = useComparisonPeriod();
  const weekRange = useWeekRange();

  // Special case, when comparing last week with prior year we want
  // to show the graph for YTD but the legend should just be last week
  const isLastWeekWithPriorYear = useMemo(() => {
    return weekRange === WeekRange.LAST_WEEK && comparisonPeriod === ComparisonPeriod.PRIOR_YEAR;
  }, [comparisonPeriod, weekRange]);

  const defaultStartWeekId = useAppSelector((state) => state.mainTimePeriod.startWeek);
  const endWeekId = useAppSelector((state) => state.mainTimePeriod.endWeek);

  const startWeekId = useMemo(() => {
    if (useLatestWeek) {
      return endWeekId;
    }
    return isLastWeekWithPriorYear ? getFirstWeekIdOfYear() : defaultStartWeekId;
  }, [defaultStartWeekId, endWeekId, isLastWeekWithPriorYear, useLatestWeek]);

  const defaultComparisonStartWeekId = useAppSelector((state) => state.comparisonTimePeriod.startWeek);
  const defaultComparisonEndWeekId = useAppSelector((state) => state.comparisonTimePeriod.endWeek);

  const comparisonStartWeekId = useMemo(
    () => (isLastWeekWithPriorYear ? getPreviousYearWeekId(startWeekId) : defaultComparisonStartWeekId),
    [defaultComparisonStartWeekId, isLastWeekWithPriorYear, startWeekId]
  );
  const comparisonEndWeekId = useMemo(
    () =>
      isLastWeekWithPriorYear || (weekRange === WeekRange.YTD && comparisonPeriod === ComparisonPeriod.PRIOR_YEAR)
        ? getPreviousWeekId(startWeekId)
        : defaultComparisonEndWeekId,
    [comparisonPeriod, defaultComparisonEndWeekId, isLastWeekWithPriorYear, startWeekId, weekRange]
  );

  const entity = useAppSelector((state) => state.entityService.mainEntity);
  const requestIdWithEntityInfo = `useMetricByWeekId-${indexName}-${fieldName}-${entity.type}-${entity.id}`;

  const retailerId = useAppSelector((state) => state.retailer.id);

  const field = useMemo(
    () => INDEX_FIELDS.getField(getAppName(), indexName, fieldName, entityType),
    [indexName, fieldName, entityType]
  );

  const baseRequestBuilderFromHook = useAdvancedSearchRequestBuilder(
    requestIdWithEntityInfo,
    `${getAppName()}-${indexName}`
  );
  const baseRequestBuilder = useMemo(() => {
    return baseRequestBuilderFromHook
      .setDoAggregation(true)
      .setPageNumber(1)
      .setPageSize(1200)
      .setPeriod('year')
      .setReturnDocuments(false)
      .setSearchBy('parent');
  }, [baseRequestBuilderFromHook]);

  const baseAggregationBuilder = useMemo(() => {
    return new AggregationBuilder('weekId').addConditionTermFilter('retailerId', [retailerId]);
  }, [retailerId]);

  /** ************** Actual and Comparison Request Builders ***************** */
  const mainAggregationBuilder = useMemo(() => {
    const aggBuilder = baseAggregationBuilder
      .clone()
      .addAggregationField(
        field.displayName,
        field.name,
        field.aggregationFunction,
        true,
        field.aggregationFunctionExpression
      )
      .addConditionRangeFilter('weekId', startWeekId, endWeekId);

    if (field.dependentFields) {
      field.dependentFields.forEach((dependentField) => {
        aggBuilder.addAggregationField(
          dependentField.displayName,
          dependentField.name,
          dependentField.aggregationFunction,
          true
        );
      });
    }

    if (primaryAggregationBuilder) {
      return primaryAggregationBuilder(aggBuilder.clone());
    }

    return aggBuilder;
  }, [baseAggregationBuilder, endWeekId, field, primaryAggregationBuilder, startWeekId]);

  const mainRequestBody = useMemo(() => {
    const body = baseRequestBuilder
      .clone()
      .addAggregation(mainAggregationBuilder.build())
      .addConditionRangeFilter('weekId', startWeekId, endWeekId)
      .apply(applyEntityFilters)
      .apply(removeRetailPriceRangeFilters);

    if (requestBuilder) {
      return requestBuilder(body, false).build();
    }
    return body.build();
  }, [
    baseRequestBuilder,
    mainAggregationBuilder,
    startWeekId,
    endWeekId,
    applyEntityFilters,
    removeRetailPriceRangeFilters,
    requestBuilder
  ]);

  const comparisonAggregationBuilder = useMemo(() => {
    const aggBuilder = baseAggregationBuilder
      .clone()
      .addAggregationField(
        field.displayName,
        field.name,
        field.aggregationFunction,
        true,
        field.aggregationFunctionExpression
      )
      .addConditionRangeFilter('weekId', comparisonStartWeekId, comparisonEndWeekId);

    if (field.dependentFields) {
      field.dependentFields.forEach((dependentField) => {
        aggBuilder.addAggregationField(
          dependentField.displayName,
          dependentField.name,
          dependentField.aggregationFunction,
          true
        );
      });
    }

    if (secondaryAggregationBuilder) {
      return secondaryAggregationBuilder(aggBuilder.clone());
    }

    return aggBuilder;
  }, [baseAggregationBuilder, comparisonEndWeekId, comparisonStartWeekId, field, secondaryAggregationBuilder]);

  const comparisonRequestBody = useMemo(() => {
    const body = baseRequestBuilder
      .clone()
      .addAggregation(comparisonAggregationBuilder.build())
      .addConditionRangeFilter('weekId', comparisonStartWeekId, comparisonEndWeekId)
      .apply(applyEntityFilters)
      .apply(removeRetailPriceRangeFilters);

    if (requestBuilder) {
      return requestBuilder(body, true).build();
    }
    return body.build();
  }, [
    baseRequestBuilder,
    comparisonAggregationBuilder,
    comparisonStartWeekId,
    comparisonEndWeekId,
    applyEntityFilters,
    removeRetailPriceRangeFilters,
    requestBuilder
  ]);

  const { data: response, isLoading } = useGenericAdvancedSearch<AdvancedSearchByWeekIdResponse<TAdditionalValues[]>>({
    requestBody: [mainRequestBody, comparisonRequestBody],
    requestId: requestIdWithEntityInfo,
    queryKeys: [mainRequestBody, comparisonRequestBody],
    ...useGenericAdvancedSearchArgs
  });

  const mainRowsWithWeekId: (TAdditionalValues & { weekId: number })[] = useMemo(() => {
    return response && response.data && response.data[0]
      ? response.data[0].aggregations.by_weekId.map(({ fieldId, additionalValues }) => ({
          weekId: fieldId,
          ...additionalValues
        }))
      : [];
  }, [response]);

  const comparisonRowsWithWeekId: (TAdditionalValues & { weekId: number })[] = useMemo(() => {
    return response && response.data && response.data[1]
      ? response.data[1].aggregations.by_weekId.map(({ fieldId, additionalValues }) => ({
          weekId: fieldId,
          ...additionalValues
        }))
      : [];
  }, [response]);

  /* *************** Helper functions for computing legend values ************************ */
  /**
   * Get the total computed value for a metric. It will be a sum,
   * average, or computation based on the index field
   */
  const getMetricTotalValue = useCallback(
    (indexNameToUse: string, fieldNameToUse: string, comparison = false, aggFunction?: string) => {
      const fieldToUse = getField(indexNameToUse, fieldNameToUse, entityType);

      // Get the primary data set week ids in order
      // so we can compare against comparison data set
      const mainWeekIds = mainRowsWithWeekId.map((row) => Number(row.weekId)).sort((a, b) => a - b);

      const defaultDataSet = comparison
        ? getSplineData(indexNameToUse, fieldNameToUse, comparisonRowsWithWeekId, entityType).filter((row) => {
            if (customFilterComparisonForTotal) {
              return customFilterComparisonForTotal(row);
            }
            // If we're comparing YTD against prior year, only compare against the same weeks
            if (
              !isComparisonDataCustom &&
              comparisonPeriod === ComparisonPeriod.PRIOR_YEAR &&
              weekRange === WeekRange.YTD
            ) {
              return mainWeekIds.includes(getNextYearWeekId(timestampToWeekId(row[0])));
            }
            return true;
          })
        : getSplineData(indexNameToUse, fieldNameToUse, mainRowsWithWeekId, entityType);

      const dataSet = buildDataSetForMetricTotal
        ? buildDataSetForMetricTotal(defaultDataSet, indexNameToUse, fieldNameToUse, comparison)
        : defaultDataSet;

      const aggregationFunctionToUse = aggFunction || fieldToUse.aggregationFunction;

      // When last week with prior year, we're showing YTD data
      // but the legend should just show the last week value, which
      // will be the last item in the data set. We also show
      // the last value for cardinality metric types
      if ((isLastWeekWithPriorYear || aggregationFunctionToUse === 'cardinality') && dataSet.length > 0) {
        // Get the last week of comparison matching the last week of the main data set
        if (comparison) {
          const lastWeekId = mainWeekIds[mainWeekIds.length - 1];
          const lastComparisonRow = dataSet.find(
            (row) =>
              (isComparisonDataCustom ? timestampToWeekId(row[0]) : getNextYearWeekId(timestampToWeekId(row[0]))) ===
              lastWeekId
          );
          return lastComparisonRow ? lastComparisonRow[1] : 0;
        }
        return dataSet[dataSet.length - 1][1];
      }

      const total = dataSet.reduce((acc, row) => acc + row[1], 0);

      if (aggregationFunctionToUse === 'avg' || aggregationFunctionToUse === 'stats') {
        return dataSet.length > 0 ? total / dataSet.length : 0;
      }

      // Computed index fields have an function evaluator that take
      // variables to compute the total. We should already have the total
      // values for the variables it relies on, so get the values
      // and use them to compute the total
      if (aggregationFunctionToUse === 'computed') {
        const dependencies = fieldToUse.dependentFields;
        const variableValues = dependencies.reduce((acc, depField) => {
          return {
            ...acc,
            [depField.nameAlias || depField.name]: getMetricTotalValue(depField.indexName, depField.name, comparison)
          };
        }, {} as Record<string, number>);
        return fieldToUse.aggregationFunctionEvaluator.evaluate(variableValues);
      }

      return total;
    },
    [
      buildDataSetForMetricTotal,
      comparisonPeriod,
      comparisonRowsWithWeekId,
      customFilterComparisonForTotal,
      entityType,
      isComparisonDataCustom,
      isLastWeekWithPriorYear,
      mainRowsWithWeekId,
      weekRange
    ]
  );

  const lastSecondaryValue = useMemo(() => {
    const lastWeekBucket = comparisonRowsWithWeekId.find(
      (w) => w.weekId.toString() === defaultComparisonEndWeekId.toString()
    );

    if (lastWeekBucket) {
      return lastWeekBucket[getMetricKey(indexName, fieldName, entityType)];
    }

    return null;
  }, [comparisonRowsWithWeekId, defaultComparisonEndWeekId, entityType, fieldName, indexName]);

  return useMemo(() => {
    const secondaryData = getSplineData(
      comparisonIndexName || indexName,
      comparisonFieldName || fieldName,
      comparisonRowsWithWeekId.map((row) => ({
        ...row,
        weekId:
          !isComparisonDataCustom && comparisonPeriod === ComparisonPeriod.PRIOR_YEAR
            ? getNextYearWeekId(row.weekId)
            : row.weekId
      })),
      entityType
    );

    // Get last data point
    const basePrimaryData = getSplineData(indexName, fieldName, mainRowsWithWeekId, entityType);
    const lastPrimaryValue = _get(basePrimaryData[basePrimaryData.length - 1], ['1']);

    // The first element is the epoch timestamp and the 2nd is the value

    const percentChangeLastValues = calculatePercentChange(lastPrimaryValue, lastSecondaryValue);

    const percentChange = calculatePercentChange(
      getMetricTotalValue(indexName, fieldName, false, aggregationFunctionForTotal),
      getMetricTotalValue(indexName, fieldName, true, aggregationFunctionForTotal)
    );

    const metricType = metricTypeDisplay || field.metricType;
    const comparisonField = INDEX_FIELDS.getField(
      getAppName(),
      comparisonIndexName || indexName,
      comparisonFieldName || fieldName,
      entityType
    );
    const comparisonMetricType = comparisonField.metricType;

    return {
      primaryData: [
        ...(!isComparisonDataCustom && comparisonPeriod === ComparisonPeriod.PRIOR_PERIOD && secondaryData.length > 0
          ? [secondaryData[secondaryData.length - 1]]
          : []),
        ...basePrimaryData
      ],
      secondaryData,
      lastPrimaryValue,
      lastSecondaryValue,
      primaryTotal: getMetricTotalValue(indexName, fieldName, false, aggregationFunctionForTotal),
      secondaryTotal: getMetricTotalValue(
        comparisonIndexName || indexName,
        comparisonFieldName || fieldName,
        true,
        aggregationFunctionForTotal
      ),
      formattedPrimaryTotal: `${formatMetric(
        getMetricTotalValue(indexName, fieldName, false, aggregationFunctionForTotal),
        metricType,
        {
          showFullValue: field.metricType === METRICTYPE.PERCENT,
          decimalPlaces: 2,
          showNegative: true
        }
      )}`,
      formattedLastPrimaryValue: `${formatMetric(lastPrimaryValue, metricType, {
        showFullValue: field.metricType === METRICTYPE.PERCENT,
        decimalPlaces: 2,
        showNegative: true
      })}`,
      formattedLastSecondaryValue: `${formatMetric(lastSecondaryValue, comparisonMetricType, {
        showFullValue: comparisonField.metricType === METRICTYPE.PERCENT,
        decimalPlaces: 2,
        showNegative: true
      })}`,
      percentChangeLastValues,
      formattedPercentChangeLastValues: `${formatMetric(Math.abs(percentChangeLastValues), METRICTYPE.PERCENT, {
        decimalPlaces: 2
      })}`,
      formattedSecondaryTotal: `${formatMetric(
        getMetricTotalValue(
          comparisonIndexName || indexName,
          comparisonFieldName || fieldName,
          true,
          aggregationFunctionForTotal
        ),
        comparisonMetricType,
        {
          showFullValue: comparisonField.metricType === METRICTYPE.PERCENT,
          decimalPlaces: 2,
          showNegative: true
        }
      )}`,
      response,
      percentChange,
      formattedPercentChange: `${formatMetric(Math.abs(percentChange), METRICTYPE.PERCENT, { decimalPlaces: 2 })}`,
      isLoading,
      field
    };
  }, [
    comparisonIndexName,
    indexName,
    comparisonFieldName,
    fieldName,
    comparisonRowsWithWeekId,
    entityType,
    mainRowsWithWeekId,
    lastSecondaryValue,
    getMetricTotalValue,
    aggregationFunctionForTotal,
    metricTypeDisplay,
    field,
    isComparisonDataCustom,
    comparisonPeriod,
    formatMetric,
    isLoading
  ]);
};
