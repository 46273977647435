import React from 'react';
import Tabs from 'src/components/common/Tabs/Tabs';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import colors from 'src/utils/colors';

const style = {
  root: {},
  // tabContainer: { width: 130 },
  value: {
    fontWeight: 300,
    fontSize: 31,
    lineHeight: 1.1,
    color: colors.darkBlue
  },
  label: {
    fontSize: 16,
    textTransform: 'uppercase',
    fontWeight: 500,
    color: colors.labelGrey
  },
  tabs: { borderBottom: `1px solid ${colors.lightestGrey}` },
  tab: { fontWeight: 400, marginRight: 10, padding: '27px 30px 15px 30px' }
};

const TabsTemplate = ({ value, title }) => (
  <div style={style.tabContainer}>
    <div style={style.value}>{value}</div>
    <div style={style.label}>{title}</div>
  </div>
);
TabsTemplate.propTypes = {
  value: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired
};

const buildtabs = (opts) => {
  const tabs = opts.map((opt) => ({
    displayTemplate: (
      <TabsTemplate value={_get(opt, 'chartProps.legend.mainLegendDisplayValue', '-')} title={opt.displayName} />
    )
  }));
  return tabs;
};

const NInOneTabs = ({ opts, handleNInOneTabs }) => {
  const tabs = buildtabs(opts);

  return (
    <div style={style.root}>
      <Tabs
        tabs={tabs}
        tabStyle={style.tab}
        tabRootStyle={style.tabs}
        onTabChange={(_event, tabIndex) => handleNInOneTabs(opts[tabIndex])}
      />
    </div>
  );
};
NInOneTabs.propTypes = {
  handleNInOneTabs: PropTypes.any.isRequired,
  opts: PropTypes.any.isRequired
};

export default NInOneTabs;
