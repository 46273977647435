/* eslint-disable react/prop-types */
import React, { useState, Dispatch, SetStateAction } from 'react';
import { AppButton, brandclub_colors, AppSnackbar } from '@stackline/ui';
import axios from 'axios';
import Loading from 'src/components/common/Loading';
import ReduxStore from 'src/types/store/reduxStore';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { USER_SCOPES as permission } from 'src/utils/constants';
import { Operations } from './UserTeamManagement';
import { DomainRow, UserRow } from 'src/routes/UserAccount/UserTeamManagement/UserTeamManagementForm';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

type Dispatcher<S> = Dispatch<SetStateAction<S>>;
interface UserTeamManagementDeleteUpdateFormProp {
  tab?: 'team' | 'domain';
  title?: string;
  confirmText?: string;
  successText?: string;
  failedText?: string;
  handleConfirm: () => void;
  handleClose: () => void;
  domain?: string;
  targetUser?: object;
  action?: string;
}

const UserTeamManagementDeleteUpdateForm: FC<UserTeamManagementDeleteUpdateFormProp> = ({
  tab,
  handleClose,
  targetUser,
  setAllUserInDivision,
  setAllApprovedDomains,
  domain,
  action
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [requestSent, setRequestSent] = useState(false);
  const { user: userFromRedux } = useSelector(({ user }: ReduxStore) => ({ user }));
  const currentEmail = _get(userFromRedux, ['config', 'profile', 'email']);

  const handleErrorSuccessClose = () => {
    setErrorMessage('');
    setSuccessMessage('');
    handleClose();
  };

  const changeUserRoleInCurrentDivision = async (setAction: string) => {
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    setRequestSent(true);

    try {
      const res = await axios.post(
        'api/user/ChangeUserRoleInCurrentDivision',
        {},
        {
          params: {
            activeUserEmail: currentEmail,
            targetUserEmail: targetUser.email,
            action: setAction
          }
        }
      );

      if (res.status === 200) {
        const tempRole = setAction.replace('set', '').toLowerCase();
        const rolesToBeChanged = tempRole === 'basic' ? 'user' : tempRole;

        setSuccessMessage(`${targetUser.email}'s role has been changed in the division.`);
        setTimeout(() => {
          setAllUserInDivision((prev: UserRow[]) =>
            prev.map((currUser) => {
              if (currUser.email === targetUser.email) {
                return {
                  ...currUser,
                  role: rolesToBeChanged,
                  userOption: (
                    <Operations
                      key={currUser.email}
                      user={{ ...currUser, role: rolesToBeChanged }}
                      tab="team"
                      setAllUserInDivision={setAllUserInDivision}
                    />
                  )
                };
                // if owner changes we need to update prev owner too
              } else if (setAction === 'setOwner' && currUser.role === 'owner') {
                return {
                  ...currUser,
                  role: 'admin',
                  userOption: (
                    <Operations
                      key={currUser.email}
                      user={{ ...currUser, role: 'admin' }}
                      tab="team"
                      setAllUserInDivision={setAllUserInDivision}
                    />
                  )
                };
              } else {
                return currUser;
              }
            })
          );
          handleClose();
        }, 1500);
      }
    } catch (error) {
      setErrorMessage(_get(error, 'response.data', 'An error occurred'));
      setRequestSent(false);
    }

    setLoading(false);
  };

  // this function is actually disable the user from division. we archive the user for 60 days
  const deleteUserFromCurrentDivision = async () => {
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    setRequestSent(true);
    try {
      const res = await axios.post(
        'api/user/DisableUserFromDivision',
        {},
        {
          params: {
            activeUserEmail: currentEmail,
            userToBeDisabledEmail: targetUser.email //
          }
        }
      );

      if (res.status === 200) {
        setSuccessMessage(`You've deleted ${targetUser.email} from your division.`);

        setTimeout(() => {
          setAllUserInDivision((prev: UserRow[]) => {
            return prev.filter((preUser) => preUser.email !== targetUser.email);
          });
          handleClose();
        }, 1500);
      }
    } catch (error) {
      setErrorMessage(_get(error, 'response.data', 'An error occurred'));
      setRequestSent(false);
    }
    setLoading(false);
  };

  const removeAuthorizedClientDomain = async () => {
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    setRequestSent(true);

    try {
      const res = await axios.post('/api/user/RemoveAuthorizedClientDomain', '', {
        params: {
          activeUserEmail: currentEmail,
          domain
        }
      });

      if (res.status === 200) {
        setSuccessMessage(`${domain} has deleted from the account.`);
        setTimeout(() => {
          setAllApprovedDomains((prev: DomainRow[]) => {
            return prev.filter((preDomain) => preDomain.domain !== domain);
          });
          handleClose();
        }, 1500);
      }
    } catch (error) {
      setErrorMessage(_get(error, 'response.data', 'An error occurred'));
    }
    setLoading(false);
  };

  const handleConfirmForTeam = () => {
    switch (action) {
      case permission.canAssignAdmins:
        return changeUserRoleInCurrentDivision('setAdmin');

      case permission.canDeleteAdmin:
        return changeUserRoleInCurrentDivision('setBasic');

      case permission.canDeleteUsers:
        return deleteUserFromCurrentDivision();

      case permission.canTransferOwnership:
        return changeUserRoleInCurrentDivision('setOwner');

      case permission.canEditApprovedDomain:
        return removeAuthorizedClientDomain();
      default:
        return undefined;
    }
  };

  const renderTitle = () => {
    const title = (param: string) => {
      switch (param) {
        case permission.canAssignAdmins:
          return 'Assign Admin Role';

        case permission.canDeleteUsers:
          return 'Remove User From Team';

        case permission.canDeleteAdmin:
          return 'Remove Admin Role';

        case permission.canTransferOwnership:
          return 'Transfer Ownership';

        case permission.canEditApprovedDomain:
          return 'Delete Approved Domain';

        default:
          return 'Remove Domain';
      }
    };
    return (
      <div
        style={{
          fontSize: '1.4rem',
          fontWeight: 'bold',
          marginBottom: 27,
          position: 'relative'
        }}
      >
        {title(action)}
      </div>
    );
  };

  const renderButton = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>
        <AppButton
          sx={{
            paddingLeft: 2,
            paddingRight: 2,
            borderRadius: '5px',
            padding: '6px 15px',
            backgroundColor: '#ffffff',
            fontWeight: 400,
            color: '#052849',
            '&:hover': {
              backgroundColor: '#cfd7df'
            }
          }}
          onClick={handleClose}
          disabled={loading}
        >
          Cancel
        </AppButton>

        {tab === 'team' ? (
          <AppButton
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              marginLeft: 2,
              borderRadius: '5px',
              padding: '6px 15px',
              fontWeight: 400,
              width: '120px'
            }}
            invertStyle
            disabled={loading || requestSent}
            onClick={handleConfirmForTeam}
          >
            {loading ? <Loading size={20} thickness={3.6} color="white" /> : 'Confirm'}
          </AppButton>
        ) : (
          <AppButton
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              marginLeft: 2,
              borderRadius: '5px',
              padding: '6px 15px',
              fontWeight: 400,
              width: '138px'
            }}
            invertStyle
            disabled={loading || requestSent}
            onClick={removeAuthorizedClientDomain}
          >
            {loading ? <Loading size={20} thickness={3.6} color="white" /> : 'Delete Domain'}
          </AppButton>
        )}
      </div>
    );
  };

  const renderBody = () => {
    const body = (param: string) => {
      switch (param) {
        case permission.canAssignAdmins:
          return (
            <div>
              <strong>{targetUser.email}</strong> will be made an Admin on this account.
            </div>
          );

        case permission.canDeleteUsers:
          return (
            <div>
              <strong>{targetUser.email}</strong> will no longer be able to access this account. All segments created by
              this user will be permanently deleted.
              <p>Please duplicate any segments still in use before deleting.</p>
            </div>
          );

        case permission.canDeleteAdmin:
          return (
            <div>
              <strong>{targetUser.email}</strong> will no longer have Admin privileges and will remain in account as a
              user.
            </div>
          );

        case permission.canTransferOwnership:
          return (
            <div>
              <strong>{targetUser.email}</strong> will be made the new Owner of this account.
              <p>Accounts are limited to one owner and your account&apos;s user status will be changed to Admin.</p>
            </div>
          );

        case permission.canEditApprovedDomain:
          return (
            <div>
              Users with the <strong>{domain}</strong> domain will no longer be able to be added to the account.
            </div>
          );

        default:
          return (
            <div>
              Users with the <strong>{domain}</strong> domain will no longer be able to be added to the account.
            </div>
          );
      }
    };

    return body(action);
  };

  return (
    <div
      style={{
        padding: '27px 30px 27px 30px',
        minWidth: 442,
        fontFamily: 'Roboto',
        color: brandclub_colors.darkBlue
      }}
    >
      {renderTitle()}
      {renderBody()}
      {renderButton()}
      {errorMessage && (
        <div className="user-management-error-snackbar">
          <AppSnackbar
            open
            setOpen={handleErrorSuccessClose}
            title="Error"
            message={errorMessage}
            alertProps={{ icon: <HighlightOffIcon fontSize="inherit" /> }}
          />
        </div>
      )}

      {successMessage && (
        <AppSnackbar
          open
          autoHideDuration={1500}
          setOpen={() => handleErrorSuccessClose()}
          title="Success"
          message={successMessage}
          severity="success"
          alertProps={{ icon: <CheckCircleOutlineIcon fontSize="inherit" /> }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      )}
    </div>
  );
};

export default UserTeamManagementDeleteUpdateForm;
