import React from 'react';
import DiscoverBrandColumn from '../DiscoverBrandColumn';
import { buildMetricValue } from '../../../utils/metrics';
import { DATATYPE, METRICTYPE } from '../../../utils/entityDefinitions';
import { IsActiveElement } from 'src/components/Discover/DiscoverUtils';

export interface DiscoverColumn {
  field: string;
  cellStyle: Record<string, number | string>;
  flex: number;
  sortable: boolean;
  hide?: boolean;
  cellClass?: string | string[];
  type?: string;
  headerTooltip?: string;
  headerName?: string;
  headerClass?: string;
  cellRendererFramework?: (row: any) => any;
}

export function getDiscoverColumns(retailerObj: any) {
  const discoverColumns: DiscoverColumn[] = [
    {
      field: 'brandName',
      hide: false,
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        'justify-content': 'flex-start',
        'overflow-wrap': 'break-word',
        border: 0
      },
      cellClass: 'grid-cell-brand',
      flex: 1.7,
      headerName: 'Brand',
      cellRendererFramework: (row: any) => {
        const element = <DiscoverBrandColumn row={row} />;
        return element;
      },
      sortable: true
    },
    {
      field: 'subCategoryName',
      headerClass: 'headerClass-cat',
      hide: true,
      headerName: 'Subcategory',
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        border: 0
      },
      cellRendererFramework: (row: any) => {
        const element = (
          <div style={{ fontSize: 14 }} title={row.data.subCategoryName}>
            {row.data.subCategoryName}
          </div>
        );
        return element;
      },
      sortable: true,
      flex: 1.7
    },
    {
      field: 'departmentName',
      hide: true,
      headerClass: 'headerClass-cat',
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        border: 0
      },
      headerName: 'Department',
      cellClass: 'grid-cell',
      sortable: true,
      flex: 1.7
    },
    {
      field: 'manufacturerName',
      hide: true,
      headerName: 'Manufacturer',
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        border: 0
      },
      sortable: true,
      flex: 1.7,
      cellClass: 'grid-cell'
    },
    {
      field: 'categoryName',
      headerClass: 'headerClass-cat',
      hide: true,
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        border: 0
      },
      cellRendererFramework: (row: any) => {
        const element = (
          <div style={{ fontSize: 14 }} title={row.data.categoryName}>
            {row.data.categoryName}
          </div>
        );
        return element;
      },
      headerName: 'Category',
      cellClass: 'grid-cell',
      sortable: true,
      flex: 1.6
    },
    {
      field: 'retailSales',
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        'justify-content': 'flex-end',
        'overflow-wrap': 'break-word',
        border: 0
      },
      cellRendererFramework: (row: any) => {
        const retailSalesValue = buildMetricValue(
          row.data.retailSales,
          METRICTYPE.MONEY,
          retailerObj.currencySymbol,
          true,
          DATATYPE.DECIMAL,
          retailerObj.locale
        );
        const val = `${retailSalesValue.prefix}${retailSalesValue.value}${retailSalesValue.suffix}`;
        return val;
      },
      sortable: true,
      flex: 1.2,
      headerTooltip: 'Retail Sales',
      type: 'numericColumn',
      cellClass: ['ag-right-aligned-cell', 'grid-cell']
    },
    {
      field: 'retailSalesGrowth',
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        'justify-content': 'flex-end',
        border: 0
      },
      cellRendererFramework: (row: any) => {
        const option = {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        };
        const numberFormatter = Intl.NumberFormat(retailerObj.locale, option);
        const val = numberFormatter.format(row.data.retailSalesGrowth / 100);

        const element = (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: val.includes('-') ? '#f35379' : '#39b3ae' }}>
              {!val.includes('-') ? `+${val}` : val}
            </span>
          </div>
        );
        return element;
      },
      headerName: 'Sales Change',
      sortable: true,
      flex: 1.3,
      type: 'numericColumn',
      cellClass: ['ag-right-aligned-cell', 'grid-cell'],
      headerTooltip: 'Retail Sales Growth'
    },
    {
      field: 'marketShare',
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        'justify-content': 'flex-end',
        border: 0
      },
      cellRendererFramework: (row: any) => {
        const numberFormatter = Intl.NumberFormat(retailerObj.locale, {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        });
        const val = numberFormatter.format(row.data.marketShare / 100);
        return val;
      },
      sortable: true,
      flex: 1.2,
      type: 'numericColumn',
      cellClass: ['ag-right-aligned-cell', 'grid-cell'],
      headerTooltip: 'Market Share',
      hide: false
    },
    {
      field: 'marketShareChange',
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        'justify-content': 'flex-end',
        border: 0
      },
      hide: false,
      cellRendererFramework: (row: any) => {
        const numberFormatter = Intl.NumberFormat(retailerObj.locale, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
        const val = numberFormatter.format(row.data.marketShareChange);
        const element = (
          <div>
            <span style={{ color: val.includes('-') ? '#f35379' : '#39b3ae' }}>
              {!val.includes('-') ? `+${val} bps` : `${val} bps`}
            </span>
          </div>
        );
        return element;
      },
      headerName: 'Share Change',
      sortable: true,
      flex: 1.3,
      type: 'numericColumn',
      cellClass: ['ag-right-aligned-cell', 'grid-cell'],
      headerTooltip: 'Share Change(BPS)'
    },

    {
      field: 'unitsSold',
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        'justify-content': 'flex-end',
        border: 0
      },
      cellRendererFramework: (row: any) => {
        const unitsSoldValue = buildMetricValue(
          row.data.unitsSold,
          METRICTYPE.VOLUME,
          retailerObj.currencySymbol,
          true,
          DATATYPE.DECIMAL,
          retailerObj.locale
        );
        const val = `${unitsSoldValue.value}${unitsSoldValue.suffix}`;
        return val;
      },
      sortable: true,
      flex: 1,
      type: 'numericColumn',
      cellClass: ['ag-right-aligned-cell', 'grid-cell'],
      headerTooltip: 'Units Sold'
    },
    {
      field: 'unitsSoldGrowth',
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        'justify-content': 'flex-end',
        border: 0
      },
      hide: false,
      cellRendererFramework: (row: any) => {
        const option = {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        };
        const numberFormatter = Intl.NumberFormat(retailerObj.locale, option);
        const val = numberFormatter.format(row.data.unitsSoldGrowth / 100);
        const element = (
          <div>
            <span style={{ color: val.includes('-') ? '#f35379' : '#39b3ae' }}>
              {!val.includes('-') ? `+${val}` : val}
            </span>
          </div>
        );
        return element;
      },
      headerName: 'Unit Change',
      sortable: true,
      flex: 1.2,
      type: 'numericColumn',
      cellClass: ['ag-right-aligned-cell', 'grid-cell'],
      headerTooltip: 'Unit Sales Growth'
    },
    {
      field: 'trafficShare',
      headerName: 'Traffic Share',
      hide: false,
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        'justify-content': 'flex-end',
        border: 0
      },
      cellRendererFramework: (row: any) => {
        const option = {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        };
        const numberFormatter = Intl.NumberFormat(retailerObj.locale, option);
        const val = numberFormatter.format(row.data.trafficShare / 100);
        return val;
      },
      sortable: true,
      flex: 1.2,
      type: 'numericColumn',
      cellClass: ['ag-right-aligned-cell', 'grid-cell'],
      headerTooltip: 'Total Traffic'
    },
    {
      field: 'conversionRate',
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        'justify-content': 'flex-end',
        border: 0
      },
      headerName: 'Conversion',
      cellRendererFramework: (row: any) => {
        const numberFormatter = Intl.NumberFormat(retailerObj.locale, {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        const val = numberFormatter.format(row.data.conversionRate / 100);
        return val;
      },
      sortable: true,
      flex: 1.1,
      type: 'numericColumn',
      cellClass: ['ag-right-aligned-cell', 'grid-cell'],
      headerTooltip: 'Conversion Rate'
    },
    {
      field: 'avgRating',
      headerName: 'Rating',
      headerClass: 'ag-right-aligned-header',
      cellStyle: {
        'text-align': 'right',
        'flex-direction': 'row',
        'justify-content': 'flex-end',
        border: 0
      },
      cellRendererFramework: (row: any) => {
        const option = {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        };
        const numberFormatter = Intl.NumberFormat(retailerObj.locale, option);
        const val = numberFormatter.format(row.data.avgRating);
        return val;
      },
      sortable: true,
      flex: 0.8,
      type: 'numericColumn',
      cellClass: ['ag-right-aligned-cell', 'grid-cell'],
      headerTooltip: 'Average Rating'
    }
  ];
  return discoverColumns;
}

/* we use this function to figure out which buttons are selected on the UI. Based on this list, headers are created for filters */
export const sendFilterHeaders = (state: IsActiveElement[]) => {
  const selectedButtons: string[] = [];
  if (state) {
    state.forEach((element: IsActiveElement) => {
      const elementName = element.name;
      const elementState = element.isActive;
      if (elementState) {
        selectedButtons.push(elementName);
      }
    });
  }
  return selectedButtons;
};
