import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import Creators from './actions';
import parseChargeBackStatuses from './selectors';
import ReduxStore from 'src/types/store/reduxStore';

export const { receiveChargeBackStatuses, clearChargeBackStatuses } = Creators;

export const fetchChargeBackStatuses =
  () => async (dispatch: ThunkDispatch<ReduxStore, void, any>, getState: () => ReduxStore) => {
    const appName = getState().app.apiAppName;

    const request = [
      {
        name: `${appName}-chargeBackStatuses`,
        pageNumber: 1,
        pageSize: 500,
        doAggregation: false,
        returnDocuments: true,
        searchBy: 'parent',
        searchType: `${appName}-chargeBackStatuses`,
        aggregations: null
      }
    ];

    const response = await axios.post(`/api/${appName}/AdvancedSearch?_id=fetchChargeBackStatuses`, request);
    dispatch(receiveChargeBackStatuses(parseChargeBackStatuses(response)));
  };
