import types, { AdTargetState } from './types';
import actionCreators from './actions';
import { ValueOf } from 'sl-api-connector';
import { AD_STATUS_DERIVED, AD_CAMPAIGN_STATUS } from 'sl-ad-campaign-manager-data-model';

// ------------------------------------
// Reducer
// ------------------------------------

const adTargetStatesAvailable: AdTargetState[] = [
  {
    id: AD_CAMPAIGN_STATUS.ENABLED,
    targetState: AD_CAMPAIGN_STATUS.ENABLED,
    name: 'Enabled',
    displayName: 'Enabled',
    type: 'adTargetState'
  },
  {
    id: AD_STATUS_DERIVED.PAUSED,
    targetState: AD_STATUS_DERIVED.PAUSED,
    name: 'Paused',
    displayName: 'Paused',
    type: 'adTargetState'
  }
];
type Action = ValueOf<{ [K in keyof typeof actionCreators]: ReturnType<(typeof actionCreators)[K]> }>;

export function adTargetStates(state = adTargetStatesAvailable, action: Action) {
  switch (action.type) {
    case types.RECEIVE_AD_TARGET_STATES:
      return action.adTargetStates;
    case types.CLEAR_AD_TARGET_STATES:
      return [];
    default:
      return state;
  }
}
