import React from 'react';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import { useStacklineTheme } from '@stackline/ui';

/**
 * Wrapper around MUI skeleton that matches the mocks
 */
const SlSkeleton = (props: SkeletonProps) => {
  const theme = useStacklineTheme();
  return (
    <Skeleton
      sx={{
        backgroundColor: theme.colors.secondaryGhost
      }}
      {...props}
    />
  );
};

export default SlSkeleton;
