import types from './types';

export const receiveOmniShareOfShelfShards = (entity: any) => ({
  type: types.RECEIVE_SHARDS_SERVICE_DATA,
  entity
});

export const startFetchData = (entity: any) => ({
  type: types.START_FETCH,
  entity
});

export const endFetchData = (entity: any) => ({
  type: types.END_FETCH,
  entity
});
