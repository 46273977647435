import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'qs';
import Truncate from 'react-truncate';
import { AppName } from 'sl-api-connector/types';
import NumberFormat from 'react-number-format';
import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';
import _orderBy from 'lodash/orderBy';
import _uniq from 'lodash/uniq';
import _get from 'lodash/get';
import SubmitScreen from 'src/components/AdManager/SubmitScreen/SubmitScreen';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import { getEmptyFormData } from 'src/utils/segments';
import { AdManagerEvents } from 'src/types/application/event';
import ReduxStore from 'src/types/store/reduxStore';
import _upperFirst from 'lodash/upperFirst';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import DataColumn from 'src/components/AdManager/Search/CustomColumns/DataColumn';
import { useBus } from 'src/utils/Hooks';
import { withBus } from 'react-bus';
import { store } from 'src/main';
import { getCampaignDisplayStatus } from 'src/components/AdManager/AmsUtils';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { AdCampaignManagerServiceClient } from 'src/utils/adCampaignManagerServiceClient';
import {
  buildAggregations,
  buildTimePeriodRangeFilters,
  zipMetricsResponseIntoArray
} from 'src/components/AdManager/Search';
import { AD_CAMPAIGN_STATUS, AD_STATUS_DERIVED } from 'sl-ad-campaign-manager-data-model';
import CustomAgGridHeaderTemplate from 'src/components/EntityGrid/Table/CustomAgGridHeaderTemplate';
import { splitSnakeCase } from 'src/utils/stringFormatting';
import { BulkAdjustBid } from 'src/components/AdManager/TargetingPage';
import AddNewPillFilters from 'src/components/Search/AdvancedSearch/AdvancedSearchSideBar/PillFilters';
import {
  buildAdvancedSearchSideBarWidget as buildExistingSideBar,
  getFieldDisplayName
} from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';
import { withProps } from 'src/utils/hoc';
import colors from 'src/utils/colors';
import AdManagerSearchSideBar from 'src/components/AdManager/Search/SearchSideBar';
import { getUserColumns } from 'src/routes/UserAccount/UserCustomColumn';

const style = {
  button: {
    width: 99
  },
  textInput: {
    height: '32px',
    outline: 'none',
    border: 'none',
    borderRadius: '5px',
    width: '80px',
    padding: 10,
    fontSize: '14px',
    color: 'currentColor',
    background: '#eff1f5',
    fontFamily: 'Roboto'
  },
  auto: {
    border: `1px solid ${colors.orange}`,
    borderRadius: '4px',
    padding: '0 4px',
    color: colors.orange
  }
};

const onGridReady = (params: any) => {
  params.api.sizeColumnsToFit();
  params.api.refreshHeader();
};

const isRangeFilterNotEqual = (
  rangeFilter1: { minValue?: number; maxValue?: number },
  rangeFilter2: { minValue?: number; maxValue?: number }
) => {
  return (
    `${rangeFilter1.minValue}` !== `${rangeFilter2.minValue}` ||
    `${rangeFilter1.maxValue}` !== `${rangeFilter2.maxValue}`
  );
};

const mapStateToColumnProps = ({ app, adCampaigns }: ReduxStore) => ({ app, adCampaigns });

const TargetBidCampaignCol = connect(mapStateToColumnProps)((props: any) => {
  const {
    app: {
      defaultQueryParams,
      queryParams: { searchParams, additionalParams }
    },
    adCampaigns
  } = props;
  const parsedAdditionalParameters = queryString.parse(additionalParams);
  parsedAdditionalParameters.tab = defaultQueryParams.tab;
  parsedAdditionalParameters.subtab = defaultQueryParams.subtab;
  const campaignId = _get(props, ['data', 'entity', 'extendedAttributes', 'childDocuments', 0, 'campaignId']);
  const isAutoAdded = _get(props, ['data', 'entity', 'isAutoAdded'], '0');
  const link = `/adCampaign/${campaignId}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`;
  const adCampaign = adCampaigns.find((x) => x.campaignId === campaignId);
  if (adCampaign) {
    const {
      campaignName,
      extendedAttributes: {
        statusDisplay: { color: statusColor },
        campaignTypeDisplayName
      }
    } = adCampaign;
    return (
      <Link
        style={{ display: 'block', height: '100%', textDecoration: 'none' }}
        // onClick={this.stopPropagation}
        target="_blank"
        to={link}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ background: statusColor, borderRadius: '50px', height: 10, width: 10, marginRight: 10 }}></div>
          <div className="campaign_column" title={campaignName}>
            <Truncate lines={2} ellipsis="...">
              {campaignName}
            </Truncate>
            &nbsp;{isAutoAdded === '1' && <span style={style.auto}>Auto</span>}
            <br />
            <div className="type_pill">{campaignTypeDisplayName}</div>
          </div>
        </div>
      </Link>
    );
  }
  return (
    <Link
      style={{ display: 'block', height: '100%', textDecoration: 'none' }}
      // onClick={this.stopPropagation}
      target="_blank"
      to={link}
    >
      <div className="campaign_column" title={campaignId}>
        {campaignId} {isAutoAdded === '1' && <span style={style.auto}>Auto</span>}
      </div>
    </Link>
  );
});

const TargetBidAdGroupCol = connect(mapStateToColumnProps)((props: any) => {
  const {
    app: {
      defaultQueryParams,
      queryParams: { searchParams, additionalParams }
    }
  } = props;
  const parsedAdditionalParameters = queryString.parse(additionalParams);
  parsedAdditionalParameters.tab = defaultQueryParams.tab;
  parsedAdditionalParameters.subtab = defaultQueryParams.subtab;
  const adGroupId = _get(props, ['data', 'entity', 'extendedAttributes', 'childDocuments', 0, 'adGroupId']);
  const adGroupName = _get(props, ['data', 'entity', 'extendedAttributes', 'childDocuments', 0, 'adGroupName']);
  const link = `/adGroup/${adGroupId}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`;
  return (
    <Link
      style={{ display: 'block', height: '100%', textDecoration: 'none' }}
      // onClick={this.stopPropagation}
      target="_blank"
      to={link}
    >
      <div className="campaign_column" title={adGroupName}>
        {adGroupName}
      </div>
    </Link>
  );
});

const buildTermFilterCondition = ({
  idFieldName,
  filterValues,
  condition
}: {
  idFieldName: string;
  filterValues: { i: string; n?: string }[];
  condition?: 'should' | 'must' | 'must_not';
}) => {
  const termFilters = [];
  if (filterValues.length > 0) {
    termFilters.push({
      fieldName: idFieldName,
      values: filterValues.map((value) => value.i),
      condition
    });
  }
  return termFilters;
};

const terms = [
  { filterName: 'campaignNameFuzzy', fieldName: 'campaignNameFuzzy' },
  { filterName: 'excludedCampaignNameFuzzy', fieldName: 'excludedCampaignNameFuzzy' },
  { filterName: 'entityId', fieldName: 'entityId' },
  { filterName: 'portfolioId', fieldName: 'portfolioId' },
  { filterName: 'derivedCampaignType', fieldName: 'derivedCampaignType' },
  { filterName: 'statusDerived', fieldName: 'statusDerived' },
  { filterName: 'automationStrategyId', fieldName: 'automationStrategyId' },
  { filterName: 'targetingType', fieldName: 'targetingType' },
  { filterName: 'isAutoAdded', fieldName: 'isAutoAdded' }
];

const ranges = [
  { filterName: 'spendComputed', fieldName: 'spendComputed' },
  { filterName: 'costPerClick', fieldName: 'costPerClick' },
  { filterName: 'returnOnAdSpend', fieldName: 'returnOnAdSpend' },
  { filterName: 'bid', fieldName: 'cpcMinBidAmount' }
];

const getMinBid = (data, adPlatformSettings) => {
  const campaignType = _get(data, ['entity', 'extendedAttributes', 'childDocuments', 0, 'campaignType'], null);
  const match = adPlatformSettings.find((set) => _get(set, ['extendedAttributes', 'campaignType']) === campaignType);

  if (match) {
    return _get(match, ['extendedAttributes', 'targetLimits', 'minimumBid'], 0.1);
  }

  // fallback logic
  return campaignType === 'sponsoredBrands' ? 0.1 : 0.02;
};

const getMaxBid = (data, adPlatformSettings) => {
  const campaignType = _get(data, ['entity', 'extendedAttributes', 'childDocuments', 0, 'campaignType'], null);
  const match = adPlatformSettings.find((set) => _get(set, ['extendedAttributes', 'campaignType']) === campaignType);

  if (match) {
    return _get(match, ['extendedAttributes', 'targetLimits', 'maximumBid'], 0.1);
  }

  // fallback logic
  return campaignType === 'sponsoredBrands' ? 49 : 1000;
};

const mapStateToPropsForColumn = (state: ReduxStore) => {
  const { retailer, adPlatformSettings, user } = state;
  return {
    retailer,
    adPlatformSettings,
    user
  };
};

const EditBitColumn = connect(mapStateToPropsForColumn)(
  withBus('eventBus')(({ eventBus, value, data, retailer, adPlatformSettings, user }) => {
    const apiLimits = adPlatformSettings.filter((set) => set.settingType === 'apiLimit');
    const minBid = getMinBid(data, apiLimits);
    const maxBid = getMaxBid(data, apiLimits);
    const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);
    return (
      <NumberFormat
        value={value}
        isAllowed={({ floatValue }) => {
          return floatValue >= minBid && floatValue <= maxBid; // Amazon's rule, all bid price must be greater or equal to $0.02
        }}
        thousandSeparator
        prefix={retailer.currencySymbol}
        decimalScale={2}
        fixedDecimalScale
        allowNegative={false}
        disabled={isAdAuditUser}
        onValueChange={({ floatValue }) =>
          eventBus.emit('handleCampaignBidPriceChange', { key: data.id, value: floatValue })
        }
        // Mimic the style of the MUI `TextInput`
        style={style.textInput}
      />
    );
  })
);

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, ['app', 'retailer', 'mainTimePeriod', 'comparisonTimePeriod', 'adPlatformSettings', 'user']),
    mainEntity: state.entityService.mainEntity
  };
};

const TargetBids: React.FC<ReturnType<typeof mapStateToProps> & { adGroupId: any; eventBus: any }> = ({
  eventBus,
  app,
  retailer,
  mainTimePeriod,
  comparisonTimePeriod,
  mainEntity,
  adGroupId,
  adPlatformSettings,
  user
}) => {
  const userColumns = getUserColumns();
  const [isLoading, setLoading] = useState(true);
  const [submitStatus, setSubmitStatus] = useState({ status: 'normal' });
  const [dataSet, setDataSet] = useState([]);
  const [formData, setFormData] = useState({});
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortFieldName, setSortFieldName] = useState(userColumns[0]);
  const [bidMapping, setBidMapping] = useState(new Map());
  const [filterRequest, setFilterRequest] = useState(undefined);
  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);

  const handleBidChange = ({ key, value }: { key: string; value: any }) => {
    // bidMapping.set(id, value);
    const newBidMapping = new Map(bidMapping);
    newBidMapping.set(key, value);
    setBidMapping(newBidMapping);
  };

  const apiLimits = adPlatformSettings.filter((set) => set.settingType === 'apiLimit');

  const handleBulkBidChange = ({ type, value }: { type: string; value: any }) => {
    if (type === 'increaseMoney') {
      const newDataSet = _cloneDeep(dataSet);
      newDataSet.forEach((dataPoint) => {
        const originalBid = _get(dataPoint, ['entity', 'extendedAttributes', 'childDocuments', 0, 'cpcMaxBidAmount']);
        let newBid = originalBid + (value || 0);
        const minBid = getMinBid(dataPoint, apiLimits);
        const maxBid = getMaxBid(dataPoint, apiLimits);
        newBid = newBid > maxBid ? maxBid : newBid < minBid ? minBid : newBid;
        dataPoint.bid = newBid.toFixed(2);
      });
      setBidMapping(new Map());
      setDataSet(newDataSet);
    } else if (type === 'increasePercent') {
      const newDataSet = _cloneDeep(dataSet);
      newDataSet.forEach((dataPoint) => {
        const originalBid = _get(dataPoint, ['entity', 'extendedAttributes', 'childDocuments', 0, 'cpcMaxBidAmount']);
        let newBid = originalBid * (1 + (value || 0) * 0.01);
        const minBid = getMinBid(dataPoint, apiLimits);
        const maxBid = getMaxBid(dataPoint, apiLimits);
        newBid = newBid > maxBid ? maxBid : newBid < minBid ? minBid : newBid;
        dataPoint.bid = newBid.toFixed(2);
      });
      setBidMapping(new Map());
      setDataSet(newDataSet);
    } else if (type === 'setMoney') {
      const newDataSet = _cloneDeep(dataSet);
      newDataSet.forEach((dataPoint) => {
        const originalBid = _get(dataPoint, ['entity', 'extendedAttributes', 'childDocuments', 0, 'cpcMaxBidAmount']);
        let newBid = value || value === 0 ? value : originalBid;
        const minBid = getMinBid(dataPoint, apiLimits);
        const maxBid = getMaxBid(dataPoint, apiLimits);
        newBid = newBid > maxBid ? maxBid : newBid < minBid ? minBid : newBid;
        dataPoint.bid = newBid.toFixed(2);
      });
      setBidMapping(new Map());
      setDataSet(newDataSet);
    } else if (type === 'decreaseMoney') {
      const newDataSet = _cloneDeep(dataSet);
      newDataSet.forEach((dataPoint) => {
        const originalBid = _get(dataPoint, ['entity', 'extendedAttributes', 'childDocuments', 0, 'cpcMaxBidAmount']);
        let newBid = originalBid - (value || 0);
        const minBid = getMinBid(dataPoint, apiLimits);
        const maxBid = getMaxBid(dataPoint, apiLimits);
        newBid = newBid > maxBid ? maxBid : newBid < minBid ? minBid : newBid;
        dataPoint.bid = newBid.toFixed(2);
      });
      setBidMapping(new Map());
      setDataSet(newDataSet);
    } else if (type === 'decreasePercent') {
      const newDataSet = _cloneDeep(dataSet);
      newDataSet.forEach((dataPoint) => {
        const originalBid = _get(dataPoint, ['entity', 'extendedAttributes', 'childDocuments', 0, 'cpcMaxBidAmount']);
        let newBid = originalBid * (1 - (value || 0) * 0.01);
        const minBid = getMinBid(dataPoint, apiLimits);
        const maxBid = getMaxBid(dataPoint, apiLimits);
        newBid = newBid > maxBid ? maxBid : newBid < minBid ? minBid : newBid;
        dataPoint.bid = newBid.toFixed(2);
      });
      setBidMapping(new Map());
      setDataSet(newDataSet);
    }
  };

  useEffect(() => {
    eventBus.emit('targetPageButtonStatus', submitStatus);
  }, [eventBus, submitStatus]);

  const submit = async () => {
    setSubmitStatus({ status: 'submitting' });
    const entitiesSelected = dataSet
      .filter((dataPoint: any) => {
        const originalBid = +dataPoint.entity.extendedAttributes.childDocuments[0].cpcMaxBidAmount;
        let newBid = dataPoint.bid;
        if (
          bidMapping.has(dataPoint.id) &&
          bidMapping.get(dataPoint.id) &&
          bidMapping.get(dataPoint.id) !== dataPoint.bid
        ) {
          newBid = bidMapping.get(dataPoint.id);
        }
        dataPoint.entity.extendedAttributes.childDocuments[0].cpcMaxBidAmount = newBid;
        dataPoint.entity.extendedAttributes.childDocuments[0].cpcMinBidAmount = newBid;
        return `${newBid}` !== `${originalBid}`;
      })
      .map(({ entity }) => entity);
    if (entitiesSelected && entitiesSelected.length > 0) {
      const { beaconClientId } = entitiesSelected.filter((x: any) => x.beaconClientId)[0];
      const { platformType } = entitiesSelected.filter((x: any) => x.platformType)[0];
      try {
        const res = await AdCampaignManagerServiceClient.updateTargetBid({
          beaconClientId,
          platformType,
          entities: entitiesSelected,
          entityType: entitiesSelected[0].type
        });
        if (res) {
          if (res.status === 200) {
            setSubmitStatus({ status: 'submitted', result: res });
          } else {
            setSubmitStatus({ status: 'failure', result: res });
          }
        } else {
          setSubmitStatus({ status: 'failure', result: res });
        }
      } catch (err) {
        setSubmitStatus({ status: 'failure' });
      }
    }
  };

  const sideBarFilterUpdated = ({ formData: newFormData }: { formData: any }) => {
    setFormData(newFormData);
  };

  useBus(eventBus, 'handleCampaignBidPriceChange', handleBidChange);
  useBus(eventBus, 'bulkApplyBidPrice', handleBulkBidChange);
  useBus(eventBus, AdManagerEvents.ENTITY_EVENT_METRICS_GRID_FILTER_UPDATED, sideBarFilterUpdated);

  const handleSortClick = (field: string) => {
    let newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    if (sortFieldName !== field) {
      newSortDirection = 'desc';
    }
    // @ts-ignore
    setSortDirection(newSortDirection);
    setSortFieldName(field);
  };

  const userColumnsDef = userColumns.map((field: string) => ({
    headerName: getFieldDisplayName(field),
    cellRendererFramework: DataColumn,
    cellStyle: { 'justify-content': 'flex-end', 'text-align': 'right', 'padding-right': '20px' },
    field,
    disableSort: false,
    sortDirection,
    sortByMetric: sortFieldName,
    headerComponentFramework: CustomAgGridHeaderTemplate,
    onSortFunction: () => {
      handleSortClick(field);
    },
    minWidth: 100,
    maxWidth: 200
  }));

  const columnDef = [
    {
      headerName: 'Campaign',
      field: 'campaignId',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 280,
      maxWidth: 600,
      cellRendererFramework: TargetBidCampaignCol,
      headerClass: 'align-left campaign-id',
      pinned: 'left'
    },
    {
      headerName: 'Ad Group',
      field: 'adGroupId',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 250,
      maxWidth: 400,
      cellRendererFramework: TargetBidAdGroupCol,
      headerClass: 'align-left ad-group',
      pinned: 'left'
    },
    {
      headerName: 'Match',
      field: 'entity',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 110,
      maxWidth: 150,
      valueFormatter: ({ value }) => _upperFirst(splitSnakeCase(value.targetingType.toLowerCase())),
      headerClass: 'align-left match',
      pinned: 'left'
    },
    {
      headerName: 'Bid',
      cellRendererFramework: EditBitColumn,
      field: 'bid',
      minWidth: 110,
      maxWidth: 110,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left',
      disableSort: false,
      sortDirection,
      sortByMetric: sortFieldName,
      headerComponentFramework: CustomAgGridHeaderTemplate,
      onSortFunction: () => {
        handleSortClick('bid');
      },
      pinned: 'left'
    },
    ...userColumnsDef
  ];

  if (mainEntity.type === 'product') {
    columnDef.splice(2, 1);
  }

  const fetchData = async () => {
    setLoading(true);
    const indexName = 'adCampaignAdGroupTargetDailyMetrics';
    const pageSize = 1000;
    const statePropertyName = `existingTargetingForCampaign_${mainEntity.id}`;
    const widget = {
      view: {
        gridOptions: { pageSize }
      },
      data: { statePropertyName }
    };
    const initAggregationFields = userColumns.map((fieldName) => INDEX_FIELDS.getField(app.name, indexName, fieldName));

    const [{ aggregations: aggregationFields }] = buildAggregations(initAggregationFields);
    const { mainTimePeriodRangeFilters, comparisonTimePeriodRangeFilters } = buildTimePeriodRangeFilters({
      app,
      indexName,
      mainTimePeriod,
      comparisonTimePeriod
    });
    const termFilters = [
      {
        fieldName: 'targetingText',
        values: [mainEntity.targetingText]
      }
    ];

    const mainCondition = {
      termFilters: [...termFilters],
      rangeFilters: [...mainTimePeriodRangeFilters]
    };

    const searchSideBarConditions = {
      termFilters: [],
      rangeFilters: []
    };

    terms.forEach(({ filterName, fieldName }: { filterName: string; fieldName: string }) => {
      if (_get(formData, ['termFilters', filterName])) {
        searchSideBarConditions.termFilters = [
          ...searchSideBarConditions.termFilters,
          ...buildTermFilterCondition({
            filterValues: formData.termFilters[filterName].values,
            condition: formData.termFilters[filterName].condition,
            idFieldName: fieldName
          })
        ];
      }
    });

    const aggregationFilters = {
      termFilters: [],
      rangeFilters: [],
      computeFilters: []
    };

    const emptyFormData = getEmptyFormData({ appName: AppName.Advertising });

    ranges.forEach(({ filterName, fieldName }: { filterName: string; fieldName: string }) => {
      if (_get(formData, ['rangeFilters', filterName])) {
        if (isRangeFilterNotEqual(emptyFormData.rangeFilters[filterName], formData.rangeFilters[filterName])) {
          if (filterName !== 'bid') {
            aggregationFilters.computeFilters = [
              ...aggregationFilters.computeFilters,
              {
                fieldName,
                minValue: formData.rangeFilters[filterName].minValue,
                maxValue: formData.rangeFilters[filterName].maxValue
              }
            ];
          }
        }
      }
    });

    const existFilter = searchSideBarConditions.termFilters.find(
      (item) => !['targetingType', 'isAutoAdded'].includes(item.fieldName) && item.values && item.values.length > 0
    );
    setFilterRequest(undefined);
    if (existFilter) {
      const campaignResult = await store.dispatch(
        fetchEntityMetrics(
          statePropertyName,
          {
            entity: mainEntity,
            retailer,
            app,
            indexName: 'adCampaignDailyMetrics',
            customResponseParser: (action: any) => zipMetricsResponseIntoArray(action, widget)
          },
          [
            {
              doAggregation: true,
              aggregations: [
                {
                  aggregationFields,
                  conditions: {
                    termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
                    rangeFilters: [...mainTimePeriodRangeFilters],
                    computeFilters: [...aggregationFilters.computeFilters]
                  },
                  sortDirection,
                  sortByAggregationField: aggregationFields.find((x) => x.aggregateByFieldName === sortFieldName),
                  comparisonRangeFilters: comparisonTimePeriodRangeFilters,
                  groupByFieldName: 'campaignId'
                }
              ],
              conditions: {
                termFilters: searchSideBarConditions.termFilters.filter((item) => item.fieldName !== 'targetingType'),
                rangeFilters: searchSideBarConditions.rangeFilters
              },
              pageSize: 2000,
              processDocuments: false
            }
          ],
          null,
          true
        )
      );
      const campaignIdList = _get(campaignResult, ['data'], []).map((item) => item.id);
      const campaignCondition = {
        fieldName: 'campaignId',
        condition: 'must',
        values: campaignIdList
      };
      mainCondition.termFilters = [...mainCondition.termFilters, campaignCondition];
      setFilterRequest(campaignResult);
      if (campaignIdList.length === 0) {
        setDataSet([]);
        setLoading(false);
        return;
      }
    }

    const targetingTextFilter = searchSideBarConditions.termFilters.find(
      (item) => ['targetingType', 'isAutoAdded'].includes(item.fieldName) && item.values && item.values.length > 0
    );

    if (targetingTextFilter) {
      mainCondition.termFilters.push(targetingTextFilter);
    }

    const targetingGroupBy = retailer.id === '63' ? 'adGroupId,isAutoAdded' : 'adGroupId,targetingType';
    const isWalmartProductEntity = (retailer.id === '2' || retailer.id === '25') && mainEntity.type === 'product';
    const autoTargetCampaignCondition = {
      fieldName: 'targetingType',
      values: ['auto_targeting']
    };
    const overideTermFilters = [
      { fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] },
      {
        fieldName: 'isProjected',
        condition: 'must',
        values: [0]
      }
    ];

    if (isWalmartProductEntity) {
      overideTermFilters.push(autoTargetCampaignCondition);
    }

    const searchRequestOverrides = [
      {
        doAggregation: true,
        aggregations: [
          {
            aggregationFields,
            conditions: {
              termFilters: overideTermFilters,
              rangeFilters: [...mainTimePeriodRangeFilters],
              computeFilters: [...aggregationFilters.computeFilters]
            },
            sortDirection,
            sortByAggregationField: aggregationFields.find((x) => x.aggregateByFieldName === sortFieldName),
            comparisonRangeFilters: comparisonTimePeriodRangeFilters,
            groupByFieldName: targetingGroupBy
          }
        ],
        conditions: mainCondition,
        pageSize,
        processDocuments: false
      }
    ];

    // fetch target data.
    const result = await store.dispatch(
      fetchEntityMetrics(
        statePropertyName,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName,
          customResponseParser: (action: any) => zipMetricsResponseIntoArray(action, widget)
        },
        searchRequestOverrides,
        null,
        true
      )
    );

    const cloneResult = result;

    const targetingTextValues: string[] = [];
    result.data.forEach((dataPoint: any) => {
      if (!targetingTextValues.includes(dataPoint.entity.id)) {
        targetingTextValues.push(dataPoint.entity.id);
      }
    });

    const queryConditions: {
      termFilters: { fieldName: string; condition: string; values: string[] }[];
      rangeFilters: { fieldName: string; minValue: number; maxValue: number }[];
    } = {
      termFilters: [...mainCondition.termFilters],
      rangeFilters: []
    };

    if (_get(formData, ['rangeFilters', 'bid'])) {
      ranges.forEach((range) => {
        if (range.filterName === 'bid') {
          const { fieldName } = range;
          const { minValue, maxValue } = _get(formData, ['rangeFilters', 'bid']);
          queryConditions.rangeFilters = [
            {
              fieldName,
              minValue,
              maxValue
            }
          ];
        }
      });
    }
    const docTermFilters = _cloneDeep(mainCondition.termFilters);

    docTermFilters.forEach((term) => {
      if (term.fieldName === 'isAutoAdded') {
        term.fieldName = 'isAutoAddedBoolean';
        const values = [];
        if (term.values.includes('1')) {
          values.push(true);
        }
        if (term.values.includes('0')) {
          values.push(false);
        }
        term.values = values;
      }
    });

    if (isWalmartProductEntity) {
      docTermFilters.push(autoTargetCampaignCondition);
    }

    const { documents: adTargetDocuments } = await store.dispatch(
      fetchEntityMetrics(
        `${statePropertyName}_Documents`,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName: 'adCampaignAdGroupTarget'
        },
        [
          {
            doAggregation: false,
            conditions: {
              termFilters: [...docTermFilters],
              rangeFilters: [...queryConditions.rangeFilters] // put the bid filter in this array
            },
            pageNumber: 1,
            pageSize: 10000,
            processDocuments: false,
            returnDocuments: true
          }
        ],
        null,
        true
      )
    );

    const adTargetDocumentsByTargetingText: any = {};
    const fullResult = [];
    adTargetDocuments.forEach((adTargetDocument: any) => {
      if (!adTargetDocumentsByTargetingText[adTargetDocument.adGroupId + adTargetDocument.targetingType]) {
        adTargetDocumentsByTargetingText[adTargetDocument.adGroupId + adTargetDocument.targetingType] = {
          beaconClientId: adTargetDocument.beaconClientId,
          platformType: adTargetDocument.platformType,
          targetingText: adTargetDocument.targetingText,
          targetingType: adTargetDocument.targetingType,
          isAutoAdded: adTargetDocument.isAutoAddedBoolean ? '1' : '0',
          extendedAttributes: {
            statusReason: '',
            status: AD_CAMPAIGN_STATUS.PAUSED,
            statusDerived: AD_STATUS_DERIVED.PAUSED,
            childDocuments: []
          }
        };
      }
      if (adTargetDocument.status === AD_CAMPAIGN_STATUS.ENABLED) {
        adTargetDocumentsByTargetingText[
          adTargetDocument.adGroupId + adTargetDocument.targetingType
        ].extendedAttributes.status = AD_CAMPAIGN_STATUS.ENABLED;
      }
      if (adTargetDocument.statusDerived === AD_STATUS_DERIVED.DELIVERING) {
        adTargetDocumentsByTargetingText[
          adTargetDocument.adGroupId + adTargetDocument.targetingType
        ].extendedAttributes.statusDerived = AD_STATUS_DERIVED.DELIVERING;
      }
      adTargetDocumentsByTargetingText[
        adTargetDocument.adGroupId + adTargetDocument.targetingType
      ].extendedAttributes.childDocuments.push(adTargetDocument);

      adTargetDocumentsByTargetingText[
        adTargetDocument.adGroupId + adTargetDocument.targetingType
      ].extendedAttributes.campaignIds = _uniq(
        adTargetDocumentsByTargetingText[
          adTargetDocument.adGroupId + adTargetDocument.targetingType
        ].extendedAttributes.childDocuments.map((doc: any) => doc.campaignId)
      );
      adTargetDocumentsByTargetingText[
        adTargetDocument.adGroupId + adTargetDocument.targetingType
      ].extendedAttributes.campaignIdsDelivering = _uniq(
        adTargetDocumentsByTargetingText[
          adTargetDocument.adGroupId + adTargetDocument.targetingType
        ].extendedAttributes.childDocuments
          .filter((x: any) => x.statusDerived === AD_STATUS_DERIVED.DELIVERING)
          .map((doc: any) => doc.campaignId)
      );

      adTargetDocumentsByTargetingText[
        adTargetDocument.adGroupId + adTargetDocument.targetingType
      ].extendedAttributes.statusDisplay = getCampaignDisplayStatus(
        adTargetDocumentsByTargetingText[adTargetDocument.adGroupId + adTargetDocument.targetingType].extendedAttributes
      );
      const targetWithData = cloneResult.data.find(
        (item: any) => item.id === adTargetDocument.adGroupId + adTargetDocument.targetingType
      );
      const docEntity = adTargetDocumentsByTargetingText[adTargetDocument.adGroupId + adTargetDocument.targetingType];
      const { targetingType } = docEntity;
      if (!targetingType.includes('negative')) {
        fullResult.push({
          ...targetWithData,
          id: adTargetDocument.adGroupId + adTargetDocument.targetingType,
          entity: {
            nameFieldName: 'targetingText',
            keyFieldName: 'targetingText',
            name: docEntity.targetingText,
            id: docEntity.targetingText,
            type: 'adTarget',
            // ...targetWithData.entity,
            ...docEntity
          },
          bid: _get(docEntity, ['extendedAttributes', 'childDocuments', 0, 'cpcMaxBidAmount'])
        });
      }
    });

    // Not all filtering/sorting can be done entirely via API call, we must do it on the client side below
    // all of these filters/sorts happen with `cleanedAndOptionallySortedData`

    // if we are sorting by bid, sort them here
    let cleanedAndOptionallySortedData = [];

    cleanedAndOptionallySortedData = _cloneDeep(fullResult);
    if (sortFieldName === 'bid') {
      cleanedAndOptionallySortedData = _orderBy(cleanedAndOptionallySortedData, 'bid', sortDirection);
    } else {
      cleanedAndOptionallySortedData = _orderBy(
        cleanedAndOptionallySortedData,
        (e) => _get(e, [sortFieldName, 'value'], 0),
        sortDirection
      );
    }

    // since we cannot filter first call by bid, we filter on second call so we must filter out those
    // datapoints without a bid (meaning they did not fall within the range in the 2nd call)
    cleanedAndOptionallySortedData = cleanedAndOptionallySortedData.filter(
      (dataPoint: any) => dataPoint.bid !== undefined
    );

    eventBus.emit('setSearchHeader', {
      isLoading: false,
      entity: { result: cleanedAndOptionallySortedData }
    });
    setDataSet(cleanedAndOptionallySortedData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, mainEntity, mainTimePeriod, retailer.id, adGroupId, sortDirection, sortFieldName]);

  const isSubmitting = submitStatus.status === 'submitting';

  if (submitStatus.status === 'submitted') {
    return (
      <div className="targeting_container">
        <SubmitScreen respondResult={submitStatus.result} />
      </div>
    );
  }

  const renderExistingButtons = () => {
    return isAdAuditUser ? null : (
      <div style={{ display: 'flex' }}>
        <BulkAdjustBid eventBus={eventBus} retailer={retailer} />
        <LargeMuiButton
          disabled={isSubmitting}
          style={style.button}
          label={isSubmitting ? 'Submitting' : 'Submit'}
          onClick={submit}
        />
      </div>
    );
  };

  const renderExistingFilter = () => {
    const advancedSearchSideBarWidget = buildExistingSideBar();
    advancedSearchSideBarWidget.data.useInternalState = true;
    advancedSearchSideBarWidget.data.isUnderComponent = true;
    const ExistingSearchSidebar = withProps({
      formDataOverride: formData,
      widget: advancedSearchSideBarWidget,
      filterDefinitionOverride: 'targetBids'
    })(AdManagerSearchSideBar);
    return (
      <>
        <ExistingSearchSidebar />
        <AddNewPillFilters isLoadingFalse defaultApiResult={filterRequest} />
      </>
    );
  };

  const renderGrid = () => {
    return (
      <>
        <div className="sub_header" style={{ height: 70 }}>
          <div className="filter_area">{renderExistingFilter()}</div>
          <div className="button_area" style={{}}>
            {renderExistingButtons()}
          </div>
        </div>
        <div className="recommendations_grid" style={{ position: 'relative' }}>
          <CustomAgMaterial
            onGridReady={onGridReady}
            onCellValueChanged={onGridReady}
            onModelUpdated={onGridReady}
            onRowValueChanged={onGridReady}
            onRowDataChanged={onGridReady}
            buildRows={() => dataSet}
            suppressNoRowsOverlay
            columnDefs={columnDef}
            getRowNodeId={(datum: any) => escape(datum.id)}
            domLayout="autoHeight"
            rowHeight={120}
            containerStyle={{ width: '100%' }}
          />
        </div>
      </>
    );
  };

  return <div className="targeting_container">{isLoading ? <GridLoading /> : renderGrid()}</div>;
};

export default connect(mapStateToProps)(withBus('eventBus')(TargetBids));
