import _flatMap from 'lodash/flatMap';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _prop from 'lodash/property';
import { Option } from 'funfix-core';
import { SavedSearch, BusinessUnit, Conditions } from 'sl-api-connector/types';

import { filterNils } from 'src/utils/fp';
import BusinessUnitFilter from 'src/components/EntityPage/Filters/BusinessUnitFilter';
import { conditionsAreEmpty, updateConditionsToCurrentRetailer } from 'src/utils/conditions';
import ReduxStore from 'src/types/store/reduxStore';

const buildFiltersForBU = (
  savedSearchesById: Map<string, SavedSearch>,
  selectedBusinessUnits: Set<string>,
  businessUnit: BusinessUnit,
  level: number
): { id: string; level: number }[] => {
  const { id, displayName } = businessUnit;
  const childSegmentsAndBUs = filterNils(
    businessUnit.children.map((childId) => {
      const child = savedSearchesById.get(childId);
      if (!child) {
        console.warn(`BU ${id} referenced child with id ${childId} but it wasn't found.`);
        return null;
      }
      return child;
    })
  );

  const filter = { displayName, id, level, component: BusinessUnitFilter, parentBusinessUnit: businessUnit };

  // Build filters for all child business units that are selected and aren't leaf nodes
  const selectedChildBUs = childSegmentsAndBUs.filter(
    ({ type, id: childId }) => type === 'businessunit' && selectedBusinessUnits.has(childId)
  ) as BusinessUnit[];

  return [
    filter,
    ..._flatMap(selectedChildBUs, (childBU) =>
      buildFiltersForBU(savedSearchesById, selectedBusinessUnits, childBU, level + 1)
    )
  ];
};

export const buildBusinessUnitFilters = (
  _app: ReduxStore['app'],
  allBusinessUnits: BusinessUnit[],
  savedSearchesById: Map<string, SavedSearch>,
  selectedBusinessUnits: Set<string>
) => {
  // Business units with a `isTopLevel` flag set to `true` should be rendered as top-level filters on the application.
  // In addition, for each selected business unit, a filter for its children should be rendered beneath it.  This should
  // happen recursively, rendering business unit filters all along the tree.
  const topLevelBUs = allBusinessUnits.filter(_prop('isTopLevel'));

  return _flatMap(topLevelBUs, (businessUnit) =>
    buildFiltersForBU(savedSearchesById, selectedBusinessUnits, businessUnit, 0)
  );
};

/**
 * Constructs a `Conditions` object for a given `BusinessUnit`.  This is used internally by business unit filters to
 * create the filter conditions that are added to API requests when a business unit filter is selected.
 *
 * @param savedSearchesById The mapping of id to saved search found in Redux's `store.segments.savedSearchesById`
 * @param getIsSelected A function that, given a business unit ID, returns whether or not that business unit is selected
 *        as a business unit filter (the user has checked its box).
 * @param businessUnit The BU for which filters are being built
 * @param isTopLevel Whether or not this business unit should be treated as a top-level business unit.  This flag has
 *        the behavior that, if it is set to true and no child BUs or segments are selected, no conditions will be
 *        generated for this BU since unchecked top-level BUs are simply ignored.
 */

// This function is responsible for building the nestedQueryConditions under the conditions property of the Advanced Search payload.

export const buildConditionsForBU = (
  savedSearchesById: NonNullable<ReduxStore['segments']['savedSearchesById']>,
  getIsSelected: (buId: string) => boolean,
  { children, id }: BusinessUnit,
  isTopLevel: boolean,
  seenIds: Set<string> = new Set()
): Conditions => {
  const selectedChildIds = children.filter(getIsSelected);
  // If none of our children are selected, that means we should AND them all (default all selected).
  // The exception to this is if the condition is top level.  If we're top-level, we just don't build any conditions
  // and ignore the entire tree as it's inactive.
  const realSelectedChildIds = _isEmpty(selectedChildIds) && !isTopLevel ? children : selectedChildIds;
  const childConditions = realSelectedChildIds
    .map((childId) => {
      if (childId === id) {
        console.error('Invalid BU created - it lists itself as its own child.');
        return null;
      }

      const child = savedSearchesById.get(childId);
      if (!child) {
        return null;
      }

      if (!seenIds.has(childId)) {
        seenIds.add(childId);
      } else {
        return child.conditions;
      }

      // If this child is itself a BU, we need to recursively build conditions for it.  If it's just a segment, we can
      // pass through its conditions as they are.
      // Check against our collection of unique IDs to prevent infinite loop in this recursive function.
      // If we've already built conditions for the child, we do not recurse.
      if (child.type === 'businessunit') {
        return buildConditionsForBU(savedSearchesById, getIsSelected, child, false, seenIds);
      } else {
        return child.conditions;
      }
    })
    .map(Option.of)
    .filter((conditionsOpt) => conditionsOpt.map((conditions) => !conditionsAreEmpty(conditions)))
    .map((opt) => opt.orNull());

  return {
    // We OR across all child conditions
    condition: 'should',
    nestedFilterConditions: filterNils(childConditions)
  };
};

/**
 * Builds `Conditions` for a given `BusinessUnit` as if it was an entity rather than a filter.  The main difference
 * is that the returned `Conditions` object will have a `condition` of `'must'` rather than `'should'`.  This allows
 * for other conditions such time period range filters and other filters to be properly AND'd with the BU's conditions.
 *
 * @param savedSearchesById The mapping of id to saved search found in Redux's `store.segments.savedSearchesById`
 * @param businessUnit The BU for which filters are being built
 */
export const buildBUEntityConditions = (
  savedSearchesById: NonNullable<ReduxStore['segments']['savedSearchesById']>,
  businessUnit: BusinessUnit
): Conditions => ({
  condition: 'must',
  nestedFilterConditions: [buildConditionsForBU(savedSearchesById, () => true, businessUnit, false)]
});

/**
 * Constructs a master `Condition` representing the full set of currently selected business unit filters.
 *
 * A unique conditions is built for each top level BU that has at least one child selected.  The created conditions
 * consists of the conditions for all selected children AND'd together.  Childrens' conditions are recursively built
 * in this same manner, constructing a tree of nested conditions that represents the current BU filter selection.
 */
export const buildBusinessUnitConditions = (
  allBusinessUnits: BusinessUnit[],
  savedSearchesById: Map<string, SavedSearch | BusinessUnit>,
  retailer?: ReduxStore['retailer'],
  selectedBusinessUnits?: Set<string>
): Conditions => {
  if (!retailer || _isNil(retailer.id)) {
    return { rangeFilters: [], termFilters: [], nestedFilterConditions: [] };
  }
  const topLevelBUs = allBusinessUnits.filter(_prop('isTopLevel'));
  const getIsSelected = (id: string) => (selectedBusinessUnits ? selectedBusinessUnits.has(id) : true);

  const topLevelConditions = topLevelBUs
    .map((bu) => buildConditionsForBU(savedSearchesById, getIsSelected, bu, true))
    .filter((conditions) => !conditionsAreEmpty(conditions));

  return {
    // We AND across all top-level BUs
    condition: 'must',
    // The conditions that are present on segments are set once when saved searches are fetched.  If these conditions
    // include `retailerId` and the retailer is changed, it will cause any queries made using these conditions to either
    // show data for the wrong retailer or show no data at all if a different `retailerId` conditions is added somewhere
    // else (more likely).
    //
    // Here, we explicity convert all `retailerId` filters to filter to the *current* retailer instead of whatever retailer
    // they say they should point to.
    nestedFilterConditions: topLevelConditions.map((conditions) =>
      updateConditionsToCurrentRetailer(conditions, retailer)
    )
  };
};
