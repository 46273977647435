import React from 'react';
import { StacklineColor, Text, SlStatusChip } from '@stackline/ui';
import LegendIndicator, { LegendIndicatorProps } from './LegendIndicator';
import {
  Direction,
  getColorForMetricChangeValue,
  getIconForMetricChange
} from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

export interface LegendWithMetricProps {
  /**
   * Main metric to be displayed
   */
  metric?: string;
  /**
   * Legend color
   */
  color: StacklineColor;
  /**
   * Display name for the legend
   */
  displayName: string;
  /**
   * Optional metric for the change.
   */
  change?: string;
  /**
   * Indicates if the change is increasing or decreasing
   */
  direction?: Direction;

  dashed?: boolean;

  legendIndicatorProps?: Partial<LegendIndicatorProps>;
}

/**
 * Displays a metric with a legend underneath, and an optional
 * status pill indicating the change from the previous period
 */
const LegendWithMetric = ({
  metric,
  change,
  direction,
  color,
  displayName,
  dashed,
  legendIndicatorProps
}: LegendWithMetricProps) => {
  return (
    <Flex gap="sm" flexDirection="column">
      {(change || metric) && (
        <Flex gap="md">
          {metric && <Text variant="body0">{metric}</Text>}
          {change && (
            <SlStatusChip color={getColorForMetricChangeValue(null, direction)} variant="xlarge">
              <Flex alignItems="center" gap="xs">
                <div
                  style={{
                    position: 'relative',
                    top: '1px'
                  }}
                >
                  {getIconForMetricChange(null, direction)}
                </div>
                {change}
              </Flex>
            </SlStatusChip>
          )}
        </Flex>
      )}
      <LegendIndicator color={color} displayName={displayName} dashed={dashed} {...legendIndicatorProps} />
    </Flex>
  );
};

export default LegendWithMetric;
