import Box from '@mui/system/Box';
import { Text } from '@stackline/ui';
import React, { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthSuccessAnimation } from 'src/components/Animations/AuthSuccessAnimation/AuthSuccessAnimation';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import SignInInputField from 'src/components/SignIn/SignInInputField';
import { AnimatedEllipsis } from 'src/components/common/Loading/AnimatedEllipsis';
import i18n from '../../i18n_en';
import AuthButton from '../SignIn/AuthButton';
import AuthContainer from '../SignIn/AuthContainer';
import AuthTitle from '../SignIn/AuthTitle';
import LogoContainer from '../SignIn/LogoContainer';

interface NewPasswordProps {
  username: string;
  createNewPassword: (requestBody: any) => Promise<any>;
  setNotification: (message: string) => void;
  app: any;
  isPasswordExpiredLayout?: boolean;
}

const NewPassword: React.FC<NewPasswordProps> = ({
  username,
  createNewPassword,
  setNotification,
  app,
  isPasswordExpiredLayout = false
}) => {
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [oneTimeCode, setOneTimeCode] = useState<string>('');
  const [showPasswordResetSuccessful, setShowPasswordResetSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const passwordsMatch = password.length > 0 && password === passwordConfirmation;

  const authTitle = isPasswordExpiredLayout ? 'Password Expired' : 'Reset your password';

  const handleErrorCases = (error: string) => {
    const { password_invalid, password_invalid_secondPassword, password_reset_codeExpired } = i18n;

    switch (error) {
      case 'expired':
        return password_reset_codeExpired;
      case 'invalidPassword':
        return password_invalid;
      case 'invalidConfirmPassword':
        return password_invalid_secondPassword;
      default:
        return error;
    }
  };

  const handleOTPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOneTimeCode(value);
  };

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!oneTimeCode) {
      setNotification('Invalid verification code. Please try again. ');
      return;
    }
    if (!passwordsMatch) {
      setNotification("We were unable to reset your password because the passwords don't match. Please try again.");
      return;
    } else if (password.length < 8) {
      setNotification('Password must be at least 8 characters.');
      return;
    }

    const requestBody = {
      username,
      password,
      confirmPassword: passwordConfirmation,
      resetCode: window.decodeURIComponent(oneTimeCode)
    };

    try {
      setLoading(true);

      const response = await createNewPassword(requestBody);

      if (response.data === 'good') {
        setShowPasswordResetSuccessful(true);
      } else {
        setNotification(handleErrorCases(response.data));
      }
    } finally {
      setLoading(false);
    }
  };

  if (showPasswordResetSuccessful) {
    return (
      <form onSubmit={handleFormSubmit} autoComplete="off">
        <AuthContainer>
          <LogoContainer title={app.displayName} />
          <Flex justifyContent="center" marginTop="32px">
            <AuthSuccessAnimation />
          </Flex>
          <AuthTitle marginTop="24px" marginBottom="8px">
            Success!
          </AuthTitle>
          <Text variant="subtitle1" textAlign="center">
            {i18n.password_reset_success}
          </Text>
          <Link to="/sign_in">
            <AuthButton
              type="submit"
              style={{
                marginTop: '32px'
              }}
            >
              Login
            </AuthButton>
          </Link>
        </AuthContainer>
      </form>
    );
  }

  return (
    <form onSubmit={handleFormSubmit} autoComplete="off">
      <AuthContainer>
        <LogoContainer title={app.displayName} />
        <AuthTitle marginTop="16px">{authTitle}</AuthTitle>
        {isPasswordExpiredLayout ? (
          <Flex marginTop="16px">
            <Text variant="subtitle1" textAlign="center">
              {`We have emailed a verification code to ${username}. Enter the code below to reset your
                password.`}
            </Text>
          </Flex>
        ) : null}
        <Box display="flex" flexDirection="column" gap="24px" mb="40px" mt="47.7px">
          <SignInInputField
            inputProps={{
              'aria-readonly': true,
              'data-lpignore': true
            }}
            id="type-search" // so last pass does not fill
            autoComplete="false"
            disabled
            label="Email"
            value={username}
          />
          <SignInInputField
            label="Verification Code"
            value={oneTimeCode}
            id="type-search"
            onChange={handleOTPChange}
            autoComplete="off"
          />
          <SignInInputField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            type="password"
            autoComplete="new-password"
          />
          <SignInInputField
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            label="Confirm Password"
            type="password"
            autoComplete="new-password"
          />
        </Box>

        <AuthButton disabled={loading} type="submit">
          {loading ? (
            <Box display="flex" flexDirection="row" maxHeight="100%" alignItems="center" justifyContent="center">
              Resetting
              <AnimatedEllipsis width={40} height={40} style={{ margin: 0, transform: 'translate(-8px,5px)' }} />
            </Box>
          ) : (
            'Reset'
          )}
        </AuthButton>
      </AuthContainer>
    </form>
  );
};

export default NewPassword;
