import dateFormat from 'dateformat';
import MomentTimezone from 'moment-timezone';
import { Moment } from 'moment';

const DAY_ID_FORMAT = 'YYYYMMDD';

export const moment = MomentTimezone;

/**
 *
 * @param {Date} date date to format as string
 * @param {string} mask check here https://github.com/felixge/node-dateformat#mask-options
 * @param {boolean} utc converts local time to UTC
 * @param {boolean} gmt converts timezone to GMT (keeping the time same i.e discard timezone)
 */
export function dateformat(date?: Date | string | number, mask?: string, utc?: boolean, gmt?: boolean) {
  return dateFormat(date, mask, utc, gmt);
}

function getFirstDateOfWeekISO8601(weekId: number | string): string {
  weekId = typeof weekId === 'string' ? parseInt(weekId, 10) : weekId;
  const year = weekId / 100;
  const weekIndex = (weekId % 100) - 1; // subtract 1 to have it start from 0
  //  1. get the first thursday of the year
  // Note: The ISO 8601 definition for week 01 is the week with the first Thursday
  // of the Gregorian year (i.e. of January) in it.
  const firstThursday = new Date(Date.UTC(year, 0));
  if (firstThursday.getUTCDay() !== 4) {
    firstThursday.setUTCMonth(0, 1 + ((4 - firstThursday.getUTCDay() + 7) % 7));
  }

  // try returning day id instead
  const date = MomentTimezone.utc(firstThursday)
    .tz('America/Los_Angeles') // all times come from the API in PST
    .add(7 * weekIndex, 'd')
    .add(-3, 'd');
  return date.format(DAY_ID_FORMAT);
}

export function getStartAndEndOfWeek(
  weekId: number | string | undefined,
  timeZoneLocalized: boolean = true
): { startDate: string | null; endDate: string | null } {
  // subtracting 1 gives start of Week Sunday (included in week) & adding 6 gives end of week Sunday (not part of week) as moment's week is based off of Monday.
  const startDate = getFirstDateOfWeekISO8601(weekId as number);
  const endDate = MomentTimezone.utc(startDate).add(6, 'd').format(DAY_ID_FORMAT);

  if (!timeZoneLocalized) {
    return { startDate, endDate };
  }
  // const timeZoneOffsetInMinutes = new Date().getTimezoneOffset(); // this cause some week have only 6 day.
  const timeZoneOffsetInMinutes = 0;
  const startDateWithOffset = moment(startDate).add(timeZoneOffsetInMinutes, 'm').format(DAY_ID_FORMAT);
  const endDateWithOffset = moment(endDate).add(timeZoneOffsetInMinutes, 'm').format(DAY_ID_FORMAT);
  return { startDate: startDateWithOffset, endDate: endDateWithOffset };
}

export const convertDayToString = (dateToConvert: Date): string => {
  const strFormat = 'YYYY-MM-DD';
  return moment(dateToConvert).format(strFormat);
};

/*
 * Gets the week id for date `d`
 */
export function getWeekId(date?: Date | string | number | any): number {
  let instance;
  if (typeof date === 'string' && date.length) {
    instance = new Date(date);
  } else if (typeof date === 'number') {
    instance = new Date(date);
  } else if (date instanceof Date) {
    instance = date;
  } else if (date._isAMomentObject) {
    instance = new Date(date.valueOf());
  } else {
    instance = new Date();
  }
  // Create a copy of this date object
  const target = new Date(instance.valueOf());

  // ISO week date weeks start on monday
  // so correct the day number
  // ** commented out as we have Sunday as Start of week day
  // const dayNr = (instance.getUTCDay() + 6) % 7; //
  const dayNr = instance.getUTCDay(); // Assumes Sunday as Start Of week

  // ISO 8601 states that week 1 is the week
  // with the first thursday of that year.
  // Set the target date to the thursday in the target week
  target.setUTCDate(target.getUTCDate() - dayNr + 3);

  // Store the millisecond value of the target date
  const firstThursday = target.valueOf();

  // Set the target to the first thursday of the year
  // First set the target to january first
  target.setUTCMonth(0, 1);
  // Not a thursday? Correct the date to the next thursday
  if (target.getUTCDay() !== 4) {
    target.setUTCMonth(0, 1 + ((4 - target.getUTCDay() + 7) % 7));
  }

  // The weeknumber is the number of weeks between the
  // first thursday of the year and the thursday in the target week
  const weekNumber = 1 + Math.ceil((firstThursday - target.valueOf()) / 604800000);
  return target.getUTCFullYear() * 100 + weekNumber;
}

export function getPreviousWeekId(weekId = getWeekId(new Date()), weeksToSubtract = 1): number {
  const startDate = getFirstDateOfWeekISO8601(weekId);
  return getWeekId(
    MomentTimezone.utc(startDate)
      .add(-7 * weeksToSubtract, 'd')
      .toDate()
  );
}

export function getNextWeekId(weekId = getWeekId(new Date()), weeksToAdd = 1): number {
  const startDate = getFirstDateOfWeekISO8601(weekId);
  return getWeekId(
    MomentTimezone.utc(startDate)
      .add(7 * weeksToAdd, 'd')
      .toDate()
  );
}

export function isValidWeekId(weekId: number | string | undefined): boolean {
  const { startDate, endDate } = getStartAndEndOfWeek(weekId);
  return Boolean(startDate && endDate);
}

export function getPreviousYearWeekId(weekId: number): number {
  const year = Math.trunc(weekId / 100);
  const week = weekId % 100;
  const prevYearWeek = (year - 1) * 100 + week;
  // TODO: If weekId is invalid in case of leap year, we should add or subtract one week
  return prevYearWeek;
}

export function getNextYearWeekId(weekId: number): number {
  const year = Math.trunc(weekId / 100);
  const week = weekId % 100;
  const nextYearWeek = (year + 1) * 100 + week;
  return nextYearWeek;
}

export function getYearForWeekId(weekId: number): number {
  const { startDate } = getStartAndEndOfWeek(weekId);
  return moment(startDate, 'YYYYMMDD').year();
  // return Math.trunc(weekId / 100);
}

/**
 * Only returns the year part of a week ID, regardless
 * of the year of the start date of the ISO week.
 * For example, 202401 -> 2024
 */
export function yearPartOfWeekId(weekId: number) {
  return Math.floor(weekId / 100);
}

export const lang = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getLastDayOfMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate();
};

export const yearMonthToMoment = (year: number, month: number) => {
  return moment(new Date(year, month - 1, getLastDayOfMonth(year, month)));
};

export const extractMonthYear = (date: Moment) => {
  return {
    year: date.year(),
    month: date.month() + 1
  };
};

/**
 * Converts formatted dates from the dispute table
 * @param date Takes in a string date formatted as M/D/YYYY
 */
export const invoiceDateToISOFormattedDate = (date: string) => {
  const dateObj = new Date(Date.parse(date));
  const isoDateStr = dateObj.toISOString();
  const formattedDate = `${isoDateStr.slice(0, -14)}`;
  return formattedDate;
};

/** Convert ISO formatted date string */
export const formatISODate = (date: string, format: string) => {
  const momentDate = moment(date);
  const formattedDate = momentDate.format(format);
  return formattedDate;
};

export const formatDateForDisputeTable = (stringISODate: string) => {
  if (!stringISODate || stringISODate === '') {
    return '';
  }

  const milliseconds = Date.parse(stringISODate);
  const date = new Date(milliseconds);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

/**
 *
 * @param dateString Accepts a string formatted as YYYY-MM-DD such as 2023-01-10
 * @returns A formatted date string with a day suffix for display such as "Jan 10th, 2023"
 */
export const formatDateWithSuffix = (dateString) => {
  if (!dateString) {
    return '';
  }

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // eslint-disable-next-line
  const date = new Date(dateString.replace(/-/g, '/'));
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  // Append the appropriate suffix to the day
  let daySuffix;
  if (day % 10 === 1 && day !== 11) {
    daySuffix = 'st';
  } else if (day % 10 === 2 && day !== 12) {
    daySuffix = 'nd';
  } else if (day % 10 === 3 && day !== 13) {
    daySuffix = 'rd';
  } else {
    daySuffix = 'th';
  }

  return `${months[monthIndex]} ${day}${daySuffix}, ${year}`;
};

/**
 * Converts a week ID to a Unix timestamp in milliseconds.
 * This will be the X value for trend charts
 */
export function weekIdToTimestamp(weekId: number) {
  // Split the input into year and week
  const year = Math.floor(weekId / 100);
  const week = weekId % 100;

  // Start from the first day of the year, add the number of weeks,
  // set the day to Saturday, and set the time to midnight.
  const date = moment().year(year).week(week).day(6).startOf('day');

  // Return the timestamp
  return date.valueOf();
}

/**
 * Converts a Unix timestamp to a week ID.
 * Inverse of weekIdToTimestamp
 */
export function timestampToWeekId(timestamp: number) {
  const date = moment(timestamp);

  // Calculate the year and week based on the date
  const year = date.year();
  const week = date.week();

  // Combine year and week to form the week ID
  const weekId = year * 100 + week;

  return weekId;
}

export function getLastWeekIdOfYear(year: number = moment().year()): number {
  const startOfYear = moment().year(year).startOf('year');
  const totalWeeks = startOfYear.isoWeeksInYear();
  const date = startOfYear.week(totalWeeks).day(6).startOf('day');
  return getWeekId(date.toDate());
}

export function getFirstWeekIdOfYear(year: number = moment().year()): number {
  return Number(`${year}01`);
}

export const formatDateStringWithSuffix = (dateString: string, formatStr?: string) => {
  const parsedDate = moment(dateString, 'YYYYMMDD');
  const month = parsedDate.format('MMM');
  const day = parsedDate.format('Do');

  if (formatStr) {
    return parsedDate.format(formatStr);
  }

  return `${month} ${day}, ${parsedDate.format('YYYY')}`;
};

interface FormatDateForAdjustmentDisplayParams {
  /**
   * A start week ID such as 202325 (week 25 of the year 2023)
   */
  startWeekId: string | number;
  /**
   * An end week ID such as 202326 (week 26 of the year 2023)
   */
  endWeekId: string | number;
  /**
   * If provided, will return the formatted date string using the provided format string.
   */
  momentFormatStr?: string;
}
/**
 * Accepts 2 week IDs, and returns the start date of the start week and the end date of the end week
 * as a formatted string: i.e Jun 4th, 2023 to Jun 10th, 2023.
 */
export const formatWeekIdsForAdjustmentDisplay = ({
  startWeekId,
  endWeekId,
  momentFormatStr
}: FormatDateForAdjustmentDisplayParams) => {
  const { startDate } = getStartAndEndOfWeek(startWeekId); // Returns a date string formatted as: YYYYMMDD
  const { endDate } = getStartAndEndOfWeek(endWeekId);

  const formattedStartDate = formatDateStringWithSuffix(startDate, momentFormatStr);
  const formattedEndDate = formatDateStringWithSuffix(endDate, momentFormatStr);

  return { formattedStartDate, formattedEndDate };
};

/**
 *
 * @param date A date object or an ISO-formatted date string such as 2023-08-23T14:30:00.000Z
 * @returns a string in YYYYMMDD format
 */
export const stripISODate = (date: Date | string) => {
  return moment(date).format('YYYYMMDD');
};

/**
 *
 * @param startWeekId
 * @param endWeekId
 * @returns the number of weeks between each start and end week
 */
export const calculateWeeksBetweenWeekIds = (startWeekId: string | number, endWeekId: string | number) => {
  const startDate = moment(startWeekId, 'YYYYWW').startOf('isoWeek');
  const endDate = moment(endWeekId, 'YYYYWW').endOf('isoWeek');

  const diffInWeeks = endDate.diff(startDate, 'weeks') + 1;

  return diffInWeeks;
};

/**
 * Get an array of week IDs between a start and end week ID, inclusive
 */
export const getWeekIdRange = (startWeekId: number, endWeekId: number) => {
  if (startWeekId > endWeekId) {
    return [];
  }

  const weekIdRange: number[] = [];
  let curWeekId = startWeekId;
  while (curWeekId <= endWeekId) {
    weekIdRange.push(curWeekId);
    curWeekId = getNextWeekId(curWeekId);
  }
  return weekIdRange;
};

/**
 *  Get last Saturday, (this can be extended to different day, but couldn't think of other day)
 */
export const formattedLastSaturday = (formatString = 'MM/DD/YYYY') => moment().day(-1).format(formatString);

/**
 * Gets the date of Thanksgiving for a given year
 */
const findThanksGivingDate = (year = moment().year()) => {
  // Start at the 1st of November
  const start = moment().year(year).month('November').startOf('month');

  // Calculate offset from that day to the next occurrence of weekday required.
  // example 1: November 1st, 2023 is a Wednesday, so 11 - 3 = 8 days away from the next Thursday.
  // example 2: November 1st, 2024 is a Friday, so 11 - 5 = 6 days
  const offsetToNextThursday = 11 - start.day();

  // example 1: November 1st, 2023 is a Wednesday, so 8 % 7 = 1.
  // example 2: November 1st, 2024 is a Friday, so 6 % 7 = 6.
  const novemberFirstVersusThursdayMod = offsetToNextThursday % 7;

  // example 1 (2023): November 22nd is the earliest date Thanksgiving can be, 22 + 1 = 23 or the 23rd of November.
  // example 2 (2024): November 22nd is the earliest date Thanksgiving can be, 22 + 6 = 28 or the 28th of November.
  const thanksGivingDay = 22 + novemberFirstVersusThursdayMod;

  // Set the date to Thanksgiving
  const thanksgiving = start.clone().date(thanksGivingDay);

  return thanksgiving;
};

/**
 * Gets the week ID for Black Friday
 */
export const findBlackFridayWeekId = (year = moment().year()) => {
  // Get the Thanksgiving date
  const thanksgiving = findThanksGivingDate(year);
  // Black Friday is the day after Thanksgiving
  const blackFriday = thanksgiving.clone().add(1, 'days');

  // Get the week ID
  const weekId = blackFriday.format('YYYYWW');
  return weekId;
};

/**
 * Gets the week ID for Cyber Monday
 */
export const findCyberMondayWeekId = (year = moment().year()) => {
  // Get the Thanksgiving date
  const thanksgiving = findThanksGivingDate(year);
  // Cyber Monday is the Monday after Thanksgiving
  const cyberMonday = thanksgiving.clone().add(4, 'days');

  // Get the week ID
  const weekId = cyberMonday.format('YYYYWW');
  return weekId;
};
