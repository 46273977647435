import { UseMutationOptions, useMutation } from 'react-query';
import { UpdateUserUploadBulkAdjustmentStatusPayload } from '../serverProxy/types';
import useServerProxy from './useServerProxy';

/**
 * Tells the backend that the bulk adjustment file has been uploaded
 */
export default function useUpdateUserUploadBulkAdjustmentStatus(
  options: UseMutationOptions<unknown, unknown, UpdateUserUploadBulkAdjustmentStatusPayload> = {}
) {
  const { updateUserUploadBulkAdjustmentStatus } = useServerProxy();
  return useMutation(updateUserUploadBulkAdjustmentStatus, options);
}
