import QueryString from 'qs';
import { Dispatch } from 'react';
import { fetchScheduledActionGroups } from 'src/store/modules/adManager/adScheduledActionGroups/operations';
import _get from 'lodash/get';
import { TermFilter } from 'sl-api-connector/types';
import { AD_PLATFORM_TYPE } from 'sl-ad-campaign-manager-data-model';

export async function handleCurrentScheduledAction(
  queryParams: QueryString.ParsedQs,
  dispatch: Dispatch<any>,
  retailer: { platformType: AD_PLATFORM_TYPE }
) {
  if (!queryParams.currentScheduledAction) {
    return { defaultSelectAll: false, scheduledActionTermFilters: [] };
  }

  let scheduledActionTermFilters: TermFilter[] = [];
  const res = await dispatch(
    fetchScheduledActionGroups(false, retailer.platformType, queryParams.currentScheduledAction)
  );
  const scheduledActionGroups = _get(res, ['scheduledActionGroups'], []);
  const foundActionGroups = scheduledActionGroups.find((action) => action.id === queryParams.currentScheduledAction);
  const saEntities = _get(
    foundActionGroups,
    ['scheduledActions', 0, 'scheduledAction', 'extendedAttributes', 'adUpdateRequest', 'extendedAttributes'],
    {}
  );
  const { adTargets, adCampaignProducts, adCampaigns, adCampaignsBudgetSetting } = saEntities;
  if (adTargets && adTargets.length > 0) {
    const targetIds = adTargets.map((item) => item.targetId);
    scheduledActionTermFilters = [
      {
        fieldName: 'targetId',
        condition: 'should',
        values: targetIds
      }
    ];
  }
  if (adCampaignProducts && adCampaignProducts.length > 0) {
    const pruductSkus = adCampaignProducts.map((item) => item.stacklineSku);
    scheduledActionTermFilters = [
      {
        fieldName: 'stacklineSku',
        condition: 'should',
        values: pruductSkus
      }
    ];
  }
  if (adCampaignsBudgetSetting && adCampaignsBudgetSetting.length > 0) {
    const campaignIds = adCampaignsBudgetSetting.map((item) => item.campaignId);
    scheduledActionTermFilters = [
      {
        fieldName: 'campaignId',
        condition: 'should',
        values: campaignIds
      }
    ];
  }
  if (adCampaigns && adCampaigns.length > 0) {
    const campaignIds = adCampaigns.map((item) => item.campaignId);
    scheduledActionTermFilters = [
      {
        fieldName: 'campaignId',
        condition: 'should',
        values: campaignIds
      }
    ];
  }
  return { defaultSelectAll: true, scheduledActionTermFilters };
}
