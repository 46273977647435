import { Widget } from 'src/types/application/widgetTypes';
import SearchResultsGridV3 from './SearchResultsGridV3';
import React from 'react';
import useSrgData from './hooks/useSrgData';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import { Location } from 'history';
import EntityColumn from 'src/components/AdManager/Search/CustomColumns/EntityColumn';
import CampaignPortfolioColumn from 'src/components/AdManager/Search/CustomColumns/CampaignPortfolioColumn';
import useFetchAdGroupsSalesData from './hooks/useFetchAdGroupsSalesData';
import { shouldFetchAllSRGRows, shouldShowCriteo } from 'src/utils/app';
import { getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';

interface AdGroupsGridProps {
  widget: Widget;
  location: Location;
  queryParams: any;
}
/**
 * Display a list of ad groups.
 */
const AdGroupsGrid: React.FC<AdGroupsGridProps> = ({ widget, location, queryParams }) => {
  const parentPlatform = getParentPlatform();
  const isCriteo = shouldShowCriteo() && parentPlatform === PARENT_PLATFORMS.CRITEO;

  let importantOverrides = [];

  if (isCriteo) {
    importantOverrides = [
      {
        action: 'add',
        path: ['conditions', 'termFilters'],
        newObj: {
          fieldName: 'parentPlatform',
          condition: 'must',
          values: [parentPlatform]
        }
      },
      {
        action: 'add',
        path: ['aggregations', '[0]', 'conditions', 'termFilters'],
        newObj: {
          fieldName: 'parentPlatform',
          condition: 'must',
          values: [parentPlatform]
        }
      }
    ];
    // for all retailers remove rid
    if (queryParams.rid === '0') {
      importantOverrides = [
        ...importantOverrides,
        {
          action: 'remove',
          path: ['aggregations', '[0]', 'conditions', 'termFilters'],
          conditionKey: 'fieldName',
          conditionValue: 'retailerId'
        },
        {
          action: 'remove',
          path: ['conditions', 'termFilters'],
          conditionKey: 'fieldName',
          conditionValue: 'retailerId'
        },
        {
          action: 'update',
          path: ['retailerId'],
          newObj: 999999
        }
      ];
    }
  }

  const fetchAdGroups = useFetchAdGroupsSalesData(importantOverrides);
  const srgData = useSrgData({
    widget,
    location,
    fetchEntityData: fetchAdGroups,
    metadataRowOptions: shouldFetchAllSRGRows(widget.data.entityType, widget.data.groupByFieldName)
      ? {
          getEntityId: (row) => row.adGroupId,
          type: 'adGroup',
          name: 'adGroupName'
        }
      : undefined,
    importantOverrides
  });

  const disabled = isCriteo;

  return (
    <SearchResultsGridV3
      editDisabled={disabled}
      columnDefs={[
        {
          headerName: isCriteo ? 'Line Item' : 'Ad Group',
          field: 'adGroupId',
          width: undefined,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          minWidth: 300,
          maxWidth: 800,
          cellRendererFramework: EntityColumn,
          headerClass: 'align-left',
          pinnedRowCellRenderer: 'simpleColumn',
          pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
          pinned: 'left'
        },
        {
          headerName: 'Campaign',
          field: 'campaignId',
          width: undefined,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          minWidth: 120,
          maxWidth: 120,
          cellRendererFramework: CampaignPortfolioColumn,
          headerClass: 'align-left',
          pinnedRowCellRenderer: 'simpleColumn',
          pinnedRowCellRendererParams: { text: '', style: { fontWeight: 'bold' } },
          pinned: 'left'
        }
      ]}
      getRowNodeId={(row) => row.id}
      widget={widget}
      {...srgData}
    />
  );
};

export default withRouter(withBus('eventBus')(AdGroupsGrid));
