import { TRAFFIC_FIELDS } from 'src/utils/entityDefinitions/fields/trafficFieldDefinitions';
import { FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';
import _cloneDeep from 'lodash/cloneDeep';

const TRAFFIC_FIELDS_FOR_BEACON: {
  [key: string]: FieldDefinition;
} = _cloneDeep(TRAFFIC_FIELDS);

TRAFFIC_FIELDS_FOR_BEACON.adRetailSales.canBeExported = false;
TRAFFIC_FIELDS_FOR_BEACON.adUnitsSold.canBeExported = false;
TRAFFIC_FIELDS_FOR_BEACON.adSpend.canBeExported = false;
TRAFFIC_FIELDS_FOR_BEACON.adClicks.canBeExported = false;

export { TRAFFIC_FIELDS_FOR_BEACON };
