import React from 'react';
import _get from 'lodash/get';
import numeral from 'numeral';
import colors from 'src/utils/colors';

import './CampaignColumn.scss';

const getPacingNumber = (number: number | null) => {
  if (!number && number !== 0) {
    return '--';
  }
  const color = number > 1.2 || number < 0.8 ? colors.red : colors.green;
  return (
    <strong style={{ color }}>
      <span>&nbsp;</span>
      {`${numeral(number).format('%0')}`}
      <span>&nbsp;</span>
    </strong>
  );
};

const PacingColumn = ({ data, value }: { data: any; value: any; adCampaigns: any[] }) => {
  if (!value) {
    return null;
  }
  const mainEntity = data.entity;
  const budgetAmount = _get(mainEntity, ['extendedAttributes', 'liveBudgetSetting', 'amount'], null);
  const { value: mtdSpend } = data.mtdSpend;
  const budgetAmountDisplay = budgetAmount === null ? '--' : numeral(budgetAmount).format('$0.00a');

  const percent = budgetAmount !== 0 ? mtdSpend / budgetAmount : 0;

  return (
    <div className="budget_column" style={{ whiteSpace: 'pre-wrap' }}>
      {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
      {`On pace to spend`}
      {getPacingNumber(percent)}
      {`of its ${budgetAmountDisplay} monthly budget.`}
    </div>
  );
};

export default PacingColumn;
