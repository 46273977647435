import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import queryString from 'qs';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import _pick from 'lodash/pick';
import colors from 'src/utils/colors';
import fontStyle from 'src/utils/fontStyle';
import { computeComparisonTimePeriod } from 'src/utils/dateformatting';
import * as appOperations from 'src/store/modules/app/operations';
import updateComparisonTimePeriod from 'src/store/modules/comparison-time-period/operations';
import { ChevronIcon } from '../SvgIcons';
import ReduxStore from 'src/types/store/reduxStore';
import { QueryResponse } from 'src/types/application/types';
import { calculateNewQueryParams } from 'src/store/modules/app/selectors';
import { setQueryParams } from 'src/store/modules/app/operations';
import { store } from 'src/main';

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['allWeekIdsByRetailerId', 'app', 'comparisonTimePeriod', 'retailer', 'mainTimePeriod']);

type ComparisonTimePeriodDropDownProps = RouteComponentProps &
  ReturnType<typeof mapStateToProps> & {
    labelStyle?: { color: string; fontWeight: number };
    updateComparisonAppPeriod: any;
    updateQueryParams: any;
  };

class ComparisonTimePeriodDropDown extends Component<ComparisonTimePeriodDropDownProps> {
  public static readonly defaultProps = {
    labelStyle: { color: colors.darkBlue, fontWeight: fontStyle.regularWeight }
  };

  public componentDidMount() {
    const { app } = this.props;
    const queryParams = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const periodParam = queryParams.pid ? queryParams.pid.toString() : app.comparisonDefault;
    this.handlePeriodChange(periodParam);
  }

  public componentDidUpdate(prevProps: ComparisonTimePeriodDropDownProps) {
    if (prevProps.mainTimePeriod.id !== this.props.mainTimePeriod.id) {
      const { mainTimePeriod, comparisonTimePeriod } = this.props;
      if (
        mainTimePeriod.id === '1w' &&
        String(mainTimePeriod.endWeek).endsWith('53') &&
        comparisonTimePeriod.id === 'prior-year'
      ) {
        this.handlePeriodChange('prior-period');
      }
    }
  }

  private handlePeriodChange = (periodParam: string) => {
    const {
      app,
      allWeekIdsByRetailerId,
      comparisonTimePeriod,
      location,
      mainTimePeriod,
      retailer,
      updateComparisonAppPeriod
    } = this.props;
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const updateTimePeriod = computeComparisonTimePeriod(
      allWeekIdsByRetailerId[+retailer.id],
      mainTimePeriod,
      periodParam
    );
    const {
      endWeek: comparisonEndWeek,
      startWeek: comparisonStartWeek,
      startDayId,
      endDayId,
      startWeekStartDate,
      endWeekEndDate
    } = updateTimePeriod;
    updateComparisonAppPeriod(
      comparisonTimePeriod.availableComparisonTimePeriods
        .map((x: { id: string; shortDisplayName: string }) => x.id)
        .indexOf(periodParam),
      comparisonStartWeek,
      comparisonEndWeek,
      startDayId,
      endDayId,
      startWeekStartDate,
      endWeekEndDate
    );
    const newQueryParams = calculateNewQueryParams(app, retailer, mainTimePeriod, updateTimePeriod, queryParams);
    const response: QueryResponse = store.dispatch(setQueryParams(newQueryParams));

    const { dropDownSelectionParams, nonPersistentParams, searchParams } = response.params;
    this.props.history.push(`${location.pathname}${searchParams}${dropDownSelectionParams}${nonPersistentParams}`);
  };

  public render() {
    const { app, comparisonTimePeriod, mainTimePeriod } = this.props;
    const isOmniAndOnlyOneOption =
      app.name === 'omni' && comparisonTimePeriod.availableComparisonTimePeriods.length === 1;
    if (!comparisonTimePeriod.id) {
      return null;
    }

    return (
      <Select
        variant="standard"
        disabled={isOmniAndOnlyOneOption}
        value={comparisonTimePeriod.id}
        // The type given by Material UI disagrees with the type of the actual event.
        onChange={(event) => this.handlePeriodChange(event.target.value as string)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        input={
          <OutlinedInput
            labelWidth={0}
            id="outlined-age-simple"
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#000000',
                paddingRight: 0,
                paddingLeft: 0,
                marginRight: 0
              }
            }}
          />
        }
        IconComponent={() => (isOmniAndOnlyOneOption ? <></> : <ChevronIcon className="sl-header__drop-down-icon" />)}
      >
        {comparisonTimePeriod.availableComparisonTimePeriods
          .filter((tp) => {
            // leap year edge case, don't show prior year for week 53
            return `${mainTimePeriod.endWeek}`.endsWith('53')
              ? mainTimePeriod.id !== '1w' || tp.id !== 'prior-year'
              : true;
          })
          .map((val: { id: string; shortDisplayName: string }) => (
            <MenuItem
              key={val.id}
              // disabled={this.isPeriodSelectionDisabled() && val.id === 'prior-period'}
              value={val.id}
            >
              {val.shortDisplayName}
            </MenuItem>
          ))}
      </Select>
    );
  }
}

const mapDispatchToProps = {
  updateComparisonAppPeriod: updateComparisonTimePeriod,
  updateQueryParams: appOperations.updateQueryParams
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComparisonTimePeriodDropDown));
