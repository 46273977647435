import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { Parser } from 'expr-eval';
import { AppName, Entity } from 'sl-api-connector/types';
import { AggregationField } from 'src/types/application/types';
import { panic } from 'src/utils/mixpanel';
import { FIELD_PATH_SEPARATOR } from 'src/utils/entityDefinitions/entityDefinitionTypes';
import {
  ADVERTISING_INDEX_FIELDS,
  ATLAS_INDEX_FIELDS,
  BEACON_INDEX_FIELDS,
  OMNI_INDEX_FIELDS
} from 'src/utils/entityDefinitions/indexes';
import {
  ADVERTISING_ENTITIES,
  ATLAS_ENTITIES,
  BEACON_ENTITIES,
  OMNI_ENTITIES
} from 'src/utils/entityDefinitions/entities';

const timerSeriesFieldNames: string[] = ['weekId', 'dayId', 'forecastWeekId'];

export const INDEX_FIELDS = {
  [AppName.Atlas]: {
    ...ATLAS_INDEX_FIELDS
  },
  [AppName.Omni]: {
    ...OMNI_INDEX_FIELDS
  },
  [AppName.Beacon]: {
    ...BEACON_INDEX_FIELDS
  },
  [AppName.Advertising]: {
    ...ADVERTISING_INDEX_FIELDS
  },
  // [AppName.Discover]:{
  //   ...ATLAS_INDEX_FIELDS
  // },
  isTimeSeriesField(fieldName: string): boolean {
    return timerSeriesFieldNames.indexOf(fieldName) >= 0;
  },
  getTimeSeriesFieldNames(): string[] {
    return timerSeriesFieldNames;
  },
  hasField(appName: string, indexName: string, fieldName: string): boolean {
    const appIndexFields: { [key: string]: any } = (INDEX_FIELDS as any)[appName];
    if (fieldName.includes(',')) {
      [fieldName] = fieldName.split(',');
    }
    if (!appIndexFields) {
      return false;
    }
    const indexNameFields: any = appIndexFields[indexName] as any;
    if (!indexNameFields) {
      return false;
    }
    if (!indexNameFields[fieldName]) {
      return false;
    }
    return true;
  },
  getField(
    appName: string,
    indexName: string,
    fieldName: string,
    entityType?: string,
    groupByFieldName?: string
  ): AggregationField {
    const appIndexFields: { [key: string]: any } = (INDEX_FIELDS as any)[appName];
    if (typeof fieldName === 'object') {
      // @ts-ignore
      [fieldName] = fieldName;
    }
    if (fieldName.includes(',')) {
      [fieldName] = fieldName.split(',');
    }

    // need to remove the . from the name (workaround for not being able to escape the . in expr-eval parser)
    if (fieldName.includes('converted')) {
      fieldName = fieldName.toString().replace('.', '');
    }

    if (!appIndexFields) {
      return panic(`Cannot find index fields definitions for appName: ${appName}`);
    }
    const indexNameFields: any = appIndexFields[indexName] as any;
    if (!indexNameFields) {
      return panic(`Cannot find index fields definitions for appName: ${appName},  indexName: ${indexName}`);
    }
    if (!indexNameFields[fieldName]) {
      return panic(
        `Cannot find index fields definitions for appName: ${appName},  indexName: ${indexName}, fieldName: ${fieldName}`
      );
    }
    let field: AggregationField & { [key: string]: any } = _cloneDeep(indexNameFields[fieldName]);

    if (entityType && field.overrides && field.overrides[`${entityType}_Override`]) {
      field = {
        ...field,
        ...field.overrides[`${entityType}_Override`]
      };
    }
    if (groupByFieldName && field.overrides && field.overrides[`${groupByFieldName}_Override`]) {
      field = {
        ...field,
        ...field.overrides[`${groupByFieldName}_Override`]
      };
    }
    if (groupByFieldName && field.overrides && field.overrides[`${entityType}_${groupByFieldName}_Override`]) {
      field = {
        ...field,
        ...field.overrides[`${entityType}_${groupByFieldName}_Override`]
      };
    }

    if (
      field.aggregationFunctionType === 'derived' &&
      field.aggregationFunction &&
      typeof field.aggregationFunction !== 'string'
    ) {
      field.dependentFields = [];
      field.aggregationFunction.variables().forEach((dependentFieldName: string) => {
        let dependentField: any = null;
        const dependentFieldGroupByFieldName = _get(
          field,
          ['dependantFieldOverrides', 'overridesByFieldName', dependentFieldName, 'groupByFieldName'],
          groupByFieldName
        );
        const dependentFieldGroupByResultType = _get(
          field,
          ['dependantFieldOverrides', 'overridesByFieldName', dependentFieldName, 'groupByResultType'],
          'multivalue'
        );
        const dependentFieldDataFetchStrategy = _get(
          field,
          ['dependantFieldOverrides', 'overridesByFieldName', dependentFieldName, 'dataFetchStrategy'],
          'parallel'
        );
        if (dependentFieldName.indexOf(FIELD_PATH_SEPARATOR) > -1) {
          const dependentFieldPath = dependentFieldName.split(FIELD_PATH_SEPARATOR);
          const dependentFieldAppName = dependentFieldPath.length > 2 ? dependentFieldPath[0] : appName;
          const dependentFieldIndexName = dependentFieldPath.length > 2 ? dependentFieldPath[1] : dependentFieldPath[0];
          const dependentFieldNameParsed =
            dependentFieldPath.length > 2 ? dependentFieldPath[2] : dependentFieldPath[1];
          dependentField = {
            ...INDEX_FIELDS.getField(
              dependentFieldAppName,
              dependentFieldIndexName,
              dependentFieldNameParsed,
              entityType,
              dependentFieldGroupByFieldName
            ),
            groupByFieldName: dependentFieldGroupByFieldName,
            groupByResultType: dependentFieldGroupByResultType,
            dataFetchStrategy: dependentFieldDataFetchStrategy
          };
        } else {
          dependentField = {
            ...INDEX_FIELDS.getField(
              appName,
              indexName,
              dependentFieldName,
              entityType,
              dependentFieldGroupByFieldName
            ),
            groupByFieldName: dependentFieldGroupByFieldName,
            groupByResultType: dependentFieldGroupByResultType,
            dataFetchStrategy: dependentFieldDataFetchStrategy
          };
        }
        if (dependentField) {
          if (
            field.dependantFieldOverrides &&
            field.dependantFieldOverrides.primaryDependantFieldName &&
            field.dependantFieldOverrides.primaryDependantFieldName === dependentField.name
          ) {
            field.primaryDependantField = dependentField;
          }
          field.dependentFields.push(dependentField);
        }
      });
      field.aggregationFunctionEvaluator = field.aggregationFunction;
    } else if (
      field.aggregationFunctionType === 'computed' &&
      field.aggregationFunction &&
      typeof field.aggregationFunction !== 'string'
    ) {
      field.dependentFields = [];
      field.aggregationFunction.variables().forEach((dependentFieldName: string) => {
        field.dependentFields.push(
          INDEX_FIELDS.getField(appName, indexName, dependentFieldName, entityType, groupByFieldName)
        );
      });
      let substitutedExpression = Parser.parse(field.aggregationFunction.toString());
      field.dependentFields.forEach((dependentField: AggregationField) => {
        substitutedExpression = substitutedExpression.substitute(
          dependentField.nameAlias ? dependentField.nameAlias : dependentField.name,
          `${dependentField.name}_${dependentField.aggregationFunction}`
        );
      });
      field.aggregationFunctionEvaluator = field.aggregationFunction;
      field.aggregationFunction = field.aggregationFunctionType;
      field.aggregationFunctionExpression = substitutedExpression.toString();
    }
    field.appName = appName;
    field.indexName = indexName;
    return field;
  }
};

export const ENTITIES: { [appName: string]: { [entityName: string]: Entity } } = {
  [AppName.Atlas]: {
    ...ATLAS_ENTITIES
  },
  [AppName.Beacon]: {
    ...BEACON_ENTITIES
  },
  [AppName.Advertising]: {
    ...ADVERTISING_ENTITIES
  },
  [AppName.Omni]: {
    ...OMNI_ENTITIES
  },
  [AppName.Discover]: {
    ...ATLAS_ENTITIES
  }
};
