/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import './ScoreBarChart.scss';
import { AdManagerKeyMetricWidget } from 'src/components/Layout/LayoutUtil';
import ReduxStore from 'src/types/store/reduxStore';
import colors from 'src/utils/colors';

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['entityService', 'adScores']),
  mainEntity: state.entityService.mainEntity,
  adScores: state.adScores
});

const lineStyle = {
  height: 2,
  width: '100%',
  background: colors.lightestGrey
};

const ScoreBarChart: React.FC<{ widget: AdManagerKeyMetricWidget } & ReturnType<typeof mapStateToProps>> = ({
  widget: {
    view: { title }
    // data: { adScoreName }
  }
  // entityService: { mainEntity },
  // adScores
}) => {
  return (
    <div>
      <div className="score_bar_title">{title}</div>
      <div className="score_bar_chart">
        <div style={lineStyle}></div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ScoreBarChart);
