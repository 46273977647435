import { useDispatch } from 'react-redux';
import { FetchAdditionalDataFn } from 'src/components/AdManager/Search/hooks/types';
import useFetchEntityMetricsArgs from './useFetchEntityMetricsArgs';
import useBuildMainEntityConditions from './useBuildMainEntityConditions';
import { CampaignsDataFetcher } from 'src/components/AdManager/Search/GridDataFetchers/Campaigns/CampaignsDataFetcher';
import { useCallback } from 'react';

/**
 * Returns a callback function for fetching campaign data.
 */
const useFetchCampaignData = (importantOverrides: any | [] = []): FetchAdditionalDataFn => {
  const dispatch = useDispatch();
  const { entity, retailer, app } = useFetchEntityMetricsArgs();
  const entityConditions = useBuildMainEntityConditions();

  return useCallback(
    async ({
      widget,
      indexName,
      sortDirection,
      currentSortFieldName,
      pageNumber,
      searchSideBarConditions,
      groupByField,
      mainTimePeriodRangeFilters,
      aggregationFilters,
      aggregationFieldsForResultCount,
      onFirstPageAndCount,
      location,
      dataSet,
      fetchMetricsParams,
      additionalTermFilters
    }) => {
      const campaignsDataFetcher = new CampaignsDataFetcher();
      const finalResult = await campaignsDataFetcher.fetchCampaignsData(
        { location },
        dispatch,
        widget,
        entity,
        retailer,
        app,
        entityConditions,
        undefined, // TODO add cancel token
        searchSideBarConditions,
        indexName,
        groupByField,
        onFirstPageAndCount,
        aggregationFieldsForResultCount,
        mainTimePeriodRangeFilters,
        aggregationFilters,
        sortDirection,
        currentSortFieldName,
        additionalTermFilters,
        widget.data.customTermFilters || [], // TODO custom term filters needs to be computed
        fetchMetricsParams,
        dataSet,
        pageNumber,
        importantOverrides
      );
      return finalResult;
    },
    [app, dispatch, entity, entityConditions, retailer]
  );
};

export default useFetchCampaignData;
