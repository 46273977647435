import React, { useState, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { withBus } from 'react-bus';
import _get from 'lodash/get';
import _identity from 'lodash/identity';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import { AppName } from 'sl-api-connector/types';

import TileContainer from '../Tiles/TileContainer';
import CompactListContainer from '../CompactList/CompactListContainer';
import { ListGrid } from 'src/components/Grids';
import EntityTableContainer from '../Table/EntityTableContainer';
import EntityGridHeader from '../Header/EntityGridHeader';
import './EntityGrid.scss';
import 'src/routes/HomePage/HomePage.scss';
import { CrossIcon } from 'src/components/SvgIcons';
import ReclassifyHeader from 'src/components/common/Header/ReclassifyHeader';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import BorderedChip from 'src/components/common/Chip/BorderedChip';
import { map } from 'src/utils/fp';
import { useBus } from 'src/utils/Hooks';
import { getAppStage, shouldShowNewBeacon } from 'src/utils/app';
import EntityTableHeader, {
  IconsList
} from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';
import BeaconProEntityGrid from './BeaconProEntityGrid';
import PaginationProvider from './PaginationProvider';

const layoutToUsePropType = PropTypes.oneOf(['compactList', 'chip', 'tile', 'table']);

const styles = {
  label: {
    order: '1',
    paddingLeft: '4px',
    whiteSpace: 'normal'
  },
  root: {
    minHeight: '32px',
    height: 'auto',
    padding: '2px 0'
  }
};

const DefaultGridLoading = () => (
  <div className="spinner-wrapper">
    <GridLoading />
  </div>
);

const NoDataAvailable = ({ message }) => (
  <div className="grid__col grid__col--12-of-12">
    <div className="no-results">
      <div>
        <div className="sl-no-data">{message}</div>
      </div>
    </div>
  </div>
);

NoDataAvailable.propTypes = {
  message: PropTypes.string.isRequired
};

const ChipListRenderer = ({
  dataToRender,
  classes,
  handleWaypointEntered,
  eventBus,
  rowDeleteEventName,
  handleNextPageClick,
  handlePrevPageClick,
  pageNumber,
  pageSize,
  handlePageChangeSuperUser
}) => (
  <ListGrid
    className="chip-list-grid"
    onWaypointEntered={handleWaypointEntered}
    handleNextPageClick={handleNextPageClick}
    handlePrevPageClick={handlePrevPageClick}
    pageNumber={pageNumber}
    pageSize={pageSize}
    handlePageChangeSuperUser={handlePageChangeSuperUser}
  >
    {dataToRender.map((item) => (
      <BorderedChip
        key={item.entity.id}
        classes={{ root: classes.root, label: classes.label }}
        label={item.name}
        variant="outlined"
        deleteIcon={<CrossIcon className="chip-list-grid__cross-icon" style={{ marginLeft: 12 }} />}
        onDelete={() => eventBus.emit(rowDeleteEventName, item)}
        color="primary"
      />
    ))}
  </ListGrid>
);

ChipListRenderer.propTypes = {
  dataToRender: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  handleWaypointEntered: PropTypes.func.isRequired,
  eventBus: PropTypes.object.isRequired,
  rowDeleteEventName: PropTypes.string.isRequired,
  handleNextPageClick: PropTypes.func.isRequired,
  handlePrevPageClick: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  handlePageChangeSuperUser: PropTypes.func.isRequired
};

const ListGridRenderer = ({
  dataToRender,
  app,
  handleWaypointEntered,
  reclassifyProducts,
  handleReclassifyCheckboxClick,
  showPriceData,
  showCardBackDeleteButton,
  rowDeleteEventName,
  eventBus,
  showOnlyBreadcrumbMismatches,
  filterRows,
  hideAlreadyClassifiedEntities,
  handleNextPageClick,
  handlePrevPageClick,
  pageSize,
  pageNumber,
  showReclassifyHeader,
  handlePageChangeSuperUser,
  isLoading
}) => {
  const ref = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  let percentagefilteredData = dataToRender.filter(filterRows);
  const MAX_ITEMS_PER_PAGE_FOR_SUPERUSER = 100;
  const numFilteredProducts = percentagefilteredData.length;
  const numPages = Math.ceil(numFilteredProducts / MAX_ITEMS_PER_PAGE_FOR_SUPERUSER);
  let hideNextPageButton = false;

  const onNextPageClick = () => {
    const nextPageNumber = Math.min(numPages, currentPage + 1);
    setCurrentPage(nextPageNumber);
    handlePageChangeSuperUser(nextPageNumber);
    if (percentagefilteredData.length <= nextPageNumber * MAX_ITEMS_PER_PAGE_FOR_SUPERUSER) {
      handleWaypointEntered();
    }
  };

  const onPrevPageClick = () => {
    const prevPageNumber = Math.max(1, currentPage - 1);
    setCurrentPage(prevPageNumber);
    handlePageChangeSuperUser(prevPageNumber);
  };

  const removeMissingProducts = (dataToFilter) => {
    if (getAppStage() === 'prod') {
      return dataToFilter;
    }
    const filteredData = dataToFilter.filter((item) => {
      const categoryName = _get(item, 'cardView.categoryName', '');
      return categoryName !== '';
    });
    return filteredData;
  };
  percentagefilteredData = removeMissingProducts(percentagefilteredData);

  if (showReclassifyHeader) {
    percentagefilteredData = percentagefilteredData.slice(
      (currentPage - 1) * MAX_ITEMS_PER_PAGE_FOR_SUPERUSER,
      currentPage * MAX_ITEMS_PER_PAGE_FOR_SUPERUSER
    );
    if (percentagefilteredData.length < MAX_ITEMS_PER_PAGE_FOR_SUPERUSER) {
      hideNextPageButton = true;
    }
    if (percentagefilteredData.length === 0) {
      setCurrentPage(numPages);
      handlePageChangeSuperUser(numPages);
    }
  }
  const renderPagination = () => {
    if (showReclassifyHeader) {
      return (
        <>
          <span ref={ref}></span>
          <ListGrid
            showLoadMoreButton={showOnlyBreadcrumbMismatches}
            className="entityGrid-list-grid"
            onWaypointEntered={handleWaypointEntered}
            handleNextPageClick={onNextPageClick}
            handlePrevPageClick={onPrevPageClick}
            pageSize={pageSize}
            pageNumber={currentPage}
            showReclassifyHeader={showReclassifyHeader}
            handleLoadMoreProductsClick={handleWaypointEntered}
            hideNextPageButton={hideNextPageButton}
          >
            {percentagefilteredData.map((item) => {
              return (
                <TileContainer
                  key={item.entity.id}
                  dataItem={item}
                  reclassifyProducts={reclassifyProducts}
                  onReclassifyChecked={handleReclassifyCheckboxClick}
                  showStatusBar={app.name === AppName.Beacon}
                  showPriceData={showPriceData}
                  showCardBackDeleteButton={showCardBackDeleteButton}
                  onDelete={() => eventBus.emit(rowDeleteEventName, item)}
                  showOnlyBreadcrumbMismatches={showOnlyBreadcrumbMismatches}
                  hideAlreadyClassifiedEntities={hideAlreadyClassifiedEntities}
                />
              );
            })}
          </ListGrid>
        </>
      );
    } else {
      return (
        <ListGrid
          showLoadMoreButton={showOnlyBreadcrumbMismatches}
          className="entityGrid-list-grid"
          onWaypointEntered={handleWaypointEntered}
          handleNextPageClick={handleNextPageClick}
          handlePrevPageClick={handlePrevPageClick}
          pageSize={pageSize}
          pageNumber={pageNumber}
          showReclassifyHeader={showReclassifyHeader}
        >
          {percentagefilteredData.map((item) => {
            return (
              <TileContainer
                key={item.entity.id}
                dataItem={item}
                reclassifyProducts={reclassifyProducts}
                onReclassifyChecked={handleReclassifyCheckboxClick}
                showStatusBar={app.name === AppName.Beacon}
                showPriceData={showPriceData}
                showCardBackDeleteButton={showCardBackDeleteButton}
                onDelete={() => eventBus.emit(rowDeleteEventName, item)}
                showOnlyBreadcrumbMismatches={showOnlyBreadcrumbMismatches}
              />
            );
          })}
        </ListGrid>
      );
    }
  };

  if (shouldShowNewBeacon()) {
    return (
      <BeaconProEntityGrid
        data={percentagefilteredData}
        fetchData={() => handleWaypointEntered()}
        isLoading={isLoading}
        lastFetchedPage={pageNumber}
      />
    );
  }
  return <div>{renderPagination()}</div>;
};

ListGridRenderer.propTypes = {
  dataToRender: PropTypes.array.isRequired,
  app: PropTypes.object.isRequired,
  handleWaypointEntered: PropTypes.func.isRequired,
  reclassifyProducts: PropTypes.array.isRequired,
  handleReclassifyCheckboxClick: PropTypes.func.isRequired,
  showPriceData: PropTypes.bool.isRequired,
  showCardBackDeleteButton: PropTypes.bool,
  rowDeleteEventName: PropTypes.string,
  eventBus: PropTypes.object.isRequired,
  showOnlyBreadcrumbMismatches: PropTypes.bool.isRequired,
  filterRows: PropTypes.func,
  hideAlreadyClassifiedEntities: PropTypes.bool,
  widget: PropTypes.object.isRequired,
  handleNextPageClick: PropTypes.func.isRequired,
  handlePrevPageClick: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  showReclassifyHeader: PropTypes.bool.isRequired,
  handlePageChangeSuperUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

ListGridRenderer.defaultProps = {
  rowDeleteEventName: undefined,
  showCardBackDeleteButton: false,
  filterRows: () => true
};

const TableRenderer = ({
  groupByField,
  configByGroupByFieldName,
  dataSet,
  handleWaypointEntered,
  handleSortDirectionChange,
  sortByMetric,
  metricFields,
  handleNextPageClick,
  handlePrevPageClick,
  pageSize,
  pageNumber,
  handlePageChangeSuperUser,
  ...rest
}) => {
  const realMetricFields =
    metricFields ||
    _get(configByGroupByFieldName, [_get(groupByField, 'name'), 'tableView', 'metricFields'], null) ||
    undefined;

  const dataSetToRender = dataSet || rest.dataToRender;

  return dataSetToRender ? (
    <EntityTableContainer
      groupByField={groupByField}
      metricFields={realMetricFields}
      dataSet={dataSetToRender}
      onWaypointEntered={handleWaypointEntered}
      onSortClick={handleSortDirectionChange}
      sortByMetric={sortByMetric}
      overlayNoRowsTemplate={ReactDOMServer.renderToString(<div style={{ marginTop: 53 }}>No Data To Show</div>)}
      handleNextPageClick={handleNextPageClick}
      handlePrevPageClick={handlePrevPageClick}
      pageSize={pageSize}
      pageNumber={pageNumber}
      handlePageChangeSuperUser={handlePageChangeSuperUser}
      {...rest}
    />
  ) : null;
};

TableRenderer.propTypes = {
  mainMetricField: PropTypes.any.isRequired,
  groupByField: PropTypes.any.isRequired,
  configByGroupByFieldName: PropTypes.any.isRequired,
  uniqueName: PropTypes.any.isRequired,
  handleWaypointEntered: PropTypes.any.isRequired,
  handleSortDirectionChange: PropTypes.any.isRequired,
  sortDirection: PropTypes.any.isRequired,
  firstColumnDefOverrides: PropTypes.object,
  filterRows: PropTypes.func,
  sortByMetric: PropTypes.string,
  sortRows: PropTypes.func,
  rowsToRender: PropTypes.number,
  metricFields: PropTypes.array,
  computePerRowAggregatedMetrics: PropTypes.bool,
  dataSet: PropTypes.object,
  handleNextPageClick: PropTypes.any.isRequired,
  handlePrevPageClick: PropTypes.any.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  handlePageChangeSuperUser: PropTypes.func.isRequired
};

TableRenderer.defaultProps = {
  firstColumnDefOverrides: {},
  filterRows: undefined,
  sortRows: undefined,
  rowsToRender: undefined,
  sortByMetric: null,
  metricFields: undefined,
  computePerRowAggregatedMetrics: false,
  dataSet: undefined
};

const CompactListRenderer = ({
  dataToRender,
  handleWaypointEntered,
  onSelectItem,
  handleNextPageClick,
  handlePrevPageClick,
  pageSize,
  pageNumber,
  handlePageChangeSuperUser
}) => (
  <ListGrid
    className="compare-products-list-grid"
    onWaypointEntered={handleWaypointEntered}
    handleNextPageClick={handleNextPageClick}
    handlePrevPageClick={handlePrevPageClick}
    pageSize={pageSize}
    pageNumber={pageNumber}
    handlePageChangeSuperUser={handlePageChangeSuperUser}
  >
    {dataToRender.map((item) => (
      <CompactListContainer key={item.entity.id} dataItem={item} onSelectItem={onSelectItem} />
    ))}
  </ListGrid>
);

CompactListRenderer.propTypes = {
  dataToRender: PropTypes.array.isRequired,
  handleWaypointEntered: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  handleNextPageClick: PropTypes.func.isRequired,
  handlePrevPageClick: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  handlePageChangeSuperUser: PropTypes.func.isRequired
};

const rendererByLayout = {
  compactList: CompactListRenderer,
  chip: ChipListRenderer,
  tile: ListGridRenderer,
  table: TableRenderer
};

const neededPropsByLayout = {
  compactList: [
    'dataToRender',
    'handleWaypointEntered',
    'onSelectItem',
    'handleNextPageClick',
    'handlePrevPageClick',
    'pageSize',
    'pageNumber',
    'showReclassifyHeader',
    'handlePageChangeSuperUser'
  ],
  chip: [
    'dataToRender',
    'classes',
    'handleWaypointEntered',
    'eventBus',
    'rowDeleteEventName',
    'handleNextPageClick',
    'handlePrevPageClick',
    'pageSize',
    'pageNumber',
    'showReclassifyHeader',
    'handlePageChangeSuperUser'
  ],
  tile: [
    'dataToRender',
    'app',
    'handleWaypointEntered',
    'reclassifyProducts',
    'handleReclassifyCheckboxClick',
    'showPriceData',
    'showCardBackDeleteButton',
    'rowDeleteEventName',
    'eventBus',
    'showOnlyBreadcrumbMismatches',
    'filterRows',
    'widget',
    'handleNextPageClick',
    'handlePrevPageClick',
    'pageSize',
    'pageNumber',
    'showReclassifyHeader',
    'handlePageChangeSuperUser',
    'isLoading'
  ],
  table: [
    'dataToRender',
    'mainMetricField',
    'groupByField',
    'configByGroupByFieldName',
    'dataSet',
    'uniqueName',
    'handleWaypointEntered',
    'handleSortDirectionChange',
    'sortDirection',
    'firstColumnDefOverrides',
    'filterRows',
    'sortRows',
    'rowsToRender',
    'sortByMetric',
    'metricFields',
    'computePerRowAggregatedMetrics',
    'disableMetricFormatting',
    'agGridProps',
    'handleNextPageClick',
    'handlePrevPageClick',
    'pageSize',
    'pageNumber',
    'showReclassifyHeader',
    'handlePageChangeSuperUser',
    'isLoading'
  ]
};

const GridRendererForLayout = ({ layout, loadingElement, ...props }) => {
  const Renderer = rendererByLayout[layout];
  const neededPropKeys = neededPropsByLayout[layout];
  const propsForRenderer = _pick(props, neededPropKeys);

  return (
    <PaginationProvider>
      <Renderer {...propsForRenderer} /> {loadingElement || null}
    </PaginationProvider>
  );
};

GridRendererForLayout.propTypes = {
  layout: layoutToUsePropType.isRequired,
  loadingElement: PropTypes.any
};

GridRendererForLayout.defaultProps = {
  loadingElement: undefined
};

const GridDataContent = ({ layoutToUse, loadingElement, ...props }) => {
  if (_isEmpty(props.dataToRender)) {
    return null;
  }

  return (
    <div className="entityGrid">
      <GridRendererForLayout layout={layoutToUse} loadingElement={loadingElement} {...props} />
    </div>
  );
};

GridDataContent.propTypes = {
  layoutToUse: layoutToUsePropType.isRequired,
  loadingElement: PropTypes.any,
  dataToRender: PropTypes.array.isRequired
};

GridDataContent.defaultProps = {
  loadingElement: undefined
};

const EntityGridRenderer = ({
  showReclassifyHeader,
  hideGridHeader,
  showHeaderDivider,
  enableExport,
  enableGroupBy,
  enableAggregateBy,
  enableSwitchingLayouts,
  eventBus,
  groupByFields,
  handleChangeLayout,
  handleAggregateByChange,
  handleGroupByChange,
  configByGroupByFieldName,
  groupByField,
  title,
  exportRequest,
  exportFileName,
  layoutToUse,
  isSelectAllChecked,
  handleSelectAllReclassifyCheckbox,
  handleSortDirectionChange,
  sortDirection,
  reclassifyProducts,
  apiRequest,
  noDataAvailableMessage,
  isLoading,
  pageNumber,
  dataToRender,
  app,
  loadingElement,
  toggleShowPriceData,
  entityGridContainerStyle,
  showOnlyBreadcrumbMismatches,
  toggleShowOnlyBreadcrumbMismatches,
  hideAlreadyClassifiedEntities,
  toggleHideAlreadyClassifiedEntities,
  pageSize,
  handlePageChangeSuperUser,
  clearAllSelections,
  numSelectedItems,
  ...rest
}) => {
  // removing negative percent change for increasing key and positive percent change for decreasing key
  // for items in grid view and table view
  const [tab, setTab] = useState('topSelling');
  const customRowFilter = {
    topSelling: () => true,
    increasing: (rowDatum) => _get(rowDatum, ['cardView', 'retailSalesPercentChange']) > 0.0,
    decreasing: (rowDatum) => _get(rowDatum, ['cardView', 'retailSalesPercentChange']) < 0.0
  }[tab];
  useBus(eventBus, 'trendingProductsTabSelect', (selectedTab) => setTab(selectedTab.id));

  // Beacon Redesign Logic
  const showNewBeacon = shouldShowNewBeacon();
  const { uniqueName, hideHeader } = { ...rest };

  return (
    <div className={`entity-grid-container ${rest.className}`} style={entityGridContainerStyle}>
      {showNewBeacon && !hideHeader ? (
        <>
          <EntityTableHeader
            app={app}
            showHeaderDivider={showHeaderDivider}
            enableExport={false}
            enableGroupBy={enableGroupBy}
            enableAggregateBy={enableAggregateBy}
            enableSwitchingLayouts={enableSwitchingLayouts}
            defaultView={layoutToUse}
            handleChangeLayout={handleChangeLayout}
            handleAggregateByChange={handleAggregateByChange}
            handleGroupByChange={handleGroupByChange}
            aggregationFields={_get(configByGroupByFieldName, [_get(groupByField, 'name'), 'aggregationFields'])}
            groupByFields={groupByFields}
            title={title}
            exportRequest={exportRequest}
            exportFileName={exportFileName}
            groupByField={groupByField}
            uniqueName={uniqueName}
            showTabs={_get(rest, ['widget', 'view', 'showTabs'], true)}
            showDropdown={_get(rest, ['widget', 'view', 'showDropdown'], true)}
            iconsList={_get(rest, ['widget', 'view', 'iconsList'], [IconsList.export, IconsList.tile, IconsList.table])}
          />
        </>
      ) : null}
      {!hideGridHeader && !showNewBeacon ? (
        <EntityGridHeader
          app={app}
          showHeaderDivider={showHeaderDivider}
          enableExport={enableExport}
          enableGroupBy={enableGroupBy}
          enableAggregateBy={enableAggregateBy}
          enableSwitchingLayouts={enableSwitchingLayouts}
          defaultView={layoutToUse}
          handleChangeLayout={handleChangeLayout}
          handleAggregateByChange={handleAggregateByChange}
          handleGroupByChange={handleGroupByChange}
          aggregationFields={_get(configByGroupByFieldName, [_get(groupByField, 'name'), 'aggregationFields'])}
          groupByFields={groupByFields}
          title={title}
          exportRequest={exportRequest}
          exportFileName={exportFileName}
          groupByField={groupByField}
        />
      ) : null}
      {showReclassifyHeader ? (
        <ReclassifyHeader
          isSelectAllChecked={isSelectAllChecked}
          handleSelectAllCheck={handleSelectAllReclassifyCheckbox}
          handleSortDirectionChange={handleSortDirectionChange}
          sortDirection={sortDirection}
          products={reclassifyProducts}
          reclassifyRequest={apiRequest}
          showPriceData={rest.showPriceData}
          toggleShowPriceData={toggleShowPriceData}
          showOnlyBreadcrumbMismatches={showOnlyBreadcrumbMismatches}
          toggleShowOnlyBreadcrumbMismatches={toggleShowOnlyBreadcrumbMismatches}
          hideAlreadyClassifiedEntities={hideAlreadyClassifiedEntities}
          toggleHideAlreadyClassifiedEntities={toggleHideAlreadyClassifiedEntities}
          clearAllSelections={clearAllSelections}
          numSelectedItems={numSelectedItems}
        />
      ) : null}

      {pageNumber === 1 ? loadingElement : null}

      <GridDataContent
        dataToRender={dataToRender}
        layoutToUse={layoutToUse}
        pageNumber={pageNumber}
        isLoading={isLoading}
        app={app}
        configByGroupByFieldName={configByGroupByFieldName}
        groupByField={groupByField}
        reclassifyProducts={reclassifyProducts}
        loadingElement={loadingElement}
        showOnlyBreadcrumbMismatches={showOnlyBreadcrumbMismatches}
        handleSortDirectionChange={handleSortDirectionChange}
        hideAlreadyClassifiedEntites={hideAlreadyClassifiedEntities}
        {...rest}
        filterRows={customRowFilter}
        pageSize={pageSize}
        showReclassifyHeader={showReclassifyHeader}
        handlePageChangeSuperUser={handlePageChangeSuperUser}
      />

      {dataToRender.length === 0 && !isLoading && (
        <div style={{ paddingTop: '20px' }}>
          <NoDataAvailable message={noDataAvailableMessage} />
        </div>
      )}
    </div>
  );
};

EntityGridRenderer.propTypes = {
  showReclassifyHeader: PropTypes.bool,
  hideGridHeader: PropTypes.bool,
  showHeaderDivider: PropTypes.any,
  enableExport: PropTypes.bool,
  enableGroupBy: PropTypes.bool,
  enableAggregateBy: PropTypes.bool,
  enableSwitchingLayouts: PropTypes.bool,
  groupByFields: PropTypes.any,
  handleChangeLayout: PropTypes.any.isRequired,
  handleAggregateByChange: PropTypes.func,
  handleGroupByChange: PropTypes.func,
  configByGroupByFieldName: PropTypes.object,
  groupByField: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
  exportRequest: PropTypes.any,
  exportFileName: PropTypes.string,
  layoutToUse: PropTypes.any.isRequired,
  isSelectAllChecked: PropTypes.bool,
  handleSelectAllReclassifyCheckbox: PropTypes.func,
  reclassifyProducts: PropTypes.array,
  apiRequest: PropTypes.any,
  noDataAvailableMessage: PropTypes.node,
  isLoading: PropTypes.any.isRequired,
  pageNumber: PropTypes.number.isRequired,
  dataToRender: PropTypes.any.isRequired,
  handleWaypointEntered: PropTypes.any.isRequired,
  CustomLoading: PropTypes.func,
  onSelectItem: PropTypes.func,
  classes: PropTypes.any.isRequired,
  eventBus: PropTypes.any.isRequired,
  handleSortDirectionChange: PropTypes.func,
  sortDirection: PropTypes.any,
  rowDeleteEventName: PropTypes.string,
  mainMetricField: PropTypes.any.isRequired,
  uniqueName: PropTypes.any.isRequired,
  loadingElement: PropTypes.any,
  handleReclassifyCheckboxClick: PropTypes.func,
  showPriceData: PropTypes.bool,
  toggleShowPriceData: PropTypes.func,
  rowsToRender: PropTypes.number,
  dataKey: PropTypes.string,
  toggleShowOnlyBreadcrumbMismatches: PropTypes.func,
  showOnlyBreadcrumbMismatches: PropTypes.bool,
  toggleHideAlreadyClassifiedEntities: PropTypes.func,
  hideAlreadyClassifiedEntities: PropTypes.bool,
  handleNextPageClick: PropTypes.func.isRequired,
  handlePrevPageClick: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  handlePageChangeSuperUser: PropTypes.func,
  clearAllSelections: PropTypes.func,
  numSelectedItems: PropTypes.number,
  // Redux props
  app: PropTypes.object.isRequired,
  entityGridContainerStyle: PropTypes.object
};

EntityGridRenderer.defaultProps = {
  showHeaderDivider: true,
  hideGridHeader: false,
  enableAggregateBy: false,
  enableGroupBy: false,
  exportRequest: undefined,
  apiRequest: [],
  CustomLoading: undefined,
  rowDeleteEventName: undefined,
  loadingElement: null,
  noDataAvailableMessage: 'No Data Available',
  reclassifyProducts: [],
  handleSelectAllReclassifyCheckbox: _identity,
  isSelectAllChecked: false,
  exportFileName: undefined,
  configByGroupByFieldName: {},
  handleGroupByChange: _identity,
  handleAggregateByChange: _identity,
  groupByFields: undefined,
  enableExport: true,
  showReclassifyHeader: false,
  handleReclassifyCheckboxClick: _identity,
  sortDirection: undefined,
  handleSortDirectionChange: _identity,
  onSelectItem: _identity,
  enableSwitchingLayouts: true,
  showPriceData: true,
  toggleShowPriceData: _identity,
  entityGridContainerStyle: undefined,
  rowsToRender: undefined,
  dataKey: undefined,
  showOnlyBreadcrumbMismatches: false,
  toggleShowOnlyBreadcrumbMismatches: _identity,
  hideAlreadyClassifiedEntities: false,
  toggleHideAlreadyClassifiedEntities: _identity
};

const mapStateToProps = (state, { CustomLoading, isLoading, dataKey, pageNumber }) => {
  const loadingElement = (() => {
    if (!isLoading) {
      return null;
    }

    if (shouldShowNewBeacon() && pageNumber > 1) {
      return null;
    }

    return <span className="entity-grid-loading">{CustomLoading ? <CustomLoading /> : <DefaultGridLoading />}</span>;
  })();

  return {
    ..._pick(state, ['app']),
    loadingElement,
    dataSet: map(dataKey, (key) => state.entitySearchService[key])
  };
};

const enhance = compose(connect(mapStateToProps), withBus('eventBus'), withStyles(styles));

const EnhancedEntityGridRenderer = enhance(EntityGridRenderer);

export default EnhancedEntityGridRenderer;
