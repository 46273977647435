import { Entity } from 'sl-api-connector/types';
import { getProductImageUrl } from 'src/utils/image';

const beaconclientEntity = {
  id: -1,
  keyFieldName: 'beaconClientId',
  nameFieldName: 'companyName',
  type: 'beaconclient',
  displayName: 'Organization',
  displayNamePlural: 'Organizations',
  imageUrl: (id: string | number) => `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`
};

export const COMMON_ENTITIES: { [entityType: string]: Entity } = {
  beaconclient: beaconclientEntity,
  client: beaconclientEntity,
  retailer: {
    id: -1,
    keyFieldName: 'retailerId',
    nameFieldName: 'retailerName',
    type: 'retailer',
    displayName: 'Retailer',
    displayNamePlural: 'Retailers',
    imageUrl: (id: string | number) => `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`
  },
  product: {
    id: -1,
    keyFieldName: 'stacklineSku',
    nameFieldName: 'title',
    type: 'product',
    displayName: 'Product',
    displayNamePlural: 'Products',
    imageUrl: (id: string | number) => getProductImageUrl(id.toString())
  },
  brand: {
    id: -1,
    keyFieldName: 'brandId',
    nameFieldName: 'brandName',
    type: 'brand',
    displayName: 'Brand',
    displayNamePlural: 'Brands',
    imageUrl: (id: string | number) =>
      `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg?t=${new Date().getTime()}`
  },
  parentBrand: {
    id: -1,
    keyFieldName: 'parentBrandId',
    nameFieldName: 'name',
    type: 'parentBrand',
    displayName: 'Manufacturer',
    displayNamePlural: 'Manufacturers',
    imageUrl: (id: string | number) =>
      `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg?t=${new Date().getTime()}`
  },
  license: {
    id: -1,
    keyFieldName: 'licenseId',
    nameFieldName: 'licenseName',
    type: 'license',
    displayName: 'License',
    displayNamePlural: 'Licenses',
    imageUrl: (id: string | number) => getProductImageUrl(id.toString())
  },
  category: {
    id: -1,
    keyFieldName: 'categoryId',
    nameFieldName: 'categoryName',
    type: 'category',
    displayName: 'Category',
    displayNamePlural: 'Categories',
    imageUrl: (id: string | number) => `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`
  },
  subcategory: {
    id: -1,
    keyFieldName: 'subCategoryId',
    nameFieldName: 'subCategoryName',
    type: 'subcategory',
    displayName: 'Subcategory',
    displayNamePlural: 'Subcategories',
    imageUrl: (id: string | number) => `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`
  },
  segment: {
    id: -1,
    keyFieldName: 'queryId',
    nameFieldName: 'name',
    type: 'segment',
    displayName: 'Segment',
    displayNamePlural: 'Segments',
    imageUrl: (id: string | number) => `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`
  },
  company: {
    id: -1,
    keyFieldName: 'id',
    nameFieldName: 'name',
    type: 'company',
    displayName: 'Organization',
    displayNamePlural: 'Organizations',
    imageUrl: (id: string | number) => `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`
  },
  searchtermlist: {
    id: -1,
    keyFieldName: 'queryId',
    nameFieldName: 'name',
    type: 'searchtermlist',
    displayName: 'Keyword List',
    displayNamePlural: 'Keyword Lists',
    imageUrl: (id: string | number) => `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`
  },
  seller: {
    id: -1,
    keyFieldName: 'merchantName',
    nameFieldName: 'merchantName',
    type: 'seller',
    displayName: 'Seller',
    displayNamePlural: 'Sellers',
    imageUrl: (id: string | number) => `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`
  },
  campaign: {
    id: -1,
    keyFieldName: 'campaignId',
    nameFieldName: 'campaignName',
    type: 'campaign',
    displayName: 'Campaign',
    displayNamePlural: 'Campaigns'
  },
  lineItem: {
    id: -1,
    keyFieldName: 'lineItemId',
    nameFieldName: 'lineItemName',
    type: 'lineItem',
    displayName: 'Line Item',
    displayNamePlural: 'Line Items'
  },
  order: {
    id: -1,
    keyFieldName: 'orderId',
    nameFieldName: 'orderName',
    type: 'order',
    displayName: 'Order',
    displayNamePlural: 'Orders'
  },
  creative: {
    id: -1,
    keyFieldName: 'creativeId',
    nameFieldName: 'creativeName',
    type: 'creative',
    displayName: 'Creative',
    displayNamePlural: 'Creatives'
  },
  creativeType: {
    id: -1,
    keyFieldName: 'creativeType',
    nameFieldName: 'creativeType',
    type: 'creativeType',
    displayName: 'Creative Type',
    displayNamePlural: 'Creative Types'
  },
  creativeSize: {
    id: -1,
    keyFieldName: 'creativeSize',
    nameFieldName: 'creativeSize',
    type: 'creativeSize',
    displayName: 'Creative Size',
    displayNamePlural: 'Creative Sizes'
  },
  conversionType: {
    id: -1,
    keyFieldName: 'conversionType',
    type: 'conversionType',
    displayName: 'Conversion Type',
    displayNamePlural: 'Conversion Type'
  },
  adGroup: {
    id: -1,
    keyFieldName: 'adGroupId',
    nameFieldName: 'adGroupName',
    type: 'adGroup',
    displayName: 'Ad Group',
    displayNamePlural: 'Ad Groups'
  },
  adCampaign: {
    id: -1,
    keyFieldName: 'campaignId',
    nameFieldName: 'campaignName',
    type: 'adCampaign',
    typeAliases: ['campaign'],
    displayName: 'Ad Campaign',
    displayNamePlural: 'Ad Campaigns'
  },
  adPortfolio: {
    id: -1,
    keyFieldName: 'portfolioId',
    nameFieldName: 'portfolioName',
    type: 'adPortfolio',
    typeAliases: ['portfolio'],
    displayName: 'Ad Portfolio',
    displayNamePlural: 'Ad Portfolios'
  },
  adEntity: {
    id: -1,
    keyFieldName: 'entityId',
    nameFieldName: 'displayName',
    type: 'adEntity',
    displayName: 'Ad Entity',
    displayNamePlural: 'Ad Entities'
  },
  adTarget: {
    id: -1,
    keyFieldName: 'targetingText',
    nameFieldName: 'targetingText',
    type: 'adTarget',
    displayName: 'Ad Target',
    displayNamePlural: 'Ad Targets'
  },
  campaignType: {
    id: -1,
    keyFieldName: 'campaignType',
    nameFieldName: 'campaignType',
    type: 'campaignType',
    displayName: 'Campaign Type',
    displayNamePlural: 'Campaign Types',
    imageUrl: (id: string | number) => `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`
  },
  searchKeyword: {
    id: -1,
    keyFieldName: 'searchKeyword',
    nameFieldName: 'searchKeyword',
    type: 'searchKeyword',
    displayName: 'Keyword',
    displayNamePlural: 'Keywords',
    imageUrl: (id: string | number) => `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`
  },
  KeywordText: {
    id: -1,
    keyFieldName: 'KeywordText',
    nameFieldName: 'KeywordText',
    type: 'KeywordText',
    displayName: 'Keyword',
    displayNamePlural: 'Keywords',
    imageUrl: (id: string | number) => `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`
  },
  searchTerm: {
    id: -1,
    keyFieldName: 'searchTerm',
    nameFieldName: 'searchTerm',
    type: 'searchTerm',
    displayName: 'Keyword',
    displayNamePlural: 'Keywords',
    imageUrl: (id: string | number) => `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`
  },
  promoType: {
    id: -1,
    keyFieldName: 'promoType',
    nameFieldName: 'promoType',
    type: 'promoType',
    displayName: 'Deal Type',
    displayNamePlural: 'Deal Types',
    imageUrl: (id: string | number) => `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`
  }
};
