import { Divider } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { BEACON_SUBTABS, BEACON_TABS } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';
import { CloseIcon } from 'src/components/SvgIcons';
import { EntityPathname } from 'src/types/application/types';
import { useAppSelector } from 'src/utils/Hooks';

export const AdjustmentModalHeader = ({
  title,
  handleClose,
  handleDelete = null
}: {
  title: string;
  handleClose: () => void;
  handleDelete: () => void;
}) => {
  return (
    <>
      <Flex
        height="72px"
        justifyContent="center"
        paddingX="48px"
        paddingTop="19px"
        paddingBottom="18.5px"
        alignItems="center"
      >
        {handleDelete ? (
          <button
            style={{ position: 'absolute', left: '45px', padding: '0', border: 'none', background: 'none' }}
            onClick={handleDelete}
          >
            <Text color="info" variant="subtitle2">
              Delete
            </Text>
          </button>
        ) : null}

        <Text variant="h4">{title}</Text>
        <CloseIcon
          onClick={handleClose}
          style={{ position: 'absolute', left: '700px', cursor: 'pointer', width: '24px', height: '24px' }}
        ></CloseIcon>
      </Flex>
      <Divider />
    </>
  );
};

export const AdjustmentSuccessMessage = ({ message, onClick }: { message: string; onClick: () => any }) => {
  const { searchParams } = useAppSelector((state) => state.app.queryParams);
  const { hashId } = useAppSelector((state) => state.user.config.vendor);
  const { id } = useAppSelector((state) => state.entityService.mainEntity);

  const path = window.location.pathname.split('/')[1];

  // Determine if the path is valid (meaning we can navigate safely to the adjustments tab with the current entity)
  const validPath = [
    EntityPathname.Client,
    EntityPathname.Product,
    EntityPathname.Category,
    EntityPathname.Subcategory,
    EntityPathname.Brand,
    EntityPathname.Segment
  ].find((potentialValidPath) => potentialValidPath.replaceAll('/', '') === path);

  // If we have a valid path, we can maintain the current entity otherwise we should redirect to the client entity before navigating to the adjustments tab
  const redirectionPathname = validPath ? `${validPath}${id}` : `${EntityPathname.Client}${hashId}`;

  return (
    <Flex gap="xs">
      <Text variant="subtitle2">{message}</Text>
      <Link
        onClick={onClick}
        to={`${redirectionPathname}${searchParams}&tab=${BEACON_TABS.FORECASTS}&subtab=${BEACON_SUBTABS.FORECAST_ADJUSTMENTS}`}
      >
        <Text variant="subtitle2" underlined>
          here.
        </Text>
      </Link>
    </Flex>
  );
};
