/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { withBus } from 'react-bus';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';

// import { PlusIcon } from 'src/components/SvgIcons';
import Tabs from 'src/components/common/Tabs/Tabs';
import colors from 'src/utils/colors';
import { withPush } from 'src/utils/hoc';
import { compose } from 'redux';
import { PushFn } from 'src/types/application/types';
import { WidgetProps } from 'src/types/application/widgetTypes';
import { EventBus } from 'src/types/utils';
import { CreatePortfolioMain } from 'src/components/AdCampaignBuilder/Widgets/CreatePortfolio';
import { store } from 'src/main';
import _get from 'lodash/get';

const styles: { [key: string]: React.CSSProperties } = {
  title: { fontSize: 31, fontWeight: 400 },
  headerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: { width: 20, height: 20 },
  tabs: { borderBottom: `1px solid ${colors.lightestGrey}` },
  tab: { padding: '13px 0px 8px 0px', fontSize: 18 },
  plusIcon: { width: '35px', height: '35px', marginLeft: '20px', verticalAlign: 'middle', cursor: 'pointer' }
};

const SummaryHeader: React.FC<WidgetProps & { push: PushFn; eventBus: EventBus }> = ({
  push,
  widget: {
    view: {
      summaryHeader: { headerTabOpts }
    }
  },
  eventBus
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const currentSelect = searchParams.get('selectedEntityName');
  const [createPortfolioDialogOpen, setCreatePortfolioDialogOpen] = useState(false);
  const { user } = store.getState();
  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);

  useEffect(() => {
    const openCreatePortfolioDialog = () => setCreatePortfolioDialogOpen(true);
    eventBus.on('openCreatePortfolioDialog', openCreatePortfolioDialog);

    return () => eventBus.off('openCreatePortfolioDialog', openCreatePortfolioDialog);
  });

  const activeTabIx = headerTabOpts.findIndex((item: { name: string }) => item.name === currentSelect);
  const handleTabChange = useCallback(
    (tabIndex: number) => {
      // set selected tab to url
      const { name } = headerTabOpts[tabIndex];
      searchParams.set('selectedEntityName', name);
      const newParams = searchParams.toString();
      push(`?${newParams}`);
    },
    [headerTabOpts, push, searchParams]
  );

  const { eventName, eventArg, tooltipText } = headerTabOpts[activeTabIx || 0];
  return (
    <div style={{ marginBottom: 40, position: 'relative' }}>
      <div>
        <Tabs
          value={activeTabIx >= 0 ? activeTabIx : 1}
          tabs={headerTabOpts}
          tabStyle={styles.tab}
          tabRootStyle={styles.tabs}
          onTabChange={(_event, tabIndex) => handleTabChange(tabIndex)}
        />
      </div>
      {!isAdAuditUser && tooltipText && (
        <div style={{ bottom: 2, right: 0, position: 'absolute' }}>
          <Tooltip title={tooltipText} placement="left">
            <IconButton
              aria-label="create_entity"
              onClick={async () => {
                eventBus.emit(eventName, eventArg);
              }}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}

      <CreatePortfolioMain open={createPortfolioDialogOpen} onClose={() => setCreatePortfolioDialogOpen(false)} />
    </div>
  );
};

const EnhancedSummaryHeader = compose(withBus('eventBus'), withPush)(SummaryHeader);

export default EnhancedSummaryHeader;
