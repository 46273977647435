import { useQuery } from 'react-query';
import { useAppSelector } from 'src/utils/Hooks';
import axios from 'axios';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { useAdvancedSearchRequestBuilder } from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import { getAppName } from 'src/utils/app';
import { OriginalProjectionAdvancedSearchResponse } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import { useLatestForecastModel } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';

const UNADJUSTED_FIELDS = [
  ['Organic Traffic', 'organicTraffic', 'sum'],
  ['Other Traffic', 'otherTraffic', 'sum'],
  ['Retail Sales', 'retailSales', 'sum'],
  ['Units Sold', 'unitsSold', 'sum'],
  ['Weighted Content Score By Count', 'weightedContentScoreByContentScoreCount', 'sum'],
  ['Content Score Count', 'contentScoreCount', 'sum'],
  ['Weighted In-Stock Rate by Units', 'weightedInStockRateByUnits', 'sum'],
  ['Weighted Buy Box Win Rate by Units', 'weightedBuyBoxWinRateByUnits', 'sum'],
  ['Weighted Rating By Rating Count', 'weightedRatingByRatingCount', 'sum'],
  ['Rating Count', 'ratingCount', 'sum'],
  ['Incremental Reviews Final', 'incrementalReviewsFinal', 'sum'],
  ['Video Score', 'videoScore', 'avg'],
  ['A+ Score', 'aplusScore', 'avg'],
  ['Bullet Score', 'bulletScore', 'avg'],
  ['Image Score', 'imageScore', 'avg'],
  ['Title Score', 'titleScore', 'avg'],
  ['Paid Traffic', 'paidTrafficValue', 'sum'],
  ['Ad Spend', 'adSpend', 'sum'],
  ['Cost per Click', 'costPerClick', 'avg'],
  ['Ad Sales', 'adSales', 'sum'],
  ['Return on Ad Spend', 'roas', 'avg']
];

interface UseOriginalProjectionProps {
  stacklineSku: string | number;
  startWeekId: string;
  endWeekId: string;
}
export const useOriginalProjection = ({
  stacklineSku,
  startWeekId = null,
  endWeekId = null
}: UseOriginalProjectionProps) => {
  const retailerId = useAppSelector((state) => state.retailer.id);
  const appName = getAppName();
  const { modelVersion, currentPublishVersion } = useLatestForecastModel();
  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);
  const { categoryIds } = mainEntity;

  const requestId = 'original-projection-unadjusted-forecast';
  const originalProjectionAdvancedSearchBuilder = useAdvancedSearchRequestBuilder(
    requestId,
    'beacon-unadjusted-forecast'
  );

  const aggregationBuilder = new AggregationBuilder('retailerId');
  aggregationBuilder.addConditionTermFilter('retailerId', [retailerId]);

  UNADJUSTED_FIELDS.forEach(([aggregateByFieldDisplayName, aggregateByFieldName, func, formula]) => {
    aggregationBuilder.addAggregationField(aggregateByFieldDisplayName, aggregateByFieldName, func, true, formula);
  });

  originalProjectionAdvancedSearchBuilder
    .setPageSize(1200)
    .setPeriod('year')
    .setSearchBy('parent')
    .setDoAggregation(true)
    .setReturnDocuments(false)
    .addConditionTermFilter('categoryId', 'should', categoryIds)
    .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
    .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
    .addConditionTermFilter('stacklineSku', 'should', [stacklineSku])
    .addAggregation(aggregationBuilder.build())
    .addSortField('Total Traffic', 'totalTraffic', 'sum', true)
    .addConditionRangeFilter('forecastWeekId', Number(startWeekId), Number(endWeekId));

  const originalProjectionRequest = [originalProjectionAdvancedSearchBuilder.build()];
  const query = useQuery(
    [stacklineSku, startWeekId, endWeekId],
    async () => {
      const response = await axios.post(`api/${appName}/AdvancedSearch?_id=${requestId}`, originalProjectionRequest);
      return response.data;
    },
    {
      retry: 0,
      staleTime: 0,
      refetchOnWindowFocus: false
    }
  );

  return {
    data: query.data as OriginalProjectionAdvancedSearchResponse[],
    isLoading: query.isLoading,
    isError: query.isError
  };
};
