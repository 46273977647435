import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { ChevronIcon } from '../SvgIcons';
import './ViewDetails.scss';

class ViewDetails extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  handleClick = (url) => {
    this.context.router.history.push(`${url}`);
  };

  render() {
    const { url } = this.props;
    return (
      <Tooltip title="View Details" placement="top">
        <IconButton
          className="view-details"
          onClick={() => this.handleClick(url)}
          style={{ display: 'inline-block', verticalAlign: 'middle', padding: '0', height: '30px', width: '30px' }}
          size="large">
          <ChevronIcon className="view-details__icon" />
        </IconButton>
      </Tooltip>
    );
  }
}

ViewDetails.propTypes = {
  url: PropTypes.string.isRequired
};

export default ViewDetails;
