import { StacklineTheme, useStacklineTheme } from '@stackline/ui';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import HoverTooltip from 'src/components/BeaconRedesignComponents/HoverTooltip/HoverTooltip';
import { useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import Box from '@mui/material/Box';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

interface DonutChartV2Datum {
  fieldId: string;
  name: string;
  y: number;
  percentage?: number;
  color?: string;
}

// based on the data length, assign colors to each data -- currently only 4 colors are supported
const assignColors = (data: DonutChartV2Datum[], theme: StacklineTheme, colorsToUse?: string[]) => {
  const colors = colorsToUse || [
    theme.colors.primaryLight,
    theme.colors.success,
    theme.colors.accentTeal,
    theme.colors.accentPine
  ];

  return data.map((item, index) => {
    if (item.fieldId === 'Unearned') {
      return { ...item, color: theme.colors.secondaryGhost };
    }

    return { ...item, color: item.color || (index < colors.length ? colors[index] : theme.colors.secondaryGhost) };
  });
};

const DonutChartV2 = ({
  getData,
  title,
  size = 308,
  onSelect,
  sortByGetDataOrder = false,
  tooltipWrapperStyle = {},
  colors
}: {
  getData: DonutChartV2Datum[];
  title: React.ReactNode;
  size?: number;
  onSelect?: Highcharts.PointSelectCallbackFunction;
  sortByGetDataOrder?: boolean;
  tooltipWrapperStyle?: React.CSSProperties;
  colors?: string[];
}) => {
  const theme = useStacklineTheme();
  const formatMetric = useMetricFormatter();

  const data = getData;
  const totalSum = data.reduce((sum, item) => sum + item.y, 0);

  const formattedData = data.map((item) => ({
    ...item,
    percentage: item.y / totalSum
  }));

  // If there is only one or 2 available the chart section direction should go clockwise,
  // else it goes to counter clockwise unless 'sortByGetDataOrder' is true

  const sortedDataSeries = () => {
    if (sortByGetDataOrder) {
      return assignColors(formattedData, theme, colors);
    }

    if (data.length < 3) {
      return assignColors(formattedData, theme, colors).sort((a, b) => b.y - a.y);
    } else {
      return assignColors(formattedData, theme, colors).sort((a, b) => a.y - b.y);
    }
  };

  const options: Highcharts.Options = {
    tooltip: {
      enabled: true,
      shared: true,
      shadow: false,
      outside: true,
      backgroundColor: 'transparent',
      borderColor: 'transparent',

      useHTML: true,
      formatter() {
        const { key, color, percentage } = this;
        // no key means => white area where the data is blank.
        if (!key) {
          return false;
        }

        return ReactDOMServer.renderToStaticMarkup(
          <HoverTooltip
            theme={theme}
            title={String(key)}
            primaryColor={color}
            primaryMetric={String(formatMetric(percentage / 100, METRICTYPE.PERCENT, { decimalPlaces: 1 }))}
            tooltipWrapperStyle={{
              maxWidth: '91px',
              whiteSpace: 'normal',
              ...tooltipWrapperStyle
            }}
          />
        );
      }
    },
    title: {
      text: ''
    },
    chart: {
      type: 'pie',
      height: size,
      width: size,
      margin: 0
    },

    plotOptions: {
      pie: {
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          backgroundColor: 'transparent',
          connectorWidth: 0
        },
        borderWidth: 0,
        slicedOffset: 0,
        startAngle: 0,
        endAngle: 360,
        center: ['50%', '50%'],
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0
            }
          }
        }
      },

      series: {
        allowPointSelect: true,
        point: {
          events: {
            select: onSelect,
            unselect: onSelect
          }
        },
        states: {
          hover: {
            enabled: true
          }
        }
      }
    },
    credits: { enabled: false },
    series: [
      {
        type: 'pie',
        name: 'Donut Chart',
        // default highcharts starts from angle 0 deg and goes to clocks wise direction.
        data: sortedDataSeries(),
        innerSize: '85%'
      }
    ]
  };

  return (
    <Flex height={size} width={size} gap="xs" alignItems="center" justifyContent="center" position="relative">
      <Box position="absolute" zIndex={0}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Box>
      <Box zIndex={1}>
        {typeof title === 'string' ? (
          <Text
            sx={{
              fontSize: '48px',
              fontWeight: 'bold'
            }}
          >
            {title}
          </Text>
        ) : (
          title
        )}
      </Box>
    </Flex>
  );
};

export default DonutChartV2;
