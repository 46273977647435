import axios from 'axios';
import { OmniBaseRequestBody } from 'src/components/Omni/omniRequestUtils';
import { OmniContentColumnData } from 'src/components/Omni/OmniContentColumn/OmniContentColumn';
import _get from 'lodash/get';

const contentScoreEndpoint = '/omni/content/getAvgContentScore';
interface ScoreData {
  value: number | null;
}

interface ScoreAverageData {
  aplusScore_avg: ScoreData;
  bulletScore_avg: ScoreData;
  contentScore_avg: ScoreData;
  contentScore_value_count: ScoreData;
  descriptionScore_avg: ScoreData;
  detailsScore_avg: ScoreData;
  directionsScore_avg: ScoreData;
  doc_count: number;
  imageScore_avg: ScoreData;
  ingredientsScore_avg: ScoreData;
  key: string;
  nutritionFactsScore_avg: ScoreData;
  titleScore_avg: ScoreData;
  videoScore_avg: ScoreData;
  warningsScore_avg: ScoreData;
}

interface InstaCartObject {
  descriptionScore_avg__sum: number;
}

const scoreTitleSwitch = (retailerId: number) => {
  switch (retailerId) {
    case 63: // InstaCart
      return {
        titleScore_avg: 'Title',
        imageScore_avg: 'Image',
        descriptionScore_avg: 'Description',
        healthScore_avg: 'Label'
      };

    default:
      return {
        titleScore_avg: 'Title',
        descriptionScore_avg: 'Description',
        bulletScore_avg: 'Bullet',
        imageScore_avg: 'Image',
        videoScore_avg: 'Video',
        enhancedScore_avg: 'Enhanced',
        healthScore_avg: 'Label'
      };
  }
};

const scoreTitleBuilder = (retailerIds: number[] = []) => {
  let scoreTitles = {};

  retailerIds.forEach((rId) => {
    scoreTitles = { ...scoreTitles, ...scoreTitleSwitch(rId) };
  });

  return scoreTitles;
};

/**
 * Process Raw data into column data for bar chart
 * Backend will return each retailer's data, so we have to average it out what is necessary in frontend.

 */
const processData = (data: ScoreAverageData[], retailerIds: number[]): OmniContentColumnData[] => {
  // Based on selected retailer(can be multiple), we will customize which score display for column data.

  const mapping = new Map(Object.entries(scoreTitleBuilder(retailerIds)));

  const precessedDataRes: OmniContentColumnData[] = [];
  const rawData = _get(data, 'retailerId.buckets', []);

  // temp object to store sum of each metric, and counter
  const tempAverageObject: { [key: string]: number | null } = {};

  // we define Instacart here, so we don't have to make additional loop for finding instaCart for replacing description score.
  // const instaCartObject: InstaCartObject = { descriptionScore_avg__sum: 0 };

  rawData.forEach((r: ScoreAverageData) => {
    // if (r.key === '63') {
    //   instaCartObject.descriptionScore_avg__sum = r.descriptionScore_avg.value;
    // }
    const keyMap: string[] = Object.keys(r);

    keyMap.forEach((key: string) => {
      // <mapping> will filter out unnecessary field from backend.
      // if the value is null, which means the target retailer won't hve the metric.

      if (mapping.has(key) && r[key].value !== null) {
        if (`${key}__count` in tempAverageObject) {
          tempAverageObject[`${key}__sum`] += r[key].value;
          tempAverageObject[`${key}__count`] += 1;
        } else {
          tempAverageObject[`${key}__sum`] = r[key].value;
          tempAverageObject[`${key}__count`] = 1;
        }
      }
    });
  });

  // (Many of retailer already have description score data, but we have to ignore most of it, since we are not displaying it within the UI )
  // As of 2/7/2023 Instacart is the only retailer uses description score data. overwrite calculated description_avg data with instacart(rId: 63)

  // if (retailerIds.includes(63)) {
  //   tempAverageObject.descriptionScore_avg__sum = instaCartObject.descriptionScore_avg__sum;
  //   tempAverageObject.descriptionScore_avg__count = 1;
  // }

  const tempAverageObjectKeys = Object.keys(tempAverageObject);

  tempAverageObjectKeys.forEach((dataKey) => {
    const originalKeyName = dataKey.split('__')[0];

    const printName = mapping.get(originalKeyName);

    const averageValue = tempAverageObject[`${originalKeyName}__sum`] / tempAverageObject[`${originalKeyName}__count`];

    // prevent duplication of same key data
    if (dataKey.endsWith('__count')) {
      precessedDataRes.push({
        fieldValue: String(printName),
        fieldId: String(printName),
        y: averageValue,
        selected: false
      });
    }
  });

  return precessedDataRes;
};

export const omniContentScoreAsync =
  (requestBody: OmniBaseRequestBody, defaultRetailers) => async (): Promise<OmniContentColumnData[]> => {
    const { retailerIds } = requestBody;

    // even though, the filter does not select any retailers, we would like to mimic it has all retailers are selected
    const copiedRetailerIds = [...defaultRetailers];
    let selectAllRetailers = [];

    if (!retailerIds) {
      selectAllRetailers = copiedRetailerIds.map((r) => r.retailerId);
    }

    const res = await axios.post(contentScoreEndpoint, requestBody);
    if (res.status === 200) {
      const { data } = res.data;
      return processData(data, retailerIds || selectAllRetailers);
    } else {
      throw Error('Can not fetch data');
    }
  };
