/* eslint-disable react/prop-types */
import React from 'react';
import Button from '@mui/lab/LoadingButton';
import { PropTypesOf } from 'src/types/utils';

const getButtonStyleName = (disabled: boolean, secondary: boolean, black: boolean) => {
  if (black) {
    return 'black';
  } else if (disabled) {
    return 'disabled';
  } else if (secondary) {
    return 'secondary';
  } else {
    return 'primary';
  }
};

const getClassNames = (disabled: boolean, secondary: boolean, black: boolean, classOverride: string) => {
  if (classOverride !== 'none') {
    return classOverride;
  }

  const name = getButtonStyleName(disabled, secondary, black);
  return `sl-${name}-button sl-${name}-button--lg`;
};

interface LargeMuiButtonProps {
  onClick?: () => void;
  secondary?: boolean;
  label?: React.ReactNode;
  black?: boolean;
  disabled?: boolean;
  classOverride?: string;
}

const LargeMuiButton: React.FC<LargeMuiButtonProps & PropTypesOf<typeof Button>> = ({
  onClick,
  secondary = false,
  children,
  label,
  black = false,
  classOverride = 'none',
  ...props
}) => (
  <Button
    className={getClassNames(props.disabled || false, secondary, black, classOverride)}
    onClick={onClick}
    {...props}
  >
    {label || children || ''}
  </Button>
);

export default LargeMuiButton;
