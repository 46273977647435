import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { AppName } from 'sl-api-connector/types';
import NumberFormat from 'react-number-format';
import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';
import _orderBy from 'lodash/orderBy';
import _uniq from 'lodash/uniq';
import _get from 'lodash/get';

import { getEmptyFormData } from 'src/utils/segments';
import { AdManagerEvents } from 'src/types/application/event';
import ReduxStore from 'src/types/store/reduxStore';
import _upperFirst from 'lodash/upperFirst';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import CampaignColumn from 'src/components/AdManager/Search/CustomColumns/EntityColumn';
import DataColumn from 'src/components/AdManager/Search/CustomColumns/DataColumn';
import { useBus } from 'src/utils/Hooks';
import { withBus } from 'react-bus';
import { store } from 'src/main';
import { getCampaignDisplayStatus } from 'src/components/AdManager/AmsUtils';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { AdCampaignManagerServiceClient } from 'src/utils/adCampaignManagerServiceClient';
import {
  buildAggregations,
  buildTimePeriodRangeFilters,
  zipMetricsResponseIntoArray
} from 'src/components/AdManager/Search';
import { AD_CAMPAIGN_STATUS, AD_STATUS_DERIVED } from 'sl-ad-campaign-manager-data-model';
import CustomAgGridHeaderTemplate from 'src/components/EntityGrid/Table/CustomAgGridHeaderTemplate';
import { splitSnakeCase } from 'src/utils/stringFormatting';
import EntityStatusColumn from 'src/components/AdManager/Search/CustomColumns/EntityStatusColumn';
import { getFieldDisplayName } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';
import { getUserColumns } from 'src/routes/UserAccount/UserCustomColumn';
import Waypoint from 'react-waypoint';
import { shouldFilterOutBlankCampaigns } from './existingTargetsUtils';

const style = {
  button: {
    width: 99
  },
  textInput: {
    height: '32px',
    outline: 'none',
    border: 'none',
    borderRadius: '5px',
    width: '80px',
    padding: 10,
    fontSize: '14px',
    color: 'currentColor',
    background: '#eff1f5',
    fontFamily: 'Roboto'
  }
};

const onGridReady = (params: any) => {
  params.api.sizeColumnsToFit();
  params.api.refreshHeader();
};

const isRangeFilterNotEqual = (
  rangeFilter1: { minValue?: number; maxValue?: number },
  rangeFilter2: { minValue?: number; maxValue?: number }
) => {
  return (
    `${rangeFilter1.minValue}` !== `${rangeFilter2.minValue}` ||
    `${rangeFilter1.maxValue}` !== `${rangeFilter2.maxValue}`
  );
};

const buildTermFilterCondition = ({
  idFieldName,
  filterValues,
  condition
}: {
  idFieldName: string;
  filterValues: { i: string; n?: string }[];
  condition?: 'should' | 'must' | 'must_not';
}) => {
  const termFilters = [];
  if (filterValues.length > 0) {
    termFilters.push({
      fieldName: idFieldName,
      values: filterValues.map((value) => value.i),
      condition
    });
  }
  return termFilters;
};

const terms = [
  { filterName: 'entityId', fieldName: 'entityId' },
  { filterName: 'portfolioId', fieldName: 'portfolioId' },
  { filterName: 'campaignId', fieldName: 'campaignId' },
  { filterName: 'campaignType', fieldName: 'campaignType' },
  { filterName: 'targetingType', fieldName: 'targetingType' },
  { filterName: 'targetingText', fieldName: 'targetingText' },
  { filterName: 'targetingTextFuzzy', fieldName: 'targetingTextFuzzy' },
  { filterName: 'excludedTargetingText', fieldName: 'excludedTargetingText' },
  { filterName: 'excludedTargetingTextFuzzy', fieldName: 'excludedTargetingTextFuzzy' },
  { filterName: 'targetState', fieldName: 'status' },
  { filterName: 'isAutoAdded', fieldName: 'isAutoAdded' }
];

const ranges = [
  { filterName: 'spendComputed', fieldName: 'spendComputed' },
  { filterName: 'costPerClick', fieldName: 'costPerClick' },
  { filterName: 'returnOnAdSpend', fieldName: 'returnOnAdSpend' },
  { filterName: 'bid', fieldName: 'cpcMinBidAmount' }
];

const getMinBid = (data, adPlatformSettings) => {
  const campaignType = _get(data, ['entity', 'extendedAttributes', 'childDocuments', 0, 'campaignType'], null);
  const match = adPlatformSettings.find((set) => _get(set, ['extendedAttributes', 'campaignType']) === campaignType);

  if (match) {
    return _get(match, ['extendedAttributes', 'targetLimits', 'minimumBid'], 0.1);
  }

  // fallback logic
  return campaignType === 'sponsoredBrands' ? 0.1 : 0.02;
};

const getMaxBid = (data, adPlatformSettings) => {
  const campaignType = _get(data, ['entity', 'extendedAttributes', 'childDocuments', 0, 'campaignType'], null);
  const match = adPlatformSettings.find((set) => _get(set, ['extendedAttributes', 'campaignType']) === campaignType);

  if (match) {
    return _get(match, ['extendedAttributes', 'targetLimits', 'maximumBid'], 0.1);
  }

  // fallback logic
  return campaignType === 'sponsoredBrands' ? 49 : 1000;
};

const mapStateToPropsForColumn = (state: ReduxStore) => {
  const { retailer, adPlatformSettings, user } = state;
  return {
    retailer,
    adPlatformSettings,
    user
  };
};

const EditBitColumn = connect(mapStateToPropsForColumn)(
  withBus('eventBus')(({ eventBus, value, data, retailer, adPlatformSettings, user }) => {
    const hideDecimals = ['16'].includes(retailer.id.toString());
    const apiLimits = adPlatformSettings.filter((set) => set.settingType === 'apiLimit');
    const minBid = getMinBid(data, apiLimits);
    const maxBid = getMaxBid(data, apiLimits);
    const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);
    return (
      <NumberFormat
        value={value}
        isAllowed={({ floatValue }) => {
          return floatValue >= minBid && floatValue <= maxBid; // Amazon's rule, all bid price must be greater or equal to $0.02
        }}
        thousandSeparator
        prefix={retailer.currencySymbol}
        decimalScale={hideDecimals ? 0 : 2}
        fixedDecimalScale
        allowNegative={false}
        onValueChange={({ floatValue }) =>
          eventBus.emit('handleCampaignBidPriceChange', { key: data.id, value: floatValue })
        }
        // Mimic the style of the MUI `TextInput`
        style={style.textInput}
        disabled={isAdAuditUser}
      />
    );
  })
);

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, ['app', 'retailer', 'mainTimePeriod', 'comparisonTimePeriod', 'adPlatformSettings', 'user']),
    mainEntity: state.entityService.mainEntity
  };
};

const ExistingTargets: React.FC<ReturnType<typeof mapStateToProps> & { adGroupId: any; eventBus: any }> = ({
  eventBus,
  app,
  retailer,
  mainTimePeriod,
  comparisonTimePeriod,
  mainEntity,
  adGroupId,
  adPlatformSettings,
  user
}) => {
  const userColumns = getUserColumns();
  const [isLoading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [submitStatus, setSubmitStatus] = useState({ status: 'normal' });
  const [dataSet, setDataSet] = useState([]);
  const [formData, setFormData] = useState({});
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortFieldName, setSortFieldName] = useState(userColumns[0]);
  const [bidMapping, setBidMapping] = useState(new Map());
  const pageSize = 150;
  const hasMoreRows = useRef(false);
  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);

  const handleBidChange = ({ key, value }: { key: string; value: any }) => {
    // bidMapping.set(id, value);
    const newBidMapping = new Map(bidMapping);
    newBidMapping.set(key, value);
    setBidMapping(newBidMapping);
  };

  const apiLimits = adPlatformSettings.filter((set) => set.settingType === 'apiLimit');

  const handleBulkBidChange = ({ type, value }: { type: string; value: any }) => {
    if (type === 'increaseMoney') {
      const newDataSet = _cloneDeep(dataSet);
      newDataSet.forEach((dataPoint) => {
        const originalBid = _get(dataPoint, ['entity', 'extendedAttributes', 'childDocuments', 0, 'cpcMaxBidAmount']);
        let newBid = originalBid + (value || 0);
        const minBid = getMinBid(dataPoint, apiLimits);
        const maxBid = getMaxBid(dataPoint, apiLimits);
        newBid = newBid > maxBid ? maxBid : newBid < minBid ? minBid : newBid;
        dataPoint.bid = newBid.toFixed(2);
      });
      setBidMapping(new Map());
      setDataSet(newDataSet);
    } else if (type === 'increasePercent') {
      const newDataSet = _cloneDeep(dataSet);
      newDataSet.forEach((dataPoint) => {
        const originalBid = _get(dataPoint, ['entity', 'extendedAttributes', 'childDocuments', 0, 'cpcMaxBidAmount']);
        let newBid = originalBid * (1 + (value || 0) * 0.01);
        const minBid = getMinBid(dataPoint, apiLimits);
        const maxBid = getMaxBid(dataPoint, apiLimits);
        newBid = newBid > maxBid ? maxBid : newBid < minBid ? minBid : newBid;
        dataPoint.bid = newBid.toFixed(2);
      });
      setBidMapping(new Map());
      setDataSet(newDataSet);
    } else if (type === 'setMoney') {
      const newDataSet = _cloneDeep(dataSet);
      newDataSet.forEach((dataPoint) => {
        const originalBid = _get(dataPoint, ['entity', 'extendedAttributes', 'childDocuments', 0, 'cpcMaxBidAmount']);
        let newBid = value || value === 0 ? value : originalBid;
        const minBid = getMinBid(dataPoint, apiLimits);
        const maxBid = getMaxBid(dataPoint, apiLimits);
        newBid = newBid > maxBid ? maxBid : newBid < minBid ? minBid : newBid;
        dataPoint.bid = newBid.toFixed(2);
      });
      setBidMapping(new Map());
      setDataSet(newDataSet);
    } else if (type === 'decreaseMoney') {
      const newDataSet = _cloneDeep(dataSet);
      newDataSet.forEach((dataPoint) => {
        const originalBid = _get(dataPoint, ['entity', 'extendedAttributes', 'childDocuments', 0, 'cpcMaxBidAmount']);
        let newBid = originalBid - (value || 0);
        const minBid = getMinBid(dataPoint, apiLimits);
        const maxBid = getMaxBid(dataPoint, apiLimits);
        newBid = newBid > maxBid ? maxBid : newBid < minBid ? minBid : newBid;
        dataPoint.bid = newBid.toFixed(2);
      });
      setBidMapping(new Map());
      setDataSet(newDataSet);
    } else if (type === 'decreasePercent') {
      const newDataSet = _cloneDeep(dataSet);
      newDataSet.forEach((dataPoint) => {
        const originalBid = _get(dataPoint, ['entity', 'extendedAttributes', 'childDocuments', 0, 'cpcMaxBidAmount']);
        let newBid = originalBid * (1 - (value || 0) * 0.01);
        const minBid = getMinBid(dataPoint, apiLimits);
        const maxBid = getMaxBid(dataPoint, apiLimits);
        newBid = newBid > maxBid ? maxBid : newBid < minBid ? minBid : newBid;
        dataPoint.bid = newBid.toFixed(2);
      });
      setBidMapping(new Map());
      setDataSet(newDataSet);
    }
  };

  useEffect(() => {
    eventBus.emit('targetPageButtonStatus', submitStatus);
  }, [eventBus, submitStatus]);

  const submit = async () => {
    setSubmitStatus({ status: 'submitting' });
    const entitiesSelected = dataSet
      .filter((dataPoint: any) => {
        const originalBid = +dataPoint.entity.extendedAttributes.childDocuments[0].cpcMaxBidAmount;
        let newBid = dataPoint.bid;
        if (
          bidMapping.has(dataPoint.id) &&
          bidMapping.get(dataPoint.id) &&
          bidMapping.get(dataPoint.id) !== dataPoint.bid
        ) {
          newBid = bidMapping.get(dataPoint.id);
        }
        dataPoint.entity.extendedAttributes.childDocuments[0].cpcMaxBidAmount = newBid;
        dataPoint.entity.extendedAttributes.childDocuments[0].cpcMinBidAmount = newBid;
        return `${newBid}` !== `${originalBid}`;
      })
      .map(({ entity }) => entity);
    if (entitiesSelected && entitiesSelected.length > 0) {
      const { beaconClientId } = entitiesSelected.filter((x: any) => x.beaconClientId)[0];
      const { platformType } = entitiesSelected.filter((x: any) => x.platformType)[0];
      try {
        const res = await AdCampaignManagerServiceClient.updateTargetBid({
          beaconClientId,
          platformType,
          entities: entitiesSelected,
          entityType: entitiesSelected[0].type
        });
        if (res) {
          if (res.status === 200) {
            setSubmitStatus({ status: 'submitted', result: res });
          } else {
            setSubmitStatus({ status: 'failure', result: res });
          }
        } else {
          setSubmitStatus({ status: 'failure', result: res });
        }
      } catch (err) {
        setSubmitStatus({ status: 'failure' });
      }
    }
  };

  const sideBarFilterUpdated = ({ formData: newFormData }: { formData: any }) => {
    setFormData(newFormData);
  };

  useBus(eventBus, 'handleCampaignBidPriceChange', handleBidChange);
  useBus(eventBus, 'bulkApplyBidPrice', handleBulkBidChange);
  useBus(eventBus, 'submitExistingTarget', submit);
  useBus(eventBus, AdManagerEvents.ENTITY_EVENT_METRICS_GRID_FILTER_UPDATED, sideBarFilterUpdated);

  const handleSortClick = (field: string) => {
    let newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    if (sortFieldName !== field) {
      newSortDirection = 'desc';
    }
    // @ts-ignore
    setSortDirection(newSortDirection);
    setSortFieldName(field);
  };

  const userColumnsDef = userColumns.map((field: string) => ({
    headerName: getFieldDisplayName(field),
    cellRendererFramework: DataColumn,
    cellStyle: { 'justify-content': 'flex-end', 'text-align': 'right', 'padding-right': '20px' },
    field,
    disableSort: false,
    sortDirection,
    sortByMetric: sortFieldName,
    headerComponentFramework: CustomAgGridHeaderTemplate,
    onSortFunction: () => {
      handleSortClick(field);
    },
    minWidth: 100,
    maxWidth: 200
  }));

  const columnDef = [
    {
      headerName: 'Target',
      field: 'targetingText',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 300,
      maxWidth: 800,
      cellRendererFramework: CampaignColumn,
      headerClass: 'align-left target',
      pinned: 'left'
    },
    {
      headerName: 'Match',
      field: 'entity',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 150,
      maxWidth: 150,
      valueFormatter: ({ value }) => _upperFirst(splitSnakeCase(value.targetingType.toLowerCase())),
      headerClass: 'align-left match',
      pinned: 'left'
    },
    {
      headerName: 'Bid',
      cellRendererFramework: EditBitColumn,
      field: 'bid',
      minWidth: 110,
      maxWidth: 110,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left',
      disableSort: false,
      sortDirection,
      sortByMetric: sortFieldName,
      headerComponentFramework: CustomAgGridHeaderTemplate,
      onSortFunction: () => {
        handleSortClick('bid');
      },
      pinned: 'left'
    },
    ...userColumnsDef,
    ...(isAdAuditUser
      ? []
      : [
          {
            headerName: 'Status',
            field: 'targetingText',
            width: undefined,
            enableRtl: true,
            minWidth: 100,
            maxWidth: 100,
            cellRendererFramework: EntityStatusColumn,
            headerClass: 'status',
            pinned: 'right'
          }
        ])
  ];

  const fetchData = async () => {
    if (pageNumber === 1) {
      setLoading(true);
    }
    const indexName = 'adCampaignAdGroupTargetDailyMetrics';
    const statePropertyName = `existingTargetingForCampaign_${mainEntity.id}`;
    const widget = {
      view: {
        gridOptions: { pageSize }
      },
      data: { statePropertyName }
    };
    const initAggregationFields = userColumns.map((fieldName) => INDEX_FIELDS.getField(app.name, indexName, fieldName));

    const [{ aggregations: aggregationFields }] = buildAggregations(initAggregationFields);
    const { mainTimePeriodRangeFilters, comparisonTimePeriodRangeFilters } = buildTimePeriodRangeFilters({
      app,
      indexName,
      mainTimePeriod,
      comparisonTimePeriod
    });
    const termFilters = [
      {
        fieldName: 'campaignId',
        values: [mainEntity.campaignId]
      },
      {
        fieldName: 'adGroupId',
        values: [adGroupId]
      }
    ];

    const searchSideBarConditions = {
      termFilters: [...termFilters],
      rangeFilters: [...mainTimePeriodRangeFilters]
    };

    terms.forEach(({ filterName, fieldName }: { filterName: string; fieldName: string }) => {
      if (_get(formData, ['termFilters', filterName])) {
        searchSideBarConditions.termFilters = [
          ...searchSideBarConditions.termFilters,
          ...buildTermFilterCondition({
            filterValues: formData.termFilters[filterName].values,
            condition: formData.termFilters[filterName].condition,
            idFieldName: fieldName
          })
        ];
      }
    });

    const aggregationFilters = {
      termFilters: [],
      rangeFilters: [],
      computeFilters: []
    };

    const emptyFormData = getEmptyFormData({ appName: AppName.Advertising });

    ranges.forEach(({ filterName, fieldName }: { filterName: string; fieldName: string }) => {
      if (_get(formData, ['rangeFilters', filterName])) {
        if (isRangeFilterNotEqual(emptyFormData.rangeFilters[filterName], formData.rangeFilters[filterName])) {
          if (filterName !== 'bid') {
            aggregationFilters.computeFilters = [
              ...aggregationFilters.computeFilters,
              {
                fieldName,
                minValue: formData.rangeFilters[filterName].minValue,
                maxValue: formData.rangeFilters[filterName].maxValue
              }
            ];
          }
        }
      }
    });

    const targetingGroupBy = retailer.id === '63' ? 'targetingText,isAutoAdded' : 'targetingText,targetingType';

    const searchRequestOverrides = [
      {
        doAggregation: true,
        aggregations: [
          {
            aggregationFields,
            conditions: {
              termFilters: [
                { fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] },
                {
                  fieldName: 'isProjected',
                  condition: 'must',
                  values: [0]
                }
              ],
              rangeFilters: [...mainTimePeriodRangeFilters],
              computeFilters: [...aggregationFilters.computeFilters]
            },
            sortDirection,
            sortByAggregationField: aggregationFields.find((x) => x.aggregateByFieldName === sortFieldName),
            comparisonRangeFilters: comparisonTimePeriodRangeFilters,
            groupByFieldName: targetingGroupBy
          }
        ],
        conditions: searchSideBarConditions,
        pageNumber,
        pageSize,
        processDocuments: false
      }
    ];

    // fetch target data.
    const result = await store.dispatch(
      fetchEntityMetrics(
        statePropertyName,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName,
          customResponseParser: (action: any) => zipMetricsResponseIntoArray(action, widget)
        },
        searchRequestOverrides,
        null,
        true
      )
    );
    hasMoreRows.current = _get(result, 'hasMoreRows', false);

    const cloneResult = result;

    const targetingTextValues: string[] = [];
    result.data.forEach((dataPoint: any) => {
      if (!targetingTextValues.includes(dataPoint.entity.id)) {
        targetingTextValues.push(dataPoint.entity.id);
      }
    });

    const queryConditions: {
      termFilters: { fieldName: string; condition: string; values: string[] }[];
      rangeFilters: { fieldName: string; minValue: number; maxValue: number }[];
    } = {
      termFilters: [],
      rangeFilters: []
    };

    if (_get(formData, ['rangeFilters', 'bid'])) {
      ranges.forEach((range) => {
        if (range.filterName === 'bid') {
          const { fieldName } = range;
          const { minValue, maxValue } = _get(formData, ['rangeFilters', 'bid']);
          queryConditions.rangeFilters = [
            {
              fieldName,
              minValue,
              maxValue
            }
          ];
        }
      });
    }

    const docTermFilters = _cloneDeep(searchSideBarConditions.termFilters);

    docTermFilters.forEach((term) => {
      if (term.fieldName === 'isAutoAdded') {
        term.fieldName = 'isAutoAddedBoolean';
        const values = [];
        if (term.values.includes('1')) {
          values.push(true);
        }
        if (term.values.includes('0')) {
          values.push(false);
        }
        term.values = values;
      }
    });
    const { documents: adTargetDocuments } = await store.dispatch(
      fetchEntityMetrics(
        `${statePropertyName}_Documents`,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName: 'adCampaignAdGroupTarget'
        },
        [
          {
            doAggregation: false,
            conditions: {
              termFilters: [...docTermFilters, ...queryConditions.termFilters],
              rangeFilters: [...queryConditions.rangeFilters] // put the bid filter in this array
            },
            pageNumber,
            pageSize,
            processDocuments: false,
            returnDocuments: true
          }
        ],
        null,
        true
      )
    );

    const adTargetDocumentsByTargetingText: any = {};
    const fullResult = [];
    adTargetDocuments.forEach((adTargetDocument: any) => {
      let targetIdToUse = adTargetDocument.targetingText + adTargetDocument.targetingType;
      if (retailer.id === '63') {
        targetIdToUse =
          adTargetDocument.targetingText +
          adTargetDocument.targetingType +
          (adTargetDocument.isAutoAddedBoolean ? '1' : '0');
      }
      if (!adTargetDocumentsByTargetingText[targetIdToUse]) {
        adTargetDocumentsByTargetingText[targetIdToUse] = {
          beaconClientId: adTargetDocument.beaconClientId,
          platformType: adTargetDocument.platformType,
          targetingText: adTargetDocument.targetingText,
          targetingType: adTargetDocument.targetingType,
          isAutoAdded: adTargetDocument.isAutoAddedBoolean ? '1' : '0',
          extendedAttributes: {
            statusReason: '',
            status: AD_CAMPAIGN_STATUS.PAUSED,
            statusDerived: AD_STATUS_DERIVED.PAUSED,
            childDocuments: []
          }
        };
      }
      if (adTargetDocument.status === AD_CAMPAIGN_STATUS.ENABLED) {
        adTargetDocumentsByTargetingText[targetIdToUse].extendedAttributes.status = AD_CAMPAIGN_STATUS.ENABLED;
      }
      if (adTargetDocument.statusDerived === AD_STATUS_DERIVED.DELIVERING) {
        adTargetDocumentsByTargetingText[targetIdToUse].extendedAttributes.statusDerived = AD_STATUS_DERIVED.DELIVERING;
      }
      adTargetDocumentsByTargetingText[targetIdToUse].extendedAttributes.childDocuments.push(adTargetDocument);

      adTargetDocumentsByTargetingText[targetIdToUse].extendedAttributes.campaignIds = _uniq(
        adTargetDocumentsByTargetingText[targetIdToUse].extendedAttributes.childDocuments.map(
          (doc: any) => doc.campaignId
        )
      );
      adTargetDocumentsByTargetingText[targetIdToUse].extendedAttributes.campaignIdsDelivering = _uniq(
        adTargetDocumentsByTargetingText[targetIdToUse].extendedAttributes.childDocuments
          .filter((x: any) => x.statusDerived === AD_STATUS_DERIVED.DELIVERING)
          .map((doc: any) => doc.campaignId)
      );

      adTargetDocumentsByTargetingText[targetIdToUse].extendedAttributes.statusDisplay = getCampaignDisplayStatus(
        adTargetDocumentsByTargetingText[targetIdToUse].extendedAttributes
      );
      const targetWithData = cloneResult.data.find((item: any) => item.id === targetIdToUse);
      const docEntity = adTargetDocumentsByTargetingText[targetIdToUse];
      const { targetingType } = docEntity;
      if (!targetingType.includes('negative')) {
        fullResult.push({
          ...targetWithData,
          id: targetIdToUse,
          entity: {
            nameFieldName: 'targetingText',
            keyFieldName: 'targetingText',
            name: docEntity.targetingText,
            id: docEntity.targetingText,
            type: 'adTarget',
            ...docEntity
          },
          bid: _get(docEntity, ['extendedAttributes', 'childDocuments', 0, 'cpcMaxBidAmount'])
        });
      }
    });
    // Not all filtering/sorting can be done entirely via API call, we must do it on the client side below
    // all of these filters/sorts happen with `cleanedAndOptionallySortedData`

    // if we are sorting by bid, sort them here
    let cleanedAndOptionallySortedData = [];

    cleanedAndOptionallySortedData = _cloneDeep(fullResult);
    if (sortFieldName === 'bid') {
      cleanedAndOptionallySortedData = _orderBy(cleanedAndOptionallySortedData, 'bid', sortDirection);
    } else {
      cleanedAndOptionallySortedData = _orderBy(
        cleanedAndOptionallySortedData,
        (e) => _get(e, [sortFieldName, 'value'], 0),
        sortDirection
      );
    }

    const filterOutBlankCampaigns = shouldFilterOutBlankCampaigns(formData as any);

    // since we cannot filter first call by bid, we filter on second call so we must filter out those
    // datapoints without a bid (meaning they did not fall within the range in the 2nd call)
    cleanedAndOptionallySortedData = cleanedAndOptionallySortedData.filter((dataPoint: any) => {
      /**
       * If filters are applied, then filter out the results that are not included in the filtered results
       */
      const includeWithFilterData = filterOutBlankCampaigns
        ? cloneResult.data.map(({ id }) => id).includes(dataPoint.id)
        : true;

      if (retailer.id === '63') {
        return !dataPoint.id.includes('browseImpressionsexact') && dataPoint.bid !== undefined && includeWithFilterData;
      }

      return dataPoint.bid !== undefined && includeWithFilterData;
    });

    eventBus.emit('setSearchHeader', {
      isLoading: false,
      entity: { result: cleanedAndOptionallySortedData }
    });
    setDataSet((prev) => {
      if (pageNumber === 1) {
        return cleanedAndOptionallySortedData;
      } else if (prev.length < pageNumber * pageSize) {
        return [...prev, ...cleanedAndOptionallySortedData];
      }
      return [];
    });
    setLoading(false);
  };

  const handleWaypointEnter = () => {
    if (hasMoreRows.current) {
      setPageNumber((prev) => prev + 1);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, mainEntity, mainTimePeriod, retailer.id, adGroupId, sortDirection, sortFieldName, pageNumber]);

  const renderGrid = () => {
    return (
      <div className="recommendations_grid" style={{ position: 'relative' }}>
        <CustomAgMaterial
          onGridReady={onGridReady}
          onCellValueChanged={onGridReady}
          onModelUpdated={onGridReady}
          onRowValueChanged={onGridReady}
          onRowDataChanged={onGridReady}
          buildRows={() => dataSet}
          suppressNoRowsOverlay
          columnDefs={columnDef}
          getRowNodeId={(datum: any) => escape(datum.id)}
          domLayout="autoHeight"
          rowHeight={120}
          containerStyle={{ width: '100%' }}
        />
        <Waypoint onEnter={handleWaypointEnter} />
      </div>
    );
  };

  return <div className="targeting_container">{isLoading ? <GridLoading /> : renderGrid()}</div>;
};

export default connect(mapStateToProps)(withBus('eventBus')(ExistingTargets));
