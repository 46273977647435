import axios from 'axios';
import {
  AdjustmentLog,
  UserMetaData
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ViewAdjustmentModal/types';
import { useAppSelector } from 'src/utils/Hooks';
import { useQueryClient } from 'react-query';
import {
  ADJUSTMENT_TABLE_QUERY,
  FORECAST_SUMMARY_KEYMETRIC_QUERY
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastQueryKeys';
import {
  AdjustmentPlanType,
  Projection
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/utils';
import { useEffect, useState } from 'react';
import {
  ADJUSTMENT_INTERVAL_DURATION_MS,
  ADJUSTMENT_POLL_DURATION_MS
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';

export const fetchUserMetaData = async ({ userIds }: { userIds: string[] }) => {
  const response = await axios.post('api/user/GetUserProfilesFromUserIds', userIds);
  return response;
};

interface FetchAdjustmentMetaData {
  adjustmentId: string;
  retailerSku: string;
  retailerId: number;
}

export const useFetchAdjustmentMetaData = () => {
  return async ({ adjustmentId, retailerSku, retailerId }: FetchAdjustmentMetaData) => {
    // Get logs for an adjustment ID
    const adjustmentLogEndpoint = `api/beaconforecast/GetAdjustmentLogForAdjustmentId?adjustmentId=${adjustmentId}&retailerId=${retailerId}&retailerSku=${retailerSku}`;

    let adjustmentLogs: AdjustmentLog[];
    try {
      const response = await axios.get(adjustmentLogEndpoint);
      adjustmentLogs = response.data;
    } catch (error) {
      console.error('Failed to fetch adjustment metadata.');
      return null;
    }

    // Get user metadata for each log
    let userMetaData: UserMetaData;
    try {
      const userIds = adjustmentLogs.map((log) => log.updatedByUserId);
      const response = await fetchUserMetaData({ userIds });
      userMetaData = response.data;
    } catch (error) {
      console.error('Failed to fetch user metadata.');
      return null;
    }

    return { adjustmentLogs, userMetaData };
  };
};

interface DeleteAdjustmentPayload {
  isDraft: boolean;
  beaconClientId: number;
  retailerId: string;
  retailerSku: string;
  adjustmentId: string;
  userId: string;
}

export interface DeleteAdjustmentParams {
  retailerSku: string;
  adjustmentId: string;
  isDraft: boolean;
}
export const useDeleteAdjustment = () => {
  const queryClient = useQueryClient();
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const retailerId = useAppSelector((state) => state.retailer.id);
  const { userId } = useAppSelector((state) => state.user.session);
  const [loading, setLoading] = useState(false);
  const [isPolling, setIsPolling] = useState(false);

  const deleteAdjustment = async ({ retailerSku, adjustmentId, isDraft }: DeleteAdjustmentParams) => {
    const deleteAdjustmentPayload: DeleteAdjustmentPayload = {
      isDraft,
      beaconClientId,
      retailerId,
      retailerSku,
      userId,
      adjustmentId
    };
    try {
      setLoading(true);
      return axios.post('/api/beaconforecast/DeleteAdjustments', [deleteAdjustmentPayload]);
    } catch (error) {
      console.error('Failed to delete adjustment.');
      return null;
    } finally {
      setLoading(false);
      setIsPolling(true);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isPolling) {
      // Polling duration in milliseconds
      const pollingDuration = ADJUSTMENT_POLL_DURATION_MS;
      // Polling interval in milliseconds
      const pollingInterval = ADJUSTMENT_INTERVAL_DURATION_MS;
      const endTime = Date.now() + pollingDuration;

      const intervalId = setInterval(() => {
        // Invalidate the adjustment query for the adjustments tab and the forecast summary key metrics
        queryClient.invalidateQueries({ queryKey: [ADJUSTMENT_TABLE_QUERY] });
        queryClient.invalidateQueries({ queryKey: [FORECAST_SUMMARY_KEYMETRIC_QUERY] });

        if (Date.now() >= endTime) {
          // Stop polling after the specified duration
          clearInterval(intervalId);
          setIsPolling(false);
        }
      }, pollingInterval);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isPolling, queryClient]);

  return { deleteAdjustment, loading };
};

export const generatePlanTitleText = (planType) => {
  const { id } = planType;

  switch (id) {
    case AdjustmentPlanType.PaidTraffic:
    case AdjustmentPlanType.OtherTraffic:
    case AdjustmentPlanType.OrganicTraffic:
      return `Originally, this product was projected to generate: `;
    case AdjustmentPlanType.RetailPrice:
      return `Originally, this product's forecasted price was: `;
    case AdjustmentPlanType.ContentScore:
      return `Originally, this product's forecasted Content Score was: `;
    case AdjustmentPlanType.BuyBox:
      return `Originally, this product's forecasted Buy Box Rate was: `;
    case AdjustmentPlanType.InstockRate:
      return `Originally, this product's forecasted In-Stock Rate was: `;
    case AdjustmentPlanType.RatingsReviews:
      return `Originally, this product's forecasted Ratings & Reviews was: `;
    default:
      return `Originally, this product was projected to generate: `;
  }
};
export const generatePlanSubtitleText = (planType, projection: Projection) => {
  const { id, label } = planType;

  switch (id) {
    case AdjustmentPlanType.PaidTraffic:
    case AdjustmentPlanType.OtherTraffic:
    case AdjustmentPlanType.OrganicTraffic:
      return `${projection.formattedValue} in ${label} from ${projection.formattedStartDate} to ${projection.formattedEndDate}.`;
    case AdjustmentPlanType.RetailPrice:
    case AdjustmentPlanType.ContentScore:
    case AdjustmentPlanType.BuyBox:
    case AdjustmentPlanType.InstockRate:
      return `${projection.formattedValue} from ${projection.formattedStartDate} to ${projection.formattedEndDate}.`;
    case AdjustmentPlanType.RatingsReviews:
      return `${projection.formattedValue} star rating from ${projection.formattedStartDate} to ${projection.formattedEndDate}.`;
    default:
      return `${projection.formattedValue} in ${label} from ${projection.formattedStartDate} to ${projection.formattedEndDate}.`;
  }
};

export const generatePlanSummaryText = (planType, projection: Projection) => {
  const { id } = planType;

  switch (id) {
    case AdjustmentPlanType.PaidTraffic:
    case AdjustmentPlanType.OtherTraffic:
    case AdjustmentPlanType.OrganicTraffic:
      return `After applying the Plan Change to adjust ${planType.label} to ${projection.formattedValue} total clicks per week, the revised projection now displays this product will generate: `;
    case AdjustmentPlanType.RetailPrice:
    case AdjustmentPlanType.ContentScore:
    case AdjustmentPlanType.BuyBox:
    case AdjustmentPlanType.InstockRate:
      return `After applying the Plan Change to adjust ${planType.label} to ${projection.formattedValue} per week: `;
    case AdjustmentPlanType.RatingsReviews:
      return `After applying the Plan Change to adjust ${planType.label} to ${projection.formattedValue}: `;
    default:
      return `After applying the Plan Change to adjust ${planType.label} to ${projection.formattedValue} per week, the revised projection now displays this product will generate: `;
  }
};

export const generatePlanChangeText = (planType, projection: Projection) => {
  const { id, label } = planType;

  const valueForDisplay = projection.aggregatedFormattedValue
    ? projection.aggregatedFormattedValue
    : projection.formattedValue;

  switch (id) {
    case AdjustmentPlanType.PaidTraffic:
    case AdjustmentPlanType.OtherTraffic:
    case AdjustmentPlanType.OrganicTraffic:
      return `${valueForDisplay} in ${label} from ${projection.formattedStartDate} to ${projection.formattedEndDate}.`;
    case AdjustmentPlanType.RetailPrice:
      return `the revised Price of ${projection.formattedValue} now displays for this product from ${projection.formattedStartDate} to ${projection.formattedEndDate}.`;
    case AdjustmentPlanType.ContentScore:
      return `the revised Content Score of ${projection.formattedValue} now displays for this product from ${projection.formattedStartDate} to ${projection.formattedEndDate}.`;
    case AdjustmentPlanType.BuyBox:
      return `the revised Buy Box Rate of ${projection.formattedValue} now displays for this product from ${projection.formattedStartDate} to ${projection.formattedEndDate}.`;
    case AdjustmentPlanType.InstockRate:
      return `the revised In-Stock Rate of ${projection.formattedValue} now displays for this product from ${projection.formattedStartDate} to ${projection.formattedEndDate}.`;
    case AdjustmentPlanType.RatingsReviews:
      return `The revised star rating of ${projection.formattedValue} now displays for this product from ${projection.formattedStartDate} to ${projection.formattedEndDate}.`;
    default:
      return `${projection.formattedValue} in ${label} from ${projection.formattedStartDate} to ${projection.formattedEndDate}.`;
  }
};

export const generateNetImpactPlanSummaryText = (planType, projection: Projection) => {
  const { id } = planType;

  switch (id) {
    case AdjustmentPlanType.PaidTraffic:
    case AdjustmentPlanType.OtherTraffic:
    case AdjustmentPlanType.OrganicTraffic:
      return `After applying the Plan Change to adjust ${planType.label} to ${projection.formattedValue} total clicks per week, the revised projection now displays this product will generate: `;
    case AdjustmentPlanType.RetailPrice:
    case AdjustmentPlanType.ContentScore:
    case AdjustmentPlanType.BuyBox:
    case AdjustmentPlanType.InstockRate:
    case AdjustmentPlanType.RatingsReviews:
      return `After applying the Plan Change to adjust ${planType.label} to ${projection.formattedValue} the revised projection now displays this product will generate: `;
    default:
      return `After applying the Plan Change to adjust ${planType.label}, the revised projection now displays this product will generate: `;
  }
};
