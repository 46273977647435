import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';

import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import { getRetailerIdDisplayName } from 'src/utils/stringFormatting';
import { getWeekLastDay, getWeekNumber } from 'src/utils/dateformatting';
import { buildMetricValue } from 'src/utils/metrics';
import { INDEX_FIELDS, METRICTYPE } from 'src/utils/entityDefinitions';
import ReduxStore from 'src/types/store/reduxStore';
import { Widget } from 'src/types/application/widgetTypes';
import colors from 'src/utils/colors';
import fontStyle from 'src/utils/fontStyle';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import { MetricField } from 'src/types/application/types';
import OmniTopEntitiesChart from 'src/components/EntityPage/TopEntitiesChart/OmniTopEntitiesChart';

export const buildOmniTopEntitiesWidgetConfig = (
  app: ReduxStore['app'],
  indexName: string,
  entity: { type: string },
  groupByFieldNames: string[],
  metricFieldNames: string[],
  weekIdField: MetricField,
  widgetConfigOverride?: { [key: string]: any }
): Widget => {
  const { name: appName } = app;
  const { type: entityType } = entity;

  //  collect metric and group by fields that will be used to construct Highchart component
  const mainMetricFields = metricFieldNames.map((metricFieldName) => {
    return _cloneDeep(INDEX_FIELDS.getField(appName, indexName, metricFieldName, entityType));
  });

  const groupByFields = groupByFieldNames.map((groupByFieldName) =>
    _cloneDeep(INDEX_FIELDS.getField(appName, indexName, groupByFieldName, entityType))
  );

  //  create component name
  const groupByFieldsName = groupByFields.map((g) => g.name);
  const groupByFieldName = groupByFieldsName.join('_');

  const widgetConfig = {
    name: `${mainMetricFields[0].name}By${groupByFieldName}`,
    CustomComponent: OmniTopEntitiesChart as any,
    view: {
      ...VIEWS.comparisonBarChart,
      displayName: `${mainMetricFields[0].displayName} by ${groupByFieldName}`,
      mainMetricFields,
      chartSeriesColors: [colors.comparison, colors.stacklineBlue],
      container: {
        style: {
          marginBottom: '100px',
          verticalAlign: 'top'
        }
      }
    },
    data: {
      weekIdField,
      valueGroups: groupByFields
    }
  };

  if (!widgetConfigOverride) {
    return widgetConfig;
  }

  return _merge({}, widgetConfig, widgetConfigOverride);
};

const onlyPutTheEmptyKeyToLastSortingLogicCBSA = (arr: { name: string; [key: string]: any }[]) => {
  let i = 0;
  let j = arr.length - 1;
  while (i < j) {
    if (arr[i].name === '') {
      const temp = arr[i];
      arr[i] = arr[j];
      arr[j] = temp;
      j--;
    } else {
      i++;
    }
  }
};

const onlyPutTheEmptyKeyToLastSortingLogicBrandId = (arr: { name: string; [key: string]: any }[]) => {
  const arrWithoutEmptyKey = arr.filter((ele) => ele.brandId);
  const arrWithEmptyKey = arr.filter((ele) => !ele.brandId);

  return arrWithoutEmptyKey.concat(arrWithEmptyKey);
};

const fillingMissingDataAndSortData = (mainEntityMetrics, comparisonEntityMetrics, groupBy) => {
  const mapForMain = new Map();
  const mapForCompare = new Map();
  mainEntityMetrics.forEach((element) => {
    mapForMain.set(element.name, element);
  });
  comparisonEntityMetrics.forEach((element) => {
    mapForCompare.set(element.name, element);

    // fill the data having in the compare but not in the Main
    if (!mapForMain.has(element.name)) {
      const filledElement = _cloneDeep(element);
      filledElement.value = 0;
      mapForMain.set(element.name, filledElement);
    }
  });

  // fill the data having in the main but not in the compare
  mainEntityMetrics.forEach((element) => {
    if (!mapForCompare.has(element.name)) {
      const filledElement = _cloneDeep(element);
      filledElement.value = 0;
      mapForCompare.set(element.name, filledElement);
    }
  });
  let filledMainData = [...mapForMain.values()];
  // sort the data
  filledMainData.sort((ele1, ele2) => {
    if (groupBy === 'retailerId') {
      // for instacart sort by the value
      if (ele1.name.includes('63') && ele2.name.includes('63')) {
        if (ele1.value === ele2.value) {
          return 0;
        }
        return ele1.value < ele2.value ? 1 : -1;
      }
      // make sure the instacart is in the end of the bar chart
      if (ele1.name.includes('63')) {
        return 1;
      }
      if (ele2.name.includes('63')) {
        return 1;
      }
      // sort according to the retailer id
      if (ele1.name === ele2.name) {
        return 0;
      }
      return Number(ele1.name) < Number(ele2.name) ? -1 : 1;
    }
    if (groupBy === 'keywordId' || groupBy === 'brandId') {
      if (ele1.value === ele2.value) {
        return 0;
      }
      return ele1.value < ele2.value ? 1 : -1;
    }
    // no need to sort in other case
    return 0;
  });

  // when groupBy locationCBSAName put the empty key data to the end
  if (groupBy === 'locationCBSAName') {
    onlyPutTheEmptyKeyToLastSortingLogicCBSA(filledMainData);
  }

  if (groupBy === 'brandId') {
    filledMainData = onlyPutTheEmptyKeyToLastSortingLogicBrandId(filledMainData);
  }

  // make sure the main data and compare data are in the same order
  const filledCompareData = [];
  filledMainData.forEach((element) => {
    filledCompareData.push(mapForCompare.get(element.name));
  });

  return [filledMainData, filledCompareData];
};

const buildChartSeries = (mainEntityMetrics, comparisonEntityMetrics, widget, comparisonTimePeriod, mainTimePeriod) => {
  const { view, data } = widget;
  const { valueGroups } = data;
  const [valueGroup] = valueGroups;
  const { mainMetricFields: metricFields } = view;
  const chartSeries = [];
  const entityMetricsList = [comparisonEntityMetrics, mainEntityMetrics];
  const [metricField] = metricFields;
  const timePeriodList = [comparisonTimePeriod, mainTimePeriod];

  entityMetricsList.forEach((e) => {
    const chartSeriesIndex = chartSeries.length;
    const chartDisplayTimePeriod = timePeriodList[chartSeriesIndex];
    const lastWeekOnly = false;
    const name = metricField.displayName;
    const series = {
      turboThreshold: data.length,
      groupPadding: 0.15,
      pointPadding: 0.05,
      name,
      metricField,
      metricType: metricField.metricType,
      currencySymbol: '',
      data: [],
      dataLabels: {
        allowOverlap: true,
        useHTML: true,
        style: {
          color: colors.darkBlue,
          fontSize: '11px',
          fontWeight: fontStyle.regularWeight,
          fontFamily: 'Roboto, sans-serif'
        }
      },
      color:
        view.chartSeriesColors && view.chartSeriesColors.length > chartSeriesIndex && !lastWeekOnly
          ? view.chartSeriesColors[chartSeriesIndex]
          : colors.stacklineBlue,
      marker: {
        lineColor:
          view.chartSeriesColors && view.chartSeriesColors.length > chartSeriesIndex && !lastWeekOnly
            ? view.chartSeriesColors[chartSeriesIndex]
            : colors.stacklineBlue,
        fillColor:
          view.chartSeriesColors && view.chartSeriesColors.length > chartSeriesIndex && !lastWeekOnly
            ? view.chartSeriesColors[chartSeriesIndex]
            : colors.stacklineBlue,
        lineWidth: 3,
        symbol: 'circle'
      },
      timePeriod: chartDisplayTimePeriod.displayName
    };

    const axisCategories = [];
    e.forEach((dataPoint) => {
      series.data.push([dataPoint.name, dataPoint.value]);
      if (valueGroup.name === 'retailerId') {
        axisCategories.push({
          id: dataPoint.name,
          keyFieldName: 'retailerId',
          nameFieldName: 'retailerName',
          type: 'retailer',
          displayName: 'Retailer',
          isplayNamePlural: 'Retailers',
          name: dataPoint.name
        });
      } else {
        axisCategories.push({
          id: dataPoint.name === '' ? 'Other' : dataPoint.name,
          name: dataPoint.name === '' ? 'Other' : dataPoint.name
        });
      }
    });
    const timePeriod = chartDisplayTimePeriod.displayName;
    series.legendDiv = `<div><div>${name}</div><div class="legend__primary-date" style="margin-top:5px;">${timePeriod}</div></div><div class="legend__primary-date" style="margin-top:5px;"></div></div>`;
    series.categories = axisCategories;
    chartSeries.push(series);
  });
  return chartSeries;
};

export const buildChartProps = (
  omniData: { main: any[]; compare: any[] },
  widget: Widget,
  retailer: ReduxStore['retailer'],
  mainTimePeriod: ReduxStore['mainTimePeriod'],
  comparisonTimePeriod: ReduxStore['comparisonTimePeriod'],
  region: ReduxStore['regionsFollowing'],
  country: ReduxStore['countriesFollowing']
) => {
  let [mainMetricField] = widget.view.mainMetricFields;
  const { data } = widget;
  const { view } = widget;
  const { valueGroups } = data;
  const [groupFiled] = valueGroups;
  const { groupBy } = groupFiled;
  const { main, compare } = omniData;
  const subtitle = 'All Categories';
  const [mainEntityMetrics, comparisonEntityMetrics] = fillingMissingDataAndSortData(main, compare, groupBy);
  const chartSeries = buildChartSeries(
    mainEntityMetrics,
    comparisonEntityMetrics,
    widget,
    comparisonTimePeriod,
    mainTimePeriod
  );
  let mainCategories = [];
  chartSeries.forEach((series) => {
    if (mainCategories.length < series.categories.length) {
      mainCategories = series.categories;
    }
  });
  chartSeries.forEach((series) => {
    series.categories = mainCategories;
  });
  if (chartSeries.length > 0) {
    mainMetricField = chartSeries[0].metricField;
  }
  const isSameMetricComparison = true;
  const chartProps = {
    chart: { type: 'column', spacingLeft: 20, spacingRight: 20 },
    title: { text: `${mainMetricField.displayName} by ${groupFiled.displayName}` },
    subtitle: { text: subtitle },
    horizontalScrolling: view.horizontalScrolling ? view.horizontalScrolling : {},
    plotOptions: {
      fillEnabled: true,
      series: {
        groupPadding: 0.15,
        pointPadding: 0.05,
        dataLabels: {
          enabled: true,
          crop: false,
          overflow: 'none',
          allowOverlap: true,
          style: {
            color: colors.darkBlue,
            'font-size': '14px',
            'font-weight': fontStyle.regularWeight,
            'font-family': 'Roboto, sans-serif'
          },
          formatter() {
            const formattedMetricValue = convertMetricToDisplayValue(
              retailer,
              this.y,
              mainMetricField.metricType,
              retailer.currencySymbol,
              mainMetricField.metricType === 'PERCENT'
                ? !['contentScore', 'contentAccuracy'].includes(mainMetricField.name)
                : false
            );
            // Share Of Shelf TopEntity Chart will have 2 decimal points.
            if (['shareOfShelf'].includes(mainMetricField.indexName)) {
              return `${parseFloat(this.y * 100)
                .toFixed(2)
                .toString()}%`;
            }

            if (
              mainMetricField.metricType === 'MONEY' &&
              this.color &&
              this.color === '#adbdcc' &&
              chartSeries &&
              chartSeries.length >= 1 &&
              chartSeries[0].currencySymbol
            ) {
              return chartSeries[0].currencySymbol + formattedMetricValue.slice(1);
            }

            return formattedMetricValue;
          }
        }
      }
    },
    legend: {
      enabled: true,
      itemStyle: {
        'font-size': '14px'
      },
      labelFormatter() {
        return this.userOptions.legendDiv;
      }
    },
    tooltip: {
      formatter() {
        // TODO: add disable logic here for overall and individually selected metrics
        if (_get(view, ['hideTooltip'], false)) {
          return '<div></div>';
        }

        let xValue = this.x.name;
        if (this.x.keyFieldName === 'retailerId') {
          xValue = getRetailerIdDisplayName(this.x.name, retailer);
        }
        const xAxisLabelDiv = `<div style="display:inline-block; float:left; color:${colors.darkBlue};">
                ${xValue || xValue === '' ? `${xValue}  ` : getWeekLastDay(getWeekNumber(this.x)[2]).replace('-', ' ')}
                </div>`;
        let tooltipStyle = '';
        let percentChangeDiv = '';
        // compute metric change, only if there are two metrics and they are of same type
        if (isSameMetricComparison && this.points.length > 1) {
          let metricsChange = { icon: '+', color: colors.green };
          let percentChange =
            this.points[0].y > 0
              ? (this.points[1].y - this.points[0].y) / this.points[0].y
              : this.points[1].y > 0
              ? 100
              : 0;
          // if the change is negative, then display in red with minus icon
          if (percentChange < 0) {
            metricsChange = { icon: '\u2212', color: colors.red };
            percentChange *= -1;
          }
          percentChange = buildMetricValue(
            percentChange,
            METRICTYPE.PERCENT,
            chartSeries[0].currencySymbol,
            true,
            null,
            'en'
          ).value;
          const tooltipWidth = 100 + (this.points[0].x.name ? xValue.length * 7 : percentChange.toString().length * 7);
          percentChangeDiv = `<div style="display:inline-block; float:right; color:${metricsChange.color};">
                ${metricsChange.icon}${percentChange}%
              </div>`;
          tooltipStyle += `width: ${tooltipWidth}px;`;
        }

        let yAxisMetricsDiv = '';
        for (let i = this.points.length - 1; i >= 0; i -= 1) {
          const value = this.points[i];
          const { color } = chartSeries[value.series.index];
          const metricValue = buildMetricValue(
            value.y,
            chartSeries[i].metricType,
            chartSeries[i].currencySymbol,
            true,
            null,
            'en'
          );
          yAxisMetricsDiv += `<div style="margin-top:5px;color:${color};">
                ${metricValue.prefix || ''}${metricValue.value}
                <span class='sl-metric__suffix'>${metricValue.suffix || ''}</span>
              </div>`;
        }
        return `<div class="hichart-tooltip" style="${tooltipStyle}" >
                      <div style="overflow:hidden;" class="hiline-chart-tooltipx">
                        ${xAxisLabelDiv}
                        ${percentChangeDiv}
                      </div>
                      <div class="hiline-chart-tooltipy">
                        ${yAxisMetricsDiv}
                      </div>
                    </div>`;
      },
      positioner(labelWidth, labelHeight, point) {
        return { x: point.plotX, y: point.plotY - 20 };
      }
    },
    yAxis: [],
    xAxis: []
  };
  chartSeries.forEach((series) => {
    chartProps.yAxis.push({
      labels: {
        enabled: false,
        formatter() {
          try {
            const val = buildMetricValue(this.value, series.metricType, series.currencySymbol, true, null, 'en');
            return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
          } catch (e) {
            console.warn(e);
          }
          return '';
        }
      }
    });
    chartProps.xAxis.push({
      categories: series.categories,
      labels: {
        useHTML: true,
        formatter() {
          let name = this.value.name ? this.value.name : '';

          if (groupBy === 'retailerId') {
            const retailerEntity = retailer.availableRetailers.find((r) => r.id === this.value.id);
            if (retailerEntity) {
              name = retailerEntity.displayName;
            }
          }

          if (groupBy === 'locationRegionCode') {
            const regionEntity = region.find((r) => r.regionCode === this.value.id);
            if (regionEntity) {
              name = regionEntity.regionName;
            }
          }

          if (groupBy === 'locationCountryCode') {
            const countryEntity = country.find((r) => r.countryCode === this.value.id);
            if (countryEntity) {
              name = countryEntity.countryName;
            }
          }

          return name;
        },
        style: {
          color: colors.darkBlue,
          fontSize: '13px',
          fontWeight: fontStyle.regularWeight,
          fontFamily: 'Roboto, sans-serif'
        }
      }
    });
  });
  const categoryLengthForMainPeriod = chartProps.xAxis[0] && _get(chartSeries, '[0].data', []);
  chartProps.xAxis[0].max = categoryLengthForMainPeriod.length >= 10 ? 9 : categoryLengthForMainPeriod.length - 1;
  return { chartProps, chartSeries };
};
