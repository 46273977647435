// ------------------------------------
// Constants/Types
// ------------------------------------

const REQUEST_ENTITY_SALES_METRICS = 'src/entitySearchService/REQUEST_ENTITY_SALES_METRICS' as const;
const RECEIVE_ENTITY_SALES_METRICS = 'src/entitySearchService/RECEIVE_ENTITY_SALES_METRICS' as const;
const CLEAR_ENTITY_SEARCH_SERVICE = 'src/entitySearchService/CLEAR_ENTITY_SEARCH_SERVICE' as const;
const IS_FETCHING_ADDITIONAL_DATA = 'src/entitySearchService/IS_FETCHING_ADDITIONAL_DATA' as const;
const MERGE_DATA_KEYS = 'src/entitySearchService/MERGE_DATA_KEYS' as const;
const SET_DATA_KEY_ATTRIBUTE = 'src/entitySearchService/SET_DATA_KEY_ATTRIBUTE' as const;
const SET_DATA_KEY = 'src/entitySearchService/SET_DATA_KEY' as const;
const DELETE_DATA_KEY = 'src/entitySearchService/DELETE_DATA_KEY' as const;
const MAP_KEY = 'src/entitySearchService/MAP_KEY' as const;
const MERGE_DATA_KEYS_TO_MAPPING = 'src/entitySearchService/MERGE_DATA_KEYS_TO_MAPPING' as const;

export default {
  CLEAR_ENTITY_SEARCH_SERVICE,
  IS_FETCHING_ADDITIONAL_DATA,
  REQUEST_ENTITY_SALES_METRICS,
  RECEIVE_ENTITY_SALES_METRICS,
  MERGE_DATA_KEYS,
  SET_DATA_KEY_ATTRIBUTE,
  SET_DATA_KEY,
  DELETE_DATA_KEY,
  MAP_KEY,
  MERGE_DATA_KEYS_TO_MAPPING
};
