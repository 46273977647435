import React from 'react';
import {
  FORM_FIELD,
  PromotionType
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';

/**
 * Dropdown menu for selecting whether a retail price adjustment is/is not a promotion.
 * Options are 'Yes' and 'No'.
 */
export const PromotionAdjustmentToggle = ({
  onChange,
  isPromotionAdjustment
}: {
  onChange: (target: FORM_FIELD, value: any) => void;
  isPromotionAdjustment: boolean;
}) => {
  const options = [
    { id: 'isPromotion', label: 'Yes' },
    { id: 'isNotPromotion', label: 'No' }
  ];

  const isPromotionOption = isPromotionAdjustment ? options[0] : options[1];

  return (
    <Flex width="69px" flexDirection="column" gap="sm">
      {/* Is promotion menu */}
      <Text variant="subtitle2" title="Promotion">
        Promotion
      </Text>
      <SlDropdownMenu
        stacklineButtonProps={{
          buttonSx: {
            width: '69px',
            justifyContent: 'space-between',
            padding: '8px 0px 6px 8px'
          },
          textSx: { fontWeight: 'normal' }
        }}
        options={options}
        defaultLabel={isPromotionOption.label}
        selectedId={isPromotionOption.id}
        onChange={(option) => onChange(FORM_FIELD.IS_PROMOTION_ADJUSTMENT, option.id === 'isPromotion')}
      />
    </Flex>
  );
};

/**
 * List of possible promotion type enums + their display name for a retail price adjustment.
 * Used in the PromotionAdjustmentTypeDropdown component to render the dropdown options.
 */
export const PROMOTION_OPTIONS = [
  { id: PromotionType.LightningDeal, label: 'Lightning Deal' },
  { id: PromotionType.DealOfTheDay, label: 'Deal of the Day' },
  { id: PromotionType.BestDeal, label: 'Best Deal' },
  { id: PromotionType.Coupon, label: 'Coupon' },
  { id: PromotionType.TopDeal, label: 'Top Deal' },
  { id: PromotionType.PrimeMemberDeal, label: 'Prime Member Deal' },
  { id: PromotionType.None, label: 'None' }
];

/**
 * Dropdown menu for selecting the type of promotion for a retail price adjustment.
 * If the retail price adjustment is not a promotion, the dropdown will only show the 'None' option.
 */
export const PromotionAdjustmentTypeDropdown = ({
  onChange,
  promotionType
}: {
  onChange: (field: FORM_FIELD, value: any) => void;
  promotionType: PromotionType;
}) => {
  return (
    <Flex width="152px" flexDirection="column" gap="sm">
      {/* Promotion Type */}
      <Text variant="subtitle2" title="Promotion Type">
        Promotion Type
      </Text>
      <SlDropdownMenu
        stacklineButtonProps={{
          buttonSx: {
            minWidth: '160px',
            justifyContent: 'space-between',
            padding: '8px 0px 6px 8px'
          },
          textSx: { fontWeight: 'normal' }
        }}
        options={
          promotionType === PromotionType.None
            ? PROMOTION_OPTIONS.filter((option) => option.id === PromotionType.None) // Only show None option if not a promotion. When we toggle 'No' for promotion, we reset the type to None in the form handler.
            : PROMOTION_OPTIONS.filter((option) => option.id !== PromotionType.None)
        }
        defaultLabel={PROMOTION_OPTIONS.find(({ id }) => id === promotionType).label}
        selectedId={promotionType}
        onChange={(option) => onChange(FORM_FIELD.PROMOTION_TYPE, option.id)}
      />
    </Flex>
  );
};
