import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _constant from 'lodash/constant';
import queryString from 'qs';
import Dialog from '@mui/material/Dialog';
import axios, { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';

import CloseButton from 'src/components/common/Buttons/CloseButton';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
// import { withPush } from 'src/utils/hoc';
// import { buildEntityLink } from 'src/components/AdManager/AmsUtils';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
// import { PushFn } from 'src/types/application/types';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { buildMuiTheme } from 'src/muiTheme';
import {
  formatFailedEntities,
  getPartialSuccessDialogMessage,
  getFailedDialogMessage
} from 'src/components/AdManager/SubmitScreen/SubmitScreen';
import { SubmitDialog } from 'src/components/AdManager/SubmitScreen/PartialSuccessScreen';
import ReduxStore from 'src/types/store/reduxStore';
import { formatTime } from 'src/utils/dateformatting';
import { error } from 'src/utils/mixpanel';
import _startCase from 'lodash/startCase';
import moment from 'moment-timezone';
import Waypoint from 'react-waypoint';
import './ActionLogPageLayout.scss';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const ACTION_LOG_API = '/apiAdManager/adUpdateRequests/findUpdateRequest/pageNumber=';

const AD_UPDATE_INFO_API = '/apiAdManager/adUpdateRequests/failedUpdateRequest';

const PAGE_SIZE = 100;

interface AdUpdateRequestObject {
  updateRequestId: string;
  extendedAttributes: object;
}

const StatusColumn = ({ value, data }: { value: string; data: any }) => {
  const [open, setOpen] = useState(false);
  if (data.link) {
    return (
      <Link target="_blank" to={data.link}>
        {value}
      </Link>
    );
  }
  if (['Failed', 'Partial Success'].includes(value)) {
    const { resultProperty } = _get(data, ['request', 'extendedAttributes']);
    const failedEntities = _get(data, ['request', 'extendedAttributes', resultProperty, 'failedEntities'], {});
    const requestType = _get(data, ['request', 'updateType']);
    const items = formatFailedEntities(failedEntities);
    if (items.length > 0) {
      const dialogDetail = {
        title: 'Detail',
        subtitle:
          value === 'Failed' ? getFailedDialogMessage(requestType) : getPartialSuccessDialogMessage(requestType),
        items
      };
      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={buildMuiTheme()}>
            <div
              style={{ cursor: 'pointer' }}
              role="button"
              onClick={() => {
                setOpen(true);
              }}
            >
              {value}
            </div>
            <Dialog open={open} onClose={() => setOpen(false)}>
              <SubmitDialog dialogDetail={dialogDetail} />
              <CloseButton onClick={() => setOpen(false)} />
            </Dialog>
          </ThemeProvider>
        </StyledEngineProvider>
      );
    }
    return <div>{value}</div>;
  }
  return <div>{value}</div>;
};

const getFormattedRow = (singleLog: any, app: ReduxStore['app']) => {
  const { searchParams } = app.queryParams;
  const parsedAdditionalParameters = {
    tab: 'adManager',
    subtab: 'keyMetrics'
  };
  const campaignType = 'adCampaign';
  const portfolioType = 'adPortfolio';
  const logType = _get(singleLog, ['updateType']);
  const tzString = moment.tz.zone(moment.tz.guess())!.abbr(new Date().getTimezoneOffset());
  const result = {
    createdTime: `${formatTime(singleLog.createdTime)} ${tzString}`,
    createdTimeEpoch: moment(singleLog.createdTime).valueOf(),
    createdBy: singleLog.createdBy,
    name: _startCase(singleLog.updateType),
    status: _startCase(singleLog.extendedAttributes.status),
    link: null,
    request: singleLog
  };
  if (singleLog.extendedAttributes.status !== 'success' || logType === 'bulkBudgetUpdate') {
    return result;
  }
  let linkOverride = null;
  switch (logType) {
    case 'createCampaign': {
      const id = _get(singleLog, ['extendedAttributes', 'adCampaignCreationResult', 'campaignId']);
      linkOverride = id
        ? `/${campaignType}/${id}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`
        : null;
      break;
    }
    case 'updateCampaignMetaData': {
      const id = _get(singleLog, ['extendedAttributes', 'adCampaign', 'campaignId']);
      linkOverride = id
        ? `/${campaignType}/${id}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`
        : null;
      break;
    }
    case 'createCampaignProduct': {
      const id = _get(singleLog, ['extendedAttributes', 'adCampaign', 'campaignId']);
      linkOverride = id
        ? `/${campaignType}/${id}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`
        : null;
      break;
    }
    case 'createPortfolio': {
      const id = _get(singleLog, ['extendedAttributes', 'adPortfolioCreationResult', 'portfolioId']);
      linkOverride = id
        ? `/${portfolioType}/${id}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`
        : null;
      break;
    }
    case 'updatePortfolioMetaData': {
      const id = _get(singleLog, ['extendedAttributes', 'adPortfolio', 'settingId']);
      linkOverride = id
        ? `/${portfolioType}/${id}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`
        : null;
      break;
    }
    case 'updateStatus': {
      const campaignLength = _get(singleLog, ['extendedAttributes', 'adCampaigns', 'length']);
      const portfolioLength = _get(singleLog, ['extendedAttributes', 'adPortfolios', 'length']);
      if (campaignLength === 1) {
        const id = _get(singleLog, ['extendedAttributes', 'adCampaigns', 0, 'id']);
        linkOverride = id
          ? `/${campaignType}/${id}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`
          : null;
      } else if (portfolioLength === 1) {
        const id = _get(singleLog, ['extendedAttributes', 'adPortfolios', 0, 'id']);
        linkOverride = id
          ? `/${portfolioType}/${id}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`
          : null;
      }
      break;
    }
    case 'updateTargetBid': {
      const id = _get(singleLog, ['extendedAttributes', 'adTargets', 0, 'campaignId']);
      linkOverride = id
        ? `/${campaignType}/${id}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`
        : null;
      break;
    }
    case 'updateProductBid': {
      const id = _get(singleLog, ['extendedAttributes', 'adCampaignProducts', 0, 'campaignId']);
      linkOverride = id
        ? `/${campaignType}/${id}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`
        : null;
      break;
    }
    default: {
      linkOverride = null;
    }
  }
  return {
    ...result,
    link: linkOverride
  };
};

// const LinkCellRenderer = withPush(LinkCellRendererInner);

const formatData = (data: any[], app: ReduxStore['app']) => {
  const actions = data.map((singleLog) => {
    return getFormattedRow(singleLog, app);
  });
  return actions;
};

const onGridReady = (params: any) => {
  params.api.sizeColumnsToFit();
  window.addEventListener('resize', () => {
    setTimeout(() => {
      params.api.sizeColumnsToFit();
    });
  });
  params.api.sizeColumnsToFit();
  params.api.refreshHeader();
};

const gridRendererFields = [
  {
    headerName: 'Request Type',
    field: 'name',
    width: 200,
    cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
    headerClass: 'align-left'
  },
  { headerName: 'Request Status', field: 'status', width: 200, cellRendererFramework: StatusColumn },
  { headerName: 'Requested By', field: 'createdBy', width: 200 },
  { headerName: 'Submitted Time', field: 'createdTime', width: 200 }
];

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, ['app', 'adPlatforms', 'retailer']),
    mainEntity: state.entityService.mainEntity
  };
};

const ActionLog = connect(mapStateToProps)(
  ({
    app,
    retailer,
    adPlatforms
  }: {
    app: ReduxStore['app'];
    retailer: ReduxStore['retailer'];
    adPlatforms: ReduxStore['adPlatforms'];
  }) => {
    const [state, setState] = useState({
      isLoading: true,
      hasMoreRows: false,
      pageNumber: 0,
      dataSet: [] as ReturnType<typeof formatData>
    });
    const currentPlatform = adPlatforms.find((platform) => {
      const platformRetailerId = _get(platform, ['extendedAttributes', 'retailerId'], '');
      const retailerId = _get(retailer, ['id'], '');
      return `${platformRetailerId}` === `${retailerId}`;
    });

    useEffect(() => {
      if (state.isLoading && !_isEmpty(currentPlatform)) {
        const formatResultData = async (inputData: AdUpdateRequestObject[]) => {
          // filter of the current platform type
          const filteredData = inputData.filter((data) => {
            const dataPlatformType = _get(data, ['platformType'], '');
            const currentPlatformType = _get(currentPlatform, ['platformType'], '');
            return dataPlatformType === currentPlatformType;
          });
          // filter the ones which have empty extended attributes
          // select the update request id
          const emptyUpdateRequests = filteredData
            .filter((data: any) => {
              return _isEmpty(data.extendedAttributes);
            })
            .map((x: any) => x.updateRequestId);

          if (emptyUpdateRequests && emptyUpdateRequests.length > 0) {
            const res: AxiosResponse = await axios.post(AD_UPDATE_INFO_API, {
              failedRequestIds: emptyUpdateRequests
            });

            if (res.status === 200) {
              const failedUpdateRequests: AdUpdateRequestObject[] = res.data;
              const keyExtendedAttributesHashMap: Map<string, object> = new Map();
              failedUpdateRequests.forEach((failedUpdateRequest) => {
                // storing the request id and the extended attributes
                keyExtendedAttributesHashMap.set(
                  failedUpdateRequest.updateRequestId.toString(),
                  failedUpdateRequest.extendedAttributes
                );
              });
              const updateRequestIdSet = new Set(emptyUpdateRequests);
              filteredData.forEach((dataElement) => {
                if (updateRequestIdSet.has(dataElement.updateRequestId)) {
                  // get the request id corresponding to the updateRequestId
                  dataElement.extendedAttributes = keyExtendedAttributesHashMap.get(dataElement.updateRequestId) || {};
                }
              });
            }
          }
          const actions = formatData(filteredData, app);

          return actions;
        };
        const fetchPageData = async () => {
          const res = await axios.get(ACTION_LOG_API + state.pageNumber.toString());
          if (res.status === 200) {
            const { data } = res;
            const incomingData = await formatResultData(data);
            setState({
              isLoading: false,
              hasMoreRows: data && data.length === PAGE_SIZE,
              dataSet: [...state.dataSet, ...incomingData],
              pageNumber: state.pageNumber + 1
            });
          } else {
            error('Error on fetching action log');
          }
        };
        fetchPageData();
      }
    }, [state, app, retailer, currentPlatform]);

    const fetchData = async () => {
      if (state.hasMoreRows) {
        setState({
          ...state,
          isLoading: true
        });
      }
    };

    return (
      <div className="ad-manager-container">
        <h1 className="action-log-title" style={{ fontSize: 31 }}>
          User Actions
        </h1>

        <hr className="sl-divider sl-divider--no-margin-top"></hr>
        <CustomAgMaterial
          onGridReady={onGridReady}
          onCellValueChanged={onGridReady}
          onModelUpdated={onGridReady}
          onRowValueChanged={onGridReady}
          onRowDataChanged={onGridReady}
          buildRows={_constant(state.dataSet!)}
          columnDefs={gridRendererFields}
          suppressNoRowsOverlay
          domLayout="autoHeight"
        >
          {state.isLoading && <GridLoading />}
          {!state.isLoading && state.hasMoreRows && <Waypoint onEnter={fetchData} />}
          <br />
          <br />
        </CustomAgMaterial>
      </div>
    );
  }
);

export const getLayout = () => {
  const widget = {
    name: 'adManagerActionLog',
    CustomComponent: ActionLog,
    view: {
      name: 'adManagerActionLog'
    },
    data: {}
  };
  return {
    widgets: [widget]
  };
};
