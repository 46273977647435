import { Dispute } from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeDetailsTable';
import { invoiceDateToISOFormattedDate } from 'src/utils/dateUtils';
// Query 1.1 = Invoice Summary
// Query 1.2 = Shipment Summary
// Query 2 = Financial Data
// Query 3 = Shorted Asins
// Query 4 = Lower Table of Details Section
// Query 5 = Last 3 fields of the upper table of Details Section

export interface InvoiceRequestParameters {
  pageNumber?: string | number;
  pageSize?: string | number;
  invoiceNumbers?: string[];
  retailerId?: string | number;
  minWeekId?: string | number;
  maxWeekId?: string | number;
  minShortageValue?: string | number;
  maxShortageValue?: string | number;
  stage?: string[];
  status?: string[];
}

// Query 1 Invoice Summary
export const buildRequestForInvoiceData = ({
  pageNumber = 1,
  pageSize = 10,
  invoiceNumbers = [],
  retailerId = 1,
  minWeekId = 202001,
  maxWeekId = 202352,
  minShortageValue = null,
  maxShortageValue = null,
  stage = ['New Shortage', 'Initial Dispute', 'Contact Us Case', 'Resolved'],
  status = []
}: InvoiceRequestParameters) => {
  return [
    {
      name: 'request-invoice-summary-data-1',
      id: 'request-invoice-summary-data-1',
      pageNumber,
      pageSize,
      retailerId,
      period: 'year',
      conditions: {
        termFilters: [
          {
            fieldName: 'documentType',
            condition: 'must',
            values: ['invoiceSummary']
          },
          {
            fieldName: 'originalInvoiceNumber',
            condition: 'must',
            values: invoiceNumbers
          },
          {
            fieldName: 'retailerId',
            values: [retailerId]
          },
          {
            fieldName: 'customDisputeStage',
            condition: 'must',
            values: stage
          },
          {
            fieldName: 'customDisputeStatus',
            condition: 'must',
            values: status
          }
        ],
        rangeFilters: [
          {
            fieldName: 'weekId',
            minValue: minWeekId,
            maxValue: maxWeekId
          },
          ...(minShortageValue || maxShortageValue
            ? [
                {
                  fieldName: 'latestSummaryShortageAmount',
                  minValue: minShortageValue,
                  maxValue: maxShortageValue
                }
              ]
            : [])
        ],
        condition: 'must',
        computeFilters: [],
        nestedFilterConditions: []
      },
      doAggregation: false,
      returnDocuments: true,
      processDocuments: true,
      SortFilter: {
        sortFields: [
          {
            FieldName: 'originalInvoiceDate',
            Direction: 'desc'
          }
        ]
      },
      searchType: 'beacon-sd-poShipmentInvoiceSummary_fetcher'
    }
  ];
};

// Query 2 Shipping Details
export interface ShippingRequestParameters {
  invoiceNumbers?: string[];
  retailerId?: string | number;
}

export const buildRequestForShippingData = ({ invoiceNumbers = [], retailerId = 1 }: ShippingRequestParameters) => {
  return [
    {
      name: 'request-invoice-shipping-data-2',
      id: 'request-invoice-shipping-data-2',
      pageNumber: 1,
      pageSize: 1200,
      retailerId,
      period: 'year',
      conditions: {
        termFilters: [
          {
            fieldName: 'documentType',
            condition: 'must',
            values: ['poShipmentSummary']
          },
          {
            fieldName: 'originalInvoiceNumber',
            condition: 'must',
            values: invoiceNumbers
          },
          {
            fieldName: 'retailerId',
            values: [retailerId]
          }
        ],
        rangeFilters: [
          {
            fieldName: 'originalInvoiceWeekId',
            minValue: 202001,
            maxValue: 202352
          }
        ],
        condition: 'must',
        computeFilters: [],
        nestedFilterConditions: []
      },
      doAggregation: false,
      returnDocuments: true,
      processDocuments: true,
      SortFilter: {
        sortFields: [
          {
            FieldName: 'invoiceAmount',
            Direction: 'desc'
          }
        ]
      },
      searchType: 'beacon-sd-poShipmentInvoiceSummary_fetcher'
    }
  ];
};

// Query 3 Financial Details
export interface FinancialRequestParameters {
  invoiceNumbers?: string[];
  retailerId?: string | number;
}

export const buildRequestForFinancialData = ({ invoiceNumbers = [], retailerId = 1 }: FinancialRequestParameters) => {
  return [
    {
      name: 'request-invoice-financial-data-3',
      id: 'request-invoice-financial-data-3',
      pageNumber: 1,
      pageSize: 1200,
      retailerId,
      period: 'year',
      doAggregation: true,
      returnDocuments: false,
      conditions: {
        termFilters: [
          {
            fieldName: 'document_join_field',
            condition: 'should',
            values: ['asinDetailMetrics']
          },
          {
            fieldName: 'originalInvoiceNumber',
            condition: 'should',
            values: invoiceNumbers
          },
          {
            fieldName: 'retailerId',
            values: [retailerId]
          }
        ]
      },
      searchBy: 'parent',
      aggregations: [
        {
          aggregationFields: [
            {
              aggregateByFieldDisplayName: 'disputeWonAmount_sum',
              aggregateByFieldName: 'disputeWonAmount',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldDisplayName: 'disputeLostAmount_sum',
              aggregateByFieldName: 'disputeLostAmount',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldDisplayName: 'disputeSubmittedAmount_sum',
              aggregateByFieldName: 'disputeSubmittedAmount',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldDisplayName: 'updatedShortageAmountBeforeDispute_sum',
              aggregateByFieldName: 'updatedShortageAmountBeforeDispute',
              function: 'sum',
              canBeExported: true
            }
          ],
          groupByFieldName: 'originalInvoiceNumber'
        }
      ],
      searchType: 'beacon-sd-asinDetailMetrics'
    }
  ];
};

// Query 4 Shorted ASINs
export interface ShortedASINRequestParameters {
  invoiceNumbers?: string[];
  retailerId?: string | number;
  categories: any;
}

export const buildRequestForShortedASINs = ({
  invoiceNumbers = [],
  retailerId = 1,
  categories
}: ShortedASINRequestParameters) => {
  return [
    {
      name: 'request-invoice-asin-data-4',
      id: 'request-invoice-asin-data-4',
      pageNumber: 1,
      pageSize: 1200,
      retailerId,
      period: 'year',
      conditions: {
        termFilters: [
          {
            fieldName: 'originalInvoiceNumber',
            condition: 'must',
            values: invoiceNumbers
          },
          {
            fieldName: 'categoryId',
            condition: 'should',
            values: categories.map((category) => category.id)
          }
        ],
        condition: 'must',
        computeFilters: [],
        nestedFilterConditions: [],
        searchBy: 'parent',
        rangeFilters: [
          {
            fieldName: 'updatedShortageAmountBeforeDispute',
            minValue: 0.1
          }
        ]
      },
      doAggregation: false,
      returnDocuments: false,
      processDocuments: false,
      aggregations: [],
      SortFilter: {
        sortFields: [
          {
            FieldName: 'updatedShortageAmountBeforeDispute',
            Direction: 'desc'
          }
        ]
      },
      searchType: 'beacon-sd-asinDetailMetrics-ShortedAsins'
    }
  ];
};

// Query 5 Shorted ASIN DETAILS
interface ASINDetailsForTableParameters {
  invoiceNumbers?: string[];
  retailerId?: string | number;
  categories: any;
}

export const buildRequestForShortedASINDetailsTable = ({
  invoiceNumbers = [],
  retailerId = 1,
  categories
}: ASINDetailsForTableParameters) => {
  return [
    {
      name: 'request-invoice-asinDetails-data-5',
      id: 'request-invoice-asinDetails-data-5',
      pageNumber: 1,
      pageSize: 1200,
      retailerId,
      period: 'year',
      conditions: {
        termFilters: [
          {
            fieldName: 'originalInvoiceNumber',
            condition: 'must',
            values: invoiceNumbers
          },
          {
            fieldName: 'categoryId',
            condition: 'should',
            values: categories.map((category) => category.id)
          }
        ],
        condition: 'must',
        computeFilters: [],
        nestedFilterConditions: [],
        rangeFilters: []
      },
      doAggregation: false,
      returnDocuments: true,
      processDocuments: false,
      aggregations: [],
      searchBy: 'child',
      searchType: 'beacon-sd-dispute'
    }
  ];
};

// Query 6 initial dispute details
export interface InitialDisputeDetailsParameters {
  invoiceNumbers?: string[];
  retailerId?: string | number;
  categories: any;
}

export const buildRequestForInitialDisputeDetails = ({
  invoiceNumbers = [],
  retailerId = 1,
  categories
}: InitialDisputeDetailsParameters) => {
  return [
    {
      name: 'request-initialDisputeDetails-data-6',
      id: 'request-initialDisputeDetails-data-6',
      pageNumber: 1,
      pageSize: 1200,
      retailerId,
      period: 'year',
      conditions: {
        termFilters: [
          {
            fieldName: 'originalInvoiceNumber',
            condition: 'must',
            values: invoiceNumbers
          },
          {
            fieldName: 'categoryId',
            condition: 'should',
            values: categories.map((category) => category.id)
          }
        ],
        condition: 'must',
        computeFilters: [],
        nestedFilterConditions: [],
        rangeFilters: []
      },
      doAggregation: true,
      returnDocuments: true,
      processDocuments: false,
      searchBy: 'child',
      aggregations: [
        {
          aggregationFields: [
            {
              aggregateByFieldDisplayName: 'invoiceQuantity_sum',
              aggregateByFieldName: 'invoiceQuantity',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldDisplayName: 'disputeQuantity_sum',
              aggregateByFieldName: 'disputeQuantity',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldDisplayName: 'disputeAmount_sum',
              aggregateByFieldName: 'disputeAmount',
              function: 'sum',
              canBeExported: true
            }
          ],
          groupByFieldName: 'originalInvoiceNumber'
        }
      ],
      searchType: 'beacon-sd-dispute'
    }
  ];
};

// Box Filter Metrics
export interface MetricRequestParameters {
  retailerId?: string | number;
  metricName?: string;
  stage?: string[];
  status?: string[];
  minWeekId: number;
  maxWeekId: number;
}

export const buildRequestForBoxFilterMetricsData = ({
  retailerId = 1,
  metricName = '',
  stage = [],
  status = [],
  minWeekId,
  maxWeekId
}: MetricRequestParameters) => {
  return [
    {
      name: `${metricName}box-filter-metric-request`,
      id: `${metricName}box-filter-metric-request`,
      pageNumber: 1,
      pageSize: 1200,
      retailerId,
      period: 'year',
      conditions: {
        termFilters: [
          {
            fieldName: 'documentType',
            condition: 'must',
            values: ['invoiceSummary']
          },
          {
            fieldName: 'customDisputeStage',
            values: stage
          },
          {
            fieldName: 'customDisputeStatus',
            values: status
          }
        ],
        rangeFilters: [
          {
            fieldName: 'weekId',
            minValue: `${minWeekId}`,
            maxValue: `${maxWeekId}`
          }
        ],
        condition: 'must',
        computeFilters: [],
        nestedFilterConditions: []
      },
      doAggregation: true,
      returnDocuments: false,
      processDocuments: false,
      aggregations: [
        {
          aggregationFields: [
            {
              aggregateByFieldDisplayName: 'originalInvoiceNumber',
              aggregateByFieldName: 'originalInvoiceNumber',
              function: 'cardinality',
              canBeExported: true
            }
          ],
          groupByFieldName: 'retailerId'
        }
      ],
      SortFilter: {
        sortFields: [
          {
            aggregateByFieldDisplayName: 'originalInvoiceNumber',
            aggregateByFieldName: 'originalInvoiceNumber',
            function: 'cardinality',
            canBeExported: true
          }
        ]
      },
      searchType: 'beacon-sd-poShipmentInvoiceSummary_fetcher'
    }
  ];
};

// Dispute Submission - Expected Request Body
interface DisputeContactUsCase {
  disputeId: string;
  originalInvoiceNum: string;
  invoiceDate: string;
  openShortageInvoiceAmount: string;
  purchaseOrderNum: string;
}

export interface SubmitDisputesRequestBody {
  beaconClientId: string;
  retailerId: string | number;
  submissionType: string;
  invoicesToSubmitForDispute: string[];
  disputesToSubmitForContactUs: DisputeContactUsCase[];
}

export interface SubmissionRequestParameters {
  disputes: Dispute[];
  retailerId?: number | string;
  beaconClientId: any;
}

// Request for submission of disputes
export const createSubmitDisputesRequestBody = ({
  disputes,
  retailerId = 1,
  beaconClientId
}: SubmissionRequestParameters): SubmitDisputesRequestBody => {
  const contactUsDisputes: DisputeContactUsCase[] = [];
  const invoiceDisputes: string[] = [];
  // Loop through disputes array and separate into contact us and invoice disputes
  disputes.forEach((dispute) => {
    if (dispute.disputeStage === 'Contact Us Case') {
      const formattedDisputeValue = dispute.disputeValue.replace(/\$/g, '');

      contactUsDisputes.push({
        disputeId: dispute.disputeId,
        originalInvoiceNum: dispute.originalInvoice,
        invoiceDate: invoiceDateToISOFormattedDate(dispute.invoiceDate),
        openShortageInvoiceAmount: formattedDisputeValue,
        purchaseOrderNum: dispute.purchaseOrder
      });
    } else {
      invoiceDisputes.push(dispute.originalInvoice);
    }
  });

  // Create the request bodies
  const requestBodies: SubmitDisputesRequestBody[] = [];

  // Add invoice disputes to the request bodies
  if (invoiceDisputes.length > 0) {
    requestBodies.push({
      beaconClientId,
      retailerId,
      submissionType: 'dispute',
      invoicesToSubmitForDispute: invoiceDisputes,
      disputesToSubmitForContactUs: []
    });
  }

  // Add contact us disputes to the request bodies
  if (contactUsDisputes.length > 0) {
    requestBodies.push({
      beaconClientId,
      retailerId,
      submissionType: 'contactUs',
      invoicesToSubmitForDispute: [],
      disputesToSubmitForContactUs: contactUsDisputes
    });
  }

  return requestBodies;
};

export interface InvoiceSearchRequestParameters {
  searchTerm: string;
  retailerId?: string | number;
  startWeek: string | number;
  endWeek: string | number;
}

/**
 * Takes a stringified search term and creates an advanced search request with it
 * @param searchTerm
 * @returns an advanced search request body
 */
export const buildInvoiceSearchRequest = ({
  searchTerm,
  retailerId = 1,
  startWeek,
  endWeek
}: InvoiceSearchRequestParameters) => {
  return [
    {
      name: 'disputes-table-search-results',
      id: 'disputes-table-search-results',
      pageNumber: 1,
      pageSize: 1200,
      retailerId: `${retailerId}`,
      period: 'year',
      aggregations: [
        {
          aggregationFields: [],
          conditions: {
            rangeFilters: [
              {
                fieldName: 'weekId',
                minValue: startWeek,
                maxValue: endWeek
              }
            ],
            termFilters: []
          },
          groupByFieldName: 'originalInvoiceNumber'
        }
      ],
      conditions: {
        termFilters: [
          {
            fieldName: 'retailerId',
            values: [`${retailerId}`]
          }
        ],
        rangeFilters: [],
        condition: 'must',
        computeFilters: [],
        nestedFilterConditions: [
          {
            condition: 'should',
            termFilters: [
              {
                fieldName: 'originalInvoiceNumber',
                values: [`${searchTerm}`]
              },
              {
                fieldName: 'purchaseOrderNumber',
                values: [`${searchTerm}`]
              },
              {
                fieldName: 'shipmentArn',
                values: [`${searchTerm}`]
              }
            ],
            nestedFilterConditions: [
              {
                condition: 'must',
                termFilters: [
                  {
                    fieldName: 'retailerSku',
                    values: [`${searchTerm}`]
                  }
                ],
                rangeFilters: [
                  {
                    fieldName: 'updatedShortageAmountBeforeDispute',
                    minValue: '1',
                    maxValue: ''
                  }
                ]
              }
            ]
          },
          {
            condition: 'should',
            termFilters: [
              {
                fieldName: 'documentType',
                values: ['invoiceSummary', 'poShipmentSummary']
              },
              {
                fieldName: 'document_join_field',
                values: ['asinDetailMetrics']
              }
            ]
          }
        ]
      },
      doAggregation: true,
      returnDocuments: true,
      processDocuments: true,
      SortFilter: {
        sortFields: []
      },
      searchType: 'beacon-sd-poShipmentInvoiceSummary_fetcher',
      searchBy: 'child'
    }
  ];
};
