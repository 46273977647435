import { Box } from '@mui/system';
import { SlColumn, SlRow, useStacklineTheme } from '@stackline/ui';
import React, { useMemo, useState } from 'react';
import SegmentCardGrid from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Segments/SegmentCardGrid';
import SegmentNoDataPlaceholder from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Segments/SegmentNoDataPlaceholder';
import { SearchTextField } from 'src/components/BeaconRedesignComponents/SearchInput/SearchInputBar';
import { useDebouncedValue, useAppSelector } from 'src/utils/Hooks';

interface TopicsViewTabProps {
  /**
   * True if we are viewing team topics
   */
  team?: boolean;
}

const TopicsViewTab = ({ team }: TopicsViewTabProps) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebouncedValue(search);

  const theme = useStacklineTheme();
  const myTopics = useAppSelector((state) => state.segments.mySearchTermLists);
  const teamTopics = useAppSelector((state) => state.segments.teamSearchTermLists);
  const topics = useMemo(() => (team ? teamTopics : myTopics), [myTopics, team, teamTopics]);

  const filteredTopics = useMemo(() => {
    return topics.filter((topic) => {
      const displayMatches = topic.displayName.toLowerCase().includes(debouncedSearch.toLowerCase());

      const owner = `${topic.segment.owner.firstName} ${topic.segment.owner.lastName}`;
      const ownerMatches = owner.toLowerCase().includes(debouncedSearch.toLowerCase());

      return displayMatches || ownerMatches;
    });
  }, [debouncedSearch, topics]);

  return (
    <SlColumn widths="full">
      <SearchTextField
        placeholder="Search topics"
        value={search}
        onChange={(event) => {
          const { value } = event.target;
          setSearch(value);
        }}
      />
      <SlRow horizontalPosition="center">
        {filteredTopics.length < 1 && (
          <Box marginTop={theme.spacing.xxl}>
            <SegmentNoDataPlaceholder
              title="No topics found"
              subtitle={`There are currently no topics to display. All ${
                team ? 'team' : 'your'
              } topics will be available on this page.`}
            />
          </Box>
        )}
      </SlRow>
      {filteredTopics.length > 0 && (
        <Box marginTop={theme.spacing.lg} width="100%">
          <SegmentCardGrid
            segments={filteredTopics}
            indexName="traffic"
            fieldName="totalClicks"
            metricDisplayName="Total Traffic"
            color="accentMagenta"
            isTopicSegment
          />
        </Box>
      )}
    </SlColumn>
  );
};

export default TopicsViewTab;
