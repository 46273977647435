import React from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import LineChartForCardView from 'src/components/Omni/OmniGeoMap/OmniGeoCardViewRenewal/LineChartForCardViewRenewal';
import { OmniMapDataMetric } from 'src/components/Omni/OmniGeoMap/OmniGeoMapContainerRenewal';
import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import ReduxStore from 'src/types/store/reduxStore';
import { useSelector } from 'react-redux';
import './cardView.scss';
import { METRICTYPE } from 'src/utils/entityDefinitions/entityDefinitionTypes';
import OmniArrowChange from 'src/components/Omni/OmniArrowChange/OmniArrowChange';
import isEmpty from 'lodash/isEmpty';

export interface CardViewOmniGeoProps {
  geoInfo: string;
  id: string;
  metric: OmniMapDataMetric;
  name: string;
  shortName: string;
  trendData: number[][];
  dataForCompare: number[][];
  legendValue: number;
  percentChange: number;
}

const CardViewOmniGeo: React.FC<CardViewOmniGeoProps> = (props: CardViewOmniGeoProps) => {
  const mainSeriesColor = '#052849';
  const compareSeriesColor = '#adbdcc';
  const { name, trendData, shortName, metric, legendValue, percentChange, dataForCompare, geoInfo } = props;
  const retailer = useSelector((store: ReduxStore) => store.retailer);
  const app = useSelector((state: ReduxStore) => state.app);
  const omniRegionsFollowing = useSelector((state: ReduxStore) => state.omniRegionsFollowing);
  const omniCountriesFollowing = useSelector((state: ReduxStore) => state.omniCountriesFollowing);
  const filters = useSelector((state: ReduxStore) => state.filters);
  const { segment } = filters;
  const { searchParams } = app.queryParams;
  const preventNav = (e) => {
    if (!isEmpty(segment)) {
      e.preventDefault();
    }
  };
  const nameGenerator = (dName: string) => {
    if (geoInfo === 'region') {
      const currentRegion = omniRegionsFollowing.find((region) => region.regionCode === dName);
      return currentRegion.regionName;
    }

    if (geoInfo === 'country') {
      const currentCountry = omniCountriesFollowing.find((country) => country.countryCode === dName);
      return currentCountry.countryName;
    }

    return dName;
  };

  return (
    <Card className="card-view" elevation={0}>
      <Link to={`/${geoInfo}/${name}${searchParams}&tab=scorecard&subtab=all`} onClick={preventNav}>
        <div className="card-view-section logo">
          <div className="no-image">{shortName.toUpperCase()}</div>
        </div>
        <div className="card-view-section name truncate-overflow">{nameGenerator(name)}</div>
        <LineChartForCardView
          mainSeriesColor={mainSeriesColor}
          compareSeriesColor={compareSeriesColor}
          data={trendData}
          dataForCompare={dataForCompare}
        />
        <div className="info-container">
          <div className="card-view-section info">
            <div className="info-type">{metric.displayName}</div>
            <div>
              <span className="legend-card-view">
                {convertMetricToDisplayValue(retailer, legendValue, metric.metricType, '$')}
              </span>
              <span style={{ marginLeft: '25px' }}>
                <OmniArrowChange value={percentChange} size={5} />
                <span className="legend-card-view-compare-value">
                  {convertMetricToDisplayValue(retailer, percentChange, METRICTYPE.PERCENT, '$')}
                </span>
              </span>
            </div>
          </div>
        </div>
      </Link>
    </Card>
  );
};
export default CardViewOmniGeo;
