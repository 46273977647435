import { StacklineTheme } from '@stackline/ui';
import { METRICTYPE } from 'src/utils/entityDefinitions';

/**
 * Primarily used to get the color for the metric change value
 * @param valueChange A formatted string or raw number for display
 * @param theme theme from StackUI
 * @returns a color based on whether the string contains a minus sign or not
 */
export const determineColor = (valueChange: string | number, theme: StacklineTheme) => {
  if (typeof valueChange !== 'string') {
    return theme.colors.primary;
  }
  return valueChange.includes('-') ? theme.colors.error : theme.colors.success;
};

export interface MetricCellProps {
  data: any;
  field: string;
}

/**
 * Scans the field name string for certain keywords to determine what metric type to use when formatting raw values.
 * @param field String used to access data on a row object for display. Set in the column definition.
 * @returns a metric type constant
 */
export const getMetricTypeForField = (field) => {
  const lowerCasedField = field.toLowerCase();
  if (lowerCasedField.includes('percent')) {
    return METRICTYPE.PERCENT;
  } else if (lowerCasedField.includes('sales')) {
    return METRICTYPE.MONEY;
  } else {
    return METRICTYPE.VOLUME;
  }
};

/**
 * Formats a metric value based on its field or returns the original string representation.
 *
 * @param {Object} parameters - parameters object containing the value, field, and formatMetric function.
 * @param {string | number} parameters.value - The metric value to be formatted.
 * @param {string} parameters.field - The field associated with the metric value.
 * @param {Function} parameters.formatMetric - The function used to format the metric value.
 * @returns {string} - The formatted metric value or the original string representation.
 */
export const formatOrReturnMetric = ({ value, field, formatMetric }) => {
  const pattern = /[+\-,%$]/; // Check for certain symbols to determine if the string is formatted or not
  const stringifiedValue = String(value);

  // If the value is a raw number or it is not formatted, then we format it before returning it
  if (typeof value === 'number' || !pattern.test(stringifiedValue)) {
    const metricType = getMetricTypeForField(field);
    return formatMetric(stringifiedValue, metricType);
  } else if (value.includes('NaN') || Number.isNaN(value)) {
    return '0.0%';
  } else {
    return stringifiedValue;
  }
};

interface HidePaginationParams {
  /**
   * The current page number
   */
  page: number;
  /**
   * The max # of items per page
   */
  pageSize: number;
  /**
   * The number of rows visible on the current page
   */
  rowCount: number;
}
/**
 * We hide the pagination controls if on page 1 and if we have less rows than the page size.
 */
export const shouldHidePagination = ({ page, pageSize, rowCount }: HidePaginationParams) => {
  return page === 1 && rowCount < pageSize;
};
