import React from 'react';
import numeral from 'numeral';
import ReactDOMServer from 'react-dom/server.browser';
import _ident from 'lodash/identity';
import _zipWith from 'lodash/zipWith';
import { Option } from 'funfix-core';
import queryString from 'qs';
import { AdManagerAdCampaignEntity } from 'sl-api-connector/types';

import { store } from 'src/main';
import { buildAggregations, buildTimePeriodRangeFilters } from 'src/components/AdManager/Search';
import { receiveEntitySalesMetrics, fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { truncateWithEllipsis } from 'src/utils/stringFormatting';
import colors from 'src/utils/colors';
import { propEq, prop } from 'src/utils/fp';
import { PushFn, ESSDataKey } from 'src/types/application/types';
import { buildEntityLink } from 'src/components/AdManager/AmsUtils';
import ReduxStore from 'src/types/store/reduxStore';

export const buildScatterPlotDataKey = (subtab: string, entityName: string) =>
  `adEntityScatterPlot-${subtab}-${entityName}`;

export interface GraphMarker {
  x: number;
  y: number;
  id: string;
  name: string;
  marker?: { fillColor: string };
}

const ScatterPlotLegend = ({ name }: { name: React.ReactNode }) => (
  <div style={{ fontFamily: 'Roboto, sans-serif' }}>{name}</div>
);

const tooltipStyles: { [key: string]: React.CSSProperties } = {
  container: { color: colors.darkBlue, fontSize: 12, padding: 20 },
  type: {
    display: 'flex',
    flexDirection: 'column',
    background: '#eff1f5',
    padding: '5px 16px',
    fontSize: 14,
    width: 'fit-content',
    borderRadius: 47
  },
  title: {
    marginTop: 10,
    fontSize: 16,
    minWidth: 190,
    maxWidth: 250,
    whiteSpace: 'normal',
    lineHeight: '1.5em',
    height: '3em' /* height is 2x line-height, so two lines will display */,
    overflow: 'hidden'
  },
  value: {
    display: 'inline-block',
    marginRight: 10,
    fontSize: 22
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 14
  }
};

const ScatterPlotTooltip = ({
  data
}: {
  data: { name: string; series: { name: string }; x: number | string; y: number | string };
}) => {
  const name = truncateWithEllipsis(40, data.name);
  const type = data.series.name;
  const xValue = numeral(data.x).format('$0.00a');
  const yValue = numeral(data.y).format('$0.00a');
  const xLabel = 'Spend';
  const yLabel = 'Sales';

  return (
    <div style={tooltipStyles.container} className="hichart-tooltip">
      <div style={tooltipStyles.type}>{type}</div>
      <div style={tooltipStyles.title}>{name}</div>
      <div style={{ marginTop: 10 }}>
        <span style={{ color: '#47A8F6', ...tooltipStyles.value }}>{xValue}</span>

        <span style={{ color: '#47A8F6', ...tooltipStyles.label }}>{xLabel}</span>
      </div>
      <div style={{ marginTop: 10 }}>
        <span style={{ color: '#185AA2', ...tooltipStyles.value }}>{yValue}</span>

        <span style={{ color: '#185AA2', ...tooltipStyles.label }}>{yLabel}</span>
      </div>
    </div>
  );
};

const entityIdByGroupByFieldName = new Map(
  Object.entries({
    entityId: 'adEntity',
    portfolioId: 'adPortfolio',
    campaignId: 'adCampaign'
  })
);

const labelFormatter = function labelFormatter(this: {
  chart: { xAxis: [{ dataMin: number; dataMax: number }]; yAxis: [{ dataMin: number; dataMax: number }] };
  axis: { isXAxis: boolean };
  value: number;
}) {
  const { dataMax = 0, dataMin = 0 } = this.chart[this.axis.isXAxis ? 'xAxis' : 'yAxis'][0];
  const dataRange = dataMax - dataMin;
  const formatString = dataRange > 1000 ? '($0.00a)' : '$1,000';

  return ReactDOMServer.renderToString(
    <span style={{ fontFamily: 'Roboto, sans-serif', color: colors.darkBlue, fontSize: 11 }}>
      {numeral(this.value).format(formatString)}
    </span>
  );
};

export const buildChartOptions = ({ push }: { push: PushFn }) => {
  // TODO: Abstract
  const { selectedEntityName } = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: 100
  });

  return {
    chart: {
      type: 'scatter',
      zoomType: 'xy',
      height: 650,
      animation: false
    },
    title: {
      text: ''
    },
    legend: {
      align: 'right',
      itemStyle: {
        color: colors.darkBlue
      },
      verticalAlign: 'top',
      labelFormatter() {
        return ReactDOMServer.renderToString(<ScatterPlotLegend name={(this as any).name} />);
      }
    },
    yAxis: {
      crosshair: false,
      title: {
        text: 'Ad Sales',
        style: { fontFamily: 'Roboto, sans-serif', color: colors.labelGrey, fontSize: 13 }
      },
      labels: {
        formatter: labelFormatter
      },
      minRange: 100
    },
    xAxis: {
      title: {
        text: 'Ad Spend',
        style: { fontFamily: 'Roboto, sans-serif', color: colors.labelGrey, fontSize: 13 }
      },
      labels: {
        formatter: labelFormatter
      },
      minRange: 100
    },
    credits: {
      enabled: false
    },
    exporting: { enabled: false },
    tooltip: {
      borderWidth: 1,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      useHTML: true,
      shadow: false,
      positioner(labelWidth: number, labelHeight: number, point: any) {
        return { x: point.plotX - 50, y: point.plotY - labelHeight + 30 };
      },
      formatter() {
        return ReactDOMServer.renderToString(<ScatterPlotTooltip data={(this as any).point} />);
      }
    },
    plotOptions: {
      series: {
        cursor: ['entityId', 'portfolioId', 'campaignId'].includes(selectedEntityName) ? 'pointer' : undefined,
        point: {
          events: {
            click: function handleClick() {
              // TODO: Abstract
              const queryParams: { [queryParam: string]: any } = queryString.parse(window.location.search, {
                ignoreQueryPrefix: true,
                arrayLimit: 100
              });

              // eslint-disable-next-line
              const { selectedEntityName } = queryParams;

              const entityType = entityIdByGroupByFieldName.get(selectedEntityName);
              if (!entityType) {
                return;
              }

              const { id } = (this as any).options;
              if (!id || id.toUpperCase() === 'UNASSIGNED') {
                return;
              }

              push(buildEntityLink(entityType, id));
            }
          }
        }
      },
      scatter: {
        // Disable turbo since that doesn't allow for point names
        turboThreshold: 1e10,
        marker: {
          symbol: 'circle',
          radius: 6
        },
        states: {
          hover: {
            marker: {
              enabled: false
            }
          }
        },
        tooltip: {
          headerFormat: '<b>{point.name}</b><br>',
          // eslint-disable-next-line no-template-curly-in-string
          pointFormat: '${point.x}, ${point.y}',
          pointFormatter: function pointFormatter(): string {
            const datum = this as any as { x: number; y: number; name: string };

            return ReactDOMServer.renderToString(
              <>
                {truncateWithEllipsis(40, datum.name)}
                <br />
                <b style={{ fontWeight: 500 }}>Sales</b>: {numeral(datum.y).format('$1,000.00')} <br />
                <b style={{ fontWeight: 500 }}>Spend</b>: {numeral(datum.x).format('$1,000.00')}
              </>
            ).replace(/<!--|-->/g, '');
          }
        }
      }
    }
  };
};

const MANUAL_GROUP_BY_NAMES = new Map(
  Object.entries({
    portfolioId: 'adPortfolios',
    entityId: 'adEntities'
  })
);

const getScatterPlotAggregations = ({ app }: { app: ReduxStore['app'] }) => {
  const metricFields = ['spend', 'sales'].map((fieldName) => INDEX_FIELDS.getField(app.name, 'advertising', fieldName));
  const [{ aggregations: aggregationFields }] = buildAggregations(metricFields);

  return { metricFields, aggregationFields };
};

const zipFetchedData = (selectedEntityName: string, dataSet: ESSDataKey): GraphMarker[] => {
  const names = dataSet[`sales_by_${selectedEntityName}`].data.map(prop('name'));
  const sales = dataSet[`sales_by_${selectedEntityName}`].data.map(prop('value'));
  const spend = dataSet[`spend_by_${selectedEntityName}`].data.map(prop('value'));
  const ids: string[] = dataSet[`spend_by_${selectedEntityName}`].data.map(
    (datum) => (datum.entity! as AdManagerAdCampaignEntity).campaignId
  );

  return _zipWith(names, sales, spend, ids, (name, salesValue, spendValue, id) => {
    return {
      name: name || 'Unknown',
      y: Math.round(salesValue * 100) / 100,
      x: Math.round(spendValue * 100) / 100,
      id
    };
  });
};

export const fetchData = async (
  entityName: string,
  {
    mainEntity,
    retailer,
    app,
    mainTimePeriod,
    adCampaigns,
    adPortfolios,
    adEntities
  }: Pick<ReduxStore, 'retailer' | 'app' | 'mainTimePeriod'> & {
    mainEntity: ReduxStore['entityService']['mainEntity'];
    adCampaigns: NonNullable<ReduxStore['adCampaigns']>;
    adPortfolios: NonNullable<ReduxStore['adPortfolios']>;
    adEntities: NonNullable<ReduxStore['adEntities']>;
  },
  subtab: string
) => {
  // There are no group-by fields for some entities, so we have to fetch data by `campaignId` and then aggregate it
  // manually on the frontend.
  const parentDataSetKey = MANUAL_GROUP_BY_NAMES.get(entityName);
  const groupByFieldName = parentDataSetKey ? 'campaignId' : entityName;
  const indexName = 'adCampaignAdGroupProductTargetDailyMetrics';
  const { aggregationFields } = getScatterPlotAggregations({ app });
  const groupByField = INDEX_FIELDS.getField(app.name, indexName, groupByFieldName);
  const { mainTimePeriodRangeFilters } = buildTimePeriodRangeFilters({
    app,
    indexName,
    mainTimePeriod
  });

  const dataKey = buildScatterPlotDataKey(subtab, groupByFieldName);
  const campaignDataSet = await store.dispatch(
    fetchEntityMetrics(
      dataKey,
      {
        entity: mainEntity,
        retailer,
        app,
        indexName
      },
      [
        {
          doAggregation: true,
          aggregations: [
            {
              aggregationFields,
              conditions: {
                termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
                rangeFilters: [...mainTimePeriodRangeFilters]
              },
              groupByFieldName: groupByField.name
            }
          ],
          conditions: {
            termFilters: [],
            rangeFilters: [...mainTimePeriodRangeFilters]
          },
          pageSize: 1000,
          processDocuments: true
        }
      ],
      undefined,
      true
    )
  );

  // Using the fetched campaign data, group them again by parent entity ID
  const zippedCampaignData = zipFetchedData(groupByFieldName, campaignDataSet);

  if (!parentDataSetKey) {
    store.dispatch(receiveEntitySalesMetrics(buildScatterPlotDataKey(subtab, entityName), [...zippedCampaignData]));
    return;
  }

  const initialAcc: Map<string, string> = new Map();
  const parentEntityIdByCampaignId: Map<string, string> = adCampaigns.reduce((acc, campaignEntity) => {
    const { id } = campaignEntity;
    // TODO retype this file when the API is finalized
    const parentEntityId = (campaignEntity.extendedAttributes as any)[entityName] as string;

    if (!parentEntityId) {
      return acc;
    }

    acc.set(id, parentEntityId);

    return acc;
  }, initialAcc);

  const parentDataSet: { id: string; name: string }[] = new Map(Object.entries({ adEntities, adPortfolios })).get(
    parentDataSetKey
  )!;

  const initialTotalsAcc: { [parentEntityId: string]: { sales: number; spend: number } } = {
    UNASSIGNED: { sales: 0, spend: 0 }
  };
  const totalsByParentEntity: {
    [parentEntityId: string]: { sales: number; spend: number };
  } = zippedCampaignData.reduce((acc, datum) => {
    const parentId = parentEntityIdByCampaignId.get(datum.id!);

    if (!parentId) {
      acc.UNASSIGNED.sales += datum.y;
      acc.UNASSIGNED.spend += datum.x;
      return acc;
    }

    if (!acc[parentId]) {
      acc[parentId] = { sales: datum.y, spend: datum.x };
      return acc;
    }

    acc[parentId].sales += datum.y;
    acc[parentId].spend += datum.x;

    return acc;
  }, initialTotalsAcc);

  const zippedParentMetrics = Object.entries(totalsByParentEntity)
    .map(([parentEntityId, totals]) => {
      // Don't show an "Unassigned" data point if there are no unassigned entities.
      if (parentEntityId === 'UNASSIGNED' && totals.spend === 0 && totals.sales === 0) {
        return null;
      }

      const parentEntityName =
        parentEntityId === 'UNASSIGNED'
          ? 'Unassigned'
          : Option.of(parentDataSet.find(propEq('id', parentEntityId)))
              .map(prop('name'))
              .getOrElse('Unknown');
      return {
        x: totals.spend,
        y: totals.sales,
        name: parentEntityName,
        id: parentEntityId
      };
    })
    .filter(_ident);

  store.dispatch(receiveEntitySalesMetrics(buildScatterPlotDataKey(subtab, entityName), [...zippedParentMetrics]));
};
