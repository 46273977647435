import {
  BuyBoxSellersDataResponse,
  MerchantMetaData
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/BuyBox/Sellers/types';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { useAppSelector } from 'src/utils/Hooks';
import _get from 'lodash/get';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import {
  useApplyEntityFilters,
  useRemoveRetailPriceRangeFilters
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';

export const parseBeaconBuyBoxSellersResponse = (response: BuyBoxSellersDataResponse) => {
  const merchantAggregations = _get(
    response,
    ['data', '0', 'aggregations', 'by_merchantName'],
    []
  ) as BuyBoxSellersDataResponse['data']['0']['aggregations']['by_merchantName'];
  const buyBoxWeighted_sum_value_by_retailerId = _get(
    response,
    ['data', '1', 'aggregations', 'by_retailerId', '0', 'additionalValues', 'buyBoxWeighted_sum_value'],
    0
  ) as BuyBoxSellersDataResponse['data']['1']['aggregations']['by_retailerId']['0']['additionalValues']['buyBoxWeighted_sum_value'];

  return merchantAggregations.reduce((tupleCollection, merchantData: MerchantMetaData) => {
    const {
      fieldId,
      additionalValues: { winsBySeller_sum_value }
    } = merchantData;

    const value =
      buyBoxWeighted_sum_value_by_retailerId > 0 ? winsBySeller_sum_value / buyBoxWeighted_sum_value_by_retailerId : 0;
    // Filter if the value is less than 0.0000 followed logic -- // https://gitlab.com/stackline-dev/website/-/blame/14fe62b4d67924513d907fd2b889b2cf386a0197/src/components/EntityPage/BuyBox/SellerSummary.tsx#L257

    if (Number(value.toFixed(4)) > 0) {
      const merchantNameValueTuple = [fieldId, value];
      return [...tupleCollection, merchantNameValueTuple];
    } else {
      return tupleCollection;
    }
  }, []);
};

export const useBeaconBuyBoxSellersData = () => {
  const retailer = useAppSelector((state) => state.retailer);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const { startWeek, endWeek } = mainTimePeriod;
  const applyEntityFilters = useApplyEntityFilters();
  const removeRetailPriceRangeFilters = useRemoveRetailPriceRangeFilters('buybox');

  const aggregationByMerchantName = new AggregationBuilder('merchantName')
    .addAggregationField('Weighted Buy Box  Rate', 'buyBoxWeighted', 'sum', false)
    .addAggregationField('Buy Box - Seller', 'winsBySeller', 'sum', false)
    .setSortDirection(null)
    .setSortByAggregationField('Weighted Buy Box  Rate', 'buyBoxWeighted', 'sum', false)
    .addConditionTermFilter('retailerId', [retailer.id])
    .addConditionRangeFilter('weekId', startWeek, endWeek)
    .build();

  const aggregationByRetailerId = new AggregationBuilder('retailerId')
    .addAggregationField('Weighted Buy Box  Rate', 'buyBoxWeighted', 'sum', false)
    .addAggregationField('Buy Box - Seller', 'winsBySeller', 'sum', false)
    .setSortDirection(null)
    .setSortByAggregationField('Weighted Buy Box  Rate', 'buyBoxWeighted', 'sum', false)
    .addConditionTermFilter('retailerId', [retailer.id])
    .addConditionRangeFilter('weekId', startWeek, endWeek)
    .build();

  // Create an advanced search request using the AdvancedSearchRequestBuilder class
  const requestByMerchant = new AdvancedSearchRequestBuilder(
    `mainEntityMetrics-client-${beaconClientId}`,
    'beacon-buybox'
  )
    .setPageNumber(1)
    .setPageSize(200)
    .setRetailerId(retailer.id)
    .setPeriod('year')
    .setDoAggregation(true)
    .setReturnDocuments(false)
    .addConditionTermFilter('retailerId', 'should', [retailer.id])
    .setSearchBy('parent')
    .addAggregation(aggregationByMerchantName)
    .addSortField('Weighted Buy Box  Rate', 'buyBoxWeighted', 'sum', false)
    .apply(applyEntityFilters)
    .apply(removeRetailPriceRangeFilters);

  const requestByRetailerId = new AdvancedSearchRequestBuilder(
    `mainEntityMetrics-client-${beaconClientId}`,
    'beacon-buybox'
  )
    .setPageNumber(1)
    .setPageSize(200)
    .setRetailerId(retailer.id)
    .setPeriod('year')
    .setDoAggregation(true)
    .setReturnDocuments(false)
    .addConditionTermFilter('retailerId', 'should', [retailer.id])
    .setSearchBy('parent')
    .addAggregation(aggregationByRetailerId)
    .addSortField('Weighted Buy Box  Rate', 'buyBoxWeighted', 'sum', false)
    .apply(applyEntityFilters)
    .apply(removeRetailPriceRangeFilters);

  const requestArray = [requestByMerchant.build(), requestByRetailerId.build()];

  const { isLoading, data } = useGenericAdvancedSearch({
    requestId: 'buyBoxSellers-columnChart',
    requestBody: requestArray,
    queryKeys: requestArray
  });

  return { isLoading, data };
};
