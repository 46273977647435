/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import _startCase from 'lodash/startCase';
import _pick from 'lodash/pick';
import { Option } from 'funfix-core';
import axios from 'axios';
import _last from 'lodash/last';
import CloseButton from 'src/components/common/Buttons/CloseButton';
import { store } from 'src/main';
import { setCreative } from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { withPush } from 'src/utils/hoc';
import fontStyle from 'src/utils/fontStyle';
import ReduxStore from 'src/types/store/reduxStore';
import { getAdCampaignsOfStrategyId } from 'src/store/modules/adManager/adCampaignBuilder/selectors';
import UploadAnimation from 'src/components/common/UploadAnimation/UploadAnimation';
import { PushFn } from 'src/types/application/types';
import { SectionWrapper, OperationButtons, buildSubtabLink } from '../Widgets/AdCampaignBuilderCommonWidgets';
import withStyles from '@mui/styles/withStyles';
import LinearProgress from '@mui/material/LinearProgress';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: '#EEEEEE'
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff'
  }
}))(LinearProgress);

export const muiStyles = {
  root: {
    margin: '20px 0 0 0',
    maxWidth: 250,
    '&:before': {
      content: 'none'
    }
  },
  select: {
    padding: '7px 27px 7px 0px',
    fontWeight: fontStyle.regularWeight,
    width: 250
  },
  textField: {
    width: 250
  }
};

const Specifications: React.FC<{ audioSpecs: any; videoSpecs: any }> = ({ audioSpecs, videoSpecs }) => {
  return (
    <>
      <div className="specs_header">Video Specs</div>
      <ul className="specs_list">
        {Object.entries(videoSpecs).map((spec, index) => {
          return <li key={index}>{spec[1].text}</li>;
        })}
      </ul>
      <div className="specs_header">Audio Specs</div>
      <ul className="specs_list">
        {Object.entries(audioSpecs).map((spec, index) => {
          return <li key={index}>{spec[1].text}</li>;
        })}
      </ul>
    </>
  );
};

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['adCampaignBuilder']),
  allPortfolios: Option.of(state.adCampaignBuilder.platformSettings)
    .flatMap(({ entity }) => Option.of(entity))
    .flatMap(({ settingId: entityId }) =>
      Option.of(state.adCampaignBuilder.setup.strategyId).map((strategyId) =>
        getAdCampaignsOfStrategyId(strategyId, entityId)(state)
      )
    )
    .orNull()
});

const VideoUpload: React.FC<{ classes: { [key: string]: any }; push: PushFn } & ReturnType<typeof mapStateToProps>> = ({
  adCampaignBuilder,
  push
}) => {
  const getDefaultStatus = () => {
    const medias = _get(adCampaignBuilder, ['creative', 'videoMedias'], []);
    if (medias.length > 0) {
      return {
        status: 'Available',
        errorMsg: '',
        medias
      };
    }
    return {
      status: 'PreUpload',
      errorMsg: '',
      medias: []
    };
  };

  const [{ enlarge, shrink }, setAnimationCallbacks] = useState({});
  const [currentStatus, setCurrentStatus] = useState(getDefaultStatus);

  // there are 4 status: *Preupload, *Uploading, *Verifing, Processing, PendingDeepValidation, Available, Failed

  useEffect(() => {
    const { creative } = store.getState().adCampaignBuilder;
    if (currentStatus.medias.length > 0) {
      store.dispatch(
        setCreative({
          ...creative,
          videoMedias: currentStatus.medias
        })
      );
    } else {
      store.dispatch(
        setCreative({
          ...creative,
          videoMedias: []
        })
      );
    }
  }, [currentStatus.medias]);

  useEffect(() => {
    const fetchNewStatus = async () => {
      const entity = _get(adCampaignBuilder, ['platformSettings', 'entity']);
      const GET_MEDIA_STATUS_API = `/apiAdManager/adCampaigns/getMediaStatus?entityId=${entity.id}&mediaId=${currentStatus.mediaCode}`;
      const mediaStatusRes = await axios.get(GET_MEDIA_STATUS_API);
      if (mediaStatusRes.status === 200) {
        const newStatus = _get(mediaStatusRes, ['data', 'status']);
        if (newStatus === 'Available') {
          const mediaUrl = _get(mediaStatusRes, ['data', 'publishedMediaUrl']);
          const { fileName, mediaCode } = currentStatus;
          setCurrentStatus({
            status: 'Available',
            errorMsg: '',
            medias: [
              {
                fileName,
                mediaUrl,
                mediaCode
              }
            ]
          });
        } else if (newStatus === 'Failed') {
          const errorCode = _get(mediaStatusRes, ['data', 'statusMetadata', 'errors', 0, 'code'], '');
          const errorOriginal = _get(mediaStatusRes, ['data', 'statusMetadata', 'errors', 0, 'message'], '');
          const errorDetail = errorOriginal.split('. ');
          setCurrentStatus({
            status: 'Failed',
            errorMsg: _startCase(errorCode),
            errorDetail,
            medias: []
          });
        }
      }
    };

    const timer = setInterval(() => {
      if (['Verifying', 'Processing', 'PendingDeepValidation'].includes(currentStatus.status)) {
        fetchNewStatus();
      }
    }, 15000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus.status]);

  if (_isNil(adCampaignBuilder.platformSettings)) {
    // Redirect to start of the flow if Redux is in an invalid state due to entering in the middle of the flow
    push(buildSubtabLink('platformSelect'));
    return null;
  }
  const { entity } = adCampaignBuilder.platformSettings;

  const canContinue = _get(adCampaignBuilder, ['creative', 'videoMedias', 'length']);

  const validateFile = (file) => {
    const extension = _last(file.name.split('.'));
    if (!['mp4', 'mov'].includes(extension.toLowerCase())) {
      return 'You must supply a .mp4 or .mov file.';
    }
    return null;
  };

  const handleUploadFileChange = async (event: any) => {
    const file = event.target.files[0];
    const errMsg = validateFile(file);

    if (errMsg) {
      setCurrentStatus({
        status: 'Failed',
        errorMsg: 'Invalid File Type',
        errorDetail: [errMsg],
        medias: []
      });
    }

    const UPLOAD_URL_API = `/apiAdManager/adCampaigns/uploadMedia/${entity.id}`;
    const uploadConfig = {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (percentCompleted === 100) {
          setCurrentStatus({
            status: 'Uploaded',
            errorMsg: '',
            medias: [],
            progress: 100,
            fileName: file.name
          });
        } else {
          setCurrentStatus({
            status: 'Uploading',
            errorMsg: '',
            medias: [],
            progress: percentCompleted,
            fileName: file.name
          });
        }
      }
    };
    const uploadFile = new FormData();
    uploadFile.append('file', file);
    setCurrentStatus({
      status: 'Uploading',
      errorMsg: '',
      medias: [],
      progress: 0,
      fileName: file.name
    });
    const uploadRes = await axios.post(UPLOAD_URL_API, uploadFile, uploadConfig);
    if (uploadRes.status === 200) {
      // uploading success, set the status to verifing so useEffect can start fetching the status.
      const mediaCode = uploadRes.data;
      setCurrentStatus({
        status: 'Verifying',
        errorMsg: '',
        medias: [],
        progress: 100,
        fileName: file.name,
        mediaCode
      });
    }
  };

  const videoSpecs = {
    ratio: {
      text: '16:9 aspect ratio',
      pass: null
    },
    resolution: {
      text: '1280 x 720px, 1920 x 1080px or 3840 x 2160px',
      pass: null
    },
    fps: {
      text: '23.976, 24, 25, 29.97, or 30 fps',
      pass: null
    },
    rate: {
      text: '1 Mbps or higher bit rate',
      pass: null
    },
    codec: {
      text: 'H.264 or J.265 codec',
      pass: null
    },
    length: {
      text: '6~45 sec long',
      pass: null
    },
    size: {
      text: '500 MB or smaller',
      pass: null
    },
    fileType: {
      text: 'MP4 or MOV file',
      pass: null
    },
    profile: {
      text: 'Main or baseline profile',
      pass: null
    },
    scanType: {
      text: 'Progressive scan type',
      pass: null
    },
    stream: {
      text: '1 video stream only',
      pass: null
    }
  };

  const audioSpecs = {
    sampleRate: {
      text: '44.1 kHz or higher sample rate',
      pass: null
    },
    codec: {
      text: 'PCM, AAC or MP3 codec',
      pass: null
    },
    bitRate: {
      text: '96 kbps or higher bit rate',
      pass: null
    },
    format: {
      text: 'stereo or mono format',
      pass: null
    },
    stream: {
      text: 'Not more than 1 audio stream',
      pass: null
    }
  };

  const resetStatus = () => {
    setCurrentStatus({
      status: 'PreUpload',
      errorMsg: '',
      medias: []
    });
  };

  const randerMedia = () => {
    const media = _get(currentStatus, ['medias', 0], {});
    const { fileName, mediaUrl } = media;
    return (
      <>
        <div className="uploading_box">
          <div className="upload_box_top" style={{ display: 'flex', alignItems: 'center' }}>
            <div>{fileName}</div>
            <div>
              <CloseButton
                onClick={() => resetStatus()}
                style={{ position: 'relative', width: 25, height: 25, margin: 0 }}
              />
            </div>
          </div>
        </div>
        <div className="video_preview" style={{ fontSize: 20 }}>
          Video Preview:
        </div>
        <div className="video_preview">
          <video controls>
            <source src={mediaUrl} />
          </video>
        </div>
      </>
    );
  };

  return (
    <div className="ad-manager-container">
      <SectionWrapper header="Video" subheader="Please choose a video to showcase in your campaign.">
        <div className="video_upload">
          <div className="verify_area">
            <Specifications audioSpecs={audioSpecs} videoSpecs={videoSpecs} />
          </div>
          <div className="video_area">
            {currentStatus.status === 'PreUpload' && (
              <div className="upload_box">
                <label className="upload_background" htmlFor="file-upload" onMouseEnter={enlarge} onMouseLeave={shrink}>
                  <span style={{ transition: 'opacity 0.5s ease-in' }}>
                    <label className="pick_file">Upload video</label>
                    <input
                      style={{ display: 'none' }}
                      id="file-upload"
                      type="file"
                      onChange={handleUploadFileChange}
                    />{' '}
                  </span>
                  <UploadAnimation
                    className="bulk_upload_clip"
                    registerCallbacks={setAnimationCallbacks}
                    style={{ transition: 'opacity 0.5s ease-in', marginTop: -35 }}
                  />
                </label>
              </div>
            )}
            {['Uploading', 'Uploaded', 'Verifying', 'Processing', 'PendingDeepValidation'].includes(
              currentStatus.status
            ) && (
              <div className="uploading_box">
                <div className="upload_box_top">
                  <div>
                    <div className="updating_title">
                      {currentStatus.status === 'Uploading'
                        ? `Updating... ${currentStatus.progress}%`
                        : 'Verifying, this may take a few minutes...'}
                    </div>
                    <div className="updating_fileName">{currentStatus.fileName}</div>
                  </div>
                  <div></div>
                </div>
                <div className="upload_progress">
                  <BorderLinearProgress variant="determinate" value={currentStatus.progress} />
                </div>
              </div>
            )}
            {currentStatus.status === 'Failed' && (
              <div className="video_error">
                <div style={{ fontSize: 25 }}>{currentStatus.errorMsg}</div>
                <ul>
                  {currentStatus.errorDetail.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
                <LargeMuiButton
                  secondary
                  style={{ width: 88, height: 35, marginLeft: 0, marginRight: 10 }}
                  label="Retry"
                  onClick={resetStatus}
                />
              </div>
            )}
            {currentStatus.status === 'Available' && randerMedia()}
          </div>
        </div>
      </SectionWrapper>

      <OperationButtons subtab="videoUpload" canContinue={canContinue} />
    </div>
  );
};

export default connect(mapStateToProps)(withPush(VideoUpload));
