const RECEIVE_CHART_DATA_FOR_MAIN = 'src/omniChartService/RECEIVE_CHART_DATA_FOR_MAIN' as const;
const RECEIVE_CHART_DATA_FOR_COMPARE = 'src/omniChartService/RECEIVE_CHART_DATA_FOR_COMPARE' as const;
const RECEIVE_CHART_DATA_FOR_BOTH = 'src/omniChartService/RECEIVE_CHART_DATA_FOR_BOTH' as const;
const DELETE_DATA_KEY = 'src/omniChartService/DELETE_DATA_KEY' as const;
const START_FETCH = 'src/omniChartService/START_FETCH' as const;
const END_FETCH = 'src/omniChartService/END_FETCH' as const;

export default {
  RECEIVE_CHART_DATA_FOR_MAIN,
  RECEIVE_CHART_DATA_FOR_COMPARE,
  RECEIVE_CHART_DATA_FOR_BOTH,
  START_FETCH,
  END_FETCH,
  DELETE_DATA_KEY
};
