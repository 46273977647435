const CLEAR_ALL_SAVED_SEARCH = 'src/segments/CLEAR_ALL_SAVED_SEARCH' as const;
const DELETE_SAVED_SEARCH = 'src/segments/DELETE_SAVED_SEARCH' as const;
const RECEIVE_ALL_SAVED_SEARCH = 'src/segments/RECEIVE_ALL_SAVED_SEARCH' as const;
const UPDATE_SEGMENT_COLOR = 'UPDATE_SEGMENT_COLOR' as const;

export default {
  CLEAR_ALL_SAVED_SEARCH,
  DELETE_SAVED_SEARCH,
  RECEIVE_ALL_SAVED_SEARCH,
  UPDATE_SEGMENT_COLOR
};
