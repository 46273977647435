import types from './types';
import actions from './actions';
import { ValueOf } from 'sl-api-connector/types';

const omniRegionsFollowing = (state = [], action: ReturnType<ValueOf<typeof actions>>) => {
  switch (action.type) {
    case types.RECEIVE_ALL_OMNI_REGIONS:
      return Object.assign([], action.data);
    default:
      return state;
  }
};

export default omniRegionsFollowing;
