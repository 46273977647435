/**
 * The purpose of this file is to accept the Widget from page layout which contains chart overrides and to set the
 * defaults for a simple column chart.  This file acts like the function `renderWeeklyTrendChart` and renders the
 * `GenericChart` component.
 */

import { Series } from 'highcharts';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _merge from 'lodash/merge';
import moment from 'moment';
import React, { Component, CSSProperties } from 'react';
import convertMetricToDisplayValue, { ConvertMetricToDisplayValueOptions } from 'src/components/EntityGrid/gridUtils';
import { ChartPropsOverride, MainEntityMetrics } from 'src/types/application/types';
import { Widget } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import { TimePeriod } from 'src/types/store/storeTypes';
import colors from 'src/utils/colors';
import fontStyle from 'src/utils/fontStyle';
import GenericChart from '../GenericChart';
import convertBarChartSeriesToDelimitedData from '../GenericChart/SeriesConverters/barChart';
import './ColumnChart.scss';

const defaults = {
  bar: {
    fillColor: colors.stacklineBlue,
    fillOpacity: 0.7,
    color: colors.stacklineBlue,
    colorByPoint: false,
    colors: [colors.stacklineBlue],
    pointWidth: 12
  },
  column: {
    fillColor: colors.stacklineBlue,
    fillOpacity: 0.7,
    color: colors.stacklineBlue,
    colorByPoint: false,
    colors: [colors.stacklineBlue],
    pointWidth: 36
  },
  noData: {
    style: {
      fontWeight: '400',
      fontSize: '20px',
      color: colors.quartz
    }
  },
  yAxis: [
    {
      gridLineWidth: 0,
      index: 0,
      labels: { enabled: false },
      rotate: 0,
      title: { text: '' }
    }
  ]
};

function defaultColumnChartFormatter(this: { value: number }) {
  return this.value;
}

interface Props {
  chartDisplayTimePeriod: TimePeriod;
  mainEntityMetrics: MainEntityMetrics;
  onPointSelect?: (dynamicThis: any) => {};
  selectedPoint?: number | string;
  widget: Widget;
  retailer: ReduxStore['retailer'];
  style?: CSSProperties;
  labelRotation?: number;
  appDetails?: ReduxStore['app'];
  numberFormatOptions?: ConvertMetricToDisplayValueOptions & { showFullValue?: boolean };
}

class ColumnChartContainer extends Component<Props> {
  public static defaultProps = {
    labelRotation: null,
    onPointSelect: () => {},
    selectedPoint: null,
    style: {},
    numberFormatOptions: {}
  };

  public shouldComponentUpdate(nextProps: Props) {
    return (
      !_isEqual(nextProps.mainEntityMetrics, this.props.mainEntityMetrics) ||
      !_isEqual(nextProps.selectedPoint, this.props.selectedPoint)
    );
  }

  private getChartParameters = (
    chartPropsOverride: ChartPropsOverride,
    chartDisplayTimePeriod: TimePeriod,
    mainEntityMetrics: MainEntityMetrics
  ) => {
    const { onPointSelect, selectedPoint, retailer, appDetails, numberFormatOptions } = this.props;
    const tab = _get(appDetails, 'queryParams.tab', '');
    const subtab = _get(appDetails, 'queryParams.subtab', '');

    const getChartPropOverride = (path: string | string[], defaultValue?: any) =>
      _get(chartPropsOverride, path, defaultValue);

    const categories: any[] = []; // TODO
    interface ChartSeriesData {
      type: ChartPropsOverride['chart'];
      name: string;
      data: {
        fieldValue: number | string;
        fieldId: number;
        y: number;
        selected: boolean;
      }[];
      dataLabels: {
        enabled: boolean;
        align: 'right' | 'left';
        x: ChartPropsOverride;
      };
    }
    const chartSeries: ChartSeriesData[] = [
      {
        type: _get(chartPropsOverride, 'chart.type', 'column'),
        name: chartDisplayTimePeriod.displayName,
        data: [],
        dataLabels: {
          enabled: true,
          align: getChartPropOverride(['plotOptions', 'column', 'dataLabels', 'align'], 'right'),
          x: getChartPropOverride(['dataLabels', 'x'], getChartPropOverride(['chart', 'type']) === 'bar' ? 30 : 3)
        }
      }
    ];

    const groupByFieldName = mainEntityMetrics.groupByField.name;
    mainEntityMetrics.data.forEach((item) => {
      if (groupByFieldName === 'weekId') {
        if (chartDisplayTimePeriod.startWeek <= item.weekId! && chartDisplayTimePeriod.endWeek >= item.weekId!) {
          let { weekEnding } = item;
          const { weekId } = item;
          weekEnding = typeof weekEnding === 'string' ? new Date(Date.parse(weekEnding)) : weekEnding;
          chartSeries[0].data.push({
            fieldValue: weekId!,
            fieldId: weekEnding!.getTime(),
            y: item.value,
            selected: selectedPoint === weekId
          });
          categories.push(moment(weekEnding).format('DD-MMM'));
        }
      } else {
        chartSeries[0].data.push({
          fieldValue: item.name!,
          fieldId: item.entity && item.entity.keyFieldName ? item.entity.keyFieldName : item.name!,
          y: item.value,
          selected: selectedPoint === item.name
        });
        categories.push(item.name);
      }
    });

    const chartProps = {
      chart: {
        type: 'column',
        height: 500,
        marginTop: 0
      },
      title: {
        text: getChartPropOverride('title', ''),
        align: 'left',
        x: 0,
        y: 0,
        style: {
          color: colors.darkBlue,
          'font-size': '22px',
          'font-weight': '400',
          'font-family': 'Roboto, sans-serif'
        }
      },
      xAxis: [
        {
          dateTimeLabelFormats: {
            month: '%b',
            week: '%b %e'
          },
          type: 'categories',
          categories,
          labels: {
            useHTML: getChartPropOverride(['xAxis', 'labels', 'useHTML']),
            rotation:
              this.props.labelRotation !== undefined || null
                ? this.props.labelRotation
                : getChartPropOverride(['xAxis', 'labels', 'rotation'], 45),
            formatter: getChartPropOverride(['xAxis', 'labels', 'formatter'], defaultColumnChartFormatter),
            style: {
              color: colors.darkBlue,
              'font-size': '11px',
              'font-weight': fontStyle.regularWeight,
              'font-family': 'Roboto, sans-serif'
            },
            y: getChartPropOverride(['xAxis', 'labels', 'y'])
          },
          crosshair: getChartPropOverride(['xAxis', 'crosshair'], false),
          lineWidth: 0,
          tickWidth: 0
        }
      ],
      yAxis: getChartPropOverride('yAxis', defaults.yAxis),
      tooltip: getChartPropOverride('tooltip', { enabled: false }),
      legend: getChartPropOverride('legend', { enabled: false }),
      exporting: { enabled: true },
      credits: false,
      plotOptions: {
        series: {
          borderRadius: 3,
          borderWidth: 0,
          allowPointSelect: (chartPropsOverride && !chartPropsOverride.disablePointSelect) || false,
          cursor: chartPropsOverride && chartPropsOverride.disablePointSelect ? 'initial' : 'pointer',
          states: {
            select: {
              color: colors.darkBlue
            },
            hover: {
              enabled: (chartPropsOverride && !chartPropsOverride.disablePointSelect) || false
            }
          },
          dataLabels: {
            enabled: getChartPropOverride(['dataLabels', 'enabled'], true),
            crop: false,
            overflow: 'none',
            style: {
              color: colors.darkBlue,
              'font-size': '11px',
              fontWeight: tab === 'sales' && subtab === 'contentScore' ? 'normal' : fontStyle.regularWeight,
              'font-family': 'Roboto, sans-serif'
            },
            formatter: function formatter(this: { y: number }): string {
              const metric = convertMetricToDisplayValue(
                retailer,
                this.y,
                mainEntityMetrics.metricType,
                mainEntityMetrics.currencySymbol,
                numberFormatOptions.showFullValue !== undefined ? numberFormatOptions.showFullValue : true,
                numberFormatOptions
              );
              return chartPropsOverride.ignoreZeroValues && this.y === 0 ? '' : `${metric}`;
            }
          },
          point: {
            events: {
              click: function click() {
                onPointSelect([this]);
                return true;
              }
            }
          }
        },
        column: getChartPropOverride('column', defaults.column),
        bar: getChartPropOverride('bar', defaults.bar)
      },
      size: 100,
      lang: {
        noData: '<div class="sl-no-data">No data available</div>'
      },
      noData: getChartPropOverride('noData', defaults.noData)
    };

    return {
      chartProps: _merge(chartProps, chartPropsOverride),
      chartSeries
    };
  };

  public render() {
    const { widget, chartDisplayTimePeriod, mainEntityMetrics, style, retailer } = this.props;
    if (!mainEntityMetrics) {
      return null;
    }

    const { chartProps, chartSeries } = this.getChartParameters(
      widget.view.chartPropsOverride,
      chartDisplayTimePeriod,
      mainEntityMetrics
    );

    return (
      <div style={{ width: '98%', ...style }}>
        <GenericChart
          chartSeries={chartSeries}
          chartProps={chartProps}
          convertSeriesToDelimitedData={(series: Series[]) => convertBarChartSeriesToDelimitedData(series, retailer)}
        />
      </div>
    );
  }
}

export default ColumnChartContainer;
