/**
 * This file contains the re-written content from the Summary.js file
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import queryString from 'qs';
import axios from 'axios';

import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { ENTITIES } from 'src/utils/entityDefinitions';
import { isSuperUser } from 'src/utils/app';
import { HomePageLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import SummaryTableContainer from './SummaryTableContainer';
import SummaryDataHeader from './SummaryDataHeader';
import '../HomePage.scss';
import {
  computeEntityMarketShareMetrics,
  computeOtherEntityRetailerMetrics,
  getChartDisplayTimePeriod,
  checkForExponentialNumber
} from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { renderWeeklyTrendChart } from 'src/components/EntityPage/Renderer/WeeklyTrendChart';
import { getLayoutForEntity } from 'src/components/Layout/EntityPageLayout';
import { anyNotEq } from 'src/utils/equality';
import { updateConditionsToCurrentRetailer } from 'src/utils/conditions';
import { buildAggregations } from 'src/components/AdManager/Search';

// ********************************************************************************************************************
// The following two functions were copied over from an older version of `EntityPageRenderer`.  Some breaking changes
// were made to them to support `dayId`s in ad manager which broke their existing behavior for this component.
// ********************************************************************************************************************

function computeEntityRetailerMetrics(mainEntityMetrics, mainTimePeriod, comparisonTimePeriod, retailer) {
  Object.keys(mainEntityMetrics).forEach((key) => {
    if (mainEntityMetrics[key].data) {
      const comparisonValuePropName = `value_${comparisonTimePeriod.startWeek}_${comparisonTimePeriod.endWeek}`;
      const dataPointByRetailerId = {
        entity: retailer,
        name: retailer.id,
        [comparisonValuePropName]: 0
      };

      mainTimePeriod.forEach((timePeriod) => {
        if (!timePeriod.startWeek) {
          return;
        }

        const mainValuePropName = `value_${timePeriod.startWeek}_${timePeriod.endWeek}`;
        dataPointByRetailerId[mainValuePropName] = 0;
        mainEntityMetrics[key].data.forEach((dataPoint) => {
          if (dataPoint.weekId >= timePeriod.startWeek && dataPoint.weekId <= timePeriod.endWeek) {
            dataPointByRetailerId[mainValuePropName] += dataPoint.value;
          }
          if (dataPoint.weekId >= comparisonTimePeriod.startWeek && dataPoint.weekId <= comparisonTimePeriod.endWeek) {
            dataPointByRetailerId[comparisonValuePropName] += dataPoint.value;
          }
        });

        dataPointByRetailerId.totalGrowthValue =
          dataPointByRetailerId[comparisonValuePropName] > 0
            ? (dataPointByRetailerId[mainValuePropName] - dataPointByRetailerId[comparisonValuePropName]) /
              dataPointByRetailerId[comparisonValuePropName]
            : 10;
      });

      mainEntityMetrics[`${mainEntityMetrics[key].name}_by_retailerId`] = {
        ...mainEntityMetrics[key],
        data: [dataPointByRetailerId]
      };
    }
  });
}

function computeMetricChange(mainEntityMetrics, mainTimePeriod, comparisonTimePeriod) {
  const mainValuePropName = `value_${mainTimePeriod.startWeek}_${mainTimePeriod.endWeek}`;
  const comparisonValuePropName = `value_${comparisonTimePeriod.startWeek}_${comparisonTimePeriod.endWeek}`;

  Object.keys(mainEntityMetrics).forEach((key) => {
    if (!mainEntityMetrics[key] || !mainEntityMetrics[key].data) {
      return;
    }

    mainEntityMetrics[key].data.forEach((dataPoint) => {
      if (dataPoint[comparisonValuePropName]) {
        dataPoint.value_change = dataPoint[mainValuePropName] - dataPoint[comparisonValuePropName];
        if (checkForExponentialNumber(dataPoint.value_change, 4) === 0) {
          dataPoint.value_change = 0;
        }
      } else if (dataPoint[mainValuePropName] > 0) {
        dataPoint.value_change = dataPoint[mainValuePropName];
      } else {
        dataPoint.value_change = 0;
      }
    });
  });
}

const { CancelToken } = axios;

class SummaryDataContainer extends Component {
  static defaultProps = {
    isStacked: true,
    currentSegment: null
  };

  static propTypes = {
    app: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    entitySearchService: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    fetchEntityMetrics: PropTypes.func.isRequired,
    subHeaderEntitySelection: PropTypes.object.isRequired,
    queryConditions: PropTypes.object.isRequired,
    aggregationConditions: PropTypes.object.isRequired,
    marketShareAggregationConditions: PropTypes.object.isRequired,
    aggregationMetricFields: PropTypes.array.isRequired,
    comparisonRangeFilters: PropTypes.array.isRequired,
    currentSegment: PropTypes.object,
    selectedMetric: PropTypes.object.isRequired,
    selectedItem: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    entityDefinition: PropTypes.object.isRequired,
    isStacked: PropTypes.bool
  };

  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      isMouseOver: false,
      isLoading: false,
      top5EntityMetrics: {},
      mainEntityCategoryMetrics: {}
    };
  }

  componentDidMount() {
    this.source = CancelToken.source();
    this.apiCall(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const params = queryString.parse(nextProps.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const prevParams = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });

    const keys = [
      'subHeaderEntitySelection',
      'selectedMetric',
      'retailer',
      'mainTimePeriod',
      'comparisonTimePeriod',
      'selectedItem'
    ];
    if (anyNotEq(keys, this.props, nextProps) || !_isEqual(prevParams, params)) {
      this.apiCall(nextProps);
    }
  }

  componentWillUnmount() {
    this.source.cancel('Cancel network request: sdc');
  }

  apiCall = (props) => {
    this.setState({
      top5EntityMetrics: {},
      mainEntityCategoryMetrics: {},
      isLoading: true
    });

    const {
      aggregationMetricFields,
      aggregationConditions,
      app,
      comparisonRangeFilters,
      comparisonTimePeriod,
      fetchEntityMetrics,
      marketShareAggregationConditions,
      entityDefinition,
      retailer,
      subHeaderEntitySelection,
      selectedMetric,
      selectedItem
    } = props;
    const { indexName } = selectedMetric;
    const [{ aggregations: aggregationFields }] = buildAggregations(aggregationMetricFields);
    const searchConditions = selectedItem.conditions;
    const selectedEntity = ENTITIES[app.name][subHeaderEntitySelection.entityType];
    const requestOverrides = {
      summaryTop5: {
        additionalFieldsToReturn: [],
        aggregations: [
          {
            aggregationFields,
            conditions: updateConditionsToCurrentRetailer(aggregationConditions, retailer),
            comparisonRangeFilters,
            groupByFieldName: selectedEntity.keyFieldName,
            sortDirection: null,
            sortByAggregationField: null
          }
        ],
        conditions: updateConditionsToCurrentRetailer(searchConditions, retailer),
        pageNumber: 1,
        pageSize: 5,
        pageSizeForAggregation: 250,
        period: comparisonTimePeriod.id.split('-')[1],
        doAggregation: true,
        sortFilter: {
          sortFields: [
            {
              fieldName: `${selectedMetric.name}`
            }
          ]
        }
      },
      summaryMainMetrics: {
        additionalFieldsToReturn: [],
        aggregations: [
          {
            aggregationFields,
            conditions: updateConditionsToCurrentRetailer(marketShareAggregationConditions, retailer),
            comparisonRangeFilters,
            groupByFieldName: 'weekId',
            sortDirection: null,
            sortByAggregationField: null
          }
        ],
        conditions: updateConditionsToCurrentRetailer(searchConditions, retailer),
        pageNumber: 1,
        pageSize: 200,
        period: comparisonTimePeriod.id.split('-')[1],
        doAggregation: true,
        sortFilter: {
          sortFields: [
            {
              fieldName: `${selectedMetric.name}`
            }
          ]
        }
      }
    };
    const promises = [];
    const requestContext = { entity: selectedEntity, retailer, app, indexName };

    // Prevent big requests in super user (dont want to req for All Categories)
    if (
      isSuperUser(_get(props, ['user', 'session', 'email']), '') &&
      _get(searchConditions, ['termFilters', '0', 'values'], []).length > 1
    ) {
      this.setState({ isLoading: false });
      return;
    }

    promises.push(
      fetchEntityMetrics(
        `${entityDefinition.type}SummaryTop5`,
        requestContext,
        [requestOverrides.summaryTop5],
        _get(this.source, 'token')
      ),
      fetchEntityMetrics(
        `${entityDefinition.type}SummaryMainMetrics`,
        requestContext,
        [requestOverrides.summaryMainMetrics],
        _get(this.source, 'token')
      )
    );

    Promise.all(promises)
      .then(() => {
        // The code below should either be memoized or use reselector library to compute derived data using a redux selector
        // https://redux.js.org/recipes/computing-derived-data
        const {
          entitySearchService,
          mainTimePeriod,
          comparisonTimePeriod: compTimePeriod,
          retailer: currentRetailer
        } = this.props;
        const top5EntityMetrics = entitySearchService[`${entityDefinition.type}SummaryTop5`];
        const mainEntityCategoryMetrics = entitySearchService[`${entityDefinition.type}SummaryMainMetrics`];
        // Sequence matters with these functions as they build on each other
        computeEntityRetailerMetrics(mainEntityCategoryMetrics, [mainTimePeriod], compTimePeriod, currentRetailer);
        computeEntityMarketShareMetrics(
          top5EntityMetrics,
          mainEntityCategoryMetrics,
          null,
          null,
          selectedEntity.keyFieldName,
          'retailerId'
        );
        computeMetricChange(top5EntityMetrics, mainTimePeriod, compTimePeriod);
        computeOtherEntityRetailerMetrics(
          top5EntityMetrics,
          mainEntityCategoryMetrics,
          [mainTimePeriod],
          compTimePeriod
        );
        this.setState({
          top5EntityMetrics,
          mainEntityCategoryMetrics,
          isLoading: false
        });
      })
      .catch((err) => {
        if (!err.message.includes('Cancel network request')) {
          console.error(err);
        }
      });
  };

  handleMouseOver = (isMouseOver) => this.setState({ isMouseOver });

  // Potentially Refactor this
  buildSummaryUrl = () => {
    const { selectedItem, app, retailer } = this.props;
    const { additionalParams, searchParams, entityType } = app.queryParams;

    if (selectedItem.id === 0) {
      if (app.name === 'beacon' && entityType === 'brand') {
        return `/brand/0${searchParams}${additionalParams}`;
      }

      return `/category/0?rid=${retailer.id}`;
    }
    return `/${selectedItem.type}${selectedItem.id ? `/${selectedItem.id}${searchParams}${additionalParams}` : ''}`;
  };

  render() {
    const {
      app,
      children,
      currentSegment,
      isStacked,
      selectedMetric,
      subHeaderEntitySelection,
      title,
      mainTimePeriod,
      location,
      retailer,
      user,
      entityService,
      comparisonTimePeriod
    } = this.props;
    const { indexName } = selectedMetric;
    const { mainEntity } = entityService;
    const { isMouseOver, top5EntityMetrics, mainEntityCategoryMetrics, isLoading } = this.state;
    const selectedEntity = ENTITIES[app.name][subHeaderEntitySelection.entityType];
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    let pageLayout = {};
    if (!_isEmpty(mainEntity)) {
      pageLayout = getLayoutForEntity({
        app,
        retailer,
        user,
        tab: indexName,
        metricType: selectedMetric.name,
        entity: mainEntity,
        pageType: 'summaryPage'
      });
    }
    const chartDisplayTimePeriod = getChartDisplayTimePeriod(mainTimePeriod);
    const chartComparisonDisplayTimePeriod = getChartDisplayTimePeriod(comparisonTimePeriod);

    // Not sure this is needed but not sure how to remove yet
    const comparisonConfig = {
      type: queryParams.ctype || 'metric',
      metricName: queryParams.csubtab,
      entityId: queryParams.cid,
      entityType: queryParams.ctype,
      indexName
    };

    const tableDataToRender =
      !_isEmpty(top5EntityMetrics) &&
      !_isEmpty(top5EntityMetrics[`${selectedMetric.name}MarketShare_by_${selectedEntity.keyFieldName}`])
        ? top5EntityMetrics[`${selectedMetric.name}MarketShare_by_${selectedEntity.keyFieldName}`].data
        : [];
    const totalsData =
      !_isEmpty(mainEntityCategoryMetrics) &&
      !_isEmpty(mainEntityCategoryMetrics[`${selectedMetric.name}_by_retailerId`])
        ? mainEntityCategoryMetrics[`${selectedMetric.name}_by_retailerId`].data
        : [];

    return (
      <div>
        {children}
        {/*  THIS IS THE SUMMARY HEADER BAR - TURN INTO ITS OWN COMPONENT */}
        <div
          className={`summary-container ${isStacked ? 'summary-container--tall' : ''}`}
          onMouseEnter={() => this.handleMouseOver(true)}
          onMouseLeave={() => this.handleMouseOver(false)}
        >
          <div className={`summary ${currentSegment ? 'summary--segment' : ''}`}>
            <div
              className={`summary__front ${currentSegment ? 'summary__front--segment' : ''} ${
                isMouseOver ? 'summary__front--hover' : ''
              }`}
            >
              <SummaryDataHeader
                appName={app.name}
                currentSegment={currentSegment}
                buildSummaryUrl={this.buildSummaryUrl}
                title={title}
              />

              {/* Summary Chart and Table */}
              {isLoading && tableDataToRender.length === 0 && <HomePageLoading />}
              {tableDataToRender.length === 0 && !isLoading && (
                <div className="summary__no-data">No data available</div>
              )}
              {tableDataToRender.length > 0 && (
                <div
                  className={`summary__data ${
                    isStacked ? 'summary__data--vertical-layout' : 'summary__data--horizontal-layout'
                  }`}
                  style={{ marginTop: '40px' }}
                >
                  <div className="summary__line">
                    {/*  DISPLAYS THE CHART */}
                    {pageLayout.widgets.map((widget) => {
                      const InnerWidget = widget.getInnerWidget(app, indexName, selectedMetric.name, mainEntity);
                      const { data, view } = InnerWidget;

                      if (view.name === 'weeklyTrendChart') {
                        return (
                          <div key={view.name}>
                            {renderWeeklyTrendChart(
                              view,
                              chartDisplayTimePeriod,
                              chartComparisonDisplayTimePeriod,
                              mainEntityCategoryMetrics,
                              mainEntityCategoryMetrics,
                              {
                                metricName: data.chartMainField.name,
                                indexName
                              },
                              data.groupByField.name,
                              {
                                ...comparisonConfig,
                                metricName: data.chartMainField.name
                              }
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  <div className={`summary__grid ${!isStacked ? 'summary__grid--left-padding' : ''}`}>
                    <SummaryTableContainer
                      dataSet={tableDataToRender}
                      dynamicColumnHeader={subHeaderEntitySelection.displayName}
                      metricHeader={selectedMetric.displayName}
                      selectedEntity={selectedEntity}
                      timePeriodRange={`_${mainTimePeriod.startWeek}_${mainTimePeriod.endWeek}`}
                      totalsData={totalsData}
                      selectedItem={this.props.selectedItem}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, [
    'app',
    'comparisonTimePeriod',
    'entityService',
    'entitySearchService',
    'mainTimePeriod',
    'retailer',
    'user'
  ]);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SummaryDataContainer));
