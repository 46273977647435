import styled from '@emotion/styled';
import { StacklineTheme, useStacklineTheme } from '@stackline/ui';
import React, { useState } from 'react';
import ContentAccuracyNodataBox from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Content/Accuracy/ContentAccuracyNodataBox';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { SmallCheckCircleIcon, SmallCancelCircleFilledWhite } from 'src/components/SvgIcons';

// if the number of images are less than 5, we want to center align it -- confirmed by design team.
export const StyledGridDiv = styled('div')(({ imagesLength }: { imagesLength: number }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${Math.min(imagesLength, 5)}, 1fr)`,
  columnGap: '12px',
  rowGap: '18px',
  alignItems: 'center'
}));

export const StyledSmallCheckCircle = styled(SmallCheckCircleIcon)(
  ({ stacklineTheme }: { stacklineTheme: StacklineTheme }) => ({
    width: stacklineTheme.spacing.md,
    fill: stacklineTheme.colors.success,
    position: 'absolute',
    top: '-6px',
    right: '-8px'
  })
);

export const StyledSmallCancelCircleFilledWhite = styled(SmallCancelCircleFilledWhite)(
  ({ stacklineTheme }: { stacklineTheme: StacklineTheme }) => ({
    width: stacklineTheme.spacing.md,
    fill: stacklineTheme.colors.error,
    position: 'absolute',
    top: '-6px',
    right: '-8px'
  })
);

interface ContentAccuracyImagesViewerProps {
  images: string[];
  changedImages?: string[];
}

/**
 * Content Accuracy's Product Page image viewer
 *
 */
const ContentAccuracyImagesViewer = ({ images, changedImages }: ContentAccuracyImagesViewerProps) => {
  const [selectedImage, setSelectedImage] = useState(images[0] || '');
  const theme = useStacklineTheme();

  const isCurrentContent = !!changedImages;
  const isMatching = changedImages && changedImages.every((image) => !image);

  if (images.length === 0) {
    return <ContentAccuracyNodataBox isCurrentContent={isCurrentContent} contentField="images" />;
  }

  return (
    <Flex
      flexDirection="column"
      border={isCurrentContent && !isMatching ? `1px solid ${theme.colors.error}` : ''}
      borderRadius={theme.spacing.xs}
    >
      <Flex justifyContent="center" marginBottom={theme.spacing.md}>
        <div style={{ minHeight: '200px', marginTop: theme.spacing.mdl }}>
          <img
            style={{ width: '200px', height: '200px', objectFit: 'contain' }}
            src={selectedImage}
            alt={selectedImage}
          />
        </div>
      </Flex>
      <Flex justifyContent="center">
        <StyledGridDiv imagesLength={images.length}>
          {images.map((image, index) => (
            <div key={image} style={{ position: 'relative', marginBottom: theme.spacing.mdl }}>
              {isCurrentContent ? (
                changedImages && !changedImages[index] ? (
                  <StyledSmallCheckCircle stacklineTheme={theme} />
                ) : (
                  <StyledSmallCancelCircleFilledWhite stacklineTheme={theme} />
                )
              ) : null}

              <img
                style={{
                  width: '40px',
                  height: '40px',
                  objectFit: 'contain',
                  cursor: 'pointer',
                  border: `1px solid ${image === selectedImage ? theme.colors.info : 'transparent'}`
                }}
                onClick={() => setSelectedImage(image)}
                src={image}
                alt={image}
                role="button"
              />
            </div>
          ))}
        </StyledGridDiv>
      </Flex>
    </Flex>
  );
};

export default ContentAccuracyImagesViewer;
