import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import React from 'react';
import Waypoint from 'react-waypoint';
import _get from 'lodash/get';
import _startCase from 'lodash/startCase';
import _isNil from 'lodash/isNil';
import { propEq } from 'src/utils/fp';
import { generateSearchLink } from 'src/utils/searchLinks';
import { enhanceTopEntitiesChart, TopEntitiesChartInner } from './TopEntitiesChart';
import { getSearchParamsForRetailer } from 'src/store/modules/app/selectors';
import { buildCustomDateRangeDisplayName, computeComparisonTimePeriod } from 'src/utils/dateformatting';
import { buildSubtitleDisplayName } from 'src/utils/filters';
import {
  computeEntityMarketShareMetrics,
  setSubTitle,
  computeShareOfTotalSalesMetrics
} from '../Renderer/EntityPageRenderer';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { AppName } from 'sl-api-connector';
import { track } from 'src/utils/mixpanel';
import ComponentInView from 'src/components/common/ComponentInView/ComponentInView';
import { shouldShowNewBeacon } from 'src/utils/app';
import { ForecastPeriod } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import { createTimePeriodDateObject } from 'src/store/modules/main-time-period/selectors';

const buildDropdownOptions = (
  uniqueName,
  metricFields,
  groupByFields,
  selectedMetricFieldName,
  selectedGroupByFieldName
) => {
  const mainMetricOptions = metricFields.map(({ displayName }) => ({
    eventId: { displayName },
    eventName: `${uniqueName}toggleChartMainMetric`,
    displayName,
    isSelected: displayName === selectedMetricFieldName
  }));

  const groupByOptions = groupByFields.map(({ name, displayName }) => ({
    eventId: { name },
    eventName: `${uniqueName}toggleChartGroupByMetric`,
    displayName,
    isSelected: name === selectedGroupByFieldName
  }));

  return { mainMetricOptions, groupByOptions };
};

class MultiGroupByTopEntitiesChart extends TopEntitiesChartInner {
  componentDidMount() {
    super.componentDidMount.call(this);
    this.props.eventBus.on(`${this.props.uniqueName}toggleChartMainMetric`, this.handleMainMetricToggle);
    this.props.eventBus.on(`${this.props.uniqueName}toggleChartGroupByMetric`, this.handleGroupByToggle);
  }

  componentWillUnmount() {
    super.componentWillUnmount.call(this);
    this.props.eventBus.off(`${this.props.uniqueName}toggleChartMainMetric`, this.handleMainMetricToggle);
    this.props.eventBus.off(`${this.props.uniqueName}toggleChartGroupByMetric`, this.handleGroupByToggle);
  }

  scrollIntoView = () => {
    const { anchorName } = this.props.widget.view;
    if (anchorName) {
      window.location.hash = anchorName;
    }
  };

  handleMainMetricToggle = ({ displayName, scrollIntoView }) => {
    this.pageNumber = 1;
    if (!this.props.widget.view.metricFields.find(propEq('displayName', displayName))) {
      return;
    }

    this.setState({ selectedMetricFieldName: displayName }, () => {
      return this.fetchMetrics(this.props, false, this.getSelectedMetricFieldName(), this.getSelectedGroupByField());
    });
    if (scrollIntoView) {
      this.scrollIntoView();
    }
    if (this.props.app.name === AppName.Advertising) {
      track('multiGroupChart switch metric', {
        metricName: displayName
      });
    }
  };

  overrideTimePeriods() {
    const { allWeekIdsByRetailerId, comparisonTimePeriod, mainTimePeriod, retailer, widget } = this.props;

    let comparisonPeriod = comparisonTimePeriod;
    let mainPeriod = mainTimePeriod;

    if (widget.data.timePeriodOverride) {
      const overrideMain = mainTimePeriod.availableMainTimePeriods.find((p) => p.id === widget.data.timePeriodOverride);
      const overrideComparison = computeComparisonTimePeriod(
        allWeekIdsByRetailerId[+retailer.id],
        overrideMain,
        'prior-period'
      );

      if (overrideMain && overrideComparison) {
        comparisonPeriod = {
          ...overrideComparison,
          availableComparisonTimePeriods: comparisonTimePeriod.availableComparisonTimePeriods,
          timePeriodSuffix: {
            dayId: `_${overrideComparison.startDayId}_${overrideComparison.endDayId}`,
            weekId: `_${overrideComparison.startWeek}_${overrideComparison.endWeek}`
          }
        };

        comparisonPeriod.displayName = buildCustomDateRangeDisplayName(
          comparisonPeriod.startDayId,
          comparisonPeriod.endDayId
        );

        mainPeriod = {
          ...overrideMain,
          availableMainTimePeriods: mainPeriod.availableMainTimePeriods,
          timePeriodSuffix: {
            dayId: `_${overrideMain.startDayId}_${overrideMain.endDayId}`,
            weekId: `_${overrideMain.startWeek}_${overrideMain.endWeek}`
          }
        };

        this.setState(
          {
            comparisonPeriod,
            mainPeriod
          },
          () => {
            this.fetchMetrics(this.props, false, this.getSelectedMetricFieldName(), this.getSelectedGroupByField());
          }
        );
      }
    } else if (widget.view.forecastPeriodOverride) {
      const { forecastPeriod, compareForecastWeekId } = widget.view.forecastPeriodOverride;

      let mainPeriodOverriding = this.props.mainTimePeriod;

      let comparisonPeriodOverriding = this.props.comparisonTimePeriod;

      // Only full year will use original data for main bar (original data + forecast data)
      if (forecastPeriod === ForecastPeriod.FULL_YEAR) {
        mainPeriodOverriding = mainTimePeriod.availableMainTimePeriods.find((p) => p.id === 'ytd');
        comparisonPeriodOverriding = mainTimePeriod.availableMainTimePeriods.find((p) => p.id === 'ly');
        comparisonPeriod = {
          ...comparisonPeriodOverriding,
          availableComparisonTimePeriods: comparisonTimePeriod.availableComparisonTimePeriods,
          timePeriodSuffix: {
            dayId: `_${comparisonPeriodOverriding.startDayId}_${comparisonPeriodOverriding.endDayId}`,
            weekId: `_${comparisonPeriodOverriding.startWeek}_${comparisonPeriodOverriding.endWeek}`
          }
        };
      } else {
        // All other case will use only forecast data for main bar.
        // only need to override comparison bar
        const { comparisonStartWeekId, comparisonEndWeekId } = compareForecastWeekId;

        const comparisonOverridingObject = createTimePeriodDateObject(
          // first argument can be anything other than ytd or ly (since we overriding available main period)
          mainTimePeriod.availableMainTimePeriods.find((p) => p.id === '4w'),
          comparisonStartWeekId,
          comparisonEndWeekId
        );

        comparisonPeriodOverriding = comparisonOverridingObject;
        comparisonPeriod = {
          ...comparisonPeriodOverriding,
          availableComparisonTimePeriods: Array.from(comparisonOverridingObject),
          timePeriodSuffix: {
            dayId: `_${comparisonPeriodOverriding.startDayId}_${comparisonPeriodOverriding.endDayId}`,
            weekId: `_${comparisonPeriodOverriding.startWeek}_${comparisonPeriodOverriding.endWeek}`
          }
        };
        comparisonPeriod.displayName = buildCustomDateRangeDisplayName(
          comparisonPeriod.startDayId,
          comparisonPeriod.endDayId
        );
      }

      comparisonPeriod.displayName = buildCustomDateRangeDisplayName(
        comparisonPeriod.startDayId,
        comparisonPeriod.endDayId
      );

      mainPeriod = {
        ...mainPeriodOverriding,
        availableMainTimePeriods: mainPeriod.availableMainTimePeriods,
        timePeriodSuffix: {
          dayId: `_${mainPeriodOverriding.startDayId}_${mainPeriodOverriding.endDayId}`,
          weekId: `_${mainPeriodOverriding.startWeek}_${mainPeriodOverriding.endWeek}`
        }
      };

      this.setState(
        {
          comparisonPeriod,
          mainPeriod
        },
        () => {
          this.fetchMetrics(this.props, false, this.getSelectedMetricFieldName(), this.getSelectedGroupByField());
        }
      );
    } else {
      this.setState(
        {
          comparisonPeriod: this.props.comparisonTimePeriod,
          mainPeriod: this.props.mainTimePeriod
        },
        () => {
          this.fetchMetrics(this.props, false, this.getSelectedMetricFieldName(), this.getSelectedGroupByField());
        }
      );
    }
  }

  switchGroupByField = (groupByFieldName) => {
    this.setState(
      {
        groupByFieldSelected: this.props.widget.data.groupByFields.find((x) => x.name === groupByFieldName)
      },
      () => {
        this.fetchMetrics(this.props, false, this.getSelectedMetricFieldName(), this.getSelectedGroupByField());
      }
    );
    if (this.props.app.name === AppName.Advertising) {
      track('multiGroupChart switch groupBy', {
        fieldName: this.state.groupByFieldSelected.displayName
      });
    }
  };

  handleGroupByToggle = ({ name, scrollIntoView }) => {
    this.pageNumber = 1;

    this.switchGroupByField(name);

    if (scrollIntoView) {
      this.scrollIntoView();
    }
  };

  getSelectedMetricFieldName = () =>
    this.state.selectedMetricFieldName || this.props.widget.view.metricFields[0].displayName;

  getSelectedGroupByFieldName = () =>
    _get(this.state.groupByFieldSelected, 'name', this.props.widget.data.groupByFields[0].name);

  getMetricFields(props) {
    return [props.widget.view.metricFields.find(propEq('displayName', this.getSelectedMetricFieldName()))];
  }

  getSelectedGroupByField = () => {
    return _get(this.props, 'widget.data.groupByFields', []).find(propEq('name', this.getSelectedGroupByFieldName()));
  };

  getAggregationFields(props, groupByField) {
    return super.getAggregationFields
      .call(this, props, groupByField)
      .filter(propEq('displayName', this.getSelectedMetricFieldName()));
  }

  preRender = () => {
    const {
      widget,
      entityService: { mainEntity },
      entitySearchService,
      retailer,
      app,
      categories,
      filters
    } = this.props;

    const { groupByFieldSelected, enableComparison } = this.state;

    const entityMetrics = this.getEntityMetrics();
    if (_isNil(entityMetrics)) {
      return null;
    }
    const { mainEntityMetrics, comparisonEntityMetrics } = entityMetrics;
    if (!mainEntityMetrics) {
      return null;
    }

    if (widget.isMarketShare || widget.data.isMarketShare) {
      const { displayName } = widget.view;
      let categoryGroupByFieldName = 'retailerId';
      if (displayName === 'Retail Sales by Category' || displayName === 'Units Sold by Category') {
        categoryGroupByFieldName = 'categoryId';
      }
      computeEntityMarketShareMetrics(
        mainEntityMetrics,
        entitySearchService[`${this.props.uniqueName}-marketShareTotal`],
        null,
        null,
        groupByFieldSelected.name,
        categoryGroupByFieldName
      );
    } else if (this.getSelectedMetricFieldName() === 'Share of Total Sales') {
      const name = `${widget.name}_mainEntity_promotions_${this.getSelectedGroupByFieldName()}_all_retail_sales`;
      computeShareOfTotalSalesMetrics(
        mainEntityMetrics,
        entitySearchService[name],
        null,
        this.getSelectedGroupByFieldName(),
        this.getSelectedGroupByFieldName()
      );
    }

    const [mainMetricField] = this.getMetricFields(this.props);

    if (shouldShowNewBeacon()) {
      return entityMetrics;
    }
    if (
      _isNil(_get(mainEntityMetrics, [`${mainMetricField.name}_by_${groupByFieldSelected.name}`, `currencySymbol`])) ||
      (enableComparison &&
        !_get(comparisonEntityMetrics, [`${mainMetricField.name}_by_${groupByFieldSelected.name}`, `currencySymbol`]))
    ) {
      return null;
    }

    const subtitle = buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app);
    setSubTitle(subtitle, [mainEntityMetrics]);

    // Returning `entityMetrics` here indicates that we are good to render the chart - all necessary data is present
    // in props and/or state.

    return entityMetrics;
  };

  buildChartProps() {
    const { widget, user, retailer, app, comparisonTimePeriod, mainTimePeriod } = this.props;
    const { isRestrictedDemoUser } = user.config && user.config;
    const { additionalParams, searchParams } = app ? app.queryParams : {};
    const metric = this.getSelectedMetricFieldName();

    // Disable the tooltip if we receive fieldNames via widget.view.hideTooltipFor
    const hideTooltipForTheseFieldNames = _get(widget, ['view', 'hideTooltipFor']);
    if (hideTooltipForTheseFieldNames) {
      const indexName = _get(widget, [
        'data',
        'configByGroupByFieldName',
        this.getSelectedGroupByFieldName(),
        'indexName'
      ]);
      const hideTooltipForTheseFields = hideTooltipForTheseFieldNames.map((field) =>
        INDEX_FIELDS.getField(app.name, indexName, field)
      );
      widget.view.hideTooltip = !!hideTooltipForTheseFields.find((field) => field.displayName === metric);
    }

    const {
      config: {
        vendor: { BeaconClientId: clientId }
      }
    } = user;

    const chartParams = super.buildChartProps.call(this);
    if (!chartParams) {
      return null;
    }
    const { chartProps, chartSeries } = chartParams;

    // Add the group-by dropdown options to the chart props to that they are rendered in the chart's title
    const { mainMetricOptions, groupByOptions } = buildDropdownOptions(
      this.props.uniqueName,
      widget.view.metricFields,
      widget.data.groupByFields,
      this.getSelectedMetricFieldName(),
      this.getSelectedGroupByFieldName()
    );

    chartProps.xAxis.forEach((axis) => {
      axis.labels = {
        ...axis.labels,
        formatter() {
          let name = this.value.name ? this.value.name : '';
          if (isRestrictedDemoUser && this.value.type !== 'category' && this.value.type !== 'subcategory') {
            return name;
          }

          const textOnly = ['promoType'];
          if (this.value.type && !textOnly.includes(this.value.type)) {
            const id = this.value.type === 'product' ? this.value.stacklineSku : this.value.id;
            const retailerEntity = retailer.availableRetailers.find((r) => r.id === this.value.id);
            if (retailerEntity && this.value.type !== 'parentBrand') {
              name = retailerEntity.displayName;
            }

            if (this.value.type === 'retailer') {
              const searchParamsWithUpdatedRetailer = getSearchParamsForRetailer(
                app,
                retailer,
                this.value.id,
                mainTimePeriod,
                comparisonTimePeriod
              );

              if (metric === 'Units Sold') {
                // need to get the first retailer (that is not 0 )
                return `<a target="_blank" style="margin-top: 5px; display: inline-block;" href="/client/${clientId}${searchParamsWithUpdatedRetailer}&tab=sales&subtab=unitsSold">${name}</a>`;
              }

              if (metric.includes('Retail Sales')) {
                // need to get the first retailer (that is not 0 )
                return `<a target="_blank" style="margin-top: 5px; display: inline-block;" href="/client/${clientId}${searchParamsWithUpdatedRetailer}&tab=sales&subtab=retailSales">${name}</a>`;
              }

              return name;
            }

            if (this.value.type === 'searchTerm') {
              return `<a target="_blank" style="margin-top: 5px; display: inline-block;" href="${generateSearchLink(
                retailer.id,
                name
              )}">${name}</a>`;
            }

            if (this.value.type === 'parentBrand') {
              return `<span style="margin-top: 5px; display: inline-block;">${name}</span>`;
            }

            if (this.value.categoryType === 'category' || this.value.categoryType === 'subCategory') {
              return `<span style="margin-top: 5px; display: inline-block;">${name}</span>`;
            }

            return `<a style="margin-top: 5px; display: inline-block;" href="/${
              this.value.type === 'campaign' ? 'adCampaign' : this.value.type
            }/${encodeURI(id)}${searchParams}${additionalParams}">${name}</a>`;
          }
          return name;
        }
      };
    });

    chartProps.exporting.buttons = [mainMetricOptions, groupByOptions];

    const selectedGroupBy = groupByOptions.find((opt) => opt.isSelected);
    const isAdType = selectedGroupBy && selectedGroupBy.displayName === 'Ad Type';
    if (isAdType) {
      chartSeries.forEach((series) => {
        series.categories.forEach((item) => {
          if (
            ['sponsoredProducts', 'sponsoredBrands', 'sponsoredDisplay', 'sponsoredBrandsVideo'].includes(item.name)
          ) {
            item.name = _startCase(item.name);
          }
        });
      });
    }
    return { chartProps, chartSeries };
  }

  handleWaypointEnter = () => {
    if (!this.state.inView) {
      this.setState(
        {
          inView: true
        },
        () => {
          // if the conditions changed or there is no data, then fetch
          if (this.shouldFetchMetricsLazyLoad()) {
            this.fetchMetrics(this.props, false, this.getSelectedMetricFieldName(), this.getSelectedGroupByField());
          }
        }
      );
    }
  };

  render = () => {
    const { widget } = this.props;
    const lazyLoad = _get(widget, ['data', 'lazyLoad'], false);
    if (this.shouldShowLoading()) {
      return (
        <>
          {lazyLoad && <Waypoint onEnter={this.handleWaypointEnter} onLeave={this.handleWaypointExit} />}
          <div style={{ height: '650px' }}>
            <GenericChartLoading />
          </div>
        </>
      );
    }

    return (
      <div>
        {lazyLoad && <Waypoint onEnter={this.handleWaypointEnter} onLeave={this.handleWaypointExit} />}
        <ComponentInView id="multi_groupBy_chart" appName={[AppName.Advertising]}>
          {this.renderChart()}
        </ComponentInView>
      </div>
    );
  };
}

const EnhancedMultiGroupByTopEntitiesChart = enhanceTopEntitiesChart(MultiGroupByTopEntitiesChart);

export default EnhancedMultiGroupByTopEntitiesChart;
