/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import GenericChart from 'src/components/Charts/GenericChart';
import Highcharts from 'highcharts';
import colors from 'src/utils/colors';
import _cloneDeep from 'lodash/cloneDeep';
import { ConvertSeriesToDelimitedDataFunction } from 'src/components/Charts/types';
import _merge from 'lodash/merge';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import './ColumnSplineChart.scss';

interface ColumnSplineChartProps {
  /**
   * List of data points to display in a column
   */
  columnData: number[];
  /**
   * List of data points to display as a spline chart
   */
  splineData: number[];
  /**
   * Overrides for highcharts options
   */
  chartPropsOverride?: Partial<Highcharts.Options>;
  /**
   * Optional callback for converting to CSV if exporting is enabled
   */
  convertSeriesToDelimitedData?: ConvertSeriesToDelimitedDataFunction;
  /**
   * Optional overrides for the column series
   */
  columnSeriesOptions?: Partial<Highcharts.SeriesColumnOptions>;
  /**
   * Optional overrides for the spline series
   */
  splineSeriesOptions?: Partial<Highcharts.SeriesSplineOptions>;
}

/**
 * Displays column data as well as a line graph
 * overlayed on top.
 */
const ColumnSplineChart = ({
  columnData,
  splineData,
  columnSeriesOptions,
  splineSeriesOptions,
  chartPropsOverride,
  convertSeriesToDelimitedData
}: ColumnSplineChartProps) => {
  /**
   * Since we had to use CSS to get the legend label to be a dot,
   * define a CSS variable with the color of the spline series
   * so that the legend is the correct color.
   */
  useEffect(() => {
    if (splineSeriesOptions && splineSeriesOptions.color) {
      document.documentElement.style.setProperty('--spline-color', splineSeriesOptions.color as string);
    }
  }, [splineSeriesOptions]);

  const columnSeries: Highcharts.SeriesColumnOptions = {
    type: 'column',
    data: _cloneDeep(columnData),
    yAxis: 0,
    ...columnSeriesOptions
  };

  const lineSeries: Highcharts.SeriesSplineOptions = {
    type: 'spline',
    data: _cloneDeep(splineData),
    yAxis: 1,
    marker: {
      enabled: false,
      states: {
        hover: {
          radius: 4,
          enabled: true
        }
      }
    },
    states: {
      hover: {
        halo: {
          enabled: true,
          size: 10
        }
      }
    },
    ...splineSeriesOptions
  };

  const chartProps: Highcharts.Options = _merge(
    {
      chart: {
        type: 'column',
        spacingLeft: 8
      },
      xAxis: [
        _merge(
          {
            title: {
              margin: 20,
              style: {
                fontSize: '14px',
                color: colors.darkBlue,
                fontFamily: 'Roboto'
              }
            },
            labels: {
              formatter() {
                return `${this.value}`;
              },
              style: {
                'font-size': '14px'
              }
            }
          },
          _get(chartPropsOverride, ['xAxis'], {})
        )
      ],
      yAxis: [
        _merge(
          {
            labels: {
              style: {
                'font-size': '14px'
              }
            }
          },
          _get(chartPropsOverride, ['yAxis', 0], {})
        ),
        _merge(
          {
            labels: {
              style: {
                'font-size': '14px'
              }
            },
            opposite: true,
            min: 0
          },
          _get(chartPropsOverride, ['yAxis', 1], {})
        )
      ],

      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: true
            }
          }
        }
      }
    },
    _omit(chartPropsOverride, ['xAxis', 'yAxis'])
  );

  return (
    <GenericChart
      className="column-spline-chart"
      chartSeries={[columnSeries, lineSeries]}
      chartProps={chartProps}
      convertSeriesToDelimitedData={convertSeriesToDelimitedData}
    />
  );
};

export default ColumnSplineChart;
