/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
// import GenericChart from 'src/components/Charts/GenericChart';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { adScoreOperations } from 'src/store/modules/adManager/adScores';
import colors from 'src/utils/colors';
import ReduxStore from 'src/types/store/reduxStore';
import { CustomLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
// import { AdScoreForEntity } from 'src/store/modules/adManager/adScores/types';
import { AdManagerKeyMetricWidget } from 'src/components/Layout/LayoutUtil';
import { getTotalMonthlyBudgetForCurrentEntity } from 'src/components/AdManager/budgetCalculation';
import { buildMainEntityConditions } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { computeDataSet, formatLegendData } from 'src/components/AdManager/DailySpendBarChart';
import { fetchDailySpendAndProjections } from 'src/components/AdManager/utils';

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['app', 'retailer', 'entitySearchService', 'adScores', 'adCampaigns', 'adPortfolios', 'adEntities']),
  mainEntity: state.entityService.mainEntity
});

const ScoreDonutChart: React.FC<
  { widget: AdManagerKeyMetricWidget; currentEntity: any } & ReturnType<typeof mapStateToProps>
> = ({
  app,
  entitySearchService,
  retailer,
  mainEntity,
  currentEntity,
  adScores,
  adCampaigns,
  adPortfolios,
  adEntities,
  receiveOneAdScores
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const entity: any = currentEntity || mainEntity;
  const entityType = entity.type;
  const entityId = entity.id;
  const score = _get(adScores, [entityType, entityId]);
  const baseDataKey = `scoreDonutChart-${entity!.id}`;

  const { [`${baseDataKey}-projections`]: adSpendMetricsProjected, [`${baseDataKey}-actual`]: adSpendMetricsActual } =
    entitySearchService;

  const fetchAdScore = () => {
    setIsFetching(true);
    const entityConditions = buildMainEntityConditions({ termFilters: [], rangeFilters: [] }, entity, app, retailer);

    fetchDailySpendAndProjections(baseDataKey, { mainEntity: entity, retailer, app }, entityConditions);
  };

  const receiveAdScore = () => {
    setIsFetching(false);
    let totalMonthlyBudget = getTotalMonthlyBudgetForCurrentEntity(entity, adCampaigns, adPortfolios, adEntities);

    let maxDayIdWithActual = 0;
    const averageDailyBudget = totalMonthlyBudget / moment().daysInMonth();
    if (adSpendMetricsActual.spend_by_dayId) {
      adSpendMetricsActual.spend_by_dayId.data.forEach((datum) => {
        const dayId = +datum.name!;
        if (dayId > maxDayIdWithActual) {
          maxDayIdWithActual = dayId;
        }
      });
    }

    const projectedAdSpendByDayData: {
      dayId: number;
      budgetAmount: number;
      value: number;
      isProjected: boolean;
    }[] = [];
    adSpendMetricsProjected.spend_by_dayId.data.forEach((datum) => {
      if (+datum.name! > maxDayIdWithActual) {
        projectedAdSpendByDayData.push({
          ...datum,
          dayId: +datum.name!,
          budgetAmount: datum.value || 0, // for projection spend is equal to budget, since our algorithm will set the budget based on projection
          isProjected: true,
          value: datum.value || 0
        });
      }
    });
    const actualAdSpendByDayData: {
      dayId: number;
      budgetAmount: number;
      value: number;
      isProjected: boolean;
    }[] = [];
    if (adSpendMetricsActual.spend_by_dayId) {
      adSpendMetricsActual.spend_by_dayId.data.forEach((datum, index) => {
        actualAdSpendByDayData.push({
          ...datum,
          dayId: +datum.name!,
          budgetAmount:
            entity.type === 'adCampaign'
              ? adSpendMetricsActual.budgetAmount_by_dayId.data[index].value
              : averageDailyBudget, // only for campaigns take daily budget into account. For portfolio and entity use the average daily budget based on monthly budget
          isProjected: false,
          value: datum.value || 0
        });
      });
    }
    const metricsByDayId: Map<number, { actual?: number; projected?: number; budgetAmount: number }> = [
      ...projectedAdSpendByDayData,
      ...actualAdSpendByDayData
    ].reduce((acc, { dayId, value, isProjected, budgetAmount }) => {
      const entry = acc.get(+dayId) || {};
      if (isProjected) {
        entry.projected = value;
      } else {
        entry.actual = value;
      }
      entry.budgetAmount = budgetAmount;
      acc.set(+dayId, entry);
      return acc;
    }, new Map());
    const dataSet = computeDataSet(metricsByDayId);
    if (entity.type === 'adCampaign') {
      totalMonthlyBudget = dataSet.totalCampaignBudgetSum;
    }
    const { totalSpendPercent } = formatLegendData(dataSet, totalMonthlyBudget);

    receiveOneAdScores(entityType, entityId, totalSpendPercent);
  };
  useEffect(() => {
    if (!score) {
      if (!adSpendMetricsProjected && !adSpendMetricsActual && !isFetching) {
        fetchAdScore();
      } else if (adSpendMetricsProjected && adSpendMetricsActual) {
        receiveAdScore();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score, adSpendMetricsProjected, adSpendMetricsActual]);

  if (!score) {
    return (
      <div style={{ width: 120, height: 120 }}>
        <CustomLoading height={120} width={120} padding={5} />
      </div>
    );
  }

  const scoreToUse = score.score < 1 ? score.score : 1;

  const series = [
    {
      data: [
        { color: '#46a8f6', fieldId: 'score', name: 'score', y: scoreToUse },
        { color: '#eff1f5', fieldId: 'unearn', name: 'unearn', y: 1 - scoreToUse }
      ]
    }
  ];

  const chartProps = {
    chart: {
      type: 'pie',
      height: 120,
      width: 120,
      margin: [0, 0, 5, 0]
    },
    // navigator: {
    //   enabled: false
    // },
    title: {
      text: `${(scoreToUse * 100).toFixed(0)}`,
      align: 'center',
      verticalAlign: 'middle',
      style: {
        color: colors.darkBlue,
        'font-size': '28px',
        'font-family': "'Roboto', sans-serif"
      },
      y: 27
    },
    series,
    plotOptions: {
      pie: {
        innerSize: '93%',
        size: 115,
        dataLabels: {
          enabled: false
        },
        center: ['50%', '52%']
      },
      series: {
        gapSize: 0,
        states: {
          select: {
            color: ''
          },
          hover: {
            enabled: false
          }
        },
        allowPointSelect: false
      }
    },
    tooltip: {
      enabled: false
    },
    credits: false,
    lang: {
      noData: ''
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={chartProps} />;
};

const mapDispatchToProps = {
  receiveOneAdScores: adScoreOperations.receiveOneAdScores
};
export default connect(mapStateToProps, mapDispatchToProps)(ScoreDonutChart);
