import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import { receiveGeoMapData, startFetchMapData, endFetchMapData } from './actions';
import ReduxStore from 'src/types/store/reduxStore';
import { OmniBaseRequestBody } from 'src/components/Omni/omniRequestUtils';

export interface AggregationMetric {
  name: string;
  displayName: string;
  fulfillmentType: string;
  aggregationFunction: string;
  metricName: string;
}

export interface OmniGeoMapServiceBody extends OmniBaseRequestBody {
  groupBy?: string;
  aggregationMetrics: AggregationMetric[];
  includeLocationState: string[];
  formatResponseForHC?: boolean;
}

export const fetchOmniGeoMapServiceData =
  (apiBody: OmniGeoMapServiceBody, mapName: string) => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
    dispatch(startFetchMapData({ mapName, data: [] }));
    try {
      const response = await axios.post('/omni/geoMap/getGeoMapData', apiBody);
      if (response && response.status === 200) {
        const { data } = response;
        const entity = {
          mapName,
          data
        };
        dispatch(receiveGeoMapData(entity));
        dispatch(
          endFetchMapData({
            mapName,
            data: []
          })
        );
      }
    } catch (e) {
      console.warn(e);
    }
  };
