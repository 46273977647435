import React from 'react';
import { StacklineTheme } from '@stackline/ui';
import _isNil from 'lodash/isNil';
import { IconDecrease, IconIncrease, IconNoChange } from 'src/components/BeaconRedesignComponents/icons';

export interface HoverTooltipProps {
  theme: StacklineTheme;
  title: string;
  change?: string;
  rawChange?: number;
  primaryMetric: string;
  primaryMetricLabel?: string;
  primaryColor: string | Highcharts.GradientColorObject | Highcharts.PatternObject;
  secondaryMetric?: string;
  secondaryMetricLabel?: string;
  secondaryColor?: string;
  tooltipWrapperStyle?: React.CSSProperties;
}

/**
 * Tooltip to be displayed when hovering
 * over Beacon graphs.
 *
 * NOTE: This must use pure HTML elements because it will
 * be converted into a string. This is because the Highcharts
 * tooltip formatter expects a string, not JSX.
 */
const HoverTooltip = ({
  title,
  change: formattedChange,
  rawChange,
  primaryMetric,
  primaryMetricLabel,
  primaryColor,
  secondaryMetric,
  secondaryColor,
  secondaryMetricLabel,
  theme,
  tooltipWrapperStyle = {}
}: HoverTooltipProps) => {
  const fontStyles = {
    fontFamily: 'Roboto',
    fontWeight: 500
  };

  const createMetricRow = (color: string, metric: string, metricLabel: string, marginBottom?: boolean) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          color,
          marginBottom: marginBottom ? '2px' : undefined
        }}
      >
        <div
          style={{
            ...fontStyles,
            fontSize: '12px',
            marginRight: theme.spacing.xs
          }}
        >
          {metric}
        </div>
        {metricLabel && (
          <div
            style={{
              ...fontStyles,
              fontSize: '10px'
            }}
          >
            {metricLabel}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        border: `1px solid ${theme.colors.primaryGray}`,
        borderRadius: theme.spacing.xs,
        padding: theme.spacing.sm,
        backgroundColor: 'white',
        display: 'inline-block',
        ...tooltipWrapperStyle
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
        {/* Title, e.g. Week 12 */}
        <span
          style={{
            fontSize: '14px',
            textTransform: 'uppercase',
            color: theme.colors.secondary,
            letterSpacing: '1.12px',
            ...fontStyles
          }}
        >
          {title}
        </span>
        {/* Percentage change next to title, like 9.02% */}
        {!_isNil(rawChange) && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: theme.spacing.sm,
              color: theme.colors[+rawChange.toFixed(3) === 0 ? 'secondary' : rawChange > 0 ? 'success' : 'error']
            }}
          >
            <div style={{ marginTop: '1px' }}>
              {/* This logic has to be inline for it to function with the static rendering */}
              {+rawChange.toFixed(3) === 0 ? (
                <div
                  style={{
                    transform: 'rotate(0deg)', // pointing to the right
                    color: theme.colors.primary
                  }}
                >
                  {IconNoChange}
                </div>
              ) : rawChange > 0 ? (
                <div style={{ color: theme.colors.success }}>{IconIncrease}</div> // pointing up
              ) : (
                <div
                  style={{
                    transform: 'rotate(180deg)', // pointing down
                    color: theme.colors.error
                  }}
                >
                  {IconDecrease}
                </div>
              )}
            </div>

            <div
              style={{
                marginLeft: theme.spacing.xs,
                fontSize: '12px',
                ...fontStyles
              }}
            >
              {formattedChange}
            </div>
          </div>
        )}
      </div>
      {createMetricRow(primaryColor, primaryMetric, primaryMetricLabel, true)}
      {secondaryMetric && createMetricRow(secondaryColor, secondaryMetric, secondaryMetricLabel)}
    </div>
  );
};

export default HoverTooltip;
