import React from 'react';
import { AdCampaignBuilderCheckbox } from 'src/components/AdCampaignBuilder/Widgets/Checkbox';
import './SelectableButtonGroup.scss';
import { List } from 'react-virtualized';

const CheckBoxGroup: React.FC<{
  items: { displayName: string; value: string | number }[];
  value: (string | number)[];
  onChange: (newValue: (string | number)[]) => void;
  style?: React.CSSProperties;
}> = ({ items, value, onChange }) => {
  const rowRenderer = ({ index, key, style }) => {
    const { displayName, value: itemValue } = items[index];
    return (
      <div key={key} className="selectable-button-group" style={{ ...style, minHeight: 40 }}>
        <AdCampaignBuilderCheckbox
          checked={value.includes(itemValue)}
          onChange={(_evt, checked) =>
            onChange(checked ? [...value, itemValue] : value.filter((val) => val !== itemValue))
          }
          color="primary"
          style={{ width: 'auto', padding: 0 }}
        />
        <span className="selectable-button-label">{displayName}</span>
      </div>
    );
  };

  return <List rowCount={items.length} rowHeight={45} height={250} width={600} rowRenderer={rowRenderer} />;
};

export default CheckBoxGroup;
