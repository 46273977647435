import React from 'react';
import numeral from 'numeral';
import { OmniTriArrowChange, OmniTriArrowChangeRed } from 'src/components/SvgIcons';

const OmniArrowChangeWithValue = ({
  value,
  width = 16,
  useImageUrl = false
}: {
  metric: any;
  value: number;
  width?: number;
  useImageUrl?: boolean;
}) => {
  let IconToUse = OmniTriArrowChange;
  let metricsChangePercent = value;
  if (metricsChangePercent > 10) {
    metricsChangePercent = 10;
  } else if (metricsChangePercent < -10) {
    metricsChangePercent = -10;
  }

  const valueDisplay = numeral(Math.abs(metricsChangePercent)).format('0.0%');

  let extraStyle: React.CSSProperties = {};

  if (value < 0) {
    IconToUse = OmniTriArrowChangeRed;
    extraStyle = {
      transform: 'rotate(180deg)'
    };
  }

  return (
    <div style={{ display: 'flex', fontWeight: 'inherit' }}>
      <IconToUse fill="red" style={{ width, marginRight: 5, ...extraStyle }} useImageUrl={useImageUrl} /> {valueDisplay}
    </div>
  );
};

export default OmniArrowChangeWithValue;
