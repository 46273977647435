/**
 * This file acts like the function renderWeeklyTrendChart and renders the GenericChart component
 */

import _isEqual from 'lodash/isEqual';
import _merge from 'lodash/merge';
import _get from 'lodash/get';
import React, { Component } from 'react';
import { Widget } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import colors from 'src/utils/colors';
import convertMetricToDisplayValue from '../../EntityGrid/gridUtils';
import GenericChart from '../GenericChart';
import './DonutChart.scss';
import _isEmpty from 'lodash/isEmpty';
import { shouldShowNewBeacon } from 'src/utils/app';
import DonutChartV2 from 'src/components/BeaconRedesignComponents/DonutChart/DonutChartV2';

interface DonutChartProps {
  widget: Widget;
  mainEntityMetrics: any;
  onDonutChartChange?: (selectedPoints: Highcharts.Point[]) => void;
  isStarChart?: boolean;
  retailer: ReduxStore['retailer'];
  chartProps?: any;
}

class DonutChart extends Component<DonutChartProps> {
  public shouldComponentUpdate(nextProps: DonutChartProps) {
    return !_isEqual(nextProps.mainEntityMetrics, this.props.mainEntityMetrics);
  }

  private getChartParameters = (
    providedChartPropsOverride: { [key: string]: any } | undefined | null,
    mainEntityMetrics: DonutChartProps['mainEntityMetrics']
  ) => {
    const chartPropsOverride = providedChartPropsOverride || {};
    const { data: widgetData } = this.props.widget;
    const { onDonutChartChange, isStarChart, retailer } = this.props;
    //  Refactor this to make more generic
    const donutClickFunction = isStarChart
      ? function handleDonutChartClick(this: any) {
          if (chartPropsOverride && !chartPropsOverride.enableMultiSelect) {
            return false;
          }
          this.slice(null);
          this.select(null, true);
          if (onDonutChartChange) {
            onDonutChartChange(this.series.chart.getSelectedPoints());
          }
          return true;
        }
      : null;

    const defaultDataLabels = {
      enabled: true,
      zIndex: 1,
      color: '#000000',
      backgroundColor: 'transparent',
      connectorWidth: 2,
      style: {
        color: '#1D2935',
        fontSize: '14px',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 'normal'
      },
      useHTML: true
    };

    let chartProps = {
      chart: {
        type: 'pie',
        height: 440
      },
      title: {
        text: `${convertMetricToDisplayValue(
          retailer,
          mainEntityMetrics.title,
          widgetData.mainMetric.metricType,
          mainEntityMetrics.currencySymbol,
          _get(chartPropsOverride, 'showFullValue', true)
        )}`,
        align: 'center',
        verticalAlign: 'middle',
        useHTML: false,
        style: {
          color: mainEntityMetrics.titleColor || colors.darkBlue,
          'font-size': mainEntityMetrics.titleFontSize || chartPropsOverride.titleFontSize || '44px'
        },
        y: 50
      },
      subtitle: {
        text: `${widgetData.secondaryMetric.displayName}`,
        align: 'center',
        verticalAlign: 'middle',
        style: { 'font-size': '16px' },
        useHTML: false,
        y: 80,
        paddingTop: '7%'
      },
      plotOptions: {
        pie: {
          dataLabels: defaultDataLabels
        },
        series: {
          allowPointSelect: !chartPropsOverride.disablePointSelect && !chartPropsOverride.enableMultiSelect,
          states: {
            hover: {
              enabled: !chartPropsOverride.disablePointSelect
            },
            select: {
              color: (chartPropsOverride.selectedPointColor && chartPropsOverride.selectedPointColor) || ''
            }
          },
          point: {
            events: {
              click: donutClickFunction
            }
          }
        }
      },
      tooltip: {
        enabled: true,
        pointFormat: '{point.percentage:.1f}%',
        borderWidth: 1,
        zIndex: 9999,
        borderColor: 'transparent',
        opacity: 1,
        backgroundColor: 'transparent',
        shadow: false,
        useHTML: true,
        shape: 'callout',
        positioner: (labelWidth: number, labelHeight: number, point: any) => ({ x: point.plotX, y: point.plotY }),
        formatter(this: any) {
          const metric = convertMetricToDisplayValue(
            retailer,
            this.percentage / 100,
            'PERCENT',
            mainEntityMetrics.currencySymbol,
            true
          );
          return `<div class="hichart-tooltip donut">
                  <div class="hiline-chart-tooltipx">
                    <span style="color: ${colors.darkBlue};">${this.point.name}</span>
                  </div>
                  <div class="hiline-chart-tooltipy metric-value">
                    <span style="color:${this.point.color};">${metric}</span>
                  </div>
                </div>`;
        }
      },
      credits: false,
      lang: {
        noData: ''
      },
      size: 50,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 400
            },
            chartOptions: {
              series: [
                {
                  id: 'versions',
                  dataLabels: {
                    enabled: false
                  }
                }
              ]
            }
          }
        ]
      }
    };

    const chartSeries = [
      {
        type: 'pie',
        name: 'Donut Chart',
        data: mainEntityMetrics.data,
        innerSize: '90%'
      }
    ];
    chartProps = _merge(chartProps, chartPropsOverride);
    return { chartProps, chartSeries };
  };

  public render() {
    const { widget, mainEntityMetrics, retailer } = this.props;
    if (_isEmpty(mainEntityMetrics)) {
      return null;
    }
    const { chartProps, chartSeries } = this.getChartParameters(widget.view.chartPropsOverride, mainEntityMetrics);
    const chartPropsToUse = this.props.chartProps || chartProps;

    if (shouldShowNewBeacon()) {
      const title = convertMetricToDisplayValue(
        retailer,
        mainEntityMetrics.title,
        widget.data.mainMetric.metricType,
        mainEntityMetrics.currencySymbol,
        _get(widget.view.chartPropsOverride, 'showFullValue', true)
      );
      return (
        <DonutChartV2
          getData={mainEntityMetrics.data}
          title={String(title)}
          subtitle={widget.data.secondaryMetric.displayName}
        />
      );
    }

    return (
      <div>
        <GenericChart chartSeries={chartSeries} chartProps={chartPropsToUse} />
      </div>
    );
  }
}

export default DonutChart;
