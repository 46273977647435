import { useEffect } from 'react';
import { useStore, useDispatch } from 'react-redux';
import moment from 'moment';
import ReduxStore from 'src/types/store/reduxStore';
import { useUpdateMainTimePeriod } from './typedHooks';
import { TimePeriod } from 'src/types/store/storeTypes';
import { getInitialState as getDefaultTimePeriodOptions } from 'src/store/modules/main-time-period/reducers';
import { computeMainTimePeriod } from 'src/utils/dateformatting';
import { updateMainTimePeriod as updateReduxMainTimePeriod } from 'src/store/modules/main-time-period/operations';
import { useUpdateQueryParams } from './useUpdateQueryParams';
import { History } from 'history';

export interface CustomDateOption {
  /**
   * ID of the custom date option
   */
  id: string;
  /**
   * Default start date if one is not
   * defined in the URL
   */
  defaultStartDate: Date;
  /**
   * Default end date if one is not defined
   * in the URL
   */
  defaultEndDate: Date;
}

interface UseUpdateTimePeriodDropdownArgs {
  /**
   * history from React Router.
   */
  history: History;
  /**
   * List of options to display in date dropdown
   */
  timePeriods: PartialTimePeriod[];
  /**
   * Option that should be selected by default on page load
   */
  defaultTimePeriod: PartialTimePeriod;
  /**
   * List of default start and end dates
   * for dropdown options that allow choosing
   * custom dates.
   */
  customDateOptions?: CustomDateOption[];

  /**
   * Optional callback to dynamically calculate if the
   * time period should be updated. For example, if the route
   * using custom time periods is protected then you don't want
   * to update the time periods if the user shouldn't be
   * able to access the route.
   */
  shouldUpdate?: () => boolean;
}
/**
 * This hook changes the options in the time period dropdown in the app header.
 * It will reset the options and query params to what they were before navigating to the page
 * using this hook.
 *
 * If the user navigated directly to the page using this hook (meaning there were no previous query params or time period),
 * then it will set the time period dropdown options to the default for the app.
 * @param history history from React Router.
 * @param timePeriods List of options to display in date dropdown
 * @param defaultTimePeriod Option that should be selected by default on page load
 */
export const useUpdateTimePeriodDropdown = ({
  history,
  timePeriods,
  defaultTimePeriod,
  customDateOptions,
  shouldUpdate
}: UseUpdateTimePeriodDropdownArgs) => {
  const dispatch = useDispatch();
  const store = useStore<ReduxStore>();
  const updateQueryParams = useUpdateQueryParams(history);
  const updateMainTimePeriod = useUpdateMainTimePeriod(history, timePeriods);

  /**
   * Update the time period options in the header dropdown
   */
  useEffect(() => {
    // Perform update if the callback is not given or
    // the callback returns true
    if (!shouldUpdate || shouldUpdate()) {
      const queryParams = new URLSearchParams(window.location.search);
      const wr = queryParams.get('wr');
      const customDateOption = customDateOptions ? customDateOptions.find(({ id }) => id === wr) : null;

      if (customDateOption) {
        const startDayId = queryParams.get('sdid');
        const endDayId = queryParams.get('edid');

        const startDate = startDayId
          ? moment(startDayId, 'YYYYMMDD').format('YYYY-MM-DD')
          : moment(customDateOption.defaultStartDate).format('YYYY-MM-DD');

        const endDate = endDayId
          ? moment(endDayId, 'YYYYMMDD').format('YYYY-MM-DD')
          : moment(customDateOption.defaultEndDate).format('YYYY-MM-DD');

        updateMainTimePeriod(wr, { startDate, endDate });
      } else {
        updateMainTimePeriod(wr);
      }
    }

    // Reset the time period dropdown options and URL query params
    // to the app defaults
    return () => {
      const { allWeekIdsByRetailerId, retailer, app } = store.getState();
      const { availableMainTimePeriods } = getDefaultTimePeriodOptions();
      const appDefaultTimePeriod = availableMainTimePeriods.find((period) => period.default);

      const appTimePeriod = computeMainTimePeriod(allWeekIdsByRetailerId[retailer.id], appDefaultTimePeriod, app);
      dispatch(
        updateReduxMainTimePeriod(
          appTimePeriod.startWeek,
          appTimePeriod.endWeek,
          appTimePeriod.id,
          appTimePeriod.shortDisplayName,
          availableMainTimePeriods,
          appTimePeriod.startWeekStartDate,
          appTimePeriod.startDayId,
          appTimePeriod.endWeekEndDate,
          appTimePeriod.endDayId
        )
      );
      updateQueryParams({ updatedMainPeriod: appTimePeriod });
    };
  }, [
    store,
    dispatch,
    defaultTimePeriod,
    timePeriods,
    updateQueryParams,
    updateMainTimePeriod,
    customDateOptions,
    shouldUpdate
  ]);
};

export type PartialTimePeriod = Pick<TimePeriod, 'id' | 'shortDisplayName' | 'onClickDateHandler'>;
