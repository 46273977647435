import { SlColumn, SlRow } from '@stackline/ui';
import { History } from 'history';
import React, { memo } from 'react';
import { CreateAdjustmentButton } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/CreateAdjustmentButton';
import ForecastKeyMetricsSummary from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastKeyMetricsSummary';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { useAppSelector, useQueryParamValue } from 'src/utils/Hooks';
import ForecastChangeBarChart from './ForecastChangeBarChart';
import ForecastProductListView from './ForecastProductListView';
import ForecastWaterfallChart from './ForecastWaterfallChart';
import ForecastsLineChart from './ForecastsLineChart';
import ForecastsProductSalesByWeekTable from './ForecastsProductSalesByWeekTable';

import { Box } from '@mui/system';
import ForecastSummaryTopEntityChart from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastSummaryTopEntityChart';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import {
  BEACON_CHART_SPACING,
  BEACON_GRID_TOP_SPACING,
  BEACON_KEY_METRIC_CARD_SPACING
} from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import ForecastAdjustmentsTypeProvider from 'src/components/BeaconRedesignComponents/ExperimentalLayout/ForecastAdjustmentsTypeProvider';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import ForecastWeeksOnHandChart from './ForecastWeeksOnHandChart';

export type OnCardClickFn = (args: { history: History; metricName: string; indexName: string }) => void;
export type BuildForecastDataForTrendWidgetFn = (args: { chartSeries: { data: number[][] }[] }) => number[][];

export type TopEntityGroupByFieldName = 'subCategoryId' | 'categoryId' | 'brandId';
export type TopEntitiesMetricFieldName =
  | 'retailSales'
  | 'unitsSold'
  | 'wholesaleSales'
  | 'retailerGrossMargin'
  | 'brandGrossMargin'
  | 'retailPrice'
  | 'instockRate'
  | 'totalClicks'
  | 'trafficOrganicClicks'
  | 'trafficOtherClicks'
  | 'trafficAdClicks'
  | 'spend'
  | 'adSales';

const titleOverrides = {
  sales: {
    purchaseRate: 'Conversion'
  },
  advertising: {
    clicks: 'Paid Traffic'
  },
  buybox: {
    winPercentage: 'Buy Box'
  },
  'ratings-reviews-metrics': {
    weightedRating: 'Rating'
  }
};
/**
 * Beacon Forecasts -> Summary page
 */
export default memo(function ForecastSummaryPage() {
  const entity = useAppSelector((state) => state.entityService.mainEntity);
  const isProductEntity = entity.type === 'product';

  const selectedIndexName = useQueryParamValue('indexName', 'sales');
  const selectedKeyMetric = useQueryParamValue('keyMetric', 'retailSales');

  return (
    <>
      <ForecastAdjustmentsTypeProvider>
        <BeaconPageContainer>
          <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
            <Flex flexDirection="column" gap={BEACON_KEY_METRIC_CARD_SPACING}>
              <SlColumn spacing="md" widths="full">
                <Box>
                  <SlRow horizontalPosition="space-between">
                    <CommonSummaryInfoSubtitle title="Key Metric Projections" />
                    <CreateAdjustmentButton />
                  </SlRow>
                </Box>

                <ForecastKeyMetricsSummary titleOverrides={titleOverrides} />
              </SlColumn>
              <ForecastsLineChart
                indexName={selectedIndexName}
                fieldName={selectedKeyMetric}
                titleOverrides={titleOverrides}
              />
            </Flex>
            <ForecastChangeBarChart />
            <ForecastWaterfallChart />
            <ForecastWeeksOnHandChart />
            {isProductEntity ? (
              <ForecastsProductSalesByWeekTable />
            ) : (
              <Flex flexDirection="column" gap={BEACON_GRID_TOP_SPACING}>
                <ForecastSummaryTopEntityChart />
                <ForecastProductListView />
              </Flex>
            )}
          </Flex>
        </BeaconPageContainer>
      </ForecastAdjustmentsTypeProvider>
    </>
  );
});
