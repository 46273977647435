import { useMemo } from 'react';
import { useAppSelector } from 'src/utils/Hooks';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import {
  AdvancedSearchByRetailerIdResponse,
  WaterfallAdjustedAdditionalValues,
  WaterfallUnadjustedAdditionalValues
} from './types';
import { parseWaterfallResponse } from './responseParsers';
import { useForecastPeriod, useForecastStartAndEndWeekIds, useForecastType, useLatestForecastModel } from './hooks';
import { UseQueryResult } from 'react-query';
import {
  ForecastPeriod,
  ForecastType
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import { getFirstWeekIdOfYear, getPreviousWeekId, yearPartOfWeekId } from 'src/utils/dateUtils';
import { useBaseMetricRequestBuilder } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';

export const waterfallFieldsToQuery = [
  'organicTrafficScaledContribution',
  'paidTrafficScaledContribution',
  'otherTrafficScaledContribution',
  'retailPriceScaledContribution',
  'contentScoreScaledContribution',
  'inStockRateScaledContribution',
  'ratingScaledContribution',
  'buyBoxScaledContribution',
  'actualTotalUnitsSoldChange',
  'currentUnitsSold',
  'priorUnitsSold'
];

export const useGetWaterfallData = (): UseQueryResult<WaterfallUnadjustedAdditionalValues> => {
  const retailerId = useAppSelector((state) => state.retailer.id);
  const { startWeekId: forecastStartWeekId, endWeekId: forecastEndWeekId } = useForecastStartAndEndWeekIds();
  const { modelVersion, loading, currentPublishVersion } = useLatestForecastModel();
  const forecastPeriod = useForecastPeriod();
  const forecastType = useForecastType();
  const startWeekId =
    forecastPeriod === ForecastPeriod.FULL_YEAR
      ? getFirstWeekIdOfYear(yearPartOfWeekId(forecastStartWeekId))
      : forecastStartWeekId;

  const actualUnitsRequestBuilder = useBaseMetricRequestBuilder({
    requestId: 'actualYoyUnitsChangeWaterfall',
    startWeekId,
    endWeekId: getPreviousWeekId(forecastStartWeekId),
    indexName: 'actual-yoy-units-change',
    fields: waterfallFieldsToQuery.map((fieldName) => ({ name: fieldName })),
    sortFieldName: 'organicTrafficScaledContribution',
    groupByFieldName: 'retailerId',
    buildAggregationBuilder: (aggBuilder) => aggBuilder.clearConditionRangeFilters()
  });

  const unadjustedASRequestBuilder = useBaseMetricRequestBuilder({
    requestId: 'unadjustedYoyUnitsChangeWaterfall',
    startWeekId,
    endWeekId: getPreviousWeekId(forecastStartWeekId),
    indexName: 'unadjusted-yoy-units-change',
    fields: waterfallFieldsToQuery.map((fieldName) => ({ name: fieldName })),
    sortFieldName: 'organicTrafficScaledContribution',
    groupByFieldName: 'retailerId',
    buildAggregationBuilder: (aggBuilder) => aggBuilder.clearConditionRangeFilters()
  });

  const adjustedASRequestBuilder = useBaseMetricRequestBuilder({
    requestId: 'adjustedYoyUnitsChangeWaterfall',
    startWeekId,
    endWeekId: getPreviousWeekId(forecastStartWeekId),
    indexName: 'adjusted-yoy-units-change',
    fields: waterfallFieldsToQuery.map((fieldName) => ({ name: `${fieldName}Delta` })),
    sortFieldName: 'organicTrafficScaledContributionDelta',
    groupByFieldName: 'retailerId',
    buildAggregationBuilder: (aggBuilder) => aggBuilder.clearConditionRangeFilters()
  });

  const actualUnitsASRequest = useMemo(() => {
    actualUnitsRequestBuilder.setReturnAdditionalMetadata(false);
    return actualUnitsRequestBuilder.build();
  }, [actualUnitsRequestBuilder]);

  const unadjustedASRequest = useMemo(() => {
    unadjustedASRequestBuilder
      .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
      .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
      .clearConditionRangeFilters()
      .addConditionRangeFilter('forecastWeekId', forecastStartWeekId, forecastEndWeekId)
      .setReturnAdditionalMetadata(false);

    return unadjustedASRequestBuilder.build();
  }, [unadjustedASRequestBuilder, modelVersion, currentPublishVersion, forecastStartWeekId, forecastEndWeekId]);

  const adjustedASRequest = useMemo(() => {
    adjustedASRequestBuilder
      .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
      .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])

      .clearConditionRangeFilters()
      .addConditionRangeFilter('forecastWeekId', forecastStartWeekId, forecastEndWeekId)
      .setReturnAdditionalMetadata(false);

    return adjustedASRequestBuilder.build();
  }, [adjustedASRequestBuilder, currentPublishVersion, forecastEndWeekId, forecastStartWeekId, modelVersion]);

  const waterfallASRequest = useMemo(() => {
    return [actualUnitsASRequest, unadjustedASRequest, adjustedASRequest];
  }, [actualUnitsASRequest, unadjustedASRequest, adjustedASRequest]);

  const { data: waterfallResponse, ...rest } = useGenericAdvancedSearch<
    [
      AdvancedSearchByRetailerIdResponse<WaterfallUnadjustedAdditionalValues>,
      AdvancedSearchByRetailerIdResponse<WaterfallUnadjustedAdditionalValues>,
      AdvancedSearchByRetailerIdResponse<WaterfallAdjustedAdditionalValues>
    ]
  >({
    requestId: 'actualYoyUnitsChangeWaterfall',
    queryKeys: waterfallASRequest,
    requestBody: waterfallASRequest,
    shouldPerformFetch: !loading
  });

  const response: WaterfallUnadjustedAdditionalValues = useMemo(() => {
    const emptyResponse: WaterfallUnadjustedAdditionalValues = {
      actualTotalUnitsSoldChange_sum_value: 0,
      buyBoxScaledContribution_sum_value: 0,
      contentScoreScaledContribution_sum_value: 0,
      currentUnitsSold_sum_value: 0,
      inStockRateScaledContribution_sum_value: 0,
      organicTrafficScaledContribution_sum_value: 0,
      otherTrafficScaledContribution_sum_value: 0,
      paidTrafficScaledContribution_sum_value: 0,
      priorUnitsSold_sum_value: 0,
      ratingScaledContribution_sum_value: 0,
      retailPriceScaledContribution_sum_value: 0
    };

    if (!waterfallResponse) {
      return emptyResponse;
    }

    const parsedResponse = parseWaterfallResponse(
      retailerId,
      waterfallResponse.data[0],
      waterfallResponse.data[1],
      forecastType === ForecastType.ADJUSTED ? waterfallResponse.data[2] : undefined
    );

    return parsedResponse || emptyResponse;
  }, [forecastType, retailerId, waterfallResponse]);

  return {
    data: response,
    ...rest
  } as UseQueryResult<WaterfallUnadjustedAdditionalValues>;
};
