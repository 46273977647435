import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

export const UNADJUSTED_YOY_UNITS_CHANGE_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  organicTrafficScaledContribution: {
    name: 'organicTrafficScaledContribution',
    displayName: 'Organic Traffic - Units Sold Change',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  paidTrafficScaledContribution: {
    name: 'paidTrafficScaledContribution',
    displayName: 'Paid Traffic - Units Sold Change',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  otherTrafficScaledContribution: {
    name: 'otherTrafficScaledContribution',
    displayName: 'Other Traffic - Units Sold Change',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  retailPriceScaledContribution: {
    name: 'retailPriceScaledContribution',
    displayName: 'Retail Price - Units Sold Change',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  contentScoreScaledContribution: {
    name: 'contentScoreScaledContribution',
    displayName: 'Content Score - Units Sold Change',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  inStockRateScaledContribution: {
    name: 'inStockRateScaledContribution',
    displayName: 'In Stock Rate - Units Sold Change',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  ratingScaledContribution: {
    name: 'ratingScaledContribution',
    displayName: 'Rating - Units Sold Change',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  buyBoxScaledContribution: {
    name: 'buyBoxScaledContribution',
    displayName: 'Buy Box - Units Sold Change',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  actualTotalUnitsSoldChange: {
    name: 'actualTotalUnitsSoldChange',
    displayName: 'Actual Total Units Sold Change',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  currentUnitsSold: {
    name: 'currentUnitsSold',
    displayName: 'Units Sold (Current)',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  priorUnitsSold: {
    name: 'priorUnitsSold',
    displayName: 'Units Sold (Prior)',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  }
};

export const ADJUSTED_YOY_UNITS_CHANGE_FIELDS: {
  [key: string]: FieldDefinition;
} = Object.entries(UNADJUSTED_YOY_UNITS_CHANGE_FIELDS).reduce((acc, [key, value]) => {
  const deltaField = `${key}Delta`;
  acc[deltaField] = {
    ...value,
    name: deltaField
  };
  return acc;
}, {});
