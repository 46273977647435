import React from 'react';
import { SvgElementProps } from 'src/components/SvgIcons/SvgIcons';
import Typography from '@mui/material/Typography';
import colors from 'src/utils/colors';

interface VisualizationPreviewProps {
  /**
   * SVG Icon to display in the preview
   */
  Icon: (props: SvgElementProps) => JSX.Element;
  /**
   * Title of the dashboard preview
   */
  title: string;
  /**
   * Description of the dashboard
   */
  description: string;

  /**
   * Customize the height of the icon
   */
  iconHeight?: number;
}
/**
 * Shows a preview for an AMC dashboard tab that hasn't
 * been released yet. It shows a relevant icon, title,
 * and description of the upcoming dashboard.
 */
const VisualizationPreview = ({ Icon, title, description, iconHeight }: VisualizationPreviewProps) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '138px' }}>
      <div style={{ display: 'inline-block', width: '492px' }}>
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F2F4F6',
            height: '80px',
            width: '80px',
            borderRadius: '40px',
            marginBottom: '16px'
          }}
        >
          <Icon height={iconHeight} />
        </div>
        <Typography fontSize={14} fontWeight="bold" marginBottom="8px" color={colors.stacklineBlue}>
          COMING SOON
        </Typography>
        <Typography fontSize={28} fontWeight="bold" marginBottom="8px">
          {title}
        </Typography>
        <Typography fontSize={20}>{description}</Typography>
      </div>
    </div>
  );
};

VisualizationPreview.defaultProps = {
  iconHeight: 43
};

export default VisualizationPreview;
