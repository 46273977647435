import React from 'react';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';

function buildAggregationFields(aggBuilder: AggregationBuilder): AggregationBuilder {
  return aggBuilder.clearConditionRangeFilters().addConditionRangeFilter('totalClicks', 1);
}

/**
 * Keywords with Traffic Spline Chart
 */
export default function KeywordsWithTrafficSplineChart() {
  return (
    <BeaconComparableSplineChart
      indexName="traffic"
      fieldName="searchTerm"
      title="Keywords with Traffic"
      primaryAggregationBuilder={buildAggregationFields}
      secondaryAggregationBuilder={buildAggregationFields}
    />
  );
}
