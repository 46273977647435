import types from './types';

export const receiveOmniSearchServiceDataForMain = (entity: any) => ({
  type: types.RECEIVE_SEARCH_DATA_FOR_MAIN,
  entity
});

export const receiveOmniSearchServiceDataForCompare = (entity: any) => ({
  type: types.RECEIVE_SEARCH_DATA_FOR_COMPARE,
  entity
});

export const startFetchData = (entity: any) => ({
  type: types.START_FETCH,
  entity
});

export const endFetchData = (entity: any) => ({
  type: types.END_FETCH,
  entity
});
