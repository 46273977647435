import React, { useEffect, useRef, useState } from 'react';
import { withBus } from 'react-bus';
import { useAppSelector, useBus } from 'src/utils/Hooks';
import _get from 'lodash/get';
import { InvoiceSummaryResponse } from 'src/components/ShortageDisputes/responseTypes';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import { CheckCircle } from 'src/components/SvgIcons';
import { fetchSubmissionStatus } from 'src/components/ShortageDisputes/components/DisputeManagement/utils';
import { useQueryClient } from 'react-query';
import {
  DISPUTE_DATA_QUERY_KEY,
  DISPUTE_SUBMISSION_EVENT
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/constants';

const SuccessSnackbar = styled(Snackbar)({
  '& .MuiPaper-root': {
    width: 'auto',
    height: '55px',
    backgroundColor: 'rgb(224 239 231)',
    color: '#222155'
  }
});

const TOTAL_POLL_TIME_IN_MINUTES = 5; // If there's something stuck in the queue, we can stop polling after 5 minutes
const POLL_INTERVAL_MILLISECONDS = 10000; // 10 second interval
/**
 *  Convert desired max polling time in minutes to ms.
 *  Divide by poll interval (ms) to get max poll attempts.
 */
const MAX_POLL_ATTEMPTS = (TOTAL_POLL_TIME_IN_MINUTES * 60 * 1000) / POLL_INTERVAL_MILLISECONDS;

/**
 * Initial time delay before first the first poll is sent in MS
 */
const INITIAL_POLL_DELAY_MILLISECONDS = 30000;

/**
 * This component is mounted in the primary navigation container to poll submitted dispute statuses before rendering a snackbar
 * @param eventBus listens for submission event from useSubmitDisputes
 * @returns
 */
const DisputeStatusUpdater = ({ eventBus }: { eventBus: any }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const submissionCountRef = useRef(0);
  const retailerId = useAppSelector((state) => _get(state, ['retailer', 'id']));
  const queryClient = useQueryClient();

  let timer = null;

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const shouldPoll = async () => {
    // implement initial fetch here to check queue
    return new Promise((resolve) => {
      setTimeout(async () => {
        const response = await fetchSubmissionStatus(retailerId);
        const invoiceData: InvoiceSummaryResponse[] = _get(response, 'data[0].documents', []);
        if (invoiceData.length > 0) {
          submissionCountRef.current = invoiceData.length;
          resolve(true);
        } else {
          resolve(false);
        }
      }, INITIAL_POLL_DELAY_MILLISECONDS); // wait for 30 seconds before fetching submission status after submission
    });
  };

  const checkForUpdate = (invoiceData) => {
    if (invoiceData.length < submissionCountRef.current) {
      setSnackbarOpen(true);
      queryClient.invalidateQueries({ queryKey: [DISPUTE_DATA_QUERY_KEY] });
      submissionCountRef.current -= 1;
    }
  };

  const beginPolling = (pollAttempts = 1) => {
    if (timer) {
      clearInterval(timer);
    }

    if (pollAttempts >= MAX_POLL_ATTEMPTS) {
      return null;
    }
    timer = setTimeout(async () => {
      const response = await fetchSubmissionStatus(retailerId);
      const invoiceData: InvoiceSummaryResponse[] = _get(response, 'data[0].documents', []);
      checkForUpdate(invoiceData);
      if (invoiceData.length > 0) {
        pollAttempts++;
        beginPolling(pollAttempts);
      } else {
        return null;
      }
      return null;
    }, POLL_INTERVAL_MILLISECONDS);
    return null;
  };

  const checkPollingStatus = () => {
    if (shouldPoll()) {
      beginPolling();
    }
    return null;
  };

  useBus(eventBus, DISPUTE_SUBMISSION_EVENT, checkPollingStatus);

  useEffect(() => {
    checkPollingStatus();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      <SuccessSnackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleSnackbarClose}
        message={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircle style={{ width: '28px', marginRight: '5px' }} />
            <span style={{ fontSize: '16px' }}>Processing complete. Your dispute submission was successful.</span>
          </div>
        }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  );
};

export default withBus('eventBus')(DisputeStatusUpdater);
