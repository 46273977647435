/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import withStyles from '@mui/styles/withStyles';

import { DayPickerSingleDateController, isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import { FieldWapper, muiStyles } from 'src/components/AdCampaignBuilder/Steps/SetupDetail';
import CalendarClearButton from 'src/components/common/DatePicker/CalendarClearButton';
import TextField from '@mui/material/TextField';

const StartEndDatePicker: React.FC<{
  value: { startDate?: string | Date | number | null | Moment; endDate?: string | Date | number | null | Moment };
  handleStartDateChange: (startDate?: Moment | null) => void;
  handleEndDateChange: (endDate?: Moment | null) => void;
  classes: { [key: string]: any };
  timezone: string;
  styleRoot: React.CSSProperties;
}> = ({ styleRoot, classes, timezone, value: { startDate, endDate }, handleStartDateChange, handleEndDateChange }) => {
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);

  const startDateLimit = (day) => {
    const todayDate = moment().tz(timezone);

    if (!endDate) {
      // if endDate not set, use could set startDate to be any day after today.
      return !isInclusivelyAfterDay(day, todayDate);
    } else {
      // if endDate set, use could set startDate to be any day between today and endDate.
      return !isInclusivelyAfterDay(day, todayDate) || !isInclusivelyBeforeDay(day, moment(endDate));
    }
  };

  const endDateLimit = (day) => {
    const todayDate = moment().tz(timezone);

    let latestDate = todayDate;
    if (startDate && todayDate.isBefore(moment(startDate).tz(timezone))) {
      latestDate = moment(startDate).tz(timezone);
    }
    // disable all dates before today
    if (!isInclusivelyAfterDay(day, latestDate)) {
      return true;
    }

    return false;
  };

  return (
    <div style={{ display: 'flex' }}>
      <FieldWapper title="Start Date" style={{ margin: '0px 40px 40px 0px' }} className="start-date-select">
        <TextField
          variant="standard"
          autoComplete="off"
          classes={{ root: classes.dateRoot }}
          style={{ ...styleRoot }}
          InputProps={{ classes: { root: classes.dateRoot } }}
          value={startDate ? moment(startDate).tz(timezone).format('MM/DD/YYYY') : 'No Start Date'}
          id="outlined-bare"
          onClick={() => setStartDatePickerOpen(!startDatePickerOpen)}
          margin="normal"
        />
        {startDatePickerOpen && (
          <div style={{ position: 'absolute', zIndex: 1 }}>
            <DayPickerSingleDateController
              hideKeyboardShortcutsPanel
              monthFormat="MMMM YYYY"
              date={startDate ? moment(startDate).tz(timezone) : null} // momentPropTypes.momentObj or null
              onDateChange={(date) => {
                handleStartDateChange(date);
              }} // PropTypes.func.isRequired
              focused
              isOutsideRange={startDateLimit}
              onFocusChange={() => {}} // PropTypes.func.isRequired
              onOutsideClick={() => setStartDatePickerOpen(!startDatePickerOpen)}
              id="adPortfolioSettingStartDatePicker"
            />
          </div>
        )}
      </FieldWapper>{' '}
      <FieldWapper title="End Date" style={{ marginBottom: 40 }} className="end-date-select">
        <TextField
          variant="standard"
          autoComplete="off"
          style={{ ...styleRoot }}
          classes={{ root: classes.dateRoot }}
          InputProps={{ classes: { root: classes.dateRoot } }}
          value={endDate ? moment(endDate).format('MM/DD/YYYY') : 'No End Date'}
          id="outlined-bare"
          onClick={() => setEndDatePickerOpen(!endDatePickerOpen)}
          margin="normal"
        />
        {endDatePickerOpen && (
          <div style={{ position: 'absolute', zIndex: 1 }}>
            {' '}
            <DayPickerSingleDateController
              hideKeyboardShortcutsPanel
              monthFormat="MMMM YYYY"
              date={endDate ? moment(endDate) : null} // momentPropTypes.momentObj or null
              onDateChange={
                (date) => {
                  handleEndDateChange(date);
                } // PropTypes.func.isRequired
              }
              focused
              onFocusChange={() => {}} // PropTypes.func.isRequired
              isOutsideRange={endDateLimit}
              onOutsideClick={() => setEndDatePickerOpen(!endDatePickerOpen)}
              id="adCampaignSettingEndDatePicker"
              renderCalendarInfo={() => (
                <CalendarClearButton
                  hasEndDate={!!endDate}
                  handleClear={() => {
                    handleEndDateChange(null);
                  }}
                />
              )}
            />{' '}
          </div>
        )}
      </FieldWapper>
    </div>
  );
};

export default withStyles(muiStyles)(StartEndDatePicker);
