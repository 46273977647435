import { Widget } from 'src/types/application/widgetTypes';
import { Dispatch } from 'react';
import { RangeFilter, TermFilter } from 'sl-api-connector/types';
import { CancelTokenSource } from 'axios';
import _cloneDeep from 'lodash/cloneDeep';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { zipMetricsResponseIntoArray } from '../../index';
import _get from 'lodash/get';
import { AD_STATUS_DERIVED } from 'sl-ad-campaign-manager-data-model';
import _uniq from 'lodash/uniq';
import { getCampaignDisplayStatus } from 'src/components/AdManager/AmsUtils';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import {
  getESBodyOverridesForParentPlatform,
  getSearchRequestOverrideForGroupByField,
  modifyESQuery
} from 'src/components/AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';
import { Indices } from 'src/components/Layout/Advertising/AdManagerPageLayout/types';
import { IGridResultData } from 'src/components/AdManager/Search/Models/IGridResultData';
import { IFetchEntityMetricsAndParseResponseParams } from 'src/components/AdManager/Search/Models/IFetchEntityMetricsAndParseResponseParams';
import {
  fetchEntityMetricsAndParseResponse,
  getClonedResult
} from 'src/components/AdManager/Search/GridDataFetchers/FetchDataForGroupByField';
import { ProductChunksFetcher } from 'src/components/AdManager/Search/GridDataFetchers/Products/ProductChunksFetcher';
import { ProductsWithNoMetricsFetcher } from 'src/components/AdManager/Search/GridDataFetchers/Products/ProductsWithNoMetricsFetcher';
import { ProductsWithZerosHelper } from 'src/components/AdManager/Search/GridDataFetchers/Products/ProductsWithZerosHelper';
import { ProductMapHelper } from 'src/components/AdManager/Search/GridDataFetchers/Products/ProductMapHelper';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';
import { getParentPlatform } from 'src/utils/browser';
import { shouldShowCriteo } from 'src/utils/app';
import { Props } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';

export type OverrideQueryConditionsFn = (args: {
  queryConditions: ISearchFilterConditions;
  skusWithMetrics: string[];
  customTermFilters: TermFilter[];
}) => ISearchFilterConditions;

export interface ProductStatuses {
  status?: string;
  statusDerived?: string;
  statusReason?: string;
}
export type OverrideProductStatusFn = (args: { adCampaignProductDocument: ProductStatuses }) => ProductStatuses;

export class ProductsDataFetcher {
  public async fetchProductsData(
    mainEntity: any,
    customTermFilters: any,
    widget: Widget,
    scheduledActionTermFilters: TermFilter[],
    onFirstPageAndCount: boolean,
    dispatch: Dispatch<any>,
    retailer: any,
    app: any,
    indexName: string,
    aggregationFieldsForResultCount: any,
    mainTimePeriodRangeFilters: RangeFilter[],
    aggregationFilters: ISearchFilterConditions,
    sortDirection: string | undefined,
    currentSortFieldName: any,
    searchSideBarConditions: ISearchFilterConditions,
    pageNumber: number,
    cancelSource: CancelTokenSource,
    fetchMetricsParams: IFetchEntityMetricsAndParseResponseParams,
    dataSet: any,
    props: Pick<Props, 'location'>,
    {
      overrideQueryConditions,
      overrideStatuses
    }: { overrideQueryConditions?: OverrideQueryConditionsFn; overrideStatuses?: OverrideProductStatusFn } = {},
    importantOverrides: any
  ): Promise<IGridResultData> {
    const result = await fetchEntityMetricsAndParseResponse(fetchMetricsParams, dispatch, cancelSource);
    const cloneResult = getClonedResult(result, pageNumber, dataSet);

    // when it is on campaign tab, fetch the projected spend for the Budget column
    const indexToUse =
      mainEntity.type !== 'adTarget'
        ? Indices.adCampaignAdGroupProduct
        : Indices.adCampaignAdGroupProductTargetDailyMetrics;
    const stacklineSkuValues = result.data.map((dataPoint: any) => dataPoint.id);
    let queryConditions: ISearchFilterConditions = {
      termFilters: [
        {
          fieldName: 'stacklineSku',
          condition: 'should',
          values: stacklineSkuValues
        }
      ],
      rangeFilters: []
    };
    const cloneAllTermFilters = _cloneDeep(customTermFilters);
    if (widget.data.showZeroProducts) {
      queryConditions = {
        termFilters: [...scheduledActionTermFilters, ..._cloneDeep(customTermFilters)],
        rangeFilters: []
      };
    }
    let showZeroProducts = false;
    if (onFirstPageAndCount) {
      const groupByFieldName = 'stacklineSku';
      const searchRequestOverrideForProducts = getSearchRequestOverrideForGroupByField(
        aggregationFieldsForResultCount,
        retailer,
        mainTimePeriodRangeFilters,
        aggregationFilters,
        sortDirection,
        currentSortFieldName,
        groupByFieldName,
        searchSideBarConditions,
        scheduledActionTermFilters,
        customTermFilters,
        pageNumber
      );

      let copyOfOVerrides = _cloneDeep([searchRequestOverrideForProducts]);

      if (shouldShowCriteo()) {
        const parentPlatform = getParentPlatform();
        if (importantOverrides && parentPlatform && parentPlatform === PARENT_PLATFORMS.CRITEO) {
          const newOverride = [];
          copyOfOVerrides.forEach((_, indx) => {
            const mutatedQuery = modifyESQuery(
              copyOfOVerrides[indx],
              getESBodyOverridesForParentPlatform(parentPlatform, importantOverrides)
            );
            newOverride.push(mutatedQuery);
          });
          copyOfOVerrides = newOverride;
        }
      }

      const allResults = await dispatch(
        fetchEntityMetrics(
          'adManager_searchResultsGrid_allResultIds',
          {
            entity: mainEntity,
            retailer,
            app,
            indexName,
            customResponseParser: (action: any) => {
              return zipMetricsResponseIntoArray(action, widget);
            }
          },
          copyOfOVerrides,
          _get(cancelSource, 'token'),
          true
        )
      );

      const fullDataSet = allResults.data.map((item) => item.entity);
      cloneResult.fullDataSet = fullDataSet;
      const allStacklineSkus = fullDataSet.map((item) => item.id);
      queryConditions.termFilters.forEach((termFilter) => {
        if (termFilter.fieldName === 'stacklineSku') {
          termFilter.values = allStacklineSkus;
        }
      });

      const skusWithMetrics = allResults.data.map((x: any) => x.id.toUpperCase());

      if (overrideQueryConditions) {
        queryConditions = overrideQueryConditions({
          queryConditions,
          skusWithMetrics,
          customTermFilters: cloneAllTermFilters
        });
      }

      const isOnCampaignOrAdGroupPage =
        app.name === 'advertising' &&
        props.location &&
        props.location.pathname &&
        (props.location.pathname.includes('adCampaign') || props.location.pathname.includes('adGroup'));
      if (isOnCampaignOrAdGroupPage) {
        const productsWithNoMetricsFetcher = new ProductsWithNoMetricsFetcher();
        const missingSkus = await productsWithNoMetricsFetcher.getProductSkusWithNoMetricsForCampaignOrAdGroup(
          searchSideBarConditions,
          dispatch,
          widget,
          mainEntity,
          retailer,
          app,
          cancelSource,
          skusWithMetrics
        );
        queryConditions.termFilters.forEach((termFilter) => {
          if (termFilter.fieldName === 'stacklineSku') {
            termFilter.values = [...termFilter.values, ...missingSkus];
          }
        });

        // make widget data show zero products as true
        showZeroProducts = true;
      }
    }

    const allTermFilters = [...searchSideBarConditions.termFilters, ...queryConditions.termFilters];
    const termFilterIndex = allTermFilters.findIndex((x) => x.fieldName === 'stacklineSku');
    const allStacklineSkuValues = [
      ...new Set(allTermFilters[termFilterIndex].values.map((x: string) => x.toLowerCase()))
    ];
    if (showZeroProducts) {
      const dataCount = allStacklineSkuValues.length;
      cloneResult.totalResultCount = Math.max(cloneResult.totalResultCount, dataCount);
    }
    const adCampaignProductDocuments = await new ProductChunksFetcher().fetchProductDocuments(
      allStacklineSkuValues,
      allTermFilters,
      termFilterIndex,
      dispatch,
      mainEntity,
      retailer,
      app,
      indexToUse,
      searchSideBarConditions,
      cancelSource,
      importantOverrides
    );
    const adProductDocumentsByStacklineSkuMap: any = {};

    const mapHelper = new ProductMapHelper();
    adCampaignProductDocuments.forEach((adProductDocument: any) => {
      mapHelper.addProductToChildDocumentsOfProductMapEntry(
        adProductDocumentsByStacklineSkuMap,
        adProductDocument,
        overrideStatuses
      );
    });
    new ProductsWithZerosHelper().fillProductsWithZeroMetrics(
      widget,
      showZeroProducts,
      cloneResult,
      adProductDocumentsByStacklineSkuMap
    );

    this.placeDoc(cloneResult.data, adProductDocumentsByStacklineSkuMap);

    if (onFirstPageAndCount && cloneResult.fullDataSet) {
      this.placeDoc(cloneResult.fullDataSet, adProductDocumentsByStacklineSkuMap);
    }

    return {
      gridResult: cloneResult
    };
  }

  // eslint-disable-next-line class-methods-use-this
  private placeDoc(dateSetWithOutDoc: any[], adProductDocumentsByStacklineSkuMap: any) {
    dateSetWithOutDoc.forEach((dataPoint: any) => {
      const dataId = dataPoint.id.toUpperCase();
      const adProductDocumentsForStacklineSku = adProductDocumentsByStacklineSkuMap[dataId];
      if (!adProductDocumentsForStacklineSku) {
        return;
      }

      const { extendedAttributes } = adProductDocumentsForStacklineSku;
      extendedAttributes.campaignIds = _uniq(
        extendedAttributes.childDocuments
          .filter((x: any) => x.statusDerived !== AD_STATUS_DERIVED.ARCHIVED)
          .map((adCampaignProductDocument: any) => adCampaignProductDocument.campaignId)
      );
      extendedAttributes.campaignIdsDelivering = _uniq(
        extendedAttributes.childDocuments
          .filter((x: any) => x.statusDerived === AD_STATUS_DERIVED.DELIVERING)
          .map((adCampaignProductDocument: any) => adCampaignProductDocument.campaignId)
      );
      extendedAttributes.statusDisplay = getCampaignDisplayStatus(extendedAttributes);
      if (dataPoint.entity) {
        dataPoint.entity = {
          ...dataPoint.entity,
          ...adProductDocumentsForStacklineSku
        };
      } else {
        dataPoint.beaconClientId = adProductDocumentsForStacklineSku.beaconClientId;
        dataPoint.platformType = adProductDocumentsForStacklineSku.platformType;
        dataPoint.extendedAttributes = {
          ...extendedAttributes
        };
      }
    });
  }
}
