import { Dispatch } from 'react';
import { Widget } from 'src/types/application/widgetTypes';
import { RangeFilter, TermFilter } from 'sl-api-connector/types';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import { CancelTokenSource } from 'axios';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { zipMetricsResponseIntoArray } from '../../index';
import _get from 'lodash/get';
import { constructNewChildDocumentsMapByCampaignId } from 'src/components/AdManager/Search/GridDataFetchers/Campaigns/CampaignIdToChildDocumentsMapGenerator';
import { getSearchRequestOverrideForGroupByField } from 'src/components/AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';
import { SearchGridConstants } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConstants';
import { CampaignIdToChildDocumentsMap } from 'src/components/AdManager/Search/GridDataFetchers/Campaigns/CampaignIdToChildDocumentsMap';

export class CampaignsForMainEntityProduct {
  // eslint-disable-next-line class-methods-use-this
  public async forMainEntityProduct(
    onFirstPageAndCount: boolean,
    dispatch: Dispatch<any>,
    mainEntity: any,
    retailer: any,
    app: any,
    indexName: any,
    widget: Widget,
    aggregationFieldsForResultCount: any,
    mainTimePeriodRangeFilters: RangeFilter[],
    aggregationFilters: ISearchFilterConditions,
    sortDirection: string | undefined,
    currentSortFieldName: any,
    searchSideBarConditions: ISearchFilterConditions,
    scheduledActionTermFilters: TermFilter[],
    customTermFilters: any,
    pageNumber: number,
    cancelSource: CancelTokenSource,
    cloneResult: any,
    queryConditions: ISearchFilterConditions
  ): Promise<CampaignIdToChildDocumentsMap> {
    if (onFirstPageAndCount) {
      const groupByFieldName = 'campaignId';
      const searchRequestOverrideForCampaignIds = getSearchRequestOverrideForGroupByField(
        aggregationFieldsForResultCount,
        retailer,
        mainTimePeriodRangeFilters,
        aggregationFilters,
        sortDirection,
        currentSortFieldName,
        groupByFieldName,
        searchSideBarConditions,
        scheduledActionTermFilters,
        customTermFilters,
        pageNumber
      );
      const allResults = await dispatch(
        fetchEntityMetrics(
          'adManager_searchResultsGrid_allResultIds',
          {
            entity: mainEntity,
            retailer,
            app,
            indexName,
            customResponseParser: (action: any) => {
              return zipMetricsResponseIntoArray(action, widget);
            }
          },
          [searchRequestOverrideForCampaignIds],
          _get(cancelSource, 'token'),
          true
        )
      );
      const fullDataSet = allResults.data.map((item) => item.entity);
      cloneResult.fullDataSet = fullDataSet;
      const allCampaignIds = fullDataSet.map((item) => item.id);
      queryConditions.termFilters.forEach((termFilter) => {
        if (termFilter.fieldName === 'campaignId') {
          termFilter.values = allCampaignIds;
        }
      });
    }
    const { documents: adProductDocuments } = await dispatch(
      fetchEntityMetrics(
        'adManager_searchResultsGrid_targets',
        {
          entity: mainEntity,
          retailer,
          app,
          indexName: 'adCampaignAdGroupProductTargetDailyMetrics'
        },
        [
          {
            doAggregation: true,
            conditions: {
              termFilters: [...searchSideBarConditions.termFilters, ...queryConditions.termFilters],
              rangeFilters: [...searchSideBarConditions.rangeFilters]
            },
            pageNumber: 1,
            pageSize: SearchGridConstants.PAGE_SIZE_25K,
            processDocuments: false,
            returnDocuments: true
          }
        ],
        _get(cancelSource, 'token'),
        true
      )
    );

    const campaignToChildDocumentsMap = constructNewChildDocumentsMapByCampaignId(adProductDocuments, 'product');
    return campaignToChildDocumentsMap;
  }
}
