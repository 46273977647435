import React, { useState, useEffect, useMemo } from 'react';
import { SlColumn, SlRow, Text } from '@stackline/ui';
import { useAppSelector } from 'src/utils/Hooks';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import EntityTableHeader from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import {
  ForecastsAdjustmentData,
  PlanType,
  handleCsvDownload,
  parseForecastsRecommendationsResponse,
  useForecastRecommendationsColumnDefinitions,
  useGenerateRecommendationCSVData
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import {
  useForecastPlanType,
  useLatestForecastModel
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import { ADJUSTMENT_TABLE_QUERY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastQueryKeys';
import { CreateAdjustmentButton } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/CreateAdjustmentButton';
import { AdjustmentPlanType } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { useApplyEntityFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import ForecastAdjustmentsTypeProvider from 'src/components/BeaconRedesignComponents/ExperimentalLayout/ForecastAdjustmentsTypeProvider';
import { Box } from '@mui/system';
import { AdjustmentModal } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/AdjustmentModal';
import { useAsyncFunction } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAsyncFunction';
import * as serverProxy from './RefactoredAdjustmentModals/serverProxy';
import useAdjustmentPolling from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAdjustmentPolling';
import { NoDataPlaceHolderTableRow } from 'src/components/BeaconRedesignComponents/common/NoDataPlaceHolder/NoDataPlaceHolder';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';

const ForecastRecommendationsProductTable = ({
  rowData,
  tableTitle,
  isLoading,
  handleRowClick
}: {
  rowData: ForecastsAdjustmentData[];
  tableTitle: string;
  isLoading: boolean;
  handleRowClick: (rowData: ForecastsAdjustmentData) => void;
}) => {
  const COL_DEFS = useForecastRecommendationsColumnDefinitions();
  const preExportData = useGenerateRecommendationCSVData(rowData);
  const formattedExportData = rowData ? preExportData : [];

  // pageSize should be changed when user clicks "View more", so we can hide "View more" row when expanded.
  return (
    <>
      <EntityTableHeader
        prefixTitle={tableTitle}
        title={tableTitle}
        paddingBottom="20.5px"
        enabledActions={['ExportCsv']}
        handleCSVExport={() => handleCsvDownload(formattedExportData, 'Recommendations.csv')}
        // We don't want to show export button when data is not available
        rowData={rowData || []}
      />
      <EntityTable
        onRowClick={handleRowClick}
        isLoading={isLoading}
        rowData={rowData || []}
        columnDefs={COL_DEFS}
        shouldModifyColumnDefs={false}
        supportViewMore
        initialPageSize={10}
        shouldPaginateViewMore
        appTableProps={{
          noDataPlaceholder: (
            <NoDataPlaceHolderTableRow
              title="No recommendations found"
              description="There are currently no recommendations to display. All your recommendations will be available on this page."
            />
          )
        }}
      />
    </>
  );
};

const ForecastsRecommendationsPage = () => {
  const retailer = useAppSelector((state) => state.retailer);

  const { setIsPolling } = useAdjustmentPolling();

  const { modelVersion, loading: isForecastModelVersionLoading, currentPublishVersion } = useLatestForecastModel();

  const [adjustmentDataForModal, setAdjustmentDataForModal] = useState<ForecastsAdjustmentData>(null);
  const [adjustmentModalOpen, setAdjustmentModalOpen] = useState(false);

  const { wrappedFunction: createAdjustment } = useAsyncFunction(serverProxy.createAdjustment);

  const handleRowClick = (rowData: ForecastsAdjustmentData) => {
    // Discard the adjustment ID to avoid incorrect results from BE data when an adjustment ID is provided
    const rowDataWithoutAdjustmentId = { ...rowData, adjustmentId: '' };
    setAdjustmentDataForModal(rowDataWithoutAdjustmentId);
    setAdjustmentModalOpen(true);
  };

  // Table data states
  // TODO: Enable these when recommendations for them are ready
  //   const [organicRowData, setOrganicRowData] = useState<ForecastsAdjustmentData[]>([]);
  //   const [otherRowData, setOtherRowData] = useState<ForecastsAdjustmentData[]>([]);
  const [paidRowData, setPaidRowData] = useState<ForecastsAdjustmentData[]>([]);
  const [retailPriceRowData, setRetailPriceRowData] = useState<ForecastsAdjustmentData[]>([]);
  const [contentScoreRowData, setContentScoreRowData] = useState<ForecastsAdjustmentData[]>([]);
  const [inStockRateRowData, setInStockRateRowData] = useState<ForecastsAdjustmentData[]>([]);
  const [ratingsAndReviewsRowData, setRatingsAndReviewsRowData] = useState<ForecastsAdjustmentData[]>([]);
  const [buyBoxRowData, setBuyBoxRowData] = useState<ForecastsAdjustmentData[]>([]);
  const [loading, setLoading] = useState(true);

  const selectedPlanType = useForecastPlanType();
  const applyEntityFilters = useApplyEntityFilters();

  /**
   * This request body has a query for each plan type embedded in it.
   * If we didn't specify a plan type with a page size, we might easily hit our page size limit with just the results from 1 plan type.
   * We create a query for each plan type so that we can get N number of results of each plan type.
   */
  const forecastAdjustmentsAdvancedSearchRequest = useMemo(() => {
    return [
      AdjustmentPlanType.BuyBox,
      AdjustmentPlanType.ContentScore,
      AdjustmentPlanType.InstockRate,
      AdjustmentPlanType.OrganicTraffic,
      AdjustmentPlanType.OtherTraffic,
      AdjustmentPlanType.PaidTraffic,
      AdjustmentPlanType.RatingsReviews,
      AdjustmentPlanType.RetailPrice
    ].map((planType) => {
      const forecastAdjustmentBuilder = new AdvancedSearchRequestBuilder(
        `forecastRecommendation${planType}`,
        'beacon-recommendations-product'
      );

      forecastAdjustmentBuilder
        .setPageSize(200)
        .setDoAggregation(false)
        .setReturnDocuments(true)
        .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
        .addConditionTermFilter('retailerId', 'should', [retailer.id])
        .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
        .addConditionTermFilter('planType', 'must', [planType])
        // Filter Recommendations where individual net impact is at least 1
        .addConditionRangeFilter('individualImpact', '1')
        .setSearchBy('child')
        .addSortFieldWithOnlyFieldName('adjustmentChangeValue', 'desc')
        .apply(applyEntityFilters);

      return forecastAdjustmentBuilder.build();
    });
  }, [applyEntityFilters, currentPublishVersion, modelVersion]);

  const { data: adjustmentPageTableData, isLoading: adjustmentDataLoading } = useGenericAdvancedSearch({
    requestId: 'recommendedAdjustments',
    staleTime: 0,
    shouldPerformFetch: !isForecastModelVersionLoading,
    requestBody: forecastAdjustmentsAdvancedSearchRequest,
    queryKeys: [ADJUSTMENT_TABLE_QUERY, forecastAdjustmentsAdvancedSearchRequest]
  });

  const parseRecommendationResponseForTable = () => {
    try {
      if (adjustmentPageTableData) {
        const recommendationsData = parseForecastsRecommendationsResponse({
          adjustmentPageTableData
        });
        const {
          paidTrafficData,
          retailPriceData,
          contentScoreData,
          inStockRateData,
          ratingsAndReviewsData,
          buyBoxData
        } = recommendationsData;

        setPaidRowData(paidTrafficData);
        setRetailPriceRowData(retailPriceData);
        setContentScoreRowData(contentScoreData);
        setInStockRateRowData(inStockRateData);
        setRatingsAndReviewsRowData(ratingsAndReviewsData);
        setBuyBoxRowData(buyBoxData);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching recommendations');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (adjustmentPageTableData) {
      parseRecommendationResponseForTable();
    }
  }, [adjustmentPageTableData]);

  const planTypesComponents = useMemo(() => {
    const allTables = [
      // Disable Organic and Other Traffic tables for now
      // TODO: Add Organic and Other Traffic tables here when available
      //  Paid Traffic
      <SlColumn spacing="sm" widths="full" key={AdjustmentPlanType.PaidTraffic}>
        <ForecastRecommendationsProductTable
          handleRowClick={handleRowClick}
          isLoading={loading}
          rowData={paidRowData}
          tableTitle={PlanType.PaidTraffic}
        />
      </SlColumn>,
      // Retail Price
      <SlColumn spacing="sm" widths="full" key={AdjustmentPlanType.RetailPrice}>
        <ForecastRecommendationsProductTable
          handleRowClick={handleRowClick}
          isLoading={loading}
          rowData={retailPriceRowData}
          tableTitle={PlanType.RetailPrice}
        />
      </SlColumn>,
      // Content Score
      <SlColumn spacing="sm" widths="full" key={AdjustmentPlanType.ContentScore}>
        <ForecastRecommendationsProductTable
          handleRowClick={handleRowClick}
          isLoading={loading}
          rowData={contentScoreRowData}
          tableTitle={PlanType.ContentScore}
        />
      </SlColumn>,
      // In-Stock Rate
      <SlColumn spacing="sm" widths="full" key={AdjustmentPlanType.InstockRate}>
        <ForecastRecommendationsProductTable
          handleRowClick={handleRowClick}
          isLoading={loading}
          rowData={inStockRateRowData}
          tableTitle={PlanType.InstockRate}
        />
      </SlColumn>,
      // Ratings & Reviews
      <SlColumn spacing="sm" widths="full" key={AdjustmentPlanType.RatingsReviews}>
        <ForecastRecommendationsProductTable
          handleRowClick={handleRowClick}
          isLoading={loading}
          rowData={ratingsAndReviewsRowData}
          tableTitle={PlanType.RatingsReviews}
        />
      </SlColumn>,
      //  Buy Box
      <SlColumn spacing="sm" widths="full" key={AdjustmentPlanType.BuyBox}>
        <ForecastRecommendationsProductTable
          handleRowClick={handleRowClick}
          isLoading={loading}
          rowData={buyBoxRowData}
          tableTitle={PlanType.Buybox}
        />
      </SlColumn>
    ];

    // Only render tables that actually have rowData available for Recommendations after we have received a response
    if (!adjustmentDataLoading) {
      const availableTables = allTables.filter((table) => {
        const {
          props: { children }
        } = table;
        const { props: childProps } = children;
        const { rowData } = childProps;
        return rowData && rowData.length > 0;
      });

      // If we don't have any recommendations we should render all tables (with their no data states)
      if (availableTables.length === 0) {
        return allTables;
      }

      return availableTables;
    } else {
      return allTables;
    }
  }, [
    adjustmentDataLoading,
    buyBoxRowData,
    contentScoreRowData,
    inStockRateRowData,
    paidRowData,
    ratingsAndReviewsRowData,
    retailPriceRowData
  ]);

  const filteredComponents =
    selectedPlanType === 'AllPlanTypes'
      ? planTypesComponents
      : planTypesComponents.filter((component) => component.key === selectedPlanType);

  return (
    <ForecastAdjustmentsTypeProvider>
      <BeaconPageContainer>
        <Flex flexDirection="column" gap="md">
          <SlRow horizontalPosition="space-between">
            <SlColumn spacing="sm">
              <CommonSummaryInfoSubtitle title="Recommendations" />
            </SlColumn>
            <CreateAdjustmentButton />
          </SlRow>
          <SlColumn spacing="md">
            <Text variant="body1">
              The sections below summarize the recommended actions and adjustments that will increase sales performance.
            </Text>
          </SlColumn>
          {filteredComponents.map((component) => component)}
          {adjustmentDataForModal ? (
            <Box position="absolute">
              <AdjustmentModal
                isRecommendation
                sendAdjustmentUpdate={createAdjustment}
                stacklineSku={adjustmentDataForModal.stacklineSku}
                retailerSku={adjustmentDataForModal.retailerSku}
                categoryId={adjustmentDataForModal.product.categoryId}
                subcategoryId={adjustmentDataForModal.product.subCategoryId}
                product={adjustmentDataForModal.product}
                adjustmentData={adjustmentDataForModal}
                open={adjustmentModalOpen}
                onClose={() => setAdjustmentModalOpen(false)}
                setIsPolling={setIsPolling}
              />
            </Box>
          ) : null}
        </Flex>
      </BeaconPageContainer>
    </ForecastAdjustmentsTypeProvider>
  );
};

export default ForecastsRecommendationsPage;
