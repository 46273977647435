import { SlRow, Text, useStacklineTheme } from '@stackline/ui';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { Divider, FormControlLabel } from '@mui/material';
import { CloseIcon } from 'src/components/SvgIcons/SvgIcons';
import { useHistory, useLocation } from 'src/utils/Hooks';
import StyledCheckbox from 'src/components/BeaconRedesignComponents/common/StyledCheckbox';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

const ForecastStyledDialogue = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '330px',
    height: '400px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px'
  }
});

const FILTER_MODAL_CONTENT_HEIGHT = 280;

export const adjustmentStatusOptions = [
  { label: 'Published', id: 'Published' },
  { label: 'Draft', id: 'Draft' },
  { label: 'Archived', id: 'Archived' }
];

const ForecastFilterInner = ({ handleClose }: { handleClose: () => void }) => {
  const theme = useStacklineTheme();
  const history = useHistory();
  const location = useLocation();
  const filterParamsFromUrl = new URLSearchParams(location.search);
  const [selection, setSelection] = useState(new Set(filterParamsFromUrl.getAll('adjustmentStatus'))); // Prefill our filter selection if possible

  const handleSelection = (e: SyntheticEvent<Element, Event>) => {
    const { value } = e.target as HTMLInputElement;
    const updatedSelection = new Set(selection);
    if (updatedSelection.has(value)) {
      updatedSelection.delete(value);
    } else {
      updatedSelection.add(value);
    }
    setSelection(updatedSelection);
  };

  const handleClearSelection = () => {
    setSelection(new Set());
  };

  const handleApplyFilters = () => {
    const filterParams = Array.from(selection.values()).map((type: string) => ({ adjustmentStatus: type }));
    const params = new URLSearchParams(window.location.search);
    params.delete('adjustmentStatus'); // Clear current filter
    filterParams.forEach((filter) => {
      params.append('adjustmentStatus', filter.adjustmentStatus);
    });
    history.push(`${window.location.pathname}?${params.toString()}`);
    handleClose();
  };

  return (
    <>
      {/* Header */}
      <SlRow horizontalInset="md" verticalInset="md" verticalPosition="center" horizontalPosition="center">
        <Text variant="subtitle1">Filter</Text>
        <div style={{ position: 'absolute', top: '18px', right: '18px' }}>
          <CloseIcon
            onClick={handleClose}
            style={{ cursor: 'pointer', width: theme.spacing.md, height: theme.spacing.md }}
          />
        </div>
      </SlRow>
      <Divider sx={{ borderColor: theme.colors.primaryGray }} />
      {/* Body */}

      <Flex margin={`${theme.spacing.md} 0 ${theme.spacing.sm} ${theme.spacing.md}`}>
        <Text variant="subtitle2">Adjustment Status</Text>
      </Flex>
      <Flex marginLeft={theme.spacing.md} flexDirection="column" height={`${FILTER_MODAL_CONTENT_HEIGHT}px`}>
        {adjustmentStatusOptions.map((option) => {
          return (
            <Flex key={option.id} marginTop={theme.spacing.md}>
              <FormControlLabel
                title={option.label}
                sx={{
                  color: theme.colors.primary,
                  minWidth: '160px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginLeft: '0px',
                  marginRight: '0px',
                  '& .MuiTypography-root': {
                    width: '100px',
                    paddingLeft: '4px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }
                }}
                checked={selection ? selection.has(option.id) : false}
                label={<Text variant="body2">{option.label}</Text>}
                value={option.id}
                control={<StyledCheckbox disableRipple disableTouchRipple />}
                key={option.id}
                onChange={(e: SyntheticEvent<Element, Event>) => handleSelection(e)}
              />
            </Flex>
          );
        })}
      </Flex>
      {/* Footer */}
      <Flex padding={theme.spacing.mdl} justifyContent="space-between">
        <SlButton onClick={handleClearSelection}>Clear</SlButton>
        <SlButton onClick={handleApplyFilters} variant="contained">
          Apply
        </SlButton>
      </Flex>
    </>
  );
};

const ForecastFilterModal = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
  return (
    <ForecastStyledDialogue open={open} onClose={handleClose}>
      <ForecastFilterInner handleClose={handleClose} />
    </ForecastStyledDialogue>
  );
};

export const ForecastFilterButton = () => {
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const location = useLocation();
  const filterParams = new URLSearchParams(location.search);

  /**
   * Checks url for an adjustmentStatus filter
   */
  const filterActive: boolean = useMemo(() => {
    return filterParams.has('adjustmentStatus');
  }, [filterParams]);

  const handleClose = () => {
    setFilterModalOpen(false);
  };

  return (
    <>
      <SlButton active={filterActive} onClick={() => setFilterModalOpen(true)}>
        <SlRow spacing={filterActive ? 'sm' : 'none'} verticalPosition="center">
          Filter
        </SlRow>
      </SlButton>
      <ForecastFilterModal open={filterModalOpen} handleClose={handleClose} />
    </>
  );
};
