/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import StandardDropdown from 'src/components/common/DropDown/StandardDropdown';
import MenuItem from '@mui/material/MenuItem';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import { Option } from 'funfix-core';
import { AdEntityAdPlatformSettingByClient } from 'sl-ad-campaign-manager-data-model';
// import SuccessScreen from 'src/components/AdManager/SubmitScreen/SuccessScreen';

import { store } from 'src/main';
import Select from 'src/components/common/Form/Select';
import Placeholder from 'src/components/common/Placeholder/Placeholder';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { adPlatformOperations } from 'src/store/modules/adManager/adPlatforms';
import { adPlatformSettingsByClientOperations } from 'src/store/modules/adManager/adPlatformSettingsByClient';
import { ChevronIcon } from 'src/components/SvgIcons';
import {
  getPlatformType,
  sortedAdEntitiesWithCampaignCountSelector
} from 'src/store/modules/adManager/adCampaignBuilder/selectors';
import '../AdCampaignBuilder.scss';
import { SectionWrapper, OperationButtons } from '../Widgets/AdCampaignBuilderCommonWidgets';
import InfoTooltip from '../Widgets/InfoTooltip';
import ReduxStore from 'src/types/store/reduxStore';
import FormControl from '@mui/material/FormControl';
import { checkNotEmpty } from 'src/utils/checkEmpty';

const muiStyles = {
  root: {
    margin: 0,
    '&:before': {
      content: 'none'
    },
    maxWidth: 500,
    width: 500,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  select: {
    margin: 0,
    fontWeight: 400,
    width: 500,
    height: 30,
    maxWidth: 500
  }
};

const mapStateToProps = (state: ReduxStore) => {
  const platformType = getPlatformType(state);

  return {
    ..._pick(state, ['app', 'retailer', 'adCampaignBuilder', 'adPlatforms', 'adPlatformSettingsByClient']),
    platformType,
    sortedAdEntitiesWithCampaignCounts: Option.of(platformType)
      .map(() => sortedAdEntitiesWithCampaignCountSelector(state))
      .getOrElse([])
  };
};

const Platform: React.FC<{ classes: { [key: string]: any } } & ReturnType<typeof mapStateToProps>> = ({
  adCampaignBuilder: { platformId, platformSettings, inited },
  platformType,
  classes,
  adPlatforms,
  retailer,
  app,
  adPlatformSettingsByClient,
  sortedAdEntitiesWithCampaignCounts
}) => {
  const isFetching = useRef(false);

  useEffect(() => {
    if (!inited) {
      store.dispatch(adCampaignBuilderActions.initAdBuilder());
    }

    if (isFetching.current) {
      return;
    }

    const promises = [];
    if (_isEmpty(adPlatforms)) {
      isFetching.current = true;
      promises.push(store.dispatch(adPlatformOperations.fetchAdPlatforms(retailer.id)));
    }
    if (_isEmpty(adPlatformSettingsByClient)) {
      isFetching.current = true;
      promises.push(store.dispatch(adPlatformSettingsByClientOperations.fetchAdPlatformSettingsByClient()));
    }

    if (promises.length > 0) {
      Promise.all(promises).then(() => {
        isFetching.current = false;
      });
    }
  });

  const changePlatform = (value: string) => {
    store.dispatch(adCampaignBuilderActions.setPlatform(value as string));

    // If the platform has changed, de-select the selected entity as well since they will no longer match
    if (value !== platformId) {
      store.dispatch(adCampaignBuilderActions.setPlatformSettings({ entity: null }));
    }
  };

  const handlePlatformChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    changePlatform(event.target.value);
  };

  const changeEntity = (value: string) => {
    const id = value;
    const newEntity = adPlatformSettingsByClient.find(
      (adPlatformSetting) =>
        adPlatformSetting.settingType === 'entityId' &&
        adPlatformSetting.platformType === platformType &&
        adPlatformSetting.id === id
    ) as AdEntityAdPlatformSettingByClient;
    store.dispatch(adCampaignBuilderActions.setPlatformSettings({ entity: newEntity || null }));
  };

  const handleEntityChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    changeEntity(event.target.value);
  };

  const canContinue = useMemo(
    () =>
      Option.of(platformId)
        .flatMap(() => Option.of(_get(platformSettings, 'entity')))
        .map((entity) => entity.id !== '' && entity.platformType === platformId)
        .getOrElse(false),
    [platformId, platformSettings]
  );

  useEffect(() => {
    changePlatform(retailer.displayName);
  }, []);

  if (_isEmpty(adPlatforms) || _isEmpty(adPlatformSettingsByClient)) {
    return null;
  }

  const suportEmail = _get(app, ['config', 'domainConfig', 'customerSupport', 'email'], 'support@stackline.com');
  return (
    <div className="ad-manager-container">
      <OperationButtons subtab="platformSelect" canContinue={canContinue} />

      <SectionWrapper header="Platform" subheader="Select the platform for this campaign." layer={0}>
        {/* <SuccessScreen /> */}

        <div className="platform-select">
          <form>
            <StandardDropdown
              variant="standard"
              value={platformId || null}
              onChange={handlePlatformChange}
              classes={{ root: classes.root, select: classes.select }}
              IconComponent={() => (
                <ChevronIcon
                  style={{
                    height: 28,
                    width: 28,
                    right: 0,
                    color: 'rgba(0,0,0,.54)',
                    position: 'absolute',
                    pointerEvents: 'none',
                    stroke: '#052849'
                  }}
                />
              )}
              displayEmpty
              // eslint-disable-next-line react/display-name
              renderValue={checkNotEmpty(platformId) ? undefined : () => <Placeholder text="Platform Name" />}
            >
              {adPlatforms
                .filter((item) => `${item.extendedAttributes.retailerId}` === retailer.id)
                .map((item) => (
                  <MenuItem key={item.id} value={item.id} disabled={item.extendedAttributes.status === 'disabled'}>
                    {item.name}
                  </MenuItem>
                ))}
            </StandardDropdown>
            <FormControl variant="standard">
              <InfoTooltip interactive>
                <span className="info">
                  Have questions about integrating more platforms? Contact{' '}
                  <a href={`mailto:${suportEmail}`} style={{ color: '#fff' }}>
                    {suportEmail}
                  </a>{' '}
                  to get started.
                </span>
              </InfoTooltip>
            </FormControl>
          </form>
        </div>
      </SectionWrapper>

      <SectionWrapper header="Entity" subheader="Select the entity for this campaign." layer={1}>
        <div className="platform-select">
          <form>
            <Select
              items={sortedAdEntitiesWithCampaignCounts}
              value={platformSettings && platformSettings.entity ? platformSettings.entity.id : null}
              onChange={handleEntityChange}
              classes={{ root: classes.root, select: classes.select }}
              IconComponent={() => (
                <ChevronIcon
                  style={{
                    height: 28,
                    width: 28,
                    right: 0,
                    color: 'rgba(0,0,0,.54)',
                    position: 'absolute',
                    pointerEvents: 'none',
                    stroke: '#052849'
                  }}
                />
              )}
              placeholder="Entity Name"
              disabled={_isEmpty(platformId)}
            />
            <FormControl>
              <InfoTooltip interactive>
                <span className="info">
                  Have questions about adding more Entities to your account? Contact{' '}
                  <a href={`mailto:${suportEmail}`} style={{ color: '#fff' }}>
                    {suportEmail}
                  </a>{' '}
                  to get started.
                </span>
              </InfoTooltip>
            </FormControl>
          </form>
        </div>
      </SectionWrapper>
    </div>
  );
};

const EnhancedPlatform = connect(mapStateToProps)(withStyles(muiStyles)(Platform));

export default EnhancedPlatform;
