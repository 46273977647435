import React from 'react';
import SmartKeyMetricCard from './SmartKeyMetricCard';
import { UseMetricByWeekIdArgs } from 'src/serverProxy/useMetricByWeekId';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

export interface KeyMetricCardDefinition extends UseMetricByWeekIdArgs {
  onClick?: () => void;
  title?: string;
}

export interface KeyMetricCardsProps {
  cardDefinitionsByColumn: KeyMetricCardDefinition[][];
}

/**
 * Displays columns of key metric summary cards
 */
const KeyMetricCards = ({ cardDefinitionsByColumn }: KeyMetricCardsProps) => {
  return (
    <Flex gap="mdl">
      {cardDefinitionsByColumn.map((cardDefinitions) => (
        <Flex flexDirection="column" key={`column-${cardDefinitions[0].fieldName}`} gap="md">
          {cardDefinitions.map((cardDefinition, index) => (
            <SmartKeyMetricCard
              variant={index === 0 ? 'large' : 'small'}
              key={cardDefinition.indexName}
              {...cardDefinition}
            />
          ))}
        </Flex>
      ))}
    </Flex>
  );
};

export default KeyMetricCards;
