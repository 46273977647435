import { Option } from 'funfix-core';
import { filterNils } from 'src/utils/fp';
import MultiMetricTrendChart from 'src/components/EntityPage/TrendChart/MultiMetricTrendChart';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import ReduxStore from 'src/types/store/reduxStore';
import { MetricField } from 'src/types/application/types';
import { Widget } from 'src/types/application/widgetTypes';
import {
  buildMultiGroupByTopEntitiesChartWidgetConfig,
  buildTrendWidgetConfig
} from 'src/components/Layout/LayoutUtil';
import { panic } from 'src/utils/mixpanel';

const allowHorizontalScroll = {
  horizontalScrolling: {
    enabled: true,
    step: 3
  },
  xAxis: [{ min: 0, max: 9 }],
  enableSwitchingGroupBy: true
};

const BEACON_CHARGEBACKS_METRIC_FIELD_NAMES = [
  'financialCharge',
  'prepCharges',
  'logisticCharges',
  'labelingCharges',
  'chargebackRate',
  'stacklineSku',
  'issueId'
];

const chargebackMetricDisplayNamesByFieldName: { [key: string]: string } = {
  stacklineSku: 'Products with Charges',
  issueId: 'Count of Charges'
};

const getChargebackGroupByMapping = (appName: string, indexName: string, metricType: string) => {
  const allGroupByFieldNames = [
    'issueType',
    'stacklineSku',
    'categoryId',
    'subCategoryId',
    'brandId',
    'issueId',
    'subTypeOfTheNonCompliance',
    'status',
    'vendorCode',
    'purchaseOrder'
  ];
  const allGroupByFieldsForIndex: {
    groupByFieldName: string;
    aggregationFieldNames: string[];
    indexName: string;
  }[] = [];
  allGroupByFieldNames.forEach((groupByFieldName) => {
    if (INDEX_FIELDS.hasField(appName, indexName, groupByFieldName)) {
      allGroupByFieldsForIndex.push({
        groupByFieldName,
        aggregationFieldNames: [metricType],
        indexName
      });
    }
  });
  return allGroupByFieldsForIndex;
};

const getGroupByFieldConfigByEntityType = ({
  app,
  indexName,
  metricType
}: {
  app: ReduxStore['app'];
  indexName: string;
  metricType: string;
}) => {
  const allGroupByFieldsForIndex = getChargebackGroupByMapping(app.name, indexName, metricType);

  const groupByFieldConfigsByEntityType: {
    [key: string]: {
      groupByFieldName: string;
      aggregationFieldNames: string[];
      indexName: string;
    }[];
  } = {
    client: allGroupByFieldsForIndex,
    company: allGroupByFieldsForIndex,
    category: allGroupByFieldsForIndex.filter((x) => x.groupByFieldName !== 'categoryId'),
    subcategory: allGroupByFieldsForIndex.filter(
      (x) => x.groupByFieldName !== 'categoryId' && x.groupByFieldName !== 'subCategoryId'
    ),
    brand: allGroupByFieldsForIndex.filter((x) => x.groupByFieldName !== 'brandIds'),
    product: allGroupByFieldsForIndex.filter(
      (x) =>
        x.groupByFieldName !== 'stacklineSku' &&
        x.groupByFieldName !== 'brandIds' &&
        x.groupByFieldName !== 'categoryId' &&
        x.groupByFieldName !== 'subCategoryId'
    ),
    segment: allGroupByFieldsForIndex,
    businessunit: allGroupByFieldsForIndex
  };

  return groupByFieldConfigsByEntityType;
};

export const buildChargeBacksMultiMetricTrendChart = ({
  indexName,
  entity,
  groupByFieldName = 'weekId',
  app,
  weekIdField,
  showOnlyMainSeries
}: {
  indexName: string;
  entity: { type: string };
  groupByFieldName?: string;
  app: ReduxStore['app'];
  weekIdField: MetricField;
  showOnlyMainSeries: boolean;
}) => {
  const metricFieldNames = filterNils([...BEACON_CHARGEBACKS_METRIC_FIELD_NAMES]);
  const name = 'multiMetricTrendChart';
  const widget = buildTrendWidgetConfig(app, indexName, entity, groupByFieldName, metricFieldNames, weekIdField, {
    CustomComponent: MultiMetricTrendChart,
    name,
    view: {
      name,
      chartPropsOverride: {
        title: { text: '' }
      },
      showOnlyMainSeries,
      hideSubtitle: true
    }
  });

  widget.view.metricFields = widget.view.metricFields.map((metricField: MetricField) => ({
    ...metricField,
    displayName: chargebackMetricDisplayNamesByFieldName[metricField.name!] || metricField.displayName
  }));

  return widget;
};

export const buildChargebackTopEntitiesChart = ({
  indexName,
  entity,
  app,
  weekIdField
}: {
  indexName: string;
  entity: { type: string };
  app: ReduxStore['app'];
  weekIdField: MetricField;
}): Widget => {
  const groupByFieldConfigsByEntityType = getGroupByFieldConfigByEntityType({
    app,
    indexName,
    metricType: 'financialCharge'
  });

  const groupByFieldConfigsForCurrentEntity = Option.of(groupByFieldConfigsByEntityType[entity.type]).getOrElseL(() => {
    return panic(`No group-by-field configs found for current entity type: "${entity.type}"`);
  });

  const aggregationFieldNames = BEACON_CHARGEBACKS_METRIC_FIELD_NAMES;
  const topEntitiesMetricFields = aggregationFieldNames.map((fieldName) => {
    const field = INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type);
    return {
      ...field,
      displayName: chargebackMetricDisplayNamesByFieldName[fieldName] || field.displayName
    };
  });

  return buildMultiGroupByTopEntitiesChartWidgetConfig(
    app,
    indexName,
    entity,
    groupByFieldConfigsForCurrentEntity.map((config) => ({
      ...config,
      aggregationFieldNames
    })),
    10,
    topEntitiesMetricFields,
    weekIdField,
    {
      view: {
        chartPropsOverride: { enableSwitchingGroupBy: true, ...allowHorizontalScroll },
        anchorName: 'multiGroupByTopEntitiesChart'
      }
    }
  );
};
