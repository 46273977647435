import { CircularProgress, CircularProgressProps, circularProgressClasses } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import mergeSx from 'src/utils/mergeSx';

const textStyle = {
  fontFamily: 'Roboto',
  fontSize: '32px',
  fontWeight: 'bold',
  color: '#052849'
};

/**
 *
 * This component is used for circular loading state with a label. -- can be found in bulk adjustment in Beacon
 */
const CircularProgressWithLabel = ({
  percentage,
  label,
  circleSx,
  circleProps = {},
  backgroundCircleProps = {},
  customLabel
}: {
  percentage: number;
  label?: string;
  circleSx?: SxProps;
  circleProps?: CircularProgressProps;
  backgroundCircleSx?: SxProps;
  backgroundCircleProps?: CircularProgressProps;
  customLabel?: React.ReactNode;
}) => {
  const formatMetric = useMetricFormatter();

  const customCircleStyle = {
    thickness: 2
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {/* This component is used for background of the circle, so it always has to be 100% */}
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#F5F9FD',
          position: 'absolute'
        }}
        size="248px"
        value={100}
        {...backgroundCircleProps}
      />
      <CircularProgress
        size="238px"
        disableShrink
        variant="determinate"
        thickness={customCircleStyle.thickness}
        sx={mergeSx(
          {
            circle: {
              transition: 'stroke-dashoffset 1100ms cubic-bezier(0.455, 0.030, 0.515, 0.955)'
            },
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round'
            }
          },
          circleSx
        )}
        value={percentage}
        {...circleProps}
      />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        {customLabel || (
          <>
            <Flex height="43px">
              <span style={textStyle}>{`${formatMetric(percentage, METRICTYPE.VOLUME, {
                showFullValue: true
              })}%`}</span>
            </Flex>
            <Flex height="24px" marginTop="4px">
              <Text variant="h5">{label}</Text>
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
