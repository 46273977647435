/**
 * Utility functions for taking the response from AdvancedSearch for
 * the Advertising Overlap visualization and converting it into a format
 * that can be displayed in a Highcharts Venn diagram.
 */

import { AdvertisingOverlapData, AdvertisingOverlapDataEntry, PointOptionsObjectWithMetric } from './types';
import _sum from 'lodash/sum';
import _cloneDeep from 'lodash/cloneDeep';
import ReduxStore from 'src/types/store/reduxStore';
import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { AdvertisingOverlapMetric } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import { MetricFormatterFn } from 'src/utils/Hooks';

export type AdType = 'dsp' | 'sponsored_products' | 'sponsored_display' | 'sponsored_brands' | 'dsp+sd';

/**
 * Takes a serialized string representing a set of ad type overlaps
 * and converts it into an array
 * @param overlaps Will be something like '[dsp, sponsored_products]'
 */
export const convertOverlapsStringToArray = (overlaps: string): AdType[] => {
  // Strip off brackets, split on comma, and sort
  return overlaps.slice(1, -1).split(', ').sort() as AdType[];
};

/**
 * Takes an array of ad types and serializes it into a string
 * like '[dsp, sponsored_products]'
 * The ad types will be alphabetized and comma separated.
 */
export const convertOverlapsArrayToString = (overlaps: AdType[]): string => {
  return `[${overlaps.sort().join(', ')}]`;
};

/**
 * Label for outer sections of Venn diagram
 * where there isn't an intersection
 */
export const createOuterLabel = (label: string, value: string): string => {
  // Move sponsored brands and sponsored products labels down so they are
  // more contained within the circle
  return `
    <span class="venn-label-container ${label.startsWith('Sponsored') ? 'venn-label-sponsored' : ''}">
      <span class="venn-label-outer">${label}</span>
      <span class="venn-label-outer-value">${value}</span>
    </span>
  `.trim();
};

/**
 * Label for sections of the Venn diagram
 * where two categories intersect
 */
export const createInnerLabel = (value: string): string => {
  return `<span class="venn-label-inner">${value}</span>`;
};

/**
 * For a given section of a Venn diagram, return the color and
 * formatted label
 * @param value The value to display in the Venn diagram intersection
 * @returns
 */
export const getOverlapSectionMetadata = (overlaps: AdType[], value: string): { color: string; name: string } => {
  return {
    [convertOverlapsArrayToString(['dsp+sd'])]: {
      color: 'rgb(173,206,251)',
      name: createOuterLabel('DSP + Sponsored Display', value)
    },
    [convertOverlapsArrayToString(['sponsored_brands'])]: {
      color: 'rgb(111, 180, 139)',
      name: createOuterLabel('Sponsored Brands', value)
    },
    [convertOverlapsArrayToString(['sponsored_products'])]: {
      color: 'rgb(245, 197, 97)',
      name: createOuterLabel('Sponsored Products', value)
    },
    [convertOverlapsArrayToString(['dsp+sd', 'sponsored_products'])]: {
      color: 'rgb(163,158,86)',
      name: createInnerLabel(value)
    },
    [convertOverlapsArrayToString(['dsp+sd', 'sponsored_brands'])]: {
      color: 'rgb(82,147,136)',
      name: createInnerLabel(value)
    },
    [convertOverlapsArrayToString(['sponsored_products', 'sponsored_brands'])]: {
      color: 'rgb(99,139,56)',
      name: createInnerLabel(value)
    },
    [convertOverlapsArrayToString(['dsp+sd', 'sponsored_brands', 'sponsored_products'])]: {
      color: 'rgb(70,112,49)',
      name: createInnerLabel(value)
    }
  }[convertOverlapsArrayToString(overlaps)];
};

/**
 * Given an overlap entry and metric, returns a formatted string value that will appear in
 * the Venn diagram
 * @param data All data for advertising overlap. Used to compute percenet of total purchases
 * and unique reach
 * @param entry Entry for which to get formatted value
 * @param metric Metric that has been selected
 * @returns A formatted string value for how the metric should appear in the Venn diagram
 */
export const getFormattedVennValue = (
  data: AdvertisingOverlapData,
  entry: AdvertisingOverlapDataEntry,
  metric: AdvertisingOverlapMetric,
  retailer: ReduxStore['retailer']
): { value: number; displayValue: string } => {
  let value: number;

  const orders = +entry.orders;
  const uniqueShoppersReached = +entry.uniqueShoppersReached;

  switch (metric) {
    case AdvertisingOverlapMetric.ConversionRate:
      value = uniqueShoppersReached > 0 ? orders / uniqueShoppersReached : 0;
      break;
    case AdvertisingOverlapMetric.PercentOfConversions: {
      const sumOfPurchases = _sum(Object.values(data).map(({ orders: totalPurchases }) => +totalPurchases || 0));
      value = sumOfPurchases > 0 ? orders / sumOfPurchases : 0;
      break;
    }
    case AdvertisingOverlapMetric.Conversions:
      value = orders;
      break;
    case AdvertisingOverlapMetric.UniqueShoppers:
      value = uniqueShoppersReached;
      break;
    case AdvertisingOverlapMetric.PercentOfUniqueShoppers: {
      const sumOfUniqueReach = _sum(
        Object.values(data).map(({ uniqueShoppersReached: uniqueReach }) => +uniqueReach || 0)
      );
      value = sumOfUniqueReach > 0 ? uniqueShoppersReached / sumOfUniqueReach : 0;
      break;
    }
    default:
      return { value: 0, displayValue: '' };
  }

  // Display as percentage value
  if (
    [
      AdvertisingOverlapMetric.ConversionRate,
      AdvertisingOverlapMetric.PercentOfConversions,
      AdvertisingOverlapMetric.PercentOfUniqueShoppers
    ].includes(metric)
  ) {
    return {
      value,
      displayValue: `${convertMetricToDisplayValue(retailer, value, METRICTYPE.PERCENT, retailer.currencySymbol, true, {
        decimalPlaces: 2
      })}`
    };
  }
  // Display as decimal value
  return {
    value,
    displayValue: `${convertMetricToDisplayValue(retailer, value, METRICTYPE.VOLUME, retailer.currencySymbol, false, {
      decimalPlaces: 2
    })}`
  };
};

/**
 * DSP and SD should be grouped together in the chart, but they will
 * be returned separately from the AdvancedSearch response. This
 * groups them together so they can be displayed properly in the Venn
 * diagram.
 */
export const groupDSPAndSponsoredDisplay = (data: AdvertisingOverlapData): AdvertisingOverlapData => {
  const result = Object.entries(data).reduce((outputObj, [key, value]) => {
    // Sometimes Amazon returns an empty exposure group so filter them out
    if (key === '') {
      return outputObj;
    }
    let newKey = key;
    const keyAsArray = convertOverlapsStringToArray(key);
    if (keyAsArray.includes('dsp') || keyAsArray.includes('sponsored_display')) {
      newKey = convertOverlapsArrayToString([
        ...keyAsArray.filter((adType) => adType !== 'dsp' && adType !== 'sponsored_display'),
        'dsp+sd'
      ]);
    }

    if (outputObj[newKey]) {
      // If the key already exists, add the values to the existing object
      outputObj[newKey].uniqueShoppersReached =
        (+outputObj[newKey].uniqueShoppersReached || 0) + (+value.uniqueShoppersReached || 0);
      outputObj[newKey].orders = (outputObj[newKey].orders || 0) + (value.orders || 0);
      outputObj[newKey].adSales = (outputObj[newKey].adSales || 0) + (value.adSales || 0);
    } else {
      // If the key doesn't exist, create a new object with the values
      outputObj[newKey] = { ...value };
    }

    return outputObj;
  }, {} as AdvertisingOverlapData);

  return result;
};

interface AdvancedSearchDocument extends AdvertisingOverlapDataEntry {
  exposureGroup: string;
}

/**
 * Parses the response data of an advanced search request and returns an object with the documents grouped by exposure group.
 * @param data The response data containing an array of documents.
 * @returns An object where the keys are the exposure groups and the values are the documents belonging to those groups.
 */
export const advancedSearchResponseParser = (data: { documents: AdvancedSearchDocument[] }): AdvertisingOverlapData => {
  return data.documents.reduce((prev, value) => {
    return {
      ...prev,
      [value.exposureGroup]: value
    };
  }, {} as AdvertisingOverlapData);
};

/**
 * Take an array of strings and return all unique combinations
 */
const getCombinations = (arr: string[]): string[][] => {
  return arr
    .reduce(
      (combinations: string[][], str: string) => {
        const newCombinations = combinations.map((c) => [...c, str]);
        return [...combinations, ...newCombinations];
      },
      [[]]
    )
    .slice(1); // remove first empty array we started with
};

/**
 * Takes advertising overlap data from AdvancedSearch and
 * converts it into a Highcharts point array for display in
 * a Venn diagram
 * @param data Raw response from backend. It is expected that the data will be
 * correctly formatted.
 */
export const convertAdOverlapDataToVennData = (
  data: AdvertisingOverlapData,
  metric: AdvertisingOverlapMetric,
  retailer: ReduxStore['retailer']
): PointOptionsObjectWithMetric[] => {
  const result = Object.entries(groupDSPAndSponsoredDisplay(data))
    .sort(([keyA], [keyB]) => {
      // Must be in order of SB, SP, DSP so that they appear in the right
      // orer for the Venn diagram
      const orderedAdTypes = ['[sponsored_brands]', '[sponsored_products]', '[dsp+sd]'];
      const aIndex = orderedAdTypes.indexOf(keyA);
      const bIndex = orderedAdTypes.indexOf(keyB);
      return aIndex === -1 && bIndex === -1 ? 0 : aIndex === -1 ? 1 : bIndex === -1 ? -1 : aIndex - bIndex;
    })
    .map(([overlaps, entry]) => {
      const overlapsArray = convertOverlapsStringToArray(overlaps);
      const { displayValue, value } = getFormattedVennValue(data, entry, metric, retailer);
      const { color, name } = getOverlapSectionMetadata(overlapsArray, displayValue);
      const options: PointOptionsObjectWithMetric = {
        sets: overlapsArray,
        value: overlapsArray.length === 1 ? 3 : 1, // Size of overlap section. Make outer sections 3 times bigger than inner
        color,
        dataLabels: {
          style: {
            fontFamily: 'Roboto'
          }
        },
        name,
        metricValue: +value
      };
      return options;
    });

  // Not all overlaps will be returned from backend if there is no data for a certain overlap.
  // Add in empty data for the missing overlaps otherwise the diagram will not look right.
  const adTypes: AdType[] = ['dsp+sd', 'sponsored_brands', 'sponsored_products'];
  const adTypeCombinations = getCombinations(adTypes);
  const missingAdTypeCombos = adTypeCombinations.filter(
    (adTypeCombo) =>
      !result.some(
        (val) =>
          convertOverlapsArrayToString(val.sets as AdType[]) === convertOverlapsArrayToString(adTypeCombo as AdType[])
      )
  );
  missingAdTypeCombos.forEach((adCombo) => {
    const isPercentage = [
      AdvertisingOverlapMetric.ConversionRate,
      AdvertisingOverlapMetric.PercentOfConversions,
      AdvertisingOverlapMetric.PercentOfUniqueShoppers
    ].includes(metric);
    const displayValue = `${convertMetricToDisplayValue(
      retailer,
      0,
      isPercentage ? METRICTYPE.PERCENT : METRICTYPE.VOLUME,
      retailer.currencySymbol,
      true,
      isPercentage ? { decimalPlaces: 2 } : undefined
    )}`;
    const { color, name } = getOverlapSectionMetadata(adCombo as AdType[], displayValue);
    result.push({
      sets: adCombo,
      metricValue: 0,
      value: adCombo.length === 1 ? 3 : 1,
      name,
      color,
      dataLabels: {
        style: {
          fontFamily: 'Roboto'
        }
      }
    });
  });
  return result;
};

/**
 * Take a response from AdvancedSearch and return an array of 3 numbers.
 * The first number will be data for 1 ad type, the second for 2 ad types,
 * and the third for 3 ad types.
 * @param data Raw response data from AdvancedSearch
 */
export const convertAdOverlapDataToBarData = (
  data: AdvertisingOverlapData,
  metric: AdvertisingOverlapMetric
): number[] => {
  const groupedData = groupDSPAndSponsoredDisplay(data);
  const sumOfAllPurchases = _sum(Object.values(groupedData).map(({ orders: totalPurchases }) => +totalPurchases || 0));
  const sumOfAllUniqueReach = _sum(
    Object.values(groupedData).map(({ uniqueShoppersReached: uniqueReach }) => +uniqueReach || 0)
  );

  return [1, 2, 3].reduce((acc, numAds) => {
    const adData: AdvertisingOverlapDataEntry[] = Object.entries(groupedData)
      .filter(([key]) => convertOverlapsStringToArray(key).length === numAds)
      .map(([_key, value]) => value);

    const sumOfUniqueReach = _sum(adData.map(({ uniqueShoppersReached: uniqueReach }) => +uniqueReach || 0));
    const sumOfPurchases = _sum(adData.map(({ orders: totalPurchases }) => +totalPurchases || 0));
    const sumOfOrders = _sum(adData.map(({ orders }) => +orders || 0));

    switch (metric) {
      case AdvertisingOverlapMetric.ConversionRate: {
        return [...acc, sumOfUniqueReach > 0 ? sumOfOrders / sumOfUniqueReach : 0];
      }
      case AdvertisingOverlapMetric.Conversions: {
        return [...acc, sumOfPurchases];
      }
      case AdvertisingOverlapMetric.PercentOfConversions: {
        return [...acc, sumOfAllPurchases > 0 ? sumOfPurchases / sumOfAllPurchases : 0];
      }
      case AdvertisingOverlapMetric.UniqueShoppers: {
        return [...acc, sumOfUniqueReach];
      }
      case AdvertisingOverlapMetric.PercentOfUniqueShoppers: {
        return [...acc, sumOfAllUniqueReach > 0 ? sumOfUniqueReach / sumOfAllUniqueReach : 0];
      }
      default:
        return acc;
    }
  }, [] as number[]);
};

const AD_TYPE_TO_LABEL_MAP = {
  'dsp+sd': 'DSP + Sponsored Display',
  sponsored_brands: 'Sponsored Brands',
  sponsored_products: 'Sponsored Products'
};

const OVERLAP_METRIC_TO_LABEL_MAP = {
  [AdvertisingOverlapMetric.ConversionRate]: 'Shopper Conversion Rate',
  [AdvertisingOverlapMetric.Conversions]: 'Conversions',
  [AdvertisingOverlapMetric.PercentOfConversions]: '% of Conversions',
  [AdvertisingOverlapMetric.PercentOfUniqueShoppers]: '% of Unique Shoppers',
  [AdvertisingOverlapMetric.UniqueShoppers]: 'Unique Shoppers'
};

/**
 * Returns a label for a Venn diagram based on the given set of ad types.
 * @param adTypes An array of ad types to generate the label from.
 * @returns A label for the Venn diagram based on the given ad types.
 */
export const getLabelFromVennSets = (adTypes: AdType[]): string => {
  return adTypes.map((adType) => AD_TYPE_TO_LABEL_MAP[adType]).join(' + ');
};

interface AdOverlapToCsvArgs {
  vennData: PointOptionsObjectWithMetric[];
  barData: number[];
  dateRange: string; // For example, "Feb 1, 2023 - Feb 28, 2023"
  metric: AdvertisingOverlapMetric;
}
export const convertAdOverlapDataToCsv = ({ vennData, barData, dateRange, metric }: AdOverlapToCsvArgs): string => {
  const vennHeaders: string = ['Exposure Group', `${OVERLAP_METRIC_TO_LABEL_MAP[metric]}: ${dateRange}`]
    .map((val) => `"${val}"`)
    .join(',');

  const vennValues = _cloneDeep(vennData)
    .sort((a, b) => b.metricValue - a.metricValue)
    .map(({ sets, metricValue }) => {
      const exposureGroup = sets.map((adType: AdType) => AD_TYPE_TO_LABEL_MAP[adType]);
      return [exposureGroup.join(', '), metricValue].map((val) => `"${val}"`).join(',');
    });

  const barHeaders: string = ['Number of Ad Types Exposed', `${OVERLAP_METRIC_TO_LABEL_MAP[metric]}: ${dateRange}`]
    .map((val) => `"${val}"`)
    .join(',');

  const barValues = _cloneDeep(barData).map((value, index) => [index + 1, value].map((val) => `"${val}"`).join(','));

  return `"Venn Diagram data"\n${vennHeaders}\n${vennValues.join(
    '\n'
  )}\n\n\nBar Chart data\n${barHeaders}\n${barValues.join('\n')}`;
};

/**
 * Get visualization insight text for conversions
 */
export const getConversionRateInsight = (conversionRates: number[], formatMetric: MetricFormatterFn) => {
  // Avoid division by 0
  if (conversionRates.length === 0 || conversionRates.some((value) => value === 0)) {
    return '';
  }

  const threeCompareTwo = conversionRates[2] / conversionRates[1];
  const threeCompareOne = conversionRates[2] / conversionRates[0];

  // If users exposed to more ad types are less likely to buy,
  // express it as a percentage
  const threeCompareTwoDisplay =
    threeCompareTwo < 1
      ? `${formatMetric(1 - threeCompareTwo, METRICTYPE.PERCENT, { decimalPlaces: 2 })} less`
      : `${formatMetric(threeCompareTwo, METRICTYPE.DECIMAL, {
          decimalPlaces: 2
        })} times more`;

  const threeCompareOneDisplay =
    threeCompareOne < 1
      ? `${formatMetric(1 - threeCompareOne, METRICTYPE.PERCENT, { decimalPlaces: 2 })} less`
      : `${formatMetric(threeCompareOne, METRICTYPE.DECIMAL, {
          decimalPlaces: 2
        })} times more`;

  return `When shoppers were exposed to all 3 ad groupings, they were ${threeCompareTwoDisplay} likely to convert compared to shoppers exposed to 2 ad types and ${threeCompareOneDisplay} likely to convert compared to shoppers exposed to 1 ad type.`;
};

/**
 * Get the insights text for Unique Shoppers or % of Unique Shoppers
 */
export const getShoppersInsight = (
  vennData: Pick<PointOptionsObjectWithMetric, 'metricValue' | 'sets'>[],
  formatMetric: MetricFormatterFn
): string => {
  const totalShoppers = _sum(vennData.map(({ metricValue }) => +metricValue || 0));

  if (totalShoppers === 0 || vennData.length < 1) {
    return '';
  }

  // Sort in descending order and get the set with highest metric
  const { metricValue, sets } = _cloneDeep(vennData).sort((a, b) => b.metricValue - a.metricValue)[0];
  const labelsForSets = sets.map((set) => AD_TYPE_TO_LABEL_MAP[set]);

  const highestSetsLabel =
    labelsForSets.length > 1
      ? `${labelsForSets.slice(0, -1).join(', ')} and ${labelsForSets[sets.length - 1]}`
      : labelsForSets[0];
  const highestPercentOfUniqueReach = formatMetric(metricValue / totalShoppers, METRICTYPE.PERCENT, {
    decimalPlaces: 2
  });

  return `All 3 ad groupings reached ${formatMetric(totalShoppers, METRICTYPE.VOLUME, {
    showFullValue: false,
    decimalPlaces: 2
  })} unique shoppers. ${highestSetsLabel} generated ${highestPercentOfUniqueReach} of unique reach.`;
};

/**
 * Get the insight text for the conversions or percent of conversions metrics
 */
export const getConversionsInsight = (
  vennData: Pick<PointOptionsObjectWithMetric, 'metricValue' | 'sets'>[],
  formatMetric: MetricFormatterFn
): string => {
  const totalConversions = formatMetric(_sum(vennData.map(({ metricValue }) => metricValue)), METRICTYPE.VOLUME);

  // Sort in descending order and get the set with highest metric
  const { metricValue, sets } = _cloneDeep(vennData).sort((a, b) => b.metricValue - a.metricValue)[0];
  const labelsForSets = sets.map((set) => AD_TYPE_TO_LABEL_MAP[set]);

  const highestSetsLabel =
    labelsForSets.length > 1
      ? `The combination of ${labelsForSets.slice(0, -1).join(', ')} and ${labelsForSets[labelsForSets.length - 1]}`
      : labelsForSets[0];

  return `${highestSetsLabel} generated the most conversions; ${formatMetric(
    metricValue,
    METRICTYPE.VOLUME
  )} out of a total of ${totalConversions}.`;
};
