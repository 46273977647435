import colors from 'src/utils/colors';
import types from './types';
import { Segment, SavedSearch, BusinessUnit } from 'sl-api-connector/types';

const segmentColors = [
  '#468AF6',
  '#FF963C',
  '#0E427E',
  colors.purple,
  colors.darkBlue,
  '#27BCB4',
  colors.lightGrey,
  colors.red
];

const receiveAllSavedSearch = (
  mySegments: Segment[],
  teamSegments: Segment[],
  mySearchTermLists: SavedSearch[],
  teamSearchTermLists: SavedSearch[],
  businessUnits: BusinessUnit[]
) => ({
  type: types.RECEIVE_ALL_SAVED_SEARCH,
  mySegments,
  teamSegments,
  mySearchTermLists,
  teamSearchTermLists,
  businessUnits
});

const clearAllSavedSearch = () => ({ type: types.CLEAR_ALL_SAVED_SEARCH });

export default {
  clearAllSavedSearch,
  receiveAllSavedSearch,
  colors: segmentColors
};
