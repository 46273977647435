import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import _get from 'lodash/get';
import _identity from 'lodash/identity';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _merge from 'lodash/merge';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import queryString from 'qs';
import axios from 'axios';

import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { parseReviewsMetrics } from 'src/store/modules/entitySearchService/selectors';
import { getLastWeek, getFirstWeekOfYear } from 'src/utils/dateformatting';
import { getLayoutForEntity } from 'src/components/Layout/EntityPageLayout';
import ReviewMetricsContainerContent from './ReviewMetricsContainerContent';
import '../ReviewMetrics.scss';
import {
  buildDefaultConditions,
  buildEntityConditions,
  buildMainEntityConditions
} from '../../Renderer/EntityPageRenderer';

/**
 * Constructs the base of the API request used to fetch the review trend chart's data
 *
 * @param {object} params Params used to build the default request
 */
const getDefaultReviewTrendChartRequestOverides = ({
  comparisonTimePeriod,
  clonedPropConditions,
  reviewStars,
  retailer,
  filteredKeywords
}) => {
  const defaultOverrides = {
    pageNumber: 1,
    pageSize: 52 * 2,
    period: comparisonTimePeriod.id.split('-')[1],
    doAggregation: true,
    additionalFieldsToReturn: [],
    conditions: {
      termFilters: [...clonedPropConditions.termFilters],
      rangeFilters: []
    },
    aggregations: [
      {
        aggregationFields: [],
        conditions: {
          termFilters: [
            {
              fieldName: 'stars',
              values: reviewStars
            },
            {
              fieldName: 'retailerId',
              values: [`${retailer.id}`]
            }
          ],
          rangeFilters: []
        },
        pageSize: 200,
        pageNumber: 1
      }
    ],
    sortFilter: {
      sortFields: [
        {
          fieldName: 'timestamp'
        },
        {
          fieldName: 'stars'
        }
      ]
    }
  };

  if (filteredKeywords[0] !== '' && filteredKeywords.length > 0) {
    defaultOverrides.aggregations[0].conditions.termFilters.push({
      fieldName: 'reviewText',
      queryType: 'match',
      values: filteredKeywords
    });
  } else {
    defaultOverrides.aggregations[0].conditions.termFilters.filter((item) => item.filedName !== 'reviewText');
  }

  return defaultOverrides;
};

const { CancelToken } = axios;

// Refactor this to remove all hard coding of values and put into layout config/ entity definitions
class ReviewsContainer extends Component {
  static defaultProps = {};

  static propTypes = {
    allWeekIdsByRetailerId: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    conditions: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired,
    entitySearchService: PropTypes.object.isRequired,
    fetchEntityMetrics: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    mainEntityConditions: PropTypes.object.isRequired,
    eventBus: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    const { location } = props;
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const { subtab: querySubtab, searchKeyword } = queryParams;

    this.state = {
      searchingValue: searchKeyword,
      reviewStars:
        querySubtab === 'reviewTrends'
          ? ['1', '2', '3', '4', '5']
          : _get(this.getHighRiskReviews(), 'stars') === null
          ? ['1', '2']
          : _get(this.getHighRiskReviews(), 'stars', ['1', '2']),
      filteredKeywords: [searchKeyword || ''],
      highRiskReviews: this.getHighRiskReviews(),
      selectedHighRiskWeekId: null
    };
  }

  componentDidMount() {
    this.cancelSource = CancelToken.source();
    this.debounce = null;
    const { location, eventBus } = this.props;
    const { highRiskReviews } = this.state;
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const { subtab: querySubtab } = queryParams;
    if (querySubtab === 'reviewTrends' || querySubtab === 'rating') {
      this.fetchReviewTrendChartData(this.props);
    }
    if (querySubtab === 'highRiskReviews' && !_isEmpty(_get(highRiskReviews, 'keywords'))) {
      this.handleHighRiskTabChange(highRiskReviews);
    }

    eventBus.on('filterReviewKeyword', this.handleFilterReviewKeyword);
  }

  componentWillReceiveProps(nextProps) {
    // Update reviewStars with a location change
    const { location, mainEntityConditions } = nextProps;
    const { highRiskReviews } = this.state;
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const { subtab: querySubtab } = queryParams;
    if (!_isEqual(this.props.mainEntityConditions, mainEntityConditions) || !_isEqual(location, this.props.location)) {
      if (location.search.includes('highRisk')) {
        if (querySubtab === 'highRiskReviews' && !_isEmpty(_get(highRiskReviews, 'keywords'))) {
          this.handleHighRiskTabChange(highRiskReviews);
        } else {
          this.setState({ reviewStars: ['1', '2'] }, () => this.fetchReviewTrendChartData(this.props));
        }
      }
      if (location.search.includes('reviewTrends')) {
        if (typeof this.cancelSource !== typeof undefined) {
          this.cancelSource.cancel('Cancel network request');
        }
        this.cancelSource = CancelToken.source();
        this.setState({ reviewStars: ['1', '2', '3', '4', '5'] }, () => this.fetchReviewTrendChartData(nextProps));
      }
    }
  }

  componentWillUnmount() {
    if (typeof this.cancelSource !== typeof undefined) {
      this.cancelSource.cancel('Cancel network request');
    }

    this.props.eventBus.off('filterReviewKeyword', this.handleFilterReviewKeyword);
  }

  handleHighRiskTabChange(highRiskReviews) {
    this.setState(
      {
        reviewStars: _get(highRiskReviews, 'stars') === null ? ['1', '2'] : _get(highRiskReviews, 'stars', ['1', '2'])
      },
      () => this.fetchHighRiskChartData(this.props)
    );
  }

  getHighRiskReviews = () => {
    const { user, retailer } = this.props;
    return _get(user.config.clientAccountSettings, ['value', retailer.id, 'highRiskReviews']);
  };

  fetchHighRiskChartData = (props) => {
    const {
      app,
      comparisonTimePeriod,
      entityService,
      fetchEntityMetrics,
      mainTimePeriod,
      retailer,
      mainEntityConditions
    } = props;
    const { mainEntity } = entityService;
    const clonedPropConditions = _cloneDeep(mainEntityConditions);
    const { highRiskReviews, reviewStars } = this.state;
    const { keywords } = highRiskReviews;

    const highRiskChartOverrides = {
      pageNumber: 1,
      pageSize: 200,
      period: comparisonTimePeriod.id.split('-')[1],
      doAggregation: true,
      returnDocuments: false,
      additionalFieldsToReturn: [],
      conditions: {
        termFilters: [
          { fieldName: 'stars', condition: 'should', values: reviewStars },
          {
            fieldName: 'reviewText',
            condition: 'should',
            values: keywords
          },
          ...clonedPropConditions.termFilters
        ],
        rangeFilters: [
          {
            fieldName: 'weekId',
            minValue: mainTimePeriod.startWeek,
            maxValue: mainTimePeriod.endWeek
          }
        ]
      },
      searchBy: 'child',
      aggregations: [
        {
          groupByFieldName: 'weekId',
          aggregationFields: [{ aggregateByFieldName: 'stars', function: 'stats' }],
          pageSize: 200,
          pageNumber: 1,
          sortDirection: null,
          sortByAggregationField: null
        }
      ],
      sortFilter: { sortFields: [{ fieldName: 'timestamp' }, { fieldName: 'stars' }] }
    };

    fetchEntityMetrics(
      'highRiskReviewsChartMetrics',
      { entity: mainEntity, retailer, app, indexName: 'reviews' },
      [highRiskChartOverrides],
      _get(this.cancelSource, 'token')
    );
  };

  fetchReviewTrendChartData = (props, renderDonut = true) => {
    const {
      allWeekIdsByRetailerId,
      app,
      comparisonTimePeriod,
      entityService,
      fetchEntityMetrics,
      mainTimePeriod,
      retailer,
      mainEntityConditions
    } = props;
    const { mainEntity } = entityService;
    const weekIds = allWeekIdsByRetailerId[retailer.id];
    const clonedPropConditions = _cloneDeep(mainEntityConditions);
    const { filteredKeywords, reviewStars } = this.state;
    const defaultOverrides = getDefaultReviewTrendChartRequestOverides({
      comparisonTimePeriod,
      clonedPropConditions,
      reviewStars,
      retailer,
      filteredKeywords
    });

    const startWeek = Math.max(
      Math.min(comparisonTimePeriod.startWeek, getFirstWeekOfYear(mainTimePeriod.startWeek - 100)),
      weekIds[0]
    );
    const endWeek = getLastWeek(weekIds);
    const mainChartRequestOverrides = _merge({}, defaultOverrides, {
      pageSize: weekIds.length,
      aggregations: [
        {
          groupByFieldName: 'weekId',
          aggregationFields: [
            {
              aggregateByFieldName: 'stars',
              function: 'stats'
            }
          ],
          conditions: {
            rangeFilters: [
              {
                fieldName: 'weekId',
                minValue: startWeek,
                maxValue: endWeek
              }
            ]
          }
        }
      ]
    });

    const donutRequestOverrides = _merge({}, defaultOverrides, {
      aggregations: [
        {
          groupByFieldName: 'stars',
          aggregationFields: [
            {
              aggregateByFieldName: 'stars',
              function: 'sum'
            }
          ],
          conditions: {
            rangeFilters: [
              {
                fieldName: 'weekId',
                minValue: mainTimePeriod.startWeek,
                maxValue: mainTimePeriod.endWeek
              }
            ]
          }
        }
      ]
    });

    const promises = [
      renderDonut &&
        fetchEntityMetrics(
          'reviewsDonutChartMetrics',
          { entity: mainEntity, retailer, app, indexName: 'reviews', customResponseParser: parseReviewsMetrics },
          [donutRequestOverrides],
          this.cancelSource.token
        ),
      fetchEntityMetrics(
        'mainChartMetrics',
        { entity: mainEntity, retailer, app, indexName: 'reviews' },
        [mainChartRequestOverrides],
        _get(this.cancelSource, 'token')
      )
    ].filter(_identity);

    Promise.all(promises)
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (!err.message.includes('Canceled network request')) {
          console.error(err);
        }
      });
  };

  // This function is passed to the Donut chart to handle the change of the stars
  handleStarValuesChange = (values) => {
    const reviewStars = ['1', '2', '3', '4', '5'];
    if (values.length !== 0) {
      this.setState({ reviewStars: values.map((val) => val.fieldId) }, () =>
        this.fetchReviewTrendChartData(this.props, false)
      );
    } else if (values.length === 0) {
      this.setState({ reviewStars }, () => this.fetchReviewTrendChartData(this.props, false));
    }
  };

  handleFilterReviewKeyword = (value) => {
    this.setState({ filteredKeywords: [value] }, () => this.fetchReviewTrendChartData(this.props));
  };

  handleWeekIdChange = (e) => {
    const { selectedHighRiskWeekId } = this.state;
    const { fieldValue } = e[0].options;
    let updatedWeekId = null;
    if (fieldValue !== selectedHighRiskWeekId) {
      updatedWeekId = fieldValue;
    }
    this.setState({ selectedHighRiskWeekId: updatedWeekId });
  };

  render() {
    const { app, entityService, location, mainEntityConditions, retailer, user } = this.props;
    const { selectedHighRiskWeekId, ...state } = this.state;
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const { tab: queryTab, subtab: querySubtab } = queryParams;
    const { mainEntity } = entityService;
    const pageLayout = getLayoutForEntity({
      app,
      retailer,
      user,
      tab: queryTab,
      metricType: querySubtab,
      entity: mainEntity,
      pageType: 'entityPage'
    });

    return (
      <div className="review-metrics__container" style={{ ...(pageLayout.containerStyle || {}), flex: 1 }}>
        {pageLayout.widgets.map((widget, i) => (
          <ReviewMetricsContainerContent
            isLoading={this.state.isLoading}
            key={i}
            widget={widget}
            pageLayout={pageLayout}
            handleStarValuesChange={this.handleStarValuesChange}
            selectedHighRiskWeekId={selectedHighRiskWeekId}
            handleWeekIdChange={this.handleWeekIdChange}
            mainEntityConditions={mainEntityConditions}
            querySubtab={querySubtab}
            {...state}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state, { conditions }) => {
  const queryParams = queryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });

  const defaultConditions = buildDefaultConditions(conditions, queryParams);
  const entityConditions = state.entityService.mainEntity
    ? buildEntityConditions(state.app, state.categories, defaultConditions, state.entityService.mainEntity)
    : null;
  const mainEntityConditions = entityConditions
    ? buildMainEntityConditions(
        entityConditions,
        state.entityService.mainEntity,
        state.app,
        state.retailer,
        queryParams
      )
    : null;

  return {
    ..._pick(state, [
      'allWeekIdsByRetailerId',
      'app',
      'comparisonTimePeriod',
      'entityService',
      'entitySearchService',
      'mainTimePeriod',
      'filters',
      'retailer',
      'user'
    ]),
    mainEntityConditions
  };
};

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

const enhance = compose(withBus('eventBus'), withRouter, connect(mapStateToProps, mapDispatchToProps));

const EnhancedReviewsContainer = enhance(ReviewsContainer);

export default EnhancedReviewsContainer;
