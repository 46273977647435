import React, { useState } from 'react';
import { getBrandImageUrl, getRetailerLogoUrl, getCountryImageUrl } from 'src/utils/image';
import { BrandIcon } from 'src/components/SvgIcons';
import { OmniTileEntity as omniTileEntityType } from 'src/types/application/types';
import colors from 'src/utils/colors';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import _get from 'lodash/get';

interface OmniTileHeaderProps {
  omniTileEntity: omniTileEntityType;
  customStyle: any;
}

const buildSummaryUrl = (app: ReduxStore['app'], omniTileEntity: omniTileEntityType, filterParams: string): string => {
  const { id, selectedEntityName } = omniTileEntity;
  const { searchParams } = app.queryParams;
  return `/${selectedEntityName}${id ? `/${id}${searchParams}&tab=scorecard&subtab=all${filterParams}` : ''}`;
};

const OmniTileHeader: React.FC<OmniTileHeaderProps> = ({ omniTileEntity, customStyle }: OmniTileHeaderProps) => {
  const [logoAvailable, setLogoAvailable] = useState(true);
  const app = useSelector((state: ReduxStore) => state.app);

  const { filterParams } = app.queryParams;
  const isCategory = omniTileEntity.selectedEntityName === 'category';
  const isSubCategory = omniTileEntity.selectedEntityName === 'subcategory';
  const shortName = omniTileEntity.name ? omniTileEntity.name.slice(0, 2) : '';
  const { value, totalValue, percentValue } = omniTileEntity;
  const omniCountriesFollowing = useSelector((state: ReduxStore) => state.omniCountriesFollowing);
  const omniFilter = useSelector((state: ReduxStore) => state.filters);
  const { region: omniRegionsFiltered, country: omniCountriesFiltered } = omniFilter;
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const omniDynamicFilter = useSelector((state: ReduxStore) => state.omniDynamicFilters);

  const omniImageSelector = (selectedEntityName, omniEntityId) => {
    switch (selectedEntityName) {
      case 'retailer':
        return getRetailerLogoUrl(omniEntityId);
      case 'country':
        return getCountryImageUrl(omniEntityId);
      default:
        return getBrandImageUrl(omniEntityId);
    }
  };
  const omniCountriesRenderer = () => {
    // in the filter, we store only countryCode.

    let countryList = [];

    if (omniCountriesFiltered && omniCountriesFiltered.length > 0) {
      countryList = omniCountriesFollowing.reduce((acc, currCountry) => {
        if (omniCountriesFiltered.includes(currCountry.countryCode)) {
          acc.push(currCountry.countryName);
        }
        return acc;
      }, []);
    } else {
      // if they filter none if country, we have to render all countries
      countryList = omniCountriesFollowing.map((country) => country.countryName);
    }

    if (omniRegionsFiltered && omniRegionsFiltered.length > 0) {
      // if a client only filter regions, we will need to get available countries
      const omniDynamicCountries = _get(omniDynamicFilter, 'data.countries', []);
      countryList = omniDynamicCountries.map((country) => country.countryName);
    }

    const sortedCountryList = countryList.sort();

    return sortedCountryList.join(', ');
  };

  const tileHeaderContentRenderer = (selectedEntityName: string) => {
    const { availableRetailers } = retailer;
    const targetRetailer = availableRetailers.find((r) => r.id === omniTileEntity.id);

    switch (selectedEntityName) {
      case 'country':
        return null;

      case 'retailer':
        return targetRetailer && targetRetailer.countryDisplayName ? (
          <div className="sub_header">
            <div className="main_box" style={{ width: 330 }}>
              <strong>Country:</strong>
              <span>&nbsp;{targetRetailer.countryDisplayName}</span>
            </div>
          </div>
        ) : null;

      default:
        return (
          <div className="sub_header">
            <div className="main_box" style={{ width: 330 }}>
              <strong>Country:</strong>
              <span>&nbsp;{omniCountriesRenderer()}</span>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="adManager_header_section" style={customStyle}>
      {isCategory || isSubCategory ? (
        <div className="segment__short-name-container" style={{ backgroundColor: colors.darkBlue }}>
          <div className="segment__short-name">{shortName}</div>
        </div>
      ) : logoAvailable ? (
        <img
          style={{
            maxWidth: omniTileEntity.selectedEntityName === 'country' ? 160 : 120,
            maxHeight: 120
          }}
          alt="logo"
          src={omniImageSelector(omniTileEntity.selectedEntityName, omniTileEntity.id)}
          onError={() => {
            setLogoAvailable(false);
          }}
        />
      ) : (
        <BrandIcon
          style={{
            stroke: colors.lightGrey,
            strokeWidth: '7px',
            display: 'inline-block',
            verticalAlign: 'middle',
            maxWidth: 120,
            maxHeight: 120
          }}
        />
      )}
      <div className="header_name image_header_name" style={{ marginLeft: 30, justifyContent: 'start' }}>
        <div className="main_header">
          <Link to={buildSummaryUrl(app, omniTileEntity, filterParams)}>
            <span className="header_text">{omniTileEntity.name}</span>
          </Link>
        </div>
        <div>
          <div className="sub_header">
            <div className="main_box" style={{ width: 430 }}>
              <strong>Store Availability:</strong>
              <span>
                {' '}
                {`${numeral(value).format('0,0') || 0} out of ${`${numeral(totalValue).format('0,0')}` || 0} stores ${
                  percentValue || 0.0
                }%`}
              </span>
            </div>
          </div>
          {tileHeaderContentRenderer(omniTileEntity.selectedEntityName)}
        </div>
      </div>
    </div>
  );
};

export default OmniTileHeader;
