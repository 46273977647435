import { AdFrequencyDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';

/**
 * Different metrics that can be returned from
 * ad frequency ES query
 */
export enum AdFrequencyMetric {
  Impressions = 'impressions',
  DetailPageView = 'detailPageView',
  Purchases = 'purchases'
}

export interface AdFrequencyMetrics {
  barChartMetrics: number[];
  lineChartMetrics: number[];
  totalMetricRate: number;
}

/**
 * For each key, the first index (0) represents data for users exposed
 * to one ad, the second is for users exposed to two ads,
 * etc.
 */
export type AdFrequencyResponse = {
  [key in AdFrequencyDropdownOption]: AdFrequencyMetrics;
};

export interface AdFrequencyAdvancedSearchResponse {
  aggregations: {
    'by_frequencyBucket.keyword': {
      fieldId: string;
      additionalValues: {
        detailPageView_sum_value: number;
        impressions_sum_value: number;
        orders_sum_value: number;
        usersInBucket_sum_value: number;
      };
    }[];
  };
}

export enum AdFrequencyBarMetric {
  Impressions = 'Impressions',
  DetailPageViews = 'Detail Page Views',
  Conversions = 'Conversions'
}

export enum AdFrequencyLineMetric {
  DPVR = 'DPVR',
  ConversionRate = 'Shopper Conversion Rate'
}
