/* eslint-disable react/prop-types */
import React, { useMemo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import ReactDOMServer from 'react-dom/server.browser';
import moment from 'moment';
import _last from 'lodash/last';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';
import numeral from 'numeral';
import { Option } from 'funfix-core';

import colors from 'src/utils/colors';
import { StackedColumnChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import StackedColumnLegend from './StackedColumnLegend';
import { getDayIdFromDate } from 'src/utils/dateformatting';
import ReduxStore from 'src/types/store/reduxStore';
import { WidgetProps } from 'src/types/application/widgetTypes';
import { warn } from 'src/utils/mixpanel';
import { prop } from 'src/utils/fp';
import { getTotalMonthlyBudgetForCurrentEntity } from 'src/components/AdManager/budgetCalculation';
import { useInView } from 'react-intersection-observer';
import { fetchDailySpendAndProjections } from './utils';

const StackedColumnTooltip: React.FC<{ datas: any[]; time: any; dailyBudgets: [number, number][] }> = ({
  datas,
  time,
  dailyBudgets
}) => {
  const date = moment(time).format('MMM Do');
  const tooltipStyle = {
    container: { color: colors.darkBlue, fontSize: 12 },
    title: { fontSize: 16 }
  };

  return (
    <div style={tooltipStyle.container} className="hichart-tooltip">
      <div style={tooltipStyle.title}>{date}</div>
      {datas.map((data, idx) => {
        if (!data.point.percentage || _isEmpty(data.series.name)) {
          return null;
        }

        const { amount, percent }: { amount: number; percent: number } = (() => {
          const dailyBudgetForTheDay = dailyBudgets[data.point.index][1];
          if (['actual spend', 'projected spend'].includes(data.series.name.toLowerCase())) {
            const overpacingAmount = Option.of(datas.find((datum) => datum.series.name.toLowerCase() === 'overpacing'))
              .map((datum) => datum.point.y)
              .getOrElse(null);

            if (overpacingAmount !== null) {
              return {
                percent: (data.point.y + overpacingAmount) / dailyBudgetForTheDay,
                amount: data.point.y + overpacingAmount
              };
            }
          }

          return { percent: data.point.y / dailyBudgetForTheDay, amount: data.point.y };
        })();
        const displayValue = ` ${numeral(amount).format('$1,000')}`;
        const color = data.series.color === '#EAECF1' ? colors.labelGrey : data.series.color;

        return (
          <div key={idx} style={{ marginTop: 5 }}>
            <span style={{ color, fontSize: 16 }}>{displayValue}</span>{' '}
            <span style={{ color, textTransform: 'uppercase' }}>{data.series.name}</span>
            {` (${numeral(percent).format('100%')})`}
            <br />
          </div>
        );
      })}
    </div>
  );
};

export const computeDataSet = (
  metricsByDayId: Map<number, { actual?: number; projected?: number; budgetAmount: number }>
) => {
  const startDate = moment().startOf('month');
  const endDate = moment().endOf('month');

  const metrics = {
    dailyBudget: [] as [number, number][],
    surplusBudget: [] as [number, number][],
    overspend: [] as [number, number][],
    padding: [] as [number, number][],
    actualSpend: [] as [number, number][],
    projectedSpend: [] as [number, number][],
    totalCampaignBudgetSum: 0 as number
  };
  let curDate = startDate.clone();
  let totalCampaignBudgetSum = 0;
  while (curDate.isBefore(endDate)) {
    const dayId = getDayIdFromDate(curDate.toDate());
    const timestamp = +curDate.format('x');

    // Get the spend for the week ID in question and then multiply by the daily multiplier
    let metricsForDay = metricsByDayId.get(dayId);
    if (!metricsForDay || (_isNil(metricsForDay.actual) && _isNil(metricsForDay.projected))) {
      warn(`Missing both daily spend metrics + projections for day id ${dayId}`);
      metricsForDay = { projected: 0, budgetAmount: 0 };
    }
    const { actual: actualSpend, projected: projectedSpend, budgetAmount: dailyBudget } = metricsForDay;
    metrics.dailyBudget.push([timestamp, dailyBudget]);
    totalCampaignBudgetSum += dailyBudget;
    const adSpendForDay = (metricsForDay.projected ? projectedSpend : actualSpend) || 0;
    if (metricsForDay.projected) {
      // Use projected spend metrics if we have no actual data and the dayId isn't in the past.
      metrics.projectedSpend.push([timestamp, adSpendForDay]);
      metrics.actualSpend.push([timestamp, 0]);
    } else {
      // Use actual spend metrics if we have them
      metrics.actualSpend.push([timestamp, adSpendForDay]);
      metrics.projectedSpend.push([timestamp, 0]);
    }

    const surplusBudget = dailyBudget - adSpendForDay;
    if (surplusBudget >= 0) {
      metrics.surplusBudget.push([timestamp, surplusBudget]);
      metrics.overspend.push([timestamp, 0]);
    } else {
      metrics.surplusBudget.push([timestamp, 0]);
      metrics.overspend.push([timestamp, -surplusBudget]);

      if (metricsForDay.projected) {
        _last(metrics.projectedSpend)![1] += surplusBudget;
      } else {
        _last(metrics.actualSpend)![1] += surplusBudget;
      }
    }

    curDate = curDate.add(1, 'day');
  }

  const maxValue = metrics.actualSpend.reduce(
    (acc, [_ts, actualSpend], i) =>
      Math.max(acc, actualSpend + metrics.projectedSpend[i][1] + metrics.overspend[i][1] + metrics.surplusBudget[i][1]),
    0
  );
  metrics.actualSpend.forEach((_, i) => {
    const timestamp = metrics.actualSpend[i][0];
    const padding =
      maxValue -
      (metrics.actualSpend[i][1] +
        metrics.projectedSpend[i][1] +
        metrics.overspend[i][1] +
        metrics.surplusBudget[i][1]);

    metrics.padding.push([timestamp, padding]);
  });

  metrics.totalCampaignBudgetSum = totalCampaignBudgetSum;

  return metrics;
};

const getChartOptions = (dailyBudgets: [number, number][]) => ({
  chart: {
    height: 200,
    type: 'column',
    marginLeft: 0
  },
  title: {
    text: ''
  },
  legend: {
    enabled: false
  },
  xAxis: {
    minPadding: 0,
    maxPadding: 0,
    crosshair: false,
    type: 'datetime',
    dateTimeLabelFormats: {
      month: '%b',
      week: '%e',
      day: '%e'
    },
    labels: {
      align: 'center',
      style: {
        fontFamily: 'Roboto, sans-serif',
        color: colors.darkBlue,
        fontSize: '11px',
        transform: 'translateX(12px)'
      }
    },
    margin: 0,
    lineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: 'transparent',
    tickInterval: 24 * 3600 * 1000 * 1,
    minorTickLength: 0,
    minorTickWidth: 0,
    tickLength: 0
  },
  yAxis: {
    visible: false,
    gridLineColor: 'none',
    min: 0,
    title: {
      text: ''
    }
  },
  credits: {
    enabled: false
  },
  exporting: { enabled: false },
  tooltip: {
    borderWidth: 1,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    useHTML: true,
    shadow: false,
    shared: true,
    positioner(labelWidth: number, labelHeight: number, point: any) {
      return { x: point.plotX, y: point.plotY - 20 };
    },
    formatter() {
      const inst = this as any;
      return ReactDOMServer.renderToString(
        <StackedColumnTooltip datas={inst.points} time={inst.x} dailyBudgets={dailyBudgets} />
      );
    }
  },
  plotOptions: {
    column: {
      stacking: 'percent',
      borderWidth: 0,
      pointWidth: 23,
      pointPadding: 0.2
    }
  }
});

const buildChartSeries = (dataSet: ReturnType<typeof computeDataSet>) =>
  [
    { name: '', color: '#00000000', data: dataSet.padding },
    { name: 'Surplus Budget', color: colors.surplusBudget, data: dataSet.surplusBudget },
    { name: 'Overpacing', color: colors.overpacing, data: dataSet.overspend },
    { name: 'Actual Spend', color: colors.actualSpend, data: dataSet.actualSpend },
    { name: 'Projected Spend', color: colors.projectedSpend, data: dataSet.projectedSpend }
  ].map((series) => ({
    ...series,
    data: series.data.map(([timestamp, value]) => [timestamp, Math.round(value * 100) / 100]),
    pointInterval: 86400000 // one day
  }));

const getSum = (arr: [number, number][]) => arr.reduce((a, b) => a + b[1], 0);

export const formatLegendData = (
  dataset: {
    actualSpend: [number, number][];
    budget: [number, number][];
    projectedSpend: [number, number][];
    overspend: [number, number][];
    padding: [number, number][];
  },
  totalMonthlyBudget: number
) => {
  const totalActualOverspend = getSum(dataset.overspend);
  const totalProjectedOverspend = getSum(dataset.overspend.filter((_, i) => dataset.projectedSpend[i][1] > 0));

  const actualSpend = getSum(dataset.actualSpend);
  const projectedSpend = getSum(dataset.projectedSpend);
  const totalSpend = actualSpend + totalActualOverspend + projectedSpend + totalProjectedOverspend;

  const actualSpendPercent = numeral((actualSpend + totalActualOverspend) / totalMonthlyBudget).format('0.00%');
  const projectedSpendPercent = numeral((projectedSpend + totalProjectedOverspend) / totalMonthlyBudget).format(
    '0.00%'
  );
  const totalSpendPercent = numeral(totalSpend / totalMonthlyBudget).format('0.00%');

  const mainLegendData = [
    {
      text: 'Actual Spend (MTD)',
      value: numeral(actualSpend + totalActualOverspend).format('$0.00a'),
      percentageValue: `${actualSpendPercent} of budget`
    },
    {
      text: 'Projected Additional Spend',
      value: numeral(projectedSpend + totalProjectedOverspend).format('$0.00a'),
      percentageValue: `${projectedSpendPercent} of budget`
    },
    {
      text: 'Projected Total Spend',
      value: numeral(totalSpend).format('$0.00a'),
      percentageValue: `${totalSpendPercent} of budget`
    },
    { text: 'Total Budget', value: numeral(totalMonthlyBudget).format('$0.00a'), percentageValue: '' }
  ];

  return { mainLegendData, totalSpendPercent: numeral(totalSpend / totalMonthlyBudget).value() };
};

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['adCampaigns', 'adPortfolios', 'adEntities', 'entitySearchService', 'retailer', 'app']),
  mainEntity: state.entityService.mainEntity
});

export const DailySpendBarChartInner: React.FC<WidgetProps & ReturnType<typeof mapStateToProps>> = ({
  mainEntity,
  app,
  retailer,
  entitySearchService,
  queryConditions,
  adCampaigns,
  adPortfolios,
  adEntities
}) => {
  const { ref, inView } = useInView({ threshold: 0 });
  const [dataFetched, setDataFetched] = useState(false);

  const baseDataKey = `dailyAdSpendBarChart-${mainEntity!.id}`;
  useEffect(() => {
    if (!mainEntity || _isEmpty(adCampaigns) || !inView || dataFetched) {
      return;
    }

    fetchDailySpendAndProjections(baseDataKey, { mainEntity, retailer, app }, queryConditions);
    setDataFetched(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseDataKey, adCampaigns, mainEntity, inView, dataFetched]);

  const { [`${baseDataKey}-projections`]: adSpendMetricsProjected, [`${baseDataKey}-actual`]: adSpendMetricsActual } =
    entitySearchService;

  const computed = useMemo(() => {
    if (
      !adSpendMetricsProjected ||
      !adSpendMetricsActual ||
      !mainEntity ||
      !adSpendMetricsActual.spend_by_dayId ||
      !adSpendMetricsProjected.spend_by_dayId
    ) {
      return null;
    }

    let maxDayIdWithActual = 0;
    if (adSpendMetricsActual.spend_by_dayId) {
      adSpendMetricsActual.spend_by_dayId.data.forEach((datum) => {
        const dayId = +datum.name!;
        if (dayId > maxDayIdWithActual) {
          maxDayIdWithActual = dayId;
        }
      });
    }

    let totalMonthlyBudget = getTotalMonthlyBudgetForCurrentEntity(mainEntity, adCampaigns, adPortfolios, adEntities);
    const averageDailyBudget = totalMonthlyBudget / moment().daysInMonth();

    // Zip up the fetched projected + actual ad spend metrics into a mapping of dayId to spend for that week
    const projectedAdSpendByDayData: {
      dayId: number;
      budgetAmount: number;
      value: number;
      isProjected: boolean;
    }[] = [];
    adSpendMetricsProjected.spend_by_dayId.data.forEach((datum) => {
      if (+datum.name! > maxDayIdWithActual) {
        projectedAdSpendByDayData.push({
          ...datum,
          dayId: +datum.name!,
          budgetAmount: datum.value || 0, // for projection spend is equal to budget, since our algorithm will set the budget based on projection
          isProjected: true,
          value: datum.value || 0
        });
      }
    });
    const actualAdSpendByDayData: {
      dayId: number;
      budgetAmount: number;
      value: number;
      isProjected: boolean;
    }[] = [];
    if (adSpendMetricsActual.spend_by_dayId) {
      adSpendMetricsActual.spend_by_dayId.data.forEach((datum, index) => {
        actualAdSpendByDayData.push({
          ...datum,
          dayId: +datum.name!,
          budgetAmount:
            mainEntity.type === 'adCampaign'
              ? adSpendMetricsActual.budgetAmount_by_dayId.data[index].value
              : averageDailyBudget, // only for campaigns take daily budget into account. For portfolio and entity use the average daily budget based on monthly budget
          isProjected: false,
          value: datum.value || 0
        });
      });
    }

    const metricsByDayId: Map<number, { actual?: number; projected?: number; budgetAmount: number }> = [
      ...projectedAdSpendByDayData,
      ...actualAdSpendByDayData
    ].reduce((acc, { dayId, value, isProjected, budgetAmount }) => {
      const entry = acc.get(+dayId) || {};
      if (isProjected) {
        entry.projected = value;
      } else {
        entry.actual = value;
      }
      entry.budgetAmount = budgetAmount;
      acc.set(+dayId, entry);
      return acc;
    }, new Map());
    const dataSet = computeDataSet(metricsByDayId);

    if (mainEntity.type === 'adCampaign') {
      totalMonthlyBudget = dataSet.totalCampaignBudgetSum;
    }
    return { totalMonthlyBudget, dailyBudgets: dataSet.dailyBudget, dataSet };
  }, [adSpendMetricsProjected, adSpendMetricsActual, mainEntity, adCampaigns, adPortfolios, adEntities]);

  const chartOptions = useMemo(
    () => Option.of(computed).map(prop('dailyBudgets')).map(getChartOptions).orNull(),
    [computed]
  );

  const renderHeader = () => {
    return (
      <div className="section_headerline" ref={ref}>
        {moment().format('MMMM')} - Budget Pacing
      </div>
    );
  };

  const { dataSet, totalMonthlyBudget } = computed || {};

  const mainLegendData = useMemo(() => {
    if (!computed || !chartOptions) {
      return null;
    }
    const { mainLegendData: mainLegendDataResult } = formatLegendData(dataSet, totalMonthlyBudget);
    return mainLegendDataResult;
  }, [dataSet, totalMonthlyBudget, computed, chartOptions]);

  if (!computed || !chartOptions) {
    return (
      <>
        {renderHeader()}
        <br />
        <br />
        <StackedColumnChartLoading />
      </>
    );
  }

  const series = buildChartSeries(dataSet);

  return (
    <div>
      {renderHeader()}
      <br />
      <br />
      <StackedColumnLegend mainLegendData={mainLegendData} />
      <br />
      <br />
      <div style={{ position: 'relative' }}>
        <HighchartsReact highcharts={Highcharts} options={{ ...chartOptions, series }} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(DailySpendBarChartInner);
