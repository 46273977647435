import React, { useMemo } from 'react';
import { useStacklineTheme } from '@stackline/ui';
import {
  parseBeaconBuyBoxSellersResponse,
  useBeaconBuyBoxSellersData
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/BuyBox/Sellers/useBeaconBuyBoxSellersData';
import SplineChartLoading from 'src/components/BeaconRedesignComponents/SplineChartLoading/SplineChartLoading';
import TopEntityChartV2 from 'src/components/BeaconRedesignComponents/TopEntityChartV2/TopEntityChartV2';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import BuyBoxLossRateByProductGrid from './BuyBoxLossRateByProductGrid';

import { METRICTYPE } from 'src/utils/entityDefinitions';
import { Box } from '@mui/system';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { useMainEntityType } from 'src/utils/Hooks/reduxSelectors';
import BuyBoxByWeekGrid from 'src/components/BeaconRedesignComponents/ExperimentalLayout/BuyBox/Summary/BuyBoxByWeekGrid';

const BuyBoxSellersPage = () => {
  const { isLoading, data } = useBeaconBuyBoxSellersData();
  const entityType = useMainEntityType();

  const sellerBuyBoxData = useMemo(() => {
    return data ? parseBeaconBuyBoxSellersResponse(data) : [];
  }, [data]);

  const theme = useStacklineTheme();

  return (
    <BeaconPageContainer>
      <Flex gap={BEACON_CHART_SPACING} flexDirection="column">
        {isLoading ? (
          <SplineChartLoading width={900} />
        ) : (
          <Flex flexDirection="column">
            <Box sx={{ marginBottom: theme.spacing.lg }}>
              <CommonSummaryInfoSubtitle title="Sellers" />
            </Box>
            <TopEntityChartV2
              chartSeries={[
                {
                  type: 'column',
                  data: sellerBuyBoxData,
                  metricType: METRICTYPE.PERCENT
                }
              ]}
              seriesColors={[theme.colors.primary]}
              chartOptionsOverride={{
                chart: {
                  height: 300
                }
              }}
            />
          </Flex>
        )}
        {entityType !== 'product' && <BuyBoxLossRateByProductGrid />}
        {entityType === 'product' && (
          <BuyBoxByWeekGrid
            gridTitle="Loss Rate by Week"
            fields={[
              {
                name: 'buyBoxWeighted',
                displayInTable: false,
                comparisonFormatter: () => null
              },
              {
                name: 'winsByClient',
                displayInTable: false,
                comparisonFormatter: () => null
              },
              {
                name: 'lossPercentage',
                displayName: 'Buy Box - Loss Rate',
                comparisonFormatter: () => null
              },
              {
                name: 'winPercentage',
                displayName: 'Buy Box - Win Rate',
                comparisonFormatter: () => null
              }
            ]}
          />
        )}
      </Flex>
    </BeaconPageContainer>
  );
};

export default BuyBoxSellersPage;
