/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import numeral from 'numeral';
import { SinglePill } from 'src/components/AdManager/Search/SearchResultsHeader';
import withStyles from '@mui/styles/withStyles';
import MuiExpansionPanel from '@mui/material/Accordion';
import MuiExpansionPanelSummary from '@mui/material/AccordionSummary';
import MuiExpansionPanelDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withBus } from 'react-bus';

import { store } from 'src/main';
import * as adBudgetEditorActions from 'src/store/modules/adManager/adBudgetEditor/actions';
import { getTimelineOpt } from 'src/store/modules/adManager/adBudgetEditor/selectors';
import {
  SectionWrapper,
  OperationButtons
} from 'src/components/AdCampaignBuilder/Widgets/AdCampaignBuilderCommonWidgets';
import ReduxStore from 'src/types/store/reduxStore';
import { AdCampaignBuilderCheckbox } from 'src/components/AdCampaignBuilder/Widgets/Checkbox';
import SlidedSidebarFilter from 'src/components/AdBudgetEditor/Widgets/SlidedSidebarFilter';
import colors from 'src/utils/colors';
import './BudgetEditor.scss';
import OrphanCampaignsTable from './components/OrphanCampaignsTable';
import PortfoliosTable from './components/PortfoliosTable';

export const Accordion = withStyles({
  root: {
    padding: 0,
    background: '#fff',
    border: 'none',
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  disabled: {
    backgroundColor: '#fff !important'
  },

  expanded: {}
})(MuiExpansionPanel);

export const AccordionSummary = withStyles({
  root: {
    padding: 0,
    background: '#fff',
    borderBottom: `1px solid ${colors.lightestGrey}`,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  disabled: {
    opacity: '0.7 !important'
  },
  expanded: {}
})(MuiExpansionPanelSummary);

export const AccordionDetails = withStyles({
  root: {
    padding: 0
  }
})(MuiExpansionPanelDetails);

const RemainingBudget = ({ remain, total }: { remain: number; total: number }) => {
  const color = remain >= 0 ? colors.green : colors.red;
  const str = remain >= 0 ? 'remaining' : 'over-allocated';
  const remainDisplay = Math.abs(remain);
  return (
    <div className="remaining">
      {' '}
      <span style={{ color }}>{numeral(remainDisplay).format('$1,000')}</span> {str} of{' '}
      {numeral(total).format('$1,000')}{' '}
    </div>
  );
};

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, ['app', 'adBudgetEditor', 'retailer', 'adPortfolioStates', 'adAutomationStrategies']),
    timelineOpt: getTimelineOpt()
  };
};

const BudgetAllocation: React.FC<ReturnType<typeof mapStateToProps>> = ({
  adBudgetEditor,
  adPortfolioStates,
  adAutomationStrategies,
  eventBus
}) => {
  const [expanded, setExpanded] = React.useState('');
  const [autoMode, setAutoMode] = React.useState(false);
  const [filterInfo, setFilter] = React.useState({});

  const { timeline } = adBudgetEditor;

  const [errorMsg, setErrorMsg] = React.useState('');

  const handleExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  /**
   * Update the budget for an individual campaign
   * @param id The ID of the campaign to update
   * @param value The new budget
   */
  const handleCampaignBudgetChange = (id: string | number, value: number | undefined) => {
    const cloneCampaigns = _cloneDeep(adBudgetEditor.campaignArray);
    cloneCampaigns.forEach((item) => {
      if (item.id === id) {
        item.entity.extendedAttributes.newBudgetSetting = { amount: value };
      }
    });
    store.dispatch(adBudgetEditorActions.setCampaignArray(cloneCampaigns));
  };

  const initBudget = () => {
    if (_isEmpty(adBudgetEditor.portfolioArray)) {
      return;
    }
    const clonePortfolios = _cloneDeep(adBudgetEditor.portfolioArray);
    clonePortfolios.forEach((item) => {
      const newBudgetSetting = _cloneDeep(item.entity.extendedAttributes[timeline.name]);
      item.entity.extendedAttributes.newBudgetSetting = newBudgetSetting;
    });
    store.dispatch(adBudgetEditorActions.setPortfolioArray(clonePortfolios));
  };

  const { entity } = adBudgetEditor.entity;

  const filteredPortfolios = (adBudgetEditor.portfolioArray || []).filter((item) => {
    return item.entity.extendedAttributes.entityId === entity.id;
  });

  const allSelectedPortfolio = filteredPortfolios.filter((item) => {
    return !!_get(item, ['entity', 'extendedAttributes', 'isSelected']);
  });
  let budgetSum = 0;
  allSelectedPortfolio.forEach((item) => {
    budgetSum += _get(item, ['entity', 'extendedAttributes', 'newBudgetSetting', 'amount'], 0);
  });

  React.useEffect(() => {
    if (allSelectedPortfolio.length >= 1) {
      setErrorMsg('');
    }
  }, [allSelectedPortfolio.length]);

  const autoAllocateBudget = () => {
    if (_isEmpty(allSelectedPortfolio)) {
      return;
    }

    let totalSales = 0;
    let totalSpend = 0;
    allSelectedPortfolio.forEach((portfolio) => {
      totalSales += _get(portfolio, 'sales.value', 0);
      totalSpend += _get(portfolio, 'spend.value', 0);
    });

    const clonePortfolios = _cloneDeep(adBudgetEditor.portfolioArray);

    const additionalAmountToAllocate = adBudgetEditor.amount! - totalSpend;

    clonePortfolios.forEach(({ entity: pEntity, sales, spend, id }) => {
      if (allSelectedPortfolio.find((portfolio) => portfolio.id === id)) {
        const salesAmount = _get(sales, ['value'], 0);
        const spendAmount = _get(spend, ['value'], 0);

        // Calculate the weights for ad spend and ad sales
        const newBudgetWeightFromSales = totalSales !== 0 ? salesAmount / totalSales : 0;
        const newBudgetWeightFromSpend = totalSpend !== 0 ? spendAmount / totalSpend : 0;

        // Amount is weighted 50/50 by % of sales and % of spend
        const newWeight = newBudgetWeightFromSales * 0.5 + newBudgetWeightFromSpend * 0.5;

        // The additional amount we allocate is new budget - amount already spent this month
        // new budget must always be >= spendAmount, so we add it
        const newBudgetAmount = additionalAmountToAllocate * newWeight + spendAmount;

        const newBudgetSetting = { amount: +newBudgetAmount.toFixed(2) };
        pEntity.extendedAttributes.newBudgetSetting = newBudgetSetting;
      }
    });
    store.dispatch(adBudgetEditorActions.setPortfolioArray(clonePortfolios));
  };

  const handleAutoModeChange = (mode: boolean) => {
    if (mode && allSelectedPortfolio.length === 0) {
      setErrorMsg('*Please select at least one portfolio');
    } else {
      if (mode) {
        autoAllocateBudget();
      } else {
        initBudget();
      }
      setAutoMode(mode);
    }
  };

  if (_isEmpty(adBudgetEditor.platform)) {
    return null;
  }

  const canContinue = adBudgetEditor.amount - budgetSum >= -1;

  const togleFilter = () => {
    eventBus.emit('toggleSearchSidebar', null);
  };

  const filterPortfolios = () => {
    let newfilteredPortfolios = _cloneDeep(filteredPortfolios);
    const pills = [];
    if (filterInfo.includes && filterInfo.includes.length >= 1) {
      pills.push({ count: filterInfo.includes.length, name: 'Keyword' });
      newfilteredPortfolios = newfilteredPortfolios.filter((p) => {
        return filterInfo.includes.find((filterName) => {
          const portfolioName = p.entity.name.toLowerCase();
          return portfolioName.includes(filterName.toLowerCase());
        });
      });
    }

    if (filterInfo.excludes && filterInfo.excludes.length >= 1) {
      pills.push({ count: filterInfo.excludes.length, name: 'Exclude' });
      newfilteredPortfolios = newfilteredPortfolios.filter((p) => {
        return !filterInfo.excludes.find((filterName) => {
          const portfolioName = p.entity.name.toLowerCase();
          return portfolioName.includes(filterName.toLowerCase());
        });
      });
    }

    if (filterInfo.portfolioState && filterInfo.portfolioState.length >= 1) {
      pills.push({ count: filterInfo.portfolioState.length, name: 'Status' });
      newfilteredPortfolios = newfilteredPortfolios.filter((p) => {
        return filterInfo.portfolioState.find(({ i }) => {
          const portfolioStatus = _get(p, ['entity', 'extendedAttributes', 'statusDerived']);
          return portfolioStatus === i;
        });
      });
    }
    if (filterInfo.automationStrategyId && filterInfo.automationStrategyId.length >= 1) {
      pills.push({ count: filterInfo.automationStrategyId.length, name: 'Optimization' });
      newfilteredPortfolios = newfilteredPortfolios.filter((p) => {
        return filterInfo.automationStrategyId.find(({ i }) => {
          const portfolioOpt = _get(p, ['entity', 'extendedAttributes', 'automationAttributes', 'strategyId']);
          return portfolioOpt === i;
        });
      });
    }

    return { pills, newfilteredPortfolios };
  };

  const { pills, newfilteredPortfolios: finalfilterPortfolios } = filterPortfolios();

  const allPortfolioSelected = !finalfilterPortfolios.find((item) => {
    return _get(item, ['entity', 'extendedAttributes', 'isSelected'], false) === false;
  });

  const toggleSelectAll = () => {
    const newSelect = !allPortfolioSelected;
    const clonePortfolios = _cloneDeep(adBudgetEditor.portfolioArray);
    clonePortfolios.forEach(({ entity: portfolioEntity, id }) => {
      if (finalfilterPortfolios.find((portfolio) => portfolio.id === id)) {
        portfolioEntity.extendedAttributes.isSelected = newSelect;
      }
    });
    store.dispatch(adBudgetEditorActions.setPortfolioArray(clonePortfolios));
  };

  let spendSum = 0;
  let salesSum = 0;
  let budgetCurrentSum = 0;

  allSelectedPortfolio.forEach(({ sales, spend, entity: pEntity }) => {
    const budgetV = _get(pEntity, ['extendedAttributes', 'currentMonthBudgetSetting', 'amount'], 0);
    salesSum += _get(sales, ['value'], 0);
    spendSum += _get(spend, ['value'], 0);
    budgetCurrentSum += budgetV;
  });

  const showWarning = allSelectedPortfolio.length === 0;

  return (
    <div className="ad-manager-container">
      <OperationButtons
        subtab="budgetAllocation"
        canContinue={canContinue}
        customFlow={adBudgetEditor.flow}
        openDialog={showWarning}
        dialogTitle="Warning"
        dialogContent="You have not selected any portfolio to update budget. Are you sure you want to proceed?"
      />
      <SectionWrapper
        header={`${timeline.displayName} Budget`}
        subheader="Allocate budgetary changes to portfolios below. Spend and Sales metrics shown represent the last four weeks."
        layer={0}
      >
        <div className="platform-select">
          <div className="allocation_container">
            <div className="allocation_header">
              <div>
                <div className="autoMode">
                  <div style={{ width: 65 }}>
                    <AdCampaignBuilderCheckbox checked={autoMode} onChange={() => handleAutoModeChange(!autoMode)} />
                  </div>
                  <div className="disc">Automatically allocate my new budget based on performance</div>
                </div>
                <div style={{ color: colors.red }}>{errorMsg}</div>
              </div>
              <div className="header_total">
                <div className="current_budget_cell">
                  <div className="value">{numeral(adBudgetEditor.entityBudget).format('$1,000')}</div>
                  <div className="title">Entity Budget</div>
                </div>

                <div className="current_budget_cell">
                  <div className="value">{numeral(+adBudgetEditor.amount).format('$1,000')}</div>
                  <div className="title">Allocation Amount</div>
                </div>
                <div className="new_budget_cell">
                  {numeral(budgetSum).format('$1,000')}
                  <RemainingBudget remain={adBudgetEditor.amount - budgetSum} total={adBudgetEditor.amount} />
                </div>
              </div>
            </div>
            <div className="filter_row">
              {pills.map((pill, pIdx) => (
                <SinglePill key={pIdx} pill={pill} onPillClick={togleFilter} />
              ))}
              <div role="button" className="filter_text" onClick={togleFilter}>
                +Filters
              </div>
            </div>
            <SlidedSidebarFilter
              filterInfo={filterInfo}
              setFilter={setFilter}
              adPortfolioStates={adPortfolioStates}
              adAutomationStrategies={adAutomationStrategies}
            />
            <div className="allocation">
              <div className="portfolio_header portfolio_row portfolio_header_sticky">
                <div className="checkbox_col">
                  <AdCampaignBuilderCheckbox checked={allPortfolioSelected} onChange={() => toggleSelectAll()} />
                </div>
                <div className="portfolio">Portfolio</div>
                <div className="ad_spend">Ad Spend</div>
                <div className="ad_sales">Ad Sales</div>
                <div className="ad_roas">AMS Budget</div>
                <div className="current_budget">Stackline Budget</div>
                <div className="budget_change">Budget Change</div>
                <div className="new_budget">New Budget</div>
              </div>
              <div
                className="portfolio_header portfolio_row portfolio_total_header_sticky"
                style={{ borderTop: 'none' }}
              >
                <div className="checkbox_col" style={{ visibility: 'hidden' }}>
                  <AdCampaignBuilderCheckbox checked={false} onChange={() => {}} />
                </div>
                <div className="portfolio">Total</div>
                <div className="ad_spend">{numeral(spendSum).format('$1,000')}</div>
                <div className="ad_sales">{numeral(salesSum).format('$1,000')}</div>
                <div className="ad_roas"></div>
                <div className="current_budget">{numeral(budgetCurrentSum).format('$1,000')}</div>
                <div className="budget_change"></div>
                <div className="new_budget"></div>
              </div>

              <PortfoliosTable
                portfolios={finalfilterPortfolios}
                expanded={expanded}
                handleExpanded={handleExpanded}
                handleCampaignBudgetChange={handleCampaignBudgetChange}
              />
            </div>
          </div>
        </div>
      </SectionWrapper>
      <div className="platform-select">
        <div className="allocation_container">
          <div className="allocation orphan-campaigns">
            <Accordion square expanded={expanded === 'orphan_campaigns'} onChange={handleExpanded('orphan_campaigns')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                <div
                  role="button"
                  className="portfolio_row"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                >
                  <div className="portfolio">Orphan Campaigns</div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <OrphanCampaignsTable handleCampaignBudgetChange={handleCampaignBudgetChange} />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

const EnhancedBudgetAllocation = connect(mapStateToProps)(withBus('eventBus')(BudgetAllocation));

export default EnhancedBudgetAllocation;
