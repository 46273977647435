import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';

import { buildShortName } from 'src/utils/stringFormatting';
import { comparisonParams, filterParams } from 'src/utils/segments';
import { addQueryParams } from 'src/utils/browser';
import { CompareBrandListLoading } from '../../common/Loading/PlaceHolderLoading/PlaceHolderLoading';

class Brands extends React.Component {
  static defaultProps = {
    togglePanel: () => {}
  };

  static propTypes = {
    app: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    togglePanel: PropTypes.func
  };

  state = {};

  componentDidMount() {
    this.buildPage();
  }

  componentWillReceiveProps(nextProps) {
    this.buildPage(nextProps);
  }

  buildPage(props = this.props) {
    const { categories, conditions, app } = props;
    const searchText = conditions.termFilters.filter((val) => val.fieldName === 'keyword')[0].values[0];
    this.setState({ isLoading: true });
    const postBody = [
      {
        pageSize: 100,
        pageNumber: 1,
        searchType: `${app.name}-brand`,
        retailerId: -1,
        returnDocuments: true,
        searchBy: 'parent',
        conditions: {
          termFilters: [
            {
              fieldName: 'brandName',
              queryType: 'wildcard',
              values: [`${searchText}*`]
            },
            {
              fieldName: 'categoryIds',
              condition: 'should',
              values: categories.map((val) => val.categoryId)
            }
          ]
        }
      }
    ];
    axios.post(`/api/${app.name}/AdvancedSearch?_id=fetchBrands`, postBody).then((response) => {
      const brands = [];

      response.data[0].documents.forEach((brand) => {
        const shortName = buildShortName(brand.brandName);
        brands.push({
          id: brand.brandId,
          type: 'brand',
          displayName: brand.brandName,
          shortName
        });
      });
      brands.sort((a, b) => a.displayName.localeCompare(b.displayName));
      this.setState({
        isLoading: false,
        brands
      });
    });
  }

  handleTouchTap = (selection) => {
    const { togglePanel, location, history, retailer, mainTimePeriod } = this.props;
    const entity = {
      id: selection.id,
      type: selection.type
    };
    const existingParams = addQueryParams(location, retailer, mainTimePeriod, [...filterParams, ...comparisonParams]);

    togglePanel();
    history.push(`?ctype=${entity.type}&cid=${entity.id}&${existingParams}`);
  };

  showNoBrands() {
    const { brands } = this.state;

    if (brands.length > 0) {
      return null;
    }

    return <div style={{ padding: '10px' }}>No brands found.</div>;
  }

  render() {
    const { brands, isLoading } = this.state;

    if (isLoading) {
      return <CompareBrandListLoading />;
    }

    if (!brands) {
      return null;
    }

    return (
      <div style={{ padding: '10px' }}>
        {brands.map((val) => (
          <MenuItem key={val.id} style={{ fontSize: '14px' }} onClick={() => this.handleTouchTap(val)}>
            {val.displayName}
          </MenuItem>
        ))}
        {this.showNoBrands()}
      </div>
    );
  }
}

export default withRouter(connect((state) => state)(Brands));
