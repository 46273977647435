import { AD_STATUS_DERIVED, AD_CAMPAIGN_STATUS } from 'sl-ad-campaign-manager-data-model';

const RECEIVE_AD_CAMPAIGN_PRODUCT_STATES = 'src/adCampaignProductStates/RECEIVE_AD_CAMPAIGN_PRODUCT_STATES' as const;
const CLEAR_AD_CAMPAIGN_PRODUCT_STATES = 'src/adCampaignProductStates/CLEAR_AD_CAMPAIGN_PRODUCT_STATES' as const;

export interface AdCampaignProductState {
  id: AD_STATUS_DERIVED | AD_CAMPAIGN_STATUS;
  campaignProductState: AD_STATUS_DERIVED | AD_CAMPAIGN_STATUS;
  name: string;
  displayName: string;
  type: 'adCampaignProductState';
}

export default {
  RECEIVE_AD_CAMPAIGN_PRODUCT_STATES,
  CLEAR_AD_CAMPAIGN_PRODUCT_STATES
};
