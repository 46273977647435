import React from 'react';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import { useCreateRemoveRetailPriceRangeFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { useMetricByWeekId } from 'src/serverProxy/useMetricByWeekId';
import {
  useComparisonTimePeriodShortDisplayName,
  useGlobalSubtitleName,
  useMainTimePeriodShortDisplayName,
  useMetricFormatter
} from 'src/utils/Hooks';
import { useTopicCompanyName, useMainEntityName, useMainEntityType } from 'src/utils/Hooks/reduxSelectors';
import { METRICTYPE } from 'src/utils/entityDefinitions';

/**
 * Total traffic spline chart
 */
export const TotalTrafficSplineChart = () => {
  const removeRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();
  const mainEntityType = useMainEntityType();
  const entityName = useMainEntityName();
  const formatMetric = useMetricFormatter();
  const { subtitleDisplayName } = useGlobalSubtitleName({ includeUnnamed: false });
  const mainPeriodDisplayName = useMainTimePeriodShortDisplayName();
  const comparisonDisplayName = useComparisonTimePeriodShortDisplayName();
  const companyName = useTopicCompanyName();

  const indexName = mainEntityType === 'searchtermlist' ? 'traffic' : 'aggregated-traffic';

  const {
    primaryTotal: totalClicks,
    percentChange,
    isLoading
  } = useMetricByWeekId({
    indexName,
    fieldName: 'totalClicks'
  });

  const getSubtitle = () => {
    const formattedClicks = formatMetric(totalClicks, METRICTYPE.VOLUME, { showFullValue: false, decimalPlaces: 1 });
    const formattedPercent = formatMetric(Math.abs(percentChange), METRICTYPE.PERCENT, { decimalPlaces: 2 });
    const percentDirection = percentChange >= 0 ? 'higher' : 'lower';

    const entityTitle = {
      client: entityName,
      brand: entityName,
      segment: entityName,
      searchtermlist: companyName,
      category: subtitleDisplayName,
      subcategory: subtitleDisplayName,
      product: 'This product'
    }[mainEntityType];

    const entitySubTitle = {
      client: subtitleDisplayName ? `in ${subtitleDisplayName}` : '',
      brand: subtitleDisplayName ? `in ${subtitleDisplayName}` : '',
      segment: subtitleDisplayName ? `in ${subtitleDisplayName}` : '',
      searchtermlist: `in ${subtitleDisplayName || entityName}`,
      category: '',
      subcategory: '',
      product: ''
    }[mainEntityType];

    return `${entityTitle} earned ${formattedClicks} total traffic ${
      entitySubTitle || ''
    } during the ${mainPeriodDisplayName} time period, which is ${formattedPercent} ${percentDirection} compared to the ${comparisonDisplayName}.`;
  };

  return (
    <BeaconComparableSplineChart
      title="Total Traffic"
      indexName={indexName}
      fieldName="totalClicks"
      requestBuilder={removeRetailPriceRangeFilters()}
      beaconChartWithLegendOverride={(defaultProps) => ({
        ...defaultProps,
        subtitle: getSubtitle(),
        loading: defaultProps.loading || isLoading,
        includeSummaryInfo: false
      })}
    />
  );
};
