import ReduxStore from 'src/types/store/reduxStore';
import _cloneDeep from 'lodash/cloneDeep';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { AppName } from 'sl-api-connector/types';
import { QueryParams } from 'src/types/application/types';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import OmniSummaryHeader from 'src/components/Omni/OmniSummaryHeader';
import { PageLayout, Widget } from 'src/types/application/widgetTypes';
import { buildWidgetGroup, buildTrendWidgetConfig } from 'src/components/Layout/LayoutUtil';
import OmniTileList from 'src/components/SummaryTile/OmniTIleList';
import OmniTrendChart from 'src/components/EntityPage/TrendChart/OmniTrendChart';
import OmniSegmentList from 'src/components/Omni/OmniSegmentList/OmniSegmentList';
import OmniKeywordList from 'src/components/Omni/OmniKeywordList/OmniKeywordList';
import OmniStoreList from 'src/components/Omni/OmniStoreList/OmniStoreList';
import OmniGeoMapContainerRenewal from 'src/components/Omni/OmniGeoMap/OmniGeoMapContainerRenewal';

const INVENTORY_INDEX_NAME = 'inventory';
const SHIPPING_INDEX_NAME = 'shipping';
const SHARE_OF_SHELF_INDEX_NAME = 'shareOfShelf';
const CONTENT_SCORE_INDEX_NAME = 'content';

export const getLayoutForEntity = (app: ReduxStore['app'], _subtab: string, entityType: string): PageLayout => {
  const widgets: Widget[] = [];
  const containerStyle = {
    padding: '0 15px',
    display: 'flex' as const,
    margin: '30px auto 0' as const,
    flexDirection: 'column' as const,
    alignItems: 'center' as const
  };

  const { selectedEntityName } = app.queryParams as QueryParams;

  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(AppName.Omni, INVENTORY_INDEX_NAME, 'weekId'));

  const buildMiniTrendOmniConfig = (fieldName: string, widgetName: string, legendTitle: string) => {
    let indexName;

    switch (fieldName) {
      case 'inStockSummaryTrend':
      case 'storesInStock':
        indexName = INVENTORY_INDEX_NAME;
        break;
      case 'averageFulfillmentTime':
        indexName = SHIPPING_INDEX_NAME;
        break;
      case 'totalShareOfShelf':
        indexName = SHARE_OF_SHELF_INDEX_NAME;
        break;
      case 'contentScore':
        indexName = CONTENT_SCORE_INDEX_NAME;
        break;
      default:
        indexName = INVENTORY_INDEX_NAME;
    }
    return buildTrendWidgetConfig(app, indexName, { type: entityType }, 'weekId', [fieldName], weekIdField, {
      name: widgetName,
      view: {
        chartPropsOverride: {
          legend: { enabled: false, legendTitle },
          subtitle: {
            text: '',
            style: {
              'border-bottom': 'none'
            }
          },
          chart: {
            type: 'areaspline',
            height: 130,
            width: 300,
            marginTop: 0
          },
          yAxis: [
            {
              endOnTick: true,
              visible: false,
              minPadding: 0.3
            }
          ],
          xAxis: [
            {
              visible: false
            }
          ],
          tooltip: {
            positioner: (labelWidth, labelHeight, point) => ({
              x: point.plotX - labelWidth / 2,
              y: point.plotY - labelHeight
            })
          },
          title: { text: '' },
          exporting: {
            enabled: false
          }
        },
        showOnlyMainSeries: true,
        isSummary: true,
        container: { style: { display: 'inline-block', marginBottom: '0px', width: 300 } }
      },
      CustomComponent: OmniTrendChart
    });
  };

  const headerTabOpts = {
    brand: [
      {
        name: 'brand',
        displayName: 'Brands',
        tooltipText: 'Create Segment'
      }
    ],
    retailer: [
      {
        name: 'retailer',
        displayName: 'Retailers',
        tooltipText: 'Create Store List'
      }
    ],
    category: [
      {
        name: 'category',
        displayName: 'Categories',
        tooltipText: 'Create Segment'
      },
      {
        name: 'subcategory',
        displayName: 'Subcategories',
        tooltipText: 'Create Segment'
      }
    ],
    subcategory: [
      {
        name: 'category',
        displayName: 'Categories',
        tooltipText: 'Create Categories'
      },
      {
        name: 'subcategory',
        displayName: 'Subcategories',
        tooltipText: 'Create Subcategories'
      }
    ],
    region: [
      {
        name: 'region',
        displayName: 'Regions',
        tooltipText: 'Create Regions'
      }
    ],
    global: [
      {
        name: 'global',
        displayName: 'Regions',
        tooltipText: 'Create Regions'
      }
    ],

    segment: [
      {
        name: 'segment',
        displayName: 'Segments',
        tooltipText: 'Create New Segment'
      }
    ],
    searchtermlist: [
      {
        name: 'searchtermlist',
        displayName: 'Keywords',
        tooltipText: 'Create Keywords Segment'
      }
    ],
    storeLists: [
      {
        name: 'storeLists',
        displayName: 'Store Lists',
        tooltipText: 'Create Store Lists'
      }
    ]
  }[selectedEntityName];

  // Global page will have separate header in OmniGeoMapContainer
  if (!['global'].includes(selectedEntityName)) {
    widgets.push({
      CustomComponent: OmniSummaryHeader,
      name: 'omniSummaryHeader',
      view: {
        name: 'omniSummaryHeader',
        summaryHeader: {
          headerTabOpts
        }
      },
      data: {}
    });
  }

  if (!['region'].includes(selectedEntityName)) {
    const totalShareOfShelfMiniMetrics = buildMiniTrendOmniConfig(
      'totalShareOfShelf',
      'miniOmniMetricsTotalShareOfShelf',
      'TOTAL SHARE OF SHELF'
    );

    const inStockRateMiniMetrics = buildMiniTrendOmniConfig(
      'inStockSummaryTrend',
      'miniOmniMetricsInStockRate',
      'IN STOCK RATE'
    );

    const storeInStockMiniMetrics = buildMiniTrendOmniConfig(
      'storesInStock',
      'miniOmniMetricsDeliveryStoresInStock',
      'STORES IN STOCK'
    );

    const fulfillmentTimeMiniMetrics = buildMiniTrendOmniConfig(
      'averageFulfillmentTime',
      'miniOmniMetricsAvgFulfillmentTime',
      'FULFILLMENT TIME'
    );

    const contentScoreMinMetrics = buildMiniTrendOmniConfig(
      'contentScore',
      'miniOmniMetricsContentScore',
      'CONTENT SCORE'
    );

    const summaryTrendWidgets = [
      totalShareOfShelfMiniMetrics,
      inStockRateMiniMetrics,
      storeInStockMiniMetrics,
      fulfillmentTimeMiniMetrics
    ];

    switch (selectedEntityName) {
      case 'brand': {
        widgets.push({
          CustomComponent: OmniTileList,
          name: 'omniTileListBrand',
          view: {
            name: 'omniTileListBrand',
            selectedEntityName
          },
          data: { summaryTrendWidgets, groupBy: 'brandId' }
        });
        break;
      }

      case 'category': {
        widgets.push({
          CustomComponent: OmniTileList,
          name: 'omniTileListCategory',
          view: {
            name: 'omniTileListCategory',
            selectedEntityName
          },
          data: {
            summaryTrendWidgets: [
              inStockRateMiniMetrics,
              storeInStockMiniMetrics,
              fulfillmentTimeMiniMetrics,
              contentScoreMinMetrics
            ],
            groupBy: 'categoryId'
          }
        });
        break;
      }

      case 'subcategory': {
        widgets.push({
          CustomComponent: OmniTileList,
          name: 'omniTileListSubcategory',
          view: {
            name: 'omniTileListSubcategory',
            selectedEntityName
          },
          data: {
            summaryTrendWidgets: [
              inStockRateMiniMetrics,
              storeInStockMiniMetrics,
              fulfillmentTimeMiniMetrics,
              contentScoreMinMetrics
            ],
            groupBy: 'subCategoryId'
          }
        });
        break;
      }

      case 'retailer': {
        widgets.push({
          CustomComponent: OmniTileList,
          name: 'omniTileListRetailer',
          view: {
            name: 'omniTileListRetailer',
            selectedEntityName
          },
          data: {
            summaryTrendWidgets,
            groupBy: 'retailerId'
          }
        });

        break;
      }
      case 'segment': {
        widgets.push({
          CustomComponent: OmniSegmentList,
          name: 'omniSegmentList',
          view: { name: 'omniSegmentList' },
          data: {}
        });
        break;
      }
      case 'searchtermlist': {
        widgets.push({
          CustomComponent: OmniKeywordList,
          name: 'omniKeywordList',
          view: { name: 'omniKeywordList' },
          data: {}
        });
        break;
      }
      case 'storeLists': {
        widgets.push({
          CustomComponent: OmniStoreList,
          name: 'omniStoreList',
          view: { name: 'omniStoreList' },
          data: {}
        });
        break;
      }
      default:
    }
  }

  if (['global'].includes(selectedEntityName)) {
    widgets.push({
      CustomComponent: OmniGeoMapContainerRenewal,
      // JBAIK change Name when we have all the data in the database, and we decide the name of the page
      name: 'omniGeoMapRenewal',
      view: {
        name: 'omniGeoMap'
      },
      data: {}
    });
  }

  const widgetGroup: Widget = buildWidgetGroup(widgets, {
    view: {
      noInnerContainer: true,
      container: {
        style: {
          width: '100%',
          maxWidth: 1370,
          margin: '0px auto'
        }
      }
    }
  });
  const finalWidgets: Widget[] = [widgetGroup];

  return {
    widgets: finalWidgets,
    CustomPageContainer: GenericPageContainer as any,
    enableComparison: false,
    containerStyle
  };
};
