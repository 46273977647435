import _get from 'lodash/get';

/**
 * Check if the user is subscribed to a single retailer and that retailer is a Beacon Pro retailer
 */
export const isSingleBeaconProRetailerSubscribed = (responseData: unknown) => {
  const allRetailerIds = _get(responseData, ['userConfig', 'allRetailerIds'], []);

  if (allRetailerIds.length === 1) {
    const [onlyRetailerId] = allRetailerIds;

    const onlyRetailerIsBeaconProRetailer =
      _get(responseData, ['userConfig', 'subscriptionsByRetailers', onlyRetailerId, 'BeaconProSubscription']) ===
      'true';

    return onlyRetailerIsBeaconProRetailer;
  } else {
    return false;
  }
};
