import React, { useState } from 'react';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import BuyBoxWinRateByProductGrid from './BuyBoxWinRateByProductGrid';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { useMainEntityType } from 'src/utils/Hooks/reduxSelectors';
import BuyBoxByWeekGrid from 'src/components/BeaconRedesignComponents/ExperimentalLayout/BuyBox/Summary/BuyBoxByWeekGrid';

/**
 * Displays visualizations for the buy box summary page
 */
const BuyBoxSummaryPage = () => {
  const [fieldName, setFieldName] = useState('winPercentage');
  const entityType = useMainEntityType();

  return (
    <BeaconPageContainer>
      <Flex gap={BEACON_CHART_SPACING} flexDirection="column">
        <BeaconComparableSplineChart
          indexName="buybox"
          fieldName={fieldName}
          singleDropdownProps={{
            defaultLabel: '',
            options: [
              {
                id: 'winPercentage',
                label: 'Win Rate'
              },
              {
                id: 'lossPercentage',
                label: 'Loss Rate'
              }
            ],
            selectedId: fieldName,
            onChange: (option) => setFieldName(option.id)
          }}
        />
        {entityType !== 'product' && <BuyBoxWinRateByProductGrid />}
        {entityType === 'product' && (
          <BuyBoxByWeekGrid
            gridTitle="Win Rate by Week"
            fields={[
              {
                name: 'buyBoxWeighted',
                displayInTable: false,
                comparisonFormatter: () => null
              },
              {
                name: 'winsByClient',
                displayInTable: false,
                comparisonFormatter: () => null
              },
              {
                name: 'winPercentage',
                displayName: 'Buy Box - Win Rate',
                comparisonFormatter: () => null
              },
              {
                name: 'lossPercentage',
                displayName: 'Buy Box - Loss Rate',
                comparisonFormatter: () => null
              }
            ]}
          />
        )}
      </Flex>
    </BeaconPageContainer>
  );
};

export default BuyBoxSummaryPage;
