import { Box } from '@mui/system';
import { isArray } from 'lodash';
import _debounce from 'lodash/debounce';
import _isString from 'lodash/isString';
import queryString from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { withBus } from 'react-bus';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { BulkUploadButton, ClearFiltersButton, SaveButton } from 'src/components/Omni/OmniButton/OmniButton';
import { fillSegmentFieldInURL, generateQueryParamObj } from 'src/components/Omni/OmniSegmentUtil';
import OmniClearButton from 'src/components/Omni/Search/OmniClearButton';
import OmniSegmentNameField from 'src/components/Omni/Search/OmniSegmentNameField';
import OmniBulkUpload from 'src/components/Search/AdvancedSearch/BulkUpload/OmniBulkUpload';
import KeywordsSearch from 'src/components/Search/AdvancedSearch/KeywordsSearch';
import {
  fetchKeywordSegmentListData,
  updateKeywordListSegment
} from 'src/store/modules/omni/omniKeywordList/operation';
import ReduxStore from 'src/types/store/reduxStore';
import { EventBus } from 'src/types/utils';
import { addPersistentQueryParams } from 'src/utils/browser';

interface OmniKeywordSideBarProps extends RouteComponentProps {
  handleRequestChange: (
    newReqContent: { [key: string]: any },
    pageInfo: { pageNumber: number; pageSize: number }
  ) => void;
  eventBus: EventBus;
}

export const TextContent = () => (
  <p>
    Paste keywords to create a segment view. You can upload a CSV file containing the information with one per line, no
    comma or other formatting.
    <br />
    <br />
    Please note: there is a max limit of 500 records per upload.
  </p>
);

const OmniKeywordSideBar: React.FC<OmniKeywordSideBarProps> = ({
  location,
  history,
  handleRequestChange,
  eventBus
}: OmniKeywordSideBarProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 10e8 });
  const parsedState = fillSegmentFieldInURL(queryParams, 'keyword');
  const { name } = parsedState;
  const [nameForSegment, setNameForSegment] = useState(name || '');
  const [isUpdating, setIsUpdating] = useState(false);
  const app = useSelector((state: ReduxStore) => state.app);
  const { searchParams } = app.queryParams;
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const mainTimePeriod = useSelector((store: ReduxStore) => store.mainTimePeriod);
  const allKeywordSegData = useSelector((state: ReduxStore) => state.omniKeywordSegmentService);
  const { data: allKeywordSegmentData } = allKeywordSegData;

  const handleChangeInputName = (inputValue: string) => {
    setNameForSegment(inputValue);
  };
  const handleBulkUploadClick = () => {
    setOpenDialog((preVla) => {
      return !preVla;
    });
  };
  const debounceNav = useRef(
    _debounce((queryId: string) => {
      history.push(`keywordSegment/${queryId}${searchParams}&tab=shareOfShelf&subtab=total`);
    }, 700)
  ).current;

  const handleChange = (newChange: { key: string; values: { i: string }[] }) => {
    parsedState.name = nameForSegment;
    const { key, values } = newChange;
    const newParamObj = generateQueryParamObj(parsedState, newChange);
    parsedState[key] = [...values.map((v) => v.i)];
    delete parsedState.name;
    delete parsedState.queryId;
    handleRequestChange(parsedState, { pageNumber: 1, pageSize: 20 });
    history.push(
      `/searchtermlist?${addPersistentQueryParams(retailer, mainTimePeriod)}&entityType=segment&${queryString.stringify(
        newParamObj
      )}`
    );
  };

  const handleSubmit = async () => {
    if (nameForSegment.length === 0) {
      return;
    }
    setIsUpdating(true);
    const { queryId } = parsedState;

    if (allKeywordSegmentData.find((element) => element.name === nameForSegment && element.queryId !== queryId)) {
      alert(`You must pick a unique name for this segment; ${nameForSegment} already exists.`);
      setIsUpdating(false);
      return;
    }

    delete parsedState.name;
    delete parsedState.queryId;

    let requestBody = { name: nameForSegment, query: { ...parsedState }, segmentType: 'keyword' };
    if (_isString(queryId)) {
      requestBody = { ...requestBody, queryId };
    }
    const response = updateKeywordListSegment(requestBody);
    response
      .then((data) => {
        setIsUpdating(false);
        const { data: newSegment } = data;
        const { queryId: newQueryId } = newSegment;
        dispatch(fetchKeywordSegmentListData());
        debounceNav(newQueryId);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const handleClear = () => {
    const { queryId } = parsedState;
    const newParamObj = generateQueryParamObj({ name, queryId }, {});
    Object.keys(parsedState).forEach((k) => {
      if (isArray(parsedState[k])) {
        parsedState[k] = [];
      }
    });
    handleRequestChange(parsedState, { pageNumber: 1, pageSize: 20 });
    history.push(
      `/searchtermlist?${addPersistentQueryParams(retailer, mainTimePeriod)}&entityType=segment&${queryString.stringify(
        newParamObj
      )}`
    );
  };

  const getIdFieldNameAndItemListKey = (filterType: string) => {
    const opt = {
      'include (phrase)': 'includePhrases',
      'include (exact)': 'includeKeywords',
      'exclude (phrase)': 'excludePhrases',
      'exclude (exact)': 'excludeKeywords'
    };
    return opt[filterType];
  };

  const handleExcludeInTable = (obj: { [key: string]: any }) => {
    const preData = parsedState.excludeKeywords || [];
    handleChange({
      key: 'excludeKeywords',
      values: [...preData, obj.name].map((e) => ({
        i: e
      }))
    });
  };

  const handleClearFunction = (key: string) => {
    handleChange({ key, values: [] });
  };

  useEffect(() => {
    eventBus.on('addToExcludeSearchTerm', handleExcludeInTable);
    return () => {
      eventBus.off('addToExcludeSearchTerm', handleExcludeInTable);
    };
  }, [eventBus, location.search]);

  return (
    <div
      className="omni-search-nav-container"
      style={{
        padding: '30px 0'
      }}
    >
      <Box>
        <form>
          <OmniSegmentNameField
            value={nameForSegment}
            handleChangeInputName={handleChangeInputName}
            errorText={nameForSegment.length === 0 ? 'This field is required' : ''}
            hintText="Name your Keywords Segment"
          />

          <KeywordsSearch
            idFieldName="includePhrases"
            headerDisplayName="Include (phrase)"
            searchFieldHintText="Enter keyword phrase to include"
            keywords={parsedState.includePhrases.map((e) => ({ i: e }))}
            onKeywordsChange={handleChange}
            entityDefinition={{}}
            appName={app.name}
            renderClearButton={() => (
              <OmniClearButton
                data={parsedState.includePhrases}
                handleClearAll={() => {
                  handleClearFunction('includePhrases');
                }}
              />
            )}
          />

          <KeywordsSearch
            idFieldName="includeKeywords"
            headerDisplayName="Include (exact)"
            searchFieldHintText="Enter exact keyword to include"
            keywords={parsedState.includeKeywords.map((e) => ({ i: e }))}
            onKeywordsChange={handleChange}
            entityDefinition={{}}
            appName={app.name}
            renderClearButton={() => (
              <OmniClearButton
                data={parsedState.includeKeywords}
                handleClearAll={() => {
                  handleClearFunction('includeKeywords');
                }}
              />
            )}
          />

          <KeywordsSearch
            idFieldName="excludePhrases"
            headerDisplayName="Exclude (phrase)"
            searchFieldHintText="Enter keyword phrase to exclude"
            keywords={parsedState.excludePhrases.map((e) => ({ i: e }))}
            onKeywordsChange={handleChange}
            entityDefinition={{}}
            appName={app.name}
            renderClearButton={() => (
              <OmniClearButton
                data={parsedState.excludePhrases}
                handleClearAll={() => {
                  handleClearFunction('excludePhrases');
                }}
              />
            )}
          />

          <KeywordsSearch
            idFieldName="excludeKeywords"
            headerDisplayName="Exclude (exact)"
            searchFieldHintText="Enter exact keyword to exclude"
            keywords={parsedState.excludeKeywords.map((e) => ({ i: e }))}
            onKeywordsChange={handleChange}
            entityDefinition={{}}
            appName={app.name}
            renderClearButton={() => (
              <OmniClearButton
                data={parsedState.excludeKeywords}
                handleClearAll={() => {
                  handleClearFunction('excludeKeywords');
                }}
              />
            )}
          />

          <SaveButton
            style={{ marginTop: 14 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {isUpdating ? 'SAVE SEGMENT...' : 'SAVE SEGMENT'}
          </SaveButton>
          <>
            <BulkUploadButton secondary style={{ marginTop: 14 }} onClick={handleBulkUploadClick}>
              Bulk Upload
            </BulkUploadButton>
            <ClearFiltersButton secondary style={{ marginTop: 14 }} onClick={handleClear}>
              Clear Filters
            </ClearFiltersButton>

            <OmniBulkUpload
              open={openDialog}
              filterOpt={['include (phrase)', 'include (exact)', 'exclude (phrase)', 'exclude (exact)']}
              uploadOpt={['']}
              itemType="Keyword"
              title="Segment Bulk Upload"
              onClose={handleBulkUploadClick}
              parsedState={parsedState}
              getIdFieldNameAndItemListKey={getIdFieldNameAndItemListKey}
              onKeywordsChange={(newChange: { key: string; values: { i: string }[] }) => {
                handleChange(newChange);
              }}
              TextContent={<TextContent />}
            />
          </>
        </form>
      </Box>
    </div>
  );
};

export default withBus('eventBus')(OmniKeywordSideBar);
