import { Dispatch } from 'react';
import { CancelTokenSource } from 'axios';
import { Conditions, Omit, RangeFilter, TermFilter } from 'sl-api-connector/types';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import { Widget } from 'src/types/application/widgetTypes';
import { PortfoliosDataFetcher } from 'src/components/AdManager/Search/GridDataFetchers/Portfolios/PortfoliosDataFetcher';
import { CampaignsDataFetcher } from 'src/components/AdManager/Search/GridDataFetchers/Campaigns/CampaignsDataFetcher';
import { TargetsDataFetcher } from 'src/components/AdManager/Search/GridDataFetchers/Targets/TargetsDataFetcher';
import { ProductsDataFetcher } from 'src/components/AdManager/Search/GridDataFetchers/Products/ProductsDataFetcher';
import { EntitiesDataFetcher } from 'src/components/AdManager/Search/GridDataFetchers/Entities/EntitiesDataFetcher';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { zipMetricsResponseIntoArray } from 'src/components/AdManager/Search/index';
import { ISearchRequestOverride } from 'src/components/AdManager/Search/Models/ISearchRequestOverride';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { DataForGroupByField } from 'src/components/AdManager/Search/Models/DataForGroupByField';
import { IGridResultData } from 'src/components/AdManager/Search/Models/IGridResultData';
import { IFetchEntityMetricsAndParseResponseParams } from 'src/components/AdManager/Search/Models/IFetchEntityMetricsAndParseResponseParams';
import { IDataSet } from 'src/components/AdManager/Search/Models/IDataSet';
import { getParentPlatform } from 'src/utils/browser';
import _isArray from 'lodash/isArray';
import {
  getESBodyOverridesForParentPlatform,
  modifyESQuery
} from 'src/components/AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';
import { shouldShowCriteo } from 'src/utils/app';
import { Props } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';

export async function fetchEntityMetricsAndParseResponse(
  {
    statePropertyName,
    mainEntity,
    retailer,
    app,
    indexName,
    widget,
    pageSizeOverride,
    searchRequestOverrides
  }: IFetchEntityMetricsAndParseResponseParams,
  dispatch: Dispatch<any>,
  cancelSource: CancelTokenSource,
  customResponseParser?: (args: any[]) => any,
  importantOverrides?: any
): Promise<any> {
  let copyOfOVerrides = _cloneDeep(searchRequestOverrides);
  const parentPlatform = getParentPlatform();

  if (shouldShowCriteo() && importantOverrides && _isArray(importantOverrides) && importantOverrides.length > 0) {
    const newOverride = [];
    copyOfOVerrides.forEach((_, indx) => {
      const mutatedQuery = modifyESQuery(
        copyOfOVerrides[indx],
        getESBodyOverridesForParentPlatform(parentPlatform, importantOverrides)
      );
      newOverride.push(mutatedQuery);
    });
    copyOfOVerrides = newOverride;
  }

  const result = await dispatch(
    fetchEntityMetrics(
      statePropertyName,
      {
        entity: mainEntity,
        retailer,
        app,
        indexName,
        customResponseParser:
          customResponseParser ||
          ((action: any) => {
            return zipMetricsResponseIntoArray(action, widget, pageSizeOverride);
          })
      },
      copyOfOVerrides,
      _get(cancelSource, 'token'),
      true
    )
  );
  return result;
}

export function getClonedResult(resultData: any, pageNumber: number, dataSet: IDataSet) {
  let cloneResult = _cloneDeep(resultData);
  if (pageNumber > 1) {
    cloneResult = {
      ...resultData,
      totalResultCount: _get(dataSet, ['totalResultCount']),
      fullDataSet: _get(dataSet, ['fullDataSet'])
    };
  }
  return cloneResult;
}

export async function fetchDataForGroupByField(
  groupByField: any,
  dispatch: Dispatch<any>,
  cancelSource: CancelTokenSource,
  props: Omit<Props, 'widget'>,
  searchSideBarConditions: ISearchFilterConditions,
  mainEntity: any,
  app: any,
  indexName: any,
  retailer: any,
  widget: Widget,
  entityConditions: Conditions,
  onFirstPageAndCount: boolean,
  aggregationFieldsForResultCount: any,
  mainTimePeriodRangeFilters: RangeFilter[],
  aggregationFilters: ISearchFilterConditions,
  sortDirection: string | undefined,
  currentSortFieldName: string,
  scheduledActionTermFilters: any[] | TermFilter[],
  customTermFilters: any,
  pageNumber: number,
  entitySearchService: any,
  dataSet: any,
  searchRequestOverrides: ISearchRequestOverride[],
  pageSizeOverride: number,
  dataForGroupByField: DataForGroupByField
): Promise<IGridResultData> {
  const { statePropertyName } = widget.data;
  const fetchMetricsParams: IFetchEntityMetricsAndParseResponseParams = {
    statePropertyName,
    mainEntity,
    retailer,
    app,
    indexName,
    widget,
    pageSizeOverride,
    searchRequestOverrides
  };

  switch (groupByField.name) {
    case 'portfolioId': {
      const portfoliosDataFetcher = new PortfoliosDataFetcher(dispatch, cancelSource);
      const gridIntermediateData = dataForGroupByField.portfolioGridIntermediateData;
      const finalResult = await portfoliosDataFetcher.fetchPortfoliosData(
        props,
        searchSideBarConditions,
        aggregationFilters,
        mainEntity,
        app,
        indexName,
        retailer,
        groupByField,
        gridIntermediateData,
        fetchMetricsParams,
        dataSet,
        pageNumber
      );
      return finalResult;
    }
    case 'campaignId': {
      const campaignsDataFetcher = new CampaignsDataFetcher();
      const finalResult = await campaignsDataFetcher.fetchCampaignsData(
        props,
        dispatch,
        widget,
        mainEntity,
        retailer,
        app,
        entityConditions,
        cancelSource,
        searchSideBarConditions,
        indexName,
        groupByField,
        onFirstPageAndCount,
        aggregationFieldsForResultCount,
        mainTimePeriodRangeFilters,
        aggregationFilters,
        sortDirection,
        currentSortFieldName,
        scheduledActionTermFilters,
        customTermFilters,
        fetchMetricsParams,
        dataSet,
        pageNumber
      );
      return finalResult;
    }
    case 'targetingText': {
      const targetsDataFetcher = new TargetsDataFetcher();
      const finalResult = await targetsDataFetcher.fetchTargetsData(
        props,
        retailer,
        mainEntity,
        searchSideBarConditions,
        dispatch,
        app,
        cancelSource,
        entitySearchService,
        widget,
        fetchMetricsParams,
        dataSet,
        pageNumber
      );
      return finalResult;
    }
    case 'stacklineSku': {
      const productsDataFetcher = new ProductsDataFetcher();
      const finalResult = await productsDataFetcher.fetchProductsData(
        mainEntity,
        customTermFilters,
        widget,
        scheduledActionTermFilters,
        onFirstPageAndCount,
        dispatch,
        retailer,
        app,
        indexName,
        aggregationFieldsForResultCount,
        mainTimePeriodRangeFilters,
        aggregationFilters,
        sortDirection,
        currentSortFieldName,
        searchSideBarConditions,
        pageNumber,
        cancelSource,
        fetchMetricsParams,
        dataSet,
        props
      );
      return finalResult;
    }
    case 'entityId': {
      const entitiesDataFetcher = new EntitiesDataFetcher();
      const finalResult = await entitiesDataFetcher.fetchEntitiesData({
        dispatch,
        fetchMetricsParams,
        dataSet,
        pageNumber,
        cancelSource
      });
      return finalResult;
    }
    default: {
      // eslint-disable-next-line no-console
      console.log(`Unsupported groupByField: ${groupByField.name}`);
      return {
        gridResult: {
          data: [],
          totalResultCount: 0
        }
      };
    }
  }
}
