/* eslint-disable react/prop-types */
/**
 * A dialog displaying a list of featured products along with information about their traffic and buttons for
 * downloading their data or creating an advanced search containing them.
 */

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { Conditions } from 'sl-api-connector/types';
import { mergeConditions } from 'sl-api-connector/search/conditions';

import { CompareBrandListLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import GenericDialog from 'src/components/common/Dialog/GenericDialog';
import {
  WaterfallNonSortableHeaderComponentFramework,
  FeaturedProductsGridProductCellRenderer
} from './CellRendererFrameworks';
import StandaloneEntityGrid from 'src/components/EntityGrid/EntityGrid/StandaloneEntityGrid';
import './WaterfallKeywordGrid.scss';
import ReduxStore from 'src/types/store/reduxStore';
import { Widget } from 'src/types/application/widgetTypes';

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    maxHeight: 640,
    overflowY: 'scroll',
    display: 'flex',
    flex: 1,
    marginLeft: 6,
    paddingLeft: 8,
    marginRight: -14
  },
  button: {
    paddingLeft: 16,
    paddingRight: 16
  },
  entityGridContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
};

const mapStateToProps = ({ mainTimePeriod, comparisonTimePeriod }: ReduxStore) => ({
  mainTimePeriod,
  comparisonTimePeriod
});

const FeaturedProductsGridInner: React.FC<
  {
    mergeCustomFilters: (mainEntityConditions: Conditions, selectedEntity?: string) => Conditions;
    selectedEntity?: string;
    widget: Widget;
    firstColumnDefOverrides?: { [key: string]: any };
    inverseComparisonTimePeriod?: boolean;
    productIds: string[];
  } & ReturnType<typeof mapStateToProps>
> = ({
  mergeCustomFilters,
  selectedEntity,
  firstColumnDefOverrides = {},
  inverseComparisonTimePeriod,
  mainTimePeriod,
  comparisonTimePeriod,
  ...rest
}) => {
  const { startWeek, endWeek } = inverseComparisonTimePeriod ? comparisonTimePeriod : mainTimePeriod;
  const mergedFirstColumnDefOverrides = {
    minWidth: 225,
    maxWidth: 275,
    headerComponentFramework: function headerComponentFramework({ ...props }: { displayName: string }) {
      return <WaterfallNonSortableHeaderComponentFramework {...props} style={{ width: '100%', textAlign: 'left' }} />;
    },
    cellRendererFramework: FeaturedProductsGridProductCellRenderer,
    ...firstColumnDefOverrides
  };

  const { DialogButtonsComponent } = rest.widget.view;

  return (
    <Fragment>
      <div style={styles.grid} className="waterfall-keyword-insights">
        <StandaloneEntityGrid
          mergeCustomFilters={(mainEntityConditions: Conditions) =>
            mergeCustomFilters(
              mergeConditions(mainEntityConditions, {
                rangeFilters: [
                  {
                    fieldName: 'weekId',
                    minValue: startWeek,
                    maxValue: endWeek
                  }
                ]
              }),
              selectedEntity
            )
          }
          firstColumnDefOverrides={mergedFirstColumnDefOverrides}
          entityGridContainerStyle={styles.entityGridContainer}
          hideGridHeader
          CustomLoading={CompareBrandListLoading}
          inverseComparisonTimePeriod={inverseComparisonTimePeriod}
          filterRows={_get(rest, ['widget', 'view', 'gridOptions', 'filterRows'], undefined)}
          {...rest}
        />
      </div>

      {DialogButtonsComponent ? <DialogButtonsComponent /> : null}
    </Fragment>
  );
};

const FeaturedProductsGrid = connect(mapStateToProps)(FeaturedProductsGridInner);

const FeaturedEntitiesDialog: React.FC<{
  selectedEntity?: string;
  name?: string;
  open: boolean;
  onClose: () => void;
  widget: Widget;
  productIds?: string[];
  mergeCustomFilters: (mainEntityConditions: Conditions, selectedEntity?: string) => Conditions;
}> = ({ selectedEntity = '', open, onClose, productIds = [], name, ...props }) => (
  <GenericDialog
    title={props.widget.view.title}
    open={open}
    onClose={onClose}
    textContent={props.widget.view.getTextContent(selectedEntity, name)}
    style={styles.root}
  >
    {/* Avoid rendering the entity grid unless we're open since that involves fetching data */}
    {open && selectedEntity ? (
      <FeaturedProductsGrid selectedEntity={selectedEntity} productIds={productIds} {...props} />
    ) : null}
  </GenericDialog>
);

export default FeaturedEntitiesDialog;
