import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { withManualBrHeader } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import { withProps } from 'src/utils/hoc';
import { buildChargeBacksSummaryWidget } from './ChargeBackSummaryLayout';
import {
  buildChargeBacksMultiMetricTrendChart,
  buildChargebackTopEntitiesChart
} from '../../util/DynamicChargebacksWidgets';
import CustomAgGridHeaderTemplate from 'src/components/EntityGrid/Table/CustomAgGridHeaderTemplate';
import AtlasProductCellFormatter from 'src/components/EntityGrid/Table/AtlasProductCellFormatter';
import { Entity } from 'sl-api-connector/types';
import { Widget as WidgetType } from 'src/types/application/widgetTypes';
import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import { EntityGridNoclipHeaderWrapper } from 'src/components/EntityGrid/HeaderComponentFrameworks/MultiLineHCF';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import EntityGrid from 'src/components/EntityGrid';
import buildShortagesSummaryWidgets from './ShortagesSummaryPageLayout';
import { buildDisputeManagementPageWidgets } from 'src/components/Layout/Beacon/OperationsPageLayout/DisputeManagementPageLayout';
import { onShortagesMode } from 'src/store/modules/user/selectors';
import ChargebackMetrics from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Operations/Chargebacks/ChargebackMetrics';
import { shouldShowNewBeacon } from 'src/utils/app';

import ChargebacksSplineChart from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Operations/Chargebacks/ChargebacksSplineChart';
import { getWidgetBarChartStyles } from 'src/components/BeaconRedesignComponents/styles/styles';
import { BEACON_GRID_TOP_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { IconsList } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';

interface PageLayoutParameters {
  app: any;
  tab: string;
  metricType: string;
  entity: Entity;
}

const buildChargeBackWidgets = ({ app, entity, dataConfig, weekIdField }: any, widgets: WidgetType[]) => {
  const indexName = 'chargebacks';
  const metricType = 'financialCharge';

  if (shouldShowNewBeacon()) {
    widgets.push({
      name: 'chargebackMetrics',
      CustomComponent: ChargebackMetrics,
      view: {}
    });
  } else {
    widgets.push(buildChargeBacksSummaryWidget({ app, entity, weekIdField, indexName, dataConfig, metricType }));
  }

  widgets.push(
    _merge(buildChargebackTopEntitiesChart({ indexName, entity, app, weekIdField }), {
      view: {
        container: {
          style: getWidgetBarChartStyles()
        }
      }
    })
  );

  if (shouldShowNewBeacon()) {
    widgets.push({
      name: 'chargebackSplineChart',
      CustomComponent: ChargebacksSplineChart,
      view: {
        container: {
          style: {
            marginBottom: `${BEACON_GRID_TOP_SPACING}px`
          }
        }
      }
    });
  } else {
    widgets.push(
      buildChargeBacksMultiMetricTrendChart({ indexName, entity, app, weekIdField, showOnlyMainSeries: false })
    );
  }

  // Table
  const aggregationFields = [
    'financialCharge',
    'chargebackRate',
    'prepCharges',
    'logisticCharges',
    'labelingCharges',
    'quantity'
  ].map((fieldName) => INDEX_FIELDS.getField(app.name, indexName, fieldName, 'product', 'stacklineSku'));
  const gridAggregationFields = aggregationFields.map((field) => ({
    ...field,
    fieldOverridesByFieldName: {},
    headerComponentFramework: withManualBrHeader(
      withProps({
        sortArrowStyle: !field.name.includes('|') ? { marginTop: '1em' } : undefined
      })(CustomAgGridHeaderTemplate)
    ),
    cellRenderFramework: AtlasProductCellFormatter,
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  }));

  widgets.push({
    name: 'entityGrid',
    CustomComponent: EntityGrid,
    view: _merge({ iconsList: [IconsList.tile, IconsList.table] }, VIEWS.entityGrid, {
      WrapperComponent: EntityGridNoclipHeaderWrapper,
      gridOptions: {
        titleOptions: {
          product: 'Products - Financial Charge'
        }
      }
    }),
    data: {
      defaultSortField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
      groupByFields: [INDEX_FIELDS.getField(app.name, 'sales', 'stacklineSku', 'product')],
      configByGroupByFieldName: {
        stacklineSku: {
          indexName,
          entity: ENTITIES.atlas.product,
          mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
          aggregationFields,
          tableView: {
            metricFields: gridAggregationFields
          }
        }
      }
    }
  });
};

const getOperationsPageLayout = ({ app, tab, metricType, entity }: PageLayoutParameters) => {
  const indexName = tab;
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
  const retailerIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'));

  // Use indexName unless testing with shortages, then use shortagesIndexName
  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.beacon.retailer),
    categoryEntity: _cloneDeep(ENTITIES.beacon.category),
    relatedEntity: _cloneDeep(ENTITIES.beacon.brand),
    weekIdField,
    retailerIdField
  };
  const widgets: WidgetType[] = [];
  const enableComparision = true;
  const subscribedToShortages = onShortagesMode();
  switch (metricType) {
    case 'chargebacks':
      buildChargeBackWidgets({ app, entity, dataConfig, weekIdField }, widgets);
      break;
    case 'shortages':
      if (subscribedToShortages) {
        buildShortagesSummaryWidgets({ app, entity, dataConfig, weekIdField }, widgets);
      }
      break;

    case 'disputes':
      buildDisputeManagementPageWidgets(widgets);
      break;

    default:
      break;
  }

  return {
    CustomPageContainer: GenericPageContainer,
    enableComparision,
    dataConfig,
    widgets
  };
};

export default getOperationsPageLayout;
