import React, { useCallback, useEffect } from 'react';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import { useCreateRemoveRetailPriceRangeFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { PromotionMetricCards } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/Promotions/PromotionMetricCards';
import { PromotionCountDonutChart } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/Promotions/Visualizations/PromotionCountDonutChart';
import { PromotionsByWeekTable } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/Promotions/Visualizations/PromotionsByWeekTable';
import PromotionsDealTypeColumnChart from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/Promotions/Visualizations/PromotionsDealTypeColumnChart';
import { PromotionsProductTable } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/Promotions/Visualizations/PromotionsProductTable';
import PromotionsTopEntityChart from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/Promotions/Visualizations/PromotionsTopEntityChart';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_SUBTABS, BEACON_TABS } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { EntityPathname } from 'src/types/application/types';
import { useAppSelector, useHistory } from 'src/utils/Hooks';
import { useProductEntity } from 'src/utils/Hooks/reduxSelectors';
import { shouldShowPromotionPage, updateUrlQueryParams } from 'src/utils/app';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';

/**
 * Promotions Summary Page under the Prices tab.
 */
const PromotionsSummaryPage = () => {
  const removeRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();
  const history = useHistory();
  const { isProductEntity } = useProductEntity();
  const hashId = useAppSelector((state) => state.user.config.vendor.hashId);

  // Redirect to the correct tab if the user is not allowed
  useEffect(() => {
    if (!shouldShowPromotionPage()) {
      history.push(
        updateUrlQueryParams(
          { tab: BEACON_TABS.SALES, subtab: BEACON_SUBTABS.KEY_METRICS },
          { path: EntityPathname.Client, routeParam: hashId }
        )
      );
    }
  }, []);

  const renderView = useCallback(() => {
    if (isProductEntity) {
      return (
        <BeaconPageContainer>
          <Flex gap="lg" flexDirection="column">
            <CommonSummaryInfoSubtitle title="Promotion Count" />
            <PromotionMetricCards />
          </Flex>
          <Flex marginTop="80px" gap={BEACON_CHART_SPACING} flexDirection="column">
            <PromotionsDealTypeColumnChart />
            <PromotionsByWeekTable />
          </Flex>
        </BeaconPageContainer>
      );
    } else {
      return (
        <BeaconPageContainer>
          <Flex gap={BEACON_CHART_SPACING} flexDirection="column">
            <Flex gap="lg" flexDirection="column">
              <CommonSummaryInfoSubtitle title="Promotion Count" />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <PromotionCountDonutChart />
                <div
                  style={{
                    width: '624px'
                  }}
                >
                  <BeaconComparableSplineChart
                    indexName={BEACON_INDEX_NAMES.PROMOTIONS}
                    fieldName="stacklineSku"
                    showTitleSummary={false}
                    splineChartLoadingProps={{
                      width: 543
                    }}
                    chartOptionsOverride={{
                      chart: {
                        height: 300
                      }
                    }}
                    requestBuilder={removeRetailPriceRangeFilters()}
                  />
                </div>
              </div>
            </Flex>
            <PromotionsTopEntityChart />
            <PromotionsDealTypeColumnChart />
            <PromotionsProductTable />
          </Flex>
        </BeaconPageContainer>
      );
    }
  }, [isProductEntity, removeRetailPriceRangeFilters]);

  return renderView();
};

export default PromotionsSummaryPage;
