import React, { useMemo, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useStacklineTheme } from '@stackline/ui';
import { useAppSelector, useGetRetailerUrl, useMetricFormatter } from 'src/utils/Hooks';
import ReactDOMServer from 'react-dom/server';
import { ChevronIconV2 } from 'src/components/SvgIcons';
import _get from 'lodash/get';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import _merge from 'lodash/merge';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { getProductImageUrl } from 'src/utils/image';
import { truncateWithEllipsis } from 'src/utils/stringFormatting';
import { IconRightArrow } from 'src/components/BeaconRedesignComponents/icons';
import { generateSearchLink } from 'src/utils/searchLinks';
import { useRetailerId } from 'src/utils/Hooks/reduxSelectors';
import { Box, SxProps } from '@mui/material';
import { EntityCategoryDatum } from 'src/components/BeaconRedesignComponents/TopEntityChartV2/types';

const DEFAULT_TOP_ENTITY_HEIGHT = 420;

export interface TopEntityV2ChartProps {
  /**
   * Topentity data to display
   */
  chartSeries: (Highcharts.SeriesOptionsType & { metricType?: string })[];
  forecastedData?: Highcharts.SeriesOptionsType[];
  chartOptionsOverride?: Partial<Highcharts.Options> | ((defaultOptions: Highcharts.Options) => Highcharts.Options);
  redirectToSamePage?: boolean;
  /**
   * Specify the color for each series.
   * TODO we should remove this and use chartSeries to define the colors,
   * we still need to remove old Widget bar charts across beacon pro first
   * since some of them override with an incorrect color.
   */
  seriesColors?: string[];
  /**
   * When true, (default) the bars will be left aligned, when false, the bars will be centered
   */
  leftAlignBars?: boolean;
  containerSx?: SxProps;

  /**
   * Optional formatter for the xAxis labels. Components must use ReactDOMServer.renderToStaticMarkup to render the label as stringified HTML.
   */
  xAxisFormatter?: (value: EntityCategoryDatum) => string;
}

const seriesMarker = {
  enabled: false,
  symbol: 'circle',
  states: {
    hover: {
      radius: 4,
      lineWidth: 0
    }
  }
};
/**
 * XAxisLabel for topEntityChart, ReactDOMServer.renderToStaticMarkup seems like it does not stringify nested ReactComponent, so used static html
 */
const XAxisLabel = ({
  name,
  link,
  newTab,
  id,
  retailerSku,
  showTooltip,
  retailerLink
}: {
  name: string;
  link?: string;
  newTab?: boolean;
  id: string;
  retailerSku: string;
  showTooltip: boolean;
  retailerLink: string;
}) => {
  const tooltipId = `tooltip-${id}`;
  return (
    <>
      <a
        href={link}
        target={newTab ? '_blank' : undefined}
        rel={newTab ? 'noreferrer' : undefined}
        style={{
          textDecoration: link ? undefined : 'none',
          cursor: link ? 'pointer' : undefined
        }}
      >
        <div
          style={{
            fontSize: '12px',
            textAlign: 'center',
            width: '90px',
            wordBreak: 'break-word',
            height: '70px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          role="button"
        >
          <Text variant="body4" truncateLines={4} title={name}>
            {name}
          </Text>
        </div>
      </a>
      {showTooltip && (
        <div
          id={tooltipId}
          style={{
            display: 'none',
            position: 'absolute',
            bottom: '0',
            left: '0',
            backgroundColor: '#fff',
            width: '160px',
            height: '137px',
            border: '1px solid #dedede',
            borderRadius: '4px',
            padding: '17px 12px 12px 12px'
          }}
        >
          <div style={{ display: 'flex' }}>
            <img
              src={getProductImageUrl(id)}
              alt={name}
              style={{
                maxWidth: '40px',
                maxHeight: '40px',
                objectFit: 'cover'
              }}
            />
            <div style={{ marginLeft: '12px' }}>
              <p style={{ margin: 0, fontSize: '10px', fontWeight: '500', height: '13px' }}>RETAILER SKU</p>
              <a
                target="_blank"
                rel="noreferrer"
                href={retailerLink}
                style={{ marginTop: '2px', fontSize: '10px', height: '13px' }}
              >
                {retailerSku.toLocaleUpperCase()}
              </a>
            </div>
          </div>

          <p
            style={{
              marginTop: '4px',
              marginBottom: '0',
              fontSize: '10px',
              letterSpacing: '0.07px',
              minHeight: '47px'
            }}
          >
            {truncateWithEllipsis(90, name, '...')}
          </p>

          <a
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '4px',
              fontSize: '10px',
              width: '100px',
              fontWeight: '500'
            }}
            target="_blank"
            rel="noreferrer"
            href={link}
          >
            View details {IconRightArrow}
          </a>
        </div>
      )}
    </>
  );
};

const PLOT_RENDER_TIMEOUT = 1000;
const SLIDE_STEP = 3;
const MaxDataPointsToShow = 5;

const TopEntityChartV2 = ({
  chartSeries,
  forecastedData,
  chartOptionsOverride,
  redirectToSamePage = true,
  seriesColors,
  leftAlignBars = true,
  containerSx = {},
  xAxisFormatter
}: TopEntityV2ChartProps) => {
  const theme = useStacklineTheme();
  const formatMetric = useMetricFormatter();
  const chartRef = useRef(null);
  const [rangeStart, setRangeStart] = useState(0);
  const [dataLabelsEnabled, setDataLabelsEnabled] = useState(false);
  const { searchParams, additionalParams } = useAppSelector((state) => state.app.queryParams);
  const retailerId = useRetailerId();
  const selectedChartSeries = useMemo(() => forecastedData || chartSeries, [forecastedData, chartSeries]);
  const totalDataPoints = (selectedChartSeries[0] && selectedChartSeries[0].data.length - 1) || 0;

  const { getRetailerUrl } = useGetRetailerUrl();

  // Set time out is needed to prevent PLOT shows up before the chart renders
  const handleChartLoad = () => {
    setTimeout(() => {
      setDataLabelsEnabled(true);
    }, PLOT_RENDER_TIMEOUT);
  };

  // Set time out is needed to prevent PLOT shows up before the chart renders
  const handleChartRedraw = () => {
    setTimeout(() => {
      setDataLabelsEnabled(true);
    }, PLOT_RENDER_TIMEOUT);
  };

  const shiftLeft = () => {
    if (rangeStart >= SLIDE_STEP) {
      setRangeStart(rangeStart - SLIDE_STEP);
      setDataLabelsEnabled(false);
    } else if (rangeStart > 0) {
      setRangeStart(0);
      setDataLabelsEnabled(false);
    }
  };

  const shiftRight = () => {
    const newStart = Math.min(rangeStart + SLIDE_STEP, totalDataPoints - MaxDataPointsToShow);
    if (newStart > rangeStart) {
      setRangeStart(newStart);
      setDataLabelsEnabled(false);
    }
  };

  const categoryData: EntityCategoryDatum[] = useMemo(() => {
    const currentGroupBy = _get(selectedChartSeries, [0, 'categories', 0, 'type']);
    const categoryIdMap = {};
    _get(selectedChartSeries, [0, 'categories'], []).forEach((category) => {
      categoryIdMap[category.name] = {
        id: category.id,
        retailerSku: category.retailerSku,
        stacklineSku: category.stacklineSku
      };
    });
    return _get(selectedChartSeries, [0, 'data'], []).map((row) => {
      const [displayName] = row;
      const linkId = _get(categoryIdMap, [displayName, 'id'], null);
      const retailerSku = _get(categoryIdMap, [displayName, 'retailerSku'], null);
      const stacklineSku = _get(categoryIdMap, [displayName, 'stacklineSku'], null);

      return {
        name: String(displayName),
        link:
          currentGroupBy === 'searchterm'
            ? generateSearchLink(retailerId, displayName)
            : currentGroupBy && linkId
            ? `/${currentGroupBy}/${currentGroupBy === 'product' ? linkId.toLocaleUpperCase() : linkId}${searchParams}${
                redirectToSamePage ? additionalParams : ''
              }`
            : undefined,
        newTab: currentGroupBy === 'searchterm',
        id: linkId,
        retailerSku: currentGroupBy === 'product' ? retailerSku : null,
        retailerLink: currentGroupBy === 'product' ? getRetailerUrl(stacklineSku) : null // Used for redirecting user to retailer page from the retailer sku even though we use the stackline SKU to generate it
      };
    });
  }, [additionalParams, redirectToSamePage, retailerId, searchParams, selectedChartSeries]);

  const textStyles: Highcharts.CSSObject = {
    fontFamily: theme.text.fontFamily,
    color: theme.colors.primary,
    fontSize: '12px'
  };

  const defaultOptions: Highcharts.Options = _merge(
    {
      chart: {
        animation: true,
        type: 'column',
        height: DEFAULT_TOP_ENTITY_HEIGHT,
        events: {
          load: handleChartLoad,
          redraw: handleChartRedraw
        },
        spacingTop: 0,
        spacingBottom: 0
      },
      title: {
        text: ''
      },
      tooltip: {
        enabled: false
      },

      xAxis: [
        {
          type: 'category',
          categories: categoryData,
          min: rangeStart,
          max: Math.min(
            rangeStart + MaxDataPointsToShow,
            leftAlignBars ? Math.max(5, totalDataPoints) : totalDataPoints
          ),
          tickLength: 0,
          lineColor: 'transparent',
          title: {
            text: ''
          },
          labels: {
            useHTML: true,
            formatter() {
              if (xAxisFormatter) {
                return xAxisFormatter(this.value);
              }

              const currentGroupBy = _get(selectedChartSeries, [0, 'categories', 0, 'type']);
              const showTooltip = currentGroupBy === 'product';
              const { id: entityId, name, link, newTab, retailerSku, retailerLink } = this.value;

              // contentScore and buyBox seller topEntity has no currentGroupBy and EntityId.
              if (!entityId && currentGroupBy) {
                return '';
              }

              // this will respect the empty xAxis (to make left align when data is less than  MaxDataPointsToShow)
              // e.g( highchart will always have max column value as 6 (xAxis max option), so if we have 4 data to show, highcharts will create 2, so we hide the xAxis from here
              // name === undefined flag is for align chart to the left side
              if (leftAlignBars && !currentGroupBy && name === undefined) {
                return '';
              }
              const id = `box-${entityId}`;
              // if the chart is product level, hover on xAxis will show tooltip
              if (showTooltip) {
                const tooltipId = `tooltip-${entityId}`;
                setTimeout(() => {
                  const element = document.getElementById(id);
                  const tooltipElement = document.getElementById(tooltipId);
                  if (element && tooltipElement) {
                    element.addEventListener('mouseover', () => {
                      tooltipElement.style.display = 'block';
                    });
                    element.addEventListener('mouseout', () => {
                      tooltipElement.style.display = 'none';
                    });
                  }
                }, 0);
              }

              return ReactDOMServer.renderToStaticMarkup(
                <div id={id} style={{ position: 'relative' }}>
                  <XAxisLabel
                    name={String(name)}
                    link={link}
                    newTab={newTab}
                    id={entityId}
                    retailerSku={retailerSku}
                    showTooltip={showTooltip}
                    retailerLink={retailerLink}
                  />
                </div>
              );
            },
            rotation: 0,
            step: 1,
            align: 'center',
            style: {
              ...textStyles,
              whiteSpace: 'normal',
              textOverflow: 'none'
            }
          }
        }
      ],
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      yAxis: {
        visible: false,
        gridLineWidth: 0,
        title: {
          text: ''
        },
        maxPadding: 0.1
      },
      series: [
        ...selectedChartSeries.map(({ data }, index) => ({
          // if the selected data is really new, then it returns datum[1] as undefined in value, so we need to set it to 0.
          data: data.map((datum) => (!datum[1] ? [datum[0], 0] : datum)),
          color: [_get(seriesColors, 0) || theme.colors.casper, _get(seriesColors, 1) || theme.colors.primary][index],
          marker: seriesMarker,
          enableMouseTracking: false,
          // this enables animation for highcharts
          cropThreshold: 1500,
          borderRadius: 2,
          pointPadding: 0.1,
          groupPadding: 0.17,
          pointWidth: 24
        }))
      ],
      plotOptions: {
        series: {
          borderColor: 'transparent',
          states: {
            hover: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: dataLabelsEnabled,
            allowOverlap: true,
            formatter() {
              return `${formatMetric(this.y, selectedChartSeries[0].metricType, {
                decimalPlaces: 1,
                showFullValue: selectedChartSeries[0].metricType === METRICTYPE.PERCENT
              })}`;
            },
            style: { ...textStyles },
            padding: 10,
            crop: false,
            overflow: 'none'
          }
        }
      }
    },
    typeof chartOptionsOverride === 'function' ? {} : chartOptionsOverride
  );
  const options = typeof chartOptionsOverride === 'function' ? chartOptionsOverride(defaultOptions) : defaultOptions;

  const chartWrapperHeight = Number(options?.chart?.height ?? DEFAULT_TOP_ENTITY_HEIGHT);
  const chevronTopHeight =
    chartWrapperHeight <= DEFAULT_TOP_ENTITY_HEIGHT ? 50 - (DEFAULT_TOP_ENTITY_HEIGHT - chartWrapperHeight) * 0.05 : 50;

  return (
    <>
      <Box sx={{ position: 'relative', height: chartWrapperHeight, ...containerSx }}>
        <div
          style={{
            visibility: rangeStart === 0 ? 'hidden' : 'visible',
            position: 'absolute',
            top: `${chevronTopHeight}%`,
            left: '0',
            cursor: 'pointer'
          }}
          onClick={shiftLeft}
          role="button"
        >
          <ChevronIconV2 style={{ height: '24px', width: '24px', transform: 'rotate(180deg)' }} />
        </div>
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
        <div
          style={{
            visibility: rangeStart + MaxDataPointsToShow < totalDataPoints ? 'visible' : 'hidden',
            position: 'absolute',
            top: `${chevronTopHeight}%`,
            right: '0',
            cursor: 'pointer'
          }}
          role="button"
          onClick={shiftRight}
        >
          <ChevronIconV2 style={{ height: '24px', width: '24px' }} />
        </div>
      </Box>
    </>
  );
};

export default TopEntityChartV2;
