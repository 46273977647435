import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { getLayout as getKeyMetricsPageLayout } from './KeyMetricsPageLayout';
import { getLayout as getSummaryPageLayout } from './EntitySummaryPageLayout';
import { getLayout as getActionLogPageLayout } from './ActionLogPageLayout';
import { getLayout as getOptimizationsPageLayout } from './OptimizationsPageLayout';
import { getLayout as getTargetingPageLayout } from './TargetingPageLayout';
import { getLayout as getTargetBidPageLayout } from './TargetBidPageLayout';
import { getLayout as getProductsPageLayout } from './ProductsPageLayout';
import { getLayout as getReviewsPageLayout } from './ReviewsPageLayout';
import { getLayout as getRecommendationPageLayout } from './RecommendationsPageLayout';
import { getLayout as getSchedulePageLayout } from './SchedulePageLayout';
import { getLayout as getBudgetPacingPageLayout } from './BudgetPacingPageLayout';
import { getLayout as getBudgetConstraintsPageLayout } from './BudgetConstraintsPageLayout';
import { getLayout as getIneligiblePageLayout } from './IneligiblePageLayout';
import { getLayout as getCampaignDetailsPageLayout } from './AdManagerSetting';
import { getLayout as getBenchmarkPageLayout } from './BenchmarkPageLayout';
import AdManagerLeftNav from 'src/components/AdManager/AdManagerLeftNav';
import { buildWidgetGroup } from 'src/components/Layout/LayoutUtil';
import ReduxStore from 'src/types/store/reduxStore';
import { PageLayout, Widget } from 'src/types/application/widgetTypes';
import { EventBus } from 'src/types/utils';

const layoutGetterBySubtab: {
  [subtab: string]: (args: {
    app: ReduxStore['app'];
    entity: { type: string };
    eventBus: EventBus;
  }) => Partial<PageLayout>;
} = {
  campaignSummary: getSummaryPageLayout as any,
  segmentSummary: getSummaryPageLayout as any,
  entitySummary: getSummaryPageLayout as any,
  keyMetrics: getKeyMetricsPageLayout,
  targeting: getTargetingPageLayout,
  products: getProductsPageLayout,
  reviews: getReviewsPageLayout,
  schedule: getSchedulePageLayout,
  actionLog: getActionLogPageLayout,
  targetBids: getTargetBidPageLayout,
  optimizations: getOptimizationsPageLayout,
  recommendations: getRecommendationPageLayout,
  budgetPacing: getBudgetPacingPageLayout,
  budgetConstraints: getBudgetConstraintsPageLayout,
  ineligible: getIneligiblePageLayout,
  campaignDetails: getCampaignDetailsPageLayout,
  benchmark: getBenchmarkPageLayout
};

const baseLayout = {
  containerStyle: {
    padding: '0 15px',
    display: 'flex' as const,
    margin: '30px auto 0' as const,
    flexDirection: 'column' as const,
    alignItems: 'center' as const
  },
  CustomPageContainer: GenericPageContainer,
  enableComparison: true,
  dataConfig: {},
  widgets: []
};

export const getLayoutForEntity = ({
  ...args
}: {
  app: ReduxStore['app'];
  entity: { type: string };
  subtab: string;
  eventBus: EventBus;
}): PageLayout => {
  const layoutGetter = layoutGetterBySubtab[args.subtab] || layoutGetterBySubtab.keyMetrics;
  const layout = layoutGetter ? layoutGetter(args) : {};

  const topNav: Widget = {
    name: 'adManagerLeftNav',
    CustomComponent: AdManagerLeftNav,
    view: {
      name: 'adManagerLeftNav',
      noInnerContainer: true,
      container: {
        style: {
          width: '100%',
          maxWidth: 1370,
          margin: '0 auto',
          padding: 0
        }
      }
    },
    data: {}
  };

  const width = 1370;

  const topNavWidget: Widget = buildWidgetGroup([topNav], {
    view: {
      noInnerContainer: true,
      container: {
        style: {
          width: '100%',
          maxWidth: width
        }
      }
    }
  });

  const baseWidgets = layout.widgets || [];
  const layoutWidgetsGroup: Widget = buildWidgetGroup(baseWidgets, {
    view: {
      noInnerContainer: true,
      container: {
        style: {
          width: '100%',
          maxWidth: width,
          margin: '0px auto'
        }
      }
    }
  });
  const widgets: Widget[] = [layoutWidgetsGroup];
  if (!['entitySummary', 'segmentSummary', 'campaignSummary', 'budget', 'actionLog'].includes(args.subtab)) {
    widgets.unshift(topNavWidget);
  }

  return { ...baseLayout, ...layout, widgets };
};
