import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

export const ViewAdjustmentSummaryLoading = () => {
  return (
    <Stack padding="16px 48px 16px 48px">
      <Box display="flex" flexDirection="row" justifyContent="center" gap="16px">
        <Skeleton variant="rounded" height={120} width={120}></Skeleton>
        <Skeleton variant="rounded" height={120} width={600}></Skeleton>
      </Box>

      <Box paddingTop="26px" display="flex" flexDirection="row" gap="64px">
        <Box display="flex" width={90} flexDirection="column" gap="8px">
          <Skeleton variant="rounded" height={19} width={60}></Skeleton>
          <Skeleton variant="rounded" height={19} width={90}></Skeleton>
        </Box>
        <Box display="flex" width={140} flexDirection="column" gap="8px">
          <Skeleton variant="rounded" height={19} width={30}></Skeleton>
          <Skeleton variant="rounded" height={19} width={140}></Skeleton>
        </Box>
        <Box display="flex" width={255} flexDirection="column" gap="8px">
          <Skeleton variant="rounded" height={19} width={50}></Skeleton>
          <Skeleton variant="rounded" height={19} width="100%"></Skeleton>
        </Box>
      </Box>
      <Box paddingTop="36px" display="flex" flexDirection="row" gap="32px" width="100%">
        <Box display="flex" width="100%" flexDirection="column" gap="8px">
          <Skeleton variant="rounded" height={19} width={65}></Skeleton>
          <Skeleton variant="rounded" height={45} width="100%"></Skeleton>
        </Box>
      </Box>
      <Box paddingTop="36px" display="flex" flexDirection="row" gap="32px" width="100%">
        <Box display="flex" width="100%" flexDirection="column" gap="8px">
          <Skeleton variant="rounded" height={19} width={80}></Skeleton>
          <Skeleton variant="rounded" height={19} width={400}></Skeleton>
          <Skeleton variant="rounded" height={90} width="100%"></Skeleton>
        </Box>
      </Box>
    </Stack>
  );
};

export const AdjustmentSummaryMetricsLoading = () => {
  return (
    <Stack padding="16px 48px 16px 48px">
      <Box display="flex" flexDirection="row" gap="32px" width="100%">
        <Box display="flex" width="100%" flexDirection="column" gap="10px">
          <Skeleton variant="rounded" height={19} width={80}></Skeleton>
          <Skeleton variant="rounded" height={19} width={300}></Skeleton>
          <Skeleton variant="rounded" height={19} width="100%"></Skeleton>
        </Box>
      </Box>
      <Box paddingTop="32px" display="flex" flexDirection="row" gap="32px" width="100%">
        <Box display="flex" width="100%" flexDirection="column" gap="10px">
          <Skeleton variant="rounded" height={19} width="100%"></Skeleton>
          <Skeleton variant="rounded" height={19} width="60%"></Skeleton>
          <Skeleton variant="rounded" height={19} width="80%"></Skeleton>
        </Box>
      </Box>
      <Box paddingTop="32px" display="flex" flexDirection="row" gap="32px" width="100%">
        <Box display="flex" width="100%" flexDirection="column" gap="10px">
          <Skeleton variant="rounded" height={19} width="100%"></Skeleton>
          <Skeleton variant="rounded" height={19} width="60%"></Skeleton>
          <Skeleton variant="rounded" height={19} width="80%"></Skeleton>
        </Box>
      </Box>
    </Stack>
  );
};
