/**
 * Functions for initializing `axios` and the API request environment
 */

import axios from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
// import rateLimit from 'axios-rate-limit';
import _get from 'lodash/get';

import { initializeMixpanel, track } from 'src/utils/mixpanel';

/**
 * URLs that contain these following strings are considered non-mutating (even if the request is not a GET request)
 * and will be retried upon failure.
 */
const whitelistedRetryEndpoints = [
  '/AdvancedSearch',
  '/GetConfig',
  '/GetEntityMetadata',
  '/GetLatestUserUploadRequests',
  '/UploadHighRiskKeywords',
  '/apiAdManager/adCampaigns/getTargetKeywords',
  '/apiAdManager/adCampaigns/getTargetProducts',
  '/apiAdManager/adCampaigns/getCampaignProducts',
  '/apiAdManager/keywordRecommendations/getRecommendedKeywords/',
  '/apiAdManager/scheduledActions/getScheduledActionByCampaignId',
  '/apiAdManager/scheduledActions/getScheduledActionByPortfolioId',
  '/apiAdManager/scheduledActions/getScheduledActionByEntityId'
];

const shouldRetryRequest = (err) => {
  if (err.response) {
    if (err.response.status === 401) {
      return false;
    }
  }

  if (whitelistedRetryEndpoints.find((endpoint) => err.config.url.includes(endpoint))) {
    return true;
  }

  // Fall back to default retry decision making if it's not a whitelisted URL.
  // "By default, it retries if it is a network error or a 5xx error on an idempotent request
  // (GET, HEAD, OPTIONS, PUT or DELETE)."
  return isNetworkOrIdempotentRequestError(err);
};

/**
 * This function sets up default `axios` functionality such as the base URL for API requests, default headers,
 * request authentication, and retry functionality.  It should be called before any API requests are made by the
 * application.
 *
 * @param {string} baseURL The base URL to use for API requests
 * @param {func} handleServerError A function that will be called with the status code of error response codes from
 *  the server such as 401 and 500. Used to log out on 401.
 */
export const configureAxios = (baseURL, handleServerError) => {
  axios.defaults.baseURL = baseURL;
  axios.defaults.headers.crossDomain = true;
  axios.defaults.withCredentials = true;

  // request interceptor
  // - track response time for each request: https://sabljakovich.medium.com/axios-response-time-capture-and-log-8ff54a02275d
  axios.interceptors.request.use((request) => {
    request.meta = request.meta || {};
    request.meta.requestStartedAt = new Date().getTime();
    return request;
  });

  // response interceptor
  axios.interceptors.response.use(
    (response) => {
      if (_get(response, 'config.meta.requestStartedAt')) {
        track('response time', {
          status: response.status,
          duration: new Date().getTime() - response.config.meta.requestStartedAt,
          url: response.config.url
        });
      }
      if (process.env.NODE_ENV !== 'development' && response.config.url.includes('/api/user/GetConfig')) {
        initializeMixpanel(response.data.appConfig.mixpanelApiKey);
      }
      return response;
    },
    (error) => {
      if (error.response && error.response.status) {
        const { status } = error.response;
        handleServerError(status);
        if (_get(error, 'config.meta.requestStartedAt')) {
          track('response time', {
            status: error.response.status,
            time: new Date().getTime() - error.config.meta.requestStartedAt,
            url: error.config.url
          });
        }
      }
      return Promise.reject(error);
    }
  );

  // Retry requests that fail up to three times, waiting 2.5 seconds in between retries.
  axiosRetry(axios, { retries: 3, retryCondition: shouldRetryRequest, retryDelay: () => 2.5 * 1000 });

  // rateLimit(axios, { maxRPS: 12 });
};
