import React, { useCallback } from 'react';
import useBulkUploadAdjustment from '../hooks/useUploadBulkAdjustment';
import useUpdateUserUploadBulkAdjustmentStatus from '../hooks/useUpdateUserUploadBulkAdjustmentStatus';
import useValidateBulkAdjustment from '../hooks/useValidateBulkAdjustment';
import useComputeNetImpactForBulkAdjustment from '../hooks/useComputeNetImpactForBulkAdjustment';
import { PollBulkAdjustmentResponse, UploadBulkAdjustmentPayload } from '../serverProxy/types';
import { StartPollingError, usePollingContext } from 'src/providers/PollingContextProvider';
import useServerProxy from '../hooks/useServerProxy';
import { useSnackbar } from 'src/utils/Hooks';
import { AdjustmentMetaData, BulkAdjustmentModalType, OpenBulkAdjustmentModalArgs } from '../BulkAdjustmentProvider';
import { TemplateProcessingState, TemplateProcessingStatus } from '../BulkTemplateProcessingModal';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { trackMixpanelEvent } from 'src/utils/mixpanel';
import { GenericErrorMessage } from 'src/components/BeaconRedesignComponents/common/CommonSnackbarMessages/GenericErrorMessage';

/**
 * Gets unique ID for the poller given a bulk adjustment id
 */
const getValidatePollingId = (bulkAdjustmentId: string) => `BULK_ADJ_VALIDATE_${bulkAdjustmentId}`;

/**
 * Gets unique ID for net impact poller given a bulk adjustment id
 */
const getNetImpactPollingId = (bulkAdjustmentId: string) => `BULK_ADJ_NET_IMPACT_${bulkAdjustmentId}`;

/**
 * Parses the total number of adjustments and invalid adjustments from the response.
 */
const parseValidAdjustmentCount = (response: PollBulkAdjustmentResponse) => {
  let totalAdjustments = 0;
  let invalidAdjustments = 0;

  try {
    const additionalDetails = _get(response, ['additionalDetails'], '');
    const adjustmentCounts = JSON.parse(additionalDetails);
    totalAdjustments = _get(adjustmentCounts, 'total_number_of_adjustments', 0);
    // When the number of errors is bigger than total adjustment. we just want to show the totalAdjustment number.
    invalidAdjustments = Math.min(_get(adjustmentCounts, 'error_records', 0), totalAdjustments);
  } catch (err) {
    console.error('Error parsing valid adjustment count: ', err);
    totalAdjustments = 0;
    invalidAdjustments = 0;
  }
  return { totalAdjustments, invalidAdjustments };
};

enum ProcessBulkAdjustmentErrorCode {
  VerificationFailed = 'VERIFICATION_FAILED',
  NetImpactFailed = 'NET_IMPACT_FAILED'
}

class AdjustmentValidationError extends Error {
  public readonly totalAdjustments: number;

  public readonly invalidAdjustments: number;

  public readonly validationResults: string;

  public constructor(message: string, totalAdjustments: number, invalidAdjustments: number, validationResults: string) {
    super(message);
    this.name = 'AdjustmentValidationError';
    this.totalAdjustments = totalAdjustments;
    this.invalidAdjustments = invalidAdjustments;
    this.validationResults = validationResults;
  }
}

/**
 * Extracts state logic for the template processing modal
 */
export default function useProcessBulkAdjustment({
  bulkAdjustmentIdRef,
  bulkAdjustmentModalRef,
  setState,
  openBulkAdjustmentModal,
  closeModal,
  setAdjustmentMetaData
}: {
  bulkAdjustmentIdRef: React.MutableRefObject<string | null>;
  bulkAdjustmentModalRef: React.MutableRefObject<BulkAdjustmentModalType | null>;
  setState: React.Dispatch<React.SetStateAction<TemplateProcessingState>>;
  openBulkAdjustmentModal: (args: OpenBulkAdjustmentModalArgs) => void;
  closeModal: () => void;
  setAdjustmentMetaData: React.Dispatch<React.SetStateAction<AdjustmentMetaData>>;
}) {
  const { mutateAsync: uploadBulkAdjustmentMutation } = useBulkUploadAdjustment();
  const { mutateAsync: updateUserUploadBulkAdjustmentStatusMutation } = useUpdateUserUploadBulkAdjustmentStatus();
  const { mutateAsync: validateBulkAdjustmentMutation } = useValidateBulkAdjustment();
  const { mutateAsync: computeNetImpactForBulkAdjustmentMutation } = useComputeNetImpactForBulkAdjustment();
  const { startPolling, stopPolling } = usePollingContext();
  const { pollBulkAdjustments } = useServerProxy();
  const { showSnackbar } = useSnackbar();

  /**
   * Fetches metrics for viewing adjustment
   */
  const getMetricsForViewingAdjustment = useCallback(
    async (bulkAdjustmentId: string, planType: string) => {
      try {
        const [validationResponse, netImpactResponse] = await Promise.all([
          pollBulkAdjustments({
            bulkAdjustmentId,
            planType,
            bulkAdjustmentStage: 'Validation'
          }),
          pollBulkAdjustments({
            bulkAdjustmentId,
            bulkAdjustmentStage: 'Compute',
            planType
          })
        ]);

        if (
          validationResponse[0].processingStatus === 'Success' &&
          netImpactResponse[0].processingStatus === 'Success'
        ) {
          return { validation: validationResponse[0], compute: netImpactResponse[0] };
        }
        return null;
      } catch (error) {
        console.error('Error viewing published bulk adjustment: ', error);
        trackMixpanelEvent({
          eventName: 'error viewing published bulk adjustment',
          data: {
            bulkAdjustmentId,
            planType
          }
        });
        return null;
      }
    },
    [pollBulkAdjustments]
  );

  const getStayedOnPopup = useCallback(
    (bulkAdjustmentId: string) => {
      return (
        bulkAdjustmentId === bulkAdjustmentIdRef.current &&
        bulkAdjustmentModalRef.current === BulkAdjustmentModalType.TemplateProcessing
      );
    },
    [bulkAdjustmentIdRef, bulkAdjustmentModalRef]
  );

  const incrementUploadPercent = useCallback(
    (amount = 1) => {
      // TODO we could use timers too to gradually increase the percentage
      // while polling, discuss with design
      setState((prevState) => {
        if (
          prevState &&
          (prevState.status === TemplateProcessingStatus.ResumePolling ||
            prevState.status === TemplateProcessingStatus.UploadAdjustments)
        ) {
          return {
            ...prevState,
            percent: Math.min(prevState.percent + amount, 90)
          };
        }
        return prevState;
      });
    },
    [setState]
  );

  const handleProcessingSuccess = useCallback(
    (bulkAdjustmentId: string, response: PollBulkAdjustmentResponse) => {
      const stayedOnPopup = getStayedOnPopup(bulkAdjustmentId);
      if (stayedOnPopup) {
        const { totalAdjustments } = parseValidAdjustmentCount(response);

        setState({
          status: TemplateProcessingStatus.TemplateVerified,
          totalAdjustments
        });
      } else {
        showSnackbar({
          message: 'Your bulk template has been uploaded',
          type: 'primary',
          showButton: true,
          buttonProps: {
            text: 'Continue',
            onClick: () => {
              openBulkAdjustmentModal({
                type: BulkAdjustmentModalType.SubmissionReview
              });
            }
          },
          timeout: null
        });
      }
    },
    [getStayedOnPopup, openBulkAdjustmentModal, setState, showSnackbar]
  );

  const handleVerificationError = useCallback(
    (args: {
      bulkAdjustmentId: string;
      totalAdjustments: number;
      invalidAdjustments: number;
      validationResults: string;
    }) => {
      const { bulkAdjustmentId, invalidAdjustments, totalAdjustments, validationResults } = args;
      const stayedOnPopup = getStayedOnPopup(bulkAdjustmentId);
      if (stayedOnPopup) {
        setState({
          status: TemplateProcessingStatus.VerificationFailed,
          totalAdjustments,
          invalidAdjustments,
          validationResults
        });
      } else {
        showSnackbar({
          message: 'Your bulk template has been uploaded but has errors.',
          type: 'info',
          showButton: true,
          buttonProps: {
            text: 'View Results',
            onClick: () => {
              openBulkAdjustmentModal({
                type: BulkAdjustmentModalType.TemplateProcessing,
                state: {
                  status: TemplateProcessingStatus.VerificationFailed,
                  totalAdjustments,
                  invalidAdjustments,
                  validationResults
                }
              });
            }
          }
        });
      }
    },
    [getStayedOnPopup, openBulkAdjustmentModal, setState, showSnackbar]
  );

  const pollForNetImpact = useCallback(
    (bulkAdjustmentId: string, planType: PlanTypeOption) => {
      return new Promise((resolve, reject) => {
        const netImpactPollingId = getNetImpactPollingId(bulkAdjustmentId);
        startPolling(
          netImpactPollingId,
          async () => {
            try {
              const [response] = await pollBulkAdjustments({
                bulkAdjustmentId,
                bulkAdjustmentStage: 'Compute',
                planType
              });

              // Empty array means net impact has not been kicked off.
              // This can happen if a user uploads an adjustment and then closes the browser
              if (!response) {
                await computeNetImpactForBulkAdjustmentMutation({
                  bulkAdjustmentId,
                  planType
                });
              } else if (response.processingStatus === 'Success') {
                setAdjustmentMetaData((metaData) => ({ ...metaData, compute: response }));
                stopPolling(netImpactPollingId);
                resolve(response);
              } else if (response.processingStatus === 'Error') {
                reject(ProcessBulkAdjustmentErrorCode.NetImpactFailed);
              }
            } catch (e) {
              reject(e);
            }
          },
          {
            interval: 5000,
            leading: true
          }
        );
      });
    },
    [pollBulkAdjustments, startPolling, stopPolling]
  );

  const pollForValidation = useCallback(
    (bulkAdjustmentId: string, planType: string) => {
      return new Promise<PollBulkAdjustmentResponse>((resolve, reject) => {
        const validatePollingId = getValidatePollingId(bulkAdjustmentId);
        startPolling(
          validatePollingId, // Key is unique so we can poll for multiple adjustments at once
          async () => {
            const [response] = await pollBulkAdjustments({
              bulkAdjustmentId,
              planType,
              bulkAdjustmentStage: 'Validation'
            });

            if (response.processingStatus === 'Success') {
              setAdjustmentMetaData({ validation: response, compute: null });
              stopPolling(validatePollingId);
              resolve(response);
            }
            if (response.processingStatus === 'Error') {
              stopPolling(validatePollingId);
              const { totalAdjustments, invalidAdjustments } = parseValidAdjustmentCount(response);
              const { fileUri } = response;
              reject(
                new AdjustmentValidationError(
                  'Bulk Adjustment validation failed',
                  totalAdjustments,
                  invalidAdjustments,
                  fileUri
                )
              );
            }
          },
          {
            interval: 5000,
            leading: true
          }
        );
      });
    },
    [pollBulkAdjustments, startPolling, stopPolling]
  );

  const startIncrementingPercentage = useCallback(() => {
    const INCREMENT_AMOUNT = 2; // increment by a random number up to this value
    const INCREMENT_INTERVAL = 4000; // number of ms between increments

    return setInterval(() => {
      incrementUploadPercent(Math.floor(Math.random() * INCREMENT_AMOUNT) + 1);
    }, INCREMENT_INTERVAL);
  }, [incrementUploadPercent]);

  /**
   * Polls for validation, conditionally kicks off net impact compute
   * and then polls for net impact. This is the only step to perform
   * when opening an Incomplete or Draft adjustment
   */
  const validateAndComputeNetImpact = useCallback(
    async (bulkAdjustmentId: string, planType: PlanTypeOption, computeNetImpact: boolean) => {
      const percentageTimer = startIncrementingPercentage();
      try {
        // Poll for validation status
        const response = await pollForValidation(bulkAdjustmentId, planType);

        if (computeNetImpact) {
          // Tell the backend to start computing net impact
          await computeNetImpactForBulkAdjustmentMutation({
            bulkAdjustmentId,
            planType
          });
        }

        // Wait for net impact to finish
        await pollForNetImpact(bulkAdjustmentId, planType);

        // Success messaging
        handleProcessingSuccess(bulkAdjustmentId, response);
      } catch (e) {
        if (e instanceof StartPollingError) {
          // Polling was already started, so we don't need to do anything
          return;
        }

        stopPolling(getValidatePollingId(bulkAdjustmentId));
        stopPolling(getNetImpactPollingId(bulkAdjustmentId));

        if (e instanceof AdjustmentValidationError) {
          handleVerificationError({
            bulkAdjustmentId,
            totalAdjustments: e.totalAdjustments,
            invalidAdjustments: e.invalidAdjustments,
            validationResults: e.validationResults
          });
          return;
        }

        closeModal();
        showSnackbar({
          message: <GenericErrorMessage />,
          type: 'error'
        });
      } finally {
        clearInterval(percentageTimer);
      }
    },
    [
      closeModal,
      computeNetImpactForBulkAdjustmentMutation,
      handleProcessingSuccess,
      handleVerificationError,
      pollForNetImpact,
      pollForValidation,
      showSnackbar,
      startIncrementingPercentage,
      stopPolling
    ]
  );

  /**
   * Uploads the bulk adjustment file, validates it and computes net impact
   */
  const processUploadBulkAdjustment = useCallback(
    async (payload: UploadBulkAdjustmentPayload) => {
      const percentageTimer = startIncrementingPercentage();

      // Upload the adjustment to the server
      const { bulkAdjustmentId } = await uploadBulkAdjustmentMutation(payload);
      bulkAdjustmentIdRef.current = bulkAdjustmentId;

      // Tell backend we've uploaded the adjustment
      await updateUserUploadBulkAdjustmentStatusMutation({
        ..._omit(payload, 'bulkAdjustmentFile'),
        bulkAdjustmentId,
        status: 'Success'
      });

      // Tell the backend to start validating the adjustment
      await validateBulkAdjustmentMutation({
        planType: payload.planType,
        bulkAdjustmentId
      });

      clearInterval(percentageTimer);
      await validateAndComputeNetImpact(bulkAdjustmentId, payload.planType, true);
    },
    [
      bulkAdjustmentIdRef,
      startIncrementingPercentage,
      updateUserUploadBulkAdjustmentStatusMutation,
      uploadBulkAdjustmentMutation,
      validateAndComputeNetImpact,
      validateBulkAdjustmentMutation
    ]
  );

  return { processUploadBulkAdjustment, validateAndComputeNetImpact, getMetricsForViewingAdjustment };
}
