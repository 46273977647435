/* eslint react/prop-types: 0 */
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import ChipsContainer from 'src/components/Search/AdvancedSearch/ChipsContainer';
import CustomizedCheckbox from 'src/components/common/CustomizedCheckBox/CustomizedCheckBox';

import SuggestionSearch from 'src/components/Omni/Search/SuggestionSearch/SuggestionSearch';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { debounce } from 'lodash';
import OmniClearButton from 'src/components/Omni/Search/OmniClearButton';

interface OmniKeywordSearchProps {
  idFieldName: string;
  headerDisplayName: string;
  searchFieldHintText: string;
  keywords: { i: string }[];
  showIncludeExclude: boolean;
  supportAutoComplete: boolean;
  bothUncheck: boolean;
  includeKeyWord: boolean;
  handleIncludeExclude: (checkedType: string, checkedValue: boolean) => void; // include or exclude check box function
  onKeywordsChange: (newChange: { key: string; values: { i: string }[] }) => void; // trigger reformat url
  autoCompleteSearchFunc: (searchUrl: string) => Promise<any>;
  handleClearAll: () => void;
}

const searchParameterLimit = 500;

const OmniKeywordSearch: React.FC<OmniKeywordSearchProps> = ({
  idFieldName,
  searchFieldHintText,
  keywords,
  headerDisplayName,
  onKeywordsChange,
  supportAutoComplete,
  includeKeyWord,
  bothUncheck,
  showIncludeExclude,
  handleIncludeExclude,
  autoCompleteSearchFunc,
  handleClearAll
}) => {
  const [keyword, setKeyword] = useState('');
  const [keywordChips, setKeywordChips] = useState(keywords.map((k, index) => ({ key: index, i: k.i })));
  const [suggestions, setSuggestions] = useState([]);
  const debouncedSearch = useRef(
    debounce(async (searchWord: string) => {
      setSuggestions(await autoCompleteSearchFunc(searchWord));
    }, 200)
  ).current;

  useEffect(() => {
    return debouncedSearch.cancel();
  }, [debouncedSearch]);

  useEffect(() => {
    setKeywordChips(keywords.map((k, index) => ({ key: index, i: k.i })));
  }, [keywords]);

  const handleSelectionChange = (selection: { id: string; value: string }) => {
    const { value } = selection;
    const newKeywordChips = [...keywordChips, { key: keywordChips.length, i: value }];
    setKeywordChips(newKeywordChips);
    onKeywordsChange({ key: idFieldName, values: newKeywordChips.map((chip) => ({ i: chip.i })) });
    setKeyword('');
    setSuggestions([]);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };
  const handleUpdateAutoCompleteUpdate = (searchWord: string) => {
    setKeyword(searchWord);
    debouncedSearch(searchWord);
  };
  const handleRequestDelete = (key) => {
    const chipToDelete = keywordChips.map((chip) => chip.key).indexOf(key);
    keywordChips.splice(chipToDelete, 1);
    setKeywordChips(keywordChips.map((chip, index) => ({ key: index, i: chip.i })));
    onKeywordsChange({ key: idFieldName, values: keywordChips.map((chip) => ({ i: chip.i })) });
  };
  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const newKeywordChips = [...keywordChips, { key: keywordChips.length, i: keyword }];
    if (event.key === 'Enter' && keyword !== '') {
      setKeyword('');
      setKeywordChips(newKeywordChips);
      onKeywordsChange({ key: idFieldName, values: newKeywordChips.map((chip) => ({ i: chip.i })) });
    }
  };
  return (
    <div className="omni-search-form-container">
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <h4 className="sl-form-label">{headerDisplayName}</h4>
        {showIncludeExclude && (
          <>
            <CustomizedCheckbox
              InnerIcon={DoneIcon}
              OuterIcon={DoneIcon}
              styleObj={{ paddingRight: '0px', paddingBottom: '0px' }}
              checkboxProps={{
                checked: bothUncheck ? false : includeKeyWord,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  handleIncludeExclude('include', event.target.checked);
                }
              }}
            />
            <CustomizedCheckbox
              InnerIcon={CloseIcon}
              OuterIcon={CloseIcon}
              checkboxProps={{
                checked: bothUncheck ? false : !includeKeyWord,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  handleIncludeExclude('exclude', event.target.checked);
                }
              }}
            />
          </>
        )}
        <OmniClearButton data={keywordChips} handleClearAll={handleClearAll} />
      </div>

      {keywords.length > searchParameterLimit && (
        <h4 className="sl-form-label" style={{ color: 'red' }}>
          Maximum number of terms is {searchParameterLimit}
        </h4>
      )}

      {supportAutoComplete ? (
        <SuggestionSearch
          searchText={keyword}
          autoFocus={false}
          className="sl-form-input"
          style={{ height: '48px' }}
          textFieldStyle={{ height: '48px' }}
          hintText={searchFieldHintText}
          handleKeyDown={() => {}}
          headerDisplayName={headerDisplayName}
          onListItemSelection={handleSelectionChange}
          handleUpdateInput={handleUpdateAutoCompleteUpdate}
          suggestions={suggestions}
        />
      ) : (
        <TextField
          variant="standard"
          autoComplete="off"
          className="sl-form-input"
          placeholder={searchFieldHintText}
          type="text"
          name={headerDisplayName}
          id={headerDisplayName}
          disabled={keywords.length > searchParameterLimit}
          style={{ width: '100%' }}
          value={keyword}
          onChange={handleInputChange}
          onKeyPress={onKeyPress}
        />
      )}

      <ChipsContainer onDelete={(key) => handleRequestDelete(key)} chipData={keywordChips} />
    </div>
  );
};

export default OmniKeywordSearch;
