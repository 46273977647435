import { Box } from '@mui/material';
import { useStacklineTheme } from '@stackline/ui';
import React from 'react';
import { Link } from 'react-router-dom';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BrandLogo } from 'src/components/BeaconRedesignComponents/Generic/BrandLogo';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import SplineChart from 'src/components/BeaconRedesignComponents/SplineChart/SplineChart';
import {
  getColorForMetricChangeValue,
  getIconForMetricChange
} from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import { BEACON_PRO_ENTITY_CARD_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { weekIdToTimestamp } from 'src/utils/dateUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions';

interface SplineChartData {
  weekId: string;
  currentValue: number;
}

interface Brand {
  id: number;
  name: string;
  displayName: string;
  currentRetailSales: number;
  retailSalesChange: number;
  retailSalesChangePercentage: number;
  currentSplineChartData: SplineChartData[];
  currentSplineChartComparisonData: SplineChartData[];
}

interface BrandCardProps {
  val: Brand;
}

/**
 * Generic container Category Card
 */
const BrandCard: React.FC<BrandCardProps> = ({ val }) => {
  const theme = useStacklineTheme();
  const {
    displayName,
    id,
    currentRetailSales,
    retailSalesChange,
    retailSalesChangePercentage,
    currentSplineChartData,
    currentSplineChartComparisonData
  } = val;
  const { searchParams, additionalParams } = useAppSelector((state) => state.app.queryParams);
  const formatMetric = useMetricFormatter();

  const chartPrimaryData = (currentSplineChartData || [])
    .map((data) => [weekIdToTimestamp(Number(data.weekId)), data.currentValue])
    .sort((a, b) => a[0] - b[0]);

  const chartSecondaryData = (currentSplineChartComparisonData || [])
    .map((data) => [weekIdToTimestamp(Number(data.weekId)), data.currentValue])
    .sort((a, b) => a[0] - b[0]);

  return (
    <div
      style={{
        width: BEACON_PRO_ENTITY_CARD_WIDTH,
        minHeight: '415px',
        borderRadius: theme.spacing.sm,
        border: `1px solid ${theme.colors.primaryGray}`,
        position: 'relative'
      }}
    >
      <Flex
        flexDirection="column"
        sx={{
          paddingY: theme.spacing.md
        }}
      >
        <Flex
          sx={{
            paddingX: theme.spacing.md
          }}
        >
          <div
            style={{
              width: theme.spacing.xl,
              height: theme.spacing.xl
            }}
          >
            <Link to={`/brand/${id}${searchParams}${additionalParams}`}>
              <BrandLogo brandId={id} displayName={val.displayName} elementCardVariant="medium" />
            </Link>
          </div>
        </Flex>
        <Flex padding={`${theme.spacing.sm} ${theme.spacing.md} ${theme.spacing.md}`}>
          <Link to={`/brand/${id}${searchParams}${additionalParams}`}>
            <Text variant="subtitle1" sx={{ fontSize: '18px' }}>
              {displayName}
            </Text>
          </Link>
        </Flex>
        <Box style={{ marginTop: '40px' }}>
          <SplineChart
            preview={{ width: +BEACON_PRO_ENTITY_CARD_WIDTH.replace('px', ''), height: 115 }}
            primaryData={chartPrimaryData}
            secondaryData={chartSecondaryData}
            metricType={METRICTYPE.MONEY}
            removeSpacing
          />
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        sx={{
          paddingX: theme.spacing.md
        }}
      >
        <div style={{ position: 'absolute', bottom: theme.spacing.md }}>
          <Flex flexDirection="column" gap="xs" justifyContent="center">
            <Link to={`/brand/${id}${searchParams}&tab=sales&subtab=retailSales`}>
              <Text transform="uppercase" variant="subtitle2">
                Retail Sales
              </Text>
            </Link>
            <Flex justifyContent="center">
              <Text variant="body1">
                {formatMetric(currentRetailSales, METRICTYPE.MONEY, {
                  decimalPlaces: 1,
                  showFullValue: false
                })}
              </Text>
              <Flex
                alignItems="center"
                sx={{
                  paddingX: theme.spacing.xs
                }}
              >
                {getIconForMetricChange(retailSalesChange)}
                <Flex
                  sx={{
                    paddingX: theme.spacing.xs
                  }}
                >
                  <Text variant="subtitle2" color={getColorForMetricChangeValue(retailSalesChangePercentage)}>
                    {' '}
                    {formatMetric(Math.abs(retailSalesChange), METRICTYPE.MONEY, {
                      decimalPlaces: 1,
                      showFullValue: false
                    })}{' '}
                    (
                    {formatMetric(Math.abs(retailSalesChangePercentage), METRICTYPE.PERCENT, {
                      decimalPlaces: 1,
                      showFullValue: true,
                      showNegative: true
                    })}
                    )
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </div>
      </Flex>
    </div>
  );
};

export default BrandCard;
