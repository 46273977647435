import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';

import Creators from './actions';
import ReduxStore from 'src/types/store/reduxStore';
import { omniCategoriesAction } from 'src/store/modules/omni/omniCategories';
import { omniSubcategoriesAction } from 'src/store/modules/omni/omniSubcategories';
import { omniKeywordCategoriesActions } from 'src/store/modules/omni/omniKeywordCategories';
import { omniCountriesFollowingAction } from 'src/store/modules/omni/omniCountries';
import { omniRegionsFollowingAction } from 'src/store/modules/omni/omniRegions';

const { receiveOmniBrandsFollowing } = Creators;
const { receiveOmniCategories } = omniCategoriesAction.default;
const { receiveOmniSubcategories } = omniSubcategoriesAction.default;
const { receiveKeywordOmniCategories } = omniKeywordCategoriesActions.default;
const { receiveOmniCountries } = omniCountriesFollowingAction.default;
const { receiveOmniRegions } = omniRegionsFollowingAction.default;

export const fetchOmniBrandsFollowing = () => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
  const response = (await axios.get(`/omni/users/fetchUserMetadata`)) as any;
  if (response && response.status === 200) {
    const { brands, categories, subCategories, keywordCategories, countries, regions } = response.data;
    dispatch(receiveOmniCategories(categories));
    dispatch(receiveOmniRegions(regions));
    dispatch(receiveOmniSubcategories(subCategories));
    dispatch(receiveOmniCountries(countries));
    dispatch(receiveOmniBrandsFollowing(brands));
    dispatch(receiveKeywordOmniCategories(keywordCategories));
  }
};
