import React, { useState } from 'react';
import { ProductEntity } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/types';
import ProductListContainer from 'src/components/BeaconRedesignComponents/MetricList/ProductListContainer';
import ProductCell from 'src/components/BeaconRedesignComponents/ProductCell/ProductCell';
import useRetailSalesSortedSkus from 'src/serverProxy/useRetailSalesSortedSkus';

/**
 * Loss Rate by Product grid on the buy box page
 */
export default function BuyBoxLossRateByProductGrid() {
  const [page, setPage] = useState(1);

  // Get SKUs sorted by retail sales so we can use it as a condition for
  // fetching the content score
  const {
    isLoading: skusLoading,
    skuIndexMap,
    slicedSkus
  } = useRetailSalesSortedSkus({
    pageNumber: page,
    aggregatePages: true
  });

  return (
    <ProductListContainer
      loading={skusLoading}
      headColumnDefinition={{
        headerName: 'Products',
        valueFormatter: (_, row: { product: ProductEntity }) => (
          <div style={{ width: '650px' }}>
            <ProductCell
              key={row.product.stacklineSku}
              stacklineSku={row.product.stacklineSku}
              title={row.product.title}
            />
          </div>
        )
      }}
      useTableDataProps={{
        useGenericAdvancedSearchArgs: {
          shouldPerformFetch: slicedSkus.length > 0 && !skusLoading
        },
        buildRequest: (builder) => {
          return builder.addConditionTermFilter('stacklineSku', 'should', slicedSkus);
        },
        pageNumber: 1
      }}
      entityTableHeaderProps={{
        title: 'Loss Rate by Product'
      }}
      fields={[
        {
          name: 'buyBoxWeighted',
          displayInTable: false
        },
        {
          name: 'lossPercentage',
          displayName: 'Buy Box - Loss Rate'
        }
      ]}
      indexName="buybox"
      sortFieldName="buyBoxWeighted"
      mainFieldName="lossPercentage"
      postProcessData={(data: { product: ProductEntity }[]) => {
        const res = [...data].sort((a, b) => {
          const aIndex = skuIndexMap[a.product.stacklineSku.toLowerCase()];
          const bIndex = skuIndexMap[b.product.stacklineSku.toLowerCase()];
          return aIndex - bIndex;
        });
        return res;
      }}
      onChangePage={(args) => {
        setPage(args.chunkedPage);
      }}
    />
  );
}
