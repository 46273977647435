import React, { useCallback, useState, useMemo } from 'react';
import ChartComparisonProvider, { useChartComparison } from './ChartComparisonProvider';
import BeaconMetricSplineChart, { BeaconMetricSplineChartProps } from './BeaconMetricSplineChart';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import { INDEX_FIELDS, METRICTYPE } from 'src/utils/entityDefinitions';
import { calculatePercentChange, getAppName } from 'src/utils/app';
import {
  ComparisonPeriod,
  WeekRange,
  useAppSelector,
  useComparisonPeriod,
  useMetricFormatter,
  useWeekRange
} from 'src/utils/Hooks';
import { SlDropdownMenuOption, useStacklineTheme } from '@stackline/ui';
import ComparePopup from '../BeaconChartWithLegend/ComparePopup/ComparePopup';
import ReactDOMServer from 'react-dom/server';
import HoverTooltip from '../HoverTooltip/HoverTooltip';
import { getFirstWeekIdOfYear, timestampToWeekId } from 'src/utils/dateUtils';
import { getFormattedMetricDateLabel } from 'src/components/BeaconRedesignComponents/SplineChart/SplineChart';
import { truncateWithEllipsis } from 'src/utils/stringFormatting';
import _merge from 'lodash/merge';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { useAllSegments } from 'src/utils/Hooks/reduxSelectors';
import _last from 'lodash/last';
import _get from 'lodash/get';
import { getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import { useRemoveRetailPriceRangeFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';

function getIndexField(indexName: string, fieldName: string) {
  return INDEX_FIELDS.getField(getAppName(), indexName, fieldName);
}

/**
 * Shows text with ellipsis if the text is too long and a title
 * to display the full name on hover
 */
function TruncatedLabel({ label }: { label: string }) {
  return (
    <Text variant="body3" title={label}>
      {truncateWithEllipsis(20, label)}
    </Text>
  );
}
/**
 * Extends the base beacon metric spline chart to support comparison
 */
function BeaconComparableSplineChartInner({
  secondaryAggregationBuilder,
  requestBuilder,
  chartOptionsOverride,
  ...props
}: BeaconMetricSplineChartProps) {
  const { metricComparator, groupComparator, isCompareMode, clearCompare } = useChartComparison();
  const {
    startWeek: defaultStartWeek,
    endWeek,
    displayName: mainTimePeriodDisplayName
  } = useAppSelector((state) => state.mainTimePeriod);
  const theme = useStacklineTheme();
  const [compareOpen, setCompareOpen] = useState(false);
  const formatMetric = useMetricFormatter();
  const comparisonPeriod = useComparisonPeriod();
  const weekRange = useWeekRange();
  const mainEntityName = useAppSelector((state) => state.entityService.mainEntity.name);
  const subcategories = useAppSelector((state) => state.subCategories);
  const segments = useAllSegments();
  const removeRetailPriceRangeFilters = useRemoveRetailPriceRangeFilters(props.indexName);

  const startWeek = useMemo(() => {
    return comparisonPeriod === ComparisonPeriod.PRIOR_YEAR && weekRange === WeekRange.LAST_WEEK
      ? getFirstWeekIdOfYear()
      : defaultStartWeek;
  }, [comparisonPeriod, defaultStartWeek, weekRange]);

  const primaryField = useMemo(() => {
    return getIndexField(props.indexName, props.fieldName);
  }, [props.fieldName, props.indexName]);

  const compareSecondaryAggregationBuilder = useCallback(
    (aggregation: AggregationBuilder): AggregationBuilder => {
      // Group compare is against the same metric, so if the chart has a secondary
      // aggregation builder we should applly it
      if (secondaryAggregationBuilder && groupComparator) {
        aggregation = secondaryAggregationBuilder(aggregation);
      }

      // Compare against the same date range as primary data set
      if (isCompareMode) {
        aggregation.replaceConditionRangeFilter('weekId', startWeek, endWeek);
      }
      if (metricComparator) {
        aggregation
          .clearAggregationFields()
          .addAggregationFieldsByIndexAndField(metricComparator.indexName, metricComparator.fieldName);
      }

      // If we're not comparing anything (besides a previous time period) but we still want to override the comparison aggregations
      if (!isCompareMode && secondaryAggregationBuilder) {
        aggregation = secondaryAggregationBuilder(aggregation);
      }

      return aggregation;
    },
    [endWeek, groupComparator, isCompareMode, metricComparator, secondaryAggregationBuilder, startWeek]
  );

  const compareRequestBuilder = useCallback(
    (builder: AdvancedSearchRequestBuilder, isComparison: boolean) => {
      if (!isComparison) {
        return requestBuilder ? requestBuilder(builder, isComparison) : builder;
      }

      // If there is a custom request builder we should only apply it to group
      // compares
      if (requestBuilder && groupComparator) {
        builder = requestBuilder(builder, isComparison);
      }

      if (isCompareMode) {
        builder.clearConditionRangeFilters().addConditionRangeFilter('weekId', startWeek, endWeek);
      }

      // TODO make sure this works with global filters
      if (metricComparator) {
        builder.setSearchType(`${getAppName()}-${metricComparator.indexName}`);
      }

      /**
       * Add in filters for everything except for segments
       */
      if (groupComparator && groupComparator.groupByField !== 'segmentId') {
        builder
          .removeConditionTermFilters((termFilter) => termFilter.fieldName !== 'retailerId')
          .replaceConditionTermFilter(groupComparator.groupByField, 'should', [groupComparator.groupId]);

        // For subcategories add in the parent category
        if (groupComparator.groupByField === 'subCategoryId') {
          const subcategory = subcategories.find((subcat) => subcat.id === groupComparator.groupId);
          if (subcategory) {
            builder.replaceConditionTermFilter('categoryId', 'should', [`${subcategory.parentCategoryId}`]);
          }
        }

        // For brand level remove all other filters
        if (groupComparator.groupByField === 'brandId') {
          builder.removeConditionTermFilters(
            (termFilter) => termFilter.fieldName !== 'brandId' && termFilter.fieldName !== 'retailerId'
          );
        }
      }

      /**
       * Add in term and range filters associated with the segment
       */
      if (groupComparator && groupComparator.groupByField === 'segmentId') {
        const segment = segments.find((seg) => seg.id === groupComparator.groupId);

        if (segment) {
          builder.removeConditionTermFilters((termFilter) => termFilter.fieldName !== 'retailerId');

          if (segment.conditions.rangeFilters) {
            segment.conditions.rangeFilters.forEach((rangeFilter) => {
              builder.replaceConditionRangeFilter(rangeFilter.fieldName, rangeFilter.minValue, rangeFilter.maxValue);
            });
          }
          if (segment.conditions.termFilters) {
            segment.conditions.termFilters.forEach((termFilter) => {
              builder.replaceConditionTermFilter(termFilter.fieldName, termFilter.condition, termFilter.values);
            });
          }
          builder.apply(removeRetailPriceRangeFilters);
        }
      }

      // This allows us to apply a custom request builder to the chart even for different group, metric, or date range compares
      if (isComparison && !!requestBuilder) {
        return requestBuilder(builder, isComparison);
      }

      return builder;
    },
    [
      endWeek,
      groupComparator,
      isCompareMode,
      metricComparator,
      removeRetailPriceRangeFilters,
      requestBuilder,
      segments,
      startWeek,
      subcategories
    ]
  );

  const buildDropdownMenuOptions = useCallback(
    (series: SlDropdownMenuOption[]): SlDropdownMenuOption[] => {
      return [
        ...series,
        ...(isCompareMode
          ? [
              {
                id: 'editCompare',
                label: 'Edit compare'
              },
              {
                id: 'clearCompare',
                label: 'Clear compare'
              }
            ]
          : [
              {
                id: 'compare',
                label: 'Compare'
              }
            ])
      ];
    },
    [isCompareMode]
  );

  const dropdownHandler = useCallback(
    (option: SlDropdownMenuOption, defaultDropdownHandler: () => Promise<void>) => {
      if (['compare', 'editCompare'].includes(option.id)) {
        setCompareOpen(true);
      } else if (option.id === 'clearCompare') {
        clearCompare();
      } else {
        defaultDropdownHandler();
      }
    },
    [clearCompare]
  );

  const comparisonAxisTitle = useMemo(() => {
    if (metricComparator) {
      return getIndexField(metricComparator.indexName, metricComparator.fieldName).displayName;
    }
    return '';
  }, [metricComparator]);

  const formatComparisonYAxisLabel = useCallback(
    (value: number) => {
      if (metricComparator) {
        const comparisonIndexField = getIndexField(metricComparator.indexName, metricComparator.fieldName);
        return `${formatMetric(value, comparisonIndexField.metricType, {
          showFullValue: false,
          showNegative: true,
          decimalPlaces: 2
        })}`;
      }
      return '';
    },
    [formatMetric, metricComparator]
  );

  return (
    <>
      <BeaconMetricSplineChart
        requestBuilder={compareRequestBuilder}
        secondaryAggregationBuilder={compareSecondaryAggregationBuilder}
        comparisonFieldName={metricComparator ? metricComparator.fieldName : undefined}
        comparisonIndexName={metricComparator ? metricComparator.indexName : undefined}
        isComparisonDataCustom={isCompareMode}
        beaconChartWithLegendOverride={(beaconChartProps, metricByWeekIdValues) => {
          if (isCompareMode) {
            const lastWeekId = _get(_last(metricByWeekIdValues.primaryData), 0);
            const lastComparisonValue = _get(
              metricByWeekIdValues.secondaryData.find((row) => row[0] === lastWeekId),
              1
            );
            const metricCompareField = metricComparator
              ? getIndexField(metricComparator.indexName, metricComparator.fieldName)
              : null;

            const fieldForFormat = metricComparator ? metricCompareField : primaryField;

            const formattedLastComparisonValue = formatMetric(lastComparisonValue, fieldForFormat.metricType, {
              showFullValue: fieldForFormat.metricType === METRICTYPE.PERCENT,
              decimalPlaces: 2
            });

            const lastValuePercentChange = calculatePercentChange(
              metricByWeekIdValues.primaryTotal,
              lastComparisonValue
            );

            const groupComparatorOverrides = groupComparator
              ? _merge(
                  {
                    primaryLegendProps: {
                      legendIndicatorProps: {
                        subtitle: <TruncatedLabel label={mainEntityName} />
                      },
                      change: beaconChartProps.primaryLegendProps.change,
                      direction: beaconChartProps.primaryLegendProps.direction
                    },
                    comparisonLegendProps: {
                      legendIndicatorProps: {
                        subtitle: <TruncatedLabel label={groupComparator.name} />
                      }
                    }
                  } as const,
                  primaryField.timePeriodAggregationFunctionType === 'lastValue'
                    ? {
                        primaryLegendProps: {
                          change: `${formatMetric(lastValuePercentChange, METRICTYPE.PERCENT, {
                            showFullValue: true,
                            decimalPlaces: 2
                          })}`.replace('-', ''),
                          direction: getDirection(lastValuePercentChange)
                        },
                        comparisonLegendProps: {
                          metric: formattedLastComparisonValue
                        }
                      }
                    : {}
                )
              : {};

            const metricComparatorOverrides = {
              primaryLegendProps: {
                legendIndicatorProps: {
                  subtitle: <TruncatedLabel label={getIndexField(props.indexName, props.fieldName).displayName} />
                }
              },
              comparisonLegendProps: {
                legendIndicatorProps: {
                  subtitle: <TruncatedLabel label={comparisonAxisTitle} />
                },
                metric:
                  metricCompareField && metricCompareField.timePeriodAggregationFunctionType === 'lastValue'
                    ? formattedLastComparisonValue
                    : metricByWeekIdValues.formattedSecondaryTotal
              }
            } as const;

            return _merge(
              {
                ...beaconChartProps,
                secondaryColor: 'accentGold' as const,
                primaryLegendProps: {
                  displayName: mainTimePeriodDisplayName,
                  metric: beaconChartProps.primaryLegendProps.metric
                },
                comparisonLegendProps: {
                  displayName: mainTimePeriodDisplayName,
                  metric: beaconChartProps.comparisonLegendProps.metric
                }
              },
              groupComparator ? groupComparatorOverrides : metricComparator ? metricComparatorOverrides : {}
            );
          }
          return beaconChartProps;
        }}
        chartOptionsOverride={_merge(
          {
            series: [
              { yAxis: 0 },
              { yAxis: 0 },
              metricComparator
                ? { color: theme.colors.accentGold, yAxis: 1 }
                : { yAxis: 0, color: groupComparator ? theme.colors.accentGold : undefined }
            ],
            tooltip: metricComparator
              ? {
                  formatter() {
                    const [primaryPoint, secondaryPoint] = this.points;
                    const weekId = timestampToWeekId(primaryPoint.x);

                    const compareMetric = getIndexField(
                      metricComparator.indexName,
                      metricComparator.fieldName
                    ).metricType;

                    return ReactDOMServer.renderToStaticMarkup(
                      <HoverTooltip
                        theme={theme}
                        title={`Week ${weekId % 100}`}
                        primaryColor={theme.colors.primary}
                        primaryMetric={`${formatMetric(primaryPoint.y, primaryField.metricType, {
                          decimalPlaces: 2,
                          showFullValue: primaryField.metricType === METRICTYPE.PERCENT,
                          showNegative: true
                        })}`}
                        primaryMetricLabel={getFormattedMetricDateLabel(weekId)}
                        secondaryMetric={
                          secondaryPoint
                            ? `${formatMetric(secondaryPoint.y, compareMetric, {
                                decimalPlaces: 2,
                                showFullValue: compareMetric === METRICTYPE.PERCENT,
                                showNegative: true
                              })}`
                            : undefined
                        }
                        secondaryColor={theme.colors.accentGold}
                        secondaryMetricLabel={getFormattedMetricDateLabel(weekId)}
                      />
                    );
                  }
                }
              : undefined,
            yAxis: metricComparator
              ? [
                  {
                    title: {
                      text: getIndexField(props.indexName, props.fieldName).displayName,
                      style: {
                        color: theme.colors.primary,
                        fontFamily: 'Roboto'
                      }
                    },
                    opposite: false
                  },
                  {
                    title: {
                      text: comparisonAxisTitle,
                      style: {
                        color: theme.colors.primary,
                        fontFamily: 'Roboto'
                      }
                    },
                    labels: {
                      formatter() {
                        return formatComparisonYAxisLabel(this.value);
                      }
                    },
                    opposite: true
                  }
                ]
              : undefined
          },
          chartOptionsOverride || {}
        )}
        buildDropdownMenuOptions={buildDropdownMenuOptions}
        dropdownOptionHandler={dropdownHandler}
        splineChartProps={{
          buildPointsForTooltip: isCompareMode ? (points) => points : undefined
        }}
        {...props}
      />
      <ComparePopup open={compareOpen} onClose={() => setCompareOpen(false)} />
    </>
  );
}

/**
 * Smart chart for comparable metrics.
 */
export default function BeaconComparableSplineChart(props: BeaconMetricSplineChartProps) {
  return (
    <ChartComparisonProvider>
      <BeaconComparableSplineChartInner {...props} />
    </ChartComparisonProvider>
  );
}
