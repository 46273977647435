import { INDEX_FIELDS } from 'src/utils/entityDefinitions';

const beaconKeyMetricsConfig = {
  sales: {
    getAggregationsConfig(app, retailer, user, tab, metricType, entity, dataConfig) {
      return [
        {
          indexName: 'sales',
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', entity.type, dataConfig.retailerIdField.name),
            INDEX_FIELDS.getField(app.name, 'sales', 'wholesaleSales', entity.type, dataConfig.retailerIdField.name),
            INDEX_FIELDS.getField(app.name, 'sales', 'unitsSold', entity.type, dataConfig.retailerIdField.name),
            INDEX_FIELDS.getField(app.name, 'sales', 'instockRate', entity.type, dataConfig.retailerIdField.name)
          ],
          groupByField: dataConfig.retailerIdField
        },
        {
          indexName: 'sales',
          aggregationFields: [
            INDEX_FIELDS.getField(
              app.name,
              'sales',
              'inventoryRetailValue',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'sales',
              'inventoryWeeksOnHand',
              entity.type,
              dataConfig.retailerIdField.name
            )
          ],
          groupByField: dataConfig.retailerIdField
        },
        {
          indexName: 'sales',
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', entity.type, dataConfig.weekIdField.name),
            INDEX_FIELDS.getField(app.name, 'sales', 'instockRate', entity.type, dataConfig.weekIdField.name)
          ],
          groupByField: dataConfig.weekIdField
        },
        {
          indexName: 'advertising',
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, 'advertising', 'sales', entity.type, dataConfig.retailerIdField.name),
            INDEX_FIELDS.getField(app.name, 'advertising', 'spend', entity.type, dataConfig.retailerIdField.name),
            INDEX_FIELDS.getField(
              app.name,
              'advertising',
              'returnOnAdSpend',
              entity.type,
              dataConfig.retailerIdField.name
            )
          ],
          groupByField: dataConfig.retailerIdField
        },
        {
          indexName: 'advertising',
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, 'advertising', 'sales', entity.type, dataConfig.weekIdField.name)
          ],
          groupByField: dataConfig.weekIdField
        },
        {
          indexName: 'content',
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, 'content', 'contentScore', entity.type, dataConfig.retailerIdField.name)
          ],
          groupByField: dataConfig.retailerIdField
        },
        {
          indexName: 'content',
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, 'content', 'contentScore', entity.type, dataConfig.weekIdField.name)
          ],
          groupByField: dataConfig.weekIdField
        },
        {
          indexName: 'reviews',
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, 'reviews', 'stars', entity.type, dataConfig.weekIdField.name)
          ],
          groupByField: dataConfig.retailerIdField
        }
      ];
    }
  },
  advertising: {
    getAggregationsConfig(app, retailer, user, tab, metricType, entity, dataConfig) {
      return [
        {
          indexName: 'advertising',
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, 'advertising', 'impressions', entity.type, dataConfig.retailerIdField.name),
            INDEX_FIELDS.getField(app.name, 'advertising', 'clicks', entity.type, dataConfig.retailerIdField.name),
            INDEX_FIELDS.getField(app.name, 'advertising', 'spend', entity.type, dataConfig.retailerIdField.name),
            INDEX_FIELDS.getField(app.name, 'advertising', 'sales', entity.type, dataConfig.retailerIdField.name),
            INDEX_FIELDS.getField(app.name, 'advertising', 'unitsSold', entity.type, dataConfig.retailerIdField.name),
            INDEX_FIELDS.getField(
              app.name,
              'advertising',
              'conversionRate',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'advertising',
              'averageSellPrice',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'advertising',
              'clickThroughRate',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'advertising',
              'costPerClick',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'advertising',
              'returnOnAdSpend',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'advertising',
              'costPerAcquisition',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'advertising',
              'adCostOfSales',
              entity.type,
              dataConfig.retailerIdField.name
            )
          ],
          groupByField: dataConfig.retailerIdField
        },
        {
          indexName: 'advertising',
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, 'advertising', 'impressions', entity.type, dataConfig.weekIdField.name),
            INDEX_FIELDS.getField(app.name, 'advertising', 'sales', entity.type, dataConfig.weekIdField.name),
            INDEX_FIELDS.getField(app.name, 'advertising', 'spend', entity.type, dataConfig.weekIdField.name),
            INDEX_FIELDS.getField(app.name, 'advertising', 'returnOnAdSpend', entity.type, dataConfig.weekIdField.name)
          ],
          groupByField: dataConfig.weekIdField
        }
      ];
    }
  },
  compliance: {
    getAggregationsConfig(app, retailer, user, tab, metricType, entity, dataConfig) {
      return [
        {
          indexName: 'compliance-keywordad',
          aggregationFields: [
            INDEX_FIELDS.getField(
              app.name,
              'compliance-keywordad',
              'unauthorized',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'compliance-keywordad',
              'authorized',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'compliance-keywordad',
              'keyword',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'compliance-keywordad',
              'compliantKeywordCount',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'compliance-keywordad',
              'compliantKeywordPercent',
              entity.type,
              dataConfig.retailerIdField.name
            )
          ],
          groupByField: dataConfig.retailerIdField
        },
        {
          indexName: 'compliance-keywordaditem',
          aggregationFields: [
            INDEX_FIELDS.getField(
              app.name,
              'compliance-keywordaditem',
              'authorizedKeywordAdPercent',
              entity.type,
              dataConfig.weekIdField.name
            )
          ],
          groupByField: dataConfig.weekIdField
        },
        {
          indexName: 'compliance-keywordaditem',
          aggregationFields: [
            INDEX_FIELDS.getField(
              app.name,
              'compliance-keywordaditem',
              'unauthorized',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'compliance-keywordaditem',
              'authorizedKeywordAdPercent',
              entity.type,
              dataConfig.weekIdField.name
            )
          ],
          groupByField: dataConfig.retailerIdField
        },
        {
          indexName: 'compliance-selleritem',
          aggregationFields: [
            INDEX_FIELDS.getField(
              app.name,
              'compliance-selleritem',
              'authorizedSellerPercent',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'compliance-selleritem',
              'authorized',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'compliance-selleritem',
              'unauthorized',
              entity.type,
              dataConfig.retailerIdField.name
            )
          ],
          groupByField: dataConfig.retailerIdField
        },
        {
          indexName: 'compliance-selleritem',
          aggregationFields: [
            INDEX_FIELDS.getField(
              app.name,
              'compliance-selleritem',
              'authorizedSellerPercent',
              entity.type,
              dataConfig.weekIdField.name
            )
          ],
          groupByField: dataConfig.weekIdField
        },
        {
          indexName: 'content',
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, 'content', 'contentScore', entity.type, dataConfig.retailerIdField.name),
            INDEX_FIELDS.getField(
              app.name,
              'content',
              'timestamp', // note: this is a proxy for content violations
              entity.type,
              dataConfig.retailerIdField.name
            )
          ],
          groupByField: dataConfig.retailerIdField
        },
        {
          indexName: 'content',
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, 'content', 'contentScore', entity.type, dataConfig.weekIdField.name)
          ],
          groupByField: dataConfig.weekIdField
        },
        {
          indexName: 'contentChanged',
          aggregationFields: [
            INDEX_FIELDS.getField(
              app.name,
              'contentChanged',
              'contentAccuracy',
              entity.type,
              dataConfig.retailerIdField.name
            )
          ],
          groupByField: dataConfig.retailerIdField
        },
        {
          indexName: 'compliance-catalogitem',
          aggregationFields: [
            INDEX_FIELDS.getField(
              app.name,
              'compliance-catalogitem',
              'authorizedCatalogPercent',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'compliance-catalogitem',
              'unauthorized',
              entity.type,
              dataConfig.retailerIdField.name
            ),
            INDEX_FIELDS.getField(
              app.name,
              'compliance-catalogitem',
              'mapPriceNonCompliant',
              entity.type,
              dataConfig.retailerIdField.name
            )
          ],
          groupByField: dataConfig.retailerIdField
        },
        {
          indexName: 'compliance-catalogitem',
          aggregationFields: [
            INDEX_FIELDS.getField(
              app.name,
              'compliance-catalogitem',
              'authorizedCatalogPercent',
              entity.type,
              dataConfig.weekIdField.name
            )
          ],
          groupByField: dataConfig.weekIdField
        }
      ];
    }
  }
};

export default beaconKeyMetricsConfig;
