import React, { useMemo } from 'react';
import ChangeBarChart from 'src/components/BeaconRedesignComponents/ChangeBarChart/ChangeBarChart';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import useBeaconUnitsChange from './serverProxy/useBeaconUnitsChange';
import SplineChartLoading from 'src/components/BeaconRedesignComponents/SplineChartLoading/SplineChartLoading';
import { FORECAST_PERIOD_TO_LABEL_MAP } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/dropdowns';
import {
  getCustomForecastDateWeek,
  useForecastPeriod
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import { useMetricFormatter } from 'src/utils/Hooks';
import BeaconChartWithLegend from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import { calculateWeeksBetweenWeekIds, timestampToWeekId } from 'src/utils/dateUtils';
import { getWeekLastDay } from 'src/utils/dateformatting';
import saveAs from 'file-saver';

const fieldNameToLabelMap = {
  organicClicks: 'organic traffic',
  otherClicks: 'other traffic',
  contentScore: 'content score',
  weightedRating: 'rating & reviews',
  clicks: 'paid traffic',
  retailPrice: 'retail price',
  instockRate: 'in-stock rate',
  winPercentage: 'buy box'
};

interface ForecastChangeBarChartProps {
  fieldName?: string;
  showInsight?: boolean;
}
/**
 * Shows the total units sold change
 */
const ForecastChangeBarChart = ({ fieldName, showInsight }: ForecastChangeBarChartProps) => {
  const formatMetric = useMetricFormatter();
  const { getData, isLoading, getMetricTotal } = useBeaconUnitsChange();
  const forecastPeriod = useForecastPeriod();
  const unitsChangeData = getData(fieldName);
  const insightText = useMemo(() => {
    const metricTotal = getMetricTotal(fieldName);
    const formattedTotal = `${metricTotal >= 0 ? '+' : ''}${formatMetric(Math.abs(metricTotal), METRICTYPE.VOLUME, {
      showFullValue: false,
      decimalPlaces: 2
    })}`;

    let weekPeriod = '';
    if (FORECAST_PERIOD_TO_LABEL_MAP[forecastPeriod]) {
      weekPeriod = FORECAST_PERIOD_TO_LABEL_MAP[forecastPeriod].toLowerCase();
    } else {
      const startWeekId = getCustomForecastDateWeek('start');
      const endWeekId = getCustomForecastDateWeek('end');
      const diffInWeeks = calculateWeeksBetweenWeekIds(startWeekId, endWeekId);
      weekPeriod = `${diffInWeeks}w`;
    }

    return `The performance changes in ${
      fieldNameToLabelMap[fieldName]
    } are forecasted to generate a ${formattedTotal} ${
      metricTotal >= 0 ? 'increase' : 'decrease'
    } in units sold during the ${weekPeriod} period.`;
  }, [fieldName, forecastPeriod, formatMetric, getMetricTotal]);

  const convertSeriesToDelimitedData = () => {
    try {
      const header = ['WeekId', 'Week End Date', 'Units Sold'];
      const fileName = fieldNameToLabelMap[fieldName]
        ? `${fieldNameToLabelMap[fieldName]} units sold change`
        : 'units sold change';

      const unitsSoldDataArray = unitsChangeData.map((datum) => {
        const weekId = timestampToWeekId(datum[0]);
        const weekEndDate = getWeekLastDay(weekId, true);
        return [weekId, `"${weekEndDate}"`, Math.round(datum[1])];
      });

      const csvData = [header, ...unitsSoldDataArray].map((row) => row.join(',')).join('\n');

      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, fileName);
    } catch (e) {
      console.error('error with exporting forecast units sold chart', e);
    }
    return Promise.resolve();
  };

  return (
    <BeaconChartWithLegend
      title="Units Sold Change"
      loading={isLoading}
      loadingComponent={<SplineChartLoading />}
      subtitle={showInsight ? insightText : undefined}
      convertSeriesToDelimitedData={convertSeriesToDelimitedData}
    >
      <ChangeBarChart data={unitsChangeData} metricType={METRICTYPE.VOLUME} />
    </BeaconChartWithLegend>
  );
};

export default ForecastChangeBarChart;
