import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import queryString from 'qs';
import _pick from 'lodash/pick';
import { withBus } from 'react-bus';

import { encodeHash } from 'src/utils/hashIds';
import * as appOperations from 'src/store/modules/app/operations';
import { authOperations } from 'src/store/modules/auth';
import { trackLogOut } from 'src/utils/mixpanel';
import DownloadList from 'src/components/Export/DownloadList';
import {
  LogoutIcon,
  AdvertisingIcon,
  BillingWhiteIcon,
  DocumentIcon,
  LogoWhite,
  NavBrandIcon,
  NavCategoryIcon,
  SegmentIcon,
  EntityIcon,
  SearchTermListIcon,
  CampaignBuilder,
  CampaignsIcon,
  CalendarIcon,
  CloudIcon,
  GlobeIcon,
  ShopBagIcon,
  StoresIcon,
  SurfaceIcon
} from 'src/components/SvgIcons';
import * as AdCampaignBuilderOperations from 'src/store/modules/adManager/adCampaignBuilder/operations';
import updateNavSidebarVisibility from 'src/store/modules/navSidebar/operations';
import NavLinkWrapper from 'src/components/common/NavLinkWrapper';
import { onPrimeMode, onShortagesMode } from 'src/store/modules/user/selectors';
import NavButtonWrapper from 'src/components/common/Buttons/NavButtonWrapper';
import { Navigation } from '.';
import './Navigation.scss';
import NavIconWithTooltip from './NavIconWithTooltip';
import ReduxStore from 'src/types/store/reduxStore';
import { ActivatedActionCreators } from 'src/types/utils';
import { calculateNewQueryParams, getValidRetailerId } from 'src/store/modules/app/selectors';
import { store } from 'src/main';
import userOperations from 'src/store/modules/user/actions';
import { isDrive } from 'src/utils/app';
import { AppName } from 'sl-api-connector';
import _get from 'lodash/get';
import { churnZeroLogout } from 'src/utils/churnZero';
import { IconButton } from '@mui/material';
import DisputeStatusUpdater from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeStatusUpdater';
import { checkIsAllowedParentPlatform } from 'src/utils/browser';
import queryClient from 'src/queryClient';
import { StoreListSupportedRetailer } from 'src/store/modules/retailer/reducers';

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, [
    'app',
    'allWeekIdsByRetailerId',
    'navSidebar',
    'user',
    'retailer',
    'mainTimePeriod',
    'comparisonTimePeriod',
    'omniRetailers',
    'omniCountriesFollowing',
    'user'
  ]);

// the types of some of these are being inferred incorrectly, so I had to cast them. Hopefully
// this fixes itself as we further typescript-ify our codebase.
const mapDispatchToProps = {
  clearReduxState: appOperations.clearReduxState,
  logOut: authOperations.logOut,
  fetchConfig: appOperations.fetchConfig,
  resetAdCampaignBuilderReduxState: AdCampaignBuilderOperations.resetAdCampaignBuilderReduxState,
  updateAppNavSidebarVisibility: updateNavSidebarVisibility
};

type PrimaryNavigationContainerProps = RouteComponentProps &
  ReturnType<typeof mapStateToProps> &
  ActivatedActionCreators<typeof mapDispatchToProps> & { eventBus: any };

const SupportedAmcRetailers = [
  '1',
  '11',
  '17',
  '14',
  '15',
  '29',
  '30',
  '31',
  '33',
  '34',
  '28',
  '53',
  '16',
  '32',
  '62',
  '72',
  '77',
  '74',
  '43',
  '90',
  '103',
  '119'
];

const checkShouldSupportStoreList = (retailer: ReduxStore['omniRetailers']['data']): boolean => {
  // temp use the fixed value;
  const set = new Set(StoreListSupportedRetailer);
  return Boolean(retailer.find((r) => set.has(r.retailerId)));
};

class PrimaryNavigationContainer extends React.Component<PrimaryNavigationContainerProps> {
  public componentWillReceiveProps(nextProps: PrimaryNavigationContainerProps) {
    const { location, navSidebar, updateAppNavSidebarVisibility } = nextProps;
    const params = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const oldParams = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    if (
      location.pathname.indexOf('/account') === -1 &&
      (this.props.location.pathname !== nextProps.location.pathname ||
        params.tab !== oldParams.tab ||
        params.subtab !== oldParams.subtab)
    ) {
      updateAppNavSidebarVisibility(navSidebar!.isVisible);
    }
  }

  private handleSignOut = () => {
    const { clearReduxState, fetchConfig, logOut } = this.props;
    churnZeroLogout();
    logOut()
      .then(() => {
        trackLogOut();
        fetchConfig();
        this.props.history.push('/sign_in');
      })
      .then(() => {
        clearReduxState();
        queryClient.clear();
      })
      .catch((err: Error) => {
        throw err;
      });
  };

  // Possibly refactor this function and how the parameters are parsed currently the component passes an annoymous function that calls this one
  private isActive(options: any) {
    // TODO

    const { location } = this.props;
    if (location.pathname === '/search') {
      return false;
    }
    const params = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    return (
      options.pathname.filter((path: string) => location.pathname.indexOf(path) !== -1).length >= 1 ||
      (params.tab === 'summary' && params.entityType === options.searchParamOption) ||
      (!params.entityType && !params.tab && options.pathname[0] === 'category' && location.pathname === '/') ||
      (params.subtab && params.subtab === options.subtab) ||
      (params.tab && params.tab === options.tab) ||
      (params.tab === 'omni' && options.searchParamOption === params.selectedEntityName)
    );
  }

  private handleExportCSV = () => {
    const { eventBus } = this.props;
    eventBus.emit('exportCSV');
  };

  private handleOmniMapClick = () => {
    const { eventBus } = this.props;
    eventBus.emit('omniPrimaryNavBarMapClick');
  };

  public render() {
    const {
      app,
      allWeekIdsByRetailerId,
      navSidebar,
      location,
      user,
      retailer,
      mainTimePeriod,
      comparisonTimePeriod,
      resetAdCampaignBuilderReduxState,
      omniRetailers,
      omniCountriesFollowing
    } = this.props;

    const { searchParams } = app.queryParams;
    const isAllowedDriveItem = checkIsAllowedParentPlatform('leftNavBar');
    const isAllowedDriveCampaign = checkIsAllowedParentPlatform('leftNavBarCampaign');
    const { isVisible } = navSidebar;
    const navClassName = `navigation ${isVisible ? 'navigation--expanded' : ''}`;
    const containerClassName = `navigation__container navigation--${app.name} ${
      isVisible ? `navigation__container--expanded` : ''
    }`;
    const navLinkContainerClassName = 'navigation__item';
    const avatarClassName = `navigation__avatar ${isVisible ? 'navigation__avatar--expanded' : ''}`;
    const avatarDisplayTextClassName = `navigation__text ${
      isVisible ? 'navigation__text--visible navigation__text--avatar' : ''
    }`;
    const { vendor } = user.config;
    const displayTextClassName = `navigation__text ${isVisible ? 'navigation__text--visible' : ''}`;
    const enableExport = user && user.config && (user.config.exportEnabled || user.config.isStacklineUser);

    const { isRestrictedDemoUser } = user.config && user.config;
    const homeLinksMapping = {
      atlas: isRestrictedDemoUser
        ? `/home${searchParams}&tab=summary&subtab=atlas&entityType=category`
        : `/company/${encodeHash(0)}${searchParams}&tab=${app.defaultQueryParams.tab}&subtab=${
            app.defaultQueryParams.subtab
          }`,
      beacon: `/client/${user.config.vendor.hashId}${
        calculateNewQueryParams(
          app,
          { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
          // { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
          mainTimePeriod,
          comparisonTimePeriod
        ).searchParams
      }&tab=${app.defaultQueryParams.tab}&subtab=${app.defaultQueryParams.subtab}`,
      advertising: `/overview${searchParams}&tab=${app.defaultQueryParams.tab}&subtab=${app.defaultQueryParams.subtab}`,
      omni: `/client/${user.config.vendor.BeaconClientId}${searchParams}&tab=${app.defaultQueryParams.tab}&subtab=${app.defaultQueryParams.subtab}`,
      discover: `/discover${
        calculateNewQueryParams(
          app,
          { id: getValidRetailerId(retailer, allWeekIdsByRetailerId) },
          mainTimePeriod,
          comparisonTimePeriod
        ).searchParams
      }&tab=summary&subtab=${app.name}&discoverFilter=${['brand', 'category'].join(',')}`
      //   : `/discover${
      //       calculateNewQueryParams(app, { id: 0 }, mainTimePeriod, comparisonTimePeriod).searchParams
      //     }&tab=summary&subtab=${app.name}&discoverFilter=${['brand,category'].join(',')}&entityType=discover`
    };
    const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);

    /**
     * Return true if the user is allowlisted for AMC and
     * has at least one retailer onboarded
     */
    const hasAmcAccess = (): boolean => {
      return (
        this.props.user.config.secondaryRetailerIds &&
        this.props.user.config.secondaryRetailerIds.includes(+retailer.id)
      );
    };

    return (
      // Abstract this whole piece into the render nav component
      <Navigation navClassName={navClassName} containerClassName={containerClassName}>
        {/* Home Button */}

        <NavLinkWrapper
          containerClassName={navLinkContainerClassName}
          displayName="Home"
          displayTextClassName={displayTextClassName}
          iconExists
          icon={<LogoWhite className="navigation__item-icon" />}
          isActive={() => true}
          linkTo={homeLinksMapping[app.name]}
        />

        {/* Map */}

        {app.name === AppName.Omni && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName={omniCountriesFollowing && omniCountriesFollowing.length === 1 ? 'Country' : 'Global'}
            displayTextClassName={displayTextClassName}
            iconExists
            onClick={this.handleOmniMapClick}
            icon={
              <NavIconWithTooltip
                displayName={omniCountriesFollowing && omniCountriesFollowing.length === 1 ? 'Country' : 'Global'}
                IconComponent={GlobeIcon}
              />
            }
            isActive={() => this.isActive({ pathname: ['global'], searchParamOption: 'global' })}
            linkTo={`/home${
              calculateNewQueryParams(app, { id: 0 }, mainTimePeriod, comparisonTimePeriod).searchParams
            }&tab=omni&subtab=entitySummary&selectedEntityName=global`}
          />
        )}

        {/* Country */}
        {/* Omni removed countries summary - https://gitlab.com/stackline-dev/website/-/blob/ccfcd0df861373e2ca01ff4ff972f80ea518c22f/src/components/Navigation/PrimaryNavigationContainer.tsx#L300 */}

        {/* Retailer */}
        {app.name === AppName.Omni && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Retailers"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip IconComponent={ShopBagIcon} displayName="Retailers" />}
            isActive={() => this.isActive({ pathname: ['retailer'], searchParamOption: 'retailer' })}
            linkTo={`/home${
              calculateNewQueryParams(app, { id: retailer.id }, mainTimePeriod, comparisonTimePeriod).searchParams
            }&tab=omni&subtab=entitySummary&selectedEntityName=retailer`}
          />
        )}

        {/* Categories */}
        {app.name !== AppName.Advertising && app.name !== AppName.Discover && app.name !== AppName.Omni && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Categories"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip IconComponent={NavCategoryIcon} displayName="Categories" />}
            isActive={() => this.isActive({ pathname: ['category'], searchParamOption: 'category' })}
            linkTo={`/home${
              calculateNewQueryParams(
                app,
                // { id: getValidRetailerId(retailer, allWeekIdsByRetailerId) },
                { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
                mainTimePeriod,
                comparisonTimePeriod
              ).searchParams
            }&tab=summary&subtab=${app.name}&entityType=category`}
          />
        )}

        {app.name === AppName.Omni && !user.config.isRestrictedDemoUser && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Categories"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip IconComponent={NavCategoryIcon} displayName="Categories" />}
            isActive={() => this.isActive({ pathname: ['category'], searchParamOption: 'category' })}
            linkTo={`/home${
              calculateNewQueryParams(app, { id: retailer.id }, mainTimePeriod, comparisonTimePeriod).searchParams
            }&tab=${app.name}&subtab=entitySummary&selectedEntityName=category`}
          />
        )}

        {app.name === AppName.Omni && !user.config.isRestrictedDemoUser && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Brands"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip IconComponent={NavBrandIcon} displayName="Brands" />}
            isActive={() => this.isActive({ pathname: ['brand'], searchParamOption: 'brand' })}
            linkTo={`/home${
              calculateNewQueryParams(app, { id: retailer.id }, mainTimePeriod, comparisonTimePeriod).searchParams
            }&tab=omni&subtab=entitySummary&selectedEntityName=brand`}
          />
        )}

        {/* Omni Product SEGMENTS */}
        {app.name === AppName.Omni && !user.config.isRestrictedDemoUser && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Segments"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip IconComponent={SegmentIcon} displayName="Segments" />}
            isActive={() => this.isActive({ pathname: ['segment'], searchParamOption: 'segment' })}
            linkTo={`/home${
              calculateNewQueryParams(app, { id: retailer.id }, mainTimePeriod, comparisonTimePeriod).searchParams
            }&tab=omni&subtab=entitySummary&selectedEntityName=segment`}
          />
        )}

        {/* Omni Keyword SEGMENTS */}
        {app.name === AppName.Omni && !user.config.isRestrictedDemoUser && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Keywords"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip IconComponent={SearchTermListIcon} displayName="Keywords" />}
            isActive={() => this.isActive({ pathname: ['searchtermlist'], searchParamOption: 'searchtermlist' })}
            linkTo={`/home${
              calculateNewQueryParams(app, { id: retailer.id }, mainTimePeriod, comparisonTimePeriod).searchParams
            }&tab=omni&subtab=entitySummary&selectedEntityName=searchtermlist`}
          />
        )}

        {/* Omni Store list SEGMENTS */}
        {app.name === AppName.Omni &&
          !user.config.isRestrictedDemoUser &&
          checkShouldSupportStoreList(omniRetailers.data) && (
            <NavLinkWrapper
              containerClassName={navLinkContainerClassName}
              displayName="Store Lists"
              displayTextClassName={displayTextClassName}
              iconExists
              icon={<NavIconWithTooltip IconComponent={StoresIcon} displayName="Store Lists" />}
              isActive={() => this.isActive({ pathname: ['storeLists'], searchParamOption: 'storeLists' })}
              linkTo={`/home${
                calculateNewQueryParams(app, { id: retailer.id }, mainTimePeriod, comparisonTimePeriod).searchParams
              }&tab=omni&subtab=entitySummary&selectedEntityName=storeLists`}
            />
          )}

        {/* SEGMENTS */}
        {![AppName.Advertising, AppName.Omni, AppName.Discover].includes(app.name) &&
          !user.config.isRestrictedDemoUser && (
            <NavLinkWrapper
              containerClassName={navLinkContainerClassName}
              displayName="Segments"
              displayTextClassName={displayTextClassName}
              iconExists
              icon={<NavIconWithTooltip IconComponent={SegmentIcon} displayName="Segments" />}
              isActive={() => this.isActive({ pathname: ['segment'], searchParamOption: 'segment' })}
              linkTo={`/home${
                calculateNewQueryParams(
                  app,
                  { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
                  mainTimePeriod,
                  comparisonTimePeriod
                ).searchParams
              }&tab=summary&subtab=${app.name}&entityType=segment`}
            />
          )}

        {app.name !== 'advertising' && app.name !== 'discover' && onPrimeMode() ? (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Keyword Lists"
            displayTextClassName={displayTextClassName}
            icon={<NavIconWithTooltip IconComponent={SearchTermListIcon} displayName="Keyword Lists" />}
            iconExists
            isActive={() => this.isActive({ pathname: ['searchtermlist'], searchParamOption: 'searchtermlist' })}
            linkTo={`/home${
              calculateNewQueryParams(
                app,
                { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
                mainTimePeriod,
                comparisonTimePeriod
              ).searchParams
            }&tab=summary&subtab=${app.name}&entityType=searchtermlist`}
          />
        ) : null}

        {/* BRANDS */}
        {app.name !== 'advertising' &&
          app.name !== AppName.Omni &&
          app.name !== 'discover' &&
          !user.config.isRestrictedDemoUser && (
            <NavLinkWrapper
              containerClassName={navLinkContainerClassName}
              displayName="Brands"
              displayTextClassName={displayTextClassName}
              iconExists
              icon={<NavIconWithTooltip IconComponent={NavBrandIcon} displayName="Brands" />}
              isActive={() => this.isActive({ pathname: ['brand'], searchParamOption: 'brand' })}
              linkTo={`/home${
                calculateNewQueryParams(
                  app,
                  { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
                  mainTimePeriod,
                  comparisonTimePeriod
                ).searchParams
              }&tab=summary&subtab=${app.name}&entityType=brand`}
            />
          )}

        {/* Multi-retailer */}
        {app.name === 'atlas' &&
          Object.keys(allWeekIdsByRetailerId).filter((id) => id !== '0').length > 2 &&
          vendor.BeaconClientId === 365 && (
            <NavLinkWrapper
              containerClassName={navLinkContainerClassName}
              displayName="Retailer"
              displayTextClassName={displayTextClassName}
              iconExists
              icon={<NavIconWithTooltip displayName="Retailer" IconComponent={GlobeIcon} />}
              isActive={() => this.isActive({ pathname: ['retailer'], searchParamOption: 'retailer' })}
              linkTo={`/home${
                calculateNewQueryParams(app, { id: 0 }, mainTimePeriod, comparisonTimePeriod).searchParams
              }&tab=summary&subtab=${app.name}&entityType=retailer`}
            />
          )}

        {app.name === 'discover' && user.config.isStacklineSuperUser && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Discover"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip displayName="Discover" IconComponent={EntityIcon} />}
            isActive={() => this.isActive({ pathname: ['discover'], searchParamOption: 'discover' })}
            linkTo={`/discover${
              calculateNewQueryParams(
                app,
                { id: getValidRetailerId(retailer, allWeekIdsByRetailerId) },
                mainTimePeriod,
                comparisonTimePeriod
              ).searchParams
            }&discoverFilter=${['noSelection1', 'category'].join(',')}`}
          />
        )}
        {app.name === 'discover' && !user.config.isStacklineSuperUser && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Discover"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip displayName="Discover" IconComponent={EntityIcon} />}
            isActive={() => this.isActive({ pathname: ['discover'], searchParamOption: 'discover' })}
            linkTo={`/discover${
              calculateNewQueryParams(
                app,
                { id: getValidRetailerId(retailer, allWeekIdsByRetailerId) },
                mainTimePeriod,
                comparisonTimePeriod
              ).searchParams
            }&discoverFilter=${['brand,category'].join(',')}`}
          />
        )}

        {app.name === 'beacon' && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Campaigns"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip IconComponent={AdvertisingIcon} displayName="Campaigns" />}
            isActive={() => this.isActive({ pathname: ['campaign'], searchParamOption: 'campaign' })}
            linkTo={`/home${
              calculateNewQueryParams(
                app,
                { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
                mainTimePeriod,
                comparisonTimePeriod
              ).searchParams
            }&tab=summary&subtab=${app.name}&entityType=campaign&selectedEntityName=stacklineSku`}
          />
        )}

        {app.name === 'beacon' && Object.keys(allWeekIdsByRetailerId).filter((id) => id !== '0').length > 2 && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Retailers"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip displayName="Retailers" IconComponent={GlobeIcon} />}
            isActive={() => this.isActive({ pathname: ['retailer'], searchParamOption: 'retailer' })}
            linkTo={`/home${
              calculateNewQueryParams(app, { id: 0 }, mainTimePeriod, comparisonTimePeriod).searchParams
            }&tab=summary&subtab=${app.name}&entityType=retailer`}
          />
        )}

        {app.name === 'advertising' && isAllowedDriveCampaign && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Campaign"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip displayName="Campaigns" IconComponent={CampaignsIcon} />}
            isActive={() =>
              this.isActive({ pathname: ['brand'], searchParamOption: 'brand', subtab: 'campaignSummary' })
            }
            linkTo={`/home${searchParams}&tab=adManager&subtab=campaignSummary&selectedEntityName=campaignId`}
          />
        )}

        {app.name === 'advertising' && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Segments"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip IconComponent={SegmentIcon} displayName="Segments" />}
            isActive={() => this.isActive({ pathname: ['segment'], searchParamOption: 'segment' })}
            linkTo={`/home${searchParams}&tab=adManager&subtab=segmentSummary&entityType=segment`}
          />
        )}

        {app.name === 'advertising' && isAllowedDriveItem && !isAdAuditUser && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Budget"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip displayName="Budget" IconComponent={BillingWhiteIcon} />}
            isActive={() => this.isActive({ pathname: ['brand'], tab: 'adBudgetEditor' })}
            linkTo={`/home${searchParams}&tab=adBudgetEditor`}
          />
        )}

        {app.name === 'advertising' && isAllowedDriveItem && !isAdAuditUser && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Action Log"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip displayName="Action Log" IconComponent={DocumentIcon} />}
            isActive={() => this.isActive({ pathname: ['brand'], subtab: 'actionLog' })}
            linkTo={`/home${searchParams}&tab=adManager&subtab=actionLog`}
          />
        )}

        {app.name === 'advertising' && isAllowedDriveItem && !isAdAuditUser && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="AD Builder"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip displayName="Campaign Builder" IconComponent={CampaignBuilder} />}
            isActive={() => this.isActive({ pathname: ['brand'], tab: 'adCampaignBuilder' })}
            linkTo={`/home${searchParams}&tab=adCampaignBuilder&subtab=platformSelect`}
            onClick={() => resetAdCampaignBuilderReduxState()}
          />
        )}

        {app.name === 'advertising' && isAllowedDriveItem && !isAdAuditUser && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName="Scheduled Actions"
            displayTextClassName={displayTextClassName}
            iconExists
            icon={<NavIconWithTooltip displayName="Scheduled Actions" IconComponent={CalendarIcon} />}
            isActive={() => this.isActive({ pathname: ['brand'], tab: 'adScheduledActions' })}
            linkTo={`/home${searchParams}&tab=adScheduledActions&subtab=view`}
            onClick={() => resetAdCampaignBuilderReduxState()}
          />
        )}

        {/* AMC Connect dashboard link */}
        {app.name === 'advertising' && SupportedAmcRetailers.indexOf(retailer.id) !== -1 && (
          <NavLinkWrapper
            containerClassName={navLinkContainerClassName}
            displayName={
              hasAmcAccess()
                ? 'Amazon Marketing Cloud'
                : "You don't have Amazon Marketing Cloud integrated. Reach out to support@stackline.com to get started."
            }
            displayTextClassName={displayTextClassName}
            iconExists
            icon={
              <NavIconWithTooltip
                displayName={
                  hasAmcAccess()
                    ? 'Amazon Marketing Cloud'
                    : "You don't have Amazon Marketing Cloud integrated. Reach out to support@stackline.com to get started."
                }
                IconComponent={CloudIcon}
              />
            }
            isActive={() => this.isActive({ pathname: ['brand'], tab: 'connectDashboard' })}
            linkTo={
              hasAmcAccess() ? `/home${searchParams}&tab=connectDashboard` : `${location.pathname}${location.search}`
            }
          />
        )}

        {/*  Bottom Nav Container */}
        <div className={`navigation__bottom-container ${isVisible ? 'navigation__bottom-container--expanded' : ''}`}>
          {/* Account */}
          <NavLinkWrapper
            avatarExists
            avatarClassName={avatarClassName}
            avatarContent={
              user && user.config && user.config.firstName ? `${user.config.firstName.substr(0, 1)}` : 'Hi'
            }
            containerClassName={navLinkContainerClassName}
            displayName={user && user.session && user.session.email ? user.session.email : 'Account'}
            displayTextClassName={avatarDisplayTextClassName}
            isActive={() => location.pathname.indexOf('account') !== -1}
            linkTo={`/account/profile${searchParams}`}
            navLinkClassName="navigation__item-link navigation__item-link--avatar"
            onClick={() => {
              // for admanager, store url for redirecting after user updates their custom columns
              if (isDrive) {
                store.dispatch(userOperations.setUserRedirectionUrl(window.location.href));
              }
            }}
          />

          {/* DOWNLOAD LIST */}
          {enableExport && app.name !== AppName.Discover && (
            <div className="navigation__item navigation__item-link--tutorial">
              <div className="navigation__item-link--active">
                <DownloadList appName={app.name} />
                <div className={`navigation__text ${isVisible ? 'navigation__text--visible' : ''}`}>Downloads</div>
              </div>
            </div>
          )}

          {/* SHORTAGES POLLING */}
          {onShortagesMode() && app.name === 'beacon' ? <DisputeStatusUpdater /> : null}

          {/* EXPORT CSV FOR DISCOVER */}
          {app.name === AppName.Discover && (
            <div className="navigation__item navigation__item-link--tutorial">
              <div className="navigation__item-link--active">
                <IconButton
                  style={{
                    width: 38,
                    height: 38,
                    background: 'none',
                    display: 'inline-block',
                    verticalAlign: 'bottom',
                    padding: 0,
                    borderRadius: '50%'
                  }}
                  onClick={this.handleExportCSV}
                  size="large"
                >
                  <NavIconWithTooltip
                    IconComponent={SurfaceIcon}
                    iconStyle={{
                      width: 24,
                      height: 24,
                      stroke: 'white'
                    }}
                    displayName="Export CSV"
                  />
                </IconButton>
              </div>
            </div>
          )}

          {/* LOGOUT */}
          <NavButtonWrapper
            buttonClassName={`navigation__item-link navigation--${app.name} navigation__item-link--logout navigation__item-link--active`}
            containerClassName="navigation__item"
            primaryContent={<NavIconWithTooltip IconComponent={LogoutIcon} displayName="Log Out" />}
            secondaryContent={
              <div className={`navigation__text ${isVisible ? 'navigation__text--visible' : ''}`}>Logout</div>
            }
            onClick={this.handleSignOut}
          />
        </div>
      </Navigation>
    );
  }
}

export default withRouter(
  withBus('eventBus')(connect(mapStateToProps, mapDispatchToProps)(PrimaryNavigationContainer))
);
