import React from 'react';
import { styled } from '@mui/material';
import { formatToDollar } from 'src/utils/money';
import Chip from '@mui/material/Chip';
import _get from 'lodash/get';
import { formatDateForDisputeTable } from 'src/utils/dateUtils';

const chipColorMap = {
  'New Shortage': 'rgb(223 238 252)',
  'Initial Dispute': 'rgb(253 243 223)',
  'Contact Us Case': 'rgb(230 233 237)',
  Resolved: 'rgb(226 240 232)'
};

// Upper half of Detail Block
const DetailsContainer = styled('div')({
  minHeight: '250px',
  height: 'auto',
  width: '100%',
  border: '1px solid #dedede',
  borderRadius: '8px',
  padding: '32px',
  marginBottom: '65px'
});

const HeaderSection = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  height: '45%',
  gap: '5em',
  borderBottom: '1px solid #dedede'
});

const HeaderItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100px',
  justifyContent: 'space-evenly'
});

const Title = styled('div')({
  fontWeight: '500',
  fontSize: '17px',
  color: '#22215',
  fontFamily: 'Roboto'
});

const Divider = styled('div')({
  width: '2px',
  backgroundColor: '#dedede',
  marginBottom: '15px'
});

const ItemDetails = styled('div')({
  fontSize: '15px',
  color: '#22215',
  fontFamily: 'Roboto'
});

// Lower half of Detail Block
const ContentSection = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, fit-content(100%))',
  minHeight: '55%',
  marginTop: '20px',
  gap: '82px'
});

const ContentGroup = styled('div')({});

const ContentGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, fit-content(100%))',
  gap: '1em 0.5em'
});

const ContentTitle = styled(Title)({
  fontSize: '14px',
  marginBottom: '20px'
});

const ContentName = styled(Title)({
  fontWeight: '500',
  fontSize: '14px'
});

const MAX_ITEMS_TO_SHOW = 4; // We only want to render 4 ASINs or 4 tracking numbers at most

const DisputeDetailSummaryBlock = ({ rawData }: any) => {
  const dispute = {
    originalInvoice: '',
    invoiceDate: '',
    disputeType: '',
    disputeValue: '',
    disputeStage: '',
    disputeStatus: '',

    purchaseOrder: '',
    disputeId: '',
    asin: [],

    submittedValue: '',
    recoveredValue: '',
    lostValue: '',

    trackingNumbers: [],
    arn: '',
    carrier: ''
  };

  if (rawData) {
    dispute.originalInvoice = rawData.originalInvoice;
    dispute.invoiceDate = formatDateForDisputeTable(rawData.invoiceDate);
    dispute.disputeType = 'Shortage';
    dispute.disputeValue = formatToDollar(rawData.disputeValue);
    dispute.disputeStage = rawData.disputeStage;
    dispute.disputeStatus = rawData.disputeStatus;

    dispute.purchaseOrder = rawData.purchaseOrder;
    dispute.disputeId = rawData.disputeId;
    dispute.asin = _get(rawData, 'asin', []);

    dispute.submittedValue = formatToDollar(rawData.submittedValue);
    dispute.recoveredValue = formatToDollar(rawData.recoveredValue);
    dispute.lostValue = formatToDollar(rawData.lostValue);

    dispute.trackingNumbers = rawData.trackingNumbers;
    dispute.arn = rawData.arn;
    dispute.carrier = rawData.carrier;
  }

  return (
    <DetailsContainer>
      <HeaderSection>
        <HeaderItem>
          <Title>Original Invoice</Title>
          <ItemDetails>{dispute.originalInvoice}</ItemDetails>
        </HeaderItem>
        <HeaderItem>
          <Title>Invoice Date</Title>
          <ItemDetails>{dispute.invoiceDate}</ItemDetails>
        </HeaderItem>
        <HeaderItem>
          <Title>Dispute Type</Title>
          <ItemDetails>{dispute.disputeType}</ItemDetails>
        </HeaderItem>
        <HeaderItem>
          <Title>Shortage Value</Title>
          <ItemDetails>{dispute.disputeValue}</ItemDetails>
        </HeaderItem>
        <Divider></Divider>
        <HeaderItem>
          <Title>Dispute Stage</Title>
          <Chip label={dispute.disputeStage} sx={{ backgroundColor: chipColorMap[dispute.disputeStage] }} />
        </HeaderItem>
        <HeaderItem>
          <Title>Dispute Status</Title>
          <ItemDetails>{dispute.disputeStatus}</ItemDetails>
        </HeaderItem>
      </HeaderSection>

      <ContentSection>
        <ContentGroup>
          <ContentTitle>Invoice Details</ContentTitle>
          <ContentGrid>
            <ContentName>PO #:</ContentName>
            <div>{dispute.purchaseOrder}</div>
            <ContentName>Dispute ID:</ContentName>
            <div>{dispute.disputeId}</div>
            <ContentName>ASIN&#40;s&#41;:</ContentName>
            <div style={{ width: '190px', display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
              {dispute.asin.slice(0, MAX_ITEMS_TO_SHOW).map((item, index) => {
                const isLast = index === dispute.asin.length - 1;
                const shouldRenderComma = index < 3 && !isLast;
                return <span key={`dispute-asin:${item}`}>{`${item}${shouldRenderComma ? ', ' : ''}`}</span>;
              })}
            </div>
          </ContentGrid>
        </ContentGroup>
        <ContentGroup>
          <ContentTitle>Financial Details</ContentTitle>
          <ContentGrid>
            <ContentName>Submitted Value $:</ContentName>
            <div>{dispute.submittedValue}</div>
            <ContentName>Recovered Value $:</ContentName>
            <div>{dispute.recoveredValue}</div>
            <ContentName>Lost Value $:</ContentName>
            <div>{dispute.lostValue}</div>
          </ContentGrid>
        </ContentGroup>
        <ContentGroup style={{ marginLeft: '90px' }}>
          <ContentTitle>Shipping Details</ContentTitle>
          <ContentGrid>
            <ContentName>PRO/Tracking #:</ContentName>
            <div style={{ width: '190px', display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
              {_get(dispute, 'trackingNumbers', [])
                .slice(0, MAX_ITEMS_TO_SHOW)
                .map((item, index) => {
                  const isLast = index === dispute.trackingNumbers.length - 1;
                  const shouldRenderComma = !isLast;
                  return <span key={`dispute-trackingNum:${item}`}>{`${item}${shouldRenderComma ? ', ' : ''}`}</span>;
                })}
            </div>
            <ContentName>ARN:</ContentName>
            <div>{dispute.arn}</div>
            <ContentName>Carrier:</ContentName>
            <div>{dispute.carrier}</div>
          </ContentGrid>
        </ContentGroup>
      </ContentSection>
    </DetailsContainer>
  );
};

export default DisputeDetailSummaryBlock;
