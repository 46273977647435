import queryString from 'qs';
import axios from 'axios';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import { RouteComponentProps } from 'react-router';
import { AppName, ValueOf } from 'sl-api-connector/types';

import ReduxStore from 'src/types/store/reduxStore';
import { STACKLINE_SUPER_USER_VALIDATOR_REGEX } from './validators';
import { panic } from 'src/utils/mixpanel';
import { factory as splitFactory, store } from 'src/main';
// eslint-disable-next-line import/no-unresolved
import { IBrowserClient } from '@splitsoftware/splitio/types/splitio';
import React, { useMemo, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import _get from 'lodash/get';
import { MetricFormatterFn, UseMetricFormatterOptions } from 'src/utils/Hooks';
import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { EntityPathname } from 'src/types/application/types';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';

/**
 * This function will return an instance of `AppName` which represents the app
 * "currently" active.
 */
export function getAppName() {
  if (/ad-?(.*)\.stackline\.com/.test(window.location.hostname)) {
    return AppName.Advertising;
  } else if (
    window.location.href.indexOf('/beacon') > 0 ||
    window.location.href.indexOf('/jarvis') > 0 ||
    window.location.href.indexOf('#/b/') > 0
  ) {
    return AppName.Beacon;
  } else if (window.location.href.includes('omni')) {
    return AppName.Omni;
  } else if (window.location.href.indexOf('discover') > 0) {
    return AppName.Discover;
  } else if (window.location.href.includes('trends')) {
    return AppName.Trends;
  } else if (window.location.href.includes('pulse')) {
    return AppName.Pulse;
  } else if (window.location.href.includes('enterprise-sso')) {
    return 'EnterpriseSSO';
  }

  return AppName.Atlas;
}

export const isOmni = getAppName() === AppName.Omni;
export const isDrive = getAppName() === AppName.Advertising;

export const getAppDisplayName = (appName: AppName) => {
  const displayName = {
    [AppName.Atlas]: 'Atlas',
    [AppName.Beacon]: 'Beacon',
    [AppName.Omni]: 'Omni',
    [AppName.Advertising]: 'Drive',
    [AppName.Discover]: 'Discover',
    EnterpriseSSO: 'Sign In'
  }[appName as AppName];

  if (!displayName) {
    return panic(`Tried to get display name for invalid app name: "${appName}"`);
  }

  return displayName;
};

const colors = {
  green: '#5CC4BE',
  red: '#D85565'
};

export const getAppStage = () => {
  if (__DEV__ || __TEST__) {
    return 'dev';
  }

  const stage = /(?:atlas|beaconpro|beacon|ad|omni|discover)-?(.*)\.stackline\.com/g.exec(
    window.location.host.replace('-pro.', '.') // for beacon-pro.stackline.com
  );
  return !!stage && _isEmpty(stage[1]) ? 'prod' : 'dev';
};

export const getAppDataFrequency = (appName: AppName) => {
  const appDataFrequency = {
    [AppName.Atlas]: 'weekly',
    [AppName.Beacon]: 'weekly',
    [AppName.Omni]: 'weekly',
    [AppName.Discover]: 'weekly',
    [AppName.Advertising]: 'daily'
  }[appName as AppName];

  if (appDataFrequency) {
    return appDataFrequency;
  }
  return 'weekly';
};

export function isSuperUser(email: string) {
  return STACKLINE_SUPER_USER_VALIDATOR_REGEX.test(email);
}

export function getAppDefaultParams(location: RouteComponentProps['location']) {
  let queryParams = {};
  if (location) {
    queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  }
  if (getAppName() === AppName.Atlas || getAppName() === AppName.Discover) {
    return { ...queryParams, tab: 'scorecard', subtab: 'all' };
  }
  if (isDrive) {
    return { ...queryParams, tab: 'adManager', subtab: 'keyMetrics' };
  }
  //  temporarily routing to price page
  if (getAppName() === AppName.Omni) {
    return { ...queryParams, tab: 'scorecard', subtab: 'all' };
  }
  return { tab: 'sales', subtab: 'keymetrics', ...queryParams };
}

/**
 * Returns true if the provided `emailAddress` belongs to a Stackline user.
 *
 * @param {string} emailAddress The email address of the user
 */
export const isStacklineUser = (emailAddress: string) => /.+@stackline.com/.test(emailAddress);

/**
 * Returns true if the provided `emailAddress` belongs to a Stackline or Piwheel superuser.
 *
 * @param {string} emailAddress The email address of the user
 */
export const isReclassifyUser = (emailAddress: string) =>
  /.+superuser@stackline.com|.+superuser@piwheel.com/.test(emailAddress);

/**
 * Returns `true` if the currently logged in user should be presented with the reclassify interface and `false`
 * otherwise.
 *
 * @param {object} app `app` from Redux
 * @param {object} user `user` from Redux
 * @param {object} location `location` from `withRouter`
 */
export const shouldShowReclassify = (
  app: ReduxStore['app'],
  user: ReduxStore['user'],
  location: RouteComponentProps['location']
) => {
  const {
    session: { email }
  } = user;
  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });

  // Do not show the reclassify UI in any of these situations:
  // 1) We're not on Atlas
  // 2) We're not on the search page
  // 3) We're not on the segment edit route
  // 4) We're on production

  if (
    // app.name !== AppName.Atlas ||
    !location.pathname.includes('/search') ||
    queryParams.entityType !== 'segment' ||
    getAppStage() === 'prod'
  ) {
    return false;
  }

  // If all of the previous conditions pass (meaning that we're on the search page, in the segment view, and not on
  // production), we display the reclassify UI if we're a Stackline user or a piwheel superuserr.
  return isReclassifyUser(email);
};

/**
 * Builds the base URL for making API requests
 */
export const buildBaseTarget = () => {
  const { hostname } = window.location;
  let apiEndpoint = '';

  // We don't want to make preflight requests for Drive so we are using
  // the same origin. Note that Cloudfront distributions need to be configured
  // correctly before doing this for our other produccts.
  if (hostname.includes('localhost') || hostname.startsWith('ad')) {
    apiEndpoint = hostname;
  } else if (hostname.indexOf('stackline.com') >= 0) {
    apiEndpoint = hostname.replace(/atlas|beacon-pro|beaconpro|beacon|ad|omni|discover/, 'api');
  } else {
    apiEndpoint = 'api.stackline.com';
  }

  // apiEndpoint = 'api-dev.stackline.com';

  return apiEndpoint;
};

/**
 * Fetches a piece of metadata from the API via `AdvancedSearch`, returning a `Promise` that resolves to it.
 *
 * @param {string} appName
 * @param {string} name The `name` and `searchType` fields of the created request
 * @param {object?} requestOverrides Additional props to add to the request object made to the server
 */
export const fetchUserMetadata = (appName: ReduxStore['app']['name'], name: string, requestOverrides: {} = {}) => {
  const request = [
    {
      name,
      pageNumber: 1,
      pageSize: 20000,
      doAggregation: false,
      returnDocuments: true,
      searchBy: 'parent',
      searchType: name,
      aggregations: null,
      ...requestOverrides
    }
  ];

  return axios.post(`/api/${appName}/AdvancedSearch?_id=fetchUserMetadata`, request);
};

export const getEntityUrlInApp = (app: { name: string; stage: string }, type: string, id: string | number) => {
  const baseURL = `https://${app.name}${app.stage !== 'prod' ? `-${app.stage}` : ''}.stackline.com/${type}/${id}`;
  return baseURL;
};

/**
 * Returns true if the selected retailer is Instacart US or Instacart Canada
 * @param retailerId The ID of the retailer selected in the nav bar
 */
export const isInstacart = (retailerId: ReduxStore['retailer']['id']) => {
  // 63 is Instacart US
  // TODO add '100' when Instacart Canada releases optimization
  return ['63'].includes(retailerId);
};

/**
 * Returns true if the user is currently on the
 * AMC connect dashboard
 */
export const isConnectDashboard = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get('tab') === 'connectDashboard';
};

/**
 * Not all pages should show the previous period dropdown in the header.
 * For now, just the AMC Connect page should not show it but add
 * additional logic here as necessary.
 */
export const shouldShowComparisonTimePeriod = () => {
  return !isConnectDashboard();
};

/**
 * Returns true if the user with the given email is allowlisted
 * to view the walmart SV feature
 */
export const isAllowed_WalmartSV = (beaconClientId: string) => {
  const splitClient = splitFactory.client();
  const TREATMENT = 'ad_walmart_sponsored_video_new';

  return (
    splitClient.getTreatment(TREATMENT, {
      beaconClientId
    }) === 'on'
  );
};

/**
 * Returns true if the user with the given email is allowlisted
 * to view the bulk change feature
 */
export const isBulkChangeAllowlisted = (userEmail: string) => {
  const splitClient = splitFactory.client();
  const BULK_CHANGE_TREATMENT = 'ad_bulkupdate_beta';

  return (
    splitClient.getTreatment(BULK_CHANGE_TREATMENT, {
      email: userEmail
    }) === 'on'
  );
};

/**
 * Split IO rule to determine whether or not to render the new Content Rewrite page for Omni.
 * @param userEmail
 * @returns boolean (true): show new content rewrite tab, (false): hide it
 */
export const isContentRewriteAllowed = () => {
  const userEmail = _get(store.getState(), ['user', 'session', 'email'], '');
  const splitClient = splitFactory.client();
  const CONTENT_TREATMENT = 'omni_content_rewrite';

  return (
    splitClient.getTreatment(CONTENT_TREATMENT, {
      email: userEmail
    }) === 'on' && getAppName() === AppName.Omni
  );
};

/**
 * Split IO to determine if the fetchAllAdCampaigns() call should use the platformType filter
 */
export const shouldFetchAllCampaignsWithPlatformTypeFilter = (
  beaconClientId: number,
  userEmail: string,
  appStage: string
) => {
  const SPLIT_IO_TREATMENT = 'fetch_adcampaigns_with_platform_type_filter';

  const splitClient: IBrowserClient = splitFactory.client();
  const should =
    splitClient.getTreatment(SPLIT_IO_TREATMENT, {
      beaconClientId,
      userEmail,
      host: window.location.hostname
    }) === 'on';

  if (appStage !== 'prod') {
    // eslint-disable-next-line no-console
    console.log(`${SPLIT_IO_TREATMENT}: ${should}`);
  }
  return should;
};

/**
 * Split IO rule to determine whether or not to render the new Beacon pages.
 * @param userEmail
 * @param appStage
 * @returns boolean (true): show new beacon pages, (false): show normal Beacon pages
 */
export const shouldShowNewBeacon = () => {
  const userEmail = _get(store.getState(), ['user', 'session', 'email'], '');
  const beaconClientId = _get(store.getState(), ['user', 'config', 'vendor', 'BeaconClientId']);
  const retailerId = _get(store.getState(), ['retailer', 'id']);
  const beaconProEnabledInFoundry =
    _get(store.getState(), ['user', 'config', 'subscriptionsByRetailers', retailerId, 'BeaconProSubscription']) ===
    'true';

  // TODO for now we only want to show pro for beaconpro.stackline.com,
  // eventually we'll roll out the new UI to all beacon clients
  if (window.location.hostname.includes('beacon.stackline.com')) {
    return false;
  }

  if (beaconProEnabledInFoundry) {
    return true;
  }

  const SPLIT_IO_TREATMENT = 'beacon_redesign_split';

  const splitClient: IBrowserClient = splitFactory.client();
  const should =
    splitClient.getTreatment(SPLIT_IO_TREATMENT, {
      userEmail,
      host: window.location.hostname,
      beaconClientId,
      pathname: window.location.pathname
    }) === 'on' && getAppName() === AppName.Beacon;

  return should;
};

/**
 * Controls rollout of the new Beacon Pro topics and topic groups features
 */
export const shouldShowBeaconProTopics = () => {
  const userEmail = _get(store.getState(), ['user', 'session', 'email'], '');
  const beaconClientId = _get(store.getState(), ['user', 'config', 'vendor', 'BeaconClientId']);

  const SPLIT_IO_TREATMENT = 'beacon_pro_topics_topic_groups';

  const splitClient: IBrowserClient = splitFactory.client();
  const should =
    splitClient.getTreatment(SPLIT_IO_TREATMENT, {
      userEmail,
      host: window.location.hostname,
      beaconClientId,
      pathname: window.location.pathname
    }) === 'on';

  return should;
};

export const shouldShowPromotionsAdjustment = () => {
  const beaconClientId = _get(store.getState(), ['user', 'config', 'vendor', 'BeaconClientId']);
  const userEmail = _get(store.getState(), ['user', 'session', 'email'], '');
  const SPLIT_IO_TREATMENT = 'beacon_pro_promotion_adjustment';
  const splitClient: IBrowserClient = splitFactory.client();

  const should =
    splitClient.getTreatment(SPLIT_IO_TREATMENT, {
      host: window.location.hostname,
      userEmail,
      beaconClientId,
      pathname: window.location.pathname
    }) === 'on';
  return should;
};

export const shouldShowPromotionPage = () => {
  const userEmail = _get(store.getState(), ['user', 'session', 'email'], '');
  const beaconClientId = _get(store.getState(), ['user', 'config', 'vendor', 'BeaconClientId']);

  const SPLIT_IO_TREATMENT = 'beacon_pro_promotions_page';

  const splitClient: IBrowserClient = splitFactory.client();
  const should =
    splitClient.getTreatment(SPLIT_IO_TREATMENT, {
      userEmail,
      host: window.location.hostname,
      beaconClientId,
      pathname: window.location.pathname
    }) === 'on' && getAppName() === AppName.Beacon;

  return should;
};

export const shouldShowRatingsAndReviewsV2 = () => {
  const userEmail = _get(store.getState(), ['user', 'session', 'email'], '');

  const SPLIT_IO_TREATMENT = 'beacon-pro-ratings-and-reviews-v2';
  const splitClient: IBrowserClient = splitFactory.client();
  const should =
    splitClient.getTreatment(SPLIT_IO_TREATMENT, {
      userEmail
    }) === 'on' &&
    getAppName() === AppName.Beacon &&
    getAppStage() === 'dev';

  return should;
};

/** In Sign in process, we do not sign them in yet, but need to leverage Split.io
 *  The arguments are coming from the login response though. -- retailer info is not important since it is before the main page
 */
export const shouldShowNewBeaconWithoutRedux = ({
  userEmail,
  beaconClientId,
  appSubscriptions = []
}: {
  userEmail: string;
  beaconClientId: number;
  appSubscriptions: string[];
}) => {
  // If they are log in with beacon version 1, we should not show the new beacon version
  if (window.location.hostname.includes('beacon.stackline.com')) {
    return false;
  }

  const beaconProSubscribed = appSubscriptions.includes('beacon-pro');

  if (beaconProSubscribed && getAppName() === AppName.Beacon) {
    return true;
  }

  const SPLIT_IO_TREATMENT = 'beacon_redesign_split';

  const splitClient: IBrowserClient = splitFactory.client();
  const should =
    splitClient.getTreatment(SPLIT_IO_TREATMENT, {
      userEmail,
      host: window.location.hostname,
      beaconClientId,
      pathname: window.location.pathname
    }) === 'on' && getAppName() === AppName.Beacon;

  return should;
};

/**
 * Used for clients who have Beacon Pro but only want to show it to certain users
 */
export const shouldShowBeaconProForUser = () => {
  const email = _get(store.getState(), ['user', 'session', 'email'], '');
  const beaconProSubscribed = _get(store.getState(), ['user', 'config', 'appSubscriptions'], []).includes(
    'beacon-pro' as AppName
  );

  if (!beaconProSubscribed) {
    return false;
  }

  const SPLIT_IO_TREATMENT = 'beacon_pro_dropdown_option';
  const splitClient: IBrowserClient = splitFactory.client();

  return (
    splitClient.getTreatment(SPLIT_IO_TREATMENT, {
      userEmail: email,
      host: window.location.hostname
    }) === 'on'
  );
};

/**
 * Split IO to determine if users can see the new bulk adjustments feature
 * in beacon pro.
 */
export function bulkAdjustmentsEnabled() {
  const userEmail = _get(store.getState(), ['user', 'session', 'email'], '');
  const beaconClientId = _get(store.getState(), ['user', 'config', 'vendor', 'BeaconClientId']);

  const SPLIT_IO_TREATMENT = 'beacon_pro_bulk_adjustments';
  const splitClient: IBrowserClient = splitFactory.client();
  const should =
    splitClient.getTreatment(SPLIT_IO_TREATMENT, {
      userEmail,
      beaconClientId,
      host: window.location.hostname
    }) === 'on';
  return should;
}

/**
 * Split IO rule to determine whether or not to use Criteo feature.
 * @param userEmail
 * @param appStage
 * @returns boolean (true): show new beacon pages, (false): show normal Beacon pages
 */
export const shouldShowCriteo = () => {
  const SPLIT_IO_TREATMENT = 'drive-criteo-feature';

  const splitClient: IBrowserClient = splitFactory.client();
  const should =
    splitClient.getTreatment(SPLIT_IO_TREATMENT, {
      host: window.location.hostname,
      pathname: window.location.pathname
    }) === 'on' && getAppName() === AppName.Advertising;

  return should;
};

const getParentPlatform = () => {
  const params = queryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const parentPlatform = _get(params, 'pp', '');
  return parentPlatform;
};

export const isCriteo = () => {
  return getParentPlatform() === PARENT_PLATFORMS.CRITEO;
};

/**
 * Split IO rule to determine whether or not to render the license tab for atlas.
 * @param userEmail
 * @param host
 * @returns boolean (true): show license tab, (false): don't show license tab
 */
export const shouldShowLicenseAtlas = () => {
  const userEmail = _get(store.getState(), ['user', 'session', 'email'], '');
  const SPLIT_IO_TREATMENT = 'atlas_license_split';
  const splitClient: IBrowserClient = splitFactory.client();
  const should =
    splitClient.getTreatment(SPLIT_IO_TREATMENT, {
      email: userEmail,
      host: window.location.hostname
    }) === 'on' && getAppName() === AppName.Atlas;
  return should;
};

/**
 * Split IO rule to determine whether or not to render the Shortages features for Beacon clients.
 * @param userEmail
 * @param appStage
 * @returns boolean (true): show new beacon pages, (false): show normal Beacon pages
 */
export const isAuthorizedShortagesClient = () => {
  const user = _get(store.getState(), ['user'], '');

  const SPLIT_IO_TREATMENT = 'beacon_shortages_client_enabled';
  const clientId = _get(user, ['config', 'vendor', 'BeaconClientId'], null);

  const splitClient: IBrowserClient = splitFactory.client();
  const should =
    splitClient.getTreatment(SPLIT_IO_TREATMENT, {
      clientId
    }) === 'on' && getAppName() === AppName.Beacon;

  return should;
};

/**
 * Split IO rule to determine where to show the Pathways and New to Brand tabs in AMC.
 * @param userEmail
 * @param appStage
 * @returns
 */
export const shouldShowPhaseTwoTabs = (userEmail: string, appStage: string) => {
  const SPLIT_IO_TREATMENT = 'amc_phase_two';

  const splitClient: IBrowserClient = splitFactory.client();
  const should =
    splitClient.getTreatment(SPLIT_IO_TREATMENT, {
      userEmail,
      host: window.location.hostname
    }) === 'on';

  if (appStage !== 'prod') {
    // eslint-disable-next-line no-console
    console.log(`${SPLIT_IO_TREATMENT}: ${should}`);
  }
  return should;
};

export const isContentUploadEnabled = (userEmail: string) => {
  const splictClient = splitFactory.client();
  const CONTENT_UPLOAD_TREATMENT = 'omni-content-upload';

  return (
    splictClient.getTreatment(CONTENT_UPLOAD_TREATMENT, {
      userEmail
    }) === 'on'
  );
};

/**
 * Returns true if we should fetch all rows for
 * an entity, including those without metrics
 */
export const shouldFetchAllSRGRows = (entityType: string, groupByField: string) => {
  const splitClient = splitFactory.client();
  const SRG_V3_TREATMENT = 'fetch_all_srg_metadata_rows';
  const userEmail = _get(store.getState(), ['user', 'config', 'profile', 'email'], '');

  const treatment =
    splitClient.getTreatment(SRG_V3_TREATMENT, {
      userEmail,
      entityType,
      groupByField,
      host: window.location.hostname
    }) === 'on';

  return treatment;
};

/**
 * Simple function to determine if client is Apple
 * @returns boolean (true): is Apple client, (false): is not Apple client
 */
export const isApple = () => {
  const beaconClientId = Number(_get(store.getState(), ['user', 'config', 'vendor', 'BeaconClientId'], -1));

  return [162].includes(beaconClientId);
};

/**
 * Log the message to console, if not running on production environment
 */
export const logDev = (appStage: string, message: string) => {
  if (appStage !== 'prod') {
    // eslint-disable-next-line no-console
    console.log(message);
  }
};

/**
 * Returns true if the page uses secondary retailers instead
 * of the normal ones for the app
 */
export const usesSecondaryRetailers = (): boolean => {
  return isConnectDashboard();
};

/**
 * Returns true if the page uses a custom main time period
 * dropdown instead of the app defaults
 */
export const usesCustomTimePeriods = (): boolean => {
  return isConnectDashboard();
};

/**
 * Parses two search strings and determines if there are any differences
 * between any of the query strings. If given `keysToWatch`, it will only
 * return true if there are differences between the given keys.
 */
export const queryParamsEqual = (search1: string, search2: string, keysToWatch?: string[]) => {
  const params1 = new URLSearchParams(search1);
  const params2 = new URLSearchParams(search2);

  const params1Obj = Object.fromEntries([...params1.entries()]);
  const params2Obj = Object.fromEntries([...params2.entries()]);

  const keys = keysToWatch || [...new Set([...params1.keys(), ...params2.keys()])];

  return _isEqual(_pick(params1Obj, keys), _pick(params2Obj, keys));
};

export default {
  colors,
  getAppName
};

type UseMemoParams = Parameters<typeof React.useMemo>;
type MemoCallback = UseMemoParams[0];
type DependencyList = UseMemoParams[1];

/**
 * Uses useDeepCompareEffect to create a memoized value that is only
 * updated when the objects in its dependencies array are not
 * deeply equal, even if the references change.
 */
export function useDeepCompareMemo(callback: MemoCallback, dependencies: DependencyList) {
  const rawMemoizedValue = useMemo(callback, dependencies);
  const [memoizedValue, setMemoizedValue] = useState(rawMemoizedValue);

  useDeepCompareEffect(() => {
    setMemoizedValue(rawMemoizedValue);
  }, [rawMemoizedValue]);

  return memoizedValue;
}

export function downloadFile(url: string, filename: string) {
  // Validate input
  if (!url || !filename || !url.startsWith('http') || !url.includes('.')) {
    throw new Error('Invalid input. Please provide a valid URL and filename.');
  }

  // Extract the file extension from the URL
  const extension = url
    .slice(url.lastIndexOf('/') + 1)
    .split('.')[1]
    .split('?')[0];

  // Add filetype to filename if it's not present
  filename = filename.endsWith(`.${extension}`) ? filename : `${filename}.${extension}`;

  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then((blob) => {
      const _url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = _url;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(_url);
    })
    .catch((e) => console.error(e));
}

/**
 * Formats a metric given
 */
export const formatMetric: MetricFormatterFn = (
  value: number,
  metricType: ValueOf<typeof METRICTYPE>,
  options: UseMetricFormatterOptions
) => {
  const { retailer } = store.getState();

  return convertMetricToDisplayValue(
    retailer,
    value,
    metricType,
    retailer.currencySymbol,
    typeof options.showFullValue !== 'undefined' ? options.showFullValue : true,
    options
  );
};

interface EntityParam {
  path: EntityPathname;
  routeParam?: string | number | null;
}

const getNewPath = (entityPath: EntityParam) => {
  const { path, routeParam } = entityPath;
  return `${path}${routeParam || ''}`;
};
export interface UpdateUrlQueryParams {
  [key: string]: string | string[] | null;
}
/**
 * Given a set of query parameters and values, return a new
 * path with the update keys and values in the URL.
 * To delete a param, pass the key with a value of null.
 * @param newParamValues An object containing param keys and values to add to the URL. To add multiple values to one key, pass the values as an array.
 * @param entityPath Optional object containing an EntityPathname as a path and an optional routeParam value. i.e: {path: Entitypath.Product, routeParam: SKU12345}
 * @param paramsOnly Optional boolean to return only the query params string without any additional information
 */
export const updateUrlQueryParams = (
  newParamValues: UpdateUrlQueryParams,
  entityPath?: EntityParam,
  paramsOnly = false
) => {
  const params = paramsOnly ? new URLSearchParams() : new URLSearchParams(window.location.search);

  Object.entries(newParamValues).forEach(([key, value]) => {
    // If the value is an array, we can append multiple values under the same param key.
    if (Array.isArray(value)) {
      value.forEach((paramValue) => {
        // If the value is null, we can safely delete the parameter under the given key.
        if (paramValue === null) {
          params.delete(key);
        } else {
          params.append(key, paramValue);
        }
      });
    } else if (value === null) {
      params.delete(key);
    } else {
      params.set(key, value);
    }
  });

  if (paramsOnly) {
    return `&${params.toString()}`;
  }

  // If we're optionally given a path such as EntityPathname.Product with a routeParam (product SKU in this case), we change the path.
  const pathname = entityPath ? getNewPath(entityPath) : window.location.pathname;
  return `${pathname}?${params.toString()}`;
};

export const getQueryParamValue = (key: string, defaultValue?: string): string => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key) || defaultValue;
};

export const calculatePercentChange = (mainMetric: number, comparisonMetric: number): number => {
  // Handle division by 0 cases
  if (comparisonMetric === 0) {
    if (mainMetric === 0) {
      return 0;
    }
    return mainMetric > 0 ? 1000 : -1000;
  }

  if (mainMetric === comparisonMetric) {
    return 0;
  }

  const percentChange =
    Math.abs(comparisonMetric) > 0 ? (mainMetric - comparisonMetric) / Math.abs(comparisonMetric) : 0;

  const minPercent = 1e-6;

  // Super small values get formatted as NaN, so we round up
  if (Math.abs(percentChange) < minPercent) {
    return minPercent * (percentChange < 0 ? -1 : 1);
  }

  return percentChange;
};

/**
 * Performs division on two numbers, returning a default value if the denominator is 0.
 */
export const safeDivide = (numerator: number, denominator: number, defaultIfZero = 0): number => {
  if (denominator === 0) {
    return defaultIfZero;
  }
  return numerator / denominator;
};

interface SafeJSONParseParams {
  value: string;
  defaultValue?: any | null;
}

/**
 * Safely parses a JSON string, returning a default value if the parse fails.
 */
export const safeJSONParse = ({ value, defaultValue = null }: SafeJSONParseParams) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    console.error('Failed to parse JSON: ', e);
    return defaultValue;
  }
};
