import _cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import { FORECAST_SUMMARY_KEYMETRIC_QUERY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastQueryKeys';
import { UNADJUSTED_METRIC_FIELDS } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy';
import {
  useForecastComparisonStartAndEndWeekIds,
  useForecastPeriod,
  useForecastStartAndEndWeekIds,
  useForecastType,
  useLatestForecastModel,
  useYtdStartAndEndWeekIdsForForecast
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import { useApplyEntityFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import {
  ForecastPeriod,
  ForecastType
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import { useAdvancedSearchRequestBuilder } from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { useAppSelector } from 'src/utils/Hooks';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import { getAppName } from 'src/utils/app';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';

const requestIndexSelector = (mainMetricField: string) => {
  if (mainMetricField.toLowerCase().includes('clicks')) {
    return 'beacon-traffic';
  } else {
    return 'beacon-sales';
  }
};

// some of the original metric should match with new metric in forecast request
const mainMetricFieldToForecastMetricMap = {
  retailerGrossMargin: 'retailerMargin',
  brandGrossMargin: 'brandMargin',
  totalClicks: 'totalTraffic',
  organicClicks: 'organicTraffic'
};

const selectedGroupByFieldToTypeMap = {
  subCategoryId: 'subcategory',
  categoryId: 'category',
  brandId: 'brand'
};

export const useForecastSummaryTopEntityChartData = (selectedGroupByField: string, selectedMainMetricField: string) => {
  //  requirement for basic original data
  const indexName = requestIndexSelector(selectedMainMetricField);

  const retailerId = useAppSelector((state) => state.retailer.id);
  const categoryIds = useAppSelector((state) => state.entityService.mainEntity.categoryIds);

  const applyEntityFilters = useApplyEntityFilters();

  const selectedMainMetricIndexField = _cloneDeep(
    INDEX_FIELDS.getField(getAppName(), 'sales', selectedMainMetricField)
  );
  const selectedMainMetricIndexFieldAggregationType =
    selectedMainMetricIndexField.aggregationFunctionType || selectedMainMetricIndexField.aggregationFunction;

  // requirement for forecast data
  const { modelVersion, loading: isForecastModelLoading, currentPublishVersion } = useLatestForecastModel();
  const forecastType = useForecastType();

  // requirements for WeekIds related

  const forecastPeriod = useForecastPeriod();
  const { startWeekId: comparisonStartWeekId, endWeekId: comparisonEndWeekId } =
    useForecastComparisonStartAndEndWeekIds();

  const { startWeekId: forecastStartWeekId, endWeekId: forecastEndWeekId } = useForecastStartAndEndWeekIds();
  const { startWeekId: actualDataStartWeekId, endWeekId: actualDataEndWeekId } = useYtdStartAndEndWeekIdsForForecast();

  // ORIGINAL TOP ENTITY CHART
  const originalTopEntityRequestId = 'originalTopEntityChart';
  const originalTopEntityRequestBuilder = useAdvancedSearchRequestBuilder(originalTopEntityRequestId, indexName);
  const originalTopEntityRequest = useMemo(() => {
    const originalTopEntityAggregationBuilder = new AggregationBuilder(selectedGroupByField);

    originalTopEntityAggregationBuilder.addAggregationField(
      selectedMainMetricIndexField.displayName,
      selectedMainMetricIndexField.name,
      selectedMainMetricIndexFieldAggregationType,
      selectedMainMetricIndexField.canBeExported
    );
    originalTopEntityAggregationBuilder.addConditionRangeFilter('weekId', actualDataStartWeekId, actualDataEndWeekId);
    originalTopEntityAggregationBuilder.addConditionTermFilter('retailerId', [retailerId]);
    originalTopEntityAggregationBuilder.addComparisonRangeFilter('weekId', comparisonStartWeekId, comparisonEndWeekId);

    originalTopEntityRequestBuilder
      .setPageNumber(1)
      .setPageSize(100)
      .setPeriod('year')
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .setSearchBy('parent')
      .addConditionTermFilter('categoryId', 'should', categoryIds)
      .addAggregation(originalTopEntityAggregationBuilder.build())
      .addSortField(
        selectedMainMetricIndexField.displayName,
        selectedMainMetricIndexField.name,
        selectedMainMetricIndexFieldAggregationType,
        selectedMainMetricIndexField.canBeExported
      )
      .apply(applyEntityFilters);

    return originalTopEntityRequestBuilder.build();
  }, [
    selectedGroupByField,
    selectedMainMetricIndexField.displayName,
    selectedMainMetricIndexField.name,
    selectedMainMetricIndexField.canBeExported,
    selectedMainMetricIndexFieldAggregationType,
    actualDataStartWeekId,
    actualDataEndWeekId,
    retailerId,
    comparisonStartWeekId,
    comparisonEndWeekId,
    originalTopEntityRequestBuilder,
    categoryIds,
    applyEntityFilters
  ]);

  const { data: originalData, isLoading: isOriginalDataLoading } = useGenericAdvancedSearch({
    requestId: originalTopEntityRequestId,
    queryKeys: [originalTopEntityRequest],
    requestBody: [originalTopEntityRequest]
  });

  const originalTopEntityData = useMemo(() => {
    if (!originalData) {
      return null;
    }

    const filteredResponse = originalData.data[0].aggregations[`by_${selectedGroupByField}`];
    const originalComparisonData = [];
    const originalCurrentData = [];
    const originalMetaData = [];
    filteredResponse.forEach((entityData) => {
      const { additionalMetaData, additionalValues, value, fieldId } = entityData;

      originalComparisonData.push([
        additionalMetaData.name,
        // The server returns like retailSales_sum_value_202217_202252
        additionalValues[
          `${selectedMainMetricField}_${selectedMainMetricIndexFieldAggregationType}_value_${comparisonStartWeekId}_${comparisonEndWeekId}`
        ]
      ]);
      // if it is not full year, the original Data will be discarded. (right side of the column)
      const originalDataValue = forecastPeriod === ForecastPeriod.FULL_YEAR ? value : 0;
      originalCurrentData.push([additionalMetaData.name, originalDataValue]);
      originalMetaData.push({
        name: additionalMetaData.name,
        id: fieldId,
        type: selectedGroupByFieldToTypeMap[selectedGroupByField]
      });
    });

    return { originalComparisonData, originalCurrentData, originalMetaData };
  }, [
    comparisonEndWeekId,
    comparisonStartWeekId,
    forecastPeriod,
    originalData,
    selectedGroupByField,
    selectedMainMetricField,
    selectedMainMetricIndexFieldAggregationType
  ]);

  // UNADJUSTED FORECAST DATA
  const topEntityUnadjustedRequestId = `unadjusted_bar_chart`;
  const topEntityUnadjustedRequestBuilder = useAdvancedSearchRequestBuilder(
    topEntityUnadjustedRequestId,
    'beacon-unadjusted-forecast'
  );

  const forecastMainMetricIndexField = UNADJUSTED_METRIC_FIELDS.find(
    (metricField) =>
      metricField[1] === selectedMainMetricField ||
      metricField[1] === mainMetricFieldToForecastMetricMap[selectedMainMetricField]
  );

  const topEntityUnadjustedRequest = useMemo(() => {
    const [displayName, name, aggregationFunction] = forecastMainMetricIndexField;
    const aggregationBuilder = new AggregationBuilder(selectedGroupByField);
    aggregationBuilder.addAggregationField(displayName, name, aggregationFunction, true);
    aggregationBuilder
      .addConditionTermFilter('forecastModelVersion', [modelVersion])
      .addConditionTermFilter('publishVersion', [currentPublishVersion]);
    aggregationBuilder.addConditionRangeFilter('forecastWeekId', forecastStartWeekId, forecastEndWeekId);
    aggregationBuilder.addConditionTermFilter('retailerId', [retailerId]);

    topEntityUnadjustedRequestBuilder
      .setPageNumber(1)
      .setPageSize(100)
      .setPeriod('year')
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .addConditionTermFilter('categoryId', 'should', categoryIds)
      .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
      .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
      .setSearchBy('parent')
      .addAggregation(aggregationBuilder.build())
      .addSortField(displayName, name, aggregationFunction, true);
    return topEntityUnadjustedRequestBuilder.build();
  }, [
    forecastMainMetricIndexField,
    selectedGroupByField,
    modelVersion,
    currentPublishVersion,
    forecastStartWeekId,
    forecastEndWeekId,
    retailerId,
    topEntityUnadjustedRequestBuilder,
    categoryIds
  ]);

  // ADJUSTED REQUEST
  const topEntityAdjustedRequestId = 'adjusted_bar_chart_delta';
  const topEntityAdjustedRequestBuilder = useAdvancedSearchRequestBuilder(
    topEntityAdjustedRequestId,
    'beacon-adjusted-forecast'
  );

  const topEntityAdjustedRequest = useMemo(() => {
    const [displayName, name, aggregationFunction] = forecastMainMetricIndexField;
    const deltaFieldName = `${name}Delta`;
    const aggregationBuilder = new AggregationBuilder(selectedGroupByField);
    aggregationBuilder.addAggregationField(displayName, deltaFieldName, aggregationFunction, true);
    aggregationBuilder.addConditionRangeFilter('forecastWeekId', forecastStartWeekId, forecastEndWeekId);
    aggregationBuilder.addConditionTermFilter('retailerId', [retailerId]);
    aggregationBuilder
      .addConditionTermFilter('forecastModelVersion', [modelVersion])
      .addConditionTermFilter('publishVersion', [currentPublishVersion]);

    topEntityAdjustedRequestBuilder
      .setPageNumber(1)
      .setPageSize(100)
      .setPeriod('year')
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .addConditionTermFilter('categoryId', 'should', categoryIds)
      .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
      .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
      .setSearchBy('parent')
      .addAggregation(aggregationBuilder.build())
      .addSortField(displayName, deltaFieldName, aggregationFunction, true);
    return topEntityAdjustedRequestBuilder.build();
  }, [
    forecastMainMetricIndexField,
    selectedGroupByField,
    forecastStartWeekId,
    forecastEndWeekId,
    retailerId,
    modelVersion,
    currentPublishVersion,
    topEntityAdjustedRequestBuilder,
    categoryIds
  ]);

  const combinedTopEntityRequest = useMemo(() => {
    return [topEntityUnadjustedRequest, ...(forecastType === ForecastType.ADJUSTED ? [topEntityAdjustedRequest] : [])];
  }, [topEntityUnadjustedRequest, topEntityAdjustedRequest, forecastType]);

  const { data: forecastResponse, isLoading: forecastLoading } = useGenericAdvancedSearch({
    requestId: topEntityUnadjustedRequestId,
    queryKeys: [FORECAST_SUMMARY_KEYMETRIC_QUERY, ...combinedTopEntityRequest],
    requestBody: combinedTopEntityRequest,
    shouldPerformFetch: !isForecastModelLoading,
    allowCancel: false
  });

  const isLoading = useMemo(() => isOriginalDataLoading && forecastLoading, [forecastLoading, isOriginalDataLoading]);

  const forecastData = useMemo(() => {
    return forecastResponse ? forecastResponse.data : null;
  }, [forecastResponse]);

  const clonedOriginalData = _cloneDeep(originalTopEntityData);

  if (forecastData && originalTopEntityData) {
    const groupByMap = {
      subCategoryId: ['subcategory', 'subCategoryName'],
      categoryId: ['category', 'categoryName'],
      brandId: ['brand', 'brandName']
    }[selectedGroupByField];

    const [entity, entityName] = groupByMap;
    const unAdjustedForecastedValues = {};
    const adjustedForecastedValues = {};

    forecastData[0].aggregations[`by_${selectedGroupByField}`].forEach((item) => {
      unAdjustedForecastedValues[item.additionalMetaData[entity][entityName]] = item.value;
    });

    clonedOriginalData.originalCurrentData.forEach((item) => {
      const groupByFieldName = item[0];
      if (unAdjustedForecastedValues[groupByFieldName]) {
        item[1] = unAdjustedForecastedValues[groupByFieldName] + item[1];
      }
    });

    if (forecastType === ForecastType.ADJUSTED) {
      forecastData[1].aggregations[`by_${selectedGroupByField}`].forEach((item) => {
        adjustedForecastedValues[item.additionalMetaData[entity][entityName]] = item.value;
      });

      clonedOriginalData.originalCurrentData.forEach((item) => {
        const groupByFieldName = item[0];
        if (adjustedForecastedValues[groupByFieldName]) {
          item[1] = adjustedForecastedValues[groupByFieldName] + item[1];
        }
      });
    }
  }

  return { clonedOriginalData, isLoading };
};
