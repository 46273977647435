import _cloneDeep from 'lodash/cloneDeep';

import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { buildTrendWidgetConfig } from 'src/components/Layout/LayoutUtil';
import { buildOmniTopEntitiesWidgetConfig } from 'src/components/EntityPage/TopEntitiesChart/OmniTopEntitesChartUtil';
import { PageLayout, Widget } from 'src/types/application/widgetTypes';
import { AppName } from 'sl-api-connector/types';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import ReduxStore from 'src/types/store/reduxStore';
import OmniTrendChart from 'src/components/EntityPage/TrendChart/OmniTrendChart';
import OmniFilterWrapper from 'src/components/Omni/OmniFilter/OmniFIlterWrapper';
import { buildOmniProductsWidget } from 'src/components/EntityGrid/EntityGrid/OmniEntityGridUtils';
import { omniGridField, omniTableViewField } from 'src/components/EntityGrid/EntityGrid/omniGridField';
import { store } from 'src/main';
import { ExportIcon } from 'src/components/SvgIcons';
import PageContextMenu from 'src/components/PageContextMenu';

const { omniRegionsFollowing, omniCountriesFollowing } = store.getState();
const RETAIL_PRICE_INDEX_NAME = 'price';

export const getLayoutForEntity = (app: ReduxStore['app'], subtab: string, entityType: string): PageLayout => {
  const widgets: Widget[] = [];

  const indexName = RETAIL_PRICE_INDEX_NAME;
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(AppName.Omni, indexName, 'weekId'));

  // if the client has only one region, we don't have to show the dropdown menu. but country it will be useful for them to combine all country data
  const geoGroups = [
    ...(entityType !== 'retailer' && omniRegionsFollowing.length > 1 ? ['region'] : []),
    ...(entityType !== 'retailer' && omniCountriesFollowing.length > 1 ? ['country'] : []),
    'state',
    'city'
  ];

  const fulfillmentTypeRetailPrice = ['pickUpRetailPrice', 'deliveryRetailPrice', 'shippingRetailPrice'];
  const fulfillmentTypeRetailPriceWithAverage = ['avgRetailPrice', ...fulfillmentTypeRetailPrice];

  const fulFillmentTypePriceViolations = [
    'priceViolationsCountPickUp',
    'priceViolationsCount',
    'priceViolationsCountShipping'
  ];
  const fulFillmentTypePriceViolationsWithTotal = ['totalPriceViolationCount', ...fulFillmentTypePriceViolations];
  const fulfillmentTypeList = ['pickUp', 'delivery', 'shipping'];

  widgets.push({
    CustomComponent: OmniFilterWrapper,
    name: 'omniFilter',
    view: {
      name: 'omniFilter',
      style: {
        display: 'flex',
        justifyContent: 'end',
        marginTop: 41
      },
      shouldShowFilter: !(entityType === 'segment')
    },
    data: {}
  });

  const subMenuItems = [];
  if (entityType === 'segment' || entityType === 'retailer') {
    const eventName = `downloadOmniData${entityType === 'segment' ? 'Segments' : ''}`;
    subMenuItems.push({
      icon: ExportIcon,
      text: 'Add to Download List',
      eventName
    });
  }

  //  get all widgets based off of the subtab that is being passed through
  switch (subtab) {
    case 'retailPrice':
      widgets.push(
        buildTrendWidgetConfig(app, indexName, { type: entityType }, 'weekId', ['retailPrice'], weekIdField, {
          CustomComponent: OmniTrendChart,
          view: {
            chartPropsOverride: {
              title: { text: 'Average Retail Price' }
            },
            container: {
              style: {
                marginBottom: 100,
                marginTop: 30,
                verticalAlign: 'top'
              }
            }
          }
        })
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          ['orderType'],
          ['retailPrice'],
          weekIdField,
          {
            view: {
              fetchList: fulfillmentTypeRetailPrice.map((metricFieldName) =>
                _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, metricFieldName, entityType))
              )
            }
          }
        )
      );

      // in retailer entity view, we don't have to show this bar chart, since it is same value as topEntityChart above
      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            ['retailerId'],
            fulfillmentTypeRetailPriceWithAverage,
            weekIdField,
            {
              view: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                }
              }
            }
          )
        );
      }

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          fulfillmentTypeRetailPriceWithAverage,
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(...buildOmniProductsWidget(omniGridField.retailPrice, omniTableViewField.retailPrice, true));
      if (entityType === 'segment' || entityType === 'retailer') {
        widgets.push({
          CustomComponent: PageContextMenu,
          name: 'pageContextMenu',
          view: {
            name: 'pageContextMenu',
            buttons: subMenuItems
          },
          data: {}
        });
      }

      break;
    case 'promotions':
      widgets.push(
        buildTrendWidgetConfig(app, indexName, { type: entityType }, 'weekId', ['avgPromotionPrice'], weekIdField, {
          CustomComponent: OmniTrendChart,
          view: {
            container: {
              style: {
                marginBottom: 100,
                marginTop: 30,
                verticalAlign: 'top'
              }
            }
          }
        })
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          ['orderType'],
          ['promotionPrice'],
          weekIdField,
          {
            view: {
              fetchList: ['promotionsPricePickup', 'promotionsPrice', 'promotionsPriceShipping'].map(
                (metricFieldName) => _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, metricFieldName, entityType))
              )
            }
          }
        )
      );

      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            ['retailerId'],
            ['avgPromotionPrice', 'promotionsPricePickup', 'promotionsPrice', 'promotionsPriceShipping'],
            weekIdField,
            {
              view: {
                fetchList: ['promotionsPricePickup', 'promotionsPrice', 'promotionsPriceShipping'].map(
                  (metricFieldName) =>
                    _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, metricFieldName, entityType))
                )
              }
            }
          )
        );
      }

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          ['avgPromotionPrice', 'promotionsPricePickup', 'promotionsPrice', 'promotionsPriceShipping'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            ['retailerId'],
            ['totalCountOfPromotionsTopEntities'],
            weekIdField,
            {
              view: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                }
              }
            }
          )
        );
      }

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          ['totalCountOfPromotionsTopEntities'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(...buildOmniProductsWidget(omniGridField.promotionPrice, omniTableViewField.promotion, true));

      if (entityType === 'segment' || entityType === 'retailer') {
        widgets.push({
          CustomComponent: PageContextMenu,
          name: 'pageContextMenu',
          view: {
            name: 'pageContextMenu',
            buttons: subMenuItems
          },
          data: {}
        });
      }

      break;
    case 'priceViolations':
      widgets.push(
        buildTrendWidgetConfig(
          app,
          indexName,
          { type: entityType },
          'weekId',
          ['priceViolationsCountSummaryTrend'],
          weekIdField,
          {
            CustomComponent: OmniTrendChart,
            view: {
              container: {
                style: {
                  marginBottom: 100,
                  marginTop: 30,
                  verticalAlign: 'top'
                }
              }
            },
            data: { fulfillmentTypeList }
          }
        )
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          ['orderType'],
          ['priceViolationsCountSummaryTopEntities'],
          weekIdField,
          {
            view: {
              fetchList: fulFillmentTypePriceViolations.map((metricFieldName) =>
                _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, metricFieldName, entityType))
              )
            }
          }
        )
      );

      // in retailer entity view, we don't have to show this bar chart, since it is same value as topEntityChart above
      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            ['retailerId'],
            fulFillmentTypePriceViolationsWithTotal,
            weekIdField,
            {
              view: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                }
              }
            }
          )
        );
      }

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          fulFillmentTypePriceViolationsWithTotal,
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(...buildOmniProductsWidget(omniGridField.priceViolation, omniTableViewField.priceViolation, true));

      if (entityType === 'segment' || entityType === 'retailer') {
        widgets.push({
          CustomComponent: PageContextMenu,
          name: 'pageContextMenu',
          view: {
            name: 'pageContextMenu',
            buttons: subMenuItems
          },
          data: {}
        });
      }

      break;
    default:
      break;
  }

  return {
    widgets,
    CustomPageContainer: GenericPageContainer as any,
    enableComparison: false
  };
};
