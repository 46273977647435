import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import _get from 'lodash/get';
import { store } from 'src/main';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import ReduxStore from 'src/types/store/reduxStore';
import queryString from 'qs';
import { fillSegmentFieldInURL } from 'src/components/Omni/OmniSegmentUtil';

const dataKey = ['totalShareOfShelf', 'paidShareOfShelf', 'organicShareOfShelf'];
const { retailer } = store.getState();
export const processListData = (rawData: any[]) => {
  return rawData.map((ele) => {
    const { key } = ele;
    const entity = {
      name: key,
      id: key
    };
    const aProcessedData = { entity, ...entity };
    dataKey.forEach((dk) => {
      const value = _get(ele, `${dk}.value`, 0);
      aProcessedData[dk] = convertMetricToDisplayValue(retailer, value, METRICTYPE.PERCENT, '');
      aProcessedData[`${dk}Value`] = value;
    });
    return aProcessedData;
  });
};

export const formKeywordSegmentRequestBody = (
  queryParams: queryString.ParsedQs,
  mainTimePeriod: ReduxStore['mainTimePeriod']
) => {
  const { startWeek, endWeek } = mainTimePeriod;
  const parsedState = fillSegmentFieldInURL(queryParams, 'keyword');
  delete parsedState.name;
  delete parsedState.queryId;
  const baseRequestBody = {
    startWeekId: startWeek,
    endWeekId: endWeek,
    pageNumber: 1,
    pageSize: 20,
    ...parsedState
  };
  return baseRequestBody;
};
