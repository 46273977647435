import axios from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ADJUSTMENT_TABLE_QUERY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastQueryKeys';
import {
  useBaseMetricRequestBuilder,
  useCreateRemoveRetailPriceRangeFilters
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { useAppSelector, useLocation } from 'src/utils/Hooks';
import AdvancedSearchRequestBuilder, {
  useAdvancedSearchRequestBuilder
} from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import {
  useBulkAdjustmentUploadData,
  useUserData
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Adjustments/hooks';
import {
  removeDraftAdjustments,
  getDraftAdjustments,
  buildDraftNetImpactPayloads,
  getPaidTrafficAdjustments,
  createDraftPaidTrafficPayloads
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useCompleteAdjustmentData/utils';
import {
  ForecastsAdjustmentData,
  Status
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import {
  DraftedNetImpactResponse,
  DraftedPaidTrafficResponse,
  PublishedNetImpactAggregation,
  PublishedPaidTrafficAggregation
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useCompleteAdjustmentData/types';
import _get from 'lodash/get';
import {
  BulkAdjustmentStatus,
  BulkAdjustmentStatusRow
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/serverProxy/types';
import { useLatestForecastModel } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';

const defaultQueryOptions = {
  retry: 0,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5, // data is stale after 5 minutes
  cacheTime: 1000 * 60 * 30 // data is removed from the cache after 30 minutes
};

/**
 * Responsible for fetching all data needed to render the adjustment table.
 * Executes a "DAG" of queries and returns each required dataset.
 */
export const useFetchAllAdjustmentData = () => {
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const retailer = useAppSelector((state) => state.retailer);
  const location = useLocation();
  const removeRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();
  const filterParams = new URLSearchParams(location.search);

  // Returns bulk adjustment uploads sorted by timestamp, get the 50 most
  // recent and then we will filter out adjustments that have a bulk adjustment ID
  // that is still publishing
  const {
    data: bulkAdjustmentUploads,
    isLoading: bulkAdjustmentUploadsLoading,
    isError: bulkAdjustmentUploadsError
  } = useBulkAdjustmentUploadData({ pageSize: 50 });

  // Query 1 - Model version
  const { modelVersion, loading: modelLoading, currentPublishVersion } = useLatestForecastModel();

  // Query 2 - Adjustments by Week Id
  const forecastAdjustmentBuilder = useBaseMetricRequestBuilder({
    requestId: 'adjustmentsByweekIdForAdjustmentsPage',
    indexName: 'adjustments-product',
    fields: []
  });

  const forecastAdjustmentsAdvancedSearchRequest = useMemo(() => {
    forecastAdjustmentBuilder
      .clearAggregations()
      .setPeriod(undefined)
      .setPageSize(1200)
      .setDoAggregation(false)
      .setReturnDocuments(true)
      .setSearchBy('child')
      .apply(removeRetailPriceRangeFilters())
      .addSortFieldWithOnlyFieldName('adjustmentChangeValue', 'desc');

    if (filterParams.has('adjustmentStatus')) {
      const statusesToFilter = filterParams.getAll('adjustmentStatus') as Status[];
      forecastAdjustmentBuilder.addConditionTermFilter('status', 'must', statusesToFilter);
    } else {
      forecastAdjustmentBuilder.addConditionTermFilter('status', 'must', ['Published', 'Draft', 'Archived']);
    }

    return forecastAdjustmentBuilder.build();
  }, [filterParams, forecastAdjustmentBuilder, removeRetailPriceRangeFilters]);

  const { data, isLoading, isError } = useQuery(
    [ADJUSTMENT_TABLE_QUERY, forecastAdjustmentsAdvancedSearchRequest],
    async () => {
      try {
        // Get the bulk adjustments that are publishing
        const publishingBulkAdjustmentIds = new Set(
          _get(bulkAdjustmentUploads, ['result'], [])
            .filter(
              (upload: BulkAdjustmentStatusRow) => upload.bulkAdjustmentStatus === BulkAdjustmentStatus.Processing
            )
            .map((upload: BulkAdjustmentStatusRow) => upload.bulkAdjustmentId)
        );

        const response = await axios.post<
          { documents: Omit<ForecastsAdjustmentData, 'netImpact' | 'userMetaData'>[] }[]
        >(`api/beacon/AdvancedSearch?_id=adjustmentsByWeekId`, [forecastAdjustmentsAdvancedSearchRequest]);

        return response.data.map((datum) => ({
          ...datum,
          documents: datum.documents.filter((doc) => !publishingBulkAdjustmentIds.has(doc.bulkAdjustmentId))
        }));
      } catch (error) {
        console.error('Failed to fetch forecast by week', error);
        return [];
      }
    },
    {
      enabled: !!modelVersion && !bulkAdjustmentUploadsLoading,
      ...defaultQueryOptions
    }
  );

  // Query 3 - Net impact for published adjustments
  const adjustmentIds = data
    ? _get(data, [0, 'documents'], [])
        .filter(removeDraftAdjustments)
        .map((doc) => doc.adjustmentId)
    : null;

  const forecastAdjustmentNetImpactBuilder = useAdvancedSearchRequestBuilder(
    `netimpact-client-${beaconClientId}`,
    'beacon-netimpact-adjustment'
  );

  const forecastsPlansAdjustmentsNetImpactRequest = useMemo(() => {
    const plansAggregationBuilder = new AggregationBuilder('adjustmentId');
    plansAggregationBuilder
      .addAggregationField('Weighted Impact', 'weightedImpact', 'sum', true)
      .addConditionTermFilter('retailerId', [retailer.id]);

    forecastAdjustmentNetImpactBuilder
      .setPageSize(1200)
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
      .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
      .addConditionTermFilter('adjustmentId', 'must', adjustmentIds)
      .setSearchBy('parent')
      .addAggregation(plansAggregationBuilder.build());

    return forecastAdjustmentNetImpactBuilder.build();
  }, [adjustmentIds, currentPublishVersion, forecastAdjustmentNetImpactBuilder, modelVersion, retailer.id]);

  const {
    data: publishedNetImpactData,
    isLoading: publishedNetImpactLoading,
    isError: publishedNetImpactError
  } = useQuery(
    [ADJUSTMENT_TABLE_QUERY, forecastsPlansAdjustmentsNetImpactRequest],
    async () => {
      try {
        const response = await axios.post<{
          data: { aggregations: { by_adjustmentId: PublishedNetImpactAggregation[] } };
        }>(`api/beacon/AdvancedSearch?_id=published-netImpactData`, [forecastsPlansAdjustmentsNetImpactRequest]);
        return response.data;
      } catch (error) {
        console.error('Failed to fetch published net impact', error);
        return [];
      }
    },
    {
      enabled: !!adjustmentIds && !!adjustmentIds.length,
      ...defaultQueryOptions
    }
  );

  // Query 4 - Net impact for drafted adjustments
  const draftAdjustmentPayloads = data
    ? _get(data, [0, 'documents'], []).filter(getDraftAdjustments).map(buildDraftNetImpactPayloads)
    : null;

  const {
    data: draftedNetImpactData,
    isLoading: draftedNetImpactLoading,
    isError: draftedNetImpactError
  } = useQuery(
    [ADJUSTMENT_TABLE_QUERY, { key: 'draftAdjustmentPayloads', draftAdjustmentPayloads }],
    async () => {
      try {
        const response = await axios.post<DraftedNetImpactResponse[]>(
          `api/beaconforecast/GetNetImpact`,
          draftAdjustmentPayloads
        );
        return response.data;
      } catch (error) {
        console.error('Failed to fetch drafted net impact', error);
        return [];
      }
    },
    {
      enabled: !!draftAdjustmentPayloads && !!draftAdjustmentPayloads.length,
      ...defaultQueryOptions
    }
  );

  // Query 5 - Paid traffic for published paid traffic adjustments
  const publishedPaidTrafficAdjustmentIds = data
    ? _get(data, [0, 'documents'], [])
        .filter(removeDraftAdjustments)
        .filter(getPaidTrafficAdjustments)
        .map((doc) => doc.adjustmentId)
    : null;

  const adSpendMetricsBuilder = new AdvancedSearchRequestBuilder(
    `paidTraffic-client-${beaconClientId}`,
    'beacon-adjustment-paidtrafficmetrics'
  );

  const adSpendAggregations = new AggregationBuilder('adjustmentId');
  adSpendAggregations.addAggregationField('Paid Traffic', 'paidTrafficValue', 'sum', true).setSortDirection(null);
  adSpendMetricsBuilder
    .setSearchBy('parent')
    .setPageNumber(1)
    .setPageSize(1200)
    .setDoAggregation(true)
    .setReturnDocuments(false)
    .setRetailerId(retailer.id)
    .addConditionTermFilter('retailerId', 'should', [retailer.id])
    .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
    .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
    .addConditionTermFilter('adjustmentId', 'must', publishedPaidTrafficAdjustmentIds)
    .addAggregation(adSpendAggregations.build());

  const adSpendMetricsRequest = adSpendMetricsBuilder.build();

  const {
    data: publishedPaidTrafficData,
    isLoading: publishedPaidTrafficLoading,
    isError: publishedPaidTrafficError
  } = useQuery(
    [ADJUSTMENT_TABLE_QUERY, { key: 'adSpendMetricsRequest', adSpendMetricsRequest }],
    async () => {
      try {
        const response = await axios.post<{
          data: { aggregations: { by_adjustmentId: PublishedPaidTrafficAggregation[] } };
        }>(`api/beacon/AdvancedSearch?_id=published-paidTrafficMetricsForAdjustmentId`, [adSpendMetricsRequest]);
        return response.data;
      } catch (error) {
        console.error('Failed to fetch published paid traffic metrics', error);
        return [];
      }
    },
    {
      enabled: !!publishedPaidTrafficAdjustmentIds && !!publishedPaidTrafficAdjustmentIds.length,
      ...defaultQueryOptions
    }
  );

  // Query 6 - Paid traffic for drafted paid traffic adjustments
  const draftedPaidTrafficAdjustmentPayloads = data
    ? _get(data, [0, 'documents'], [])
        .filter(getDraftAdjustments)
        .filter(getPaidTrafficAdjustments)
        .map(createDraftPaidTrafficPayloads)
    : null;

  const {
    data: draftedPaidTrafficData,
    isLoading: draftedPaidTrafficLoading,
    isError: draftedPaidTrafficError
  } = useQuery(
    [ADJUSTMENT_TABLE_QUERY, { key: 'draftedPaidTrafficAdjustmentPayloads', draftedPaidTrafficAdjustmentPayloads }],
    async () => {
      try {
        const response = await axios.post<DraftedPaidTrafficResponse[]>(
          `api/beaconforecast/GetPaidTrafficMetrics`,
          draftedPaidTrafficAdjustmentPayloads
        );
        return response.data;
      } catch (error) {
        console.error('Failed to fetch drafted paid traffic metrics', error);
        return [];
      }
    },
    {
      enabled: !!draftedPaidTrafficAdjustmentPayloads && !!draftedPaidTrafficAdjustmentPayloads.length,
      ...defaultQueryOptions
    }
  );

  // Query 7 - User metadata
  const userIds = data ? Array.from(new Set(_get(data, [0, 'documents'], []).map((doc) => doc.createdByUserId))) : null;

  const {
    data: userData,
    isError: isUserDataError,
    isLoading: isUserDataLoading
  } = useUserData({
    userIds,
    queryKeys: [ADJUSTMENT_TABLE_QUERY, { key: 'userData' }, userIds],
    enabled: !!userIds && !!userIds.length
  });

  return {
    isLoading:
      isLoading ||
      modelLoading ||
      publishedNetImpactLoading ||
      draftedNetImpactLoading ||
      publishedPaidTrafficLoading ||
      draftedPaidTrafficLoading ||
      isUserDataLoading ||
      bulkAdjustmentUploadsLoading,
    isError:
      isError ||
      publishedNetImpactError ||
      draftedNetImpactError ||
      publishedPaidTrafficError ||
      isUserDataError ||
      draftedPaidTrafficError ||
      bulkAdjustmentUploadsError,
    userData,
    data,
    publishedNetImpactData,
    draftedNetImpactData,
    publishedPaidTrafficData,
    draftedPaidTrafficData
  };
};
