/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import Slide from '@mui/material/Slide';
import { withBus } from 'react-bus';
import TextField from '@mui/material/TextField';
import ListItemsSelector from 'src/components/Search/AdvancedSearch/ListItemsSelector';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';

import ChipsContainer from 'src/components/Search/AdvancedSearch/ChipsContainer';
import { useBus, useOutsideAlerter } from 'src/utils/Hooks';
import 'src/components/AdManager/Search/SearchSideBar.scss';

const SlidedSidebarFilter = ({ filterInfo, setFilter, adPortfolioStates, adAutomationStrategies, eventBus }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [include, setInclude] = useState('');
  const [exclude, setExclude] = useState('');

  const closeFilters = () => {
    setOpenFilter(false);
  };

  useBus(eventBus, 'toggleSearchSidebar', () => {
    if (openFilter) {
      closeFilters();
    } else {
      setOpenFilter(!openFilter);
    }
  });
  useBus(eventBus, 'closeSearchSidebar', () => closeFilters());
  useEffect(() => {
    eventBus.emit('setSearchFilterButtonText', openFilter);
  }, [openFilter, eventBus]);

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => {
    closeFilters();
  });

  const includeKeywordChips = filterInfo.includes
    ? filterInfo.includes.map((item, idx) => {
        return { i: item, key: idx };
      })
    : [];
  const handleIncludeAdd = (event: any) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      setInclude('');
      if (!filterInfo.includes) {
        setFilter({
          ...filterInfo,
          includes: [event.target.value]
        });
      } else if (!filterInfo.includes.includes(event.target.value)) {
        setFilter({
          ...filterInfo,
          includes: [...filterInfo.includes, event.target.value]
        });
      }
    }
  };

  const handleClearInclude = (key) => {
    const text = includeKeywordChips[key].i;
    const newFilter = filterInfo.includes.filter((filterText) => filterText !== text);
    setFilter({
      ...filterInfo,
      includes: newFilter
    });
  };

  const excludeKeywordChips = filterInfo.excludes
    ? filterInfo.excludes.map((item, idx) => {
        return { i: item, key: idx };
      })
    : [];
  const handleExcludeAdd = (event: any) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      setExclude('');
      if (!filterInfo.excludes) {
        setFilter({
          ...filterInfo,
          excludes: [event.target.value]
        });
      } else if (!filterInfo.excludes.includes(event.target.value)) {
        setFilter({
          ...filterInfo,
          excludes: [...filterInfo.excludes, event.target.value]
        });
      }
    }
  };

  const handleClearExclude = (key) => {
    const text = excludeKeywordChips[key].i;
    const newFilter = filterInfo.excludes.filter((filterText) => filterText !== text);
    setFilter({
      ...filterInfo,
      excludes: newFilter
    });
  };

  const handleSelect = (arg) => {
    const { key, values } = arg;
    setFilter({
      ...filterInfo,
      [key]: values
    });
  };

  const clearAllFilters = () => {
    setFilter({});
    closeFilters();
  };
  return (
    <Slide ref={wrapperRef} direction="right" in={openFilter} mountOnEnter>
      <div className="admanager_sidebar">
        <div className="header" style={{ marginTop: 50 }}>
          <div className="header_text">Filters</div>
          <div className="hide_button" role="button" onClick={() => setOpenFilter(false)}>
            Hide
          </div>
        </div>
        <div className="search-form-container">
          <h4 className="sl-form-label">Include</h4>
          <TextField
            variant="standard"
            autoComplete="off"
            className="sl-form-input"
            placeholder="Portfolio names to include"
            type="text"
            name="keyword"
            id="keyword"
            style={{ width: '100%' }}
            value={include}
            onChange={(event: any) => setInclude(event.target.value)}
            onKeyPress={handleIncludeAdd}
          />
          <ChipsContainer
            headerDisplayName="Include"
            onDelete={(key) => handleClearInclude(key)}
            chipData={includeKeywordChips}
          />
        </div>

        <div className="search-form-container">
          <h4 className="sl-form-label">Exclude</h4>
          <TextField
            variant="standard"
            autoComplete="off"
            className="sl-form-input"
            placeholder="Portfolio names to exclude"
            type="text"
            name="keyword"
            id="keyword"
            style={{ width: '100%' }}
            value={exclude}
            onChange={(event: any) => setExclude(event.target.value)}
            onKeyPress={handleExcludeAdd}
          />
          <ChipsContainer
            headerDisplayName="Include"
            onDelete={(key) => handleClearExclude(key)}
            chipData={excludeKeywordChips}
          />
        </div>

        <ListItemsSelector
          listDisplayName="Portfolio Status"
          idFieldName="portfolioState"
          nameFieldName="name"
          searchFieldHintText="Refine by portfolio status"
          searchFieldNotFoundText="No portfolio status were found"
          listItems={adPortfolioStates}
          selectedListItems={filterInfo.portfolioState || []}
          onSelectedListItemsChange={(arg) => handleSelect(arg)}
        />
        <ListItemsSelector
          listDisplayName="Optimization"
          idFieldName="automationStrategyId"
          nameFieldName="name"
          searchFieldHintText="Refine by automation strategy"
          searchFieldNotFoundText="No automation strategies were found"
          listItems={adAutomationStrategies}
          selectedListItems={filterInfo.automationStrategyId || []}
          onSelectedListItemsChange={(arg) => handleSelect(arg)}
        />

        <LargeMuiButton secondary style={{ marginBottom: 14, minHeight: 40 }} onClick={clearAllFilters}>
          Clear Filters
        </LargeMuiButton>
      </div>
    </Slide>
  );
};

export default withBus('eventBus')(SlidedSidebarFilter);
