import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { StacklineSizing, useStacklineTheme } from '@stackline/ui';
import _isNumber from 'lodash/isNumber';

interface FlexProps extends Omit<BoxProps, 'display' | 'gap'> {
  /**
   *
   * @description The gap between flex items, if number treated as px
   */
  gap?: StacklineSizing | number;
}

/**
 * Wrapper around MUI Box for flex layouts
 */
export default function Flex({ children, gap, ...rest }: FlexProps) {
  const theme = useStacklineTheme();

  const getGap = (g: FlexProps['gap']): number | string => {
    if (typeof g === 'string') {
      return theme.spacing[g];
    }

    return _isNumber(g) ? `${g}px` : undefined;
  };

  return (
    <Box display="flex" gap={getGap(gap)} {...rest}>
      {children}
    </Box>
  );
}
