import { ThunkDispatch } from 'redux-thunk';

import Creators from './actions';
import ReduxStore from 'src/types/store/reduxStore';
import { ParentPlatformUtils, SingleParentPlatform } from 'src/store/modules/parentPlatform/platformUtils';
import { isDrive } from 'src/utils/app';

export const { setAllParentPlatform, setParentPlatform, requestParentPlatforms } = Creators;

export const fetchParentPlatforms = () => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
  dispatch(requestParentPlatforms());
  if (isDrive) {
    const data = await ParentPlatformUtils.getPlatforms();
    return dispatch(setAllParentPlatform(data));
  }

  return dispatch(setAllParentPlatform([]));
};

export const updateParentPlatform =
  (parentPlatform: SingleParentPlatform) => (dispatch: ThunkDispatch<ReduxStore, void, any>) =>
    Promise.resolve(dispatch(setParentPlatform(parentPlatform)));
