import React from 'react';
import { SlColumn } from '@stackline/ui';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import BeaconMetricSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconMetricSplineChart';
import CompliantAdsDonutChart from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Compliance/Advertising/CompliantAdsDonutChart';
import CompliantAdsGrid from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Compliance/Advertising/CompliantAdsGrid';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';

/**
 * The "Advertising" tab on the Compliance page
 */
const ComplianceAdsPage = () => {
  return (
    <BeaconPageContainer>
      <SlColumn spacing="lg" widths="full">
        <CommonSummaryInfoSubtitle title="Compliant Ads" />
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: '40%'
            }}
          >
            <CompliantAdsDonutChart />
          </div>
          <div
            style={{
              width: '60%'
            }}
          >
            <BeaconMetricSplineChart
              indexName="compliance-keywordaditem"
              fieldName="authorizedKeywordAdPercent"
              showTitleSummary={false}
              splineChartLoadingProps={{
                width: 543
              }}
              chartOptionsOverride={{
                chart: {
                  height: 300
                }
              }}
            />
          </div>
        </div>
        <CompliantAdsGrid />
      </SlColumn>
    </BeaconPageContainer>
  );
};

export default ComplianceAdsPage;
