import { FieldId } from './visualizationTypes';
import { ALL_AD_TYPES } from './constants';

/**
 * Pads advertising analytics data with zeros at the end
 * if the array length is less than 4, meaning there is
 * no data for certain ad types. We do this so that all
 * the bars show up in the graph.
 */
export const padEmptyData = (metrics: number[]) => {
  const NUM_AD_TYPES = ALL_AD_TYPES.length; // Pad with 0 if metrics length is less than total ad types
  const paddingLength = Math.max(NUM_AD_TYPES - metrics.length, 0);
  const paddedArray = metrics.concat(new Array(paddingLength).fill(0));
  return paddedArray.slice(0, NUM_AD_TYPES);
};

/**
 * Not all advertising analytics responses will have data for each
 * advertising type, so add on any missing ones so that all the
 * bars show up on the graph.
 */
export const padEmptyAdTypes = (initialFields: FieldId[]): FieldId[] => {
  return ALL_AD_TYPES.reduce((curFields, field) => {
    if (!initialFields.includes(field)) {
      return [...curFields, field];
    }
    return curFields;
  }, initialFields);
};
