import { makeStyles } from '@mui/styles';
import { BEACON_PRO_RIGHT_CONTENT_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

export const useStyles = makeStyles({
  defaultStyles: {
    '& .MuiTableCell-head': {
      paddingTop: '0px',
      fontSize: '12px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: '0.96px',
      paddingBottom: '16px',
      color: '#052849'
    },

    '& .MuiTableCell-body': {
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: '#052849'
    }
  },
  entityTableHeaderContainer: {
    height: '24px',
    display: 'flex',
    maxWidth: BEACON_PRO_RIGHT_CONTENT_WIDTH,
    justifyContent: 'space-between',
    '& div': {
      fontWeight: '700'
    },
    '& button': {
      padding: '0'
    }
  },
  titleGroup: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },

  buttonGroupContainer: {
    display: 'flex',
    flexDirection: 'row'
  },

  metricCellContainer: { display: 'flex', flexDirection: 'column', fontSize: '14px' }
});
