/* eslint react/prop-types: 0 */
import React from 'react';
import { OmniIncludeExcludeFuncWrapperProps } from 'src/components/Omni/Search/OmniSelectorWrapper';
import { addPersistentQueryParams } from 'src/utils/browser';
import { generateQueryParamObj } from 'src/components/Omni/OmniSegmentUtil';
import OmniKeywordSearch from 'src/components/Omni/Search/OmniKeywordSearch';
import queryString from 'qs';
import { autoCompleteSearch } from 'src/store/modules/omni/omniSegment/operation';
import { lowerFirst } from 'lodash';

export const typeOfKeyWordSearch = {
  zipCode: {
    title: 'Zip Code',
    hintText: 'Refine by zip code',
    include: 'include',
    exclude: 'exclude',
    keyForSegment: 'LocationKeywords',
    showIncludeExclude: true,
    shouldAutoComplete: false
  },
  state: {
    title: 'State',
    hintText: 'Refine by state',
    include: 'include',
    exclude: 'exclude',
    keyForSegment: 'LocationStateName',
    showIncludeExclude: true,
    shouldAutoComplete: true
  },
  county: {
    title: 'County',
    hintText: 'Refine by county',
    include: 'include',
    exclude: 'exclude',
    keyForSegment: 'LocationCounty',
    showIncludeExclude: true,
    shouldAutoComplete: true
  },
  CBSA: {
    title: 'CBSA Name',
    hintText: 'Refine by CBSA Name',
    include: 'include',
    exclude: 'exclude',
    keyForSegment: 'LocationCBSAName',
    showIncludeExclude: true,
    shouldAutoComplete: true
  },
  city: {
    title: 'City',
    hintText: 'Refine by city',
    include: 'include',
    exclude: 'exclude',
    keyForSegment: 'LocationCity',
    showIncludeExclude: true,
    shouldAutoComplete: true
  }
};

const OmniKeywordSearchWrapper: React.FC<OmniIncludeExcludeFuncWrapperProps> = ({
  parsedState,
  selectorType,
  history,
  retailer,
  mainTimePeriod,
  handleChange,
  nameForSegment,
  navEndPoint,
  handleRequestChange = () => {}
}) => {
  const selectorEntity = typeOfKeyWordSearch[selectorType];
  const { include, exclude, keyForSegment, title, showIncludeExclude, shouldAutoComplete, hintText } = selectorEntity;

  const includeDataKey = `${include}${keyForSegment}`;
  const excludeDataKey = `${exclude}${keyForSegment}`;
  const includeData = parsedState[includeDataKey] || [];
  const excludeData = parsedState[excludeDataKey] || [];

  let shouldInclude = true;
  const bothUncheck = (!includeData || includeData.length === 0) && (!excludeData || excludeData.length === 0);
  if (excludeData && excludeData.length > 0) {
    shouldInclude = false;
  }

  const keywordsData = shouldInclude ? includeData : excludeData;

  const handleIncludeExclude = (checkedType: string, checkedValue: boolean) => {
    if (!checkedValue) {
      return;
    }
    if (checkedType === 'include') {
      parsedState[includeDataKey] = [...includeData, ...excludeData];
      parsedState[excludeDataKey] = [];
    } else {
      parsedState[excludeDataKey] = [...includeData, ...excludeData];
      parsedState[includeDataKey] = [];
    }
    parsedState.name = nameForSegment;
    const newParamObj = generateQueryParamObj(parsedState, { key: '', values: [] });
    history.push(
      `/${navEndPoint}?${addPersistentQueryParams(retailer, mainTimePeriod)}&entityType=segment&${queryString.stringify(
        newParamObj
      )}`
    );
  };

  const handleAutoCompleteSearchFunc = async (searchWord: string) => {
    return autoCompleteSearch(`/omni/autoCompleteSuggestions?type=${lowerFirst(keyForSegment)}&term=${searchWord}`);
  };

  const handleClearAll = () => {
    parsedState[includeDataKey] = [];
    parsedState[excludeDataKey] = [];
    parsedState.name = nameForSegment;
    const newParamObj = generateQueryParamObj(parsedState, { key: '', values: [] });
    // newParamObj need name and queryId but for new requestBody don't need
    delete parsedState.name;
    delete parsedState.queryId;
    handleRequestChange(parsedState, { pageNumber: 1, pageSize: 20 });
    history.push(
      `/${navEndPoint}?${addPersistentQueryParams(retailer, mainTimePeriod)}&entityType=segment&${queryString.stringify(
        newParamObj
      )}`
    );
  };

  return (
    <OmniKeywordSearch
      headerDisplayName={title}
      idFieldName={shouldInclude ? includeDataKey : excludeDataKey}
      keywords={keywordsData.map((e) => ({ i: e }))}
      onKeywordsChange={handleChange}
      showIncludeExclude={showIncludeExclude}
      supportAutoComplete={shouldAutoComplete}
      searchFieldHintText={hintText}
      includeKeyWord={shouldInclude}
      bothUncheck={bothUncheck}
      handleIncludeExclude={handleIncludeExclude}
      autoCompleteSearchFunc={handleAutoCompleteSearchFunc}
      handleClearAll={handleClearAll}
    />
  );
};

export default OmniKeywordSearchWrapper;
