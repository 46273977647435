import { useStacklineTheme } from '@stackline/ui';
import React, { useCallback, useMemo, memo } from 'react';
import BeaconChartWithLegend, {
  BeaconChartWithLegendProps
} from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import SplineChartLoading from 'src/components/BeaconRedesignComponents/SplineChartLoading/SplineChartLoading';
import TopEntityChartV2, {
  TopEntityV2ChartProps
} from 'src/components/BeaconRedesignComponents/TopEntityChartV2/TopEntityChartV2';
import { useAppSelector } from 'src/utils/Hooks';
import useGetAdvancedSearchFieldKey from 'src/utils/Hooks/useGetAdvancedSearchFieldKey';
import { getAppName } from 'src/utils/app';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import useAdvancedSearchData, { UseAdvancedSearchDataArgs } from 'src/serverProxy/useAdvancedSearchData';
import convertBarChartSeriesToDelimitedData from 'src/components/Charts/GenericChart/SeriesConverters/barChart';
import { Series, SeriesColumnOptions } from 'highcharts';
import saveAs from 'file-saver';
import { EntityCategoryDatum } from 'src/components/BeaconRedesignComponents/TopEntityChartV2/types';

export interface BeaconMetricColumnChartProps {
  beaconChartWithLegendPropsBuilder?: (
    props: Omit<BeaconChartWithLegendProps, 'children'>
  ) => Omit<BeaconChartWithLegendProps, 'children'>;
  useAdvancedSearchDataArgs: Omit<UseAdvancedSearchDataArgs, 'indexName'>;
  fieldName: string;
  indexName: string;
  /**
   * Post-process data before mapping it to the chart series but after the default mapping behavior.
   */
  postProcessData?: (data: any) => any;
  /**
   * Format the x-axis labels by providing a custom formatter
   */
  xAxisFormatter?: (value: EntityCategoryDatum) => string;
  /**
   * Pre-process data before mapping it to the chart series
   */
  preProcessData?: (data: any) => any;
}

/**
 * Shows a column chart that fetches data from advanced search
 */
export default memo(function BeaconMetricColumnChart({
  beaconChartWithLegendPropsBuilder,
  useAdvancedSearchDataArgs,
  fieldName,
  indexName,
  postProcessData,
  xAxisFormatter,
  preProcessData
}: BeaconMetricColumnChartProps) {
  const getAdvancedSearchFieldKey = useGetAdvancedSearchFieldKey();
  const theme = useStacklineTheme();
  const { displayName: mainTimeDisplayName } = useAppSelector((state) => state.mainTimePeriod);
  const { displayName: comparisonTimeDisplayName } = useAppSelector((state) => state.comparisonTimePeriod);
  const retailer = useAppSelector((state) => state.retailer);

  const { data, isLoading } = useAdvancedSearchData({
    ...useAdvancedSearchDataArgs,
    indexName
  });

  const field = useMemo(() => INDEX_FIELDS.getField(getAppName(), indexName, fieldName), [fieldName, indexName]);

  // Preprocess data before mapping it to the chart series
  const preprocessedPrimaryData = preProcessData ? preProcessData(data) : data;
  const preprocessedComparisonData = preProcessData ? preProcessData(data) : data;

  const mappedPrimaryData = preprocessedPrimaryData.map((row) => [
    row.metadata.name,
    row.values[getAdvancedSearchFieldKey(indexName, fieldName)]
  ]);
  const mappedComparisonData = preprocessedComparisonData.map((row) => [
    row.metadata.name,
    row.comparisonValues[getAdvancedSearchFieldKey(indexName, fieldName)]
  ]);

  const primaryData = postProcessData ? postProcessData(mappedPrimaryData) : mappedPrimaryData;
  const comparisonData = postProcessData ? postProcessData(mappedComparisonData) : mappedComparisonData;

  const { groupByField } = useAdvancedSearchDataArgs;
  const categories = data.map((row) => {
    return {
      name: row.metadata.name,
      id: row.fieldId,
      type: groupByField === 'stacklineSku' ? 'product' : groupByField.toLowerCase().replace('id', ''), // subCategoryId -> subcategory, brandId -> brand
      retailerSku: groupByField === 'stacklineSku' ? row.metadata.product.retailerSku : null,
      stacklineSku: groupByField === 'stacklineSku' ? row.metadata.product.stacklineSku : null
    };
  });

  const convertSeriesToDelimitedData = () => {
    try {
      const csvData = convertBarChartSeriesToDelimitedData(
        [
          {
            type: 'column',
            color: theme.colors.secondary,
            data: comparisonData,
            metricType: field.metricType,
            timePeriod: comparisonTimeDisplayName,
            name: field.displayName,
            categories
          } as unknown as Series,
          {
            type: 'column',
            color: theme.colors.primary,
            data: primaryData,
            metricType: field.metricType,
            timePeriod: mainTimeDisplayName,
            name: field.displayName,
            categories
          } as unknown as Series
        ],
        retailer
      );
      const blob = new Blob([csvData], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'chart_data.csv');
    } catch (e) {
      console.error('error: ', e);
    }
    return Promise.resolve();
  };

  const getBeaconChartWithLegendProps = useCallback(() => {
    const defaultProps: Omit<BeaconChartWithLegendProps, 'children'> = {
      title: '',
      primaryLegendProps: {
        displayName: mainTimeDisplayName
      },
      comparisonLegendProps: {
        displayName: comparisonTimeDisplayName
      },
      loading: isLoading,
      loadingComponent: <SplineChartLoading />,
      convertSeriesToDelimitedData
    };

    if (beaconChartWithLegendPropsBuilder) {
      return beaconChartWithLegendPropsBuilder(defaultProps);
    }

    return defaultProps;
  }, [beaconChartWithLegendPropsBuilder, comparisonTimeDisplayName, isLoading, mainTimeDisplayName]);

  const getTopEntityChartV2Props = useCallback(() => {
    const defaultProps: TopEntityV2ChartProps = {
      chartSeries: [
        {
          type: 'column',
          color: theme.colors.secondary,
          data: comparisonData,
          metricType: field.metricType,
          categories
        } as SeriesColumnOptions,
        {
          type: 'column',
          color: theme.colors.primary,
          data: primaryData,
          metricType: field.metricType,
          categories
        } as SeriesColumnOptions
      ],
      xAxisFormatter
    };

    return defaultProps;
  }, [
    theme.colors.secondary,
    theme.colors.primary,
    comparisonData,
    field.metricType,
    categories,
    primaryData,
    xAxisFormatter
  ]);

  const beaconChartWithLegendProps = useMemo(() => getBeaconChartWithLegendProps(), [getBeaconChartWithLegendProps]);
  const topEntityChartV2Props = useMemo(() => getTopEntityChartV2Props(), [getTopEntityChartV2Props]);

  return (
    <BeaconChartWithLegend {...beaconChartWithLegendProps}>
      <TopEntityChartV2 {...topEntityChartV2Props} />
    </BeaconChartWithLegend>
  );
});
