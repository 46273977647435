import types from './types';

// ------------------------------------
// Reducer
// ------------------------------------

export function adPlatformSettings(state = [], action) {
  switch (action.type) {
    case types.RECEIVE_ADPLATFORM_SETTINGS:
      return action.adPlatformSettings;
    case types.CLEAR_ADPLATFORM_SETTINGS:
      return [];
    default:
      return state;
  }
}
