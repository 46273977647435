import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _prop from 'lodash/property';
import queryString from 'qs';

import { mapQueryToConditions } from 'src/utils/segments';
import { shouldShowCriteo } from 'src/utils/app';

//  NON dispatching helper functions
export function generateFilterUrlString(filterValue, filterType, queryParams) {
  const parsedFilterParams = queryParams.filter && queryString.parse(JSON.parse(queryParams.filter));
  const defaultFilterObj = {
    filter: { ...parsedFilterParams }
  };
  switch (filterType) {
    case 'region':
      if (filterValue.length > 0) {
        defaultFilterObj.filter.rg = [];
        filterValue.forEach((item) => defaultFilterObj.filter.rg.push({ i: `${item.id}` }));
      } else {
        delete defaultFilterObj.filter.rg;
      }
      break;
    case 'country':
      if (filterValue.length > 0) {
        defaultFilterObj.filter.co = [];
        filterValue.forEach((item) => defaultFilterObj.filter.co.push({ i: `${item.id}` }));
      } else {
        delete defaultFilterObj.filter.co;
      }
      break;
    case 'brand':
      if (filterValue.length > 0) {
        defaultFilterObj.filter.b = [];
        filterValue.forEach((item) => defaultFilterObj.filter.b.push({ i: `${item.id}` }));
      } else {
        delete defaultFilterObj.filter.b;
      }
      break;
    case 'category':
      if (filterValue.length > 0) {
        defaultFilterObj.filter.c = [];
        filterValue.forEach((item) => defaultFilterObj.filter.c.push({ i: `${item.id}` }));
      } else {
        delete defaultFilterObj.filter.c;
      }
      break;
    case 'promoType':
      if (filterValue.length > 0) {
        defaultFilterObj.filter.pt = []; // verify
        filterValue.forEach((item) => defaultFilterObj.filter.pt.push({ i: `${item.name}` }));
      } else {
        delete defaultFilterObj.filter.pt;
      }
      break;
    case 'issueTypeNormalized':
      if (filterValue.length > 0) {
        defaultFilterObj.filter.it = [];
        filterValue.forEach((item) => defaultFilterObj.filter.it.push({ i: `${item.name}` }));
      } else {
        delete defaultFilterObj.filter.it;
      }
      break;
    case 'vendorCode':
      if (filterValue.length > 0) {
        defaultFilterObj.filter.vc = [];
        filterValue.forEach((item) => defaultFilterObj.filter.vc.push({ i: `${item.name}` }));
      } else {
        delete defaultFilterObj.filter.vc;
      }
      break;
    case 'chargeBackStatus':
      if (filterValue.length > 0) {
        defaultFilterObj.filter.cs = [];
        filterValue.forEach((item) => defaultFilterObj.filter.cs.push({ i: `${item.name}` }));
      } else {
        delete defaultFilterObj.filter.cs;
      }
      break;
    case 'subcategory':
      if (filterValue.length > 0) {
        defaultFilterObj.filter.sc = [];
        filterValue.forEach((item) => defaultFilterObj.filter.sc.push({ i: `${item.id}` }));
      } else {
        delete defaultFilterObj.filter.sc;
      }
      break;
    case 'segment':
      if (filterValue.id) {
        defaultFilterObj.filter.sg = [];
        defaultFilterObj.filter.sg.push({ i: `${filterValue.id}` });
      } else {
        delete defaultFilterObj.filter.sg;
      }
      break;
    case 'keywordSegment':
      if (filterValue.id) {
        defaultFilterObj.filter.ks = [];
        defaultFilterObj.filter.ks.push({ i: `${filterValue.id}` });
      } else {
        delete defaultFilterObj.filter.ks;
      }
      break;
    case 'keywordCategories':
      if (filterValue.length > 0) {
        defaultFilterObj.filter.kc = [];
        filterValue.forEach((item) => defaultFilterObj.filter.kc.push({ i: `${item.id}` }));
      } else {
        delete defaultFilterObj.filter.kc;
      }
      break;
    case 'platform':
      if (filterValue.length > 0) {
        defaultFilterObj.filter.pl = [];
        filterValue.forEach((item) => defaultFilterObj.filter.pl.push({ i: `${item.id}` }));
      } else {
        delete defaultFilterObj.filter.pl;
      }
      break;
    case 'retailer':
      if (filterValue.length > 0) {
        defaultFilterObj.filter.r = [];
        filterValue.forEach((item) => defaultFilterObj.filter.r.push({ i: `${item.id}` }));
      } else {
        delete defaultFilterObj.filter.r;
      }

      break;
    case 'instacartRetailer':
      if (filterValue.length > 0) {
        defaultFilterObj.filter.ir = [];
        filterValue.forEach((item) => defaultFilterObj.filter.ir.push({ i: `${item.id}` }));
      } else {
        delete defaultFilterObj.filter.ir;
      }

      break;
    case 'parentPlatform':
      if (shouldShowCriteo()) {
        if (filterValue.length > 0) {
          defaultFilterObj.filter.pp = [];
          filterValue.forEach((item) => defaultFilterObj.filter.pp.push({ i: `${item.id}` }));
        } else {
          delete defaultFilterObj.filter.pp;
        }
      }
      break;
    case 'retailPrice':
      if (filterValue) {
        defaultFilterObj.filter.rp = [];
        defaultFilterObj.filter.rp.push({ min: `${filterValue.minValue}` }, { max: `${filterValue.maxValue}` });
      } else {
        delete defaultFilterObj.filter.rp;
      }
      break;
    case 'businessunit':
      if (filterValue) {
        defaultFilterObj.filter.bu = filterValue.map((selectedBUId) => ({ i: selectedBUId }));
      } else {
        delete defaultFilterObj.filter.bu;
      }
      break;
    default:
      break;
  }
  const stringifiedFilterParams = queryString.stringify({ filter: JSON.stringify({ ...defaultFilterObj.filter }) });
  queryParams.filter = JSON.stringify({ ...defaultFilterObj.filter });
  const generatedFilterParams = !_isEmpty(defaultFilterObj.filter) ? `&${stringifiedFilterParams}` : '';
  // Both of these parameters can be used in a window.history.pushState() call in order to update the url without reload
  return { generatedFilterParams };
}

/**
 * This function takes the query params found in the URL and maps them into the `filters` key of Redux.  The filters are
 * stored under the `filters` param as a JSON-encoded object.  The keys of that object are short, and then mappings
 * between the query param keys and the keys of the `filters` object in Redux are defined in `queryFilterMappings`.
 */
export function mapQueryFiltersToRedux({
  queryParams,
  filters,
  retailer,
  parentPlatform,
  categories = [],
  segments,
  platforms = [],
  subcategories = [],
  user,
  brands = [],
  availableRetailers = [],
  promoTypes = [],
  chargeBackIssueTypes = [],
  vendorCodes = [],
  chargeBackStatuses = []
}) {
  const { id: currentRetailerId } = retailer;
  const {
    config: { allPlatformIdsByRetailerId }
  } = user;
  const mergedSegments = [..._cloneDeep(segments.mySegments), ..._cloneDeep(segments.teamSegments)];
  const queryFilterMappings = {
    c: { name: 'category', value: categories },
    b: { name: 'brand', value: brands },
    r: { name: 'retailer', value: availableRetailers },
    sc: { name: 'subcategory', value: subcategories },
    pl: { name: 'platform', value: platforms },
    sg: { name: 'segment', value: mergedSegments },
    rp: { name: 'retailPrice', value: filters.retailPrice },
    bu: { name: 'businessUnits', value: null },
    pt: { name: 'promoType', value: promoTypes },
    it: { name: 'chargeBackIssueType', value: chargeBackIssueTypes },
    vc: { name: 'vendorCode', value: vendorCodes },
    cs: { name: 'chargeBackStatus', value: chargeBackStatuses }
  };
  if (shouldShowCriteo()) {
    queryFilterMappings.pp = { name: 'parentPlatform', value: parentPlatform };
  }

  const updatedFilters = { ...filters };

  const parsedFilterParams = queryParams.filter && queryString.parse(JSON.parse(queryParams.filter));
  if (parsedFilterParams) {
    Object.keys(queryFilterMappings).forEach((key) => {
      if (parsedFilterParams[key]) {
        let currentFilters = [];
        if (key === 'rp') {
          currentFilters = {
            minValue: parsedFilterParams[key][0].min,
            maxValue: parsedFilterParams[key][1].max
          };
          updatedFilters[`${queryFilterMappings[key].name}`] = currentFilters;
          return;
        } else if (key === 'bu') {
          updatedFilters[`${queryFilterMappings[key].name}`] = parsedFilterParams[key].map(_prop('i'));
          return;
        }
        const filtersForKey = queryFilterMappings[key].value; // all possible selections
        const queryFilterMapping = _isArray(filtersForKey) ? filtersForKey : Object.values(filtersForKey);
        queryFilterMapping.forEach((val) => {
          // eslint-disable-next-line
          if (parsedFilterParams[key].find(({ i }) => i == val.id)) {
            if (key === 'sg') {
              const conditions = mapQueryToConditions(val.segment, retailer);
              currentFilters = {
                ...conditions,
                dn: val.segment.dn,
                id: val.segment.id
              };
            } else if (key === 'bu' || key === 'b' || key === 'r' || (shouldShowCriteo() && key === 'pp')) {
              // delete the b and handle like all other filters?
              currentFilters.push(val.id);
            } else {
              if (key === 'pl') {
                // The available platforms differ depending on the current retailer, so we need to check that all of
                // the provided platform IDs are available on the current retailer before applying them.
                const allPlatformIdsForCurrentRetailer = _get(allPlatformIdsByRetailerId, currentRetailerId, []);
                if (!allPlatformIdsForCurrentRetailer.includes(val.id)) {
                  return;
                }
              }

              currentFilters.push(val);
            }
          }
        });

        updatedFilters[`${queryFilterMappings[key].name}`] = currentFilters;
      } else {
        updatedFilters[`${queryFilterMappings[key].name}`] = null;
      }
    });

    return updatedFilters;
  }

  updatedFilters.category = null;
  updatedFilters.brand = null;
  updatedFilters.segment = null;
  updatedFilters.platform = null;
  updatedFilters.subcategory = null;
  updatedFilters.retailPrice = null;
  updatedFilters.businessUnits = null;
  updatedFilters.promoTypes = null;
  updatedFilters.chargeBackIssueTypes = null;
  updatedFilters.vendorCodes = null;
  updatedFilters.chargeBackStatuses = null;
  return updatedFilters;
}

export const mapQueryFiltersToReduxOmni = (
  queryParams,
  filters,
  omniRetailers = [],
  omniSubcategories = [],
  omniCategories = [],
  omniBrandsFollowing = [],
  omniSegmentData = [],
  omniKeywordSegmentData = [],
  omniKeywordCategoriesData = [],
  omniCountriesFollowing = [],
  omniRegionsFollowing = [],
  omniInstacartRetailers = []
) => {
  const queryFilterMappings = {
    co: { name: 'country', value: omniCountriesFollowing.map((e) => ({ id: e.countryCode })) },
    rg: { name: 'region', value: omniRegionsFollowing.map((e) => ({ id: e.regionCode })) },
    c: { name: 'category', value: omniCategories.map((e) => ({ id: e.categoryId })) },
    b: { name: 'brand', value: omniBrandsFollowing.map((e) => ({ id: e.brandId })) },
    r: { name: 'retailer', value: omniRetailers.map((e) => ({ id: e.retailerId })) },
    sc: { name: 'subcategory', value: omniSubcategories.map((e) => ({ id: e.subCategoryId })) },
    sg: { name: 'segment', value: omniSegmentData.map((e) => ({ id: e.queryId })) },
    ks: { name: 'keywordSegment', value: omniKeywordSegmentData.map((e) => ({ id: e.queryId })) },
    kc: { name: 'keywordCategories', value: omniKeywordCategoriesData.map((e) => ({ id: e.keywordCategoryId })) },
    ir: { name: 'instacartRetailer', value: omniInstacartRetailers.map((e) => ({ id: e.instacartRetailerId })) }
  };
  const updatedFilters = { ...filters };

  const parsedFilterParams = queryParams.filter && queryString.parse(JSON.parse(queryParams.filter));
  if (parsedFilterParams) {
    Object.keys(queryFilterMappings).forEach((key) => {
      if (parsedFilterParams[key]) {
        const currentFilters = [];
        const filtersForKey = queryFilterMappings[key].value; // all possible selections
        const queryFilterMapping = _isArray(filtersForKey) ? filtersForKey : Object.values(filtersForKey);
        queryFilterMapping.forEach((val) => {
          if (parsedFilterParams[key].find(({ i }) => String(i) === String(val.id))) {
            if (
              key === 'c' ||
              key === 'b' ||
              key === 'r' ||
              key === 'sc' ||
              key === 'sg' ||
              key === 'ks' ||
              key === 'kc' ||
              key === 'rg' ||
              key === 'co' ||
              key === 'ir'
            ) {
              currentFilters.push(val.id);
            }
          }
        });
        updatedFilters[`${queryFilterMappings[key].name}`] = currentFilters;
      } else {
        updatedFilters[`${queryFilterMappings[key].name}`] = null;
      }
    });
    return updatedFilters;
  }

  updatedFilters.category = null;
  updatedFilters.brand = null;
  updatedFilters.country = null;
  updatedFilters.region = null;
  updatedFilters.segment = null;
  updatedFilters.keywordCategories = null;
  updatedFilters.keywordSegment = null;
  updatedFilters.platform = null;
  updatedFilters.subcategory = null;
  updatedFilters.retailPrice = null;
  updatedFilters.businessUnits = null;
  updatedFilters.promoTypes = null;
  updatedFilters.chargeBackIssueTypes = null;
  updatedFilters.vendorCodes = null;
  updatedFilters.chargeBackStatuses = null;
  updatedFilters.instacartRetailer = null;
  return updatedFilters;
};
