import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

//  Used with AgGrid to format cell in the Promotions table
class PromotionsCellFormatter extends React.Component {
  static propTypes = {
    // headerName: PropTypes.string.isRequired,
    colDef: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
  };

  render() {
    const { headerName } = this.props.colDef;
    const {
      currentDiscountPrice,
      previousDiscountPrice,
      discountPricePercent,
      currentSalesLift,
      previousSalesLift,
      salesLiftPercent,
      parsedPromoType,
      parsedDealDate
    } = this.props.data;
    const { value: percentSales, className: salesClassName } = salesLiftPercent;
    const { value: percentPrice, className: priceClassName } = discountPricePercent;

    return (
      <Fragment>
        {headerName.includes('Promotions') && (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
              justifyContent: 'row-reverse',
              height: '65px'
            }}
          >
            <div style={{ alignSelf: 'left' }}>{parsedPromoType}</div>
            <div style={{ alignSelf: 'left' }}>{parsedDealDate}</div>
          </div>
        )}
        {headerName.includes('Discount') && (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
              justifyContent: 'row-reverse',
              height: '65px'
            }}
          >
            <div>{currentDiscountPrice}</div>
            <div>
              <span className={`promotions-percent--${priceClassName}`}>{percentPrice}</span> from{' '}
              {previousDiscountPrice}
            </div>
          </div>
        )}
        {headerName.includes('Sales Lift') && (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
              justifyContent: 'row-reverse',
              height: '65px'
            }}
          >
            <div>{currentSalesLift}</div>
            <div>
              <span className={`promotions-percent--${salesClassName}`}>{percentSales}</span> from {previousSalesLift}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default PromotionsCellFormatter;
