/**
 * Entity table header display names for the sales tables
 */
export const SALES_TABLE_HEADER_DISPLAY_NAMES = {
  RETAIL_SALES: 'Retail \nSales',
  UNITS_SOLD: 'Units \nSold',
  WHOLESALE_SALES: 'Wholesale \nSales',
  RETAIL_PRICE: 'Retail \nPrice',
  INSTOCK_RATE: 'In-Stock \nRate',
  UNITS_ON_HAND: 'Units \nOn Hand',
  RETAILER_GROSS_MARGIN: 'Retailer \nMargin',
  BRAND_GROSS_MARGIN: 'Brand \nMargin',
  BRAND_COGS: 'Brand \nCOGS',
  WHOLESALE_PRICE: 'Wholesale \nPrice',
  BRAND_COGS_PER_UNIT: 'Brand COGS \nPer Unit',
  MAP_PRICE: 'MAP \nPrice',
  MAP_PRICE_VIOLATION: 'Violations',
  INVENTORY_WEEKS_ON_HAND: 'Weeks \nOn Hand',
  INVENTORY_RETAIL_VALUE: 'On Hand - \nRetail'
};
