import { Widget } from 'src/types/application/widgetTypes';
import _get from 'lodash/get';

export class ProductsWithZerosHelper {
  // eslint-disable-next-line class-methods-use-this
  public fillProductsWithZeroMetrics(
    widget: Widget,
    showZeroProducts: boolean,
    cloneResult: any,
    adProductDocumentsByStacklineSkuMap: any
  ) {
    const showZeroProductsFlag = widget.data.showZeroProducts || showZeroProducts;
    if (widget.data.showZeroProducts) {
      // TODO: Unexpected side-effect
      cloneResult.totalResultCount = undefined;
    }
    if (showZeroProductsFlag && !cloneResult.hasMoreRows) {
      const skusWithData = cloneResult.data.map((item: any) => item.entity.stacklineSku);
      Object.entries(adProductDocumentsByStacklineSkuMap).forEach(([key, product]) => {
        if (!skusWithData.includes(key)) {
          cloneResult.data.push(ProductsWithZerosHelper.constructZeroProduct(product));
        }
      });
    }
  }

  private static constructZeroProduct(product: any) {
    const childDocument = product.extendedAttributes.childDocuments[0];
    const productEntity = childDocument.product || childDocument;
    const stacklineSkuLowerCase = _get(productEntity, ['stacklineSku'], '').toLowerCase();
    productEntity.id = stacklineSkuLowerCase;
    const zeroProduct = {
      entity: {
        ...productEntity,
        name: productEntity.title,
        type: 'product'
      },
      id: stacklineSkuLowerCase
    };
    return zeroProduct;
  }
}
