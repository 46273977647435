import React from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import './List.scss';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';

const ListGrid = ({ children, className, onWaypointEntered, handleNextPageClick, handlePrevPageClick, pageNumber, showReclassifyHeader, hideNextPageButton }) => (
  <div className={`list-grid ${className}`}>
    {children.map((element, index) => (
      <div key={element.key} className={`list-grid__item ${className}__item`}>
        {/* adding waypoint to the last element of the grid */}
        {children.length - 1 === index ? <Waypoint onEnter={onWaypointEntered} /> : null}
        {element}
      </div>
    ))}
    {showReclassifyHeader ? <div>
      <p>Page No : {pageNumber} </p>
      <div>
        {pageNumber > 1 ? <LargeMuiButton label="Prev Page" onClick={handlePrevPageClick} /> : null}
        {!hideNextPageButton ? <LargeMuiButton label="Next Page" onClick={handleNextPageClick} style={{ marginLeft: "20px" }} /> : null}
      </div>
    </div> : null}
  </div>
);

ListGrid.defaultProps = {
  className: '',
  onWaypointEntered: () => {},
  showLoadMoreButton: false
};

ListGrid.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  className: PropTypes.string,
  onWaypointEntered: PropTypes.func,
  showLoadMoreButton: PropTypes.bool,
  handleNextPageClick: PropTypes.func.isRequired,
  handlePrevPageClick: PropTypes.func.isRequired,
  pageNumber: PropTypes.number,
  showReclassifyHeader: PropTypes.bool.isRequired,
  hideNextPageButton: PropTypes.bool
};

export default ListGrid;
