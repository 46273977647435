import _cloneDeep from 'lodash/cloneDeep';
import { AppName } from 'sl-api-connector/types';

import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { PageLayout, Widget } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import { buildTrendWidgetConfig } from 'src/components/Layout/LayoutUtil';
import OmniTrendChart from 'src/components/EntityPage/TrendChart/OmniTrendChart';
import { buildOmniTopEntitiesWidgetConfig } from 'src/components/EntityPage/TopEntitiesChart/OmniTopEntitesChartUtil';
import OmniFilterWrapper from 'src/components/Omni/OmniFilter/OmniFIlterWrapper';
import { buildOmniProductsWidget } from 'src/components/EntityGrid/EntityGrid/OmniEntityGridUtils';
import { omniGridField, omniTableViewField } from 'src/components/EntityGrid/EntityGrid/omniGridField';
import { store } from 'src/main';
import { ExportIcon } from 'src/components/SvgIcons';
import PageContextMenu from 'src/components/PageContextMenu';

const { omniRegionsFollowing, omniCountriesFollowing } = store.getState();
const INVENTORY_INDEX_NAME = 'inventory';

export const getLayoutForEntity = (app: ReduxStore['app'], subtatb: string, entityType: string): PageLayout => {
  const widgets: Widget[] = [];

  const indexName = INVENTORY_INDEX_NAME;
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(AppName.Omni, indexName, 'weekId'));
  const fulfillmentTypeList = ['pickUp', 'delivery', 'shipping'];
  const fulfillmentTypeInStock = ['pickupInStockRate', 'deliveryInStockRate', 'shippingInStockRate'];
  const fulfillmentTypeInStockByStore = ['shippingStoresInStock', 'deliveryStoresInStock', 'pickupStoresInStock'];
  const fulfillmentTypeInStockByStoreWithAverage = ['avgShippingStoresInStock', ...fulfillmentTypeInStockByStore];
  const fulfillmentTypeInStockWithAverage = ['avgInStockRate', ...fulfillmentTypeInStock];
  const fulfillmentTypeOutOfStock = ['pickupOutOfStockRate', 'deliveryOutOfStockRate', 'shippingOutOfStockRate'];

  const fulfillmentTypeOutOfStockWithAverage = ['avgOutOfStockRate', ...fulfillmentTypeOutOfStock];
  const fulfillmentTypeOutOfStockByStore = [
    'shippingStoresOutOfStock',
    'deliveryStoresOutOfStock',
    'pickUpStoresOutOfStock'
  ];

  const fulfillmentTypeOutOfStockByStoreWIthAverage = ['avgStoresOutOfStock', ...fulfillmentTypeOutOfStockByStore];
  const fulfillmentTypeAvailability = ['pickUpAvailability', 'deliveryAvailability', 'shippingAvailability'];

  const geoGroups = [
    ...(entityType !== 'retailer' && omniRegionsFollowing.length > 1 ? ['region'] : []),
    ...(entityType !== 'retailer' && omniCountriesFollowing.length > 1 ? ['country'] : []),
    'state',
    'city'
  ];

  const subMenuItems = [];
  if (entityType === 'segment' || entityType === 'retailer') {
    const eventName = `downloadOmniData${entityType === 'segment' ? 'Segments' : ''}`;
    subMenuItems.push({
      icon: ExportIcon,
      text: 'Add to Download List',
      eventName
    });
  }

  widgets.push({
    CustomComponent: OmniFilterWrapper,
    name: 'omniFilter',
    view: {
      name: 'omniFilter',
      style: { display: 'flex', justifyContent: 'end', marginTop: 41 },
      shouldShowFilter: !(entityType === 'segment')
    },
    data: {}
  });

  switch (subtatb) {
    case 'inStock':
      widgets.push(
        buildTrendWidgetConfig(app, indexName, { type: entityType }, 'weekId', ['inStockSummaryTrend'], weekIdField, {
          CustomComponent: OmniTrendChart,
          view: {
            container: {
              style: {
                marginBottom: 100,
                marginTop: 30,
                verticalAlign: 'top'
              }
            }
          },
          data: { fulfillmentTypeList }
        })
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          ['orderType'],
          ['inStockSummaryTopEntities'],
          weekIdField,
          {
            view: {
              fetchList: fulfillmentTypeInStock.map((metricFieldName) =>
                _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, metricFieldName, entityType))
              )
            }
          }
        )
      );

      // in retailer view, it is already showing in the topEntityCharts above
      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            ['retailerId'],
            fulfillmentTypeInStockWithAverage,
            weekIdField,
            {
              view: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                }
              }
            }
          )
        );
      }

      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            ['retailerId'],
            fulfillmentTypeInStockByStoreWithAverage,
            weekIdField,
            {
              view: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                }
              }
            }
          )
        );
      }

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          fulfillmentTypeInStockWithAverage,
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          fulfillmentTypeInStockByStoreWithAverage,
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(...buildOmniProductsWidget(omniGridField.inStockRate, omniTableViewField.inStockRate, true));

      if (entityType === 'segment' || entityType === 'retailer') {
        widgets.push({
          CustomComponent: PageContextMenu,
          name: 'pageContextMenu',
          view: {
            name: 'pageContextMenu',
            buttons: subMenuItems
          },
          data: {}
        });
      }
      break;
    case 'outOfStock':
      widgets.push(
        buildTrendWidgetConfig(
          app,
          indexName,
          { type: entityType },
          'weekId',
          ['outOfStockRateSummaryTrend'],
          weekIdField,
          {
            CustomComponent: OmniTrendChart,
            view: {
              container: {
                style: {
                  marginBottom: 100,
                  marginTop: 30,
                  verticalAlign: 'top'
                }
              }
            },
            data: { fulfillmentTypeList }
          }
        )
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          ['orderType'],
          ['outOfStockRateSummaryTopEntities'],
          weekIdField,
          {
            view: {
              fetchList: fulfillmentTypeOutOfStock.map((metricFieldName) =>
                _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, metricFieldName, entityType))
              )
            }
          }
        )
      );

      // in retailer view, it is already showing in the topEntityCharts above
      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            ['retailerId'],
            fulfillmentTypeOutOfStockWithAverage,
            weekIdField,
            {
              view: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                }
              }
            }
          )
        );
      }

      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            ['retailerId'],
            fulfillmentTypeOutOfStockByStoreWIthAverage,
            weekIdField,
            {
              view: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                }
              }
            }
          )
        );
      }

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          fulfillmentTypeOutOfStockWithAverage,
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          fulfillmentTypeOutOfStockByStoreWIthAverage,
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(...buildOmniProductsWidget(omniGridField.outOfStockRate, omniTableViewField.outOfStockRate, true));
      if (entityType === 'retailer') {
        widgets.push({
          CustomComponent: PageContextMenu,
          name: 'pageContextMenu',
          view: {
            name: 'pageContextMenu',
            buttons: subMenuItems
          },
          data: {}
        });
      }

      break;
    case 'placement':
      widgets.push(
        buildTrendWidgetConfig(app, indexName, { type: entityType }, 'weekId', ['placementRate'], weekIdField, {
          CustomComponent: OmniTrendChart,
          view: {
            container: {
              style: {
                marginBottom: 100,
                marginTop: 30,
                verticalAlign: 'top'
              }
            }
          }
        })
      );

      widgets.push(
        buildTrendWidgetConfig(app, indexName, { type: entityType }, 'weekId', ['storesWithPlacement'], weekIdField, {
          CustomComponent: OmniTrendChart
        })
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          ['retailerId'],
          ['placementRate'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          ['retailerId'],
          ['storesWithPlacement'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          ['placementRate'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          ['storesWithPlacement'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(...buildOmniProductsWidget(omniGridField.placementRate, omniTableViewField.outOfStockRate, true));

      break;
    case 'availability':
      widgets.push(
        buildTrendWidgetConfig(app, indexName, { type: entityType }, 'weekId', ['availabilityRate'], weekIdField, {
          CustomComponent: OmniTrendChart,
          view: {
            container: {
              style: {
                marginBottom: 100,
                marginTop: 30,
                verticalAlign: 'top'
              }
            }
          }
        })
      );

      widgets.push(
        buildTrendWidgetConfig(
          app,
          indexName,
          { type: entityType },
          'weekId',
          ['storesWithAvailability'],
          weekIdField,
          {
            CustomComponent: OmniTrendChart
          }
        )
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          [...(entityType !== 'retailer' ? ['retailerId'] : []), 'orderType'],
          ['availabilityRate'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              },
              fetchList: fulfillmentTypeAvailability.map((metricFieldName) =>
                _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, metricFieldName, entityType))
              )
            }
          }
        )
      );
      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            ['retailerId'],
            ['storesWithAvailability'],
            weekIdField,
            {
              view: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                }
              }
            }
          )
        );
      }

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          ['availabilityRate'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      // in retailer view this charts is duplication of the first trendCharts

      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            geoGroups,
            ['storesWithAvailability'],
            weekIdField,
            {
              view: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                }
              }
            }
          )
        );
      }

      widgets.push(
        ...buildOmniProductsWidget(omniGridField.availabilityRate, omniTableViewField.availabilityRate, true)
      );

      if (entityType === 'segment') {
        widgets.push({
          CustomComponent: PageContextMenu,
          name: 'pageContextMenu',
          view: {
            name: 'pageContextMenu',
            buttons: subMenuItems
          },
          data: {}
        });
      }

      break;
    default:
      break;
  }

  return {
    widgets,
    CustomPageContainer: GenericPageContainer as any,
    enableComparison: false
  };
};
