export const PHONE_NUMBER_VALIDATOR_REGEX = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/; // eslint-disable-line
export const NUMBER_VALIDATOR_REGEX = /^\d+$/;
export const LETTER_VALIDATOR_REGEX = /^[a-zA-Z\s]*$/;
export const CARD_NUMBER_VALIDATOR_REGEX =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
export const EXPIRATION_DATE_VALIDATOR_REGEX = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;
export const EXPIRATION_MONTH_VALIDATOR_REGEX = /^(0[1-9]|1[0-2])$/;
export const EXPIRATION_YEAR_VALIDATOR_REGEX = /^([0-9]{4}|[0-9]{2})$/;
export const EMAIL_VALIDATOR_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
export const STACKLINE_SUPER_USER_VALIDATOR_REGEX = /-superuser@stackline.com$|-superuser@piwheel.com$/;
export const STACKLINE_DEPLOYMENT_USER_VALIDATOR_REGEX =
  /^mkeidan-superuser@stackline.com$|^mkeidan@stackline.com|^raj@stackline.com$|^raj-superuser@stackline.com$|^rvalenza-superuser@stackline.com$|^rvalenza@stackline.com$|^yjiang-superuser@stackline.com$|^yjiang@stackline.com$|^aberry-superuser@stackline.com$|^jreiss-superuser@stackline.com$|^jreiss@stackline.com$|^aberry@stackline.com$|^kmacdonald@stackline.com$|^kmacdonald-superuser@stackline.com$|^jkrishnamoorthy@stackline.com$|^jkrishnamoorthy-superuser@stackline.com$|^ksagar@stackline.com$|^ksagar-superuser@stackline.com$|^nwadhwa@stackline.com$|^nwadhwa-superuser@stackline.com$|^czhou@stackline.com$|^czhou-superuser@stackline.com$|^pjakhotia@stackline.com$|^pjakhotia-superuser@stackline.com$/;
export const STACKLINE_USER_VALIDATOR_REGEX = /@stackline.com$/;
export const DOMAIN_VALIDATOR_REGEX = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
export const URL_VALIDATOR_REGEX = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
);

/**
 * Creates a validator function given a regular expression and an error message to display if the input is invalid
 *
 * @param {RegExp} rgx A regular expression that will be used to test the input
 * @param {string} errMsg The error message to display if the input is invalid
 */
export const mkRegexValidator = (rgx: RegExp, errMsg: string) => (value: string) =>
  rgx.test(value) ? undefined : errMsg;
