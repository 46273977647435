/* eslint-disable react/prop-types */

import React from 'react';

const Checkmark: React.FC<{ selected: boolean }> = ({ selected }) => (
  <div style={selected ? { left: '4px', top: '4px', position: 'absolute', zIndex: 1 } : { display: 'none' }}>
    <svg style={{ fill: 'white', position: 'absolute' }} width="24px" height="24px">
      <circle cx="12.5" cy="12.2" r="8.292" />
    </svg>
    <svg style={{ fill: '#06befa', position: 'absolute' }} width="24px" height="24px">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  </div>
);

const styles: { [key: string]: React.CSSProperties } = {
  image: {
    width: 'auto',
    height: 'auto',
    background: 'none',
    maxWidth: '100%',
    maxHeight: '100%',
    transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s'
  },
  imageSelected: {
    transform: 'translateZ(0px) scale3d(0.9, 0.9, 1)',
    transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s'
  },
  cont: {
    backgroundColor: 'none',
    padding: 5,
    margin: 10,
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

const ImageBox: React.FC<{ photo: { [key: string]: any }; handleOnClick: () => void; isSelected: boolean }> = ({
  photo,
  handleOnClick,
  isSelected
}) => (
  <div style={{ height: 150, width: 150, ...styles.cont }} className={!isSelected ? 'not-selected' : ''}>
    <Checkmark selected={isSelected} />
    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
    <img
      alt={photo.id}
      style={isSelected ? { ...styles.image, ...styles.imageSelected } : { ...styles.image }}
      src={photo.url}
      onClick={handleOnClick}
    />
    <style>{`.not-selected:hover{outline:2px solid #06befa}`}</style>
  </div>
);

export default ImageBox;
