import { COMMON_FIELDS } from 'src/utils/entityDefinitions/fields/commonFieldDefinitions';
import { OMNI_PRICE_FIELDS } from 'src/utils/entityDefinitions/fields/omniPriceFieldDefinitions';
import { OMNI_INVENTORY_FIELDS } from 'src/utils/entityDefinitions/fields/omniInventoryFieldDefinitions';
import { OMNI_SHIPPING_FIELDS } from 'src/utils/entityDefinitions/fields/omniShippingTimeFieldDefinitions';
import { OMNI_SHARE_OF_SHELF_FIELDS } from 'src/utils/entityDefinitions/fields/omniShareOfShelfFieldDefinitions';
import { OMNI_REVIEW_FIELDS } from 'src/utils/entityDefinitions/fields/omniReviewDefinitions';
import { OMNI_CONTENT_FIELDS } from 'src/utils/entityDefinitions/fields/omniContentFieldDefinition';

export const OMNI_INDEX_FIELDS = {
  price: {
    ...COMMON_FIELDS,
    ...OMNI_PRICE_FIELDS
  },
  inventory: {
    ...COMMON_FIELDS,
    ...OMNI_INVENTORY_FIELDS
  },
  shipping: {
    ...COMMON_FIELDS,
    ...OMNI_SHIPPING_FIELDS
  },
  shareOfShelf: {
    ...COMMON_FIELDS,
    ...OMNI_SHARE_OF_SHELF_FIELDS
  },
  reviews: {
    ...COMMON_FIELDS,
    ...OMNI_REVIEW_FIELDS
  },
  content: {
    ...COMMON_FIELDS,
    ...OMNI_CONTENT_FIELDS
  }
};
