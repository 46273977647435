// The `scratch` reducer is an arbitrary Key:Value store that allows for arbitrary state to be stored and retrieved by
// different components without the need to create an entirely new sub-store each time.

import * as types from './types';

interface State {
  [key: string]: any;
}

interface Action {
  type: string;
  [key: string]: any;
}

const initialState: State = {};

const subReducers: { [actionType: string]: ((state: State, action: Action) => State) | undefined } = {
  [types.SET_KEY]: (state, action) => ({ ...state, [action.key]: action.val }),
  [types.DELETE_KEY]: (state, action) => {
    const newState = { ...state };
    delete newState[action.key];
    return newState;
  }
};

const scratchReducer = (state: State = initialState, action: Action) => {
  const subReducer = subReducers[action.type];

  return subReducer ? subReducer(state, action) : state;
};

export default scratchReducer;
