import View from 'src/components/AdManager/ScheduledAction/View';
import { buildWidgetGroup } from 'src/components/Layout/LayoutUtil';
import colors from 'src/utils/colors';
import AdManagerEntityHeader from 'src/components/AdManager/AdManagerEntityHeader';

const nameMapping = {
  adCampaign: 'campaign',
  adPortfolio: 'portfolio',
  adEntity: 'entity'
};

export const getLayout = ({ entity }) => {
  const customSubHeader = `The scheduled actions listed below are actively making changes to this ${
    nameMapping[entity.type]
  }.`;
  const HeaderWidget = {
    CustomComponent: AdManagerEntityHeader,
    name: 'adManagerHeader',
    view: {
      name: 'adManagerHeader',
      title: 'Ad Score',
      container: {
        style: { borderBottom: `1px solid ${colors.lightestGrey}`, marginBottom: 50 }
      }
    },
    data: {
      adScoreName: 'adScore'
    }
  };
  const widgets = [
    {
      CustomComponent: View,
      name: 'viewScheduledActions',
      view: {
        name: 'campaignAlign',
        className: 'entity_scheduled_actions_container',
        gridOptions: {
          pageSize: 1000
        }
      },
      data: {
        customSubHeader,
        hideAddButton: true
      }
    }
  ];

  const contentWidgetsGroup = buildWidgetGroup(
    [buildWidgetGroup(widgets, { data: {}, view: { container: { style: { minWidth: 1370, maxWidth: 1370 } } } })],
    {
      view: {
        container: {
          className: 'ad_builder-container',
          style: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }
        }
      }
    }
  );

  return {
    widgets: [HeaderWidget, contentWidgetsGroup]
  };
};
