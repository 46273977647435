import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Text, useStacklineTheme } from '@stackline/ui';
import { GenericNavigationTabs } from 'src/components/BeaconRedesignComponents/SubtabNav/SubtabNavigation';
import BusinessUnitsTab from './BusinessUnitsTab';
import SegmentsViewTab from './SegmentsViewTab';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/utils/Hooks';
import { BusinessUnitsCreationModal } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Segments/BusinessUnitsCreation/BusinessUnitsCreationModal';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

enum SegmentsTab {
  mySegments = 'mySegments',
  teamSegments = 'teamSegments',
  businessUnits = 'businessUnits'
}

interface SegmentsNavigationTabsProps {
  tab: SegmentsTab;
  setTab: (tab: SegmentsTab) => void;
}

/**
 * List of tabs for the top of the segments page
 */
const SegmentsNavigationTabs = ({ tab, setTab }: SegmentsNavigationTabsProps) => {
  return (
    <GenericNavigationTabs
      tab={tab}
      tabs={[
        {
          label: 'My Segments',
          onClick: () => setTab(SegmentsTab.mySegments),
          value: SegmentsTab.mySegments
        },
        {
          label: 'Team Segments',
          onClick: () => setTab(SegmentsTab.teamSegments),
          value: SegmentsTab.teamSegments
        },
        {
          label: 'Business Units',
          onClick: () => setTab(SegmentsTab.businessUnits),
          value: SegmentsTab.businessUnits
        }
      ]}
    />
  );
};

/**
 * Segments page container
 */
const Segments = () => {
  const theme = useStacklineTheme();
  const [tab, setTab] = useState(SegmentsTab.mySegments);
  const { searchParams } = useAppSelector((state) => state.app.queryParams);
  const { mySegments, teamSegments } = useAppSelector((state) => state.segments);

  const [businessUnitCreationModalOpen, setBusinessUnitCreationModalOpen] = useState(false);

  const handleClose = () => {
    setBusinessUnitCreationModalOpen(false);
  };

  return (
    <BeaconPageContainer sx={{ width: '100%', marginTop: '0px' }}>
      <Box display="flex" gap="44px" width="100%">
        <Box width="100%">
          <Box display="flex" justifyContent="space-between" width="100%">
            <Text variant="h2">Segments</Text>
            {[SegmentsTab.mySegments, SegmentsTab.teamSegments].includes(tab) && (
              <Link to={`/search${searchParams}&entityType=segment`}>
                <SlButton variant="contained">+ Create Segment</SlButton>
              </Link>
            )}
            {tab === SegmentsTab.businessUnits && (
              <SlButton
                disabled={mySegments.length === 0 && teamSegments.length === 0} // Disable BU creation if no segments exist
                onClick={() => setBusinessUnitCreationModalOpen(true)}
                variant="contained"
              >
                + Create Business Unit
              </SlButton>
            )}
          </Box>
          <Box marginBottom={theme.spacing.lg}>
            <SegmentsNavigationTabs tab={tab} setTab={setTab} />
          </Box>
          <Box width="100%">
            {tab === SegmentsTab.mySegments && <SegmentsViewTab />}
            {tab === SegmentsTab.teamSegments && <SegmentsViewTab team />}
            {tab === SegmentsTab.businessUnits && <BusinessUnitsTab />}
            {businessUnitCreationModalOpen && (
              <BusinessUnitsCreationModal handleClose={handleClose} open={businessUnitCreationModalOpen} />
            )}
          </Box>
        </Box>
      </Box>
    </BeaconPageContainer>
  );
};

export default Segments;
