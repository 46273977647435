import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';

import colorVariables from '../styles/_colors.scss';
import { toSnakeCase } from './stringFormatting';
import { panic } from 'src/utils/mixpanel';

/**
 * Using the colors from the `src/styles/_colors.scss` file, creates a JavaScript object with their color values
 * after transforming color names containing dashes to snakeCase.  This allows the same color names as used in the
 * CSS to be used in JS.
 *
 * The `eslint` rule disable is due to custom behavior that only takes place when we're in dev mode.  See the
 * `installDevTypecheckProxy` for more info.
 */
// eslint-disable-next-line import/no-mutable-exports
let mappedColors = {
  // Placeholders to serve as documentation for available colors + facilitate editor autocomplete
  actualSpend: '',
  projectedSpend: '',
  surplusBudget: '',
  overpacing: '',
  titleScore: '',
  bulletsScore: '',
  imagesScore: '',
  videosScore: '',
  darkBlue: '',
  blue: '',
  stacklineBlue: '',
  lightBlue: '',
  greenButton: '',
  comparison: '',
  brightBlue: '',
  blueGreen: '',
  labelGrey: '',
  filterBlue: '',
  green: '',
  lightGreen: '',
  borderGrey: '',
  grey: '',
  lightGrey: '',
  lighterGrey: '',
  lightestGrey: '',
  darkerGrey: '',
  darkGrey: '',
  black: '',
  white: '',
  purple: '',
  lightPurple: '',
  lightestPurple: '',
  beaconPurple: '',
  pink: '',
  lightPink: '',
  orange: '',
  lightOrange: '',
  red: '',
  quartz: '',
  default: '',
  atlasGreen: '',
  atlasDarkGreen: '',
  atlasBlue: '',
  atlasDarkGrey: '',
  atlasGrey: '',
  atlasTeal: '',
  advertisingBlue: '',
  redBright: '',
  userManagementBulkErrorPink: ''
};

/**
 * Creates some manual type checking for the `colors` object if we're in dev mode.  The `mappedColors` object
 * is replaced with a wrapped version that uses a `Proxy` to intercept key accesses and checks to make sure the
 * requested color key actually exists.
 */
const installDevTypecheckProxy = () => {
  const handlers = {
    get: (obj, key) => {
      const color = obj[key];
      if (_isEmpty(color)) {
        return panic(`Attempted to get color with key "${key}" but no color exists with that name.`);
      }
      return color;
    },
    set: (obj, key, value) => {
      return panic(`Attempted to mutate the colors object; Tried to set key "${key}" to ${value}`);
    }
  };

  const proxy = new Proxy(_cloneDeep(mappedColors), handlers);
  // Replace `mappedColors` with the proxy, intercepting all accesses and attempted mutations.
  mappedColors = proxy;
};

// Update `mappedColors` with values from the `_colors.scss` file
Object.entries(colorVariables).forEach(([key, value]) => {
  mappedColors[toSnakeCase(key)] = value;
});

if (__DEV__) {
  installDevTypecheckProxy();
}

export default mappedColors;
