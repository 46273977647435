import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import colors from 'src/utils/colors';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import {
  Filters,
  StageStatusMap
} from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeManagementDashboard';

// create a new component that extends TextField
const CustomTextField = styled(TextField)({
  // override the styles of the MUI classes
  '& .MuiInputBase-root': {
    width: '100px',
    height: '30px',
    borderRadius: '4px',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    padding: '5px'
  },
  '& .MuiInputLabel-root': {
    display: 'none'
  },
  'input::-webkit-outer-spin-button,input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: '0'
  },

  'input[type=number]': {
    '-moz-appearance': 'textfield'
  },

  input: {
    height: '19px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#7e8fa8',
    padding: '0px'
  }
});

const styles = {
  root: {
    padding: '0 30px'
  },
  container: {
    padding: '0 28px'
  },
  headerText: {
    fontSize: '16px',
    fontWeight: 700
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '219px',
    borderBottom: '1px solid #dedede'
  },
  searchInput: {
    border: 'none',
    outline: 'none',
    fontWeight: 500
  },
  filterOption: {
    display: 'grid',
    marginTop: '10px',
    gridTemplateColumns: 'repeat(3, 1fr)'
  },
  filterDropDown: {
    marginTop: '10px',
    padding: 0,
    border: 'none',
    outline: 'none',
    background: 'none',
    color: colors.darkBlue,
    fontWeight: 550,
    display: 'flex',
    alignItems: 'center'
  },
  mainBorder: {
    width: '100%',
    height: '1px',
    backgroundColor: '#dedede',
    margin: '25px 0'
  },
  selectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '2em'
  },
  selectionGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
};

export const DisputeValueRangeFilter = ({
  updateFilterState,
  filters
}: {
  updateFilterState: (a: any) => void;
  filters: Filters;
}) => {
  const [minValue, setMinValue] = useState(filters.minValue || '');
  const [maxValue, setMaxValue] = useState(filters.maxValue || '');

  useEffect(() => {
    setMinValue(filters.minValue);
    setMaxValue(filters.maxValue);
  }, [filters]);

  useEffect(() => {
    updateFilterState({
      newFilters: {
        minValue,
        maxValue
      }
    });
  }, [minValue, maxValue]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
      <CustomTextField
        id="min-value"
        label="Min Value"
        type="number"
        onChange={(e) => setMinValue(e.target.value)}
        InputLabelProps={{
          shrink: false
        }}
        sx={{
          '.MuiFormControl-root': {
            display: 'none',
            width: '96px',
            height: '31px',
            borderRadius: '4px',
            border: 'solid 1px #dedede',
            backgroundColor: '#fff'
          },
          '. MuiFormControl-root label': {
            display: 'none'
          }
        }}
        placeholder="0"
        value={minValue}
      />
      to
      <CustomTextField
        id="max-value"
        label="Max Value"
        type="number"
        onChange={(e) => setMaxValue(e.target.value)}
        InputLabelProps={{
          shrink: false
        }}
        placeholder="1000"
        value={maxValue}
      />
    </div>
  );
};

const Stage = ({ stage, handleStageClick }: { stage: StageStatusMap; handleStageClick: (e) => any }) => {
  return (
    <div style={styles.selectionGroup}>
      <Checkbox
        checked={stage.isChecked}
        sx={{
          color: '#222155',
          '&.Mui-checked': {
            color: '#222155'
          }
        }}
        value={stage.name}
        onClick={(e) => handleStageClick(e.target.value)}
      />
      {stage.name}
    </div>
  );
};

const stageStatuses: StageStatusMap[] = [
  { name: 'New Shortage', statuses: ['Needs Documents', 'Ready to Submit'], isChecked: false },
  { name: 'Initial Dispute', statuses: ['Pending'], isChecked: false },
  { name: 'Contact Us Case', statuses: ['Ready to Submit', 'Pending Decision'], isChecked: false },
  { name: 'Resolved', statuses: ['Won', 'Lost', 'Partially Won'], isChecked: false }
];

const statusMap = [
  {
    name: 'New Shortage',
    statuses: [
      { name: 'Needs Supporting Documents', isChecked: false },
      { name: 'Ready to Submit', isChecked: false },
      { name: 'Queued for Submission', isChecked: false },
      { name: 'Submission in Progress', isChecked: false },
      { name: 'Failed to Submit', isChecked: false }
    ]
  },
  {
    name: 'Initial Dispute',
    statuses: [{ name: 'Pending', isChecked: false }]
  },
  {
    name: 'Contact Us Case',
    statuses: [
      { name: 'Ready to Submit', isChecked: false },
      { name: 'Needs Action', isChecked: false },
      { name: 'Queued for Submission', isChecked: false },
      { name: 'Submission in Progress', isChecked: false },
      { name: 'Failed to Submit', isChecked: false }
    ]
  },
  {
    name: 'Resolved',
    statuses: [
      { name: 'Won', isChecked: false },
      { name: 'Lost', isChecked: false },
      { name: 'Partially Won', isChecked: false }
    ]
  }
];

/**
 * Allows for filtering by dispute stage. Also determines which status filter selections are shown.
 * @param setStageSelected updates filter modal state for rendering status filters
 * @returns
 */
export const DisputeStageSelection = ({
  updateFilterState,
  setStageSelected,
  filters
}: {
  updateFilterState: (a: any) => void;
  setStageSelected: (s: string) => any;
  filters: Filters;
}) => {
  const [stages, setStages] = useState<StageStatusMap[]>(stageStatuses);

  /**
   * Takes a filter name and updates the selections to be active or not
   * @param selection The name of the filter clicked on.
   */
  const handleStageClick = (selection: string) => {
    const updatedStages = stages.map((stage) => ({
      ...stage,
      isChecked: stage.isChecked ? false : stage.name === selection
    }));
    const stageIsSelected = updatedStages.find((stage) => stage.isChecked);
    if (stageIsSelected) {
      updateFilterState({
        newFilters: {
          stage: selection,
          status: ''
        }
      });
      setStageSelected(selection);
      setStages(updatedStages);
    } else {
      updateFilterState({
        newFilters: {
          stage: '',
          status: ''
        }
      });
      // If no stages are selected, we disable the status filter here.
      setStageSelected(null);
      setStages(updatedStages);
    }
  };

  const prefillFilterSelection = (key, filterState) => {
    const value = filterState[key];
    const updatedStages = stages.map((stage) => ({
      ...stage,
      isChecked: stage.name === value
    }));
    setStageSelected(value);
    setStages(updatedStages);
  };

  useEffect(() => {
    prefillFilterSelection('stage', filters);
  }, [filters]);

  const renderStageSelection = useCallback(() => {
    return stages.map((stage, index) => <Stage key={index} stage={stage} handleStageClick={handleStageClick} />);
  }, [stages, handleStageClick]);

  return <div style={styles.selectionContainer}>{renderStageSelection()}</div>;
};

/**
 * Allows for filtering by dispute status depending on which stage is currently selected.
 * @param stageSelected the name of the selected stage as a string
 * @returns
 */
export const DisputeStatusSelection = ({
  updateFilterState,
  stageSelected,
  filters
}: {
  updateFilterState: (a: any) => void;
  stageSelected: string;
  filters: Filters;
}) => {
  const getAvailableStatuses = () => {
    const { statuses } = statusMap.find((stage) => stage.name === stageSelected);
    return statuses;
  };

  const [availableStatuses, setAvailableStatuses] = useState(getAvailableStatuses());

  const prefillFilterSelection = (key, filterState) => {
    const value = filterState[key];
    // We lowercase because our URL parser will not match "Ready to Submit" vs "Ready To Submit"
    // We should find a cleaner way to handle this in order to clean up all the modal filters
    const updatedStatuses = getAvailableStatuses().map((status) => ({
      ...status,
      isChecked: status.name.toLowerCase() === value.toLowerCase()
    }));
    setAvailableStatuses(updatedStatuses);
  };

  useEffect(() => {
    setAvailableStatuses(getAvailableStatuses());
    prefillFilterSelection('status', filters);
  }, [stageSelected, filters]);

  /**
   * Takes a filter name and updates the selections to be active or not
   * @param selection The name of the filter clicked on.
   */
  const handleStatusClick = (selection: string) => {
    const updatedStatuses = availableStatuses.map((status) => ({
      ...status,
      isChecked: status.isChecked ? false : status.name === selection
    }));
    const statusIsSelected = updatedStatuses.find((stage) => stage.isChecked);
    if (statusIsSelected) {
      updateFilterState({
        newFilters: {
          status: selection
        }
      });
    } else {
      updateFilterState({
        newFilters: {
          status: ''
        }
      });
    }
    setAvailableStatuses(updatedStatuses);
  };

  const renderStatusSelection = useCallback(() => {
    return availableStatuses.map((status, index) => (
      <Stage key={index} stage={status} handleStageClick={handleStatusClick} />
    ));
  }, [availableStatuses, handleStatusClick]);

  return <div style={styles.selectionContainer}>{renderStatusSelection()}</div>;
};
