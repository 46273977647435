/* eslint-disable react/prop-types */
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputBase from '@mui/material/InputBase';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { AxiosResponse } from 'axios';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import React, { useEffect, useState } from 'react';
import { withBus } from 'react-bus';
import { connect } from 'react-redux';
import {
  AD_TARGETING_CLASS,
  AD_CAMPAIGN_STATUS,
  AD_STATUS_DERIVED,
  IAdCampaign,
  IAdTarget
} from 'sl-ad-campaign-manager-data-model';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import i18n from 'src/i18n_en';
import { store } from 'src/main';
import { fetchEntity } from 'src/store/modules/entityService/operations';
import ReduxStore from 'src/types/store/reduxStore';
import { AdCampaignManagerServiceClient } from 'src/utils/adCampaignManagerServiceClient';
import EntityStatusColumn from 'src/components/AdManager/Search/CustomColumns/EntityStatusColumn';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import _merge from 'lodash/merge';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import { getCampaignDisplayStatus } from 'src/components/AdManager/AmsUtils';

const mapStateToProps = (state: ReduxStore) => {
  const props = {
    ..._pick(state, ['app', 'retailer', 'mainTimePeriod', 'comparisonTimePeriod']),
    mainEntity: state.entityService.mainEntity,
    campaignEntity: state.entityService.campaign
  };

  return props;
};

const NegativeTargets: React.FC<ReturnType<typeof mapStateToProps> & { adGroupId: any; eventBus: any }> = ({
  mainEntity,
  campaignEntity,
  adGroupId,
  mainTimePeriod,
  app,
  retailer
}) => {
  const [dataSet, setDataSet] = useState<IAdTarget[]>([]);
  const [matchType, setMatchType] = useState('negativeExact');
  const [keywordString, setKeywordString] = useState('');
  const [submitStatus, setSubmitStatus] = useState<{ status: string; result?: AxiosResponse | null }>({
    status: 'normal',
    result: null
  });

  const onGridReady = (params: any) => {
    params.api.sizeColumnsToFit();
    params.api.refreshHeader();
  };

  const PAGE_SIZE = 150;

  const id = `${mainEntity.id}_Documents`;
  const searchType = `${app.apiAppName}-adCampaignAdGroupTarget`;
  const builder = new AdvancedSearchRequestBuilder(id, searchType);
  builder.setRetailerId(retailer.id).setPageNumber(1).setPageSize(PAGE_SIZE).setPeriod('year').setSearchBy('parent');

  const termFilters = [
    {
      fieldName: 'campaignId',
      values: [mainEntity.campaignId]
    },
    {
      fieldName: 'adGroupId',
      values: [adGroupId]
    }
  ];

  const searchSideBarConditions = {
    termFilters: [...termFilters]
  };

  const docTermFilters = searchSideBarConditions.termFilters;

  const request = React.useMemo(() => {
    const timePeriodRangeFilter = {
      fieldName: 'dayId',
      minValue: mainTimePeriod.startDayId,
      maxValue: mainTimePeriod.endDayId // include current week
    };

    const queryConditions: {
      termFilters: { fieldName: string; condition: string; values: string[] }[];
      rangeFilters: { fieldName: string; minValue: number; maxValue: number }[];
    } = {
      termFilters: [],
      rangeFilters: []
    };

    const requestBody = {
      conditions: {
        termFilters: [...docTermFilters, ...queryConditions.termFilters],
        rangeFilters: [...queryConditions.rangeFilters]
      },
      pageSize: PAGE_SIZE,
      returnDocuments: true,
      doAggregation: false,
      aggregations: [
        {
          conditions: {
            termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
            rangeFilters: [timePeriodRangeFilter]
          }
        }
      ]
    };

    builder.setRetailerId(retailer.id).setPageNumber(1).setPageSize(1200).setPeriod('year').setSearchBy('parent');

    return [_merge(builder.build(), requestBody)];
  }, [mainTimePeriod.startDayId, mainTimePeriod.endDayId, docTermFilters, retailer.id, builder]);

  const { data: negativeKeywordsData } = useGenericAdvancedSearch({
    requestId: 'negative_keywords_document',
    requestBody: request,
    staleTime: 0,
    // shouldPerformFetch: false,
    queryKeys: [id, request]
  });

  const parsedNegativeKeywordsDocuments = React.useMemo(() => {
    if (negativeKeywordsData) {
      const keywords = _get(negativeKeywordsData, ['data', 0, 'documents'], []);
      return keywords.filter((item) => item.targetingClass === 'negativeKeyword');
    }

    return [];
  }, [negativeKeywordsData]);

  useEffect(() => {
    if (mainEntity) {
      store.dispatch(
        fetchEntity('campaign', 'adCampaign', mainEntity.campaignId, mainEntity.extendedAttributes.retailerId)
      );
    }
  }, [mainEntity]);

  useEffect(() => {
    if (submitStatus.status === 'submitted' && mainEntity) {
      store.dispatch(
        fetchEntity('campaign', 'adCampaign', mainEntity.campaignId, mainEntity.extendedAttributes.retailerId)
      );
    }
  }, [submitStatus, mainEntity]);

  useEffect(() => {
    if (campaignEntity) {
      const campaign = campaignEntity as IAdCampaign;
      const adTargetDocumentsByTargetingText: any = {};

      /**
       * START
       */
      // Function to construct targetIdToUse
      const constructTargetId = (adTargetDocument, retailerId) => {
        const baseId = adTargetDocument.targetingText + adTargetDocument.targetingClass;
        return retailerId === '63' ? baseId + (adTargetDocument.isAutoAddedBoolean ? '1' : '0') : baseId;
      };

      // Function to update extended attributes
      const updateExtendedAttributes = (doc, targetIdToUse, _adTargetDocumentsByTargetingText) => {
        const extAttr = _adTargetDocumentsByTargetingText[targetIdToUse].extendedAttributes;
        if (doc.status === AD_CAMPAIGN_STATUS.ENABLED) {
          extAttr.status = AD_CAMPAIGN_STATUS.ENABLED;
        }
        if (doc.statusDerived === AD_STATUS_DERIVED.DELIVERING) {
          extAttr.statusDerived = AD_STATUS_DERIVED.DELIVERING;
        }
        extAttr.childDocuments.push(doc);
        extAttr.campaignIds = _uniq(extAttr.childDocuments.map((d) => d.campaignId));
        extAttr.campaignIdsDelivering = _uniq(
          extAttr.childDocuments
            .filter((x) => x.statusDerived === AD_STATUS_DERIVED.DELIVERING)
            .map((d) => d.campaignId)
        );
        extAttr.statusDisplay = getCampaignDisplayStatus(extAttr);
      };

      parsedNegativeKeywordsDocuments.forEach((adTargetDocument) => {
        const targetIdToUse = constructTargetId(adTargetDocument, retailer.id);

        if (!adTargetDocumentsByTargetingText[targetIdToUse]) {
          adTargetDocumentsByTargetingText[targetIdToUse] = {
            beaconClientId: adTargetDocument.beaconClientId,
            platformType: adTargetDocument.platformType,
            targetingText: adTargetDocument.targetingText,
            targetingType: adTargetDocument.targetingType,
            isAutoAdded: adTargetDocument.isAutoAddedBoolean ? '1' : '0',
            extendedAttributes: {
              statusReason: '',
              status: AD_CAMPAIGN_STATUS.PAUSED,
              statusDerived: AD_STATUS_DERIVED.PAUSED,
              childDocuments: []
            }
          };
        }

        updateExtendedAttributes(adTargetDocument, targetIdToUse, adTargetDocumentsByTargetingText);
      });

      /**
       * END
       */

      const negativeTargets = campaign
        .adTargets!.filter((target: IAdTarget) => target.targetingClass === 'negativeKeyword')
        .map((item) => {
          const targetIdToUse = constructTargetId(item, retailer.id);
          const docEntity = _get(
            adTargetDocumentsByTargetingText[targetIdToUse],
            ['extendedAttributes', 'childDocuments'],
            []
          );

          return {
            ...item,
            entity: {
              nameFieldName: 'targetingText',
              keyFieldName: 'targetingText',
              name: item.targetingText,
              id: item.targetingText,
              type: 'adTarget',
              platformType: retailer.platformType,
              extendedAttributes: {
                ...item.extendedAttributes,
                childDocuments: docEntity
              }
            }
          };
        });
      setDataSet(negativeTargets);
    }
  }, [campaignEntity, parsedNegativeKeywordsDocuments, retailer.id, retailer.platformType]);

  const columnDef = [
    {
      headerName: 'Keyword',
      field: 'targetingText',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 300,
      maxWidth: 800,
      valueFormatter: ({ value }) => value,
      headerClass: 'align-left',
      editable: true
    },
    {
      headerName: 'Match Type',
      field: 'extendedAttributes.matchType',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 150,
      maxWidth: 150,
      valueFormatter: ({ value }) => {
        if (value === 'negativeExact') {
          return 'Negative Exact';
        } else if (value === 'negativePhrase') {
          return 'Negative Phrase';
        }
        return value;
      },
      headerClass: 'align-left'
    },
    {
      headerName: 'Status',
      field: 'targetingText',
      width: undefined,
      enableRtl: true,
      minWidth: 100,
      maxWidth: 100,
      cellRendererFramework: EntityStatusColumn,
      headerClass: 'status',
      pinned: 'right'
    }
    // add the delete button as another column? or as a part of the column above
  ];

  const renderGrid = () => {
    if (dataSet.length === 0) {
      return <p>{i18n.adNegativeKeywords_noneFound}</p>;
    }

    return (
      <div className="recommendations_grid" style={{ position: 'relative' }}>
        <CustomAgMaterial
          onGridReady={onGridReady}
          onCellValueChanged={onGridReady}
          onModelUpdated={onGridReady}
          onRowValueChanged={onGridReady}
          onRowDataChanged={onGridReady}
          buildRows={() => dataSet}
          suppressNoRowsOverlay
          columnDefs={columnDef}
          getRowNodeId={(datum: any) => escape(datum.id)}
          domLayout="autoHeight"
          rowHeight={120}
          containerStyle={{ width: '100%' }}
        />
      </div>
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setKeywordString(event.target.value);
  };

  const buildTargetPayload = (keyword: string) => {
    const {
      beaconClientId,
      campaignId,
      platformType,
      extendedAttributes: { beaconClientLoginId, retailerId, entityId, entityIdApi }
    } = campaignEntity as IAdCampaign;
    return {
      beaconClientId,
      platformType,
      campaignId,
      adGroupId,
      targetingClass: AD_TARGETING_CLASS.NEGATIVE_KEYWORD,
      targetingText: keyword,
      extendedAttributes: {
        beaconClientLoginId,
        retailerId,
        entityId,
        entityIdApi,
        matchType,
        cpcMinBidAmount: 0, // yes, use cpcMaxBidAmount for cpcMinBidAmount for now.
        cpcMaxBidAmount: 0,
        keywordMetaData: {
          matchType
        }
      }
    };
  };

  const submitNegativeTargets = async () => {
    // setSubmitStatus({ status: 'submitted' });

    const keywords = keywordString.split('\n').filter((k) => k !== ''); // filter out empty lines/keywords
    if (keywords.length > 0) {
      const campaignEntityModified: IAdCampaign = _omit(campaignEntity, [
        'type',
        'displayName',
        'id',
        'entity'
      ]) as IAdCampaign;
      const newNegativeTargets = keywords.map(buildTargetPayload);
      campaignEntityModified.adTargets = newNegativeTargets;

      setSubmitStatus({ status: 'submitting' });
      const submitResult = await AdCampaignManagerServiceClient.createCampaignTargets({
        adCampaign: campaignEntityModified
      });
      if (submitResult && submitResult.status === 200) {
        setKeywordString(''); // clear the form
        setSubmitStatus({ status: 'submitted', result: submitResult });
      } else {
        setSubmitStatus({ status: 'failure', result: submitResult });
      }
    }
  };

  const hasKeywordInput = keywordString.split('\n').filter((k) => k !== '').length > 0;
  const disableSubmitButton = submitStatus.status === 'submitting' || !hasKeywordInput;
  const inNotAllowedListForPhrase = ![25].includes(mainEntity.extendedAttributes.retailerId);

  return (
    <div className="targeting_container">
      <div className="negative_body">
        <div className="input_container">
          <div className="inner">
            <h2>Add Negative Keywords</h2>
            <FormControl variant="standard" component="fieldset">
              <RadioGroup
                row
                aria-label="keyword type"
                name="keywordType"
                value={matchType}
                onChange={(event) => setMatchType(event.target.value)}
              >
                {inNotAllowedListForPhrase && (
                  <FormControlLabel
                    labelPlacement="start"
                    value="negativePhrase"
                    control={<Radio style={{ color: '#2E3746' }} />}
                    label="Phrase"
                  />
                )}
                <FormControlLabel
                  labelPlacement="start"
                  value="negativeExact"
                  control={<Radio style={{ color: '#2E3746' }} />}
                  label="Exact"
                />
              </RadioGroup>
            </FormControl>
            <InputBase
              placeholder={i18n.adNegativeKeywords_placeholder}
              multiline
              rows={12}
              onChange={handleChange}
              className="keyword_input"
              disabled={submitStatus.status === 'submitting'}
            />
            <button disabled={disableSubmitButton} className="submit" onClick={submitNegativeTargets}>
              {submitStatus.status === 'submitting' ? 'SUBMITTING' : 'SUBMIT'}
            </button>
          </div>
        </div>
        <div className="table">
          <h2>Existing Negative Keywords</h2>
          {renderGrid()}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(withBus('eventBus')(NegativeTargets));
