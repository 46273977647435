import { TermFilter, RangeFilter, AppName } from 'sl-api-connector';

export enum DATATYPE {
  INTEGER = 'INTEGER',
  DECIMAL = 'DECIMAL',
  DATETIME = 'DATETIME',
  TEXT = 'TEXT',
  MULTIPLE = 'MULTIPLE'
}

export enum METRICTYPE {
  INTEGER = 'INTEGER',
  DATETIME = 'DATETIME',
  MONEY = 'MONEY',
  PERCENT = 'PERCENT',
  VOLUME = 'VOLUME',
  DECIMAL = 'DECIMAL',
  KEYFIELD = 'KEYFIELD',
  TEXT = 'TEXT',
  MULTIPLE = 'MULTIPLE'
}

export interface DependantFieldDefinitionOverrides {
  primaryDependantFieldName: string;
  overridesByFieldName?: {
    [key: string]: {
      dataFetchStrategy: string;
      groupByFieldName?: string;
      groupByResultType: string;
    };
  };
}

export interface FieldDefinition {
  name: string;
  displayName: string;
  displayNamePlural?: string;
  groupBy?: string;
  fulfillmentType?: string;
  shareOfShelfType?: string;
  dataType: DATATYPE;
  metricType: METRICTYPE;
  entity?: {
    type: string;
  };
  canBeExported?: boolean;
  supportedAppNames?: AppName[];
  aggregationFunctionExpressionString?: string;
  aggregationFunctionType?: string;
  aggregationFunction?: any;
  timePeriodAggregationFunctionType?: string;
  timePeriodAggregationFunction?: any;
  isNegativeMetric?: boolean;
  fillWithLastKnownValue?: boolean;
  maxValue?: any;
  processAdditionalMetaData?: any;
  conditions?: {
    termFilters?: TermFilter[];
    rangeFilters?: RangeFilter[];
  };
  dependantFieldOverrides?: DependantFieldDefinitionOverrides;
  overrides?: {
    [key: string]: {
      aggregationFunction?: any;
      aggregationFunctionType?: string;
      aggregationFunctionExpressionString?: string;
      timePeriodAggregationFunctionType?: string;
      timePeriodAggregationFunction?: any;
      metricType?: METRICTYPE;
      dependantFieldOverrides?: DependantFieldDefinitionOverrides;
    };
  };
  useAbsoluteValue?: boolean;
  topSearchResults?: string;
  excludeBrandAndProduct?: boolean;
  aggregationFunctionTimeRange?: string;
  }

// this is used to specify the field paths in the formula,
// if the fields don't belong to the same index or even same app
// e.g atlas__sales__retailSales vs beacon__sales__retailSales
// or sales__retailSales vs promotion__retailSales
export const FIELD_PATH_SEPARATOR = '__' as const;
