import {
  TimeToConversionAdvancedSearchResponse,
  TimeToConversionData
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/TimeToConversion/types';
import _get from 'lodash/get';

/**
 * Parses advanced search response from Time to Conversion api request
 */
export const adTimeToConversionAdvancedSearchParser = (response: TimeToConversionAdvancedSearchResponse) => {
  const timeToConversionData: TimeToConversionData[] = _get(
    response,
    ['aggregations', 'by_timeToConversion.keyword'],
    []
  );
  return timeToConversionData;
};

interface BuildRequestForTimeToConversionParameters {
  startDayId: string | number;
  endDayId: string | number;
  retailerId?: string | number;
}

export const buildRequestForTimeToConversion = ({
  startDayId,
  endDayId,
  retailerId = 1
}: BuildRequestForTimeToConversionParameters) => {
  return [
    {
      name: 'amc_time_to_conversion',
      id: 'amc_time_to_conversion',
      pageNumber: 1,
      pageSize: 100,
      doAggregation: true,
      returnDocuments: false,
      conditions: {
        condition: 'must',
        termFilters: [
          {
            fieldName: 'periodStartDate',
            condition: 'should',
            values: [startDayId]
          },
          {
            fieldName: 'periodEndDate',
            condition: 'should',
            values: [endDayId]
          },
          {
            fieldName: 'retailerId',
            condition: 'should',
            values: [retailerId]
          }
        ],
        computeFilters: []
      },
      searchBy: 'parent',
      aggregations: [
        {
          aggregationFields: [
            {
              aggregateByFieldName: 'beaconClientId',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'adSales',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'adUnitsSold',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'orders',
              function: 'sum',
              canBeExported: true
            }
          ],
          groupByFieldName: 'timeToConversion.keyword,adProductType.keyword'
        }
      ],
      searchType: 'advertising-amc-time-to-conversion',
      processDocuments: false
    }
  ];
};

export const convertTimeToConversionDataToCsv = ({ data, dateRange, metric }): string => {
  const keys = ['< 10 MIN', '10 - 60 MIN', '1 - 6 HRS', '6 - 24 HRS', '1 - 7 DAYS', '7+ DAYS'];

  const metricAndDate = [`${metric}: ${dateRange}`].map((item) => `"${item}"`).join(',');
  const headers = keys.map((header) => `"${header}"`).join(',');
  const values = data.map((dataPoint) => `"${dataPoint}"`);

  return `${metricAndDate}\n${headers}\n${values.join(',')}`;
};
