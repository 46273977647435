import { IconButton } from '@mui/material';
import React, { useState, useRef, CSSProperties } from 'react';
import { PlayVideo } from 'src/components/SvgIcons/SvgIcons';

interface VideoPreviewProps {
  videoUrl: string;
  poster?: string;
  hidePlay?: boolean;
  style?: CSSProperties;
}

const AdVideoPreview: React.FC<VideoPreviewProps> = ({ hidePlay = false, videoUrl, poster = null, style }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div style={{ position: 'relative', width: '560px', minHeight: '340px', ...style }}>
      <video style={{ borderRadius: 4 }} poster={poster} width="100%" height="100%" ref={videoRef}>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {!hidePlay && (
        <IconButton
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
            width: '100%',
            height: '100%'
          }}
          onClick={handlePlayPause}
          disableRipple
        >
          {!isPlaying ? <PlayVideo style={{ width: 40 }} /> : <div style={{ width: '100%', height: '60%' }}></div>}
        </IconButton>
      )}
    </div>
  );
};

export default AdVideoPreview;
