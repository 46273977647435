import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { AppName } from 'sl-api-connector';

import colors from 'src/utils/colors';
import { CrossIcon, OmniCloseIcon } from '../../SvgIcons';

const styles = {
  closeButton: {
    color: colors.red,
    position: 'absolute',
    alignSelf: 'flex-end',
    width: 40,
    margin: '10px',
    height: 40,
    border: 'none',
    outline: 'none',
    background: 'none',
    padding: 0
  },
  icon: {
    width: 40,
    height: 40
  },
  omniIcon: {
    width: 20,
    height: 20,
    marginTop: '10px'
  }
};

const CloseButton = ({ style, iconStyle, onClick, ...props }) => {
  const app = useSelector((state) => state.app);
  const isOmni = app.name === AppName.Omni;
  return (
    <IconButton
      aria-label="Close"
      style={app.name === AppName.Omni ? { ...style } : { ...styles.closeButton, ...style }}
      onClick={onClick}
      disableRipple
      {...props}
      size="large"
    >
      {isOmni ? (
        <OmniCloseIcon style={{ ...styles.omniIcon, ...iconStyle }} />
      ) : (
        <CrossIcon style={{ ...styles.icon, ...iconStyle }} />
      )}
    </IconButton>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  iconStyle: PropTypes.object
};

CloseButton.defaultProps = {
  style: {},
  iconStyle: {}
};

export default CloseButton;
