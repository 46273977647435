import React from 'react';
import { timePeriodOptions } from 'src/store/modules/main-time-period/reducers';
import CustomMainTimePeriodDropDown from './CustomMainTimePeriodDropDown';
import moment from 'moment';

const timePeriods = [
  timePeriodOptions.last2Weeks,
  timePeriodOptions.lastMonth,
  timePeriodOptions.last3Months,
  timePeriodOptions.select1Month,
  timePeriodOptions.select3Months
];

const isCustomDate = (periodId: string): boolean => ['select1Month', 'select3Months'].includes(periodId);

/**
 * AMC data is only populated back to Jan 1, 2022
 */
const AMC_MIN_DATE = moment('2022-01-01').toDate();

/**
 * Custom main time period dropdown for the AMC dashboard
 */
const AmcDashboardDropDown = () => {
  return (
    <CustomMainTimePeriodDropDown
      isCustomDate={isCustomDate}
      timePeriods={timePeriods}
      dividerIndex={3}
      minDate={AMC_MIN_DATE}
      maxDate={moment().subtract(1, 'month').endOf('month').toDate()} // AMC data is populated until the end of last month
    />
  );
};

export default AmcDashboardDropDown;
