import React from 'react';
import PropTypes from 'prop-types';
import EntityIconButtonGroup from 'src/components/common/Buttons/EntityIconButtonGroup';
import EntityMetricDropDown from 'src/components/common/DropDown/EntityMetricDropDown';
import Export from 'src/components/Export';
import './EntityGridHeader.scss';

const propTypes = {
  app: PropTypes.object.isRequired,
  className: PropTypes.string,
  exportRequest: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  aggregationFields: PropTypes.array,
  handleChangeLayout: PropTypes.func,
  title: PropTypes.string,
  showHeaderDivider: PropTypes.bool,
  defaultView: PropTypes.string,
  enableSwitchingLayouts: PropTypes.bool,
  enableGroupBy: PropTypes.bool,
  enableAggregateBy: PropTypes.bool,
  groupByFields: PropTypes.array,
  groupByField: PropTypes.any,
  enableExport: PropTypes.bool,
  exportFileName: PropTypes.string,
  handleAggregateByChange: PropTypes.func,
  handleGroupByChange: PropTypes.func,
  style: PropTypes.object,
  hideTitle: PropTypes.bool,
  children: PropTypes.any
};

const defaultProps = {
  className: '',
  title: 'No title found',
  exportFileName: 'stackline_data_export.csv',
  defaultView: 'tile',
  enableSwitchingLayouts: true,
  enableGroupBy: false,
  enableAggregateBy: false,
  showHeaderDivider: true,
  groupByFields: [],
  groupByField: undefined,
  enableExport: true,
  exportRequest: [],
  handleChangeLayout: () => {},
  handleAggregateByChange: () => {},
  handleGroupByChange: () => {},
  aggregationFields: [],
  style: undefined,
  hideTitle: false,
  children: null
};

const EntityGridHeader = ({
  app,
  className,
  handleChangeLayout,
  handleAggregateByChange,
  handleGroupByChange,
  defaultView,
  showHeaderDivider,
  enableSwitchingLayouts,
  enableGroupBy,
  enableAggregateBy,
  enableExport,
  groupByField,
  groupByFields,
  aggregationFields,
  exportFileName,
  title,
  exportRequest,
  style,
  hideTitle,
  children
}) => (
  <>
    <div className={`grid-header ${className}`} style={style}>
      {!hideTitle ? <div className="grid-header__title">{title}</div> : null}
      {children}
      <div className="grid-header__icon-menu" style={title === 'Reviews' ? { marginBottom: '1px' } : null}>
        {enableAggregateBy ? (
          <EntityMetricDropDown handleMetricsFieldChange={handleAggregateByChange} metricFields={aggregationFields} />
        ) : null}
        {enableGroupBy ? (
          <EntityMetricDropDown
            handleMetricsFieldChange={handleGroupByChange}
            metricFields={groupByFields}
            value={groupByField}
            style={{ marginTop: -13, paddingBottom: 4 }}
          />
        ) : null}
        {enableSwitchingLayouts ? (
          <EntityIconButtonGroup defaultView={defaultView} handleChangeLayout={handleChangeLayout} />
        ) : null}
        {enableExport ? (
          <Export
            exportRequest={exportRequest}
            apiUrl={`/api/${app.name}/AdvancedSearchExport`}
            className="grid-header__action-button"
            fileName={exportFileName}
          />
        ) : null}
      </div>
    </div>
    {showHeaderDivider ? (
      <hr className="sl-divider sl-divider--no-margin-top" style={title === 'Reviews' ? { marginTop: '-6px' } : null} />
    ) : null}
  </>
);

EntityGridHeader.propTypes = propTypes;
EntityGridHeader.defaultProps = defaultProps;

export default EntityGridHeader;
