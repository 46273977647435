import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { buildWidgetGroup } from 'src/components/Layout/LayoutUtil';
import {
  Platform,
  PortfolioAlign,
  CampaignAlign,
  BudgetPeriod,
  BudgetAmount,
  BudgetAllocation,
  BudgetConfirmation,
  BudgetSubmit
} from 'src/components/AdBudgetEditor/Steps';
import AdManagerTabs from 'src/components/AdBudgetEditor/Widgets/AdBudgetEditorTabs';
import ReduxStore from 'src/types/store/reduxStore';
import { Widget } from 'src/types/application/widgetTypes';

/**
 * Creates a widget group that renders an array of child widgets inside of an `AdManagerStep`.  It makes use of the
 * `WrapperComponent` API to specify a custom wrapper component for the widget.
 */

const getWidgetsForSubtab = ({ subtab }: { subtab: string }) => {
  const widgetsBySubtab: { [subtab: string]: Widget[] } = {
    platformSelect: [
      {
        CustomComponent: Platform,
        name: 'platformSelect',
        view: {
          name: 'platformSelect',
          gridOptions: {
            pageSize: 1000
          }
        },
        data: {}
      }
    ],
    portfolioAlign: [
      {
        CustomComponent: PortfolioAlign,
        name: 'portfolioAlign',
        view: {
          name: 'portfolioAlign',
          gridOptions: {
            pageSize: 1000
          }
        },
        data: {}
      }
    ],
    campaignAlign: [
      {
        CustomComponent: CampaignAlign,
        name: 'campaignAlign',
        view: {
          name: 'campaignAlign',
          gridOptions: {
            pageSize: 1000
          }
        },
        data: {}
      }
    ],
    budgetPeriod: [
      {
        CustomComponent: BudgetPeriod,
        name: 'budgetPeriod',
        view: { name: 'budgetPeriod' },
        data: {}
      }
    ],
    budgetAmount: [
      {
        CustomComponent: BudgetAmount,
        name: 'budgetAmount',
        view: {
          name: 'budgetAmount',
          gridOptions: {
            pageSize: 1000
          }
        },
        data: {}
      }
    ],
    budgetAllocation: [
      {
        CustomComponent: BudgetAllocation,
        name: 'budgetAllocation',
        view: { name: 'budgetAllocation' },
        data: {}
      }
    ],
    confirmation: [
      {
        CustomComponent: BudgetConfirmation,
        name: 'confirmation',
        view: { name: 'confirmation' },
        data: {}
      }
    ],
    submit: [
      {
        CustomComponent: BudgetSubmit,
        name: 'submit',
        view: { name: 'submit' },
        data: {}
      }
    ]
  };

  return widgetsBySubtab[subtab];
};

export const getLayoutForEntity = ({
  subtab = 'platformSelect',
  app
}: { subtab: string } & Pick<ReduxStore, 'app'>) => {
  let contentWidgets = getWidgetsForSubtab({ subtab, app });
  if (!contentWidgets) {
    contentWidgets = getWidgetsForSubtab({ subtab: 'platformSelect', app });
  }

  const adCampaignBuilderTabsWidget = {
    CustomComponent: AdManagerTabs,
    view: {
      container: {
        style: {
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }
      }
    }
  };

  const contentWidgetsGroup = buildWidgetGroup(
    [
      buildWidgetGroup(contentWidgets, { data: {}, view: { container: { style: { minWidth: 1310, maxWidth: 1310 } } } })
    ],
    {
      view: {
        container: {
          className: 'ad_builder-container',
          style: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }
        }
      }
    }
  );
  const widgets = [adCampaignBuilderTabsWidget, contentWidgetsGroup];

  return {
    containerStyle: { flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' },
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    dataConfig: {},
    widgets
  };
};
