import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import ProductListContainer from 'src/components/BeaconRedesignComponents/MetricList/ProductListContainer';
import { getWeekColumnDefinition } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import { removeComparisonFormatter } from 'src/components/BeaconRedesignComponents/MetricList/utils';
import { useProductEntity } from 'src/utils/Hooks/reduxSelectors';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';

const fields = [
  { name: 'invoiceAmount' },
  { name: 'updatedShortageAmountBeforeDispute' },
  { name: 'disputeSubmittedAmount' },
  { name: 'disputeWonAmount' },
  { name: 'shortageRatio' }
];

const metricOptions = [
  { name: 'invoiceAmount', displayName: 'Invoices', id: 'invoiceAmount', label: 'Invoices' },
  {
    name: 'updatedShortageAmountBeforeDispute',
    displayName: 'Shortages',
    id: 'updatedShortageAmountBeforeDispute',
    label: 'Shortages'
  },
  {
    name: 'disputeSubmittedAmount',
    displayName: 'Disputes Submitted',
    id: 'disputeSubmittedAmount',
    label: 'Disputes Submitted'
  },
  { name: 'disputeWonAmount', displayName: 'Disputes Won', id: 'disputeWonAmount', label: 'Disputes Won' },
  { name: 'shortageRatio', displayName: 'Shortage Ratio', id: 'shortageRatio', label: 'Shortage Ratio' }
];

export const ShortagesProductGrid = () => {
  const [selectedOption, setSelectedOption] = React.useState(metricOptions[0]);
  const { isProductEntity } = useProductEntity();

  const weekEndingFields = fields.map(removeComparisonFormatter);

  return isProductEntity ? (
    <MetricListContainer
      entityTableHeaderProps={{
        title: 'Invoices by Week'
      }}
      indexName={BEACON_INDEX_NAMES.SD_ASIN_DETAIL_METRICS}
      sortFieldName="invoiceAmount"
      fields={weekEndingFields}
      headColumnDefinition={getWeekColumnDefinition({
        getTableColumnProps(defaults) {
          return {
            ...defaults,
            style: {
              ...defaults.style,
              width: '220px'
            }
          };
        }
      })}
      postProcessData={(data) => {
        return data.sort((a, b) => b.weekId.localeCompare(a.weekId));
      }}
      useTableDataProps={{
        groupByFieldName: 'weekId',
        filterResponse: () => true,
        itemsPerPage: 100
      }}
      showGrid={false}
    />
  ) : (
    <ProductListContainer
      entityTableHeaderProps={{
        renderHeader() {
          return (
            <Flex
              gap={5}
              sx={{
                paddingBottom: `0px`
              }}
              alignItems="center"
            >
              <SlDropdownMenu
                onChange={(s) => setSelectedOption(s)}
                defaultLabel={selectedOption.label}
                selectedId={selectedOption.id}
                buttonVariant="title"
                options={metricOptions}
              />
              <Text variant="h4">by Product</Text>
            </Flex>
          );
        },
        title: 'Shortages by Product'
      }}
      indexName={BEACON_INDEX_NAMES.SD_ASIN_DETAIL_METRICS}
      sortFieldName={selectedOption.id}
      fields={fields}
    />
  );
};
