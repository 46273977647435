import _get from 'lodash/get';
import { AD_STATUS_DERIVED } from 'sl-ad-campaign-manager-data-model';
import { Dispatch } from 'react';
import { CancelTokenSource } from 'axios';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import { PortfoliosProjectedSpendFetcher } from 'src/components/AdManager/Search/GridDataFetchers/Portfolios/PortfoliosProjectedSpendFetcher';
import { PortfolioFilterApplicator } from 'src/components/AdManager/Search/GridDataFetchers/Portfolios/PortfolioFilterApplicator';
import _cloneDeep from 'lodash/cloneDeep';
import { GridIntermediateData } from 'src/components/AdManager/Search/Models/GridIntermediateData';
import { SearchGridDataMappers } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridDataMappers';
import { IGridResultData } from 'src/components/AdManager/Search/Models/IGridResultData';
import {
  fetchEntityMetricsAndParseResponse,
  getClonedResult
} from 'src/components/AdManager/Search/GridDataFetchers/FetchDataForGroupByField';
import { IFetchEntityMetricsAndParseResponseParams } from 'src/components/AdManager/Search/Models/IFetchEntityMetricsAndParseResponseParams';
import { Location } from 'history';
import { store } from 'src/main';

export class PortfoliosDataFetcher {
  private readonly _dispatch: Dispatch<any>;

  private readonly _cancelSource: CancelTokenSource;

  public constructor(dispatch: Dispatch<any>, cancelSource: CancelTokenSource) {
    this._dispatch = dispatch;
    this._cancelSource = cancelSource;
  }

  public async fetchPortfoliosData(
    props: { location: Location },
    searchSideBarConditions: ISearchFilterConditions,
    aggregationFilters: ISearchFilterConditions,
    mainEntity: any,
    app: any,
    indexName: any,
    retailer: any,
    groupByField: any,
    gridIntermediateData: GridIntermediateData,
    fetchMetricsParams: IFetchEntityMetricsAndParseResponseParams,
    dataSet: any,
    pageNumber: number
  ): Promise<IGridResultData> {
    const dispatch = this._dispatch;
    const isOnAdEntityPage: boolean = props.location.pathname.includes('adEntity');

    let metricResults = { data: [] };
    if (gridIntermediateData.data.length > 0) {
      metricResults = await fetchEntityMetricsAndParseResponse(fetchMetricsParams, dispatch, this._cancelSource);
    }
    const cloneResult = getClonedResult(metricResults, pageNumber, dataSet);
    const mapOfPortfolioDataWithMetrics = PortfoliosDataFetcher.getMapOfPortfolioDataWithMetrics(metricResults);
    const mapOfPortfolioMetadata = PortfoliosDataFetcher.getMapOfPortfolioMetadata(gridIntermediateData);
    const itemsToReturn = _cloneDeep(cloneResult);
    itemsToReturn.data = [];

    const hasSearchFilters = this.hasAnySearchFilters(aggregationFilters, searchSideBarConditions, isOnAdEntityPage);
    const filterApplicator = new PortfolioFilterApplicator(searchSideBarConditions, aggregationFilters);

    // First, filter and add Portfolios with metrics, to preserve the sort order
    metricResults.data.forEach((portfolioWithMetric: any) => {
      const portfolioIdLower = portfolioWithMetric.entity.portfolioId.toLowerCase();
      if (!portfolioIdLower) {
        console.warn(`PortfolioId is unknown for portfolio with metrics`);
      }
      const portfolioMetadata = mapOfPortfolioMetadata.get(portfolioIdLower);
      const transformedPortfolio = SearchGridDataMappers.getPortfolioGridDataMergedWithMetrics(
        portfolioMetadata,
        portfolioWithMetric
      );
      itemsToReturn.data.push(transformedPortfolio);
    });
    // Second, filter and add Portfolios without metrics
    gridIntermediateData.data.forEach((portfolioGridMetadata: any) => {
      const portfolioMetadata = portfolioGridMetadata.entity;
      const portfolioIdLower = portfolioMetadata.id.toLowerCase();
      if (!portfolioIdLower) {
        console.warn(`PortfolioId is unknown for portfolio without metrics`);
      }
      const isThisAPortfolioWithoutMetrics = !mapOfPortfolioDataWithMetrics.has(portfolioIdLower);
      if (isThisAPortfolioWithoutMetrics) {
        const shouldAdd = this.shouldAddPortfolioWithoutMetrics(
          portfolioMetadata,
          isOnAdEntityPage,
          mainEntity,
          hasSearchFilters,
          filterApplicator
        );
        if (shouldAdd) {
          itemsToReturn.data.push(portfolioGridMetadata);
        }
      }
    });

    itemsToReturn.totalResultCount = itemsToReturn.data.length;

    const projectedSpendCalculator = new PortfoliosProjectedSpendFetcher();
    await projectedSpendCalculator.addProjectedSpendDetails(
      mainEntity,
      itemsToReturn,
      app,
      indexName,
      dispatch,
      retailer,
      groupByField,
      this._cancelSource
    );

    return {
      gridResult: itemsToReturn
    };
  }

  private static getMapOfPortfolioDataWithMetrics(metricResults: { data: any[] }) {
    const mapOfPortfolioDataWithMetrics = new Map<string, any>();
    metricResults.data.forEach((portfolioDataWithMetrics) => {
      const portfolioIdLower = (portfolioDataWithMetrics.id || '').toLowerCase();
      if (!portfolioIdLower) {
        console.warn(`PortfolioId is unknown for portfolio with metrics`);
      }
      return mapOfPortfolioDataWithMetrics.set(portfolioIdLower, portfolioDataWithMetrics);
    });
    return mapOfPortfolioDataWithMetrics;
  }

  private static getMapOfPortfolioMetadata(gridIntermediateData: GridIntermediateData) {
    const mapOfPortfolioMetadata = new Map<string, any>();
    gridIntermediateData.data.forEach((portfolioGridMetadata) => {
      const portfolioMetadata = portfolioGridMetadata.entity;
      const portfolioId = portfolioMetadata.id || '';
      const portfolioIdLower = portfolioId.toLowerCase();
      if (!portfolioIdLower) {
        console.warn(`PortfolioId is unknown for portfolio without metrics`);
      }
      return mapOfPortfolioMetadata.set(portfolioIdLower, portfolioMetadata);
    });
    return mapOfPortfolioMetadata;
  }

  public fetchPortfolioDataThatMatchMetadataFilters(
    searchSideBarConditions: ISearchFilterConditions,
    props: { location: Location }
  ): GridIntermediateData {
    const isOnAdEntityPage: boolean = props.location.pathname.includes('adEntity');

    const { adPortfolios } = store.getState();

    const hasAnyMetadataFilters = this.hasAnySearchBarMetadataFilters(searchSideBarConditions, isOnAdEntityPage);
    const filterApplicator = new PortfolioFilterApplicator(searchSideBarConditions, null);
    const itemsToReturn: GridIntermediateData = {
      data: [],
      hasAnyMetadataFilters
    };
    adPortfolios.forEach((portfolioMetadata: any) => {
      const portfolioId = portfolioMetadata.portfolioId || portfolioMetadata.id;
      const portfolioIdLower = portfolioId.toLowerCase();
      const isMatch = !hasAnyMetadataFilters || filterApplicator.doesPortfolioMatchMetadataFilters(portfolioMetadata);
      if (isMatch) {
        const gridDataItem = SearchGridDataMappers.getPortfolioGridDataFromMetadata(
          portfolioMetadata,
          portfolioIdLower
        );
        itemsToReturn.data.push(gridDataItem);
      }
    });

    return itemsToReturn;
  }

  // eslint-disable-next-line class-methods-use-this
  private shouldAddPortfolioWithMetrics(
    adPortfolio: any,
    hasSearchFilters: boolean,
    filterApplicator: PortfolioFilterApplicator
  ) {
    if (!hasSearchFilters) {
      return true;
    }

    return filterApplicator.doesPortfolioMatchFilters(adPortfolio, true);
  }

  private hasAnySearchFilters(
    aggregationFilters: ISearchFilterConditions,
    searchSideBarConditions: ISearchFilterConditions,
    isOnAdEntityTab: boolean
  ) {
    let hasSearchFilters = aggregationFilters.computeFilters.length > 0;
    if (!hasSearchFilters) {
      hasSearchFilters = this.hasAnySearchBarMetadataFilters(searchSideBarConditions, isOnAdEntityTab);
    }
    return hasSearchFilters;
  }

  // eslint-disable-next-line class-methods-use-this
  private shouldAddPortfolioWithoutMetrics(
    adPortfolio: any,
    isOnAdEntityPage: boolean,
    mainEntity: any,
    hasSearchFilters: boolean,
    filterApplicator: PortfolioFilterApplicator
  ): boolean {
    const adPortfolioStatusDerived = _get(adPortfolio, ['extendedAttributes', 'statusDerived'], '');
    const adPortfolioStatus = _get(adPortfolio, ['extendedAttributes', 'status'], '');
    if (adPortfolioStatus.toLowerCase() === AD_STATUS_DERIVED.ARCHIVED) {
      return false;
    }
    if (adPortfolioStatusDerived.toLowerCase() === AD_STATUS_DERIVED.ARCHIVED) {
      return false;
    }
    const shouldAdd = PortfoliosDataFetcher.shouldAddPortfolioWithoutMetricsInternal(
      hasSearchFilters,
      isOnAdEntityPage,
      adPortfolio,
      mainEntity,
      filterApplicator
    );
    return shouldAdd;
  }

  // eslint-disable-next-line class-methods-use-this
  private hasAnySearchBarMetadataFilters(searchSideBarConditions: ISearchFilterConditions, isOnAdEntityPage: boolean) {
    const portfolioFilterFieldNames = [
      'portfolioNameFuzzy',
      'excludedPortfolioNameFuzzy',
      'statusDerived',
      'automationStrategyId'
    ];
    if (!isOnAdEntityPage) {
      portfolioFilterFieldNames.push('entityId');
    }
    return PortfoliosDataFetcher.hasFilters(searchSideBarConditions, portfolioFilterFieldNames);
  }

  private static hasFilters(searchSideBarConditions: ISearchFilterConditions, portfolioFilters: string[]) {
    return searchSideBarConditions.termFilters.some(
      (termFilter) => portfolioFilters.includes(termFilter.fieldName) && termFilter.values.length > 0
    );
  }

  private static shouldAddPortfolioWithoutMetricsInternal(
    hasSearchFilters: boolean,
    isOnAdEntityPage: boolean,
    adPortfolio: any,
    mainEntity: any,
    filterApplicator: PortfolioFilterApplicator
  ) {
    if (isOnAdEntityPage) {
      // check if portfolio without metric belongs to current adEntity
      const doesPortfolioBelongToCurrentAdEntity = PortfoliosDataFetcher.doesPortfolioBelongToCurrentAdEntity(
        adPortfolio,
        mainEntity
      );
      if (!hasSearchFilters) {
        return doesPortfolioBelongToCurrentAdEntity;
      } else if (!doesPortfolioBelongToCurrentAdEntity) {
        return false;
      }
    }

    return filterApplicator.doesPortfolioWithoutMetricsMatchFilters(adPortfolio);
  }

  private static doesPortfolioBelongToCurrentAdEntity(adPortfolio: any, mainEntity: any) {
    const doesPortfolioBelongToCurrentAdEntity =
      _get(adPortfolio, 'extendedAttributes.entityId') === _get(mainEntity, 'id', '');
    return doesPortfolioBelongToCurrentAdEntity;
  }
}
