import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import queryString from 'qs';
import { AppName } from 'sl-api-connector/types';

import colors from 'src/utils/colors';
import { getProductImageUrl } from 'src/utils/image';
import { BrandIcon } from 'src/components/SvgIcons';
import './Entity.scss';
import ReduxStore from 'src/types/store/reduxStore';
import { getEntityUrlInApp, isDrive } from 'src/utils/app';
import { generateProductLink } from 'src/utils/searchLinks';
import { destructurePathName } from 'src/utils/urlParsing';
import { withRouter } from 'react-router';

const styles: { [key: string]: React.CSSProperties } = {
  text: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center'
  },
  textLink: {
    fontSize: 14,
    whiteSpace: 'pre-wrap',
    lineHeight: '1.5em',
    maxHeight: '3em',
    overflow: 'hidden'
  },
  imageWrapper: {
    alignSelf: 'center',
    minWidth: 45,
    textAlign: 'center',
    marginRight: 10
  },
  brandIcon: {
    stroke: colors.lightGrey,
    strokeWidth: 13,
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  imageWrapperInner: {
    width: 45,
    display: 'table'
  },
  imageWrapperInnerInner: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  image: {
    maxWidth: 35,
    maxHeight: 35
  }
};

const mapStateToProps = ({ app, retailer }: ReduxStore) => ({ app, retailer });

type EntityColumnProps = {
  data?: { [key: string]: any };
  url?: string;
  style?: React.CSSProperties;
  location: PropTypes.object.isRequired;
  omniGroupByField?: {
    name: string;
    displayName: string;
    isSelected: boolean;
    groupByKey: string;
  };
  value?: { [key: string]: any };
  getLinkOverride?: (props: {
    type: string;
    id: string | number;
    searchParams: string;
    parsedAdditionalParameters: { [key: string]: any };
  }) => string;
} & ReturnType<typeof mapStateToProps>;

interface EntityColumnState {
  value?: EntityColumnProps['value'];
  imageUri?: string | null;
  didImageLoadError?: boolean;
}

class EntityColumn extends React.Component<EntityColumnProps, EntityColumnState> {
  public static defaultProps = {
    data: {},
    value: {},
    style: {},
    url: undefined,
    getLinkOverride: undefined,
    omniGroupByField: {
      name: 'brand',
      displayName: 'Brand',
      isSelected: false,
      groupByKey: 'brandId'
    }
  };

  public static propTypes = {
    data: (props: EntityColumnProps, _propName: string, componentName: string) => {
      if (!props.data && !props.value) {
        return new Error(`One of props 'data' or 'url' was not specified in '${componentName}'.`);
      }
      return null;
    },
    value: (props: EntityColumnProps, _propName: string, componentName: string) => {
      if (!props.data && !props.value) {
        return new Error(`One of props 'url' or 'data' was not specified in '${componentName}'.`);
      }
      return null;
    },
    style: PropTypes.object,
    app: PropTypes.object.isRequired,
    url: PropTypes.string,
    getLinkOverride: PropTypes.func,
    location: PropTypes.object.isRequired
  };

  public state: EntityColumnState = {};

  public componentWillMount() {
    const { value, data } = this.props;
    /**
     * In order to split the data we use to render 2 different Product Entities, we check against our useCustomData property in our column definition.
     * This property is set via cellRendererParams but the data itself is nested within the default data/row object under the customData key.
     */
    const useCustomData = _get(this.props, 'useCustomData', null);
    const customData = _get(this.props, 'data.customData', null);
    let val = value && value.name ? value : data;

    // Allows us to render 2 distinct columns of products
    if (useCustomData) {
      val = customData;
    }

    this.setState({
      value: val,
      imageUri: val && val.id ? getProductImageUrl(val.id.toString()) : null
    });
  }

  private handleImageLoadingError = () =>
    this.setState({
      didImageLoadError: true,
      imageUri: 'https://placehold.it/50x50'
    });

  private showImageElement() {
    const { imageUri, value, didImageLoadError } = this.state;

    if (!value || value.id === 'Other' || value.id === 'Total') {
      return null;
    }

    if (didImageLoadError && value.type === 'brand') {
      return <BrandIcon style={styles.brandIcon} />;
    }

    return (
      <img onError={this.handleImageLoadingError} alt={value.type} style={styles.image} src={imageUri || undefined} />
    );
  }

  private stopPropagation = (evt: { stopPropagation: Function }) => evt.stopPropagation();

  private showImage() {
    const { app, omniGroupByField } = this.props;
    const { searchParams, additionalParams, tab } = app.queryParams;
    const { value } = this.state;

    if (app.name === AppName.Omni) {
      const imgUrl = _get(value, ['entity', 'imageUrl']) || 'https://placehold.it/50x50';
      const OMNI_GROUP_BY_IMAGE_SUPPORT_LIST = ['product', 'brand', 'retailer'];

      if (OMNI_GROUP_BY_IMAGE_SUPPORT_LIST.includes(omniGroupByField.name)) {
        return (
          <div style={styles.imageWrapper}>
            <div style={styles.imageWrapperInner}>
              <div style={styles.imageWrapperInnerInner}>
                <img
                  onError={this.handleImageLoadingError}
                  alt={value.type}
                  style={styles.image}
                  src={imgUrl || undefined}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }
    // Basically we have no idea what could be in here at this point, so we check everything to make as sure as possible
    // that we actually have an image to render
    // no need to check the upc in omni app
    const isDriveCB = tab === 'adCampaignBuilder';
    if (value && value.entity && value.entity.upc && (!isDrive || isDriveCB)) {
      let imgUrl = _get(value, ['entity', 'imageUrl']) || 'https://placehold.it/50x50';
      if (typeof imgUrl === 'function' && value.id) {
        // for some reason the function does not executes on render
        imgUrl = imgUrl(value.id);
      }

      return (
        <div style={styles.imageWrapper}>
          <div style={styles.imageWrapperInner}>
            <div style={styles.imageWrapperInnerInner}>
              <img
                onError={this.handleImageLoadingError}
                alt={value.type}
                style={styles.image}
                src={imgUrl || undefined}
              />
            </div>
          </div>
        </div>
      );
    }
    if (
      !value ||
      !value.id ||
      value.type === 'category' ||
      value.type === 'subcategory' ||
      value.id === 'Other' ||
      value.id === 'Total'
    ) {
      return null;
    } else if (
      value.entity &&
      (!value.entity.imageUrl || ['category', 'categoryId', 'subcategory', 'subCategoryId'].includes(value.entity.type))
    ) {
      return null;
    }
    const image = (
      <div style={styles.imageWrapperInner}>
        <div style={styles.imageWrapperInnerInner}>{this.showImageElement()}</div>
      </div>
    );
    /**
     * The following logic fixes an issue in Drive where the Product Image is not a valid link to the product summary page.
     * Because this component was copied to other files under the same name, it is applied there as well. See: EntityColumn.tsx
     */
    let link: string;
    const entityType = value.type || _get(value, 'entity.type', '');
    let uniqueValueId = value.id;
    if (entityType === 'product') {
      uniqueValueId = uniqueValueId.toUpperCase();
    }

    if (isDrive && entityType === 'product') {
      link = `/${entityType}/${uniqueValueId}${searchParams}`;
    } else {
      link = `/${entityType}/${uniqueValueId}${searchParams}${additionalParams}`;
    }
    return (
      <div style={styles.imageWrapper}>
        {link ? (
          <Link onClick={this.stopPropagation} to={link}>
            {image}
          </Link>
        ) : (
          image
        )}
      </div>
    );
  }

  private getValue = () => {
    if (!this.state.value) {
      return null;
    } else if (this.state.value.type) {
      return this.state.value;
    } else if (this.state.value.entity) {
      return this.state.value.entity;
    } else {
      return this.state.value;
    }
  };

  private showTextLink(avoidLink = false) {
    const {
      defaultQueryParams,
      queryParams: { searchParams, additionalParams },
      name: appName,
      stage: appStage
    } = this.props.app;
    const parsedAdditionalParameters = queryString.parse(additionalParams);
    if (!parsedAdditionalParameters.tab) {
      parsedAdditionalParameters.tab = defaultQueryParams.tab;
    }
    if (!parsedAdditionalParameters.subtab) {
      parsedAdditionalParameters.subtab = defaultQueryParams.subtab;
    }
    const value = this.getValue();

    if (appName === AppName.Omni) {
      const { omniGroupByField } = this.props;

      let dataKeyNameMap;

      switch (omniGroupByField.name) {
        case 'product':
          dataKeyNameMap = { name: 'name', id: 'gtin', urlPrefix: 'product' };
          break;
        case 'retailer':
          dataKeyNameMap = { name: 'retailerName', id: 'retailerId', urlPrefix: 'retailer' };
          break;
        case 'brand':
          dataKeyNameMap = { name: 'brandName', id: 'brandId', urlPrefix: 'brand' };
          break;
        case 'category':
          dataKeyNameMap = { name: 'categoryName', id: 'categoryId', urlPrefix: 'category' };
          break;
        case 'subcategory':
          dataKeyNameMap = { name: 'subCategoryName', id: 'subCategoryId', urlPrefix: 'subcategory' };
          break;
        default:
          dataKeyNameMap = { name: 'name', id: 'gtin', urlPrefix: 'product' };
      }

      const valueId = _get(value, [dataKeyNameMap.id], '');

      let filterParams = '';

      const { pathname } = this.props.location;
      const [entityType, entityId] = destructurePathName(pathname);

      if (entityType === 'retailer') {
        filterParams = `&filter=${JSON.stringify({ r: [{ i: String(entityId) }] })}`;
      }
      const isInstacartRetailerPage = entityType === 'retailer' && entityId === '63';
      const disableLink = isInstacartRetailerPage && omniGroupByField.name === 'retailer';
      const rowURL = `/${dataKeyNameMap.urlPrefix}/${valueId}${searchParams}${additionalParams}${filterParams}`;
      return disableLink ? (
        <div style={styles.textLink} className={value.isBold ? 'bold' : undefined} title={value[dataKeyNameMap.name]}>
          {value[dataKeyNameMap.name]}
        </div>
      ) : (
        <Link style={{ display: 'block' }} to={rowURL} target="_self">
          <div style={styles.textLink} className={value.isBold ? 'bold' : undefined} title={value[dataKeyNameMap.name]}>
            {value[dataKeyNameMap.name]}
          </div>
        </Link>
      );
    }

    if (appName === AppName.Advertising) {
      parsedAdditionalParameters.tab = 'adManager';
      parsedAdditionalParameters.subtab = 'keyMetrics';
    }
    if (!value.type) {
      return (
        <div style={styles.textLink} className={value.isBold ? 'bold' : undefined}>
          {value.name}
        </div>
      );
    }

    // For entities that don't have pages on ad manager, we link them to beacon
    if (appName === AppName.Advertising && ['brand', 'category', 'subcategory'].includes(value.type)) {
      const linkToBeacon = getEntityUrlInApp(
        { name: AppName.Beacon, stage: appStage },
        value.type,
        value.id.toUpperCase()
      );
      return (
        <a href={linkToBeacon} target="_blank" rel="noopener noreferrer">
          <div style={styles.textLink} className={value.isBold ? 'bold' : undefined} title={value.name}>
            {value.name}
          </div>
        </a>
      );
    }
    const textLinkType = value.type === 'promotions' ? 'product' : value.type;
    let entityId = value.id || '';
    if (textLinkType === 'product') {
      entityId = entityId.toUpperCase();
    }
    const link = this.props.getLinkOverride
      ? this.props.getLinkOverride({
          type: value.type,
          id: entityId,
          searchParams: searchParams!,
          parsedAdditionalParameters
        })
      : `/${textLinkType}/${entityId}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`;

    // Access the entityColumn style overrides from the cellRendererParams
    const entityColumnTextStyles = _get(this.props, 'entityColumnTextStyles', {});

    const target = appName === AppName.Advertising ? '_blank' : undefined;
    return avoidLink ? (
      <div
        style={{ ...styles.textLink, ...entityColumnTextStyles }}
        className={value.isBold ? 'bold' : undefined}
        title={value.name}
      >
        {value.name}
      </div>
    ) : (
      <Link style={{ display: 'block' }} onClick={this.stopPropagation} to={link} target={target}>
        <div
          style={{ ...styles.textLink, ...entityColumnTextStyles }}
          className={value.isBold ? 'bold' : undefined}
          title={value.name}
        >
          {value.name}
        </div>
      </Link>
    );
  }

  private showBrandLink() {
    const { searchParams, additionalParams } = this.props.app.queryParams;
    const { value } = this.state;

    if (!value) {
      return null;
    }

    if (!value.type) {
      return (
        <Truncate style={{ fontSize: 14 }} lines={1} title={value.brandName}>
          {value.brandName}
        </Truncate>
      );
    }

    return (
      <Link
        style={{ display: 'block' }}
        onClick={this.stopPropagation}
        to={`/brand/${value.brandId}${searchParams}${additionalParams}`}
      >
        <div style={{ fontSize: 14 }} title={value.brandName}>
          <Truncate lines={1} title={value.brandName}>
            {value.brandName}
          </Truncate>
        </div>
      </Link>
    );
  }

  public render() {
    return (
      <div style={{ display: 'flex', ...this.props.style, textAlign: 'left' }}>
        {this.showImage()}
        <div style={styles.text}>
          {this.state.value && this.state.value.brandName ? this.showBrandLink() : null}

          {this.state.value && ['Other', 'Total'].includes(this.state.value.name)
            ? this.showTextLink(true)
            : this.showTextLink()}
          {this.props.app.name === 'advertising' && this.state.value && this.state.value.type === 'product' && (
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={generateProductLink(this.props.retailer.id, this.state.value.entity.retailerSku)}
              >
                {this.state.value.entity.retailerSku}
              </a>
            </div>
          )}
          {this.props.app.name === 'advertising' &&
            this.state.value &&
            this.state.value.entity &&
            this.state.value.entity.productUrl &&
            this.state.value.entity.upc && (
              <div>
                <a target="_blank" rel="noopener noreferrer" href={this.state.value.entity.productUrl}>
                  {this.state.value.entity.retailerSku}
                </a>
              </div>
            )}
        </div>
      </div>
    );
  }
}

// TODO: Fix this type error.  Idk why it doesn't like the `propTypes` type.
const EnhancedEntityColumn = withRouter(connect(mapStateToProps)(EntityColumn));

export default EnhancedEntityColumn;
