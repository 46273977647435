import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import StyledCheckbox from 'src/components/BeaconRedesignComponents/common/StyledCheckbox';
import Box from '@mui/material/Box';

export enum ProductGrowthQuadrant {
  Upgrade = 'Upgrade',
  Rationalize = 'Rationalize',
  Leverage = 'Leverage',
  Invest = 'Invest'
}

const QuadrantCheckbox = ({ label, ...rest }: { label: string } & React.ComponentProps<typeof StyledCheckbox>) => {
  return (
    <Box
      display="inline-flex"
      gap="8px"
      sx={{
        cursor: 'pointer'
      }}
    >
      <StyledCheckbox {...rest} />
      <Text variant="body2" onClick={() => rest.onChange && rest.onChange(null, !rest.checked)}>
        {label}
      </Text>
    </Box>
  );
};

/**
 * Displays the quadrant section on the product growth matrix filter modal
 */
export default function QuadrantFilterSection({
  quadrants,
  setQuadrants
}: {
  quadrants: ProductGrowthQuadrant[];
  setQuadrants: React.Dispatch<React.SetStateAction<ProductGrowthQuadrant[]>>;
}) {
  return (
    <Flex flexDirection="column" gap="md">
      <Text variant="subtitle1">Quadrants</Text>
      <Box display="grid" gridTemplateColumns="max-content max-content" rowGap="16px" columnGap="74px">
        {[
          ProductGrowthQuadrant.Upgrade,
          ProductGrowthQuadrant.Leverage,
          ProductGrowthQuadrant.Rationalize,
          ProductGrowthQuadrant.Invest
        ].map((quadrant) => (
          <QuadrantCheckbox
            key={quadrant}
            label={`${quadrant} Quadrant`}
            checked={quadrants.includes(quadrant)}
            onChange={(_, checked) => {
              if (checked) {
                setQuadrants([...quadrants, quadrant]);
              } else {
                setQuadrants(quadrants.filter((q) => q !== quadrant));
              }
            }}
          />
        ))}
      </Box>
    </Flex>
  );
}
