import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import ReduxStore from 'src/types/store/reduxStore';
import {
  receiveOmniRetailerContent,
  startFetchRetailerContent
} from 'src/store/modules/omni/omniRetailerContentScoreConfigure/actions';

export interface OmniRetailerContentScoreServiceBody {
  retailerIds: number[];
}

export const fetchOmniRetailerContentConfigureData =
  (apiBody: OmniRetailerContentScoreServiceBody) => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
    try {
      dispatch(startFetchRetailerContent({ data: [] }));
      const response = await axios.post('/omni/content/getContentScoreFields', apiBody);

      if (response && response.status === 200) {
        const { data } = response;
        dispatch(receiveOmniRetailerContent({ data }));
      }
    } catch (e) {
      console.warn(e);
    }
  };
