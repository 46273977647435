import React, { useMemo, useState } from 'react';
import { Text, useStacklineTheme } from '@stackline/ui';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { DialogProps } from '@mui/material/Dialog';
import { CloseIcon } from 'src/components/SvgIcons/SvgIcons';
import StyledInput from 'src/components/BeaconRedesignComponents/common/StyledInput';
import * as serverProxy from 'src/routes/UserAccount/UserTeamManagement/serverProxy';
import { useAppSelector, useSnackbar } from 'src/utils/Hooks';
import StyledDialog from 'src/components/BeaconRedesignComponents/common/StyledDialog/StyledDialog';
import StyledCheckbox from 'src/components/BeaconRedesignComponents/common/StyledCheckbox';
import { settingsErrorMessage } from '../utils';
import _get from 'lodash/get';
import { EMAIL_VALIDATOR_REGEX } from 'src/utils/validators';
import BulkUploadUserModal from './BulkUploadUserModal';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { PROCESSING_REQUEST_MESSAGE } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Team/constants';

type AddNewUserModalProps = Omit<DialogProps, 'children' | 'sx'> & {
  refetchData: () => Promise<void>;
};

/**
 * Popup for adding a new user to a team
 */
export default function AddNewUserModal({ open, refetchData, ...rest }: AddNewUserModalProps) {
  const theme = useStacklineTheme();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [baseEmail, setBaseEmail] = useState('');
  const currentUser = useAppSelector((state) => state.user.config.profile);
  const currentUserEmail = useAppSelector((state) => state.user.config.profile.email);
  const [multiAccount, setMultiAccount] = useState(false);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const closePopup = (event?: React.MouseEvent<unknown, MouseEvent>) => {
    if (rest.onClose) {
      setFirstName('');
      setLastName('');
      setEmail('');
      setBaseEmail('');

      setBulkUploadModalOpen(false);
      rest.onClose(event, 'backdropClick');
    }
  };

  const saveNewUser = async () => {
    try {
      closePopup();
      showSnackbar({
        type: 'info',
        message: PROCESSING_REQUEST_MESSAGE
      });
      await serverProxy.CreateUserInCurrentDivision({
        email,
        firstName,
        lastName,
        currentUserEmail,
        ...(multiAccount ? { emailAlias: baseEmail } : {})
      });
      await refetchData();
      showSnackbar({
        type: 'success',
        message: `You've successfully sent an invitation email to ${email}.`
      });
    } catch (error) {
      closePopup();
      showSnackbar({
        type: 'error',
        message: _get(error, ['response', 'data', 'error']) || settingsErrorMessage
      });
    }
  };

  const canSave = useMemo(() => {
    if (multiAccount && (!baseEmail.trim() || !EMAIL_VALIDATOR_REGEX.test(baseEmail))) {
      return false;
    }
    return firstName && lastName && email && EMAIL_VALIDATOR_REGEX.test(email);
  }, [multiAccount, baseEmail, firstName, lastName, email]);

  return (
    <>
      <StyledDialog padding="24px" width={450} open={open && !bulkUploadModalOpen} {...rest} onClose={closePopup}>
        <Flex flexDirection="column" justifyContent="space-between" height="100%">
          <Flex flexDirection="column" gap="mdl">
            <Flex justifyContent="space-between" alignItems="center">
              <Text variant="h4">Add New User</Text>
              <CloseIcon
                onClick={(event) => rest.onClose && rest.onClose(event, 'backdropClick')}
                style={{ cursor: 'pointer', width: theme.spacing.md, height: theme.spacing.md }}
              />
            </Flex>
            <Flex flexDirection="column" gap="sm">
              <Text variant="subtitle1">First Name</Text>
              <StyledInput
                placeholder="Enter first name"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                autoComplete="off"
              />
            </Flex>
            <Flex flexDirection="column" gap="sm">
              <Text variant="subtitle1">Last Name</Text>
              <StyledInput
                placeholder="Enter last name"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                autoComplete="off"
              />
            </Flex>
            <Flex flexDirection="column" gap="sm">
              <Text variant="subtitle1">Email</Text>
              <StyledInput
                placeholder="Enter an email address"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                autoComplete="off"
              />
            </Flex>
            {currentUser.role === 'support' && (
              <Flex gap="sm">
                <StyledCheckbox
                  checked={multiAccount}
                  onChange={(event) => {
                    const isChecked = event.target.checked;
                    if (!isChecked) {
                      setBaseEmail('');
                    }
                    return setMultiAccount(isChecked);
                  }}
                />
                <Text variant="body2">Multi-Account Email Setup</Text>
              </Flex>
            )}
            {multiAccount && (
              <Flex flexDirection="column" gap="sm">
                <Text variant="subtitle1">Base Email</Text>
                <StyledInput
                  placeholder="Enter a base email address"
                  value={baseEmail}
                  onChange={(event) => setBaseEmail(event.target.value)}
                />
              </Flex>
            )}
          </Flex>
          <Flex justifyContent="space-between" marginTop="44px">
            <SlButton
              onClick={closePopup}
              buttonSx={{
                paddingX: '20px'
              }}
            >
              Cancel
            </SlButton>
            <Flex gap="md">
              <SlButton
                onClick={() => setBulkUploadModalOpen(true)}
                buttonSx={{
                  paddingX: '20px'
                }}
              >
                Bulk Upload
              </SlButton>
              <SlButton disabled={!canSave} variant="contained" onClick={() => saveNewUser()}>
                Save
              </SlButton>
            </Flex>
          </Flex>
        </Flex>
      </StyledDialog>
      <BulkUploadUserModal
        onBack={() => setBulkUploadModalOpen(false)}
        refetchData={refetchData}
        open={open && bulkUploadModalOpen}
        onClose={closePopup}
      />
    </>
  );
}
