import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { PHONE_NUMBER_VALIDATOR_REGEX } from '../../../utils/validators';

class PhoneNumber extends React.Component {
  static defaultProps = {
    hintText: 'Phone Number',
    floatingLabelText: 'Phone Number',
    defaultValue: '',
    inputStyle: { marginTop: '8px' },
    style: { width: '100%', height: '58px' },
    className: ''
  };

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    isFormSubmitted: PropTypes.bool.isRequired,
    defaultValue: PropTypes.string,
    inputStyle: PropTypes.object,
    style: PropTypes.object,
    className: PropTypes.string,
    hintText: PropTypes.string,
    floatingLabelText: PropTypes.string
  };

  componentWillMount() {
    const { defaultValue } = this.props;
    const state = this.buildState(defaultValue);
    this.setState(state);
    this.triggerOnChange(state);
  }

  buildState = (value) => ({
    isPhoneNumberValid: PHONE_NUMBER_VALIDATOR_REGEX.test(value),
    phoneNumber: value || ''
  });

  triggerOnChange(state) {
    const { phoneNumber, isPhoneNumberValid } = state;
    const { onChange } = this.props;
    onChange({ phoneNumber, validator: { isPhoneNumberValid } });
  }

  handleInputChange = (event) => {
    const values = this.buildState(event.target.value);
    this.setState(values);
    this.triggerOnChange(values);
  };

  showErrorText = () => {
    const { isFormSubmitted } = this.props;
    const { isPhoneNumberValid, value } = this.state;

    if (isFormSubmitted) {
      if (value === '') {
        return 'This field is required';
      } else if (!isPhoneNumberValid) {
        return 'Please enter a valid phone number';
      }
    }
    return null;
  };

  render() {
    const { hintText, floatingLabelText, defaultValue, style, className, inputStyle } = this.props;

    return (
      <TextField
        variant="standard"
        className={className}
        type="text"
        placeholder={hintText}
        floatingLabelText={floatingLabelText}
        defaultValue={defaultValue}
        errorText={this.showErrorText()}
        floatingLabelStyle={{ top: '24px' }}
        inputStyle={inputStyle}
        errorStyle={{ bottom: '9px' }}
        style={style}
        onChange={this.handleInputChange}
      />
    );
  }
}

export default PhoneNumber;
