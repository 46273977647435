/* eslint-disable no-restricted-syntax */
import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import moment from 'moment-timezone';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import OverlayScrollbars from 'overlayscrollbars/js/OverlayScrollbars';
import PropTypes from 'prop-types';
import queryString from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppName } from 'sl-api-connector/types';
import { factory as splitFactory, store } from 'src/main';
import * as AdCampaignBuilderOperations from 'src/store/modules/adManager/adCampaignBuilder/operations';
import { adCampaignOperations } from 'src/store/modules/adManager/adCampaigns';
import { adGroupOperations } from 'src/store/modules/adManager/adGroups';
import { adEntityOperations } from 'src/store/modules/adManager/adEntities';
import { adPlatformOperations } from 'src/store/modules/adManager/adPlatforms';
import { adPlatformSettingsOperations } from 'src/store/modules/adManager/adPlatformSettings';
import { adPortfolioOperations } from 'src/store/modules/adManager/adPortfolios';
import updateAllWeekIdsByRetailerId from 'src/store/modules/allWeekIdsByRetailerId/operations';
import * as appOperations from 'src/store/modules/app/operations';
import { calculateNewQueryParams } from 'src/store/modules/app/selectors';
import { brandOperations } from 'src/store/modules/brands';
import { categoryOperations } from 'src/store/modules/categories';
import { chargeBackStatusesOperations } from 'src/store/modules/chargeBackStatus';
import { chargeBackIssueTypeOperations } from 'src/store/modules/chargeBackTypes';
import { filterOperations, filterSelectors } from 'src/store/modules/filters';
import { updateAllTimePeriods } from 'src/store/modules/main-time-period/operations';
import { promoTypeOperations } from 'src/store/modules/promoTypes';
import { retailerOperations } from 'src/store/modules/retailer';
import { parentPlatformOperations } from 'src/store/modules/parentPlatform';
import { segmentOperations } from 'src/store/modules/segments';
import { subCategoryOperations } from 'src/store/modules/subcategories';
import { vendorCodeOperations } from 'src/store/modules/vendorCodes';
import {
  getAppStage,
  shouldFetchAllCampaignsWithPlatformTypeFilter,
  shouldShowCriteo,
  shouldShowNewBeacon
} from 'src/utils/app';
import { computeUpdatedTimePeriods } from 'src/utils/dateformatting';
import { anyNotEq } from 'src/utils/equality';
import { trackClearFilters, trackFilters } from 'src/utils/mixpanel';
import SignInPrompt from '../AdManager/AdAudit/SignInPrompt/SignInPrompt';
import Loading from '../common/Loading';
import '../common/OverlayScrollbar/theme-overrides.scss';
import GlobalPopup from '../common/Popup/GlobalPopup';
import { HeaderContainer } from '../Header';
import PrimaryNavigationContainer from '../Navigation/PrimaryNavigationContainer';
import { adPlatformSettingsByClientOperations } from 'src/store/modules/adManager/adPlatformSettingsByClient';
import Header from 'src/components/BeaconRedesignComponents/Header/Header';
import { PrimaryNavBar } from 'src/components/BeaconRedesignComponents/PrimaryPageNavigation/PrimaryNavBar';
import styles from 'src/components/BeaconRedesignComponents/styles/styles';
import { getParentPlatform } from 'src/utils/browser';
import { isArray } from 'lodash';
import { ParentPlatformUtils } from 'src/store/modules/parentPlatform/platformUtils';

class AdHomeAuthLayout extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    children: PropTypes.array.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    brandsFollowing: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    promoTypes: PropTypes.array.isRequired,
    chargeBackIssueTypes: PropTypes.array.isRequired,
    vendorCodes: PropTypes.array.isRequired,
    chargeBackStatuses: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    parentPlatforms: PropTypes.object.isRequired,
    subCategories: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    allSuperUserCategories: PropTypes.array.isRequired,
    allSuperUserSubCategories: PropTypes.array.isRequired,
    resetAdCampaignBuilderReduxState: PropTypes.func.isRequired
  };

  state = {
    isInitializing: true
  };

  async componentDidMount() {
    this.scrollbar = OverlayScrollbars(document.querySelectorAll('body'), {
      className: 'os-theme-dark',
      scrollbars: {
        visibility: 'auto',
        autoHide: 'scroll',
        autoHideDelay: 1500,
        clickScrolling: true
      }
    });
    await this.initializeAfterSplitIOReadyForDrive(this.props);
  }

  async initializeAfterSplitIOReadyForDrive(props, nextProps) {
    const { app } = nextProps || props;
    if (app.name !== AppName.Advertising || this.splitClientReady) {
      await this.initialize(props, nextProps);
      return;
    }

    this.splitClientReady = false;
    try {
      const splitClient = splitFactory.client();
      // Wait for SplitIO SDK ready
      await splitClient.ready().catch((e) => {
        throw e;
      });
      if (app.stage !== 'prod') {
        // eslint-disable-next-line no-console
        // console.log(`SplitIO SDK is asdasdasdasdasdmasl/dmaskldjas;djaslkjready`);
      }
      // SDK is ready
      this.splitClientReady = true;
      await this.initialize(props, nextProps);
    } catch (e) {
      console.warn(`SplitIO SDK could have timed-out!: ${e}`);
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { app } = nextProps || this.props;
    if (app === AppName.Advertising && !this.splitClientReady) {
      return; // Wait till SplitIO SDK is ready
    }

    if (!_isEqual(this.props, nextProps)) {
      await this.initializeAfterSplitIOReadyForDrive(this.props, nextProps);
    }
  }

  componentWillUnmount() {
    if (this.scrollbar && this.scrollbar.destroy) {
      this.scrollbar.destroy();
    }
  }

  initialize = async (props, nextProps) => {
    const {
      app,
      categories,
      location,
      retailer,
      parentPlatform,
      setParentPlatform,
      filters,
      platforms,
      segments,
      setAppRetailer,
      subCategories,
      user,
      brandsFollowing,
      promoTypes,
      chargeBackIssueTypes,
      vendorCodes,
      chargeBackStatuses,
      user: { session, config },
      fetchConfig,
      fetchAllSavedSearch,
      fetchAllAdPortfolios,
      fetchAllBrandsFollowing,
      fetchAdPlatforms,
      fetchAllAdCampaigns,
      fetchAllAdEntities,
      fetchAllPlatformSettings,
      fetchAllAdGroups,
      fetchAdPlatformSettingsByClient,
      fetchUnfilteredCategories,
      fetchSubCategories,
      mainTimePeriod,
      comparisonTimePeriod,
      updateAllAppTimePeriods,
      allWeekIdsByRetailerId,
      setWeekIds,
      updateQueryParams,
      clearAllFilters,
      setIsClearingFilters,
      updateFilters,
      categoriesByRetailerId,
      setFilteredCategories,
      history
    } = nextProps || props;
    let isInitializing = false;
    const { platformEntities } = platforms;
    const { mapQueryFiltersToRedux } = filterSelectors;
    const params = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    });
    // `allRetailerIds` can contain retailer IDs that we don't have data for.
    // `availableRetailerIds` can container retailer IDs that client doesn't subscribe.
    // so the logic here is to read the rid on URL first and find it on availableRetailerIds (if it is not 0 (all retailers)),
    // if nothing found, return the first retailer on user's subscription.
    // At omni user config, there is no config.allWeekIdsByRetailerId. The actual available retailer id will in the metaData. so we temp use fake value here
    let availableRetailerIds = [0];

    availableRetailerIds = Object.keys(config.allWeekIdsByRetailerId)
      .filter((id) => {
        return id !== '0';
      })
      .map((id) => +id);

    const { allRetailerIds } = config;
    availableRetailerIds = _intersection(availableRetailerIds, allRetailerIds).map((id) => {
      return `${id}`;
    });

    let retailerId = params.rid;
    // let parentPlatformParam = params.pp;
    // if param.rid is valid, let it be
    // not valid if 0 or not a valid rid for the client
    if ((retailerId !== '0' && !availableRetailerIds.includes(params.rid)) || !params.rid) {
      // If no available retailer form query params, we set first from array of available ones.
      [retailerId] = availableRetailerIds;
    }

    const { availablePlatforms, current: reduxParentPlatform } = parentPlatform;

    const searchParentPlatform = (_retailerId, _availablePlatforms, _currentParentPlatform) => {
      if (!_availablePlatforms || (_availablePlatforms && _availablePlatforms.length === 0)) {
        return [null, null];
      }

      let { rid = _retailerId, pp } = params;
      let currentPlatform = null;
      let currentRetailer = null;

      if (_retailerId) {
        rid = _retailerId;
      }

      if (isArray(rid)) {
        rid = rid.find((r) => r);
      }
      if (isArray(pp)) {
        pp = pp.find((r) => r);
      }

      // 1. PP and RID -> set PP
      if (pp && rid) {
        currentPlatform = _availablePlatforms.find((item) => item.id.toLowerCase() === pp.toLowerCase());
        currentRetailer = ParentPlatformUtils.getRetailer(currentPlatform, rid);

        // platform were found and rid = 0, we set retailer as allRetailers
        if (currentPlatform && rid === '0') {
          return [currentPlatform, null];
        }

        // check that rid is actually part of pp
        if (!currentRetailer || !currentPlatform) {
          [currentPlatform, currentRetailer] = ParentPlatformUtils.getFirstParentPlatformByRetailer(
            _availablePlatforms,
            rid
          );
        }

        return [currentPlatform, currentRetailer];
      }
      // 2. PP, no RID -> get 1st RID in PP retailer list
      else if (pp && !rid) {
        currentPlatform = _availablePlatforms.find((item) => item.id.toLowerCase() === pp.toLowerCase());
        currentRetailer = ParentPlatformUtils.getFirstRetailer(currentPlatform);
        return [currentPlatform, currentRetailer];
      }
      // 3. No PP and RID -> find first PP which has this RID -> set PP
      else if (!pp && rid) {
        [currentPlatform, currentRetailer] = ParentPlatformUtils.getFirstParentPlatformByRetailer(
          _availablePlatforms,
          rid
        );
        if (!currentPlatform && rid === '0') {
          currentPlatform = _currentParentPlatform;
        }
        return [currentPlatform, currentRetailer];
      }
      // 4. No PP and no RID -> Take first PP and 1st RID in it
      else {
        [currentPlatform, currentRetailer] = ParentPlatformUtils.getFirstResult(_availablePlatforms);
        return [currentPlatform, currentRetailer];
      }
    };

    const [foundedParentPlatform] = searchParentPlatform(retailerId, availablePlatforms, reduxParentPlatform);

    // if ((!availablePlatforms.some((r) => r.id === params.pp) || !params.pp) && foundedParentPlatform) {
    //   // set first available platform we found based on rid
    //   parentPlatformParam = foundedParentPlatform.id;
    // }
    if (!reduxParentPlatform && foundedParentPlatform) {
      setParentPlatform(foundedParentPlatform);
    }

    // Need to change for new Parent Platform as well?
    if (retailerId !== params.rid || (!params.pp && foundedParentPlatform)) {
      // Redirect to new retailer if param rid is not subscribed to by client
      const newRetailer = retailer.availableRetailers.find((r) => r.id === retailerId);
      if (newRetailer) {
        const additionalParams = { ...params };

        const { searchParams, nonPersistentParams } = calculateNewQueryParams(
          app,
          newRetailer,
          mainTimePeriod,
          comparisonTimePeriod,
          additionalParams,
          null,
          foundedParentPlatform
        );

        history.push(`${searchParams}${nonPersistentParams}`);
      }
    }

    // set the retailer and parent platform on load
    // will it trigger on query RID or PP change, yes? so params.rid not always === retailer.id
    if (!retailer || !retailer.id || (params.rid && params.rid !== retailer.id)) {
      isInitializing = true;
      if (foundedParentPlatform) {
        setAppRetailer(retailerId, foundedParentPlatform.id);
        // if parent platform in redux doesn't match in query, update redux
        if (reduxParentPlatform && foundedParentPlatform.id !== reduxParentPlatform.id) {
          setParentPlatform(foundedParentPlatform);
        }
      } else {
        // eslint-disable-next-line no-console
        // Find a different way to assign when no PP available
        // setAppRetailer(retailerId, '');
        // console.log(`Missing foundedParentPlatform`);
      }
    }

    // Fetch config if we do not have one already
    if (!session) {
      isInitializing = true;
      fetchConfig();
    }

    // Set week ids
    if (session && config && _isEmpty(allWeekIdsByRetailerId)) {
      setWeekIds(config.allWeekIdsByRetailerId);
    }

    // Set categories
    if (
      (retailer.id && !_isEmpty(categoriesByRetailerId) && _isEmpty(categories)) ||
      (retailer.id && !_isEmpty(categoriesByRetailerId) && !_isEqual(retailer.id, this.props.retailer.id)) ||
      retailer.id === '0'
    ) {
      setFilteredCategories(categoriesByRetailerId, retailer);
    }

    // Load some stuff into redux, e.g. categories, segments
    if (retailer.id && !mainTimePeriod.startWeek && !_isEmpty(allWeekIdsByRetailerId) && !this.state.isInitializing) {
      fetchAllSavedSearch(app.name);
      fetchAllBrandsFollowing(app.name);
      fetchSubCategories().then(fetchUnfilteredCategories);

      const { config: userConfig } = user;
      const beaconClientId = _get(userConfig, ['vendor', 'BeaconClientId']);
      const userEmail = _get(userConfig, ['profile', 'email'], null);
      if (!this.splitClientReady) {
        console.warn(`Fetching SplitIO treatment before SDK ready`);
        return;
      }
      // if pp fetch all
      // FIXME: what if query empty
      const _parentPlatform = getParentPlatform();

      const shouldAddPlatformTypeFilter = shouldFetchAllCampaignsWithPlatformTypeFilter(
        beaconClientId,
        userEmail,
        app.stage
      );
      // fetch all platforms and all ad entities if user login to Ad manager
      fetchAdPlatforms(retailer.id).then(() => {
        fetchAllAdCampaigns(!_parentPlatform && shouldAddPlatformTypeFilter);
      });
      fetchAllPlatformSettings();
      fetchAdPlatformSettingsByClient().then(() => {
        fetchAllAdEntities(false);
        fetchAllAdPortfolios(false);
      });

      const { updatedMainPeriod, updatedComparisonPeriod, startWeek, endWeek, allWeekIds } = computeUpdatedTimePeriods({
        retailer,
        app,
        params,
        mainTimePeriod,
        allWeekIdsByRetailerId,
        comparisonTimePeriod,
        appName: app.name
      });

      isInitializing = true;
      updateAllAppTimePeriods(allWeekIds, updatedMainPeriod, updatedComparisonPeriod);
      if (params.wr !== 'cd' && (parseInt(params.sw, 10) !== startWeek || parseInt(params.ew, 10) !== endWeek)) {
        updateQueryParams(app, { id: retailer.id }, updatedMainPeriod, updatedComparisonPeriod, params).then(
          (response) => {
            const { additionalParams, nonPersistentParams, searchParams, dropDownSelectionParams } = response.params;

            const mergedParams = {
              ...queryString.parse(dropDownSelectionParams),
              ...queryString.parse(additionalParams)
            };
            const stringifiedMergedParams = queryString.stringify(mergedParams);

            return history.push(`${location.pathname}${searchParams}&${stringifiedMergedParams}${nonPersistentParams}`);
          }
        );
      }

      if (shouldShowCriteo()) {
        fetchAllAdGroups();
      }
    }

    if (
      (retailer.id &&
        mainTimePeriod.startWeek &&
        comparisonTimePeriod.startWeek &&
        !_isEqual(this.props.retailer, retailer)) ||
      anyNotEq(['mainTimePeriod', 'comparisonTimePeriod', 'location'], this.props, nextProps || props)
    ) {
      updateQueryParams(app, retailer, mainTimePeriod, comparisonTimePeriod, params, foundedParentPlatform);
    }

    // Just clear all filters if there is a location change anywhere in the app
    const categoriesForEntity = categories;
    const subCategoriesForEntity = subCategories;
    const { availableRetailers } = retailer;
    // Check to see that all of the fields we can filter on are populated
    const allFiltersNotEmpty = [
      categoriesForEntity,
      segments,
      subCategoriesForEntity,
      platforms,
      brandsFollowing
    ].every((filter) => !_isEmpty(filter));
    if (allFiltersNotEmpty) {
      const updatedFilters = mapQueryFiltersToRedux({
        filters,
        retailer,
        parentPlatform,
        segments,
        user,
        availableRetailers,
        promoTypes,
        chargeBackIssueTypes,
        vendorCodes,
        chargeBackStatuses,
        queryParams: params,
        categories: categoriesForEntity,
        platforms: platformEntities,
        subcategories: subCategoriesForEntity,
        brands: brandsFollowing
      });
      const allFilterNames = [
        'category',
        'subcategory',
        'platform',
        'segment',
        'keywordSegment',
        'retailPrice',
        'businessUnits',
        'brand',
        'region',
        'country',
        'retailer',
        'promoType',
        'chargeBackIssueType',
        'vendorCode',
        'chargeBackStatus'
      ];
      const allFiltersAreNull = allFilterNames.every((key) => filters[key] === null || filters[key] === undefined);

      if (params.filter && (!_isEqual(updatedFilters, this.props.filters) || allFiltersAreNull)) {
        updateFilters(updatedFilters);
        const mixPanelUpdatedFilters = { ...updatedFilters };
        if (mixPanelUpdatedFilters.category !== null) {
          // Removing childEntities only for mixPanel purposes otherwise the call fails with 413 (payload too large)
          mixPanelUpdatedFilters.category = mixPanelUpdatedFilters.category.map((item) =>
            _omit(item, ['childEntities'])
          );
        }
        trackFilters(location, mixPanelUpdatedFilters, user);
      } else if (!params.filter) {
        const hasNotNullFilter = !!allFilterNames.find((key) => filters[key] !== null);
        // Only call this if the filters in redux are not equal
        if (!_isEqual(updatedFilters, this.props.filters) && hasNotNullFilter) {
          trackClearFilters(updatedFilters, user);
          clearAllFilters(allFilterNames.reduce((acc, filterName) => ({ ...acc, [filterName]: null }), {})).then(() =>
            setIsClearingFilters(false)
          );
        }
      }
    }

    // Update time period if it does not match params (this usually happens when redirecting to a page from
    // /sign_in with a custom date)
    const needToUpdate = params.wr && mainTimePeriod.id && params.wr !== mainTimePeriod.id;
    if (
      needToUpdate ||
      (params.wr === 'cd' &&
        params.sw &&
        (mainTimePeriod.startWeek
          ? mainTimePeriod.startWeek !== parseInt(params.sw, 10)
          : false || mainTimePeriod.endWeek
          ? mainTimePeriod.endWeek !== parseInt(params.ew, 10)
          : false || mainTimePeriod.startDayId
          ? mainTimePeriod.startDayId !== parseInt(params.sdid, 10)
          : false || mainTimePeriod.endDayId
          ? mainTimePeriod.endDayId !== parseInt(params.edid, 10)
          : false))
    ) {
      const { updatedMainPeriod, updatedComparisonPeriod, allWeekIds } = computeUpdatedTimePeriods({
        retailer,
        app,
        params,
        mainTimePeriod,
        allWeekIdsByRetailerId,
        comparisonTimePeriod,
        appName: app.name
      });

      updateAllAppTimePeriods(allWeekIds, updatedMainPeriod, updatedComparisonPeriod);
    }

    this.setState({ isInitializing });
  };

  isLoading = () => {
    if (this.state.isInitializing) {
      return true;
    }

    return false;
  };

  render() {
    const { location, resetAdCampaignBuilderReduxState, app } = this.props;

    if (this.isLoading()) {
      return <Loading style={{ position: 'fixed' }} className="spinner" size={150} thickness={2} />;
    }

    const pathname = location.pathname.includes('account') || location.pathname === '/search' ? '/' : location.pathname;
    const { user } = store.getState();
    const isAdAuditUser = app.name === AppName.Advertising && _get(user, 'config.adAuditEnabled', false);
    const adAuditUiPreference = _get(user, 'config.uiPreference.adAudit', null); // adAudit will be null for new users
    let showSignInPrompt = isAdAuditUser ? adAuditUiPreference === null : false;
    if (isAdAuditUser && adAuditUiPreference !== null) {
      const { hasRequestedDemo, nextReminder, hasDisabledPrompt } = adAuditUiPreference;
      if (hasRequestedDemo !== undefined && hasDisabledPrompt !== undefined) {
        showSignInPrompt =
          !hasRequestedDemo && !hasDisabledPrompt && (!nextReminder || moment().isAfter(moment(nextReminder)));
      }
    }
    const isDev = getAppStage() === 'dev';

    // TODO: Mount new Beacon header + primary navigation controls
    // * Add split to primary navigation sidebar

    // Beacon Redesign Logic
    const showNewBeacon = shouldShowNewBeacon();

    if (showNewBeacon) {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', flex: 'row nowrap' }}
          className="home-auth-layout-wrapper"
        >
          {/* New header and navigation */}
          <Header />
          <PrimaryNavBar location={location} />
          {/* New main view container */}
          <div style={{ ...styles.pageCenterDiv, paddingTop: '30px' }}>
            <div className="sl-page">
              <div>
                {/* AuthenticatedRouter */}
                {this.props.children}
                <div className="sl-page__empty-sidebar" />
              </div>
              <GlobalPopup resetAdCampaignBuilderReduxState={resetAdCampaignBuilderReduxState} />
              <SignInPrompt open={showSignInPrompt && isDev} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', flex: 'row nowrap' }} className="home-auth-layout-wrapper">
          <PrimaryNavigationContainer />
          <div style={{ flex: '1 1', minWidth: 0, minHeight: 0 }} className="home-auth-layout">
            <div className="sl-page">
              <HeaderContainer />
              <div className={`sl-page__body ${pathname !== '/' ? 'sl-page__body--atlas' : ''}`}>
                {this.props.children}
                <div className="sl-page__empty-sidebar" />
              </div>
              <GlobalPopup resetAdCampaignBuilderReduxState={resetAdCampaignBuilderReduxState} />
              <SignInPrompt open={showSignInPrompt && isDev} />
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) =>
  _pick(state, [
    'allWeekIdsByRetailerId',
    'app',
    'auth',
    'brandsFollowing',
    'categories',
    'comparisonTimePeriod',
    'filters',
    'mainTimePeriod',
    'navSidebar',
    'platforms',
    'promoTypes',
    'chargeBackIssueTypes',
    'vendorCodes',
    'chargeBackStatuses',
    'retailer',
    'parentPlatform',
    'user',
    'segments',
    'subCategories',
    'allSuperUserCategories',
    'allSuperUserSubCategories',
    'categoriesByRetailerId'
  ]);

const mapDispatchToProps = {
  setAppRetailer: retailerOperations.updateRetailer,
  setParentPlatform: parentPlatformOperations.setParentPlatform,
  updateAllAppTimePeriods: updateAllTimePeriods,
  fetchUnfilteredCategories: categoryOperations.fetchUnfilteredCategories,
  fetchAllSavedSearch: segmentOperations.fetchAllSavedSearch,
  fetchAllBrandsFollowing: brandOperations.fetchBrandsFollowing,
  fetchPromoTypesForRetailer: promoTypeOperations.fetchPromoTypesForRetailer,
  fetchChargeBackIssueTypes: chargeBackIssueTypeOperations.fetchChargeBackIssueTypes,
  fetchVendorCodes: vendorCodeOperations.fetchVendorCodes,
  fetchChargeBackStatuses: chargeBackStatusesOperations.fetchChargeBackStatuses,
  fetchAllAdEntities: adEntityOperations.fetchAdEntities,
  fetchAllAdPortfolios: adPortfolioOperations.fetchAdPortfolios,
  fetchAllAdCampaigns: adCampaignOperations.fetchAdCampaigns,
  fetchAllPlatformSettings: adPlatformSettingsOperations.fetchAdPlatformSettings,
  fetchAllAdGroups: adGroupOperations.fetchAllAdGroups,
  fetchAdPlatforms: adPlatformOperations.fetchAdPlatforms,
  fetchAdPlatformSettingsByClient: adPlatformSettingsByClientOperations.fetchAdPlatformSettingsByClient,
  fetchSubCategories: subCategoryOperations.fetchSubCategories,
  fetchConfig: appOperations.fetchConfig,
  updateFilters: filterOperations.updateFilters,
  setWeekIds: updateAllWeekIdsByRetailerId,
  updateQueryParams: appOperations.updateQueryParams,
  clearAllFilters: filterOperations.clearAllFilters,
  setIsClearingFilters: filterOperations.setIsClearingFilters,
  setFilteredCategories: categoryOperations.setFilteredCategories,
  fetchElasticSearchAdCampaigns: adCampaignOperations.fetchElasticSearchAdCampaigns,
  resetAdCampaignBuilderReduxState: AdCampaignBuilderOperations.resetAdCampaignBuilderReduxState
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdHomeAuthLayout));
