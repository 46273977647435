import types from './types';
import actions from './actions';
import { ValueOf } from 'sl-api-connector/types';

const omniEntity = (state = {}, action: ReturnType<ValueOf<typeof actions>>) => {
  switch (action.type) {
    case types.RECEIVE_OMNI_ENTITY:
      return action.entity;
    default:
      return state;
  }
};

export default omniEntity;
