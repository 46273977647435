import React, { useState, Dispatch, SetStateAction, useMemo, useContext } from 'react';
import { Text } from '@stackline/ui';
import { useAppSelector } from 'src/utils/Hooks';
import EntityTableHeader from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import {
  ForecastsAdjustmentData,
  PlanType,
  handleCsvDownload,
  useForecastsAdjustmentsColumnDefinitions,
  useGenerateCSVData
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import CommonSummaryWithDropdown from 'src/components/BeaconRedesignComponents/common/CommonSummaryWithDropdown';
import { buildSubtitleDisplayName } from 'src/utils/filters';
import ReduxStore from 'src/types/store/reduxStore';
import { useForecastPlanType } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import { DeleteAdjustmentModal } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/DeleteAdjustmentModal/DeleteAdjustmentModal';
import { CreateAdjustmentButton } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/CreateAdjustmentButton';
import { AdjustmentPlanType } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import { SlColumn } from 'src/components/BeaconRedesignComponents/Generic/SlColumn';
import { SlRow } from 'src/components/BeaconRedesignComponents/Generic/SlRow';
import { Box } from '@mui/material';
import { useDeleteAdjustment } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ViewAdjustmentModal/utils';
import { AdjustmentModal } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/AdjustmentModal';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import useAdjustmentPolling from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAdjustmentPolling';
import { useAsyncFunction } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAsyncFunction';
import * as serverProxy from './Forecasts/RefactoredAdjustmentModals/serverProxy';
import NoDataPlaceHolder, {
  NoDataPlaceHolderTableRow
} from 'src/components/BeaconRedesignComponents/common/NoDataPlaceHolder/NoDataPlaceHolder';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import {
  RightArrow,
  EmptyAdjustmentBuyBox,
  EmptyAdjustmentContentScore,
  EmptyAdjustmentInstockRate,
  EmptyAdjustmentOrganicTraffic,
  EmptyAdjustmentOtherTraffic,
  EmptyAdjustmentPaidTraffic,
  EmptyAdjustmentRatingsReviews,
  EmptyAdjustmentRetailPrice
} from 'src/components/SvgIcons';
import ForecastAdjustmentsTypeProvider, {
  ForecastAdjustmentsTypeContext
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/ForecastAdjustmentsTypeProvider';
import { useCompleteAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useCompleteAdjustmentData/useCompleteAdjustmentData';
import { byNetImpact } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useCompleteAdjustmentData/utils';
import { useBulkAdjustmentContext } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/hooks/useBulkAdjustmentContext';
import { BulkAdjustmentModalType } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkAdjustmentProvider';
import { bulkAdjustmentsEnabled } from 'src/utils/app';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import { Link } from 'react-router-dom';

interface ForecastPlanGroupByField {
  name: string;
  displayName: string;
}

interface SummarySubTitle {
  displayName?: string;
  subtitleDisplayName?: string;
  href?: string;
  entityDisplayName?: string;
}

const FORECASTS_ADJUSTMENTS_GROUP_BY_FIELDS_FOR_PAGE: ForecastPlanGroupByField[] = [
  { name: 'stacklineSku', displayName: 'Product' },
  { name: 'forecastsPlansAdjustmentsForUser', displayName: 'User' }
];

const planTypeToIcon = {
  [PlanTypeOption.OrganicTraffic]: <EmptyAdjustmentOrganicTraffic />,
  [PlanTypeOption.OtherTraffic]: <EmptyAdjustmentOtherTraffic />,
  [PlanTypeOption.PaidTraffic]: <EmptyAdjustmentPaidTraffic />,
  [PlanTypeOption.RetailPrice]: <EmptyAdjustmentRetailPrice />,
  [PlanTypeOption.ContentScore]: <EmptyAdjustmentContentScore />,
  [PlanTypeOption.InStockRate]: <EmptyAdjustmentInstockRate />,
  [PlanTypeOption.RatingsReviews]: <EmptyAdjustmentRatingsReviews />,
  [PlanTypeOption.BuyBox]: <EmptyAdjustmentBuyBox />
};

const ForecastsPlansProductTable = ({
  onRowClick,
  rowData,
  selectedGroupByFieldForTable,
  tableTitle,
  isLoading,
  planType
}: {
  onRowClick: (rowData: any) => void;
  rowData: ForecastsAdjustmentData[];
  selectedGroupByFieldForTable: ForecastPlanGroupByField;
  setSelectedGroupByFieldForTable: Dispatch<SetStateAction<ForecastPlanGroupByField>>;
  tableTitle: string;
  isLoading: boolean;
  planType: PlanTypeOption;
}) => {
  const entityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const COL_DEFS = useForecastsAdjustmentsColumnDefinitions(selectedGroupByFieldForTable);
  const { setSelectedPlanType, setAdjustmentModalOpen } = useContext(ForecastAdjustmentsTypeContext);
  const preExportData = useGenerateCSVData(rowData);
  const formattedExportData = rowData ? preExportData : [];
  const { openBulkAdjustmentModal } = useBulkAdjustmentContext();

  const handlePlaceHolderLinkClick = () => {
    setSelectedPlanType(planType);
    setAdjustmentModalOpen(true);
  };

  const handleNewBulkAdjustment = () => {
    openBulkAdjustmentModal({
      type: BulkAdjustmentModalType.DownloadTemplate,
      planType
    });
  };

  // When in product level, we should provide them to open a modal for creating new adjustment
  // other entity level, we use default "No Data Available"
  const customPlaceHolder =
    entityType === 'product' ? (
      <NoDataPlaceHolderTableRow
        title={`No ${tableTitle.toLocaleLowerCase()} adjustments?`}
        description=""
        link={
          <Flex
            alignItems="center"
            onClick={handlePlaceHolderLinkClick}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            <Text variant="body2">Create a new adjustment</Text>
            <RightArrow style={{ width: '12px', height: '12px', marginLeft: '2px' }} />
          </Flex>
        }
        icon={planTypeToIcon[planType]}
      />
    ) : bulkAdjustmentsEnabled() ? (
      <NoDataPlaceHolderTableRow
        title={`No ${tableTitle.toLocaleLowerCase()} adjustments?`}
        link={
          <Flex
            alignItems="center"
            onClick={() => handleNewBulkAdjustment()}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            <Text variant="body2">Create a new bulk adjustment</Text>
            <RightArrow style={{ width: '12px', height: '12px', marginLeft: '2px' }} />
          </Flex>
        }
        icon={planTypeToIcon[planType]}
      />
    ) : (
      <NoDataPlaceHolder />
    );

  // pageSize should be changed when user clicks "View more", so we can hide "View more" row when expanded.
  return (
    <Box display="flex" flexDirection="column">
      <EntityTableHeader
        prefixTitle={tableTitle}
        title={tableTitle}
        paddingBottom="20.5px"
        enabledActions={['ExportCsv']}
        handleCSVExport={() => handleCsvDownload(formattedExportData)}
        // We don't want to show export button when data is not available
        rowData={rowData || []}
      />
      <EntityTable
        isLoading={isLoading}
        onRowClick={onRowClick}
        rowData={rowData || []}
        columnDefs={COL_DEFS}
        shouldModifyColumnDefs={false}
        supportViewMore
        initialPageSize={10}
        appTableProps={{
          noDataPlaceholder: customPlaceHolder
        }}
      />
    </Box>
  );
};

const ForecastsAdjustmentsPage = () => {
  const retailer = useAppSelector((state: ReduxStore) => state.retailer);
  const { mainEntity } = useAppSelector((state) => state.entityService);

  const { type: entityType } = mainEntity;
  const isProductEntity = entityType === 'product';
  const filters = useAppSelector((state: ReduxStore) => state.filters);
  const categories = useAppSelector((state: ReduxStore) => state.categories);
  const app = useAppSelector((state: ReduxStore) => state.app);
  const filterParamsFromUrl = new URLSearchParams(window.location.search);
  const adjustmentStatusSet = new Set(filterParamsFromUrl.getAll('adjustmentStatus'));
  const adjustmentFilterParams = Array.from(adjustmentStatusSet.values()).reduce(
    (prevString, currentAdjustmentType) => {
      return `${prevString}&adjustmentStatus=${currentAdjustmentType}`;
    },
    ''
  );

  const { wrappedFunction: editAdjustment } = useAsyncFunction(serverProxy.editAdjustment);

  const { setIsPolling } = useAdjustmentPolling();

  const { data, isLoading } = useCompleteAdjustmentData();

  const organicTrafficData = data
    ? data.filter((datum) => datum.planType === PlanTypeOption.OrganicTraffic).sort(byNetImpact)
    : [];
  const otherTrafficData = data
    ? data.filter((datum) => datum.planType === PlanTypeOption.OtherTraffic).sort(byNetImpact)
    : [];
  const paidTrafficData = data
    ? data.filter((datum) => datum.planType === PlanTypeOption.PaidTraffic).sort(byNetImpact)
    : [];
  const retailPriceData = data
    ? data.filter((datum) => datum.planType === PlanTypeOption.RetailPrice).sort(byNetImpact)
    : [];
  const contentScoreData = data
    ? data.filter((datum) => datum.planType === PlanTypeOption.ContentScore).sort(byNetImpact)
    : [];
  const inStockRateData = data
    ? data.filter((datum) => datum.planType === PlanTypeOption.InStockRate).sort(byNetImpact)
    : [];
  const ratingsAndReviewsData = data
    ? data.filter((datum) => datum.planType === PlanTypeOption.RatingsReviews).sort(byNetImpact)
    : [];
  const buyBoxData = data ? data.filter((datum) => datum.planType === PlanTypeOption.BuyBox).sort(byNetImpact) : [];

  /**
   * View & Delete modal states
   */
  const [viewAdjustmentModalOpen, setViewAdjustmentModalOpen] = useState(false);

  const [adjustmentDataForModal, setAdjustmentDataForModal] = useState<ForecastsAdjustmentData>(null);
  const [deleteAdjustmentModalOpen, setDeleteAdjustmentModalOpen] = useState(false);

  const [adjustmentToDelete, setAdjustmentToDelete] = useState<ForecastsAdjustmentData>(null);

  const { deleteAdjustment } = useDeleteAdjustment();

  const [selectedGroupByFieldForPage, setSelectedGroupByFieldForPage] = useState(
    isProductEntity
      ? FORECASTS_ADJUSTMENTS_GROUP_BY_FIELDS_FOR_PAGE[1]
      : FORECASTS_ADJUSTMENTS_GROUP_BY_FIELDS_FOR_PAGE[0]
  );

  const selectedPlanType = useForecastPlanType();

  const handleAdjustmentRowClick = (rowData: ForecastsAdjustmentData) => {
    setAdjustmentDataForModal(rowData);
    setViewAdjustmentModalOpen(true);
  };

  const handleAdjustmentModalClose = () => {
    setViewAdjustmentModalOpen(false);
  };

  const handleDeleteButtonClick = ({ adjustmentData }: { adjustmentData: ForecastsAdjustmentData }) => {
    setViewAdjustmentModalOpen(false);
    setAdjustmentToDelete(adjustmentData);
    setDeleteAdjustmentModalOpen(true);
  };

  const handleDeleteAdjustmentModalClose = () => {
    setDeleteAdjustmentModalOpen(false);
  };

  const summarySubTitle: SummarySubTitle = mainEntity
    ? buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app)
    : {
        displayName: 'All Categories',
        subtitleDisplayName: 'All Categories',
        href: '',
        entityDisplayName: 'All Categories'
      };

  const title = summarySubTitle.href ? (
    <Link to={`${summarySubTitle.href}${adjustmentFilterParams}`}>
      <Text variant="body2">{summarySubTitle.subtitleDisplayName || summarySubTitle.entityDisplayName}</Text>
    </Link>
  ) : (
    <Text variant="body2">{summarySubTitle.subtitleDisplayName}</Text>
  );

  const planTypesComponents = useMemo(
    () => [
      <ForecastsPlansProductTable
        key={AdjustmentPlanType.OrganicTraffic}
        isLoading={isLoading}
        onRowClick={handleAdjustmentRowClick}
        rowData={organicTrafficData}
        selectedGroupByFieldForTable={selectedGroupByFieldForPage}
        setSelectedGroupByFieldForTable={setSelectedGroupByFieldForPage}
        tableTitle={PlanType.OrganicTraffic}
        planType={PlanTypeOption.OrganicTraffic}
      />,
      // //  Other Traffic
      <ForecastsPlansProductTable
        key={AdjustmentPlanType.OtherTraffic}
        isLoading={isLoading}
        onRowClick={handleAdjustmentRowClick}
        rowData={otherTrafficData}
        selectedGroupByFieldForTable={selectedGroupByFieldForPage}
        setSelectedGroupByFieldForTable={setSelectedGroupByFieldForPage}
        tableTitle={PlanType.OtherTraffic}
        planType={PlanTypeOption.OtherTraffic}
      />,
      //  Paid Traffic
      <ForecastsPlansProductTable
        key={AdjustmentPlanType.PaidTraffic}
        isLoading={isLoading}
        onRowClick={handleAdjustmentRowClick}
        rowData={paidTrafficData}
        selectedGroupByFieldForTable={selectedGroupByFieldForPage}
        setSelectedGroupByFieldForTable={setSelectedGroupByFieldForPage}
        tableTitle={PlanType.PaidTraffic}
        planType={PlanTypeOption.PaidTraffic}
      />,
      // Retail Price
      <ForecastsPlansProductTable
        key={AdjustmentPlanType.RetailPrice}
        isLoading={isLoading}
        onRowClick={handleAdjustmentRowClick}
        rowData={retailPriceData}
        selectedGroupByFieldForTable={selectedGroupByFieldForPage}
        setSelectedGroupByFieldForTable={setSelectedGroupByFieldForPage}
        tableTitle={PlanType.RetailPrice}
        planType={PlanTypeOption.RetailPrice}
      />,
      // Content Score
      <ForecastsPlansProductTable
        key={AdjustmentPlanType.ContentScore}
        isLoading={isLoading}
        onRowClick={handleAdjustmentRowClick}
        rowData={contentScoreData}
        selectedGroupByFieldForTable={selectedGroupByFieldForPage}
        setSelectedGroupByFieldForTable={setSelectedGroupByFieldForPage}
        tableTitle={PlanType.ContentScore}
        planType={PlanTypeOption.ContentScore}
      />,
      // In-Stock Rate
      <ForecastsPlansProductTable
        key={AdjustmentPlanType.InstockRate}
        isLoading={isLoading}
        onRowClick={handleAdjustmentRowClick}
        rowData={inStockRateData}
        selectedGroupByFieldForTable={selectedGroupByFieldForPage}
        setSelectedGroupByFieldForTable={setSelectedGroupByFieldForPage}
        tableTitle={PlanType.InstockRate}
        planType={PlanTypeOption.InStockRate}
      />,
      // Ratings & Reviews
      <ForecastsPlansProductTable
        key={AdjustmentPlanType.RatingsReviews}
        isLoading={isLoading}
        onRowClick={handleAdjustmentRowClick}
        rowData={ratingsAndReviewsData}
        selectedGroupByFieldForTable={selectedGroupByFieldForPage}
        setSelectedGroupByFieldForTable={setSelectedGroupByFieldForPage}
        tableTitle={PlanType.RatingsReviews}
        planType={PlanTypeOption.RatingsReviews}
      />,
      //  Buy Box
      <ForecastsPlansProductTable
        key={AdjustmentPlanType.BuyBox}
        isLoading={isLoading}
        onRowClick={handleAdjustmentRowClick}
        rowData={buyBoxData}
        selectedGroupByFieldForTable={selectedGroupByFieldForPage}
        setSelectedGroupByFieldForTable={setSelectedGroupByFieldForPage}
        tableTitle={PlanType.Buybox}
        planType={PlanTypeOption.BuyBox}
      />
    ],
    [
      isLoading,
      selectedGroupByFieldForPage,
      buyBoxData,
      organicTrafficData,
      contentScoreData,
      inStockRateData,
      otherTrafficData,
      paidTrafficData,
      ratingsAndReviewsData,
      retailPriceData
    ]
  );

  const filteredComponents =
    selectedPlanType === 'AllPlanTypes'
      ? planTypesComponents
      : planTypesComponents.filter((component) => component.key === selectedPlanType);

  return (
    <>
      <ForecastAdjustmentsTypeProvider>
        <BeaconPageContainer>
          <Flex flexDirection="column" gap="md">
            <SlRow horizontalPosition="space-between">
              <SlColumn spacing="sm">
                <CommonSummaryWithDropdown
                  prefixTitle="Adjustments"
                  title={isProductEntity ? 'User' : selectedGroupByFieldForPage.displayName}
                  selectedGroupBy={selectedGroupByFieldForPage}
                  groupByFields={
                    isProductEntity
                      ? [FORECASTS_ADJUSTMENTS_GROUP_BY_FIELDS_FOR_PAGE[1]]
                      : FORECASTS_ADJUSTMENTS_GROUP_BY_FIELDS_FOR_PAGE
                  }
                  handleGroupByChange={({ target }) =>
                    setSelectedGroupByFieldForPage(
                      FORECASTS_ADJUSTMENTS_GROUP_BY_FIELDS_FOR_PAGE.find((field) => field.name === target.value)
                    )
                  }
                />
                {title}
              </SlColumn>
              <CreateAdjustmentButton />
            </SlRow>
            {isProductEntity ? null : (
              <SlColumn>
                <Text variant="body1">
                  {`The sections below summarize the adjustments made that impact the sales forecast for ${
                    ['brand', 'client'].includes(entityType) ? mainEntity.name : `this ${entityType}`
                  }.`}
                </Text>
              </SlColumn>
            )}
          </Flex>
          <Box display="flex" flexDirection="column" gap="16px">
            {filteredComponents.map((component) => component)}
          </Box>
        </BeaconPageContainer>
        {deleteAdjustmentModalOpen && adjustmentToDelete && (
          <DeleteAdjustmentModal
            deleteAdjustment={deleteAdjustment}
            adjustmentToDelete={adjustmentToDelete}
            open={deleteAdjustmentModalOpen}
            onClose={handleDeleteAdjustmentModalClose}
          />
        )}

        {adjustmentDataForModal && (
          <Box position="absolute">
            <AdjustmentModal
              sendAdjustmentUpdate={editAdjustment}
              stacklineSku={adjustmentDataForModal.stacklineSku}
              retailerSku={adjustmentDataForModal.retailerSku}
              categoryId={adjustmentDataForModal.product.categoryId}
              subcategoryId={adjustmentDataForModal.product.subCategoryId}
              product={adjustmentDataForModal.product}
              adjustmentData={adjustmentDataForModal}
              open={viewAdjustmentModalOpen}
              onClose={handleAdjustmentModalClose}
              handleDelete={() => handleDeleteButtonClick({ adjustmentData: adjustmentDataForModal })}
              setIsPolling={setIsPolling}
            />
          </Box>
        )}
      </ForecastAdjustmentsTypeProvider>
    </>
  );
};

export default ForecastsAdjustmentsPage;
