import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import CommonSummaryInfo from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import BoxFilterControls, {
  BoxFilterPayload
} from 'src/components/ShortageDisputes/components/DisputeManagement/BoxFilterControls';
import DisputeDetailsTable from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeDetailsTable';
import { Widget } from 'src/types/application/widgetTypes';
import _cloneDeep from 'lodash/cloneDeep';
import FilterModal from 'src/components/ShortageDisputes/components/DisputeManagement/FilterModal';
import TuneIcon from '@mui/icons-material/Tune';
import { useSubmitDisputes, useFetchDisputeData } from 'src/components/ShortageDisputes/hooks';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { Search } from 'src/components/Header';
import { AppButton } from '@stackline/ui';
import { Tooltip } from '@mui/material';
import SubmissionModal from 'src/components/ShortageDisputes/components/DisputeManagement/SubmissionModal';
import { withBus } from 'react-bus';
import colors from 'src/utils/colors';
import { parseUrlIntoFilters } from 'src/components/ShortageDisputes/utils';

const SectionTitleWidget: Widget = { view: { title: 'Dispute Management', disableLegend: true } };

export interface StageStatusMap {
  name: string;
  statuses: string[];
  isChecked: boolean;
}

export interface SearchObject {
  search: string;
}

export interface Filters {
  startDate: string;
  endDate: string;
  minValue: string;
  maxValue: string;
  stage: string;
  status: string;
}

export interface FilterUpdate extends Filters {
  [name: string]: string;
}

const initialFilters: Filters = {
  startDate: '',
  endDate: '',

  minValue: '',
  maxValue: '',

  stage: '',
  status: ''
};

const FilterModalButton = ({ handleClick, disabled }: { handleClick: () => void; disabled: boolean }) => {
  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      style={{
        display: 'flex',
        gap: '10px',
        height: '25px',
        alignItems: 'center',
        border: 'none',
        backgroundColor: 'transparent',
        marginRight: '25px'
      }}
    >
      <TuneIcon sx={{ color: colors.darkBlue }} />
      <span
        style={{
          borderRadius: '5px',
          border: 'none',
          color: colors.darkBlue,
          backgroundColor: 'transparent',
          whiteSpace: 'nowrap',
          fontWeight: '600'
        }}
      >
        FILTER
      </span>
    </button>
  );
};

interface DisputeManagementPageProps {
  queryClient: any;
  location: any;
  history: any;
  eventBus: any;
}

const DisputeManagementDashboard: React.FC<DisputeManagementPageProps> = ({
  queryClient,
  location,
  history,
  eventBus
}) => {
  const searchParameters = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<Filters>(parseUrlIntoFilters(searchParameters, initialFilters));
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [submissionModalOpen, setSubmissionModalOpen] = useState(false);
  const [selectedDisputes, setSelectedDisputes] = useState([]);
  const { app } = useSelector((state: ReduxStore) => state);
  const [pageNumber, setPageNumber] = useState(1);

  const { stage, status } = filters;

  // We change the max amount of rows to display based on the filter selected.
  // This allows us to select more Contact Us Cases for submission.
  const maxPageSize = stage === 'Contact Us Case' && status === 'Ready To Submit' ? 50 : 10;

  const { data, isLoading } = useFetchDisputeData({ location, pageNumber, maxPageSize });
  const { submitDisputes, isSubmitting, submissionError, submissionResponse, reset } = useSubmitDisputes({ eventBus });

  useEffect(() => {
    setPageNumber(1);
    setFilters(parseUrlIntoFilters(searchParameters, initialFilters));
  }, [location]);

  /**
    Takes a copy of the filter state and returns an updated param string
    @param {Filters} filterState - A copy of the most current filter state object
    @returns {string} The new filter parameters to be appended to URL
    */
  const generateFilterParamsForUrl = (filterState: Filters) => {
    const params = Object.entries(filterState)
      .filter(([_, value]) => value !== '')
      .map(([key, value]) => `${key}=${value.toLowerCase().replaceAll(' ', '-')}`)
      .join('&');

    const { searchParams, additionalParams } = app.queryParams;
    const { pathname } = location;
    const newFilterParams = `&${params}`;
    return `${pathname}${searchParams}${additionalParams}${newFilterParams}`;
  };

  const handleFilterChange = (payload: FilterUpdate | BoxFilterPayload, isBoxFilter?: boolean) => {
    const filterStateCopy = _cloneDeep(filters);
    if (isBoxFilter) {
      const newFilters = {
        ...initialFilters,
        ...payload
      };
      // Updating the URL is what currently drives changes in our dispute table; we set our filter state based on it
      history.push(generateFilterParamsForUrl(newFilters));
      setFilters(newFilters);
    } else {
      const updatedFiltersFromModal = payload as Filters;
      history.push(generateFilterParamsForUrl(updatedFiltersFromModal));
      setFilters({
        ...filterStateCopy,
        ...updatedFiltersFromModal
      });
    }
  };

  const generateSearchParams = (searchObj: SearchObject) => {
    const params = searchObj.search.trim();
    const { searchParams, additionalParams } = app.queryParams;
    const { pathname } = location;
    const newSearchParams = `&search=${params}`;
    return `${pathname}${searchParams}${additionalParams}${newSearchParams}`;
  };

  const handleSearchSubmission = (searchString: string) => {
    if (!isSubmitting) {
      history.push(generateSearchParams({ search: searchString }));
    }
  };

  const handleDisputeSubmission = () => {
    submitDisputes(selectedDisputes);
  };

  // On closing the modal, we reset our cache and then invalidate our query keys to refetch the data again.
  const handleClose = () => {
    reset();
    queryClient.invalidateQueries({ queryKey: ['fetchDisputes'] });
    setSubmissionModalOpen(false);
  };

  return (
    <>
      <CommonSummaryInfo widget={SectionTitleWidget} />
      <BoxFilterControls handleFilterChange={handleFilterChange} location={location} disabled={isSubmitting} />
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
        <FilterModalButton handleClick={() => setFilterModalOpen(!filterModalOpen)} disabled={isSubmitting} />
        <Search style={{ width: '40%' }} handleSearchChange={(selection) => handleSearchSubmission(selection as any)} />
        <Tooltip
          placement="top-end"
          title={selectedDisputes.length > 0 ? 'Ready to Submit' : 'Please select a dispute'}
          arrow
        >
          <div>
            <AppButton
              onClick={() => handleDisputeSubmission()}
              invertStyle
              disabled={!(selectedDisputes.length > 0) || isSubmitting}
              sx={{
                position: 'initial',
                backgroundColor: colors.darkBlue,
                borderRadius: '20px',
                top: '50px',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingTop: '6px',
                paddingBottom: '6px',
                width: '167px',
                height: '30px',
                fontSize: 'small',
                whiteSpace: 'nowrap',
                fontWeight: '400'
              }}
            >
              Submit Disputes
            </AppButton>
          </div>
        </Tooltip>
      </div>

      <DisputeDetailsTable
        rawData={data}
        isLoading={isLoading}
        setSelectedDisputes={setSelectedDisputes}
        isSubmitting={isSubmitting}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        maxPageSize={maxPageSize}
      />
      <FilterModal
        filters={filters}
        handleFilterChange={handleFilterChange}
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(!filterModalOpen)}
      />
      <SubmissionModal open={submissionError || !!submissionResponse || submissionModalOpen} onClose={handleClose} />
    </>
  );
};

export default withRouter(withBus('eventBus')(DisputeManagementDashboard));
