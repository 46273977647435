import _memoize from 'lodash/memoize';
import _startCase from 'lodash/startCase';
import _capitalize from 'lodash/capitalize';
import numeral from 'numeral';

import { panic } from 'src/utils/mixpanel';
import ReduxStore from 'src/types/store/reduxStore';
import { customLocaleString } from 'src/utils/metrics';

/**
 * Wraps a string in quotation marks (") and returns it
 */
export const quoteWrap = (x: string | number) => `"${x}"`;

/**
 * Truncates the provided string to the specified number of characters, adding an ellipsis at the end if it is
 * actually truncated.
 */
export const truncateWithEllipsis = (maxLength: number, s = '', ellipsis = '…') => {
  const sliced = s.slice(0, maxLength);
  return sliced.length < s.length ? `${sliced}${ellipsis}` : sliced;
};

/**
 * Returns the retailer `displayName` for the given `retailerId`
 */
export const getRetailerIdDisplayName = (retailerId: string, retailer: ReduxStore['retailer']) => {
  const retailers = retailer.availableRetailers;
  const retailerObj = retailers.find((r) => r.id === retailerId);
  if (retailerObj) {
    return retailerObj.displayName;
  }

  // Default, return retailer Id if no match found
  return retailerId;
};

export const buildShortName = (value: string) =>
  value.split(' ').reduce((acc, val) => acc + val.charAt(0).toUpperCase(), '');

export const toSnakeCase = (s: string) =>
  s
    .split('')
    .reduce(
      ({ capitalizeNext, acc }, char) => {
        if (char === '-') {
          // capitalize the next letter
          return { capitalizeNext: true, acc };
        } else if (capitalizeNext) {
          return { capitalizeNext: false, acc: [...acc, char.toUpperCase()] };
        }
        return { capitalizeNext: false, acc: [...acc, char] };
      },
      { capitalizeNext: false, acc: [] as string[] }
    )
    .acc.join('');

/**
 * Adds a space between lowercase and capital letters to un-truncate the camelCase string
 * e.g. 'camelCase' => 'camel Case'
 */
export const splitCamelCase = (s: string) => s.replace(/([a-z])([A-Z])/g, '$1 $2');

/**
 * Replaces _ with a space to un-truncate the snakeCase string
 * e.g. 'snake_case' => 'snake case'
 */
export const splitSnakeCase = (s: string) => s.replace(/_/g, ' ');

export const mkNumeralFormatter = _memoize(
  (formatString: string) => (value: number | string) => numeral(value).format(formatString)
);

export const formatMoney = (amount: number | string, currencySymbol?: string) => {
  if (currencySymbol) {
    return currencySymbol + numeral(amount).format(`1,000`);
  }
  return numeral(amount).format(`$1,000`);
};

// Based off of implementation from here:
// https://ourcodeworld.com/articles/read/188/encode-and-decode-html-entities-using-pure-javascript
export const unescapeHtmlEntities = (raw?: string) =>
  (raw || '').replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));

export const registerCustomNumeralFormatters = () => {
  // registers a custom formatter that displays units with magnitude units (K, M, B, etc.) a single decimal if they're
  // above 1000 and displays them directly without any decimals otherwise
  numeral.register('format', '√', {
    regexps: {
      format: /(√)/,
      unformat: /^\b$/ // Regex that can never match
    },
    format: (value: any, _format: string, _roundingFunction): string =>
      numeral(value).format(Math.abs(value) >= 1000 ? '1,000.0a' : '1'),
    unformat: () => panic('Unimplemented')
  });
};

export const getPluralVerb = (value: any, singular: string, plural: string) => {
  if (value === 1) {
    return singular;
  }
  return plural;
};

export const formatPromoType = (str: string) => {
  return _startCase(_capitalize(splitSnakeCase(str))).replace('Of The', 'of the');
};

// We were supposed to store same value for any countries(locales), and mask it using the value.
// however the app is architected modifying actual value based on locales.
// so we are using customLocalString to convert data...

export const formatDecimalNumber = (str: string) => {
  // numeral data should be called here, if you put this globally it will be memoized.

  const { decimal, thousands } = numeral.localeData().delimiters;
  if (str === '-') {
    return str;
  }

  if (str !== '' && str[str.length - 1] === decimal) {
    return `${numeral(str).format('0,0')}${decimal}`;
  } else {
    return str === '' ? '' : customLocaleString(str, thousands, decimal);
  }
};

export const formatDecimalNumberWithoutNeg = (str: string) => {
  const { decimal, thousands } = numeral.localeData().delimiters;
  if (str === '-') {
    return '';
  }

  if (str !== '' && str[str.length - 1] === decimal) {
    return `${numeral(str).format('0,0')}${decimal}`;
  } else {
    if (str === '') {
      return '';
    } else {
      return customLocaleString(str, thousands, decimal);
    }
  }
};

export const normalFormattingForFilter = (str: string) => {
  if (Number.isInteger(str)) {
    return str === '' ? '' : numeral(str).format(`1,000`);
  }
  return str;
};

export const formatIntegerWithoutNeg = (str: string) => {
  if (str === '-') {
    return str;
  }
  return str === '' ? '' : numeral(str).format(`0,0`);
};

export const numeralFormattingForNumbersWithOutDecimal = (str: string) => {
  if (Number.isInteger(str)) {
    return str === '' ? '' : numeral(parseInt(str, 10)).format(`0,0`);
  }
  return str;
};

export const numeralFormattingForNumbersWithoutNeg = (str: string) => {
  return str === '' ? '' : numeral(parseInt(str, 10)).format(`1,000`);
};

export const numeralFormattingForNumbersWithoutNegWithDollar = (str: string) => {
  return str === '' ? '' : numeral(str).format('($0,0)');
};

export const numeralFormattingWithoutNegWithDec = (str: string) => {
  return str;
};

export const formatMoneyString = (amount: number | string) => {
  return amount === '' ? `$${amount}` : numeral(amount).format(`$1,000`);
};

/**
 * Takes a string like "14.8M" and returns an object with the value and label.
 */
export const getVolumeStringParts = (volume: string) => {
  /**
   * Group 1: Currency sign. \D* because some currency signs are multiple characters,
   *  like Brazil is R$
   * Group 2: Value. Works for decimals separated by . or ,
   * Group 3: Label. For example, "K" or "M" for numbers formatted like "14.3M"
   */
  const match = volume.match(/(\D*)(\d+[.,]?\d*)([A-Z])?/);
  if (!match) {
    return { value: volume, label: '' };
  }
  const currencySign = match[1];
  const value = match[2];
  const label = match[3] || '';
  return { value, label, currencySign };
};
