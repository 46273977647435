import React, { useState, useMemo, useEffect, useCallback } from 'react';
import Tabs from 'src/components/common/Tabs/Tabs';
import Box from '@mui/material/Box';
import './ConnectPage.scss';
import AdvertisingOverlapVisualization from './visualizations/AdvertisingOverlap/AdvertisingOverlapVisualization';
import DspAdFrequencyVisualization from './visualizations/DspAdFrequency/DspAdFrequencyVisualization';
import NewToBrandVisualization from './visualizations/NewToBrand/NewToBrandVisualization';
import AdvertisingAnalyticsVisualization from './visualizations/AdvertisingAnalytics/AdvertisingAnalyticsVisualization';
import { Widget } from 'src/types/application/widgetTypes';
import { timePeriodOptions } from 'src/store/modules/main-time-period/reducers';
import { withRouter } from 'react-router';
import { History } from 'history';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  AmcRegions,
  AmcAudiences,
  AmcCustomize,
  AmcTouchpoints,
  AmcPathways,
  AmcNewToBrand
} from 'src/components/SvgIcons';
import VisualizationPreview from './components/VisualizationPreview';
import { CustomDateOption, useAppSelector, useUpdateTimePeriodDropdown } from 'src/utils/Hooks';
import moment from 'moment';
import ConversionPathwaysVisualization from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/ConversionPathways/ConversionPathwaysVisualization';
import TimeToConversionVisualization from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/TimeToConversion/TimeToConversionVisualization';
import FirstTouchAttributionVisualization from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/FirstTouchAttribution/FirstTouchAttributionVisualization';
import PurchasedTogetherVisualization from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/FrequentlyPurchasedTogether/PurchasedTogetherVisualization';
import NewToBrandOverTimeVisualization from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/NewToBrandTab/NewToBrandOverTime/NewToBrandOverTimeVisualization';
import NewToBrandProductVisualization from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/NewToBrandTab/NewToBrandProducts/NewToBrandProductVisualization';
import { shouldShowPhaseTwoTabs } from 'src/utils/app';
import _get from 'lodash/get';

interface ConnectPageProps {
  /**
   * `history` from withRouter HOC from React Router.
   * Unfortunately, React Router v4 doesn't export
   * a type for `history`.
   */
  history: History;
}

/**
 * There is a different set of time period dropdown options in the
 * header for the Connect dashboard. Keep this outside of the component
 * so it doesn't trigger an infinite render in the useUpdateTimePeriodDropdown
 * hook
 */
const timePeriodDropdownOptions = [
  timePeriodOptions.last2Weeks,
  timePeriodOptions.lastMonth,
  timePeriodOptions.last3Months,
  timePeriodOptions.select1Month,
  timePeriodOptions.select3Months
];

const queryClient = new QueryClient();
interface ConnectPageProps {
  widget: Widget;
}

interface ConnectTab {
  /**
   * String label for a tab
   */
  displayName?: string;
  /**
   * JSX template for a tab label
   */
  displayTemplate?: React.ReactNode;
  /**
   * Element that should be displayed when
   * tab is selected
   */
  element: React.ReactNode;
  /**
   * The value that will be stored in the URL to preserve
   * the tab on page reload
   */
  value: string;
}

/**
 * Factory function for creating tabs for the
 * AMC dashboard
 */
const createDashboardTabs = (widget: Widget, showPhaseTwoTabs: boolean): ConnectTab[] => [
  {
    displayName: 'Summary',
    value: 'summary',
    element: (
      <>
        <Box marginTop="104px">
          <AdvertisingOverlapVisualization widget={widget} />
        </Box>
        <Box marginTop="109px">
          <AdvertisingAnalyticsVisualization />
        </Box>
        <Box marginTop="158px">
          <DspAdFrequencyVisualization />
        </Box>
        <Box marginTop="178px">
          <NewToBrandVisualization />
        </Box>
      </>
    )
  },
  {
    displayName: 'Pathways',
    value: 'pathways',
    element: (
      <>
        {showPhaseTwoTabs ? (
          <>
            <Box marginTop="104px">
              <ConversionPathwaysVisualization />
            </Box>
            <Box marginTop="75px">
              <TimeToConversionVisualization />
            </Box>
            <Box marginTop="128px">
              <FirstTouchAttributionVisualization />
            </Box>
            <Box marginTop="128px">
              <PurchasedTogetherVisualization />
            </Box>
          </>
        ) : (
          <VisualizationPreview
            Icon={AmcPathways}
            title="Pathways"
            description="Understand your customer's shopping journey, from awareness to purchase."
          />
        )}
      </>
    )
  },
  {
    displayName: 'New-to-Brand',
    value: 'newToBrand',
    element: (
      <>
        {showPhaseTwoTabs ? (
          <>
            <Box marginTop="104px">
              <NewToBrandOverTimeVisualization />
            </Box>
            <Box marginTop="75px">
              <NewToBrandVisualization renderVisualizationInsightText={false} />
            </Box>
            <Box marginTop="128px">
              <NewToBrandProductVisualization />
            </Box>
          </>
        ) : (
          <VisualizationPreview
            Icon={AmcNewToBrand}
            title="New-to-Brand"
            description="Understand which aspects of your advertising are driving new-to-brand behavior."
          />
        )}
      </>
    )
  },
  {
    displayName: 'Regions',
    value: 'regions',
    element: (
      <VisualizationPreview
        Icon={AmcRegions}
        title="Regions"
        description="Leverage geographical data to drive improved advertising performance."
      />
    )
  },
  {
    displayName: 'Audiences',
    value: 'audiences',
    element: (
      <VisualizationPreview
        Icon={AmcAudiences}
        title="Audiences"
        description="Identify and reach your brand's most valuable customers."
      />
    )
  },

  {
    displayName: 'Loyalty',
    value: 'loyalty',
    element: (
      <VisualizationPreview
        title="Loyalty"
        description="Identify the aspects of your advertising that are driving shopper loyalty."
        Icon={AmcTouchpoints}
      />
    )
  },
  {
    value: 'custom',
    displayName: 'Custom',
    element: (
      <VisualizationPreview
        Icon={AmcCustomize}
        title="Customize your dashboard"
        description="Use custom dashboards to analyze advertising performance across brands, product lines and strategies."
        iconHeight={30}
      />
    )
  }
];

const customDateOptions: CustomDateOption[] = [
  {
    id: 'select1Month',
    defaultStartDate: moment().subtract(1, 'month').startOf('month').toDate(),
    defaultEndDate: moment().subtract(1, 'month').endOf('month').toDate()
  },
  {
    id: 'select3Months',
    defaultStartDate: moment().subtract(3, 'months').startOf('month').toDate(),
    defaultEndDate: moment().subtract(1, 'month').endOf('month').toDate()
  }
];

/**
 * Landing page for the Connect dashboard. It contains
 * all the tabs for each dashboard of visualizations.
 */
export const ConnectPageInner = ({ history, widget }: ConnectPageProps) => {
  const [tabIndex, setTabIndex] = useState(0);

  /**
   * Split logic to hide AMC Phase Two tabs in Prod
   */
  const userEmail = useAppSelector((state) => _get(state, ['user', 'config', 'profile', 'email'], ''));
  const appStage = useAppSelector((state) => state.app.stage);

  const showPhaseTwoTabs = shouldShowPhaseTwoTabs(userEmail, appStage);

  const tabs: ConnectTab[] = useMemo(() => createDashboardTabs(widget, showPhaseTwoTabs), [widget]);

  useUpdateTimePeriodDropdown({
    history,
    timePeriods: timePeriodDropdownOptions,
    defaultTimePeriod: timePeriodOptions.last2Weeks,
    customDateOptions
  });
  const { searchParams } = useAppSelector((state) => state.app.queryParams);
  const secondaryRetailerIds = useAppSelector((state) => state.user.config.secondaryRetailerIds);
  const retailerId = useAppSelector((state) => state.retailer.id);

  const redirectHome = useCallback(() => {
    const params = new URLSearchParams(searchParams);

    // Remove wr so that the URL will reflect the default time period
    // instead of what the AMC dashboard overrides
    params.delete('wr');
    history.replace(`/overview?${params.toString()}`);
  }, [history, searchParams]);

  /**
   * If the currently selected retailer is not enabled for AMC, redirect home
   */
  useEffect(() => {
    if (secondaryRetailerIds && !secondaryRetailerIds.includes(+retailerId)) {
      redirectHome();
    }
  }, [redirectHome, secondaryRetailerIds, retailerId]);

  return (
    <QueryClientProvider client={queryClient}>
      {/** Devtools for React query. Automatically not included in production builds. */}
      <ReactQueryDevtools initialIsOpen={false} />
      <Box
        sx={{
          width: '100%',
          maxWidth: '1340px',
          paddingTop: '64px'
        }}
        className="amc-dashboard"
      >
        <Tabs
          value={tabIndex}
          tabs={tabs}
          onTabChange={(_event, newTab) => {
            setTabIndex(newTab);
          }}
          tabStyle={(_tab, _idx, isSelected) => ({
            fontWeight: isSelected ? 500 : 'normal',
            fontSize: '20px',
            marginRight: '80px'
          })}
        />
        {tabs[tabIndex].element}
      </Box>
    </QueryClientProvider>
  );
};

export default withRouter(ConnectPageInner);
