import React from 'react';
import _chunk from 'lodash/chunk';
import { SlColumn, SlRow } from '@stackline/ui';
import ProductCardLoading from 'src/components/BeaconRedesignComponents/ProductCard/ProductCardLoading';

const ITEMS_PER_PAGE = 20;

/**
 * Displays a grid of loading product cards
 */
const ProductGridLoading = () => {
  return (
    <SlColumn spacing="cardGrid" widths="full">
      {_chunk(
        Array.from({ length: ITEMS_PER_PAGE }, (_, i) => i).map((i) => <ProductCardLoading key={i} />),
        4
      ).map((items) => (
        <SlRow key={items.map((i) => i.key).join('')} spacing="cardGrid">
          {items.map((item) => item)}
        </SlRow>
      ))}
    </SlColumn>
  );
};

export default ProductGridLoading;
