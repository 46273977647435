import React from 'react';

import './AutomatedBadge.scss';

const AutomatedBadge = ({ style, isAutomated = true }: { style?: React.CSSProperties; isAutomated?: boolean }) => (
  <span className={`automated-badge ${isAutomated ? 'automated' : 'not-automated'}`} style={style}>
    {isAutomated ? 'Automated' : 'Manual'}
  </span>
);

export default AutomatedBadge;
