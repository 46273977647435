// ------------------------------------
// Reducer
// ------------------------------------

import types from './types';
import parseAllWeekIds from './selectors';

// initialState only for testing purposes
// const initialState = { 1: [201601, 201602] };

export default function updateAllWeekIdsByRetailerIdReducer(state = {}, action) {
  switch (action.type) {
    case types.UPDATE_WEEK_IDS:
      return Object.assign({}, state, parseAllWeekIds(action.weekIds));
    case types.CLEAR_WEEK_IDS:
      return {};
    default:
      return state;
  }
}
