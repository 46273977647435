import { useMemo } from 'react';
import { useAdvancedSearchRequestBuilder } from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import { useAppSelector } from 'src/utils/Hooks';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';

export const REVIEWS_PAGE_SIZE = 108; // Multiple of 12 since we show 12 cards per page
export const useReviewTrendsReviewGridData = (pageNumber: number, starFilter = ['1', '2', '3', '4', '5']) => {
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);

  const { startWeek, endWeek } = mainTimePeriod;
  const categoryIds = useAppSelector((state) => state.entityService.mainEntity.categoryIds);

  const reviewDocumentsRequestId = 'reviewsGridDocuments-product--1';

  const reviewDocumentsRequestBuilder = useAdvancedSearchRequestBuilder(reviewDocumentsRequestId, 'beacon-reviews');

  const reviewDocumentsRequest = useMemo(() => {
    reviewDocumentsRequestBuilder
      .setPageNumber(pageNumber)
      // set page size can be divide by 3 (3 cards in a row)
      .setPageSize(REVIEWS_PAGE_SIZE)
      .setPeriod('year')
      .setDoAggregation(false)
      .setReturnDocuments(true)
      .addConditionTermFilter('categoryId', 'should', categoryIds)
      .addConditionTermFilter('reviewText', 'match', [''])
      .addConditionTermFilter('stars', 'match', starFilter)
      .addConditionTermFilter('categoryId', 'should', categoryIds)
      .addConditionRangeFilter('weekId', startWeek, endWeek)
      .setSearchBy('child')
      .addSortFieldWithOnlyFieldName('timestamp', 'desc')
      .addSortFieldWithOnlyFieldName('stars', 'desc');

    if (mainEntity.type === 'product') {
      reviewDocumentsRequestBuilder.addConditionTermFilter('stacklineSku', 'should', [mainEntity.id]);
    } else if (mainEntity.type === 'brand') {
      reviewDocumentsRequestBuilder.addConditionTermFilter('brandId', 'should', [mainEntity.id]);
    } else if (mainEntity.type === 'subcategory') {
      reviewDocumentsRequestBuilder.addConditionTermFilter('subCategoryId', 'should', [mainEntity.id]);
    } else if (mainEntity.type === 'category') {
      reviewDocumentsRequestBuilder.addConditionTermFilter('categoryId', 'should', [mainEntity.id]);
    }

    return reviewDocumentsRequestBuilder.build();
  }, [
    categoryIds,
    endWeek,
    mainEntity.id,
    mainEntity.type,
    starFilter,
    pageNumber,
    reviewDocumentsRequestBuilder,
    startWeek
  ]);

  const { data, isLoading } = useGenericAdvancedSearch({
    requestId: reviewDocumentsRequestId,
    queryKeys: [reviewDocumentsRequest],
    requestBody: [reviewDocumentsRequest]
  });

  const reviewDocumentData = useMemo(() => {
    return data ? data.data[0].documents : [];
  }, [data]);

  return { reviewDocumentData, isLoading };
};
