import {
  AsinDocument,
  NewToBrandProductDocument,
  NewToBrandProductsAdvancedSearchResponse,
  ProductGridRow
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/NewToBrandTab/NewToBrandProducts/types';
import _get from 'lodash/get';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { getProductImageUrl } from 'src/utils/image';
import ReduxStore from 'src/types/store/reduxStore';

/**
 * Parses advanced search response from NTB Product Grid API request
 * and formats it to be used by CustomAgMaterial/AG-Grid.
 */
export const NTBProductGridAdvancedSearchParser = (
  response: NewToBrandProductsAdvancedSearchResponse,
  formatMetric
) => {
  const newToBrandAsinData: AsinDocument[] = _get(response, ['aggregations', 'by_trackedAsin.keyword'], []);
  const productData: NewToBrandProductDocument[] = _get(response, 'additionalResponseMetaData.productData', []);

  /**
   * Takes the Asin data and product data from the API response and modifies it to work with the EntityColumn component
   * rather than have it be mutated somewhere in the widget logic chain.
   */
  const productRows = newToBrandAsinData.map((asinDocument, index) => {
    // Find the matching product data
    const product = productData.find((productItem) => productItem.retailerSku === asinDocument.fieldId);

    if (product) {
      const modifiedEntity = {
        ...product,
        name: product.title,
        keyFieldName: 'stacklineSku',
        type: 'product',
        displayName: 'Product',
        displayNamePlural: 'Products',
        id: product.stacklineSku,
        imageUrl: () => getProductImageUrl(product.stacklineSku)
      };

      // Calculate metrics needed for each column
      const adSales = _get(asinDocument, 'additionalValues.adSales_sum_value', 1);
      const newToBrandAdSales = _get(asinDocument, 'additionalValues.newToBrandAdSales_sum_value', 0);
      const percentNewToBrandAdSales = newToBrandAdSales / adSales;

      // Format according to metric type
      const formattedAdSales = formatMetric(adSales, METRICTYPE.MONEY, { showFullValue: true, decimals: 0 });
      const formattedNewToBrandAdSales = formatMetric(newToBrandAdSales, METRICTYPE.MONEY, {
        showFullValue: true,
        decimals: 0
      });
      const formattedPercentNewToBrandAdSales = formatMetric(percentNewToBrandAdSales, METRICTYPE.PERCENT, {
        showFullValue: true,
        decimals: 2
      });

      return {
        name: product.title,
        id: product.stacklineSku,
        i: index,
        adSales, // Raw Ad Sales
        formattedAdSales, // Formatted version for display
        newToBrandAdSales,
        formattedNewToBrandAdSales,
        percentNewToBrandAdSales,
        formattedPercentNewToBrandAdSales,
        entity: modifiedEntity
      };
    }
    return null;
  });
  // Default sort by New to Brand Ad Sales
  return productRows
    .filter((value) => value !== null)
    .sort((productA, productB) => productB.newToBrandAdSales - productA.newToBrandAdSales);
};

interface BuildRequestForNTBProductGridParameters {
  startDayId: string | number;
  endDayId: string | number;
  retailerId?: string | number;
}

export const buildRequestForNTBProductGrid = ({
  startDayId,
  endDayId,
  retailerId = 1
}: BuildRequestForNTBProductGridParameters) => {
  return [
    {
      name: 'ntb_product_grid',
      id: 'ntb_product_grid',
      pageNumber: 1,
      pageSize: 50,
      doAggregation: true,
      returnDocuments: false,
      conditions: {
        condition: 'must',
        termFilters: [
          {
            fieldName: 'periodStartDate',
            condition: 'should',
            values: [startDayId]
          },
          {
            fieldName: 'periodEndDate',
            condition: 'should',
            values: [endDayId]
          },
          {
            fieldName: 'retailerId',
            condition: 'should',
            values: [retailerId]
          }
        ],
        computeFilters: []
      },
      searchBy: 'parent',
      aggregations: [
        {
          aggregationFields: [
            {
              aggregateByFieldName: 'beaconClientId',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'adSales',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'newToBrandAdSales',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'adUnitsSold',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'orders',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'newToBrandUniqueShoppersThatPurchased',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'uniqueShoppersThatPurchased',
              function: 'sum',
              canBeExported: true
            }
          ],
          groupByFieldName: 'trackedAsin.keyword',
          sortByAggregationField: {
            aggregateByFieldName: 'newToBrandAdSales',
            function: 'sum'
          },
          sortDirection: 'desc',
          pageSize: 1000
        }
      ],
      searchType: 'advertising-amc-new-to-brand-products',
      processDocuments: false
    }
  ];
};

interface ConvertProductGridDataToCsv {
  rowData: ProductGridRow[];
  dateRange: ReduxStore['mainTimePeriod']['displayName'];
}

/**
 *
 * @param rowData A grid row containing the data we want to export
 * @param dateRange A formatted string for the selected date range
 * @returns A stringified version of our data with line breaks
 */
export const convertProductGridDataToCsv = ({ rowData, dateRange }: ConvertProductGridDataToCsv) => {
  const date = `"${dateRange}",`;
  const headers = ['Products', 'Ad Sales', 'New to Brand Ad Sales', '% of Ad Sales New to Brand']
    .map((val) => `"${val}"`)
    .join(',');

  const rowValues = rowData.map((row) => {
    const productName = `"${row.name}"`;
    return `${productName},${row.adSales},${row.newToBrandAdSales},${row.percentNewToBrandAdSales}`;
  });

  return `${date}\n${headers}\n${rowValues.join('\n')}`;
};
