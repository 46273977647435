import React from 'react';
import Typography from '@mui/material/Typography';
import colors from 'src/utils/colors';

export interface VisualizationInsightProps {
  /**
   * Text content to show inside the bullet point. It should
   * be a React Node instead of just a string in case the text
   * needs to be specially formatted.
   */
  children: React.ReactNode;
}

/**
 * Displays an insight for a visualization with a bullet point
 */
const VisualizationInsight = ({ children }: VisualizationInsightProps) => {
  return (
    <Typography
      position="relative"
      zIndex={1}
      fontSize={16}
      minHeight={40}
      color={colors.darkBlue}
      lineHeight={1.31}
      maxWidth={1280}
    >
      {children}
    </Typography>
  );
};

export default VisualizationInsight;
