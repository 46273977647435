import React, { HTMLProps } from 'react';
import { styled } from '@mui/material';
import { StacklineTextVariant, Text, useStacklineTheme } from '@stackline/ui';
import { ChevronLeft } from '@mui/icons-material';

const UnstyledButton = styled('button')({
  display: 'flex',
  alignItems: 'center',
  border: 'none',
  background: 'none',
  padding: '0'
});

interface GenericBackButtonProps extends HTMLProps<HTMLButtonElement> {
  text?: string;
  variant?: StacklineTextVariant;
}
export const GenericBackButton = ({ text = '', variant = 'subtitle2', ...rest }: GenericBackButtonProps) => {
  const theme = useStacklineTheme();

  return (
    <UnstyledButton {...rest}>
      <ChevronLeft style={{ fill: theme.colors.primary }} />
      <Text variant={variant}>{text}</Text>
    </UnstyledButton>
  );
};
