import React, { FC, useEffect, useState } from 'react';
import { Avatar, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StacklineTheme, useStacklineTheme } from '@stackline/ui';
import { useAppSelector, useUserProfileImage } from 'src/utils/Hooks';
import _get from 'lodash/get';

export interface AppProfileIconProps {
  userId?: string;
  size?: number;
  initials?: string;
  fontSize?: string;
  shouldShowNotificationBadge?: boolean;
  backgroundColor?: string;
  imageSrc?: string;
}

const StyledAvatar = styled(Badge)((props: { stacklineTheme: StacklineTheme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: props.stacklineTheme.colors.primary,
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    transform: 'translate(7px, -5px)'
  }
}));

/**
 * - renders user profile icon, defaulted to table icon width & height
 * - displays placeholder icon for no imageUrl
 */
const AppProfileIcon: FC<AppProfileIconProps> = ({
  size = 30,
  shouldShowNotificationBadge = false,
  userId,
  initials,
  fontSize,
  backgroundColor,
  imageSrc
}) => {
  const theme = useStacklineTheme();
  const user = useAppSelector((state) => state.user);
  const { firstName, lastName } = user.config;
  const userInitials = initials || `${_get(firstName, 0, '')}${_get(lastName, 0, '')}`;

  const userProfileImage = useUserProfileImage(userId);

  const [imageLoaded, setImageLoaded] = useState(false);

  const defaultBackgroundColor = backgroundColor || theme.colors.primary;

  useEffect(() => {
    const img = new Image();
    img.src = imageSrc || userProfileImage;
    img.onload = () => setImageLoaded(true);
  }, [imageSrc, userProfileImage]);

  return (
    <>
      <StyledAvatar
        stacklineTheme={theme}
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        variant={shouldShowNotificationBadge ? 'dot' : 'standard'}
      >
        <Avatar
          sx={{
            width: size,
            height: size,
            bgcolor: imageLoaded ? 'transparent' : defaultBackgroundColor,
            fontSize: fontSize || theme.text.variants.subtitle2.fontSize
          }}
          src={imageSrc || userProfileImage}
        >
          {`${_get(userInitials, 0, '').toUpperCase()}${_get(userInitials, 1, '').toUpperCase()}`}
        </Avatar>
      </StyledAvatar>
    </>
  );
};
export default React.memo(AppProfileIcon);
