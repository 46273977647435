import types from './types';

interface OmniSegmentEntity {
  queryId: string;
  name: string;
  canEdit: boolean;
  query: { [key: string]: string };
  ownerProfile: {
    firstName: string;
    lastName: string;
    [key: string]: any;
  };
}

export interface OmniSegmentListState {
  data: OmniSegmentEntity[];
  isFetching: boolean;
}

const omniSegmentListService = (
  state: OmniSegmentListState = {
    data: [],
    isFetching: true
  },
  action: { type: string; entity: { data: any[] } }
) => {
  switch (action.type) {
    case types.START_FETCH:
      return {
        data: [],
        isFetching: true
      };

    case types.RECEIVE_OMNI_SEGMENT_DATA:
      return {
        isFetching: false,
        data: action.entity.data
      };
    default:
      return state;
  }
};

export default omniSegmentListService;
