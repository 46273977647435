/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import { Entity } from 'sl-api-connector';
import _orderBy from 'lodash/orderBy';
import { Option } from 'funfix-core';

import colors from 'src/utils/colors';
import { store } from 'src/main';
import { buildAggregations } from 'src/components/AdManager/Search';
import ColumnChartContainer from 'src/components/Charts/Column';
import { warn } from 'src/utils/mixpanel';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { WidgetProps } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import { MetricField } from 'src/types/application/types';
import { prop } from 'src/utils/fp';

const DATA_KEY = 'contentScoreColumnChart';

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['app', 'mainTimePeriod', 'retailer', 'entityService']),
  dataSet: state.entitySearchService[DATA_KEY]
});

type ContentScoreColumnChartProps = { entity: Entity } & WidgetProps & ReturnType<typeof mapStateToProps>;

const fetchData = ({
  queryConditions,
  app,
  entityService: { mainEntity },
  retailer,
  widget
}: ContentScoreColumnChartProps) => {
  if (!mainEntity) {
    return;
  }

  const indexName = 'content';
  const groupByFieldName = 'weekId';

  const [{ aggregations: aggregationFields }] = buildAggregations(widget.data.aggregationFields);

  store.dispatch(
    fetchEntityMetrics(
      DATA_KEY,
      {
        entity: mainEntity,
        retailer,
        app,
        indexName
      },
      [
        {
          doAggregation: true,
          aggregations: [
            {
              aggregationFields,
              conditions: {
                termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id, 10)] }],
                rangeFilters: []
              },
              groupByFieldName
            }
          ],
          conditions: queryConditions,
          pageSize: 1000,
          processDocuments: true
        }
      ]
    )
  );
};

const ContentScoreColumnChart: React.FC<ContentScoreColumnChartProps> = (props) => {
  const { widget, entityService, queryConditions, mainTimePeriod, retailer, dataSet, entity } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchData(props), [queryConditions, entityService.mainEntity, retailer]);

  const getColumnChartSeries = (): any => {
    const seriesData = widget.data.aggregationFields
      .filter((field: MetricField) => !!dataSet[`${field.name}_by_weekId`])
      .map((field: MetricField) => {
        const dataPoints = dataSet[`${field.name}_by_weekId`].data;
        const valuesForMainTimePeriod = dataPoints.filter(
          ({ weekId }: { weekId: number }) => weekId >= mainTimePeriod.startWeek && weekId <= mainTimePeriod.endWeek
        );
        const score = Option.of(
          _orderBy(
            valuesForMainTimePeriod.filter(({ count }) => count > 0),
            [prop('weekId')],
            ['desc']
          )
        )
          .flatMap((points) => Option.of(points[0]))
          .map(prop('value'))
          .getOrElse(0);

        return {
          fieldId: field.name,
          name: field.displayName,
          value: score,
          entity,
          color: colors.stacklineBlue
        };
      });

    const aggregationName = `${widget.data.aggregationFields[0].name}_by_weekId`;
    const aggregationMetrics: any = dataSet[aggregationName];
    if (!aggregationMetrics) {
      warn('`aggregationMetrics` are nil in `ContentScoreColumnChart`; would have crashed');
      return null;
    }

    return {
      entity,
      data: seriesData,
      groupByField: widget.data.groupByField,
      metricType: aggregationMetrics.metricType,
      currencySymbol: aggregationMetrics.currencySymbol,
      dataType: aggregationMetrics.dataType
    };
  };

  if (!dataSet) {
    return <GenericChartLoading />;
  }

  const columnChartSeries = getColumnChartSeries();
  if (!columnChartSeries) {
    return null;
  }

  return (
    <div>
      <ColumnChartContainer
        mainEntityMetrics={columnChartSeries}
        widget={widget}
        chartDisplayTimePeriod={mainTimePeriod}
        retailer={retailer}
        labelRotation={0}
      />
    </div>
  );
};

export default connect(mapStateToProps)(ContentScoreColumnChart);
