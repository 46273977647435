import React, { useState } from 'react';
import { Box } from '@mui/system';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import { BEACON_KEY_METRIC_CARD_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { useAppSelector } from 'src/utils/Hooks';
import { EntityLink } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';
import { getBrandImageUrl } from 'src/utils/image';
import { BeaconProImageFallback } from 'src/components/SvgIcons';

enum DropdownOption {
  Keyword = 'KeywordText',
  Brand = 'brandId'
}

interface Brand {
  id: string;
  brandId: number;
  brandName: string;
  name: string;
  parentBrandId: null;
  parentBrandName: null;
  retailerIds: string[];
  categoryIds: string[];
  categoryIdsProductCount: Record<string, unknown>;
  brandNameSuggestion: null;
  searchScoreByCategory: Record<string, unknown>;
  searchScore: number;
}

interface Unauthorized {
  value: number;
  difference: number;
}

interface Metadata {
  brandId: string;
  brand: Brand;
  name: string;
}

interface ResponseRow {
  unauthorized_sum_value: number;
  unauthorized_sum_count: number;
  metadata: Metadata;
}

interface BrandRow {
  brandId: string;
  brand: Brand;
  name: string;
  unauthorized: Unauthorized;
  responseRow: ResponseRow;
}

// TODO: Replace BrandIcon with new placeholder when mocks are done
const BrandImage = ({ row }: { row: BrandRow }) => {
  const [imageError, setImageError] = useState(false);
  if (imageError) {
    return (
      <BeaconProImageFallback style={{ width: '30px', height: '30px', marginRight: '12px', objectFit: 'cover' }} />
    );
  }

  return (
    <img
      onError={() => setImageError(true)}
      src={getBrandImageUrl(row.brand.id)}
      style={{ width: '30px', height: '30px', marginRight: '12px', objectFit: 'contain' }}
      alt={row.brand.name}
    />
  );
};

/**
 * Table definition by the selected dropdown
 */
const dropdownOptionToColumnDefinition = {
  [DropdownOption.Keyword]: {
    headerName: 'Keywords',
    tableColumnProps: {
      style: {
        paddingLeft: '24px',
        width: '890px'
      }
    },
    valueFormatter: (_, row) => (
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        <span style={{ margin: 0 }} title={row.name}>
          {row.name}
        </span>
      </div>
    )
  },
  [DropdownOption.Brand]: {
    headerName: 'Brands',
    tableColumnProps: {
      style: {
        paddingLeft: '24px',
        width: '890px'
      }
    },
    valueFormatter: (_, row: BrandRow) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <BrandImage row={row} />
        <EntityLink id={row.brand.id} entityType="brand">
          {row.brand.name}
        </EntityLink>
      </div>
    )
  }
};

/**
 * Dropdown option names
 */
const dropdownOptionToDisplayName = {
  [DropdownOption.Keyword]: 'Keyword',
  [DropdownOption.Brand]: 'Brand'
};

const CompliantAdsGrid = () => {
  const [dropdownOption, setDropdownOption] = useState<DropdownOption>(DropdownOption.Keyword);
  const entityType = useAppSelector((state) => state.entityService.mainEntity.type);

  /**
   * Dropdown options by the main entity type
   */
  const dropdownOptionsByEntityType = {
    brand: [DropdownOption.Brand]
  };

  return (
    <Box sx={{ marginTop: `${BEACON_KEY_METRIC_CARD_SPACING}px` }}>
      <MetricListContainer
        entityTableHeaderProps={{
          prefixTitle: 'Violations by',
          title: '',
          groupByFields: (
            dropdownOptionsByEntityType[entityType] || [DropdownOption.Keyword, DropdownOption.Brand]
          ).map((option) => ({
            name: option,
            displayName: dropdownOptionToDisplayName[option]
          })),
          handleGroupByChange: (event) => setDropdownOption(event.target.value as DropdownOption)
        }}
        fields={[
          {
            name: 'unauthorized',
            comparisonFormatter: () => null // Removes comparison data
          }
        ]}
        indexName={BEACON_INDEX_NAMES.COMPLIANCE_KEYWORDADITEM}
        sortFieldName="unauthorized"
        showGrid={false}
        useTableDataProps={{
          groupByFieldName: dropdownOption,
          filterResponse: () => true,
          itemsPerPage: 20
        }}
        headColumnDefinition={dropdownOptionToColumnDefinition[dropdownOption]}
      />
    </Box>
  );
};

export default CompliantAdsGrid;
