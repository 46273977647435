import { useAppSelector } from 'src/utils/Hooks';
import moment from 'moment';

export const DATE_FORMAT = 'YYYYMMDD';
export const TIMEZONE = 'America/New_York';
/**
 * We want to make sure the selected date range for AMC data
 * is valid for all populated data. AMC data is refreshed and
 * populated at the end of the week, so if a user is using
 * the dashboard at the end of the week before the data
 * is finished refreshing, they will see a blank dasboard.
 * This hook will return the soonest available date where the
 * data is available.
 *
 * TODO we will update this when we are able to poll the data
 * to see if it is finished populating, until now we can assume
 * data will be finished populating at 8 am on Sunday Eastern Time.
 */
const useAmcDateRange = () => {
  const { endDayId, startDayId, id } = useAppSelector((state) => state.mainTimePeriod);

  const currentDate = moment();
  const startDate = moment(`${startDayId}`, DATE_FORMAT).tz(TIMEZONE);
  const endDate = moment(`${endDayId}`, DATE_FORMAT).tz(TIMEZONE);

  // Saturday or Sunday before 8 am, return the previous week
  if (id === '2w' && (currentDate.day() === 6 || (currentDate.day() === 0 && currentDate.hour() < 8))) {
    return {
      startDayId: +startDate.subtract(1, 'week').format(DATE_FORMAT),
      endDayId: +endDate.subtract(1, 'week').format(DATE_FORMAT)
    };
  }

  return {
    endDayId,
    startDayId
  };
};

export default useAmcDateRange;
