// These arrays contain props that will be passed into `EnhancedTextInput` components in order to render the
// form's input elements.

import * as Validators from 'src/utils/validators';

const { mkRegexValidator } = Validators;

// Validators are functions that take a value entered into the form and return either an error message if the value
// is invalid or `undefined` if the value is valid.  They don't have to check for missing values; that logic is handled
// by the `TextInput` component if the default `required` prop is set.
const validators = {
  cardNumber: mkRegexValidator(Validators.CARD_NUMBER_VALIDATOR_REGEX, 'Please enter a valid card number'),
  cardName: mkRegexValidator(Validators.LETTER_VALIDATOR_REGEX, 'Please enter a valid card name'),
  expiration: mkRegexValidator(Validators.EXPIRATION_DATE_VALIDATOR_REGEX, 'Please enter a valid expiration date'),
  expirationMonth: mkRegexValidator(
    Validators.EXPIRATION_MONTH_VALIDATOR_REGEX,
    'Please enter a valid expiration month'
  ),
  expirationYear: mkRegexValidator(Validators.EXPIRATION_YEAR_VALIDATOR_REGEX, 'Please enter a valid expiration year'),
  cvv: mkRegexValidator(Validators.NUMBER_VALIDATOR_REGEX, 'Please enter a valid CVV'),
  addressLine: (value) => (value.length < 8 ? 'Please enter a valid address' : undefined),
  city: mkRegexValidator(Validators.LETTER_VALIDATOR_REGEX, 'Please enter a valid city name'),
  state: mkRegexValidator(Validators.LETTER_VALIDATOR_REGEX, 'Please enter a valid state name'),
  zip: mkRegexValidator(Validators.NUMBER_VALIDATOR_REGEX, 'Please enter a valid zip code')
};

const billingInputDefinitions = {
  cardNumber: { placeholder: 'Card Number', validator: validators.cardNumber },
  cardName: { placeholder: 'Cardholder Name', validator: validators.cardName },
  expiration: { placeholder: 'Expiration', validator: validators.expiration },
  expirationMonth: { placeholder: 'Expiration Month', validator: validators.expirationMonth },
  expirationYear: { placeholder: 'Expiration Year', validator: validators.expirationYear },
  cvv: { placeholder: 'CVV', validator: validators.cvv },
  addressLine: { placeholder: 'Address Line 1', validator: validators.addressLine },
  addressLine2: { required: false, placeholder: 'Address Line 2' },
  city: { placeholder: 'City', validator: validators.city },
  state: { placeholder: 'State', validator: validators.state },
  zip: { placeholder: 'Zip Code', validator: validators.zip }
};

export default billingInputDefinitions;
