import types from './types';
import actions from './actions';
import { ValueOf } from 'sl-api-connector/types';

export interface OmniDynamicFilterState {
  isFetching: boolean;
  data: any;
}

const initialState: OmniDynamicFilterState = {
  isFetching: true,
  data: []
};

const omniDynamicFilter = (state = initialState, action: ReturnType<ValueOf<typeof actions>>) => {
  switch (action.type) {
    case types.START_FETCH:
      return { isFetching: true, data: [] };
    case types.RECEIVE_ALL_OMNI_DYNAMIC_FILTER:
      return { isFetching: false, data: action.dynamicFilter };
    case types.END_FETCH:
      return { isFetching: true, data: [] };
    default:
      return state;
  }
};

export default omniDynamicFilter;
