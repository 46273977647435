import {
  OriginalProjectionAdvancedSearchResponse,
  ParsedOriginalProjectionResponse
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import _get from 'lodash/get';

export const parseOriginalProjectionResponse = (response: OriginalProjectionAdvancedSearchResponse[]) => {
  const unadjustedResponse = _get(response, ['0', 'aggregations', 'by_retailerId', '0', 'additionalValues'], null);

  // Define object with placeholder values
  const originalForecastData = {
    totalTraffic_sum_value: 0,
    organicTraffic_sum_value: 0,
    otherTraffic_sum_value: 0,
    paidTrafficValue_sum_value: 0,
    unitsSold_sum_value: 0,
    retailPrice_avg_value: 0,
    contentScore_avg_value: 0,
    aplusScore_avg_value: 0,
    bulletScore_avg_value: 0,
    titleScore_avg_value: 0,
    imageScore_avg_value: 0,
    videoScore_avg_value: 0,
    inStockRate_avg_value: 0,
    rating_stats_avg: 0,
    buyBoxWon_avg_value: 0,
    retailSales_sum_value: 0,
    winPercentage_computed_value: 0,
    weightedRating: 0,
    reviewCount: 0,
    costPerClick: 0,
    adSpend: 0,
    // Paid Traffic
    adClicks: 0,
    adSales: 0,
    returnOnAdSpend: 0
  };

  try {
    const {
      organicTraffic_sum_value,
      otherTraffic_sum_value,
      retailSales_sum_value,
      unitsSold_sum_value,
      weightedInStockRateByUnits_sum_value,
      weightedBuyBoxWinRateByUnits_sum_value,
      weightedRatingByRatingCount_sum_value,
      ratingCount_sum_value,
      incrementalReviewsFinal_sum_value,
      aplusScore_avg_value,
      bulletScore_avg_value,
      titleScore_avg_value,
      imageScore_avg_value,
      videoScore_avg_value,
      paidTrafficValue_sum_value,
      adSpend_sum_value,
      costPerClick_avg_value,
      adSales_sum_value,
      roas_avg_value
    } = unadjustedResponse;

    /**
     * Calculate traffic
     */
    originalForecastData.organicTraffic_sum_value += organicTraffic_sum_value;
    originalForecastData.otherTraffic_sum_value += otherTraffic_sum_value;
    originalForecastData.paidTrafficValue_sum_value += paidTrafficValue_sum_value;
    /**
     * Calculate Retail Price as unadjusted retail sales + adjusted retails sales (divided by) unadjusted units sold + adjusted units sold
     */
    originalForecastData.retailPrice_avg_value += retailSales_sum_value / (unitsSold_sum_value || 1);
    /**
     * Get Content Score
     */
    originalForecastData.aplusScore_avg_value = aplusScore_avg_value;
    originalForecastData.bulletScore_avg_value = bulletScore_avg_value;
    originalForecastData.titleScore_avg_value = titleScore_avg_value;
    originalForecastData.videoScore_avg_value = videoScore_avg_value;
    originalForecastData.imageScore_avg_value = imageScore_avg_value;

    const allScores = [
      aplusScore_avg_value,
      bulletScore_avg_value,
      titleScore_avg_value,
      videoScore_avg_value,
      imageScore_avg_value
    ];

    originalForecastData.contentScore_avg_value = allScores.reduce((acc, score) => acc + score, 0) / allScores.length;

    /**
     * Get In-Stock Rate
     */
    originalForecastData.inStockRate_avg_value =
      unitsSold_sum_value > 0 ? weightedInStockRateByUnits_sum_value / unitsSold_sum_value : 0;

    /**
     * Get Buy Box
     */
    originalForecastData.winPercentage_computed_value =
      unitsSold_sum_value > 0 ? weightedBuyBoxWinRateByUnits_sum_value / unitsSold_sum_value : 0;

    /**
     * Get Weighted Rating
     */
    originalForecastData.weightedRating =
      ratingCount_sum_value > 0 ? weightedRatingByRatingCount_sum_value / ratingCount_sum_value : 0;

    /**
     * Get Review Count
     */
    originalForecastData.reviewCount = incrementalReviewsFinal_sum_value || 0;

    /**
     * Paid traffic Metrics
     * Get Cost per Click
     */
    originalForecastData.costPerClick = costPerClick_avg_value;

    /**
     * Get ad spend
     */
    originalForecastData.adSpend = adSpend_sum_value;

    /**
     * Get ad clicks (paid traffic)
     */
    originalForecastData.adClicks = paidTrafficValue_sum_value;

    /**
     * Get ad sales
     */
    originalForecastData.adSales = adSales_sum_value;

    /**
     * Get ad ROAS
     */
    originalForecastData.returnOnAdSpend = roas_avg_value;

    return originalForecastData as ParsedOriginalProjectionResponse;
  } catch (err) {
    console.error('Failed to get unadjusted data: ', err);
    return originalForecastData;
  }
};
