import React, { useEffect, useMemo, useRef } from 'react';
import Highcharts from 'highcharts';
import colors from 'src/utils/colors';
import { createNonComparisonLegendDivs } from 'src/utils/chartUtils';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { getSimpleTooltipDiv } from 'src/components/EntityPage/Renderer/tooltipDiv';
import { NewToBrandData } from './types';
import { NewToBrandDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import _cloneDeep from 'lodash/cloneDeep';
import _sum from 'lodash/sum';
import { convertNewToBrandDataToCsv, generateCampaignLinkForChart } from './utils';
import StackedColumnChart from 'src/components/Charts/StackedColumn/StackedColumnChart';
import { NEW_TO_BRAND_EVENT, NEW_TO_BRAND_GROUPBY_EVENT } from './constants';
import _get from 'lodash/get';

/**
 * TODO FOR HORIZONTAL SCROLL
 * - Determine xMin and xMax workaround
 * - Determine cause of categories bug
 */

interface NewToBrandStackedChartProps {
  /**
   * Contains non NTB and NTB data to display
   * in the stacked bar chart.
   */
  data: NewToBrandData;
  /**
   * Selected dropdown item from the NTB visualization
   */
  metric: NewToBrandDropdownOption;
  groupByMetric: NewToBrandDropdownOption;
  renderVisualizationInsightText: boolean;
}

enum NewToBrandChartLabelType {
  NTB = 'NTB',
  NonNTB = 'NonNTB'
}

/**
 * Stacked bar chart for new to brand visualization that
 * displays metrics for new to brand and non-new to brand
 */
const NewToBrandStackedChart = ({
  data,
  metric,
  groupByMetric,
  renderVisualizationInsightText
}: NewToBrandStackedChartProps) => {
  const { mainTimePeriod, adCampaigns } = useAppSelector((state) => state);
  const formatMetric = useMetricFormatter();

  // Access the chart instance after the chart load event
  const chartRef = useRef(null);

  // We change the title position if we're showing the insight text on the summary page
  const titlePositionOverride = renderVisualizationInsightText ? -95 : 15;

  /**
   * We change the chart settings if we're grouped on Campaigns instead of Ad Type
   * */
  const isGroupedByCampaign = groupByMetric === NewToBrandDropdownOption.Campaign;
  const campaignChartModifiers = useMemo(
    () => ({
      decreaseBarWidth: isGroupedByCampaign // Change column bar width depending on group type
        ? {
            plotOptions: {
              barWidth: 65
            }
          }
        : {},
      shouldScroll: isGroupedByCampaign
        ? {
            horizontalScrolling: {
              enabled: true,
              step: 3,
              scrollStyles: {
                top: '225px' // Used to adjust the scroll controls container
              }
            }
          }
        : {
            horizontalScrolling: {
              enabled: false, // We don't want to enable scrolling when grouped on Ad Type
              step: 0
            }
          },
      extremes: isGroupedByCampaign ? { min: 0, max: 9 } : { min: 0, max: 3 }
    }),
    [groupByMetric]
  );

  // Used in conjunction with the useEffect to redraw the chart after the extremes are reset
  const updateChartExtremes = (min, max) => {
    if (chartRef.current) {
      const chart = chartRef.current;
      chart.xAxis[0].setExtremes(min, max);
      chart.redraw(); // Rerender the chart
    }
  };

  // Update the chart extremes when campaignChartModifiers change
  useEffect(() => {
    const { min, max } = campaignChartModifiers.extremes;
    updateChartExtremes(min, max);
  }, [campaignChartModifiers]);

  /**
   * NTB and non NTB series to graph.
   */
  const chartSeries: Highcharts.SeriesColumnOptions[] = useMemo(
    () => [
      {
        type: 'column',
        name: NewToBrandChartLabelType.NonNTB,
        data: _cloneDeep(data[metric].nonNewToBrand),
        color: colors.stacklineBlue,
        borderRadius: 0
      },
      {
        type: 'column',
        name: NewToBrandChartLabelType.NTB,
        data: _cloneDeep(data[metric].newToBrand),
        color: colors.darkBlue,
        borderRadius: 0
      }
    ],
    [data, metric, groupByMetric]
  );

  const buttons = useMemo(() => {
    return [
      [
        {
          displayName: 'New-to-Brand Ad Sales',
          eventName: NEW_TO_BRAND_EVENT,
          eventId: NewToBrandDropdownOption.AdSales,
          isSelected: metric === NewToBrandDropdownOption.AdSales
        },
        {
          displayName: 'New-to-Brand Ad Units Sold',
          eventName: NEW_TO_BRAND_EVENT,
          eventId: NewToBrandDropdownOption.UnitsSold,
          isSelected: metric === NewToBrandDropdownOption.UnitsSold
        },
        {
          displayName: 'New-to-Brand Conversions',
          eventName: NEW_TO_BRAND_EVENT,
          eventId: NewToBrandDropdownOption.Conversions,
          isSelected: metric === NewToBrandDropdownOption.Conversions
        }
      ],
      [
        {
          displayName: 'Ad Type',
          eventName: NEW_TO_BRAND_GROUPBY_EVENT,
          eventId: NewToBrandDropdownOption.AdType,
          isSelected: groupByMetric === NewToBrandDropdownOption.AdType
        },
        {
          displayName: 'Campaign',
          eventName: NEW_TO_BRAND_GROUPBY_EVENT,
          eventId: NewToBrandDropdownOption.Campaign,
          isSelected: groupByMetric === NewToBrandDropdownOption.Campaign
        }
      ]
    ];
  }, [metric, groupByMetric]);

  /**
   * Display props for a stacked bar chart.
   */
  const chartProps: Highcharts.Options = useMemo(
    () => ({
      ...campaignChartModifiers.decreaseBarWidth, // If we're grouped by Campaign, then decrease bar width
      ...campaignChartModifiers.shouldScroll, // If we're grouped by Campaign, then enable scroll
      chart: {
        spacingLeft: 8,
        events: {
          load: (evt) => {
            chartRef.current = evt.target;
          }
        }
      },
      title: {
        y: titlePositionOverride // If we're on the summary page, we can make space for the insight text, otherwise don't adjust the title
      },
      xAxis: {
        type: 'category',
        ...campaignChartModifiers.extremes,
        categories: data[metric].adTypes,
        labels: {
          useHTML: true,
          formatter() {
            if (isGroupedByCampaign) {
              const campaignName = this.value;

              // Search our campaign collection from Redux to match the campaign name to the campaign details
              const campaignMatch = adCampaigns.find((campaign) => campaign.campaignName === campaignName);

              if (campaignMatch) {
                const { campaignId } = campaignMatch;
                return generateCampaignLinkForChart({ campaignName, campaignId, mainTimePeriod });
              } else {
                const convertedName = String(campaignName).replace(/_/g, '-'); // CAMPAIGN_NAME --> CAMPAIGN-NAME. Replace campaign names using "_" in order to properly wrap them.
                return `<a style="margin-top: 5px; display: inline-block; white-space: normal; font-size: 13px;">${convertedName}</a>`;
              }
            }
            return this.value;
          }
        }
      },
      yAxis: {
        labels: {
          formatter() {
            return `${formatMetric(
              this.value as number,
              metric === NewToBrandDropdownOption.AdSales ? METRICTYPE.MONEY : METRICTYPE.VOLUME,
              {
                decimalPlaces: 2,
                showFullValue: false
              }
            )}`;
          }
        }
      },
      exporting: {
        enabled: true,
        buttons
      },

      legend: {
        reversed: true,
        labelFormatter() {
          const legendDivs: string[] = [];
          const isNtb = this.name === NewToBrandChartLabelType.NTB;
          const labelName = isNtb ? `NTB ${metric}` : `Non-NTB ${metric}`;
          const metricTotal = isNtb ? _sum(data[metric].newToBrand) : _sum(data[metric].nonNewToBrand);

          createNonComparisonLegendDivs({
            chartDisplayTimePeriod: mainTimePeriod,
            mainLegendMetricValue: {
              value: `${labelName} (${formatMetric(
                metricTotal,
                metric === NewToBrandDropdownOption.AdSales ? METRICTYPE.MONEY : METRICTYPE.VOLUME,
                { decimalPlaces: 2, showFullValue: false }
              )})`
            },
            isSameMetricComparison: true,
            metricsDataByTimeSeries: null,
            legendDivs
          });

          return legendDivs[0];
        }
      },
      tooltip: {
        enabled: true,
        positioner(boxWidth, boxHeight, point) {
          return {
            x: point.plotX + this.chart.plotLeft - boxWidth / 2, // center the tooltip horizontally
            y: point.plotY + this.chart.plotTop - boxHeight - 10 // position the tooltip above the column
          };
        },
        formatter() {
          const metricType = metric === NewToBrandDropdownOption.AdSales ? METRICTYPE.MONEY : METRICTYPE.VOLUME;
          // TODO: Update tooltip to be driven by the series colors

          const point1 = _get(this, ['points', 0, 'y'], 0);
          const point2 = _get(this, ['points', 1, 'y'], 0);
          const metricTotal = point1 + point2;

          return getSimpleTooltipDiv([
            {
              label: `${formatMetric(point1, metricType, { showFullValue: false, decimalPlaces: 2 })} (${formatMetric(
                metricTotal > 0 ? point1 / metricTotal : 0,
                METRICTYPE.PERCENT,
                { decimalPlaces: 2 }
              )})`
            },
            {
              label: `${formatMetric(point2, metricType, { showFullValue: false, decimalPlaces: 2 })} (${formatMetric(
                metricTotal > 0 ? point2 / metricTotal : 0,
                METRICTYPE.PERCENT,
                { decimalPlaces: 2 }
              )})`,
              color: colors.darkBlue
            }
          ]);
        }
      }
    }),
    [data, formatMetric, mainTimePeriod, metric, buttons, campaignChartModifiers, isGroupedByCampaign, groupByMetric]
  );

  return (
    <StackedColumnChart
      data={chartSeries}
      chartProps={chartProps}
      convertSeriesToDelimitedData={() =>
        convertNewToBrandDataToCsv({ data, metric, groupByMetric, dateRange: mainTimePeriod.displayName })
      }
    />
  );
};

export default NewToBrandStackedChart;
