import { SrgApi } from '../SearchResultsGridV3';
import { useMemo } from 'react';

const usePagination = (gridApi: SrgApi) => {
  return useMemo(() => {
    if (gridApi) {
      return {
        page: gridApi.getPage(),
        sortField: gridApi.getSortField(),
        sortDirection: gridApi.getSortDirection()
      };
    }
    return {
      page: 1,
      sortField: '',
      sortDirection: ''
    };
  }, [gridApi]);
};

export default usePagination;
