import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import 'src/routes/HomePage/Segments.scss';
import Truncate from 'react-truncate';
import Chip from '@mui/material/Chip';
import colors from 'src/utils/colors';
import { destructurePathName } from 'src/utils/urlParsing';
import { capitalize } from 'lodash';
import { getCountryImageUrl } from 'src/utils/image';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';

const OmniGeoSidebarCard: React.FC<RouteComponentProps> = ({ location }: RouteComponentProps) => {
  const { pathname } = location;
  const [entityName, value] = destructurePathName(pathname);
  const [logoAvailable, setLogoAvailable] = useState(true);
  const omniRegionFollowing = useSelector((state: ReduxStore) => state.omniRegionsFollowing);
  const omniCountriesFollowing = useSelector((state: ReduxStore) => state.omniCountriesFollowing);

  const nameGenerator = (name: string) => {
    if (entityName === 'region') {
      const currentRegion = omniRegionFollowing.find((region) => region.regionCode === name);
      return currentRegion.regionName;
    }

    if (entityName === 'country') {
      const currentCountry = omniCountriesFollowing.find((country) => country.countryCode === name);
      return currentCountry.countryName;
    }

    return name;
  };

  return (
    <div className="nav-header-container">
      <div className="nav-image-container">
        <div className="nav-image-center">
          {entityName === 'country' && logoAvailable ? (
            <div className="brand__image-container sl-image-container">
              <div className="brand__image-content sl-image-center">
                <img
                  className="sl-image"
                  alt="logo"
                  src={getCountryImageUrl(value)}
                  onError={() => setLogoAvailable(false)}
                />
              </div>
            </div>
          ) : (
            <div className="segment__short-name-container" style={{ backgroundColor: colors.darkBlue }}>
              <div className="segment__short-name">{value.substring(0, 2)}</div>
            </div>
          )}
        </div>
      </div>
      <div className="nav-name-container" title={value}>
        <Truncate style={{ display: 'inline-block', verticalAlign: 'middle' }} lines={2} ellipsis="...">
          <div className="nav-company-name">{nameGenerator(value)}</div>
        </Truncate>
      </div>
      <Chip className="entity-chip" label={capitalize(entityName)} />
    </div>
  );
};

export default withRouter(OmniGeoSidebarCard);
