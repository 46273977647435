import * as types from './types';

export const setValue = (formName, name, value) => ({ type: types.SET_GENERIC_FORM_VALUE, formName, name, value });

export const setIsSubmitted = (formName, isSubmitted) => ({
  type: types.SET_GENERIC_FORM_IS_SUBMITTED,
  formName,
  isSubmitted
});

export const setIsSubmitting = (formName, isSubmitting) => ({
  type: types.SET_GENERIC_FORM_IS_SUBMITTING,
  formName,
  isSubmitting
});

export const initForm = (formName, initialState) => ({ type: types.INIT_GENERIC_FORM, formName, initialState });

export const deleteForm = (formName) => ({ type: types.DELETE_FORM, formName });
