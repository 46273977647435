import _cloneDeep from 'lodash/cloneDeep';

import {
  buildCustomDateRangeDisplayName,
  getWeekFirstDate,
  getWeekLastDate,
  capDateToCurrentDate,
  computeMainTimePeriod,
  getDayIdFromDate
} from 'src/utils/dateformatting';

export const createTimePeriodDateObject = (timePeriodObject, startWeek, endWeek) => {
  timePeriodObject.startWeek = startWeek;
  timePeriodObject.endWeek = endWeek;
  timePeriodObject.startWeekStartDate = getWeekFirstDate(startWeek);
  timePeriodObject.endWeekEndDate = capDateToCurrentDate(getWeekLastDate(endWeek));
  timePeriodObject.startDayId = getDayIdFromDate(timePeriodObject.startWeekStartDate);
  timePeriodObject.endDayId = getDayIdFromDate(timePeriodObject.endWeekEndDate);
  timePeriodObject.displayName = buildCustomDateRangeDisplayName(
    timePeriodObject.startDayId,
    timePeriodObject.endDayId
  );
  timePeriodObject.timePeriodSuffix = {
    dayId: `_${timePeriodObject.startDayId}_${timePeriodObject.endDayId}`,
    weekId: `_${timePeriodObject.startWeek}_${timePeriodObject.endWeek}`
  };
  return timePeriodObject;
};

export default function calculateAdditionalTimePeriods(
  allWeekIds,
  availableMainTimePeriods,
  updatedMainTimePeriod,
  app
) {
  const clonedTimePeriods = _cloneDeep(availableMainTimePeriods);
  const newAvailableTimePeriods = clonedTimePeriods.map((item) => {
    const newItem = item;

    if (item.id === 'cd') {
      if (updatedMainTimePeriod.id === 'cd') {
        const returnedItem = createTimePeriodDateObject(
          newItem,
          updatedMainTimePeriod.startWeek,
          updatedMainTimePeriod.endWeek
        );
        return returnedItem;
      } else if (item.startWeek) {
        return {
          id: newItem.id,
          shortDisplayName: newItem.shortDisplayName
        };
      } else if (updatedMainTimePeriod.id !== 'cd') {
        return newItem;
      }
    }

    const { startWeek, endWeek } = computeMainTimePeriod(allWeekIds, item, app);
    const returnedItem = createTimePeriodDateObject(newItem, startWeek, endWeek);
    return returnedItem;
  });
  return newAvailableTimePeriods;
}
