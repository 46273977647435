/**
 * A version of `TrendChart` that allows the displayed metric to be toggled via a dropdown
 */

import { propEq } from 'src/utils/fp';
import { TrendChartInner, enhanceTrendChart } from './TrendChart';
import { buildSubtitleDisplayName } from 'src/utils/filters';

const buildDropdownOptions = (metricFields, selectedMetricFieldName) => {
  return metricFields.map(({ displayName }) => ({
    eventId: { displayName },
    eventName: 'toggleChartMainMetric',
    displayName,
    isSelected: displayName === selectedMetricFieldName
  }));
};

class MultiMetricTrendChart extends TrendChartInner {
  componentDidMount() {
    super.componentDidMount.call(this);
    this.props.eventBus.on('toggleChartMainMetric', this.handleMainMetricToggle);
  }

  generateSubtitle = () => {
    const {
      retailer,
      entityService: { mainEntity },
      app,
      categories,
      filters
    } = this.props;

    const subtitle = buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app);

    return subtitle;
  };

  componentWillUnmount() {
    super.componentWillUnmount.call(this);
    this.props.eventBus.off('toggleChartMainMetric', this.handleMainMetricToggle);
  }

  getSelectedMetricField = () => {
    const { metricFields } = this.props.widget.view;
    return metricFields.find(propEq('displayName', this.state.selectedMetricFieldName)) || metricFields[0];
  };

  handleMainMetricToggle = ({ displayName }) => {
    this.setState({ selectedMetricFieldName: displayName }, () => this.fetchData());
  };

  getAggregationFields(groupByField) {
    return super.getAggregationFields
      .call(this, groupByField)
      .filter(propEq('name', this.getSelectedMetricField().name));
  }

  getMetricFields() {
    return [this.getSelectedMetricField()];
  }

  getChartParams() {
    const chartParams = super.getChartParams.call(this, this.getSelectedMetricField());
    if (!chartParams) {
      return chartParams;
    }
    const { chartProps, chartSeries } = chartParams;

    chartProps.exporting.buttons = buildDropdownOptions(
      this.props.widget.view.metricFields,
      this.getSelectedMetricField().displayName
    );
    chartProps.title.dropdownStyle = { marginLeft: -5 };

    return { chartProps, chartSeries };
  }
}

const EnhancedMultiMetricTrendChart = enhanceTrendChart(MultiMetricTrendChart);

export default EnhancedMultiMetricTrendChart;
