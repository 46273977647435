import _get from 'lodash/get';
import _merge from 'lodash/merge';

import Highcharts from 'highcharts/highstock';
import colors from 'src/utils/colors';
import fontStyle from 'src/utils/fontStyle';
import { enhanceChartPropsWithGroupByOptions } from './GroupByOptions';

/**
 * This function returns an object containing default chart props which will be merged into the custom chart
 * props passed into `GenericChart` to produce a master set.
 *
 * @param {*} chartProps the custom `chartProps` passed into the `GenericChart` component
 */
export const getChartPropDefaults = (chartProps) => ({
  yAxis: {
    gridLineWidth: 0,
    opposite: false,
    title: {
      text: ''
    },
    labels: {
      style: {
        color: colors.darkBlue,
        'font-size': '11px',
        'font-family': "'Roboto', sans-serif",
        'font-weight': fontStyle.regularWeight,
        fill: colors.darkBlue
      },
      formatter() {
        return this.value;
      }
    },
    min: null,
    max: null
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      month: '%b',
      week: '%b %e'
    },
    crosshair: false,
    lineWidth: 0,
    tickWidth: 0,
    tickPositions: null,
    labels: {
      rotation: 0,
      formatter() {
        return Highcharts.dateFormat('%b %e', this.value);
      },
      style: {
        color: colors.darkBlue,
        'min-width': '100px',
        'font-size': '11px',
        'font-family': "'Roboto', sans-serif",
        'font-weight': fontStyle.regularWeight,
        fill: colors.darkBlue,
        whiteSpace: 'normal',
        textAlign: chartProps.chart.type !== 'column' ? 'left' : 'center'
      }
    }
  },
  tooltip: {
    shared: true,
    valueSuffix: '',
    borderColor: 'transparent',
    opacity: 1,
    backgroundColor: 'transparent',
    shadow: false,
    useHTML: true,
    positioner(labelWidth, labelHeight, point) {
      if (this.chart.plotBox.width - point.plotX < 200) {
        point.plotY = this.chart.plotBox.y + 10;
      }
      return { x: point.plotX, y: point.plotY };
    },
    formatter() {
      return this.y;
    }
  },
  legend: {
    enabled: true,
    layout: 'horizontal',
    align: 'right',
    verticalAlign: 'top',
    x: -75,
    y: -5,
    floating: true,
    borderWidth: 0,
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 6,
    symbolPadding: 20,
    itemDistance: 40,
    rtl: false,
    backgroundColor: '#FFFFFF',
    labelFormatter() {
      return this.name;
    },
    itemStyle: {
      color: colors.darkBlue,
      'font-size': '18px',
      'font-family': "'Roboto', sans-serif",
      'font-weight': fontStyle.regularWeight,
      fill: colors.darkBlue
    },
    useHTML: true
  },
  title: {
    text: '',
    align: 'left',
    useHTML: true,
    style: {
      'font-size': '28px',
      'font-family': "'Roboto', sans-serif",
      'font-weight': fontStyle.regularWeight,
      color: colors.darkBlue,
      fill: colors.darkBlue
    }
  },
  subtitle: {
    text: '',
    align: 'left',
    y: 40,
    useHTML: true,
    style: {
      'font-size': '14px',
      'font-family': "'Roboto', sans-serif",
      'font-weight': fontStyle.regularWeight,
      color: colors.darkBlue,
      fill: colors.darkBlue,
      'padding-bottom': '25px',
      'border-bottom': `1px solid ${colors.lightestGrey}`
    }
  },
  lang: {
    noData: 'No data to display'
  },
  chart: {
    type: 'areaspline',
    height: 500,
    marginTop: 110
  },
  navigator: {
    enabled: false,
    handles: {
      backgroundColor: '#fff',
      borderColor: '#1D2935'
    },
    maskInside: false,
    maskFill: 'rgba(180, 198, 220, 0.5)',
    height: 80,
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        week: '%d-%m'
      },
      crosshair: false,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        style: {
          color: '#808fa4',
          'font-size': '9px',
          'font-weight': fontStyle.regularWeight,
          'font-family': "'Roboto', sans-serif"
        },
        formatter() {
          return this.value;
        }
      }
    }
  },
  scrollbar: {
    barBackgroundColor: '#E4EFFC',
    barBorderRadius: 0,
    barBorderWidth: 1,
    barBorderColor: '#E4EFFC',
    buttonBackgroundColor: '#fff',
    buttonBorderWidth: 1,
    buttonBorderColor: '#ccc',
    buttonArrowColor: '#ccc',
    buttonBorderRadius: 7,
    rifleColor: '#fff',
    trackBackgroundColor: '#fff',
    trackBorderWidth: 1,
    trackBorderColor: '#eee',
    trackBorderRadius: 7,
    enabled: false
  },
  credits: {
    enabled: false
  },
  navigation: {
    menuStyle: {
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
      margin: '-35px -10px 0 0',
      background: 'white',
      padding: '5px 0',
      border: 'none'
    },
    menuItemStyle: {
      padding: '8px 28px',
      background: 'none',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '15px',
      fontWeight: fontStyle.regularWeight,
      border: 'none'
    },
    menuItemHoverStyle: {
      background: 'rgb(246, 249,252)',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  rangeSelector: {
    enabled: false,
    allButtonsEnabled: false,
    inputEnabled: false,
    buttonPosition: {
      x: 120,
      y: 10
    },
    buttons: [
      {
        type: 'month',
        count: 3,
        text: '3M'
      },
      {
        type: 'month',
        count: 6,
        text: '6M'
      },
      {
        type: 'ytd',
        text: 'YTD'
      },
      {
        type: 'all',
        text: 'All'
      }
    ],
    buttonTheme: {
      // styles for the buttons
      fill: 'none',
      stroke: 'none',
      'stroke-width': 0,
      r: 3,
      style: {
        color: colors.stacklineBlue,
        fontWeight: '400',
        fontSize: '14px'
      },
      states: {
        select: {
          fill: colors.green,
          style: {
            color: 'white'
          }
        }
      }
    },
    labelStyle: {
      color: '#1D2935',
      fontWeight: '400',
      fontSize: '16px',
      display: 'none'
    },
    height: 0,
    selected: 0
  }
});

/**
 * Merges the custom `chartProps` object passed to `GenericChart` into a default plot options object, returning the
 * resulting new `plotOptions` object.
 *
 * @param {object} chartProps The `chartProps` object passed into `GenericChart`
 */
export const getEnhancedPlotOptions = (chartProps) => {
  /**
   * Retrieves a property from `chartProps` at the provided path, returning `defaultValue` (or `undefined`) in the case
   * that it can't be found in the object.
   *
   * @param {string} path A path into the `chartProps.plotOptions` object.  Passing in `"foo.bar"` will return
   * `chartProps.plotOptions.foo.bar`.
   * @param {any} defaultValue The value to return if any of the levels up to the specified path don't exist
   */
  const getPlotOption = (path, defaultValue) => _get(chartProps, `plotOptions.${path}`, defaultValue);
  return {
    waterfall: {
      events: {
        legendItemClick: getPlotOption('waterfall.events.legendItemClick')
      }
    },
    spline: {
      states: {
        hover: {
          halo: {
            size: 1
          },
          lineWidth: 2
        }
      },
      marker: {
        enabled: false,
        radius: 0,
        states: {
          hover: {
            radiusPlus: 1
          }
        }
      },
      events: {
        legendItemClick: getPlotOption('spline.events.legendItemClick')
      }
    },
    bar: {
      stacking: 'bar',
      cursor: getPlotOption('allowPointSelect') ? 'pointer' : 'cursor',
      allowPointSelect: getPlotOption('allowPointSelect', false),
      states: {
        select: {
          color: getPlotOption('selectedPointColor', '#FC911C'),
          borderColor: getPlotOption('selectedPointBorderColor', '#fff')
        }
      },
      fillColor: '#D9E6FF',
      fillOpacity: 1,
      color: colors.stacklineBlue,
      pointWidth: getPlotOption('barWidth', 15),
      borderWidth: getPlotOption('borderWidth', 1),
      borderRadius: getPlotOption('borderRadius', 3),
      borderColor: getPlotOption('borderColor', '#fff')
    },
    column: {
      cursor: getPlotOption('allowPointSelect') ? 'pointer' : 'cursor',
      fillColor: getPlotOption('column.fillColor', '#D9E6FF'),
      fillOpacity: getPlotOption('column.fillOpacity', 1),
      color: getPlotOption('column.color', colors.stacklineBlue),
      colorByPoint: !!getPlotOption('column.colorByPoint'),
      colors: getPlotOption('columns.colors'),
      pointPlacement: getPlotOption('column.pointPlacement'),
      pointWidth: getPlotOption('barWidth', 15),
      borderWidth: getPlotOption('borderWidth', 0),
      borderRadius: getPlotOption('borderRadius', 3),
      borderColor: getPlotOption('borderColor', '#fff'),
      grouping: getPlotOption('column.grouping', true),
      shadow: false,
      pointPadding: getPlotOption('column.pointPadding', 0.1),
      dataLabels: getPlotOption('column.dataLabels', {}),
      stacking: getPlotOption('column.stacking'),
      events: getPlotOption('column.events')
    },
    areaspline: {
      events: getPlotOption('events'),
      fillOpacity: getPlotOption('fillEnabled') ? 0.1 : 0,
      states: {
        hover: {
          lineWidth: 2
        }
      },
      lineWidth: 2,
      marker: {
        enabled: false,
        fillOpacity: getPlotOption('fillEnabled') ? 0.1 : 0,
        lineColor: colors.stacklineBlue,
        lineWidth: 2,
        radius: 2,
        symbol: 'circle',
        states: {
          hover: {
            radius: 2
          }
        }
      }
    },
    pie: {
      colors: getPlotOption('pie.colors', [
        colors.stacklineBlue,
        colors.purple,
        colors.orange,
        colors.red,
        colors.pink
      ]),
      size: getPlotOption('pie.size', 300),
      cursor: getPlotOption('pie.cursor') ? 'initial' : 'pointer',
      dataLabels: getPlotOption('pie.dataLabels', { enabled: false }),
      startAngle: getPlotOption('pie.startAngle', -135),
      endAngle: getPlotOption('pie.endAngle', 135),
      center: getPlotOption('pie.center', ['50%', '50%']),
      point: {
        events: {}
      }
    },
    line: {
      animation: getPlotOption('line.animation', true),
      states: {
        hover: {
          lineWidth: getPlotOption('line.states.hover.lineWidth', 2)
        }
      },
      lineWidth: getPlotOption('line.lineWidth', 2),
      fillOpacity: getPlotOption('fillEnabled') ? 0.1 : 0,
      lineColor: colors.stacklineBlue,
      marker: {
        enabled: getPlotOption('line.marker.enabled', false),
        fillOpacity: getPlotOption('fillEnabled') ? 0.1 : 0,
        lineColor: getPlotOption('line.marker.lineColor', colors.stacklineBlue),
        lineWidth: 2,
        radius: getPlotOption('line.marker.radius', 4),
        symbol: getPlotOption('line.marker.symbol')
      }
    },
    venn: _get(chartProps, 'plotOptions.venn', {}),
    series: {
      allowPointSelect: getPlotOption('series.allowPointSelect', false),
      cursor: getPlotOption('series.cursor', 'initial'),
      event: {
        legendItemClick: getPlotOption('series.events.legendItemClick')
      },
      states: {
        select: {
          color: getPlotOption('series.states.select.color', '')
        },
        hover: {
          enabled: getPlotOption('series.states.hover.enabled', false)
        }
      },
      dataLabels: getPlotOption('series.dataLabels', {
        crop: false,
        overflow: 'none'
      }),
      point: {
        events: {
          click: getPlotOption('series.point.events.click', () => {})
        }
      },
      gapSize: 0
    }
  };
};

/**
 * Given the chart props passed into the `GenericChart` component, enhances them by deep-merging them with defaults,
 * producing a new `chartProps` object containing the custom props passed in as well as the base props.
 *
 * @param {object} chartProps The `chartProps` object passed into the `GenericChart` component
 */
export const enhanceChartPropsWithDefaults = (chartProps, uniqChartId, eventBus) => {
  const defaultChartProps = getChartPropDefaults(chartProps);
  const { hideFieldDropdowns, hideGroupBy } = chartProps;
  // These keys can be enhanced by simply merging in the values of `chartProps` and `chartPropDefaults` for each.
  const simplyEnhancedKeys = [
    'tooltip',
    'legend',
    'title',
    'subtitle',
    'lang',
    'chart',
    'navigator',
    'scrollbar',
    'credits',
    'navigation',
    'exporting',
    'subtitleOverride'
  ];
  const simplyEnhancedChartProps = simplyEnhancedKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: _merge({}, defaultChartProps[key] || {}, chartProps[key] || {})
    }),
    {}
  );

  // Add the group by options, giving them the option to inject custom HTML into the title and add event listeners
  // to the config.
  const enhancedChartProps = enhanceChartPropsWithGroupByOptions(
    {
      ...simplyEnhancedChartProps,
      hideFieldDropdowns,
      hideGroupBy,
      yAxis: chartProps.yAxis.map((axis) => _merge({}, defaultChartProps.yAxis, axis)),
      xAxis: chartProps.xAxis.map((axis) => _merge({}, defaultChartProps.xAxis, axis)),
      tab: chartProps.tab,
      subtab: chartProps.subtab
    },
    uniqChartId,
    eventBus
  );

  return {
    ...enhancedChartProps,
    rangeSelector: _merge({}, defaultChartProps.rangeSelector, enhancedChartProps.chart.rangeSelector || {}),
    exporting: { enabled: false }
  };
};
