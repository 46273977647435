import {
  ContentScoreFields,
  PaidTrafficFields,
  PlanTypeOption,
  RatingsReviewFields
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import {
  AdjustmentForm,
  ContentScoreAdditionalProperties,
  ContentScoreInput,
  DefaultInput,
  PaidTrafficInput,
  ParsedOriginalProjectionResponse,
  PromotionMetricsResponse,
  RatingsReviewInput
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import { AdjustmentLog } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ViewAdjustmentModal/types';

import { MetricFormatterFn } from 'src/utils/Hooks';
import { safeDivide } from 'src/utils/app';
import {
  calculateWeeksBetweenWeekIds,
  formatWeekIdsForAdjustmentDisplay,
  getStartAndEndOfWeek
} from 'src/utils/dateUtils';
import { formatTime } from 'src/utils/dateformatting';
import { METRICTYPE } from 'src/utils/entityDefinitions';

/**
 *
 * @param startDate Given a week ID such as 202305, gets the start day of the start week
 * @param endDate Given a week ID such as 202306, gets the end day of the end week
 * @param formatStr Optional format str for use with moment.js. Defaults "MMMM Do, YYYY"
 */
export const getFormattedStartAndEndDates = (startDate: string, endDate: string, formatStr = null) => {
  const formatString = formatStr || 'MMMM Do, YYYY';
  const { startDate: startDayOfStartWeek } = getStartAndEndOfWeek(startDate);
  const { endDate: endDayOfEndWeek } = getStartAndEndOfWeek(endDate);
  const formattedStartDate = formatTime(startDayOfStartWeek, formatString);
  const formattedEndDate = formatTime(endDayOfEndWeek, formatString);
  return { formattedStartDate, formattedEndDate };
};

// Type guards for the different input types
export function isDefaultInput(
  input: DefaultInput | ContentScoreInput | RatingsReviewInput | PaidTrafficInput
): input is DefaultInput {
  return 'total' in input;
}

export function isContentScoreInput(
  input: DefaultInput | ContentScoreInput | RatingsReviewInput | PaidTrafficInput
): input is ContentScoreInput {
  return ContentScoreFields.TitleScore in input;
}

export function isRatingsReviewInput(
  input: DefaultInput | ContentScoreInput | RatingsReviewInput | PaidTrafficInput
): input is RatingsReviewInput {
  return RatingsReviewFields.AverageRating in input;
}

export function isPaidTrafficInput(
  input: DefaultInput | ContentScoreInput | RatingsReviewInput | PaidTrafficInput
): input is PaidTrafficInput {
  return PaidTrafficFields.AdSpend in input;
}

interface GetDerivedAdjustmentAmountParams {
  planType: PlanTypeOption;
  input: DefaultInput | ContentScoreInput | RatingsReviewInput | PaidTrafficInput;
}
/**
 * Given a plan type and an adjustment form, determines the derived plan change amount based on all given inputs.
 * Note: percentages are returned as decimals.
 */
export const getDerivedAdjustmentAmount = ({ planType, input }: GetDerivedAdjustmentAmountParams) => {
  if (isDefaultInput(input)) {
    if ([PlanTypeOption.BuyBox, PlanTypeOption.InStockRate].includes(planType)) {
      return Number(input.total) / 100;
    } else {
      return Number(input.total);
    }
  }
  if (isContentScoreInput(input)) {
    const sum = Object.values(input).reduce((acc, curr) => {
      acc += Number(curr);
      return acc;
    }, 0);

    return Number(sum / Object.keys(input).length) / 100;
  }
  // We don't technically use derived ratings inputs, only the separate fields found within the inputs
  if (isRatingsReviewInput(input)) {
    return 0;
  }
  // Calculate Paid Traffic
  if (isPaidTrafficInput(input)) {
    return Number(input.adSpend) / (Number(input.costPerClick) || 1);
  }
  return 0;
};

/**
 * Generates a specific object to attach to adjustment requests
 * @param adjustmentForm The adjustment form based on user input.
 * @param cpcFromUser Optional boolean to determine if the user modified the cpc at all.
 * @returns A specific object to attach to adjustment requests.
 */
export const createAdditionalProperties = (adjustmentForm: AdjustmentForm, cpcFromUser = false) => {
  const { planInputAmounts, isPromotionAdjustment, promotionType, planType } = adjustmentForm;

  if (isContentScoreInput(planInputAmounts)) {
    return Object.entries(planInputAmounts).reduce((acc, [key, val]) => {
      if (val) {
        acc[key] = val / 100;
      } else {
        acc[key] = 0;
      }
      return acc as ContentScoreAdditionalProperties;
    }, {});
  } else if (isRatingsReviewInput(planInputAmounts)) {
    return {
      reviewAvgRating: planInputAmounts.averageStarRatingAmount,
      reviewCount: planInputAmounts.reviewQuantityAmount
    };
  } else if (isPaidTrafficInput(planInputAmounts)) {
    return {
      adSpend: planInputAmounts.adSpend,
      cpc: planInputAmounts.costPerClick,
      cpcFromUser
    };
  } else if (planType === PlanTypeOption.RetailPrice) {
    return {
      promotionType: isPromotionAdjustment ? promotionType : ''
    };
  } else {
    return {
      reviewAvgRating: 0,
      reviewCount: 0
    };
  }
};

export const calculateAverage = (values: number[]) => {
  return (
    values.reduce((acc, curr) => {
      acc += Number(curr);
      return acc;
    }, 0) / (values.length || 1)
  );
};

interface GenerateOriginalPlanTextParams {
  adjustmentForm: AdjustmentForm;
  parsedData: ParsedOriginalProjectionResponse;
  isPromotionAdjustment?: boolean;
  formatMetric: MetricFormatterFn;
}
export const generateOriginalPlanText = ({
  adjustmentForm,
  parsedData,
  isPromotionAdjustment,
  formatMetric
}: GenerateOriginalPlanTextParams) => {
  const { planType, startDate, endDate } = adjustmentForm;

  const { formattedStartDate, formattedEndDate } = getFormattedStartAndEndDates(startDate, endDate);
  const numberOfWeeks = calculateWeeksBetweenWeekIds(startDate, endDate) || 1;

  if (!parsedData) {
    return '';
  }

  switch (planType) {
    case PlanTypeOption.OrganicTraffic:
      return `Originally, this product was projected to generate ${formatMetric(
        parsedData.organicTraffic_sum_value,
        METRICTYPE.VOLUME
      )} (${formatMetric(
        parsedData.organicTraffic_sum_value / numberOfWeeks,
        METRICTYPE.VOLUME
      )} per week) in organic traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;
    case PlanTypeOption.OtherTraffic:
      return `Originally, this product was projected to generate ${formatMetric(
        parsedData.otherTraffic_sum_value,
        METRICTYPE.VOLUME
      )} (${formatMetric(
        parsedData.otherTraffic_sum_value / numberOfWeeks,
        METRICTYPE.VOLUME
      )} per week) in other traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;
    case PlanTypeOption.BuyBox:
      return `Originally, this product was projected to have a ${formatMetric(
        parsedData.winPercentage_computed_value,
        METRICTYPE.PERCENT
      )} buy box rate during the period of ${formattedStartDate} to ${formattedEndDate}.`;

    case PlanTypeOption.ContentScore:
      return `Originally, this product was projected to have a ${formatMetric(
        parsedData.contentScore_avg_value,
        METRICTYPE.PERCENT
      )} content score during the period of ${formattedStartDate} to ${formattedEndDate}.`;

    case PlanTypeOption.InStockRate:
      return `Originally, this product was projected to generate ${formatMetric(
        parsedData.inStockRate_avg_value,
        METRICTYPE.PERCENT
      )} in-stock rate during the period of ${formattedStartDate} to ${formattedEndDate}.`;

    case PlanTypeOption.RetailPrice: {
      if (isPromotionAdjustment) {
        return `Originally, this product was projected to have a retail price of ${formatMetric(
          parsedData.retailPrice_avg_value,
          METRICTYPE.MONEY
        )} and generate ${formatMetric(parsedData.otherTraffic_sum_value, METRICTYPE.VOLUME)} 
(${formatMetric(
          parsedData.otherTraffic_sum_value / numberOfWeeks,
          METRICTYPE.VOLUME
        )} per week) in other traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;
      }
      return `Originally, this product was projected to have a retail price of ${formatMetric(
        parsedData.retailPrice_avg_value,
        METRICTYPE.MONEY
      )} during the period of ${formattedStartDate} to ${formattedEndDate}.`;
    }

    case PlanTypeOption.RatingsReviews:
      return `Originally, this product was projected to generate ${formatMetric(
        parsedData.reviewCount,
        METRICTYPE.VOLUME
      )} (${formatMetric(
        parsedData.reviewCount / numberOfWeeks,
        METRICTYPE.VOLUME
      )} per week) reviews with an average star rating of ${formatMetric(
        parsedData.weightedRating,
        METRICTYPE.DECIMAL
      )} during the period of ${formattedStartDate} to ${formattedEndDate}.`;

    case PlanTypeOption.PaidTraffic:
      return `Originally, this product was projected to spend ${formatMetric(
        parsedData.adSpend,
        METRICTYPE.MONEY
      )} (${formatMetric(
        parsedData.adSpend / numberOfWeeks,
        METRICTYPE.MONEY
      )} per week) on advertising and generate ${formatMetric(parsedData.adClicks, METRICTYPE.VOLUME)} (${formatMetric(
        parsedData.adClicks / numberOfWeeks,
        METRICTYPE.VOLUME
      )} per week) in paid traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;

    default:
      return '';
  }
};

/**
 * (Current number of reviews *  Current AVG Star Rating) + (Additional Number of reviews * Additional AVG Star Rating)
------------------------------------------------------[divided by]------------------------------------------------------
                                 (Current number of reviews + Additional Number of reviews)
 */
interface CalculateWeightedAverageRatingParams {
  currentReviewCount: number;
  currentAverageStarRating: number;
  additionalReviewCount: number;
  additionalAverageStarRating: number;
}
export const calculateWeightedAverageRating = ({
  currentReviewCount,
  currentAverageStarRating,
  additionalReviewCount,
  additionalAverageStarRating
}: CalculateWeightedAverageRatingParams): number => {
  const numerator = currentReviewCount * currentAverageStarRating + additionalReviewCount * additionalAverageStarRating;
  const denominator = currentReviewCount + additionalReviewCount;

  return denominator > 0 ? numerator / denominator : 0;
};

interface GenerateNewProjectionTextParams extends GenerateOriginalPlanTextParams {
  paidTrafficMetrics: { [key: string]: number };
  promotionProjection: Omit<PromotionMetricsResponse, 'weekId'>;
  isPromotionAdjustment: boolean;
}

export const generateNewProjectionText = ({
  parsedData,
  adjustmentForm,
  formatMetric,
  paidTrafficMetrics,
  promotionProjection,
  isPromotionAdjustment
}: GenerateNewProjectionTextParams) => {
  const { planType, startDate, endDate, planInputAmounts } = adjustmentForm;

  const numberOfWeeks = calculateWeeksBetweenWeekIds(startDate, endDate) || 1;

  // Wait for the original projection parsed data. If we're on a Paid Traffic adjustment, we also need the ad spend projection.
  if (
    !parsedData ||
    (planType === PlanTypeOption.PaidTraffic && !paidTrafficMetrics) ||
    (!promotionProjection && isPromotionAdjustment)
  ) {
    return '';
  }

  switch (planType) {
    case PlanTypeOption.OrganicTraffic:
      if (isDefaultInput(planInputAmounts)) {
        const planAmount = Number(planInputAmounts.total);
        return `After applying your adjustment, this product is now projected to generate ${formatMetric(
          planAmount * numberOfWeeks,
          METRICTYPE.VOLUME
        )} (${formatMetric(planAmount, METRICTYPE.VOLUME)} per week) in organic traffic during the period.`;
      }
      break;
    case PlanTypeOption.OtherTraffic:
      if (isDefaultInput(planInputAmounts)) {
        const planAmount = Number(planInputAmounts.total);
        return `After applying your adjustment, this product is now projected to generate ${formatMetric(
          planAmount * numberOfWeeks,
          METRICTYPE.VOLUME
        )} (${formatMetric(planAmount, METRICTYPE.VOLUME)} per week) in other traffic during the period.`;
      }
      break;
    case PlanTypeOption.BuyBox:
      if (isDefaultInput(planInputAmounts)) {
        const planAmount = Number(planInputAmounts.total) / 100;
        return `After applying your adjustment, this product is now projected to have a ${formatMetric(
          planAmount,
          METRICTYPE.PERCENT
        )} buy box rate during the period.`;
      }
      break;
    case PlanTypeOption.ContentScore:
      if (isContentScoreInput(planInputAmounts)) {
        const planAmount = getDerivedAdjustmentAmount({ planType, input: planInputAmounts });

        return `After applying your adjustment, this product is now projected to have a ${formatMetric(
          planAmount,
          METRICTYPE.PERCENT
        )} content score during the period.`;
      }
      break;
    case PlanTypeOption.InStockRate:
      if (isDefaultInput(planInputAmounts)) {
        const planAmount = Number(planInputAmounts.total) / 100;
        return `After applying your adjustment, this product is now projected to have a ${formatMetric(
          planAmount,
          METRICTYPE.PERCENT
        )} in-stock rate during the period.`;
      }
      break;
    case PlanTypeOption.RetailPrice:
      if (isDefaultInput(planInputAmounts)) {
        const planAmount = Number(planInputAmounts.total);
        if (isPromotionAdjustment) {
          return `After applying your adjustment, this product is now projected to have a retail price of ${formatMetric(
            planAmount,
            METRICTYPE.MONEY
          )} 
and generate ${formatMetric(promotionProjection.other_traffic * numberOfWeeks, METRICTYPE.VOLUME)} (${formatMetric(
            promotionProjection.other_traffic,
            METRICTYPE.VOLUME
          )} per week) in other traffic during the period.`;
        }

        return `After applying your adjustment, this product is now projected to have a retail price of ${formatMetric(
          planAmount,
          METRICTYPE.MONEY
        )} during the period.`;
      }
      break;

    case PlanTypeOption.RatingsReviews:
      if (isRatingsReviewInput(planInputAmounts)) {
        return `After applying your adjustment, this product is now projected to generate ${formatMetric(
          (parsedData.reviewCount + Number(planInputAmounts.reviewQuantityAmount)) * numberOfWeeks,
          METRICTYPE.VOLUME
        )} (${formatMetric(
          parsedData.reviewCount + Number(planInputAmounts.reviewQuantityAmount),
          METRICTYPE.VOLUME
        )} per week) reviews with an average star rating of ${formatMetric(
          Number(
            calculateWeightedAverageRating({
              currentReviewCount: parsedData.reviewCount,
              currentAverageStarRating: parsedData.weightedRating,
              additionalAverageStarRating: Number(planInputAmounts.averageStarRatingAmount),
              additionalReviewCount: Number(planInputAmounts.reviewQuantityAmount)
            }).toFixed(1)
          ),
          METRICTYPE.DECIMAL
        )} during the period.`;
      }
      break;

    case PlanTypeOption.PaidTraffic:
      if (isPaidTrafficInput(planInputAmounts)) {
        return `After applying your adjustment, this product is now projected to spend ${formatMetric(
          paidTrafficMetrics.ad_spend,
          METRICTYPE.MONEY
        )} (${formatMetric(
          safeDivide(paidTrafficMetrics.ad_spend, numberOfWeeks),
          METRICTYPE.MONEY
        )} per week) in ad spend and generate ${formatMetric(
          paidTrafficMetrics.paid_traffic,
          METRICTYPE.VOLUME
        )} (${formatMetric(
          safeDivide(paidTrafficMetrics.paid_traffic, numberOfWeeks),
          METRICTYPE.VOLUME
        )} per week) in paid traffic during the period.`;
      }
      break;
    default:
      return '';
  }
  return null;
};

export const generateNetImpactText = ({
  netImpact,
  planType,
  formatMetric
}: {
  netImpact: number;
  planType: PlanTypeOption;
  formatMetric: MetricFormatterFn;
}) => {
  const formattedNetImpact = `${formatMetric(netImpact, METRICTYPE.VOLUME)} units sold`;
  const directionalTerm = netImpact > 0 ? 'increase' : 'decrease';
  const locationTerm = netImpact > 0 ? 'above' : 'below';

  switch (planType) {
    case PlanTypeOption.OrganicTraffic:
      return `This revision to your organic traffic plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
    case PlanTypeOption.OtherTraffic:
      return `This revision to your other traffic plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
    case PlanTypeOption.BuyBox:
      return `This revision to your buy box rate plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
    case PlanTypeOption.ContentScore:
      return `This revision to your content score plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
    case PlanTypeOption.InStockRate:
      return `This revision to your in-stock rate plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
    case PlanTypeOption.RetailPrice:
      return `This revision to your retail price plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
    case PlanTypeOption.RatingsReviews:
      return `This revision to your reviews plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;

    case PlanTypeOption.PaidTraffic:
      return `This revision to your paid traffic plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
    default:
      return '';
  }
};

export const generateAdjustmentRecordText = ({
  planType,
  adjustmentRecord,
  formatMetric
}: {
  planType: PlanTypeOption;
  adjustmentRecord: AdjustmentLog;
  formatMetric: MetricFormatterFn;
}) => {
  const { adjustmentChangeValue, startWeekId, endWeekId, adSpend, reviewAvgRating, reviewCount } = adjustmentRecord;

  const { formattedStartDate, formattedEndDate } = formatWeekIdsForAdjustmentDisplay({ startWeekId, endWeekId });

  switch (planType) {
    case PlanTypeOption.OrganicTraffic:
      return `${formatMetric(
        adjustmentChangeValue,
        METRICTYPE.VOLUME
      )} total organic traffic per week from ${formattedStartDate} to ${formattedEndDate}`;
    case PlanTypeOption.OtherTraffic:
      return `${formatMetric(
        adjustmentChangeValue,
        METRICTYPE.VOLUME
      )} total other traffic per week from ${formattedStartDate} to ${formattedEndDate}`;
    case PlanTypeOption.BuyBox:
      return `${formatMetric(
        adjustmentChangeValue,
        METRICTYPE.PERCENT
      )} buy box per week from ${formattedStartDate} to ${formattedEndDate}`;
    case PlanTypeOption.ContentScore:
      return `${formatMetric(
        adjustmentChangeValue,
        METRICTYPE.PERCENT
      )} content score per week from ${formattedStartDate} to ${formattedEndDate}`;
    case PlanTypeOption.InStockRate:
      return `${formatMetric(
        adjustmentChangeValue,
        METRICTYPE.PERCENT
      )} in-stock rate per week from ${formattedStartDate} to ${formattedEndDate}`;
    case PlanTypeOption.RetailPrice:
      return `${formatMetric(
        adjustmentChangeValue,
        METRICTYPE.MONEY
      )} retail price per week from ${formattedStartDate} to ${formattedEndDate}`;
    case PlanTypeOption.RatingsReviews:
      return `${formatMetric(
        reviewAvgRating,
        METRICTYPE.DECIMAL
      )} star rating per week from ${formattedStartDate} to ${formattedEndDate}. ${formatMetric(
        reviewCount,
        METRICTYPE.VOLUME
      )} total new reviews per week from ${formattedStartDate} to ${formattedEndDate}.`;

    case PlanTypeOption.PaidTraffic:
      return `${formatMetric(
        adSpend,
        METRICTYPE.MONEY
      )} ad spend per week from ${formattedStartDate} to ${formattedEndDate}`;
    default:
      return '';
  }
};
