import types from './types';

interface OmniGeoMapServiceState {
  [mapName: string]: {
    data: any[];
    isFetching: boolean;
  };
}

const initialState: OmniGeoMapServiceState = {};

const omniGeoMapService = (
  state: OmniGeoMapServiceState = initialState,
  action: { type: string; entity: { mapName: string; data: any[] } }
) => {
  switch (action.type) {
    case types.START_FETCH:
      return {
        ...state,
        [action.entity.mapName]: {
          ...state[action.entity.mapName],
          isFetching: true
        }
      };
    case types.RECEIVE_GEO_MAP_DATA:
      return {
        ...state,
        [action.entity.mapName]: {
          ...state[action.entity.mapName],
          data: action.entity.data
        }
      };
    case types.END_FETCH:
      return {
        ...state,
        [action.entity.mapName]: {
          ...state[action.entity.mapName],
          isFetching: false
        }
      };
    case types.DELETE_DATA: {
      const newData = { ...state };
      delete newData[action.entity.mapName];
      return newData;
    }
    default:
      return state;
  }
};

export default omniGeoMapService;
