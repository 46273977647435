import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Widget } from 'src/types/application/widgetTypes';
import { useAsyncHook } from 'src/utils/Hooks/typedHooks';
import { withRouter } from 'react-router';
import { OmniBaseRequestBody, addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import axios from 'axios';
import Loading from 'src/components/common/Loading';
import './ContentAccuracy.scss';
import _isEmpty from 'lodash/isEmpty';
import { determineTheDefault } from 'src/components/EntityPage/Content/OmniContentAccuracy';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import { decode } from 'html-entities';
import KeywordInput from 'src/components/Omni/OmniContentRewriteKeyword/OmniContentRewriteKeyword';
import { fetchContentDocAsync } from 'src/components/EntityPage/Content/OmniContentCompare';
import { KeywordSuggestionsMap } from 'src/components/Omni/OmniContentRewriteKeyword/KeywordForm';

interface OmniContentRewriteCompareProps extends RouteComponentProps {
  widget: Widget;
  eventBus: EventBus;
}

interface ContentScoreKeywordSuggestion {
  keywordId: number;
  searchTerm: string;
  searchVolume: number;
}

const renderProductTitle = ({ contentCurrent, contentSuggested }: { contentCurrent: any; contentSuggested: any }) => {
  return (
    <div className="accuracy__content">
      <div className="accuracy__master-content">
        <label className="accuracy__label">Product Title</label>
        <div className="accuracy__item">
          <div className="accuracy__item-content">{decode(contentCurrent.title)}</div>
        </div>
      </div>
      <div className="accuracy__current-content">
        <label className="accuracy__label">Product Title</label>
        <div className="accuracy__item accuracy__item--validate true">
          <div className="accuracy__item-content">{decode(contentSuggested.suggestedTitle)}</div>
        </div>
      </div>
    </div>
  );
};

const renderProductDescription = ({
  contentCurrent,
  contentSuggested
}: {
  contentCurrent: any;
  contentSuggested: any;
}) => {
  return (
    <div className="accuracy__content">
      <div className="accuracy__master-content">
        <label className="accuracy__label">Product Description</label>
        <div className="accuracy__item">
          <div className="accuracy__item-description">{decode(contentCurrent.description)}</div>
        </div>
      </div>
      <div className="accuracy__current-content">
        <label className="accuracy__label">Product Description </label>
        <div className="accuracy__item accuracy__item--validate ">
          <div className="accuracy__item-description">{decode(contentSuggested.suggestedDescription)}</div>
        </div>
      </div>
    </div>
  );
};

const renderProductBullets = ({ contentCurrent, contentSuggested }: { contentCurrent: any; contentSuggested: any }) => {
  return (
    <div className="accuracy__content">
      <div className="accuracy__master-content">
        <label className="accuracy__label">Product Bullets</label>
        {contentCurrent.bullets.map((val) => (
          <div key={val} className="accuracy__item">
            <div className="accuracy__bullet">{val}</div>
          </div>
        ))}
      </div>
      <div className="accuracy__current-content">
        <label className="accuracy__label">Product Bullets </label>
        {contentSuggested.suggestedBullets.map((val) => (
          <div key={val} className="accuracy__item">
            <div className="accuracy__bullet">{val}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const renderAccuracyByRetailerSwitch = (retailerId: number, contentCurrent, contentSuggested) => {
  const params = { contentCurrent, contentSuggested };
  switch (retailerId) {
    case 63: // Instacart
      return (
        <>
          {renderProductTitle(params)}
          {renderProductDescription(params)}
        </>
      );

    default:
      return (
        <>
          {renderProductTitle(params)}
          {renderProductDescription(params)}
          {renderProductBullets(params)}
        </>
      );
  }
};

const renderProductComparison = (retailerToUse, contentCurrent, contentSuggested) => {
  return (
    <div className="accuracy__container">
      <div className="accuracy__content">
        <div className="accuracy__master-content">
          <h2 className="sl-header-text">Current Content</h2>
          <hr className="sl-divider sl-divider--no-margin-bottom" />
        </div>
        <div className="accuracy__current-content">
          <h2 className="sl-header-text">Suggested Content</h2>
          <hr className="sl-divider sl-divider--no-margin-bottom" />
        </div>
      </div>
      {/* render accuracy field depending on retailer ID */}
      {renderAccuracyByRetailerSwitch(retailerToUse, contentCurrent, contentSuggested)}
    </div>
  );
};

const fetchContentSuggestedAsync = (requestBody: any) => async () => {
  const res = await axios.post('/omni/content/getSuggestedContent', requestBody);
  if (res && res.status === 200) {
    return res.data;
  } else {
    throw Error('Can not fetch the data');
  }
};

const getKeywordSuggestions = (keywordSuggestions: ContentScoreKeywordSuggestion[]) => () => {
  if (!keywordSuggestions || keywordSuggestions.length === 0) {
    return {};
  }
  const searchTermVolumeMap: KeywordSuggestionsMap = {};
  const sortedKeywords = keywordSuggestions.sort((a, b) => b.searchVolume - a.searchVolume).slice(0, 4);

  sortedKeywords.forEach((keyword) => {
    searchTermVolumeMap[keyword.searchTerm] = keyword.searchVolume;
  });

  return searchTermVolumeMap;
};

const getSortedKeywords = (keywordSuggestions: KeywordSuggestionsMap) => {
  const sortedSearchTerms = Object.keys(keywordSuggestions).sort(
    (a, b) => keywordSuggestions[b] - keywordSuggestions[a]
  );
  return sortedSearchTerms;
};

const OmniContentRewriteCompare: FC<OmniContentRewriteCompareProps> = ({ location, eventBus }) => {
  const filters = useSelector((state: ReduxStore) => state.filters);
  const omniRetailerState = useSelector((state: ReduxStore) => state.omniRetailers);
  const { pathname } = location;
  const defaultRetailer = determineTheDefault(filters, omniRetailerState.data, pathname);
  const [retailerToUse, setRetailerToUse] = useState(defaultRetailer);
  const [titleKeywordList, setTitleKeywords] = useState([]);
  const [descriptionKeywordList, setDescriptionKeywords] = useState([]);
  const [bulletKeywordList, setBulletKeywords] = useState([]);
  const [titleCharCount, setTitleCharCount] = useState(0);
  const [bulletCharCount, setBulletCharCount] = useState(0);
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  const [contentVoice, setContentVoice] = useState('');
  const [titleKeywordSuggestions, setTitleKeywordSuggestions] = useState({});
  const [descriptionKeywordSuggestions, setDescriptionKeywordSuggestions] = useState({});
  const [bulletsKeywordSuggestions, setBulletsKeywordSuggestions] = useState({});

  const { data: contentCurrent, run: runCurrent, loading: currentLoading } = useAsyncHook<{ [key: string]: any }>({});
  const { data: contentScore, run: runScore } = useAsyncHook<{ [key: string]: any }>({});

  const {
    data: contentSuggested,
    run: runSuggested,
    loading: suggestedLoading
  } = useAsyncHook<{ [key: string]: any }>({});

  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const { startWeek, endWeek } = mainTimePeriod;

  const handleRetailerDropDown = ({ retailerSelected }: { retailerSelected: number }) => {
    setRetailerToUse(retailerSelected);
  };

  useEffect(() => setRetailerToUse(defaultRetailer), [defaultRetailer]);

  useEffect(() => {
    eventBus.on('omniDropDownSelected', handleRetailerDropDown);
    const baseBody: OmniBaseRequestBody = {
      productIds: [],
      retailerIds: [],
      docType: 'contentScore',
      startWeekId: startWeek,
      endWeekId: endWeek
    };
    const requestBody = addFilterToOmniBaseReqBody(baseBody, filters, pathname);
    baseBody.retailerIds = [retailerToUse];
    runScore(fetchContentDocAsync(requestBody));
    requestBody.docType = 'currentContent';
    runCurrent(fetchContentDocAsync(requestBody));
    return () => {
      eventBus.off('omniDropDownSelected', handleRetailerDropDown);
    };
  }, [pathname, filters, startWeek, endWeek, retailerToUse, eventBus]);

  useEffect(() => {
    if (contentScore) {
      const titleSuggestions = getKeywordSuggestions(contentScore.titleKeywordSuggestions);
      const bulletSuggestions = getKeywordSuggestions(contentScore.bulletKeywordSuggestions);
      const descriptionSuggestions = getKeywordSuggestions(contentScore.descriptionKeywordSuggestions);

      setTitleKeywordSuggestions(titleSuggestions);
      setBulletsKeywordSuggestions(bulletSuggestions);
      setDescriptionKeywordSuggestions(descriptionSuggestions);
    }
  }, [contentScore]);

  useEffect(() => {
    if (contentCurrent && titleKeywordList.length > 0) {
      runSuggested(
        fetchContentSuggestedAsync({
          currentContent: contentCurrent,
          titleKeywords:
            titleKeywordList[0] !== ''
              ? titleKeywordList.concat(getSortedKeywords(titleKeywordSuggestions))
              : getSortedKeywords(titleKeywordSuggestions),
          descriptionKeywords:
            descriptionKeywordList[0] !== ''
              ? descriptionKeywordList.concat(getSortedKeywords(descriptionKeywordSuggestions))
              : getSortedKeywords(descriptionKeywordSuggestions),
          bulletKeywords:
            bulletKeywordList[0] !== ''
              ? bulletKeywordList.concat(getSortedKeywords(bulletsKeywordSuggestions))
              : getSortedKeywords(bulletsKeywordSuggestions),
          titleCharCount,
          bulletCharCount,
          descriptionCharCount,
          contentVoice
        })
      );
    }
  }, [
    contentCurrent,
    titleCharCount,
    bulletCharCount,
    descriptionCharCount,
    titleKeywordList,
    bulletKeywordList,
    descriptionKeywordList,
    contentVoice
  ]);

  const assignKeywords = (
    titleKeywords,
    descriptionKeywords,
    bulletKeywords,
    titleCount,
    bulletCount,
    descriptionCount,
    contentVoiceInput
  ) => {
    setTitleKeywords(titleKeywords);
    setBulletKeywords(bulletKeywords);
    setDescriptionKeywords(descriptionKeywords);
    setTitleCharCount(titleCount);
    setBulletCharCount(bulletCount);
    setDescriptionCharCount(descriptionCount);
    setContentVoice(contentVoiceInput);
  };

  return (
    <div>
      {KeywordInput(assignKeywords, titleKeywordSuggestions, bulletsKeywordSuggestions, descriptionKeywordSuggestions)}
      {currentLoading || suggestedLoading || _isEmpty(contentCurrent) || _isEmpty(contentSuggested) ? (
        <Loading className="spinner" />
      ) : (
        <>
          <div className="accuracy">
            <div className="accuracy__title">Content Rewrite</div>
          </div>

          {renderProductComparison(retailerToUse, contentCurrent, contentSuggested)}
        </>
      )}
    </div>
  );
};

export default withRouter(withBus('eventBus')(OmniContentRewriteCompare));
