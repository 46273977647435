import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useStacklineTheme, AppImage, brandclub_colors } from '@stackline/ui';
import mergeSx from 'src/utils/mergeSx';

interface FilterSearchInputProps {
  input: string;
  handleChange: (s) => void;
  placeholderText?: string;
  sx?: TextFieldProps['sx'];
}

export const FilterSearchInput = ({ input, handleChange, placeholderText = '', sx }: FilterSearchInputProps) => {
  const theme = useStacklineTheme();
  return (
    <TextField
      variant="standard"
      value={input}
      onChange={(event) => handleChange(event.target.value)}
      InputProps={{
        placeholder: placeholderText,
        disableUnderline: true,
        startAdornment: (
          <AppImage
            src="/svg/icons/search.svg"
            alt="search-icon"
            style={{
              marginRight: 8,
              height: 13,
              width: 13
            }}
          />
        ),
        autoComplete: 'off',
        id: 'type-search'
      }}
      sx={mergeSx(
        {
          borderRadius: 17,
          border: `1px solid ${theme.colors.primaryGray}`,
          height: 30,
          width: 290,
          backgroundColor: brandclub_colors.white,
          '& div.MuiInput-root': {
            padding: '0 16px',
            display: 'flex',
            alignItems: 'center',
            height: 30,
            '& input': {
              padding: 0,
              fontSize: '12px',
              fontFamily: 'Roboto',
              color: theme.colors.primary,

              '&::placeholder': {
                color: theme.colors.secondary
              }
            }
          }
        },
        sx
      )}
    />
  );
};
