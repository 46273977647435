/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import { IntersectionObserverProps, InView, PlainChildrenProps } from 'react-intersection-observer';
import _merge from 'lodash/merge';
import { AppName } from 'sl-api-connector';
import { getAppName } from 'src/utils/app';
import { track } from 'src/utils/mixpanel';

export interface ComponentInViewProps {
  id: string;
  appName?: AppName[];
}

// https://www.npmjs.com/package/react-intersection-observer
/**
 * track element visible in the viewport
 *
 * usage:
 * - wrap the element inside ComponentInView and pass an identifier (id)
 * - optionally pass AppName[] to track only for those apps
 */
const ComponentInView: FC<ComponentInViewProps & (IntersectionObserverProps | PlainChildrenProps)> = ({
  id,
  appName,
  root,
  rootMargin,
  threshold,
  triggerOnce,
  children,
  ...rest
}) => {
  const defaultObserverOptions: IntersectionObserverInit = {
    root: null,
    rootMargin: '65px', // header offset
    threshold: 0.2
  };
  const mergedObserverOptions = _merge(defaultObserverOptions, { root, rootMargin, threshold });

  const handleInView = (inView: boolean) => {
    if (inView && (!appName || appName.includes(getAppName()))) {
      track('viewing component', { componentId: id });
    }
  };

  return (
    <InView
      as="span"
      id={id}
      onChange={handleInView}
      triggerOnce={triggerOnce === undefined ? true : triggerOnce}
      fallbackInView={false}
      {...mergedObserverOptions}
      {...rest}
    >
      {children}
    </InView>
  );
};

export default ComponentInView;
