import { AD_CAMPAIGN_STATUS, AD_STATUS_DERIVED } from 'sl-ad-campaign-manager-data-model';
import { Dispatch } from 'react';
import { Widget } from 'src/types/application/widgetTypes';
import { RangeFilter, TermFilter } from 'sl-api-connector/types';
import { CancelTokenSource } from 'axios';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import { CampaignsForMainEntityProduct } from 'src/components/AdManager/Search/GridDataFetchers/Campaigns/CampaignsForMainEntityProduct';
import { CampaignsForMainEntityTarget } from 'src/components/AdManager/Search/GridDataFetchers/Campaigns/CampaignsForMainEntityTarget';
import { CampaignsForAdditionalTargets } from 'src/components/AdManager/Search/GridDataFetchers/Campaigns/CampaignsForAdditionalTargets';
import {
  CampaignIdToChildDocumentsMap,
  ICampaignChildDocument,
  ICampaignIdMapItem
} from 'src/components/AdManager/Search/GridDataFetchers/Campaigns/CampaignIdToChildDocumentsMap';

export async function getTargetOrProductDocumentsByCampaignIdMap(
  widget: Widget,
  dispatch: Dispatch<any>,
  mainEntity: any,
  retailer: any,
  app: any,
  queryConditions: ISearchFilterConditions,
  searchSideBarConditions: ISearchFilterConditions,
  cancelSource: CancelTokenSource,
  onFirstPageAndCount: boolean,
  indexName: any,
  aggregationFieldsForResultCount: any,
  mainTimePeriodRangeFilters: RangeFilter[],
  aggregationFilters: ISearchFilterConditions,
  sortDirection: string | undefined,
  currentSortFieldName: any,
  scheduledActionTermFilters: TermFilter[],
  customTermFilters: any,
  pageNumber: number,
  cloneResult: any
): Promise<CampaignIdToChildDocumentsMap> {
  let campaignIdToChildDocumentsMap: CampaignIdToChildDocumentsMap = null;

  if (widget.data.fetchAdditionalCampaignTargets) {
    const additionalTargetsFetcher = new CampaignsForAdditionalTargets();
    campaignIdToChildDocumentsMap = await additionalTargetsFetcher.fetchAdditionalCampaignTargets(
      dispatch,
      mainEntity,
      retailer,
      app,
      queryConditions,
      searchSideBarConditions,
      cancelSource
    );
  }

  if (mainEntity && mainEntity.type === 'product') {
    const campaignsForMainEntityProduct = new CampaignsForMainEntityProduct();
    campaignIdToChildDocumentsMap = await campaignsForMainEntityProduct.forMainEntityProduct(
      onFirstPageAndCount,
      dispatch,
      mainEntity,
      retailer,
      app,
      indexName,
      widget,
      aggregationFieldsForResultCount,
      mainTimePeriodRangeFilters,
      aggregationFilters,
      sortDirection,
      currentSortFieldName,
      searchSideBarConditions,
      scheduledActionTermFilters,
      customTermFilters,
      pageNumber,
      cancelSource,
      cloneResult,
      queryConditions
    );
  }

  if (mainEntity && mainEntity.type === 'adTarget') {
    const campaignsForMainEntityTarget = new CampaignsForMainEntityTarget();
    campaignIdToChildDocumentsMap = await campaignsForMainEntityTarget.forMainEntityTarget(
      dispatch,
      mainEntity,
      retailer,
      app,
      searchSideBarConditions,
      queryConditions,
      cancelSource
    );
  }
  return campaignIdToChildDocumentsMap;
}

function createEmptyEntry(adChildDocument: ICampaignChildDocument): ICampaignIdMapItem {
  return {
    beaconClientId: adChildDocument.beaconClientId,
    platformType: adChildDocument.platformType,
    extendedAttributes: {
      statusReason: '',
      status: AD_CAMPAIGN_STATUS.PAUSED,
      statusDerived: AD_STATUS_DERIVED.PAUSED,
      childDocuments: []
    }
  };
}

/**
 * Add child document (target or product) to the adChildDocumentsByCampaignId map if an entry already exists
 * Else add an empty entry to the map and then add the child document
 * @param adChildDocumentsByCampaignId The map to add/update the entry
 * @param adChildDocument The child document to add
 */
function addChildDocumentToDocumentsByCampaignIdMap(
  adChildDocumentsByCampaignId: CampaignIdToChildDocumentsMap,
  adChildDocument: ICampaignChildDocument
) {
  const { campaignId } = adChildDocument;
  if (!adChildDocumentsByCampaignId[campaignId]) {
    adChildDocumentsByCampaignId[campaignId] = createEmptyEntry(adChildDocument);
  }
  const childDocumentsForCampaign = adChildDocumentsByCampaignId[campaignId];
  const { extendedAttributes } = childDocumentsForCampaign;
  if (adChildDocument.status === AD_CAMPAIGN_STATUS.ENABLED) {
    extendedAttributes.status = AD_CAMPAIGN_STATUS.ENABLED;
  }
  if (adChildDocument.statusDerived === AD_STATUS_DERIVED.DELIVERING) {
    extendedAttributes.statusDerived = AD_STATUS_DERIVED.DELIVERING;
  }
  extendedAttributes.childDocuments.push(adChildDocument);
}

export function constructNewChildDocumentsMapByCampaignId(
  campaignChildDocuments: ICampaignChildDocument[],
  childType: string = null
): CampaignIdToChildDocumentsMap {
  const adChildDocumentsByCampaignId = {} as CampaignIdToChildDocumentsMap;
  campaignChildDocuments.forEach((childDocument: any) => {
    if (childType) {
      childDocument.type = childType;
    }
    addChildDocumentToDocumentsByCampaignIdMap(adChildDocumentsByCampaignId, childDocument);
  });
  return adChildDocumentsByCampaignId;
}
