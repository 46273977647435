/* eslint-disable react/display-name */
import React, { CSSProperties, Ref } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { StacklineTheme, useStacklineTheme } from '@stackline/ui';
import { SelectedExcludeCheckbox, SelectedIncludeCheckbox } from 'src/components/SvgIcons/SvgIcons';

interface StyledCheckboxInnerProps {
  stacklineTheme: StacklineTheme;
}

const StyledCheckboxInner = styled(Checkbox)<StyledCheckboxInnerProps>(({ stacklineTheme }) => ({
  padding: '0px',
  '&, &.Mui-checked': {
    color: stacklineTheme.colors.primary
  },
  '& .MuiSvgIcon-root': {
    width: '12px',
    height: '12px'
  },
  '& .MuiCheckbox-root .Mui-checked': {
    // Add additional styles for checked state if needed
  }
}));

/**
 * Default checkbox for Beacon
 */
const StyledCheckbox = React.forwardRef(
  (
    {
      filter = 'include',
      disabled = false,
      iconProps = {},
      ...props
    }: React.ComponentProps<typeof Checkbox> & { filter?: string; disabled?: boolean; iconProps?: CSSProperties },

    ref: Ref<HTMLButtonElement>
  ): JSX.Element => {
    const theme = useStacklineTheme();

    // Define checked and unchecked icons
    const checkedIcon =
      filter === 'exclude' ? (
        <SelectedExcludeCheckbox style={{ width: '12px', height: '12px', ...iconProps }} />
      ) : (
        <SelectedIncludeCheckbox style={{ width: '12px', height: '12px', ...iconProps }} />
      );

    const icon = (
      <div
        style={{
          width: '12px',
          height: '12px',
          border: disabled ? '1px solid #ededf1' : '1px solid #9ea9b5',
          backgroundColor: '#fff',
          borderRadius: '2px',
          ...iconProps
        }}
      ></div>
    );

    return (
      <StyledCheckboxInner
        disableRipple
        disableFocusRipple
        ref={ref}
        stacklineTheme={theme}
        checkedIcon={checkedIcon}
        icon={icon}
        {...props}
      />
    );
  }
);

export default StyledCheckbox;
