import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import _identity from 'lodash/identity';
import { ExportIcon, ContentCurrentIcon, InventoryIcon, CompareScaleIcon } from 'src/components/SvgIcons';
import PageContextMenu from 'src/components/PageContextMenu';
import getBeaconPrimePageLayout from './Beacon/BeaconPrimePageLayout';
import getSummaryPageLayout from './SummaryPageLayout';
import getSalesPageLayout from './Beacon/SalesPageLayout';
import getWaterfallPageLayout from './Beacon/WaterfallPageLayout';
import getCompliancePageLayout from './Beacon/CompliancePageLayout';
import getTrafficPageLayout from './Beacon/TrafficPageLayout/TrafficPageLayout';
import getConversionPageLayout from './Beacon/ConversionPageLayout';
import getOperationsPageLayout from './Beacon/OperationsPageLayout/OperationsPageLayout';
import { flattenWidgets } from 'src/components/Layout/LayoutUtil';
import { shouldShowNewBeacon } from 'src/utils/app';
import getNewBeaconKeyMetricsLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/getNewBeaconKeyMetricsLayout';

const getPageLayoutGetter = (name) => {
  return (
    {
      compliance: getCompliancePageLayout,
      summaryPage: getSummaryPageLayout,
      keymetrics: getBeaconPrimePageLayout,
      traffic: getTrafficPageLayout,
      waterfall: getWaterfallPageLayout,
      conversion: getConversionPageLayout,
      sales: getSalesPageLayout,
      operations: getOperationsPageLayout
    }[name] || getSalesPageLayout
  );
};

const getLayoutName = (pageType, tab, metricType) => {
  // summary page
  if (pageType === 'summaryPage') {
    return pageType;
  }

  // compliance pages
  if (tab === 'compliance') {
    return tab;
  }

  // prime/basic page and waterfall page
  if (['keymetrics', 'waterfall'].includes(metricType)) {
    return metricType;
  }

  // conversion pages
  if (
    (tab === 'sales' && ['retailPrice', 'mapPrice', 'inventory', 'inactive'].includes(metricType)) ||
    ['content', 'buybox', 'reviews'].includes(tab)
  ) {
    return 'conversion';
  }

  // sales page
  if (tab === 'sales' && ['retailSales', 'unitsSold', 'margin', 'returns'].includes(metricType)) {
    return 'sales';
  }

  return tab;
};

const shouldEnableExport = (metricType, user, flattenedWidgets, queryParams) => {
  const lastWidgetView = _get(_last(flattenedWidgets), 'view');
  const failConditions = [!user.config.exportEnabled, _isEmpty(flattenedWidgets), !lastWidgetView];

  // Beacon does not support multi retailer exports.
  if (queryParams.rid === '0') {
    return false;
  }

  if (failConditions.find(_identity)) {
    return false;
  }

  if (['reviewsGrid', 'weeklyMetricsGridContainer', 'promotionsGrid', 'entityGrid'].includes(lastWidgetView.name)) {
    return true;
  }

  return _get(lastWidgetView.gridOptions, 'enableExport', false);
};

const getNewBeaconPage = (name) => {
  return {
    keymetrics: getNewBeaconKeyMetricsLayout
  }[name];
};

function getLayoutForEntity({
  app,
  retailer,
  user,
  tab,
  metricType,
  entity,
  pageType,
  filters,
  entityType,
  selectedEntityName
}) {
  // Beacon Redesign Logic
  const showNewBeacon = shouldShowNewBeacon();
  let getPageLayout;

  const layoutName = getLayoutName(pageType, tab, metricType);

  // ! TODO: Expand this functionality to all pages or refactor to a better system
  // Only show the new keymetrics layout for now
  if (showNewBeacon && layoutName === 'keymetrics') {
    getPageLayout = getNewBeaconPage(layoutName);
  } else {
    getPageLayout = getPageLayoutGetter(layoutName);
  }

  const pageLayout = getPageLayout({
    app,
    retailer,
    user,
    tab,
    metricType,
    entity,
    filters,
    entityType,
    selectedEntityName
  });
  const { widgets, enableComparison, CustomPageContainer, dataConfig, ...rest } = pageLayout;
  const { queryParams } = app;
  const flattenedWidgets = flattenWidgets(widgets);
  const enableExport = shouldEnableExport(metricType, user, flattenedWidgets, queryParams);

  const enableContentDownload =
    queryParams.tab === 'content' && ['contentAccuracy'].includes(queryParams.subtab) && enableExport;

  if (enableComparison || enableExport || enableContentDownload) {
    const subMenuItems = [];
    if (enableExport) {
      subMenuItems.push({
        icon: ExportIcon,
        text: enableContentDownload ? 'Download Content Accuracy' : 'Add to Download List',
        eventName: 'downloadEntityGrid'
      });
    }

    if (enableContentDownload) {
      subMenuItems.push({
        icon: InventoryIcon,
        text: 'Download Master Content',
        eventName: 'downloadContentCombine'
      });

      subMenuItems.push({
        icon: ContentCurrentIcon,
        text: 'Download Live Content',
        eventName: 'downloadCurrentContent'
      });
    }

    if (enableComparison) {
      subMenuItems.push({
        icon: CompareScaleIcon,
        text: 'Compare',
        eventName: 'showCompare'
      });
    }

    widgets.push({
      CustomComponent: PageContextMenu,
      name: 'pageContextMenu',
      view: {
        name: 'pageContextMenu',
        buttons: subMenuItems
      }
    });
  }

  return {
    CustomPageContainer,
    enableComparison,
    widgets,
    dataConfig,
    containerStyle: { marginTop: 60 },
    ...rest
  };
}

export default { getLayoutForEntity };
