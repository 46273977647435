import React from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import Switch from '@mui/material/Switch';
import { store } from 'src/main';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import ReduxStore from 'src/types/store/reduxStore';
import { SectionWrapper, OperationButtons } from '../Widgets/AdCampaignBuilderCommonWidgets';
import 'src/components/Layout/Advertising/AdManagerPageLayout/AdManagerSettings.scss';

export const Walmart_Bid_Location = {
  placements: {
    BUY_BOX: 'Buy-Box',
    SEARCH_IN_GRID: 'Search Ingrid',
    HOME_PAGE: 'Home Page',
    STOCK_UP: 'Stock Up'
  },
  platforms: {
    DESKTOP: 'Desktop',
    MOBILE: 'Mobile',
    APP: 'App'
  }
};

const defaultMultifiers = {
  placements: [
    {
      placement: 'Search Carousel',
      status: 'included'
    },
    {
      placement: 'Item Buybox',
      status: 'included'
    },
    {
      placement: 'Item Carousel',
      status: 'included'
    }
  ],
  placementBidMultipliers: [
    {
      bidLocation: 'Buy-Box',
      multiplier: 0
    },
    {
      bidLocation: 'Search Ingrid',
      multiplier: 0
    },
    {
      bidLocation: 'Home Page',
      multiplier: 0
    },
    {
      bidLocation: 'Stock Up',
      multiplier: 0
    }
  ],
  platformBidMultipliers: [
    {
      bidLocation: Walmart_Bid_Location.platforms.DESKTOP,
      multiplier: 0
    },
    {
      bidLocation: Walmart_Bid_Location.platforms.MOBILE,
      multiplier: 0
    },
    {
      bidLocation: Walmart_Bid_Location.platforms.APP,
      multiplier: 0
    }
  ]
};

export const MULTIPLIERS_MAX_VAL = 900;
const mapStateToProps = (state: ReduxStore) => state;

const AdditionalSettings: React.FC<{} & ReturnType<typeof mapStateToProps>> = ({ ...reduxState }) => {
  const baseAdCampaignBuilderState = _cloneDeep(reduxState.adCampaignBuilder);

  const {
    target: { targetingTypeId }
  } = baseAdCampaignBuilderState;

  const bidMultifiers = _get(baseAdCampaignBuilderState, ['bidMultifiers'], defaultMultifiers);
  const isAutoTargeting = targetingTypeId === 'autoTargeting';

  const handleBidMultifiersChange = (newBidMultifiers: any) => {
    store.dispatch(
      adCampaignBuilderActions.replaceState({
        ...baseAdCampaignBuilderState,
        bidMultifiers: newBidMultifiers
      })
    );
  };

  const handlePlacementsChange = (name: string, value: string) => {
    const newPlacement = _cloneDeep(bidMultifiers.placements) as any[];
    if (newPlacement.find((item) => item.placement === name)) {
      newPlacement.forEach((item) => {
        if (item.placement === name) {
          item.status = value;
        }
      });
    } else {
      newPlacement.push({
        placement: name,
        status: value
      });
    }
    const newBidMultifiers = {
      ...bidMultifiers,
      placements: newPlacement
    };
    handleBidMultifiersChange(newBidMultifiers);
  };

  const handlePlacementBidMultipliersChange = (name: string, value: number) => {
    const newPlacementBidMultipliers = _cloneDeep(bidMultifiers.placementBidMultipliers) as any[];
    if (newPlacementBidMultipliers.find((item) => item.bidLocation === name)) {
      newPlacementBidMultipliers.forEach((item) => {
        if (item.bidLocation === name) {
          item.multiplier = value;
        }
      });
    } else {
      newPlacementBidMultipliers.push({
        bidLocation: name,
        multiplier: value
      });
    }
    const newBidMultifiers = {
      ...bidMultifiers,
      placementBidMultipliers: newPlacementBidMultipliers
    };
    handleBidMultifiersChange(newBidMultifiers);
  };

  const handlePlatformBidMultipliersChange = (name: string, value: number) => {
    const newPlatformBidMultipliers = _cloneDeep(bidMultifiers.platformBidMultipliers) as any[];
    if (newPlatformBidMultipliers.find((item) => item.bidLocation === name)) {
      newPlatformBidMultipliers.forEach((item) => {
        if (item.bidLocation === name) {
          item.multiplier = value;
        }
      });
    } else {
      newPlatformBidMultipliers.push({
        bidLocation: name,
        multiplier: value
      });
    }
    const newBidMultifiers = {
      ...bidMultifiers,
      platformBidMultipliers: newPlatformBidMultipliers
    };
    handleBidMultifiersChange(newBidMultifiers);
  };

  const placementBidMultipliersOpt: string[] = isAutoTargeting
    ? [
        Walmart_Bid_Location.placements.BUY_BOX,
        Walmart_Bid_Location.placements.HOME_PAGE,
        Walmart_Bid_Location.placements.SEARCH_IN_GRID,
        Walmart_Bid_Location.placements.STOCK_UP
      ]
    : [Walmart_Bid_Location.placements.SEARCH_IN_GRID, Walmart_Bid_Location.placements.BUY_BOX];
  const platformBidMultipliersOpt: string[] = [
    Walmart_Bid_Location.platforms.DESKTOP,
    Walmart_Bid_Location.platforms.MOBILE,
    Walmart_Bid_Location.platforms.APP
  ];

  const searchCarouselChecked = _get(bidMultifiers, ['placements', 0, 'status']) === 'included';
  const itemBuyboxChecked = _get(bidMultifiers, ['placements', 1, 'status']) === 'included';
  const itemCarouselChecked = _get(bidMultifiers, ['placements', 2, 'status']) === 'included';

  return (
    <div className="ad-manager-container">
      {!isAutoTargeting && (
        <SectionWrapper
          header="Campaign Placement"
          subheader="Please select additional placements to show your ad"
          layer={0}
        >
          <div className="setting_container">
            <br />
            <div className="setting_row" style={{ marginBottom: 20 }}>
              <div className="label">Search Carousel:</div>
              <div>
                <Switch
                  checked={searchCarouselChecked}
                  onClick={() => {
                    const newVal = !searchCarouselChecked ? 'included' : 'excluded';
                    handlePlacementsChange('Search Carousel', newVal);
                  }}
                  color="primary"
                />
              </div>
            </div>
            <div className="setting_row" style={{ marginBottom: 20 }}>
              <div className="label">Item Buybox:</div>
              <div>
                <Switch
                  checked={itemBuyboxChecked}
                  onClick={() => {
                    const newVal = !itemBuyboxChecked ? 'included' : 'excluded';
                    handlePlacementsChange('Item Buybox', newVal);
                  }}
                  color="primary"
                />
              </div>
            </div>
            <div className="setting_row" style={{ marginBottom: 20 }}>
              <div className="label">Item Carousel:</div>
              <div>
                <Switch
                  checked={itemCarouselChecked}
                  onClick={() => {
                    const newVal = !itemCarouselChecked ? 'included' : 'excluded';
                    handlePlacementsChange('Item Carousel', newVal);
                  }}
                  color="primary"
                />
              </div>
            </div>
            <br />
          </div>
        </SectionWrapper>
      )}
      <SectionWrapper
        header="Bid Multipliers"
        subheader="In addition to your bidding strategy, you can increase bids by up to 900% for specific placements."
        layer={0}
      >
        <div className="setting_container">
          <br />
          {placementBidMultipliersOpt.map((opt, idx) => {
            const current = bidMultifiers.placementBidMultipliers.find((item: any) => item.bidLocation === opt);
            const value = _get(current, ['multiplier'], 0);
            return (
              <div key={idx} className="setting_row" style={{ marginBottom: 20 }}>
                <div className="label">{opt}:</div>
                <div>
                  <NumberFormat
                    value={value}
                    thousandSeparator
                    suffix="%"
                    decimalScale={2}
                    fixedDecimalScale
                    allowNegative={false}
                    isAllowed={({ floatValue }) => {
                      return floatValue <= MULTIPLIERS_MAX_VAL || floatValue === undefined;
                    }}
                    onValueChange={({ floatValue }) => handlePlacementBidMultipliersChange(opt, floatValue)}
                    // Mimic the style of the MUI `TextInput`
                    style={{
                      height: '32px',
                      outline: 'none',
                      border: '0',
                      borderBottomColor: 'currentcolor',
                      borderBottomStyle: 'none',
                      borderBottomWidth: '0px',
                      borderBottom: '1px solid #eee',
                      width: '120px',
                      fontSize: '16px',
                      color: 'currentColor',
                      fontFamily: 'Roboto'
                    }}
                  />
                </div>
              </div>
            );
          })}
          <br />
        </div>
      </SectionWrapper>
      <SectionWrapper header="Platform Multipliers" subheader="Update platform multipliers." layer={0}>
        <div className="setting_container">
          <br />
          {platformBidMultipliersOpt.map((opt, idx) => {
            const current = bidMultifiers.platformBidMultipliers.find((item: any) => item.bidLocation === opt);
            const value = _get(current, ['multiplier'], 0);
            return (
              <div key={idx} className="setting_row" style={{ marginBottom: 20 }}>
                <div className="label">{opt}:</div>
                <div>
                  <NumberFormat
                    value={value}
                    thousandSeparator
                    suffix="%"
                    decimalScale={2}
                    fixedDecimalScale
                    allowNegative={false}
                    isAllowed={({ floatValue }) => {
                      return floatValue <= MULTIPLIERS_MAX_VAL || floatValue === undefined;
                    }}
                    onValueChange={({ floatValue }) => handlePlatformBidMultipliersChange(opt, floatValue)}
                    // Mimic the style of the MUI `TextInput`
                    style={{
                      height: '32px',
                      outline: 'none',
                      border: '0',
                      borderBottomColor: 'currentcolor',
                      borderBottomStyle: 'none',
                      borderBottomWidth: '0px',
                      borderBottom: '1px solid #eee',
                      width: '120px',
                      fontSize: '16px',
                      color: 'currentColor',
                      fontFamily: 'Roboto'
                    }}
                  />
                </div>
              </div>
            );
          })}
          <br />
        </div>
      </SectionWrapper>
      <OperationButtons
        subtab="additionalSettings"
        canContinue
        onBeforeLeave={() => {
          store.dispatch(
            adCampaignBuilderActions.replaceState({
              ...baseAdCampaignBuilderState,
              bidMultifiers
            })
          );
          return true;
        }}
      />
    </div>
  );
};

const EnhancedConfirmation = connect(mapStateToProps)(AdditionalSettings);

export default EnhancedConfirmation;
