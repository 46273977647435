import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { useAdvancedSearchRequestBuilder } from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import { useMemo } from 'react';
import { useAppSelector, useQueryParamValue } from 'src/utils/Hooks';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import {
  ProductSalesResponse,
  ProductTrafficResponse,
  ProductGridForecastData,
  ProductGridUnadjustedForecastResponse,
  ProductGridAdjustedForecastResponse,
  ProductSalesDatum,
  ProductGridAdjustedForecastResponseAdditionalValues
} from './types';
import { parseProductSalesResponse } from './responseParsers';
import {
  useForecastComparisonStartAndEndWeekIds,
  useForecastPeriod,
  useForecastStartAndEndWeekIds,
  useLatestForecastModel,
  useYtdStartAndEndWeekIdsForForecast
} from './hooks';
import { UseQueryResult } from 'react-query';
import { ForecastPeriod, ForecastType } from '../types';
import {
  useBaseMetricRequestBuilder,
  useApplyEntityFilters
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';

interface UseProductGridForecastedDataProps {
  page: number;
  pageSize: number;
}
/**
 * Returns the forecasted metrics for a list of products
 */
export const useProductGridForecastedData = ({
  page,
  pageSize
}: UseProductGridForecastedDataProps): UseQueryResult<ProductGridForecastData> => {
  const applyEntityFilters = useApplyEntityFilters();
  const { endWeekId: forecastEndWeekId, startWeekId: forecastStartWeekId } = useForecastStartAndEndWeekIds();
  const requestId = 'forecastProductGrid';
  const unadjustedForecastRequestBuilder = useAdvancedSearchRequestBuilder(requestId, 'beacon-unadjusted-forecast');
  const adjustedForecastRequestBuilder = useAdvancedSearchRequestBuilder(requestId, 'beacon-adjusted-forecast');
  const forecastType = useQueryParamValue('forecastType', ForecastType.ADJUSTED);
  const { modelVersion, loading, currentPublishVersion } = useLatestForecastModel();

  const categoryIds = useAppSelector((state) => state.entityService.mainEntity.categoryIds);
  const retailerId = useAppSelector((state) => state.retailer.id);

  const unadjustedForecastRequestBody = useMemo(() => {
    const aggregationBuilder = new AggregationBuilder('stacklineSku')
      .addAggregationField('Retail Sales', 'retailSales', 'sum', true)
      .addAggregationField('Units Sold', 'unitsSold', 'sum', true)
      .addAggregationField('Total Traffic', 'totalTraffic', 'sum', true)
      .addConditionTermFilter('retailerId', [retailerId])
      .setSortByAggregationField('Retail Sales', 'retailSales', 'sum', true)
      .setSortDirection('desc')
      .addConditionRangeFilter('forecastWeekId', forecastStartWeekId, forecastEndWeekId);

    unadjustedForecastRequestBuilder
      .setPeriod('year')
      .setPageNumber(page)
      .setPageSize(pageSize)
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .setProcessDocuments(true)
      .addConditionTermFilter('categoryId', 'should', categoryIds)
      .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
      .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
      .addAggregation(aggregationBuilder.build())
      .addSortField('Retail Sales', 'retailSales', 'sum', true)
      .setSearchBy('parent')
      .apply(applyEntityFilters);

    return unadjustedForecastRequestBuilder.build();
  }, [
    retailerId,
    forecastStartWeekId,
    forecastEndWeekId,
    unadjustedForecastRequestBuilder,
    page,
    pageSize,
    categoryIds,
    modelVersion,
    currentPublishVersion,
    applyEntityFilters
  ]);

  const adjustedForecastRequestBody = useMemo(() => {
    const aggregationBuilder = new AggregationBuilder('stacklineSku')
      .addAggregationField('Retail Sales', 'retailSalesDelta', 'sum', true)
      .addAggregationField('Units Sold', 'unitsSoldDelta', 'sum', true)
      .addAggregationField('Total Traffic', 'totalTrafficDelta', 'sum', true)
      .addConditionTermFilter('retailerId', [retailerId])
      .setSortByAggregationField('Retail Sales', 'retailSalesDelta', 'sum', true)
      .setSortDirection('desc')
      .addConditionRangeFilter('forecastWeekId', forecastStartWeekId, forecastEndWeekId);

    adjustedForecastRequestBuilder
      .setPeriod('year')
      .setPageNumber(page)
      .setPageSize(pageSize)
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .setProcessDocuments(true)
      .addConditionTermFilter('categoryId', 'should', categoryIds)
      .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
      .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
      .addAggregation(aggregationBuilder.build())
      .addSortField('Retail Sales', 'retailSalesDelta', 'sum', true)
      .setSearchBy('parent')
      .apply(applyEntityFilters);

    return adjustedForecastRequestBuilder.build();
  }, [
    retailerId,
    forecastStartWeekId,
    forecastEndWeekId,
    adjustedForecastRequestBuilder,
    page,
    pageSize,
    categoryIds,
    modelVersion,
    currentPublishVersion,
    applyEntityFilters
  ]);

  const requestBody = useMemo(() => {
    return [
      unadjustedForecastRequestBody,
      ...(forecastType === ForecastType.ADJUSTED ? [adjustedForecastRequestBody] : [])
    ];
  }, [unadjustedForecastRequestBody, forecastType, adjustedForecastRequestBody]);

  const { data: productGridForecastResponse, ...rest } = useGenericAdvancedSearch<
    [ProductGridUnadjustedForecastResponse, ProductGridAdjustedForecastResponse]
  >({
    requestId,
    queryKeys: requestBody,
    requestBody,
    shouldPerformFetch: !loading
  });

  return useMemo(() => {
    // Create a map from fieldId to the adjusted forecasted deltas
    const stacklineSkuToAdjustedForecastDeltas: Record<string, ProductGridAdjustedForecastResponseAdditionalValues> =
      productGridForecastResponse && productGridForecastResponse.data && productGridForecastResponse.data[1]
        ? productGridForecastResponse.data[1].aggregations.by_stacklineSku.reduce(
            (acc, { fieldId, additionalValues }) => {
              return {
                ...acc,
                [fieldId]: {
                  ...additionalValues
                }
              };
            },
            {} as Record<string, ProductGridAdjustedForecastResponseAdditionalValues>
          )
        : {};

    const productGridForecastData: ProductGridForecastData =
      productGridForecastResponse && productGridForecastResponse.data
        ? productGridForecastResponse.data[0].aggregations.by_stacklineSku.reduce(
            (acc, { fieldId, additionalValues, additionalMetaData }) => {
              const deltas = stacklineSkuToAdjustedForecastDeltas[fieldId] || {
                retailSalesDelta_sum_value: 0,
                totalTrafficDelta_sum_value: 0,
                unitsSoldDelta_sum_value: 0
              };

              return {
                ...acc,
                [fieldId]: {
                  unitsSold_sum_value: additionalValues.unitsSold_sum_value + deltas.unitsSoldDelta_sum_value,
                  retailSales_sum_value: additionalValues.retailSales_sum_value + deltas.retailSalesDelta_sum_value,
                  totalTraffic_sum_value: additionalValues.totalTraffic_sum_value + deltas.totalTrafficDelta_sum_value,
                  product: additionalMetaData.product
                }
              };
            },
            {} as ProductGridForecastData
          )
        : null;

    return {
      ...rest,
      data: productGridForecastData
    } as UseQueryResult<ProductGridForecastData>;
  }, [productGridForecastResponse, rest]);
};

interface UseProductTrafficDataProps {
  page: number;
  pageSize: number;
  stacklineSkus: string[];
}
/**
 * Returns the traffic metrics given a list of product SKUs
 */
export const useProductTrafficData = ({
  stacklineSkus,
  page,
  pageSize
}: UseProductTrafficDataProps): UseQueryResult<ProductTrafficResponse> => {
  const retailerId = useAppSelector((state) => state.retailer.id);
  const categoryIds = useAppSelector((state) => state.entityService.mainEntity.categoryIds);
  const trafficRequestId = 'entityGridMetricsentitypagecontainer_entityGrid_3-product--1';
  const trafficMetricsRequestBuilder = useAdvancedSearchRequestBuilder(trafficRequestId, 'beacon-traffic');

  const { startWeekId: ytdStartWeekId, endWeekId: ytdEndWeekId } = useYtdStartAndEndWeekIdsForForecast();
  const { startWeekId: forecastComparisonStartWeekId, endWeekId: forecastComparisonEndWeekId } =
    useForecastComparisonStartAndEndWeekIds();

  const trafficRequestBody = useMemo(() => {
    const aggregationBuilder = new AggregationBuilder('stacklineSku')
      .addAggregationField('Total Traffic', 'totalClicks', 'sum', true)
      .addConditionTermFilter('retailerId', [retailerId])
      .addConditionRangeFilter('weekId', ytdStartWeekId, ytdEndWeekId)
      .addComparisonRangeFilter('weekId', forecastComparisonStartWeekId, forecastComparisonEndWeekId)
      .setSortByAggregationField('Total Traffic', 'totalClicks', 'sum', true)
      .setSortDirection('desc');

    trafficMetricsRequestBuilder
      .setPageNumber(page)
      .setPageSize(pageSize)
      .setPeriod('year')
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .addConditionTermFilter('categoryId', 'should', categoryIds)
      .addConditionTermFilter('stacklineSku', 'should', stacklineSkus)
      .setSearchBy('parent')
      .addAggregation(aggregationBuilder.build())
      .addSortField('Total Traffic', 'totalClicks', 'sum', true)
      .setProcessDocuments(true);

    return trafficMetricsRequestBuilder.build();
  }, [
    retailerId,
    ytdStartWeekId,
    ytdEndWeekId,
    forecastComparisonStartWeekId,
    forecastComparisonEndWeekId,
    trafficMetricsRequestBuilder,
    page,
    pageSize,
    categoryIds,
    stacklineSkus
  ]);

  const { data: trafficResponse, ...rest } = useGenericAdvancedSearch<ProductTrafficResponse[]>({
    requestId: trafficRequestId,
    queryKeys: [trafficRequestBody],
    requestBody: [trafficRequestBody]
  });

  return {
    ...rest,
    data: trafficResponse && trafficResponse.data ? trafficResponse.data[0] : null
  } as UseQueryResult<ProductTrafficResponse>;
};

interface UseProductGridDataProps {
  page: number;
  pageSize: number;
}
/**
 * Returns the product metrics combined with the forecasted data
 * for the selected time period
 */
export const useProductGridData = ({
  page,
  pageSize
}: UseProductGridDataProps): Pick<UseQueryResult<ProductSalesDatum[]>, 'data' | 'isLoading'> => {
  const forecastPeriod = useForecastPeriod();
  const { startWeekId: ytdStartWeekId, endWeekId: ytdEndWeekId } = useYtdStartAndEndWeekIdsForForecast();
  const { startWeekId: forecastComparisonStartWeekId, endWeekId: forecastComparisonEndWeekId } =
    useForecastComparisonStartAndEndWeekIds();
  const { data: productGridForecastData, isLoading: forecastLoading } = useProductGridForecastedData({
    page,
    pageSize
  });

  const entityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const entityId = useAppSelector((state) => state.entityService.mainEntity.id);
  const salesRequestId = `forecasts-product-grid-data-${entityType}-${entityId}`;

  const salesMetricsRequestBuilder = useBaseMetricRequestBuilder({
    requestId: salesRequestId,
    startWeekId: ytdStartWeekId,
    endWeekId: ytdEndWeekId,
    groupByFieldName: 'stacklineSku',
    fields: [
      {
        name: 'retailSales'
      },
      {
        name: 'unitsSold'
      }
    ],
    indexName: 'sales',
    buildAggregationBuilder: (aggBuilder) => {
      return aggBuilder
        .addComparisonRangeFilter('weekId', forecastComparisonStartWeekId, forecastComparisonEndWeekId)
        .setSortDirection('desc');
    },
    sortFieldName: 'retailSales'
  });

  const salesRequestBody = useMemo(() => {
    return salesMetricsRequestBuilder
      .setPageNumber(page)
      .setPageSize(pageSize)
      .setProcessDocuments(true)
      .clearConditionRangeFilters()
      .build();
  }, [page, pageSize, salesMetricsRequestBuilder]);

  const { data: salesResponse, isLoading: productSalesLoading } = useGenericAdvancedSearch<ProductSalesResponse[]>({
    requestId: salesRequestId,
    queryKeys: [salesRequestBody],
    requestBody: [salesRequestBody]
  });

  const stacklineSkus = useMemo(
    () =>
      salesResponse && salesResponse.data
        ? salesResponse.data[0].aggregations.by_stacklineSku.map((sku) => sku.fieldId)
        : [],
    [salesResponse]
  );
  const { data: trafficResponse, isLoading: trafficLoading } = useProductTrafficData({ stacklineSkus, page, pageSize });

  const productGridData = useMemo(() => {
    return productGridForecastData && salesResponse && salesResponse.data && trafficResponse
      ? parseProductSalesResponse({
          salesResponse: salesResponse.data[0],
          aggregateActuals: forecastPeriod === ForecastPeriod.FULL_YEAR,
          trafficResponse,
          productGridForecastData,
          comparisonStartWeekId: forecastComparisonStartWeekId,
          comparisonEndWeekId: forecastComparisonEndWeekId
        })
      : { data: [] };
  }, [
    forecastComparisonEndWeekId,
    forecastComparisonStartWeekId,
    forecastPeriod,
    productGridForecastData,
    salesResponse,
    trafficResponse
  ]);

  return {
    data: productGridData.data,
    isLoading: forecastLoading || productSalesLoading || trafficLoading
  };
};
