import React, { useState, Fragment } from 'react';
import GenericDialog from 'src/components/common/Dialog/GenericDialog';
import {
  TextureBackgroundArea,
  UploadWrapper,
  validateFile,
  parseUploadedItems
} from 'src/components/Search/AdvancedSearch/BulkUpload/BulkUploadDialog';
import { SegmentState, KeyWordSegment } from 'src/components/Omni/OmniSegmentUtil';
import colors from 'src/utils/colors';
import _isEmpty from 'lodash/isEmpty';
import _capitalize from 'lodash/capitalize';
import Radio from '@mui/material/Radio';
import './BulkUpload.scss';

const styles: { [key: string]: React.CSSProperties } = {
  body: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 25
  },
  uploadWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: 20,
    minHeight: 38,
    alignItems: 'center'
  },
  content: {
    maxWidth: 400,
    display: 'flex',
    flex: 1,
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center'
  },
  pasteArea: {
    position: 'absolute',
    top: 35,
    zIndex: 1,
    padding: 20,
    marginTop: 5,
    width: 600,
    height: 350,
    background: 'none',
    fontFamily: '"Input", "Oxygen Mono", monospace',
    resize: 'none',
    borderRadius: '10px',
    border: `2px dashed ${colors.comparison}`
  },
  radioSelectWrapper: {
    width: '100%',
    display: 'contents'
  },
  firstIncludeExcludeRadioButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    top: '-50px',
    position: 'absolute',
    width: 293,
    marginTop: 17,
    marginBottom: 15,
    fontSize: 15
  },
  secondIncludeExcludeRadioButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    top: '-10px',
    position: 'absolute',
    marginTop: 17,
    marginBottom: 15,
    fontSize: 15,
    width: 293
  },
  errorMessage: {
    color: colors.red,
    fontWeight: 400,
    paddingTop: 8,
    marginBottom: -5
  },
  draggingStyle: {
    backgroundColor: '#9DF',
    border: '2px solid deepskyblue'
  },
  pasteAreaDraggingStyle: {
    backgroundColor: '#bee9ff'
  },
  radioButton: {
    color: colors.darkBlue
  },
  opacityTransition: {
    transition: 'opacity 0.5s ease-in'
  }
};

interface IncludeExcludeSelectProps {
  filterOpt: string[];
  value: string;
  onChange: (name: string) => void;
  styleForWrapper: React.CSSProperties;
  styleForButton: React.CSSProperties;
}

export const IncludeExcludeSelect: React.FC<IncludeExcludeSelectProps> = ({
  filterOpt,
  value,
  onChange,
  styleForWrapper,
  styleForButton
}: IncludeExcludeSelectProps) => (
  <div style={styleForWrapper}>
    {filterOpt.map((name: string) => (
      <Fragment key={name}>
        {['phrase', 'exact'].includes(name) ? `Include (${name})` : _capitalize(name)}
        <Radio checked={name === value} onChange={() => onChange(name)} label={name} style={styleForButton} />
      </Fragment>
    ))}
  </div>
);

interface OmniBulkUploadProps {
  filterOpt: string[];
  uploadOpt?: string[];
  open: boolean;
  itemType?: string;
  title: string;
  onClose: () => void;
  parsedState: SegmentState | KeyWordSegment;
  onKeywordsChange: (newChange: { key: string; values: { i: string }[] }) => void;
  getIdFieldNameAndItemListKey: (filterType: string, uploadType?: string) => string;
  TextContent: JSX.Element;
}

const backgroundText = {
  product: 'Titles, Retailer SKUs, GTIN',
  location: 'Zip Code'
};

const OmniBulkUpload: React.FC<OmniBulkUploadProps> = ({
  open,
  onClose,
  itemType,
  title,
  parsedState,
  onKeywordsChange,
  filterOpt,
  uploadOpt = [''],
  getIdFieldNameAndItemListKey,
  TextContent
}: OmniBulkUploadProps) => {
  const [selectedFile, setSelectedFileInner] = useState(undefined);
  const [isUploaded, setIsUploaded] = useState(false);
  const [pasteContent, setPasteContent] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [draggingCount, setDraggingCount] = useState(0);
  const [filterType, setFilterType] = useState(filterOpt[0]);
  const [uploadType, setUploadType] = useState(uploadOpt[0]);
  const [{ enlarge, shrink, upload }, setAnimationCallbacks] = useState({});

  const downloadButtonIsDisabled = (!selectedFile && _isEmpty(pasteContent)) || isUploaded;
  const shouldShowUploadOpt = uploadOpt[0] !== '';

  const itemTypeToShow = itemType || backgroundText[uploadType];

  const setSelectedFile = (file) => {
    if (errorMsg) {
      setErrorMsg(null);
    }
    setSelectedFileInner(file);
  };
  const handleClose = () => {
    setSelectedFile(undefined);
    setIsUploaded(false);

    onClose();
  };

  const handleSubmit = async (file = selectedFile) => {
    const idFieldName = getIdFieldNameAndItemListKey(filterType, uploadType);
    const existingItems = parsedState[idFieldName] || [];

    try {
      const newItems = await parseUploadedItems(pasteContent, file);
      const newValues = [...existingItems.map((item) => ({ i: item })), ...newItems.map((item) => ({ i: item }))];
      setPasteContent('');
      setDraggingCount(0);
      setFilterType(filterOpt[0]);
      setUploadType(uploadOpt[0]);
      handleClose();
      onKeywordsChange({ key: idFieldName, values: newValues });
    } catch (err) {
      console.error(err);
      setErrorMsg(err.message);
      setIsUploaded(false);
    }
  };

  const eventHandlers = {
    onDrop: (evt) => {
      setErrorMsg(undefined);
      evt.preventDefault();
      evt.stopPropagation();

      const file = evt.dataTransfer.files[0];
      const errMsg = validateFile(file);
      if (errMsg) {
        shrink();
        setErrorMsg(errMsg);
        return;
      }

      setSelectedFile(evt.dataTransfer.files[0]);
      setIsUploaded(true);
      upload(() => handleSubmit(file));

      setPasteContent('');
      setDraggingCount(0);
    },
    onDragOver: (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
    },
    onDragStart: () => {},
    onDragLeave: () => {
      if (draggingCount === 1) {
        shrink();
      }
      setDraggingCount(draggingCount - 1);
    },
    onDragEnter: () => {
      if (draggingCount === 0) {
        enlarge();
      }
      setDraggingCount(draggingCount + 1);
    },
    onDragExit: shrink
  };
  return (
    <GenericDialog
      open={open}
      onClose={handleClose}
      textContent={TextContent}
      title={title}
      style={{ minHeight: 680 }}
      {...eventHandlers}
    >
      <div style={styles.body}>
        <div style={styles.content}>
          <div style={styles.radioSelectWrapper}>
            <IncludeExcludeSelect
              filterOpt={filterOpt}
              value={filterType}
              onChange={setFilterType}
              styleForWrapper={styles.firstIncludeExcludeRadioButtons}
              styleForButton={styles.radioButton}
            />
          </div>
          {shouldShowUploadOpt && (
            <div style={styles.radioSelectWrapper}>
              <IncludeExcludeSelect
                filterOpt={uploadOpt}
                value={uploadType}
                onChange={setUploadType}
                styleForWrapper={styles.secondIncludeExcludeRadioButtons}
                styleForButton={styles.radioButton}
              />
            </div>
          )}
          <textarea
            className="no-outline"
            value={pasteContent}
            onChange={(e) => {
              if (selectedFile) {
                setSelectedFile(null);
              }

              setPasteContent(e.target.value);
            }}
            style={styles.pasteArea}
          />
          <TextureBackgroundArea
            pasteContent={pasteContent}
            itemType={itemTypeToShow}
            isUploaded={isUploaded}
            setAnimationCallbacks={setAnimationCallbacks}
            setErrorMsg={setErrorMsg}
            setSelectedFile={setSelectedFile}
            enlarge={enlarge}
            shrink={shrink}
          />
          <div style={styles.uploadWrapper}>
            {selectedFile || pasteContent ? (
              <UploadWrapper
                pasteContent={pasteContent}
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
                downloadButtonIsDisabled={downloadButtonIsDisabled}
                setIsUploaded={setIsUploaded}
                upload={upload}
                handleSubmit={handleSubmit}
                isUploaded={isUploaded}
              />
            ) : null}
            <div style={styles.errorMessage}>{errorMsg}</div>
          </div>
        </div>
      </div>
    </GenericDialog>
  );
};

export default OmniBulkUpload;
