import React from 'react';
import BeaconProWaterfallChart from 'src/components/EntityPage/WaterfallChart/BeaconProWaterfallChart';
import BeaconProWaterfallUnitsSoldTable from 'src/components/EntityPage/WaterfallChart/BeaconProWaterfallUnitsSoldTable';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';

/**
 * Waterfall chart and units sold table for the summary page
 */
export default function BeaconProWaterfallPageLayout() {
  return (
    <BeaconPageContainer>
      <Flex gap={BEACON_CHART_SPACING} flexDirection="column">
        <BeaconProWaterfallChart />
        <BeaconProWaterfallUnitsSoldTable />
      </Flex>
    </BeaconPageContainer>
  );
}
