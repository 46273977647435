import React from 'react';
import { connect } from 'react-redux';
import { withBus } from 'react-bus';
import { withRouter, RouteComponentProps } from 'react-router';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import { Conditions, AppName } from 'sl-api-connector/types';
import queryString from 'qs';

import { store } from 'src/main';
import { getLayoutForEntity } from 'src/components/Layout/EntityPageLayout';
import { combineFilterConditions } from 'src/utils/filters';
import { hashIdOfZero } from 'src/utils/constants';
import { entityOperations } from 'src/store/modules/entityService';
import Loading from 'src/components/common/Loading';
import './EntityPage.scss';
import ReduxStore from 'src/types/store/reduxStore';
import { buildBUEntityConditions } from 'src/components/EntityPage/Filters/businessUnitFilters';
import { setEntity, fetchEntity } from 'src/store/modules/entityService/operations';
import { anyNotEq } from 'src/utils/equality';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { EventBus } from 'src/types/utils';

export const styles: { [key: string]: React.CSSProperties } = {
  loading: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['app', 'user', 'entityService', 'categories', 'filters', 'retailer', 'subCategories', 'segments']);

export type Props = { conditions: Conditions } & ReturnType<typeof mapStateToProps> & {
    eventBus: EventBus;
  } & RouteComponentProps<{ id: string }>;

export class EntityPageInner<AdditionalProps = { eventBus: EventBus }> extends React.Component<
  Props & AdditionalProps
> {
  public state = {
    loading: true
  };

  private setMainEntityForBU = (
    buId: string,
    savedSearchesById: NonNullable<ReduxStore['segments']['savedSearchesById']>
  ) => {
    const savedSearch = savedSearchesById.get(buId);

    if (!savedSearch) {
      console.error(`No business unit or saved search at all found with id "${buId}"`);
      this.props.history.push('/');
      return Promise.reject();
    } else if (savedSearch.type !== 'businessunit') {
      console.error(`Saved search id "${buId}" is not a business unit; it's a "${savedSearch.type}"`);
      this.props.history.push('/');
      return Promise.reject();
    }

    return store
      .dispatch(
        entityOperations.createAllCategoryEntity('mainEntity', 'businessunit', buId, {
          conditions: buildBUEntityConditions(savedSearchesById, savedSearch),
          name: savedSearch.displayName,
          displayName: savedSearch.displayName,
          entityType: 'businessunit'
        })
      )
      .then(() => {
        store.dispatch(entityOperations.performCategorySortRequests());
        this.setState({ loading: false });
      });
  };

  private fetchMainEntity(props: Props = this.props) {
    const { app, user, location, retailer, match, segments, history } = props;
    const { pathname } = location;
    const { id = user.config.vendor.hashId } = match.params;
    let parsedPathname = pathname.substring(1, pathname.lastIndexOf('/'));
    if (parsedPathname === '/') {
      parsedPathname = 'client';
    }

    if (app.name !== AppName.Omni) {
      const entityId = id.includes('?') ? id.substring(0, id.indexOf('?')) : id;
      if (id === '0' || id === hashIdOfZero || parsedPathname.toLowerCase() === 'businessunit') {
        if (parsedPathname === 'category') {
          return store.dispatch(entityOperations.createAllCategoryEntity('mainEntity', parsedPathname, 0)).then(() => {
            store.dispatch(entityOperations.performCategorySortRequests());
            this.setState({ loading: false });
          });
        } else if (parsedPathname.toLowerCase() === 'businessunit') {
          // If the request to fetch saved searches hasn't finished yet, this is put off and will be done later
          if (segments.savedSearchesById) {
            return this.setMainEntityForBU(entityId, segments.savedSearchesById);
          } else {
            return undefined;
          }
        }
        return store.dispatch(entityOperations.createCompanyEntity('mainEntity')).then(() => {
          store.dispatch(entityOperations.performCategorySortRequests());
          this.setState({ loading: false });
        });
      }

      return store
        .dispatch(entityOperations.fetchMainEntity(parsedPathname, entityId, retailer.id, history.push.bind(history)))
        .then(() => this.setState({ loading: false }));
    } else {
      return store
        .dispatch(entityOperations.fetchEntity('mainEntity', 'client', '-1', '0'))
        .then(() => this.setState({ loading: false }));
    }
  }

  private fetchComparisonEntity(props: Props = this.props) {
    const queryParams = queryString.parse(props.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });

    let { comparisonEntity } = props.entityService;
    if (comparisonEntity && `${comparisonEntity.id}` !== queryParams.cid) {
      comparisonEntity = undefined;
    }

    if (queryParams.ctype && !comparisonEntity) {
      if (queryParams.ctype === 'metric' && props.entityService.mainEntity) {
        if (queryParams.ctype === 'metric') {
          comparisonEntity = _cloneDeep(props.entityService.mainEntity);
        }
        store.dispatch(setEntity('comparisonEntity', _cloneDeep(props.entityService.mainEntity)));
      } else {
        store.dispatch(fetchEntity('comparisonEntity', queryParams.ctype, queryParams.cid, props.retailer.id));
      }
    }
  }

  public componentDidMount() {
    this.fetchComparisonEntity();
    this.fetchMainEntity();
  }

  public componentWillReceiveProps(nextProps: Props) {
    const {
      user,
      location,
      match,
      retailer,
      history,
      entityService: { mainEntity }
    } = nextProps;
    const { pathname } = location;
    const { id = user.config.vendor.hashId } = match.params;
    let parsedPathname = pathname.substring(1, pathname.lastIndexOf('/'));
    if (parsedPathname === '/') {
      parsedPathname = 'client';
    }
    const entityId = id.includes('?') ? id.substring(0, id.indexOf('?')) : id;

    // If the list of saved searches weren't available when we mounted, we take care of setting the main entity here
    if (
      parsedPathname.toLowerCase() === 'businessunit' &&
      !this.props.segments.savedSearchesById &&
      nextProps.segments.savedSearchesById
    ) {
      this.setMainEntityForBU(entityId, nextProps.segments.savedSearchesById);
    } else if (location.pathname !== this.props.location.pathname) {
      this.setState({ loading: true });
      if (id === '0' || id === hashIdOfZero) {
        store.dispatch(entityOperations.createCompanyEntity('mainEntity')).then(() => {
          store.dispatch(entityOperations.performCategorySortRequests());
          this.setState({ loading: false });
        });
      } else {
        store
          .dispatch(entityOperations.fetchMainEntity(parsedPathname, entityId, retailer.id, history.push.bind(history)))
          .then(() => this.setState({ loading: false }));
      }
    } else if (parsedPathname !== _get(mainEntity, 'type') && !this.state.loading) {
      // Refetch main entity if the type does not match thce URL path (rare, but can happen on slow connections or rapid navigation)
      if (parsedPathname === 'brand' && _get(mainEntity, 'type') === 'company' && id === '0') {
        return;
      }
      this.setState({ loading: true });
      this.fetchMainEntity(nextProps);
    }

    const prevQueryParams = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const nextQueryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    if (anyNotEq(['ctype', 'cid'], prevQueryParams, nextQueryParams)) {
      this.fetchComparisonEntity(nextProps);
    }
  }

  public render() {
    const { entityService, app, user, filters, location, retailer, categories, subCategories, segments, eventBus } =
      this.props;
    const { loading } = this.state;
    const loadingSpinner = <Loading style={{ position: 'fixed' }} className="spinner" size={150} thickness={2} />;
    if (loading || !filters || !entityService || !entityService.mainEntity) {
      // handle condition of main entity loading here?
      return loadingSpinner;
    }
    const { conditions, aggregationConditions } = combineFilterConditions(
      app,
      filters,
      categories,
      subCategories,
      segments,
      retailer
    );

    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const { tab, subtab } = queryParams;

    const pageLayout = getLayoutForEntity({
      app,
      retailer,
      user,
      tab,
      metricType: subtab,
      entity: entityService.mainEntity,
      pageType: 'entityPage',
      filters,
      eventBus
    });

    if (!pageLayout) {
      // Prevents a crash caused by the condition where the mainEntity does not match the URL path
      console.error(
        `No page page layout found for ${app.name}->${_get(entityService, 'mainEntity.type', '')}->entityPage`
      );
      return loadingSpinner;
    }

    if (!pageLayout.CustomPageContainer) {
      pageLayout.CustomPageContainer = GenericPageContainer;
      console.error(`No \`CustomPageContainer\` found for page layout; we need one.`);
    }
    return (
      <pageLayout.CustomPageContainer
        pageLayout={pageLayout}
        entity={entityService.mainEntity}
        conditions={conditions}
        aggregationConditions={aggregationConditions}
      />
    );
  }
}

export default withRouter(connect(mapStateToProps)(withBus('eventBus')(EntityPageInner)));
