import ProductGrowthPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/ProductGrowthPage';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';

const getProductGrowthLayout = () => {
  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    widgets: [
      {
        name: 'productGrowth',
        CustomComponent: ProductGrowthPage,
        view: {}
      }
    ],
    containerStyle: {},
    dataConfig: {}
  };
};

export default getProductGrowthLayout;
