import _cloneDeep from 'lodash/cloneDeep';
import { NewToBrandDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import { NewToBrandAdvancedSearchResponse, NewToBrandData } from './types';
import { padEmptyAdTypes, padEmptyData } from '../visualizationUtils';
import ReduxStore from 'src/types/store/reduxStore';

/**
 * Take an advanced search response and return bar data for new to brand
 * and non new to brand users.
 * @param response Raw response from advanced search
 */
export const newToBrandAdvancedSearchResponseParser = (response: NewToBrandAdvancedSearchResponse): NewToBrandData => {
  const fieldIdToDisplayName = {
    dsp: 'DSP',
    sponsored_products: 'Sponsored Products',
    sponsored_brands: 'Sponsored Brands',
    sponsored_display: 'Sponsored Display'
  } as const;

  /**
   * additionalFields has all the information we are interested in. Keep fieldId so that
   * we can sort in descending order and know the order of the sorted ad types.
   */
  const aggregations = response.aggregations['by_adProductType.keyword']
    .filter(({ fieldId }) => Object.keys(fieldIdToDisplayName).includes(fieldId))
    .map(({ additionalValues, fieldId }) => ({
      ...additionalValues,
      fieldId
    }));

  // Sort by each metric with descending order, making sure to keep the order of the ad types.
  // Clone each one deeply because sort modifies the array in place
  const adSales = _cloneDeep(aggregations).sort((a, b) => b.totalAdSales_sum_value - a.totalAdSales_sum_value);
  const unitsSold = _cloneDeep(aggregations).sort((a, b) => b.totalUnitsSold_sum_value - a.totalUnitsSold_sum_value);
  const orders = _cloneDeep(aggregations).sort((a, b) => b.newToBrandOrders_sum_value - a.newToBrandOrders_sum_value);

  return {
    [NewToBrandDropdownOption.AdSales]: {
      newToBrand: padEmptyData(adSales.map(({ newToBrandAdSales_sum_value }) => newToBrandAdSales_sum_value)),
      nonNewToBrand: padEmptyData(adSales.map(({ nonNewToBrandAdSales_sum_value }) => nonNewToBrandAdSales_sum_value)),
      adTypes: padEmptyAdTypes(adSales.map(({ fieldId }) => fieldId)).map((fieldId) => fieldIdToDisplayName[fieldId])
    },
    [NewToBrandDropdownOption.UnitsSold]: {
      newToBrand: padEmptyData(unitsSold.map(({ newToBrandUnitsSold_sum_value }) => newToBrandUnitsSold_sum_value)),
      nonNewToBrand: padEmptyData(
        unitsSold.map(({ nonNewToBrandUnitsSold_sum_value }) => nonNewToBrandUnitsSold_sum_value)
      ),
      adTypes: padEmptyAdTypes(unitsSold.map(({ fieldId }) => fieldId)).map((fieldId) => fieldIdToDisplayName[fieldId])
    },
    [NewToBrandDropdownOption.Conversions]: {
      newToBrand: padEmptyData(orders.map(({ newToBrandOrders_sum_value }) => newToBrandOrders_sum_value)),
      nonNewToBrand: padEmptyData(
        orders.map(
          ({ totalOrders_sum_value, newToBrandOrders_sum_value }) => totalOrders_sum_value - newToBrandOrders_sum_value
        )
      ),
      adTypes: padEmptyAdTypes(orders.map(({ fieldId }) => fieldId)).map((fieldId) => fieldIdToDisplayName[fieldId])
    }
  };
};

export const newToBrandCampaignResponseParser = (response: any) => {
  /**
   * additionalFields has all the information we are interested in. Keep fieldId so that
   * we can sort in descending order and know the order of the sorted ad types.
   */
  const aggregations = response.aggregations['by_campaign.keyword'].map(({ additionalValues, fieldId }) => ({
    ...additionalValues,
    fieldId
  }));

  // Clone each one deeply because sort modifies the array in place
  // Only take the top N values for display after sorting
  const TOP_N_VALUES = 100;
  const adSales = _cloneDeep(aggregations)
    .sort((a, b) => b.adSales_sum_value - a.adSales_sum_value)
    .slice(0, TOP_N_VALUES);
  const unitsSold = _cloneDeep(aggregations)
    .sort((a, b) => b.adUnitsSold_sum_value - a.adUnitsSold_sum_value)
    .slice(0, TOP_N_VALUES);
  const orders = _cloneDeep(aggregations)
    .sort((a, b) => b.orders_sum_value - a.orders_sum_value)
    .slice(0, TOP_N_VALUES);

  const dataByMetric = {
    [NewToBrandDropdownOption.AdSales]: {
      newToBrand: adSales.map(({ newToBrandAdSales_sum_value }) => newToBrandAdSales_sum_value),
      nonNewToBrand: adSales.map(
        ({ adSales_sum_value, newToBrandAdSales_sum_value }) => adSales_sum_value - newToBrandAdSales_sum_value
      ),
      adTypes: adSales.map(({ fieldId }) => fieldId).map((fieldId) => fieldId)
    },
    [NewToBrandDropdownOption.UnitsSold]: {
      newToBrand: unitsSold.map(({ newToBrandAdUnitsSold_sum_value }) => newToBrandAdUnitsSold_sum_value),
      nonNewToBrand: unitsSold.map(
        ({ adUnitsSold_sum_value, newToBrandAdUnitsSold_sum_value }) =>
          adUnitsSold_sum_value - newToBrandAdUnitsSold_sum_value
      ),
      adTypes: unitsSold.map(({ fieldId }) => fieldId).map((fieldId) => fieldId)
    },
    [NewToBrandDropdownOption.Conversions]: {
      newToBrand: orders.map(({ newToBrandOrders_sum_value }) => newToBrandOrders_sum_value),
      nonNewToBrand: orders.map(({ orders_sum_value, newToBrandOrders_sum_value }) => {
        const nonNewToBrandOrders = orders_sum_value - newToBrandOrders_sum_value;
        return nonNewToBrandOrders < 0 ? 0 : nonNewToBrandOrders;
      }),
      adTypes: orders.map(({ fieldId }) => fieldId).map((fieldId) => fieldId)
    }
  };

  return dataByMetric;
};

interface ConvertNewToBrandDataToCsvArgs {
  data: NewToBrandData;
  metric: NewToBrandDropdownOption;
  groupByMetric: string;
  dateRange: string;
}

/**
 * Take NTB data and return a CSV string for export
 */
export const convertNewToBrandDataToCsv = ({
  data,
  metric,
  groupByMetric,
  dateRange
}: ConvertNewToBrandDataToCsvArgs) => {
  if (!data || !data[metric]) {
    return '';
  }

  const groupedOnType = groupByMetric;

  const { adTypes, newToBrand, nonNewToBrand } = data[metric];
  const headers = [groupedOnType, `NTB ${metric}: ${dateRange}`, `Non-NTB ${metric}: ${dateRange}`].map(
    (val) => `"${val}"`
  );

  const values = adTypes.map((adType, index) => {
    return [adType, newToBrand[index], nonNewToBrand[index]].map((val) => `"${val}"`).join(',');
  });

  return `${headers}\n${values.join('\n')}`;
};

interface GenerateCampaignLinkParameters {
  mainTimePeriod: ReduxStore['mainTimePeriod'];
  campaignName: string | number;
  campaignId: string;
}

/**
 *
 * @param mainTimePeriod
 * @param campaignName the campaign name as a string that will be displayed as the x-axis label
 * @param campaignId a string that will be used to identify which campaign to link to
 * @returns a stringified <a> element that will be used as both the x-axis label and the link to our campaign summary
 */
export const generateCampaignLinkForChart = ({
  mainTimePeriod,
  campaignName,
  campaignId
}: GenerateCampaignLinkParameters) => {
  const { startDayId, endDayId, startWeek, endWeek } = mainTimePeriod;
  const params = new URLSearchParams(window.location.search);

  // Set the start and end days and weeks
  params.set('sdid', String(startDayId));
  params.set('edid', String(endDayId));
  params.set('sw', String(startWeek));
  params.set('ew', String(endWeek));

  // Ensure we switch tabs to where we find the campaign summary info
  params.set('tab', 'adManager');
  params.set('subtab', 'keyMetrics');

  // Update the time period to default
  params.set('wr', 'mtd');

  // Set the entity to be a campaign
  params.append('selectedEntityName', 'campaignId');

  const newParams = params.toString();

  // Return an <a> element that has a link to our campaign summary
  return `<a style="margin-top: 5px; display: inline-block; font-size: 13px;" href="/adCampaign/${encodeURI(
    campaignId
  )}?${newParams}">${campaignName}</a>`;
};

interface BuildRequestForNTBStackedChartParameters {
  groupByMetric: string;
  startDayId: string | number;
  endDayId: string | number;
  retailerId?: string | number;
}

export const buildRequestForNTBStackedChart = ({
  groupByMetric,
  startDayId,
  endDayId,
  retailerId = 1
}: BuildRequestForNTBStackedChartParameters) => {
  if (groupByMetric === NewToBrandDropdownOption.Campaign) {
    return [
      {
        name: 'adManagerSearchResultData_total-client-gl9avgmbG1',
        id: 'adManagerSearchResultData_total-client-gl9avgmbG1',
        pageNumber: 1,
        pageSize: 5000,
        doAggregation: true,
        returnDocuments: false,
        conditions: {
          condition: 'must',
          termFilters: [
            {
              fieldName: 'periodStartDate',
              condition: 'should',
              values: [`${startDayId}`]
            },
            {
              fieldName: 'periodEndDate',
              condition: 'should',
              values: [`${endDayId}`]
            },
            {
              fieldName: 'retailerId',
              condition: 'should',
              values: [retailerId]
            }
          ],
          computeFilters: []
        },
        searchBy: 'parent',
        aggregations: [
          {
            aggregationFields: [
              {
                aggregateByFieldName: 'beaconClientId',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'adSales',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'newToBrandAdSales',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'adUnitsSold',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'newToBrandAdUnitsSold',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'orders',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'newToBrandOrders',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'uniqueShoppersThatPurchased',
                function: 'sum',
                canBeExported: true
              }
            ],
            groupByFieldName: 'campaign.keyword'
          }
        ],
        searchType: 'advertising-amc-new-to-brand-by-campaign',
        processDocuments: false
      }
    ];
  } else {
    return [
      {
        doAggregation: true,
        pageNumber: 1,
        pageSize: 50,
        processDocuments: false,
        conditions: {
          condition: 'must',
          termFilters: [
            {
              fieldName: 'periodStartDate',
              condition: 'should',
              values: [`${startDayId}`]
            },
            {
              fieldName: 'retailerId',
              condition: 'should',
              values: [retailerId]
            },
            {
              fieldName: 'periodEndDate',
              condition: 'should',
              values: [`${endDayId}`]
            },
            {
              fieldName: 'segmentId',
              condition: 'should',
              values: ['DEFAULT']
            }
          ],
          computeFilters: []
        },
        aggregations: [
          {
            aggregationFields: [
              {
                aggregateByFieldName: 'retailerId',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'newToBrandUniqueShoppersReached',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'nonNewToBrandUnitsSold',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'newToBrandUnitsSold',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'uniqueShoppersReached',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'totalAdSales',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'newToBrandAdSales',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'totalUnitsSold',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'newToBrandOrders',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'nonNewToBrandAdSales',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'totalOrders',
                function: 'sum',
                canBeExported: true
              },
              {
                aggregateByFieldName: 'nonNewToBrandOrders',
                function: 'sum',
                canBeExported: true
              }
            ],
            groupByFieldName: 'adProductType.keyword'
          }
        ]
      }
    ];
  }
};
