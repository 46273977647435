import React, { useState, useRef } from 'react';
import { useBulkAdjustmentContext } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/hooks/useBulkAdjustmentContext';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { SlMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlMenu';
import { DownChevronWhite } from 'src/components/SvgIcons';
import { useMainEntityType } from 'src/utils/Hooks/reduxSelectors';
import { BulkAdjustmentModalType } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkAdjustmentProvider';
import { useAppSelector, useHistory } from 'src/utils/Hooks';
import { trackMixpanelEvent } from 'src/utils/mixpanel';

export enum BulkAdjustmentOptions {
  SingularAdjustment = 'SingularAdjustment',
  DownloadTemplate = 'DownloadTemplate',
  UploadTemplate = 'UploadTemplate',
  ViewUploads = 'ViewUploads'
}

/**
 * Button that opens a menu for bulk adjustment options
 */
export default function CreateBulkAdjustmentButton({
  onAddSingularAdjustment,
  options = null
}: {
  onAddSingularAdjustment: () => void;
  options?: { id: string; label: string }[] | null;
}) {
  const entityType = useMainEntityType();
  const [menuOpen, setMenuOpen] = useState(false);
  const buttonRef = useRef(null);
  const { openBulkAdjustmentModal } = useBulkAdjustmentContext();
  const history = useHistory();
  const { queryParams } = useAppSelector((state) => state.app);

  return (
    <>
      <SlButton
        ref={buttonRef}
        onClick={() => setMenuOpen(true)}
        buttonSx={{
          width: '162px',
          paddingLeft: '12px',
          paddingRight: '4px'
        }}
        textSx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
        variant="contained"
      >
        + Add Adjustment
        <DownChevronWhite width="24px" height="24px" color="white" />
      </SlButton>
      <SlMenu
        anchorEl={buttonRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        // If the entity type is product, we only show the singular adjustment, otherwise we show the bulk adjustment options
        options={
          options || [
            ...(entityType === 'product'
              ? [
                  {
                    id: BulkAdjustmentOptions.SingularAdjustment,
                    label: 'Add single adjustment'
                  }
                ]
              : [
                  {
                    id: BulkAdjustmentOptions.DownloadTemplate,
                    label: 'Download bulk template'
                  }
                ]),

            {
              id: BulkAdjustmentOptions.UploadTemplate,
              label: 'Upload bulk template'
            },
            {
              id: BulkAdjustmentOptions.ViewUploads,
              label: 'View recent uploads'
            }
          ]
        }
        selectedId={null}
        onChange={(option) => {
          switch (option.id) {
            case BulkAdjustmentOptions.SingularAdjustment:
              onAddSingularAdjustment();
              break;
            case BulkAdjustmentOptions.DownloadTemplate:
              openBulkAdjustmentModal({
                type: BulkAdjustmentModalType.DownloadTemplate
              });
              break;
            case BulkAdjustmentOptions.UploadTemplate:
              openBulkAdjustmentModal({
                type: BulkAdjustmentModalType.UploadTemplate
              });
              break;
            case BulkAdjustmentOptions.ViewUploads:
              trackMixpanelEvent({ eventName: 'view bulk adjustment uploads' });
              history.push(`/account/adjustments${queryParams.searchParams}`);
              break;
            default:
              break;
          }
        }}
      ></SlMenu>
    </>
  );
}
