import React, { useState, useEffect } from 'react';
import { useInterval } from 'src/utils/Hooks';
import PropTypes from 'prop-types';
// import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ChevronIcon } from 'src/components/SvgIcons';
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';
import colors from 'src/utils/colors';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import InfoIcon from '!svg-react-loader!./info.svg'; // eslint-disable-line
import './UserAccountUpload.scss';
import axios from 'axios';
import moment from 'moment';
import * as momenttz from 'moment-timezone';
import { connect } from 'react-redux';
import { isContentUploadEnabled, isOmni } from 'src/utils/app';

const mapStateToProps = (state) => {
  return {
    targetUrl: state.app.targetUrl,
    locale: state.retailer.locale,
    appName: state.app.name,
    omniRetailers: state.omniRetailers,
    availableRetailers: state.availableRetailers,
    userEmail: state.user.session.email
  };
};

const muiStyles = {
  root: {
    margin: 0,
    '&:before': {
      content: 'none'
    }
  },
  disabled: {
    color: colors.lightGrey
  },
  progressWhite: {
    color: 'white'
  },
  progressBlack: {
    color: colors.darkBlue
  },
  select: {
    padding: '7px 27px 7px 7px',
    fontWeight: 400,
    minWidth: '220px'
  }
};

export const formatError =
  'It appears you’ve used an incorrect file format.  Try converting your spreadsheet to a CSV and re-uploading.';

const propTypes = {
  headerText: PropTypes.string,
  currRetailer: PropTypes.object.isRequired,
  children: PropTypes.any,
  retailers: PropTypes.array,
  buttons: PropTypes.object.isRequired,
  style: PropTypes.object,
  classes: PropTypes.object.isRequired,
  getLatestApi: PropTypes.any.isRequired,
  updateUserAccount: PropTypes.func.isRequired,
  uploadType: PropTypes.string,
  targetUrl: PropTypes.string,
  locale: PropTypes.string,
  availableRetailers: PropTypes.object.isRequired,
  userEmail: PropTypes.string.isRequired
};

const defaultProps = {
  children: null,
  headerText: 'Upload',
  style: undefined,
  retailers: undefined,
  uploadType: '',
  targetUrl: '',
  locale: 'en'
};

const UserAccountUpload = ({
  headerText,
  children,
  retailers,
  currRetailer,
  classes,
  buttons,
  updateUserAccount,
  getLatestApi,
  uploadType,
  targetUrl,
  locale,
  userEmail
}) => {
  // const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [retailer, setRetailer] = useState({
    id: currRetailer.id,
    name: currRetailer.displayName
  });

  const [state, setState] = useState({
    latest: '',
    shouldFetch: false,
    errors: [],
    buttonText: buttons.uploadFile.text
  });
  const [lastUpdated, setLastUpdated] = useState('');
  // buttons.uploadFile.api for Omni does not have prefixed params.
  const retailerParamKey = isOmni ? '?retailerId=' : '&retailer=';

  // Omni's currRetailer will always "All Retailers" (combined data)
  useEffect(() => {
    if (isOmni) {
      const defaultRetailer = retailers[0];
      setRetailer({ id: defaultRetailer.id, name: defaultRetailer.displayName });
    }
  }, [retailers]);

  useEffect(() => {
    /**
     * @param {number} time - epoch time
     *
     * @returns {string} data and time as per user's timezone
     */
    const showDateTimeByUserTimezone = (time) => {
      const timezone = momenttz.tz.guess();
      moment.locale(locale);
      return momenttz.tz(moment.unix(time), timezone).format('DD MMM YYYY hh:mm A');
    };

    const params = isOmni
      ? { retailerId: retailer.id }
      : {
          uploadType,
          retailer: retailer.id
        };

    const fetchLastUpdated = async (url) => {
      try {
        const res = await axios.get(url, {
          params
        });

        if (res.status === 200) {
          // Omni API returns 200 and returns status fail message
          if (isOmni && res.data.status === 'failed') {
            setLastUpdated(res.data.message);
          } else {
            setLastUpdated(showDateTimeByUserTimezone(res.data.lastUploadTime));
          }
        }
      } catch (e) {
        setLastUpdated(e.response.data);
      }
    };

    let lastUploadUrl = '';
    if (uploadType === 'reviews-upload') {
      lastUploadUrl = `https://${targetUrl}/api/account/GetLastUploadTimeHighRiskKeywords`;
    } else if (uploadType === 'multi-retailer-upload') {
      lastUploadUrl = `https://${targetUrl}/api/account/GetLastSuccessfulUploadTime`;
    } else if (uploadType === 'master-content-upload-omni') {
      lastUploadUrl = `https://${targetUrl}/omni/account/getLastUploadTimeMasterContentData`;
    } else {
      lastUploadUrl = `https://${targetUrl}/api/account/GetLastUploadTime`;
    }

    fetchLastUpdated(lastUploadUrl);
  }, [retailer.id, uploadType, targetUrl, locale]);

  const fetchLatest = async () => {
    if (getLatestApi) {
      try {
        const res = await updateUserAccount(`${getLatestApi}&retailer=${retailer.id}`);
        if (res.data === '') {
          setState({
            ...state,
            status: ''
          });
        } else {
          const date = new Date(res.data[0].requestedTime * 1000);
          const errors = res.data[0].error === '{}' ? [] : JSON.parse(res.data[0].error);

          const latest = `Last updated: ${date.toLocaleString()}`;
          const shouldFetch = ['in progress', 'queued'].includes(res.data[0].status);
          setState({
            ...state,
            status: res.data[0].status,
            shouldFetch,
            errors,
            latest
          });
        }

        setIsLoading(false);
      } catch (e) {
        setState({
          shouldFetch: true,
          ...state
        });
      }
    } else {
      setState({
        shouldFetch: false,
        ...state
      });
      setIsLoading(false);
    }
  };
  const fetchInterval = () => {
    if (state.shouldFetch) {
      fetchLatest();
    }
  };

  useInterval(() => {
    fetchInterval();
  }, 5000);

  const handleRetailerChange = (event) => {
    const id = event.target.value;
    const name = retailers.find((item) => item.id === id).displayName;
    setState({ errors: [] });
    setRetailer({ id, name });
  };
  const handleUpload = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('file', file, file.name);

    setState({ ...state, status: 'queued', errors: [] });
    try {
      const res = await updateUserAccount(`${buttons.uploadFile.api}${retailerParamKey}${retailer.id}`, formData);
      if (res.data === false) {
        const errors = [{ ErrorMessage: formatError }];
        setState({ ...state, errors, status: 'failed with error' });
      } else if (res.data === true) {
        const errors = [];
        setState({ ...state, errors, status: 'finished successfully' });
      } else {
        setTimeout(() => fetchLatest(), 3000);
      }
    } catch (e) {
      const errors = [{ ErrorMessage: e.response.data }];
      setState({ ...state, errors, status: 'failed with error' });
    }
  };

  const downloadTemplete = async (apiUrl) => {
    try {
      const res = await axios.get(apiUrl);

      if (res.data) {
        // status failed will be triggered in Omni => when user have not uploaded Master Content Data
        if (res.data.status === 'failed') {
          const errors = [{ ErrorMessage: res.data.message }];
          setState({ ...state, errors });
        } else {
          const a = document.createElement('a');
          a.style = 'display: none';
          a.href = res.data;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            a.remove();
          }, 100);
        }
      }
    } catch (e) {
      const errors = [{ ErrorMessage: e.response.data }];
      setState({ ...state, errors });
    }
  };

  const renderErrors = (errors) => {
    if (!errors || errors.length === 0) {
      return null;
    }
    return (
      <div className="error_area">
        <ul className="error_list">
          {errors.map((err, idx) => (
            <li key={idx} className="error_item">
              <span>
                <InfoIcon />
              </span>
              <div style={{ maxWidth: 600 }}>
                {err.ErrorMessage}
                {err.Items ? (
                  <ul
                    style={{
                      padding: 0,
                      listStyle: 'none'
                    }}
                  >
                    {err.Items.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderTooltip = (text) => {
    if (!text) {
      return null;
    }
    return <span className="account_upload_tooltip">{text}</span>;
  };

  const getButtonDetail = (defaultText) => {
    const button = { text: defaultText, colorClass: 'sl-primary-button sl-primary-button--lg' };
    switch (state.status) {
      case 'in progress':
        button.text = 'PROCESSING';
        break;
      case 'canceled':
        button.text = 'CANCELED';
        button.colorClass = 'sl-error-button sl-error-button--lg';
        break;
      case 'queued':
        button.text = 'PROCESSING';
        break;
      case 'finished successfully':
        button.text = 'SUCCESS!';
        button.colorClass = 'sl-green-button sl-green-button--lg';
        break;
      case 'failed with error':
        button.text = 'RETRY';
        button.colorClass = 'sl-error-button sl-error-button--lg';
        break;
      default:
    }
    return button;
  };
  const renderUploadButton = (defaultText, SuccessMSG) => {
    const { text, colorClass } = getButtonDetail(defaultText);
    const className = colorClass;

    return (
      <React.Fragment>
        <LargeMuiButton className={className} style={{ marginTop: '14px', marginRight: '14px' }}>
          <label htmlFor="file-upload">
            {text === 'PROCESSING' ? (
              <CircularProgress size={15} thickness={5} className={classes.progressWhite} style={{ marginRight: 7 }} />
            ) : null}
            <span>{text}</span>
          </label>
          {['in progress', 'queued'].includes(state.status) ? null : (
            <input id="file-upload" type="file" onChange={(evt) => handleUpload(evt)} />
          )}
        </LargeMuiButton>
        <br />
        <br />
        {state.status === 'finished successfully' && SuccessMSG ? <SuccessMSG /> : null}{' '}
      </React.Fragment>
    );
  };

  const loadingElement = (
    <CircularProgress
      size={40}
      thickness={2}
      className={classes.progressBlack}
      style={{
        marginTop: 20,
        marginLeft: 237,
        minHeight: 0
      }}
    />
  );

  const uploadGroupElement = (
    <div className="upload_group">
      <span>
        {`${buttons.uploadFile.desc} `}
        <strong>{retailer.name}</strong>.
      </span>
      <div>
        {renderUploadButton(buttons.uploadFile.text, buttons.uploadFile.success)}
        <br />
        {renderTooltip(state.latest)}
      </div>
    </div>
  );

  const showLoadingOrUploadElement = isLoading ? loadingElement : uploadGroupElement;
  const showUploadGroupElement = isContentUploadEnabled(userEmail) ? showLoadingOrUploadElement : null;
  // This will prevent super nested logic inside return
  const uploadButtonArea = isOmni ? showUploadGroupElement : showLoadingOrUploadElement;

  return (
    <div className="user_account_upload">
      <h4 className="sl-header-text">{headerText}</h4>
      <hr className="sl-divider sl-divider--no-margin-top sl-divider--sm-margin-bottom" />
      {children}
      <div className="retailer_select">
        <Select
          variant="standard"
          disabled={['in progress', 'queued'].includes(state.status)}
          value={retailer.id}
          onChange={handleRetailerChange}
          classes={{ root: classes.root, select: classes.select, disabled: classes.disabled }}
          IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
        >
          {retailers.map((item, idx) => (
            <MenuItem key={idx} value={item.id}>
              {item.displayName}
            </MenuItem>
          ))}
        </Select>
        <div className="last_update">
          {uploadType === 'multi-retailer-upload'
            ? `Last Successful Update: ${lastUpdated}`
            : `Last Update: ${lastUpdated}`}
        </div>
      </div>
      <div className="user_upload_body">
        <div className="download_group">
          <div>
            <span>{buttons.downloadTemplete.desc}</span>
            <LargeMuiButton
              secondary
              style={{ marginTop: '14px' }}
              onClick={() => downloadTemplete(buttons.downloadTemplete.api)}
            >
              {buttons.downloadTemplete.text}
            </LargeMuiButton>
          </div>
          {buttons.downloadLatest ? (
            <div>
              <span>{buttons.downloadLatest.desc}</span>
              <LargeMuiButton
                secondary
                style={{ marginTop: '14px' }}
                onClick={() => downloadTemplete(`${buttons.downloadLatest.api}${retailerParamKey}${retailer.id}`)}
              >
                {buttons.downloadLatest.text}
              </LargeMuiButton>
            </div>
          ) : null}
        </div>

        {uploadButtonArea}
      </div>

      {renderErrors(state.errors)}
    </div>
  );
};

UserAccountUpload.propTypes = propTypes;
UserAccountUpload.defaultProps = defaultProps;

export default connect(mapStateToProps)(withStyles(muiStyles)(UserAccountUpload));
