import _get from 'lodash/get';
import _last from 'lodash/last';

import PageContextMenu from 'src/components/PageContextMenu';
import { ExportIcon, CompareScaleIcon, CrossIcon } from 'src/components/SvgIcons';
import getScorecardPageLayout from './Atlas/ScorecardPageLayout';
import getSummaryPageLayout from './SummaryPageLayout';
import getSalesPageLayout from './Atlas/SalesPageLayout';
import getTrafficPageLayout from './Atlas/TrafficPageLayout';
import getConversionPageLayout from './Atlas/ConversionPageLayout';
import { flattenWidgets } from 'src/components/Layout/LayoutUtil';

const getPageLayoutGetter = (name) =>
  ({
    summaryPage: getSummaryPageLayout,
    scorecard: getScorecardPageLayout,
    traffic: getTrafficPageLayout,
    conversion: getConversionPageLayout,
    promotions: getConversionPageLayout
  }[name] || getSalesPageLayout);

const getLayoutName = (pageType, tab, metricType) => {
  if (pageType === 'summaryPage') {
    return pageType;
  }
  if (['contentScore', 'rating', 'purchaseRate'].includes(metricType)) {
    return 'conversion';
  }
  return tab;
};

export default {
  getLayoutForEntity({ app, retailer, user, tab, metricType, entity, pageType, filters, entityType }) {
    const layout = getLayoutName(pageType, tab, metricType);
    const getPageLayout = getPageLayoutGetter(layout);
    const pageLayout = getPageLayout({ app, retailer, user, tab, metricType, entity, pageType, entityType });
    const { widgets, enableComparison, CustomPageContainer, dataConfig, ...rest } = pageLayout;
    
    const lastWidgetView = _get(_last(flattenWidgets(widgets)), 'view');
    const enableExport =
      (user.config.exportEnabled || user.config.isStacklineUser) &&
      widgets.length > 0 &&
      lastWidgetView &&
      (lastWidgetView.name === 'weeklyMetricsGridContainer' ||
        lastWidgetView.name === 'promotionsGrid' ||
        (lastWidgetView.gridOptions && lastWidgetView.gridOptions.enableExport));

    if (enableComparison || enableExport) {
      const subMenuItems = [];
      if (enableExport) {
        subMenuItems.push({
          icon: ExportIcon,
          text: 'Add to Download List',
          eventName: 'downloadEntityGrid'
        });
      }

      if (enableComparison) {
        // we need to hide if we do not have an filterss
        const hasAtLeastOneFilter =
          Object.entries(filters).filter(([key, value]) => {
            return key !== 'isClearingFilters' && value !== null;
          }).length > 0;

        if (!hasAtLeastOneFilter) {
          subMenuItems.push({
            icon: CompareScaleIcon,
            text: 'Compare',
            eventName: 'showCompare'
          });
        }

        subMenuItems.push({
          icon: CrossIcon,
          text: 'Clear Filters',
          eventName: 'clearCompareFilters'
        });
      }

      if (!user.config.isRestrictedDemoUser) {
        widgets.push({
          CustomComponent: PageContextMenu,
          name: 'pageContextMenu',
          view: {
            name: 'pageContextMenu',
            buttons: subMenuItems
          }
        });
      }
    }

    return {
      CustomPageContainer,
      enableComparison,
      widgets,
      dataConfig,
      containerStyle: {
        marginTop: 60
      },
      ...rest
    };
  }
};
