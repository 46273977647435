import React from 'react';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import MarginsProductGrid from './MarginsProductGrid';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';

/**
 * Sales Margins Page
 */
const MarginsPage = () => {
  return (
    <BeaconPageContainer>
      <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
        <BeaconComparableSplineChart indexName="sales" fieldName="retailerGrossMargin" />
        <BeaconComparableSplineChart indexName="sales" fieldName="retailerGrossMarginPercent" />
        <BeaconComparableSplineChart indexName="sales" fieldName="brandCogs" />
        <BeaconComparableSplineChart indexName="sales" fieldName="brandGrossMargin" />
        <BeaconComparableSplineChart indexName="sales" fieldName="brandGrossMarginPercent" />
        <MarginsProductGrid />
      </Flex>
    </BeaconPageContainer>
  );
};

export default MarginsPage;
