import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userOperations } from 'src/store/modules/user';
import UserAccountUpload from './UserAccountUpload/UserAccountUpload';

const UserAccountCogs = ({ entityService, retailer, updateUserAccount, getUploadPageDetail }) => {
  if (!entityService.mainEntity) {
    return null;
  }
  const { retailers, buttons, getLatestApi } = getUploadPageDetail('brandCogs');
  return (
    <UserAccountUpload
      headerText="Brand COGS"
      retailers={retailers}
      currRetailer={retailer}
      buttons={buttons}
      updateUserAccount={updateUserAccount}
      getLatestApi={getLatestApi}
      uploadType="brand-cogs-upload"
    >
      <div>Upload your Brand COGS for products in your catalog to better optimize your advertising campaigns.</div>
    </UserAccountUpload>
  );
};
UserAccountCogs.propTypes = {
  entityService: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired,
  updateUserAccount: PropTypes.func.isRequired,
  getUploadPageDetail: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  updateUserAccount: userOperations.updateUserAccount
};

export default connect((state) => state, mapDispatchToProps)(UserAccountCogs);
