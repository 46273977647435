import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from '@mui/styles';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import colors from 'src/utils/colors';
import { useDispatch, useSelector } from 'react-redux';
import { userOperations } from 'src/store/modules/user';
import ReduxStore from 'src/types/store/reduxStore';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import axios from 'axios';
import AppSnackbar from 'src/components/AdManager/Common/AppSnackbar/AppSnackbar';

export interface SignInPromptProps {
  open: DialogProps['open'];
}

const SignInPrompt: React.FC<SignInPromptProps> = ({ open }) => {
  const [dialogOpen, setDialogOpen] = useState(open);
  const [disableRequestDemo, setDisableRequestDemo] = useState(false);
  const [disableRemindLater, setDisableRemindLater] = useState(false);
  const [disableDontShow, setDisableDontShow] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const userState = useSelector((state: ReduxStore) => state.user);
  const hasRequestedDemo: boolean = _get(userState, 'config.uiPreference.adAudit.hasRequestedDemo', false);
  const nextReminder: null | string = _get(userState, 'config.uiPreference.adAudit.nextReminder', null);
  const hasDisabledPrompt: boolean = _get(userState, 'config.uiPreference.adAudit.hasDisabledPrompt', false);
  // hide dont show again message for new user the first time they sign in
  const hideDontShowAgainOption = useRef(nextReminder === null);

  const useStyles = makeStyles(
    {
      dialogRoot: {
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(5, 40, 73, 0.6)'
        }
      },
      dialogPaperAD: {
        padding: 0,
        width: 484,
        height: 355,
        borderRadius: 20,
        color: colors.darkBlue
      },
      messageWrapper: {
        position: 'relative'
      },
      dialogContentRoot: {
        padding: '20px 40px 25px',
        textAlign: 'center'
      },
      title: {
        fontSize: 27,
        fontWeight: 'bolder',
        padding: '0 20px'
      },
      subtitle: {
        fontSize: 16,
        margin: '10px 0'
      },
      submitContainer: {
        padding: '60px 0'
      },
      submitTitle: {
        fontSize: 33,
        fontWeight: 'bolder',
        padding: '0 20px'
      },
      submitSubtitle: {
        fontSize: 16,
        margin: '25px 0'
      },
      iconRoot: {
        position: 'absolute',
        transform: 'scale(1.3)',
        marginTop: 8
      },
      infoIconRoot: {
        left: -10
      },
      closeIconRoot: {
        right: -10,
        '&:hover': {
          cursor: 'pointer'
        }
      },
      actionButtonRoot: {
        backgroundColor: colors.darkBlue,
        borderRadius: 8,
        margin: '10px 0',
        width: 200,
        '&:hover': {
          backgroundColor: colors.darkBlue
        }
      },
      actionButtonDisabled: {
        backgroundColor: colors.lightGrey
      },
      actionButtonLabel: {
        color: '#fff',
        fontSize: 15,
        textTransform: 'none'
      },
      dontShowButtonRoot: {
        backgroundColor: '#fff',
        '&:hover': {
          backgroundColor: '#fff'
        }
      },
      dontShowButtonDisabled: {
        '& .MuiButton-label': {
          color: colors.lightGrey
        }
      },
      dontShowButtonLabel: {
        color: colors.darkBlue,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1,
        textTransform: 'none'
      }
    },
    { index: 1 }
  );

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClose = () => setDialogOpen(false);

  const handleSnackbarOpen = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const updateAdAuditPromptConfig = (
    bodyParams: {
      hasRequestedDemo: boolean;
      nextReminder: null | string;
      hasDisabledPrompt: boolean;
    },
    closeDialogOnSuccess: boolean,
    disableButtonRef: Dispatch<SetStateAction<boolean>>
  ) => {
    disableButtonRef(true);
    axios
      .post(`/api/user/SetAdAudit`, bodyParams)
      .then((response) => {
        disableButtonRef(false);
        if (closeDialogOnSuccess) {
          handleClose();
        }
        dispatch(userOperations.setAdAuditUserPreference(response.data));
      })
      .catch((error) => {
        disableButtonRef(false);
        handleSnackbarOpen(_get(error, 'response.data.message', error.toString()));
        console.error(error);
      });
  };

  const handleRequestDemo = () => {
    // send email notification
    axios
      .post(`/apiAdManager/connect/withEmail`, {
        email: _get(userState, 'session.email', '')
      })
      .then(() => {
        updateAdAuditPromptConfig(
          {
            hasDisabledPrompt,
            nextReminder,
            hasRequestedDemo: true
          },
          false,
          setDisableRequestDemo
        );
      })
      .catch((error) => {
        handleSnackbarOpen(_get(error, 'response.data.message', error.toString()));
        console.error(error);
      });
  };

  const handleRemindLater = () => {
    updateAdAuditPromptConfig(
      {
        hasDisabledPrompt,
        nextReminder: `${moment().add(1, 'M').format('YYYY-MM-DDTHH:mm:ss')}Z`,
        hasRequestedDemo
      },
      true,
      setDisableRemindLater
    );
  };

  const handleDontShow = () => {
    updateAdAuditPromptConfig(
      {
        hasDisabledPrompt: true,
        nextReminder,
        hasRequestedDemo
      },
      true,
      setDisableDontShow
    );
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        disableEscapeKeyDown={!hasRequestedDemo}
        classes={{ root: classes.dialogRoot, paper: classes.dialogPaperAD }}
        onClose={handleClose}
      >
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <div className={classes.messageWrapper}>
            {!hasRequestedDemo ? (
              <>
                <InfoIcon classes={{ root: `${classes.iconRoot} ${classes.infoIconRoot}` }} />
                <div className={classes.title}>You are using the read-only version of Ad Manager.</div>
                <div className={classes.subtitle}>
                  If you’re interested in exploring a fully read/write ad tech solution, our team would be happy to walk
                  you through an overview.
                </div>
                <div>
                  <Button
                    disableRipple
                    disabled={disableRequestDemo}
                    classes={{
                      root: classes.actionButtonRoot,
                      label: classes.actionButtonLabel,
                      disabled: classes.actionButtonDisabled
                    }}
                    onClick={handleRequestDemo}
                  >
                    Request a demo
                  </Button>
                </div>
                <div>
                  <Button
                    disableRipple
                    disabled={disableRemindLater}
                    classes={{
                      root: classes.actionButtonRoot,
                      label: classes.actionButtonLabel,
                      disabled: classes.actionButtonDisabled
                    }}
                    onClick={handleRemindLater}
                  >
                    Remind me later
                  </Button>
                </div>
                <div>
                  {!hideDontShowAgainOption.current && (
                    <Button
                      disableRipple
                      disabled={disableDontShow}
                      classes={{
                        root: classes.dontShowButtonRoot,
                        label: classes.dontShowButtonLabel,
                        disabled: classes.dontShowButtonDisabled
                      }}
                      onClick={handleDontShow}
                    >
                      Do not show me again
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <>
                <CloseIcon onClick={handleClose} classes={{ root: `${classes.iconRoot} ${classes.closeIconRoot}` }} />
                <div className={classes.submitContainer}>
                  <div className={classes.submitTitle}>Thank you for requesting a demo.</div>
                  <div className={classes.submitSubtitle}>
                    Your request has been successfully submitted and we will be in touch shortly.
                  </div>
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <AppSnackbar open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarMessage} />
    </>
  );
};

SignInPrompt.propTypes = {
  open: PropTypes.bool.isRequired
};

export default SignInPrompt;
