import React, { useMemo } from 'react';
import { useMetricFormatter, useAppSelector } from 'src/utils/Hooks';
import Visualization from 'src/components/Layout/Advertising/ConnectPageLayout/components/Visualization';
import PurchasedTogetherGrid from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/FrequentlyPurchasedTogether/PurchasedTogetherGrid';
import {
  adPurchasedTogetherAdvancedSearchParser,
  buildRequestForPurchasedTogether,
  convertPurchasedTogetherGridDataToCsv
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/FrequentlyPurchasedTogether/utils';
import useAmcDateRange from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/hooks/useAmcDateRange';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import _get from 'lodash/get';
import '../../../ConnectPage.scss';
import { Divider } from '@mui/material';
import colors from 'src/utils/colors';
import GridExportMenu from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/FrequentlyPurchasedTogether/GridExportMenu';

const PurchasedTogetherVisualization = () => {
  const { startDayId, endDayId } = useAmcDateRange();
  const { retailer, mainTimePeriod } = useAppSelector((state) => state);
  const formatMetric = useMetricFormatter();

  // Advanced Search Request Body
  const purchasedTogetherRequestBody = useMemo(
    () => buildRequestForPurchasedTogether({ startDayId, endDayId, retailerId: retailer.id }),
    [endDayId, startDayId, retailer.id]
  );

  // Fetching data
  const { data } = useGenericAdvancedSearch({
    requestBody: purchasedTogetherRequestBody,
    queryKeys: purchasedTogetherRequestBody,
    requestId: 'frequently-purchased-together-grid'
  });

  // Data Access and Parsing for Grid
  const rowData = useMemo(() => {
    if (data) {
      const response = _get(data, 'data[0]');
      return adPurchasedTogetherAdvancedSearchParser(response, formatMetric);
    }
    return null;
  }, [data]);

  return (
    <>
      <GridExportMenu
        styles={{ position: 'relative', zIndex: '2', width: '40px', height: '40px', left: '94%', top: '45px' }}
        exportCsvData={() => convertPurchasedTogetherGridDataToCsv({ rowData, dateRange: mainTimePeriod.displayName })}
      />
      <Visualization
        titleProps={{
          title: 'Frequently Purchased Together'
        }}
      >
        <Divider sx={{ color: colors.borderGrey }}></Divider>

        <div style={{ marginTop: '80px' }}>
          <PurchasedTogetherGrid rowData={rowData !== null ? rowData : []} />
        </div>
      </Visualization>
    </>
  );
};

export default PurchasedTogetherVisualization;
