import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import entitySearchServiceTypes from 'src/store/modules/entitySearchService/types';
import rootReducers from './reducers';

export default () => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [thunk];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];

  let composeEnhancers = compose;

  if (__DEV__ || __STAGING__) {
    const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    if (typeof composeWithDevToolsExtension === 'function') {
      composeEnhancers = composeWithDevToolsExtension({
        actionSanitizer: (action) => {
          if (action.type === entitySearchServiceTypes.RECEIVE_ENTITY_SALES_METRICS) {
            action.statePropertyValue = '[stripped data]';
          }

          return action;
        },
        trace: true,
        traceLimit: 300,
        maxAge: 1000, // Retains last 1000 actions
        stateSanitizer: (state) =>
          state.entitySearchService
            ? { ...state, entitySearchService: 'stripped for performance; use `ess()` function in console.' }
            : state
      });
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    // makeRootReducer()
    rootReducers,
    // persistedState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );
  store.asyncReducers = {};

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  // store.unsubscribeHistory = browserHistory.listen(updateLocation(store));

  // if (module.hot) {
  //   module.hot.accept('./reducers', () => {
  //     store.replaceReducer(makeRootReducer(store.asyncReducers));
  //   });
  // }

  return store;
};
