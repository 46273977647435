import types from './types';
import actions from './actions';
import { ValueOf } from 'sl-api-connector/types';

export interface OmniBrandState {
  isFetching: boolean;
  data: { countryCode: string; countryName: string }[];
}

const initialState: OmniBrandsState = {
  isFetching: true,
  data: []
};

const omniBrandsService = (state: OmniBrandsState = initialState, action: ReturnType<ValueOf<typeof actions>>) => {
  switch (action.type) {
    case types.RECEIVE_ALL_OMNI_COUNTRIES:
      return {
        isFetching: false,
        data: Object.assign([], action.data)
      };
    default:
      return state;
  }
};

export default omniBrandsService;
