import updateComparisonTimePeriod from '../comparison-time-period/operations';
import Creators from './actions';
import calculateAdditionalTimePeriods from './selectors';

export const { updateMainTimePeriod } = Creators;

export const updateAllTimePeriods = (allWeekIds, updatedMainTimePeriod, updatedComparisonTimePeriod) => (
  dispatch,
  getState
) => {
  const {
    app,
    mainTimePeriod: { availableMainTimePeriods: timePeriods }
  } = getState();
  const updatedAvailableTimePeriods = calculateAdditionalTimePeriods(
    allWeekIds,
    timePeriods,
    updatedMainTimePeriod,
    app
  );
  const {
    startWeek,
    endWeek,
    id,
    shortDisplayName,
    startWeekStartDate,
    startDayId,
    endWeekEndDate,
    endDayId
  } = updatedMainTimePeriod;
  const {
    endWeek: comparisonEndWeek,
    startWeek: comparisonStartWeek,
    comparisonIndex,
    startWeekStartDate: comparisonStartWeekStartDate,
    startDayId: comparisonStartDayId,
    endWeekEndDate: comparisonEndWeekEndDate,
    endDayId: comparisonEndDayId
  } = updatedComparisonTimePeriod;

  return Promise.all([
    dispatch(
      updateMainTimePeriod(
        startWeek,
        endWeek,
        id,
        shortDisplayName,
        updatedAvailableTimePeriods,
        startWeekStartDate,
        startDayId,
        endWeekEndDate,
        endDayId
      )
    ),
    dispatch(
      updateComparisonTimePeriod(
        comparisonIndex,
        comparisonStartWeek,
        comparisonEndWeek,
        comparisonStartDayId,
        comparisonEndDayId,
        comparisonStartWeekStartDate,
        comparisonEndWeekEndDate
      )
    )
  ]);
};
