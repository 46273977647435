import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import DonutChartLoading from 'src/components/Charts/Donut/DonutChartLoading';
import CircularProgressPercentage from 'src/components/common/UploadAnimation/CircularProgressPercentage';
import { useMetricByWeekId } from 'src/serverProxy/useMetricByWeekId';
import { useMetricFormatter } from 'src/utils/Hooks';
import { safeDivide } from 'src/utils/app';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';

const CustomDonutLabel = ({ percentage }: { percentage: number }) => {
  const formatMetric = useMetricFormatter();
  return (
    <Flex flexDirection="column" alignItems="center" gap="xs">
      <Flex gap="sm" alignItems="center">
        <Text
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '63px',
            fontSize: '48px',
            fontWeight: 'bold'
          }}
        >
          {formatMetric(percentage * 100, METRICTYPE.DECIMAL, { decimalPlaces: 1, showFullValue: true })}
        </Text>
        <Text
          sx={{
            fontSize: '20px',
            fontWeight: 'bold'
          }}
        >
          %
        </Text>
      </Flex>
      <Text
        variant="h6"
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '21px',
          letterSpacing: 'normal'
        }}
      >
        Share of Total Sales
      </Text>
    </Flex>
  );
};

export const PromotionCountDonutChart = () => {
  const { primaryTotal: retailSales, isLoading: retailSalesLoading } = useMetricByWeekId({
    indexName: BEACON_INDEX_NAMES.SALES,
    fieldName: 'retailSales'
  });
  const { primaryTotal: retailSalesDelta, isLoading: retailSalesDeltaLoading } = useMetricByWeekId({
    indexName: BEACON_INDEX_NAMES.PROMOTIONS,
    fieldName: 'retailSales'
  });
  const percentage = safeDivide(retailSalesDelta, retailSales, 0);

  return (
    <div style={{ width: '308px', height: '308px' }}>
      {retailSalesLoading || retailSalesDeltaLoading ? (
        <DonutChartLoading />
      ) : (
        <CircularProgressPercentage
          customLabel={<CustomDonutLabel percentage={percentage} />}
          percentage={percentage}
        />
      )}
    </div>
  );
};
