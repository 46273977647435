const RECEIVE_AD_TARGET_MATCH_TYPES = 'src/adTargetMatchTypes/RECEIVE_AD_TARGET_MATCH_TYPES' as const;
const CLEAR_AD_TARGET_MATCH_TYPES = 'src/adTargetMatchTypes/CLEAR_AD_TARGET_MATCH_TYPES' as const;

export interface AdTargetMatchType {
  id: string;
  targetMatchType: string;
  name: string;
  displayName: string;
  type: 'adTargetMatchType';
}

export default {
  RECEIVE_AD_TARGET_MATCH_TYPES,
  CLEAR_AD_TARGET_MATCH_TYPES
};
