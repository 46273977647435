import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';
import { withBus } from 'react-bus';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsCustomEvents from 'highcharts-custom-events';
import saveAs from 'file-saver';
import invariant from 'invariant';

import colors from 'src/utils/colors';
import MenuButton from 'src/components/common/Buttons/MenuButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  DocumentIcon,
  ContentIcon,
  SubCategoryIcon,
  ChevronIcon,
  CompareScaleIcon,
  CrossIcon
} from 'src/components/SvgIcons';
import './GenericChart.scss';
import './Legend.scss';
import { enhanceChartPropsWithDefaults, getEnhancedPlotOptions } from './chartPropDefaults';
import convertLineChartSeriesToDelimitedData from './SeriesConverters/lineChart';
import { track } from 'src/utils/mixpanel';
import { isDrive } from 'src/utils/app';
import isEqual from 'lodash/isEqual';
import domtoimage from 'src/utils/dom-to-image';
import { useSnackbarState } from 'src/providers/SnackbarProvider';

// Load Highcharts modules
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/map')(Highcharts);
require('highcharts/modules/venn')(Highcharts);

HighchartsMore(Highcharts);
HighchartsCustomEvents(Highcharts);

const HORIZONTAL_SCROLL_THRESHOLD = 8;

/**
 * Renders a tooltip above the title by displaying an invisible, absolutely-positioned copy of the title above the
 * chart itself and registering a tooltip for that, avoiding the horrors of installing React components into the
 * insides of Highcharts.
 */
const maybeRenderTitleTooltip = (chartProps) => {
  if (!chartProps.title || !chartProps.title.text || !chartProps.title.TooltipComponent) {
    return null;
  }

  const { text, TooltipComponent } = chartProps.title;
  return (
    <TooltipComponent placement="right-end">
      <div
        style={{
          position: 'absolute',
          zIndex: 2,
          fontSize: 28,
          fontFamily: '"Roboto", sans-serif',
          top: -7,
          left: 9,
          cursor: 'default',
          color: 'transparent'
        }}
      >
        {text}
      </div>
    </TooltipComponent>
  );
};

class GenericChart extends React.Component {
  static defaultProps = {
    className: '',
    loadNextPage: () => {},
    convertSeriesToDelimitedData: convertLineChartSeriesToDelimitedData,
    doChartRefreshAfterUpdate: false
  };

  static propTypes = {
    chartSeries: PropTypes.array.isRequired,
    chartProps: PropTypes.object.isRequired,
    convertSeriesToDelimitedData: PropTypes.func,
    eventBus: PropTypes.object.isRequired,
    className: PropTypes.string,
    loadNextPage: PropTypes.func,
    comparisonTimePeriod: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    doChartRefreshAfterUpdate: PropTypes.bool
  };

  constructor(props) {
    super(props);

    // This is used for adding manual event listeners to the chart in some cases.  It allows event listeners
    // to be set that find this particular chart's elements and attach to them dynamically.  By building it in the
    // constructor, we don't have problems with the random ID being regenerated during component updates etc.
    this.uniqChartId = btoa(Math.random());
  }

  shouldComponentUpdate(nextProps) {
    return !!['className', 'chartSeries', 'chartProps'].find((key) => !isEqual(this.props[key], nextProps[key]));
  }

  componentDidUpdate() {
    const { chartSeries, doChartRefreshAfterUpdate } = this.props;
    if (this.chart && doChartRefreshAfterUpdate) {
      this.chart.update({ series: chartSeries });
      this.chart.redraw();
    }
  }

  getChartOptions = (props) => {
    const { chartProps, chartSeries, app } = props;

    if (!chartProps.xAxis || chartProps.xAxis.length === 0) {
      chartProps.xAxis = [{}];
    }
    if (!chartProps.yAxis || chartProps.yAxis.length === 0) {
      chartProps.yAxis = [{}];
    }

    const enhancedChartProps = enhanceChartPropsWithDefaults(
      { ...chartProps, tab: _get(app, 'queryParams.tab', ''), subtab: _get(app, 'queryParams.subtab', '') },
      this.uniqChartId,
      this.props.eventBus
    );
    const enhancedPlotOptions = getEnhancedPlotOptions(chartProps);

    // Show markers if just one point on the trend chart
    if (chartProps.chart.type === 'areaspline') {
      chartSeries.forEach((series) => {
        if (series.data.filter((dp) => dp[1] !== null).length < 2) {
          series.marker.enabled = true;
        }
      });
    }

    return {
      ...enhancedChartProps,
      plotOptions: enhancedPlotOptions,
      series: chartSeries,
      noData: {
        style: {
          fontWeight: '400',
          fontSize: '20px',
          color: colors.quartz
        }
      }
    };
  };

  getExportButtonInfo = (chartProps) => {
    const exportMenu = {
      mainButton: {
        icon: <MoreHorizIcon style={{ color: colors.darkBlue }} />,
        direction: 'down'
      },
      subItems: [
        { icon: <ContentIcon />, text: 'Save image', onClick: () => this.saveChart() },
        { icon: <DocumentIcon />, text: 'Export CSV', onClick: () => this.exportCsvData() },
        {
          icon: <SubCategoryIcon />,
          text: 'Copy image',
          onClick: () => this.createChart()
        }
      ]
    };
    if (_get(chartProps, ['exporting', 'showCompare'])) {
      exportMenu.subItems.push({
        icon: <CompareScaleIcon />,
        text: 'Compare',
        onClick: () => this.props.eventBus.emit('showCompare')
      });
      if (isDrive && window.location.search.includes('ctype')) {
        exportMenu.subItems.push({
          icon: <CrossIcon />,
          text: 'Clear Comparison',
          onClick: () => this.props.eventBus.emit('clearComparison')
        });
      }
    }
    return exportMenu;
  };

  setChartContainerRef = (element) => {
    this.chartContainer = element;
  };

  setChartInstanceRef = (element) => {
    this.chart = element ? element.chart : {};
  };

  exportCsvData = async () => {
    // Track the event
    track('export chart as csv');

    // Prepare the arguments for fetching data
    const propsForFetch = [
      this.props.chartSeries,
      this.props.comparisonTimePeriod.id === 'prior-period',
      this.props.comparisonTimePeriod,
      this.props.mainTimePeriod
    ];

    // Convert the chart series to delimited data (may return a promise)
    const data = await this.props.convertSeriesToDelimitedData(...propsForFetch);

    // Create a blob with the data and save it as a CSV file
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'chart_data.csv');
  };

  moveLeft = (steps) => {
    const { dataMin } = this.chart.xAxis[0].getExtremes();
    let { min, max } = this.chart.xAxis[0].getExtremes();

    if (min - steps < dataMin) {
      max -= min - dataMin;
      min = dataMin;
    } else {
      min -= steps;
      max -= steps;
    }

    this.chart.xAxis[0].setExtremes(min, max);
  };

  moveRight = (steps) => {
    const { loadNextPage } = this.props;
    const { dataMax } = this.chart.xAxis[0].getExtremes();
    let { min, max } = this.chart.xAxis[0].getExtremes();

    if (max === dataMax) {
      loadNextPage();
    } else if (max + steps > dataMax) {
      min += dataMax - max;
      max = dataMax;
    } else {
      min += steps;
      max += steps;
    }

    this.chart.xAxis[0].setExtremes(min, max);
  };

  createChart = async () => {
    useSnackbarState.setState((prev) => ({ ...prev, open: true, message: 'Copying image...', type: 'success' }));
    try {
      const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      const blob = await domtoimage.toBlob(this.chartContainer, { additionalOptions: { scale: 2 } });

      if (isFirefox) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'image.png'; // Provide a default filename
        document.body.appendChild(a); // Append the link to the document
        a.click();
        document.body.removeChild(a); // Clean up
      } else {
        const clipboardItem = new ClipboardItem({ 'image/png': blob });
        navigator.clipboard.write([clipboardItem]);
      }

      useSnackbarState.setState((prev) => ({
        ...prev,
        open: true,
        message: 'Copied image!',
        type: 'success',
        timeout: 3000
      }));
    } catch (e) {
      console.error('Error copying image to clipboard', e);
      useSnackbarState.setState((prev) => ({
        ...prev,
        open: true,
        message: 'Image copy failure',
        type: 'error',
        timeout: 3000
      }));
    }
  };

  saveChart = async () => {
    track('saved chart as image');
    const blob = await domtoimage.toBlob(this.chartContainer, { additionalOptions: { scale: 2 } });
    saveAs(blob, 'chart_image.png');
  };

  renderExportMenu = (chartProps) => {
    if (_get(chartProps, ['exporting', 'enabled'])) {
      const menuInfo = this.getExportButtonInfo(chartProps);
      return <MenuButton menuInfo={menuInfo} />;
    }
    return null;
  };

  renderHorizontalScroll(chartProps) {
    if (!_get(chartProps, ['horizontalScrolling', 'enabled'])) {
      return null;
    }
    // Used to adjust the scroll controls container
    const scrollStyles = _get(chartProps, ['horizontalScrolling', 'scrollStyles'], {});
    const { step } = chartProps.horizontalScrolling;

    invariant(
      !_isNil(step),
      '`chartProps.horizontalScrolling.step` must be set when `chartProps.horizontalScrolling.enabled` is set.'
    );

    return (
      <div className="chart-horizontal-scrolling" style={{ ...scrollStyles }}>
        <button onClick={() => this.moveLeft(step)}>
          <ChevronIcon style={{ stroke: colors.darkBlue, height: '40px', width: '40px', transform: 'rotate(90deg)' }} />
        </button>
        <button onClick={() => this.moveRight(step)}>
          <ChevronIcon
            style={{ stroke: colors.darkBlue, height: '40px', width: '40px', transform: 'rotate(-90deg)' }}
          />
        </button>
      </div>
    );
  }

  /**
   * Original logic only checked if xAxis length was greater than 8. xAxis is not always an array.
   * Allows for setting the horizontal scroll render logic in the chartProps
   */
  shouldRenderHorizontalScroll = (axis, chartSettings) => {
    const axisArray = _get(axis, [0, 'categories'], []);
    const shouldScroll = _get(chartSettings, 'horizontalScrolling.enabled', false);
    return axisArray.length > HORIZONTAL_SCROLL_THRESHOLD || shouldScroll;
  };

  render() {
    const { className, chartSeries, chartProps } = this.props;
    if (!chartSeries || !chartProps) {
      return null;
    }
    const chartOptions = this.getChartOptions(this.props);
    const { xAxis } = chartProps;
    return (
      <div className={`line-chart ${className}`} style={{ position: 'relative' }}>
        {this.renderExportMenu(chartProps, chartSeries)}
        {this.shouldRenderHorizontalScroll(xAxis, chartProps) && this.renderHorizontalScroll(chartProps)}
        {maybeRenderTitleTooltip(chartProps)}
        <div className="line-chart__container" ref={this.setChartContainerRef}>
          <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={this.setChartInstanceRef} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => _pick(state, ['comparisonTimePeriod', 'mainTimePeriod', 'app']);

export default withBus('eventBus')(connect(mapStateToProps)(GenericChart));
