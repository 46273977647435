import { useMemo } from 'react';
import { useCreateBaseMetricRequestBuilder } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { AdvancedSearchResponse } from 'src/serverProxy/useTableData';
import { useAppSelector } from 'src/utils/Hooks';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import _get from 'lodash/get';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';

export interface UseAdvancedSearchDataField {
  name: string;
}

export interface UseAdvancedSearchDataArgs {
  fields: UseAdvancedSearchDataField[];
  groupByField: string;
  indexName: string;
  requestBuilder?: (builder: AdvancedSearchRequestBuilder, comparison: boolean) => AdvancedSearchRequestBuilder;
}

interface AdvancedSearchParsedDatum {
  fieldId: string;
  values: Record<string, number>;
  comparisonValues: Record<string, number>;
  metadata: Record<string, any>;
}
/**
 * Accepts an arbitrary number of index/field name combinations to return the
 * combined data in a single array where each item is an object containing
 * the value and comparison value for each field.
 */
export default function useAdvancedSearchData({
  fields,
  groupByField,
  indexName,
  requestBuilder
}: UseAdvancedSearchDataArgs) {
  const { startWeek, endWeek } = useAppSelector((state) => state.mainTimePeriod);
  const { startWeek: comparisonStartWeek, endWeek: comparisonEndWeek } = useAppSelector(
    (state) => state.comparisonTimePeriod
  );
  const createBaseMetricRequestBuilder = useCreateBaseMetricRequestBuilder();

  /**
   * There will be two objects in the request - one for
   * the current time period and one for the comparison time period. This is the
   * array of request bodies that will be sent to the server.
   */
  const primaryRequestBuilder = useMemo(() => {
    const builder = createBaseMetricRequestBuilder({
      fields,
      indexName,
      requestId: 'advancedSearchData',
      groupByFieldName: groupByField
    })
      .addConditionRangeFilter('weekId', startWeek, endWeek)
      .setPageSize(100)
      .modifyAggregation((aggBuilder) => aggBuilder.addConditionRangeFilter('weekId', startWeek, endWeek));

    if (requestBuilder) {
      requestBuilder(builder, false);
    }

    return builder;
  }, [createBaseMetricRequestBuilder, endWeek, fields, groupByField, indexName, requestBuilder, startWeek]);

  // Primary data
  const { data: response, isLoading } = useGenericAdvancedSearch<AdvancedSearchResponse<any>[]>({
    requestBody: [primaryRequestBuilder.build()],
    queryKeys: [primaryRequestBuilder.build()],
    requestId: 'advancedSearchData'
  });

  // Comparison request bodies - we need to add the fields returned from the primary data as
  // term filters in order to get the data for the same set
  const comparisonRequestBuilder = useMemo(() => {
    const builder = primaryRequestBuilder
      .clone()
      .clearConditionRangeFilters()
      .addConditionRangeFilter('weekId', comparisonStartWeek, comparisonEndWeek) // Comparison date range
      .addConditionTermFilter(
        groupByField,
        'should',
        // Add in the field IDs from the primary data as term filters
        _get(response, ['data', 0, 'aggregations', `by_${groupByField}`], []).map((row) => row.fieldId)
      )
      .modifyAggregation((aggBuilder) =>
        aggBuilder
          .clearConditionRangeFilters()
          .addConditionRangeFilter('weekId', comparisonStartWeek, comparisonEndWeek)
      );

    if (requestBuilder) {
      requestBuilder(builder, true);
    }
    return builder;
  }, [comparisonEndWeek, comparisonStartWeek, groupByField, primaryRequestBuilder, requestBuilder, response]);

  // Secondary data request
  const { data: comparisonResponse, isLoading: compareLoading } = useGenericAdvancedSearch<
    AdvancedSearchResponse<any>[]
  >({
    shouldPerformFetch: !isLoading,
    queryKeys: [comparisonRequestBuilder.build()],
    requestBody: [comparisonRequestBuilder.build()],
    requestId: 'advancedSearchData'
  });

  /**
   * Merge the primary and comparison data for each index into a single array
   */
  const parsedData: AdvancedSearchParsedDatum[] = useMemo(() => {
    if (isLoading || compareLoading) {
      return [];
    }

    const responseBodies = _get(response, 'data', []);
    const comparisonResponseBodies = _get(comparisonResponse, 'data', []);

    const primaryData = _get(responseBodies, [0, 'aggregations', `by_${groupByField}`], []);
    const comparisonData = _get(comparisonResponseBodies, [0, 'aggregations', `by_${groupByField}`], []);

    // Since comparison data won't be in the same order as primary, we map the field to its data
    // so we can combine primary and secondary data in constant time
    const comparisonDataMap = comparisonData.reduce((acc, row) => {
      return {
        ...acc,
        [row.fieldId]: row
      };
    }, {});

    // Combine primary and comparison data into a single array
    return primaryData.map((row) => {
      const comparisonRow = comparisonDataMap[row.fieldId] || {};
      return {
        fieldId: row.fieldId,
        values: row.additionalValues,
        comparisonValues: comparisonRow.additionalValues || {},
        metadata: row.additionalMetaData
      };
    });
  }, [compareLoading, comparisonResponse, groupByField, isLoading, response]);

  return {
    data: parsedData,
    isLoading: isLoading || compareLoading
  };
}
