import React, { useEffect, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _omit from 'lodash/omit';

import TextInput from 'src/components/common/Form/TextInput';
import * as genericFormActions from 'src/store/modules/genericForm/actions';
import { withDisplayName } from 'src/utils/hoc';
import { panic } from 'src/utils/mixpanel';

/**
 * Combines the `fieldDefinitions` with defaults and overrides, returning a field definitions object that contains
 * the merged definitions from all of them.
 *
 * @param {*} fieldDefinitions See the prop of the same name on `GenericForm`
 * @param {*} defaultFieldDefinition See the prop of the same name on `GenericForm`
 * @param {*} fieldDefinitionOverrides See the prop of the same name on `GenericForm`
 * @param {*} defaultFieldDefinitionOverride See the prop of the same name on `GenericForm`
 */
const buildFieldDefinitions = (
  fieldDefinitions,
  defaultFieldDefinition,
  fieldDefinitionOverrides,
  defaultFieldDefinitionOverride
) =>
  Object.keys(fieldDefinitions).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ...defaultFieldDefinition,
        ...fieldDefinitions[key],
        ...defaultFieldDefinitionOverride,
        ...fieldDefinitionOverrides[key]
      }
    }),
    {}
  );

/**
 * Loops through all fields in the form and validates them, returning `true` if all fields are valid and all `required`
 * fields have values.
 *
 * @param {object} fieldDefinitions The `fieldDefinitions` prop that is used to get the `required` flag and validator
 * for every field in the form.
 * @param {object} state The current state of the `GenericForm`
 */
const isFormValid = (fieldDefinitions, state) =>
  Object.entries(fieldDefinitions).every(([name, { validator, required = true }]) => {
    const value = state[name];
    if (required && _isNil(value)) {
      // Value is empty and required, thus invalid
      return false;
    } else if (validator) {
      // The validator returns a falsey value if it's valid
      const isInvalid = validator(value);
      return !isInvalid;
    }

    // Value is empty and not required, thus valid
    return true;
  });

/**
 * HOC that creates an input component enhanced with the state and action creators of the parent `GenericForm`.
 * It automatically maps in the field's value and `onChange` props from Redux, adds in the props from the field
 * definition that matches up with its name, and returns a simple component that can be used in the `FormComponent`
 * to display the field.
 *
 * @param {string} formName The name of the `GenericForm` to which this component will belong
 * @param {object} builtFieldDefinitions An object containing the merged field definitions consisting of the base
 * `fieldDefinitions` combined with `defaultFieldDefinition` as well as overrides.
 * @param {React.Component} WrapperComponent A wrapper component that will be rendered around the generated input
 * component (the `TextInput` will be passed as the `children` prop to it).
 */
const createFieldComponent = (formName, builtFieldDefinitions, WrapperComponent) =>
  connect(
    ({ genericForm }) => {
      const formData = genericForm[formName] || {};
      return {
        formState: formData.formState || {},
        isSubmitted: formData.isSubmitted || false
      };
    },
    {
      setValue: (key, value) => genericFormActions.setValue(formName, key, value)
    }
  )(({ wrapperProps, name, formState, isSubmitted, setValue, ...props }) => {
    const InputComponent = useMemo(
      () =>
        WrapperComponent
          ? withDisplayName('WrappedEnhancedInput')(({ ...inputProps }) => {
              const fieldDef = builtFieldDefinitions[name];
              if (!fieldDef) {
                return panic(`No field definition named "${name}" found in \`fieldDefinitions\``);
              }

              const InnerInputComponent = fieldDef.InputComponent || TextInput;
              const RealWrapperComponent = fieldDef.WrapperComponent || WrapperComponent;

              return (
                <RealWrapperComponent {...(fieldDef.wrapperProps || {})} {...(wrapperProps || {})}>
                  <InnerInputComponent {...inputProps} {...(fieldDef.wrapperProps || {})} />
                </RealWrapperComponent>
              );
            })
          : TextInput,
      [name, wrapperProps]
    );

    return (
      <InputComponent
        value={formState ? formState[name] : undefined}
        onChange={(newValue) => setValue(name, newValue)}
        formIsSubmitted={isSubmitted}
        {..._omit(builtFieldDefinitions[name], ['WrapperComponent', 'wrapperProps'])}
        {...props}
      />
    );
  });

/**
 * `GenericForm` is inspired by the `redux-form` library.  It provides a platform through which forms can be constructed
 * from re-useable *field definitions*.  Field definitions are nothing more than a set of props that are are set onto
 * `TextInput` and eventually material-ui `TextField` components.
 *
 * `GenericForm` provides several mechanisms for enhancing/overriding these field definitions for individual use cases,
 * creating wrapper components around the created text fields, handling form submission states, and more.
 *
 * See the documentation on this component's `propTypes` for more API usage and more detailed information.
 */
const GenericForm = ({
  name: formName,
  fieldDefinitions,
  defaultFieldDefinition,
  fieldDefinitionOverrides,
  defaultFieldDefinitionOverride,
  initialState,
  onSubmit,
  formComponentProps,
  FormComponent,
  formState,
  TextInputWrapper,
  isSubmitting,
  isSubmitted,
  initForm,
  setIsSubmitting,
  setIsSubmitted
}) => {
  const builtFieldDefinitions = useMemo(
    () =>
      buildFieldDefinitions(
        fieldDefinitions,
        defaultFieldDefinition,
        fieldDefinitionOverrides,
        defaultFieldDefinitionOverride
      ),
    [fieldDefinitions, defaultFieldDefinition, fieldDefinitionOverrides, defaultFieldDefinitionOverride]
  );

  const Field = useMemo(() => {
    return createFieldComponent(formName, builtFieldDefinitions, TextInputWrapper);
  }, [formName, builtFieldDefinitions, TextInputWrapper]);

  const formIsInitialized = !(
    !formState ||
    (_isEmpty(formState) && !_isEmpty(initialState)) ||
    (_isNil(formState) && !_isNil(initialState))
  );
  useEffect(() => {
    if (!formIsInitialized) {
      initForm(initialState);
    }
  }, [formState, initForm, initialState, formIsInitialized]);

  const handleSubmit = useMemo(
    () => async (completeFormState) => {
      setIsSubmitted(true);
      setIsSubmitting(true);

      if (!isFormValid(builtFieldDefinitions, completeFormState)) {
        setIsSubmitting(false);
        return;
      }

      // TODO: add support for formatting form state?
      await onSubmit(completeFormState);
      setIsSubmitting(false);
    },
    [setIsSubmitted, builtFieldDefinitions, onSubmit, setIsSubmitting]
  );

  if (!formIsInitialized) {
    return null;
  }

  return (
    <FormComponent
      TextInput={Field}
      Field={Field}
      onSubmit={() => (formState ? handleSubmit(formState) : null)}
      isSubmitted={isSubmitted}
      isSubmitting={isSubmitting}
      {...formComponentProps}
    />
  );
};

GenericForm.propTypes = {
  /**
   * The name of the form.  This must be unique among all other `GenericForm` instances created since it is used to
   * map this form's state to a key in Redux.
   */
  name: PropTypes.string.isRequired,
  /**
   * An object of `{ [fieldName]: fieldDefinition }`.  The `name` prop passed to `TextInput` components which are
   * supplied as props by this component to the provided `FormComponent` is used to index into this object and retrieve
   * additional props to add to the underlying `TextInput`.
   */
  fieldDefinitions: PropTypes.object.isRequired,
  /** An object that all field definitions in `fieldDefinitions` are merged into before being applied. */
  defaultFieldDefinition: PropTypes.object,
  /** An object of the same format as that of `fieldDefinitions` that can be used to override the definitions there. */
  fieldDefinitionOverrides: PropTypes.object,
  /** An object that is merged into all field definitions in `fieldDefinitions` *after* all other merging. */
  defaultFieldDefinitionOverride: PropTypes.object,
  /** An initial state object mapping `{ [fieldName]: initialValueForField }` */
  initialState: PropTypes.object,
  /**
   * A function to be called when the form is submitted.  It will be supplied one argument, which is the form's state
   * object.  This function will only be called if the form's state is fully valid (all validators for all fields pass
   * and all required fields have values).
   *
   * This function can return anything or a `Promise`.  In the case that it returns a promise, it will be awaited before
   * setting the form `isSubmitting` state back to `false`.
   */
  onSubmit: PropTypes.func.isRequired,
  /** Extra props to pass through to the rendered `FormComponent` */
  formComponentProps: PropTypes.object,
  /** This should be a React component that will actually create the `TextField`s and render the form.  It will be
   * supplied the following props:
   *
   * \/ DEPRECATED; use `Field` instead.
   * `TextInput`: A component that will render a `TextInput` element that has been enhanced with props from the generic
   *    form's state, error state based on validators, and change handlers that update the state.  It will also be
   *    wrapped in the `TextInputWrapper` (the `TextInput` will be passed to `TextInputWrapper` as the `children`
   *    prop), but the props stay the same and are passed through.
   * `Field`: A component that will render whatever `InputComponent` the field definition with the name corresponding to
   *    the `name` prop passed to it has (defaulting to `TextInput` if none is specified).  The `InputComponent` should
   *    accept `value`, `formIsSubmitted`, and `onChange` props along with all other props provided to the
   *    `fieldDefinition`.
   * `onSubmit`:  A function that should be called when the form's submit button is clicked.  It will run all validators
   *    including checking to make sure that all required fields have values.  If everything passes, it will pass the
   *    form's state object into the `onSubmit` prop passed into `GenericForm` and update the
   * `isSubmitting` flag accordingly.
   * `isSubmitted`: A boolean flag that indicates whether the `onSubmit` function has ever been called.  This includes
   *    if it was called and the form wasn't invalid, meaning that the `GenericForm`'s `onSubmit` handler
   *    has not been called yet.
   */
  FormComponent: PropTypes.func.isRequired,
  /** This prop is mapped in from Redux via `mapStateToProps` */
  formState: PropTypes.object.isRequired,
  /**
   * An optional component that can be provided to wrap the created `InputElement` that will be provided as a prop to
   * the rendered `TextInput`.  The `TextInput` will be rendered as a child of this component.
   */
  TextInputWrapper: PropTypes.func,
  /** This prop is mapped in from Redux via `mapStateToProps` */
  isSubmitting: PropTypes.bool.isRequired,
  /** This prop is mapped in from Redux via `mapStateToProps` */
  isSubmitted: PropTypes.bool.isRequired,
  /**
   * This action creator is mapped in from Redux via `mapDispatchToProps`.  It is used to initialize the Redux state
   * containing this form's values.
   */
  initForm: PropTypes.func.isRequired,
  /** This action creator is mapped in from Redux via `mapDispatchToProps`. */
  setIsSubmitting: PropTypes.func.isRequired,
  /** This action creator is mapped in from Redux via `mapDispatchToProps` */
  setIsSubmitted: PropTypes.func.isRequired
};

GenericForm.defaultProps = {
  defaultFieldDefinition: {},
  fieldDefinitionOverrides: {},
  defaultFieldDefinitionOverride: {},
  initialState: {},
  formComponentProps: {},
  TextInputWrapper: Fragment
};

const mapStateToProps = ({ genericForm }, { name: formName }) => {
  const { formState = null, isSubmitting = false, isSubmitted = false } = genericForm[formName] || {};
  return { formState, isSubmitting, isSubmitted };
};

const mapDispatchToProps = (dispatch, { name: formName }) => ({
  initForm: (initialState) => dispatch(genericFormActions.initForm(formName, initialState)),
  setIsSubmitted: (isSubmitted) => dispatch(genericFormActions.setIsSubmitted(formName, isSubmitted)),
  setIsSubmitting: (isSubmitting) => dispatch(genericFormActions.setIsSubmitting(formName, isSubmitting))
});

export const formComponentPropTypes = {
  TextInput: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericForm);
