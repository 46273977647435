import React from 'react';
import { useMetricFormatter } from 'src/utils/Hooks';
import { ValueOf } from 'sl-api-connector/types';
import { METRICTYPE } from '../../../utils/entityDefinitions';
import { Text } from '@stackline/ui';

interface MetricCellProps {
  value: number;
  metricType: ValueOf<typeof METRICTYPE>;
  percentChange?: number;
  comparisonNode?: React.ReactNode;
}

/**
 * Shows a metric and optionally a percent change for use
 * in a table
 */
const MetricCell = ({ value, metricType, percentChange, comparisonNode }: MetricCellProps) => {
  const formatMetric = useMetricFormatter();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
        gap: '1px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        height: '100%',
        boxSizing: 'border-box'
      }}
    >
      <Text variant="body2">
        {formatMetric(value, metricType, {
          decimalPlaces: metricType === METRICTYPE.PERCENT ? 1 : 0
        })}
      </Text>
      {percentChange !== undefined && (
        <Text variant="body2" color={percentChange >= 0 ? 'success' : 'error'}>
          {percentChange >= 0 ? '+' : ''}
          {formatMetric(percentChange, METRICTYPE.PERCENT, { decimalPlaces: 1 })}
        </Text>
      )}
      {comparisonNode || null}
    </div>
  );
};

export default MetricCell;
