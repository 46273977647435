import React from 'react';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import { SlColumn, useStacklineTheme } from '@stackline/ui';

interface GenericCardLoadingProps {
  width?: number;
  height?: number;
}

/**
 * Generic skeleton for loading cards.
 */
const GenericCardLoading = ({ width = 290, height = 382 }: GenericCardLoadingProps) => {
  const theme = useStacklineTheme();
  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: theme.spacing.sm,
        position: 'relative'
      }}
    >
      <SlColumn spacing="md" horizontalPosition="center">
        <SlSkeleton variant="rounded" height={height} width={width} />
      </SlColumn>
    </div>
  );
};

export default GenericCardLoading;
