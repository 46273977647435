import React, { FC } from 'react';
import { RadioGroup, FormControl, FormLabel, FormControlLabel, Radio } from '@mui/material';
import _capitalize from 'lodash/capitalize';
import { IsActiveElement } from 'src/components/Discover/DiscoverUtils/CommonUtils';

interface DiscoverRadioGroupProps {
  title: string;
  chipFields: string[];
  options: IsActiveElement[];
  radioGroup: string;
  handleClick: (name?: string, index?: number, group?: string) => void;
}
const DiscoverRadioGroup: FC<DiscoverRadioGroupProps> = ({ handleClick, title, radioGroup, options, chipFields }) => {
  return (
    <FormControl>
      <FormLabel id="radio-buttons-group-label">{title}</FormLabel>
      <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
        {chipFields.length > 0 &&
          options.map((fieldName, index) => {
            return (
              <FormControlLabel
                key={index}
                control={<Radio className="custom-radio-discover" checked={fieldName.isActive} />}
                value={_capitalize(fieldName.name)}
                label={_capitalize(fieldName.name)}
                onChange={() => handleClick(fieldName.name, index, radioGroup)}
              />
            );
          })}
      </RadioGroup>
    </FormControl>
  );
};

export default DiscoverRadioGroup;
