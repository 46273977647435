import React, { useMemo } from 'react';
import GenericChart from 'src/components/Charts/GenericChart';
import Highcharts from 'highcharts';
import _merge from 'lodash/merge';

interface StackedColumnChartProps {
  /**
   * Array of Highcharts column options. Make sure to include
   * the `data` property for each item which is what is going
   * to be displayed in each section of the stacked column. Make
   * sure to define `color` for each set of data so it distinctly
   * appears in the stacked column.
   */
  data: Omit<Highcharts.SeriesColumnOptions, 'type'>[];
  /**
   * Optional chart props override. For example, use this to customize
   * the tooltip, legend, data labels, etc.
   */
  chartProps?: Partial<Highcharts.Options>;
  /**
   * GenericChart prop for converting the stacked column data
   * to CSV. Should be given if `exporting.enabled` is true
   * in `chartProps`.
   */
  convertSeriesToDelimitedData?: () => string;
}

/**
 * Stacked bar chart. For example, see https://www.highcharts.com/demo/column-stacked
 */
const StackedColumnChart = ({ data, chartProps, ...rest }: StackedColumnChartProps) => {
  const chartSeries: Highcharts.SeriesColumnOptions[] = useMemo(
    () =>
      data.map((item) => ({
        type: 'column',
        borderRadius: 0,
        ...item
      })),
    [data]
  );

  /**
   * Display props for a stacked bar chart.
   */
  const mergedChartProps: Highcharts.Options = useMemo(() => {
    const defaultOptions: Highcharts.Options = {
      chart: {
        type: 'column'
      },
      xAxis: [
        _merge(
          {
            labels: {
              formatter() {
                return `${this.value}`;
              },
              style: {
                'font-size': '14px'
              }
            }
          },
          chartProps.xAxis || {}
        )
      ],
      yAxis: [
        _merge(
          {
            labels: {
              style: {
                'font-size': '14px'
              }
            }
          },
          chartProps.yAxis || {}
        )
      ],
      plotOptions: {
        barWidth: 130,
        column: {
          stacking: 'normal',
          color: null
        }
      }
    };

    return _merge({}, defaultOptions, chartProps);
  }, [chartProps]);

  return <GenericChart chartSeries={chartSeries} chartProps={mergedChartProps} {...rest} />;
};

export default StackedColumnChart;
