import types from './types';

function updateMainTimePeriod(
  startWeek,
  endWeek,
  id,
  shortDisplayName,
  updatedAvailableTimePeriods,
  startWeekStartDate,
  startDayId,
  endWeekEndDate,
  endDayId
) {
  return (dispatch, getState) => {
    const { retailer, app } = getState();

    dispatch({
      type: types.UPDATE_MAIN_TIME_PERIOD,
      startWeek,
      endWeek,
      startDayId,
      endDayId,
      id,
      shortDisplayName,
      updatedAvailableTimePeriods,
      startWeekStartDate,
      endWeekEndDate,
      retailer,
      app
    });
  };
}

export default {
  updateMainTimePeriod
};
