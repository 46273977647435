import { useDispatch } from 'react-redux';
import { FetchAdditionalDataFn } from './types';
import useFetchEntityMetricsArgs from './useFetchEntityMetricsArgs';
import { useCallback } from 'react';
import { TargetsDataFetcher } from 'src/components/AdManager/Search/GridDataFetchers/Targets/TargetsDataFetcher';
import { useAppSelector } from 'src/utils/Hooks';
import _get from 'lodash/get';
import { useDeepCompareMemo } from 'src/utils/app';
import { Widget } from 'src/types/application/widgetTypes';

/**
 * Returns a callback function for fetching targets sales data.
 */
const useFetchTargetsSalesData = ({
  widget,
  importantOverrides = []
}: {
  widget: Widget;
  importantOverrides: any;
}): FetchAdditionalDataFn => {
  const dispatch = useDispatch();
  const { entity, retailer, app } = useFetchEntityMetricsArgs();
  const targetsWithoutMetrics = useAppSelector((state) =>
    _get(state, ['entitySearchService', widget.data.statePropertyName, 'targetsWithoutMetrics'], [])
  );
  const targetsWithoutMetricsMemo = useDeepCompareMemo(() => targetsWithoutMetrics, [targetsWithoutMetrics]) as any[];

  return useCallback(
    async ({ pageNumber, searchSideBarConditions, location, dataSet, fetchMetricsParams }) => {
      const targetsDataFetcher = new TargetsDataFetcher();
      const finalResult = await targetsDataFetcher.fetchTargetsData(
        { location, entity },
        retailer,
        entity,
        searchSideBarConditions,
        dispatch,
        app,
        undefined, // TODO add cancel source
        { [widget.data.statePropertyName]: { targetsWithoutMetrics: targetsWithoutMetricsMemo } },
        widget,
        fetchMetricsParams,
        dataSet,
        pageNumber,
        importantOverrides
      );
      return finalResult;
    },
    [app, dispatch, entity, retailer, targetsWithoutMetricsMemo, widget]
  );
};

export default useFetchTargetsSalesData;
