import _cloneDeep from 'lodash/cloneDeep';
import queryString from 'qs';
import { RouteComponentProps } from 'react-router';
import { Entity } from 'sl-api-connector/types/entity';

import {
  onPrimeMode,
  onDisplayAdvertisingMode,
  onBasicAdvertisingMode,
  onChargebacksMode,
  onShortagesMode
} from 'src/store/modules/user/selectors';
import ReduxStore from 'src/types/store/reduxStore';
import { store } from 'src/main';
import { destructurePathName } from 'src/utils/urlParsing';
import _toLower from 'lodash/toLower';
import { isContentRewriteAllowed } from 'src/utils/app';

export function buildLinkParams(params: { [key: string]: unknown }, enableComparison: boolean) {
  let linkParams = '';
  let comparisonLinkParams = '';

  Object.keys(params).forEach((key) => {
    linkParams += `&${key}=${params[key]}`;
    comparisonLinkParams += `&c${key}=${params[key]}`;
  });

  linkParams = linkParams.substr(1);
  comparisonLinkParams = comparisonLinkParams.substr(1);

  return {
    linkParams,
    comparisonLinkParams: enableComparison ? comparisonLinkParams : undefined,
    queryParams: queryString.parse(linkParams),
    comparisonQueryParams: queryString.parse(comparisonLinkParams)
  };
}

type AllConfig = {
  location: RouteComponentProps['location'];
  queryParams: ReduxStore['app']['queryParams'];
  entity: Entity;
} & Pick<ReduxStore, 'app' | 'retailer' | 'user'>;

type MenuItem = {
  name: string;
  displayName: string;
  compareDisabledForEntityType?: string[];
  excludedForEntityType?: string[];
  excludedForRetailerIds?: number[];
  disabledForEntityType?: string[];
  isMainLabel?: boolean;
  userModuleSubscriptionRequired?: string[];
  appStage?: string;
  shouldShow?: (props: AllConfig) => boolean;
  onClick?: null;
  subMenuItems?: MenuItem[];
  className?: string;
} & Partial<ReturnType<typeof buildLinkParams>>;

const ENTITY_NAV_CONFIG: { [appName: string]: MenuItem[] } = {
  atlas: [
    // Summary Label
    {
      name: 'summary',
      displayName: 'SUMMARY',
      compareDisabledForEntityType: ['all'],
      excludedForEntityType: ['searchtermlist'],
      isMainLabel: true
    },
    {
      name: 'scorecard',
      displayName: 'Key Metrics',
      compareDisabledForEntityType: ['all'],
      excludedForEntityType: ['searchtermlist'],
      ...buildLinkParams({ tab: 'scorecard', subtab: 'all' }, true),
      shouldShow: ({ user }: { user: ReduxStore['user'] }) => {
        const { isRestrictedDemoUser } = user.config && user.config;
        return !isRestrictedDemoUser;
      }
    },
    {
      name: 'traffic',
      displayName: 'TRAFFIC',
      isMainLabel: true,
      compareDisabledForEntityType: ['all'],
      shouldShow: onPrimeMode
    },
    {
      name: 'totalClicks',
      displayName: 'Traffic - Total',
      shouldShow: onPrimeMode,
      ...buildLinkParams({ tab: 'traffic', subtab: 'totalClicks' }, true)
    },
    {
      name: 'organicClicks',
      displayName: 'Traffic - Organic',
      shouldShow: onPrimeMode,
      ...buildLinkParams({ tab: 'traffic', subtab: 'organicClicks' }, true)
    },
    {
      name: 'adClicks',
      displayName: 'Traffic - Paid',
      shouldShow: onPrimeMode,
      ...buildLinkParams({ tab: 'traffic', subtab: 'adClicks' }, true)
    },
    // Conversion Label
    {
      name: 'conversion',
      displayName: 'CONVERSION',
      excludedForEntityType: ['searchtermlist'],
      compareDisabledForEntityType: ['all'],
      isMainLabel: true,
      shouldShow: ({ user }: { user: ReduxStore['user'] }) => {
        const { isRestrictedDemoUser } = user.config && user.config;
        return !isRestrictedDemoUser;
      }
    },
    {
      name: 'conversion',
      displayName: 'Conversion',
      ...buildLinkParams({ tab: 'sales', subtab: 'purchaseRate' }, true),
      excludedForEntityType: ['searchtermlist'],
      excludedForRetailerIds: [26, 34, 35],
      onClick: null,
      shouldShow: onPrimeMode
    },
    {
      name: 'retailPrice',
      displayName: 'Retail Price',
      ...buildLinkParams({ tab: 'sales', subtab: 'retailPrice' }, true),
      excludedForEntityType: ['searchtermlist'],
      onClick: null,
      shouldShow: ({ user }: { user: ReduxStore['user'] }) => {
        const { isRestrictedDemoUser } = user.config && user.config;
        return !isRestrictedDemoUser;
      }
    },
    {
      name: 'promotions',
      displayName: 'Promotions',
      ...buildLinkParams({ tab: 'promotions', subtab: 'retailSales' }, true),
      excludedForEntityType: ['searchtermlist'],
      onClick: null,
      shouldShow: ({ user }: { user: ReduxStore['user'] }) => {
        const { isRestrictedDemoUser } = user.config && user.config;
        return !isRestrictedDemoUser;
      }
    },
    {
      name: 'contentScore',
      displayName: 'Content Score',
      ...buildLinkParams({ tab: 'sales', subtab: 'contentScore' }, true),
      onClick: null,
      excludedForEntityType: ['searchtermlist'],
      shouldShow: onPrimeMode
    },
    {
      name: 'rating',
      displayName: 'Rating',
      ...buildLinkParams({ tab: 'sales', subtab: 'rating' }, true),
      onClick: null,
      excludedForEntityType: ['searchtermlist'],
      shouldShow: onPrimeMode
    },
    // Sales Label
    {
      name: 'sales',
      displayName: 'SALES',
      compareDisabledForEntityType: ['all'],
      excludedForEntityType: ['searchtermlist'],
      isMainLabel: true,
      shouldShow: ({ user }: { user: ReduxStore['user'] }) => {
        const { isRestrictedDemoUser } = user.config && user.config;
        return !isRestrictedDemoUser;
      }
    },
    {
      name: 'retailSales',
      displayName: 'Retail Sales',
      excludedForEntityType: ['searchtermlist'],
      ...buildLinkParams({ tab: 'sales', subtab: 'retailSales' }, true),
      onClick: null
    },
    {
      name: 'unitsSold',
      displayName: 'Units Sold',
      excludedForEntityType: ['searchtermlist'],
      ...buildLinkParams({ tab: 'sales', subtab: 'unitsSold' }, true),
      onClick: null
    },
    {
      name: 'retailPrice',
      displayName: 'Retail Price',
      ...buildLinkParams({ tab: 'sales', subtab: 'retailPrice' }, true),
      excludedForEntityType: ['searchtermlist'],
      onClick: null,
      shouldShow: ({ user }: { user: ReduxStore['user'] }) => {
        const { isRestrictedDemoUser } = user.config && user.config;
        return isRestrictedDemoUser;
      }
    }
  ],
  beacon: [
    // Summary Label
    {
      name: 'summary',
      displayName: 'SUMMARY',
      compareDisabledForEntityType: ['all'],
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      isMainLabel: true
    },
    {
      name: 'summary',
      displayName: 'Key Metrics',
      ...buildLinkParams({ tab: 'sales', subtab: 'keymetrics' }, false),
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      compareDisabledForEntityType: ['all']
    },
    {
      name: 'waterfall',
      displayName: 'Waterfall',
      ...buildLinkParams({ tab: 'sales', subtab: 'waterfall' }, false),
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      compareDisabledForEntityType: ['all'],
      shouldShow: () =>
        onPrimeMode() &&
        ['1', '11', '17', '14', '15', '16', '28', '29', '30', '53'].includes(store.getState().retailer.id)
    },
    // Traffic Label
    {
      name: 'traffic',
      displayName: 'TRAFFIC',
      compareDisabledForEntityType: ['all'],
      isMainLabel: true
    },
    {
      name: 'totalClicks',
      displayName: 'Traffic - Total',
      ...buildLinkParams({ tab: 'traffic', subtab: 'totalClicks' }, true),
      excludedForEntityType: ['adCampaign'],
      shouldShow: onPrimeMode
    },
    {
      name: 'organicClicks',
      displayName: 'Traffic - Organic',
      ...buildLinkParams({ tab: 'traffic', subtab: 'organicClicks' }, true),
      excludedForEntityType: ['adCampaign'],
      shouldShow: onPrimeMode
    },
    {
      name: 'adClicks',
      displayName: 'Traffic - Advertising',
      ...buildLinkParams({ tab: 'traffic', subtab: 'adClicks' }, true),
      shouldShow: onBasicAdvertisingMode
    },
    {
      name: 'trafficAdvertising',
      displayName: 'Traffic - Advertising',
      shouldShow: onDisplayAdvertisingMode,
      subMenuItems: [
        {
          name: 'adClicks',
          displayName: 'Traffic - Search',
          ...buildLinkParams({ tab: 'traffic', subtab: 'adClicks' }, true)
        },
        {
          name: 'dpvViews',
          displayName: 'Traffic - Display',
          ...buildLinkParams({ tab: 'traffic', subtab: 'dpvViews' }, true)
        }
      ]
    },
    // Conversion Label
    {
      name: 'conversion',
      displayName: 'CONVERSION',
      compareDisabledForEntityType: ['all'],
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      isMainLabel: true
    },
    {
      name: 'conversion',
      displayName: 'Conversion',
      ...buildLinkParams({ tab: 'conversion', subtab: 'purchaseRate' }, true),
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      onClick: null,
      shouldShow: onPrimeMode
    },
    {
      name: 'price',
      displayName: 'Price',
      onClick: null,
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      subMenuItems: [
        {
          name: 'price', // This subtab contains: Retail Price,	Wholesale Price,	Retail Price by Subcategory, and Table
          displayName: 'Price',
          ...buildLinkParams({ tab: 'sales', subtab: 'retailPrice' }, true)
        },
        {
          name: 'mapPrice',
          displayName: 'MAP Price',
          ...buildLinkParams({ tab: 'sales', subtab: 'mapPrice' }, true)
        }
      ]
    },
    {
      name: 'content',
      displayName: 'Content',
      onClick: null,
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      subMenuItems: [
        {
          name: 'contentScore',
          displayName: 'Content Score',
          ...buildLinkParams({ tab: 'content', subtab: 'contentScore' }, true)
        },
        {
          name: 'contentAccuracy',
          displayName: 'Content Accuracy',
          ...buildLinkParams({ tab: 'content', subtab: 'contentAccuracy' }, true)
        }
      ]
    },
    {
      name: 'buybox',
      displayName: 'Buy Box',
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      onClick: null,
      subMenuItems: [
        {
          name: 'winPercentage', // This subtab contains: Buy box - win,
          displayName: 'Buy Box - Rate',
          ...buildLinkParams({ tab: 'buybox', subtab: 'winPercentage' }, true)
        },
        {
          name: 'sellers',
          displayName: 'Buy Box - Sellers',
          ...buildLinkParams({ tab: 'buybox', subtab: 'sellers' }, false)
        }
      ]
    },
    {
      name: 'reviews',
      displayName: 'Reviews',
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      onClick: null,
      subMenuItems: [
        {
          name: 'reviewTrends',
          displayName: 'Review Trends',
          ...buildLinkParams({ tab: 'reviews', subtab: 'reviewTrends' }, true)
        },
        {
          name: 'highRiskReviews',
          displayName: 'High Risk',
          ...buildLinkParams({ tab: 'reviews', subtab: 'highRiskReviews' }, true)
        }
      ]
    },
    // Sales Label
    {
      name: 'sales',
      displayName: 'SALES',
      compareDisabledForEntityType: ['all'],
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      isMainLabel: true
    },
    {
      name: 'retailSales',
      displayName: 'Sales',
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      ...buildLinkParams({ tab: 'sales', subtab: 'retailSales' }, true)
    },
    {
      name: 'unitsSold',
      displayName: 'Units Sold',
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      ...buildLinkParams({ tab: 'sales', subtab: 'unitsSold' }, true)
    },
    {
      name: 'margin',
      displayName: 'Margin',
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      ...buildLinkParams({ tab: 'sales', subtab: 'retailerGrossMargin' }, true)
    },
    {
      name: 'return',
      displayName: 'Returns',
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      ...buildLinkParams({ tab: 'sales', subtab: 'returnRate' }, true)
    },
    // Operations Label
    {
      name: 'operations',
      displayName: 'OPERATIONS',
      compareDisabledForEntityType: ['all'],
      excludedForEntityType: ['searchTermList', 'adCampaign'],
      isMainLabel: true
    },
    {
      name: 'inventory',
      displayName: 'Inventory',
      onClick: null,
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      subMenuItems: [
        {
          name: 'inventory', // This subtab contains: In-Stock Rate, On Hand - Units,	Weeks on Hand, On Hand - Retail, and Table
          displayName: 'In-Stock',
          ...buildLinkParams({ tab: 'sales', subtab: 'inventory' }, true)
        },
        {
          name: 'inactive',
          displayName: 'Inactive',
          ...buildLinkParams({ tab: 'sales', subtab: 'inactive' }, true),
          disabledForEntityType: ['product']
        }
      ]
    },
    {
      name: 'chargebacks',
      displayName: 'Chargebacks',
      shouldShow: onChargebacksMode,
      onClick: null,
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      ...buildLinkParams({ tab: 'operations', subtab: 'chargebacks' }, true)
    },
    // Shortages Summary
    {
      name: 'shortages',
      displayName: 'Shortages',
      shouldShow: onShortagesMode,
      excludedForEntityType: ['searchtermlist', 'adCampaign', 'segment'],
      ...buildLinkParams({ tab: 'operations', subtab: 'shortages' }, true)
    },
    // Dispute Management Page
    {
      name: 'disputes',
      displayName: 'Dispute Management',
      shouldShow: onShortagesMode,
      excludedForEntityType: ['segment', 'brand', 'product', 'category', 'subcategory', 'searchtermlist', 'adCampaign'],
      ...buildLinkParams({ tab: 'operations', subtab: 'disputes' }, true)
    },

    // Compliance Label
    {
      name: 'compliance',
      displayName: 'COMPLIANCE',
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      userModuleSubscriptionRequired: ['compliance'],
      compareDisabledForEntityType: ['all'],
      isMainLabel: true
    },
    {
      name: 'keymetrics',
      displayName: 'Compliance Summary',
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      userModuleSubscriptionRequired: ['compliance'],
      compareDisabledForEntityType: ['all'],
      ...buildLinkParams({ tab: 'compliance', subtab: 'keymetrics' }, false)
    },
    {
      name: 'keywordCompliance',
      displayName: 'Advertising Compliance',
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      userModuleSubscriptionRequired: ['compliance'],
      compareDisabledForEntityType: ['all'],
      ...buildLinkParams(
        {
          tab: 'compliance',
          subtab: 'keywordCompliance',
          ctab: 'compliance-keywordaditem',
          csubtab: 'unauthorized',
          ctype: 'metric'
        },
        true
      )
    },
    {
      name: 'sellerCompliance',
      displayName: 'Seller Compliance',
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      userModuleSubscriptionRequired: ['compliance'],
      compareDisabledForEntityType: ['all'],
      ...buildLinkParams(
        {
          tab: 'compliance',
          subtab: 'sellerCompliance',
          ctab: 'compliance-selleritem',
          csubtab: 'unauthorized',
          ctype: 'metric'
        },
        true
      )
    }
  ],
  omni: [
    // Summary Label
    {
      name: 'summary',
      displayName: 'SUMMARY',
      compareDisabledForEntityType: ['all'],
      excludedForEntityType: ['searchtermlist'],
      isMainLabel: true
    },
    {
      name: 'scorecard',
      displayName: 'Key Metrics',
      compareDisabledForEntityType: ['all'],
      excludedForEntityType: ['searchtermlist'],
      ...buildLinkParams({ tab: 'scorecard', subtab: 'all' }, true),
      onClick: null
    },
    {
      name: 'shareOfShelf',
      displayName: 'SHARE OF SHELF',
      isMainLabel: true,
      compareDisabledForEntityType: ['all']
    },
    {
      name: 'totalShareOfShelf',
      displayName: 'Total Share of Shelf',
      ...buildLinkParams({ tab: 'shareOfShelf', subtab: 'total' }, true),
      onClick: null
    },
    {
      name: 'organicShareOfShelf',
      displayName: 'Organic Share of Shelf',
      ...buildLinkParams({ tab: 'shareOfShelf', subtab: 'organic' }, true),
      onClick: null
    },
    {
      name: 'paidShareOfShelf',
      displayName: 'Paid Share of Shelf',
      ...buildLinkParams({ tab: 'shareOfShelf', subtab: 'paid' }, true),
      onClick: null
    },
    {
      name: 'prices',
      displayName: 'PRICES',
      excludedForEntityType: ['searchtermlist'],
      compareDisabledForEntityType: ['all'],
      isMainLabel: true
    },
    {
      name: 'retailPrices',
      displayName: 'Retail Price',
      ...buildLinkParams({ tab: 'price', subtab: 'retailPrice' }, true),
      excludedForEntityType: ['searchtermlist'],
      onClick: null
    },
    {
      name: 'omniPromotions',
      displayName: 'Promotions',
      ...buildLinkParams({ tab: 'price', subtab: 'promotions' }, true),
      excludedForEntityType: ['searchtermlist'],
      onClick: null
    },
    {
      name: 'priceViolations',
      displayName: 'Violations',
      ...buildLinkParams({ tab: 'price', subtab: 'priceViolations' }, true),
      excludedForEntityType: ['searchtermlist'],
      onClick: null
    },
    {
      name: 'inventory',
      displayName: 'INVENTORY',
      compareDisabledForEntityType: ['all'],
      excludedForEntityType: ['searchtermlist'],
      isMainLabel: true
    },
    {
      name: 'inStock',
      displayName: 'In-Stock',
      excludedForEntityType: ['searchtermlist'],
      ...buildLinkParams({ tab: 'inventory', subtab: 'inStock' }, true),
      onClick: null
    },
    {
      name: 'outOfStock',
      displayName: 'Out-of-Stock',
      excludedForEntityType: ['searchtermlist'],
      ...buildLinkParams({ tab: 'inventory', subtab: 'outOfStock' }, true),
      onClick: null
    },
    {
      name: 'availability',
      displayName: 'Availability',
      ...buildLinkParams({ tab: 'inventory', subtab: 'availability' }, true),
      excludedForEntityType: ['searchtermlist'],
      onClick: null
    },
    {
      name: 'fulfillment',
      displayName: 'Fulfillment Time',
      excludedForEntityType: ['searchtermlist'],
      ...buildLinkParams({ tab: 'time', subtab: 'fulfillment' }, true)
    },
    // {
    //   name: 'orderType',
    //   displayName: 'ORDER TYPE',
    //   compareDisabledForEntityType: ['all'],
    //   excludedForEntityType: ['searchtermlist'],
    //   isMainLabel: true
    // },
    // {
    //   name: 'delivery',
    //   displayName: 'Delivery',
    //   excludedForEntityType: ['searchtermlist'],
    //   ...buildLinkParams({ tab: 'time', subtab: 'delivery' }, true),
    //   onClick: null
    // },
    // {
    //   name: 'ship',
    //   displayName: 'Ship',
    //   excludedForEntityType: ['searchtermlist'],
    //   ...buildLinkParams({ tab: 'time', subtab: 'shipping' }, true),
    //   onClick: null
    // },
    // {
    //   name: 'pickup',
    //   displayName: 'Pickup',
    //   excludedForEntityType: ['searchtermlist'],
    //   ...buildLinkParams({ tab: 'time', subtab: 'pickup' }, true),
    //   onClick: null
    // },
    {
      name: 'conversion',
      displayName: 'CATALOG',
      compareDisabledForEntityType: ['all'],
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      isMainLabel: true
    },
    {
      name: 'content',
      displayName: 'Content',
      onClick: null,
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      subMenuItems: [
        {
          name: 'contentScore',
          displayName: 'Content Score',
          ...buildLinkParams({ tab: 'content', subtab: 'contentScore' }, true)
        },
        {
          name: 'contentAccuracy',
          displayName: 'Content Accuracy',
          ...buildLinkParams({ tab: 'content', subtab: 'contentAccuracy' }, true)
        }
      ]
    },
    {
      name: 'reviews',
      displayName: 'Reviews',
      excludedForEntityType: ['searchtermlist', 'adCampaign'],
      onClick: null,
      subMenuItems: [
        {
          name: 'reviewTrends',
          displayName: 'Review Trends',
          ...buildLinkParams({ tab: 'reviews', subtab: 'reviewTrends' }, true)
        },
        {
          name: 'highRiskReviews',
          displayName: 'High Risk',
          ...buildLinkParams({ tab: 'reviews', subtab: 'highRiskReviews' }, true)
        }
      ]
    }
  ]
};

function setMenuItemContext({
  location,
  app,
  retailer,
  queryParams,
  entity,
  user,
  menuItem
}: AllConfig & { menuItem: MenuItem & { isSelected?: boolean; isCompared?: boolean } }) {
  if (menuItem.queryParams) {
    menuItem.isSelected = true;
    Object.keys(menuItem.queryParams).forEach((key) => {
      if (!queryParams[key] || menuItem.queryParams[key] !== queryParams[key]) {
        menuItem.isSelected = false;
      }
    });

    if (menuItem.comparisonQueryParams) {
      menuItem.isCompared = true;
      Object.keys(menuItem.comparisonQueryParams).forEach((key) => {
        if (!queryParams[key] || menuItem.comparisonQueryParams[key] !== queryParams[key]) {
          menuItem.isCompared = false;
        }
      });
    }
  }

  if (menuItem.subMenuItems) {
    menuItem.subMenuItems.forEach((subMenuItem: MenuItem) => {
      const { isSelected } = setMenuItemContext({
        location,
        app,
        retailer,
        queryParams,
        entity,
        user,
        menuItem: subMenuItem
      });

      if (!menuItem.isSelected) {
        menuItem.isSelected = isSelected;
      }
    });
  }

  return { isSelected: menuItem.isSelected, isCompared: menuItem.isCompared };
}

const decideShouldShow = (menuItem: MenuItem, { location, app, retailer, user, queryParams, entity }: AllConfig) => {
  const { userModuleSubscriptionRequired, appStage, excludedForEntityType, excludedForRetailerIds, shouldShow } =
    menuItem;

  return (
    (!shouldShow || shouldShow({ location, app, retailer, user, queryParams, entity })) &&
    !(appStage && !appStage.includes(app.stage)) &&
    !(excludedForEntityType && excludedForEntityType.includes(entity.type)) &&
    !(
      userModuleSubscriptionRequired &&
      (!user.config.modulesSubscribed || !user.config.modulesSubscribed.includes(userModuleSubscriptionRequired[0]))
    ) &&
    !(excludedForRetailerIds && excludedForRetailerIds.includes(Number.parseInt(retailer.id, 10)))
  );
};

export function getLeftNavConfig(args: AllConfig & Pick<ReduxStore, 'user'>) {
  const { location, app, retailer, user, queryParams, entity } = args;
  let menuItemsCloned = _cloneDeep(ENTITY_NAV_CONFIG[app.name]);
  const menuItems: MenuItem[] = [];

  // temp disable the Share of Shelf in the Omni category
  // TODO: delete below code after the Share Of shelf category calculation is correct
  // Disable Share Of shelf when in the Product page
  if (app.name === 'omni') {
    const { pathname } = location;
    const [entityType] = destructurePathName(pathname);
    if (entityType === 'keywordSegment') {
      menuItemsCloned = menuItemsCloned.filter((ele) => _toLower(ele.displayName).includes('share of shelf'));
    }

    const omniContentRewriteAllowed = isContentRewriteAllowed();
    if (omniContentRewriteAllowed) {
      menuItemsCloned.forEach((menuItem) => {
        if (menuItem.name === 'content') {
          menuItem.subMenuItems.push({
            name: 'contentRewrite',
            displayName: 'Content Rewrite',
            ...buildLinkParams({ tab: 'content', subtab: 'contentRewrite' }, true)
          });
        }
      });
    }

    if (['category', 'product', 'segment', 'store', 'state', 'subcategory'].includes(entityType)) {
      menuItemsCloned = menuItemsCloned.map((ele) => {
        switch (ele.name) {
          case 'shareOfShelf':
            return {
              displayName: 'SHARE OF SHELF',
              isMainLabel: true,
              compareDisabledForEntityType: ['all'],
              className: 'Incoming_feature'
            };
          case 'totalShareOfShelf':
            return {
              name: 'totalShareOfShelf',
              displayName: 'Total Share of Shelf',
              className: 'Incoming_feature'
            };
          case 'organicShareOfShelf':
            return {
              name: 'organicShareOfShelf',
              displayName: 'Organic Share of Shelf',
              className: 'Incoming_feature'
            };
          case 'paidShareOfShelf':
            return { name: 'paidShareOfShelf', displayName: 'Paid Share of Shelf', className: 'Incoming_feature' };
          default:
            return ele;
        }
      });
    }
  }

  menuItemsCloned.forEach((menuItem) => {
    const shouldShow = decideShouldShow(menuItem, args);

    if (!shouldShow) {
      return;
    }

    if (menuItem.subMenuItems) {
      menuItem.subMenuItems = menuItem.subMenuItems.filter(
        (subMenuItem) =>
          subMenuItem.shouldShow === undefined ||
          subMenuItem.shouldShow({ location, app, retailer, user, queryParams, entity })
      );
    }

    setMenuItemContext({ location, app, retailer, queryParams, entity, menuItem, user });
    menuItems.push(menuItem);
  });

  return { menuItems };
}
