import types from './types';
import parsePromoTypes from 'src/store/modules/promoTypes/selectors';

const receivePromoTypes = (promoTypes: ReturnType<typeof parsePromoTypes>) => ({
  type: types.RECEIVE_ALL_PROMO_TYPES,
  promoTypes
});

const clearPromoTypes = () => ({
  type: types.CLEAR_ALL_PROMO_TYPES
});

export default {
  clearPromoTypes,
  receivePromoTypes
};
