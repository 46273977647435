import { Dispatch } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Widget } from 'src/types/application/widgetTypes';
import queryString from 'qs';
import _get from 'lodash/get';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { AD_CAMPAIGN_STATUS, AD_STATUS_DERIVED, AD_TARGET_MATCH_TYPE } from 'sl-ad-campaign-manager-data-model';
import _uniq from 'lodash/uniq';
import { getCampaignDisplayStatus } from 'src/components/AdManager/AmsUtils';
import _set from 'lodash/set';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import { SearchGridConstants } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConstants';
import { Indices } from 'src/components/Layout/Advertising/AdManagerPageLayout/types';
import { IGridResultData } from 'src/components/AdManager/Search/Models/IGridResultData';
import { IFetchEntityMetricsAndParseResponseParams } from 'src/components/AdManager/Search/Models/IFetchEntityMetricsAndParseResponseParams';
import {
  fetchEntityMetricsAndParseResponse,
  getClonedResult
} from 'src/components/AdManager/Search/GridDataFetchers/FetchDataForGroupByField';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';
import { getParentPlatform } from 'src/utils/browser';
import _isArray from 'lodash/isArray';
import {
  getESBodyOverridesForParentPlatform,
  modifyESQuery
} from 'src/components/AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';
import { shouldShowCriteo } from 'src/utils/app';
import { Props } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';

export class TargetsDataFetcher {
  // eslint-disable-next-line class-methods-use-this
  public async fetchTargetsData(
    props: Pick<Props, 'location'> & { entity: { campaignId: string } },
    retailer: any,
    mainEntity: any,
    searchSideBarConditions: ISearchFilterConditions,
    dispatch: Dispatch<any>,
    app: any,
    cancelSource: CancelTokenSource,
    entitySearchService: { [key: string]: { targetsWithoutMetrics: any[] } },
    widget: Widget,
    fetchMetricsParams: IFetchEntityMetricsAndParseResponseParams,
    dataSet: any,
    pageNumber: number,
    importantOverrides: any
  ): Promise<IGridResultData> {
    const result = await fetchEntityMetricsAndParseResponse(fetchMetricsParams, dispatch, cancelSource);
    const cloneResult = getClonedResult(result, pageNumber, dataSet);

    const isCampaignOrAdGroup =
      props.location &&
      props.location.pathname &&
      (props.location.pathname.includes('adCampaign') || props.location.pathname.includes('adGroup'));
    let passTargetingTextFilter = false;
    if (isCampaignOrAdGroup) {
      // get total target count for campaign/adGroup and
      // if it is > 250, pass targetingText term filter
      // some instacart campaigns have 10000+ targets with no metric
      const stringifiedParams = queryString.stringify({
        campaignIds: _get(props, 'entity.campaignId', ''),
        retailerId: parseInt(retailer.id, 10)
      });
      const totalCountRes = await axios.get(`/apiAdManager/adTargets/count?${stringifiedParams}`);
      const totalCount = _get(totalCountRes, 'data[0].count');

      if (totalCount && totalCount >= SearchGridConstants.TARGETS_THRESHOLD_250) {
        passTargetingTextFilter = true;
      }
    }
    // when it is on campaign tab, fetch the projected spend for the Budget column
    const targetingTextValues: string[] = [];
    result.data.forEach((dataPoint: any) => {
      if (!targetingTextValues.includes(dataPoint.entity.id)) {
        targetingTextValues.push(dataPoint.entity.id);
      }
    });

    const targetDocIndex =
      mainEntity && mainEntity.type === 'product'
        ? Indices.adCampaignAdGroupProductTargetDailyMetrics
        : Indices.adCampaignAdGroupTarget;

    const queryConditions = {
      termFilters: [
        {
          fieldName: 'targetingText',
          condition: 'should',
          values: targetingTextValues
        }
      ],
      rangeFilters: []
    };
    const allTermFilters = isCampaignOrAdGroup
      ? passTargetingTextFilter
        ? [
            ...searchSideBarConditions.termFilters.filter((x) => x.fieldName !== 'targetingText'),
            { fieldName: 'targetingText', values: targetingTextValues }
          ]
        : searchSideBarConditions.termFilters.filter((x) => x.fieldName !== 'targetingText')
      : [...searchSideBarConditions.termFilters, ...queryConditions.termFilters];

    const excludeNegativeTargetsFilter = {
      fieldName: 'targetingType',
      condition: 'must_not',
      values: ['negativeexact', 'negativephrase']
    };

    let overrides = [
      {
        doAggregation: false,
        conditions: {
          termFilters: [...allTermFilters, excludeNegativeTargetsFilter],
          rangeFilters: [...searchSideBarConditions.rangeFilters]
        },
        pageNumber: 1,
        pageSize: SearchGridConstants.PAGE_SIZE_10K,
        processDocuments: false,
        returnDocuments: true
      }
    ];

    if (shouldShowCriteo()) {
      const parentPlatform = getParentPlatform();

      // Criteo overrides
      if (
        parentPlatform &&
        parentPlatform === PARENT_PLATFORMS.CRITEO &&
        importantOverrides &&
        _isArray(importantOverrides)
      ) {
        const newOverride = [];

        overrides.forEach((_, indx) => {
          const mutatedQuery = modifyESQuery(
            overrides[indx],
            getESBodyOverridesForParentPlatform(null, importantOverrides)
          );
          newOverride.push(mutatedQuery);
        });

        overrides = newOverride;
      }
    }

    const { documents: adTargetDocuments } = await dispatch(
      fetchEntityMetrics(
        'adManager_searchResultsGrid_targets',
        {
          entity: mainEntity,
          retailer,
          app,
          indexName: targetDocIndex
        },
        overrides,
        _get(cancelSource, 'token'),
        true
      )
    );
    const isInstacart = retailer.id === '63';
    const adTargetDocumentsByTargetingText: any = {};
    adTargetDocuments.forEach((adTargetDocument: any) => {
      let targetIdToUse = adTargetDocument.targetingText + adTargetDocument.targetingType;
      if (isInstacart) {
        targetIdToUse =
          adTargetDocument.targetingText +
          adTargetDocument.targetingType +
          (adTargetDocument.isAutoAddedBoolean ? '1' : '0');
      }
      if (!adTargetDocumentsByTargetingText[targetIdToUse]) {
        adTargetDocumentsByTargetingText[targetIdToUse] = {
          beaconClientId: adTargetDocument.beaconClientId,
          platformType: adTargetDocument.platformType,
          targetingText: adTargetDocument.targetingText,
          targetingType: adTargetDocument.targetingType,
          extendedAttributes: {
            statusReason: '',
            status: AD_CAMPAIGN_STATUS.PAUSED,
            statusDerived: AD_STATUS_DERIVED.PAUSED,
            childDocuments: []
          }
        };
      }
      if (adTargetDocument.status === AD_CAMPAIGN_STATUS.ENABLED) {
        adTargetDocumentsByTargetingText[targetIdToUse].extendedAttributes.status = AD_CAMPAIGN_STATUS.ENABLED;
      }
      if (adTargetDocument.statusDerived === AD_STATUS_DERIVED.DELIVERING) {
        adTargetDocumentsByTargetingText[targetIdToUse].extendedAttributes.statusDerived = AD_STATUS_DERIVED.DELIVERING;
      }
      if (!['ended', 'archived'].includes(adTargetDocument.statusDerived)) {
        adTargetDocumentsByTargetingText[targetIdToUse].extendedAttributes.childDocuments.push(adTargetDocument);
      }
    });
    cloneResult.data.forEach((dataPoint: any) => {
      if (adTargetDocumentsByTargetingText[dataPoint.id]) {
        adTargetDocumentsByTargetingText[dataPoint.id].extendedAttributes.campaignIds = _uniq(
          adTargetDocumentsByTargetingText[dataPoint.id].extendedAttributes.childDocuments.map(
            (adTargetDocument: any) => adTargetDocument.campaignId
          )
        );
        adTargetDocumentsByTargetingText[dataPoint.id].extendedAttributes.campaignIdsDelivering = _uniq(
          adTargetDocumentsByTargetingText[dataPoint.id].extendedAttributes.childDocuments
            .filter((x: any) => x.statusDerived === AD_STATUS_DERIVED.DELIVERING)
            .map((adTargetDocument: any) => adTargetDocument.campaignId)
        );

        adTargetDocumentsByTargetingText[dataPoint.id].extendedAttributes.statusDisplay = getCampaignDisplayStatus(
          adTargetDocumentsByTargetingText[dataPoint.id].extendedAttributes
        );
        dataPoint.entity = {
          ...dataPoint.entity,
          ...adTargetDocumentsByTargetingText[dataPoint.id]
        };
      }
    });
    if (isCampaignOrAdGroup) {
      // add entries for empty targeting texts
      const targetWithoutMetrics = [];
      const allResultTargetingTexts = new Set(
        cloneResult.data.map((item: any) => item.entity.targetingText + item.entity.targetingType)
      );
      const includeInResultTargetingText = (targetingText: string) => allResultTargetingTexts.has(targetingText);
      const insertZeroTargetingText = (target: any) => {
        const targetEntity = _get(target, ['extendedAttributes', 'childDocuments', '0'], {});
        target.extendedAttributes.campaignIds = [targetEntity.campaignId]; // will be a unique document
        target.extendedAttributes.campaignIdsDelivering =
          targetEntity.statusDerived === AD_STATUS_DERIVED.DELIVERING ? [targetEntity.campaignId] : [];
        target.extendedAttributes.statusDisplay = getCampaignDisplayStatus(target.extendedAttributes);
        const { targetingText } = targetEntity;
        const { targetingText: originalTargetingText } = target;
        target.id = targetingText || originalTargetingText;
        _set(target, ['extendedAttributes', 'status'], targetEntity.status);
        targetWithoutMetrics.push({
          entity: { ...target, name: targetingText, type: 'adTarget' },
          id: targetingText + target.targetingType
        });
      };
      const isValidTargetingTextEntity = (adTarget: any) => {
        const targetEntity = adTarget.extendedAttributes.childDocuments[0];
        const statusDerived = _get(targetEntity, ['statusDerived'], '').toLowerCase();
        const targetingType = _get(targetEntity, ['targetingType'], '').toLowerCase();
        return (
          statusDerived !== AD_STATUS_DERIVED.ARCHIVED.toLowerCase() &&
          statusDerived !== AD_STATUS_DERIVED.ENDED.toLowerCase() &&
          targetingType !== AD_TARGET_MATCH_TYPE.NEGATIVE_EXACT.toLowerCase() &&
          targetingType !== AD_TARGET_MATCH_TYPE.NEGATIVE_PHRASE.toLowerCase() &&
          targetingType !== AD_TARGET_MATCH_TYPE.NEGATIVE_BROAD.toLowerCase()
        );
      };
      Object.entries(adTargetDocumentsByTargetingText).forEach(([targetingText, adTarget]) => {
        if (!includeInResultTargetingText(targetingText) && isValidTargetingTextEntity(adTarget)) {
          insertZeroTargetingText(adTarget);
        }
      });
      const totalCount = cloneResult.data ? cloneResult.data.length : 0;
      cloneResult.totalResultCount = Math.max(totalCount, cloneResult.totalResultCount);
      if (!cloneResult.hasMoreRows) {
        // these are the last of the targets with metrics,
        // appending all the targets without metrics
        const allTargetsWithoutMetricsInStore = _get(
          entitySearchService,
          `${widget.data.statePropertyName}.targetsWithoutMetrics`,
          []
        );
        cloneResult.data = [...cloneResult.data, ...allTargetsWithoutMetricsInStore, ...targetWithoutMetrics];
      } else if (!passTargetingTextFilter && pageNumber === 1) {
        cloneResult.targetsWithoutMetrics = targetWithoutMetrics;
      } else if (passTargetingTextFilter) {
        const allTargetsWithoutMetricsInStore = _get(
          entitySearchService,
          `${widget.data.statePropertyName}.targetsWithoutMetrics`,
          []
        );
        cloneResult.targetsWithoutMetrics = [...allTargetsWithoutMetricsInStore, ...targetWithoutMetrics];
      }
    }

    return {
      gridResult: cloneResult
    };
  }
}
