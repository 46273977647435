import React from 'react';
import { SlRow, SlColumn } from '@stackline/ui';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import { BEACON_PRO_RIGHT_CONTENT_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

export interface SplineChartLoadingProps {
  width?: number;
}
const SplineChartLoading = ({ width }: SplineChartLoadingProps) => {
  const legendSkeleton = (
    <SlColumn spacing="sm">
      <SlSkeleton variant="rounded" height={23} width={68} />
      <SlSkeleton variant="rounded" height={23} width={152} />
    </SlColumn>
  );

  return (
    <SlColumn spacing="lg">
      <SlRow spacing="lg">
        {legendSkeleton}
        {legendSkeleton}
      </SlRow>
      <SlSkeleton variant="rounded" height={280} width={width || BEACON_PRO_RIGHT_CONTENT_WIDTH} />
    </SlColumn>
  );
};

export default SplineChartLoading;
