import axios from 'axios';

/**
 * Removes an approved domain from a team
 */
export default function RemoveAuthorizedClientDomain(currentEmail: string, domain: string) {
  return axios.post('/api/user/RemoveAuthorizedClientDomain', '', {
    params: {
      activeUserEmail: currentEmail,
      domain
    }
  });
}
