import { COMMON_FIELDS } from 'src/utils/entityDefinitions/fields/commonFieldDefinitions';
import { CONTENT_SCORE_FIELDS } from 'src/utils/entityDefinitions/fields/contentScoreFieldDefinitions';
import { TRAFFIC_FIELDS } from 'src/utils/entityDefinitions/fields/trafficFieldDefinitions';
import { SALES_FIELDS } from 'src/utils/entityDefinitions/fields/salesFieldDefintions';
import { REVIEWS_FIELDS } from 'src/utils/entityDefinitions/fields/reviewsFieldDefinitions';
import { PROMOTION_FIELDS } from 'src/utils/entityDefinitions/fields/promotionFieldDefinitions';
import { BEACON_FIELDS } from 'src/utils/entityDefinitions/fields/beaconSalesFieldDefinitions';
import { ATLAS_RETAILER_FIELD } from '../fields/atlasRetailerField';

export const ATLAS_INDEX_FIELDS = {
  sales: {
    ...COMMON_FIELDS,
    ...CONTENT_SCORE_FIELDS,
    ...REVIEWS_FIELDS,
    ...BEACON_FIELDS,
    ...SALES_FIELDS
  },
  promotions: {
    ...PROMOTION_FIELDS
  },
  traffic: {
    ...COMMON_FIELDS,
    ...TRAFFIC_FIELDS
  },
  multiretailer: {
    ...COMMON_FIELDS,
    ...BEACON_FIELDS,
    ...SALES_FIELDS,
    ...TRAFFIC_FIELDS,
    ...ATLAS_RETAILER_FIELD
  }
};
