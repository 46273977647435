import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withBus } from 'react-bus';
import Input from '@mui/material/Input';
import { withQuerySubtab } from 'src/utils/hoc';

/**
 * A widget that allows the displayed reviews to be filtered by some keyword.  It emits the `filterReviewKeyword` event
 * whenever the keyword is changed.
 */
const FilterKeywords = ({ eventBus, querySubtab }) => {
  const [searchingValue, setSearchingValue] = useState('');

  if (querySubtab !== 'reviewTrends') {
    return null;
  }

  return (
    <div style={{ width: '100%', marginBottom: '20px' }}>
      <Input
        autoComplete="off"
        className="review-metrics__search-bar"
        placeholder="Filter by keyword"
        type="text"
        value={searchingValue}
        name="review"
        id="review"
        style={{ width: '100%' }}
        onChange={(evt) => setSearchingValue(evt.target.value)}
        onKeyPress={(evt) => {
          if (evt.key === 'Enter') {
            eventBus.emit('filterReviewKeyword', evt.target.value);
          }
        }}
      />
    </div>
  );
};

FilterKeywords.propTypes = {
  eventBus: PropTypes.object.isRequired,
  querySubtab: PropTypes.string.isRequired
};

const EnhancedFilterKeywords = withBus('eventBus')(withQuerySubtab(FilterKeywords));

export default EnhancedFilterKeywords;
