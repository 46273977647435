import React from 'react';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import BeaconDropdownMetricColumnChart from 'src/components/BeaconRedesignComponents/BeaconMetricColumnChart/BeaconDropdownMetricColumnChart';
import TopEntityChartV2Loading from 'src/components/BeaconRedesignComponents/TopEntityChartV2/TopEntityChartV2Loading';
import { useMainEntityType } from 'src/utils/Hooks/reduxSelectors';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import ProductsWithRetailSalesSplineChart from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Sales/ProductsWithRetailSalesSplineChart';
import RetailSalesProductsList from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Summary/Sales/RetailSales/RetailSalesProductsList';

/**
 * Shows the data for the retail sales page on the Sales tab
 */

const RetailSalesPage = () => {
  const entityType = useMainEntityType();

  return (
    <BeaconPageContainer>
      <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
        <BeaconComparableSplineChart indexName="sales" fieldName="retailSales" />
        <BeaconComparableSplineChart indexName="sales" fieldName="wholesaleSales" />
        <BeaconDropdownMetricColumnChart
          beaconChartWithLegendPropsBuilder={(props) => ({
            ...props,
            loadingComponent: <TopEntityChartV2Loading />
          })}
          indexName="sales"
          fieldName="retailSales"
          dropdownOptions={[
            {
              id: 'subCategoryId',
              label: 'Subcategory'
            },
            {
              id: 'categoryId',
              label: 'Category'
            },
            {
              id: 'brandId',
              label: 'Brand'
            }
          ].filter((option) => !option.id.toLowerCase().startsWith(entityType))}
          titlePrefix="Retail Sales by"
        />
        {entityType !== 'product' && <ProductsWithRetailSalesSplineChart />}
        <RetailSalesProductsList />
      </Flex>
    </BeaconPageContainer>
  );
};

export default RetailSalesPage;
