import React, { useState } from 'react';
import { Box } from '@mui/system';
import { SlMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlMenu';
import { BeaconProEllipses } from 'src/components/SvgIcons';
import {
  BulkAdjustmentStatus,
  BulkAdjustmentUploadDatum
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/serverProxy/types';
import useRevertBulkAdjustment from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/hooks/useRevertBulkAdjustment';
import { usePollingContext } from 'src/providers/PollingContextProvider';
import useServerProxy from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/hooks/useServerProxy';
import { useQueryClient } from 'react-query';
import { GET_BULK_ADJUSTMENT_UPLOADS_QUERY_KEY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Adjustments/constants';
import RevertConfirmationDialogue from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Adjustments/RevertConfirmationDialogue';
import { useSnackbar } from 'src/utils/Hooks';
import { settingsErrorMessage } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/utils';
import { trackMixpanelEvent } from 'src/utils/mixpanel';

enum DROPDOWN_ACTION {
  REVERT = 'revert',
  DELETE = 'delete',
  EXPORT = 'export'
}

const getOptions = (status: BulkAdjustmentStatus, enableRevert?: boolean) => {
  switch (status) {
    case BulkAdjustmentStatus.Draft:
      return [
        {
          id: DROPDOWN_ACTION.EXPORT,
          label: 'Export file'
        },
        {
          id: DROPDOWN_ACTION.DELETE,
          label: 'Delete file'
        }
      ];
    case BulkAdjustmentStatus.Published: {
      if (enableRevert) {
        return [
          {
            id: DROPDOWN_ACTION.EXPORT,
            label: 'Export file'
          },
          {
            id: DROPDOWN_ACTION.REVERT,
            label: 'Revert file'
          }
        ];
      } else {
        return [
          {
            id: DROPDOWN_ACTION.EXPORT,
            label: 'Export file'
          }
        ];
      }
    }

    // TODO: Enable delete for Incomplete status when API is available
    case BulkAdjustmentStatus.Incomplete:
      return [
        {
          id: DROPDOWN_ACTION.EXPORT,
          label: 'Export file'
        }
      ];
    default:
      return [];
  }
};

export const BulkAdjustmentEllipsesMenu = ({
  row,
  enableRevert
}: {
  row: BulkAdjustmentUploadDatum;
  enableRevert?: boolean;
}) => {
  const { mutateAsync: revertBulkAdjustmentMutation } = useRevertBulkAdjustment();
  const { startPolling, stopPolling } = usePollingContext();
  const { pollBulkAdjustments } = useServerProxy();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const [confirmationDialogue, setConfirmationDialogue] = useState<{
    open: boolean;
    title: string;
    body: string;
    buttonText: string;
  }>({ open: false, title: '', body: '', buttonText: '' });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Opens the dropdown menu
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // Handles reverting/deleting a published or drafted bulk adjustment
  const handleBulkRevert = async () => {
    try {
      setConfirmationDialogue({ ...confirmationDialogue, open: false });
      const { planType, bulkAdjustmentId, bulkAdjustmentStatus, bulkAdjustmentDescription, bulkAdjustmentTitle } = row;
      const isDraft = bulkAdjustmentStatus === BulkAdjustmentStatus.Draft;

      trackMixpanelEvent({
        eventName: 'revert bulk adjustment',
        data: {
          bulkAdjustmentId,
          title: bulkAdjustmentTitle,
          description: bulkAdjustmentDescription,
          planType,
          isDraft
        }
      });

      await revertBulkAdjustmentMutation({ planType, bulkAdjustmentId, isDraft });
      const revertBulkAdjustmentPollingId = `REVERT_BULK_ADJUSTMENT_${bulkAdjustmentId}`;
      setTimeout(() => {
        // Invalidate the query so that the table will update after set timeout
        queryClient.invalidateQueries([GET_BULK_ADJUSTMENT_UPLOADS_QUERY_KEY]);
        // Don't poll when deleting a draft
        if (!isDraft) {
          startPolling(
            revertBulkAdjustmentPollingId,
            async () => {
              const [response] = await pollBulkAdjustments({
                bulkAdjustmentId,
                planType,
                bulkAdjustmentStage: 'Revert'
              });
              if (response.processingStatus === 'Success') {
                stopPolling(revertBulkAdjustmentPollingId);
                // Invalidate the query so that the table will update after successful polling
                queryClient.invalidateQueries([GET_BULK_ADJUSTMENT_UPLOADS_QUERY_KEY]);
              } else if (response.processingStatus === 'Error') {
                stopPolling(revertBulkAdjustmentPollingId);
              }
            },
            {
              interval: 5000 // TODO how often should we check?
            }
          );
        }
      }, 3000);
    } catch (error) {
      console.error('Error reverting bulk adjustment', error);
      trackMixpanelEvent({
        eventName: 'error reverting bulk adjustment',
        data: {
          bulkAdjustmentId: row.bulkAdjustmentId
        }
      });
    }
  };

  // Handles exporting a bulk adjustment upload in any status
  const handleExport = async () => {
    try {
      // We can use the poll endpoint to get the file download
      const { planType, bulkAdjustmentId, bulkAdjustmentStatus } = row;
      // Export net impact for published and draft bulk adjustments
      const bulkAdjustmentStage = [BulkAdjustmentStatus.Published, BulkAdjustmentStatus.Draft].includes(
        bulkAdjustmentStatus
      )
        ? 'Compute'
        : 'Validation';

      const response = await pollBulkAdjustments({ bulkAdjustmentId, planType, bulkAdjustmentStage });
      const { fileUri, fileName } = response[0];

      if (fileUri) {
        const fileInfo = JSON.parse(fileUri);
        const s3URL = `https://${fileInfo.s3Bucket}.s3.amazonaws.com/${fileInfo.s3Key}`;
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = s3URL;
        anchor.download = fileName;
        anchor.click();
      }
    } catch (error) {
      console.error('Error exporting bulk adjustment file', error);
      showSnackbar({
        message: settingsErrorMessage,
        type: 'error',
        timeout: 10000
      });
    }
  };

  // Handles clicking on an option in the dropdown menu and opening a confirmation dialogue
  const handleOptionClick = async (id: DROPDOWN_ACTION) => {
    if (id === DROPDOWN_ACTION.REVERT) {
      handleClose();
      setConfirmationDialogue({
        open: true,
        title: 'Revert File',
        body: 'Are you sure you want to revert this file? Once this file is reverted to an old version it cannot be recovered.',
        buttonText: 'Revert'
      });
    } else if (id === DROPDOWN_ACTION.DELETE) {
      setConfirmationDialogue({
        open: true,
        title: 'Delete File',
        body: 'Are you sure you want to delete this file? Once this file is deleted it cannot be recovered.',
        buttonText: 'Delete'
      });
    } else if (id === DROPDOWN_ACTION.EXPORT) {
      setConfirmationDialogue({ ...confirmationDialogue, open: false });
      handleExport();
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <BeaconProEllipses
        role="button"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          // Prevents the bulk adjustment modal from opening
          // when clicking on the ellipses
          e.stopPropagation();
          const { bulkAdjustmentStatus } = row;
          if (
            [BulkAdjustmentStatus.Published, BulkAdjustmentStatus.Draft, BulkAdjustmentStatus.Incomplete].includes(
              bulkAdjustmentStatus
            )
          ) {
            handleOpen(e);
          }
        }}
        style={{ width: '24px', height: '24px' }}
      />
      <SlMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={!!anchorEl}
        selectedId={null}
        onClick={(e) => e.stopPropagation()}
        onChange={({ id }) => handleOptionClick(id as DROPDOWN_ACTION)}
        options={getOptions(row.bulkAdjustmentStatus, enableRevert)}
      />
      <RevertConfirmationDialogue
        onClick={() => handleBulkRevert()}
        onClose={() => setConfirmationDialogue({ ...confirmationDialogue, open: false })}
        buttonText={confirmationDialogue.buttonText}
        title={confirmationDialogue.title}
        body={confirmationDialogue.body}
        open={confirmationDialogue && confirmationDialogue.open}
      />
    </Box>
  );
};
