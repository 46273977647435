import React, { useState, useMemo } from 'react';
import AdvertisingOverlapVennDiagram from './AdvertisingOverlapVennDiagram';
import AdvertisingOverlapBarChart from './AdvertisingOverlapBarChart';
import { ESSRequestContext, SearchRequestOverride } from 'src/store/modules/entitySearchService/operationTypes';
import Visualization from '../../components/Visualization';
import VisualizationInsight from '../../components/VisualizationInsight';
import {
  advancedSearchResponseParser,
  convertAdOverlapDataToBarData,
  convertAdOverlapDataToVennData,
  getConversionRateInsight,
  getShoppersInsight,
  getConversionsInsight
} from './utils';
import { AD_MANAGER_STATE_PROPERTY_NAME } from '../constants';
import { Widget } from 'src/types/application/widgetTypes';
import useConnectFetchEntityMetrics from '../hooks/useConnectFetchEntityMetrics';
import useAmcDateRange from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/hooks/useAmcDateRange';
import { AdvertisingOverlapMetric } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import { useAppSelector, useMetricFormatter, useBus } from 'src/utils/Hooks';
import { AdvertisingOverlapData } from './types';
import { withBus } from 'react-bus';
import { ADVERTISING_OVERLAP_EVENT } from './constants';
import { EventBus } from 'src/types/utils';

interface AdvertisingOverlapVisualizationProps {
  widget: Widget;
  eventBus: EventBus;
}
/**
 * Advertising overlap visualization. Displays a Venn
 * diagram and bar chart showing impact of different
 * ad types
 */
const AdvertisingOverlapVisualization = ({ widget, eventBus }: AdvertisingOverlapVisualizationProps) => {
  const [metric, setMetric] = useState<AdvertisingOverlapMetric>(AdvertisingOverlapMetric.ConversionRate);
  const { endDayId, startDayId } = useAmcDateRange();
  const formatMetric = useMetricFormatter();
  const retailer = useAppSelector((state) => state.retailer);

  useBus(eventBus, ADVERTISING_OVERLAP_EVENT, setMetric);

  /**
   * Define a response parser to extract the documents from the Advanced Search
   * response and provide it in a more consumable format
   */
  const requestContextOverrides: Partial<ESSRequestContext> = useMemo(
    () => ({
      customResponseParser: ({ apiResponse, state }) => {
        return {
          ...state[AD_MANAGER_STATE_PROPERTY_NAME],
          advertisingOverlapData: advancedSearchResponseParser(apiResponse.data[0])
        };
      }
    }),
    []
  );

  /**
   * Advanced Search properties for advertising overlap query
   */
  const searchRequestOverrides: SearchRequestOverride[] = useMemo(
    () => [
      {
        doAggregation: true,
        returnDocuments: true,
        pageNumber: 1,
        pageSize: 50,
        processDocuments: false,
        conditions: {
          termFilters: [
            {
              fieldName: 'periodStartDate',
              condition: 'should',
              values: [`${startDayId}`]
            },
            {
              fieldName: 'periodEndDate',
              condition: 'should',
              values: [`${endDayId}`]
            },
            {
              fieldName: 'segmentId',
              condition: 'should',
              values: ['DEFAULT']
            },
            {
              fieldName: 'retailerId',
              condition: 'should',
              values: [`${retailer.id}`]
            }
          ],
          computeFilters: []
        }
      }
    ],
    [endDayId, startDayId]
  );

  const { isLoading, data: queryData } = useConnectFetchEntityMetrics({
    indexName: 'overlap-metrics',
    visualizationKey: 'advertisingOverlapData',
    searchRequestOverrides,
    requestContextOverrides
  });

  /**
   * Safe access to Advanced Search response since some properties can
   * be undefined if the query fails
   */
  const adOverlapData: AdvertisingOverlapData | null = useMemo(() => {
    return queryData && queryData.advertisingOverlapData && Object.keys(queryData.advertisingOverlapData).length > 0
      ? queryData.advertisingOverlapData
      : null;
  }, [queryData]);

  /**
   * Computed insights text that appears at the top of the visualization
   */
  const insightText: string = useMemo(() => {
    // Data not loaded yet or one of the purchase rates is 0 so we
    // avoid 0 division
    if (isLoading || !adOverlapData) {
      return '';
    }

    if (metric === AdvertisingOverlapMetric.ConversionRate) {
      const purchaseRates = convertAdOverlapDataToBarData(adOverlapData, AdvertisingOverlapMetric.ConversionRate);
      return getConversionRateInsight(purchaseRates, formatMetric);
    } else if (
      metric === AdvertisingOverlapMetric.Conversions ||
      metric === AdvertisingOverlapMetric.PercentOfConversions
    ) {
      return getConversionsInsight(
        convertAdOverlapDataToVennData(adOverlapData, AdvertisingOverlapMetric.Conversions, retailer),
        formatMetric
      );
    }
    return getShoppersInsight(
      convertAdOverlapDataToVennData(adOverlapData, AdvertisingOverlapMetric.UniqueShoppers, retailer),
      formatMetric
    );
  }, [isLoading, adOverlapData, formatMetric, metric, retailer]);

  return (
    <Visualization
      titleProps={{
        title: ''
      }}
    >
      <VisualizationInsight>{insightText}</VisualizationInsight>
      <div style={{ display: 'flex', marginTop: '24px' }}>
        <AdvertisingOverlapBarChart
          widget={widget}
          metric={metric}
          isLoading={isLoading}
          data={
            adOverlapData
              ? convertAdOverlapDataToBarData(adOverlapData, metric).map((y) => ({
                  name: `${y}`,
                  value: y
                }))
              : []
          }
        />
        <AdvertisingOverlapVennDiagram
          key={`${startDayId} ${endDayId}`}
          metric={metric}
          isLoading={isLoading}
          data={adOverlapData}
        />
      </div>
    </Visualization>
  );
};

export default withBus('eventBus')(AdvertisingOverlapVisualization);
