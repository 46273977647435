import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';

import MUISelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { ChevronIcon } from 'src/components/SvgIcons';
import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import ImageBox from '../Widgets/ImageBox';
import colors from 'src/utils/colors';
import { SectionWrapper, OperationButtons } from '../Widgets/AdCampaignBuilderCommonWidgets';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';

import { store } from 'src/main';
import { adPlatformSettingsByClientOperations } from 'src/store/modules/adManager/adPlatformSettingsByClient';
import '../AdCampaignBuilder.scss';
import Axios from 'axios';
import { makeStyles } from '@mui/styles';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';

export const nameNotValid = (headline) => {
  const regex = /[<>#^!@%*~]/g;
  return regex.test(headline);
};

const muiStyles = {
  root: {
    margin: '20px 0 0 0',
    maxWidth: 250,
    '&:before': {
      content: 'none'
    }
  },
  selectRoot: {
    margin: 0,
    width: 100,
    maxWidth: 300
  },
  select: {
    padding: '7px 27px 7px 7px',
    width: 100,
    maxWidth: 300
  },
  fieldTitle: {
    fontSize: 20,
    margin: '20px 0'
  },
  uploadButtonContainer: {
    margin: '20px 0 30px'
  },
  logoList: {
    margin: '0 10px 50px',
    padding: '0 10px'
  }
};

const useStyles = makeStyles({
  uploadButtonRoot: {
    backgroundColor: `${colors.greenButton} !important`
  }
});

const formatClickVolume = (click) => {
  return convertMetricToDisplayValue({}, click, 'VOLUME', '$', false);
};

// some example headline, should call an API to get this
const topSixHeadlines = [
  { click: 22441, sponsored: 'Blue Buffalo', headline: 'Delicious Chicken Dog Food' },
  { click: 18800, sponsored: 'Green Goat', headline: 'Drool Worthy All-natural Dog Food' },
  { click: 15231, sponsored: 'Yellow Fluffer', headline: 'Good Boy Doggo Canine Treats' },
  { click: 12441, sponsored: 'Purple Potato', headline: 'Treats to Sit and Stay for' },
  { click: 8741, sponsored: 'Orange Ears', headline: 'Organic Dog Food to Wag About' },
  { click: 3833, sponsored: 'Blue Buffalo', headline: 'Furriously healthy Dog Food' }
];

const FILE_SIZE = 200000; // 200 kB

export const SbaLogo = ({
  saveLogo,
  sbaLogo,
  isPNG,
  isValidDimension,
  isValidSize,
  setIsPNG,
  setIsValidDimension,
  setIsValidSize
}) => {
  const uploadInputRef = React.useRef(null);
  const classes = useStyles();

  const convertBase64 = (file) => {
    if (file && file.type.includes('/png')) {
      setIsPNG(true);
    } else {
      setIsPNG(false);
    }

    if (file.size <= FILE_SIZE) {
      setIsValidSize(true);
    } else {
      setIsValidSize(false);
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = (theFile) => {
        const image = new Image();
        image.src = theFile.target.result;
        // eslint-disable-next-line func-names
        image.onload = function () {
          // eslint-disable-next-line react/no-this-in-sfc
          if (this.width === 300 && this.height === 180) {
            setIsValidDimension(true);
          } else {
            setIsValidDimension(false);
          }
        };
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    saveLogo(base64);
  };

  const retry = !(isPNG && isValidDimension && isValidSize);

  return (
    <div className="App">
      <div style={muiStyles.fieldTitle}>Brand Logo</div>
      <ul style={muiStyles.logoList}>
        <li style={{ color: !isPNG ? colors.red : colors.darkBlue }}>Logo file must be PNG</li>
        <li style={{ color: !isValidDimension ? colors.red : colors.darkBlue }}>Logo dimension must be 300x180</li>
        <li style={{ color: !isValidSize ? colors.red : colors.darkBlue }}>{'File size <200kb'}</li>
      </ul>
      <span>
        <input type="file" ref={uploadInputRef} hidden onChange={(e) => uploadImage(e)} />
        <div style={muiStyles.uploadButtonContainer}>
          <LargeMuiButton
            onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
            classes={{ root: retry ? {} : classes.uploadButtonRoot }}
            secondary={retry}
          >
            {retry ? 'Retry' : 'Upload'}
          </LargeMuiButton>
        </div>
      </span>
      {sbaLogo && <img alt="" src={sbaLogo} style={{ maxWidth: 300, maxHeight: 300 }} />}
    </div>
  );
};
SbaLogo.propTypes = {
  saveLogo: PropTypes.func.isRequired,
  sbaLogo: PropTypes.string.isRequired,
  isPNG: PropTypes.bool.isRequired,
  isValidDimension: PropTypes.bool.isRequired,
  isValidSize: PropTypes.bool.isRequired,
  setIsPNG: PropTypes.func.isRequired,
  setIsValidDimension: PropTypes.func.isRequired,
  setIsValidSize: PropTypes.func.isRequired
};
const FieldWapper = ({ title, children, style }) => (
  <div style={style}>
    <h3 className="field-header">{title}</h3>
    {children}
  </div>
);
FieldWapper.propTypes = {
  style: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const HeadlineSuggestion = () => {
  return (
    <div className="headline-suggestion">
      <div className="headline-suggestion-row header-row">
        <div className="row-left">
          <div className="header">Click</div>
        </div>
        <div className="row-right">
          <div className="header">Headline</div>
        </div>
      </div>
      {topSixHeadlines.map(({ click, sponsored, headline }, idx) => (
        <div key={idx} className="headline-suggestion-row">
          <div className="row-left">
            <div className="click">{formatClickVolume(click)}</div>
          </div>
          <div className="row-right">
            <div className="sponsored">Sponsored by {sponsored}</div>
            <div className="headline">{headline}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

HeadlineSuggestion.propTypes = {};

const HEADLINE_LIMIT = 50;
const HEADLINE_LIMIT_SBA = 45;
const BRAND_NAME_LIMIT = 35;

const canContinue = (isSBA, headline, brandName, clickUrl, clickUrlLimit, brandLogoAssetId, sbaLogo) => {
  // common validations
  if (!headline || headline.trim() === '') {
    return false;
  }

  if (isSBA) {
    // sba validations
    if (headline.length > HEADLINE_LIMIT_SBA) {
      return false;
    }
    if (!brandName || brandName.trim() === '') {
      return false;
    }
    if (brandName.length > BRAND_NAME_LIMIT) {
      return false;
    }
    if (!clickUrl || _get(clickUrl, 'length', 0) === 0 || clickUrlLimit) {
      return false;
    }
    if (!sbaLogo) {
      return false;
    }
  } else {
    if (headline.length > HEADLINE_LIMIT) {
      return false;
    }
    if (nameNotValid(headline)) {
      return false;
    }
    if (!brandLogoAssetId || brandLogoAssetId.trim() === '') {
      return false;
    }
  }

  return true;
};

const SelectLogo = ({ logos, brandLogoAssetId, handleSelect, uploadInfo }) => {
  const [open, setOpen] = React.useState(false);
  const uploadInputRef = React.useRef(null);

  const [uploadButtonText, setUploadButtonText] = React.useState('Upload Logo');
  const selectedlogo = logos.find((logo) => logo.id === brandLogoAssetId);
  const { entityId, brandEntityId, platformType, beaconClientLoginId } = uploadInfo;
  const uploadLogo = async (imgFile) => {
    const LOGO_UPLOAD_ENDPOINT = `/apiAdManager/adCampaigns/uploadBrandLogo/${entityId}`;

    const uploadFile = new FormData();
    uploadFile.append('file', imgFile);
    uploadFile.append('brandEntityId', brandEntityId);
    uploadFile.append('platformType', platformType);
    uploadFile.append('beaconClientLoginId', beaconClientLoginId);

    // const requestBody = {
    //   brandEntityId,
    //   file: imgFile
    // }
    setUploadButtonText('Uploading');

    try {
      const res = await Axios.post(LOGO_UPLOAD_ENDPOINT, uploadFile);
      if (res) {
        if (res.status === 200) {
          if (res.data.code === 'SUCCESS') {
            await store.dispatch(adPlatformSettingsByClientOperations.fetchAdPlatformSettingsByClient());
          } else {
            // eslint-disable-next-line
            alert(res.data.description);
          }
        }
      }
    } catch (err) {
      const errorMsg = _get(err, ['response', 'data', 'message'], 'Failed, please try again later.');
      // eslint-disable-next-line
      alert(errorMsg);
    }
    setUploadButtonText('Upload Logo');
  };

  const handleLogoUpload = (evt) => {
    const file = evt.target.files[0];
    uploadLogo(file);
  };

  // make this to true in future
  const allowUpload = brandEntityId;

  const logoRequirements = [
    'Dimensions are at least 400 x 400 px.',
    'File size is smaller than 1MB.',
    'File format is PNG, JPEG, or GIF.',
    'Logo needs to fill the image or have a white or transparent background.'
  ];

  return (
    <div>
      <div className="logo-preview" role="button" onClick={() => setOpen(true)}>
        {selectedlogo ? (
          <img alt={selectedlogo.id} src={selectedlogo.url} />
        ) : (
          <div className="empty-logo">Select a logo</div>
        )}
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Pick your logo</DialogTitle>
        <DialogContent>
          {/* logo gallery */}
          <div className="logo-gallery">
            {logos.map((logo) => {
              return (
                <ImageBox
                  key={logo.id}
                  photo={logo}
                  handleOnClick={() => handleSelect(logo.id)}
                  isSelected={logo.id === brandLogoAssetId}
                />
              );
            })}
          </div>
        </DialogContent>
        <DialogActions
          id="alert-dialog-action"
          style={{ paddingTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}
        >
          {allowUpload && (
            <Tooltip
              title={
                <ul>
                  {logoRequirements.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              }
            >
              <span>
                <input type="file" ref={uploadInputRef} hidden onChange={handleLogoUpload} />

                <LargeMuiButton
                  disabled={uploadButtonText === 'Uploading'}
                  onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                >
                  {uploadButtonText}
                </LargeMuiButton>
              </span>
            </Tooltip>
          )}
          <LargeMuiButton onClick={() => setOpen(false)}>Confirm</LargeMuiButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

SelectLogo.propTypes = {
  uploadInfo: PropTypes.any.isRequired,
  brandLogoAssetId: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  logos: PropTypes.array.isRequired
};

const CONFIG = {
  extractPlatformName(input) {
    // Input "SamsClub-US"
    // Output: "SamsClub"
    // Regular expression to match the name part before the optional '-Country'
    // Extract the matched part and Return the matched group if it exists, otherwise return an empty string
    return (input.match(/^([^-]+)/) || [''])[0];
  },
  getPlatformName(input) {
    const name = this.extractPlatformName(input);
    if (name === '') {
      // in case we didn't find it we run additional check
      const allPlatforms = Object.values(PARENT_PLATFORMS);
      const search = allPlatforms.find((v) => v.toLowerCase() === input.toLowerCase());
      if (search) {
        return search;
      } else {
        console.error(`Couldn't find platformType for input:`, input);
        return '';
      }
    }
    return name;
  },
  [PARENT_PLATFORMS.WALMART]: {
    website: 'https://www.walmart.com'
  },
  [PARENT_PLATFORMS.SAMSCLUB]: {
    website: 'https://www.samsclub.com'
  }
};

const CreativeHeadline = ({ adPlatformSettingsByClient, adCampaignBuilder, classes, onSave }) => {
  const [isPNG, setIsPNG] = useState(true);
  const [isValidSize, setIsValidSize] = useState(true);
  const [isValidDimension, setIsValidDimension] = useState(true);
  if (!adCampaignBuilder.platformSettings) {
    return null;
  }

  const platformType = CONFIG.getPlatformName(adCampaignBuilder.platformId);
  const configToUse = CONFIG[platformType];

  const {
    creative: { headline, brandLogoAssetId, brandEntityId, brandName, clickUrl, sbaLogo },
    platformSettings: { entity }
  } = adCampaignBuilder;
  const brandLogosForCurrentEntity = _cloneDeep(adPlatformSettingsByClient).filter(
    (img) =>
      img.settingType === 'asset' &&
      img.extendedAttributes.amsApiModel.mediaType === 'brandLogo' &&
      img.extendedAttributes.entityId === entity.id
  );

  // seller or vendor account type
  const entityAccountType = _get(entity, ['extendedAttributes', 'amsApiModel', 'accountInfo', 'type']);

  const availableBrand = _cloneDeep(adPlatformSettingsByClient).filter((setting) => {
    return setting.settingType === 'brand' && setting.extendedAttributes.entityId === entity.id;
  });

  const logos = brandLogosForCurrentEntity.map((brandLogo) => ({
    id: brandLogo.extendedAttributes.amsApiModel.assetId,
    url: brandLogo.extendedAttributes.amsApiModel.url
  }));

  const handleSelect = (id) => {
    onSave({ brandLogoAssetId: id });
  };
  const isSBA = _get(adCampaignBuilder, 'campaignType.settingId') === 'sba';

  const lengthLimit = headline && headline.length > (isSBA ? HEADLINE_LIMIT_SBA : HEADLINE_LIMIT);
  const charLimit = nameNotValid(headline);
  const brandNameLimit = brandName && brandName.length > BRAND_NAME_LIMIT;
  const clickUrlLimit = !(clickUrl && _get(clickUrl, 'length', 1) > 0
    ? (clickUrl.startsWith && clickUrl.startsWith('https://www.walmart.com')) ||
      (clickUrl.startsWith && clickUrl.startsWith('https://www.samsclub.com'))
    : true);

  const campaignType = _get(adCampaignBuilder, ['campaignType', 'name']);

  const uploadInfo = {
    entityId: entity.id,
    brandEntityId: entityAccountType === 'seller' ? brandEntityId : entity.extendedAttributes.entityIdUi,
    platformType: entity.platformType,
    beaconClientLoginId: entity.extendedAttributes.beaconClientLoginId
  };

  return (
    <div className="ad-manager-container">
      <SectionWrapper
        header="Headline Message"
        subheader={`Since this is a ${campaignType} campaign, please create a headline message.`}
        layer={0}
      >
        <FieldWapper title="Headline" style={{ margin: '45px 0px' }}>
          <TextField
            variant="standard"
            autoComplete="off"
            id="outlined-bare"
            classes={{ root: classes.root }}
            value={headline}
            placeholder="Headline Name"
            onChange={({ target: { value } }) => {
              onSave({ headline: value });
            }}
            margin="normal"
          />
          {lengthLimit && (
            <div style={{ color: colors.red }}>
              *Headline text should not be longer than {isSBA ? 45 : 50} characters
            </div>
          )}
          {charLimit && (
            <div style={{ color: colors.red }}>
              {'*Headline text cannot contain special characters such as <>#^!@%*~'}
            </div>
          )}
        </FieldWapper>
        {isSBA && (
          <FieldWapper title="Brand Name" style={{ margin: '45px 0px' }}>
            <TextField
              variant="standard"
              autoComplete="off"
              id="outlined-bare"
              classes={{ root: classes.root }}
              value={brandName}
              placeholder="Brand Name"
              onChange={({ target: { value } }) => {
                onSave({ brandName: value });
              }}
              margin="normal"
            />
            {brandNameLimit && (
              <div style={{ color: colors.red }}>
                *Headline text should not be longer than {BRAND_NAME_LIMIT} characters
              </div>
            )}
          </FieldWapper>
        )}
        {isSBA && (
          <FieldWapper title="Click URL" style={{ margin: '45px 0px' }}>
            <TextField
              variant="standard"
              autoComplete="off"
              id="outlined-bare"
              classes={{ root: classes.root }}
              value={clickUrl}
              placeholder="Click URL"
              onChange={({ target: { value } }) => {
                onSave({ clickUrl: value });
              }}
              margin="normal"
            />
            {clickUrlLimit && <div style={{ color: colors.red }}>*The url must start with {configToUse.website}</div>}
          </FieldWapper>
        )}
      </SectionWrapper>
      {entityAccountType === 'seller' && (
        <>
          <strong style={{ fontSize: 16 }}>Brand: </strong>
          <MUISelect
            variant="standard"
            style={{ marginRight: 20 }}
            value={brandEntityId || null}
            onChange={(evt) => onSave({ brandEntityId: evt.target.value })}
            classes={{ root: classes.selectRoot, select: classes.select }}
            IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
          >
            {availableBrand.map((opt, idx) => (
              <MenuItem key={idx} value={opt.id}>
                {opt.name}
              </MenuItem>
            ))}
          </MUISelect>
          <br></br>
          <br></br>
        </>
      )}
      {logos && !isSBA && (
        <SelectLogo
          logos={logos}
          brandLogoAssetId={brandLogoAssetId}
          handleSelect={handleSelect}
          uploadInfo={uploadInfo}
        />
      )}
      {isSBA && (
        <SbaLogo
          {...{
            sbaLogo,
            saveLogo: (value) => {
              onSave({ sbaLogo: value });
            },
            isPNG,
            isValidDimension,
            isValidSize,
            setIsPNG,
            setIsValidSize,
            setIsValidDimension
          }}
        />
      )}
      <OperationButtons
        subtab="creativeHeadline"
        canContinue={canContinue(isSBA, headline, brandName, clickUrl, clickUrlLimit, brandLogoAssetId, sbaLogo)}
      />
    </div>
  );
};

CreativeHeadline.propTypes = {
  retailer: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  adCampaignBuilder: PropTypes.object.isRequired,
  adPlatformSettingsByClient: PropTypes.array.isRequired
};

const mapStateToProps = ({ adCampaignBuilder, adPlatformSettingsByClient, retailer }) => ({
  adCampaignBuilder,
  adPlatformSettingsByClient,
  retailer
});

const EnhancedCreativeHeadline = connect(mapStateToProps, { onSave: adCampaignBuilderActions.setCreative })(
  withStyles(muiStyles)(CreativeHeadline)
);

export default EnhancedCreativeHeadline;
