import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';

export interface TriggerDownloadBulkAdjustmentTemplatePayload {
  userId: string;
  planType: PlanTypeOption;
  beaconClientId: number;
  retailerId: number;
  bulkAdjustmentLevel: string;
  bulkAdjustmentLevelId: string;
}

export interface UploadBulkAdjustmentPayload {
  bulkAdjustmentFile: File;
  planType: PlanTypeOption;
  bulkAdjustmentTitle: string;
  bulkAdjustmentDescription: string;
  // if user is updating the bulkAdjustment (e.g) validation failed, then we will attach original bulkAdjustmentId
  // if this is not provided, backend will create a new bulkAdjustmentId
  bulkAdjustmentId?: string;
}

export interface UploadBulkAdjustmentResponse {
  url: string;
  isSuccess: boolean;
  message: string;
  bulkAdjustmentId: string;
  userId: string;
  planType: number;
  beaconClientId: number;
  retailerId: number;
}

export interface UpdateUserUploadBulkAdjustmentStatusPayload {
  planType: string;
  bulkAdjustmentTitle: string;
  bulkAdjustmentDescription: string;
  bulkAdjustmentId: string;
  status: 'Success' | 'Error';
}

export type UpdateUserUploadBulkAdjustmentStatusResponse = [
  {
    bulkAdjustmentId: string;
    message: string;
    isSuccess: boolean;
    userId: string;
    planType: number;
    beaconClientId: number;
    retailerId: number;
  }
];

export interface ValidateBulkAdjustmentPayload {
  planType: string;
  bulkAdjustmentId: string;
}

export type ValidateBulkAdjustmentResponse = [
  {
    isSuccess: boolean;
    message: string;
    bulkAdjustmentId: string;
    userId: string;
    planType: number;
    beaconClientId: number;
    retailerId: number;
  }
];
export interface PollBulkAdjustmentPayload {
  planType: string;
  bulkAdjustmentId: string;
  bulkAdjustmentStage: 'Template' | 'Validation' | 'Compute' | 'Publish' | 'Revert';
}

export interface PaidTrafficNetImpactSummaryDetails {
  min_weekId: number;
  max_weekId: number;
  avg_unadjusted_forecast_adspend: number;
  avg_adjusted_forecast_adspend: number;
  avg_unadjusted_forecast_cpc: number;
  avg_adjusted_forecast_cpc: number;
  individual_new_avg_ad_spend: number;
  individual_new_avg_cpc: number;
  individual_new_avg_paid_traffic: number;
  individual_new_avg_ad_sales: number;
  individual_new_avg_roas: number;
  individual_current_avg_ad_spend: number;
  individual_current_avg_cpc: number;
  individual_current_avg_paid_traffic: number;
  individual_current_avg_ad_sales: number;
  individual_current_avg_roas: number;
}

export type NetImpactSummaryDetails =
  | {
      min_weekId: number;
      max_weekId: number;
      avg_unadjusted_forecast: number;
      avg_adjusted_forecast: number;
    }
  | {
      min_weekId: number;
      max_weekId: number;
      weighted_unadjusted_ratings: number;
      weighted_adjusted_ratings: number;
      unadjusted_reviews: number;
      adjusted_reviews: number;
    }
  | {
      min_weekId: number;
      max_weekId: number;
      avg_unadjusted_forecast: number;
      avg_adjusted_forecast: number;
      avg_unadjusted_other_traffic: number;
      avg_adjusted_other_traffic: number;
    }
  | PaidTrafficNetImpactSummaryDetails;

export interface ComputeAdditionalDetails {
  totalNetImpact: number;
}

export interface PollBulkAdjustmentResponse {
  pk: string;
  sk: string;
  beaconClientId: number;
  retailerId: number;
  userId: string;
  planType: number;
  bulkAdjustmentId: string;
  bulkAdjustmentStage: number;
  bulkAdjustmentLevel: string;
  bulkAdjustmentLevelId: string;
  fileUri: string;
  fileName: string | null; // TODO double check this type with backend
  isUserDownload: boolean;
  processingStatus: 'Queued' | 'InProgress' | 'Success' | 'Error';
  requestedDate: string;
  processedDate: string;
  /**
   * Unix time in milliseconds
   */
  lastUpdatedTime: number;
  additionalDetails: string | null;
  summaryDetails: string | null;
}

export interface PublishBulkAdjustmentPayload {
  planType: PlanTypeOption;
  bulkAdjustmentId: string;
  bulkAdjustmentTitle: string;
  bulkAdjustmentDescription: string;
  bulkAdjustmentChangeType: 'Absolute' | 'PercentageChange';
  saveAsDraft: boolean;
}

interface PublishBulkAdjustmentBaseResponse<MessageType> {
  bulkAdjustmentId: string;
  message: MessageType;
  isSuccess: boolean;
  userId: string;
  planType: number;
  beaconClientId: number;
  retailerId: number;
}
export type PublishBulkAdjustmentResponse = [
  PublishBulkAdjustmentBaseResponse<'Initiated_NetImpact'>,
  PublishBulkAdjustmentBaseResponse<'Initiated_AdjustedForecast'>,
  PublishBulkAdjustmentBaseResponse<'Initiated_AdjustedCandleStick'>
];

export interface ComputeNetImpactForBulkAdjustmentPayload {
  planType: PlanTypeOption;
  bulkAdjustmentId: string;
}

export type ComputeNetImpactForBulkAdjustmentResponse = [
  {
    isSuccess: boolean;
    message: string;
    bulkAdjustmentId: string;
    userId: string;
    planType: number;
    beaconClientId: number;
    retailerId: number;
  }
];

/**
 * Response for reverting a bulk adjustment
 */
export type RevertBulkAdjustmentResponse = {
  bulkAdjustmentId: string;
  message: string;
  isSuccess: true;
  userId: string;
  planType: number;
  beaconClientId: number;
  retailerId: number;
}[];

/**
 * Payload for reverting a bulk adjustment
 */
export interface RevertBulkAdjustmentPayload {
  userId: string;
  planType: string;
  beaconClientId: number;
  retailerId: number;
  bulkAdjustmentId: string;
  isDraft: boolean;
}

export interface ServerProxy {
  /**
   * Uploads a bulk adjustment file to the server
   */
  uploadBulkAdjustment: (data: UploadBulkAdjustmentPayload) => Promise<UploadBulkAdjustmentResponse>;
  /**
   * Updates the status of a bulk adjustment file on the server
   */
  updateUserUploadBulkAdjustmentStatus: (
    data: UpdateUserUploadBulkAdjustmentStatusPayload
  ) => Promise<UpdateUserUploadBulkAdjustmentStatusResponse>;
  /**
   * Validates a bulk adjustment file on the server
   */
  validateBulkAdjustment: (data: ValidateBulkAdjustmentPayload) => Promise<ValidateBulkAdjustmentResponse>;

  /**
   *
   * Triggers the download of a bulk adjustment template
   */

  triggerDownloadBulkAdjustmentTemplate: (
    data: TriggerDownloadBulkAdjustmentTemplatePayload
  ) => Promise<ValidateBulkAdjustmentResponse>;

  /**
   * Generic endpoint for checking the status of various stages
   * of the bulk adjustment process
   */
  pollBulkAdjustments: (payload: PollBulkAdjustmentPayload) => Promise<PollBulkAdjustmentResponse[]>;

  /**
   * Publishes a bulk adjustment
   */
  publishBulkAdjustment: (payload: PublishBulkAdjustmentPayload) => Promise<PublishBulkAdjustmentResponse>;

  /**
   * Tells the backend to start computing net impact
   */
  computeNetImpactForBulkAdjustment: (
    payload: ComputeNetImpactForBulkAdjustmentPayload
  ) => Promise<ComputeNetImpactForBulkAdjustmentResponse>;

  /**
   * Reverts a bulk adjustment
   */
  revertBulkAdjustment: (
    payload: Omit<RevertBulkAdjustmentPayload, 'userId' | 'beaconClientId' | 'retailerId'>
  ) => Promise<RevertBulkAdjustmentResponse>;
}

export enum BulkAdjustmentStatus {
  Published = 'Published',
  Draft = 'Draft',
  Incomplete = 'Incomplete',
  Processing = 'Processing',
  Reverting = 'Reverting',
  Reverted = 'Reverted',
  Deleted = 'Deleted'
}
/**
 * This is the response from the server when you fetch the adjustments uploads
 */
export interface BulkAdjustmentStatusRow {
  accuracyDetails: string; // Ex: "{\"accuracyPercentage\": \"100.0\", \"numOfSuccessfulAdjs\": \"2\", \"totalAdjustments\": \"2\"}"
  beaconClientId: number;
  retailerId: number;
  userId: string;
  planType: PlanTypeOption;
  bulkAdjustmentId: string;
  bulkAdjustmentTitle: string;
  bulkAdjustmentDescription: string;
  bulkAdjustmentStatus: BulkAdjustmentStatus;
  bulkAdjustmentLevel: string | null;
  bulkAdjustmentLevelId: string | null;
  requestedDate: string;
  processedDate: string;
  lastUpdatedTime: string;
  additionalDetails: null;
  publishVersion: string;
}

export interface AccuracyDetails {
  accuracyPercentage: string;
  numOfSuccessfulAdjs: string;
  totalAdjustments: string;
}

export interface GetBulkAdjustmentStatusesResponse {
  paginationToken: string | null;
  result: BulkAdjustmentStatusRow[];
}

export interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  emailVerified: boolean;
  emailAlias: string | null;
  company: string | null;
  jobTitle: string | null;
  phoneNumber: string | null;
  photoId: string | null;
}

export interface UserMetaData {
  [key: string]: UserData;
}

export type BulkAdjustmentUploadDatum = BulkAdjustmentStatusRow & UserData & { disableRowClick: boolean };
