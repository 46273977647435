import _cloneDeep from 'lodash/cloneDeep';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import { encodeCampaignName } from 'src/utils/browser';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { RangeFilter } from 'sl-api-connector';
import ReduxStore from 'src/types/store/reduxStore';
import { parseEntityMetrics } from 'src/store/modules/entitySearchService/selectors';
import { Conditions, TermFilter } from 'sl-api-connector/types';
import { shouldShowCriteo } from 'src/utils/app';

export function addOrReplaceFilterInEntityConditions(
  entityConditions: Conditions,
  filterPropertyName: 'termFilters' | 'rangeFilters',
  filter: any
) {
  if (!entityConditions[filterPropertyName]) {
    entityConditions[filterPropertyName] = [];
  }

  entityConditions[filterPropertyName] = [
    ...(filterPropertyName === 'termFilters'
      ? (entityConditions[filterPropertyName] || []).filter((x) => x.fieldName !== filter.fieldName)
      : (entityConditions[filterPropertyName] || []).filter((x) => x.fieldName !== filter.fieldName)),
    filter
  ];
}

export function buildTermFilterCondition({
  idFieldName,
  filterValues,
  condition
}: {
  idFieldName: string;
  filterValues: { i: string; n?: string }[];
  condition?: 'should' | 'must' | 'must_not';
}): TermFilter[] {
  const termFilters: TermFilter[] = [];

  termFilters.push({
    fieldName: idFieldName,
    values: filterValues.map((value) =>
      ['campaignNameFuzzy', 'excludedCampaignNameFuzzy'].includes(idFieldName)
        ? // names from handleSearchChange() returns output of JSON.stringify() which have quotes
          value.i.startsWith('"') && value.i.endsWith('"')
          ? encodeCampaignName(value.i.slice(1, -1))
          : encodeCampaignName(value.i)
        : value.i
    ),
    condition
  });

  return termFilters;
}

export const createFilterConditions = (
  formDataToUse,
  mainEntityType,
  termFiltersForGroupByField,
  groupByField,
  searchSideBarConditions
) => {
  const searchSideBarConditionsClone = _cloneDeep(searchSideBarConditions);

  const termFiltersForGroupByFieldElement = termFiltersForGroupByField[groupByField.name];
  if (!termFiltersForGroupByFieldElement) {
    return searchSideBarConditionsClone;
  }

  termFiltersForGroupByFieldElement.forEach(({ filterName, fieldName }: { filterName: string; fieldName: string }) => {
    if (!formDataToUse.termFilters[filterName]) {
      return;
    }

    const termFilters = buildTermFilterCondition({
      filterValues: formDataToUse.termFilters[filterName].values,
      condition: formDataToUse.termFilters[filterName].condition,
      idFieldName: fieldName
    });
    if (mainEntityType === 'segment') {
      // Idk why needed, in result on load no term filters applied
      // const overwriteTermFilters = termFilters;
      // if (overwriteTermFilters.length > 0) {
      //   addOrReplaceFilterInEntityConditions(searchSideBarConditionsClone, 'termFilters', overwriteTermFilters[0]);
      // }
      searchSideBarConditionsClone.termFilters = searchSideBarConditionsClone.termFilters.filter(
        (termFilter) => termFilter.values && termFilter.values.length > 0
      );
    } else {
      searchSideBarConditionsClone.termFilters = [...searchSideBarConditionsClone.termFilters, ...termFilters];
    }
  });

  return searchSideBarConditionsClone;
};

/**
 * Build aggregations for a single derived or computed field.  It takes the list of all of its dependent fields and
 * recursively builds aggregations for those as well, adding them to the aggregation computation state.
 *
 * Returns a new derivation to be added to the output `derivations` array if there is one to be added, otherwise
 * returns `undefined`.
 *
 * @param {object} field The derived or computed field for which to build aggregations
 * @param {object} state The current state of the aggregation computation process.  Some of it will be mutated as a
 *    result of this function.
 */
const buildAggregationsForDerivedOrComputedField = (
  field,
  { indexName, uniqueAggregationFieldNames, aggregationFieldConditions, newAggregations }
) => {
  let newDerivation;

  if (field.aggregationFunctionType === 'derived') {
    newDerivation = field;
  }

  // Recursively build aggregations for dependent fields of the computed/derived aggregation function.
  // Computed/derived aggregations are defined as a string representing a function to apply to the query results in
  // order to generate a new field from existing fields.  The variables names used in that query are used here to
  // generate aggregations sent along with the query to ensure that the required data for that function exists.
  field.dependentFields.forEach((dependentField) => {
    if (dependentField.indexName === indexName) {
      if (newAggregations.findIndex((x) => x.aggregateByFieldName === dependentField.name) === -1) {
        if (!uniqueAggregationFieldNames[dependentField.name]) {
          if (['computed'].includes(dependentField.aggregationFunctionType)) {
            buildAggregationsForDerivedOrComputedField(dependentField, {
              indexName,
              uniqueAggregationFieldNames,
              aggregationFieldConditions,
              newAggregations
            });
          } else {
            newAggregations.push({
              aggregateByFieldDisplayName: dependentField.displayName,
              aggregateByFieldName: dependentField.name,
              function: dependentField.aggregationFunction,
              canBeExported: dependentField.canBeExported !== undefined ? dependentField.canBeExported : true
            });
          }
          uniqueAggregationFieldNames[dependentField.name] = true;
        }
      }

      if (dependentField.conditions) {
        if (dependentField.conditions.termFilters) {
          aggregationFieldConditions.termFilters = aggregationFieldConditions.termFilters.concat(
            dependentField.conditions.termFilters
          );
        }
        if (dependentField.conditions.rangeFilters) {
          aggregationFieldConditions.rangeFilters = aggregationFieldConditions.rangeFilters.concat(
            dependentField.conditions.rangeFilters
          );
        }
      }
    }
  });

  if (field.aggregationFunctionType === 'computed' && !uniqueAggregationFieldNames[field.name]) {
    // This is necessary since expr-eval does not allow us to use a field with a .
    const aggregateByFormula = field.aggregationFunctionExpression.replace('_convertedUSD', '_converted.USD_sum');

    newAggregations.push({
      aggregateByFieldDisplayName: field.displayName,
      aggregateByFieldName: field.name,
      function: field.aggregationFunction,
      aggregateByFormula,
      canBeExported: field.canBeExported !== undefined ? field.canBeExported : true
    });

    if (field.conditions) {
      if (field.conditions.termFilters) {
        aggregationFieldConditions.termFilters = aggregationFieldConditions.termFilters.concat(
          field.conditions.termFilters
        );
      }
      if (field.conditions.rangeFilters) {
        aggregationFieldConditions.rangeFilters = aggregationFieldConditions.rangeFilters.concat(
          field.conditions.rangeFilters
        );
      }
    }
    uniqueAggregationFieldNames[field.name] = true;
  }

  return newDerivation;
};

/**
 *
 * @param {object} acc State accumulator from `.reduce`
 * @param {object} field The element of `metricFieldsByIndexNames` that we're building aggregations for
 */
const buildAggregationsForMetricField = (acc, field) => {
  const {
    indexName,
    uniqueMetricFieldNames,
    derivations,
    aggregations,
    uniqueAggregationFieldNames,
    aggregationFieldConditions
  } = acc;

  // Avoid building duplicate aggregations if we've already built one for this field
  if (uniqueMetricFieldNames[field.name] || field.indexName !== indexName) {
    return acc;
  }

  const newAggregations = [...aggregations];
  let newDerivation;
  let newAggregationFieldConditions = aggregationFieldConditions;

  if (['derived', 'computed'].includes(field.aggregationFunctionType)) {
    newDerivation = buildAggregationsForDerivedOrComputedField(field, {
      indexName,
      uniqueAggregationFieldNames,
      aggregationFieldConditions,
      newAggregations
    });
  } else if (!uniqueAggregationFieldNames[field.name]) {
    newAggregations.push({
      aggregateByFieldDisplayName: field.displayName,
      aggregateByFieldName: field.name,
      function: field.aggregationFunction,
      ranges: field.ranges,
      canBeExported: field.canBeExported !== undefined ? field.canBeExported : true
    });
    uniqueAggregationFieldNames[field.name] = true;

    // Add in term and range filters from this field to the master set for the whole query
    newAggregationFieldConditions = {
      termFilters: [..._get(field, ['conditions', 'termFilters'], []), ...newAggregationFieldConditions.termFilters],
      rangeFilters: [..._get(field, ['conditions', 'rangeFilters'], []), ...newAggregationFieldConditions.rangeFilters]
    };
  }

  return {
    ...acc,
    derivations: newDerivation ? [...derivations, newDerivation] : derivations,
    aggregations: newAggregations,
    uniqueMetricFieldNames: { ...uniqueMetricFieldNames, [field.name]: true },
    uniqueAggregationFieldNames,
    aggregationFieldConditions: newAggregationFieldConditions
  };
};

export function buildAggregations(metricFields: any[], additionalFieldsForExport: any[] = []) {
  // For each index, build up a list of fields that use it from both the metric fields themselves as well as their
  // dependent fields recursively
  metricFields = [...metricFields, ...additionalFieldsForExport];

  const metricFieldsByIndexNames = metricFields.reduce((acc, metricField) => {
    const accWithMetricField = {
      ...acc,
      [metricField.indexName]: [...(acc[metricField.indexName] || []), metricField]
    };

    return (metricField.dependentFields || []).reduce(
      (innerAcc, dependentField) => ({
        ...innerAcc,
        [dependentField.indexName]: [...(innerAcc[dependentField.indexName] || []), dependentField]
      }),
      accWithMetricField
    );
  }, {});

  // Build aggregations for all of the merged metric fields + dependent fields, checking to avoid creating multiple
  // aggregations for the same field

  // Careful! Object.entries is not guaranteed to have the same order in all browsers
  // Let's sort it so the field with the most derivations comes first (default order in chrome without the sort)
  return _sortBy(
    Object.entries(metricFieldsByIndexNames).map(([indexName, metricFieldsForIndex]) => {
      const initialAcc: any = {
        indexName,
        groupByFieldName: null,
        groupByResultType: null,
        dataFetchStrategy: null,
        uniqueMetricFieldNames: {},
        derivations: [],
        aggregations: [],
        uniqueAggregationFieldNames: {},
        aggregationFieldConditions: { termFilters: [], rangeFilters: [] }
      };
      metricFieldsForIndex.forEach((metricField: any) => {
        if (metricField.groupByFieldName && !initialAcc.groupByFieldName) {
          initialAcc.groupByFieldName = metricField.groupByFieldName;
        }
        if (metricField.groupByResultType && !initialAcc.groupByResultType) {
          initialAcc.groupByResultType = metricField.groupByResultType;
        }
        if (metricField.dataFetchStrategy && !initialAcc.dataFetchStrategy) {
          initialAcc.dataFetchStrategy = metricField.dataFetchStrategy;
        }
      });

      const { derivations, aggregations, aggregationFieldConditions } = metricFieldsForIndex.reduce(
        buildAggregationsForMetricField,
        initialAcc
      );

      return {
        aggregations,
        derivations,
        aggregationFieldConditions,
        indexName,
        groupByFieldName: initialAcc.groupByFieldName,
        groupByResultType: initialAcc.groupByResultType,
        dataFetchStrategy: initialAcc.dataFetchStrategy
      };
    }),
    (agg) => agg.derivations.length
  ).reverse();
}

export function buildTimePeriodRangeFilters({
  app,
  indexName,
  mainTimePeriod,
  comparisonTimePeriod
}: {
  app: Pick<ReduxStore['app'], 'name'>;
  indexName: string;
  mainTimePeriod: Pick<ReduxStore['mainTimePeriod'], 'startDayId' | 'endDayId' | 'startWeek' | 'endWeek'>;
  comparisonTimePeriod?: Pick<ReduxStore['comparisonTimePeriod'], 'startDayId' | 'endDayId' | 'startWeek' | 'endWeek'>;
}) {
  const mainTimePeriodRangeFilters: RangeFilter[] = [];
  const comparisonTimePeriodRangeFilters: RangeFilter[] = [];
  // if the index supports dayId filter, then we push the dayId range filter
  if (INDEX_FIELDS.hasField(app.name, indexName, 'dayId')) {
    mainTimePeriodRangeFilters.push({
      fieldName: 'dayId',
      minValue: mainTimePeriod.startDayId,
      maxValue: mainTimePeriod.endDayId
    });
  } else {
    mainTimePeriodRangeFilters.push({
      fieldName: 'weekId',
      minValue: mainTimePeriod.startWeek,
      maxValue: mainTimePeriod.endWeek
    });
  }
  if (comparisonTimePeriod) {
    // if the index supports dayId filter, then we push the dayId range filter
    if (INDEX_FIELDS.hasField(app.name, indexName, 'dayId')) {
      comparisonTimePeriodRangeFilters.push({
        fieldName: 'dayId',
        minValue: comparisonTimePeriod.startDayId,
        maxValue: comparisonTimePeriod.endDayId
      });
    } else {
      comparisonTimePeriodRangeFilters.push({
        fieldName: 'weekId',
        minValue: comparisonTimePeriod.startWeek,
        maxValue: comparisonTimePeriod.endWeek
      });
    }
  }

  return { mainTimePeriodRangeFilters, comparisonTimePeriodRangeFilters };
}

export function zipMetricsResponseIntoArray(
  action: any,
  widget: any,
  pageSizeOveride?: number,
  enableDataStacking = true
) {
  const {
    state: { adEntities, adPortfolios, adCampaigns, entitySearchService }
  } = action;
  const {
    view: {
      gridOptions: { pageSize }
    },
    data: { statePropertyName }
  } = widget;

  const { groupByFieldName: groupByField } = action.apiRequest[0].aggregations[0];
  const groupByFieldName = groupByField === 'targetingText,targetingType' ? 'targetingText' : groupByField;
  const entityById: any = {};
  switch (groupByFieldName) {
    case 'campaignId':
      adCampaigns.forEach((adCampaign: any) => {
        entityById[adCampaign.id] = adCampaign;
      });
      break;
    case 'portfolioId':
      adPortfolios.forEach((adPortfolio: any) => {
        entityById[adPortfolio.id] = adPortfolio;
      });
      break;
    case 'entityId':
      adEntities.forEach((adEntity: any) => {
        entityById[adEntity.id] = adEntity;
      });
      break;
    default:
      break;
  }
  const mainRequestAction = {
    ...action,
    apiRequest: [action.apiRequest[0]],
    apiResponse: { data: [action.apiResponse.data[0]] }
  };

  const data = parseEntityMetrics(mainRequestAction);
  const metricPropertyNames = Object.entries(data).filter(([key]) => key !== 'apiRequest');
  const rows: any[] = metricPropertyNames[0][1].data.map((dataPoint: any, i: number) =>
    metricPropertyNames.reduce((acc, [key, value]) => {
      const entityFromValue = value.data[i].entity;
      delete value.data[i].entity;
      delete value.data[i].cardview;

      let id =
        entityFromValue.type === 'adTarget' || groupByFieldName === 'adGroupId,targetingType'
          ? entityFromValue.id + entityFromValue.targetingType
          : entityFromValue.id;
      if (groupByField === 'targetingText,isAutoAdded') {
        id = `${entityFromValue.id}exact${entityFromValue.isAutoAdded}`;
        entityFromValue.targetingType = 'exact';
      }
      return {
        ...acc,
        [key.split('_by_')[0]]: value.data[i],
        entity: {
          ...entityFromValue,
          ...entityById[entityFromValue.id]
        },
        id
      };
    }, {})
  );
  if (
    entitySearchService[statePropertyName] &&
    entitySearchService[statePropertyName].data &&
    entitySearchService[statePropertyName].groupByFieldName === groupByFieldName
  ) {
    let dataToStack = [];

    if (enableDataStacking) {
      const ids = new Set(entitySearchService[statePropertyName].data.map((d) => d.id));
      // merging only uniq items
      dataToStack = [...entitySearchService[statePropertyName].data, ...rows.filter((d) => !ids.has(d.id))];
    } else {
      dataToStack = rows;
    }

    return {
      entitySearchServiceDataModelType: 'gridRows',
      groupByFieldName,
      hasMoreRows: rows && rows.length === (pageSizeOveride || pageSize),
      data: dataToStack
    };
  }
  const result = {
    entitySearchServiceDataModelType: 'gridRows',
    hasMoreRows: rows && rows.length === (pageSizeOveride || pageSize),
    groupByFieldName,
    data: rows,
    totalResultCount: 0
  };
  if (action.apiRequest.length > 1) {
    const resultCountRequestAction = {
      ...action,
      apiRequest: [action.apiRequest[1]],
      apiResponse: { data: [action.apiResponse.data[1]] }
    };

    const resultCountData = parseEntityMetrics(resultCountRequestAction);
    if (shouldShowCriteo()) {
      if (resultCountData[`${groupByFieldName}_by_retailerId`]) {
        if (
          resultCountData[`${groupByFieldName}_by_retailerId`].data &&
          resultCountData[`${groupByFieldName}_by_retailerId`].data.length > 0
        ) {
          resultCountData[`${groupByFieldName}_by_retailerId`].data.forEach((item) => {
            result.totalResultCount += item.value;
          });
        } else {
          result.totalResultCount = 0;
        }
      } else if (
        groupByFieldName.includes('targetingText') &&
        resultCountData.targetId_by_retailerId.data &&
        resultCountData.targetId_by_retailerId.data.length > 0
      ) {
        resultCountData.targetId_by_retailerId.data.forEach((item) => {
          result.totalResultCount += item.value;
        });
      }
    } else if (resultCountData[`${groupByFieldName}_by_retailerId`]) {
      if (
        resultCountData[`${groupByFieldName}_by_retailerId`].data &&
        resultCountData[`${groupByFieldName}_by_retailerId`].data.length > 0
      ) {
        result.totalResultCount = resultCountData[`${groupByFieldName}_by_retailerId`].data[0].value;
      } else {
        result.totalResultCount = 0;
      }
    } else if (
      // TODO: clean this up to not be hardcoded for just the targetingText/targetId case
      groupByFieldName === 'targetingText' &&
      resultCountData.targetId_by_retailerId.data &&
      resultCountData.targetId_by_retailerId.data.length > 0
    ) {
      result.totalResultCount = resultCountData.targetId_by_retailerId.data[0].value;
    }
  }
  return result;
}
