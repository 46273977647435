import { useMemo } from 'react';
import {
  ComputeAdditionalDetails,
  NetImpactSummaryDetails
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/serverProxy/types';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { getFormattedStartAndEndDates } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/utils';
import { useMetricFormatter } from 'src/utils/Hooks';
import { calculateWeeksBetweenWeekIds } from 'src/utils/dateUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions';

interface UseDynamicTextProps {
  planType: PlanTypeOption;
  summaryDetails: NetImpactSummaryDetails;
}

export const useOriginalPlanDynamicText = ({ planType = null, summaryDetails }: UseDynamicTextProps): string => {
  const formatMetric = useMetricFormatter();
  const numberOfWeeks = summaryDetails
    ? calculateWeeksBetweenWeekIds(summaryDetails.min_weekId, summaryDetails.max_weekId) || 1
    : 1;
  const { formattedEndDate, formattedStartDate } = summaryDetails
    ? getFormattedStartAndEndDates(`${summaryDetails.min_weekId}`, `${summaryDetails.max_weekId}`)
    : { formattedEndDate: '', formattedStartDate: '' };

  const text = useMemo(() => {
    if (!summaryDetails) {
      return '';
    } else if ('avg_unadjusted_forecast' in summaryDetails) {
      switch (planType) {
        case PlanTypeOption.OrganicTraffic:
          return `Originally, this group of products was projected to generate ${formatMetric(
            summaryDetails.avg_unadjusted_forecast * numberOfWeeks,
            METRICTYPE.VOLUME
          )} (${formatMetric(
            summaryDetails.avg_unadjusted_forecast,
            METRICTYPE.VOLUME
          )} per week) in organic traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;

        case PlanTypeOption.OtherTraffic:
          return `Originally, this group of products was projected to generate ${formatMetric(
            summaryDetails.avg_unadjusted_forecast * numberOfWeeks,
            METRICTYPE.VOLUME
          )} (${formatMetric(
            summaryDetails.avg_unadjusted_forecast,
            METRICTYPE.VOLUME
          )} per week) in other traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;

        case PlanTypeOption.BuyBox:
          return `Originally, this product was projected to have a ${formatMetric(
            summaryDetails.avg_unadjusted_forecast / 100,
            METRICTYPE.PERCENT,
            { showFullValue: false }
          )} buy box rate during the period of ${formattedStartDate} to ${formattedEndDate}.`;

        case PlanTypeOption.ContentScore:
          return `Originally, this group of products was projected to have a ${formatMetric(
            summaryDetails.avg_unadjusted_forecast / 100,
            METRICTYPE.PERCENT,
            { showFullValue: false }
          )} content score during the period of ${formattedStartDate} to ${formattedEndDate}.`;

        case PlanTypeOption.InStockRate:
          return `Originally, this group of products was projected to have a ${formatMetric(
            summaryDetails.avg_unadjusted_forecast / 100,
            METRICTYPE.PERCENT,
            { showFullValue: false }
          )} in-stock rate during the period of ${formattedStartDate} to ${formattedEndDate}`;

        case PlanTypeOption.RetailPrice:
          // Promotion adjustment text
          if ('avg_unadjusted_other_traffic' in summaryDetails) {
            return `Originally, this group of products was projected to have an average sell price of ${formatMetric(
              summaryDetails.avg_unadjusted_forecast,
              METRICTYPE.MONEY
            )} and generate ${formatMetric(
              summaryDetails.avg_unadjusted_other_traffic * numberOfWeeks,
              METRICTYPE.VOLUME
            )} (${formatMetric(
              summaryDetails.avg_unadjusted_other_traffic,
              METRICTYPE.VOLUME
            )} per week) in other traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;
          } else {
            // Regular retail price adjustment text
            return `Originally, this group of products was projected to have an average sell price of ${formatMetric(
              summaryDetails.avg_unadjusted_forecast,
              METRICTYPE.MONEY
            )} during the period of ${formattedStartDate} to ${formattedEndDate}.`;
          }

        default:
          return '';
      }
    }
    // RatingsReviews
    else if ('unadjusted_reviews' in summaryDetails) {
      return `Originally, this group of products was projected to generate ${formatMetric(
        summaryDetails.unadjusted_reviews * numberOfWeeks,
        METRICTYPE.VOLUME
      )} (${formatMetric(
        summaryDetails.unadjusted_reviews,
        METRICTYPE.VOLUME
      )} per week) reviews with an average star rating of ${formatMetric(
        summaryDetails.weighted_unadjusted_ratings,
        METRICTYPE.DECIMAL,
        { decimalPlaces: 2 }
      )} during the period of ${formattedStartDate} to ${formattedEndDate}.`;
    }
    // Paid Traffic
    else if ('avg_unadjusted_forecast_adspend' in summaryDetails) {
      return `Originally, this group of products was projected to spend ${formatMetric(
        summaryDetails.avg_unadjusted_forecast_adspend * numberOfWeeks,
        METRICTYPE.MONEY
      )} (${formatMetric(
        summaryDetails.avg_unadjusted_forecast_adspend,
        METRICTYPE.MONEY
      )} per week) on advertising and generate ${formatMetric(
        summaryDetails.individual_current_avg_paid_traffic * numberOfWeeks,
        METRICTYPE.VOLUME
      )} (${formatMetric(
        summaryDetails.individual_current_avg_paid_traffic,
        METRICTYPE.VOLUME
      )} per week) in paid traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;
    }
    return '';
  }, [formatMetric, formattedEndDate, formattedStartDate, numberOfWeeks, planType, summaryDetails]);

  return text;
};

export const useNewPlanDynamicText = ({ planType = null, summaryDetails }: UseDynamicTextProps): string => {
  const formatMetric = useMetricFormatter();
  const numberOfWeeks = summaryDetails
    ? calculateWeeksBetweenWeekIds(summaryDetails.min_weekId, summaryDetails.max_weekId) || 1
    : 1;

  const text = useMemo(() => {
    if (!summaryDetails) {
      return '';
    } else if ('avg_adjusted_forecast' in summaryDetails) {
      switch (planType) {
        case PlanTypeOption.OrganicTraffic:
          return `After applying your adjustments, this group of products is now projected to generate ${formatMetric(
            summaryDetails.avg_adjusted_forecast * numberOfWeeks,
            METRICTYPE.VOLUME
          )} (${formatMetric(
            summaryDetails.avg_adjusted_forecast,
            METRICTYPE.VOLUME
          )} per week) in organic traffic during the period.`;

        case PlanTypeOption.OtherTraffic:
          return `After applying your adjustments, this group of products is now projected to generate ${formatMetric(
            summaryDetails.avg_adjusted_forecast * numberOfWeeks,
            METRICTYPE.VOLUME
          )} (${formatMetric(
            summaryDetails.avg_adjusted_forecast,
            METRICTYPE.VOLUME
          )} per week) in other traffic during the period.`;

        case PlanTypeOption.BuyBox:
          return `After applying your adjustments, this group of products is now projected to have a ${formatMetric(
            summaryDetails.avg_adjusted_forecast / 100,
            METRICTYPE.PERCENT,
            { showFullValue: false }
          )} buy box rate during the period.`;

        case PlanTypeOption.ContentScore:
          return `After applying your adjustments, this group of products is now projected to have a ${formatMetric(
            summaryDetails.avg_adjusted_forecast / 100,
            METRICTYPE.PERCENT,
            { showFullValue: false }
          )} content score during the period.`;

        case PlanTypeOption.InStockRate:
          return `After applying your adjustments, this group of products is now projected to have a ${formatMetric(
            summaryDetails.avg_adjusted_forecast / 100,
            METRICTYPE.PERCENT,
            { showFullValue: false }
          )} in-stock rate during the period.`;

        case PlanTypeOption.RetailPrice:
          if ('avg_adjusted_other_traffic' in summaryDetails) {
            return `After applying your adjustments, this group of products is now projected to have an average sell price of ${formatMetric(
              summaryDetails.avg_adjusted_forecast,
              METRICTYPE.MONEY
            )} and generate ${formatMetric(
              summaryDetails.avg_adjusted_other_traffic * numberOfWeeks,
              METRICTYPE.VOLUME
            )} (${formatMetric(
              summaryDetails.avg_adjusted_other_traffic,
              METRICTYPE.VOLUME
            )} per week) in other traffic during the period.`;
          }

          return `After applying your adjustments, this group of products is now projected to have an average sell price of ${formatMetric(
            summaryDetails.avg_adjusted_forecast,
            METRICTYPE.MONEY
          )} during the period.`;

        default:
          return ``;
      }
    } // RatingsReviews
    else if ('adjusted_reviews' in summaryDetails) {
      return `After applying your adjustments, this group of products is now projected to generate ${formatMetric(
        summaryDetails.adjusted_reviews * numberOfWeeks,
        METRICTYPE.VOLUME
      )} (${formatMetric(
        summaryDetails.adjusted_reviews,
        METRICTYPE.VOLUME
      )} per week) reviews with an average star rating of ${formatMetric(
        summaryDetails.weighted_adjusted_ratings,
        METRICTYPE.DECIMAL,
        {
          decimalPlaces: 2
        }
      )} during the period.`;
    } else if ('avg_adjusted_forecast_adspend' in summaryDetails) {
      return `After applying your adjustments, this group of products is now projected to spend ${formatMetric(
        summaryDetails.avg_adjusted_forecast_adspend * numberOfWeeks,
        METRICTYPE.MONEY
      )} (${formatMetric(
        summaryDetails.avg_adjusted_forecast_adspend,
        METRICTYPE.MONEY
      )} per week) on advertising and generate ${formatMetric(
        summaryDetails.individual_new_avg_paid_traffic * numberOfWeeks,
        METRICTYPE.VOLUME
      )} (${formatMetric(
        summaryDetails.individual_new_avg_paid_traffic,
        METRICTYPE.VOLUME
      )} per week) in paid traffic during the period.`;
    }
    return '';
  }, [formatMetric, numberOfWeeks, planType, summaryDetails]);

  return text;
};

export interface UseNetImpactDynamicTextProps {
  planType: PlanTypeOption;
  computeDetails: ComputeAdditionalDetails;
}

export const useNetImpactDynamicText = ({ planType = null, computeDetails }: UseNetImpactDynamicTextProps): string => {
  const { totalNetImpact } = computeDetails || { totalNetImpact: 0 };
  const formatMetric = useMetricFormatter();
  const unitsSoldText = `${formatMetric(Math.abs(totalNetImpact), METRICTYPE.VOLUME)} units sold ${
    totalNetImpact >= 0 ? 'increase above' : 'decrease below'
  }`;

  const text = useMemo(() => {
    switch (planType) {
      case PlanTypeOption.OrganicTraffic:
        return `This revision to your organic traffic plan is projected to have a ${unitsSoldText} the previous sales forecast during the period.`;

      case PlanTypeOption.PaidTraffic:
        return `This revision to your paid traffic plan is projected to have a ${unitsSoldText} the previous sales forecast during the period.`;

      case PlanTypeOption.OtherTraffic:
        return `This revision to your other traffic plan is projected to have a ${unitsSoldText} the previous sales forecast during the period.`;

      case PlanTypeOption.BuyBox:
        return `This revision to your buy box rate is projected to have a ${unitsSoldText} the previous sales forecast during the period.`;

      case PlanTypeOption.ContentScore:
        return `This revision to your content score plan is projected to have a ${unitsSoldText} the previous sales forecast during the period.`;

      case PlanTypeOption.InStockRate:
        return `This revision to your in-stock rate plan is projected to have a ${unitsSoldText} the previous sales forecast during the period.`;

      case PlanTypeOption.RatingsReviews:
        return `This revision to your reviews plan is projected to have a ${unitsSoldText} the previous sales forecast.`;

      case PlanTypeOption.RetailPrice:
        return `This revision to your retail price is projected to have a ${unitsSoldText} the previous sales forecast during the period.`;

      default:
        return ``;
    }
  }, [planType, unitsSoldText]);

  return text;
};
