import { useCallback } from 'react';
import { useAppSelector } from 'src/utils/Hooks';
import { prefillFormData } from 'src/utils/segments';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import { useRemoveRetailPriceRangeFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';

/**
 * Takes in the filters for the search page and applies them to the
 * advanced search request
 */
export default function useKeywordSearchRequestBuilder(indexName: string) {
  const removeRetailPriceRangeFilters = useRemoveRetailPriceRangeFilters(indexName);
  const queryParams = useAppSelector((state) => state.app.queryParams);
  // Extract any filters from the URL that were added by the sidebar filters into a parsed "form"
  const formData = prefillFormData({ queryParams, appName: 'beacon' });
  const { termFilters, rangeFilters } = formData;
  /**
   * Collection of active term filters mapped from the form. These are ready for use in AS queries.
   */
  const activeTermFilters = Object.entries(termFilters)
    .filter(([_, filter]) => Array.isArray(filter.values) && filter.values.length > 0)
    .map(([fieldName, filter]) => ({
      fieldName,
      condition: filter.condition,
      values: filter.values.map(({ i }) => i)
    }));

  const activeRangeFilters = Object.entries(rangeFilters).map(([fieldName, filter]) => ({
    fieldName,
    minValue: filter.minValue,
    maxValue: filter.maxValue
  }));

  return useCallback(
    (additionalBuilder?: (builder: AdvancedSearchRequestBuilder) => AdvancedSearchRequestBuilder) =>
      (requestBuilder: AdvancedSearchRequestBuilder) => {
        activeTermFilters.forEach(({ fieldName, condition, values }) => {
          requestBuilder.addConditionTermFilter(fieldName, condition, values);
        });
        activeRangeFilters.forEach(({ fieldName, minValue, maxValue }) => {
          requestBuilder.addConditionRangeFilter(fieldName, minValue, maxValue);
        });

        // We don't want to see search results for the Other Traffic keyword
        requestBuilder
          .removeConditionTermFilters(
            (filter) => filter.fieldName === 'excludedSearchTerm' && filter.values.includes('[Other Traffic]')
          )
          .addConditionTermFilter('excludedSearchTerm', 'must_not', ['[Other Traffic]'])
          .apply(removeRetailPriceRangeFilters);

        if (additionalBuilder) {
          return additionalBuilder(requestBuilder);
        }
        return requestBuilder;
      },
    [activeRangeFilters, activeTermFilters, removeRetailPriceRangeFilters]
  );
}
