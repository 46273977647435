import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { useAsyncHook } from 'src/utils/Hooks';
import OmniContentColumn, { OmniContentColumnData } from 'src/components/Omni/OmniContentColumn/OmniContentColumn';
import { omniContentScoreAsync } from 'src/components/Omni/OmniContentColumn/OmniContentColumnWrapperUtil';
import { OmniBaseRequestBody, addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Widget } from 'src/types/application/widgetTypes';

interface OmniContentColumnWrapperProps extends RouteComponentProps {
  widget: Widget;
}
const OmniContentColumnWrapper: FC<OmniContentColumnWrapperProps> = ({ location }) => {
  const { data, loading, run } = useAsyncHook<OmniContentColumnData[]>([]);
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const filters = useSelector((state: ReduxStore) => state.filters);
  const defaultRetailers = useSelector((state: ReduxStore) => state.omniRetailers.data);

  const { startWeek, endWeek } = mainTimePeriod;
  const { pathname } = location;

  useEffect(() => {
    const baseRequestBody: OmniBaseRequestBody = {
      startWeekId: startWeek,
      endWeekId: endWeek,
      groupBy: 'retailerId',
      detailedScores: true
    };
    const requestBody = addFilterToOmniBaseReqBody(baseRequestBody, filters, pathname);
    run(omniContentScoreAsync(requestBody, defaultRetailers));
  }, [startWeek, endWeek, filters, pathname]);

  // When there is no data, we don't want to show export button.
  return <div>{loading ? <GenericChartLoading /> : data ? <OmniContentColumn data={data || []} /> : null}</div>;
};

export default withRouter(OmniContentColumnWrapper);
