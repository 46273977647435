import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import _get from 'lodash/get';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { ForecastPeriod, ForecastComparisonPeriod, ForecastType } from './types';
import { getStartAndEndOfWeek } from 'src/utils/dateUtils';
import {
  useForecastPeriod,
  useForecastComparisonPeriod,
  useExportStartAndEndWeekIds,
  useLatestForecastModel,
  useForecastStartAndEndWeekIds,
  useForecastType
} from './serverProxy/hooks';
import {
  FORECAST_COMPARISON_TO_LABEL_MAP,
  FORECAST_PERIOD_TO_LABEL_MAP
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/dropdowns';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import indefinite from 'indefinite';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import { ADJUSTMENT_TABLE_QUERY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastQueryKeys';
import { ForecastsAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import {
  AdjustmentPlanType,
  NetImpactPayload
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/utils';
import { AdvancedSearchQuery } from 'sl-api-connector/search';
import { ContentScoreFields } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/CreateAdjustmentModal';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { useApplyEntityFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { getAppStage } from 'src/utils/app';
import { formatTime } from 'src/utils/dateformatting';

export const mainEntityToForecastExportGroupByMap = {
  brand: 'brandId,weekId',
  subcategory: 'subCategoryId,weekId',
  category: 'categoryId,weekId',
  client: 'stacklineSku,weekId'
};

interface UseForecastInsightTextArgs {
  fieldName: string;
  fieldTitle: string;
  formattedTotal: string;
  changePercent: number;
}

/**
 * These metrics wil say "... forecasted to have ..." instead of "... forecasted to generate ..."
 */
const INSIGHT_RATE_METRICS = ['purchaseRate', 'retailPrice', 'contentScore', 'instockRate', 'stars', 'winPercentage'];

/**
 * Unique field titles for certain
 * metrics in the insight text.
 */
const FIELD_TITLE_OVERRIDES = {
  purchaseRate: 'conversion rate',
  retailPrice: 'average retail price',
  contentScore: 'average content score',
  instockRate: 'in-stock rate',
  stars: 'average star rating',
  winPercentage: 'buy box rate',
  brandGrossMarginPercent: 'brand margin'
};

/**
 * Get the insight text for a given forecast metric.
 */
export const useBuildForecastInsightText = ({
  fieldName,
  fieldTitle,
  formattedTotal,
  changePercent
}: UseForecastInsightTextArgs) => {
  const formatMetric = useMetricFormatter();
  const forecastPeriod: ForecastPeriod = useForecastPeriod();
  const { startWeekId, endWeekId } = useForecastStartAndEndWeekIds();
  const { startDate: startOfForecastWeek } = getStartAndEndOfWeek(startWeekId);
  const { endDate: endOfForecastWeek } = getStartAndEndOfWeek(endWeekId);
  const forecastComparisonPeriod: ForecastComparisonPeriod = useForecastComparisonPeriod();
  const entityTitle = useAppSelector((state) => {
    const entity = state.entityService.mainEntity;
    return ['client', 'brand'].includes(entity.type) ? entity.name : `This ${entity.type}`;
  });

  const dynamicTimePeriodCopy = useMemo(() => {
    if (FORECAST_PERIOD_TO_LABEL_MAP[forecastPeriod] && forecastPeriod !== ForecastPeriod.CUSTOM) {
      const formattedPeriod = FORECAST_PERIOD_TO_LABEL_MAP[forecastPeriod].toLowerCase();

      return `during the ${formattedPeriod} period`;
    } else {
      return `from ${formatTime(startOfForecastWeek, 'MMM D, YYYY')} to ${formatTime(
        endOfForecastWeek,
        'MMM D, YYYY'
      )}`;
    }
  }, [forecastPeriod, endOfForecastWeek, startOfForecastWeek]);
  const formattedComparisonPeriod = useMemo(() => {
    return FORECAST_COMPARISON_TO_LABEL_MAP[forecastComparisonPeriod].toLowerCase();
  }, [forecastComparisonPeriod]);

  const formattedPercentage = useMemo(() => {
    return formatMetric(Math.abs(changePercent), METRICTYPE.PERCENT, { decimalPlaces: 2 });
  }, [changePercent, formatMetric]);

  const formattedFieldTitle = useMemo(() => {
    return FIELD_TITLE_OVERRIDES[fieldName] || fieldTitle.toLowerCase();
  }, [fieldTitle, fieldName]);

  const insightText = useMemo(() => {
    if (INSIGHT_RATE_METRICS.includes(fieldName)) {
      return `${entityTitle} is forecasted to have ${indefinite(
        formattedFieldTitle
      )} of ${formattedTotal} ${dynamicTimePeriodCopy}, which represents a ${formattedPercentage} ${
        changePercent >= 0 ? 'increase' : 'decline'
      } compared to the ${formattedComparisonPeriod}.`;
    } else if (fieldName === 'winPercentage') {
      return `${entityTitle} is forecasted to have a ${formattedTotal} ${formattedFieldTitle} ${dynamicTimePeriodCopy}, which represents a ${formattedPercentage} ${
        changePercent >= 0 ? 'increase' : 'decline'
      } compared to the ${formattedComparisonPeriod}.`;
    } else if (fieldName === 'unitsSold') {
      return `${entityTitle} is forecasted to sell ${formattedTotal} units ${dynamicTimePeriodCopy}, which represents a ${formattedPercentage} ${
        changePercent >= 0 ? 'increase' : 'decline'
      } compared to the ${formattedComparisonPeriod}.`;
    } else {
      return `${entityTitle} is forecasted to generate ${formattedTotal} in ${formattedFieldTitle} ${dynamicTimePeriodCopy}, which represents a ${formattedPercentage} ${
        changePercent >= 0 ? 'increase' : 'decline'
      } compared to the ${formattedComparisonPeriod}.`;
    }
  }, [
    changePercent,
    entityTitle,
    fieldName,
    formattedComparisonPeriod,
    formattedFieldTitle,
    formattedPercentage,
    formattedTotal,
    dynamicTimePeriodCopy
  ]);

  return insightText;
};

interface UseAdjustmentPageNetImpactDataParams {
  advancedSearchRequest: AdvancedSearchQuery;
  adjustmentPageTableData: AxiosResponse<unknown>;
  isForecastModelVersionLoading: boolean;
}
export const useAdjustmentPageNetImpactData = ({
  advancedSearchRequest,
  adjustmentPageTableData,
  isForecastModelVersionLoading
}: UseAdjustmentPageNetImpactDataParams) => {
  const { data, isLoading } = useGenericAdvancedSearch({
    requestId: `published-netImpactData`,
    staleTime: 0,
    requestBody: [advancedSearchRequest],
    queryKeys: [ADJUSTMENT_TABLE_QUERY, advancedSearchRequest],
    // Need to wait fetching adjustmentTableData and setting the adjustmentIds state and getting forecastModelVersion  before fetching netImpactData
    shouldPerformFetch: !!adjustmentPageTableData && !isForecastModelVersionLoading
  });

  // Filter adjustment table data to pull any adjustments in draft status.
  // Reduce drafted adjustments into a colleciton of Net Impact payloads
  const adjustmentDocuments = _get(adjustmentPageTableData, ['data', '0', 'documents'], []);

  const draftedNetImpactPayloads: NetImpactPayload[] = adjustmentDocuments
    .filter(({ status }) => status === 'Draft')
    .map((adjustmentDatum: ForecastsAdjustmentData) => {
      const {
        adjustmentId,
        beaconClientId,
        retailerId,
        retailerSku,
        createdByUserId,
        planType,
        startWeekId,
        endWeekId,
        adjustmentChangeValue,
        stacklineSku,
        product,
        reviewAvgRating,
        reviewCount
      } = adjustmentDatum;
      // Get category and subcategory ID
      const { categoryId, subCategoryId } = product;

      const additionalProperties = [AdjustmentPlanType.ContentScore].includes(planType)
        ? {
            titleScore: adjustmentDatum[ContentScoreFields.TitleScore],
            bulletScore: adjustmentDatum[ContentScoreFields.BulletScore],
            imageScore: adjustmentDatum[ContentScoreFields.ImageScore],
            videoScore: adjustmentDatum[ContentScoreFields.VideoScore],
            aplusScore: adjustmentDatum[ContentScoreFields.A_Score]
          }
        : [AdjustmentPlanType.RatingsReviews].includes(planType)
        ? {
            reviewCount: reviewCount || 0,
            reviewAvgRating: reviewAvgRating || 0
          }
        : {
            reviewCount: 0,
            reviewAvgRating: 0
          };

      return {
        baseAdjustmentRequest: {
          adjustmentId,
          beaconClientId,
          retailerId,
          retailerSku,
          userId: createdByUserId,
          planType,
          startWeekId,
          endWeekId,
          ignoreConflictWithAdjustmentIds: [adjustmentId]
        },
        additionalProperties,
        adjustmentChangeType: 'Absolute',
        adjustmentChangeValue,
        stacklineSku,
        categoryId,
        subCategoryId,
        requestType: 'Create',
        shouldPublish: false
      };
    });

  const { data: draftData, isLoading: draftIsLoading } = useGenericAdvancedSearch({
    requestId: `GetNetImpact`,
    customEndpoint: 'api/beaconforecast/GetNetImpact',
    staleTime: 0,
    requestBody: draftedNetImpactPayloads,
    queryKeys: [ADJUSTMENT_TABLE_QUERY, draftedNetImpactPayloads],
    shouldPerformFetch: draftedNetImpactPayloads.length > 0
  });

  return {
    publishedNetImpactResponse: data,
    draftedNetImpactResponse: draftData,
    isLoading: draftIsLoading && isLoading
  };
};

export const useUnadjustedForecastExportRequest = ({
  groupByFieldName,
  includeCompTimePeriodForExport
}: {
  groupByFieldName: string;
  includeCompTimePeriodForExport: boolean;
}) => {
  const retailerId = useAppSelector((state) => state.retailer.id);
  const convertedForecastGroupBy = groupByFieldName.replace('weekId', 'forecastWeekId');
  const forecastType = useForecastType();
  const forecastPeriod: ForecastPeriod = useForecastPeriod();
  const { currentPublishVersion, modelVersion } = useLatestForecastModel();
  const { forecastEndWeekId, forecastStartWeekId } = useExportStartAndEndWeekIds();
  const applyEntityFilters = useApplyEntityFilters();

  const aggregations = [
    {
      aggregateByFieldDisplayName: 'Total Traffic',
      aggregateByFieldName: 'totalTraffic',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Organic Traffic',
      aggregateByFieldName: 'organicTraffic',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Other Traffic',
      aggregateByFieldName: 'otherTraffic',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Paid Traffic',
      aggregateByFieldName: 'paidTrafficValue',
      func: 'sum',
      canBeExported: true
    },
    { aggregateByFieldDisplayName: 'Ad Spend', aggregateByFieldName: 'adSpend', func: 'sum', canBeExported: true },
    { aggregateByFieldDisplayName: 'Ad Sales', aggregateByFieldName: 'adSales', func: 'sum', canBeExported: true },
    {
      aggregateByFieldDisplayName: 'Weighted Content Score By Content Count',
      aggregateByFieldName: 'weightedContentScoreByContentScoreCount',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Weighted Content Score by Units',
      aggregateByFieldName: 'weightedContentScoreByUnits',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Units Sold For Content Score',
      aggregateByFieldName: 'unitsSoldForContentScore',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Content Score Count',
      aggregateByFieldName: 'contentScoreCount',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Weighted Star Rating By Number of Reviews',
      aggregateByFieldName: 'weightedRatingByRatingCount',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Weighted Buy Box Wins By Units',
      aggregateByFieldName: 'weightedBuyBoxWinRateByUnits',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Weighted In-Stock Rate By Units',
      aggregateByFieldName: 'weightedInStockRateByUnits',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Number of Cumulative Reviews',
      aggregateByFieldName: 'ratingCount',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Number of New Reviews',
      aggregateByFieldName: 'incrementalReviewsFinal',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Retail Sales',
      aggregateByFieldName: 'retailSales',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Units Sold',
      aggregateByFieldName: 'unitsSold',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Wholesale Sales',
      aggregateByFieldName: 'wholesaleSales',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Retailer Margin',
      aggregateByFieldName: 'retailerMargin',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Brand Margin',
      aggregateByFieldName: 'brandMargin',
      func: 'sum',
      canBeExported: true
    }
  ];
  const unAdjustedRequestBuilder = new AdvancedSearchRequestBuilder(
    'unadjustedForecastByweekId_weekId-client',
    'beacon-unadjusted-forecast'
  );

  const unadjustedAggregationBuilder = new AggregationBuilder(convertedForecastGroupBy);

  aggregations.forEach(({ aggregateByFieldDisplayName, aggregateByFieldName, func, canBeExported }) => {
    unadjustedAggregationBuilder.addAggregationField(
      aggregateByFieldDisplayName,
      aggregateByFieldName,
      func,
      canBeExported
    );
  });

  unadjustedAggregationBuilder.addConditionTermFilter('retailerId', [retailerId]);
  unadjustedAggregationBuilder.addConditionTermFilterWithCondition('publishVersion', 'must', [currentPublishVersion]);
  unadjustedAggregationBuilder.addConditionRangeFilter('forecastWeekId', forecastStartWeekId, forecastEndWeekId);
  unadjustedAggregationBuilder.setSortByAggregationField('Retail Sales', 'retailSales', 'sum', true);
  unadjustedAggregationBuilder.setSortDirection('desc');

  // If ForecastType is Adjusted, we should not include this into the request
  if (forecastType === ForecastType.UNADJUSTED) {
    unAdjustedRequestBuilder.setCustomAdditionalMetadata({
      toCompare: includeCompTimePeriodForExport,
      forecastSelection: forecastPeriod === ForecastPeriod.FULL_YEAR ? 'Full Year' : forecastPeriod
    });
  }

  unAdjustedRequestBuilder
    .setPageNumber(1)
    .setPageSize(1200)
    .addConditionRangeFilter('forecastWeekId', forecastStartWeekId, forecastEndWeekId)
    .addConditionTermFilter('retailerId', 'should', [retailerId])
    .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
    .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
    .addSortField('Retail Sales', 'retailSales', 'sum', true)
    .setPeriod('year')
    .setRetailerId(retailerId)
    .setDoAggregation(true)
    .setReturnDocuments(false)
    .setSearchBy('parent')
    .apply(applyEntityFilters)
    .addAggregation(unadjustedAggregationBuilder.build());

  const unadjustedForecastsRequest = unAdjustedRequestBuilder.build();
  return unadjustedForecastsRequest;
};

export const useAdjustedForecastExportRequest = ({
  groupByFieldName,
  includeCompTimePeriodForExport
}: {
  groupByFieldName: string;
  includeCompTimePeriodForExport: boolean;
}) => {
  const { forecastEndWeekId, forecastStartWeekId } = useExportStartAndEndWeekIds();
  const retailerId = useAppSelector((state) => state.retailer.id);
  const forecastPeriod: ForecastPeriod = useForecastPeriod();
  const convertedForecastGroupBy = groupByFieldName.replace('weekId', 'forecastWeekId');
  const { currentPublishVersion, modelVersion } = useLatestForecastModel();
  const appStage = getAppStage();

  const applyEntityFilters = useApplyEntityFilters();
  const aggregations = [
    {
      aggregateByFieldDisplayName: 'Total Traffic Delta',
      aggregateByFieldName: 'totalTrafficDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Organic Traffic Delta',
      aggregateByFieldName: 'organicTrafficDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Other Traffic Delta',
      aggregateByFieldName: 'otherTrafficDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Paid Traffic Delta',
      aggregateByFieldName: 'paidTrafficDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Ad Spend Delta',
      aggregateByFieldName: 'adSpendDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Ad Sales Delta',
      aggregateByFieldName: 'adSalesDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Units Sold Delta',
      aggregateByFieldName: 'unitsSoldDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Retail Sales Delta',
      aggregateByFieldName: 'retailSalesDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Weighted Content Score By Content Count Delta',
      aggregateByFieldName: 'weightedContentScoreByContentScoreCountDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Weighted Content Score by Units Delta',
      aggregateByFieldName: 'weightedContentScoreByUnitsDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Units Sold For Content Score Delta',
      aggregateByFieldName: 'unitsSoldForContentScoreDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Content Score Count Delta',
      aggregateByFieldName: 'contentScoreDelta',
      func: 'avg',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Weighted Star Rating By Number of Reviews Delta',
      aggregateByFieldName: 'weightedRatingByRatingCountDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Weighted Buy Box Wins By Units Delta',
      aggregateByFieldName: 'weightedBuyBoxWinRateByUnitsDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Weighted In-Stock Rate By Units Delta',
      aggregateByFieldName: 'weightedInStockRateByUnitsDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Number of New Reviews Delta',
      aggregateByFieldName: 'ratingCountDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Wholesale Sales Delta',
      aggregateByFieldName: 'wholesaleSalesDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Retailer Margin Delta',
      aggregateByFieldName: 'retailerMarginDelta',
      func: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Brand Margin Delta',
      aggregateByFieldName: 'brandMarginDelta',
      func: 'sum',
      canBeExported: true
    }
  ];
  const adjustedRequestBuilder = new AdvancedSearchRequestBuilder(
    'adjustedForecastByweekId_weekId-client',
    'beacon-adjusted-forecast'
  );

  const adjustedAggregationBuilder = new AggregationBuilder(convertedForecastGroupBy);

  aggregations.forEach(({ aggregateByFieldDisplayName, aggregateByFieldName, func, canBeExported }) => {
    adjustedAggregationBuilder.addAggregationField(
      aggregateByFieldDisplayName,
      aggregateByFieldName,
      func,
      canBeExported
    );
  });

  adjustedAggregationBuilder.addConditionTermFilter('retailerId', [retailerId]);
  adjustedAggregationBuilder.addConditionRangeFilter('forecastWeekId', forecastStartWeekId, forecastEndWeekId);
  adjustedAggregationBuilder.addConditionTermFilterWithCondition('publishVersion', 'must', [currentPublishVersion]);
  adjustedAggregationBuilder.addConditionTermFilterWithCondition('appStage', 'must', [appStage]);
  adjustedAggregationBuilder.setSortByAggregationField('Retail Sales Delta', 'retailSalesDelta', 'sum', true);
  adjustedAggregationBuilder.setSortDirection('desc');

  adjustedRequestBuilder
    .setCustomAdditionalMetadata({
      toCompare: includeCompTimePeriodForExport,
      forecastSelection: forecastPeriod === ForecastPeriod.FULL_YEAR ? 'Full Year' : forecastPeriod
    })
    .setPageNumber(1)
    .setPageSize(1200)
    .addConditionRangeFilter('forecastWeekId', forecastStartWeekId, forecastEndWeekId)
    .addConditionTermFilter('retailerId', 'should', [retailerId])
    .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
    .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
    .addConditionTermFilter('appStage', 'must', [appStage])
    .addSortField('Retail Sales Delta', 'retailSalesDelta', 'sum', true)
    .setPeriod('year')
    .setRetailerId(retailerId)
    .setDoAggregation(true)
    .setReturnDocuments(false)
    .setSearchBy('parent')
    .apply(applyEntityFilters)
    .addAggregation(adjustedAggregationBuilder.build());

  const adjustedForecastsRequest = adjustedRequestBuilder.build();
  return adjustedForecastsRequest;
};
