import colors from 'src/utils/colors';
import moment from 'moment';

export const disabledWeekly = (wr, start, end) => {
  switch (wr) {
    case 'ytd':
    case 'mtd':
      return true;
    case 'cd':
      // Start day = Sunday(0) and end day = Saturday(6)
      // Enable selection
      if (moment(start).day() === 0 && moment(end).day() === 6) {
        return false;
      } else {
        return true;
      }
    default:
      return false;
  }
};

export const chartColorsLineChart = [colors.stacklineBlue, colors.comparison, '#b1c7eb'];
export const chartColorsColumnChart = [colors.comparison, colors.stacklineBlue];

export const getBaseLineChartSeries = (chartDisplayTimePeriod, metricsDataByWeekId) => [
  {
    name: chartDisplayTimePeriod.displayName,
    metricType: metricsDataByWeekId.metricType,
    data: [],
    color: chartColorsLineChart[0],
    marker: {
      lineColor: chartColorsLineChart[0],
      fillColor: chartColorsLineChart[0],
      lineWidth: 3,
      symbol: 'circle'
    }
  }
];

export const computeLastWeek = (currentWeek) => {
  const year = Math.floor(currentWeek / 100);
  const week = currentWeek % 100;

  if (week === 1) {

    if (moment([year - 1]).isLeapYear()) {
      return currentWeek - 48;
    }

    return currentWeek - 49;
  }

  return currentWeek - 1;
};

export const undoComputeLastWeek = (currentWeek) => {
  const week = currentWeek % 100;
  if (week === 52) {
    return currentWeek + 49;
  }

  if (week === 53) {
    return currentWeek + 48;
  }

  return currentWeek + 1;
};
