import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import colors from 'src/utils/colors';
import useAdGetMediaData from 'src/components/Layout/Advertising/AdMediaManagementLayout/hooks/useAdGetMedia';
import AdVideoPreview from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdVideoPreview';

const useStyles = makeStyles({
  sectionContainer: {
    marginBottom: 100
  },
  section: {
    paddingBottom: 64,
    fontSize: 16
  },
  sectionTitle: {
    fontSize: 28,
    marginBottom: 8
  },
  sectionColumnContainer: {
    display: 'grid',
    gridTemplateColumns: '1.2fr 10fr',
    gap: '1em',
    margin: '10px 0',
    '& div:first-child': {
      fontWeight: 'bold'
    }
  },
  reviewStatus: {
    textTransform: 'capitalize'
  },
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.lightestGrey}`,
    paddingBottom: 20
  },
  formField: {
    padding: '15px 0'
  },
  formFieldLabel: {
    fontSize: 20,
    margin: '20px 0'
  },
  formFieldError: {
    color: colors.red
  },
  submitButtonRoot: {
    background: colors.stacklineBlue,
    height: 40,
    padding: '0px 27px',
    '&:hover': {
      backgroundColor: colors.stacklineBlue
    }
  },
  submitButtonLabel: {
    color: colors.lightestGrey,
    lineHeight: 1.7,
    textTransform: 'uppercase'
  },
  disabledButtonRoot: {
    backgroundColor: colors.lightestGrey,
    '& span': {
      color: colors.lightGrey
    }
  },
  uploadLogoWrapper: {
    margin: '50px 0 0'
  }
});

interface Review {
  reviewId: number | string;
  reviewStatus: string;
  reviewReason: string;
}

const FieldWapper = ({ title, children }: { title: string; children: any }) => {
  const classes = useStyles();
  return (
    <div className={classes.formField}>
      <h3 className={classes.formFieldLabel}>{title}</h3>
      {children}
    </div>
  );
};

FieldWapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const queryConfig = {
  retry: 3,
  staleTime: 60000 * 60, // 2 min
  cacheTime: 60000 * 60,
  refetchOnWindowFocus: false,
  refetchOnMount: false
};

const ReviewSection = ({ title, review }: { title: string; review: Review }) => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <div className={classes.sectionTitle}>{title}</div>
      <div>
        Please make appropriate changes by switching to <strong>{title}</strong> tab.
      </div>
      <div className={classes.sectionColumnContainer}>
        <div>Status: </div>
        <div className={classes.reviewStatus}>{review.reviewStatus}</div>
      </div>
      {review.reviewReason && (
        <div className={classes.sectionColumnContainer}>
          <div>Reason: </div>
          <div>{review.reviewReason}</div>
        </div>
      )}
    </div>
  );
};

type ParsedData = string | string[];

function parseData(data: string): ParsedData {
  let result: any;
  try {
    // Try to parse the string as JSON
    result = JSON.parse(data);
  } catch (err) {
    // If parsing fails, return the original string
    return data;
  }

  // If parsed data is an array, map each item to a string
  // Otherwise, convert the parsed data to a string
  if (Array.isArray(result)) {
    return result.map(String);
  } else {
    return String(result);
  }
}

const ReviewsPageWalmart = ({ entity }: { entity: any }) => {
  const classes = useStyles();

  const reviews = _get(entity, 'extendedAttributes.amsApiModel.reviews', []);
  const adGroupMedia = _get(entity, 'extendedAttributes.amsApiModel.adGroupMedia', {});
  const { mediaId } = _get(entity, 'extendedAttributes.amsApiModel.adGroupMedia', {});

  const beaconClientLoginId = _get(entity, 'extendedAttributes.beaconClientLoginId', null);

  const { data } = useAdGetMediaData({ mediaId, qryConfig: queryConfig, beaconClientLoginId });

  reviews.sort((a: any, b: any) => (_get(a, 'reviewId') > _get(b, 'reviewId') ? -1 : 1));

  let reviewStatus = 'Complete';
  let reviewId = '';
  let keywordReviewReason = '';
  let contentReviewReason = '';

  if (_get(entity, 'extendedAttributes.amsApiModel.state') === 'proposal' && reviews.length === 0) {
    // pending
    reviewStatus = 'Pending';
  } else if (reviews.length > 0) {
    const review = reviews[0];
    reviewId = _get(review, 'reviewId', '');

    const reviewComments = _get(review, 'reviewComments', []);
    if (reviewComments.length > 0) {
      reviewStatus = _get(review, 'reviewStatus', '');
      reviewComments.forEach((comment: any) => {
        const reviewReason = _get(comment, 'comments', '');
        if (_get(comment, 'commentType') === 'keyword') {
          keywordReviewReason = reviewReason;
        } else if (_get(comment, 'commentType') === 'content') {
          contentReviewReason = reviewReason;
        }
      });
    }
  }

  const keywordReview = {
    reviewId,
    reviewStatus,
    reviewReason: keywordReviewReason
  };

  const contentReview = {
    reviewId,
    reviewStatus,
    reviewReason: contentReviewReason
  };

  return (
    <div className={classes.sectionContainer}>
      {!_isEmpty(keywordReview) && <ReviewSection title="Targets" review={keywordReview} />}
      {!_isEmpty(contentReview) && <ReviewSection title="Products" review={contentReview} />}
      {data && data.length > 0 && data[0] && data[0].videoUrl && (
        <div className={classes.section}>
          <div className={classes.sectionTitle}>Media Profile</div>
          <div className={classes.sectionColumnContainer}>
            <div>
              <strong>Media Name:</strong>
            </div>
            <div className={classes.reviewStatus}>{data[0].stackLineMediaDetails.mediaName}</div>
            {data[0].stackLineMediaDetails.mediaDescription && (
              <>
                <div>
                  <strong>Media Description:</strong>
                </div>
                <div className={classes.reviewStatus}>{data[0].stackLineMediaDetails.mediaDescription}</div>
              </>
            )}
            {adGroupMedia.reviewStatus === 'rejected' && (
              <>
                <div>
                  <strong>Reason:</strong>
                </div>
                <div className={classes.reviewStatus}>{parseData(adGroupMedia.reviewReason)}</div>
              </>
            )}
          </div>
          <div style={{ marginTop: 30 }}>
            <AdVideoPreview poster={data[0].thumbnailUrl} videoUrl={data[0].videoUrl} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewsPageWalmart;
