import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

import colors from 'src/utils/colors';
import './Loading.scss';

const Loading = ({ className, size, thickness, style, color }) => (
  <div style={style} className={`${className} spinner-wrapper`}>
    <CircularProgress
      thickness={thickness}
      size={size}
      className="spinner-icon"
      style={{ verticalAlign: 'middle', color }}
    />
  </div>
);

Loading.propTypes = {
  style: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.number,
  thickness: PropTypes.number,
  color: PropTypes.string
};

Loading.defaultProps = {
  style: {},
  className: '',
  size: 50,
  thickness: 2,
  color: colors.blue
};

export default Loading;
