import { useEffect, useState, useCallback, useRef, useMemo, useContext } from 'react';
import axios from 'axios';
import _get from 'lodash/get';
import ReduxStore from 'src/types/store/reduxStore';
import { TypedUseSelectorHook, useSelector, useDispatch, useStore } from 'react-redux';
import convertMetricToDisplayValue, { ConvertMetricToDisplayValueOptions } from 'src/components/EntityGrid/gridUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { ValueOf } from 'sl-api-connector/types';
import { TimePeriod } from 'src/types/store/storeTypes';
import { updateMainTimePeriod as updateReduxMainTimePeriod } from 'src/store/modules/main-time-period/operations';
import { computeMainTimePeriod, getDayIdFromDate } from 'src/utils/dateformatting';
import { History } from 'history';
import { getWeekId } from 'src/utils/dateUtils';
import moment from 'moment';
import { useUpdateQueryParams } from 'src/utils/Hooks/useUpdateQueryParams';
import { RouterPropsContext } from 'src/providers/RouterPropsProvider';
import { SnackbarContext } from 'src/providers/SnackbarProvider';
import { getUserProfileImageUrl } from 'src/utils/image';
import { PopupContext } from 'src/providers/PopupProvider';
import { UserProfileImageContext } from 'src/providers/UserProfileImageProvider';
import { buildSubtitleDisplayName } from 'src/utils/filters';

/**
 * Takes in an async function and manages state associated with it.  Whenever the state of the underlying promise
 * changes, the returned variables will also be automatically updated and the surrounding component re-rendered.
 *
 * Taken from: https://usehooks.com/useAsync/
 *
 * @param asyncFunction
 * @param immediate If set to `false`, the generated wrapped promise `execute` won't be called and it will be up
 *  to you to call `execute` in order to call the provided `asyncFunction`.
 */
export const useAsync = <T>(asyncFunction: () => Promise<T>, immediate = true) => {
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<any>(null);

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(async () => {
    try {
      setPending(true);
      setValue(null);
      setError(null);
      const response = await asyncFunction();
      setValue(response);
    } catch (err) {
      setError(err);
    } finally {
      setPending(false);
    }
  }, [asyncFunction]);

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, pending, value, error };
};

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const usePagination = (
  endPoint: string,
  baseRequestBody: { [key: string]: any },
  accessData: string,
  getPageNumber: string,
  getPageSize: string,
  processData: (data: any[]) => any[]
) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [requestBody, setRequestBody] = useState({ ...baseRequestBody });

  const handleRequestChange = (
    newReqContent: { [key: string]: any },
    pageInfo: { pageNumber: number; pageSize: number }
  ) => {
    setRequestBody({ ...requestBody, ...newReqContent, ...pageInfo });
  };

  const fetchData = useCallback(() => {
    setIsLoading(true);
    axios
      .post(endPoint, requestBody)
      .then((res) => {
        const rawData = _get(res, accessData, []);
        const actPageSize = _get(res, getPageSize, 20);
        const actPageNumber = _get(res, getPageNumber, 1);
        if (actPageNumber !== pageNumber) {
          setPageNumber(actPageNumber);
        }
        if (actPageSize !== pageSize) {
          setPageSize(actPageSize);
        }
        setIsLoading(false);
        const data = processData(rawData);
        if (requestBody.pageNumber === 1) {
          setDataList(data);
        } else {
          setDataList([...dataList, ...data]);
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  }, [requestBody]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [pageNumber, pageSize, handleRequestChange, isLoading, dataList];
};

// export default function useMultiKeyPress() {
//   const [keysPressed, setKeyPressed] = useState(new Set<string>([]));

//   function downHandler({ key }: { key: string }) {
//     const deepClonedMap = new Set<string>(JSON.parse(JSON.stringify([...keysPressed])));
//     deepClonedMap.add(key);
//     setKeyPressed(deepClonedMap);
//   }

//   const upHandler = ({ key }: { key: string }) => {
//     keysPressed.delete(key);
//     setKeyPressed(keysPressed);
//   };

//   useEffect(() => {
//     window.addEventListener('keydown', downHandler);
//     window.addEventListener('keyup', upHandler);
//     return () => {
//       window.removeEventListener('keydown', downHandler);
//       window.removeEventListener('keyup', upHandler);
//     };
//   }, []); // Empty array ensures that effect is only run on mount and unmount

//   return keysPressed;
// }

export const useAsyncHook = <T>(defaultData: T) => {
  interface StateType {
    data: T | null;
    error: any;
    loading: boolean;
  }

  const [dataState, setDataState] = useState<StateType>({
    data: defaultData || null,
    error: null,
    loading: false
  });
  const run = async (asyncFun: () => Promise<T>) => {
    try {
      setDataState({ data: null, error: null, loading: true });
      const response = await asyncFun();
      const result = { data: response, error: null, loading: false };
      setDataState(result);
      return result;
    } catch (error) {
      const result = { data: null, error, loading: false };
      setDataState(result);
      return result;
    }
  };
  return {
    ...dataState,
    run
  };
};

/**
 * Typed useSelector hook that is annotated with the type of
 * our Redux store
 */
export const useAppSelector: TypedUseSelectorHook<ReduxStore> = useSelector;

export interface UseMetricFormatterOptions extends ConvertMetricToDisplayValueOptions {
  showFullValue?: boolean;
}

export type MetricFormatterFn = (
  value: number,
  metricType: ValueOf<typeof METRICTYPE>,
  options?: UseMetricFormatterOptions
) => string | number;
/**
 * Wrapper around convertMetricToDisplayValue to make formatting
 * metrics easier without having to pass so many arguments
 */
export const useMetricFormatter = () => {
  const retailer = useAppSelector((state) => state.retailer);

  const format: MetricFormatterFn = useCallback(
    (value, metricType, options = {}) => {
      return convertMetricToDisplayValue(
        retailer,
        value,
        metricType,
        retailer.currencySymbol,
        typeof options.showFullValue !== 'undefined' ? options.showFullValue : true,
        options
      );
    },
    [retailer]
  );

  return format;
};

/**
 * Updates the available time periods in Redux. This is for custom
 * main time period dropdown options so that the time period is
 * properly updated when something is selected.
 */
export const useUpdateMainTimePeriod = (history: History, timePeriods: Pick<TimePeriod, 'id'>[]) => {
  const dispatch = useDispatch();
  const allWeekIdsByRetailerId = useAppSelector((state) => state.allWeekIdsByRetailerId);
  const retailerId = useAppSelector((state) => state.retailer.id);
  const updateQuery = useUpdateQueryParams(history);
  const store = useStore<ReduxStore>();

  const createTimePeriodFromCustomDates = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const startWeekId = getWeekId(startDate);
    const endWeekId = getWeekId(endDate);

    return {
      shortDisplayName: null,
      startWeek: startWeekId,
      endWeek: endWeekId,
      startDayId: getDayIdFromDate(startDate),
      endDayId: getDayIdFromDate(endDate),
      startWeekStartDate: moment(startDate).toDate(),
      endWeekEndDate: moment(endDate).toDate()
    };
  };

  return useCallback(
    (newTimePeriodId: string, customDates?: { startDate: string; endDate: string }) => {
      // Updating query params updates `app`, so if we use the app selector
      // at the top level then it will enter infinite render loop.
      const { app } = store.getState();
      const mainTimePeriodSelectedOption =
        timePeriods.find((period) => period.id === newTimePeriodId) || timePeriods[0];
      const newTimePeriod = customDates
        ? createTimePeriodFromCustomDates(customDates)
        : computeMainTimePeriod(allWeekIdsByRetailerId[+retailerId], mainTimePeriodSelectedOption, app);
      const { shortDisplayName, endWeek, startWeek, startDayId, endDayId, startWeekStartDate, endWeekEndDate } =
        newTimePeriod;

      dispatch(
        updateReduxMainTimePeriod(
          startWeek,
          endWeek,
          newTimePeriodId,
          shortDisplayName,
          timePeriods,
          startWeekStartDate,
          startDayId,
          endWeekEndDate,
          endDayId
        )
      );

      updateQuery({
        updatedMainPeriod: newTimePeriod,
        additionalParams: {
          wr: newTimePeriodId
        }
      });
    },
    [allWeekIdsByRetailerId, dispatch, retailerId, timePeriods, updateQuery, store]
  );
};

export const useIsScheduledActionsPage = (search: string): boolean => {
  return useMemo(() => new URLSearchParams(search).get('tab') === 'adScheduledActions', [search]);
};

/**
 * Returns react router history object. Throws an error if used outside of a RouterPropsProvider.
 */
export const useHistory = () => {
  const context = useContext(RouterPropsContext);
  if (!context) {
    throw new Error('useHistory must be used within a RouterPropsProvider');
  }
  return context.history;
};

/**
 * Returns react router location object. Throws an error if used outside of a RouterPropsProvider.
 */
export const useLocation = () => {
  const context = useContext(RouterPropsContext);
  if (!context) {
    throw new Error('useLocation must be used within a RouterPropsProvider');
  }
  return context.location;
};

/**
 * Gets the value of a query param from the URL. If the param is not present, returns the default value.
 * An optional validator callback function can be given that can be used to validate the returned
 * value. If the validator returns false, the default value will be returned.
 */
export const useQueryParamValue = (
  param: string,
  defaultValue?: string,
  validator?: (originalParam: string) => boolean
) => {
  const location = useLocation();
  const params = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      [param]: searchParams.get(param) || defaultValue
    };
  }, [location.search, param, defaultValue]);

  return useMemo(() => {
    if (validator && !validator(params[param])) {
      return defaultValue;
    }
    return params[param];
  }, [defaultValue, param, params, validator]);
};

/**
 * Update any query params in the URL. This will merge the new params with the existing ones.
 */
export const useUpdateSearchParams = () => {
  const location = useLocation();
  const history = useHistory();

  return useCallback(
    (params: { [key: string]: string | string[] }) => {
      const searchParams = new URLSearchParams(location.search);
      Object.entries(params).forEach(([key, value]) => {
        if (typeof value === 'string') {
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
          value.forEach((val) => searchParams.append(key, val));
        }
      });

      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      history.push(newUrl);
    },
    [history, location.pathname, location.search]
  );
};

export enum ComparisonPeriod {
  PRIOR_PERIOD = 'prior-period',
  PRIOR_YEAR = 'prior-year'
}

export const useComparisonPeriod = () => {
  const pid = useQueryParamValue('pid', ComparisonPeriod.PRIOR_YEAR);
  return pid;
};

export enum WeekRange {
  YTD = 'ytd',
  LAST_YEAR = 'ly',
  LAST_52 = '52w',
  LAST_26 = '26w',
  LAST_13 = '13w',
  LAST_4 = '4w',
  LAST_WEEK = '1w'
}

export const useWeekRange = () => {
  return useQueryParamValue('wr', WeekRange.YTD);
};

/**
 * Pass in a value and get back a debounced version of it.
 * It will only return an updated value after
 */
export const useDebouncedValue = (value: string, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [value, delay]);

  return debouncedValue;
};

/**
 * Get the URL to the retailer's product page for a given stackline sku, such as for Amazon
 */
export const useRetailerUrl = (stacklineSku: string) => {
  const retailerId = useAppSelector((state) => state.retailer.id);
  const { targetUrl, name } = useAppSelector((state) => state.app);

  return `https://${targetUrl}/api/utility/OpenRetailerProductPage?appName=${name}&retailerId=${retailerId}&stacklineSku=${stacklineSku}`;
};

/**
 * returns a function that gets the URL to the retailer's product page for a given stackline sku, such as for Amazon
 */
export const useGetRetailerUrl = () => {
  const retailerId = useAppSelector((state) => state.retailer.id);
  const { targetUrl, name } = useAppSelector((state) => state.app);

  const getRetailerUrl = useCallback(
    (stacklineSku: string) => {
      return `https://${targetUrl}/api/utility/OpenRetailerProductPage?appName=${name}&retailerId=${retailerId}&stacklineSku=${stacklineSku}`;
    },
    [name, retailerId, targetUrl]
  );
  return { getRetailerUrl };
};

/**
 * Exposes functions for opening a snackbar anywhere in the app
 */
export const useSnackbar = () => {
  const snackbarContext = useContext(SnackbarContext);
  if (!snackbarContext) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return snackbarContext;
};

/**
 * Exposes functions for opening a popup anywhere in the app
 */
export const useGenericPopup = () => {
  const popupContext = useContext(PopupContext);
  if (!popupContext) {
    throw new Error('useGenericPopup must be used within a PopupProvider');
  }
  return popupContext;
};

/**
 * Exposes functions for getting/setting a users profile image last updated date
 */
export const useProfileImageContext = () => {
  const userProfileImageContext = useContext(UserProfileImageContext);
  if (!userProfileImageContext) {
    throw new Error('useProfileImageContext must be used within a UserProfileImageProvider');
  }
  return userProfileImageContext;
};

export function useKeypress(key: string | number, action: () => void, dependencies: any[]) {
  useEffect(() => {
    function onKeyDown(e: any) {
      if (e.key === key) {
        action();
      }
    }
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);
}

export function useUserProfileImage(userId?: string) {
  const { userId: currentUserId } = useAppSelector((state) => state.user.session || {});

  const { profileImageUpdatedTimestamp } = useProfileImageContext();

  return `${getUserProfileImageUrl(userId !== undefined ? userId : currentUserId)}?t=${profileImageUpdatedTimestamp}`;
}

/**
 * Returns a function that can be used to re-render a component.
 * Useful for when you're updating a ref and need to trigger a re-render
 */
export function useRerender() {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [_, setRerender] = useState({});

  const rerender = useCallback(() => {
    setRerender({});
  }, []);

  return { rerender };
}

/**
 * Returns boolean for instacart retailer page.
 */
export const useInstacartPageFilter = () => {
  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);
  const { id: entityId, type: entityType } = mainEntity;
  return entityType === 'retailer' && entityId.toString() === '63';
};

/**
 * Each visualization has a subtitle name which displays the currently selected
 * entity (such as the Category, Brand, or Subcategory name). If there are filters
 * applied, it will display the name of the filter.
 *
 * For example, if we are at the Brand level with a Category filter, the subtitle
 * will display the category name. If multiple filters are applied, the global subtitle
 * name will be empty.
 */
export const useGlobalSubtitleName = ({
  includeUnnamed = true
}: {
  /**
   * Returns general names like 'All Categories' and 'Keyword List' when true and we're on the all categories view,
   * but if false returns an empty string
   */
  includeUnnamed?: boolean;
} = {}): {
  subtitleDisplayName: string;
} => {
  const retailer = useAppSelector((state) => state.retailer);
  const entity = useAppSelector((state) => state.entityService.mainEntity);
  const filters = useAppSelector((state) => state.filters);
  const app = useAppSelector((state) => state.app);
  const categories = useAppSelector((state) => state.categories);

  const { subtitleDisplayName, entityDisplayName } = buildSubtitleDisplayName(
    retailer,
    entity,
    filters,
    categories,
    app
  );

  if (!includeUnnamed && ['All Categories', 'Keyword List'].includes(subtitleDisplayName || entityDisplayName)) {
    return {
      subtitleDisplayName: ''
    };
  }

  return {
    subtitleDisplayName: subtitleDisplayName ?? entityDisplayName ?? ''
  };
};

/**
 * Returns the main time period object that is currently selected. This is useful
 * because state.mainTimePeriod does not include the short display name (like "Last 4 Weeks")
 * and sometimes we need that for the UI.
 */
export const useMainTimePeriod = () => {
  const { id, availableMainTimePeriods } = useAppSelector((state) => state.mainTimePeriod);

  return availableMainTimePeriods.find((period) => period.id === id);
};

export const useMainTimePeriodShortDisplayName = () => {
  const timePeriod = useMainTimePeriod();

  if (timePeriod?.id === 'cd') {
    return timePeriod.displayName;
  }

  if (timePeriod?.id === 'ytd') {
    return 'YTD';
  }

  return timePeriod?.shortDisplayName?.toLowerCase()?.replace('weeks', 'week') ?? 'selected';
};

export const useComparisonTimePeriodShortDisplayName = () => {
  const comparisonPeriod = useAppSelector((state) => state.comparisonTimePeriod.shortDisplayName);

  return comparisonPeriod.toLowerCase();
};
