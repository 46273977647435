import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import queryString from 'qs';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { PERIODS } from 'src/store/modules/omni/constants';
import { ListGrid } from 'src/components/Grids';
import TileContainer from 'src/components/EntityGrid/Tiles/TileContainer';
import { omniSearchServiceOperation } from 'src/store/modules/omni/omniSearchService';
import { processGridData } from 'src/components/EntityGrid/EntityGrid/OmniEntityGridUtils';
import { OmniSearchBody } from 'src/store/modules/omni/omniSearchService/operations';
import OmniSearchSideBar from 'src/components/Omni/Search/OmniSearchSideBar';
import { fillSegmentFieldInURL } from 'src/components/Omni/OmniSegmentUtil';
import './omniSearch.scss';
import _merge from 'lodash/merge';

const { fetchOmniSearchServiceData } = omniSearchServiceOperation;

const DefaultGridLoading = () => (
  <div className="spinner-wrapper">
    <span className="entity-grid-loading">
      <GridLoading />
    </span>
  </div>
);

const OmniSearch: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  // temp use fixed value
  const tempName = 'omniSearch';
  const { location } = props;
  const mainTimePeriod = useSelector((store: ReduxStore) => store.mainTimePeriod);
  const comparisonTimePeriod = useSelector((state: ReduxStore) => state.comparisonTimePeriod);
  const searchDataState = useSelector((store: ReduxStore) => store.omniSearchService[tempName]);
  const { startWeek: mainStartWeek, endWeek: mainEndWeek } = mainTimePeriod;
  const { startWeek: compareStartWeek, endWeek: compareEndWeek } = comparisonTimePeriod;

  const dispatch = useDispatch();
  const fetchData = useCallback(() => {
    const baseSearchBody: OmniSearchBody = {
      startWeekId: mainStartWeek,
      endWeekId: mainEndWeek,
      retailerIds: [],
      includeBrandIds: [],
      includeCategoryIds: [],
      excludeKeywords: [],
      includeKeywords: []
    };
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 10e8 });
    const filledFilterInUrl = fillSegmentFieldInURL(queryParams, 'product');
    delete filledFilterInUrl.name;
    delete filledFilterInUrl.queryId;
    const searchBody = _merge(baseSearchBody, filledFilterInUrl);
    dispatch(fetchOmniSearchServiceData(searchBody, tempName, PERIODS.MAIN));
    const requestBodyForCompare = { ...searchBody, startWeekId: compareStartWeek, endWeekId: compareEndWeek };
    dispatch(fetchOmniSearchServiceData(requestBodyForCompare, tempName, PERIODS.COMPARE));
  }, [dispatch, mainStartWeek, mainEndWeek, compareStartWeek, compareEndWeek, location.search]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  let dataToRender = [];
  if (searchDataState && !searchDataState.isFetching && searchDataState.main && searchDataState.compare) {
    dataToRender = processGridData(searchDataState, {
      currencySymbol: '$',
      metricType: 'MONEY',
      name: 'avgRetailPrice'
    });
  }
  const onReclassifyChecked = false;
  const showStatusBar = false;
  const showPriceData = true;
  const showCardBackDeleteButton = false;
  const showOnlyBreadcrumbMismatches = false;
  return (
    <div className="omni-search">
      <OmniSearchSideBar {...props} />
      <div className="omni-search__content">
        {!searchDataState || searchDataState.isFetching || !(searchDataState.main && searchDataState.compare) ? (
          <DefaultGridLoading />
        ) : (
          <ListGrid showLoadMoreButton={false} className="entityGrid-list-grid" onWaypointEntered={() => {}}>
            {dataToRender.map((item) => {
              return (
                <TileContainer
                  key={item.id}
                  dataItem={item}
                  reclassifyProducts={[]}
                  onReclassifyChecked={onReclassifyChecked}
                  showStatusBar={showStatusBar}
                  showPriceData={showPriceData}
                  showCardBackDeleteButton={showCardBackDeleteButton}
                  onDelete={() => {}}
                  showOnlyBreadcrumbMismatches={showOnlyBreadcrumbMismatches}
                />
              );
            })}
          </ListGrid>
        )}
      </div>
    </div>
  );
};

export default withRouter(OmniSearch);
