import axios from 'axios';
import { KeyWordSegment } from 'src/components/Omni/OmniSegmentUtil';
import { ThunkDispatch } from 'redux-thunk';
import ReduxStore from 'src/types/store/reduxStore';
import { receiveOmniKeywordList, startFetchOmniKeywordList } from './actions';

interface UpdateKeywordListRequestBody {
  query?: KeyWordSegment;
  name: string;
  queryId?: string;
  isActive?: boolean;
  segmentType: string;
}

export const updateKeywordListSegment = async (updateKeywordListSegmentBody: UpdateKeywordListRequestBody) => {
  return axios.post('/omni/segment/updateSegment', updateKeywordListSegmentBody);
};

export const fetchOmniKeyword = async () => {
  return axios.get('omni/segment/getAllSegments?segmentType=keyword');
};

export const fetchKeywordSegmentListData = () => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
  try {
    dispatch(startFetchOmniKeywordList({ data: [] }));
    const response = await fetchOmniKeyword();
    if (response && response.status === 200) {
      const { data } = response;
      dispatch(receiveOmniKeywordList({ data }));
    }
  } catch (e) {
    console.warn(e);
  }
};
