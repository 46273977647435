import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { useStacklineTheme } from '@stackline/ui';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import _pick from 'lodash/pick';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List as VirtualizedList } from 'react-virtualized';
import StyledCheckbox from 'src/components/BeaconRedesignComponents/common/StyledCheckbox';
import 'src/components/EntityPage/Filters/Filter.scss';
import { BEACON_SIDEBAR_FILTER_SCROLL_CONTAINER_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { SelectedExcludeCheckbox, SelectedIncludeCheckbox, UnselectedCheckbox } from 'src/components/SvgIcons/SvgIcons';
import CustomizedCheckbox from 'src/components/common/CustomizedCheckBox/CustomizedCheckBox';
import { useBus } from 'src/utils/Hooks';
import { shouldShowNewBeacon } from 'src/utils/app';
import { prefillFormData } from 'src/utils/segments';
import checkboxRenderer from './checkboxRenderer';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
/*
    State & Miscellaneous Definitions:
    listItemAvailable - All items available in the Categories/Subcategories sections
    listItemsToShow - Items to be shown based on if it is checked or filtered via search
    filter - Driven by include/exclude toggle controls next to filter name. Determines whether to include or exclude selected items. Set to default initially which will include items on click.
    eventBus for Subcategory Mismatch - Used to determine if we need to update or uncheck certain subcategories based on parent categories selected.
    fieldNameMap - Maps the idFieldName (Created in AdvancedSearchSideBar.js) to its relevant include or exclude term filter name.
*/
interface UpdatedListItemSelectorProps {
  idFieldName: string;
  nameFieldName: string;
  searchFieldHintText: string;
  searchFieldNotFoundText: string;
  listDisplayName: string;
  listItems: { isChecked: boolean }[];
  selectedListItems: { i: string; n: string }[];
  onSelectedListItemsChange: (change: { key: string; values: { i: string }[] }, filterOptions?: object) => void;
  listItemHeight: number;
  eventBus: { emit: Function };
  key: any;
  parent: any;
  index: any;
}

const UpdatedListItemSelector: React.FC<UpdatedListItemSelectorProps> = ({
  idFieldName,
  nameFieldName,
  listDisplayName,
  searchFieldHintText,
  searchFieldNotFoundText,
  listItems,
  selectedListItems,
  onSelectedListItemsChange,
  listItemHeight = 45,
  eventBus
}) => {
  const [listItemsAvailable, setListItemsAvailable] = useState([]);
  const [listItemsToShow, setListItemsToShow] = useState([]);
  const [input, setInput] = useState('');
  const [filter, setFilter] = useState('default');
  const { app, categories } = useSelector(
    (state: { app: { queryParams: object; name: string }; categories: { id: number; childEntities: object[] }[] }) =>
      state
  );

  const theme = useStacklineTheme();

  const currentFormData = prefillFormData({ queryParams: app.queryParams, appName: app.name });

  const fieldNameMap = {
    categoryId: 'excludedCategoryId',
    excludedCategoryId: 'categoryId',
    subCategoryId: 'excludedSubCategoryId',
    excludedSubCategoryId: 'subCategoryId'
  };
  const { termFilters } = currentFormData;
  const includeField = idFieldName;
  const excludeField = fieldNameMap[idFieldName];

  function updateFilterSelection() {
    // We set the toggle state for the user based on whether we're including or excluding items
    if (termFilters[includeField].values.length > 0) {
      setFilter('include');
    } else if (termFilters[excludeField].values.length > 0) {
      setFilter('exclude');
    } else {
      setFilter('default');
    }
  }

  function setListItems() {
    let availableListItems = _cloneDeep(listItems);
    if (selectedListItems && selectedListItems.length >= 0) {
      selectedListItems.forEach((selectedListItem) => {
        if (selectedListItem.i) {
          const matchingElement = availableListItems.find((x) => `${x[idFieldName]}` === selectedListItem.i.toString());
          if (matchingElement) {
            matchingElement.isChecked = true;
          }
        }
      });
    }

    availableListItems = _orderBy(availableListItems, [({ isChecked }) => (isChecked ? 1 : 0)], ['desc']);
    setListItemsAvailable(availableListItems);
    setListItemsToShow(availableListItems);
  }

  useEffect(() => {
    setListItems();
    updateFilterSelection();
  }, [listItems]);

  const selectFilter = (selection) => {
    setFilter(selection);
    const selectedListItemsCloned = _cloneDeep(selectedListItems);
    const mapFilters = {
      include: { includeKey: idFieldName, excludeKey: fieldNameMap[idFieldName] },
      default: { includeKey: idFieldName, excludeKey: fieldNameMap[idFieldName] },
      exclude: { excludeKey: idFieldName, includeKey: fieldNameMap[idFieldName] }
    };
    // We only want to fire a form update change when the user has items selected, otherwise just change the filter state.
    if (selectedListItemsCloned.length > 0) {
      onSelectedListItemsChange({ key: idFieldName, values: selectedListItemsCloned }, mapFilters[selection]);
    }
  };

  function handleSubcategoryMismatch(selectedCategories: { i: number }[]) {
    if (idFieldName === 'subCategoryId') {
      if (selectedCategories.length > 0) {
        let childEntities = [];
        // Map our selected categories with the category list from Redux. Find all valid subcategories (defined as subcategories tied to selected categories).
        selectedCategories.forEach((selectedCategory) => {
          const categoryMatch = categories.find((cat) => Number(cat.id) === Number(selectedCategory.i));
          const selectedCategorySubcategories = categoryMatch.childEntities;
          childEntities = [...childEntities, ...selectedCategorySubcategories];
        });
        const subCategoriesToRemove = [];
        // SelectedListItems in this case will always be Selected Subcategories
        selectedListItems.forEach((selectedSubcategory) => {
          const match = childEntities.find(
            (allowedSubcategory) => Number(allowedSubcategory.id) === Number(selectedSubcategory.i)
          );
          // If a selected subcategory is not in our list of allowed subcategories, we will filter it out and keep the allowed subcategories.
          if (match === undefined) {
            subCategoriesToRemove.push(Number(selectedSubcategory.i));
          }
        });
        const keyOverride = filter === 'exclude' ? fieldNameMap[idFieldName] : idFieldName;
        const valuesWithoutInvalidSubcategory = selectedListItems.filter(
          (item) => !subCategoriesToRemove.includes(Number(item.i))
        );
        onSelectedListItemsChange({ key: keyOverride, values: valuesWithoutInvalidSubcategory });
      }
    }
  }
  useBus(eventBus, 'checkForSubcategoryMismatch', handleSubcategoryMismatch);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value || value === '') {
      setInput(value);
      setListItemsToShow(listItemsAvailable);
    } else {
      const items = [];
      listItemsAvailable.forEach((listItem) => {
        if (listItem[nameFieldName].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
          items.push(listItem);
        }
      });
      setInput(value);
      setListItemsToShow(items.sort((a, b) => b.isChecked - a.isChecked || a.name.localeCompare(b.name)));
    }
  };

  // Only used for Category search.
  const checkForSubcategoryMismatch = (selectedCategories) => {
    eventBus.emit('checkForSubcategoryMismatch', selectedCategories);
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>, isInputChecked: boolean, index: number) => {
    const listItemsToShowCopy = _cloneDeep(listItemsToShow);
    listItemsToShowCopy[index].isChecked = isInputChecked;
    const selectedListItem = listItemsToShowCopy[index];
    listItemsAvailable.find((x) => x[idFieldName].toString() === selectedListItem[idFieldName].toString()).checked =
      true;
    let selectedListItemsCloned = _cloneDeep(selectedListItems);

    if (isInputChecked) {
      selectedListItemsCloned.push({ i: selectedListItem[idFieldName], n: selectedListItem[nameFieldName] });
    } else {
      selectedListItemsCloned = selectedListItems.filter((x) => x.i.toString() !== `${selectedListItem[idFieldName]}`);
    }

    const keyOverride = filter === 'exclude' ? fieldNameMap[idFieldName] : idFieldName;
    onSelectedListItemsChange({ key: keyOverride, values: selectedListItemsCloned });

    // Only fires if component is an instance of Category search
    if (idFieldName === 'categoryId') {
      checkForSubcategoryMismatch(selectedListItemsCloned);
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    const keyOverride = filter === 'exclude' ? fieldNameMap[idFieldName] : idFieldName;
    onSelectedListItemsChange({ key: keyOverride, values: [] });
  };

  const rowRenderer = ({ index, style }) => {
    const listItem = listItemsToShow[index];
    return (
      <div
        key={listItem[nameFieldName]}
        style={{ ...style, height: undefined, paddingBottom: shouldShowNewBeacon() ? '12px' : '5px' }}
      >
        {shouldShowNewBeacon() ? (
          <FormControlLabel
            title={listItem[nameFieldName]}
            sx={{
              color: theme.colors.primary,
              minWidth: '100%',
              alignItems: 'flex-start',
              marginLeft: '0px',
              marginRight: '0px',
              '& .MuiTypography-root': {
                paddingLeft: '6px'
              }
            }}
            checked={listItem.isChecked}
            label={<Text variant="body2">{listItem[nameFieldName]}</Text>}
            value={listItem[nameFieldName]}
            control={
              <StyledCheckbox
                filter={filter}
                disableRipple
                disableTouchRipple
                iconProps={{ width: '16px', height: '16px' }}
              />
            }
            key={listItem[nameFieldName]}
            onChange={(evt) => handleCheck(evt, evt.target.checked, index)}
          />
        ) : (
          // WARNING: DEPRECATED --- this should eventually be removed after full redesign
          <FormControlLabel
            control={
              <CustomizedCheckbox
                styleObj={{
                  width: 12,
                  height: 12,
                  padding: 0,
                  iconBackgroundColor: 'transparent',
                  iconColor: 'transparent',
                  checkedIconBackgroundColor: 'transparent',
                  checkedIconColor: 'transparent'
                }}
                InnerIcon={filter === 'exclude' ? SelectedExcludeCheckbox : SelectedIncludeCheckbox}
                OuterIcon={UnselectedCheckbox}
                checkboxProps={{
                  checked: listItem.isChecked,
                  style: { marginLeft: '2px' },
                  onChange: (evt) => {
                    handleCheck(evt, evt.target.checked, index);
                  }
                }}
              />
            }
            label={
              <span
                style={{
                  fontSize: '12px',
                  fontWeight: shouldShowNewBeacon() ? 'normal' : '500',
                  letterSpacing: 'normal'
                }}
              >
                {listItem[nameFieldName] || ''}
              </span>
            }
          />
        )}
      </div>
    );
  };

  // Caches row heights for many list items in our virtualized list
  // Currently exhibits weird behavior when placed outside of component; we may not be receiving performance benefits with the way it is setup currently.
  // React Docs advise against using Refs for rendering but we may be able to expose the ref properly with a wrapper function that returns just the "current" property.
  const cache = new CellMeasurerCache({
    defaultHeight: 45,
    fixedWidth: true
  });

  return (
    <div className="search-form-container">
      <div className={shouldShowNewBeacon() ? 'form-group-controls-beaconV2' : 'form-group-controls'}>
        <h4 className="sl-form-label">{listDisplayName}</h4>
        <div className="checkbox-container">{checkboxRenderer(filter, selectFilter)}</div>
        <button onClick={(e) => handleClear(e)} className="clear-btn">
          Clear
        </button>
      </div>
      <TextField
        variant="standard"
        autoComplete="off"
        className="updated-sl-form-input"
        placeholder={searchFieldHintText}
        type="text"
        name="keyword"
        id="keyword"
        style={{ width: '100%' }}
        value={input}
        onChange={handleInputChange}
        sx={{
          '& .MuiInput-underline:before': {
            borderBottomColor: '#dedede'
          },
          '& .MuiInput-underline::hover:before': {
            borderBottomColor: '#dedede'
          },
          '& .MuiInputBase-root:before': {
            borderBottomColor: '#dedede'
          },
          '& .MuiInputBase-root::hover:before': {
            borderBottomColor: '#dedede'
          }
        }}
      />
      {_isEmpty(listItemsToShow) ? (
        <div style={{ height: '175px' }}>{searchFieldNotFoundText}</div>
      ) : (
        <AutoSizer disableHeight>
          {() => (
            <VirtualizedList
              width={BEACON_SIDEBAR_FILTER_SCROLL_CONTAINER_WIDTH}
              height={listItemsToShow.length < 5 ? listItemsToShow.length * listItemHeight : 175}
              rowCount={listItemsToShow.length}
              rowHeight={cache.rowHeight}
              style={{ marginBottom: '10px', marginTop: '16px' }}
              rowRenderer={({ ...props }) => {
                const { key, parent, index } = props;
                return (
                  <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                    {rowRenderer(props)}
                  </CellMeasurer>
                );
              }}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
};

const mapStateToProps = (state) =>
  _pick(state, ['app', 'retailer', 'user', 'categories', 'subCategories', 'filters', 'entityService']);

export default withRouter(connect(mapStateToProps)(UpdatedListItemSelector));
