import React, { useMemo, useState } from 'react';
import { useStyles } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/styles';
import EntityIconButtonGroup from 'src/components/common/Buttons/EntityIconButtonGroup';
import { SlDropdownMenuOption, StacklineSizing, useStacklineTheme } from '@stackline/ui';
import { withBus } from 'react-bus';
import TabsWidget from 'src/components/BeaconRedesignComponents/EntityTableRefresh/TabsWidget';
import { getTitleOverrides } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/titleOverrides';
import { useAppSelector } from 'src/utils/Hooks';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import { SlRow } from 'src/components/BeaconRedesignComponents/Generic/SlRow';
import { SlColumn } from 'src/components/BeaconRedesignComponents/Generic/SlColumn';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { BEACON_PRO_RIGHT_CONTENT_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { SlMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlMenu';
import { IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ExportConfig } from 'src/components/BeaconRedesignComponents/common/AppExportModal/exportDataUtils';

export enum IconsList {
  export = 'Export',
  tile = 'Tile',
  table = 'Table'
}

export type TableActions = 'SaveImage' | 'CopyImage' | 'ExportCsv';

export interface BuildExportRequestFunction {
  (args: { originalRequest: ExportConfig[]; groupByFieldName: string; includeCompTimePeriod: boolean }): ExportConfig[];
}

export interface EntityTableHeaderProps {
  /**
   * Optionally render a custom header
   */
  renderHeader?: () => React.ReactNode | null;
  eventBus: any;
  enableSwitchingLayouts?: boolean;
  defaultView: string;
  groupByFields?: GroupByField[];
  title: string;
  prefixTitle?: string;
  uniqueName?: string;
  handleGroupByChange?: (evt) => void;
  handleChangeLayout?: () => void;
  handleCSVExport?: () => void;
  iconsList?: IconsList[];
  verticalInset?: StacklineSizing;
  paddingBottom?: string | number;
  paddingTop?: string | number;
  enabledActions?: TableActions[];
  rowData?: any[];
  buildExportRequest?: BuildExportRequestFunction;
}

interface GroupByField {
  name: string;
  displayName: string;
}

const EntityTableHeader = ({
  renderHeader = null,
  paddingBottom = '32px',
  paddingTop = '32px',
  enableSwitchingLayouts = false,
  enabledActions = [],
  defaultView = 'table',
  groupByFields = [],
  prefixTitle,
  title,
  uniqueName = '',
  handleGroupByChange = () => {},
  handleChangeLayout = () => {},
  handleCSVExport = () => {},
  rowData = [],
  buildExportRequest,
  iconsList = [IconsList.export, IconsList.tile, IconsList.table]
}: EntityTableHeaderProps) => {
  const [selection, setSelection] = useState(groupByFields.length > 0 ? groupByFields[0].name : 'stacklineSku');
  const classes = useStyles();
  const {
    queryParams: { tab, subtab }
  } = useAppSelector((state) => state.app);

  const { prefix, completeTitleOverride } = getTitleOverrides({ tab, subtab });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const theme = useStacklineTheme();

  const dropdownOptions: SlDropdownMenuOption[] = [
    {
      id: 'exportCsv',
      label: 'Export CSV'
    }
  ];

  const handleCloseEllipsis = () => {
    setAnchorEl(null);
  };
  const handleEllipsisDropdownClick = (option: SlDropdownMenuOption) => {
    switch (option.id) {
      case 'exportCsv':
        handleCSVExport();
        break;
      default:
        break;
    }
    handleCloseEllipsis();
  };

  const handleOpenEllipsis = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Map our available fields to button dropdown options
  // These change based on the entity currently selected (i.e "Category" is not available on the category entity level)
  const options = useMemo(() => {
    if (groupByFields.length === 0) {
      return [];
    }

    return groupByFields.map((field) => ({
      id: field.name,
      label: field.displayName,
      ...field
    }));
  }, [groupByFields]);

  const selectedOption = useMemo(() => {
    return options.find((option) => option.id === selection);
  }, [options, selection]);

  const handleSelectionChange = (id: string) => {
    setSelection(id);
    handleGroupByChange({ target: { value: id } });
  };

  const prefixToUse = prefixTitle || prefix;

  return (
    <>
      <SlColumn widths="full" style={{ paddingBottom, paddingTop, maxWidth: BEACON_PRO_RIGHT_CONTENT_WIDTH }}>
        <div className={classes.entityTableHeaderContainer}>
          <div className={classes.titleGroup}>
            {renderHeader ? (
              renderHeader()
            ) : groupByFields.length > 1 && selectedOption && options ? (
              <SlRow
                style={{
                  gap: '5px',
                  paddingBottom: `0px` // to offset padding in the chart column labels
                }}
                verticalPosition="center"
              >
                {prefixToUse && <Text variant="h4">{prefixToUse}</Text>}
                <SlDropdownMenu
                  onChange={(s) => handleSelectionChange(s.id)}
                  defaultLabel={selectedOption.label}
                  selectedId={selectedOption.id}
                  buttonVariant="title"
                  options={options}
                />
              </SlRow>
            ) : (
              <Text
                sx={{
                  paddingBottom: `0px` // to offset padding in the chart column labels
                }}
                variant="h4"
              >
                {title || completeTitleOverride || ''}
              </Text>
            )}
          </div>
          {enableSwitchingLayouts ? (
            <div className={classes.buttonGroupContainer}>
              <EntityIconButtonGroup
                defaultView={defaultView}
                handleChangeLayout={handleChangeLayout}
                iconsList={iconsList}
                buildExportRequest={buildExportRequest}
              />
            </div>
          ) : null}

          {enabledActions.length > 0 && rowData.length > 0 ? (
            <IconButton
              onClick={handleOpenEllipsis}
              sx={{
                height: theme.spacing.mdl,
                width: theme.spacing.mdl,
                borderRadius: theme.spacing.xs,
                color: theme.colors.primary
              }}
            >
              <MoreHorizIcon />
            </IconButton>
          ) : null}
        </div>

        {uniqueName.includes('trendingProducts') ? <TabsWidget /> : null}
        {/* some tables (forecasts) we want to have ellipsis for csv export.save image, copy image etc */}
        <SlMenu
          anchorEl={anchorEl}
          open={!!anchorEl}
          selectedId={null}
          onChange={handleEllipsisDropdownClick}
          onClose={handleCloseEllipsis}
          options={dropdownOptions}
        />
      </SlColumn>
    </>
  );
};

export default withBus('eventBus')(EntityTableHeader);
