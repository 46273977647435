import React, { useCallback, useMemo, useState } from 'react';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import EntityTableHeader, {
  IconsList
} from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';
import {
  RetailSalesResponseAdditionalValues,
  TrafficResponseAdditionalValues,
  TrafficDatum
} from './serverProxy/types';
import MetricCell from 'src/components/BeaconRedesignComponents/MetricCell/MetricCell';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { Text } from '@stackline/ui';
import moment from 'moment-timezone';
import { useKeyMetricForecastSummaryData } from './serverProxy';
import { useBeaconSalesDataByWeekId } from './serverProxy/useBeaconSalesDataByWeekId';
import {
  useExportStartAndEndWeekIds,
  useForecastComparisonStartAndEndWeekIds,
  useForecastType
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import { calculatePercentChange } from 'src/utils/app';
import { useBeaconTrafficDataByWeekId } from './serverProxy/useBeaconTrafficDataByWeekId';
import { useAdjustedForecastExportRequest, useUnadjustedForecastExportRequest } from './hooks';
import { useAppSelector, useQueryParamValue } from 'src/utils/Hooks';
import {
  ForecastPeriod,
  ForecastType
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import { useYtdStartAndEndWeekIdsForForecast, usePreviousForecastWeekId } from './serverProxy/hooks';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { useRetailerDisplayName } from 'src/utils/Hooks/reduxSelectors';

/**
 * Type for a single row in the table
 */
interface SalesTrafficDatum {
  weekId: string;
  retailSales_sum_value: number;
  retailSalesPercentChange: number;
  unitsSold_sum_value: number;
  unitsSoldPercentChange: number;
  totalTraffic_sum_value: number;
  conversionRate: number;
  conversionRatePercentChange: number;
}

/**
 * Helper type to return each row in the table without the percent change values.
 * We do this so that we can combine forcasted and actual data in one array,
 * then compute the percent change for each row in a single computation.
 */
type SalesTrafficDatumWithoutPercentChanges = Omit<
  SalesTrafficDatum,
  'retailSalesPercentChange' | 'unitsSoldPercentChange' | 'conversionRatePercentChange' | 'conversionRate'
>;

/**
 * Formats a week ID like Aug 19, 2023
 */
function getLastDayOfWeek(weekId: number) {
  const year = Math.floor(weekId / 100);
  const week = weekId % 100;

  const firstDayOfWeek = moment().year(year).week(week).day(0);
  const lastDayOfWeek = firstDayOfWeek.clone().day(6);

  return lastDayOfWeek.format('MMM D, YYYY');
}

/**
 * Shows a list of the forecasted sales data for a product
 */
const ForecastsProductSalesByWeekTable = () => {
  const { type: mainEntityType, shortDisplayName } = useAppSelector((state) => state.entityService.mainEntity);
  const [includeCompTimePeriodForExport, setIncludeCompTimePeriodForExport] = useState(false);
  const [forecastGroupByFieldName, setForecastGroupByFieldName] = useState<string>('stacklineSku');
  const retailerDisplayName = useRetailerDisplayName();

  /* ************************** Helper variables - week IDs and forecast period, forecast type ************************** */
  const { historicStartWeekId, historicEndWeekId, forecastEndWeekId } = useExportStartAndEndWeekIds();
  const forecastType = useForecastType();
  const { startWeekId: comparisonStartWeekId, endWeekId: comparisonEndWeekId } =
    useForecastComparisonStartAndEndWeekIds();
  const { startWeekId: mainStartWeekId, endWeekId: mainEndWeekId } = useYtdStartAndEndWeekIdsForForecast();
  const previousForecastWeekId = usePreviousForecastWeekId();
  const forecastPeriod = useQueryParamValue('forecastPeriod', ForecastPeriod.FULL_YEAR);

  /* ******************** Fetched data - forecasts, sales, traffic, and comparisons ********************* */
  const { data: forecastedData, isLoading: isForecastLoading } = useKeyMetricForecastSummaryData();

  const { data: salesData, isLoading: isSalesLoading } = useBeaconSalesDataByWeekId({
    requestId: 'forecastProductTableSales',
    startWeekId: mainStartWeekId,
    endWeekId: mainEndWeekId
  });

  const { data: trafficData, isLoading: isTrafficLoading } = useBeaconTrafficDataByWeekId({
    requestId: 'forecastProductTableTraffic',
    startWeekId: mainStartWeekId,
    endWeekId: mainEndWeekId
  });

  const { data: comparisonData, isLoading: isComparisonLoading } = useBeaconSalesDataByWeekId({
    requestId: 'forecastProductTableSalesComparison',
    startWeekId: comparisonStartWeekId,
    endWeekId: comparisonEndWeekId
  });

  const { data: trafficComparisonData, isLoading: isTrafficComparisonLoading } = useBeaconTrafficDataByWeekId({
    requestId: 'forecastProductTableTrafficComparison',
    startWeekId: comparisonStartWeekId,
    endWeekId: comparisonEndWeekId
  });
  /* ********************  Forecast export request builder ******************************* */

  const unadjustedExportRequest = useUnadjustedForecastExportRequest({
    groupByFieldName: forecastGroupByFieldName,
    includeCompTimePeriodForExport
  });
  const adjustedExportRequest = useAdjustedForecastExportRequest({
    groupByFieldName: forecastGroupByFieldName,
    includeCompTimePeriodForExport
  });

  /* ********************  Helper functions for getting sales and traffic ******************************* */

  /** Maps a week ID to sales comparison values */
  const comparisonWeekIdToDatumMap = useMemo(() => {
    return comparisonData.reduce(
      (acc, datum) => ({ ...acc, [datum.weekId]: datum }),
      {} as Record<string, RetailSalesResponseAdditionalValues>
    );
  }, [comparisonData]);

  /** Maps a week ID to traffic comparison values */
  const trafficComparisonWeekIdToDatumMap = useMemo(() => {
    return trafficComparisonData.reduce(
      (acc, datum) => ({ ...acc, [datum.weekId]: datum }),
      {} as Record<string, TrafficResponseAdditionalValues>
    );
  }, [trafficComparisonData]);

  /** Maps a week ID to traffic values */
  const trafficWeekIdToDatumMap = useMemo(() => {
    return trafficData.reduce((acc, datum) => ({ ...acc, [datum.weekId]: datum }), {} as Record<string, TrafficDatum>);
  }, [trafficData]);

  /**
   * Get a sales comparison row for a week id
   */
  const getSalesComparisonDatum = useCallback(
    (weekId: string) => {
      return (
        comparisonWeekIdToDatumMap[previousForecastWeekId(Number(weekId))] || {
          retailSales_sum_value: 0,
          unitsSold_sum_value: 0
        }
      );
    },
    [comparisonWeekIdToDatumMap, previousForecastWeekId]
  );

  /**
   * Get a traffic comparison row for a week id
   */
  const getTrafficComparisonDatum = useCallback(
    (weekId: string) => {
      return (
        trafficComparisonWeekIdToDatumMap[previousForecastWeekId(Number(weekId))] || {
          totalClicks_sum_value: 0
        }
      );
    },
    [previousForecastWeekId, trafficComparisonWeekIdToDatumMap]
  );

  /**
   * Get a traffic row for a week id
   */
  const getTrafficDatum = useCallback(
    (weekId: string) => {
      return trafficWeekIdToDatumMap[weekId] || { totalClicks_sum_value: 0 };
    },
    [trafficWeekIdToDatumMap]
  );

  /* ***************** Calculated data - loading state and formatted rows for table ******************* */
  const isLoading = useMemo(
    () => isForecastLoading || isSalesLoading || isTrafficLoading || isComparisonLoading || isTrafficComparisonLoading,
    [isComparisonLoading, isForecastLoading, isSalesLoading, isTrafficComparisonLoading, isTrafficLoading]
  );

  /** Calculate each metric for the forecasted data and actual sales
   * data (if we're on the full year view)
   */
  const sortedData: SalesTrafficDatum[] = useMemo(() => {
    const combinedData: SalesTrafficDatum[] = [
      // Add in the forecasted data
      ...forecastedData,

      // If we're on the full year, add in the actual sales data
      ...(forecastPeriod === ForecastPeriod.FULL_YEAR
        ? salesData.map((row): SalesTrafficDatumWithoutPercentChanges => {
            return {
              ...row,
              totalTraffic_sum_value: getTrafficDatum(row.weekId).totalClicks_sum_value
            };
          })
        : [])
    ]
      .map((row: SalesTrafficDatumWithoutPercentChanges): SalesTrafficDatum => {
        const conversionRate =
          row.totalTraffic_sum_value > 0 ? row.unitsSold_sum_value / row.totalTraffic_sum_value : 0;
        const comparisonTrafficRow = getTrafficComparisonDatum(row.weekId);
        const comparisonSalesRow = getSalesComparisonDatum(row.weekId);

        const comparisonConversionRate =
          comparisonTrafficRow.totalClicks_sum_value > 0
            ? comparisonSalesRow.unitsSold_sum_value / comparisonTrafficRow.totalClicks_sum_value
            : 0;

        return {
          ...row,
          retailSalesPercentChange: calculatePercentChange(
            row.retailSales_sum_value,
            getSalesComparisonDatum(row.weekId).retailSales_sum_value
          ),
          unitsSoldPercentChange: calculatePercentChange(
            row.unitsSold_sum_value,
            getSalesComparisonDatum(row.weekId).unitsSold_sum_value
          ),
          conversionRate,
          conversionRatePercentChange: calculatePercentChange(conversionRate, comparisonConversionRate)
        };
      })
      .sort((a, b) => a.weekId.localeCompare(b.weekId));

    return combinedData;
  }, [forecastPeriod, forecastedData, getSalesComparisonDatum, getTrafficComparisonDatum, getTrafficDatum, salesData]);

  return (
    <Flex flexDirection="column">
      <EntityTableHeader
        title="Retail Sales by Week"
        showDropdown={false}
        enableSwitchingLayouts
        iconsList={[IconsList.export]}
        buildExportRequest={({ originalRequest, groupByFieldName, includeCompTimePeriod }) => {
          setForecastGroupByFieldName(groupByFieldName);
          setIncludeCompTimePeriodForExport(includeCompTimePeriod);
          const convertHistoricRequest = originalRequest.map((exportRequest) => {
            // this is a special case only for the ratings and reviews forecasts export
            if (exportRequest.searchType === 'beacon-ratings-reviews-metrics') {
              exportRequest.conditions.rangeFilters = [
                { fieldName: 'weekId', minValue: historicStartWeekId, maxValue: historicEndWeekId }
              ];
            }
            // override the filename to include the forecast week id

            exportRequest.fileName = `${mainEntityType}_${shortDisplayName}_forecasts_${forecastType}_${retailerDisplayName}_${
              includeCompTimePeriod ? comparisonStartWeekId : historicStartWeekId
            }_${forecastEndWeekId}`;
            return {
              ...exportRequest,
              exportResults: true,
              aggregations: [
                {
                  ...exportRequest.aggregations[0],
                  ...(includeCompTimePeriod && forecastPeriod === ForecastPeriod.FULL_YEAR
                    ? {
                        comparisonRangeFilters: [
                          { fieldName: 'weekId', maxValue: comparisonEndWeekId, minValue: comparisonStartWeekId }
                        ]
                      }
                    : {}),
                  conditions: {
                    ...exportRequest.aggregations[0].conditions,
                    rangeFilters: [{ fieldName: 'weekId', minValue: historicStartWeekId, maxValue: historicEndWeekId }]
                  }
                }
              ]
            };
          });

          return [
            ...(includeCompTimePeriod || forecastPeriod === ForecastPeriod.FULL_YEAR ? convertHistoricRequest : []),
            unadjustedExportRequest,
            ...(forecastType === ForecastType.ADJUSTED ? [adjustedExportRequest] : [])
          ];
        }}
      />
      <EntityTable
        isLoading={isLoading || isComparisonLoading || isTrafficComparisonLoading}
        shouldModifyColumnDefs={false}
        columnDefs={[
          {
            headerName: 'Week Ending',
            valueFormatter: (_, row: SalesTrafficDatum) => (
              <Text variant="body2">{getLastDayOfWeek(Number(row.weekId))}</Text>
            )
          },
          {
            headerName: 'Retail Sales',
            valueFormatter: (_, row: SalesTrafficDatum) => (
              <MetricCell
                metricType={METRICTYPE.MONEY}
                value={row.retailSales_sum_value}
                percentChange={row.retailSalesPercentChange}
              />
            )
          },
          {
            headerName: 'Units Sold',
            valueFormatter: (_, row: SalesTrafficDatum) => (
              <MetricCell
                metricType={METRICTYPE.VOLUME}
                value={row.unitsSold_sum_value}
                percentChange={row.unitsSoldPercentChange}
              />
            )
          },
          {
            headerName: 'Total Traffic',
            valueFormatter: (_, row: SalesTrafficDatum) => (
              <MetricCell metricType={METRICTYPE.VOLUME} value={row.totalTraffic_sum_value} />
            )
          },
          {
            headerName: 'Conversion Rate',
            valueFormatter: (_, row: SalesTrafficDatum) => (
              <MetricCell
                metricType={METRICTYPE.PERCENT}
                value={row.conversionRate}
                percentChange={row.conversionRatePercentChange}
              />
            )
          }
        ]}
        rowData={sortedData}
      />
    </Flex>
  );
};

export default ForecastsProductSalesByWeekTable;
