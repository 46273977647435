import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';

import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import EntityGrid from 'src/components/EntityGrid';
import VIEWS from '../../ViewDefaultConfig';
import { EnhancedDonutChartContainer } from 'src/components/Charts/Donut';
import {
  buildTrendWidgetConfig,
  buildWidgetGroup,
  buildTopEntitiesWidgetConfig
} from 'src/components/Layout/LayoutUtil';
import {
  buildAdvertisingMultiMetricTrendChart,
  buildAdvertisingTopEntitiesChart,
  getGroupByFieldConfigByEntityType,
  buildDisplayAdvertisingMultiMetricTrendChart,
  buildDisplayAdvertisingTopEntitiesChart
} from 'src/components/Layout/util/DynamicAdvertisingWidgets';
import { buildSwappableDisplayAdvertisingGrid } from 'src/components/EntityGrid/DynamicAdvertisingGrid/DynamicDisplayAdvertisingGrid';
import { buildSwappableAdvertisingGrid } from 'src/components/EntityGrid/DynamicAdvertisingGrid/DynamicAdvertisingGrid';
import getBeaconAdSummaryWidgets from './AdSummaryLayout';
import getBeaconAdDisplaySummaryWidgets from './AdsDisplaySummaryLayout';
import { propEq } from 'src/utils/fp';
import { shouldShowNewBeacon } from 'src/utils/app';
import TotalTrafficPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Traffic/Total/TotalTrafficPage';
import TrafficDisplayTrendChart from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Traffic/Display/TrafficDisplayTrendChart';
import TrafficDisplayKeyMetricCards from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Traffic/Display/TrafficDisplayKeyMetricCards';
import { getWidgetBarChartStyles } from 'src/components/BeaconRedesignComponents/styles/styles';
import { BEACON_GRID_TOP_SPACING, BEACON_KEY_METRIC_CARD_SPACING } from '../BeaconProLayoutConsts';
import OtherTrafficPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Traffic/Other/OtherTrafficPage';
import OrganicTrafficPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Traffic/Organic/OrganicTrafficPage';
import PaidSearchPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Traffic/PaidSearch/PaidSearchPage';
import TrafficDisplayGrid from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Traffic/Display/TrafficDisplayGrid';

const noOfTopEntities = 50;
const allowHorizontalScroll = {
  horizontalScrolling: {
    enabled: true,
    step: 3
  },
  xAxis: [{ min: 0, max: 9 }],
  enableSwitchingGroupBy: true
};

const buildAdvertisingPaidSearchWidgets = ({ app, entity, weekIdField }, widgets) => {
  const indexName = 'advertising';

  // Key metrics grid
  if (shouldShowNewBeacon()) {
    widgets.push({
      CustomComponent: PaidSearchPage,
      view: {}
    });
    return;
  }

  widgets.push(...getBeaconAdSummaryWidgets({ app, entity }));

  // Multi-group-by top entities chart
  const w = _merge(buildAdvertisingTopEntitiesChart({ indexName, entity, app, weekIdField }), {
    view: {
      container: {
        style: getWidgetBarChartStyles({
          marginTop: `${BEACON_KEY_METRIC_CARD_SPACING}px`
        })
      }
    }
  });
  // w.data.groupByFields -- resort this so the obj with name = 'searchKeyword' is at the front / is default
  w.data.groupByFields = w.data.groupByFields.sort((a, b) => {
    if (a.name === 'searchKeyword') {
      return -1;
    } else if (b.name === 'searchKeyword') {
      return 1;
    } else {
      return 0;
    }
  });
  widgets.push(w);

  // Multi-metric trend chart

  widgets.push(
    buildAdvertisingMultiMetricTrendChart({ indexName, entity, app, weekIdField, showOnlyMainSeries: false })
  );

  // grid for ad traffic
  widgets.push(buildSwappableAdvertisingGrid({ app, entity }, 'advertising'));
};

const buildDisplayAdvertisingWidgets = ({ app, entity, weekIdField }, widgets) => {
  const indexName = 'advertisingDisplay';

  // Key metrics grid
  if (shouldShowNewBeacon()) {
    widgets.push({
      CustomComponent: TrafficDisplayKeyMetricCards,
      view: {
        container: {
          style: {
            marginBottom: `${BEACON_GRID_TOP_SPACING}px`
          }
        }
      },
      name: 'advertisingDisplayKeyMetricCards'
    });
  } else {
    widgets.push(...getBeaconAdDisplaySummaryWidgets({ app, entity }));
  }

  // Multi-group-by top entities chart
  widgets.push(
    buildDisplayAdvertisingTopEntitiesChart({ indexName, entity, app, weekIdField, showOnlyMainSeries: false })
  );

  // Multi-metric trend chart
  if (shouldShowNewBeacon()) {
    widgets.push({
      CustomComponent: TrafficDisplayTrendChart,
      view: {
        container: {
          style: {
            marginBottom: `${BEACON_GRID_TOP_SPACING}px`
          }
        }
      },
      name: 'advertisingDisplayMultiMetricTrendChart'
    });
  } else {
    widgets.push(
      buildDisplayAdvertisingMultiMetricTrendChart({ indexName, entity, app, weekIdField, showOnlyMainSeries: false })
    );
  }

  if (shouldShowNewBeacon()) {
    widgets.push({
      CustomComponent: TrafficDisplayGrid,
      view: {}
    });
  } else {
    widgets.push(buildSwappableDisplayAdvertisingGrid({ app, entity }, 'advertisingDisplay'));
  }
};

const buildTotalClicksWidgets = ({ app, entity, weekIdField, indexName, dataConfig, metricType }, widgets) => {
  const mainFields = ['otherClicks', 'otherAdClicks', 'organicClicks', 'adClicks'].map((fieldName) =>
    INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type, dataConfig.retailerIdField.name)
  );

  const otherTrafficDonutChart = {
    CustomComponent: EnhancedDonutChartContainer,
    view: {
      ...VIEWS.donutChart,
      name: `OtherTrafficDonutChart`,
      chartPropsOverride: {
        chart: {
          type: 'pie',
          height: 350
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            }
          }
        },
        tooltip: {
          enabled: true
        },
        disablePointSelect: true,
        enableMultiSelect: false,
        showFullValue: false
      },
      customHeader: {
        enabled: true,
        title: 'Total Traffic'
      },
      container: {
        style: {
          minWidth: '500px',
          height: shouldShowNewBeacon() ? '120px' : '400px'
        }
      }
    },
    data: {
      indexName: 'traffic',
      mainMetric: mainFields[0],
      secondaryMetric: { ...mainFields, displayName: 'Total Traffic' },
      aggregationFields: mainFields,
      groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.retailerIdField.name, entity.type)
    }
  };

  const aggregationFieldMapping = getGroupByFieldConfigByEntityType({
    app,
    indexName: 'traffic',
    metricType
  });

  // Total Traffic
  if (shouldShowNewBeacon() && metricType === 'totalClicks') {
    widgets.push({
      CustomComponent: TotalTrafficPage,
      view: {},
      name: 'totalTraffic'
    });
  } else {
    widgets.push(
      buildWidgetGroup(
        [
          otherTrafficDonutChart,
          buildTrendWidgetConfig(app, indexName, entity, 'weekId', [metricType], weekIdField, {
            view: {
              chartPropsOverride: {
                title: {
                  text: ''
                },
                subtitle: {
                  text: '',
                  style: {
                    'border-bottom': 'none'
                  }
                },
                chart: {
                  height: 450,
                  marginTop: 100
                },
                yAxis: [
                  {
                    visible: false
                  }
                ]
              },
              container: { style: { width: '60%', display: 'inline-block', marginBottom: '100px' } }
            }
          })
        ],
        {
          view: {
            container: {
              style: {
                height: '630px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around'
              }
            }
          }
        }
      )
    );
    // Organic Traffic by XXX
    widgets.push(
      buildTopEntitiesWidgetConfig(
        app,
        indexName,
        entity,
        aggregationFieldMapping[entity.type],
        noOfTopEntities,
        weekIdField,
        {
          data: {
            configByGroupByFieldName: {
              searchTerm: {
                conditions: {
                  termFilters: [
                    {
                      fieldName: 'searchTerm',
                      condition: 'must_not',
                      values: ['[other traffic]', '[Other Traffic]']
                    }
                  ]
                }
              }
            }
          },
          view: {
            container: {
              style: getWidgetBarChartStyles()
            },
            chartPropsOverride: {
              ...allowHorizontalScroll,
              enableSwitchingGroupBy: true
            },
            // Group by keyword by default if keyword is a valid group-by field option
            defaultGroupByField: aggregationFieldMapping[entity.type].find(propEq('name', 'searchTerm'))
          }
        }
      )
    );
    // Keywords with Traffic
    const keywordsWithTrafficWidgetConfig = buildTrendWidgetConfig(
      app,
      indexName,
      entity,
      'weekId',
      ['searchTerm'],
      weekIdField,
      { view: { chartPropsOverride: { title: { text: 'Keywords with Traffic' } } } }
    );
    keywordsWithTrafficWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
      (x) => x.name === 'searchTerm'
    ).conditions = {
      rangeFilters: [
        {
          fieldName: 'totalClicks',
          minValue: 1
        }
      ]
    };
    widgets.push(keywordsWithTrafficWidgetConfig);
  }
};

const buildOrganicClicksWidgets = ({ app, indexName, entity, metricType, weekIdField }, widgets) => {
  const aggregationFieldMapping = getGroupByFieldConfigByEntityType({
    app,
    indexName: 'traffic',
    metricType
  });

  // Organic Traffic
  if (shouldShowNewBeacon()) {
    widgets.push({
      CustomComponent: OrganicTrafficPage,
      view: {},
      name: 'organicTrafficPage'
    });
    return;
  }

  widgets.push(
    buildTrendWidgetConfig(app, indexName, entity, 'weekId', [metricType], weekIdField, {
      view: { chartPropsOverride: { title: { text: 'Organic Traffic' } } }
    })
  );
  // Organic Traffic by XXX
  widgets.push(
    buildTopEntitiesWidgetConfig(
      app,
      indexName,
      entity,
      aggregationFieldMapping[entity.type],
      noOfTopEntities,
      weekIdField,
      {
        view: {
          container: {
            style: getWidgetBarChartStyles()
          },
          chartPropsOverride: {
            ...allowHorizontalScroll,
            enableSwitchingGroupBy: true
          }
        }
      }
    )
  );

  // Products with Organic Traffic
  if (entity.type !== 'product') {
    const productsWithOrganicTrafficWidgetConfig = buildTrendWidgetConfig(
      app,
      indexName,
      entity,
      'weekId',
      ['stacklineSku'],
      weekIdField,
      {
        view: { chartPropsOverride: { title: { text: 'Products with Organic Traffic' } } }
      }
    );
    productsWithOrganicTrafficWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
      (x) => x.name === 'stacklineSku'
    ).conditions = {
      rangeFilters: [
        {
          fieldName: 'organicClicks',
          minValue: 1
        }
      ]
    };

    widgets.push(productsWithOrganicTrafficWidgetConfig);
  }

  // Keywords with Organic Traffic
  const keywordsWithOrganicTrafficWidgetConfig = buildTrendWidgetConfig(
    app,
    indexName,
    entity,
    'weekId',
    ['searchTerm'],
    weekIdField,
    {
      view: { chartPropsOverride: { title: { text: 'Keywords with Organic Traffic' } } }
    }
  );
  keywordsWithOrganicTrafficWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'searchTerm'
  ).conditions = {
    rangeFilters: [
      {
        fieldName: 'organicClicks',
        minValue: 1
      }
    ]
  };

  widgets.push(keywordsWithOrganicTrafficWidgetConfig);
};

export default function getTrafficPageLayout({ app, tab, metricType, entity }) {
  const indexName = tab;
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
  const retailerIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'));
  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.beacon.retailer),
    categoryEntity: _cloneDeep(ENTITIES.beacon.category),
    relatedEntity: _cloneDeep(ENTITIES.beacon.brand),
    weekIdField,
    retailerIdField
  };
  const widgets = [];
  let enableComparison = true;
  if (metricType === 'totalClicks') {
    enableComparison = false;
    buildTotalClicksWidgets({ app, entity, weekIdField, indexName, dataConfig, metricType }, widgets);
  } else if (metricType === 'organicClicks') {
    buildOrganicClicksWidgets({ app, indexName, entity, metricType, weekIdField }, widgets);
  } else if (metricType === 'adClicks') {
    buildAdvertisingPaidSearchWidgets({ app, entity, weekIdField, indexName }, widgets, metricType);
  } else if (metricType === 'dpvViews') {
    buildDisplayAdvertisingWidgets({ app, entity, weekIdField, indexName }, widgets, metricType);
  } else if (metricType === 'otherClicks') {
    widgets.push({
      CustomComponent: OtherTrafficPage,
      view: {}
    });
  }

  // grid for organic traffic page
  if (!shouldShowNewBeacon() && ['totalClicks', 'organicClicks'].includes(metricType)) {
    const gridAggregationFields = [
      INDEX_FIELDS.getField(app.name, indexName, 'organicClicks', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, indexName, 'adClicks', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, indexName, 'totalClicks', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, indexName, 'adSpend', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, indexName, 'adRetailSales', 'product', 'stacklineSku')
    ].map((f) => ({
      ...f,
      cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
    }));
    const skuAggregationFields = _cloneDeep(gridAggregationFields);
    skuAggregationFields.splice(2, 0, {
      ...INDEX_FIELDS.getField(app.name, indexName, 'otherClicks', 'product', 'stacklineSku'),
      cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
    });

    widgets.push({
      CustomComponent: EntityGrid,
      view: _merge({}, VIEWS.entityGrid, {
        gridOptions: {
          enableGroupBy: true
        },
        container: {
          style: {
            marginTop: `${BEACON_GRID_TOP_SPACING}px`
          }
        }
      }),
      data: {
        groupByFields: [
          { ...INDEX_FIELDS.getField(app.name, indexName, 'searchTerm', 'product'), displayName: 'Keyword' },
          {
            ...INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product'),
            displayName: 'Product'
          }
        ],
        configByGroupByFieldName: {
          searchTerm: {
            indexName,
            entity: ENTITIES.beacon.searchTerm,
            mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
            aggregationFields: gridAggregationFields,
            tableView: {
              metricFields: gridAggregationFields
            }
          },
          stacklineSku: {
            indexName,
            entity: ENTITIES.beacon.product,
            mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
            aggregationFields: skuAggregationFields,
            tableView: {
              metricFields: skuAggregationFields
            }
          }
        }
      }
    });
  }

  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison,
    dataConfig,
    widgets
  };
}
