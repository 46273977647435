import types from './types';

export interface OmniAccountSettingEntity {
  entityId: string;
  entityType: string;
  highRiskReviews: {
    keywords: string[];
    status?: {
      value: string;
      text: string;
    };
  };
  updatedBy: string;
  updatedAt: string;
}

export interface OmniAccountSettingState {
  data: OmniAccountSettingEntity | null;
  isFetching: boolean;
}

const omniAccountSettingService = (
  state: OmniAccountSettingState = {
    data: null,
    isFetching: true
  },
  action: { type: string; entity: { data: any[] } }
) => {
  switch (action.type) {
    case types.START_FETCH:
      return {
        data: [],
        isFetching: true
      };

    case types.RECEIVE_OMNI_ACCOUNT:
      return {
        isFetching: false,
        data: action.entity.data
      };
    default:
      return state;
  }
};

export default omniAccountSettingService;
