import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';
import _startCase from 'lodash/startCase';
import React from 'react';
import { DonutChart, DonutChartContainer } from 'src/components/Charts/Donut';
import colors from 'src/utils/colors';
import { buildSubtitleDisplayName } from 'src/utils/filters';
import { propEq } from 'src/utils/fp';
import { warn } from 'src/utils/mixpanel';
import convertMetricToDisplayValue from '../../EntityGrid/gridUtils';
import { enhanceDonutChart } from './DonutChartContainer';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';

const buildDropdownOptions = (
  uniqueName,
  metricFields,
  groupByFields,
  selectedMetricFieldName,
  selectedGroupByFieldName
) => {
  const mainMetricOptions = metricFields.map(({ displayName }) => ({
    eventId: { displayName },
    eventName: `${uniqueName}toggleDonutChartMainMetric`,
    displayName,
    isSelected: displayName === selectedMetricFieldName
  }));

  const groupByOptions = groupByFields.map(({ name, displayName }) => ({
    eventId: { name },
    eventName: `${uniqueName}toggleDonutChartGroupByMetric`,
    displayName,
    isSelected: name === selectedGroupByFieldName
  }));

  return { mainMetricOptions, groupByOptions };
};

export class MultiMetricFieldDonutChart extends DonutChartContainer {
  componentDidMount() {
    super.componentDidMount.call(this);
    this.props.eventBus.on(`${this.props.uniqueName}toggleDonutChartMainMetric`, this.handleMainMetricToggle);
    this.props.eventBus.on(`${this.props.uniqueName}toggleDonutChartGroupByMetric`, this.handleGroupByToggle);

    const listenForMainMetricToggle = _get(this.props, 'widget.view.chartPropsOverride.hideFieldDropdowns', false);
    if (listenForMainMetricToggle) {
      this.props.eventBus.on('toggleChartMainMetric', this.handleMainMetricToggle);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount.call(this);
    this.props.eventBus.off(`${this.props.uniqueName}toggleDonutChartMainMetric`, this.handleMainMetricToggle);
    this.props.eventBus.off(`${this.props.uniqueName}toggleDonutChartGroupByMetric`, this.handleGroupByToggle);
  }

  generateSubtitle = () => {
    const {
      widget,
      retailer,
      entityService: { mainEntity },
      app,
      categories,
      filters
    } = this.props;
    if (!widget.view.customHeader || !mainEntity) {
      return null;
    }

    const subtitle = buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app);

    return subtitle;
  };

  scrollIntoView = () => {
    const { anchorName } = this.props.widget.view;
    if (anchorName) {
      window.location.hash = anchorName;
    }
  };

  handleMainMetricToggle = ({ displayName, scrollIntoView }) => {
    this.pageNumber = 1;
    if (!this.props.widget.view.metricFields.find(propEq('displayName', displayName))) {
      return;
    }

    this.setState({ selectedMetricFieldName: displayName }, () =>
      this.fetchMetrics({ ...this.props, isGrowthCalculation: true })
    );
    if (scrollIntoView) {
      this.scrollIntoView();
    }
  };

  handleGroupByToggle = ({ name, scrollIntoView }) => {
    this.pageNumber = 1;
    this.switchGroupByField(name);
    if (scrollIntoView) {
      this.scrollIntoView();
    }
  };

  getSelectedMetricFieldName = () => {
    const fallback = this.props.widget.view.metricFields ? this.props.widget.view.metricFields[0].displayName : '';
    return this.state.selectedMetricFieldName || fallback;
  };

  getSelectedGroupByFieldName = () => {
    return _get(this.state.groupByFieldSelected, 'name', this.props.widget.data.groupByFields[0].name);
  };

  getMetricFields(props) {
    return [props.widget.view.metricFields.find(propEq('displayName', this.getSelectedMetricFieldName()))];
  }

  getAggregationFields(props, groupByField) {
    return super.getAggregationFields
      .call(this, props, groupByField)
      .filter(propEq('displayName', this.getSelectedMetricFieldName()));
  }

  getMetricFieldDisplayName(name) {
    const fieldObj = this.props.widget.data.aggregationFields.find((f) => f.name === name);
    if (fieldObj) {
      return fieldObj.displayName;
    }

    return name;
  }

  getMetricFieldName(displayName) {
    const fieldObj = this.props.widget.data.aggregationFields.find((f) => f.displayName === displayName);
    if (fieldObj) {
      return fieldObj.name;
    }

    return displayName;
  }

  getAggregatedRetailerDonutChartSeries = (props) => {
    const { entity, entitySearchService, retailer, widget, uniqueName } = props;

    const displayName = this.state.selectedMetricFieldName || widget.data.mainMetric.displayName;
    const selection = this.getMetricFieldName(displayName);
    const dataPoints = _get(
      entitySearchService,
      [`donutChartMetrics${uniqueName}`, `${selection}_by_${widget.data.groupByField.name}`, 'data'],
      []
    );
    const metricType = _get(
      entitySearchService,
      [`donutChartMetrics${uniqueName}`, `${selection}_by_${widget.data.groupByField.name}`, 'metricType'],
      'VOLUME'
    );

    if (_isEmpty(dataPoints)) {
      warn('No data found for donut chart; would have crashed');
    }

    const comparisonDataPoints = _get(
      entitySearchService,
      [`donutChartMetrics${uniqueName}-growth-comparison`, `${selection}_by_${widget.data.groupByField.name}`, 'data'],
      []
    );

    let mainMetricValue = 0;
    let comparisonMainMetric = 0;
    const seriesData = [];

    if (widget.data.isGrowthCalculation || displayName.includes('Growth')) {
      dataPoints.forEach((point) => {
        mainMetricValue += point.value;

        const matchingPointForComparison = comparisonDataPoints.find((c) => c.name === point.name);

        if (matchingPointForComparison) {
          comparisonMainMetric += matchingPointForComparison.value;
          const retailerData = retailer.availableRetailers.find((r) => r.id === point.name);
          const growth = matchingPointForComparison.value === 0 ? 10 : point.value / matchingPointForComparison.value;
          seriesData.push({
            fieldId: point.name,
            name: retailerData ? retailerData.displayName : point.name,
            y: growth
          });
        }
      });
      mainMetricValue -= comparisonMainMetric;
    } else {
      dataPoints.forEach((point) => {
        mainMetricValue += point.value;
        const retailerData = retailer.availableRetailers.find((r) => r.id === point.name);
        seriesData.push({
          fieldId: point.name,
          name: retailerData ? retailerData.displayName : point.name,
          y: point.value
        });
      });
    }

    return {
      displayName,
      metricType,
      data: seriesData,
      entity,
      title: mainMetricValue,
      titleColor: colors.darkBlue,
      currencySymbol: retailer.currencySymbol
    };
  };

  buildChartProps(mainEntityMetrics) {
    const { widget } = this.props;
    const { retailer } = this.props;

    const {
      view: { chartPropsOverride }
    } = widget;

    const defaultDataLabels = {
      enabled: true,
      zIndex: 1,
      color: '#000000',
      backgroundColor: 'transparent',
      connectorWidth: 2,
      style: {
        color: '#1D2935',
        fontSize: '14px',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 'normal'
      },
      useHTML: true
    };

    let chartProps = {
      subtitleOverride: this.generateSubtitle(),

      chart: {
        type: 'pie',
        height: 440
      },
      title: {
        align: 'left',
        verticalAlign: 'top',
        useHTML: true,
        style: {
          color: mainEntityMetrics.titleColor || colors.darkBlue,
          'font-size': mainEntityMetrics.titleFontSize || '28px'
        },
        text: '<div></div>'
      },
      subtitle: {
        text: `
        <div style="text-align: center; font-size: 16px;">
          <div style="font-size: ${mainEntityMetrics.titleFontSize || '44px'}; color: ${
          mainEntityMetrics.titleColor || colors.darkBlue
        }">
            ${convertMetricToDisplayValue(
              retailer,
              mainEntityMetrics.title,
              mainEntityMetrics.metricType,
              mainEntityMetrics.currencySymbol,
              false
            )}
          </div>
          <div>${mainEntityMetrics.displayName}</div>
        </div>`,
        align: 'center',
        verticalAlign: 'middle',
        style: { 'font-size': '16px', 'border-bottom-width': '0px', 'padding-top': '13%' },
        useHTML: true,
        y: 20
      },
      plotOptions: {
        pie: {
          dataLabels: defaultDataLabels
        },
        series: {
          allowPointSelect: !chartPropsOverride.disablePointSelect && !chartPropsOverride.enableMultiSelect,
          states: {
            hover: {
              enabled: !chartPropsOverride.disablePointSelect
            },
            select: {
              color: (chartPropsOverride.selectedPointColor && chartPropsOverride.selectedPointColor) || ''
            }
          },
          point: {
            events: {}
          }
        }
      },
      tooltip: {
        enabled: true,
        pointFormat: '{point.percentage:.1f}%',
        borderWidth: 1,
        zIndex: 9999,
        borderColor: 'transparent',
        opacity: 1,
        backgroundColor: 'transparent',
        shadow: false,
        useHTML: true,
        shape: 'callout',
        positioner: (labelWidth, labelHeight, point) => ({ x: point.plotX, y: point.plotY }),
        formatter() {
          const metric = convertMetricToDisplayValue(
            retailer,
            this.percentage / 100,
            'PERCENT',
            mainEntityMetrics.currencySymbol,
            true
          );
          return `<div class="hichart-tooltip donut">
                  <div class="hiline-chart-tooltipx">
                    <span style="color: ${colors.darkBlue};">${this.point.name}</span>
                  </div>
                  <div class="hiline-chart-tooltipy metric-value">
                    <span style="color:${this.point.color};">${metric}</span>
                  </div>
                </div>`;
        }
      },
      credits: false,
      lang: {
        noData: ''
      },
      size: 50,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 400
            },
            chartOptions: {
              series: [
                {
                  id: 'versions',
                  dataLabels: {
                    enabled: false
                  }
                }
              ]
            }
          }
        ]
      }
    };

    const chartSeries = [
      {
        type: 'pie',
        name: 'Donut Chart',
        data: mainEntityMetrics.data,
        innerSize: '90%'
      }
    ];
    chartProps = _merge(chartProps, chartPropsOverride);

    // Add the group-by dropdown options to the chart props to that they are rendered in the chart's title
    const { mainMetricOptions, groupByOptions } = buildDropdownOptions(
      this.props.uniqueName,
      widget.view.metricFields,
      widget.data.groupByFields,
      this.getSelectedMetricFieldName(),
      this.getSelectedGroupByFieldName()
    );

    const { view, data } = widget;
    if (view.chartPropsOverride.enableSwitchingGroupBy && data.groupByFields.length > 0) {
      // chartProps.title.text = `${mainMetricField.displayName} by ${groupByFieldSelected.displayName}`;
      chartProps.exporting = chartProps.exporting || {};
      chartProps.exporting.enabled = false;
      chartProps.exporting.buttons = [mainMetricOptions, groupByOptions];

      // chartProps.exporting
    }

    const selectedGroupBy = groupByOptions.find((opt) => opt.isSelected);
    const isAdType = selectedGroupBy && selectedGroupBy.displayName === 'Ad Type';
    if (isAdType) {
      chartSeries.forEach((series) => {
        series.categories.forEach((item) => {
          if (['sponsoredProducts', 'sponsoredBrands'].includes(item.name)) {
            item.name = _startCase(item.name);
          }
        });
      });
    }

    return { chartProps, chartSeries };
  }

  render() {
    const { widget, retailer } = this.props;
    let donutChartSeries = {};
    if (this.state.isLoading) {
      return <GenericChartLoading />;
    }

    if (widget.view.name === 'OtherTrafficDonutChart') {
      donutChartSeries = this.getOtherTrafficChartSeries(this.props);
    } else if (widget.view.name === 'PromoDonutChart') {
      widget.data.mainMetric.metricType = 'PERCENT';
      donutChartSeries = this.getPromoDonutChartSeries(this.props);
    } else if (widget.view.name === 'multiMetricDonutChart') {
      // const chartParams = this.buildChartProps()
      // if (widget.view.name.endsWith('ByretailerId')) {
      donutChartSeries = this.getAggregatedRetailerDonutChartSeries(this.props);
      // }
    } else if (widget.view.name === 'AggregatedRetailersDonutChart') {
      donutChartSeries = this.getAggregatedRetailerDonutChartSeries(this.props);
    } else {
      donutChartSeries = this.getDonutChartSeries(this.props);
    }

    const { chartProps, chartSeries } = this.buildChartProps(donutChartSeries);
    return chartSeries ? (
      <div key={widget.view.name}>
        <DonutChart mainEntityMetrics={donutChartSeries} chartProps={chartProps} widget={widget} retailer={retailer} />
      </div>
    ) : null;
  }
}

const EnhancedMultiMetricFieldDonutChart = enhanceDonutChart(MultiMetricFieldDonutChart);
export default EnhancedMultiMetricFieldDonutChart;
