import React, { useEffect, useState } from 'react';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { getAppName } from 'src/utils/app';
import _omit from 'lodash/omit';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import BeaconMetricColumnChart, {
  BeaconMetricColumnChartProps
} from 'src/components/BeaconRedesignComponents/BeaconMetricColumnChart/BeaconMetricColumnChart';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

export interface BeaconDoubleDropdownMetricColumnChartProps
  extends Omit<BeaconMetricColumnChartProps, 'indexName' | 'fieldName'> {
  fields: {
    fieldName: string;
    label?: string;
  }[];
  groupByFields: {
    id: string;
    label: string;
    indexName: string;
  }[];
  showSingleGroupBy?: boolean;
  /**
   * Optionally override the selected field/metric from the parent component.
   * Defaults to the first field in the fields array.
   */
  selectedField?: string;
}

const getField = (indexName: string, fieldName: string) => {
  return INDEX_FIELDS.getField(getAppName(), indexName, fieldName);
};

/**
 * Query advanced search data with two dropdowns. The first is for metric fields and
 * the second is for group by fields.
 */
export default function BeaconDoubleDropdownMetricColumnChart(props: BeaconDoubleDropdownMetricColumnChartProps) {
  const { fields, groupByFields, showSingleGroupBy, selectedField } = props;
  const [selectedFieldName, setSelectedFieldName] = useState(selectedField || fields[0].fieldName);
  const [selectedGroupByFieldName, setSelectedGroupByFieldName] = useState(groupByFields[0].id);

  useEffect(() => {
    if (selectedField) {
      setSelectedFieldName(selectedField);
    }
  }, [selectedField]);

  const groupByFieldToIndex = groupByFields.reduce((acc, field) => {
    return {
      ...acc,
      [field.id]: field.indexName
    };
  }, {});

  const indexName = groupByFieldToIndex[selectedGroupByFieldName];

  return (
    <BeaconMetricColumnChart
      {...props}
      indexName={indexName}
      fieldName={selectedFieldName}
      useAdvancedSearchDataArgs={{
        groupByField: selectedGroupByFieldName,
        fields: [{ name: selectedFieldName }],
        ...(props.useAdvancedSearchDataArgs ? props.useAdvancedSearchDataArgs : {})
      }}
      beaconChartWithLegendPropsBuilder={(legendProps) => ({
        ..._omit(legendProps, 'singleDropdownProps'),
        title: showSingleGroupBy ? (
          <Flex gap="sm" alignItems="center">
            <SlDropdownMenu
              buttonVariant="title"
              options={fields.map((field) => ({
                id: field.fieldName,
                label: field.label || getField(indexName, field.fieldName).displayName
              }))}
              defaultLabel=""
              onChange={(option) => setSelectedFieldName(option.id)}
              selectedId={selectedFieldName}
            />
            <Text variant="h4">by</Text>
            <Text variant="h4">{groupByFields[0]?.label ?? ''}</Text>
          </Flex>
        ) : (
          <Flex gap="sm" alignItems="center">
            <SlDropdownMenu
              buttonVariant="title"
              options={fields.map((field) => ({
                id: field.fieldName,
                label: field.label || getField(indexName, field.fieldName).displayName
              }))}
              defaultLabel=""
              onChange={(option) => setSelectedFieldName(option.id)}
              selectedId={selectedFieldName}
            />
            <Text variant="h4">by</Text>
            <SlDropdownMenu
              buttonVariant="title"
              options={groupByFields}
              defaultLabel=""
              onChange={(option) => setSelectedGroupByFieldName(option.id)}
              selectedId={selectedGroupByFieldName}
            />
          </Flex>
        )
      })}
    />
  );
}
