import React, { useMemo, useState } from 'react';
import { useStyles } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/styles';
import { SlRow } from '@stackline/ui';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import { Text } from '../Generic/Text';

interface CommonSummaryWithDropdownProps {
  enableSwitchingLayouts?: boolean;
  defaultView?: string;
  groupByFields?: GroupByField[];
  title?: string;
  prefixTitle?: string;
  uniqueName?: string;
  handleGroupByChange?: (string) => void;
  handleMainMetric?: (string) => void;
  selectedGroupBy?: GroupByField;
  mainMetricFields?: GroupByField[];
  selectedMainMetric?: GroupByField;

  /**
   * If true, we will not set fixed menu width with 160px,
   * so the ellipsis for slMenu won't be applied
   */
  shouldShowFullItemText?: boolean;
}

interface GroupByField {
  name: string;
  displayName: string;
}

const CommonSummaryWithDropdown = ({
  groupByFields = [],
  prefixTitle,
  title,
  handleGroupByChange = () => {},
  handleMainMetric = () => {},
  selectedGroupBy,
  mainMetricFields,
  selectedMainMetric,
  shouldShowFullItemText = false
}: CommonSummaryWithDropdownProps) => {
  const classes = useStyles();
  const defaultGroupBySelection = selectedGroupBy && groupByFields.length > 0 ? selectedGroupBy.name : 'stacklineSku';
  const [groupBySelection, setGroupBySelection] = useState(defaultGroupBySelection);

  const [mainMetricSelection, setMainMetricSelection] = useState(selectedMainMetric ? selectedMainMetric.name : []);

  const groupByOptions = useMemo(() => {
    if (groupByFields.length === 0) {
      return [];
    }

    return groupByFields.map((field) => {
      let label = field.displayName;
      if (label === 'Keywords') {
        label = 'Keyword'; // TODO: move to config
      }

      return {
        id: field.name,
        label,
        ...field
      };
    });
  }, [groupByFields]);

  const selectedGroupByOption = useMemo(() => {
    return groupByOptions.find((option) => option.id === groupBySelection);
  }, [groupByOptions, groupBySelection]);

  const handleGroupBySelectionChange = (id: string) => {
    handleGroupByChange({ target: { value: id } });
    setGroupBySelection(id);
  };

  const mainMetricOptions = useMemo(() => {
    if (!mainMetricFields || mainMetricFields.length === 0) {
      return [];
    }

    return mainMetricFields.map((field) => ({
      id: field.name,
      label: field.displayName,
      ...field
    }));
  }, [mainMetricFields]);

  const selectedMainMetricOption = useMemo(() => {
    return mainMetricOptions.find((option) => option.id === mainMetricSelection) || mainMetricOptions[0];
  }, [mainMetricOptions, mainMetricSelection]);

  const handleMainMetricSelectionChange = (id: string) => {
    handleMainMetric({ target: { value: id } });
    setMainMetricSelection(id);
  };

  return (
    <div className={classes.entityTableHeaderContainer}>
      {/* Main Entity Dropdown */}
      <SlRow verticalPosition="center">
        {mainMetricFields && mainMetricFields.length > 1 ? (
          <SlDropdownMenu
            onChange={(s) => handleMainMetricSelectionChange(s.id)}
            defaultLabel={selectedMainMetricOption.label}
            selectedId={selectedMainMetricOption.id}
            buttonVariant="title"
            options={mainMetricOptions}
            shouldShowFullItemText={shouldShowFullItemText}
          />
        ) : (
          <Text variant="h4">{prefixTitle}</Text>
        )}

        <Text variant="h4" sx={{ paddingX: '5px' }}>
          by
        </Text>

        {/* GroupBy Dropdown */}
        {groupByFields && groupByFields.length > 1 && selectedGroupByOption && groupByOptions ? (
          <SlDropdownMenu
            onChange={(s) => handleGroupBySelectionChange(s.id)}
            defaultLabel={selectedGroupByOption.label}
            selectedId={selectedGroupByOption.id}
            buttonVariant="title"
            options={groupByOptions}
          />
        ) : (
          <Text variant="h4">{title}</Text>
        )}
      </SlRow>
    </div>
  );
};

export default CommonSummaryWithDropdown;
