export class SearchGridConstants {
  public static readonly AD_SEARCH_PAGINATION_SIZE = 50;

  public static readonly PAGE_SIZE_5K = 5000;

  public static readonly PAGE_SIZE_10K = 10000;

  public static readonly PAGE_SIZE_25K = 25000;

  public static readonly CHUNK_SIZE_1024 = 1024;

  public static readonly TARGETS_THRESHOLD_250 = 250;

  public static readonly PORTFOLIO_TAB_ADVANCED_SEARCH_REQUEST_PAGE_SIZE = 500;

  public static readonly GROUP_BY_PORTFOLIO_ID = 'portfolioId';

  /** Maxmimum number of rows to fetch and display, including
   * rows without metrics
   */
  public static readonly NO_METRICS_MAX = 1000; // TODO get this from split IO
}
