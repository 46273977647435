import { Parser } from 'expr-eval';
import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

export const REVIEWS_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  stars: {
    name: 'stars',
    displayName: 'Avg. Star Rating',
    metricType: METRICTYPE.DECIMAL,
    aggregationFunction: 'stats',
    dataType: DATATYPE.DECIMAL,
    overrides: {
      weekId_Override: {
        timePeriodAggregationFunctionType: 'trueAvg',
        timePeriodAggregationFunction: 'avg'
      }
    }
  },

  // Forecasting
  rolling52WeekWeightedRating: {
    name: 'rolling52WeekWeightedRating',
    displayName: 'Buy Box - Rate',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum',
    canBeExported: false
  },

  // Forecasting
  rolling52WeekReviews: {
    name: 'rolling52WeekReviews',
    displayName: 'Buy Box - Rate',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum',
    canBeExported: false
  },

  // Forecasting
  weightedRating: {
    name: 'weightedRating',
    displayName: 'Weighted Rating',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.DECIMAL,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      `rolling52WeekReviews > 0 ? rolling52WeekWeightedRating / rolling52WeekReviews : 0`
    ),
    timePeriodAggregationFunctionType: 'simple',
    timePeriodAggregationFunction: 'avg'
  }
};
