import { Parser } from 'expr-eval';
import { AppName } from 'sl-api-connector';
import { getWeekLastDate } from 'src/utils/dateformatting';
import { DATATYPE, FieldDefinition, METRICTYPE } from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

export const COMMON_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  weekId: {
    name: 'weekId',
    displayName: 'Week Ending',
    dataType: DATATYPE.DATETIME,
    metricType: METRICTYPE.DATETIME,
    aggregationFunction: 'avg',
    processAdditionalMetaData(additionalMetaData: { name: number }) {
      const nextYearWeekId =
        (additionalMetaData.name * 1) % 100 === 53
          ? additionalMetaData.name * 1 + 148 // e.g. 202053 -> 202201 (use this as comparison val)
          : additionalMetaData.name * 1 + 100;

      return {
        name: additionalMetaData.name,
        weekId: additionalMetaData.name * 1,
        weekEnding: getWeekLastDate(additionalMetaData.name * 1),
        weekEndingNextYear: getWeekLastDate(nextYearWeekId)
      };
    }
  },
  weekDay: {
    name: 'weekDay',
    displayName: 'Day',
    supportedAppNames: [AppName.Advertising],
    dataType: DATATYPE.DATETIME,
    metricType: METRICTYPE.DATETIME,
    aggregationFunction: 'avg'
  },
  monthId: {
    name: 'monthId',
    displayName: 'Month Id',
    dataType: DATATYPE.DATETIME,
    metricType: METRICTYPE.DATETIME,
    aggregationFunction: 'avg'
  },
  promoType: {
    name: 'promoType',
    displayName: 'Deal Type',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.KEYFIELD,
    aggregationFunction: 'cardinality',
    entity: {
      type: 'promoType'
    }
  },
  promoSalesPercentage: {
    name: 'retailSales',
    displayName: 'Share of Total Sales',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'sum',
    aggregationFunction: 'sum'
  },
  retailSales: {
    name: 'retailSales',
    displayName: 'Retail Sales',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  product: {
    name: 'product',
    displayName: 'Product',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  retailSalesMarketShare: {
    name: 'retailSalesMarketShare',
    displayName: 'Market Share - Retail Sales',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg'
  },
  retailSalesShareTotalSales: {
    name: 'retailSalesShareTotalSales',
    displayName: 'Share of Total Sales', // Promo
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg'
  },
  retailPrice: {
    name: 'retailPrice',
    displayName: 'Retail Price',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold > 0 ? (retailSales/unitsSold) : 0'),
    overrides: {
      product_stacklineSku_Override: {
        aggregationFunctionType: 'computed'
      }
    },
    fillWithLastKnownValue: true
  },
  retailPriceMarketShare: {
    name: 'retailPriceMarketShare',
    displayName: 'Market Share - Retail Price',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg'
  },
  retailPriceChangePercent: {
    name: 'retailPriceChangePercent',
    displayName: 'Average Discount',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    useAbsoluteValue: true
  },
  unitsSold: {
    name: 'unitsSold',
    displayName: 'Units Sold',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  unitsSoldMarketShare: {
    name: 'unitsSoldMarketShare',
    displayName: 'Market Share - Units Sold',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg'
  },
  spendMarketShare: {
    name: 'spendMarketShare',
    displayName: 'Market Share - Ad Spend',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg'
  },
  salesMarketShare: {
    name: 'salesMarketShare',
    displayName: 'Market Share - Ad Sales',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg'
  },
  dataPointCount: {
    name: 'dataPointCount',
    displayName: 'Data Points',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
    // timePeriodAggregationFunctionType: 'lastValue',
    // timePeriodAggregationFunction: 'sum'
  },
  dataPointCountMarketShare: {
    name: 'dataPointCountMarketShare',
    displayName: 'Market Share - Data Point',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg'
  },
  brandId: {
    name: 'brandId',
    displayName: 'Brand',
    entity: {
      type: 'brand'
    },
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.KEYFIELD,
    aggregationFunction: 'cardinality'
  },
  parentBrandId: {
    name: 'parentBrandId',
    displayName: 'Manufacturer',
    entity: {
      type: 'parentBrand'
    },
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.KEYFIELD,
    aggregationFunction: 'cardinality'
  },
  licenseId: {
    name: 'licenseId',
    displayName: 'License',
    entity: {
      type: 'license'
    },
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.KEYFIELD,
    aggregationFunction: 'cardinality'
  },
  categoryId: {
    name: 'categoryId',
    displayName: 'Category',
    entity: {
      type: 'category'
    },
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.KEYFIELD,
    aggregationFunction: 'cardinality'
  },
  subCategoryId: {
    name: 'subCategoryId',
    displayName: 'Subcategory',
    entity: {
      type: 'subcategory'
    },
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.KEYFIELD,
    aggregationFunction: 'cardinality'
  },
  stacklineSku: {
    name: 'stacklineSku',
    displayName: 'Product',
    displayNamePlural: 'Products',
    entity: {
      type: 'product'
    },
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.INTEGER,
    aggregationFunction: 'cardinality',
    timePeriodAggregationFunctionType: 'lastValue',
    timePeriodAggregationFunction: 'sum'
  },
  beaconClientId: {
    name: 'beaconClientId',
    displayName: 'Organization',
    entity: {
      type: 'beaconclient'
    },
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.KEYFIELD,
    aggregationFunction: 'cardinality'
  },
  retailerId: {
    name: 'retailerId',
    displayName: 'Retailer',
    entity: {
      type: 'retailer'
    },
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.KEYFIELD,
    aggregationFunction: 'cardinality',
    groupBy: 'retailerId'
  },
  parentPlatform: {
    name: 'parentPlatform',
    displayName: 'Parent Platform',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.KEYFIELD,
    aggregationFunction: 'cardinality',
    groupBy: 'parentPlatform'
  },
  averageSellPrice: {
    name: 'averageSellPrice',
    displayName: 'Avg Sell Price',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold > 0 ? (retailSales/unitsSold) : 0')
  },
  reviewId: {
    name: 'reviewId',
    displayName: 'Reviews Count',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'value_count'
  },
  reviewsCount: {
    name: 'reviewsCount',
    displayName: 'Reviews Count',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum',
    timePeriodAggregationFunctionType: 'lastValue',
    timePeriodAggregationFunction: 'sum',
    overrides: {
      retailerId_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'sum'
      },
      stacklineSku_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'sum'
      }
    }
  },
  reviewsRating: {
    name: 'reviewsRating',
    displayName: 'Rating',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.DECIMAL,
    aggregationFunction: 'avg',
    timePeriodAggregationFunctionType: 'lastValue',
    timePeriodAggregationFunction: 'avg',
    maxValue: 5.0,
    aggregationFunctionTimeRange: 'lastWeek'
  },
  spendComputed: {
    name: 'spend',
    displayName: 'Ad Spend',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'sum'
  },
  // Next 3 Sams Club metrics -- start
  newToBrandUnitsSold: {
    name: 'newToBrandUnitsSold',
    displayName: 'NTB Units Sold',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.INTEGER,
    aggregationFunction: 'sum'
  },
  newToBrandSales: {
    name: 'newToBrandSales',
    displayName: 'NTB Sales',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  offlineSales: {
    name: 'offlineSales',
    displayName: 'Offline Sales',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  // SamsClub metrics -- end
  sameCategorySales: {
    name: 'sameCategorySales',
    displayName: 'Same Category Sales',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  // Duplicate to fix getConfig ----
  sameSKUSales: {
    name: 'sameSKUSales',
    displayName: 'Same SKU Sales',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  sameCategoryUnits: {
    name: 'sameCategoryUnits',
    displayName: 'Same Category Conversions',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  sameCategoryConversions: {
    name: 'sameCategoryConversions',
    displayName: 'Same Category Conversions',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  // ------------------------------
  sameSKUConversions: {
    name: 'sameSKUConversions',
    displayName: 'Same SKU Conversions',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  region: {
    name: 'region',
    displayName: 'Continent',
    groupBy: 'locationRegionCode',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  country: {
    name: 'country',
    displayName: 'Country',
    groupBy: 'locationCountryCode',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  locationZip: {
    name: 'locationZip',
    displayName: 'Zip Code',
    groupBy: 'locationZip',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  state: {
    name: 'state',
    displayName: 'State',
    groupBy: 'locationStateName',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  city: {
    name: 'city',
    displayName: 'City',
    groupBy: 'locationCity',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  keyword: {
    name: 'keyword',
    displayName: 'Keyword',
    groupBy: 'keywordId',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  cbsa: {
    name: 'cbsa',
    displayName: 'CBSA',
    groupBy: 'locationCBSAName',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  orderType: {
    name: 'orderType',
    displayName: 'Order Type',
    groupBy: 'orderType',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  brand: {
    name: 'brand',
    displayName: 'Brand',
    groupBy: 'brandId',
    excludeBrandAndProduct: true,
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  }
};

export const DAILY_DATA_FIELDS = {
  dayId: {
    name: 'dayId',
    displayName: 'Day Id',
    dataType: DATATYPE.DATETIME,
    metricType: METRICTYPE.DATETIME,
    aggregationFunction: 'avg'
  }
};
