import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { useAppSelector, useComparisonPeriod, useLocation } from 'src/utils/Hooks';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import { getLastDayPreviousYear, getLastWeekPreviousYear } from 'src/utils/dateformatting';
import { getRetailerIdDisplayName } from 'src/utils/stringFormatting';
import { getDefaultExportConfig, maybeOverrideAggregationFields } from 'src/components/Export/Export';
import { propEq } from 'src/utils/fp';
import {
  ExportConfig,
  makeGroupByConsistent
} from 'src/components/BeaconRedesignComponents/common/AppExportModal/exportDataUtils';
import axios from 'axios';
import { useState } from 'react';
import {
  ExportDropdownOptionIds,
  exportAggregationFieldsMatcher
} from 'src/components/BeaconRedesignComponents/common/AppExportModal/exportDataConfigData';
import {
  displayNameByFieldName,
  getDoubleAggregatableFieldNames,
  getExportGroupByFieldNames
} from 'src/components/Export/exportGroupByFields';
import { getAppName } from 'src/utils/app';
import { trackExport } from 'src/utils/mixpanel';
import { useApplyEntityFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { BuildExportRequestFunction } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';

// This is used during Cypress tests to generate custom export filenames that are easily identifiable
const useGenerateFileName = (prefix: string, request: ExportConfig[]) => {
  const { startWeek: mainStartWeek, endWeek: mainEndWeek } = useAppSelector((state) => state.mainTimePeriod);

  const retailer = useAppSelector((state) => state.retailer);
  const app = useAppSelector((state) => state.app);
  const entityService = useAppSelector((state) => state.entityService);

  if (window.localStorage.exportFileNameOverride) {
    return window.localStorage.exportFileNameOverride;
  }

  let retailerName = '';
  if (request.length > 0) {
    const [requestObj] = request;
    if (requestObj && requestObj.retailerId) {
      retailerName = getRetailerIdDisplayName(requestObj.retailerId, retailer);
    }
  }

  const { type, shortDisplayName: name } = entityService.mainEntity;
  const { tab = app.defaultQueryParams.tab, subtab = app.defaultQueryParams.subtab } = app.queryParams;

  // Default: use main time period start and end week
  let startWeek = mainStartWeek;
  let endWeek = mainEndWeek;

  // Override the field name with the weekId filter, if there is one
  _get(request, ['0', 'aggregations', '0', 'conditions', 'rangeFilters'], []).forEach((filter) => {
    if (filter.fieldName === 'weekId') {
      startWeek = filter.minValue;
      endWeek = filter.maxValue;
    }
  });

  const fileName = `${prefix ? `${prefix} ` : ''}${type} ${
    name || ''
  } ${tab} ${subtab} ${retailerName} ${startWeek} ${endWeek}`;
  return fileName.replace(/ /g, '_');
};

// In Beacon V1, there is a default request from the table.
export const useDefaultExportRequest = ({
  groupByField = 'stacklineSku',
  shouldAddCompare = false,
  aggregationOverride
}: {
  groupByField: string;
  shouldAddCompare: boolean;
  aggregationOverride?: string;
}) => {
  const { queryParams } = useAppSelector((state) => state.app);
  const { categories } = useAppSelector((state) => state);
  const categoryIds = categories.map((category) => category.id);
  const { startWeek: comparisonStartWeek, endWeek: comparisonEndWeek } = useAppSelector(
    (state) => state.comparisonTimePeriod
  );
  const applyEntityFilters = useApplyEntityFilters();

  const retailerId = useAppSelector((state) => state.retailer.id);
  const { tab, subtab, sw: startWeek, ew: endWeek } = queryParams;

  let aggregationFieldMap =
    tab && subtab ? exportAggregationFieldsMatcher[tab][subtab] : exportAggregationFieldsMatcher.default;

  if (aggregationFieldMap.shouldExportMultipleRequest) {
    aggregationFieldMap = aggregationFieldMap[aggregationOverride];
  }

  const {
    indexName,
    aggregationList,
    sortByField,
    derivedFieldsJson,
    aggregationSortByField,
    exportExtraConditions,
    searchBy,
    shouldUserNullAggregation,
    shouldDoAggregationFalse,
    shouldOverrideConditionFilter,
    sortByOnlyFieldNames,
    isReturnDocument,
    period,
    shouldUseSortByAggregationFieldNull,
    setSortDirectionAsNull
  } = aggregationFieldMap || {
    indexName: 'beacon-sales',
    aggregationList: [['Retail Sales', 'retailSales', 'sum', true]],
    sortByField: ['Retail Sales', 'retailSales', 'sum', true]
  };

  if (!aggregationFieldMap) {
    console.error('Fallback image is being used. Please replace it with the correct values.');
  }

  const requestBuilder = new AdvancedSearchRequestBuilder(
    'entityGridMetricsentitypagecontainer_trendingProducts_5-product--1',
    indexName
  );

  let exportAggregationBuilder;

  if (aggregationList) {
    exportAggregationBuilder = new AggregationBuilder(groupByField);

    aggregationList.forEach(
      ([aggregationDisplayName, aggregationName, aggregationFunction, canBeExported, aggregateByFormula]) => {
        exportAggregationBuilder.addAggregationField(
          aggregationDisplayName,
          aggregationName,
          aggregationFunction,
          canBeExported,
          aggregateByFormula
        );
      }
    );

    exportAggregationBuilder.addConditionTermFilter('retailerId', [retailerId]);
    exportAggregationBuilder.addConditionRangeFilter('weekId', startWeek, endWeek);

    if (shouldAddCompare) {
      exportAggregationBuilder.addComparisonRangeFilter('weekId', comparisonStartWeek, comparisonEndWeek);
    }

    const [sortDisplayName, sortName, sortFunc, sortCanBeExported] =
      aggregationSortByField || sortByField || aggregationList[0];
    exportAggregationBuilder.setSortByAggregationField(sortDisplayName, sortName, sortFunc, sortCanBeExported);
    // some exports (e.g - review need to have sortByAggregationField as null)
    if (shouldUseSortByAggregationFieldNull) {
      exportAggregationBuilder.setSortByAggregationFieldAsNull();
    }
    exportAggregationBuilder.setSortDirection('desc');
    requestBuilder.addAggregation(exportAggregationBuilder.build());
  }

  // Currently Review should have aggregation as null.
  if (shouldUserNullAggregation) {
    requestBuilder.makeNullAggregations();
  }

  if (setSortDirectionAsNull) {
    requestBuilder.setSortDirectionAsNull();
  }

  requestBuilder
    .setAdditionalRequestMetaDataDerivedFieldsJson(derivedFieldsJson || '[]')
    .setProcessDocuments(true)
    .setPageNumber(1)
    .setPageSize(20)
    .setRetailerId(String(retailerId))
    .setPeriod(period || 'period')
    .setDoAggregation(!shouldDoAggregationFalse)
    .setReturnDocuments(isReturnDocument || false)
    .setSearchBy(searchBy || 'parent')
    .addConditionTermFilter('retailerId', 'should', [retailerId])
    .addConditionTermFilter('categoryId', 'should', categoryIds)
    .apply(applyEntityFilters);

  if (sortByField) {
    const [sortDisplayName, sortName, sortFunc, sortCanBeExported] = sortByField;
    requestBuilder.addSortField(sortDisplayName, sortName, sortFunc, sortCanBeExported);
  }

  if (exportExtraConditions) {
    exportExtraConditions.forEach(({ fieldName, values }) =>
      requestBuilder.addConditionTermFilter(fieldName, 'should', values)
    );
  }

  if (shouldOverrideConditionFilter) {
    requestBuilder.replaceConditionRangeFilter('weekId', startWeek, endWeek);
  }

  if (sortByOnlyFieldNames) {
    requestBuilder.clearSortFields();
    sortByOnlyFieldNames.forEach((fieldName) => requestBuilder.addSortFieldWithOnlyFieldName(fieldName, 'desc'));
  }

  return requestBuilder.build();
};

// some exports we need to send two different request
export const buildMultipleRequests = ({
  groupByFieldName,
  includeCompTimePeriod,
  fieldElements
}: {
  groupByFieldName: string;
  includeCompTimePeriod: boolean;
  fieldElements: string[];
}) => {
  const requestList = [];
  fieldElements.forEach((element) => {
    const exportRequest = useDefaultExportRequest({
      groupByField: groupByFieldName,
      shouldAddCompare: includeCompTimePeriod,
      aggregationOverride: element
    });
    requestList.push(exportRequest);
  });

  return requestList;
};

export const useExportDataFunction = ({
  type,
  groupByFieldName,
  includeCompTimePeriod,
  buildExportRequest
}: {
  type?: string;
  groupByFieldName: string;
  includeCompTimePeriod: boolean;
  buildExportRequest: BuildExportRequestFunction;
}) => {
  const app = useAppSelector((state) => state.app);
  const pid = useComparisonPeriod();
  const { tab, subtab } = app.queryParams;
  const retailerId = useAppSelector((state) => state.retailer.id);
  const allWeekIdsByRetailerId = useAppSelector((state) => state.allWeekIdsByRetailerId);
  const weekIds = allWeekIdsByRetailerId[retailerId];
  const {
    id: mainTimePeriodId,
    endWeek: mainTimePeriodEndWeek,
    startWeek: mainTimePeriodStartWeek
  } = useAppSelector((state) => state.mainTimePeriod);
  const location = useLocation();
  const user = useAppSelector((state) => state.user);

  const { mainEntity } = useAppSelector((state) => state.entityService);
  const [shouldStartPolling, setShouldStartPolling] = useState(false);

  let exportRequest = useDefaultExportRequest({
    groupByField: groupByFieldName,
    shouldAddCompare: includeCompTimePeriod
  });

  const aggregationFieldMap =
    tab && subtab ? exportAggregationFieldsMatcher[tab][subtab] : exportAggregationFieldsMatcher.default;

  if (aggregationFieldMap.shouldExportMultipleRequest) {
    exportRequest = buildMultipleRequests({
      groupByField: groupByFieldName,
      shouldAddCompare: includeCompTimePeriod,
      fieldElements: exportAggregationFieldsMatcher[tab][subtab].shouldExportMultipleRequest
    });
  }

  const defaultExport = Array.isArray(exportRequest) ? exportRequest : [exportRequest];

  const request = _cloneDeep(defaultExport);
  if (mainTimePeriodId === 'ytd') {
    const firstComparisonRangeFilter = _get(request, '[0].aggregations[0].comparisonRangeFilters[0]');
    if (firstComparisonRangeFilter) {
      switch (firstComparisonRangeFilter.fieldName) {
        case 'weekId': {
          firstComparisonRangeFilter.maxValue = getLastWeekPreviousYear(weekIds);
          break;
        }
        case 'dayId': {
          firstComparisonRangeFilter.maxValue = getLastDayPreviousYear(weekIds);
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  const entityType = _get(mainEntity, ['type'], 'noType');
  const entityDisplayName = _get(mainEntity, ['displayName'], entityType);
  const entityName = _get(mainEntity, ['name'], 'no name');

  let requestOverride = [{}];

  if (type === 'Master' || type === 'Current') {
    requestOverride[0] = {
      sortFilter: null,
      returnDocuments: true,
      searchType: type === 'Master' ? 'beacon-contentApproved' : 'beacon-contentCurrent',
      doAggregation: false,
      searchBy: 'child',
      conditions: { rangeFilters: request[0].aggregations[0].conditions.rangeFilters }
    };
  } else if (type === 'ContentCombine') {
    // Change to most recent week
    request[0].aggregations[0].conditions.rangeFilters.forEach((filter) => {
      if (filter.fieldName === 'weekId') {
        // prev commit from export says "Use most recent week in master content export"
        filter.minValue = mainTimePeriodEndWeek;
        filter.maxValue = mainTimePeriodEndWeek;
      }
    });
    requestOverride = [
      {
        sortFilter: null,
        returnDocuments: true,
        searchType: 'beacon-contentApproved',
        doAggregation: false,
        searchBy: 'child',
        conditions: { rangeFilters: request[0].aggregations[0].conditions.rangeFilters }
      }
    ];
  } else if (['Unaggregated', 'Aggregated'].includes(type)) {
    if (request[0].aggregations[0].groupByFieldName === 'searchKeyword,matchingType') {
      request[0].searchType = 'advertising-adKeywordDailyMetrics';
      request[0].pageSize = 500;
    }
    request[0].aggregations[0].aggregationFields.forEach((field) => {
      if (field.aggregateByFieldDisplayName === 'Campaign Name' && field.aggregateByFieldName === 'campaignId') {
        field.aggregateByFieldDisplayName = 'No of Campaigns';
      }
    });

    request[0].additionalRequestMetaData = request[0].additionalRequestMetaData || {};
    if (groupByFieldName) {
      request[0].additionalRequestMetaData = {
        ...request[0].additionalRequestMetaData,
        EntityColumnToExport: JSON.stringify({ [entityDisplayName]: entityName }),
        Aggregations: { groupByField: [groupByFieldName] }
      };
    } else {
      request[0].additionalRequestMetaData = {
        ...request[0].additionalRequestMetaData,
        EntityColumnToExport: JSON.stringify({ [entityDisplayName]: entityName })
      };
    }

    if (type === 'Aggregated') {
      const requestClone = _cloneDeep(request[0]);
      requestClone.aggregations[0].conditions.rangeFilters = requestClone.aggregations[0].comparisonRangeFilters;
      request.push(requestClone);
    } else if (type === 'Unaggregated') {
      request[0].aggregations[0].groupByFieldName += ',dayId';
      request[0].conditions.rangeFilters = request[0].aggregations[0].conditions.rangeFilters;
    }
  }

  let fileNamePrefix;
  if (type === 'Master') {
    fileNamePrefix = 'MasterContent';
  } else if (type === 'Current') {
    fileNamePrefix = 'LiveCurrentContent';
  } else if (type === 'ContentCombine') {
    fileNamePrefix = 'MasterContent';
  } else if (['Unaggregated', 'Aggregated'].includes(type)) {
    fileNamePrefix = type;
  }

  // Enable break down by week even if there is a last value main metric, e.g. unitsOnHand
  // This way we can export multiple weeks but only display most recent in the UI
  // If we want to disable this for an export, we should disable the "Separate data by week" for the export

  if (groupByFieldName && groupByFieldName.includes('weekId')) {
    _get(request, [0, 'aggregations', 0, 'conditions', 'rangeFilters'], []).forEach((filter) => {
      if (filter.fieldName === 'weekId') {
        filter.minValue = mainTimePeriodStartWeek;
        filter.maxValue = mainTimePeriodEndWeek;
      }
    });
  }

  const fileName = useGenerateFileName(fileNamePrefix, request);
  const defaultExportConfig = getDefaultExportConfig(fileName);

  const mergedRequest = _merge([], defaultExportConfig, request, requestOverride);

  if (Array.isArray(mergedRequest[0].aggregations) && mergedRequest[0].aggregations[0] && groupByFieldName) {
    mergedRequest[0].aggregations[0].groupByFieldName = groupByFieldName;
  }

  if (_get(mergedRequest, '[0].searchBy') === 'child') {
    mergedRequest[0].returnParentDocuments = true;
  }

  // Remove comparison time period if the user hasn't opted to include it in their export
  const hasComparisonTimePeriod = _get(mergedRequest, '[0].aggregations[0].comparisonRangeFilters');
  if (hasComparisonTimePeriod && !includeCompTimePeriod) {
    mergedRequest.forEach((req) => {
      delete req.aggregations[0].comparisonRangeFilters;
    });
  }

  if (mergedRequest[0] && mergedRequest[0].doAggregation && !!mergedRequest[0].aggregations) {
    maybeOverrideAggregationFields(
      app.name,
      mergedRequest,
      tab || app.defaultQueryParams.tab,
      subtab || app.defaultQueryParams.subtab
    );
  }

  // If the time period is YTD and we're pulling prior period, we want the comparison time period to have
  // the same number of weeks as the main time period.
  if (hasComparisonTimePeriod && includeCompTimePeriod && mainTimePeriodId === 'ytd') {
    mergedRequest.forEach((req) => {
      const comparisonRangeFilters = _get(req, 'aggregations[0].comparisonRangeFilters');
      if (Array.isArray(comparisonRangeFilters) && pid !== 'prior-period') {
        comparisonRangeFilters.filter(propEq('fieldName', 'weekId')).forEach((compRangeFilter) => {
          compRangeFilter.minValue = mainTimePeriodStartWeek - 100;
          compRangeFilter.maxValue = mainTimePeriodEndWeek - 100;
        });
      }
    });
  }

  // Add some additional fields to indicate that this is to use the new exports pipeline
  mergedRequest.forEach((req) => {
    if (!req.additionalRequestMetaData) {
      req.additionalRequestMetaData = {};
    }
    req.additionalRequestMetaData.useNewExports = 'True';
    // Used by segment export
    req.entityType = entityType;
    req.entityName = entityName;
  });

  mergedRequest[0].tab = tab;
  mergedRequest[0].subtab = subtab;

  makeGroupByConsistent(mergedRequest, groupByFieldName);

  const updatedMergedRequest = buildExportRequest
    ? buildExportRequest({ originalRequest: mergedRequest, groupByFieldName, includeCompTimePeriod })
    : mergedRequest;

  const exportCallBack = () => {
    axios({
      method: 'post',
      url: '/api/beacon/AdvancedSearchExport',
      data: updatedMergedRequest
    }).then(
      () => {
        setShouldStartPolling(true);
      },
      (err) => {
        console.error('export service throws an error', err);
      }
    );

    trackExport(location, {}, user);
  };

  return { exportRequestFunction: exportCallBack, shouldStartPolling };
};

// https://stackline.atlassian.net/wiki/spaces/~63644528d66d8108a125f792/pages/1958084798/Compare+Export+Functionality
export const useExportDropdownOption = () => {
  const { queryParams } = useAppSelector((state) => state.app);
  const entityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const { tab, subtab } = queryParams;
  const appName = getAppName();

  const { groupByFieldNames, isAggregatable } = getExportGroupByFieldNames({
    appName,
    entityType,
    tab,
    subtab
  });

  return {
    dropdownOptions: groupByFieldNames.map((fieldName) => ({
      label: displayNameByFieldName.get(fieldName) || fieldName,
      id: fieldName
    })),
    isAggregatable
  };
};

/**
 * On the export modal, there is a section called customized, and it should be dynamically rendered by tab, subtab, and what is selected on the dropdown
 * to add or modify this, check BaseDoubleAggregatableFieldNames in exportGroupByFields.ts file.
 */
export const useExportCustomOptionBuilder = () => {
  // based on the selectedGroupBy we will show or hide Separate data by weekId option.

  const { tab, subtab } = useAppSelector((state) => state.app.queryParams);
  const getGroupByWeekAvailableFunction = (selectedGroupBy: ExportDropdownOptionIds): boolean => {
    return getDoubleAggregatableFieldNames(getAppName()).has(selectedGroupBy);
  };

  // buybox and seller should always have forceCheckBox - https://gitlab.com/stackline-dev/website/-/commit/c29d881e7eac2d723a1bc736d7bd5907ce7583f2
  const forceWeekSeparation = tab === 'buybox' && subtab === 'sellers';

  // Forecasts summary does not support comparison time period exports
  const isForecastsExport = tab === 'forecasts';
  return { getGroupByWeekAvailableFunction, forceWeekSeparation, isForecastsExport };
};
