import { CheckboxProps, SvgIconProps } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import _get from 'lodash/get';
import React, { CSSProperties } from 'react';

interface StyledCheckboxProps {
  InnerIcon: React.ComponentType<SvgIconProps>;
  OuterIcon?: React.ComponentType<SvgIconProps>;
  checkboxProps: CheckboxProps;
  styleObj?: CSSProperties & {
    iconBackgroundColor?: string;
    iconColor?: string;
    checkedIconBackgroundColor?: string;
    checkedIconColor?: string;
  };
}

export default function StyledCheckbox({ InnerIcon, checkboxProps, styleObj = {}, OuterIcon }: StyledCheckboxProps) {
  const width = _get(styleObj, 'width', 14);
  const height = _get(styleObj, 'height', 14);
  const iconBackgroundColor = _get(styleObj, 'iconBackgroundColor', '#dedede');
  const iconColor = _get(styleObj, 'iconColor', '#052849');
  const checkedIconBackgroundColor = _get(styleObj, 'checkedIconBackgroundColor', '#052849');
  const checkedIconColor = _get(styleObj, 'checkedIconColor', '#dedede');
  const paddingRight = _get(styleObj, 'paddingRight', '9px');

  const baseIconStyle = {
    width,
    height,
    backgroundColor: iconBackgroundColor,
    color: iconColor
  };

  return (
    <Checkbox
      sx={{
        paddingRight,
        padding: styleObj.padding,
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }}
      disableRipple
      color="default"
      checkedIcon={
        <InnerIcon
          style={{
            ...baseIconStyle,
            color: checkedIconColor,
            backgroundColor: checkedIconBackgroundColor
          }}
        />
      }
      icon={<OuterIcon style={baseIconStyle} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...checkboxProps}
    />
  );
}
