import _cloneDeep from 'lodash/cloneDeep';

import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { buildTrendWidgetConfig } from 'src/components/Layout/LayoutUtil';
import { buildOmniTopEntitiesWidgetConfig } from 'src/components/EntityPage/TopEntitiesChart/OmniTopEntitesChartUtil';
import { PageLayout, Widget } from 'src/types/application/widgetTypes';
import { AppName } from 'sl-api-connector/types';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import ReduxStore from 'src/types/store/reduxStore';
import OmniTrendChart from 'src/components/EntityPage/TrendChart/OmniTrendChart';
import OmniFilterWrapper from 'src/components/Omni/OmniFilter/OmniFIlterWrapper';
import { buildOmniProductsWidget } from 'src/components/EntityGrid/EntityGrid/OmniEntityGridUtils';
import { omniGridField, omniTableViewField } from 'src/components/EntityGrid/EntityGrid/omniGridField';
import { store } from 'src/main';
import PageContextMenu from 'src/components/PageContextMenu';
import { ExportIcon } from 'src/components/SvgIcons';

const { omniRegionsFollowing, omniCountriesFollowing } = store.getState();
const SHIPPING_INDEX_NAME = 'shipping';

export const getLayoutForEntity = (app: ReduxStore['app'], subtab: string, entityType: string): PageLayout => {
  const widgets: Widget[] = [];

  const indexName = SHIPPING_INDEX_NAME;
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(AppName.Omni, indexName, 'weekId'));

  const geoGroups = [
    ...(entityType !== 'retailer' && omniRegionsFollowing.length > 1 ? ['region'] : []),
    ...(entityType !== 'retailer' && omniCountriesFollowing.length > 1 ? ['country'] : []),
    'state',
    'city'
  ];

  widgets.push({
    CustomComponent: OmniFilterWrapper,
    name: 'omniFilter',
    view: {
      name: 'omniFilter',
      style: { display: 'flex', justifyContent: 'end', marginTop: 41 },
      shouldShowFilter: !(entityType === 'segment')
    },
    data: {}
  });

  const subMenuItems = [];
  if (entityType === 'segment') {
    subMenuItems.push({
      icon: ExportIcon,
      text: 'Add to Download List',
      eventName: 'downloadOmniDataSegments'
    });
  }

  //  get all widgets based off of the subtab that is being passed through
  switch (subtab) {
    case 'fulfillment':
      widgets.push(
        buildTrendWidgetConfig(
          app,
          indexName,
          { type: entityType },
          'weekId',
          ['averageFulfillmentTime'],
          weekIdField,
          {
            CustomComponent: OmniTrendChart,
            view: {
              container: {
                style: {
                  marginBottom: 100,
                  marginTop: 30,
                  verticalAlign: 'top'
                }
              }
            }
          }
        )
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          ['averageFulfillmentByOrderType'],
          ['averageFulfillmentTime'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            ['retailerId'],
            ['averageFulfillmentTime', 'averagePickUpTime', 'averageDeliveryTime', 'averageShippingTime'],
            weekIdField,
            {
              view: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                }
              }
            }
          )
        );
      }

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          ['averageFulfillmentTime', 'averagePickUpTime', 'averageDeliveryTime', 'averageShippingTime'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(
        ...buildOmniProductsWidget(omniGridField.fulfillmentTime, omniTableViewField.fulfillmentTime, false)
      );

      break;

    case 'shipping':
      widgets.push(
        buildTrendWidgetConfig(app, indexName, { type: entityType }, 'weekId', ['averageShippingTime'], weekIdField, {
          CustomComponent: OmniTrendChart,
          view: {
            container: {
              style: {
                marginBottom: 100,
                marginTop: 30,
                verticalAlign: 'top'
              }
            }
          }
        })
      );

      // in retailer entity view, we don't have to show this bar chart, since it is same value as trendCharts above
      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            ['retailerId'],
            ['averageShippingTime'],
            weekIdField,
            {
              view: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                }
              }
            }
          )
        );
      }

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          ['averageShippingTime'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(...buildOmniProductsWidget(omniGridField.shippingTime, omniTableViewField.shippingTime, false));

      if (entityType === 'segment') {
        widgets.push({
          CustomComponent: PageContextMenu,
          name: 'pageContextMenu',
          view: {
            name: 'pageContextMenu',
            buttons: subMenuItems
          },
          data: {}
        });
      }

      break;
    default:
      break;
    case 'delivery':
      widgets.push(
        buildTrendWidgetConfig(app, indexName, { type: entityType }, 'weekId', ['averageDeliveryTime'], weekIdField, {
          CustomComponent: OmniTrendChart,
          view: {
            container: {
              style: {
                marginBottom: 100,
                marginTop: 30,
                verticalAlign: 'top'
              }
            }
          }
        })
      );

      // in retailer entity view, we don't have to show this bar chart, since it is same value as trendCharts above
      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            ['retailerId'],
            ['averageDeliveryTime'],
            weekIdField,
            {
              view: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                }
              }
            }
          )
        );
      }

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          ['averageDeliveryTime'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(...buildOmniProductsWidget(omniGridField.deliveryTime, omniTableViewField.deliveryTime, false));

      if (entityType === 'segment') {
        widgets.push({
          CustomComponent: PageContextMenu,
          name: 'pageContextMenu',
          view: {
            name: 'pageContextMenu',
            buttons: subMenuItems
          },
          data: {}
        });
      }

      break;
    case 'pickup':
      widgets.push(
        buildTrendWidgetConfig(app, indexName, { type: entityType }, 'weekId', ['averagePickUpTime'], weekIdField, {
          CustomComponent: OmniTrendChart,
          view: {
            container: {
              style: {
                marginBottom: 100,
                marginTop: 30,
                verticalAlign: 'top'
              }
            }
          }
        })
      );

      // in retailer entity view, we don't have to show this bar chart, since it is same value as trendCharts above
      if (entityType !== 'retailer') {
        widgets.push(
          buildOmniTopEntitiesWidgetConfig(
            app,
            indexName,
            { type: entityType },
            ['retailerId'],
            ['averagePickUpTime'],
            weekIdField,
            {
              view: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                }
              }
            }
          )
        );
      }

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          geoGroups,
          ['averagePickUpTime'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      widgets.push(...buildOmniProductsWidget(omniGridField.pickupTime, omniTableViewField.pickupTime, false));

      if (entityType === 'segment') {
        widgets.push({
          CustomComponent: PageContextMenu,
          name: 'pageContextMenu',
          view: {
            name: 'pageContextMenu',
            buttons: subMenuItems
          },
          data: {}
        });
      }

      break;
  }

  return {
    widgets,
    CustomPageContainer: GenericPageContainer as any,
    enableComparison: false
  };
};
