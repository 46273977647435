/* eslint-disable react/prop-types */
import _pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { SlColumn, Text, useStacklineTheme } from '@stackline/ui';
import { History } from 'history';
import { withRouter } from 'react-router';
import { SlRow } from 'src/components/BeaconRedesignComponents/Generic/SlRow';
import { PageLegend } from 'src/components/BeaconRedesignComponents/Legend/PageLegend';
import OmniFilter from 'src/components/Omni/OmniFilter/OmniFilter';
import Tabs from 'src/components/common/Tabs/Tabs';
import { WidgetProps } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import { shouldShowNewBeacon } from 'src/utils/app';
import colors from 'src/utils/colors';
import { buildSubtitleDisplayName } from 'src/utils/filters';
import fontStyle from 'src/utils/fontStyle';

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    margin: '11px 0 40px',
    fontSize: '14px',
    paddingBottom: '9px',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.lightestGrey}`,
    padding: '0',
    display: 'flex',
    alignItems: 'flex-start'
  },
  omniHeaderOuterContainer: {
    marginBottom: '50px'
  },
  omniHeaderContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  legendContainer: {
    margin: '5px',
    marginTop: '10px',
    paddingTop: '16px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  tabs: { borderBottom: `1px solid ${colors.lightestGrey}` },
  // changed marginRight for Omni. this property only uses in shouldUseOmniCommonSummaryInfo === true
  tab: { padding: '13px 0px 8px 0px', fontSize: 25, marginRight: 0 },

  omniSubHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  filterContainer: {
    margin: '5px',
    marginTop: '50px',
    paddingTop: '16px'
  },
  legendContainerInOmni: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  title: {
    fontWeight: fontStyle.regularWeight,
    fontSize: 28,
    margin: '19px 0px 15px 3px'
  },
  center: {
    display: 'flex',
    alignItems: 'center'
  },
  dot: {
    height: '9px',
    width: '9px',
    borderRadius: '50%',
    margin: '10px'
  },
  titles: {
    display: 'flex',
    flexDirection: 'column'
  },
  subTitle: {
    fontWeight: 400,
    marginLeft: 3,
    marginBottom: 8
  }
};

const SummaryLegend: React.FC<{ color: string; text: string }> = ({ color, text }) => (
  <div style={styles.center}>
    <div className="summary_legend_dot" style={{ ...styles.dot, background: color }} />
    {text}
  </div>
);

SummaryLegend.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, [
    'app',
    'retailer',
    'entityService',
    'mainTimePeriod',
    'comparisonTimePeriod',
    'user',
    'filters',
    'categories'
  ]);

const TitleAndSubTitle: React.FC<{ titleContent: React.ReactNode; subTitleContent?: string | null }> = ({
  titleContent,
  subTitleContent
}) => (
  <div style={styles.titles}>
    <h2 style={{ ...styles.title }}>{titleContent}</h2>
    {subTitleContent ? <span style={styles.subTitle}>{subTitleContent}</span> : null}
  </div>
);

const CommonSummaryInfo = ({
  app,
  entityService,
  mainTimePeriod,
  comparisonTimePeriod,
  widget,
  retailer,
  filters,
  categories,
  history,
  includeLegend = false,
  includeSubtitle = true
}: WidgetProps &
  ReturnType<typeof mapStateToProps> & { history: History; includeLegend?: boolean; includeSubtitle?: boolean }) => {
  const baseEntityName = entityService.mainEntity ? (entityService.mainEntity as any).name : '';
  const comparisonEntityName = entityService.comparisonEntity ? (entityService.comparisonEntity as any).name : '';
  const mainTimePeriodDisplayName = mainTimePeriod.displayName;
  const comparisonTimePeriodDisplayName = comparisonTimePeriod.displayName;
  const mainEntityName = app.queryParams.ctype && comparisonEntityName ? baseEntityName : mainTimePeriodDisplayName;
  const comparisonEntityDisplayName =
    app.queryParams.ctype && comparisonEntityName ? comparisonEntityName : comparisonTimePeriodDisplayName;

  const mainColor = colors.stacklineBlue;
  const compareColor = colors.comparison;

  const theme = useStacklineTheme();

  const titleContent = widget.view.title || 'Summary';

  const { shouldShowFilter, showComparePeriod, disableLegend } = widget.view;
  const tabs =
    widget.data && widget.data.tabs
      ? widget.data.tabs
      : [
          {
            name: 'summary',
            displayName: 'Summary'
          }
        ];

  const subTitleContent = useMemo(() => {
    return widget.view.buildSubTitle ? (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: widget.view.buildSubTitle({
            retailer,
            mainEntity: entityService.mainEntity,
            filters,
            categories,
            app
          })
        }}
      ></div>
    ) : null;
  }, [app, categories, entityService.mainEntity, filters, retailer, widget.view]);

  if (shouldShowNewBeacon()) {
    const subtitleObj = widget.view.buildSubTitle
      ? widget.view.buildSubTitle({
          retailer,
          mainEntity: entityService.mainEntity,
          filters,
          categories,
          app
        })
      : {};

    const { href, subtitleDisplayName, entityDisplayName } = subtitleObj;

    const title = (
      <SlColumn spacing="sm">
        {typeof widget.view.title === 'string' ? <Text variant="h4">{widget.view.title}</Text> : widget.view.title}
        {includeSubtitle &&
          (href ? (
            <Text variant="body2" link onClick={() => history.push(href)}>
              {subtitleDisplayName || entityDisplayName}
            </Text>
          ) : (
            <Text variant="body2">{subtitleDisplayName || entityDisplayName}</Text>
          ))}
      </SlColumn>
    );

    if (includeLegend) {
      return (
        <SlRow style={{ marginTop: theme.spacing.lg }} horizontalPosition="space-between">
          {title}
          <PageLegend />
        </SlRow>
      );
    }

    return title;
  }

  const { shouldUseOmniCommonSummaryInfo } = app;
  return shouldUseOmniCommonSummaryInfo ? (
    <div style={styles.omniHeaderOuterContainer}>
      <div style={styles.omniHeaderContainer}>
        <div style={{ marginRight: 'auto' }}>
          <Tabs tabs={tabs} value={0} tabStyle={styles.tab} tabRootStyle={styles.tabs} onTabChange={() => {}} />
        </div>
        <OmniFilter shouldShowSegmentFilter={false} shouldShowFilter={shouldShowFilter} />
      </div>
      <hr className="sl-divider sl-divider--no-margin-top" style={{ marginBottom: 10 }} />
      <div style={styles.omniSubHeader}>
        <span style={styles.subTitle}>{subTitleContent}</span>
        <div style={{ ...styles.legendContainerInOmni }}>
          <SummaryLegend color={mainColor} text={mainEntityName} />
          {showComparePeriod ? <SummaryLegend color={compareColor} text={comparisonEntityDisplayName} /> : null}
        </div>
      </div>
    </div>
  ) : (
    <div className="atlas_summary_info" style={{ ...styles.root }}>
      <TitleAndSubTitle titleContent={titleContent} subTitleContent={subTitleContent} />

      {disableLegend ? null : (
        <div style={{ ...styles.legendContainer }}>
          <SummaryLegend color={mainColor} text={mainEntityName} />
          <SummaryLegend color={compareColor} text={comparisonEntityDisplayName} />
        </div>
      )}
    </div>
  );
};

const EnhancedCommonSummaryInfo = withRouter(connect(mapStateToProps)(CommonSummaryInfo));

export const EnhancedCommonSummaryInfoWithLegend = (
  props: React.ComponentPropsWithoutRef<typeof EnhancedCommonSummaryInfo>
) => {
  // @ts-expect-error
  return <EnhancedCommonSummaryInfo {...props} includeLegend />;
};

export default EnhancedCommonSummaryInfo;

export const CommonSummaryInfoSubtitle = ({
  title,
  subtitleOverride = null,
  includeLegend,
  includeSubtitle
}: {
  title: React.ReactNode;
  subtitleOverride?: { subtitleDisplayName: string; href: string };
  includeLegend?: boolean;
  includeSubtitle?: boolean;
}) => {
  return (
    <EnhancedCommonSummaryInfo
      // @ts-expect-error
      includeLegend={includeLegend}
      includeSubtitle={includeSubtitle}
      widget={{
        view: {
          name: 'CommonSummaryInfo',
          // eslint-disable-next-line no-shadow
          buildSubTitle: ({ retailer, mainEntity, filters, categories, app }) =>
            subtitleOverride ||
            (mainEntity ? buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app) : 'Summary'),
          title
        }
      }}
    />
  );
};
