import React, { useState } from 'react';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import {
  ProductColumnDefinition,
  CampaignColumnDefinition,
  KeywordColumnDefinition,
  CampaignTypeColumnDefinition,
  CampaignTypeForCampaignColumnDefinition,
  CampaignStatusColumnDefinition,
  getWeekColumnDefinition
} from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import { Text } from '@stackline/ui';
import { useTotalProductCount } from 'src/components/BeaconRedesignComponents/MetricList/entityCountHooks';
import { useConvertPaidTrafficFieldName } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Traffic/PaidSearch/paidSearchUtil';
import { useProductEntity } from 'src/utils/Hooks/reduxSelectors';
import { removeComparisonFormatter } from 'src/components/BeaconRedesignComponents/MetricList/utils';

enum DropdownOption {
  Product = 'stacklineSku',
  Keyword = 'searchKeyword',
  Campaign = 'campaignId',
  CampaignType = 'campaignType'
}

const dropdownOptionToColumnDefinition = {
  [DropdownOption.Product]: ProductColumnDefinition,
  [DropdownOption.Campaign]: [
    CampaignColumnDefinition,
    CampaignTypeForCampaignColumnDefinition,
    CampaignStatusColumnDefinition
  ],
  [DropdownOption.Keyword]: KeywordColumnDefinition,
  [DropdownOption.CampaignType]: CampaignTypeColumnDefinition
};

const dropdownOptionToDisplayName = {
  [DropdownOption.Keyword]: 'Keyword',
  [DropdownOption.Product]: 'Product',
  [DropdownOption.Campaign]: 'Campaign',
  [DropdownOption.CampaignType]: 'Campaign Type'
};

const fields = [
  {
    name: 'clicks',
    displayName: 'Ad\nClicks'
  },
  {
    name: 'spend',
    displayName: 'Ad\nSpend'
  },
  {
    name: 'sales',
    displayName: 'Ad\nSales'
  },
  {
    name: 'unitsSold',
    displayName: 'Ad\nUnits Sold'
  },
  {
    name: 'returnOnAdSpend'
  }
];

/**
 * Results grid for Traffic Paid Search
 */
export default function PaidSearchGrid() {
  const [dropdownOption, setDropdownOption] = useState<DropdownOption>(DropdownOption.Keyword);
  const { count, isLoading } = useTotalProductCount();
  const { convertedBuilderFunc } = useConvertPaidTrafficFieldName();

  const { isProductEntity } = useProductEntity();
  const weekEndingFields = fields.map(removeComparisonFormatter);

  return isProductEntity ? (
    <MetricListContainer
      entityTableHeaderProps={{
        title: 'Ad Metrics by Week'
      }}
      indexName="advertising"
      sortFieldName="clicks"
      fields={weekEndingFields}
      headColumnDefinition={getWeekColumnDefinition({
        getTableColumnProps(defaults) {
          return {
            ...defaults,
            style: {
              ...defaults.style,
              width: '220px'
            }
          };
        }
      })}
      postProcessData={(data) => {
        return data.sort((a, b) => b.weekId.localeCompare(a.weekId));
      }}
      useTableDataProps={{
        groupByFieldName: 'weekId',
        filterResponse: () => true,
        itemsPerPage: 100
      }}
      showGrid={false}
    />
  ) : (
    <MetricListContainer
      showGrid={false}
      rowCount={dropdownOption === DropdownOption.Product ? count : undefined}
      entityTableHeaderProps={{
        prefixTitle: 'Ad Metrics by',
        title: '',
        groupByFields: [
          DropdownOption.Keyword,
          DropdownOption.Product,
          DropdownOption.Campaign,
          DropdownOption.CampaignType
        ].map((option) => ({
          name: option,
          displayName: dropdownOptionToDisplayName[option]
        })),
        handleGroupByChange: (event) => setDropdownOption(event.target.value as DropdownOption)
      }}
      indexName="advertising"
      fields={[
        ...(dropdownOption !== DropdownOption.Campaign
          ? [
              {
                name: 'campaignId',
                displayName: 'Campaigns',
                comparisonFormatter: (_, difference: number) => {
                  return (
                    <Text variant="body2" color={difference >= 0 ? 'success' : 'error'}>{`(${
                      difference >= 0 ? '+' : ''
                    }${difference})`}</Text>
                  );
                }
              }
            ]
          : []),
        ...fields
      ]}
      sortFieldName="clicks"
      headColumnDefinition={dropdownOptionToColumnDefinition[dropdownOption]}
      loading={dropdownOption === DropdownOption.Product ? isLoading : false}
      useTableDataProps={{
        groupByFieldName: dropdownOption,
        filterResponse: () => true
      }}
      buildRequest={(builder) => convertedBuilderFunc(builder)}
    />
  );
}
