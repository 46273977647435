import axios from 'axios';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';

export interface AdjustmentPostRequest {
  isDraft: boolean;
  beaconClientId: number;
  retailerId: string | number;
  retailerSku: string | number;
  adjustmentTitle: string;
  adjustmentDescription: string;
  planType: PlanTypeOption;
  adjustmentChangeType: string;
  adjustmentChangeValue: string | number;
  userId: string;
  startWeekId: string;
  endWeekId: string;
  saveAsDraft: boolean;
  draftAdjustment: {
    adjustmentId: string;
    publishFromDraft: boolean;
  };
  additionalProperties: any;
}

export interface PostAdjustmentParams {
  adjustmentPostRequest: AdjustmentPostRequest;
}

export default async function createAdjustment({ adjustmentPostRequest }: PostAdjustmentParams) {
  return axios.post(`api/beaconforecast/CreateAdjustments`, [adjustmentPostRequest]);
}
