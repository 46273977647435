import React, { useEffect, useMemo } from 'react';
import { AppTable, ExpandRowColumnLayout } from '@stackline/ui';
import ReduxStore from 'src/types/store/reduxStore';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import axios from 'axios';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  styleOverrides: {
    '& .MuiTableCell-head': {
      textTransform: 'none',
      fontSize: '18px',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: '#052849'
    },
    '& .MuiTableCell-body': {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.5',
      letterSpacing: 'normal',
      color: '#052849'
    },
    '& .customer-rating-details': {
      fontSize: '16px'
    }
  }
}));

const COL_DEFINITIONS = [
  {
    field: 'creationDate',
    headerName: 'Creation Date',
    tableColumnProps: {
      align: 'left'
    }
  },
  {
    field: 'id',
    headerName: 'ID',
    tableColumnProps: {
      align: 'left'
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    tableColumnProps: {
      align: 'left'
    }
  },
  {
    field: 'primaryEmail',
    headerName: 'Primary E-mail',
    tableColumnProps: {
      align: 'left'
    }
  },

  {
    field: 'link',
    headerName: 'Link to Contact Us Case',
    tableColumnProps: {
      align: 'left'
    },
    valueFormatter: (str) => (
      <a target="_blank" rel="noreferrer" href={str}>
        {str}
      </a>
    )
  },
  {
    field: '',
    headerName: '',
    tableColumnProps: {
      align: 'left'
    },
    expandRow: true
  }
];

const EXP_COL_DEFINITIONS = {
  containerClassName: 'customer-rating-details',
  columns: {
    shortDescription: {
      title: 'Short Description',
      layout: ExpandRowColumnLayout.Section,
      fields: {
        shortDescription: {}
      }
    },
    financialDetails: {
      title: 'Communication Text',
      layout: ExpandRowColumnLayout.Section,
      fields: {
        communicationText: {}
      }
    }
  }
};

interface ContactUsDetails {
  creationDate: number;
  caseId: string;
  status: string;
  primaryEmail: string;
  link: string;
  shortDescription: string;
  communicationText: string;
}

const fetchContactUsCaseDetails = async ({ headers, beaconClientId, id, invoiceId }) => {
  const contactUsCaseResponse = await axios.post(
    `api/beacon/ContactUsCaseDetails`,
    {
      beaconClientId,
      retailerId: id,
      originalInvoiceNumber: invoiceId
    },
    headers
  );
  const contactUsData = _get(contactUsCaseResponse, 'data', []);
  return contactUsData as ContactUsDetails;
};

const formatDataForTable = (data: ContactUsDetails) => {
  // Do we need a fallback link? https://vendorcentral.amazon.com/

  if (data) {
    const creationDate = new Date(data.creationDate * 1000);
    return [
      {
        creationDate: `${creationDate}`,
        id: data.caseId,
        status: data.status,
        primaryEmail: data.primaryEmail,
        link: data.link,
        shortDescription: data.shortDescription,
        communicationText: data.communicationText
      }
    ];
  }
  return [];
};

const ContactUsCaseDetails = ({ queryClient, invoiceId }: { queryClient: any; invoiceId: string }) => {
  const { user, retailer } = useSelector((state: ReduxStore) => state);
  const { email } = user.session;
  const beaconId = _get(user, 'config.vendor.BeaconClientId', null);
  const { id } = retailer;
  const classes = useStyles();

  const headers = {
    'x-sl-ui-user-email': email,
    'sl-beacon-retailer-id': id || 1
  };

  const { isLoading, data } = useQuery(
    ['fetchContactUsDetails'],
    () => fetchContactUsCaseDetails({ headers, beaconClientId: beaconId, id, invoiceId }),
    {
      retry: 0,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always'
    }
  );

  const rows = useMemo(() => {
    return formatDataForTable(data);
  }, [data]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['fetchContactUsDetails'] });
  }, []);

  return (
    <>
      <AppTable
        className={classes.styleOverrides}
        loading={isLoading}
        columns={COL_DEFINITIONS}
        rows={rows}
        expandRowDef={EXP_COL_DEFINITIONS}
        noDataPlaceholder={<h2>No Data Available</h2>}
        sx={{ marginBottom: '100px' }}
        hidePagination
      />
    </>
  );
};

export default ContactUsCaseDetails;
