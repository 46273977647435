/**
 * Enum representing index names that determine which resource an API request will access
 * as well as which fields we have access to.
 */
export enum BEACON_INDEX_NAMES {
  SALES = 'sales',
  SALES_BY_PLATFORM = 'salesbyplatform',
  TRAFFIC = 'traffic',
  TRAFFIC_ALL = 'traffic-all',
  ADVERTISING = 'advertising',
  ADVERTISING_DISPLAY = 'advertisingDisplay',
  CHARGEBACKS = 'chargebacks',
  REVIEWS = 'reviews',
  CONTENT = 'content',
  CONTENT_CHANGED = 'contentChanged',
  BUYBOX = 'buybox',
  COMPLIANCE_KEYWORDAD = 'compliance-keywordad',
  COMPLIANCE_KEYWORDADITEM = 'compliance-keywordaditem',
  COMPLIANCE_SELLERITEM = 'compliance-selleritem',
  COMPLIANCE_CATALOGITEM = 'compliance-catalogitem',
  CONVERSION = 'conversion',
  OPERATIONS = 'operations',
  PRODUCT_WEEKLY_METRICS = 'productWeeklyMetrics',
  SHORTAGE_DISPUTE = 'shortage-dispute',
  SD_INVOICE_METRICS = 'sd-invoiceMetrics',
  SD_ASIN_DETAIL_METRICS = 'sd-asinDetailMetrics',
  UNADJUSTED_FORECAST = 'unadjusted-forecast',
  ACTUAL_YOY_UNITS_CHANGE = 'actual-yoy-units-change',
  UNADJUSTED_YOY_UNITS_CHANGE = 'unadjusted-yoy-units-change',
  ADJUSTED_YOY_UNITS_CHANGE = 'adjusted-yoy-units-change',
  /**
   * Used for:
   * - Forecasting: Gets accurate Rating values for the forecast summary and plans page
   */
  RATINGS_REVIEWS_METRICS = 'ratings-reviews-metrics',
  NEW_CONTENT_METRICS = 'new-content-metrics',

  /**
   * Used for: Promotion summary page
   */
  PROMOTIONS = 'promotions'
}
