/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import queryString from 'qs';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import _pick from 'lodash/pick';
import { Entity } from 'sl-api-connector/types';
import { withBus } from 'react-bus';

import { store } from 'src/main';
import {
  BrandFilter,
  CategoryFilter,
  RetailPriceFilter,
  SegmentFilter,
  SubCategoryFilter,
  PromoTypeFilter,
  ChargebacksFilter,
  VendorCodesFilter,
  ChargeBackStatusesFilter,
  RetailerFilter
} from '.';
import { updateQueryParams } from 'src/store/modules/app/operations';
import { filterSelectors } from 'src/store/modules/filters';
import ClearAllFiltersButton from 'src/components/common/Buttons/ClearAllFiltersButton';
import fontStyle from 'src/utils/fontStyle';
import { buildBusinessUnitFilters } from './businessUnitFilters';
import ReduxStore from 'src/types/store/reduxStore';
import { updateFilters } from 'src/store/modules/filters/operations';
import { filterNils } from 'sl-api-connector/util';

const categoryFilter = { id: 1, component: CategoryFilter };
const subCategoryFilter = { id: 2, component: SubCategoryFilter };
const segmentFilter = { id: 3, component: SegmentFilter };
const retailPriceFilter = { id: 4, component: RetailPriceFilter };
const brandFilter = { id: 5, component: BrandFilter };
const promoTypeFilter = { id: 6, component: PromoTypeFilter };
const chargebacksFilter = { id: 7, component: ChargebacksFilter };
const vendorCodesFilter = { id: 8, component: VendorCodesFilter };
const chargeBackStatusesFilter = { id: 9, component: ChargeBackStatusesFilter };
const retailerFilter = { id: 10, component: RetailerFilter };

/**
 * This map determines which filters should be shown on different kinds of pages on the different applications.
 */
const filterSectionsEntityType: {
  [appName: string]: {
    [entityType: string]: { id: number; component: React.ComponentType }[];
  };
} = {
  atlas: {
    brand: [categoryFilter, subCategoryFilter, segmentFilter, retailPriceFilter],
    company: [categoryFilter, subCategoryFilter, segmentFilter, retailPriceFilter],
    category: [subCategoryFilter, segmentFilter, retailPriceFilter],
    client: [categoryFilter, subCategoryFilter],
    subcategory: [segmentFilter, retailPriceFilter],
    product: [segmentFilter, retailPriceFilter],
    businessunit: [retailPriceFilter],
    segment: [retailPriceFilter],
    allcategories: [segmentFilter, retailPriceFilter]
  },
  beacon: {
    client: [categoryFilter, subCategoryFilter, segmentFilter, retailPriceFilter],
    brand: [categoryFilter, subCategoryFilter, segmentFilter, retailPriceFilter],
    product: []
  },
  advertising: {
    client: [categoryFilter, subCategoryFilter, segmentFilter],
    brand: [categoryFilter, subCategoryFilter, segmentFilter],
    product: []
  },
  omni: {
    client: [categoryFilter, subCategoryFilter]
  }
};

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    padding: '20px 15px 40px 15px'
  },
  topWrapper: {
    overflow: 'hidden'
  },
  header: {
    fontWeight: fontStyle.regularWeight,
    marginBottom: 0,
    fontSize: 20,
    float: 'left',
    display: 'inline-block'
  }
};

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, [
    'app',
    'categories',
    'comparisonTimePeriod',
    'filters',
    'mainTimePeriod',
    'segments',
    'subCategories',
    'retailer',
    'user'
  ]);

type FilterContainerProps = { entity: Entity; eventBus: any } & ReturnType<typeof mapStateToProps> &
  RouteComponentProps;

const handleClearFilters = async (
  _event: any,
  { app, comparisonTimePeriod, mainTimePeriod, location, retailer, history, filters }: FilterContainerProps
) => {
  // TODO: Abstract query params parsing
  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  await store
    .dispatch(updateQueryParams(app, retailer, mainTimePeriod, comparisonTimePeriod, queryParams))
    .then((response: { params: { additionalParams: string; searchParams: string } }) => {
      const { additionalParams, searchParams } = response.params;
      history.push(`${searchParams}${additionalParams}`);

      // Clear out all of the filters
      Object.keys(filters).forEach((key) => {
        filters[key] = null;
      });

      store.dispatch(updateFilters(filters));
    });
};

const FilterContainer: React.FC<FilterContainerProps> = (props) => {
  const { categories, filters, entity, app, segments, history, location, user, retailer } = props;
  const onFilterChange = useCallback(
    (filterValue: any, filterType: string) => {
      const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
      const { searchParams, additionalParams } = app.queryParams;
      const { generatedFilterParams } = filterSelectors.generateFilterUrlString(filterValue, filterType, queryParams);
      // we should always clear compare on filter change, which is why we do not include in this URL
      history.push(`${searchParams}${additionalParams}${generatedFilterParams}`);
    },
    [app.queryParams, history, location.search]
  );

  const isLoading = !app || !entity || _isEmpty(categories) || !segments.savedSearchesById;

  const filtersForCurrentPage = useMemo(() => {
    if (isLoading || user.config.isRestrictedDemoUser) {
      return null;
    }

    const baseFiltersForPage = _get(filterSectionsEntityType, [app.name, entity.type]);

    // Add in category filter only when on the "all categories" page.
    const isOnAllCategoriesPage = (() => {
      if (typeof location.pathname !== 'string') {
        return false;
      }

      const splitPath = location.pathname.split('/');
      return _last(splitPath) === '0';
    })();

    const isOnPromotionsPage = location.search.includes('tab=promotions');

    const isOnOperationsPage = location.search.includes('tab=operations');

    const isOnShortagesSubTab =
      location.search.includes('subtab=shortages') || location.search.includes('subtab=disputes');
    const isOnDisputeManagementSubTab = location.search.includes('subtab=disputes');

    const operationsFilters =
      isOnOperationsPage && !isOnShortagesSubTab
        ? [chargebacksFilter, vendorCodesFilter, chargeBackStatusesFilter]
        : [null];

    const filtersForPage = filterNils([
      ...buildBusinessUnitFilters(
        app,
        segments.businessUnits,
        segments.savedSearchesById!,
        new Set(filters.businessUnits)
      ),
      isOnAllCategoriesPage ? categoryFilter : null,
      ...operationsFilters,
      // Promo type filter does not work for documents
      // Should be enabled once Abhijeet finishes API refactor
      // Keeping it to only be on dev for now
      isOnPromotionsPage && app.stage === 'dev' ? promoTypeFilter : null
    ]);
    if (!isOnDisputeManagementSubTab) {
      filtersForPage.push(...(baseFiltersForPage || []));
    }

    return filtersForPage;
  }, [
    isLoading,
    app,
    entity.type,
    filters.businessUnits,
    segments.businessUnits,
    segments.savedSearchesById,
    location.pathname,
    user,
    location.search // reason for eslint-disable, need to detect filter changes in URL
  ]);
  if (isLoading || !filtersForCurrentPage || _isEmpty(filtersForCurrentPage)) {
    return null;
  }

  let filtersToUse = filtersForCurrentPage;

  if (retailer.id === '0') {
    filtersToUse = [categoryFilter, subCategoryFilter, brandFilter, retailerFilter];
  }

  return (
    <section style={styles.root} className="nav-container">
      <div style={styles.topWrapper}>
        <h2 style={styles.header}>Filters</h2>
        <ClearAllFiltersButton
          categories={categories}
          filters={filters}
          handleClearFilters={(event, passedProps) => handleClearFilters(event, passedProps || props)}
        />
      </div>
      {filtersToUse.map(({ component: RenderedComponent, ...filterProps }, idx) => (
        // id is not unique, so use index for key
        <RenderedComponent {...filterProps} key={idx} entity={entity} onFilterChange={onFilterChange} />
      ))}
    </section>
  );
};

const ComponentWithRedux = connect(mapStateToProps)(FilterContainer);
const EnhancedFilterContainer = withRouter(withBus('eventBus')(ComponentWithRedux));

export default EnhancedFilterContainer;
