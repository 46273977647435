import React, { FC, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import TimePeriodDropdown from 'src/components/BeaconRedesignComponents/common/TimePeriodDropdown';
import ComparisonPeriodDropdown from 'src/components/BeaconRedesignComponents/common/ComparisonPeriodDropdown';
import {
  BEACON_SUBTABS,
  useBeaconRoutes
} from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';
import { withRouter } from 'react-router';
import { ProductGrowthFilterButton } from 'src/components/BeaconRedesignComponents/ProductGrowthModal/ProductGrowthFilterButton';
import {
  ForecastPeriodDropdown,
  ForecastPlanTypeDropdown,
  ForecastTypeDropdown
} from '../ExperimentalLayout/Forecasts/dropdowns';
import { getQueryParamValue } from 'src/utils/app';
import { Text } from '@stackline/ui';
import { AppFilterButton } from 'src/components/BeaconRedesignComponents/common/AppFilterButton/AppFilterButton';
import { useAppSelector } from 'src/utils/Hooks';

const useStyles = makeStyles({
  commonHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  subtabTitleText: {
    fontFamily: 'Roboto',
    fontSize: '26px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.19px',
    textAlign: 'left',
    color: '#052849'
  },
  buttonGroup: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center'
  }
});

interface SubtabHeaderProps {
  hideButtons?: boolean;
  location: any;
  overrideLabel?: string;
}

const SubtabHeader: FC<SubtabHeaderProps> = ({ hideButtons, overrideLabel }) => {
  const classes = useStyles();
  const currentTab = getQueryParamValue('tab');
  const { currentRoute, currentSubtab } = useBeaconRoutes();
  // Get the current sidebar tab label
  const { label } = currentRoute;

  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);

  const subtabButtonMap = useMemo(() => {
    let filterElements = [
      currentSubtab === BEACON_SUBTABS.GROWTH_MATRIX ? (
        <ProductGrowthFilterButton key="productGrowthFilterButton" />
      ) : (
        <AppFilterButton key="appFilterButton" />
      )
    ];

    // Hide filter at product level, since none would be applicable
    if (mainEntity.type === 'product') {
      filterElements = [];
    }

    // Hide filter on Dispute pages, since none would be applicable
    if (currentSubtab === BEACON_SUBTABS.DISPUTES) {
      filterElements = [];
    }

    return {
      forecasts: [
        <ForecastPeriodDropdown key="forecastPeriodDropdown" />,
        <Text variant="subtitle2" key="vsText">
          vs.
        </Text>,

        <ComparisonPeriodDropdown key="comparisonPeriodDropdown" />,

        // Adjusted forecast / unadjusted forecast dropdown
        currentSubtab !== BEACON_SUBTABS.FORECAST_ADJUSTMENTS ? (
          <ForecastTypeDropdown key="forecastTypeDropdown" />
        ) : null,

        // Organic Traffic / Paid Traffic / Content Score etc. dropdown
        currentSubtab === BEACON_SUBTABS.FORECAST_ADJUSTMENTS ? (
          <ForecastPlanTypeDropdown key="forecastPlanTypeDropdown" />
        ) : null,

        ...filterElements
      ],
      // Add more subtabs and their corresponding buttons here

      default: [
        <TimePeriodDropdown key="timePeriodDropdown" />,
        <ComparisonPeriodDropdown key="comparisonPeriodDropdown" />,
        ...filterElements
      ]
    };
  }, [currentSubtab, mainEntity.type]);

  const buttonsToRender = subtabButtonMap[currentTab] || subtabButtonMap.default;

  return (
    <div className={classes.commonHeaderContainer}>
      <div className={classes.subtabTitleText}>{overrideLabel || label}</div>
      {hideButtons ? null : (
        <div className={classes.buttonGroup}>
          {buttonsToRender.map((button, index) => {
            return <React.Fragment key={index}>{button}</React.Fragment>;
          })}
        </div>
      )}
    </div>
  );
};

export default withRouter(SubtabHeader);
