import React, { useState, memo } from 'react';
import './Search.scss';
import { getQueryParamValue } from 'src/utils/app';
import { SlRow, Text } from '@stackline/ui';
import Box from '@mui/material/Box';
import { GenericNavigationTabs } from '../BeaconRedesignComponents/SubtabNav/SubtabNavigation';
import { SegmentDeletionModal } from '../BeaconRedesignComponents/ExperimentalLayout/Segments/SegmentDeletionModal';
import SegmentBuilder from '../BeaconRedesignComponents/ExperimentalLayout/Segments/SegmentBuilder';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import { useLocation } from 'src/utils/Hooks';
import queryString from 'qs';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

const SEARCH_TERM_LIST = 'searchtermlist';
const SEGMENT = 'segment';

interface BeaconProSearchContainerProps {
  eventBus: EventBus;
}

/**
 * Search page for Beacon Pro
 */
export default withBus('eventBus')(
  memo(function BeaconProSearchContainer({ eventBus }: BeaconProSearchContainerProps) {
    const [deleteSegmentModalOpen, setDeleteSegmentModalOpen] = useState(false);
    const [segmentToDelete, setSegmentToDelete] = useState(null);

    const location = useLocation();
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 520 });
    const segmentIdBeingEdited = getQueryParamValue('id'); // We can't depend on the queryParams passed in as props as they do not accurately reflect the URL
    const entityType = getQueryParamValue('entityType');
    const isTopicSegment = entityType === 'searchtermlist';
    const pageTitle = isTopicSegment ? 'Topic Builder' : 'Segment Builder';

    return (
      <Box display="flex" flexDirection="row" gap="24px">
        <Box display="flex" flexDirection="column" width="100%">
          <SlRow horizontalPosition="space-between">
            <Text variant="h2">{pageTitle}</Text>
            {segmentIdBeingEdited && (
              <SlButton
                disabled={!segmentIdBeingEdited}
                onClick={() => {
                  setDeleteSegmentModalOpen(true);
                  setSegmentToDelete(queryParams.id);
                }}
              >
                Delete
              </SlButton>
            )}
          </SlRow>
          <Box marginBottom="32px">
            <GenericNavigationTabs
              tab={isTopicSegment ? SEARCH_TERM_LIST : SEGMENT}
              tabs={[
                {
                  label: 'Product',
                  value: SEGMENT,
                  onClick: () => {
                    // Triggers this callback which will update the query params between segment and keyword
                    // https://gitlab.com/stackline-dev/website/-/blob/61c71c3a6383b4f25cf4e95d66c9c003ef7362fd/src/components/Search/AdvancedSearch/AdvancedSearchSideBar/AdvancedSearchSideBar.js#L299
                    eventBus.emit('handleEntityTypeSwitching', { value: SEGMENT });
                  }
                },
                {
                  label: 'Keyword',
                  value: SEARCH_TERM_LIST,
                  onClick: () => {
                    // Triggers this callback which will update the query params between segment and keyword
                    // https://gitlab.com/stackline-dev/website/-/blob/61c71c3a6383b4f25cf4e95d66c9c003ef7362fd/src/components/Search/AdvancedSearch/AdvancedSearchSideBar/AdvancedSearchSideBar.js#L299
                    eventBus.emit('handleEntityTypeSwitching', { value: SEARCH_TERM_LIST });
                  }
                }
              ]}
            />
          </Box>
          {/* Position correctly since table has padding */}
          <Box marginTop={isTopicSegment ? '-12px' : undefined}>
            <SegmentBuilder topics={isTopicSegment} />
          </Box>
        </Box>
        {deleteSegmentModalOpen && (
          <div style={{ position: 'absolute' }}>
            <SegmentDeletionModal
              isTopicSegment={isTopicSegment}
              segmentToDelete={segmentToDelete}
              open={deleteSegmentModalOpen}
              handleClose={() => {
                setDeleteSegmentModalOpen(false);
                setSegmentToDelete(null);
              }}
            />
          </div>
        )}
      </Box>
    );
  })
);
