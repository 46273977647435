import queryString, { ParsedQs } from 'qs';
import { store } from 'src/main';
import ReduxStore from 'src/types/store/reduxStore';
import _isEmpty from 'lodash/isEmpty';
import { StoreListSupportedRetailer } from 'src/store/modules/retailer/reducers';

const mappingToShortKey: { [key: string]: string } = {
  name: 'n',
  queryId: 'qId',
  includeCategoryIds: 'ic',
  excludeCategoryIds: 'ec',
  includeSubCategoryIds: 'isc',
  excludeSubCategoryIds: 'esc',
  includeKeywords: 'k',
  excludeKeywords: 'ek',
  includeBrandIds: 'ib',
  excludeBrandIds: 'eb',
  retailerIds: 'r',
  includeLocationKeywords: 'ilk',
  excludeLocationKeywords: 'elk',
  includeLocationCountryCode: 'ilcc',
  excludeLocationCountryCode: 'elcc',
  includeLocationRegionCode: 'ilrc',
  excludeLocationRegionCode: 'elrc',
  includeLocationCity: 'ilci',
  excludeLocationCity: 'elci',
  includeLocationCounty: 'ilco',
  excludeLocationCounty: 'elco',
  includeLocationCBSAName: 'ilCBSA',
  excludeLocationCBSAName: 'elCBSA',
  includeLocationStateName: 'ils',
  excludeLocationStateName: 'els',
  includePhrases: 'ip',
  excludePhrases: 'ep',
  includeStoreIds: 'ist',
  excludeStoreIds: 'ext'
};

export interface SegmentState {
  name: string | undefined;
  queryId: string | undefined;
  includeCategoryIds: number[];
  excludeCategoryIds: number[];
  includeSubCategoryIds: number[];
  excludeSubCategoryIds: number[];
  includeKeywords: string[];
  excludeKeywords: string[];
  includeBrandIds: string[];
  excludeBrandIds: string[];
  retailerIds: number[];
  includeLocationKeywords: string[];
  excludeLocationKeywords: string[];
  includeLocationCountryCode: string[];
  excludeLocationCountryCode: string[];
  includeLocationRegionCode: string[];
  excludeLocationRegionCode: string[];
  includeLocationCity: string[];
  excludeLocationCity: string[];
  includeLocationCounty: string[];
  excludeLocationCounty: string[];
  includeLocationCBSAName: string[];
  excludeLocationCBSAName: string[];
  includeLocationStateName: string[];
  excludeLocationStateName: string[];
}

export interface KeyWordSegment {
  name: string | undefined;
  queryId: string | undefined;
  includeKeywords: string[];
  excludeKeywords: string[];
  includePhrases: string[];
  excludePhrases: string[];
}

export interface StoreSegment {
  name: string | undefined;
  queryId: string | undefined;
  retailerIds: number[];
  includeStoreIds: string[];
  excludeStoreIds: string[];
  includeBrandIds: string[];
  excludeBrandIds: string[];
  includeCategoryIds: number[];
  excludeCategoryIds: number[];
  includeSubCategoryIds: number[];
  excludeSubCategoryIds: number[];
  includeLocationCountryCode: string[];
  excludeLocationCountryCode: string[];
  includeLocationRegionCode: string[];
  excludeLocationRegionCode: string[];
  includeLocationCBSAName: string[];
  excludeLocationCBSAName: string[];
  includeLocationStateName: string[];
  excludeLocationStateName: string[];
}

const mappingToFullKey: { [key: string]: string } = {};
Object.keys(mappingToShortKey).forEach((fullKey) => {
  mappingToFullKey[mappingToShortKey[fullKey]] = fullKey;
});

export const fillSegmentFieldInURL = (
  param: ParsedQs,
  segmentType: string
): SegmentState | KeyWordSegment | StoreSegment => {
  let filledFiled: SegmentState | KeyWordSegment | StoreSegment = {
    name: undefined,
    queryId: undefined,
    includeCategoryIds: [],
    excludeCategoryIds: [],
    includeSubCategoryIds: [],
    excludeSubCategoryIds: [],
    includeKeywords: [],
    excludeKeywords: [],
    includeBrandIds: [],
    excludeBrandIds: [],
    retailerIds: [],
    includeLocationKeywords: [],
    excludeLocationKeywords: [],
    includeLocationCountryCode: [],
    excludeLocationCountryCode: [],
    // includeLocationRegionCode: [],
    // excludeLocationRegionCode: [],
    includeLocationCity: [],
    excludeLocationCity: [],
    includeLocationCounty: [],
    excludeLocationCounty: [],
    includeLocationCBSAName: [],
    excludeLocationCBSAName: [],
    includeLocationStateName: [],
    excludeLocationStateName: []
  };
  if (segmentType === 'keyword') {
    filledFiled = {
      name: undefined,
      queryId: undefined,
      includeKeywords: [],
      excludeKeywords: [],
      includePhrases: [],
      excludePhrases: []
    };
  }
  if (segmentType === 'store') {
    filledFiled = {
      name: undefined,
      queryId: undefined,
      retailerIds: [],
      includeStoreIds: [],
      excludeStoreIds: [],
      includeBrandIds: [],
      excludeBrandIds: [],
      includeCategoryIds: [],
      excludeCategoryIds: [],
      includeSubCategoryIds: [],
      excludeSubCategoryIds: [],
      // includeLocationCountryCode: [],
      // excludeLocationCountryCode: [],
      // includeLocationRegionCode: [],
      // excludeLocationRegionCode: [],
      includeLocationCBSAName: [],
      excludeLocationCBSAName: [],
      includeLocationStateName: [],
      excludeLocationStateName: []
    };
  }
  Object.keys(param).forEach((shortKey) => {
    const fullKeyValue = mappingToFullKey[shortKey];
    if (fullKeyValue) {
      const value = param[shortKey];
      if (value) {
        if (Array.isArray(value)) {
          if (fullKeyValue.includes('CategoryIds') || fullKeyValue.includes('retailerIds')) {
            filledFiled[fullKeyValue] = value.map((e) => Number(e.i));
          } else {
            filledFiled[fullKeyValue] = value.map((e) => e.i);
          }
        } else {
          filledFiled[fullKeyValue] = value;
        }
      }
    }
  });
  return filledFiled;
};

export const generateQueryParamObj = (
  state: SegmentState | KeyWordSegment | StoreSegment,
  newChange: { key: string; values: { i: string | number }[] }
) => {
  const { key, values } = newChange;
  const shortKeyValueMapping: { [key: string]: string | { i: string } } = {};
  Object.keys(state).forEach((fullName: string) => {
    const shortKeyName = mappingToShortKey[fullName];
    if (shortKeyName) {
      const currentValue = state[fullName];
      if (key === fullName) {
        shortKeyValueMapping[shortKeyName] = values;
      } else if (Array.isArray(currentValue)) {
        shortKeyValueMapping[shortKeyName] = currentValue.map((v) => {
          if (typeof v === 'object' && v !== null && v.value) {
            return { i: v.value };
          }
          return { i: v };
        });
      } else if (currentValue) {
        shortKeyValueMapping[shortKeyName] = currentValue;
      }
    }
  });
  return shortKeyValueMapping;
};

export const findAvailableRetailer = () => {
  const { omniRetailers } = store.getState();
  const filteredRetailer = omniRetailers.data.filter((r) => StoreListSupportedRetailer.includes(r.retailerId));
  const findAnAvailableR = filteredRetailer.find((r) => ![43, 63].includes(r.retailerId));
  if (findAnAvailableR) {
    return [findAnAvailableR.retailerId];
  }
  return [];
};

export const formStoreListRequestBody = (
  queryParams: queryString.ParsedQs,
  mainTimePeriod: ReduxStore['mainTimePeriod']
) => {
  const { startWeek, endWeek } = mainTimePeriod;
  const parsedState = fillSegmentFieldInURL(queryParams, 'store');
  delete parsedState.name;
  delete parsedState.queryId;
  const retailerFilter = findAvailableRetailer();
  if (_isEmpty(parsedState.retailerIds)) {
    parsedState.retailerIds = retailerFilter;
  }
  const baseRequestBody = {
    startWeekId: startWeek,
    endWeekId: endWeek,
    pageNumber: 1,
    pageSize: 20,
    ...parsedState
  };
  return baseRequestBody;
};
