import React from 'react';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import UnitsReturnedProductGrid from './UnitsReturnedProductGrid';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import BeaconDropdownMetricColumnChart from 'src/components/BeaconRedesignComponents/BeaconMetricColumnChart/BeaconDropdownMetricColumnChart';
import { useMainEntityType } from 'src/utils/Hooks/reduxSelectors';

/**
 * Sales Return Page
 */
const ReturnsPage = () => {
  const entityType = useMainEntityType();

  return (
    <BeaconPageContainer>
      <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
        <BeaconComparableSplineChart indexName="sales" fieldName="returnRate" />
        <BeaconComparableSplineChart indexName="sales" fieldName="unitsReturned" />
        <BeaconComparableSplineChart indexName="sales" fieldName="returnRetailValue" />
        <BeaconDropdownMetricColumnChart
          indexName="sales"
          fieldName="returnRate"
          dropdownOptions={[
            {
              id: 'subCategoryId',
              label: 'Subcategory'
            },
            {
              id: 'categoryId',
              label: 'Category'
            },
            {
              id: 'brandId',
              label: 'Brand'
            }
          ].filter((option) => !option.id.toLowerCase().startsWith(entityType))}
          titlePrefix="Return Rate % by"
        />
        <UnitsReturnedProductGrid />
      </Flex>
    </BeaconPageContainer>
  );
};

export default ReturnsPage;
