import types from './types';
import currentComparisonPeriod from './selectors';
import { getAppName } from 'src/utils/app';
import { AppName } from 'sl-api-connector';
// ------------------------------------
// Reducer
// ------------------------------------

const appIsOmni = getAppName() === AppName.Omni;
const initialState = appIsOmni
  ? {
      availableComparisonTimePeriods: [
        {
          id: 'prior-period',
          shortDisplayName: 'Prior Period'
        }
      ]
    }
  : {
      availableComparisonTimePeriods: [
        {
          id: 'prior-period',
          shortDisplayName: 'Prior Period'
        },
        {
          id: 'prior-year',
          shortDisplayName: 'Prior Year'
        }
      ]
    };

export default function updateComparisonTimePeriodReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_COMPARISON_TIME_PERIOD:
      return Object.assign({}, state, currentComparisonPeriod(state, action));
    default:
      return state;
  }
}
