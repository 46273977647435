import React from 'react';
import { StacklineColor, useStacklineTheme } from '@stackline/ui';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

export interface LegendIndicatorProps {
  /**
   * Color of the legend indicator
   */
  color: StacklineColor;
  /**
   * The time period to display, e.g. "Jan 2, 2022 - Apr 9, 2022"
   */
  displayName: string;

  dashed?: boolean;

  subtitle?: React.ReactNode;

  showDot?: boolean;
}

/**
 * Indicator with a color and a date corresponding to the
 * chart the legend is for
 */
const LegendIndicator = ({ color, displayName, dashed, subtitle, showDot = true }: LegendIndicatorProps) => {
  const theme = useStacklineTheme();

  const legendDot = (
    <div
      style={{
        backgroundColor: theme.colors[color],
        width: '5px',
        height: '3px',
        borderRadius: '10px'
      }}
    />
  );

  return (
    <Flex gap="sm" flexDirection="column">
      <Flex gap="sm" alignItems="center">
        {showDot &&
          (dashed ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '12px'
              }}
            >
              {legendDot}
              {legendDot}
            </div>
          ) : (
            <div
              style={{
                width: '12px',
                height: '3px',
                borderRadius: '10px',
                backgroundColor: theme.colors[color]
              }}
            />
          ))}
        <Text variant="body2">{displayName}</Text>
      </Flex>
      {subtitle &&
        (typeof subtitle === 'string' ? (
          <Text sx={{ paddingLeft: '20px' }} variant="body3">
            {subtitle}
          </Text>
        ) : (
          subtitle
        ))}
    </Flex>
  );
};

export default LegendIndicator;
