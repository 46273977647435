import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'qs';
import axios from 'axios';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _set from 'lodash/set';

// data store
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { entityOperations } from 'src/store/modules/entityService';
import { DonutChart } from 'src/components/Charts/Donut';
import './ContentAccuracy.scss';

// rendering
import {
  getChartDisplayTimePeriod,
  setSubTitle,
  buildDefaultConditions,
  buildEntityConditions,
  buildMainEntityConditions,
  buildAggregationConditions
} from '../Renderer/EntityPageRenderer';

// layout
import { getLayoutForEntity } from 'src/components/Layout/EntityPageLayout';

import Loading from 'src/components/common/Loading';
import { buildSubtitleDisplayName } from 'src/utils/filters';
import colors from 'src/utils/colors';
import EntityGrid from '../../EntityGrid';
import Verification from './Verification';
import ImagePicker from './ImagePicker';
import VideoPicker from './VideoPicker';
import { flattenWidgets, defaultWidgetWrapperStyle } from 'src/components/Layout/LayoutUtil';
import { buildAggregations } from 'src/components/AdManager/Search';

const { CancelToken } = axios;

class ContentAccuracy extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    allWeekIdsByRetailerId: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    conditions: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    aggregationConditions: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired,
    entitySearchService: PropTypes.object.isRequired
  };

  static metricConfig = {
    titleAccuracy: {
      color: colors.titleScore
    },
    bulletsAccuracy: {
      color: colors.bulletsScore
    },
    imageUrlsAccuracy: {
      color: colors.imagesScore
    },
    videoUrlsAccuracy: {
      color: colors.videosScore
    }
  };

  static productContentIndexNames = ['contentApproved', 'contentCurrent', 'contentChanged'];

  state = {
    isLoading: true
  };

  componentDidMount() {
    this.cancelSource = CancelToken.source();
    this.fetchMetrics(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { location, entityService, conditions } = nextProps;
    if (
      location.pathname !== this.props.location.pathname ||
      location.search !== this.props.location.search ||
      !_isEqual(this.props.conditions, conditions) ||
      !_isEqual(this.props.entityService.mainEntity, entityService.mainEntity) ||
      !_isEqual(this.props.entityService.comparisonEntity, entityService.comparisonEntity)
    ) {
      if (typeof this.cancelSource !== typeof undefined) {
        this.cancelSource.cancel('Cancel network request');
      }
      this.cancelSource = CancelToken.source();
      this.fetchMetrics(nextProps);
    }
  }

  componentWillUnmount() {
    if (typeof this.cancelSource !== typeof undefined) {
      this.cancelSource.cancel('Cancel network request');
    }
  }

  getDonutChartSeriesForProduct = (contentValues, mainEntity, retailer, widget) => {
    const { data } = widget;
    const val = contentValues.find((x) => x.displayName === data.chartMainField.displayName);
    return {
      data: [
        {
          name: '',
          y: val.value,
          color: ContentAccuracy.metricConfig[data.chartMainField.name].color
        },
        { name: '', y: 1 - val.value, color: colors.lightestGrey }
      ],
      entity: mainEntity,
      title: val.displayValue,
      titleColor: ContentAccuracy.metricConfig[data.chartMainField.name].color,
      subtitle: val.displayName,
      currencySymbol: retailer.currencySymbol
    };
  };

  getDonutChartSeries = (mainEntity, mainEntityMetrics, retailer, widget) => {
    const { data } = widget;
    const dataPoints = mainEntityMetrics[`${data.chartMainField.name}_by_retailerId`].data;
    const seriesData = [];
    let totalSum = 0;
    dataPoints.forEach((dataPoint) => {
      totalSum += dataPoint.value;
      seriesData.push({
        fieldId: data.chartMainField.name,
        name: data.chartMainField.displayName,
        y: dataPoint.value,
        color: ContentAccuracy.metricConfig[data.chartMainField.name].color
      });
    });
    seriesData.push({
      fieldId: 'Unearned',
      name: '',
      y: 1 - totalSum,
      color: colors.lightestGrey
    });
    return {
      data: seriesData,
      entity: mainEntity,
      title: totalSum,
      titleColor: ContentAccuracy.metricConfig[data.chartMainField.name].color,
      currencySymbol: retailer.currencySymbol
    };
  };

  fetchMetrics(props) {
    const {
      conditions,
      entityService,
      app,
      retailer,
      allWeekIdsByRetailerId,
      mainTimePeriod,
      comparisonTimePeriod,
      user,
      categories,
      fetchEntityMetrics,
      location,
      match
    } = props;
    const { mainEntity } = entityService;
    // the main entity has to match with what is in the current url
    const { id } = match.params;
    const mainEntityId = mainEntity && `${mainEntity.id}`;
    const comparisonEntityId = id === mainEntityId ? mainEntityId : mainEntity.hashId;
    if (!mainEntity || id !== comparisonEntityId) {
      return;
    }
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const { tab, subtab } = queryParams;
    const pageLayout = getLayoutForEntity({
      app,
      retailer,
      user,
      tab,
      metricType: subtab,
      entity: mainEntity,
      pageType: 'entityPage'
    });
    const { indexName } = pageLayout.dataConfig;

    const comparisonConfig = {
      type: queryParams.ctype,
      indexName: queryParams.ctab || tab,
      metricName: queryParams.csubtab || subtab,
      entityId: queryParams.cid,
      entityType: queryParams.ctype
    };

    const defaultConditions = buildDefaultConditions(conditions, queryParams);
    const entityConditions = buildEntityConditions(app, categories, defaultConditions, mainEntity);
    const { aggregationConditions } = buildAggregationConditions(
      app,
      indexName,
      retailer,
      allWeekIdsByRetailerId,
      mainTimePeriod,
      comparisonTimePeriod,
      props.aggregationConditions,
      pageLayout.dataConfig.weekIdField,
      true,
      true
    );

    const mainEntityConditions = buildMainEntityConditions(entityConditions, mainEntity, app, retailer, queryParams);

    const promises = [];
    if (mainEntity.type === 'product') {
      const searchRequestOverrides = {
        conditions: mainEntityConditions,
        aggregations: [],
        returnDocuments: true,
        doAggregation: false,
        searchBy: 'child',
        sortFilter: { sortFields: [{ fieldName: 'weekId', direction: 'desc' }] },
        pageNumber: 1,
        pageSize: 1
      };
      ContentAccuracy.productContentIndexNames.forEach((contentIndexName) => {
        promises.push(
          fetchEntityMetrics(
            `mainEntityMetrics${contentIndexName}`,
            { entity: mainEntity, retailer, app, indexName: contentIndexName, derivedFields: [] },
            [searchRequestOverrides],
            this.cancelSource.token
          )
        );
      });
    } else {
      const mainEntityWeeklyAggregationFields = [];
      const flattenedWidgets = flattenWidgets(pageLayout.widgets);
      flattenedWidgets.forEach((widget) => {
        if (_get(widget, ['view', 'name'], '').toLowerCase().includes('donutchart') && widget.data.aggregationFields) {
          widget.data.aggregationFields.forEach((field) => mainEntityWeeklyAggregationFields.push(field));
        }
      });

      const builtAggregations = buildAggregations(mainEntityWeeklyAggregationFields);
      if (_isEmpty(builtAggregations)) {
        return;
      }
      const [
        { aggregations: mainEntityWeeklyTrendAggregationFields, derivations: mainEntityWeeklyTrendDerivedFields }
      ] = builtAggregations;

      const mainEntityWeeklyAggregations = {
        groupByFieldName: pageLayout.dataConfig.retailerIdField.name,
        aggregationFields: mainEntityWeeklyTrendAggregationFields,
        sortDirection: null,
        sortByAggregationField: null,
        conditions: {
          termFilters: [],
          rangeFilters: []
        }
      };

      const searchRequestOverrides = {
        conditions: mainEntityConditions,
        aggregations: [
          {
            ...mainEntityWeeklyAggregations,
            conditions: _cloneDeep(aggregationConditions)
          }
        ]
      };

      // This clears all rangeFilters if we have a retailPrice filter present
      if (
        mainEntity.type === 'segment' &&
        _get(searchRequestOverrides, 'conditions.rangeFilters[0].fieldName') === 'retailPrice'
      ) {
        _set(searchRequestOverrides, 'conditions.rangeFilters', []);
      }

      promises.push(
        fetchEntityMetrics(
          'mainEntityMetrics',
          { entity: retailer, retailer, app, indexName, derivedFields: mainEntityWeeklyTrendDerivedFields },
          [searchRequestOverrides],
          this.cancelSource.token
        )
      );
    }

    this.setState({
      pageLayout,
      comparisonConfig,
      entityConditions,
      mainEntityConditions,
      isLoading: true
    });

    Promise.all(promises)
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (!err.message.includes('Canceled network request')) {
          console.error(err);
        }
      });
  }

  isValid = (val) => val === '' || (val.filter && val.filter((value) => value !== '').length === 0);

  showBullet(contentCurrent, contentChangedVal, index) {
    let valueElement = null;
    if (contentChangedVal !== 'Missing required bullet') {
      valueElement = <div className="accuracy__bullet">{contentCurrent.bullets[index]}</div>;
    }

    return (
      <div
        key={`${contentChangedVal}${index}`}
        className={`accuracy__item accuracy__item--validate ${
          this.isValid(contentChangedVal) ? '' : 'accuracy__item--invalid'
        }`}
      >
        <div className="accuracy__item-content">{valueElement}</div>
        <Verification isValid={this.isValid(contentChangedVal)} type="bullet" />
      </div>
    );
  }

  buildDisplayValue = (contentApproved, contentChanged) =>
    `${contentChanged.matchedContentCount}/${contentChanged.approvedContentCount}`;

  buildValue = (contentChanged) => {
    if (!contentChanged || contentChanged.approvedContentCount === 0) {
      return 1;
    }

    return contentChanged.matchedContentCount / contentChanged.approvedContentCount;
  };

  buildContentValues(contentApproved, contentCurrent, contentChanged) {
    return [
      {
        displayName: 'Title',
        displayValue: this.buildDisplayValue([contentApproved.title], contentChanged.titleStats),
        value: this.buildValue(contentChanged.titleStats),
        color: colors.stacklineBlue
      },
      {
        displayName: 'Bullets',
        displayValue: this.buildDisplayValue(contentApproved.bullets, contentChanged.bulletsStats),
        value: this.buildValue(contentChanged.bulletsStats),
        color: '#FF963C'
      },
      {
        displayName: 'Images',
        displayValue: this.buildDisplayValue(contentApproved.imageUrls, contentChanged.imageUrlsStats),
        value: this.buildValue(contentChanged.imageUrlsStats),
        color: colors.darkBlue
      },
      {
        displayName: 'Videos',
        displayValue: this.buildDisplayValue(contentApproved.videoUrls, contentChanged.videoUrlsStats),
        value: this.buildValue(contentChanged.videoUrlsStats),
        color: colors.purple
      }
    ];
  }

  renderProductContentMatch = (contentApproved, contentCurrent, contentChanged, contentValues) => (
    <div className="accuracy__container">
      <div className="accuracy__content">
        <div className="accuracy__master-content">
          <h2 className="sl-header-text">Master Content</h2>
          <hr className="sl-divider sl-divider--no-margin-bottom" />
        </div>
        <div className="accuracy__current-content">
          <h2 className="sl-header-text">Current Content</h2>
          <hr className="sl-divider sl-divider--no-margin-bottom" />
        </div>
      </div>
      <div className="accuracy__content">
        <div className="accuracy__master-content">
          <label className="accuracy__label">Product Title</label>
          <div className="accuracy__item">
            <div className="accuracy__item-content">{contentApproved.title}</div>
          </div>
        </div>
        <div className="accuracy__current-content">
          <label className="accuracy__label">Product Title ({contentValues[0].displayValue})</label>
          <div
            className={`accuracy__item accuracy__item--validate ${
              this.isValid(contentChanged.title) ? '' : 'accuracy__item--invalid'
            }`}
          >
            <div className="accuracy__item-content">{contentCurrent.title}</div>
            <Verification isValid={this.isValid(contentChanged.title)} type="title" />
          </div>
        </div>
      </div>
      <div className="accuracy__content">
        <div className="accuracy__master-content">
          <label className="accuracy__label">Product Description Bullets</label>
          {contentApproved.bullets.map((val) => (
            <div key={val} className="accuracy__item">
              <div className="accuracy__bullet">{val}</div>
            </div>
          ))}
        </div>
        <div className="accuracy__current-content">
          <label className="accuracy__label">Product Description Bullets ({contentValues[1].displayValue})</label>
          {contentChanged.bullets.map((val, index) => this.showBullet(contentCurrent, val, index))}
        </div>
      </div>

      <div className="accuracy__content">
        <div className="accuracy__master-content">
          <label className="accuracy__label">Product Images</label>
          <div className="accuracy__images-container">
            <ImagePicker images={contentApproved.imageUrls} />
          </div>
        </div>
        <div className="accuracy__current-content">
          <label className="accuracy__label">Product Images ({contentValues[2].displayValue})</label>
          <div
            className={`accuracy__images-container ${
              this.isValid(contentChanged.imageUrls) ? '' : 'accuracy__images-container--invalid'
            }`}
          >
            <ImagePicker images={contentCurrent.imageUrls} contentChanged={contentChanged.imageUrls} />
          </div>
        </div>
      </div>

      <div className="accuracy__content">
        <div className="accuracy__master-content">
          <label className="accuracy__label">Product Videos</label>
          <div className="accuracy__videos-container">
            <VideoPicker videos={contentApproved.videoUrls} />
          </div>
        </div>
        <div className="accuracy__current-content">
          <label className="accuracy__label">Product Videos ({contentValues[3].displayValue})</label>
          <div
            className={`accuracy__videos-container ${
              this.isValid(contentChanged.videoUrls) ? '' : 'accuracy__videos-container--invalid'
            }`}
          >
            <VideoPicker videos={contentCurrent.videoUrls} contentChanged={contentChanged.videoUrls} />
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    const {
      app,
      mainTimePeriod,
      comparisonTimePeriod,
      entityService,
      entitySearchService,
      filters,
      categories,
      retailer,
      aggregationConditions
    } = this.props;
    const { isLoading, entityConditions, comparisonConfig, mainEntityConditions, pageLayout } = this.state;
    const { mainEntity } = entityService;
    if (
      isLoading ||
      !pageLayout ||
      !mainEntity ||
      !entityConditions ||
      !mainEntityConditions ||
      !aggregationConditions
    ) {
      return <Loading className="spinner" />;
    }
    const { mainEntityMetrics } = entitySearchService;
    if (!comparisonConfig || !entitySearchService) {
      return <Loading className="spinner" />;
    }
    if (mainEntity.type !== 'product' && (!mainEntityMetrics || !mainEntityMetrics.titleAccuracy_by_retailerId)) {
      return <Loading className="spinner" />;
    }
    let contentValues = null;
    let contentApproved = null;
    let contentCurrent = null;
    let contentChanged = null;
    if (mainEntity.type === 'product') {
      const isLoadingContent = !!ContentAccuracy.productContentIndexNames.find(
        (indexName) => !entitySearchService[`mainEntityMetrics${indexName}`]
      );

      if (isLoadingContent) {
        return <Loading className="spinner" />;
      }
      [contentApproved] = entitySearchService.mainEntityMetricscontentApproved.documents;
      [contentCurrent] = entitySearchService.mainEntityMetricscontentCurrent.documents;
      [contentChanged] = entitySearchService.mainEntityMetricscontentChanged.documents;
      contentValues = this.buildContentValues(contentApproved, contentCurrent, contentChanged);
    }
    const subtitle = buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app);
    setSubTitle(subtitle, [mainEntityMetrics]);

    const chartDisplayTimePeriod = getChartDisplayTimePeriod(mainTimePeriod);
    const chartComparisonDisplayTimePeriod = getChartDisplayTimePeriod(comparisonTimePeriod);

    // `ContentAccuracy` is a massive anti-pattern in terms of what a page container looks like.  It looks through the
    // list of provided widgets, picks them out individually by their name, and then renders them deep into custom
    // parts of the document depending on that.
    //
    // Rather than try to create a custom `WidgetComponent` and deal with all the special cases, we deconstruct
    // (flatten) the provided list of widgets and use the same old custom/override logic as before, adding in the
    // left nav widget manually.
    const flattenedWidgets = flattenWidgets(pageLayout.widgets);
    const leftNavWidget = flattenedWidgets.find((widget) => widget.name === 'leftNav');

    return (
      <div style={pageLayout.containerStyle}>
        <leftNavWidget.CustomComponent widget={leftNavWidget} pageLayout={pageLayout} />
        <div style={{ ...defaultWidgetWrapperStyle, marginTop: 40 }}>
          <div className="accuracy">
            <div className="accuracy__title">Content Accuracy</div>
            <div className="accuracy__scores">
              {flattenedWidgets
                .filter((widget) => _get(widget, ['view', 'name'], '').toLowerCase().includes('donutchart'))
                .map((widget, index) => {
                  const chartSeriesData =
                    mainEntity.type === 'product'
                      ? this.getDonutChartSeriesForProduct(contentValues, mainEntity, retailer, widget)
                      : this.getDonutChartSeries(mainEntity, mainEntityMetrics, retailer, widget);

                  return (
                    <div key={`${widget.view.name}_${index}`} style={{ flex: '1 1 0%' }}>
                      <DonutChart
                        chartDisplayTimePeriod={chartDisplayTimePeriod}
                        chartComparisonDisplayTimePeriod={chartComparisonDisplayTimePeriod}
                        mainEntityMetrics={chartSeriesData}
                        widget={widget}
                        retailer={retailer}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div>
            {flattenedWidgets.map((widget, index) => {
              const { view, CustomComponent } = widget;
              if (view.name === 'entityGrid') {
                return (
                  <div
                    key={`${view.name}_${index}`}
                    style={view.container && view.container.style ? view.container.style : {}}
                    className={view.container && view.container.className ? view.container.className : ''}
                  >
                    <EntityGrid
                      key={`${view.name}`}
                      queryConditions={mainEntityConditions}
                      aggregationConditions={aggregationConditions}
                      widget={widget}
                    />
                  </div>
                );
              } else if (view.name === 'pageContextMenu') {
                return <CustomComponent key="pageContextMenu" widget={widget} />;
              }
              return null;
            })}
          </div>
          <div>
            {flattenedWidgets
              .filter(({ view: { name } }) => name === 'contentMatchingGrid')
              .map(({ view }, index) => (
                <div key={`${view.name}_${index}`}>
                  {this.renderProductContentMatch(contentApproved, contentCurrent, contentChanged, contentValues)}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, [
    'app',
    'retailer',
    'user',
    'allWeekIdsByRetailerId',
    'categories',
    'filters',
    'mainTimePeriod',
    'comparisonTimePeriod',
    'entityService',
    'entitySearchService'
  ]);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics,
  fetchEntity: entityOperations.fetchEntity,
  setEntity: entityOperations.setEntity
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentAccuracy));
