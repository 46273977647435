import types from './types';
import _omit from 'lodash/omit';

interface OmniChartsServiceState {
  [chartName: string]: {
    main: any[];
    compare: any[];
    isFetching: boolean;
  };
}

const initialState: OmniChartsServiceState = {};

const omniChartsService = (
  state: OmniChartsServiceState = initialState,
  action: { type: string; entity: { chartName: string; main: any[]; compare: any[] } }
) => {
  switch (action.type) {
    case types.START_FETCH:
      return {
        ...state,
        [action.entity.chartName]: { main: [], compare: [], isFetching: true }
      };
    case types.RECEIVE_CHART_DATA_FOR_BOTH:
      return {
        ...state,
        [action.entity.chartName]: {
          ...state[action.entity.chartName],
          main: action.entity.main,
          compare: action.entity.compare,
          isFetching: false
        }
      };
    case types.END_FETCH:
      return {
        ...state,
        [action.entity.chartName]: { ...state[action.entity.chartName], isFetching: false }
      };
    case types.DELETE_DATA_KEY: {
      const { chartName } = action.entity;
      return _omit(state, [chartName]);
    }
    default:
      return state;
  }
};

export default omniChartsService;
