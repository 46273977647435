import _cloneDeep from 'lodash/cloneDeep';
import colors from 'src/utils/colors';
import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import VIEWS from '../ViewDefaultConfig';
import WaterfallChart from 'src/components/EntityPage/WaterfallChart/WaterfallChart';
import _merge from 'lodash/merge';
import BeaconProWaterfallPageLayout from './BeaconProWaterfallPageLayout';
import { shouldShowNewBeacon } from 'src/utils/app';

export default function getWaterfallPageLayout({ app, entity }, widgetOverride = {}) {
  const indexName = 'productWeeklyMetrics';
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
  const retailerIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'));
  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.atlas.retailer),
    categoryEntity: _cloneDeep(ENTITIES.atlas.category),
    relatedEntity: _cloneDeep(ENTITIES.atlas.brand),
    weekIdField,
    retailerIdField
  };

  const aggregationConfig = {
    groupByFields: [INDEX_FIELDS.getField(app.name, 'sales', 'stacklineSku', entity.type)],
    configByGroupByFieldName: {
      stacklineSku: {
        indexName,
        entity,
        aggregationFields: [
          'trafficOrganicClicks',
          'trafficAdClicks',
          'trafficOtherClicks',
          'trafficOtherUnitsSold',
          'unitsSold',
          'retailSales',
          'retailPrice',
          'instockRate',
          'adUnitsSold',
          'buyBoxWinPercentage',
          'contentScore',
          'avgStars'
        ].map((field) => INDEX_FIELDS.getField(app.name, indexName, field, entity.type, 'stacklineSku'))
      }
    }
  };

  const widgetConfig = _merge(
    {
      name: `waterfallChart_For_${entity.type}_${entity.id}`,
      CustomComponent: shouldShowNewBeacon() ? BeaconProWaterfallPageLayout : WaterfallChart,
      view: {
        ...VIEWS.comparisonBarChart,
        chartSeriesColors: [colors.comparison, colors.stacklineBlue],
        displayName: `Waterfall Chart`,
        container: {
          style: {
            marginBottom: shouldShowNewBeacon() ? '0px' : '100px',
            verticalAlign: 'top'
          }
        }
      },
      data: {
        groupByField: INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product'),
        mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product'),
        weekIdField,
        ...aggregationConfig
      }
    },
    widgetOverride
  );

  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    dataConfig,
    widgets: [widgetConfig]
  };
}
