/**
 * Functional programming utilities
 */

import _curry from 'lodash/curry';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _isEqual from 'lodash/isEqual';

/**
 * Applies `pred` to `x` and returns the result if `x` is not nil, otherwise passes through the nil `x`.
 */
export const map = <T, R>(x: T | null | undefined, pred: (x: T) => R): R | null | undefined =>
  _isNil(x) ? (x as null | undefined) : pred(x);

type ArgumentsOf<T> = T extends (...args: infer Args) => any ? Args : never;

/**
 * HOF that negates the sign of whatever the output of the wrapped function is
 */
export const negate =
  <F extends (...args: any[]) => number>(func: F): ((...args: ArgumentsOf<F>) => number) =>
  (...args) =>
    -func(...args);

/**
 * HOF that applies a boolean NOT (`!`) to whatever the output of the wrapped function is
 */
export const not =
  <F extends (...args: any[]) => any>(func: F): ((...args: ArgumentsOf<F>) => boolean) =>
  (...args) =>
    !func(...args);

const propEqInner = <S extends string, T, C extends Record<S, T>>(prop: S, val: T, collection: C) => {
  const ownVal = collection[prop];
  return _isEqual(ownVal, val);
};

/**
 * A clone of Ramda's `propEq` function: https://ramdajs.com/docs/#propEq
 *
 * It uses `_isEqual` to compare the props' values.
 *
 * This function is curried, meaning that calling it with less than its required arg count (3) will return a partially
 * applied function.
 *
 * @param {string} prop The key of the collection that will be compared against
 * @param {any} val The value that will be checked for equality wrt. `collection[prop]`
 * @param {any} collection The object or array from which the value will be retrieved for comparison
 */
export const propEq = _curry(propEqInner);

/**
 * Works the same way as `propEq`, except uses `_get()` to retrieve the provided `path` from `collection` instead of
 * a simple property access.
 *
 * It uses `_isEqual` to compare the props' values.
 *
 * This function is curried, meaning that calling it with less than its required arg count (3) will return a partially
 * applied function.
 *
 * @param {string} path The key of the collection that will be compared against
 * @param {any} val The value that will be checked for equality wrt. `collection[prop]`
 * @param {any} collection The object or array from which the value will be retrieved for comparison
 */
const pathEqInner = (path: string | (string | number)[], val: any, collection: any[] | { [key: string]: any }) => {
  const ownVal = _get(collection, path);
  return _isEqual(ownVal, val);
};

export const pathEq = _curry(pathEqInner);

/**
 * Returns a new object with `obj[key]` set to `val`.
 *
 * @param {string} key The object key that will be set
 * @param {any} val The val that will be set into `obj[key]`
 * @param {object} obj The object to which the new key will be added
 */
export const addProp = _curry((key: string, val: any, obj: { [key: string]: any }) => ({ ...obj, [key]: val }));

/**
 * Returns a new function that internally calls `func` with zero arguments, discarding any arguments that are passed in.
 *
 * @param {function} func
 */
export const makeNullary =
  <T, F extends (...args: any[]) => T>(func: F): (() => T) =>
  () =>
    func();

/**
 * Returns a new function that passes through the first `n` arguments supplied to it into `func`.
 *
 * @param {number} n The number of arguments to retain
 * @param {function} func
 */
export const makeNAry = _curry((n: number, func: (...args: any) => any) => (...args: any[]) => {
  const retainedArgs = args.slice(0, n);
  return func(...retainedArgs);
});

export const eq = _curry((a: any, b: any) => a === b);

export const ne = _curry((a: any, b: any) => a !== b);

/**
 * Returns a promise that resolves after `ms` milliseconds.
 */
export const timeout = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export const filterNils = <T>(arr: (T | null | undefined)[]): T[] => arr.filter((x) => !_isNil(x)) as T[];

export const prop =
  <P extends string | number>(property: P) =>
  <T extends { [key: string]: any }>(obj: T) =>
    obj[property];

/**
 * Sums up the values of key `key` for each item in `items` and returns the total.  This function is curried.
 */
export const sumByKey = <S extends string, T extends Record<S, number>>(key: S, items: T[]): number =>
  items.reduce((acc, item) => acc + item[key], 0);

/*
 * Counts the number of instances in an array where `pred` returns a truthy value
 */
export const count = <T>(pred: (x: T) => any, collection: T[]): number =>
  collection.reduce((acc, item) => (pred(item) ? acc + 1 : acc), 0);

/**
 * Similar to `_.get`, but takes an array of paths to try.  Each path will be tried in order.  For each, if the
 * found value `_.isNil()`, the next path will be tried etc.  This is repeated until a non-nil element is found or
 * all paths are tried, in which case `defaultValue` will be returned.
 *
 * @param base The object that will be indexed into
 * @param paths An array of paths to try in order
 * @param defaultValue A value to be returned if no paths match
 */
export const tryGetMulti = <T>(base: any, paths: (string | (string | number)[])[], defaultValue: T): T => {
  const foundElem = paths.reduce((acc, path) => {
    if (!_isNil(acc)) {
      return acc;
    }

    return _get(base, path);
  }, undefined);

  return _isNil(foundElem) ? defaultValue : foundElem;
};
