import _cloneDeep from 'lodash/cloneDeep';

import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { buildSummaryTrendColumnGroupsWidget } from 'src/components/Layout/LayoutUtil';
import CommonSummaryInfo from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';

export default function getBeaconAdDisplaySummaryWidgets({ app, entity }) {
  const adIndexName = 'advertisingDisplay';
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, adIndexName, 'weekId'));

  const widgets = [];

  widgets.push({
    CustomComponent: CommonSummaryInfo,
    name: 'CommonSummaryInfo',
    view: {
      name: 'CommonSummaryInfo',
      title: 'Ad Summary - Display'
    }
  });
  const keyMetricsColumnDefinitions = [
    [['impressions', ' Ad Impressions'], ['dpv', 'DPV'], ['clickThroughRate', 'CTR %'], ['detailPageViewRate', 'DPVR %']],
    [['spend', 'Ad Spend'], ['costPerClick', 'CPC'], ['costPerMilli', 'CPM'], ['costPerAcquisition', 'CPA']],
    [
      ['sales', 'Ad Sales'],
      ['percentNewToBrand', 'NEW TO BRAND %'],
      ['unitsSold', 'AD UNITS SOLD'],
      ['returnOnAdSpend', 'ROAS']
    ]
  ].map((column) =>
    column.map(([fieldName, displayName]) => [
      adIndexName,
      fieldName,
      displayName,
      null,
      null,
      { eventName: 'toggleChartMainMetric', eventData: { name: fieldName, scrollIntoView: true } }
    ])
  );

  const keyMetricsWidgetGroup = buildSummaryTrendColumnGroupsWidget(
    { app, entity, weekIdField },
    keyMetricsColumnDefinitions,
    { view: { container: { style: { marginBottom: 50 } } } }
  );
  widgets.push(keyMetricsWidgetGroup);

  return widgets;
}
