import React, { InputHTMLAttributes, ForwardedRef, CSSProperties } from 'react';
import { styled } from '@mui/material/styles';
import { StacklineTheme, hexToRgba, useStacklineTheme } from '@stackline/ui';

import { SxProps } from '@mui/system';

export interface StyledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  style?: CSSProperties;
  sx?: SxProps;
}

// Define the props for StyledInput
type StyledInputInnerProps = {
  stacklineTheme: StacklineTheme;
} & StyledInputProps;

const StyledInputInner = styled('input')<StyledInputInnerProps>(({ stacklineTheme, error, style = {} }) => ({
  height: stacklineTheme.spacing.lgl,
  paddingTop: '7px',
  paddingBottom: '6px',
  paddingLeft: stacklineTheme.spacing.sm,
  border: `1px solid ${error ? stacklineTheme.colors.error : stacklineTheme.colors.primaryGray}`,
  borderRadius: stacklineTheme.spacing.xs,
  fontFamily: 'Roboto',
  fontWeight: '400',
  fontSize: '14px',
  color: stacklineTheme.colors.primary,
  '&:disabled': {
    backgroundColor: stacklineTheme.colors.white,
    color: hexToRgba(stacklineTheme.colors.secondary, 0.5)
  },
  '&::placeholder': {
    color: hexToRgba(stacklineTheme.colors.secondary, 0.5)
  },
  '&:focus': {
    outline: 'none'
  },
  '::placeholder': {
    color: hexToRgba(stacklineTheme.colors.secondary, 0.5)
  },
  ...style
}));

// eslint-disable-next-line react/display-name
const StyledInput = React.forwardRef((props: StyledInputProps, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
  const theme = useStacklineTheme();
  return <StyledInputInner ref={ref} stacklineTheme={theme} {...props} />;
});

export default StyledInput;
