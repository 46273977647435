import colors from 'src/utils/colors';

const discoverStyles = {
  text: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center'
  },
  textLink: {
    fontSize: 14,
    whiteSpace: 'pre-wrap',
    lineHeight: '1.5em',
    maxHeight: '3em',
    overflow: 'hidden'
  },
  imageWrapper: {
    alignSelf: 'center',
    minWidth: 45,
    textAlign: 'center',
    marginRight: 10
  },
  crossIcon: {
    stroke: colors.lightGrey,
    strokeWidth: 13,
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  imageWrapperInner: {
    width: 45,
    display: 'table'
  },
  imageWrapperInnerInner: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  image: {
    maxWidth: 35,
    maxHeight: 35
  },
  chip: {
    right: 0,
    padding: '0 10px',
    marginRight: 0,
    maxHeight: 27
  },
  input: {
    color: 'blue'
  }
};

export default discoverStyles;
