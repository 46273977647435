import React from 'react';
import PropTypes from 'prop-types';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import Cycle from '!svg-react-loader!src/components/common/UploadAnimation/cycle.svg'; // eslint-disable-line
import Check from '!svg-react-loader!src/components/common/UploadAnimation/check.svg'; // eslint-disable-line
import './submitScreen.scss'

const SuccessScreen = ({ topLine, bottomLine, buttons }) => {
    return (<div className="finish_screen">
        <div className="icon_group">
          <Cycle className="success_cycle" />
          <Check className="success_check" />
        </div>
        <div className="content_area">
        <div className="title">Success</div>
        <div className="subtitle">{topLine}</div>
        <div className="subtitle">{bottomLine}</div>
        <div className="button_group" >
        { buttons.map((botton, index) => {
            return (
              <LargeMuiButton
                key={index}
                style={{ textDecoration: 'none', cursor: 'pointer', marginLeft: 5, marginRight: 5 }}
                onClick={() => { botton.onClick() }}
                {...botton.buttonProps}
              >
                {botton.text}
              </LargeMuiButton>
            )
        }) }

        </div> 
        </div>         
    </div>)
}

SuccessScreen.propTypes = {
  topLine: PropTypes.string,
  bottomLine: PropTypes.string,
  buttons: PropTypes.array
};
  
SuccessScreen.defaultProps = {
  topLine: '',
  bottomLine: '',
  buttons: []
};

export default SuccessScreen;