import { AD_STATUS_DERIVED, AD_CAMPAIGN_STATUS } from 'sl-ad-campaign-manager-data-model';

const RECEIVE_AD_TARGET_STATES = 'src/adTargetStates/RECEIVE_AD_TARGET_STATES' as const;
const CLEAR_AD_TARGET_STATES = 'src/adTargetStates/CLEAR_AD_TARGET_STATES' as const;

export interface AdTargetState {
  id: AD_STATUS_DERIVED | AD_CAMPAIGN_STATUS;
  targetState: AD_STATUS_DERIVED | AD_CAMPAIGN_STATUS;
  name: string;
  displayName: string;
  type: 'adTargetState';
}

export default {
  RECEIVE_AD_TARGET_STATES,
  CLEAR_AD_TARGET_STATES
};
