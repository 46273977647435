import React, { useMemo } from 'react';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { TimeToConversionDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import { AD_CONVERSION_EVENT } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/TimeToConversion/constants';
import GenericBarChart from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/GenericBarChart';
import { createNonComparisonLegendDivs } from 'src/utils/chartUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { getSimpleTooltipDiv } from 'src/components/EntityPage/Renderer/tooltipDiv';
import { convertTimeToConversionDataToCsv } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/TimeToConversion/utils';
import _sum from 'lodash/sum';

interface TimeToConversionBarChartProps {
  metric: any;
  data: any;
}

/**
 *
 * @returns Bar chart displaying time to conversion for [metric]
 */
const TimeToConversionBarChart = ({ data, metric }: TimeToConversionBarChartProps) => {
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const formatMetric = useMetricFormatter();
  const buttons = useMemo(() => {
    return [
      {
        displayName: 'Time to Conversion for All Ads',
        eventId: TimeToConversionDropdownOption.AllAdTypes,
        eventName: AD_CONVERSION_EVENT,
        isSelected: metric === TimeToConversionDropdownOption.AllAdTypes
      },
      {
        displayName: 'Time to Conversion for DSP Ads',
        eventId: TimeToConversionDropdownOption.DemandSidePlatformAds,
        eventName: AD_CONVERSION_EVENT,
        isSelected: metric === TimeToConversionDropdownOption.DemandSidePlatformAds
      },
      {
        displayName: 'Time to Conversion for Search Ads',
        eventId: TimeToConversionDropdownOption.SearchAds,
        eventName: AD_CONVERSION_EVENT,
        isSelected: metric === TimeToConversionDropdownOption.SearchAds
      }
    ];
  }, [metric]);

  const chartProps: Highcharts.Options = useMemo(
    () => ({
      chart: {
        spacingLeft: 8
      },
      exporting: {
        enabled: true,
        buttons
      },
      legend: {
        labelFormatter() {
          const label = `Conversions (${formatMetric(_sum(data), METRICTYPE.VOLUME, {
            showFullValue: false,
            decimalPlaces: 2
          })})`;

          const legendDivs: string[] = [];
          createNonComparisonLegendDivs({
            chartDisplayTimePeriod: mainTimePeriod,
            mainLegendMetricValue: {
              value: label
            },
            isSameMetricComparison: true,
            metricsDataByTimeSeries: null,
            legendDivs
          });

          return legendDivs[0];
        }
      },
      xAxis: {
        categories: ['<10 minutes', '10-60 minutes', '1-6 hours', '6-24 hours', '1-7 days', '7+ days']
      },
      yAxis: {
        labels: {
          formatter() {
            return formatMetric(this.value, METRICTYPE.VOLUME, { showFullValue: false, decimalPlaces: 2 });
          }
        }
      },
      tooltip: {
        shared: true,
        positioner(boxWidth, boxHeight, point) {
          return {
            x: point.plotX + this.chart.plotLeft - boxWidth / 2, // center the tooltip horizontally
            y: point.plotY + this.chart.plotTop - boxHeight - 10 // position the tooltip above the column
          };
        },
        formatter() {
          const primaryValue = this.points[0].y;
          const primaryColor = this.points[0].color as string;

          return getSimpleTooltipDiv([
            {
              label: `Conversions: ${formatMetric(primaryValue, METRICTYPE.VOLUME, {
                decimalPlaces: 2,
                showFullValue: false
              })}`,
              color: primaryColor
            }
          ]);
        }
      },

      plotOptions: {
        line: {
          marker: {
            lineColor: 'transparent'
          }
        },
        column: {
          events: {
            legendItemClick() {
              // Disable legend click to avoid hiding the single series
              return false;
            }
          },

          dataLabels: {
            crop: false,
            overflow: 'none'
          }
        },
        barWidth: 100
      }
    }),
    [data, formatMetric, mainTimePeriod, buttons]
  );

  const columnSeries: Highcharts.SeriesColumnOptions = useMemo(
    () => ({
      type: 'column',
      name: 'name',
      data
    }),
    [data]
  );

  return (
    <GenericBarChart
      columnData={columnSeries}
      chartProps={chartProps}
      convertSeriesToDelimitedData={() =>
        convertTimeToConversionDataToCsv({ data, dateRange: mainTimePeriod.displayName, metric })
      }
    />
  );
};

export default TimeToConversionBarChart;
