import _cloneDeep from 'lodash/cloneDeep';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { buildSummaryTrendColumnGroupsWidget } from 'src/components/Layout/LayoutUtil';
import CommonSummaryInfo from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';

export default function getBeaconAdSummaryWidgets({ app, entity }) {
  const adIndexName = 'advertising';
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, adIndexName, 'weekId'));

  const widgets = [];
  widgets.push({
    CustomComponent: CommonSummaryInfo,
    name: 'CommonSummaryInfo',
    view: {
      name: 'CommonSummaryInfo',
      title: 'Ad Summary'
    }
  });

  const keyMetricsColumnDefinitions = [
    [
      ['clicks', 'Ad Clicks'],
      ['impressions', 'AD IMPRESSIONS'],
      ['clickThroughRate', 'CTR %'],
      ['searchKeyword', 'KEYWORDS']
    ],
    [['spend', 'Ad Spend'], ['costPerClick', 'CPC'], ['costPerMilli', 'CPM'], ['costPerAcquisition', 'CPA']],
    [
      ['sales', 'Ad Sales'],
      ['conversionRate', 'CONVERSION'],
      ['unitsSold', 'AD UNITS SOLD'],
      ['returnOnAdSpend', 'ROAS']
    ]
  ].map((column) =>
    column.map(([fieldName, displayName]) => [
      adIndexName,
      fieldName,
      displayName,
      null,
      null,
      { eventName: 'toggleChartMainMetric', eventData: { name: fieldName, scrollIntoView: true } }
    ])
  );

  const keyMetricsWidgetGroup = buildSummaryTrendColumnGroupsWidget(
    { app, entity, weekIdField },
    keyMetricsColumnDefinitions,
    { view: { container: { style: { marginBottom: 50 } } } }
  );

  keyMetricsWidgetGroup.data.widgets[0].data.widgets[3].data.configByGroupByFieldName.weekId.aggregationFields[0].conditions = {
    rangeFilters: [
      {
        fieldName: 'clicks',
        minValue: 1
      }
    ]
  };
  widgets.push(keyMetricsWidgetGroup);
  return widgets;
}
