import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Widget } from 'src/types/application/widgetTypes';
import { useAsyncHook } from 'src/utils/Hooks/typedHooks';
import Verification from './Verification';
import ImagePicker from './ImagePicker';
import VideoPicker from './VideoPicker';
import { withRouter } from 'react-router';
import { OmniBaseRequestBody, addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import axios from 'axios';
import { isArray } from 'lodash';
import Loading from 'src/components/common/Loading';
import Export from 'src/components/Export';
import colors from 'src/utils/colors';
import './ContentAccuracy.scss';
import _isEmpty from 'lodash/isEmpty';
import { determineTheDefault } from 'src/components/EntityPage/Content/OmniContentAccuracy';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import { decode } from 'html-entities';
import { destructurePathName } from 'src/utils/urlParsing';

interface OmniContentCompareProps extends RouteComponentProps {
  widget: Widget;
  eventBus: EventBus;
}

interface ContentValueType {
  displayName: string;
  displayValue: string;
  value: number;
  color: string;
}

const buildDisplayValue = (contentApproved, contentChanged) => {
  return `${contentChanged.matchedContentCount}/${contentChanged.approvedContentCount}`;
};

const buildValue = (contentChanged) => {
  if (!contentChanged || contentChanged.approvedContentCount === 0) {
    return 1;
  }

  return contentChanged.matchedContentCount / contentChanged.approvedContentCount;
};

const buildContentValues = (contentApproved, _, contentChanged) => {
  return [
    {
      displayName: 'Title',
      displayValue: buildDisplayValue([contentApproved.title], contentChanged.titleStats),
      value: buildValue(contentChanged.titleStats),
      color: colors.stacklineBlue
    },
    {
      displayName: 'Bullets',
      displayValue: buildDisplayValue(contentApproved.bullets, contentChanged.bulletsStats),
      value: buildValue(contentChanged.bulletsStats),
      color: '#FF963C'
    },
    {
      displayName: 'Images',
      displayValue: buildDisplayValue(contentApproved.imageUrls, contentChanged.imageUrlsStats),
      value: buildValue(contentChanged.imageUrlsStats),
      color: colors.darkBlue
    },
    {
      displayName: 'Videos',
      displayValue: buildDisplayValue(contentApproved.videoUrls, contentChanged.videoUrlsStats),
      value: buildValue(contentChanged.videoUrlsStats),
      color: colors.purple
    },
    {
      displayName: 'Descriptions',
      displayValue: buildDisplayValue(contentApproved.descriptionContentStats, contentChanged.descriptionStats),
      value: buildValue(contentChanged.descriptionContentStats),
      color: colors.purple
    }
  ];
};
const isValid = (val: any) => val === '' || (val.filter && val.filter((value: any) => value !== '').length === 0);
const showBullet = (contentCurrent, contentChangedVal, index) => {
  let valueElement = null;
  if (contentChangedVal !== 'Missing required bullet') {
    valueElement = <div className="accuracy__bullet">{contentCurrent.bullets[index]}</div>;
  }
  return (
    <div
      key={`${contentChangedVal}${index}`}
      className={`accuracy__item accuracy__item--validate ${
        isValid(contentChangedVal) ? '' : 'accuracy__item--invalid'
      }`}
    >
      <div className="accuracy__item-content">{valueElement}</div>
      <Verification isValid={isValid(contentChangedVal)} type="bullet" />
    </div>
  );
};

const renderProductTitle = ({
  contentApproved,
  contentCurrent,
  contentChanged,
  contentValues
}: {
  contentApproved: any;
  contentCurrent: any;
  contentChanged: any;
  contentValues: ContentValueType[];
}) => {
  return (
    <div className="accuracy__content">
      <div className="accuracy__master-content">
        <label className="accuracy__label">Product Title</label>
        <div className="accuracy__item">
          <div className="accuracy__item-content">{decode(contentApproved.title)}</div>
        </div>
      </div>
      <div className="accuracy__current-content">
        <label className="accuracy__label">Product Title ({contentValues[0].displayValue})</label>
        <div
          className={`accuracy__item accuracy__item--validate ${
            isValid(contentChanged.title) ? '' : 'accuracy__item--invalid'
          }`}
        >
          <div className="accuracy__item-content">{decode(contentCurrent.title)}</div>
          <Verification isValid={isValid(contentChanged.title)} type="title" />
        </div>
      </div>
    </div>
  );
};

const renderProductDescription = ({
  contentApproved,
  contentCurrent,
  contentChanged,
  contentValues
}: {
  contentApproved: any;
  contentCurrent: any;
  contentChanged: any;
  contentValues: any;
}) => {
  return (
    <div className="accuracy__content">
      <div className="accuracy__master-content">
        <label className="accuracy__label">Product Description</label>
        <div className="accuracy__item">
          <div className="accuracy__item-description">
            {contentApproved.description && contentApproved.description.length > 0 ? (
              decode(contentApproved.description)
            ) : (
              <div className="accuracy__item">This product has no approved description</div>
            )}
          </div>
        </div>
      </div>
      <div className="accuracy__current-content">
        <label className="accuracy__label">Product Description ({contentValues[4].displayValue})</label>
        {contentCurrent.description && contentCurrent.description.length > 0 ? (
          <div
            className={`accuracy__item accuracy__item--validate ${
              isValid(contentChanged.description) ? '' : 'accuracy__item--invalid'
            }`}
          >
            <div className="accuracy__item-description">{decode(contentCurrent.description)}</div>
            <Verification isValid={isValid(contentChanged.description)} type="title" />
          </div>
        ) : (
          <div className="accuracy__item">
            <div className="accuracy__item">This product has no description</div>
          </div>
        )}
      </div>
    </div>
  );
};

const renderProductBullets = ({
  contentApproved,
  contentCurrent,
  contentChanged,
  contentValues
}: {
  contentApproved: any;
  contentCurrent: any;
  contentChanged: any;
  contentValues: ContentValueType[];
}) => {
  return (
    <div className="accuracy__content">
      <div className="accuracy__master-content">
        <label className="accuracy__label">Product Bullets</label>
        {contentApproved.bullets && contentApproved.bullets.length > 0 ? (
          contentApproved.bullets.map((val) => (
            <div key={val} className="accuracy__item">
              <div className="accuracy__bullet">{val}</div>
            </div>
          ))
        ) : (
          <div className="accuracy__item">
            <div className="accuracy__item">This product has no approved bullets</div>
          </div>
        )}
      </div>
      <div className="accuracy__current-content">
        <label className="accuracy__label">Product Bullets ({contentValues[1].displayValue})</label>
        {contentChanged.bullets && contentChanged.bullets.length > 0 ? (
          contentChanged.bullets.map((val, index) => showBullet(contentCurrent, val, index))
        ) : (
          <div className="accuracy__item">
            <div className="accuracy__item">This product has no bullets</div>
          </div>
        )}
      </div>
    </div>
  );
};

const renderProductImages = ({
  contentApproved,
  contentCurrent,
  contentChanged,
  contentValues
}: {
  contentApproved: any;
  contentCurrent: any;
  contentChanged: any;
  contentValues: ContentValueType[];
}) => {
  return (
    <div className="accuracy__content">
      <div className="accuracy__master-content">
        <label className="accuracy__label">Product Images</label>
        <div className="accuracy__images-container">
          <ImagePicker images={contentApproved.imageUrls} />
        </div>
      </div>
      <div className="accuracy__current-content">
        <label className="accuracy__label">Product Images ({contentValues[2].displayValue})</label>
        <div
          className={`accuracy__images-container ${
            isValid(contentChanged.imageUrls) ? '' : 'accuracy__images-container--invalid'
          }`}
        >
          <ImagePicker images={contentCurrent.imageUrls} contentChanged={contentChanged.imageUrls} />
        </div>
      </div>
    </div>
  );
};

const renderProductVideos = ({
  contentApproved,
  contentCurrent,
  contentChanged,
  contentValues
}: {
  contentApproved: any;
  contentCurrent: any;
  contentChanged: any;
  contentValues: ContentValueType[];
}) => {
  return (
    <div className="accuracy__content">
      <div className="accuracy__master-content">
        <label className="accuracy__label">Product Videos</label>
        <div className="accuracy__videos-container">
          <VideoPicker videos={contentApproved.videoUrls} />
        </div>
      </div>
      <div className="accuracy__current-content">
        <label className="accuracy__label">Product Videos ({contentValues[3].displayValue})</label>
        <div
          className={`accuracy__videos-container ${
            isValid(contentChanged.videoUrls) ? '' : 'accuracy__videos-container--invalid'
          }`}
        >
          <VideoPicker videos={contentCurrent.videoUrls} contentChanged={contentChanged.videoUrls} />
        </div>
      </div>
    </div>
  );
};

const renderAccuracyByRetailerSwitch = (retailerId: number, contentApproved, contentCurrent, contentChanged) => {
  const contentValues = buildContentValues(contentApproved, contentCurrent, contentChanged);
  const params = { contentApproved, contentCurrent, contentChanged, contentValues };
  switch (retailerId) {
    case 63: // Instacart
      return (
        <>
          {renderProductTitle(params)}
          {renderProductDescription(params)}
          {renderProductImages(params)}
          {renderProductVideos(params)}
        </>
      );

    default:
      return (
        <>
          {renderProductTitle(params)}
          {renderProductDescription(params)}
          {renderProductBullets(params)}
          {renderProductImages(params)}
          {renderProductVideos(params)}
        </>
      );
  }
};

const renderProductContentMatch = (retailerToUse, contentApproved, contentCurrent, contentChanged) => {
  return (
    <div className="accuracy__container">
      <div className="accuracy__content">
        <div className="accuracy__master-content">
          <h2 className="sl-header-text">Master Content</h2>
          <hr className="sl-divider sl-divider--no-margin-bottom" />
        </div>
        <div className="accuracy__current-content">
          <h2 className="sl-header-text">Current Content</h2>
          <hr className="sl-divider sl-divider--no-margin-bottom" />
        </div>
      </div>
      {/* render accuracy field depending on retailer ID */}
      {renderAccuracyByRetailerSwitch(retailerToUse, contentApproved, contentCurrent, contentChanged)}
    </div>
  );
};

export const fetchContentDocAsync = (requestBody: OmniBaseRequestBody) => async () => {
  const res = await axios.post('/omni/content/getContentDocs', requestBody);
  if (res && res.status === 200) {
    const { data } = res.data;
    if (isArray(data)) {
      return data[0];
    } else {
      throw Error('Invalid res');
    }
  } else {
    throw Error('Can not fetch the data');
  }
};

const OmniContentCompare: FC<OmniContentCompareProps> = ({ location, eventBus }) => {
  const filters = useSelector((state: ReduxStore) => state.filters);
  const omniRetailerState = useSelector((state: ReduxStore) => state.omniRetailers);
  const app = useSelector((state: ReduxStore) => state.app);
  const { pathname } = location;
  const defaultRetailer = determineTheDefault(filters, omniRetailerState.data, pathname);
  const [retailerToUse, setRetailerToUse] = useState(defaultRetailer);
  const { data: contentApproved, run: runApproved, loading: approveLoading } = useAsyncHook<{ [key: string]: any }>({});

  const { data: contentCurrent, run: runCurrent, loading: currentLoading } = useAsyncHook<{ [key: string]: any }>({});
  const { data: contentChanged, run: runChanged, loading: changedLoading } = useAsyncHook<{ [key: string]: any }>({});

  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const { startWeek, endWeek } = mainTimePeriod;
  const [entityType] = destructurePathName(pathname);
  const { subtab } = app.queryParams;
  const shouldSupportExport = entityType === 'product' || subtab === 'contentAccuracy';
  const exportFileName = `${app.name}_data_download.csv`;

  const handleRetailerDropDown = ({ retailerSelected }: { retailerSelected: number }) => {
    setRetailerToUse(retailerSelected);
  };

  useEffect(() => setRetailerToUse(defaultRetailer), [defaultRetailer]);

  useEffect(() => {
    eventBus.on('omniDropDownSelected', handleRetailerDropDown);
    const baseBody: OmniBaseRequestBody = {
      productIds: [],
      retailerIds: [],
      docType: 'approvedContent',
      startWeekId: startWeek,
      endWeekId: endWeek
    };
    const requestBody = addFilterToOmniBaseReqBody(baseBody, filters, pathname);
    baseBody.retailerIds = [retailerToUse];
    runApproved(fetchContentDocAsync(requestBody));
    runCurrent(fetchContentDocAsync({ ...requestBody, docType: 'currentContent' }));
    runChanged(fetchContentDocAsync({ ...requestBody, docType: 'contentChanged' }));
    return () => {
      eventBus.off('omniDropDownSelected', handleRetailerDropDown);
    };
  }, [pathname, filters, startWeek, endWeek, retailerToUse, eventBus]);

  return (
    <div>
      {approveLoading ||
      currentLoading ||
      changedLoading ||
      _isEmpty(contentApproved) ||
      _isEmpty(contentCurrent) ||
      _isEmpty(contentChanged) ? (
        <Loading className="spinner" />
      ) : (
        <>
          {shouldSupportExport ? (
            <Export
              omniRetailerSelectedFromDropdown={retailerToUse}
              exportRequest={[]}
              apiUrl={`/api/${app.name}/AdvancedSearchExport`}
              className="omni-grid-header__action-button"
              fileName={exportFileName}
            />
          ) : null}
          {renderProductContentMatch(retailerToUse, contentApproved, contentCurrent, contentChanged)}
        </>
      )}
    </div>
  );
};

export default withRouter(withBus('eventBus')(OmniContentCompare));
