import { QueryClient, QueryKey } from 'react-query';
import { createAsyncStoragePersistor } from 'react-query/createAsyncStoragePersistor-experimental';
// import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import _cloneDeep from 'lodash/cloneDeep';
import { store, factory as splitFactory } from 'src/main';
import { receiveEntitySalesMetrics } from 'src/store/modules/entitySearchService/operations';
import localForageAdapter from './localForageAdapter';

/**
 * Query client for React Query. Configure default
 * options here
 */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0, // fetchEntityMetrics already has retry logic
      cacheTime: 1000 * 60 * 60 // 1 hour
    }
  }
});

/**
 * Persists React Query calls with IndexedDB
 */
export const persistor = createAsyncStoragePersistor({
  storage: localForageAdapter
});

// if (window.location.href.includes('beacon-localhost')) {
//   persistQueryClient({
//     queryClient,
//     persistor,
//     maxAge: 1000 * 60 * 60 // 1 hour
//   });
// }

export interface FetchDataWithCacheOptions {
  /**
   * If given, will store in ESS with the given key
   */
  essKey?: string;
}

/**
 * Return true if the user has caching enabled
 * in the split IO experiment
 */
const isCacheEnabled = () => {
  const splitClient = splitFactory.client();
  const SPLIT_TREATMENT = 'drive_query_caching';
  const { user } = store.getState();

  return (
    splitClient.getTreatment(SPLIT_TREATMENT, {
      email: user && user.session && user.session.email
    }) === 'on'
  );
};

/**
 * Given a unique query key and callback that returns data,
 * call the callback if there is not a cached value associated
 * with the query key. Subsequent calls with the same query key
 * will not invoke the callback but will instead return the
 * cached value.
 *
 * If an `essKey` is given, the result will be stored in Redux ESS
 * with the given key
 */
export const fetchDataWithCache = async <T>(
  queryKey: QueryKey,
  fetchData: () => Promise<T>,
  options: FetchDataWithCacheOptions = {}
) => {
  // Do not cache any calls, fetch the data like normal
  if (!isCacheEnabled()) {
    return fetchData();
  }

  let fetchedData;

  const clonedKey = _cloneDeep(queryKey);
  const cachedData = queryClient.getQueryData(clonedKey);
  if (cachedData) {
    fetchedData = cachedData;
  } else {
    const data = await fetchData();
    queryClient.setQueryData(clonedKey, data);
    fetchedData = data;
  }

  if (options.essKey) {
    store.dispatch(receiveEntitySalesMetrics(options.essKey, fetchedData));
  }

  return fetchedData;
};

export default queryClient;
