import React from 'react';
import { AdConversionPathwaysDocument } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/ConversionPathways/types';
import {
  PathwaysSponsoredBrand,
  PathwaysSponsoredBrandOutlined,
  PathwaysSponsoredDisplayAds,
  PathwaysSponsoredDisplayAdsOutlined,
  PathwaysSponsoredProducts,
  PathwaysSponsoredProductsOutlined,
  PathwaysSponsoredDisplay,
  PathwaysSponsoredDisplayOutlined
} from 'src/components/SvgIcons/SvgIcons';
import _get from 'lodash/get';
import { safelyParsePathwayJson } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/ConversionPathways/utils';

interface PathwaysMapProps {
  data: AdConversionPathwaysDocument[];
}

interface Touchpoint {
  0: number;
  1: string;
}

const svgStyle = { width: '40px', height: '32px' };

const iconMap = {
  regular: {
    DSP: <PathwaysSponsoredDisplayAds style={svgStyle} />,
    SD: <PathwaysSponsoredDisplay style={svgStyle} />,
    SP: <PathwaysSponsoredProducts style={svgStyle} />,
    SB: <PathwaysSponsoredBrand style={svgStyle} />
  },
  outlined: {
    DSP: <PathwaysSponsoredDisplayAdsOutlined style={svgStyle} />,
    SD: <PathwaysSponsoredDisplayOutlined style={svgStyle} />,
    SP: <PathwaysSponsoredProductsOutlined style={svgStyle} />,
    SB: <PathwaysSponsoredBrandOutlined style={svgStyle} />
  }
};

const getIconForTouchpoint = (touchpoint: Touchpoint, pathLength: number) => {
  // The last touchpoint always gets a special outlined icon
  if (touchpoint[0] === pathLength) {
    return iconMap.outlined[touchpoint[1]];
  } else {
    return iconMap.regular[touchpoint[1]];
  }
};

/**
 * Accesses the conversion pathway and formats it for display
 *
 */
const Pathway = ({ dataPoint }: { dataPoint: AdConversionPathwaysDocument }) => {
  // Stringified tuples
  const pathway = _get(dataPoint, 'path', '[]');
  // Regex-replace and parse bad JSON
  let pathCollection = safelyParsePathwayJson(pathway);
  let pathLength = pathCollection.length;

  // Rarely, a pathway can have more than 4 touchpoints. We take the first and last 2 elements in the pathway collection and discard the rest.
  if (pathLength > 4) {
    pathCollection = [
      pathCollection[0],
      pathCollection[1],
      pathCollection[pathLength - 2],
      pathCollection[pathLength - 1]
    ];
    pathLength = pathCollection.length;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '65px', height: '100%' }}>
      {pathCollection.map((touchpoint: Touchpoint) => {
        return getIconForTouchpoint(touchpoint, pathLength);
      })}
    </div>
  );
};

/**
 *
 * @param data Sliced document data which corresponds to the visible chart columns
 * @returns Columns of Pathway components
 */
const PathwaysMap = ({ data }: PathwaysMapProps) => {
  return (
    <div style={{ height: '150px', display: 'flex' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: '45px', gap: '20px' }}>
        <span>Touchpoint 1</span>
        <span>Touchpoint 2</span>
        <span>Touchpoint 3</span>
        <span>Touchpoint 4</span>
      </div>
      <div style={{ display: 'flex', gap: '58px' }}>
        {data
          ? data.map((dataPoint, index) => {
              return <Pathway key={index} dataPoint={dataPoint} />;
            })
          : null}
      </div>
    </div>
  );
};

export default PathwaysMap;
