import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { ESSRequestContext, SearchRequestOverride } from 'src/store/modules/entitySearchService/operationTypes';
import { AD_MANAGER_STATE_PROPERTY_NAME } from '../constants';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useAppSelector } from 'src/utils/Hooks';
import _cloneDeep from 'lodash/cloneDeep';
import { AdvertisingOverlapData } from '../AdvertisingOverlap/types';
import { AdFrequencyResponse } from '../DspAdFrequency/types';
import { NewToBrandData } from '../NewToBrand/types';
import { AdSpendData, AdvertisingAnalyticsData } from '../AdvertisingAnalytics/types';

interface UseConnectFetchEntityMetricsArgs {
  indexName: string;
  visualizationKey: string;
  requestContextOverrides?: Partial<Omit<ESSRequestContext, 'indexName'>>;
  searchRequestOverrides: SearchRequestOverride[];
  onError?: () => unknown;
}

/**
 * Advanced Search client ID that will
 * be passed to all requests
 */
const CLIENT_ID = 'gl9avgmbG1';

export interface AdManagerSearchResultData {
  advertisingOverlapData?: AdvertisingOverlapData;
  adFrequencyData?: AdFrequencyResponse;
  newToBrandData?: NewToBrandData;
  advertisingAnalyticsData?: AdvertisingAnalyticsData;
  adSpendData?: AdSpendData;
}

/**
 * Wrapper around common logic for fetching entity metrics
 * from the Connect dashboards
 */
const useConnectFetchEntityMetrics = ({
  indexName,
  visualizationKey,
  requestContextOverrides,
  searchRequestOverrides,
  onError
}: UseConnectFetchEntityMetricsArgs) => {
  const app = useAppSelector((state) => state.app);
  const retailer = useAppSelector((state) => state.retailer);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const cancelSource = useRef(axios.CancelToken.source());

  const dispatch = useDispatch();
  const requestContext: ESSRequestContext = useMemo(
    () => ({
      entity: {
        id: CLIENT_ID,
        type: 'client'
      },
      indexName,
      app: {
        apiAppName: app.apiAppName,
        name: app.name
      },
      retailer,
      ...requestContextOverrides
    }),
    [app.apiAppName, app.name, requestContextOverrides, retailer, indexName]
  );

  const query = useQuery(
    [AD_MANAGER_STATE_PROPERTY_NAME, visualizationKey, searchRequestOverrides],
    async (): Promise<AdManagerSearchResultData> => {
      // Don't fetch if date range is undefined, which can happen
      // as the app loads
      if (!mainTimePeriod.startDayId || !mainTimePeriod.endDayId) {
        return;
      }

      const result = dispatch(
        fetchEntityMetrics(
          AD_MANAGER_STATE_PROPERTY_NAME,
          _cloneDeep(requestContext),
          _cloneDeep(searchRequestOverrides),
          cancelSource.current.token
        )
      ) as AdManagerSearchResultData;

      // eslint-disable-next-line consistent-return
      return result;
    },
    {
      retry: 0, // fetchEntityMetrics already has retry logic built in
      staleTime: 60 * 10 * 1000,
      refetchOnWindowFocus: false,
      onError
    }
  );

  // Cancel API calls when component is unmounted
  useEffect(() => {
    const cancelNetworkCalls = cancelSource.current.cancel;

    return () => cancelNetworkCalls();
  }, []);

  return query;
};

export default useConnectFetchEntityMetrics;
