import React, { useMemo } from 'react';
import { PlanInputContainer } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Components/PlanInputContainer';
import { PROMOTION_OPTIONS } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Components/RetailPricePromotionInputs';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { useAdSpendProjection } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAdSpendProjection';
import {
  NetImpactDataType,
  useNetImpact
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useNetImpact';
import { useOriginalProjection } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useOriginalProjection';
import { usePromotionAdjustmentMetrics } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/usePromotionAdjustmentMetrics';
import { parseOriginalProjectionResponse } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/parsers';
import {
  AdjustmentForm,
  PaidTrafficInput
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import {
  calculateWeightedAverageRating,
  getDerivedAdjustmentAmount,
  getFormattedStartAndEndDates,
  isContentScoreInput,
  isDefaultInput,
  isPaidTrafficInput,
  isRatingsReviewInput
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/utils';
import { ForecastsAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import StyledInput from 'src/components/BeaconRedesignComponents/common/StyledInput';
import { useMetricFormatter } from 'src/utils/Hooks';
import { safeDivide, shouldShowPromotionsAdjustment } from 'src/utils/app';
import { calculateWeeksBetweenWeekIds } from 'src/utils/dateUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { BEACON_FIELD_DISPLAY_NAMES } from 'src/utils/entityDefinitions/fields/beaconFieldDisplayNames';

/**
 * Styles applied to the read-only inputs
 */
export const inputStyles = {
  width: '80px',
  letterSpacing: '0.8px',
  height: '32px'
};

// Ad Spend constants
const AD_SPEND_INPUT_WIDTH = '90px';

/**
 * Stage 3 of 3 when creating an adjustment
 */
interface ConfirmAdjustmentProps {
  stacklineSku: string;
  retailerSku: string;
  categoryId: number;
  subcategoryId: number;
  adjustmentForm: AdjustmentForm;
  adjustmentData: ForecastsAdjustmentData;
  shouldOverrideCostPerClick: boolean;
}
export const ConfirmAdjustment = ({
  adjustmentData,
  adjustmentForm,
  stacklineSku,
  retailerSku,
  categoryId,
  subcategoryId,
  shouldOverrideCostPerClick
}: ConfirmAdjustmentProps) => {
  const formatMetric = useMetricFormatter();
  const { planInputAmounts, planType, startDate, endDate, isPromotionAdjustment, promotionType } = adjustmentForm;
  const { formattedStartDate, formattedEndDate } = getFormattedStartAndEndDates(startDate, endDate);
  const numberOfWeeks = calculateWeeksBetweenWeekIds(startDate, endDate) || 1;

  const { data: originalProjectionResponse, isLoading } = useOriginalProjection({
    stacklineSku,
    startWeekId: startDate,
    endWeekId: endDate
  });

  const parsedData = originalProjectionResponse ? parseOriginalProjectionResponse(originalProjectionResponse) : null;

  const { netImpact, loading: netImpactLoading } = useNetImpact({
    adjustmentId: adjustmentData ? adjustmentData.adjustmentId : null,
    adjustmentForm,
    stacklineSku,
    retailerSku,
    categoryId,
    subCategoryId: subcategoryId,
    netImpactDataType: NetImpactDataType.Unpublished,
    cpcFromUser: !shouldOverrideCostPerClick
  });

  const { adSpendProjection } = useAdSpendProjection({
    adjustmentId: adjustmentData ? adjustmentData.adjustmentId : null,
    adjustmentForm,
    stacklineSku,
    retailerSku,
    categoryId,
    subCategoryId: subcategoryId,
    isCpcFromUser: !shouldOverrideCostPerClick
  });

  const { data: promotionProjection } = usePromotionAdjustmentMetrics({
    adjustmentId: adjustmentData ? adjustmentData.adjustmentId : null,
    ignoreConflictWithAdjustmentIds: [adjustmentData ? adjustmentData.adjustmentId : ''],
    adjustmentForm,
    stacklineSku,
    retailerSku,
    categoryId,
    subCategoryId: subcategoryId,
    enabled: isPromotionAdjustment && planType === PlanTypeOption.RetailPrice,
    queryKeys: [isPromotionAdjustment, promotionType, adjustmentForm]
  });

  const originalProjectionText = useMemo(() => {
    if (!parsedData) {
      return '';
    }

    switch (planType) {
      case PlanTypeOption.OrganicTraffic:
        return `Originally, this product was projected to generate ${formatMetric(
          parsedData.organicTraffic_sum_value,
          METRICTYPE.VOLUME
        )} (${formatMetric(
          parsedData.organicTraffic_sum_value / numberOfWeeks,
          METRICTYPE.VOLUME
        )} per week) in organic traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;
      case PlanTypeOption.OtherTraffic:
        return `Originally, this product was projected to generate ${formatMetric(
          parsedData.otherTraffic_sum_value,
          METRICTYPE.VOLUME
        )} (${formatMetric(
          parsedData.otherTraffic_sum_value / numberOfWeeks,
          METRICTYPE.VOLUME
        )} per week) in other traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;
      case PlanTypeOption.BuyBox:
        return `Originally, this product was projected to have a ${formatMetric(
          parsedData.winPercentage_computed_value,
          METRICTYPE.PERCENT
        )} buy box rate during the period of ${formattedStartDate} to ${formattedEndDate}.`;

      case PlanTypeOption.ContentScore:
        return `Originally, this product was projected to have a ${formatMetric(
          parsedData.contentScore_avg_value,
          METRICTYPE.PERCENT
        )} content score during the period of ${formattedStartDate} to ${formattedEndDate}.`;

      case PlanTypeOption.InStockRate:
        return `Originally, this product was projected to generate ${formatMetric(
          parsedData.inStockRate_avg_value,
          METRICTYPE.PERCENT
        )} in-stock rate during the period of ${formattedStartDate} to ${formattedEndDate}.`;

      case PlanTypeOption.RetailPrice: {
        if (isPromotionAdjustment) {
          return `Originally, this product was projected to have a retail price of ${formatMetric(
            parsedData.retailPrice_avg_value,
            METRICTYPE.MONEY
          )} and generate ${formatMetric(parsedData.otherTraffic_sum_value, METRICTYPE.VOLUME)} 
(${formatMetric(
            parsedData.otherTraffic_sum_value / numberOfWeeks,
            METRICTYPE.VOLUME
          )} per week) in other traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;
        }
        return `Originally, this product was projected to have a retail price of ${formatMetric(
          parsedData.retailPrice_avg_value,
          METRICTYPE.MONEY,
          { showFullValue: true, decimalPlaces: 2 }
        )} during the period of ${formattedStartDate} to ${formattedEndDate}.`;
      }

      case PlanTypeOption.RatingsReviews:
        return `Originally, this product was projected to generate ${formatMetric(
          parsedData.reviewCount,
          METRICTYPE.VOLUME
        )} (${formatMetric(
          parsedData.reviewCount / numberOfWeeks,
          METRICTYPE.VOLUME
        )} per week) reviews with an average star rating of ${formatMetric(
          parsedData.weightedRating,
          METRICTYPE.DECIMAL
        )} during the period of ${formattedStartDate} to ${formattedEndDate}.`;

      case PlanTypeOption.PaidTraffic:
        return `Originally, this product was projected to spend ${formatMetric(
          parsedData.adSpend,
          METRICTYPE.MONEY
        )} (${formatMetric(
          parsedData.adSpend / numberOfWeeks,
          METRICTYPE.MONEY
        )} per week) on advertising and generate ${formatMetric(
          parsedData.adClicks,
          METRICTYPE.VOLUME
        )} (${formatMetric(
          parsedData.adClicks / numberOfWeeks,
          METRICTYPE.VOLUME
        )} per week) in paid traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;

      default:
        return '';
    }
  }, [parsedData, planType, formattedStartDate, formattedEndDate, formatMetric, numberOfWeeks, isPromotionAdjustment]);

  const newProjectionText = useMemo(() => {
    if (
      !parsedData ||
      (!adSpendProjection && planType === PlanTypeOption.PaidTraffic) ||
      (!promotionProjection && isPromotionAdjustment)
    ) {
      return '';
    }

    switch (planType) {
      case PlanTypeOption.OrganicTraffic:
        if (isDefaultInput(planInputAmounts)) {
          const planAmount = Number(planInputAmounts.total);
          return `After applying your adjustment, this product is now projected to generate ${formatMetric(
            planAmount * numberOfWeeks,
            METRICTYPE.VOLUME
          )} (${formatMetric(planAmount, METRICTYPE.VOLUME)} per week) in organic traffic during the period.`;
        }
        break;
      case PlanTypeOption.OtherTraffic:
        if (isDefaultInput(planInputAmounts)) {
          const planAmount = Number(planInputAmounts.total);
          return `After applying your adjustment, this product is now projected to generate ${formatMetric(
            planAmount * numberOfWeeks,
            METRICTYPE.VOLUME
          )} (${formatMetric(planAmount, METRICTYPE.VOLUME)} per week) in other traffic during the period.`;
        }
        break;
      case PlanTypeOption.BuyBox:
        if (isDefaultInput(planInputAmounts)) {
          const planAmount = Number(planInputAmounts.total) / 100;
          return `After applying your adjustment, this product is now projected to have a ${formatMetric(
            planAmount,
            METRICTYPE.PERCENT
          )} buy box rate during the period.`;
        }
        break;
      case PlanTypeOption.ContentScore:
        if (isContentScoreInput(planInputAmounts)) {
          const planAmount = getDerivedAdjustmentAmount({ planType, input: planInputAmounts });

          return `After applying your adjustment, this product is now projected to have a ${formatMetric(
            planAmount,
            METRICTYPE.PERCENT
          )} content score during the period.`;
        }
        break;
      case PlanTypeOption.InStockRate:
        if (isDefaultInput(planInputAmounts)) {
          const planAmount = Number(planInputAmounts.total) / 100;
          return `After applying your adjustment, this product is now projected to have a ${formatMetric(
            planAmount,
            METRICTYPE.PERCENT
          )} in-stock rate during the period.`;
        }
        break;
      case PlanTypeOption.RetailPrice:
        if (isDefaultInput(planInputAmounts)) {
          const planAmount = Number(planInputAmounts.total);

          if (isPromotionAdjustment) {
            return `After applying your adjustment, this product is now projected to have a retail price of ${formatMetric(
              planAmount,
              METRICTYPE.MONEY,
              { showFullValue: true, decimalPlaces: 2 }
            )} 
and generate ${formatMetric(promotionProjection.other_traffic * numberOfWeeks, METRICTYPE.VOLUME)} (${formatMetric(
              promotionProjection.other_traffic,
              METRICTYPE.VOLUME
            )} per week) in other traffic during the period.`;
          }
          return `After applying your adjustment, this product is now projected to have a retail price of ${formatMetric(
            planAmount,
            METRICTYPE.MONEY,
            { showFullValue: true, decimalPlaces: 2 }
          )} during the period.`;
        }
        break;
      case PlanTypeOption.RatingsReviews:
        if (isRatingsReviewInput(planInputAmounts)) {
          return `After applying your adjustment, this product is now projected to generate ${formatMetric(
            (Math.round(safeDivide(parsedData.reviewCount, numberOfWeeks)) +
              Number(planInputAmounts.reviewQuantityAmount)) *
              numberOfWeeks,
            METRICTYPE.VOLUME
          )} (${formatMetric(
            safeDivide(parsedData.reviewCount, numberOfWeeks) + Number(planInputAmounts.reviewQuantityAmount),
            METRICTYPE.VOLUME
          )} per week) reviews with an average star rating of ${formatMetric(
            Number(
              calculateWeightedAverageRating({
                currentReviewCount: parsedData.reviewCount,
                currentAverageStarRating: parsedData.weightedRating,
                additionalAverageStarRating: Number(planInputAmounts.averageStarRatingAmount),
                additionalReviewCount: Number(planInputAmounts.reviewQuantityAmount)
              }).toFixed(1)
            ),
            METRICTYPE.DECIMAL
          )} during the period.`;
        }
        break;

      case PlanTypeOption.PaidTraffic:
        if (isPaidTrafficInput(planInputAmounts)) {
          return `After applying your adjustment, this product is now projected to spend ${formatMetric(
            Number(planInputAmounts.adSpend) * numberOfWeeks,
            METRICTYPE.MONEY
          )} (${formatMetric(
            Number(planInputAmounts.adSpend),
            METRICTYPE.MONEY
          )} per week) in ad spend and generate ${formatMetric(
            adSpendProjection.paid_traffic * numberOfWeeks,
            METRICTYPE.VOLUME
          )} (${formatMetric(
            adSpendProjection.paid_traffic,
            METRICTYPE.VOLUME
          )} per week) in paid traffic during the period.`;
        }
        break;
      default:
        return '';
    }
    return null;
  }, [parsedData, planType, numberOfWeeks, formatMetric, isPromotionAdjustment, promotionProjection]);

  const directionalTerm = netImpact > 0 ? 'increase' : 'decrease';
  const locationTerm = netImpact > 0 ? 'above' : 'below';

  const netImpactText = useMemo(() => {
    if (!netImpactLoading) {
      const formattedNetImpact = `${formatMetric(netImpact, METRICTYPE.VOLUME)} units sold`;

      switch (planType) {
        case PlanTypeOption.OrganicTraffic:
          return `This revision to your organic traffic plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
        case PlanTypeOption.OtherTraffic:
          return `This revision to your other traffic plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
        case PlanTypeOption.BuyBox:
          return `This revision to your buy box rate plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
        case PlanTypeOption.ContentScore:
          return `This revision to your content score plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
        case PlanTypeOption.InStockRate:
          return `This revision to your in-stock rate plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
        case PlanTypeOption.RetailPrice:
          return `This revision to your retail price plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
        case PlanTypeOption.RatingsReviews:
          return `This revision to your reviews plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;

        case PlanTypeOption.PaidTraffic:
          return `This revision to your paid traffic plan is projected to have a ${formattedNetImpact} ${directionalTerm} ${locationTerm} the previous sales forecast during the period.`;
        default:
          return '';
      }
    } else {
      return '';
    }
  }, [netImpact, netImpactLoading, planType, locationTerm, directionalTerm, formatMetric]);

  /**
   * For Paid Traffic (Ad Spend) adjustments, we render additional read-only inputs populated with current metrics
   * and adjustment plan metrics
   */
  const renderReadOnlyInputs = () => {
    switch (planType) {
      case PlanTypeOption.PaidTraffic: {
        const { adSpend, costPerClick } = planInputAmounts as PaidTrafficInput;
        const {
          adSpend: originalAdSpend,
          costPerClick: originalCostPerClick,
          paidTrafficValue_sum_value: originalPaidTraffic,
          adSales: originalAdSales,
          returnOnAdSpend: originalReturnOnAdSpend
        } = parsedData;

        const {
          roas: newProjectedRoas,
          ad_sales: newProjectedAdSpend,
          paid_traffic: newPaidTraffic
        } = adSpendProjection;
        return (
          <>
            <PlanInputContainer
              width={AD_SPEND_INPUT_WIDTH}
              label={BEACON_FIELD_DISPLAY_NAMES.AD_SPEND}
              firstInput={
                <StyledInput
                  sx={inputStyles}
                  readOnly
                  value={formatMetric(Number(originalAdSpend) / numberOfWeeks, METRICTYPE.MONEY)}
                />
              }
              secondInput={
                <StyledInput sx={inputStyles} readOnly value={formatMetric(Number(adSpend), METRICTYPE.MONEY)} />
              }
            />
            <PlanInputContainer
              width={AD_SPEND_INPUT_WIDTH}
              label={BEACON_FIELD_DISPLAY_NAMES.COST_PER_CLICK}
              firstInput={
                <StyledInput
                  sx={inputStyles}
                  readOnly
                  value={formatMetric(Number(originalCostPerClick), METRICTYPE.MONEY)}
                />
              }
              secondInput={
                <StyledInput sx={inputStyles} readOnly value={formatMetric(Number(costPerClick), METRICTYPE.MONEY)} />
              }
            />
            <PlanInputContainer
              width={AD_SPEND_INPUT_WIDTH}
              label={BEACON_FIELD_DISPLAY_NAMES.PAID_TRAFFIC}
              firstInput={
                <StyledInput
                  sx={inputStyles}
                  readOnly
                  value={formatMetric(Number(originalPaidTraffic) / numberOfWeeks, METRICTYPE.VOLUME)}
                />
              }
              secondInput={
                <StyledInput sx={inputStyles} readOnly value={formatMetric(newPaidTraffic, METRICTYPE.VOLUME)} />
              }
            />
            <PlanInputContainer
              width={AD_SPEND_INPUT_WIDTH}
              label={BEACON_FIELD_DISPLAY_NAMES.AD_SALES}
              firstInput={
                <StyledInput
                  sx={inputStyles}
                  readOnly
                  value={formatMetric(Number(originalAdSales) / numberOfWeeks, METRICTYPE.MONEY)}
                />
              }
              secondInput={
                <StyledInput sx={inputStyles} readOnly value={formatMetric(newProjectedAdSpend, METRICTYPE.MONEY)} />
              }
            />
            <PlanInputContainer
              width={AD_SPEND_INPUT_WIDTH}
              label={BEACON_FIELD_DISPLAY_NAMES.RETURN_ON_AD_SPEND}
              firstInput={
                <StyledInput
                  sx={inputStyles}
                  readOnly
                  value={formatMetric(Number(originalReturnOnAdSpend), METRICTYPE.MONEY)}
                />
              }
              secondInput={
                <StyledInput sx={inputStyles} readOnly value={formatMetric(newProjectedRoas, METRICTYPE.MONEY)} />
              }
            />
          </>
        );
      }
      default:
        return null;
    }
  };

  return (
    <Flex flexDirection="column">
      {/* Plan Confirmation */}
      <Flex marginTop="24px" gap="sm" flexDirection="column">
        <Text variant="subtitle2">Plan Change Confirmation</Text>
        <Flex flexDirection="column" gap="sm">
          {isLoading ? (
            <>
              <SlSkeleton variant="rounded" height={19} width="100%"></SlSkeleton>
              <SlSkeleton variant="rounded" height={19} width="100%"></SlSkeleton>
            </>
          ) : (
            <>
              <Text variant="body2">{originalProjectionText}</Text>
              <Text variant="body2">{newProjectionText}</Text>
            </>
          )}
        </Flex>
      </Flex>

      {/* Sales net impact */}
      <Flex marginTop="24px" gap="sm" flexDirection="column">
        <Text variant="subtitle2">
          {isPromotionAdjustment
            ? `This price adjustment is a ${
                PROMOTION_OPTIONS.find((option) => promotionType === option.id)?.label ?? ''
              } promotion.`
            : planType === PlanTypeOption.RetailPrice && shouldShowPromotionsAdjustment()
            ? 'This price adjustment is not a promotion.'
            : 'Sales Net Impact'}
        </Text>
        <Flex flexDirection="column" gap="sm">
          {netImpactLoading ? (
            <>
              <SlSkeleton variant="rounded" height={19} width="100%"></SlSkeleton>
              <SlSkeleton variant="rounded" height={19} width="100%"></SlSkeleton>
            </>
          ) : (
            <>
              <Text variant="body2">{netImpactText}</Text>
            </>
          )}
        </Flex>
      </Flex>

      {/* Advertising summary - *Paid Traffic Only* */}
      {planType === PlanTypeOption.PaidTraffic && !netImpactLoading && adSpendProjection ? (
        <Flex marginTop="24px" gap="sm" flexDirection="column">
          <Text variant="subtitle2">Advertising Summary</Text>
          <Flex flexDirection="column" gap="sm">
            <Text variant="body2">
              The table below displays how this plan change will impact key advertising metrics on a weekly basis.
            </Text>
          </Flex>
          <Flex flexDirection="column" gap="sm" marginTop="5px" marginBottom="90px">
            {renderReadOnlyInputs()}
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
};
