import { COMMON_FIELDS, DAILY_DATA_FIELDS } from 'src/utils/entityDefinitions/fields/commonFieldDefinitions';
import {
  AD_CAMPAIGN_DAILY_METRICS_FIELDS,
  AD_OPTIMIZATION_HISTORY_FIELDS,
  ADMANAGER_ADCAMPAIGN_METADATA_FIELDS,
  ADMANAGER_ADENTITY_METADATA_FIELDS,
  ADMANAGER_ADPORTFOLIO_METADATA_FIELDS,
  ADMANAGER_ADTARGET_METADATA_FIELDS,
  ADMANAGER_ADGROUP_METADATA_FIELDS,
  BUDGET_PACING_FIELDS,
  AD_INCREMENTAL_FIELDS
} from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import {
  ADVERTISING_METRICS_FIELDS,
  ADVERTISING_METADATA_FIELDS,
  ADVERTISING_INCREMENTALITY_METRICS_FIELDS
} from 'src/utils/entityDefinitions/fields/beaconAdvertisingFieldDefinitions';
import { BEACON_INDEX_FIELDS } from 'src/utils/entityDefinitions/indexes/beaconIndexDefinitions';

export const ADVERTISING_INDEX_FIELDS = {
  ...BEACON_INDEX_FIELDS,
  adCampaign: {
    ...COMMON_FIELDS,
    ...ADVERTISING_METADATA_FIELDS,
    ...ADMANAGER_ADENTITY_METADATA_FIELDS,
    ...ADMANAGER_ADPORTFOLIO_METADATA_FIELDS,
    ...AD_CAMPAIGN_DAILY_METRICS_FIELDS
  },
  adEntityDailyMetrics: {
    ...COMMON_FIELDS,
    ...DAILY_DATA_FIELDS,
    ...ADMANAGER_ADENTITY_METADATA_FIELDS,
    ...ADVERTISING_METRICS_FIELDS,
    ...ADVERTISING_INCREMENTALITY_METRICS_FIELDS,
    ...ADVERTISING_METADATA_FIELDS,
    ...ADMANAGER_ADTARGET_METADATA_FIELDS
  },
  adPortfolioDailyMetrics: {
    ...COMMON_FIELDS,
    ...DAILY_DATA_FIELDS,
    ...ADMANAGER_ADENTITY_METADATA_FIELDS,
    ...ADMANAGER_ADPORTFOLIO_METADATA_FIELDS,
    ...ADVERTISING_METRICS_FIELDS,
    ...ADVERTISING_INCREMENTALITY_METRICS_FIELDS,
    ...ADVERTISING_METADATA_FIELDS,
    ...ADMANAGER_ADTARGET_METADATA_FIELDS
  },
  adCampaignDailyMetrics: {
    ...COMMON_FIELDS,
    ...DAILY_DATA_FIELDS,
    ...ADMANAGER_ADENTITY_METADATA_FIELDS,
    ...ADMANAGER_ADPORTFOLIO_METADATA_FIELDS,
    ...ADMANAGER_ADCAMPAIGN_METADATA_FIELDS,
    ...ADVERTISING_METRICS_FIELDS,
    ...ADVERTISING_INCREMENTALITY_METRICS_FIELDS,
    ...ADVERTISING_METADATA_FIELDS,
    ...ADMANAGER_ADTARGET_METADATA_FIELDS,
    ...AD_CAMPAIGN_DAILY_METRICS_FIELDS,
    ...AD_INCREMENTAL_FIELDS
  },
  adCampaignAdGroupDailyMetrics: {
    ...COMMON_FIELDS,
    ...DAILY_DATA_FIELDS,
    ...ADMANAGER_ADENTITY_METADATA_FIELDS,
    ...ADMANAGER_ADPORTFOLIO_METADATA_FIELDS,
    ...ADMANAGER_ADCAMPAIGN_METADATA_FIELDS,
    ...ADMANAGER_ADGROUP_METADATA_FIELDS(),
    ...ADVERTISING_METRICS_FIELDS,
    ...ADVERTISING_INCREMENTALITY_METRICS_FIELDS,
    ...ADVERTISING_METADATA_FIELDS,
    ...ADMANAGER_ADTARGET_METADATA_FIELDS,
    ...AD_CAMPAIGN_DAILY_METRICS_FIELDS
  },
  adCampaignAdGroupProduct: {
    ...COMMON_FIELDS,
    ...DAILY_DATA_FIELDS,
    ...ADMANAGER_ADENTITY_METADATA_FIELDS,
    ...ADMANAGER_ADPORTFOLIO_METADATA_FIELDS,
    ...ADMANAGER_ADCAMPAIGN_METADATA_FIELDS,
    ...ADMANAGER_ADGROUP_METADATA_FIELDS(),
    ...ADVERTISING_METRICS_FIELDS,
    ...ADVERTISING_INCREMENTALITY_METRICS_FIELDS,
    ...ADVERTISING_METADATA_FIELDS,
    ...ADMANAGER_ADTARGET_METADATA_FIELDS,
    ...AD_CAMPAIGN_DAILY_METRICS_FIELDS
  },
  adCampaignAdGroupProductDailyMetrics: {
    ...COMMON_FIELDS,
    ...DAILY_DATA_FIELDS,
    ...ADMANAGER_ADENTITY_METADATA_FIELDS,
    ...ADMANAGER_ADPORTFOLIO_METADATA_FIELDS,
    ...ADMANAGER_ADCAMPAIGN_METADATA_FIELDS,
    ...ADMANAGER_ADGROUP_METADATA_FIELDS(),
    ...ADVERTISING_METRICS_FIELDS,
    ...ADVERTISING_INCREMENTALITY_METRICS_FIELDS,
    ...ADVERTISING_METADATA_FIELDS,
    ...ADMANAGER_ADTARGET_METADATA_FIELDS,
    ...AD_CAMPAIGN_DAILY_METRICS_FIELDS,
    ...AD_INCREMENTAL_FIELDS
  },
  adCampaignAdGroupTargetDailyMetrics: {
    ...COMMON_FIELDS,
    ...DAILY_DATA_FIELDS,
    ...ADMANAGER_ADENTITY_METADATA_FIELDS,
    ...ADMANAGER_ADPORTFOLIO_METADATA_FIELDS,
    ...ADMANAGER_ADGROUP_METADATA_FIELDS(),
    ...ADMANAGER_ADTARGET_METADATA_FIELDS,
    ...ADVERTISING_METRICS_FIELDS,
    ...ADVERTISING_INCREMENTALITY_METRICS_FIELDS,
    ...ADVERTISING_METADATA_FIELDS,
    ...AD_OPTIMIZATION_HISTORY_FIELDS,
    ...AD_CAMPAIGN_DAILY_METRICS_FIELDS,
    ...ADMANAGER_ADCAMPAIGN_METADATA_FIELDS,
    ...AD_INCREMENTAL_FIELDS
  },
  adCampaignAdGroupProductTargetDailyMetrics: {
    ...COMMON_FIELDS,
    ...DAILY_DATA_FIELDS,
    ...ADMANAGER_ADENTITY_METADATA_FIELDS,
    ...ADMANAGER_ADGROUP_METADATA_FIELDS(),
    ...ADMANAGER_ADPORTFOLIO_METADATA_FIELDS,
    ...ADMANAGER_ADCAMPAIGN_METADATA_FIELDS,
    ...ADMANAGER_ADTARGET_METADATA_FIELDS,
    ...ADVERTISING_METRICS_FIELDS,
    ...ADVERTISING_INCREMENTALITY_METRICS_FIELDS,
    ...AD_CAMPAIGN_DAILY_METRICS_FIELDS
  },
  adOptimizationHistory: {
    ...COMMON_FIELDS,
    ...DAILY_DATA_FIELDS,
    ...AD_OPTIMIZATION_HISTORY_FIELDS,
    ...ADMANAGER_ADENTITY_METADATA_FIELDS,
    ...ADMANAGER_ADPORTFOLIO_METADATA_FIELDS,
    ...ADMANAGER_ADCAMPAIGN_METADATA_FIELDS
  },
  budgetPacingDailyMetrics: {
    ...COMMON_FIELDS,
    ...DAILY_DATA_FIELDS,
    ...ADMANAGER_ADENTITY_METADATA_FIELDS,
    ...ADMANAGER_ADPORTFOLIO_METADATA_FIELDS,
    ...ADMANAGER_ADCAMPAIGN_METADATA_FIELDS,
    ...ADMANAGER_ADGROUP_METADATA_FIELDS(),
    ...ADVERTISING_METRICS_FIELDS,
    ...ADVERTISING_INCREMENTALITY_METRICS_FIELDS,
    ...ADVERTISING_METADATA_FIELDS,
    ...ADMANAGER_ADTARGET_METADATA_FIELDS,
    ...AD_CAMPAIGN_DAILY_METRICS_FIELDS,
    ...BUDGET_PACING_FIELDS
  }
};
