import _cloneDeep from 'lodash/cloneDeep';

import { INDEX_FIELDS, ENTITIES, METRICTYPE, DATATYPE } from 'src/utils/entityDefinitions';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import CommonSummaryTrend from 'src/components/EntityPage/SummaryTrendChart/CommonSummaryTrend';
import CommonSummaryInfo from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { buildTrendingProductsWidgets } from 'src/components/Grids/TrendingProductsGrid';
import {
  buildTrendWidgetConfig,
  buildSummaryTrendColumnGroupsWidget,
  summarySubTrendView,
  summaryTrendStyle
} from 'src/components/Layout/LayoutUtil';
import { buildSubtitleDisplayName } from 'src/utils/filters';
import getBeaconBasicPageLayout from './BeaconBasicPageLayout';

import { onPrimeMode } from 'src/store/modules/user/selectors';

export default function getBeaconPrimePageLayout({ app, retailer, user, tab, metricType, entity }) {
  if (!onPrimeMode()) {
    return getBeaconBasicPageLayout({ app, retailer, user, tab, metricType, entity });
  }

  const indexName = 'sales';
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
  const retailerIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'));
  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.atlas.retailer),
    categoryEntity: _cloneDeep(ENTITIES.atlas.category),
    relatedEntity: _cloneDeep(ENTITIES.atlas.brand),
    weekIdField,
    retailerIdField
  };

  const widgets = [];

  const keywordsWithTrafficWidgetConfig = buildTrendWidgetConfig(
    app,
    'traffic',
    entity,
    'weekId',
    ['searchTerm'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: {
            legendTitle: 'KEYWORDS',
            enabled: false
          },
          ...summarySubTrendView
        },
        linkTo: { tab: 'traffic', subtab: 'totalClicks', scrollTo: 'searchTermByweekId' },
        container: { ...summaryTrendStyle.subTrend }
      },
      CustomComponent: CommonSummaryTrend
    }
  );

  keywordsWithTrafficWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'searchTerm'
  ).conditions = {
    rangeFilters: [
      {
        fieldName: 'totalClicks',
        minValue: 1
      }
    ]
  };

  widgets.push({
    CustomComponent: CommonSummaryInfo,
    name: 'CommonSummaryInfo',
    view: {
      name: 'CommonSummaryInfo',
      // eslint-disable-next-line no-shadow
      buildSubTitle: ({ retailer, mainEntity, filters, categories, app }) =>
        mainEntity ? buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app).displayName : 'Summary'
    }
  });

  const productsWithSalesWidgetConfig = buildTrendWidgetConfig(
    app,
    'sales',
    entity,
    'weekId',
    ['stacklineSku'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: {
            legendTitle: 'PRODUCTS',
            enabled: false
          },
          ...summarySubTrendView
        },
        linkTo: { tab: 'sales', subtab: 'retailSales', scrollTo: 'productCountMetric' },
        container: { ...summaryTrendStyle.subTrend }
      },
      CustomComponent: CommonSummaryTrend
    }
  );

  productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'stacklineSku'
  ).conditions = {
    rangeFilters: [
      {
        fieldName: 'unitsSold',
        minValue: 4
      }
    ]
  };

  productsWithSalesWidgetConfig.view.metricFields[0].metricType = METRICTYPE.VOLUME;
  productsWithSalesWidgetConfig.view.metricFields[0].dataType = DATATYPE.INTEGER;
  productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'stacklineSku'
  );

  const stacklineSkuAggregationField =
    productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
      (x) => x.name === 'stacklineSku'
    );
  stacklineSkuAggregationField.metricType = METRICTYPE.VOLUME;
  stacklineSkuAggregationField.dataType = DATATYPE.INTEGER;

  const summaryTrendColumnWidgetGroup = buildSummaryTrendColumnGroupsWidget({ app, entity, weekIdField }, [
    [
      ['traffic', 'totalClicks', 'Traffic', 'traffic', 'totalClicks'],
      ['traffic', 'organicClicks', 'TRAFFIC - ORGANIC', 'traffic', 'organicClicks'],
      ['advertising', 'clicks', 'TRAFFIC - ADS', 'traffic', 'adClicks'],
      ['advertising', 'spend', 'AD SPEND', 'traffic', 'adClicks'],
      ['advertising', 'sales', 'AD SALES', 'traffic', 'adClicks'],
      ['advertising', 'returnOnAdSpend', 'ROAS', 'traffic', 'adClicks']
    ],
    [
      ['sales', 'purchaseRate', 'Conversion', 'conversion', 'purchaseRate'],
      ['sales', 'retailPrice', 'RETAIL PRICE', 'sales', 'retailPrice'],
      ['content', 'contentScore', 'CONTENT SCORE', 'content', 'contentScore'],
      ['sales', 'instockRate', 'IN-STOCK RATE', 'sales', 'inventory'],
      ['reviews', 'stars', 'RATING', 'reviews', 'reviewTrends'],
      ['buybox', 'winPercentage', 'BUY BOX', 'buybox', 'winPercentage']
    ],
    [
      ['sales', 'retailSales', 'Retail Sales', 'sales', 'retailSales'],
      ['sales', 'unitsSold', 'UNITS SOLD', 'sales', 'unitsSold'],
      ['sales', 'wholesaleSales', 'WHOLESALE SALES', 'sales', 'retailSales'],
      ['sales', 'retailerGrossMargin', 'RETAILER MARGIN', 'sales', 'retailerGrossMargin'],
      ['sales', 'brandGrossMargin', 'BRAND MARGIN', 'sales', 'retailerGrossMargin'],
      ['traffic', 'stacklineSku', 'PRODUCTS', 'sales', 'unitsSold']
    ]
  ]);

  widgets.push(summaryTrendColumnWidgetGroup);

  // TRENDING PRODUCTS
  if (entity.type !== 'product') {
    widgets.push(...buildTrendingProductsWidgets({ app, retailer, entity }));
  }

  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    widgets,
    containerStyle: {
      marginTop: 30
    },
    dataConfig
  };
}
