/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import { Conditions, RangeFilter } from 'sl-api-connector/types';
import { mergeConditions } from 'sl-api-connector/search/conditions';

import { buildSubtitleDisplayName } from 'src/utils/filters';
import { store } from 'src/main';
import { getChartDisplayTimePeriod, setSubTitle } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import ColumnChartContainer from 'src/components/Charts/Column';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import WeeklyMetricsGridContainer from 'src/components/EntityGrid/Table/WeeklyMetricsGridContainer';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import * as scratchActions from 'src/store/modules/scratch/actions';
import EntityGridInner from 'src/components/EntityGrid';
import ReduxStore from 'src/types/store/reduxStore';
import { WidgetProps, Widget } from 'src/types/application/widgetTypes';
import { MetricField } from 'src/types/application/types';
import { buildAggregations } from 'src/components/AdManager/Search';

const EntityGrid = EntityGridInner as any;

const mapSellerSummaryBarChartStateToProps = ({ entitySearchService, scratch, ...state }: ReduxStore) => ({
  // 2 `_pick`s here just for ergonomics; only does nice type inference when the list of picked props is small.
  ..._pick(state, ['app', 'retailer', 'mainTimePeriod', 'comparisonTimePeriod']),
  ..._pick(state, ['filters', 'categories', 'entityService']),
  mainEntityMetrics: entitySearchService.mainEntityMetrics,
  mainEntity: state.entityService.mainEntity,
  buyBoxSellers: scratch.buyBoxSellers
});

const fetchWeeklyMetricsGridData = (
  {
    mainEntity,
    retailer,
    mainTimePeriod,
    app,
    indexName
  }: Pick<ReduxStore, 'retailer' | 'app' | 'mainTimePeriod'> & {
    mainEntity: ReduxStore['entityService']['mainEntity'];
    indexName: string;
  },
  widget: Widget,
  queryConditions: Conditions,
  aggregationConditions: Conditions
) => {
  const weeklyMetricsGridAggregationFields = widget.data.aggregationFields;
  const [{ aggregations: mainEntityWeeklyAggregationFields, derivations: mainEntityWeeklyTrendDerivedFields }] =
    buildAggregations(weeklyMetricsGridAggregationFields);

  const timePeriodRangeFilter: RangeFilter = {
    fieldName: 'weekId',
    minValue: mainTimePeriod.startWeek,
    maxValue: mainTimePeriod.endWeek
  };

  const mainEntityWeeklyAggregations = {
    groupByFieldName: widget.data.groupByField.name,
    aggregationFields: mainEntityWeeklyAggregationFields,
    sortDirection: null,
    sortByAggregationField: null,
    conditions: {
      termFilters: [],
      rangeFilters: [timePeriodRangeFilter]
    }
  };

  // fetch sales data for the current entity filter.
  const searchRequestOverrides = [
    {
      conditions: queryConditions,
      aggregations: [
        {
          ...mainEntityWeeklyAggregations,
          conditions: mergeConditions(_cloneDeep(aggregationConditions), { rangeFilters: [timePeriodRangeFilter] })
        }
      ]
    }
  ];

  store.dispatch(
    fetchEntityMetrics(
      'mainEntityMetricsWeeklyLossPercent',
      { entity: mainEntity, retailer, app, indexName, derivedFields: mainEntityWeeklyTrendDerivedFields },
      searchRequestOverrides
    )
  );
};

const mapWeeklyMetricsGridStateToProps = ({ ...state }: ReduxStore) => ({
  ..._pick(state, ['retailer', 'app', 'mainTimePeriod']),
  mainEntity: state.entityService.mainEntity
});

const WeeklyMetricsGridInner: React.FC<WidgetProps & ReturnType<typeof mapWeeklyMetricsGridStateToProps>> = ({
  mainEntity,
  retailer,
  app,
  widget,
  queryConditions,
  mainTimePeriod,
  aggregationConditions,
  ...widgetProps
}) => {
  useEffect(() => {
    const { indexName } = widget.data.chartMainField;
    fetchWeeklyMetricsGridData(
      { mainEntity, retailer, app, indexName, mainTimePeriod },
      widget,
      queryConditions,
      aggregationConditions
    );
  }, [app, queryConditions, retailer, mainTimePeriod, aggregationConditions, mainEntity, widget]);

  return (
    <WeeklyMetricsGridContainer
      {...widgetProps}
      widget={widget}
      entitySearchServicePropertyName="mainEntityMetricsWeeklyLossPercent"
    />
  );
};

export const SellerSummaryWeeklyMetricsGrid = connect(mapWeeklyMetricsGridStateToProps)(WeeklyMetricsGridInner);

const fetchSellerSummaryBarChartData = ({
  entityService,
  app,
  retailer,
  mainTimePeriod,
  match,
  widget,
  queryConditions
}: Pick<ReduxStore, 'entityService' | 'app' | 'retailer' | 'mainTimePeriod'> &
  Pick<WidgetProps, 'widget' | 'queryConditions'> & { match: any }) => {
  const { mainEntity } = entityService;
  const { indexName } = widget.data.chartMainField as MetricField;
  const { id } = match.params;
  const mainEntityId = mainEntity && `${mainEntity.id}`;
  const comparisonEntityId = id === mainEntityId ? mainEntityId : (mainEntity! as any).hashId;
  // the main entity has to match with what is in the current url
  if (!mainEntity || id !== comparisonEntityId) {
    return;
  }

  const mainEntityAggregationMetricFields = [...widget.data.aggregationFields];
  const mainEntityGroupByField = widget.data.groupByField;

  const mainEntityAggregationConditions = mainEntityAggregationMetricFields.reduce(
    (acc, field) => mergeConditions(acc, field.conditions),
    _cloneDeep(
      mergeConditions(
        { termFilters: [], rangeFilters: [] },
        {
          termFilters: [
            {
              fieldName: 'retailerId',
              values: [retailer.id]
            }
          ]
        }
      )
    )
  );
  const aggregationItems = buildAggregations(mainEntityAggregationMetricFields);

  // [{ aggregations: mainEntityAggregationFields, derivations: mainEntityDerivedFields }]
  const mainEntityAggregations = {
    groupByFieldName: mainEntityGroupByField ? mainEntityGroupByField.name : 'retailerId',
    aggregationFields: aggregationItems[0].aggregations,
    sortDirection: null,
    sortByAggregationField: null,
    conditions: {
      termFilters: [],
      rangeFilters: []
    }
  };

  const timePeriodRangeFilter: RangeFilter = {
    fieldName: 'weekId',
    minValue: mainTimePeriod.startWeek,
    maxValue: mainTimePeriod.endWeek
  };

  // fetch sales data for the current entity filter.
  const searchRequestOverrides = [
    {
      conditions: queryConditions,
      aggregations: [
        {
          ...mainEntityAggregations,
          conditions: mergeConditions(mainEntityAggregationConditions, {
            rangeFilters: [timePeriodRangeFilter]
          })
        }
      ],
      pageSize: 200
    }
  ];
  if (aggregationItems[0].derivations.length > 0 && aggregationItems.length > 0) {
    searchRequestOverrides.push({
      indexName: aggregationItems[0].indexName,
      doAggregation: true,
      conditions: queryConditions,
      aggregations: [
        {
          ...{
            groupByFieldName: aggregationItems[0].groupByFieldName || mainEntityAggregations.groupByFieldName,
            aggregationFields: aggregationItems[0].aggregations,
            sortDirection: null,
            sortByAggregationField: null,
            conditions: {
              termFilters: [],
              rangeFilters: []
            }
          },
          conditions: mergeConditions(mainEntityAggregationConditions, {
            rangeFilters: [timePeriodRangeFilter]
          })
        }
      ],
      pageSize: 200
    });
  }
  store.dispatch(
    fetchEntityMetrics(
      'mainEntityMetrics',
      {
        entity: mainEntity,
        retailer,
        app,
        indexName,
        derivedFields: aggregationItems[0].derivations
      },
      searchRequestOverrides
    )
  );
};

const SellerSummaryBarChartInner: React.FC<
  WidgetProps & ReturnType<typeof mapSellerSummaryBarChartStateToProps> & { match: any }
> = ({
  widget: providedWidget,
  i,
  queryConditions,
  mainEntityMetrics,
  app,
  retailer,
  mainTimePeriod,
  comparisonTimePeriod,
  mainEntity,
  filters,
  categories,
  entityService,
  buyBoxSellers = {},
  buyBoxSellers: { selectedMerchantName } = {},
  match
}) => {
  useEffect(() => {
    fetchSellerSummaryBarChartData({
      app,
      retailer,
      entityService,
      widget: providedWidget,
      mainTimePeriod,
      queryConditions,
      match
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityService.mainEntity, entityService.comparisonEntity, queryConditions]);

  if (!mainEntityMetrics || !mainEntityMetrics.sellerWinPercentage_by_merchantName) {
    return <GenericChartLoading />;
  }

  const subtitle = buildSubtitleDisplayName(retailer, mainEntity as any, filters, categories, app); // TODO as any
  // TODO: This is very bad; it *mutates Redux* and must be destroyed.
  setSubTitle(subtitle, [mainEntityMetrics]);

  const retailerSellerPageUrl = `https://${app.targetUrl}/api/utility/OpenRetailerMerchantPageForMerchantName?appName=${app.name}&retailerId=${retailer.id}&merchantName=`;

  const chartDisplayTimePeriod = getChartDisplayTimePeriod(mainTimePeriod);
  const chartComparisonDisplayTimePeriod = getChartDisplayTimePeriod(comparisonTimePeriod);

  const widget = _cloneDeep(providedWidget);
  const sellersToRender = {
    ...mainEntityMetrics.sellerWinPercentage_by_merchantName,
    data: mainEntityMetrics.sellerWinPercentage_by_merchantName.data.filter((d) => d.value.toFixed(4) > 0)
  };

  // make sure other merchants is last
  const otherMerchantsIndex = sellersToRender.data.findIndex((el) => el.name === 'Other Merchants');
  if (otherMerchantsIndex > -1) {
    sellersToRender.data.push(sellersToRender.data.splice(otherMerchantsIndex, 1)[0]);
  }

  widget.view.chartPropsOverride.chart.height = 40 * sellersToRender.data.length;
  widget.view.chartPropsOverride.xAxis = widget.view.chartPropsOverride.xAxis || {};
  const value = 'TODO';
  widget.view.chartPropsOverride.xAxis.formatter = function formatter() {
    return `<a style="white-space: nowrap; text-align: right; width: 100%; display: inline-block;"  href="${retailerSellerPageUrl}${value}" target="_blank">${value}</a>`;
  };

  return (
    <div key={`entitypagecontainer_${widget.view.name}${i}`}>
      <div className="buybox-metrics__donut">
        <div className="line-chart">
          <div className="line-chart__header" style={{ marginBottom: '10px' }}>
            <div className="line-chart__title">
              <div className="chart-title">Buy Box - Sellers</div>
            </div>
          </div>
        </div>
        <hr className="sl-divider sl-divider--no-margin-top" />
        <ColumnChartContainer
          key={`entitypagecontainer_${widget.view.name}${i}`}
          chartDisplayTimePeriod={chartDisplayTimePeriod}
          chartComparisonDisplayTimePeriod={chartComparisonDisplayTimePeriod}
          mainEntityMetrics={sellersToRender}
          widget={widget}
          onPointSelect={(e: [{ options: { fieldValue: string } }]) => {
            const { fieldValue } = e[0].options;
            store.dispatch(
              scratchActions.setKey('buyBoxSellers', {
                ...buyBoxSellers,
                // preSelectedMerchant: selectedMerchantName,
                selectedMerchantName: fieldValue
              })
            );

            window.location.hash = 'seller-summary-entity-grid';

            return true;
          }}
          selectedPoint={selectedMerchantName}
          retailer={retailer}
          labelRotation={0}
        />
      </div>
    </div>
  );
};

const mapSellerSummaryEntityGridStateToProps = ({ scratch }: ReduxStore) => ({
  buyBoxSellers: scratch.buyBoxSellers
});

const SellerSummaryEntityGridInner: React.FC<
  WidgetProps & ReturnType<typeof mapSellerSummaryEntityGridStateToProps>
> = ({ widget, buyBoxSellers: { selectedMerchantName } = {}, ...widgetProps }) => {
  // Manually add a condition to make the merchant name match the currently selected merchant
  const updatedWidget = _cloneDeep(widget);
  if (selectedMerchantName) {
    updatedWidget.data.additionalConditionFieldsForOnlyPrimaryMetric = ['merchantName'];
    updatedWidget.data.additionalConditions = {
      termFilters: [
        {
          fieldName: 'merchantName',
          values: [selectedMerchantName]
        }
      ]
    };
  }

  return <EntityGrid {...widgetProps} widget={updatedWidget} />;
};

export const SellerSummaryEntityGrid = connect(mapSellerSummaryEntityGridStateToProps)(SellerSummaryEntityGridInner);

export const SellerSummaryBarChart = compose(
  connect(mapSellerSummaryBarChartStateToProps),
  withRouter
)(SellerSummaryBarChartInner);
