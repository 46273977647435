import View from 'src/components/AdManager/ScheduledAction/View';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { buildWidgetGroup } from 'src/components/Layout/LayoutUtil';

export const getLayout = () => {
  const widgets = [
    {
      CustomComponent: View,
      name: 'viewScheduledActions',
      view: {
        name: 'campaignAlign',
        gridOptions: {
          pageSize: 1000
        }
      },
      data: {}
    }
  ];

  const contentWidgetsGroup = buildWidgetGroup(
    [buildWidgetGroup(widgets, { data: {}, view: { container: { style: { minWidth: 1370, maxWidth: 1370 } } } })],
    {
      view: {
        container: {
          className: 'ad_builder-container',
          style: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }
        }
      }
    }
  );

  return {
    containerStyle: { flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' },
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    dataConfig: {},
    widgets: [contentWidgetsGroup]
  };
};
