import React from 'react';
import styled from '@emotion/styled';
import { useStacklineTheme, StacklineTheme, SlRow } from '@stackline/ui';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { useAppSelector } from 'src/utils/Hooks';
import { CheckCircle, SmallCancelIcon } from 'src/components/SvgIcons';
import { useContentAccuracyProductCompareData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Content/Accuracy/ContentAccuracyProductCompareHooks';
import ContentAccuracyImagesViewer from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Content/Accuracy/ContentAccuracyImagesViewer';
import ContentAccuracyVideoViewer from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Content/Accuracy/ContentAccuracyVideoViewer';
import ContentAccuracyNodataBox from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Content/Accuracy/ContentAccuracyNodataBox';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import {
  ContentAccuracyFieldName,
  contentMetrics,
  donutChartColorSelector
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Content/Accuracy/ContentAccuracyPage';
import DonutChartLoading from 'src/components/Charts/Donut/DonutChartLoading';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import CircularProgressPercentage from 'src/components/common/UploadAnimation/CircularProgressPercentage';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

const DONUT_LOADING_SIZE = 200;

const SKELETON_LOADING_SIZE = {
  height: 400,
  width: 390
};

const StyledGridDiv = styled('div')({
  display: 'grid',
  gridTemplateColumns: '390px 426px',
  columnGap: '225px'
});

export const StyledBorderTextBox = styled('div')(
  ({
    stacklineTheme,
    borderColor,
    width
  }: {
    borderColor?: string;
    stacklineTheme: StacklineTheme;
    width?: number;
  }) => ({
    border: `1px solid ${borderColor}`,
    borderRadius: stacklineTheme.spacing.xs,
    padding: `12px ${stacklineTheme.spacing.sm}`,
    width: width ? `${width}px` : '390px'
  })
);

const StyledCheckCycleIcon = styled(CheckCircle)(({ stacklineTheme }: { stacklineTheme: StacklineTheme }) => ({
  fill: stacklineTheme.colors.success,
  width: '28px',
  marginLeft: '-2px',
  marginRight: '8px'
}));

const StyledSmallCancelIcon = styled(SmallCancelIcon)(({ stacklineTheme }: { stacklineTheme: StacklineTheme }) => ({
  fill: stacklineTheme.colors.error,
  width: stacklineTheme.spacing.mdl,
  marginRight: '12px'
}));

interface IsContentMatchingProps {
  approvedContentCount: number;
  matchedContentCount: number;
}

const isContentMatching = (singleContent: IsContentMatchingProps) =>
  singleContent.approvedContentCount === singleContent.matchedContentCount;

const statsToFraction = (stats: IsContentMatchingProps) => {
  if (stats.approvedContentCount === 0) {
    return 0;
  } else {
    return stats.matchedContentCount / stats.approvedContentCount;
  }
};

/**
 *
 * Content Accuracy Page in Product Level -- Comparing user uploaded master data against Current data we crawl.
 */
const ContentAccuracyProductCompare = () => {
  const theme = useStacklineTheme();
  const { id: productId } = useAppSelector((state) => state.entityService.mainEntity);

  const { isLoading: approvedIsLoading, contentAccuracyData: approvedData } = useContentAccuracyProductCompareData({
    id: `contentAccuracy-product-${productId}-approved`,
    searchType: 'beacon-contentApproved'
  });

  const { isLoading: currentIsLoading, contentAccuracyData: currentData } = useContentAccuracyProductCompareData({
    id: `contentAccuracy-product-${productId}-current`,
    searchType: 'beacon-contentCurrent'
  });

  const { isLoading: changedIsLoading, contentAccuracyData: changedData } = useContentAccuracyProductCompareData({
    id: `contentAccuracy-product-${productId}-changed`,
    searchType: 'beacon-contentChanged'
  });

  const {
    title: approvedTitle,
    bullets: approvedBullets,
    imageUrls: approvedImageUrls,
    videoUrls: approvedVideoUrls
  } = approvedData;

  const {
    title: currentTitle,
    bullets: currentBullets,
    imageUrls: currentImageUrls,
    videoUrls: currentVideoUrls
  } = currentData;

  const {
    titleStats,
    bulletsStats,
    imageUrlsStats,
    videoUrlsStats,
    bullets: changedBullets,
    imageUrls: changedImageUrls,
    videoUrls: changedVideoUrls
  } = changedData;

  const isLoading = approvedIsLoading || currentIsLoading || changedIsLoading;

  const chartDataConfig = (fieldName) => {
    switch (fieldName) {
      case ContentAccuracyFieldName.TitleAccuracy:
        return {
          title: `${titleStats.matchedContentCount}/${titleStats.approvedContentCount}`,
          value: statsToFraction(titleStats)
        };

      case ContentAccuracyFieldName.BulletsAccuracy:
        return {
          title: `${bulletsStats.matchedContentCount}/${bulletsStats.approvedContentCount}`,
          value: statsToFraction(bulletsStats)
        };

      case ContentAccuracyFieldName.ImageUrlsAccuracy:
        return {
          title: `${imageUrlsStats.matchedContentCount}/${imageUrlsStats.approvedContentCount}`,
          value: statsToFraction(imageUrlsStats)
        };

      case ContentAccuracyFieldName.VideoUrlsAccuracy:
        return {
          title: `${videoUrlsStats.matchedContentCount}/${videoUrlsStats.approvedContentCount}`,
          value: statsToFraction(videoUrlsStats)
        };

      default:
        return {
          title: `${titleStats.matchedContentCount}/${titleStats.approvedContentCount}`,
          value: statsToFraction(titleStats)
        };
    }
  };

  return (
    <BeaconPageContainer>
      <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
        <Flex flexDirection="column" gap="xl">
          <CommonSummaryInfoSubtitle title="Content Accuracy" />
          <SlRow horizontalPosition="space-between">
            {contentMetrics.map(({ fieldName, title }) => {
              const color = donutChartColorSelector(fieldName, theme);

              return isLoading ? (
                <DonutChartLoading size={DONUT_LOADING_SIZE} />
              ) : (
                <CircularProgressPercentage
                  percentage={chartDataConfig(fieldName).value}
                  size="200px"
                  color={color}
                  customLabel={
                    <Flex gap="xxs" flexDirection="column" justifyContent="center">
                      <Text
                        sx={{
                          fontSize: '32px',
                          fontWeight: 'bold'
                        }}
                      >
                        {chartDataConfig(fieldName).title}
                      </Text>
                      <Text
                        variant="h6"
                        sx={{
                          textAlign: 'center'
                        }}
                      >
                        {title}
                      </Text>
                    </Flex>
                  }
                />
              );
            })}
          </SlRow>
        </Flex>
        <Flex flexDirection="column" gap={40}>
          {/* Main Header */}
          <StyledGridDiv>
            <Text variant="h4">Master Content</Text>
            <Text variant="h4">Current Content</Text>
          </StyledGridDiv>

          {isLoading ? (
            <StyledGridDiv>
              <SlSkeleton variant="rounded" height={SKELETON_LOADING_SIZE.height} width={SKELETON_LOADING_SIZE.width} />
              <SlSkeleton variant="rounded" height={SKELETON_LOADING_SIZE.height} width={SKELETON_LOADING_SIZE.width} />
            </StyledGridDiv>
          ) : (
            <>
              {/* Title Comparison */}
              <StyledGridDiv>
                <Flex flexDirection="column">
                  <Text variant="subtitle1">Product Title</Text>
                  <Flex marginTop={theme.spacing.md}>
                    <StyledBorderTextBox stacklineTheme={theme} borderColor={theme.colors.primaryGray}>
                      <Text variant="body2">{approvedTitle || 'This product has no approved title.'}</Text>
                    </StyledBorderTextBox>
                  </Flex>
                </Flex>

                <Flex flexDirection="column">
                  <Text variant="subtitle1">{`Product Title (${titleStats.matchedContentCount}/${titleStats.approvedContentCount})`}</Text>

                  <Flex marginTop={theme.spacing.md}>
                    {isContentMatching(titleStats) ? (
                      <StyledCheckCycleIcon stacklineTheme={theme} />
                    ) : (
                      <StyledSmallCancelIcon stacklineTheme={theme} />
                    )}

                    <StyledBorderTextBox
                      stacklineTheme={theme}
                      borderColor={isContentMatching(titleStats) ? theme.colors.success : theme.colors.error}
                    >
                      <Text variant="body2">{currentTitle}</Text>
                    </StyledBorderTextBox>
                  </Flex>
                </Flex>
              </StyledGridDiv>

              {/* Description Bullets Comparison */}
              <StyledGridDiv>
                <Flex flexDirection="column">
                  <Text variant="subtitle1">Product Description Bullets</Text>

                  {approvedBullets.map((bullet) => (
                    <Flex marginTop={theme.spacing.md} key={bullet}>
                      <StyledBorderTextBox stacklineTheme={theme} borderColor={theme.colors.primaryGray}>
                        <Text variant="body2">{bullet}</Text>
                      </StyledBorderTextBox>
                    </Flex>
                  ))}
                </Flex>

                <Flex flexDirection="column">
                  <Text variant="subtitle1">{`Product Description Bullets (${
                    bulletsStats.matchedContentCount + bulletsStats.extraContentCount
                  }/${bulletsStats.approvedContentCount})`}</Text>
                  {currentBullets.length === 0 ? (
                    <ContentAccuracyNodataBox contentField="bullets" marginTop="md" />
                  ) : (
                    changedBullets.map((changedBullet, index) => (
                      <Flex marginTop={theme.spacing.md} key={changedBullet}>
                        {!changedBullet ? (
                          <StyledCheckCycleIcon stacklineTheme={theme} />
                        ) : (
                          <StyledSmallCancelIcon stacklineTheme={theme} />
                        )}

                        <StyledBorderTextBox
                          stacklineTheme={theme}
                          borderColor={!changedBullet ? theme.colors.success : theme.colors.error}
                        >
                          <Text variant="body2">{changedBullet || currentBullets[index]}</Text>
                        </StyledBorderTextBox>
                      </Flex>
                    ))
                  )}
                </Flex>
              </StyledGridDiv>

              {/* Image compare */}
              <StyledGridDiv>
                <Flex flexDirection="column" gap={40}>
                  <Text variant="subtitle1">Product Images</Text>
                  <ContentAccuracyImagesViewer images={approvedImageUrls} />
                </Flex>

                <Flex flexDirection="column" gap={40}>
                  <Text variant="subtitle1">{`Product Images (${imageUrlsStats.matchedContentCount}/${imageUrlsStats.approvedContentCount})`}</Text>
                  <ContentAccuracyImagesViewer images={currentImageUrls} changedImages={changedImageUrls} />
                </Flex>
              </StyledGridDiv>

              {/* Video compare */}
              <StyledGridDiv>
                <Flex flexDirection="column" gap={40}>
                  <Text variant="subtitle1">Product Videos</Text>
                  <ContentAccuracyVideoViewer videos={approvedVideoUrls} />
                </Flex>

                <Flex flexDirection="column" gap={40}>
                  <Text variant="subtitle1">{`Product Videos (${videoUrlsStats.matchedContentCount}/${videoUrlsStats.approvedContentCount})`}</Text>
                  <ContentAccuracyVideoViewer videos={currentVideoUrls} changedVideos={changedVideoUrls} />
                </Flex>
              </StyledGridDiv>
            </>
          )}
        </Flex>
      </Flex>
    </BeaconPageContainer>
  );
};

export default ContentAccuracyProductCompare;
