import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AppButton, AppTable } from '@stackline/ui';
import MenuButton from 'src/components/common/Buttons/MenuButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDateForDisputeTable } from 'src/utils/dateUtils';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import _get from 'lodash/get';
import { useQuery } from 'react-query';
import { UploadFileModal } from 'src/components/ShortageDisputes/components/DisputeManagement/FileUploadModal';
import { makeStyles } from '@mui/styles';
import colors from 'src/utils/colors';

const useStyles = makeStyles(() => ({
  styleOverrides: {
    '& .MuiTableCell-head': {
      textTransform: 'none',
      fontSize: '18px',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: colors.darkBlue
    },
    '& .MuiTableCell-body': {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.5',
      letterSpacing: 'normal',
      color: colors.darkBlue
    }
  }
}));

const generateDocumentRows = (data) => {
  if (!data) {
    return [];
  }
  const documentCollection = [];
  data.forEach((document) => {
    documentCollection.push({
      documentName: document.fileName,
      documentType: document.documentType,
      documentUploadDate: formatDateForDisputeTable(document.creationDate),
      documentId: document.documentId,
      invoiceNumber: document.invoiceNumber
    });
  });
  return documentCollection;
};

const fetchSupportingDocuments = async (invoiceId: string, retailerId: string | number) => {
  const supportingDocumentsResponse = await axios.get(
    `api/shortagesdisputedocument/ListUploadedSupportingDocuments?invoiceNumber=${invoiceId}&retailerId=${
      retailerId || 1
    }`
  );
  const documentData = _get(supportingDocumentsResponse, 'data', []);
  return documentData;
};

const SupportingDocuments = ({ invoiceId, queryClient }: { invoiceId: string; queryClient: any }) => {
  const [uploadDocumentsModalOpen, setUploadDocumentsModalOpen] = useState(false);
  const { retailer, user } = useSelector((state: ReduxStore) => state);
  const { email } = user.session;
  const { id } = retailer;
  const classes = useStyles();
  const ref = useRef(null);

  const headers = {
    'x-sl-ui-user-email': email,
    'sl-beacon-retailer-id': id || 1
  };

  const { isLoading, data } = useQuery(['fetchDocuments'], () => fetchSupportingDocuments(invoiceId, id), {
    retry: 0,
    staleTime: 0,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['fetchDocuments'] });
  }, []);

  const COLUMN_DEFINITIONS = [
    {
      field: 'documentName',
      headerName: 'Document Name'
    },
    {
      field: 'documentType',
      headerName: 'Document Type',
      tableColumnProps: {
        align: 'left'
      }
    },
    {
      field: 'documentUploadDate',
      headerName: 'Date Uploaded',
      tableColumnProps: {
        align: 'left'
      }
    },
    {
      field: '',
      headerName: '',
      tableColumnProps: {
        align: 'left',
        padding: '0'
      },
      valueFormatter: (_, rowData) => <WrappedMenuDropDown rowData={rowData} />
    }
  ];

  const WrappedMenuDropDown = ({ rowData }: any) => {
    const downloadRef = useRef(null);

    const menuOptions = {
      mainButton: {
        icon: <MoreHorizIcon style={{ color: colors.darkBlue }} fontSize="medium" />,
        direction: 'down'
      },
      subItems: [
        {
          icon: <SearchIcon ref={downloadRef} />,
          text: 'View Document',
          onClick: async () => {
            try {
              const response = await axios.get(
                `api/shortagesdisputedocument/GetDocument?invoiceNumber=${rowData.invoiceNumber}&documentId=${rowData.documentId}&retailerId=${id}`,
                { responseType: 'blob' }
              );
              window.open(URL.createObjectURL(response.data));
            } catch (error) {
              console.error('Error downloading file:', error);
            }
          }
        },
        {
          icon: <DeleteIcon />,
          text: 'Delete Document',
          onClick: async () => {
            try {
              await axios.post(
                `api/shortagesdisputedocument/DeleteDocument`,
                {
                  invoiceNumber: rowData.invoiceNumber,
                  documentId: rowData.documentId,
                  retailerId: id || 1
                },
                headers
              );

              // Refetch our documents after deletion
              queryClient.invalidateQueries({ queryKey: ['fetchDocuments'] });
            } catch (error) {
              console.error('Error deleting file:', error);
            }
          }
        }
      ]
    };
    return (
      <div className="custom-line-chart">
        <MenuButton menuInfo={menuOptions} />
      </div>
    );
  };

  const handleUploadModalClose = () => {
    queryClient.invalidateQueries({ queryKey: ['fetchDocuments'] });
    setUploadDocumentsModalOpen(!uploadDocumentsModalOpen);
  };

  const rows = useMemo(() => {
    return generateDocumentRows(data);
  }, [data]);

  return (
    <>
      <span style={{ display: 'block', width: '100%', marginBottom: '30px' }}>
        Documents and attachments that have been uploaded as part of your disputes.
      </span>
      <AppButton
        onClick={() => setUploadDocumentsModalOpen(!uploadDocumentsModalOpen)}
        invertStyle
        sx={{
          width: '180px',
          height: '31px',
          padding: '6px 32px',
          borderRadius: '15.5px',
          border: 'solid 1px #052849',
          backgroundColor: '#052849',
          position: 'initial',
          top: '50px',
          fontSize: 'small',
          whiteSpace: 'nowrap',
          fontWeight: '400',
          marginBottom: '30px'
        }}
      >
        Upload Documents
      </AppButton>
      <h2 ref={ref}>Uploaded</h2>
      <AppTable
        className={classes.styleOverrides}
        loading={isLoading}
        columns={COLUMN_DEFINITIONS}
        rows={rows}
        noDataPlaceholder={<h2>No Data Available</h2>}
      />
      {invoiceId ? (
        <UploadFileModal
          selectedDisputeForFileUpload={{ originalInvoice: invoiceId }}
          open={uploadDocumentsModalOpen}
          onClose={handleUploadModalClose}
        />
      ) : null}
    </>
  );
};

export default SupportingDocuments;
