import _get from 'lodash/get';

export function shouldDisableToggle(item: any, retailerId: string) {
  const isInstacart = retailerId === '63';
  const { type } = item.entity;
  let disableToggle = false;
  if (
    type === 'adCampaign' &&
    ['Ended', 'Archived'].includes(_get(item.entity, ['extendedAttributes', 'statusDisplay', 'displayName']))
  ) {
    disableToggle = true;
  }
  if (type === 'product') {
    const childDocuments = _get(item.entity, ['extendedAttributes', 'childDocuments'], []);
    disableToggle = true;
    childDocuments.forEach((doc) => {
      if (doc.campaignType !== 'sponsoredBrands') {
        disableToggle = false;
      }
    });
  }
  if (type === 'adTarget' && isInstacart && item.entity.id === 'browseImpressions') {
    disableToggle = true;
  }
  return disableToggle;
}

export function setDisableTogglesForItems(cloneResult: any, retailerId: string) {
  cloneResult.data.forEach((item) => {
    item.disableToggle = shouldDisableToggle(item, retailerId);
  });
}
