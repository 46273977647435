import { SlColumn } from '@stackline/ui';
import React, { useState } from 'react';
import BeaconMetricSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconMetricSplineChart';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import EntityTableHeader, {
  IconsList
} from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';
import ReviewTrendsDonutChart from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Reviews/ReviewTrends/ReviewTrendsDonutChart';
import ReviewTrendsReviewGrid from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Reviews/ReviewTrends/ReviewTrendsReviewGrid';
import { calculateWeightedRatingForLegend } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Reviews/ReviewTrends/utils';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import { useTotalProductCount } from 'src/components/BeaconRedesignComponents/MetricList/entityCountHooks';
import { ProductColumnDefinition } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import ProductGrid from 'src/components/BeaconRedesignComponents/ProductGrid/ProductGrid';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { BEACON_GRID_TOP_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { useMetricFormatter } from 'src/utils/Hooks';
import { calculatePercentChange } from 'src/utils/app';
import { METRICTYPE } from 'src/utils/entityDefinitions';

enum ReviewDropdownOption {
  ReviewDocument = 'reviewDocument',
  StacklineSku = 'stacklineSku'
}

/**
 * The "Summary" tab on the Review page
 */
const ReviewTrendsPage = () => {
  const [dropdownOption, setDropdownOption] = useState<ReviewDropdownOption>(ReviewDropdownOption.ReviewDocument);
  const formatMetric = useMetricFormatter();
  const { count: productCount, isLoading: productsLoading } = useTotalProductCount();
  const [selectedStarRating, setSelectedStarRating] = useState<string[]>([]);

  const onSelectDonut: Highcharts.PointSelectCallbackFunction = (e) => {
    const point = e.target as any;
    const value = point.fieldId;
    const newSelectedPoints = [value];
    if (e.type === 'select') {
      setSelectedStarRating(newSelectedPoints);
    }
  };

  return (
    <BeaconPageContainer>
      <SlColumn widths="full" spacing="xl">
        <CommonSummaryInfoSubtitle title="Rating" />
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: '40%'
            }}
          >
            <ReviewTrendsDonutChart onSelectDonut={onSelectDonut} />
          </div>
          <div
            style={{
              width: '60%'
            }}
          >
            <BeaconMetricSplineChart
              indexName="reviews"
              fieldName="stars"
              showTitleSummary={false}
              splineChartLoadingProps={{
                width: 543
              }}
              chartOptionsOverride={{
                chart: {
                  height: 300
                }
              }}
              // Override the spline chart legend to use the weighted average rating instead of the straight average
              beaconChartWithLegendOverride={(props, { response }) => {
                const primaryMetric = response ? calculateWeightedRatingForLegend(response) : 0;
                const secondaryMetric = response ? calculateWeightedRatingForLegend(response, 1) : 0;

                const percentChange = calculatePercentChange(primaryMetric, secondaryMetric);

                return {
                  ...props,
                  primaryLegendProps: {
                    // eslint-disable-next-line
                    ...props.primaryLegendProps,
                    metric: formatMetric(primaryMetric, METRICTYPE.DECIMAL, { decimalPlaces: 2, showNegative: true }),
                    change: `${formatMetric(Math.abs(percentChange), METRICTYPE.PERCENT, { decimalPlaces: 2 })}`
                  },
                  comparisonLegendProps: {
                    // eslint-disable-next-line
                    ...props.comparisonLegendProps,
                    metric: formatMetric(
                      // We look at the comparison data located at index 1 in the response
                      secondaryMetric,
                      METRICTYPE.DECIMAL,
                      { decimalPlaces: 2, showNegative: true }
                    )
                  }
                };
              }}
            />
          </div>
        </div>
      </SlColumn>
      <div style={{ marginTop: `${BEACON_GRID_TOP_SPACING}px` }}>
        <EntityTableHeader
          enableSwitchingLayouts
          defaultView="tile"
          groupByFields={[
            { name: ReviewDropdownOption.ReviewDocument, displayName: 'Reviews' },
            { name: ReviewDropdownOption.StacklineSku, displayName: 'Products' }
          ]}
          iconsList={[IconsList.export, IconsList.tile]}
          verticalInset="none"
          handleGroupByChange={(event) => {
            setDropdownOption(event.target.value as ReviewDropdownOption);
          }}
        />
        {dropdownOption === ReviewDropdownOption.ReviewDocument ? (
          <ReviewTrendsReviewGrid starFilter={selectedStarRating} />
        ) : (
          <MetricListContainer
            indexName="reviews"
            fields={[{ name: 'stars' }]}
            entityTableHeaderProps={{
              title: ''
            }}
            sortFieldName="stars"
            suffixOverride="count"
            showHeader={false}
            headColumnDefinition={ProductColumnDefinition}
            loading={productsLoading}
            rowCount={productCount}
            renderGrid={(args) => (
              <ProductGrid
                {...args}
                mainMetricOverride={(metricValue) =>
                  `${formatMetric(metricValue, METRICTYPE.VOLUME, { showFullValue: false, decimalPlaces: 1 })} Reviews`
                }
                secondaryMetricOverride={(_, row) => {
                  const starsSum = row.responseRow.stars_stats_sum;
                  const totalStars = row.responseRow.stars_stats_count;

                  const avgStars = totalStars > 0 ? starsSum / totalStars : 0;
                  return `${formatMetric(avgStars, METRICTYPE.DECIMAL, { decimalPlaces: 1 })} stars`;
                }}
                directionOverride={() => 'increasing'}
                showDirectionOverride={() => false}
              />
            )}
          />
        )}
      </div>
    </BeaconPageContainer>
  );
};

export default ReviewTrendsPage;
