import types from './types';
// ------------------------------------
// Action Creators - they simply return an action
// An action being a payload of information that send data from your application to your store.
// ------------------------------------

function requestConfig() {
  return {
    type: types.REQUEST_CONFIG
  };
}

function isUpdatingRedux(bool) {
  return {
    type: types.IS_UPDATING_REDUX,
    isUpdatingRedux: bool
  };
}

function receiveAppConfig(json) {
  return {
    type: types.RECEIVE_APP_CONFIG,
    appConfig: json.data.appConfig,
    userConfig: json.data.userConfig,
    session: json.data.session,
    receivedAt: Date.now()
  };
}

function setTargetUrl(targetUrl) {
  return {
    type: types.SET_APP_TARGET_URL,
    targetUrl
  };
}

function setAppName(appName, appStage) {
  return {
    type: types.SET_APP_NAME,
    appName,
    appStage
  };
}
function setQueryParams(params) {
  return {
    type: types.SET_QUERY_PARAMS,
    params
  };
}

export default {
  isUpdatingRedux,
  receiveAppConfig,
  requestConfig,
  setAppName,
  setTargetUrl,
  setQueryParams
};
