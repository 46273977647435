import { useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { History } from 'history';
import { QueryResponse } from 'src/types/application/types';
import { calculateNewQueryParams } from 'src/store/modules/app/selectors';
import { setQueryParams } from 'src/store/modules/app/operations';
/**
 * Updates query params in the redux store and in the URL
 * @param history history from React Router
 */
export const useUpdateQueryParams = (history: History) => {
  const dispatch = useDispatch();
  const store = useStore<ReduxStore>();

  return useCallback(
    async ({
      updatedMainPeriod,
      updatedComparisonPeriod,
      additionalParams
    }: {
      updatedMainPeriod?: ReduxStore['mainTimePeriod'];
      updatedComparisonPeriod?: ReduxStore['comparisonTimePeriod'];
      additionalParams?: { [key: string]: string };
    } = {}) => {
      const { app, retailer, mainTimePeriod, comparisonTimePeriod } = store.getState();
      const newQueryParams = calculateNewQueryParams(
        app,
        retailer,
        updatedMainPeriod || mainTimePeriod,
        updatedComparisonPeriod || comparisonTimePeriod,
        {
          ...Object.fromEntries(new URLSearchParams(window.location.search)),
          ...additionalParams
        }
      );
      const { params }: QueryResponse = dispatch(setQueryParams(newQueryParams));
      const { nonPersistentParams, searchParams, dropDownSelectionParams } = params;
      const newUrl = `${window.location.pathname}${searchParams}${dropDownSelectionParams}${nonPersistentParams}`;

      // Don't push the same location onto the stack
      if (!window.location.href.includes(newUrl)) {
        history.push(newUrl);
      }
    },
    [store, dispatch, history]
  );
};
