import memoizeOne from 'memoize-one';
import _cloneDeep from 'lodash/cloneDeep';

import { buildSubtitleDisplayName } from 'src/utils/filters';
import {
  addMainEntityMetricsForMarketShare,
  computeEntityMarketShareMetrics,
  computeWeeklyMarketShareMetrics,
  getChartDisplayTimePeriod,
  setSubTitle
} from './Renderer/EntityPageRenderer';

/**
 * Computes several props that are used by the `WeeklyTrendChart` and some other custom components.  The return value
 * from this function is memoized in order to do everything we can to return referentially equal values to facilitate
 * optimal rendering and React component memoization.
 */
export const computeChartProps = memoizeOne((
  // props
  app,
  mainTimePeriod,
  comparisonTimePeriod,
  { mainEntity, comparisonEntity },
  mainEntityMetrics,
  givenComparisonEntityMetrics,
  filters,
  categories,
  retailer,
  // state
  comparisonConfig,
  pageLayout,
  widgetNames
) => {
  // Only compute chart props if we have `mainEntityMetrics`.  It is assumed that we won't never need these
  // chart props unless we have `mainEntityMetrics`.
  if (!mainEntityMetrics || !comparisonConfig) {
    return {};
  }

  const { mainEntityCategoryMetrics, relatedEntityMetrics, relatedEntityCategoryMetrics } = mainEntityMetrics;

  let comparisonEntityMetrics =
    !comparisonConfig.type || !comparisonEntity ? _cloneDeep(mainEntityMetrics) : givenComparisonEntityMetrics;

  if (
    app.name === 'atlas' &&
    (widgetNames.includes('marketShareComparisonChart') ||
      widgetNames.includes('marketShareGrid') ||
      mainEntity.type === 'product')
  ) {
    if (mainEntityCategoryMetrics) {
      // Adds MarketShare to mainEntityMetrics
      const {
        mainEntityMetrics: newMainEntityMetrics,
        comparisonEntityMetrics: newComparisonEntityMetrics
      } = computeWeeklyMarketShareMetrics(
        mainEntityMetrics,
        mainEntityCategoryMetrics,
        comparisonEntityMetrics,
        pageLayout.dataConfig.weekIdField.name,
        false
      );

      mainEntityMetrics = newMainEntityMetrics;
      comparisonEntityMetrics = newComparisonEntityMetrics;
    }
  }

  const clonedRelatedEntityMetrics = _cloneDeep(relatedEntityMetrics);
  if (app.name === 'atlas' && widgetNames.includes('marketShareComparisonChart')) {
    // Adds MarketShare to relatedEntityMetrics
    computeEntityMarketShareMetrics(
      clonedRelatedEntityMetrics,
      relatedEntityCategoryMetrics,
      null,
      null,
      pageLayout.dataConfig.relatedEntity.keyFieldName,
      pageLayout.dataConfig.retailerEntity.keyFieldName
    );
    // Updates relatedEntityMetrics to put the brand at begginning of object
    // ======= Only used for the Comparison Chart =======
    addMainEntityMetricsForMarketShare(
      clonedRelatedEntityMetrics,
      mainEntityMetrics,
      mainEntity,
      mainTimePeriod,
      comparisonTimePeriod
    );
  }
  const subtitle = buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app);
  setSubTitle(subtitle, [mainEntityMetrics, comparisonEntityMetrics, clonedRelatedEntityMetrics]);

  const chartDisplayTimePeriod = getChartDisplayTimePeriod(mainTimePeriod);
  const chartComparisonDisplayTimePeriod = getChartDisplayTimePeriod(comparisonTimePeriod);

  return {
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    comparisonEntityMetrics,
    clonedRelatedEntityMetrics
  };
});
