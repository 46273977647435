import { Dialog, styled } from '@mui/material';
import React, { useCallback, useState } from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { ExitIconSmall } from 'src/components/SvgIcons';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import { useStacklineTheme } from '@stackline/ui';
import { useEntityInfo } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/hooks';
import { planTypeDropdownOptions } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkTemplateUploadModal';
import axios from 'axios';
import { useAppSelector, useHistory, useSnackbar } from 'src/utils/Hooks';
import { usePollingContext } from 'src/providers/PollingContextProvider';
import { useBeaconClientId } from 'src/utils/Hooks/reduxSelectors';
import BulkTemplateDownloadModalLoadingInner from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkTemplateDownloadModalLoadingInner';
import useServerProxy from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/hooks/useServerProxy';
import useNotificationsBadge from 'src/utils/Hooks/useNotificationsBadge';
import { AdjustmentDataItem } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/utils';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { trackMixpanelEvent } from 'src/utils/mixpanel';

export const GenericStyledDialogue = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '450px',
    height: '448px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '24px'
  }
});

// TODO: Extend this when segments are added
export enum bulkAdjustmentLevels {
  client = 'Org',
  category = 'Category',
  subcategory = 'Subcategory',
  brand = 'Brand',
  segment = 'Segment'
}

interface BulkTemplateDownloadModalProps extends DialogProps {
  open: boolean;
  onClose: () => void;
  initialPlanType?: PlanTypeOption;
}

// poll interval and add percentage to 1 for every 20 secs. Apple org takes around 80% then goes to 100%
const POLL_INTERVAL_FOR_DOWNLOAD_TEMPLATE = 2000;

const MODAL_CLOSING_TIME = 5000;
/**
 *
 * This component is a modal that is used for adding bulk adjustment for any entityType.
 */
// eslint-disable-next-line react/display-name
export const BulkTemplateDownloadModal = React.forwardRef(
  (props: BulkTemplateDownloadModalProps, ref: React.Ref<HTMLDivElement>): JSX.Element => {
    const theme = useStacklineTheme();
    const entityInfo = useEntityInfo();
    const [selectedOption, setSelectedOption] = useState(
      (props.initialPlanType && planTypeDropdownOptions.find((option) => option.id === props.initialPlanType)) ||
        planTypeDropdownOptions[0]
    );
    const retailerId = useAppSelector((state) => state.retailer.id);
    const { userId, divisionId, companyId } = useAppSelector((state) => state.user.session);
    const {
      type: entityType,
      id: entityId,
      name: entityName
    } = useAppSelector((state) => state.entityService.mainEntity);
    const { startPolling, stopPolling } = usePollingContext();
    const { setShowNotificationBadge } = useNotificationsBadge();
    const [disabledDownload, setDisabledDownload] = useState(false);
    const [isDownloadInitiated, setIsDownloadInitiated] = useState(false);
    const beaconClientId = useBeaconClientId();
    const { showSnackbar } = useSnackbar();
    const [downloadPercentage, setDownloadPercentage] = useState(0);
    const history = useHistory();
    const { searchParams } = useAppSelector((state) => state.app.queryParams);
    const { pollBulkAdjustments } = useServerProxy();

    const onCloseModal = () => {
      props.onClose();
      // prevent the modal animation delays closing, and flicking the download button
      setTimeout(() => {
        // TODO:: Check a user can access the processing modal from settings, if so, we should check polling map.
        setIsDownloadInitiated(false);
        setDisabledDownload(false);
      }, 500);
    };

    const handlingPolling = useCallback(
      (
        processingStatus: AdjustmentDataItem['processingStatus'],
        validatePollingId: string,
        bulkAdjustmentId: string
      ) => {
        setDownloadPercentage((prevPercentage) => {
          return Math.min(prevPercentage + 1, 90);
        });

        if (processingStatus === 'Success') {
          stopPolling(validatePollingId);
          setDownloadPercentage(100);
          setTimeout(() => {
            onCloseModal();
            showSnackbar({
              message: 'Bulk template successfully downloaded.',
              type: 'success',
              showButton: true,
              buttonProps: {
                text: 'View',
                onClick: () => {
                  history.push(`/account/downloads${searchParams}&tab=adjustmentsDownload`);
                }
              }
            });
          }, MODAL_CLOSING_TIME);
          setShowNotificationBadge();
        }
        if (processingStatus === 'Error') {
          stopPolling(validatePollingId);
          onCloseModal();
          showSnackbar({
            message: 'Download failed. We have encountered an issue processing your request. Please try again.',
            type: 'error',
            showButton: false
          });
          console.error('Error downloading bulk template bulkAdjustmentId is - ', bulkAdjustmentId);
        }
      },
      []
    );

    const requestBody = {
      userId,
      planType: selectedOption.id,
      beaconClientId,
      retailerId: Number(retailerId),
      bulkAdjustmentLevel: bulkAdjustmentLevels[entityType],
      bulkAdjustmentLevelId:
        entityType === 'segment'
          ? JSON.stringify({
              company_id: companyId,
              division_id: divisionId,
              segment_id: entityId
            })
          : entityId
    };

    const onDownloadBtnClick = async () => {
      trackMixpanelEvent({
        eventName: 'download bulk adjustments template',
        data: {
          planType: selectedOption.label,
          entityLevel: entityType,
          entityId: `${entityId}`,
          entityName
        }
      });
      setIsDownloadInitiated(true);
      setDisabledDownload(true);

      const response = await axios.post('/api/beaconbulkadjustment/DownloadBulkAdjustmentTemplate', requestBody);
      if (response && response.data && response.data.length > 0) {
        const { bulkAdjustmentId } = response.data[0];
        const validatePollingId = `BULK_ADJ_DOWNLOAD_${bulkAdjustmentId}`;

        startPolling(
          validatePollingId,
          async () => {
            const pollResponse = await pollBulkAdjustments({
              ...requestBody,
              bulkAdjustmentStage: 'Template',
              bulkAdjustmentId
            });

            if (pollResponse && pollResponse[0]) {
              const { processingStatus } = pollResponse[0];
              handlingPolling(processingStatus, validatePollingId, bulkAdjustmentId);
            }
          },
          { interval: POLL_INTERVAL_FOR_DOWNLOAD_TEMPLATE }
        );
      }
    };

    return (
      <GenericStyledDialogue ref={ref} {...props}>
        {isDownloadInitiated ? (
          // TODO:: Design and BE agreed that FE will get the total number of request, and FE will assume the percentage value
          <BulkTemplateDownloadModalLoadingInner
            closeBulkTemplateDownloadModal={onCloseModal}
            downloadPercentage={downloadPercentage}
          />
        ) : (
          <>
            {/* Header */}
            <Flex>
              <Text variant="h4">Download Template</Text>
              <ExitIconSmall
                onClick={onCloseModal}
                style={{
                  position: 'absolute',
                  right: '24px',
                  cursor: 'pointer',
                  width: theme.spacing.md,
                  height: theme.spacing.md
                }}
              />
            </Flex>

            {/* Content */}
            <Flex flexDirection="column">
              <Flex flexDirection="column" marginTop={theme.spacing.cardGrid} gap="sm">
                <Flex height="19px">
                  <Text variant="subtitle2">Create Template</Text>
                </Flex>
                <Flex height="19px">
                  <Text variant="body2">Create a bulk adjustment template for the following:</Text>
                </Flex>
              </Flex>

              {/* EntityInfo */}
              <Flex marginTop={theme.spacing.cardGrid}>
                <Flex>{entityInfo.header}</Flex>
                <Flex marginLeft={theme.spacing.cardGrid} flexDirection="column">
                  <Flex marginTop="13px" height="19px">
                    <Text variant="subtitle2">{entityInfo.displayName}</Text>
                  </Flex>
                  <Flex marginTop={theme.spacing.sm} height="19px">
                    <Text variant="body2">{entityInfo.title}</Text>
                  </Flex>
                </Flex>
              </Flex>

              <Flex marginTop={theme.spacing.cardGrid}>
                <Flex flexDirection="column" gap="sm">
                  {/* Plan Type */}
                  <Flex height="19px">
                    <Text variant="subtitle2" title="Plan Type">
                      Plan Type
                    </Text>
                  </Flex>

                  <Flex height="19px">
                    <Text variant="body2">Create a template for the following plan type:</Text>
                  </Flex>

                  {/* Plan dropdown */}
                  <SlDropdownMenu
                    menuItemTextStyles={{ fontWeight: 'normal' }}
                    stacklineButtonProps={{
                      buttonSx: {
                        width: '152px',
                        justifyContent: 'space-between'
                      },
                      textSx: {
                        fontWeight: 'normal',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }
                    }}
                    sx={{ maxWidth: '152px' }}
                    options={planTypeDropdownOptions}
                    defaultLabel="Select a plan type"
                    selectedId={selectedOption.id}
                    onChange={(option) => setSelectedOption(option)}
                  />
                </Flex>
              </Flex>
            </Flex>

            {/* Footer */}
            <Flex justifyContent="space-between" alignItems="center" marginTop="66px">
              <SlButton onClick={onCloseModal}>Cancel</SlButton>
              <SlButton
                disabled={disabledDownload}
                onClick={onDownloadBtnClick}
                variant="contained"
                buttonSx={{ width: '102px' }}
              >
                Download
              </SlButton>
            </Flex>
          </>
        )}
      </GenericStyledDialogue>
    );
  }
);
