/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import withStyles from '@mui/styles/withStyles';
import MUISelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  IStoreAdPlatformSettingsByClient,
  AD_PLATFORM_SETTING_TYPE_BY_CLIENT,
  AD_TARGETING_TYPE,
  IAdProductMetaData
} from 'sl-ad-campaign-manager-data-model';
import _flatten from 'lodash/flatten';
import _isNil from 'lodash/isNil';
import axios from 'axios';
import { Option } from 'funfix-core';

import PillFilters from 'src/components/Search/AdvancedSearch/AdvancedSearchSideBar/PillFilters';
import FeaturedProductsGrid from 'src/components/AdCampaignBuilder/Widgets/FeaturedProductsGrid';
import { ChevronIcon } from 'src/components/SvgIcons';
import colors from 'src/utils/colors';
import { prop } from 'src/utils/fp';
import { store } from 'src/main';
import { useOnce } from 'src/utils/Hooks';
import { getInitialState } from 'src/store/modules/adManager/adCampaignBuilder/initialState';
import {
  getPlatformType,
  getAdPlatformSettingsByClientOfType
} from 'src/store/modules/adManager/adCampaignBuilder/selectors';
import ReduxStore from 'src/types/store/reduxStore';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { SectionWrapper, OperationButtons, buildSubtabLink } from '../Widgets/AdCampaignBuilderCommonWidgets';
// import InfoTooltip from '../Widgets/InfoTooltip';
import { PushFn } from 'src/types/application/types';
import { withPush } from 'src/utils/hoc';
import validator from 'validator';

const clearFeatured: Function = () => store.dispatch(adCampaignBuilderActions.clearFeatured());

const linkStyle: React.CSSProperties = {
  display: 'block',
  color: colors.blue,
  cursor: 'pointer'
};

const sponsoredBrandLandingOptions = [
  {
    name: 'Brand Store'
  },
  {
    name: 'Product List Page'
  }
];

const isURLValid = (str: string): boolean => validator.isURL(str);

const openLink = (url: string) => {
  if (isURLValid(url)) {
    url = url.match(/^http[s]?:\/\//) ? url : `http://${url}`; // add http:// to the url if the user input dosen't contain it
    window.open(url, '_blank');
  } else {
    // eslint-disable-next-line
    alert('Please enter a valid URL.');
  }
};

const mapBrandStoreURLSelectStateToProps = (state: ReduxStore) => {
  const platformType = getPlatformType(state);
  const storePlatformSettings = getAdPlatformSettingsByClientOfType(
    AD_PLATFORM_SETTING_TYPE_BY_CLIENT.STORE,
    platformType
  )(state) as IStoreAdPlatformSettingsByClient[];

  // Create a flattened list of store page info items, combining the name of the store and the page into one
  const flattenedStorePageInfo = _flatten(
    storePlatformSettings.map((storeSetting) =>
      storeSetting.extendedAttributes.amsApiModel.storePageInfo.map((storePageInfo) => ({
        displayName: `${storeSetting.extendedAttributes.amsApiModel.storeName} -> ${storePageInfo.storePageName}`,
        id: storePageInfo.storePageId,
        value: storePageInfo.storePageUrl,
        entityId: storeSetting.extendedAttributes.entityId,
        retailerId: storeSetting.extendedAttributes.retailerId
      }))
    )
  );

  return { storePageInfo: flattenedStorePageInfo, storeUrl: state.adCampaignBuilder.featured.storeUrl };
};

const BrandStoreURLSelectInner: React.FC<
  { onSave: (newVal: { storeUrl?: string; customUrl?: string }) => void; classes: { [key: string]: any } } & ReturnType<
    typeof mapBrandStoreURLSelectStateToProps
  >
> = ({ entityId, storeUrl, storePageInfo, onSave, classes }) => (
  <>
    <div className="setting_row">
      <div className="label">Store:</div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MUISelect
          variant="standard"
          style={{ marginRight: 20 }}
          value={storeUrl || null}
          onChange={(evt: any) => onSave({ storeUrl: evt.target.value as string })}
          classes={{ root: classes.selectRoot, select: classes.select }}
          IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
        >
          {storePageInfo
            .filter((item) => item.entityId === entityId)
            .map((opt, idx) => (
              <MenuItem key={idx} value={opt.value}>
                {opt.displayName}
              </MenuItem>
            ))}
        </MUISelect>
        <a role="link" onClick={() => openLink(storeUrl)} style={linkStyle}>
          Preview Link
        </a>
      </div>
    </div>
  </>
);

const BrandStoreURLSelect = connect(mapBrandStoreURLSelectStateToProps)(
  withStyles({
    selectRoot: {
      margin: 0,
      width: 300,
      maxWidth: 300
    },
    select: {
      padding: '7px 27px 7px 7px',
      width: 300,
      maxWidth: 300
    }
  })(BrandStoreURLSelectInner)
);

const ProductListSelect: React.FC = () => (
  <>
    <div style={{ padding: '25px 0', borderBottom: `1px solid ${colors.lightestGrey}` }}>
      <h2 style={{ fontSize: 20, fontWeight: 500 }}>Product selection</h2>
      <div>
        Select between 3 and 100 products. We recommend adding at least 5 products to reduce the likelihood of campaign
        pauses.
      </div>
      <div>Maximum: 100 products</div>
    </div>
    <PillFilters />
    <FeaturedProductsGrid />
  </>
);

const mapStateToProps = (state: ReduxStore) => ({
  entityId: Option.of(state.adCampaignBuilder.platformSettings)
    .flatMap((platformSettings) => Option.of(platformSettings.entity))
    .map((entity) => entity.extendedAttributes.entityId)
    .orNull(),
  adCampaignBuilder: state.adCampaignBuilder,
  storeUrl: state.adCampaignBuilder.featured.storeUrl
});

const FeaturedLanding: React.FC<
  {
    classes: { [key: string]: any };
    push: PushFn;
  } & ReturnType<typeof mapStateToProps>
> = ({ entityId, storeUrl, adCampaignBuilder, push, classes }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState<string | null>(null);

  useOnce(() => {
    // The only valid targeting type for sponsored brand campaigns is keyword targeting
    store.dispatch(adCampaignBuilderActions.setTargetTypeId(AD_TARGETING_TYPE.KEYWORD_TARGETING));
  });

  const selectedLandingType = adCampaignBuilder.featured.landingType;

  const activeTabIx = selectedLandingType === 'Brand Store' ? 0 : 1;

  const ComponentForActiveTabIx = useMemo(() => [BrandStoreURLSelect, ProductListSelect][activeTabIx]!, [activeTabIx]);

  const canContinue = () => {
    if (activeTabIx === 0) {
      return isURLValid(storeUrl);
    } else {
      const count = _get(adCampaignBuilder, ['featured', 'selectedFeaturedProducts', 'length'], 0);
      return count >= 3 && count <= 100;
    }
  };

  const onBeforeLeave = () => {
    if (activeTabIx === 0) {
      return async (isNext) => {
        if (!isNext) {
          return true;
        }

        setIsLoading(true);
        clearFeatured();
        setErrMsg(null);

        try {
          // Fetch the products for the store URL and use to populate the next page
          const res = await axios.get(
            `/apiAdManager/adCampaigns/getProductsForUrl?entityId=${entityId}&pageUrl=${window.encodeURIComponent(
              storeUrl
            )}`
          );
          if (res.status !== 200) {
            throw new Error('Error fetching products for store URL; got bad response from API.');
          }
          const productsForURL: IAdProductMetaData[] = res.data;
          await store.dispatch(
            adCampaignBuilderActions.setFeatured({
              productsForStoreStacklineSkus: productsForURL.map(prop('stacklineSku'))
            })
          );
          return true;
        } catch (err) {
          console.error(err);
          setErrMsg('Failed to fetch products for store URL; check that your URL is valid and try again.');
          return false;
        } finally {
          setIsLoading(false);
        }
      };
    } else {
      return undefined;
    }
  };

  if (_isNil(entityId)) {
    // Redirect to start of the flow if Redux is in an invalid state due to entering in the middle of the flow
    push(buildSubtabLink('platformSelect'));
    return null;
  }

  const handleLandingTypeChange = (value: string) => {
    const initialState = getInitialState();
    store.dispatch(
      adCampaignBuilderActions.setFeatured({
        ...initialState.featured,
        productsForStoreStacklineSkus: undefined,
        landingType: value
      })
    );
  };

  return (
    <div className="ad-manager-container">
      <SectionWrapper header="Landing Page" subheader="Select the landing page for this campaign.">
        <br />
        <br />
        <div className="setting_container">
          <div className="setting_row">
            <div className="label">Type:</div>
            <div>
              <MUISelect
                variant="standard"
                style={{ marginRight: 20 }}
                value={selectedLandingType || null}
                onChange={({ target: { value } }) => {
                  handleLandingTypeChange(value);
                }}
                classes={{ root: classes.selectRoot, select: classes.select }}
                IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
              >
                {sponsoredBrandLandingOptions.map((opt, idx) => (
                  <MenuItem key={idx} value={opt.name}>
                    {opt.name}
                  </MenuItem>
                ))}
              </MUISelect>
            </div>
          </div>
          <ComponentForActiveTabIx
            onSave={(newFeatured) => store.dispatch(adCampaignBuilderActions.setFeatured(newFeatured))}
            entityId={entityId}
          />
          <div style={{ color: colors.red }}>{errMsg}</div>
        </div>
      </SectionWrapper>

      <OperationButtons
        subtab="featuredLanding"
        canContinue={canContinue()}
        isLoading={isLoading}
        onBeforeLeave={onBeforeLeave()}
      />
    </div>
  );
};

export default connect(mapStateToProps)(
  withPush(
    withStyles({
      selectRoot: {
        margin: 0,
        width: 300,
        maxWidth: 300
      },
      select: {
        padding: '7px 27px 7px 7px',
        width: 300,
        maxWidth: 300
      }
    })(FeaturedLanding)
  )
);
