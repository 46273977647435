import DisputeManagement from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/DisputeManagement';
import DisputeManagementPage from 'src/components/ShortageDisputes/DisputeManagementPage';
import { shouldShowNewBeacon } from 'src/utils/app';

export const buildDisputeManagementPageWidgets = (widgets) => {
  // Instead of building multiple widgets, return just one page that contains everything we need

  if (shouldShowNewBeacon()) {
    widgets.push({
      CustomComponent: DisputeManagement,
      data: {},
      view: {}
    });
  } else {
    widgets.push({
      CustomComponent: DisputeManagementPage,
      data: {},
      view: {}
    });
  }
};
