import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import TextField from '@mui/material/TextField';

import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import { PhoneNumber } from 'src/components/Forms';
import Loading from 'src/components/common/Loading';
import i18n from 'src/i18n_en';

const { account_profile, account_firstName, account_lastName, account_company, account_position, account_phoneNumber } =
  i18n;

const propTypes = {
  buttonText: PropTypes.string.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  handleProfileChange: PropTypes.func.isRequired,
  handlePhoneNumberChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showErrorText: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

function UserProfile({
  buttonText,
  handleProfileChange,
  handlePhoneNumberChange,
  isFormSubmitted,
  user,
  onSubmit,
  showErrorText
}) {
  if (_isEmpty(user.profile)) {
    return <Loading className="spinner" />;
  }
  const { firstName, lastName, company, jobTitle, phoneNumber } = user.profile;

  return (
    <form className="profile" onSubmit={onSubmit}>
      <h4 className="sl-header-text">{account_profile}</h4>
      <hr className="sl-divider sl-divider--no-margin-top sl-divider--lg-margin-bottom" />
      <h4 className="sl-form-label">{account_firstName}</h4>
      <TextField
        variant="standard"
        className="sl-form-input"
        placeholder="First Name"
        name="firstName"
        defaultValue={firstName}
        errorText={showErrorText('firstName')}
        floatingLabelStyle={{ top: '24px' }}
        inputStyle={{ marginTop: '0' }}
        errorStyle={{ bottom: '9px' }}
        style={{ width: '100%', height: '48px' }}
        onChange={handleProfileChange}
      />
      <h4 className="sl-form-label">{account_lastName}</h4>
      <TextField
        variant="standard"
        className="sl-form-input"
        placeholder="Last Name"
        name="lastName"
        defaultValue={lastName}
        errorText={showErrorText('lastName')}
        floatingLabelStyle={{ top: '24px' }}
        inputStyle={{ marginTop: '0' }}
        errorStyle={{ bottom: '9px' }}
        style={{ width: '100%', height: '48px' }}
        onChange={handleProfileChange}
      />
      <h4 className="sl-form-label">{account_company}</h4>
      <TextField
        variant="standard"
        className="sl-form-input"
        placeholder="Company"
        name="company"
        defaultValue={company}
        errorText={showErrorText('company')}
        floatingLabelStyle={{ top: '24px' }}
        inputStyle={{ marginTop: '0' }}
        errorStyle={{ bottom: '9px' }}
        style={{ width: '100%', height: '48px' }}
        onChange={handleProfileChange}
      />
      <h4 className="sl-form-label">{account_position}</h4>
      <TextField
        variant="standard"
        className="sl-form-input"
        placeholder="Job Title"
        name="jobTitle"
        defaultValue={jobTitle}
        errorText={showErrorText('jobTitle')}
        floatingLabelStyle={{ top: '24px' }}
        inputStyle={{ marginTop: '0' }}
        errorStyle={{ bottom: '9px' }}
        style={{ width: '100%', height: '48px' }}
        onChange={handleProfileChange}
      />
      <h4 className="sl-form-label">{account_phoneNumber}</h4>
      <PhoneNumber
        placeholder="(555) 555-5555"
        style={{ width: '100%', height: '48px' }}
        inputStyle={{ marginTop: '0' }}
        className="sl-form-input"
        defaultValue={phoneNumber}
        floatingLabelText=""
        isFormSubmitted={isFormSubmitted}
        onChange={handlePhoneNumberChange}
      />
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <LargeMuiButton label={buttonText} type="submit" />
      </div>
    </form>
  );
}

UserProfile.propTypes = propTypes;

export default UserProfile;
