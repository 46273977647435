import React from 'react';
import SuccessScreen from 'src/components/AdManager/SubmitScreen/SuccessScreen';
import FailedScreen from 'src/components/AdManager/SubmitScreen/FailedScreen';
import PartialSuccessScreen from 'src/components/AdManager/SubmitScreen/PartialSuccessScreen';
import _get from 'lodash/get';
import { PushFn } from 'src/types/application/types';
import { withPush } from 'src/utils/hoc';
import { connect } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';

const typeDisplayNameMapping: Record<string, string> = {
  adCampaignProducts: 'Products',
  products: 'Products',
  adTargets: 'Targets',
  adCampaigns: 'Campaigns',
  adCampaign: 'Campaigns',
  adPortfolios: 'Portfolios'
};

const addTargetSuccess = "You've successfully added targets.";
const updateBidSuccess = "You've successfully updated bids.";
const updateBudgetSuccess = "You've successfully updated budgets.";
const addProductsSuccess = "You've successfully added products.";
const createCampaignSuccess = "You've successfully created a new campaign.";

export const getPartialSuccessDialogMessage = (requestType: string) => {
  const partialSuccessDialog: Record<string, string> = {
    createCampaignTarget: 'Your targets were successfully updated. However, the platform rejected the details below.',
    updateTargetBid: 'Your targets were successfully updated. However, the platform rejected the details below.',
    updateProductBid: 'Your products were successfully updated. However, the platform rejected the details below.',
    bulkBudgetUpdate: 'Your budgets were successfully updated. However, the platform rejected the details below.',
    createCampaignProduct: 'Your campaign was successfully updated. However, the platform rejected the details below.',
    createCampaign: 'Your campaign was successfully created. However, the platform rejected the details below.',
    createPortfolio: 'Your portfolio was successfully created. However, the platform rejected the details below.'
  };

  const defaultMessage = 'It appears the followings failed to update.';
  return partialSuccessDialog[requestType] || defaultMessage;
};

export const getFailedDialogMessage = (requestType: string) => {
  const failedDialog: Record<string, string> = {
    createCampaignTarget: 'Your targets failed during the submission process. The platform rejected the details below.',
    updateTargetBid: 'Your targets failed during the submission process. The platform rejected the details below.',
    updateProductBid: 'Your products failed during the submission process. The platform rejected the details below.',
    bulkBudgetUpdate: 'Your budgets failed during the submission process. The platform rejected the details below.',
    createCampaignProduct:
      'Your products failed during the submission process. The platform rejected the details below.',
    createCampaign:
      'Your campaign failed during the submission process and wasn’t created. The platform rejected the details below.',
    createPortfolio:
      'Your portfolio failed during the submission process and wasn’t created. The platform rejected the details below.'
  };

  const defaultMessage = 'The platform rejected the details below.';
  return failedDialog[requestType] || defaultMessage;
};

export const formatFailedEntities = (failedEntities: any) => {
  const res: { name: string; type: string; reason: string }[] = [];
  if (!failedEntities) {
    return res;
  }
  Object.entries(failedEntities).forEach(([key, values]) => {
    const type = typeDisplayNameMapping[key] || key;
    if (key === 'adCampaign') {
      const code = _get(values, ['apiResponse', 'code'], '');
      const reason = _get(values, ['apiResponse', 'description'], '');
      const name = _get(values, ['entity', 'campaignName'], '');
      res.push({
        name,
        type,
        reason: `${code}${reason ? `: ${reason}` : ''}`
      });
    } else if (Array.isArray(values)) {
      let reasonAMZ;
      values.forEach((value) => {
        if (value && Array.isArray(value.apiResponse) && value.apiResponse.length > 0) {
          const errorType = _get(value.apiResponse[0], ['errorType'], '');
          reasonAMZ = _get(value.apiResponse[0], ['errorValue', errorType, 'message'], '');
        } else {
          reasonAMZ = _get(value, ['apiResponse', 'description'], '');
        }
        const reasonWAL = _get(value, ['apiResponse', 'details'], '');
        const reason = reasonAMZ + reasonWAL;
        let name = _get(value, ['entity', 'name'], '');
        if (key === 'adTargets') {
          name = _get(value, ['entity', 'targetingText'], '');
        } else if (key === 'adCampaignProducts') {
          name = _get(value, ['entity', 'retailerSku'], '');
        } else if (key === 'adCampaigns') {
          name = _get(value, ['entity', 'extendedAttributes', 'amsApiModel', 'name'], '');
        } else if (key === 'adPortfolios') {
          name = _get(value, ['entity', 'extendedAttributes', 'amsApiModel', 'name'], '');
        }
        res.push({
          name,
          type,
          reason
        });
      });
    }
  });
  return res;
};

const SubmitScreen = ({
  push,
  respondResult,
  retailer,
  app
}: {
  push: PushFn;
  respondResult: any;
  retailer: ReduxStore['retailer'];
  app: ReduxStore['app'];
}) => {
  if (!respondResult) {
    return null;
  }
  const { searchParams } = app.queryParams;

  const respondData = _get(respondResult, ['data']);

  const status = _get(respondData, ['extendedAttributes', 'status']);
  let topLine = '';

  if (status === 'success') {
    const bottomLine = 'All details were received.';
    const buttons = [];
    if (['updateTargetBid', 'updateProductBid'].includes(respondData.updateType)) {
      topLine = updateBidSuccess;
      buttons.push({
        text: 'View Logs',
        onClick: () => {
          push(`/home${searchParams}&tab=adManager&subtab=actionLog`);
        },
        buttonProps: {
          secondary: true
        }
      });
      buttons.push({
        text: 'View Bid Change',
        onClick: () => {
          window.location.reload();
        },
        buttonProps: {}
      });
    } else if (respondData.updateType === 'createCampaignTarget') {
      topLine = addTargetSuccess;
      buttons.push({
        text: 'View Logs',
        onClick: () => {
          push(`/home${searchParams}&tab=adManager&subtab=actionLog`);
        },
        buttonProps: {
          secondary: true
        }
      });
      buttons.push({
        text: 'View Bid Change',
        onClick: () => {
          window.location.reload();
        },
        buttonProps: {}
      });
    } else if (respondData.updateType === 'bulkBudgetUpdate') {
      topLine = updateBudgetSuccess;
      buttons.push({
        text: 'View Logs',
        onClick: () => {
          push(`/home${searchParams}&tab=adManager&subtab=actionLog`);
        },
        buttonProps: {
          secondary: true
        }
      });
      buttons.push({
        text: 'Update More',
        onClick: () => {
          window.location.reload();
        },
        buttonProps: {}
      });
    } else if (respondData.updateType === 'createCampaignProduct') {
      topLine = addProductsSuccess;
      buttons.push({
        text: 'View Logs',
        onClick: () => {
          push(`/home${searchParams}&tab=adManager&subtab=actionLog`);
        },
        buttonProps: {
          secondary: true
        }
      });
      buttons.push({
        text: 'Update More',
        onClick: () => {
          window.location.reload();
        },
        buttonProps: {}
      });
    } else if (respondData.updateType === 'createCampaign') {
      topLine = createCampaignSuccess;
      buttons.push({
        text: 'View Campaigns',
        onClick: () => {
          window.open(
            `/home?pp=${retailer.parentPlatform}&rid=${retailer.id}&tab=adManager&subtab=campaignSummary&selectedEntityName=campaignId`,
            '_self'
          );
        },
        buttonProps: {
          secondary: true
        }
      });
      buttons.push({
        text: 'Create More',
        onClick: () => {
          window.location.reload();
        },
        buttonProps: {}
      });
    }
    return <SuccessScreen topLine={topLine} bottomLine={bottomLine} buttons={buttons} />;
  } else if (status === 'partialSuccess') {
    //   } else {
    const buttons = [];
    const { resultProperty } = _get(respondData, ['extendedAttributes']);
    const failedEntities = _get(respondData, ['extendedAttributes', resultProperty, 'failedEntities'], {});
    const items = formatFailedEntities(failedEntities);
    const dialogDetail = {
      title: 'Detail',
      subtitle: getPartialSuccessDialogMessage(respondData.updateType),
      items
    };
    if (['updateTargetBid', 'createCampaignTarget', 'updateProductBid'].includes(respondData.updateType)) {
      buttons.push({
        text: 'View Logs',
        onClick: () => {
          push(`/home${searchParams}&tab=adManager&subtab=actionLog`);
        },
        buttonProps: {
          secondary: true
        }
      });
      buttons.push({
        text: 'View Changes',
        onClick: () => {
          window.location.reload();
        },
        buttonProps: {}
      });
      if (['updateTargetBid', 'updateProductBid'].includes(respondData.updateType)) {
        topLine = updateBidSuccess;
      } else if (respondData.updateType === 'createCampaignTarget') {
        topLine = addTargetSuccess;
      }
    } else if (respondData.updateType === 'bulkBudgetUpdate') {
      topLine = updateBudgetSuccess;
      buttons.push({
        text: 'View Logs',
        onClick: () => {
          push(`/home${searchParams}&tab=adManager&subtab=actionLog`);
        },
        buttonProps: {
          secondary: true
        }
      });
      buttons.push({
        text: 'Update More',
        onClick: () => {
          window.location.reload();
        },
        buttonProps: {}
      });
    } else if (respondData.updateType === 'createCampaignProduct') {
      topLine = addProductsSuccess;
      buttons.push({
        text: 'View Logs',
        onClick: () => {
          push(`/home${searchParams}&tab=adManager&subtab=actionLog`);
        },
        buttonProps: {
          secondary: true
        }
      });
      buttons.push({
        text: 'Update More',
        onClick: () => {
          window.location.reload();
        },
        buttonProps: {}
      });
    } else if (respondData.updateType === 'createCampaign') {
      topLine = createCampaignSuccess;
      buttons.push({
        text: 'View Campaigns',
        onClick: () => {
          window.open(
            `/home?pp=${retailer.parentPlatform}&rid=${retailer.id}&tab=adManager&subtab=campaignSummary&selectedEntityName=campaignId`,
            '_self'
          );
        },
        buttonProps: {
          secondary: true
        }
      });
      buttons.push({
        text: 'Create More',
        onClick: () => {
          window.location.reload();
        },
        buttonProps: {}
      });
    }

    return <PartialSuccessScreen topLine={topLine} dialogDetail={dialogDetail} buttons={buttons} retailer={retailer} />;
  } else if (status === 'failed') {
    const buttons = [];
    const { resultProperty } = _get(respondData, ['extendedAttributes']);
    const failedEntities = _get(respondData, ['extendedAttributes', resultProperty, 'failedEntities'], {});
    const items = formatFailedEntities(failedEntities);
    topLine = 'It appears this request is rejected.';
    const dialogDetail = {
      title: 'Detail',
      subtitle: getFailedDialogMessage(respondData.updateType),
      items
    };
    if (['updateTargetBid', 'createCampaignTarget', 'updateProductBid'].includes(respondData.updateType)) {
      buttons.push({
        text: 'View Logs',
        onClick: () => {
          push(`/home${searchParams}&tab=adManager&subtab=actionLog`);
        },
        buttonProps: {
          secondary: true
        }
      });
      buttons.push({
        text: 'View Changes',
        onClick: () => {
          window.location.reload();
        },
        buttonProps: {}
      });
    } else if (respondData.updateType === 'bulkBudgetUpdate') {
      buttons.push({
        text: 'View Logs',
        onClick: () => {
          push(`/home${searchParams}&tab=adManager&subtab=actionLog`);
        },
        buttonProps: {
          secondary: true
        }
      });
      buttons.push({
        text: 'Update More',
        onClick: () => {
          window.location.reload();
        },
        buttonProps: {}
      });
    } else if (respondData.updateType === 'createCampaignProduct') {
      buttons.push({
        text: 'View Logs',
        onClick: () => {
          push(`/home${searchParams}&tab=adManager&subtab=actionLog`);
        },
        buttonProps: {
          secondary: true
        }
      });
      buttons.push({
        text: 'Update More',
        onClick: () => {
          window.location.reload();
        },
        buttonProps: {}
      });
    } else if (respondData.updateType === 'createCampaign') {
      buttons.push({
        text: 'View Logs',
        onClick: () => {
          push(`/home${searchParams}&tab=adManager&subtab=actionLog`);
        },
        buttonProps: {
          secondary: true
        }
      });
      buttons.push({
        text: 'Create More',
        onClick: () => {
          window.location.reload();
        },
        buttonProps: {}
      });
    }

    return <FailedScreen topLine={topLine} dialogDetail={dialogDetail} buttons={buttons} retailer={retailer} />;
  }
  return null;
};

const mapStateToProps = (state: ReduxStore) => {
  return {
    app: state.app,
    retailer: state.retailer
  };
};
export default connect(mapStateToProps)(withPush(SubmitScreen));
