/* eslint-disable react/prop-types, no-shadow */
import React, { useMemo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import _chunk from 'lodash/chunk';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';
import axios from 'axios';
import { Option } from 'funfix-core';
import { Conditions, TermFilter } from 'sl-api-connector/types';

import { store } from 'src/main';
import { percentColor, buildMetricValue, computePercentChange } from 'src/utils/metrics';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import entitySearchServiceActions from 'src/store/modules/entitySearchService/actions';
import { buildMainEntityConditions } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import './MetricsGrid.scss';
import { METRICTYPE, DATATYPE } from 'src/utils/entityDefinitions';
import Widget from 'src/components/EntityPage/Widget';
import { RowDatum } from 'src/types/store/storeTypes';
import ReduxStore from 'src/types/store/reduxStore';
import { MetricField, ESSDataKey } from 'src/types/application/types';
import { WidgetProps, Widget as WidgetType } from 'src/types/application/widgetTypes';
import { getCampaignChildrenOfAdManagerEntity } from 'src/utils/conditions';
import { mergeConditions } from 'sl-api-connector/search/conditions';
import { buildAggregations, buildTimePeriodRangeFilters } from 'src/components/AdManager/Search';

const MAIN_METRICS_DATA_KEY = 'adManager-keyMetrics-mainMetrics' as const;
const MAIN_METRICS_COMPARISON_DATA_KEY = 'adManager-keyMetrics-mainMetrics-comparison' as const;
const TOTAL_MARKET_DATA_KEY = 'adManager-keyMetrics-totalMarket' as const;

const fetchEntityMetrics: typeof entitySearchServiceOperations.fetchEntityMetrics = (...args) =>
  store.dispatch(entitySearchServiceOperations.fetchEntityMetrics(...args));

const mapMainMetricsCellStateToProps = (state: ReduxStore) => _pick(state, ['retailer']);

const doStripDecimals = (s: string) => {
  const [left, right] = s.split('.');
  if (!right) {
    return left;
  }
  return parseInt(right[0], 10) > 5 ? (parseInt(left, 10) + 1).toString() : left;
};

type MainMetricsCellInnerrops = {
  metricField: MetricField & { stripDecimals: boolean };
  data: RowDatum;
  comparisonData: RowDatum;
} & ReturnType<typeof mapMainMetricsCellStateToProps>;

const MainMetricsCellInner: React.FC<MainMetricsCellInnerrops> = ({
  retailer,
  metricField,
  data: { value: mainValue },
  comparisonData: { value: comparisonValue }
}) => {
  const { periodChange, percentChange } = computePercentChange(comparisonValue, mainValue);
  const {
    prefix: mainPrefix = '',
    value: formattedMainValue = '',
    suffix: mainSuffix = ''
  } = buildMetricValue(
    mainValue,
    metricField.metricType!,
    retailer.currencySymbol,
    true,
    metricField.dataType!,
    retailer.locale
  );
  const {
    prefix: periodChangePrefix,
    value: periodChangeValue = '',
    suffix: periodChangeSuffix = ''
  } = buildMetricValue(
    periodChange,
    metricField.metricType!,
    retailer.currencySymbol,
    true,
    metricField.dataType!,
    retailer.locale
  );
  const { value: percentChangeValue = '', suffix: percentChangeSuffix = '' } = buildMetricValue(
    percentChange,
    METRICTYPE.PERCENT,
    retailer.currencySymbol,
    true,
    DATATYPE.DECIMAL,
    retailer.locale
  );
  return (
    <>
      <div>
        {mainPrefix}
        {metricField.stripDecimals ? doStripDecimals(formattedMainValue) : formattedMainValue}
        {mainSuffix}
      </div>
      <div>
        <span>
          {periodChange >= 0 ? '+' : '-'}
          {periodChangePrefix}
          {!metricField.stripDecimals
            ? periodChangeValue.replace('-', '')
            : doStripDecimals(periodChangeValue.replace('-', ''))}
          {periodChangeSuffix}
        </span>
        <span style={{ paddingLeft: 5 }}>
          (
          <span style={{ color: percentColor(percentChangeValue) }}>
            {percentChange >= 0 ? '+' : ''}
            {doStripDecimals(percentChangeValue)}
            {percentChangeSuffix}
          </span>
          )
        </span>
      </div>
    </>
  );
};

const mapMarketAverageCellStateToProps = ({ retailer }: ReduxStore) => ({ retailer });

const MarketAverageCellInner: React.FC<
  { metricField: MetricField; marketAverageForMetric: number } & ReturnType<typeof mapMarketAverageCellStateToProps>
> = ({ retailer, metricField, marketAverageForMetric }) => {
  const {
    prefix = '',
    value = '',
    suffix = ''
  } = buildMetricValue(
    marketAverageForMetric,
    metricField.metricType!,
    retailer.currencySymbol,
    true,
    metricField.dataType!,
    retailer.locale
  );

  return (
    <>
      {prefix}
      {value}
      {suffix}
    </>
  );
};

const MarketAverageCell = connect(mapMarketAverageCellStateToProps)(MarketAverageCellInner);

/**
 * Computes the change for a given metric given its data fields, assuming each data field is a single week's worth
 * of data fetched with the main time period.
 */
const MainMetricsCell = connect(mapMainMetricsCellStateToProps)(MainMetricsCellInner);

/**
 * For each fetched metric, computes the sum or average for all of the fetched chunks and returns the result as an
 * object.  Market share metrics are averaged, weighting by `count`, and the others are simply summed.
 */
const aggregateTotalMarketData = (data: ESSDataKey, marketShareFieldNames: Set<string>) => {
  const initialAcc: { [dataKey: string]: number } = {};

  const entries = Object.entries(data).filter(([key]) => !key.includes('dataPointCount') && key !== 'apiRequest');

  return entries.reduce((acc, [key, val]) => {
    const metricName = key.split('_by_')[0];

    // Weighted average by count if it's a market average field, sum otherwise.
    const value = marketShareFieldNames.has(metricName)
      ? val.data.reduce((sum, { value }) => sum + value, 0)
      : (() => {
          const { value, count } = val.data.reduce(
            (acc, { value, count }) => ({ count: acc.count + count, value: acc.value + value * count }),
            { value: 0, count: 0 }
          );

          return value / count;
        })();
    return { ...acc, [metricName]: value };
  }, initialAcc);
};

const computeMarketShare = (totalMarketAggregateValue: number, clientValues: RowDatum[]) => {
  // There should only be at most a single datum here since we group by `retailerId`
  const clientValue = Option.of(_get(clientValues, [0]) as RowDatum | undefined)
    .flatMap((rowDatum) => Option.of(rowDatum.value as number | null))
    .getOrElse(0);

  // Then, we divide that by the total market value average for the time period
  return clientValue / totalMarketAggregateValue;
};

interface GridRowProps {
  colWidthPercents: number[];
  title?: string;
  isHeader?: boolean;
  trendChartWidget?: WidgetType;
  widgetProps?: object;
  columnNames: string[];
  headerCells?: React.ReactNode[];
  mainMetricsData?: ESSDataKey | null;
  mainMetricsComparisonData?: ESSDataKey | null;
  metricField?: MetricField & { stripDecimals: boolean };
  totalMarketMetricFields: (MetricField | null)[];
  i: number;
  aggregateTotalMarketValueByMetricName?: ReturnType<typeof aggregateTotalMarketData> | null;
  marketShareFieldNames: Set<string>;
}

const GridRowInner: React.FC<GridRowProps> = ({
  title,
  isHeader,
  colWidthPercents,
  trendChartWidget,
  widgetProps,
  headerCells,
  metricField,
  totalMarketMetricFields,
  i,
  aggregateTotalMarketValueByMetricName,
  mainMetricsData,
  mainMetricsComparisonData,
  marketShareFieldNames
}) => {
  const mainMetricsDataArray = Option.of(_get(mainMetricsData, [`${_get(metricField, 'name')}_by_retailerId`, 'data']));
  const mainMetricsDatum = mainMetricsDataArray.map((arr) => arr[0] || { value: 0 }).orNull();
  const mainMetricsComparisonDataArray: Option<RowDatum[]> = Option.of(
    _get(mainMetricsComparisonData, [`${_get(metricField, 'name')}_by_retailerId`, 'data'])
  );
  const mainMetricsComparisonDatum = mainMetricsComparisonDataArray.map((arr) => arr[0] || { value: 0 }).orNull();
  const mainMetrics =
    mainMetricsDatum && mainMetricsComparisonDatum ? (
      <MainMetricsCell data={mainMetricsDatum} comparisonData={mainMetricsComparisonDatum} metricField={metricField!} />
    ) : null;

  const showMarketShare = totalMarketMetricFields[i] && marketShareFieldNames.has(totalMarketMetricFields[i]!.name!);
  const showMarketAverage = !showMarketShare;

  const marketShare =
    showMarketShare && aggregateTotalMarketValueByMetricName && mainMetricsData
      ? computeMarketShare(
          aggregateTotalMarketValueByMetricName[totalMarketMetricFields![i]!.name!],
          mainMetricsData[`${metricField!.name!}_by_retailerId`].data
        )
      : null;

  const marketAverageForMetric = Option.of(showMarketAverage || null)
    .map(() => _get(totalMarketMetricFields, [i, 'name']))
    .flatMap((key) => Option.of(_get(aggregateTotalMarketValueByMetricName, [key])));

  const colValues =
    isHeader && headerCells
      ? headerCells
      : [
          title,
          mainMetrics,
          marketAverageForMetric
            .map((marketAverageForMetric) => {
              const metricFieldForMetric = totalMarketMetricFields![i]!;

              return (
                // eslint-disable-next-line react/jsx-key
                <MarketAverageCell metricField={metricFieldForMetric} marketAverageForMetric={marketAverageForMetric} />
              );
            })
            .orNull(),
          Option.of(marketShare)
            .map((marketShareDecimal) => {
              // eslint-disable-next-line react/jsx-key
              return <span>{numeral(marketShareDecimal).format('10.00%')}</span>;
            })
            .orNull(),
          trendChartWidget ? <Widget key={`${title}-${i}`} {...widgetProps} widget={trendChartWidget} /> : ''
        ].map((datum) => (_isNil(datum) && title ? '-' : datum));

  return (
    <div className="grid-row">
      {colWidthPercents.map((_, i) => (
        <div key={i} style={{ width: `${colWidthPercents[i]}%` }} className="grid-cell">
          {colValues[i]}
        </div>
      ))}
    </div>
  );
};

const GridRow = React.memo(GridRowInner);

const KEYWORD_CHUNK_SIZE = 950 as const;

const fetchData = async (
  reduxProps: Pick<
    ReduxStore,
    'entityService' | 'retailer' | 'app' | 'mainTimePeriod' | 'adCampaigns' | 'comparisonTimePeriod'
  >,
  gridFields: (MetricField | null)[],
  totalMarketFields: (MetricField | null)[],
  baseConditions: Conditions,
  setKeywordChunkCount: (keywordChunkCount: number) => void
) => {
  const { mainEntity } = reduxProps.entityService;
  if (!mainEntity) {
    console.warn('Not fetching metrics grid data due to missing `mainEntity`');
    return;
  }

  // Fetch the full list of keywords that the current entity targets, using the list of campaign IDs that it consists of
  // to fetch that list from the ad manager microservice
  const campaignIdsForCurrentEntity = getCampaignChildrenOfAdManagerEntity(mainEntity, reduxProps.adCampaigns);

  const targetedKeywords: string[] = (
    await axios.post('/apiAdManager/adCampaigns/getRawTargetKeywords', campaignIdsForCurrentEntity)
  ).data;
  if (targetedKeywords.length === 0) {
    // This is ignored by the API but serves to trigger a request to actually be kicked off
    targetedKeywords.push(null as any);
  }
  const chunkCount = Math.ceil(targetedKeywords.length / KEYWORD_CHUNK_SIZE);
  setKeywordChunkCount(chunkCount);

  const { retailer, app, mainTimePeriod, comparisonTimePeriod } = reduxProps;

  const pageSize = 1000000 as const; // We're fetching by week ID, so the data set's size is small + limited.
  const filteredGridFields: MetricField[] = gridFields.reduce(
    (acc, fieldOpt) => (fieldOpt ? [...acc, fieldOpt] : acc),
    [] as MetricField[]
  );

  const indexName = 'adCampaignAdGroupProductTargetDailyMetrics';
  const { mainTimePeriodRangeFilters, comparisonTimePeriodRangeFilters } = buildTimePeriodRangeFilters({
    app,
    indexName,
    mainTimePeriod,
    comparisonTimePeriod
  });

  const { timePeriodRangeFilters: mainTimePeriodRangeFilterByWeekId } = buildTimePeriodRangeFilters({
    app,
    indexName: 'traffic-all',
    mainTimePeriod,
    comparisonTimePeriod
  });
  const filteredTotalMarketFields: MetricField[] = totalMarketFields.reduce(
    (acc, fieldOpt) => (fieldOpt ? [...acc, fieldOpt] : acc),
    [] as MetricField[]
  );
  // We fetch data for each of the metrics displayed in the grid
  const [aggs] = buildAggregations(filteredGridFields);

  const mainEntityConditions = mergeConditions(
    { rangeFilters: [...mainTimePeriodRangeFilters] },
    buildMainEntityConditions(baseConditions, mainEntity, app, retailer)
  );

  const statsRequestOverrides = {
    doAggregation: true,
    aggregations: [
      {
        aggregationFields: aggs.aggregations,
        conditions: {
          termFilters: [{ fieldName: 'retailerId', values: [+retailer.id] }],
          rangeFilters: [...mainTimePeriodRangeFilters]
        },
        groupByFieldName: 'retailerId'
      }
    ],
    conditions: mergeConditions(mainEntityConditions, { rangeFilters: [...mainTimePeriodRangeFilters] }),
    pageSize,
    processDocuments: true
  };

  // Main Metrics
  fetchEntityMetrics(
    `${MAIN_METRICS_DATA_KEY}-${reduxProps.entityService.mainEntity!.id}`,
    {
      entity: mainEntity,
      retailer,
      app,
      indexName
    },
    [statsRequestOverrides]
  );

  // Update the time period range filter for the comparison time period
  const mainMetricsComparisonRequestOverrides = _cloneDeep(statsRequestOverrides);
  mainMetricsComparisonRequestOverrides.aggregations[0].conditions.rangeFilters = [...comparisonTimePeriodRangeFilters];
  mainMetricsComparisonRequestOverrides.conditions.rangeFilters = [...comparisonTimePeriodRangeFilters];

  // Comparison Metrics
  fetchEntityMetrics(
    `${MAIN_METRICS_COMPARISON_DATA_KEY}-${reduxProps.entityService.mainEntity!.id}`,
    {
      entity: mainEntity,
      retailer,
      app,
      indexName
    },
    [mainMetricsComparisonRequestOverrides]
  );

  // We want to get the adveretising traffic of the whole market to use as the divisor for calculating market share
  const [marketShareAggs] = buildAggregations(filteredTotalMarketFields);

  _chunk(targetedKeywords, KEYWORD_CHUNK_SIZE).forEach((targetKeywordsChunk) => {
    const searchTermTermFilter: TermFilter = {
      fieldName: 'searchTerm',
      condition: 'should',
      values: targetKeywordsChunk
    };

    // Total market advertising traffic using Atlas data
    fetchEntityMetrics(
      `${TOTAL_MARKET_DATA_KEY}-${reduxProps.entityService.mainEntity!.id}`,
      {
        entity: mainEntity,
        retailer,
        app,
        indexName: 'traffic-all',
        // This pushes additional items into the end of the existing array
        mergeIfStatePropertyValueExists: true
      },
      [
        {
          doAggregation: true,
          aggregations: [
            {
              aggregationFields: marketShareAggs.aggregations,
              conditions: {
                termFilters: [{ fieldName: 'retailerId', values: [+retailer.id] }],
                rangeFilters: [mainTimePeriodRangeFilterByWeekId]
              },
              groupByFieldName: 'retailerId'
            }
          ],
          conditions: {
            termFilters: [...(baseConditions.termFilters || []), searchTermTermFilter],
            rangeFilters: [mainTimePeriodRangeFilterByWeekId]
          },
          pageSize,
          processDocuments: true
        }
      ]
    );
  });
};

const mapStateToProps = (state: ReduxStore) => {
  const mainMetricsDataKey = `${MAIN_METRICS_DATA_KEY}-${state.entityService.mainEntity!.id}`;
  const mainMetricsComparisonDataKey = `${MAIN_METRICS_COMPARISON_DATA_KEY}-${state.entityService.mainEntity!.id}`;

  return {
    ..._pick(state, ['retailer', 'app', 'mainTimePeriod', 'comparisonTimePeriod', 'entityService', 'adCampaigns']),
    mainMetricsData: (state.entitySearchService[mainMetricsDataKey] || null) as ESSDataKey | null,
    mainMetricsComparisonData: (state.entitySearchService[mainMetricsComparisonDataKey] || null) as ESSDataKey | null,
    totalMarketData: (state.entitySearchService[`${TOTAL_MARKET_DATA_KEY}-${state.entityService.mainEntity!.id}`] ||
      null) as ESSDataKey | null,
    mainMetricsDataKey,
    mainMetricsComparisonDataKey
  };
};

const MetricsGrid: React.FC<WidgetProps & ReturnType<typeof mapStateToProps>> = ({
  widget,
  entityService,
  retailer,
  app,
  mainTimePeriod,
  entityConditions,
  adCampaigns,
  mainMetricsData,
  mainMetricsComparisonData,
  totalMarketData,
  comparisonTimePeriod,
  mainMetricsDataKey,
  mainMetricsComparisonDataKey,
  ...widgetProps
}) => {
  const [keywordChunkCount, setKeywordChunkCount] = useState<number | null>(null);
  useEffect(() => {
    if (_isEmpty(adCampaigns)) {
      return;
    }

    if (mainMetricsData) {
      store.dispatch(entitySearchServiceActions.deleteKey(mainMetricsDataKey));
    }
    if (mainMetricsComparisonData) {
      store.dispatch(entitySearchServiceActions.deleteKey(mainMetricsComparisonDataKey));
    }

    fetchData(
      { entityService, retailer, app, mainTimePeriod, comparisonTimePeriod, adCampaigns },
      widget.data.gridFields,
      widget.data.totalMarketFields,
      entityConditions,
      setKeywordChunkCount
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    entityService.mainEntity,
    mainTimePeriod.startWeek,
    mainTimePeriod.endWeek,
    comparisonTimePeriod.startWeek,
    comparisonTimePeriod.endWeek,
    adCampaigns
  ]);

  const aggregateTotalMarketValueByMetricName = useMemo(() => {
    if (_isNil(keywordChunkCount) || !totalMarketData) {
      return null;
    }

    return aggregateTotalMarketData(totalMarketData, widget.data.marketShareFieldNames);
  }, [totalMarketData, keywordChunkCount, widget.data.marketShareFieldNames]);

  return (
    <div className="ad-manager-summary-metrics-grid">
      <GridRow
        colWidthPercents={widget.view.colWidthPercents}
        columnNames={widget.view.columnNames}
        isHeader
        headerCells={widget.view.headerCells}
        totalMarketMetricFields={widget.data.totalMarketFields}
        i={-1}
        marketShareFieldNames={widget.data.marketShareFieldNames}
      />
      {(widget.data.gridFields as ((MetricField & { stripDecimals: boolean }) | null)[]).map((field, i) =>
        field ? (
          <GridRow
            colWidthPercents={widget.view.colWidthPercents}
            key={field.name}
            metricField={field}
            totalMarketMetricFields={widget.data.totalMarketFields}
            title={field.displayName}
            trendChartWidget={widget.data.trendChartWidgets[i]}
            widgetProps={{ ...widgetProps, entityConditions }}
            columnNames={widget.view.columnNames}
            aggregateTotalMarketValueByMetricName={aggregateTotalMarketValueByMetricName}
            mainMetricsData={mainMetricsData}
            mainMetricsComparisonData={mainMetricsComparisonData}
            i={i}
            marketShareFieldNames={widget.data.marketShareFieldNames}
          />
        ) : (
          <GridRow
            colWidthPercents={widget.view.colWidthPercents}
            key={`empty-${i}`}
            title=""
            columnNames={widget.view.columnNames}
            totalMarketMetricFields={widget.data.totalMarketFields}
            i={-1}
            marketShareFieldNames={widget.data.marketShareFieldNames}
          />
        )
      )}
    </div>
  );
};

const EnhancedMetricsGrid = connect(mapStateToProps)(MetricsGrid);

export default EnhancedMetricsGrid;
