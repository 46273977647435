import React, { Component } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EMAIL_VALIDATOR_REGEX } from 'src/utils/validators';
import { withRouter } from 'react-router';
import { anyNotEq } from 'src/utils/equality';
import queryString from 'qs';
import Cycle from '!svg-react-loader!src/components/common/UploadAnimation/cycle.svg'; // eslint-disable-line
import Check from '!svg-react-loader!src/components/common/UploadAnimation/check.svg'; // eslint-disable-line
import { authOperations } from '../../store/modules/auth';
import NewPassword from './NewPassword';
import i18n from '../../i18n_en';
import { LoginContainers } from '../SignIn/SignInContainer';
import colors from 'src/utils/colors';
import 'src/components/AdManager/SubmitScreen/submitScreen.scss';
import LogoContainer from '../SignIn/LogoContainer';
import AuthTitle from '../SignIn/AuthTitle';
import AuthContainer from '../SignIn/AuthContainer';
import AuthButton from '../SignIn/AuthButton';
import { Text } from '@stackline/ui';
import SignInInputField from 'src/components/SignIn/SignInInputField';
import _pick from 'lodash/pick';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { AnimatedEllipsis } from 'src/components/common/Loading/AnimatedEllipsis';
import { Box } from '@mui/system';

const styles = {
  buttonText: {
    color: colors.labelGrey,
    fontSize: '30px',
    fontWeight: 500
  },
  buttonWrapperOuter: {
    marginTop: '30px',
    textAlign: 'center'
  },
  buttonWrapperInner: {
    marginTop: '28px',
    textAlign: 'center'
  },
  confirmationScreenButton: {
    marginTop: '46px',
    textAlign: 'center'
  }
};

export const SuccessCycle = () => {
  return (
    <div className="finish_screen">
      <div className="icon_group">
        <Cycle className="success_cycle" />
        <Check className="success_check" />
      </div>
    </div>
  );
};
class ResetPassword extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    createNewPassword: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    resetPassword: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    clearErrorMessage: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const { location } = props;
    const params = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });

    this.state = {
      isFormSubmitted: false,
      buttonText: i18n.button_resetPassword,
      currentStep: params.step || 'inputEmail',
      email: params.username
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (!this.state.email) {
      history.push(`/password_reset`);
    }
  }

  componentWillReceiveProps(nextProps) {
    const propKeysToCompare = ['location.pathname', 'location.search'];
    if (anyNotEq(propKeysToCompare, this.props, nextProps)) {
      const { location } = nextProps;
      const params = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
      const { email } = this.state;
      this.setState({
        currentStep: params.step || 'inputEmail',
        email: params.username || email
      });
    }
  }

  setNotification = (msg) => this.props.setErrorMessage(msg);

  handleEmailChange = (values) => {
    const emailChange = { notificationMSG: '', ...values };
    this.setState(emailChange);
  };

  handleOTPContinueButtonClick = () => {
    const { history } = this.props;
    history.push(`/password_reset?step=inputNewPassword`);
  };

  handleFormSubmit = (event) => {
    const { history } = this.props;
    event.preventDefault();
    const { email } = this.state;

    const isEmailValid = EMAIL_VALIDATOR_REGEX.test(email);

    if (!isEmailValid) {
      this.props.setErrorMessage('Email address is invalid. Please try again.');

      return;
    }

    this.setState({ isFormSubmitted: true });
    this.setState({ buttonText: i18n.button_resettingPassword });

    axios
      .post(`/api/user/SendPasswordReset?email=${window.encodeURIComponent(email.toLowerCase())}`)
      .then((response) => {
        if (response.status === 'signUpLinkSent') {
          history.push('/password_reset?step=redirectForSignUp');
        } else {
          this.setState({ buttonText: i18n.button_resetPassword });
          history.push(`/password_reset?step=inputNewPassword`);
        }
      })
      .catch((err) => {
        // We assume this error is always the user is not existed in our database 500 internal error
        console.error('Error sending password reset ::: ', err);
        this.props.setErrorMessage(
          'The email address provided is not registered. Please contact your Stackline representative for further guidance.'
        );
        this.setState({ isFormSubmitted: false });
        this.setState({ buttonText: i18n.button_resetPassword });
      });
  };

  render() {
    const { email, currentStep, isFormSubmitted, buttonText } = this.state;
    const { app, createNewPassword, auth } = this.props;
    const { message: notificationMSG } = auth;
    const Container = LoginContainers[app.name].container;

    if (currentStep === 'inputNewPassword') {
      return (
        <Container appName={app.name} notificationMSG={notificationMSG}>
          <NewPassword
            app={app}
            username={email}
            createNewPassword={createNewPassword}
            setNotification={this.setNotification}
          />
        </Container>
      );
    } else if (currentStep === 'passwordExpired') {
      return (
        <Container appName={app.name} notificationMSG={notificationMSG}>
          <NewPassword
            app={app}
            // Forgot password and expired password have same layout
            isPasswordExpiredLayout
            username={email}
            createNewPassword={createNewPassword}
            setNotification={this.setNotification}
          />
        </Container>
      );
    } else if (currentStep === 'showOtpMessage') {
      return (
        <Container appName={app.name}>
          <AuthContainer>
            <form onSubmit={this.handleFormSubmit}>
              <LogoContainer title={app.displayName} />
              <AuthTitle marginTop="16px" marginBottom="8px">
                Verification
              </AuthTitle>
              <Text textAlign="center" variant="subtitle2">
                {i18n.password_reset_otp_message} {email}
                {'. '}
                {i18n.password_reset_instruction_message}.
              </Text>

              <AuthButton
                sx={{
                  marginTop: '24px'
                }}
                onClick={this.handleOTPContinueButtonClick}
              >
                Continue
              </AuthButton>
            </form>
          </AuthContainer>
        </Container>
      );
    } else if (currentStep === 'redirectForSignUp') {
      return (
        <Container appName={app.name}>
          <AuthContainer>
            <form>
              <LogoContainer title={app.displayName} />
              <AuthTitle marginTop="16px" marginBottom="8px">
                Verification
              </AuthTitle>
              <Flex>
                <Text variant="subtitle1" textAlign="center">
                  {i18n.password_new_user_sign_up}
                  {email}.
                </Text>
              </Flex>
            </form>
          </AuthContainer>
        </Container>
      );
    }

    const handleChangeSignInInputField = (e) => {
      this.setState({ email: e.target.value });
      this.props.clearErrorMessage(null);
    };

    return (
      <Container appName={app.name} notificationMSG={notificationMSG}>
        <AuthContainer>
          <form onSubmit={this.handleFormSubmit}>
            <LogoContainer title={app.displayName} />
            <AuthTitle marginTop="24px" marginBottom="16px">
              Forgot your password?
            </AuthTitle>
            <Flex justifyContent="center" height="42px" alignItems="center">
              <p style={{ width: '306px' }}>
                <Text variant="subtitle1" textAlign="center">
                  Please enter your Stackline account email below to receive a verification code.
                </Text>
              </p>
            </Flex>

            <SignInInputField
              sx={{ mb: '32px', mt: '32px' }}
              label="Email"
              disabled={isFormSubmitted}
              value={email}
              onChange={handleChangeSignInInputField}
            />

            <div style={styles.buttonWrapperOuter}>
              <div style={styles.buttonWrapperInner}>
                <AuthButton disabled={buttonText !== i18n.button_resetPassword} type="submit">
                  {isFormSubmitted ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      maxHeight="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      Sending Code
                      <AnimatedEllipsis
                        width={40}
                        height={40}
                        style={{ margin: 0, transform: 'translate(-8px,5px)' }}
                      />
                    </Box>
                  ) : (
                    'Send Code'
                  )}
                </AuthButton>
              </div>
            </div>
          </form>
        </AuthContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => _pick(state, ['app', 'auth']);

const mapDispatchToProps = {
  resetPassword: authOperations.resetPassword,
  createNewPassword: authOperations.createNewPassword,
  clearErrorMessage: authOperations.clearMessage,
  setErrorMessage: authOperations.setErrorMessage
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
