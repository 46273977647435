import React from 'react';
import { useAppSelector } from 'src/utils/Hooks';
import { useCreateRemoveRetailPriceRangeFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { useMetricByWeekId } from 'src/serverProxy/useMetricByWeekId';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import TotalTrafficDonutChart from './TotalTrafficDonutChart';
import { TrafficBarCharts } from './TrafficBarCharts';
import { shouldShowBeaconProTopics } from 'src/utils/app';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';

/**
 * Shows donut chart and bar charts for the top section of Total Traffic
 */
export const TotalTrafficTopSection = () => {
  const mainEntityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const removeRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();

  // TODO: Use agg-traffic index when available for topics
  const index = mainEntityType === 'searchtermlist' ? 'traffic' : 'aggregated-traffic';

  const { primaryTotal: totalClicks, isLoading: totalLoading } = useMetricByWeekId({
    indexName: index,
    fieldName: 'totalClicks',
    requestBuilder: removeRetailPriceRangeFilters()
  });
  const {
    primaryTotal: organicClicks,
    secondaryTotal: organicClicksComparison,
    isLoading: organicLoading
  } = useMetricByWeekId({
    indexName: index,
    fieldName: 'organicClicks',
    requestBuilder: removeRetailPriceRangeFilters()
  });
  const {
    primaryTotal: paidTraffic,
    secondaryTotal: paidTrafficComparison,
    isLoading: paidLoading
  } = useMetricByWeekId({
    indexName: index,
    fieldName: 'adClicks',
    requestBuilder: removeRetailPriceRangeFilters()
  });
  const {
    primaryTotal: otherTraffic,
    secondaryTotal: otherTrafficComparison,
    isLoading: otherLoading
  } = useMetricByWeekId({
    indexName: index,
    fieldName: 'otherClicks',
    requestBuilder: removeRetailPriceRangeFilters()
  });
  const { primaryTotal: dspClicks, isLoading: dspLoading } = useMetricByWeekId({
    indexName: index,
    fieldName: 'otherAdClicks',
    requestBuilder: removeRetailPriceRangeFilters()
  });

  const loading = organicLoading || paidLoading || otherLoading || dspLoading || totalLoading;

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <TotalTrafficDonutChart
        loading={loading}
        dspClicks={dspClicks}
        organicClicks={organicClicks}
        otherTraffic={otherTraffic}
        paidTraffic={paidTraffic}
        totalClicks={totalClicks}
      />
      {shouldShowBeaconProTopics() ? (
        <TrafficBarCharts
          organicTraffic={{ current: organicClicks, comparison: organicClicksComparison }}
          paidTraffic={{ current: paidTraffic, comparison: paidTrafficComparison }}
          otherTraffic={{ current: otherTraffic, comparison: otherTrafficComparison }}
          loading={loading}
        />
      ) : (
        <div
          style={{
            width: '624px'
          }}
        >
          <BeaconComparableSplineChart
            indexName={mainEntityType === 'searchtermlist' ? 'traffic' : 'aggregated-traffic'} // TODO: Use agg-traffic index when available for topics
            fieldName="totalClicks"
            showTitleSummary={false}
            splineChartLoadingProps={{
              width: 543
            }}
            chartOptionsOverride={{
              chart: {
                height: 300
              }
            }}
            requestBuilder={removeRetailPriceRangeFilters()}
          />
        </div>
      )}
    </Flex>
  );
};
