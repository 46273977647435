/**
 * This file is mostly copied from ShopperOS,
 * https://gitlab.com/stackline-dev/brandclub-web-app/-/blob/develop/portal/src/components/Forms/ImageCropper.tsx
 */

import Cropper, { CropperProps } from 'react-easy-crop';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Grid, Slider, styled } from '@mui/material';
import StyledDialog from 'src/components/BeaconRedesignComponents/common/StyledDialog/StyledDialog';
import StyledButton from 'src/components/BeaconRedesignComponents/common/StyledButton';

import { useKeypress } from 'src/utils/Hooks';
import { useStacklineTheme } from '@stackline/ui';

const readFile = (file: any) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string), false);
    reader.readAsDataURL(file);
  });
};

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

const getCroppedImg = async (imageSrc: string, pixelCrop: any, type: string) => {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.fillStyle = '#ffffff00';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file);
    }, type);
  });
};

const cropWindow = {
  width: 400,
  height: 360
} as const;

const CropperContainer = styled('div')({
  width: cropWindow.width,
  padding: 25,
  '.crop': {
    position: 'relative',
    width: '100%',
    height: cropWindow.height,
    background: '#fff',
    borderRadius: 10,
    overflow: 'hidden'
  },
  '.control': {
    padding: '15px 0 0 10px',
    display: 'flex',
    alignItems: 'center'
  }
});

const LoadingContainer = styled(Grid, {
  shouldForwardProp: (prop) => !['aspectRatio'].includes(prop.toString())
})<{ aspectRatio?: CropperProps['aspect'] }>(({ aspectRatio }) => ({
  width: cropWindow.width,
  height: cropWindow.height,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  img: {
    aspectRatio: `${aspectRatio}`,
    overflow: 'hidden'
  }
}));

/**
 * Generic component for opening a popup that lets you crop an
 * image file
 */
export interface ImageCropperProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  file: File | null;
  cropperProps: Partial<CropperProps>;
  onFileChange: (newFile: File | null) => void;
}

const ImageCropper: FC<ImageCropperProps> = ({ open, setOpen, file, cropperProps, onFileChange }) => {
  const theme = useStacklineTheme();
  const [imageSrc, setImageSrc] = useState<string>('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [zoom, setZoom] = useState(1);

  const closeModal = () => {
    setOpen(false);

    // Reset zoom after closing modal
    setZoom(1);
  };

  const onCropComplete = useCallback((_croppedArea: any, newCroppedAreaPixels: any) => {
    setCroppedAreaPixels(newCroppedAreaPixels);
  }, []);

  const cropIt = useCallback(async () => {
    if (file) {
      try {
        const croppedImage: any = await getCroppedImg(imageSrc, croppedAreaPixels, file ? file.type : null);

        const newFileName = file.name;

        const newFile = new File([croppedImage], newFileName, {
          type: file.type
        });

        onFileChange(newFile);
        closeModal();
      } catch (e) {
        console.error('error cropping image: ', e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSrc, croppedAreaPixels, file, onFileChange, setOpen]);

  const moveCrop = (x: number, y: number) => {
    setCrop({ x: crop.x + x, y: crop.y + y });
  };

  useEffect(() => {
    setCrop({ x: 0, y: 0 });
  }, [imageSrc]);

  useKeypress(
    'ArrowRight',
    () => {
      moveCrop(1, 0);
    },
    [crop]
  );

  useKeypress(
    'ArrowLeft',
    () => {
      moveCrop(-1, 0);
    },
    [crop]
  );
  useKeypress(
    'ArrowUp',
    () => {
      moveCrop(0, -1);
    },
    [crop]
  );
  useKeypress(
    'ArrowDown',
    () => {
      moveCrop(0, 1);
    },
    [crop]
  );

  useEffect(() => {
    const initImage = async () => {
      const imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl as string);
    };
    if (file) {
      initImage();
    }
  }, [file]);

  return (
    <StyledDialog
      open={open}
      width={450}
      height={458}
      padding="0px 0px 0px 25px"
      onClose={() => {
        closeModal();
      }}
      maxWidth="lg"
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <CropperContainer>
        {imageSrc ? (
          <>
            <div className="crop">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                restrictPosition
                showGrid={false}
                {...cropperProps}
                minZoom={1}
              />
            </div>
            <div className="control">
              <div style={{ flex: 1, marginRight: 30 }}>
                <Slider
                  value={zoom}
                  sx={{
                    color: theme.colors.primary
                  }}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  classes={{}}
                  onChange={(e, newZoom) => setZoom(newZoom as number)}
                />
              </div>
              <div>
                <StyledButton onClick={cropIt}>Crop</StyledButton>
              </div>
            </div>
          </>
        ) : (
          <LoadingContainer aspectRatio={cropperProps.aspect} />
        )}
      </CropperContainer>
    </StyledDialog>
  );
};

export default ImageCropper;
