import React from 'react';
import { Text } from '@stackline/ui';
import { getProductImageUrl } from 'src/utils/image';
import { useAppSelector } from 'src/utils/Hooks';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Link } from 'react-router-dom';

interface ProductCellProps {
  title: string;
  stacklineSku: string;
  customLink?: string | null;
}

/**
 * Shows a product image and title for use in a table
 */
const ProductCell = ({ stacklineSku, title, customLink = null }: ProductCellProps) => {
  const { searchParams, additionalParams } = useAppSelector((state) => state.app.queryParams);

  return (
    <Flex gap="md" alignItems="center">
      <img
        key={stacklineSku}
        style={{
          minWidth: '30px',
          maxWidth: '30px',
          maxHeight: '30px',
          objectFit: 'cover'
        }}
        src={getProductImageUrl(stacklineSku)}
        alt={title}
      />
      <Link to={customLink || `/product/${stacklineSku.toUpperCase()}${searchParams}${additionalParams}`}>
        <Text variant="body2" truncateLines={2} title={title}>
          {title}
        </Text>
      </Link>
    </Flex>
  );
};

export default ProductCell;
