import React, { useEffect, useState } from 'react';
import { useStacklineTheme } from '@stackline/ui';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import ReduxStore from 'src/types/store/reduxStore';
import { PHONE_NUMBER_VALIDATOR_REGEX } from 'src/utils/validators';
import StyledInput from 'src/components/BeaconRedesignComponents/common/StyledInput';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { useSnackbar } from 'src/utils/Hooks';
import MaskedInput from 'src/components/BeaconRedesignComponents/common/StyledInput/MaskedInput';
import { phoneNumberMask } from 'src/components/BeaconRedesignComponents/common/StyledInput/inputMasks';
import ProfilePictureWithUpload from './ProfilePictureWithUpload';
import _capitalize from 'lodash/capitalize';
import * as serverProxy from '../serverProxy';
import { SettingsProfile } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/NewSettingsLayout';
import { settingsErrorMessage } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/utils';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

export interface ProfileSummaryLayoutProps {
  user: ReduxStore['user'];
  handleProfileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  handlePhoneNumberChange: (values: any) => void;
  profile: SettingsProfile;
}

/**
 * Simple wrapper for holding an input label and input
 */
function InputContainer({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      flexDirection="column"
      gap="md"
      width="242px"
      sx={{
        '[data-lastpass-icon-root="true"]': {
          display: 'none' // makes weird height changes if we don't hide it
        }
      }}
    >
      {children}
    </Flex>
  );
}

/**
 * Returns true if the phone number is valid, false otherwise
 */
const isPhoneNumberValid = (phoneNumber: string) => PHONE_NUMBER_VALIDATOR_REGEX.test(phoneNumber);

/**
 * Allows users to edit their profile information.
 */
export default function ProfileSummaryLayout({
  user,
  handleProfileChange,
  onSubmit,
  handlePhoneNumberChange,
  profile
}: ProfileSummaryLayoutProps) {
  const { showSnackbar } = useSnackbar();

  const theme = useStacklineTheme();

  const { firstName, lastName, email, company, jobTitle, phoneNumber, role } = user.profile;

  // We use refs because the user account container doesn't provide a great way for
  // the inputs to be controlled. This lets us reset when the user cancels changes.
  const firstNameInputRef = React.useRef<HTMLInputElement>(null);
  const lastNameInputRef = React.useRef<HTMLInputElement>(null);
  const companyInputRef = React.useRef<HTMLInputElement>(null);
  const jobTitleInputRef = React.useRef<HTMLInputElement>(null);
  const phoneNumberInputRef = React.useRef<HTMLInputElement>(null);

  /**
   * Error states for the required form fields
   */
  const [errors, setErrors] = useState<{ [key in 'firstName' | 'lastName' | 'phoneNumber']: boolean }>({
    firstName: false,
    lastName: false,
    phoneNumber: false
  });

  const updatePhoneNumber = (newNumber: string) => {
    handlePhoneNumberChange({
      phoneNumber: newNumber || '',
      validator: { isPhoneNumberValid: isPhoneNumberValid(newNumber) }
    });
  };

  /**
   * Updates won't trigger unless phone number is updated.
   * Hacky but we can fix logic once we have mocks for error
   * handling.
   */
  useEffect(() => {
    updatePhoneNumber(phoneNumber);
  }, [phoneNumber]);

  /**
   * Undoes any unsaved changes to profile information.
   */
  const cancelChanges = () => {
    [
      {
        name: 'firstName',
        value: firstName,
        ref: firstNameInputRef
      },
      {
        name: 'lastName',
        value: lastName,
        ref: lastNameInputRef
      },
      {
        name: 'company',
        value: company,
        ref: companyInputRef
      },
      {
        name: 'jobTitle',
        value: jobTitle,
        ref: jobTitleInputRef
      }
    ].forEach(({ name, value, ref }) => {
      if (ref.current) {
        ref.current.value = value;
      }

      // Queue in the event loop since the parent uses setState
      // which is async
      setTimeout(() =>
        handleProfileChange({
          target: {
            name,
            value
          }
        } as React.ChangeEvent<HTMLInputElement>)
      );
    });

    phoneNumberInputRef.current.value = phoneNumber;
    setTimeout(() => updatePhoneNumber(phoneNumber));
  };

  const onPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    updatePhoneNumber(value);
  };

  /**
   * Sets error states for invalid fields. Returns
   * true if the form is valid, false otherwise
   */
  const validateForm = () => {
    const valueValid = (ref: React.MutableRefObject<HTMLInputElement>) => {
      return ref.current && ref.current.value.trim().length > 0;
    };

    const firstNameValid = valueValid(firstNameInputRef);
    const lastNameValid = valueValid(lastNameInputRef);

    const curPhoneNumber = phoneNumberMask.extractRawValue(phoneNumberInputRef.current.value);
    const phoneNumberValid = isPhoneNumberValid(curPhoneNumber);

    setErrors({
      firstName: !firstNameValid,
      lastName: !lastNameValid,
      phoneNumber: !phoneNumberValid
    });

    return firstNameValid && lastNameValid && phoneNumberValid;
  };

  const submitForm: React.FormEventHandler<HTMLFormElement> = async (event) => {
    if (!validateForm()) {
      event.preventDefault();
      showSnackbar({
        type: 'error',
        message: 'Enter all required fields. Please try again.'
      });
      return;
    }

    try {
      await onSubmit(event);
      showSnackbar({
        type: 'success',
        message: 'Your profile has been updated successfully!'
      });
    } catch {
      showSnackbar({
        type: 'error',
        message: settingsErrorMessage
      });
    }
  };

  const horiz_gap = 120;
  // Yes, this is a typo from Redux
  // lol
  return user.isUserFecthing ? null : (
    <>
      <Flex gap="xxl" sx={{ marginX: theme.spacing.lg }}>
        <Flex flexDirection="column" alignItems="center">
          <ProfilePictureWithUpload uploadImage={serverProxy.uploadUserImage} />
          <Text variant="subtitle2" sx={{ marginTop: '12px', marginBottom: '4px' }}>{`${firstName} ${lastName}`}</Text>
          <Text variant="body3">{_capitalize(role)}</Text>
        </Flex>
        <form
          onSubmit={submitForm}
          autoComplete="off"
          style={{
            flex: '1'
          }}
        >
          <Flex flexDirection="column" gap={56}>
            <Flex flexDirection="column" gap="mdl">
              <Flex gap={horiz_gap}>
                <InputContainer>
                  <Text variant="subtitle1" fontWeight="bold">
                    First Name
                  </Text>
                  <StyledInput
                    defaultValue={firstName}
                    onChange={handleProfileChange}
                    name="firstName"
                    autoComplete="off"
                    ref={firstNameInputRef}
                    error={errors.firstName}
                  />
                </InputContainer>
                <InputContainer>
                  <Text variant="subtitle1" fontWeight="bold">
                    Last Name
                  </Text>
                  <StyledInput
                    name="lastName"
                    defaultValue={lastName}
                    onChange={handleProfileChange}
                    autoComplete="off"
                    ref={lastNameInputRef}
                    error={errors.lastName}
                  />
                </InputContainer>
              </Flex>
              <Flex gap={horiz_gap}>
                <InputContainer>
                  <Text variant="subtitle1" fontWeight="bold">
                    Email
                  </Text>
                  <StyledInput value={email} disabled autoComplete="off" id="type-search" />
                </InputContainer>
                <InputContainer>
                  <Text variant="subtitle1" fontWeight="bold">
                    Phone Number
                  </Text>
                  {phoneNumber !== undefined && (
                    <MaskedInput
                      masker={phoneNumberMask}
                      placeholder="(123) 456-7890"
                      defaultValue={phoneNumber}
                      onChange={onPhoneNumberChange}
                      autoComplete="off"
                      ref={phoneNumberInputRef}
                      error={errors.phoneNumber}
                      style={{
                        maxWidth: 'none'
                      }}
                      value={profile.phoneNumber} // MaskedInput needs a `value` to update the masked value, and `profile` is the profile state from the parent
                    />
                  )}
                </InputContainer>
              </Flex>
              <Flex gap={horiz_gap}>
                <InputContainer>
                  <Text variant="subtitle1" fontWeight="bold">
                    Company
                  </Text>
                  <StyledInput
                    defaultValue={company}
                    name="company"
                    onChange={handleProfileChange}
                    autoComplete="off"
                    ref={companyInputRef}
                  />
                </InputContainer>
                <InputContainer>
                  <Text variant="subtitle1" fontWeight="bold">
                    Job Title
                  </Text>
                  <StyledInput
                    placeholder="Job Title"
                    defaultValue={jobTitle}
                    name="jobTitle"
                    onChange={handleProfileChange}
                    autoComplete="off"
                    ref={jobTitleInputRef}
                  />
                </InputContainer>
              </Flex>
            </Flex>
            <Flex justifyContent="flex-end" gap="md">
              <SlButton onClick={() => cancelChanges()}>Cancel</SlButton>
              <SlButton variant="contained" type="submit">
                Save
              </SlButton>
            </Flex>
          </Flex>
        </form>
      </Flex>
    </>
  );
}
