import React, { useMemo } from 'react';
import GenericBulkAdjustmentModal, { GenericBulkAdjustmentModalProps } from './GenericBulkAdjustmentModal';
import BulkTemplateCircularLoadingInner from './BulkTemplateCircularLoadingInner';
import Divider from '@mui/material/Divider';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { Link } from 'react-router-dom';
import { useStacklineTheme } from '@stackline/ui';
import { useBulkAdjustmentContext } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/hooks/useBulkAdjustmentContext';
import { safeDivide } from 'src/utils/app';
import { UploadBulkAdjustmentPayload } from './serverProxy/types';
import {
  BulkAdjustmentModalType,
  UploadedAdjustment
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkAdjustmentProvider';
import { settingsErrorMessage } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/utils';
import { useAppSelector, useMetricFormatter, useSnackbar } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';

export interface TemplateUploadModalState {
  title: string;
  description: string;
  planType: string;
  payload: UploadBulkAdjustmentPayload;
}

export enum TemplateProcessingStatus {
  UploadAdjustments = 'UploadAdjustments',
  ResumePolling = 'ResumePolling',
  TemplateVerified = 'TemplateVerified',
  VerificationFailed = 'VerificationFailed'
}

export type TemplateProcessingState =
  | {
      status: TemplateProcessingStatus.UploadAdjustments; // Start at 0% and perform all of the steps to upload the adjustments
      payload: UploadBulkAdjustmentPayload;
      percent: number;
    }
  | {
      status: TemplateProcessingStatus.ResumePolling; // File has been uploaded, so check if the file is valid and then recompute net impact
      bulkAdjustmentId: string;
      uploadedAdjustment: UploadedAdjustment;
      computeNetImpact: boolean;
      percent: number;
    }
  | {
      status: TemplateProcessingStatus.TemplateVerified; // Everything looks good, template is valid and net impact is calculated
      totalAdjustments: number; // How many adjustments were in the document
    }
  | {
      status: TemplateProcessingStatus.VerificationFailed; // Some adjustments in the template were invalid
      totalAdjustments: number; // How many adjustments were in the document
      invalidAdjustments: number; // How many adjustments were invalid
      validationResults: string;
    };

export interface BulkTemplateProcessingModalProps extends Omit<GenericBulkAdjustmentModalProps, 'children' | 'header'> {
  state: TemplateProcessingState;
  uploadedAdjustment: UploadedAdjustment;
}

/**
 * Modal that displays a circular loading indicator and a description of the bulk template processing.
 */
export default React.forwardRef(function BulkTemplateProcessingModal(
  { state, uploadedAdjustment, ...rest }: BulkTemplateProcessingModalProps,
  ref: React.Ref<HTMLDivElement>
) {
  const theme = useStacklineTheme();
  const { closeModal, openBulkAdjustmentModal } = useBulkAdjustmentContext();
  const { showSnackbar } = useSnackbar();
  const { queryParams } = useAppSelector((appState) => appState.app);
  const formatMetric = useMetricFormatter();

  const displayedPercentage = useMemo(() => {
    if (!state) {
      return 0;
    }

    switch (state.status) {
      case TemplateProcessingStatus.ResumePolling:
      case TemplateProcessingStatus.UploadAdjustments:
        return state.percent;
      case TemplateProcessingStatus.TemplateVerified:
        return 100;
      case TemplateProcessingStatus.VerificationFailed:
        return Math.max(safeDivide(state.totalAdjustments - state.invalidAdjustments, state.totalAdjustments) * 100, 0);
      default:
        return 0;
    }
  }, [state]);

  const handleDownload = () => {
    try {
      const { validationResults } = state;
      const fileInfo = JSON.parse(validationResults);
      const s3URL = `https://${fileInfo.s3Bucket}.s3.amazonaws.com/${fileInfo.s3Key}`;
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = s3URL;
      anchor.download = null;
      anchor.click();
    } catch (err) {
      console.error('Error downloading bulk adjustment results', err);
      closeModal();
      showSnackbar({
        message: settingsErrorMessage,
        type: 'error',
        timeout: 10000
      });
    }
  };

  const config: {
    header: string;
    descriptionTitle: string;
    descriptionBody: React.ReactNode;
    circleLabel: string;
    color: string;
    footer: React.ReactNode;
  } | null = useMemo(() => {
    switch (state.status) {
      case TemplateProcessingStatus.UploadAdjustments:
      case TemplateProcessingStatus.ResumePolling:
        return {
          header: 'Template Processing',
          descriptionTitle: 'Your bulk template is processing',
          descriptionBody: (
            <>
              {state.status === TemplateProcessingStatus.ResumePolling && state.computeNetImpact
                ? 'Your bulk adjustment was computed on an older forecasting model and needs to be reprocessed.'
                : "We've received your submission and will notify you when processing is complete."}{' '}
              You can close this window in the meantime.
              <br /> View the status of your submission{' '}
              <Link to={`/account/adjustments${queryParams.searchParams}`} onClick={() => closeModal()}>
                <span style={{ textDecoration: 'underline' }}>here</span>
              </Link>
              .
            </>
          ),
          circleLabel: 'Processing',
          color: theme.colors.primary,
          footer: (
            <SlButton onClick={() => closeModal()} variant="contained" buttonSx={{ width: '71px' }}>
              Close
            </SlButton>
          )
        };
      case TemplateProcessingStatus.TemplateVerified:
        return {
          header: 'Bulk Template Results',
          descriptionTitle: 'Your template has been verified',
          descriptionBody: (
            <>
              The file you uploaded contains {formatMetric(state.totalAdjustments, METRICTYPE.VOLUME)} adjustments
              <br></br>
              and all of them were accurate.
            </>
          ),
          circleLabel: 'Accuracy Rate',
          color: theme.colors.success,
          footer: (
            // TODO go to next screen
            <SlButton
              onClick={() =>
                openBulkAdjustmentModal({
                  type: BulkAdjustmentModalType.SubmissionReview
                })
              }
              variant="contained"
              buttonSx={{ width: '71px' }}
            >
              Next
            </SlButton>
          )
        };
      case TemplateProcessingStatus.VerificationFailed:
        return {
          header: 'Bulk Template Results',
          descriptionTitle: 'Your template failed verification',
          descriptionBody: (
            <>
              The file you uploaded contains {formatMetric(state.totalAdjustments, METRICTYPE.VOLUME)} adjustments,
              <br></br>
              but {formatMetric(state.invalidAdjustments, METRICTYPE.VOLUME)} of those adjustments had errors. You must
              correct<br></br> these errors before submitting this bulk adjustment file.
            </>
          ),
          circleLabel: 'Accuracy Rate',
          color: theme.colors.accentGold,
          footer: (
            <Flex gap="md">
              <SlButton onClick={handleDownload}>Download results</SlButton>
              <SlButton
                onClick={() =>
                  openBulkAdjustmentModal({
                    type: BulkAdjustmentModalType.UploadTemplate,
                    isUpdateAdjustment: true,
                    uploadedAdjustment: {
                      bulkAdjustmentTitle: uploadedAdjustment.bulkAdjustmentTitle,
                      bulkAdjustmentDescription: uploadedAdjustment.bulkAdjustmentDescription
                    }
                  })
                }
                variant="contained"
              >
                New upload
              </SlButton>
            </Flex>
          )
        };
      default:
        return null;
    }
  }, [closeModal, openBulkAdjustmentModal, state, theme]);

  if (!config) {
    return null;
  }

  return (
    <GenericBulkAdjustmentModal
      header={config.header}
      footer={
        <>
          <Divider />
          <Flex justifyContent="space-between" alignItems="center" paddingX="48px" paddingY="20px">
            <SlButton disabled buttonSx={{ width: '71px' }}>
              Back
            </SlButton>
            {config.footer}
          </Flex>
        </>
      }
      defaultContentContainer={false}
      ref={ref}
      {...rest}
    >
      <BulkTemplateCircularLoadingInner
        percentage={displayedPercentage}
        circleLabel={config.circleLabel}
        descriptionTitle={config.descriptionTitle}
        descriptionBody={config.descriptionBody}
        circleSx={{ color: config.color }}
        sx={
          // Decrease padding if recomputing net impact so we have room for additional messaging
          {
            height: '490px',
            ...(state.status === TemplateProcessingStatus.ResumePolling && state.computeNetImpact
              ? { padding: '72.5px 112px 71.5px 97px' }
              : {})
          }
        }
      />
    </GenericBulkAdjustmentModal>
  );
});
