import { styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { brandclub_colors } from '@stackline/ui';
import _get from 'lodash/get';
import React, { FC } from 'react';
import { EntityCardContainer } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/EntityCardContainer';
import { useBeaconRoutes } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';
import { AppLinkTabs } from 'src/components/BeaconRedesignComponents/PrimaryPageNavigation/PrimaryNavBar';
import styles from 'src/components/BeaconRedesignComponents/styles/styles';
import {
  BEACON_PRO_SIDEBAR_ITEM_HEIGHT,
  BEACON_PRO_SIDEBAR_WIDTH
} from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { Widget } from 'src/types/application/widgetTypes';

export const useGenericSideBarStyles = makeStyles({
  container: {
    ...styles.pageCenterDiv,
    color: brandclub_colors.darkBlue,
    paddingTop: 31,
    textAlign: 'left'
  },
  inner: {
    display: 'grid',
    gridTemplateColumns: '185px 1fr',
    gap: 50
  },
  innerWithoutSidebar: {
    display: 'grid',
    gridTemplateColumns: '1fr'
  },
  leftNavTitle: {
    fontSize: '1.6rem',
    fontWeight: 'bold'
  },
  tabs: {
    marginTop: 32,
    '& div.MuiTabs-flexContainer': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > span': {
        height: '36px'
      }
    }
  },
  tab: {
    alignItems: 'flex-start',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    padding: '8px 10px 8px 19px',
    textAlign: 'left',
    textTransform: 'none',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',

    '&.MuiButtonBase-root': {
      color: '#052849',
      borderLeft: `3px solid ${brandclub_colors.darkBlue1}`,
      width: BEACON_PRO_SIDEBAR_WIDTH,
      minHeight: BEACON_PRO_SIDEBAR_ITEM_HEIGHT,
      '&:hover': {
        backgroundColor: brandclub_colors.blueWhite,
        transition: 'background-color 0.25s ease-in',
        textDecoration: 'none'
      }
    },
    '&.MuiButtonBase-root.Mui-selected': {
      textRendering: 'geometricPrecision',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      backgroundColor: '#a5cfff30',
      borderLeft: `3px solid #052849`,
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      fontWeight: 600,
      '&:hover': {
        backgroundColor: '#a5cfff33'
      }
    }
  }
});

const SidebarContainer = styled('div')({
  width: BEACON_PRO_SIDEBAR_WIDTH
});

const GenericSidebar: FC<{
  widget: Widget;
  disableAppLinkTabs?: boolean;
}> = ({ widget, disableAppLinkTabs = false }) => {
  const classes = useGenericSideBarStyles();

  const { getCurrentRoutes, currentRoute } = useBeaconRoutes();

  const empty: boolean = _get(widget, ['data', 'empty'], false);

  if (empty) {
    return <SidebarContainer />;
  }

  return (
    <SidebarContainer>
      <EntityCardContainer />
      {!disableAppLinkTabs && (
        <AppLinkTabs
          tabIndex={currentRoute.index}
          setTabIndex={() => {}}
          tabRoutes={getCurrentRoutes()}
          tabsProps={{
            classes: {
              root: classes.tabs
            }
          }}
          containerStyle={{
            marginLeft: 0,
            marginRight: 0
          }}
          tabProps={{
            classes: {
              root: classes.tab
            }
          }}
        />
      )}
    </SidebarContainer>
  );
};

/**
 * wraps Component with Sidebar
 * @param Component
 */
export const withSidebar = (Component: any, Sidebar?: any): FC => {
  const WrappedComponent = () => {
    const classes = useGenericSideBarStyles();
    return (
      <div className={classes.container}>
        <div className={Sidebar ? classes.inner : classes.innerWithoutSidebar}>
          {Sidebar && <Sidebar />}
          <div>
            <Component />
          </div>
        </div>
      </div>
    );
  };
  return WrappedComponent;
};

export default GenericSidebar;
