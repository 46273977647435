import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';

import { mkChipButtonCellFormatter } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import i18n from 'src/i18n_en';
import EntityGrid from 'src/components/EntityGrid';
import VIEWS from './ViewDefaultConfig';
import {
  buildTrendWidgetConfig,
  buildTopEntitiesWidgetConfig,
  buildWidgetGroup
} from 'src/components/Layout/LayoutUtil';
import { buildTabsWidgetConfig } from 'src/components/common/Tabs/TabsWidget';
import { propEq, filterNils } from 'src/utils/fp';
import { onPrimeMode } from 'src/store/modules/user/selectors';
import { AppName } from 'sl-api-connector';
import { shouldShowNewBeacon } from 'src/utils/app';
import BeaconProSearchContainer from '../Search/BeaconProSearchContainer';
import KeywordSearchOrganicTrafficSpline from '../BeaconRedesignComponents/ExperimentalLayout/Segments/KeywordsSearch/KeywordSearchOrganicTrafficSpline';
import KeywordAdClicksSpline from '../BeaconRedesignComponents/ExperimentalLayout/Segments/KeywordsSearch/KeywordAdClicksSpline';
import { BEACON_CHART_SPACING } from './Beacon/BeaconProLayoutConsts';

const { products_search_noneFound } = i18n;

const NUMBER_OF_TOP_ENTITES = 10;

const buildSearchtermlistWidgets = ({ app, indexName, entity, dataConfig, weekIdField, metricType }) => {
  const gridAggregationFields = ['organicClicks', 'adClicks', 'totalClicks', 'adSpend'].map((fieldName) =>
    INDEX_FIELDS.getField(app.name, indexName, fieldName, 'product', 'stacklineSku')
  );

  const excludeColumnDef = {
    name: 'action',
    displayName: '',
    entity: {},
    width: 40,
    dataType: null,
    metricType: null,
    cellRendererFramework: mkChipButtonCellFormatter('addToExcludeSearchTerm', 'Exclude')
  };

  const columnDefs = [...gridAggregationFields, excludeColumnDef];

  const organicTrafficTrendChart = buildTopEntitiesWidgetConfig(
    app,
    indexName,
    entity,
    [
      { groupByFieldName: 'brandId', aggregationFieldNames: ['organicClicks'] },
      { groupByFieldName: 'categoryId', aggregationFieldNames: ['organicClicks'] },
      { groupByFieldName: 'subCategoryId', aggregationFieldNames: ['organicClicks'] }
    ],
    NUMBER_OF_TOP_ENTITES,
    weekIdField,
    { view: { chartPropsOverride: { enableSwitchingGroupBy: true } } }
  );

  const adClicksTrendChart = buildTopEntitiesWidgetConfig(
    app,
    app.name === 'beacon' ? 'advertising' : indexName,
    entity,
    [
      {
        groupByFieldName: 'brandId',
        aggregationFieldNames: [app.name === 'beacon' ? 'clicks' : 'adClicks']
      },
      {
        groupByFieldName: 'categoryId',
        aggregationFieldNames: [app.name === 'beacon' ? 'clicks' : 'adClicks']
      },
      {
        groupByFieldName: 'subCategoryId',
        aggregationFieldNames: [app.name === 'beacon' ? 'clicks' : 'adClicks']
      }
    ],
    NUMBER_OF_TOP_ENTITES,
    weekIdField,
    { view: { chartPropsOverride: { enableSwitchingGroupBy: true } } }
  );

  const dataVizWidgets = shouldShowNewBeacon()
    ? [
        {
          CustomComponent: BeaconProSearchContainer,
          view: {}
        },
        {
          CustomComponent: KeywordSearchOrganicTrafficSpline,
          view: {
            container: {
              style: {
                marginBottom: BEACON_CHART_SPACING
              }
            }
          }
        },
        organicTrafficTrendChart,
        {
          CustomComponent: KeywordAdClicksSpline,
          view: {
            container: {
              style: {
                marginTop: BEACON_CHART_SPACING,
                marginBottom: BEACON_CHART_SPACING
              }
            }
          }
        },
        adClicksTrendChart
      ]
    : [
        {
          CustomComponent: EntityGrid,
          view: _merge({}, VIEWS.entityGrid, {
            container: {
              style: {
                maxHeight: 615,
                overflowY: 'scroll',
                overflowX: 'hidden',
                marginTop: 0,
                marginBottom: 100
              }
            },
            gridOptions: {
              hideGridHeader: true,
              enableGroupBy: true
            }
          }),
          data: {
            groupByFields: [
              {
                ...INDEX_FIELDS.getField(app.name, indexName, 'searchTerm', 'product'),
                displayName: 'Keyword'
              },
              INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')
            ],
            configByGroupByFieldName: {
              searchTerm: {
                indexName,
                entity: ENTITIES[app.name].searchTerm,
                mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
                aggregationFields: gridAggregationFields,
                tableView: {
                  metricFields: columnDefs
                }
              },
              stacklineSku: {
                indexName,
                entity: ENTITIES[app.name].product,
                mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
                aggregationFields: gridAggregationFields,
                tableView: {
                  metricFields: gridAggregationFields
                }
              }
            }
          }
        },
        buildTrendWidgetConfig(app, indexName, entity, 'weekId', ['organicClicks'], dataConfig.weekIdField),
        organicTrafficTrendChart,
        // If we're on beacon, we want to display beacon advertising data rather than atlas traffic data.  Keep
        // in mind the fact that the `beacon-traffic` index is actually mapped to `atlas-traffic` internally,
        // meaning that it displays Atlas data.  Beacon data is preferred in this situation since it comes
        // straight from Amazon.
        app.name === 'beacon'
          ? buildTrendWidgetConfig(app, 'advertising', entity, 'weekId', ['clicks'], weekIdField)
          : buildTrendWidgetConfig(app, indexName, entity, 'weekId', ['adClicks'], dataConfig.weekIdField),
        adClicksTrendChart
      ];

  return [
    buildWidgetGroup([
      buildWidgetGroup(dataVizWidgets, {
        view: {
          noInnerContainer: true,
          container: {}
        }
      })
    ])
  ];
};

const buildSegmentSearchWidgets = ({ app, indexName, metricType, user }) => {
  const isSuperUser = user.config.isStacklineSuperUser;
  const params = new URLSearchParams(window.location.search);
  const showPriceData = params.get('showPriceData') === '1';
  if (isSuperUser && !showPriceData && app.name === AppName.Atlas) {
    return [
      {
        name: 'entityGrid',
        CustomComponent: EntityGrid,
        view: _merge({}, VIEWS.entityGrid, {
          container: {
            style: {
              marginTop: 0
            }
          },
          gridOptions: {
            enableExport: true,
            hideGridHeader: true,
            noDataAvailableMessage: products_search_noneFound,
            rowDeleteEventName: 'addToExcludeKeyword',
            showCardBackDeleteButton: !!window.localStorage.getItem('showCardBackDeleteButtonOnSearchPage')
          }
        }),
        data: {
          groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
          configByGroupByFieldName: {
            stacklineSku: {
              indexName,
              entity: ENTITIES[app.name].product,
              mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
              aggregationFields: []
            }
          },
          processDocuments: false,
          mergeIfStatePropertyValueExists: true
        }
      }
    ];
  }

  if (shouldShowNewBeacon()) {
    return [
      {
        CustomComponent: BeaconProSearchContainer,
        view: {}
      }
    ];
  }

  return [
    {
      name: 'entityGrid',
      CustomComponent: EntityGrid,
      view: _merge({}, VIEWS.entityGrid, {
        container: {
          style: {
            marginTop: 0
          }
        },
        gridOptions: {
          enableExport: true,
          hideGridHeader: true,
          noDataAvailableMessage: products_search_noneFound,
          rowDeleteEventName: 'addToExcludeKeyword',
          showCardBackDeleteButton: !!window.localStorage.getItem('showCardBackDeleteButtonOnSearchPage')
        }
      }),
      data: {
        groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
        configByGroupByFieldName: {
          stacklineSku: {
            indexName,
            entity: ENTITIES[app.name].product,
            mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
            aggregationFields: [
              INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
              INDEX_FIELDS.getField(app.name, indexName, 'retailPrice', 'product', 'stacklineSku')
            ]
          }
        }
      }
    }
  ];
};

const getLayoutForEntity = ({ app, tab, metricType, entity, user }) => {
  const indexName = entity.type === 'searchtermlist' ? 'traffic' : 'sales';
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
  const retailerIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'));
  const dataConfig = {
    indexName: tab,
    retailerEntity: _cloneDeep(ENTITIES[app.name].retailer),
    categoryEntity: _cloneDeep(ENTITIES[app.name].category),
    relatedEntity: _cloneDeep(ENTITIES[app.name].brand),
    weekIdField,
    retailerIdField
  };

  const isOnBeaconPremium = onPrimeMode();

  const tabOpts = filterNils([
    { displayName: 'Products', value: 'segment' },
    isOnBeaconPremium ? { displayName: 'Keywords', value: 'searchtermlist' } : null
  ]);

  let activeTab = tabOpts.findIndex(propEq('value', entity.type));
  activeTab = activeTab === -1 ? 0 : activeTab;

  const searchTabsWidget = buildTabsWidgetConfig({
    eventName: 'handleEntityTypeSwitching',
    tabs: tabOpts,
    style: { marginBottom: 25, borderBottom: '1px solid #dedede', height: 36 },
    tabStyle: { fontSize: 18 },
    value: activeTab
  });

  const widgets = [
    ...(shouldShowNewBeacon() ? [] : [searchTabsWidget]),
    ...(entity.type === 'searchtermlist'
      ? buildSearchtermlistWidgets({ app, indexName, entity, dataConfig, weekIdField, metricType })
      : buildSegmentSearchWidgets({ app, indexName, metricType, user }))
  ];

  return {
    CustomPageContainer: null,
    enableComparison: true,
    widgets,
    dataConfig
  };
};

export default { getLayoutForEntity };
