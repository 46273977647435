import React, { useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import AddIcon from '@mui/icons-material/Add';
import numeral from 'numeral';
import { AD_PLATFORM_SETTING_TYPE, AD_PLATFORM_SETTING_TYPE_BY_CLIENT } from 'sl-ad-campaign-manager-data-model';
import { createSelector } from 'reselect';
import { HeaderSearchIcon } from 'src/components/SvgIcons';
import Chip from '@mui/material/Chip';

import { store } from 'src/main';
import { adPlatformSettingsByClientOperations } from 'src/store/modules/adManager/adPlatformSettingsByClient';
import { useOnce } from 'src/utils/Hooks';
import ReduxStore from 'src/types/store/reduxStore';
import {
  getPlatformType,
  getAdPlatformSettingsOfType,
  getAdPlatformSettingsByClientOfType,
  getCampaignEntity
} from 'src/store/modules/adManager/adCampaignBuilder/selectors';
import { sortAdPortfoliosByBudget } from 'src/store/modules/adManager/adPortfolios/selectors';

import { withPush } from 'src/utils/hoc';
import { PushFn } from 'src/types/application/types';

import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { SectionWrapper, OperationButtons, buildSubtabLink } from '../Widgets/AdCampaignBuilderCommonWidgets';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { AdCampaignBuilderRadio } from 'src/components/AdCampaignBuilder/Widgets/Checkbox';
import { CreatePortfolioAdBuilder } from 'src/components/AdCampaignBuilder/Widgets/CreatePortfolio';
import colors from 'src/utils/colors';
import './SetupStrategy.scss';

interface PortfolioData {
  selected: boolean;
  name: string;
  strategy: {
    name: string;
    settingId: string;
  };
  creator: string;
  id: number | string;
  handleClick: Function;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: colors.darkBlue,
    background: colors.lightestGrey,
    padding: theme.spacing(0.5),
    fontSize: 14
  }
}));

const portfolioSelector = createSelector(
  [
    (state: ReduxStore) =>
      sortAdPortfoliosByBudget(
        getAdPlatformSettingsByClientOfType(
          AD_PLATFORM_SETTING_TYPE_BY_CLIENT.PORTFOLIO_ID,
          getPlatformType(state)
        )(state)
      ),
    getCampaignEntity
  ],
  (portfolioSettings, campaignEntity) => {
    if (!campaignEntity) {
      return [];
    }
    return portfolioSettings.filter((item) => item.extendedAttributes.entityId === campaignEntity.id);
  }
);

const RadioColumnRenderer = connect(
  ({ adCampaignBuilder }: ReduxStore, { data }: { data: PortfolioData }) => {
    return {
      checked: adCampaignBuilder.setup.portfolioId === data.id
    };
  },
  (dispatch, { data }) => {
    return {
      toggleChecked: () =>
        dispatch(
          adCampaignBuilderActions.setSetup({ portfolioId: data.id as string, strategyId: data.strategy.settingId })
        )
    };
  }
)(({ checked, toggleChecked }) => <AdCampaignBuilderRadio checked={checked} onChange={toggleChecked} />);

const mapStateToProps = (state: ReduxStore) => {
  const { adCampaignBuilder } = state;
  const platformType = getPlatformType(state);

  return {
    adCampaignBuilder,
    adStrategies: getAdPlatformSettingsOfType(AD_PLATFORM_SETTING_TYPE.CAMPAIGN_STRATEGY, platformType)(state),
    campaignEntity: getCampaignEntity(state),
    portfolioList: portfolioSelector(state)
  };
};

const SetupStrategy: React.FC<{ push: PushFn } & ReturnType<typeof mapStateToProps>> = ({
  adCampaignBuilder,
  campaignEntity,
  adStrategies,
  portfolioList,
  push
}) => {
  const { portfolioId } = adCampaignBuilder.setup;
  const canContinue = !_isNil(portfolioId);
  const [loading, setLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState('');
  const classes = useStyles();

  const handleKeyPress = (evt: any) => {
    if (evt.key === 'Enter') {
      setSearchKeyword(evt.target.value);
    }
  };

  const clearKeyword = () => {
    setSearchKeyword('');
  };
  const fetchAdPlatformSettingsByClient = async () => {
    setLoading(true);
    await store.dispatch(adPlatformSettingsByClientOperations.fetchAdPlatformSettingsByClient());
    setLoading(false);
  };
  useOnce(() => {
    fetchAdPlatformSettingsByClient();
  });

  const [createPortfolioDialogOpen, setCreatePortfolioDialogOpen] = useState(false);

  if (_isEmpty(adStrategies)) {
    return null;
  }

  // entity is only unset if we're re-entering halfway through the flow; bail.
  if (!campaignEntity) {
    push(buildSubtabLink('platformSelect'));
    return null;
  }

  const NoPortfolioAction = () => {
    return (
      <div className="ag_grid_no_result">
        <div className="no_results_header">No Portfolios</div>
        <div className="no_results_subheader">It appears there are no portfolios for the selected entity</div>
        <div role="button" onClick={() => setCreatePortfolioDialogOpen(true)} className="open_filter">
          Create Portfolio
        </div>
      </div>
    );
  };

  const NoPortfolioResultsAction = () => {
    return (
      <div className="ag_grid_no_result">
        <div className="no_results_header">No results</div>
        <div className="no_results_subheader">No results found for your search term</div>
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: ' ',
      field: 'selected',
      width: undefined,
      minWidth: 70,
      maxWidth: 70,
      headerClass: ['alignleft'],
      cellRendererFramework: RadioColumnRenderer
    },
    {
      headerName: 'Portfolio',
      field: 'name',
      minWidth: 600,
      maxWidth: 800,
      width: undefined,
      enableRtl: false,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left'
    },
    {
      headerName: 'Strategy',
      field: 'strategy',
      width: 205,
      valueFormatter: ({ data }: { data: PortfolioData }) => _get(data, ['strategy', 'name'], 'Manual (No Automation)'),
      cellStyle: {
        'text-align': 'left',
        'padding-right': '20px',
        'flex-direction': 'row',
        'justify-content': 'flex-start'
      }
    },
    {
      headerName: 'Budget',
      field: 'budget',
      width: 175,
      cellStyle: {
        'text-align': 'left',
        'padding-right': '20px',
        'flex-direction': 'row',
        'justify-content': 'flex-start'
      }
    }
  ];

  const handlePortfolioSelect = (value: string) => {
    store.dispatch(adCampaignBuilderActions.setSetup({ portfolioId: value }));
  };

  const dataSet = portfolioList.map((portfolio) => {
    const budgetAmount = _get(portfolio, ['extendedAttributes', 'liveBudgetSetting', 'amount'], null);

    const budgetDisplay = budgetAmount !== null ? numeral(budgetAmount).format('$1,000') : '--';
    return {
      selected: portfolio.id === portfolioId,
      name: portfolio.name,
      strategy: adStrategies.find(
        (strategy) =>
          strategy.settingId === _get(portfolio, ['extendedAttributes', 'automationAttributes', 'strategyId'], 'Manual')
      ),
      budget: budgetDisplay,
      id: portfolio.id,
      handleClick() {
        handlePortfolioSelect(portfolio.id);
      }
    };
  });

  const filteredDataSet = dataSet.filter(
    ({ name, id }: { name: string; id: string }) =>
      name &&
      (name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        id.toLowerCase().includes(searchKeyword.toLowerCase()))
  );

  const onGridReady = (params: any) => {
    params.api.sizeColumnsToFit();
  };

  const hasRows = dataSet.length > 0;
  const hasMatches = filteredDataSet.length > 0 && hasRows;

  return (
    <div className="ad-manager-container">
      <SectionWrapper
        header="Portfolio"
        subheader="Choose the portfolio and optimization strategy for this campaign."
        layer={0}
      >
        {/* Portfolio Table */}
        <div className="portfolio-control-button-group">
          <div className="searchbar_container" style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <button className="sl-header__search-button">
              <HeaderSearchIcon className="sl-header__search-icon" />
            </button>
            {searchKeyword ? (
              <Chip label={searchKeyword} onDelete={clearKeyword} className={classes.root} />
            ) : (
              <input onKeyPress={(evt) => handleKeyPress(evt)} placeholder="Search" />
            )}
          </div>
          <Tooltip title="Create Portfolio">
            <IconButton aria-label="create_portfolio" onClick={() => setCreatePortfolioDialogOpen(true)} size="large">
              <AddIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Refresh">
            <IconButton aria-label="refresh" onClick={fetchAdPlatformSettingsByClient} disabled={loading} size="large">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
        {loading ? (
          <GridLoading />
        ) : (
          <div>
            <CustomAgMaterial
              onGridReady={onGridReady}
              onCellValueChanged={onGridReady}
              onModelUpdated={onGridReady}
              onRowValueChanged={onGridReady}
              onRowDataChanged={onGridReady}
              buildRows={() => filteredDataSet}
              columnDefs={columnDefs as any}
              deltaRowDataMode
              getRowNodeId={(datum: any) => escape(datum.id)}
              domLayout="autoHeight"
              suppressNoRowsOverlay
              containerStyle={hasMatches ? { height: 600, width: '100%' } : { width: '100%' }}
            />
            {!hasMatches && hasRows && <NoPortfolioResultsAction />}
            {!hasMatches && !hasRows && <NoPortfolioAction />}
          </div>
        )}
      </SectionWrapper>
      <OperationButtons subtab="setupStrategy" canContinue={canContinue} />
      <CreatePortfolioAdBuilder open={createPortfolioDialogOpen} onClose={() => setCreatePortfolioDialogOpen(false)} />
    </div>
  );
};

const EnhancedSetupStrategy = connect(mapStateToProps)(withPush(SetupStrategy));

export default EnhancedSetupStrategy;
