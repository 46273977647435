import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment-timezone';
import { Option } from 'funfix-core';

import Creators from './actions';
import { parseAdPlatformSettingsByClient } from './selectors';
import ReduxStore from 'src/types/store/reduxStore';

import {
  IAdPortfolioAdPlatformSettingsByClient,
  IAdEntityAdPlatformSettingsByClient,
  AD_PLATFORM_SETTING_TYPE_BY_CLIENT,
  AD_BUDGET_TYPE
} from 'sl-ad-campaign-manager-data-model';
import { PartialNullable, OverrideWith } from 'src/types/utils';
import { panic } from 'src/utils/mixpanel';

export const { clearAdPlatformSettingsByClient, receiveAdPlatformSettingsByClient } = Creators;

const CREATE_PORTFOLIO_API_ENDPOINT = '/apiAdManager/adPlatformSettingsByClient/adPortfolio';

export const fetchAdPlatformSettingsByClient = () => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
  const response = await axios.get(`/apiAdManager/adPlatformSettingsByClient`);
  return dispatch(receiveAdPlatformSettingsByClient(parseAdPlatformSettingsByClient(response.data)));
};

export const createPortfolio =
  (formState: any) => async (dispatch: ThunkDispatch<ReduxStore, void, any>, getState: () => ReduxStore) => {
    const { user, retailer } = getState();
    const {
      platform: { platformType }
    } = formState; // TODO: Make dynamic once we have a config option for that somewhere
    const now = new Date();
    const nowISOString = now.toISOString();
    const nowUnixTimestampMS = now.getTime();
    const portfolioName = formState.name.trim();

    const entity: IAdEntityAdPlatformSettingsByClient = getState().adPlatformSettingsByClient.find(
      (setting) => setting.settingType === 'entityId' && setting.settingId === formState.entity.id
    )! as IAdEntityAdPlatformSettingsByClient;
    if (!entity) {
      return panic(`No ad platform setting by client found for entity id in form state: "${formState.entity}"`);
    }

    // TODO: This type needs a lot of love.  It currently is unreadable and doesn't even properly enforce that all
    // required keys exist on the object.
    const portfolioData: OverrideWith<
      PartialNullable<IAdPortfolioAdPlatformSettingsByClient, 'id' | 'settingId'>,
      {
        extendedAttributes: PartialNullable<
          OverrideWith<
            IAdPortfolioAdPlatformSettingsByClient['extendedAttributes'],
            {
              amsApiModel: OverrideWith<
                PartialNullable<
                  IAdPortfolioAdPlatformSettingsByClient['extendedAttributes']['amsApiModel'],
                  'portfolioId'
                >,
                {
                  budget:
                    | OverrideWith<
                        NonNullable<
                          IAdPortfolioAdPlatformSettingsByClient['extendedAttributes']['amsApiModel']['budget']
                        >,
                        {
                          endDate:
                            | NonNullable<
                                IAdPortfolioAdPlatformSettingsByClient['extendedAttributes']['amsApiModel']['budget']
                              >['endDate']
                            | null;
                        }
                      >
                    | undefined;
                }
              >;
            }
          >,
          'portfolioId' | 'portfolioIdApi'
        >;
      }
    > = {
      beaconClientId: user.config.vendor.BeaconClientId,
      createdBy: user.session.email,
      createdTime: nowISOString,
      extendedAttributes: {
        amsApiModel: {
          creationDate: nowUnixTimestampMS,
          inBudget: true,
          lastUpdatedDate: nowUnixTimestampMS,
          name: portfolioName,
          portfolioId: undefined,
          servingStatus: 'PORTFOLIO_STATUS_ENABLED',
          state: 'enabled'
        },
        automationAttributes: {
          strategyId: formState.strategy.id
        },
        beaconClientLoginId: entity.extendedAttributes.beaconClientLoginId,
        entityId: entity.extendedAttributes.entityId,
        entityIdApi: entity.extendedAttributes.entityIdApi,
        entityIdUi: entity.extendedAttributes.entityIdUi,
        name: portfolioName,
        nextMonthBudgetSetting: undefined,
        portfolioId: undefined,
        portfolioIdApi: undefined,
        retailerId: parseInt(retailer.id, 10)
      },
      id: undefined,
      lastUpdatedBy: user.session.email,
      lastUpdatedTime: nowISOString,
      name: portfolioName,
      platformType,
      settingId: undefined,
      settingType: AD_PLATFORM_SETTING_TYPE_BY_CLIENT.PORTFOLIO_ID
    };

    if (formState.budgetType.name === AD_BUDGET_TYPE.MONTHLY_RECURRING) {
      portfolioData.extendedAttributes.currentMonthBudgetSetting = {
        amount: formState.budgetAmount,
        budgetType: formState.budgetType.name,
        startDate: Option.of(formState.startDate)
          .map(moment)
          .map((m) => m.format('YYYYMMDD'))
          .getOrElseL(() => {
            if (platformType.startsWith('AmazonAMS')) {
              return moment().format('YYYYMMDD');
            }
            return null;
          }),
        endDate: Option.of(formState.endDate)
          .map(moment)
          .map((m) => m.format('YYYYMMDD'))
          .orNull()
      };
    } else if (formState.budgetType.name === AD_BUDGET_TYPE.DATE_RANGE) {
      portfolioData.extendedAttributes.currentMonthBudgetSetting = {
        amount: formState.budgetAmount,
        budgetType: formState.budgetType.name,
        endDate: Option.of(formState.endDate)
          .map(moment)
          .map((m) => m.format('YYYYMMDD'))
          .orNull(),
        startDate: Option.of(formState.startDate)
          .map(moment)
          .map((m) => m.format('YYYYMMDD'))
          .orNull()
      };
    } else {
      portfolioData.extendedAttributes.currentMonthBudgetSetting = {};
    }

    const res = await axios.post(CREATE_PORTFOLIO_API_ENDPOINT, portfolioData);
    if (res.status !== 200) {
      return panic(`Error response when trying to create portfolio: ${JSON.stringify(res.data)}`);
    }

    // Re-fetch list of ad platform settings by client now that portfolio has been created
    return dispatch(fetchAdPlatformSettingsByClient());
  };
