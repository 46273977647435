/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { withBus } from 'react-bus';

import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { store } from 'src/main';
import { useOnce } from 'src/utils/Hooks';
import { fetchMainEntity } from 'src/store/modules/entityService/operations';
import ReduxStore from 'src/types/store/reduxStore';
import { PushFn } from 'src/types/application/types';
import { WidgetProps } from 'src/types/application/widgetTypes';
import { EventBus } from 'src/types/utils';
import { withPush } from 'src/utils/hoc';
import { RouteChildrenProps } from 'react-router';
import { getLayoutForEntity } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';
import Loading from 'src/components/common/Loading';

const mapStateToProps = ({ app, retailer, entityService }: ReduxStore) => ({ app, retailer, entityService });

const AdManagerSearchPage: React.FC<
  RouteChildrenProps & WidgetProps & ReturnType<typeof mapStateToProps> & { eventBus: EventBus; push: PushFn }
> = ({ app, retailer, location, eventBus, push, entityService }) => {
  const entity = entityService.mainEntity;

  useOnce(() => {
    if (!entity || entity.type !== 'client') {
      store.dispatch(fetchMainEntity('client', -1, retailer.id, push));
    }
  });
  if (!entity || entity.type !== 'client') {
    return <Loading style={{ position: 'fixed' }} className="spinner" size={150} thickness={2} />;
  }
  const pageLayout = getLayoutForEntity({ app, entity, eventBus });
  return <GenericPageContainer pageLayout={pageLayout} location={location} />;
};

const EnhancedAdManagerSearchPage = connect(mapStateToProps)(withBus('eventBus')(withPush(AdManagerSearchPage)));

export default EnhancedAdManagerSearchPage;
