import { StacklineTheme, hexToRgba, useStacklineTheme } from '@stackline/ui';
import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import styled from '@mui/material/styles/styled';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

interface StyledCounterProps {
  stacklineTheme: StacklineTheme;
  selected?: boolean;
}

const StyledCounter = styled('div')<StyledCounterProps>(({ stacklineTheme, selected = false }) => ({
  backgroundColor: selected ? '#fff' : hexToRgba(stacklineTheme.colors.secondaryPorcelain, 0.8),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  height: '28px',
  minWidth: '28px',
  maxWidth: '78px',
  padding: '5px 10px 4px 10px'
}));

const ActiveAdjustments = ({
  count,
  selected,
  hidden = false
}: {
  count: string | number;
  selected: boolean;
  hidden?: boolean;
}) => {
  const theme = useStacklineTheme();

  return (
    <Flex height="30px" gap="sm" alignItems="center">
      {hidden ? null : (
        <>
          {' '}
          <StyledCounter selected={selected} stacklineTheme={theme}>
            <Text variant="subtitle2">{count}</Text>
          </StyledCounter>
          <Text
            sx={{
              letterSpacing: 'normal'
            }}
            variant="body2"
          >
            Active {count === 1 ? 'adjustment' : 'adjustments'}
          </Text>
        </>
      )}
    </Flex>
  );
};

export default ActiveAdjustments;
