/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import _assign from 'lodash/assign';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _startCase from 'lodash/startCase';
import _cloneDeep from 'lodash/cloneDeep';
import { ValueOf } from 'sl-api-connector/types';
import { IAdCampaignProduct, IAdTarget } from 'sl-ad-campaign-manager-data-model';

import { METRICTYPE } from 'src/utils/entityDefinitions';
import ReduxStore from 'src/types/store/reduxStore';
import { propEq } from 'src/utils/fp';
import { WaterfallNonSortableHeaderComponentFramework } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import {
  getCampaignDisplayStatus,
  getProductDisplayStatus,
  getKeywordDisplayStatus
} from 'src/components/AdManager/AmsUtils';
import { isDrive } from 'src/utils/app';

require('numeral/locales');

export interface ConvertMetricToDisplayValueOptions {
  /**
   * For numbers that show decimals, how many decimal
   * places to round to
   */
  decimalPlaces?: number;
  /**
   * Mininum number for volume metrics before a decimal point
   * is displayed. For example, a volumeDecimalMin value of
   * 1 million will display 900K (without decimals) and 1.00M
   * (with decimals)
   */
  volumeDecimalMin?: number;
  /**
   * False by default. When true, show a negative sign,
   * otherwise the absolute value is diplayed.
   */
  showNegative?: boolean;
  /**
   * Custom formatting string
   */
  formatString?: string;
}

export default function convertMetricToDisplayValue(
  retailer: ReduxStore['retailer'],
  value: number,
  metricType: ValueOf<typeof METRICTYPE>,
  currencySymbol: string,
  showFullValue = true,
  { decimalPlaces = 1, volumeDecimalMin, showNegative, formatString }: ConvertMetricToDisplayValueOptions = {}
) {
  if (metricType === METRICTYPE.TEXT) {
    return value;
  }

  if (__DEV__) {
    if (_isNil(currencySymbol)) {
      console.error('Nil `currencySymbol` passed to `convertMetricToDisplayValue`.');
    }
  }

  const locale = retailer.locale || 'en';

  numeral.locale(`${locale}`);

  // We cannot override locales and the library has not been updated in ~4 years
  // so this is a DE specific fix (by default, it uses a space as a separator)
  if (locale === 'de') {
    numeral.localeData('de').delimiters.thousands = '.';
  }

  // This is being used just to capitalize labels
  if (!isDrive) {
    _assign(numeral.localeData(`${locale}`), {
      abbreviations: {
        thousand: 'K',
        million: 'M',
        billion: 'B',
        trillion: 'T'
      },
      currency: {
        symbol: `${currencySymbol}`
      }
    });
  }
  let valueToDisplay = Math.abs(value);
  // Work around a bug in `numeral`: https://github.com/adamwdraper/Numeral-js/issues/596
  if (valueToDisplay <= 1e-5) {
    valueToDisplay = 0;
  }

  if (showNegative) {
    valueToDisplay *= value < 0 ? -1 : 1;
  }

  const decimalZeros = '0'.repeat(decimalPlaces);
  if (showFullValue) {
    switch (metricType) {
      case METRICTYPE.MONEY:
        if (value && locale === 'de') {
          // handling decimal numbers for , decimal separator --- replacing . with ,
          return numeral(value.toString().replace(/\./g, ','))
            .format(formatString || (value < 1000 && value > -1000 ? '$0,0.00' : '$0,000'))
            .replace(/\s/g, '');
        }
        return numeral(value)
          .format(formatString || (value < 1000 && value > -1000 ? '$0,0.00' : '$0,000'))
          .replace(/\s/g, '');
      case METRICTYPE.PERCENT:
        if (value > 10) {
          return numeral(10).format(formatString || `0.${decimalZeros}%`);
        }
        return numeral(value).format(formatString || `0.${decimalZeros}%`);
      case METRICTYPE.VOLUME:
        return numeral(Math.round(value))
          .format(formatString || '0,00')
          .replace(/\s/g, '');
      case METRICTYPE.DECIMAL:
      default:
        return numeral(value).format(formatString || `0,0.${decimalZeros}`);
    }
  }

  switch (metricType) {
    case METRICTYPE.MONEY:
      return numeral(valueToDisplay)
        .format(formatString || (value >= 1000 ? `$ 0.${decimalZeros} a` : `$ 0.00 a`))
        .replace(/\s/g, '')
        .replace('-$', '$-') // Diplays negative like $-7.14 instead of -$7.14
        .toLocaleUpperCase();
    case METRICTYPE.PERCENT:
      if (value > 10) {
        return numeral(value).format(formatString || '1000%');
      }
      return numeral(valueToDisplay).format(formatString || '0%');
    case METRICTYPE.VOLUME:
      if (Math.abs(valueToDisplay) < 1000) {
        return numeral(valueToDisplay)
          .format(formatString || '0,0')
          .replace(/\s/g, '');
      }
      return numeral(valueToDisplay)
        .format(
          formatString ||
            (volumeDecimalMin !== undefined && Math.abs(valueToDisplay) < volumeDecimalMin
              ? '0,0 a'
              : `0.${decimalZeros} a`)
        )
        .replace(/\s/g, '')
        .toLocaleUpperCase();
    case METRICTYPE.INTEGER:
      return numeral(valueToDisplay)
        .format(formatString || '0,0')
        .replace(/\s/g, '');
    case METRICTYPE.DECIMAL:
    default:
      return numeral(valueToDisplay).format(formatString || `0,0.${decimalZeros}`);
  }
}

export const buildCampaignStatusMappings = (adCampaignProducts: IAdCampaignProduct[], adTargets: IAdTarget[]) => {
  const productLookup: { [key: string]: string } = adCampaignProducts
    ? adCampaignProducts.reduce(
        (acc: {}, product: IAdCampaignProduct) =>
          product.extendedAttributes.productMetaData
            ? {
                ...acc,
                [product.extendedAttributes.productMetaData.stacklineSku]: getProductDisplayStatus(
                  product.extendedAttributes.status,
                  product.extendedAttributes.statusReason
                ).displayName
              }
            : { ...acc },
        {} as { [key: string]: string }
      )
    : {};

  // TODO: We probably want to change this to take both keyword and match type since we have both available now
  // since I assume that the underlying data is broken out by both of those
  const keywordStatusLookup: { [key: string]: string } = adTargets
    ? adTargets.reduce(
        (acc, target: IAdTarget) => ({
          ...acc,
          [target.targetingText]: getKeywordDisplayStatus(
            target.extendedAttributes.status,
            target.extendedAttributes.statusReason
          ).displayName
        }),
        {} as { [key: string]: string }
      )
    : {};

  return { productLookup, keywordStatusLookup };
};

const mapStateToProps = ({ adCampaigns }: ReduxStore) => ({ adCampaigns });

const CampaignStatusCell: React.FC<{ data: { entity: { id: string } } } & ReturnType<typeof mapStateToProps>> = ({
  data: {
    entity: { id }
  },
  adCampaigns
}) => {
  // The time complexity of this isn't beautiful but it's a lot simpler than generating mappings and passing them around
  const campaign = adCampaigns.find(propEq('id', id));
  if (!campaign) {
    return null;
  }

  return <>{getCampaignDisplayStatus(campaign.extendedAttributes).displayName}</>;
};

const CampaignTypeCell: React.FC<{ data: { entity: { id: string } } } & ReturnType<typeof mapStateToProps>> = ({
  data: {
    entity: { id }
  },
  adCampaigns
}) => {
  // The time complexity of this isn't beautiful but it's a lot simpler than generating mappings and passing them around
  const campaign = adCampaigns.find(propEq('id', id));
  if (!campaign) {
    return null;
  }

  return <>{_startCase(_get(campaign, 'extendedAttributes.campaignType'))}</>;
};

export const adManagerCampaignStatsMetricFields = [
  {
    displayName: 'Status \t',
    name: 'status',
    cellRendererFramework: connect(mapStateToProps)(React.memo(CampaignStatusCell)),
    headerComponentFramework: WaterfallNonSortableHeaderComponentFramework
  },
  {
    displayName: 'Campaign Type',
    name: 'status',
    cellRendererFramework: connect(({ adCampaigns }: ReduxStore) => ({ adCampaigns }))(React.memo(CampaignTypeCell)),
    headerComponentFramework: WaterfallNonSortableHeaderComponentFramework
  }
];

export const addKeywordToEntityType = (pathname: string, entity: any): any => {
  // checking pathname to see whether we need to add the keyword businessunit and segment to the url
  const checkBusinessunit = pathname.indexOf('businessunit') !== -1;
  const checkSegment = pathname.indexOf('segment') !== -1;
  if (checkBusinessunit || checkSegment) {
    const newEntity = _cloneDeep(entity);
    const { type } = newEntity;
    if (checkBusinessunit) {
      newEntity.type = `${type}-businessunit`;
    }
    if (checkSegment) {
      newEntity.type = `${type}-segment`;
    }
    return newEntity;
  }
  return entity;
};

export function createFilterParams(passingFilterParams: string, filter: any) {
  let filterParams = passingFilterParams;
  if (filter) {
    try {
      const parsedFilterParams = JSON.parse(filter);
      const platformFilterParams = parsedFilterParams.pl;
      if (platformFilterParams) {
        filterParams = `&filter=${JSON.stringify({ pl: platformFilterParams })}`;
      }
    } catch (err) {
      filterParams = '';
    }
  }
  return filterParams;
}
