import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import momentPropTypes from 'react-moment-proptypes';
import { forbidExtraProps } from 'airbnb-prop-types';
import moment from 'moment';
import _omit from 'lodash/omit';
import { DayPickerRangeController } from 'react-dates';
// import { ChevronIcon } from '../../SvgIcons/index';

import { isInclusivelyAfterDay } from 'src/utils/dateformatting';
import ControlButtons from './ControlButtons';

const propTypes = forbidExtraProps({
  // example props for the demo
  autoFocusEndDate: PropTypes.bool,
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,

  keepOpenOnDateSelect: PropTypes.bool,
  minimumNights: PropTypes.number,
  isOutsideRange: PropTypes.func,
  isDayBlocked: PropTypes.func,
  isDayHighlighted: PropTypes.func,

  showInputs: PropTypes.bool,

  // Other
  mainTimePeriod: PropTypes.object,

  // DayPicker props
  enableOutsideDays: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  orientation: PropTypes.oneOf(['horizontal']),
  withPortal: PropTypes.bool,
  initialVisibleMonth: PropTypes.func,
  renderCalendarInfo: PropTypes.func,

  navPrev: PropTypes.node,
  navNext: PropTypes.node,

  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  onDatesChange: PropTypes.func,
  onOutsideClick: PropTypes.func,
  renderCalendarDay: PropTypes.func,
  renderDayContents: PropTypes.func,

  // i18n
  monthFormat: PropTypes.string,

  isRTL: PropTypes.bool
});

const defaultProps = {
  // example props for the demo
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  isDayBlocked: () => false,
  isOutsideRange: (day) => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: false,
  showInputs: false,
  mainTimePeriod: undefined,

  // calendar presentation and interaction related props
  orientation: 'horizontal',
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onDatesChange() {},

  // internationalization
  monthFormat: 'MMMM YYYY'
};

class DatePickerRangeContainer extends React.Component {
  constructor(props) {
    super(props);
    const { mainTimePeriod } = this.props;
    let start = props.initialStartDate;
    let end = props.initialEndDate;
    if (mainTimePeriod.id && mainTimePeriod.id === 'cd') {
      start = moment(mainTimePeriod.startWeekStartDate);
      end = moment(mainTimePeriod.endWeekEndDate);
    }

    this.state = {
      focusedInput: props.autoFocusEndDate ? 'endDate' : 'startDate',
      startDate: start,
      endDate: end
    };
  }

  onDatesChange = ({ startDate, endDate }) => this.setState({ startDate, endDate });

  onFocusChange = (focusedInput) => {
    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? 'startDate' : focusedInput
    });
  };

  saveChange = () => {
    const { startDate, endDate } = this.state;
    const { onDatesChange } = this.props;
    onDatesChange({ startDate, endDate });
  };

  clearChange = () =>
    this.setState({
      startDate: null,
      endDate: null
    });

  render() {
    const { showInputs } = this.props;
    const { focusedInput, startDate, endDate } = this.state;

    const inputProps = _omit(this.props, [
      'autoFocus',
      'autoFocusEndDate',
      'initialStartDate',
      'initialEndDate',
      'mainTimePeriod'
    ]);

    const startDateString = startDate && startDate.format('YYYY-MM-DD');
    const endDateString = endDate && endDate.format('YYYY-MM-DD');

    const MIN_YEAR = 2017;
    const currYear = moment().year();
    const yearOptions = [];
    for (let i = MIN_YEAR; i <= currYear; i++) {
      yearOptions.push(i);
    }

    return (
      <div>
        {showInputs ? (
          <div style={{ marginBottom: 16 }}>
            <input type="text" name="start date" value={startDateString} readOnly />
            <input type="text" name="end date" value={endDateString} readOnly />
          </div>
        ) : null}

        <DayPickerRangeController
          {...inputProps}
          minimumNights={0}
          hideKeyboardShortcutsPanel
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          renderCalendarInfo={() => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 1 }}>
                <ControlButtons
                  startDate={startDate}
                  endDate={endDate}
                  save={this.saveChange}
                  clear={this.clearChange}
                />
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

DatePickerRangeContainer.propTypes = propTypes;
DatePickerRangeContainer.defaultProps = defaultProps;

const mapStateToProps = ({ mainTimePeriod }) => ({ mainTimePeriod });

export default connect(mapStateToProps)(DatePickerRangeContainer);
