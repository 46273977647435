import { MetricFormatterFn, useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { FetchAdvancedSearchDataParameters, fetchAdvancedSearchData } from 'src/utils/Hooks/useGenericAdvancedSearch';
import _get from 'lodash/get';
import axios, { AxiosResponse } from 'axios';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { useAdvancedSearchRequestBuilder } from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import { getAppName } from 'src/utils/app';
import { parseForecastResponseForAdjustmentSummary } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/responseParsers';
import { DATATYPE, METRICTYPE } from 'src/utils/entityDefinitions';
import { calculateWeeksBetweenWeekIds, formatWeekIdsForAdjustmentDisplay } from 'src/utils/dateUtils';
import { SlDropdownMenuOption } from '@stackline/ui';
import { ProductEntity } from 'sl-api-connector';
import { ForecastsAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import { useQueryClient } from 'react-query';
import {
  ADJUSTMENT_TABLE_QUERY,
  FORECAST_SUMMARY_KEYMETRIC_QUERY
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastQueryKeys';
import { METRIC_TO_AS_RESPONSE_KEY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy';
import {
  useForecastStartAndEndWeekIds,
  useLatestForecastModel
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import {
  ContentScoreAmounts,
  ContentScoreFields
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/CreateAdjustmentModal';
import { useEffect, useState } from 'react';
import {
  ADJUSTMENT_INTERVAL_DURATION_MS,
  ADJUSTMENT_POLL_DURATION_MS
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ViewAdjustmentModal/utils';

export enum AdjustmentPlanType {
  OrganicTraffic = 'OrganicTraffic',
  PaidTraffic = 'PaidTraffic',
  OtherTraffic = 'OtherTraffic',
  RetailPrice = 'RetailPrice',
  ContentScore = 'ContentScore',
  InstockRate = 'InstockRate',
  RatingsReviews = 'RatingsReviews',
  BuyBox = 'Buybox'
}

/**
 * Used to format metric values depending on plan types, get display names, and determine copy.
 */
export const planTypeMetaData: Record<
  string,
  {
    id: AdjustmentPlanType;
    displayName: string;
    metricType: METRICTYPE;
    dataType: DATATYPE;
    decimalPlaces: number;
    advancedSearchKey: string;
    positivePrefix?: string; // Prefixed if a positive number
    negativePrefix?: string; // Prefixed if a negative number
  }
> = {
  OrganicTraffic: {
    id: AdjustmentPlanType.OrganicTraffic,
    displayName: 'Organic Traffic',
    metricType: METRICTYPE.VOLUME,
    dataType: DATATYPE.INTEGER,
    positivePrefix: '+',
    negativePrefix: '-',
    decimalPlaces: 1,
    advancedSearchKey: METRIC_TO_AS_RESPONSE_KEY.organicClicks
  },
  PaidTraffic: {
    id: AdjustmentPlanType.PaidTraffic,
    displayName: 'Paid Traffic',
    metricType: METRICTYPE.VOLUME,
    dataType: DATATYPE.INTEGER,
    positivePrefix: '+',
    negativePrefix: '-',
    decimalPlaces: 1,
    advancedSearchKey: METRIC_TO_AS_RESPONSE_KEY.clicks
  },
  OtherTraffic: {
    id: AdjustmentPlanType.OtherTraffic,
    displayName: 'Other Traffic',
    metricType: METRICTYPE.VOLUME,
    dataType: DATATYPE.INTEGER,
    positivePrefix: '+',
    negativePrefix: '-',
    decimalPlaces: 1,
    advancedSearchKey: METRIC_TO_AS_RESPONSE_KEY.otherClicks
  },
  RetailPrice: {
    id: AdjustmentPlanType.RetailPrice,
    displayName: 'Retail Price',
    metricType: METRICTYPE.MONEY,
    dataType: DATATYPE.DECIMAL,
    decimalPlaces: 2,
    advancedSearchKey: METRIC_TO_AS_RESPONSE_KEY.retailPrice
  },
  ContentScore: {
    id: AdjustmentPlanType.ContentScore,
    displayName: 'Content Score',
    metricType: METRICTYPE.PERCENT,
    dataType: DATATYPE.INTEGER,
    decimalPlaces: 0,
    advancedSearchKey: METRIC_TO_AS_RESPONSE_KEY.contentScore
  },
  InstockRate: {
    id: AdjustmentPlanType.InstockRate,
    displayName: 'In-Stock Rate',
    metricType: METRICTYPE.PERCENT,
    dataType: DATATYPE.DECIMAL,
    decimalPlaces: 0,
    advancedSearchKey: METRIC_TO_AS_RESPONSE_KEY.instockRate
  },
  RatingsReviews: {
    id: AdjustmentPlanType.RatingsReviews,
    displayName: 'Ratings & Reviews',
    metricType: METRICTYPE.DECIMAL,
    dataType: DATATYPE.DECIMAL,
    decimalPlaces: 1,
    advancedSearchKey: 'weightedRating',
    reviewCount: {
      id: 'reviewCount',
      displayName: 'Review Count',
      metricType: METRICTYPE.INTEGER,
      dataType: DATATYPE.INTEGER,
      decimalPlaces: 0,
      advancedSearchKey: 'reviewCount'
    }
  },
  Buybox: {
    id: AdjustmentPlanType.BuyBox,
    displayName: 'Buy Box',
    metricType: METRICTYPE.PERCENT,
    dataType: DATATYPE.INTEGER,
    decimalPlaces: 0,
    advancedSearchKey: 'winPercentage_computed_value'
  }
};

interface AdjustmentConflictPayload {
  beaconClientId: number;
  retailerId: number | string;
  retailerSku: string;
  adjustmentTitle: '';
  adjustmentDescription: '';
  planType: string;
  adjustmentChangeType: 'Absolute';
  adjustmentChangeValue: '';
  userId: string;
  startWeekId: string | number;
  endWeekId: string | number;
  ignoreConflictWithAdjustmentIds: string[];
}
interface AdjustmentConflictResponse {
  beaconClientId: number;
  retailerId: number;
  retailerSku: string;
  planType: string;
  startWeekId: number;
  endWeekId: number;
  message: string;
  isConflicted: boolean;
  conflictedAdjustmentIdsAndWeeks: string[] | null;
}

export const useFetchInvalidDatesForPlanType = () => {
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const retailer = useAppSelector((state) => state.retailer);
  const userId = useAppSelector((state) => state.user.session.userId);

  return async ({
    retailerSku,
    planType,
    startWeekId,
    endWeekId,
    adjustmentId = ''
  }: {
    retailerSku: string;
    planType: SlDropdownMenuOption['id'];
    startWeekId: string | number;
    endWeekId: string | number;
    adjustmentId?: string;
  }) => {
    const validDatesRequestBody: AdjustmentConflictPayload = {
      beaconClientId,
      retailerId: retailer.id,
      retailerSku,
      adjustmentTitle: '',
      adjustmentDescription: '',
      planType,
      adjustmentChangeType: 'Absolute',
      adjustmentChangeValue: '',
      userId,
      startWeekId,
      endWeekId,
      ignoreConflictWithAdjustmentIds: [adjustmentId]
    };

    try {
      const response = await fetchAdvancedSearchData({
        cancelTokenConfig: undefined,
        requestEndpoint: 'api/beaconforecast/CheckForConflictingAdjustments',
        requestBody: validDatesRequestBody
      });
      if (response) {
        return response;
      } else {
        throw Error('Failed validation step');
      }
    } catch (error) {
      console.error(error);
      return { data: { isConflicted: true } };
    }
  };
};

export const parseInvalidDateResponse = (response: AxiosResponse) => {
  let idWithDateRangeMap: Map<string, string[]> | null = null;
  if (response) {
    const conflictResponse = _get(response, ['data']) as AdjustmentConflictResponse;

    if (conflictResponse) {
      const conflictedIdsAndDates = _get(conflictResponse, ['conflictedAdjustmentIdsAndWeeks']);

      if (conflictedIdsAndDates) {
        idWithDateRangeMap = conflictedIdsAndDates.reduce((acc: Map<string, string[]>, current: string) => {
          const [adjustmentId, conflictedDate] = current.split('->');
          if (acc.has(adjustmentId)) {
            const conflictingWeekIds = acc.get(adjustmentId);
            conflictingWeekIds.push(conflictedDate);
            acc.set(adjustmentId, conflictingWeekIds);
          } else {
            acc.set(adjustmentId, [conflictedDate]);
          }

          const allConflictingWeekIds = acc.get('allConflictingWeekIds');
          allConflictingWeekIds.push(conflictedDate);
          acc.set('allConflictingWeekIds', allConflictingWeekIds);
          return acc;
        }, new Map<string, string[]>([['allConflictingWeekIds', []]]));

        const sortedConflictingWeekIds = idWithDateRangeMap
          .get('allConflictingWeekIds')
          .sort((a, b) => Number(a) - Number(b));

        return sortedConflictingWeekIds;
      } else {
        return [];
      }
    }
  }
  return [];
};

const modifyPlanChangeAmount = ({
  planType,
  planChangeAmount
}: {
  planType: GenerateConfirmationMetricsParams['planType'];
  planChangeAmount: GenerateConfirmationMetricsParams['planChangeAmount'];
}) => {
  if (
    [AdjustmentPlanType.ContentScore, AdjustmentPlanType.BuyBox, AdjustmentPlanType.InstockRate].includes(
      planType as AdjustmentPlanType
    )
  ) {
    return Math.abs(Number(planChangeAmount));
  } else {
    return Math.abs(Number(planChangeAmount));
  }
};

/**
 * Used for creating an adjustment, saving as a draft, and publishing from a draft.
 */
interface AdjustmentPayload {
  beaconClientId: number;
  retailerId: string;
  retailerSku: string;
  adjustmentTitle: string;
  adjustmentDescription: string;
  planType: string;
  adjustmentChangeType: string;
  adjustmentChangeValue: number;
  userId: string;
  startWeekId: string | number;
  endWeekId: string | number;
  saveAsDraft: boolean;
  draftAdjustment: {
    adjustmentId: string;
    publishFromDraft: boolean;
  };
  additionalProperties: {
    reviewCount: string | number;
    reviewAvgRating: string | number;
  };
}

/**
 * Used for editing an adjustment
 */
export interface EditAdjustmentPayload {
  beaconClientId: number;
  retailerId: string;
  retailerSku: string;
  adjustmentTitle: string;
  adjustmentDescription: string;
  planType: string;
  adjustmentChangeType: string;
  adjustmentChangeValue: number;
  userId: string;
  startWeekId: string | number;
  endWeekId: string | number;
  adjustmentId: string;
  ignoreConflictWithAdjustmentIds: string[];
}
export interface SubmitAdjustmentParams {
  retailerSku;
  planType: string;
  adjustmentTitle: string;
  description: string;
  planChangeAmount: string;
  planStartDate: string | number;
  planEndDate: string | number;
  saveAsDraft?: boolean;
  adjustmentId?: string;
  publishFromDraft?: boolean;
  isEditOnly?: boolean;
  reviewQuantityAmount: string | number;
  averageStarRatingAmount: string | number;
  contentScoreAmounts: ContentScoreAmounts;
}

/**
 * A custom hook used to generate part of the adjustment submission body and returns a function to submit the adjustment.
 * @returns an async function used to submit new adjustments
 */
export const useSubmitAdjustment = () => {
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const retailer = useAppSelector((state) => state.retailer);
  const userId = useAppSelector((state) => state.user.session.userId);
  const queryClient = useQueryClient(); // Get the queryClient instance
  const [loading, setLoading] = useState(false);
  const [isPolling, setIsPolling] = useState(false);

  const submitAdjustment = async ({
    retailerSku,
    planType,
    adjustmentTitle,
    description,
    planChangeAmount,
    planStartDate,
    planEndDate,
    saveAsDraft = false,
    adjustmentId = '',
    publishFromDraft = false,
    isEditOnly = false,
    reviewQuantityAmount = 0,
    averageStarRatingAmount = 0,
    contentScoreAmounts
  }: SubmitAdjustmentParams) => {
    const amountPayload = modifyPlanChangeAmount({ planChangeAmount, planType });

    /**
     * We use the additional properties to pass additional metadata about the adjustment plan.
     * Only applies to Ratings & Reviews and Content Score
     */
    const additionalProperties = [AdjustmentPlanType.ContentScore].includes(planType)
      ? {
          titleScore: contentScoreAmounts[ContentScoreFields.TitleScore],
          bulletScore: contentScoreAmounts[ContentScoreFields.BulletScore],
          imageScore: contentScoreAmounts[ContentScoreFields.ImageScore],
          videoScore: contentScoreAmounts[ContentScoreFields.VideoScore],
          aplusScore: contentScoreAmounts[ContentScoreFields.A_Score]
        }
      : [AdjustmentPlanType.RatingsReviews].includes(planType)
      ? {
          reviewCount: reviewQuantityAmount || 0,
          reviewAvgRating: averageStarRatingAmount || 0
        }
      : {
          reviewCount: 0,
          reviewAvgRating: 0
        };

    const adjustmentPayload: AdjustmentPayload | EditAdjustmentPayload = isEditOnly
      ? {
          beaconClientId,
          retailerId: retailer.id,
          retailerSku,
          adjustmentTitle,
          adjustmentDescription: description,
          planType,
          adjustmentChangeType: 'Absolute',
          adjustmentChangeValue: amountPayload,
          userId,
          startWeekId: planStartDate,
          endWeekId: planEndDate,
          adjustmentId,
          ignoreConflictWithAdjustmentIds: [adjustmentId],
          draftAdjustment: {
            adjustmentId,
            publishFromDraft
          },
          additionalProperties
        }
      : {
          beaconClientId,
          retailerId: retailer.id,
          retailerSku,
          adjustmentTitle,
          adjustmentDescription: description,
          planType,
          adjustmentChangeType: 'Absolute',
          adjustmentChangeValue: amountPayload,
          userId,
          startWeekId: planStartDate,
          endWeekId: planEndDate,
          saveAsDraft,
          draftAdjustment: {
            adjustmentId,
            publishFromDraft
          },
          additionalProperties
        };

    const adjustmentEndpoint = isEditOnly && !publishFromDraft ? 'UpdateAdjustments' : 'CreateAdjustments';

    try {
      queryClient.invalidateQueries({ queryKey: [ADJUSTMENT_TABLE_QUERY] });
      queryClient.invalidateQueries({ queryKey: [FORECAST_SUMMARY_KEYMETRIC_QUERY] });
      setLoading(true);
      await axios.post(`api/beaconforecast/${adjustmentEndpoint}`, [adjustmentPayload]);
      setLoading(false);
      setIsPolling(true);
    } catch (error) {
      console.error('Failed to post adjustment.');
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isPolling) {
      // Polling duration in milliseconds (10 seconds)
      const pollingDuration = ADJUSTMENT_POLL_DURATION_MS;
      // Polling interval in milliseconds (2 second)
      const pollingInterval = ADJUSTMENT_INTERVAL_DURATION_MS;
      const endTime = Date.now() + pollingDuration;

      const intervalId = setInterval(() => {
        // Invalidate the adjustment query for the adjustments tab and the forecast summary key metrics
        queryClient.invalidateQueries({ queryKey: [ADJUSTMENT_TABLE_QUERY] });
        queryClient.invalidateQueries({ queryKey: [FORECAST_SUMMARY_KEYMETRIC_QUERY] });

        if (Date.now() >= endTime) {
          // Stop polling after the specified duration
          clearInterval(intervalId);
          setIsPolling(false);
        }
      }, pollingInterval);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isPolling, queryClient]);
  return { submitAdjustment, loading };
};

export interface Projection {
  rawValue: number; // 84042.52123132
  rawWeeklyValue: number;
  formattedWeeklyValue: string;
  aggregatedFormattedValue: string;
  formattedValue: string; // 84K
  formattedStartDate: string; // Jun 01, 2023
  formattedEndDate: string;
  planType: string;
  adjustmentTitle: string;
  description: string;
  planChangeAmount: string | number;
  planStartDate: string | number; // 202323
  planEndDate: string | number;
  additionalData?: any;
}

export interface NetImpactProjection {
  rawValue: number;
  formattedValue: string;
  metricChangeString: 'increase' | 'decrease';
  metricChangeComparisonString: 'above' | 'below';
}

interface GenerateConfirmationMetricsParams {
  planType: string;
  adjustmentTitle: string;
  description: string;
  planChangeAmount: string | number;
  planStartDate: string | number;
  planEndDate: string | number;
  product: ProductEntity | ForecastsAdjustmentData['product'];
  adjustmentId?: string;
  viewingExistingAdjustment?: boolean;
  status?: string;
  reviewQuantityAmount: string | number; // Only for ratings and reviews
  averageStarRatingAmount: string | number;
  contentScoreAmounts: any;
}

/**
 * Used to generate net impact for an adjustment plan before it's officially published.
 */
export interface NetImpactPayload {
  baseAdjustmentRequest: {
    beaconClientId: number;
    retailerId: string;
    retailerSku: string;
    userId: string;
    planType: string;
    startWeekId: string | number;
    endWeekId: string | number;
    ignoreConflictWithAdjustmentIds: string[];
  };
  additionalProperties: any;
  adjustmentChangeType: string;
  adjustmentChangeValue: string | number;
  stacklineSku: string;
  categoryId: string;
  subCategoryId: string;
  requestType: string;
  shouldPublish: boolean;
}

export interface NetImpactResponse {
  baseAdjustmentRequest: {
    adjustmentId?: number | null;
    beaconClientId: number;
    retailerId: number;
    retailerSku: string;
    userId: string;
    planType: number;
    startWeekId: number;
    endWeekId: number;
  };
  weightedImpact: number;
  isSuccess: boolean;
  message: string;
  conflictedAdjustmentIdsAndWeeks: string[];
}

// Helper functions for generating confirmation metrics

const fetchAdjustedAndUnadjustedData = async ({
  requestBody,
  appName
}: {
  requestBody: FetchAdvancedSearchDataParameters['requestBody'];
  appName: string;
}) => {
  try {
    const response = await fetchAdvancedSearchData({
      cancelTokenConfig: undefined,
      requestEndpoint: `api/${appName}/AdvancedSearch?_id=originalProjectionForSku`,
      requestBody
    });

    const unadjustedResponse = _get(response, ['data', 0, 'aggregations', 'by_retailerId', 0, 'additionalValues'], []);
    const adjustedResponse = _get(response, ['data', 1, 'aggregations', 'by_retailerId', 0, 'additionalValues'], []);

    return { unadjustedResponse, adjustedResponse };
  } catch (error) {
    console.error('Error fetching adjusted and unadjusted data:', error);
    return { unadjustedResponse: {}, adjustedResponse: {} };
  }
};

/**
 *
 * @returns the planChangeAmount depending on the plan type. For instance, using a traffic type, we multiply the plan change value over the number of weeks selected.
 */
const getNewProjectionValue = ({
  planType,
  planChangeAmount,
  planStartDate,
  planEndDate
}: {
  planType: GenerateConfirmationMetricsParams['planType'];
  planChangeAmount: GenerateConfirmationMetricsParams['planChangeAmount'];
  planStartDate: GenerateConfirmationMetricsParams['planStartDate'];
  planEndDate: GenerateConfirmationMetricsParams['planEndDate'];
}) => {
  // Traffic: We take the absolute value of the change and multiply it across the selected week range.
  if (
    [
      AdjustmentPlanType.OrganicTraffic,
      AdjustmentPlanType.OtherTraffic,
      AdjustmentPlanType.PaidTraffic,
      AdjustmentPlanType.RatingsReviews
    ].includes(planType as AdjustmentPlanType)
  ) {
    const numberOfWeeks = calculateWeeksBetweenWeekIds(planStartDate, planEndDate);
    return numberOfWeeks * Math.abs(Number(planChangeAmount));
  } else {
    // For all other metrics, we can simply use this number as the desired adjustment value across the selected weeks
    return planChangeAmount;
  }
};

interface BuildProjectionParams {
  rawValue: number;
  metricType: METRICTYPE;
  decimalPlaces: number;
  startDate: GenerateConfirmationMetricsParams['planStartDate'];
  endDate: GenerateConfirmationMetricsParams['planEndDate'];
  planType: GenerateConfirmationMetricsParams['planType'];
  adjustmentTitle: GenerateConfirmationMetricsParams['adjustmentTitle'];
  description: GenerateConfirmationMetricsParams['description'];
  planChangeAmount: GenerateConfirmationMetricsParams['planChangeAmount'];
  formatValue: MetricFormatterFn;
}
const buildProjection = ({
  rawValue,
  metricType,
  decimalPlaces,
  startDate,
  endDate,
  planType,
  adjustmentTitle,
  description,
  planChangeAmount,
  formatValue
}: BuildProjectionParams): Projection => {
  const { formattedStartDate } = formatWeekIdsForAdjustmentDisplay({
    startWeekId: startDate,
    endWeekId: endDate
  });
  const { formattedEndDate } = formatWeekIdsForAdjustmentDisplay({
    startWeekId: startDate,
    endWeekId: endDate
  });

  let aggregatedFormattedValue = null;
  let weeklyValue = null;
  let formattedWeeklyValue = null;

  if (
    [
      AdjustmentPlanType.OrganicTraffic,
      AdjustmentPlanType.OtherTraffic,
      AdjustmentPlanType.PaidTraffic,
      AdjustmentPlanType.RatingsReviews
    ].includes(planType as AdjustmentPlanType)
  ) {
    const aggregatedValue = getNewProjectionValue({
      planType,
      planChangeAmount,
      planStartDate: startDate,
      planEndDate: endDate
    });

    aggregatedFormattedValue = `${formatValue(aggregatedValue, metricType, { showFullValue: false, decimalPlaces })}`;

    const numberOfWeeks = calculateWeeksBetweenWeekIds(startDate, endDate);
    if (
      [AdjustmentPlanType.RatingsReviews].includes(planType as AdjustmentPlanType) &&
      metricType === METRICTYPE.INTEGER
    ) {
      weeklyValue = Math.ceil(rawValue);
    } else {
      weeklyValue = rawValue / (numberOfWeeks > 0 ? numberOfWeeks : 1);
    }
    formattedWeeklyValue = `${formatValue(weeklyValue, metricType, { showFullValue: false, decimalPlaces })}`;
  }

  let formattedValue = null;

  if (
    [AdjustmentPlanType.RatingsReviews].includes(planType as AdjustmentPlanType) &&
    metricType === METRICTYPE.INTEGER
  ) {
    const numberOfWeeks = calculateWeeksBetweenWeekIds(startDate, endDate);
    const aggregatedValue = Math.ceil(rawValue) * (numberOfWeeks > 0 ? numberOfWeeks : 1);
    formattedValue = `${formatValue(aggregatedValue, metricType, { showFullValue: false, decimalPlaces })}`;
  } else {
    formattedValue = `${formatValue(rawValue, metricType, { showFullValue: false, decimalPlaces })}`;
  }

  return {
    rawValue,
    rawWeeklyValue: weeklyValue,
    formattedWeeklyValue,
    aggregatedFormattedValue, // Defined as: # of weeks * planChangeAmount
    formattedValue,
    formattedStartDate,
    formattedEndDate,
    planType,
    adjustmentTitle,
    description,
    planChangeAmount,
    planStartDate: startDate,
    planEndDate: endDate
  };
};

// Separate function for fetching unpublished net impact
export const fetchNetImpact = async (
  netImpactPayload: NetImpactPayload[]
): Promise<NetImpactResponse | { weightedImpact: 0 }> => {
  try {
    const res = await axios.post('api/beaconforecast/GetNetImpact', netImpactPayload);
    const [netImpactResponse] = res.data;
    return netImpactResponse;
  } catch (error) {
    console.error('Failed to fetch net impact for product:', error);
    return { weightedImpact: 0 };
  }
};

/**
 * Returns an async function for fetching published net impact via Adv. Search
 */
const useFetchPublishedNetImpact = () => {
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const { startWeek: startWeekId } = useAppSelector((state) => state.mainTimePeriod);
  const { endWeekId: forecastsEndWeekId } = useForecastStartAndEndWeekIds();
  const { modelVersion, loading: isForecastModelVersionLoading, currentPublishVersion } = useLatestForecastModel();
  const retailerId = useAppSelector((state) => state.retailer.id);

  const forecastAdjustmentNetImpactBuilder = useAdvancedSearchRequestBuilder(
    `netimpact-client-${beaconClientId}`,
    'beacon-netimpact-adjustment'
  );

  return async ({ adjustmentId }): Promise<number> => {
    if (!isForecastModelVersionLoading) {
      const plansAggregationBuilder = new AggregationBuilder('adjustmentId');
      plansAggregationBuilder
        .addAggregationField('Weighted Impact', 'weightedImpact', 'sum', true)
        .addConditionTermFilter('retailerId', [retailerId]);

      forecastAdjustmentNetImpactBuilder
        .setPageSize(1200)
        .setDoAggregation(true)
        .setReturnDocuments(false)
        .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
        .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
        .addConditionTermFilter('adjustmentId', 'must', [adjustmentId])
        .addConditionRangeFilter('forecastWeekId', startWeekId, forecastsEndWeekId)
        .setSearchBy('parent')
        .addAggregation(plansAggregationBuilder.build());

      const forecastsPlansAdjustmentsNetImpactRequest = forecastAdjustmentNetImpactBuilder.build();

      const response = await fetchAdvancedSearchData({
        requestBody: [forecastsPlansAdjustmentsNetImpactRequest],
        requestEndpoint: 'api/beacon/AdvancedSearch?_id=netImpactDataForAdjustment',
        cancelTokenConfig: undefined
      });
      return _get(
        response,
        ['data', 0, 'aggregations', 'by_adjustmentId', 0, 'additionalValues', 'weightedImpact_sum_value'],
        0
      );
    } else {
      return 0;
    }
  };
};

/**
 * Returns an async function used for fetching summarized adjustment metrics
 */
export const useGenerateMetricsForConfirmation = () => {
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const unadjustedRequestId = `unadjustedProjectionForSku-${beaconClientId}`;
  const retailerId = useAppSelector((state) => state.retailer.id);
  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);
  const userId = useAppSelector((state) => state.user.session.userId);
  const { categoryIds } = mainEntity;
  const { modelVersion, currentPublishVersion } = useLatestForecastModel();
  const appName = getAppName();
  const fetchPublishedNetImpact = useFetchPublishedNetImpact();
  const formatValue = useMetricFormatter();

  const unadjustedAdvSearchRequestBuilder = useAdvancedSearchRequestBuilder(
    unadjustedRequestId,
    'beacon-unadjusted-forecast'
  );

  return async ({
    planType,
    adjustmentTitle,
    description,
    planChangeAmount,
    reviewQuantityAmount, // Only for ratings and reviews
    averageStarRatingAmount,
    contentScoreAmounts, // Only for content score
    planStartDate,
    planEndDate,
    product,
    adjustmentId = '',
    viewingExistingAdjustment = false,
    status = null
  }: GenerateConfirmationMetricsParams) => {
    const productEntity = mainEntity.type === 'product' ? mainEntity : product;
    const { subCategoryId, categoryId, retailerSku, stacklineSku } = productEntity;

    /**
     * Unadjusted request
     */
    const unadjustedAggregationBuilder = new AggregationBuilder('retailerId');
    unadjustedAggregationBuilder.addConditionTermFilter('retailerId', [retailerId]);

    const UNADJUSTED_FIELDS = [
      ['Organic Traffic', 'organicTraffic', 'sum'],
      ['Other Traffic', 'otherTraffic', 'sum'],
      ['Paid Traffic', 'paidTrafficValue', 'sum'],
      ['Retail Sales', 'retailSales', 'sum'],
      ['Units Sold', 'unitsSold', 'sum'],
      ['Weighted Content Score By Count', 'weightedContentScoreByContentScoreCount', 'sum'],
      ['Content Score Count', 'contentScoreCount', 'sum'],
      ['Weighted In-Stock Rate by Units', 'weightedInStockRateByUnits', 'sum'],
      ['Weighted Buy Box Win Rate by Units', 'weightedBuyBoxWinRateByUnits', 'sum'],
      ['Weighted Rating By Rating Count', 'weightedRatingByRatingCount', 'sum'],
      ['Rating Count', 'ratingCount', 'sum'],
      ['Incremental Reviews Final', 'incrementalReviewsFinal', 'avg'],
      ['Video Score', 'videoScore', 'avg'],
      ['A+ Score', 'aplusScore', 'avg'],
      ['Bullet Score', 'bulletScore', 'avg'],
      ['Image Score', 'imageScore', 'avg'],
      ['Title Score', 'titleScore', 'avg']
    ];

    UNADJUSTED_FIELDS.forEach(([aggregateByFieldDisplayName, aggregateByFieldName, func, formula]) => {
      unadjustedAggregationBuilder.addAggregationField(
        aggregateByFieldDisplayName,
        aggregateByFieldName,
        func,
        true,
        formula
      );
    });

    unadjustedAdvSearchRequestBuilder
      .setPageSize(1200)
      .setPeriod('year')
      .setSearchBy('parent')
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .addConditionTermFilter('categoryId', 'should', categoryIds)
      .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
      .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
      .addConditionTermFilter('stacklineSku', 'should', [stacklineSku])
      .addAggregation(unadjustedAggregationBuilder.build())
      .addSortField('Total Traffic', 'totalTraffic', 'sum', true)
      .addConditionRangeFilter('forecastWeekId', planStartDate, planEndDate);

    const unadjustedAdvSearchRequest = [unadjustedAdvSearchRequestBuilder.build()];

    // TODO: For now, don't use include adjusted response as adding the deltas does not make sense when viewing an already created adjustment.
    /**
     * Fetch aggregated unadjusted data for selected adjustment weeks.
     */
    const { unadjustedResponse } = await fetchAdjustedAndUnadjustedData({
      requestBody: unadjustedAdvSearchRequest,
      appName
    });

    const originalForecastData = parseForecastResponseForAdjustmentSummary({ unadjustedResponse });

    let originalProjection = null;
    let newProjection = null;
    let netImpact = null;

    // This object is attached to net impact requests
    const additionalProperties = [AdjustmentPlanType.ContentScore].includes(planType)
      ? {
          titleScore: contentScoreAmounts[ContentScoreFields.TitleScore],
          bulletScore: contentScoreAmounts[ContentScoreFields.BulletScore],
          imageScore: contentScoreAmounts[ContentScoreFields.ImageScore],
          videoScore: contentScoreAmounts[ContentScoreFields.VideoScore],
          aplusScore: contentScoreAmounts[ContentScoreFields.A_Score]
        }
      : [AdjustmentPlanType.RatingsReviews].includes(planType)
      ? {
          reviewCount: reviewQuantityAmount || 0,
          reviewAvgRating: averageStarRatingAmount || 0
        }
      : {
          reviewCount: 0,
          reviewAvgRating: 0
        };

    /**
     * For ratings and reviews, we have to build 2 separate projections for both original and new projections for a total of 4 projections.
     */
    if ([AdjustmentPlanType.RatingsReviews].includes(planType)) {
      // Meta data for weighted rating
      const { advancedSearchKey, metricType, decimalPlaces } = planTypeMetaData[planType];
      // Meta data for review count
      const {
        advancedSearchKey: reviewCountKey,
        metricType: reviewCountMetricType,
        decimalPlaces: reviewCountDecimalPlaces
      } = planTypeMetaData[planType].reviewCount;

      // Get unadjusted weighted rating
      const weightedRating = originalForecastData[advancedSearchKey];
      // Get unadjusted review count
      const reviewCount = originalForecastData[reviewCountKey];

      const modifiedWeightedRatingPlanChangeAmount = viewingExistingAdjustment
        ? averageStarRatingAmount
        : modifyPlanChangeAmount({ planType, planChangeAmount: averageStarRatingAmount });

      const modifiedReviewCountPlanChangeAmount = viewingExistingAdjustment
        ? reviewQuantityAmount
        : modifyPlanChangeAmount({ planType, planChangeAmount: reviewQuantityAmount });

      /**
       * Get original projections for count and weighted rating
       */
      const weightedRatingOriginalProjection = buildProjection({
        rawValue: weightedRating,
        metricType,
        decimalPlaces,
        startDate: planStartDate,
        endDate: planEndDate,
        planType,
        adjustmentTitle,
        description,
        planChangeAmount: modifiedWeightedRatingPlanChangeAmount,
        formatValue
      });

      const ratingCountOriginalProjection = buildProjection({
        rawValue: reviewCount,
        metricType: reviewCountMetricType,
        decimalPlaces: reviewCountDecimalPlaces,
        startDate: planStartDate,
        endDate: planEndDate,
        planType,
        adjustmentTitle,
        description,
        planChangeAmount: modifiedReviewCountPlanChangeAmount,
        formatValue
      });

      originalProjection = {
        ratingCount: ratingCountOriginalProjection,
        weightedRating: weightedRatingOriginalProjection
      };
      /**
       * Get new projections for count and weighted rating
       */
      const weightedRatingNewProjection = buildProjection({
        rawValue: modifiedWeightedRatingPlanChangeAmount,
        metricType,
        decimalPlaces,
        startDate: planStartDate,
        endDate: planEndDate,
        planType,
        adjustmentTitle,
        description,
        planChangeAmount: modifiedWeightedRatingPlanChangeAmount,
        formatValue
      });

      const ratingCountNewProjection = buildProjection({
        rawValue: modifiedReviewCountPlanChangeAmount,
        metricType: reviewCountMetricType,
        decimalPlaces: reviewCountDecimalPlaces,
        startDate: planStartDate,
        endDate: planEndDate,
        planType,
        adjustmentTitle,
        description,
        planChangeAmount: modifiedReviewCountPlanChangeAmount,
        formatValue
      });

      newProjection = {
        ratingCount: ratingCountNewProjection,
        weightedRating: weightedRatingNewProjection
      };
      /**
       * Build the net impact for the new adjustment plan
       */
      const netImpactPayload: NetImpactPayload = {
        baseAdjustmentRequest: {
          beaconClientId,
          retailerId,
          retailerSku,
          userId,
          planType,
          startWeekId: planStartDate,
          endWeekId: planEndDate,
          ignoreConflictWithAdjustmentIds: [adjustmentId]
        },
        additionalProperties,
        adjustmentChangeType: 'Absolute',
        adjustmentChangeValue: 0,
        stacklineSku,
        categoryId,
        subCategoryId,
        requestType: 'Create',
        shouldPublish: false
      };

      if (viewingExistingAdjustment && status !== 'Draft') {
        netImpact = await fetchPublishedNetImpact({ adjustmentId });
      } else {
        const { weightedImpact } = await fetchNetImpact([netImpactPayload]);
        netImpact = weightedImpact;
      }
    } else {
      // For other metrics excluding Ratings & Reviews

      /**
       * Extract raw metric value
       */
      const { advancedSearchKey } = planTypeMetaData[planType];
      const originalRawMetricValue = originalForecastData[advancedSearchKey];

      // Get the metric type depending on the plan type
      const { metricType, decimalPlaces } = planTypeMetaData[planType];

      // Adjust the planChangeAmount for expected payloads and formatted values
      const modifiedPlanChangeAmount = viewingExistingAdjustment
        ? planChangeAmount
        : modifyPlanChangeAmount({ planType, planChangeAmount });

      /**
       * Build the original projection for the product
       */
      originalProjection = buildProjection({
        rawValue: originalRawMetricValue,
        metricType,
        decimalPlaces,
        startDate: planStartDate,
        endDate: planEndDate,
        planType,
        adjustmentTitle,
        description,
        planChangeAmount: modifiedPlanChangeAmount,
        formatValue
      });

      /**
       * For Content Score, we attach additional metadata about the makeup of the content score for use in the UI
       */
      if ([AdjustmentPlanType.ContentScore].includes(planType)) {
        const {
          aplusScore_avg_value,
          bulletScore_avg_value,
          imageScore_avg_value,
          titleScore_avg_value,
          videoScore_avg_value
        } = originalForecastData;

        originalProjection.additionalData = {
          [ContentScoreFields.A_Score]: {
            rawValue: aplusScore_avg_value,
            formattedValue: formatValue(aplusScore_avg_value, METRICTYPE.PERCENT, {
              showFullValue: false,
              decimalPlaces: 0
            })
          },
          [ContentScoreFields.BulletScore]: {
            rawValue: bulletScore_avg_value,
            formattedValue: formatValue(bulletScore_avg_value, METRICTYPE.PERCENT, {
              showFullValue: false,
              decimalPlaces: 0
            })
          },
          [ContentScoreFields.ImageScore]: {
            rawValue: imageScore_avg_value,
            formattedValue: formatValue(imageScore_avg_value, METRICTYPE.PERCENT, {
              showFullValue: false,
              decimalPlaces: 0
            })
          },
          [ContentScoreFields.TitleScore]: {
            rawValue: titleScore_avg_value,
            formattedValue: formatValue(titleScore_avg_value, METRICTYPE.PERCENT, {
              showFullValue: false,
              decimalPlaces: 0
            })
          },
          [ContentScoreFields.VideoScore]: {
            rawValue: videoScore_avg_value,
            formattedValue: formatValue(videoScore_avg_value, METRICTYPE.PERCENT, {
              showFullValue: false,
              decimalPlaces: 0
            })
          }
        };
      }

      /**
       * Build the new projection for the product
       */
      newProjection = buildProjection({
        rawValue: modifiedPlanChangeAmount,
        metricType,
        decimalPlaces,
        startDate: planStartDate,
        endDate: planEndDate,
        planType,
        adjustmentTitle,
        description,
        planChangeAmount: modifiedPlanChangeAmount,
        formatValue
      });

      /**
       * Build the net impact for the new adjustment plan
       */
      const netImpactPayload: NetImpactPayload = {
        baseAdjustmentRequest: {
          beaconClientId,
          retailerId,
          retailerSku,
          userId,
          planType,
          startWeekId: planStartDate,
          endWeekId: planEndDate,
          ignoreConflictWithAdjustmentIds: [adjustmentId]
        },
        additionalProperties,
        adjustmentChangeType: 'Absolute',
        adjustmentChangeValue: modifiedPlanChangeAmount,
        stacklineSku,
        categoryId,
        subCategoryId,
        requestType: 'Create',
        shouldPublish: false
      };

      /**
       * If we're creating or editing an adjustment, fetch unpublished net impact.
       * If we're viewing a draft adjustment, fetch unpublished net impact.
       * If we're viewing a published adjustment, fetch published net impact.
       */

      if (viewingExistingAdjustment && status !== 'Draft') {
        netImpact = await fetchPublishedNetImpact({ adjustmentId });
      } else {
        const { weightedImpact } = await fetchNetImpact([netImpactPayload]);
        netImpact = weightedImpact;
      }
    }

    const symbol = netImpact <= 0 ? '' : '+';
    const metricChangeStringFromValue = netImpact < 0 ? 'decrease' : 'increase';
    const metricChangeComparisonStringFromValue = netImpact < 0 ? 'below' : 'above';

    const netImpactProjection: NetImpactProjection = {
      rawValue: netImpact,
      formattedValue: `${symbol}${formatValue(netImpact, METRICTYPE.VOLUME, {
        showFullValue: false,
        decimalPlaces: 2
      })}`,
      metricChangeString: metricChangeStringFromValue,
      metricChangeComparisonString: metricChangeComparisonStringFromValue
    };
    return { originalProjection, newProjection, netImpactProjection };
  };
};

export const getContentScoreValues = (contentScoreAmounts: ContentScoreAmounts) => {
  const payloadValues = Object.entries(contentScoreAmounts).reduce((acc, [key, value]) => {
    if (value) {
      const numericValue = Number(value.replace('%', ''));
      if (!Number.isNaN(numericValue)) {
        acc[key] = numericValue / 100;
      } else {
        acc[key] = 0;
      }
    } else {
      acc[key] = 0;
    }
    return acc;
  }, {} as ContentScoreAmounts);
  return payloadValues;
};

export const getAdjustmentAmountValue = (planType: AdjustmentPlanType, amount: string) => {
  if (amount) {
    switch (planType) {
      case AdjustmentPlanType.RatingsReviews:
        return Number(amount);
      case AdjustmentPlanType.ContentScore:
        return Number(amount) / 100;
      case AdjustmentPlanType.InstockRate:
        return Number(amount.replace('%', '')) / 100;
      case AdjustmentPlanType.BuyBox:
        return Number(amount.replace('%', '')) / 100;
      default:
        return Number(amount);
    }
  } else {
    return null;
  }
};
