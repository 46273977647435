import React from 'react';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import DomainActionButton from './DomainActionButton';

interface ApprovedDomainsProps {
  approvedDomains: string[];
  loading: boolean;
  refetchData: () => Promise<void>;
  canEditDomains: boolean;
}

/**
 * Table for showing approved domains
 */
export default function ApprovedDomains({
  approvedDomains,
  loading,
  refetchData,
  canEditDomains
}: ApprovedDomainsProps) {
  return (
    <EntityTable
      isLoading={loading}
      shouldModifyColumnDefs={false}
      rowData={approvedDomains || []}
      columnDefs={[
        {
          headerName: 'Domain Name',
          field: 'domain',
          valueFormatter: (_, domainName) => {
            return domainName;
          }
        },
        {
          headerName: '',
          field: '',
          valueFormatter: (_, domainName) => {
            return canEditDomains ? <DomainActionButton domain={domainName} refetchData={refetchData} /> : null;
          },
          tableColumnProps: {
            align: 'right'
          },
          tableCellProps: {
            style: {
              paddingRight: '27px'
            }
          }
        }
      ]}
    />
  );
}
