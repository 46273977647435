import React, { useState, useEffect } from 'react';
import Select from 'src/components/common/Form/Select';
import colors from 'src/utils/colors';
import Checkbox from '@mui/material/Checkbox';
import { OmniSelectedField } from 'src/components/Omni/OmniExportService/OmniExportGroupBy';

interface OmniContentExportGroupProps {
  classes: { [key: string]: any };
  setShareOfShelfSelection: (opt: OmniSelectedField) => void;
}

const OmniContentExportGroup: React.FC<OmniContentExportGroupProps> = ({ setShareOfShelfSelection, classes }) => {
  const firstOpt = [
    {
      displayName: 'Product',
      id: 'stacklineSku.keyword',
      value: 'stacklineSku.keyword'
    }
  ];
  const [firstGroupByValue] = useState(firstOpt[0].value);
  const [aggByWeek, setSggByWeek] = useState(false);
  const [includeComparison] = useState(false);

  const handleCheckSeparateWeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSggByWeek(e.target.checked);
  };

  useEffect(() => {
    setShareOfShelfSelection({ firstGroupByValue, aggByWeek, includeComparison });
  }, [firstGroupByValue, aggByWeek, includeComparison]);

  return (
    <>
      <div className="setting_row">
        <div className="label" style={{ width: 100 }}>
          Group by:
        </div>
        <div>
          <Select
            items={firstOpt}
            disabled={false}
            value={firstGroupByValue}
            onChange={() => {}}
            style={{ width: 300 }}
            classes={classes}
          />
        </div>
      </div>
      <div className="setting_row" style={{ display: 'flex' }}>
        <div className="label" style={{ width: 100, marginTop: 7 }}>
          Customize:
        </div>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Checkbox
              sx={{
                root: {
                  color: colors.darkBlue,
                  '&$checked': {
                    color: colors.darkBlue
                  }
                }
              }}
              checked={aggByWeek}
              onChange={handleCheckSeparateWeek}
              //   disabled
              style={{ marginLeft: -9 }}
              id="weekId-checkbox"
            />
            <div
              style={{
                paddingTop: 9
                // color: colors.lightGrey
              }}
            >
              Separate data by week
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OmniContentExportGroup;
