const RECEIVE_ADSCORES = 'src/adEntities/RECEIVE_ADSCORES' as const;
const CLEAR_ADSCORES = 'src/adEntities/CLEAR_ADSCORES' as const;

export interface AdScoreForEntity {
  reachAdScore: number;
  adSpendAdScore: number;
  adSalesAdScore: number;
  optimizationAdScore: number;
  adScore: number;
}

export interface AdScoreByEntityId {
  [key: string]: AdScoreForEntity;
}

export interface AdScores {
  adEntity: AdScoreByEntityId;
  adPortfolio: AdScoreByEntityId;
  adCampaign: AdScoreByEntityId;
  client: AdScoreForEntity;
}

export default {
  CLEAR_ADSCORES,
  RECEIVE_ADSCORES
};
