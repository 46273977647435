import React, { useState } from 'react';
import StyledDialog from 'src/components/BeaconRedesignComponents/common/StyledDialog/StyledDialog';
import StyledInput from 'src/components/BeaconRedesignComponents/common/StyledInput';
import TeamPopupCloseIcon from '../TeamPopupCloseIcon';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from '@stackline/ui';
import * as serverProxy from 'src/routes/UserAccount/UserTeamManagement/serverProxy';
import { useAppSelector, useSnackbar } from 'src/utils/Hooks';
import { DOMAIN_VALIDATOR_REGEX } from 'src/utils/validators';
import { settingsErrorMessage } from '../../utils';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { PROCESSING_REQUEST_MESSAGE } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Team/constants';

type AddDomainModalProps = Pick<React.ComponentProps<typeof StyledDialog>, 'open' | 'onClose'> & {
  refetchData: () => Promise<void>;
};

/**
 * Modal for adding a new domain to the approved domains list
 */
export default function AddDomainModal({ open, refetchData, onClose }: AddDomainModalProps) {
  const [domain, setDomain] = useState('');
  const { showSnackbar } = useSnackbar();

  const currentUserEmail = useAppSelector((state) => state.user.config.profile.email);

  const closePopup = (event?: React.MouseEvent<unknown, MouseEvent>) => {
    if (onClose) {
      onClose(event, 'backdropClick');
    }
  };

  const addNewDomain = async () => {
    try {
      closePopup();
      showSnackbar({
        type: 'info',
        message: PROCESSING_REQUEST_MESSAGE
      });
      await serverProxy.AddAuthorizedClientDomain(currentUserEmail, domain);
      await refetchData();
      showSnackbar({
        type: 'success',
        message: `You've successfully added ${domain} as an approved domain.`
      });
    } catch {
      showSnackbar({
        type: 'error',
        message: settingsErrorMessage
      });
    }
  };

  return (
    <StyledDialog width={329} padding="24px" open={open} onClose={onClose}>
      <Flex flexDirection="column" gap="md">
        <Flex justifyContent="space-between" alignItems="center">
          <Text variant="h4">Add New Domain</Text>
          <TeamPopupCloseIcon onClick={closePopup} />
        </Flex>
        <Flex flexDirection="column" gap="sm">
          <Text variant="subtitle2">Domain Name</Text>
          <StyledInput autoFocus value={domain} onChange={(e) => setDomain(e.target.value)} />
        </Flex>
      </Flex>
      <Flex justifyContent="flex-end" marginTop="24px">
        <SlButton variant="contained" onClick={() => addNewDomain()} disabled={!DOMAIN_VALIDATOR_REGEX.test(domain)}>
          Save
        </SlButton>
      </Flex>
    </StyledDialog>
  );
}
