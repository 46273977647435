import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _cloneDeep from 'lodash/cloneDeep';
import MenuItem from '@mui/material/MenuItem';
import { comparisonParams, filterParams } from '../../../utils/segments';
import { addQueryParams } from '../../../utils/browser';

class Categories extends React.Component {
  static defaultProps = {
    searchText: '',
    togglePanel: () => {}
  };

  static propTypes = {
    entityService: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    searchText: PropTypes.string,
    togglePanel: PropTypes.func
  };

  static contextTypes = {
    router: PropTypes.object
  };

  componentWillMount() {
    const { categories, entityService } = this.props;
    const allCategories = _cloneDeep(categories);
    this.categories = [];
    allCategories.sort((a, b) => b.relevancyScore - a.relevancyScore || a.categoryName.localeCompare(b.categoryName));
    allCategories.forEach((val) => {
      if (
        entityService.mainEntity.type === 'category' ||
        entityService.mainEntity.categoryIds.indexOf(val.categoryId) !== -1
      ) {
        this.categories.push(val);
      }
    });
    this.setState({ categories: this.categories });
  }

  componentWillReceiveProps(nextProps) {
    const { searchText } = nextProps;

    const categories = this.categories.filter(
      (val) => val.categoryName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    );
    this.setState({
      categories
    });
  }

  handleTouchTap = (selection) => {
    const { togglePanel, location, retailer, mainTimePeriod } = this.props;
    const entity = {
      id: selection.categoryId,
      type: 'category'
    };

    const existingParams = addQueryParams(location, retailer, mainTimePeriod, [...filterParams, ...comparisonParams]);

    togglePanel();
    this.context.router.history.push(`?ctype=${entity.type}&cid=${entity.id}&${existingParams}`);
  };

  showNoCategories() {
    const { categories } = this.state;

    if (categories.length > 0) {
      return null;
    }

    return <div style={{ padding: '10px' }}>No categories found.</div>;
  }

  render() {
    const { categories } = this.state;
    return (
      <div style={{ padding: '10px' }}>
        {categories.map((val) => (
          <MenuItem key={val.categoryId} style={{ fontSize: '14px' }} onClick={() => this.handleTouchTap(val)}>
            {val.categoryName}
          </MenuItem>
        ))}
        {this.showNoCategories()}
      </div>
    );
  }
}

export default withRouter(connect((state) => state)(Categories));
