import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import _get from 'lodash/get';
import { AppName } from 'sl-api-connector/types';

import { store } from 'src/main';
import Creators from './actions';
import AdAutomationStrategyCreators from 'src/store/modules/adManager/adAutomationStrategies/actions';
import { parseAdPlatformSettings } from './selectors';
import ReduxStore from 'src/types/store/reduxStore';
import { fetchDataWithCache } from 'src/queryClient';
import { shouldShowCriteo } from 'src/utils/app';

export const { clearAdPlatformSettings, receiveAdPlatformSettings } = Creators;

export const fetchAdPlatformSettings = () => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
  if ((store.getState() as ReduxStore).app.name !== AppName.Advertising) {
    return;
  }

  const response = await fetchDataWithCache('adPlatformSettings', () => axios.get('/apiAdManager/adPlatformSettings'));
  const parsedData = parseAdPlatformSettings(response.data);
  let filteredParsedData = [];
  const retailerId = +store.getState().retailer.id;

  if (shouldShowCriteo()) {
    const isNotMaximizeROAS = (x: any) => !['MaximizeROAS', 'MaximizeIncrementalROAS'].includes(x.settingId);

    filteredParsedData = parsedData.filter((x: any) => {
      if (retailerId === 0) {
        return isNotMaximizeROAS(x);
      } else {
        const isMatchingRetailer = retailerId === _get(x, ['extendedAttributes', 'retailerId'], null);
        return isNotMaximizeROAS(x) && isMatchingRetailer;
      }
    });
  } else {
    filteredParsedData = parsedData.filter((x: any) => {
      const isNotMaximizeROAS = !['MaximizeROAS', 'MaximizeIncrementalROAS'].includes(x.settingId);
      const isMatchingRetailer = retailerId === _get(x, ['extendedAttributes', 'retailerId'], null);
      const condition = isNotMaximizeROAS && isMatchingRetailer;
      return condition;
    });
  }
  filteredParsedData.sort((a, b) => {
    const settingIdA = a.settingId.toUpperCase(); // ignore upper and lowercase
    const settingIdB = b.settingId.toUpperCase(); // ignore upper and lowercase
    if (settingIdA < settingIdB) {
      return 1;
    }
    if (settingIdA > settingIdB) {
      return -1;
    }
    return 0;
  });
  dispatch(receiveAdPlatformSettings(filteredParsedData));
  dispatch(
    AdAutomationStrategyCreators.receiveAdAutomationStrategies(
      filteredParsedData.filter((x: any) => x.type === 'adAutomationStrategy')
    )
  );
};
