import { useMemo, useCallback } from 'react';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import AdvancedSearchRequestBuilder, {
  useAdvancedSearchRequestBuilder
} from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import { useAppSelector, useQueryParamValue } from 'src/utils/Hooks';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import {
  ForecastsKeyMetricsUnadjustedResponse,
  ForecastsKeyMetricsAdjustedResponse,
  ForecastsKeyMetricsDatum
} from './types';
import { parseForecastsKeyMetricsReponse } from './responseParsers';
import { ForecastType } from '../types';
import { useForecastStartAndEndWeekIds, useLatestForecastModel } from './hooks';

import { UseQueryResult } from 'react-query';
import { FORECAST_SUMMARY_KEYMETRIC_QUERY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastQueryKeys';
import {
  useApplyEntityFilters,
  useCreateRemoveRetailPriceRangeFilters
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { getAppName } from 'src/utils/app';
import { weekIdToTimestamp } from 'src/utils/dateUtils';

export const UNADJUSTED_METRIC_FIELDS = [
  ['Total Traffic', 'totalTraffic', 'sum'],
  ['Organic Traffic', 'organicTraffic', 'sum'],
  ['Other Traffic', 'otherTraffic', 'sum'],
  ['Paid Traffic', 'paidTrafficValue', 'sum'],
  ['Ad Spend', 'adSpend', 'sum'],
  ['Ad Sales', 'adSales', 'sum'],
  ['Weighted Content Score By Content Count', 'weightedContentScoreByContentScoreCount', 'sum'],
  ['Weighted Content Score by Units', 'weightedContentScoreByUnits', 'sum'],
  ['Units Sold For Content Score', 'unitsSoldForContentScore', 'sum'],
  ['Content Score Count', 'contentScoreCount', 'sum'],
  ['Weighted Star Rating By Number of Reviews', 'weightedRatingByRatingCount', 'sum'],
  ['Weighted Buy Box Wins By Units', 'weightedBuyBoxWinRateByUnits', 'sum'],
  ['Weighted In-Stock Rate By Units', 'weightedInStockRateByUnits', 'sum'],
  ['Number of Reviews', 'ratingCount', 'sum'],
  ['Retail Sales', 'retailSales', 'sum'],
  ['Units Sold', 'unitsSold', 'sum'],
  ['Wholesale Sales', 'wholesaleSales', 'sum'],
  ['Retailer Margin', 'retailerMargin', 'sum'],
  ['Brand Margin', 'brandMargin', 'sum']
];

export const ADJUSTED_METRIC_FIELDS = [
  ['Total Traffic', 'totalTrafficDelta', 'sum'],
  ['Organic Traffic', 'organicTrafficDelta', 'sum'],
  ['Other Traffic', 'otherTrafficDelta', 'sum'],
  ['Paid Traffic', 'paidTrafficDelta', 'sum'],
  ['Ad Spend', 'adSpendDelta', 'sum'],
  ['Ad Sales', 'adSalesDelta', 'sum'],
  ['Units Sold', 'unitsSoldDelta', 'sum'],
  ['Retail Sales', 'retailSalesDelta', 'sum'],
  ['Weighted Content Score By Content Count', 'weightedContentScoreByContentScoreCountDelta', 'sum'],
  ['Weighted Content Score by Units', 'weightedContentScoreByUnitsDelta', 'sum'],
  ['Units Sold For Content Score', 'unitsSoldForContentScoreDelta', 'sum'],
  ['Content Score Count', 'contentScoreDelta', 'avg'],
  ['Weighted Star Rating By Number of Reviews', 'weightedRatingByRatingCountDelta', 'sum'],
  ['Weighted Buy Box Wins By Units', 'weightedBuyBoxWinRateByUnitsDelta', 'sum'],
  ['Weighted In-Stock Rate By Units', 'weightedInStockRateByUnitsDelta', 'sum'],
  ['Number of Reviews', 'ratingCountDelta', 'sum'],
  ['Wholesale Sales', 'wholesaleSalesDelta', 'sum'],
  ['Retailer Margin', 'retailerMarginDelta', 'sum'],
  ['Brand Margin', 'brandMarginDelta', 'sum']
];

type UseKeyMetricForecastSummaryDataReturn = Pick<UseQueryResult<ForecastsKeyMetricsDatum[]>, 'data' | 'isLoading'> & {
  getSplineData: (indexName: string, fieldName: string) => number[][];
};

const fieldNameToForecastFieldName = {
  totalClicks: 'totalTraffic',
  organicClicks: 'organicTraffic',
  otherClicks: 'otherTraffic',
  clicks: 'paidTrafficValue',
  spend: 'adSpend',
  sales: 'adSales',
  retailerGrossMargin: 'retailerMargin',
  brandGrossMargin: 'brandMargin',
  inStockWeighted: 'weightedInStockRateByUnits',
  rolling52WeekWeightedRating: 'weightedRatingByRatingCount',
  rolling52WeekReviews: 'ratingCount',
  buyBoxWeighted: 'unitsSold',
  winsByClient: 'weightedBuyBoxWinRateByUnits'
};

const fieldNameToForecastAggFunction = {
  purchaseRate: 'computed',
  contentScore: 'computed',
  retailPrice: 'computed',
  instockRate: 'computed',
  weightedRating: 'computed',
  winPercentage: 'computed'
};

/**
 * Get the data for the key metric summary cards on the forecast
 * summary page.
 */
export const useKeyMetricForecastSummaryData = ({
  unadjustedMetricFields = UNADJUSTED_METRIC_FIELDS,
  adjustedMetricFields = ADJUSTED_METRIC_FIELDS,
  buildRequest
}: {
  unadjustedMetricFields?: string[][];
  adjustedMetricFields?: string[][];
  buildRequest?: (requestBuilder: AdvancedSearchRequestBuilder) => AdvancedSearchRequestBuilder;
} = {}): UseKeyMetricForecastSummaryDataReturn => {
  const applyEntityFilters = useApplyEntityFilters();
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const unadjustedRequestId = `unadjustedForecastByweekId_weekId-client-${beaconClientId}`;
  const adjustedRequestId = `adjustedForecastByweekId_weekId-client-${beaconClientId}`;
  const removeRetailPriceFilters = useCreateRemoveRetailPriceRangeFilters();

  const keyMetricCardRequestBuilder = useAdvancedSearchRequestBuilder(
    unadjustedRequestId,
    'beacon-unadjusted-forecast'
  );
  const retailerId = useAppSelector((state) => state.retailer.id);
  const forecastType: ForecastType = useQueryParamValue('forecastType', ForecastType.ADJUSTED);
  const { modelVersion, loading, currentPublishVersion } = useLatestForecastModel();

  const { startWeekId, endWeekId } = useForecastStartAndEndWeekIds();

  const keyMetricProjectionsAdvancedSearchRequest = useMemo(() => {
    const aggregationBuilder = new AggregationBuilder('forecastWeekId');
    aggregationBuilder.addConditionTermFilter('retailerId', [retailerId]);

    unadjustedMetricFields.forEach(([aggregateByFieldDisplayName, aggregateByFieldName, func, formula]) => {
      aggregationBuilder.addAggregationField(aggregateByFieldDisplayName, aggregateByFieldName, func, true, formula);
    });

    keyMetricCardRequestBuilder
      .setPageSize(1200)
      .setPeriod('year')
      .setSearchBy('parent')
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
      .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
      .addAggregation(aggregationBuilder.build())
      .addSortField('Total Traffic', 'totalTraffic', 'sum', true)
      .addConditionRangeFilter('forecastWeekId', startWeekId, endWeekId)
      .apply(applyEntityFilters)
      .apply((builder) => (buildRequest ? buildRequest(builder) : builder))
      .apply(removeRetailPriceFilters());

    return keyMetricCardRequestBuilder.build();
  }, [
    retailerId,
    unadjustedMetricFields,
    keyMetricCardRequestBuilder,
    modelVersion,
    currentPublishVersion,
    startWeekId,
    endWeekId,
    applyEntityFilters,
    buildRequest,
    removeRetailPriceFilters
  ]);

  const keyMetricAdjustedForecastAdvancedSearchRequest = useMemo(() => {
    const aggregationBuilder = new AggregationBuilder('forecastWeekId');
    aggregationBuilder.addConditionTermFilter('retailerId', [retailerId]);
    adjustedMetricFields.forEach(([aggregateByFieldDisplayName, aggregateByFieldName, func]) => {
      aggregationBuilder.addAggregationField(aggregateByFieldDisplayName, aggregateByFieldName, func, true);
    });

    const unadjustedRequestBuilder = keyMetricCardRequestBuilder
      .clone()
      .clearAggregations()
      .clearSortFields()
      .setId(adjustedRequestId)
      .setSearchType('beacon-adjusted-forecast')
      .addSortField('Total Traffic', 'totalTrafficDelta', 'sum', true)
      .addAggregation(aggregationBuilder.build())
      .apply((builder) => (buildRequest ? buildRequest(builder) : builder))
      .apply(removeRetailPriceFilters());

    return unadjustedRequestBuilder.build();
  }, [
    retailerId,
    adjustedMetricFields,
    keyMetricCardRequestBuilder,
    adjustedRequestId,
    buildRequest,
    removeRetailPriceFilters
  ]);

  const requestBody = useMemo(() => {
    return [
      keyMetricProjectionsAdvancedSearchRequest,
      ...(forecastType === ForecastType.ADJUSTED ? [keyMetricAdjustedForecastAdvancedSearchRequest] : [])
    ];
  }, [keyMetricProjectionsAdvancedSearchRequest, keyMetricAdjustedForecastAdvancedSearchRequest, forecastType]);

  const { data: response, isLoading } = useGenericAdvancedSearch<
    [ForecastsKeyMetricsUnadjustedResponse, ForecastsKeyMetricsAdjustedResponse]
  >({
    requestId: unadjustedRequestId,
    queryKeys: [FORECAST_SUMMARY_KEYMETRIC_QUERY, requestBody],
    shouldPerformFetch: !loading,
    requestBody,
    allowCancel: false // Gets invalidated after creating adjustment and then polled for
  });

  const parsedData = useMemo(
    () =>
      response && response.data && response.data[0]
        ? parseForecastsKeyMetricsReponse(response.data[0], response.data[1])
        : {
            data: []
          },
    [response]
  );

  const getSplineData = useCallback(
    (indexName: string, fieldName: string) => {
      const field = INDEX_FIELDS.getField(getAppName(), indexName, fieldName);
      const metricKey = `${fieldNameToForecastFieldName[fieldName] || fieldName}_${
        fieldNameToForecastAggFunction[fieldName] || field.aggregationFunctionType || field.aggregationFunction
      }_value`;
      return [...parsedData.data]
        .sort((a, b) => a.weekId.localeCompare(b.weekId))
        .map((row) => [weekIdToTimestamp(Number(row.weekId)), row[metricKey]]);
    },
    [parsedData.data]
  );

  return useMemo(
    () => ({
      getSplineData,
      data: parsedData.data,
      isLoading
    }),
    [getSplineData, isLoading, parsedData.data]
  );
};
