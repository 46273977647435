import React, { useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from 'src/components/BeaconRedesignComponents/Generic/IconButton';
import { useStacklineTheme, SlMenu, SlDropdownMenuOption } from '@stackline/ui';
import RemoveUserPopup from './ActionModals/RemoveUserPopup';
import AssignAdminRoleModal from './ActionModals/AssignAdminRoleModal';
import RemoveAdminRoleModal from './ActionModals/RemoveAdminRoleModal';
import TransferOwnershipModal from './ActionModals/TransferOwnershipModal';

export enum UserAction {
  DELETE = 'delete',
  ASSIGN_ADMIN = 'assignAdmin',
  REMOVE_ADMIN = 'removeAdmin',
  TRANSFER_OWNERSHIP = 'transferOwnership'
}
/**
 * Button for the team table rows that includes administrative
 * actions like deleting a user, editing, etc.
 *
 */
export default function UserActionButton({
  user,
  refetchData,
  options
}: {
  /**
   * The user object for the row
   */
  user: { email: string };
  /**
   * Function to refetch the data after an action is performed. This will keep the
   * table up-to-date after performing a change.
   */
  refetchData: () => Promise<void>;
  /**
   * Options to display in the dropdown menu
   */
  options: SlDropdownMenuOption[];
}) {
  const theme = useStacklineTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [openAction, setOpenAction] = useState<UserAction | null>(null);

  const userActionToModal = {
    [UserAction.DELETE]: RemoveUserPopup,
    [UserAction.ASSIGN_ADMIN]: AssignAdminRoleModal,
    [UserAction.REMOVE_ADMIN]: RemoveAdminRoleModal,
    [UserAction.TRANSFER_OWNERSHIP]: TransferOwnershipModal
  };

  const ActionModal = openAction ? userActionToModal[openAction] : null;

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (option: SlDropdownMenuOption) => {
    setOpenAction(option.id as UserAction);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreHorizIcon
          style={{
            fill: theme.colors.primary
          }}
        />
      </IconButton>
      <SlMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={!!anchorEl}
        selectedId={null}
        onChange={handleClickItem}
        options={options}
      />
      {ActionModal && <ActionModal user={user} open onClose={() => setOpenAction(null)} refetchData={refetchData} />}
    </>
  );
}
