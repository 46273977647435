import React from 'react';
import Lottie from 'react-lottie';
import * as ellipsisAnimation from './AnimationData/ellipsis.json';

export const AnimatedEllipsis = ({ ...props }: Omit<React.ComponentPropsWithRef<typeof Lottie>, 'options'>) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ellipsisAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return <Lottie options={defaultOptions} {...props} />;
};
