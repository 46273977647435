import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import DisputeDetailsPage from 'src/components/ShortageDisputes/DisputeDetailsPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import DisputeManagementDashboard from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeManagementDashboard';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { useLinkForDisputeManagement } from 'src/components/ShortageDisputes/components/DisputeManagement/hooks';

const queryClient = new QueryClient();

interface DisputeManagementPageProps {
  location: any;
  history: any;
}

const DisputeManagementPage = ({ location, history }: DisputeManagementPageProps) => {
  const searchParameters = new URLSearchParams(location.search);
  const disputeManagementLink = useLinkForDisputeManagement();

  const redirectToClientEntity = () => {
    history.push(disputeManagementLink);
  };

  useEffect(() => {
    if (!location.pathname.includes('client')) {
      redirectToClientEntity();
    }
  }, [location]);

  const renderView = () => {
    if (searchParameters.has('invoiceId')) {
      return <DisputeDetailsPage location={location} queryClient={queryClient} />;
    }
    return <DisputeManagementDashboard queryClient={queryClient} />;
  };

  return <>{renderView()}</>;
};

const WrappedDisputeManagementPage = ({ location, history }: { location: any; history: any }) => {
  const { app } = useSelector((state: ReduxStore) => state);

  return (
    <QueryClientProvider client={queryClient}>
      <DisputeManagementPage queryClient={queryClient} history={history} location={location} />
      {app.stage === 'dev' ? <ReactQueryDevtools initialIsOpen position="bottom-right" /> : null}
    </QueryClientProvider>
  );
};

export default withRouter(WrappedDisputeManagementPage);
