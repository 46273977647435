import { TooltipFormatterContextObject } from 'highcharts/highmaps';
import ReduxStore from 'src/types/store/reduxStore';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import convertMetricToDisplayValue from '../components/EntityGrid/gridUtils';

const changePointerName = (pointName) => {
  if (pointName === 'United States of America') {
    return 'United States';
  }
  return pointName;
};

/**
 * geo tooltip formatter
 * @param point
 * @param tooltipClassName
 * @param geoChartType
 */
export const tooltipFormatter = (
  retailer: ReduxStore['retailer'],
  point: TooltipFormatterContextObject | any,
  tooltipClassName: string,
  metricType: any,
  shouldShowComparison: boolean
): string => {
  switch (shouldShowComparison) {
    case true:
      return `
      <div class=${tooltipClassName}>

        <div class="geo-region">
          <div class="region-dot" style="background-color: ${point.point.color}"> </div>
          <div>${changePointerName(point.point.name)}</div>
        </div>

        <div class='geo-metric'>

        <div>${convertMetricToDisplayValue(retailer, point.point.value as number, metricType, '$')}</div>

          <div class="omni__geo--tooltip_comparison-arrow ${
            point.point.preValue > 0 ? 'arrow--positive' : point.point.preValue < 0 ? 'arrow--negative' : 'arrow--zero'
          }">
            <svg style="width: 7px;" xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6">
              <path data-name="Polygon 127" d="M3.5 0 7 6H0z" style="fill:#39b3ae"/>
            </svg>
          </div>

          <div class="omni__geo--tooltip_comparison-item">
              ${convertMetricToDisplayValue(retailer, point.point.preValue, METRICTYPE.PERCENT, '$')}
          </div>

      </div>`;

    default:
      return `
        <div class=${tooltipClassName}>

          <div class="geo-region">
            <div class="region-dot" style="background-color: ${point.point.color}"> </div>
            <div>${changePointerName(point.point.name)}</div>
          </div>

          <div class='geo-metric'>
            <div>${convertMetricToDisplayValue(retailer, point.point.value as number, metricType, '$')}</div>
        </div>`;
  }
};
