import { FetchAdditionalDataFn } from './types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { RetailersDataFetcher } from 'src/components/AdManager/Search/GridDataFetchers/Retailer/RetailersDataFetcher';

const useFetchRetailerData = (importantOverrides): FetchAdditionalDataFn => {
  const dispatch = useDispatch();

  return useCallback(
    async ({ dataSet, pageNumber, fetchMetricsParams }) => {
      const retailersDataFetcher = new RetailersDataFetcher();

      const finalResult = await retailersDataFetcher.fetchRetailersData({
        dispatch,
        fetchMetricsParams,
        dataSet,
        pageNumber,
        cancelSource: undefined,
        returnEntitiesWithNoMetrics: false,
        importantOverrides
      });
      return finalResult;
    },
    [dispatch]
  );
};

export default useFetchRetailerData;
