/* eslint-disable react/prop-types */
import React from 'react';
import TextField from '@mui/material/TextField';
import _isEmpty from 'lodash/isEmpty';

import SearchItem from './SearchItem';
import { warn } from 'src/utils/mixpanel';
import { AutoSizer, List as VirtualizedList } from 'react-virtualized';

interface SearchListProps {
  currentSelectedItem: unknown;
  handleSearchItemClick: (value: any, name: string) => void;
  searchListOptions: { [key: string]: { id: string }[] };
  handleSearchInputChange: (evt: React.ChangeEvent, name: string) => void;
  queryParams: { [key: string]: string };
  entityDefinition: { type: string };
  pageLayout: { [key: string]: any };
}

type SingleSearchListProps = {
  opt: { name: string; displayName: string };
} & Pick<
  SearchListProps,
  'currentSelectedItem' | 'handleSearchItemClick' | 'searchListOptions' | 'handleSearchInputChange' | 'queryParams'
>;

const SingleSearchList: React.FC<SingleSearchListProps> = ({
  opt,
  currentSelectedItem = {},
  handleSearchItemClick,
  handleSearchInputChange,
  searchListOptions,
  queryParams
}) => {
  const options = searchListOptions[opt.name];
  if (!options) {
    return null;
  }

  return (
    <div key={opt.name} style={{ marginBottom: 20 }}>
      <TextField
        variant="standard"
        autoComplete="off"
        className="sl-form-input"
        placeholder={`Search ${opt.displayName.toLowerCase()}`}
        type="text"
        name="keyword"
        id="keyword"
        style={{ width: '100%', marginBottom: 10 }}
        onChange={(evt: React.ChangeEvent) => handleSearchInputChange(evt, opt.name)}
      />
      {_isEmpty(options) ? (
        `No ${opt.name === 'mySegments' ? 'segments' : opt.displayName.toLowerCase()} found`
      ) : (
        <div key={opt.name} style={{ display: 'flex', flexFlow: 'row wrap' }}>
          {/* VirtualizedList when > 2000 items only */}
          {options.length > 2000 ? (
            <AutoSizer disableHeight>
              {({ width }) => (
                <VirtualizedList
                  width={width}
                  height={300}
                  rowCount={options.length}
                  rowHeight={45}
                  style={{ marginBottom: 10 }}
                  rowRenderer={({ index }: { index: number }) => {
                    const val = options[index];
                    if (!val) {
                      warn(`Val was nil in \`SearchList\``, { options });
                      return null;
                    }

                    return (
                      <SearchItem
                        key={val.id}
                        val={val}
                        selectedItem={currentSelectedItem}
                        onClick={(value: any) => handleSearchItemClick(value, opt.name)}
                        queryParams={queryParams}
                      />
                    );
                  }}
                />
              )}
            </AutoSizer>
          ) : (
            options.map((val) => {
              if (!val) {
                warn(`Val was nil in \`SearchList\``, { options });
                return null;
              }

              return (
                <SearchItem
                  key={val.id}
                  val={val}
                  selectedItem={currentSelectedItem}
                  onClick={(value: any) => handleSearchItemClick(value, opt.name)}
                  queryParams={queryParams}
                />
              );
            })
          )}
        </div>
      )}
    </div>
  );
};

const SearchList: React.FC<SearchListProps> = ({ entityDefinition, pageLayout, ...rest }) => (
  <div className="summary-page__search-container">
    {pageLayout[entityDefinition.type].search.map((opt: { name: string; displayName: string }) => (
      <SingleSearchList key={opt.name} {...rest} opt={opt} />
    ))}
  </div>
);

export default SearchList;
