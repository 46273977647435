import { Omit } from 'src/types/utils';
import queryString from 'qs';
import { prefillFormData } from 'src/utils/segments';
import _get from 'lodash/get';
import { IFormDataToUse } from 'src/components/AdManager/Search/Models/IFormDataToUse';
import { QUERY_STRING_ARRAY_LIMIT } from '../constants';
import { Props } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';

export function buildFormDataAndQueryParams(
  props: Omit<Props, 'widget'>,
  useInternalState: any,
  formData: FormData,
  app: any,
  isWalmart: boolean
) {
  let formDataToUse: IFormDataToUse;
  const queryParams = queryString.parse(props.location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: QUERY_STRING_ARRAY_LIMIT
  });
  if (useInternalState) {
    formDataToUse = formData;
  } else {
    formDataToUse = prefillFormData({ queryParams, appName: app.name });
  }
  if (isWalmart) {
    const targetStatus = _get(formDataToUse, ['termFilters', 'targetState', 'values'], []);
    const campaignProductStatus = _get(formDataToUse, ['termFilters', 'campaignProductState', 'values'], []);
    if (targetStatus.find((item: any) => item.i === 'paused')) {
      formDataToUse.termFilters.targetState.values.push({ i: 'disabled', n: 'Disabled' });
    }
    if (campaignProductStatus.find((item: any) => item.i === 'paused')) {
      formDataToUse.termFilters.campaignProductState.values.push({ i: 'disabled', n: 'Disabled' });
    }
  }
  return { formDataToUse, queryParams };
}
