// File to handle all string constants. Use [nameofSection_itemInCamelCase]

const _loggingIn = 'Logging in...';
const _login = 'Login';
const _update = 'Update';
const _updating = 'Updating...';
const _updated = 'Updated!';
const _submit = 'Submit';

export default {
  //  ====== USER ACCOUNT =======

  account_profile: 'Profile',
  account_firstName: 'First Name',
  account_lastName: 'Last Name',
  account_company: 'Company',
  account_position: 'Position',
  account_phoneNumber: 'Phone Number',
  account_buttonUpdate: _update,
  account_buttonUpdating: _updating,
  account_buttonUpdated: _updated,

  account_billingTitle: 'Billing',
  account_billingExpirationMonth: 'Expiration Month',
  account_billingExpirationYear: 'Expiration Year',
  account_billingAddress: 'Billing Address',
  account_passwordIssueMessage: 'Your current password is incorrect, please try again',

  // ======= BRANDS ========
  brands_noneFound: 'No brands found',

  // ======= RETAILERS ========
  retailers_noneFound: 'No retailers found',
  // ======= CATEGORIES ========
  categories_noneFound: 'No categories found',

  // ======= PROMOTYPES ========
  promoTypes_noneFound: 'No deal types found',

  // ======= SUBCATEGORIES ========
  subCategories_noneFound: 'No subcategories found',

  // ======= SEGMENTS ========
  segments_noneFound: 'No segments found',

  // ======= Ad Entities ========
  adEntities_noneFound: 'No entities were found',

  // ======= Ad Portfolios ========
  adPortfolios_noneFound: 'No portfolios were found',

  // ======= Ad Campaign ========
  adCampaigns_noneFound: 'No campaigns were found',

  // ======= Ad Campaign Type ========
  adCampaignTypes_noneFound: 'No ad types were found',

  // ======= Ad Campaign State ========
  adCampaignStates_noneFound: 'No campaign status were found',

  // ======= Ad Portfolio State ========
  adPortfolioStates_noneFound: 'No portfolio status were found',

  // ======= Ad Automation Strategy ========
  adAutomationStrategies_noneFound: 'No automation strategies were found',

  // ======= Ad Target Match Type ========
  adTargetMatchTypes_noneFound: 'No match types were found',

  // ======= Ad Target State ========
  adTargetStates_noneFound: 'No target status were found',

  // ======= Negative Keywords ========
  adNegativeKeywords_noneFound:
    'Currently, there are no negative keywords in this campaign. Use the form on the left side of this screen to add negative keywords, which will be excluded from your campaign.',
  adNegativeKeywords_placeholder: 'Enter list of keywords and separate each item with a new line',

  // ======= Ad Campaign Product State ========
  adCampaignProductStates_noneFound: 'No product status were found',

  // ====== GENERAL MESSAGES =========
  scroll_toViewMore: 'scroll to view more',

  // ======= PRODUCTS ==========
  products_search_noneFound: 'No products were found',

  // ======= CHART ==========
  chart_noChartDataAvailable: 'No chart data available',

  // ======== BUTTONS ========
  button_loggingIn: _loggingIn,
  button_login: _login,
  button_resetPassword: 'Reset Password',
  button_resettingPassword: 'Resetting...',
  button_reset: 'Reset',
  button_createAccount: 'Create Account',
  button_creatingAccount: 'Creating Account',
  button_accountCreated: 'Created Account',
  button_submit: _submit,
  button_submitting: 'Submitting...',
  button_submitted: 'Submitted!',

  // ======= ERROR MESSAGES =======
  errorMessage_login_noEmailPassword: 'Please enter a valid email and password',
  errorMessage_basic: 'There has been an error please try again',
  // ====== PASSWORDS =======
  password_reset_success: 'You have successfully reset your password.',
  password_expired_reset_otp_message: 'Your password has expired. We have emailed a one-time code to ',
  password_expired_reset_instruction_message:
    'Once you receive the one-time code from your email, please click on continue to reset your password.',
  password_reset_otp_message: 'We have emailed a one-time code to ',
  password_reset_instruction_message: 'When you have the one-time code from the email ready, hit continue',
  password_reset_codeExpired: 'The reset code expired.',
  password_invalid: 'Invalid password',
  password_invalid_secondPassword: 'Re-typed password does not match',
  password_reset_failed: 'Reset password failed. Please retry or contact support',
  passwords_mustMatch: 'Please make passwords match',
  password_new_user_sign_up: 'This email is not currently active. We have emailed the activation link to '
};
