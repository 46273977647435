const RECEIVE_ALL_OMNI_DYNAMIC_FILTER = 'src/omniDynamicFilter/RECEIVE_ALL_OMNI_DYNAMIC_FILTER' as const;
const START_FETCH = 'src/omniDynamicFilter/START_FETCH' as const;
const END_FETCH = 'src/omniDynamicFilter/END_FETCH' as const;

export interface OmniBrand {
  brandId: string;
  brandName: string;
}

export interface OmniCategory {
  categoryId: string;
  categoryName: string;
}

export interface OmniSubCategory {
  subCategoryId: string;
  subCategoryName: string;
}

export interface OmniRetailerInFilter {
  retailerId: number;
}

export interface OmniCountry {
  countryCode: string;
  countryName: string;
}

export interface OmniRegion {
  regionCode: string;
  regionName: string;
}

export interface OmniInstacartRetailer {
  instacartRetailerId: string;
}

export default { START_FETCH, RECEIVE_ALL_OMNI_DYNAMIC_FILTER, END_FETCH };
