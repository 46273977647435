import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import axios from 'axios';
import CircleComponent from 'src/components/Omni/OmniShareOfShelf/CircleComponent';
import 'src/components/Omni/OmniShareOfShelf/ShareOfShelfContainer.scss';
import _capitalize from 'lodash/capitalize';
import { Link } from 'react-router-dom';

interface ShareOfShelfContainerProps {
  retailerId: string;
  data: any[];
}

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    width: '291px',
    marginTop: '60px',
    fontFamily: 'Roboto'
  },
  header: {
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    marginBottom: '40px'
  },
  headerTextStyle: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  circleContainer: {
    display: 'flex',
    height: '291px',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  nameTable: {
    marginTop: '30px'
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    fontSize: '18px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  }
};

const ShareOfShelfContainer: React.FC<ShareOfShelfContainerProps> = ({
  retailerId,
  data
}: ShareOfShelfContainerProps) => {
  const [isFetching, setFetching] = useState(true);
  const [showingData, setShowingData] = useState([]);
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const app = useSelector((state: ReduxStore) => state.app);
  const { searchParams, tab, subtab } = app.queryParams;
  const { availableRetailers } = retailer;
  const retailerObj = availableRetailers.find((element) => element.id === retailerId);
  const retailerName = retailerObj ? retailerObj.displayName : retailerId;
  const navRetailerId = retailerId.includes('63') ? '63' : retailerId;

  useEffect(() => {
    setFetching(true);
    const entityIds = [];
    let rest = 100;

    data.forEach((aData) => {
      rest -= aData.value;
      entityIds.push(Number(aData.brandId));
    });
    const baseRequestBody = {
      entityType: 'brand',
      entityIds
    };
    axios
      .post('/omni/entityMetadata/bulk', baseRequestBody)
      .then((response) => {
        if (response.status === 200) {
          const { data: brandNameObj } = response.data;

          const newShowingData = data.map((aData) => {
            const findBrandNameObj = brandNameObj.find(
              (aBrandObj) => String(aBrandObj.brandId) === String(aData.brandId)
            );
            const brandName = findBrandNameObj ? findBrandNameObj.brandName : aData.brandId;
            return {
              ...aData,
              brandName
            };
          });
          newShowingData.push({ brandId: 'Other', brandName: 'Other', value: rest, color: '#adbac6' });
          setShowingData(newShowingData);
          setFetching(false);
        } else {
          throw new Error('Request failed');
        }
      })
      .catch((err) => console.warn(err));
  }, [data]);

  const showingCircleData = [];
  // Since we are rounding up or down, sometimes the circle will be more than 100
  showingData.forEach((aData) => {
    for (let i = 0, n = Math.round(aData.value); i < n && showingCircleData.length < 100; i++) {
      showingCircleData.push({
        ...aData,
        key: `${aData.brandId}${i}`
      });
    }
  });

  // TODO: choose a loading here
  if (isFetching) {
    return <div></div>;
  }

  return (
    <div style={styles.root} className="shareOfShelfContainer">
      <div style={styles.header}>
        <Link to={`/retailer/${navRetailerId}${searchParams}&tab=${tab}&subtab=${subtab}`}>
          <span style={styles.headerTextStyle}>{retailerName}</span>
        </Link>
      </div>
      <div style={styles.circleContainer}>
        {showingCircleData.map((aShowingData) => (
          <CircleComponent key={aShowingData.key} color={aShowingData.color} />
        ))}
      </div>
      <div style={styles.nameTable}>
        {showingData.map((aData) => {
          return (
            <div style={{ ...styles.nameContainer, color: aData.color }} key={aData.brandId}>
              <span>{_capitalize(aData.brandName)}</span>
              <span>{`${aData.value.toFixed(2)}%`}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ShareOfShelfContainer;
