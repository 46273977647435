import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'qs';
import { Box } from '@mui/system';

import i18n from 'src/i18n_en';
import { trackLogIn } from 'src/utils/mixpanel';
import { authOperations } from 'src/store/modules/auth';
import 'src/components/SignIn/SignIn.scss';
import BeaconSignInContainer from 'src/components/SignIn/BeaconSignIn';
import SignInInputField from 'src/components/SignIn/SignInInputField';
import './SignUp.scss';
import fontStyle from 'src/utils/fontStyle';
import LoggingIn from './LoggingIn';
import ProfilePictureWithUpload from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Profile/ProfilePictureWithUpload';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import * as serverProxy from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/serverProxy';
import AddIcon from '@mui/icons-material/Add';
import LogoContainer from '../SignIn/LogoContainer';
import AuthTitle from '../SignIn/AuthTitle';
import AuthContainer from '../SignIn/AuthContainer';
import AuthButton from '../SignIn/AuthButton';
import { Text } from '@stackline/ui';
import { EMAIL_VALIDATOR_REGEX } from 'src/utils/validators';
import { AnimatedEllipsis } from 'src/components/common/Loading/AnimatedEllipsis';

const styles = {
  tosWrapper: {
    width: 295,
    fontWeight: fontStyle.regularWeight,
    fontSize: 14,
    margin: 'auto',
    textAlign: 'left',
    color: '#fff'
  },
  signInButtonWrapper: {
    marginTop: 24,
    marginBottom: 16,
    textAlign: 'center'
  }
};

class SignUp extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getUserByInviteCode: PropTypes.func.isRequired,
    signUpByInvite: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    clearErrorMessage: PropTypes.func.isRequired
  };

  formData = {};

  state = {
    buttonText: i18n.button_createAccount,
    isFormSubmitted: false,
    isPasswordConfirmationSame: true,
    showSuccessfulLogin: false,
    onProfilePhotoPage: false,
    profilePicture: null
  };

  componentDidMount() {
    const { getUserByInviteCode, location, history } = this.props;
    const { code } = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });

    getUserByInviteCode(window.encodeURIComponent(code))
      .then((response) => {
        if (response.data === 'signed') {
          history.push('/sign_in');
        } else {
          this.setState({
            code,
            email: response.data
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  // TODO: move to util fxn
  validatePassword = (password) => {
    return password.length > 7;
  };

  handlePasswordChange = (password) => {
    this.props.clearErrorMessage(null);
    this.setState({
      password
    });
  };

  handlePasswordConfirmationChange = (password) => {
    this.props.clearErrorMessage(null);
    this.setState({
      passwordConfirmation: password
    });
  };

  /**
   * Make sure passwords meet requirements and show error message if not.
   * Returns true if everything is valid.
   */
  validateForm = () => {
    const isEmailValid = EMAIL_VALIDATOR_REGEX.test(this.state.email);
    const isPasswordValid = this.state.password && this.validatePassword(this.state.password);
    const isPasswordConfirmationSame = this.state.password === this.state.passwordConfirmation;

    const isValid = isEmailValid && isPasswordConfirmationSame && isPasswordValid;
    if (!isValid) {
      if (!isPasswordConfirmationSame) {
        this.props.setErrorMessage("Passwords don't match. Please try again.");
      } else if (!isPasswordValid) {
        this.props.setErrorMessage('Password must be at least 8 characters.');
      }
    }
    return isValid;
  };

  handleButtonTap = (requestBodyAdditionalParams = {}) => {
    const { signUpByInvite, history } = this.props;
    const { code, email, password, passwordConfirmation } = this.state;
    this.setState({ isFormSubmitted: true });
    if (!this.validateForm()) {
      return;
    }

    this.setState({ buttonText: i18n.button_creatingAccount });
    const requestBody = {
      code,
      email,
      password,
      confirmPassword: passwordConfirmation,
      agreed: true,
      ...requestBodyAdditionalParams
    };
    signUpByInvite(requestBody)
      .then((response) => {
        const { status, redirect, redirectType } = response;
        if (status === 'bad') {
          this.setState({ buttonText: i18n.button_createAccount });
        } else if (status === 'good') {
          if (redirectType === 'window') {
            this.setState({ buttonText: i18n.button_accountCreated });
            setTimeout(() => {
              window.location.href = redirect;
            }, 1000);
            return;
          } else if (redirectType === 'local') {
            this.setState({ buttonText: i18n.button_accountCreated });
            if (redirect === '/') {
              setTimeout(() => this.setState({ showSuccessfulLogin: true }), 1000);
            } else if (redirect === '/subscribe') {
              history.push(`${redirect}`);
            }
          }
          trackLogIn();
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  /**
   * Validate the form and if it's valid, go to the profile
   * photo upload page.
   */
  handleContinueToImageUpload = () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({ onProfilePhotoPage: true });
  };

  showPasswordConfirmationError = () => {
    const { isFormSubmitted, isPasswordConfirmationSame } = this.state;

    if (!isFormSubmitted || (isFormSubmitted && isPasswordConfirmationSame)) {
      return null;
    }

    return i18n.passwords_mustMatch;
  };

  render() {
    const { buttonText, showSuccessfulLogin } = this.state;
    const { app } = this.props;
    const { message } = this.props.auth;

    const Container = BeaconSignInContainer;

    if (showSuccessfulLogin) {
      return (
        <Container appName={app.name}>
          <LoggingIn />
        </Container>
      );
    }

    const profileUpload = (
      <Container appName={app.name} notificationMSG={message}>
        <AuthContainer>
          <LogoContainer title={app.displayName} />
          <p
            style={{
              fontSize: '34px',
              fontWeight: 'bold',
              marginTop: '24px',
              marginBottom: '48px',
              textAlign: 'center'
            }}
          >
            Upload profile photo
          </p>
          <Flex justifyContent="center" marginBottom="48px">
            <ProfilePictureWithUpload
              email={this.state.email}
              appProfileIconProps={{
                size: 256,
                backgroundColor: '#f6f8fa'
              }}
              fabSize={56}
              uploadImage={(file, email) => {
                this.setState({ profilePicture: file });
                serverProxy.userPreSignedUrl(file, email);
                this.props.clearErrorMessage(null);
              }}
              fabIcon={<AddIcon sx={{ color: 'white', fontSize: '32px' }} />}
            />
          </Flex>
          <AuthButton
            type="submit"
            onClick={() => {
              if (!this.state.profilePicture) {
                this.props.setErrorMessage('Please upload a profile photo to complete your account setup.');
                return;
              }
              this.handleButtonTap({ userDocUpload: true });
            }}
          >
            {this.state.isFormSubmitted ? (
              <Box display="flex" flexDirection="row" maxHeight="100%" alignItems="center" justifyContent="center">
                {buttonText}
                <AnimatedEllipsis width={40} height={40} style={{ margin: 0, transform: 'translate(-8px,5px)' }} />
              </Box>
            ) : (
              <>{buttonText}</>
            )}
          </AuthButton>
        </AuthContainer>
      </Container>
    );

    const continueToImageUploadButton = <AuthButton onClick={this.handleContinueToImageUpload}>Continue</AuthButton>;

    if (this.state.onProfilePhotoPage) {
      return profileUpload;
    }

    return (
      <Container appName={app.name} notificationMSG={message}>
        <AuthContainer>
          <LogoContainer title={app.displayName} />

          <AuthTitle marginTop="16px" marginBottom="24px">
            Let&apos;s get started!
          </AuthTitle>

          <Box display="flex" width="100%" flexDirection="column" gap="24px" marginTop="8px">
            {this.state.email && <SignInInputField label="Email" disabled value={this.state.email} />}
            <SignInInputField
              value={this.state.password}
              onChange={(e) => this.handlePasswordChange(e.target.value)}
              label="Password"
              type="password"
              autoComplete="new-password"
              disabled={this.state.isFormSubmitted}
            />

            <SignInInputField
              value={this.state.passwordConfirmation}
              onChange={(e) => this.handlePasswordConfirmationChange(e.target.value)}
              label="Confirm Password"
              type="password"
              autoComplete="new-password"
              disabled={this.state.isFormSubmitted}
            />
            <div className="signup-password-rules-container">
              <Text variant="subtitle2">Passwords should have:</Text>
              <ul style={{ paddingLeft: '16px', marginTop: '8px', fontSize: '14px' }}>
                <li>at least 8 characters</li>
                <li style={{ marginTop: '4px' }}>at least one lowercase letter</li>
              </ul>
            </div>
          </Box>

          <div style={styles.signInButtonWrapper}>{continueToImageUploadButton}</div>
        </AuthContainer>
      </Container>
    );
  }
}

const mapStateToProps = ({ app, auth }) => ({ app, auth });

const mapDispatchToProps = {
  getUserByInviteCode: authOperations.getUserByInviteCode,
  signUpByInvite: authOperations.signUpByInvite,
  setErrorMessage: authOperations.setErrorMessage,
  clearErrorMessage: authOperations.clearMessage
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
