/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _get from 'lodash/get';

import DataColumn from 'src/components/AdManager/Search/CustomColumns/DataColumn';
import CampaignColumn from 'src/components/AdManager/Search/CustomColumns/EntityColumn';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import {
  SectionWrapper,
  OperationButtons
} from 'src/components/AdCampaignBuilder/Widgets/AdCampaignBuilderCommonWidgets';
import {
  AmsBudgetColumn,
  StacklineBudgetColumn,
  BudgetDifferentColumn
} from 'src/components/AdBudgetEditor/Steps/PortfolioAlign';
import ReduxStore from 'src/types/store/reduxStore';

const baseColDef = {
  disableSort: true,
  width: 100
};

const onGridReady = (params: any) => {
  params.api.sizeColumnsToFit();
};

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, ['app', 'retailer', 'adCampaigns', 'adBudgetEditor']),
    mainEntity: state.entityService.mainEntity
  };
};

const PortfolioAlign: React.FC<{ classes: { [key: string]: any } } & ReturnType<typeof mapStateToProps>> = ({
  adBudgetEditor
}) => {
  const columnDefs = [
    {
      headerName: 'Campaign',
      field: 'campaign',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 300,
      maxWidth: 800,
      cellRendererFramework: CampaignColumn,
      headerClass: 'align-left'
    },
    {
      headerName: 'Ad Spend',
      cellRendererFramework: DataColumn,
      field: 'spend'
    },
    {
      headerName: 'Ad Sales',
      cellRendererFramework: DataColumn,
      field: 'sales'
    },
    {
      headerName: 'ROAS',
      cellRendererFramework: DataColumn,
      field: 'returnOnAdSpend'
    },
    {
      headerName: 'AMS Budget',
      cellRendererFramework: AmsBudgetColumn,
      field: 'portfolio'
    },
    {
      headerName: 'Stackline Budget',
      cellRendererFramework: StacklineBudgetColumn,
      field: 'portfolio'
    },
    {
      headerName: 'Difference',
      cellRendererFramework: BudgetDifferentColumn,
      field: 'portfolio'
    }
  ].map(({ headerName, ...colDef }) => ({
    ...baseColDef,
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' },
    ...colDef,
    displayName: headerName
  }));

  if (_isEmpty(adBudgetEditor.platform)) {
    return null;
  }

  const filterCampaign = (adBudgetEditor.campaignArray || []).filter((item) => {
    // filter out all the portfolio that belongs to the selected entity
    // and have more that one campaign
    return (
      item.entity.extendedAttributes.entityId === adBudgetEditor.entity.selectedEntityId &&
      _get(item.entity.extendedAttributes, ['currentMonthBudgetSetting', 'amount'], null) !==
        _get(item.entity.extendedAttributes, ['liveBudgetSetting', 'amount'], null)
    );
  });

  return (
    <div className="ad-manager-container">
      <OperationButtons subtab="campaignAlign" canContinue customFlow={adBudgetEditor.flow} />
      <SectionWrapper
        header="Campaign Budgets"
        subheader="The table below compares budgets from Amazon’s Ad Console to those in Stackline’s Ad Manager."
        layer={0}
      >
        <div className="platform-select">
          <CustomAgMaterial
            onGridReady={onGridReady}
            onCellValueChanged={onGridReady}
            onModelUpdated={onGridReady}
            onRowValueChanged={onGridReady}
            onRowDataChanged={onGridReady}
            buildRows={() => filterCampaign}
            columnDefs={columnDefs}
            // deltaRowDataMode
            getRowNodeId={(datum: any) => escape(datum.id)}
            domLayout="autoHeight"
            rowHeight={120}
            containerStyle={{ width: '100%' }}
          />
        </div>
      </SectionWrapper>
    </div>
  );
};

const EnhancedPlatform = connect(mapStateToProps)(PortfolioAlign);

export default EnhancedPlatform;
