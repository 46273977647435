// This is the Top level container that contains a Summary Tile Search Bar and all Summary Tile items.

import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { HeaderSearchIcon } from 'src/components/SvgIcons';
import Chip from '@mui/material/Chip';
import colors from 'src/utils/colors';
import EntityIconButtonGroup from 'src/components/common/Buttons/EntityIconButtonGroup';
import './SummaryTileSearchbar.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    color: colors.darkBlue,
    background: colors.lightestGrey,
    padding: theme.spacing(0.5),
    fontSize: 14
  }
}));

const SummaryTileSearchbar = ({ keyword, onKeywordChange, placeholderText, handleChangeLayout, currentLayout }) => {
  const classes = useStyles();
  const handleKeyPress = (evt) => {
    if (evt.key === 'Enter') {
      onKeywordChange(evt.target.value);
    }
  };

  const clearKeyword = () => {
    onKeywordChange('');
  };

  return (
    <div className="searchbar_container">
      <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        {currentLayout === 'tile' ? (
          <>
            <button className="sl-header__search-button">
              <HeaderSearchIcon className="sl-header__search-icon" />
            </button>
            {keyword ? (
              <Chip label={keyword} onDelete={clearKeyword} className={classes.root} />
            ) : (
              <input className="search-input" onKeyPress={(evt) => handleKeyPress(evt)} placeholder={placeholderText} />
            )}
          </>
        ) : null}
      </div>

      <EntityIconButtonGroup defaultView={currentLayout} handleChangeLayout={handleChangeLayout} />
    </div>
  );
};

SummaryTileSearchbar.propTypes = {
  keyword: PropTypes.string.isRequired,
  currentLayout: PropTypes.string.isRequired,
  handleChangeLayout: PropTypes.func.isRequired,
  onKeywordChange: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired
};

export default SummaryTileSearchbar;
