import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import queryString from 'qs';

import ColumnChartContainer from 'src/components/Charts/Column';
import { getChartDisplayTimePeriod } from '../../Renderer/EntityPageRenderer';
import { renderWeeklyTrendChart } from '../../Renderer/WeeklyTrendChart';
import { DonutChart } from 'src/components/Charts/Donut';
import WidgetGroup from '../../WidgetGroup/WidgetGroup';
import ReviewsGridContainer from 'src/components/EntityGrid/Table/ReviewsGridContainer';
import { BareWidget } from 'src/components/EntityPage/Widget';
import HighRiskReviews from '../HighRiskReviews';
import ReviewsContainer from 'src/components/EntityPage/Reviews';
import { withDisplayName } from 'src/utils/hoc';
import { GenericChartLoading, GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';

const arrayOrObjectPropType = PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired]);

const DonutChartContent = ({
  widget,
  widget: { view },
  retailer,
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod,
  donutMetrics,
  handleStarValuesChange,
  isLoading
}) => (
  <div key={view.name} className="review-metrics__donut">
    {isLoading ? (
      <GenericChartLoading />
    ) : (
      <DonutChart
        isStarChart
        chartDisplayTimePeriod={chartDisplayTimePeriod}
        chartComparisonDisplayTimePeriod={chartComparisonDisplayTimePeriod}
        mainEntityMetrics={donutMetrics}
        onDonutChartChange={handleStarValuesChange}
        widget={widget}
        retailer={retailer}
      />
    )}
  </div>
);

DonutChartContent.propTypes = {
  widget: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired,
  chartDisplayTimePeriod: PropTypes.object.isRequired,
  chartComparisonDisplayTimePeriod: PropTypes.object.isRequired,
  donutMetrics: arrayOrObjectPropType.isRequired,
  handleStarValuesChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const WeeklyTrendChartContent = ({
  widget: { data, view },
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod,
  chartMetrics,
  pageLayout,
  comparisonConfig,
  isLoading
}) => {
  if (_isEmpty(chartMetrics)) {
    return null;
  }
  return isLoading ? (
    <GenericChartLoading />
  ) : (
    <div key={view.name} className="review-metrics__line">
      <div>
        {renderWeeklyTrendChart(
          view,
          chartDisplayTimePeriod,
          chartComparisonDisplayTimePeriod,
          chartMetrics,
          chartMetrics,
          {
            metricName: data.chartMainField.name,
            indexName: pageLayout.dataConfig.indexName
          },
          data.groupByField.name,
          {
            ...comparisonConfig,
            metricName: data.chartMainField.name
          }
        )}
      </div>
    </div>
  );
};

WeeklyTrendChartContent.propTypes = {
  widget: PropTypes.object.isRequired,
  chartDisplayTimePeriod: PropTypes.object.isRequired,
  chartComparisonDisplayTimePeriod: PropTypes.object.isRequired,
  chartMetrics: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired]).isRequired,
  pageLayout: PropTypes.object.isRequired,
  comparisonConfig: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const ColumnChartContent = ({
  highRiskChartMetrics,
  widget,
  widget: { view },
  retailer,
  selectedHighRiskWeekId,
  handleWeekIdChange,
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod
}) => {
  if (_isEmpty(highRiskChartMetrics)) {
    return null;
  }
  return (
    <div key={view.name} className="column-chart">
      <div className="column-chart__container">
        <ColumnChartContainer
          chartDisplayTimePeriod={chartDisplayTimePeriod}
          chartComparisonDisplayTimePeriod={chartComparisonDisplayTimePeriod}
          mainEntityMetrics={highRiskChartMetrics}
          onPointSelect={handleWeekIdChange}
          widget={widget}
          selectedPoint={selectedHighRiskWeekId}
          retailer={retailer}
        />
      </div>
    </div>
  );
};

ColumnChartContent.propTypes = {
  highRiskChartMetrics: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.object.isRequired]).isRequired,
  widget: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired,
  selectedHighRiskWeekId: PropTypes.any,
  handleWeekIdChange: PropTypes.func.isRequired,
  chartDisplayTimePeriod: PropTypes.object.isRequired,
  chartComparisonDisplayTimePeriod: PropTypes.object.isRequired
};

ColumnChartContent.defaultProps = {
  selectedHighRiskWeekId: undefined
};

const PageContextMenuContent = ({ CustomComponent, widget }) => {
  if (!CustomComponent) {
    return null;
  }

  return <CustomComponent key="reviews_download" widget={widget} />;
};

PageContextMenuContent.propTypes = {
  widget: PropTypes.object.isRequired,
  CustomComponent: PropTypes.func
};

PageContextMenuContent.defaultProps = {
  CustomComponent: undefined
};

const ReviewsGridContent = ({
  widget,
  selectedHighRiskWeekId,
  mainEntityConditions,
  reviewStars,
  pageLayout,
  filteredKeywords,
  highRiskReviews,
  querySubtab,
  isLoading
}) => {
  const { data, view } = widget;

  if (querySubtab !== 'reviewTrends' && !(querySubtab === 'highRiskReviews' && highRiskReviews !== undefined)) {
    return null;
  }

  const keywordList =
    querySubtab === 'reviewTrends'
      ? filteredKeywords
      : highRiskReviews && highRiskReviews.keywords
      ? highRiskReviews.keywords
      : null;

  const keyWordQueryObj =
    querySubtab === 'reviewTrends'
      ? {
          fieldName: 'reviewText',
          queryType: 'match',
          values: keywordList
        }
      : {
          fieldName: 'reviewText',
          condition: 'should',
          values: keywordList
        };

  return isLoading ? (
    <GridLoading />
  ) : (
    <ReviewsGridContainer
      key={view.name}
      selectedMetric={data.mainMetricField}
      selectedEntity={data.entity}
      selectedHighRiskWeekId={selectedHighRiskWeekId}
      queryConditions={mainEntityConditions}
      dataConfig={pageLayout.dataConfig}
      reviewStars={reviewStars}
      keywords={keywordList}
      keyWordQueryObj={keyWordQueryObj}
      widget={widget}
    />
  );
};

ReviewsGridContent.propTypes = {
  widget: PropTypes.object.isRequired,
  selectedHighRiskWeekId: PropTypes.any,
  mainEntityConditions: PropTypes.any.isRequired,
  reviewStars: PropTypes.any.isRequired,
  pageLayout: PropTypes.any.isRequired,
  filteredKeywords: PropTypes.any.isRequired,
  highRiskReviews: PropTypes.any.isRequired,
  querySubtab: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};

ReviewsGridContent.defaultProps = {
  selectedHighRiskWeekId: undefined
};

const HighRiskReviewsContent = ({ querySubtab, highRiskReviews }) => {
  if (querySubtab !== 'highRiskReviews') {
    return null;
  }

  return <HighRiskReviews highRiskReviews={highRiskReviews} />;
};

HighRiskReviewsContent.propTypes = {
  querySubtab: PropTypes.string.isRequired,
  highRiskReviews: PropTypes.any.isRequired
};

/**
 * Maps the name of a widget `widget.view.name` to the list of keys of props that it needs to render
 */
const propNamesByView = {
  donutChart: [
    'widget',
    'retailer',
    'chartDisplayTimePeriod',
    'chartComparisonDisplayTimePeriod',
    'donutMetrics',
    'handleStarValuesChange',
    'isLoading'
  ],
  weeklyTrendChart: [
    'widget',
    'chartDisplayTimePeriod',
    'chartComparisonDisplayTimePeriod',
    'chartMetrics',
    'pageLayout',
    'comparisonConfig',
    'isLoading'
  ],
  columnChart: [
    'highRiskChartMetrics',
    'widget',
    'retailer',
    'selectedHighRiskWeekId',
    'handleWeekIdChange',
    'chartDisplayTimePeriod',
    'chartComparisonDisplayTimePeriod'
  ],
  pageContextMenu: ['CustomComponent', 'widget'],
  reviewsGrid: [
    'widget',
    'selectedHighRiskWeekId',
    'mainEntityConditions',
    'reviewStars',
    'pageLayout',
    'filteredKeywords',
    'highRiskReviews',
    'querySubtab',
    'isLoading'
  ],
  highRiskReviews: ['highRiskReviews', 'querySubtab'],
  widget_group: null
};

/**
 * Renders a `WidgetGroup` that internally uses the `ReviewMetricsContainerContent` component to render the individual
 * widgets.
 *
 * @param {object} props
 */
const ReviewMetricsWidgetGroupContent = ({ ...props }) => {
  return (
    <WidgetGroup
      queryConditions={{ termFilters: [], rangeFilters: [] }}
      aggregationConditions={{ termFilters: [], rangeFilters: [] }}
      WidgetComponent={
        /* eslint-disable-next-line no-use-before-define */
        ReviewMetricsContainerContent
      }
      i={0}
      {...props}
    />
  );
};

const componentForView = {
  donutChart: DonutChartContent,
  weeklyTrendChart: WeeklyTrendChartContent,
  columnChart: ColumnChartContent,
  pageContextMenu: PageContextMenuContent,
  reviewsGrid: ReviewsGridContent,
  highRiskReviews: HighRiskReviewsContent,
  widget_group: withDisplayName('ReviewMetricsWidgetGroupWrapper')(({ ...props }) => (
    <div style={{ ...props.widget.view.container.style, marginTop: 0 }}>
      <ReviewMetricsWidgetGroupContent {...props} />
    </div>
  ))
};

const mapViewNameToWidgetRenderer = (viewName) => [componentForView[viewName] || BareWidget, propNamesByView[viewName]];

const ReviewMetricsContainerContentInner = ({
  widget,
  mainTimePeriod,
  comparisonTimePeriod,
  location,
  reviewsDonutChartMetrics,
  mainChartMetrics,
  highRiskReviewsChartMetrics,
  ...props
}) => {
  const [RendererForView, propNamesForView] = useMemo(
    () => mapViewNameToWidgetRenderer(widget.view.name),
    [widget.view.name]
  );

  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const chartDisplayTimePeriod = getChartDisplayTimePeriod(mainTimePeriod);
  const chartComparisonDisplayTimePeriod = getChartDisplayTimePeriod(comparisonTimePeriod);
  // Not sure this is needed but not sure how to remove yet
  const comparisonConfig = useMemo(
    () => ({
      type: queryParams.ctype || 'metric',
      metricName: queryParams.csubtab,
      entityId: queryParams.cid,
      entityType: queryParams.ctype,
      indexName: 'reviews'
    }),
    [queryParams.ctype, queryParams.csubtab, queryParams.cid]
  );

  const donutMetrics = useMemo(
    () => (reviewsDonutChartMetrics && !_isEmpty(reviewsDonutChartMetrics) ? reviewsDonutChartMetrics : []),
    [reviewsDonutChartMetrics]
  );
  const chartMetrics = useMemo(
    () => (mainChartMetrics && !_isEmpty(mainChartMetrics) ? mainChartMetrics : []),
    [mainChartMetrics]
  );
  const highRiskChartMetrics = useMemo(
    () => _get(highRiskReviewsChartMetrics, 'dataPointCount_by_weekId', []),
    [highRiskReviewsChartMetrics]
  );

  if (!RendererForView || props.pageLayout.CustomPageContainer !== ReviewsContainer) {
    return null;
  }

  const allProps = {
    ...props,
    widget,
    CustomComponent: widget.CustomComponent,
    comparisonConfig,
    donutMetrics,
    chartMetrics,
    highRiskChartMetrics,
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod
  };

  // If no entry exists in the `propNamesByView` mapping for the current view name, we pass them all through.
  const propsForRenderer = propNamesForView ? _pick(allProps, propNamesForView) : allProps;

  return <RendererForView {...propsForRenderer} />;
};

ReviewMetricsContainerContentInner.propTypes = {
  location: PropTypes.object.isRequired,
  mainTimePeriod: PropTypes.object.isRequired,
  comparisonTimePeriod: PropTypes.object.isRequired,
  widget: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired,
  handleStarValuesChange: PropTypes.func.isRequired,
  pageLayout: PropTypes.object.isRequired,
  selectedHighRiskWeekId: PropTypes.any,
  handleWeekIdChange: PropTypes.func.isRequired,
  CustomComponent: PropTypes.func,
  reviewsDonutChartMetrics: arrayOrObjectPropType,
  mainChartMetrics: arrayOrObjectPropType,
  highRiskReviewsChartMetrics: arrayOrObjectPropType
};

ReviewMetricsContainerContentInner.defaultProps = {
  CustomComponent: undefined,
  selectedHighRiskWeekId: undefined,
  reviewsDonutChartMetrics: undefined,
  mainChartMetrics: undefined,
  highRiskReviewsChartMetrics: undefined
};

const mapReviewMetricsCompStateToProps = ({
  mainTimePeriod,
  comparisonTimePeriod,
  retailer,
  entitySearchService: { reviewsDonutChartMetrics, mainChartMetrics, highRiskReviewsChartMetrics }
}) => ({
  mainTimePeriod,
  comparisonTimePeriod,
  retailer,
  reviewsDonutChartMetrics,
  mainChartMetrics,
  highRiskReviewsChartMetrics
});

const ReviewMetricsContainerContent = withRouter(
  connect(mapReviewMetricsCompStateToProps)(ReviewMetricsContainerContentInner)
);

export default ReviewMetricsContainerContent;
