import React from 'react';
import _get from 'lodash/get';
import _startCase from 'lodash/startCase';
import numeral from 'numeral';
import moment from 'moment';

import './CampaignColumn.scss';
import { getLastDayOfMonth, basicDayFormat } from 'src/utils/dateformatting';
import { splitCamelCase } from 'src/utils/stringFormatting';

const BudgetColumn = ({ data, value }: { data: any; value: any; adCampaigns: any[] }) => {
  if (data.id === 'total_row') {
    return '--';
  }
  if (!value) {
    return null;
  }
  const mainEntity = data.entity;
  const budget = _get(mainEntity, ['extendedAttributes', 'liveBudgetSetting'], null);
  const budgetAmount = _get(mainEntity, ['extendedAttributes', 'liveBudgetSetting', 'amount'], null);

  let budgetType = _get(mainEntity, ['extendedAttributes', 'liveBudgetSetting', 'budgetType'], '');
  budgetType = _startCase(splitCamelCase(budgetType));

  const budgetAmountDisplay = budgetAmount === null ? '--' : numeral(budgetAmount).format('$1,000');

  if (mainEntity.type === 'adCampaign') {
    const projectedSpendAmount = value.value || null;
    const projectedSpendDisplay = projectedSpendAmount === null ? '--' : numeral(projectedSpendAmount).format('$0.00a');
    return (
      <div className="budget_column">
        <div>{`${budgetAmountDisplay} ${budgetType}`}</div>
        <div className="secondary_label">{`${projectedSpendDisplay.toUpperCase()} projected spend in ${moment().format(
          'MMM YYYY'
        )}`}</div>
      </div>
    );
  }

  if (budget) {
    if (budgetType === 'Monthly Recurring') {
      return (
        <div>
          <div>{numeral(budgetAmount).format('$1,000')}</div>
          <div>{budgetType}</div>
          {budget.endDate ? <div>Ends {getLastDayOfMonth(budget.endDate)}</div> : <div>No end date</div>}
        </div>
      );
    }

    return (
      <div>
        <div>{numeral(budgetAmount).format('$1,000')}</div>
        <div>{budgetType}</div>
        <div>
          {budget.startDate ? basicDayFormat(budget.startDate) : 'No Start Date'} -{' '}
          {budget.endDate ? basicDayFormat(budget.endDate) : 'No End Date'}
        </div>
      </div>
    );
  }

  return <div>Not Set</div>;
};

export default BudgetColumn;
