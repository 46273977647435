/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';

import { store } from 'src/main';
import colors from 'src/utils/colors';
import { userOperations } from 'src/store/modules/user';
import UserAccountUpload from './UserAccountUpload/UserAccountUpload';
import ReduxStore from 'src/types/store/reduxStore';

const mapStateToProps = (state: ReduxStore) => _pick(state, ['entityService', 'retailer']);

const UserAccountMapPrice: React.FC<
  {
    getUploadPageDetail: (arg1: string) => {
      searchParams: string;
      hashId: string;
      retailers: any[];
      buttons: any[];
      getLatestApi: unknown;
    };
  } & ReturnType<typeof mapStateToProps>
> = ({ entityService, retailer, getUploadPageDetail }) => {
  if (!entityService.mainEntity) {
    return null;
  }

  const { searchParams, hashId, retailers, buttons, getLatestApi } = getUploadPageDetail('mapprice');
  return (
    <UserAccountUpload
      headerText="MAP Prices"
      retailers={retailers}
      currRetailer={retailer}
      buttons={buttons}
      updateUserAccount={(...args) => store.dispatch(userOperations.updateUserAccount(...args))}
      getLatestApi={getLatestApi}
      uploadType="map-price-upload"
    >
      <div>
        Upload MAP Prices for products in your catalog. After successfully uploading, you can visit the{' '}
        <a
          role="link"
          style={{ color: colors.blue, cursor: 'pointer' }}
          onClick={() => window.location.assign(`/client/${hashId}${searchParams}&tab=sales&subtab=mapPrice`)}
        >
          MAP Price
        </a>{' '}
        page to identify violations.
      </div>
    </UserAccountUpload>
  );
};

export default connect(mapStateToProps)(UserAccountMapPrice);
