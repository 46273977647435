import { AppName } from 'sl-api-connector/types';

import { buildCustomDateRangeDisplayName } from 'src/utils/dateformatting';

import { getAppName } from 'src/utils/app';
import types from './types';
import moment from 'moment-timezone';

export const timePeriodOptions = {
  lastWeek: {
    id: '1w',
    value: 1,
    shortDisplayName: 'Last Week'
  },
  last2Weeks: {
    id: '2w',
    value: 2,
    shortDisplayName: 'Last 2 weeks'
  },
  last4Weeks: {
    id: '4w',
    value: 4,
    shortDisplayName: 'Last 4 Weeks'
  },
  last13Weeks: {
    id: '13w',
    value: 13,
    shortDisplayName: 'Last 13 Weeks'
  },
  last26Weeks: {
    id: '26w',
    value: 26,
    shortDisplayName: 'Last 26 Weeks'
  },
  last52Weeks: {
    id: '52w',
    value: 52,
    shortDisplayName: 'Last 52 Weeks'
  },
  lastMonth: {
    id: 'lastMonth',
    shortDisplayName: 'Last Month'
  },
  last3Months: {
    id: 'last3Months',
    shortDisplayName: 'Last 3 Months'
  },
  select1Month: {
    id: 'select1Month',
    shortDisplayName: 'Select 1 Month',
    onClickDateHandler: (selectedDate) => {
      const monthStart = moment(selectedDate).startOf('month').toDate();
      const monthEnd = moment(selectedDate).endOf('month').toDate();

      return [monthStart, monthEnd];
    }
  },
  select3Months: {
    id: 'select3Months',
    shortDisplayName: 'Select 3 Months',
    onClickDateHandler: (selectedDate, { minDate }) => {
      let startDate = moment(selectedDate).subtract(2, 'months').startOf('month');
      let endDate = moment(selectedDate).endOf('month');

      // If 3 months ago is before the minimum date then
      // return the earliest 3 months after min date
      if (startDate.isBefore(moment(minDate))) {
        startDate = moment(minDate);
        endDate = moment(minDate).add(2, 'months').endOf('month');
      }

      return [startDate.toDate(), endDate.toDate()];
    }
  },
  monthToDate: {
    id: 'mtd',
    shortDisplayName: 'MTD'
  },
  yearToDate: {
    id: 'ytd',
    shortDisplayName: 'YTD'
  },
  lastYear: {
    id: 'ly',
    value: 52,
    shortDisplayName: 'Last Year'
  },
  customDate: {
    id: 'cd',
    shortDisplayName: 'Custom Date'
  }
};

const timePeriodOptionsByAppName = {
  default: [
    timePeriodOptions.lastWeek,
    timePeriodOptions.last4Weeks,
    timePeriodOptions.last13Weeks,
    timePeriodOptions.last26Weeks,
    timePeriodOptions.last52Weeks,
    timePeriodOptions.lastYear,
    {
      ...timePeriodOptions.yearToDate,
      default: true
    },
    timePeriodOptions.customDate
  ],
  [AppName.Advertising]: [
    timePeriodOptions.lastWeek,
    {
      ...timePeriodOptions.monthToDate,
      default: true
    },
    timePeriodOptions.last4Weeks,
    timePeriodOptions.last13Weeks,
    timePeriodOptions.last26Weeks,
    timePeriodOptions.last52Weeks,
    timePeriodOptions.yearToDate,
    timePeriodOptions.customDate
  ],
  [AppName.Omni]: [
    timePeriodOptions.lastWeek,
    {
      ...timePeriodOptions.last4Weeks,
      default: true
    },
    timePeriodOptions.last13Weeks,
    timePeriodOptions.last26Weeks,
    timePeriodOptions.last52Weeks,
    timePeriodOptions.lastYear,
    timePeriodOptions.yearToDate,
    timePeriodOptions.customDate
  ]
};

const currentDayOfMonth = parseInt(moment().format('D'), 10);
if (currentDayOfMonth === 1) {
  timePeriodOptionsByAppName[AppName.Advertising] = timePeriodOptionsByAppName[AppName.Advertising].filter(
    (x) => x.id !== 'mtd'
  );
  timePeriodOptionsByAppName[AppName.Advertising].find((x) => x.id === 'ytd').default = true;
} else if (currentDayOfMonth >= 2 && currentDayOfMonth <= 7) {
  timePeriodOptionsByAppName[AppName.Advertising].find((x) => x.id === 'mtd').default = false;
  timePeriodOptionsByAppName[AppName.Advertising].find((x) => x.id === 'ytd').default = true;
}

export const getInitialState = () => {
  return {
    availableMainTimePeriods: timePeriodOptionsByAppName[getAppName()] || timePeriodOptionsByAppName.default
  };
};

export default (state = getInitialState(), action) => {
  const newAvailableTimePeriods = action.updatedAvailableTimePeriods
    ? action.updatedAvailableTimePeriods
    : state.availableMainTimePeriods;
  const { startWeekStartDate, startDayId, endWeekEndDate, endDayId } = action;
  switch (action.type) {
    case types.UPDATE_MAIN_TIME_PERIOD:
      return {
        ...state,
        id: action.id,
        shortDisplayName: action.shortDisplayName,
        startWeek: action.startWeek,
        endWeek: action.endWeek,
        startWeekStartDate,
        startDayId,
        endWeekEndDate,
        endDayId,
        displayName: buildCustomDateRangeDisplayName(startDayId, endDayId),
        timePeriodSuffix: {
          dayId: `_${action.startDayId}_${action.endDayId}`,
          weekId: `_${action.startWeek}_${action.endWeek}`
        },
        availableMainTimePeriods: newAvailableTimePeriods
      };
    default:
      return state;
  }
};
