import React from 'react';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import _get from 'lodash/get';
import { Text, useStacklineTheme } from '@stackline/ui';
import { formatTime } from 'src/utils/dateformatting';
import StatusPill, { StatusPillProps } from 'src/components/BeaconRedesignComponents/StatusPill/StatusPill';
import _capitalize from 'lodash/capitalize';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { getProductImageUrl } from 'src/utils/image';
import {
  calculateWeeksBetweenWeekIds,
  formatWeekIdsForAdjustmentDisplay,
  getStartAndEndOfWeek
} from 'src/utils/dateUtils';
import { planTypeMetaData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/utils';
import UserTimestampCell from 'src/components/BeaconRedesignComponents/UserTimestampCell/UserTimestampCell';
import { AdjustmentPlanType } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import {
  PlanTypeOption,
  PromotionType
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { UserMetaDataMap } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAdjustmentUserData';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Link } from 'react-router-dom';

export type Status = 'Published' | 'Archived' | 'Draft';

export enum PlanType {
  OrganicTraffic = 'Organic Traffic',
  OtherTraffic = 'Other Traffic',
  PaidTraffic = 'Paid Traffic',
  RetailPrice = 'Retail Price',
  ContentScore = 'Content Score',
  InstockRate = 'In-Stock Rate',
  RatingsReviews = 'Ratings & Reviews',
  Buybox = 'Buy Box'
}

interface UserMetaData {
  firstName: string;
  lastName: string;
}

interface Product {
  title: string;
}

interface RawDataItemForCSV {
  startWeekId: number;
  endWeekId: number;
  lastUpdatedDate: Date;
  createdDate: Date;
  planType: AdjustmentPlanType;
  reviewAvgRating: number;
  adjustmentChangeValue: number;
  netImpact: number;
  userMetaData: UserMetaData;
  product: Product;
  status: 'Published' | 'Draft' | 'Archived';
}

interface TrafficDataType {
  organicTrafficData: ForecastsAdjustmentData[];
  otherTrafficData: ForecastsAdjustmentData[];
  paidTrafficData: ForecastsAdjustmentData[];
  retailPriceData: ForecastsAdjustmentData[];
  contentScoreData: ForecastsAdjustmentData[];
  inStockRateData: ForecastsAdjustmentData[];
  ratingsAndReviewsData: ForecastsAdjustmentData[];
  buyBoxData: ForecastsAdjustmentData[];
}
export interface ForecastsAdjustmentData {
  id: string;
  beaconClientId: number;
  bulkAdjustmentId: string;
  retailerId: number;
  retailerSku: string;
  stacklineSku: string;
  updatedTime: string;
  publishedTime: string;
  document_join_field: {
    parent: string;
    name: string;
  };
  adjustmentId: string;
  adjustmentTitle: string;
  adjustmentDescription: string;
  planType: string;
  adjustmentChangeType: string;
  adjustmentChangeValue: number;
  createdByUserId: string;
  updatedByUserId: string;
  startDate: string;
  endDate: string;
  startWeekId: number;
  endWeekId: number;
  createdDate: string;
  lastUpdatedDate: string;
  status: string;
  reviewAvgRating: number;
  reviewCount: number;
  aplusScore: number;
  bulletScore: number;
  imageScore: number;
  titleScore: number;
  videoScore: number;
  adSpend: number;
  cpc: number;
  netImpact: number;
  cpcFromUserFlag: boolean;
  promotionType: PromotionType;
  product: {
    id: string;
    title: string;
    subtitle: string;
    translatedTitles: any; // Change this to the appropriate type if available
    brandName: string;
    brandId: number;
    parentBrandName: string | null;
    parentBrandId: number | null;
    categoryName: string;
    categoryId: number;
    subCategoryName: string;
    subCategoryId: number;
    retailerSku: string;
    stacklineSku: string;
    hasImage: boolean;
    breadCrumbNodeId: number | null;
    breadCrumbText: string | null;
    breadCrumbSubCategoryId: number;
    breadCrumbSubCategoryName: string | null;
    classificationReason: string | null;
    classificationBy: string | null;
    classificationSource: string | null;
    salesScore: number;
    upc: string | null;
    beaconClientIds: number[];
    retailerIds: number[];
    retailerSkus: {
      [key: number]: string;
    };
    retailerParentSkus: {
      [key: number]: string;
    };
    availabilityStatus: string;
    availabilityStatusCode: string;
    availabilityStatusPrevious: string;
    availabilityStatusCodePrevious: string;
  };
  sortResult: [number, number];
  userMetaData: Record<string, any>; // Change this to the appropriate type if available
}

export interface NetImpactDocument {
  additionalMetaData: {
    adjustmentId: string;
    name: string;
  };
  additionalValues: {
    weightedImpact_sum_count: number;
    weightedImpact_sum_value: number;
  };
  count: number;
  fieldId: string;
  fieldName: string;
  value: number;
  weekId: number;
}

/**
 * Parse document data, and make a chain requests call for UserMetadata, and Net Impact Data
 * @param {AxiosResponse<any>} data
 * @returns {ForecastsAdjustmentData[]}
 *
 /** */

export const parseForecastsAdjustmentTable = async ({
  adjustmentTableData,
  netImpactData,
  adSpendAmounts,
  userDataResponse
}: {
  adjustmentTableData: {
    data: {
      documents: ForecastsAdjustmentData[];
    }[];
  };
  netImpactData: {
    data: {
      aggregations: {
        by_adjustmentId: NetImpactDocument[];
      };
    };
  };
  adSpendAmounts: { [key: string]: { [key: string]: number } };
  userDataResponse: UserMetaDataMap;
}): Promise<ForecastsAdjustmentData[]> => {
  const documents = _get(adjustmentTableData, ['data', 0, 'documents']);

  // If we don't have any active adjustments, we can skip uniting the datasets
  if (Array.isArray(documents) && !documents.length) {
    return [];
  }

  const netImpactObj: Record<string, number> = {};
  const netImpactDocuments = _get(netImpactData, ['data', 0, 'aggregations', 'by_adjustmentId']);
  netImpactDocuments.forEach((doc) => {
    netImpactObj[doc.fieldId] = doc.value;
  });

  try {
    const userProfileObj = userDataResponse.data;
    const userProfileSet = new Map(Object.entries(userProfileObj));

    const documentsWithUserMetaData = documents.map((document) => {
      const userMetaData = userProfileSet.get(document.createdByUserId);
      const netImpactValue = netImpactObj[document.adjustmentId];

      // Override plan change amount for Paid Traffic adjustments
      if (document.planType === PlanTypeOption.PaidTraffic) {
        return {
          ...document,
          userMetaData: userMetaData || {},
          netImpact: netImpactValue || 0,
          adjustmentChangeValue: _get(adSpendAmounts[document.adjustmentId], ['paidTrafficValue_sum_value'], 0)
        };
      } else {
        return {
          ...document,
          userMetaData: userMetaData || {},
          netImpact: netImpactValue || 0
        };
      }
    });

    return documentsWithUserMetaData;
  } catch (error) {
    console.error(error);
  }

  return documents;
};

/**
 * Parse document data, and make a chain requests call for UserMetadata, and Net Impact Data
 *
 * @param {AxiosResponse<any>}
 * @returns {TrafficData}
 *
 /** */
export const filteredParseForecastsAdjustmentTable = async ({
  adjustmentPageTableData,
  userDataResponse,
  netImpactData,
  adSpendAmounts
}: {
  adjustmentPageTableData: {
    data: {
      documents: ForecastsAdjustmentData[];
    }[];
  };
  userDataResponse: UserMetaDataMap;
  netImpactData: {
    data: {
      aggregations: {
        by_adjustmentId: NetImpactDocument[];
      };
    };
  };
  adSpendAmounts: { [key: string]: { [key: string]: number } };
}): Promise<TrafficDataType> => {
  const documents = _get(adjustmentPageTableData, ['data', 0, 'documents']);

  const organicTrafficData = [];
  const otherTrafficData = [];
  const paidTrafficData = [];
  const retailPriceData = [];
  const contentScoreData = [];
  const inStockRateData = [];
  const ratingsAndReviewsData = [];
  const buyBoxData = [];

  // If we don't have any active adjustments, we can skip uniting the datasets
  if (Array.isArray(documents) && !documents.length) {
    return {
      organicTrafficData,
      otherTrafficData,
      paidTrafficData,
      retailPriceData,
      contentScoreData,
      inStockRateData,
      ratingsAndReviewsData,
      buyBoxData
    };
  }

  const netImpactObj: Record<string, number> = {};
  const netImpactDocuments = _get(netImpactData, ['data', 0, 'aggregations', 'by_adjustmentId'], []);
  netImpactDocuments.forEach((doc) => {
    netImpactObj[doc.fieldId] = doc.value;
  });

  try {
    const userProfileObj = userDataResponse.data;
    const userProfileSet = new Map(Object.entries(userProfileObj));
    const documentsWithUserMetaData = documents.map((document) => {
      const userMetaData = userProfileSet.get(document.createdByUserId);
      const netImpactValue = netImpactObj[document.adjustmentId];
      return {
        ...document,
        userMetaData: userMetaData || {},
        netImpact: netImpactValue || 0
      };
    });

    documentsWithUserMetaData.forEach((docsWithUserInfo) => {
      const { planType } = docsWithUserInfo;
      if (planType === 'OrganicTraffic') {
        organicTrafficData.push(docsWithUserInfo);
      } else if (planType === 'OtherTraffic') {
        otherTrafficData.push(docsWithUserInfo);
      } else if (planType === 'PaidTraffic') {
        paidTrafficData.push({
          ...docsWithUserInfo,
          adjustmentChangeValue: _get(adSpendAmounts[docsWithUserInfo.adjustmentId], ['paidTrafficValue_sum_value'], 0)
        });
      } else if (planType === 'RetailPrice') {
        retailPriceData.push(docsWithUserInfo);
      } else if (planType === 'ContentScore') {
        contentScoreData.push(docsWithUserInfo);
      } else if (planType === 'InstockRate') {
        inStockRateData.push(docsWithUserInfo);
      } else if (planType === 'RatingsReviews') {
        ratingsAndReviewsData.push(docsWithUserInfo);
      } else if (planType === 'Buybox') {
        buyBoxData.push(docsWithUserInfo);
      }
    });

    return {
      organicTrafficData,
      otherTrafficData,
      paidTrafficData,
      retailPriceData,
      contentScoreData,
      inStockRateData,
      ratingsAndReviewsData,
      buyBoxData
    };
  } catch (error) {
    // Handle any errors that might occur during the API call
    console.error(error);
  }

  return {
    organicTrafficData,
    otherTrafficData,
    paidTrafficData,
    retailPriceData,
    contentScoreData,
    inStockRateData,
    ratingsAndReviewsData,
    buyBoxData
  };
};

/**
 * Parse document data, and make a chain requests call for UserMetadata, and Net Impact Data
 *
 * @param {AxiosResponse<any>} data
 * @returns {TrafficData}
 *
 /** */
export const useForecastsAdjustmentsColumnDefinitions = (selectedGroupByFieldForTable) => {
  const formatMetric = useMetricFormatter(); // Used to format metric values before being displayed in the table
  const { searchParams, additionalParams } = useAppSelector((state) => state.app.queryParams);
  const theme = useStacklineTheme();

  const statusPillStyleRenderer = (status: Status): Omit<StatusPillProps, 'children'> => {
    switch (status) {
      case 'Published':
        return {
          color: 'info',
          backgroundColor: 'primaryLight'
        };
      case 'Archived':
        return {
          color: 'secondary',
          backgroundColor: 'secondaryPorcelain',
          opacity: 1
        };
      case 'Draft':
        return {
          color: 'accentGamboge',
          backgroundColor: 'accentGold'
        };

      default:
        return {
          color: 'accentGamboge',
          backgroundColor: 'accentGold'
        };
    }
  };

  const COL_DEFS = [
    // PRODUCTS
    ...(selectedGroupByFieldForTable.name === 'stacklineSku'
      ? [
          {
            headerName: 'Products',
            field: 'product',
            tableColumnProps: {
              style: {
                width: '254px'
              }
            },
            valueFormatter: (value) => {
              const { title: productTitle, stacklineSku } = value;
              // Your value formatter logic here
              return (
                <Flex alignItems="center">
                  <Flex flexDirection="column" paddingX={theme.spacing.sm} gap="sm">
                    <div style={{ width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img
                        src={getProductImageUrl(stacklineSku)}
                        alt={productTitle}
                        style={{
                          maxWidth: '35px',
                          maxHeight: '35px',
                          objectFit: 'cover'
                        }}
                      />
                    </div>
                  </Flex>
                  <Flex flexDirection="column" gap="sm">
                    <Link to={`/product/${stacklineSku.toUpperCase()}${searchParams}${additionalParams}`}>
                      <Text variant="body2" title={productTitle} truncateLines={2}>
                        {productTitle}
                      </Text>
                    </Link>
                  </Flex>
                </Flex>
              );
            }
          }
        ]
      : []),

    // USER NAME
    ...(selectedGroupByFieldForTable.name === 'forecastsPlansAdjustmentsForUser'
      ? [
          {
            headerName: 'OWNED BY',
            field: 'createdByUserId',
            tableColumnProps: {
              style: {
                width: '227px'
              }
            },
            valueFormatter: (value, row) => {
              const { userMetaData } = row;
              const { firstName, lastName } = userMetaData;

              return (
                <UserTimestampCell
                  date={row.createdDate}
                  firstName={firstName || ''}
                  lastName={lastName || ''}
                  userId={row.createdByUserId}
                />
              );
            }
          }
        ]
      : []),

    // DATE RANGE
    {
      field: 'startDate',
      headerName: 'DATE RANGE',
      tableColumnProps: {
        style: {
          width: '262px'
        }
      },
      valueFormatter: (_, row) => {
        const { startWeekId, endWeekId } = row;
        const dateFormatStr = 'MMM D, YYYY';

        const { formattedStartDate, formattedEndDate } = formatWeekIdsForAdjustmentDisplay({
          startWeekId,
          endWeekId,
          momentFormatStr: dateFormatStr
        });

        const lastEditedDate = formatTime(row.lastUpdatedDate, dateFormatStr);
        return (
          <Flex flexDirection="column" gap="xxs">
            <div>
              <Text variant="body2">{`${formattedStartDate} - ${formattedEndDate}`}</Text>
            </div>
            <div>
              <Text color="secondary" variant="body3">
                Last edited on {lastEditedDate}
              </Text>
            </div>
          </Flex>
        );
      }
    },

    // ADJUSTMENT PLAN CHANGE
    {
      field: 'adjustmentChangeValue',
      headerName: 'PLAN CHANGE',
      tableColumnProps: {
        style: {
          width: '172px'
        }
      },
      valueFormatter: (_, row: ForecastsAdjustmentData) => {
        const { planType } = row;
        let value = [AdjustmentPlanType.RatingsReviews].includes(planType)
          ? row.reviewAvgRating
          : row.adjustmentChangeValue;
        const { metricType, decimalPlaces } = planTypeMetaData[planType];

        // Paid traffic plan change amount is now aggregated and needs to be divided by the # of weeks in the plan to be considered accurate
        if ([AdjustmentPlanType.PaidTraffic].includes(planType)) {
          const { startWeekId, endWeekId } = row;
          const numberOfWeeks = calculateWeeksBetweenWeekIds(startWeekId, endWeekId);
          value /= numberOfWeeks || 1;
        }

        const formattedMetric = formatMetric(value, metricType, { showFullValue: false, decimalPlaces });

        return (
          <Flex flexDirection="column" gap="xxs">
            <div>
              <Text variant="body2">{formattedMetric}</Text>
            </div>
            <div>
              <Text variant="body3" color="secondary">
                {PlanType[row.planType]}
              </Text>
            </div>
          </Flex>
        );
      }
    },
    // Net Impact
    {
      field: 'netImpact',
      headerName: 'NET IMPACT',
      tableColumnProps: {
        style: {
          width: '182px'
        }
      },
      valueFormatter: (value) => {
        let formattedMetric = formatMetric(value, METRICTYPE.VOLUME);
        if (value > 0) {
          formattedMetric = `+${formattedMetric}`;
        }

        return (
          <Flex flexDirection="column" gap="xxs">
            <Text variant="body2">{formattedMetric}</Text>
            <Text variant="body3" color="secondary">
              Units Sold
            </Text>
          </Flex>
        );
      }
    },
    // Status
    {
      field: 'status',
      headerName: 'STATUS',
      valueFormatter: (value: Status) => {
        return <StatusPill {...statusPillStyleRenderer(value)}>{value && _capitalize(value)}</StatusPill>;
      }
    }
  ];
  return COL_DEFS;
};

interface RecommendationDocument {
  id: string;
  beaconClientId: number;
  retailerId: number;
  retailerSku: string;
  stacklineSku: string;
  startWeekId: number;
  endWeekId: number;
  forecastModelVersion: string;
  adjustmentId: string;
  adjustmentTitle: string;
  adjustmentDescription: string;
  planType: string;
  adjustmentChangeType: string;
  inputValue: number;
  adjustmentChangeValue: number;
  individualImpact: number;
  status: string;
  message: string;
  publishedTime: string;
  updatedTime: string;
}

// Sort the arrays by individualImpact
const sortByIndividualImpact = (a: RecommendationDocument, b: RecommendationDocument) => {
  return b.individualImpact - a.individualImpact;
};

export const parseForecastsRecommendationsResponse = ({
  adjustmentPageTableData
}: {
  adjustmentPageTableData: {
    data: {
      documents: RecommendationDocument[];
    }[];
  };
}): ForecastsAdjustmentData[] => {
  const responseByPlanType = _get(adjustmentPageTableData, ['data'], []);

  /**
   * Parse each response into buckets by plan type
   */

  const organicTrafficData = _get(
    responseByPlanType.filter((response) => response.id.includes(AdjustmentPlanType.OrganicTraffic))[0],
    'documents',
    []
  );
  const otherTrafficData = _get(
    responseByPlanType.filter((response) => response.id.includes(AdjustmentPlanType.OtherTraffic))[0],
    'documents',
    []
  );
  const paidTrafficData = _get(
    responseByPlanType.filter((response) => response.id.includes(AdjustmentPlanType.PaidTraffic))[0],
    'documents',
    []
  );
  const retailPriceData = _get(
    responseByPlanType.filter((response) => response.id.includes(AdjustmentPlanType.RetailPrice))[0],
    'documents',
    []
  );
  const contentScoreData = _get(
    responseByPlanType.filter((response) => response.id.includes(AdjustmentPlanType.ContentScore))[0],
    'documents',
    []
  );
  const inStockRateData = _get(
    responseByPlanType.filter((response) => response.id.includes(AdjustmentPlanType.InstockRate))[0],
    'documents',
    []
  );
  const ratingsAndReviewsData = _get(
    responseByPlanType.filter((response) => response.id.includes(AdjustmentPlanType.RatingsReviews))[0],
    'documents',
    []
  );
  const buyBoxData = _get(
    responseByPlanType.filter((response) => response.id.includes(AdjustmentPlanType.BuyBox))[0],
    'documents',
    []
  );

  organicTrafficData.sort(sortByIndividualImpact);
  otherTrafficData.sort(sortByIndividualImpact);
  paidTrafficData.sort(sortByIndividualImpact);
  retailPriceData.sort(sortByIndividualImpact);
  contentScoreData.sort(sortByIndividualImpact);
  inStockRateData.sort(sortByIndividualImpact);
  ratingsAndReviewsData.sort(sortByIndividualImpact);
  buyBoxData.sort(sortByIndividualImpact);

  return {
    organicTrafficData,
    otherTrafficData,
    paidTrafficData,
    retailPriceData,
    contentScoreData,
    inStockRateData,
    ratingsAndReviewsData,
    buyBoxData
  };
};
/**
 *
 * @returns Array of column definition objects for use in the Recommendations table
 */
export const useForecastRecommendationsColumnDefinitions = () => {
  const formatMetric = useMetricFormatter(); // Used to format metric values before being displayed in the table
  const { searchParams, additionalParams } = useAppSelector((state) => state.app.queryParams);
  const theme = useStacklineTheme();

  const COL_DEFS = [
    // PRODUCT

    {
      headerName: 'Products',
      field: 'product',
      tableColumnProps: {
        style: {
          width: '275px'
        }
      },
      valueFormatter: (value) => {
        const { title: productTitle, stacklineSku } = value;
        // Your value formatter logic here
        return (
          <Flex alignItems="center">
            <Flex flexDirection="column" paddingX={theme.spacing.sm} gap="md">
              <div style={{ width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img
                  src={getProductImageUrl(stacklineSku)}
                  alt={productTitle}
                  style={{
                    maxWidth: '35px',
                    maxHeight: '35px',
                    objectFit: 'cover'
                  }}
                />
              </div>
            </Flex>
            <Flex flexDirection="column" gap="sm">
              <Link to={`/product/${stacklineSku.toUpperCase()}${searchParams}${additionalParams}`}>
                <Text variant="body2" title={productTitle} truncateLines={2}>
                  {productTitle}
                </Text>
              </Link>
            </Flex>
          </Flex>
        );
      }
    },

    // DATE RANGE
    {
      field: 'startDate',
      headerName: 'DATE RANGE',
      tableColumnProps: {
        style: {
          width: '245px'
        }
      },
      valueFormatter: (_, row) => {
        const { startWeekId, endWeekId } = row;
        const { formattedStartDate, formattedEndDate } = formatWeekIdsForAdjustmentDisplay({ startWeekId, endWeekId });

        const lastEditedDate = formatTime(row.lastUpdatedDate, 'MMM D, YYYY');
        return (
          <Flex flexDirection="column" gap="sm">
            <div>
              <Text variant="body2">{`${formattedStartDate} - ${formattedEndDate}`}</Text>
            </div>
            <div>
              <Text color="secondary" variant="body4">
                Last edited on {lastEditedDate}
              </Text>
            </div>
          </Flex>
        );
      }
    },

    // ADJUSTMENT PLAN CHANGE
    {
      field: 'adjustmentChangeValue',
      headerName: 'PLAN CHANGE',
      valueFormatter: (value, row) => {
        const { planType } = row;

        const { metricType, decimalPlaces } = planTypeMetaData[planType];
        const formattedMetric = formatMetric(value, metricType, { showFullValue: false, decimalPlaces });
        return (
          <Flex flexDirection="column" gap="sm">
            <div>
              <Text variant="body2">{formattedMetric}</Text>
            </div>
            <div>
              <Text variant="body4" color="secondary">
                {PlanType[row.planType]}
              </Text>
            </div>
          </Flex>
        );
      }
    },
    // Net Impact
    {
      field: 'individualImpact',
      headerName: 'NET IMPACT',
      valueFormatter: (value) => {
        let formattedMetric = formatMetric(value, METRICTYPE.VOLUME);
        if (value > 0) {
          formattedMetric = `+${formattedMetric}`;
        }
        return (
          <Flex flexDirection="column" gap="sm">
            <Text variant="body2">{formattedMetric}</Text>
            <Text variant="body4" color="secondary">
              Units Sold
            </Text>
          </Flex>
        );
      }
    }
  ];
  return COL_DEFS;
};

// CSV Exports for the plans and adjustments table -- that is a collection of adjustment created by actual user
export const CSV_HEADER_DEFS = [
  'Plan Type',
  'User',
  'Title',
  'Start Week',
  'End Week',
  'Creation Date',
  'Last Updated Date',
  'Plan Change',
  'Net Impact',
  'Status'
];

// CSV Exports for the forecasts recommendation table
export const RECOMMENDATION_CSV_HEADER_DEFS = [
  'Plan Type',
  'Title',
  'Start Week',
  'End Week',
  'Last Updated Date',
  'Plan Change',
  'Net Impact'
];

/**
 * Plans, Adjustments table csv data generator -- this data collection is created by actual user, so user is included
 */
export const useGenerateCSVData = (rawData: RawDataItemForCSV[]) => {
  const formatMetric = useMetricFormatter();
  if (!rawData) {
    return [];
  }

  let mappedData = [];
  try {
    mappedData = rawData.map((item) => {
      const {
        startWeekId,
        endWeekId,
        lastUpdatedDate,
        createdDate,
        planType,
        reviewAvgRating,
        adjustmentChangeValue,
        netImpact
      } = item;

      const { metricType, decimalPlaces } = planTypeMetaData[planType];

      let planChangeValue = [AdjustmentPlanType.RatingsReviews].includes(planType)
        ? reviewAvgRating
        : adjustmentChangeValue;

      // Paid traffic has different logic for plan change number - aggregated and needs to be divided by the # of weeks in the plan to be considered accurate
      if ([AdjustmentPlanType.PaidTraffic].includes(planType as AdjustmentPlanType)) {
        const numberOfWeeks = calculateWeeksBetweenWeekIds(startWeekId, endWeekId);
        planChangeValue /= numberOfWeeks || 1;
      }

      const formattedPlanChange = formatMetric(planChangeValue, metricType, {
        showFullValue: true,
        decimalPlaces
      });

      const { startDate } = getStartAndEndOfWeek(startWeekId);
      const { endDate } = getStartAndEndOfWeek(endWeekId);

      const formattedStartDate = formatTime(startDate, 'MMM D, YYYY');
      const formattedEndDate = formatTime(endDate, 'MMM D, YYYY');
      const creationDate = formatTime(createdDate, 'MMM D, YYYY');
      const lastEditedDate = formatTime(lastUpdatedDate, 'MMM D, YYYY');

      const formattedNetImpact = formatMetric(netImpact, METRICTYPE.VOLUME);
      return [
        PlanType[planType],
        `${item.userMetaData.firstName} ${item.userMetaData.lastName}`,
        item.product.title,
        formattedStartDate,
        formattedEndDate,
        creationDate,
        lastEditedDate,
        formattedPlanChange,
        formattedNetImpact,
        item.status
      ];
    });
  } catch (err) {
    console.error(err, rawData);
    return [];
  }

  return [[...CSV_HEADER_DEFS], ...mappedData];
};

/**
 *  Recommendation table csv data generator
 */
export const useGenerateRecommendationCSVData = (rawData: RawRecommendationItemForCSV[]) => {
  const formatMetric = useMetricFormatter();

  if (!rawData) {
    return [];
  }
  const mappedData = rawData.map((item) => {
    const { startWeekId, endWeekId, updatedTime, planType, individualImpact, adjustmentChangeValue } = item;

    const { metricType, decimalPlaces } = planTypeMetaData[planType];

    const formattedPlanChange = formatMetric(adjustmentChangeValue, metricType, {
      showFullValue: true,
      decimalPlaces
    });

    const { startDate } = getStartAndEndOfWeek(startWeekId);
    const { endDate } = getStartAndEndOfWeek(endWeekId);

    const formattedStartDate = formatTime(startDate, 'MMM D, YYYY');
    const formattedEndDate = formatTime(endDate, 'MMM D, YYYY');
    const lastEditedDate = formatTime(updatedTime, 'MMM D, YYYY');

    const formattedNetImpact = formatMetric(individualImpact, METRICTYPE.VOLUME);

    return [
      PlanType[planType],
      item.product.title,
      formattedStartDate,
      formattedEndDate,
      lastEditedDate,
      formattedPlanChange,
      formattedNetImpact
    ];
  });

  return [[...RECOMMENDATION_CSV_HEADER_DEFS], ...mappedData];
};

/**
 * Adjustment table csv download handler
 *
 */
export const handleCsvDownload = (formattedData: string[][], fileName = 'Adjustments.csv') => {
  const csvContent = formattedData.map((e) => e.map((field) => `"${field}"`).join(',')).join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);

  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
