import actions from './actions';
import axios from 'axios';
import _cloneDeep from 'lodash/cloneDeep';
import { computeAdScores } from './selectors';
import ReduxStore from 'src/types/store/reduxStore';
import { RangeFilter } from 'sl-api-connector/types';
import { buildAggregations } from 'src/components/AdManager/Search';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { parseEntityMetrics } from 'src/store/modules/entitySearchService/selectors';
import { fetchEntity } from 'src/store/modules/entityService/operations';
import { parseAdCampaigns } from 'src/store/modules/adManager/adCampaigns/selectors';

export const { receiveAdScores } = actions;

export const clearAdScores = () => (dispatch: Function) => {
  dispatch(actions.clearAdScores());
};

export const receiveOneAdScores =
  (type: string, entityId: string, score: number) => (dispatch: Function, getState: () => ReduxStore) => {
    const { adScores } = getState();
    const newAdScores: any = _cloneDeep(adScores);
    if (!newAdScores[type]) {
      newAdScores[type] = {};
    }
    newAdScores[type][entityId] = { score };
    dispatch(actions.receiveAdScores(newAdScores));
  };

export const fetchAdScores = () => async (dispatch: Function, getState: () => ReduxStore) => {
  const {
    app,
    retailer,
    mainTimePeriod,
    allWeekIdsByRetailerId,
    entityService: { mainEntity }
  } = getState();
  let { adCampaigns } = getState();
  let entity: any = mainEntity && mainEntity.type === 'client' ? mainEntity : null;
  if (!entity) {
    entity = await fetchEntity('client', 'client', '-1', +retailer.id);
  }
  const adCampaignAdGroupProductTargetDailyMetrics = 'adCampaignAdGroupProductTargetDailyMetrics';
  const indexNameAdOptimizationHistory = 'adOptimizationHistory';
  const groupByFieldCampaignId = INDEX_FIELDS.getField(
    app.name,
    adCampaignAdGroupProductTargetDailyMetrics,
    'campaignId',
    entity.type
  );

  const metricFieldsByIndexName: any = {
    [adCampaignAdGroupProductTargetDailyMetrics]: [
      INDEX_FIELDS.getField(
        app.name,
        adCampaignAdGroupProductTargetDailyMetrics,
        'clickThroughRate',
        entity.type,
        groupByFieldCampaignId.name
      ),
      INDEX_FIELDS.getField(
        app.name,
        adCampaignAdGroupProductTargetDailyMetrics,
        'costPerClick',
        entity.type,
        groupByFieldCampaignId.name
      ),
      INDEX_FIELDS.getField(
        app.name,
        adCampaignAdGroupProductTargetDailyMetrics,
        'returnOnAdSpend',
        entity.type,
        groupByFieldCampaignId.name
      )
    ],
    [indexNameAdOptimizationHistory]: [
      INDEX_FIELDS.getField(
        app.name,
        indexNameAdOptimizationHistory,
        'campaignId',
        entity.type,
        groupByFieldCampaignId.name
      )
    ]
  };

  const mainTimePeriodRangeFilters: RangeFilter[] = [
    {
      fieldName: 'dayId',
      minValue: mainTimePeriod.startDayId,
      maxValue: mainTimePeriod.endDayId
    }
  ];
  let entityMetricsParsed: any = {};
  const allPromises = [adCampaignAdGroupProductTargetDailyMetrics, indexNameAdOptimizationHistory].map(
    async (indexName) => {
      const [{ aggregations: aggregationFields }] = buildAggregations(metricFieldsByIndexName[indexName]);
      const requestContext = { entity, retailer, app, indexName, mergeIfStatePropertyValueExists: true };
      const requestOverrides = [
        {
          doAggregation: true,
          aggregations: [
            {
              aggregationFields,
              conditions: {
                termFilters: [{ fieldName: 'retailerId', values: [+retailer.id] }],
                rangeFilters: mainTimePeriodRangeFilters
              },
              groupByFieldName: groupByFieldCampaignId.name
            }
          ],
          conditions: {
            termFilters: [{ fieldName: 'retailerId', values: [+retailer.id] }],
            rangeFilters: mainTimePeriodRangeFilters
          },
          pageSize: 3000,
          processDocuments: true
        }
      ];
      const dataKey = `adScores_input_metrics_${indexName}_${groupByFieldCampaignId.name}`;
      const { searchRequests: apiRequest } = entitySearchServiceOperations.buildSearchRequests(
        dataKey,
        requestContext,
        requestOverrides
      );

      const apiResponse = await entitySearchServiceOperations.requestEntityMetrics(app.name, apiRequest);

      entityMetricsParsed = {
        ...entityMetricsParsed,
        ...parseEntityMetrics({
          apiRequest,
          apiResponse,
          statePropertyName: dataKey,
          requestContext,
          searchRequestsOverrides: requestOverrides,
          allWeekIdsByRetailerId
        })
      };
      return true;
    }
  );
  if (!adCampaigns || adCampaigns.length === 0) {
    const res = await axios.get(`/apiAdManager/adCampaigns`);
    adCampaigns = parseAdCampaigns(res.data, retailer.id);
  }

  const benchMarksRes = await axios.get('/apiAdManager/adPlatformSettings');

  const benchMarks = benchMarksRes.data.filter((data: any) => data.settingType === 'adScoreBenchmark');
  const adScoresBenchMarks = {} as any;
  benchMarks.forEach((element: any) => {
    adScoresBenchMarks[element.settingId] = element.extendedAttributes || {};
  });

  await Promise.all(allPromises);
  const newAdScores = computeAdScores(
    {
      spendByCampaignId: entityMetricsParsed[`spend_by_${groupByFieldCampaignId.name}`],
      clickThroughRateByCampaignId: entityMetricsParsed[`clickThroughRate_by_${groupByFieldCampaignId.name}`],
      costPerClickByCampaignId: entityMetricsParsed[`costPerClick_by_${groupByFieldCampaignId.name}`],
      returnOnAdSpendByCampaignId: entityMetricsParsed[`returnOnAdSpend_by_${groupByFieldCampaignId.name}`],
      optimizationsByCampaignId: entityMetricsParsed[`campaignId_by_${groupByFieldCampaignId.name}`],
      adCampaigns
    },
    adScoresBenchMarks
  );
  dispatch(actions.receiveAdScores(newAdScores));
};
