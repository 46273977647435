import React from 'react';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import BeaconDropdownMetricColumnChart from 'src/components/BeaconRedesignComponents/BeaconMetricColumnChart/BeaconDropdownMetricColumnChart';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import removeOtherTraffic from 'src/components/BeaconRedesignComponents/utils/removeOtherTraffic';
import KeywordsWithTrafficSplineChart from './KeywordsWithTrafficSplineChart';
import { useAppSelector } from 'src/utils/Hooks';
import BeaconMetricColumnChart from 'src/components/BeaconRedesignComponents/BeaconMetricColumnChart';
import TotalTrafficGrid from './TotalTrafficGrid';
import { useCreateRemoveRetailPriceRangeFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { TotalTrafficTopSection } from './TotalTrafficTopSection';
import { TotalTrafficSplineChart } from './TotalTrafficSplineChart';
import { shouldShowBeaconProTopics } from 'src/utils/app';

/**
 * The "Total" tab on the Traffic page
 */
const TotalTrafficPage = () => {
  const mainEntityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const removeRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();

  return (
    <BeaconPageContainer>
      <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
        <Flex gap="lg" flexDirection="column">
          <CommonSummaryInfoSubtitle title="Total Traffic" />
          <TotalTrafficTopSection />
        </Flex>
        {shouldShowBeaconProTopics() && <TotalTrafficSplineChart />}
        {mainEntityType === 'product' ? (
          <BeaconMetricColumnChart
            fieldName="totalClicks"
            indexName="traffic"
            useAdvancedSearchDataArgs={{
              requestBuilder: (builder) => builder.apply(removeOtherTraffic),
              fields: [{ name: 'totalClicks' }],
              groupByField: 'searchTerm'
            }}
            beaconChartWithLegendPropsBuilder={(props) => ({
              ...props,
              title: 'Total Traffic'
            })}
          />
        ) : (
          <BeaconDropdownMetricColumnChart
            fieldName="totalClicks"
            indexName={({ selectedDropdownId }) =>
              selectedDropdownId === 'searchTerm' ? 'traffic' : 'aggregated-traffic'
            }
            dropdownOptions={[
              {
                id: 'searchTerm',
                label: 'Keyword'
              },
              {
                id: 'categoryId',
                label: 'Category'
              },
              {
                id: 'subCategoryId',
                label: 'Subcategory'
              },
              {
                id: 'brandId',
                label: 'Brand'
              },
              {
                id: 'stacklineSku',
                label: 'Product'
              }
            ].filter((option) => !option.id.toLowerCase().startsWith(mainEntityType))}
            useAdvancedSearchDataArgs={{
              requestBuilder: (builder) => builder.apply(removeOtherTraffic).apply(removeRetailPriceRangeFilters())
            }}
            titlePrefix="Total Traffic by"
          />
        )}
        <KeywordsWithTrafficSplineChart />
        <TotalTrafficGrid />
      </Flex>
    </BeaconPageContainer>
  );
};

export default TotalTrafficPage;
