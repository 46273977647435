import { OverridingAggregationField } from 'src/types/application/types';

interface BeaconAggregationFieldOverride {
  retailPrice: OverridingAggregationField[];
}

export const beaconAggregationFieldOverride: BeaconAggregationFieldOverride = {
  retailPrice: [
    {
      aggregateByFieldDisplayName: 'Units Sold',
      aggregateByFieldName: 'unitsSold',
      function: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Retail Sales',
      aggregateByFieldName: 'retailSales',
      function: 'sum',
      canBeExported: true
    },
    {
      aggregateByFieldDisplayName: 'Retail Price',
      aggregateByFieldName: 'retailPrice',
      function: 'computed',
      aggregateByFormula: '((unitsSold_sum > 0) ? ((retailSales_sum / unitsSold_sum)) : (0))',
      canBeExported: true
    }
  ]
};
