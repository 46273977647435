import Create from 'src/components/AdManager/ScheduledAction/Create';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { buildWidgetGroup } from 'src/components/Layout/LayoutUtil';
import {
  buildAdvancedSearchSideBarWidget,
  buildTabbedSearchResultGridWidget,
  getFieldDisplayName
} from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import ReduxStore from 'src/types/store/reduxStore';
import { Entity } from 'sl-api-connector';
import { EventBus } from 'src/types/utils';
import SelectEntityColumn from 'src/components/AdManager/Search/CustomColumns/SelectEntityColumn';
import EntityColumn from 'src/components/AdManager/Search/CustomColumns/EntityColumn';
import DataColumn from 'src/components/AdManager/Search/CustomColumns/DataColumn';
import { actionOptions } from 'src/store/modules/adManager/adScheduledActionGroups/selectors';
import { splitSnakeCase } from 'src/utils/stringFormatting';
import { getUserColumns } from 'src/routes/UserAccount/UserCustomColumn';
import pluralize from 'pluralize';

export const getLayout = ({
  app,
  entity,
  eventBus
}: {
  app: ReduxStore['app'];
  entity: Entity;
  eventBus: EventBus;
}) => {
  const advancedSearchSideBarWidget = buildAdvancedSearchSideBarWidget();
  advancedSearchSideBarWidget.view.hideByDefault = true;
  advancedSearchSideBarWidget.view.container.style = {
    ...advancedSearchSideBarWidget.view.container.style,
    paddingTop: 0
  };
  advancedSearchSideBarWidget.data.useInternalState = true;

  const { currentScheduledAction } = app.queryParams;

  const getEditColumnDefs = (firstHeaderName: string) => {
    const userColumns = getUserColumns();
    const userColumnsDefs = userColumns.map((field: string) => {
      return {
        headerName: getFieldDisplayName(field),
        disableSort: false,
        cellRendererFramework: DataColumn,
        field
      };
    });
    const firsColumn = [
      {
        headerName: firstHeaderName,
        field: 'campaignId',
        width: undefined,
        enableRtl: true,
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        minWidth: 300,
        maxWidth: 800,
        cellRendererFramework: EntityColumn,
        headerClass: 'align-left justify-flex-end',
        pinnedRowCellRenderer: 'simpleColumn',
        pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
        pinned: 'left'
      }
    ];
    if (firstHeaderName === 'Target') {
      firsColumn.push({
        disableSort: true,
        headerName: 'Match',
        field: 'entity',
        width: undefined,
        enableRtl: true,
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        minWidth: 100,
        maxWidth: 100,
        valueFormatter: ({ value }) => _upperFirst(splitSnakeCase(_get(value, 'targetingType', '').toLowerCase())),
        headerClass: 'align-left',
        pinnedRowCellRenderer: 'emptyColumn',
        pinned: 'left'
      });
    }
    return [...firsColumn, ...userColumnsDefs];
  };

  const getCreateColumnDefs = (firstHeaderName: string) => {
    return [
      {
        headerName: '',
        field: 'campaignId',
        width: undefined,
        enableRtl: true,
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        minWidth: 100,
        maxWidth: 100,
        disableSort: true,
        cellRendererFramework: SelectEntityColumn,
        headerComponentFramework: SelectEntityColumn,
        pinned: 'left'
      },
      ...getEditColumnDefs(firstHeaderName)
    ];
  };
  const getEditMainColumnDefs = (firstHeaderName: string) => {
    return [
      {
        headerName: '',
        field: 'campaignId',
        width: undefined,
        enableRtl: true,
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        minWidth: 100,
        maxWidth: 100,
        disableSort: true,
        cellRendererFramework: SelectEntityColumn,
        pinned: 'left'
      },
      ...getEditColumnDefs(firstHeaderName)
    ];
  };

  const editColDefGroupBy = {
    targetingText: getEditMainColumnDefs('Target'),
    stacklineSku: getEditMainColumnDefs('Product'),
    campaignId: getEditMainColumnDefs('Campaign')
  };

  const createColDefGroupBy = {
    targetingText: getCreateColumnDefs('Target'),
    stacklineSku: getCreateColumnDefs('Product'),
    campaignId: getCreateColumnDefs('Campaign')
  };

  const tabbedSearchResultGridWidget = buildTabbedSearchResultGridWidget({
    app,
    entity: { type: 'scheduledActionsCreate' },
    eventBus,
    searchResultsGridWidgetDataOverride: {
      // fetchAdditionalCampaignTargets: true,
      hideSearchResultsActionBar: false,
      customColumnDefsByGroupByField: createColDefGroupBy,
      useInternalState: true,
      statePropertyName: 'scheduledActionsCreateGrid'
    },
    headerWidgetDataOverride: {
      headerOverride: 'Campaigns Selection',
      subHeaderOverride: (entityType: string) =>
        `Select ${entityType ? pluralize(entityType) : 'campaigns'} to add to the scheduled action`,
      disableSearchPlaceholder: true
    }
  });

  const editSearchGridWidget = buildTabbedSearchResultGridWidget({
    app,
    entity: { type: 'scheduledActionsCreate' },
    eventBus,
    searchResultsGridWidgetDataOverride: {
      currentScheduledAction,
      hideSearchResultsActionBar: false,
      customColumnDefsByGroupByField: editColDefGroupBy,
      useInternalState: true,
      statePropertyName: 'scheduledActionsCreateGrid'
    },
    headerWidgetDataOverride: {
      hidePills: true,
      headerOverride: 'Campaigns Selection',
      subHeaderOverride: (entityType: string) =>
        `Select ${entityType ? pluralize(entityType) : 'campaigns'} to add to the scheduled action`,
      disableSearchPlaceholder: true
    }
  });

  const searchResultGridWidgetGroupWidget = buildWidgetGroup(
    [advancedSearchSideBarWidget, tabbedSearchResultGridWidget],
    {
      view: {
        container: {
          style: {
            height: '550px',
            width: '100%',
            display: 'flex'
          }
        }
      }
    }
  );

  const editPageCampaignGrid = buildWidgetGroup([editSearchGridWidget], {
    view: {
      container: {
        style: {
          height: '550px',
          width: '100%',
          display: 'flex'
        }
      }
    }
  });

  const createSetting = {
    CustomComponent: Create,
    name: 'createScheduledActions',
    view: {
      name: 'createScheduledActions',
      gridOptions: {
        pageSize: 1000
      },
      bottomGrid: searchResultGridWidgetGroupWidget
    },
    data: {
      actionOpts: actionOptions
    }
  };

  const editSetting = {
    CustomComponent: Create,
    name: 'createScheduledActions',
    view: {
      name: 'createScheduledActions',
      gridOptions: {
        pageSize: 1000
      },
      bottomGrid: editPageCampaignGrid
    },
    data: {
      currentScheduledAction,
      actionOpts: actionOptions
    }
  };

  const widgets = [currentScheduledAction ? editSetting : createSetting];

  // if (!currentScheduledAction) {
  //   widgets.push(searchResultGridWidgetGroupWidget);
  // } else {
  //   widgets.push(editPageCampaignGrid);
  // }

  const contentWidgetsGroup = buildWidgetGroup(
    [buildWidgetGroup(widgets, { data: {}, view: { container: { style: { minWidth: 1370, maxWidth: 1370 } } } })],
    {
      view: {
        container: {
          className: 'ad_builder-container',
          style: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }
        }
      }
    }
  );

  return {
    containerStyle: { flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' },
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    dataConfig: {},
    widgets: entity.type === 'client' ? [contentWidgetsGroup] : []
  };
};
