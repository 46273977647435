/**
 * Read-only tabs indicating the user's current progress through the flow
 */

import React, { useEffect } from 'react';
import queryString from 'qs';
import { makeStyles } from '@mui/styles';
import { withPush } from 'src/utils/hoc';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import colors from 'src/utils/colors';
import { ChevronIcon } from 'src/components/SvgIcons';

const useStyles = makeStyles({
  label: {
    '&:active': {},
    fontSize: 18,
    color: colors.lightGrey,
    fontWeight: 400
  },
  completed: { color: `${colors.darkBlue} !important` },
  active: { color: `${colors.blue} !important` }
});

const buildSubtabLink = (subtab) => {
  const curParams = queryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const newSearchString = queryString.stringify({ ...curParams, subtab });
  return `?${newSearchString}`;
};

const AdCampaignBuilderTabs = ({ app, push, adCampaignBuilder, retailer }) => {
  const classes = useStyles();
  const [step, setStep] = React.useState(0);
  useEffect(() => {
    if (!adCampaignBuilder.inited) {
      push(buildSubtabLink('platformSelect'));
    }
  });

  // default subtabs
  let steps = [
    { value: 'platformSelect', displayName: 'Platform' },
    { value: 'setupType', displayName: 'Setup' },
    { value: 'featured', displayName: 'Products' },
    { value: 'creative', displayName: 'Creative' },
    { value: 'targetType', displayName: 'Targets' },
    { value: 'projections', displayName: 'Projections' },
    { value: 'market', displayName: 'Market Stats' },
    { value: 'confirmation', displayName: 'Confirmation' }
  ];

  if (['63'].includes(retailer.id)) {
    steps = [
      { value: 'platformSelect', displayName: 'Platform' },
      { value: 'setupType', displayName: 'Setup' },
      { value: 'featured', displayName: 'Products' },
      { value: 'targetType', displayName: 'Targets' },
      { value: 'confirmation', displayName: 'Confirmation' }
    ];
  }

  if (['2', '25'].includes(retailer.id)) {
    steps = [
      { value: 'platformSelect', displayName: 'Platform' },
      { value: 'setupType', displayName: 'Setup' },
      { value: 'featured', displayName: 'Products' },
      { value: 'walmartVideo', displayName: 'Creative' },
      { value: 'targetType', displayName: 'Targets' },
      { value: 'confirmation', displayName: 'Confirmation' }
    ];
  }

  if (
    (retailer.id === '2' || retailer.id === '25') &&
    adCampaignBuilder &&
    adCampaignBuilder.campaignType &&
    adCampaignBuilder.campaignType.settingId !== 'video'
  ) {
    steps.splice(5, 0, { value: 'placements', displayName: 'Placements' });
  }

  const subtabMapping1 = {
    platformSelect: 0,
    setupType: 1,
    setupStrategy: 1,
    setupDetail: 1,
    featured: 2,
    featuredLanding: 2,
    creative: 3,
    creativeHeadline: 3,
    videoUpload: 3,
    walmartVideo: 3,
    targetType: 4,
    targetDetail: 4,
    targetMatch: 4,
    audienceSettings: 4,
    projections: 5,
    market: 6,
    additionalSettings: 7,
    confirmation: 7,
    submit: 8
  };

  const subtabMapping2 = {
    platformSelect: 0,
    setupType: 1,
    setupStrategy: 1,
    setupDetail: 1,
    featured: 2,
    featuredLanding: 2,
    creative: 3,
    creativeHeadline: 3,
    videoUpload: 3,
    walmartVideo: 3,
    targetType: 3,
    targetDetail: 3,
    audienceSettings: 3,
    targetMatch: 3,
    projections: 3,
    market: 4,
    additionalSettings: 4,
    confirmation: 4,
    submit: 5
  };

  const subtabMappingWalmart = {
    platformSelect: 0,
    setupType: 1,
    setupStrategy: 1,
    setupDetail: 1,
    featured: 2,
    featuredLanding: 2,
    creative: 3,
    creativeHeadline: 3,
    videoUpload: 3,
    walmartVideo: 3,
    targetType: 4,
    targetDetail: 4,
    audienceSettings: 4,
    targetMatch: 4,
    projections: 4,
    market: 4,
    additionalSettings: 4,
    confirmation: 5,
    submit: 6
  };

  const getStepBySubtab = () => {
    if (retailer.id === '2' || retailer.id === '25') {
      return subtabMappingWalmart[app.queryParams.subtab];
    } else if (retailer.id === '63') {
      return subtabMapping2[app.queryParams.subtab];
    } else {
      return subtabMapping1[app.queryParams.subtab];
    }
  };

  React.useEffect(() => setStep(getStepBySubtab()));

  const connector = (
    <ChevronIcon
      style={{
        transform: 'rotate(-90deg)',
        width: 30,
        height: 30,
        stroke: colors.lightGrey
      }}
    />
  );

  return (
    <div className={classes.root}>
      <Stepper activeStep={step} connector={connector}>
        {steps.map(({ displayName }) => (
          <Step key={displayName}>
            <StepLabel
              classes={{
                label: classes.label,
                active: classes.active,
                completed: classes.completed
              }}
              icon={<></>}
              className={classes.label}
            >
              {displayName}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

AdCampaignBuilderTabs.propTypes = {
  adCampaignBuilder: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  retailer: PropTypes.object.isRequired
};

const mapStateToProps = ({ app, adCampaignBuilder, retailer }) => ({ app, adCampaignBuilder, retailer });

export default connect(mapStateToProps)(withPush(AdCampaignBuilderTabs));
