import FormControlLabel from '@mui/material/FormControlLabel';
import _cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List as VirtualizedList } from 'react-virtualized';
import Suggestions from 'src/components/Suggestions';
import { SelectedExcludeCheckbox, SelectedIncludeCheckbox, UnselectedCheckbox } from 'src/components/SvgIcons/SvgIcons';
import CustomizedCheckbox from 'src/components/common/CustomizedCheckBox/CustomizedCheckBox';
import { shouldShowNewBeacon } from 'src/utils/app';
import { prefillFormData } from 'src/utils/segments';
import checkboxRenderer from './checkboxRenderer';
import { useStacklineTheme } from '@stackline/ui';

interface UpdatedBrandsSearchProps {
  app: { queryParams: object; name: string; apiAppName: string };
  brands: { i: number; n: string }[];
  onBrandsChange: Function;
  autocompleteAppNameOverride: any;
  key: any;
  parent: any;
  index: any;
}

const UpdatedBrandsSearch: React.FC<UpdatedBrandsSearchProps> = ({
  app,
  brands,
  onBrandsChange,
  autocompleteAppNameOverride = null
}) => {
  const [brandChips, setBrandChips] = useState([]);
  const [filter, setFilter] = useState('default');
  const currentFormData = prefillFormData({ queryParams: app.queryParams, appName: app.name });
  const theme = useStacklineTheme();

  function updateBrands() {
    setBrandChips(brands.map((brand, index) => ({ key: index, i: brand.i, n: brand.n })));
  }

  function updateFilterSelection() {
    const { termFilters } = currentFormData;
    const { brandId, excludedBrandId } = termFilters;

    if (brandId.values.length > 0) {
      setFilter('include');
    } else if (excludedBrandId.values.length > 0) {
      setFilter('exclude');
    } else {
      setFilter('default');
    }
  }

  useEffect(() => {
    updateBrands();
    updateFilterSelection();
  }, [brands]);

  function handleBrandsChange(newBrands: { i: number; n: string }[], selection = null) {
    const values = newBrands.map((brand) => ({ i: brand.i, n: brand.n }));
    const filterMap = {
      include: { includeKey: 'brandId', excludeKey: 'excludedBrandId' },
      default: { includeKey: 'brandId', excludeKey: 'excludedBrandId' },
      exclude: { includeKey: 'excludedBrandId', excludeKey: 'brandId' }
    };
    const filterOptions = filterMap[selection || filter];
    onBrandsChange({ key: 'brandId', values }, filterOptions);
  }

  const handleRequestDelete = (key: number) => {
    const clonedBrands = _cloneDeep(brandChips);
    const chipToDelete = clonedBrands.map((chip) => chip.key).indexOf(key);
    clonedBrands.splice(chipToDelete, 1);
    handleBrandsChange(clonedBrands);
  };

  const handleSelectionChange = (selection: { id: number; value: string }, index: number) => {
    const clonedBrands = _cloneDeep(brandChips);

    const isAlreadySelected = clonedBrands.filter((brand) => brand.value === selection.value).length > 0;
    if (index !== -1 && !isAlreadySelected) {
      clonedBrands.push({ key: clonedBrands.length, i: selection.id, n: selection.value });
      handleBrandsChange(clonedBrands);
    }
  };

  const selectFilter = (selection: string) => {
    setFilter(selection);
    handleBrandsChange(brandChips, selection);
  };

  const handleClear = (e: React.ChangeEvent) => {
    e.preventDefault();
    handleBrandsChange([]);
  };

  const rowRenderer = ({ index, style }) => {
    const listItem = brandChips[index];
    const handleCheck = (i: number) => {
      handleRequestDelete(i);
    };
    return (
      <div key={listItem.key} style={style}>
        <FormControlLabel
          control={
            <CustomizedCheckbox
              styleObj={{
                width: 16,
                height: 16,
                iconBackgroundColor: 'transparent',
                iconColor: 'transparent',
                checkedIconBackgroundColor: 'transparent',
                checkedIconColor: 'transparent'
              }}
              InnerIcon={filter === 'include' ? SelectedIncludeCheckbox : SelectedExcludeCheckbox}
              OuterIcon={UnselectedCheckbox}
              checkboxProps={{
                checked: true,
                style: { marginLeft: '2px' },
                onChange: () => {
                  handleCheck(index);
                }
              }}
            />
          }
          label={<span style={{ fontSize: '14px', letterSpacing: 'normal' }}>{listItem.n || ''}</span>}
        />
      </div>
    );
  };

  // Caches row heights for many list items in our virtualized list
  // Currently exhibits weird behavior when placed outside of component; we may not be receiving performance benefits with the way it is setup currently.
  // React Docs advise against using Refs for rendering but we may be able to expose the ref properly with a wrapper function that returns just the "current" property.
  const cache = new CellMeasurerCache({
    defaultHeight: 45,
    fixedWidth: true
  });

  return (
    <div className={shouldShowNewBeacon() ? 'brands-form-container' : 'search-form-container'}>
      <div className={shouldShowNewBeacon() ? 'form-group-controls-beaconV2' : 'form-group-controls'}>
        <h4 style={{ margin: '0' }}>Brands</h4>
        <div className="checkbox-container">{checkboxRenderer(filter, selectFilter)}</div>
        <button onClick={(e) => handleClear(e)} className="clear-btn">
          Clear
        </button>
      </div>

      <Suggestions
        autocompleteAppNameOverride={autocompleteAppNameOverride}
        apiUrl={`/api/${autocompleteAppNameOverride || app.apiAppName}/AutoCompleteSuggestions?term=`}
        className="updated-sl-form-input"
        hintText="Refine by brands"
        type="brand"
        textFieldStyle={{
          height: '48px',
          '&::placeholder': {
            fontSize: '12px',
            color: theme.colors.secondary
          },
          '& .MuiInput-underline:before': {
            borderBottomColor: '#e8e8ed'
          }
        }}
        onSelectionChange={handleSelectionChange}
      />
      <AutoSizer disableHeight>
        {() => (
          <VirtualizedList
            width={205}
            height={brandChips.length < 5 ? brandChips.length * 45 : 175}
            rowCount={brandChips.length}
            rowHeight={cache.rowHeight}
            rowRenderer={({ ...props }) => {
              const { key, parent, index } = props;
              return (
                <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                  {rowRenderer(props)}
                </CellMeasurer>
              );
            }}
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default UpdatedBrandsSearch;
