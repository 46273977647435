import { useStacklineTheme } from '@stackline/ui';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { ExitIconSmall } from 'src/components/SvgIcons';
import CircularProgressWithLabel from 'src/components/common/UploadAnimation/CircularProgressWithLabel';
import { formatMetric } from 'src/utils/app';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

const textStyle = {
  fontFamily: 'Roboto',
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#052849'
};

const BulkTemplateDownloadModalLoadingInner = ({
  closeBulkTemplateDownloadModal,
  downloadPercentage
}: {
  closeBulkTemplateDownloadModal: () => void;
  downloadPercentage: number;
}) => {
  const theme = useStacklineTheme();

  const onCloseBtn = () => {
    closeBulkTemplateDownloadModal();
  };

  return (
    <>
      <Flex>
        <ExitIconSmall
          onClick={closeBulkTemplateDownloadModal}
          style={{
            position: 'absolute',
            right: '24px',
            cursor: 'pointer',
            width: theme.spacing.md,
            height: theme.spacing.md
          }}
        />
      </Flex>

      {/* Content */}
      <Flex marginTop="26px" flexDirection="column">
        <CircularProgressWithLabel
          percentage={downloadPercentage}
          label="Downloaded"
          circleSx={{ color: theme.colors.primary }}
          circleProps={{ size: '178px' }}
          backgroundCircleProps={{ size: '186px' }}
          customLabel={
            <>
              <Flex height="32px">
                <span style={textStyle}>{`${formatMetric(downloadPercentage, METRICTYPE.VOLUME, {
                  showFullValue: true
                })}%`}</span>
              </Flex>
              <Flex height="24px" marginTop="4px">
                <Text variant="h5">Downloaded</Text>
              </Flex>
            </>
          }
        />
      </Flex>

      <Flex marginTop="28px" justifyContent="center" height="24px">
        <Text variant="h4">Your bulk template is downloading</Text>
      </Flex>

      <Flex textAlign="center" marginTop={theme.spacing.sm} marginBottom="5px" minHeight="58px" justifyContent="center">
        <Flex width="312px">
          <Text variant="body1">
            It may take a few minutes for the download to complete. You can close this window in the meantime.
          </Text>
        </Flex>
      </Flex>

      {/* Footer */}
      <Flex justifyContent="space-between" alignItems="center" marginTop="39px">
        <SlButton disabled>Back</SlButton>
        <SlButton onClick={onCloseBtn} variant="contained" buttonSx={{ width: '71px' }}>
          Close
        </SlButton>
      </Flex>
    </>
  );
};

export default BulkTemplateDownloadModalLoadingInner;
