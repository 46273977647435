import { SxProps } from '@mui/material/styles';

/**
 * Utility function to merge multiple sx props into one
 */
export default function mergeSx(...sxProps: SxProps[]) {
  return sxProps.reduce((acc, sx) => {
    if (!sx) {
      return acc;
    }

    return [...acc, ...(Array.isArray(sx) ? sx : [sx])];
  }, [] as SxProps[]);
}
