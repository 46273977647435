import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import 'src/routes/HomePage/Segments.scss';
import Truncate from 'react-truncate';
import Chip from '@mui/material/Chip';
import colors from 'src/utils/colors';
import { destructurePathName } from 'src/utils/urlParsing';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import IconButton from '@mui/material/IconButton';
import { DuplicateIcon, EditIcon } from 'src/components/SvgIcons';
import { generateQueryParamObj } from 'src/components/Omni/OmniSegmentUtil';
import { addPersistentQueryParams } from 'src/utils/browser';
import ReactTooltip from 'react-tooltip';
import queryString from 'qs';

const buildSegmentNavUrl = (
  query: { [key: string]: string[] | number[] },
  queryId: string,
  name: string,
  retailer: ReduxStore['retailer'],
  mainTimePeriod: ReduxStore['mainTimePeriod'],
  type: string,
  entityName: string
) => {
  let navAddress = 'search';
  if (entityName === 'keywordSegment') {
    navAddress = 'searchtermlist';
  } else if (entityName === 'store') {
    navAddress = 'storeListsSearch';
  }
  let newParamObj = generateQueryParamObj({ queryId, name, ...query }, {});
  if (type === 'duplicate') {
    newParamObj = generateQueryParamObj({ ...query }, {});
  }
  return `/${navAddress}?${addPersistentQueryParams(
    retailer,
    mainTimePeriod
  )}&entityType=segment&${queryString.stringify(newParamObj)}`;
};

const OmniSegmentSideBarCard: React.FC<RouteComponentProps> = ({ location }: RouteComponentProps) => {
  const { pathname } = location;
  const [entityName] = destructurePathName(pathname);
  const { mainEntity } = useSelector((state: ReduxStore) => state.entityService);
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  if (!mainEntity || !mainEntity.name) {
    return <div></div>;
  }
  const { name, query, queryId, canEdit } = mainEntity;

  const updateUrl = buildSegmentNavUrl(query, queryId, name, retailer, mainTimePeriod, 'update', entityName);
  const duplicateUrl = buildSegmentNavUrl(query, queryId, name, retailer, mainTimePeriod, 'duplicate', entityName);
  const displayType = entityName === 'keywordSegment' ? 'Keyword' : 'Segment';
  return (
    <div className="nav-header-container">
      <div className="nav-image-container">
        <div className="nav-image-center">
          <div className="segment__short-name-container" style={{ backgroundColor: colors.darkBlue }}>
            <div className="segment__short-name">{name.substring(0, 2)}</div>
          </div>
        </div>
      </div>
      <div className="nav-name-container" title={name}>
        <Truncate style={{ display: 'inline-block', verticalAlign: 'middle' }} lines={2} ellipsis="...">
          <div className="nav-company-name">{name}</div>
        </Truncate>
        {canEdit && (
          <IconButton
            onClick={() => {}}
            data-for="edit-segment"
            data-tip={`Edit ${name}`}
            style={{
              display: 'inline-block',
              height: '20px',
              width: '20px',
              verticalAlign: 'middle',
              padding: '0',
              marginLeft: '5px'
            }}
            size="large"
          >
            <Link to={updateUrl}>
              <EditIcon className="segment-header__edit-icon" />
            </Link>
          </IconButton>
        )}
        <IconButton
          onClick={() => {}}
          data-for="duplicate-segment"
          data-tip={`Duplicate ${name}`}
          style={{
            display: 'inline-block',
            height: '20px',
            width: '20px',
            verticalAlign: 'middle',
            padding: '0',
            marginLeft: '5px'
          }}
          size="large"
        >
          <Link to={duplicateUrl}>
            <DuplicateIcon className="segment-header__duplicate-icon" />
          </Link>
        </IconButton>
        <ReactTooltip id="duplicate-segment" effect="solid" className="sl-tooltip" />
        <ReactTooltip id="edit-segment" effect="solid" className="sl-tooltip" />
      </div>
      <Chip className="entity-chip" label={displayType} />
    </div>
  );
};

export default withRouter(OmniSegmentSideBarCard);
