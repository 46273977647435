import React from 'react';

const UserTerms = () => (
  <>
    <h1 style={{ textAlign: 'center' }}>Stackline Terms of Use</h1>
    <p>
      Welcome, and thank you for your interest in Fuelcomm, Inc., d.b.a. Stackline (“<strong>Stackline</strong>,” “
      <strong>we</strong>,” or “<strong>us</strong>
      ”) and our website at www.stackline.com, along with our related websites, networks, applications, and other
      services provided by us (collectively, our “ <strong>Service</strong>”). These Terms of Use are a legally binding
      contract between you and Stackline regarding your use of the Service.
    </p>
    <p>
      PLEASE READ THE FOLLOWING TERMS CAREFULLY. BY CLICKING “I ACCEPT,” OR BY USING THE SERVICE, YOU ACKNOWLEDGE THAT
      YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE FOLLOWING TERMS AND CONDITIONS (THE “
      <strong>TERMS</strong>”). If you are not eligible, or do not agree to the Terms, then you do not have our
      permission to use the Service.
    </p>
    <p>
      These Terms provide that all disputes between you and Stackline will be resolved by BINDING ARBITRATION. YOU AGREE
      TO GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend your rights under this contract, except for matters that
      may be taken to small claims court. Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or
      jury, and your claims cannot be brought as a class action. Please review Section 19 (“Dispute Resolution and
      Arbitration”) for the details regarding your agreement to arbitrate any disputes with Stackline.
    </p>
    <p>
      1. <strong>Stackline Service Overview</strong>. The Service measures data across the largest online retailers to
      give you the most complete view of trends, metrics and shopping behavior.
    </p>
    <p>
      <a name="_Ref315450713" />
      <a name="_Ref398037682" />
      <a name="_Ref341270068">
        2. <strong>Eligibility</strong>. You must be at least 18 years of age to use the Service. By agreeing to these
        Terms, you represent and warrant to us that: (a) you are at least 18 years of age; (b) you have not previously
        been suspended or removed from the Service; and (c) your registration and your use of the Service is in
        compliance with all applicable laws and regulations. If you are using the Service on behalf of an entityService,
        organization, or company, you represent and warrant that you have the authority to bind that organization to
        these Terms and you agree to be bound by these Terms on behalf of that organization
      </a>
      . <strong />
    </p>
    <p>
      3. <strong>Accounts and Registration</strong>. To access most features of the Service, you must register for an
      account. When you register for an account, you may be required to provide us with some information about yourself,
      such as your email address or other contact information. You agree that the information you provide to us is
      accurate and that you will keep it accurate and up-to-date at all times. When you register, you will be asked to
      provide a password. You are solely responsible for maintaining the confidentiality of your account and password,
      and you accept responsibility for all activities that occur under your account. You agree that you will only allow
      the number of users authorized under your account to access the Service and any related tools, applications,
      information, materials provided in connection with the Service. If you have reason to believe that your account is
      no longer secure, then you must immediately notify us at <a>support@stackline.com.</a>
    </p>
    <p>
      <a name="_Ref329019238" />
      <a name="_Ref341270071">
        4. <strong>Payment</strong>.{' '}
      </a>
    </p>
    <p>
      4.1 <strong>Invoicing &amp; Payment. </strong>You agree to pay the fees for the Service that were shown on the
      ordering webpage.<strong> </strong>Fees for the Service will be invoiced in advance and otherwise in accordance
      with the terms set forth on the ordering webpage. Charges are due net 30 days from the invoice date. All payments
      made under these Terms shall be in United States dollars.
    </p>
    <p>
      4.2 <strong>Overdue Payments. </strong>Any payment not received by the due date may accrue (except with respect to
      charges then under reasonable and good faith dispute), at Stackline&apos;s discretion, late charges at the rate of
      1% of the outstanding balance per month, or the maximum rate permitted by law, whichever is lower, from the date
      such payment was due until the date paid.
    </p>
    <p>
      4.3 <strong>Taxes. </strong>Unless otherwise stated, Stackline&apos;s stated fees do not include any local, state,
      federal or foreign taxes, levies or duties of any nature (&quot;<strong>Taxes</strong>&quot;). You are responsible
      for paying all Taxes, excluding only taxes based on Stackline&apos;s income. If Stackline has the legal obligation
      to pay or collect Taxes for which you are responsible under this section, you will be invoiced and agree to pay
      the appropriate amount unless you provide Stackline with a valid tax exemption certificate authorized by the
      appropriate taxing authority.
    </p>
    <p>
      4.4 <strong>Billing and Contact Information. </strong>You agree to maintain complete and accurate billing and
      contact information on the Service at all times.
    </p>
    <p>
      <a name="_Ref337653099">
        5. <strong>User Content</strong>
      </a>
      <strong> </strong>
    </p>
    <p>
      5.1 <strong>User Content Generally</strong>. Certain features of the Service may permit users to upload content to
      the Service, including messages, images, photos, folders, data, text, information about your products and
      services, and other types of works (“<strong>User Content</strong>”).
    </p>
    <p>
      5.2 <strong>Limited License Grant to Stackline</strong>. By uploading or otherwise providing Stackline access to
      User Content, you grant Stackline a worldwide, perpetual, non-exclusive, royalty-free, fully paid right and
      license (with the right to sublicense) to host, store, transfer, display, perform, reproduce, modify for the
      purpose of formatting for display, and distribute your User Content, in whole or in part, in any media formats and
      through any media channels now known or hereafter developed. You waive any rights you may have regarding the User
      Content being altered or manipulated in any way that may be objectionable to you.
    </p>
    <p>
      5.3 <strong>User Content Representations and Warranties</strong>. You are solely responsible for your User Content
      and the consequences of uploading your User Content. By providing your User Content on the Service, you affirm,
      represent, and warrant that:
    </p>
    <p>
      a. you are the creator and owner of the User Content, or have the necessary licenses, rights, consents, and
      permissions to authorize Stackline to use and distribute your User Content as necessary to provide you the Service
      in a manner contemplated by these Terms; and
    </p>
    <p>
      b. your User Content, and the use of your User Content as contemplated by these Terms, does not and will not: (i)
      infringe, violate, or misappropriate any third-party right, including any copyright, trademark, patent, trade
      secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;
      (ii) slander, defame, libel, or invade the right of privacy, publicity or other property rights of any other
      person; or (iii) cause Stackline to violate any law or regulation.
    </p>
    <p>
      <a name="_Ref314748914" />
      <a name="_Ref341218788" />
      <a name="_Ref314760608">
        6. <strong>Prohibited Conduct</strong>
      </a>
      . BY USING THE SERVICE YOU AGREE NOT TO:
    </p>
    <p>
      6.1 use the Service for any illegal purpose or in violation of any local, state, national, or international law;
    </p>
    <p>
      6.2 violate, or encourage others to violate, any right of a third party, including by infringing or
      misappropriating any third party intellectual property right;
    </p>
    <p>
      6.3 interfere with security-related features of the Service, including by: (a) disabling or circumventing features
      that prevent or limit use or copying of any content; or (b) reverse engineering or otherwise attempting to
      discover the source code of any portion of the Service except to the extent that the activity is expressly
      permitted by applicable law;
    </p>
    <p>
      6.4 use a robot, spider, or any similar device to copy or catalog any materials or information made available
      through the Service;
    </p>
    <p>
      6.5 interfere with the operation of the Service or any user’s enjoyment of the Service, including by: (a)
      uploading or otherwise disseminating any virus, adware, spyware, worm, or other malicious code; (b) making any
      unsolicited offer or advertisement to another user of the Service; (c) attempting to collect personal information
      about another user or third party without consent; or (d) interfering with or disrupting any network, equipment,
      or server connected to or used to provide the Service, or violating any regulation, policy, or procedure of any
      such network, equipment, or server;
    </p>
    <p>
      6.6 perform any fraudulent activity including impersonating any person or entityService, claiming a false
      affiliation, accessing any other Service account without permission, or falsifying your age or date of birth;
    </p>
    <p>
      6.7 sell or otherwise transfer the access granted under these Terms or any Materials (as defined in Section 12) or
      any right or ability to view, access, or use any Material; or
    </p>
    <p>
      6.8 attempt to do any of the acts described in this Section 6, or assist or permit any person in engaging in any
      of the acts described in this Section 6.
    </p>
    <p>
      7. <strong>Third-Party Content and Links</strong>. The Service may contain content supplied by third parties and
      links to Internet sites maintained by third parties. Stackline does not, in any respect, control such content or
      operate such third-party sites. Third party content and links are included solely for the convenience of users,
      and do not constitute any endorsement by Stackline or its suppliers. Stackline is not responsible for the accuracy
      or reliability of third party information and you assume sole responsibility for the use of third party
      information.
      <strong />
    </p>
    <p>
      8. <strong>Attribution</strong>. The Service will provide you with data and information related to product and
      service markets and how specific products and services are being sold online (“Stackline Data”). If you
      distribute, display or disclose the Stackline Data to any third party or make any other non-internal use of the
      Stackline Data, you will provide proper attribution to Stackline in a reasonable manner that informs viewers that
      the Stackline Data was generated by Stackline and provide a link to our website. You will discontinue any public
      disclosure of the Stackline Data immediately upon request from us. You may not commercially distribute, display or
      disclose the Stackline Data (e.g. resell the Stackline Data) without Stackline’s prior written consent.
      <strong />
    </p>
    <p>
      <a name="_Ref329019115">
        9. <strong>Termination of Use; Discontinuation and Modification of the Service</strong>
      </a>
      . You may terminate your account at any time by contacting customer service at <a>support@stackline.com.</a> If
      you terminate your account, you remain obligated to pay all outstanding fees, if any, incurred prior to
      termination relating to your use of the Service. If you violate any provision of these Terms, your permission from
      us to use the Service will terminate automatically. In addition, Stackline may in its sole discretion terminate
      your user account on the Service or suspend or terminate your access to the Service at any time for any reason or
      no reason, with or without notice. We also reserve the right to modify or discontinue the Service at any time
      (including by limiting or discontinuing certain features of the Service), temporarily or permanently, without
      notice to you. We will have no liability whatsoever on account of any change to the Service or any suspension or
      termination of your access to or use of the Service. If we terminate your account other than for your violation of
      these Terms, and you have pre-paid for a year-long subscription, we will provide you a pro-rated refund of any
      unused portion of the subscription fees within 30 days of the termination. <strong />
    </p>
    <p>
      <a name="_Ref315034760" />
      <a name="_Ref337675683">
        10. <strong>Additional Terms</strong>
      </a>
      . Your use of the Service is subject to all additional terms, policies, rules, or guidelines applicable to the
      Service or certain features of the Service that we may post on or link to from the Service (the “
      <strong>Additional Terms</strong>”), such as end-user license agreements for any downloadable software
      applications, or rules that apply to a particular feature or content on the Service, subject to Section 11. All
      Additional Terms are incorporated by this reference into, and made a part of, these Terms.
    </p>
    <p>
      <a name="_Ref337674853">
        11. <strong>Modification of these Terms</strong>. We reserve the right, at our discretion, to change these Terms
        on a going-forward basis at any time. Please check these Terms periodically for changes. If a change to these
        Terms materially modifies your rights or obligations, you will be required to accept the modified Terms in order
        to continue to use the Service. Material modifications are effective upon your acceptance of the modified Terms.
        Immaterial modifications are effective upon publication. Disputes arising under these Terms will be resolved in
        accordance with the version of these Terms that was in effect at the time the dispute arose.
      </a>
    </p>
    <p>
      <a name="_Ref337650008">
        12. <strong>Ownership; Proprietary Rights</strong>
      </a>
      . The Service is owned and operated by Stackline. The visual interfaces, graphics, design, compilation,
      information, data, computer code (including source code or object code), products, software, services, and all
      other elements of the Service (“<strong>Materials</strong>”) provided by Stackline are protected by intellectual
      property and other laws. All Materials included in the Service are the property of Stackline or our third-party
      licensors. Except as expressly permitted in Section 8 of these Terms, you may not make use of the Materials. Any
      unauthorized reproduction, publication, further distribution, or public exhibition of the Materials is strictly
      prohibited. Stackline reserves all rights to the Materials not granted expressly in these Terms.
      <strong />
    </p>
    <p>
      13. <strong>Feedback</strong>. If you choose to provide input and suggestions regarding problems with or proposed
      modifications or improvements to the Service (“<strong>Feedback</strong>”), then you hereby grant Stackline an
      unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right to exploit the Feedback in any
      manner and for any purpose, including to improve the Service and create other products and services.
      <strong />
    </p>
    <p>
      14. <strong>Indemnity. </strong>You are responsible for your use of the Service, and you will defend and indemnify
      Stackline and its officers, directors, employees, consultants, affiliates, subsidiaries and agents (together, the
      “<strong>Stackline Entities</strong>”) from and against every claim, liability, damage, loss, and expense,
      including reasonable attorneys&apos; fees and costs, arising out of or in any way connected with: (a) your access
      to, use of, or alleged use of, the Service; (b) your violation of any portion of these Terms, any representation,
      warranty, or agreement referenced in these Terms, or any applicable law or regulation; (c) your violation of any
      third-party right, including any intellectual property right or publicity, confidentiality, other property, or
      privacy right; or (d) any dispute or issue between you and any third party. We reserve the right, at our own
      expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you
      (without limiting your indemnification obligations with respect to that matter), and in that case, you agree to
      cooperate with our defense of that claim.
    </p>
    <p>
      <strong>15. </strong>
      <strong>Disclaimers; No Warranties</strong>
      <strong />
    </p>
    <p>
      THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE ARE PROVIDED “<strong>AS IS</strong>” AND
      ON AN “<strong>AS AVAILABLE</strong>” BASIS, WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED.
      THE STACKLINE ENTITIES DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICE
      AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE, INCLUDING: (A) ANY IMPLIED WARRANTY OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY
      WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. THE STACKLINE ENTITIES DO NOT WARRANT THAT THE SERVICE
      OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THE SERVICE, WILL BE UNINTERRUPTED,
      SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND DO NOT WARRANT THAT ANY OF THOSE ISSUES WILL
      BE CORRECTED.
    </p>
    <p>
      NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR ANY MATERIALS OR CONTENT
      AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF THE STACKLINE ENTITIES OR THE SERVICE THAT
      IS NOT EXPRESSLY STATED IN THESE TERMS. YOU ASSUME ALL RISK FOR ANY DAMAGE THAT MAY RESULT FROM YOUR USE OF OR
      ACCESS TO THE SERVICE, YOUR DEALING WITH ANY OTHER SERVICE USER, AND ANY MATERIALS OR CONTENT AVAILABLE THROUGH
      THE SERVICE. YOU UNDERSTAND AND AGREE THAT YOU USE THE SERVICE, AND USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAIN
      MATERIALS OR CONTENT THROUGH THE SERVICE AND ANY ASSOCIATED SITES OR SERVICES, AT YOUR OWN DISCRETION AND RISK,
      AND THAT YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE
      DEVICE USED IN CONNECTION WITH THE SERVICE), OR THE LOSS OF DATA THAT RESULTS FROM THE USE OF THE SERVICE OR THE
      DOWNLOAD OR USE OF THAT MATERIAL OR CONTENT. <strong />
    </p>
    <p>
      SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM
      JURISDICTION TO JURISDICTION.
    </p>
    <p>
      <a name="_Ref341221031">
        16. <strong>Limitation of Liability</strong>
      </a>
    </p>
    <p>
      IN NO EVENT WILL THE STACKLINE ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
      PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR
      RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT
      ON THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL
      THEORY, AND WHETHER OR NOT ANY STACKLINE ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.
    </p>
    <p>
      EXCEPT AS PROVIDED IN SECTION 19.4(iii), THE AGGREGATE LIABILITY OF THE STACKLINE ENTITIES TO YOU FOR ALL CLAIMS
      ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER
      THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF: (A) THE AMOUNT YOU HAVE PAID
      TO STACKLINE FOR ACCESS TO AND USE OF THE SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT(S) OR CIRCUMSTANCES GIVING
      RISE TO CLAIM; OR (B) $100.
    </p>
    <p>
      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES.
      ACCORDINGLY, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
    </p>
    <p>
      EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION
      OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN
      ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND
      INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION 16 WILL APPLY EVEN IF ANY
      LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
    </p>
    <p>
      <a name="_Ref317080117" />
      <a name="_Ref301190062" />
      <a name="_Ref337639440" />
      <a name="_Ref337639534">
        17. <strong>Governing Law</strong>
      </a>
      . These Terms are governed by the laws of the State of Washington without regard to conflict of law principles. If
      a lawsuit or court proceeding is permitted under these Terms, then you and Stackline agree to submit to the
      personal and exclusive jurisdiction of the state courts and federal courts located within King County, Washington
      for the purpose of litigating any dispute. We operate the Service from our offices in Washington, and we make no
      representation that Materials included in the Service are appropriate or available for use in other locations.{' '}
      <strong />
    </p>
    <p>
      18. <strong>General. </strong>These Terms, together with any other agreements expressly incorporated by reference
      into these Terms, are the entire and exclusive understanding and agreement between you and Stackline regarding
      your use of the Service. Except as expressly permitted above, these Terms may be amended only by a written
      agreement signed by authorized representatives of all parties to these Terms. You may not assign or transfer these
      Terms or your rights under these Terms, in whole or in part, by operation of law or otherwise, without our prior
      written consent. We may assign these Terms at any time without notice or consent. The failure to require
      performance of any provision will not affect our right to require performance at any other time after that, nor
      will a waiver by us of any breach or default of these Terms, or any provision of these Terms, be a waiver of any
      subsequent breach or default or a waiver of the provision itself. Use of section headers in these Terms is for
      convenience only and will not have any impact on the interpretation of any provision. If any part of these Terms
      is held to be invalid or unenforceable, the unenforceable part will be given effect to the greatest extent
      possible, and the remaining parts will remain in full force and effect. Upon termination of these Terms, Sections
      2 and 5 through 20, along with any other accompanying agreements, will survive.
    </p>
    <p>
      <a name="_Ref337663206">
        19. <strong>Dispute Resolution and Arbitration</strong>
      </a>
      <strong />
    </p>
    <p>
      <a name="_Ref337676308">
        19.1 <strong>Generally</strong>. In the interest of resolving disputes between you and Stackline in the most
        expedient and cost effective manner, you and Stackline agree that every dispute arising in connection with these
        Terms will be resolved by binding arbitration. Arbitration is less formal than a lawsuit in court. Arbitration
        uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than in court, and
        can be subject to very limited review by courts. Arbitrators can award the same damages and relief that a court
        can award. This agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of
        these Terms, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and
        regardless of whether a claim arises during or after the termination of these Terms. YOU UNDERSTAND AND AGREE
        THAT, BY ENTERING INTO THESE TERMS, YOU AND STACKLINE ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
        PARTICIPATE IN A CLASS ACTION.
      </a>
    </p>
    <p>
      19.2 <strong>Exceptions</strong>. Despite the provisions of Section 19.1, nothing in these Terms will be deemed to
      waive, preclude, or otherwise limit the right of either party to: (a) bring an individual action in small claims
      court; (b) pursue an enforcement action through the applicable federal, state, or local agency if that action is
      available; (c) seek injunctive relief in a court of law; or (d) to file suit in a court of law to address an
      intellectual property infringement claim.
    </p>
    <p>
      19.3 <strong>Arbitrator</strong>. Any arbitration between you and Stackline will be settled under the Federal
      Arbitration Act, and governed by the Commercial Dispute Resolution Procedures and the Supplementary Procedures for
      Consumer Related Disputes (collectively, “<strong>AAA Rules</strong>”) of the American Arbitration Association (“
      <strong>AAA</strong>”), as modified by these Terms, and will be administered by the AAA. The AAA Rules and filing
      forms are available online at www.adr.org, by calling the AAA at 1-800-778-7879, or by contacting Stackline.
    </p>
    <p>
      <a name="_Ref400658304">
        19.4 <strong>Notice; Process</strong>. A party who intends to seek arbitration must first send a written notice
        of the dispute to the other party by certified U.S. Mail or by Federal Express (signature required) or, only if
        such other party has not provided a current physical address, then by electronic mail (“<strong>Notice</strong>
        ”). Stackline&apos;s address for Notice is:
      </a>
      <a>701 Fifth Ave, Suite 4200, Seattle, WA, 98104.</a>
      The Notice must: (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief
      sought (“<strong>Demand</strong>”). The parties will make good faith efforts to resolve the claim directly, but if
      the parties do not reach an agreement to do so within 30 days after the Notice is received, you or Stackline may
      commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by you or
      Stackline must not be disclosed to the arbitrator until after the arbitrator makes a final decision and award, if
      any. If the dispute is finally resolved through arbitration in your favor, Stackline will pay you the highest of
      the following: (i) the amount awarded by the arbitrator, if any; (ii) the last written settlement amount offered
      by Stackline in settlement of the dispute prior to the arbitrator’s award; or (iii) $1,000.
    </p>
    <p>
      19.5 <strong>Fees</strong>. If you commence arbitration in accordance with these Terms, Stackline will reimburse
      you for your payment of the filing fee, unless your claim is for more than $10,000, in which case the payment of
      any fees will be decided by the AAA Rules. Any arbitration hearing will take place at a location to be agreed upon
      in King County, Washington, but if the claim is for $10,000 or less, you may choose whether the arbitration will
      be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b) through a non-appearance based
      telephone hearing; or (c) by an in-person hearing as established by the AAA Rules in the county (or parish) of
      your billing address. If the arbitrator finds that either the substance of your claim or the relief sought in the
      Demand is frivolous or brought for an improper purpose (as measured by the standards set forth in Federal Rule of
      Civil Procedure 11(b)), then the payment of all fees will be governed by the AAA Rules. In that case, you agree to
      reimburse Stackline for all monies previously disbursed by it that are otherwise your obligation to pay under the
      AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a reasoned
      written decision sufficient to explain the essential findings and conclusions on which the decision and award, if
      any, are based. The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees
      or expenses at any time during the proceeding and upon request from either party made within 14 days of the
      arbitrator’s ruling on the merits.
    </p>
    <p>
      <a name="_Ref337639421">
        19.6 <strong>No Class Actions</strong>. YOU AND STACKLINE AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
        ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
        REPRESENTATIVE PROCEEDING. Further, unless both you and Stackline agree otherwise, the arbitrator may not
        consolidate more than one person’s claims, and may not otherwise preside over any form of a representative or
        class proceeding.
      </a>
    </p>
    <p>
      19.7 <strong>Modifications to this Arbitration Provision</strong>. If Stackline makes any future change to this
      arbitration provision, other than a change to Stackline&apos;s address for Notice, you may reject the change by
      sending us written notice within 30 days of the change to Stackline&apos;s address for Notice, in which case your
      account with Stackline will be immediately terminated and this arbitration provision, as in effect immediately
      prior to the changes you rejected will survive.
    </p>
    <p>
      19.8 <strong>Enforceability</strong>. If Section 19.6 is found to be unenforceable or if the entirety of this
      Section 19 is found to be unenforceable, then the entirety of this Section 19 will be null and void and, in that
      case, the parties agree that the exclusive jurisdiction and venue described in Section 17 will govern any action
      arising out of or related to these Terms.
    </p>
    <p>
      <a name="_Ref398039634" />
      <a name="_Ref341270145">
        20. <strong>Consent to Electronic Communications</strong>. By using the Service, you consent to receiving
        certain electronic communications from us
      </a>
      through the e-mail address listed in your user account.
      <strong />
    </p>
    <p>
      <a name="_Ref378306932">
        21. <strong>Contact Information. </strong>The Service is offered by Fuelcomm, Inc. d.b.a. Stackline, located at{' '}
      </a>
      <a>701 Fifth Ave, Suite 4200, Seattle, WA, 98104. </a>
      You may contact us by sending correspondence to that address or by emailing us at <a>support@stackline.com.</a>
    </p>
    <div>
      <div>
        <div id="_com_5" />
      </div>
    </div>
  </>
);

export default UserTerms;
