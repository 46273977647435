import _cloneDeep from 'lodash/cloneDeep';

import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import {
  buildTrendWidgetConfig,
  buildSummaryTrendColumnGroupsWidget,
  summaryTrendView,
  summarySubTrendView,
  summaryTrendStyle
} from 'src/components/Layout/LayoutUtil';
import { PageLayout, Widget } from 'src/types/application/widgetTypes';
import { AppName } from 'sl-api-connector/types';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import ReduxStore from 'src/types/store/reduxStore';
import { store } from 'src/main';
import OmniCommonSummaryTrend from 'src/components/EntityPage/SummaryTrendChart/OmniCommonSummaryTrend';
import CommonSummaryInfo from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { buildSubtitleDisplayName } from 'src/utils/filters';
import { buildOmniProductsWidget } from 'src/components/EntityGrid/EntityGrid/OmniEntityGridUtils';
import { omniGridField, omniTableViewField } from 'src/components/EntityGrid/EntityGrid/omniGridField';
import { ExportIcon } from 'src/components/SvgIcons';
import PageContextMenu from 'src/components/PageContextMenu';

const RETAIL_PRICE_INDEX_NAME = 'price';
const INVENTORY_INDEX_NAME = 'inventory';
const SHIPPING_INDEX_NAME = 'shipping';
const CONTENT_INDEX_NAME = 'content';
const REVIEW_INDEX_NAME = 'reviews';

export const getLayoutForEntity = (app: ReduxStore['app'], subtab: string, entityType: string): PageLayout => {
  const widgets: Widget[] = [];
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(AppName.Omni, RETAIL_PRICE_INDEX_NAME, 'weekId'));
  const { entityService } = store.getState();
  const entity = entityService.mainEntity;
  const fulfillmentTypeList = ['delivery', 'pickUp', 'shipping'];
  const priceChart = buildTrendWidgetConfig(
    app,
    RETAIL_PRICE_INDEX_NAME,
    { type: entityType },
    'weekId',
    ['retailPrice'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: { enabled: false, legendTitle: 'Retail Price' },
          ...summaryTrendView
        },
        linkTo: { tab: 'price', subtab: 'retailPrice' },
        container: { style: { ...summaryTrendStyle.mainTrend } }
      },
      name: 'common_summary_retail_price',
      CustomComponent: OmniCommonSummaryTrend
    }
  );
  const countOfPromotionsTrendWidgetConfig = buildTrendWidgetConfig(
    app,
    RETAIL_PRICE_INDEX_NAME,
    { type: entityType },
    'weekId',
    ['totalCountOfPromotions'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: {
            legendTitle: 'PROMOTIONS',
            enabled: false
          },
          ...summarySubTrendView
        },
        linkTo: { tab: 'price', subtab: 'promotions' },
        container: { ...summaryTrendStyle.subTrend }
      },
      name: 'common_summary_promotions',
      data: { fulfillmentTypeList },
      CustomComponent: OmniCommonSummaryTrend
    }
  );

  const priceViolationsCountTrendWidgetConfig = buildTrendWidgetConfig(
    app,
    RETAIL_PRICE_INDEX_NAME,
    { type: entityType },
    'weekId',
    ['priceViolationsCountSummaryTrend'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: {
            legendTitle: 'VIOLATIONS',
            enabled: false
          },
          ...summarySubTrendView
        },
        linkTo: { tab: 'price', subtab: 'priceViolations' },
        container: { ...summaryTrendStyle.subTrend }
      },
      name: 'common_summary_countPriceViolations',
      data: { fulfillmentTypeList },
      CustomComponent: OmniCommonSummaryTrend
    }
  );

  const inStockChart = buildTrendWidgetConfig(
    app,
    INVENTORY_INDEX_NAME,
    { type: entityType },
    'weekId',
    ['inStockSummaryTrend'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: { enabled: false, legendTitle: 'In-Stock' },
          ...summaryTrendView
        },
        linkTo: { tab: 'inventory', subtab: 'inStock' },
        container: { style: { ...summaryTrendStyle.mainTrend } }
      },
      name: 'common_summary_inStock_rate',
      data: { fulfillmentTypeList },
      CustomComponent: OmniCommonSummaryTrend
    }
  );

  const availableRate = buildTrendWidgetConfig(
    app,
    INVENTORY_INDEX_NAME,
    { type: entityType },
    'weekId',
    ['availabilityRate'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: { enabled: false, legendTitle: 'AVAILABILITY' },
          ...summarySubTrendView
        },
        linkTo: { tab: 'inventory', subtab: 'availability' },
        container: {
          ...summaryTrendStyle.subTrend
        }
      },
      name: 'common_summary_available_rate',
      CustomComponent: OmniCommonSummaryTrend
    }
  );

  const avgContentScoreChart = buildTrendWidgetConfig(
    app,
    CONTENT_INDEX_NAME,
    { type: entityType },
    'weekId',
    ['contentScore'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: { enabled: false, legendTitle: 'Content Score' },
          ...summaryTrendView
        },
        linkTo: { tab: 'content', subtab: 'contentScore' },
        container: { style: { ...summaryTrendStyle.mainTrend } }
      },
      name: 'common_summary_content',
      CustomComponent: OmniCommonSummaryTrend
    }
  );

  const avgReviewStartChart = buildTrendWidgetConfig(
    app,
    REVIEW_INDEX_NAME,
    { type: entityType },
    'weekId',
    ['stars'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: { enabled: false, legendTitle: 'RATING' },
          ...summarySubTrendView
        },
        linkTo: { tab: 'reviews', subtab: 'reviewTrends' },
        container: {
          ...summaryTrendStyle.subTrend
        }
      },
      name: 'common_summary_rating',
      CustomComponent: OmniCommonSummaryTrend
    }
  );

  const avgFulfillmentTime = buildTrendWidgetConfig(
    app,
    SHIPPING_INDEX_NAME,
    { type: entityType },
    'weekId',
    ['averageFulfillmentTime'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: { enabled: false, legendTitle: 'FULFILLMENT TIME' },
          ...summarySubTrendView
        },
        linkTo: { tab: 'time', subtab: 'fulfillment' },
        container: {
          ...summaryTrendStyle.subTrend
        }
      },
      name: 'common_summary_fulfillment_time',
      CustomComponent: OmniCommonSummaryTrend
    }
  );

  const avgContentAccuracy = buildTrendWidgetConfig(
    app,
    CONTENT_INDEX_NAME,
    { type: entityType },
    'weekId',
    ['contentAccuracy'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: { enabled: false, legendTitle: 'CONTENT ACCURACY' },
          ...summarySubTrendView
        },
        linkTo: { tab: 'content', subtab: 'contentAccuracy' },
        container: {
          ...summaryTrendStyle.subTrend
        }
      },
      name: 'common_summary_content_accuracy',
      CustomComponent: OmniCommonSummaryTrend
    }
  );

  const commonSummary = {
    CustomComponent: CommonSummaryInfo,
    name: 'CommonSummaryInfo',
    view: {
      container: {
        style: {
          marginTop: 41
        }
      },
      name: 'CommonSummaryInfo',
      // eslint-disable-next-line no-shadow
      buildSubTitle: ({ retailer, mainEntity, filters, categories, app }) => {
        const filterApplied = Object.values(filters).some((value) => Boolean(value));
        return filterApplied
          ? buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app).displayName
          : 'All Categories';
      },
      shouldShowFilter: entityType !== 'segment',
      showComparePeriod: true
    },
    data: {
      tabs: [
        {
          name: 'summary',
          displayName: 'Summary'
        }
      ]
    }
  };
  const summaryTrendColumnsWidget = buildSummaryTrendColumnGroupsWidget({ app, entity, weekIdField }, [
    [priceChart, countOfPromotionsTrendWidgetConfig, priceViolationsCountTrendWidgetConfig],
    [inStockChart, availableRate, avgFulfillmentTime],
    [avgContentScoreChart, avgContentAccuracy, avgReviewStartChart]
  ]);
  widgets.push(commonSummary);
  widgets.push(summaryTrendColumnsWidget);
  if (entity.type !== 'product') {
    widgets.push(
      ...buildOmniProductsWidget(omniGridField.retailPrice, omniTableViewField.keyMetric, true, 'keyMetric')
    );
  }
  const subMenuItems = [];

  // segments will have different type of export request.
  if (entityType === 'segment') {
    subMenuItems.push({
      icon: ExportIcon,
      text: 'Add to Download List',
      eventName: 'downloadOmniDataSegments'
    });
  } else {
    subMenuItems.push({
      icon: ExportIcon,
      text: 'Add to Download List',
      eventName: 'downloadOmniData'
    });
  }

  if (entity.type === 'retailer' || entity.type === 'segment') {
    widgets.push({
      CustomComponent: PageContextMenu,
      name: 'pageContextMenu',
      view: {
        name: 'pageContextMenu',
        buttons: subMenuItems
      },
      data: {}
    });
  }

  return {
    widgets,
    CustomPageContainer: GenericPageContainer as any,
    enableComparison: false
  };
};
