import React from 'react';

import { Skeleton, Box } from '@mui/material';

const DiscoverSkeleton = () => {
  return (
    <Box className="is-loading-container" sx={{ width: '100%', marginBottom: '3em' }}>
      <Skeleton style={{ height: 88, backgroundColor: '#f6f9fc', marginBottom: '0.5em' }} />
      {[1, 2, 3, 4, 5, 6, 7].map((i: number) => (
        <Skeleton style={{ height: 55, backgroundColor: '#f6f9fc', marginBottom: '0.25em' }} key={i} />
      ))}
    </Box>
  );
};

export default DiscoverSkeleton;
