import {
  ADVERTISING_DISPLAY_METADATA_FIELDS,
  ADVERTISING_DISPLAY_METRICS_FIELDS
} from 'src/utils/entityDefinitions/fields/beaconAdvertisingFieldDefinitions';
import _cloneDeep from 'lodash/cloneDeep';
import { Parser } from 'expr-eval';

const expressionParser = new Parser();
const BEACON_AD_PRODUCT_METRIC_FIELDS = {
  ..._cloneDeep(ADVERTISING_DISPLAY_METADATA_FIELDS),
  ..._cloneDeep(ADVERTISING_DISPLAY_METRICS_FIELDS)
};

BEACON_AD_PRODUCT_METRIC_FIELDS.conversionRate.aggregationFunction =
  expressionParser.parse('clicks>0?unitsSold/clicks:0');
BEACON_AD_PRODUCT_METRIC_FIELDS.costPerAcquisition.aggregationFunction = expressionParser.parse(
  'unitsSold>0?spend/unitsSold:0'
);

export { BEACON_AD_PRODUCT_METRIC_FIELDS };
