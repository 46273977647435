import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { SectionWrapper, OperationButtons } from '../Widgets/AdCampaignBuilderCommonWidgets';
import { connect } from 'react-redux';
import numeral from 'numeral';

import '../AdCampaignBuilder.scss';

const muiStyles = {
  root: {},
  select: {}
};

const Projections = ({
  adCampaignBuilder: {
    target: { projections }
  }
}) => {
  const projectionsData = [
    [
      { value: numeral(projections.adClicks).format('0,0'), name: 'Ad Clicks' },
      { value: numeral(projections.cpc).format('$1,000.00'), name: 'Cost per Click' },
      { value: numeral(projections.adSpend).format('$0,0'), name: 'Ad Spend' }
    ],
    [
      { value: numeral(projections.adSales).format('$0,0'), name: 'Ad Sales' },
      { value: numeral(projections.incrementalSales).format('$0,0'), name: 'Incremental Sales' }
    ],
    [
      { value: numeral(projections.grossMargin).format('$0,0'), name: 'Gross Margin' },
      { value: numeral(projections.incrementalMargin).format('$0,0'), name: 'Incremental Margin' }
    ],
    [
      { value: numeral(projections.returnOnAdSpend).format('$0.00'), name: 'ROAS' },
      { value: numeral(projections.incrementalReturnOnAdSpend).format('$0.00'), name: 'Incremental ROAS' }
    ]
  ];

  return (
    <div className="ad-manager-container">
      <SectionWrapper
        header="Financial Projections"
        subheader="The metrics below project the financial performance of this campaign over the next four weeks."
        layer={0}
      >
        <div>
          {projectionsData.map((block, idx) => (
            <div key={idx} className="projection-block">
              {block.map(({ name, value }) => (
                <div key={name} className="projection-item">
                  <div className="data">{value}</div>
                  <div className="name">{name}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </SectionWrapper>
      <OperationButtons subtab="projections" />
    </div>
  );
};

Projections.propTypes = {
  classes: PropTypes.object.isRequired,
  adCampaignBuilder: PropTypes.object.isRequired
};

const mapStateToProps = ({ retailer, adCampaignBuilder }) => ({ retailer, adCampaignBuilder });

const ProjectsWithState = connect(mapStateToProps)(Projections);

const EnhancedProjections = withStyles(muiStyles)(ProjectsWithState);

export default EnhancedProjections;
