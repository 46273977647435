import { Box } from '@mui/system';
import React, { useState } from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from '@stackline/ui';
import { GenericBackButton } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeDetails/components/GenericBackButton';
import { useAppSelector, useHistory } from 'src/utils/Hooks';
import { getQueryParamValue, updateUrlQueryParams } from 'src/utils/app';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import { getDisputeTableColumnDefinitions } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/defaultConfigs';
import { useFetchDisputeDetails } from 'src/components/ShortageDisputes/hooks';
import _get from 'lodash/get';
import {
  AsinData,
  ContactUsDetails,
  DisputeQuantityData,
  InitialDetailsDatum,
  SupportingDocument
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/types';
import { useQuery } from 'react-query';
import axios from 'axios';
import {
  INITIAL_DETAILS_COL_DEFS,
  ASIN_DETAILS_COL_DEFS,
  CONTACT_US_COL_DEFS,
  CONTACT_US_EXPANDED_COL_DEFS,
  SUPPORTING_DOCS_COL_DEFS
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeDetails/ColumnDefinitions';
import {
  CONTACT_US_CASE_QUERY_KEY,
  SUPPORTING_DOCS_QUERY_KEY
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/constants';
import DisputeFileUploadModal from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/modals/DisputeFileUploadModal';
import { InvoiceSummaryResponse, ShipmentSummaryResponse } from 'src/components/ShortageDisputes/responseTypes';
import { LifecycleTimeline } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeDetails/LifecycleTimeline';
import NoDataPlaceHolder from 'src/components/BeaconRedesignComponents/common/NoDataPlaceHolder/NoDataPlaceHolder';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

const DisputeDetails = () => {
  const { COLUMN_DEFINITIONS, EXPANDED_COLUMN_DEFINITIONS } = getDisputeTableColumnDefinitions({
    handleToggleRow: () => {}
  });
  const NECESSARY_COLUMN_DEFS = COLUMN_DEFINITIONS.slice(1, COLUMN_DEFINITIONS.length - 2);
  const history = useHistory();
  const invoiceId = getQueryParamValue('invoiceId');
  const retailerId = useAppSelector((state) => state.retailer.id);
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const handleClick = () => {
    history.push(updateUrlQueryParams({ invoiceId: null }));
  };

  const [fileUploadOpen, setFileUploadOpen] = useState(false);

  const { data, isLoading } = useFetchDisputeDetails({ invoiceId: getQueryParamValue('invoiceId') });
  const disputeRow =
    data && data.section && data.section.disputeDetailsBlockData ? data.section.disputeDetailsBlockData : null;

  const disputeQuantityData: DisputeQuantityData = _get(
    data,
    ['section', 'initialDetailsData', 'data', 0, 'aggregations', 'by_originalInvoiceNumber', 0, 'additionalValues'],
    {}
  );

  const asinData: AsinData[] = _get(data, ['section', 'initialDetailsData', 'data', 0, 'documents'], null);

  // Needs to combine data from ASIN data + dispute quantity data set
  const initialDetailsRow: InitialDetailsDatum[] = [
    { ...(disputeQuantityData || {}), ...(asinData ? asinData[0] : {}) }
  ];

  // Fetch contact us data
  const { data: contactUsData, isLoading: contactUsLoading } = useQuery([CONTACT_US_CASE_QUERY_KEY], async () => {
    try {
      const response = await axios.post('api/beacon/ContactUsCaseDetails', {
        beaconClientId,
        retailerId,
        originalInvoiceNumber: invoiceId
      });
      return response.data as ContactUsDetails;
    } catch (err) {
      console.error('Failed to fetch contact us details: ', err);
    }
    return null;
  });

  // Fetch supporting docs data
  const { data: supportingDocsData, isLoading: supportingDocsLoading } = useQuery(
    [SUPPORTING_DOCS_QUERY_KEY],
    async () => {
      try {
        const response = await axios.get(
          `api/shortagesdisputedocument/ListUploadedSupportingDocuments?invoiceNumber=${invoiceId}&retailerId=${retailerId}`
        );
        return response.data as SupportingDocument[];
      } catch (err) {
        console.error('Failed to fetch supporting documents: ', err);
      }
      return null;
    }
  );

  const disputeLifeCycleData: InvoiceSummaryResponse & ShipmentSummaryResponse = {
    ..._get(data, ['section', 'lifeCycleData', 'invoiceSummaryData', 0], {}),
    ..._get(data, ['section', 'lifeCycleData', 'shippingDetailsData', 0], {})
  };

  return (
    <Flex flexDirection="column">
      <Flex marginTop="32px" marginBottom="20px" justifyContent="space-between">
        <Text variant="h4">Dispute Details</Text>
        <GenericBackButton onClick={handleClick} text="Disputes Summary" />
      </Flex>
      <EntityTable
        rowData={disputeRow ? [disputeRow] : []}
        skeletonRows={1}
        isLoading={isLoading}
        columnDefs={NECESSARY_COLUMN_DEFS}
        expandRowDef={EXPANDED_COLUMN_DEFINITIONS}
        showPagination={false}
        shouldModifyColumnDefs={false}
        openRow
        appTableProps={{
          noDataPlaceholder: <NoDataPlaceHolder />
        }}
      />
      {/* Lifecycle  */}
      <Text variant="h4">Dispute Lifecycle</Text>
      {disputeLifeCycleData ? <LifecycleTimeline data={disputeLifeCycleData} /> : null}
      {/* Initial Details */}
      {/* We hide the following 2 tables if we don't have asin data available */}
      {(asinData && asinData.length) || isLoading ? (
        <>
          <Flex flexDirection="column" gap="lg" marginTop="64px">
            <Text variant="h4">Initial Dispute Details</Text>
            <EntityTable
              rowData={initialDetailsRow.length > 0 ? initialDetailsRow : []}
              skeletonRows={1}
              isLoading={isLoading}
              columnDefs={INITIAL_DETAILS_COL_DEFS}
              showPagination={false}
              shouldModifyColumnDefs={false}
              appTableProps={{
                noDataPlaceholder: <NoDataPlaceHolder />
              }}
            />
          </Flex>

          {/* ASIN Details */}
          <Box marginTop="20px">
            <EntityTable
              rowData={asinData || []}
              skeletonRows={1}
              isLoading={isLoading}
              columnDefs={ASIN_DETAILS_COL_DEFS}
              showPagination={false}
              shouldModifyColumnDefs={false}
              appTableProps={{
                noDataPlaceholder: <NoDataPlaceHolder />
              }}
            />
          </Box>
        </>
      ) : null}
      {/* Contact Us Details */}
      {contactUsLoading || contactUsData ? (
        <Flex flexDirection="column" marginTop="64px" gap="lg">
          <Text variant="h4">Contact Us Case Details</Text>
          <EntityTable
            rowData={contactUsData ? [contactUsData] : []}
            skeletonRows={1}
            isLoading={contactUsLoading}
            columnDefs={CONTACT_US_COL_DEFS}
            expandRowDef={CONTACT_US_EXPANDED_COL_DEFS}
            showPagination={false}
            shouldModifyColumnDefs={false}
            appTableProps={{
              noDataPlaceholder: <NoDataPlaceHolder />
            }}
          />
        </Flex>
      ) : null}

      {/* Supporting Documents */}
      <Flex flexDirection="column" marginTop="64px">
        <Text variant="h4">Supporting Documents</Text>
        <Flex flexDirection="column" marginTop="32px" gap="md">
          <Text variant="body1">Documents and attachments that have been uploaded as part of your disputes.</Text>
          <Box>
            <SlButton
              textSx={{
                '-webkit-font-smoothing': 'antialiased',
                '-moz-osx-font-smoothing': 'grayscale',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '500',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                textAlign: 'left',
                color: '#fff'
              }}
              onClick={() => setFileUploadOpen(true)}
              variant="contained"
            >
              Upload Documents
            </SlButton>
          </Box>
          <Box marginTop="32px" marginBottom="20px">
            <Text variant="h4">Uploaded</Text>
          </Box>
        </Flex>
        <Flex flexDirection="column" marginBottom="64px">
          <EntityTable
            rowData={supportingDocsData || []}
            skeletonRows={1}
            isLoading={supportingDocsLoading}
            columnDefs={SUPPORTING_DOCS_COL_DEFS}
            showPagination={false}
            shouldModifyColumnDefs={false}
            appTableProps={{
              noDataPlaceholder: <NoDataPlaceHolder />
            }}
          />
        </Flex>
      </Flex>
      <DisputeFileUploadModal row={disputeRow} open={fileUploadOpen} handleClose={() => setFileUploadOpen(false)} />
    </Flex>
  );
};

export default DisputeDetails;
