import types from './types';
import ReduxStore from 'src/types/store/reduxStore';

const receiveAdPlatformSettingsByClient = (adPlatformSettingsByClient: ReduxStore['adPlatformSettingsByClient']) => ({
  type: types.RECEIVE_ADPLATFORM_SETTINGS_BY_CLIENT,
  adPlatformSettingsByClient,
  receivedAt: Date.now()
});

const clearAdPlatformSettingsByClient = () =>
  ({
    type: types.CLEAR_ADPLATFORM_SETTINGS_BY_CLIENT
  } as const);

export default {
  clearAdPlatformSettingsByClient,
  receiveAdPlatformSettingsByClient
};
