import Waypoint from 'react-waypoint';
import axios from 'axios';
import _capitalize from 'lodash/capitalize';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import _isEmpty from 'lodash/isEmpty';
import _isFinite from 'lodash/isFinite';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _merge from 'lodash/merge';
import _pick from 'lodash/pick';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import queryString from 'qs';
import React from 'react';
import { withBus } from 'react-bus';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { mergeConditions } from 'sl-api-connector/search/conditions';
import { cartesianProduct } from 'sl-api-connector/util';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import { getSearchParamsForRetailer } from 'src/store/modules/app/selectors';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import colors from 'src/utils/colors';
import { replaceConflictingConditions } from 'src/utils/conditions';
import {
  buildCustomDateRangeDisplayName,
  computeComparisonTimePeriod,
  getWeekLastDay,
  getWeekNumber
} from 'src/utils/dateformatting';
import { INDEX_FIELDS, METRICTYPE } from 'src/utils/entityDefinitions';
import { anyNotEq } from 'src/utils/equality';
import { buildSubtitleDisplayName } from 'src/utils/filters';
import fontStyle from 'src/utils/fontStyle';
import { buildMetricValue } from 'src/utils/metrics';
import { generateSearchLink } from 'src/utils/searchLinks';
import { getRetailerIdDisplayName, splitSnakeCase, formatPromoType } from 'src/utils/stringFormatting';
import GenericChart from '../../Charts/GenericChart';
import convertBarChartSeriesToDelimitedData from '../../Charts/GenericChart/SeriesConverters/barChart';
import {
  buildAggregationConditions,
  buildComparisonEntityConditions,
  buildDefaultConditions,
  buildEntityConditions,
  buildMainEntityConditions,
  buildRelatedEntityMarketShareConditions,
  buildSubCategoriesSiblingConditions,
  computeEntityMarketShareMetrics,
  setSubTitle
} from '../Renderer/EntityPageRenderer';
import { buildAggregations } from 'src/components/AdManager/Search';
import { queryParamsEqual, isDrive, shouldShowNewBeacon, shouldShowCriteo } from 'src/utils/app';
import { Indices } from 'src/components/Layout/Advertising/AdManagerPageLayout/types';
import { SHORTAGES_FIELDS } from 'src/utils/entityDefinitions/fields/beaconShortagesFieldDefinitions';
import TopEntityChartV2 from 'src/components/BeaconRedesignComponents/TopEntityChartV2/TopEntityChartV2';
import BeaconChartWithLegend from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import { beaconAggregationFieldOverride } from 'src/components/EntityPage/TopEntitiesChart/BeaconAggregationFieldOverride';
import {
  getESBodyOverridesForParentPlatform,
  modifyESQuery
} from 'src/components/AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';
import { getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';
import saveAs from 'file-saver';

const { CancelToken } = axios;

const propKeysToCheck = [
  'location.pathname',
  'conditions',
  'categoriesByRetailerId',
  'entityService.mainEntity',
  'entityService.comparisonEntity',
  'filters',
  'widget',
  'mainTimePeriod',
  'comparisonTimePeriod'
];

export class TopEntitiesChartInner extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    allWeekIdsByRetailerId: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    aggregationConditions: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired,
    entitySearchService: PropTypes.object.isRequired,
    conditions: PropTypes.object,
    queryConditions: PropTypes.object.isRequired,
    eventBus: PropTypes.object.isRequired,
    uniqueName: PropTypes.string.isRequired,
    widget: PropTypes.object.isRequired,
    brandsFollowing: PropTypes.array.isRequired
  };

  static defaultProps = {
    conditions: undefined
  };

  pageNumber = 1;

  state = {
    isLoading: true,
    groupByFieldSelected: null,
    mainMetricFieldSelected: null,
    comparisonPeriod: undefined,
    mainPeriod: undefined,
    inView: false,
    lastLoadedProps: undefined,
    comparisonDenominatorPath: undefined
  };

  componentDidMount() {
    this.cancelSource = CancelToken.source();
    this.props.eventBus.on(`${this.props.uniqueName}topEntitiesGroupBySwitch`, this.switchGroupByField);
    this.overrideTimePeriods();
    this.setState({
      mainMetricFieldSelected: this.props.widget.view.metricFields[0]
    });
  }

  overrideTimePeriods() {
    const { allWeekIdsByRetailerId, comparisonTimePeriod, mainTimePeriod, retailer, widget } = this.props;
    let comparisonPeriod = comparisonTimePeriod;
    let mainPeriod = mainTimePeriod;

    if (widget.data.timePeriodOverride) {
      const overrideMain = mainTimePeriod.availableMainTimePeriods.find((p) => p.id === widget.data.timePeriodOverride);
      const overrideComparison = computeComparisonTimePeriod(
        allWeekIdsByRetailerId[+retailer.id],
        overrideMain,
        'prior-period'
      );

      if (overrideMain && overrideComparison) {
        comparisonPeriod = {
          ...overrideComparison,
          availableComparisonTimePeriods: comparisonTimePeriod.availableComparisonTimePeriods,
          timePeriodSuffix: {
            dayId: `_${overrideComparison.startDayId}_${overrideComparison.endDayId}`,
            weekId: `_${overrideComparison.startWeek}_${overrideComparison.endWeek}`
          }
        };

        comparisonPeriod.displayName = buildCustomDateRangeDisplayName(
          comparisonPeriod.startDayId,
          comparisonPeriod.endDayId
        );

        mainPeriod = {
          ...overrideMain,
          availableMainTimePeriods: mainPeriod.availableMainTimePeriods,
          timePeriodSuffix: {
            dayId: `_${overrideMain.startDayId}_${overrideMain.endDayId}`,
            weekId: `_${overrideMain.startWeek}_${overrideMain.endWeek}`
          }
        };

        this.setState(
          {
            comparisonPeriod,
            mainPeriod
          },
          () => {
            this.fetchMetrics(this.props);
          }
        );
      }
    } else {
      this.setState(
        {
          comparisonPeriod: this.props.comparisonTimePeriod,
          mainPeriod: this.props.mainTimePeriod
        },
        () => {
          this.fetchMetrics(this.props);
        }
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    /** When these query parameters change we should trigger a refetch */
    const queryParamsToWatch = ['rid', 'edid', 'ew', 'pid', 'sdid', 'sw', 'wr'];

    if (
      anyNotEq(propKeysToCheck, this.props, nextProps) ||
      !queryParamsEqual(this.props.location.search, nextProps.location.search, queryParamsToWatch)
    ) {
      if (typeof this.cancelSource !== typeof undefined) {
        this.cancelSource.cancel('Canceled network request: top');
      }

      this.cancelSource = CancelToken.source();

      // at this time we need to renew the state first or within the overrideTimePeriods function can't renew the state on time then cause switch customize time period not work
      this.setState(
        {
          comparisonPeriod: nextProps.comparisonTimePeriod,
          mainPeriod: nextProps.mainTimePeriod
        },
        () => {
          this.overrideTimePeriods();
        }
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_isEqual(this.state, nextState)) {
      return true;
    }
    const { entitySearchService } = this.props;
    const { entitySearchService: entitySearchServiceNextProps } = nextProps;

    const shouldUpdate = !!(this.state.entitySearchServiceStateNames || []).find(
      (entitySearchServiceStateName) =>
        !_isEqual(
          entitySearchService[entitySearchServiceStateName],
          entitySearchServiceNextProps[entitySearchServiceStateName]
        )
    );

    return shouldUpdate;
  }

  componentDidUpdate(_prevProps, _prevState) {
    if (this.state.mainPeriod && this.state.comparisonPeriod) {
      if (
        (this.props.mainTimePeriod.id !== this.state.mainPeriod.id ||
          this.props.comparisonTimePeriod.id !== this.state.comparisonPeriod.id) &&
        !this.props.widget.data.timePeriodOverride &&
        !this.props.widget.view.forecastPeriodOverride
      ) {
        this.overrideTimePeriods();
      }
    }

    if (this.state.propUpdateCallback) {
      const shouldDeregisterCallback = this.state.propUpdateCallback(this);
      if (shouldDeregisterCallback) {
        // This is valid according to the React docs:
        //
        // "You may call setState() immediately in componentDidUpdate() but note that it must be wrapped in a
        // condition [...]"
        // https://reactjs.org/docs/react-component.html#componentdidupdate
        //
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ propUpdateCallback: null });
      }
    }
  }

  componentWillUnmount() {
    this.cancelSource.cancel('Cancel network request');
    this.props.eventBus.off(`${this.props.uniqueName}topEntitiesGroupBySwitch`, this.switchGroupByField);
  }

  /**
   * Retrieves fetched data from `entitySearchService`
   */
  getEntityMetrics = () => {
    const {
      widget,
      widget: { data },
      entitySearchService
    } = this.props;
    const { groupByFieldSelected } = this.state;

    const configForSelectedGroupByField = data.configByGroupByFieldName[groupByFieldSelected.name];

    if (_isNil(configForSelectedGroupByField)) {
      console.error('Missing configForSelectedGroupByField');
      return null;
    }
    const configSelectedIndexName = configForSelectedGroupByField.indexName;

    const mainEntityMetrics =
      entitySearchService[`${widget.name}_mainEntity_${configSelectedIndexName}_${groupByFieldSelected.name}`];

    const comparisonEntityMetrics =
      entitySearchService[`${widget.name}_comparisonEntity_${configSelectedIndexName}_${groupByFieldSelected.name}`];

    return { mainEntityMetrics, comparisonEntityMetrics };
  };

  // eslint-disable-next-line class-methods-use-this
  getMetricFields(props) {
    return props.widget.view.metricFields;
  }

  // eslint-disable-next-line class-methods-use-this
  getAggregationFields(props, groupByField) {
    return _get(props, ['widget', 'data', 'configByGroupByFieldName', groupByField.name, 'aggregationFields'], []);
  }

  // eslint-disable-next-line class-methods-use-this
  getSortByAggregationField(props) {
    return _get(props, ['widget', 'data', 'sortByAggregationField'], null);
  }

  secondaryRequest(
    primaryResponse,
    { localMainEntityConditions, aggregationFieldConditions, groupByField, aggregations, derivedFields }
  ) {
    const {
      entityService: { comparisonEntity },
      app,
      retailer,
      // eslint-disable-next-line react/prop-types
      fetchEntityMetrics,
      location,
      widget
    } = this.props;
    const { indexName } = widget.data.configByGroupByFieldName[groupByField.name];
    const { pageNumber } = this;
    const { entitySearchServiceStateNames } = this.state;
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });

    const promises = [];
    let enableComparison = false;
    let comparisonEntityConditions = null;
    if (comparisonEntity && widget.view.enableComparison && queryParams.ctype && queryParams.ctype !== 'metric') {
      enableComparison = true;
      comparisonEntityConditions = buildComparisonEntityConditions(
        localMainEntityConditions,
        comparisonEntity,
        app,
        retailer
      );
    }

    if (enableComparison) {
      const brandFilterValues = _get(primaryResponse, ['1', 'retailSales_by_brandId', 'data'], []).map((dataPoint) =>
        _get(dataPoint, ['entity', 'brandId'])
      );
      const brandFilter = {
        fieldName: 'brandId',
        values: brandFilterValues
      };
      const comparisonName = `${widget.name}_comparisonEntity_${indexName}_${groupByField.name}`;

      entitySearchServiceStateNames.push(comparisonName);
      // need to override the retailerId in the aggregation conditions
      const comparisonAggregationConditions = _cloneDeep(aggregationFieldConditions);

      if (comparisonEntity.type === 'retailer') {
        comparisonAggregationConditions.termFilters.forEach((f) => {
          if (f.fieldName === 'retailerId') {
            f.values = [comparisonEntity.id];
          }
        });
      }

      this.setState({ comparisonDenominatorPath: comparisonName });

      const comparisonEntityAsRetailerObj = retailer.availableRetailers.find((r) => r.id === comparisonEntity.id);
      const retailerForRequest =
        comparisonEntity.type === 'retailer' ? comparisonEntityAsRetailerObj || comparisonEntity : retailer;

      const marketShareEntityConditions = _cloneDeep(comparisonEntityConditions);
      comparisonEntityConditions.termFilters.push(brandFilter);
      if (widget.name.startsWith('purchaseRateByTop')) {
        // conversion rate by XXX
        if (comparisonEntity.type === 'retailer') {
          promises.push(
            fetchEntityMetrics(
              comparisonName,
              {
                entity: widget.data.configByGroupByFieldName[groupByField.name].entity,
                retailer: retailerForRequest,
                app,
                indexName,
                derivedFields
              },
              [
                {
                  conditions: _cloneDeep(comparisonEntityConditions),
                  indexName: 'sales',
                  aggregations: [
                    {
                      ...aggregations,
                      conditions: comparisonAggregationConditions
                    }
                  ],
                  pageNumber,
                  pageSize: widget.data.configByGroupByFieldName[groupByField.name].pageSize
                },
                {
                  conditions: _cloneDeep(comparisonEntityConditions),
                  indexName: 'traffic',
                  aggregations: [
                    {
                      ...aggregations,
                      conditions: comparisonAggregationConditions,
                      aggregationFields: [
                        {
                          aggregateByFieldDisplayName: 'Total Clicks',
                          aggregateByFieldName: 'totalClicks',
                          canBeExported: true,
                          function: 'sum'
                        }
                      ]
                    }
                  ],
                  pageNumber,
                  pageSize: widget.data.configByGroupByFieldName[groupByField.name].pageSize
                }
              ],
              _get(this.cancelSource, 'token')
            )
          );
        }
      } else {
        promises.push(
          fetchEntityMetrics(
            comparisonName,
            {
              entity: widget.data.configByGroupByFieldName[groupByField.name].entity,
              retailer: retailerForRequest,
              app,
              indexName,
              derivedFields
            },
            [
              {
                conditions: _cloneDeep(comparisonEntityConditions),
                aggregations: [
                  {
                    ...aggregations,
                    conditions: comparisonAggregationConditions
                  }
                ],
                pageNumber,
                pageSize: widget.data.configByGroupByFieldName[groupByField.name].pageSize
              }
            ],
            _get(this.cancelSource, 'token')
          )
        );
      }

      if (widget.isMarketShare || widget.data.isMarketShare) {
        entitySearchServiceStateNames.push(`${comparisonName}-marketShareTotal`);
        promises.push(
          fetchEntityMetrics(
            `${comparisonName}-marketShareTotal`,
            {
              entity: widget.data.configByGroupByFieldName[groupByField.name].entity,
              retailer: retailerForRequest,
              app,
              indexName,
              derivedFields
            },
            [
              {
                conditions: marketShareEntityConditions,
                aggregations: [
                  {
                    ...aggregations,
                    conditions: comparisonAggregationConditions,
                    groupByFieldName: 'retailerId'
                  }
                ],
                pageNumber,
                pageSize: widget.data.configByGroupByFieldName[groupByField.name].pageSize
              }
            ],
            _get(this.cancelSource, 'token')
          )
        );
      }
    }

    this.setState({
      isLoading: true,
      enableComparison
    });

    Promise.all(promises)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        if (!err.message.includes('Canceled network request')) {
          console.error(err);
        }
      });

    // promises all
  }

  collectBrandsFollowing = () => {
    const { brandsFollowing } = this.props;
    const copyedBrandsFollowing = brandsFollowing.filter((b) => b.id !== 0);
    return { fieldName: 'brandId', condition: 'should', values: copyedBrandsFollowing.map((b) => b.id) };
  };

  fetchMetrics(props = this.props, shouldLoad = true, mainMetricField, groupByFieldFromMulti) {
    const {
      conditions,
      entityService,
      app,
      retailer,
      categories,
      allWeekIdsByRetailerId,
      fetchEntityMetrics,
      location,
      filters,
      widget
    } = props;
    const lazyLoad = _get(widget, ['data', 'lazyLoad'], false);

    if (lazyLoad) {
      if (!this.state.inView) {
        return;
      } else {
        this.setState({
          lastLoadedProps: props
        });
      }
    }
    const PAGE_SIZE_FOR_AGGREGATION_MULTIPLE = 5;
    const { mainPeriod, comparisonPeriod } = this.state;
    const { mainEntity } = entityService;
    const { pageNumber } = this;
    let { groupByFieldSelected } = this.state;
    const queryParams = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    });
    const defaultConditions = buildDefaultConditions(conditions, queryParams);

    // This is a hacky performance optimization --- should be refactored once this component is refactored
    if (widget.name === 'totalClicksByTopsearchTerm' && shouldShowNewBeacon()) {
      const minWeek = comparisonPeriod.startWeek;
      const maxWeek = mainPeriod.endWeek;
      // We filter at aggregation level but this helps us aggregate less documents in Elasticsearch
      // suggested by Subhadeep

      defaultConditions.rangeFilters.push({
        fieldName: 'weekId',
        minValue: minWeek,
        maxValue: maxWeek
      });
    }

    const entityConditions = buildEntityConditions(app, categories, defaultConditions, mainEntity);
    let mainEntityConditions = buildMainEntityConditions(entityConditions, mainEntity, app, retailer, queryParams);
    if (widget.data.additionalQueryConditions) {
      mainEntityConditions = mergeConditions(mainEntityConditions, widget.data.additionalQueryConditions);
    }
    if (widget.replaceSubCategoryWithParent) {
      if (mainEntity.type === 'subcategory' && !_isNil(mainEntity.parentCategoryId)) {
        mainEntityConditions.termFilters = mainEntityConditions.termFilters.filter(
          (tf) => tf.fieldName !== 'subCategoryId'
        );
        mainEntityConditions.termFilters.push({
          fieldName: 'categoryId',
          condition: 'should',
          values: [mainEntity.parentCategoryId]
        });
      } else {
        mainEntityConditions = buildSubCategoriesSiblingConditions(mainEntityConditions, filters);
      }
    }
    const promises = [];
    const entitySearchServiceStateNames = [];
    let { groupByFields } = widget.data;

    if (!groupByFieldSelected || groupByFields.findIndex((x) => x.name === groupByFieldSelected.name) === -1) {
      if (widget.view.defaultGroupByField) {
        groupByFieldSelected = widget.view.defaultGroupByField;
      } else {
        [groupByFieldSelected] = groupByFields;
      }
    }

    if (widget.view.chartPropsOverride && widget.view.chartPropsOverride.enableSwitchingGroupBy) {
      // Check to see if `groupByFieldSelected` is invalid due to a re-render of this component with a new widget
      // that doesn't include that group by field.  If that's the case, reset it.
      const selectedGroupByFieldExists = !!widget.data.configByGroupByFieldName[groupByFieldSelected.name];
      if (selectedGroupByFieldExists) {
        groupByFields = [groupByFieldSelected];
      } else if (widget.view.defaultGroupByField) {
        groupByFieldSelected = widget.view.defaultGroupByField;
      }
    }
    const [groupByField] = groupByFieldFromMulti ? [groupByFieldFromMulti] : groupByFields;
    // we only need to fetch for the current group by field
    const { indexName } = widget.data.configByGroupByFieldName[groupByField.name];

    const { aggregationConditions, comparisonRangeFilters } = buildAggregationConditions(
      app,
      indexName,
      retailer,
      allWeekIdsByRetailerId,
      mainPeriod,
      comparisonPeriod,
      props.aggregationConditions,
      widget.data.weekIdField,
      false,
      true
    );

    const [builtAggregation] = buildAggregations(this.getAggregationFields(props, groupByField));
    if (!builtAggregation) {
      return;
    }
    const { derivations: derivedFields } = builtAggregation;
    let { aggregations: aggregationFields } = builtAggregation;

    const { aggregateByFieldDisplayName } = aggregationFields[0];
    // special case: when in the multiRetailer page using buildMultiGroupByTopEntitiesChartWidgetConfig for market share can't get correct aggregationFields
    if (
      indexName === 'multiretailer' &&
      (aggregateByFieldDisplayName === 'Market Share - Retail Sales' ||
        aggregateByFieldDisplayName === 'Market Share - Units Sold')
    ) {
      aggregationFields = [
        {
          aggregateByFieldDisplayName: 'Retail Sales',
          aggregateByFieldName: 'retailSales',
          function: 'sum',
          ranges: undefined,
          canBeExported: true
        }
      ];
    }

    // Instead of eventBus, we want to override selected MainMetric Field from CommonSummaryWithDropdown
    if (shouldShowNewBeacon() && this.state.mainMetricFieldSelected) {
      const { name } = this.state.mainMetricFieldSelected;

      if (name === 'retailPrice') {
        aggregationFields = beaconAggregationFieldOverride.retailPrice;
      }
    }

    let { aggregationFieldConditions } = builtAggregation;
    if (aggregationConditions && aggregationConditions.termFilters) {
      aggregationFieldConditions.termFilters = aggregationFieldConditions.termFilters.concat(
        aggregationConditions.termFilters
      );
    }
    if (aggregationConditions && aggregationConditions.rangeFilters) {
      aggregationFieldConditions.rangeFilters = aggregationFieldConditions.rangeFilters.concat(
        aggregationConditions.rangeFilters
      );
    }

    aggregationFieldConditions = replaceConflictingConditions(groupByField, aggregationFieldConditions);
    const sortByAggregationField = this.getSortByAggregationField(props);
    const doNotAddAdditionalSortField = sortByAggregationField
      ? aggregationFields.find((field) => field.aggregateByFieldName === sortByAggregationField.aggregateByFieldName)
      : true;

    const aggregations = {
      groupByFieldName: groupByField.name,
      aggregationFields: [
        ...(sortByAggregationField && !doNotAddAdditionalSortField ? [sortByAggregationField] : []),
        ...aggregationFields
      ],
      sortDirection: null,
      sortByAggregationField,
      conditions: aggregationFieldConditions,
      comparisonRangeFilters
    };

    entitySearchServiceStateNames.push(`${widget.name}_mainEntity_${indexName}_${groupByField.name}`);

    let localMainEntityConditions = _cloneDeep(mainEntityConditions);
    // when in retailer page we need to use the condition retailer.
    if ((widget.isMarketShare || widget.data.isMarketShare) && indexName !== 'multiretailer') {
      localMainEntityConditions = buildRelatedEntityMarketShareConditions(
        filters,
        defaultConditions,
        entityConditions,
        mainEntity,
        app,
        retailer
      );

      if (widget.data.additionalQueryConditions) {
        localMainEntityConditions = mergeConditions(localMainEntityConditions, widget.data.additionalQueryConditions);
      }
    }

    localMainEntityConditions = replaceConflictingConditions(groupByField, localMainEntityConditions);

    const additionalConditions = widget.data.configByGroupByFieldName[groupByField.name].conditions;
    if (additionalConditions) {
      localMainEntityConditions = mergeConditions(localMainEntityConditions, additionalConditions);
    }

    if (_get(widget, 'data.orderByOverride')) {
      entitySearchServiceStateNames.push(`${widget.name}_mainEntity_${indexName}_${groupByField.name}_sortOverride`);
    }
    const { displayName } = widget.view;
    if (widget.isMarketShare || widget.data.isMarketShare) {
      // condition should adjust1
      let groupByFieldName = 'retailerId';
      if (
        (displayName === 'Retail Sales by Category' || displayName === 'Units Sold by Category') &&
        app.name === 'atlas' &&
        indexName === 'multiretailer'
      ) {
        groupByFieldName = 'categoryId';
      }
      const clonedLocalMainEntityConditions = _cloneDeep(localMainEntityConditions);
      if (filters.brand) {
        const { termFilters } = clonedLocalMainEntityConditions;
        clonedLocalMainEntityConditions.termFilters = termFilters.filter((t) => t.fieldName !== 'brandId');
      }
      promises.push(
        fetchEntityMetrics(
          `${this.props.uniqueName}-marketShareTotal`,
          {
            entity: widget.data.configByGroupByFieldName[groupByField.name].entity,
            retailer,
            app,
            indexName,
            derivedFields
          },
          [
            {
              conditions: clonedLocalMainEntityConditions,
              aggregations: [
                {
                  ...aggregations,
                  conditions: _cloneDeep(aggregationFieldConditions),
                  groupByFieldName
                }
              ],
              pageNumber,
              pageSize: widget.data.configByGroupByFieldName[groupByField.name].pageSize
            }
          ],
          _get(this.cancelSource, 'token')
        )
      );
    }

    const sortOverride = _get(widget, 'data.orderByOverride');
    if (sortOverride) {
      const fields = [INDEX_FIELDS.getField(app.name, sortOverride.index, sortOverride.fieldName)];
      const [builtAggregationForOrderOverride] = buildAggregations(fields);
      if (!builtAggregationForOrderOverride) {
        return;
      }
      const {
        aggregations: aggregationFieldsForOrderOverrides,
        derivations: derivedFieldsForOrderOverride,
        aggregationFieldConditions: aggregationFieldConditionsForOrderOverride
      } = builtAggregationForOrderOverride;

      if (aggregationConditions && aggregationFieldConditionsForOrderOverride.termFilters) {
        aggregationFieldConditionsForOrderOverride.termFilters =
          aggregationFieldConditionsForOrderOverride.termFilters.concat(aggregationConditions.termFilters);
      }
      if (aggregationConditions && aggregationFieldConditionsForOrderOverride.rangeFilters) {
        aggregationFieldConditionsForOrderOverride.rangeFilters =
          aggregationFieldConditionsForOrderOverride.rangeFilters.concat(aggregationConditions.rangeFilters);
      }

      const aggregationsForOrderOverride = {
        groupByFieldName: groupByField.name,
        aggregationFields: aggregationFieldsForOrderOverrides,
        sortDirection: null,
        sortByAggregationField,
        conditions: aggregationFieldConditionsForOrderOverride,
        comparisonRangeFilters
      };

      promises.push(
        fetchEntityMetrics(
          `${widget.name}_mainEntity_${indexName}_${groupByField.name}_sortOverride`,
          {
            entity: widget.data.configByGroupByFieldName[groupByField.name].entity,
            retailer,
            app,
            indexName: sortOverride.index,
            derivedFieldsForOrderOverride,
            conditions: widget.data.configByGroupByFieldName[groupByField.name].conditions
          },
          [
            {
              conditions: _cloneDeep(localMainEntityConditions),
              aggregations: [
                {
                  ...aggregationsForOrderOverride,
                  conditions: _cloneDeep(aggregationFieldConditionsForOrderOverride)
                }
              ],
              pageNumber,
              pageSize: widget.data.configByGroupByFieldName[groupByField.name].pageSize
            }
          ],
          _get(this.cancelSource, 'token')
        )
      );
    }

    if (_get(widget, ['data', 'lastWeekOnly'], false)) {
      // remove comparison range filter
      aggregations.comparisonRangeFilters = [];
      // update aggregation condition range filter
      aggregationFieldConditions.rangeFilters.forEach((field) => {
        if (field.fieldName === 'weekId') {
          field.minValue = field.maxValue;
        }
      });
    }
    if (widget.name.startsWith('purchaseRateByTop')) {
      // fetch for conversion chart based on entity type
      promises.push(
        fetchEntityMetrics(
          `${widget.name}_mainEntity_${indexName}_${groupByField.name}`,
          {
            entity: widget.data.configByGroupByFieldName[groupByField.name].entity,
            retailer,
            app,
            indexName,
            derivedFields,
            conditions: widget.data.configByGroupByFieldName[groupByField.name].conditions
          },
          [
            {
              conditions: _cloneDeep(localMainEntityConditions),
              indexName: 'sales',
              aggregations: [
                {
                  ...aggregations,
                  conditions: _cloneDeep(aggregationFieldConditions)
                }
              ],
              pageNumber,
              pageSize: widget.data.configByGroupByFieldName[groupByField.name].pageSize
            },
            {
              conditions: _cloneDeep(localMainEntityConditions),
              indexName: 'traffic',
              aggregations: [
                {
                  ...aggregations,
                  conditions: _cloneDeep(aggregationFieldConditions),
                  aggregationFields: [
                    {
                      aggregateByFieldDisplayName: 'Total Clicks',
                      aggregateByFieldName: 'totalClicks',
                      canBeExported: true,
                      function: 'sum'
                    }
                  ]
                }
              ],
              pageNumber,
              pageSize: widget.data.configByGroupByFieldName[groupByField.name].pageSize
            }
          ],
          _get(this.cancelSource, 'token')
        )
      );
    } else {
      const clonedLocalMainEntityConditions = _cloneDeep(localMainEntityConditions);
      const clonedAggregationFieldConditions = _cloneDeep(aggregationFieldConditions);
      let { shouldCache } = false;

      // On the shortages summary page, We add an additional aggregation rangeFilter to exclude products without shortages
      if (mainMetricField === SHORTAGES_FIELDS.skusWithShortages.displayName) {
        clonedAggregationFieldConditions.rangeFilters.push({
          fieldName: SHORTAGES_FIELDS.totalShortages.name,
          minValue: '0.1'
        });
      }

      // add Brand Following Only when calculate the market share
      if (
        (widget.isMarketShare || widget.data.isMarketShare) &&
        (displayName === 'Retail Sales by Category' ||
          displayName === 'Retail Sales by Retailer' ||
          displayName === 'Units Sold by Retailer' ||
          displayName === 'Units Sold by Category') &&
        app.name === 'atlas' &&
        indexName === 'multiretailer'
      ) {
        const brandIdFollowing = this.collectBrandsFollowing();
        clonedLocalMainEntityConditions.termFilters.push(brandIdFollowing);
      }

      //  Added rangeFilter to narrow down ES document search for the following indices and reduce latency when fetching data for EntitiesChart on Drive
      if (
        [
          Indices.adCampaignAdGroupProductDailyMetrics,
          Indices.adCampaignAdGroupTargetDailyMetrics,
          Indices.adEntityDailyMetrics,
          Indices.adCampaignDailyMetrics
        ].includes(indexName)
      ) {
        shouldCache = false;
        clonedLocalMainEntityConditions.rangeFilters = aggregationConditions.rangeFilters;
      }

      const mainEntityOverrides = [
        {
          conditions: clonedLocalMainEntityConditions,
          aggregations: [
            {
              ...aggregations,
              conditions: clonedAggregationFieldConditions
            }
          ],
          pageNumber,
          shouldCache,
          pageSize: widget.data.configByGroupByFieldName[groupByField.name].pageSize,
          pageSizeForAggregation:
            widget.data.configByGroupByFieldName[groupByField.name].pageSize * PAGE_SIZE_FOR_AGGREGATION_MULTIPLE
        }
      ];

      let copyOfOVerrides = _cloneDeep(mainEntityOverrides);

      const parentPlatform = getParentPlatform();

      if (shouldShowCriteo() && parentPlatform && parentPlatform === PARENT_PLATFORMS.CRITEO) {
        const newOverride = [];
        let customRules = [
          {
            action: 'add',
            path: ['conditions', 'termFilters'],
            newObj: {
              fieldName: 'parentPlatform',
              condition: 'must',
              values: [parentPlatform]
            }
          },
          {
            action: 'add',
            path: ['aggregations', '[0]', 'conditions', 'termFilters'],
            newObj: {
              fieldName: 'parentPlatform',
              condition: 'must',
              values: [parentPlatform]
            }
          }
        ];

        if (groupByFieldSelected && groupByFieldSelected.name === 'targetingText') {
          customRules.push({
            action: 'concat',
            path: ['aggregations', '[0]', 'groupByFieldName'],
            newObj: ',pageType'
          });
        }

        // for all retailers remove rid
        if (retailer.id === '0') {
          customRules = [
            ...customRules,
            {
              action: 'remove',
              path: ['aggregations', '[0]', 'conditions', 'termFilters'],
              conditionKey: 'fieldName',
              conditionValue: 'retailerId'
            },
            {
              action: 'remove',
              path: ['conditions', 'termFilters'],
              conditionKey: 'fieldName',
              conditionValue: 'retailerId'
            },
            {
              action: 'update',
              path: ['retailerId'],
              newObj: 999999
            }
          ];
        } else {
          let newSearchType = null;

          const getEntityType = _get(entityService, 'mainEntity.type', null);

          switch (getEntityType) {
            case 'adTarget':
              switch (groupByFieldSelected.name) {
                case 'adGroupId':
                  newSearchType = 'advertising-adCampaignAdGroupTargetDailyMetrics';
                  break;
                case 'targetingText':
                  // newSearchType = 'advertising-adCampaignAdGroupDailyMetrics';
                  break;
                case 'categoryId':
                  // newSearchType = 'advertising-adCampaignAdGroupDailyMetrics';
                  break;
                case 'subCategoryId':
                  // newSearchType = 'advertising-adCampaignAdGroupDailyMetrics';
                  break;
                case 'brandId':
                  // newSearchType = 'advertising-adCampaignAdGroupDailyMetrics';
                  break;
                case 'stacklineSku':
                  // newSearchType = 'advertising-adCampaignAdGroupDailyMetrics';
                  break;
                case 'weekDay':
                  newSearchType = 'advertising-adCampaignAdGroupDailyMetrics';
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }

          if (newSearchType) {
            customRules.push({
              action: 'update',
              path: ['searchType'],
              newObj: newSearchType
            });
          }
        }

        copyOfOVerrides.forEach((_, indx) => {
          const mutatedQuery = modifyESQuery(
            copyOfOVerrides[indx],
            getESBodyOverridesForParentPlatform(parentPlatform, customRules)
          );
          newOverride.push(mutatedQuery);
        });

        copyOfOVerrides = newOverride;
      }

      promises.push(
        fetchEntityMetrics(
          `${widget.name}_mainEntity_${indexName}_${groupByField.name}`,
          {
            entity: widget.data.configByGroupByFieldName[groupByField.name].entity,
            retailer,
            app,
            indexName,
            derivedFields,
            conditions: widget.data.configByGroupByFieldName[groupByField.name].conditions
          },
          copyOfOVerrides,
          _get(this.cancelSource, 'token')
        )
      );
    }
    if (mainMetricField === 'Share of Total Sales') {
      entitySearchServiceStateNames.push(
        `${widget.name}_mainEntity_${indexName}_${groupByField.name}_all_retail_sales`
      );

      const denominatorConditions = _cloneDeep(localMainEntityConditions);
      denominatorConditions.rangeFilters.push({
        fieldName: 'weekId',
        minValue: mainPeriod.startWeek,
        maxValue: mainPeriod.endWeek
      });
      promises.push(
        fetchEntityMetrics(
          `${widget.name}_mainEntity_${indexName}_${groupByField.name}_all_retail_sales`,
          {
            entity: widget.data.configByGroupByFieldName[groupByField.name].entity,
            retailer,
            app,
            indexName: 'sales',
            derivedFields,
            conditions: widget.data.configByGroupByFieldName[groupByField.name].conditions
          },
          [
            {
              conditions: denominatorConditions,
              aggregations: [
                {
                  ...aggregations,
                  conditions: _cloneDeep(aggregationFieldConditions)
                }
              ],
              pageNumber,
              pageSize: 50
              // We aren't guaranteed to have all of the matches for the categories in the promos,
              // so we increase the number increase the likelihood of having all categories present
              // in the promos present here
            }
          ],
          _get(this.cancelSource, 'token')
        )
      );
    }

    this.setState({
      isLoading: shouldLoad,
      entitySearchServiceStateNames,
      groupByFieldSelected,
      groupByFields
    });

    Promise.all(promises)
      .then((res) => {
        this.secondaryRequest(res, {
          localMainEntityConditions,
          aggregationFieldConditions,
          groupByField,
          aggregations,
          derivedFields
        });
        this.setState({
          isLoading: false
        });
      })
      .catch((err) => {
        if (!err.message.includes('Canceled network request')) {
          console.error(err);
        }
      });
  }

  shouldShowLoading = () => this.state.isLoading;

  loadNextPage = () => {
    if (!this.state.isLoading) {
      this.pageNumber += 1;
      this.fetchMetrics(this.props, false);
    }
  };

  /**
   * Since we only fetch a portion of the required at once, we must emit API requests to fetch the top 100 items, await
   * the responses, and then call the bar chart data serializer.
   */
  // Bar charts, not only, but Drive Export CSV
  fetchAndCsvSerializeSeries = async (chartSeries) => {
    const desiredItemCount = 100;
    const itemsPerPage = 50;
    const loadedItems = this.pageNumber * itemsPerPage;
    const { retailer } = this.props;
    // If we've already fetched all the required data, we can take a fast-path and just serialize our
    // current series without having to wait on fetching additional data.
    if (loadedItems >= desiredItemCount) {
      return convertBarChartSeriesToDelimitedData(chartSeries, retailer);
    }

    // Emit the asynchronous request to fetch the desired data
    this.loadNextPage();

    // We need to wait for the request to complete, the data to be inserted into Redux, and the data from Redux
    // to be mapped into `this.props.chartSeries`, so add a function the state that indicates that when this
    // component is updated, it should call `this.state.propUpdateCallback` with the component's `this` context
    // as an argument which will give us the ability to build an updated `chartProps` using the newly fetched data,
    // serialize it, and return the serialized data to be downloaded by the user.
    return new Promise((resolve) => {
      const propUpdateCallback = (componentContext) => {
        // `this.preRender()` will return a falsey value if the current component state/props are not in a valid
        // state to allow rendering the chart.  If this is the case, we bail out and wait for the props/state
        // to be updated again.
        const entityMetrics = componentContext.preRender();
        if (!entityMetrics) {
          return false;
        }

        // Using the new component context belonging to our future selves, construct a fresh chart series
        const { mainEntityMetrics, comparisonEntityMetrics } = entityMetrics;
        const updatedChartSeries = componentContext.buildChartSeries(
          mainEntityMetrics,
          comparisonEntityMetrics,
          componentContext.props.widget.view
        );

        const getChartSeriesLength = (series) => _get(series, '[0].data.length');

        // If the series' data length hasn't changed, that means that data hasn't fetched yet.  Returning `false`
        // from this function indicates that it shouldn't be deregistered, and this will be called again
        // the next time the component updates.
        const oldSeriesLength = getChartSeriesLength(chartSeries);
        const newSeriesLength = getChartSeriesLength(updatedChartSeries);
        if (!_isNil(oldSeriesLength) && !_isNil(oldSeriesLength) && oldSeriesLength === newSeriesLength) {
          return false;
        }

        // It's happened - we've got our updated chart series!
        // Serialize it and return it from this promise, after which it will be downloaded to the user.
        const serializedCsvData = convertBarChartSeriesToDelimitedData(updatedChartSeries, retailer);

        resolve(serializedCsvData);

        // Return `true` to indicate that this callback should be de-registered and not called again.
        return true;
      };

      this.setState({ propUpdateCallback });
    });
  };

  orderSeries = (orderByName, metrics) => {
    // Order metrics by the name order in orderByName.  Any metrics with names not in the
    // sort array will be added at the end
    // Caution: this can mutate redux (unfortunately)
    let newArray = [];
    if (metrics) {
      const data = _get(metrics, 'data', []);

      orderByName.forEach((name) => {
        const match = data.findIndex((d) => _get(d, 'entity.id') === name || d.name === name);
        if (match > -1) {
          newArray.push(data[match]);
          data.splice(match, 1); // remove
        }
      });

      if (data.length > 0) {
        // if we have any leftovers that we were unable to sort, add them at the end
        newArray = newArray.concat(data);
      }

      metrics.data = newArray;
    }

    return metrics;
  };

  buildChartSeries = (mainEntityMetrics, comparisonEntityMetrics, view) => {
    const { groupByFields, groupByFieldSelected, enableComparison, comparisonPeriod, mainPeriod } = this.state;
    const { retailer, widget, entitySearchService, entityService } = this.props;
    const { comparisonEntity } = entityService;

    const hideEmptyCategories = _get(widget, ['data', 'hideEmptyCategories'], false);
    const chartSeries = [];
    if (groupByFields > 1) {
      return null;
    }

    let entityMetricsList = [mainEntityMetrics];
    let timePeriodList = [comparisonPeriod, mainPeriod];
    if (enableComparison) {
      entityMetricsList = [comparisonEntityMetrics, mainEntityMetrics];
      timePeriodList = [mainPeriod];
    }

    if (widget.singleTimePeriodOverride || _get(widget, ['data', 'lastWeekOnly'], false)) {
      timePeriodList = [mainPeriod];
    }

    let orderByName = [];

    const orderByOverride = _get(widget, 'data.orderByOverride');
    const sortFromESS = _get(widget, 'data.sortFromESS');

    if (orderByOverride || sortFromESS) {
      const configForSelectedGroupByField = widget.data.configByGroupByFieldName[groupByFieldSelected.name];
      if (_isNil(configForSelectedGroupByField)) {
        return null;
      }
      let orderMetrics;

      if (orderByOverride) {
        orderMetrics = _get(entitySearchService, [
          `${widget.name}_mainEntity_${configForSelectedGroupByField.indexName}_${groupByFieldSelected.name}_sortOverride`,
          `${orderByOverride.fieldName}_by_${groupByFieldSelected.name}`
        ]);
      } else if (sortFromESS) {
        orderMetrics = _get(entitySearchService, sortFromESS);
      }

      if (orderMetrics) {
        orderByName = orderMetrics.data.map((m) => m.name);
      }
    }

    const metricFields = this.getMetricFields(this.props);

    const [first] = metricFields;
    const noDataMain = [];
    const noDataComparison = [];

    if (first.displayName.includes('Growth')) {
      const [entityMetrics] = entityMetricsList;
      const [metricField] = metricFields;
      let metricsDataByKeyField = entityMetrics[`${metricField.name}_by_${groupByFieldSelected.name}`];
      if (!_isEmpty(orderByName)) {
        metricsDataByKeyField = this.orderSeries(orderByName, metricsDataByKeyField);
      }
      const chartDisplayTimePeriod = timePeriodList[timePeriodList.length - 1];

      const series = {
        groupPadding: 0.15,
        pointPadding: 0.05,
        name: metricField.displayName,
        metricField,
        metricType: metricsDataByKeyField.metricType,
        currencySymbol: metricsDataByKeyField.currencySymbol,
        data: [],
        dataLabels: {
          allowOverlap: true,
          useHTML: true,
          style: {
            color: colors.darkBlue,
            fontSize: '11px',
            fontWeight: fontStyle.regularWeight,
            fontFamily: 'Roboto, sans-serif'
          }
        },
        color: colors.stacklineBlue,
        marker: {
          lineColor: colors.stacklineBlue,
          fillColor: colors.stacklineBlue,
          lineWidth: 3,
          symbol: 'circle'
        },
        timePeriod: chartDisplayTimePeriod.displayName
      };

      const [comparison, main] = timePeriodList;
      const { data } = metricsDataByKeyField;
      const axisCategories = [];

      if (data) {
        data.forEach((dataPoint) => {
          const retailerObj = this.props.retailer.availableRetailers.find((r) => r.id === dataPoint.name);
          if (retailerObj) {
            axisCategories.push({
              ...dataPoint.entity,
              displayName: retailerObj.displayName,
              id: dataPoint.name
            });
          }
          series.data.push([
            retailerObj.displayName,
            dataPoint[`value${comparison.timePeriodSuffix.weekId}`] === 0
              ? 10
              : (dataPoint[`value${main.timePeriodSuffix.weekId}`] -
                  dataPoint[`value${comparison.timePeriodSuffix.weekId}`]) /
                dataPoint[`value${comparison.timePeriodSuffix.weekId}`]
          ]);
        });
      }
      series.legendDiv = `<div><div>${metricField.displayName}</div><div class="legend__primary-date" style="margin-top:5px;">${chartDisplayTimePeriod.displayName}</div></div>`;
      series.categories = axisCategories;
      chartSeries.push(series);
    } else {
      cartesianProduct(entityMetricsList, timePeriodList, metricFields).forEach(
        ([entityMetrics, chartDisplayTimePeriod, metricField]) => {
          const chartSeriesIndex = chartSeries.length;

          const metricFieldName = metricField.name;

          let metricsDataByKeyField = entityMetrics[`${metricFieldName}_by_${groupByFieldSelected.name}`];
          const lastWeekOnly = _get(widget, ['data', 'lastWeekOnly'], false);
          if (first.displayName === 'Share of Total Sales') {
            metricsDataByKeyField = entityMetrics[`${metricField.name}ShareTotalSales_by_${groupByFieldSelected.name}`];
          }

          if (!_isEmpty(orderByName)) {
            metricsDataByKeyField = this.orderSeries(orderByName, metricsDataByKeyField);
          }

          if (!metricsDataByKeyField || !metricsDataByKeyField.currencySymbol) {
            return;
          }

          const name = metricField.displayName;
          const series = {
            groupPadding: 0.15,
            pointPadding: 0.05,
            name,
            metricField,
            metricType: metricsDataByKeyField.metricType,
            currencySymbol: metricsDataByKeyField.currencySymbol,
            data: [],
            dataLabels: {
              allowOverlap: true,
              useHTML: true,
              style: {
                color: colors.darkBlue,
                fontSize: '11px',
                fontWeight: fontStyle.regularWeight,
                fontFamily: 'Roboto, sans-serif'
              }
            },
            color:
              view.chartSeriesColors && view.chartSeriesColors.length > chartSeriesIndex && !lastWeekOnly
                ? view.chartSeriesColors[chartSeriesIndex]
                : colors.stacklineBlue,
            marker: {
              lineColor:
                view.chartSeriesColors && view.chartSeriesColors.length > chartSeriesIndex && !lastWeekOnly
                  ? view.chartSeriesColors[chartSeriesIndex]
                  : colors.stacklineBlue,
              fillColor:
                view.chartSeriesColors && view.chartSeriesColors.length > chartSeriesIndex && !lastWeekOnly
                  ? view.chartSeriesColors[chartSeriesIndex]
                  : colors.stacklineBlue,
              lineWidth: 3,
              symbol: 'circle'
            },
            timePeriod: chartDisplayTimePeriod.displayName
          };
          const { timePeriodFieldName } = metricsDataByKeyField;
          let timeSuffix = chartDisplayTimePeriod.timePeriodSuffix[timePeriodFieldName] || '';
          if (lastWeekOnly) {
            const { endWeek } = chartDisplayTimePeriod;
            timeSuffix = `_${endWeek}_${endWeek}`;
          }

          const dataName = `${
            metricField.name.toLowerCase().includes('count') && metricField.name !== 'dataPointCount'
              ? 'count'
              : 'value'
          }${timeSuffix}`;
          const axisCategories = [];
          metricsDataByKeyField.data.forEach((dataPoint) => {
            let value = dataPoint[dataName];

            // Take absolute value if metricField requires it
            // check that it is a number first though
            if (metricField.useAbsoluteValue && _isFinite(value)) {
              value = Math.abs(value);
            }

            if (value !== undefined && value !== null) {
              if (value === 0 && hideEmptyCategories) {
                // track it as missing data
                if (chartDisplayTimePeriod.id.includes('prior')) {
                  noDataMain.push(dataPoint);
                } else {
                  noDataComparison.push(dataPoint);
                }
              }

              if (view.setSeriesNamesAsCategories) {
                series.data.push([metricField.displayName, value]);
                axisCategories.push({ name: metricField.displayName, id: metricField.name });
              } else if (groupByFieldSelected.name === 'searchTerm') {
                series.data.push([dataPoint.name, value]);
                axisCategories.push({ ...dataPoint.entity, type: 'searchTerm' });
              } else if (groupByFieldSelected.name === 'promoType') {
                dataPoint.entity.name = formatPromoType(dataPoint.entity.id);
                dataPoint.name = dataPoint.entity.name;
                series.data.push([dataPoint.name, value]);
                axisCategories.push(dataPoint.entity);
              } else if (shouldShowCriteo()) {
                const pageTypeValue = _get(dataPoint, ['entity', 'pageType']);
                let _name = dataPoint.name;
                if (
                  pageTypeValue &&
                  pageTypeValue !== 'search' &&
                  groupByFieldSelected &&
                  groupByFieldSelected.name === 'targetingText'
                ) {
                  const dataPointName = _get(dataPoint, ['name'], 'Missing');
                  const pageTypeDisplayLabel = `pageType = "${dataPointName}"`;
                  _name = pageTypeDisplayLabel;
                }
                series.data.push([_name, value]);
                axisCategories.push({ ...dataPoint.entity, name: _name });
              } else {
                series.data.push([dataPoint.name, value]);
                axisCategories.push(dataPoint.entity);
              }
            } else if (
              metricsDataByKeyField.metricType === METRICTYPE.PERCENT ||
              metricsDataByKeyField.metricType === METRICTYPE.VOLUME ||
              metricsDataByKeyField.metricType === METRICTYPE.MONEY
            ) {
              if (hideEmptyCategories) {
                if (chartDisplayTimePeriod.id.includes('prior')) {
                  noDataMain.push(dataPoint);
                } else {
                  noDataComparison.push(dataPoint);
                }
              }

              // default: set the value to 0 if it is missing
              series.data.push([dataPoint.name, 0]);

              if (groupByFieldSelected.name === 'promoType') {
                dataPoint.entity.name = _startCase(_capitalize(splitSnakeCase(dataPoint.entity.id)));
                dataPoint.name = dataPoint.entity.name;
                axisCategories.push(dataPoint.entity);
              } else {
                axisCategories.push(dataPoint.entity);
              }
            }
          });

          let timePeriod = chartDisplayTimePeriod.displayName;
          let subtitle = '';
          if (enableComparison) {
            timePeriod = _get(timePeriodList, ['0', 'displayName'], ''); // default

            if (comparisonEntity.type === 'retailer') {
              if (chartSeriesIndex === 0) {
                const retailerObj = retailer.availableRetailers.find((r) => r.id === comparisonEntity.id);
                if (retailerObj) {
                  subtitle = retailerObj.displayName;
                } else {
                  subtitle = comparisonEntity.retailerName;
                }
              } else if (chartSeriesIndex === 1) {
                subtitle = retailer.displayName;
              }
            }
          }

          series.legendDiv = `<div><div>${name}</div><div class="legend__primary-date" style="margin-top:5px;">${timePeriod}</div></div><div class="legend__primary-date" style="margin-top:5px;">${subtitle}</div></div>`;
          if (groupByFieldSelected.name === 'campaignType') {
            axisCategories.forEach((axisCategoryObject) => {
              if (axisCategoryObject.name === 'SponsoredProductAds') {
                axisCategoryObject.name = 'Sponsored Products';
                // x.id = "Sponsored Products"
              }
              if (axisCategoryObject.name === 'SearchAds') {
                axisCategoryObject.name = 'Sponsored Brands';
              }
              if (axisCategoryObject.name === 'DisplayAds') {
                axisCategoryObject.name = 'Sponsored Display';
              }
            });
          }

          // Replace names for Ad Type
          if (groupByFieldSelected.name === 'derivedCampaignType') {
            axisCategories.forEach((axisCategoryObject) => {
              if (axisCategoryObject.name === 'video') {
                axisCategoryObject.name = 'Sponsored Videos';
              }
              if (axisCategoryObject.name === 'sba') {
                axisCategoryObject.name = 'Sponsored Brands';
              }
            });
          }

          series.categories = axisCategories;
          chartSeries.push(series);
        }
      );
    }
    if (hideEmptyCategories) {
      const emptyCategories = _intersection(
        noDataMain.map((n) => n.name),
        noDataComparison.map((n) => n.name)
      );
      emptyCategories.forEach((emptyCat) => {
        chartSeries.forEach((series) => {
          const catIndex = series.categories.findIndex((cat) => cat.name === emptyCat);
          if (catIndex > -1) {
            series.categories.splice(catIndex, 1);
            series.data.splice(catIndex, 1);
          }
        });
      });
    }

    // Match up series if we are doing a comparison chart

    if (enableComparison && chartSeries.length > 1) {
      const mainSeries = chartSeries[1];
      const comparisonSeries = chartSeries[0];

      const newComparisonSeries = [];
      mainSeries.data.forEach((dataPoint) => {
        const xValue = dataPoint[0];
        const matchingDataPoint = comparisonSeries.data.find((d) => d[0] === xValue);
        // If we don't have a matching point (by x value), then add with a y=0
        if (matchingDataPoint) {
          newComparisonSeries.push(matchingDataPoint);
        } else {
          newComparisonSeries.push([xValue, 0]);
        }
      });

      comparisonSeries.data = newComparisonSeries;
    }

    return chartSeries;
  };

  switchGroupByField = (groupByFieldName) => {
    this.setState(
      {
        groupByFieldSelected: this.props.widget.data.groupByFields.find((x) => x.name === groupByFieldName)
      },
      () => {
        this.fetchMetrics(this.props);
      }
    );
  };

  /**
   * This function is called before rendering the component, acting as a fast-path that checks to make sure that
   * component props and state are valid for rendering the chart.
   *
   * If things aren't valid, `null` will be returned.  Otherwise, `entityMetrics` will be returned.
   */
  preRender = () => {
    const {
      widget,
      entityService: { mainEntity },
      entitySearchService,
      retailer,
      app,
      categories,
      filters
    } = this.props;

    const { groupByFieldSelected, enableComparison } = this.state;

    const entityMetrics = this.getEntityMetrics();

    if (_isNil(entityMetrics)) {
      return null;
    }

    const { mainEntityMetrics, comparisonEntityMetrics } = entityMetrics;
    if (!mainEntityMetrics) {
      return null;
    }

    const comparisonName = this.state.comparisonDenominatorPath;

    if (widget.isMarketShare || widget.data.isMarketShare) {
      const { displayName } = widget.view;
      let categoryGroupByFieldName = 'retailerId';
      if (displayName === 'Retail Sales by Category' || displayName === 'Units Sold by Category') {
        categoryGroupByFieldName = 'categoryId';
      }

      if (displayName === 'Retail Sales by Category' || displayName === 'Retail Sales by Retailer') {
        delete mainEntityMetrics.unitsSold_by_retailerId;
      }

      computeEntityMarketShareMetrics(
        mainEntityMetrics,
        entitySearchService[`${this.props.uniqueName}-marketShareTotal`],
        comparisonEntityMetrics,
        entitySearchService[`${comparisonName}-marketShareTotal`],
        groupByFieldSelected.name,
        categoryGroupByFieldName
      );
    }

    const [mainMetricField] = this.getMetricFields(this.props);

    if (
      _isNil(_get(mainEntityMetrics, [`${mainMetricField.name}_by_${groupByFieldSelected.name}`, `currencySymbol`])) ||
      (enableComparison &&
        !_get(comparisonEntityMetrics, [`${mainMetricField.name}_by_${groupByFieldSelected.name}`, `currencySymbol`]))
    ) {
      return null;
    }

    const subtitle = buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app);

    setSubTitle(subtitle, [mainEntityMetrics]);

    // Returning `entityMetrics` here indicates that we are good to render the chart - all necessary data is present
    // in props and/or state.

    return entityMetrics;
  };

  buildChartProps() {
    const {
      widget: {
        view,
        view: { chartPropsOverride },
        data
      },
      entityService: { mainEntity },
      retailer,
      app,
      user,
      categories,
      filters
    } = this.props;

    const { isRestrictedDemoUser } = user.config && user.config;
    const { additionalParams, searchParams } = app ? app.queryParams : {};
    let [mainMetricField] = this.props.widget.view.metricFields; // this is always set to the first element

    const { groupByFieldSelected } = this.state;

    const entityMetrics = this.preRender();

    if (!entityMetrics) {
      return null;
    }

    const { mainEntityMetrics, comparisonEntityMetrics } = entityMetrics;
    const subtitle = buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app);
    setSubTitle(subtitle, [mainEntityMetrics]);

    const chartSeries = this.buildChartSeries(mainEntityMetrics, comparisonEntityMetrics, view);

    let mainCategories = [];
    chartSeries.forEach((series) => {
      if (mainCategories.length < series.categories.length || this.state.enableComparison) {
        mainCategories = series.categories;
      }
    });
    chartSeries.forEach((series) => {
      series.categories = mainCategories;
    });

    if (chartSeries.length > 0) {
      mainMetricField = chartSeries[0].metricField;
    }

    const isSameMetricComparison = this.getMetricFields(this.props).length === 1;

    const decimalPlaces = isDrive ? 2 : 1;

    let chartProps = {
      chart: { type: 'bar', spacingLeft: 20, spacingRight: 20 },
      title: { text: view.displayName },
      subtitle: { text: subtitle.displayName },
      plotOptions: {
        fillEnabled: true,
        series: {
          groupPadding: 0.15,
          pointPadding: 0.05,
          dataLabels: {
            enabled:
              (chartPropsOverride && chartPropsOverride.dataLabels && chartPropsOverride.dataLabels.enabled) || true,
            crop: false,
            overflow: 'none',
            allowOverlap: true,
            style: {
              color: colors.darkBlue,
              'font-size': '14px',
              'font-weight': fontStyle.regularWeight,
              'font-family': 'Roboto, sans-serif'
            },
            className: 'sl-plot-options-labels',
            formatter() {
              // Formats labels above the columns
              // TODO: figure out why the formatter runs and returns a value but does not display on the first load
              const formattedMetricValue = convertMetricToDisplayValue(
                retailer,
                this.y,
                mainMetricField.metricType,
                retailer.currencySymbol,
                mainMetricField.metricType === 'PERCENT'
                  ? !['contentScore', 'contentAccuracy'].includes(mainMetricField.name)
                  : false,
                {
                  decimalPlaces
                }
              );

              // modifying comparison entity data label (currency)
              // applicable when
              // - comparing entity with retailer or metric &&
              // - the metric type of data is currency &&
              // - main entity currency !== comparison entity currency
              if (
                mainMetricField.metricType === 'MONEY' &&
                this.color &&
                this.color === '#adbdcc' &&
                chartSeries &&
                chartSeries.length >= 1 &&
                chartSeries[0].currencySymbol
              ) {
                return chartSeries[0].currencySymbol + formattedMetricValue.slice(1);
              }

              return formattedMetricValue;
            }
          }
        }
      },
      legend: {
        enabled: true,
        itemStyle: {
          'font-size': '14px'
        },
        labelFormatter() {
          return this.userOptions.legendDiv;
        }
      },
      tooltip: {
        formatter() {
          // TODO: add disable logic here for overall and individually selected metrics
          if (_get(view, ['hideTooltip'], false)) {
            return '<div></div>';
          }

          let xValue = this.x.name;
          if (this.x.keyFieldName === 'retailerId') {
            xValue = getRetailerIdDisplayName(this.x.name, retailer);
          }
          const xAxisLabelDiv = `<div style="display:inline-block; float:left; color:${colors.darkBlue};">
                ${
                  xValue || xValue === ''
                    ? `${xValue.slice(0, 10)}${xValue.length >= 10 ? '...' : ''}`
                    : getWeekLastDay(getWeekNumber(this.x)[2]).replace('-', ' ')
                }
                </div>`;
          let tooltipStyle = '';
          let percentChangeDiv = '';
          // compute metric change, only if there are two metrics and they are of same type
          if (isSameMetricComparison && this.points.length > 1) {
            let metricsChange = { icon: '+', color: colors.green };
            let percentChange =
              this.points[0].y > 0
                ? (this.points[1].y - this.points[0].y) / this.points[0].y
                : this.points[1].y > 0
                ? 100
                : 0;
            // if the change is negative, then display in red with minus icon
            if (percentChange < 0) {
              metricsChange = { icon: '\u2212', color: colors.red };
              percentChange *= -1;
            }
            percentChange = buildMetricValue(
              percentChange,
              METRICTYPE.PERCENT,
              chartSeries[0].currencySymbol,
              true,
              null,
              retailer.locale
            ).value;
            const tooltipWidth =
              100 +
              (this.points[0].x.name
                ? Math.min(this.points[0].x.name.length, 10) * 6
                : percentChange.toString().length * 7);
            percentChangeDiv = `<div style="display:inline-block; float:right; color:${metricsChange.color};">
                ${metricsChange.icon}${percentChange}%
              </div>`;
            tooltipStyle += `width: ${tooltipWidth}px;`;
          }

          let yAxisMetricsDiv = '';
          for (let i = this.points.length - 1; i >= 0; i -= 1) {
            const value = this.points[i];
            const { color } = chartSeries[value.series.index];
            const metricValue = buildMetricValue(
              value.y,
              chartSeries[i].metricType,
              chartSeries[i].currencySymbol,
              true,
              null,
              retailer.locale
            );
            yAxisMetricsDiv += `<div style="margin-top:5px;color:${color};">
                ${metricValue.prefix || ''}${metricValue.value}
                <span class='sl-metric__suffix'>${metricValue.suffix || ''}</span>
              </div>`;
          }
          return `<div class="hichart-tooltip" style="${tooltipStyle}" >
                      <div style="overflow:hidden;" class="hiline-chart-tooltipx">
                        ${xAxisLabelDiv}
                        ${percentChangeDiv}
                      </div>
                      <div class="hiline-chart-tooltipy">
                        ${yAxisMetricsDiv}
                      </div>
                    </div>`;
        },
        positioner(labelWidth, labelHeight, point) {
          return { x: point.plotX, y: point.plotY - 20 };
        }
      },
      yAxis: [],
      xAxis: []
    };

    const {
      config: {
        vendor: { BeaconClientId: clientId }
      }
    } = user;
    const { mainTimePeriod, comparisonTimePeriod } = this.props;

    chartSeries.forEach((series) => {
      chartProps.yAxis.push({
        labels: {
          enabled: false,
          formatter() {
            const val = buildMetricValue(
              this.value,
              series.metricType,
              series.currencySymbol,
              true,
              null,
              retailer.locale
            );

            return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
          }
        }
      });
      chartProps.xAxis.push({
        categories: series.categories,
        labels: {
          useHTML: true,
          formatter() {
            let name = this.value.name ? this.value.name : '';
            if (isRestrictedDemoUser && this.value.type !== 'category' && this.value.type !== 'subcategory') {
              return name;
            }

            if (this.value.type) {
              if (this.value.type === 'retailer') {
                const searchParamsWithUpdatedRetailer = getSearchParamsForRetailer(
                  app,
                  retailer,
                  this.value.id,
                  mainTimePeriod,
                  comparisonTimePeriod
                );

                const retailerEntity = retailer.availableRetailers.find((r) => r.id === this.value.id);
                if (retailerEntity) {
                  name = retailerEntity.displayName;
                }

                const metric = _get(data, 'configByGroupByFieldName.retailerId.aggregationFields[0].name', '');
                if (metric === 'contentAccuracy') {
                  return `<a target="_blank" style="margin-top: 5px; display: inline-block;" href="/client/${clientId}${searchParamsWithUpdatedRetailer}&tab=content&subtab=contentAccuracy">${name}</a>`;
                }

                if (metric === 'contentScore') {
                  return `<a target="_blank" style="margin-top: 5px; display: inline-block;" href="/client/${clientId}${searchParamsWithUpdatedRetailer}&tab=content&subtab=contentScore">${name}</a>`;
                }
              }

              if (this.value.type === 'searchTerm') {
                return `<a target="_blank" style="margin-top: 5px; display: inline-block;" href="${generateSearchLink(
                  retailer.id,
                  name
                )}">${name}</a>`;
              }

              if (this.value.type === 'retailer') {
                const retailerEntity = retailer.availableRetailers.find((r) => r.id === this.value.id);
                return retailerEntity ? retailerEntity.displayName : this.value.id;
              }

              if (this.value.type === 'parentBrand') {
                return `<span style="margin-top: 5px; display: inline-block;">${name}</span>`;
              }

              const id = this.value.type === 'product' ? this.value.stacklineSku : this.value.id;
              return `<a style="margin-top: 5px; display: inline-block;" href="/${
                this.value.type === 'campaign' ? 'adCampaign' : this.value.type
              }/${encodeURI(id)}${searchParams}${additionalParams}">${name}</a>`;
            }
            return name;
          },
          style: {
            color: colors.darkBlue,
            fontSize: '13px',
            fontWeight: fontStyle.regularWeight,
            fontFamily: 'Roboto, sans-serif'
          }
        }
      });
    });
    if (view.chartPropsOverride.enableSwitchingGroupBy && data.groupByFields.length > 0) {
      chartProps.title.text = `${mainMetricField.displayName} by ${groupByFieldSelected.displayName}`;

      chartProps.exporting = chartProps.exporting || {};
      chartProps.exporting.enabled = true;
      chartProps.exporting.buttons = data.groupByFields.map(({ name, displayName }) => ({
        eventId: name,
        eventName: `${this.props.uniqueName}topEntitiesGroupBySwitch`,
        isSelected: name === groupByFieldSelected.name,
        text: displayName
      }));
    }
    chartProps = _merge(chartProps, chartPropsOverride);

    // Limit the max number of elements on the page
    if (_get(chartSeries, '[0].data', []).length && chartProps.xAxis[0] && chartProps.xAxis[0].max) {
      // overwrite the xAxis.max, to make the chart fit to data is it have less that 10 data.
      chartProps.xAxis[0].max = Math.min(chartProps.xAxis[0].max, chartSeries[0].data.length - 1);
    } else if (chartProps.xAxis[0] && _get(chartSeries, '[0].data', []).length > 10) {
      // default: set to maximum 10 elements if > 10 in series
      chartProps.xAxis[0].max = 9;
    }

    return { chartProps, chartSeries };
  }

  renderChart = () => {
    const { mainPeriod, comparisonPeriod } = this.state;

    const { data: widgetData } = this.props.widget;
    const chartParams = this.buildChartProps();

    if (!chartParams || !comparisonPeriod || !mainPeriod) {
      return <GenericChartLoading style={{ transform: shouldShowNewBeacon() ? 'translateX(-26px)' : undefined }} />;
    }
    const { chartProps, chartSeries } = chartParams;

    if (shouldShowNewBeacon()) {
      const title = chartSeries[0] && widgetData.groupByFields.length > 1 ? chartSeries[0].name : chartProps.title.text;
      const { groupByFields } = widgetData;

      const handleGroupByChange = ({ target }) => {
        const newGroupByFieldSelected = groupByFields.find((x) => x.name === target.value);
        this.setState({ groupByFieldSelected: newGroupByFieldSelected, selectedGroupBy: target.value }, () => {
          this.fetchMetrics(this.props);
        });
      };

      const handleMainMetricChange = ({ target }) => {
        const newMainMetricFieldSelected = this.props.widget.view.metricFields.find((x) => x.name === target.value);
        this.setState(
          {
            mainMetricFieldSelected: newMainMetricFieldSelected,
            selectedMetricFieldName: newMainMetricFieldSelected.displayName
          },
          () => {
            this.fetchMetrics(this.props);
          }
        );
      };

      const titleToUse =
        _get(this.props.widget, ['view', 'titleOverride']) ||
        this.props.widget.view.metricFields[0].displayName ||
        title;
      return (
        <BeaconChartWithLegend
          title={titleToUse}
          primaryLegendProps={{
            displayName: this.props.widget.view.mainLegendTitle || this.props.mainTimePeriod.displayName
          }}
          comparisonLegendProps={{
            displayName: this.props.widget.view.comparisonLegendTitle || this.props.comparisonTimePeriod.displayName
          }}
          groupByFields={widgetData.groupByFields}
          handleGroupBy={handleGroupByChange}
          handleMainMetric={handleMainMetricChange}
          convertSeriesToDelimitedData={async () => {
            // Prepare the arguments for fetching data
            const propsForFetch = [
              chartSeries,
              this.props.comparisonTimePeriod.id === 'prior-period',
              this.props.comparisonTimePeriod,
              this.props.mainTimePeriod
            ];

            // Convert the chart series to delimited data (may return a promise)
            const data = await this.fetchAndCsvSerializeSeries(...propsForFetch);

            // Create a blob with the data and save it as a CSV file
            const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
            saveAs(blob, `${titleToUse}.csv`);
          }}
          selectedGroupBy={this.state.groupByFieldSelected}
          mainMetricFields={this.props.widget.view.metricFields}
          selectedMainMetric={this.state.mainMetricFieldSelected}
        >
          <TopEntityChartV2
            chartSeries={chartSeries}
            chartProps={chartProps}
            loadNextPage={this.loadNextPage}
            convertSeriesToDelimitedData={this.fetchAndCsvSerializeSeries}
            selectedGroupBy={
              this.props.widget.view.selectedGroupByFieldName
                ? this.props.widget.view.selectedGroupByFieldName({
                    topEntitySelectedGroupBy: this.state.groupByFieldSelected
                  })
                : undefined
            }
            selectedMinMetric={
              this.props.widget.view.selectedMainMetricFieldName
                ? this.props.widget.view.selectedMainMetricFieldName({
                    mainMetricFieldName: this.state.mainMetricFieldSelected
                  })
                : undefined
            }
            forecastedData={
              this.props.widget.view.buildForecastData && chartSeries.length > 0
                ? this.props.widget.view.buildForecastData({ chartSeries: chartParams.chartSeries })
                : undefined
            }
          />
        </BeaconChartWithLegend>
      );
    }

    return (
      <GenericChart
        chartSeries={chartSeries}
        chartProps={chartProps}
        loadNextPage={this.loadNextPage}
        convertSeriesToDelimitedData={this.fetchAndCsvSerializeSeries}
      />
    );
  };

  shouldFetchMetricsLazyLoad = () => {
    // if the conditions changed or there is no data, then fetch

    return anyNotEq(propKeysToCheck, this.state.lastLoadedProps, this.props) || !this.getEntityMetrics();
  };

  handleWaypointEnter = () => {
    if (!this.state.inView) {
      this.setState(
        {
          inView: true
        },
        () => {
          // if the conditions changed or there is no data, then fetch
          if (this.shouldFetchMetricsLazyLoad()) {
            this.fetchMetrics(this.props);
          }
        }
      );
    }
  };

  handleWaypointExit = () => {
    this.setState({
      inView: false
    });
  };

  render = () => {
    const { widget } = this.props;
    const lazyLoad = _get(widget, ['data', 'lazyLoad'], false);

    if (this.shouldShowLoading()) {
      return (
        <>
          {lazyLoad && <Waypoint onEnter={this.handleWaypointEnter} onLeave={this.handleWaypointExit} />}
          <div style={{ height: '650px' }}>
            <GenericChartLoading style={{ transform: shouldShowNewBeacon() ? 'translateX(-26px)' : undefined }} />
          </div>
        </>
      );
    }

    return (
      <div>
        {lazyLoad && <Waypoint onEnter={this.handleWaypointEnter} onLeave={this.handleWaypointExit} />}
        {this.renderChart()}
      </div>
    );
  };
}

const mapStateToProps = (state) =>
  _pick(state, [
    'app',
    'user',
    'entityService',
    'entitySearchService',
    'comparisonTimePeriod',
    'mainTimePeriod',
    'retailer',
    'categories',
    'filters',
    'allWeekIdsByRetailerId',
    'categoriesByRetailerId',
    'brandsFollowing'
  ]);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

export const enhanceTopEntitiesChart = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withBus('eventBus')
);

const EnhancedTopEntitiesChart = enhanceTopEntitiesChart(TopEntitiesChartInner);

export default EnhancedTopEntitiesChart;
