import React, { createContext, useContext, useEffect, useRef } from 'react';

interface StartPollingOptions {
  interval: number;
  /**
   * Executes the callback function immediately when the polling is started.
   */
  leading?: boolean;
  maxTries?: number;
  /**
   * If true, throws an error when calling startPolling with a label that
   * is already registered. Defaults to true.
   */
  shouldThrow?: boolean;
}

interface PollingContext {
  startPolling: (label: string, callbackFunction: () => any, options: StartPollingOptions) => NodeJS.Timeout;
  stopPolling: (id: string) => void;
}

const PollingContext = createContext<PollingContext | null>(null);

export const usePollingContext = (): PollingContext => {
  const context = useContext(PollingContext);
  if (!context) {
    throw new Error('usePollingContext must be used within a PollingContextProvider');
  }
  return context;
};

export class StartPollingError extends Error {
  public constructor(message: string) {
    super(message);
    this.name = 'StartPollingError';
  }
}

/**
 * Provides functions for executing a callback function at a given
 * interval
 */
export const PollingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const pollers = useRef<Map<string, NodeJS.Timeout>>(new Map());
  const attempts = useRef<Map<string, number>>(new Map());

  const stopPolling = (label: string): void => {
    const poller = pollers.current.get(label);
    if (poller) {
      clearInterval(poller);
      pollers.current.delete(label);
    }
  };

  /**
   * Starts a polling interval that will call the callback function at the specified interval.
   * @param label - A label to identify the polling interval.
   * @param callbackFunction - The function to call at the specified interval.
   */
  const startPolling = (
    label: string,
    callbackFunction: () => any,
    { interval, leading = false, maxTries = null, shouldThrow = true }: StartPollingOptions
  ): NodeJS.Timeout => {
    if (pollers.current.has(label)) {
      if (shouldThrow) {
        throw new StartPollingError(`Polling interval with label ${label} already exists`);
      }
      return pollers.current.get(label);
    }

    // Initialize attempts to 0
    attempts.current.set(label, 0);

    if (leading) {
      const currentAttempts = attempts.current.get(label);
      attempts.current.set(label, currentAttempts + 1);
      callbackFunction();
    }

    const poller = setInterval(() => {
      const currentAttempts = attempts.current.get(label);
      // Check against maxTries if provided
      if (maxTries !== null && currentAttempts >= maxTries) {
        stopPolling(label);
      } else {
        attempts.current.set(label, currentAttempts + 1);
        callbackFunction();
      }
    }, interval);

    pollers.current.set(label, poller);

    return poller;
  };

  useEffect(() => {
    return () => {
      pollers.current.forEach((poller) => clearInterval(poller));
      pollers.current.clear();
      attempts.current.clear();
    };
  }, []);

  return <PollingContext.Provider value={{ startPolling, stopPolling }}>{children}</PollingContext.Provider>;
};
