import { RouteComponentProps } from 'react-router';
import { ValueOf, Entity, AppName } from 'sl-api-connector/types';
import { METRICTYPE, DATATYPE } from 'src/utils/entityDefinitions';
import { RowDatum } from 'src/types/store/storeTypes';
import ReduxStore from 'src/types/store/reduxStore';
import colors from 'src/utils/colors';
import { AD_STATUS_DERIVED } from 'sl-ad-campaign-manager-data-model';

/**
 * The types in this file are related to parts of the application itself and are separate from the
 * API and store. They should *probably* be refactored and put into the areas of the application they are
 * used in.
 */

/**
 * `history.push` pushes a new entry onto the history stack. This redirects
 * the user to a new route.
 * We use this type a lot so we extract it from RouteComponentProps here for
 * convenience.
 */
export type PushFn = RouteComponentProps['history']['push'];

/**
 * Aggregation fields are used to define a field to retrieve from the API.  They specify the field name, the function to
 * use when performing the aggregation (sum the value across all documents, average it, etc.), as well as some metadata
 * about the value/type of the expected value that will be returned.
 *
 * See external docs in `APP_ARCHITECTURE.md` and on Confluence for additional info about aggregations + the API.
 */
export interface AggregationField {
  supportedAppNames: AppName[];
  appName: string;
  name: string;
  indexName: string;
  nameAlias?: string;
  timePeriodAggregationFunctionType?: string;
  aggregationFunction?: string | { variables: () => any };
  aggregationFunctionType?: string;
  displayName: string;
  metricType?: ValueOf<typeof METRICTYPE>;
  dataType?: ValueOf<typeof DATATYPE>;
}

export interface OverridingAggregationField {
  aggregateByFieldDisplayName: string;
  aggregateByFieldName: string;
  function: 'sum' | 'computed';
  aggregateByFormula?: string;
  canBeExported: boolean;
}

/**
 * Metric fields are derivative of `AggregationField`s and represent columns of
 */
export type MetricField = Partial<AggregationField> & {
  cellStyle?: object;
  displayName?: string;
  cellRendererFramework?: React.ComponentType<any>;
  headerComponentFramework?: React.ComponentType<any>;
  metricType?: ValueOf<typeof METRICTYPE>;
  dataType?: ValueOf<typeof DATATYPE>;
  nameAlias?: string;
  valueFormatter?: AGGridValueFormatter;
  cellFormatter?: AGGridValueFormatter;
  headerName?: string;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
};

/**
 * The type of an action dispatched for redux.
 */
export interface ReduxAction {
  type: string;
  [key: string]: any;
}

/**
 * A function which renders one cell of an AG Grid.  See AG Grid docs for more info.
 */
export type AGGridValueFormatter = ({
  data,
  colDef
}: {
  data: { [key: string]: any };
  colDef: { name?: string; field?: string };
}) => string;

/**
 * Return type of updateQueryParams, used when updating the parameters in the URL to match
 * state in some places.
 */
export interface QueryResponse {
  type: string;
  params: {
    rid: number;
    pid: string;
    sw: number;
    ew: number;
    sdid: number;
    edid: number;
    wr: string;
    tab: string;
    subtab: string;
    dropDownSelectionParams: string;
    searchParams: string;
    additionalParams: string;
    nonPersistentParams: string;
    filterParams: string;
    compareParams: string;
  };
}

export interface ESSDataKey {
  apiRequest: any;
  [dataKey: string]: {
    data: RowDatum[];
  };
}

/**
 * The colors used in charts to denote the status of a campaign.
 * As of 7/30/2019, these are only used in one place, the campaign scatter plot.
 * They have been placed in the global types.ts to promote consistency for future charts.
 */
export enum CampaignColor {
  Delivering = colors.green,
  OutOfBudget = '#F35379',
  Paused = '#b86ca9',
  Ended = '#7E8FA8',
  Ineligible = '#ff6347',
  PendingStart = '#637ed4',
  Error = '#F35379',
  None = '#fff'
}

/**
 * Type for queryParams
 */

export interface QueryParams {
  edid: string;
  ew: string;
  pid: string;
  rid: string;
  sdid: string;
  selectedEntityName: string;
  subtab: string;
  sw: string;
  tab: string;
  wr: string;
}

// Ad campaign statuses
export const Delivering = {
  value: AD_STATUS_DERIVED.DELIVERING,
  displayName: 'Delivering',
  color: CampaignColor.Delivering
};
export const Ended = {
  value: AD_STATUS_DERIVED.ENDED,
  displayName: 'Ended',
  color: CampaignColor.Ended
};
export const Archived = {
  value: AD_STATUS_DERIVED.ARCHIVED,
  displayName: 'Archived',
  color: CampaignColor.Ended
};
export const Incomplete = {
  value: AD_STATUS_DERIVED.INCOMPLETE,
  displayName: 'Incomplete',
  color: CampaignColor.OutOfBudget
};
export const Paused = {
  value: AD_STATUS_DERIVED.PAUSED,
  displayName: 'Paused',
  color: CampaignColor.Paused
};
export const OutOfBudget = {
  value: AD_STATUS_DERIVED.OUT_OF_BUDGET,
  displayName: 'Out of Budget',
  color: CampaignColor.OutOfBudget
};
export const Invalid = {
  value: AD_STATUS_DERIVED.INVALID,
  displayName: 'Invalid',
  color: CampaignColor.None
};
export const PendingStart = {
  value: AD_STATUS_DERIVED.PENDING_START,
  displayName: 'Pending Start',
  color: CampaignColor.PendingStart
};
export const Err = {
  value: AD_STATUS_DERIVED.ERROR,
  displayName: 'Error',
  color: CampaignColor.Error
};
export const AdPolicingSuspended = {
  value: 'adPolicingSuspended',
  displayName: 'Ad Policing Suspended',
  color: CampaignColor.Error
};
export const Ineligible = {
  value: 'ineligible',
  displayName: 'Ineligible',
  color: CampaignColor.Error
};
export const MissingDecoration = {
  value: 'missing_decoration',
  displayName: 'Missing Decoration',
  color: CampaignColor.Error
};
export const MissingImage = {
  value: 'missing_image',
  displayName: 'Missing Image',
  color: CampaignColor.Error
};
export const NotBuyable = {
  value: 'not_buyable',
  displayName: ' Not Buyable',
  color: CampaignColor.Error
};
export const MissingBuybox = {
  value: 'not_in_buybox',
  displayName: 'Missing Buybox',
  color: CampaignColor.Error
};

/** This is the interface used to provde props to Highcharts. Just add to this whenever
 * you use a new prop override -- this is not all-inclusive.
 *  */
export interface ChartPropsOverride {
  title?: {
    text?: string;
  };
  horizontalScrolling?: {
    enabled?: boolean;
    step?: number;
  };
  chart?: {
    type?: 'column' | 'bar' | 'donut';
  };
  disablePointSelect?: boolean;
  ignoreZeroValues?: boolean;
}

export interface MainEntityMetrics {
  data: RowDatum[];
  currencySymbol: string;
  locale: string;
  entity: Entity;
  retailer: ReduxStore['retailer'];
  groupByField: MetricField;
  name: string;
  displayName: string;
  dataType: string;
  metricType: ValueOf<typeof METRICTYPE>;
  aggregationFunction: string;
  appName: string;
  indexName: string;
  subtitle: string;
}

export interface OmniTileEntity {
  name: string;
  id: string;
  selectedEntityName: string;
  value?: number;
  totalValue?: number;
  percentValue?: string;
  percentChange?: number;
}

export type Div = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export enum StacklineOptimizationStrategy {
  Manual = 'Manual',
  MaximizeSales = 'MaximizeSales',
  MaximizeClicks = 'MaximizeClicks',
  MaximizeMargin = 'MaximizeMargin'
}

export enum EntityPathname {
  Company = '/company/',
  Client = '/client/',
  Brand = '/brand/',
  Segment = '/segment/',
  BusinessUnit = '/businessunit/',
  SearchTermList = '/searchtermlist/',
  Product = '/product/',
  Category = '/category/',
  Subcategory = '/subcategory/',
  AdCampaign = '/adCampaign/',
  AdPortfolio = '/adPortfolio/',
  AdEntity = '/adEntity/',
  AdTarget = '/adTarget/',
  AdGroup = '/adGroup/',
  Summary = '/summary/',
  Overview = '/overview',
  Discover = '/discover',
  Search = '/search',
  Account = '/account',
  Home = '/home',
  SegmentPowerTools = '/segment-power-tools' // Only in dev mode
}
