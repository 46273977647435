/* eslint-disable react/prop-types */

/**
 * This component displays a weekly sales grid showing metrics week-by-week.  If fetches its own data according to
 * attributes set in `widget.data`.  See the `fetchData` function for which attributes it reads/depends on.
 */

import React, { useEffect } from 'react';
import _pick from 'lodash/pick';
import { connect } from 'react-redux';

import { store } from 'src/main';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import ReduxStore from 'src/types/store/reduxStore';
import { WidgetProps } from 'src/types/application/widgetTypes';
import { buildAggregations } from 'src/components/AdManager/Search';
import WeeklyMetricsGridContainer from 'src/components/EntityGrid/Table/WeeklyMetricsGridContainer';
import { panic } from 'src/utils/mixpanel';

const mapStateToProps = (state: ReduxStore, { widget }: WidgetProps) => {
  const dataKey = `productWeeklySalesGrid-${widget.name || widget.view.name || 'UNKNOWN'}`;

  return {
    ..._pick(state, ['app', 'retailer', 'entityService', 'mainTimePeriod']),
    dataKey,
    dataSet: state.entitySearchService[dataKey]
  };
};

type Props = WidgetProps & ReturnType<typeof mapStateToProps>;

const fetchData = ({
  mainTimePeriod,
  queryConditions,
  app,
  entityService: { mainEntity },
  retailer,
  widget,
  dataKey
}: Props) => {
  // Add the weekId range filter
  if (queryConditions && queryConditions.rangeFilters && mainTimePeriod) {
    queryConditions.rangeFilters.push({
      fieldName: 'weekId',
      minValue: mainTimePeriod.startWeek,
      maxValue: mainTimePeriod.endWeek
    });
  }

  if (!mainEntity) {
    return;
  }

  const { indexName } = widget.data;
  if (!indexName) {
    throw panic('`widget.data.indexName` must be provided in `SelfReliantWeeklySalesGrid`');
  } else if (!widget.data.aggregationFields) {
    throw panic('`widget.data.aggregationFields` must be provided in `SelfReliantWeeklySalesGrid`');
  } else if (!widget.data.chartMainField.name) {
    throw panic('`widget.data.chartMainField.name` must b provided in `SelfReliantWeeklySalesGrid`');
  }
  const groupByFieldName = 'weekId';

  const [{ aggregations: aggregationFields }] = buildAggregations(widget.data.aggregationFields);

  const termFilters = [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id, 10)] }];

  store.dispatch(
    fetchEntityMetrics(
      dataKey,
      {
        entity: mainEntity,
        retailer,
        app,
        indexName
      },
      [
        {
          doAggregation: true,
          aggregations: [
            {
              aggregationFields,
              conditions: {
                termFilters,
                rangeFilters: []
              },
              groupByFieldName
            }
          ],
          conditions: queryConditions,
          pageSize: 1000,
          processDocuments: true
        }
      ]
    )
  );
};

const SelfReliantProductWeeklySalesGrid: React.FC<Props> = (props) => {
  const { queryConditions, entityService, mainTimePeriod, retailer } = props;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchData(props), [queryConditions, mainTimePeriod, entityService.mainEntity, retailer]);

  return <WeeklyMetricsGridContainer {...props} entitySearchServicePropertyName={props.dataKey} />;
};

export default connect(mapStateToProps)(SelfReliantProductWeeklySalesGrid);
