/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { SlDropdownMenuOption } from '@stackline/ui';
import { getQueryParamValue, updateUrlQueryParams } from 'src/utils/app';
import { useHistory, useLocation } from 'src/utils/Hooks';
import { ForecastComparisonPeriod, ForecastPeriod, ForecastType, AdjustmentPlanType } from '../types';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import ForecastCalendarContainer from 'src/components/BeaconRedesignComponents/common/SlCalendarPicker/ForecastCalendarContainer';

export const FORECAST_PERIOD_TO_LABEL_MAP: Record<ForecastPeriod, string> = {
  [ForecastPeriod.FULL_YEAR]: 'Full Year',
  [ForecastPeriod.FIFTY_TWO_WEEKS]: 'Next 52W',
  [ForecastPeriod.TWENTY_SIX_WEEKS]: 'Next 26W',
  [ForecastPeriod.THIRTEEN_WEEKS]: 'Next 13W',
  [ForecastPeriod.FOUR_WEEKS]: 'Next 4W',
  [ForecastPeriod.CUSTOM]: 'Custom'
};

const FORECAST_PERIOD_OPTIONS: SlDropdownMenuOption[] = [
  {
    label: FORECAST_PERIOD_TO_LABEL_MAP[ForecastPeriod.FULL_YEAR],
    id: ForecastPeriod.FULL_YEAR
  },
  {
    label: FORECAST_PERIOD_TO_LABEL_MAP[ForecastPeriod.FIFTY_TWO_WEEKS],
    id: ForecastPeriod.FIFTY_TWO_WEEKS
  },
  {
    label: FORECAST_PERIOD_TO_LABEL_MAP[ForecastPeriod.TWENTY_SIX_WEEKS],
    id: ForecastPeriod.TWENTY_SIX_WEEKS
  },
  {
    label: FORECAST_PERIOD_TO_LABEL_MAP[ForecastPeriod.THIRTEEN_WEEKS],
    id: ForecastPeriod.THIRTEEN_WEEKS
  },
  {
    label: FORECAST_PERIOD_TO_LABEL_MAP[ForecastPeriod.FOUR_WEEKS],
    id: ForecastPeriod.FOUR_WEEKS
  },
  {
    label: FORECAST_PERIOD_TO_LABEL_MAP[ForecastPeriod.CUSTOM],
    id: ForecastPeriod.CUSTOM
  }
];

export const FORECAST_COMPARISON_TO_LABEL_MAP: Record<ForecastComparisonPeriod, string> = {
  [ForecastComparisonPeriod.PRIOR_YEAR]: 'Prior Year',
  [ForecastComparisonPeriod.PRIOR_PERIOD]: 'Prior Period'
};

const FORECAST_TYPE_OPTIONS: SlDropdownMenuOption[] = [
  {
    label: 'Adjusted Forecast',
    id: ForecastType.ADJUSTED
  },
  {
    label: 'Unadjusted Forecast',
    id: ForecastType.UNADJUSTED
  }
];

const FORECAST_PLANS_TYPE_OPTIONS: SlDropdownMenuOption[] = [
  { label: 'All Plan Types', id: AdjustmentPlanType.AllPlanTypes },
  { label: 'Organic Traffic', id: AdjustmentPlanType.OrganicTraffic },
  { label: 'Other Traffic', id: AdjustmentPlanType.OtherTraffic },
  { label: 'Paid Traffic', id: AdjustmentPlanType.PaidTraffic },
  { label: 'Retail Price', id: AdjustmentPlanType.RetailPrice },
  { label: 'Content Score', id: AdjustmentPlanType.ContentScore },
  { label: 'In-Stock Rate', id: AdjustmentPlanType.InstockRate },
  { label: 'Rating & Reviews', id: AdjustmentPlanType.RatingsReviews },
  { label: 'Buy Box', id: AdjustmentPlanType.BuyBox }
];

const buildDropdown = (options: SlDropdownMenuOption[], queryParamKey: string) => () => {
  const [selectedId, setSelectedId] = useState(getQueryParamValue(queryParamKey, options[0].id));
  const history = useHistory();
  const location = useLocation();

  const onChange = (option: SlDropdownMenuOption) => {
    setSelectedId(option.id);
    history.push(updateUrlQueryParams({ [queryParamKey]: option.id }));
  };

  useEffect(() => {
    const newSelectedId = getQueryParamValue(queryParamKey, options[0].id);
    setSelectedId(newSelectedId);
  }, [location.search]);

  return (
    <SlDropdownMenu
      selectedId={selectedId}
      shouldShowFullItemText
      onChange={onChange}
      options={options}
      defaultLabel=""
    />
  );
};

export const ForecastPeriodDropdown = () => {
  const options = FORECAST_PERIOD_OPTIONS;
  const [selectedId, setSelectedId] = useState(getQueryParamValue('forecastPeriod', options[0].id));
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const onChange = (option: SlDropdownMenuOption) => {
    if (option.id === ForecastPeriod.CUSTOM) {
      setIsCalendarOpen(true);
    } else {
      setSelectedId(option.id);
      history.push(updateUrlQueryParams({ forecastPeriod: option.id }));
    }
  };

  useEffect(() => {
    const newSelectedId = getQueryParamValue('forecastPeriod', options[0].id);

    // If the selected id is not a predetermined forecast period, default to custom
    if (
      ![
        ForecastPeriod.FULL_YEAR,
        ForecastPeriod.FIFTY_TWO_WEEKS,
        ForecastPeriod.TWENTY_SIX_WEEKS,
        ForecastPeriod.THIRTEEN_WEEKS,
        ForecastPeriod.FOUR_WEEKS
      ].includes(newSelectedId as ForecastPeriod)
    ) {
      setSelectedId(ForecastPeriod.CUSTOM);
    } else {
      setSelectedId(newSelectedId);
    }
  }, [location.search, options]);

  return (
    <>
      <SlDropdownMenu selectedId={selectedId} onChange={onChange} options={options} defaultLabel="" />
      {isCalendarOpen && (
        <ForecastCalendarContainer
          setSelectedId={setSelectedId}
          open={isCalendarOpen}
          setCalendarOpen={setIsCalendarOpen}
        />
      )}
    </>
  );
};

export const ForecastTypeDropdown = buildDropdown(FORECAST_TYPE_OPTIONS, 'forecastType');

export const ForecastPlanTypeDropdown = buildDropdown(FORECAST_PLANS_TYPE_OPTIONS, 'forecastPlansType');
