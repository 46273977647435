import React, { useState } from 'react';
import { useStacklineTheme, SlStatusChip, SlStatusChipColor } from '@stackline/ui';
import IconMore from '!svg-react-loader!./assets/iconMore.svg'; //eslint-disable-line
import IconLess from '!svg-react-loader!./assets/iconLess.svg'; //eslint-disable-line
import { Link } from 'react-router-dom';
import { Text } from '../Generic/Text';
import { SlColumn } from 'src/components/BeaconRedesignComponents/Generic/SlColumn';
import { SlRow } from 'src/components/BeaconRedesignComponents/Generic/SlRow';
import {
  Direction,
  getColorForMetricChangeValue,
  getIconForMetricChange
} from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import { PRODUCT_CARD_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { BeaconProImageFallback } from 'src/components/SvgIcons';

export interface ProductCardProps {
  mainDisplayTitle: string;
  secondaryDisplayTitle: string;
  imageUri: string;
  mainMetric: string;
  secondaryMetric: string;
  availabilityStatusCode: string;
  direction: Direction;
  stacklineSku: string;
  retailerSku: string;
  categoryName: string;
  subCategoryName: string;
  statusColor: SlStatusChipColor;
  productUrl: string;
  brandUrl: string;
  retailerUrl: string;
  categoryUrl: string;
  subcategoryUrl: string;
  showDirection?: boolean;
  showExcludeProductButton?: boolean;
  handleExcludeProduct?: (retailerSku: string) => void;
}

/** How long the flip animation should last, in seconds */
const FLIP_TIME = 0.4;

/**
 * Product image that will be rendered in product card, and if image is failed to load, this will render image fallback icon.
 */
export const ProductImage = ({
  imageUri,
  secondaryDisplayTitle,
  mainDisplayTitle,
  width = '106px',
  height = '106px',
  fallbackImageStyle = {}
}: {
  imageUri: string;
  mainDisplayTitle: string;
  secondaryDisplayTitle: string;
  width?: number | string;
  height?: number | string;
  fallbackImageStyle?: React.CSSProperties;
}) => {
  const [imageError, setImageError] = useState(false);

  if (imageError) {
    return (
      <BeaconProImageFallback
        style={{
          width: '78.2px',
          height: '68.2px',
          objectFit: 'cover',
          marginTop: '12.5px',
          marginBottom: '21.5px',
          ...fallbackImageStyle
        }}
      />
    );
  }

  return (
    <img
      style={{
        height,
        maxWidth: width,
        objectFit: 'contain'
      }}
      src={imageUri}
      alt={secondaryDisplayTitle || mainDisplayTitle}
      onError={() => setImageError(true)}
    />
  );
};

/**
 * Shows the details for a product card.
 */
export const ProductCardInner = ({
  availabilityStatusCode,
  showExcludeProductButton,
  handleExcludeProduct,
  imageUri,
  mainDisplayTitle,
  mainMetric,
  secondaryDisplayTitle,
  secondaryMetric,
  direction,
  stacklineSku,
  retailerSku,
  categoryName,
  subCategoryName,
  statusColor,
  productUrl,
  brandUrl,
  retailerUrl,
  categoryUrl,
  subcategoryUrl,
  showDirection = true
}: ProductCardProps) => {
  const theme = useStacklineTheme();
  const [isHovered, setIsHovered] = useState(false);
  const [side, setSide] = useState<'front' | 'back'>('front');

  // Controls card content - used to hide content while the flip animation happens
  const [cardSide, setCardSide] = useState<'front' | 'back'>('front');

  const flipCard = () => {
    const newSide = side === 'front' ? 'back' : 'front';
    setCardSide(null);
    setSide(newSide);
    setTimeout(() => setCardSide(newSide), (FLIP_TIME * 1000) / 2);
  };

  const cardFront = (
    <SlColumn spacing="sm" style={{ padding: '16px' }} widths={['full', 'full', 'normal', 'normal', 'normal']}>
      <div
        style={{
          height: '26px'
        }}
      >
        <SlRow horizontalPosition="space-between" verticalPosition="center">
          {isHovered && showExcludeProductButton ? (
            <button style={{ border: 'none', background: 'none' }} onClick={() => handleExcludeProduct(retailerSku)}>
              <SlStatusChip color="error">Exclude</SlStatusChip>
            </button>
          ) : isHovered && availabilityStatusCode ? (
            <SlStatusChip color={statusColor}>{availabilityStatusCode}</SlStatusChip>
          ) : (
            <div></div>
          )}

          {isHovered && <IconMore onClick={flipCard} />}
        </SlRow>
      </div>
      <SlColumn widths="full" spacing="md">
        <SlRow horizontalPosition="center">
          <Link to={productUrl}>
            <ProductImage
              imageUri={imageUri}
              mainDisplayTitle={mainDisplayTitle}
              secondaryDisplayTitle={secondaryDisplayTitle}
            />
          </Link>
        </SlRow>
        <Link to={brandUrl}>
          <Text variant="subtitle2">{mainDisplayTitle}</Text>
        </Link>
      </SlColumn>
      <SlRow verticalPosition="center" style={{ gap: '6px' }}>
        {/* This is an intentional deviation from the Typography system */}
        <Text variant="h4" sx={{ fontSize: 22, fontWeight: 500 }}>
          {mainMetric}
        </Text>
        <SlRow verticalPosition="center" spacing="xs">
          {showDirection ? getIconForMetricChange(null, direction) : null}
          <Text variant="subtitle3" color={getColorForMetricChangeValue(null, direction)}>
            {secondaryMetric}
          </Text>
        </SlRow>
      </SlRow>
      <Link to={productUrl}>
        <Text variant="body2" title={secondaryDisplayTitle} truncateLines={3}>
          {secondaryDisplayTitle}
        </Text>
      </Link>
    </SlColumn>
  );

  const cardBack = (
    <div style={{ transform: 'rotateY(180deg)' }}>
      <SlColumn widths={['full']} style={{ padding: '16px' }}>
        <div style={{ height: '20px' }}>
          {isHovered && (
            <SlRow horizontalPosition="end">
              <IconLess onClick={flipCard} />
            </SlRow>
          )}
        </div>
        <SlColumn spacing="sm">
          {(
            [
              ['Product ID', stacklineSku, productUrl],
              ['Retailer SKU', retailerSku, retailerUrl, true],
              ['Status', availabilityStatusCode],
              ['Brand', mainDisplayTitle, brandUrl],
              ['Category', categoryName, categoryUrl],
              ['Subcategory', subCategoryName, subcategoryUrl]
            ] as const
          ).map(([title, value, link, newTab]) => {
            const textValue = <Text variant="body3">{value}</Text>;
            const linkElement = newTab ? (
              <a href={link} target="_blank" rel="noreferrer">
                {textValue}
              </a>
            ) : link ? (
              <Link to={link}>{textValue}</Link>
            ) : (
              textValue
            );
            return (
              <SlColumn key={`${title}${value}`} spacing="xs">
                <Text transform="uppercase" variant="subtitle4">
                  {title}
                </Text>
                {linkElement}
              </SlColumn>
            );
          })}
        </SlColumn>
      </SlColumn>
    </div>
  );

  return (
    <div
      style={{
        width: PRODUCT_CARD_WIDTH,
        height: '302px',
        border: `1px solid ${theme.colors.primaryGray}`,
        borderRadius: theme.spacing.sm,
        cursor: 'pointer',
        transform: side === 'back' ? 'rotateY(180deg)' : 'rotateY(0deg)',
        transition: `${FLIP_TIME}s`
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {cardSide === 'front' ? cardFront : cardSide === 'back' ? cardBack : null}
    </div>
  );
};

export default ProductCardInner;
