import _get from 'lodash/get';
import queryString from 'qs';
import { shouldShowCriteo } from './app';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';

export const isPhone = () => window.matchMedia('(max-width: 767px)').matches;

export function addPersistentQueryParams(retailer, mainTimePeriod, queryParamOverrides = {}) {
  const searchParams = {
    rid: retailer.id,
    sw: mainTimePeriod.startWeek,
    ew: mainTimePeriod.endWeek,
    sdid: mainTimePeriod.startDayId,
    edid: mainTimePeriod.endDayId,
    wr: mainTimePeriod.id,
    ...queryParamOverrides
  };
  return queryString.stringify(searchParams);
}

export function addQueryParams(location, retailer, mainTimePeriod, updateParams) {
  const params = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const searchParams = {};
  Object.keys(params).forEach((key) => {
    if (updateParams.indexOf(key) === -1 && key !== 'rid' && key !== 'sw' && key !== 'ew' && key !== 'wr') {
      searchParams[key] = params[key];
    }
  });

  return `${queryString.stringify(searchParams)}&${addPersistentQueryParams(retailer, mainTimePeriod)}`;
}

export const checkIsAmazonRetailer = () => {
  const params = queryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const retailerId = _get(params, 'rid');
  if (
    retailerId &&
    [
      '1',
      '11',
      '14',
      '15',
      '16',
      '17',
      '28',
      '29',
      '30',
      '31',
      '32',
      '33',
      '34',
      '53',
      '62',
      '72',
      '74',
      '77',
      '90'
    ].includes(retailerId)
  ) {
    return true;
  }
  return false;
};

export const checkIsSamsClubRetailer = () => {
  const params = queryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const retailerId = _get(params, 'rid');
  if (retailerId && ['25'].includes(retailerId)) {
    return true;
  }
  return false;
};
const PLATFORMS_CONDITIONS = {
  incrementality: 'incrementality',
  sameSKUSales: 'sameSKUSales',
  leftNavBar: 'leftNavBar',
  leftNavBarCampaign: 'leftNavBarCampaign',
  overviewBudgetPacing: 'overviewBudgetPacing',
  overviewAddAdGroup: 'overviewAddAdGroup',
  keyMetricsPageOptimizations: 'keyMetricsPageOptimizations'
};
/**
 * Returns true if condition is true
 * @param {*} condition
 * @returns {Boolean}
 */
export const checkIsAllowedParentPlatform = (condition) => {
  // Enable all if Criteo disabled
  if (!shouldShowCriteo()) {
    return true;
  }
  const params = queryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const parentPlatform = _get(params, 'pp');

  switch (condition) {
    case PLATFORMS_CONDITIONS.incrementality: {
      // Add incrementality if allowed
      const notAllowedPlatforms = [PARENT_PLATFORMS.CRITEO];
      return !notAllowedPlatforms.includes(parentPlatform);
    }
    case PLATFORMS_CONDITIONS.sameSKUSales: {
      const allowed = [PARENT_PLATFORMS.CRITEO];
      return !allowed.includes(parentPlatform);
    }
    case PLATFORMS_CONDITIONS.leftNavBar: {
      const notAllowed = [PARENT_PLATFORMS.CRITEO];
      return !notAllowed.includes(parentPlatform);
    }
    case PLATFORMS_CONDITIONS.leftNavBarCampaign: {
      const notAllowed = [PARENT_PLATFORMS.CRITEO, PARENT_PLATFORMS.SAMSCLUB];
      return !notAllowed.includes(parentPlatform);
    }
    case PLATFORMS_CONDITIONS.overviewBudgetPacing: {
      const notAllowed = [PARENT_PLATFORMS.CRITEO];
      return !notAllowed.includes(parentPlatform);
    }
    case PLATFORMS_CONDITIONS.overviewAddAdGroup: {
      const allowed = [PARENT_PLATFORMS.CRITEO];
      return !allowed.includes(parentPlatform);
    }
    case PLATFORMS_CONDITIONS.keyMetricsPageOptimizations: {
      const notAllowed = [PARENT_PLATFORMS.CRITEO];
      return !notAllowed.includes(parentPlatform);
    }
    default:
      return false;
  }
};

export const getParentPlatform = () => {
  const params = queryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const parentPlatform = _get(params, 'pp', '');
  return parentPlatform;
};

export const getRetailerId = () => {
  const params = queryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const parentPlatform = _get(params, 'rid', '');
  return parentPlatform;
};

export const getTargetPageType = () => {
  const params = queryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const pageType = _get(params, 'pageType', '');
  return pageType;
};

export const parseQueryString = () => {
  return queryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: 100
  });
};

export const encodeCampaignName = (campaignName) =>
  campaignName
    .replace(/_/g, ' symbol_underscore ')
    .replace(/~/g, ' symbol_tilde ')
    .replace(/`/g, ' symbol_acute ')
    .replace(/!/g, ' symbol_exclamation_mark ')
    .replace(/@/g, ' symbol_at ')
    .replace(/#/g, ' symbol_sharp ')
    .replace(/\$/g, ' symbol_dollar ')
    .replace(/%/g, ' symbol_percent ')
    .replace(/\^/g, ' symbol_caret ')
    .replace(/&/g, ' symbol_ampersand ')
    .replace(/\*/g, ' symbol_asterisk ')
    .replace(/\(/g, ' symbol_open_parenthesis ')
    .replace(/\)/g, ' symbol_close_parenthesis ')
    .replace(/-/g, ' symbol_minus ')
    .replace(/\+/g, ' symbol_plus ')
    .replace(/=/g, ' symbol_equal ')
    .replace(/{/g, ' symbol_open_brace ')
    .replace(/}/g, ' symbol_close_brace ')
    .replace(/\[/g, ' symbol_open_bracket ')
    .replace(/\]/g, ' symbol_close_bracket ')
    .replace(/\|/g, ' symbol_pipe ')
    .replace(/\\/g, ' symbol_backslash ')
    .replace(/\//g, ' symbol_forward_slash ')
    .replace(/:/g, ' symbol_colon ')
    .replace(/;/g, ' symbol_semicolon ')
    .replace(/"/g, ' symbol_quote ')
    .replace(/'/g, ' symbol_single_quote ')
    .replace(/</g, ' symbol_less_than ')
    .replace(/>/g, ' symbol_greater_than ')
    .replace(/,/g, ' symbol_comma ')
    .replace(/\./g, ' symbol_dot ')
    .replace(/\?/g, ' symbol_question_mark ');

// not used, but kept if needed
// export const decodeCampaignName = (campaignName) =>
//   campaignName
//     .replace(/ symbol_underscore /g, '_')
//     .replace(/ symbol_tilde /g, '~')
//     .replace(/ symbol_acute /g, '`')
//     .replace(/ symbol_exclamation_mark /g, '!')
//     .replace(/ symbol_at /g, '@')
//     .replace(/ symbol_sharp /g, '#')
//     .replace(/ symbol_dollar /g, '$')
//     .replace(/ symbol_percent /g, '%')
//     .replace(/ symbol_caret /g, '^')
//     .replace(/ symbol_ampersand /g, '&')
//     .replace(/ symbol_asterisk /g, '*')
//     .replace(/ symbol_open_parenthesis /g, '(')
//     .replace(/ symbol_close_parenthesis /g, ')')
//     .replace(/ symbol_minus /g, '-')
//     .replace(/ symbol_plus /g, '+')
//     .replace(/ symbol_equal /g, '=')
//     .replace(/ symbol_open_brace /g, '{')
//     .replace(/ symbol_close_brace /g, '}')
//     .replace(/ symbol_open_bracket /g, '[')
//     .replace(/ symbol_close_bracket /g, ']')
//     .replace(/ symbol_pipe /g, '|')
//     .replace(/ symbol_backslash /g, '\\')
//     .replace(/ symbol_forward_slash /g, '/')
//     .replace(/ symbol_colon /g, ':')
//     .replace(/ symbol_semicolon /g, ';')
//     .replace(/ symbol_quote /g, '"')
//     .replace(/ symbol_signgle_quote /g, "'")
//     .replace(/ symbol_less_than /g, '<')
//     .replace(/ symbol_greater_than /g, '>')
//     .replace(/ symbol_comma /g, ',')
//     .replace(/ symbol_dot /g, '.')
//     .replace(/ symbol_question_mark /g, '?');
