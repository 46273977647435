import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

/**
 * Review tab, Sentiment page
 */
const ReviewSentimentPage = () => {
  return (
    <Flex flexDirection="column" gap={16}>
      Sentiment page
    </Flex>
  );
};

export default ReviewSentimentPage;
