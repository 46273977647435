import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import Truncate from 'react-truncate';
import Chip from '@mui/material/Chip';
import { compose } from 'redux';
import { AppName } from 'sl-api-connector/types';

import { getClientImageUrl } from 'src/utils/image';
import { entityOperations } from 'src/store/modules/entityService';
import { isOmni } from 'src/utils/app';

class ClientSidebarCard extends React.Component {
  static propTypes = {
    disableFetchMainEntity: PropTypes.bool,
    // From `withRouter`
    history: PropTypes.object.isRequired,
    // Redux Props
    app: PropTypes.object.isRequired,
    fetchMainEntity: PropTypes.func.isRequired,
    entityService: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired
  };

  static defaultProps = {
    disableFetchMainEntity: false
  };

  state = {};

  componentDidMount() {
    const { user, retailer, fetchMainEntity, disableFetchMainEntity, history } = this.props;
    if (disableFetchMainEntity) {
      return;
    }
    const { vendor } = user.config;

    fetchMainEntity('client', vendor && vendor.BeaconClientId, retailer.id, history.push.bind(history));
  }

  componentWillReceiveProps(nextProps) {
    const { entityService } = nextProps;
    const { mainEntity } = entityService;
    const { companyName, id } = mainEntity;
    const { logoNotAvailable } = this.state;
    if (_isEqual(entityService, this.props.entityService)) {
      return;
    }

    // trying to use the id in the mainEntity to fetch the logo.
    // Some client did't have the beacon Client Id. We can upload a logo in s3 and using it's id as name.
    if (id && logoNotAvailable) {
      this.setState({ name: companyName, id, logoNotAvailable: false });
    } else {
      this.setState({ name: companyName });
    }
  }

  handleImageError = () => this.setState({ logoNotAvailable: true });

  showLogo(vendor, name, logoNotAvailable, id) {
    let clientId = vendor && vendor.BeaconClientId;
    if (clientId === -1 && id) {
      clientId = id;
    }
    if (logoNotAvailable) {
      const shortName = name ? name.slice(0, 2) : '';
      return (
        <div className="segment__short-name-container" style={{ backgroundColor: '#468AF6' }}>
          <div className="segment__short-name">{shortName}</div>
        </div>
      );
    }

    return (
      <div className="brand__image-container sl-image-container">
        <div className="brand__image-content sl-image-center">
          <img className="sl-image" alt="logo" src={getClientImageUrl(clientId)} onError={this.handleImageError} />
        </div>
      </div>
    );
  }

  render() {
    const { app, user } = this.props;
    const { searchParams } = app.queryParams;
    const { name, logoNotAvailable, id } = this.state;
    const { vendor } = user.config;
    const subtab = {
      [AppName.Atlas]: 'retailSales',
      [AppName.Beacon]: 'keymetrics',
      [AppName.Advertising]: 'keyMetrics',
      [AppName.Omni]: 'all'
    }[app.name];

    let tab = 'sales';

    if (app.name === AppName.Advertising) {
      tab = 'adManager';
    } else if (isOmni) {
      tab = 'scorecard';
    }
    return (
      <div className="nav-header-container">
        <div className="sl-image-container">
          <div className="sl-image-center">
            <NavLink to={`${searchParams}&tab=${tab}&subtab=${subtab}`}>
              {this.showLogo(vendor, name, logoNotAvailable, id)}
            </NavLink>
          </div>
        </div>
        <div className="nav-name-container" title={name}>
          <Truncate lines={2} ellipsis="...">
            <h4 className="nav-company-name">{name}</h4>
          </Truncate>
        </div>
        <Chip className="entity-chip" label="Organization" />
      </div>
    );
  }
}

const mapStateToProps = (state) => _pick(state, ['app', 'entityService', 'user', 'retailer']);

const mapDispatchToProps = {
  fetchMainEntity: entityOperations.fetchMainEntity
};

const EnhancedClientSidebarCard = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ClientSidebarCard);

export default EnhancedClientSidebarCard;
