import { useMemo } from 'react';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import { AdvancedSearchByWeekIdResponse } from './types';
import { UseQueryResult } from 'react-query';
import { useBaseMetricRequestBuilder } from './useBaseMetricRequestBuilder';
import { useAppSelector } from 'src/utils/Hooks';
import { Field } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useAggregationBuilderWithMetrics';

interface UseMetricDataByWeekIdProps {
  requestId: string;
  startWeekId: number;
  endWeekId: number;
  indexName: string;
  fields: Field[];
}

/**
 * Gets metrics for retail sales and units sold by week ID.
 */
export const useMetricDataByWeekId = <TResponseAdditionalValues, TParsedDatum>({
  startWeekId,
  endWeekId,
  requestId,
  fields,
  indexName
}: UseMetricDataByWeekIdProps): UseQueryResult<TParsedDatum[]> => {
  const entity = useAppSelector((state) => state.entityService.mainEntity);
  const requestIdWithEntityInfo = `${requestId}-${entity.type}-${entity.id}`;
  const requestBody = useBaseMetricRequestBuilder({
    endWeekId,
    startWeekId,
    fields,
    indexName,
    requestId: requestIdWithEntityInfo
  });
  const { data: response, ...rest } = useGenericAdvancedSearch<
    AdvancedSearchByWeekIdResponse<TResponseAdditionalValues[]>
  >({
    requestBody: [requestBody.build()],
    requestId: requestIdWithEntityInfo,
    queryKeys: [requestBody.build()]
  });

  const data: TParsedDatum[] = useMemo(() => {
    return response && response.data && response.data[0]
      ? response.data[0].aggregations.by_weekId.map(({ fieldId, additionalValues }) => ({
          weekId: fieldId,
          ...additionalValues
        }))
      : [];
  }, [response]);

  return {
    data,
    ...rest
  } as UseQueryResult<TParsedDatum[]>;
};
