/* eslint-disable react/prop-types */

import React, { ReactNode } from 'react';
import { SpeedDial } from '@mui/material';

interface Props {
  menuInfo: {
    mainButton: {
      direction: 'up' | 'down' | 'left' | 'right';
      icon: ReactNode;
    };
    subItems: {
      text: string;
      icon: ReactNode;
      onClick: () => {};
    }[];
  };
}

// used only if there is one sub item
const MenuButtonSingle: React.FC<Props> = ({ menuInfo }) => {
  const {
    mainButton: { direction, icon },
    subItems
  } = menuInfo;

  return (
    <div className="menu-button-container">
      {subItems.map((item) => {
        return (
          <SpeedDial
            onClick={item.onClick}
            ariaLabel="speedDial"
            direction={direction}
            icon={icon}
            key={item.text}
            className="menu-button"
          ></SpeedDial>
        );
      })}
    </div>
  );
};

export default MenuButtonSingle;
