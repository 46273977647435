/**
 * How many ads should be shown before they are
 * aggregated. For example, a value of 25 means that
 * any ad exposure greater than 25 will be aggregated
 * as 25+.
 */
export const MAX_ADS_AGGREGATION = 25;

/**
 * Event emitted when the dropdown value is changed.
 */
export const AD_FREQUENCY_EVENT = 'adFrequencyEvent';
