import React from 'react';
import PropTypes from 'prop-types';
import colors from 'src/utils/colors';
import { buildMetricValue } from 'src/utils/metrics';

const styles = {
  legendDivInner: {
    height: 12,
    borderRadius: '50%',
    width: 12,
    margin: '7px 20px 0px 38px'
  }
};

const DateDisplay = ({ displayName }) => (
  <div className="legend__primary-date" style={{ marginTop: 5 }}>
    {displayName}
  </div>
);

DateDisplay.propTypes = {
  displayName: PropTypes.node.isRequired
};

const WaterfallLegend = ({
  comparisonLegendValue,
  retailer,
  mainLegendValue,
  mainTimePeriod,
  comparisonTimePeriod
}) => {
  const mainLegendMetricValue = buildMetricValue(mainLegendValue, 'VOLUME', '$', true, null, retailer.locale);
  const comparisonLegendMetricValue = buildMetricValue(
    comparisonLegendValue,
    'VOLUME',
    '$',
    true,
    null,
    retailer.locale
  );

  let metricsChangeIcon = { icon: '+', className: 'increase' };
  let metricsChangePercent = (mainLegendValue - comparisonLegendValue) / comparisonLegendValue;
  if (metricsChangePercent && metricsChangePercent < 0) {
    metricsChangePercent *= -1;
    metricsChangeIcon = { icon: '\u2212', className: 'decrease' };
  }
  const metricsChangePercentMetricValue = buildMetricValue(
    metricsChangePercent,
    'PERCENT',
    '$',
    true,
    null,
    retailer.locale
  );

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ ...styles.legendDivInner, background: colors.comparison }} />

        <div>
          <div>
            {comparisonLegendMetricValue.value}
            {comparisonLegendMetricValue.suffix || ''}
          </div>

          <DateDisplay displayName={comparisonTimePeriod.displayName} />
        </div>
      </div>

      <div style={{ display: 'flex' }}>
        <div style={{ ...styles.legendDivInner, background: colors.stacklineBlue }} />

        <div>
          <div>
            {mainLegendMetricValue.value}
            {mainLegendMetricValue.suffix || ''}

            <span className="legend__percent-change">
              (
              <span
                className={`legend__percent-change-metric legend__percent-change-metric--${metricsChangeIcon.className}`}
              >
                <span>{metricsChangeIcon.icon}</span>
                <span className="sl-metric">
                  <span className="sl-metric__value">{metricsChangePercentMetricValue.value}</span>
                  <span className="sl-metric__suffix">{metricsChangePercentMetricValue.suffix}</span>
                </span>
              </span>
              )
            </span>
          </div>

          <DateDisplay displayName={mainTimePeriod.displayName} />
        </div>
      </div>
    </div>
  );
};

WaterfallLegend.propTypes = {
  comparisonLegendValue: PropTypes.number.isRequired,
  retailer: PropTypes.object.isRequired,
  mainLegendValue: PropTypes.number.isRequired,
  mainTimePeriod: PropTypes.object.isRequired,
  comparisonTimePeriod: PropTypes.object.isRequired
};

export default WaterfallLegend;
