import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import Truncate from 'react-truncate';
import Chip from '@mui/material/Chip';

import colors from 'src/utils/colors';
import { isOmni } from 'src/utils/app';

class CategorySidebarCard extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    entity: PropTypes.object.isRequired
  };

  render() {
    const { app, entity } = this.props;
    const { searchParams, entityType } = app.queryParams;
    const { name } = app;
    if (_isEmpty(entity)) {
      return null;
    }
    const { categoryName } = entity;
    let shortName = categoryName ? categoryName.slice(0, 2) : '';
    if (entityType === 'retailer' && name === 'atlas') {
      shortName = 'Al';
    }

    const tab = isOmni ? 'scorecard' : 'sales';

    let subtab = 'keymetrics';
    if (name === 'atlas') {
      subtab = 'retailSales';
    } else if (isOmni) {
      subtab = 'all';
    }

    return (
      <div className="nav-header-container">
        <div className="sl-image-container">
          <div className="sl-image-center">
            <NavLink style={{ textDecoration: 'none' }} to={`${searchParams}&tab=${tab}&subtab=${subtab}`}>
              <div className="segment__short-name-container" style={{ backgroundColor: colors.stacklineBlue }}>
                <div className="segment__short-name">{shortName}</div>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="nav-name-container" title={categoryName}>
          <Truncate lines={2} ellipsis="...">
            <h4 className="nav-company-name">{categoryName}</h4>
          </Truncate>
        </div>
        <Chip className="entity-chip" label="Category" />
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  app
});

export default withRouter(connect(mapStateToProps, null)(CategorySidebarCard));
