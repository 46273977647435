import { Parser } from 'expr-eval';

import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

export const TRAFFIC_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  bidType: {
    name: 'bidType',
    displayName: 'Bid Type',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  searchTerm: {
    name: 'searchTerm',
    displayName: 'Keywords',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.INTEGER,
    aggregationFunction: 'cardinality',
    timePeriodAggregationFunctionType: 'lastValue',
    timePeriodAggregationFunction: 'sum'
  },
  searchTermFuzzy: {
    name: 'searchTermFuzzy',
    displayName: 'Keywords',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality',
    timePeriodAggregationFunctionType: 'simple',
    timePeriodAggregationFunction: 'avg'
  },
  microSegment: {
    name: 'microSegment',
    displayName: 'Microsegment',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  searchVolume: {
    name: 'searchVolume',
    displayName: 'Impressions',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  totalClicks: {
    name: 'totalClicks',
    displayName: 'Total Traffic',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  organicClicks: {
    name: 'organicClicks',
    displayName: 'Organic Traffic',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  adClicks: {
    name: 'adClicks',
    displayName: 'Paid Traffic',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  otherClicks: {
    name: 'otherClicks',
    displayName: 'Other Traffic',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  otherAdClicks: {
    name: 'otherAdClicks',
    displayName: 'DSP Clicks',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  otherUnitsSold: {
    name: 'otherUnitsSold',
    displayName: 'Other Units Sold',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  adSpend: {
    name: 'adSpend',
    displayName: 'Paid Ad Spend',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  adRetailSales: {
    name: 'adRetailSales',
    displayName: 'Paid Ad Sales',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  adUnitsSold: {
    name: 'adUnitsSold',
    displayName: 'Paid Ad Units Sold',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  adConversionRate: {
    name: 'adConversionRate',
    displayName: 'Ad Conversion Rate',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('adClicks>0?adUnitsSold/adClicks:0')
  },
  adAverageSellPrice: {
    name: 'adAverageSellPrice',
    displayName: 'Avg Sell Price (Ad)',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('adUnitsSold>0?adRetailSales/adUnitsSold:0')
  },
  adClickThroughRate: {
    name: 'adClickThroughRate',
    displayName: 'CTR %',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('searchVolume>0?adClicks/searchVolume:0')
  },
  adCostPerClick: {
    name: 'adCostPerClick',
    displayName: 'Cost per Click',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('adClicks>0?adSpend/adClicks:0')
  },
  adCostPerMille: {
    name: 'adCostPerMille',
    displayName: 'CPM',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('searchVolume>0?(adSpend * 1000)/searchVolume:0')
  },
  returnOnAdSpend: {
    name: 'returnOnAdSpend',
    displayName: 'ROAS',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('adSpend>0?adRetailSales/adSpend:0')
  },
  costPerAcquisition: {
    name: 'costPerAcquisition',
    displayName: 'Cost per Acquisition',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('adUnitsSold>0?adSpend/adUnitsSold:0')
  },
  adCostOfSales: {
    name: 'adCostOfSales',
    displayName: 'Ad Cost of Sales',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('adRetailSales>0?adSpend/adRetailSales:0')
  },
  adSpendMarketShare: {
    name: 'adSpendMarketShare',
    displayName: 'Market Share - Ad Spend',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg'
  },
  totalClicksMarketShare: {
    name: 'totalClicksMarketShare',
    displayName: 'Total Traffic Share',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg'
  },
  adClicksMarketShare: {
    name: 'adClicksMarketShare',
    displayName: 'Paid Traffic Share',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg'
  },
  organicClicksMarketShare: {
    name: 'organicClicksMarketShare',
    displayName: 'Organic Traffic Share',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg'
  },
  otherClicksMarketShare: {
    name: 'otherClicksMarketShare',
    displayName: 'Other Traffic Share',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg'
  }
};
