/* eslint-disable prefer-template */
import React, { useMemo } from 'react';
import BeaconChartWithLegend from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import SplineChart from 'src/components/BeaconRedesignComponents/SplineChart/SplineChart';
import { getAppName } from 'src/utils/app';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import {
  FORECAST_PERIOD_TO_LABEL_MAP,
  FORECAST_COMPARISON_TO_LABEL_MAP
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/dropdowns';
import {
  useForecastComparisonPeriod,
  useForecastPeriod,
  useForecastType,
  usePreviousForecastWeekId
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import { ForecastType } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import SplineChartLoading from 'src/components/BeaconRedesignComponents/SplineChartLoading/SplineChartLoading';
import { getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import useForecastMetricByWeekId from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useForecastMetricByWeekId';
import { getCardTitle } from 'src/components/BeaconRedesignComponents/KeyMetricCards/SmartKeyMetricCard';
import useForecastDerivedMetricByWeekId from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useForecastDerivedMetricByWeekId';
import saveAs from 'file-saver';
import { timestampToWeekId } from 'src/utils/dateUtils';
import { getWeekLastDay } from 'src/utils/dateformatting';

interface ForecastsLineChartProps {
  indexName: string;
  fieldName: string;
  subtitle?: string;
  titleOverrides?: Record<string, Record<string, string>>;
}

function ForecastsLineChart({
  subtitle,
  indexName,
  fieldName,
  titleOverrides,
  formattedPrimaryTotal,
  percentChange,
  formattedPercentChange,
  isLoading,
  formattedSecondaryTotal,
  primaryData,
  secondaryData,
  forecastData = []
}: ForecastsLineChartProps &
  Pick<
    ReturnType<typeof useForecastMetricByWeekId>,
    | 'isLoading'
    | 'primaryData'
    | 'secondaryData'
    | 'formattedPrimaryTotal'
    | 'formattedSecondaryTotal'
    | 'formattedPercentChange'
    | 'percentChange'
    | 'forecastData'
  >) {
  const forecastPeriod = useForecastPeriod();
  const forecastType = useForecastType();
  const forecastComparisonPeriod = useForecastComparisonPeriod();
  const field = useMemo(() => INDEX_FIELDS.getField(getAppName(), indexName, fieldName), [indexName, fieldName]);
  const cardTitle = getCardTitle(indexName, fieldName, titleOverrides);
  const previousForecastWeekId = usePreviousForecastWeekId();
  const convertSeriesToDelimitedData = () => {
    try {
      if (forecastData.length > 1) {
        const forecastCSVFormatter = (
          primaryDataSet: number[][],
          secondaryDataSet: number[][],
          forecastedDataSet: number[][]
        ) => {
          // Forecast data has default 1 week of actual data, so we need to remove it
          const trailedForecastedData = primaryDataSet.length > 0 ? forecastedDataSet.slice(1) : forecastedDataSet;
          const combinedMainData = [...primaryDataSet, ...trailedForecastedData];

          const comparisonObj = {};
          secondaryData.forEach(([timeStamp, value]) => {
            const actualWeekId = previousForecastWeekId(timestampToWeekId(timeStamp));
            comparisonObj[actualWeekId] = value;
          });

          const result = [];
          for (let i = 0; i < combinedMainData.length; i++) {
            const mainTimeStamp = combinedMainData[i][0];
            const mainWeekId = timestampToWeekId(mainTimeStamp);
            const formattedMainEndDate = getWeekLastDay(mainWeekId, true);
            const comparisonWeekId = previousForecastWeekId(timestampToWeekId(mainTimeStamp));
            const formattedComparisonEndDate = getWeekLastDay(comparisonWeekId, true);
            const primaryDataType = i < primaryDataSet.length ? 'Actual' : 'Forecasted';
            result.push([
              // Comparison Week End Date
              `"${formattedComparisonEndDate}"`,
              // Comparison Week End Id
              comparisonWeekId,
              //   Comparison Data Type -- this is always Actual since historic data
              'Actual',
              // Main Week End Date
              `"${formattedMainEndDate}"`,
              // Main Week End Id
              mainWeekId,
              // Main Data Type - Actual vs Forecasted
              primaryDataType,
              // Comparison Value
              comparisonObj[comparisonWeekId] ? comparisonObj[comparisonWeekId] : '',
              // Main Value
              combinedMainData[i][1]
            ]);
          }
          const defaultHeader = [
            'Comparison Week End Date',
            'Comparison Week End Id',
            'Comparison Data Type',
            'Main Week End Date',
            'Main Week End Id',
            'Main Data Type',
            `Comparison - ${cardTitle}`,
            `Main - ${cardTitle}`
          ];
          return [defaultHeader, ...result];
        };
        const resultData = forecastCSVFormatter(primaryData, secondaryData, forecastData);
        let csvContent = '';

        resultData.forEach((row) => {
          csvContent += row.join(',') + '\n';
        });
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, `${cardTitle} Projections`);
      }
    } catch (e) {
      console.error('error with exporting forecast line chart', e);
    }
    return Promise.resolve();
  };

  return (
    <BeaconChartWithLegend
      subtitle={subtitle}
      title={`${getCardTitle(indexName, fieldName, titleOverrides)} Projections`}
      primaryLegendProps={{
        displayName: `${FORECAST_PERIOD_TO_LABEL_MAP[forecastPeriod]} (${
          forecastType === ForecastType.ADJUSTED ? 'Adjusted' : 'Unadjusted'
        })`,
        metric: formattedPrimaryTotal,
        direction: getDirection(percentChange),
        change: formattedPercentChange
      }}
      comparisonLegendProps={{
        displayName: `${FORECAST_COMPARISON_TO_LABEL_MAP[forecastComparisonPeriod]} (Actuals)`,
        metric: formattedSecondaryTotal
      }}
      loading={isLoading}
      loadingComponent={<SplineChartLoading />}
      convertSeriesToDelimitedData={convertSeriesToDelimitedData}
    >
      <SplineChart
        metricType={field.metricType}
        primaryData={primaryData}
        secondaryData={secondaryData}
        forecastedData={forecastData}
      />
    </BeaconChartWithLegend>
  );
}

function DerivedForecastLineChart({ indexName, fieldName, subtitle, titleOverrides }: ForecastsLineChartProps) {
  const args = useForecastDerivedMetricByWeekId({ indexName, fieldName });

  return (
    <ForecastsLineChart
      subtitle={subtitle}
      indexName={indexName}
      fieldName={fieldName}
      titleOverrides={titleOverrides}
      {...args}
    />
  );
}

/**
 * Forecast line chart for everything except derived metrics (purchase rate)
 */
function DefaultForecastLineChart({ indexName, fieldName, subtitle, titleOverrides }: ForecastsLineChartProps) {
  const args = useForecastMetricByWeekId({
    fieldName,
    indexName
  });

  return (
    <ForecastsLineChart
      subtitle={subtitle}
      indexName={indexName}
      fieldName={fieldName}
      titleOverrides={titleOverrides}
      {...args}
    />
  );
}
/**
 * Displays a spline chart with forcasted data
 */
const SmartForecastsLineChart = ({ indexName, fieldName, subtitle, titleOverrides }: ForecastsLineChartProps) => {
  const field = useMemo(() => INDEX_FIELDS.getField(getAppName(), indexName, fieldName), [indexName, fieldName]);

  return field.aggregationFunctionType === 'derived' ? (
    <DerivedForecastLineChart
      indexName={indexName}
      fieldName={fieldName}
      subtitle={subtitle}
      titleOverrides={titleOverrides}
    />
  ) : (
    <DefaultForecastLineChart
      indexName={indexName}
      fieldName={fieldName}
      subtitle={subtitle}
      titleOverrides={titleOverrides}
    />
  );
};

export default SmartForecastsLineChart;
