import _isEmpty from 'lodash/isEmpty';
import { mergeConditions } from 'sl-api-connector/search/conditions';
import { Conditions, TermFilter, Entity } from 'sl-api-connector/types';

import { store } from 'src/main';
import { combineFilterConditions } from 'src/utils/filters';
import {
  buildDefaultConditions,
  buildEntityConditions,
  buildMainEntityConditions
} from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import ReduxStore from 'src/types/store/reduxStore';
import { MetricField } from 'src/types/application/types';
import { ArrayElementOf } from 'src/types/utils';
import { prop } from 'src/utils/fp';
import { panic } from 'src/utils/mixpanel';

/**
 * Using the global Redux store, constructs a set of conditions to be used when making queries against the main entity.
 *
 * @param {object?} state The Redux store that will be used to build the main conditions.  If not supplied, the state is
 *  pulled directly from the main global store.
 */
export const getMainConditions = (state?: ReduxStore) => {
  const {
    app,
    app: { queryParams },
    filters,
    categories,
    subCategories,
    entityService: { mainEntity },
    retailer,
    segments
  } = state || (store.getState() as any as ReduxStore);

  const { conditions, aggregationConditions: baseAggregationConditions } = combineFilterConditions(
    app,
    filters,
    categories,
    subCategories,
    segments,
    retailer
  );
  const defaultConditions = buildDefaultConditions(conditions, queryParams);
  const entityConditions = buildEntityConditions(app, categories, defaultConditions, mainEntity);
  const mainEntityConditions = mergeConditions(
    { termFilters: [], rangeFilters: [] },
    buildMainEntityConditions(entityConditions, mainEntity, app, retailer)
  );

  return { baseAggregationConditions, entityConditions, mainEntityConditions };
};

/**
 * There is an edge case where a widget is inserted into the search page that has a different index than the rest of the
 * widgets there.  The left search has a field for including/excluding keywords, but the filters that it inserts are
 * meant for the other index.
 *
 * This function handles detecting those conflicting conditions and replacing them with the correct filters for the
 * `advertising` index.
 *
 * @param {object} groupByField
 * @param {{ termFilters: object[], rangeFilters: object[] }} aggregationFieldConditions
 */
export const replaceConflictingConditions = (groupByField: MetricField, aggregationFieldConditions: Conditions) => {
  if (groupByField.indexName !== 'advertising') {
    return aggregationFieldConditions;
  }

  return {
    ...aggregationFieldConditions,
    termFilters: (aggregationFieldConditions.termFilters || []).map(({ fieldName, ...filter }) => ({
      ...filter,
      fieldName: fieldName.replace('searchTerm', 'searchKeyword').replace('SearchTerm', 'SearchKeyword')
    }))
  };
};

export const removeRetailPriceRangeFilters = (conditions: Conditions): Conditions => ({
  ...conditions,
  rangeFilters: (conditions.rangeFilters || []).filter((rangeFilter) => rangeFilter.fieldName !== 'retailPrice'),
  nestedFilterConditions: (conditions.nestedFilterConditions || []).map(removeRetailPriceRangeFilters)
});

export const removePromoTypeTermFilters = (conditions: Conditions, indexName: string): Conditions => {
  if (indexName === 'promotions') {
    return conditions;
  }

  return {
    ...conditions,
    termFilters: (conditions.termFilters || []).filter((termFilter) => termFilter.fieldName !== 'promoType')
  };
};

export const conditionsAreEmpty = (conditions: Conditions = { termFilters: [], rangeFilters: [] }): boolean =>
  [conditions.termFilters, conditions.rangeFilters].every(_isEmpty) &&
  (_isEmpty(conditions.nestedFilterConditions) || conditions.nestedFilterConditions!.every(conditionsAreEmpty));

/**
 * Given a set of conditions, replaces all term filters in them and any nested conditions (recursively) to be set to
 * the current retailer.
 */
export const updateConditionsToCurrentRetailer = (
  conditions: Conditions,
  retailer: ReduxStore['retailer']
): Conditions => {
  const fixTermFilters = (termFilters: TermFilter[]) =>
    termFilters.map((termFilter) =>
      termFilter.fieldName === 'retailerId' ? { ...termFilter, values: [retailer.id] } : termFilter
    );

  if (conditions.termFilters) {
    conditions.termFilters = fixTermFilters(conditions.termFilters);
  }

  if (conditions.nestedFilterConditions) {
    conditions.nestedFilterConditions = conditions.nestedFilterConditions.map((nestedConditions) =>
      updateConditionsToCurrentRetailer(nestedConditions, retailer)
    );
  }

  return conditions;
};

/**
 *
 * @param entity The ad manager entity (campaign, portfolio, or ad entity) for which the list of child campaigns will
 *        be retrieved
 * @param adCampaigns The full list of all ad campaigns to use as a corpus from which to look
 */
export const getCampaignChildrenOfAdManagerEntity = (entity: Entity, adCampaigns: ReduxStore['adCampaigns']) => {
  if (_isEmpty(adCampaigns)) {
    console.error(
      'Tried to get list of campaigns that are a child of an ad manager entity, but the list of `adCampaigns` is empty or missing.'
    );
  }

  const filterPredByEntityType: {
    [entityType: string]: (campaign: ArrayElementOf<typeof adCampaigns>) => boolean;
  } = {
    adCampaign: (campaign) => campaign.extendedAttributes.campaignId === entity.id,
    adPortfolio: (campaign) => campaign.extendedAttributes.portfolioId === entity.id,
    adEntity: (campaign) => campaign.extendedAttributes.entityId === entity.id,
    client: (_campaign) => true
  };
  const filterPred = filterPredByEntityType[entity.type];
  if (!filterPred) {
    return panic(`No known method to get the list of all campaigns for entity of type "${entity.type}"`);
  }
  return adCampaigns.filter(filterPred).map(prop('id'));
};
