/* eslint-disable react/prop-types */
import React from 'react';
import Chip from '@mui/material/Chip';
import 'overlayscrollbars/css/OverlayScrollbars.css';

/**
 * Renders a container filled with chips, each with an "X" button to delete it.
 */
const ChipsContainer: React.FC<{
  onDelete: (datumKey: unknown) => void;
  chipData: { key: string | number; [key: string]: any }[];
  dataKey?: string | number;
  style?: React.CSSProperties;
}> = ({ onDelete, chipData, dataKey = 'i', style = {} }) => (
  <div className="ReactVirtualized__List" style={{ maxHeight: 200, ...style }}>
    {chipData.map((datum) => (
      <Chip
        label={datum[dataKey]}
        style={{ overflow: 'hidden' }}
        key={datum.key}
        className="search-chip"
        onDelete={() => onDelete(datum.key)}
      />
    ))}
  </div>
);

export default ChipsContainer;
