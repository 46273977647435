/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import _uniq from 'lodash/uniq';
import _isEmpty from 'lodash/isEmpty';
import _upperFirst from 'lodash/upperFirst';
import _get from 'lodash/get';
import { AD_CAMPAIGN_STATUS, AD_STATUS_DERIVED } from 'sl-ad-campaign-manager-data-model';
import { AdManagerEvents } from 'src/types/application/event';

import { useBus } from 'src/utils/Hooks';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import { store } from 'src/main';
import { getCampaignDisplayStatus } from 'src/components/AdManager/AmsUtils';
import CampaignColumn from 'src/components/AdManager/Search/CustomColumns/EntityColumn';
import SelectEntityColumn from 'src/components/AdManager/Search/CustomColumns/SelectEntityColumn';
import EntityStatusColumn from 'src/components/AdManager/Search/CustomColumns/EntityStatusColumn';
import DataColumn from 'src/components/AdManager/Search/CustomColumns/DataColumn';
import { buildAggregations, zipMetricsResponseIntoArray } from 'src/components/AdManager/Search';
import ReduxStore from 'src/types/store/reduxStore';
import { WidgetProps, Widget } from 'src/types/application/widgetTypes';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { fetchEntityMetrics, receiveEntitySalesMetrics } from 'src/store/modules/entitySearchService/operations';
import SearchResultsActionBar from 'src/components/AdManager/Search/SearchResultsActionBar';
import { CongratulationsWithOpenSummary } from 'src/components/Grids/Data/NoResultTemplate';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import './AdOptimizationHistory.scss';
import { splitSnakeCase } from 'src/utils/stringFormatting';

const onGridReady = (params: any) => {
  params.api.sizeColumnsToFit();
  params.api.refreshHeader();
};

const baseColDef = {
  disableSort: true,
  width: 100
};

const mapStateToProps = (state: ReduxStore, { widget }: { widget: Widget }) => ({
  ..._pick(state, ['app', 'comparisonTimePeriod', 'mainTimePeriod', 'retailer']),
  mainEntity: state.entityService.mainEntity,
  dataSet: state.entitySearchService[widget.data.statePropertyName]
    ? state.entitySearchService[widget.data.statePropertyName].data
    : null
});

type AdRecommendationsProps = WidgetProps & ReturnType<typeof mapStateToProps> & { eventBus: EventBus };

const AdRecommendations: React.FunctionComponent<AdRecommendationsProps> = ({ ...props }) => {
  const [isLoading, setLoading] = useState(true);
  const { widget, mainEntity, retailer, app, dataSet } = props;
  const [groupByField] = widget.data.groupByFields;
  const getGridColumnDefinitions = (groupByFieldName: string) => {
    const GRID_COLUMN_DEFINITIONS: { [key: string]: any[] } = {
      targetingText: [
        {
          headerName: 'Target',
          field: 'targetingText',
          width: undefined,
          enableRtl: true,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          minWidth: 100,
          maxWidth: 100,
          cellRendererFramework: SelectEntityColumn,
          headerComponentFramework: SelectEntityColumn,
          widget,
          headerClass: 'align-left'
        },
        {
          headerName: 'Target',
          field: 'targetingText',
          width: undefined,
          enableRtl: true,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          minWidth: 300,
          maxWidth: 800,
          cellRendererFramework: CampaignColumn,
          headerClass: 'align-left'
        },
        {
          headerName: 'Match',
          field: 'entity',
          width: undefined,
          enableRtl: true,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          minWidth: 100,
          maxWidth: 100,
          valueFormatter: ({ value }) => _upperFirst(splitSnakeCase(value.targetingType.toLowerCase())),
          headerClass: 'align-left'
        },
        {
          headerName: 'Ad Spend',
          cellRendererFramework: DataColumn,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          field: 'spend'
        },
        {
          headerName: 'Ad Sales',
          cellRendererFramework: DataColumn,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          field: 'sales'
        },
        {
          headerName: 'ROAS',
          cellRendererFramework: DataColumn,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          field: 'returnOnAdSpend'
        },
        {
          headerName: 'CPC',
          cellRendererFramework: DataColumn,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          field: 'costPerClick'
        },
        {
          headerName: 'Active',
          field: 'targetingText',
          width: undefined,
          enableRtl: true,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          minWidth: 100,
          maxWidth: 100,
          cellRendererFramework: EntityStatusColumn
        }
      ].map(({ headerName, ...colDef }) => ({ ...baseColDef, ...colDef, displayName: headerName }))
    };
    return GRID_COLUMN_DEFINITIONS[groupByFieldName];
  };

  // business logic
  const last28DaysRangeFilters = [
    {
      fieldName: 'dayId',
      minValue: +moment().subtract(28, 'days').format('YYYYMMDD'),
      maxValue: +moment().format('YYYYMMDD')
    },
    {
      fieldName: 'clicks',
      minValue: 20
    }
  ];
  // business logic
  const last28DaysComparisonRangeFilters = [
    {
      fieldName: 'dayId',
      minValue: +moment().subtract(57, 'days').format('YYYYMMDD'),
      maxValue: +moment().subtract(29, 'days').format('YYYYMMDD')
    }
  ];

  const fetchData = async () => {
    setLoading(true);
    const { indexName } = widget.data.configByGroupByFieldName[groupByField.name];
    const [{ aggregations: aggregationFields }] = buildAggregations(
      widget.data.configByGroupByFieldName[groupByField.name].aggregationFields
    );
    const termFilters = [
      {
        fieldName: 'campaignId',
        values: [mainEntity.id]
      }
    ];
    const groupByFieldName = groupByField.name === 'targetingText' ? 'targetingText,targetingType' : groupByField.name;

    // parent portfolios' CPC and ROAS

    const parentPortfolioId = mainEntity.extendedAttributes.portfolioId;
    const portfoliosTermFilters = [
      {
        fieldName: 'portfolioId',
        values: [parentPortfolioId]
      }
    ];

    const fetchRes = await store.dispatch(
      fetchEntityMetrics(
        'parent_portfolio',
        {
          entity: mainEntity,
          retailer,
          app,
          indexName,
          customResponseParser: (action: any) => {
            return zipMetricsResponseIntoArray(action, widget);
          }
        },
        [
          {
            doAggregation: true,
            aggregations: [
              {
                aggregationFields,
                conditions: {
                  termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
                  rangeFilters: [...last28DaysRangeFilters]
                },
                sortDirection: 'desc',
                comparisonRangeFilters: last28DaysComparisonRangeFilters,
                groupByFieldName: 'portfolioId'
              }
            ],
            conditions: {
              termFilters: portfoliosTermFilters,
              rangeFilters: [...last28DaysRangeFilters]
            },
            pageSize: 10,
            processDocuments: false
          }
        ],
        null,
        true
      )
    );

    const parentPortfolioCPC = _get(fetchRes, ['data', 0, 'costPerClick', 'value'], 0);
    const parentPortfolioROAS = _get(fetchRes, ['data', 0, 'returnOnAdSpend', 'value'], 0);

    const addirangeFilters = [];

    addirangeFilters.push({ fieldName: 'costPerClick', minValue: '44', maxValue: '1000000' });

    const recommendationsResult = await store.dispatch(
      fetchEntityMetrics(
        widget.data.statePropertyName,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName,
          customResponseParser: (action: any) => zipMetricsResponseIntoArray(action, widget)
        },
        [
          {
            doAggregation: true,
            aggregations: [
              {
                aggregationFields,
                conditions: {
                  termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
                  rangeFilters: [...last28DaysRangeFilters]
                },
                sortDirection: 'desc',
                comparisonRangeFilters: last28DaysComparisonRangeFilters,
                groupByFieldName
              }
            ],
            conditions: {
              termFilters,
              rangeFilters: [...last28DaysRangeFilters]
            },
            pageSize: 10000,
            processDocuments: false
          }
        ]
      )
    );

    const cloneResult = recommendationsResult;

    const targetingTextValues: string[] = [];
    recommendationsResult.data.forEach((dataPoint: any) => {
      if (!targetingTextValues.includes(dataPoint.entity.id)) {
        targetingTextValues.push(dataPoint.entity.id);
      }
    });

    const queryConditions = {
      termFilters: [
        {
          fieldName: 'targetingText',
          condition: 'should',
          values: targetingTextValues
        }
      ],
      rangeFilters: []
    };
    const { documents: adTargetDocuments } = await store.dispatch(
      fetchEntityMetrics(
        'adManager_searchResultsGrid_targets',
        {
          entity: mainEntity,
          retailer,
          app,
          indexName: 'adCampaignAdGroupTarget'
        },
        [
          {
            doAggregation: false,
            conditions: {
              termFilters: [...termFilters, ...queryConditions.termFilters],
              rangeFilters: []
            },
            pageNumber: 1,
            pageSize: 10000,
            processDocuments: false,
            returnDocuments: true
          }
        ],
        undefined,
        true
      )
    );
    const adTargetDocumentsByTargetingText: any = {};
    adTargetDocuments.forEach((adTargetDocument: any) => {
      if (!adTargetDocumentsByTargetingText[adTargetDocument.targetingText + adTargetDocument.targetingType]) {
        adTargetDocumentsByTargetingText[adTargetDocument.targetingText + adTargetDocument.targetingType] = {
          beaconClientId: adTargetDocument.beaconClientId,
          platformType: adTargetDocument.platformType,
          targetingText: adTargetDocument.targetingText,
          targetingType: adTargetDocument.targetingType,
          extendedAttributes: {
            statusReason: '',
            status: AD_CAMPAIGN_STATUS.PAUSED,
            statusDerived: AD_STATUS_DERIVED.PAUSED,
            childDocuments: []
          }
        };
      }
      if (adTargetDocument.status === AD_CAMPAIGN_STATUS.ENABLED) {
        adTargetDocumentsByTargetingText[
          adTargetDocument.targetingText + adTargetDocument.targetingType
        ].extendedAttributes.status = AD_CAMPAIGN_STATUS.ENABLED;
      }
      if (adTargetDocument.statusDerived === AD_STATUS_DERIVED.DELIVERING) {
        adTargetDocumentsByTargetingText[
          adTargetDocument.targetingText + adTargetDocument.targetingType
        ].extendedAttributes.statusDerived = AD_STATUS_DERIVED.DELIVERING;
      }
      adTargetDocumentsByTargetingText[
        adTargetDocument.targetingText + adTargetDocument.targetingType
      ].extendedAttributes.childDocuments.push(adTargetDocument);
    });
    cloneResult.data.forEach((dataPoint: any) => {
      if (adTargetDocumentsByTargetingText[dataPoint.id]) {
        adTargetDocumentsByTargetingText[dataPoint.id].extendedAttributes.campaignIds = _uniq(
          adTargetDocumentsByTargetingText[dataPoint.id].extendedAttributes.childDocuments.map(
            (adTargetDocument: any) => adTargetDocument.campaignId
          )
        );
        adTargetDocumentsByTargetingText[dataPoint.id].extendedAttributes.campaignIdsDelivering = _uniq(
          adTargetDocumentsByTargetingText[dataPoint.id].extendedAttributes.childDocuments
            .filter((x: any) => x.statusDerived === AD_STATUS_DERIVED.DELIVERING)
            .map((adTargetDocument: any) => adTargetDocument.campaignId)
        );

        adTargetDocumentsByTargetingText[dataPoint.id].extendedAttributes.statusDisplay = getCampaignDisplayStatus(
          adTargetDocumentsByTargetingText[dataPoint.id].extendedAttributes
        );
        dataPoint.entity = {
          ...dataPoint.entity,
          ...adTargetDocumentsByTargetingText[dataPoint.id]
        };
      }
    });

    let dataSetCloned = [];
    if (_get(mainEntity, ['extendedAttributes', 'automationAttributes', 'strategyId'], '') === 'MaximizeClicks') {
      // Max Clicks campaigns
      dataSetCloned = cloneResult.data.filter((item) => item.costPerClick.value >= parentPortfolioCPC * 2);
    } else {
      // Other campaigns
      dataSetCloned = cloneResult.data.filter((item) => item.returnOnAdSpend.value <= parentPortfolioROAS / 2);
    }
    const activeResult = dataSetCloned.filter(
      (item) => item.entity.extendedAttributes.status === AD_CAMPAIGN_STATUS.ENABLED && item.returnOnAdSpend.value <= 5
    );
    await store.dispatch(
      receiveEntitySalesMetrics(widget.data.statePropertyName, { ...cloneResult, data: activeResult })
    );

    setLoading(false);
  };

  useBus(props.eventBus, AdManagerEvents.ENTITY_EVENT_METRICS_GRID_FILTER_TOGGLED, fetchData);

  useEffect(() => {
    // fetch parent's portfolio's data here as filter
    // then fetch current entity's table
    if (!mainEntity) {
      return;
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainEntity, retailer.id]);

  const renderGrid = () => {
    return (
      <div className="recommendations_grid">
        <SearchResultsActionBar widget={widget} />
        <CustomAgMaterial
          onGridReady={onGridReady}
          onCellValueChanged={onGridReady}
          onModelUpdated={onGridReady}
          onRowValueChanged={onGridReady}
          onRowDataChanged={onGridReady}
          buildRows={() => dataSet}
          suppressNoRowsOverlay
          columnDefs={getGridColumnDefinitions(groupByField.name) as any}
          getRowNodeId={(datum: any) => escape(datum.id)}
          domLayout="autoHeight"
          rowHeight={120}
          containerStyle={{ width: '100%' }}
        />
        {_isEmpty(dataSet) && <CongratulationsWithOpenSummary />}
      </div>
    );
  };

  if (!mainEntity) {
    return null;
  }

  return (
    <div className="recommendations_container">
      <div className="header">Recommended Actions</div>
      <div className="sub_header">
        Consider pausing the targets below since they are below your performance thresholds. Metrics below represent the
        last 28 days.
      </div>
      {isLoading ? <GridLoading /> : renderGrid()}
    </div>
  );
};

const EnhancedAdRecommendations = withBus('eventBus')(connect(mapStateToProps)(AdRecommendations));

export default EnhancedAdRecommendations;
