/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

const numberRange = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  rotator: {
    transition: 'transform 0.5s',
    position: 'absolute',
    left: '0',
    top: '0',
    bottom: '0',
    right: '0'
  },
  piece: {
    position: 'absolute',
    left: '0',
    zIndex: 10
  },
  tick: {
    overflow: 'hidden',
    display: 'inline-block',
    position: 'relative'
  },
  hiddenPiece: {
    visibility: 'hidden'
  }
};

const Tick: React.FC<{
  value: string;
  height: number;
  range: string[];
  className: string;
  currentClassName: string;
  hiddenClassName: string;
}> = ({ value, height, range, className, currentClassName, hiddenClassName }) => (
  <span className={className} style={styles.tick}>
    <span className={className} style={styles.hiddenPiece}>
      {value}
    </span>
    <span style={{ ...styles.rotator, transform: `translateY(${height * range.indexOf(value) * -1}px)` }}>
      {range.map((v, i) => (
        <span
          key={v + i}
          className={[className, v === value ? currentClassName : hiddenClassName].join(' ')}
          style={{ ...styles.piece, top: i * height }}
        >
          {v}
        </span>
      ))}
    </span>
  </span>
);

const measureHeight = (className: string, value: string) => {
  const d = document.createElement('span');
  d.textContent = value;
  d.className = className;
  d.style.opacity = '0';
  d.style.pointerEvents = 'none';
  d.style.position = 'absolute';
  document.body.appendChild(d);
  const height = d.offsetHeight;
  document.body.removeChild(d);
  return height;
};

const NumFlip: React.FC<{
  text: string;
  textClassName: string;
  currentClassName?: string;
  hiddenClassName?: string;
}> = ({ children, text, textClassName, currentClassName = 'currentTicker', hiddenClassName = 'hiddenTicker' }) => {
  const height = useMemo(() => measureHeight(textClassName, '0'), [textClassName]);

  return (
    <div style={styles.root}>
      {((children as string | undefined) || text)
        .split('')
        .reverse()
        .map((v, i) => {
          if (Number.isNaN(parseFloat(v))) {
            return (
              <span key={i} className={textClassName} style={styles.tickStyle}>
                {v}
              </span>
            );
          }

          return (
            <Tick
              range={numberRange}
              className={textClassName}
              currentClassName={currentClassName}
              hiddenClassName={hiddenClassName}
              key={i}
              value={v}
              height={height}
            />
          );
        })}
    </div>
  );
};

export default NumFlip;
