import React from 'react';
import { CloseIcon } from 'src/components/SvgIcons/SvgIcons';
import _merge from 'lodash/merge';

type TeamPopupCloseIconProps = React.ComponentProps<typeof CloseIcon>;

/**
 * X icon for closing the popup
 */
export default function TeamPopupCloseIcon(props: TeamPopupCloseIconProps) {
  const mergedProps: TeamPopupCloseIconProps = _merge(
    {
      style: {
        cursor: 'pointer',
        width: '16px',
        height: '16px'
      }
    },
    props
  );

  return <CloseIcon {...mergedProps} />;
}
