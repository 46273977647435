import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import _pick from 'lodash/pick';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import queryString from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AutoSizer, List as VirtualizedList } from 'react-virtualized';
import i18n from 'src/i18n_en';
import ReduxStore from 'src/types/store/reduxStore';
import { anyNotEq } from 'src/utils/equality';
import { ListFilterRow } from './common';
import './Filter.scss';

const NoBrandsFound = () => <div style={{ height: 175 }}>{i18n.brands_noneFound}</div>;

type BrandForEntity = ReduxStore['brandsFollowing'] & {
  isChecked: boolean;
};

// We have to use lodash's `_.orderBy()` here since it's stable, and JS isn't guaranteed to have a stable sort algorithm
const sortBrands = (brands: BrandForEntity) => _orderBy(brands, [({ isChecked }) => (isChecked ? 1 : 0)], ['desc']);

interface BrandFilterProps {
  filters: any;
  brandsFollowing: ReduxStore['brandsFollowing'];
  entity: any;
  location: any;
  onFilterChange: (any, any) => void;
}

interface BrandFilterState {
  searchText: string;
  brandsForEntity: BrandForEntity[];
  staticSearchBrands: any[];
}

class BrandFilter extends Component<BrandFilterProps, BrandFilterState> {
  private static propTypes = {
    filters: PropTypes.object.isRequired,
    brandsFollowing: PropTypes.array.isRequired,
    entity: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired
  };

  public constructor(props: BrandFilterProps) {
    super(props);

    this.state = {
      searchText: '',
      brandsForEntity: [],
      staticSearchBrands: []
    };
  }

  public componentWillMount() {
    this.setBrands(this.props);
  }

  public componentWillReceiveProps(nextProps: BrandFilterProps) {
    const { filters } = nextProps;
    if (
      (filters.isClearingFilters && this.props.filters.brand !== null) ||
      anyNotEq(['entity', 'location'], this.props, nextProps) ||
      this.props.brandsFollowing !== nextProps.brandsFollowing // shallow equality for increased speed
    ) {
      this.setBrands(nextProps);
    }
  }

  private setBrands = (props: BrandFilterProps) => {
    const { brandsFollowing, entity, filters, location } = props;

    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const parsedFilterParams = queryParams.filter && queryString.parse(JSON.parse(queryParams.filter));

    // _get returns default value only the key does not exist.
    // (in this example, 'brandIds' has null value, so it returned null.)
    const entityBrandIds = _get(entity, 'brandIds', []) || [];

    const brandsForEntity = brandsFollowing
      .filter(({ brandId }) => {
        return entityBrandIds.includes(String(brandId));
      })
      .map((val) => {
        const isChecked =
          filters.brand !== null &&
          parsedFilterParams &&
          parsedFilterParams.b &&
          // eslint-disable-next-line
          !!parsedFilterParams.b.find((item) => item.i == val.id);

        return { ...val, isChecked };
      });

    this.setState({
      staticSearchBrands: brandsForEntity,
      brandsForEntity: sortBrands(brandsForEntity),
      searchText: ''
    });
  };

  private handleCheck = (event, isInputChecked: boolean, index: any) => {
    const { onFilterChange } = this.props;
    const { brandsForEntity, staticSearchBrands } = this.state;
    brandsForEntity[index].isChecked = isInputChecked;

    staticSearchBrands.forEach((val) => {
      if (String(val.brandId) === String(brandsForEntity[index].brandId)) {
        val.isChecked = isInputChecked;
      }
    });
    const values = staticSearchBrands.filter((val) => val.isChecked);

    this.setState({ staticSearchBrands, brandsForEntity });
    onFilterChange(values, 'brand');
  };

  private handleInputChange = ({ target: { value } }) => {
    const lowerCaseVal = value.toLowerCase();
    const updatedBrands = this.state.staticSearchBrands.filter(({ brandName }) =>
      brandName.toLowerCase().includes(lowerCaseVal)
    );

    this.setState({
      searchText: value,
      brandsForEntity: sortBrands(updatedBrands)
    });
  };

  private rowRenderer = ({ index, style }) => {
    const { brandsForEntity } = this.state;
    return (
      <ListFilterRow
        index={index}
        key={index}
        style={style}
        isChecked={brandsForEntity[index].isChecked}
        value={brandsForEntity[index].brandName}
        onCheck={this.handleCheck}
      />
    );
  };

  public render() {
    const { brandsForEntity, staticSearchBrands, searchText } = this.state;
    if (staticSearchBrands && _isEmpty(staticSearchBrands)) {
      return null;
    }

    return (
      <div className="search-form-container search-form-container--sm">
        <TextField
          variant="standard"
          autoComplete="off"
          className="sl-form-input"
          placeholder="Search for brands"
          type="text"
          name="keyword"
          id="keyword"
          style={{ width: '100%' }}
          value={searchText}
          onChange={this.handleInputChange}
        />
        {brandsForEntity && _isEmpty(brandsForEntity) ? <NoBrandsFound /> : null}
        {!_isEmpty(brandsForEntity) ? (
          <AutoSizer disableHeight>
            {({ width }) => (
              <VirtualizedList
                width={width}
                height={300}
                rowCount={brandsForEntity.length}
                rowHeight={45}
                style={{ marginBottom: 10 }}
                rowRenderer={this.rowRenderer}
              />
            )}
          </AutoSizer>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['app', 'brandsFollowing', 'comparisonTimePeriod', 'filters', 'mainTimePeriod', 'retailer']);

export default withRouter(connect(mapStateToProps)(BrandFilter));
