import React, { useMemo } from 'react';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import DonutChartLoading from 'src/components/Charts/Donut/DonutChartLoading';
import { useAppSelector } from 'src/utils/Hooks';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import _get from 'lodash/get';
import CircularProgressPercentage from 'src/components/common/UploadAnimation/CircularProgressPercentage';

const useCompliantAdsDonutData = () => {
  const retailer = useAppSelector((state) => state.retailer);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const { startWeek, endWeek } = mainTimePeriod;
  const categories = useAppSelector((state) => state.categories);

  const requestBuilder = new AdvancedSearchRequestBuilder(
    'donutChart-Ad-Compliance',
    'beacon-compliance-keywordaditem'
  );

  // Set properties for the request
  requestBuilder
    .setPageNumber(1)
    .setPageSize(200)
    .setRetailerId(retailer.id)
    .setPeriod('year')
    .setDoAggregation(true)
    .setReturnDocuments(false);

  // Add term filters to the request
  requestBuilder.addConditionTermFilter('retailerId', 'should', [retailer.id]);
  requestBuilder.addConditionTermFilter(
    'categoryId',
    'should',
    categories.map((category) => category.id)
  );

  // Create an instance of AggregationBuilder
  const aggregationBuilder = new AggregationBuilder('retailerId');

  // Add aggregation fields to the aggregation
  aggregationBuilder.addAggregationField('Compliant', 'authorized', 'sum', true);
  aggregationBuilder.addAggregationField('Violations', 'unauthorized', 'sum', true);
  aggregationBuilder.addAggregationField(
    'Compliant Ads',
    'authorizedKeywordAdPercent',
    'computed',
    true,
    '(((authorized_sum + unauthorized_sum) > 0) ? ((authorized_sum / (authorized_sum + unauthorized_sum))) : (1))'
  );

  // Add range filters to the aggregation
  aggregationBuilder.addConditionRangeFilter('weekId', startWeek, endWeek);

  // Add the aggregation to the request
  requestBuilder.addAggregation(aggregationBuilder.build());

  // Set the sort field for the request
  requestBuilder.addSortField('Compliant', 'authorized', 'sum', true);

  // Build the final request object
  const adComplianceDonutRequest = requestBuilder.build();

  const { isLoading, data } = useGenericAdvancedSearch({
    requestId: 'donutChart-Ad-Compliance',
    requestBody: [adComplianceDonutRequest],
    queryKeys: [adComplianceDonutRequest]
  });

  return { isLoading, data };
};

/**
 * Displays the donut chart for Compliant Ads
 */
const CompliantAdsDonutChart = () => {
  const { isLoading, data } = useCompliantAdsDonutData();

  const { authorizedKeywordDecimalValue } = useMemo(() => {
    return data
      ? {
          authorizedKeywordDecimalValue: _get(
            data,
            [
              'data',
              '0',
              'aggregations',
              'by_retailerId',
              '0',
              'additionalValues',
              'authorizedKeywordAdPercent_computed_value'
            ],
            0
          ),
          authorized: _get(
            data,
            ['data', '0', 'aggregations', 'by_retailerId', '0', 'additionalValues', 'authorized_sum_value'],
            0
          ),
          unauthorized: _get(
            data,
            ['data', '0', 'aggregations', 'by_retailerId', '0', 'additionalValues', 'unauthorized_sum_value'],
            0
          )
        }
      : { authorizedKeywordDecimalValue: 0, authorized: 0, unauthorized: 0 };
  }, [data]);

  return isLoading ? (
    <DonutChartLoading />
  ) : (
    <CircularProgressPercentage percentage={authorizedKeywordDecimalValue} size="308px" />
  );
};

export default CompliantAdsDonutChart;
