/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _omit from 'lodash/omit';
// import _get from 'lodash/get';
import memoizeOne from 'memoize-one';
import moment from 'moment';
import { withBus } from 'react-bus';

import ReduxStore from 'src/types/store/reduxStore';
import TargetEntitiesGrid from 'src/components/AdCampaignBuilder/Widgets/TargetEntitiesGrid';
import { AdManagerAdCampaignEntity, AppName } from 'sl-api-connector';
import { buildTargetDetailWidget } from 'src/components/Layout/Advertising/AdCampaignBuilderPageLayout/AdCampaignBuilderPageLayout';
import { store } from 'src/main';
import './AdOptimizationHistory.scss';
import { AdCampaignBuilderState } from 'src/types/store/storeTypes';
import { useOnce, useBus } from 'src/utils/Hooks';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { AD_TARGETING_TYPE, AD_TARGETING_CLASS, AD_TARGET_MATCH_TYPE } from 'sl-ad-campaign-manager-data-model';
import { warn, error } from 'src/utils/mixpanel';
import { prop } from 'src/utils/fp';
import PickTargetingType from 'src/components/AdCampaignBuilder/Steps/TargetMatch';

import { AdCampaignManagerServiceClient } from 'src/utils/adCampaignManagerServiceClient';

export const buildFiltersFromCampaignProducts = (
  campaignProducts: NonNullable<AdManagerAdCampaignEntity['adCampaignProducts']>
) => {
  const brandNameByNameId: Map<string | number, string> = new Map();
  const allCampaignBrands: Set<number> = new Set();
  const allCampaignCategoryIds: Set<number> = new Set();
  const allCampaignSubCategoryIds: Set<number> = new Set();

  campaignProducts.forEach((product) => {
    const { brandId, brandName, categoryId, subCategoryId, stacklineSku } =
      product.extendedAttributes.productMetaData || {};

    if (_isNil(brandId)) {
      warn(`Missing \`brandId\` in product metadata for campaign product with \`stacklineSku\` "${stacklineSku}"`);
    } else if (_isNil(brandName)) {
      warn(`Missing \`brandName\` in product metadata for campaign product with \`stacklineSku\` "${stacklineSku}"`);
    } else {
      allCampaignBrands.add(brandId);
      brandNameByNameId.set(brandId, brandName);
    }

    if (_isNil(brandId)) {
      warn(`Missing \`categoryId\` in product metadata for campaign product with \`stacklineSku\` "${stacklineSku}"`);
    } else {
      allCampaignCategoryIds.add(categoryId);
    }

    if (_isNil(subCategoryId)) {
      warn(
        `Missing \`subCategoryId\` in product metadata for campaign product with \`stacklineSku\` "${stacklineSku}"`
      );
    } else {
      allCampaignSubCategoryIds.add(subCategoryId);
    }
  });

  return {
    filters: {
      isDefault: true,
      termFilters: {
        brandId: {
          condition: 'should' as const,
          values: []
        },
        categoryId: {
          condition: 'should' as const,
          values: [...allCampaignCategoryIds].map((categoryId) => ({ i: categoryId }))
        },
        subCategoryId: {
          condition: 'should' as const,
          values: [...allCampaignSubCategoryIds].map((subCategoryId) => ({ i: subCategoryId }))
        }
      },
      rangeFilters: {}
    },
    featuredProductBrandIds: [...allCampaignBrands],
    featuredProductCategoryIds: [...allCampaignCategoryIds],
    featuredProductSubCategoryIds: [...allCampaignSubCategoryIds]
  };
};

const reHydrateReduxStateForCampaign = (campaign: AdManagerAdCampaignEntity, targetingTypeId: AD_TARGETING_TYPE) => {
  const monthlyBudget = _isNil(campaign.extendedAttributes.currentMonthBudget)
    ? 2500
    : campaign.extendedAttributes.currentMonthBudget;
  const dailyBudget = moment().daysInMonth();

  if (_isNil(campaign.adCampaignProducts) || _isEmpty(campaign.adCampaignProducts)) {
    error(
      `\`adCampaignProducts\` is nil or empty when trying to display "add keywords to campaign" grid for campaign id ${campaign.id}`
    );
    return null;
  }

  const previouslySelectedTargetEntityIds = (() => {
    if (_isEmpty(campaign.adTargets)) {
      error(`Campaign id ${campaign.id} has no target keywords AND no target products; don't know what to do.`);
      return [];
    }
    if (campaign.adTargets && !_isEmpty(campaign.adTargets)) {
      return campaign.adTargets.map(prop('targetingText'));
    }
    return [];
  })();

  const { filters, ...newFeaturedState } = buildFiltersFromCampaignProducts(campaign.adCampaignProducts);

  const newTargetState: AdCampaignBuilderState['target'] = {
    loading: false,
    filters,
    budget: {
      total: monthlyBudget,
      daily: dailyBudget,
      displayName: 'THIS SHOULD NEVER DISPLAY'
    },
    previouslySelectedTargetEntityIds,
    selectedTargetEntities: [],
    targetingTypeId,
    targetEntitiesListFromAtlas: [],
    uploadedTargetKeywords: null
  };

  const baseAdCampaignBuilderState = (store.getState() as ReduxStore).adCampaignBuilder;

  store.dispatch(
    adCampaignBuilderActions.replaceState({
      ...baseAdCampaignBuilderState,
      target: newTargetState,
      featured: { ...baseAdCampaignBuilderState.featured, ...newFeaturedState }
    })
  );

  return newFeaturedState;
};

const mapAddKeywordToCampaignStateToProps = (state: ReduxStore) => ({
  appName: state.app.name,
  featuredProductBrandIds: state.adCampaignBuilder.featured.featuredProductBrandIds
});

type AddKeywordToCampaignProps = {
  currentStep: number;
  eventBus: any;
  adGroupId: any;
  campaignEntity: AdManagerAdCampaignEntity;
} & ReturnType<typeof mapAddKeywordToCampaignStateToProps>;

const buildTargetEntitiesGridWidget = memoizeOne((appName: AppName, targetingType: AD_TARGETING_TYPE) => {
  const widget = buildTargetDetailWidget(appName);
  return { ...widget, ...widget.configByTargetType[targetingType] };
});

const AddKeywordToCampaignInner: React.FC<AddKeywordToCampaignProps> = ({
  currentStep,
  eventBus,
  adGroupId,
  campaignEntity,
  appName,
  featuredProductBrandIds
}) => {
  const expectedFeaturedProductBrandIds = useRef<(string | number)[] | null | undefined>(null);
  const [submitStatus, setSubmitStatus] = useState({ status: 'normal' });

  useEffect(() => {
    eventBus.emit('targetPageButtonStatus', submitStatus);
  }, [eventBus, submitStatus]);

  const submit = async () => {
    setSubmitStatus({ status: 'submitting' });
    const campaignEntityModified: IAdCampaign = _omit(campaignEntity, [
      'type',
      'displayName',
      'id',
      'entity',
      'adTargets',
      'adCampaignProducts'
    ]) as IAdCampaign;
    const {
      beaconClientId,
      platformType,
      campaignId,
      extendedAttributes: { beaconClientLoginId, retailerId, entityId, entityIdApi }
    } = campaignEntity.adTargets[0];
    campaignEntityModified.adTargets = [];
    store.getState().adCampaignBuilder.target.keywordList.forEach((list) => {
      list.forEach((keywordMatch) => {
        const { keyword, matchType, selected, cpcMaxBidAmount } = keywordMatch;
        if (selected) {
          campaignEntityModified.adTargets.push({
            beaconClientId,
            platformType,
            campaignId,
            adGroupId,
            targetingClass: AD_TARGETING_CLASS.KEYWORD,
            targetingText: keyword,
            extendedAttributes: {
              beaconClientLoginId,
              retailerId,
              entityId,
              entityIdApi,
              matchType,
              cpcMinBidAmount: +cpcMaxBidAmount.toFixed(2),
              cpcMaxBidAmount: +cpcMaxBidAmount.toFixed(2),
              keywordMetaData: {
                matchType
              }
            }
          });
        }
      });
    });
    const submitResult = await AdCampaignManagerServiceClient.createCampaignTargets({
      adCampaign: campaignEntityModified
    });
    if (submitResult && submitResult.status === 200) {
      setSubmitStatus({ status: 'submitted', result: submitResult });
    } else {
      setSubmitStatus({ status: 'failure', result: submitResult });
    }
  };

  useBus(eventBus, 'submitAddNewKeyword', submit);

  useOnce(() => {
    const newFeaturedState = reHydrateReduxStateForCampaign(campaignEntity, AD_TARGETING_TYPE.KEYWORD_TARGETING);
    if (!newFeaturedState) {
      return;
    }

    // We wait for Redux to update to contain our new state before proceeding to render the target keywords grid.
    //
    // We can't let it know what kind of horrible twisted reality it's living in.
    expectedFeaturedProductBrandIds.current = newFeaturedState.featuredProductBrandIds;
  });

  if (featuredProductBrandIds !== expectedFeaturedProductBrandIds.current) {
    return null;
  }

  return (
    <div className="targeting_container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {currentStep === 0 ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TargetEntitiesGrid
              widget={buildTargetEntitiesGridWidget(appName, AD_TARGETING_TYPE.KEYWORD_TARGETING)}
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginBottom: 70
              }}
              gridStyle={{ height: 880 }}
              adGroupId={adGroupId}
              pageSize={100}
            />
          </div>
        ) : (
          <PickTargetingType onlyTable hideOperationButtons style={{ marginTop: 5, paddingTop: 0 }} />
        )}
      </div>
    </div>
  );
};

export const AddKeywordToCampaign = connect(mapAddKeywordToCampaignStateToProps)(
  withBus('eventBus')(AddKeywordToCampaignInner)
);

const mapAddProductsToCampaignStateToProps = (state: ReduxStore) => ({
  appName: state.app.name,
  featuredProductBrandIds: state.adCampaignBuilder.featured.featuredProductBrandIds
});

const AddProductsToCampaignInner: React.FC<
  { eventBus: any; adGroupId: any; campaignEntity: AdManagerAdCampaignEntity } & ReturnType<
    typeof mapAddProductsToCampaignStateToProps
  >
> = ({ eventBus, adGroupId, campaignEntity, featuredProductBrandIds, appName }) => {
  const expectedFeaturedProductBrandIds = useRef<(string | number)[] | null | undefined>(null);
  const [submitStatus, setSubmitStatus] = useState({ status: 'normal' });

  useEffect(() => {
    eventBus.emit('targetPageButtonStatus', submitStatus);
  }, [eventBus, submitStatus]);

  const submit = async () => {
    setSubmitStatus({ status: 'submitting' });
    const campaignEntityModified: IAdCampaign = _omit(campaignEntity, [
      'type',
      'displayName',
      'id',
      'entity',
      'adTargets',
      'adCampaignProducts'
    ]) as IAdCampaign;
    const {
      beaconClientId,
      platformType,
      campaignId,
      extendedAttributes: { beaconClientLoginId, retailerId, entityId, entityIdApi }
    } = campaignEntity.adTargets[0];
    campaignEntityModified.adTargets = store
      .getState()
      .adCampaignBuilder.target.selectedTargetEntities.filter(prop('selected'))
      .map(({ keyword, startBid }: { keyword: string; startBid: number }) => {
        const targetingText = keyword;
        return {
          beaconClientId,
          platformType,
          campaignId,
          adGroupId,
          targetingClass: AD_TARGETING_CLASS.TARGET,
          targetingText,
          extendedAttributes: {
            beaconClientLoginId,
            retailerId,
            entityId,
            entityIdApi,
            cpcMinBidAmount: +startBid.toFixed(2),
            cpcMaxBidAmount: +startBid.toFixed(2),
            matchType: AD_TARGET_MATCH_TYPE.PRODUCT
          }
        };
      });
    const submitResult = await AdCampaignManagerServiceClient.createCampaignTargets({
      adCampaign: campaignEntityModified
    });
    if (submitResult && submitResult.status === 200) {
      setSubmitStatus({ status: 'submitted', result: submitResult });
    } else {
      setSubmitStatus({ status: 'failure', result: submitResult });
    }
  };
  useBus(eventBus, 'submitAddNewProduct', submit);

  useOnce(() => {
    const newFeaturedState = reHydrateReduxStateForCampaign(campaignEntity, AD_TARGETING_TYPE.PRODUCT_TARGETING);
    if (!newFeaturedState) {
      return;
    }

    // We wait for Redux to update to contain our new state before proceeding to render the target keywords grid.
    //
    // We can't let it know what kind of horrible twisted reality it's living in.
    expectedFeaturedProductBrandIds.current = newFeaturedState.featuredProductBrandIds;
  });

  if (featuredProductBrandIds !== expectedFeaturedProductBrandIds.current) {
    return null;
  }

  return (
    <div className="targeting_container">
      <br /> <br />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TargetEntitiesGrid
          widget={buildTargetEntitiesGridWidget(appName, AD_TARGETING_TYPE.PRODUCT_TARGETING)}
          style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: 70 }}
          gridStyle={{ height: 880 }}
          adGroupId={adGroupId}
        />
      </div>
    </div>
  );
};

export const AddProductsToCampaign = connect(mapAddProductsToCampaignStateToProps)(
  withBus('eventBus')(AddProductsToCampaignInner)
);
