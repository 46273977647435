import { AD_STATUS_DERIVED, AD_CAMPAIGN_STATUS } from 'sl-ad-campaign-manager-data-model';

const RECEIVE_AD_CAMPAIGN_STATES = 'src/adCampaignStates/RECEIVE_AD_CAMPAIGN_STATES' as const;
const CLEAR_AD_CAMPAIGN_STATES = 'src/adCampaignStates/CLEAR_AD_CAMPAIGN_STATES' as const;

export interface AdCampaignState {
  id: AD_STATUS_DERIVED | AD_CAMPAIGN_STATUS;
  statusDerived: AD_STATUS_DERIVED | AD_CAMPAIGN_STATUS;
  name: string;
  displayName: string;
  type: 'adCampaignState';
}

export default {
  RECEIVE_AD_CAMPAIGN_STATES,
  CLEAR_AD_CAMPAIGN_STATES
};
