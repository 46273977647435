import React, { useState } from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { SearchTextField } from 'src/components/BeaconRedesignComponents/SearchInput/SearchInputBar';
import CompareScrollableContainer from './CompareScrollableContainer';
import CompareOption from './CompareOption';
import { useChartComparison } from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/ChartComparisonProvider';
import { useStacklineTheme } from '@stackline/ui';

export interface CompareMetricOption {
  label: string;
  indexName: string;
  fieldName: string;
}

const trafficOptions: CompareMetricOption[] = [
  {
    label: 'Total',
    indexName: 'traffic',
    fieldName: 'totalClicks'
  },
  {
    label: 'Organic Traffic',
    indexName: 'traffic',
    fieldName: 'organicClicks'
  },
  {
    label: 'Ad Clicks',
    indexName: 'advertising',
    fieldName: 'clicks'
  },
  {
    label: 'Ad Spend',
    indexName: 'advertising',
    fieldName: 'spend'
  },
  {
    label: 'Ad Units Sold',
    indexName: 'advertising',
    fieldName: 'unitsSold'
  },
  {
    label: 'Ad Impressions',
    indexName: 'advertising',
    fieldName: 'impressions'
  },
  {
    label: 'Ad CTR%',
    indexName: 'advertising',
    fieldName: 'clickThroughRate'
  },
  {
    label: 'Ad CPC',
    indexName: 'advertising',
    fieldName: 'costPerClick'
  },
  {
    label: 'Ad CPM',
    indexName: 'advertising',
    fieldName: 'costPerMilli'
  },
  {
    label: 'Ad CPA',
    indexName: 'advertising',
    fieldName: 'costPerAcquisition'
  },
  {
    label: 'Ad Conversion Rate',
    indexName: 'advertising',
    fieldName: 'conversionRate'
  },
  {
    label: 'Ad ROAS',
    indexName: 'advertising',
    fieldName: 'returnOnAdSpend'
  }
];

const conversionOptions: CompareMetricOption[] = [
  // TODO Add conversion rate here
  {
    label: 'Retail Price',
    indexName: 'sales',
    fieldName: 'retailPrice'
  },
  {
    label: 'In-Stock Rate',
    indexName: 'sales',
    fieldName: 'instockRate'
  },
  {
    label: 'Units on Hand',
    indexName: 'sales',
    fieldName: 'unitsOnHand'
  },
  {
    label: 'Weeks on Hand',
    indexName: 'sales',
    fieldName: 'inventoryWeeksOnHand'
  },
  {
    label: 'On Hand - Retail',
    indexName: 'sales',
    fieldName: 'inventoryRetailValue'
  },
  {
    label: 'Buy Box - Rate',
    indexName: 'buybox',
    fieldName: 'winPercentage'
  }
];

const salesOptions: CompareMetricOption[] = [
  {
    label: 'Retail Sales',
    indexName: 'sales',
    fieldName: 'retailSales'
  },
  {
    label: 'Wholesale Sales',
    indexName: 'sales',
    fieldName: 'wholesaleSales'
  },
  {
    label: 'Units Sold',
    indexName: 'sales',
    fieldName: 'unitsSold'
  },
  {
    label: 'Retailer Margin',
    indexName: 'sales',
    fieldName: 'retailerGrossMargin'
  },
  {
    label: 'Retailer Margin (%)',
    indexName: 'sales',
    fieldName: 'retailerGrossMarginPercent'
  },
  {
    label: 'Return Rate (%)',
    indexName: 'sales',
    fieldName: 'returnRate'
  },
  {
    label: 'Units Returned',
    indexName: 'sales',
    fieldName: 'unitsReturned'
  }
];

const compareMetricOptions = [
  {
    title: 'Traffic',
    options: trafficOptions
  },
  {
    title: 'Conversion',
    options: conversionOptions
  },
  {
    title: 'Sales',
    options: salesOptions
  }
];

interface CompareMetricsProps {
  handleClose: () => void;
}

/**
 * Compare popup inner content for comparing metrics
 */
export default function CompareMetrics({ handleClose }: CompareMetricsProps) {
  const [metricSearch, setMetricSearch] = useState('');
  const { setMetricComparator, metricComparator } = useChartComparison();
  const theme = useStacklineTheme();

  const handleSelectOption = (option: CompareMetricOption) => {
    setMetricComparator({
      fieldName: option.fieldName,
      indexName: option.indexName
    });
    handleClose();
  };

  const filteredOptions = compareMetricOptions
    .map((option) => ({
      ...option,
      options: option.options.filter((metricOption) =>
        metricOption.label.toLowerCase().includes(metricSearch.toLowerCase())
      )
    }))
    .filter((option) => option.options.length > 0);

  return (
    <Flex flexDirection="column" gap="md">
      <Text variant="subtitle1">Metrics</Text>
      <SearchTextField
        placeholder="Search metrics"
        value={metricSearch}
        onChange={(e) => setMetricSearch(e.target.value)}
        sx={{
          width: '100%'
        }}
      />
      <CompareScrollableContainer height="417px">
        {filteredOptions.map((option) => (
          <React.Fragment key={option.title}>
            <Flex alignItems="center" paddingY={theme.spacing.sm} paddingLeft="12px">
              <Text variant="subtitle2">{option.title}</Text>
            </Flex>
            {option.options.map((metricOption) => (
              <CompareOption
                key={metricOption.label}
                label={metricOption.label}
                selected={metricComparator && metricComparator.fieldName === metricOption.fieldName}
                onClick={() => handleSelectOption(metricOption)}
                sx={{
                  paddingLeft: '24px'
                }}
              />
            ))}
          </React.Fragment>
        ))}
      </CompareScrollableContainer>
    </Flex>
  );
}
