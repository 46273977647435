import { useAppSelector } from 'src/utils/Hooks';
import _get from 'lodash/get';

export const useLinkForDisputeManagement = () => {
  // Set pathname entity to be client
  const clientHashId = useAppSelector((state) => _get(state, ['user', 'config', 'vendor', 'hashId']));
  const pathname = `/client/${clientHashId}`;

  // Get the following information from Redux
  const retailerId = useAppSelector((state) => _get(state, ['retailer', 'id']));
  const mainTimePeriod = useAppSelector((state) => _get(state, ['mainTimePeriod']));
  // Start day, end day, start week, end week, week range id
  const { startDayId, endDayId, startWeek, endWeek, id } = mainTimePeriod;
  return `${pathname}?rid=${retailerId}&pid=prior-period&sw=${startWeek}&ew=${endWeek}&sdid=${startDayId}&edid=${endDayId}&wr=${id}&tab=operations&subtab=disputes`;
};
