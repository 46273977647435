import React from 'react';
import PropTypes from 'prop-types';

import { Password } from 'src/components/Forms';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';

class UserSecurity extends React.Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    clearInput: PropTypes.bool.isRequired,
    isFormSubmitted: PropTypes.bool.isRequired,
    isPasswordConfirmationSame: PropTypes.bool.isRequired,
    serverErrorMessage: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleCurrentPasswordChange: PropTypes.func.isRequired,
    handleNewPasswordChange: PropTypes.func.isRequired,
    handlePasswordConfirmationChange: PropTypes.func.isRequired
  };

  showPasswordConfirmationError = () => {
    const { isFormSubmitted, isPasswordConfirmationSame } = this.props;

    if (!isFormSubmitted || (isFormSubmitted && isPasswordConfirmationSame)) {
      return null;
    }

    return 'Please make passwords match';
  };

  render() {
    const {
      buttonText,
      clearInput,
      handleNewPasswordChange,
      handlePasswordConfirmationChange,
      isFormSubmitted,
      serverErrorMessage,
      onSubmit
    } = this.props;

    return (
      <form className="security" onSubmit={onSubmit}>
        <h4 className="sl-header-text">Security</h4>
        <hr className="sl-divider sl-divider--no-margin-top sl-divider--lg-margin-bottom" />
        {serverErrorMessage !== '' && (
          <div style={{ textAlign: 'center', fontSize: '16px', color: 'red', marginTop: '10px', marginBottom: '20px' }}>
            {serverErrorMessage}
          </div>
        )}
        <h4 className="sl-form-label">New Password</h4>
        <Password
          id="newPassword"
          className="sl-form-input"
          hintText=""
          floatingLabelText=""
          clearInput={clearInput}
          isFormSubmitted={isFormSubmitted}
          onChange={handleNewPasswordChange}
          inputStyle={{ marginTop: '0' }}
          style={{ height: '48px', width: '100%' }}
          validateLength
        />
        <h4 className="sl-form-label">Re-enter Password</h4>
        <Password
          id="confirmPassword"
          className="sl-form-input"
          hintText=""
          floatingLabelText=""
          clearInput={clearInput}
          error={this.showPasswordConfirmationError()}
          isFormSubmitted={isFormSubmitted}
          onChange={handlePasswordConfirmationChange}
          inputStyle={{ marginTop: '0' }}
          style={{ height: '48px', width: '100%' }}
        />
        <div className="signup-password-rules-container">
          <p>Password should have: </p>
          <ul>
            <li>at least 8 characters</li>
            <li>at least one lowercase letter</li>
          </ul>
        </div>
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <LargeMuiButton label={buttonText} type="submit" />
        </div>
      </form>
    );
  }
}

export default UserSecurity;
