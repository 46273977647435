import { Filters } from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeManagementDashboard';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import {
  InvoiceSummaryResponse,
  ShipmentSummaryResponse,
  FinancialSummaryResponse
} from 'src/components/ShortageDisputes/responseTypes';
import axios, { CancelToken } from 'axios';
import { Dispute } from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeDetailsTable';
import {
  InvoiceRequestParameters,
  buildRequestForInvoiceData,
  ShippingRequestParameters,
  buildRequestForShippingData,
  FinancialRequestParameters,
  buildRequestForFinancialData,
  ShortedASINRequestParameters,
  buildRequestForShortedASINs,
  InvoiceSearchRequestParameters,
  buildInvoiceSearchRequest
} from 'src/components/ShortageDisputes/requestBuilders';

export const initialFilters: Filters = {
  startDate: '',
  endDate: '',

  minValue: '',
  maxValue: '',

  stage: '',
  status: ''
};

export const parseUrlIntoFilters = (searchParameters: URLSearchParams, objectFormat: Filters | any) => {
  const formatString = (str: string) => {
    return str
      .replace(/-/g, ' ')
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const parsed = Object.keys(objectFormat).reduce((memo, key) => {
    return {
      ...memo,
      [key]: formatString(searchParameters.get(key) || '')
    };
  }, {} as Filters | any);

  return parsed;
};

/**
 * @param obj Any object where values might be null, "null", or undefined.
 * @returns A cleaned object where missing values are replaced with a default value.
 */
export const replaceNullValues = (obj) => {
  const clonedObject = _cloneDeep(obj);
  const fallbackValues = {
    shipmentCarrierReferenceNumbers: [] // We always want an array as a default value
  };

  Object.keys(clonedObject).forEach((key) => {
    if (clonedObject[key] === null || clonedObject[key] === 'null' || clonedObject[key] === undefined) {
      clonedObject[key] = fallbackValues[key] || '';
    }
  });
  return clonedObject;
};

/**
 * Used to map our filter object to the equivalent request body values.
 * @param filters An object containing all of our applied filters for the request body.
 * @returns An object with our correctly mapped values for a request.
 */
export const generateFilterModifiers = (filters: Filters) => {
  // Our fields in the requests are case sensitive; if we need to make any small adjustments to stage/status to correctly format it we do that here.
  // If our stage filter is empty, we use an array with the default stages we should show. Omitting these will cause the API to return disputes in various other stages that we want to avoid showing.
  const filterToRequestMap = {
    stage: {
      '': ['New Shortage', 'Initial Dispute', 'Contact Us Case', 'Resolved'],
      'New Shortage': 'New Shortage',
      'Initial Dispute': 'Initial Dispute',
      'Contact Us Case': 'Contact Us Case',
      Resolved: 'Resolved'
    },
    status: {
      '': '',
      'Needs Supporting Documents': 'Needs Supporting Documents',
      'Ready To Submit': 'Ready to Submit',
      'Queued For Submission': 'Queued for Submission',
      'Submission In Progress': 'Submission in Progress',
      'Failed To Submit': 'Failed to Submit',
      Pending: 'Pending',
      'Needs Action': 'Needs Action',
      Won: 'Won',
      Lost: 'Lost',
      'Partially Won': 'Partially Won'
    }
  };
  let stageCollection = [];
  const mappedStages = filterToRequestMap.stage[filters.stage];
  // API responses will default return results with various unwanted stages if we pass an empty string.
  // If our "stage" filter is an empty string, we default to only include the stages we want.
  if (Array.isArray(mappedStages)) {
    stageCollection = [...mappedStages];
  } else {
    stageCollection.push(mappedStages);
  }

  const statusForRequest = [filterToRequestMap.status[filters.status]] || [];

  const modifiers = {
    stage: stageCollection,
    status: statusForRequest,
    minShortageValue: filters.minValue,
    maxShortageValue: filters.maxValue
  };

  return modifiers;
};

interface GenerateCombinedDataParameters {
  invoiceSummaryData: InvoiceSummaryResponse[];
  shippingDetailsData: ShipmentSummaryResponse[];
  financialDetailsData: FinancialSummaryResponse[];
  asinCollection: any;
}

export const generateCombinedDataSet = ({
  invoiceSummaryData,
  shippingDetailsData,
  financialDetailsData,
  asinCollection
}: GenerateCombinedDataParameters) => {
  const collectionOfShortedProducts = asinCollection;

  // ASIN details for each Invoice
  // create a Map where the keys are invoice numbers and the values are collections of products
  const asinDataMap = new Map();

  collectionOfShortedProducts.forEach((product) => {
    const invoiceNumber = product.originalInvoiceNumber;
    if (!asinDataMap.has(invoiceNumber)) {
      asinDataMap.set(invoiceNumber, []);
    }
    asinDataMap.get(invoiceNumber).push(product.retailerSku);
  });

  const dataSetCollection = [];
  invoiceSummaryData.forEach((invoice) => {
    const disputeBody = {
      originalInvoice: '',
      invoiceDate: '',
      disputeType: '',
      disputeValue: '',
      disputeStage: '',
      disputeStatus: '',
      purchaseOrder: '',
      disputeId: '',
      asin: [],
      submittedValue: '',
      recoveredValue: '',
      lostValue: '',
      trackingNumbers: [],
      arn: '',
      carrier: ''
    };

    // Invoice Summary Data from first request
    const cleanedInvoiceDetails = replaceNullValues(invoice);
    disputeBody.originalInvoice = _get(cleanedInvoiceDetails, 'originalInvoiceNumber', '');
    disputeBody.invoiceDate = _get(cleanedInvoiceDetails, 'originalInvoiceDate', '');
    disputeBody.disputeId = _get(cleanedInvoiceDetails, 'disputeId', '');
    disputeBody.disputeType = 'Shortage';
    disputeBody.disputeStage = _get(cleanedInvoiceDetails, 'customDisputeStage', 'N/A');
    disputeBody.disputeStatus = _get(cleanedInvoiceDetails, 'customDisputeStatus', 'N/A');

    // Shipping Details for each invoice from second request
    const shippingDetailsMatch = shippingDetailsData.find(
      (item) => item.originalInvoiceNumber === _get(invoice, 'originalInvoiceNumber', '')
    );
    if (shippingDetailsMatch) {
      const cleanedShippingDetails = replaceNullValues(shippingDetailsMatch);
      disputeBody.purchaseOrder = _get(cleanedShippingDetails, 'purchaseOrderNumber', '');
      disputeBody.trackingNumbers = _get(cleanedShippingDetails, 'shipmentCarrierReferenceNumbers', []);
      disputeBody.arn = _get(cleanedShippingDetails, 'shipmentArn', '');
      disputeBody.carrier = _get(cleanedShippingDetails, 'shipmentCarrierscac', '');
    }

    // Financial Details for each invoice from third request
    // Find the financial details for the invoice we're building up
    const financialDetailsMatch = financialDetailsData.find(
      (disputeData) => _get(disputeData, 'fieldId') === disputeBody.originalInvoice
    );
    if (financialDetailsMatch) {
      const cleanedFinancialDetails = replaceNullValues(financialDetailsMatch);
      disputeBody.disputeValue = _get(
        cleanedFinancialDetails,
        'additionalValues.updatedShortageAmountBeforeDispute_sum_value',
        ''
      );
      disputeBody.submittedValue = _get(
        cleanedFinancialDetails,
        'additionalValues.disputeSubmittedAmount_sum_value',
        ''
      );
      disputeBody.recoveredValue = _get(cleanedFinancialDetails, 'additionalValues.disputeWonAmount_sum_value', '');
      disputeBody.lostValue = _get(cleanedFinancialDetails, 'additionalValues.disputeLostAmount_sum_value', '');
    }

    // Use the map we created earlier to easily access the shorted ASINs per invoice number
    const asinGroup = asinDataMap.get(_get(invoice, 'originalInvoiceNumber', ''));
    disputeBody.asin = asinGroup || [];

    dataSetCollection.push(disputeBody);
  });

  return dataSetCollection;
};

interface FetchDisputeDataParameters {
  invoiceDataRequestParameters: InvoiceRequestParameters;
  categories: any;
  requestConfig: { cancelToken: CancelToken };
  searchResults?: string[] | null;
}

interface DisputeData {
  combinedDataSet: Dispute[];
  invoiceSummaryData: InvoiceSummaryResponse[];
  shippingDetailsData: ShipmentSummaryResponse[];
  financialDetailsData: FinancialSummaryResponse[];
  asinCollection: any[];
}

/**
 * This function fetches summary and detail-level data with certain conditions applied if present in the arguments.
 * @param invoiceDataRequestParameters The initial parameters for the invoice summary request body. This determines if we're fetching for 1 invoice or 10, and whether or not filters are applied.
 * @param requestConfig contains cancel tokens if the user navigates away from the page
 * @param categories
 * @param searchResults Optional collection of invoices to limit results for
 * @returns A combined data set and the data used to create it: invoice summary details, shipping details, financial details, and shorted ASIN details.
 */
export const fetchDisputeData = async ({
  invoiceDataRequestParameters,
  requestConfig,
  categories,
  searchResults = []
}: FetchDisputeDataParameters): Promise<DisputeData> => {
  // If our search failed to retrieve any results, we show blank results
  if (!searchResults) {
    return {
      combinedDataSet: [],
      invoiceSummaryData: [],
      shippingDetailsData: [],
      financialDetailsData: [],
      asinCollection: []
    };
  }

  // If we are using filters (date range, value range, stage, status) or we have valid search results, we fetch all the details we need

  try {
    const { retailerId } = invoiceDataRequestParameters;

    // First Request: Invoice Summary Data (this will determine the invoice numbers passed to the remaining 3 API requests)
    const invoiceSummaryRequest = buildRequestForInvoiceData(invoiceDataRequestParameters);
    const firstResponse = await axios.post(
      `/api/beacon/AdvancedSearch?_id=${invoiceSummaryRequest[0].id}`,
      invoiceSummaryRequest,
      requestConfig
    );
    const invoiceSummaryData: InvoiceSummaryResponse[] = _get(firstResponse, 'data[0].documents', []);
    const invoices = invoiceSummaryData.map((document) => document.originalInvoiceNumber) || [];

    // If we don't receive any resulting invoice numbers, we need to bail out of the request chain to avoid unnecessary API queries.
    // The remaining 3 requests are dependent on invoices being returned on the first request.
    if (!invoices || invoices.length === 0) {
      return {
        combinedDataSet: [],
        invoiceSummaryData: [],
        shippingDetailsData: [],
        financialDetailsData: [],
        asinCollection: []
      };
    }

    // Second Request: Shipping Details
    const shippingDataRequestParameters: ShippingRequestParameters = {
      invoiceNumbers: [...invoices],
      retailerId
    };
    const shippingDetailsRequest = buildRequestForShippingData(shippingDataRequestParameters);
    const secondResponse = await axios.post(
      `/api/beacon/AdvancedSearch?_id=${shippingDetailsRequest[0].id}`,
      shippingDetailsRequest,
      requestConfig
    );
    const shippingDetailsData: ShipmentSummaryResponse[] = _get(secondResponse, 'data[0].documents', []);

    // Third Request: Financial Details
    const financialDataRequestParameters: FinancialRequestParameters = {
      invoiceNumbers: [...invoices],
      retailerId
    };
    const financialDetailsRequest = buildRequestForFinancialData(financialDataRequestParameters);
    const thirdResponse = await axios.post(
      `/api/beacon/AdvancedSearch?_id=${financialDetailsRequest[0].id}`,
      financialDetailsRequest,
      requestConfig
    );
    const financialDetailsData: FinancialSummaryResponse[] = _get(
      thirdResponse,
      'data[0].aggregations.by_originalInvoiceNumber',
      []
    );

    // Fourth Request: ASIN-level details
    const shortedASINDataRequestParameters: ShortedASINRequestParameters = {
      invoiceNumbers: [...invoices],
      retailerId,
      categories
    };
    const shortedASINRequest = buildRequestForShortedASINs(shortedASINDataRequestParameters);
    const fourthResponse = await axios.post(
      `/api/beacon/AdvancedSearch?_id=request-asin-detail-data-4`,
      shortedASINRequest,
      requestConfig
    );
    const asinCollection = _get(fourthResponse, 'data[0].documents', []);

    const combinedDataSet: Dispute[] = generateCombinedDataSet({
      invoiceSummaryData,
      shippingDetailsData,
      financialDetailsData,
      asinCollection
    });

    return {
      combinedDataSet,
      invoiceSummaryData,
      shippingDetailsData,
      financialDetailsData,
      asinCollection
    };
  } catch (error) {
    console.error('Failed to fetch data');
    return null;
  }
};

/**
 * Takes a search term as a string and checks it against multiple fields to find an existing invoice.
 * @param searchTerm string from user input
 * @returns Invoice number(s) as a result of the search or null if no results are found
 */
export const fetchSearchResults = async ({
  searchTerm,
  retailerId,
  startWeek,
  endWeek
}: InvoiceSearchRequestParameters) => {
  const invoiceSearchRequest = buildInvoiceSearchRequest({ searchTerm, retailerId, startWeek, endWeek });
  const response = await axios.post(`/api/beacon/AdvancedSearch?_id=search`, invoiceSearchRequest);
  const resultingInvoiceNumbers = _get(response, ['data', 0, 'aggregations', 'by_originalInvoiceNumber'], []).map(
    (matchingDocument) => matchingDocument.fieldId
  );

  if (resultingInvoiceNumbers.length > 0) {
    return resultingInvoiceNumbers;
  } else {
    return null;
  }
};
