/**
 * This file takes Advanced Search responses (*Response types, see types.ts)
 * and converts them to *Data responses for direct consumption by
 * the frontend (see types.ts).
 */
import {
  ForecastsKeyMetricsUnadjustedResponse,
  ForecastsKeyMetricsData,
  ForecastsKeyMetricsAdjustedResponse,
  ForecastsKeyMetricsUnadjustedResponseAdditionalValues,
  ForecastsKeyMetricsAdjustedResponseAdditionalValues,
  WaterfallAdjustedAdditionalValues,
  ProductGridData,
  ProductSalesResponse,
  ProductTrafficResponse,
  ProductTrafficResponseAdditionalValues,
  ProductGridForecastData,
  AdjustedAdjustmentResponse,
  UnadjustedAdjustmentResponse,
  AdvancedSearchByRetailerIdResponse,
  WaterfallUnadjustedAdditionalValues,
  ProductSalesResponseAdditionalValues
} from './types';
import { calculatePercentChange, safeDivide } from 'src/utils/app';

export const UNADJUSTED_TO_ADJUSTED_MAP: Record<
  keyof ForecastsKeyMetricsAdjustedResponseAdditionalValues,
  keyof ForecastsKeyMetricsUnadjustedResponseAdditionalValues
> = {
  totalTrafficDelta_sum_value: 'totalTraffic_sum_value',
  organicTrafficDelta_sum_value: 'organicTraffic_sum_value',
  paidTrafficDelta_sum_value: 'paidTrafficValue_sum_value',
  otherTrafficDelta_sum_value: 'otherTraffic_sum_value',
  adSpendDelta_sum_value: 'adSpend_sum_value',
  adSalesDelta_sum_value: 'adSales_sum_value',
  unitsSoldDelta_sum_value: 'unitsSold_sum_value',
  retailSalesDelta_sum_value: 'retailSales_sum_value',
  weightedContentScoreByContentScoreCountDelta_sum_value: 'weightedContentScoreByContentScoreCount_sum_value',
  weightedContentScoreByUnitsDelta_sum_value: 'weightedContentScoreByUnits_sum_value',
  contentScoreCountDelta_sum_value: 'contentScoreCount_sum_value',
  weightedRatingByRatingCountDelta_sum_value: 'weightedRatingByRatingCount_sum_value',
  ratingCountDelta_sum_value: 'ratingCount_sum_value',
  weightedInStockRateByUnitsDelta_sum_value: 'weightedInStockRateByUnits_sum_value',
  weightedBuyBoxWinRateByUnitsDelta_sum_value: 'weightedBuyBoxWinRateByUnits_sum_value',
  wholesaleSalesDelta_sum_value: 'wholesaleSales_sum_value',
  retailerMarginDelta_sum_value: 'retailerMargin_sum_value',
  brandMarginDelta_sum_value: 'brandMargin_sum_value'
};

export const parseForecastsKeyMetricsReponse = (
  unadjustedResponse: ForecastsKeyMetricsUnadjustedResponse,
  adjustedResponse?: ForecastsKeyMetricsAdjustedResponse
): ForecastsKeyMetricsData => {
  // Maps a week ID to the adjusted delta values for that week.
  const adjustedDeltasByWeekId = adjustedResponse
    ? adjustedResponse.aggregations.by_forecastWeekId.reduce((acc, { additionalValues, fieldId }) => {
        return {
          ...acc,
          [fieldId]: Object.entries(additionalValues).reduce((additionalValsAcc, [key, value]) => {
            const unadjustedKey =
              UNADJUSTED_TO_ADJUSTED_MAP[key as keyof ForecastsKeyMetricsAdjustedResponseAdditionalValues];

            // We don't need all the values from the adjusted response, so we only
            // add the ones that have a corresponding unadjusted value.
            if (unadjustedKey) {
              return {
                ...additionalValsAcc,
                [unadjustedKey]: value
              };
            }
            return additionalValsAcc;
          }, {} as Record<keyof ForecastsKeyMetricsUnadjustedResponseAdditionalValues, number>)
        };
      }, {} as Record<string, ForecastsKeyMetricsAdjustedResponseAdditionalValues>)
    : {};

  /** Add all the adjusted values to the unadjusted values */
  const forecastedValues = unadjustedResponse.aggregations.by_forecastWeekId.map(
    ({ additionalValues, fieldId }) =>
      Object.entries(additionalValues).reduce(
        (acc, [key, value]) => {
          // If adjusted, add the adjusted delta
          return {
            ...acc,
            [key]:
              adjustedDeltasByWeekId[fieldId] && adjustedDeltasByWeekId[fieldId][key]
                ? value + adjustedDeltasByWeekId[fieldId][key]
                : value
          };
        },
        { fieldId }
      ) as ForecastsKeyMetricsUnadjustedResponseAdditionalValues & { fieldId: string }
  );

  return {
    data: forecastedValues.map((row) => ({
      ...row,
      weekId: row.fieldId,
      instockRate_computed_value: safeDivide(row.weightedInStockRateByUnits_sum_value, row.unitsSold_sum_value),
      brandGrossMarginPercent_computed_value: safeDivide(row.brandMargin_sum_value, row.wholesaleSales_sum_value),
      weightedRating_computed_value: safeDivide(row.weightedRatingByRatingCount_sum_value, row.ratingCount_sum_value),
      purchaseRate_computed_value: safeDivide(row.unitsSold_sum_value, row.totalTraffic_sum_value),
      contentScore_computed_value: safeDivide(row.weightedContentScoreByUnits_sum_value, row.unitsSold_sum_value),
      retailPrice_computed_value: safeDivide(row.retailSales_sum_value, row.unitsSold_sum_value),
      winPercentage_computed_value: safeDivide(row.weightedBuyBoxWinRateByUnits_sum_value, row.unitsSold_sum_value)
    }))
  };
};

const waterfallResponseToRetailerIdMap = <TAdditionalValues>(
  response: AdvancedSearchByRetailerIdResponse<TAdditionalValues>
): Record<string, TAdditionalValues> => {
  return response.aggregations.by_retailerId.reduce((acc, { additionalValues, fieldId }) => {
    return {
      ...acc,
      [fieldId]: additionalValues
    };
  }, {} as Record<string, TAdditionalValues>);
};

const WATERFALL_UNADJUSTED_TO_ADJUSTED_KEY_MAP: Record<
  keyof WaterfallUnadjustedAdditionalValues,
  keyof WaterfallAdjustedAdditionalValues
> = {
  actualTotalUnitsSoldChange_sum_value: 'actualTotalUnitsSoldChangeDelta_sum_value',
  buyBoxScaledContribution_sum_value: 'buyBoxScaledContributionDelta_sum_value',
  contentScoreScaledContribution_sum_value: 'contentScoreScaledContributionDelta_sum_value',
  currentUnitsSold_sum_value: 'currentUnitsSoldDelta_sum_value',
  inStockRateScaledContribution_sum_value: 'inStockRateScaledContributionDelta_sum_value',
  organicTrafficScaledContribution_sum_value: 'organicTrafficScaledContributionDelta_sum_value',
  otherTrafficScaledContribution_sum_value: 'otherTrafficScaledContributionDelta_sum_value',
  paidTrafficScaledContribution_sum_value: 'paidTrafficScaledContributionDelta_sum_value',
  priorUnitsSold_sum_value: 'priorUnitsSoldDelta_sum_value',
  ratingScaledContribution_sum_value: 'ratingScaledContributionDelta_sum_value',
  retailPriceScaledContribution_sum_value: 'retailPriceScaledContributionDelta_sum_value'
};

export const emptyWaterfallResponse: WaterfallUnadjustedAdditionalValues = {
  actualTotalUnitsSoldChange_sum_value: 0,
  buyBoxScaledContribution_sum_value: 0,
  contentScoreScaledContribution_sum_value: 0,
  currentUnitsSold_sum_value: 0,
  inStockRateScaledContribution_sum_value: 0,
  organicTrafficScaledContribution_sum_value: 0,
  otherTrafficScaledContribution_sum_value: 0,
  paidTrafficScaledContribution_sum_value: 0,
  priorUnitsSold_sum_value: 0,
  ratingScaledContribution_sum_value: 0,
  retailPriceScaledContribution_sum_value: 0
};

export const parseWaterfallResponse = (
  retailerId: string,
  actualWaterfallResponse: AdvancedSearchByRetailerIdResponse<WaterfallUnadjustedAdditionalValues>,
  unadjustedWaterfallResponse?: AdvancedSearchByRetailerIdResponse<WaterfallUnadjustedAdditionalValues>,
  adjustedWaterfallResponse?: AdvancedSearchByRetailerIdResponse<WaterfallAdjustedAdditionalValues>
): WaterfallUnadjustedAdditionalValues => {
  const emptyAdjustedResponse: WaterfallAdjustedAdditionalValues = {
    actualTotalUnitsSoldChangeDelta_sum_value: 0,
    buyBoxScaledContributionDelta_sum_value: 0,
    contentScoreScaledContributionDelta_sum_value: 0,
    currentUnitsSoldDelta_sum_value: 0,
    inStockRateScaledContributionDelta_sum_value: 0,
    organicTrafficScaledContributionDelta_sum_value: 0,
    otherTrafficScaledContributionDelta_sum_value: 0,
    paidTrafficScaledContributionDelta_sum_value: 0,
    priorUnitsSoldDelta_sum_value: 0,
    ratingScaledContributionDelta_sum_value: 0,
    retailPriceScaledContributionDelta_sum_value: 0
  };

  const actualWaterfallMap =
    waterfallResponseToRetailerIdMap(actualWaterfallResponse)[retailerId] || emptyWaterfallResponse;
  const unadjustedWaterfallMap = unadjustedWaterfallResponse
    ? waterfallResponseToRetailerIdMap(unadjustedWaterfallResponse)[retailerId] || emptyWaterfallResponse
    : emptyWaterfallResponse;
  const adjustedWaterfallMap = adjustedWaterfallResponse
    ? waterfallResponseToRetailerIdMap(adjustedWaterfallResponse)[retailerId] || emptyAdjustedResponse
    : emptyAdjustedResponse;

  const forecastedTotalValues = Object.entries(actualWaterfallMap).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value + unadjustedWaterfallMap[key] + adjustedWaterfallMap[WATERFALL_UNADJUSTED_TO_ADJUSTED_KEY_MAP[key]]
    };
  }, {} as WaterfallUnadjustedAdditionalValues);

  // For adjusted forecasts, we also need to add the total units sold delta
  if (adjustedWaterfallResponse) {
    forecastedTotalValues.currentUnitsSold_sum_value += adjustedWaterfallMap.actualTotalUnitsSoldChangeDelta_sum_value;
  }

  return forecastedTotalValues;
};

export const parseProductSalesResponse = ({
  salesResponse,
  trafficResponse,
  productGridForecastData,
  comparisonStartWeekId,
  comparisonEndWeekId,
  aggregateActuals
}: {
  salesResponse: ProductSalesResponse;
  trafficResponse: ProductTrafficResponse;
  productGridForecastData: ProductGridForecastData;
  comparisonStartWeekId: number;
  comparisonEndWeekId: number;
  aggregateActuals?: boolean;
}): ProductGridData => {
  // Map the stackline sku to the traffic values for
  // constant time lookup when parsing the sales response
  const skuToTrafficValuesMap = trafficResponse.aggregations.by_stacklineSku.reduce(
    (acc, { fieldId, additionalValues }) => {
      return {
        ...acc,
        [fieldId]: {
          ...additionalValues,
          totalClicks_sum_value: aggregateActuals ? additionalValues.totalClicks_sum_value : 0
        }
      };
    },
    {} as Record<string, ProductTrafficResponseAdditionalValues>
  );

  // Map the stackline sku to the sales values for
  // constant time lookup when parsing the sales response
  const skuToSalesValuesMap = salesResponse.aggregations.by_stacklineSku.reduce(
    (acc, { fieldId, additionalValues, additionalMetaData }) => {
      return {
        ...acc,
        [fieldId]: {
          ...additionalValues,
          retailSales_sum_value: aggregateActuals ? additionalValues.retailSales_sum_value : 0,
          unitsSold_sum_value: aggregateActuals ? additionalValues.unitsSold_sum_value : 0,
          product: additionalMetaData.product
        }
      };
    },
    {} as Record<string, ProductSalesResponseAdditionalValues>
  );

  return {
    data: Object.entries(skuToSalesValuesMap)
      .map(([stacklineSku, salesDatum]) => {
        const totalClicksComparisonKey = `totalClicks_sum_value_${comparisonStartWeekId}_${comparisonEndWeekId}`;
        const retailSalesComparisonKey = `retailSales_sum_value_${comparisonStartWeekId}_${comparisonEndWeekId}`;
        const unitsSoldComparisonKey = `unitsSold_sum_value_${comparisonStartWeekId}_${comparisonEndWeekId}`;

        const forecastDatum = productGridForecastData[stacklineSku] || {
          totalTraffic_sum_value: 0,
          retailSales_sum_value: 0,
          unitsSold_sum_value: 0,
          [unitsSoldComparisonKey]: 0,
          [retailSalesComparisonKey]: 0
        };

        const trafficData = skuToTrafficValuesMap[stacklineSku] || {
          totalClicks_sum_value: 0,
          [totalClicksComparisonKey]: 0
        };

        // Traffic with forecast data
        const totalTraffic: number = trafficData.totalClicks_sum_value + forecastDatum.totalTraffic_sum_value;
        const comparisonTotalTraffic: number = trafficData[totalClicksComparisonKey] || 0;

        // Retail sales with forecast data
        const totalRetailSales: number = salesDatum.retailSales_sum_value + forecastDatum.retailSales_sum_value;
        const comparisonRetailSales: number = salesDatum[retailSalesComparisonKey] || 0;

        // Units sold with forecast data
        const totalUnitsSold: number = salesDatum.unitsSold_sum_value + forecastDatum.unitsSold_sum_value;
        const comparisonUnitsSold: number = salesDatum[unitsSoldComparisonKey] || 0;

        const conversionRate = totalTraffic > 0 ? totalUnitsSold / totalTraffic : 0;
        const comparisonConversionRate = comparisonTotalTraffic > 0 ? comparisonUnitsSold / comparisonTotalTraffic : 0;

        return {
          stacklineSku,
          retailSales_sum_value: totalRetailSales,
          unitsSold_sum_value: totalUnitsSold,
          conversionRate,
          conversionRatePercentChange: calculatePercentChange(conversionRate, comparisonConversionRate),
          retailSalesPercentChange: calculatePercentChange(totalRetailSales, comparisonRetailSales),
          unitsSoldPercentChange: calculatePercentChange(totalUnitsSold, comparisonUnitsSold),
          productTitle: salesDatum.product.title,
          totalClicks_sum_value: totalTraffic,
          product: salesDatum.product,
          retailSalesChange: totalRetailSales - comparisonRetailSales
        };
      })
      .sort((a, b) => b.retailSales_sum_value - a.retailSales_sum_value) // Sort by retail sales
  };
};

interface OriginalForecastData {
  totalTraffic_sum_value: number;
  organicTraffic_sum_value: number;
  otherTraffic_sum_value: number;
  paidTrafficValue_sum_value: number;
  unitsSold_sum_value: number;
  retailPrice_avg_value: number;
  contentScore_avg_value: number;
  aplusScore_avg_value: number;
  bulletScore_avg_value: number;
  titleScore_avg_value: number;
  imageScore_avg_value: number;
  videoScore_avg_value: number;
  inStockRate_avg_value: number;
  rating_stats_avg: number;
  buyBoxWon_avg_value: number;
  retailSales_sum_value: number;
  winPercentage_computed_value: number;
  weightedRating: number;
  reviewCount: number;
}

/**
 *
 * Takes an aggregated unadjusted and adjusted dataset and combines them into forecasted data under typical adv. search keys.
 * @returns
 */
export const parseForecastResponseForAdjustmentSummary = ({
  unadjustedResponse,
  adjustedResponse
}: {
  unadjustedResponse: UnadjustedAdjustmentResponse;
  adjustedResponse?: AdjustedAdjustmentResponse | null;
}): OriginalForecastData => {
  const originalForecastData = {
    totalTraffic_sum_value: 0,
    organicTraffic_sum_value: 0,
    otherTraffic_sum_value: 0,
    paidTrafficValue_sum_value: 0,
    unitsSold_sum_value: 0,
    retailPrice_avg_value: 0,
    contentScore_avg_value: 0,
    aplusScore_avg_value: 0,
    bulletScore_avg_value: 0,
    titleScore_avg_value: 0,
    imageScore_avg_value: 0,
    videoScore_avg_value: 0,
    inStockRate_avg_value: 0,
    rating_stats_avg: 0,
    buyBoxWon_avg_value: 0,
    retailSales_sum_value: 0,
    winPercentage_computed_value: 0,
    weightedRating: 0,
    reviewCount: 0
  };

  const {
    organicTraffic_sum_value,
    otherTraffic_sum_value,
    paidTrafficValue_sum_value,
    retailSales_sum_value,
    unitsSold_sum_value,
    weightedInStockRateByUnits_sum_value,
    weightedBuyBoxWinRateByUnits_sum_value,
    weightedRatingByRatingCount_sum_value,
    ratingCount_sum_value,
    incrementalReviewsFinal_avg_value,
    aplusScore_avg_value,
    bulletScore_avg_value,
    titleScore_avg_value,
    imageScore_avg_value,
    videoScore_avg_value
  } = unadjustedResponse;
  const {
    organicTrafficDelta_sum_value,
    otherTrafficDelta_sum_value,
    paidTrafficDelta_sum_value,
    retailSalesDelta_sum_value,
    unitsSoldDelta_sum_value
  } = adjustedResponse || {};

  /**
   * Calculate traffic
   */
  originalForecastData.organicTraffic_sum_value += organicTraffic_sum_value + (organicTrafficDelta_sum_value || 0);
  originalForecastData.otherTraffic_sum_value += otherTraffic_sum_value + (otherTrafficDelta_sum_value || 0);
  originalForecastData.paidTrafficValue_sum_value += paidTrafficValue_sum_value + (paidTrafficDelta_sum_value || 0);
  /**
   * Calculate Retail Price as unadjusted retail sales + adjusted retails sales (divided by) unadjusted units sold + adjusted units sold
   */
  originalForecastData.retailPrice_avg_value +=
    (retailSales_sum_value + (retailSalesDelta_sum_value || 0)) / (unitsSold_sum_value || 1) +
    (unitsSoldDelta_sum_value || 0);
  /**
   * Get Content Score
   */
  originalForecastData.aplusScore_avg_value = aplusScore_avg_value;
  originalForecastData.bulletScore_avg_value = bulletScore_avg_value;
  originalForecastData.titleScore_avg_value = titleScore_avg_value;
  originalForecastData.videoScore_avg_value = videoScore_avg_value;
  originalForecastData.imageScore_avg_value = imageScore_avg_value;

  const allScores = [
    aplusScore_avg_value,
    bulletScore_avg_value,
    titleScore_avg_value,
    videoScore_avg_value,
    imageScore_avg_value
  ];

  originalForecastData.contentScore_avg_value = allScores.reduce((acc, score) => acc + score, 0) / allScores.length;

  /**
   * Get In-Stock Rate
   */
  originalForecastData.inStockRate_avg_value =
    unitsSold_sum_value > 0 ? weightedInStockRateByUnits_sum_value / unitsSold_sum_value : 0;

  /**
   * Get Buy Box
   */
  originalForecastData.winPercentage_computed_value =
    unitsSold_sum_value > 0 ? weightedBuyBoxWinRateByUnits_sum_value / unitsSold_sum_value : 0;

  /**
   * Get Weighted Rating
   */
  originalForecastData.weightedRating =
    ratingCount_sum_value > 0 ? weightedRatingByRatingCount_sum_value / ratingCount_sum_value : 0;

  /**
   * Get Review Count
   */
  originalForecastData.reviewCount = incrementalReviewsFinal_avg_value || 0;

  return originalForecastData;
};
