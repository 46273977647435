import axios from 'axios/index';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { AppName } from 'sl-api-connector/types';
import { AD_PLATFORM_SETTING_TYPE_BY_CLIENT } from 'sl-ad-campaign-manager-data-model';

import { store } from 'src/main';
import { getMainConditions } from 'src/utils/conditions';
import { decodeHash, encodeHash } from 'src/utils/hashIds';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import * as categoryOperations from 'src/store/modules/categories/operations';
import categoryTypes from 'src/store/modules/categories/types';
import * as subCategoryOperations from 'src/store/modules/subcategories/operations';
import subCategoryTypes from 'src/store/modules/subcategories/types';
import { propEq, timeout } from 'src/utils/fp';
import { error } from 'src/utils/mixpanel';
import Creators from './actions';
import { getAdPlatformSettingsByClientOfType } from '../adManager/adCampaignBuilder/selectors';
import { getParentPlatform, getTargetPageType } from 'src/utils/browser';
import { shouldShowCriteo } from 'src/utils/app';

export const { clearEntity, receiveEntity, requestEntity, removeEntity, updateSBAProfile } = Creators;

export const setEntity = (statePropertyName, entity) => (dispatch) => {
  entity.name = entity.name || entity.title || entity.companyName || '';
  entity.shortDisplayName = entity.name.length > 20 ? `${entity.name.substring(0, 20)}...` : entity.name;
  return dispatch(receiveEntity(statePropertyName, entity));
};

export const removeEntityWithKey = (statePropertyName) => (dispatch) => {
  return dispatch(removeEntity(statePropertyName));
};

export const updateSBAProfileReview = (sbaProfile) => {
  return store.dispatch(updateSBAProfile('mainEntity', sbaProfile));
};

export const fetchEntity = (statePropertyName, entityType, entityId, retailerId) => (dispatch, getState) => {
  const appName = getState().app.apiAppName;

  const userConfig = getState().user.config;
  if (Number.isNaN(entityId) && !['segment', 'searchtermlist', 'businessunit', 'product'].includes(entityType)) {
    entityId = decodeHash(entityId).toString();
  }
  dispatch(requestEntity());

  if (entityType === 'adCampaign' && appName === 'advertising') {
    return axios.get(`/apiAdManager/adCampaigns/${entityId}`).then((response) => {
      const { data: entity } = response;
      if (!(entity.error || entity.errors || !entity.campaignId)) {
        // Normalize the received campaign metadata to match what an entity should look like
        entity.id = entity.extendedAttributes.campaignId;
        entity.type = 'adCampaign';
        entity.displayName = entity.campaignName;
        dispatch(receiveEntity(statePropertyName, entity));
      }
    });
  }
  if (appName === AppName.Omni) {
    // Api will return default(universal) product info if we don't pass queryString params,
    // but in order to get specific product & retailer combo, we need to pass retailerId, and docType
    const { tab } = getState().app.queryParams;

    const decideDocType = (str) => {
      switch (str) {
        case 'content':
          return 'contentScore';
        case 'reviews':
          return 'review';

        default:
          return 'priceAndAvailability';
      }
    };
    const docType = decideDocType(tab);

    if (entityType === 'product') {
      let url = `omni/entityMetadata?entityType=${entityType}&entityId=${entityId}&docType=${docType}`;
      if (retailerId !== '0') {
        // in Omni default rId is 0 for universal.
        url += `&retailerId=${retailerId}`;
      }

      return axios.get(url).then((response) => {
        const { data: entity } = response.data;
        dispatch(receiveEntity(statePropertyName, entity));
      });
    }
    return axios
      .get(`/omni/entityMetadata?entityType=${entityType}&entityId=${entityId}`)
      .then((response) => {
        if (entityType === 'segment' || store === 'store') {
          const { data } = response;
          const { queryId } = data;
          if (queryId) {
            if (entityType === 'segment') {
              dispatch(receiveEntity(statePropertyName, { ...data, type: 'segment', id: queryId }));
            }
            if (entityType === 'store') {
              dispatch(receiveEntity(statePropertyName, { ...data, type: 'store', id: queryId }));
            }
          }
        } else {
          const { data: entity } = response.data;
          dispatch(receiveEntity(statePropertyName, entity));
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  }

  return axios
    .get(`/api/${appName}/GetEntityMetadata?entityType=${entityType}&entityId=${entityId}&retailerId=${retailerId}`)
    .then((response) => {
      const { data: entity } = response;
      entity.type = entityType;
      entity.id = `${entityId}`;
      entity.name = entity.name || entity.title || entity.companyName || '';
      if (userConfig.profile.email === 'syadav-gm@stackline.com') {
        entity.name = 'Demo Account';
      }
      entity.shortDisplayName = entity.name.length > 20 ? `${entity.name.substring(0, 20)}...` : entity.name;
      entity.hashId = encodeHash(entity.beaconClientId);
      dispatch(receiveEntity(statePropertyName, entity));
    });
};

export const createCompanyEntity = (statePropertyName) => (dispatch, getState) => {
  const { categories, retailer, user, app, brandsFollowing } = getState();

  let brandIds = [];

  if (app.name === AppName.Atlas) {
    brandIds = JSON.parse(user.config.BrandsOwned);
  } else if (brandsFollowing.length > 0) {
    brandIds = _get(brandsFollowing, ['0', 'conditions', 'termFilters', '0', 'values'], []);
  }

  const entity = {
    id: 0,
    hashId: encodeHash(0),
    type: 'company',
    name: user.config.vendor.CompanyName || 'Your Company',
    companyName: user.config.vendor.CompanyName || 'Your Company',
    displayName: user.config.vendor.CompanyName || 'Your Company',
    retailerIds: retailer.availableRetailers.map((x) => x.id),
    categoryIds: categories
      .filter((x) => x.categoryId > 0)
      .map((x) => x.categoryId)
      .sort((a, b) => a - b), // filter out 'all categories' with id '0'
    categoryIdsProductCount: {},
    brandIds
  };
  entity.shortDisplayName = entity.name.length > 20 ? `${entity.name.substring(0, 20)}...` : entity.name;
  return Promise.resolve(dispatch(receiveEntity(statePropertyName, entity)));
};

export const createAllCategoryEntity = (statePropertyName, entityType, entityId, overrides) => (dispatch, getState) => {
  const { categories, retailer } = getState();
  const name = 'All Categories';
  const entity = {
    type: entityType,
    id: `${entityId}`,
    name,
    retailerIds: retailer.availableRetailers.map((x) => x.id),
    categoryIds: categories.map((x) => x.categoryId).sort((a, b) => a - b),
    categoryIdsProductCount: {},
    shortDisplayName: name.length > 20 ? `${name.substring(0, 20)}...` : name,
    categoryName: name,
    ...(overrides || {})
  };
  return Promise.resolve(dispatch(receiveEntity(statePropertyName, entity)));
};

const getMainEntitySalesMetricsRequestOverrides = (
  mainEntityConditions,
  groupByFieldName,
  retailerId,
  mainTimePeriod
) => {
  const conditions = _cloneDeep(mainEntityConditions);
  conditions.termFilters = (conditions.termFilters || []).filter(({ fieldName }) => fieldName !== 'retailerId');

  return [
    {
      pageSize: 10000,
      conditions,
      aggregations: [
        {
          aggregationFields: [{ aggregateByFieldName: 'retailSales', function: 'sum' }],
          groupByFieldName,
          conditions: {
            termFilters: [
              {
                fieldName: 'retailerId',
                values: [retailerId]
              }
            ],
            rangeFilters: [
              {
                fieldName: 'weekId',
                minValue: mainTimePeriod.startWeek,
                maxValue: mainTimePeriod.endWeek
              }
            ]
          }
        }
      ]
    }
  ];
};

export const performCategorySortRequests = () => (dispatch, getState) => {
  const { mainEntityConditions } = getMainConditions(getState());
  const {
    entityService: { mainEntity },
    app,
    retailer,
    mainTimePeriod
  } = getState();
  const requestContext = { entity: mainEntity, retailer, app, indexName: 'sales' };

  // Fetch metrics by category
  const metricsByCategoryPromise = dispatch(
    entitySearchServiceOperations.fetchEntityMetrics(
      categoryTypes.SALES_BY_CATEGORY_STATE_KEY,
      requestContext,
      getMainEntitySalesMetricsRequestOverrides(mainEntityConditions, 'categoryId', retailer.id, mainTimePeriod)
    )
  );

  // Fetch metrics by subcategory
  const metricsBySubcategoryPromise = dispatch(
    entitySearchServiceOperations.fetchEntityMetrics(
      subCategoryTypes.SALES_BY_SUB_CATEGORY_STATE_KEY,
      requestContext,
      getMainEntitySalesMetricsRequestOverrides(mainEntityConditions, 'subCategoryId', retailer.id, mainTimePeriod)
    )
  );

  // If we've already received the response for our requests to fetch subscribed categories and subcategories,
  // we can sort them right away.  Othewise, wait until those are fetched and then do the sorting.
  return Promise.all([
    metricsByCategoryPromise.then(() => dispatch(categoryOperations.maybeSortCategories())),
    metricsBySubcategoryPromise.then(() => dispatch(subCategoryOperations.maybeSortSubCategories()))
  ]);
};

/**
 * Fetches the main entity and stores it in Redux.  At the same time, fetches sales data for all categories and
 * subcategories to which the current user is subscribed and uses them to sort the list of categories and subcategories
 * displayed in the filters on the left side of many application pages.
 *
 * @param {string} entityType
 * @param {any} entityId
 * @param {any} retailerId
 */
export const fetchMainEntity =
  (entityType, entityId, retailerId, push, sortCategories = true) =>
  async (dispatch, getState) => {
    // Use RDS data from the ad manager microservice for ad campaign metadata rather than ElasticSearch data.
    // ElasticSearch data is apparently out-of-date and unreliable.
    if (store.getState().app.name === AppName.Advertising) {
      const parentPlatform = shouldShowCriteo() ? getParentPlatform() : null;
      if (entityType === 'adGroup') {
        const bail = () => {
          error(`Error while fetching metadata for ad group id "${entityId}"`);
          push('/');
          return new Promise(); // Return promise that never resolves so we stay spinning until after the redirect
        };

        try {
          const { data: entity } = await axios.get(`/apiAdManager/adGroups/${entityId}`);
          if (entity.error || entity.errors || !entity.adGroupId) {
            return bail();
          }

          // Normalize the received adGroup metadata to match what an entity should look like
          entity.id = entity.extendedAttributes.adGroupId;
          entity.type = 'adGroup';
          entity.displayName = entity.extendedAttributes.adGroupName;

          dispatch(receiveEntity('mainEntity', entity));
          return entity;
        } catch (err) {
          return bail();
        }
      } else if (entityType === 'adCampaign') {
        const bail = () => {
          error(`Error while fetching metadata for ad campaign id "${entityId}"`);
          push('/');
          return new Promise(); // Return promise that never resolves so we stay spinning until after the redirect
        };

        try {
          const { data: entity } = await axios.get(`/apiAdManager/adCampaigns/${entityId}`);
          if (entity.error || entity.errors || !entity.campaignId) {
            return bail();
          }

          // Normalize the received campaign metadata to match what an entity should look like
          entity.id = entity.extendedAttributes.campaignId;
          entity.type = 'adCampaign';
          entity.displayName = entity.campaignName;

          dispatch(receiveEntity('mainEntity', entity));
          return entity;
        } catch (err) {
          return bail();
        }
      } else if (entityType === 'adPortfolio' && getState().app.name === AppName.Advertising) {
        const bail = () => {
          error(`Error while fetching metadata for ad portfolio id "${entityId}"`);
          push('/');
          return new Promise(); // Return promise that never resolves so we stay spinning until after the redirect
        };

        try {
          const { data: entity } = await axios.get(`/apiAdManager/adPlatformSettingsByClient/adPortfolio/${entityId}`);
          if (entity.error || entity.errors || !entity.settingId) {
            return bail();
          }

          dispatch(
            receiveEntity('mainEntity', {
              ...entity,
              type: 'adPortfolio',
              displayName: entity.extendedAttributes.name,
              id: entity.extendedAttributes.portfolioId
            })
          );

          return entity;
        } catch (err) {
          return bail();
        }
      } else if (entityType === 'adEntity') {
        let { adPlatformSettingsByClient } = getState();
        while (_isEmpty(adPlatformSettingsByClient)) {
          // eslint-disable-next-line no-await-in-loop
          await timeout(50);
          ({ adPlatformSettingsByClient } = getState());
        }

        const entity = getAdPlatformSettingsByClientOfType(AD_PLATFORM_SETTING_TYPE_BY_CLIENT.ENTITY_ID)(
          getState()
        ).find(propEq('id', entityId));
        if (!entity) {
          error(`No ad entity found matching id ${entityId}`);
          window.location.pathname = '/';
          window.location.search = '';
          // Return a promise that never fulfills in order to prevent crashes while the page refreshes
          return new Promise(() => {});
        }

        dispatch(
          receiveEntity('mainEntity', {
            ...entity,
            type: 'adEntity',
            displayName: entity.extendedAttributes.name,
            id: entity.extendedAttributes.entityId
          })
        );
        return entity;
      } else if (entityType === 'product') {
        const bail = () => {
          error(`Error while fetching metadata for ad campaign product "${entityId}"`);
          push('/');
          return new Promise(); // Return promise that never resolves so we stay spinning until after the redirect
        };

        try {
          const { data } = await axios.get(
            `/apiAdManager/adCampaignProducts/getAdCampaignProductsByRetailerSku?retailerId=${retailerId}&stacklineSku=${entityId}${
              parentPlatform ? `&parentPlatform=${parentPlatform}` : ''
            }`
          );
          if (!data || data.error || data.errors || data.length === 0) {
            return bail();
          }
          let { productMetaData } = data[0].extendedAttributes;
          // product metadata should be same in all objects
          // however at the time of this update, admanager sync service caused some
          // products with missing brand and subcategory details,
          // so we pick the metaData obejct with these details present
          const productMetaDataIndex = data.findIndex(
            (obj) =>
              _get(obj, 'extendedAttributes.productMetaData.categoryName', false) &&
              _get(obj, 'extendedAttributes.productMetaData.subCategoryName', false) &&
              _get(obj, 'extendedAttributes.productMetaData.brandName', false)
          );
          if (productMetaDataIndex !== -1) {
            // eslint-disable-next-line prefer-destructuring
            productMetaData = data[productMetaDataIndex].extendedAttributes.productMetaData;
          }
          const entity = {
            ...productMetaData
          };
          entity.type = 'product';
          entity.name = entity.title;
          entity.displayName = entity.title;
          entity.entityIds = [];
          entity.childEntities = data;
          data.forEach((adCampaignProduct) => {
            if (entity.entityIds.indexOf(adCampaignProduct.extendedAttributes.entityIdApi) < 0) {
              entity.entityIds.push(adCampaignProduct.extendedAttributes.entityIdApi);
            }
          });
          entity.campaignIds = data.map((adCampaignProduct) => {
            return adCampaignProduct.extendedAttributes.campaignIdApi || adCampaignProduct.campaignId;
          });

          if (entity.stacklineSku) {
            entity.id = entity.stacklineSku;
          } else if (!entity.id) {
            console.error('Product missing id -> showing total result instead of single product');
          }

          dispatch(receiveEntity('mainEntity', entity));
          return entity;
        } catch (err) {
          return bail();
        }
      } else if (entityType === 'adTarget') {
        const bail = () => {
          error(`Error while fetching metadata for ad target "${entityId}"`);
          push('/');
          return new Promise(); // Return promise that never resolves so we stay spinning until after the redirect
        };

        try {
          const pageType = getTargetPageType();
          const pageTypeOptionalField = pageType ? `&pageType=${pageType}` : '';
          const parentPlatformOptionalField = parentPlatform ? `&parentPlatform=${parentPlatform}` : '';
          const { data } = await axios.get(
            `/apiAdManager/adTargets/getAdTargetsByTargetingText?retailerId=${retailerId}&targetingText=${entityId}${parentPlatformOptionalField}${pageTypeOptionalField}`
          );

          if (!data || data.error || data.errors || data.length === 0) {
            return bail();
          }
          const entity = data[0];
          entity.id = entity.targetingText;
          entity.type = 'adTarget';
          entity.name = entity.targetingText;
          entity.displayName = entity.targetingText;
          entity.entityIds = [];
          entity.childEntities = data;
          data.forEach((adTarget) => {
            if (entity.entityIds.indexOf(adTarget.extendedAttributes.entityIdApi) < 0) {
              entity.entityIds.push(adTarget.extendedAttributes.entityIdApi);
            }
          });
          entity.campaignIds = [];
          data.forEach((adTarget) => {
            if (entity.campaignIds.indexOf(adTarget.extendedAttributes.campaignIdApi) < 0) {
              entity.campaignIds.push(adTarget.extendedAttributes.campaignIdApi || adTarget.campaignId);
            }
          });
          dispatch(receiveEntity('mainEntity', entity));
          return entity;
        } catch (err) {
          return bail();
        }
      }
    }

    if (store.getState().app.name === AppName.Omni) {
      // To Do: make calls to Omni Microservice here necessary to fetchMainEntity
      // Can use above Ad Manager logic as an example
    }

    await dispatch(fetchEntity('mainEntity', entityType, entityId, retailerId));

    if (![AppName.Atlas, AppName.Beacon].includes(getState().app.name)) {
      return null;
    }

    if (retailerId === '0') {
      return null;
    }
    // Once the main entity is fetched, kick off the request for sales data, scoping the query to the main entity.
    if (sortCategories) {
      return dispatch(performCategorySortRequests());
    }

    return null;
  };
