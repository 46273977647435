import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import { receiveAllOmniChartServiceData } from './actions';
import ReduxStore from 'src/types/store/reduxStore';
import { getWeekLastDate } from 'src/utils/dateformatting';
import { PERIODS } from '../constants';
import _get from 'lodash/get';

interface OmniChartsServiceDataRequestBody {
  retailerIds?: number[];
  includeBrandIds?: string[];
  includeCategoryIds?: number[];
  includeSubCategoryIds?: number[];
  startWeekId: number;
  endWeekId: number;
  groupBy?: string;
  fulfillmentType?: string;
  getEntityTableMetrics?: string[];
}

interface ElasticSearchAggBucket {
  key: number | string;
  docCount: number;
  data: {
    value: number;
  };
}

// TODO: change the endpoint for the placement and available
export const getOmniUrlEndpointByMetricFieldName = (metricFieldName: string) => {
  switch (metricFieldName) {
    case 'countOfPromotions':
    case 'totalCountOfPromotions':
    case 'countOfPromotionsSummaryTopEntities':
    case 'promotionsCountByRetailer':
    case 'countOfPromotionsShipping':
    case 'countOfPromotionsPickUp':
      return `/omni/retailPromotion/getRetailPromotionCount`;
    case 'storesWithPromotions':
      return `/omni/retailPromotion/getStoresWithPromotionCount`;
    case 'promotionsPrice':
      return `/omni/retailPromotion/getAvgPromotionPrice`;
    case 'priceViolationsCountSummaryTrend':
    case 'priceViolationsCountSummaryTopEntities':
    case 'priceViolationsCountShipping':
    case 'priceViolationsCountPickUp':
    case 'priceViolationsCount':
      return '/omni/priceViolation/getPriceViolationCount';
    case 'storesWithPriceViolation':
      return '/omni/priceViolation/getStoresWithPriceViolation';
    case 'retailProductCount':
      return '/omni/retailProduct/getRetailProductCount';
    case 'retailPrice':
    case 'avgRetailPrice':
    case 'deliveryRetailPrice':
    case 'shippingRetailPrice':
    case 'pickUpRetailPrice':
      return '/omni/retailPrice/getAverageRetailPrice';
    case 'inStockSummaryTrend':
    case 'inStockSummaryTopEntities':
    case 'deliveryInStockRate':
    case 'shippingInStockRate':
    case 'pickupInStockRate':
    case 'deliveryInStockRateByRetailer':
      return '/omni/inStock/getInStockRate';
    case 'storesInStock':
      return '/omni/inStock/getStoresInStock';
    case 'deliveryOutOfStockRate':
    case 'outOfStockRateSummaryTrend':
    case 'outOfStockRateSummaryTopEntities':
    case 'shippingOutOfStockRate':
    case 'pickupOutOfStockRate':
    case 'deliveryOutOfStockRateByRetailer':
      return '/omni/outOfStock/getOutOfStockRate';
    case 'deliveryStoresOutOfStock':
    case 'shippingStoresOutOfStock':
    case 'pickUpStoresOutOfStock':
    case 'avgStoresOutOfStock':
      return '/omni/outOfStock/getStoresOutOfStock';
    case 'placementRate':
      return '/omni/placement/getPlacementRate';
    case 'storesWithPlacement':
      return '/omni/placement/getStoresWithPlacement';
    case 'availabilityRate':
      return '/omni/availability/getAvailabilityRate';
    case 'availabilityTotalStoreCount':
      return '/omni/availability/getTotalStoreCount';
    case 'storesWithAvailability':
      return '/omni/availability/getStoresWithAvailability';
    case 'averageShippingTime':
    case 'averageDeliveryTime':
    case 'averagePickUpTime':
    case 'averageFulfillmentTime':
      return '/omni/fulfillment/getAverageFulfillmentTime';
    case 'totalShareOfShelf':
    case 'organicShareOfShelf':
    case 'paidShareOfShelf':
      return '/omni/shareOfShelf/getAverageShareOfShelf';
    case 'stars':
      return 'omni/reviews/getStarsRatingStats';
    case 'contentScore':
      return 'omni/content/getAvgContentScore';
    case 'contentAccuracy':
      return 'omni/content/getAvgContentAccuracy';
    default:
      return '';
  }
};

const formatTheData = async (
  groupBy: string,
  metricFieldName: string,
  chartName: string,
  isFetchingShareOfShelfByBrand: boolean,
  data: any
) => {
  const collectID: string[] = [];
  let formattedData = data[groupBy].buckets.map((bucket: ElasticSearchAggBucket) => {
    let aFormattedData = {
      name: `${bucket.key}`,
      [groupBy]: bucket.key,
      value: 0
    };
    if (metricFieldName === 'stars') {
      if (groupBy === 'retailerId') {
        const sum = _get(bucket, 'stars_sum.value', 0);
        const count = _get(bucket, 'stars_value_count.value', 0);
        aFormattedData.value = sum / count;
      } else {
        aFormattedData.value = _get(bucket, 'stars_stats.avg', 0);
      }
    } else if (metricFieldName === 'contentScore') {
      aFormattedData.value = _get(bucket, 'contentScore_avg.value', 0);
    } else if (metricFieldName === 'contentAccuracy') {
      aFormattedData.value = _get(bucket, 'contentAccuracy_avg.value', 0);
    } else {
      aFormattedData.value = isFetchingShareOfShelfByBrand
        ? bucket.shareOfShelf
          ? bucket.shareOfShelf.value
          : 0
        : bucket.data.value;
    }
    if (groupBy === 'keywordId' || groupBy === 'brandId') {
      collectID.push(bucket.key);
    }
    if (groupBy === 'weekId') {
      aFormattedData = {
        ...aFormattedData,
        weekEnding: getWeekLastDate(bucket.key),
        weekEndingNextYear: getWeekLastDate(bucket.key * 1 + 100)
      };
    }
    return aFormattedData;
  });

  // get the name
  if (groupBy === 'keywordId' || groupBy === 'brandId') {
    const reqBody = {
      entityType: 'keyword',
      entityIds: collectID
    };
    if (groupBy === 'brandId') {
      reqBody.entityType = 'brand';
    }
    const nameResponse = await axios.post('/omni/entityMetadata/bulk', reqBody);
    if (nameResponse && nameResponse.status === 200) {
      const { data: nameData } = nameResponse.data;
      const mapping = new Map();
      nameData.forEach((element) => {
        if (groupBy === 'keywordId') {
          mapping.set(`${element[groupBy]}`, element.searchTerm);
        }
        if (groupBy === 'brandId') {
          mapping.set(`${element[groupBy]}`, element.brandName);
        }
      });
      formattedData = formattedData.map((element) => {
        const correctName = mapping.get(element.name);
        element.name = correctName || element.name;
        return element;
      });
    }
  }

  if (groupBy === 'retailerId' && data.instacartRetailerId) {
    let filteredFormattedData = formattedData.filter((item: any) => item.retailerId !== 63);
    filteredFormattedData.sort((ele1, ele2) => {
      return Number(ele1.retailerId) < Number(ele2.retailerId) ? -1 : 1;
    });

    // special case: These two chart shouldn't filter out the retailer 63
    if (chartName === 'omniTileListRetailer' || chartName === 'omniTileListRetailerTotal') {
      filteredFormattedData = formattedData;
    }

    const instacartRetailerIdFormattedData = data.instacartRetailerId.buckets.map((bucket: ElasticSearchAggBucket) => ({
      name: `${bucket.key}`,
      [groupBy]: bucket.key,
      value: bucket.data.value
    }));

    formattedData = [...filteredFormattedData, ...instacartRetailerIdFormattedData];
  }

  // Average Fulfillment Time has an anti pattern, it will return everything in a single element.
  if (metricFieldName === 'averageFulfillmentTime' && groupBy === 'beaconClientId') {
    const bucket = data[groupBy].buckets[0];
    formattedData = [
      { name: 'Pickup', value: _get(bucket, 'pickUpTime.value', 0) },
      { name: 'Delivery', value: _get(bucket, 'deliveryTime.value', 0) },
      { name: 'Shipping', value: _get(bucket, 'shippingTime.value', 0) }
    ];
  }

  return formattedData;
};

export const fetchOmniChartsServiceData =
  (
    apiBody: OmniChartsServiceDataRequestBody,
    period: string,
    chartName: string,
    metricFieldName: string,
    comparisonPeriod: {
      compareStartWeek: number;
      compareEndWeek: number;
    }
  ) =>
  async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
    let urlEndpoint = getOmniUrlEndpointByMetricFieldName(metricFieldName);

    const groupBy = apiBody.groupBy ? apiBody.groupBy : 'weekId';

    // to get the brand share of shelf by brand in the Bar chart, we need to use below logic
    // the groupBy value is empty string
    const isFetchingShareOfShelfByBrand =
      ['totalShareOfShelf', 'organicShareOfShelf', 'paidShareOfShelf'].includes(metricFieldName) &&
      groupBy === 'brandId';

    if (isFetchingShareOfShelfByBrand) {
      apiBody.groupBy = '';
      urlEndpoint = '/omni/shareOfShelf/getShareOfShelfShards';
    }
    const response = await axios.post(urlEndpoint, apiBody);
    try {
      if (response && response.status === 200) {
        let { data } = response.data;

        data = isFetchingShareOfShelfByBrand ? data.ownedBrandsFilter : data;
        const formattedData = await formatTheData(
          groupBy,
          metricFieldName,
          chartName,
          isFetchingShareOfShelfByBrand,
          data
        );

        const entity = {
          chartName,
          main: formattedData,
          compare: []
        };

        if (period === PERIODS.COMPARE) {
          const { compareStartWeek, compareEndWeek } = comparisonPeriod;
          const compareApiBody = {
            ...apiBody,
            startWeekId: compareStartWeek,
            endWeekId: compareEndWeek
          };
          const compareResponse = await axios.post(urlEndpoint, compareApiBody);
          if (compareResponse && compareResponse.status === 200) {
            let { data: compareData } = compareResponse.data;
            compareData = isFetchingShareOfShelfByBrand ? compareData.ownedBrandsFilter : compareData;
            const formattedDataCompare = await formatTheData(
              groupBy,
              metricFieldName,
              chartName,
              isFetchingShareOfShelfByBrand,
              compareData
            );
            entity.compare = formattedDataCompare;
          }
        }

        dispatch(receiveAllOmniChartServiceData(entity));
      }
    } catch (e) {
      console.warn(e);
    }
  };

export const fetchSummaryForTopEntitiesGroupByOrderType =
  (fetchList, baseRequestBodyForMain, baseRequestBodyForCompare, chartName, metricFieldName) =>
  async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
    const urlEndpoint = getOmniUrlEndpointByMetricFieldName(metricFieldName);
    const [firstFetchList] = fetchList;

    const mainPromises = fetchList.map(async (ele) => {
      const requestBodyForMain = { ...baseRequestBodyForMain, fulfillmentType: ele.fulfillmentType };
      const responseForMain = await axios.post(urlEndpoint, requestBodyForMain);

      if (responseForMain && responseForMain.status === 200) {
        const { data: dataForMain } = responseForMain.data;
        if (
          firstFetchList.name === 'deliveryAvailability' ||
          firstFetchList.name === 'pickUpAvailability' ||
          firstFetchList.name === 'shippingAvailability'
        ) {
          const value = _get(dataForMain, 'all_docs.buckets.all.data.value', 1);
          return { name: ele.displayName, value };
        }
        if (!dataForMain.data) {
          return { name: ele.displayName, value: 1 };
        }
        return { name: ele.displayName, value: dataForMain.data.value };
      }
      return { name: ele.displayName, value: 0 };
    });

    const comparePromises = fetchList.map(async (ele) => {
      const requestBodyForCompare = { ...baseRequestBodyForCompare, fulfillmentType: ele.fulfillmentType };
      const responseForCompare = await axios.post(urlEndpoint, requestBodyForCompare);
      if (responseForCompare && responseForCompare.status === 200) {
        const { data: dataForCompare } = responseForCompare.data;
        if (
          firstFetchList.name === 'deliveryAvailability' ||
          firstFetchList.name === 'pickUpAvailability' ||
          firstFetchList.name === 'shippingAvailability'
        ) {
          const value = _get(dataForCompare, 'all_docs.buckets.all.data.value', 1);
          return { name: ele.displayName, value };
        }
        if (!dataForCompare.data) {
          return { name: ele.displayName, value: 1 };
        }
        return { name: ele.displayName, value: dataForCompare.data.value };
      }
      return { name: ele.displayName, value: 0 };
    });
    const main = await Promise.all(mainPromises);
    const entity = {
      chartName,
      main,
      compare: []
    };

    const compare = await Promise.all(comparePromises);
    entity.compare = compare;

    dispatch(receiveAllOmniChartServiceData(entity));
  };
