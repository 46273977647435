// This component houses the logic for specific pieces in the header.  It is connected to redux and passes
import _pick from 'lodash/pick';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { disableTranslation, enableTranslation } from 'src/store/modules/user/operations';
import ReduxStore from 'src/types/store/reduxStore';
import { Header } from '.';

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['app', 'user', 'navSidebar', 'retailer', 'mainTimePeriod']);

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    enableTranslation: async (reqBody: { [key: string]: string[] }) => dispatch(enableTranslation(reqBody)),
    disableTranslation: async (reqBody: { [key: string]: string[] }) => dispatch(disableTranslation(reqBody))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
