/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import numeral from 'numeral';
import DataColumn from 'src/components/AdManager/Search/CustomColumns/DataColumn';

import PortfolioColumn from 'src/components/AdManager/Search/CustomColumns/PortfolioColumn';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import {
  SectionWrapper,
  OperationButtons
} from 'src/components/AdCampaignBuilder/Widgets/AdCampaignBuilderCommonWidgets';
import ReduxStore from 'src/types/store/reduxStore';
import { basicDayFormat, getLastDayOfMonth } from 'src/utils/dateformatting';

const baseColDef = {
  disableSort: true,
  width: 100
};

export const BudgetColumn = (props: any) => {
  const budget = _get(props, ['data', 'entity', 'extendedAttributes', 'liveBudgetSetting'], null);

  if (budget) {
    const { amount, budgetType } = budget;
    const type = budgetType === 'monthlyRecurring' ? 'Monthly Recurring' : 'Date Range';
    if (budgetType === 'monthlyRecurring') {
      return (
        <div>
          <div>{numeral(amount).format('$1,000')}</div>
          <div>{type}</div>
          {budget.endDate ? <div>Ends {getLastDayOfMonth(budget.endDate)}</div> : <div>No end date</div>}
        </div>
      );
    }

    return (
      <div>
        <div>{numeral(amount).format('$1,000')}</div>
        <div>{type}</div>
        <div>
          {budget.startDate ? basicDayFormat(budget.startDate) : 'No Start Date'} -{' '}
          {budget.endDate ? basicDayFormat(budget.endDate) : 'No End Date'}
        </div>
      </div>
    );
  }

  return <div>Not Set</div>;
};

export const AmsBudgetColumn = (props: any) => {
  const budget = _get(props, ['data', 'entity', 'extendedAttributes', 'liveBudgetSetting', 'amount'], null);
  if (budget === null) {
    return 'Not Set';
  }
  return numeral(budget).format('$1,000');
};

export const StacklineBudgetColumn = (props: any) => {
  const budget = _get(props, ['data', 'entity', 'extendedAttributes', 'currentMonthBudgetSetting', 'amount'], null);
  if (budget === null) {
    return 'Not Set';
  }
  return numeral(budget).format('$1,000');
};

export const BudgetDifferentColumn = (props: any) => {
  const amsBudget = _get(props, ['data', 'entity', 'extendedAttributes', 'liveBudgetSetting', 'amount'], 0);
  const stacklineBudget = _get(
    props,
    ['data', 'entity', 'extendedAttributes', 'currentMonthBudgetSetting', 'amount'],
    0
  );
  const diff = stacklineBudget - amsBudget;

  return numeral(diff).format('$1,000');
};

const onGridReady = (params: any) => {
  params.api.sizeColumnsToFit();
};

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, ['app', 'retailer', 'adCampaigns', 'adBudgetEditor', 'adPortfolios']),
    mainEntity: state.entityService.mainEntity
  };
};

const PortfolioAlign: React.FC<{ classes: { [key: string]: any } } & ReturnType<typeof mapStateToProps>> = ({
  adBudgetEditor,
  adCampaigns
}) => {
  const columnDefs = [
    {
      headerName: 'Portfolio',
      field: 'portfolio',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 300,
      maxWidth: 800,
      cellRendererFramework: PortfolioColumn,
      headerClass: 'align-left'
    },
    {
      headerName: 'Ad Spend',
      cellRendererFramework: DataColumn,
      field: 'spend'
    },
    {
      headerName: 'Ad Sales',
      cellRendererFramework: DataColumn,
      field: 'sales'
    },
    {
      headerName: 'ROAS',
      cellRendererFramework: DataColumn,
      field: 'returnOnAdSpend'
    },
    {
      headerName: 'AMS Budget',
      cellRendererFramework: AmsBudgetColumn,
      field: 'portfolio'
    },
    {
      headerName: 'Stackline Budget',
      cellRendererFramework: StacklineBudgetColumn,
      field: 'portfolio'
    },
    {
      headerName: 'Difference',
      cellRendererFramework: BudgetDifferentColumn,
      field: 'portfolio'
    }
  ].map(({ headerName, ...colDef }) => ({
    ...baseColDef,
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' },
    ...colDef,
    displayName: headerName
  }));

  if (_isEmpty(adBudgetEditor.platform)) {
    return null;
  }

  const filterPortfolio = (adBudgetEditor.portfolioArray || []).filter((item) => {
    // filter out all the portfolio that belongs to the selected entity
    // and have more that one campaign
    return (
      item.entity.extendedAttributes.entityId === adBudgetEditor.entity.selectedEntityId &&
      !_isEmpty(adCampaigns.filter((campaign) => campaign.extendedAttributes.portfolioId === item.id)) &&
      _get(item.entity.extendedAttributes, ['currentMonthBudgetSetting', 'amount'], null) !==
        _get(item.entity.extendedAttributes, ['liveBudgetSetting', 'amount'], null)
    );
  });

  return (
    <div className="ad-manager-container">
      <OperationButtons subtab="portfolioAlign" canContinue customFlow={adBudgetEditor.flow} />
      <SectionWrapper
        header="Portfolio Budgets"
        subheader="The table below compares budgets from Amazon’s Ad Console to those in Stackline’s Ad Manager."
        layer={0}
      >
        <div className="platform-select">
          <CustomAgMaterial
            onGridReady={onGridReady}
            onCellValueChanged={onGridReady}
            onModelUpdated={onGridReady}
            onRowValueChanged={onGridReady}
            onRowDataChanged={onGridReady}
            buildRows={() => filterPortfolio}
            columnDefs={columnDefs}
            // deltaRowDataMode
            getRowNodeId={(datum: any) => escape(datum.id)}
            domLayout="autoHeight"
            rowHeight={120}
            containerStyle={{ width: '100%' }}
          />
        </div>
      </SectionWrapper>
    </div>
  );
};

const EnhancedPlatform = connect(mapStateToProps)(PortfolioAlign);

export default EnhancedPlatform;
