import React, { createContext } from 'react';
import { withRouter } from 'react-router';

import { History, Location } from 'history';

export const RouterPropsContext = createContext<{
  history: History;
  location: Location;
}>(null);

interface RouterPropsProviderProps {
  children: React.ReactNode;
  history: History;
  location: Location;
}

const RouterPropsProvider = ({ children, history, location }: RouterPropsProviderProps) => {
  return <RouterPropsContext.Provider value={{ history, location }}>{children}</RouterPropsContext.Provider>;
};

export default withRouter(RouterPropsProvider);
