export const SET_BUDGET_FLOW = 'SET_BUDGET_FLOW';
export const SET_BUDGET_PLATFORM = 'SET_BUDGET_PLATFORM';
export const SET_BUDGET_ENTITY = 'SET_BUDGET_ENTITY';
export const SET_BUDGET_ENTITY_ARRAY = 'SET_BUDGET_ENTITY_ARRAY';
export const SET_BUDGET_PORTFOLIO_ARRAY = 'SET_BUDGET_PORTFOLIO_ARRAY';
export const SET_BUDGET_CAMPAIGN_ARRAY = 'SET_BUDGET_CAMPAIGN_ARRAY';
export const SET_BUDGET_TIMELINE = 'SET_BUDGET_TIMELINE';
export const SET_BUDGET_AMOUNT = 'SET_BUDGET_AMOUNT';
export const SET_ADJUSTED_CAMPAIGNS = 'SET_ADJUSTED_CAMPAIGNS';
export const SET_MTD_AD_SPEND = 'SET_MTD_AD_SPEND';
export const RESET_BUDGETEDITOR = 'RESET_BUDGETEDITOR';
export const REPLACE_AD_BUDGET_EDITOR_STATE = 'REPLACE_AD_BUDGET_EDITOR_STATE';
