import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { CloseIcon } from 'src/components/SvgIcons/SvgIcons';
import colors from 'src/utils/colors';
import { withRouter } from 'react-router';
import queryString from 'qs';
import {
  DisputeStageSelection,
  DisputeStatusSelection,
  DisputeValueRangeFilter
} from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeModalFilters';
import { Filters } from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeManagementDashboard';
import _cloneDeep from 'lodash/cloneDeep';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '70%',
    overflow: 'auto',
    gap: '4em',
    padding: '4em',
    '&::-webkit-scrollbar': {
      width: '8px'
    },

    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(126, 143, 168, 0.8)',
        borderRadius: '15px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#eff1f500'
      }
    }
  }
}));

const CustomFilterDialogue = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '920px',
    height: '740px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff'
  }
});

const styles: any = {
  root: {
    color: colors.darkBlue,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '22',
    padding: '0 20px',
    height: '100px'
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
    height: '70%',
    overflow: 'scroll',
    gap: '4em',
    padding: '4em',
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#eff1f5',
        borderRadius: '15px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#eff1f500'
      }
    }
  },
  headTitle: {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#052849'
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  },

  filterTitle: {
    fontSize: '16px',
    fontWeight: 700
  },

  closeButton: {
    position: 'relative',
    alignSelf: '',
    width: '24px',
    margin: '0 39px',
    height: '24px',
    border: 'none',
    outline: 'none',
    background: 'none',
    padding: 0,
    cursor: 'pointer'
  },
  clearButton: {
    color: '#46a8f6',
    margin: '0 39px',
    fontSize: '18px',
    border: 'none',
    outline: 'none',
    background: 'none',
    width: 40,
    height: 40
  },
  clearButtonAct: {
    color: colors.blue,
    margin: '0 39px',
    fontSize: '18px',
    border: 'none',
    outline: 'none',
    background: 'none',
    width: 40,
    height: 40
  },
  headerBoarder: {
    width: '100%',
    height: '1px',
    backgroundColor: '#dedede',
    margin: 0
  },
  footerBoarder: {
    width: '100%',
    height: '1px',
    backgroundColor: '#dedede',
    marginBottom: 0
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '82.5px'
  },
  saveButton: {
    width: '158px',
    height: '31px',
    padding: '6px 64px',
    borderRadius: '15.5px',
    border: 'solid 1px #052849',
    backgroundColor: '#052849',
    color: '#fff'
  }
};

interface FilterModalProps {
  filters: Filters;
  handleFilterChange: any;
  open: boolean;
  onClose: () => void;
  location: any;
  history: any;
}

const FilterModal: React.FC<FilterModalProps> = ({ filters, handleFilterChange, open, onClose, location }) => {
  // We create a copy of our filter state to be distributed to all modal filters.
  const [filterState, setFilterState] = useState(_cloneDeep(filters));
  const classes = useStyles();

  // If we close the modal and reopen it we should refill the selection from parent state
  useEffect(() => {
    const copyOfFilterState = _cloneDeep(filters);
    setFilterState(copyOfFilterState);
  }, [filters, onClose]);

  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const [stageSelected, setStageSelected] = useState(null);

  const handleClear = () => {
    const copyOfFilters = _cloneDeep(filterState);
    Object.keys(copyOfFilters).forEach((key) => {
      copyOfFilters[key] = '';
    });
    setFilterState(copyOfFilters);
  };

  const updateFilterState = ({ newFilters }) => {
    setFilterState({
      ...filterState,
      ...newFilters
    });
  };

  const handleSaveFilters = () => {
    handleFilterChange(filterState);
    onClose();
  };

  const renderStatusFilters = useCallback(() => {
    return stageSelected ? (
      <div style={styles.filterContainer}>
        <span style={styles.filterTitle}>Dispute Status</span>
        <DisputeStatusSelection
          updateFilterState={updateFilterState}
          filters={filterState}
          stageSelected={stageSelected}
        />
      </div>
    ) : null;
  }, [stageSelected, filters, filterState]);

  return (
    <CustomFilterDialogue open={open} onClose={onClose}>
      <div style={styles.root}>
        <div style={styles.header}>
          <CloseIcon onClick={onClose} style={styles.closeButton} />
          <h1 style={styles.headTitle}>Filters</h1>
          <button style={!queryParams.filter ? styles.clearButton : styles.clearButtonAct} onClick={handleClear}>
            Clear
          </button>
        </div>
        <hr style={styles.headerBoarder}></hr>
        <div className={classes.contentContainer}>
          <div style={styles.filterContainer}>
            <span style={styles.filterTitle}>Shortage Value</span>
            <DisputeValueRangeFilter updateFilterState={updateFilterState} filters={filterState} />
            <div style={styles.headerBoarder}></div>
          </div>
          <div style={styles.filterContainer}>
            <span style={styles.filterTitle}>Dispute Stage</span>
            <DisputeStageSelection
              updateFilterState={updateFilterState}
              filters={filterState}
              setStageSelected={setStageSelected}
            />
            <div style={styles.headerBoarder}></div>
          </div>
          {renderStatusFilters()}
        </div>

        <hr style={styles.footerBoarder}></hr>
        <div style={styles.footer}>
          <button style={styles.saveButton} onClick={handleSaveFilters}>
            Save
          </button>
        </div>
      </div>
    </CustomFilterDialogue>
  );
};

export default withRouter(FilterModal);
