import React from 'react';

import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from 'src/components/SvgIcons';
import Checkbox from '@mui/material/Checkbox';
// import MenuItem from '@mui/material/MenuItem';

const targetGridOpt = [
  'Low In-Stock Rate',
  'Low Buy Box Rate',
  'Low Conversion Rate',
  'Low Brand Awareness',
  'Low Advertising Spend'
  //  'Dynamic Keyword'
];
const TargetGridCheckbox = () => {
  return (
    <div>
      <h2
        style={{
          marginTop: 36,
          fontWeight: 400,
          fontSize: 12
        }}
      >
        Dynamic Targeting
      </h2>
      {targetGridOpt.map((name, idx) => {
        return (
          <div key={idx}>
            <Checkbox
              style={{ transform: 'translateX(-9px)' }}
              checkedIcon={<CheckboxCheckedIcon height={24} width={24} />}
              icon={<CheckboxUncheckedIcon height={24} width={24} />}
              disableRipple
              color="primary"
            />
            {name}
          </div>
        );
      })}
    </div>
  );
};

export default TargetGridCheckbox;
