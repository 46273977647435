import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import _isNil from 'lodash/isNil';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import colors from 'src/utils/colors';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { withPush } from 'src/utils/hoc';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useDispatch } from 'react-redux';
import userOperations from 'src/store/modules/user/actions';
import { compose } from 'redux';
import { withBus } from 'react-bus';
import { isDrive } from 'src/utils/app';

const darkBlueColorBasedOnApp = isDrive ? 'rgba(126,143,168,.8)' : '#dedede';

const topIndicatorStyles = makeStyles(() => ({
  tabsIndicator: {
    top: 0,
    height: '1px',
    backgroundColor: colors.darkBlue
  },
  tabsRoot: {
    borderTop: `1px solid ${colors.lightestGrey}`
  },
  tabRoot: {
    fontSize: 16,
    color: darkBlueColorBasedOnApp,
    opacity: 1,
    textTransform: 'initial',
    minWidth: 72,
    padding: 0,
    paddingBottom: 4,
    minHeight: 20,
    marginRight: 40,
    maxWidth: 700,
    '&:hover': {
      color: colors.darkBlue,
      opacity: 1
    },
    '&$tabSelected': {
      color: colors.darkBlue
    },
    '&:focus': {
      color: colors.darkBlue
    }
  },
  tabSelected: {}
}));

const bottomIndicatorStyles = makeStyles(() => ({
  tabsIndicator: {
    height: 1,
    backgroundColor: colors.darkBlue
  },
  tabsRoot: {},
  tabRoot: {
    fontSize: 16,
    fontWeight: 'normal',
    color: darkBlueColorBasedOnApp,
    opacity: 1,
    textTransform: 'initial',
    minWidth: 72,
    padding: 0,
    paddingBottom: 14.5,
    minHeight: 20,
    marginRight: 40,
    maxWidth: 700,
    '&:hover': {
      color: colors.darkBlue,
      opacity: 1,
      fontWeight: 'bold'
    },
    '&$tabSelected': {
      color: colors.darkBlue,
      fontWeight: 'bold'
    },
    '&:focus': {
      color: colors.darkBlue,
      fontWeight: 'bold'
    }
  },
  tabSelected: {}
}));

const CustomizedTabs = ({
  tabs,
  onTabChange,
  style,
  tabStyle,
  tabRootStyle,
  defaultValue,
  value: valueOverride,
  useTopIndicator,
  actions,
  onActionClick,
  actionContainerStyle,
  actionStyle,
  linkCustomTab,
  push
}) => {
  const classes = useTopIndicator ? topIndicatorStyles() : bottomIndicatorStyles();
  const [value, setValue] = React.useState(defaultValue);
  const dispatch = useDispatch();

  const [menuState, setMenuState] = React.useState({
    open: false,
    anchorEl: null
  });

  const tabValue = _isNil(valueOverride) ? value : valueOverride;

  function handleChange(event, newValue) {
    setValue(newValue);
    onTabChange(event, newValue);
  }

  const handleButtonClick = (event) => {
    setMenuState({
      open: !menuState.open,
      anchorEl: event.currentTarget
    });
  };

  const menuItems = linkCustomTab
    ? [
        {
          text: 'Customize Columns',
          onClick: () => {
            dispatch(userOperations.setUserRedirectionUrl(window.location.href));
            push(`/account/custom${window.location.search}`);
          }
        }
      ]
    : [];

  return (
    <div className={classes.root} style={{ position: 'relative', display: 'flex', alignItems: 'center', ...style }}>
      <Tabs
        value={tabValue}
        onChange={(event, val) => handleChange(event, val)}
        style={tabRootStyle}
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      >
        {tabs.map((tab, idx) => (
          <Tab
            key={idx}
            className={tabValue === idx ? 'selected' : ''}
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected, wrapper: classes.wrapper }}
            style={typeof tabStyle === 'function' ? tabStyle(tab, idx, tabValue === idx) : tabStyle}
            label={tab.displayTemplate || tab.displayName}
          />
        ))}
      </Tabs>
      {actions && (
        <div style={actionContainerStyle}>
          {actions.map((action) => {
            const { icon: Icon } = action;
            return (
              <Tooltip key={`${action.name}`} title={`${action.toolTip} ${tabs[value].displayName}`} placement="top">
                <span
                  role="button"
                  onClick={() => {
                    onActionClick(action);
                  }}
                  style={actionStyle}
                >
                  <Icon />
                </span>
              </Tooltip>
            );
          })}
          {menuItems && menuItems.length > 0 && (
            <>
              <button onClick={handleButtonClick} style={{ border: 'none', outline: 'none', background: 'none' }}>
                <MoreVertIcon />
              </button>
              <Popper anchorEl={menuState.anchorEl} open={menuState.open} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper
                      id="menu-list-grow"
                      style={{
                        position: 'absolute',
                        right: -35,
                        zIndex: 900,
                        paddingRight: 0
                      }}
                    >
                      <ClickAwayListener onClickAway={() => setMenuState({ ...menuState, open: false })}>
                        <MenuList>
                          {menuItems.map((item, idx) => {
                            return (
                              <MenuItem key={idx} onClick={() => item.onClick()}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          )}
        </div>
      )}
    </div>
  );
};

CustomizedTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onTabChange: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  style: PropTypes.object,
  tabStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  tabRootStyle: PropTypes.object,
  defaultValue: PropTypes.number,
  value: PropTypes.any,
  useTopIndicator: PropTypes.bool,
  actions: PropTypes.array,
  onActionClick: PropTypes.func,
  actionContainerStyle: PropTypes.object,
  actionStyle: PropTypes.object,
  linkCustomTab: PropTypes.bool
};

CustomizedTabs.defaultProps = {
  style: undefined,
  tabStyle: undefined,
  tabRootStyle: undefined,
  defaultValue: 0,
  value: undefined,
  useTopIndicator: false,
  actionContainerStyle: { position: 'absolute', right: 10, zIndex: 2 },
  actionStyle: { cursor: 'pointer', marginLeft: 10 },
  actions: [],
  onActionClick: () => {},
  linkCustomTab: false
};

const EnhancedCustomizedTabs = compose(withBus('eventBus'), withPush)(CustomizedTabs);

export default EnhancedCustomizedTabs;
