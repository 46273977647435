import React, { FC, useEffect, useState } from 'react';
import EntityGridHeader from 'src/components/EntityGrid/Header/EntityGridHeader';
import TabsWidget from 'src/components/common/Tabs/TabsWidget';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import ReduxStore from 'src/types/store/reduxStore';
import { propEq } from 'src/utils/fp';
import _prop from 'lodash/property';
import { usePagination, useAsyncHook } from 'src/utils/Hooks';
import 'src/components/EntityGrid/Table/EntityTableContainer.scss';
import Masonry from 'react-masonry-component';
import ReviewTile from 'src/components/ReviewTile';
import Waypoint from 'react-waypoint';
import Loading from 'src/components/common/Loading';
import TileContainer from 'src/components/EntityGrid/Tiles/TileContainer';
import { reviewTileAsync } from 'src/components/Omni/OmniReviewTable/omniReviewTileUtil';
import { ListGrid } from 'src/components/Grids';
import { addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';
import _get from 'lodash/get';
import { destructurePathName } from 'src/utils/urlParsing';

const REPLY_STATUS_TABS = [
  { displayName: 'All', value: 'none' },
  { displayName: 'Replied', value: 'replied' },
  { displayName: 'Unreplied', value: 'unreplied' }
];

const CustomizeLoading = () => (
  <div className="spinner-wrapper">
    <Loading className="spinner__table--entity" />
  </div>
);

interface ReplyStatusTabsProps {
  value: string;
  onChange: (selectedTab: string) => void;
}

const ReplyStatusTabs: FC<ReplyStatusTabsProps> = ({ value, onChange }) => (
  <TabsWidget
    widget={{
      view: {
        tabs: REPLY_STATUS_TABS,
        tabStyle: { fontSize: 20, padding: 10, fontWeight: 400 },
        tabRootStyle: { border: 'none' },
        value: REPLY_STATUS_TABS.findIndex(propEq('value', value)),
        onChange
      }
    }}
  />
);

const formBaseRequestBody = (mainTimePeriod: ReduxStore['mainTimePeriod']) => {
  const { startWeek, endWeek } = mainTimePeriod;
  return {
    pageNumber: 1,
    pageSize: 20,
    reviewKeywords: [],
    startWeekId: startWeek,
    endWeekId: endWeek
  };
};

const formTileViewBaseRequestBody = (mainTimePeriod: ReduxStore['mainTimePeriod']) => {
  const { startWeek, endWeek } = mainTimePeriod;
  return {
    retailerIds: [],
    includeBrandIds: [],
    excludeBrandIds: [],
    excludeCategoryIds: [],
    startWeekId: startWeek,
    endWeekId: endWeek,
    productIds: [],
    includeKeywords: [],
    excludeKeywords: [],
    groupBy: 'gtin',
    reviewKeywords: []
  };
};

export interface OmniReviewTileDataState {
  cardView: { [key: string]: any };
  fieldId: string;
}

interface OmniReviewTableProps extends RouteComponentProps {
  reviewKeywordFilter?: string[];
  weekFilter?: undefined | number;
  starFilter: string[];
}

const OmniReviewTable: FC<OmniReviewTableProps> = ({ starFilter, reviewKeywordFilter, location, weekFilter }) => {
  const groupByFields = [
    {
      name: 'stars',
      displayName: 'Reviews',
      metricType: 'DECIMAL',
      aggregationFunction: 'stats',
      dataType: 'DECIMAL',
      overrides: {
        weekId_Override: {
          timePeriodAggregationFunctionType: 'trueAvg',
          timePeriodAggregationFunction: 'avg'
        }
      },
      appName: 'omni',
      indexName: 'reviews'
    },
    {
      name: 'stacklineSku',
      displayName: 'Product',
      displayNamePlural: 'Products',
      entity: {
        type: 'product'
      },
      dataType: 'INTEGER',
      metricType: 'INTEGER',
      aggregationFunction: 'cardinality',
      timePeriodAggregationFunctionType: 'lastValue',
      timePeriodAggregationFunction: 'sum',
      appName: 'omni',
      indexName: 'reviews'
    }
  ];
  const { pathname } = location;
  const [entityType] = destructurePathName(pathname);
  const [tabVal, setTanVal] = useState(REPLY_STATUS_TABS[0].value);
  const [selectedGroup, setSelectedGroup] = useState(groupByFields[0]);
  const { name: currentGroupName } = selectedGroup;
  const app = useSelector((state: ReduxStore) => state.app);
  const mainTimePeriod = useSelector((store: ReduxStore) => store.mainTimePeriod);
  const { mainEntity } = useSelector((state: ReduxStore) => state.entityService);
  const filters = useSelector((state: ReduxStore) => state.filters);
  const { startWeek, endWeek } = mainTimePeriod;
  const baseRequestBody = formBaseRequestBody(mainTimePeriod);
  const initialBaseWithFilter = addFilterToOmniBaseReqBody(baseRequestBody, filters, pathname, mainEntity.query);
  const baseRequestBodyTile = formTileViewBaseRequestBody(mainTimePeriod);
  const initialRequestWithFilter = addFilterToOmniBaseReqBody(baseRequestBodyTile, filters, pathname, mainEntity.query);
  const [pageNumber, pageSize, handleRequestChange, isLoading, dataList] = usePagination(
    '/omni/reviews/getReviews',
    initialBaseWithFilter,
    'data.data',
    '',
    '',
    (rawData) =>
      rawData.map((r) => {
        const gtin = _get(r, 'product.gtin', '');
        r.gtin = gtin;
        return { ...r };
      })
  );
  const perPageSize = 50;
  const [page, setPage] = useState(1);

  const { data: dataForTile, loading: tileLoading, run } = useAsyncHook<OmniReviewTileDataState[]>([]);

  useEffect(() => {
    if (currentGroupName === 'stars') {
      const addFilterRequest = addFilterToOmniBaseReqBody(baseRequestBody, filters, pathname, mainEntity.query);
      let baseOption: { [key: string]: any } = {
        ...addFilterRequest,
        startWeekId: weekFilter || startWeek,
        endWeekId: weekFilter || endWeek,
        hasReplied: null,
        starsFilter: starFilter,
        reviewKeywords: reviewKeywordFilter || []
      };

      if (tabVal === 'replied') {
        baseOption = { ...baseOption, hasReplied: true };
      } else if (tabVal === 'unreplied') {
        baseOption = { ...baseOption, hasReplied: false };
      }

      handleRequestChange(baseOption, { pageNumber: 1, pageSize: 20 });
    } else if (currentGroupName === 'stacklineSku') {
      const addFilterRequest = addFilterToOmniBaseReqBody(
        initialRequestWithFilter,
        filters,
        pathname,
        mainEntity.query
      );
      const groupDataBy = entityType === 'retailer' ? 'stacklineSku' : 'gtin';
      const newRequestBody = {
        ...addFilterRequest,
        starsFilter: weekFilter || starFilter,
        startWeekId: weekFilter || startWeek,
        endWeekId: endWeek,
        groupBy: groupDataBy,
        reviewKeywords: reviewKeywordFilter || []
      };
      setPage(1);
      run(reviewTileAsync(newRequestBody, groupDataBy));
    }
  }, [startWeek, endWeek, currentGroupName, tabVal, starFilter, reviewKeywordFilter, entityType, weekFilter]);

  const handleOnchange = (selectedValue: string) => {
    setTanVal(selectedValue);
  };

  const handleWaypointEntered = () => {
    if (isLoading || pageNumber === pageSize || pageSize < 20) {
      return;
    }
    handleRequestChange({}, { pageNumber: pageNumber + 1, pageSize });
  };
  const handleGroupChange = (
    evt: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const selectedValue = evt.target.value;
    let selectedGroupObj = groupByFields.find((g) => g.name === selectedValue);
    selectedGroupObj = selectedGroupObj || groupByFields[0];
    setSelectedGroup(selectedGroupObj);
  };

  const handleInfiniteScroll = () => {
    if (dataForTile && page * perPageSize < dataForTile.length) {
      setPage(page + 1);
    }
  };

  const dataToRender = dataForTile ? dataForTile.slice(0, page * perPageSize) : [];

  return (
    <div className="reviews-grid-container" style={{ marginBottom: 50 }}>
      <EntityGridHeader
        hideTitle={currentGroupName === 'stars'}
        title="Reviews"
        app={app}
        enableSwitchingLayouts={false}
        enableGroupBy
        groupByField={selectedGroup}
        groupByFields={groupByFields}
        handleGroupByChange={handleGroupChange}
        style={{ marginTop: 70 }}
      >
        {currentGroupName === 'stars' && <ReplyStatusTabs value={tabVal} onChange={handleOnchange} />}
      </EntityGridHeader>
      {currentGroupName === 'stars' ? (
        <>
          <Masonry
            options={{
              itemSelector: '.reviews__grid-item',
              columnWidth: '.reviews__grid-sizer',
              percentPosition: true
            }}
          >
            {dataList.filter(_prop('product')).map((review, idx) => (
              <div key={`${review.threadId}-${review.timestamp}-${idx}`}>
                <div className="reviews__grid-sizer" />
                <div className="reviews__grid-item">
                  <ReviewTile review={review} />
                </div>
              </div>
            ))}
          </Masonry>
          <Waypoint onEnter={handleWaypointEntered} />
          <br />
          <br />
          {isLoading && <CustomizeLoading />}
        </>
      ) : null}

      {currentGroupName === 'stacklineSku' ? (
        <>
          {tileLoading ? (
            <CustomizeLoading />
          ) : (
            <ListGrid className="entityGrid-list-grid" onWaypointEntered={handleInfiniteScroll}>
              {dataToRender.map((item) => (
                <TileContainer
                  key={item.fieldId}
                  dataItem={item}
                  showStatusBar={false}
                  isFlippable={false}
                  isPromotionsPage
                />
              ))}
            </ListGrid>
          )}
        </>
      ) : null}
    </div>
  );
};

export default withRouter(OmniReviewTable);
