/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Option } from 'funfix-core';
import _uniqBy from 'lodash/uniqBy';
import { BusinessUnit, SavedSearch } from 'sl-api-connector/types/savedSearch';

import TextInput from 'src/components/common/Form/TextInput';
import Select from 'src/components/common/Form/Select';
import GenericDialog from 'src/components/common/Dialog/GenericDialog';
import GenericFormInner from 'src/components/Forms/GenericForm';
import { store } from 'src/main';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import CheckBoxGroup from 'src/components/common/Form/CheckBoxGroup';
import { updateSavedSearch } from 'src/store/modules/segments/operations';
import CheckBoxInput from 'src/components/common/Form/CheckBox';
import InfoTooltip from 'src/components/AdCampaignBuilder/Widgets/InfoTooltip';
import { deleteForm } from 'src/store/modules/genericForm/actions';
import colors from 'src/utils/colors';
import { filterNils, prop } from 'src/utils/fp';
import { getUnassignedBusinessUnits } from 'src/store/modules/segments/selectors';
import ReduxStore from 'src/types/store/reduxStore';
import { panic } from 'src/utils/mixpanel';
import { AppName } from 'sl-api-connector';

export type GenericFormType<S = { [name: string]: any }> = React.ComponentType<{
  name: string;
  fieldDefinitions: { [fieldName: string]: any };
  defaultFieldDefinition?: { [key: string]: any };
  defaultFieldDefinitionOverride?: { [key: string]: any };
  initialState: S;
  onSubmit: (formState: S) => void | Promise<void>;
  formComponentProps?: { [key: string]: any };
  FormComponent: React.ComponentType<{ Field: React.ComponentType<{ name: string }> } & any>;
}>;

// React's `propTypes`-based type inference breaks down when we have Redux props and other props added via HOCs, so we
// manually specify the prop types for `GenericForm`.
//
// TODO: Convert `GenericForm` into TypeScript to properly solve this problem.
const GenericForm = GenericFormInner as any as GenericFormType;

const styles: { [key: string]: React.CSSProperties } = {
  formField: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40
  },
  formFieldHeader: {
    fontSize: 18
  },
  formFieldDescriptionText: {
    fontSize: 14,
    paddingTop: 4
  },
  formRoot: {
    paddingLeft: 28,
    paddingRight: 28
  },
  selectWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  select: {
    width: 300,
    marginLeft: 0
  },
  button: {
    width: 99
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '28px 26px 24px',
    borderBottom: `1px solid ${colors.lightestGrey}`,
    paddingBottom: 8
  },
  title: {
    fontSize: 24,
    marginBottom: 2
  },
  inputComponentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  }
};

export const FormField: React.FC<{ headerText: string; descriptionText?: string; infoText?: string }> = ({
  headerText,
  descriptionText,
  infoText,
  children
}) => (
  <div style={styles.formField}>
    <span style={styles.formFieldHeader}>{headerText}</span>
    {descriptionText ? <span style={styles.formFieldDescriptionText}>{descriptionText}</span> : null}

    <div style={styles.inputComponentWrapper}>
      {children}
      {infoText ? <InfoTooltip style={{ marginLeft: 20 }}>{infoText}</InfoTooltip> : null}
    </div>
  </div>
);

const mapParentBUPickerStateToProps = ({ segments }: ReduxStore) => ({ businessUnits: segments.businessUnits });

const ParentBUPickerInner: React.FC<
  { value: string; onChange: (newValue: string) => void } & ReturnType<typeof mapParentBUPickerStateToProps>
> = ({ value, onChange, businessUnits }) => (
  <div style={styles.selectWrapper}>
    <Select
      items={[{ displayName: 'None', id: '0' }, ...businessUnits].map(({ displayName, id }) => ({
        displayName,
        id,
        value: id
      }))}
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        }
      }}
      style={styles.select}
      value={value}
      displayEmpty
      onChange={(evt: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
        onChange(evt.target.value as string)
      }
    />
  </div>
);

const ParentBUPicker = connect(mapParentBUPickerStateToProps)(ParentBUPickerInner);

const defaultFieldDefinition = {
  WrapperComponent: FormField,
  required: true
};

const buildFieldDefinitions = (segments: ReduxStore['segments'], editingBUIdOpt?: string | null, appName: string) => {
  const orphanBUIds = getUnassignedBusinessUnits(segments);

  const allChildrenItems = Option.of(segments.savedSearchesById)
    .map((savedSearchesById) => [
      // If we're editing an existing BU, we want to include all of its current children in this list
      ...Option.of(editingBUIdOpt)
        .map((editingBUId) => {
          const editingBU = savedSearchesById.get(editingBUId);
          if (!editingBU) {
            return panic(`Trying to edit non-existent BU with id "${editingBUId}"`);
          } else if (editingBU.type !== 'businessunit') {
            return panic(`Trying to edit non-businessunit saved search of type "${editingBU.type}"`);
          }

          const childrenOfEditingBU = filterNils(editingBU.children.map((id) => savedSearchesById.get(id)));
          return childrenOfEditingBU.map(({ displayName, id }) => ({ displayName, value: id }));
        })
        .getOrElse([] as { value: string; displayName: string }[]),
      ...orphanBUIds.map((buId) => ({
        value: buId,
        // `orphanBUIds` is guarenteed to be a subset of `savedSearchesById` so we can avoid a null check here
        displayName: savedSearchesById.get(buId)!.displayName
      })),
      ...segments.mySegments.map(({ id, displayName }) => ({ value: id, displayName })),
      ...segments.teamSegments.map(({ id, displayName }) => ({ value: id, displayName }))
    ])
    .getOrElse([] as { value: string; displayName: string }[]);
  const childrenItems = _uniqBy(allChildrenItems, prop('value'));

  return {
    name: {
      InputComponent: TextInput,
      wrapperProps: {
        headerText: 'Business Unit Name',
        placeholder: 'Enter Business Unit Name'
      }
    },
    parentBusinessUnit: {
      InputComponent: ParentBUPicker,
      wrapperProps: {
        headerText: 'Parent Business Unit'
      },
      required: false
    },
    isTopLevel: {
      required: false,
      InputComponent: CheckBoxInput,
      wrapperProps: {
        headerText: 'Set as Top Level',
        descriptionText:
          "Make this business unit's children accessible as a top-level filter box in the website's left bar",
        infoText: (
          <>
            Top level BUs are visible by default as a list of their children on <br />
            the website&apos;s left bar
          </>
        )
      },
      wrapperStyle: {
        marginTop: 4,
        marginLeft: -4
      },
      checkboxStyle: {
        paddingBottom: 4,
        paddingLeft: 4
      },
      label: 'Top Level'
    },
    children: {
      required: false,
      InputComponent: CheckBoxGroup,
      wrapperProps: {
        headerText: 'Assign Segments & Folders'
      },
      items: childrenItems,
      style: {
        height: appName === AppName.Advertising ? 400 : 250,
        width: '100%',
        overflowY: 'scroll'
      }
    }
  };
};

const defaultFieldDefinitionOverride = {};

export interface FormState {
  name: string;
  parentBusinessUnit: string;
  children: string[];
  isTopLevel: boolean;
  // This field isn't actually controlled by the form.  It is set explicity using a Redux action when initializing the
  // BU Builder form for editing an existing BU.
  id?: string;
}

const initialState: FormState = {
  name: '',
  parentBusinessUnit: '0',
  children: [],
  isTopLevel: false
};

const BUBuilderForm: React.FC<{
  Field: React.ComponentType<{ name: string }>;
  onSubmit: () => void;
  onClose: () => void;
}> = ({ Field, onSubmit, onClose }) => (
  <>
    <Title onSubmit={onSubmit} onClose={onClose} />

    <div style={styles.formRoot}>
      <Field name="name" />
      <Field name="isTopLevel" />
      <Field name="parentBusinessUnit" />
      <Field name="children" />
    </div>
  </>
);

const AdBUBuilderForm: React.FC<{
  Field: React.ComponentType<{ name: string }>;
  onSubmit: () => void;
  onClose: () => void;
}> = ({ Field, onSubmit, onClose }) => (
  <>
    <Title onSubmit={onSubmit} onClose={onClose} />

    <div style={styles.formRoot}>
      <Field name="name" />
      <Field name="children" />
    </div>
  </>
);

const Title: React.FC<{ onSubmit: () => void; onClose: () => void }> = ({ onSubmit, onClose }) => (
  <div style={styles.header}>
    <h1 style={styles.title}>New Business Unit</h1>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <LargeMuiButton
        secondary
        style={{ ...styles.button, marginRight: 14 }}
        label="Close"
        onClick={() => {
          onClose();
          store.dispatch(deleteForm('businessUnitBuilder'));
        }}
      />
      <LargeMuiButton style={styles.button} label="SAVE" onClick={onSubmit} />
    </div>
  </div>
);

const mapStateToProps = ({ segments, app }: ReduxStore) => ({ segments, appName: app.name });

const findBusinessUnitsWithChild = (
  savedSearchMap: Map<string, SavedSearch>,
  savedSearchId: string
): BusinessUnit[] => {
  const result: BusinessUnit[] = [];
  savedSearchMap.forEach((savedSearch) => {
    // Check if savedSearch is a BusinessUnit and if its children contain the savedSearchId
    if (savedSearch.type === 'businessunit' && savedSearch.children.includes(savedSearchId)) {
      result.push(savedSearch as BusinessUnit);
    }
  });

  return result;
};

const deleteBusinessUnitFromParent = async (
  savedSearchMap: Map<string, SavedSearch>,
  selectedBusinessUnitId: string
): Promise<void> => {
  const parentBUsWithChild = findBusinessUnitsWithChild(savedSearchMap, selectedBusinessUnitId);
  if (parentBUsWithChild.length > 0) {
    const promises = parentBUsWithChild.map(({ id, userId, children, displayName, isTopLevel }) =>
      store.dispatch(
        updateSavedSearch(
          'businessunit',
          {
            children: children.filter((childId) => childId !== selectedBusinessUnitId),
            dn: displayName,
            isTopLevel
          },
          id,
          userId
        )
      )
    );
    await Promise.all(promises);
  }
};

const mkHandleSubmit =
  (appName: string, savedSearchesById: NonNullable<ReduxStore['segments']['savedSearchesById']>, onClose: () => void) =>
  async (formState: FormState) => {
    const parentBUSupplied = !(formState.parentBusinessUnit === '0');

    if (formState.children.includes(formState.parentBusinessUnit)) {
      // eslint-disable-next-line no-alert
      alert('You cannot set the same business unit as both a parent and child');
      return;
    } else if (formState.children.find((id) => !id)) {
      console.error('`null`/`undefined` elements in the `children` array.');
      formState.children = filterNils(formState.children);
    }

    if (formState.isTopLevel && formState.parentBusinessUnit !== '0') {
      // eslint-disable-next-line no-alert
      alert('You cannot set a BU as top-level and also specify a parent');
      return;
    }

    const lowerCaseDisplayName = formState.name.toLowerCase();
    const hasNameCollision = !!Array.from(savedSearchesById.values()).find(
      (savedSearch) => savedSearch.id !== formState.id && savedSearch.displayName.toLowerCase() === lowerCaseDisplayName
    );
    if (hasNameCollision) {
      // eslint-disable-next-line no-alert
      alert('You must pick a unique name for this BU; a segment/BU with the same name already exists.');
      return;
    }

    // Create or update the BU on the backend and wait for it to be created
    const res = await store.dispatch(
      updateSavedSearch(
        'businessunit',
        {
          children: formState.children,
          dn: formState.name,
          isTopLevel: formState.isTopLevel
        },
        formState.id
      )
    );

    const createdOrUpdatedBUId = res.data;
    if (!createdOrUpdatedBUId) {
      console.error('No saved search ID returned when creating/updating BU');
      return;
    }

    if (parentBUSupplied) {
      const parentBU = savedSearchesById.get(formState.parentBusinessUnit)! as BusinessUnit;
      if (parentBU && !parentBU.children.includes(createdOrUpdatedBUId)) {
        await deleteBusinessUnitFromParent(savedSearchesById, createdOrUpdatedBUId);
        store.dispatch(
          updateSavedSearch(
            'businessunit',
            {
              children: [...parentBU.children, createdOrUpdatedBUId],
              dn: parentBU.displayName,
              isTopLevel: parentBU.isTopLevel
            },
            parentBU.id,
            parentBU.userId,
            true
          )
        );
      }
    } else {
      await deleteBusinessUnitFromParent(savedSearchesById, createdOrUpdatedBUId);
    }

    onClose();
    store.dispatch(deleteForm('businessUnitBuilder'));
  };

const BusinessUnitBuilderDialog: React.FC<
  { open: boolean; onClose: () => void; editingBUId?: string | null } & ReturnType<typeof mapStateToProps>
> = ({ open, onClose, editingBUId, segments, appName }) => {
  const fieldDefinitions = useMemo(
    () => buildFieldDefinitions(segments, editingBUId, appName),
    [segments, editingBUId, appName]
  );
  const handleSubmit = useCallback(
    Option.of(segments.savedSearchesById)
      .map((savedSearchesById) => mkHandleSubmit(appName, savedSearchesById, onClose))
      .getOrElse(() => console.warn('Tried to submit BU creation form before saved search data loaded')),
    [appName, segments.savedSearchesById]
  );

  const BUForm = appName === AppName.Advertising ? AdBUBuilderForm : BUBuilderForm;
  if (appName === AppName.Advertising) {
    initialState.isTopLevel = true;
  } else {
    initialState.isTopLevel = false;
  }
  return (
    <GenericDialog title={null} open={open} onClose={onClose} showCloseButton={false}>
      <GenericForm
        name="businessUnitBuilder"
        fieldDefinitions={fieldDefinitions}
        defaultFieldDefinition={defaultFieldDefinition}
        defaultFieldDefinitionOverride={defaultFieldDefinitionOverride}
        initialState={initialState}
        onSubmit={handleSubmit as any}
        formComponentProps={{ onClose }}
        FormComponent={BUForm}
      />
    </GenericDialog>
  );
};

const EnhancedBusinessUnitBuilderDialog = connect(mapStateToProps)(BusinessUnitBuilderDialog);

export default EnhancedBusinessUnitBuilderDialog;
