// This is the Top level container that contains a Summary Tile Search Bar and all Summary Tile items.

// TODO: We're sorting things in `render` which is objectively bad.
//
// This could have a microservice written for it that gives it pre-sorted data of the format we need, avoiding the two
// API requests.

import React from 'react';
import { connect } from 'react-redux';
import Waypoint from 'react-waypoint';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _orderBy from 'lodash/orderBy';
import _keyBy from 'lodash/keyBy';
import _startCase from 'lodash/startCase';
import queryString from 'qs';
import { Option } from 'funfix-core';
import { Entity, AppName, AdManagerAdCampaignEntity, ElasticSearchAdCampaignEntity } from 'sl-api-connector/types';
import axios, { CancelTokenSource } from 'axios';

import { anyNotEq } from 'src/utils/equality';
import SummaryTile from 'src/components/SummaryTile/SummaryTile';
import SummaryTileSearchbar from 'src/components/SummaryTile/SummaryTileSearchbar';
import Widget from 'src/components/EntityPage/Widget';
import { propEq, prop } from 'src/utils/fp';
import './SummaryTile.scss';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import ReduxStore from 'src/types/store/reduxStore';
import { ArrayElementOf } from 'src/types/utils';
import { WidgetProps } from 'src/types/application/widgetTypes';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { store } from 'src/main';
import { buildAggregations, buildTimePeriodRangeFilters } from 'src/components/AdManager/Search';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { error, panic } from 'src/utils/mixpanel';
import { buildEntityLink } from 'src/components/AdManager/AmsUtils';
import { NoResultWithCustomButton } from 'src/components/Grids/Data/NoResultTemplate';

const { CancelToken } = axios;

const pageSize = 3;

const entityLevelByEntityType: { [entityType: string]: number } = {
  adEntity: 0,
  entity: 0,
  entityId: 0,
  adPortfolio: 1,
  portfolio: 1,
  portfolioId: 1,
  adCampaign: 2,
  camapaign: 2,
  campaignId: 2,
  stacklineSku: 3,
  searchKeyword: 3,
  campaignType: 3,
  category: 3,
  categoryId: 3,
  subCategory: 3,
  subCategoryId: 3,
  brandId: 3,
  brand: 3
};

const entityGroupByFieldNameByLevel = ['entityId', 'portfolioId', 'campaignId', 'stacklineSku'];

/**
 * Given the tab that a user has selected on the top of the entity summary page and the type of entity that is
 * displayed in the cards, returns the group-by field to be used for the top-5 grid that's embedded in each one of the
 * cards.
 *
 * If the selected entity is at a greater or equal level in the entity hierarchy to the card entity, the next entity
 * level down is used as an override.  Otherwise, the selected entity from the tab is used directly.
 */
const getGroupByFieldForCardEntity = (
  appName: string,
  indexName: string,
  cardEntity: Entity,
  selectedEntityName: string
) => {
  let groupByFieldName: string;
  const cardEntityLevel = entityLevelByEntityType[cardEntity.type] || 0;
  if (_isNil(entityLevelByEntityType[cardEntity.type])) {
    error(`Unhandled card entity type: ${cardEntity.type}`);
  }
  const selectedEntityLevel = entityLevelByEntityType[selectedEntityName] || 0;
  if (_isNil(entityLevelByEntityType[selectedEntityName])) {
    error(`Unhandled selected entity type: ${selectedEntityName}`);
  }

  if (selectedEntityLevel <= cardEntityLevel) {
    groupByFieldName = entityGroupByFieldNameByLevel[cardEntityLevel + 1];
  } else {
    groupByFieldName = selectedEntityName;
  }

  return INDEX_FIELDS.getField(appName, indexName, groupByFieldName);
};

const formatCampaign = (
  adCampaigns: ReduxStore['adCampaigns'] | NonNullable<ReduxStore['user']['adCampaigns']>,
  adPortfolios: ReduxStore['adPortfolios'],
  adEntities: ReduxStore['adEntities']
) =>
  (adCampaigns as (AdManagerAdCampaignEntity | ElasticSearchAdCampaignEntity)[]).map((campaign) => {
    const ParentEntity = adEntities.find(propEq('id', _get(campaign, ['extendedAttributes', 'entityId'])));
    const parentPortfolio = adPortfolios.find(propEq('id', _get(campaign, ['extendedAttributes', 'portfolioId'])));
    const entityName = Option.of(ParentEntity).map(prop('name')).getOrElse('Unassigned');
    const portfolioName = Option.of(parentPortfolio).map(prop('name')).getOrElse('Unassigned');

    const entityLink = Option.of(ParentEntity)
      .map((entity) => buildEntityLink('adEntity', entity.id))
      .getOrElse('');
    const portfolioLink = Option.of(parentPortfolio)
      .map((portfolio) => buildEntityLink('adPortfolio', portfolio.id))
      .getOrElse('');
    const mainData: { label: string; value: string; link?: string }[] = [
      { label: 'Portfolio', value: portfolioName, link: portfolioLink },
      { label: 'Entity', value: entityName, link: entityLink }
    ];

    mainData.push(
      { label: 'Platform', value: _startCase(_get(campaign, 'platformType')) },
      {
        label: 'Goal',
        value: _startCase(_get(campaign, 'extendedAttributes.automationAttributes.strategyId', 'Manual'))
      },
      { label: 'Ad Type', value: _startCase(_get(campaign, 'extendedAttributes.campaignType')) }
    );

    return {
      ...campaign,
      link: buildEntityLink('adCampaign', campaign.id),
      mainData,
      displayName: campaign.campaignName
    };
  });

type FormattedData = ArrayElementOf<ReturnType<typeof formatCampaign>>[];

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['adEntities', 'adPortfolios', 'adPlatforms', 'app', 'retailer', 'entityService', 'mainTimePeriod']),
  adCampaigns: state.app.name === AppName.Beacon ? state.user.adCampaigns : state.adCampaigns
});

type SummaryTileListProps = WidgetProps & ReturnType<typeof mapStateToProps>;

class SummaryTileList extends React.Component<SummaryTileListProps> {
  private cancelSource: CancelTokenSource | null = null;

  /**
   * The index name of the ES index from which ad campaign metrics will bee pulled.
   */
  private metricsIndexName: string;

  public constructor(props: SummaryTileListProps) {
    super(props);
    this.metricsIndexName =
      props.app.name === AppName.Advertising ? 'adCampaignAdGroupProductTargetDailyMetrics' : 'advertising';
  }

  private populateSpendById = (props: SummaryTileListProps) =>
    this.fetchCampaignDataSet(props).then((res) => {
      const dataKey = `spend_by_${props.widget.data.listType}Id`;
      if (!res[dataKey]) {
        throw panic('No data returned from scatter plot data API request');
      }

      const spendById = _keyBy(res[dataKey].data, ({ entity: { id } }: { entity: { id: string } }) => id);

      this.setState({ spendById, currentPage: 1 });
    });

  public componentDidMount() {
    this.populateSpendById(this.props);
    this.initialize(this.props);
  }

  public componentWillReceiveProps(nextProps: SummaryTileListProps) {
    if (anyNotEq(['adEntities', 'adCampaigns', 'adPlatforms', 'widget'], this.props, nextProps)) {
      this.initialize(nextProps);
    }

    if (
      this.props.widget.data.listType !== nextProps.widget.data.listType ||
      this.props.mainTimePeriod.id !== nextProps.mainTimePeriod.id
    ) {
      this.populateSpendById(nextProps);
    }
  }

  public componentDidUpdate() {
    // TODO: Abstract
    const { selectedEntityName } = queryString.parse(window.location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    });

    if (this.selectedEntityName && this.selectedEntityName !== selectedEntityName) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentPage: 1 });
    }

    this.selectedEntityName = selectedEntityName;
  }

  public state = {
    formattedData: [] as FormattedData,
    filteredData: [] as FormattedData,
    currentPage: 1,
    currentLayout: 'tile' as 'tile' | 'table',
    spendById: {} as { [key: string]: { value: number } }
  };

  private fetchCampaignDataSet = async ({
    app,
    retailer,
    entityService: { mainEntity },
    mainTimePeriod,
    widget
  }: SummaryTileListProps) => {
    // If there are any active network requests from previous states, cancel them so we don't get out-of-order udpates
    if (this.cancelSource) {
      this.cancelSource.cancel('API request cancelled due to new update');
    }
    this.cancelSource = CancelToken.source();

    const dataKey = `summary_tile_list-${mainEntity!.id}`;
    const groupByFieldName = `${widget.data.listType}Id`;

    const metricFields = ['spend'].map((fieldName) =>
      INDEX_FIELDS.getField(app.name, this.metricsIndexName, fieldName)
    );
    const [{ aggregations: aggregationFields }] = buildAggregations(metricFields);
    const { mainTimePeriodRangeFilters } = buildTimePeriodRangeFilters({
      app,
      indexName: this.metricsIndexName,
      mainTimePeriod
    });

    return store.dispatch(
      fetchEntityMetrics(
        dataKey,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName: this.metricsIndexName
        },
        [
          {
            doAggregation: true,
            aggregations: [
              {
                aggregationFields,
                conditions: {
                  termFilters: [{ fieldName: 'retailerId', values: [+retailer.id] }],
                  rangeFilters: [...mainTimePeriodRangeFilters]
                },
                groupByFieldName
              }
            ],
            conditions: {
              termFilters: [],
              rangeFilters: [...mainTimePeriodRangeFilters]
            },
            pageSize: 10000,
            processDocuments: true
          }
        ],
        undefined,
        true
      )
    );
  };

  private selectedEntityName: string | undefined;

  private initialize = (props: SummaryTileListProps) => {
    const { adEntities, adPortfolios, adCampaigns, widget } = props;
    const { listType } = widget.data;

    if (!adCampaigns) {
      return;
    }

    let formattedData: FormattedData = [];
    switch (listType) {
      case 'campaign': {
        formattedData = formatCampaign(adCampaigns, adPortfolios, adEntities);
        break;
      }
      default: {
        break;
      }
    }

    this.setState({ formattedData, filteredData: formattedData, currentPage: 1 });
  };

  private onKeywordChange = (keyword: string) => {
    const filteredData = this.state.formattedData.filter(
      ({ displayName, id }) =>
        displayName.toLowerCase().includes(keyword.toLowerCase()) || id.toLowerCase().includes(keyword.toLowerCase())
    );
    this.setState({ searchKeyword: keyword, filteredData, currentPage: 1 });
  };

  private renderSummaryTile = (cardEntity: Entity) => {
    const { widget, app } = this.props;
    const { weekIdField, entity, topFiveAggregationMetricFields, nInOneMetricFieldNames } = widget.data;
    const name = `tile_${cardEntity.id}`;

    const summaryTileConfig = {
      CustomComponent: SummaryTile,
      name,
      view: {},
      data: {
        weekIdField,
        entity,
        nInOneProps: {
          mericFieldNames: nInOneMetricFieldNames
        },
        currentEntity: cardEntity,
        topFiveProps: {
          aggregationMetricFields: topFiveAggregationMetricFields,
          indexName: this.metricsIndexName,
          groupByField: getGroupByFieldForCardEntity(
            app.name,
            this.metricsIndexName,
            cardEntity,
            this.selectedEntityName || 'stacklineSku'
          ),
          cardEntity
        }
      }
    };

    return <Widget {...this.props} noInnerContainer widget={summaryTileConfig} />;
  };

  private loadNextPage = () => this.setState({ currentPage: this.state.currentPage + 1 });

  private handleChangeLayout = (showTableView: boolean) =>
    this.setState({ currentLayout: showTableView ? ('table' as const) : ('tile' as const) });

  private renderTiles = () => {
    const { currentPage, filteredData, spendById } = this.state;
    const sortedData = _orderBy(filteredData, (elem) => (spendById[elem.id] ? -spendById[elem.id].value : Infinity));
    const dataToDisplay = sortedData.slice(0, currentPage * pageSize);

    if (_isEmpty(dataToDisplay)) {
      return (
        <div>
          <NoResultWithCustomButton handleChange={this.onKeywordChange} />
        </div>
      );
    }
    return (
      <div>
        {dataToDisplay.map((item, idx) => (
          <div key={idx}>{this.renderSummaryTile(item)}</div>
        ))}
        <Waypoint onEnter={this.loadNextPage} />
        <br />
        <br />
      </div>
    );
  };

  private renderTable = () => (
    <div>
      <Widget {...this.props} widget={this.props.widget.data.tableGridConfig} />
    </div>
  );

  public render() {
    if (_isEmpty(this.state.spendById)) {
      return <GridLoading />;
    }

    return (
      <div className="summarytile_list">
        <SummaryTileSearchbar
          keyword={this.state.searchKeyword || ''}
          placeholderText="Search"
          onKeywordChange={this.onKeywordChange}
          handleChangeLayout={this.handleChangeLayout}
          currentLayout={this.state.currentLayout}
        />
        <br />
        {this.state.currentLayout === 'tile' ? this.renderTiles() : this.renderTable()}
      </div>
    );
  }
}

export default connect(mapStateToProps)(SummaryTileList);
