import { useAppSelector } from 'src/utils/Hooks';
import {
  useBaseMetricRequestBuilder,
  UseBaseMetricRequestBuilderProps
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { useCallback, useMemo } from 'react';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { AggregationField } from 'src/types/application/types';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import useGenericAdvancedSearch, { UseGenericAdvancedSearchParameters } from 'src/utils/Hooks/useGenericAdvancedSearch';
import { getAppName } from 'src/utils/app';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';

export interface AdvancedSearchResponse<TMetadata> {
  aggregations: {
    [byGroupByFieldName: string]: {
      additionalValues: {
        [key: string]: number;
      };
      additionalMetaData: TMetadata;
    }[];
  };
}

export interface UseTableDataProps
  extends Omit<
    Required<UseBaseMetricRequestBuilderProps>,
    'startWeekId' | 'endWeekId' | 'buildAggregationBuilder' | 'sortFieldName'
  > {
  sortFieldName?: string;
  pageNumber: number;
  itemsPerPage: number;
  /**
   * We get {metric}_value by default, but can override to get
   * {metric}_{suffixOverride} instead
   */
  suffixOverride?: string;
  /**
   * Optionally modify the request builder before sending the request
   */
  buildRequest?: (requestBuilder: AdvancedSearchRequestBuilder) => AdvancedSearchRequestBuilder;

  /**
   * Optionally modify the aggregation in the builder request.
   */
  buildAggregation?: (aggregation: AggregationBuilder) => AggregationBuilder;

  /**
   * Optionally filter the response data
   */
  filterResponse?: <TMetadata>(row: {
    additionalValues: {
      [key: string]: number;
    };
    additionalMetaData: TMetadata;
  }) => boolean;
  startWeekId?: number;
  endWeekId?: number;
  useGenericAdvancedSearchArgs?: Partial<UseGenericAdvancedSearchParameters>;
  // override ComparisonWeekId - used for inventory (we only use last week), make sure you change buildRequest or buildAggregation to use this value

  customComparisonStartWeekId?: number;
  customComparisonEndWeekId?: number;

  aggregationKey?: string;
}

/**
 * Generic hook for fetching all beacon data that is displayed in tables/grids
 * throughout the site.
 *
 * We specify how to group the data - such as by stackline SKU, category, keyword,
 * etc. and which aggregation fields to use (the metrics about each datum to display).
 */
export const useTableData = <TMetadata>({
  fields,
  groupByFieldName,
  indexName,
  requestId,
  sortFieldName,
  pageNumber,
  itemsPerPage,
  suffixOverride,
  startWeekId,
  endWeekId,
  buildRequest,
  buildAggregation,
  filterResponse,
  useGenericAdvancedSearchArgs,
  customComparisonStartWeekId,
  customComparisonEndWeekId,
  aggregationKey
}: UseTableDataProps) => {
  const { startWeek: mainStartWeek, endWeek: mainEndWeek } = useAppSelector((state) => state.mainTimePeriod);
  const { startWeek: compareStartWeek, endWeek: compareEndWeek } = useAppSelector(
    (state) => state.comparisonTimePeriod
  );
  const metricSuffix = useMemo(() => suffixOverride || 'value', [suffixOverride]);

  const sortField: AggregationField | null = useMemo(() => {
    return sortFieldName ? INDEX_FIELDS.getField('beacon', indexName, sortFieldName) : null;
  }, [indexName, sortFieldName]);

  const buildAggregationBuilder = useCallback(
    (aggBuilder: AggregationBuilder) => {
      aggBuilder.addComparisonRangeFilter('weekId', compareStartWeek, compareEndWeek);
      if (sortField) {
        aggBuilder.setSortByAggregationField(
          sortField.displayName,
          sortField.name,
          sortField.aggregationFunction,
          true
        );
        aggBuilder.setSortDirection('desc');
      }
      if (buildAggregation) {
        return buildAggregation(aggBuilder);
      }
      return aggBuilder;
    },
    [compareStartWeek, compareEndWeek, sortField, buildAggregation]
  );

  const requestBuilder = useBaseMetricRequestBuilder({
    requestId,
    fields,
    indexName,
    groupByFieldName,
    endWeekId: endWeekId || mainEndWeek,
    startWeekId: startWeekId || mainStartWeek,
    buildAggregationBuilder
  });

  const requestBody = useMemo(() => {
    requestBuilder.setPageNumber(pageNumber).setPageSize(itemsPerPage).setProcessDocuments(true);

    if (sortField) {
      requestBuilder.addSortField(sortField.displayName, sortField.name, sortField.aggregationFunction, true);
    }

    if (buildRequest) {
      return buildRequest(requestBuilder).build();
    }
    return requestBuilder.build();
  }, [requestBuilder, pageNumber, itemsPerPage, sortField, buildRequest]);

  const { data: response, isLoading } = useGenericAdvancedSearch<AdvancedSearchResponse<TMetadata>[]>({
    queryKeys: [requestBody],
    requestBody: [requestBody],
    requestId,
    ...useGenericAdvancedSearchArgs
  });

  const parsedData = useMemo(() => {
    return !isLoading && response && response.data && response.data[0]
      ? response.data[0].aggregations[`by_${aggregationKey || groupByFieldName}`]
          .filter((row) => (filterResponse ? filterResponse(row) : true))
          .map((row) => ({
            ...row.additionalValues,
            metadata: row.additionalMetaData
          }))
      : [];
  }, [aggregationKey, groupByFieldName, isLoading, response]);

  const getValuesByFieldName = useCallback(
    (fieldName: string) => {
      const field = INDEX_FIELDS.getField(getAppName(), indexName, fieldName);
      return parsedData.map((row) => row[`${field.name}_${field.aggregationFunction}_${metricSuffix}`]);
    },
    [indexName, metricSuffix, parsedData]
  );

  const getComparisonValuesByFieldName = useCallback(
    (fieldName: string) => {
      const field = INDEX_FIELDS.getField(getAppName(), indexName, fieldName);
      const compareStartWeekId = customComparisonStartWeekId || compareStartWeek;
      const compareEndWeekId = customComparisonEndWeekId || compareEndWeek;

      return parsedData.map(
        (row) =>
          row[`${field.name}_${field.aggregationFunction}_${metricSuffix}_${compareStartWeekId}_${compareEndWeekId}`]
      );
    },
    [compareEndWeek, compareStartWeek, indexName, metricSuffix, parsedData]
  );

  const getValueDifferencesByFieldName = useCallback(
    (fieldName: string) => {
      const values = getValuesByFieldName(fieldName);
      const compareValues = getComparisonValuesByFieldName(fieldName);

      /**
       * Since we display differences as a percentage with 2 decimal points,
       * anything less than this will show up as.
       */
      const DIFFERENCE_MIN_VALUE = 0.000001;
      return values.map((value, index) => {
        const difference = value - (compareValues[index] || 0);
        // Super small values will show up as NaN when we try to format
        // them, so just show 0 instead
        if (Math.abs(difference) < DIFFERENCE_MIN_VALUE) {
          return 0;
        }
        return difference;
      });
    },
    [getComparisonValuesByFieldName, getValuesByFieldName]
  );

  return {
    data: parsedData,
    isLoading,
    getValuesByFieldName,
    getValueDifferencesByFieldName
  };
};
