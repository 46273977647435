import { useCallback } from 'react';
import { Segment } from 'sl-api-connector';
import { useAppSelector } from 'src/utils/Hooks/typedHooks';
import queryString from 'qs';
import { extractQueryConditions } from 'src/utils/segments';

/**
 * Returns a function that creates a link to edit or duplicate a segment
 */
export default function useCreateSegmentLink() {
  const { searchParams } = useAppSelector((state) => state.app.queryParams);

  return useCallback(
    (segment: Segment, edit?: boolean) => {
      const editLink = `/search/${searchParams}&doAggregation=true&entityType=${
        segment.segment.entityType
      }&${queryString.stringify(extractQueryConditions(segment.segment, true, true))}`;

      const duplicateLink = `/search${searchParams}&entityType=${segment.segment.entityType}&${queryString.stringify(
        extractQueryConditions(segment.segment, true)
      )}`;

      return edit ? editLink : duplicateLink;
    },
    [searchParams]
  );
}
