/**
 * Entity grid widget derivative that allows for fetching data from multiple different ElasticSearch indices and
 * displaying it lazily.
 */

import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _merge from 'lodash/merge';
import _prop from 'lodash/property';
import { mergeConditions } from 'sl-api-connector/search/conditions';

import { store } from 'src/main';
import EntityGrid from 'src/components/EntityGrid/EntityGrid';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import entitySearchServiceActions from 'src/store/modules/entitySearchService/actions';
import {
  getEntityGridParams,
  buildAggregationConditions
} from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { getMainConditions } from 'src/utils/conditions';
import { buildEntityGridDataKey } from '../EntityGrid/EntityGrid/EntityGrid';
import { parseEntityMetrics } from 'src/store/modules/entitySearchService/selectors';
import { buildAggregations } from 'src/components/AdManager/Search';

const fetchEntityMetrics = (...args) => store.dispatch(entitySearchServiceOperations.fetchEntityMetrics(...args));

/**
 * Builds the request body and initializes the request to fetch secondary entity metrics data.  Returns a promise that
 * resolves once the request has complete successfully.
 *
 * @param {object} params
 * @param {object} secondaryRequestConditions
 * @param {object} state
 * @param {string} indexName
 * @param {object} mainField
 * @param {object | undefined} eventBus
 */
export const fetchSecondaryFeaturedProductsData = async (
  { pageSize, entity, dataKey },
  { entityGridAggregationConditions, mainEntityConditions, entityIdsConditions, comparisonRangeFilters },
  { retailer, app, allWeekIdsByRetailerId },
  indexName,
  aggregationFields,
  groupByField,
  avoidESS = false,
  sortByAggregationField = aggregationFields[0],
  sortDirection = 'desc',
  eventBus = null
) => {
  const requestOverrides = {
    aggregations: [
      {
        aggregationFields,
        conditions: entityGridAggregationConditions,
        comparisonRangeFilters,
        groupByFieldName: groupByField.name,
        sortByAggregationField,
        sortDirection
      }
    ],
    conditions: mergeConditions(mainEntityConditions, entityIdsConditions),
    doAggregation: true,
    pageSize,
    period: 'year',
    processDocuments: true,
    returnDocuments: false
  };

  const requestContext = { entity, retailer, app, indexName, mergeIfStatePropertyValueExists: true };

  if (!avoidESS) {
    return fetchEntityMetrics(dataKey, requestContext, [requestOverrides]);
  } else {
    const { searchRequests: apiRequest } = entitySearchServiceOperations.buildSearchRequests(dataKey, requestContext, [
      requestOverrides
    ]);

    const apiResponse = await entitySearchServiceOperations.requestEntityMetrics(app.name, apiRequest);

    if (eventBus) {
      eventBus.emit('setSearchHeader', {
        isLoading: false,
        entity: { result: { apiRequest } }
      });
    }
    return parseEntityMetrics({
      apiRequest,
      apiResponse,
      statePropertyName: dataKey,
      requestContext,
      searchRequestsOverrides: [requestOverrides],
      allWeekIdsByRetailerId
    });
  }
};

const buildMultiIndexGridWidget = (
  primaryAggregationFields,
  secondaryAggregationFields,
  gridRendererFields,
  entity,
  groupByField,
  { pageSize = 20, name = 'featuredProductsEntityGrid', mainMetricField = primaryAggregationFields[0] } = {},
  widgetOverrides = {},
  conditionsOverride
) => {
  const widget = {
    name,
    CustomComponent: EntityGrid,
    view: _merge({}, VIEWS.entityGrid, {
      name,
      gridOptions: {
        enableGroupBy: false,
        defaultLayout: 'table',
        pageSize,
        onDataFetched: (uniqueName, { pageNumber, statePropertyValue }) => {
          const state = store.getState();
          const { app, retailer, mainTimePeriod, comparisonTimePeriod, allWeekIdsByRetailerId } = state;

          const dataKey = buildEntityGridDataKey(uniqueName);

          const entityIds = statePropertyValue[`${mainMetricField.name}_by_${groupByField.name}`].data.map(
            (datum) => datum.cardView[groupByField.name]
          );

          // Construct a query condition that limits returned results to only those for the already fetched product IDs
          const entityIdsConditions = {
            termFilters: [
              {
                condition: 'should',
                fieldName: groupByField.name,
                values: entityIds
              }
            ]
          };

          // This emulates the behavior of `EntityPage` in building the API request
          // conditions from the application's global Redux store.
          const { baseAggregationConditions, mainEntityConditions } = getMainConditions();

          const secondaryRequestsByIndexName = _groupBy(secondaryAggregationFields, _prop('indexName'));

          Object.entries(secondaryRequestsByIndexName).forEach(async ([indexName, fields]) => {
            const { entityGridAggregationConditions } = getEntityGridParams(
              retailer,
              mainTimePeriod,
              comparisonTimePeriod,
              baseAggregationConditions,
              fields[0]
            );

            const { comparisonRangeFilters } = buildAggregationConditions(
              app,
              indexName,
              retailer,
              allWeekIdsByRetailerId,
              mainTimePeriod,
              comparisonTimePeriod,
              baseAggregationConditions,
              INDEX_FIELDS.getField(app.name, 'sales', 'weekId'),
              false
            );

            const secondaryRequestDataKey = `${name}_secondaryRequest_${indexName}_page-${pageNumber}`;

            const secondaryRequestConditions = {
              entityGridAggregationConditions,
              mainEntityConditions: conditionsOverride || mainEntityConditions,
              entityIdsConditions,
              comparisonRangeFilters
            };
            const { aggregations } = buildAggregations(fields)[0];

            await fetchSecondaryFeaturedProductsData(
              { entity, dataKey: secondaryRequestDataKey, pageSize: 1000 },
              secondaryRequestConditions,
              state,
              indexName,
              aggregations,
              groupByField
            );

            // Take the data from the secondary request data key and merge it into the data key of the main entity
            // grid so that it's available to the cell renderers.
            store.dispatch(entitySearchServiceActions.mergeDataKeyToMapping(secondaryRequestDataKey, dataKey));
          });
        }
      }
    }),
    data: {
      groupByFields: [groupByField],
      configByGroupByFieldName: {
        stacklineSku: {
          indexName: primaryAggregationFields[0].indexName,
          entity,
          mainMetricField,
          aggregationFields: primaryAggregationFields,
          tableView: {
            metricFields: gridRendererFields
          }
        },
        retailerId: {
          indexName: primaryAggregationFields[0].indexName,
          entity,
          mainMetricField,
          aggregationFields: primaryAggregationFields,
          tableView: {
            metricFields: gridRendererFields
          }
        }
      }
    }
  };

  return _merge(widget, widgetOverrides);
};

export const mkSecondaryFieldCRF = (groupByField, field, retailer) => ({ data }) => {
  const secondaryDataSetKey = `${field.name}_by_${groupByField.name}`;
  const { [groupByField.name]: groupByFieldValue } = data.cardView;

  const secondaryDataSet = data.rawData[secondaryDataSetKey];
  const cardView = secondaryDataSet ? _get(secondaryDataSet[groupByFieldValue], 'cardView') : null;

  if (!cardView) {
    return '--';
  }

  const value = cardView[`${field.name}CurrentValue`];

  return convertMetricToDisplayValue(retailer, value, field.metricType, retailer.currencySymbol, true);
};

export default buildMultiIndexGridWidget;
