/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import MenuItem from '@mui/material/MenuItem';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import { store } from 'src/main';
import StyledComponentWithPlaceHolder from 'src/components/common/Form/WrapWithPlaceholder';
import DataColumn from 'src/components/AdManager/Search/CustomColumns/DataColumn';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import * as adBudgetEditorActions from 'src/store/modules/adManager/adBudgetEditor/actions';
import { ChevronIcon } from 'src/components/SvgIcons';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { AggregationField } from 'src/types/application/types';
import {
  SectionWrapper,
  OperationButtons
} from 'src/components/AdCampaignBuilder/Widgets/AdCampaignBuilderCommonWidgets';
import {
  build4wRangeFilters,
  build4wComparisonRangeFilters,
  buildMtdRangeFilters,
  buildMtdComparisonRangeFilters
} from 'src/utils/dateformatting';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { AdCampaignBuilderRadio } from 'src/components/AdCampaignBuilder/Widgets/Checkbox';
import ReduxStore from 'src/types/store/reduxStore';
import { buildAggregations, zipMetricsResponseIntoArray } from 'src/components/AdManager/Search';
import StandardDropdown from 'src/components/common/DropDown/StandardDropdown';

const initFlow = [
  'platformSelect',
  // 'portfolioAlign',
  // 'campaignAlign',
  'budgetPeriod',
  'budgetAmount',
  'budgetAllocation',
  'confirmation'
];

export const formatList = (arr1: any[], arr2: any[]) => {
  const result = arr2.map((entity) => {
    const entityData = arr1.find((data) => data.id === entity.id);
    return {
      ...entityData,
      id: entity.id,
      entity
    };
  });
  result.sort((a: any, b: any) => {
    const aValue = _get(a, ['spend', 'value'], -1);
    const bValue = _get(b, ['spend', 'value'], -1);
    return bValue - aValue;
  });
  return result;
};

const baseColDef = {
  disableSort: true,
  width: 100
};

const entityColumn = ({ data }: { data: any }) => {
  const name = data.entity.displayName || data.entity.name || '';
  const id = data.entity.id || '';
  return (
    <div>
      <div>{name}</div>
      <div style={{ color: '#6b7c93' }}>{id}</div>
    </div>
  );
};
const onGridReady = (params: any) => {
  params.api.sizeColumnsToFit();
  params.api.refreshHeader();
};

const muiStyles = {
  root: {
    margin: 0,
    '&:before': {
      content: 'none'
    },
    maxWidth: 500,
    width: 500,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  select: {
    fontWeight: 400,
    width: 500,
    height: 30,
    maxWidth: 500
  }
};

const RadioCell = (props) => {
  const { adBudgetEditor } = props;
  const { id } = props.data;
  const setStepFlow = () => {
    const stepFlow = initFlow;
    store.dispatch(adBudgetEditorActions.setFlow(stepFlow));
  };
  const onCheck = () => {
    store.dispatch(adBudgetEditorActions.setEntity({ ...props.data, selectedEntityId: id }));
    setStepFlow();
  };
  return (
    <AdCampaignBuilderRadio
      disableRipple
      checked={adBudgetEditor.entity.selectedEntityId === id}
      onChange={onCheck}
      style={{ padding: 8 }}
    />
  );
};

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, [
      'app',
      'retailer',
      'adEntities',
      'adPortfolios',
      'adCampaigns',
      'adBudgetEditor',
      'adPlatforms',
      'mainTimePeriod'
    ]),
    mainEntity: state.entityService.mainEntity
  };
};

const Platform: React.FC<{ classes: { [key: string]: any } } & ReturnType<typeof mapStateToProps>> = ({
  app,
  adBudgetEditor,
  classes,
  adPlatforms,
  adCampaigns,
  adPortfolios,
  mainEntity,
  adEntities,
  retailer,
  mainTimePeriod,
  widget
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const columnDefs = [
    {
      headerName: '',
      field: '',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 100,
      maxWidth: 100,
      cellRendererFramework: connect(mapStateToProps)(RadioCell),
      headerClass: 'align-left'
    },
    {
      headerName: 'Entity',
      field: 'entity',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 300,
      maxWidth: 800,
      cellRendererFramework: entityColumn,
      headerClass: 'align-left'
    },
    {
      headerName: 'Ad Clicks',
      cellRendererFramework: DataColumn,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      field: 'clicks'
    },
    {
      headerName: 'Ad Spend',
      cellRendererFramework: DataColumn,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      field: 'spend'
    },
    {
      headerName: 'Ad Sales',
      cellRendererFramework: DataColumn,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      field: 'sales'
    },
    {
      headerName: 'ROAS',
      cellRendererFramework: DataColumn,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      field: 'returnOnAdSpend'
    }
  ].map(({ headerName, ...colDef }) => ({
    ...baseColDef,
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' },
    ...colDef,
    displayName: headerName
  }));

  const fetchListData = async (metrics: string[], metricName: string, groupByFieldName: string) => {
    const entityIds = adEntities.map((entity) => entity.id);
    const termFilters = [{ fieldName: 'entityId', values: entityIds }];
    const timePeriodRangeFilters = groupByFieldName === 'entityId' ? buildMtdRangeFilters() : build4wRangeFilters();
    const comparisonRangeFilters =
      groupByFieldName === 'entityId' ? buildMtdComparisonRangeFilters() : build4wComparisonRangeFilters();
    const indexName = 'adCampaignDailyMetrics';
    const aggregations: AggregationField[] = metrics.map((fieldName) =>
      INDEX_FIELDS.getField(app.name, indexName, fieldName)
    );

    const [{ aggregations: aggregationFields }] = buildAggregations(aggregations);
    const fetchResult = await store.dispatch(
      fetchEntityMetrics(
        metricName,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName: 'adCampaignDailyMetrics',
          customResponseParser: (action: any) => {
            return zipMetricsResponseIntoArray(action, widget);
          }
        },
        [
          {
            doAggregation: true,
            aggregations: [
              {
                aggregationFields,
                conditions: {
                  termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
                  rangeFilters: [...timePeriodRangeFilters]
                },
                sortDirection: 'desc',
                comparisonRangeFilters,
                groupByFieldName
              }
            ],
            conditions: {
              termFilters,
              rangeFilters: [...timePeriodRangeFilters]
            },
            pageSize: 10000,
            processDocuments: false
          }
        ],
        null,
        true
      )
    );
    return fetchResult;
  };

  const fetchEntityListData = async () => {
    setIsLoading(true);
    const entityList = await fetchListData(
      ['clicks', 'spend', 'sales', 'returnOnAdSpend'],
      'budgetIntakeEntityGrid',
      'entityId'
    );

    const formattedEntityList = formatList(entityList.data, adEntities);
    await store.dispatch(adBudgetEditorActions.setEntityArray(formattedEntityList));

    setIsLoading(false);
  };

  useEffect(() => {
    if (
      !isLoading &&
      !adBudgetEditor.entityArray &&
      mainTimePeriod &&
      mainEntity &&
      !_isEmpty(adPlatforms) &&
      !_isEmpty(adEntities) &&
      !_isEmpty(adPortfolios) &&
      !_isEmpty(adCampaigns)
    ) {
      fetchEntityListData();
    }
  });

  const handlePlatformChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const selectedPlatform = adPlatforms.find((item) => item.id === event.target.value) || {};
    store.dispatch(
      adBudgetEditorActions.setPlatform({
        platformId: selectedPlatform.id,
        platformName: selectedPlatform.name,
        timeZone: _get(selectedPlatform, ['extendedAttributes', 'processInTimezone'], '')
      })
    );

    // If the platform has changed, de-select the selected entity as well since they will no longer match
    if (event.target.value !== adBudgetEditor.platform.platformId) {
      store.dispatch(adBudgetEditorActions.setEntity({ selectedEntityId: null }));
    }
  };

  const canContinue = useMemo(() => {
    return !!adBudgetEditor.platform.platformId && !!adBudgetEditor.entity.selectedEntityId;
  }, [adBudgetEditor.platform, adBudgetEditor.entity]);

  if (!adBudgetEditor.entityArray) {
    return null;
  }

  const filterEntities = (adBudgetEditor.entityArray || []).filter((item) => {
    // filter out all the entity that belongs to the selected platform and have more that one campaign.
    return (
      item.entity.platformType === adBudgetEditor.platform.platformId &&
      !_isEmpty(adCampaigns.filter((campaign) => campaign.extendedAttributes.entityId === item.id))
    );
  });

  return (
    <div className="ad-manager-container">
      <OperationButtons
        subtab="platformSelect"
        canContinue={canContinue}
        customFlow={adBudgetEditor.flow || initFlow}
      />
      <SectionWrapper header="Platform" subheader="Select the platform for this new budget." layer={0}>
        <div className="platform-select">
          <StyledComponentWithPlaceHolder valueToCheck={adBudgetEditor.platform.platformId} placeholder="Platform Type">
            <StandardDropdown
              variant="standard"
              value={adBudgetEditor.platform.platformId || null}
              onChange={handlePlatformChange}
              classes={{ root: classes.root, select: classes.select }}
              IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
            >
              {adPlatforms
                .filter((item) => `${item.extendedAttributes.retailerId}` === retailer.id)
                .map((item) => (
                  <MenuItem key={item.id} value={item.id} disabled={item.extendedAttributes.status === 'disabled'}>
                    {item.name}
                  </MenuItem>
                ))}
            </StandardDropdown>
          </StyledComponentWithPlaceHolder>
        </div>
      </SectionWrapper>
      {adBudgetEditor.platform.platformId ? (
        <SectionWrapper header="Entity" subheader="Select the entity for this new budget." layer={1}>
          <div className="platform-select">
            {filterEntities && (
              <CustomAgMaterial
                onGridReady={onGridReady}
                onCellValueChanged={onGridReady}
                onModelUpdated={onGridReady}
                onRowValueChanged={onGridReady}
                onRowDataChanged={onGridReady}
                buildRows={() => filterEntities}
                columnDefs={columnDefs}
                // deltaRowDataMode
                getRowNodeId={(datum: any) => escape(datum.id)}
                domLayout="autoHeight"
                rowHeight={66}
                containerStyle={{ width: '100%' }}
                widget={widget}
              />
            )}
          </div>
        </SectionWrapper>
      ) : null}
    </div>
  );
};

const EnhancedPlatform = connect(mapStateToProps)(withStyles(muiStyles)(Platform));

export default EnhancedPlatform;
