import React from 'react';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import ProductListContainer from 'src/components/BeaconRedesignComponents/MetricList/ProductListContainer';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { Field } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy';
import { useAppSelector } from 'src/utils/Hooks';
import { getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import { useProductEntity } from 'src/utils/Hooks/reduxSelectors';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import { getWeekColumnDefinition } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import { removeComparisonFormatter } from 'src/components/BeaconRedesignComponents/MetricList/utils';
import { SALES_TABLE_HEADER_DISPLAY_NAMES } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Summary/Sales/constants';

/**
 * Shows charts for the inventory summary page
 */

const fields: Field[] = [
  {
    name: 'unitsSold',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.UNITS_SOLD
  },
  {
    name: 'retailPrice',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.RETAIL_PRICE
  },
  {
    name: 'instockRate',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.INSTOCK_RATE
  },
  {
    name: 'unitsOnHand',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.UNITS_ON_HAND
  },

  {
    name: 'inventoryWeeksOnHand',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.INVENTORY_WEEKS_ON_HAND
  },
  {
    name: 'inventoryRetailValue',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.INVENTORY_RETAIL_VALUE
  }
];
const InventorySummaryPage = () => {
  const { endWeek: mainEndWeekId } = useAppSelector((state) => state.mainTimePeriod);
  const { endWeek: comparisonEndWeekId } = useAppSelector((state) => state.comparisonTimePeriod);
  const weekEndingFields = fields.map(removeComparisonFormatter);
  const { isProductEntity } = useProductEntity();

  return (
    <BeaconPageContainer>
      <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
        <BeaconComparableSplineChart
          indexName="sales"
          fieldName="instockRate"
          aggregationFunctionForTotal="avg"
          beaconChartWithLegendOverride={(defaultProps, metricByWeekValues) => {
            return {
              ...defaultProps,
              primaryLegendProps: {
                ...defaultProps.primaryLegendProps,
                change: metricByWeekValues.formattedPercentChange,
                direction: getDirection(metricByWeekValues.percentChange),
                metric: metricByWeekValues.formattedPrimaryTotal
              },
              comparisonLegendProps: {
                ...defaultProps.comparisonLegendProps,
                metric: metricByWeekValues.formattedSecondaryTotal
              }
            };
          }}
        />
        <BeaconComparableSplineChart
          indexName="sales"
          fieldName="unitsOnHand"
          aggregationFunctionForTotal="cardinality"
        />
        <BeaconComparableSplineChart
          indexName="sales"
          fieldName="inventoryWeeksOnHand"
          title="Weeks on Hand - Historical Run Rate"
        />
        <BeaconComparableSplineChart
          indexName="sales"
          fieldName="inventoryRetailValue"
          aggregationFunctionForTotal="cardinality"
        />

        {isProductEntity ? (
          <MetricListContainer
            entityTableHeaderProps={{
              title: 'Units on Hand by Week'
            }}
            indexName="sales"
            sortFieldName="unitsOnHand"
            fields={weekEndingFields}
            headColumnDefinition={getWeekColumnDefinition({
              getTableColumnProps(defaults) {
                return {
                  ...defaults,
                  style: {
                    ...defaults.style,
                    width: '220px'
                  }
                };
              }
            })}
            postProcessData={(data) => {
              return data.sort((a, b) => b.weekId.localeCompare(a.weekId));
            }}
            useTableDataProps={{
              groupByFieldName: 'weekId',
              filterResponse: () => true,
              itemsPerPage: 100
            }}
            showGrid={false}
          />
        ) : (
          <ProductListContainer
            indexName="sales"
            fields={fields}
            sortFieldName="unitsOnHand"
            entityTableHeaderProps={{ title: 'Units on Hand by Product' }}
            useTableDataProps={{
              customComparisonStartWeekId: comparisonEndWeekId,
              customComparisonEndWeekId: comparisonEndWeekId,
              buildAggregation: (aggBuilder) =>
                aggBuilder
                  .clearConditionRangeFilters()
                  .addConditionRangeFilter('weekId', mainEndWeekId, mainEndWeekId)
                  .replaceComparisonRangeFilter('weekId', comparisonEndWeekId, comparisonEndWeekId),
              buildRequest: (builder) =>
                builder.removeConditionRangeFilters((rangeFilter) => rangeFilter.fieldName === 'weekId')
            }}
          />
        )}
      </Flex>
    </BeaconPageContainer>
  );
};

export default InventorySummaryPage;
