import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';

type ScrollableContainerProps = BoxProps & {
  sx?: SxProps;
  scrollbarWidth?: string;
  thumbColor?: string;
};

const ScrollableBox = (props: ScrollableContainerProps) => <Box {...props} />;

/**
 * A container for scrollable content that
 * shows a scrollbar. For some reason, by default
 * the scrollbar is hidden so this component
 * is needed to show it.
 */
export default styled(ScrollableBox)(
  ({ sx = {}, scrollbarWidth = '3px', thumbColor = '#7e8fa8' }: ScrollableContainerProps) => ({
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: scrollbarWidth
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: thumbColor,
      borderRadius: '3px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    },
    ...{ sx }
  })
);
