import React, { FC } from 'react';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import _get from 'lodash/get';
import colors from 'src/utils/colors';

const useStyles = makeStyles({
  defaultStyles: {
    color: colors.atlasBlue,
    transition: '0.6s',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});

const AppIconButton: FC<IconButtonProps> = (props) => {
  const { children, ...rest } = props;
  const classes = useStyles();
  return (
    <IconButton
      className={classes.defaultStyles}
      disableRipple={_get(props, 'disableRipple', true)}
      {...rest}
      size="large"
    >
      {children}
    </IconButton>
  );
};

export default AppIconButton;
