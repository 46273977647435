import colors from 'src/utils/colors';
import { getDay } from 'src/utils/dateformatting';

const getXAxisLabelDivWrapper = (content) => `<div style="display:inline-block; float:left; color:${colors.darkBlue};">
    ${content}
  </div>`;

const getXAxisLabelDiv = (weekId) => {
  const content = `Week ${weekId % 100}`;
  return getXAxisLabelDivWrapper(content);
};

const getDailyXAxisLabelDiv = (x) => {
  const content = x.name ? `${x.name.slice(0, 10)}${x.name.length >= 10 ? '...' : ''}` : getDay(x);
  return getXAxisLabelDivWrapper(content);
};

const getDriveDaily = (day = '') => {
  return getXAxisLabelDivWrapper(day);
};

export const getTooltipDiv = (
  tooltipStyle,
  percentChangeDiv,
  yAxisMetricsDiv,
  x,
  idDaily = false,
  weekId,
  driveProps = {
    isDriveDays: false,
    point: null
  }
) => {
  const content = driveProps.isDriveDays ? getDriveDaily(driveProps.point.name || '') : null;

  return `<div class="hichart-tooltip" style="${tooltipStyle}" >
  <div style="overflow:hidden;" class="hiline-chart-tooltipx">
    ${content || (idDaily ? getDailyXAxisLabelDiv(x) : getXAxisLabelDiv(weekId))}
    ${percentChangeDiv}
  </div>
  <div class="hiline-chart-tooltipy">
    ${yAxisMetricsDiv}
  </div>
</div>`;
};

/**
 *
 * @param tooltipStyle A string containing style overrides for the tooltip.
 * @param headerContent A string to be displayed above the main content.
 * @param headerColor Changes the header color.
 * @param mainContent A string to be used as the main content in the tooltip.
 * @param mainContentColor Changes the main content color.
 * @returns stringified HTML for rendering a tooltip with a header and a value under it.
 */
export const getTooltipWithHeaderAndValueDiv = ({
  tooltipStyle = '',
  headerContent,
  headerColor = colors.darkBlue,
  mainContent,
  mainContentColor = colors.stacklineBlue
}) => {
  return `<div class="hichart-tooltip" style="${tooltipStyle}" >
      <div style="overflow:hidden;" class="hiline-chart-tooltipx">
          <div style="display:inline-block; float:left; color:${headerColor};">
            ${headerContent}
      </div>
    </div>
  <div class="hiline-chart-tooltipy">
    <div style="margin-top:5px;color:${mainContentColor};">
      ${mainContent}                            
    </div>
  </div>
</div>`;
};

/**
 * Gets a multiline tooltip div for when hovering over a chart.
 * @param {Array.<{label: string, fontSize?: string, color? : string}} labels An array of tooltip labels.
 */
export const getSimpleTooltipDiv = (labels) => {
  const labelDivs = labels
    .map(({ label, fontSize, color }, index) => {
      const style = Object.entries({
        'font-size': fontSize || '14px',
        color: color || colors.stacklineBlue,
        'margin-top': index > 0 ? '10px' : '0px',
        'font-weight': 500
      }).reduce((prev, [key, value]) => `${prev} ${key}: ${value};`, '');

      return `<div style="${style}">${label}</div>`;
    })
    .join('');

  return `
    <div class="hichart-tooltip" style="border: solid 0.8px #dedede;">
      ${labelDivs}
    </div>
  `;
};
