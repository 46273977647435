export * from './waterfallData';
export * from './productGridData';
export * from './useAggregationBuilderWithMetrics';
export * from './useKeyMetricForecastSummaryData';

/**
 * Maps the key metric summary card metric name
 * to the key in the parsed forecast AS response.
 */
export const METRIC_TO_AS_RESPONSE_KEY = {
  totalClicks: 'totalTraffic_sum_value',
  organicClicks: 'organicTraffic_sum_value',
  otherClicks: 'otherTraffic_sum_value',
  clicks: 'paidTrafficValue_sum_value',
  spend: 'adSpend_sum_value',
  sales: 'adSales_sum_value',
  unitsSold: 'unitsSold_sum_value',

  purchaseRate: 'purchaseRate_sum_value',
  retailPrice: 'retailPrice_avg_value',
  contentScore: 'contentScore_avg_value',
  instockRate: 'inStockRate_avg_value',
  stars: 'rating_stats_avg',
  winPercentage: 'buyBoxWon_avg_value',

  retailSales: 'retailSales_sum_value',
  wholesaleSales: 'wholesaleSales_sum_value',
  retailerGrossMargin: 'retailerMargin_sum_value',
  brandGrossMargin: 'brandMargin_sum_value',
  brandGrossMarginPercent: 'brandMarginPercent_avg_value'
};
