import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { updateStoreListSegment, fetchOmniStoreListData } from 'src/store/modules/omni/OmniStoreList/operation';
import { useDispatch, useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import ListSegmentComponent from 'src/components/Omni/OmniSegmentList/ListSegmentComponent';
import OmniSegmentDeleteDialog from 'src/components/Omni/OmniSegmentList/OmniSegmentDeleteDialog';

const OmniStoreList: React.FC<RouteComponentProps> = ({ history }: RouteComponentProps) => {
  const [selected, setSelected] = useState({ queryId: '', name: '' });
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const storeListData = useSelector((state: ReduxStore) => state.omniStoreSegmentService);
  const app = useSelector((state: ReduxStore) => state.app);
  const { searchParams } = app.queryParams;
  const isFetching = storeListData ? storeListData.isFetching : true;
  const data = storeListData ? storeListData.data : [];
  const displayData = isFetching
    ? []
    : data.map((e) => ({ name: e.name, id: e.queryId, canEdit: e.canEdit, ownerProfile: e.ownerProfile }));

  const handleListItemClick = (id: string) => {
    history.push(`/store/${id}${searchParams}&tab=scorecard&subtab=all`);
  };

  const handleDeleteButtonClick = (queryId: string, name: string) => {
    setSelected({
      queryId,
      name
    });
    setIsOpen(true);
  };

  const handleDelete = () => {
    const { queryId, name } = selected;
    updateStoreListSegment({ queryId, name, isActive: false, segmentType: 'store' }).then(() => {
      dispatch(fetchOmniStoreListData());
      setIsOpen(false);
    });
  };

  return isFetching ? (
    <div>Loading</div>
  ) : (
    <>
      <ListSegmentComponent
        title="Store Lists"
        data={displayData}
        handleListItemClick={handleListItemClick}
        handleDeleteButtonClick={handleDeleteButtonClick}
        searchBarInfo={{
          name: 'Store Lists',
          searchHintText: 'Search Store Lists Name'
        }}
      />
      <OmniSegmentDeleteDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selected={selected}
        deleteButtonText="DELETE"
        handleDelete={handleDelete}
      />
    </>
  );
};

export default withRouter(OmniStoreList);
