import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import _identity from 'lodash/identity';
import { ExportIcon, ContentCurrentIcon, InventoryIcon, CompareScaleIcon } from 'src/components/SvgIcons';
import PageContextMenu from 'src/components/PageContextMenu';
import getSummaryPageLayout from './SummaryPageLayout';
import getSalesPageLayout from './Beacon/SalesPageLayout';
import getWaterfallPageLayout from './Beacon/WaterfallPageLayout';
import getCompliancePageLayout from './Beacon/CompliancePageLayout';
import getTrafficPageLayout from './Beacon/TrafficPageLayout/TrafficPageLayout';
import getConversionPageLayout from './Beacon/ConversionPageLayout';
import getOperationsPageLayout from './Beacon/OperationsPageLayout/OperationsPageLayout';
import { flattenWidgets } from 'src/components/Layout/LayoutUtil';
import getNewBeaconKeyMetricsLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/getNewBeaconKeyMetricsLayout';
import getProductGrowthLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/getProductGrowthLayout';
import getForecastsPlansLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/getForecastsPlansLayout';
import getForeCastsAdjustmentsLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/getForeCastsAdjustmentsLayout';
import SubtabNavigation from 'src/components/BeaconRedesignComponents/SubtabNav/SubtabNavigation';
import SubtabHeader from 'src/components/BeaconRedesignComponents/common/SubtabHeader';
import getForecastSummaryPageLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/getForecastSummaryPageLayout';
import getForecastRecommendationsPageLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/getForecastRecommendationsPageLayout';

const getPageLayoutGetter = (name: string) => {
  return (
    {
      compliance: getCompliancePageLayout,
      summaryPage: getSummaryPageLayout,
      keymetrics: getNewBeaconKeyMetricsLayout,
      growthmatrix: getProductGrowthLayout,
      forecastsPlans: getForecastsPlansLayout,
      forecastsAdjustments: getForeCastsAdjustmentsLayout,
      recommendations: getForecastRecommendationsPageLayout, // Forecast recommendations
      traffic: getTrafficPageLayout,
      waterfall: getWaterfallPageLayout,
      conversion: getConversionPageLayout,
      sales: getSalesPageLayout,
      operations: getOperationsPageLayout,
      forecastSummary: getForecastSummaryPageLayout
    }[name] || getSalesPageLayout
  );
};

const getLayoutName = (pageType, tab, metricType) => {
  // summary page
  if (pageType === 'summaryPage') {
    return pageType;
  }

  // compliance pages
  if (tab === 'compliance') {
    return tab;
  }

  // prime/basic page and waterfall page
  if (['keymetrics', 'waterfall', 'growthmatrix'].includes(metricType)) {
    return metricType;
  }

  // conversion pages
  if (
    (tab === 'sales' && ['retailPrice', 'mapPrice', 'inventory', 'inactive'].includes(metricType)) ||
    ['content', 'buybox', 'reviews'].includes(tab)
  ) {
    return 'conversion';
  }

  // sales page
  if (tab === 'sales' && ['retailSales', 'unitsSold', 'margin', 'returns'].includes(metricType)) {
    return 'sales';
  }

  if (tab === 'forecasts') {
    return metricType;
  }

  return tab;
};

const shouldEnableExport = (metricType, user, flattenedWidgets) => {
  const lastWidgetView = _get(_last(flattenedWidgets), 'view');
  const failConditions = [!user.config.exportEnabled, _isEmpty(flattenedWidgets), !lastWidgetView];

  if (failConditions.find(_identity)) {
    return false;
  }

  if (['reviewsGrid', 'weeklyMetricsGridContainer', 'promotionsGrid', 'entityGrid'].includes(lastWidgetView.name)) {
    return true;
  }

  return _get(lastWidgetView.gridOptions, 'enableExport', false);
};

function getLayoutForEntity({
  app,
  retailer,
  user,
  tab,
  metricType,
  entity,
  pageType,
  filters,
  entityType,
  selectedEntityName
}) {
  const { queryParams } = app;
  const layoutName = getLayoutName(pageType, tab, metricType);

  const getPageLayout = getPageLayoutGetter(layoutName);

  const pageLayout = getPageLayout({
    app,
    retailer,
    user,
    tab,
    metricType,
    entity,
    filters,
    entityType,
    selectedEntityName
  });

  const { widgets, enableComparison, CustomPageContainer, dataConfig, ...rest } = pageLayout;

  const flattenedWidgets = flattenWidgets(widgets);
  const enableExport = shouldEnableExport(metricType, user, flattenedWidgets);

  const enableContentDownload =
    queryParams.tab === 'content' && ['contentAccuracy'].includes(queryParams.subtab) && enableExport;

  if (enableComparison || enableExport || enableContentDownload) {
    const subMenuItems = [];
    if (enableExport) {
      subMenuItems.push({
        icon: ExportIcon,
        text: enableContentDownload ? 'Download Content Accuracy' : 'Add to Download List',
        eventName: 'downloadEntityGrid'
      });
    }

    if (enableContentDownload) {
      subMenuItems.push({
        icon: InventoryIcon,
        text: 'Download Master Content',
        eventName: 'downloadContentCombine'
      });

      subMenuItems.push({
        icon: ContentCurrentIcon,
        text: 'Download Live Content',
        eventName: 'downloadCurrentContent'
      });
    }

    if (enableComparison) {
      subMenuItems.push({
        icon: CompareScaleIcon,
        text: 'Compare',
        eventName: 'showCompare'
      });
    }

    widgets.push({
      CustomComponent: PageContextMenu,
      name: 'pageContextMenu',
      view: {
        name: 'pageContextMenu',
        buttons: subMenuItems
      }
    });
  }

  // Don't add subtab navigation/header if we're on other pages
  if (layoutName !== 'summaryPage') {
    widgets.unshift({
      name: 'subtabNavigation',
      CustomComponent: SubtabNavigation,
      view: {}
    });
    widgets.unshift({
      name: 'subtabHeader',
      CustomComponent: SubtabHeader,
      view: {}
    });
  }

  return {
    CustomPageContainer,
    enableComparison,
    widgets,
    dataConfig,
    containerStyle: { marginTop: 60 },
    ...rest
  };
}

export default { getLayoutForEntity };
