import './AdOptimizationHistory.scss';

import { AxiosResponse } from 'axios';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
// import _isNil from 'lodash/isNil';
import _omit from 'lodash/omit';
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { withBus } from 'react-bus';
import { connect } from 'react-redux';
import { AD_PLATFORM_SETTING_TYPE, IAdCampaign } from 'sl-ad-campaign-manager-data-model';
import { AdManagerAdCampaignEntity } from 'sl-api-connector';
import FeaturedProductsGrid from 'src/components/AdCampaignBuilder/Widgets/FeaturedProductsGrid';
import InstacartProductGrid from 'src/components/AdCampaignBuilder/Widgets/InstacartProductGrid';
import { buildFiltersFromCampaignProducts } from 'src/components/AdManager/AdTargeting';
import SubmitScreen from 'src/components/AdManager/SubmitScreen/SubmitScreen';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { buildAdvancedSearchSidebarWidget } from 'src/components/Layout/Advertising/AdCampaignBuilderPageLayout/AdCampaignBuilderPageLayout';
import { store } from 'src/main';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { getAdPlatformSettingsOfType } from 'src/store/modules/adManager/adCampaignBuilder/selectors';
import { adPlatformSettingsOperations } from 'src/store/modules/adManager/adPlatformSettings';
import { fetchEntity } from 'src/store/modules/entityService/operations';
import ReduxStore from 'src/types/store/reduxStore';
import { SelectedFeaturedProductsItem } from 'src/types/store/storeTypes';
import { AdCampaignManagerServiceClient } from 'src/utils/adCampaignManagerServiceClient';
import { filterNils, propEq } from 'src/utils/fp';
import { useBus } from 'src/utils/Hooks';
import { panic } from 'src/utils/mixpanel';

const ConnectedAdvancedSearchSidebar = buildAdvancedSearchSidebarWidget('featured')!.CustomComponent!;

const reHydrateReduxStateForCampaign = (campaignEntity: AdManagerAdCampaignEntity, parentEntity, isAdGroup = false) => {
  if (!campaignEntity) {
    return;
  }

  let campaignType = getAdPlatformSettingsOfType(
    AD_PLATFORM_SETTING_TYPE.CAMPAIGN_TYPE,
    campaignEntity.platformType
  )(store.getState() as ReduxStore).find(propEq('id', campaignEntity.extendedAttributes.campaignType));

  if (isAdGroup) {
    campaignType = {
      ...campaignEntity,
      settingId: campaignEntity.extendedAttributes.campaignType,
      settingType: AD_PLATFORM_SETTING_TYPE.CAMPAIGN_TYPE,
      name: campaignEntity.extendedAttributes.name
    };
  }

  if (!campaignType) {
    panic(
      `Unable to find campaignType adPlatformSetting with id ${campaignEntity.extendedAttributes.campaignType} for campaign id ${campaignEntity.extendedAttributes.campaignId}`
    );
  }

  const newState = {
    platformSettings: {
      entity: parentEntity
    },
    featured: {
      ...buildFiltersFromCampaignProducts(campaignEntity.adCampaignProducts || []),
      productsForStoreStacklineSkus: 'TODO',
      existingFeaturedProductIds: filterNils(
        (campaignEntity.adCampaignProducts || []).map((product) =>
          _get(product, ['extendedAttributes', 'productMetaData', 'stacklineSku'], undefined)
        )
      ),
      selectedFeaturedProducts: [] as SelectedFeaturedProductsItem[],
      selectedFeaturedUPCs: []
    },
    target: {
      filters: {
        isDefault: true,
        termFilters: {},
        rangeFilters: {}
      }
    },
    campaignType
  };

  store.dispatch(adCampaignBuilderActions.replaceState(newState as any));

  // eslint-disable-next-line consistent-return
  return newState;
};

const mapStateToProps = (state: ReduxStore) => {
  const props = {
    adPlatformSettingsByClient: state.adPlatformSettingsByClient,
    adCampaignBuilder: state.adCampaignBuilder,
    adEntities: state.adEntities,
    retailer: state.retailer,
    mainEntity: state.entityService.mainEntity, // adGroup or adCampaign
    campaign: state.entityService.mainEntity, // default: we are on campaign page
    adPlatformSettingsLoaded: !_isEmpty(state.adPlatformSettings),
    existingFeaturedProductIds: state.adCampaignBuilder.featured.existingFeaturedProductIds
  };

  if (props.mainEntity) {
    if (props.mainEntity.type === 'adGroup' && state.entityService.campaignForAdGroup) {
      props.campaign = state.entityService.campaignForAdGroup;
    }
  }

  return props;
};

const AddProductsToCampaign: React.FC<{ adGroupId?: any; eventBus: any } & ReturnType<typeof mapStateToProps>> = ({
  retailer,
  adGroupId,
  eventBus,
  mainEntity,
  adPlatformSettingsLoaded,
  existingFeaturedProductIds,
  campaign,
  adEntities,
  adCampaignBuilder,
  adPlatformSettingsByClient
}) => {
  const expectedExistingFeaturedProductIds = useRef<(string | number)[] | null | undefined>(null);
  const [submitStatus, setSubmitStatus] = useState<{
    status: 'normal' | 'submitted' | 'submitting' | 'failure';
    result?: AxiosResponse | null;
  }>({ status: 'normal', result: null });

  useEffect(() => {
    eventBus.emit('productPageButtonStatus', submitStatus);
  }, [eventBus, submitStatus]);

  useEffect(() => {
    if (!adPlatformSettingsLoaded) {
      store.dispatch(adPlatformSettingsOperations.fetchAdPlatformSettings());
    }
  }, [adPlatformSettingsLoaded]);

  useEffect(() => {
    const enableComponentFor = ['adCampaign', 'adGroup'];
    if (!mainEntity || !enableComponentFor.includes(mainEntity.type) || !adPlatformSettingsLoaded) {
      return;
    }

    const campaignEntity = mainEntity as AdManagerAdCampaignEntity; // can be bot

    if (mainEntity.type === 'adGroup') {
      store.dispatch(
        fetchEntity('campaignForAdGroup', 'adCampaign', mainEntity.campaignId, mainEntity.extendedAttributes.retailerId)
      );
    }

    if (campaignEntity) {
      const parentEntity = adEntities.find((entity) => entity.id === campaignEntity.extendedAttributes.entityId) || {};
      const newFeaturedState = reHydrateReduxStateForCampaign(
        campaignEntity,
        parentEntity,
        mainEntity.type === 'adGroup'
      );

      if (!newFeaturedState) {
        return;
      }

      // We wait for Redux to update to contain our new state before proceeding to render the featured products grid.
      //
      // We can't let it know what kind of horrible twisted reality it's living in.
      expectedExistingFeaturedProductIds.current = newFeaturedState.featured.existingFeaturedProductIds;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainEntity, adPlatformSettingsLoaded]);

  useEffect(() => {
    const campaignEntity = campaign as AdManagerAdCampaignEntity;
    const parentEntity = adEntities.find((entity) => entity.id === campaignEntity.extendedAttributes.entityId) || {};
    if (campaignEntity && mainEntity) {
      const newFeaturedState = reHydrateReduxStateForCampaign(
        campaignEntity,
        parentEntity,
        mainEntity.type === 'adGroup'
      );
      if (!newFeaturedState) {
        return;
      }

      // We wait for Redux to update to contain our new state before proceeding to render the featured products grid.
      //
      // We can't let it know what kind of horrible twisted reality it's living in.
      expectedExistingFeaturedProductIds.current = newFeaturedState.featured.existingFeaturedProductIds;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign, mainEntity]);

  const handleSubmit = async () => {
    setSubmitStatus({ status: 'submitting' });
    const campaignEntity = mainEntity as AdManagerAdCampaignEntity;
    const campaignEntityModified: IAdCampaign = _omit(campaignEntity, [
      'type',
      'displayName',
      'id',
      'entity',
      'adTargets',
      'adCampaignProducts'
    ]) as Omit<IAdCampaign, 'type' | 'displayName' | 'id' | 'entity' | 'adTargets' | 'adCampaignProducts'> & {
      adCampaignProducts: IAdCampaign['adCampaignProducts'] | undefined;
    };

    const {
      beaconClientId,
      platformType,
      campaignId,
      extendedAttributes: { retailerId, beaconClientLoginId, entityId, entityIdApi }
    } = campaignEntity;
    campaignEntityModified.adCampaignProducts = (
      store.getState() as ReduxStore
    ).adCampaignBuilder.featured.selectedFeaturedProducts.map(({ retailerSku }) => {
      return {
        beaconClientId,
        platformType,
        campaignId,
        adGroupId,
        retailerId,
        retailerSku,
        extendedAttributes: {
          beaconClientLoginId,
          retailerId,
          entityId,
          entityIdApi
        }
      };
    });
    if (['2', '63', '25'].includes(retailer.id)) {
      campaignEntityModified.adCampaignProducts = (
        store.getState() as ReduxStore
      ).adCampaignBuilder.featured.selectedFeaturedUPCs.map((item) => {
        return {
          beaconClientId,
          platformType,
          adGroupId,
          retailerId: parseInt(retailer.id, 10),
          retailerSku: item,
          status: 'enabled',
          startDate: null,
          endDate: null,
          extendedAttributes: {
            bid: 1,
            status: 'enabled'
          }
        };
      });
    }
    const submitResult = await AdCampaignManagerServiceClient.createCampaignProducts({
      adCampaign: campaignEntityModified
    });
    if (submitResult && submitResult.status === 200) {
      setSubmitStatus({ status: 'submitted', result: submitResult });
    } else {
      setSubmitStatus({ status: 'failure', result: submitResult });
    }
  };

  useBus(eventBus, 'submitAddNewFeaturedProducts', handleSubmit);

  if (
    existingFeaturedProductIds !== expectedExistingFeaturedProductIds.current ||
    !mainEntity ||
    !['adCampaign', 'adGroup'].includes(mainEntity.type)
  ) {
    return <GridLoading />;
  }

  if (submitStatus.status === 'submitted') {
    return (
      <div className="targeting_container">
        <SubmitScreen respondResult={submitStatus.result} />
      </div>
    );
  }

  const clientName = _get(
    adPlatformSettingsByClient.find((item) => item.settingType === 'clientName'),
    ['settingId'],
    ''
  );
  return (
    <div className="targeting_container" style={{ display: 'flex', flexDirection: 'column' }}>
      {['63', '2', '25'].includes(retailer.id) ? (
        <div className="ad-manager-container">
          <InstacartProductGrid
            adCampaignBuilder={adCampaignBuilder}
            retailer={retailer}
            mainEntity={{ companyName: clientName }}
          />
        </div>
      ) : (
        <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'column' }}>
          <ConnectedAdvancedSearchSidebar />
          {/* <PillFilters /> */}
          <FeaturedProductsGrid
            selectedAdGroup={adGroupId}
            gridStyle={{ flex: 1 }}
            gridContainerStyle={{ height: 720 }}
          />
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(withBus('eventBus')(AddProductsToCampaign));
