import React, { Fragment, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { authOperations } from 'src/store/modules/auth';
import * as appOperations from 'src/store/modules/app/operations';
import { trackLogOut } from 'src/utils/mixpanel';
import { withPush } from 'src/utils/hoc';
import './AccountMenuPopover.scss';

const AccountMenuPopoverRow = ({ i, text, onClick }) => (
  <div className="index-menu-popover__row" onClick={onClick} role="button" tabIndex={i}>
    {text}
  </div>
);

AccountMenuPopoverRow.propTypes = {
  i: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

/**
 * Invisible backdrop that displays across the entire page and intercepts click events on every element.  This allows
 * us to hide the popover whenever the user clicks off of it.
 *
 * @param {object} props
 */
export const PopoverBackdrop = ({ onClick }) => <div className="popover-backdrop" aria-hidden onClick={onClick} />;

PopoverBackdrop.propTypes = {
  onClick: PropTypes.func.isRequired
};

const AccountMenuPopover = ({ push, fetchConfig, logOut, clearReduxState, onClose }) => {
  const doLogOut = useCallback(
    () =>
      logOut()
        .then(() => {
          trackLogOut();
          fetchConfig();
          push('/sign_in');
        })
        .then(() => {
          clearReduxState();
        })
        .catch((err) => {
          throw err;
        }),
    [push, fetchConfig, logOut, clearReduxState]
  );

  const items = useMemo(
    () =>
      [
        ['My Account', () => push('/account/profile')],
        // ['Notifications', () => console.log('TODO')],
        ['Log Out', doLogOut]
      ].map(([text, action]) => [
        text,
        () => {
          onClose();
          action();
        }
      ]),
    [doLogOut, push, onClose]
  );

  return (
    <Fragment>
      <PopoverBackdrop onClick={onClose} />
      <div className="index-menu-popover">
        {items.map(([text, action], i) => <AccountMenuPopoverRow i={i} text={text} onClick={action} key={text} />)}
        <div className="popover-triangle" />
      </div>
    </Fragment>
  );
};

AccountMenuPopover.propTypes = {
  push: PropTypes.func.isRequired,
  fetchConfig: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  clearReduxState: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = () => ({}); // TODO

const mapDispatchToProps = {
  logOut: authOperations.logOut,
  fetchConfig: appOperations.fetchConfig,
  clearReduxState: appOperations.clearReduxState
};

const EnhancedAccountMenuPopover = withPush(connect(mapStateToProps, mapDispatchToProps)(AccountMenuPopover));

export default EnhancedAccountMenuPopover;
