import { combineReducers } from 'redux';
import * as authTypes from './modules/auth/types';

// Modularized  redux files
import app from './modules/app';
import auth from './modules/auth';
import entityService from './modules/entityService';
import filters from './modules/filters';
import navSidebar from './modules/navSidebar';
import comparisonTimePeriod from './modules/comparison-time-period';
import retailer, { retailerTypes } from './modules/retailer';
import parentPlatform from './modules/parentPlatform';
import mainTimePeriod from './modules/main-time-period';
import segments from './modules/segments';
import allWeekIdsByRetailerId from './modules/allWeekIdsByRetailerId';
import entitySearchService from './modules/entitySearchService';
import brandsFollowing from './modules/brands';
import { categories, categoriesByRetailerId, allSuperUserCategories } from './modules/categories';
import subCategories, { allSuperUserSubCategories } from './modules/subcategories';
import promoTypes from './modules/promoTypes';
import chargeBackIssueTypes from './modules/chargeBackTypes';
import vendorCodes from './modules/vendorCodes';
import chargeBackStatuses from './modules/chargeBackStatus';
import omniBrandsFollowing from 'src/store/modules/omni/omniBrands';
import omniCountriesFollowing from 'src/store/modules/omni/omniCountries';
import omniRegionsFollowing from 'src/store/modules/omni/omniRegions';
import omniCategories from 'src/store/modules/omni/omniCategories';
import omniSubcategories from 'src/store/modules/omni/omniSubcategories';
import { omniRetailers, omniInstacartRetailers } from 'src/store/modules/omni/omniRetailers';
import omniEntity from 'src/store/modules/omni/omniEntity';
import omniDynamicFilters from 'src/store/modules/omni/omniDynamicFilter';
import omniChartsService from 'src/store/modules/omni/omniChartService';
import omniProductService from 'src/store/modules/omni/omniProductService';
import omniGeoMapService from 'src/store/modules/omni/omniGeoMap';
import omniSearchService from 'src/store/modules/omni/omniSearchService';
import omniShareOfShelfShardsService from 'src/store/modules/omni/omniShareOfShelfShardsService';
import omniSegmentService from 'src/store/modules/omni/omniSegment';
import omniKeywordSegmentService from 'src/store/modules/omni/omniKeywordList';
import omniStoreSegmentService from 'src/store/modules/omni/OmniStoreList';
import omniRetailerContentConfigureService from 'src/store/modules/omni/omniRetailerContentScoreConfigure';
import omniAccountSettingService from 'src/store/modules/omni/omniAccountSetting/reducers';
import omniKeywordCategoriesService from 'src/store/modules/omni/omniKeywordCategories/reducers';
import user from './modules/user';
import platforms from './modules/platforms';
import genericForm from './modules/genericForm';
import adCampaignBuilder from './modules/adManager/adCampaignBuilder';
import { adPlatforms } from './modules/adManager/adPlatforms';
import { adPortfolios } from './modules/adManager/adPortfolios';
import adCampaignsReducer from './modules/adManager/adCampaigns/reducers';
import adGroupsReducer from './modules/adManager/adGroups/reducers';
import adScheduledActionGroups from './modules/adManager/adScheduledActionGroups/reducers';
import { adEntities } from './modules/adManager/adEntities';
import adBudgetEditor from './modules/adManager/adBudgetEditor';
import { adPlatformSettings } from './modules/adManager/adPlatformSettings';
import { adPlatformSettingsByClient } from './modules/adManager/adPlatformSettingsByClient';
import { adBusinessUnits } from './modules/adManager/adBusinessUnits';
import { adScores } from './modules/adManager/adScores';
import { adAutomationStrategies } from './modules/adManager/adAutomationStrategies';
import { adCampaignTypes } from './modules/adManager/adCampaignTypes';
import { adCampaignStates } from './modules/adManager/adCampaignStates';
import { adTargetMatchTypes } from './modules/adManager/adTargetMatchTypes';
import { adTargetStates } from './modules/adManager/adTargetStates';
import { adCampaignProductStates } from './modules/adManager/adCampaignProductStates';
import { adPortfolioStates } from 'src/store/modules/adManager/adPortfolioStates';
import swappableGridReducer from './modules/swappableGrid/reducer';
import scratchReducer from './modules/scratch/reducer';
import ReduxStore from 'src/types/store/reduxStore';
import { ReduxAction } from 'src/types/application/types';

const makeRootReducer = combineReducers({
  allWeekIdsByRetailerId,
  app,
  entitySearchService,
  auth,
  brandsFollowing,
  categories,
  comparisonTimePeriod,
  entityService,
  filters,
  mainTimePeriod,
  navSidebar,
  platforms,
  parentPlatform,
  promoTypes,
  chargeBackIssueTypes,
  vendorCodes,
  chargeBackStatuses,
  retailer,
  segments,
  subCategories,
  allSuperUserSubCategories,
  categoriesByRetailerId,
  allSuperUserCategories,
  user,
  genericForm,
  adCampaigns: adCampaignsReducer,
  adGroups: adGroupsReducer,
  adScheduledActionGroups,
  adAutomationStrategies,
  adCampaignStates,
  adPortfolioStates,
  adCampaignTypes,
  adTargetStates,
  adTargetMatchTypes,
  adCampaignProductStates,
  adCampaignBuilder,
  adEntities,
  adPortfolios,
  adPlatforms,
  adPlatformSettings,
  adPlatformSettingsByClient,
  adBusinessUnits,
  adScores,
  adBudgetEditor,
  swappableGrid: swappableGridReducer,
  scratch: scratchReducer,
  omniBrandsFollowing,
  omniCountriesFollowing,
  omniRegionsFollowing,
  omniCategories,
  omniSubcategories,
  omniRetailers,
  omniInstacartRetailers,
  omniRetailerContentConfigureService,
  omniDynamicFilters,
  omniEntity,
  omniChartsService,
  omniProductService,
  omniGeoMapService,

  omniSearchService,
  omniShareOfShelfShardsService,
  omniSegmentService,
  omniKeywordSegmentService,
  omniStoreSegmentService,
  omniAccountSettingService,
  omniKeywordCategoriesService
});

const rootReducers = (state: ReduxStore, action: ReduxAction) => {
  // These are the objects we want to hold on to after clearing
  if (action.type === authTypes.SIGN_OUT_SUCCESS) {
    state = { app: state.app } as any;
  }

  if (action.type === retailerTypes.SET_RETAILER) {
    state = { ...state, entitySearchService: { isFetching: false as any } } as ReduxStore;
  }

  return makeRootReducer(state, action);
};

export default rootReducers;
