import React, { useState, useEffect, useMemo } from 'react';
import { AppTable } from '@stackline/ui';
import getConnectedColumnDefinitions from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeTableColumnDefinitions';
import { withRouter } from 'react-router';
import _cloneDeep from 'lodash/cloneDeep';
import './DisputeManagement.scss';
import { UploadFileModal } from 'src/components/ShortageDisputes/components/DisputeManagement/FileUploadModal';
import Loading from 'src/components/common/Loading';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AppIconButton from 'src/components/Omni/OmniGeoMap/AppIconButton';
import { ResponseForDisputeTable } from 'src/components/ShortageDisputes/responseTypes';
import { makeStyles } from '@mui/styles';
import colors from 'src/utils/colors';
import { formatToDollar } from 'src/utils/money';
import { formatDateForDisputeTable } from 'src/utils/dateUtils';

const useStyles = makeStyles(() => ({
  styleOverrides: {
    '& .MuiTableCell-head': {
      textTransform: 'none',
      fontSize: '18px',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: '#052849'
    },
    '& .MuiTableCell-body': {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.5',
      letterSpacing: 'normal',
      color: '#052849'
    }
  }
}));

interface DisputeDetailsTableProps {
  rawData: any;
  isLoading: boolean;
  history: any;
  location: any;
  setSelectedDisputes: (selectedDisputes: Row[]) => Row[];
  isSubmitting: boolean;
  pageNumber: number;
  setPageNumber: (page: any) => void;
  maxPageSize?: string | number;
}

export interface Dispute {
  originalInvoice: string;
  invoiceDate: string;
  disputeType: string;
  disputeValue: string;
  disputeStage: string;
  disputeStatus: string;
  purchaseOrder: string;
  disputeId: string;
  asin: string[];
  submittedValue: string;
  recoveredValue: string;
  lostValue: string;
  trackingNumbers: string[];
  arn: string;
  carrier: string;
}

export interface Row extends Dispute {
  index: number;
  isChecked: boolean;
  isSubmitting: boolean;
}

const formatCollectionsForRow = (arrayOfStrings: string[]) => {
  return arrayOfStrings.slice(0, 4).join(',').replaceAll(',', ', ');
};

const cleanRawDataForTable = (rawResponse): Dispute => {
  return {
    originalInvoice: rawResponse.originalInvoice,
    invoiceDate: formatDateForDisputeTable(rawResponse.invoiceDate),
    disputeType: 'Shortage',
    disputeValue: formatToDollar(rawResponse.disputeValue),
    disputeStage: rawResponse.disputeStage,
    disputeStatus: rawResponse.disputeStatus,
    purchaseOrder: rawResponse.purchaseOrder,
    disputeId: rawResponse.disputeId,
    asin: formatCollectionsForRow(rawResponse.asin),
    submittedValue: formatToDollar(rawResponse.submittedValue),
    recoveredValue: formatToDollar(rawResponse.recoveredValue),
    lostValue: formatToDollar(rawResponse.lostValue),
    trackingNumbers: formatCollectionsForRow(rawResponse.trackingNumbers),
    arn: rawResponse.arn,
    carrier: rawResponse.carrier
  };
};

const DisputeDetailsTable: React.FC<DisputeDetailsTableProps> = ({
  setSelectedDisputes,
  rawData,
  isLoading,
  isSubmitting,
  pageNumber,
  setPageNumber,
  history,
  location,
  maxPageSize = 10
}) => {
  const [uploadDocumentsModalOpen, setUploadDocumentsModalOpen] = useState<boolean>(false);
  const [selectedDisputeForFileUpload, setSelectedDisputeForFileUpload] = useState<Row>(null);
  const [rows, setRows] = useState<Row[]>([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const handleUploadFileClick = (rowData: Row) => {
    setUploadDocumentsModalOpen(!uploadDocumentsModalOpen);
    setSelectedDisputeForFileUpload(rowData);
  };

  const handleUploadModalClose = () => {
    setSelectedDisputeForFileUpload(null);
    setUploadDocumentsModalOpen(!uploadDocumentsModalOpen);
  };

  const handleDetailsPageClick = (invoiceId: string) => {
    const searchParams = location.search;
    const disputesIndex = searchParams.indexOf('disputes');
    let modifiedUrl = '';
    if (disputesIndex !== -1) {
      modifiedUrl = `${searchParams.substring(0, disputesIndex + 'disputes'.length)}&invoiceId=${invoiceId}`;
      history.push(modifiedUrl);
    }
  };

  const formatDataForRows = () => {
    if (rawData) {
      const newRows = rawData.combinedDataSet.map((dispute: ResponseForDisputeTable, index: number) => ({
        ...cleanRawDataForTable(dispute),
        isChecked: false,
        isSubmitting: false,
        index
      }));
      // We use an additional loading state to avoid flashing "No Data Available" while formatting
      setLoading(false);
      setRows(newRows);
    }
  };

  const updateRowsToSubmitInProgress = () => {
    if (rows.length > 0) {
      const newRows = _cloneDeep(rows);
      newRows.forEach((row) => {
        if (row.isChecked) {
          row.isSubmitting = !row.isSubmitting;
        }
      });
      setRows(newRows);
    }
  };

  useEffect(() => {
    formatDataForRows();
  }, [rawData]);

  useEffect(() => {
    updateRowsToSubmitInProgress();
  }, [isSubmitting]);

  /**
   * Takes a row index, creates a copy of the selected row and all rows and inserts it with the updated state.
   * @param {number} rowIndex the index of the select row
   */
  const selectRow = (rowIndex: number) => {
    const selectedRow = rows[rowIndex];
    const updatedRow = _cloneDeep(selectedRow);
    const newRowCollection = _cloneDeep(rows);
    updatedRow.isChecked = !updatedRow.isChecked;
    newRowCollection.splice(rowIndex, 1, updatedRow);
    setRows(newRowCollection);
  };

  const toggleSelectAllForSubmit = (toggleAll: boolean) => {
    const newRowCollection = _cloneDeep(rows);
    newRowCollection.forEach((row) => {
      if (row.disputeStatus === 'Ready to Submit') {
        row.isChecked = toggleAll;
      }
    });
    setRows(newRowCollection);
  };

  const getSelectedRows = () => {
    const allSelectedRows = rows.filter((row) => row.isChecked);
    setSelectedDisputes(allSelectedRows);
  };

  const { COLUMN_DEFINITIONS, EXPANDED_COLUMN_DEFINITIONS } = useMemo(() => {
    return getConnectedColumnDefinitions({
      selectRow,
      toggleSelectAllForSubmit,
      handleDetailsPageClick,
      handleUploadFileClick,
      isSubmitting
    });
  }, [rows, isSubmitting]);

  useMemo(() => {
    getSelectedRows();
  }, [rows]);

  const disputeTable = useMemo(() => {
    return (
      <AppTable
        className={classes.styleOverrides}
        loading={isLoading || loading}
        rows={rows}
        columns={COLUMN_DEFINITIONS}
        expandRowDef={EXPANDED_COLUMN_DEFINITIONS}
        pageSize={maxPageSize}
        noDataPlaceholder={<h2>No Data Available</h2>}
        rowStyle={{ height: '75px' }}
        hidePagination
      />
    );
  }, [maxPageSize, isLoading, loading, rows, COLUMN_DEFINITIONS]);

  // Conditions for disabling our change-page buttons
  const disableLeftClick = isLoading || pageNumber === 1;
  const disableRightClick = isLoading || rows.length < 10;
  return (
    <>
      {isSubmitting ? (
        <Loading
          style={{
            position: 'absolute',
            left: '55%',
            top: '29%',
            display: 'flex',
            height: '100px',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          className="spinner"
          size={200}
          thickness={2}
        />
      ) : null}

      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '800px' }}>
        {disputeTable}

        <div style={{ textAlign: 'right' }}>
          <AppIconButton
            onClick={() => {
              setPageNumber(Math.max(1, pageNumber - 1));
            }}
            disabled={disableLeftClick}
          >
            <ChevronLeftIcon sx={{ color: disableLeftClick ? colors.borderGrey : colors.darkBlue }} />
          </AppIconButton>
          <span>Page {pageNumber}</span>
          <AppIconButton
            disabled={disableRightClick}
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
          >
            <ChevronRightIcon sx={{ color: disableRightClick ? colors.borderGrey : colors.darkBlue }} />
          </AppIconButton>
        </div>
      </div>
      {selectedDisputeForFileUpload ? (
        <UploadFileModal
          selectedDisputeForFileUpload={selectedDisputeForFileUpload}
          open={uploadDocumentsModalOpen}
          onClose={handleUploadModalClose}
        />
      ) : null}
    </>
  );
};

export default withRouter(DisputeDetailsTable);
