/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { store } from 'src/main';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import queryString from 'qs';
import _uniq from 'lodash/uniq';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import { CrossIcon, EditIcon } from 'src/components/SvgIcons';
import { deleteScheduleActions } from 'src/store/modules/adManager/adScheduledActionGroups/selectors';
import _get from 'lodash/get';
import { withPush } from 'src/utils/hoc';
import _cloneDeep from 'lodash/cloneDeep';
import ReduxStore from 'src/types/store/reduxStore';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import {
  fetchScheduledActionGroups,
  fetchScheduledActionGroupsByEntity
} from 'src/store/modules/adManager/adScheduledActionGroups/operations';
import { NoScheduledAction } from 'src/components/Grids/Data/NoResultTemplate';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import Dialog from '@mui/material/Dialog';
import './scheduledActions.scss';

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['adScheduledActionGroups', 'app'])
});

const TitleColumn = ({ data }: { data: any }) => {
  const name = _get(data, ['name']);
  return name;
};

const ActionColumn = ({ data }: { data: any }) => {
  const action = _get(data, ['scheduleDetail', 'action']);
  const period = _get(data, ['scheduleDetail', 'period']);
  return (
    <div className="scheduled_action_cell">
      <div>{action}</div> <div className="period">{period}</div>
    </div>
  );
};

const PlanColumn = ({ data }: { data: any }) => {
  const planStr = _get(data, ['scheduleDetail', 'plan']);
  return (
    <div className="scheduled_action_cell">
      <div className="plan">{planStr}</div>
    </div>
  );
};

const DatesColumn = ({ data }: { data: any }) => {
  const startDate = _get(data, ['scheduleDetail', 'startTime']);
  const endDate = _get(data, ['scheduleDetail', 'endTime']);
  return (
    <div className="scheduled_action_cell">
      <div className="plan">{`${startDate} to ${endDate}`}</div>
    </div>
  );
};

const EntityCountColumn = ({ data }: { data: any }) => {
  const isSummarized = _get(data, 'isSummarized');
  if (isSummarized) {
    return _get(data, 'scheduleDetail.appliesTo', '');
  }
  const saEntities = _get(
    data,
    ['scheduledActions', 0, 'scheduledAction', 'extendedAttributes', 'adUpdateRequest', 'extendedAttributes'],
    []
  );
  const { adTargets, adCampaignProducts, adCampaigns, adCampaignsBudgetSetting } = saEntities;
  let renderValue = '';
  if (adTargets && adTargets.length > 0) {
    const targetIds = _uniq(adTargets.map((item) => item.targetId));
    renderValue = `${targetIds.length} ${targetIds.length > 1 ? 'targets' : 'target'}`;
  }
  if (adCampaignProducts && adCampaignProducts.length > 0) {
    const pruductSkus = _uniq(adCampaignProducts.map((item) => item.stacklineSku));
    renderValue = `${pruductSkus.length} ${pruductSkus.length > 1 ? 'products' : 'product'}`;
  }
  if (adCampaigns && adCampaigns.length > 0) {
    const campaignIds = _uniq(adCampaigns.map((item) => item.campaignId));
    renderValue = `${campaignIds.length} ${campaignIds.length > 1 ? 'campaigns' : 'campaign'}`;
  }
  if (adCampaignsBudgetSetting && adCampaignsBudgetSetting.length > 0) {
    const campaignIds = _uniq(adCampaignsBudgetSetting.map((item) => item.campaignId));
    renderValue = `${campaignIds.length} ${campaignIds.length > 1 ? 'campaigns' : 'campaign'}`;
  }
  return (
    <div className="scheduled_action_cell">
      <div className="plan">{renderValue}</div>
    </div>
  );
};

const OperationColumn = connect(mapStateToProps)(
  withPush(({ app, push, data, colDef }: { push: any }) => {
    const [open, setOpen] = useState(false);
    const saEntities = _get(
      data,
      ['scheduledActions', 0, 'scheduledAction', 'extendedAttributes', 'adUpdateRequest', 'extendedAttributes'],
      []
    );
    const { adTargets, adCampaignProducts, adCampaigns, adCampaignsBudgetSetting } = saEntities;
    let groupByField = '';
    if (adTargets && adTargets.length > 0) {
      groupByField = 'targetingText';
    }
    if (adCampaignProducts && adCampaignProducts.length > 0) {
      groupByField = 'stacklineSku';
    }
    if ((adCampaigns && adCampaigns.length > 0) || (adCampaignsBudgetSetting && adCampaignsBudgetSetting.length > 0)) {
      groupByField = 'campaignId';
    }
    const onEditClick = () => {
      const { searchParams } = app.queryParams;
      push(
        `/home${searchParams}&tab=adScheduledActions&subtab=edit&currentScheduledAction=${data.id}&groupByField=${groupByField}`
      );
    };

    const onDeleteClick = async () => {
      const request = data.scheduledActions.map((item) => item.scheduledAction);
      // TODO: call api to delete item and the refetch the grid.
      await deleteScheduleActions(request);
      colDef.fetchData();
      setOpen(false);
    };

    return (
      <div className="scheduled_action_cell" style={{ display: 'flex' }}>
        <div role="button" className="operation_button" onClick={onEditClick}>
          <EditIcon fill="#3c4859" width={30} />
        </div>
        &nbsp; &nbsp;
        <div role="button" className="operation_button" onClick={() => setOpen(true)}>
          <CrossIcon width={30} />
        </div>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <div className="confirm_dialog">
            <div className="confirm_title">Confirmation</div>
            <div className="confirm_body">
              Are you sure to delete <strong>{data.name}</strong>?
            </div>
            <div className="confirm_buttons">
              <LargeMuiButton
                style={{ width: 88, height: 35, marginRight: 5 }}
                label="Confirm"
                onClick={onDeleteClick}
              />
              <LargeMuiButton
                secondary
                style={{ width: 88, height: 35, marginLeft: 5, marginRight: 5 }}
                label="Cancel"
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  })
);

const onGridReady = (params: any) => {
  params.api.sizeColumnsToFit();
};

const View = ({ adScheduledActionGroups, push, widget, entity }) => {
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    if (['adCampaign', 'adPortfolio', 'adEntity'].includes(entity.type)) {
      return store.dispatch(fetchScheduledActionGroupsByEntity(entity));
    } else {
      return store.dispatch(fetchScheduledActionGroups(true));
    }
  };

  const columnDefs = [
    {
      headerName: 'Name',
      field: 'Name',
      width: undefined,
      enableRtl: true,
      minWidth: 400,
      maxWidth: 800,
      cellRendererFramework: TitleColumn,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left'
    },
    {
      headerName: 'Action',
      cellRendererFramework: ActionColumn,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left'
    },
    {
      headerName: 'Plan',
      cellRendererFramework: PlanColumn,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left'
    },
    {
      headerName: 'Dates',
      cellRendererFramework: DatesColumn,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left'
    },
    {
      headerName: 'Applies To',
      cellRendererFramework: EntityCountColumn,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left'
    },
    {
      headerName: '',
      cellRendererFramework: OperationColumn,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left',
      fetchData,
      minWidth: 100,
      maxWidth: 100
    }
  ];

  React.useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  const openCreatePage = () => {
    const curParams = queryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const newSearchString = queryString.stringify({ ...curParams, subtab: 'create' });
    push(`?${newSearchString}`);
  };

  const { hideAddButton, customSubHeader } = widget.data;
  const { className } = widget.view;

  const filteredScheduledActionGroups = _cloneDeep(adScheduledActionGroups);

  const actionCount = filteredScheduledActionGroups ? filteredScheduledActionGroups.length : 0;

  return (
    <div className={className || 'scheduled_actions_container'}>
      <div className="header">Scheduled Actions</div>
      {isLoading ? (
        <GridLoading />
      ) : (
        <>
          <div className="subheader">
            {customSubHeader || (
              <>
                <div style={{ paddingBottom: 20 }}>
                  {' '}
                  {`${pluralize('action', actionCount, true)}`} currently scheduled
                </div>
                {!hideAddButton && <LargeMuiButton style={{ width: 99 }} label="Create" onClick={openCreatePage} />}
              </>
            )}
          </div>
          {/* grid */}
          <CustomAgMaterial
            onGridReady={onGridReady}
            onCellValueChanged={onGridReady}
            onModelUpdated={onGridReady}
            onRowValueChanged={onGridReady}
            onRowDataChanged={onGridReady}
            buildRows={() => filteredScheduledActionGroups}
            columnDefs={columnDefs}
            suppressNoRowsOverlay
            // deltaRowDataMode
            getRowNodeId={(datum: any) => escape(datum.id)}
            domLayout="autoHeight"
            rowHeight={120}
            containerStyle={{ width: '100%' }}
          />
          {_isEmpty(filteredScheduledActionGroups) && <NoScheduledAction />}
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(withPush(View));
