import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

export const OMNI_REVIEW_FIELDS: { [key: string]: FieldDefinition } = {
  stars: {
    name: 'stars',
    displayName: 'Avg. Star Rating',
    metricType: METRICTYPE.DECIMAL,
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    overrides: {
      weekId_Override: {
        timePeriodAggregationFunctionType: 'trueAvg',
        timePeriodAggregationFunction: 'avg'
      }
    }
  }
};
