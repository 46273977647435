const RECEIVE_UNFILTERED_CATEGORIES = 'src/categories/RECEIVE_UNFILTERED_CATEGORIES' as const;
const RECEIVE_UNFILTERED_SUPERUSER_CATEGORIES = 'src/categories/RECEIVE_UNFILTERED_SUPERUSER_CATEGORIES' as const;
const RECEIVE_FILTERED_CATEGORIES = 'src/categories/RECEIVE_FILTERED_CATEGORIES' as const;
const CLEAR_CATEGORIES = 'src/categories/CLEAR_CATEGORIES' as const;
const SORT_CATEGORIES = 'src/categories/SORT_CATEGORIES' as const;

const SALES_BY_CATEGORY_STATE_KEY = 'mainEntity__retailSales_by_categoryId' as const;

export default {
  CLEAR_CATEGORIES,
  RECEIVE_FILTERED_CATEGORIES,
  RECEIVE_UNFILTERED_CATEGORIES,
  RECEIVE_UNFILTERED_SUPERUSER_CATEGORIES,
  SORT_CATEGORIES,
  SALES_BY_CATEGORY_STATE_KEY
};
