import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { AD_PLATFORM_SETTING_TYPE, AD_TARGETING_TYPE, IAdPlatformSettings } from 'sl-ad-campaign-manager-data-model';

import { store } from 'src/main';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { adPlatformSettingsOperations } from 'src/store/modules/adManager/adPlatformSettings';
import colors from 'src/utils/colors';
import { useOnce } from 'src/utils/Hooks';
import { getAdPlatformSettingsOfType } from 'src/store/modules/adManager/adCampaignBuilder/selectors';
import { SectionWrapper, OperationButtons } from '../Widgets/AdCampaignBuilderCommonWidgets';
import InfoTooltip from '../Widgets/InfoTooltip';
import { AdCampaignBuilderRadio } from '../Widgets/Checkbox';
import ReduxStore from 'src/types/store/reduxStore';
import { getAppStage } from 'src/utils/app';

const styles: { [key: string]: { [key: string]: any } } = {
  root: {
    padding: '8px 12px',
    color: colors.blue,
    '&$checked': {
      color: colors.blue
    }
  },
  checked: {},
  colorSecondary: {
    '&&&&&:hover': {
      backgroundColor: '#fff'
    }
  }
};

const PickTypeInner: React.FC<{
  classes: { [key: string]: any };
  adTargetingTypes: (IAdPlatformSettings & { id: AD_TARGETING_TYPE })[];
  targetingTypeId: ReduxStore['adCampaignBuilder']['target']['targetingTypeId'];
  // eslint-disable-next-line react/prop-types
}> = ({ classes, adTargetingTypes, targetingTypeId }) => {
  useOnce(() => {
    // if we just have one target type, automatically select it
    if (adTargetingTypes.length === 1) {
      store.dispatch(adCampaignBuilderActions.setTargetTypeId(adTargetingTypes[0].id));
    }
  });

  return (
    <div className="radio-group">
      {adTargetingTypes
        .sort((x, y) => {
          return x.name > y.name ? 1 : -1;
        })
        .map((adTargetingType) => {
          return (
            <div
              key={adTargetingType.id}
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <AdCampaignBuilderRadio
                disableRipple
                checked={targetingTypeId === adTargetingType.id}
                onChange={() => store.dispatch(adCampaignBuilderActions.setTargetTypeId(adTargetingType.id))}
                value={adTargetingType.id}
                classes={{
                  root: classes.root,
                  checked: classes.checked
                }}
              />

              <InfoTooltip
                customNode={
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <label
                    onClick={() => store.dispatch(adCampaignBuilderActions.setTargetTypeId(adTargetingType.id))}
                    className="radio-label"
                  >
                    {adTargetingType.name}
                  </label>
                }
              >
                {adTargetingType.extendedAttributes.description || ''}
              </InfoTooltip>
            </div>
          );
        })}
    </div>
  );
};

const PickType = withStyles(styles)(PickTypeInner);

const mapStateToProps = (state: ReduxStore) => ({
  adCampaignBuilder: state.adCampaignBuilder,
  retailer: state.retailer,
  adPlatformSettings: state.adPlatformSettings,
  adTargetingTypes: getAdPlatformSettingsOfType(AD_PLATFORM_SETTING_TYPE.TARGETING_TYPE)(
    state
  ) as (IAdPlatformSettings & { id: AD_TARGETING_TYPE })[]
});

const TargetType: React.FC<{} & ReturnType<typeof mapStateToProps>> = ({
  // eslint-disable-next-line react/prop-types
  adCampaignBuilder,
  adPlatformSettings,
  adTargetingTypes
}) => {
  const { targetingTypeId } = adCampaignBuilder.target;

  useOnce(() => {
    if (_isEmpty(adPlatformSettings)) {
      store.dispatch(adPlatformSettingsOperations.fetchAdPlatformSettings());
    }
  });

  if (_isEmpty(adPlatformSettings)) {
    return null;
  }

  const isSponsoredDisplay =
    _get(adCampaignBuilder, 'campaignType.settingId', 'sponsoredProducts') === 'sponsoredDisplay';
  const isSBV = _get(adCampaignBuilder, 'campaignType.settingId', 'sponsoredProducts') === 'sponsoredBrandsVideo';
  const isSBA = _get(adCampaignBuilder, 'campaignType.settingId') === 'sba';
  const isDev = getAppStage() === 'dev';
  const targetingTypes = adTargetingTypes.filter((type) => {
    if (isSponsoredDisplay) {
      return type.id === 'productTargeting' || (isDev && type.id === 'audienceTargeting');
    } else if (isSBV || isSBA) {
      return type.id === 'keywordTargeting';
    } else {
      return type.id !== 'audienceTargeting';
    }
  });

  const customFlow =
    targetingTypeId === 'audienceTargeting'
      ? [
          // flow for Sponsored Display
          'platformSelect',
          'setupType',
          'setupDetail',
          'featured',
          'targetType',
          'audienceSettings',
          'confirmation'
        ]
      : undefined;

  return (
    <div className="ad-manager-container">
      <SectionWrapper header="Targeting Type" subheader="Select how you would like to target this campaign." layer={0}>
        <PickType adTargetingTypes={targetingTypes} targetingTypeId={targetingTypeId} />
      </SectionWrapper>
      <br />
      <OperationButtons
        subtab="targetType"
        canContinue={!!targetingTypeId}
        customFlow={customFlow}
        onBeforeLeave={() => {
          if (targetingTypeId === 'audienceTargeting') {
            store.dispatch(
              adCampaignBuilderActions.setTargetAudience([
                { product: 'exactProduct', bid: 1, name: 'Advertised products' },
                { product: 'similarProduct', bid: 1, name: 'Similar to advertised products' }
              ])
            );
          }
          return true;
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps)(TargetType);
