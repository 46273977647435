import React from 'react';
import StyledDialog from 'src/components/BeaconRedesignComponents/common/StyledDialog/StyledDialog';
import TeamUserOptionPopup from '../TeamUserOptionPopup';
import { Text } from '@stackline/ui';
import * as serverProxy from 'src/routes/UserAccount/UserTeamManagement/serverProxy';
import { useAppSelector, useSnackbar } from 'src/utils/Hooks';
import { settingsErrorMessage } from '../../utils';

type AssignAdminRoleModalProps = Omit<React.ComponentProps<typeof StyledDialog>, 'children' | 'width' | 'padding'> & {
  user: {
    email: string;
  };
  refetchData: () => Promise<void>;
};

/**
 * Popup to assign a user the role of Admin.
 */
export default function AssignAdminRoleModal({ user, refetchData, ...rest }: AssignAdminRoleModalProps) {
  const { showSnackbar } = useSnackbar();
  const currentUserEmail = useAppSelector((state) => state.user.config.profile.email);
  const assignAdminRole = async () => {
    try {
      await serverProxy.ChangeUserRoleInCurrentDivision({
        action: 'setAdmin',
        currentEmail: currentUserEmail,
        targetEmail: user.email
      });
      await refetchData();
      showSnackbar({
        type: 'success',
        message: `${user.email}'s role has been changed in the division.`
      });
    } catch (error) {
      showSnackbar({
        type: 'error',
        message: settingsErrorMessage
      });
    }
  };

  return (
    <TeamUserOptionPopup modalTitle="Assign Admin Role" onConfirm={() => assignAdminRole()} {...rest}>
      <Text variant="subtitle2" inline>
        {user.email}
      </Text>{' '}
      will be made an Admin on this account.
    </TeamUserOptionPopup>
  );
}
