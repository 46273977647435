import { SlRow, Text, SlColumn } from '@stackline/ui';
import React from 'react';
import { styled } from '@mui/material/styles';
import { GenericStyledDialogue } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/CreateAdjustmentModal';
import { ForecastsAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import { DeleteAdjustmentParams } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ViewAdjustmentModal/utils';
import { useSnackbar } from 'src/utils/Hooks';
import { AxiosResponse } from 'axios';
import _get from 'lodash/get';
import { settingsErrorMessage } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/utils';
import { usePollingContext } from 'src/providers/PollingContextProvider';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

const DeleteAdjustmentDialogue = styled(GenericStyledDialogue)({
  '& .MuiPaper-root': {
    width: '500px',
    height: '180px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px'
  }
});

export const DeleteAdjustmentModal = ({
  adjustmentToDelete,
  open,
  onClose,
  deleteAdjustment
}: {
  adjustmentToDelete: ForecastsAdjustmentData;
  open: boolean;
  onClose: () => void;
  deleteAdjustment: (payload: DeleteAdjustmentParams) => Promise<AxiosResponse>;
}) => {
  const { adjustmentId, retailerSku, status } = adjustmentToDelete;
  const { showSnackbar } = useSnackbar();

  const { startPolling } = usePollingContext();

  const handleDeleteAdjustment = async () => {
    const pendingDeletePromise = deleteAdjustment({ retailerSku, adjustmentId, isDraft: status === 'Draft' });

    startPolling(
      `DELETE_ADJUSTMENT_${Date.now()}`,
      async () => {
        const response = await pendingDeletePromise;
        const isSuccess = _get(response, ['data', 0, 'isSuccess'], false);
        if (isSuccess) {
          showSnackbar({
            message: 'Your adjustment was successfully deleted.',
            type: 'success',
            timeout: 10000
          });
        } else {
          showSnackbar({
            message: settingsErrorMessage,
            type: 'error',
            timeout: 10000
          });
        }
      },
      { interval: 5000, leading: true, maxTries: 1 }
    );

    onClose();
  };

  return (
    <DeleteAdjustmentDialogue open={open} onClose={onClose}>
      <SlColumn widths={[undefined, undefined, 'full']} spacing="md" verticalInset="mdl" horizontalInset="mdl">
        <SlRow>
          <Text variant="h2">Delete Adjustment</Text>
        </SlRow>
        <Text variant="body2">
          Are you sure you want to delete this adjustment? Once this adjustment is deleted, it cannot be recovered.
        </Text>
        <SlRow spacing="md" horizontalPosition="end">
          <SlButton onClick={onClose}>Cancel</SlButton>
          <SlButton onClick={handleDeleteAdjustment} variant="contained">
            Delete
          </SlButton>
        </SlRow>
      </SlColumn>
    </DeleteAdjustmentDialogue>
  );
};
