import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { AdWalmartService } from 'src/components/AdManager/walmartUtils';
import { useAppSelector } from 'src/utils/Hooks';
import _get from 'lodash/get';
import { MediaData } from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdMediaTable/AdMediaTable';

export function useAdDeleteMedia(): UseMutationResult<void, unknown, number, unknown> {
  const queryClient = useQueryClient();
  const beaconClientLoginId = useAppSelector((state) =>
    _get(state, 'adCampaignBuilder.platformSettings.entity.extendedAttributes.beaconClientLoginId', null)
  );

  return useMutation<void, unknown, number, unknown>(
    (mediaId) => AdWalmartService.deleteMedia({ mediaId, beaconClientLoginId }),
    {
      onSuccess: (data, mediaId) => {
        // On success, use the returned mediaId to update the cache
        queryClient.setQueryData<MediaData[]>(['AdWalmartService_getMedia', beaconClientLoginId, null], (old) => {
          return (old && old.filter((media) => media.mediaId !== mediaId)) || [];
        });
      }
    }
  );
}
