import React, { useState, useMemo } from 'react';
import SegmentNoDataPlaceholder from './SegmentNoDataPlaceholder';
import { SearchTextField } from 'src/components/BeaconRedesignComponents/SearchInput/SearchInputBar';
import { SlColumn, SlRow, useStacklineTheme } from '@stackline/ui';
import { useAppSelector, useDebouncedValue } from 'src/utils/Hooks';
import SegmentCardGrid from './SegmentCardGrid';
import Box from '@mui/material/Box';

interface SegmentsViewTabProps {
  /**
   * True if we are viewing team segments
   */
  team?: boolean;
}

/**
 * Segments tab on the segments page
 */
const SegmentsViewTab = ({ team }: SegmentsViewTabProps) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebouncedValue(search);

  const theme = useStacklineTheme();
  const mySegments = useAppSelector((state) => state.segments.mySegments);
  const teamSegments = useAppSelector((state) => state.segments.teamSegments);
  const segments = useMemo(() => (team ? teamSegments : mySegments), [mySegments, team, teamSegments]);

  const filteredSegments = useMemo(() => {
    return segments.filter((segment) => {
      const displayMatches = segment.displayName.toLowerCase().includes(debouncedSearch.toLowerCase());

      const owner = `${segment.segment.owner.firstName} ${segment.segment.owner.lastName}`;
      const ownerMatches = owner.toLowerCase().includes(debouncedSearch.toLowerCase());

      return displayMatches || ownerMatches;
    });
  }, [debouncedSearch, segments]);

  return (
    <SlColumn widths="full">
      <SearchTextField
        placeholder="Search segments"
        value={search}
        onChange={(event) => {
          const { value } = event.target;
          setSearch(value);
        }}
      />
      <SlRow horizontalPosition="center">
        {filteredSegments.length < 1 && (
          <Box marginTop={theme.spacing.xxl}>
            <SegmentNoDataPlaceholder
              title="No segments found"
              subtitle={`There are currently no segments to display. All ${
                team ? 'team' : 'your'
              } segments will be available on this page.`}
            />
          </Box>
        )}
      </SlRow>
      {filteredSegments.length > 0 && (
        <Box marginTop={theme.spacing.lg} width="100%">
          <SegmentCardGrid segments={filteredSegments} />
        </Box>
      )}
    </SlColumn>
  );
};

export default SegmentsViewTab;
