import ForecastRecommendationsPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastRecommendationsPage';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';

const getForecastRecommendationsPageLayout = () => {
  return {
    CustomPageContainer: GenericPageContainer,
    widgets: [
      {
        name: 'forecastRecommendationsPageLayout',
        CustomComponent: ForecastRecommendationsPage,
        view: {}
      }
    ],
    containerStyle: {},
    dataConfig: {}
  };
};

export default getForecastRecommendationsPageLayout;
