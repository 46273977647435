/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import _constant from 'lodash/constant';
import { withProps } from 'src/utils/hoc';
import { EntityColumn } from 'src/components/Grids/Data/ColumnTypes';
import { MetricField } from 'src/types/application/types';
import { WidgetProps } from 'src/types/application/widgetTypes';
import { useAppSelector, useOnce } from 'src/utils/Hooks';
import { fetchData, mkCellRenderer, parseDataSet, updateGridSize } from './TopFiveGrid';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import 'src/components/EntityGrid/HeaderComponentFrameworks/GridHeaderOverflow.scss';
import HeaderInnerDropdown from './HeaderInnerDropdown';

const TopThreeGrid: React.FC<WidgetProps> = ({ widget, ...widgetProps }) => {
  const { groupByFields } = widget.data;
  // Local state
  const [groupByField, setGroupByField] = useState({ name: groupByFields[0].name } as MetricField);
  const [widgetName, setWidgetName] = useState(`${widget.name}_${groupByField.name}`);
  const [aggregationFields, setAggregationFields] = useState([]);
  const [indexName, setIndexName] = useState('');

  // Redux
  const dataSet = useAppSelector((state) => state.entitySearchService[widgetName]);
  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);
  const app = useAppSelector((state) => state.app);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const retailer = useAppSelector((state) => state.retailer);

  const updateStateForSelectedGroupBy = (newSelectedGroupBy: { name: string }) => {
    const newWidgetName = `${widget.name}_${newSelectedGroupBy.name}`;
    const { aggregationFields: newAggregationFields, indexName: newIndexName } =
      widget.data.configByGroupByFieldName[newSelectedGroupBy.name];
    setWidgetName(newWidgetName);
    setGroupByField(newSelectedGroupBy);
    setAggregationFields(newAggregationFields);
    setIndexName(newIndexName);
  };

  useOnce(() => {
    // init top three chart
    updateStateForSelectedGroupBy(groupByFields[0]);
  });

  useDeepCompareEffect(() => {
    if (dataSet || !aggregationFields || !indexName || !groupByField) {
      return;
    }

    fetchData(
      widgetName,
      groupByField, // groupByField
      indexName,
      { app, retailer, mainTimePeriod, mainEntity: mainEntity! },
      widgetProps.conditions,
      aggregationFields,
      3 // pageSize
    );
  }, [widgetName, mainTimePeriod, retailer, indexName, widgetProps.conditions]);

  const handleGroupByChange = (newField: MetricField) => {
    updateStateForSelectedGroupBy(newField);
  };

  const firstHeaderFramework = () => (
    <HeaderInnerDropdown value={groupByField} groupByFields={groupByFields} handleGroupByChange={handleGroupByChange} />
  );

  const getLinkOverride = ({ type, id, searchParams }) => {
    return `/${type}/${id}${searchParams}`;
  };

  const enhancedEntityColumn = withProps({ getLinkOverride })(EntityColumn);

  const buildColDefs = ([firstCol, ...cols]: MetricField[]): MetricField[] => [
    {
      displayName: firstCol.displayName,
      name: firstCol.name,
      suppressMovable: true,
      cellRendererFramework: enhancedEntityColumn,
      headerComponentFramework: firstHeaderFramework,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 350
    },
    ...cols.map((field) => ({
      ...field,
      maxWidth: 200,
      cellRenderer: mkCellRenderer({ field, retailer })
    }))
  ];

  const colDefs = () => buildColDefs([groupByField, ...aggregationFields]);
  const rows = useMemo(() => {
    return dataSet ? parseDataSet(dataSet, false) : null;
  }, [dataSet]);

  return (
    <div className="top-3-grid">
      <div className="entity-grid-noclip-header">
        {rows ? (
          <CustomAgMaterial
            columnDefs={colDefs()}
            buildRows={_constant(rows)}
            domLayout="autoHeight"
            onGridReady={updateGridSize}
            onCellValueChanged={updateGridSize}
            onModelUpdated={updateGridSize}
            onRowValueChanged={updateGridSize}
            onRowDataChanged={updateGridSize}
          />
        ) : (
          <GridLoading rows={3} />
        )}
      </div>
    </div>
  );
};

const TopThreeGridMemo = React.memo(TopThreeGrid);

export default TopThreeGridMemo;
