import React from 'react';
import { NoDataIcon } from 'src/components/BeaconRedesignComponents/common/NoDataPlaceHolder/NoDataPlaceHolder';
import { SuccessCheckIcon } from 'src/components/SvgIcons';

/**
 * Shows checkmark on background for profile popup
 * success messages
 */
export default function ProfileSuccessIcon() {
  return <NoDataIcon icon={<SuccessCheckIcon />} dimensions={64} />;
}
