import _cloneDeep from 'lodash/cloneDeep';
import { ConvertSeriesToDelimitedDataFunction } from 'src/components/Charts/types';
import _merge from 'lodash/merge';
import React, { useMemo } from 'react';
import GenericChart from 'src/components/Charts/GenericChart';

interface GenericBarChartProps {
  /**
   * Series options for the column data.
   */
  columnData?: Highcharts.SeriesColumnOptions;
  /**
    
    /**
     * Optional chart option overrides.
     */
  chartProps?: Partial<Highcharts.Options>;
  /**
   * GenericChart prop for converting the stacked column data
   * to CSV. Should be given if `exporting.enabled` is true
   * in `chartProps`.
   */
  convertSeriesToDelimitedData?: ConvertSeriesToDelimitedDataFunction;
  /**
   * Second column data to render next to columnData
   */
  secondaryColumnData?: Highcharts.SeriesColumnOptions;
}

const GenericBarChart = ({ columnData, secondaryColumnData, chartProps, ...rest }: GenericBarChartProps) => {
  const mergedChartProps: Highcharts.Options = useMemo(() => {
    const defaultOptions: Highcharts.Options = {
      chart: {
        type: 'column'
      },
      xAxis: [
        _merge(
          {
            labels: {
              formatter() {
                return `${this.value}`;
              },
              style: {
                'font-size': '14px'
              }
            }
          },
          chartProps.xAxis || {}
        )
      ],
      yAxis: [
        _merge(
          {
            labels: {
              style: {
                'font-size': '14px'
              }
            }
          },
          chartProps.yAxis ? chartProps.yAxis : {}
        )
      ],
      plotOptions: {
        barWidth: 130,
        // Shared tooltip isn't enabled for a scatter chart so we
        // render a line chart and make it look like scatter points
        line: {
          animation: false,
          lineWidth: 0,
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 8
          },
          states: {
            hover: {
              lineWidth: 0
            }
          }
        }
      }
    };
    return _merge({}, defaultOptions, chartProps);
  }, [chartProps]);

  const columnSeries: Highcharts.SeriesColumnOptions[] = useMemo(() => {
    const series = [];
    if (columnData) {
      series.push({
        type: 'column',
        yAxis: 0,
        ..._cloneDeep(columnData)
      });
    }
    if (secondaryColumnData) {
      series.push({
        type: 'column',
        yAxis: 0,
        ..._cloneDeep(secondaryColumnData)
      });
    }
    return series;
  }, [columnData]);

  return <GenericChart chartSeries={[...columnSeries]} chartProps={_cloneDeep(mergedChartProps)} {...rest} />;
};

export default GenericBarChart;
