/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import ContentLoader from 'react-content-loader';

import colors from 'src/utils/colors';

const commonParam = {
  ariaLabel: false,
  primaryColor: colors.white,
  secondaryColor: '#ffffff'
};

export const AtlasSummaryMainTrendLoading = () => (
  <ContentLoader height={250} width={320} speed={2} {...commonParam}>
    <rect x="13" y="3.61" rx="4" ry="4" width="134.55" height="33.47" />
    <rect x="13" y="44" rx="3" ry="3" width="135.39" height="21.31" />
    <rect x="13" y="73.61" rx="3" ry="3" width="292.6" height="140.77" />
  </ContentLoader>
);
export const AtlasSummarySubTrendLoading = () => (
  <ContentLoader height={95} width={371} speed={2} {...commonParam}>
    <rect x="28" y="20.61" rx="4" ry="4" width="152.04" height="23" />
    <rect x="194.03" y="20.61" rx="3" ry="3" width="152.15" height="52.38" />
    <rect x="28" y="50" rx="4" ry="4" width="152.04" height="23" />
  </ContentLoader>
);

export const HomePageLoading = () => {
  const randomWidth = (num) => (Math.random() * (1 - 0.6) + 0.6) * num;
  const rowDis = 59;
  return (
    <ContentLoader height={451} width={1478} speed={2} {...commonParam}>
      <rect x="16.39" y="33" rx="4" ry="4" width="678.37" height="379.83" />
      {[...Array(7)].map((x, i) => (
        <React.Fragment key={i}>
          <rect x="800" y={33 + rowDis * i} rx="4" ry="4" width={randomWidth(220)} height="25.65" />
          <rect x="1140" y={33 + rowDis * i} rx="4" ry="4" width={randomWidth(80)} height="25.65" />
          <rect x="1260" y={33 + rowDis * i} rx="4" ry="4" width={randomWidth(80)} height="25.65" />
          <rect x="1380" y={33 + rowDis * i} rx="4" ry="4" width={randomWidth(80)} height="25.65" />
        </React.Fragment>
      ))}
    </ContentLoader>
  );
};

export const CompareBrandListLoading = () => {
  const yPosition = (index) => 25 + index * 50;
  const randomWidth = () => (Math.random() * (1 - 0.7) + 0.7) * 200;
  return (
    <ContentLoader height={1060} width={400} speed={2} {...commonParam}>
      <rect x="30" y={yPosition(0)} rx="5" ry="5" width={randomWidth()} height="17.8" />
      <rect x="30" y={yPosition(1)} rx="5" ry="5" width={randomWidth()} height="17.8" />
      <rect x="30" y={yPosition(2)} rx="5" ry="5" width={randomWidth()} height="17.8" />
      <rect x="30" y={yPosition(3)} rx="5" ry="5" width={randomWidth()} height="17.8" />
      <rect x="30" y={yPosition(4)} rx="5" ry="5" width={randomWidth()} height="17.8" />
      <rect x="30" y={yPosition(5)} rx="5" ry="5" width={randomWidth()} height="17.8" />
      <rect x="30" y={yPosition(6)} rx="5" ry="5" width={randomWidth()} height="17.8" />
      <rect x="30" y={yPosition(7)} rx="5" ry="5" width={randomWidth()} height="17.8" />
      <rect x="30" y={yPosition(8)} rx="5" ry="5" width={randomWidth()} height="17.8" />
      <rect x="30" y={yPosition(9)} rx="5" ry="5" width={randomWidth()} height="17.8" />
    </ContentLoader>
  );
};

export const CompareProductListLoading = () => {
  const yPosition = (index) => 17 + index * 70;
  return (
    <ContentLoader height={1060} width={400} speed={2} {...commonParam}>
      <rect x="89.53" y={yPosition(0)} rx="5" ry="5" width="287.98" height="57.67" />
      <rect x="21" y={yPosition(0)} rx="5" ry="5" width="57.6" height="58.03" />
      <rect x="89.53" y={yPosition(1)} rx="5" ry="5" width="287.98" height="57.67" />
      <rect x="21" y={yPosition(1)} rx="5" ry="5" width="57.6" height="58.03" />
      <rect x="89.53" y={yPosition(2)} rx="5" ry="5" width="287.98" height="57.67" />
      <rect x="21" y={yPosition(2)} rx="5" ry="5" width="57.6" height="58.03" />
      <rect x="89.53" y={yPosition(3)} rx="5" ry="5" width="287.98" height="57.67" />
      <rect x="21" y={yPosition(3)} rx="5" ry="5" width="57.6" height="58.03" />
      <rect x="89.53" y={yPosition(4)} rx="5" ry="5" width="287.98" height="57.67" />
      <rect x="21" y={yPosition(4)} rx="5" ry="5" width="57.6" height="58.03" />
      <rect x="89.53" y={yPosition(5)} rx="5" ry="5" width="287.98" height="57.67" />
      <rect x="21" y={yPosition(5)} rx="5" ry="5" width="57.6" height="58.03" />
      <rect x="89.53" y={yPosition(6)} rx="5" ry="5" width="287.98" height="57.67" />
      <rect x="21" y={yPosition(6)} rx="5" ry="5" width="57.6" height="58.03" />
      <rect x="89.53" y={yPosition(7)} rx="5" ry="5" width="287.98" height="57.67" />
      <rect x="21" y={yPosition(7)} rx="5" ry="5" width="57.6" height="58.03" />
      <rect x="89.53" y={yPosition(8)} rx="5" ry="5" width="287.98" height="57.67" />
      <rect x="21" y={yPosition(8)} rx="5" ry="5" width="57.6" height="58.03" />
    </ContentLoader>
  );
};

export const GenericChartLoading = ({ style = {} }) => (
  <ContentLoader
    className="generic-chart-loading"
    height={650}
    width={1280}
    preserveAspectRatio="none"
    speed={2}
    style={style}
    {...commonParam}
  >
    <rect x="31.6" y="19.2" rx="4" ry="4" width="406" height="46.36" />
    <rect x="31.6" y="81.39" rx="4" ry="4" width="215.16" height="21.75" />
    <rect x="31.6" y="121.61" rx="5" ry="5" width="1220" height="500.88" />
    <circle cx="751.52" cy="51" r="9.39" />
    <circle cx="980.52" cy="50" r="9.39" />
    <rect x="775.39" y="26.39" rx="4" ry="4" width="158.8" height="21.75" />
    <rect x="775.39" y="54.39" rx="4" ry="4" width="158.8" height="21.75" />
    <rect x="1003.39" y="27.39" rx="4" ry="4" width="158.8" height="21.75" />
    <rect x="1003.39" y="53.39" rx="4" ry="4" width="158.8" height="21.75" />
  </ContentLoader>
);

export const GridLoading = ({ rows = 10, ...props }) => {
  const numberOfRows = rows || 10;
  const height = numberOfRows * 65;
  const yPosition = 60;
  const randomLongWidth = () => (Math.random() * (1 - 0.7) + 0.7) * 500;
  const randomShortWidth = () => (Math.random() * (1 - 0.7) + 0.7) * 90;
  return (
    <ContentLoader height={height} width={1280} preserveAspectRatio="none" speed={2} {...commonParam} {...props}>
      {[...Array(numberOfRows).keys()].map((i) => {
        const idx = i + 1;
        return (
          <Fragment key={i}>
            <rect x="27.39" y={yPosition * idx} rx="4" ry="4" width={randomLongWidth()} height="21.75" />
            <rect x="871.39" y={yPosition * idx} rx="4" ry="4" width={randomShortWidth()} height="21.75" />
            <rect x="1018.39" y={yPosition * idx} rx="4" ry="4" width={randomShortWidth()} height="21.75" />
            <rect x="1174.39" y={yPosition * idx} rx="4" ry="4" width={randomShortWidth()} height="21.75" />
          </Fragment>
        );
      })}
    </ContentLoader>
  );
};

export const SingleCellLoading = () => {
  const randomShortWidth = (Math.random() * (1 - 0.88) + 0.88) * 100;
  return (
    <ContentLoader height={20} width={100} preserveAspectRatio="none" speed={2} {...commonParam}>
      <rect x={100 - randomShortWidth} y="4" rx="4" ry="4" width={randomShortWidth} height="27" />
    </ContentLoader>
  );
};

export const StackedColumnChartLoading = () => {
  const width = 1370;
  const height = 384;
  return (
    <ContentLoader height={height} width={width} preserveAspectRatio="none" speed={2} {...commonParam}>
      {[...Array(4).keys()].map((i) => {
        return (
          <Fragment key={i}>
            <rect x={380 * i} y={0} rx="4" ry="4" width={180} height="21.75" />
            <rect x={380 * i} y={28} rx="4" ry="4" width={120} height="38.75" />
            <rect x={380 * i} y={73} rx="4" ry="4" width={200} height="21.75" />
          </Fragment>
        );
      })}
      <rect x={5} y={200} rx="4" ry="4" width={1360} height={155} />
    </ContentLoader>
  );
};

export const CustomLoading = ({ height, width, padding }) => {
  return (
    <ContentLoader height={height || 0} width={width || 0} speed={2} {...commonParam}>
      <rect x={padding} y={padding} rx="4" ry="4" width={width - 2 * padding} height={height - 2 * padding} />
    </ContentLoader>
  );
};

export const SingleLegendLoading = () => {
  return (
    <ContentLoader height={75} width={200} preserveAspectRatio="none" speed={2} {...commonParam}>
      <rect x={0} y={0} rx="4" ry="4" width={135} height={22} />
      <rect x={0} y={34} rx="4" ry="4" width={95} height={32} />
      <rect x={105} y={34} rx="4" ry="4" width={95} height={32} />
    </ContentLoader>
  );
};
