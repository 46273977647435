import React, { useState } from 'react';
import moment from 'moment';
import { ExpandRowColumnLayout, SlMenu, Text } from '@stackline/ui';
import { Box } from '@mui/system';
import {
  AsinData,
  ContactUsDetails,
  InitialDetailsDatum,
  SupportingDocument
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/types';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BeaconProEllipses } from 'src/components/SvgIcons';
import * as serverProxy from '../DisputeManagement/serverProxy';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { useQueryClient } from 'react-query';
import { SUPPORTING_DOCS_QUERY_KEY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/constants';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { formatToDollar } from 'src/utils/money';

const defaultTableColumnStyles = {
  align: 'left',
  padding: 'none',
  color: '#052849',
  style: {
    width: '225px'
  }
};

const VolumeMetric = ({ value }: { value: number }) => {
  const formatMetric = useMetricFormatter();
  return formatMetric(value, METRICTYPE.VOLUME, { showFullValue: true });
};

const WrappedHeader = ({ title }: { title: string }) => {
  return <div style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word' }}>{title.replaceAll(' ', '\n')}</div>;
};

export const INITIAL_DETAILS_COL_DEFS = [
  {
    field: 'disputeId',
    headerName: 'Dispute ID',
    tableColumnProps: defaultTableColumnStyles
  },
  {
    field: 'payeeCode',
    headerName: <WrappedHeader title="Payee Code" />,
    tableColumnProps: defaultTableColumnStyles
  },
  {
    field: 'purchaseOrderNumber',
    headerName: <WrappedHeader title="PO Number" />,
    tableColumnProps: defaultTableColumnStyles
  },
  {
    field: 'originalInvoiceDate',
    headerName: <WrappedHeader title="Invoice Date" />,
    tableColumnProps: defaultTableColumnStyles,
    valueFormatter: (_, row: InitialDetailsDatum) => {
      const { originalInvoiceDate } = row;
      return moment(originalInvoiceDate).format('M/DD/YYYY');
    }
  },
  {
    field: 'paymentDueDate',
    headerName: <WrappedHeader title="Due Date" />,
    tableColumnProps: defaultTableColumnStyles,
    valueFormatter: (_, row: InitialDetailsDatum) => {
      const { paymentDueDate } = row;
      return moment(paymentDueDate).format('M/DD/YYYY');
    }
  },
  {
    field: 'invoiceQuantity_sum_value',
    headerName: 'Invoiced Quantity',
    tableColumnProps: defaultTableColumnStyles,
    valueFormatter: (_, row: InitialDetailsDatum) => {
      const { invoiceQuantity_sum_value } = row;
      return <VolumeMetric value={invoiceQuantity_sum_value} />;
    }
  },
  {
    field: 'disputeQuantity_sum_value',
    headerName: 'Dispute Quantity',
    tableColumnProps: defaultTableColumnStyles,
    valueFormatter: (_, row: InitialDetailsDatum) => {
      const { disputeQuantity_sum_value } = row;
      return <VolumeMetric value={disputeQuantity_sum_value} />;
    }
  },
  {
    field: 'disputeAmount_sum_value',
    headerName: 'Dispute Amount',
    tableColumnProps: defaultTableColumnStyles,
    valueFormatter: (_, row: InitialDetailsDatum) => {
      const { disputeAmount_sum_value } = row;
      return formatToDollar(disputeAmount_sum_value);
    }
  }
];

export const ASIN_DETAILS_COL_DEFS = [
  {
    field: 'retailerSku',
    headerName: 'ASIN',
    tableColumnProps: defaultTableColumnStyles
  },
  {
    field: 'invoiceQuantity',
    headerName: 'Invoiced Quantity',
    tableColumnProps: defaultTableColumnStyles,
    valueFormatter: (_, row: AsinData) => {
      const { invoiceQuantity } = row;
      return <VolumeMetric value={invoiceQuantity} />;
    }
  },
  {
    field: 'invoiceAmount',
    headerName: 'Invoiced Amount',
    tableColumnProps: defaultTableColumnStyles,
    valueFormatter: (_, row: AsinData) => {
      const { invoiceAmount } = row;
      return formatToDollar(invoiceAmount);
    }
  },
  {
    field: 'disputeQuantity',
    headerName: 'Dispute Quantity',
    tableColumnProps: defaultTableColumnStyles,
    valueFormatter: (_, row: AsinData) => {
      const { disputeQuantity } = row;
      return <VolumeMetric value={disputeQuantity} />;
    }
  },
  {
    field: 'disputeAmount',
    headerName: 'Dispute Amount',
    tableColumnProps: defaultTableColumnStyles,
    valueFormatter: (_, row: AsinData) => {
      const { disputeAmount } = row;
      return formatToDollar(disputeAmount);
    }
  },
  {
    field: 'shortageQuantity',
    headerName: 'Shortage Quantity',
    tableColumnProps: defaultTableColumnStyles,
    valueFormatter: (_, row: AsinData) => {
      const { shortageQuantity } = row;
      return <VolumeMetric value={shortageQuantity} />;
    }
  },
  {
    field: 'shortageAmount',
    headerName: 'Shortage Amount',
    tableColumnProps: defaultTableColumnStyles,
    valueFormatter: (_, row: AsinData) => {
      const { shortageAmount } = row;
      return formatToDollar(shortageAmount);
    }
  },
  {
    field: 'invoiceUnitCost',
    headerName: <WrappedHeader title="Unit Price" />,
    tableColumnProps: defaultTableColumnStyles,
    valueFormatter: (_, row: AsinData) => {
      const { invoiceUnitCost } = row;
      return formatToDollar(invoiceUnitCost);
    }
  }
];

export const CONTACT_US_COL_DEFS = [
  {
    field: 'creationDate',
    headerName: 'Creation Date',
    tableColumnProps: { style: { width: '220px' } },
    valueFormatter: (_, row: ContactUsDetails) => {
      const { creationDate } = row;
      const formattedDate = moment.unix(creationDate).format('M/D/YYYY [at] hh:mm:ss A [PDT]');
      return formattedDate;
    }
  },
  {
    field: 'caseId',
    headerName: 'ID',
    tableColumnProps: { style: { width: '50px' } }
  },
  {
    field: 'status',
    headerName: 'Status',
    tableColumnProps: { style: { width: '50px' } }
  },
  {
    field: 'primaryEmail',
    headerName: 'Primary E-mail',
    tableColumnProps: { style: { width: '150px' } }
  },
  {
    field: 'link',
    headerName: 'Link to Case',
    tableColumnProps: { ...defaultTableColumnStyles, style: { width: '210px' } },
    valueFormatter: (_, row: ContactUsDetails) => {
      const { link } = row;
      return (
        <div style={{ width: '210px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
          <a target="_blank" rel="noreferrer" href={link}>
            {link}
          </a>
        </div>
      );
    }
  },
  {
    field: '',
    headerName: '',
    tableColumnProps: { style: { width: '20px' } },
    expandRow: true
  }
];

export const CONTACT_US_EXPANDED_COL_DEFS = {
  containerClassName: '',
  valueFormatter: (row: ContactUsDetails) => {
    const { communicationText, shortDescription } = row;
    return (
      <Box minHeight="180px" display="flex" flexDirection="row" height="auto" padding="50px 24px 50px 24px" gap="70px">
        <Flex width="435px" flexDirection="column" gap="sm">
          <Text variant="subtitle2">Short Description</Text>
          <Text variant="body2">{shortDescription}</Text>
        </Flex>
        <Flex width="320px" flexDirection="column" gap="sm">
          <Text variant="subtitle2">Communication Text</Text>
          <div style={{ overflowWrap: 'anywhere' }}>
            <Text variant="body2">{communicationText}</Text>
          </div>
        </Flex>
      </Box>
    );
  },
  columns: {
    shortDescription: {
      title: 'Short Description',
      layout: ExpandRowColumnLayout.Section,
      fields: {
        shortDescription: {}
      }
    },
    financialDetails: {
      title: 'Communication Text',
      layout: ExpandRowColumnLayout.Section,
      fields: {
        communicationText: {}
      }
    }
  }
};

enum DROPDOWN_ACTION {
  VIEW = 'view',
  DELETE = 'delete'
}

const DocumentEllipsesMenu = ({ row }: { row: SupportingDocument }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { invoiceNumber, documentId, fileName } = row;
  const retailerId = useAppSelector((state) => state.retailer.id);
  const queryClient = useQueryClient();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClick = async (id: DROPDOWN_ACTION) => {
    if (id === DROPDOWN_ACTION.VIEW) {
      try {
        const response = await serverProxy.downloadFile({ invoiceNumber, documentId, retailerId });
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = URL.createObjectURL(response.data);
        anchor.download = fileName;
        anchor.click();
      } catch (err) {
        console.error('Failed to download file: ', err);
      }
    } else if (id === DROPDOWN_ACTION.DELETE) {
      try {
        await serverProxy.deleteFile({ invoiceNumber, documentId, retailerId });
        queryClient.invalidateQueries({ queryKey: SUPPORTING_DOCS_QUERY_KEY });
      } catch (err) {
        console.error('Failed to delete file: ', err);
      }
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <BeaconProEllipses onClick={handleOpen} style={{ width: '24px', height: '24px' }} />
        <SlMenu
          anchorEl={anchorEl}
          onClose={handleClose}
          open={!!anchorEl}
          selectedId={null}
          onChange={({ id }) => handleOptionClick(id as DROPDOWN_ACTION)}
          options={[
            {
              id: DROPDOWN_ACTION.VIEW,
              label: 'View'
            },
            {
              id: DROPDOWN_ACTION.DELETE,
              label: 'Delete'
            }
          ]}
        />
      </Box>
    </>
  );
};

export const SUPPORTING_DOCS_COL_DEFS = [
  {
    field: 'fileName',
    headerName: 'Document Name'
  },
  {
    field: 'documentType',
    headerName: 'Document Type',
    tableColumnProps: {
      align: 'left'
    }
  },
  {
    field: 'creationDate',
    headerName: 'Date Uploaded',
    tableColumnProps: {
      align: 'left'
    },
    valueFormatter: (_, row: SupportingDocument) => {
      const { creationDate } = row;
      const formattedDate = moment(creationDate).format('M/DD/YYYY');
      return formattedDate;
    }
  },
  {
    field: '',
    headerName: '',
    tableColumnProps: {
      align: 'left'
    },
    valueFormatter: (_, row: SupportingDocument) => {
      return <DocumentEllipsesMenu row={row} />;
    }
  }
];
