import { useQuery } from 'react-query';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { useAppSelector } from 'src/utils/Hooks';
import { getFirstWeekIdOfYear, getLastWeekIdOfYear } from 'src/utils/dateUtils';
import axios from 'axios';
import moment from 'moment';

interface AdjustmentConflictPayload {
  beaconClientId: number;
  retailerId: number | string;
  retailerSku: string | number;
  adjustmentTitle: '';
  adjustmentDescription: '';
  planType: string;
  adjustmentChangeType: 'Absolute';
  adjustmentChangeValue: '';
  userId: string;
  startWeekId: string | number;
  endWeekId: string | number;
  ignoreConflictWithAdjustmentIds: string[];
}
export interface AdjustmentConflictResponse {
  beaconClientId: number;
  retailerId: number;
  retailerSku: string;
  planType: string;
  startWeekId: number;
  endWeekId: number;
  message: string;
  isConflicted: boolean;
  conflictedAdjustmentIdsAndWeeks: string[] | null;
}

interface UseConflictingWeekIdsProps {
  /**
   * The retailer SKU of the product we're creating an adjustment for
   */
  retailerSku: string | number;
  /**
   * The plan type of the adjustment we're creating
   */
  planType: PlanTypeOption;
  /**
   * The start week ID/minimum range to search for adjustment conflicts.
   * Defaults to the first week of the current year.
   */
  startWeekId?: string;
  /**
   * The end week ID/maximum range to search for adjustment conflicts.
   * Defaults to the last week of next year.
   */
  endWeekId?: string;
  adjustmentId?: string;
}

/**
 * Accepts an optional start and end week ID and returns any potential conflicting dates where adjustments are already active.
 * Used to populate the invalid dates in the adjustment modal calendar picker.
 */
export const useConflictingWeekIds = ({
  retailerSku,
  planType,
  startWeekId = String(getFirstWeekIdOfYear()),
  endWeekId = String(getLastWeekIdOfYear(moment().add(1, 'year').year())),
  adjustmentId = ''
}: UseConflictingWeekIdsProps) => {
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const retailerId = useAppSelector((state) => state.retailer.id);
  const userId = useAppSelector((state) => state.user.session.userId);

  const validDatesRequestBody: AdjustmentConflictPayload = {
    beaconClientId,
    retailerId,
    retailerSku,
    adjustmentTitle: '',
    adjustmentDescription: '',
    planType,
    adjustmentChangeType: 'Absolute',
    adjustmentChangeValue: '',
    userId,
    startWeekId,
    endWeekId,
    ignoreConflictWithAdjustmentIds: [adjustmentId]
  };

  const query = useQuery(
    [retailerSku, planType, startWeekId, endWeekId],
    async () => {
      const response = await axios.post('api/beaconforecast/CheckForConflictingAdjustments', validDatesRequestBody);
      return response.data;
    },
    {
      retry: 0,
      staleTime: 0,
      refetchOnWindowFocus: false
    }
  );

  return {
    data: query.data as AdjustmentConflictResponse,
    isLoading: query.isLoading,
    isError: query.isError
  };
};
