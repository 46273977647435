import { styled } from '@mui/material';
import Select from '@mui/material/Select';

/**
 * The MUI Select is styled for the header with
 * wide padding and spacing. The standard dropdown
 * uses overridden styles but with appropriate
 * spacing for normal use.
 */
const StandardDropdown = styled(Select)({
  margin: '0',
  '.MuiSelect-select': { margin: '0 !important' }
});
export default StandardDropdown;
