import React from 'react';
import { NavLink } from 'react-router-dom';
import { store } from 'src/main';
import BusinessUnitListing from 'src/routes/HomePage/SearchList/BusinessUnitListing';
// import BusinessUnitBuilderDialog from 'src/components/BusinessUnitBuilder/BusinessUnitBuilderDialog';
import { CreateNewSegmentIcon } from '../SubHeader/CreateNewSavedSearchButton';

const SegmentAndBUListing: React.FC<{ HydratedSavedSearchLists: Function }> = () => {
  // const [buBuilderDialogOpen, setBUBuilderDialogOpen] = useState(false);
  const { app } = store.getState();
  return (
    <div style={{ marginTop: 40, padding: '0px 30px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <h1>Segments</h1>
        <NavLink to={`/search${app.queryParams.searchParams}&segmentStage=segmentCreation`}>
          <CreateNewSegmentIcon data-for="create-new-segment" />
        </NavLink>
      </div>
      <BusinessUnitListing />

      {/* <BusinessUnitBuilderDialog open={buBuilderDialogOpen} onClose={() => setBUBuilderDialogOpen(false)} /> */}
    </div>
  );
};

export default SegmentAndBUListing;
