import { Box } from '@mui/system';
import { StacklineTheme, Text, hexToRgba, useStacklineTheme } from '@stackline/ui';
import React, { MouseEventHandler } from 'react';
import {
  DISPUTE_DATA_QUERY_KEY,
  DISPUTE_KEY_METRIC_QUERY_KEY,
  DISPUTE_STAGE,
  DISPUTE_STATUS
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/constants';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { styled } from '@mui/material';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import _get from 'lodash/get';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';

const UnstyledButton = styled('button')(
  ({ stacklineTheme, selected }: { stacklineTheme: StacklineTheme; selected: boolean }) => ({
    border: 'none',
    borderRadius: '4px',
    background: selected ? hexToRgba(stacklineTheme.colors.secondaryPorcelain) : 'none',
    padding: '0',
    ':hover': {
      backgroundColor: hexToRgba(stacklineTheme.colors.secondaryPorcelain)
    }
  })
);

interface DisputeKeyMetricCardProps {
  label: string;
  stage: DISPUTE_STAGE;
  status: DISPUTE_STATUS;
  selected: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}
export const DisputeKeyMetricCard = ({ label, stage, status, selected, onClick }: DisputeKeyMetricCardProps) => {
  const theme = useStacklineTheme();
  const retailer = useAppSelector((state) => state.retailer);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const { startWeek, endWeek } = mainTimePeriod;
  const formatMetric = useMetricFormatter();

  const requestBuilder = new AdvancedSearchRequestBuilder(
    `${stage}-${status}-box-filter-metric-request`,
    'beacon-sd-poShipmentInvoiceSummary_fetcher'
  );

  // Set various request properties
  requestBuilder
    .setPageNumber(1)
    .setPageSize(1200)
    .setRetailerId(retailer.id)
    .setPeriod('year')
    .setDoAggregation(true)
    .setReturnDocuments(false)
    .setProcessDocuments(false);

  // Add term filters
  requestBuilder
    .addConditionTermFilter('documentType', 'must', ['invoiceSummary'])
    .addConditionTermFilter('customDisputeStage', 'should', [stage])
    .addConditionTermFilter('customDisputeStatus', 'should', [status]);

  // Add a range filter
  requestBuilder.addConditionRangeFilter('weekId', startWeek, endWeek);

  // Add aggregations
  requestBuilder.addAggregation({
    aggregationFields: [
      {
        aggregateByFieldDisplayName: 'originalInvoiceNumber',
        aggregateByFieldName: 'originalInvoiceNumber',
        function: 'cardinality',
        canBeExported: true
      }
    ],
    groupByFieldName: 'retailerId'
  });

  // Add sort fields
  requestBuilder.addSortField('originalInvoiceNumber', 'originalInvoiceNumber', 'cardinality', true);

  // Build the request
  const mainRequest = requestBuilder.build();

  const { data, isLoading } = useGenericAdvancedSearch({
    requestId: `${stage}-${status}-keymetric`,
    requestBody: [mainRequest],
    queryKeys: [DISPUTE_DATA_QUERY_KEY, DISPUTE_KEY_METRIC_QUERY_KEY, stage, status, startWeek, endWeek]
  });

  const primaryCount = data ? _get(data, 'data[0].aggregations.by_retailerId[0].value', 0) : 0;

  const formattedCount = formatMetric(primaryCount, METRICTYPE.VOLUME);

  return (
    <UnstyledButton
      onClick={onClick}
      stacklineTheme={theme}
      selected={selected}
      sx={{
        width: '245px'
      }}
    >
      <Box height="98px" borderRadius="4px" padding="16px">
        <div style={{ textAlign: 'left', whiteSpace: 'break-spaces' }}>
          <Text color="secondary" variant="subtitle3" transform="uppercase">
            {label}
          </Text>
        </div>
        <Flex justifyContent="space-between" marginTop="8px" height="24px" alignItems="center">
          {isLoading ? <SlSkeleton width="55px" height="34px" /> : <Text variant="body0">{formattedCount}</Text>}
        </Flex>
      </Box>
    </UnstyledButton>
  );
};
