import { Box } from '@mui/system';
import React from 'react';
import MetricListContainer, {
  MetricListContainerField
} from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import { getStartAndEndOfWeek } from 'src/utils/dateUtils';
import { formatTime } from 'src/utils/dateformatting';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';

interface ValueDifference {
  value: number;
  difference: number;
}

interface ResponseRow {
  winsByClient_sum_value: number;
  winsByClient_sum_count: number;
  winPercentage_computed_value: number;
  winPercentage_computed_count: number;
  buyBoxWeighted_sum_value: number;
  buyBoxWeighted_sum_count: number;
  winsBySeller_sum_value: number;
  winsBySeller_sum_count: number;
  lossPercentage_computed_value: number;
  lossPercentage_computed_count: number;
  metadata: {
    weekId: string;
    name: string;
  };
}

interface BuyboxRow {
  weekId: string;
  name: string;
  buyBoxWeighted: ValueDifference;
  winsByClient: ValueDifference;
  winPercentage: ValueDifference;
  lossPercentage: ValueDifference;
  responseRow: ResponseRow;
}

const headColumnDefinition = {
  headerName: 'Week Ending',
  tableColumnProps: {
    style: {
      paddingLeft: '24px',
      width: '500px'
    }
  },
  valueFormatter: (_, row: BuyboxRow) => {
    const { weekId } = row;
    const { endDate } = getStartAndEndOfWeek(weekId);
    const weekEndingDate = formatTime(endDate, 'MMM D, YYYY');
    return (
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        <span style={{ margin: 0 }} title={weekEndingDate}>
          {weekEndingDate}
        </span>
      </div>
    );
  }
};

interface BuyBoxByWeekGridProps {
  gridTitle: string;
  fields: MetricListContainerField[];
}

const BuyBoxByWeekGrid = ({ gridTitle, fields }: BuyBoxByWeekGridProps) => {
  return (
    <Box>
      <MetricListContainer
        entityTableHeaderProps={{
          prefixTitle: '',
          title: gridTitle
        }}
        fields={fields}
        indexName={BEACON_INDEX_NAMES.BUYBOX}
        sortFieldName="buyBoxWeighted"
        showGrid={false}
        postProcessData={(data: BuyboxRow[]) => {
          return data.sort((a, b) => b.weekId.localeCompare(a.weekId));
        }}
        useTableDataProps={{
          groupByFieldName: 'weekId',
          filterResponse: () => true,
          itemsPerPage: 100
        }}
        headColumnDefinition={headColumnDefinition}
      />
    </Box>
  );
};

export default BuyBoxByWeekGrid;
