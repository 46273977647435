import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import queryString from 'qs';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import Truncate from 'react-truncate';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import { Option } from 'funfix-core';

import colors from 'src/utils/colors';
import { extractQueryConditions } from 'src/utils/segments';
import { DuplicateIcon, EditIcon } from 'src/components/SvgIcons';
import 'src/routes/HomePage/Segments.scss';

const displayNameByEntityType = {
  segment: 'Segment',
  keywordlist: 'Keyword List',
  searchtermlist: 'Keyword List',
  businessunit: 'Business Unit'
};

const getChipDisplayName = (entityType) => displayNameByEntityType[entityType];

class SegmentSidebarCard extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired
  };

  static contextTypes = {
    router: PropTypes.object
  };

  state = {
    segment: {}
  };

  componentDidMount() {
    const { entityService } = this.props;
    if (entityService.mainEntity.query || entityService.mainEntity.type === 'businessunit') {
      this.buildSegment(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { entityService } = nextProps;
    if (
      !_isEqual(entityService.mainEntity.query, this.props.entityService.mainEntity.query) ||
      (entityService.mainEntity.type === 'businessunit' &&
        entityService.mainEntity.id !== this.props.entityService.mainEntity.id)
    ) {
      this.buildSegment(nextProps);
    }
  }

  stopPropagation = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  buildSegment(nextProps) {
    const { entityService } = nextProps;
    const segment = {
      ...Option.of(entityService.mainEntity.query)
        .map((query) => JSON.parse(query))
        .getOrElse({}),
      type: entityService.mainEntity.entityType,
      entityType: entityService.mainEntity.entityType,
      canEdit: entityService.mainEntity.canEdit,
      ownerProfile: entityService.mainEntity.ownerProfile
    };

    const name = segment.dn || entityService.mainEntity.displayName || 'Segment';

    this.setState({
      segment: {
        ...segment,
        name,
        shortName: name.slice(0, 2)
      }
    });
  }

  handleEditSegment = (event) => {
    this.stopPropagation(event);
    const { app } = this.props;
    const { searchParams } = app.queryParams;
    const { segment } = this.state;
    const conditions = extractQueryConditions(segment, true, true);
    this.context.router.history.push(
      `/search${searchParams}&doAggregation=true&entityType=${segment.entityType}&${queryString.stringify(
        conditions
      )}&supportExport=true`
    );
  };

  handleDuplicateSegment = (event) => {
    this.stopPropagation(event);
    const { segment } = this.state;
    const { retailer } = this.props;
    const conditions = extractQueryConditions(segment, true);
    this.context.router.history.push(
      `/search?rid=${retailer.id}&entityType=${segment.entityType}&doAggregation=true&${queryString.stringify(
        conditions
      )}&supportExport=true`
    );
  };

  showEditIcon() {
    const { segment } = this.state;

    if (!segment.ownerProfile || !segment.canEdit) {
      return null;
    }

    return (
      <IconButton
        onClick={this.handleEditSegment}
        style={{
          display: 'inline-block',
          height: '20px',
          width: '20px',
          verticalAlign: 'middle',
          padding: '0',
          marginLeft: '5px'
        }}
        size="large"
      >
        <EditIcon className="segment-header__edit-icon" />
      </IconButton>
    );
  }

  showCreatedBy() {
    const { segment } = this.state;
    if (!segment.ownerProfile || segment.canEdit || segment.type === 'businessunit') {
      return null;
    }

    return (
      <div style={{ marginTop: '2px' }}>
        <span style={{ fontSize: '11px' }}>
          Created By {segment.ownerProfile.firstName} {segment.ownerProfile.lastName}
        </span>
        <IconButton
          onClick={this.handleDuplicateSegment}
          style={{
            display: 'inline-block',
            height: '30px',
            width: '30px',
            verticalAlign: 'middle',
            padding: '0',
            marginTop: '-3px'
          }}
          size="large"
        >
          <DuplicateIcon className="segment-header__duplicate-icon" />
        </IconButton>
      </div>
    );
  }

  render() {
    const { segment: savedSearch } = this.state;
    if (_isEmpty(this.props.entityService.mainEntity)) {
      return null;
    }

    return (
      <div className="nav-header-container">
        <div className="nav-image-container">
          <div className="nav-image-center">
            <div className="segment__short-name-container" style={{ backgroundColor: colors.darkBlue }}>
              <div className="segment__short-name">{savedSearch.shortName}</div>
            </div>
          </div>
        </div>
        <div className="nav-name-container" title={savedSearch.name}>
          <Truncate style={{ display: 'inline-block', verticalAlign: 'middle' }} lines={2} ellipsis="...">
            <div className="nav-company-name">{savedSearch.name}</div>
          </Truncate>
          {this.showEditIcon()}
          {this.showCreatedBy()}
        </div>
        <Chip className="entity-chip" label={getChipDisplayName(this.props.entityService.mainEntity.type)} />
      </div>
    );
  }
}

export default withRouter(connect((state) => state)(SegmentSidebarCard));
