import { Field } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy';

/**
 *  Sets a default comparison formatter for a field to remove the comparison metrics from the table
 */
export const removeComparisonFormatter = (field: Field) => {
  return {
    ...field,
    comparisonFormatter: () => null
  };
};
