import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';

import Creators from './actions';
import parsePromoTypes from './selectors';
import ReduxStore from 'src/types/store/reduxStore';

export const { clearPromoTypes, receivePromoTypes } = Creators;

export const fetchPromoTypes = (
  appName: ReduxStore['app']['name'],
  name: string,
  retailer: ReduxStore['retailer'],
  mainTimePeriod: ReduxStore['mainTimePeriod'],
  requestOverrides: {} = {}
) => {
  const request = [
    {
      name,
      pageNumber: 1,
      pageSize: 50,
      retailerId: retailer.id,
      doAggregation: true,
      returnDocuments: false,
      searchBy: 'parent',
      searchType: name,
      aggregations: [
        {
          groupByFieldName: 'promoType',
          aggregationFields: [
            {
              aggregateByFieldDisplayName: 'Promotion Count',
              aggregateByFieldName: 'retailerId',
              function: 'sum',
              canBeExported: true
            }
          ],
          sortDirection: null,
          sortByAggregationField: {
            aggregateByFieldDisplayName: 'Promotion Count',
            aggregateByFieldName: 'retailerId',
            function: 'sum',
            canBeExported: true
          },
          conditions: {
            termFilters: [
              {
                fieldName: 'retailerId',
                values: [retailer.id]
              }
            ],
            rangeFilters: [
              {
                fieldName: 'weekId',
                minValue: mainTimePeriod.startWeek,
                maxValue: mainTimePeriod.endWeek
              }
            ]
          },
          comparisonRangeFilters: []
        }
      ],
      conditions: {
        termFilters: [
          {
            fieldName: 'retailerId',
            condition: 'should',
            values: [retailer.id]
          }
        ],
        rangeFilters: [],
        condition: 'must',
        nestedFilterConditions: []
      },
      ...requestOverrides
    }
  ];

  return axios.post(`/api/${appName}/AdvancedSearch?_id=fetchPromoTypes`, request);
};

export function fetchPromoTypesForRetailer() {
  return (dispatch: ThunkDispatch<ReduxStore, void, any>, getState: () => ReduxStore) => {
    const {
      retailer,
      mainTimePeriod,
      app: { apiAppName: appName }
    } = getState();
    const name = `${appName}-promotions`;

    fetchPromoTypes(appName, name, retailer, mainTimePeriod).then((response) => {
      return dispatch(receivePromoTypes(parsePromoTypes(response)));
    });
  };
}
