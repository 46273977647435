/* eslint-disable react/prop-types */
import React from 'react';

import BusinessUnitFilter from 'src/components/EntityPage/Filters/BusinessUnitFilter';
import { BusinessUnit } from 'sl-api-connector';

/**
 * A React component that wraps `BusinessUnitFilter` and exposes an interface that is compatible with the advanced
 * search sidebar's filter components.
 */
const ShimBusinessUnitFilter: React.FC<{
  parentBusinessUnit: BusinessUnit;
  onChange: (...args: any[]) => void;
  value: { [parentBuId: string]: string[] };
}> = ({ parentBusinessUnit, onChange, value }) => (
  <BusinessUnitFilter
    parentBusinessUnit={parentBusinessUnit}
    level={0}
    onFilterChange={(selectedFilters: string[]) =>
      onChange({ parentBusinessUnitId: parentBusinessUnit.id, selectedFilters })
    }
    value={value[parentBusinessUnit.id] || []}
  />
);

export default ShimBusinessUnitFilter;
