import { useMemo } from 'react';

/**
 * A custom hook that returns the number of remaining characters
 * @param text a string of text
 * @param characterLimit a number representing the maximum number of characters allowed
 * @returns a number representing the number of remaining characters
 */
const useRemainingCharacters = (text: string, characterLimit: number) => {
  return useMemo(() => (text ? characterLimit - text.length : characterLimit), [text, characterLimit]);
};

export default useRemainingCharacters;
