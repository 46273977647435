import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'qs';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import _prop from 'lodash/property';

import { entityOperations } from 'src/store/modules/entityService';
import { combineFilterConditions } from 'src/utils/filters';
import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import { getLastWeek, getFirstWeekOfYear } from 'src/utils/dateformatting';
import CreateNewSavedSearchButton from './SubHeader/CreateNewSavedSearchButton';
import EntityMetricDropDown from 'src/components/common/DropDown/EntityMetricDropDown';
import SummarySubHeader from './SubHeader/SummarySubHeader';
import SummaryDataContainer from './SummaryData/SummaryDataContainer';
import SavedSearchLists from './SearchList/SavedSearchLists';
import SegmentAndBUListing from './SearchList/SegmentAndBUListing';
import AdSegmentListing from './SearchList/AdvertisingSegment';
import './HomePage.scss';
import './Segments.scss';
import { anyNotEq } from 'src/utils/equality';
import { onPrimeMode } from 'src/store/modules/user/selectors';
import { shouldShowNewBeacon } from 'src/utils/app';

import CategorySummary from 'src/components/BeaconRedesignComponents/ExperimentalLayout/CategorySummary/CategorySummary';

class HomePage extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    allWeekIdsByRetailerId: PropTypes.object.isRequired,
    brandsFollowing: PropTypes.array.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    subCategories: PropTypes.array.isRequired,
    entityService: PropTypes.object.isRequired,
    filters: PropTypes.object,
    mainTimePeriod: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    segments: PropTypes.object.isRequired,
    fetchMainEntity: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  static defaultProps = {
    filters: {}
  };

  constructor(props) {
    super(props);

    const params = this.getQueryParams(props);
    const entityType = params.entityType || 'category';
    const category = {
      displayName: 'Category',
      fieldName: 'categoryId',
      entityType: 'category'
    };
    const subCategory = {
      displayName: 'Subcategory',
      fieldName: 'subCategoryId',
      entityType: 'subcategory'
    };
    const brand = {
      displayName: 'Brand',
      fieldName: 'brandId',
      entityType: 'brand'
    };
    const product = {
      displayName: 'Product',
      fieldName: 'stacklineSku',
      entityType: 'product'
    };
    const restrictedEntities = [];
    if (!props.user.config.isRestrictedDemoUser) {
      restrictedEntities.push(product);
    }
    const categoryEntities = props.user.config.isRestrictedDemoUser
      ? [{ ...subCategory }]
      : [{ ...subCategory }, { ...brand }, ...restrictedEntities];
    //  Extract the page layout to be similar to the AtlasEntityPageLayout
    this.state = {
      pageLayout: {
        category: {
          entities: categoryEntities,
          search: [
            {
              displayName: 'Categories',
              name: 'categories'
            }
          ]
        },
        brand: {
          entities: [{ ...category }, ...restrictedEntities],
          search: [
            {
              displayName: 'Brands',
              name: 'brands'
            }
          ]
        },
        product: {
          entities: [],
          search: null
        },
        segment: {
          entities: [{ ...brand }, { ...category }, ...restrictedEntities],
          search: [
            {
              displayName: 'My Segments',
              name: 'mySegments'
            },
            {
              displayName: 'Team Segments',
              name: 'teamSegments'
            }
          ]
        },
        searchtermlist: {
          entities: [{ ...brand }, { ...category }, ...restrictedEntities],
          search: [
            {
              displayName: 'My Keyword Lists',
              name: 'mySearchTermLists'
            },
            {
              displayName: 'Team Keyword Lists',
              name: 'teamSearchTermLists'
            }
          ]
        },
        campaign: {
          entities: [{ ...brand }, { ...category }, ...restrictedEntities],
          search: [
            {
              displayName: 'My Keyword Lists',
              name: 'mySearchTermLists'
            },
            {
              displayName: 'Team Keyword Lists',
              name: 'teamSearchTermLists'
            }
          ]
        },
        retailer: {
          entities: [],
          search: []
        }
      },
      selectedItem: this.buildSelectedSearchItem(entityType, this.props)
    };
  }

  componentWillMount() {
    this.updateAllQueryConditions(this.props);
    const params = this.getQueryParams();
    const entityType = params.entityType || 'category';
    const selectedOption = this.state.pageLayout[entityType];
    const { selectedItem, searchListObject } = this.buildSelectedItemAndList(entityType, params, this.props);
    const clonePageLayout = _cloneDeep(this.state.pageLayout);
    if (this.props.app.name === 'advertising') {
      clonePageLayout.segment.search = [
        {
          displayName: 'Campaign Segments',
          name: 'campaignSegments'
        },
        {
          displayName: 'Product Segments',
          name: 'productSegments'
        },
        {
          displayName: 'Target Segments',
          name: 'targetSegments'
        }
      ];
    }

    this.setState({
      ...this.getMetricsConfig(entityType),
      pageLayout: clonePageLayout,
      selectedOption,
      subHeaderEntitySelection: selectedOption.entities[0],
      searchListObject,
      selectedItem
    });
  }

  componentDidMount() {
    const { fetchMainEntity, retailer } = this.props;
    fetchMainEntity('mainEntity', 'client', -1, retailer.id, this.props.history.push.bind(this.props.history));
  }

  componentWillReceiveProps(nextProps) {
    if (!_isEqual(this.props, nextProps)) {
      this.updateAllQueryConditions(nextProps);
    }

    if (
      anyNotEq(
        ['location.search', 'retailer', 'segments', 'mainTimePeriod', 'comparisonTimePeriod'],
        this.props,
        nextProps
      )
    ) {
      const params = this.getQueryParams(nextProps);
      const { entityType = 'category' } = params;
      const selectedOption = this.state.pageLayout[entityType];
      const { selectedItem, searchListObject } = this.buildSelectedItemAndList(entityType, params, nextProps);
      const clonePageLayout = _cloneDeep(this.state.pageLayout);
      if (this.props.app.name === 'advertising') {
        clonePageLayout.segment.search = [
          {
            displayName: 'Campaign Segments',
            name: 'campaignSegments'
          },
          {
            displayName: 'Product Segments',
            name: 'productSegments'
          },
          {
            displayName: 'Target Segments',
            name: 'targetSegments'
          }
        ];
      }
      this.setState(() => ({
        ...this.getMetricsConfig(entityType),
        subHeaderEntitySelection: selectedOption.entities[0],
        pageLayout: clonePageLayout,
        selectedOption,
        selectedItem,
        searchListObject
      }));
    }
  }

  buildSelectedItemAndList = (entityType, params, nextProps) => {
    const { selectedType, selectedId } = params;
    let selectedItem = this.buildSelectedSearchItem(entityType, nextProps);
    let searchListObject = this.buildSearchListObject(nextProps);
    if (selectedType && selectedId) {
      selectedItem = searchListObject[selectedType].find((val) => selectedId.toString() === val.id.toString());
      searchListObject = {
        ...searchListObject,
        [selectedType]: [
          ...[selectedItem],
          ...searchListObject[selectedType].filter((val) => selectedId.toString() !== val.id.toString())
        ]
      };
    }
    return { selectedItem, searchListObject };
  };

  getQueryParams = (props = this.props) =>
    queryString.parse(props.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });

  getEntityType = (props = this.props) => this.getQueryParams(props).entityType || 'category';

  getMetricsConfig(entityType) {
    const { app } = this.props;
    let aggregationMetricFields = [
      INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, 'sales', 'unitsSold', 'product', 'stacklineSku')
    ];

    if (onPrimeMode()) {
      if (app.name === 'atlas') {
        aggregationMetricFields.push(
          INDEX_FIELDS.getField(app.name, 'traffic', 'organicClicks', 'product', 'stacklineSku')
        );
        aggregationMetricFields.push(INDEX_FIELDS.getField(app.name, 'traffic', 'adClicks', 'product', 'stacklineSku'));
      }

      if (entityType === 'searchtermlist') {
        aggregationMetricFields = [
          INDEX_FIELDS.getField(app.name, 'traffic', 'organicClicks', 'product', 'stacklineSku'),
          INDEX_FIELDS.getField(app.name, 'traffic', 'adClicks', 'product', 'stacklineSku')
        ];
      }
    }

    aggregationMetricFields[0].isSelected = true;
    return { aggregationMetricFields };
  }

  /**
   * sets queryConditions, aggregationConditions and comparisonRangeFilters to be used in api calls for product
   * container and summary chart container
   */
  updateAllQueryConditions = ({
    app,
    allWeekIdsByRetailerId,
    mainTimePeriod,
    comparisonTimePeriod,
    retailer,
    filters,
    categories,
    subCategories,
    segments
  }) => {
    const weekIds = allWeekIdsByRetailerId[retailer.id];
    const { conditions: queryConditions, aggregationConditions } = combineFilterConditions(
      app,
      filters,
      categories,
      subCategories,
      segments,
      retailer
    );
    //  add additional queryConditions here
    const entityConditions = _cloneDeep(queryConditions);
    if (entityConditions.rangeFilters === undefined) {
      entityConditions.rangeFilters = [];
    }

    let marketShareAggregationConditions = _cloneDeep(aggregationConditions);
    marketShareAggregationConditions = {
      ...marketShareAggregationConditions,
      rangeFilters: marketShareAggregationConditions.rangeFilters.filter((filter) => filter.fieldName !== 'weekId'),
      termFilters: []
    };
    const marketShareWeekIdRangeFilter = {
      fieldName: 'weekId',
      minValue: Math.min(comparisonTimePeriod.startWeek, getFirstWeekOfYear(mainTimePeriod.startWeek - 100)),
      maxValue: getLastWeek(weekIds)
    };
    marketShareAggregationConditions.rangeFilters.push(marketShareWeekIdRangeFilter);

    let clonedAggregationConditions = _cloneDeep(aggregationConditions);
    clonedAggregationConditions = {
      ...clonedAggregationConditions,
      rangeFilters: clonedAggregationConditions.rangeFilters.filter((filter) => filter.fieldName !== 'weekId'),
      termFilters: []
    };
    //  Sets one object within the aggregations.queryConditions.rangeFilters as the specific main time period
    const weekIdRangeFilter = {
      fieldName: 'weekId',
      minValue: mainTimePeriod.startWeek,
      maxValue: mainTimePeriod.endWeek
    };

    const aggregationTermFilter = {
      fieldName: `retailerId`,
      values: [`${retailer.id}`]
    };
    clonedAggregationConditions.rangeFilters.push(weekIdRangeFilter);
    clonedAggregationConditions.termFilters.push(aggregationTermFilter);
    marketShareAggregationConditions.termFilters.push(aggregationTermFilter);

    const comparisonRangeFilters = [
      {
        fieldName: 'weekId',
        minValue: comparisonTimePeriod.startWeek,
        maxValue: comparisonTimePeriod.endWeek
      }
    ];

    this.setState({
      queryConditions: entityConditions,
      aggregationConditions: clonedAggregationConditions,
      marketShareAggregationConditions,
      comparisonRangeFilters
    });
  };

  handleEntityClick = (entity) => this.setState({ subHeaderEntitySelection: entity });

  handleMetricsFieldChange = (evt) => {
    const { value } = evt.target;
    const { aggregationMetricFields } = this.state;
    aggregationMetricFields.forEach((metricsField) => {
      metricsField.isSelected = metricsField.name === value;
    });

    this.setState({ aggregationMetricFields });
  };

  handleSearchInputChange = (evt, name) => {
    const { searchListObject } = this.state;
    //  Gets the array from props every time, then filters it and then updates state
    const clonedSearchObj = _cloneDeep(this.buildSearchListObject(this.props));
    this.setState({
      searchListObject: {
        ...searchListObject,
        [name]: clonedSearchObj[name].filter(
          (val) => val.displayName.toLowerCase().indexOf(evt.target.value.toLowerCase()) !== -1
        )
      }
    });
  };

  handleSearchItemClick = (value, name) => {
    const { history } = this.props;
    const searchParams = new URLSearchParams(window.location.search);
    if (this.props.app.name === 'advertising') {
      return;
    }
    searchParams.set('selectedType', name);
    searchParams.set('selectedId', value.id);
    const newParams = searchParams.toString();
    history.push(`?${newParams}`);
  };

  // Built with data from Redux
  buildSelectedSearchItem = (entityType, props) => {
    const { allSuperUserCategories, brandsFollowing, categories, segments } = props;
    const { mySegments, mySearchTermLists } = segments;
    const entitiesByType = {
      categories: this.isSuperUser() ? allSuperUserCategories : categories,
      brand: brandsFollowing,
      segment: mySegments,
      searchtermlist: mySearchTermLists
    };
    if (entityType === 'category') {
      // set default category selection to NOT be All Categories
      // All Categories is not always the first element, so we need to search
      const allCatIndex = entitiesByType.categories.findIndex((cat) => cat.categoryId !== 0);
      return entitiesByType.categories && allCatIndex > -1 ? entitiesByType.categories[allCatIndex] : null;
    }

    return entitiesByType[entityType] && entitiesByType[entityType].length > 0 ? entitiesByType[entityType][0] : null;
  };

  isSuperUser = () => {
    return this.props.user.config.isStacklineSuperUser;
  };

  // Built with data from Redux
  buildSearchListObject = (props) => {
    const { allSuperUserCategories, app, brandsFollowing, categories, segments } = props;
    const { mySegments, teamSegments, mySearchTermLists, teamSearchTermLists } = segments;
    const searchListObj = {
      categories: this.isSuperUser() ? allSuperUserCategories : categories,
      brands: brandsFollowing,
      mySegments,
      teamSegments,
      mySearchTermLists,
      teamSearchTermLists
    };

    if (app.name === 'advertising') {
      const targetSegments = mySegments.filter((item) => item.segment.segmentType === 'targetingText');
      const productSegments = mySegments.filter((item) => item.segment.segmentType === 'stacklineSku');
      const campaignSegments = mySegments.filter((item) => item.segment.segmentType === 'campaignId');
      searchListObj.targetSegments = targetSegments;
      searchListObj.productSegments = productSegments;
      searchListObj.campaignSegments = campaignSegments;
    }
    return searchListObj;
  };

  render() {
    const {
      aggregationConditions,
      queryConditions,
      comparisonRangeFilters,
      marketShareAggregationConditions,
      aggregationMetricFields,
      pageLayout,
      searchListObject,
      selectedItem,
      selectedOption,
      subHeaderEntitySelection
    } = this.state;
    const {
      app,
      entityService: { mainEntity }
    } = this.props;

    const params = this.getQueryParams();
    const entityType = params.entityType || 'category';
    const entityDefinition = ENTITIES[app.name][entityType];

    const currentSegment = selectedItem ? selectedItem.segment : null;
    const selectedMetricField = aggregationMetricFields.find(_prop('isSelected'));

    const HydratedSavedSearchLists = () => (
      <SavedSearchLists
        entityType={entityType}
        selectedMetricField={selectedMetricField}
        aggregationMetricFields={aggregationMetricFields}
        searchListObject={searchListObject}
        pageLayout={pageLayout}
        selectedItem={selectedItem}
        queryConditions={queryConditions}
        aggregationConditions={aggregationConditions}
        entityDefinition={entityDefinition}
        handleSearchInputChange={this.handleSearchInputChange}
        handleSearchItemClick={this.handleSearchItemClick}
      />
    );

    if (_isEmpty(mainEntity)) {
      return null;
    }

    const buListingEnabled = entityType === 'segment' && app.name !== 'advertising'; // now enabled for all clients

    if (app.name === 'advertising') {
      return <AdSegmentListing />;
    }

    if (shouldShowNewBeacon()) {
      if (entityType === 'category') {
        return <CategorySummary />;
      }
    }
    return (
      <>
        {app.name !== 'advertising' && entityType !== 'product' && selectedItem ? (
          <div className="summary-page__summaries-container">
            <div className="summary-page__summaries-item">
              <SummaryDataContainer
                currentSegment={currentSegment}
                isStacked={false}
                entityDefinition={entityDefinition}
                queryConditions={queryConditions}
                subHeaderEntitySelection={subHeaderEntitySelection}
                selectedMetric={selectedMetricField}
                selectedItem={selectedItem}
                title={selectedItem.displayName || selectedItem.name}
                onDelete={this.handleDeleteSegment}
                aggregationConditions={aggregationConditions}
                aggregationMetricFields={[selectedMetricField]}
                comparisonRangeFilters={comparisonRangeFilters}
                marketShareAggregationConditions={marketShareAggregationConditions}
              >
                <SummarySubHeader
                  entityDefinition={entityDefinition}
                  handleEntityClick={this.handleEntityClick}
                  displayedEntities={selectedOption.entities}
                  subHeaderEntitySelection={subHeaderEntitySelection}
                  segmentButtonIcon={<CreateNewSavedSearchButton entityType={entityType} />}
                >
                  <div style={{ textAlign: 'right', flex: '1 1', marginRight: '-15px' }}>
                    <EntityMetricDropDown
                      handleMetricsFieldChange={this.handleMetricsFieldChange}
                      metricFields={aggregationMetricFields}
                    />
                  </div>
                </SummarySubHeader>
              </SummaryDataContainer>
            </div>
          </div>
        ) : null}

        {buListingEnabled ? (
          <SegmentAndBUListing HydratedSavedSearchLists={HydratedSavedSearchLists} />
        ) : (
          HydratedSavedSearchLists()
        )}
      </>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, [
    'app',
    'user',
    'allWeekIdsByRetailerId',
    'entityService',
    'brandsFollowing',
    'categories',
    'allSuperUserCategories',
    'comparisonTimePeriod',
    'filters',
    'mainTimePeriod',
    'retailer',
    'segments',
    'subCategories',
    'allSuperUserSubCategories'
  ]);

const mapDispatchToProps = {
  fetchMainEntity: entityOperations.fetchEntity
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));
