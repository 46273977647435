import React, { useState } from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import { SearchTextField } from 'src/components/BeaconRedesignComponents/SearchInput/SearchInputBar';
import CompareScrollableContainer from './CompareScrollableContainer';
import CompareOption from './CompareOption';
import CompareProductOption from './CompareProductOption';
import { useAppSelector, useDebouncedValue } from 'src/utils/Hooks';
import { useChartComparison } from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/ChartComparisonProvider';
import { ProductEntity } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/types';
import useProductsForCompare from './hooks/useProductsForCompare';
import CompareProductLoadingRow from './CompareProductLoadingRow';
import { useAllSegments } from 'src/utils/Hooks/reduxSelectors';

interface CompareOptionDefinition {
  /**
   * List of group items to render
   */
  items: readonly any[];
  /**
   * Row rendering definition for each group item
   */
  render: (item: any, index: number) => JSX.Element;
  /**
   * Filter function for each group item when the
   * search bar is used
   */
  filter: (item: any) => boolean;
}

interface CompareGroupsProps {
  handleClose: () => void;
}

enum CompareGroup {
  Categories = 'categories',
  Subcategories = 'subcategories',
  Brands = 'brands',
  Segments = 'segments',
  Products = 'products'
}

/**
 * Maps a group type to its compare group
 * so that when a group is selected, we can
 * default to the correct compare group
 */
const GROUP_BY_TO_COMPARE_GROUP = {
  categoryId: CompareGroup.Categories,
  subCategoryId: CompareGroup.Subcategories,
  brandId: CompareGroup.Brands,
  segmentId: CompareGroup.Segments,
  stacklineSku: CompareGroup.Products
} as const;

/**
 * Displays group comparison options
 */
export default function CompareGroups({ handleClose }: CompareGroupsProps) {
  const { setGroupComparator, groupComparator } = useChartComparison();
  const [selectedGroup, setSelectedGroup] = useState<CompareGroup>(
    groupComparator && GROUP_BY_TO_COMPARE_GROUP[groupComparator.groupByField]
      ? GROUP_BY_TO_COMPARE_GROUP[groupComparator.groupByField]
      : CompareGroup.Categories
  );
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebouncedValue(search);
  const retailerId = useAppSelector((state) => +state.retailer.id);
  const categories = useAppSelector((state) => state.categories.filter((category) => category.id !== 0));
  const subcategories = useAppSelector((state) => state.subCategories);
  const brands = useAppSelector((state) =>
    state.brandsFollowing.filter((brand) => brand.id !== 0 && brand.retailerIds.includes(retailerId))
  );
  const segments = useAllSegments();

  const {
    products,
    fetchNextProducts,
    isLoading,
    reset: resetCompareProducts
  } = useProductsForCompare(debouncedSearch);

  /**
   * Maps a group type to its rendering definitions
   */
  const optionDefinitions: Record<string, CompareOptionDefinition> = {
    [CompareGroup.Categories]: {
      items: categories,
      render: (category) => (
        <CompareOption
          key={category.id}
          label={category.name}
          selected={
            groupComparator && groupComparator.groupByField === 'categoryId' && groupComparator.groupId === category.id
          }
          onClick={() => {
            setGroupComparator({ groupByField: 'categoryId', groupId: category.id, name: category.name });
            handleClose();
          }}
        />
      ),
      filter: (category) => category.name.toLowerCase().includes(search.toLowerCase())
    },
    [CompareGroup.Subcategories]: {
      items: subcategories,
      render: (subcategory) => (
        <CompareOption
          key={subcategory.id}
          label={subcategory.name}
          selected={
            groupComparator &&
            groupComparator.groupByField === 'subCategoryId' &&
            groupComparator.groupId === subcategory.id
          }
          onClick={() => {
            setGroupComparator({
              groupByField: 'subCategoryId',
              groupId: subcategory.id,
              name: subcategory.name
            });
            handleClose();
          }}
        />
      ),
      filter: (subcategory) => subcategory.name.toLowerCase().includes(search.toLowerCase())
    },
    [CompareGroup.Brands]: {
      items: brands,
      filter: (brand) => brand.name.toLowerCase().includes(search.toLowerCase()),
      render: (brand) => (
        <CompareOption
          key={brand.id}
          label={brand.name}
          selected={
            groupComparator && groupComparator.groupByField === 'brandId' && groupComparator.groupId === brand.id
          }
          onClick={() => {
            setGroupComparator({ groupByField: 'brandId', groupId: brand.id, name: brand.name });
            handleClose();
          }}
        />
      )
    },
    [CompareGroup.Products]: {
      items: [...products, ...(isLoading ? Array.from({ length: 20 }).map(() => ({ loading: true })) : [])],
      filter: () => true,
      render: (product: ProductEntity & { loading?: boolean }, index) => {
        if (product.loading) {
          return <CompareProductLoadingRow key={`loading-${index}`} />;
        }

        return (
          <CompareProductOption
            key={product.stacklineSku}
            title={product.title}
            stacklineSku={product.stacklineSku}
            retailerSku={product.retailerSku}
            selected={
              groupComparator &&
              groupComparator.groupByField === 'stacklineSku' &&
              groupComparator.groupId === product.id
            }
            onClick={() => {
              setGroupComparator({ groupByField: 'stacklineSku', groupId: product.id, name: product.title });
              handleClose();
              resetCompareProducts();
            }}
          />
        );
      }
    },
    [CompareGroup.Segments]: {
      items: segments,
      filter: (segment) => segment.displayName.toLowerCase().includes(search.toLowerCase()),
      render: (segment) => (
        <CompareOption
          key={segment.id}
          label={segment.displayName}
          selected={
            groupComparator && groupComparator.groupByField === 'segmentId' && groupComparator.groupId === segment.id
          }
          onClick={() => {
            setGroupComparator({ groupByField: 'segmentId', groupId: segment.id, name: segment.displayName });
            handleClose();
          }}
        />
      )
    }
  } as const;

  const groupDefinition = optionDefinitions[selectedGroup];

  return (
    <Flex gap="md" flexDirection="column">
      <Text variant="subtitle1">Groups</Text>
      <SlDropdownMenu
        defaultLabel=""
        options={[
          {
            id: CompareGroup.Categories,
            label: 'Categories'
          },
          {
            id: CompareGroup.Subcategories,
            label: 'Subcategories'
          },
          {
            id: CompareGroup.Brands,
            label: 'Brands'
          },
          {
            id: CompareGroup.Segments,
            label: 'Segments'
          },
          {
            id: CompareGroup.Products,
            label: 'Products'
          }
        ].filter(({ id }) => optionDefinitions[id].items.length > 0)}
        selectedId={selectedGroup}
        onChange={(option) => setSelectedGroup(option.id as CompareGroup)}
        stacklineButtonProps={{
          textSx: {
            fontWeight: 'normal'
          },
          buttonSx: {
            width: '139px',
            justifyContent: 'space-between'
          }
        }}
      />
      <SearchTextField
        placeholder={`Search ${selectedGroup}`}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        sx={{
          width: '100%'
        }}
      />
      {groupDefinition && (
        <CompareScrollableContainer
          maxHeight="369px"
          onScrollToBottom={selectedGroup === CompareGroup.Products ? fetchNextProducts : undefined}
        >
          {groupDefinition.items
            .filter(groupDefinition.filter)
            .map((item, index) => groupDefinition.render(item, index))}
        </CompareScrollableContainer>
      )}
    </Flex>
  );
}
