import queryString from 'qs';

interface DefaultFilterObject {
  filter: {
    [key: string]: any;
  };
}

export enum FilterKey {
  Adjustment = 'adjustment',
  Category = 'category',
  Subcategory = 'subcategory',
  Segment = 'segment'
}

/**
 *
 * @param filterValues obj {FilterKey }
 * @returns
 */
export const generateFilterUrlString = (
  filterValues: { [filterType: string]: any[] } // Pass an object with filter types as keys and arrays of values
): { generatedFilterParams: string } => {
  const defaultFilterObj: DefaultFilterObject = {
    filter: {}
  };

  // Iterate over the keys of filterValues
  Object.keys(filterValues).forEach((filterType) => {
    const filterValue = filterValues[filterType];

    switch (filterType) {
      case 'category':
        if (filterValue.length > 0) {
          defaultFilterObj.filter.c = filterValue.map((item) => ({ i: item.id }));
        }
        break;
      case 'subcategory':
        if (filterValue.length > 0) {
          defaultFilterObj.filter.sc = filterValue.map((item) => ({ i: item.id }));
        }
        break;
      case 'segment':
        if (filterValue.length > 0) {
          defaultFilterObj.filter.sg = filterValue.map((item) => ({ i: item.id }));
        }
        break;
      // Add more cases for other filter types as needed
      default:
        break;
    }
  });
  let stringifiedFilterParams = null;
  // JSON.stringify the default filter object
  if (Object.keys(defaultFilterObj.filter).length > 0) {
    stringifiedFilterParams = `&${queryString.stringify({ filter: JSON.stringify(defaultFilterObj.filter) })}`;
  }

  // Return the generated filter parameters
  return { generatedFilterParams: stringifiedFilterParams };
};
