import axios from 'axios';
import { OmniBaseRequestBody } from 'src/components/Omni/omniRequestUtils';

import { isArray, get, orderBy } from 'lodash';

export const OmniDonutEndPointReview = 'omni/reviews/getStarsRatingStats' as const;

const OmniDonutEndPointContent = 'omni/content/getAvgContentScore';

export interface OmniDonutData {
  color?: string;
  name: string;
  fieldId: number | string;
  y: number;
}

const processDataOmniDonutData = (data: any): OmniDonutData[] => {
  const dataNeedProcess = get(data, 'stars.buckets', []);
  if (!isArray(dataNeedProcess)) {
    return [];
  }

  const processedData = dataNeedProcess.map((aData: any) => {
    const fieldId = get(aData, 'key', 1);
    const y = get(aData, 'stars_value_count.value', 1);

    return {
      name: `${fieldId} stars`,
      fieldId,
      y: Number(y)
    };
  });
  return orderBy(processedData, 'fieldId', 'desc');
};

export const OmniDonutAsync = (requestBody: OmniBaseRequestBody) => async () => {
  const res = await axios.post(OmniDonutEndPointReview, requestBody);
  if (res && res.status === 200) {
    const { data } = res.data;
    return processDataOmniDonutData(data);
  } else {
    throw Error('Can not fetch the data');
  }
};

const processContentData = (data: any) => {
  const dataNeedProcess = get(data, 'beaconClientId.buckets', []);
  if (!isArray(dataNeedProcess)) {
    return [];
  }
  const processedData = dataNeedProcess.map((aData: any) => {
    const fieldId = get(aData, 'key', 1);
    const y = get(aData, 'aggsByChild.contentScore_avg.value', 1);

    return {
      name: `${fieldId} stars`,
      fieldId,
      y: Number(y)
    };
  });
  return processedData;
};

export const OmniDonutAsyncContent = (requestBody: OmniBaseRequestBody) => async () => {
  const res = await axios.post(OmniDonutEndPointContent, requestBody);
  if (res && res.status === 200) {
    const { data } = res.data;
    return processContentData(data);
  } else {
    throw Error('Can not fetch the data');
  }
};
