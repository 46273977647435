import React, { useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/system/Box';
import { Text, useStacklineTheme } from '@stackline/ui';
import { GenericNavigationTabs } from 'src/components/BeaconRedesignComponents/SubtabNav/SubtabNavigation';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { useSettingsIntegrationsConfig } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Integrations/SettingsIntegrationsConfig';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import { useAppSelector, useSnackbar } from 'src/utils/Hooks';
import { formatError } from 'src/routes/UserAccount/UserAccountUpload/UserAccountUpload';
import SettingsIntegrationsApi from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Integrations/SettingsIntegrationsApi';
import axios from 'axios';
import { AMAZON_RETAILER_IDS } from 'src/store/modules/retailer/reducers';
import IntegrationsActionBox from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Integrations/IntegrationsActionBox';
import { BlankDocumentsIcon, CheckedDocumentsIcon, FileStorageIcon } from 'src/components/SvgIcons';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';

const DROPDOWN_SKELETON_SIZE = {
  width: 150,
  height: 36
};

export enum IntegrationTab {
  Content = 'content',
  Prices = 'mapprice',
  RetailerUpload = 'retailerUpload',
  COGS = 'cogs',
  API = 'api'
}

export enum IntegrationsTabsDisplayName {
  content = 'Content',
  mapprice = 'Prices',
  retailerUpload = 'Retailer Upload',
  cogs = 'COGs',
  api = 'API'
}

interface IntegrationsNavigationTabsProps {
  tab: IntegrationTab;
  setTab: (tab: IntegrationTab) => void;
}

export const IntegrationsNavigationTabs = ({ tab, setTab }: IntegrationsNavigationTabsProps) => {
  const {
    config: { allRetailerIds }
  } = useAppSelector((state) => state.user);
  const isApiTabAvailable = useMemo(
    () => allRetailerIds.some((retailerId) => AMAZON_RETAILER_IDS.includes(String(retailerId))),
    [allRetailerIds]
  );
  return (
    <GenericNavigationTabs
      tab={tab}
      tabs={[
        {
          label: IntegrationsTabsDisplayName.content,
          onClick: () => {
            setTab(IntegrationTab.Content);
          },
          value: IntegrationTab.Content
        },
        {
          label: IntegrationsTabsDisplayName.mapprice,
          onClick: () => {
            setTab(IntegrationTab.Prices);
          },
          value: IntegrationTab.Prices
        },
        {
          label: IntegrationsTabsDisplayName.retailerUpload,
          onClick: () => {
            setTab(IntegrationTab.RetailerUpload);
          },
          value: IntegrationTab.RetailerUpload
        },
        {
          label: IntegrationsTabsDisplayName.cogs,
          onClick: () => {
            setTab(IntegrationTab.COGS);
          },
          value: IntegrationTab.COGS
        },
        ...(isApiTabAvailable
          ? [
              {
                label: IntegrationsTabsDisplayName.api,
                onClick: () => {
                  setTab(IntegrationTab.API);
                },
                value: IntegrationTab.API
              }
            ]
          : [])
      ]}
    />
  );
};

/**
 *
 * Settings integrations layout
 * Content Prices RetailerUpload Cogs will share layouts based on the tab and integrationConfig object
 */
const SettingsIntegrationsPageLayout = () => {
  const theme = useStacklineTheme();
  const [tab, setTab] = useState(IntegrationTab.Content);
  const isApiTab = tab === IntegrationTab.API;
  const {
    isInitialLoading,
    displayName,
    description,
    defaultStatusUploadName,
    retailersOption,
    templateUrl,
    downloadTemplateFunction,
    fetchLastUpdatedFunction,
    downloadCurrentIntegrationFunction,
    currentIntegrationUrl,
    defaultRetailer,
    fileUploadUrl,
    customTemplateDescription
  } = useSettingsIntegrationsConfig(tab);

  const [selectedRetailerInfo, setSelectedRetailerInfo] = useState(defaultRetailer);
  const [lastUpdatedTime, setLastUpdatedTime] = useState<null | string>(null);
  const { showSnackbar } = useSnackbar();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!isInitialLoading && retailersOption.length > 0) {
      setSelectedRetailerInfo(defaultRetailer);
    }
  }, [isInitialLoading]);

  const fetchUpdatedTime = async () => {
    // API tab does not need fetchLastUpdatedFunction
    if (selectedRetailerInfo && fetchLastUpdatedFunction) {
      const updatedTimeString = await fetchLastUpdatedFunction({
        tabInfo: tab,
        retailerId: selectedRetailerInfo.id
      });
      const { uploadedTime, errorMessage } = updatedTimeString;
      if (errorMessage) {
        setLastUpdatedTime(errorMessage);
      } else {
        setLastUpdatedTime(uploadedTime);
      }
    }
  };

  useEffect(() => {
    fetchUpdatedTime();
  }, [selectedRetailerInfo]);

  useEffect(() => {
    setSelectedRetailerInfo(defaultRetailer);
  }, [tab]);

  // Initial loading is when we don't fetch the mainEntity or retailer info from the server
  if (isInitialLoading || (!retailersOption && !selectedRetailerInfo)) {
    return null;
  }

  const handleDropdownSelect = (retailerInfo) => {
    setSelectedRetailerInfo(retailerInfo);
  };

  const handleFileUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);

      try {
        const urlWithRetailerParam = `${fileUploadUrl}&retailer=${selectedRetailerInfo.id}`;
        const res = await axios.post(urlWithRetailerParam, formData);

        if (res.data === false) {
          return showSnackbar({ type: 'error', message: formatError });
        } else if (res.data) {
          fetchUpdatedTime();
          showSnackbar({ type: 'success', message: 'Upload complete. Your file was successfully uploaded.' });
        } else {
          throw Error('Invalid Response');
        }
      } catch (e) {
        console.error('failed to upload', e);
        showSnackbar({ type: 'error', message: e.response.data });
      }
    }

    return false;
  };

  const handleDownloadCurrentIntegration = async () => {
    const { errorMessage } = await downloadCurrentIntegrationFunction({
      tabInfo: tab,
      retailerId: selectedRetailerInfo.id
    });
    if (errorMessage) {
      showSnackbar({
        type: 'error',
        message: errorMessage
      });
    }
  };

  if (isApiTab) {
    return <SettingsIntegrationsApi tab={tab} setTab={setTab} />;
  }

  return (
    <Box>
      <Text variant="h2">Integrations</Text>
      <Box marginBottom={theme.spacing.lg}>
        <IntegrationsNavigationTabs tab={tab} setTab={setTab} />
      </Box>

      <Flex flexDirection="column">
        <Text variant="h4">{displayName}</Text>
      </Flex>

      <Flex marginTop="8px">{description}</Flex>

      <Flex marginTop="8px">
        <Text variant="subtitle2">Last Update: </Text> <Text variant="body2">&nbsp; {lastUpdatedTime}</Text>
      </Flex>

      <Flex marginTop={theme.spacing.md} marginBottom={theme.spacing.lg}>
        {selectedRetailerInfo ? (
          <SlDropdownMenu
            open={false}
            defaultLabel={selectedRetailerInfo.label}
            options={retailersOption}
            selectedId={selectedRetailerInfo.id}
            onChange={handleDropdownSelect}
            stacklineButtonProps={{
              textSx: {
                fontWeight: 'normal'
              }
            }}
          />
        ) : (
          <SlSkeleton width={DROPDOWN_SKELETON_SIZE.width} height={DROPDOWN_SKELETON_SIZE.height} />
        )}
      </Flex>

      <IntegrationsActionBox
        icon={<BlankDocumentsIcon />}
        title="Get a template"
        action={{
          description: customTemplateDescription || `Download a blank template to populate ${defaultStatusUploadName}.`,
          descriptionAction: () => downloadTemplateFunction(templateUrl)
        }}
      />

      {currentIntegrationUrl && (
        <IntegrationsActionBox
          icon={<CheckedDocumentsIcon />}
          title={`View ${defaultStatusUploadName}`}
          action={{
            description: `Download the ${defaultStatusUploadName} you've uploaded previously.`,
            descriptionAction: () => handleDownloadCurrentIntegration()
          }}
        />
      )}
      {/* Hidden input for getting upload file */}
      <input
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        onChange={(evt) => handleFileUpload(evt)}
        ref={fileInputRef}
      />

      <IntegrationsActionBox
        icon={<FileStorageIcon />}
        title={`Upload ${defaultStatusUploadName}`}
        action={{
          description: `Upload a CSV of your new ${defaultStatusUploadName} for ${
            selectedRetailerInfo && selectedRetailerInfo.label
          }.`,
          descriptionAction: () => handleFileUploadButtonClick()
        }}
      />
    </Box>
  );
};

export default SettingsIntegrationsPageLayout;
