import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import BeaconDropdownMetricColumnChart from 'src/components/BeaconRedesignComponents/BeaconMetricColumnChart/BeaconDropdownMetricColumnChart';
import TopEntityChartV2Loading from 'src/components/BeaconRedesignComponents/TopEntityChartV2/TopEntityChartV2Loading';
import { useMainEntityType } from 'src/utils/Hooks/reduxSelectors';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import ProductsWithUnitsSoldSplineChart from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Sales/ProductsWithUnitsSoldSplineChart';
import UnitsSoldProductGrid from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Summary/Sales/UnitsSold/UnitsSoldProductGrid';

/**
 * Sales Units Sold Page
 */
const UnitsSoldPage = () => {
  const entityType = useMainEntityType();

  return (
    <BeaconPageContainer>
      <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
        <BeaconComparableSplineChart indexName="sales" fieldName="unitsSold" />
        <BeaconComparableSplineChart indexName="sales" fieldName="retailPrice" />
        <BeaconDropdownMetricColumnChart
          beaconChartWithLegendPropsBuilder={(props) => ({
            ...props,
            loadingComponent: <TopEntityChartV2Loading />
          })}
          indexName="sales"
          fieldName="unitsSold"
          dropdownOptions={[
            {
              id: 'subCategoryId',
              label: 'Subcategory'
            },
            {
              id: 'categoryId',
              label: 'Category'
            },
            {
              id: 'brandId',
              label: 'Brand'
            }
          ].filter((option) => !option.id.toLowerCase().startsWith(entityType))}
          titlePrefix="Units Sold by"
        />
        {entityType !== 'product' && <ProductsWithUnitsSoldSplineChart />}
        <UnitsSoldProductGrid />
      </Flex>
    </BeaconPageContainer>
  );
};

export default UnitsSoldPage;
