// Utility functions for filtering adjustment data, building payloads, etc

import {
  PlanTypeOption,
  ContentScoreFields
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import {
  AdjustmentChangeType,
  PaidTrafficMetrics
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAdSpendProjection';
import { DraftedPaidTrafficResponse } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useCompleteAdjustmentData/types';
import { ForecastsAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import { AdjustmentPlanType } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';

/**
 * Removes paid traffic adjustments from the adjustmentsByWeekId data
 */
export const removePaidTrafficAdjustments = (document: ForecastsAdjustmentData) => {
  return document.planType !== PlanTypeOption.PaidTraffic;
};

/**
 * Gets paid traffic adjustments from the adjustmentsByWeekId data
 */
export const getPaidTrafficAdjustments = (document: ForecastsAdjustmentData) => {
  return document.planType === PlanTypeOption.PaidTraffic;
};

/**
 * Gets draft adjustments from the adjustmentsByWeekId data
 */
export const getDraftAdjustments = (document: ForecastsAdjustmentData) => {
  return document.status === 'Draft';
};

/**
 * Removes draft adjustments from the adjustmentsByWeekId data
 */
export const removeDraftAdjustments = (document: ForecastsAdjustmentData) => {
  return document.status !== 'Draft';
};

/**
 * Comparator function for sorting adjustments by net impact
 */
export const byNetImpact = (documentA: ForecastsAdjustmentData, documentB: ForecastsAdjustmentData) => {
  return documentB.netImpact - documentA.netImpact;
};

/**
 * Builds the payload for a drafted net impact amounts
 */
export const buildDraftNetImpactPayloads = (adjustmentDatum: ForecastsAdjustmentData) => {
  const {
    adjustmentId,
    beaconClientId,
    retailerId,
    retailerSku,
    createdByUserId,
    planType,
    startWeekId,
    endWeekId,
    adjustmentChangeValue,
    stacklineSku,
    product,
    reviewAvgRating,
    cpc,
    cpcFromUserFlag,
    adSpend,
    reviewCount,
    promotionType
  } = adjustmentDatum;
  // Get category and subcategory ID
  const { categoryId, subCategoryId } = product;

  const additionalProperties = [AdjustmentPlanType.ContentScore].includes(planType)
    ? {
        titleScore: adjustmentDatum[ContentScoreFields.TitleScore],
        bulletScore: adjustmentDatum[ContentScoreFields.BulletScore],
        imageScore: adjustmentDatum[ContentScoreFields.ImageScore],
        videoScore: adjustmentDatum[ContentScoreFields.VideoScore],
        aplusScore: adjustmentDatum[ContentScoreFields.A_Score]
      }
    : [AdjustmentPlanType.RatingsReviews].includes(planType)
    ? {
        reviewCount: reviewCount || 0,
        reviewAvgRating: reviewAvgRating || 0
      }
    : [AdjustmentPlanType.PaidTraffic].includes(planType)
    ? {
        adSpend,
        cpc,
        cpcFromUser: cpcFromUserFlag
      }
    : [AdjustmentPlanType.RetailPrice].includes(planType)
    ? {
        promotionType: promotionType || ''
      }
    : {
        reviewCount: 0,
        reviewAvgRating: 0
      };

  return {
    baseAdjustmentRequest: {
      adjustmentId,
      beaconClientId,
      retailerId,
      retailerSku,
      userId: createdByUserId,
      planType,
      startWeekId,
      endWeekId,
      ignoreConflictWithAdjustmentIds: [adjustmentId]
    },
    additionalProperties,
    adjustmentChangeType: 'Absolute',
    adjustmentChangeValue,
    stacklineSku,
    categoryId,
    subCategoryId,
    requestType: 'Create',
    shouldPublish: false
  };
};

/**
 * Builds the payload for drafted paid traffic adjustments in order to get the paid traffic metrics
 */
export const createDraftPaidTrafficPayloads = (adjustmentDatum: ForecastsAdjustmentData) => {
  const {
    adjustmentId,
    beaconClientId,
    retailerId,
    retailerSku,
    planType,
    startWeekId,
    endWeekId,
    stacklineSku,
    product,
    adSpend,
    cpc,
    cpcFromUserFlag,
    createdByUserId
  } = adjustmentDatum;

  const { categoryId, subCategoryId } = product;

  const additionalProperties = {
    adSpend,
    cpc,
    cpcFromUser: cpcFromUserFlag
  };

  const payload = {
    baseAdjustmentRequest: {
      beaconClientId,
      retailerId,
      retailerSku,
      userId: createdByUserId,
      planType,
      startWeekId,
      endWeekId,
      adjustmentId,
      ignoreConflictWithAdjustmentIds: [adjustmentId]
    },
    additionalProperties,
    adjustmentChangeType: AdjustmentChangeType.Absolute,
    adjustmentChangeValue: 0,
    stacklineSku,
    categoryId,
    subCategoryId,
    requestType: 'Create',
    shouldPublish: false
  };

  return payload;
};

/**
 * Creates a map of adjustment IDs to a published metric amount.
 * A published metric amount will either be a net impact amount or a paid traffic metric amount.
 */
export const createPublishedDataMap = (accumulator: Map<string, number>, currentDocument: any) => {
  accumulator.set(currentDocument.fieldId, currentDocument.value);
  return accumulator;
};

/**
 * Creates a map of adjustment IDs to a drafted net impact amount (weighted impact).
 */
export const createDraftedNetImpactMap = (accumulator: Map<string, number>, currentDocument: any) => {
  const { adjustmentId } = currentDocument.baseAdjustmentRequest;
  accumulator.set(adjustmentId, currentDocument.weightedImpact);
  return accumulator;
};

/**
 * Calculates the average paid traffic metrics for a given set of paid traffic metrics.
 * This is used to calculate average CPC, ad spend, paid traffic, etc, over a set of weeks.
 */
export const calculatePaidTrafficAverages = (paidTrafficMetrics: PaidTrafficMetrics[]) => {
  const summedMetrics = paidTrafficMetrics.reduce(
    (accumulator: Omit<PaidTrafficMetrics, 'weekId'>, metricsByWeek: PaidTrafficMetrics) => {
      Object.keys(metricsByWeek).forEach((key) => {
        if (key !== 'weekId') {
          accumulator[key] = (accumulator[key] || 0) + metricsByWeek[key];
        }
      });
      return accumulator;
    },
    {
      cpc: 0,
      ad_spend: 0,
      paid_traffic: 0,
      ad_sales: 0,
      roas: 0
    } as Omit<PaidTrafficMetrics, 'weekId'>
  );

  return Object.entries(summedMetrics).reduce(
    (acc, [key, value]) => {
      acc[key] = value / paidTrafficMetrics.length || 1;
      return acc;
    },
    { cpc: 0, ad_spend: 0, paid_traffic: 0, ad_sales: 0, roas: 0 } as Omit<PaidTrafficMetrics, 'weekId'>
  );
};

/**
 * Creates a map of adjustment IDs to a drafted paid traffic metric amounts.
 */
export const createDraftedPaidTrafficMap = (
  accumulator: Map<string, Omit<PaidTrafficMetrics, 'weekId'>>,
  currentDocument: DraftedPaidTrafficResponse
) => {
  const { adjustmentId } = currentDocument.baseAdjustmentRequest;

  const values = calculatePaidTrafficAverages(currentDocument.paidTrafficMetrics);
  accumulator.set(adjustmentId, values);
  return accumulator;
};
