import { AD_PLATFORM_SETTING_TYPE_BY_CLIENT } from 'sl-ad-campaign-manager-data-model';

import { adPlatformSettingsByClientOperations } from 'src/store/modules/adManager/adPlatformSettingsByClient';
import { getAdPlatformSettingsByClientOfType } from 'src/store/modules/adManager/adCampaignBuilder/selectors';
import ReduxStore from 'src/types/store/reduxStore';
import Creators from './actions';
import { parseAdPortfolio } from './selectors';
import { AppName } from 'sl-api-connector';
import { getAppName } from 'src/utils/app';
import { store } from 'src/main';

export const { receiveAdPortfolios } = Creators;

export const fetchAdPortfolios =
  (fetchAdPlatformSettings = true) =>
  async (dispatch: (action: any) => void, getState: () => ReduxStore) => {
    const retailerId = getState().retailer.id;
    if (getAppName() !== AppName.Advertising) {
      return dispatch(receiveAdPortfolios([]));
    }

    if (fetchAdPlatformSettings) {
      await dispatch(adPlatformSettingsByClientOperations.fetchAdPlatformSettingsByClient());
    }
    const adPortfolios = getAdPlatformSettingsByClientOfType(AD_PLATFORM_SETTING_TYPE_BY_CLIENT.PORTFOLIO_ID)(
      getState()
    );
    // TODO: Look into whether or not we have to filter this down by platform.  However, we have no concept of platform
    // outside of ad builder right now...
    // Custom Filtering Logic Update (for CPO - SBD) - Check if the client has any portfolio tags specified in dynamoDb
    try {
      const { user } = store.getState();
      // read redux
      const tagsString = user.config.TagsForGranularViews;
      const tags = tagsString ? JSON.parse(tagsString) : [];
      if (tags && tags.length > 0) {
        const parsedAdPortfolios = parseAdPortfolio(adPortfolios, retailerId, tags);
        return dispatch(receiveAdPortfolios(parsedAdPortfolios));
      } else {
        return dispatch(receiveAdPortfolios(parseAdPortfolio(adPortfolios, retailerId)));
      }
    } catch (error) {
      console.error('Failed to fetch company division config:', error);
    }
    return null;
  };
