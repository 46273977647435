import React from 'react';
import { Text, SlColumn, SlRow } from '@stackline/ui';

interface UnitsSoldPreviewProps {
  metric: string;
}

const UnitsSoldPreview = ({ metric }: UnitsSoldPreviewProps) => {
  return (
    <SlColumn spacing="xs">
      <Text transform="uppercase" variant="subtitle4" color="secondary">
        Units Sold Change
      </Text>
      <SlRow spacing="sm" verticalPosition="center">
        <Text variant="body0">{metric}</Text>
      </SlRow>
    </SlColumn>
  );
};

export default UnitsSoldPreview;
