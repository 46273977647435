import colors from 'src/utils/colors';
import { getNewBeaconWidgetStyles } from 'src/utils/styleUtils';

const enableExports = true;

const VIEWS = {
  donutChart: {
    name: 'donutChart',
    chartPropsOverride: {
      chart: {
        type: 'pie'
      },
      disablePointSelect: false,
      enableMultiSelect: true
    }
  },
  columnChart: {
    name: 'columnChart',
    chartPropsOverride: {
      chart: { type: 'column' },
      disablePointSelect: false,
      ignoreZeroValues: true,
      xAxis: {
        rotation: -45
      }
    }
  },
  barChart: {
    name: 'barChart',
    chartPropsOverride: {
      chart: { type: 'bar' },
      disablePointSelect: false,
      ignoreZeroValues: true
    }
  },
  comparisonBarChart: {
    name: 'comparisonBarChart',
    chartSeriesColors: [colors.comparison, colors.stacklineBlue],
    chartPropsOverride: {
      chart: { type: 'column', height: 650 },
      exporting: { enabled: enableExports },
      dataLabels: {
        enabled: true
      }
    },
    container: {
      style: { marginBottom: '40px' }
    }
  },
  weeklyTrendChart: {
    name: 'weeklyTrendChart',
    chartPropsOverride: {
      chart: { type: 'areaspline', height: 650 },
      exporting: { enabled: enableExports }
    },
    container: {
      style: { marginBottom: '40px' }
    }
  },
  marketShareComparisonChart: {
    name: 'comparisonBarChart',
    chartPropsOverride: {
      chart: { type: 'column', height: 650 },
      exporting: { enabled: enableExports },
      yAxis: [{ labels: { enabled: false } }],
      dataLabels: {
        enabled: true
      }
    },
    container: {
      style: { marginBottom: '40px' }
    }
  },
  marketShareGrid: {
    name: 'marketShareGrid',
    container: {
      style: { marginBottom: '40px', marginTop: '80px' }
    }
  },
  entityGrid: {
    name: 'entityGrid',
    container: {
      style: {
        marginBottom: '40px',
        ...getNewBeaconWidgetStyles([{ propertyName: 'marginTop', originalValue: '80px', newBeaconValue: '0px' }])
      }
    },
    gridOptions: {
      noDataAvailableMessage: 'No data available',
      defaultLayout: 'tile',
      enableSwitchingLayouts: true,
      enableExport: true,
      pageSize: 20
    }
  },
  weeklyMetricsGridContainer: {
    name: 'weeklyMetricsGridContainer'
  },
  promotionsGrid: {
    name: 'promotionsGrid'
  },
  reviewsGrid: {
    name: 'reviewsGrid',
    gridOptions: {
      noDataAvailableMessage: 'No reviews were found'
    }
  },
  contentMatchingGrid: {
    name: 'contentMatchingGrid'
  },
  keyMetrics: {
    name: 'keyMetrics',
    noInnerContainer: true
  }
};

export default VIEWS;
