import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { SlColumn, SlRow, StacklineColor, Text, hexToRgba, useStacklineTheme } from '@stackline/ui';
import queryString from 'qs';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Segment } from 'sl-api-connector';
import ElementCard from 'src/components/BeaconRedesignComponents/ElementCard/ElementCard';
import GenericCardLoading from 'src/components/BeaconRedesignComponents/GenericCardLoading/GenericCardLoading';
import SplineChart from 'src/components/BeaconRedesignComponents/SplineChart/SplineChart';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import {
  getColorForMetricChangeValue,
  getIconForMetricChange
} from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import { BEACON_PRO_ENTITY_CARD_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { useMetricByWeekId } from 'src/serverProxy/useMetricByWeekId';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { extractQueryConditions } from 'src/utils/segments';
import MiniButton from './MiniButton';

interface SegmentCardProps {
  percentChange: number;
  displayName: string;
  subtitle: string;
  metricTotal: string;
  primaryData: number[][];
  secondaryData: number[][];
  segmentId: string;
  savedSearch: Segment['segment'];
  segmentUserId: string;
  metricType: METRICTYPE;
  metricDisplayName?: string;
  color?: StacklineColor;
  isTopicSegment?: boolean;
}

const SegmentCardTitle = styled('p')(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 500,
  fontSize: '18px',
  margin: 0
}));

/**
 * Card for a segment
 */
const SegmentCard = ({
  segmentId,
  percentChange,
  displayName,
  subtitle,
  metricTotal,
  primaryData,
  secondaryData,
  savedSearch,
  segmentUserId,
  metricType,
  metricDisplayName = 'Retail Sales',
  color = 'info',
  isTopicSegment = false
}: SegmentCardProps) => {
  const [hovered, setHovered] = useState(false);
  const theme = useStacklineTheme();
  const formatMetric = useMetricFormatter();
  const { searchParams, additionalParams } = useAppSelector((state) => state.app.queryParams);
  const { userId } = useAppSelector((state) => state.user.session);

  return (
    <div
      style={{
        width: BEACON_PRO_ENTITY_CARD_WIDTH,
        height: '415px',
        border: `1px solid ${theme.colors.primaryGray}`,
        borderRadius: theme.spacing.sm,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden'
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box>
        <Box padding={theme.spacing.md} display="flex" flexDirection="column" gap={theme.spacing.md}>
          <SlRow horizontalPosition="space-between" verticalPosition="center">
            <ElementCard color={color} variant="small">
              {`${(displayName[0] || '').toUpperCase()}${(displayName[1] || '').toLowerCase()}`}
            </ElementCard>
            {hovered && (
              <SlRow spacing="sm">
                {userId === segmentUserId && (
                  <Link
                    to={`/search${searchParams}&doAggregation=true&entityType=${
                      savedSearch.entityType
                    }&${queryString.stringify(extractQueryConditions(savedSearch, true, true))}`}
                  >
                    <MiniButton
                      sx={{
                        backgroundColor: isTopicSegment
                          ? hexToRgba(theme.colors.accentMagenta, 0.1)
                          : hexToRgba(theme.colors.primaryLight, 0.1),
                        '&:hover': {
                          backgroundColor: isTopicSegment
                            ? hexToRgba(theme.colors.accentMagenta, 0.1)
                            : hexToRgba(theme.colors.primaryLight, 0.1)
                        }
                      }}
                    >
                      Edit
                    </MiniButton>
                  </Link>
                )}
                <Link
                  to={`/search${searchParams}&entityType=${savedSearch.entityType}&${queryString.stringify(
                    extractQueryConditions(savedSearch, true)
                  )}`}
                >
                  <MiniButton
                    sx={{
                      backgroundColor: isTopicSegment
                        ? hexToRgba(theme.colors.accentMagenta, 0.1)
                        : hexToRgba(theme.colors.primaryLight, 0.1),
                      '&:hover': {
                        backgroundColor: isTopicSegment
                          ? hexToRgba(theme.colors.accentMagenta, 0.1)
                          : hexToRgba(theme.colors.primaryLight, 0.1)
                      }
                    }}
                  >
                    Duplicate
                  </MiniButton>
                </Link>
              </SlRow>
            )}
          </SlRow>
          <Box display="flex" flexDirection="column" gap={theme.spacing.xs}>
            <Link
              to={`/${isTopicSegment ? 'searchtermlist' : 'segment'}/${segmentId}${searchParams}${additionalParams}`}
            >
              <SegmentCardTitle title={displayName}>{displayName}</SegmentCardTitle>
            </Link>
            <Text variant="body2">{subtitle}</Text>
          </Box>
        </Box>
        <Box sx={{ marginTop: '40px' }}>
          <SplineChart
            metricType={metricType}
            primaryData={primaryData}
            secondaryData={secondaryData}
            removeSpacing
            preview={{ width: +BEACON_PRO_ENTITY_CARD_WIDTH.replace('px', ''), height: 115 }}
            chartOptionsOverride={{
              plotOptions: {
                series: {
                  lineWidth: 2
                }
              }
            }}
          />
        </Box>
      </Box>
      <div>
        <SlColumn spacing="xxs" verticalInset="md" horizontalInset="md">
          <Text variant="subtitle2" transform="uppercase">
            {metricDisplayName}
          </Text>
          <SlRow verticalPosition="center" spacing="sm">
            <Text variant="body1">{metricTotal}</Text>
            <SlRow verticalPosition="center" spacing="xs">
              {getIconForMetricChange(percentChange)}
              <Text variant="subtitle2" color={getColorForMetricChangeValue(percentChange)}>
                {`${formatMetric(percentChange, METRICTYPE.PERCENT, { decimalPlaces: 2, showNegative: false })}`}
              </Text>
            </SlRow>
          </SlRow>
        </SlColumn>
      </div>
    </div>
  );
};

interface SmartSegmentCardProps {
  segment: Segment;
  indexName?: string;
  fieldName?: string;
  metricDisplayName?: string;
  color?: StacklineColor;
  isTopicSegment?: boolean;
}
/**
 * Fetches retail sales data for a segment and displays
 * the card. When loading, it shows a loading state
 */
export const SmartSegmentCard = ({
  segment,
  indexName = 'sales',
  fieldName = 'retailSales',
  metricDisplayName = 'Retail Sales',
  color = 'info',
  isTopicSegment
}: SmartSegmentCardProps) => {
  const requestBuilder = useCallback(
    (body: AdvancedSearchRequestBuilder) => {
      body.clearConditionRangeFilters().clearConditionTermFilters();

      if (segment.conditions.rangeFilters) {
        segment.conditions.rangeFilters.forEach((rangeFilter) => {
          body.addConditionRangeFilter(rangeFilter.fieldName, rangeFilter.minValue, rangeFilter.maxValue);
        });
      }

      if (segment.conditions.termFilters) {
        segment.conditions.termFilters.forEach((termFilter) => {
          body.addConditionTermFilter(termFilter.fieldName, termFilter.condition, termFilter.values);
        });
      }
      return body;
    },
    [segment]
  );

  const {
    formattedPrimaryTotal,
    percentChange,
    isLoading,
    primaryData,
    secondaryData,
    field: { metricType }
  } = useMetricByWeekId({
    indexName,
    fieldName,
    requestBuilder
  });

  return isLoading ? (
    <GenericCardLoading width={+BEACON_PRO_ENTITY_CARD_WIDTH.replace('px', '')} height={415} />
  ) : (
    <SegmentCard
      savedSearch={segment.segment}
      segmentId={segment.id}
      displayName={segment.displayName}
      percentChange={percentChange}
      subtitle={`Created by ${segment.segment.owner.firstName} ${segment.segment.owner.lastName}`}
      metricTotal={formattedPrimaryTotal}
      primaryData={primaryData}
      secondaryData={secondaryData}
      segmentUserId={segment.userId}
      metricDisplayName={metricDisplayName}
      metricType={metricType}
      color={color}
      isTopicSegment={isTopicSegment}
    />
  );
};

export default SegmentCard;
