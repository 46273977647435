import { useEffect, useRef } from 'react';
import axios, { AxiosResponse, CancelToken } from 'axios';
import { UseQueryResult, useQuery } from 'react-query';
import { AdvancedSearchQuery } from 'sl-api-connector/search';
import { getAppName } from 'src/utils/app';
import _omit from 'lodash/omit';

export interface FetchAdvancedSearchDataParameters {
  cancelTokenConfig: { cancelToken: CancelToken };
  requestEndpoint: string;
  requestBody: AdvancedSearchQuery[];
}

export const fetchAdvancedSearchData = async ({
  requestEndpoint,
  requestBody,
  cancelTokenConfig
}: FetchAdvancedSearchDataParameters) => {
  const response = await axios.post(requestEndpoint, requestBody, cancelTokenConfig);
  return response;
};

export interface UseGenericAdvancedSearchParameters {
  customEndpoint?: string;
  requestId?: string;
  requestBody: any;
  queryKeys: any[];
  staleTime?: number;
  shouldPerformFetch?: boolean;
  onError?: (error: unknown) => unknown;
  allowCancel?: boolean;
}

/**
 *
 * @param customEndPoint An optional custom endpoint to use instead of the default.
 * @param requestId Appends a string to the default endpoint for easy identification in the network tab.
 * @param requestBody A required request body for the Adv. Search request.
 * @param queryKeys Any argument here will be used as the query key. See React-Query documentation.
 * @param staleTime The time in milliseconds after data is considered stale. If set to Infinity, the data will never be considered stale.
 * @param onError An optional callback that fires if the query encounters an error.
 * @returns
 */
const useGenericAdvancedSearch = <T>({
  customEndpoint,
  requestId = '/',
  requestBody,
  queryKeys,
  staleTime = 60 * 10 * 1000,
  shouldPerformFetch = true,
  allowCancel = true,
  onError
}: UseGenericAdvancedSearchParameters): UseQueryResult<AxiosResponse<T>> => {
  const cancelSource = useRef(axios.CancelToken.source());
  const queryRef = useRef<UseQueryResult>(null);
  const appName = getAppName();

  const cancelTokenConfig = {
    cancelToken: cancelSource.current.token
  };

  let requestEndpoint = `api/${appName}/AdvancedSearch?_id=${requestId}`;

  if (customEndpoint) {
    requestEndpoint = customEndpoint;
  }
  const mapQueryKeys = (keys: any[]) => {
    return keys.map((key) => {
      if (typeof key === 'object') {
        return _omit(key, ['name', 'id']); // Don't use the name and ID because they can be unique but don't affect the response
      }
      return key;
    });
  };

  const query = useQuery(
    shouldPerformFetch ? mapQueryKeys(queryKeys) : [],
    () =>
      shouldPerformFetch
        ? fetchAdvancedSearchData({
            cancelTokenConfig: allowCancel ? cancelTokenConfig : undefined,
            requestEndpoint,
            requestBody
          })
        : undefined,
    {
      retry: 0, // Axios configured for 3 retries already
      staleTime,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error('returning null, failed to fetch data from: ', requestEndpoint);
        console.error(err);
        if (onError) {
          onError(err);
        }
      }
    }
  );

  useEffect(() => {
    queryRef.current = query;
  }, [query]);

  // Cancel API calls when component is unmounted
  useEffect(() => {
    const cancelNetworkCalls = cancelSource.current.cancel;

    return () => {
      /**
       * Query is still loading and will be canceled. Remove it from
       * the cache so that it doesn't store null data.
       */
      if (queryRef.current && queryRef.current.isLoading) {
        queryRef.current.remove();
      }
      cancelNetworkCalls();
    };
  }, []);

  // Type assertion to treat query.data as T
  const typedQuery = query as typeof query & {
    data: AxiosResponse<T> | undefined;
  };

  return typedQuery;
};

export default useGenericAdvancedSearch;
