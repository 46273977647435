import { parseQueryString } from 'src/utils/browser';

export const getLoginType = () => {
  let loginType = 'normal';
  if (window.location.href.indexOf('sso') > -1) {
    loginType = 'sso';
  }
  return loginType;
};

export const getSSOReferrer = () => {
  const { referrer } = parseQueryString();
  return referrer || 'gitbook';
};

export const handleSSORedirect = (jwtToken: string) => {
  const { referrer: referrerFromQueryString, location } = parseQueryString();
  const referrer = referrerFromQueryString || 'gitbook';
  if (referrer === 'gitbook' || referrer === 'stackline-support-portal') {
    if (location && location !== '') {
      window.location.href = `https://enterprise-help.stackline.com/${decodeURIComponent(
        location as any
      )}?jwt_token=${jwtToken}`;
    } else {
      window.location.href = `https://enterprise-help.stackline.com/?jwt_token=${jwtToken}`;
    }
  } else if (referrer === 'atlas-llm') {
    if (location && location !== '') {
      window.location.href = `https://atlas-llm.stackline.com/${decodeURIComponent(
        location as any
      )}?jwt_token=${jwtToken}`;
    } else {
      window.location.href = `https://atlas-llm.stackline.com/?jwt_token=${jwtToken}`;
    }
  } else if (referrer === 'atlas-llm-localhost') {
    if (location && location !== '') {
      window.location.href = `https://atlas-llm-localhost.stackline.com/${decodeURIComponent(
        location as any
      )}?jwt_token=${jwtToken}`;
    } else {
      window.location.href = `https://atlas-llm-localhost.stackline.com/?jwt_token=${jwtToken}`;
    }
  } else {
    throw new Error('Invalid sso referrer');
  }
};
