import { AxiosResponse } from 'axios';
import _get from 'lodash/get';
import { ProductGrowthTableRow } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/types';
import { getProductImageUrl } from 'src/utils/image';

/**
 * Parses the product growth data and prepares it for rendering in a table format.
 *
 * @param {AxiosResponse<any>} data - The raw response from the API request via React Query.
 * @returns {ProductGrowthTableRow[]} - An array of parsed product growth table rows.
 */
export const parseProductGrowthDataForTable = ({
  data,
  adjustmentCountBySku
}: {
  data: AxiosResponse<any>;
  adjustmentCountBySku: Record<string, number>;
}): ProductGrowthTableRow[] => {
  const documents = _get(data, ['data', 0, 'documents']);
  let productRows: ProductGrowthTableRow[];
  try {
    productRows = documents.map((document, index) => {
      const { product } = document;
      const productEntity = {
        ...product,
        name: product.title,
        keyFieldName: 'stacklineSku',
        type: 'product',
        displayName: 'Product',
        displayNamePlural: 'Products',
        id: product.stacklineSku,
        imageUrl: () => getProductImageUrl(product.stacklineSku)
      };

      return {
        name: product.title,
        id: product.stacklineSku,
        i: index,
        entity: productEntity,
        retailSales: document.retailSales,
        beaconTotalTraffic: document.beaconTotalTraffic,
        trafficIndex: document.trafficIndex,
        beaconConversion: document.beaconConversion,
        conversionIndex: document.conversionIndex,
        adjustmentCount: adjustmentCountBySku[product.stacklineSku.toLowerCase()] || 0
      };
    });
  } catch (e) {
    return [];
  }

  return productRows;
};
