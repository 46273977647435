import axios from 'axios';
import { StoreSegment } from 'src/components/Omni/OmniSegmentUtil';
import { ThunkDispatch } from 'redux-thunk';
import ReduxStore from 'src/types/store/reduxStore';
import { receiveOmniStoreList, startFetchOmniStoreList } from 'src/store/modules/omni/OmniStoreList/actions';

interface UpdateStoreListRequestBody {
  query?: StoreSegment;
  name: string;
  queryId?: string;
  isActive?: boolean;
  segmentType: string;
}

export const updateStoreListSegment = async (updateStoreListSegmentBody: UpdateStoreListRequestBody) => {
  return axios.post('/omni/segment/updateSegment', updateStoreListSegmentBody);
};

export const fetchOmniStoreList = async () => {
  return axios.get('omni/segment/getAllSegments?segmentType=store');
};

export const fetchOmniStoreListData = () => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
  try {
    dispatch(startFetchOmniStoreList({ data: [] }));
    const response = await fetchOmniStoreList();
    if (response && response.status === 200) {
      const { data } = response;
      dispatch(receiveOmniStoreList({ data }));
    }
  } catch (e) {
    console.warn(e);
  }
};
