import React from 'react';
import numeral from 'numeral';
import ArrowMui from '@mui/icons-material/ArrowOutward';

import { ArrowGrey, ArrowDownGreen, ArrowDownRed, ArrowUpGreen, ArrowUpRed } from 'src/components/SvgIcons';

const arrowOptions = {
  default: {
    transform: 'translate(-4px, -2px) rotate(45deg)'
  },
  upRed: {
    color: '#f35379',
    fill: 'currentcolor',
    transform: 'translate(-4px, -2px)'
  },
  upGreen: {
    color: '#39B3AE',
    fill: 'currentcolor',
    transform: 'translate(-4px, -2px)'
  },
  downRed: {
    color: '#f35379',
    fill: 'currentcolor',
    transform: 'translate(-2px, -1px) rotate(90deg)'
  },
  downGreen: {
    color: '#39B3AE',
    fill: 'currentcolor',
    transform: 'translate(-2px, 1px) rotate(180deg)'
  }
};

const ArrowChange = ({
  metric,
  value,
  width = 16,
  useImageUrl = false
}: {
  metric: any;
  value: number;
  width?: number;
  useImageUrl?: boolean;
}) => {
  const { isNegativeMetric } = metric;

  let metricsChangePercent = value;
  if (metricsChangePercent > 10) {
    metricsChangePercent = 10;
  } else if (metricsChangePercent < -10) {
    metricsChangePercent = -10;
  }
  const valueDisplay = numeral(Math.abs(metricsChangePercent)).format('0.0%');
  let pickArrowStyles = arrowOptions.default;
  let IconToUse = ArrowGrey;

  if (value > 0) {
    pickArrowStyles = isNegativeMetric ? arrowOptions.upRed : arrowOptions.upGreen;
    IconToUse = isNegativeMetric ? ArrowUpRed : ArrowUpGreen;
  } else if (value < 0) {
    pickArrowStyles = isNegativeMetric ? arrowOptions.downGreen : arrowOptions.downRed;
    IconToUse = isNegativeMetric ? ArrowDownGreen : ArrowDownRed;
  }

  return (
    <div style={{ display: 'flex', fontWeight: 'inherit', margin: '0 4px' }}>
      {useImageUrl ? (
        <span style={{ width, height: width, marginRight: 2 }}>
          <ArrowMui
            className="mui-test-test"
            sx={{
              width,
              height: width - 4,
              verticalAlign: 'middle',
              mr: 1
            }}
            style={{
              width: 'calc(100% + 5px)',
              height: 'calc(100% + 5px)',
              ...pickArrowStyles
            }}
          />
        </span>
      ) : (
        <IconToUse style={{ width, marginRight: 5 }} useImageUrl={useImageUrl} />
      )}
      {valueDisplay}
    </div>
  );
};

export default ArrowChange;
