import { FormControl } from '@mui/material';
import { Search } from '@mui/icons-material';
import React, { FC } from 'react';
import ChipsContainer from 'src/components/Search/AdvancedSearch/ChipsContainer';
import Suggestions from 'src/components/Suggestions';
import { useSelector } from 'react-redux';

interface DiscoverBrandSearchProps {
  handleBrandChange: (selection: any, index: any) => void;
  setBrandChips: any;
  brandChips: { [key: string]: any; key: string | number }[];
}
const DiscoverBrandSearch: FC<DiscoverBrandSearchProps> = (props: DiscoverBrandSearchProps) => {
  const { handleBrandChange, setBrandChips, brandChips } = props;

  const app = useSelector((state: ReduxStore) => state.app);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '3em', width: '90%' }}>
      <div
        style={{
          marginTop: '1em',
          border: '1px solid #f6f9fd',
          borderRadius: '5px',
          width: '100%',
          background: '#f6f9fd',
          height: '60px'
        }}
      >
        <FormControl style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <div className="brand-search">
            <Search className="filter-blue" style={{ height: '60px' }} />
          </div>
          <div style={{ width: '100%' }}>
            <Suggestions
              autocompleteAppNameOverride={null}
              apiUrl={`/api/${app.apiAppName}/AutoCompleteSuggestions?term=`}
              className="sl-form-input-discover"
              hintText="Search Brands"
              type="brand"
              style={{ height: '60px' }}
              textFieldStyle={{ height: '60px' }}
              onSelectionChange={handleBrandChange}
            />
          </div>
        </FormControl>
      </div>
      <div className="brandChipsContainer">
        <ChipsContainer
          onDelete={(key) => {
            const result = brandChips.filter((brandChip) => brandChip.key !== key);
            setBrandChips(result);

            // const clonedBrands = [...brandChips];
            // const chipToDelete = clonedBrands.map((chip) => chip.key).indexOf(key as string);
            // clonedBrands.splice(chipToDelete, 1);
            // setBrandChips(clonedBrands);
          }}
          style={{ marginTop: '1em' }}
          chipData={brandChips}
          dataKey="n"
        />
      </div>
    </div>
  );
};

DiscoverBrandSearch.propTypes = {};

export default DiscoverBrandSearch;
