import React, { FC } from 'react';
import DiscoverRadioGroup from 'src/components/Discover/DiscoverRadioGroup';
import DiscoverFilter from 'src/components/Discover/DiscoverFilter';
import { Divider } from '@mui/material';
import DiscoverCategorySearch from './DiscoverCategorySearch/DiscoverCategorySearch';
import DiscoverSubCategorySearch from './DiscoverSubCategorySearch/DiscoverSubCategorySearch';
import DiscoverBrandSearch from 'src/components/Discover/DiscoverFilterDialog/DiscoverFilterDialogBody/DiscoverBrandSearch';
import ReduxStore from 'src/types/store/reduxStore';
import { IsActiveElement } from 'src/components/Discover/DiscoverUtils/CommonUtils';

interface DiscoverFilterDialogBodyProps {
  chipFields: string[];
  isBrandManufacturerActive: IsActiveElement[];
  handleClick: (field?: string, par?: number, group?: string) => Promise<void>;
  isDepartCatSubcatActive: IsActiveElement[];
  filterTitles: string[];
  filterObj: any;
  filterContent: any;
  setIsDisabled: any;
  helperText: any;
  setHelperText: any;
  brandChips: any;
  setBrandChips: any;
  handleBrandChange: (selection: any, index: any) => void;
  isSuperUser: boolean;
  categories: ReduxStore['categories'];
  superUserCategories: ReduxStore['allSuperUserCategories'];
  superUserSubCategories: ReduxStore['allSuperUserSubCategories'];
  handleCategoryChange: (event: any, values: any) => void;
  handleSubCategoryChange: (event: any, values: any) => void;
  selectedCategory: number[];
  onDeleteCategory: (categoryName: string) => void;
  selectedSubCategory: number[];
  subCategoryBasedOnCategorySelection: any;
  onDeleteSubcategories: (subCategoryName: string) => void;
}

const DiscoverFilterDialogBody: FC<DiscoverFilterDialogBodyProps> = (props: DiscoverFilterDialogBodyProps) => {
  const {
    chipFields,
    isBrandManufacturerActive,
    handleClick,
    isDepartCatSubcatActive,
    filterTitles,
    filterObj,
    filterContent,
    setIsDisabled,
    helperText,
    setHelperText,
    brandChips,
    handleBrandChange,
    setBrandChips,
    categories,
    superUserCategories,
    superUserSubCategories,
    isSuperUser,
    handleCategoryChange,
    handleSubCategoryChange,
    selectedCategory,
    onDeleteCategory,
    subCategoryBasedOnCategorySelection,
    selectedSubCategory,
    onDeleteSubcategories
  } = props;

  return (
    <div>
      <div className="chipGroup">
        <div className="brandManufacturerChipGroup">
          <DiscoverRadioGroup
            title="Organizational Level"
            chipFields={chipFields}
            options={isBrandManufacturerActive}
            radioGroup="brandManufacturerChipGroup"
            handleClick={handleClick}
          />
        </div>
        <Divider />
        <div className="deptCatSubcatChipGroup">
          <DiscoverRadioGroup
            title="Taxonomy Level"
            chipFields={chipFields}
            options={isDepartCatSubcatActive}
            radioGroup="deptCatSubcatChipGroup"
            handleClick={handleClick}
          />
        </div>
        <Divider />
      </div>
      <div className="discoverFilterContainerGrid" style={{ width: '100%' }}>
        {filterTitles.length > 0 &&
          filterTitles.map((fieldName, index) => {
            return (
              <div key={index}>
                <DiscoverFilter
                  setFilter={filterObj}
                  sendFilterContent={filterContent}
                  filterTitles={fieldName}
                  setIsDisabled={setIsDisabled}
                  helperText={helperText}
                  setHelperText={setHelperText}
                />
              </div>
            );
          })}
      </div>
      <Divider />
      <div className="moreFiltersContainer">
        <p>More Filters</p>
      </div>
      <div className="multiSelectPanelContainer" style={{ width: '67%', margin: 0 }}>
        <DiscoverBrandSearch
          brandChips={brandChips}
          setBrandChips={setBrandChips}
          handleBrandChange={handleBrandChange}
        />
        <DiscoverCategorySearch
          isSuperUser={isSuperUser}
          categories={categories}
          superUserCategories={superUserCategories}
          handleCategoryChange={handleCategoryChange}
          selectedCategory={selectedCategory}
          onDeleteCategory={onDeleteCategory}
        />
        <DiscoverSubCategorySearch
          isSuperUser={isSuperUser}
          subCategoryBasedOnCategorySelection={subCategoryBasedOnCategorySelection}
          superUserSubCategories={superUserSubCategories}
          handleSubCategoryChange={handleSubCategoryChange}
          selectedSubCategory={selectedSubCategory}
          onDeleteSubcategories={onDeleteSubcategories}
        />
      </div>
    </div>
  );
};

export default DiscoverFilterDialogBody;
