import React, { CSSProperties } from 'react';

export const DynamicDownChevron = ({
  style,
  color,
  strokeWidth = '1.5px'
}: {
  style: CSSProperties;
  color: string;
  strokeWidth?: string;
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ ...style }}>
      <g data-name="icon - chevron down - 24px">
        <path style={{ fill: 'none' }} d="M0 0h24v24H0z" />
        <path
          data-name="↳color"
          d="m6 9 4 4 4-4"
          transform="translate(2 2)"
          style={{ stroke: color, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth, fill: 'none' }}
        />
      </g>
    </svg>
  );
};
