import AdOptimizationHistory from 'src/components/AdManager/AdOptimizationHistory';
import ReduxStore from 'src/types/store/reduxStore';
import { PageLayout } from 'src/types/application/widgetTypes';
import colors from 'src/utils/colors';
import AdManagerEntityHeader from 'src/components/AdManager/AdManagerEntityHeader';

export const getLayout = (_: { app: ReduxStore['app']; entity: { type: string } }): Partial<PageLayout> => {
  const HeaderWidget = {
    CustomComponent: AdManagerEntityHeader,
    name: 'adManagerHeader',
    view: {
      name: 'adManagerHeader',
      title: 'Ad Score',
      container: {
        style: { borderBottom: `1px solid ${colors.lightestGrey}`, marginBottom: 50 }
      }
    },
    data: {
      adScoreName: 'adScore'
    }
  };
  return {
    widgets: [
      HeaderWidget,
      {
        name: 'adManagerInfoPage',
        CustomComponent: AdOptimizationHistory,
        view: {
          noInnerContainer: true,
          name: 'adManagerInfoPage'
        },
        data: {}
      }
    ]
  };
};
