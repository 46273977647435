import { useDispatch } from 'react-redux';
import { FetchAdditionalDataFn } from 'src/components/AdManager/Search/hooks/types';
import useFetchEntityMetricsArgs from './useFetchEntityMetricsArgs';
import { useCallback } from 'react';
import {
  ProductsDataFetcher,
  OverrideQueryConditionsFn,
  OverrideProductStatusFn
} from 'src/components/AdManager/Search/GridDataFetchers/Products/ProductsDataFetcher';
import { SearchGridConstants } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConstants';
import _get from 'lodash/get';
import { INELIGIBLE_FIELDS } from 'src/components/AdManager/AdIneligibleInfo';
import { getAppName } from 'src/utils/app';
import { TermFilter } from 'sl-api-connector';

/**
 * Overrides the query conditions for ineligible products
 */
export const overrideQueryConditionsForIneligibleProducts: OverrideQueryConditionsFn = ({
  queryConditions,
  skusWithMetrics,
  customTermFilters
}) => {
  // get the data for the products which have no SKU's
  if (getAppName() === 'advertising') {
    // find the term filter which has the field name sku
    const findFilterConditionWithSKU: Pick<TermFilter, 'values'> = customTermFilters.find(
      (x: any) => x.fieldName === 'stacklineSku'
    ) || {
      values: []
    };
    const allSkus = _get(findFilterConditionWithSKU, ['values'], []);
    // first find the SKU's which have no metrics
    const missingSkus = allSkus.filter((x: string) => !skusWithMetrics.some((y) => y === x));
    const updatedTermFilters = queryConditions.termFilters.map((termFilter) => {
      if (termFilter.fieldName === 'stacklineSku') {
        return {
          ...termFilter,
          values: [...termFilter.values, ...missingSkus]
        };
      }
      return termFilter;
    });

    return {
      ...queryConditions,
      termFilters: updatedTermFilters
    };
  }
  return queryConditions;
};

/**
 * Overrides the extnded attributes statuses for ineligible products
 */
export const overrideStatusesForIneligibleProducts: OverrideProductStatusFn = ({ adCampaignProductDocument }) => {
  if (INELIGIBLE_FIELDS.includes(adCampaignProductDocument.statusReason)) {
    const { status, statusDerived, statusReason } = adCampaignProductDocument;
    // Will override product with ineligible reasons
    return {
      status,
      statusDerived,
      statusReason
    };
  }
  return {};
};

/**
 * Returns a callback function for fetching product data.
 */
const useFetchProductsTableSalesData = (importantOverrides = []): FetchAdditionalDataFn => {
  const dispatch = useDispatch();
  const { entity, retailer, app } = useFetchEntityMetricsArgs();

  return useCallback(
    async ({
      widget,
      indexName,
      sortDirection,
      currentSortFieldName,
      pageNumber,
      searchSideBarConditions,
      mainTimePeriodRangeFilters,
      aggregationFilters,
      aggregationFieldsForResultCount,
      onFirstPageAndCount,
      location,
      dataSet,
      fetchMetricsParams,
      additionalTermFilters
    }) => {
      const isOnIneligibleTab = new URLSearchParams(location.search).get('subtab') === 'ineligible';

      const productsDataFetcher = new ProductsDataFetcher();
      const finalResult = await productsDataFetcher.fetchProductsData(
        entity,
        widget.data.customTermFilters || [],
        widget,
        additionalTermFilters,
        onFirstPageAndCount,
        dispatch,
        retailer,
        app,
        indexName,
        aggregationFieldsForResultCount,
        mainTimePeriodRangeFilters,
        aggregationFilters,
        sortDirection,
        currentSortFieldName,
        searchSideBarConditions,
        pageNumber,
        undefined, // TODO add cancel source
        {
          ...fetchMetricsParams,
          // Ineligible products cannot be fetched in chunks because the list is aggregated from
          // the result of multiple network calls, so we fetch all products in order to fetch all
          // ineligble products at once.
          // TODO if there are more than 5K ineligible we should show a warning https://stackline.atlassian.net/browse/ADSPRT-1678
          pageSizeOverride: location.search.includes('ineligible')
            ? SearchGridConstants.PAGE_SIZE_5K
            : fetchMetricsParams.pageSizeOverride
        },
        dataSet,
        { location },
        isOnIneligibleTab
          ? {
              overrideQueryConditions: overrideQueryConditionsForIneligibleProducts,
              overrideStatuses: overrideStatusesForIneligibleProducts
            }
          : undefined,
        importantOverrides
      );
      return finalResult;
    },
    [app, dispatch, entity, retailer]
  );
};

export default useFetchProductsTableSalesData;
