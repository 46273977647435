import { useStacklineTheme, Text, StacklineSizing } from '@stackline/ui';
import React from 'react';
import { StyledBorderTextBox } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Content/Accuracy/ContentAccuracyProductCompare';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

interface ContentAccuracyNodataBoxProps {
  isCurrentContent?: boolean;
  contentField: 'videos' | 'images' | 'bullets' | 'title';
  marginTop?: StacklineSizing;
}

/**
 * This component is used when one of the required content field is missing
 *
 */
const ContentAccuracyNodataBox = ({
  isCurrentContent,
  contentField,
  marginTop = 'lg'
}: ContentAccuracyNodataBoxProps) => {
  const theme = useStacklineTheme();

  return (
    <Flex marginTop={theme.spacing[marginTop]}>
      <StyledBorderTextBox stacklineTheme={theme} borderColor={theme.colors.primaryGray} width={424}>
        <Text variant="body2">{`This product has no ${isCurrentContent ? '' : 'approved'} ${contentField}.`}</Text>
      </StyledBorderTextBox>
    </Flex>
  );
};

export default ContentAccuracyNodataBox;
