import React from 'react';
import Button from '@mui/material/Button';
import isEmpty from 'lodash/isEmpty';

const styles: { [key: string]: React.CSSProperties } = {
  placeholder: {
    display: 'inline-block',
    height: '36px',
    float: 'left',
    marginTop: '12px'
  }
};

interface OmniClearButtonProps {
  data: any[];
  handleClearAll: () => void;
  isToggleGroup?: boolean;
}

const OmniClearButton: React.FC<OmniClearButtonProps> = ({
  data,
  handleClearAll,
  isToggleGroup
}: OmniClearButtonProps) => {
  if (isEmpty(data)) {
    return <span style={styles.placeholder} />;
  }
  return (
    <Button
      className={isToggleGroup ? 'omni-nav-clear-toggle-all' : 'omni-nav-clear-all'}
      onClick={handleClearAll}
      color="primary"
    >
      Clear
    </Button>
  );
};

export default OmniClearButton;
