import React, { useState } from 'react';
import BeaconMetricColumnChart, { BeaconMetricColumnChartProps } from './BeaconMetricColumnChart';
import { SlDropdownMenuOption } from '@stackline/ui';
import _get from 'lodash/get';
import { BeaconChartWithLegendProps } from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';

export interface BeaconDropdownMetricColumnChartProps
  extends Omit<BeaconMetricColumnChartProps, 'useAdvancedSearchDataArgs' | 'indexName'> {
  dropdownOptions: SlDropdownMenuOption[];
  defaultOptionId?: string;
  useAdvancedSearchDataArgs?: Omit<
    BeaconMetricColumnChartProps['useAdvancedSearchDataArgs'],
    'groupByField' | 'fields'
  >;
  titlePrefix?: string;

  /**
   * Either take a straight index name or a function to compute it
   */
  indexName: string | ((args: { selectedDropdownId: string }) => string);
}

/**
 * Shows a column chart with a dropdown that will change the
 * group by field
 */
export default function BeaconDropdownMetricColumnChart(props: BeaconDropdownMetricColumnChartProps) {
  const { defaultOptionId, dropdownOptions, useAdvancedSearchDataArgs, titlePrefix, fieldName, indexName } = props;
  const [selectedId, setSelectedId] = useState(defaultOptionId || _get(dropdownOptions, [0, 'id']));

  return (
    <BeaconMetricColumnChart
      {...props}
      indexName={typeof indexName === 'function' ? indexName({ selectedDropdownId: selectedId }) : indexName}
      useAdvancedSearchDataArgs={{
        groupByField: selectedId,
        fields: [{ name: fieldName }],
        ...useAdvancedSearchDataArgs
      }}
      beaconChartWithLegendPropsBuilder={(defaultChartProps) => {
        const chartProps: Omit<BeaconChartWithLegendProps, 'children'> = {
          ...defaultChartProps,
          singleDropdownProps: {
            ...defaultChartProps.singleDropdownProps,
            prefix: titlePrefix,
            defaultLabel: '',
            options: dropdownOptions,
            selectedId,
            onChange: (option) => {
              setSelectedId(option.id);
            }
          }
        };

        return props.beaconChartWithLegendPropsBuilder
          ? props.beaconChartWithLegendPropsBuilder(chartProps)
          : chartProps;
      }}
    />
  );
}
