import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { ISearchRequestOverride } from 'src/components/AdManager/Search/Models/ISearchRequestOverride';
import _isNil from 'lodash/isNil';
import { shouldDisableToggle } from 'src/components/AdManager/Search/GridDataFetchers/SetDisableTogglesForItems';
import { useAppSelector } from 'src/utils/Hooks';
import { receiveEntitySalesMetrics } from 'src/store/modules/entitySearchService/operations';
import { SearchGridConstants } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConstants';
import _get from 'lodash/get';
import { Widget } from 'src/types/application/widgetTypes';
import { fetchEntityMetricsAndParseResponse } from 'src/components/AdManager/Search/GridDataFetchers/FetchDataForGroupByField';
import useFetchEntityMetricsArgs from './useFetchEntityMetricsArgs';
import { getAppName } from 'src/utils/app';
import { IFetchEntityMetricsAndParseResponseParams } from 'src/components/AdManager/Search/Models/IFetchEntityMetricsAndParseResponseParams';
import axios from 'axios';
import { emitter } from 'src/components/AdManager/utils';

export type AmsMetadataType = 'adCampaign' | 'adCampaignProduct' | 'adTarget';

export type FetchDataFn = (options?: {
  customizeFetchMetricsParams?: (
    fetchMetricsParamsToCustomize: IFetchEntityMetricsAndParseResponseParams
  ) => IFetchEntityMetricsAndParseResponseParams;
}) => Promise<{ gridResult: any }>;

export interface MetadataRowOptions {
  /**
   * Entity type, like 'product' or 'adCampaign'
   */
  type: string;
  /**
   * Property on metadata response object that contains
   * the name of the item to display. For example, 'campaignName'
   */
  name: string;
  /**
   * Callback function to get the unique ID of the entity on a
   * metadata row.
   */
  getEntityId: (row: any) => string;
  /**
   * Optional callback to define the unique ID for the grid row
   * if it should be different than the entity ID. This ID is typically
   * combined with `type` to create a link in SRG.
   *
   * One way this is used is for targets, where the entity ID should be the
   * target combined with the target type like "dog foodphrase", but the row ID
   * should just be "dog food"
   */
  getRowId?: (row: any) => string;
}

const useSrgFetchEntityMetrics = ({
  indexName,
  searchRequestOverrides,
  fetchData,
  pageNumber,
  computeTotalResultCount,
  setFetchedFirstPage,
  widget,
  onFetchedData,
  apiRequest,
  metadataRowOptions,
  fetchTotalRowCountAmsMetadataType,
  importantOverrides
}: {
  indexName: string;
  searchRequestOverrides: ISearchRequestOverride[];
  fetchData: FetchDataFn;
  pageNumber: number;
  setFetchedFirstPage: (fetchedFirstPage: boolean) => void;
  /**
   * Optional callback to compute the total result count
   * if not relying on totalResultCount from ESS
   */
  computeTotalResultCount?: ({ result }) => number;
  widget: Widget;
  /** Optional callback for when the data has been fetched */
  onFetchedData?: ({ gridResult }) => void | Promise<void>;
  /** For fetching rows without  metrics, define the properties
   * on the rows that are returned so that they can be properly
   * merged with the metric rows
   */
  metadataRowOptions?: MetadataRowOptions;
  apiRequest: any[];
  fetchTotalRowCountAmsMetadataType?: AmsMetadataType;
  importantOverrides?: any;
}) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const retailerId = useAppSelector((state) => state.retailer.id);
  const retailerPlatformType = useAppSelector((state) => state.retailer.platformType);
  const savedData = useAppSelector((state) =>
    _get(state, ['entitySearchService', widget.data.statePropertyName, 'data'], [])
  );
  const pageOffset = useMemo(() => (pageNumber - 1) * SearchGridConstants.AD_SEARCH_PAGINATION_SIZE, [pageNumber]);
  const dataSlice = useMemo(
    () => savedData.slice(pageOffset, pageOffset + SearchGridConstants.AD_SEARCH_PAGINATION_SIZE),
    [savedData, pageOffset]
  );
  const { app, entity, retailer } = useFetchEntityMetricsArgs();

  const getGroupByField = useCallback(() => {
    return new URLSearchParams(window.location.search).get('groupByField');
  }, []);

  /**
   * Separate endpoint for fetching the total number of rows when there are metrics filters
   */
  const fetchTotalRowCount = useCallback(
    async (amsMetadataType: AmsMetadataType) => {
      const payload = [
        {
          ...apiRequest[0],
          pageSize: 100000,
          pageNumber: 1
        }
      ];
      if (apiRequest[1]) {
        payload.push({
          ...apiRequest[1],
          pageSize: 100000,
          pageNumber: 1
        });
      }
      const { data } = await axios.post('/apiAdManager/adBulkUpdate/getTotalSelectedCount', {
        esQueryParams: payload,
        rdsQueryFilters: [],
        platformType: retailerPlatformType,
        retailerId,
        isExpandedView: false,
        amsMetadataType
      });

      // FIXME: Backend need to fix this to be correct number. Even cardinality not always right
      return data.totalSelectedCount ? data.totalSelectedCount : _get(data, 'totalSelectedCountUsingCardinality', 0);
    },
    [apiRequest, retailerId, retailerPlatformType]
  );

  const fetchMetricRowsWithTotal = useCallback(
    async (_importantOverrides) => {
      const { gridResult } = await fetchData(_importantOverrides);

      let totalResultCount: number;
      if (fetchTotalRowCountAmsMetadataType) {
        // FIXME: Sync with Jagan Why counts return 500
        totalResultCount = await fetchTotalRowCount(fetchTotalRowCountAmsMetadataType);
      } else if (computeTotalResultCount) {
        totalResultCount = computeTotalResultCount({ result: gridResult });
      } else {
        // eslint-disable-next-line prefer-destructuring
        totalResultCount = gridResult.totalResultCount;
      }

      return {
        ...gridResult,
        totalResultCount
      };
    },
    [computeTotalResultCount, fetchData, fetchTotalRowCount, fetchTotalRowCountAmsMetadataType]
  );

  /**
   * Return true if there are any metric filters in the API request.
   */
  const hasMetricFilters = useMemo(() => {
    return _get(apiRequest, [0, 'aggregations'], []).some(
      (aggregation) => _get(aggregation, ['conditions', 'computeFilters'], []).length > 0
    );
  }, [apiRequest]);

  useEffect(() => {
    (async () => {
      if (dataSlice.length < 1) {
        setLoading(true);
        let gridResultWithTotal;
        const originalGroupByField = getGroupByField();

        // TODO get rid of hasMetric filters when backend can return correct count
        if (!metadataRowOptions || hasMetricFilters) {
          gridResultWithTotal = await fetchMetricRowsWithTotal(importantOverrides);
          emitter.emit('srg/tooltipHeader', '');
        } else {
          const fetchEntityMetricsArgs = {
            statePropertyName: widget.data.statePropertyName,
            indexName,
            app,
            mainEntity: entity,
            pageSizeOverride: SearchGridConstants.AD_SEARCH_PAGINATION_SIZE,
            retailer,
            widget
          };
          const searchType = `${getAppName()}-${indexName.replace('DailyMetrics', '')}`;
          const noMetricsOverrides = {
            ...searchRequestOverrides[0],
            doAggregation: false,
            returnDocuments: true,
            doDefaultSearch: true,
            searchType,
            aggregations: null,
            conditions: {
              ...searchRequestOverrides[0].conditions,
              rangeFilters: []
            },
            sortFilter: undefined
          };
          // Get the total number of rows including those without metrics
          const docsCount: number = await fetchEntityMetricsAndParseResponse(
            {
              ...fetchEntityMetricsArgs,
              searchRequestOverrides: [
                {
                  ...noMetricsOverrides,
                  onlyCount: true
                }
              ]
            },
            dispatch,
            undefined,
            (...args) => _get(args, [0, 'apiResponse', 'data', 0, 'docsCount'], 0)
          );

          // Total number of rows is small enough that we can query for everything
          if (docsCount < SearchGridConstants.NO_METRICS_MAX) {
            // Get metadata for all rows, including those without metrics
            const metadataRows: any[] = await fetchEntityMetricsAndParseResponse(
              {
                ...fetchEntityMetricsArgs,
                mainEntity: {
                  ...fetchEntityMetricsArgs.mainEntity
                },
                searchRequestOverrides: [
                  {
                    ...noMetricsOverrides,
                    processDocuments: true,
                    onlyCount: false,
                    pageSize: SearchGridConstants.NO_METRICS_MAX
                  }
                ]
              },
              dispatch,
              undefined,
              (action) => {
                return _get(action, ['apiResponse', 'data', 0, 'documents'], []);
              }
            );

            const { gridResult } = await fetchData({
              customizeFetchMetricsParams: (fetchMetricsParams) =>
                ({
                  ...fetchMetricsParams,
                  pageSizeOverride: SearchGridConstants.NO_METRICS_MAX,
                  searchRequestOverrides: [
                    {
                      ...fetchMetricsParams.searchRequestOverrides[0],
                      pageSize: SearchGridConstants.NO_METRICS_MAX
                    }
                  ]
                } as IFetchEntityMetricsAndParseResponseParams)
            });

            const rowsWithMetricsIds = new Set(gridResult.data.map(({ id }) => id));

            const enhanceRow = (row) => {
              const entityId = metadataRowOptions.getEntityId(row);
              const rowId = metadataRowOptions.getRowId ? metadataRowOptions.getRowId(row) : entityId;

              return {
                ...row,
                id: entityId,
                entity: {
                  ...row,
                  name: row[metadataRowOptions.name],
                  id: rowId,
                  type: metadataRowOptions.type
                }
              };
            };

            const filteredMetadataRows = metadataRows.map(enhanceRow);
            const filtered = filteredMetadataRows.filter(
              (row) => !rowsWithMetricsIds.has(metadataRowOptions.getEntityId(row))
            );
            // Sometimes products can have a duplicates with different adGroupsId, we can filter them out
            const uniqueFiltered = Object.values(
              filtered.reduce((acc, current) => {
                if (!acc[current.id]) {
                  acc[current.id] = current;
                }
                return acc;
              }, {})
            );

            /** All rows, those with metrics combined with those without metrics */
            const combinedRows = [...gridResult.data, ...uniqueFiltered];

            gridResultWithTotal = {
              ...gridResult,
              data: combinedRows,
              totalResultCount: combinedRows.length
            };
          } else {
            const TOOLTIP_MESSAGE = `Due to the volume of data, only data with metrics is being displayed`;
            emitter.emit('srg/tooltipHeader', TOOLTIP_MESSAGE);
            gridResultWithTotal = await fetchMetricRowsWithTotal(importantOverrides);
          }
        }

        // Tells toggle column if it should show the toggle or not
        setResult({
          ...gridResultWithTotal,
          data: gridResultWithTotal.data.map((item) => ({
            ...item,
            disableToggle: shouldDisableToggle(item, retailerId)
          }))
        });

        // If the user switches tabs before the data is done loading,
        // we don't want to update Redux because then the wrong data will be displayed
        if (originalGroupByField === getGroupByField()) {
          dispatch(receiveEntitySalesMetrics(widget.data.statePropertyName, gridResultWithTotal));
        }

        if (pageNumber === 1) {
          setFetchedFirstPage(true);
        }

        if (onFetchedData) {
          onFetchedData({ gridResult: gridResultWithTotal });
        }
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    indexName,
    searchRequestOverrides,
    retailerId,
    fetchData,
    dataSlice.length,
    getGroupByField,
    computeTotalResultCount,
    pageNumber,
    setFetchedFirstPage,
    widget.data.statePropertyName,
    onFetchedData
  ]);

  return {
    result,
    rowData: dataSlice,
    totalResultCount: result && !_isNil(result.totalResultCount) ? result.totalResultCount : null,
    loading
  };
};

export default useSrgFetchEntityMetrics;
