import queryString from 'qs';
import _isEmpty from 'lodash/isEmpty';
import { AppName } from 'sl-api-connector/types';
import { shouldShowCriteo, shouldShowNewBeacon } from 'src/utils/app';

export function calculateNewQueryParams(
  app,
  retailer,
  mainTimePeriod,
  comparisonTimePeriod,
  additionalParams = {},
  forceRemoveParamsParams = null,
  parentPlatform = { id: null }
) {
  // add a whitelist of mappings and everything else would need to be deleted when we move to other tabs
  const whitelistParams = ['rid', 'pid', 'sw', 'ew', 'sdid', 'edid', 'wr', 'tab', 'subtab', 'entityType'];
  const isCriteoAllowed = shouldShowCriteo();
  if (isCriteoAllowed) {
    whitelistParams.unshift('pp');
  }
  const additionalParamMappings = {};
  const nonPersistentParamMappings = {};
  const filterParamMappings = {};
  const compareParamMappings = {};
  const discoverFilterParamMappings = {};
  const defaultAdditionalParams = `&tab=${app.defaultQueryParams.tab}&subtab=${app.defaultQueryParams.subtab}`;
  const searchParamsMapping = {
    rid: retailer.id,
    pid: comparisonTimePeriod.id,
    sw: mainTimePeriod.startWeek,
    ew: mainTimePeriod.endWeek,
    sdid: mainTimePeriod.startDayId,
    edid: mainTimePeriod.endDayId,
    wr: mainTimePeriod.id
  };

  if (isCriteoAllowed) {
    // FIXME: Bugfix to remove duplicates in 'pp'
    // const { pp: parentPlatformParams } = additionalParams;
    // const firstParentPlatform =
    //   _isArray(parentPlatformParams) && parentPlatformParams && parentPlatformParams.length > 1
    //     ? parentPlatformParams[0]
    //     : parentPlatformParams;
    // searchParamsMapping.pp = (parentPlatform && parentPlatform.id) || retailer.parentPlatform || firstParentPlatform;
    searchParamsMapping.pp = (parentPlatform && parentPlatform.id) || retailer.parentPlatform || additionalParams.pp;
  }

  // TODO: hack that can be removed once chart is fully widgetized
  if (additionalParams.tab && additionalParams.tab === 'compliance') {
    whitelistParams.push('ctab', 'csubtab', 'ctype');
  }

  Object.keys(additionalParams).forEach((key) => {
    if (!searchParamsMapping[key] && whitelistParams.indexOf(key) !== -1) {
      additionalParamMappings[key] = additionalParams[key];
    } else if (whitelistParams.indexOf(key) === -1) {
      nonPersistentParamMappings[key] = additionalParams[key];
      if (key === 'filter') {
        filterParamMappings[key] = additionalParams[key];
      }
      if (key === 'ctype' || key === 'cid' || key === 'ctab' || key === 'csubtab') {
        compareParamMappings[key] = additionalParams[key];
      }
      if (key === 'discoverFilter') {
        discoverFilterParamMappings[key] = additionalParams[key];
      }
    }
  });

  //  Builds the additional params
  const buildTabSubtab = () => {
    if (additionalParamMappings.tab && additionalParamMappings.tab !== 'summary') {
      return `&${queryString.stringify(additionalParamMappings)}`;
    } else if (additionalParams.entityType === 'searchtermlist') {
      return `&tab=${app.name === AppName.Advertising ? 'adManager' : 'traffic'}&subtab=totalClicks`;
    } else if (
      additionalParamMappings.tab &&
      additionalParamMappings.tab === 'summary' &&
      additionalParamMappings.subtab &&
      additionalParamMappings.subtab === 'beacon' &&
      retailer.id === '0'
    ) {
      return '&tab=summary&subtab=beacon&entityType=retailer';
    } else if (
      additionalParamMappings.tab &&
      additionalParamMappings.tab === 'summary' &&
      additionalParamMappings.subtab &&
      additionalParamMappings.subtab === 'atlas' &&
      retailer.id === '0'
    ) {
      return '&tab=summary&subtab=atlas&entityType=retailer';
    } else if (shouldShowNewBeacon() && !additionalParamMappings) {
      return `&tab=${app.defaultQueryParams.tab}&subtab=${app.defaultQueryParams.subtab}`;
    } else if (!_isEmpty(nonPersistentParamMappings)) {
      return '';
    }

    //  provides the default tab subtab for the summary pages
    return defaultAdditionalParams;
  };

  if (forceRemoveParamsParams) {
    Object.keys(forceRemoveParamsParams).forEach((key) => {
      if (searchParamsMapping[key]) {
        delete searchParamsMapping[key];
      }
    });
  }

  return {
    ...searchParamsMapping,
    ...additionalParamMappings,
    ...nonPersistentParamMappings,
    dropDownSelectionParams: !_isEmpty(additionalParamMappings)
      ? `&${queryString.stringify(additionalParamMappings)}`
      : '',
    searchParams: `?${queryString.stringify(searchParamsMapping)}`,
    additionalParams: buildTabSubtab(),
    nonPersistentParams: !_isEmpty(nonPersistentParamMappings)
      ? `&${queryString.stringify(nonPersistentParamMappings)}`
      : '',
    filterParams: !_isEmpty(filterParamMappings) ? `&${queryString.stringify(filterParamMappings)}` : '',
    discoverFilterParamMappings: !_isEmpty(discoverFilterParamMappings)
      ? `&${queryString.stringify(filterParamMappings)}`
      : '',
    compareParams: !_isEmpty(compareParamMappings) ? `&${queryString.stringify(compareParamMappings)}` : ''
  };
}

export function getFirstRetailerSearchParams(app, config, retailer, mainTimePeriod, comparisonTimePeriod) {
  const availableRetailerIds = Object.keys(config.allWeekIdsByRetailerId).filter((id) => {
    return id !== '0';
  });

  const [retailerId] = availableRetailerIds;
  // Redirect to new retailer if param rid is not subscribed to by client
  const newRetailer = retailer.availableRetailers.find((r) => r.id === retailerId);
  if (newRetailer) {
    const { searchParams } = calculateNewQueryParams(app, newRetailer, mainTimePeriod, comparisonTimePeriod);

    return searchParams;
  }
  return '';
}

export function getSearchParamsForRetailer(app, retailer, retailerId, mainTimePeriod, comparisonTimePeriod) {
  // Redirect to new retailer if param rid is not subscribed to by client
  const newRetailer = retailer.availableRetailers.find((r) => r.id === retailerId);
  if (newRetailer) {
    const { searchParams } = calculateNewQueryParams(app, newRetailer, mainTimePeriod, comparisonTimePeriod);

    return searchParams;
  }
  return '';
}

export function getFirstNonZeroRetailerId(allWeekIdsByRetailerId) {
  return Object.keys(allWeekIdsByRetailerId).find((id) => id !== '0');
}

export function getValidRetailerId(retailer, allWeekIdsByRetailerId) {
  return retailer.id === '0' ? getFirstNonZeroRetailerId(allWeekIdsByRetailerId) : retailer.id;
}
