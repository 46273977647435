import { Widget } from 'src/types/application/widgetTypes';
import { AggregationField } from 'src/types/application/types';
import { ISearchGridConditionsAndFilters } from 'src/components/AdManager/Search/Models/ISearchGridConditionsAndFilters';
import { buildFormDataAndQueryParams } from 'src/components/AdManager/Search/GridDataFetchers/BuildFormDataAndQueryParams';
import { buildMainEntityConditions } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import {
  buildAggregations,
  buildTimePeriodRangeFilters,
  createFilterConditions
} from 'src/components/AdManager/Search/index';
import { buildPayloadForComputedFilters } from 'src/components/AdManager/Search/GridDataFetchers/BuildPayloadForComputedFilters';
import queryString from 'qs';
import _get from 'lodash/get';
import { STACKLINE_UNASSIGNED } from 'src/utils/constants';
import { getEmptyFormData } from 'src/utils/segments';
import { getAppName } from 'src/utils/app';
import { Props } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';

export class SearchGridConditionsAndFiltersGenerator {
  public static generateSearchConditionsAndFilters(
    props: Pick<Props, 'location'>,
    app: any,
    retailer: any,
    widget: Widget,
    mainEntity: any,
    groupByField: AggregationField,
    formData: FormData,
    mainTimePeriod: any,
    comparisonTimePeriod: any,
    saAction: string | undefined,
    adCampaigns: any
  ): ISearchGridConditionsAndFilters {
    const emptyFormData = getEmptyFormData({ appName: getAppName() });
    const {
      data: {
        termFiltersForGroupByField,
        aggregationFilters: { computeFiltersForGroupByField },
        useInternalState
      }
    } = widget;

    const isWalmart = retailer.id === '2';
    const isSamsClub = retailer.id === '25';

    const { formDataToUse } = buildFormDataAndQueryParams(
      props,
      useInternalState,
      formData,
      app,
      isWalmart || isSamsClub
    );

    // Build entity conditions
    const entityConditions = buildMainEntityConditions(
      { termFilters: [], rangeFilters: [] },
      mainEntity,
      app,
      retailer
    );

    let searchSideBarConditions: ISearchFilterConditions = {
      termFilters: entityConditions.termFilters ? [...entityConditions.termFilters] : [],
      rangeFilters: entityConditions.rangeFilters ? [...entityConditions.rangeFilters] : [],
      computeFilters: entityConditions.computeFilters ? [...entityConditions.computeFilters] : []
    };
    searchSideBarConditions = createFilterConditions(
      formDataToUse,
      mainEntity!.type,
      termFiltersForGroupByField,
      groupByField,
      searchSideBarConditions
    );

    const aggregationFilters: ISearchFilterConditions = {
      termFilters: [],
      rangeFilters: [],
      computeFilters: []
    };

    // Building payload for computed filters
    if (computeFiltersForGroupByField[groupByField.name]) {
      buildPayloadForComputedFilters(
        computeFiltersForGroupByField,
        groupByField,
        formDataToUse,
        emptyFormData,
        aggregationFilters
      );
    }

    const configForGroupByField = widget.data.configByGroupByFieldName[groupByField.name];
    const { indexName } = configForGroupByField;
    const [{ aggregations: aggregationFields }] = buildAggregations(configForGroupByField.aggregationFields);

    let [{ aggregations: aggregationFieldsForResultCount }] = buildAggregations([groupByField]);
    let { mainTimePeriodRangeFilters, comparisonTimePeriodRangeFilters } = buildTimePeriodRangeFilters({
      app,
      indexName,
      mainTimePeriod,
      comparisonTimePeriod
    });

    /**
     * FIXME: Issue with the counts from cardinality comes from not having all aggregation fields.
     * Potentially needs to be fixed for other groupByFields
     */
    if (groupByField.name === 'retailerId') {
      aggregationFieldsForResultCount = [...aggregationFieldsForResultCount, ...aggregationFields];
    }

    const customTermFilters = widget.data.customTermFilters || [];
    const consistentRangeFilters = widget.data.consistentRangeFilters || undefined;
    const consistentCompareRangeFilters = widget.data.consistentCampareRangeFilters || undefined;
    if (consistentRangeFilters && consistentCompareRangeFilters) {
      mainTimePeriodRangeFilters = consistentRangeFilters;
      comparisonTimePeriodRangeFilters = consistentCompareRangeFilters;
    }

    const customConditionTermFilters = this.getCustomConditionTermFilters(saAction, retailer.id);

    if (isWalmart) {
      let autoCampaigns: string[] = [];
      const params = queryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });

      if (saAction === 'bid' && params && _get(params, 'groupByField') === 'stacklineSku') {
        adCampaigns.forEach((campaign) => {
          if (_get(campaign, 'extendedAttributes.amsApiModel.targetingType') === 'auto') {
            autoCampaigns.push(_get(campaign, 'id', ''));
          }
        });
      } else {
        autoCampaigns = [];
      }
      searchSideBarConditions.termFilters.forEach((termFilter, index) => {
        if (termFilter.fieldName === 'campaignId' && termFilter.condition === 'should') {
          searchSideBarConditions.termFilters[index].values.push(...autoCampaigns);
        }
      });
    }

    // hide unassigned portfolios at adEntity level
    if (mainEntity && mainEntity.type === 'adEntity' && groupByField.name === 'portfolioId') {
      customTermFilters.push({
        fieldName: 'portfolioId',
        condition: 'must_not',
        values: [STACKLINE_UNASSIGNED.portfolioId]
      });
    }
    return {
      indexName,
      entityConditions,
      searchSideBarConditions,
      aggregationFilters,
      aggregationFields,
      aggregationFieldsForResultCount,
      mainTimePeriodRangeFilters,
      comparisonTimePeriodRangeFilters,
      customTermFilters,
      customConditionTermFilters
    };
  }

  private static getCustomConditionTermFilters(saAction: string | undefined, retailerId: string) {
    const isWalmart = retailerId === '2' || retailerId === '25';
    const isInstacart = retailerId === '63';

    const customConditionTermFilters = [];

    // create/edit sa page (walmart) - hide auto target as it is just a placeholder
    if (saAction && isWalmart && ['bid', 'targetStatus'].includes(saAction)) {
      customConditionTermFilters.push({
        fieldName: 'targetingType',
        condition: 'must_not',
        values: ['auto_targeting']
      });
    }

    // create/edit sa page (instacart) - hide browseImpressions target as it is just a placeholder
    if (saAction && isInstacart && ['bid', 'targetStatus'].includes(saAction)) {
      customConditionTermFilters.push({
        fieldName: 'targetingText',
        condition: 'must_not',
        values: ['browseImpressions']
      });
    }
    return customConditionTermFilters;
  }
}
