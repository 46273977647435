import AdBudgetPacing from 'src/components/AdManager/AdBudgetPacing';
import { PageLayout } from 'src/types/application/widgetTypes';
import colors from 'src/utils/colors';
import AdManagerEntityHeader from 'src/components/AdManager/AdManagerEntityHeader';
import { buildAdvancedSearchSideBarWidget } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';

export const getLayout = ({ entity }: { entity: { type: string } }): Partial<PageLayout> => {
  const HeaderWidget = {
    CustomComponent: AdManagerEntityHeader,
    name: 'adManagerHeader',
    view: {
      name: 'adManagerHeader',
      title: 'Ad Score',
      container: {
        style: { borderBottom: `1px solid ${colors.lightestGrey}`, marginBottom: 50 }
      }
    },
    data: {
      adScoreName: 'adScore'
    }
  };
  const advancedSearchSideBarWidget = buildAdvancedSearchSideBarWidget();
  advancedSearchSideBarWidget.view.hideByDefault = true;
  advancedSearchSideBarWidget.view.container.style = {
    ...advancedSearchSideBarWidget.view.container.style,
    paddingTop: 0
  };
  advancedSearchSideBarWidget.data.defaultGroupByField = 'stacklineSku';
  advancedSearchSideBarWidget.data.useInternalState = true;

  const groupByFieldNameByEntityType = {
    client: 'retailerId',
    adEntity: 'entityId',
    adPortfolio: 'portfolioId',
    adCampaign: 'campaignId'
  };

  return {
    widgets: [
      HeaderWidget,
      {
        name: 'adManagerInfoPage',
        CustomComponent: AdBudgetPacing,
        view: {
          noInnerContainer: true,
          name: 'adManagerInfoPage',
          gridOptions: {
            pageSize: 50
          }
        },
        data: {
          groupByFieldName: groupByFieldNameByEntityType[entity.type],
          statePropertyName: 'budgetPacing_info'
        }
      }
    ]
  };
};
