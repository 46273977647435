import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TextField from '@mui/material/TextField';
import { useStacklineTheme } from '@stackline/ui';
import axios from 'axios';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { mkExportAllSegments } from 'src/components/SegmentPowerTools/SegmentExport';
import { useAppSelector } from 'src/utils/Hooks';
import { shouldShowNewBeacon } from 'src/utils/app';
import colors from 'src/utils/colors';

const isFormValid = (value, id, entityDefinition, segments) =>
  id
    ? value !== '' &&
      !(segments || []).find(
        (segment) => segment.dn === value && segment.id !== id && segment.entityType === entityDefinition.type
      )
    : value !== '' &&
      !(segments || []).find((segment) => segment.dn === value && segment.entityType === entityDefinition.type);

const getError = (isFormSubmitted, dn, id, entityDefinition, segments) => {
  if (!isFormSubmitted || (isFormSubmitted && isFormValid(dn, id, entityDefinition, segments))) {
    return '';
  }

  if (dn === '') {
    return 'This field is required';
  }
  return 'This name is already taken. Please choose a different name.';
};

const DisplayNameAdvancedSearch = ({
  app,
  entityDefinition,
  id,
  isFormSubmitted,
  dn: initialDn,
  onChange,
  shouldSupportExport
}) => {
  const allSegments = useAppSelector((state) => state.segments);
  const categories = useAppSelector((state) => state.categories);
  const subCategories = useAppSelector((state) => state.subCategories);
  const retailer = useAppSelector((state) => state.retailer);
  const user = useAppSelector((state) => state.user);
  const [dn, setDn] = useState(initialDn);
  const segments = useRef(null);

  const theme = useStacklineTheme();

  const isSegmentExist =
    allSegments.mySegments.find((ele) => _get(ele, ['id']) === id) ||
    allSegments.teamSegments.find((ele) => _get(ele, ['segment', 'dn']) === initialDn);
  const filteredSegment = {
    mySegments: allSegments.mySegments.filter((ele) => _get(ele, ['id']) === id),
    mySearchTermLists: [],
    teamSegments: allSegments.teamSegments.filter((ele) => _get(ele, ['segment', 'dn']) === initialDn),
    teamSearchTermLists: []
  };
  const exportAllSegments = mkExportAllSegments(
    { segments: filteredSegment, categories, subCategories, retailer, app },
    `${initialDn}-saved-search.csv`
  );

  useEffect(() => {
    if (segments.current === null) {
      segments.current = [];

      axios
        .get(`/api/${app.name}/GetAllSavedSearch?appName=${app.apiAppName}&timestamp=${Date.now()}`)
        .then((response) => {
          segments.current = response.data.map((segment) => ({
            ...JSON.parse(segment.query),
            canEdit: segment.canEdit
          }));
          onChange({ isFormValid: isFormValid(dn, id, entityDefinition, segments.current), dn });
        });
    }
  });

  const handleInputChange = useCallback(
    ({ target: { value } }) => {
      setDn(value);

      onChange({
        isFormValid: isFormValid(value, id, entityDefinition, segments.current),
        dn: value
      });
    },
    [setDn, onChange, id, entityDefinition]
  );

  const segmentType =
    shouldShowNewBeacon() && entityDefinition.type === 'searchtermlist' ? 'topic' : entityDefinition.displayName;

  return (
    <div className="search-form-container" style={{ ...(shouldShowNewBeacon() ? { marginBottom: '37px' } : {}) }}>
      <Flex>
        {shouldShowNewBeacon() ? (
          <Flex sx={{ height: '16px' }}>
            <Text variant="h6">Name</Text>
          </Flex>
        ) : (
          <h4 className="sl-form-label">Name</h4>
        )}

        {/* temp only allow on dev */}
        {app.stage === 'dev' && shouldSupportExport && isSegmentExist && user.config.isStacklineSuperUser ? (
          <div
            style={{ height: '20px' }}
            role="button"
            onClick={() => {
              exportAllSegments();
            }}
          >
            <CloudDownloadIcon style={{ color: colors.blue, marginLeft: '5px' }} />
          </div>
        ) : null}
      </Flex>
      {shouldShowNewBeacon() ? (
        <Flex marginTop="4px" height="16px">
          <TextField
            variant="standard"
            autoComplete="off"
            className="updated-sl-form-input"
            placeholder={`Name your ${segmentType.toLowerCase()}`}
            helperText={getError(isFormSubmitted, dn, id, entityDefinition, segments.current)}
            type="text"
            name={entityDefinition.name}
            id={entityDefinition.name}
            style={{ width: '100%' }}
            value={dn}
            onChange={handleInputChange}
            sx={{
              '&::placeholder': {
                fontSize: '12px',
                color: theme.colors.secondary
              },
              '& .MuiInput-underline:before': {
                borderBottomColor: '#e8e8ed'
              }
            }}
          />
        </Flex>
      ) : (
        <TextField
          variant="standard"
          autoComplete="off"
          className="updated-sl-form-input"
          placeholder={`Name your ${segmentType.toLowerCase()}`}
          helperText={getError(isFormSubmitted, dn, id, entityDefinition, segments.current)}
          type="text"
          name={entityDefinition.name}
          id={entityDefinition.name}
          style={{ width: '100%' }}
          value={dn}
          sx={{
            '& .MuiInput-underline:before': {
              borderBottomColor: '#dedede'
            },
            '& .MuiInput-underline::hover:before': {
              borderBottomColor: '#dedede'
            },
            '& .MuiInputBase-root:before': {
              borderBottomColor: '#dedede'
            },
            '& .MuiInputBase-root::hover:before': {
              borderBottomColor: '#dedede'
            }
          }}
          onChange={handleInputChange}
        />
      )}
    </div>
  );
};

DisplayNameAdvancedSearch.propTypes = {
  entityDefinition: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  dn: PropTypes.string,
  id: PropTypes.string,
  shouldSupportExport: PropTypes.bool
};

DisplayNameAdvancedSearch.defaultProps = {
  dn: '',
  id: null,
  shouldSupportExport: false
};

export default DisplayNameAdvancedSearch;
