import React, { useEffect, useState } from 'react';
import { SmartSegmentCard } from './SegmentCard';
import _chunk from 'lodash/chunk';
import { SlColumn, SlRow, StacklineColor } from '@stackline/ui';
import Pagination from 'src/components/BeaconRedesignComponents/Pagination/Pagination';
import { Segment } from 'sl-api-connector';
import Box from '@mui/material/Box';
import { shouldHidePagination } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/utils';

const CARDS_PER_ROW = 4;
const CARDS_PER_PAGE = 20;

interface SegmentCardGridProps {
  segments: Segment[];
  indexName?: string;
  fieldName?: string;
  metricDisplayName?: string;
  color?: StacklineColor;
  isTopicSegment?: boolean;
}

/**
 * Shows a grid of segment cards
 */
const SegmentCardGrid = ({
  segments,
  indexName,
  fieldName,
  metricDisplayName,
  color,
  isTopicSegment
}: SegmentCardGridProps) => {
  const totalPages = Math.ceil(segments.length / CARDS_PER_PAGE);
  const [page, setPage] = useState(1);

  const startIndex = (page - 1) * CARDS_PER_PAGE;
  const endIndex = startIndex + CARDS_PER_PAGE;

  const handleNextPage = () => {
    setPage(Math.min(page + 1, totalPages));
  };

  const handlePrevPage = () => {
    setPage(Math.max(page - 1, 1));
  };

  /**
   * Reset the page when the search changes
   */
  useEffect(() => {
    setPage(1);
  }, [segments]);

  return (
    <SlColumn spacing="mdl" widths="full">
      {_chunk(segments.slice(startIndex, endIndex), CARDS_PER_ROW).map((row, index) => {
        return (
          <SlRow key={index} spacing="mdl">
            {row.map((segment) => {
              return (
                <SmartSegmentCard
                  indexName={indexName}
                  fieldName={fieldName}
                  key={segment.id}
                  segment={segment}
                  metricDisplayName={metricDisplayName}
                  color={color}
                  isTopicSegment={isTopicSegment}
                />
              );
            })}
          </SlRow>
        );
      })}
      <Box width="100%" display="flex" justifyContent="flex-end">
        {shouldHidePagination({ page, pageSize: CARDS_PER_PAGE, rowCount: (segments || []).length }) ? null : (
          <Pagination
            currentPage={page}
            onClickNext={() => handleNextPage()}
            onClickPrevious={() => handlePrevPage()}
            totalPages={totalPages}
          />
        )}
      </Box>
    </SlColumn>
  );
};

export default SegmentCardGrid;
