import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import _pick from 'lodash/pick';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import queryString from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AutoSizer, List as VirtualizedList } from 'react-virtualized';
import i18n from 'src/i18n_en';
import ReduxStore from 'src/types/store/reduxStore';
import { anyNotEq } from 'src/utils/equality';
import { ListFilterRow } from './common';
import './Filter.scss';

const NoPromoTypesFound = () => <div style={{ height: 175 }}>{i18n.promoTypes_noneFound}</div>;

interface PromoTypeForEntity {
  name: string;
  displayName: string;
  isChecked: boolean;
}

// We have to use lodash's `_.orderBy()` here since it's stable, and JS isn't guaranteed to have a stable sort algorithm
const sortPromoTypes = (promoTypes: PromoTypeForEntity) =>
  _orderBy(promoTypes, [({ isChecked }) => (isChecked ? 1 : 0)], ['desc']);

interface PromoTypeFilterProps {
  filters: any;
  promoTypes: ReduxStore['promoTypes'];
  entity: any;
  location: any;
  onFilterChange: (arg0: any, arg1: any) => void;
}

interface PromoTypeFilterState {
  searchText: string;
  promoTypesForEntity: PromoTypeForEntity[];
  staticSearchPromoTypes: any[];
}

class PromoTypeFilter extends Component<PromoTypeFilterProps, PromoTypeFilterState> {
  private static propTypes = {
    filters: PropTypes.object.isRequired,
    promoTypes: PropTypes.array.isRequired,
    entity: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired
  };

  public constructor(props: PromoTypeFilterProps) {
    super(props);

    this.state = {
      searchText: '',
      promoTypesForEntity: [],
      staticSearchPromoTypes: []
    };
  }

  public componentWillMount() {
    this.setPromoTypes(this.props);
  }

  public componentWillReceiveProps(nextProps: PromoTypeFilterProps) {
    const { filters } = nextProps;
    if (
      (filters.isClearingFilters && this.props.filters.promoType !== null) ||
      anyNotEq(['entity', 'location'], this.props, nextProps) ||
      this.props.promoTypes !== nextProps.promoTypes // shallow equality for increased speed
    ) {
      this.setPromoTypes(nextProps);
    }
  }

  private setPromoTypes = (props: PromoTypeFilterProps) => {
    const { promoTypes, filters, location } = props;

    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const parsedFilterParams = queryParams.filter && queryString.parse(JSON.parse(queryParams.filter));

    const promoTypesForEntity = promoTypes.map((val) => {
      const isChecked =
        filters.promoType !== null &&
        parsedFilterParams &&
        parsedFilterParams.pt &&
        // eslint-disable-next-line
        !!parsedFilterParams.pt.find((item: { i: string }) => item.i == val.name);

      return { ...val, isChecked };
    });

    this.setState({
      staticSearchPromoTypes: promoTypesForEntity,
      promoTypesForEntity: sortPromoTypes(promoTypesForEntity),
      searchText: ''
    });
  };

  private handleCheck = (event, isInputChecked: boolean, index: any) => {
    const { onFilterChange } = this.props;
    const { promoTypesForEntity, staticSearchPromoTypes } = this.state;
    promoTypesForEntity[index].isChecked = isInputChecked;

    staticSearchPromoTypes.forEach((val) => {
      if (String(val.displayName) === String(promoTypesForEntity[index].displayName)) {
        val.isChecked = isInputChecked;
      }
    });
    const values = staticSearchPromoTypes.filter((val) => val.isChecked);

    this.setState({ staticSearchPromoTypes, promoTypesForEntity });
    onFilterChange(values, 'promoType');
  };

  private handleInputChange = ({ target: { value } }) => {
    const lowerCaseVal = value.toLowerCase();
    const updatedPromoTypes = this.state.staticSearchPromoTypes.filter(({ displayName }) =>
      displayName.toLowerCase().includes(lowerCaseVal)
    );

    this.setState({
      searchText: value,
      promoTypesForEntity: sortPromoTypes(updatedPromoTypes)
    });
  };

  private rowRenderer = ({ index, style }) => {
    const { promoTypesForEntity } = this.state;
    return (
      <ListFilterRow
        index={index}
        key={index}
        style={style}
        isChecked={promoTypesForEntity[index].isChecked}
        value={promoTypesForEntity[index].displayName}
        onCheck={this.handleCheck}
      />
    );
  };

  public render() {
    const { promoTypesForEntity, staticSearchPromoTypes, searchText } = this.state;

    if (staticSearchPromoTypes && _isEmpty(staticSearchPromoTypes)) {
      return null;
    }

    return (
      <div className="search-form-container search-form-container--sm">
        <TextField
          variant="standard"
          autoComplete="off"
          className="sl-form-input"
          placeholder="Search for promo types"
          type="text"
          name="keyword"
          id="keyword"
          style={{ width: '100%' }}
          value={searchText}
          onChange={this.handleInputChange}
        />
        {promoTypesForEntity && _isEmpty(promoTypesForEntity) ? <NoPromoTypesFound /> : null}
        {!_isEmpty(promoTypesForEntity) ? (
          <AutoSizer disableHeight>
            {({ width }) => (
              <VirtualizedList
                width={width}
                height={300}
                rowCount={promoTypesForEntity.length}
                rowHeight={45}
                style={{ marginBottom: 10 }}
                rowRenderer={this.rowRenderer}
              />
            )}
          </AutoSizer>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['app', 'promoTypes', 'comparisonTimePeriod', 'filters', 'mainTimePeriod', 'retailer']);

export default withRouter(connect(mapStateToProps)(PromoTypeFilter));
