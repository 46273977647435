import types from './types';
import _omit from 'lodash/omit';

// ------------------------------------
// Reducer
// ------------------------------------

export default function entity(state = {}, action) {
  switch (action.type) {
    case types.RECEIVE_ENTITY:
      return {
        ...state,
        [action.statePropertyName]: action.statePropertyValue,
        lastUpdated: action.receivedAt
      };
    case types.REMOVE_ENTITY:
      return _omit({ ...state, lastUpdated: action.receivedAt }, action.statePropertyName);
    // case types.CLEAR_ENTITY:
    //   return Object.assign({},{});
    case types.UPDATE_SBA_PROFILE:
      return {
        ...state,
        [action.statePropertyName]: {
          ...state[action.statePropertyName],
          extendedAttributes: {
            ...state[action.statePropertyName].extendedAttributes,
            amsApiModel: {
              ...state[action.statePropertyName].extendedAttributes.amsApiModel,
              sbaProfile: {
                ...state[action.statePropertyName].extendedAttributes.amsApiModel.sbaProfile,
                ...action.sbaProfile
              }
            }
          }
        }
      };
    default:
      return state;
  }
}
