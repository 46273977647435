import axios, { CancelTokenSource } from 'axios';
import { Option } from 'funfix-core';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _maxBy from 'lodash/maxBy';
import _pick from 'lodash/pick';
import React, { Component } from 'react';
import { withBus } from 'react-bus';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { Conditions, Entity } from 'sl-api-connector/types';
import { buildAggregations } from 'src/components/AdManager/Search';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { Widget } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import { ActivatedActionCreators } from 'src/types/utils';
import colors from 'src/utils/colors';
import { anyNotEq } from 'src/utils/equality';
import { buildSubtitleDisplayName } from 'src/utils/filters';
import fontStyle from 'src/utils/fontStyle';
import { prop, propEq } from 'src/utils/fp';
import { warn } from 'src/utils/mixpanel';
import DonutChart from './DonutChart';
import _sum from 'lodash/sum';
import { shouldShowNewBeacon } from 'src/utils/app';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { SlColumn } from '@stackline/ui';
import DonutChartLoading from 'src/components/Charts/Donut/DonutChartLoading';

const { CancelToken } = axios;

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, [
    'app',
    'categories',
    'filters',
    'entityService',
    'entitySearchService',
    'comparisonTimePeriod',
    'mainTimePeriod',
    'retailer',
    'categoriesByRetailerId'
  ]);

const getLatestWeekValue = (dataPoints: { weekId: number; value: number }[]) => {
  if (_isEmpty(dataPoints)) {
    return 0;
  }

  return _maxBy(dataPoints, prop('weekId'))!.value;
};

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

type DonutChartContainerProps = {
  entity: Entity;
  queryConditions: Conditions;
  aggregationConditions: Conditions;
  widget: Widget;
  uniqueName?: string;
  isGrowthCalculation?: boolean;
} & ReturnType<typeof mapStateToProps> &
  ActivatedActionCreators<typeof mapDispatchToProps> &
  RouteComponentProps;

export class DonutChartContainer extends Component<DonutChartContainerProps> {
  public static defaultProps = {
    uniqueName: 'donutChart'
  };

  private cancelSource: CancelTokenSource | undefined;

  public state = { isLoading: true };

  public componentDidMount() {
    this.cancelSource = CancelToken.source();
    this.fetchMetrics(this.props);
  }

  public componentWillReceiveProps(nextProps: DonutChartContainerProps) {
    if (
      anyNotEq(
        ['retailer', 'mainTimePeriod', 'queryConditions', 'entity', 'location.search', 'categoriesByRetailerId'],
        this.props,
        nextProps
      )
    ) {
      if (this.cancelSource) {
        this.cancelSource.cancel('Canceled network request: donut');
      }
      this.cancelSource = CancelToken.source();
      this.fetchMetrics(nextProps || this.props);
    }
  }

  public componentWillUnmount() {
    if (this.cancelSource) {
      this.cancelSource.cancel('Cancel network request');
    }
  }

  private getChargeBacksChartSeries = ({
    entity,
    entitySearchService,
    retailer,
    widget,
    uniqueName
  }: DonutChartContainerProps) => {
    const chartMetrics = entitySearchService[`donutChartMetrics${uniqueName}`];

    const [prepData, logisticsData, labelingData] = ['prep', 'logistic', 'labeling'].map(
      (dataType) => chartMetrics[`${dataType}Charges_by_${widget.data.groupByField.name}`].data[0]
    );

    const seriesData = [];
    let title = '';

    if (prepData && logisticsData && labelingData) {
      seriesData.push({
        fieldId: 'prepCharges',
        name: 'Prep Charges',
        y: prepData.value,
        color: colors.purple
      });
      seriesData.push({
        fieldId: 'logisticCharges',
        name: 'Logistic Charges',
        y: logisticsData.value,
        color: colors.orange
      });
      seriesData.push({
        fieldId: 'labelingCharges',
        name: 'Labeling Charges',
        y: labelingData.value,
        color: colors.pink
      });

      title = prepData.value + logisticsData.value + labelingData.value;
    }

    return {
      data: seriesData,
      entity,
      title,
      titleColor: colors.darkBlue,
      currencySymbol: retailer.currencySymbol
    };
  };

  private getShortagesChartSeries = ({
    entity,
    entitySearchService,
    retailer,
    widget,
    uniqueName
  }: DonutChartContainerProps) => {
    const chartMetrics = entitySearchService[`donutChartMetrics${uniqueName}`];

    const [underReviewData, lostShortagesData, notYetDisputedData, recoveredShortageData] = [
      'disputeUnderReviewShortageAmountV2',
      'lostShortageAmountV2',
      'notYetDisputedShortageAmountV2',
      'recoveredShortageAmountV2'
    ].map((dataType) => chartMetrics[`${dataType}_by_${widget.data.groupByField.name}`].data[0]);

    const seriesData = [];
    let title = '';

    if (underReviewData && lostShortagesData && notYetDisputedData && recoveredShortageData) {
      seriesData.push({
        fieldId: 'notYetDisputedShortageAmountV2',
        name: 'Not Yet Disputed Amount',
        y: notYetDisputedData.value,
        color: colors.blue
      });
      seriesData.push({
        fieldId: 'disputeUnderReviewShortageAmountV2',
        name: 'Disputes Under Review Amount',
        y: underReviewData.value,
        color: colors.purple
      });
      seriesData.push({
        fieldId: 'recoveredShortageAmountV2',
        name: 'Recovered Shortage Amount',
        y: recoveredShortageData.value,
        color: colors.orange
      });
      seriesData.push({
        fieldId: 'lostShortageAmountV2',
        name: 'Lost Shortage Amount',
        y: lostShortagesData.value,
        color: colors.red
      });

      title = _sum([
        underReviewData.value,
        lostShortagesData.value,
        notYetDisputedData.value,
        recoveredShortageData.value
      ]).toString();
    }

    return {
      data: seriesData,
      entity,
      title,
      titleColor: colors.darkBlue,
      currencySymbol: retailer.currencySymbol
    };
  };

  private getOtherTrafficChartSeries = ({
    entity,
    entitySearchService,
    retailer,
    widget,
    uniqueName,
    app
  }: DonutChartContainerProps) => {
    const chartMetrics = entitySearchService[`donutChartMetrics${uniqueName}`];

    // Need to generalize this beyond ad
    const [adData, otherData, organicData, otherAdData] =
      app.name === 'beacon'
        ? ['ad', 'other', 'organic', 'otherAd'].map(
            (dataType) => chartMetrics[`${dataType}Clicks_by_${widget.data.groupByField.name}`].data[0]
          )
        : ['ad', 'other', 'organic'].map(
            (dataType) => chartMetrics[`${dataType}Clicks_by_${widget.data.groupByField.name}`].data[0]
          );

    const seriesData = [];
    let title = '';

    if (adData && otherData && organicData) {
      seriesData.push({
        fieldId: 'organicTraffic',
        name: 'Organic Traffic',
        y: organicData.value,
        color: colors.purple
      });
      seriesData.push({
        fieldId: 'adTraffic',
        name: 'Paid Traffic',
        y: adData.value,
        color: colors.orange
      });
      seriesData.push({
        fieldId: 'otherTraffic',
        name: 'Other Traffic',
        y: otherData.value,
        color: colors.pink
      });

      title = organicData.value + adData.value + otherData.value;
    }

    if (otherAdData) {
      seriesData.push({
        fieldId: 'otherAdTraffic',
        name: 'DSP Clicks',
        y: otherAdData.value,
        color: colors.green
      });

      title = organicData.value + adData.value + otherData.value + otherAdData.value;
    }

    return {
      data: seriesData,
      entity,
      title,
      titleColor: colors.darkBlue,
      currencySymbol: retailer.currencySymbol
    };
  };

  private getPromoDonutChartSeries = ({
    entity,
    entitySearchService,
    retailer,
    widget,
    uniqueName
  }: DonutChartContainerProps) => {
    const dataPointsPromo =
      entitySearchService[`donutChartMetrics${uniqueName}`][
        `${widget.data.chartMainField.name}_by_${widget.data.groupByField.name}`
      ].data;
    const dataPointsRetail =
      entitySearchService[`donutChartMetrics${uniqueName}_retail`][
        `${widget.data.chartMainField.name}_by_${widget.data.groupByField.name}`
      ].data;
    const seriesData = [];
    const mainMetricDataPromo = dataPointsPromo.find((o) => o.name === retailer.id);
    const mainMetricDataRetail = dataPointsRetail.find((o) => o.name === retailer.id);
    const mainMetricValuePromo = mainMetricDataPromo ? mainMetricDataPromo.value : 0;
    const mainMetricValueRetail = mainMetricDataRetail ? mainMetricDataRetail.value : 0;

    seriesData.push({
      fieldId: widget.data.chartMainField.name,
      name: widget.data.chartMainField.displayName,
      y: mainMetricValuePromo / mainMetricValueRetail,
      color: colors.stacklineBlue
    });
    seriesData.push({
      fieldId: 'Other',
      name: 'Others',
      y: 1 - mainMetricValuePromo / mainMetricValueRetail,
      color: colors.lightestGrey
    });

    return {
      data: seriesData,
      entity,
      title: mainMetricValuePromo / mainMetricValueRetail,
      titleColor: colors.darkBlue,
      currencySymbol: retailer.currencySymbol
    };
  };

  private getAggregatedRetailerDonutChartSeries = (props: DonutChartContainerProps) => {
    const { entity, entitySearchService, retailer, widget, uniqueName } = props;
    if (widget.view.name === 'RatingDonutChart') {
      return entitySearchService[`donutChartMetrics${uniqueName}`];
    }

    const dataPoints = _get(
      entitySearchService,
      [
        `donutChartMetrics${uniqueName}`,
        `${widget.data.chartMainField.name}_by_${widget.data.groupByField.name}`,
        'data'
      ],
      []
    );

    if (_isEmpty(dataPoints)) {
      warn('No data found for donut chart; would have crashed');
    }

    const comparisonDataPoints = _get(
      entitySearchService,
      [
        `donutChartMetrics${uniqueName}-growth-comparison`,
        `${widget.data.chartMainField.name}_by_${widget.data.groupByField.name}`,
        'data'
      ],
      []
    );

    let mainMetricValue = 0;
    let comparisonMainMetric = 0;
    const seriesData: { fieldId: string; name: string; y: any; color?: string }[] = [];

    if (widget.data.isGrowthCalculation) {
      dataPoints.forEach((point: any) => {
        mainMetricValue += point.value;

        const matchingPointForComparison = comparisonDataPoints.find((c) => c.name === point.name);

        if (matchingPointForComparison) {
          comparisonMainMetric += matchingPointForComparison.value;
          const retailerData = retailer.availableRetailers.find((r) => r.id === point.name);
          const growth = matchingPointForComparison.value === 0 ? 10 : point.value / matchingPointForComparison.value;
          seriesData.push({
            fieldId: point.name,
            name: retailerData ? retailerData.displayName : point.name,
            y: growth
          });
        }
      });
      mainMetricValue -= comparisonMainMetric;
    } else {
      dataPoints.forEach((point: any) => {
        mainMetricValue += point.value;
        const retailerData = retailer.availableRetailers.find((r) => r.id === point.name);
        seriesData.push({
          fieldId: point.name,
          name: retailerData ? retailerData.displayName : point.name,
          y: point.value
        });
      });
    }

    return {
      data: seriesData,
      entity,
      title: mainMetricValue,
      titleColor: colors.darkBlue,
      currencySymbol: retailer.currencySymbol
    };
  };

  private getDonutChartSeries = (props: DonutChartContainerProps) => {
    const { entity, entitySearchService, retailer, widget, uniqueName, mainTimePeriod } = props;
    if (widget.view.name === 'RatingDonutChart') {
      return entitySearchService[`donutChartMetrics${uniqueName}`];
    }

    const dataPoints = _get(
      entitySearchService,
      [
        `donutChartMetrics${uniqueName}`,
        `${widget.data.chartMainField.name}_by_${widget.data.groupByField.name}`,
        'data'
      ],
      []
    );

    if (_isEmpty(dataPoints)) {
      warn('No data found for donut chart; would have crashed');
    }

    const mainMetricValue = _get(widget, ['data', 'useLatestWeek'])
      ? (() => {
          // Make sure we are grabbing the last week in the range
          const valuesForMainTimePeriod = dataPoints.filter(
            ({ weekId }: { weekId: number }) => weekId >= mainTimePeriod.startWeek && weekId <= mainTimePeriod.endWeek
          );
          return getLatestWeekValue(valuesForMainTimePeriod);
        })()
      : Option.of(dataPoints.find(propEq('name', retailer.id)))
          .map(prop('value'))
          .getOrElse(0);

    const seriesData = [
      {
        fieldId: widget.data.chartMainField.name,
        name: widget.data.chartMainField.displayName,
        y: mainMetricValue,
        color: colors.stacklineBlue
      },
      {
        fieldId: 'Unearned',
        name: '',
        y: (widget.data.chartMainField.maxValue || 1) - mainMetricValue,
        color: colors.lightestGrey
      }
    ];

    return {
      data: seriesData,
      entity,
      title: mainMetricValue,
      titleColor: colors.darkBlue,
      currencySymbol: retailer.currencySymbol
    };
  };

  private buildTimePeriodRangeFilter = ({
    widget,
    mainTimePeriod
  }: Pick<DonutChartContainerProps, 'widget' | 'mainTimePeriod'>) => {
    if (_get(widget, ['data', 'useLatestWeek'])) {
      return {
        fieldName: 'weekId',
        // Pull at least the last year of data in order to find the last week for which we have a value
        minValue: Math.min(
          mainTimePeriod.startWeek,
          mainTimePeriod.availableMainTimePeriods.find((x) => x.id === '52w')!.startWeek
        ),
        maxValue: mainTimePeriod.endWeek
      };
    }

    let isLatest = false;
    isLatest =
      widget.view && widget.view.avoidIsLatestComputation
        ? false
        : _get(widget, ['data', 'mainMetric', 'aggregationFunctionType']) === 'lastValue' ||
          _get(widget, ['data', 'mainMetric', 'aggregationFunctionTimeRange']) === 'lastWeek';
    return {
      fieldName: 'weekId',
      minValue: isLatest ? mainTimePeriod.endWeek : mainTimePeriod.startWeek,
      maxValue: mainTimePeriod.endWeek
    };
  };

  private buildComparisonTimePeriodRangeFilter = ({
    widget,
    comparisonTimePeriod
  }: Pick<DonutChartContainerProps, 'widget' | 'comparisonTimePeriod'>) => {
    if (_get(widget, ['data', 'useLatestWeek'])) {
      return {
        fieldName: 'weekId',
        // Pull at least the last year of data in order to find the last week for which we have a value
        minValue: Math.min(
          comparisonTimePeriod.startWeek,
          comparisonTimePeriod.availableMainTimePeriods.find((x) => x.id === '52w')!.startWeek
        ),
        maxValue: comparisonTimePeriod.endWeek
      };
    }

    const isLatest =
      _get(widget, ['data', 'mainMetric', 'aggregationFunctionType']) === 'lastValue' ||
      _get(widget, ['data', 'mainMetric', 'aggregationFunctionTimeRange']) === 'lastWeek';
    return {
      fieldName: 'weekId',
      minValue: isLatest ? comparisonTimePeriod.endWeek : comparisonTimePeriod.startWeek,
      maxValue: comparisonTimePeriod.endWeek
    };
  };

  private fetchMetrics({
    app,
    retailer,
    widget,
    uniqueName,
    entity,
    fetchEntityMetrics,
    queryConditions,
    aggregationConditions,
    mainTimePeriod,
    comparisonTimePeriod,
    isGrowthCalculation
  }: DonutChartContainerProps) {
    const { aggregationFields, indexName, groupByField } = widget.data;
    const [{ aggregations, aggregationFieldConditions }] = buildAggregations(aggregationFields);
    const aggregationConditionsCloned = _cloneDeep(aggregationConditions);
    aggregationConditionsCloned.rangeFilters = [
      ...(aggregationConditionsCloned.rangeFilters || []).filter((x) => x.fieldName !== 'weekId'),
      this.buildTimePeriodRangeFilter({ widget, mainTimePeriod })
    ];

    if (aggregationFieldConditions && aggregationFieldConditions.rangeFilters) {
      aggregationConditionsCloned.rangeFilters = [
        ...aggregationConditionsCloned.rangeFilters,
        ...aggregationFieldConditions.rangeFilters
      ];
    }
    if (aggregationFieldConditions && aggregationFieldConditions.termFilters) {
      aggregationConditionsCloned.termFilters = [
        ...aggregationConditionsCloned.termFilters,
        ...aggregationFieldConditions.termFilters
      ];
    }
    const requestOverrides = {
      aggregations: [
        {
          aggregationFields: aggregations,
          conditions: aggregationConditionsCloned,
          groupByFieldName: groupByField.name
        }
      ],
      conditions: queryConditions,
      pageNumber: 1,
      pageSize: 200
    };
    this.setState({ isLoading: true });

    const promises = [];
    promises.push(
      fetchEntityMetrics(
        `donutChartMetrics${uniqueName}`,
        { entity, retailer, app, indexName },
        [requestOverrides],
        this.cancelSource ? this.cancelSource.token : undefined
      )
    );

    if (widget.data.isGrowthCalculation || isGrowthCalculation) {
      const comparisonRequestOverrides = {
        aggregations: [
          {
            aggregationFields: aggregations,
            conditions: aggregationConditionsCloned,
            groupByFieldName: groupByField.name
          }
        ],
        conditions: queryConditions,
        pageNumber: 1,
        pageSize: 200
      };
      comparisonRequestOverrides.aggregations[0].conditions.rangeFilters![0] =
        this.buildComparisonTimePeriodRangeFilter({ widget, comparisonTimePeriod });
      promises.push(
        fetchEntityMetrics(
          `donutChartMetrics${uniqueName}-growth-comparison`,
          { entity, retailer, app, indexName },
          [comparisonRequestOverrides],
          this.cancelSource ? this.cancelSource.token : undefined
        )
      );
    }

    if (widget.view.name === 'PromoDonutChart') {
      promises.push(
        fetchEntityMetrics(
          `donutChartMetrics${uniqueName}_retail`,
          { entity, retailer, app, indexName: 'sales' },
          [requestOverrides],
          this.cancelSource ? this.cancelSource.token : undefined
        )
      );
    }

    Promise.all(promises)
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (!err.message.includes('Canceled network request')) {
          console.error(err);
        }
      });
  }

  private renderHeader = () => {
    const {
      widget,
      retailer,
      entityService: { mainEntity },
      app,
      categories,
      filters
    } = this.props;
    if (!widget.view.customHeader || !mainEntity) {
      return null;
    }

    const subtitle = buildSubtitleDisplayName(
      retailer,
      mainEntity as Entity & {
        categoryIds?: unknown[];
        subCategoryId: string | number;
        name: string;
        subCategoryName: string;
      },
      filters,
      categories,
      app
    );

    return (
      <div>
        <h2 style={{ fontSize: 28, fontWeight: fontStyle.regularWeight, margin: '0 0 15px 0' }}>
          {widget.view.customHeader.title}
        </h2>
        <div // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: subtitle.displayName }}
        />
      </div>
    );
  };

  public render() {
    const { widget, retailer } = this.props;
    let donutChartSeries = {};

    if (this.state.isLoading) {
      if (shouldShowNewBeacon()) {
        return (
          <>
            <SlColumn verticalInset="lg" spacing="md" widths="full">
              {widget.view.customHeader ? <CommonSummaryInfoSubtitle title={widget.view.customHeader.title} /> : null}
            </SlColumn>
            <DonutChartLoading />
          </>
        );
      }
      return null;
    }

    switch (widget.view.name) {
      case 'OtherTrafficDonutChart':
        donutChartSeries = this.getOtherTrafficChartSeries(this.props);
        break;
      case 'PromoDonutChart':
        widget.data.mainMetric.metricType = 'PERCENT';
        donutChartSeries = this.getPromoDonutChartSeries(this.props);
        break;
      case 'AggregatedRetailersDonutChart':
        donutChartSeries = this.getAggregatedRetailerDonutChartSeries(this.props);
        break;
      case 'ChargeBackMetricsDonutChart':
        donutChartSeries = this.getChargeBacksChartSeries(this.props);
        break;
      case 'NotYetDisputedShortageDonutChart':
        donutChartSeries = this.getShortagesChartSeries(this.props);
        break;
      default:
        donutChartSeries = this.getDonutChartSeries(this.props);
    }

    return donutChartSeries ? (
      <div key={widget.view.name}>
        {shouldShowNewBeacon() ? (
          <SlColumn verticalInset="lg" spacing="md" widths="full">
            {widget.view.customHeader ? <CommonSummaryInfoSubtitle title={widget.view.customHeader.title} /> : null}
          </SlColumn>
        ) : (
          this.renderHeader()
        )}
        <DonutChart mainEntityMetrics={donutChartSeries} widget={widget} retailer={retailer} />
      </div>
    ) : null;
  }
}

export const enhanceDonutChart = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withBus('eventBus'));

const EnhancedDonutChartContainer = enhanceDonutChart(DonutChartContainer);
export default EnhancedDonutChartContainer;
