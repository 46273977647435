import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { Widget } from 'src/types/application/widgetTypes';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import { RouteComponentProps } from 'react-router-dom';
import { PERIODS } from 'src/store/modules/omni/constants';
import { MainEntityMetrics } from 'src/types/application/types';
import { fetchOmniChartsServiceData } from 'src/store/modules/omni/omniChartService/operations';
import { OmniBaseRequestBody, addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';
import { startFetchChartData } from 'src/store/modules/omni/omniChartService/actions';
import { METRICTYPE, DATATYPE } from 'src/utils/entityDefinitions/entityDefinitionTypes';
import ColumnChartContainer from 'src/components/Charts/Column';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { defaultWidgetWrapperStyle } from 'src/components/Layout/LayoutUtil';
import OmniReviewTable from 'src/components/Omni/OmniReviewTable/OmniReviewTable';
import 'src/components/EntityPage/Reviews/ReviewMetrics.scss';
import './omniReview.scss';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

interface OmniReviewColumnProps extends RouteComponentProps {
  widget: Widget;
}

const OmniReviewHighRisk: FC<OmniReviewColumnProps> = ({ widget, location }) => {
  const [selectedHighRiskWeekId, setSelectedHighRiskWeekId] = useState<undefined | number>(undefined);
  const dispatch = useDispatch();
  const chartName = 'columnHighRisk';
  const chartData = useSelector((state: ReduxStore) => state.omniChartsService[chartName]);
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const comparisonTimePeriod = useSelector((state: ReduxStore) => state.comparisonTimePeriod);
  const accountSetting = useSelector((state: ReduxStore) => state.omniAccountSettingService);
  const { startWeek: compareStartWeek, endWeek: compareEndWeek } = comparisonTimePeriod;
  const filters = useSelector((state: ReduxStore) => state.filters);

  let highRiskKeywords = _get(accountSetting, 'data.highRiskReviews.keywords', []);
  // To prevent accountSetting, 'data.highRiskReviews.keywords' is { }
  if (_isEmpty(highRiskKeywords)) {
    highRiskKeywords = [];
  }

  const { startWeek, endWeek } = mainTimePeriod;
  const { pathname } = location;

  const dateUse = !chartData || chartData.isFetching ? [] : chartData.main;
  const starFilter = ['1', '2'];
  useEffect(() => {
    const baseRequestBody: OmniBaseRequestBody = {
      startWeekId: startWeek,
      endWeekId: endWeek,
      groupBy: 'weekId',
      includeBrandIds: [],
      includeCategoryIds: [],
      retailerIds: [],
      productIds: [],
      includeSubCategoryIds: [],
      starsFilter: starFilter,
      reviewKeywords: highRiskKeywords
    };
    const requestBody = addFilterToOmniBaseReqBody(baseRequestBody, filters, pathname);
    dispatch(
      startFetchChartData({
        chartName,
        data: []
      })
    );

    dispatch(
      fetchOmniChartsServiceData(requestBody, PERIODS.MAIN, chartName, 'stars', {
        compareStartWeek,
        compareEndWeek
      })
    );
  }, [startWeek, endWeek, pathname, filters]);

  const handleWeekIdChange = (e: any) => {
    const fieldValue = _get(e, '0.options.fieldValue', undefined);
    if (fieldValue !== selectedHighRiskWeekId) {
      setSelectedHighRiskWeekId(fieldValue);
    }
    return {};
  };

  const mainEntityMetrics: MainEntityMetrics = {
    data: dateUse,
    currencySymbol: '$',
    locale: 'en',
    appName: 'omni',
    aggregationFunction: 'sum',
    dataType: 'INTEGER',
    displayName: 'Data Points',
    metricType: METRICTYPE.VOLUME,
    groupByField: {
      name: 'weekId',
      displayName: 'Week Ending',
      dataType: DATATYPE.DATETIME,
      metricType: METRICTYPE.DATETIME,
      aggregationFunction: 'avg',
      appName: 'beacon',
      indexName: 'reviews'
    }
  };

  return (
    <div style={{ ...defaultWidgetWrapperStyle, marginTop: 40 }}>
      <div className="omni_review">
        <div className="omni_review__title">High Risk Review Trends</div>
        <div className="column-chart">
          <div className="column-chart__container">
            <div style={{ width: '98%' }}>
              {!chartData || chartData.isFetching ? (
                <GenericChartLoading />
              ) : (
                <ColumnChartContainer
                  chartDisplayTimePeriod={mainTimePeriod}
                  mainEntityMetrics={mainEntityMetrics}
                  widget={widget}
                  retailer={retailer}
                  onPointSelect={handleWeekIdChange}
                  selectedPoint={selectedHighRiskWeekId}
                />
              )}
            </div>
          </div>
        </div>
        <OmniReviewTable
          starFilter={starFilter}
          weekFilter={selectedHighRiskWeekId}
          reviewKeywordFilter={highRiskKeywords}
        />
      </div>
    </div>
  );
};

export default withRouter(withBus('eventBus')(OmniReviewHighRisk));
