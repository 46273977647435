import { Parser } from 'expr-eval';

import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

export const BEACON_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  retailSales_convertedUSD: {
    name: 'retailSales_converted.USD',
    displayName: 'Retail Sales (USD)',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  retailSales_convertedUSD_Growth: {
    name: 'retailSales_converted.USD',
    displayName: 'Retail Sales Growth Rate (USD)',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum'
  },
  retailPrice_convertedUSD: {
    name: 'retailPrice_converted.USD',
    displayName: 'Retail Price (USD)',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold > 0 ? (retailSales_convertedUSD/unitsSold) : 0'),
    overrides: {
      product_stacklineSku_Override: {
        aggregationFunctionType: 'computed'
      }
    },
    fillWithLastKnownValue: true
  },
  retailPrice: {
    name: 'retailPrice',
    displayName: 'Retail Price',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold > 0 ? (retailSales/unitsSold) : 0'),
    overrides: {
      product_stacklineSku_Override: {
        aggregationFunctionType: 'computed',
        aggregationFunction: expressionParser.parse('unitsSold > 0 ? (retailSales/unitsSold) : 0')
      },
      product_Override: {
        aggregationFunctionType: 'computed',
        aggregationFunction: expressionParser.parse('unitsSold > 0 ? (retailSales/unitsSold) : 0')
      }
    },
    fillWithLastKnownValue: true
  },
  unitsSoldGrowth: {
    name: 'unitsSold',
    displayName: 'Units Sold Growth Rate',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum'
  },
  retailSales_convertedUSDGrowth: {
    name: 'retailSales_converted.USD',
    displayName: 'Retail Sales (USD) Growth Rate',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum'
  },
  wholesaleSales: {
    name: 'wholesaleSales',
    displayName: 'Wholesale Sales',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  wholesalePrice: {
    name: 'wholesalePrice',
    displayName: 'Wholesale Price',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold > 0 ? (wholesaleSales/unitsSold) : 0'),
    overrides: {
      product_weekId_Override: {
        aggregationFunctionType: 'simple',
        aggregationFunction: 'avg'
      }
    }
  },
  wholesalePrice_convertedUSD: {
    name: 'wholesalePrice_converted.USD',
    displayName: 'Wholesale Price',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'simple',
    aggregationFunction: 'avg'
  },
  brandCogs: {
    name: 'brandCogs',
    displayName: 'Brand COGs',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  brandGrossMargin: {
    name: 'brandGrossMargin',
    displayName: 'Brand Margin',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  brandGrossMarginPercent: {
    name: 'brandGrossMarginPercent',
    displayName: 'Brand Margin %',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('wholesaleSales > 0 ? (brandGrossMargin/wholesaleSales) : 0')
  },
  brandCogsPerUnit: {
    name: 'brandCogsPerUnit',
    displayName: 'Brand COGs per Unit',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold>0?brandCogs/unitsSold:0')
  },
  brandGrossMarginPerUnit: {
    name: 'brandGrossMarginPerUnit',
    displayName: 'Brand Margin per Unit',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold>0?brandGrossMargin/unitsSold:0')
  },
  retailerGrossMargin: {
    name: 'retailerGrossMargin',
    displayName: 'Retailer Margin',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  retailerGrossMargin_convertedUSD: {
    name: 'retailerGrossMargin_converted.USD',
    displayName: 'Retailer Margin (USD)',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  retailerGrossMarginPercent: {
    name: 'retailerGrossMarginPercent',
    displayName: 'Retailer Margin %',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('retailSales > 0 ? (retailerGrossMargin/retailSales) : 0')
  },
  retailerMarginPerUnit: {
    name: 'retailerMarginPerUnit',
    displayName: 'Retailer Margin per Unit',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold > 0 ? (retailerGrossMargin / unitsSold) : 0')
  },
  inventoryRetailValue: {
    name: 'inventoryRetailValue',
    displayName: 'On Hand - Retail',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum',
    timePeriodAggregationFunctionType: 'lastValue',
    timePeriodAggregationFunction: 'sum',
    overrides: {
      retailerId_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'sum'
      },
      stacklineSku_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'sum'
      }
    }
  },
  inventoryWholesaleValue: {
    name: 'inventoryWholesaleValue',
    displayName: 'On Hand - Wholesale',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum',
    timePeriodAggregationFunctionType: 'lastValue',
    timePeriodAggregationFunction: 'sum',
    overrides: {
      retailerId_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'sum'
      },
      stacklineSku_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'sum'
      }
    }
  },
  unitsOnHand: {
    name: 'unitsOnHand',
    displayName: 'Units on Hand',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum',
    timePeriodAggregationFunctionType: 'lastValue',
    timePeriodAggregationFunction: 'sum',
    overrides: {
      retailerId_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'sum'
      },
      stacklineSku_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'sum'
      }
    }
  },
  inStock: {
    name: 'inStock',
    displayName: 'Products In-Stock',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum',
    canBeExported: false
  },
  isActive: {
    name: 'isActive',
    displayName: 'Products Active',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum',
    canBeExported: false
  },
  unitsSold: {
    name: 'unitsSold',
    displayName: 'Units Sold',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum',
    canBeExported: true
  },
  inStockWeighted: {
    name: 'inStockWeighted',
    displayName: 'Weighted In-Stock Rate',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.DECIMAL,
    aggregationFunction: 'sum',
    canBeExported: false
  },
  instockRate: {
    name: 'instockRate',
    displayName: 'In-Stock Rate',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold > 0 ? inStockWeighted / unitsSold : 0'),
    timePeriodAggregationFunctionType: 'lastValue',
    overrides: {
      product_Override: {
        aggregationFunctionType: 'computed',
        aggregationFunction: expressionParser.parse('unitsSold > 0 ? inStockWeighted / unitsSold : inStock')
      }
    }
  },
  inventoryWeeksOnHandGrid: {
    name: 'inventoryWeeksOnHandGrid',
    displayName: 'Weeks on Hand',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'avg',
    timePeriodAggregationFunctionType: 'lastValue',
    timePeriodAggregationFunction: 'avg',
    overrides: {
      retailerId_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'avg'
      },
      stacklineSku_Override: {
        aggregationFunctionType: 'computed',
        aggregationFunction: expressionParser.parse('unitsSold < 1 ? (unitsOnHand > 0 ? 20 : 0) : inventoryWeeksOnHand')
      }
    }
  },
  inventoryWeeksOnHand: {
    name: 'inventoryWeeksOnHand',
    displayName: 'Weeks on Hand',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.DECIMAL,
    aggregationFunctionType: 'lastValue',
    aggregationFunction: 'avg',
    timePeriodAggregationFunctionType: 'lastValue',
    overrides: {
      retailerId_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'avg'
      },
      stacklineSku_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'avg'
      }
    }
  },
  unitsReturned: {
    name: 'unitsReturned',
    displayName: 'Units Returned',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  returnRetailValue: {
    name: 'returnRetailValue',
    displayName: 'Returns - Retail Value',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  returnRate: {
    name: 'returnRate',
    displayName: 'Return Rate %',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      'unitsSold > 0 ? (unitsReturned/unitsSold) : (unitsReturned > 0 ? 1 : 0)'
    )
  },
  mapPrice: {
    name: 'mapPrice',
    displayName: 'MAP Price',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'avg'
  },
  retailPriceMinimum: {
    name: 'retailPriceMinimum',
    displayName: 'Retail Price',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'avg'
  },
  mapPriceViolation: {
    name: 'mapPriceViolation',
    displayName: 'Violations',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  trafficOrganicClicks: {
    name: 'trafficOrganicClicks',
    displayName: 'Organic Traffic',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  totalClicks: {
    name: 'totalClicks',
    displayName: 'Total Traffic',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  trafficAdClicks: {
    name: 'trafficAdClicks',
    displayName: 'Paid Traffic',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum',
    canBeExported: false
  },
  trafficOtherClicks: {
    name: 'trafficOtherClicks',
    displayName: 'Other Traffic',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  spend: {
    name: 'spend',
    isNegativeMetric: true,
    displayName: 'Ad Spend',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  adSales: {
    name: 'sales',
    displayName: 'Ad Sales',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  organicClicks: {
    name: 'organicClicks',
    displayName: 'Organic Traffic',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  }
};
