import React from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { CloseIcon, LoadingGearIcon } from 'src/components/SvgIcons/SvgIcons';

const SubmissionDialogue = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '670px',
    height: '610px',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff'
  }
});

interface SubmissionModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flexStart',
    height: '340px',
    marginTop: '90px',
    marginLeft: '140px',
    marginRight: '140px'
  },
  iconBackdrop: {
    display: 'flex',
    justifyContent: 'center',
    width: '100px',
    height: '100px',
    borderRadius: '125px',
    objectFit: 'contain',
    backgroundColor: 'rgb(241 243 245)'
  },
  mainModalText: {
    fontFamily: 'Roboto',
    fontSize: '28px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#052849'
  },
  secondaryModalText: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#052849'
  },
  closeButton: {
    width: '180px',
    height: '30px',
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: '14px',
    borderRadius: '15.5px',
    border: 'solid 1px #7e8fa8',
    backgroundColor: '#052849',
    marginTop: '40px'
  }
}));

const SubmissionModal = ({ open, onClose }: SubmissionModalProps) => {
  const classes = useStyles();

  return (
    <SubmissionDialogue open={open} onClose={onClose}>
      <div>
        <div className={classes.headerContainer}>
          <CloseIcon
            style={{ width: '24px', marginRight: '28px', marginTop: '28px', cursor: 'pointer' }}
            onClick={onClose}
          />
        </div>
        <div className={classes.bodyContainer}>
          <div className={classes.iconBackdrop}>
            <LoadingGearIcon style={{ width: '47px' }} />
          </div>
          <h2 className={classes.mainModalText}>Submitting Updates</h2>
          <span className={classes.secondaryModalText}>
            We received your submission and will notify you when the processing is complete. You can close this window
            in the meantime.
          </span>
          <button className={classes.closeButton} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </SubmissionDialogue>
  );
};

export default SubmissionModal;
