import { Dialog, DialogProps, Divider, styled } from '@mui/material';
import { Box } from '@mui/system';
import { useStacklineTheme } from '@stackline/ui';
import React, { useMemo } from 'react';
import {
  useNetImpactDynamicText,
  useNewPlanDynamicText,
  useOriginalPlanDynamicText
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/hooks/useDynamicText';
import { PlanInputContainer } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Components/PlanInputContainer';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import ScrollableContainer from 'src/components/BeaconRedesignComponents/ScrollableContainer';
import StyledInput from 'src/components/BeaconRedesignComponents/common/StyledInput';
import { CloseIcon, NewBeaconExportIconWhite } from 'src/components/SvgIcons';
import { BEACON_FIELD_DISPLAY_NAMES } from 'src/utils/entityDefinitions/fields/beaconFieldDisplayNames';
import { useBulkAdjustmentContext } from './hooks/useBulkAdjustmentContext';
import {
  AdjustmentMetaData,
  BulkAdjustmentModalType,
  UploadedAdjustment
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkAdjustmentProvider';
import { TemplateSubmittingStatus } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkTemplateSubmittingModal';
import {
  ComputeAdditionalDetails,
  NetImpactSummaryDetails,
  PaidTrafficNetImpactSummaryDetails
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/serverProxy/types';
import { useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { trackMixpanelEvent } from 'src/utils/mixpanel';
import _get from 'lodash/get';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import { safeJSONParse } from 'src/utils/app';

// TODO: Refactor this file to use GenericBulkAdjustmentModal
export const GenericStyledDialogue = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '757px',
    height: '641px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px'
  },
  textarea: {
    color: '#052849 !important'
  },
  overflowX: 'hidden',
  '&::-webkit-scrollbar:': {
    width: '5px'
  },

  '&:hover': {
    '&::-webkit-scrollbar-thumb': {
      'background-color': '#7e8fa8',
      'border-radius': '15px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff'
    }
  }
});

// Ad Spend constants
const AD_SPEND_INPUT_WIDTH = '90px';
const inputStyles = {
  width: '104px',
  letterSpacing: '0.8px',
  height: '32px'
};

export const AdSpendInputTable = ({ summaryDetails }: { summaryDetails: PaidTrafficNetImpactSummaryDetails }) => {
  const formatMetric = useMetricFormatter();

  if (!summaryDetails) {
    return null;
  }

  return (
    <>
      <PlanInputContainer
        width={AD_SPEND_INPUT_WIDTH}
        label={BEACON_FIELD_DISPLAY_NAMES.AD_SPEND}
        firstInput={
          <StyledInput
            sx={inputStyles}
            readOnly
            value={formatMetric(summaryDetails.individual_current_avg_ad_spend, METRICTYPE.MONEY)}
          />
        }
        secondInput={
          <StyledInput
            sx={inputStyles}
            readOnly
            value={formatMetric(summaryDetails.individual_new_avg_ad_spend, METRICTYPE.MONEY)}
          />
        }
      />
      <PlanInputContainer
        width={AD_SPEND_INPUT_WIDTH}
        label={BEACON_FIELD_DISPLAY_NAMES.COST_PER_CLICK}
        firstInput={
          <StyledInput
            sx={inputStyles}
            readOnly
            value={formatMetric(summaryDetails.individual_current_avg_cpc, METRICTYPE.MONEY)}
          />
        }
        secondInput={
          <StyledInput
            sx={inputStyles}
            readOnly
            value={formatMetric(summaryDetails.individual_new_avg_cpc, METRICTYPE.MONEY)}
          />
        }
      />
      <PlanInputContainer
        width={AD_SPEND_INPUT_WIDTH}
        label={BEACON_FIELD_DISPLAY_NAMES.PAID_TRAFFIC}
        firstInput={
          <StyledInput
            sx={inputStyles}
            readOnly
            value={formatMetric(summaryDetails.individual_current_avg_paid_traffic, METRICTYPE.VOLUME)}
          />
        }
        secondInput={
          <StyledInput
            sx={inputStyles}
            readOnly
            value={formatMetric(summaryDetails.individual_new_avg_paid_traffic, METRICTYPE.VOLUME)}
          />
        }
      />
      <PlanInputContainer
        width={AD_SPEND_INPUT_WIDTH}
        label={BEACON_FIELD_DISPLAY_NAMES.AD_SALES}
        firstInput={
          <StyledInput
            sx={inputStyles}
            readOnly
            value={formatMetric(summaryDetails.individual_current_avg_ad_sales, METRICTYPE.MONEY)}
          />
        }
        secondInput={
          <StyledInput
            sx={inputStyles}
            readOnly
            value={formatMetric(summaryDetails.individual_new_avg_ad_sales, METRICTYPE.MONEY)}
          />
        }
      />
      <PlanInputContainer
        width={AD_SPEND_INPUT_WIDTH}
        label={BEACON_FIELD_DISPLAY_NAMES.RETURN_ON_AD_SPEND}
        firstInput={
          <StyledInput
            sx={inputStyles}
            readOnly
            value={formatMetric(summaryDetails.individual_current_avg_roas, METRICTYPE.MONEY)}
          />
        }
        secondInput={
          <StyledInput
            sx={inputStyles}
            readOnly
            value={formatMetric(summaryDetails.individual_new_avg_roas, METRICTYPE.MONEY)}
          />
        }
      />
    </>
  );
};

export interface ViewBulkAdjustmentState {
  bulkAdjustmentId: string;
  uploadedAdjustment: {
    bulkAdjustmentTitle: string;
    bulkAdjustmentDescription: string;
    planType: PlanTypeOption;
  };
}

interface BulkSubmissionReviewModalProps extends DialogProps {
  open: boolean;
  planType: PlanTypeOption;
  handleSaveAsDraft: (payload: UploadedAdjustment) => Promise<void>;
  uploadedAdjustment: UploadedAdjustment;
  adjustmentMetadata: AdjustmentMetaData;
  options?: {
    disableButtons?: boolean;
  };
  loading?: boolean; // Only used when clicking a published bulk adjustment to view the summary metrics
}

// eslint-disable-next-line react/display-name
const BulkSubmissionReviewModal = React.forwardRef(
  (props: BulkSubmissionReviewModalProps, ref: React.Ref<HTMLDivElement>): JSX.Element => {
    const { openBulkAdjustmentModal, bulkAdjustmentId } = useBulkAdjustmentContext();
    const { planType, handleSaveAsDraft, uploadedAdjustment, adjustmentMetadata } = props;
    const fileUri = _get(adjustmentMetadata, ['compute', 'fileUri']); // Pull net impact file from compute bucket
    const summaryDetails = _get(adjustmentMetadata, ['validation', 'summaryDetails']); // Pull summary details from validation
    const computeDetails = _get(adjustmentMetadata, ['compute', 'additionalDetails']); // Pull compute details from compute

    const parsedSummaryDetails: NetImpactSummaryDetails = useMemo(() => {
      if (!summaryDetails) {
        return null;
      } else {
        const validParsedSummaryMetrics = safeJSONParse({ value: summaryDetails });
        if (!validParsedSummaryMetrics) {
          trackMixpanelEvent({
            eventName: 'error parsing bulk adjustment summary metrics',
            data: {
              details: summaryDetails
            }
          });
        }
        return validParsedSummaryMetrics;
      }
    }, [summaryDetails]);

    const parsedComputeDetails: ComputeAdditionalDetails = useMemo(() => {
      if (!computeDetails) {
        return null;
      } else {
        const validParsedComputeMetrics = safeJSONParse({ value: computeDetails });
        if (!validParsedComputeMetrics) {
          trackMixpanelEvent({
            eventName: 'error parsing bulk adjustment summary metrics',
            data: {
              details: computeDetails
            }
          });
        }
        return validParsedComputeMetrics;
      }
    }, [computeDetails]);

    const originalPlanText = useOriginalPlanDynamicText({ planType, summaryDetails: parsedSummaryDetails });
    const newPlanText = useNewPlanDynamicText({ planType, summaryDetails: parsedSummaryDetails });
    const netImpactText = useNetImpactDynamicText({ planType, computeDetails: parsedComputeDetails });

    const theme = useStacklineTheme();

    const handleNetImpactDownload = () => {
      try {
        const s3Info = JSON.parse(fileUri);

        trackMixpanelEvent({
          eventName: 'download bulk adjustment net impact',
          data: {
            fileName: s3Info.s3Key,
            bulkAdjustmentId,
            planType: uploadedAdjustment.planType,
            title: uploadedAdjustment.bulkAdjustmentTitle,
            description: uploadedAdjustment.bulkAdjustmentDescription
          }
        });

        const s3URL = `https://${s3Info.s3Bucket}.s3.amazonaws.com/${s3Info.s3Key}`;
        const anchor = document.createElement('a');

        anchor.style.display = 'none';
        anchor.href = s3URL;
        anchor.download = s3Info.s3Key;
        anchor.click();
      } catch (error) {
        console.error('BULK ADJUSTMENT REVIEW MODAL :: ', error);
        trackMixpanelEvent({
          eventName: 'error downloading bulk adjustment net impact',
          data: {
            bulkAdjustmentId
          }
        });
      }
    };
    const renderView = () => {
      return (
        <>
          {/* Plan summaries */}
          <Flex flexDirection="column" marginTop="24px" gap="sm" height="auto">
            <Text variant="subtitle2">Plan Change Confirmation</Text>
            {props.loading ? (
              <>
                <SlSkeleton variant="rounded" height={19} width="100%"></SlSkeleton>
                <SlSkeleton variant="rounded" height={19} width="85%"></SlSkeleton>
                <SlSkeleton variant="rounded" height={19} width="100%"></SlSkeleton>
                <SlSkeleton variant="rounded" height={19} width="60%"></SlSkeleton>
              </>
            ) : (
              <>
                <Text variant="body2">{originalPlanText}</Text>
                <Text variant="body2">{newPlanText}</Text>
              </>
            )}
          </Flex>
          {/* Net impact summary */}
          <Flex flexDirection="column" marginTop="24px" gap="sm" height="auto">
            <Text variant="subtitle2">Sales Net Impact</Text>
            {props.loading ? (
              <>
                <SlSkeleton variant="rounded" height={19} width="100%"></SlSkeleton>
                <SlSkeleton variant="rounded" height={19} width="85%"></SlSkeleton>
              </>
            ) : (
              <Text variant="body2">{netImpactText}</Text>
            )}
          </Flex>

          {/* Download button */}
          <Flex flexDirection="column" marginTop="24px">
            {props.loading ? null : (
              <button
                onClick={() => handleNetImpactDownload()}
                style={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  padding: '0px',
                  maxWidth: '325px',
                  background: 'none',
                  border: 'none'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50px',
                    backgroundColor: theme.colors.primary
                  }}
                >
                  <NewBeaconExportIconWhite style={{ width: '12px', height: '12px' }} />
                </Box>
                <Text variant="subtitle2">Download Adjustments and Sales Net Impact</Text>
              </button>
            )}
          </Flex>

          {/* Advertising summary for paid traffic plan only */}
          {planType === PlanTypeOption.PaidTraffic && (
            <Flex flexDirection="column" marginTop="24px" gap="sm" height="auto" marginBottom="32px">
              <Text variant="subtitle2">Advertising Summary</Text>
              <Text variant="body2">
                The table below displays how this plan change will impact key advertising metrics on a weekly basis.
              </Text>
              <Flex flexDirection="column">
                <AdSpendInputTable summaryDetails={parsedSummaryDetails as PaidTrafficNetImpactSummaryDetails} />
              </Flex>
            </Flex>
          )}
        </>
      );
    };

    return (
      <GenericStyledDialogue ref={ref} {...props}>
        {/* Header */}
        <Flex justifyContent="center" alignItems="center" paddingX="48px" paddingY="18px" height="82px">
          {/* TODO the success modal should probably be its own component */}
          <Text variant="h4">Bulk Submission Review</Text>
          <CloseIcon
            onClick={(e) => props.onClose && props.onClose(e, 'backdropClick')}
            style={{ position: 'absolute', left: '700px', cursor: 'pointer', width: '24px', height: '24px' }}
          ></CloseIcon>
        </Flex>
        <Divider />

        {/* Content */}
        <ScrollableContainer scrollbarWidth="5px" flexDirection="column" paddingX="48px" height="100%">
          {renderView()}
        </ScrollableContainer>

        {/* Footer */}
        <Divider />
        <Flex justifyContent="space-between" alignItems="center" paddingX="48px" paddingY="20px">
          <SlButton disabled buttonSx={{ width: '71px' }}>
            Back
          </SlButton>
          <Flex gap="md">
            <SlButton
              disabled={_get(props, ['options', 'disableButtons'], false)}
              onClick={() => handleSaveAsDraft(uploadedAdjustment)}
            >
              Save as draft
            </SlButton>
            <SlButton
              disabled={_get(props, ['options', 'disableButtons'], false)}
              buttonSx={{ width: '71px' }}
              onClick={() =>
                openBulkAdjustmentModal({
                  type: BulkAdjustmentModalType.TemplateSubmitting,
                  state: {
                    status: TemplateSubmittingStatus.Submitting,
                    percentage: 0,
                    saveAsDraft: false
                  }
                })
              }
              variant="contained"
            >
              Publish
            </SlButton>
          </Flex>
        </Flex>
      </GenericStyledDialogue>
    );
  }
);

export default BulkSubmissionReviewModal;
