import React from 'react';
import StyledDialog from 'src/components/BeaconRedesignComponents/common/StyledDialog/StyledDialog';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import TeamPopupCloseIcon from './TeamPopupCloseIcon';
import { Text } from '@stackline/ui';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { useSnackbar } from 'src/utils/Hooks';
import { PROCESSING_REQUEST_MESSAGE } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Team/constants';

type TeamUserOptionPopupProps = Omit<React.ComponentProps<typeof StyledDialog>, 'width' | 'padding'> & {
  onConfirm: () => Promise<void> | void;
  modalTitle: string;
};

/**
 * Generic popup container for assigning/removing admin role, removing users,
 * and transferring ownership.
 *
 * It will display the title, content of the popup, close the popup when cancelling,
 * and display a loading popup when the confirm button is clicked and while waiting
 * for the request to finish.
 */
export default function TeamUserOptionPopup({
  onConfirm,
  children,
  modalTitle,
  open,
  ...rest
}: TeamUserOptionPopupProps) {
  const { showSnackbar } = useSnackbar();
  const closePopup = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (rest.onClose) {
      rest.onClose(event, 'backdropClick');
    }
  };

  const handleConfirm = async () => {
    try {
      closePopup();
      showSnackbar({
        type: 'info',
        message: PROCESSING_REQUEST_MESSAGE
      });
      await onConfirm();
    } catch (error) {
      console.error('Error with popup confirmation', error);
    }
  };

  return (
    <>
      <StyledDialog
        width={500}
        sx={{
          '& .MuiPaper-root': {
            minHeight: '180px'
          }
        }}
        padding="24px"
        open={open}
        {...rest}
      >
        <Flex flexDirection="column" justifyContent="space-between" flex={1}>
          <Flex gap="md" flexDirection="column">
            <Flex justifyContent="space-between" alignItems="center">
              <Text variant="h4">{modalTitle}</Text>
              <TeamPopupCloseIcon onClick={closePopup} />
            </Flex>
            <div>{children}</div>
          </Flex>
          <Flex justifyContent="flex-end" gap="md">
            <SlButton onClick={closePopup}>Cancel</SlButton>
            <SlButton variant="contained" onClick={() => handleConfirm()}>
              Confirm
            </SlButton>
          </Flex>
        </Flex>
      </StyledDialog>
    </>
  );
}
