import React, { useState } from 'react';
import { ProductEntity } from 'sl-api-connector';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import ComplianceSummaryKeyMetricCards from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Compliance/Summary/ComplianceSummaryKeyMetricCards';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import ProductListContainer from 'src/components/BeaconRedesignComponents/MetricList/ProductListContainer';
import { EntityLink } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import ProductCell from 'src/components/BeaconRedesignComponents/ProductCell/ProductCell';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { useMetricFormatter } from 'src/utils/Hooks';
import { safeDivide } from 'src/utils/app';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { getBrandImageUrl } from 'src/utils/image';

const fields = [
  {
    name: 'unauthorized',
    displayInTable: false,
    comparisonFormatter: () => null
  }
];

enum ComplianceDropdownOption {
  Brand = 'brandId',
  Product = 'stacklineSku'
}
const ProductColumnDefinition = {
  headerName: 'Products',
  tableColumnProps: {
    style: {
      width: '490px'
    }
  },
  valueFormatter: (_, row: { product: ProductEntity }) => (
    <div style={{ width: '360px' }}>
      <ProductCell key={row.product.stacklineSku} stacklineSku={row.product.stacklineSku} title={row.product.title} />
    </div>
  )
};

const BrandColumnDefinition = {
  headerName: 'Brands',
  tableColumnProps: {
    style: {
      width: '490px'
    }
  },
  valueFormatter: (_, row: { brand: { id: string; name: string } }) => (
    <div style={{ display: 'flex', alignItems: 'center', width: '360px' }}>
      <img
        src={getBrandImageUrl(row.brand.id)}
        style={{ width: '30px', height: '30px', marginRight: '12px', objectFit: 'cover' }}
        alt={row.brand.name}
      />
      <EntityLink id={row.brand.id} entityType="brand">
        {row.brand.name}
      </EntityLink>
    </div>
  )
};

const complianceSummaryDropdownOptionToColumnDefinition = {
  [ComplianceDropdownOption.Product]: ProductColumnDefinition,
  [ComplianceDropdownOption.Brand]: BrandColumnDefinition
};

const complianceDropdownOptionToDisplayName = {
  [ComplianceDropdownOption.Product]: 'Product',
  [ComplianceDropdownOption.Brand]: 'Brand'
};

const ComplianceSummaryProductsList = () => {
  const [dropdownOption, setDropdownOption] = useState<ComplianceDropdownOption>(ComplianceDropdownOption.Product);
  const formatMetric = useMetricFormatter();
  return (
    <ProductListContainer
      showGrid={dropdownOption === ComplianceDropdownOption.Product}
      entityTableHeaderProps={{
        prefixTitle: 'YTD by',
        title: '',
        groupByFields: [ComplianceDropdownOption.Product, ComplianceDropdownOption.Brand].map((option) => ({
          name: option,
          displayName: complianceDropdownOptionToDisplayName[option]
        })),
        handleGroupByChange: (event) => setDropdownOption(event.target.value as ComplianceDropdownOption)
      }}
      headColumnDefinition={[complianceSummaryDropdownOptionToColumnDefinition[dropdownOption]]}
      additionalColumnDefs={[
        {
          headerName: 'YTD',
          tableColumnProps: {
            style: {
              width: '180px'
            }
          },
          valueFormatter: (_, row) => {
            const yearToDateValue = row.unauthorized?.value;
            return <div>{yearToDateValue}</div>;
          }
        },
        {
          headerName: 'Prior\nYear',
          tableColumnProps: {
            style: {
              width: '180px'
            }
          },
          valueFormatter: (_, row) => {
            const previousYearValue = row.unauthorized?.value - row.unauthorized?.difference;
            return <div>{previousYearValue}</div>;
          }
        },
        {
          headerName: 'Percentage\nChange',
          tableColumnProps: {
            style: {
              width: '120px'
            }
          },
          valueFormatter: (_, row) => {
            const previousYearValue = row.unauthorized?.value - row.unauthorized?.difference;
            const percentChange = safeDivide(row.unauthorized?.value - previousYearValue, previousYearValue, 10);

            return <div>{formatMetric(percentChange, METRICTYPE.PERCENT)}</div>;
          }
        }
      ]}
      indexName="compliance-selleritem"
      fields={fields}
      useTableDataProps={{
        groupByFieldName: dropdownOption,
        filterResponse: () => true
      }}
      sortFieldName="unauthorized"
    />
  );
};

/**
 *
 * @returns A beacon page container with the compliance summary key metric cards and the compliance summary products list
 */
const ComplianceSummaryPage = () => {
  return (
    <BeaconPageContainer sx={{ marginTop: '0px' }}>
      <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
        <ComplianceSummaryKeyMetricCards />
        <ComplianceSummaryProductsList />
      </Flex>
    </BeaconPageContainer>
  );
};

export default ComplianceSummaryPage;
