import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import { BrandIcon } from 'src/components/SvgIcons';
import ReduxStore from 'src/types/store/reduxStore';
import colors from 'src/utils/colors';
import { getRetailerLogoUrl } from 'src/utils/image';
import { generateProductLink } from 'src/utils/searchLinks';
import './Entity.scss';

const styles: { [key: string]: React.CSSProperties } = {
  text: {
    flexDirection: 'column',
    justifyContent: 'center'
  },
  textLink: {
    fontSize: 14,
    whiteSpace: 'pre-wrap',
    lineHeight: '1.5em',
    maxHeight: '3em',
    overflow: 'hidden'
  },
  imageWrapper: {
    alignSelf: 'center',
    minWidth: 45,
    textAlign: 'center',
    marginRight: 10
  },
  brandIcon: {
    stroke: colors.lightGrey,
    strokeWidth: 13,
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  imageWrapperInner: {
    width: 45,
    display: 'table'
  },
  imageWrapperInnerInner: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  image: {
    maxWidth: 35,
    maxHeight: 35
  }
};

const mapStateToProps = ({ app, retailer }: ReduxStore) => ({ app, retailer });

type EntityColumnProps = {
  data?: { [key: string]: any };
  url?: string;
  style?: React.CSSProperties;
  value?: { [key: string]: any };
  getLinkOverride?: (props: {
    type: string;
    id: string | number;
    searchParams: string;
    parsedAdditionalParameters: { [key: string]: any };
  }) => string;
} & ReturnType<typeof mapStateToProps>;

interface EntityColumnState {
  value?: EntityColumnProps['value'];
  imageUri?: string | null;
  didImageLoadError?: boolean;
}

class RetailerColumn extends React.Component<EntityColumnProps, EntityColumnState> {
  public static defaultProps = {
    data: {},
    value: {},
    style: {},
    url: undefined,
    getLinkOverride: undefined
  };

  public static propTypes = {
    data: (props: EntityColumnProps, _propName: string, componentName: string) => {
      if (!props.data && !props.value) {
        return new Error(`One of props 'data' or 'url' was not specified in '${componentName}'.`);
      }
      return null;
    },
    value: (props: EntityColumnProps, _propName: string, componentName: string) => {
      if (!props.data && !props.value) {
        return new Error(`One of props 'url' or 'data' was not specified in '${componentName}'.`);
      }
      return null;
    },
    style: PropTypes.object,
    app: PropTypes.object.isRequired,
    url: PropTypes.string,
    getLinkOverride: PropTypes.func
  };

  public state: EntityColumnState = {};

  public componentWillMount() {
    const { value, data } = this.props;
    const val = value && value.name ? value : data;

    this.setState({
      value: val,
      imageUri: val && val.id ? getRetailerLogoUrl(val.id.toString()) : null
    });
  }

  private handleImageLoadingError = () =>
    this.setState({
      didImageLoadError: true,
      imageUri: 'https://placehold.it/50x50'
    });

  private showImageElement() {
    const { imageUri, value, didImageLoadError } = this.state;
    if (!value || value.id === 'Other' || value.id === 'Total') {
      return null;
    }

    if (didImageLoadError && value.type === 'brand') {
      return <BrandIcon style={styles.brandIcon} />;
    }

    return (
      <img onError={this.handleImageLoadingError} alt={value.type} style={styles.image} src={imageUri || undefined} />
    );
  }

  private stopPropagation = (evt: { stopPropagation: Function }) => evt.stopPropagation();

  private showImage() {
    const { searchParams, additionalParams } = this.props.app.queryParams;
    const { value } = this.state;

    // Basically we have no idea what could be in here at this point, so we check everything to make as sure as possible
    // that we actually have an image to render
    if (
      !value ||
      !value.id ||
      value.type === 'category' ||
      value.type === 'subcategory' ||
      value.id === 'Other' ||
      value.id === 'Total'
    ) {
      return null;
    } else if (
      value.entity &&
      (!value.entity.imageUrl || ['category', 'categoryId', 'subcategory', 'subCategoryId'].includes(value.entity.type))
    ) {
      return null;
    }
    const image = (
      <div style={styles.imageWrapperInner}>
        <div style={styles.imageWrapperInnerInner}>{this.showImageElement()}</div>
      </div>
    );

    const link = `/${value.type}/${value.id}${searchParams}${additionalParams}`;

    return (
      <div style={styles.imageWrapper}>
        {link ? (
          <Link onClick={this.stopPropagation} to={link}>
            {image}
          </Link>
        ) : (
          image
        )}
      </div>
    );
  }

  private getValue = () => {
    if (!this.state.value) {
      return null;
    } else if (this.state.value.type) {
      return this.state.value;
    } else if (this.state.value.entity) {
      return this.state.value.entity;
    } else {
      return this.state.value;
    }
  };

  private showTextLink() {
    const { retailer } = this.props;
    const value = this.getValue();
    const retailerObj = retailer.availableRetailers.find((r) => r.id === value.name);
    const valueAsRetailerName = retailerObj ? retailerObj.displayName : null;

    return (
      <div style={styles.textLink} className={value.isBold ? 'bold' : undefined} title={value.name}>
        {valueAsRetailerName || value.name}
      </div>
    );
  }

  private showBrandLink() {
    const { searchParams, additionalParams } = this.props.app.queryParams;
    const { value } = this.state;

    if (!value) {
      return null;
    }

    if (!value.type) {
      return (
        <Truncate style={{ fontSize: 14 }} lines={1} title={value.brandName}>
          {value.brandName}
        </Truncate>
      );
    }

    return (
      <Link
        style={{ display: 'block' }}
        onClick={this.stopPropagation}
        to={`/brand/${value.brandId}${searchParams}${additionalParams}`}
      >
        <div style={{ fontSize: 14 }} title={value.brandName}>
          <Truncate lines={1} title={value.brandName}>
            {value.brandName}
          </Truncate>
        </div>
      </Link>
    );
  }

  public render() {
    return (
      <div style={{ display: 'flex', ...this.props.style }}>
        {this.showImage()}
        <div style={styles.text}>
          {this.state.value && this.state.value.brandName ? this.showBrandLink() : null}
          {this.showTextLink()}
          {this.props.app.name === 'advertising' && this.state.value && this.state.value.type === 'product' && (
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={generateProductLink(this.props.retailer.id, this.state.value.entity.retailerSku)}
              >
                {this.state.value.entity.retailerSku}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

// TODO: Fix this type error.  Idk why it doesn't like the `propTypes` type.
const EnhancedEntityColumn = connect(mapStateToProps)(RetailerColumn);

export default EnhancedEntityColumn;
