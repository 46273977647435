import _get from 'lodash/get';
import _merge from 'lodash/merge';
import { getChartDisplayTimePeriod } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { getBaseLineChartSeries, chartColorsLineChart, computeLastWeek } from 'src/components/EntityPage/Renderer/util';
import { buildMetricValue } from 'src/utils/metrics';
import { getNextWeek, getWeekLastDate, moveForwardOneWeek } from 'src/utils/dateformatting';
import _cloneDeep from 'lodash/cloneDeep';
import _differenceBy from 'lodash/differenceBy';
import moment from 'moment';
import numeral from 'numeral';
import { getTooltipDiv } from 'src/components/EntityPage/Renderer/tooltipDiv';
import colors from 'src/utils/colors';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { getWeekId } from 'src/utils/dateUtils';
import ReduxStore from 'src/types/store/reduxStore';
import { Widget } from 'src/types/application/widgetTypes';
import { TrendChartInfo, ChartDisplayTimePeriod, ChartCompState } from './types';

// TODO: some value shouldn't give the fixed value, will fixed this issue when we have more trend chart use in OMNI
const computePercentChangeChecked = (y0, y1) => {
  if (y0 <= 0) {
    return y1 > 0 ? 100 : 0;
  }

  return (y1 - y0) / y0;
};

export const createInitialChartCompState = (
  chartDisplayTimePeriod: ChartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod: ChartDisplayTimePeriod,
  data: any,
  groupByFieldName: string,
  view: any
): ChartCompState => {
  const { metricFields } = view;
  const [metricField] = metricFields;
  const metricsDataByWeekId = {
    data: data.main,
    aggregationFunction: metricField.aggregationFunction,
    metricType: metricField.metricType,
    dataType: metricField.dataType
  };
  // temp use fixed value
  const mainCurrencySymbol = '$';
  const comparisonCurrencySymbol = '$';
  const mainLocale = 'en';
  const comparisonLocale = 'en';
  const currencySymbols = [mainCurrencySymbol, comparisonCurrencySymbol];
  const locales = [mainLocale, comparisonLocale];
  const isSameMetricComparison = true;
  return {
    chartSeries: getBaseLineChartSeries(chartDisplayTimePeriod, data),
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    groupByFieldName,
    metricsDataByWeekId,
    mainLocale,
    mainCurrencySymbol,
    currencySymbols,
    isSameMetricComparison,
    locales,
    legendDivs: [],
    // The following are set by `populateFirstChartSeries`
    loadLastWeek: false,
    mainLegendMetricValue: 0,
    mainLegendValue: 0,
    mainMetricSum: 0,
    comparisonLegendValue: 0,
    comparisonCurrencySymbol,
    comparisonMetricSum: 0,
    comparisonMetricDataPointCount: 0,
    comparisonMetricLastValue: 0,
    comparisonMetricTrueSum: 0,
    comparisonMetricTrueCount: 0
  };
};

/*
 In the Omni, we need to fill some data when the week data missing.
 Especially when we use prior-period we need to fill prior-period and main-period missing data.
*/
export const fillInMissingTimePeriods = (
  chartDisplayTimePeriod: ChartDisplayTimePeriod,
  comparisonTimePeriod: ReduxStore['comparisonTimePeriod'],
  omniData: any
) => {
  const { timePeriod } = chartDisplayTimePeriod;
  const { id: idForMain } = chartDisplayTimePeriod;
  const { id: idForCompare } = comparisonTimePeriod;
  const startWeek =
    idForCompare === 'prior-period' && idForMain !== '1w'
      ? computeLastWeek(comparisonTimePeriod.startWeek)
      : comparisonTimePeriod.startWeek;
  const { endWeek } = timePeriod;
  const xAxisKey = 'name';
  let currWeek = startWeek;
  const arr = [];
  while (currWeek <= endWeek) {
    arr.push({
      name: String(currWeek),
      weekId: currWeek,
      weekEnding: getWeekLastDate(currWeek),
      weekEndingNextYear: getWeekLastDate(currWeek * 1 + 100),
      isFilled: true,
      value: 0
    });
    currWeek = getNextWeek(currWeek);
  }
  const diffForMain = _differenceBy(arr, omniData.main, xAxisKey);
  omniData.main.push(...diffForMain);

  omniData.main.sort((a, b) => {
    if (a[xAxisKey] < b[xAxisKey]) {
      return -1;
    }
    if (a[xAxisKey] > b[xAxisKey]) {
      return 1;
    }
    return 0;
  });
  return omniData;
};

export const getTrendChartInfo = (
  widget: Widget,
  mainTimePeriod: ReduxStore['mainTimePeriod'],
  comparisonTimePeriod: ReduxStore['comparisonTimePeriod'],
  omniData: {
    compare: any[];
    main: any[];
  }
): TrendChartInfo => {
  const {
    view: { chartPropsOverride }
  } = widget;
  const groupByFieldName = 'weekId';
  const chartDisplayTimePeriod = getChartDisplayTimePeriod(mainTimePeriod);
  const chartComparisonDisplayTimePeriod = getChartDisplayTimePeriod(comparisonTimePeriod);
  const clonedOmniData = _cloneDeep(omniData);
  return {
    chartPropsOverride,
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    omniData: clonedOmniData,
    comparisonTimePeriod,
    groupByFieldName,
    widget
  };
};

const buildLegendDiv = (chartCompState: ChartCompState, chartComparisonDisplayTimePeriod: ChartDisplayTimePeriod) => {
  const {
    metricsDataByWeekId,
    mainLegendMetricValue,
    comparisonCurrencySymbol,
    mainLegendValue,
    comparisonLegendValue,
    locales
  } = chartCompState;
  chartCompState.comparisonLegendMetricValue = buildMetricValue(
    chartCompState.comparisonLegendValue,
    metricsDataByWeekId.metricType,
    comparisonCurrencySymbol,
    true,
    metricsDataByWeekId.dataType,
    locales[0]
  );
  const { prefix: prefixForCurrent, value: valueForCurrent, suffix: suffixForCurrent } = mainLegendMetricValue;
  const {
    prefix: prefixForCompare,
    value: valueForCompare,
    suffix: suffixForCompare
  } = chartCompState.comparisonLegendMetricValue;

  // calculate the change
  let metricsChangeIcon = { icon: '+', className: 'increase' };
  let metricsChangePercent =
    comparisonLegendValue !== 0
      ? (mainLegendValue - comparisonLegendValue) / Math.abs(comparisonLegendValue)
      : mainLegendValue === 0
      ? 0
      : 1000;
  const metricsChange = numeral(mainLegendValue - comparisonLegendValue).format('+1,000.00');
  if (metricsChangePercent && metricsChangePercent < 0) {
    metricsChangePercent *= -1;
    metricsChangeIcon = { icon: '\u2212', className: 'decrease' };
  }

  const metricsChangePercentMetricValue = buildMetricValue(
    metricsChangePercent,
    METRICTYPE.PERCENT,
    '$',
    true,
    null,
    'en'
  );

  chartCompState.chartSeries[0].legendDiv = `<div>${prefixForCompare || ''}${valueForCompare}${
    suffixForCompare || ''
  }</div>\n    <div class="legend__primary-date" style="margin-top:5px;">${
    chartComparisonDisplayTimePeriod.displayName
  }</div>`;
  chartCompState.chartSeries[1].legendDiv = `<div>${prefixForCurrent || ''}${valueForCurrent}${
    suffixForCurrent || ''
  }<span class="legend__percent-change">(<span class="legend__percent-change-metric legend__percent-change-metric--${
    metricsChangeIcon.className
  }"><span>${
    metricsChangeIcon.icon
  }</span><span class="sl-metric"><span class="sl-metric__value" title="${metricsChange}">${
    metricsChangePercentMetricValue.value
  }</span><span class="sl-metric__suffix">${
    metricsChangePercentMetricValue.suffix
  }</span></span></span>)</span></div><div class="legend__primary-date" style="margin-top:5px;">${
    chartCompState.chartDisplayTimePeriod.displayName
  }</div>`;
};

const populateComparisonSeriesData = (compState) => {
  const {
    metricsDataByWeekId,
    comparisonStartWeek,
    chartComparisonDisplayTimePeriod,
    chartDisplayTimePeriod,
    isSameMetricComparison
  } = compState;
  const isYearOverYearComparison =
    isSameMetricComparison &&
    chartDisplayTimePeriod.endWeek - chartComparisonDisplayTimePeriod.endWeek === 100 &&
    !(chartDisplayTimePeriod.id === '52w' && chartComparisonDisplayTimePeriod.id === 'prior-period');
  // Contains 53 when starts in a leap year and the week ends in 53 or the end week is ina different year
  const mainStartYear = Math.floor(chartDisplayTimePeriod.startWeek / 100);
  const mainEndYear = Math.floor(chartDisplayTimePeriod.endWeek / 100);
  const mainTimePeriodContains53 = moment([mainStartYear]).isLeapYear() && mainStartYear !== mainEndYear;

  const comparisonSeries = compState.chartSeries[0];
  metricsDataByWeekId.data.forEach((dataPoint) => {
    const datumIsInRange =
      comparisonStartWeek <= dataPoint.weekId && chartComparisonDisplayTimePeriod.endWeek >= dataPoint.weekId;
    if (datumIsInRange) {
      // We move forward week to handle the leap year case where the main time period has a week 53 but the
      // comparison period does not (and the main time period ccontinues into the next year)
      // So, we would compare: Last 4 weeks: 202051, 202052, 202053, 202101 Prior Year: 201950, 201951, 201952, 202001
      const shouldMoveForwardOneWeek = dataPoint.weekId < +`${mainStartYear - 1}53` && mainTimePeriodContains53;
      if (shouldMoveForwardOneWeek) {
        dataPoint = moveForwardOneWeek(_cloneDeep(dataPoint));
      }
      let weekEnding = isYearOverYearComparison ? dataPoint.weekEndingNextYear : dataPoint.weekEnding;
      weekEnding = typeof weekEnding === 'string' ? new Date(Date.parse(weekEnding)) : weekEnding;
      if (chartDisplayTimePeriod.id === '1w' || chartDisplayTimePeriod.startWeek === chartDisplayTimePeriod.endWeek) {
        comparisonSeries.zones = [{ color: chartColorsLineChart[2] }];
        if (chartComparisonDisplayTimePeriod.endWeek === dataPoint.weekId) {
          comparisonSeries.data.push({
            x: weekEnding.getTime(),
            y: dataPoint.value,
            marker: { enabled: true, radius: 3, fillColor: chartColorsLineChart[1] }
          });
        } else {
          comparisonSeries.data.push({ x: weekEnding.getTime(), y: dataPoint.value });
        }
      } else {
        comparisonSeries.data.push([weekEnding.getTime(), dataPoint.value]);
      }

      if (
        chartComparisonDisplayTimePeriod.timePeriod.startWeek <= dataPoint.weekId &&
        chartComparisonDisplayTimePeriod.timePeriod.endWeek >= dataPoint.weekId
      ) {
        compState.comparisonMetricSum += dataPoint.value;
        if (dataPoint.value !== 0) {
          compState.comparisonMetricDataPointCount += 1;
        }
        compState.comparisonMetricTrueSum += dataPoint.value * dataPoint.count || 0;
        compState.comparisonMetricTrueCount += dataPoint.count || 0;
        compState.comparisonMetricLastValue = dataPoint.value;
      }
    } else if (
      chartDisplayTimePeriod.showExtendedMonths &&
      chartComparisonDisplayTimePeriod.firstWeekOfYear <= dataPoint.weekId &&
      chartComparisonDisplayTimePeriod.lastWeekOfYear >= dataPoint.weekId
    ) {
      const rawWeekEnding = isYearOverYearComparison ? dataPoint.weekEndingNextYear : dataPoint.weekEnding;
      const weekEnding = typeof rawWeekEnding === 'string' ? new Date(Date.parse(rawWeekEnding)) : rawWeekEnding;

      if (chartDisplayTimePeriod.id === '1w') {
        if (
          chartComparisonDisplayTimePeriod.id === 'prior-period' &&
          chartComparisonDisplayTimePeriod.endWeek < dataPoint.weekId
        ) {
          return;
        }

        comparisonSeries.data.push({ x: weekEnding.getTime(), y: dataPoint.value });
      } else {
        comparisonSeries.data.push([weekEnding.getTime(), dataPoint.value]);
      }
    }
  });
};

const computeComparisonMetrics = (compState) => {
  compState.comparisonMetricSum = 0.0;
  compState.comparisonMetricDataPointCount = 0.0;
  compState.comparisonMetricLastValue = 0.0;
  compState.comparisonMetricTrueSum = 0.0;
  compState.comparisonMetricTrueCount = 0.0;
  compState.comparisonLegendValue = 0.0;
  compState.comparisonStartWeek = compState.loadLastWeek
    ? computeLastWeek(compState.chartComparisonDisplayTimePeriod.startWeek)
    : compState.chartComparisonDisplayTimePeriod.startWeek;
};

const getComparisonLegendValue = (chartCompState) => {
  const {
    metricsDataByWeekId,
    comparisonMetricSum,
    comparisonMetricTrueSum,
    comparisonMetricTrueCount,
    comparisonMetricDataPointCount,
    comparisonMetricLastValue
  } = chartCompState;
  let comparisonLegendValue =
    metricsDataByWeekId.aggregationFunction === 'avg' || metricsDataByWeekId.timePeriodAggregationFunction === 'avg'
      ? comparisonMetricSum / (comparisonMetricDataPointCount > 0 ? comparisonMetricDataPointCount : 1)
      : comparisonMetricSum;

  if (metricsDataByWeekId.timePeriodAggregationFunctionType === 'lastValue') {
    comparisonLegendValue = comparisonMetricLastValue;
  } else if (metricsDataByWeekId.timePeriodAggregationFunctionType === 'trueAvg') {
    comparisonLegendValue = comparisonMetricTrueSum / (comparisonMetricTrueCount > 0 ? comparisonMetricTrueCount : 1);
  }

  return comparisonLegendValue;
};

const populateFirstChartSeries = (compState) => {
  const {
    chartSeries,
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    metricsDataByWeekId,
    mainCurrencySymbol,
    mainLocale
  } = compState;
  compState.mainMetricSum = 0.0;
  let mainMetricDataPointCount = 0.0;
  let mainMetricTrueCount = 0.0;
  let mainMetricLastValue = 0.0;
  let mainMetricTrueSum = 0.0;
  compState.loadLastWeek = chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id !== '1w';
  const mainStartWeek = compState.loadLastWeek
    ? computeLastWeek(chartDisplayTimePeriod.startWeek)
    : chartDisplayTimePeriod.startWeek;
  metricsDataByWeekId.data
    .filter((dataPoint) => mainStartWeek <= dataPoint.weekId && chartDisplayTimePeriod.endWeek >= dataPoint.weekId)
    .forEach((dataPoint) => {
      let { weekEnding } = dataPoint;
      const parsedData = Date.parse(weekEnding);
      // make sure the date is valid then add to the chartSeries
      if (!Number.isNaN(parsedData)) {
        weekEnding = typeof weekEnding === 'string' ? new Date(parsedData) : weekEnding;
        if (chartDisplayTimePeriod.id === '1w' || chartDisplayTimePeriod.startWeek === chartDisplayTimePeriod.endWeek) {
          chartSeries[1].zones = [{ color: chartColorsLineChart[2] }];
          if (chartDisplayTimePeriod.endWeek === dataPoint.weekId) {
            chartSeries[1].data.push({
              x: weekEnding.getTime(),
              y: dataPoint.value,
              marker: { enabled: true, radius: 3, fillColor: chartColorsLineChart[0] }
            });
          } else {
            chartSeries[1].data.push({ x: weekEnding.getTime(), y: dataPoint.value });
          }
        } else {
          chartSeries[1].data.push([weekEnding.getTime(), dataPoint.value]);
        }

        if (
          chartDisplayTimePeriod.timePeriod.startWeek <= dataPoint.weekId &&
          chartDisplayTimePeriod.timePeriod.endWeek >= dataPoint.weekId &&
          !dataPoint.isFilled
        ) {
          compState.mainMetricSum += dataPoint.value;
          mainMetricTrueSum += dataPoint.value * dataPoint.count || 0;
          mainMetricDataPointCount += 1;
          mainMetricTrueCount += dataPoint || 0;
          mainMetricLastValue = dataPoint.value;
        }
      }
    });
  compState.mainLegendValue =
    metricsDataByWeekId.aggregationFunction === 'avg' || metricsDataByWeekId.timePeriodAggregationFunction === 'avg'
      ? compState.mainMetricSum / (mainMetricDataPointCount > 0 ? mainMetricDataPointCount : 1)
      : compState.mainMetricSum;

  if (metricsDataByWeekId.timePeriodAggregationFunctionType === 'lastValue') {
    compState.mainLegendValue = mainMetricLastValue;
  } else if (metricsDataByWeekId.timePeriodAggregationFunctionType === 'trueAvg') {
    compState.mainLegendValue = mainMetricTrueSum / (mainMetricTrueCount > 0 ? mainMetricTrueCount : 1);
  }

  compState.mainLegendMetricValue = buildMetricValue(
    compState.mainLegendValue,
    metricsDataByWeekId.metricType,
    mainCurrencySymbol,
    true,
    metricsDataByWeekId.dataType,
    mainLocale
  );

  if (chartDisplayTimePeriod.showExtendedMonths) {
    for (let i = chartDisplayTimePeriod.endWeek + 1; i <= chartDisplayTimePeriod.lastWeekOfYear; i += 1) {
      const weekEnding = getWeekLastDate(i);
      chartSeries[1].data.push([weekEnding.getTime(), null]);
    }
  }
};

export const getWeeklyTrendChartParameters = (
  chartPropsOverride: any,
  data: any,
  chartDisplayTimePeriod: ChartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod: ChartDisplayTimePeriod,
  comparisonTimePeriod: ReduxStore['comparisonTimePeriod'],
  groupByFieldName: string,
  chartViewConfig: any,
  widget: Widget
) => {
  if (!data || !data.main) {
    return null;
  }
  const filledData = fillInMissingTimePeriods(chartDisplayTimePeriod, comparisonTimePeriod, data);
  const chartCompState = createInitialChartCompState(
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    filledData,
    groupByFieldName,
    chartViewConfig
  );
  const { metricFields } = chartViewConfig;
  const [metricField] = metricFields;
  const showOnlyMainSeries = _get(widget, ['view', 'showOnlyMainSeries'], false);
  chartCompState.chartSeries.unshift({
    name: chartComparisonDisplayTimePeriod.displayName,
    metricType: metricField.metricType,
    data: [],
    color: chartColorsLineChart[1],
    marker: {
      lineColor: chartColorsLineChart[1],
      fillColor: chartColorsLineChart[1],
      lineWidth: 3,
      symbol: 'circle'
    }
  });
  populateFirstChartSeries(chartCompState);
  computeComparisonMetrics(chartCompState);
  populateComparisonSeriesData(chartCompState);
  chartCompState.comparisonLegendValue = getComparisonLegendValue(chartCompState);

  buildLegendDiv(chartCompState, chartComparisonDisplayTimePeriod);

  const { chartSeries } = chartCompState;
  const xAxis = [];
  const longestSeriesData =
    chartSeries[1].data.length > chartSeries[0].data.length ? chartSeries[1].data : chartSeries[0].data;
  if (longestSeriesData.length > 0) {
    let xAxisMin = longestSeriesData[0][0];
    let xAxisMax = longestSeriesData[longestSeriesData.length - 1][0];
    if (chartDisplayTimePeriod.id === 'ytd' || chartDisplayTimePeriod.id === '1w') {
      // Always show the full year so we can show the comparison period "tail"
      xAxisMin = getWeekLastDate(chartDisplayTimePeriod.firstWeekOfYear).getTime();
      xAxisMax = getWeekLastDate(chartDisplayTimePeriod.lastWeekOfYear).getTime();
    }

    if (chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id === '1w') {
      xAxisMin = getWeekLastDate(chartComparisonDisplayTimePeriod.startWeek).getTime();
      xAxisMax = getWeekLastDate(chartDisplayTimePeriod.endWeek).getTime();
    }

    let mainSeriesClone = chartSeries[1].data;

    if (chartDisplayTimePeriod.id === 'ytd' && showOnlyMainSeries) {
      const endTimeString = chartDisplayTimePeriod.timePeriod.endWeekEndDate.getTime();
      mainSeriesClone = chartSeries[1].data.filter((item) => item[0] < endTimeString);
      [xAxisMax] = mainSeriesClone[mainSeriesClone.length - 1];
    }

    if (
      chartComparisonDisplayTimePeriod &&
      chartComparisonDisplayTimePeriod.id === 'prior-period' &&
      chartDisplayTimePeriod.id !== '1w' &&
      chartDisplayTimePeriod.startWeek !== chartDisplayTimePeriod.endWeek &&
      chartSeries[1].data.length &&
      chartSeries[0].data.length &&
      (!showOnlyMainSeries || chartDisplayTimePeriod.id !== 'ytd')
    ) {
      [[xAxisMin]] = chartSeries[0].data;
      [xAxisMax] = mainSeriesClone[mainSeriesClone.length - 1];
    }

    if (chartComparisonDisplayTimePeriod.id === 'prior-period' && showOnlyMainSeries) {
      if (Array.isArray(chartSeries[1].data[0])) {
        [[xAxisMin]] = chartSeries[1].data;
      } else {
        xAxisMin = chartSeries[1].data[0].x;
      }
    }
    xAxis.push({
      className:
        chartDisplayTimePeriod.id === 'ytd' || chartDisplayTimePeriod.id === '1w'
          ? 'highcharts-axis-labels highcharts-xaxis-labels-weekly-trend'
          : '',
      min: xAxisMin,
      max: xAxisMax,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        align: 'left'
      },
      showLastLabel: chartDisplayTimePeriod.id !== 'ytd' && chartDisplayTimePeriod.endWeek % 100 !== 53
    });
  } else {
    xAxis.push({});
  }

  let chartProps = {
    chart: { type: 'areaspline' },
    title: { text: metricField.displayName },
    subtitle: { text: 'All Categories' },
    plotOptions: {
      fillEnabled: true,
      series: {
        states: {
          hover: {
            enabled: true
          }
        }
      }
    },
    legend: {
      labelFormatter() {
        return this.userOptions.legendDiv;
      }
    },
    tooltip: {
      formatter() {
        let tooltipStyle = '';
        let percentChangeDiv = '';

        if (chartCompState.isSameMetricComparison && this.points.length > 1) {
          let metricsChange = { icon: '+', color: colors.green };
          let percentChange = computePercentChangeChecked(this.points[0].y, this.points[1].y);

          // if the change is negative, then display in red with minus icon
          if (percentChange < 0) {
            metricsChange = { icon: '\u2212', color: colors.red };
            percentChange *= -1;
          }

          percentChange = buildMetricValue(
            percentChange,
            METRICTYPE.PERCENT,
            '',
            true,
            metricField.dataType,
            chartCompState.mainLocale
          ).value;

          const tooltipWidth =
            100 +
            (this.points[0].x.name
              ? Math.min(this.points[0].x.name.length, 10) * 6
              : percentChange.toString().length * 7);
          percentChangeDiv = `<div style="display:inline-block; float:right; color:${metricsChange.color};">
              ${metricsChange.icon}${percentChange}%
            </div>`;
          tooltipStyle += `width: ${tooltipWidth}px;`;
        }

        const mainWeekId = getWeekId(this.points[0].x);
        const { lastWeekOfYear: mainLastWeekOfYear } = chartDisplayTimePeriod;
        let weekId = mainWeekId;
        if (mainWeekId > mainLastWeekOfYear && mainWeekId % 100 === 1) {
          // do 53 as the week
          weekId = +`${mainLastWeekOfYear}`;
        }

        let yAxisMetricsDiv = '';
        for (let i = this.points.length - 1; i >= 0; i -= 1) {
          const pointValue = this.points[i];
          if (pointValue.point.index === 0 && chartCompState.loadLastWeek) {
            percentChangeDiv = '';
          } else {
            const { color } = chartSeries[pointValue.series.index];
            const metricValue = buildMetricValue(
              pointValue.y,
              metricField.metricType,
              '',
              true,
              metricField.dataType,
              'en'
            );
            yAxisMetricsDiv += `
            <div style="margin-top:5px;color:${color};">
                ${metricValue.prefix || ''}${metricValue.value.toString().trim()}
                <span class='sl-metric__suffix'>${metricValue.suffix || ''}</span>
                <span class="sl-metric__week-ending">${moment(
                  getWeekLastDate(
                    i === 0
                      ? color === colors.blue || chartComparisonDisplayTimePeriod.id === 'prior-period'
                        ? weekId
                        : weekId - 100
                      : weekId
                  )
                ).format('M/D')}</span>

            </div>`;
          }
        }

        return yAxisMetricsDiv === ''
          ? false
          : getTooltipDiv(tooltipStyle, percentChangeDiv, yAxisMetricsDiv, this.x, undefined, weekId);
      },
      positioner(labelWidth, labelHeight, point) {
        return { x: point.plotX, y: point.plotY - 20 };
      }
    },
    xAxis,
    yAxis: [
      {
        labels: {
          formatter() {
            try {
              const val = buildMetricValue(this.value, metricField.metricType, '', true, metricField.dataType, 'en');
              return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
            } catch (e) {
              console.warn(e);
            }
            return '';
          }
        }
      },
      {
        opposite: true,
        labels: {
          formatter() {
            const val = buildMetricValue(
              this.value,
              metricField.metricType,
              '',
              true,
              metricField.dataType,
              chartCompState.locales[1]
            );
            return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
          }
        }
      }
    ]
  };
  chartProps = _merge(chartProps, chartPropsOverride);
  const { mainLegendMetricValue, mainLegendValue, comparisonLegendValue } = chartCompState;
  chartProps.legend.mainLegendDisplayValue = `${mainLegendMetricValue.prefix || ''}${mainLegendMetricValue.value}${
    mainLegendMetricValue.suffix || ''
  }`;

  chartProps.legend.metricsChangePercent =
    comparisonLegendValue === 0 ? 1000 : (mainLegendValue - comparisonLegendValue) / comparisonLegendValue;
  return { chartProps, chartSeries };
};
