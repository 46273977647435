import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { AD_PLATFORM_SETTING_TYPE } from 'sl-ad-campaign-manager-data-model';
import { AdManagerCampaignTypeAdPlatformSettings } from 'sl-api-connector/types';
import { store } from 'src/main';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { SectionWrapper, OperationButtons } from '../Widgets/AdCampaignBuilderCommonWidgets';
import { AdCampaignBuilderRadio } from '../Widgets/Checkbox';
import { adPlatformSettingsOperations } from 'src/store/modules/adManager/adPlatformSettings';
import { useAppSelector, useOnce } from 'src/utils/Hooks';
import { getPlatformType, getAdPlatformSettingsOfType } from 'src/store/modules/adManager/adCampaignBuilder/selectors';
import ReduxStore from 'src/types/store/reduxStore';
import { CAMPAIGN_TYPES } from 'src/store/modules/adManager/adCampaignBuilder/types';
// import { isAllowed_WalmartSV } from 'src/utils/app';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useQuery } from 'react-query';
import { AdWalmartService } from 'src/components/AdManager/walmartUtils';
import InfoTooltip from 'src/components/AdCampaignBuilder/Widgets/InfoTooltip';

const disabledStyles: React.CSSProperties = {
  opacity: 0.6,
  filter: 'grayscale(100%)',
  pointerEvents: 'none'
};

const SponsoredBrandsTooltip = ({ disabled }: { disabled: boolean }) => {
  if (!disabled) {
    return null;
  }
  return (
    <FormControl style={{ marginLeft: 5, verticalAlign: 'sub' }} variant="standard">
      <InfoTooltip style={{ marginTop: -8 }} interactive>
        <span className="info">
          Sponsored Brands creation is temporarily disabled while we make some updates following Amazon’s API changes.
        </span>
      </InfoTooltip>
    </FormControl>
  );
};

const SponsoredBrandsVideoTooltip = ({ disabled }: { disabled: boolean }) => {
  if (!disabled) {
    return null;
  }
  return (
    <FormControl style={{ marginLeft: 5, verticalAlign: 'sub' }} variant="standard">
      <InfoTooltip style={{ marginTop: -8 }} interactive>
        <span className="info">
          Sponsored Brands Video creation is temporarily disabled while we make some updates following Amazon’s API
          changes.
        </span>
      </InfoTooltip>
    </FormControl>
  );
};

const SponsoredVideosTooltip = ({ disabled, supportEmail }: { disabled: boolean; supportEmail: string }) => {
  if (!disabled) {
    return null;
  }
  return (
    <FormControl style={{ marginLeft: 5, verticalAlign: 'sub' }} variant="standard">
      <InfoTooltip style={{ marginTop: -8 }} interactive>
        <span className="info">
          To enable Sponsored Videos, please reach out to{' '}
          <a href={`mailto:${supportEmail}`} style={{ color: '#fff' }}>
            {supportEmail}
          </a>
        </span>
      </InfoTooltip>
    </FormControl>
  );
};

const CampaignTypeItem: React.FC<{
  campaignType: NonNullable<ReduxStore['adCampaignBuilder']['campaignType']>;
  selectedCampaignType: ReduxStore['adCampaignBuilder']['campaignType'];
  selectedCreative: ReduxStore['adCampaignBuilder']['creative'];
  creativeType: string | undefined;
  isWalmartSVAllowed: boolean;
  // eslint-disable-next-line react/prop-types
}> = ({ campaignType, selectedCampaignType, selectedCreative, creativeType, isWalmartSVAllowed }) => {
  if (!campaignType) {
    return <div key="empty" className="campaign-type-opt"></div>;
  }

  const selectedCampaignTypeId = selectedCampaignType ? selectedCampaignType.id : null;
  const selectedCreativeType = selectedCreative ? selectedCreative.creativeType : null;
  const { id, name, image: Image, extendedAttributes } = campaignType;

  const campaignCreativeType = (id || '') + (creativeType || '');
  const selectedCampaignCreativeType = (selectedCampaignTypeId || '') + (selectedCreativeType || '');
  const isWSV = campaignType && campaignType.id === CAMPAIGN_TYPES.SponsoredWalmartVideo;
  const disabledAmazonSB = campaignType && campaignType.id === CAMPAIGN_TYPES.SponsoredBrands;
  const disabledAmazonSBV = campaignType && campaignType.id === CAMPAIGN_TYPES.SponsoredBrandsVideo;
  const suportEmail = 'support@stackline.com';

  const isDisabled = (isWSV && !isWalmartSVAllowed) || disabledAmazonSB || disabledAmazonSBV;
  const handleSelect = () => {
    if (isDisabled) {
      return;
    }
    store.dispatch(adCampaignBuilderActions.setCampaignType({ ...campaignType }));

    store.dispatch(adCampaignBuilderActions.setCreative({ creativeType }));
  };

  return (
    <div key={id} className="campaign-type-opt">
      <div className="type-head">
        <AdCampaignBuilderRadio
          style={{ padding: '8px 13px 10px 0' }}
          checked={campaignCreativeType === selectedCampaignCreativeType}
          onChange={handleSelect}
          value={campaignCreativeType}
          disabled={isDisabled}
        />

        {/*  eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <label onClick={handleSelect} className="radio-label">
          {name}
        </label>

        <SponsoredBrandsTooltip disabled={disabledAmazonSB} />
        <SponsoredBrandsVideoTooltip disabled={disabledAmazonSBV} />
        <SponsoredVideosTooltip
          disabled={isDisabled && !disabledAmazonSB && !disabledAmazonSBV}
          supportEmail={suportEmail}
        />
      </div>

      <div style={isDisabled ? disabledStyles : null} role="button" className="type-img" onClick={handleSelect}>
        <div className={`type-svg ${campaignCreativeType === selectedCampaignCreativeType ? 'active' : ''}`}>
          {Image ? <Image /> : null}
        </div>
      </div>

      <div className="type-disc">{extendedAttributes.description || ''}</div>
    </div>
  );
};

const mapStateToProps = (state: ReduxStore) => ({
  app: state.app,
  adCampaignBuilder: state.adCampaignBuilder,
  adPlatformSettings: state.adPlatformSettings,
  campaignTypeSettings: getAdPlatformSettingsOfType(
    AD_PLATFORM_SETTING_TYPE.CAMPAIGN_TYPE,
    getPlatformType(state)
  )(state) as AdManagerCampaignTypeAdPlatformSettings[]
});

const WALMART_SV_ACTION_TYPES = {
  mediaManagement: 'type_mediaManagement',
  campaignBuilder: 'type_campaignBuilder'
};

function modifyURL(paramName: string, paramValue: string | null) {
  // Create a URL object with the current location
  const url = new URL(window.location.href);
  // Create URLSearchParams object from the search string
  const params = new URLSearchParams(url.search);
  // Add or update parameter
  if (paramValue !== null) {
    params.set(paramName, paramValue);
  }
  // Remove parameter
  else {
    params.delete(paramName);
  }
  // Set the search params back to the url
  url.search = params.toString();
  // Change the browser URL without refreshing the page
  window.history.replaceState({}, '', url);
}

const SetupType: React.FC<ReturnType<typeof mapStateToProps>> = ({
  // eslint-disable-next-line react/prop-types
  adCampaignBuilder: { campaignType, creative },
  // eslint-disable-next-line react/prop-types
  campaignTypeSettings,
  // eslint-disable-next-line react/prop-types
  adPlatformSettings
}) => {
  const beaconClientLoginId = useAppSelector((state) =>
    _get(state, 'adCampaignBuilder.platformSettings.entity.extendedAttributes.beaconClientLoginId', null)
  );

  const [actionType, setActionType] = React.useState(WALMART_SV_ACTION_TYPES.mediaManagement);

  const handleChangeActionType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionType((event.target as HTMLInputElement).value);
  };

  useOnce(() => {
    if (_isEmpty(adPlatformSettings)) {
      store.dispatch(adPlatformSettingsOperations.fetchAdPlatformSettings());
    }
  });

  const { data: mediaAssets, isFetching: isFetchingWSV } = useQuery(
    ['AdWalmartService_getMedia', beaconClientLoginId],
    () => {
      if (beaconClientLoginId === null) {
        // Return a Promise that resolves to null
        return Promise.resolve(null);
      }
      return AdWalmartService.getMedia({ headers: null, beaconClientLoginId });
    },
    {
      retry: 0,
      staleTime: 60000,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true
    }
  );

  const { data: mediaLocations } = useQuery(
    ['AdWalmartService_getMediaUploadLocations', beaconClientLoginId],
    () => {
      if (beaconClientLoginId === null) {
        // Return a Promise that resolves to null
        return Promise.resolve(null);
      }
      return AdWalmartService.getMediaLocations({ headers: null, beaconClientLoginId });
    },
    {
      retry: 0,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true
    }
  );

  const canContinue = campaignType ? campaignType.id !== '' : false;
  const isCampaignTypeWalmartSV = campaignType && campaignType.id === CAMPAIGN_TYPES.SponsoredWalmartVideo;
  const haveAnyMedia = mediaAssets && mediaAssets.length > 0;

  const canContinueWithWSV = !isFetchingWSV;

  let types: any[] = campaignTypeSettings;
  const ORDER_OVERRIDE: (typeof CAMPAIGN_TYPES)[keyof typeof CAMPAIGN_TYPES][] = [
    CAMPAIGN_TYPES.SponsoredProducts,
    CAMPAIGN_TYPES.SponsoredBrands,
    CAMPAIGN_TYPES.SponsoredBrandsVideo,
    CAMPAIGN_TYPES.SponsoredDisplay,
    CAMPAIGN_TYPES.SponsoredBrandAmplifier,
    CAMPAIGN_TYPES.SponsoredWalmartVideo
  ];

  const isAllowedWalmartClint = (substring) => {
    return !substring.includes('is not allowed');
  };

  const isWalmartAllowedClient =
    mediaLocations && mediaLocations.details !== undefined && isAllowedWalmartClint(mediaLocations.details);
  // const beaconClientId = _get(store.getState(), ['user', 'config', 'vendor', 'BeaconClientId'], null);
  // const isWalmartSplitClientAllowed = isAllowed_WalmartSV(beaconClientId);
  const isWalmartSVAllowed = isWalmartAllowedClient;
  // || !isWalmartSplitClientAllowed;

  // Generate Campaign Types for
  // build the types in order of ORDER_OVERRIDE
  types = ORDER_OVERRIDE.map((id) => campaignTypeSettings.find((item) => item.id === id)).filter(
    (item) => item !== undefined
  );

  useEffect(() => {
    // Update query params for NEXT button to redirect to Media management if selected
    modifyURL(
      'mediaManagementActionType',
      isCampaignTypeWalmartSV && actionType === WALMART_SV_ACTION_TYPES.mediaManagement ? 'true' : null
    );

    return () => {
      modifyURL('mediaManagementActionType', null);
    };
  }, [isCampaignTypeWalmartSV, actionType]);

  if (_isEmpty(adPlatformSettings)) {
    return null;
  }
  if (types.length === 2) {
    types.push(null);
  }
  return (
    <div className="ad-manager-container">
      <SectionWrapper header="Campaign type" subheader="Choose the type of campaign you'd like to create.">
        <div className="campaign-type-container">
          {types.map((opt, index) => {
            const creativeType = opt && opt.id === 'sponsoredBrandsVideo' ? 'VIDEO' : undefined;
            return (
              <CampaignTypeItem
                isWalmartSVAllowed={isWalmartSVAllowed}
                campaignType={opt}
                key={index}
                selectedCampaignType={campaignType}
                selectedCreative={creative}
                creativeType={creativeType}
              />
            );
          })}
        </div>
      </SectionWrapper>

      {isCampaignTypeWalmartSV && canContinueWithWSV && (
        <SectionWrapper header="Action type" subheader="Select action type.">
          <div className="campaign-type-container">
            <FormControl>
              <RadioGroup
                className="media-management-label"
                onChange={handleChangeActionType}
                defaultValue={actionType}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={WALMART_SV_ACTION_TYPES.mediaManagement}
                  control={<Radio style={{ color: '#46a8f6' }} />}
                  label={
                    <div>
                      <h5>Media Management</h5>
                      <span>Create new and manage existing media assets for your campaigns.</span>
                    </div>
                  }
                />
                <FormControlLabel
                  style={{ marginTop: 15 }}
                  disabled={!haveAnyMedia}
                  value={WALMART_SV_ACTION_TYPES.campaignBuilder}
                  control={<Radio style={{ color: '#46a8f6' }} />}
                  label={
                    <div>
                      <h5>
                        Campaign Builder
                        {!haveAnyMedia && (
                          <FormControl style={{ marginLeft: 5, verticalAlign: 'sub' }} variant="standard">
                            <InfoTooltip placement="right" style={{ marginTop: -8 }} interactive>
                              <span className="info">
                                At least 1 media asset is required before building your campaign.
                              </span>
                            </InfoTooltip>
                          </FormControl>
                        )}
                      </h5>
                      <span className="media-management-sublabel">Build a new Sponsored Brand Video campaign.</span>
                    </div>
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
        </SectionWrapper>
      )}

      <OperationButtons subtab="setupType" canContinue={isCampaignTypeWalmartSV ? canContinueWithWSV : canContinue} />
    </div>
  );
};

const EnhancedSetupType = connect(mapStateToProps)(SetupType);

export default EnhancedSetupType;
