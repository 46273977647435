import { FieldId } from './visualizationTypes';

/**
 * ESS key used for ad manager
 */
export const AD_MANAGER_STATE_PROPERTY_NAME = 'adManagerSearchResultData_total';

export const ALL_AD_TYPES: readonly FieldId[] = [
  'dsp',
  'sponsored_brands',
  'sponsored_display',
  'sponsored_products'
] as const;
