import { Parser } from 'expr-eval';

import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

export const CONTENT_SCORE_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  contentScore: {
    name: 'contentScore',
    displayName: 'Content Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    timePeriodAggregationFunctionType: 'lastValue',
    timePeriodAggregationFunction: 'avg',
    overrides: {
      retailerId_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'avg'
      },
      stacklineSku_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'avg'
      }
    },
    fillWithLastKnownValue: true,
    maxValue: 1.0
  },
  titleScore: {
    name: 'titleScore',
    displayName: 'Title Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    fillWithLastKnownValue: true
  },
  ingredientsScore: {
    name: 'ingredientsScore',
    displayName: 'Ingredients Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    fillWithLastKnownValue: true
  },
  warningsScore: {
    name: 'warningsScore',
    displayName: 'Warnings Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    fillWithLastKnownValue: true
  },
  directionsScore: {
    name: 'directionsScore',
    displayName: 'Directions Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    fillWithLastKnownValue: true
  },
  descriptionScore: {
    name: 'descriptionScore',
    displayName: 'Description Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    fillWithLastKnownValue: true
  },
  bulletScore: {
    name: 'bulletScore',
    displayName: 'Bullets Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    fillWithLastKnownValue: true
  },
  imageScore: {
    name: 'imageScore',
    displayName: 'Image Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    fillWithLastKnownValue: true
  },
  videoScore: {
    name: 'videoScore',
    displayName: 'Video Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    fillWithLastKnownValue: true
  },
  aplusScore: {
    name: 'aplusScore',
    displayName: 'A-Plus Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    fillWithLastKnownValue: true
  },
  // TODO: timestamp is used as a proxy for counting here because name and field currently must be equal
  timestamp: {
    name: 'timestamp',
    displayName: 'Content Violations',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'value_count',
    conditions: {
      rangeFilters: [
        {
          fieldName: 'contentScore',
          maxValue: 0.99,
          minValue: 0
        }
      ]
    }
  }
};

const buildContentAccuracyFields = (fieldName: string, fieldDisplayName: string) => {
  const approvedContentCount = {
    name: `${fieldName}Stats.approvedContentCount`,
    nameAlias: `${fieldName}ApprovedContentCount`,
    displayName: `Approved ${fieldDisplayName} Count`,
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  };

  const matchedContentCount = {
    name: `${fieldName}Stats.matchedContentCount`,
    nameAlias: `${fieldName}MatchedContentCount`,
    displayName: `Matched ${fieldDisplayName} Count`,
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  };
  const accuracy = {
    name: `${fieldName}Accuracy`,
    displayName: `${fieldDisplayName}`,
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      `${fieldName}ApprovedContentCount>0?${fieldName}MatchedContentCount/${fieldName}ApprovedContentCount:1`
    )
  };
  return {
    [approvedContentCount.name]: approvedContentCount,
    [approvedContentCount.nameAlias]: approvedContentCount,
    [matchedContentCount.name]: matchedContentCount,
    [matchedContentCount.nameAlias]: matchedContentCount,
    [accuracy.name]: accuracy
  };
};

/**
 * @deprecated We should be using fields from the new-content-metrics index from now on
 */
export const CONTENT_ACCURACY_FIELDS = {
  ...buildContentAccuracyFields('title', 'Title'),
  ...buildContentAccuracyFields('bullets', 'Bullets'),
  ...buildContentAccuracyFields('imageUrls', 'Images'),
  ...buildContentAccuracyFields('videoUrls', 'Videos'),
  contentAccuracy: {
    name: 'contentAccuracy',
    displayName: 'Content Accuracy',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg'
  }
};
