import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

export const OMNI_SHIPPING_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  averageShippingTime: {
    name: 'averageShippingTime',
    displayName: 'Average Shipping Time',
    aggregationFunction: 'avg',
    fulfillmentType: 'shipping',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.DECIMAL
  },
  averageDeliveryTime: {
    name: 'averageDeliveryTime',
    displayName: 'Average Delivery Time',
    aggregationFunction: 'avg',
    fulfillmentType: 'delivery',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.DECIMAL
  },
  averagePickUpTime: {
    name: 'averagePickUpTime',
    displayName: 'Average Pickup Time',
    aggregationFunction: 'avg',
    fulfillmentType: 'pickUp',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.DECIMAL
  },
  averageFulfillmentTime: {
    name: 'averageFulfillmentTime',
    displayName: 'Average Fulfillment Time',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.DECIMAL
  },
  averageFulfillmentByOrderType: {
    name: 'averageFulfillmentByOrderType',
    displayName: 'Order Type',
    groupBy: 'beaconClientId',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.DECIMAL
  }
};
