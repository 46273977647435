import { useAppSelector } from 'src/utils/Hooks';
import { useDeepCompareMemo } from 'src/utils/app';
import _pick from 'lodash/pick';
import ReduxStore from 'src/types/store/reduxStore';

/**
 * Get a subset of main time period and comparison time periods
 * with the properties that are required for SRG
 */
const useTimePeriods = () => {
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const comparisonTimePeriod = useAppSelector((state) => state.comparisonTimePeriod);

  const mainTimePeriodMemo = useDeepCompareMemo(
    () => _pick(mainTimePeriod, ['endDayId', 'endWeek', 'startDayId', 'startWeek']),
    [mainTimePeriod]
  ) as Pick<ReduxStore['mainTimePeriod'], 'endDayId' | 'endWeek' | 'startDayId' | 'startWeek'>;

  const comparisonTimePeriodMemo = useDeepCompareMemo(
    () => _pick(comparisonTimePeriod, ['endDayId', 'endWeek', 'startDayId', 'startWeek']),
    [comparisonTimePeriod]
  ) as Pick<ReduxStore['mainTimePeriod'], 'endDayId' | 'endWeek' | 'startDayId' | 'startWeek'>;

  return {
    mainTimePeriod: mainTimePeriodMemo,
    comparisonTimePeriod: comparisonTimePeriodMemo
  };
};

export default useTimePeriods;
