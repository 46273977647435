import { AD_CAMPAIGN_STATUS, AD_STATUS_DERIVED } from 'sl-ad-campaign-manager-data-model';
import { ProductStatuses } from 'src/components/AdManager/Search/GridDataFetchers/Products/ProductsDataFetcher';
import { INELIGIBLE_FIELDS } from 'src/components/AdManager/AdIneligibleInfo';

const shouldUpdateProductStatus = (value: string) => {
  return INELIGIBLE_FIELDS.find((item) => item === value);
};

export class ProductMapHelper {
  // eslint-disable-next-line class-methods-use-this
  public addProductToChildDocumentsOfProductMapEntry(
    adProductDocumentsByStacklineSkuMap: any,
    adCampaignProductDocument: any,
    overrideStatuses: (args: { adCampaignProductDocument: ProductStatuses }) => ProductStatuses
  ) {
    const { stacklineSku } = adCampaignProductDocument;
    if (!adProductDocumentsByStacklineSkuMap[stacklineSku]) {
      adProductDocumentsByStacklineSkuMap[stacklineSku] = ProductMapHelper.getEmptyProductMapEntry(
        adCampaignProductDocument,
        stacklineSku
      );
    }
    const { extendedAttributes } = adProductDocumentsByStacklineSkuMap[stacklineSku];
    if (overrideStatuses) {
      const updatedStatuses = overrideStatuses({ adCampaignProductDocument });
      Object.entries(updatedStatuses).forEach(([key, value]) => {
        extendedAttributes[key] = value;
      });
    } else {
      if (adCampaignProductDocument.status === AD_CAMPAIGN_STATUS.ENABLED) {
        extendedAttributes.status = AD_CAMPAIGN_STATUS.ENABLED;
      }
      if (adCampaignProductDocument.statusDerived === AD_STATUS_DERIVED.DELIVERING) {
        extendedAttributes.statusDerived = AD_STATUS_DERIVED.DELIVERING;
      }
      if (!shouldUpdateProductStatus(adCampaignProductDocument.statusReason)) {
        extendedAttributes.statusReason = adCampaignProductDocument.statusReason;
      }
    }

    extendedAttributes.childDocuments.push(adCampaignProductDocument);
  }

  private static getEmptyProductMapEntry(adCampaignProductDocument: any, stacklineSku: any) {
    return {
      beaconClientId: adCampaignProductDocument.beaconClientId,
      platformType: adCampaignProductDocument.platformType,
      stacklineSku,
      extendedAttributes: {
        statusReason: adCampaignProductDocument.statusReason || '',
        status: AD_CAMPAIGN_STATUS.PAUSED,
        statusDerived: AD_STATUS_DERIVED.PAUSED,
        childDocuments: []
      }
    };
  }
}
