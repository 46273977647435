import React, { useState, useMemo } from 'react';
import Visualization from '../../components/Visualization';
import VisualizationInsight from '../../components/VisualizationInsight';
import { NewToBrandDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import NewToBrandStackedChart from './NewToBrandStackedChart';
import useConnectFetchEntityMetrics from '../hooks/useConnectFetchEntityMetrics';
import useAmcDateRange from '../hooks/useAmcDateRange';
import { useAppSelector, useMetricFormatter, useBus } from 'src/utils/Hooks';
import { ESSRequestContext, SearchRequestOverride } from 'src/store/modules/entitySearchService/operationTypes';
import { AD_MANAGER_STATE_PROPERTY_NAME } from '../constants';
import {
  buildRequestForNTBStackedChart,
  newToBrandAdvancedSearchResponseParser,
  newToBrandCampaignResponseParser
} from './utils';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { withBus } from 'react-bus';
import { NEW_TO_BRAND_EVENT, NEW_TO_BRAND_GROUPBY_EVENT } from './constants';
import { EventBus } from 'src/types/utils';
import _sum from 'lodash/sum';

/**
 * TODO List for adding "Group By Campaign" functionality
 * @todo Implement Group-by Campaign option
 * Currently we only support Group By Ad Type. Switching to "by Campaign" will fetch the campaign data instead.
 * @todo Update parser to handle additional data or add a secondary parser function.
 * @todo Determine what campaign data we need from Redux to map NTB metrics to Campaign details.
 * @todo Add Group By Campaign button to chart configuration.
 * @todo Add ability to go to specific campaign summary page via clicking on campaign names on x-axis.
 * @completed Add Group By button event.
 * @completed Change request body based on Group By selected.
 */

interface NewToBrandVisualizationProps {
  eventBus: EventBus;
  renderVisualizationInsightText?: boolean; // temporary until we determine status of these for other charts
}
/**
 * Visualization showing a stacked bar chart with information about New to Brand
 * and non New to Brand metrics.
 */
const NewToBrandVisualization = ({ eventBus, renderVisualizationInsightText = true }: NewToBrandVisualizationProps) => {
  const [metric, setMetric] = useState(NewToBrandDropdownOption.AdSales);
  const [groupByMetric, setGroupByMetric] = useState(NewToBrandDropdownOption.AdType);
  const { startDayId, endDayId } = useAmcDateRange();
  const retailerId = useAppSelector((state) => state.retailer.id);
  const formatMetric = useMetricFormatter();

  useBus(eventBus, NEW_TO_BRAND_EVENT, setMetric);
  useBus(eventBus, NEW_TO_BRAND_GROUPBY_EVENT, setGroupByMetric);

  const indexOverrides = {
    [NewToBrandDropdownOption.AdType]: 'amc-new-to-brand-metrics',
    [NewToBrandDropdownOption.Campaign]: 'amc-new-to-brand-by-campaign'
  };

  const searchRequestOverrides: SearchRequestOverride[] = useMemo(
    () => buildRequestForNTBStackedChart({ groupByMetric, startDayId, endDayId, retailerId }),
    [startDayId, endDayId, retailerId, groupByMetric]
  );

  const advancedSearchParserLookup = {
    [NewToBrandDropdownOption.AdType]: newToBrandAdvancedSearchResponseParser,
    [NewToBrandDropdownOption.Campaign]: newToBrandCampaignResponseParser
  };

  const requestContextOverrides: Partial<ESSRequestContext> = useMemo(
    () => ({
      customResponseParser: ({ apiResponse, state }) => ({
        ...state[AD_MANAGER_STATE_PROPERTY_NAME],
        newToBrandData: advancedSearchParserLookup[groupByMetric](apiResponse.data[0])
      })
    }),
    [groupByMetric]
  );

  const { data, isLoading } = useConnectFetchEntityMetrics({
    indexName: indexOverrides[groupByMetric],
    visualizationKey: 'newToBrandData',
    searchRequestOverrides,
    requestContextOverrides
  });

  /**
   * Safe access to new to brand since data is undefined
   * while fetching from advanced search
   */
  const newToBrandData = useMemo(() => {
    return data && data.newToBrandData ? data.newToBrandData : null;
  }, [data]);

  const insightText: string = useMemo(() => {
    if (!newToBrandData) {
      return '';
    }

    if (newToBrandData[metric].adTypes.length < 1) {
      return '';
    }

    const newToBrandTotal = _sum(newToBrandData[metric].newToBrand);
    const nonNewToBrandTotal = _sum(newToBrandData[metric].nonNewToBrand);
    const metricTotal = newToBrandTotal + nonNewToBrandTotal;
    const newToBrandPercent = formatMetric(metricTotal > 0 ? newToBrandTotal / metricTotal : 0, METRICTYPE.PERCENT, {
      decimalPlaces: 2
    });

    switch (metric) {
      case NewToBrandDropdownOption.AdSales:
        return `${newToBrandPercent} of ad sales were new-to-brand shoppers.`;
      case NewToBrandDropdownOption.UnitsSold:
        return `${newToBrandPercent} of ad units sold were new-to-brand shoppers.`;
      case NewToBrandDropdownOption.Conversions:
        return `${newToBrandPercent} of conversions were new-to-brand shoppers.`;
      default:
        return '';
    }
  }, [newToBrandData, formatMetric, metric]);

  return (
    <Visualization
      titleProps={{
        title: ''
      }}
    >
      {renderVisualizationInsightText ? <VisualizationInsight>{insightText}</VisualizationInsight> : null}
      <div style={{ marginTop: '24px' }}>
        {isLoading ? (
          <GenericChartLoading />
        ) : newToBrandData ? (
          <NewToBrandStackedChart
            data={newToBrandData}
            metric={metric}
            groupByMetric={groupByMetric}
            renderVisualizationInsightText={renderVisualizationInsightText}
          />
        ) : null}
      </div>
    </Visualization>
  );
};

export default withBus('eventBus')(NewToBrandVisualization);
