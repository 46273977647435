import React from 'react';
import { useTreatments, useClient } from '@splitsoftware/splitio-react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import { UserIcon } from '../../components/SvgIcons';
import Loading from '../../components/common/Loading';
import UserTeamManagement from './UserTeamManagement/UserTeamManagement';

const SPLIT_FEATURE_NAME = 'atlas_user_management';
const propTypes = {
  user: PropTypes.object.isRequired
};

function UserTeam({ user }) {
  const { team } = user;

  useClient(undefined, 'user', { email: user.session.email, companyId: user.session.companyId });

  const treatments = useTreatments([SPLIT_FEATURE_NAME]);

  const { treatment } = treatments[SPLIT_FEATURE_NAME];
  if (treatment === 'on') {
    return <UserTeamManagement />;
  } else {
    if (_isEmpty(team) || team.length === 0) {
      return <Loading className="spinner" />;
    }
    return (
      <div className="team">
        <h4 className="sl-header-text">Team</h4>
        <hr className="sl-divider sl-divider--no-margin-top" />
        <List className="nav-list-item-container">
          {team.map((teamUser) => (
            <MenuItem
              key={teamUser.email}
              leftIcon={
                <UserIcon
                  style={{ height: '28px', width: '28px', margin: '10px' }}
                  className="nav-list-item__icon nav-list-item__icon--active sl-divider--lg-margin-bottom"
                />
              }
            >
              {`${teamUser.firstName} ${teamUser.lastName}`}
              <span className="account-team--email">{` (${teamUser.email})`}</span>
            </MenuItem>
          ))}
        </List>
      </div>
    );
  }
}

UserTeam.propTypes = propTypes;

export default UserTeam;
