import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { ValueOf } from 'sl-api-connector/types';

import ReduxStore from 'src/types/store/reduxStore';
import types from './types';
import actionCreators from './actions';

type State = ReduxStore['entitySearchService'];
type ESSActionCreators = typeof actionCreators;
type ESSAction = ValueOf<{ [K in keyof ESSActionCreators]: ReturnType<ESSActionCreators[K]> }>;

const initialState: State = {
  isFetching: false as any
};

export default function entitySearchService(state = initialState, action: ESSAction): State {
  switch (action.type) {
    case types.RECEIVE_ENTITY_SALES_METRICS:
      return {
        ...state,
        [action.statePropertyName]: action.statePropertyValue,
        isFetching: false
      };
    case types.REQUEST_ENTITY_SALES_METRICS:
      return {
        ...state,
        [action.statePropertyName]: null,
        isFetching: true as any
      };
    case types.IS_FETCHING_ADDITIONAL_DATA:
      return {
        ...state,
        isFetching: true as any
      };
    case types.MERGE_DATA_KEYS:
      return {
        ...state,
        [action.fromKey]: null,
        [action.toKey]: { ...state[action.toKey], ...state[action.fromKey] }
      } as State;
    case types.SET_DATA_KEY_ATTRIBUTE:
      return {
        ...state,
        [action.dataKey]: { ...state[action.dataKey], [action.attrName]: action.attrValue }
      };
    case types.DELETE_DATA_KEY:
      return _omit(state, [action.key]);
    case types.SET_DATA_KEY:
      return {
        ...state,
        [action.key]: action.value
      };
    case types.MAP_KEY:
      return {
        ...state,
        [action.toKey]: action.mapValueToNewValue(state[action.fromKey]) as any
      };
    case types.MERGE_DATA_KEYS_TO_MAPPING: {
      const values = state[action.srcKey];
      const { groupByFieldName } = values.apiRequest[0].aggregations[0];

      const createdMappings: { [key: string]: { [key: string]: any } } = {};
      Object.entries(values)
        .filter(([key]) => key !== 'apiRequest' && !key.includes('dataPointCount'))
        .forEach(([key, { data }]) => {
          const dstMapping = _get(state[action.dstKey], key);
          createdMappings[key] = dstMapping ? { ...dstMapping } : {};

          if (key.includes('_by_retailerId')) {
            createdMappings[key] = values[key];
          } else {
            data.forEach((datum) => {
              const groupByFieldValue = datum.cardView[groupByFieldName];
              createdMappings[key][groupByFieldValue] = datum;
            });
          }
        });
      const newState = {
        ...state,
        [action.srcKey]: null,
        [action.dstKey]: { ...state[action.dstKey], ...createdMappings }
      };
      delete newState[action.srcKey];
      return newState as State;
    }
    case types.CLEAR_ENTITY_SEARCH_SERVICE:
      return initialState;
    default:
      return state;
  }
}
