/**
 * Contains functions used to compute the impact for each of the metrics that the waterfall chart provides.
 */

import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { waterfallImpactByCategoryId } from './WaterfallConstants';

// Constants for performing the impact calculations of the individual metrics
const CONTENT_SCORE_TO_UNITS_SOLD_ELASTICITY = 0.3;
const REVIEW_STAR_RATING_TO_UNITS_SOLD_ELASTICITY = 0.3;
const INSTOCK_RATE_TO_UNITS_SOLD_ELASTICITY = 1.0;
const RATE_TO_UNITS_SOLD_ELASTICITY = 1.0;

const capMetricImpactValue = ({ unCappedValue, maxImpactAbs }) => {
  const impactDirection = unCappedValue >= 0 ? 1 : -1;
  return Math.min(Math.abs(unCappedValue), maxImpactAbs) * impactDirection;
};

const computeImpactBasedOnRatio = ({
  maxImpactAbs,
  mainMetricComparisonTimePeriod,
  comparisonMetricMainTimePeriod,
  comparisonMetricComparisonTimePeriod
}) => {
  // Impact of organic traffic to unitssold
  const mainMetricToComparisonMetricRatio = Math.min(
    !comparisonMetricComparisonTimePeriod ? 1 : mainMetricComparisonTimePeriod / comparisonMetricComparisonTimePeriod,
    1
  );
  const impactUncapped =
    comparisonMetricMainTimePeriod * mainMetricToComparisonMetricRatio -
    comparisonMetricComparisonTimePeriod * mainMetricToComparisonMetricRatio;
  const impactDirection = impactUncapped >= 0 ? 1 : -1;
  return Math.min(Math.abs(impactUncapped), maxImpactAbs) * impactDirection;
};

export const allMetrics = [
  { name: '<div>Units Sold<br />(Prior)</div>', displayName: 'Units Sold (Prior)', id: 'unitsSoldComparisonPeriod' },
  { name: '<div>Organic<br />Traffic</div>', displayName: 'Organic Traffic', id: 'trafficOrganicClicks' },
  { name: '<div>Paid<br />Traffic</div>', displayName: 'Paid Traffic', id: 'trafficAdClicks' },
  { name: '<div>Other<br />Traffic</div>', displayName: 'Other Traffic', id: 'trafficOtherClicks' },
  { name: '<div>Retail<br />Price</div>', displayName: 'Retail Price', id: 'retailPrice' },
  { name: '<div>Content<br />Score</div>', displayName: 'Content Score', id: 'contentScore' },
  { name: '<div>Ratings<br />& Reviews</div>', displayName: 'Ratings & Reviews', id: 'avgStars' },
  { name: '<div>In-Stock<br />Rate</div>', displayName: 'In-Stock Rate', id: 'instockRate' },
  { name: '<div>Buy Box<br />Rate</div>', displayName: 'Buy Box Rate', id: 'buyBoxWinPercentage' },
  { name: '<div>Other<br />Variables</div>', displayName: 'Other Variables', id: 'other' },
  { name: '<div>Products<br />(New)</div>', displayName: 'Products (New)', id: 'newProducts' },
  { name: '<div>Products<br />(Inactive)</div>', displayName: 'Products (Inactive)', id: 'discontinuedProducts' },
  { name: '<div>Units Sold<br />(Current)</div>', displayName: 'Units Sold (Current)', id: 'unitsSoldMainPeriod' }
];

export const getFieldNameFromSeriesName = (seriesName) => (allMetrics.find(({ name }) => name === seriesName) || {}).id;

export const getDefaultUnitsSoldImpactConsolidated = () => {
  const unitsSoldImpactConsolidated = allMetrics.reduce((acc, { name, id }) => ({ ...acc, [id]: { name, y: 0 } }), {});
  unitsSoldImpactConsolidated.unitsSoldMainPeriod.sum = true;
  return unitsSoldImpactConsolidated;
};

/**
 * In order to figure out what data to display and how to display it, the `CardFrom` component looks at the
 * `metricData` field of the `cardView` datum that it receives as a prop.  This `metricData` attribute must be
 * dynamically created depending on the current entity and the selected metric field, which is what this function does.
 *
 * @param {string} selectedField The name of the selected impact metric for which metric data must be returned
 * @param {string} entityType The value of `entity.type` from the API response item
 * @param {string} currencySymbol `retailer.currencySymbol` from Redux
 * @param {string} locale `retailer.locale` from Redux
 */
export const getMetricFieldForSelectedField = (selectedField, entityType, currencySymbol, locale) => {
  const indexName = 'productWeeklyMetrics';
  const entityDefinition = {
    unitsSoldComparisonPeriod: INDEX_FIELDS.getField('beacon', indexName, 'unitsSold', entityType, 'stacklineSku'), // beacon_unitsSold_value_comparisontimeperiod
    trafficOrganicClicks: INDEX_FIELDS.getField(
      'beacon',
      indexName,
      'trafficOrganicClicks',
      entityType,
      'stacklineSku'
    ), // beacon_trafficOrganicClicks_value_maintimeperiod
    trafficAdClicks: INDEX_FIELDS.getField('beacon', indexName, 'adUnitsSold', entityType, 'stacklineSku'), // beacon_adUnitsSold_change
    trafficOtherClicks: INDEX_FIELDS.getField('beacon', indexName, 'trafficOtherClicks', entityType, 'stacklineSku'), // beacon_trafficOtherClicks_change
    retailPrice: INDEX_FIELDS.getField('beacon', indexName, 'retailPrice', entityType, 'stacklineSku'), // beacon_retailPrice_changePercent
    contentScore: INDEX_FIELDS.getField('beacon', indexName, 'contentScore', entityType, 'stacklineSku'), // beacon_contentScore_changePercent
    avgStars: INDEX_FIELDS.getField('beacon', indexName, 'avgStars', entityType, 'stacklineSku'), // beacon_avgStars_changePercent
    instockRate: INDEX_FIELDS.getField('beacon', indexName, 'instockRate', entityType, 'stacklineSku'), // beacon_instockRate_changePercent
    buyBoxWinPercentage: INDEX_FIELDS.getField('beacon', indexName, 'buyBoxWinPercentage', entityType, 'stacklineSku'), // beacon_buyBoxWinPercentage_changePercent
    unitsSoldMainPeriod: INDEX_FIELDS.getField('beacon', indexName, 'unitsSold', entityType, 'stacklineSku') // beacon_unitsSold_value_maintimeperiod
  }[selectedField];

  if (!entityDefinition) {
    return null;
  }
  return { ...entityDefinition, currencySymbol, locale };
};

export const computeImpactDataForProduct = (
  {
    gridMetric,
    unitsSoldImpactConsolidated,
    metricAggregatedValues,
    countOfSkuWithDataToCompare,
    selectedField,
    currencySymbol,
    locale
  },
  stacklineSku,
  {
    beacon_unitsSold_value_maintimeperiod,
    beacon_unitsSold_value_comparisontimeperiod,
    beacon_unitsSold_count_comparisontimeperiod,
    beacon_unitsSold_change,
    beacon_unitsSold_changePercent,
    // beacon_unitsSold_value_comparisontimeperiod,
    beacon_trafficOrganicClicks_value_maintimeperiod,
    beacon_trafficOrganicClicks_value_comparisontimeperiod,
    beacon_trafficOtherClicks_value_maintimeperiod,
    beacon_trafficOtherClicks_value_comparisontimeperiod,
    beacon_trafficOtherUnitsSold_value_maintimeperiod,
    beacon_trafficOtherUnitsSold_value_comparisontimeperiod,
    // beacon_trafficOtherUnitsSold_change,
    beacon_adUnitsSold_change,
    beacon_retailPrice_value_maintimeperiod,
    beacon_retailPrice_value_comparisontimeperiod,
    beacon_retailPrice_changePercent,
    beacon_contentScore_changePercent,
    beacon_contentScore_value_maintimeperiod,
    beacon_contentScore_value_comparisontimeperiod,
    beacon_avgStars_changePercent,
    beacon_avgStars_value_maintimeperiod,
    beacon_avgStars_value_comparisontimeperiod,
    beacon_instockRate_changePercent,
    beacon_buyBoxWinPercentage_changePercent,
    beacon_buyBoxWins_value_maintimeperiod,
    beacon_buyBoxWins_value_comparisontimeperiod,
    availabilityStatus,
    cardView = {},
    entity
  }
) => {
  const unitSoldImpact = {};
  if (!beacon_unitsSold_count_comparisontimeperiod) {
    // new product
    unitSoldImpact.newProducts = Number.isNaN(+beacon_unitsSold_value_maintimeperiod)
      ? 0
      : beacon_unitsSold_value_maintimeperiod;
  } else if (!beacon_unitsSold_value_maintimeperiod && availabilityStatus === 'inactive') {
    // discontinued product
    unitSoldImpact.discontinuedProducts = Number.isNaN(+beacon_unitsSold_value_comparisontimeperiod)
      ? 0
      : -beacon_unitsSold_value_comparisontimeperiod;
  } else {
    // Maximum unitsSold impact any single metric can account for is 80%
    const unitsSoldMaxImpactAbs = Math.abs(
      Math.max(
        Number.isNaN(+beacon_unitsSold_value_maintimeperiod) ? 0 : beacon_unitsSold_value_maintimeperiod,
        beacon_unitsSold_value_comparisontimeperiod
      ) * 0.8
    );

    // Impact of organic traffic to unitSold [Capped]
    unitSoldImpact.trafficOrganicClicks = computeImpactBasedOnRatio({
      maxImpactAbs: unitsSoldMaxImpactAbs,
      mainMetricComparisonTimePeriod: beacon_unitsSold_value_comparisontimeperiod,
      comparisonMetricMainTimePeriod: beacon_trafficOrganicClicks_value_maintimeperiod,
      comparisonMetricComparisonTimePeriod: beacon_trafficOrganicClicks_value_comparisontimeperiod
    });

    // Impact of paid traffic to unitSold [Not Capped]
    unitSoldImpact.trafficAdClicks = beacon_adUnitsSold_change;

    // Impact of other traffic to unitSold [Capped]
    unitSoldImpact.trafficOtherClicks = computeImpactBasedOnRatio({
      maxImpactAbs: unitsSoldMaxImpactAbs,
      mainMetricComparisonTimePeriod: beacon_unitsSold_value_comparisontimeperiod,
      comparisonMetricMainTimePeriod: beacon_unitsSold_value_maintimeperiod * 0.93,
      comparisonMetricComparisonTimePeriod: beacon_unitsSold_value_comparisontimeperiod * 0.93
    });

    if (!metricAggregatedValues.beacon_unitsSold_value_maintimeperiod) {
      metricAggregatedValues.beacon_unitsSold_value_maintimeperiod = 0;
    }
    metricAggregatedValues.beacon_unitsSold_value_maintimeperiod += beacon_unitsSold_value_maintimeperiod;
    if (!metricAggregatedValues.beacon_unitsSold_value_comparisontimeperiod) {
      metricAggregatedValues.beacon_unitsSold_value_comparisontimeperiod = 0;
    }
    metricAggregatedValues.beacon_unitsSold_value_comparisontimeperiod += beacon_unitsSold_value_comparisontimeperiod;
    if (!metricAggregatedValues.beacon_trafficOtherClicks_value_maintimeperiod) {
      metricAggregatedValues.beacon_trafficOtherClicks_value_maintimeperiod = 0;
    }
    metricAggregatedValues.beacon_trafficOtherClicks_value_maintimeperiod +=
      beacon_trafficOtherClicks_value_maintimeperiod;
    if (!metricAggregatedValues.beacon_trafficOtherClicks_value_comparisontimeperiod) {
      metricAggregatedValues.beacon_trafficOtherClicks_value_comparisontimeperiod = 0;
    }
    metricAggregatedValues.beacon_trafficOtherClicks_value_comparisontimeperiod +=
      beacon_trafficOtherClicks_value_comparisontimeperiod;
    if (!metricAggregatedValues.beacon_trafficOtherUnitsSold_value_maintimeperiod) {
      metricAggregatedValues.beacon_trafficOtherUnitsSold_value_maintimeperiod = 0;
    }
    metricAggregatedValues.beacon_trafficOtherUnitsSold_value_maintimeperiod +=
      beacon_trafficOtherUnitsSold_value_maintimeperiod;
    if (!metricAggregatedValues.beacon_trafficOtherUnitsSold_value_comparisontimeperiod) {
      metricAggregatedValues.beacon_trafficOtherUnitsSold_value_comparisontimeperiod = 0;
    }
    metricAggregatedValues.beacon_trafficOtherUnitsSold_value_comparisontimeperiod +=
      beacon_trafficOtherUnitsSold_value_comparisontimeperiod;

    // Impact of retail price to unitsSold [Capped]
    const waterfallImpactConstantsForCategory = waterfallImpactByCategoryId[`${cardView.categoryId}`]
      ? waterfallImpactByCategoryId[`${cardView.categoryId}`]
      : waterfallImpactByCategoryId['9999'];
    if (beacon_retailPrice_value_maintimeperiod > 0 && beacon_retailPrice_value_comparisontimeperiod > 0) {
      unitSoldImpact.retailPrice = capMetricImpactValue({
        unCappedValue:
          beacon_retailPrice_changePercent *
          waterfallImpactConstantsForCategory.retailPriceElasticity *
          beacon_unitsSold_value_comparisontimeperiod,
        maxImpactAbs: unitsSoldMaxImpactAbs
      });
    } else {
      unitSoldImpact.retailPrice = 0;
    }

    // Impact of contentScore to unitsSold [Capped]
    if (beacon_contentScore_value_maintimeperiod > 0 && beacon_contentScore_value_comparisontimeperiod > 0) {
      unitSoldImpact.contentScore = capMetricImpactValue({
        unCappedValue:
          beacon_contentScore_changePercent *
          CONTENT_SCORE_TO_UNITS_SOLD_ELASTICITY *
          beacon_unitsSold_value_comparisontimeperiod,
        maxImpactAbs: unitsSoldMaxImpactAbs
      });
    } else {
      unitSoldImpact.contentScore = 0;
    }

    // Impact of review start rating to unitsSold [Capped]
    if (beacon_avgStars_value_maintimeperiod > 0 && beacon_avgStars_value_comparisontimeperiod > 0) {
      unitSoldImpact.avgStars = capMetricImpactValue({
        unCappedValue:
          beacon_avgStars_changePercent *
          REVIEW_STAR_RATING_TO_UNITS_SOLD_ELASTICITY *
          beacon_unitsSold_value_comparisontimeperiod,
        maxImpactAbs: unitsSoldMaxImpactAbs
      });
    } else {
      unitSoldImpact.avgStars = 0;
    }
    // Impact of instockRate to unitsSold [Capped]
    if (beacon_instockRate_changePercent >= 0.75 && beacon_unitsSold_changePercent <= 0.25) {
      unitSoldImpact.instockRate = 0;
    } else {
      unitSoldImpact.instockRate = capMetricImpactValue({
        unCappedValue:
          beacon_instockRate_changePercent *
          INSTOCK_RATE_TO_UNITS_SOLD_ELASTICITY *
          beacon_unitsSold_value_comparisontimeperiod,
        maxImpactAbs: unitsSoldMaxImpactAbs
      });
    }

    // Impact of `wins` (buybox rate) to unitsSold [Capped]
    if (beacon_buyBoxWins_value_comparisontimeperiod > 0 && beacon_buyBoxWins_value_maintimeperiod > 0) {
      if (beacon_buyBoxWinPercentage_changePercent >= 0.75 && beacon_unitsSold_changePercent <= 0.25) {
        unitSoldImpact.buyBoxWinPercentage = 0;
      } else {
        unitSoldImpact.buyBoxWinPercentage = capMetricImpactValue({
          unCappedValue:
            beacon_buyBoxWinPercentage_changePercent *
            RATE_TO_UNITS_SOLD_ELASTICITY *
            beacon_unitsSold_value_comparisontimeperiod,
          maxImpactAbs: unitsSoldMaxImpactAbs
        });
      }
    } else {
      unitSoldImpact.buyBoxWinPercentage = 0;
    }

    // Aggregate impact of all the known metric names
    const sumOfAllAccountedUnitsSoldImpact = Object.entries(unitSoldImpact).reduce(
      (acc, [metricName, metricValue]) =>
        acc + (metricName === 'other' || !metricValue ? 0 : unitSoldImpact[metricName]),
      0
    );

    // Compute impact of unknown metric as what's not accounted for by known metrics
    unitSoldImpact.other = beacon_unitsSold_change - sumOfAllAccountedUnitsSoldImpact;
  }

  if (beacon_unitsSold_value_comparisontimeperiod) {
    unitsSoldImpactConsolidated.unitsSoldComparisonPeriod.y += beacon_unitsSold_value_comparisontimeperiod;
  }

  Object.entries(unitSoldImpact).forEach(([impactMetricName, impactMetricValue]) => {
    if (impactMetricValue) {
      unitsSoldImpactConsolidated[impactMetricName].y += impactMetricValue;
    }
  });

  cardView.stacklineSku = stacklineSku.toUpperCase();
  cardView.name = cardView.title;
  cardView.secondaryDisplayTitle = cardView.title;

  const newGridMetricDatum = {
    unitSoldImpact,
    cardView,
    entity,
    name: cardView.title
  };

  gridMetric.push(newGridMetricDatum);

  return {
    metricAggregatedValues,
    unitsSoldImpactConsolidated,
    countOfSkuWithDataToCompare: countOfSkuWithDataToCompare + 1,
    currencySymbol,
    locale,
    selectedField,
    gridMetric
  };
};
