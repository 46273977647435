import _merge from 'lodash/merge';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import _pick from 'lodash/pick';
import _prop from 'lodash/property';

import { computePercentChange } from 'src/utils/metrics';
import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import { unescapeHtmlEntities } from 'src/utils/stringFormatting';
import { propEq } from 'src/utils/fp';
import { panic } from 'src/utils/mixpanel';

const dataPointCountFieldName = 'dataPointCount';

function fillMissingWeeks(metricsByGroup, currentWeekIds, groupByField) {
  Object.entries(metricsByGroup).forEach(([metricsGroupName, metricsForGroupName]) => {
    const fillWithLastKnownValue = _get(metricsForGroupName, 'fillWithLastKnownValue');
    const metricsData = metricsForGroupName.data;
    let lastKnownValue = 0;
    const existingWeekIds = metricsData.map(_prop('weekId'));
    const minWeekId = _get(metricsData, '[0].weekId', currentWeekIds[0]);

    currentWeekIds.forEach((weekId) => {
      if (!existingWeekIds.includes(weekId) && weekId >= minWeekId) {
        const processedMetaData = groupByField.processAdditionalMetaData
          ? groupByField.processAdditionalMetaData({ name: weekId.toString() })
          : { name: weekId };
        metricsData.push({
          ...processedMetaData,
          value: fillWithLastKnownValue ? lastKnownValue : 0
        });
      } else if (fillWithLastKnownValue && metricsData.find((x) => x.weekId === weekId)) {
        lastKnownValue = metricsData.find((x) => x.weekId === weekId).value;
      }
    });
    metricsData.sort((a, b) => a.weekId - b.weekId);

    const maxWeekId = _last(currentWeekIds);
    metricsForGroupName.data = metricsByGroup[metricsGroupName].data.filter((a) => a.weekId <= maxWeekId);
  });
}

function parseEntityTimeSeriesMetricsForOneSearchRequest(
  requestContext,
  allWeekIdsByRetailerId,
  searchRequestOverrides,
  apiRequest,
  apiResponse
) {
  const { app, entity, retailer, aggregationFields } = requestContext;
  const indexName = searchRequestOverrides.indexName || requestContext.indexName;
  const { currencySymbol, locale } = retailer;
  const groupByField = INDEX_FIELDS.getField(
    app.name,
    indexName,
    apiRequest.aggregations[0].groupByFieldName,
    entity.type
  );
  const { groupByFieldName } = apiRequest.aggregations[0];
  if (!apiResponse.aggregations || !apiResponse.aggregations[`by_${groupByField.name}`]) {
    return {};
  }
  const result = apiResponse;
  const metricsByGroup = {};
  const aggregationResults = result.aggregations[`by_${groupByField.name}`];

  const metricsGroupNames = [];
  apiRequest.aggregations[0].aggregationFields.forEach((aggregationField) => {
    let tempField = {
      ...INDEX_FIELDS.getField(
        app.name,
        indexName,
        [`${aggregationField.aggregateByFieldName}`],
        entity.type,
        groupByField.name
      ),
      displayName: aggregationField.aggregateByFieldDisplayName
    };
    if (aggregationFields) {
      tempField = aggregationFields.find((x) => x.name === aggregationField.aggregateByFieldName) || tempField;
    }
    metricsByGroup[`${aggregationField.aggregateByFieldName}_by_${groupByField.name}`] = _merge(
      {},
      { data: [], currencySymbol, locale, entity: _cloneDeep(entity), retailer, groupByField },
      tempField
    );
    metricsGroupNames.push(`${aggregationField.aggregateByFieldName}_by_${groupByField.name}`);
  });

  metricsByGroup[`${dataPointCountFieldName}_by_${groupByField.name}`] = _merge(
    {},
    { data: [], currencySymbol, locale, entity: _cloneDeep(entity), retailer, groupByField },
    INDEX_FIELDS.getField(app.name, indexName, dataPointCountFieldName, groupByField.name)
  );

  aggregationResults.forEach((aggregationResult) => {
    const processedMetaData = groupByField.processAdditionalMetaData
      ? groupByField.processAdditionalMetaData(aggregationResult.additionalMetaData)
      : { name: aggregationResult.additionalMetaData.name };
    const currentAggregationValues = {};

    metricsGroupNames.forEach((metricsGroupName) => {
      const metricsForGroup = metricsByGroup[metricsGroupName];

      const aggregationValue = `${metricsForGroup.aggregationFunction}` === 'stats' ? 'avg' : 'value';

      const additionalValueKey = `${metricsForGroup.name}_${metricsForGroup.aggregationFunction}_${aggregationValue}`;
      const additionalCountKey = `${metricsForGroup.name}_${metricsForGroup.aggregationFunction}_count`;
      currentAggregationValues[metricsGroupName] = {
        ...processedMetaData,
        value: aggregationResult.additionalValues[additionalValueKey],
        count: aggregationResult.additionalValues[additionalCountKey]
      };

      if (`${metricsForGroup.aggregationFunction}` === 'stats') {
        currentAggregationValues[metricsGroupName] = {
          ...currentAggregationValues[metricsGroupName],
          sum: aggregationResult.additionalValues[`${metricsForGroup.name}_${metricsForGroup.aggregationFunction}_sum`],
          documentCount:
            aggregationResult.additionalValues[`${metricsForGroup.name}_${metricsForGroup.aggregationFunction}_count`]
        };
      }
    });

    const firstGroupNameMetrics = metricsByGroup[metricsGroupNames[0]];
    const additionalValueKey = `${firstGroupNameMetrics.name}_${firstGroupNameMetrics.aggregationFunction}_count`;
    currentAggregationValues[`${dataPointCountFieldName}_by_${groupByField.name}`] = {
      ...processedMetaData,
      value: aggregationResult.additionalValues[additionalValueKey]
    };

    Object.entries(currentAggregationValues).forEach(([key, val]) => metricsByGroup[key].data.push(val));
  });
  // sort by groupByField
  Object.keys(metricsByGroup).forEach((metricsGroupName) => {
    metricsByGroup[metricsGroupName].data.sort((a, b) => +a.name - b.name);
  });

  // fill missing time periods
  let requestRangeFilters = _get(apiRequest.aggregations, '[0].conditions.rangeFilters', []);
  if (requestRangeFilters.length === 0 || requestRangeFilters.findIndex((x) => x.fieldName === groupByFieldName) < 0) {
    requestRangeFilters = _get(apiRequest, 'conditions.rangeFilters', []);
  }
  const timePeriodRange = requestRangeFilters.find((item) => item.fieldName.includes(groupByFieldName));
  if (timePeriodRange) {
    if (groupByFieldName === 'weekId') {
      const allWeekIds = _cloneDeep(allWeekIdsByRetailerId[retailer.id]);
      const indexOfLastWeekInTimePeriod = allWeekIds.indexOf(timePeriodRange.maxValue);
      const currentWeekIds = allWeekIds.slice(
        allWeekIds.indexOf(timePeriodRange.minValue),
        indexOfLastWeekInTimePeriod > -1 ? indexOfLastWeekInTimePeriod + 1 : allWeekIds.length - 1
      );

      if (!_isEmpty(timePeriodRange) || timePeriodRange !== undefined) {
        // Add any missing weeks into the data given the current requested range
        fillMissingWeeks(metricsByGroup, currentWeekIds, groupByField);
      }
    }
  }

  return metricsByGroup;
}

const buildBaseCardViewFromDataPoint = (dataPoint) => {
  const {
    entity: {
      brandName,
      brandId,
      categoryId,
      categoryName,
      id: productId,
      retailerSkus: retailerSku,
      stacklineSku,
      subCategoryName,
      subCategoryId,
      breadCrumbSubCategoryName,
      breadCrumbSubCategoryId,
      breadCrumbNodeId,
      breadCrumbText,
      subtitle: secondarySubtitle,
      availabilityStatusCode = '',
      availabilityStatus = '',
      campaignType
    },
    name: secondaryDisplayTitle
  } = dataPoint;

  return {
    brandId,
    brandName,
    secondaryDisplayTitle,
    secondarySubtitle,
    productId,
    retailerSku,
    stacklineSku,
    categoryId,
    categoryName,
    subCategoryName,
    subCategoryId,
    breadCrumbSubCategoryName,
    breadCrumbSubCategoryId,
    breadCrumbNodeId,
    breadCrumbText,
    availabilityStatusCode,
    availabilityStatus,
    campaignType
  };
};

function computedDerivedMetrics(action, parsedMetrics) {
  const { requestContext, apiRequest } = action;
  const { app, entity, retailer, indexName, derivedFields } = requestContext;
  if (_isEmpty(derivedFields)) {
    return;
  }
  const { currencySymbol, locale } = retailer;
  const groupByField = INDEX_FIELDS.getField(
    app.name,
    indexName,
    apiRequest[0].aggregations[0].groupByFieldName,
    entity.type
  );

  if (derivedFields) {
    derivedFields.forEach((derivedField) => {
      const metricKey = `${derivedField.name}_by_${groupByField.name}`;
      parsedMetrics[metricKey] = _merge(
        {},
        {
          data: [],
          currencySymbol,
          locale,
          entity: _cloneDeep(entity),
          retailer,
          groupByField
        },
        derivedField
      );
      const primaryDependantField = derivedField.primaryDependantField || derivedField.dependentFields[0];

      parsedMetrics[`${primaryDependantField.name}_by_${primaryDependantField.groupByFieldName}`].data.forEach(
        (dataPoint) => {
          const derivedFieldParamValues = {};
          derivedField.dependentFields.forEach((dependentField) => {
            const dependentFieldParamValue = {};
            if (parsedMetrics[`${dependentField.name}_by_${dependentField.groupByFieldName}`].data.length > 0) {
              if (!parsedMetrics[metricKey].timePeriodFieldName) {
                const { timePeriodFieldName, mainTimePeriodRangeSuffix, comparisonTimePeriodRangeSuffix } =
                  parsedMetrics[`${dependentField.name}_by_${dependentField.groupByFieldName}`];
                parsedMetrics[metricKey] = {
                  ...parsedMetrics[metricKey],
                  timePeriodFieldName,
                  mainTimePeriodRangeSuffix,
                  comparisonTimePeriodRangeSuffix
                };
              }
              const dependentFieldDataPoint =
                dependentField.groupByResultType === 'onevalue'
                  ? parsedMetrics[`${dependentField.name}_by_${dependentField.groupByFieldName}`].data[0]
                  : parsedMetrics[`${dependentField.name}_by_${dependentField.groupByFieldName}`].data.find(
                      (x) => x.name === dataPoint.name
                    );
              if (dependentFieldDataPoint) {
                Object.keys(dependentFieldDataPoint)
                  .filter((x) => x.indexOf('value') === 0)
                  .forEach((valuePropertyName) => {
                    dependentFieldParamValue[valuePropertyName] = dependentFieldDataPoint[valuePropertyName];
                  });
              }
            }
            Object.keys(dependentFieldParamValue).forEach((valuePropertyName) => {
              if (!derivedFieldParamValues[valuePropertyName]) {
                derivedFieldParamValues[valuePropertyName] = {};
              }
              derivedFieldParamValues[valuePropertyName][dependentField.name] =
                dependentFieldParamValue[valuePropertyName];
              derivedFieldParamValues[valuePropertyName][`${dependentField.indexName}__${dependentField.name}`] =
                dependentFieldParamValue[valuePropertyName];
              derivedFieldParamValues[valuePropertyName][
                `${app.name}__${dependentField.indexName}__${dependentField.name}`
              ] = dependentFieldParamValue[valuePropertyName];
            });
          });
          derivedField.dependentFields.forEach((dependentField) => {
            Object.keys(derivedFieldParamValues).forEach((valuePropertyName) => {
              if (!derivedFieldParamValues[valuePropertyName][dependentField.name]) {
                derivedFieldParamValues[valuePropertyName][dependentField.name] = 0;
              }
              if (!derivedFieldParamValues[valuePropertyName][`${dependentField.indexName}__${dependentField.name}`]) {
                derivedFieldParamValues[valuePropertyName][`${dependentField.indexName}__${dependentField.name}`] = 0;
              }
              if (
                !derivedFieldParamValues[valuePropertyName][
                  `${app.name}__${dependentField.indexName}__${dependentField.name}`
                ]
              ) {
                derivedFieldParamValues[valuePropertyName][
                  `${app.name}__${dependentField.indexName}__${dependentField.name}`
                ] = 0;
              }
            });
          });
          const dataPointForDerivedField = groupByField.processAdditionalMetaData
            ? groupByField.processAdditionalMetaData({
                name: dataPoint.name,
                entity: dataPoint.entity
              })
            : { name: dataPoint.name, entity: dataPoint.entity };
          Object.keys(derivedFieldParamValues).forEach((valuePropertyName) => {
            dataPointForDerivedField[valuePropertyName] = derivedField.aggregationFunction.evaluate(
              derivedFieldParamValues[valuePropertyName]
            );
          });
          dataPointForDerivedField.cardView = dataPoint.cardView
            ? buildBaseCardViewFromDataPoint(dataPointForDerivedField)
            : null;
          if (dataPointForDerivedField.cardView) {
            const comparisonValue =
              dataPointForDerivedField[`value${parsedMetrics[metricKey].comparisonTimePeriodRangeSuffix}`];
            const mainValue = dataPointForDerivedField.value;
            const { periodChange, percentChange } = computePercentChange(comparisonValue, mainValue);
            dataPointForDerivedField.cardView = {
              ...dataPointForDerivedField.cardView,
              metricData: Object.assign({}, { currencySymbol, locale }, derivedField),
              [`${derivedField.name}CurrentValue`]: mainValue,
              [`${derivedField.name}PreviousValue`]: comparisonValue,
              [`${derivedField.name}PeriodChange`]: periodChange,
              [`${derivedField.name}PercentChange`]: percentChange
            };
          }
          parsedMetrics[metricKey].data.push(dataPointForDerivedField);
        }
      );
    });
  }
}

function parseBuyBoxTimeSeriesMetrics(action) {
  const { requestContext, allWeekIdsByRetailerId, searchRequestsOverrides, apiRequest, apiResponse } = action;
  const metrics_by_group = parseEntityTimeSeriesMetricsForOneSearchRequest(
    requestContext,
    allWeekIdsByRetailerId,
    searchRequestsOverrides[0],
    apiRequest[0],
    apiResponse.data[0]
  );
  // this is very special for buybox
  const numeratorKey = `wins_by_${action.apiRequest[0].aggregations[0].groupByFieldName}`;
  const denominatorKey = `by_${action.apiRequest[1].aggregations[0].groupByFieldName}`;
  action.apiResponse.data[1].aggregations[denominatorKey].forEach((dataPoint) => {
    const timeSeriesFieldId = dataPoint.fieldId;
    const denominatorValue =
      dataPoint.additionalValues.wins_sum_value === 0 ? 1 : dataPoint.additionalValues.wins_sum_value;
    metrics_by_group[numeratorKey].data.find((x) => x.name === timeSeriesFieldId).value /= denominatorValue;
  });
  return metrics_by_group;
}

function parseEntityTimeSeriesMetrics(action) {
  if (!action || !action.apiResponse || !action.apiResponse.data || !action.apiResponse.data.length === 0) {
    return {};
  } else if (
    action.apiRequest[0].searchType === 'beacon-buybox' &&
    action.apiRequest.length === 2 &&
    action.apiResponse.data.length === 2
  ) {
    return parseBuyBoxTimeSeriesMetrics(action);
  }

  const { requestContext, allWeekIdsByRetailerId, searchRequestsOverrides, apiRequest, apiResponse } = action;

  const parsedMetrics = apiRequest.reduce((acc, request, index) => {
    const metrics_by_group = parseEntityTimeSeriesMetricsForOneSearchRequest(
      requestContext,
      allWeekIdsByRetailerId,
      searchRequestsOverrides[index],
      request,
      apiResponse.data[index]
    );

    return { ...metrics_by_group, ...acc };
  }, {});

  computedDerivedMetrics(action, parsedMetrics);

  return { ...parsedMetrics };
}

const removeZeroValues = (metrics) => {
  metrics.data = metrics.data.filter(({ value }) => value > 0);
};

const parseSingleAggregationResult = ({ groupByField, entityConfig }, aggregationResult, groupByFieldNames) => {
  const { additionalMetaData, additionalValues } = aggregationResult;
  // this is to remove bad zero results from api search service
  // commented out for aggregated retailer view
  // if (groupByField.name === 'retailerId' && `${aggregationResult.fieldId}` !== `${retailer.id}`) {
  //   return null;
  // }
  let entityType = entityConfig.type;
  if (entityConfig.typeAliases) {
    // eslint-disable-next-line no-restricted-syntax
    for (const typeAlias of entityConfig.typeAliases) {
      if (additionalMetaData[typeAlias]) {
        entityType = typeAlias;
        break;
      }
    }
  }
  const unprocessedName =
    additionalMetaData.name ||
    (entityType && entityConfig.nameFieldName && additionalMetaData[entityType]
      ? additionalMetaData[entityType][entityConfig.nameFieldName]
      : groupByField.name === 'searchTerm' || groupByField.name === 'microSegment'
      ? aggregationResult.fieldId.replace(/_/g, ' ')
      : aggregationResult.fieldId);
  const name = unescapeHtmlEntities(unprocessedName);

  const dataPoint = {
    entity: {
      ...(entityType && additionalMetaData[entityType] ? additionalMetaData[entityType] : {}),
      ...entityConfig,
      name,
      id:
        groupByField.name === 'searchTerm' || groupByField.name === 'microSegment'
          ? aggregationResult.fieldId.replace(/_/g, ' ')
          : aggregationResult.fieldId
    },
    name
  };

  if (groupByFieldNames.length > 1) {
    dataPoint.entity[groupByFieldNames[1]] = additionalMetaData[groupByFieldNames[1]];
  }

  if (groupByFieldNames.length > 2) {
    dataPoint.entity[groupByFieldNames[2]] = additionalMetaData[groupByFieldNames[2]];
  }

  const dataPointCount = {
    entity: {
      ...(entityType && additionalMetaData[entityType] ? additionalMetaData[entityType] : {}),
      ...entityConfig,
      name:
        entityType && entityConfig.nameFieldName && additionalMetaData[entityType]
          ? additionalMetaData[entityType][entityConfig.nameFieldName]
          : '',
      id: aggregationResult.fieldId
    },
    name:
      entityType && entityConfig.nameFieldName && additionalMetaData[entityType]
        ? additionalMetaData[entityType][entityConfig.nameFieldName]
        : aggregationResult.fieldId
  };

  return { dataPoint, dataPointCount, additionalValues };
};

export const parseEntityMetricsForOneSearchRequest = (
  requestContext,
  searchRequestOverrides,
  apiRequest,
  apiResponse
) => {
  const { app, entity, retailer, inverseComparisonTimePeriod } = requestContext;
  const indexName = searchRequestOverrides.indexName || requestContext.indexName;
  const { currencySymbol, locale } = retailer;
  const groupByFieldNames = apiRequest.aggregations[0].groupByFieldName.split(',');
  const groupByField = INDEX_FIELDS.getField(
    app.name,
    indexName,
    apiRequest.aggregations[0].groupByFieldName,
    entity.type
  );
  if (!apiResponse.aggregations || !apiResponse.aggregations[`by_${groupByField.name}`]) {
    return {};
  }

  let groupByEntity = null;
  const entityConfig =
    groupByField.entity && ENTITIES[app.name][groupByField.entity.type]
      ? ENTITIES[app.name][groupByField.entity.type]
      : {};
  if (entityConfig.keyFieldName) {
    groupByEntity =
      ENTITIES[app.name][INDEX_FIELDS.getField(app.name, indexName, entityConfig.keyFieldName).entity.type];
  }

  const aggregationResults = apiResponse.aggregations[`by_${groupByField.name}`];
  const result = {};
  let requestRangeFilters = _get(apiRequest.aggregations, '[0].conditions.rangeFilters', []);
  if (
    requestRangeFilters.length === 0 ||
    requestRangeFilters.findIndex((x) => INDEX_FIELDS.isTimeSeriesField(x.fieldName)) < 0
  ) {
    requestRangeFilters = _get(apiRequest, 'conditions.rangeFilters', []);
  }
  const mainTimePeriodRangeFilter = requestRangeFilters.find((x) => INDEX_FIELDS.isTimeSeriesField(x.fieldName));
  if (!mainTimePeriodRangeFilter) {
    panic(
      `No \`${JSON.stringify(
        INDEX_FIELDS.getTimeSeriesFieldNames()
      )}\` aggregation condition range filter found on request id ${apiRequest.id}`
    );
  }
  const timePeriodFieldName = mainTimePeriodRangeFilter.fieldName;
  const mainTimePeriodRangeSuffix = `_${mainTimePeriodRangeFilter.minValue}_${mainTimePeriodRangeFilter.maxValue}`;
  let comparisonTimePeriodRangeSuffix = null;
  if (
    apiRequest.aggregations[0].comparisonRangeFilters &&
    apiRequest.aggregations[0].comparisonRangeFilters.length > 0
  ) {
    const comparisonTimePeriodRangeFilter = apiRequest.aggregations[0].comparisonRangeFilters.find((x) =>
      INDEX_FIELDS.isTimeSeriesField(x.fieldName)
    );
    comparisonTimePeriodRangeSuffix = `_${comparisonTimePeriodRangeFilter.minValue}_${comparisonTimePeriodRangeFilter.maxValue}`;
  }

  apiRequest.aggregations[0].aggregationFields.forEach((aggregationField) => {
    const metric = INDEX_FIELDS.getField(
      app.name,
      indexName,
      aggregationField.aggregateByFieldName,
      entity.type,
      groupByField.name
    );
    const dataPointCountMetric = INDEX_FIELDS.getField(
      app.name,
      indexName,
      'dataPointCount',
      entity.type,
      groupByField.name
    );
    metric.displayName = `${metric.displayName} by ${groupByEntity ? groupByEntity.displayName : ''}`;
    dataPointCountMetric.displayName = `${dataPointCountMetric.displayName} by ${
      groupByEntity ? groupByEntity.displayName : ''
    }`;
    const metricByEntity = _merge(
      {},
      {
        data: [],
        currencySymbol,
        locale,
        entity,
        retailer,
        groupByField,
        timePeriodFieldName,
        mainTimePeriodRangeSuffix,
        comparisonTimePeriodRangeSuffix
      },
      metric
    );
    const dataPointCountMetricByEntity = _merge(
      {
        data: [],
        currencySymbol,
        locale,
        entity,
        retailer,
        groupByField,
        timePeriodFieldName,
        mainTimePeriodRangeSuffix,
        comparisonTimePeriodRangeSuffix
      },
      dataPointCountMetric
    );

    const valueFieldNamePrefix = `${metric.name}_${metric.aggregationFunction}_`;

    // The indexes for `unitsSold` from the traffic index and `unitsSold` from the advertising index have the same
    // name but represent different values.  When we merge this data into card views, they override each other and
    // cause tons of issues.
    //
    // This special case renames `unitsSold` metrics on the advertising index to `adUnitsSold` to disambiguate them
    if (apiRequest.searchType === 'beacon-advertising') {
      metric.name = metric.name.replace('unitsSold', 'adUnitsSold');
      aggregationField.aggregateByFieldName = aggregationField.aggregateByFieldName.replace('unitsSold', 'adUnitsSold');
    }
    aggregationResults.forEach((aggregationResult) => {
      const parsedAggregation = parseSingleAggregationResult(
        { groupByField, entityConfig },
        aggregationResult,
        groupByFieldNames
      );
      if (!parsedAggregation) {
        return;
      }
      const { additionalValues, dataPoint, dataPointCount } = parsedAggregation;

      Object.keys(additionalValues).forEach((key) => {
        if (key.indexOf(valueFieldNamePrefix) !== 0) {
          return;
        }

        if (key.toLowerCase().includes('stars_stats_avg')) {
          // change avg to count if you want to show num of review
          dataPoint[key.replace(/(?:avg)?[sS]tars_stats_avg/g, 'value')] = additionalValues[key];
        }
        dataPoint[key.replace(valueFieldNamePrefix, '')] = additionalValues[key];

        if (key.replace(valueFieldNamePrefix, '').indexOf('count') === 0) {
          dataPointCount[key.replace(`${valueFieldNamePrefix}count`, 'value')] = additionalValues[key];
        }
      });

      if (metric.aggregationFunction === 'stats') {
        dataPoint[`sum${mainTimePeriodRangeSuffix}`] = dataPoint.sum;
        dataPoint[`avg${mainTimePeriodRangeSuffix}`] = dataPoint.avg;
        dataPoint[`min${mainTimePeriodRangeSuffix}`] = dataPoint.min;
        dataPoint[`max${mainTimePeriodRangeSuffix}`] = dataPoint.max;
      }
      dataPoint[`value${mainTimePeriodRangeSuffix}`] = dataPoint.value;
      dataPoint[`count${mainTimePeriodRangeSuffix}`] = dataPoint.count;
      dataPointCount[`value${mainTimePeriodRangeSuffix}`] = dataPointCount.value;
      dataPointCountMetricByEntity.data.push(dataPointCount);
      if (inverseComparisonTimePeriod) {
        dataPoint.value = dataPoint[`value${comparisonTimePeriodRangeSuffix}`];
        dataPoint.count = dataPoint[`count${comparisonTimePeriodRangeSuffix}`];
        dataPointCount[`value${mainTimePeriodRangeSuffix}`] = dataPointCount[`count${comparisonTimePeriodRangeSuffix}`];
      }

      // metric change calculation
      const comparisonValue = inverseComparisonTimePeriod
        ? dataPoint[`value${mainTimePeriodRangeSuffix}`]
        : dataPoint[`value${comparisonTimePeriodRangeSuffix}`];
      const mainValue = dataPoint.value;
      const { periodChange, percentChange } = computePercentChange(comparisonValue, mainValue);

      // This handles the special case for buy box win rate percentage.  The backend sends back percentages as a number
      // from 0 to 100, but the formatters in the cardview and other places expect it to be from 0 to 1.
      const valueMultiplier = metric.name === 'buyBoxWon' ? 0.01 : 1;

      const {
        brandName,
        brandId,
        categoryId,
        categoryName,
        id,
        retailerSkus,
        stacklineSku,
        subCategoryName,
        subCategoryId,
        breadCrumbSubCategoryName,
        breadCrumbSubCategoryId,
        breadCrumbNodeId,
        breadCrumbText
      } = dataPoint.entity;

      dataPoint.cardView = {
        brandId,
        brandName,
        secondaryDisplayTitle: dataPoint.name,
        secondarySubtitle: dataPoint.entity.subtitle,
        [`${metric.name}CurrentValue`]: dataPoint.value * valueMultiplier,
        [`${metric.name}PreviousValue`]: comparisonValue * valueMultiplier,
        [`${metric.name}PeriodChange`]: periodChange * valueMultiplier,
        [`${metric.name}PercentChange`]: percentChange,
        productId: id,
        retailerSku: retailerSkus,
        stacklineSku,
        categoryId,
        categoryName,
        subCategoryName,
        subCategoryId,
        breadCrumbSubCategoryName,
        breadCrumbSubCategoryId,
        breadCrumbNodeId,
        breadCrumbText,
        metricData: Object.assign({}, { currencySymbol, locale }, metric),
        availabilityStatusCode: dataPoint.entity.availabilityStatusCode || '',
        availabilityStatus: dataPoint.entity.availabilityStatus || ''
      };
      metricByEntity.data.push(dataPoint);
    });
    result[`${app.name}_${indexName}_${aggregationField.aggregateByFieldName}_by_${groupByField.name}`] =
      metricByEntity;
    result[`${app.name}_${indexName}_${aggregationField.aggregateByFieldName}_dataPointCount_by_${groupByField.name}`] =
      dataPointCountMetricByEntity;
  });

  return result;
};

function parseAdditionalEntityMetrics(action, metrics_by_group) {
  // if (
  //   action.apiRequest[0].searchType === 'beacon-buybox' &&
  //   action.apiRequest.length === 2 &&
  //   action.apiResponse.data.length === 2
  // ) {
  //   const { app } = action.requestContext;
  //   const indexName = action.searchRequestsOverrides[0].indexName || action.requestContext.indexName;
  //   // this is very special for buybox
  //   const numeratorKey = `wins_by_${action.apiRequest[0].aggregations[0].groupByFieldName}`;
  //   const numeratorKeyWithIndexName = `${app.name}_${indexName}_wins_by_${action.apiRequest[0].aggregations[0].groupByFieldName}`;
  //   const denominatorKey = `wins_by_${action.apiRequest[1].aggregations[0].groupByFieldName}`;
  //   const denominatorKeyWithIndexName = `${app.name}_${indexName}_wins_by_${action.apiRequest[1].aggregations[0].groupByFieldName}`;
  //   const denominatorGroupByFieldName = action.apiRequest[1].aggregations[0].groupByFieldName;
  //   const metricsByGroupDenominator = parseEntityMetricsForOneSearchRequest(
  //     action.requestContext,
  //     action.searchRequestsOverrides[1],
  //     action.apiRequest[1],
  //     action.apiResponse.data[1]
  //   );
  //   const denominator_metrics_by_entity = {};
  //   (metricsByGroupDenominator[denominatorKey] || metricsByGroupDenominator[denominatorKeyWithIndexName]).data.forEach(
  //     (dataPoint) => {
  //       if (denominatorGroupByFieldName === 'retailerId') {
  //         denominator_metrics_by_entity.retailerId = dataPoint;
  //       } else {
  //         denominator_metrics_by_entity[dataPoint.name] = dataPoint;
  //       }
  //     }
  //   );
  //   (metrics_by_group[numeratorKey] || metrics_by_group[numeratorKeyWithIndexName]).data.forEach((dataPoint) => {
  //     const denominatorDataPoint =
  //       denominatorGroupByFieldName === 'retailerId'
  //         ? denominator_metrics_by_entity.retailerId
  //         : denominator_metrics_by_entity[dataPoint.name];
  //     if (denominatorDataPoint && denominatorDataPoint.cardView) {
  //       if (denominatorDataPoint.cardView.winsCurrentValue) {
  //         dataPoint.cardView.winsCurrentValue /= denominatorDataPoint.cardView.winsCurrentValue;
  //       }
  //       if (denominatorDataPoint.cardView.winsPreviousValue) {
  //         dataPoint.cardView.winsPreviousValue /= denominatorDataPoint.cardView.winsPreviousValue;
  //       }
  //       if (denominatorDataPoint.cardView.winsCurrentValue && denominatorDataPoint.cardView.winsPreviousValue) {
  //         dataPoint.cardView.winsPeriodChange =
  //           dataPoint.cardView.winsCurrentValue - dataPoint.cardView.winsPreviousValue;
  //         dataPoint.cardView.winsPercentChange =
  //           dataPoint.cardView.winsPeriodChange / dataPoint.cardView.winsPreviousValue;
  //       }
  //     }

  //     Object.keys(dataPoint)
  //       .filter((key) => key.indexOf('value') === 0)
  //       .forEach((valueKey) => {
  //         if (denominatorDataPoint[valueKey] > 0) {
  //           dataPoint[valueKey] /= denominatorDataPoint[valueKey];
  //         }
  //       });
  //   });
  // }

  if (metrics_by_group.buyBoxWon_by_merchantName) {
    removeZeroValues(metrics_by_group.buyBoxWon_by_merchantName);
  }
}

/**
 * If `returnDocuments` is set to `true` on a request, the raw ElasticSearch documents will be returned in the response
 * under the key `documents`.  In the case that no aggregations are supplied by the entity table, this function will
 * parse the raw documents directly in order too build the card view rather than pulling data from the
 * `additionalMetaData` fields of the response.
 *
 * @param {object} action The Redux action that was initiated to make the request
 */
const parseEntityMetricsFromDocuments = (action) => {
  const { app, indexName, entity } = action.requestContext;
  const groupByField = INDEX_FIELDS.getField(app.name, indexName, entity.keyFieldName, entity.type);

  const entityConfig =
    groupByField.entity && ENTITIES[app.name][groupByField.entity.type]
      ? ENTITIES[app.name][groupByField.entity.type]
      : {};

  const { documents } = action.apiResponse.data[0];
  const documentData = documents.map((document) => {
    const unprocessedName = document.title;
    const name = unescapeHtmlEntities(unprocessedName);

    const dataPoint = {
      entity: {
        ...document,
        ...(entityConfig.type && document[entityConfig.type] ? document[entityConfig.type] : {}),
        ...entityConfig,
        name,
        id: document.id
      },
      name
    };

    dataPoint.cardView = buildBaseCardViewFromDataPoint(dataPoint);
    return dataPoint;
  });

  return { data: documentData };
};

function parseEntityMetricsForOneRequest({ requestContext, searchRequestOverrides, apiRequest, apiResponse }) {
  const requestAggregations = _get(apiRequest, 'aggregations');
  const { app, entity, retailer, inverseComparisonTimePeriod } = requestContext;
  const indexName = searchRequestOverrides.indexName || requestContext.indexName;
  const groupByFieldNames = requestAggregations[0].groupByFieldName.split(',');
  const groupByField = INDEX_FIELDS.getField(app.name, indexName, requestAggregations[0].groupByFieldName, entity.type);

  const entityConfig =
    groupByField.entity && ENTITIES[app.name][groupByField.entity.type]
      ? ENTITIES[app.name][groupByField.entity.type]
      : {};

  let groupByEntity = null;
  if (entityConfig.keyFieldName) {
    groupByEntity =
      ENTITIES[app.name][INDEX_FIELDS.getField(app.name, indexName, entityConfig.keyFieldName).entity.type];
  }

  let aggregationResults = _get(apiResponse, ['aggregations', `by_${groupByField.name}`], []);

  // sort weekDay
  if (groupByField.name === 'weekDay') {
    const weekDaySortOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    aggregationResults = aggregationResults.sort(
      (a, b) => weekDaySortOrder.indexOf(a.fieldId) - weekDaySortOrder.indexOf(b.fieldId)
    );
  }
  const result = {};
  let requestRangeFilters = _get(requestAggregations, '[0].conditions.rangeFilters', []);
  if (
    requestRangeFilters.length === 0 ||
    requestRangeFilters.findIndex((x) => INDEX_FIELDS.isTimeSeriesField(x.fieldName)) < 0
  ) {
    requestRangeFilters = _get(apiRequest, 'conditions.rangeFilters', []);
  }

  const mainTimePeriodRangeFilter = requestRangeFilters.find((x) => INDEX_FIELDS.isTimeSeriesField(x.fieldName));

  const timePeriodFieldName = mainTimePeriodRangeFilter.fieldName;
  const mainTimePeriodRangeSuffix = mainTimePeriodRangeFilter
    ? `_${mainTimePeriodRangeFilter.minValue}_${mainTimePeriodRangeFilter.maxValue}`
    : null;

  let comparisonTimePeriodRangeSuffix = null;
  if (!_isEmpty(requestAggregations[0].comparisonRangeFilters)) {
    const comparisonTimePeriodRangeFilter = requestAggregations[0].comparisonRangeFilters.find((x) =>
      INDEX_FIELDS.isTimeSeriesField(x.fieldName)
    );
    comparisonTimePeriodRangeSuffix = `_${comparisonTimePeriodRangeFilter.minValue}_${comparisonTimePeriodRangeFilter.maxValue}`;
  }

  const { currencySymbol, locale } = requestContext.retailer;
  requestAggregations[0].aggregationFields.forEach((aggregationField) => {
    const { function: requestAggregationFunction } =
      _get(apiRequest, 'aggregations[0].aggregationFields', []).find(
        propEq('aggregateByFieldName', aggregationField.aggregateByFieldName)
      ) || {};

    const defaultMetric = INDEX_FIELDS.getField(
      app.name,
      indexName,
      aggregationField.aggregateByFieldName,
      entity.type,
      groupByField.name
    );

    // Use the `aggregationFunction` from the request (if we can find it) to override the default.
    const metric = {
      ...defaultMetric,
      aggregationFunction: requestAggregationFunction || defaultMetric.aggregationFunction
    };

    const dataPointCountMetric = INDEX_FIELDS.getField(
      app.name,
      indexName,
      'dataPointCount',
      entity.type,
      groupByField.name
    );

    metric.displayName = `${metric.displayName} by ${groupByEntity ? groupByEntity.displayName : ''}`;
    dataPointCountMetric.displayName = `${dataPointCountMetric.displayName} by ${
      groupByEntity ? groupByEntity.displayName : ''
    }`;
    const metricByEntity = _merge(
      {},
      {
        data: [],
        currencySymbol,
        locale,
        entity,
        retailer,
        groupByField,
        timePeriodFieldName,
        mainTimePeriodRangeSuffix,
        comparisonTimePeriodRangeSuffix
      },
      metric
    );
    const dataPointCountMetricByEntity = _merge(
      {},
      {},
      {
        data: [],
        currencySymbol,
        locale,
        entity,
        retailer,
        groupByField,
        timePeriodFieldName,
        mainTimePeriodRangeSuffix,
        comparisonTimePeriodRangeSuffix
      },
      dataPointCountMetric
    );

    const valueFieldNamePrefix = `${metric.name}_${metric.aggregationFunction}_`;
    // TODO: make async via `setTimeout` or similar as to avoid locking up the UI while it runs
    aggregationResults.forEach((aggregationResult) => {
      const parsedAggregation = parseSingleAggregationResult(
        { groupByField, entityConfig },
        aggregationResult,
        groupByFieldNames
      );

      if (!parsedAggregation) {
        return;
      }
      const { additionalValues, dataPoint, dataPointCount } = parsedAggregation;

      if (app.name === 'advertising' && groupByField.name === 'targetingText') {
        dataPoint.entity.type = 'adTarget';
      }

      Object.entries(additionalValues).forEach(([key, val]) => {
        if (key.indexOf(valueFieldNamePrefix) !== 0) {
          return;
        }

        if (key.toLowerCase().includes('stars_stats_avg')) {
          // change avg to count if you want to show num of review
          dataPoint[key.replace(/(?:avg)?[sS]tars_stats_avg/g, 'value')] = additionalValues[key];
        }

        const keyWithoutPrefix = key.replace(valueFieldNamePrefix, '');
        dataPoint[keyWithoutPrefix] = val;
        if (keyWithoutPrefix.indexOf('count') === 0) {
          dataPointCount[key.replace(`${valueFieldNamePrefix}count`, 'value')] = val;
        }
      });

      if (metric.aggregationFunction === 'stats' && mainTimePeriodRangeSuffix) {
        dataPoint[`sum${mainTimePeriodRangeSuffix}`] = dataPoint.sum;
        dataPoint[`avg${mainTimePeriodRangeSuffix}`] = dataPoint.avg;
        dataPoint[`min${mainTimePeriodRangeSuffix}`] = dataPoint.min;
        dataPoint[`max${mainTimePeriodRangeSuffix}`] = dataPoint.max;
      }

      if (mainTimePeriodRangeSuffix) {
        dataPoint[`value${mainTimePeriodRangeSuffix}`] = dataPoint.value;
        dataPoint[`count${mainTimePeriodRangeSuffix}`] = dataPoint.count;
        dataPointCount[`value${mainTimePeriodRangeSuffix}`] = dataPointCount.value;
      }

      if (inverseComparisonTimePeriod) {
        dataPoint.value = dataPoint[`value${comparisonTimePeriodRangeSuffix}`];
        dataPoint.count = dataPoint[`count${comparisonTimePeriodRangeSuffix}`];
        dataPointCount[`value${mainTimePeriodRangeSuffix}`] = dataPointCount[`count${comparisonTimePeriodRangeSuffix}`];
      }

      dataPointCountMetricByEntity.data.push(dataPointCount);
      // metric change calculation
      const prevPeriodValue = inverseComparisonTimePeriod
        ? dataPoint[`value${mainTimePeriodRangeSuffix}`]
        : dataPoint[`value${comparisonTimePeriodRangeSuffix}`];
      const periodChange = dataPoint.value - (prevPeriodValue === undefined ? 0 : prevPeriodValue);
      const percentChange = prevPeriodValue > 0 ? periodChange / prevPeriodValue : dataPoint.value > 0 ? 10 : 0;
      const [curValKey, prevValKey, periodChangeKey, percentChangeKey] = [
        'CurrentValue',
        'PreviousValue',
        'PeriodChange',
        'PercentChange'
      ].map((suffix) => `${metric.name}${suffix}`);

      const {
        brandName,
        brandId,
        categoryId,
        categoryName,
        id,
        retailerSkus,
        stacklineSku,
        subCategoryName,
        subCategoryId,
        breadCrumbSubCategoryName,
        breadCrumbSubCategoryId,
        breadCrumbNodeId,
        breadCrumbText,
        campaignType,
        upc
      } = dataPoint.entity;

      const secondaryDisplayTitle = dataPoint.name;
      let translatedTitles;

      if (dataPoint.entity && dataPoint.entity.translatedTitles && dataPoint.entity.translatedTitles) {
        ({ translatedTitles } = dataPoint.entity);
      }

      dataPoint.cardView = {
        brandId,
        brandName,
        secondaryDisplayTitle,
        translatedTitles,
        secondarySubtitle: dataPoint.entity.subtitle,
        [curValKey]: dataPoint.value,
        [prevValKey]: prevPeriodValue,
        [periodChangeKey]: periodChange,
        [percentChangeKey]: percentChange,
        productId: id,
        retailerSku: retailerSkus,
        stacklineSku,
        categoryId,
        categoryName,
        subCategoryName,
        subCategoryId,
        breadCrumbSubCategoryName,
        breadCrumbSubCategoryId,
        breadCrumbNodeId,
        breadCrumbText,
        metricData: Object.assign({}, { currencySymbol, locale }, metric),
        availabilityStatusCode: dataPoint.entity.availabilityStatusCode || '',
        availabilityStatus: dataPoint.entity.availabilityStatus || '',
        campaignType,
        upc
      };
      metricByEntity.data.push(dataPoint);
    });

    result[`${aggregationField.aggregateByFieldName}_by_${groupByField.name}`] = metricByEntity;
    result[`dataPointCount_by_${groupByField.name}`] = dataPointCountMetricByEntity;
  });
  return result;
}

export function parseEntityMetrics(action) {
  const firstRequest = action.apiRequest[0];
  if (firstRequest.returnDocuments) {
    if (
      firstRequest.name.includes('entityGrid') &&
      _get(action, ['state', 'user', 'config', 'isStacklineSuperUser'], false) &&
      !firstRequest.processDocuments
    ) {
      const metrics = {
        documents: action.apiResponse.data[0].documents.map((doc) => {
          return {
            entity: doc,
            cardView: {
              ...doc,
              secondaryDisplayTitle: doc.title,
              retailerSku: doc.retailerSkus
            },
            name: doc.name
          };
        }),
        documentData: undefined
      };
      return metrics;
    }

    return {
      documents: action.apiResponse.data[0].documents,
      // The `processDocuments` flag is used to indicate that the returned documents should be parsed into
      // entity metrics directly rather than just storing the raw documents.
      documentData: firstRequest.processDocuments ? parseEntityMetricsFromDocuments(action) : undefined
    };
  }

  if (action.statePropertyName.includes('waterfallChart_For')) {
    return action.apiRequest.reduce(
      (acc, apiRequest, index) => ({
        ...acc,
        ...parseEntityMetricsForOneSearchRequest(
          action.requestContext,
          action.searchRequestsOverrides[index],
          apiRequest,
          action.apiResponse.data[index]
        )
      }),
      {}
    );
  }

  const requestAggregations = _get(action, 'apiRequest[0].aggregations');

  if (!requestAggregations || !Array.isArray(requestAggregations)) {
    return {};
  } else if (INDEX_FIELDS.isTimeSeriesField(action.apiRequest[0].aggregations[0].groupByFieldName)) {
    return parseEntityTimeSeriesMetrics(action);
  }

  const parsedMetrics = action.apiRequest.reduce((acc, request, index) => {
    const metrics_by_group = parseEntityMetricsForOneRequest({
      requestContext: { ...action.requestContext, user: _get(action, ['state', 'user']) },
      searchRequestOverrides: action.searchRequestsOverrides[index],
      apiRequest: request,
      apiResponse: action.apiResponse.data[index]
    });
    return { ...metrics_by_group, ...acc };
  }, {});
  computedDerivedMetrics(action, parsedMetrics);
  parseAdditionalEntityMetrics(action, parsedMetrics);
  return parsedMetrics;
}

export const parsePromotionMetrics = (action) => {
  const { documents } = action.apiResponse.data[0];
  const { retailer } = action.requestContext;
  let currentPrice = 0;
  let previousPrice = 0;
  let currentSales = 0;
  let previousSales = 0;
  const promotionDocuments = { data: [], totalPromotions: documents.length, retailer };
  const newDocuments = documents.map((item) => {
    const newItem = item;
    currentPrice += newItem.retailPrice;
    previousPrice += newItem.retailPrice - newItem.retailPriceChange;
    currentSales += newItem.retailSales;
    previousSales += newItem.retailSales - newItem.retailSalesChange;

    newItem.previousRetailPrice = newItem.retailPrice - newItem.retailPriceChange;
    newItem.previousRetailSales = newItem.retailSales - newItem.retailSalesChange;
    return item;
  });

  promotionDocuments.data.push(...newDocuments);

  const avgDiscountPricePercent = previousPrice !== 0 ? (currentPrice - previousPrice) / previousPrice : 0;
  const avgSalesLiftPercent = previousSales !== 0 ? (currentSales - previousSales) / previousSales : 0;
  promotionDocuments.totalDiscountChange = avgDiscountPricePercent;
  promotionDocuments.totalSalesLiftChange = avgSalesLiftPercent;
  promotionDocuments.currencySymbol = retailer.currencySymbol;
  promotionDocuments.locale = retailer.locale;
  return promotionDocuments;
};

export const parseReviewsMetrics = (action) => {
  const { apiResponse } = action;
  const { retailer } = action.requestContext;
  let reviewsMetrics = [];

  const responseAggregations = _get(apiResponse, 'data[0].aggregations');

  // Handles the review Cards
  if (apiResponse.data[0] && apiResponse.data[0].documents && apiResponse.data[0].documents.length > 0) {
    const { documents } = apiResponse.data[0];
    reviewsMetrics.push(...documents);
    return reviewsMetrics;
    // Handles the product cards
  } else if (!_isEmpty(_get(responseAggregations, 'by_stacklineSku'))) {
    const { by_stacklineSku: returnedProducts } = apiResponse.data[0].aggregations;
    const metric = INDEX_FIELDS.beacon.reviews.reviewTrends;
    const updatedProducts = returnedProducts.map((item) => {
      const { additionalValues, count } = item;
      // Parameterize
      const { stars_sum_count, stars_sum_value } = additionalValues;
      const calculatedSecondaryMetric = stars_sum_count > 0 ? stars_sum_value / stars_sum_count : 0;
      const { title, retailerSkus } = item.additionalMetaData.product;

      const picked = _pick(item.additionalMetaData.product, [
        'brandId',
        'brandName',
        'categoryName',
        'categoryId',
        'subCategoryId',
        'subCategoryName',
        'stacklineSku',
        'breadCrumbSubCategoryName',
        'breadCrumbSubCategoryId',
        'breadCrumbNodeId',
        'breadCrumbText'
      ]);

      const cardView = {
        ...picked,
        availabilityStatusCode: '',
        mainMetric: `${count} Reviews`,
        metricData: Object.assign({}, { currencySymbol: retailer.currencySymbol, locale: retailer.locale }, metric),
        retailerSku: retailerSkus,
        secondaryDisplayTitle: title,
        secondaryMetric: calculatedSecondaryMetric
      };
      return { ...item, cardView };
    });
    reviewsMetrics.push(...updatedProducts);
  } else if (!_isEmpty(_get(responseAggregations, 'by_retailerId'))) {
    return parseEntityMetrics(action);
  } else if (!_isEmpty(_get(responseAggregations, 'by_stars'))) {
    reviewsMetrics = { data: [] };
    const { by_stars } = apiResponse.data[0].aggregations;
    const totalReviewStars = by_stars.reduce((acc, curr) => acc + curr.value, 0);
    const totalReviews = by_stars.reduce((acc, curr) => acc + curr.count, 0);
    reviewsMetrics.title = totalReviews > 0 ? totalReviewStars / totalReviews : 0;
    const data = by_stars
      .map((star) => ({
        name: `${star.fieldId} ${
          star.fieldId === '1' ? star.fieldName.slice(0, star.fieldName.length - 1) : star.fieldName
        }`,
        fieldId: star.fieldId,
        y: star.count
      }))
      .sort((a, b) => b.fieldId - a.fieldId);
    reviewsMetrics.data.push(...data);
    return reviewsMetrics;
  } else {
    return parseEntityMetrics(action);
  }

  return reviewsMetrics;
};
