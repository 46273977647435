import types from './types';

const receiveAdPlatforms = (adPlatforms) => ({
  type: types.RECEIVE_ADPLATFORMS,
  adPlatforms,
  receivedAt: Date.now()
});

const clearAdPlatforms = () => ({
  type: types.CLEAR_ADPLATFORMS
});

export default {
  clearAdPlatforms,
  receiveAdPlatforms
};
