import axios from 'axios';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import _union from 'lodash/union';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';
import { Option } from 'funfix-core';
import { AppName } from 'sl-api-connector/types';

import Creators from './actions';
import { parseEntityMetrics } from './selectors';
import {
  replaceConflictingConditions,
  removeRetailPriceRangeFilters,
  removePromoTypeTermFilters
} from 'src/utils/conditions';
// import { error } from 'src/utils/mixpanel';
import { store } from 'src/main';
import { propEq, not } from 'src/utils/fp';
import { getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';
import { shouldShowCriteo } from 'src/utils/app';

export const { clearEntitySearchService, requestEntitySalesMetrics, receiveEntitySalesMetrics, requestAdditionalData } =
  Creators;

const baseAdvancedSearchRequest = {
  name: `Unknown`,
  id: `Unknown`,
  pageNumber: 1,
  pageSize: 1200,
  retailerId: -1,
  period: 'year',
  doAggregation: false,
  returnDocuments: false,
  conditions: {
    termFilters: [],
    rangeFilters: []
  },
  searchBy: 'parent',
  aggregations: null,
  sortFilter: null
};

// returns index of object with matching key and value from array if present, else -1
export const getFieldIndexFromArray = (array, fieldName, value) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][fieldName] && array[i][fieldName] === value) {
      return i;
    }
  }
  return -1;
};

const updateSearchTypeIndexName = (statePropertyName, app, entity, indexName) => {
  if (
    app.name === AppName.Advertising &&
    statePropertyName.startsWith('comparison_summaryMainChart') &&
    entity.type === 'product'
  ) {
    // comparing with product
    if (window.location.pathname.startsWith('/adTarget')) {
      return `${app.apiAppName}-adCampaignAdGroupProductTargetDailyMetrics`;
    }
    return `${app.apiAppName}-adCampaignAdGroupProductDailyMetrics`;
  }
  return `${app.apiAppName}-${indexName}`;
};

export function buildSearchRequests(statePropertyName, requestContext, searchRequestsOverrides) {
  const searchRequests = [];
  const searchRequestsOverridesWithAdditionalRequests = [];
  const isCriteoAllowed = shouldShowCriteo();
  const parentPlatform = getParentPlatform();
  searchRequestsOverrides.forEach((searchRequestOverrides) => {
    const { entity, retailer, app, indexName } = requestContext;

    const requestPayload = {
      searchType: `${app.apiAppName}-${indexName}`,
      name: `${statePropertyName}-${entity.type}-${entity.id}`,
      id: `${statePropertyName}-${entity.type}-${entity.id}`,
      retailerId: `${retailer.id}`,
      pageNumber: 1,
      doAggregation: true,
      aggregations: null
    };

    if (isCriteoAllowed && parentPlatform === PARENT_PLATFORMS.CRITEO) {
      requestPayload.parentPlatform = parentPlatform;
    }

    const request = _merge(_cloneDeep(baseAdvancedSearchRequest), requestPayload, searchRequestOverrides);
    // For Criteo queries we don't need retailer id. Workaround not being able to pass null or undefined. It has to be removed from ES query
    if (isCriteoAllowed && request.retailerId === 999999) {
      delete request.retailerId;
    }
    if (request.indexName) {
      request.searchType = updateSearchTypeIndexName(statePropertyName, app, entity, request.indexName);
      delete request.indexName;
    }
    if (request.dataFetchStrategy) {
      delete request.dataFetchStrategy;
    }

    if (indexName === 'multiretailer') {
      request.searchType = `${app.apiAppName}-multi-retailers`;
    }
    // sort by the first aggregation or any specific field
    const sortField = _get(request, 'aggregations[0].aggregationFields[0]');
    if (!searchRequestOverrides.sortFilter) {
      request.sortFilter = {
        sortFields: sortField ? [sortField] : []
      };
    }
    const additionalRequests = [request];
    additionalRequests.forEach((additionalRequest) => {
      if (app.name === 'advertising') {
        // add custom aggregation field when ad spend filter is present on admanager
        const computeFilters = _get(additionalRequest, 'aggregations[0].conditions.computeFilters', []);
        if (getFieldIndexFromArray(computeFilters, 'fieldName', 'spendComputed') !== -1) {
          const aggregationFields = _get(additionalRequest, 'aggregations[0].aggregationFields', []);
          const index = getFieldIndexFromArray(aggregationFields, 'aggregateByFieldName', 'spend');
          if (index !== -1) {
            additionalRequest.aggregations[0].aggregationFields.push({
              ...additionalRequest.aggregations[0].aggregationFields[index],
              aggregateByFieldName: 'spendComputed',
              function: 'computed',
              aggregateByFormula: 'spend_sum*1'
            });
          }
        }
      }
      searchRequests.push(additionalRequest);
      searchRequestsOverridesWithAdditionalRequests.push(searchRequestOverrides);
    });
  });
  return { searchRequests, searchRequestsOverridesWithAdditionalRequests };
}

export const requestEntityMetrics = (appName, apiRequest, cancelToken, email = '') => {
  const queryParams = Option.of(_get(apiRequest, [0, 'id']))
    .map((id) => `?_id=${id}`)
    .getOrElse('');

  const retailerId = Option.of(_get(apiRequest, [0, 'retailerId'])).getOrElse('1');

  return axios.post(`/api/${appName}/AdvancedSearch${queryParams}`, apiRequest, {
    cancelToken,
    headers: {
      'x-sl-ui-user-email': email,
      'sl-beacon-retailer-id': retailerId
    }
  });
};

const maybeOverrideAllRetailerFilter = (searchRequestOverride, appName) => {
  const {
    user: {
      config: { allWeekIdsByRetailerId, allRetailerIds }
    },
    retailer: { availableRetailers },
    filters: { retailer }
  } = store.getState();

  const weekRetailerKeys = Object.keys(allWeekIdsByRetailerId).filter((w) => w !== '0');
  const allRetailers = allRetailerIds.map((id) => String(id));
  let retailerIds = _intersection(weekRetailerKeys, allRetailers).filter((id) => {
    const retailerObj = availableRetailers.find((r) => r.id === id);
    if (retailerObj) {
      if (appName === 'beacon') {
        return retailerObj.supportedAppNames.includes('beacon');
      }
      if (appName === 'atlas') {
        return retailerObj.supportedAppNames.includes('atlas');
      }
    }

    return false;
  });

  if (searchRequestOverride.aggregations) {
    searchRequestOverride.aggregations.forEach((agg) => {
      if (agg.conditions) {
        agg.conditions.termFilters.forEach((tf) => {
          if (tf.fieldName === 'retailerId' && tf.values.length === 1 && tf.values.includes('0')) {
            if (retailer) {
              retailerIds = _intersection(retailerIds, retailer);
            }
            tf.values = retailerIds;
          }
        });
      }
    });
  }

  if (searchRequestOverride.conditions) {
    searchRequestOverride.conditions.termFilters.forEach((tf) => {
      if (tf.fieldName === 'retailerId' && tf.values.length === 1 && tf.values.includes('0')) {
        if (retailer) {
          retailerIds = _intersection(retailerIds, retailer);
        }
        tf.values = retailerIds;
      }
    });
  }

  return searchRequestOverride;
};

const maybeRemoveUselessCategoryFilters = (searchRequestOverride) => {
  // Don't try anything fancy if `nestedFilterConditions` are present
  if (
    !searchRequestOverride.conditions ||
    !searchRequestOverride.conditions.termFilters ||
    !_isEmpty(searchRequestOverride.conditions.nestedFilterConditions)
  ) {
    return searchRequestOverride;
  }

  const { user, categories, allSuperUserCategories } = store.getState();
  const isSuperUser = _get(user, ['config', 'isStacklineSuperUser'], false);
  const allCategories = isSuperUser ? allSuperUserCategories : categories;
  if (!allCategories || _isEmpty(allCategories)) {
    return searchRequestOverride;
  }

  const allInclusiveCatIdFilters = searchRequestOverride.conditions.termFilters.filter(
    (tf) => tf.fieldName === 'categoryId' && (!tf.condition || tf.condition === 'should' || tf.condition === 'must')
  );
  const subCategoryConditions = searchRequestOverride.conditions.termFilters.filter(
    (tf) => tf.fieldName === 'subCategoryId'
  );
  if (!_isEmpty(subCategoryConditions)) {
    const allParentCategoryIds = new Set();
    subCategoryConditions.forEach((tf) => {
      if (!tf.values) {
        return;
      }

      tf.values.forEach((rawId) => {
        const id = +rawId;
        const parentCategory = allCategories.find((cat) => cat.childEntities.find(propEq('id', id)));
        if (!parentCategory) {
          return;
        }
        allParentCategoryIds.add(+parentCategory.categoryId);
      });
    });

    allInclusiveCatIdFilters.forEach((tf) => {
      if (!tf.values) {
        return;
      }

      tf.values = tf.values.filter((id) => allParentCategoryIds.has(+id));
    });
  }

  const values = allInclusiveCatIdFilters.map((tf) =>
    Option.of(tf.values)
      .map((vals) => vals.map((val) => +val))
      .getOrElse([])
  );

  // Union used for superuser to make sure we get all categoryIds and not just the ones
  const inclusiveCategoryIdsIntersection = isSuperUser ? _union(...values) : _intersection(...values);

  // Reduce all categoryId term filters of type `must` or `should` into one
  const mergedCategoryIdFilter = {
    fieldName: 'categoryId',
    condition: 'should',
    values: [...inclusiveCategoryIdsIntersection]
  };
  searchRequestOverride.conditions.termFilters = [
    ...searchRequestOverride.conditions.termFilters.filter(not(propEq('fieldName', 'categoryId'))),
    mergedCategoryIdFilter
  ];
  return searchRequestOverride;
};

/**
 * There are some places in the application where filters/conditions are hard-coded as either `searchTerm*` or
 * `searchKeyword*`, and we're unable to tell them apart easily.  In order to make sure that the data is fetched
 * successfully, we replace all `searchTerm*` conditions with `searchKeyword` when querying the `beacon-advertising`
 * index.  This function handles doing that replacement.
 *
 * This also checks to see if we're currently on beacon and querying a set of indices that don't have `retailPrice`
 * fields.  If we are, they are removed since they cause the queries to return no results.
 *
 * @param {{ indexName: string }} requestContext
 * @param {{ conditions?: any; aggregations?: { conditions?: any }[] }} searchRequestOverride
 * @param { string } appName
 */
export const maybeCorrectConditions = (requestContext, searchRequestOverride, appName) => {
  searchRequestOverride = maybeRemoveUselessCategoryFilters(searchRequestOverride);

  const { indexName } = requestContext;

  // All Apps

  // remove promotype filters for non promotion indexes
  if (searchRequestOverride.conditions) {
    searchRequestOverride.conditions = removePromoTypeTermFilters(searchRequestOverride.conditions, indexName);
  }

  if (_isArray(searchRequestOverride.aggregations)) {
    searchRequestOverride.aggregations.forEach((agg) => {
      if (agg.conditions) {
        agg.conditions = removePromoTypeTermFilters(agg.conditions, indexName);
      }
    });
  }

  // Beacon specific
  if (
    appName === AppName.Beacon &&
    ['content', 'advertising', 'reviews', 'buybox', 'advertisingDisplay', 'chargebacks'].includes(
      requestContext.indexName
    )
  ) {
    if (searchRequestOverride.conditions) {
      searchRequestOverride.conditions = removeRetailPriceRangeFilters(searchRequestOverride.conditions);
    }

    if (_isArray(searchRequestOverride.aggregations)) {
      searchRequestOverride.aggregations.forEach((agg) => {
        if (agg.conditions) {
          agg.conditions = removeRetailPriceRangeFilters(agg.conditions);
        }
      });
    }
  }

  searchRequestOverride = maybeOverrideAllRetailerFilter(searchRequestOverride, appName);

  if (requestContext.indexName !== 'advertising') {
    return searchRequestOverride;
  }

  if (searchRequestOverride.conditions) {
    searchRequestOverride.conditions = replaceConflictingConditions(requestContext, searchRequestOverride.conditions);
  }

  if (_isArray(searchRequestOverride.aggregations)) {
    searchRequestOverride.aggregations.forEach((aggregation) => {
      const aggregationConditions = _get(aggregation, 'conditions');
      if (!aggregationConditions) {
        return;
      }

      aggregation.conditions = replaceConflictingConditions(requestContext, aggregationConditions);
    });
  }

  return searchRequestOverride;
};

function detectAndLogPartialApiResponse(apiResponse, apiRequest) {
  try {
    const apiResponseData = apiResponse.data[0];
    const apiResponseStats = apiResponseData.responseStats;
    if (apiResponseStats.timed_out || (apiResponseStats._shards && apiResponseStats._shards.failed > 0)) {
      // eslint-disable-next-line no-console
      console.log(
        `Received partial response for Request Id: '${apiResponseData.id}' Stats: ${JSON.stringify(apiResponseStats)}`
      );
    }
  } catch (e) {
    try {
      // eslint-disable-next-line no-console
      console.log(`Error checking response stats for Request Id '${apiRequest[0].id}'. Error: ${e}`);
    } catch (f) {
      // eslint-disable-next-line no-console
      console.log(`Error checking response stats. Error: ${f}`);
    }
  }
}

export const fetchEntityMetrics =
  (statePropertyName, requestContext, searchRequestsOverrides = [{}], cancellation, dontStoreInESS = false) =>
  async (dispatch, getState) => {
    const appName = getState().app.apiAppName;
    const { allWeekIdsByRetailerId, user } = getState();
    let existingStatePropertyValue = null;
    const [searchRequestOverrides] = searchRequestsOverrides;
    if (!dontStoreInESS) {
      if (searchRequestOverrides.pageNumber && searchRequestOverrides.pageNumber > 1) {
        existingStatePropertyValue = _cloneDeep(getState().entitySearchService[statePropertyName]);
        dispatch(requestAdditionalData());
      } else {
        // if `requestContext.mergeIfStatePropertyValueExists` is `true`, the existing value for the state key will NOT
        // be blanked before making this new request.
        dispatch(requestEntitySalesMetrics(statePropertyName, requestContext.mergeIfStatePropertyValueExists));
      }
    }

    const searchRequestsOverridesCorrected = searchRequestsOverrides.map((override) =>
      maybeCorrectConditions(requestContext, override, appName)
    );

    const searchRequestsOverridesParallel = searchRequestsOverridesCorrected.filter(
      (x) => !x.dataFetchStrategy || x.dataFetchStrategy === 'parallel'
    );
    const searchRequestsOverridesChained = searchRequestsOverridesCorrected.filter(
      (x) => x.dataFetchStrategy === 'chained'
    );
    const { searchRequests: apiRequest, searchRequestsOverridesWithAdditionalRequests } = buildSearchRequests(
      statePropertyName,
      requestContext,
      searchRequestsOverridesParallel
    );
    const { customResponseParser } = requestContext;

    const apiResponse = await requestEntityMetrics(appName, apiRequest, cancellation, _get(user, ['session', 'email']));

    detectAndLogPartialApiResponse(apiResponse, apiRequest);
    if (searchRequestsOverridesChained && searchRequestsOverridesChained.length > 0) {
      const additionalTermFilterValues = apiResponse.data[0].aggregations[
        `by_${apiRequest[0].aggregations[0].groupByFieldName}`
      ].map(({ fieldId }) => fieldId);
      searchRequestsOverridesChained.forEach((x) => {
        x.pageNumber = 1;
        x.conditions.termFilters.push({
          condition: 'should',
          fieldName: apiRequest[0].aggregations[0].groupByFieldName,
          values: additionalTermFilterValues
        });
      });
      const {
        searchRequests: apiRequestChained,
        searchRequestsOverridesWithAdditionalRequests: searchRequestsOverridesWithAdditionalRequestsChained
      } = buildSearchRequests(statePropertyName, requestContext, searchRequestsOverridesChained);
      const apiResponseForChained = await requestEntityMetrics(appName, apiRequestChained, cancellation);
      searchRequestsOverridesWithAdditionalRequestsChained.forEach(
        (searchRequestsOverridesWithAdditionalRequestChained) => {
          searchRequestsOverridesWithAdditionalRequests.push(searchRequestsOverridesWithAdditionalRequestChained);
        }
      );
      if (apiResponseForChained.data) {
        apiRequestChained.forEach((x) => {
          apiRequest.push(x);
        });
        apiResponseForChained.data.forEach((x) => {
          apiResponse.data.push(x);
        });
      }
    }
    const parser = customResponseParser || parseEntityMetrics;
    const statePropertyValue =
      parser({
        apiRequest,
        apiResponse,
        statePropertyName,
        requestContext,
        searchRequestsOverrides: searchRequestsOverridesWithAdditionalRequests,
        allWeekIdsByRetailerId,
        state: getState()
      }) || {};

    // if (typeof statePropertyValue === "object" && !Array.isArray(statePropertyValue) && statePropertyValue !== null) {
    if (typeof statePropertyValue === 'object') {
      statePropertyValue.apiRequest = apiRequest;
    }
    if (
      apiResponse.data[0] &&
      apiResponse.data[0].additionalResponseMetaData &&
      apiResponse.data[0].additionalResponseMetaData.skusWithAssignedCategories
    ) {
      statePropertyValue.classifiedSkus = apiResponse.data[0].additionalResponseMetaData.skusWithAssignedCategories;
    }
    if (requestContext.mergeIfStatePropertyValueExists && !dontStoreInESS) {
      existingStatePropertyValue = _cloneDeep(getState().entitySearchService[statePropertyName]);
    }
    if (
      existingStatePropertyValue &&
      existingStatePropertyValue.entitySearchServiceDataModelType &&
      existingStatePropertyValue.entitySearchServiceDataModelType === 'gridRows'
    ) {
      dispatch(receiveEntitySalesMetrics(statePropertyName, statePropertyValue));
    } else if (existingStatePropertyValue) {
      Object.keys(existingStatePropertyValue).forEach((key) => {
        if (statePropertyValue[key]) {
          if (statePropertyValue[key].data) {
            existingStatePropertyValue[key].data.push(...statePropertyValue[key].data);
          } else if (key === 'documents' && Array.isArray(existingStatePropertyValue[key])) {
            existingStatePropertyValue[key].push(...statePropertyValue[key]);
          }
        }
      });
      Object.keys(statePropertyValue).forEach((key) => {
        if (!existingStatePropertyValue[key]) {
          existingStatePropertyValue[key] = statePropertyValue[key];
        }
      });

      // Used only for promotion grid
      if (statePropertyName.includes('promotionsGridMetrics')) {
        existingStatePropertyValue.data.push(...statePropertyValue.data);
      }
      // Used only for reviews to append additional reviews to redux instead of replacing the entire object
      if (statePropertyName.includes('reviewsGrid')) {
        existingStatePropertyValue.push(...statePropertyValue);
        existingStatePropertyValue.sort((a, b) => b.timestamp - a.timestamp);
      }
      dispatch(receiveEntitySalesMetrics(statePropertyName, existingStatePropertyValue));
    } else if (!dontStoreInESS) {
      dispatch(receiveEntitySalesMetrics(statePropertyName, statePropertyValue));
    }

    return statePropertyValue;
  };
