import React, { useMemo } from 'react';
import useForecastMetricByWeekId from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useForecastMetricByWeekId';
import { useMetricByWeekId } from 'src/serverProxy/useMetricByWeekId';
import BeaconChartWithLegend from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import SplineChart from 'src/components/BeaconRedesignComponents/SplineChart/SplineChart';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import {
  useForecastComparisonPeriod,
  useForecastStartAndEndWeekIds,
  useYtdStartAndEndWeekIdsForForecast
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import { formatWeekIdsForAdjustmentDisplay, getPreviousWeekId, getPreviousYearWeekId } from 'src/utils/dateUtils';
import SplineChartLoading from 'src/components/BeaconRedesignComponents/SplineChartLoading/SplineChartLoading';
import _get from 'lodash/get';
import { useMetricFormatter } from 'src/utils/Hooks';
import { calculatePercentChange, safeDivide } from 'src/utils/app';
import { getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import {
  ForecastComparisonPeriod,
  ForecastPeriod
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import convertLineChartSeriesToDelimitedData, {
  Series
} from 'src/components/Charts/GenericChart/SeriesConverters/lineChart';
import saveAs from 'file-saver';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import {
  CustomTooltip,
  TooltipBox,
  TooltipIconComponent
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/ProductGrowthPage';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { useStacklineTheme } from '@stackline/ui';

const createRunningTotalArray = (data: number[][]) => {
  return data.reduce((acc, [_, value]) => {
    const lastValue = acc[acc.length - 1] || 0;
    return [...acc, lastValue + value];
  }, [] as number[]);
};

const createWeeksOnHandSplineValues = (unitsOnHand: number[][], unitsSold: number[][]) => {
  return unitsOnHand.map(([timestamp, onHand], index) => {
    const cumulativeUnitsSold = createRunningTotalArray(unitsSold.slice(index));

    // First week where units on hand is not enough to fill running total of units sold
    const weeksOnHandIndex = cumulativeUnitsSold.findIndex((i) => i >= onHand);

    // Means we can fill all units sold for every week in the data set
    if (weeksOnHandIndex === -1) {
      return [timestamp, unitsOnHand.length - index];
    }

    const lastFullWeekIndex = Math.max(weeksOnHandIndex - 1, 0); // Last week where units on hand can fill 100% of units sold so far
    const partialAvailableWeek = safeDivide(
      onHand - cumulativeUnitsSold[lastFullWeekIndex],
      _get(unitsSold, [index + weeksOnHandIndex, 1], 0)
    );
    return [timestamp, weeksOnHandIndex + partialAvailableWeek];
  });
};

/**
 * This chart answers the question: "How many weeks of inventory do I have on hand?"
 * We calculate this by making a running total of units sold (including forecasted units sold)
 * and then finding the week where the units on hand is less than or equal to the running total.
 */
export default function ForecastWeeksOnHandChart() {
  const theme = useStacklineTheme();
  const comparisonPeriod = useForecastComparisonPeriod();
  const isPriorPeriod = comparisonPeriod === ForecastComparisonPeriod.PRIOR_PERIOD;
  const formatMetric = useMetricFormatter();
  const { startWeekId, endWeekId } = useYtdStartAndEndWeekIdsForForecast();
  const comparisonStartWeekId = getPreviousYearWeekId(startWeekId);
  const comparisonEndWeekId = getPreviousWeekId(startWeekId);

  const { formattedEndDate, formattedStartDate } = formatWeekIdsForAdjustmentDisplay({ startWeekId, endWeekId });
  const { formattedEndDate: comparisonFormattedEndDate, formattedStartDate: comparisonFormattedStartDate } =
    formatWeekIdsForAdjustmentDisplay({ startWeekId: comparisonStartWeekId, endWeekId: comparisonEndWeekId });

  const { startWeekId: forecastStartWeekId, endWeekId: forecastEndWeekId } = useForecastStartAndEndWeekIds({
    forecastPeriodOverride: ForecastPeriod.FULL_YEAR
  });

  /**
   * Request builder for unitsSold and unitsOnHand.
   * Always uses YTD for start and end week + comparison because
   * we don't forecast unitsOnHand.
   */
  const requestBuilder = (builder: AdvancedSearchRequestBuilder, comparison: boolean) => {
    const startWeekIdToUse = comparison ? comparisonStartWeekId : startWeekId;
    const endWeekIdToUse = comparison ? comparisonEndWeekId : endWeekId;

    const newBuilder = builder
      .replaceConditionRangeFilter('weekId', startWeekIdToUse, endWeekIdToUse)
      .modifyAggregation((aggBuilder: any) =>
        aggBuilder.replaceConditionRangeFilter('weekId', startWeekIdToUse, endWeekIdToUse)
      );

    return newBuilder;
  };

  const {
    forecastData: forecastUnitsSold,
    primaryData: unitsSold,
    secondaryData: unitsSoldComparison,
    isLoading: unitsSoldLoading
  } = useForecastMetricByWeekId({
    fieldName: 'unitsSold',
    indexName: 'sales',
    requestBuilder,
    useKeyMetricForecastSummaryDataArgs: {
      buildRequest: (builder) =>
        builder.replaceConditionRangeFilter('forecastWeekId', forecastStartWeekId, forecastEndWeekId)
    },
    forecastPeriodOverride: ForecastPeriod.FULL_YEAR
  });

  /**
   * Combine units sold and forecasted units sold. Filter out duplicates because the forecast
   * model can be behind the current published week, so we may have duplicate weeks.
   */
  const combinedUnitsSold = useMemo(
    () =>
      [...unitsSold, ...forecastUnitsSold].reduce((acc, [timestamp, value]) => {
        if (!acc.map(([t]) => t).includes(timestamp)) {
          return [...acc, [timestamp, value]];
        }
        return acc;
      }, [] as number[][]),
    [forecastUnitsSold, unitsSold]
  );

  const {
    primaryData: unitsOnHand,
    secondaryData: unitsOnHandComparison,
    isLoading: unitsOnHandLoading
  } = useMetricByWeekId({
    fieldName: 'unitsOnHand',
    indexName: 'sales',
    requestBuilder
  });

  const primaryWeeksOnHand = createWeeksOnHandSplineValues(unitsOnHand, combinedUnitsSold);
  const comparisonWeeksOnHand = createWeeksOnHandSplineValues(unitsOnHandComparison, [
    ...unitsSoldComparison,
    ...combinedUnitsSold
  ]);

  const primaryLegendValue = _get(primaryWeeksOnHand, [primaryWeeksOnHand.length - 1, 1], 0);

  const comparisonLegendValue = useMemo(() => {
    const primaryTimestamp = _get(primaryWeeksOnHand, [primaryWeeksOnHand.length - 1, 0], -1);
    const comparisonIndex = comparisonWeeksOnHand.findIndex(([timestamp]) => timestamp === primaryTimestamp);
    return isPriorPeriod
      ? _get(comparisonWeeksOnHand, [comparisonWeeksOnHand.length - 1, 1], 0) // last value of comparison if prior period
      : _get(comparisonWeeksOnHand, [comparisonIndex, 1], 0);
  }, [comparisonWeeksOnHand, isPriorPeriod, primaryWeeksOnHand]);

  const percentChange = calculatePercentChange(primaryLegendValue, comparisonLegendValue);

  return (
    <BeaconChartWithLegend
      title={
        <Flex gap="sm" alignItems="center">
          <Text variant="h4">Weeks on Hand - Projected Run Rate</Text>
          <CustomTooltip
            title={<TooltipBox description="This chart only displays YTD data." />}
            stacklineTheme={theme}
            placement="right"
          >
            <TooltipIconComponent style={{ width: theme.spacing.md }} />
          </CustomTooltip>
        </Flex>
      }
      loading={unitsSoldLoading || unitsOnHandLoading}
      loadingComponent={<SplineChartLoading />}
      primaryLegendProps={{
        metric: `${formatMetric(primaryLegendValue, METRICTYPE.DECIMAL, { decimalPlaces: 2 })}`,
        change: `${formatMetric(percentChange, METRICTYPE.PERCENT, {
          decimalPlaces: 2
        })}`,
        direction: getDirection(percentChange),
        displayName: 'Full Year'
      }}
      comparisonLegendProps={{
        metric: `${formatMetric(comparisonLegendValue, METRICTYPE.DECIMAL, { decimalPlaces: 2 })}`,
        displayName: 'Prior Year'
      }}
      convertSeriesToDelimitedData={() => {
        try {
          const data = convertLineChartSeriesToDelimitedData(
            [
              {
                type: 'spline',
                name: `${formattedStartDate} - ${formattedEndDate}`,
                data: primaryWeeksOnHand.map(([x, y]) => ({ x, y }))
              },
              {
                type: 'spline',
                name: `${comparisonFormattedStartDate} - ${comparisonFormattedEndDate}`,
                data: comparisonWeeksOnHand.map(([x, y]) => ({ x, y }))
              }
            ] as Series[],
            isPriorPeriod
          );
          const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
          saveAs(blob, `Weeks on Hand - Projected Run Rate.csv`);
        } catch (e) {
          console.error('error saving forecast weeks on hand - projected run rate as CSV: ', e);
        }

        return Promise.resolve();
      }}
    >
      <SplineChart
        metricType={METRICTYPE.DECIMAL}
        primaryData={
          isPriorPeriod
            ? [_get(comparisonWeeksOnHand, comparisonWeeksOnHand.length - 1, []), ...primaryWeeksOnHand]
            : primaryWeeksOnHand
        }
        secondaryData={comparisonWeeksOnHand}
      />
    </BeaconChartWithLegend>
  );
}
