import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _isEqual from 'lodash/isEqual';
import queryString from 'qs';
import { anyNotEq, keyNotEq } from 'src/utils/equality';
import _omit from 'lodash/omit';

const { CancelToken } = axios;

class BaseTrendChart extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    eventBus: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    allWeekIdsByRetailerId: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    aggregationConditions: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired,
    entitySearchService: PropTypes.object.isRequired,
    conditions: PropTypes.object,
    queryConditions: PropTypes.object,
    widget: PropTypes.object.isRequired,
    comparisonConfig: PropTypes.object.isRequired,
    queryParams: PropTypes.object.isRequired
  };

  static defaultProps = {
    queryConditions: {},
    conditions: undefined
  };

  state = {
    isLoading: true
  };

  componentDidMount() {
    this.cancelSource = CancelToken.source();
    this.fetchData(this.props);
    this.addEventListeners();
  }

  componentWillReceiveProps(nextProps) {
    const propKeysToCompare = [
      'location.pathname',
      // TODO, 'location.search' and 'entityService.mainEntity' are causing double api fetching, and when 'location.pathname' change, 'entityService.mainEntity' is the old main entity.
      // 'location.search',
      'conditions',
      'filters',
      'entityService.mainEntity',
      'entityService.comparisonEntity',
      'widget'
    ];
    const currentSearchParam = queryString.parse(this.props.location.search);
    const nextSearchParam = queryString.parse(nextProps.location.search);
    delete currentSearchParam.groupByField;
    delete nextSearchParam.groupByField;
    if (
      anyNotEq(
        propKeysToCompare,
        {
          ...this.props,
          filters: _omit(this.props.filters, 'isClearingFilters')
        },
        {
          ...nextProps,
          filters: _omit(nextProps.filters, 'isClearingFilters')
        }
      ) ||
      !_isEqual(currentSearchParam, nextSearchParam)
    ) {
      if (typeof this.cancelSource !== typeof undefined) {
        this.cancelSource.cancel('Canceled network request: base');
      }
      this.cancelSource = CancelToken.source();
      this.fetchData(nextProps);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_isEqual(this.state, nextState)) {
      return true;
    }
    const { entitySearchService, widget } = this.props;
    const { entitySearchService: nextEntitySearchService, widget: nextWidget } = nextProps;
    const { entitySearchServiceStateNamesMainEntity = [], entitySearchServiceStateNamesComparisonEntity = [] } =
      this.state;
    if (!_isEqual(widget, nextWidget)) {
      return true;
    }
    return !![...entitySearchServiceStateNamesMainEntity, ...entitySearchServiceStateNamesComparisonEntity].find(
      (stateName) => keyNotEq(stateName, entitySearchService, nextEntitySearchService)
    );
  }

  componentWillUnmount() {
    if (typeof this.cancelSource !== typeof undefined) {
      this.cancelSource.cancel('Cancel network request');
    }
    this.removeEventListeners();
  }

  addEventListeners = () => {
    const { eventListeners } = this.props.widget.view;
    const { eventBus } = this.props;
    if (eventListeners && eventListeners.length > 0) {
      eventListeners.forEach((event) => {
        eventBus.on(event.name, this.fetchData);
      });
    }
  };

  removeEventListeners = () => {
    const { eventListeners } = this.props.widget.view;
    const { eventBus } = this.props;
    if (eventListeners && eventListeners.length > 0) {
      eventListeners.forEach((event) => {
        eventBus.off(event.name, this.fetchData);
      });
    }
  };

  shouldShowLoading = () => this.state.isLoading;
}

export default BaseTrendChart;
