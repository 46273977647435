import React from 'react';
import PropTypes from 'prop-types';
// import AdManagerSpace from '!svg-react-loader!./SignInAdManagerSpace.svg'; // eslint-disable-line
// import AdManagerMontains from '!svg-react-loader!./MountainsAdManager.svg'; // eslint-disable-line
import StarrySky from 'src/components/SignIn/StarrySky/StarrySky';

import anime from 'animejs';

import shakeTranslations from '../shakeTranslations';
import './AdvertisingSignInContainer.scss';
import { NotificationContext } from 'src/providers/NotificationProvider';

class AdvertisingSignInContainer extends React.Component {
  static contextType = NotificationContext;

  static defaultProps = {
    notificationMSG: ''
  };

  static propTypes = {
    notificationMSG: PropTypes.string,
    appName: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
  };

  state = {
    notificationMSG: ''
  };

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    const showZoomInAnimation = document.querySelector('.signin-form');
    if (showZoomInAnimation) {
      this.zoomIn();
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
    anime.remove(`.adManager-signin-form-container form, #Particles circle, #Particles rect`);
  }

  setNotification = (msg) => {
    this.setState({ notificationMSG: msg });
  };

  zoomIn = () => {
    anime({
      targets: '.cross3',
      top: [
        {
          value: ['163vh', '-37vh'],
          duration: 1430,
          easing: 'easeOutCubic'
        }
      ],
      right: [
        {
          value: ['-401vh', '-101vh'],
          duration: 1430,
          easing: 'easeOutCubic'
        }
      ]
    });
    anime({
      targets: '.cross7',
      bottom: [
        {
          value: ['181vh', '-19vh'],
          duration: 1430,
          easing: 'easeOutCubic'
        }
      ],
      left: [
        {
          value: ['-402vh', '-102vh'],
          duration: 1430,
          easing: 'easeOutCubic'
        }
      ]
    });
    anime({
      targets: '.cross1,.cross2,.cross4,.cross5,.cross6,.cross8,.reg_star',
      opacity: [
        {
          value: ['0', '1'],
          duration: 3430,
          delay: 1000,
          easing: 'easeOutCubic'
        }
      ]
    });
  };

  shakeLoginBox = () => {
    const shakeky = 2;
    anime({
      targets: `.adManager-signin-form-container form`,
      easing: 'easeInOutSine',
      duration: 550,
      translateX: shakeTranslations(shakeky)
    });
  };

  render() {
    const { children, appName } = this.props;
    const notificationMSG = this.state.notificationMSG || this.props.notificationMSG;

    return (
      <div className="adManager-signin-container">
        <div className="adManager-sence">
          <StarrySky appName={appName} />
        </div>
        <div className="adManager-signin-form-container">{children}</div>

        {this.context.showNotification({ message: notificationMSG, header: 'Error', open: !!notificationMSG })}
      </div>
    );
  }
}

export default AdvertisingSignInContainer;
