import React, { useMemo, useState } from 'react';
import SegmentNoDataPlaceholder from './SegmentNoDataPlaceholder';
import { SearchTextField } from 'src/components/BeaconRedesignComponents/SearchInput/SearchInputBar';
import { useStacklineTheme } from '@stackline/ui';
import { useAppSelector } from 'src/utils/Hooks';
import BusinessUnitTable from './BusinessUnitTable';
import Box from '@mui/material/Box';
import { EMPTY_BUSINESS_UNIT_NAME } from './utils';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

/**
 * Business units tab on the segments page
 */
const BusinessUnitsTab = () => {
  const [value, setValue] = useState('');
  const businessUnits = useAppSelector((state) => state.segments.businessUnits);
  const theme = useStacklineTheme();

  const filteredBusinessUnits = useMemo(() => {
    return businessUnits.filter((unit) =>
      (unit.displayName || EMPTY_BUSINESS_UNIT_NAME).toLowerCase().includes(value.toLowerCase())
    );
  }, [businessUnits, value]);

  return (
    <Flex flexDirection="column">
      <SearchTextField
        value={value}
        onChange={(event) => setValue(event.target.value)}
        placeholder="Search business units"
      />
      {filteredBusinessUnits.length < 1 && (
        <Box marginTop={theme.spacing.xxl}>
          <Flex justifyContent="center">
            <SegmentNoDataPlaceholder
              title="No business units found"
              subtitle="There are currently no business units to display. All your business units will be available on this page."
            />
          </Flex>
        </Box>
      )}
      {filteredBusinessUnits.length > 0 && (
        <Box marginTop={theme.spacing.lg}>
          <Flex flexDirection="column" gap={84}>
            {[...filteredBusinessUnits]
              .sort((a, b) => b.children.length - a.children.length) // Show units with most segments first
              .map((businessUnit) => (
                <BusinessUnitTable key={businessUnit.id} businessUnit={businessUnit} />
              ))}
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default BusinessUnitsTab;
