import { brandclub_colors } from '@stackline/ui';
import { BEACON_PRO_CENTER_WIDTH, BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { shouldShowNewBeacon } from 'src/utils/app';
import React from 'react';

const sectionBottomMargin = 150;

const styles = {
  sectionBottomMargin,
  pageCenterDiv: {
    margin: `0 auto`,
    width: BEACON_PRO_CENTER_WIDTH,
    boxSizing: 'border-box'
  },
  customScrollbar: {
    '&::-webkit-scrollbar': {
      height: 5,
      width: 5,
      backgroundColor: 'transparent',
      borderRadius: 20,
      position: 'absolute',
      zIndex: 99999
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: brandclub_colors.darkBlue1,
      borderRadius: 20
    }
  },
  hiddenScrollbar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  clampTextOverflow1: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1
  },
  clampTextOverflow2: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2
  },
  textLink: {
    textDecoration: 'none',
    color: brandclub_colors.darkBlue,
    ':hover': {
      textDecoration: 'underline'
    }
  },
  title: {},
  subtitle: {},
  hyperlink: {},
  section: {}
} as const;

export default styles;

export const getWidgetBarChartStyles = (overrides: React.CSSProperties = {}) =>
  shouldShowNewBeacon()
    ? {
        marginTop: `${BEACON_CHART_SPACING}px`,
        marginBottom: `${BEACON_CHART_SPACING}px`,
        ...overrides
      }
    : undefined;
