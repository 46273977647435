import React from 'react';
import PropTypes from 'prop-types';
import _isString from 'lodash/isString';

import { cellRendererFrameworkPropTypes } from '.';
import CustomAgGridHeaderTemplate from 'src/components/EntityGrid/Table/CustomAgGridHeaderTemplate';
import 'src/components/EntityGrid/HeaderComponentFrameworks/GridHeaderOverflow.scss';
/**
 * Inserts a newline between the first word and the rest of the words in the `headerContent` prop and renders it
 *
 * @param {object} props
 */
export const LineBreakHeaderContent = ({ headerContent, ...rest }) => {
  if (!_isString(headerContent)) {
    return headerContent;
  }
  const [firstWord, ...restOfWords] = headerContent.split(' ');

  const content =
    restOfWords.length === 0 ? (
      <span style={{ display: 'inline-block', marginTop: '1em' }}>{firstWord}</span>
    ) : (
      <>
        {firstWord}
        <br />
        {restOfWords.join(' ')}
      </>
    );

  return <span {...rest}>{content}</span>;
};

LineBreakHeaderContent.propTypes = {
  headerContent: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired
  ]).isRequired
};

export const EntityGridNoclipHeaderWrapper = ({ children, style }) => (
  <div className="entity-grid-noclip-header" style={style}>
    {children}
  </div>
);

EntityGridNoclipHeaderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object
};

/**
 * AG Grid column header that renders the default `CustomAgGridHeaderTemplate` after inserting a newline between the
 * first word and the rest of the words of the `displayName`.
 *
 * @param {object} props
 */
const MultiLineHCF = ({ displayName, ...props }) => (
  <CustomAgGridHeaderTemplate {...props} displayName={<LineBreakHeaderContent headerContent={displayName} />} />
);

MultiLineHCF.propTypes = cellRendererFrameworkPropTypes;

export default MultiLineHCF;
