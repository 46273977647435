import { ValueOf } from 'sl-api-connector/types';

import types, { AdScores } from './types';
import actionCreators from './actions';

type Action = ValueOf<{ [K in keyof typeof actionCreators]: ReturnType<(typeof actionCreators)[K]> }>;

export function adScores(state = {} as AdScores, action: Action) {
  switch (action.type) {
    case types.RECEIVE_ADSCORES:
      return action.adScores;
    case types.CLEAR_ADSCORES:
      return [];
    default:
      return state;
  }
}
