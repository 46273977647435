import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import Loading from 'src/components/common/Loading';
import { CardViewOmniGeoProps } from 'src/components/Omni/OmniGeoMap/OmniGeoCardViewRenewal/CardViewOmniGeoRenewal';
import { omniGeoMapServiceOperations } from 'src/store/modules/omni/omniGeoMap';
import { OmniMapDataMetric } from 'src/components/Omni/OmniGeoMap/OmniGeoMapContainerRenewal';
import { OmniGeoMapServiceBody } from 'src/store/modules/omni/omniGeoMap/operations';
import CardViewContainerRenewal from 'src/components/Omni/OmniGeoMap/OmniGeoCardViewRenewal/CardViewContainerRenewal';
import OmniGeoCardHeader from 'src/components/Omni/OmniGeoMap/OmniGeoCardView/OmniGeoCardHeader';
import OmniCardViewSearch from 'src/components/Omni/OmniGeoMap/OmniGeoCardView/OmniCardViewSearch';
import OmniDropDownOption from 'src/components/Omni/OmniDropDownOption/OmniDropDownOption';
import { getWeekLastDate } from 'src/utils/dateformatting';
import _capitalize from 'lodash/capitalize';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';
import { trackTheDataSwitching } from 'src/utils/mixpanel';
import { addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';

import { RouteComponentProps } from 'react-router-dom';
import {
  dropDownGenerator,
  groupByGenerator,
  payloadGenerator,
  groupByGeneratorForCard
} from 'src/components/Omni/OmniGeoMap/geoMapUtils';

interface OmniGeoCardPartContainerProps {
  name: string;
  metricFroData: OmniMapDataMetric;
  curCardContainerTab: { name: string; displayName: string };
  tabsForCardContainer: { name: string; displayName: string }[];
  startWeek: number;
  endWeek: number;
  handleCardContainerTabChange: (tabIndex: number) => void;
  comparisonTimePeriod: ReduxStore['comparisonTimePeriod'];
  location: RouteComponentProps['location'];
  user: ReduxStore['user'];
  chartSeries: { 'hc-key': string; value: number; preValue?: number }[];
  mapName: string;
  mapHistory: string[];
  currentSelectedHCKey: string;
}
const { fetchOmniGeoMapServiceData } = omniGeoMapServiceOperations;

const OmniGeoCardPartContainer: React.FC<OmniGeoCardPartContainerProps> = ({
  name,
  metricFroData,
  tabsForCardContainer,
  handleCardContainerTabChange,
  curCardContainerTab,
  startWeek,
  endWeek,
  comparisonTimePeriod,
  location,
  user,
  chartSeries,
  mapName,
  currentSelectedHCKey
}: OmniGeoCardPartContainerProps) => {
  const dispatch = useDispatch();

  const [dropDownValueForCard, setDropDownValueForCard] = useState(groupByGenerator(mapName));

  const [currentSearchKey, setCurrentSearchKey] = useState('');
  const geoDataSeparateByWeek = useSelector(
    (store: ReduxStore) => store.omniGeoMapService[`${name}byWeek${dropDownValueForCard}Card`]
  );

  const filters = useSelector((state: ReduxStore) => state.filters);
  const geoDataSeparateByWeekData = geoDataSeparateByWeek ? geoDataSeparateByWeek.data : [];
  const isFetchingGeoDataByWeek = geoDataSeparateByWeek ? geoDataSeparateByWeek.isFetching : true;
  const geoDataSeparateByWeekPrePeriod = useSelector(
    (store: ReduxStore) => store.omniGeoMapService[`${name}byWeekPrePeriod${dropDownValueForCard}Card`]
  );

  const { startWeek: preStartWeek, endWeek: preEndWeek } = comparisonTimePeriod;

  const geoDataSeparateByWeekDataPrePeriod = geoDataSeparateByWeekPrePeriod ? geoDataSeparateByWeekPrePeriod.data : [];
  const isFetchingGeoDataByWeekPrePeriod = geoDataSeparateByWeekPrePeriod
    ? geoDataSeparateByWeekPrePeriod.isFetching
    : true;

  const dropDownOption = dropDownGenerator(mapName);

  let displayNameForDropDownOPT = '';
  const dropDownOptionObj = dropDownOption.find((d) => d.name === dropDownValueForCard);
  if (dropDownOptionObj) {
    displayNameForDropDownOPT = dropDownOptionObj.displayName;
  }

  if (dropDownValueForCard === 'locationCountyCode') {
    displayNameForDropDownOPT = 'County';
  }

  const fetchData = useCallback(() => {
    trackTheDataSwitching(`${displayNameForDropDownOPT}`, location, user, { displayNameForDropDownOPT });

    const baseRequestBody: OmniGeoMapServiceBody = {
      retailerIds: [],
      includeBrandIds: [],
      includeCategoryIds: [],
      includeSubCategoryIds: [],
      includeLocationRegionCode: [],
      includeLocationCountryCode: [],
      includeLocationState: [],
      startWeekId: startWeek,
      endWeekId: endWeek,
      groupBy: dropDownValueForCard,
      aggregationMetrics: [metricFroData]
    };

    const [payloadProperty, value] = payloadGenerator(mapName, currentSelectedHCKey);
    if (payloadProperty) {
      baseRequestBody[payloadProperty].push(value);
    }

    const requestBody = addFilterToOmniBaseReqBody(baseRequestBody, filters, '');

    const requestBodyByWeek = {
      ...requestBody,
      separateByWeek: true,
      groupBy: groupByGeneratorForCard(dropDownValueForCard)
    };
    dispatch(fetchOmniGeoMapServiceData(requestBodyByWeek, `${name}byWeek${dropDownValueForCard}Card`));

    // dispatch data for map data for 'hc-key' as key
    const requestBodyByWeekForMap = {
      ...requestBody,
      separateByWeek: true
    };
    dispatch(fetchOmniGeoMapServiceData(requestBodyByWeekForMap, `${name}byWeek${dropDownValueForCard}`));

    const requestBodyByWeekPrePeriod = {
      ...requestBodyByWeek,
      startWeekId: preStartWeek,
      endWeekId: preEndWeek,
      separateByWeek: true,
      groupBy: groupByGeneratorForCard(dropDownValueForCard)
    };
    dispatch(
      fetchOmniGeoMapServiceData(requestBodyByWeekPrePeriod, `${name}byWeekPrePeriod${dropDownValueForCard}Card`)
    );

    const requestBodyByWeekPrePeriodForMap = {
      ...requestBody,
      startWeekId: preStartWeek,
      endWeekId: preEndWeek,
      separateByWeek: true
    };
    dispatch(
      fetchOmniGeoMapServiceData(requestBodyByWeekPrePeriodForMap, `${name}byWeekPrePeriod${dropDownValueForCard}`)
    );
  }, [
    startWeek,
    endWeek,
    dispatch,
    filters,
    name,
    preStartWeek,
    preEndWeek,
    dropDownValueForCard,
    metricFroData,
    displayNameForDropDownOPT,
    location,
    user
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const buildGeoDataByWeek = (): CardViewOmniGeoProps[] => {
    const processedGeoDataByWeek: CardViewOmniGeoProps[] = [];
    const { name: dataKey } = metricFroData;

    const mapPrePeriodData = new Map();

    geoDataSeparateByWeekDataPrePeriod.forEach((d) => {
      let prePeriodDataLegend = 0;
      let dataForCompare: number[][] = [];
      d.weekId.buckets.forEach((weekData) => {
        const weekEndingValue = getWeekLastDate(weekData.key);
        if (weekEndingValue) {
          const weekEnding = new Date(weekEndingValue);
          if (weekData[dataKey]) {
            const weekValue = weekData[dataKey].value;
            prePeriodDataLegend += weekValue;
            dataForCompare.push([weekEnding.getTime(), weekValue]);
          } else {
            dataForCompare.push([weekEnding.getTime(), 0]);
          }
        }
      });

      prePeriodDataLegend = d.weekId.buckets.length === 0 ? 0 : prePeriodDataLegend / d.weekId.buckets.length;

      dataForCompare = _orderBy(dataForCompare, 0, 'asc');
      mapPrePeriodData.set(d.key, { prePeriodDataLegend, dataForCompare });
    });

    geoDataSeparateByWeekData.forEach((d) => {
      let acc = 0;
      let legendValue = 0;
      const preData = mapPrePeriodData.get(d.key);
      const legendValueForPrevious = preData ? preData.prePeriodDataLegend : 0;
      const dataForCompare = preData ? preData.dataForCompare : [];
      if (!preData || dataForCompare.length === 0) {
        return;
      }
      let trendData: number[][] = [];
      d.weekId.buckets.forEach((weekData) => {
        const weekEndingValue = getWeekLastDate(weekData.key);
        if (weekEndingValue) {
          const weekEnding = new Date(weekEndingValue);
          if (weekData[dataKey]) {
            const weekValue = weekData[dataKey].value;
            acc += weekValue;
            trendData.push([weekEnding.getTime(), weekValue]);
          } else {
            trendData.push([weekEnding.getTime(), 0]);
          }
        }
      });

      legendValue = trendData.length === 0 ? 0 : acc / trendData.length;
      let percentChange =
        legendValueForPrevious === 0 ? 1 : (legendValue - legendValueForPrevious) / legendValueForPrevious;
      if (legendValueForPrevious === legendValue) {
        percentChange = 0;
      }
      if (percentChange > 0 && percentChange <= 0.0005) {
        percentChange = 0.001;
      }
      if (percentChange < 0 && percentChange >= 0.0005) {
        percentChange = -0.001;
      }
      if (comparisonTimePeriod.id === 'prior-period' && dataForCompare.length >= 1) {
        // fill a value to make the line consecutive
        trendData.push([...dataForCompare[dataForCompare.length - 1]]);
      }
      trendData = _orderBy(trendData, 0, 'asc');
      let cardDisplayName = d.key;

      cardDisplayName = d.name || d.key;

      let shouldAddData = true;
      if (dropDownValueForCard === 'locationStateName' && chartSeries) {
        const dataObj = chartSeries.find((ele) => {
          return ele.key === d.key;
        });
        if (!dataObj) {
          shouldAddData = false;
        }
        legendValue = _get(dataObj, 'value', legendValue);
        percentChange = _get(dataObj, 'preValue', percentChange);
      }
      const aProcessedData = {
        id: d.key,
        name: cardDisplayName,
        shortName: d.key.substring(0, 2),
        metric: metricFroData,
        trendData,
        legendValue,
        percentChange,
        dataForCompare
      };

      if (shouldAddData) {
        if (curCardContainerTab.name === 'increasing' && percentChange > 0) {
          processedGeoDataByWeek.push(aProcessedData);
        }
        if (curCardContainerTab.name === 'all') {
          processedGeoDataByWeek.push(aProcessedData);
        }
        if (curCardContainerTab.name === 'decreasing' && percentChange < 0) {
          processedGeoDataByWeek.push(aProcessedData);
        }
      }
    });
    if (curCardContainerTab.name === 'increasing') {
      return _orderBy(processedGeoDataByWeek, 'percentChange', 'desc');
    } else if (curCardContainerTab.name === 'decreasing') {
      return _orderBy(processedGeoDataByWeek, 'percentChange', 'asc');
    }

    return processedGeoDataByWeek;
  };

  const handleDropDownChange = (newValue: string) => {
    setDropDownValueForCard(newValue);
  };
  const onSearch = (searchKey: string) => {
    setCurrentSearchKey(searchKey);
  };
  const dropDownHeader = metricFroData.displayName
    .toLowerCase()
    .split(' ')
    .map((word) => {
      if (word === 'in-stock') {
        return 'In-Stock';
      } else {
        return _capitalize(word);
      }
    });
  return (
    <div>
      <div
        style={{
          display: 'flex',
          fontFamily: 'Roboto',
          fontSize: '24px',
          alignItems: 'center',
          marginTop: '14px'
        }}
      >
        <span>{`${dropDownHeader.join(' ')} by`}</span>
        <OmniDropDownOption
          tabOptions={dropDownOption}
          style={{ marginLeft: '-23px', marginTop: '2px' }}
          onOptionChange={handleDropDownChange}
          currentValue={dropDownValueForCard === 'locationCountyCode' ? 'locationCounty' : dropDownValueForCard}
        />
      </div>

      <OmniGeoCardHeader
        tabOptions={tabsForCardContainer}
        handleCardContainerTabChange={handleCardContainerTabChange}
        style={{
          margin: '25px 0px'
        }}
      />
      <OmniCardViewSearch
        style={{
          margin: '20px 0px 60px 0'
        }}
        onSearch={onSearch}
      />
      {isFetchingGeoDataByWeek || isFetchingGeoDataByWeekPrePeriod ? (
        <div
          style={{
            position: 'relative',
            margin: 'auto',
            height: '400px',
            width: '400px'
          }}
        >
          <Loading className="spinner" size={150} thickness={2} />
        </div>
      ) : (
        <CardViewContainerRenewal
          geoInfo={dropDownValueForCard}
          data={buildGeoDataByWeek()}
          searchKey={currentSearchKey}
          mapName={mapName}
        />
      )}
    </div>
  );
};

export default OmniGeoCardPartContainer;
