import React, { useState } from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import Cycle from '!svg-react-loader!src/components/common/UploadAnimation/cycle.svg'; // eslint-disable-line
import CloseIcon from '@mui/icons-material/Close';
import './submitScreen.scss'
import { SubmitDialog } from 'src/components/AdManager/SubmitScreen/PartialSuccessScreen'

const FailedScreen = ({ topLine, dialogDetail, buttons, retailer }) => {
    const [open, setOpen] = useState(false);
    const retailerName = _get(retailer, ['displayName'], 'the ad platform')

    return (<div className="finish_screen">
        <div className="icon_group">
          <Cycle className="failed_cycle" />
          <CloseIcon className="failed_cross" style={{ fontSize: 70, color: '#f35379' }} />
        </div>
        <div className="content_area">
        <div className="title">Unsuccessful</div>
        <div className="subtitle">{topLine}</div>
        <div className="subtitle">{retailerName} returned  <strong role="button" style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>errors</strong> in this request.</div>
        <div className="button_group" >
        { buttons.map((button, index) => {
            return (
              <LargeMuiButton
                key={index}
                style={{ textDecoration: 'none', cursor: 'pointer', marginLeft: 5, marginRight: 5 }}
                onClick={() => { button.onClick() }}
                {...button.buttonProps}
              >
                {button.text}
              </LargeMuiButton>
            )
        }) }

        </div>
        </div>
        <Dialog open={open} onClose={() => setOpen(false)}>
        <SubmitDialog dialogDetail={dialogDetail} />
      </Dialog>        
    </div>)
}

FailedScreen.propTypes = {
  retailer: PropTypes.object.isRequired,
  dialogDetail: PropTypes.object,
  topLine: PropTypes.string,
  bottomLine: PropTypes.string,
  buttons: PropTypes.array
};
  
FailedScreen.defaultProps = {
  dialogDetail: {},
  topLine: '',
  bottomLine: '',
  buttons: []
};

const mapStateToProps = ({ retailer }) => ({ retailer });
export default connect(mapStateToProps)(FailedScreen);