import React, { useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useStacklineTheme } from '@stackline/ui';
import { useAppSelector, useLocation, useUpdateSearchParams } from 'src/utils/Hooks';
import { Grid, FormControlLabel, Radio, Divider } from '@mui/material';
import ReduxStore from 'src/types/store/reduxStore';
import { useBeaconSubcategoryIdRouter } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/ProductGrowthPage';
import _get from 'lodash/get';
import { CategoryEntity, SubCategoryEntity } from 'sl-api-connector';
import { CloseIcon } from 'src/components/SvgIcons/SvgIcons';
import { FilterSearchInput } from 'src/components/BeaconRedesignComponents/ProductGrowthModal/ModalComponents/FilterSearchInput';
import ScrollableContainer from 'src/components/BeaconRedesignComponents/ScrollableContainer';
import Box from '@mui/system/Box';
import { SlRow } from 'src/components/BeaconRedesignComponents/Generic/SlRow';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import QuadrantFilterSection, { ProductGrowthQuadrant } from './QuadrantFilterSection';

const GenericStyledDialogue = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '752px',
    height: '641px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px'
  }
});

/**
 * How many more options to show per "Show more" click & also determines the default number of options shown
 */
const MAX_OPTIONS_SHOWN = 6;

/**
 * @returns the Product Growth modal header containing a 'Clear' button and X icon for closing the modal
 */
export const ProductGrowthFilterModalHeader = ({ handleClose }: { handleClose: () => void }) => {
  return (
    <>
      <SlRow
        horizontalInset="mdl"
        verticalInset="mdl"
        spacing="md"
        verticalPosition="center"
        horizontalPosition="space-between"
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '20px' // to center text in the modal header
          }}
        >
          <Text variant="h4">Filter</Text>
        </Box>
        <CloseIcon onClick={handleClose} style={{ cursor: 'pointer', width: '20px', height: '20px' }}></CloseIcon>
      </SlRow>
      <Divider />
    </>
  );
};

/**
 *
 * @returns the Product Growth modal footer containing a 'cancel' button and an 'Apply' button for updating the subcategory filtered on
 */
export const ProductGrowthFilterModalFooter = ({
  handleClose,
  subcategoryForSubmission,
  quadrants
}: {
  handleClose: () => void;
  subcategoryForSubmission: string | number;
  quadrants: ProductGrowthQuadrant[];
}) => {
  const updateSearchParams = useUpdateSearchParams();
  const theme = useStacklineTheme();

  const handleSubmit = () => {
    handleClose();
    updateSearchParams({
      subcategory: `${subcategoryForSubmission}`,
      quadrants
    });
  };

  return (
    <>
      <Divider />
      <Box
        sx={{
          paddingX: theme.spacing.xl,
          paddingY: '20px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <SlButton onClick={handleSubmit} disabled={!subcategoryForSubmission} variant="contained">
          Apply
        </SlButton>
      </Box>
    </>
  );
};

interface FilterGroupProps {
  /**
   * The title of the section above the actual filter controls
   */
  filterTitle: string;
  /**
   * Either categories or subcategories available as options to filter on
   */
  options: ReduxStore['categories'] | ReduxStore['subCategories'];
  /**
   * The toggled selection
   */
  selectedOption: CategoryEntity | SubCategoryEntity | null;
  /**
   * Function that fires when an option is selected
   */
  onSelect: (e: Event) => void;
  /**
   * Either null (when no category is selected) or a collection of filtered subcategories under
   * a parent category.
   */
  rolledUpOptions?: null | ReduxStore['subCategories'];
}
const FilterGroup = ({ filterTitle, options, selectedOption, onSelect, rolledUpOptions = null }: FilterGroupProps) => {
  // Pagination for "Show more" button
  const [optionsPage, setOptionsPage] = useState(1);
  // Input used for searching categories or subcategories
  const [input, setInput] = useState('');
  const theme = useStacklineTheme();

  const userInput = useMemo(() => {
    return input;
  }, [input]);

  /**
   * If the user types in the search bar, we should search all available options.
   * Otherwise we should only show subcategories under a selected parent category.
   * If no category is selected, we can make all options available.
   * Note that we limit (paginate) available options unless the user is searching.
   */
  const availableOptions = useMemo(() => {
    if (userInput.length > 0) {
      return options
        .filter((option) => option.id !== 0 && option.displayName.toLowerCase().includes(userInput.toLowerCase()))
        .map((option) => {
          return {
            id: option.id,
            label: option.displayName
          };
        });
    } else {
      return rolledUpOptions
        ? rolledUpOptions
            .slice(0, optionsPage * MAX_OPTIONS_SHOWN)
            .filter((cat) => cat.id !== 0)
            .map((option) => {
              return {
                id: option.id,
                label: option.displayName
              };
            })
        : options
            .slice(0, optionsPage * MAX_OPTIONS_SHOWN)
            .filter((cat) => cat.id !== 0)
            .map((option) => {
              return {
                id: option.id,
                label: option.displayName
              };
            });
    }
  }, [optionsPage, userInput, selectedOption, onSelect]);

  const showMoreOptions = () => {
    setOptionsPage(optionsPage + 1);
  };

  return (
    <Flex flexDirection="column" gap="md">
      {/* FilterTitle */}
      <Text variant="subtitle1">{filterTitle}</Text>
      {/* Search Input  */}
      <FilterSearchInput
        handleChange={setInput}
        input={input}
        placeholderText={filterTitle === 'Categories' ? 'Search category' : 'Search subcategory'}
      />
      {/* Radio Buttons */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, auto)',
          color: theme.colors.primary,
          justifyContent: 'space-between',
          rowGap: '5px',
          marginTop: '9px'
        }}
      >
        {availableOptions
          ? availableOptions.map((option) => (
              <Grid sx={{ maxWidth: 'fit-content' }} item key={option.id}>
                <FormControlLabel
                  title={option.label}
                  sx={{
                    color: theme.colors.primary,
                    minWidth: '190px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginLeft: '0px',
                    marginRight: '0px',
                    '& .MuiTypography-root': {
                      width: '160px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }
                  }}
                  checked={selectedOption ? String(option.id) === String(selectedOption.id) : false}
                  label={option.label}
                  value={option.id}
                  control={
                    <Radio
                      disableRipple
                      disableTouchRipple
                      sx={{
                        svg: {
                          height: '15px',
                          width: '15px'
                        },
                        '&, &.Mui-checked': {
                          color: theme.colors.primary
                        }
                      }}
                    />
                  }
                  key={option.id}
                  onChange={(e) => onSelect(e)}
                />
              </Grid>
            ))
          : null}
      </div>

      {/* Show more button */}
      <Flex>
        <button style={{ border: 'none', background: 'none' }} onClick={showMoreOptions}>
          <Text variant="subtitle2" underlined>
            Show more
          </Text>
        </button>
      </Flex>
    </Flex>
  );
};

/**
 * Contains Modal header, footer, and main content + business logic for the Product Growth filter modal.
 *
 * This differs from other filter modals in that we must always have a filter applied
 */
const ProductGrowthFilterInner = ({ handleClose }: { handleClose: () => void }) => {
  const location = useLocation();
  const entityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const categories = useAppSelector((state) => {
    // Only return the category that matches the main entity ID if we are on a category page
    if (entityType === 'category') {
      return state.categories.filter((category) => category.id === Number(state.entityService.mainEntity.id));
    }
    return state.categories;
  });
  const subCategories = useAppSelector((state) => state.subCategories);
  const { selectedSubcategoryId } = useBeaconSubcategoryIdRouter();

  // Subcategory Logic
  // The subcategory ID currently selected. This state will drive the modal selections.
  const [subcategorySelection, setSubcategorySelection] = useState(selectedSubcategoryId);
  const [quadrants, setQuadrants] = useState<ProductGrowthQuadrant[]>(
    new URLSearchParams(location.search).getAll('quadrants') as ProductGrowthQuadrant[]
  );

  // Get the matching subcategory data based on the selected subcategory ID
  const subcategoryDataFromSelection = useMemo(() => {
    return subCategories.find((subcategory) => subcategory.id === subcategorySelection);
  }, [subCategories, subcategorySelection]);

  // Category Logic
  // Get the parent category for the currently selected subcategory
  const categoryDataFromSelection = useMemo(() => {
    return categories.find((category) => category.id === _get(subcategoryDataFromSelection, 'parentCategoryId'));
  }, [categories, subcategoryDataFromSelection]);

  /**
   * Determine the category based on the category ID selected. Then set the subcategory selection to be the first child subcategory of that category.
   */
  const onSelectCategory = (e: Event) => {
    const categoryId = Number(e.target.value);
    const newCategory = categories.find((category) => category.id === categoryId);
    setSubcategorySelection(String(newCategory.childEntities[0].id));
  };

  const onSelectSubcategory = (e: Event) => {
    setSubcategorySelection(e.target.value);
  };

  return (
    <>
      <ProductGrowthFilterModalHeader handleClose={handleClose} />
      <ScrollableContainer sx={{ height: '500px', overflow: 'scroll', overflowX: 'hidden' }} scrollbarWidth="5px">
        <Flex flexDirection="column" paddingX="48px" gap="lg" paddingY="24px">
          <QuadrantFilterSection quadrants={quadrants} setQuadrants={setQuadrants} />
          <FilterGroup
            filterTitle="Categories"
            options={categories}
            selectedOption={categoryDataFromSelection}
            onSelect={onSelectCategory}
          />
          <FilterGroup
            filterTitle="Subcategories"
            rolledUpOptions={categoryDataFromSelection ? categoryDataFromSelection.childEntities : subCategories} // Filter subcategories under a selected parent category for use when not searching
            options={subCategories}
            selectedOption={subcategoryDataFromSelection}
            onSelect={onSelectSubcategory}
          />
        </Flex>
      </ScrollableContainer>
      <ProductGrowthFilterModalFooter
        handleClose={handleClose}
        subcategoryForSubmission={subcategorySelection} // Used for disabling "Apply" button
        quadrants={quadrants}
      />
    </>
  );
};

interface ProductGrowthFilterModalProps {
  open: boolean;
  handleClose: () => void;
}
export const ProductGrowthFilterModal = ({ open, handleClose }: ProductGrowthFilterModalProps) => {
  return (
    <GenericStyledDialogue open={open} onClose={handleClose}>
      <ProductGrowthFilterInner handleClose={handleClose} />
    </GenericStyledDialogue>
  );
};
