import { CategoryEntity } from 'sl-api-connector';

import types from './types';
import ReduxStore from 'src/types/store/reduxStore';

const receiveCategoriesByRetailerId = (categoriesByRetailerId: ReduxStore['categoriesByRetailerId']) => ({
  type: types.RECEIVE_UNFILTERED_CATEGORIES,
  categoriesByRetailerId,
  receivedAt: Date.now()
});

const receiveAllSuperUserCategories = (allSuperUserCategories: ReduxStore['allSuperUserCategories']) => {
  return {
    type: types.RECEIVE_UNFILTERED_SUPERUSER_CATEGORIES,
    allSuperUserCategories: allSuperUserCategories[0].filter((cat) => cat.id !== 0),
    receivedAt: Date.now()
  };
};

const receiveFilteredCategories = (filteredCategories: ReduxStore['categories']) => ({
  type: types.RECEIVE_FILTERED_CATEGORIES,
  filteredCategories,
  receivedAt: Date.now()
});

const clearCategories = () => ({ type: types.CLEAR_CATEGORIES });

const sortCategories = (sorter: (category: CategoryEntity) => boolean, direction: 'asc' | 'desc' = 'desc') => ({
  type: types.SORT_CATEGORIES,
  sorter,
  direction
});

export default {
  clearCategories,
  receiveCategoriesByRetailerId,
  receiveAllSuperUserCategories,
  receiveFilteredCategories,
  sortCategories
};
