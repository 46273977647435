import { Widget } from 'src/types/application/widgetTypes';
import ShareOfShelfTable from 'src/components/Omni/OmniShareOfShelf/ShareOfShelfTable';
import _cloneDeep from 'lodash/cloneDeep';
import { findLast } from 'lodash';

const swapElementInArr = (arr: any[], i: number, j: number) => {
  const temp = arr[i];
  arr[i] = arr[j];
  arr[j] = temp;
};

const topKElementQuickSelectPartition = (
  arr: string[],
  left: number,
  right: number,
  mapCountFreq: Map<string, number>
): number => {
  const partitionIndex = Math.floor(Math.random() * (right - left + 1)) + left;
  swapElementInArr(arr, partitionIndex, right);
  let i = 0;
  let j = right - 1;
  const pivotValue = mapCountFreq.get(arr[right]);
  while (i <= j) {
    const currentVal = mapCountFreq.get(arr[i]);
    if (currentVal < pivotValue) {
      swapElementInArr(arr, i, j);
      j--;
    } else {
      i++;
    }
  }
  swapElementInArr(arr, i, right);
  return i;
};

const topKElementQuickSelectHelper = (
  arr: string[],
  kIndex: number,
  left: number,
  right: number,
  mapCountFreq: Map<string, number>
): void => {
  if (left >= right) {
    return;
  }
  const pivotIndex = topKElementQuickSelectPartition(arr, left, right, mapCountFreq);
  if (pivotIndex === kIndex) {
    return;
  }
  if (pivotIndex < kIndex) {
    topKElementQuickSelectHelper(arr, kIndex, pivotIndex + 1, right, mapCountFreq);
  } else {
    topKElementQuickSelectHelper(arr, kIndex, left, pivotIndex - 1, mapCountFreq);
  }
};

const topKElementQuickSelect = (arr: string[], k: number, mapCountFreq: Map<string, number>): string[] => {
  if (!arr || k >= arr.length || k < 1) {
    return arr;
  }
  topKElementQuickSelectHelper(arr, k - 1, 0, arr.length - 1, mapCountFreq);
  const res: string[] = [];
  for (let i = 0; i < k; i++) {
    res.push(arr[i]);
  }
  return res;
};

const preAssignColor = (arr: string[], colorSet: { color: string; used: boolean }[]) => {
  const assignedMapping = new Map();
  const colorUsed = new Set();
  const n = Math.min(arr.length, colorSet.length);
  for (let i = 0; i < n; i++) {
    assignedMapping.set(arr[i], colorSet[i].color);
    colorUsed.add(colorSet[i].color);
  }
  return { assignedMapping, colorUsed };
};

export const processShardsData = (data: any[], omniBrandsFollowing: any[]) => {
  const mapCountFreq = new Map();
  const processedData = data.map((ele) => {
    const { brandData } = ele;

    const top4WithoutEmpty = brandData.filter((aTop4) => aTop4.brandId);
    const top4 = top4WithoutEmpty.slice(0, 4);

    const findTop4ExistFollowing = omniBrandsFollowing.find((brandFollow) =>
      top4.find((top4brand) => top4brand.brandId === brandFollow.brandId)
    );

    // we need to ensure that the top4 have at least one following brand
    if (!findTop4ExistFollowing) {
      const findTheFollowingBrand = brandData.find((aBrandData) =>
        omniBrandsFollowing.find((brandFollow) => brandFollow.brandId === aBrandData.brandId)
      );
      if (findTheFollowingBrand) {
        top4[top4.length - 1] = findTheFollowingBrand;
      }
    }

    // count the freq for each brand
    top4.forEach((topEle) => {
      const curFreq = mapCountFreq.get(topEle.brandId);
      if (!curFreq) {
        mapCountFreq.set(topEle.brandId, 1);
      } else {
        mapCountFreq.set(topEle.brandId, curFreq + 1);
      }
    });

    return { ...ele, brandData: top4 };
  });

  const colorSet = [
    {
      color: '#5fb1f2',
      used: false
    },
    {
      color: '#043048',
      used: false
    },
    {
      color: '#FFBD32',
      used: false
    },
    {
      color: '#356593',
      used: false
    },
    {
      color: '#7196B4',
      used: false
    },
    {
      color: '#4D5965',
      used: false
    },
    {
      color: '#A4CFFF',
      used: false
    },
    {
      color: '#3383D3',
      used: false
    }
  ];

  // get the top 8 brand. We have 8 color. first assign to the most occur brand.
  const topBrand = topKElementQuickSelect([...mapCountFreq.keys()], colorSet.length, mapCountFreq);
  const { assignedMapping, colorUsed } = preAssignColor(topBrand, colorSet);
  const final = processedData.map((ele) => {
    const { brandData } = ele;
    const brandDataWithColor = [];
    const clonedColorSet = _cloneDeep(colorSet);

    // first mark the color already used in other retailer
    brandData.forEach((topEle) => {
      if (assignedMapping.has(topEle.brandId)) {
        const usedColor = assignedMapping.get(topEle.brandId);
        const findColorSet = clonedColorSet.find((colorObj) => colorObj.color === usedColor);
        if (findColorSet) {
          findColorSet.used = true;
        }
      }
    });

    // give the correct color to each brand
    brandData.forEach((topEle) => {
      let correctColor = clonedColorSet[clonedColorSet.length - 1].color;
      if (!assignedMapping.has(topEle.brandId)) {
        const findBestColorObj = clonedColorSet.find((colorObj) => !colorUsed.has(colorObj.color) && !colorObj.used);
        if (findBestColorObj) {
          correctColor = findBestColorObj.color;
          findBestColorObj.used = true;
          assignedMapping.set(topEle.brandId, correctColor);
          colorUsed.add(findBestColorObj.color);
        } else {
          const secondOpt = findLast(clonedColorSet, (colorObj) => !colorObj.used);
          if (secondOpt) {
            correctColor = secondOpt.color;
            secondOpt.used = true;
            assignedMapping.set(topEle.brandId, secondOpt.color);
            colorUsed.add(secondOpt.color);
          }
        }
      } else {
        correctColor = assignedMapping.get(topEle.brandId);
      }
      // const value = Math.round(topEle.value * 100);
      const value = topEle.value * 100;
      brandDataWithColor.push({
        brandId: topEle.brandId,
        value,
        color: correctColor
      });
    });

    return { ...ele, brandData: brandDataWithColor };
  });
  return final;
};

export const buildShareOfShelfTableWidget = (name: string, shareOfShelfType: string): Widget => {
  return {
    name,
    CustomComponent: ShareOfShelfTable,
    view: {
      name
    },
    data: {
      shareOfShelfType
    }
  };
};
