import { Box, Button } from '@mui/material';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import axios, { AxiosRequestConfig } from 'axios';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  brandManufacturerActiveState,
  chipFields,
  DEFAULT_BASE_FILTER_OBJ,
  DEFAULT_BRAND_FILTER_OBJ,
  departCatSubcatActiveState,
  filterContent,
  initError
} from 'src/components/Discover/config';
import DiscoverFilterDialogBody from 'src/components/Discover/DiscoverFilterDialog/DiscoverFilterDialogBody/DiscoverFilterDialogBody';
import DiscoverFilterDialogFooter from 'src/components/Discover/DiscoverFilterDialog/DiscoverFilterDialogFooter';
import DiscoverFilterDialogHeader from 'src/components/Discover/DiscoverFilterDialog/DiscoverFilterDialogHeader';
import DiscoverSkeleton from 'src/components/Discover/DiscoverSkeleton';
import { getDiscoverColumns, sendFilterHeaders } from 'src/components/Discover/DiscoverUtils/ColumnUtils';
import { IsActiveElement } from 'src/components/Discover/DiscoverUtils/CommonUtils';
import { createBodyForApiCall, sortColumnArrangement } from 'src/components/Discover/DiscoverUtils/DiscoverAPIUtils';
import { ClearFilters, FilterIcon } from 'src/components/SvgIcons';
import { store } from 'src/main';
import { updateQueryParams } from 'src/store/modules/app/operations';
import ReduxStore from 'src/types/store/reduxStore';
import '../EntityPage/WaterfallChart/Insights/ActionCell.scss';
import './discover.scss';
import DiscoverFilterDialog from './DiscoverFilterDialog';
import DiscoverPaginationGroup from './DiscoverPaginationGroup';
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';
import { CategoryEntity, SubCategoryEntity } from 'sl-api-connector';
import { useBus } from 'src/utils/Hooks';
import { withBus } from 'react-bus';

export type Props = RouteComponentProps;

const Discover: React.FC<Props> = (props) => {
  const { location, history } = props;
  const mainTimePeriodObj = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const comparisonTimePeriodObj = useSelector((state: ReduxStore) => state.comparisonTimePeriod);
  const user = useSelector((state: ReduxStore) => state.user);
  const isSuperUser = user.config.isStacklineSuperUser;
  const dispatch = useDispatch();
  const retailerObj = useSelector((state: ReduxStore) => state.retailer);
  const categories = useSelector((state: ReduxStore) =>
    state.categories.sort((a, b) => {
      if (a.id > b.id) {
        return -1;
      }

      return 1;
    })
  );

  const superUserCategories = useSelector((state: ReduxStore) => state.allSuperUserCategories);
  const superUserSubCategories = useSelector((state: ReduxStore) => state.allSuperUserSubCategories);

  const app = useSelector((state: ReduxStore) => state.app);
  const subCategories = useSelector((state: ReduxStore) => state.subCategories);

  const mainTimePeriodStartWeek = mainTimePeriodObj.startWeek;
  const comparisonTimePeriodStartWeek = comparisonTimePeriodObj.startWeek;
  const priorYearPriorPeriodSwitch = comparisonTimePeriodObj.id;

  const [rowData, setRowData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  // These update if a brand is selected
  const [brandChips, setBrandChips] = useState<{ key?: number; i: number; n: string }[]>([]);
  // Should this be a ref?
  const [gridApi, setGridApi] = useState<GridApi | undefined>();

  const [dFilterObj, setDFilterObj] = useState({});
  const [isBrandManufacturerActive, setIsBrandManufacturerActive] = useState(brandManufacturerActiveState);
  const [isDepartCatSubcatActive, setIsDepartCatSubcatActive] = useState(departCatSubcatActiveState);
  const [callApi, setCallApi] = useState(false);
  const [isEffectLoaded, setIsEffectLoaded] = useState(false);
  const [filterObj, setFilterObj] = useState<Record<string, any>>(DEFAULT_BRAND_FILTER_OBJ);
  const [tempFilterObj, setTempFilterObj] = useState<Record<string, any>>(DEFAULT_BRAND_FILTER_OBJ);
  const [tempMoreFilter, setTempMoreFilter] = useState({
    brands: [] as string[],
    cats: [] as number[],
    subcats: [] as number[]
  });
  const [filterTitles, setFilterTitles] = useState<string[]>([]);

  const [helperText, setHelperText] = useState(initError);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<CategoryEntity[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<SubCategoryEntity[]>([]);
  const [subCategoryBasedOnCategorySelection, setSubCategoryBasedOnCategorySelection] = useState<SubCategoryEntity[]>(
    []
  );
  const [isSubCatLoaded, setIsSubCatLoaded] = useState(true);
  const [updateColsOnChipClick, setUpdateColsOnChipClick] = useState<any>(() => {});
  const [isDisabled, setIsDisabled] = useState(false);
  const colDefs = [...getDiscoverColumns(retailerObj)];
  const [colDef, setColDef] = useState(colDefs);

  const hasAppliedFilters = useMemo(() => {
    if (brandChips.length > 0 || selectedCategory.length > 0 || selectedSubCategory.length > 0) {
      return true;
    }

    let hasFilter = false;
    const rootKeys = Object.keys(tempFilterObj);

    rootKeys.forEach((baseKey) => {
      const filters = tempFilterObj[baseKey];

      if (filters) {
        const filterKeys = Object.keys(filters);
        filterKeys.forEach((filterKey) => {
          const currFilter = filters[filterKey];

          const currFilterKeys = Object.keys(currFilter);
          currFilterKeys.forEach((key) => {
            if (currFilter[key] !== '') {
              hasFilter = true;
            }
          });
        });
      }
    });

    return hasFilter;
  }, [tempFilterObj, filterObj, brandChips.length, selectedCategory.length, selectedSubCategory.length]);

  const initialApiCallConfig = (isApply: boolean) => {
    setHasLoaded(true);
    const headersSelectedBrandManufacturer = sendFilterHeaders(isBrandManufacturerActive);
    const headersSelectedDeptCatSubCat = sendFilterHeaders(isDepartCatSubcatActive);
    const headersList = [...headersSelectedBrandManufacturer, ...headersSelectedDeptCatSubCat];

    const dataBody = createBodyForApiCall({
      columnsSelected: headersList,
      isApply,
      filterObj,
      filterTitles,
      retailerObj,
      isSuperUser,
      superUserCategories,
      categories,
      comparisonTimePeriodObj,
      mainTimePeriodObj,
      selectedCategory,
      selectedSubCategory,
      brandChips,
      history,
      location
    });
    const dataOne = JSON.stringify(dataBody);

    const config: AxiosRequestConfig = {
      method: 'post',
      url: '/api/discover/DiscoverAdvancedSearch',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        crossDomain: 'true',
        'Access-Control-Allow-Origin': '*'
      },
      data: dataOne,
      withCredentials: true
    };

    if (isApply) {
      const selected: string[] = [];

      isBrandManufacturerActive.forEach((clip) => {
        if (clip.isActive) {
          selected.push(clip.name);
        }
      });
      if (!(selected.includes('brand') || selected.includes('manufacturer'))) {
        colDef.forEach((col, index) => {
          if (index > 4) {
            col.hide = ['marketShare', 'marketShareChange', 'trafficShare'].includes(col.field);
          }
        });
      } else {
        colDef.forEach((col, index) => {
          if (index > 4) {
            col.hide = false;
          }
        });
      }
      axios(config).then((response) => {
        const rowDataJson = response.data.recordsList;
        setRowData(rowDataJson);
        setHasLoaded(false);
      });
    }
    return config;
  };

  // TODO:: why do we need isEffectLoaded....?
  useEffect(() => {
    if (isEffectLoaded) {
      initialApiCallConfig(true);
    }
    setIsEffectLoaded(true);
  }, [mainTimePeriodStartWeek, comparisonTimePeriodStartWeek, priorYearPriorPeriodSwitch]);

  /* Based on user selection of multi select, when Users click on show filters, the respective filters with filter titles are created. These filter titles are iterated over filter body to create filter for each and every individual filter header */
  const assignFilterHeaders = (filter: string[]) => {
    filter.forEach((f, index) => (f === 'no selection' ? filter.splice(index, 1) : ''));
    const filterHeader = filter.join('/');

    const filterHeaderNames = [];
    filterHeaderNames.push(filterHeader);

    return filterHeaderNames;
  };

  /* function to check if states of all button are set to not active. This function is a helper function for handleClick */
  const checkFinalState = (state1: IsActiveElement[], state2: IsActiveElement[]) => {
    let checkFalseCount = 0;
    let allButtonStateSetToFalse = false;
    state1.forEach((state: IsActiveElement) => {
      if (state.isActive === false) {
        checkFalseCount += 1;
      }
    });
    state2.forEach((state: IsActiveElement) => {
      if (state.isActive === false) {
        checkFalseCount += 1;
      }
    });
    if (checkFalseCount === 7) {
      allButtonStateSetToFalse = true;
      return allButtonStateSetToFalse;
    }
    return allButtonStateSetToFalse;
  };

  /* clicked field - > show
      else hide
      field === 'no selection' && group === 'deptCatSubcatChipGroup'
        => show cat and hide rest
      field === 'no selection' && group === 'row 2'
        => show cat and hide rest
  */

  const alterColumns = () => {
    const newColDefState = [...colDef];
    const manIndex = newColDefState.findIndex((col) => col.field === 'manufacturerName');
    const brandIndex = newColDefState.findIndex((col) => col.field === 'brandName');
    const catIndex = newColDefState.findIndex((col) => col.field === 'categoryName');
    const subcatIndex = newColDefState.findIndex((col) => col.field === 'subCategoryName');
    const deptIndex = newColDefState.findIndex((col) => col.field === 'departmentName');
    const newBrandManuState = [...isBrandManufacturerActive];
    const newDeptCatSubcatState = [...isDepartCatSubcatActive];

    newBrandManuState.forEach((field) => {
      if (field.isActive) {
        if (field.name === 'no selection') {
          newColDefState[manIndex].hide = true;
          newColDefState[brandIndex].hide = true;
        }
        if (field.name === 'manufacturer') {
          newColDefState[manIndex].hide = false;
        } else {
          newColDefState[manIndex].hide = true;
        }
        if (field.name === 'brand') {
          newColDefState[brandIndex].hide = false;
        } else {
          newColDefState[brandIndex].hide = true;
        }
      }
    });
    newDeptCatSubcatState.forEach((state) => {
      if (state.isActive) {
        if (state.name === 'no selection') {
          newColDefState[catIndex].hide = true;
          newColDefState[subcatIndex].hide = true;
          newColDefState[deptIndex].hide = true;
        }
        if (state.name === 'department') {
          newColDefState[deptIndex].hide = false;
          newColDefState[catIndex].hide = true;
          newColDefState[subcatIndex].hide = true;
        } else if (state.name === 'category') {
          newColDefState[catIndex].hide = false;
          newColDefState[subcatIndex].hide = true;
          newColDefState[deptIndex].hide = true;
        } else if (state.name === 'subcategory') {
          newColDefState[subcatIndex].hide = false;
          newColDefState[deptIndex].hide = true;
          newColDefState[catIndex].hide = true;
        }
      }
    });
    const selected: string[] = [];
    newBrandManuState.forEach((clip) => {
      if (clip.isActive) {
        selected.push(clip.name);
      }
    });

    if (!(selected.includes('brand') || selected.includes('manufacturer'))) {
      newColDefState.forEach((col, colIndex) => {
        if (colIndex > 4) {
          col.hide = ['marketShare', 'marketShareChange', 'trafficShare'].includes(col.field);
        }
      });
    } else {
      newColDefState.forEach((col, colIndex) => {
        if (colIndex > 4) {
          col.hide = false;
        }
      });
    }
    // This is a bad pattern -- should fix
    setUpdateColsOnChipClick(newColDefState);
  };

  /* function to handle clicks on multi select button panel */
  const handleClick = async (field?: string, par?: number, group?: string) => {
    /* setting init Error and isDisabled to false every time user navigates on the group of radio buttons */
    setHelperText(initError);
    setIsDisabled(false);
    /* first copy state variables, search for the state variable in both the states and set the state to opposite state */
    const newBrandManufacturerState = [...isBrandManufacturerActive];
    const newDeptCatSubcatState = [...isDepartCatSubcatActive];

    const headerSelectedBM = sendFilterHeaders(newBrandManufacturerState);
    const headerSelectedDCS = sendFilterHeaders(newDeptCatSubcatState);
    const finalFilterHeaders = [...headerSelectedBM, ...headerSelectedDCS];
    const assignedFH = assignFilterHeaders(finalFilterHeaders);

    let objEr = {};
    assignedFH.map((head) => {
      objEr = {
        ...objEr,
        [head]: {
          retailSalesRange: { rangeBegin: '', rangeEnd: '' },
          popRetailSalesChangePct: { rangeBegin: '', rangeEnd: '' },
          avgRating: { rangeBegin: '', rangeEnd: '' },
          marketShare: { rangeBegin: '', rangeEnd: '' },
          marketShareChange: { rangeBegin: '', rangeEnd: '' },
          unitsSold: { rangeBegin: '', rangeEnd: '' },
          trafficShare: { rangeBegin: '', rangeEnd: '' },
          conversion: { rangeBegin: '', rangeEnd: '' },
          popUnitsSoldChangePct: { rangeBegin: '', rangeEnd: '' }
        }
      };
      return objEr;
    });
    setFilterObj(objEr);
    setTempFilterObj(objEr);

    if (field === 'brand' || field === 'manufacturer') {
      newBrandManufacturerState[par].isActive = !newBrandManufacturerState[par].isActive;
    }
    if (field === 'department' || field === 'category' || field === 'subcategory') {
      newDeptCatSubcatState[par].isActive = !newDeptCatSubcatState[par].isActive;
    }
    if (field === 'no selection' && group === 'brandManufacturerChipGroup') {
      newBrandManufacturerState[par].isActive = !newBrandManufacturerState[par].isActive;
      let isDeptRowSel = false;
      newDeptCatSubcatState.forEach((state: IsActiveElement) => {
        if (state.isActive && state.name !== 'no selection') {
          isDeptRowSel = true;
        }
      });
      if (!isDeptRowSel) {
        newDeptCatSubcatState[1].isActive = true;
      }
    }
    if (field === 'no selection' && group === 'deptCatSubcatChipGroup') {
      newDeptCatSubcatState[par].isActive = !newDeptCatSubcatState[par].isActive;
    }
    if (field === 'brand' && newBrandManufacturerState[par].isActive) {
      newBrandManufacturerState.forEach((state: IsActiveElement) => {
        if (state.name === 'manufacturer') {
          state.isActive = false;
        }
        if (state.name === 'no selection') {
          state.isActive = false;
        }
      });
    }

    if (field === 'manufacturer' && newBrandManufacturerState[par].isActive) {
      newBrandManufacturerState.forEach((state: IsActiveElement) => {
        if (state.name === 'brand') {
          state.isActive = false;
        }
        if (state.name === 'no selection') {
          state.isActive = false;
        }
      });
    }

    if (field === 'no selection' && group === 'brandManufacturerChipGroup') {
      newBrandManufacturerState.forEach((state: IsActiveElement) => {
        if (state.name === 'manufacturer') {
          state.isActive = false;
        }
        if (state.name === 'brand') {
          state.isActive = false;
        }
      });
      let newDeptCatSubcatStateNoSel = false;
      newDeptCatSubcatState.forEach((state: IsActiveElement) => {
        if (state.name === 'no selection' && state.isActive) {
          newDeptCatSubcatStateNoSel = true;
        }
      });
      if (newDeptCatSubcatStateNoSel) {
        newDeptCatSubcatState.forEach((state: IsActiveElement) => {
          if (state.name === 'subcategory') {
            state.isActive = false;
          }
          if (state.name === 'department') {
            state.isActive = false;
          }
          if (state.name === 'no selection') {
            state.isActive = false;
          }
          if (state.name === 'category') {
            state.isActive = true;
          }
        });
      }
    }

    if (field === 'department' && newDeptCatSubcatState[par].isActive) {
      newDeptCatSubcatState.forEach((state: IsActiveElement) => {
        if (state.name === 'category') {
          state.isActive = false;
        }
        if (state.name === 'subcategory') {
          state.isActive = false;
        }
        if (state.name === 'no selection') {
          state.isActive = false;
        }
      });
    }

    if (field === 'category' && newDeptCatSubcatState[par].isActive) {
      newDeptCatSubcatState.forEach((state: IsActiveElement) => {
        if (state.name === 'department') {
          state.isActive = false;
        }
        if (state.name === 'subcategory') {
          state.isActive = false;
        }
        if (state.name === 'no selection') {
          state.isActive = false;
        }
      });
    }

    if (field === 'subcategory' && newDeptCatSubcatState[par].isActive) {
      newDeptCatSubcatState.forEach((state: isActive) => {
        if (state.name === 'department') {
          state.isActive = false;
        }
        if (state.name === 'category') {
          state.isActive = false;
        }
        if (state.name === 'no selection') {
          state.isActive = false;
        }
      });
    }

    if (field === 'no selection' && group === 'deptCatSubcatChipGroup') {
      /** when users click on no selection on dept cat subcat list of buttons, there can be a selection already in the deptCatSubCat Selection, so we check if no selection is active in the deptCatSubCatSelection */
      newDeptCatSubcatState.forEach((state: IsActiveElement) => {
        if (state.name === 'department') {
          state.isActive = false;
        }
        if (state.name === 'category') {
          state.isActive = false;
        }
        if (state.name === 'subcategory') {
          state.isActive = false;
        }
      });
      let brandManuStateNoSel = false;
      newBrandManufacturerState.forEach((state: IsActiveElement) => {
        if (state.name === 'no selection' && state.isActive) {
          brandManuStateNoSel = true;
        }
      });
      if (brandManuStateNoSel) {
        newBrandManufacturerState.forEach((state: IsActiveElement) => {
          if (state.name === 'manufacturer') {
            state.isActive = false;
          }
          if (state.name === 'brand') {
            state.isActive = true;
          }
          if (state.name === 'no selection') {
            state.isActive = false;
          }
        });
      }
    }

    /* check final state and if all the states are set to false, set brand and category to true */
    /* function to take both the states at the end of the clicks and check if all the states are set to false */
    /* if all the states are set to false, set brandCol and categoryCol to active */

    const checkIfAllStatesAreSetToNotActive = checkFinalState(newBrandManufacturerState, newDeptCatSubcatState);
    if (checkIfAllStatesAreSetToNotActive) {
      newBrandManufacturerState.forEach((element: IsActiveElement) => {
        if (element.name === 'brand') {
          element.isActive = !element.isActive;
        }
      });

      newDeptCatSubcatState.forEach((element: IsActiveElement) => {
        if (element.name === 'category') {
          element.isActive = !element.isActive;
        }
      });
    }

    const headersSelectedBrandManu = sendFilterHeaders(newBrandManufacturerState);
    const headersSelectedDeptCatSubCat = sendFilterHeaders(newDeptCatSubcatState);
    const headersList = [...headersSelectedBrandManu, ...headersSelectedDeptCatSubCat];
    const assignedFilterHeaders = assignFilterHeaders(headersList);
    let obj = { ...filterObj };
    assignedFilterHeaders.map((head) => {
      obj = {
        ...obj,
        [head]: {
          retailSalesRange: { rangeBegin: '', rangeEnd: '' },
          popRetailSalesChangePct: { rangeBegin: '', rangeEnd: '' },
          avgRating: { rangeBegin: '', rangeEnd: '' },
          marketShare: { rangeBegin: '', rangeEnd: '' },
          marketShareChange: { rangeBegin: '', rangeEnd: '' },
          unitsSold: { rangeBegin: '', rangeEnd: '' },
          trafficShare: { rangeBegin: '', rangeEnd: '' },
          conversion: { rangeBegin: '', rangeEnd: '' },
          popUnitsSoldChangePct: { rangeBegin: '', rangeEnd: '' }
        }
      };
      return obj;
    });
    setFilterObj(obj);
    setTempFilterObj(obj);

    setFilterTitles(assignedFilterHeaders);

    let headerSelectedBrandManu = sendFilterHeaders(newBrandManufacturerState);

    let headerSelectedDeptCatSubCat = sendFilterHeaders(newDeptCatSubcatState);

    headerSelectedBrandManu = headerSelectedBrandManu.map((filter: string) =>
      filter === 'no selection' ? 'noSelection1' : filter
    );
    headerSelectedDeptCatSubCat = headerSelectedDeptCatSubCat.map((filter: string) =>
      filter === 'no selection' ? 'noSelection2' : filter
    );
    const selectedDiscoverFilters = [...headerSelectedBrandManu, ...headerSelectedDeptCatSubCat];

    const url = location.search.split('&');

    const newURL: string[] = [];
    url.forEach((params: string) => {
      const keyVal = params.split('=');
      if (keyVal[0] === 'discoverFilter') {
        keyVal[1] = selectedDiscoverFilters;
      }
      newURL.push(keyVal.join('='));
    });
    const finalUrl = `${location.pathname}${newURL.join('&')}`;

    history.push(finalUrl);
    const queryParams = { discoverFilter: selectedDiscoverFilters };
    await store.dispatch(updateQueryParams(app, retailerObj, mainTimePeriodObj, comparisonTimePeriodObj, queryParams));
  };

  const handleRowStyle = (params: any) => (params.node.rowIndex === rowData.length - 1 ? 'last-line' : '');

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    params.api.sizeColumnsToFit();
    params.api.refreshHeader();
  };

  const catSubCatFilterHandler = (value: number[]) => {
    const finalSubCat: any = [];
    value.forEach((cat: number) => {
      categories.forEach((allCat) => {
        if (allCat.categoryId === cat) {
          selectedSubCategory.forEach((subCat) => {
            allCat.childEntities.forEach((chileEnt) => {
              if (chileEnt.subCategoryId === subCat.subCategoryId) {
                finalSubCat.push(subCat);
              }
            });
          });
        }
      });
    });
    return finalSubCat;
  };

  const setSubCategories = (categoryIds: number[]) => {
    const subCats: SubCategoryEntity[] = [];
    const selectedCategoryIds = [...categoryIds];
    categories.forEach((category) => {
      if (selectedCategoryIds.includes(category.id)) {
        category.childEntities.forEach((subCategory) => {
          subCats.push(subCategory);
        });
      }
    });
    return subCats;
  };

  const resetSubCategory = () => {
    const allSubCatIds: number[] = [];
    categories.forEach((cate) => allSubCatIds.push(cate.id));
    const allSubCat = setSubCategories(allSubCatIds);
    setSubCategoryBasedOnCategorySelection(allSubCat);
  };

  useEffect(() => {
    const url = decodeURIComponent(location.search);
    if (url && url.indexOf('catFilter') === -1 && selectedCategory.length === 0) {
      resetSubCategory();
    }
  }, [categories]);

  const handleBrandChange = (selection: { id: number; value: string }, index: number) => {
    const clonedBrands = [...brandChips];
    const isAlreadySelected = clonedBrands.filter((brand) => brand.n === selection.value).length > 0;
    if (index !== -1 && !isAlreadySelected) {
      clonedBrands.push({ key: clonedBrands.length, i: selection.id, n: selection.value });
      setBrandChips(clonedBrands);
      let addURL = '';
      clonedBrands.forEach((brand, i) => {
        addURL += `${brand.i}-${brand.n}`;
        if (i !== clonedBrands.length - 1) {
          addURL += ';';
        }
      });
      const url = location.search.split('&');
      let urlStr = '';
      const newURL: string[] = [];
      if (location.search.indexOf('brandIdFilter') !== -1) {
        url.forEach((params: string) => {
          const keyVal = params.split('=');
          if (keyVal[0] === 'brandIdFilter') {
            keyVal[1] = encodeURI(addURL);
          }
          newURL.push(keyVal.join('='));
        });
        urlStr = newURL.join('&');
      } else if (clonedBrands.length > 0) {
        urlStr = `${location.search}&brandIdFilter=${encodeURI(addURL)}`;
      } else {
        urlStr = `${location.search}`;
      }
      const finalUrl = `${location.pathname}${urlStr}`;
      history.push(finalUrl);
    }
  };

  const handleCategoryChange = (_event: any, values: CategoryEntity[]) => {
    let finalValues = values.map((value) => value.id);
    if (!isSuperUser) {
      setSelectedSubCategory(catSubCatFilterHandler(finalValues));
    }
    setSelectedCategory(values);
    if (!isSuperUser) {
      const subCats = setSubCategories(finalValues);
      setSubCategoryBasedOnCategorySelection(subCats);
    }
    let hasAllCat = false;

    const allCatObj: CategoryEntity[] = [];

    // TODO: replace with .find()
    values.forEach((val) => {
      if (val.categoryId === 0) {
        hasAllCat = true;
        allCatObj.push(val);
      }
    });

    if (hasAllCat) {
      const allSubCatIds: number[] = [];
      if (!isSuperUser) {
        categories.forEach((cate) => allSubCatIds.push(cate.id));
        const allSubCat = setSubCategories(allSubCatIds);
        setSubCategoryBasedOnCategorySelection(allSubCat);
      }
      setSelectedCategory(allCatObj);
      finalValues = [0];
    }

    const url = location.search.split('&');
    let urlStr = '';
    const newURL: string[] = [];
    if (location.search.indexOf('catFilter') !== -1) {
      url.forEach((params: string) => {
        const keyVal = params.split('=');
        if (keyVal[0] === 'catFilter') {
          keyVal[1] = finalValues.join(',');
        }
        newURL.push(keyVal.join('='));
      });
      urlStr = newURL.join('&');
    } else if (values.length > 0) {
      urlStr = `${location.search}&catFilter=${finalValues.join(',')}`;
    } else {
      urlStr = `${location.search}`;
    }
    const finalUrl = `${location.pathname}${urlStr}`;
    history.push(finalUrl);
  };

  const handleSubCategoryChange = (_event: any, values: any) => {
    const finalValues = values.map((value) => value.id);
    setSelectedSubCategory(values);
    const url = location.search.split('&');
    let urlStr = '';
    const newURL: string[] = [];
    if (location.search.indexOf('subCatFilter') !== -1) {
      url.forEach((params: string) => {
        const keyVal = params.split('=');
        if (keyVal[0] === 'subCatFilter') {
          keyVal[1] = finalValues.join(',');
        }
        newURL.push(keyVal.join('='));
      });
      urlStr = newURL.join('&');
    } else {
      urlStr = `${location.search}&subCatFilter=${finalValues.join(',')}`;
    }
    const finalUrl = `${location.pathname}${urlStr}`;
    history.push(finalUrl);
  };

  const clearFilters = (isDialogHeaderClear?: boolean) => {
    const newBrandManuState = [...isBrandManufacturerActive];
    const newDeptCatSubcatState = [...isDepartCatSubcatActive];
    const headerSelectedBrandManu = sendFilterHeaders(newBrandManuState);
    const headerSelectedDeptCatSubCat = sendFilterHeaders(newDeptCatSubcatState);
    const finalFilterHeaders = [...headerSelectedBrandManu, ...headerSelectedDeptCatSubCat];
    const assignedFilterHeaders = assignFilterHeaders(finalFilterHeaders);

    let obj = {};
    assignedFilterHeaders.map((head) => {
      obj = {
        ...obj,
        [head]: {
          retailSalesRange: { rangeBegin: '', rangeEnd: '' },
          popRetailSalesChangePct: { rangeBegin: '', rangeEnd: '' },
          avgRating: { rangeBegin: '', rangeEnd: '' },
          marketShare: { rangeBegin: '', rangeEnd: '' },
          marketShareChange: { rangeBegin: '', rangeEnd: '' },
          unitsSold: { rangeBegin: '', rangeEnd: '' },
          trafficShare: { rangeBegin: '', rangeEnd: '' },
          conversion: { rangeBegin: '', rangeEnd: '' },
          popUnitsSoldChangePct: { rangeBegin: '', rangeEnd: '' }
        }
      };
      return obj;
    });
    setSelectedCategory([]);
    setSelectedSubCategory([]);
    setFilterObj(obj);
    setTempFilterObj(obj);

    setTempMoreFilter({
      brands: [],
      cats: [],
      subcats: []
    });

    if (!isDialogHeaderClear) {
      setDFilterObj(obj);
    }
    setBrandChips([]);
    setHelperText(initError);
    setIsDisabled(false);
    // Reset sub categories
    resetSubCategory();

    // TODO: navigate back to base URL
    const finalUrl = `${location.pathname}?rid=${retailerObj.id}&discoverFilter=brand%2Ccategory`;
    history.push(finalUrl);
    const queryParams = { discoverFilter: [] };
    dispatch(updateQueryParams(app, retailerObj, mainTimePeriodObj, comparisonTimePeriodObj, queryParams));
  };

  useEffect(() => {
    if (dFilterObj && Object.keys(dFilterObj).length > 0) {
      initialApiCallConfig(true);
    }
  }, [dFilterObj]);

  useEffect(() => {
    if (callApi) {
      initialApiCallConfig(true);
      setCallApi(false);
    }
  }, [callApi]);

  const onApplyFilter = () => {
    setTempMoreFilter({
      brands: brandChips.map((val) => `${val.i} - ${val.n}`),
      cats: selectedCategory.map((val) => val.id),
      subcats: selectedSubCategory.map((val) => val.id)
    });
  };

  /* ------ start URL -> State translation ------ */
  const loadCategories = (values: string[]) => {
    const intValArr: typeof categories = [];
    const catIds: number[] = [];
    categories.forEach((cat) => {
      if (values.map((val) => parseInt(val, 10)).includes(cat.categoryId)) {
        intValArr.push(cat);
        catIds.push(cat.categoryId);
      }
    });
    setSelectedCategory(intValArr);

    const subCatIds = setSubCategories(catIds);
    setSubCategoryBasedOnCategorySelection(subCatIds);
    if (catIds.includes(0)) {
      const allSubCatIds: number[] = [];
      categories.forEach((cate) => allSubCatIds.push(cate.id));
      const allSubCat = setSubCategories(allSubCatIds);
      setSubCategoryBasedOnCategorySelection(allSubCat);
    }
  };

  const loadSubCategories = (values: string[]) => {
    const intValArr: SubCategoryEntity[] = [];
    subCategories.forEach((cat) => {
      if (values.map((val) => parseInt(val, 10)).includes(cat.subCategoryId)) {
        intValArr.push(cat);
      }
    });
    setSelectedSubCategory(intValArr);
  };

  const loadBrands = (values: string[]) => {
    const brands: { i: number; n: string }[] = [];
    values.forEach((brand) => {
      const newValues = brand.split('-');
      const b = { i: newValues[0], n: newValues[1] };
      brands.push(b);
    });
    setBrandChips(brands);
  };

  // This hook handles hydration of some of the filters from the URL
  useEffect(() => {
    const url = decodeURIComponent(location.search);
    const filters = url.split('&');
    let urlTempValues = { brands: [] as string[], cats: [] as number[], subcats: [] as number[] };
    filters.forEach((filter: string) => {
      if (filter.includes('discoverFilter')) {
        const value = filter.split('=')[1];
        const values = value.split(',');
        let newBrandManuState = [...isBrandManufacturerActive];
        let newDeptCatSubcatState = [...isDepartCatSubcatActive];
        const newFilterTiles: string[] = [];
        let obj = { ...filterObj };
        newBrandManuState = newBrandManuState.map((state) => {
          state.isActive = values.includes(state.name);
          return state;
        });
        newDeptCatSubcatState = newDeptCatSubcatState.map((state) => {
          state.isActive = values.includes(state.name);
          return state;
        });
        let columns = [...colDef];
        const index = columns.findIndex((col) => col.field === 'brandName');
        const manIndex = columns.findIndex((col) => col.field === 'manufacturerName');
        const catIndex = columns.findIndex((col) => col.field === 'categoryName');
        const subcatIndex = columns.findIndex((col) => col.field === 'subCategoryName');
        const deptIndex = columns.findIndex((col) => col.field === 'departmentName');
        newBrandManuState = newBrandManuState.map((state) => {
          const values1 = values.map((val) => (['noSelection1'].includes(val) ? 'no selection' : val));
          state.isActive = values1.includes(state.name);
          if (state.isActive) {
            if (state.name === 'manufacturer') {
              columns[manIndex].hide = false;
            } else {
              columns[manIndex].hide = true;
            }
            if (state.name === 'brand') {
              columns[index].hide = false;
            } else {
              columns[index].hide = true;
            }

            newFilterTiles.push(state.name);
          }
          return state;
        });
        newDeptCatSubcatState = newDeptCatSubcatState.map((state) => {
          const name = state.name === 'noSelection2' ? 'no selection' : state.name;
          const values2 = values.map((val) => (['noSelection2'].includes(val) ? 'no selection' : val));
          state.isActive = values2.includes(name);
          if (state.isActive) {
            if (state.name === 'department') {
              columns[deptIndex].hide = false;
            } else if (state.name === 'category') {
              columns[catIndex].hide = false;
            } else if (state.name === 'subcategory') {
              columns[subcatIndex].hide = false;
            }
            newFilterTiles.push(state.name);
          }
          return state;
        });
        const selected: string[] = [];
        newBrandManuState.forEach((clip) => {
          if (clip.isActive) {
            selected.push(clip.name);
          }
        });

        if (!(selected.includes('brand') || selected.includes('manufacturer'))) {
          columns.forEach((col, colIndex) => {
            if (colIndex > 4) {
              col.hide = ['marketShare', 'marketShareChange', 'trafficShare'].includes(col.field);
            }
          });
        } else {
          columns.forEach((col, colIndex) => {
            if (colIndex > 4) {
              col.hide = false;
            }
          });
        }

        columns = sortColumnArrangement(columns);
        setColDef(columns);
        const headerSelectedBrandManu = sendFilterHeaders(newBrandManuState);
        const headerSelectedDeptCatSubCat = sendFilterHeaders(newDeptCatSubcatState);
        const finalFilterHeaders = [...headerSelectedBrandManu, ...headerSelectedDeptCatSubCat];
        const assignedFilterHeaders = assignFilterHeaders(finalFilterHeaders);
        assignedFilterHeaders.map((head) => {
          obj = {
            ...obj,
            [head]: {
              retailSalesRange: { rangeBegin: '', rangeEnd: '' },
              popRetailSalesChangePct: { rangeBegin: '', rangeEnd: '' },
              avgRating: { rangeBegin: '', rangeEnd: '' },
              marketShare: { rangeBegin: '', rangeEnd: '' },
              marketShareChange: { rangeBegin: '', rangeEnd: '' },
              unitsSold: { rangeBegin: '', rangeEnd: '' },
              trafficShare: { rangeBegin: '', rangeEnd: '' },
              conversion: { rangeBegin: '', rangeEnd: '' },
              popUnitsSoldChangePct: { rangeBegin: '', rangeEnd: '' }
            }
          };
          return obj;
        });
        if (location.state && Object.keys(location.state).length > 0) {
          const stateObj: Record<string, any> = {};
          const initFilters = {
            ...DEFAULT_BASE_FILTER_OBJ
          };
          Object.keys(location.state).forEach((key: string) => {
            stateObj[key] = { ...initFilters, ...location.state[key] };
          });
          obj = { ...obj, ...stateObj };

          setDFilterObj(obj);
        }
        setFilterObj(obj);
        setTempFilterObj(obj);
        setFilterTitles(assignedFilterHeaders);
        setIsBrandManufacturerActive(newBrandManuState);
        setIsDepartCatSubcatActive(newDeptCatSubcatState);
      } else if (filter.includes('catFilter')) {
        const value = filter.split('=')[1];
        const values = value.split(',');
        urlTempValues = { ...urlTempValues, cats: values };
        loadCategories(values);
      } else if (filter.includes('subCatFilter')) {
        const value = filter.split('=')[1];
        const values = value.split(',');
        urlTempValues = { ...urlTempValues, subcats: values };
        loadSubCategories(values);
      } else if (filter.includes('brandIdFilter')) {
        const value = filter.split('=')[1];
        const decodedVal = decodeURI(value);
        const allSelBrands = decodedVal.split(';');
        urlTempValues = { ...urlTempValues, brands: allSelBrands };
        loadBrands(allSelBrands);
      }
    });
    setTempMoreFilter(urlTempValues);
    setCallApi(true);
  }, [categories, subCategories]);

  /* ------ start URL -> State translation ------ */

  // only load subcategories for the selected categories
  useEffect(() => {
    if (subCategoryBasedOnCategorySelection.length > 0 && isSubCatLoaded) {
      const url = decodeURIComponent(location.search);
      const filters = url.split('&');
      filters.forEach((filter: string) => {
        if (filter.includes('subCatFilter')) {
          const value = filter.split('=')[1];
          const values = value.split(',');
          const intValArr: SubCategoryEntity[] = [];
          subCategories.forEach((cat) => {
            if (values.map((val) => parseInt(val, 10)).includes(cat.subCategoryId)) {
              intValArr.push(cat);
            }
          });
          setSelectedSubCategory(intValArr);
        }
      });
      setIsSubCatLoaded(false);
    }
  }, [subCategoryBasedOnCategorySelection]);

  // Reset subcategories when we do not have any categories selected
  useEffect(() => {
    if (selectedCategory.length === 0) {
      resetSubCategory();
    }
  }, [selectedCategory]);

  const filterClick = () => {
    loadCategories(tempMoreFilter.cats);
    loadSubCategories(tempMoreFilter.subcats);
    loadBrands(tempMoreFilter.brands);

    const tempObj = JSON.parse(JSON.stringify(tempFilterObj));
    setFilterObj({ ...tempObj });
    setShowFilter((prevState) => !prevState);
  };

  const onDeleteCategory = (categoryName: string) => {
    let categoriesCopy = [...selectedCategory];
    categoriesCopy = categoriesCopy.filter((category) => category.categoryName !== categoryName);

    setSelectedCategory(categoriesCopy);
    const finalValues = categoriesCopy.map((value) => value.id);
    const url = location.search.split('&');
    let urlStr = '';
    const newURL: string[] = [];
    if (location.search.indexOf('catFilter') !== -1) {
      url.forEach((params: string) => {
        const keyVal = params.split('=');
        if (keyVal[0] === 'catFilter') {
          keyVal[1] = finalValues.join(',');
        }
        newURL.push(keyVal.join('='));
      });
      urlStr = newURL.join('&');
    } else {
      if (categoriesCopy.length > 0) {
        urlStr = `${location.search}&catFilter=${finalValues.join(',')}`;
      } else {
        urlStr = `${location.search}`;
      }
    }
    const finalUrl = `${location.pathname}${urlStr}`;
    history.push(finalUrl);
  };

  const onDeleteSubcategories = (subCategoryName: string) => {
    let subCategoriesCopy = [...selectedSubCategory];
    subCategoriesCopy = subCategoriesCopy.filter((subCategory) => subCategory.name !== subCategoryName);
    setSelectedSubCategory(subCategoriesCopy);
    const finalValues = subCategoriesCopy.map((value) => value.id);
    const url = location.search.split('&');
    let urlStr = '';
    const newURL: string[] = [];
    if (location.search.indexOf('subCatFilter') !== -1) {
      url.forEach((params: string) => {
        const keyVal = params.split('=');
        if (keyVal[0] === 'subCatFilter') {
          keyVal[1] = finalValues.join(',');
        }
        newURL.push(keyVal.join('='));
      });
      urlStr = newURL.join('&');
    } else if (subCategoriesCopy.length > 0) {
      urlStr = `${location.search}&subCatFilter=${finalValues.join(',')}`;
    } else {
      urlStr = `${location.search}`;
    }
    const finalUrl = `${location.pathname}${urlStr}`;
    history.push(finalUrl);
  };

  const gridRef = useRef<AgGridReactType>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const setLastButtonDisabled = (disabled: boolean) => {
    if (document.querySelector('#btLast') !== null) {
      // fix for null --- this should happen via react, not via manipulating the DOM manually
      (document.querySelector('#btLast') as any).disabled = disabled;
    }
  };

  const onPaginationChanged = useCallback(() => {
    // Workaround for bug in events order
    if (gridRef.current && gridRef.current.api) {
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage() + 1);
      setTotalPage(gridRef.current.api.paginationGetTotalPages());
      setLastButtonDisabled(!gridRef.current.api.paginationIsLastPageFound());
    }
  }, []);

  const onBtFirst = useCallback(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.paginationGoToFirstPage();
    }
  }, []);

  const onBtNext = useCallback(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.paginationGoToNextPage();
    }
  }, []);

  const onBtPrevious = useCallback(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.paginationGoToPreviousPage();
    }
  }, []);

  const onBtPageTen = useCallback(() => {
    // we say page 4, as the first page is zero
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.paginationGoToPage(gridRef.current.api.paginationGetCurrentPage() + 10);
    }
  }, []);

  const onBtPageBackTen = useCallback(() => {
    // we say page 4, as the first page is zero
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.paginationGoToPage(gridRef.current.api.paginationGetCurrentPage() - 10);
    }
  }, []);

  // exportCSV

  const onExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv();
  }, []);

  useBus(props.eventBus, 'exportCSV', onExport);

  return (
    <>
      {
        <>
          <DiscoverFilterDialog
            header={
              <DiscoverFilterDialogHeader
                setShowFilter={setShowFilter}
                clearFilters={clearFilters}
                tempFilterObj={tempFilterObj}
                setFilterObj={setFilterObj}
              />
            }
            footer={
              // TODO: reduce the number of props (should use redux instead where appropriate)
              <DiscoverFilterDialogFooter
                isDisabled={isDisabled}
                initialApiCallConfig={initialApiCallConfig}
                alterColumns={alterColumns}
                setColDef={setColDef}
                gridApi={gridApi}
                setShowFilter={setShowFilter}
                updateColsOnChipClick={updateColsOnChipClick}
                setTempFilterObj={setTempFilterObj}
                filterObj={filterObj}
                onApplyFilter={onApplyFilter}
              />
            }
            body={
              // TODO: reduce the number of props (should use redux instead where appropriate)
              <DiscoverFilterDialogBody
                chipFields={chipFields}
                categories={categories}
                isBrandManufacturerActive={isBrandManufacturerActive}
                handleClick={handleClick}
                isDepartCatSubcatActive={isDepartCatSubcatActive}
                filterTitles={filterTitles}
                filterObj={filterObj}
                filterContent={filterContent}
                setIsDisabled={setIsDisabled}
                isSuperUser={isSuperUser}
                superUserCategories={superUserCategories}
                superUserSubCategories={superUserSubCategories}
                selectedCategory={selectedCategory}
                selectedSubCategory={selectedSubCategory}
                subCategoryBasedOnCategorySelection={subCategoryBasedOnCategorySelection}
                onDeleteCategory={onDeleteCategory}
                onDeleteSubcategories={onDeleteSubcategories}
                brandChips={brandChips}
                setBrandChips={setBrandChips}
                setHelperText={setHelperText}
                helperText={helperText}
                handleBrandChange={handleBrandChange}
                handleCategoryChange={handleCategoryChange}
                handleSubCategoryChange={handleSubCategoryChange}
              />
            }
            showFilter={showFilter}
            setShowFilter={setShowFilter}
          />
          <div className="discoverContainer">
            <div className="discoverStackContainer">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '30px' }}>
                {!hasLoaded && (
                  <Button className="filterToggle " onClick={filterClick}>
                    <FilterIcon style={{ margin: '0 10px 0 0' }} />
                    <span>FILTER</span>
                  </Button>
                )}
                {!hasLoaded && hasAppliedFilters ? (
                  <Button className="filterToggle" onClick={() => clearFilters()} disabled={hasLoaded}>
                    <ClearFilters style={{ margin: '0 10px 0 0' }} />
                    <span>CLEAR ALL FILTERS</span>
                  </Button>
                ) : null}
              </div>
              <div className="discoverDateRange">
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <div
                    style={{
                      height: '9px',
                      width: '9px',
                      borderRadius: '50%',
                      margin: '5px',
                      marginRight: '8px',
                      background: 'rgb(70, 168, 246)'
                    }}
                  ></div>
                  <span>{mainTimePeriodObj.displayName}</span>
                </div>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      height: '9px',
                      width: '9px',
                      borderRadius: '50%',
                      margin: '5px',
                      marginRight: '8px',
                      background: 'rgb(173, 189, 204)'
                    }}
                  ></div>
                  <span>{comparisonTimePeriodObj.displayName}</span>
                </div>
              </div>
            </div>

            {hasLoaded ? (
              <DiscoverSkeleton />
            ) : (
              <div
                className="discoverGridContainer"
                style={{
                  width: '100%',
                  marginLeft: '5em',
                  marginRight: '5em'
                }}
              >
                <Box>
                  <AgGridReact
                    ref={gridRef}
                    className="ag-theme-material discover-table"
                    domLayout="normal"
                    rowData={rowData}
                    rowHeight={55}
                    headerHeight={88}
                    getRowClass={handleRowStyle}
                    onGridReady={onGridReady}
                    applyColumnDefOrder
                    pagination
                    enableRangeSelection
                    enableRangeHandle
                    paginationPageSize={50}
                    suppressMovableColumns
                    suppressPaginationPanel
                    onPaginationChanged={onPaginationChanged}
                    defaultCsvExportParams={{ fileName: 'Stackline-Discover.csv' }}
                  >
                    {colDef.map((column) => {
                      return <AgGridColumn {...column} key={column.field}></AgGridColumn>;
                    })}
                  </AgGridReact>
                  <DiscoverPaginationGroup
                    onBtFirst={onBtFirst}
                    onBtPageBackTen={onBtPageBackTen}
                    onBtNext={onBtNext}
                    onBtPageTen={onBtPageTen}
                    onBtPrevious={onBtPrevious}
                    currentPage={currentPage}
                    totalPage={totalPage}
                  />
                </Box>
              </div>
            )}
          </div>
        </>
      }
    </>
  );
};

// TODO: convert this to hooks
Discover.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
const EnhancedDiscover = withRouter(withBus('eventBus')(Discover));
export default EnhancedDiscover;
