/* eslint-disable react/require-default-props */
import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { getCommonColumns } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';
import 'src/components/AdCampaignBuilder/Widgets/TargetEntitiesGrid/TargetEntitiesGrid.scss';
import 'src/components/EntityGrid/Table/EntityTableContainer.scss';
import AdPaginationGroup from 'src/components/AdManager/AdPaginationGroup';
import { MetricField } from 'src/types/application/types';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import { withRouter } from 'react-router';
import { Location } from 'history';
import { Widget } from 'src/types/application/widgetTypes';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import Export from 'src/components/Export';
import { SearchGridConstants } from './GridDataFetchers/SearchGridConstants';
import { AdCampaignBuilderCheckbox } from 'src/components/AdCampaignBuilder/Widgets/Checkbox';
import { FormData } from 'src/utils/segments';
import EntityStatusColumn from 'src/components/AdManager/Search/CustomColumns/EntityStatusColumn';
import SearchResultsActionBar from 'src/components/AdManager/Search/SearchResultsActionBar';
import _uniq from 'lodash/uniq';
import { NoResultWithOpenFilter } from 'src/components/Grids/Data/NoResultTemplate';
import _isEmpty from 'lodash/isEmpty';

export interface SrgApi {
  getPage: () => number;
  getSortField: () => string;
  getSortDirection: () => string;
  setPage: (newPage: number) => void;
  setSortField: (newSortField: string) => void;
  setSortDirection: (newSortDirection: string) => void;
}

type MaybePromise<T> = T | Promise<T>;

export interface SearchResultsGridV3Props<T> {
  /**
   * If false when `selectable` is true, it will not render
   * the checkbox in the header. It is true by default.
   */
  allowSelectAll?: boolean;
  /** The data to be displayed in the table (rows) */
  data: T[];

  /**
   * Rows to be pinned at the top of the table representing
   * the totals of all the data in the rows.
   */
  dataTotalRow: T[];

  /** The column definitions for the table */
  columnDefs: ColDef[];

  /** A flag indicating if rows should be selectable with a checkbox */
  selectable?: boolean;

  /** A flag indicating if rows should be disabled for status change or checkbox select */
  editDisabled?: boolean;

  /**
   * Callback for when the list of selected items changes. Will be called
   * with the set of all item ids that remain selected.
   */
  onSelectChanged?: (selectedIds: string[]) => void;

  /** A callback function to determine if an item is selected */
  isSelected?: (item: T) => boolean;

  /**
   * A list of ids for each item that is selected
   */
  selectedItemIds?: string[];

  /** A flag indicating if the table should have a toggle switch at the end */
  togglable?: boolean;

  /** A callback function called when the toggle status changes */
  onToggle?: (item: T, toggleStatus: boolean) => MaybePromise<void>;

  /** The total number of items for pagination */
  totalItems: number;

  /** How many rows should be shown at once */
  rowsPerPage?: number;

  /** A callback function called when the current page changes */
  onPageChanged?: (pageNumber: number) => MaybePromise<void>;

  /** Function for getting the unique id of each row. */
  getRowNodeId: GridOptions['getRowNodeId'];

  /** Passes in the API for pagination, selected items */
  onReady?: (api: SrgApi) => MaybePromise<void>;

  /** Callback for when sort direction or field is changed. */
  onSortChanged?: (sortFieldName: string, sortDirection: string) => MaybePromise<void>;

  /** Optional custom AgGrid settings */
  gridOptions?: Partial<GridOptions>;

  /**
   * Displays a loading indicator when true
   */
  loading?: boolean;

  /**
   * API request used to fetch data so that data export
   * is properly handled
   */
  apiRequest?: any[];

  formData?: FormData;

  /**
   * Callback for when the full data set should be refreshed,
   * like when the filters, sort order, and sort direction change.
   * This will reset the page back to 1
   */
  onResetData?: () => void;

  widget: Widget;
}

/**
 * ID to designate the row at the top of the table
 * showing the data totals
 */
const TOTAL_ROW_ID = 'total_row';

const ROW_HEIGHT = 120;

/**
 * Controlled component for displaying a list of entities in a grid.
 * All rows to be displayed should be passed in with the `data` prop.
 * This component should never contain any business logic for fetching
 * the data it displays - it is purely presentational.
 *
 * It exposes common fields like the current page, sort field, and sort direction
 * through an api object that will be given in the callback to `onReady`. That
 * way every implementation will not have to duplicate logic for updating
 * the page, sort column, etc.
 */
export const SearchResultsGridV3Inner = <T extends object>({
  allowSelectAll = true,
  data,
  dataTotalRow,
  columnDefs,
  getRowNodeId,
  totalItems,
  rowsPerPage = SearchGridConstants.AD_SEARCH_PAGINATION_SIZE,
  onReady,
  onSortChanged,
  eventBus,
  loading,
  apiRequest,
  selectable,
  editDisabled,
  formData,
  togglable,
  widget,
  onResetData,
  onSelectChanged = () => {},
  selectedItemIds
}: SearchResultsGridV3Props<T> & { eventBus: EventBus; location: Location }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortFieldName, setSortFieldName] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const gridApi = useRef<GridApi>(null);
  const [{ displayName }] = widget.data.groupByFields;
  const { hideSearchResultsActionBar } = widget.data;

  /* This is a ref because the cell renderer for a checkbox doesn't work
     when this is a state variable. Might be a bug with AG Grid v25 */
  const selectedItemIdsRef = useRef<string[]>([]);

  // TODO delete when migration is complete
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('GridV3');
  }, []);

  /**
   * Keep selected items ref up to date with the prop. If it changes,
   * we have to force cell refresh because AG Grid doesn't do it
   * on its own.
   */
  useEffect(() => {
    selectedItemIdsRef.current = selectedItemIds;
    if (gridApi.current) {
      gridApi.current.refreshCells({ force: true });
    }
  }, [selectedItemIds]);

  const getPage = useCallback(() => currentPage, [currentPage]);
  const getSortField = useCallback(() => sortFieldName, [sortFieldName]);
  const getSortDirection = useCallback(() => sortDirection, [sortDirection]);

  // Provide updates to page, sort direction and sort field to parent
  useEffect(() => {
    if (onReady) {
      onReady({
        getPage,
        getSortDirection,
        getSortField,
        setPage: (newPage: number) => setCurrentPage(newPage),
        setSortDirection: (newSortDirection: string) => setSortDirection(newSortDirection),
        setSortField: (newSortField: string) => setSortFieldName(newSortField)
      });
    }
  }, [getPage, getSortDirection, getSortField, onReady]);

  // Communicate that the table is done loading to
  // SRG header
  useEffect(() => {
    if (data && !loading) {
      eventBus.emit('setSearchHeader', {
        isLoading: false,
        entity: {
          result: {
            data,
            apiRequest
          },
          displayName,
          totalCount: totalItems
        }
      });
    } else if (loading) {
      eventBus.emit('setSearchHeader', {
        isLoading: true,
        entity: { displayName: '', totalCount: 0 }
      });
    }
  }, [eventBus, data, loading, totalItems, apiRequest, displayName]);

  // When form data changes, set page to 1 and reset checked items
  useEffect(() => {
    setCurrentPage(1);
    onSelectChanged([]);
    if (onResetData) {
      onResetData();
    }
  }, [formData, sortFieldName, sortDirection, onResetData, onSelectChanged]);

  const totalPages = useMemo(() => {
    return rowsPerPage > 0 ? Math.ceil(totalItems / rowsPerPage) : 0;
  }, [totalItems, rowsPerPage]);

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  /**
   * Returns true if all the items on the current page are selected,
   * so the header checkbox should be checked too
   */
  const getIsHeaderCheckboxSelected = () => {
    if (!selectable || !data || data.length === 0) {
      return false;
    }
    const dataIds = data.map((row) => getRowNodeId(row));

    // There can be items checked on other pages, so we just want to show the header as
    // checked if all the items on the current page are selected
    return dataIds.every((id) => selectedItemIds.includes(id));
  };

  /**
   * Compute the column for the checkbox column if the grid is selectable
   */
  const getSelectColumn = (): ColDef[] => {
    return selectable
      ? [
          {
            field: 'stacklineSku',
            width: undefined,
            cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
            minWidth: 100,
            maxWidth: 100,
            cellRendererFramework: (params) => {
              const nodeId = getRowNodeId(params.data);

              if (nodeId === TOTAL_ROW_ID) {
                return null;
              }

              return (
                <AdCampaignBuilderCheckbox
                  checked={selectedItemIdsRef.current.includes(nodeId)}
                  disabled={editDisabled}
                  onChange={(_evt, checked) => {
                    const allChecked = getIsHeaderCheckboxSelected();
                    if (checked) {
                      onSelectChanged(_uniq([...selectedItemIdsRef.current, nodeId]));
                    } else {
                      onSelectChanged(_uniq(selectedItemIdsRef.current.filter((i) => i !== nodeId)));
                    }

                    if (gridApi.current) {
                      gridApi.current.refreshCells({ force: true });

                      // Header flickers so only refresh header if it status should change
                      if (allChecked !== getIsHeaderCheckboxSelected()) {
                        gridApi.current.refreshHeader();
                      }
                    }
                  }}
                />
              );
            },
            headerComponentFramework: () => {
              return allowSelectAll ? (
                <AdCampaignBuilderCheckbox
                  disabled={editDisabled}
                  checked={getIsHeaderCheckboxSelected()}
                  onChange={(_evt, checked) => {
                    const dataIds = data.map((item) => getRowNodeId(item));
                    if (checked) {
                      onSelectChanged(_uniq([...selectedItemIds, ...dataIds]));
                    } else {
                      onSelectChanged(_uniq(selectedItemIds.filter((id) => !dataIds.includes(id))));
                    }

                    if (gridApi.current) {
                      gridApi.current.refreshCells({ force: true });
                      gridApi.current.refreshHeader();
                    }
                  }}
                />
              ) : null;
            },
            pinned: 'left'
          } as ColDef
        ]
      : [];
  };

  if (loading) {
    return <GridLoading />;
  }

  return (
    <div
      className="target-keywords-grid search-results-grid-v2"
      data-component-type="search-result-grid"
      style={{ height: '48vh' }}
    >
      {apiRequest && (
        <Export
          exportRequest={apiRequest}
          apiUrl="/api/advertising/AdvancedSearchExport"
          className="grid-header__action-button"
        />
      )}
      {!hideSearchResultsActionBar && (
        // hide action buttons on SA page
        <SearchResultsActionBar widget={widget} hideActionButtons={window.location.pathname === '/home'} />
      )}
      {_isEmpty(data) ? (
        <NoResultWithOpenFilter />
      ) : (
        <CustomAgMaterial
          onGridReady={onGridReady}
          buildRows={() => data}
          columnDefs={[
            ...getSelectColumn(),
            // When selecting all is not permitted, add a class that will
            // fix the positioning of the first column, otherwise it gets messed up
            ...(columnDefs as MetricField[]),
            ...getCommonColumns(sortFieldName, sortDirection, (newSortBy, newSortDirection) => {
              setSortFieldName(newSortBy);
              setSortDirection(newSortDirection);
              setCurrentPage(1);
              if (onSortChanged) {
                onSortChanged(newSortBy, newSortDirection);
              }
            }),
            ...(togglable
              ? [
                  {
                    headerName: 'Status',
                    field: 'stacklineSku',
                    width: undefined,
                    enableRtl: true,
                    cellStyle: { 'justify-content': 'flex-end', 'text-align': 'left' },
                    minWidth: 100,
                    maxWidth: 100,
                    cellRendererFramework: (args) => {
                      return <EntityStatusColumn disabled={editDisabled} {...args} />;
                    },
                    pinned: 'right'
                  } as ColDef
                ]
              : [])
          ]}
          getRowNodeId={getRowNodeId}
          domLayout="normal"
          containerStyle={{ width: '100%' }}
          suppressNoRowsOverlay
          pinnedTopRowData={(dataTotalRow || []).map((el) => ({
            ...el,
            id: TOTAL_ROW_ID,
            disableToggle: true
          }))}
          getRowHeight={(params) => {
            const {
              data: { id }
            } = params;
            if (id === TOTAL_ROW_ID) {
              return ROW_HEIGHT / 2;
            }
            return ROW_HEIGHT;
          }}
          applyColumnDefOrder
          pagination
          enableRangeSelection
          paginationPageSize={50}
          cacheBlockSize={100}
          cacheOverflowSize={2}
          maxBlocksInCache={4}
          suppressPaginationPanel
          alwaysShowHorizontalScroll
          headerHeight={54}
        />
      )}
      {totalItems > 0 && (
        <AdPaginationGroup
          onBtFirst={() => {
            setCurrentPage(1);
          }}
          onBtNext={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1);
            }
          }}
          onBtPrevious={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
          currentPage={currentPage}
          totalPage={totalPages}
          isLoading={false}
        />
      )}
    </div>
  );
};

export default withRouter(withBus('eventBus')(SearchResultsGridV3Inner)) as unknown as React.FC<
  SearchResultsGridV3Props<any>
>;
