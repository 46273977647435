import { Dispatch } from 'react';
import { adEntityOperations } from 'src/store/modules/adManager/adEntities';
import { IGridResultData } from 'src/components/AdManager/Search/Models/IGridResultData';
import { IFetchEntityMetricsAndParseResponseParams } from 'src/components/AdManager/Search/Models/IFetchEntityMetricsAndParseResponseParams';
import {
  fetchEntityMetricsAndParseResponse,
  getClonedResult
} from 'src/components/AdManager/Search/GridDataFetchers/FetchDataForGroupByField';
import { CancelTokenSource } from 'axios';

interface FetchRetailersDataArgs {
  dispatch: Dispatch<any>;
  fetchMetricsParams: IFetchEntityMetricsAndParseResponseParams;
  dataSet: any;
  pageNumber: number;
  cancelSource: CancelTokenSource;
  returnEntitiesWithNoMetrics?: boolean;
  importantOverrides?: any;
}
export class RetailersDataFetcher {
  // eslint-disable-next-line class-methods-use-this
  public async fetchRetailersData({
    dispatch,
    fetchMetricsParams,
    dataSet,
    pageNumber,
    cancelSource,
    returnEntitiesWithNoMetrics = true,
    importantOverrides
  }: FetchRetailersDataArgs): Promise<IGridResultData> {
    const result = await fetchEntityMetricsAndParseResponse(
      fetchMetricsParams,
      dispatch,
      cancelSource,
      null,
      importantOverrides
    );
    const cloneResult = getClonedResult(result, pageNumber, dataSet);

    // when it is on the entity page, also get all the entity ids which don't have metrics
    if (returnEntitiesWithNoMetrics) {
      const allEntityIds = result.data.map((entity: any) => entity.id);
      const allEntities = await dispatch(adEntityOperations.fetchAdEntities());
      const allEntityIdSet = new Set(allEntityIds);
      allEntities.adEntities.forEach((adEntity: any) => {
        const entityId = adEntity.id || adEntity.entityId;
        if (!allEntityIdSet.has(entityId)) {
          cloneResult.data.push({
            entity: { ...adEntity, name: adEntity.name, type: 'adEntity' },
            id: entityId.toLowerCase()
          });
        }
      });
    }

    return {
      gridResult: cloneResult
    };
  }
}
