import { ADVANCED_SEARCH_KEYWORD_PARAMS } from 'src/utils/constants';

/**
 * Return true if the URL query parameters contain all the parameters
 * that indicate that the user searched for a keyword in the top navigation
 * bar
 */
export const isAdvancedSearchByKeyword = (queryParams: { [key: string]: any; searchParams?: string }) => {
  const queryParamSet = new Set(Object.keys(queryParams));
  return ADVANCED_SEARCH_KEYWORD_PARAMS.every((param) => queryParamSet.has(param));
};

/**
 * Returns true if the user is currently on the edit scheduled action page
 */
export const isEditScheduledActionPage = (search: string): boolean => {
  const queryParams = new URLSearchParams(search);
  return queryParams.get('tab') === 'adScheduledActions' && queryParams.get('subtab') === 'edit';
};
