import { Parser } from 'expr-eval';

import {
  DATATYPE,
  METRICTYPE,
  FIELD_PATH_SEPARATOR,
  FieldDefinition
} from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

export const SALES_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  purchaseRate: {
    name: 'purchaseRate',
    displayName: 'Conversion Rate',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'derived',
    aggregationFunction: expressionParser.parse(
      `traffic${FIELD_PATH_SEPARATOR}totalClicks>0?sales${FIELD_PATH_SEPARATOR}unitsSold/traffic${FIELD_PATH_SEPARATOR}totalClicks:0`
    ),
    aggregationFunctionExpressionString: `traffic${FIELD_PATH_SEPARATOR}totalClicks>0?sales${FIELD_PATH_SEPARATOR}unitsSold/traffic${FIELD_PATH_SEPARATOR}totalClicks:0`,
    dependantFieldOverrides: {
      primaryDependantFieldName: 'unitsSold'
    },
    overrides: {
      stacklineSku_Override: {
        aggregationFunctionType: 'derived',
        aggregationFunction: expressionParser.parse(
          `traffic${FIELD_PATH_SEPARATOR}totalClicks>0?sales${FIELD_PATH_SEPARATOR}unitsSold/traffic${FIELD_PATH_SEPARATOR}totalClicks:0`
        ),
        aggregationFunctionExpressionString: `traffic${FIELD_PATH_SEPARATOR}totalClicks>0?sales${FIELD_PATH_SEPARATOR}unitsSold/traffic${FIELD_PATH_SEPARATOR}totalClicks:0`,
        dependantFieldOverrides: {
          primaryDependantFieldName: 'unitsSold',
          overridesByFieldName: {
            [`traffic${FIELD_PATH_SEPARATOR}totalClicks`]: {
              dataFetchStrategy: 'chained',
              groupByFieldName: 'stacklineSku',
              groupByResultType: 'multivalue'
            }
          }
        }
      }
    }
  }
};
