import types from './types';

const initialState = {
  category: null,
  segment: null,
  platform: null,
  subcategory: null,
  retailPrice: null,
  businessUnits: null,
  isClearingFilters: false
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_FILTERS:
      return {
        ...action.filters,
        isClearingFilters: false
      };
    case types.CLEAR_FILTERS:
      return {
        ...action.filters,
        isClearingFilters: true
      };
    case types.UPDATE_IS_CLEARING_FILTERS_BOOL:
      return Object.assign({}, state, { isClearingFilters: action.bool });
    default:
      return state;
  }
}
