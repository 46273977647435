import React, { useState } from 'react';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import colors from 'src/utils/colors';
import { SbaLogo, nameNotValid } from 'src/components/AdCampaignBuilder/Steps/CreativeHeadline';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateSBAProfileReview } from 'src/store/modules/entityService/operations';
import AppSnackbar from 'src/components/AdManager/Common/AppSnackbar/AppSnackbar';

const useStyles = makeStyles({
  sectionContainer: {
    marginBottom: 100
  },
  section: {
    paddingBottom: 75
  },
  sectionTitle: {
    fontSize: 25,
    marginBottom: 8
  },
  sectionColumnContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 10fr',
    gap: '1em',
    margin: '10px 0',
    '& div:first-child': {
      fontWeight: 'bold'
    }
  },
  reviewStatus: {
    textTransform: 'capitalize'
  },
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.lightestGrey}`,
    paddingBottom: 20
  },
  formField: {
    padding: '15px 0'
  },
  formFieldLabel: {
    fontSize: 20,
    margin: '20px 0'
  },
  formFieldError: {
    color: colors.red
  },
  submitButtonRoot: {
    background: colors.stacklineBlue,
    height: 40,
    padding: '0px 27px',
    '&:hover': {
      backgroundColor: colors.stacklineBlue
    }
  },
  submitButtonLabel: {
    color: colors.lightestGrey,
    lineHeight: 1.7,
    textTransform: 'uppercase'
  },
  disabledButtonRoot: {
    backgroundColor: colors.lightestGrey,
    '& span': {
      color: colors.lightGrey
    }
  },
  uploadLogoWrapper: {
    margin: '50px 0 0'
  }
});

interface Review {
  reviewId: number | string;
  reviewStatus: string;
  reviewReason: string;
}

export const isReview = (obj: any): obj is Review => {
  if (
    typeof obj === 'object' &&
    'reviewId' in obj &&
    ['number', 'string'].includes(typeof obj.reviewId) &&
    'reviewStatus' in obj &&
    typeof obj.reviewStatus === 'string' &&
    'reviewReason' in obj &&
    typeof obj.reviewReason === 'string'
  ) {
    return true;
  }
  return false;
};

const FieldWapper = ({ title, children }: { title: string; children: any }) => {
  const classes = useStyles();
  return (
    <div className={classes.formField}>
      <h3 className={classes.formFieldLabel}>{title}</h3>
      {children}
    </div>
  );
};

FieldWapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const ReviewsPage = ({ entity }: { entity: any }) => {
  const initHeadline = _get(entity, 'extendedAttributes.amsApiModel.sbaProfile.headlineText', '');
  const initBrandName = _get(entity, 'extendedAttributes.amsApiModel.sbaProfile.searchAmpName', '');
  const initClickUrl = _get(entity, 'extendedAttributes.amsApiModel.sbaProfile.clickUrl', '');
  const entityId = _get(entity, 'extendedAttributes.entityId', '');
  const campaignId = _get(entity, 'extendedAttributes.amsApiModel.sbaProfile.campaignId', '');
  const adGroupId = _get(entity, 'extendedAttributes.amsApiModel.sbaProfile.adGroupId', '');

  const [headline, setHeadline] = useState(initHeadline);
  const [brandName, setBrandName] = useState(initBrandName);
  const [clickURL, setClickURL] = useState(initClickUrl);
  const [logo, setLogo] = useState('');
  const [showHeadlineError, setShowHeadlineError] = useState(false);
  const [showBrandNameError, setShowBrandNameError] = useState(false);
  const [showClickURLError, setShowClickURLError] = useState(false);
  const [isPNG, setIsPNG] = useState(true);
  const [isValidSize, setIsValidSize] = useState(true);
  const [isValidDimension, setIsValidDimension] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const dispatch = useDispatch();
  const reviews = _get(entity, 'extendedAttributes.reviews', []);
  let sbaProfile: Review | {} = {};
  let keywordReview: Review | {} = {};
  let contentReview: Review | {} = {};
  const HEADLINE_LIMIT = 45;
  const BRANDNAME_LIMIT = 35;
  const lengthCheck = headline.length > HEADLINE_LIMIT;
  const charCheck = nameNotValid(headline);
  const brandNameCheck = brandName.length > BRANDNAME_LIMIT;
  const clickURLCheck = clickURL !== '' && !clickURL.startsWith('https://www.walmart.com');
  const PROFILE_UPDATE_SUCCESS = 'SBA Profile has been updated.';
  const PROFILE_UPDATE_FAILURE = 'There was a problem updating the profile. Please try again later.';
  const PROFILE_UPDATE_FAILURE_LOGO = 'There was a problem updating the profile logo. Please try again later.';
  const isValidLogo = logo ? isPNG && isValidDimension && isValidSize : true;
  const classes = useStyles();

  reviews.sort((a: any, b: any) => (_get(a, 'reviewId') > _get(b, 'reviewId') ? -1 : 1));

  const getReviewStatusColor = (status: string, resaon: string) => {
    if (status === 'approved') {
      return colors.green;
    } else if (status === 'pending') {
      return colors.overpacing;
    } else if (status === 'complete') {
      if (resaon) {
        return colors.red;
      }
      return colors.green;
    }
    return colors.darkBlue;
  };

  const index = reviews.length - 1;
  if (reviews.length > 0 && _get(reviews[index], 'sbaProfileId')) {
    sbaProfile = {
      reviewId: reviews[index].sbaProfileId,
      reviewStatus: _get(reviews[index], 'reviewStatus', ''),
      reviewReason: _get(reviews[index], 'reviewReason', '')
    };
  }

  if (reviews.length > 0) {
    _get(reviews[0], 'reviewComments', []).forEach((comment: any) => {
      if (_get(comment, 'commentType') === 'keyword') {
        keywordReview = {
          reviewId: _get(reviews[0], 'reviewId', ''),
          reviewStatus: _get(reviews[0], 'reviewStatus', ''),
          reviewReason: _get(comment, 'comments', '')
        };
      } else if (_get(comment, 'commentType') === 'content') {
        contentReview = {
          reviewId: _get(reviews[0], 'reviewId', ''),
          reviewStatus: _get(reviews[0], 'reviewStatus', ''),
          reviewReason: _get(comment, 'comments', '')
        };
      }
    });
  }

  const handleResponse = (message: string) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message);
  };

  const updateStore = () => {
    dispatch(
      updateSBAProfileReview({
        clickUrl: clickURL,
        headlineText: headline,
        searchAmpName: brandName
      })
    );
  };

  const hasChangedProfile = () => {
    return !(
      headline.trim() === initHeadline &&
      brandName.trim() === initBrandName &&
      clickURL.trim() === initClickUrl
    );
  };
  const didUpdateProfile = hasChangedProfile();

  const updateProfile = () => {
    const profileUpdatParams = {
      entityId,
      campaignId,
      adGroupId,
      searchAmpName: brandName,
      headlineText: headline,
      clickUrl: clickURL,
      retailerId: 2
    };
    return axios.post('/apiAdManager/adCampaigns/updateSBAProfile', profileUpdatParams);
  };

  const updateLogo = () => {
    const logoUpdateParams = {
      file: logo,
      platformType: 'Walmart',
      entityId,
      campaignId,
      adGroupId,
      retailerId: 2
    };
    return axios.post('/apiAdManager/adCampaigns/uploadSBALogoBase64', logoUpdateParams);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    let hasError = false;
    if (headline.trim().length === 0) {
      setShowHeadlineError(true);
      hasError = true;
    } else {
      setShowHeadlineError(false);
    }

    if (brandName.trim().length === 0) {
      setShowBrandNameError(true);
      hasError = true;
    } else {
      setShowBrandNameError(false);
    }

    if (clickURL.trim().length === 0) {
      setShowClickURLError(true);
      hasError = true;
    } else {
      setShowClickURLError(false);
    }

    if (!(hasError || lengthCheck || charCheck || brandNameCheck || clickURLCheck) && isValidLogo) {
      setDisableButton(true);
      const promises = [];
      if (didUpdateProfile) {
        promises.push(updateProfile());
      }
      if (logo) {
        promises.push(updateLogo());
      }
      Promise.all(promises)
        .then(([resp1, resp2]) => {
          const resp1Success = _get(resp1, 'data[0].code') === 'success';
          const resp2Success = _get(resp2, 'data[0].code') === 'success';
          if (didUpdateProfile && logo) {
            if (resp1Success) {
              updateStore();
              handleResponse(resp2Success ? PROFILE_UPDATE_SUCCESS : PROFILE_UPDATE_FAILURE_LOGO);
            } else {
              setDisableButton(false);
              handleResponse(PROFILE_UPDATE_FAILURE);
            }
          } else if (didUpdateProfile) {
            if (resp1Success) {
              updateStore();
              handleResponse(PROFILE_UPDATE_SUCCESS);
            } else {
              setDisableButton(false);
              handleResponse(PROFILE_UPDATE_FAILURE);
            }
          } else if (logo) {
            if (resp1Success) {
              handleResponse(PROFILE_UPDATE_SUCCESS);
            } else {
              setDisableButton(false);
              handleResponse(PROFILE_UPDATE_FAILURE_LOGO);
            }
          }
        })
        .catch((err) => {
          setDisableButton(false);
          handleResponse(PROFILE_UPDATE_FAILURE);
          console.error(err);
        });
    }
  };

  return (
    <div className={classes.sectionContainer}>
      {!_isEmpty(sbaProfile) && (
        <div className={classes.section}>
          <div className={classes.sectionTitle}>SBA Profile</div>
          <div className={classes.sectionColumnContainer}>
            <div>Status: </div>
            <div
              className={classes.reviewStatus}
              style={{
                color: getReviewStatusColor((sbaProfile as Review).reviewStatus, (sbaProfile as Review).reviewReason)
              }}
            >
              {(sbaProfile as Review).reviewStatus}
            </div>
          </div>
          <div className={classes.sectionColumnContainer}>
            <div>Reason: </div>
            <div>{(sbaProfile as Review).reviewReason}</div>
          </div>
        </div>
      )}
      {!_isEmpty(keywordReview) && (
        <div className={classes.section}>
          <div className={classes.sectionTitle}>Keywords</div>
          <div>
            Please make appropriate changes by switching to <strong>Targets</strong> tab.
          </div>
          <div className={classes.sectionColumnContainer}>
            <div>Status: </div>
            <div
              className={classes.reviewStatus}
              style={{
                color: getReviewStatusColor(
                  (keywordReview as Review).reviewStatus,
                  (keywordReview as Review).reviewReason
                )
              }}
            >
              {(keywordReview as Review).reviewStatus}
            </div>
          </div>
          <div className={classes.sectionColumnContainer}>
            <div>Reason: </div>
            <div>{(keywordReview as Review).reviewReason}</div>
          </div>
        </div>
      )}
      {!_isEmpty(contentReview) && (
        <div className={classes.section}>
          <div className={classes.sectionTitle}>Products</div>
          <div>
            Please make appropriate changes by switching to <strong>Products</strong> tab.
          </div>
          <div className={classes.sectionColumnContainer}>
            <div>Status: </div>
            <div
              className={classes.reviewStatus}
              style={{
                color: getReviewStatusColor(
                  (keywordReview as Review).reviewStatus,
                  (keywordReview as Review).reviewReason
                )
              }}
            >
              {(contentReview as Review).reviewStatus}
            </div>
          </div>
          <div className={classes.sectionColumnContainer}>
            <div>Reason: </div>
            <div>{(contentReview as Review).reviewReason}</div>
          </div>
        </div>
      )}
      {!_isEmpty(sbaProfile) && (
        <div className={classes.section}>
          <div>
            <form onSubmit={handleSubmit}>
              <div className={classes.formHeader}>
                <div>
                  <div className={classes.sectionTitle}>SBA Profile Update</div>
                  <div>Important: Please only update the rejected section(s) as per Walmart’s review comment.</div>
                </div>
                <div>
                  <Button
                    type="submit"
                    disableRipple
                    classes={{
                      root: classes.submitButtonRoot,
                      label: classes.submitButtonLabel,
                      disabled: classes.disabledButtonRoot
                    }}
                    disabled={disableButton || !(didUpdateProfile || logo ? isValidLogo : false)}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              <div>
                <FieldWapper title="Headline">
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    placeholder="Headline Name"
                    value={headline}
                    inputProps={{ maxLength: 100 }}
                    onChange={(e: any) => setHeadline(e.target.value)}
                  />
                  {lengthCheck && (
                    <div className={classes.formFieldError}>
                      *Headline text should not be longer than {HEADLINE_LIMIT} characters
                    </div>
                  )}
                  {charCheck && (
                    <div className={classes.formFieldError}>
                      {'*Headline text cannot contain special characters such as <>#^!@%*~'}
                    </div>
                  )}
                  {showHeadlineError && headline.trim().length === 0 && (
                    <div className={classes.formFieldError}>*Please enter a headline name</div>
                  )}
                </FieldWapper>
                <FieldWapper title="Brand Name">
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    placeholder="Brand Name"
                    value={brandName}
                    inputProps={{ maxLength: 100 }}
                    onChange={(e: any) => setBrandName(e.target.value)}
                  />
                  {brandNameCheck && (
                    <div className={classes.formFieldError}>
                      *Brand name should not be longer than {BRANDNAME_LIMIT} characters
                    </div>
                  )}
                  {showBrandNameError && brandName.trim().length === 0 && (
                    <div className={classes.formFieldError}>*Please enter a brand name</div>
                  )}
                </FieldWapper>
                <FieldWapper title="Click URL">
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    placeholder="Click URL"
                    value={clickURL}
                    inputProps={{ maxLength: 500 }}
                    onChange={(e: any) => setClickURL(e.target.value)}
                  />
                  {clickURLCheck && (
                    <div className={classes.formFieldError}>*The url must start with https://www.walmart.com</div>
                  )}
                  {showClickURLError && clickURL.trim().length === 0 && (
                    <div className={classes.formFieldError}>*Please enter a click url</div>
                  )}
                </FieldWapper>
              </div>
              <div className={classes.uploadLogoWrapper}>
                <SbaLogo
                  {...{
                    sbaLogo: logo,
                    saveLogo: setLogo,
                    isPNG,
                    isValidDimension,
                    isValidSize,
                    setIsPNG,
                    setIsValidSize,
                    setIsValidDimension
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      )}
      <AppSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
    </div>
  );
};

export default ReviewsPage;
