import React, { useState } from 'react';
import { withBus } from 'react-bus';
import { TRENDING_PRODUCTS_TAB_EVENT } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/constants';
import { brandclub_colors } from '@stackline/ui';
import { makeStyles } from '@mui/styles';
import { Tab, Tabs } from '@mui/material';
import { BEACON_PRO_RIGHT_CONTENT_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

const useStyles = makeStyles({
  tabsDefaultStyles: {
    minHeight: 'unset',
    paddingTop: 16,
    width: BEACON_PRO_RIGHT_CONTENT_WIDTH,
    maxWidth: BEACON_PRO_RIGHT_CONTENT_WIDTH,
    '& div.MuiTabs-scroller': {
      borderBottom: `1px solid #0528491f`,
      '& div.MuiTabs-flexContainer': {
        gap: 32,
        minHeight: 'unset'
      },
      '& span.MuiTabs-indicator': {
        backgroundColor: '#052849',
        borderRadius: 3
      }
    }
  },
  tabDefaultStyles: {
    color: brandclub_colors.gray5,
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    letterSpacing: 0.09,
    minHeight: 'unset',
    minWidth: 'unset',
    justifyContent: 'flex-end',
    paddingBottom: 7.5,
    padding: 0,
    textTransform: 'none',
    cursor: 'pointer',
    '&.Mui-selected': {
      color: '#052849'
    },
    '&.Mui-disabled': {
      color: '#0528491f'
    }
  }
});

const TabsWidget = ({ eventBus }: { eventBus: any }) => {
  const tabs = [
    { displayName: 'Top-Selling', id: 'topSelling' },
    { displayName: 'Increasing', id: 'increasing' },
    { displayName: 'Decreasing', id: 'decreasing' }
  ];
  const [tab] = tabs;

  const [selectedTab, setSelectedTab] = useState(tab.id);

  // TODO: style these
  const classes = useStyles();

  return (
    <Tabs value={selectedTab} variant="scrollable" scrollButtons={false} className={classes.tabsDefaultStyles}>
      {tabs.map((t, index) => {
        return (
          <Tab
            key={index}
            value={t.id}
            label={t.displayName}
            disableRipple
            className={classes.tabDefaultStyles}
            onClick={() => {
              if (TRENDING_PRODUCTS_TAB_EVENT) {
                setSelectedTab(t.id);
                eventBus.emit(TRENDING_PRODUCTS_TAB_EVENT, tabs[index]);
              }
            }}
          />
        );
      })}
    </Tabs>
  );

  // return (
  //   <Tabs
  //     tabs={tabs}
  //     className={classes.tabsDefaultStyles}
  //     sx={{}}
  //     onTabChange={(_event, i) => {
  //       if (TRENDING_PRODUCTS_TAB_EVENT) {
  //         eventBus.emit(TRENDING_PRODUCTS_TAB_EVENT, tabs[i]);
  //       }
  //     }}
  //     tabStyle={{}}
  //     value={undefined}
  //   />
  // );
};

export default withBus('eventBus')(TabsWidget);
