import axios from 'axios';
import { useEffect, useState } from 'react';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { AdjustmentForm } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import { createAdditionalProperties } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/utils';
import { useAppSelector } from 'src/utils/Hooks';

export enum AdjustmentChangeType {
  Absolute = 'Absolute'
}

interface BaseAdjustmentRequest {
  adjustmentId: string;
  beaconClientId: number;
  retailerId: string;
  retailerSku: string | number;
  userId: string;
  planType: string;
  startWeekId: string;
  endWeekId: string;
  ignoreConflictWithAdjustmentIds: string[];
}

interface PaidTrafficAdditionalProperties {
  adSpend: number;
  cpc: number;
  isCpcFromUser: boolean;
}

interface AdSpendPayload {
  baseAdjustmentRequest: BaseAdjustmentRequest;
  additionalProperties: PaidTrafficAdditionalProperties;
  adjustmentChangeType: string;
  adjustmentChangeValue: number;
  stacklineSku: string | number;
  categoryId: string | number;
  subCategoryId: string | number;
  requestType: string;
  shouldPublish: boolean;
}

export interface PaidTrafficMetrics {
  weekId: number;
  ad_spend: number;
  cpc: number;
  paid_traffic: number;
  ad_sales: number;
  roas: number;
}

export interface AdSpendResponse {
  baseAdjustmentRequest: BaseAdjustmentRequest;
  paidTrafficMetrics: PaidTrafficMetrics[];
  isSuccess: boolean;
  message: string;
  conflictedAdjustmentIdsAndWeeks: string[];
}

/**
 * Fetches the ad spend metrics
 */
export const fetchAdSpendMetrics = async (payload: AdSpendPayload) => {
  const response = await axios.post('api/beaconforecast/GetPaidTrafficMetrics', [payload]);
  const adSpendResponse: AdSpendResponse = response.data[0];
  return adSpendResponse;
};

/**
 * Parses the ad spend response
 */
export const parseAdSpendResponse = (adSpendResponse: AdSpendResponse) => {
  const summedMetrics = adSpendResponse.paidTrafficMetrics.reduce((accumulator, metricsByWeek) => {
    Object.keys(metricsByWeek).forEach((key) => {
      if (key !== 'weekId') {
        accumulator[key] = (accumulator[key] || 0) + metricsByWeek[key];
      }
    });
    return accumulator;
  }, {} as Record<string, number>);

  return Object.entries(summedMetrics).reduce((acc, [key, value]) => {
    acc[key] = value / adSpendResponse.paidTrafficMetrics.length || 1;
    return acc;
  }, {} as Record<string, number>);
};

interface UseAdSpendProjectionParams {
  adjustmentForm: AdjustmentForm;
  retailerSku: string | number;
  stacklineSku: string | number;
  categoryId: string | number;
  subCategoryId: string | number;
  shouldFetch?: boolean;
  /**
   * true if the user input a CPC. False if the CPC is computed.
   */
  isCpcFromUser?: boolean;
  adjustmentId?: string;
}
export const useAdSpendProjection = ({
  adjustmentForm,
  stacklineSku,
  retailerSku,
  categoryId,
  subCategoryId,
  shouldFetch = true,
  isCpcFromUser = true,
  adjustmentId = ''
}: UseAdSpendProjectionParams) => {
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const retailerId = useAppSelector((state) => state.retailer.id);
  const userId = useAppSelector((state) => state.user.session.userId);

  const { startDate, endDate, planType } = adjustmentForm;

  const [adSpendProjection, setAdSpendProjection] = useState<Omit<PaidTrafficMetrics, 'weekId'>>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  /**
   * This is where the user input is attached to the request
   */
  const additionalProperties = createAdditionalProperties(
    adjustmentForm,
    isCpcFromUser
  ) as PaidTrafficAdditionalProperties;

  const payload: AdSpendPayload = {
    baseAdjustmentRequest: {
      beaconClientId,
      retailerId,
      retailerSku,
      userId,
      planType,
      startWeekId: startDate,
      endWeekId: endDate,
      ignoreConflictWithAdjustmentIds: [adjustmentId]
    },
    additionalProperties,
    adjustmentChangeType: AdjustmentChangeType.Absolute,
    adjustmentChangeValue: 0,
    stacklineSku,
    categoryId,
    subCategoryId,
    requestType: 'Create',
    shouldPublish: false
  };

  const fetchAndParseAdSpendProjection = async () => {
    try {
      const adSpendResponse = await fetchAdSpendMetrics(payload);
      const averages = parseAdSpendResponse(adSpendResponse);

      setAdSpendProjection(averages as Omit<PaidTrafficMetrics, 'weekId'>);
      setLoading(false);
    } catch (err) {
      console.error('Failed to fetch ad spend data', err);
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (planType === PlanTypeOption.PaidTraffic && shouldFetch) {
      setLoading(true);
      fetchAndParseAdSpendProjection();
    } else {
      setLoading(false);
    }
  }, [shouldFetch]);

  // Additional helper function to create an ad spend request body
  const createAdSpendRequest = (additionalAdSpendProperties: PaidTrafficAdditionalProperties, adjustmentIds = []) => {
    const adSpendPayload: AdSpendPayload = {
      baseAdjustmentRequest: {
        beaconClientId,
        retailerId,
        retailerSku,
        userId,
        planType,
        startWeekId: startDate,
        endWeekId: endDate,
        ignoreConflictWithAdjustmentIds: adjustmentIds
      },
      additionalProperties: additionalAdSpendProperties,
      adjustmentChangeType: AdjustmentChangeType.Absolute,
      adjustmentChangeValue: 0,
      stacklineSku,
      categoryId,
      subCategoryId,
      requestType: 'Create',
      shouldPublish: false
    };
    return adSpendPayload;
  };

  return { adSpendProjection, loading, error, createAdSpendRequest };
};
