import React from 'react';
import { AppName } from 'sl-api-connector';
import './StarrySky.scss';

const getBgImg = (appName: string) => {
  let BG_LD_URL = `https://media.brandclub.com/auth/${appName}/BG_LD.svg`;
  let BG_SD_URL = `https://media.brandclub.com/auth/${appName}/BG_SD.svg`;
  if (appName === AppName.Advertising) {
    BG_LD_URL = 'https://media.brandclub.com/auth/drive/BG_LD.svg';
    BG_SD_URL = 'https://media.brandclub.com/auth/drive/BG_SD.svg';
  }
  return { BG_LD_URL, BG_SD_URL };
};

export default function StarrySky({ appName }: { appName: string }) {
  const { BG_LD_URL, BG_SD_URL } = getBgImg(appName);
  return (
    <div className="auth_bg_container">
      <div className="starrySky">
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross1" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross2" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross3" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross4" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross5" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross6" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross7" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross8" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross9" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross10" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross11" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross12" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross13" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross14" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross15" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross16" />
        <img alt="BG_LD" src={BG_LD_URL} className="cross_star cross17" />

        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg1" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg2" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg3" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg4" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg5" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg6" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg7" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg8" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg9" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg10" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg11" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg12" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg13" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg14" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg15" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg16" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg17" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg18" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg19" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg20" />
        <img alt="BG_SD" src={BG_SD_URL} className="reg_star reg21" />
      </div>
    </div>
  );
}
