import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import withStyles from '@mui/styles/withStyles';

import colors from 'src/utils/colors';
import fontStyle from 'src/utils/fontStyle';
import CloseButton from '../Buttons/CloseButton';

const styles = {
  root: {
    color: colors.darkBlue,
    width: 670,
    minHeight: 630
  },
  dialogTitle: {
    display: 'flex',
    flex: 0,
    fontWeight: 'normal',
    fontSize: 27,
    marginBottom: 0,
    padding: '25px 0px 0px 26px',
    marginTop: 0
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    top: 0
  },
  textContent: {
    fontSize: 13,
    color: colors.darkBlue,
    fontWeight: fontStyle.regularWeight,
    padding: '5px 27px 15px',
    borderBottom: `1px solid ${colors.lightestGrey}`
  }
};

const muiStyles = { paper: { borderRadius: 6, overflow: 'hidden' } };

const GenericDialog = ({
  title,
  open,
  onClose,
  onExit,
  children,
  textContent,
  classes,
  style,
  PaperProps,
  showCloseButton = true,
  ...props
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="form-dialog-title"
    keepMounted
    classes={classes}
    PaperProps={PaperProps}
    TransitionProps={{
      onExit
    }}
  >
    <div style={{ ...styles.root, ...style }} {...props}>
      {title ? (
        <h1 style={styles.dialogTitle} id="form-dialog-title">
          {title}
        </h1>
      ) : null}
      {showCloseButton ? <CloseButton onClick={onClose} style={styles.closeButton} /> : null}

      {textContent ? <DialogContentText style={styles.textContent}>{textContent}</DialogContentText> : null}

      {children}
    </div>
  </Dialog>
);

GenericDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExit: PropTypes.func,
  children: PropTypes.node,
  textContent: PropTypes.node,
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  PaperProps: PropTypes.object,
  showCloseButton: PropTypes.bool
};

GenericDialog.defaultProps = {
  style: {},
  onExit: () => {},
  PaperProps: undefined,
  children: null,
  showCloseButton: true,
  textContent: null,
  title: null
};

const EnhancedGenericDialog = withStyles(muiStyles)(GenericDialog);

export default EnhancedGenericDialog;
