import { TermFilter } from 'sl-api-connector/types';
import _get from 'lodash/get';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';

export class PortfolioFilterApplicator {
  private readonly _filterConditions: ISearchFilterConditions;
  private readonly _aggregationFilters: ISearchFilterConditions;

  private readonly _shouldNotAddIfPortfolioDoesNotHaveMetrics: boolean;

  public constructor(filterConditions: ISearchFilterConditions, aggregationFilters: ISearchFilterConditions) {
    this._filterConditions = filterConditions;
    this._aggregationFilters = aggregationFilters;
    const shouldNotAddIfPortfolioDoesNotHaveMetrics1 = this._filterConditions.termFilters.some((termFilter) => {
      switch (termFilter.fieldName) {
        case 'statusDerived':
        case 'automationStrategyId':
          return termFilter.values.length > 0;
        default:
          return false;
      }
    });
    const shouldNotAddIfPortfolioDoesNotHaveMetrics2 =
      this._aggregationFilters &&
      this._aggregationFilters.computeFilters.some((computeFilter) => {
        switch (computeFilter.fieldName) {
          case 'costPerClick':
          case 'returnOnAdSpend':
          case 'spendComputed':
            return computeFilter.minValue > 0;
          default:
            return false;
        }
      });
    this._shouldNotAddIfPortfolioDoesNotHaveMetrics =
      shouldNotAddIfPortfolioDoesNotHaveMetrics1 || shouldNotAddIfPortfolioDoesNotHaveMetrics2;
  }

  public doesPortfolioMatchFilters(adPortfolio: any, doesPortfolioHaveMetrics: boolean): boolean {
    if (!doesPortfolioHaveMetrics && this._shouldNotAddIfPortfolioDoesNotHaveMetrics) {
      return false;
    }
    return this.doesPortfolioMatchMetadataFilters(adPortfolio);
  }

  public doesPortfolioMatchMetadataFilters(adPortfolio: any) {
    const isNameIncluded = this.isPortfolioNameIncluded(adPortfolio);
    const isEntityIncluded = this.isAdEntityIncluded(adPortfolio);
    const isStatusIncluded = this.isPortfolioStatusIncluded(adPortfolio);
    const isAutomationStrategyIncluded = this.isAutomationStrategyIncluded(adPortfolio);
    const isNameExcluded = this.isPortfolioNameExcluded(adPortfolio);
    return isNameIncluded && isEntityIncluded && isStatusIncluded && isAutomationStrategyIncluded && !isNameExcluded;
  }

  public doesPortfolioWithoutMetricsMatchFilters(
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    adPortfolio: any
  ): boolean {
    if (this._shouldNotAddIfPortfolioDoesNotHaveMetrics) {
      return false;
    }
    return true;
  }

  private getTermFilter(fieldName: string): TermFilter {
    return this._filterConditions.termFilters.find((termFilter) => fieldName === termFilter.fieldName) || null;
  }

  private isPortfolioNameIncluded(adPortfolio: any): boolean {
    const fieldName = 'portfolioNameFuzzy';
    const termFilter = this.getTermFilter(fieldName);
    if (!termFilter) {
      return true;
    }
    const filters = termFilter.values;
    if (filters.length === 0) {
      return true;
    }
    const portfolioNameLower = _get(adPortfolio, 'extendedAttributes.name', '').toLowerCase();
    return filters.some((filter) => portfolioNameLower.includes(filter.toString().toLowerCase()));
  }

  private isAdEntityIncluded(adPortfolio: any) {
    const fieldName = 'entityId';
    const termFilter = this.getTermFilter(fieldName);
    if (!termFilter) {
      return true;
    }
    const filters = termFilter.values;
    if (filters.length === 0) {
      return true;
    }
    const entityIdLower = _get(adPortfolio, 'extendedAttributes.entityId', '').toLowerCase();
    return filters.some((filter) => entityIdLower === filter.toString().toLowerCase());
  }

  private isPortfolioStatusIncluded(adPortfolio: any) {
    const fieldName = 'statusDerived';
    const termFilter = this.getTermFilter(fieldName);
    if (!termFilter) {
      return true;
    }
    const filters = termFilter.values;
    if (filters.length === 0) {
      return true;
    }
    const statusDerivedLower = _get(adPortfolio, 'extendedAttributes.statusDerived', '').toLowerCase();
    return filters.some((filter) => statusDerivedLower === filter.toString().toLowerCase());
  }

  private isAutomationStrategyIncluded(adPortfolio: any) {
    const fieldName = 'automationStrategyId';
    const termFilter = this.getTermFilter(fieldName);
    if (!termFilter) {
      return true;
    }
    const filters = termFilter.values;
    if (filters.length === 0) {
      return true;
    }
    const automationStrategyIdLower = _get(
      adPortfolio,
      'extendedAttributes.automationAttributes.strategyId',
      ''
    ).toLowerCase();
    return filters.some((filter) => automationStrategyIdLower === filter.toString().toLowerCase());
  }

  private isPortfolioNameExcluded(adPortfolio: any) {
    const fieldName = 'excludedPortfolioNameFuzzy';
    const termFilter = this.getTermFilter(fieldName);
    if (!termFilter) {
      return false;
    }
    const filters = termFilter.values;
    if (filters.length === 0) {
      return false;
    }
    const portfolioNameLower = _get(adPortfolio, 'extendedAttributes.name', '').toLowerCase();
    return filters.some((filter) => portfolioNameLower.includes(filter.toString().toLowerCase()));
  }
}
