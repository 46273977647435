import * as types from './types';

// ------------------------------------
// Reducer
// ------------------------------------

const platformLabels = ['Amazon Fresh', 'Amazon PrimeNow', 'Amazon Business', 'Amazon.com'];

const initialState = {
  pantryIds: [6, 7, 10, 15],
  platformEntities: platformLabels.reduce(
    (acc, label, i) => ({ ...acc, [i + 1]: { label, id: i + 1, isChecked: false } }),
    {}
  ),
  showPlatformFilter: false
};

const platformsReducers = {
  [types.UPDATE_PLATFORMS]: (state, action) => ({ ...state, platformEntities: action.platforms })
};

const platformsReducer = (state = initialState, action) => {
  const subReducer = platformsReducers[action.type];
  return subReducer ? subReducer(state, action) : state;
};

export default platformsReducer;
