import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { withBus } from 'react-bus';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { buildMuiTheme } from 'src/muiTheme';
import { splitCamelCase } from 'src/utils/stringFormatting';
import 'src/components/Grids/Data/ColumnTypes/Entity.scss';
import ReduxStore from 'src/types/store/reduxStore';
import Switch from '@mui/material/Switch';
import './CampaignColumn.scss';
import pluralize from 'pluralize';
import { store } from 'src/main';
import { adCampaignOperations } from 'src/store/modules/adManager/adCampaigns';

import Loading from 'src/components/common/Loading';
import { EventBus } from 'src/types/utils';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import Dialog from '@mui/material/Dialog';
import { AdManagerEvents } from 'src/types/application/event';
import { AdCampaignManagerServiceClient } from 'src/utils/adCampaignManagerServiceClient';
import {
  IAdCampaign,
  IAdCampaignProduct,
  IAdPortfolioAdPlatformSettingsByClient,
  IAdEntityAdPlatformSettingsByClient,
  IAdTarget,
  AD_CAMPAIGN_STATUS,
  AD_UPDATE_TYPE,
  AD_UPDATE_STATUS
} from 'sl-ad-campaign-manager-data-model';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const isChecked = (location: any, currentEntity: any, mainEntity: any): boolean => {
  if (currentEntity.type === 'adCampaign' && window.location.pathname.includes('adTarget')) {
    const { childEntities } = mainEntity;
    const { campaignId } = currentEntity;
    // find matching
    const matches = childEntities ? childEntities.filter((entity) => entity.campaignId === campaignId) : [];
    const enabledTargets = matches.filter((match) => {
      return match.extendedAttributes.status === 'enabled';
    });

    return enabledTargets.length > 0;
  }

  if (currentEntity.type === 'adCampaign' && window.location.pathname.includes('product')) {
    const { childEntities } = mainEntity;
    const { campaignId } = currentEntity;
    // is this product enabled in this campaign?

    const matches = childEntities ? childEntities.filter((entity) => entity.campaignId === campaignId) : [];
    const enabledTargets = matches.filter((match) => {
      return match.extendedAttributes.status === 'enabled';
    });

    return enabledTargets.length > 0;
  }

  return currentEntity.extendedAttributes
    ? currentEntity.extendedAttributes.status === 'enabled' || currentEntity.extendedAttributes.status === 'active'
    : true;
};

const ToggleStatus = ({
  app,
  currentEntity,
  eventBus,
  mainEntity,
  disabled
}: {
  currentEntity:
    | IAdCampaign
    | IAdPortfolioAdPlatformSettingsByClient
    | IAdEntityAdPlatformSettingsByClient
    | IAdCampaignProduct
    | IAdTarget;
  app: ReduxStore['app'];
  eventBus: EventBus;
  mainEntity: ReduxStore['entityService']['mainEntity'];
  disabled: boolean;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [checked, setCheck] = React.useState(isChecked(window.location, currentEntity, mainEntity));

  const handleClick = () => {
    setOpen(true);
  };

  const toggleStatusForEntity = async () => {
    setOpen(false);
    setLoading(true);
    // const currentStatus = currentEntity.extendedAttributes.status;
    const newStatus: AD_CAMPAIGN_STATUS = checked ? 'paused' : 'enabled';
    let override;

    if (currentEntity.type === 'adCampaign' && window.location.pathname.includes('adTarget')) {
      // @ts-ignore
      const { beaconClientId, platformType, campaignId, retailerId, campaignType } = currentEntity;
      // On ad target and product pages, we need to wrangle data to be able to have the switch on campaigns toggle
      // @ts-ignore
      const { childEntities } = mainEntity;

      // find matching
      const matches = childEntities.filter((entity) => entity.campaignId === campaignId);
      const adTargetsToPause = matches.map((match) => {
        const {
          targetingClass,
          targetingText,
          extendedAttributes: {
            adGroupId,
            beaconClientLoginId,
            targetId,
            cpcMaxBidAmount,
            cpcMinBidAmount,
            entityId,
            status
          }
        } = match;
        const newStatusForMatch = status === 'enabled' ? 'paused' : 'enabled';
        return {
          beaconClientId,
          platformType,
          campaignId,
          adGroupId,
          targetId,
          targetingClass,
          targetingText,
          extendedAttributes: {
            retailerId,
            beaconClientLoginId,
            entityId,
            campaignId,
            adGroupId,
            cpcMaxBidAmount,
            cpcMinBidAmount,
            targetId,
            campaignType,
            status: newStatusForMatch
          }
        };
      });

      override = {
        beaconClientId,
        platformType,
        updateType: AD_UPDATE_TYPE.UPDATE_STATUS,
        extendedAttributes: {
          status: AD_UPDATE_STATUS.NEW,
          adEntities: [],
          adPortfolios: [],
          adCampaigns: [],
          adGroups: [],
          adCampaignProducts: [],
          adTargets: adTargetsToPause
        }
      };
    } else if (currentEntity.type === 'adCampaign' && window.location.pathname.includes('product')) {
      // @ts-ignore
      const { beaconClientId, platformType, campaignId, retailerId, campaignType } = currentEntity;
      // On ad target and product pages, we need to wrangle data to be able to have the switch on campaigns toggle
      // @ts-ignore
      const { childEntities } = mainEntity;

      // find matching
      const matches = childEntities.filter((entity) => entity.campaignId === campaignId);
      const adProductsToPause = matches.map((match) => {
        const {
          adId,
          retailerSku,
          extendedAttributes: { adGroupId, beaconClientLoginId, entityId, status }
        } = match;
        const newStatusForMatch = status === 'enabled' ? 'paused' : 'enabled';
        return {
          beaconClientId,
          platformType,
          campaignId,
          adGroupId,
          retailerId,
          retailerSku,
          extendedAttributes: {
            retailerId,
            beaconClientLoginId,
            entityId,
            campaignId,
            adGroupId,
            adId,
            campaignType,
            status: newStatusForMatch
          }
        };
      });

      override = {
        beaconClientId,
        platformType,
        updateType: AD_UPDATE_TYPE.UPDATE_STATUS,
        extendedAttributes: {
          status: AD_UPDATE_STATUS.NEW,
          adEntities: [],
          adPortfolios: [],
          adCampaigns: [],
          adGroups: [],
          adCampaignProducts: adProductsToPause,
          adTargets: []
        }
      };
    }

    // Instead of doing it for the whole campaign, we should toggle the target
    const res = await AdCampaignManagerServiceClient.updateEntityStatus({
      beaconClientId: currentEntity.beaconClientId,
      platformType: currentEntity.platformType,
      entities: [currentEntity],
      entityType: currentEntity.type,
      updatedStatus: newStatus,
      override
    });

    if (_get(res, ['data', 'extendedAttributes', 'status']) === 'success') {
      setCheck(!checked);
      if (
        _get(app, 'queryParams.groupByField', '') === 'campaignId' ||
        _get(app, 'queryParams.selectedEntityName', '') === 'campaignId'
      ) {
        store.dispatch(adCampaignOperations.fetchAdCampaigns());
      }
    }
    eventBus.emit(AdManagerEvents.ENTITY_EVENT_UPDATE_STATUS, { entity: currentEntity });
    setLoading(false);
  };

  if (loading) {
    return (
      <div className="control_button">
        <Loading className="spinner in-place" size={30} thickness={2} style={{ width: 30, height: 30 }} />
      </div>
    );
  }

  const generateTooltipText = () => {
    let childDocuments: any[] = [];
    if (currentEntity.extendedAttributes) {
      // eslint-disable-next-line prefer-destructuring
      childDocuments = currentEntity.extendedAttributes.childDocuments || [];
    }
    switch (currentEntity.type) {
      case 'adTarget':
        return `${checked ? 'Pause' : 'Turn on'} "${currentEntity.targetingText}" in ${
          childDocuments.length
        } ${pluralize('campaign', childDocuments.length)}`;
      case 'product':
        return `${checked ? 'Pause' : 'Turn on'} this ${splitCamelCase(currentEntity.type).toLowerCase()} in ${
          childDocuments.length
        } ${pluralize('campaign', childDocuments.length)}`;
      case 'adCampaign':
        if (window.location.pathname.includes('adTarget')) {
          // @ts-ignore
          const { targetingText } = mainEntity;

          return `${checked ? 'Pause' : 'Turn on'} "${targetingText}" in this campaign`;
        }

        if (window.location.pathname.includes('product')) {
          return `${checked ? 'Pause' : 'Turn on'} this product in this campaign`;
        }

        return `${checked ? 'Pause' : 'Turn on'} ${splitCamelCase(currentEntity.type).toLowerCase()}`;

      default:
        // e.g. Pause ad campaign
        return `${checked ? 'Pause' : 'Turn on'} ${splitCamelCase(currentEntity.type).toLowerCase()}`;
    }
  };

  if (_get(currentEntity, ['targetingText']) === 'browseImpressions') {
    return null;
  }

  const displayName =
    currentEntity.type === 'adTarget' ? currentEntity.targetingText : currentEntity.campaignName || currentEntity.name;

  return (
    <Tooltip disableHoverListener={disabled} title={generateTooltipText()} placement="top">
      <div className="control_button">
        <Switch disabled={disabled} checked={checked} onClick={handleClick} color="primary" />
        <Dialog open={open} onClose={() => setOpen(false)}>
          <div className="confirm_dialog">
            <div className="confirm_title">Confirmation</div>
            <div className="confirm_body">
              Are you sure to update the status for <strong>{displayName}</strong>?
            </div>
            <div className="confirm_buttons">
              <LargeMuiButton
                style={{ width: 88, height: 35, marginRight: 5 }}
                label="Confirm"
                onClick={toggleStatusForEntity}
              />
              <LargeMuiButton
                secondary
                style={{ width: 88, height: 35, marginLeft: 5, marginRight: 5 }}
                label="Cancel"
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        </Dialog>
      </div>
    </Tooltip>
  );
};

const mapStateToProps = ({ app, adCampaigns, entityService: { mainEntity } }: ReduxStore) => ({
  app,
  adCampaigns,
  mainEntity
});

type EntityColumnProps = {
  data?: { [key: string]: any };
  style?: React.CSSProperties;
  value?: { [key: string]: any };
  disabled?: boolean;
  eventBus: EventBus;
} & ReturnType<typeof mapStateToProps>;

class EntityStatusColumn extends React.Component<EntityColumnProps> {
  public static defaultProps = {
    data: {},
    value: {},
    style: {},
    disabled: false
  };

  public static propTypes = {
    data: (props: EntityColumnProps, _propName: string, componentName: string) => {
      if (!props.data && !props.value) {
        return new Error(`One of props 'data' or 'url' was not specified in '${componentName}'.`);
      }
      return null;
    },
    value: (props: EntityColumnProps, _propName: string, componentName: string) => {
      if (!props.data && !props.value) {
        return new Error(`One of props 'url' or 'data' was not specified in '${componentName}'.`);
      }
      return null;
    },
    style: PropTypes.object,
    app: PropTypes.object.isRequired
  };

  public componentWillMount() {}

  private stopPropagation = (evt: { stopPropagation: Function }) => evt.stopPropagation();

  public render() {
    const { data, eventBus } = this.props;
    if (!data) {
      return null;
    }

    if (data.disableToggle) {
      return null;
    }

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={buildMuiTheme()}>
          <ToggleStatus
            disabled={this.props.disabled}
            app={this.props.app}
            currentEntity={data.entity}
            eventBus={eventBus}
            mainEntity={this.props.mainEntity}
          />{' '}
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const EntityStatusColumnWithState = withBus('eventBus')(connect(mapStateToProps)(EntityStatusColumn));

export default EntityStatusColumnWithState;
