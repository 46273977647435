import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import Button from '@mui/material/Button';

import { isFilterApplied } from 'src/utils/filters';

const styles = {
  placeholder: {
    display: 'inline-block',
    height: '36px',
    float: 'left',
    marginTop: '12px'
  }
};

const propTypes = {
  categories: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  handleClearFilters: PropTypes.func.isRequired
};
const defaultProps = {};

const ClearAllFiltersButton = ({ filters, categories, handleClearFilters }) => {
  if (!_isEmpty(filters) && !isFilterApplied(filters, categories)) {
    return <span style={styles.placeholder} />;
  }

  return (
    <Button className="nav-clear-all" onClick={handleClearFilters} color="primary">
      Clear All
    </Button>
  );
};

ClearAllFiltersButton.propTypes = propTypes;
ClearAllFiltersButton.defaultProps = defaultProps;

export default ClearAllFiltersButton;
