import React from 'react';

interface OmniArrowChangeProps {
  value: number;
  size: number;
}

const OmniArrowChange: React.FC<OmniArrowChangeProps> = ({ value, size }: OmniArrowChangeProps) => {
  const upperGreen: React.CSSProperties = {
    width: 0,
    height: 0,
    borderLeft: `${size}px solid transparent`,
    borderRight: `${size}px solid transparent`,
    borderBottom: `${size}px solid #39b3ae`,
    position: 'absolute',
    top: '-5px'
  };
  const downRed: React.CSSProperties = {
    width: 0,
    height: 0,
    borderLeft: `${size}px solid transparent`,
    borderRight: `${size}px solid transparent`,
    borderTop: `${size}px solid #C54242`,
    position: 'absolute',
    top: `${-size * 1.5}px`
  };
  const zero: React.CSSProperties = {
    width: 0,
    height: 0,
    borderLeft: `${size}px solid #233333`,
    borderBottom: `${size}px solid transparent`,
    borderTop: `${size}px solid transparent`,
    position: 'absolute',
    top: '-9.5px'
  };

  let correctArrow = upperGreen;
  if (value === 0) {
    correctArrow = zero;
  } else if (value < 0) {
    correctArrow = downRed;
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block'
      }}
    >
      <div style={correctArrow}></div>
    </div>
  );
};

export default OmniArrowChange;
