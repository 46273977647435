import React, { memo } from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { KeyMetricCardDefinition } from 'src/components/BeaconRedesignComponents/KeyMetricCards/KeyMetricCards';
import SmartForecastKeyMetricCard from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/SmartForecastKeyMetricCard';
import { KEY_METRICS_CONTAINER_ADJUSTED_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

interface ForecastKeyMetricSummaryCardsProps {
  titleOverrides?: Record<string, Record<string, string>>;
}

/**
 * Shows the key metric cards on the forecast summary page
 */
export default memo(function ForecastKeyMetricSummaryCards({ titleOverrides }: ForecastKeyMetricSummaryCardsProps) {
  const cardDefinitionsByColumn: KeyMetricCardDefinition[][] = [
    [
      ['traffic', 'totalClicks'],
      ['traffic', 'organicClicks'],
      ['traffic', 'otherClicks'],
      ['advertising', 'clicks'],
      ['advertising', 'spend'],
      ['advertising', 'sales']
    ],
    [
      ['sales', 'purchaseRate'],
      ['sales', 'retailPrice'],
      ['new-content-metrics', 'contentScore'],
      ['sales', 'instockRate'],
      ['ratings-reviews-metrics', 'weightedRating'],
      ['buybox', 'winPercentage']
    ],
    [
      ['sales', 'retailSales'],
      ['sales', 'unitsSold'],
      ['sales', 'wholesaleSales'],
      ['sales', 'retailerGrossMargin'],
      ['sales', 'brandGrossMargin'],
      ['sales', 'brandGrossMarginPercent']
    ]
  ].map((columnDefinitions) =>
    columnDefinitions.map(([indexName, fieldName]) => {
      const colDef: KeyMetricCardDefinition = {
        indexName,
        fieldName,
        title: titleOverrides && titleOverrides[indexName] ? titleOverrides[indexName][fieldName] : undefined
      };

      return colDef;
    })
  );

  return (
    <Flex
      gap="md"
      style={{
        width: KEY_METRICS_CONTAINER_ADJUSTED_WIDTH,
        transform: 'translate(-16px)'
      }}
    >
      {cardDefinitionsByColumn.map((cardDefinitions) => (
        <Flex flexDirection="column" key={`column-${cardDefinitions[0].fieldName}`} gap="md">
          {cardDefinitions.map((cardDefinition, index) => (
            <SmartForecastKeyMetricCard
              variant={index === 0 ? 'large' : 'small'}
              key={cardDefinition.fieldName}
              {...cardDefinition}
            />
          ))}
        </Flex>
      ))}
    </Flex>
  );
});
