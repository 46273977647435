import AdIneligibleInfo from 'src/components/AdManager/AdIneligibleInfo';
import ReduxStore from 'src/types/store/reduxStore';
import { PageLayout } from 'src/types/application/widgetTypes';
import colors from 'src/utils/colors';

import { ExportIcon } from 'src/components/SvgIcons';
import PageContextMenu from 'src/components/PageContextMenu';
import { EventBus } from 'src/types/utils';
import AdManagerEntityHeader from 'src/components/AdManager/AdManagerEntityHeader';
import {
  buildAdvancedSearchSideBarWidget,
  buildTabbedSearchResultGridWidget
} from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';
import { buildWidgetGroup } from 'src/components/Layout/LayoutUtil';

export const getLayout = ({
  app,
  entity,
  eventBus
}: {
  app: ReduxStore['app'];
  entity: { type: string };
  eventBus: EventBus;
}): Partial<PageLayout> => {
  const HeaderWidget = {
    CustomComponent: AdManagerEntityHeader,
    name: 'adManagerHeader',
    view: {
      name: 'adManagerHeader',
      title: 'Ad Score',
      container: {
        style: { borderBottom: `1px solid ${colors.lightestGrey}`, marginBottom: 50 }
      }
    },
    data: {
      adScoreName: 'adScore'
    }
  };
  const advancedSearchSideBarWidget = buildAdvancedSearchSideBarWidget();
  advancedSearchSideBarWidget.view.hideByDefault = true;
  advancedSearchSideBarWidget.view.container.style = {
    ...advancedSearchSideBarWidget.view.container.style,
    paddingTop: 0
  };
  advancedSearchSideBarWidget.data.defaultGroupByField = 'stacklineSku';
  advancedSearchSideBarWidget.data.useInternalState = true;

  const tabbedSearchResultGridWidget = buildTabbedSearchResultGridWidget({
    app,
    entity: { type: 'ineligibleProduct' },
    eventBus,
    searchResultsGridWidgetDataOverride: {
      useInternalState: true,
      showZeroProducts: true
    },
    searchResultsGridWidgetGridOptOverride: {
      pageSize: 20
    },
    headerWidgetDataOverride: {
      hideHeader: true
    }
  });

  const searchResultGridWidgetGroupWidget = buildWidgetGroup(
    [advancedSearchSideBarWidget, tabbedSearchResultGridWidget],
    {
      view: {
        container: {
          style: {
            height: '550px',
            width: '100%',
            display: 'flex'
          }
        }
      }
    }
  );

  const groupByFieldNameByEntityType = {
    client: 'retailerId',
    adEntity: 'entityId',
    adPortfolio: 'portfolioId',
    adCampaign: 'campaignId'
  };
  const subMenuItems = [];

  subMenuItems.push({
    icon: ExportIcon,
    text: 'Download Ineligible Products',
    eventName: 'downloadAdManagerIneligible'
  });

  return {
    widgets: [
      HeaderWidget,
      {
        name: 'adManagerInfoPage',
        CustomComponent: AdIneligibleInfo,
        view: {
          noInnerContainer: true,
          name: 'adManagerInfoPage',
          gridOptions: {
            pageSize: 50
          }
        },
        data: {
          groupByFieldName: groupByFieldNameByEntityType[entity.type],
          statePropertyName: 'ineligible_info',
          gridWidget: searchResultGridWidgetGroupWidget
        }
      },
      {
        CustomComponent: PageContextMenu,
        name: 'pageContextMenu',
        view: {
          name: 'pageContextMenu',
          buttons: subMenuItems
        }
      }
    ]
  };
};
