import { useContext } from 'react';
import { FormDataContext } from 'src/components/AdManager/Search/FormDataProvider';

/**
 * Listen to event bus changes that indicate the sidebar filters
 * have changed and return a formData object representing
 * the selected filters.
 */
const useFormData = () => {
  const context = useContext(FormDataContext);

  if (!context) {
    throw new Error('useFormData must be used within a FormDataProvider');
  }

  return context;
};

export default useFormData;
