import { SlColumn, SlRow } from '@stackline/ui';
import _chunk from 'lodash/chunk';
import React, { useMemo, useState } from 'react';
import {
  REVIEWS_PAGE_SIZE,
  useReviewTrendsReviewGridData
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Reviews/ReviewTrends/ReviewTrendsReviewGridData';
import GenericCardLoading from 'src/components/BeaconRedesignComponents/GenericCardLoading/GenericCardLoading';
import Pagination from 'src/components/BeaconRedesignComponents/Pagination/Pagination';
import ProductReviewCard from 'src/components/BeaconRedesignComponents/ProductReviewCard/ProductReviewCard';
import { useReviewTrendsDonutChartData } from './ReviewTrendsDonutChartData';
import { shouldHidePagination } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/utils';

const CARD_PER_ROW = 3;
const CARDS_PER_PAGE = 12;

const LoadingGrid = () => {
  return (
    <SlColumn spacing="cardGrid">
      {_chunk(Array.from({ length: CARDS_PER_PAGE }), CARD_PER_ROW).map((row, index) => (
        <SlRow key={`reviewLoadingGridRow-${index}`} spacing="cardGrid">
          {row.map((_, rowIndex) => (
            <SlColumn key={`reviewLoadingGridCol-${rowIndex}`} widths="full" horizontalPosition="center">
              <GenericCardLoading width={330.6} />
            </SlColumn>
          ))}
        </SlRow>
      ))}
    </SlColumn>
  );
};

const ReviewTrendsReviewGrid = ({ starFilter }: { starFilter?: string[] }) => {
  const { donutData, isLoading: donutLoading } = useReviewTrendsDonutChartData();
  const [pageNumber, setPageNumber] = useState(1);
  const { reviewDocumentData, isLoading } = useReviewTrendsReviewGridData(
    Math.floor((pageNumber - 1) / (REVIEWS_PAGE_SIZE / CARDS_PER_PAGE)) + 1,
    starFilter
  );

  // TODO this doesn't return the correct number of reviews
  const totalReviews = useMemo(() => {
    return donutData ? donutData.reduce((acc, { count }) => acc + count, 0) : 0;
  }, [donutData]);

  const totalPages = useMemo(() => Math.ceil(totalReviews / CARDS_PER_PAGE), [totalReviews]);

  const displayData = useMemo(() => {
    // The index into the returned data. Since the data will directly contain the result from
    // the backend, we mod by the backend page size.
    // For example, we show 12 cards per page but fetch 108 items at a time. Therefore, when
    // we are on page 10, we are showing the second fetched results from the backend and
    // so we start at index 0
    const startIndex = ((pageNumber - 1) % (REVIEWS_PAGE_SIZE / CARDS_PER_PAGE)) * CARDS_PER_PAGE;
    const endIndex = startIndex + CARDS_PER_PAGE;

    return reviewDocumentData.slice(startIndex, endIndex);
  }, [pageNumber, reviewDocumentData]);

  const onClickNext = () => {
    const newPage = Math.min(totalPages, pageNumber + 1);
    setPageNumber(newPage);
  };

  const onClickPrevious = () => {
    setPageNumber(Math.max(1, pageNumber - 1));
  };

  return (
    <>
      {isLoading || donutLoading ? (
        <LoadingGrid />
      ) : (
        <SlColumn spacing="cardGrid">
          {_chunk(displayData, CARD_PER_ROW).map((items: any[]) => (
            <SlRow key={items.map((i) => i.reviewId).join('')} spacing="cardGrid" horizontalPosition="start">
              {items.map((review) => {
                return <ProductReviewCard key={review.reviewId} review={review} />;
              })}
            </SlRow>
          ))}
        </SlColumn>
      )}
      <div style={{ marginTop: '20px' }}>
        {shouldHidePagination({
          page: pageNumber,
          pageSize: CARDS_PER_PAGE,
          rowCount: (displayData || []).length
        }) ? null : (
          <SlRow horizontalPosition="end">
            <Pagination
              currentPage={pageNumber}
              totalPages={totalPages}
              onClickNext={onClickNext}
              onClickPrevious={onClickPrevious}
            />
          </SlRow>
        )}
      </div>
    </>
  );
};

export default ReviewTrendsReviewGrid;
