/**
 * This component wraps the entire application and catches any errors that bubble up from inside, displaying an error
 * page that redirects users back to the application home page.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { trackError } from '../../../utils/mixpanel';
import StarrySky from 'src/components/SignIn/StarrySky/StarrySky';
import LogoIcon from '../Logo/LogoIcon/LogoIcon';

const styles = {
  message: {
    margin: '29px 0 17px 0',
    fontSize: '20px',
    textAlign: 'center'
  },
  back: {
    fontSize: '14px',
    textAlign: 'center'
  }
};

class ErrorBoundary extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  static defaultProps = { children: null };

  state = { hasError: false };

  /**
   * If the user hits the browser back button while on the error page, we need to refresh the application manually
   * in order to purge the error state.
   */
  componentWillReceiveProps({ history: { action, goBack }, location: { pathname, search } }) {
    // We only want to do this when they click the browser back button while the application is in the error state.
    if (action !== 'POP' || !this.state.hasError) {
      return;
    }

    // Sometimes, the same route gets pushed multiple times.  In that case, we want to go back another step.
    if (this.props.location.pathname === pathname && this.props.location.search === search) {
      goBack();
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    const shouldReportError = !window.location.href.includes('-localhost');
    if (shouldReportError) {
      trackError(window.location.href, error);
    }

    this.setState({ hasError: true });
    console.warn(error, info);
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    const { app } = this.props;

    if (this.state.hasError) {
      return (
        <div className="beacon-signin-container">
          <div className="beacon-sence">
            <StarrySky appName={app.name} />
          </div>
          <div className="beacon-signin-form-container">
            <div className="signin-form">
              <div className={`app-logo app-logo-${app.name}`}>
                <LogoIcon />
              </div>
              <h1 className="login_app_title">{app.displayName}</h1>
              <div style={styles.message}>
                Sorry we have encountered an <br />
                error. Please try again.
              </div>
              <div>
                <Link to="/">
                  <p style={styles.back}>Back to home</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
