/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { BusinessUnit } from 'sl-api-connector/types/savedSearch';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import _pick from 'lodash/pick';
import Dialog from '@mui/material/Dialog';
import { store } from 'src/main';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import {
  deleteRequestedSavedSearch,
  fetchAllSavedSearch,
  updateSavedSearch
} from 'src/store/modules/segments/operations';
import ReduxStore from 'src/types/store/reduxStore';

export const getParentBUsOfSavedSearch = (segments: ReduxStore['segments'], savedSearchId: string) =>
  segments.businessUnits.filter((bu) => bu.children.includes(savedSearchId));

const mapStateToProps = ({ ...state }: ReduxStore) => _pick(state, ['app', 'segments']);

const DeleteSavedSearchDialog: React.FC<
  { isOpen: boolean; setIsClosed: () => void; savedSearchId?: string | null; onDelete?: () => void } & ReturnType<
    typeof mapStateToProps
  > &
    RouteComponentProps
> = ({ isOpen, setIsClosed, savedSearchId, onDelete, app, segments, history }) => {
  const [deleteButtonText, setDeleteButtonText] = useState('Delete');

  const handleDeleteSavedSearch = useCallback(async () => {
    if (onDelete) {
      onDelete();
    }

    // If this segment has parent BUs that list it as a child, update them all first
    const parentBUs: BusinessUnit[] = getParentBUsOfSavedSearch(segments, savedSearchId!);
    if (parentBUs.length > 0) {
      setDeleteButtonText('Updating Parents...');
      const promises = parentBUs.map(({ id, userId, children, displayName, isTopLevel }) =>
        store.dispatch(
          updateSavedSearch(
            'businessunit',
            {
              children: children.filter((childId) => childId !== savedSearchId),
              dn: displayName,
              isTopLevel
            },
            id,
            userId
          )
        )
      );
      await Promise.all(promises);
    }

    setDeleteButtonText('Deleting...');

    await store.dispatch(deleteRequestedSavedSearch(app.name, savedSearchId!));

    store.dispatch(fetchAllSavedSearch());
    setDeleteButtonText('Deleted');
    setIsClosed();
    setTimeout(() => setDeleteButtonText('Delete'), 300);

    // clear entity selection on URL
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('selectedType');
    searchParams.delete('selectedId');
    const newParams = searchParams.toString();
    history.push(`?${newParams}`);
  }, [app.name, history, savedSearchId, segments, setIsClosed, onDelete]);

  if (!savedSearchId || !segments.savedSearchesById) {
    return null;
  }
  const savedSearch = segments.savedSearchesById.get(savedSearchId);
  if (!savedSearch) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={setIsClosed}>
      <div className="confirm_dialog">
        <div className="confirm_title">Confirmation</div>
        <div className="confirm_body">
          Are you sure you want to delete <strong>{savedSearch.displayName}</strong>? This cannot be undone.
        </div>
        <div className="confirm_buttons">
          <LargeMuiButton
            style={{ width: 88, height: 35, marginRight: 5 }}
            label={deleteButtonText}
            onClick={handleDeleteSavedSearch}
          />
          <LargeMuiButton
            secondary
            style={{ width: 88, height: 35, marginLeft: 5, marginRight: 5 }}
            label="Cancel"
            onClick={setIsClosed}
          />
        </div>
      </div>
    </Dialog>
  );
};

const EnhancedDeleteSavedSearchDialog = connect(mapStateToProps)(withRouter(DeleteSavedSearchDialog));

export default EnhancedDeleteSavedSearchDialog;
