import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import { Dispatch } from 'react';
import { Widget } from 'src/types/application/widgetTypes';
import { CancelTokenSource } from 'axios';
import _cloneDeep from 'lodash/cloneDeep';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import _get from 'lodash/get';
import { SearchGridConstants } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConstants';

export class ProductsWithNoMetricsFetcher {
  // eslint-disable-next-line class-methods-use-this
  public async getProductSkusWithNoMetricsForCampaignOrAdGroup(
    searchSideBarConditions: ISearchFilterConditions,
    dispatch: Dispatch<any>,
    widget: Widget,
    mainEntity: any,
    retailer: any,
    app: any,
    cancelSource: CancelTokenSource,
    skusWithMetrics: any
  ): Promise<string[]> {
    // filter the term filter which has the field name stackline sku
    const sideBarFilters = _cloneDeep(searchSideBarConditions.termFilters);
    const filterConditionWithCampaignOrAdGroupId: any = sideBarFilters.find(
      (x: any) => x.fieldName === 'campaignId' || x.fieldName === 'adGroupId'
    ) || { values: [] };
    // find all the docs
    const productIndexName = 'adCampaignAdGroupProduct';
    const productSkus = await dispatch(
      fetchEntityMetrics(
        widget.data.statePropertyName,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName: productIndexName,
          customResponseParser: (action: any) => {
            return action.apiResponse.data[0].documents.map((x: any) => _get(x, ['stacklineSku'], ''));
          }
        },
        [
          {
            doAggregation: false,
            additionalRequestMetadata: {
              requestAdditionalMetadata: true
            },
            conditions: {
              termFilters: [filterConditionWithCampaignOrAdGroupId]
            },
            pageNumber: 1,
            pageSize: SearchGridConstants.PAGE_SIZE_10K,
            returnDocuments: true,
            processDocuments: false
          }
        ],
        _get(cancelSource, 'token'),
        true
      )
    );
    const missingSkus = productSkus.filter((x: string) => !skusWithMetrics.some((y: string) => y === x));
    return missingSkus;
  }
}
