import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import _pick from 'lodash/pick';
import { EventBus } from 'src/types/utils';
import { AdManagerEvents } from 'src/types/application/event';
import { store } from 'src/main';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import 'src/components/AdCampaignBuilder/Widgets/TargetEntitiesGrid/TargetEntitiesGrid.scss';
import { AdCampaignManagerServiceClient } from 'src/utils/adCampaignManagerServiceClient';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import ReduxStore from 'src/types/store/reduxStore';
import { Widget } from 'src/types/application/widgetTypes';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import Dialog from '@mui/material/Dialog';
import { AD_CAMPAIGN_STATUS } from 'sl-ad-campaign-manager-data-model';
import Loading from 'src/components/common/Loading';
import { fetchAdCampaigns } from 'src/store/modules/adManager/adCampaigns/operations';

const ColorButton = withStyles(() => ({
  root: {
    color: '#2a8ade',
    backgroundColor: 'white',
    textTransform: 'none',
    '&:hover': {
      // backgroundColor: 'white'
    }
  },
  label: {
    color: '#2a8ade',
    fontSize: '14px'
  }
}))(Button);

const mapStateToProps = (state: ReduxStore, { widget }: { widget: Widget }) => ({
  ..._pick(state, [
    'app',
    'entityService',
    'comparisonTimePeriod',
    'mainTimePeriod',
    'retailer',
    'categories',
    'filters',
    'allWeekIdsByRetailerId',
    'adCampaigns',
    'adPortfolios',
    'adEntities'
  ]),
  dataSet: state.entitySearchService[widget.data.statePropertyName],
  selectedMapping: state.entitySearchService[`${widget.data.statePropertyName}_selectedMapping`] || []
});

const renderLoading = () => {
  return (
    <div className="confirm_loading">
      <div>
        <Loading size={80} thickness={1} />
      </div>
      <div className="confirm_title">Submitting...</div>
    </div>
  );
};

type Props = ReturnType<typeof mapStateToProps> & { widget: Widget; eventBus: EventBus; hideActionButtons?: boolean };

class SearchResultsActionBar extends React.Component<Props> {
  public state: any = {
    isLoading: false,
    confirmationDialogOpen: false,
    selectedFullData: false,
    groupByField: { displayName: '', displayNamePlural: '' },
    count: 0,
    status: ''
  };

  public async changeEntitiesStatus() {
    const { dataSet, selectedMapping } = this.props;
    const { selectedFullData } = this.state;
    this.setState({ isLoading: true });
    if (!dataSet || !dataSet.data || dataSet.data.length === 0) {
      return;
    }
    const entitiesSelected = selectedFullData
      ? dataSet.fullDataSet
      : dataSet.data.filter((dataPoint: any) => selectedMapping.includes(dataPoint.id)).map(({ entity }) => entity);
    if (entitiesSelected && entitiesSelected.length > 0) {
      const { beaconClientId } = entitiesSelected.filter((x: any) => x.beaconClientId)[0];
      const { platformType } = entitiesSelected.filter((x: any) => x.platformType)[0];
      const { status } = this.state;
      await AdCampaignManagerServiceClient.updateEntityStatus({
        beaconClientId,
        platformType,
        entities: entitiesSelected,
        entityType: entitiesSelected[0].type,
        updatedStatus: status as AD_CAMPAIGN_STATUS
      });
    }
    await store.dispatch(fetchAdCampaigns());

    this.props.eventBus.emit(AdManagerEvents.ENTITY_EVENT_METRICS_GRID_FILTER_TOGGLED, null);
    this.setState({ isLoading: false, confirmationDialogOpen: false });
  }

  public handleSelectedFullDataClick = async () => {
    const { widget, dataSet } = this.props;
    const newSelectedMapping = dataSet.fullDataSet.map((item) => item.id);
    await store.dispatch(
      entitySearchServiceOperations.receiveEntitySalesMetrics(
        `${widget.data.statePropertyName}_selectedMapping`,
        newSelectedMapping
      )
    );
    this.setState({ selectedFullData: true });
  };

  public clearSelectedFullDataClick = async () => {
    const { widget } = this.props;
    await store.dispatch(
      entitySearchServiceOperations.receiveEntitySalesMetrics(`${widget.data.statePropertyName}_selectedMapping`, [])
    );
    this.setState({ selectedFullData: false });
  };

  public renderConfirmation() {
    const { confirmationDialogOpen, groupByField, count, status, isLoading } = this.state;
    const displayName = count > 1 ? groupByField.displayNamePlural : groupByField.displayName;
    const stateAction = status === 'paused' ? 'pause' : status === 'enabled' ? 'enable' : status;
    return (
      <Dialog open={confirmationDialogOpen}>
        <div className="confirm_dialog">
          {isLoading ? (
            renderLoading()
          ) : (
            <>
              <div className="confirm_title">Confirmation</div>
              <div className="confirm_body">
                {`Are you sure you want to ${stateAction} ${count} ${displayName.toLowerCase()}?`}
              </div>
              <div className="confirm_buttons">
                <LargeMuiButton
                  style={{ width: 88, height: 35, marginRight: 5 }}
                  label="Confirm"
                  onClick={async () => {
                    await this.changeEntitiesStatus();
                  }}
                />
                <LargeMuiButton
                  secondary
                  style={{ width: 88, height: 35, marginLeft: 5, marginRight: 5 }}
                  label="Cancel"
                  onClick={() => this.setState({ confirmationDialogOpen: false })}
                />
              </div>
            </>
          )}
        </div>
      </Dialog>
    );
  }

  public render() {
    const { dataSet, selectedMapping, widget } = this.props;
    const { selectedFullData } = this.state;

    if (!dataSet || !dataSet.data || dataSet.data.length === 0) {
      return null;
    }
    const entitiesSelected = dataSet.data.filter((dataPoint) => selectedMapping.includes(dataPoint.id));
    const enabledSellectFullData =
      selectedFullData ||
      (dataSet.fullDataSet && dataSet.fullDataSet.length > 50 && dataSet.data.length <= selectedMapping.length);
    if (entitiesSelected && entitiesSelected.length > 0) {
      const count = selectedFullData ? dataSet.totalResultCount : entitiesSelected.length;
      const groupByField = widget.data.groupByFields[0];
      const displayName = count > 1 ? groupByField.displayNamePlural : groupByField.displayName;
      return (
        <div>
          {this.renderConfirmation()}
          <div className="control_button" style={{ display: 'flex', alignItems: 'center' }}>
            {`${count} ${displayName.toLowerCase()} selected.`}
            {enabledSellectFullData && (
              <>
                {selectedFullData ? (
                  <ColorButton onClick={() => this.clearSelectedFullDataClick()}>Clear selection</ColorButton>
                ) : (
                  <ColorButton onClick={() => this.handleSelectedFullDataClick()}>
                    Select all {dataSet.totalResultCount} {displayName}
                  </ColorButton>
                )}
              </>
            )}
            {!this.props.hideActionButtons && (
              <>
                <LargeMuiButton
                  secondary
                  style={{ width: 99, marginLeft: 15, marginRight: 15 }}
                  label="Pause"
                  onClick={() =>
                    this.setState({
                      confirmationDialogOpen: true,
                      groupByField,
                      count,
                      status: 'paused'
                    })
                  }
                />
                <LargeMuiButton
                  style={{ width: 99, marginRight: 15 }}
                  label="Enable"
                  onClick={() =>
                    this.setState({
                      confirmationDialogOpen: true,
                      groupByField,
                      count,
                      status: 'enabled'
                    })
                  }
                />
              </>
            )}
          </div>
        </div>
      );
    }
    return null;
  }
}

const EnhancedSearchResultsActionBar: React.ComponentType<{ widget: Widget; hideActionButtons?: boolean }> = withRouter(
  withBus('eventBus')(connect(mapStateToProps)(SearchResultsActionBar))
) as any;

export default EnhancedSearchResultsActionBar;
