/**
 * Contains misc. functions used to render elements of `EntityPage`s.
 *
 * TODO: Split this out into multiple files; it's waaaay too big as it is.
 */

import React from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _memoize from 'lodash/memoize';
import _pick from 'lodash/pick';
import { Option } from 'funfix-core';
import { mergeConditions, RangeFilter, Conditions } from 'sl-api-connector/search/conditions';
import ReduxStore from 'src/types/store/reduxStore';
import moment from 'moment';

import GenericChart from 'src/components/Charts/GenericChart';
import colors from 'src/utils/colors';
import fontStyle from 'src/utils/fontStyle';
import { not, propEq, ne, filterNils } from 'src/utils/fp';
import {
  getFirstWeekOfYear,
  getLastWeekOfYear,
  getWeekLastDate,
  getLastWeek,
  moveForwardOneWeek
} from 'src/utils/dateformatting';
import { buildMetricValue } from 'src/utils/metrics';
import { INDEX_FIELDS, ENTITIES, METRICTYPE } from 'src/utils/entityDefinitions';
import { mapQueryToConditions } from 'src/utils/segments';
import {
  chartColorsLineChart,
  chartColorsColumnChart,
  getBaseLineChartSeries,
  computeLastWeek,
  undoComputeLastWeek
} from './util';
import { getTooltipDiv } from './tooltipDiv';
import { error, warn, panic } from 'src/utils/mixpanel';
import { combineFilterConditions } from 'src/utils/filters';
import { store } from 'src/main';
import { AppName, Entity, SavedSearch } from 'sl-api-connector';
import { MetricField } from 'src/types/application/types';
import { getPreviousWeekId, getPreviousYearWeekId, getWeekId } from 'src/utils/dateUtils';
import { ChartDisplayTimePeriod } from '../TrendChart/types';
import { addOrReplaceFilterInEntityConditions } from 'src/components/AdManager/Search';
import { computeMainLegendValueAdjustment } from 'src/components/EntityPage/Renderer/helpers';
import { isDrive } from 'src/utils/app';

const getChartDisplayTimePeriodInner = (
  timePeriod: ReduxStore['mainTimePeriod'] | ReduxStore['comparisonTimePeriod']
): ChartDisplayTimePeriod => {
  // Changing this will make main period appear before comparison
  let startWeekSpecialCase = timePeriod.id === '1w' ? getFirstWeekOfYear(timePeriod.startWeek) : timePeriod.startWeek;
  // Removing '1w' will make chart appear as a dot for comparison
  const extendedMonths = isDrive && timePeriod.id !== 'prior-year' ? ['ytd'] : ['ytd', '1w'];
  if (isDrive && timePeriod.id !== 'prior-year') {
    startWeekSpecialCase = timePeriod.startWeek;
  }
  return {
    id: timePeriod.id,
    displayName: timePeriod.displayName,
    timePeriod,
    startWeek: startWeekSpecialCase,
    endWeek: timePeriod.endWeek,
    firstWeekOfYear: getFirstWeekOfYear(timePeriod.startWeek),
    lastWeekOfYear: getLastWeekOfYear(timePeriod.endWeek),
    showExtendedMonths: extendedMonths.includes(timePeriod.id)
  };
};

export const getChartDisplayTimePeriod = _memoize(getChartDisplayTimePeriodInner);

const mkTooltipFormatter = (
  { isSameMetricComparison, mainCurrencySymbol, mainLocale, chartSeries, currencySymbols, locales, loadLastWeek },
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod,
  checkFirstDataPoint
) =>
  function formatter() {
    let tooltipStyle = '';
    let percentChangeDiv = '';
    // compute metric change, only if there are two metrics and they are of same type
    if (isSameMetricComparison && this.points.length > 1) {
      let metricsChange = { icon: '+', color: colors.green };
      let percentChange =
        this.points[0].y > 0
          ? (this.points[1].y - this.points[0].y) / this.points[0].y
          : this.points[1].y > 0
          ? 100
          : 0;
      // if the change is negative, then display in red with minus icon
      if (percentChange < 0) {
        metricsChange = { icon: '\u2212', color: colors.red };
        percentChange *= -1;
      }
      percentChange = buildMetricValue(
        percentChange,
        METRICTYPE.PERCENT,
        mainCurrencySymbol,
        true,
        null,
        mainLocale
      ).value;
      const tooltipWidth =
        100 +
        (this.points[0].x.name ? Math.min(this.points[0].x.name.length, 10) * 6 : percentChange.toString().length * 7);
      percentChangeDiv = `<div style="display:inline-block; float:right; color:${metricsChange.color};">
        ${metricsChange.icon}${percentChange}%
      </div>`;
      tooltipStyle += `width: ${tooltipWidth}px;`;
    }
    let yAxisMetricsDiv = '';
    const mainWeekId = getWeekId(this.points[0].x);
    const { lastWeekOfYear: mainLastWeekOfYear } = chartDisplayTimePeriod;
    let weekId = mainWeekId;
    if (mainWeekId > mainLastWeekOfYear && mainWeekId % 100 === 1) {
      // do 53 as the week
      weekId = +`${mainLastWeekOfYear}`;
    }
    for (let i = this.points.length - 1; i >= 0; i -= 1) {
      const value = this.points[i];

      if (checkFirstDataPoint && value.point.index === 0 && loadLastWeek) {
        percentChangeDiv = '';
      } else {
        const { color } = chartSeries[value.series.index];
        const metricValue = buildMetricValue(
          value.y,
          chartSeries[value.series.index].metricType,
          currencySymbols[value.series.index],
          true,
          null,
          locales[value.series.index]
        );
        let weekIdToUse =
          i === 0
            ? color === '#46a8f6' || chartComparisonDisplayTimePeriod.id === 'prior-period'
              ? weekId
              : weekId - 100
            : weekId;

        // Used to display the correct week ending after shifting to account for week 53
        // need to shift if start week in 2020 (or other leap year) and end week is 53 or in 2021
        const comparisonStartYear = Math.floor(chartComparisonDisplayTimePeriod.startWeek / 100);
        const comparisonEndYear = Math.floor(chartComparisonDisplayTimePeriod.endWeek / 100);
        const comparisonEndWeek = chartComparisonDisplayTimePeriod.endWeek % 100;

        const needToMoveForward =
          moment([comparisonStartYear]).isLeapYear() &&
          (comparisonStartYear !== comparisonEndYear || comparisonEndWeek === 53);
        if (i === 0 && chartComparisonDisplayTimePeriod.id === 'prior-year') {
          if (needToMoveForward && weekIdToUse < +`${comparisonStartYear}53`) {
            weekIdToUse++;
          }
        }

        yAxisMetricsDiv += `<div style="margin-top:5px;color:${color};">
              ${metricValue.prefix || ''}${metricValue.value}
              <span class='sl-metric__suffix'>${metricValue.suffix || ''} ${moment(getWeekLastDate(weekIdToUse)).format(
          'M/D'
        )}</span>
            </div>`;
      }
    }

    return checkFirstDataPoint && yAxisMetricsDiv === ''
      ? false
      : getTooltipDiv(tooltipStyle, percentChangeDiv, yAxisMetricsDiv, this.x, undefined, weekId);
  };

export const computeMarketShareComparisonLegendValue = ({
  mainStartWeek,
  mainMetricName,
  mainEntityMetrics,
  chartDisplayTimePeriod,
  marketShareDenominatorESSDataKey,
  chartComparisonDisplayTimePeriod
}) => {
  // need to decrement mainstartweek here if we loaded the last one above
  const loadLastWeek = chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id !== '1w'; // Original logic, unsure if we should remove or not
  const actualStartWeek = loadLastWeek ? undoComputeLastWeek(mainStartWeek) : mainStartWeek;

  const filterAndSumMetrics = (metrics) =>
    metrics
      .filter(
        (dataPoint) =>
          dataPoint.weekId >= actualStartWeek && dataPoint.weekId <= chartComparisonDisplayTimePeriod.endWeek
      )
      .reduce((acc, dataPoint) => acc + dataPoint.value, 0);

  if (!marketShareDenominatorESSDataKey) {
    error(
      "Expected `marketShareDenominatorESSDataKey` to be set since this is a market share computation, but it wasn't"
    );
    return 0;
  }

  const nonMarketShareFieldName = mainMetricName.replace('MarketShare', '');

  const { data: numeratorMetrics } = mainEntityMetrics[nonMarketShareFieldName];
  const numeratorSum = filterAndSumMetrics(numeratorMetrics);

  const denominatorData = store.getState().entitySearchService[marketShareDenominatorESSDataKey];
  if (!denominatorData) {
    warn(
      `Denominator data expected to be in \`entitySearchService.${marketShareDenominatorESSDataKey}\` was empty; falling back to lossy market share computation...`
    );
    return null;
  }
  const { data: denominatorMetrics } = denominatorData[nonMarketShareFieldName];
  const denominatorSum = filterAndSumMetrics(denominatorMetrics);

  if (denominatorSum === 0) {
    return numeratorSum > 0 ? 1 : 0;
  }
  return numeratorSum / denominatorSum;
};

export function getCommonSummaryTrendParameters({
  chartPropsOverride,
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod,
  mainEntityMetrics,
  mainMetricName,
  comparisonEntityMetrics,
  comparisonMetricName,
  groupByFieldName,
  marketShareDenominatorESSDataKey,
  replaceZeroWithLastKnownValue
}) {
  const metricsDataByWeekId = mainEntityMetrics[mainMetricName];
  const metricsComparisonDataByWeekId = comparisonEntityMetrics[comparisonMetricName];
  const { currencySymbol: mainCurrencySymbol, locale: mainLocale } = metricsDataByWeekId;
  const { currencySymbol: comparisonCurrencySymbol, locale: comparisonLocale } = metricsComparisonDataByWeekId;
  const currencySymbols = [mainCurrencySymbol, comparisonCurrencySymbol];
  const locales = [mainLocale, comparisonLocale];
  const legendDivs = [];
  let legendClassName = '';
  let legendValue = '';
  let legendChange = '';
  let legendAbsoluteChange = '';

  const chartSeries = getBaseLineChartSeries(chartDisplayTimePeriod, metricsDataByWeekId);
  let mainMetricSum = 0.0;
  let mainMetricDataPointCount = 0.0;
  let mainMetricLastValue = 0.0;
  let mainMetricTrueSum = 0.0;
  let mainMetricTrueCount = 0.0;
  const mainMetricDependentFieldsSum = {};
  if (metricsDataByWeekId.dependentFields) {
    metricsDataByWeekId.dependentFields.forEach((field) => {
      mainMetricDependentFieldsSum[field.name] = 0;
      mainMetricDependentFieldsSum[`${field.indexName}__${field.name}`] = 0;
    });
  }
  const isSameMetricComparison =
    metricsDataByWeekId.displayName === metricsComparisonDataByWeekId.displayName &&
    mainCurrencySymbol === comparisonCurrencySymbol;
  // we should revisit this one to make sure this approach is correct
  const isYearOverYearComparison =
    isSameMetricComparison &&
    chartDisplayTimePeriod.endWeek - chartComparisonDisplayTimePeriod.endWeek === 100 &&
    !(chartDisplayTimePeriod.id === '52w' && chartComparisonDisplayTimePeriod.id === 'prior-period');

  const loadLastWeek = chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id !== '1w'; // Original logic, unsure if we should remove or not
  const mainStartWeek = loadLastWeek
    ? computeLastWeek(chartDisplayTimePeriod.startWeek)
    : chartDisplayTimePeriod.startWeek;

  const filteredVal = metricsDataByWeekId.data.filter(
    (dataPoint) => dataPoint.weekId >= mainStartWeek && dataPoint.weekId <= chartDisplayTimePeriod.endWeek
  );
  filteredVal.forEach((dataPoint, index) => {
    let { weekEnding } = dataPoint;
    weekEnding = typeof weekEnding === 'string' ? new Date(Date.parse(weekEnding)) : weekEnding;
    if (replaceZeroWithLastKnownValue) {
      dataPoint.value = index !== 0 && dataPoint.value === 0 ? filteredVal[index - 1].value : dataPoint.value;
    }
    if (chartDisplayTimePeriod.id === '1w' || chartDisplayTimePeriod.startWeek === chartDisplayTimePeriod.endWeek) {
      chartSeries[0].zones = [{ color: chartColorsLineChart[2] }];
      if (chartDisplayTimePeriod.endWeek === dataPoint.weekId) {
        chartSeries[0].data.push({
          x: weekEnding.getTime(),
          y: dataPoint.value,
          marker: { enabled: true, radius: 3, fillColor: chartColorsLineChart[0] }
        });
      } else {
        chartSeries[0].data.push({ x: weekEnding.getTime(), y: dataPoint.value });
      }
    } else {
      chartSeries[0].data.push([weekEnding.getTime(), dataPoint.value]);
    }

    if (
      dataPoint.weekId >= chartDisplayTimePeriod.timePeriod.startWeek &&
      dataPoint.weekId <= chartDisplayTimePeriod.timePeriod.endWeek
    ) {
      if (metricsDataByWeekId.aggregationFunction === 'stats') {
        if (dataPoint.sum) {
          mainMetricSum += dataPoint.sum;
        }

        if (dataPoint.documentCount) {
          mainMetricDataPointCount += dataPoint.documentCount;
        }
      } else {
        mainMetricSum += dataPoint.value;
        mainMetricTrueCount += dataPoint.count || 0;
        mainMetricTrueSum += dataPoint.value * dataPoint.count || 0;
        mainMetricDataPointCount += 1;
        mainMetricLastValue = dataPoint.value;
        if (metricsDataByWeekId.dependentFields) {
          metricsDataByWeekId.dependentFields.forEach((field) => {
            let dependentFieldParamValue = 0;
            if (mainEntityMetrics[`${field.name}_by_${groupByFieldName}`].data.length > 0) {
              const dependentFieldDataPoint = mainEntityMetrics[`${field.name}_by_${groupByFieldName}`].data.find(
                (x) => x.weekId === dataPoint.weekId
              );
              if (dependentFieldDataPoint) {
                dependentFieldParamValue = dependentFieldDataPoint.value;
              }
            }
            mainMetricDependentFieldsSum[field.name] += dependentFieldParamValue;
            mainMetricDependentFieldsSum[`${field.indexName}__${field.name}`] += dependentFieldParamValue;
          });
        }
      }
    }
  });

  let mainLegendValue = mainMetricSum;

  if (
    metricsDataByWeekId.aggregationFunction === 'avg' ||
    metricsDataByWeekId.timePeriodAggregationFunction === 'avg'
  ) {
    mainLegendValue = mainMetricSum / (mainMetricDataPointCount > 0 ? mainMetricDataPointCount : 1);
  } else if (metricsDataByWeekId.timePeriodAggregationFunctionType === 'lastValue') {
    mainLegendValue = mainMetricLastValue;
  } else if (metricsDataByWeekId.timePeriodAggregationFunctionType === 'trueAvg') {
    mainLegendValue = mainMetricTrueSum / (mainMetricTrueCount > 0 ? mainMetricTrueCount : 1);
  } else if (
    (metricsDataByWeekId.aggregationFunctionType === 'computed' ||
      metricsDataByWeekId.aggregationFunctionType === 'derived') &&
    metricsDataByWeekId.aggregationFunctionEvaluator
  ) {
    mainLegendValue = metricsDataByWeekId.aggregationFunctionEvaluator.evaluate(mainMetricDependentFieldsSum);
  }

  // This is a hack to work around faulty market share computations.  If we were to just take the market share series and
  // average each of its data points, we'd be averaging averages instead of calculating a true average of market share over
  // that period.
  //
  // Here, we determine the data keys for the raw numerator and denominator data sets, sum them, and divide directly.
  if (mainMetricName.includes('MarketShare')) {
    const accurateComputedMarketShare = computeMainLegendValueAdjustment({
      mainStartWeek,
      mainMetricName,
      mainEntityMetrics,
      chartDisplayTimePeriod,
      marketShareDenominatorESSDataKey,
      chartComparisonDisplayTimePeriod
    });

    if (accurateComputedMarketShare !== null) {
      mainLegendValue = accurateComputedMarketShare;
    }
    if (chartDisplayTimePeriod.timePeriod.startWeek === chartDisplayTimePeriod.timePeriod.endWeek) {
      // show mainMetricLastValue when time period is last week
      mainLegendValue = mainMetricLastValue;
    }
  }

  const mainLegendMetricValue = buildMetricValue(
    mainLegendValue,
    metricsDataByWeekId.metricType,
    mainCurrencySymbol,
    true,
    metricsDataByWeekId.dataType,
    mainLocale
  );

  if (chartDisplayTimePeriod.showExtendedMonths) {
    for (let i = chartDisplayTimePeriod.endWeek + 1; i <= chartDisplayTimePeriod.lastWeekOfYear; i += 1) {
      const weekEnding = getWeekLastDate(i);
      chartSeries[0].data.push([weekEnding.getTime(), null]);
    }
  }

  if (chartComparisonDisplayTimePeriod && metricsComparisonDataByWeekId) {
    let comparisonMetricSum = 0.0;
    let comparisonMetricDataPointCount = 0.0;
    let comparisonMetricLastValue = 0.0;
    const comparisonMetricDependentFieldsSum = {};
    if (metricsComparisonDataByWeekId.dependentFields) {
      metricsComparisonDataByWeekId.dependentFields.forEach((field) => {
        comparisonMetricDependentFieldsSum[field.name] = 0;
        comparisonMetricDependentFieldsSum[`${field.indexName}__${field.name}`] = 0;
      });
    }
    chartSeries.push({
      name: chartComparisonDisplayTimePeriod.displayName,
      metricType: metricsComparisonDataByWeekId.metricType,
      data: [],
      color: chartColorsLineChart[1],
      marker: {
        lineColor: chartColorsLineChart[1],
        fillColor: chartColorsLineChart[1],
        lineWidth: 3,
        symbol: 'circle'
      }
    });

    const mainStartYear = Math.floor(chartDisplayTimePeriod.startWeek / 100);
    const mainEndYear = Math.floor(chartDisplayTimePeriod.endWeek / 100);
    const mainTimePeriodContains53 = moment([mainStartYear]).isLeapYear() && mainStartYear !== mainEndYear;

    const comparisonStartWeek = loadLastWeek
      ? computeLastWeek(chartComparisonDisplayTimePeriod.startWeek)
      : chartComparisonDisplayTimePeriod.startWeek;
    metricsComparisonDataByWeekId.data.forEach((dataPoint, index) => {
      if (comparisonStartWeek <= dataPoint.weekId && chartComparisonDisplayTimePeriod.endWeek >= dataPoint.weekId) {
        const shouldMoveForwardOneWeek = dataPoint.weekId < +`${mainStartYear - 1}53` && mainTimePeriodContains53;
        if (shouldMoveForwardOneWeek) {
          dataPoint = moveForwardOneWeek(dataPoint);
        }
        if (replaceZeroWithLastKnownValue) {
          dataPoint.value =
            index !== 0 && dataPoint.value === 0
              ? metricsComparisonDataByWeekId.data[index - 1].value
              : dataPoint.value;
        }
        let weekEnding = isYearOverYearComparison ? dataPoint.weekEndingNextYear : dataPoint.weekEnding;
        weekEnding = typeof weekEnding === 'string' ? new Date(Date.parse(weekEnding)) : weekEnding;
        if (chartDisplayTimePeriod.id === '1w' || chartDisplayTimePeriod.startWeek === chartDisplayTimePeriod.endWeek) {
          chartSeries[1].zones = [{ color: chartColorsLineChart[2] }];
          if (chartComparisonDisplayTimePeriod.endWeek === dataPoint.weekId) {
            chartSeries[1].data.push({
              x: weekEnding.getTime(),
              y: dataPoint.value,
              marker: { enabled: true, radius: 3, fillColor: chartColorsLineChart[1] }
            });
          } else {
            chartSeries[1].data.push({ x: weekEnding.getTime(), y: dataPoint.value });
          }
        } else {
          chartSeries[1].data.push([weekEnding.getTime(), dataPoint.value]);
        }

        if (
          chartComparisonDisplayTimePeriod.timePeriod.startWeek <= dataPoint.weekId &&
          chartComparisonDisplayTimePeriod.timePeriod.endWeek >= dataPoint.weekId
        ) {
          comparisonMetricSum += dataPoint.value;
          comparisonMetricDataPointCount += 1;
          comparisonMetricLastValue = dataPoint.value;
          if (metricsComparisonDataByWeekId.dependentFields) {
            metricsComparisonDataByWeekId.dependentFields.forEach((field) => {
              let dependentFieldParamValue = 0;
              if (comparisonEntityMetrics[`${field.name}_by_${groupByFieldName}`].data.length > 0) {
                const dependentFieldDataPoint = comparisonEntityMetrics[
                  `${field.name}_by_${groupByFieldName}`
                ].data.find((x) => x.weekId === dataPoint.weekId);
                if (dependentFieldDataPoint) {
                  dependentFieldParamValue = dependentFieldDataPoint.value;
                }
              }
              comparisonMetricDependentFieldsSum[field.name] += dependentFieldParamValue;
              comparisonMetricDependentFieldsSum[`${field.indexName}__${field.name}`] += dependentFieldParamValue;
            });
          }
        }
      } else if (chartDisplayTimePeriod.showExtendedMonths) {
        if (
          chartComparisonDisplayTimePeriod.firstWeekOfYear <= dataPoint.weekId &&
          chartComparisonDisplayTimePeriod.lastWeekOfYear >= dataPoint.weekId
        ) {
          let weekEnding = isYearOverYearComparison ? dataPoint.weekEndingNextYear : dataPoint.weekEnding;
          weekEnding = typeof weekEnding === 'string' ? new Date(Date.parse(weekEnding)) : weekEnding;
          if (chartDisplayTimePeriod.id === '1w') {
            if (
              chartComparisonDisplayTimePeriod.id === 'prior-period' &&
              chartComparisonDisplayTimePeriod.endWeek < dataPoint.weekId
            ) {
              return;
            }
            chartSeries[1].data.push({ x: weekEnding.getTime(), y: dataPoint.value });
          } else {
            chartSeries[1].data.push([weekEnding.getTime(), dataPoint.value]);
          }
        }
      }
    });

    let comparisonLegendValue =
      metricsComparisonDataByWeekId.aggregationFunction === 'avg' ||
      metricsComparisonDataByWeekId.timePeriodAggregationFunction === 'avg'
        ? comparisonMetricSum / (comparisonMetricDataPointCount > 0 ? comparisonMetricDataPointCount : 1)
        : comparisonMetricSum;
    if (metricsComparisonDataByWeekId.timePeriodAggregationFunctionType === 'lastValue') {
      comparisonLegendValue = comparisonMetricLastValue;
    }

    if (
      (metricsComparisonDataByWeekId.aggregationFunctionType === 'computed' ||
        metricsComparisonDataByWeekId.aggregationFunctionType === 'derived') &&
      metricsComparisonDataByWeekId.aggregationFunctionEvaluator
    ) {
      comparisonLegendValue = metricsComparisonDataByWeekId.aggregationFunctionEvaluator.evaluate(
        comparisonMetricDependentFieldsSum
      );
    }

    if (isSameMetricComparison) {
      let metricsChangeIcon = { icon: '+', className: 'increase' };
      let diff = mainLegendValue - comparisonLegendValue;
      if (diff.toString().indexOf('e') !== -1) {
        diff = parseFloat(diff.toFixed(5));
      }
      let metricsChangePercent = diff / Math.abs(comparisonLegendValue);
      if (metricsChangePercent && metricsChangePercent < 0) {
        metricsChangePercent *= -1;
        metricsChangeIcon = { icon: '\u2212', className: 'decrease' };
      }

      const metricsChangeMetricValue = buildMetricValue(
        mainLegendValue - comparisonLegendValue,
        metricsComparisonDataByWeekId.metricType,
        mainCurrencySymbol,
        true,
        null,
        mainLocale
      );
      const metricsChangePercentMetricValue = buildMetricValue(
        metricsChangePercent,
        METRICTYPE.PERCENT,
        mainCurrencySymbol,
        true,
        null,
        mainLocale
      );

      legendClassName = `legend__percent-change-metric--${metricsChangeIcon.className}`;
      legendChange = `${metricsChangeIcon.icon}${metricsChangePercentMetricValue.value}${
        metricsChangePercentMetricValue.suffix || ''
      }`;
      legendAbsoluteChange = `${metricsChangeIcon.icon}${
        metricsChangeMetricValue.prefix || ''
      }${metricsChangeMetricValue.value.replace('-', '')}${metricsChangeMetricValue.suffix || ''}`;
    }
    legendValue = `${mainLegendMetricValue.prefix || ''}${mainLegendMetricValue.value}${
      mainLegendMetricValue.suffix || ''
    }`;
  } else {
    let mainLegendDiv = `<div>${mainLegendMetricValue.prefix || ''}${mainLegendMetricValue.value}${
      mainLegendMetricValue.suffix || ''
    }</div>
    <div class="legend__primary-date" style="margin-top:5px;">${chartDisplayTimePeriod.displayName}</div>`;
    if (!isSameMetricComparison) {
      mainLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${metricsDataByWeekId.displayName}</div>`;
    }
    legendDivs.push(mainLegendDiv);
  }

  [chartSeries[0].legendDiv, chartSeries[1].legendDiv] = legendDivs;
  const xAxis = [];
  // Realigns data if the start weeks do not line up
  // A case where this happens is when we have to shift the comparison period to account for 202053
  if (
    chartComparisonDisplayTimePeriod.id === 'prior-year' &&
    chartSeries[0].data.length > 0 &&
    chartSeries[1].data.length > 0 &&
    chartSeries[0].data.length === chartSeries[1].data.length &&
    chartSeries[0].data[0][0] !== chartSeries[1].data[0][0]
  ) {
    chartSeries[0].data.forEach((dataPoint, index) => {
      // Update time stamps so time periods line up
      if (chartSeries[1].data && chartSeries[1].data[index]) {
        chartSeries[1].data[index] = [dataPoint[0], chartSeries[1].data[index][1]];
      }
    });
  }

  const longestSeriesData =
    chartSeries[0].data.length > chartSeries[1].data.length ? chartSeries[0].data : chartSeries[1].data;
  if (longestSeriesData.length > 0) {
    let xAxisMin = longestSeriesData[0][0];
    let xAxisMax = longestSeriesData[longestSeriesData.length - 1][0];
    if (chartDisplayTimePeriod.id === 'ytd' || chartDisplayTimePeriod.id === '1w') {
      // Always show the full year so we can show the comparison period "tail"
      xAxisMin = getWeekLastDate(chartDisplayTimePeriod.firstWeekOfYear).getTime();
      xAxisMax = getWeekLastDate(chartDisplayTimePeriod.lastWeekOfYear).getTime();
    }

    if (chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id === '1w') {
      xAxisMin = getWeekLastDate(chartComparisonDisplayTimePeriod.startWeek).getTime();
      xAxisMax = getWeekLastDate(chartDisplayTimePeriod.endWeek).getTime();
    }

    if (
      chartComparisonDisplayTimePeriod &&
      chartComparisonDisplayTimePeriod.id === 'prior-period' &&
      chartDisplayTimePeriod.id !== '1w' &&
      chartDisplayTimePeriod.startWeek !== chartDisplayTimePeriod.endWeek &&
      chartSeries[1].data.length &&
      chartSeries[0].data.length
    ) {
      [[xAxisMin]] = chartSeries[1].data;
      [xAxisMax] = chartSeries[0].data[chartSeries[0].data.length - 1];
    }

    xAxis.push({
      className:
        chartDisplayTimePeriod.id === 'ytd' || chartDisplayTimePeriod.id === '1w'
          ? 'highcharts-axis-labels highcharts-xaxis-labels-weekly-trend'
          : '',
      min: xAxisMin,
      max: xAxisMax,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        align: 'left'
      }
    });
  } else {
    xAxis.push({});
  }

  let chartProps = {
    chart: { type: 'line' },
    title: { text: metricsDataByWeekId.displayName },
    subtitle: {
      text: '',
      style: {
        borderBottom: 'none'
      }
    },
    plotOptions: {
      fillEnabled: true,
      series: {
        states: {
          hover: {
            enabled: true
          }
        }
      }
    },
    legend: {
      legendAbsoluteChange,
      legendValue,
      legendChange,
      legendClassName,
      labelFormatter() {
        return this.userOptions.legendDiv;
      }
    },
    tooltip: {
      formatter: mkTooltipFormatter(
        { isSameMetricComparison, mainCurrencySymbol, mainLocale, chartSeries, currencySymbols, locales, loadLastWeek },
        chartDisplayTimePeriod,
        chartComparisonDisplayTimePeriod,
        true
      ),
      positioner(labelWidth, labelHeight, point) {
        return { x: point.plotX - labelWidth + 10, y: point.plotY - labelHeight / 2 };
      }
    },
    xAxis,
    yAxis: [
      {
        labels: {
          formatter() {
            const val = buildMetricValue(
              this.value,
              metricsDataByWeekId.metricType,
              currencySymbols[0],
              true,
              null,
              locales[0]
            );
            return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
          }
        }
      },
      {
        opposite: true,
        labels: {
          formatter() {
            const val = buildMetricValue(
              this.value,
              metricsComparisonDataByWeekId.metricType,
              currencySymbols[1],
              true,
              null,
              locales[1]
            );
            return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
          }
        }
      }
    ]
  };

  chartProps = _merge(chartProps, chartPropsOverride);

  const baseYAxisTitleStyle = {
    'font-size': '12px',
    'font-family': "'Roboto', sans-serif",
    'font-weight': fontStyle.regularWeight
  };

  if (!isSameMetricComparison && !chartProps.singleYAxis) {
    chartProps.yAxis[0].title = {
      text: metricsDataByWeekId.displayName,
      useHTML: true,
      style: {
        ...baseYAxisTitleStyle,
        color: chartColorsLineChart[0]
      }
    };
    chartProps.yAxis.push({
      title: {
        text: metricsComparisonDataByWeekId.displayName,
        useHTML: true,
        style: {
          ...baseYAxisTitleStyle,
          color: chartColorsLineChart[1]
        }
      },
      labels: {
        formatter() {
          const val = buildMetricValue(
            this.value,
            metricsComparisonDataByWeekId.metricType,
            mainCurrencySymbol,
            true,
            null,
            mainLocale
          );
          return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
        }
      },
      opposite: true
    });
    chartSeries[1].yAxis = 1;
  } else {
    chartSeries[1].yAxis = 0;
  }
  // SWITCH ORDER TO OLD-TO-NEW
  chartSeries.push(chartSeries.shift());
  chartSeries[0].showInLegend = false;
  chartSeries[0].softThreshold = false;
  chartSeries[1].softThreshold = false;
  return {
    chartProps,
    chartSeries,
    value: mainLegendValue
  };
}

function calculateWeekRange(startWeek, endWeek) {
  const weekRange = (Math.floor(endWeek / 100) - Math.floor(startWeek / 100)) * 52;
  return weekRange - (startWeek % 100) + (endWeek % 100) + 1;
}

export function getBasicTrendChartParams(metricsDataByWeekId, chartDisplayTimePeriod, chartColor) {
  const currencySymbols = [metricsDataByWeekId.currencySymbol];
  const locales = [metricsDataByWeekId.locale];
  const chartSeries = [
    {
      name: chartDisplayTimePeriod.displayName,
      metricType: metricsDataByWeekId.metricType,
      data: [],
      color: chartColor,
      marker: {
        lineColor: chartColor,
        fillColor: chartColor,
        lineWidth: 3,
        symbol: 'circle'
      }
    }
  ];
  metricsDataByWeekId.data.forEach((dataPoint) => {
    if (chartDisplayTimePeriod.startWeek <= dataPoint.weekId && chartDisplayTimePeriod.endWeek >= dataPoint.weekId) {
      let { weekEnding } = dataPoint;
      weekEnding = typeof weekEnding === 'string' ? new Date(Date.parse(weekEnding)) : weekEnding;
      chartSeries[0].data.push([weekEnding.getTime(), dataPoint.value]);
    }
  });
  const weekRange = calculateWeekRange(chartDisplayTimePeriod.startWeek, chartDisplayTimePeriod.endWeek);
  const weeksToRender = weekRange < 8 ? weekRange : 8;
  if (chartSeries[0].data.length > weeksToRender) {
    chartSeries[0].data = chartSeries[0].data.splice(chartSeries[0].data.length - weeksToRender, weeksToRender);
  }
  const chartProps = {
    chart: { type: 'areaspline', height: 200, marginTop: 20 },
    title: { enabled: false },
    plotOptions: {
      fillEnabled: false,
      series: {
        states: {
          hover: {
            enabled: true
          }
        }
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter() {
        let yAxisMetricsDiv = '';
        for (let i = this.points.length - 1; i >= 0; i -= 1) {
          const value = this.points[i];
          const { color } = chartSeries[value.series.index];
          const metricValue = buildMetricValue(
            value.y,
            chartSeries[value.series.index].metricType,
            currencySymbols[value.series.index],
            true,
            null,
            locales[value.series.index]
          );
          yAxisMetricsDiv += `<div style="margin-top:5px;color:${color};">
              ${metricValue.prefix || ''}${metricValue.value}
              <span class='sl-metric__suffix'>${metricValue.suffix || ''}</span>
            </div>`;
        }

        return getTooltipDiv('', '', yAxisMetricsDiv, this.x);
      },
      positioner(labelWidth, labelHeight, point) {
        return { x: point.plotX, y: point.plotY + 25 };
      }
    },
    subtitle: {
      style: {
        borderBottom: 'none'
      }
    },
    xAxis: [
      {
        labels: {
          enabled: false
        }
      }
    ],
    yAxis: [
      {
        labels: {
          enabled: false
        }
      }
    ]
  };

  return { chartProps, chartSeries };
}

export const setSubTitle = (
  subtitle: { displayName: unknown; id: unknown; type: unknown },
  listOfMetrics: ({ [key: string]: any } | null | undefined)[]
) =>
  listOfMetrics.forEach((metrics) => {
    if (!metrics) {
      return;
    }

    Object.keys(metrics).forEach((key) => {
      if (!metrics[key]) {
        return;
      }

      metrics[key].subtitle = subtitle.displayName;
      metrics[key].subtitleId = subtitle.id;
      metrics[key].subtitleType = subtitle.type;
    });
  });

export function checkForExponentialNumber(number: number, decimals: number) {
  const str = number.toString();
  if (str.indexOf('e') === -1) {
    return number;
  }

  const exponent = parseInt(str.split('-')[1], 10);
  const result = number.toFixed(exponent);
  // Round to the nearest four decimal places
  const roundedResult = +`${Math.round(+`${result}e${decimals}`)}e-${decimals}`;
  return roundedResult;
}

//  MOVE TO SELECTOR IN REDUX
export function computeEntityRetailerMetrics(mainEntityMetrics, mainTimePeriods, comparisonTimePeriod, retailer) {
  Object.keys(mainEntityMetrics).forEach((key) => {
    if (mainEntityMetrics[key].data) {
      const { comparisonTimePeriodRangeSuffix } = mainEntityMetrics[key];
      const comparisonValuePropName = `value${comparisonTimePeriodRangeSuffix}`;
      const dataPointByRetailerId = {
        entity: retailer,
        name: retailer.id,
        [comparisonValuePropName]: 0
      };

      mainTimePeriods.forEach((timePeriod) => {
        if (!timePeriod.startWeek) {
          return;
        }
        const mainValuePropName = `value${timePeriod.timePeriodSuffix.weekId}`;
        dataPointByRetailerId[mainValuePropName] = 0;
        mainEntityMetrics[key].data.forEach((dataPoint) => {
          if (dataPoint.weekId >= timePeriod.startWeek && dataPoint.weekId <= timePeriod.endWeek) {
            dataPointByRetailerId[mainValuePropName] += dataPoint.value;
          }
          if (dataPoint.weekId >= comparisonTimePeriod.startWeek && dataPoint.weekId <= comparisonTimePeriod.endWeek) {
            dataPointByRetailerId[comparisonValuePropName] += dataPoint.value;
          }
        });

        dataPointByRetailerId.totalGrowthValue =
          dataPointByRetailerId[comparisonValuePropName] > 0
            ? (dataPointByRetailerId[mainValuePropName] - dataPointByRetailerId[comparisonValuePropName]) /
              dataPointByRetailerId[comparisonValuePropName]
            : 10;
      });

      mainEntityMetrics[`${mainEntityMetrics[key].name}_by_retailerId`] = {
        ...mainEntityMetrics[key],
        data: [dataPointByRetailerId]
      };
    }
  });
}

//  MOVE TO SELECTOR IN REDUX
export function computeMetricChange(mainEntityMetrics: {
  [key: string]:
    | { data: { [key: string]: any }[]; mainTimePeriodRangeSuffix: string; comparisonTimePeriodRangeSuffix: string }
    | null
    | undefined;
}) {
  Object.keys(mainEntityMetrics).forEach((key) => {
    const metrics = mainEntityMetrics[key];
    if (!metrics || !metrics!.data) {
      return;
    }

    const { mainTimePeriodRangeSuffix, comparisonTimePeriodRangeSuffix } = metrics;
    const mainValuePropName = `value${mainTimePeriodRangeSuffix}`;
    const comparisonValuePropName = `value${comparisonTimePeriodRangeSuffix}`;

    metrics.data.forEach((dataPoint) => {
      if (!dataPoint[comparisonValuePropName]) {
        if (dataPoint[mainValuePropName] > 0) {
          dataPoint.value_change = dataPoint[mainValuePropName];
        } else {
          dataPoint.value_change = 0;
        }
      } else {
        dataPoint.value_change = dataPoint[mainValuePropName] - dataPoint[comparisonValuePropName];
        if (checkForExponentialNumber(dataPoint.value_change, 4) === 0) {
          dataPoint.value_change = 0;
        }
      }
    });
  });
}

// Used for only the Market Share Grid in Atlas
export function addTotalsToMarketShareGrid(
  mainAndPrevTimePeriods: any[],
  relatedEntityMetrics: { [key: string]: { data: { entity: Entity }[] } },
  mainEntityCategoryMetrics: any,
  metricName: string,
  groupByFieldName: string,
  dataLength: number
) {
  const metricPropertyName = `${metricName}_by_${groupByFieldName}`;
  const metricMarketSharePropertyName = `${metricName}MarketShare_by_${groupByFieldName}`;
  if (
    relatedEntityMetrics[metricPropertyName].data.length > dataLength ||
    relatedEntityMetrics[metricMarketSharePropertyName].data.length > dataLength ||
    relatedEntityMetrics[metricPropertyName].data.find((x) => x.entity.name === 'Total')
  ) {
    return undefined;
  }

  const finalMarketShareGrid = _cloneDeep(relatedEntityMetrics);
  const otherTotals: any = {
    rank: '-',
    entity: {
      name: 'Other',
      id: 'Other',
      type: 'brand'
    }
  };
  const totalsObject: any = {
    rank: '',
    entity: {
      name: 'Total',
      id: 'Total',
      type: 'brand'
    }
  };
  const otherShare: any = _cloneDeep(otherTotals);
  const totalsShare: any = _cloneDeep(totalsObject);

  mainEntityCategoryMetrics[`${metricName}_by_retailerId`].data.forEach((item: any) => {
    mainAndPrevTimePeriods.forEach((timePeriod) => {
      if (!timePeriod.startWeek) {
        return;
      }
      const keySuffix = `${timePeriod.startWeek}_${timePeriod.endWeek}`;
      const key = `value_${keySuffix}`;
      otherShare[key] = item[`otherCurrentPercentShare_${keySuffix}`];
      otherTotals[key] = item[`otherCurrentValue_${keySuffix}`];
      totalsShare[key] = 1;
      totalsObject[key] = item[key];
    });
  });

  finalMarketShareGrid[metricPropertyName].data.push(otherTotals);
  finalMarketShareGrid[metricPropertyName].data.push(totalsObject);
  finalMarketShareGrid[metricMarketSharePropertyName].data.push(otherShare);
  finalMarketShareGrid[metricMarketSharePropertyName].data.push(totalsShare);

  return { finalMarketShareGrid };
}

/**
 *  This function calculates the other and total columns for retailer metrics
 *  @param {Object} topEntityMetrics contains the data for the top entities
 *  @param {Object} totalEntityMetrics contains totals for all entities
 *  @param {Array} mainTimePeriods is an array that contains the time periods that need values computed for
 *  @param {Object} comparisonTimePeriod contains the single comparison time period in question
 */
export function computeOtherEntityRetailerMetrics(
  topEntityMetrics,
  totalEntityMetrics,
  mainTimePeriods,
  comparisonTimePeriod
) {
  // Sales is the Total from mainEntity minus sum of top_5
  const comparisonValuePropName = `value_${comparisonTimePeriod.startWeek}_${comparisonTimePeriod.endWeek}`;
  // Collect data from top entities for Other row calculations

  Object.keys(topEntityMetrics).forEach((topEntityKey) => {
    mainTimePeriods.forEach((timePeriod) => {
      if (!timePeriod.startWeek) {
        return;
      }
      const mainValuePropName = `value_${timePeriod.startWeek}_${timePeriod.endWeek}`;
      const mainTimeRange = `${timePeriod.startWeek}_${timePeriod.endWeek}`;
      let currentSumTopEntityAmount = 0;
      let previousSumTopEntityAmount = 0;
      if (!topEntityMetrics[topEntityKey] || !topEntityMetrics[topEntityKey].data) {
        return;
      }
      topEntityMetrics[topEntityKey].data.forEach((dataPoint) => {
        if (dataPoint[mainValuePropName]) {
          currentSumTopEntityAmount += dataPoint[mainValuePropName];
          previousSumTopEntityAmount += dataPoint[comparisonValuePropName] || 0;
        } else {
          // eslint-disable-next-line
          currentSumTopEntityAmount;
          // eslint-disable-next-line
          previousSumTopEntityAmount;
        }
      });

      // Loop over the all time periods and generate other totals for each time period
      Object.keys(totalEntityMetrics).forEach((mainKey) => {
        if (mainKey.includes(topEntityKey.split('_')[0]) && mainKey.includes('retailerId')) {
          totalEntityMetrics[mainKey].data.forEach((mainDataPoint) => {
            const otherCurrentTotal = checkForExponentialNumber(
              mainDataPoint[mainValuePropName] - currentSumTopEntityAmount
            );
            const otherPreviousTotal = checkForExponentialNumber(
              mainDataPoint[comparisonValuePropName] - previousSumTopEntityAmount
            );
            const otherCurrentTotalRounded = checkForExponentialNumber(otherCurrentTotal, 4);
            const otherPreviousTotalRounded = checkForExponentialNumber(otherPreviousTotal, 4);

            const otherGrowth =
              otherPreviousTotal > 0
                ? (otherCurrentTotal - otherPreviousTotal) / otherPreviousTotal
                : otherCurrentTotalRounded > 0
                ? 1
                : 0;
            const otherCurrentShare =
              mainDataPoint[mainValuePropName] !== 0
                ? otherCurrentTotal / mainDataPoint[mainValuePropName]
                : otherCurrentTotalRounded > 0
                ? 1
                : 0;
            const otherPreviousShare =
              mainDataPoint[comparisonValuePropName] !== 0
                ? otherPreviousTotal / mainDataPoint[comparisonValuePropName]
                : otherPreviousTotalRounded > 0
                ? 1
                : 0;
            mainDataPoint[`otherCurrentValue_${mainTimeRange}`] = Math.round(otherCurrentTotalRounded * 100) / 100;
            mainDataPoint[`otherCurrentPercentGrowth_${mainTimeRange}`] = checkForExponentialNumber(otherGrowth, 4);
            mainDataPoint[`otherCurrentPercentShare_${mainTimeRange}`] = checkForExponentialNumber(
              otherCurrentShare,
              4
            );
            mainDataPoint[`otherCurrentPercentChange_${mainTimeRange}`] = checkForExponentialNumber(
              otherCurrentShare - otherPreviousShare,
              4
            );
          });
        }
      });
    });
  });
}

//  MOVE TO SELECTOR IN REDUX
function computeEntityMarketShareByMetricName(
  entityMetrics,
  entityCategoryMetrics,
  metricName,
  groupByFieldName,
  categoryGroupByFieldName
) {
  const metrics = entityMetrics[`${metricName}_by_${groupByFieldName}`];
  if (!metrics) {
    return null;
  }

  const { appName, indexName, displayName } = metrics;
  try {
    if (!INDEX_FIELDS.getField(appName, indexName, `${metricName}MarketShare`)) {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
  const groupByEntity = ENTITIES.atlas[INDEX_FIELDS.getField(appName, indexName, groupByFieldName).entity.type];
  const marketShareByEntity = _merge(
    _cloneDeep(INDEX_FIELDS.getField(appName, indexName, `${metricName}MarketShare`)),
    {
      displayName: `Market Share by ${groupByEntity.displayName}`,
      entity: metrics.entity,
      data: [],
      currencySymbol: metrics.currencySymbol || '$',
      locale: metrics.locale,
      groupByField: metrics.groupByField,
      timePeriodFieldName: metrics.timePeriodFieldName
    }
  );
  let dataMissing = false;
  const dataForMetric = _get(entityCategoryMetrics, [`${metricName}_by_${categoryGroupByFieldName}`]);
  if (!dataForMetric || dataForMetric.data.length < 0) {
    dataMissing = true;
    return undefined;
  }
  // check if the nominator is group by Brand
  const isMarketShareGridByBrand = displayName.indexOf('Brand') > -1;

  if (indexName === 'multiretailer' && dataForMetric.data.length > 1 && !isMarketShareGridByBrand) {
    const mapForDataForMetric = new Map();
    dataForMetric.data.forEach((d) => mapForDataForMetric.set(d.name, d));
    metrics.data.forEach((dataPoint) => {
      let checkIfgetCorrectVal = false;
      const marketShareDataPoint = _cloneDeep(dataPoint);
      Object.keys(marketShareDataPoint).forEach((key) => {
        const nameId = marketShareDataPoint.name;
        const getTheValueDataForMetric = mapForDataForMetric.get(nameId);
        if (key.indexOf('value_') === 0 && nameId && getTheValueDataForMetric && getTheValueDataForMetric[key]) {
          if (getTheValueDataForMetric[key] > 0) {
            checkIfgetCorrectVal = true;
            marketShareDataPoint[key] /= getTheValueDataForMetric[key];
          } else {
            marketShareDataPoint[key] = 100;
          }
        }
      });
      if (checkIfgetCorrectVal) {
        marketShareByEntity.data.push(marketShareDataPoint);
      }
    });
  } else {
    // accumulate the denominator
    const accumulateDenominator = dataForMetric.data.reduce((acc, currentValue) => {
      Object.keys(currentValue).forEach((key) => {
        if (key.indexOf('value') > -1) {
          if (!acc[key]) {
            acc[key] = currentValue[key];
          } else {
            acc[key] += currentValue[key];
          }
        }
      });
      return acc;
    }, {});
    metrics.data.forEach((dataPoint) => {
      const marketShareDataPoint = _cloneDeep(dataPoint);
      Object.keys(marketShareDataPoint).forEach((key) => {
        if (key.indexOf('value') === 0 && accumulateDenominator[key]) {
          if (accumulateDenominator[key] > 0) {
            marketShareDataPoint[key] /= accumulateDenominator[key];
          } else {
            marketShareDataPoint[key] = 100;
          }
        }
      });

      marketShareByEntity.data.push(marketShareDataPoint);
    });
  }

  if (dataMissing) {
    return undefined;
  }

  return marketShareByEntity;
}

function computeShareOfTotalSalesMetricsByMetricName(
  entityMetrics,
  entityCategoryMetrics,
  metricName,
  groupByFieldName,
  categoryGroupByFieldName
) {
  const salesShareKey = 'ShareTotalSales';
  const metrics = entityMetrics[`${metricName}_by_${groupByFieldName}`];
  if (!metrics) {
    return null;
  }

  const { appName, indexName } = metrics;
  try {
    if (!INDEX_FIELDS.getField(appName, indexName, `${metricName}${salesShareKey}`)) {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }

  const groupByEntity = ENTITIES.atlas[INDEX_FIELDS.getField(appName, indexName, groupByFieldName).entity.type];
  const marketShareByEntity = _merge(
    _cloneDeep(INDEX_FIELDS.getField(appName, indexName, `${metricName}${salesShareKey}`)),
    {
      displayName: `Share of Total Sales by ${groupByEntity.displayName}`,
      entity: metrics.entity,
      data: [],
      currencySymbol: metrics.currencySymbol,
      locale: metrics.locale,
      groupByField: metrics.groupByField,
      timePeriodFieldName: metrics.timePeriodFieldName
    }
  );

  let dataMissing = false;

  metrics.data.forEach((dataPoint) => {
    const promoSalesDataPoint = _cloneDeep(dataPoint);
    const totalRetailSales = _get(entityCategoryMetrics, [`${metricName}_by_${categoryGroupByFieldName}`], undefined);
    if (!totalRetailSales) {
      dataMissing = true;
      return;
    }

    let noDenominatorForDataPoint = false;

    Object.keys(promoSalesDataPoint).forEach((key) => {
      // Get matching data point for group by field
      const matchingDataPoint = totalRetailSales.data.find((dp) => dp.name === promoSalesDataPoint.name);
      if (matchingDataPoint) {
        if (key.indexOf('value') === 0 && matchingDataPoint[key]) {
          if (matchingDataPoint[key] > 0) {
            promoSalesDataPoint[key] /= matchingDataPoint[key];
          } else {
            promoSalesDataPoint[key] = 100;
          }
        }
      } else {
        noDenominatorForDataPoint = true;
      }
    });

    if (!noDenominatorForDataPoint) {
      marketShareByEntity.data.push(promoSalesDataPoint);
    }
  });

  if (dataMissing) {
    return undefined;
  }

  return marketShareByEntity;
}

export function addMainEntityMetricsForMarketShare(
  relatedEntityMetrics,
  mainEntityMetrics,
  mainEntity,
  mainTimePeriod,
  comparisonTimePeriod
) {
  if (mainEntity.type !== 'brand') {
    return relatedEntityMetrics;
  }

  Object.entries(relatedEntityMetrics).forEach(([key, val]) => {
    if (!key.includes('MarketShare_by_')) {
      return;
    }

    const relatedBrandEntity = val.data.find(propEq('name', mainEntity.brandName));
    if (relatedBrandEntity) {
      val.data.sort((a, b) => (a === relatedBrandEntity ? -1 : b === relatedBrandEntity ? 1 : 0));
      return;
    }

    const fieldName = key.split('_')[0];
    // const mainTimePeriodRangeSuffix = `${mainTimePeriod.startWeek}_${mainTimePeriod.endWeek}`;
    // const comparisonTimePeriodRangeSuffix = `${comparisonTimePeriod.startWeek}_${comparisonTimePeriod.endWeek}`;
    let mainMetricSum = 0;
    let mainMetricDataPointCount = 0.0;
    let comparisonMetricSum = 0;
    let comparisonMetricDataPointCount = 0.0;

    const metricsForField = mainEntityMetrics[`${fieldName}_by_weekId`];
    const { mainTimePeriodRangeSuffix, comparisonTimePeriodRangeSuffix } = metricsForField;
    metricsForField.data.forEach(({ weekId, value }) => {
      if (weekId >= mainTimePeriod.startWeek && weekId <= mainTimePeriod.endWeek) {
        mainMetricSum += value;
        mainMetricDataPointCount += 1;
      }
      if (weekId >= comparisonTimePeriod.startWeek && weekId <= comparisonTimePeriod.endWeek) {
        comparisonMetricSum += value;
        comparisonMetricDataPointCount += 1;
      }
    });

    const mainValue =
      metricsForField.aggregationFunction === 'avg' || metricsForField.timePeriodAggregationFunction === 'avg'
        ? mainMetricSum / (mainMetricDataPointCount > 0 ? mainMetricDataPointCount : 1)
        : mainMetricSum;

    const compValue =
      metricsForField.aggregationFunction === 'avg' || metricsForField.timePeriodAggregationFunction === 'avg'
        ? comparisonMetricSum / (comparisonMetricDataPointCount > 0 ? comparisonMetricDataPointCount : 1)
        : comparisonMetricSum;

    const addedBrandEntity = {
      name: mainEntity.brandName,
      entity: mainEntity,
      [`value${mainTimePeriodRangeSuffix}`]: mainValue,
      [`value${comparisonTimePeriodRangeSuffix}`]: compValue
    };

    val.data.splice(val.data.length - 1, 1);
    val.data.unshift(addedBrandEntity);
  });

  return relatedEntityMetrics;
}

//  MOVE TO SELECTOR IN REDUX
export function computeEntityMarketShareMetrics(
  mainEntityMetrics,
  mainEntityCategoryMetrics,
  comparisonEntityMetrics,
  comparisonEntityCategoryMetrics,
  groupByFieldName,
  categoryGroupByFieldName
): void {
  Object.keys(mainEntityMetrics).forEach((key) => {
    if (key.indexOf('MarketShare_by_') > -1 || key === 'apiRequest') {
      return;
    }

    const computedMarketShareMetrics = computeEntityMarketShareByMetricName(
      mainEntityMetrics,
      mainEntityCategoryMetrics,
      mainEntityMetrics[key].name,
      groupByFieldName,
      categoryGroupByFieldName
    );

    if (!computedMarketShareMetrics) {
      console.warn('Failed to compute entity market share by metric name due to missing or bad data.');
      return;
    }

    // I just found out that this \/ mutates Redux directly.  I don't know what to say.
    mainEntityMetrics[`${mainEntityMetrics[key].name}MarketShare_by_${groupByFieldName}`] = computedMarketShareMetrics;
    if (comparisonEntityMetrics && comparisonEntityMetrics[key]) {
      const computedComparisonMarketShareMetrics = computeEntityMarketShareByMetricName(
        comparisonEntityMetrics,
        comparisonEntityCategoryMetrics,
        comparisonEntityMetrics[key].name,
        groupByFieldName,
        categoryGroupByFieldName
      );

      if (!computedComparisonMarketShareMetrics) {
        return;
      }

      comparisonEntityMetrics[`${comparisonEntityMetrics[key].name}MarketShare_by_${groupByFieldName}`] =
        computedComparisonMarketShareMetrics;
    }
  });
}

export function computeShareOfTotalSalesMetrics(
  mainEntityMetrics,
  mainEntityCategoryMetrics,
  comparisonEntityMetrics,
  groupByFieldName,
  categoryGroupByFieldName
) {
  const salesShareKey = 'ShareTotalSales';
  const dataPointCountKey = 'dataPointCount';
  Object.keys(mainEntityMetrics).forEach((key) => {
    if (key.indexOf(salesShareKey) > -1 || key === 'apiRequest' || key.includes(dataPointCountKey)) {
      return;
    }

    const computedShareOfTotalSalesMetrics = computeShareOfTotalSalesMetricsByMetricName(
      mainEntityMetrics,
      mainEntityCategoryMetrics,
      mainEntityMetrics[key].name,
      groupByFieldName,
      categoryGroupByFieldName
    );

    if (!computedShareOfTotalSalesMetrics) {
      console.warn(`Failed to compute entity market share by metric name due to missing or bad data for key: ${key}`);
      return;
    }

    // I just found out that this \/ mutates Redux directly.  I don't know what to say.
    mainEntityMetrics[`${mainEntityMetrics[key].name}${salesShareKey}_by_${groupByFieldName}`] =
      computedShareOfTotalSalesMetrics;
    if (comparisonEntityMetrics && comparisonEntityMetrics[key]) {
      const computedComparisonShareOfTotalSalesMetrics = computeShareOfTotalSalesMetricsByMetricName(
        comparisonEntityMetrics,
        mainEntityCategoryMetrics,
        comparisonEntityMetrics[key].name,
        groupByFieldName,
        categoryGroupByFieldName
      );

      if (!computedComparisonShareOfTotalSalesMetrics) {
        return;
      }

      comparisonEntityMetrics[`${comparisonEntityMetrics[key].name}${salesShareKey}_by_${groupByFieldName}`] =
        computedComparisonShareOfTotalSalesMetrics;
    }
  });
}

function computeWeeklyMarketShareByMetricName(
  entityMetrics,
  entityCategoryMetrics,
  metricName,
  groupByFieldName,
  metricType
) {
  const dataKey = `${metricName}_by_${groupByFieldName}`;

  try {
    if (!INDEX_FIELDS.getField('atlas', entityMetrics[dataKey].indexName || 'sales', `${metricName}MarketShare`)) {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }

  const marketShareByWeekId = _merge(
    _cloneDeep(INDEX_FIELDS.getField('atlas', entityMetrics[dataKey].indexName || 'sales', `${metricName}MarketShare`)),
    {
      displayName: `Market Share Trend`,
      ..._pick(entityMetrics[dataKey], ['entity', 'currencySymbol', 'locale', 'groupByField', 'timePeriodFieldName'])
    }
  );

  marketShareByWeekId.metricType = metricType || marketShareByWeekId.metricType;

  marketShareByWeekId.data = _get(entityCategoryMetrics, [dataKey, 'data'], []).map(
    ({ weekId, weekEnding, weekEndingNextYear, value }) => {
      const entityDataPointForWeekId = entityMetrics[dataKey].data.find(propEq('weekId', weekId));

      return {
        weekId,
        weekEnding,
        weekEndingNextYear,
        value: entityDataPointForWeekId ? (value > 0 ? entityDataPointForWeekId.value / value : 1) : 0
      };
    }
  );

  return marketShareByWeekId;
}

export function computeWeeklyMarketShareMetrics(
  mainEntityMarketShareNumeratorMetrics,
  mainEntityMarketShareDenominatorMetrics,
  comparisonEntityMetrics,
  groupByFieldName,
  metricType,
  mutate = true
) {
  const { newMainEntityMetrics, newComparisonEntityMetrics } = mutate
    ? {
        newMainEntityMetrics: mainEntityMarketShareNumeratorMetrics,
        newComparisonEntityMetrics: comparisonEntityMetrics
      }
    : {
        newMainEntityMetrics: { ...mainEntityMarketShareNumeratorMetrics },
        newComparisonEntityMetrics: { ...comparisonEntityMetrics }
      };

  Object.keys(mainEntityMarketShareNumeratorMetrics)
    .filter(ne('apiRequest'))
    .forEach((key) => {
      if (mainEntityMarketShareDenominatorMetrics[key]) {
        newMainEntityMetrics[`${mainEntityMarketShareNumeratorMetrics[key].name}MarketShare_by_${groupByFieldName}`] =
          computeWeeklyMarketShareByMetricName(
            mainEntityMarketShareNumeratorMetrics,
            mainEntityMarketShareDenominatorMetrics,
            mainEntityMarketShareNumeratorMetrics[key].name,
            groupByFieldName,
            metricType
          );
      }

      if (comparisonEntityMetrics && comparisonEntityMetrics[key]) {
        newComparisonEntityMetrics[`${comparisonEntityMetrics[key].name}MarketShare_by_${groupByFieldName}`] =
          computeWeeklyMarketShareByMetricName(
            comparisonEntityMetrics,
            mainEntityMarketShareDenominatorMetrics,
            comparisonEntityMetrics[key].name,
            groupByFieldName,
            metricType
          );
      }
    });

  return { mainEntityMetrics: newMainEntityMetrics, comparisonEntityMetrics: newComparisonEntityMetrics };
}

function getComparisonChartParameters(
  chartPropsOverride,
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod,
  metricsDataByKeyField,
  metricsComparisonDataByKeyField
) {
  const { currencySymbol: mainCurrencySymbol, locale: mainLocale, mainTimePeriodRangeSuffix } = metricsDataByKeyField;
  const {
    currencySymbol: comparisonCurrencySymbol,
    locale: comparisonLocale,
    mainTimePeriodRangeSuffix: comparisonTimePeriodRangeSuffix
  } = metricsComparisonDataByKeyField;
  const locales = [mainLocale, comparisonLocale];
  const currencySymbols = [mainCurrencySymbol, comparisonCurrencySymbol];
  const legendDivs = [];
  const isSameMetricComparison = metricsDataByKeyField.displayName === metricsComparisonDataByKeyField.displayName;

  const chartSeries = [
    {
      name: chartDisplayTimePeriod.displayName,
      metricType: metricsDataByKeyField.metricType,
      data: [],
      color: chartColorsColumnChart[0],
      marker: {
        lineColor: chartColorsColumnChart[0],
        fillColor: chartColorsColumnChart[0],
        lineWidth: 3,
        symbol: 'circle'
      }
    }
  ];
  const categories = [];
  let mainLegendValue = 0.0;

  metricsDataByKeyField.data.forEach((dataPoint, index) => {
    if (Number.isNaN(+dataPoint.entity.id)) {
      return;
    }

    const value = dataPoint[`value_${mainTimePeriodRangeSuffix}`];
    if (!_isNil(value)) {
      chartSeries[0].data.push([dataPoint.name, value]);
      categories.push(dataPoint.entity);
      // This needs to be based on the brand name
      if (index === 0) {
        mainLegendValue = value;
      }
    }
  });

  const mainLegendMetricValue = buildMetricValue(
    mainLegendValue,
    metricsDataByKeyField.metricType,
    mainCurrencySymbol,
    true,
    metricsDataByKeyField.dataType,
    mainLocale
  );

  let comparisonLegendValue = 0.0;

  if (metricsComparisonDataByKeyField && metricsComparisonDataByKeyField.data) {
    // Add comparison data as first in chart series in order to show earlier years first
    chartSeries.unshift({
      name: chartComparisonDisplayTimePeriod.displayName,
      metricType: metricsComparisonDataByKeyField.metricType,
      data: [],
      color: chartColorsColumnChart[1],
      marker: {
        lineColor: chartColorsColumnChart[1],
        fillColor: chartColorsColumnChart[1],
        lineWidth: 3,
        symbol: 'circle'
      }
    });

    metricsComparisonDataByKeyField.data.forEach((dataPoint, index) => {
      if (Number.isNaN(+dataPoint.entity.id)) {
        return;
      }
      const value = dataPoint[`value${comparisonTimePeriodRangeSuffix}`];
      if (!_isNil(value)) {
        chartSeries[0].data.push([dataPoint.name, value]);
        if (index === 0) {
          comparisonLegendValue = value;
        }
      }
    });
  }

  const comparisonLegendMetricValue = buildMetricValue(
    comparisonLegendValue,
    metricsComparisonDataByKeyField.metricType,
    comparisonCurrencySymbol,
    true,
    metricsComparisonDataByKeyField.dataType,
    comparisonLocale
  );

  let mainLegendDiv = `<div>${mainLegendMetricValue.prefix || ''}${mainLegendMetricValue.value}${
    mainLegendMetricValue.suffix || ''
  }`;

  if (isSameMetricComparison) {
    let metricsChangeIcon = { icon: '+', className: 'increase' };
    let metricsChangePercent = (mainLegendValue - comparisonLegendValue) / comparisonLegendValue;
    if (metricsChangePercent && metricsChangePercent < 0) {
      metricsChangePercent *= -1;
      metricsChangeIcon = { icon: '\u2212', className: 'decrease' };
    }
    const metricsChangePercentMetricValue = buildMetricValue(
      metricsChangePercent,
      METRICTYPE.PERCENT,
      mainCurrencySymbol,
      true,
      null,
      mainLocale
    );
    mainLegendDiv += '<span class="legend__percent-change">(<span class="legend__percent-change-metric legend__percent';
    mainLegendDiv += `-change-metric--${metricsChangeIcon.className}"><span>${metricsChangeIcon.icon}</span>`;
    mainLegendDiv += `<span class="sl-metric"><span class="sl-metric__value">${metricsChangePercentMetricValue.value}`;
    mainLegendDiv += `</span><span class="sl-metric__suffix">${metricsChangePercentMetricValue.suffix}</span></span>`;
    mainLegendDiv += '</span>)</span>';
  }

  mainLegendDiv += `</div><div class="legend__primary-date" style="margin-top:5px;">${chartDisplayTimePeriod.displayName}</div>`;

  if (
    metricsDataByKeyField.entity.type !== metricsComparisonDataByKeyField.entity.type ||
    metricsDataByKeyField.entity.id !== metricsComparisonDataByKeyField.entity.id
  ) {
    mainLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${metricsDataByKeyField.entity.shortDisplayName}</div>`;
  } else if (!isSameMetricComparison) {
    mainLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${metricsDataByKeyField.displayName}</div>`;
  }
  legendDivs.push(mainLegendDiv);

  let comparisonLegendDiv = `<div>${comparisonLegendMetricValue.prefix || ''}${comparisonLegendMetricValue.value}${
    comparisonLegendMetricValue.suffix || ''
  }</div>
    <div class="legend__primary-date" style="margin-top:5px;">${chartComparisonDisplayTimePeriod.displayName}</div>`;

  if (
    metricsDataByKeyField.entity.type !== metricsComparisonDataByKeyField.entity.type ||
    metricsDataByKeyField.entity.id !== metricsComparisonDataByKeyField.entity.id
  ) {
    comparisonLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${metricsComparisonDataByKeyField.entity.shortDisplayName}</div>`;
  } else if (!isSameMetricComparison) {
    comparisonLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${metricsComparisonDataByKeyField.displayName}</div>`;
  }

  legendDivs.unshift(comparisonLegendDiv);
  [chartSeries[0].legendDiv, chartSeries[1].legendDiv] = legendDivs;
  let chartProps = {
    chart: { type: 'bar' },
    title: { text: metricsDataByKeyField.displayName },
    subtitle: { text: metricsDataByKeyField.subtitle },
    plotOptions: {
      fillEnabled: true,
      series: {
        dataLabels: {
          enabled: !!(chartPropsOverride && chartPropsOverride.dataLabels && chartPropsOverride.dataLabels.enabled),
          crop: false,
          overflow: 'none',
          style: {
            color: colors.darkBlue,
            'font-size': '14px',
            'font-weight': fontStyle.regularWeight,
            'font-family': 'Roboto, sans-serif'
          },
          formatter: function formatter() {
            const { value, suffix } = buildMetricValue(
              this.y,
              chartSeries[this.series.index].metricType,
              currencySymbols[this.series.index],
              true,
              chartSeries[this.series.index].dataType,
              locales[this.series.index]
            );

            return chartPropsOverride.ignoreZeroValues && this.y === 0 ? '' : `${value}${suffix}`;
          }
        }
      }
    },
    legend: {
      enabled: true,
      itemStyle: {
        'font-size': '14px'
      },
      labelFormatter() {
        return this.userOptions.legendDiv;
      }
    },
    tooltip: {
      formatter: mkTooltipFormatter(
        { isSameMetricComparison, mainCurrencySymbol, mainLocale, chartSeries, currencySymbols, locales },
        chartDisplayTimePeriod,
        chartComparisonDisplayTimePeriod,
        false
      ),
      positioner(labelWidth, labelHeight, point) {
        return { x: point.plotX, y: point.plotY - 20 };
      }
    },
    yAxis: [
      {
        labels: {
          formatter() {
            const { prefix, value, suffix } = buildMetricValue(
              this.value,
              metricsDataByKeyField.metricType,
              mainCurrencySymbol,
              true,
              null,
              mainLocale
            );

            return `${prefix || ''}${value}${suffix || ''}`;
          }
        }
      }
    ],
    xAxis: [
      {
        categories,
        labels: {
          useHTML: true,
          formatter() {
            const name = this.value.name.length > 15 ? `${this.value.name.slice(0, 15)}...` : this.value.name;
            return `<a style="margin-top: 5px; display: inline-block;" href="/${this.value.type}/${this.value.id}">${name}</a>`;
          },
          style: {
            color: colors.darkBlue,
            fontSize: '13px',
            fontWeight: fontStyle.regularWeight,
            fontFamily: 'Roboto, sans-serif'
          }
        }
      },
      {
        categories,
        labels: {
          useHTML: true,
          formatter() {
            const name = this.value.name.length > 15 ? `${this.value.name.slice(0, 15)}...` : this.value.name;
            return `<a style="margin-top: 5px; display: inline-block;" href="/${this.value.type}/${this.value.id}">${name}</a>`;
          },
          style: {
            color: colors.darkBlue,
            fontSize: '13px',
            fontWeight: fontStyle.regularWeight,
            fontFamily: 'Roboto, sans-serif'
          }
        }
      }
    ]
  };

  chartProps = _merge(chartProps, chartPropsOverride);
  return { chartProps, chartSeries };
}

export function renderComparisonChart(
  view,
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod,
  mainEntityMetrics,
  comparisonEntityMetrics,
  metricName,
  groupByFieldName
) {
  if (
    mainEntityMetrics === undefined ||
    metricName === undefined ||
    mainEntityMetrics[`${metricName}_by_${groupByFieldName}`] === undefined ||
    mainEntityMetrics[`${metricName}_by_${groupByFieldName}`].currencySymbol === undefined
  ) {
    return null;
  }

  const { chartSeries, chartProps } = getComparisonChartParameters(
    view.chartPropsOverride,
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    mainEntityMetrics[`${metricName}_by_${groupByFieldName}`],
    comparisonEntityMetrics[`${metricName}_by_${groupByFieldName}`]
  );
  return <GenericChart chartSeries={chartSeries} chartProps={chartProps} />;
}

export function getEntityGridParams(
  retailer: ReduxStore['retailer'],
  mainTimePeriod: ReduxStore['mainTimePeriod'],
  comparisonTimePeriod: ReduxStore['comparisonTimePeriod'],
  aggregationConditions: any, // is there a type for this somewhere?
  mainMetricField: MetricField
) {
  const entityComparisonFilters = [
    {
      fieldName: 'weekId',
      minValue:
        mainMetricField.aggregationFunctionType === 'lastValue'
          ? comparisonTimePeriod.endWeek
          : comparisonTimePeriod.startWeek,
      maxValue: comparisonTimePeriod.endWeek
    }
  ];
  const exportComparisonFilters = [
    {
      fieldName: 'weekId',
      minValue:
        mainMetricField.aggregationFunctionType === 'lastValue'
          ? comparisonTimePeriod.endWeek
          : comparisonTimePeriod.startWeek,
      maxValue: comparisonTimePeriod.endWeek
    }
  ];

  exportComparisonFilters[0].minValue = comparisonTimePeriod.startWeek;
  const entityGridAggregationConditions = {
    termFilters: [],
    ..._cloneDeep(aggregationConditions)
  };
  entityGridAggregationConditions.rangeFilters = entityGridAggregationConditions.rangeFilters.filter(
    not(propEq('fieldName', 'weekId'))
  );
  entityGridAggregationConditions.rangeFilters.push({
    fieldName: 'weekId',
    minValue:
      mainMetricField.aggregationFunctionType === 'lastValue' ? mainTimePeriod.endWeek : mainTimePeriod.startWeek,
    maxValue: mainTimePeriod.endWeek
  });
  entityGridAggregationConditions.termFilters.push({
    fieldName: `retailerId`,
    values: [`${retailer.id}`]
  });

  [mainMetricField].forEach((field) => {
    if (field.conditions) {
      if (field.conditions.termFilters) {
        entityGridAggregationConditions.termFilters = entityGridAggregationConditions.termFilters || [];
        entityGridAggregationConditions.termFilters = entityGridAggregationConditions.termFilters.concat(
          field.conditions.termFilters
        );
      }
      if (field.conditions.rangeFilters) {
        entityGridAggregationConditions.rangeFilters = entityGridAggregationConditions.rangeFilters || [];
        entityGridAggregationConditions.rangeFilters = entityGridAggregationConditions.rangeFilters.concat(
          field.conditions.rangeFilters
        );
      }
    }
  });

  const exportAggregationConditions = _cloneDeep(entityGridAggregationConditions);
  // We want the export to be across the multiple weeks (instead of just lastValue)
  if (mainMetricField.name === 'unitsOnHand') {
    const weekFilter = exportAggregationConditions.rangeFilters.find(
      (f: { fieldName: string }) => f.fieldName === 'weekId'
    );

    if (weekFilter) {
      weekFilter.minValue = mainTimePeriod.startWeek;
    }
  }

  const weekIdRangeFilter = Option.of(_get(exportAggregationConditions, 'rangeFilters'))
    .map((rangeFilters) => rangeFilters.find(propEq('fieldName', 'weekId')))
    .orNull();

  if (weekIdRangeFilter) {
    weekIdRangeFilter.minValue = weekIdRangeFilter.minValue || mainTimePeriod.startWeek;
  }
  return {
    exportComparisonFilters,
    entityComparisonFilters,
    exportAggregationConditions,
    entityGridAggregationConditions
  };
}

/**
 * Constructs base conditions using the conditions constructed from filters and other global state such as the list of
 * subscribed categories for a user.
 */
export function buildDefaultConditions(conditions: Conditions, queryParams: { [key: string]: any }): Conditions {
  const clonedConditions = _cloneDeep(conditions || {});

  if (!clonedConditions.termFilters) {
    clonedConditions.termFilters = [];
  }

  if (queryParams && queryParams.ftype !== undefined) {
    clonedConditions.termFilters.push({
      fieldName: queryParams.ftype,
      values: [queryParams.fvalue]
    });
  }

  return clonedConditions;
}

export function buildEntityConditions(
  _app: ReduxStore['app'],
  _categories: ReduxStore['categories'],
  defaultConditions: Conditions,
  entity: any
): Conditions {
  const entityConditions = _cloneDeep(defaultConditions);
  if (!entityConditions.termFilters) {
    entityConditions.termFilters = [];
  }
  const excludedTermFilters: string[] = [];

  // Previously, we were not allowing
  if (['brand', 'company'].includes(entity.type)) {
    excludedTermFilters.push('brandId');
  }

  return {
    ...entityConditions,
    termFilters: entityConditions.termFilters.filter((val) => !excludedTermFilters.includes(val.fieldName))
  };
}

/**
 * If a filter with a `fieldName` that's the same as the `fieldName` of the provided `filter` argument exists on the
 * provided `entityConditions`, the existing filter will be removed and `filter` added in its place.  If no existing
 * filter exists with that field name, `field` will simply be added on to the end of the filter list.
 */

export function buildSubCategoriesSiblingConditions(entityConditions: Conditions, filters: { [key: string]: any }) {
  const subcategoriesParentIds =
    filters && filters.subcategory && filters.subcategory.length
      ? filters.subcategory.map((subcat: any) => subcat.parentCategoryId)
      : [];

  if (entityConditions.termFilters) {
    entityConditions.termFilters.forEach((termFilter) => {
      if (termFilter.fieldName === 'subCategoryId') {
        termFilter.fieldName = 'categoryId';
        termFilter.values = subcategoriesParentIds;
      }
    });
  }

  return entityConditions;
}

/**
 * Constructs conditions for the main entity.  This constructs the set of conditions that are sent along with API
 * requests used to fetch data about the slice of the entity hierarchy we're currently looking at.
 */
export function buildMainEntityConditions(
  entityConditions: Conditions,
  mainEntity: any,
  app: Pick<ReduxStore['app'], 'name'>,
  retailer: Pick<ReduxStore['retailer'], 'id'>
) {
  let mainEntityConditions: Conditions = _cloneDeep(entityConditions || { termFilters: [], rangeFilters: [] });
  if (['segment', 'searchtermlist'].includes(mainEntity.type) && app.name !== 'omni') {
    mainEntityConditions = {
      ...mapQueryToConditions(JSON.parse(mainEntity.query), retailer),
      // We retain nested conditions from the existing entity conditions so that business unit filters are still applied
      nestedFilterConditions: mainEntityConditions.nestedFilterConditions
    };
  } else if (mainEntity.type === 'businessunit') {
    mainEntityConditions = mergeConditions(mainEntity.conditions, {
      // We retain nested conditions from the existing entity conditions so that business unit filters are still applied
      nestedFilterConditions: mainEntityConditions.nestedFilterConditions
    });
  }

  if (!mainEntityConditions.termFilters) {
    mainEntityConditions.termFilters = [];
  }

  if (mainEntity.type === 'company') {
    mainEntityConditions.termFilters.push({
      fieldName: 'brandId',
      condition: 'should',
      values: mainEntity.brandIds
    });
  }

  if (!['segment', 'searchtermlist', 'businessunit', 'client', 'company'].includes(mainEntity.type)) {
    const keyFieldName = _get(ENTITIES[app.name][mainEntity.type], 'keyFieldName');
    if (keyFieldName && `${mainEntity.id}` !== '0') {
      addOrReplaceFilterInEntityConditions(mainEntityConditions, 'termFilters', {
        fieldName: keyFieldName,
        condition: 'should',
        values: [mainEntity.id]
      });
    }
  }

  // Ad portfolios and ad entities consist of multiple ad campaigns, and the results of the query are equivalent to the
  // union of the results for all of the child ad campaigns.
  if (['adPortfolio', 'adEntity', 'portfolio', 'entity'].includes(mainEntity.type)) {
    // Portfolio + Ad Entity entities have a different schema between ad manager/ad builder and Atlas/Beacon.  They are
    // fetched from different sources and contain different data.
    //
    // Beacon+Atlas don't support grouping by/filtering some of these entity types, but ad manager does.  In addition,
    // ad manager doesn't provide a list of campaigns that exist within portfolios/entities.  So, the logic for
    // constructing these conditions is different depending on the application.
    if (app.name === 'advertising') {
      const idFieldName = (
        {
          adPortfolio: 'portfolioId',
          portfolio: 'portfolioId',
          adEntity: 'entityId',
          entity: 'entityId'
        } as { [key: string]: string }
      )[mainEntity.type];

      if (!mainEntity.id) {
        error(`Can't create main entity conditions for entity of type ${mainEntity.type} since it has no id field`);
      } else {
        addOrReplaceFilterInEntityConditions(mainEntityConditions, 'termFilters', {
          fieldName: idFieldName,
          condition: 'should',
          values: [mainEntity.id || '---']
        });
      }
    } else if (!_isArray(mainEntity.campaignIds)) {
      warn(`Missing or invalid \`campaignIds\` field for \`${mainEntity.type}\` entity`);
    } else {
      addOrReplaceFilterInEntityConditions(mainEntityConditions, 'termFilters', {
        fieldName: 'campaignId',
        condition: 'should',
        values: mainEntity.campaignIds
      });
    }
  } else if (mainEntity.type === 'subCategory' && !_isNil(mainEntity.parentCategoryId)) {
    mainEntityConditions.termFilters = [
      ...(mainEntityConditions.termFilters || []),
      {
        fieldName: 'categoryId',
        condition: 'should',
        values: mainEntity.categoryIds
      }
    ];
  } else if (['brand', 'product'].includes(mainEntity.type) && Array.isArray(mainEntity.categoryIds)) {
    mainEntityConditions.termFilters = [...(mainEntityConditions.termFilters || [])];
  }

  addOrReplaceFilterInEntityConditions(mainEntityConditions, 'termFilters', {
    fieldName: 'retailerId',
    condition: 'should',
    values: [retailer.id]
  });

  if (app.name === AppName.Advertising) {
    mainEntityConditions.termFilters = mainEntityConditions.termFilters.filter((x) => x.fieldName !== 'categoryId');
  }

  return mainEntityConditions;
}

export function buildComparisonEntityConditions(
  entityConditions: Conditions,
  comparisonEntity: any,
  app: ReduxStore['app'],
  retailer: ReduxStore['retailer'],
  isMetric: boolean
) {
  let comparisonEntityConditions: Conditions = {
    termFilters: [...(entityConditions.termFilters || [])],
    rangeFilters: []
  };

  if (['segment', 'searchtermlist'].includes(comparisonEntity.type)) {
    comparisonEntityConditions = mapQueryToConditions(JSON.parse(comparisonEntity.query), retailer);
  } else if (comparisonEntity.type === 'brand') {
    comparisonEntityConditions = _cloneDeep(entityConditions);
  } else if (comparisonEntity.type === 'businessunit') {
    comparisonEntityConditions = mergeConditions(comparisonEntity.conditions, {
      // We retain nested conditions from the existing entity conditions so that business unit filters are still applied
      nestedFilterConditions: entityConditions.nestedFilterConditions
    });
  }

  if (entityConditions.termFilters) {
    const salesPlatformFilter = entityConditions.termFilters.filter(propEq('fieldName', 'salesPlatform'));
    if (!_isEmpty(salesPlatformFilter)) {
      comparisonEntityConditions.termFilters = [
        ...(comparisonEntityConditions.termFilters || []),
        ...salesPlatformFilter
      ];
    }
  }

  if (
    ['segment', 'searchtermlist', 'businessunit', 'company'].every(ne(comparisonEntity.type)) &&
    (app.name !== 'advertising' || (!isMetric && app.name === 'advertising'))
  ) {
    if (ENTITIES[app.name][comparisonEntity.type] && ENTITIES[app.name][comparisonEntity.type].keyFieldName) {
      addOrReplaceFilterInEntityConditions(comparisonEntityConditions, 'termFilters', {
        fieldName: ENTITIES[app.name][comparisonEntity.type].keyFieldName,
        condition: 'should',
        values: [comparisonEntity.id]
      });
    }
  }

  if (isMetric || comparisonEntity.type === 'retailer') {
    return comparisonEntityConditions;
  }

  if (comparisonEntity.type !== 'retailer') {
    addOrReplaceFilterInEntityConditions(comparisonEntityConditions, 'termFilters', {
      fieldName: 'retailerId',
      condition: 'should',
      values: [retailer.id]
    });
  }

  if (comparisonEntity.type !== 'category' && comparisonEntity.categoryId) {
    addOrReplaceFilterInEntityConditions(comparisonEntityConditions, 'termFilters', {
      fieldName: 'categoryId',
      condition: 'should',
      values: [comparisonEntity.categoryId]
    });
  } else if (comparisonEntity.categoryIds) {
    addOrReplaceFilterInEntityConditions(comparisonEntityConditions, 'termFilters', {
      fieldName: 'categoryId',
      condition: 'should',
      values: comparisonEntity.categoryIds
    });
  }

  if (app.name === AppName.Advertising) {
    comparisonEntityConditions.termFilters = (comparisonEntityConditions.termFilters || []).filter(
      (x) => x.fieldName !== 'categoryId' && x.fieldName !== 'brandId'
    );
  }

  return comparisonEntityConditions;
}

export const buildMarketShareConditions = (
  filters: { [key: string]: any },
  defaultConditions: Conditions,
  entityConditions: Conditions,
  entity: any,
  retailer: ReduxStore['retailer']
) => {
  let termFilters = (entityConditions.termFilters || []).filter(propEq('fieldName', 'categoryId'));
  let rangeFilters: RangeFilter[] = [];
  if (filters.platform) {
    termFilters = [
      ...termFilters,
      ...(defaultConditions.termFilters || []).filter(({ fieldName }) => fieldName !== 'categoryId')
    ];
  }

  if (filters.segment) {
    ({ termFilters = [] } = defaultConditions);
  }

  if (['segment', 'searchtermlist', 'businessunit'].includes(entity.type)) {
    const segmentConditions = mapQueryToConditions(JSON.parse(entity.query), retailer);
    termFilters = [...(segmentConditions.termFilters || [])];
    rangeFilters = [...(segmentConditions.rangeFilters || [])];
  }

  // eslint-disable-next-line eqeqeq
  if (entity.type === 'subcategory' && entity.id != 0) {
    termFilters.push({
      fieldName: 'subCategoryId',
      values: [entity.subCategoryId]
    });
    // eslint-disable-next-line eqeqeq
  } else if (entity.type === 'category' && entity.id != 0) {
    termFilters = [
      ...termFilters.filter(not(propEq('fieldName', 'categoryId'))),
      { fieldName: 'categoryId', values: [entity.id] }
    ];
  }

  return {
    termFilters: [...termFilters, ...(entityConditions.termFilters || []).filter(propEq('fieldName', 'subCategoryId'))],
    rangeFilters,
    nestedFilterConditions: entityConditions.nestedFilterConditions
  };
};

export function buildEntityMarketShareConditions({
  app,
  filters,
  entity,
  categories,
  subCategories,
  segments,
  retailer
}: Pick<ReduxStore, 'app' | 'categories' | 'subCategories' | 'segments' | 'retailer'> & {
  entity: any;
  filters: { [key: string]: any };
}) {
  const allSubscribedCategoryIds = categories.map((category) => `${category.categoryId}`);

  const { conditions: filterConditions } = combineFilterConditions(
    app,
    filters,
    categories,
    subCategories,
    segments,
    retailer
  );

  const entityConditions = (() => {
    switch (entity.type) {
      case 'client':
      case 'company':
      case 'organization': {
        return {
          termFilters: [
            {
              fieldName: 'categoryId',
              condition: 'should' as const,
              values: allSubscribedCategoryIds
            }
          ],
          rangeFilters: []
        };
      }
      case 'category': {
        // If we're on the "all categories" page, no filtering should be done.
        if (entity.id === '0' || entity.id === 0) {
          return { termFilters: [], rangeFilters: [] };
        }

        return {
          termFilters: [
            {
              fieldName: 'categoryId',
              condition: 'should' as const,
              values: [entity.categoryId.toString()]
            }
          ],
          rangeFilters: []
        };
      }
      case 'subcategory': {
        return {
          termFilters: filterNils([
            entity.parentCategoryId
              ? {
                  fieldName: 'categoryId',
                  condition: 'should' as const,
                  values: [entity.parentCategoryId]
                }
              : null,
            {
              fieldName: 'subCategoryId',
              condition: 'should' as const,
              values: [entity.subCategoryId.toString()]
            }
          ]),
          rangeFilters: []
        };
      }
      case 'brand': {
        let categoryIdsForBrand: (string | number)[] = entity.categoryIds;
        if (!categoryIdsForBrand) {
          error(
            `No \`categoryIds\` found for entity of type brand with id ${entity.id}; defaulting to all subscribed categories`
          );
          categoryIdsForBrand = allSubscribedCategoryIds;
        }

        return {
          termFilters: [
            {
              fieldName: 'categoryId',
              condition: 'should' as const,
              values: categoryIdsForBrand.map((id) => id.toString())
            }
          ],
          rangeFilters: []
        };
      }
      case 'product': {
        let productCategoryId = entity.categoryId;
        if (_isNil(productCategoryId)) {
          error(`Nil \`categoryId\` for product entity with id ${entity.id}`);
          productCategoryId = 0;
        }

        return {
          termFilters: [
            {
              fieldName: 'categoryId',
              condition: 'should' as const,
              values: [productCategoryId.toString()]
            }
          ]
        };
      }
      case 'segment':
      case 'businessunit':
      case 'businessUnit':
      case 'searchtermlist': {
        if (!segments.savedSearchesById) {
          throw panic('`buildEntityMarketShareConditions` called with nil `segments.savedSearchesById` argument');
        }

        const savedSearch = segments.savedSearchesById.get(entity.id) as SavedSearch & {
          categoryIds: number[];
          conditions: Conditions;
        };
        if (!savedSearch) {
          throw panic(`\`savedSearchesById\` did not have an entry for entity with id "${entity.id}"`);
        } else if (!savedSearch.conditions) {
          throw panic(`Saved search did not have a \`conditions\` attribute`, { savedSearch });
        }

        return savedSearch.conditions;
      }
      default: {
        error(
          `Unhandled entity type of ${entity.type} when trying to build marketshare entity conditions; falling back to all subscribed categories.`
        );
        return {
          termFilters: [
            {
              fieldName: 'categoryId',
              condition: 'should' as const,
              values: allSubscribedCategoryIds
            }
          ],
          rangeFilters: []
        };
      }
    }
  })();

  return mergeConditions(entityConditions, filterConditions);
}

/**
 * Returns entity conditions for the organization of the current user.
 */
const buildOrganizationEntityConditions = ({
  user,
  categories
}: Pick<ReduxStore, 'user' | 'categories'>): Conditions => {
  const allOwnedBrandIds = JSON.parse(user.config.BrandsOwned);
  const allSubscribedCategoryIds = categories.map((category) => `${category.categoryId}`);

  return {
    termFilters: [
      {
        fieldName: 'categoryId',
        condition: 'should',
        values: allSubscribedCategoryIds
      },
      {
        fieldName: 'brandId',
        condition: 'should',
        values: allOwnedBrandIds
      }
    ],
    rangeFilters: [],
    nestedFilterConditions: []
  };
};

/**
 * This function returns the `Conditions` that should be used to metrics for the numerator of the market share computation.
 * If the conditions are the same as the normal `mainEntityMetrics` that are fetched meaning that the data from the normal
 * fetch for entity metrics can be re-used, this function returns `null` indicating that no additional API request is necessary.
 */
export const buildEntityMarketShareNumeratorConditions = ({
  entity,
  app,
  filters,
  categories,
  subCategories,
  segments,
  retailer,
  user,
  mainEntityConditions
}: Pick<ReduxStore, 'app' | 'filters' | 'categories' | 'subCategories' | 'segments' | 'retailer' | 'user'> & {
  mainEntityConditions: Conditions;
  entity: Entity;
}) => {
  if (['client', 'company', 'organization', 'brand', 'product'].includes(entity.type)) {
    return null;
  }

  const filterConditions = combineFilterConditions(app, filters, categories, subCategories, segments, retailer);
  const organizationEntityConditions = buildOrganizationEntityConditions({ user, categories });

  return mergeConditions(
    mergeConditions(mainEntityConditions, organizationEntityConditions),
    filterConditions.conditions
  );
};

export const buildRelatedEntityMarketShareConditions = (
  filters: { [key: string]: any },
  defaultConditions: Conditions,
  entityConditions: Conditions,
  mainEntity: any,
  app: ReduxStore['app'],
  retailer: ReduxStore['retailer']
) =>
  ['segment', 'searchtermlist', 'businessunit', 'company'].includes(mainEntity.type)
    ? buildMainEntityConditions(entityConditions, mainEntity, app, retailer)
    : buildMarketShareConditions(filters, defaultConditions, entityConditions, mainEntity, retailer);

export function buildAggregationConditions(
  app: ReduxStore['app'],
  indexName: string,
  retailer: ReduxStore['retailer'],
  allWeekIdsByRetailerId: ReduxStore['allWeekIdsByRetailerId'],
  mainTimePeriod: ReduxStore['mainTimePeriod'],
  comparisonTimePeriod: ReduxStore['comparisonTimePeriod'],
  defaultAggregationConditions: any,
  weekIdField: any,
  includeAllAvailableTimePeriodsInComparison: boolean = true,
  limitMainWeekIdRangeToBeExact: boolean = false,
  includePreviousYear: boolean = false,
  startWeekPreviousYear: number = 0
) {
  const weekIds = allWeekIdsByRetailerId[parseInt(retailer.id, 10)];
  let aggregationConditions = _cloneDeep(defaultAggregationConditions || { termFilters: [], rangeFilters: [] });

  if (!aggregationConditions.termFilters) {
    aggregationConditions.termFilters = [];
  } else if (!aggregationConditions.rangeFilters) {
    aggregationConditions.rangeFilters = [];
  }

  aggregationConditions.termFilters.push({
    fieldName: 'retailerId',
    values: [`${retailer.id}`]
  });
  aggregationConditions = {
    ...aggregationConditions,
    rangeFilters: aggregationConditions.rangeFilters.filter(
      (filter: any) => !INDEX_FIELDS.isTimeSeriesField(filter.fieldName)
    )
  };
  if (limitMainWeekIdRangeToBeExact) {
    const weekIdRangeFilter = {
      fieldName: weekIdField.name,
      minValue: mainTimePeriod.startWeek,
      maxValue: mainTimePeriod.endWeek
    };

    // if the index supports dayId filter, then we push the dayId range filter
    if (INDEX_FIELDS.hasField(app.name, indexName, 'dayId')) {
      aggregationConditions.rangeFilters.push({
        fieldName: 'dayId',
        minValue: mainTimePeriod.startDayId,
        maxValue: mainTimePeriod.endDayId
      });
    } else {
      aggregationConditions.rangeFilters.push(weekIdRangeFilter);
    }
  } else if (comparisonTimePeriod) {
    const weekMinValue = includePreviousYear ? startWeekPreviousYear : comparisonTimePeriod.startWeek;
    const weekIdRangeFilter = {
      fieldName: weekIdField.name,
      minValue: Math.min(weekMinValue, getFirstWeekOfYear(mainTimePeriod.startWeek - 100)),
      maxValue: getLastWeek(weekIds)
    };

    // if the index supports dayId filter, then we push the dayId range filter
    if (INDEX_FIELDS.hasField(app.name, indexName, 'dayId')) {
      aggregationConditions.rangeFilters.push({
        fieldName: 'dayId',
        minValue: Math.min(comparisonTimePeriod.startDayId, mainTimePeriod.startDayId - 10000),
        maxValue: mainTimePeriod.endDayId
      });
    } else {
      aggregationConditions.rangeFilters.push(weekIdRangeFilter);
    }
  }
  const marketshareAggregationConditions = _cloneDeep(
    defaultAggregationConditions || { termFilters: [], rangeFilters: [] }
  );
  if (!marketshareAggregationConditions.termFilters) {
    marketshareAggregationConditions.termFilters = [];
  } else if (!marketshareAggregationConditions.rangeFilters) {
    marketshareAggregationConditions.rangeFilters = [];
  }
  marketshareAggregationConditions.termFilters.push({
    fieldName: 'retailerId',
    values: [`${retailer.id}`]
  });

  marketshareAggregationConditions.rangeFilters = marketshareAggregationConditions.rangeFilters.filter(
    (filter: any) => !INDEX_FIELDS.isTimeSeriesField(filter.fieldName)
  );

  if (INDEX_FIELDS.hasField(app.name, indexName, 'dayId')) {
    marketshareAggregationConditions.rangeFilters.push({
      fieldName: 'dayId',
      minValue: mainTimePeriod.startDayId,
      maxValue: mainTimePeriod.endDayId
    });
  } else {
    marketshareAggregationConditions.rangeFilters.push({
      fieldName: weekIdField.name,
      minValue: mainTimePeriod.startWeek,
      maxValue: mainTimePeriod.endWeek
    });
  }
  const comparisonRangeFilters = [];
  if (
    comparisonTimePeriod &&
    (comparisonTimePeriod.startWeek !== mainTimePeriod.startWeek ||
      comparisonTimePeriod.endWeek !== mainTimePeriod.endWeek)
  ) {
    // if the index supports dayId filter, then we push the dayId range filter
    if (INDEX_FIELDS.hasField(app.name, indexName, 'dayId')) {
      comparisonRangeFilters.push({
        fieldName: 'dayId',
        minValue: comparisonTimePeriod.startDayId,
        maxValue: comparisonTimePeriod.endDayId
      });
    } else {
      comparisonRangeFilters.push({
        fieldName: weekIdField.name,
        minValue: comparisonTimePeriod.startWeek,
        maxValue: comparisonTimePeriod.endWeek
      });
    }
  }
  if (includeAllAvailableTimePeriodsInComparison) {
    mainTimePeriod.availableMainTimePeriods.forEach(({ startWeek, endWeek, id }) => {
      if (
        !startWeek ||
        id === mainTimePeriod.id ||
        (startWeek === comparisonTimePeriod.startWeek && endWeek === comparisonTimePeriod.endWeek)
      ) {
        return;
      }

      comparisonRangeFilters.push({
        fieldName: weekIdField.name,
        minValue: startWeek,
        maxValue: endWeek
      });
    });
  }

  if (includeAllAvailableTimePeriodsInComparison && includePreviousYear) {
    mainTimePeriod.availableMainTimePeriods.forEach(({ startWeek, endWeek, value }) => {
      if (
        !startWeek ||
        !value ||
        (startWeek === comparisonTimePeriod.startWeek && endWeek === comparisonTimePeriod.endWeek)
      ) {
        return;
      }

      const endWeekPrev = getPreviousYearWeekId(endWeek);
      const startWeekPrev = getPreviousWeekId(endWeekPrev, value - 1);

      comparisonRangeFilters.push({
        fieldName: weekIdField.name,
        minValue: startWeekPrev,
        maxValue: endWeekPrev
      });
    });
  }
  return { aggregationConditions, marketshareAggregationConditions, comparisonRangeFilters };
}
