import React from 'react';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { ColumnChartContainer } from 'src/components/Charts';
import { Widget } from 'src/types/application/widgetTypes';
import { MainEntityMetrics } from 'src/types/application/types';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { AdvertisingOverlapMetric } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import colors from 'src/utils/colors';
import { RowDatum } from 'src/types/store/storeTypes';
import { ADVERTISING_OVERLAP_EVENT } from './constants';

/**
 * Get the chart props with the correct y axis label
 * @param metric Selected metric in the dropdown
 * @returns Props for the advertising overlap bar chart
 */
const getChartProps = (metric: AdvertisingOverlapMetric): Highcharts.Options => {
  const buttons = [
    [
      {
        displayName: 'Shopper Conversion Rate',
        eventId: AdvertisingOverlapMetric.ConversionRate,
        eventName: ADVERTISING_OVERLAP_EVENT,
        isSelected: metric === AdvertisingOverlapMetric.ConversionRate
      },
      {
        displayName: 'Conversions',
        eventId: AdvertisingOverlapMetric.Conversions,
        eventName: ADVERTISING_OVERLAP_EVENT,
        isSelected: metric === AdvertisingOverlapMetric.Conversions
      },
      {
        displayName: '% of Conversions',
        eventId: AdvertisingOverlapMetric.PercentOfConversions,
        eventName: ADVERTISING_OVERLAP_EVENT,
        isSelected: metric === AdvertisingOverlapMetric.PercentOfConversions
      },
      {
        displayName: 'Unique Shoppers',
        eventId: AdvertisingOverlapMetric.UniqueShoppers,
        eventName: ADVERTISING_OVERLAP_EVENT,
        isSelected: metric === AdvertisingOverlapMetric.UniqueShoppers
      },
      {
        displayName: '% of Unique Shoppers',
        eventId: AdvertisingOverlapMetric.PercentOfUniqueShoppers,
        eventName: ADVERTISING_OVERLAP_EVENT,
        isSelected: metric === AdvertisingOverlapMetric.PercentOfUniqueShoppers
      }
    ],
    [
      {
        isSelected: true,
        displayName: 'Ad Exposure'
      }
    ]
  ];
  return {
    chart: {
      type: 'column',
      marginTop: 64,
      spacingLeft: 8
    },
    title: {
      y: -95
    },
    exporting: {
      enabled: false,
      buttons
    },
    legend: {
      enabled: false
    },
    xAxis: [
      {
        title: {
          text: 'Number of Ad Types Exposed',
          style: {
            fontSize: '15px',
            color: colors.darkBlue,
            fontFamily: 'Roboto'
          },
          margin: 20
        },
        categories: ['1', '2', '3'],
        labels: {
          style: {
            'font-size': '15px',
            color: colors.darkBlue
          }
        }
      }
    ],
    yAxis: [
      {
        labels: {
          enabled: false
        }
      }
    ],
    plotOptions: {
      barWidth: 70,
      column: {
        dataLabels: {
          align: 'center',
          y: -10,
          style: {
            fontSize: '14px',
            color: colors.darkBlue,
            fontWeight: '500'
          }
        }
      }
    }
  };
};

interface AdvertisingOverlapBarChartProps {
  widget: Widget;
  metric: AdvertisingOverlapMetric;
  isLoading: boolean;
  data: RowDatum[];
}

/**
 * Displays bar chart for advertising overlap visualization
 */
const AdvertisingOverlapBarChart = ({ widget, metric, isLoading, data }: AdvertisingOverlapBarChartProps) => {
  const { mainTimePeriod, retailer } = useSelector((state: ReduxStore) => state);
  const formatAsPercentage = [
    AdvertisingOverlapMetric.ConversionRate,
    AdvertisingOverlapMetric.PercentOfConversions,
    AdvertisingOverlapMetric.PercentOfUniqueShoppers
  ].includes(metric);

  const mainEntityMetrics: MainEntityMetrics = {
    data,
    metricType: formatAsPercentage ? METRICTYPE.PERCENT : METRICTYPE.VOLUME,
    groupByField: {}
  };

  return isLoading ? (
    <GenericChartLoading />
  ) : (
    <ColumnChartContainer
      chartDisplayTimePeriod={mainTimePeriod}
      mainEntityMetrics={mainEntityMetrics}
      retailer={retailer}
      widget={{
        ...widget,
        view: {
          ...widget.view,
          chartPropsOverride: getChartProps(metric)
        }
      }}
      numberFormatOptions={{
        decimalPlaces: 2,
        showFullValue: formatAsPercentage
      }}
    />
  );
};

export default AdvertisingOverlapBarChart;
