import React, { useEffect, useState } from 'react';
import CategoryCard, { Category } from 'src/components/BeaconRedesignComponents/CategoryCard/CategoryCard';
import { useAppSelector } from 'src/utils/Hooks';
import { useTableData } from 'src/serverProxy/useTableData';
import { Text, useStacklineTheme } from '@stackline/ui';
import Pagination from 'src/components/BeaconRedesignComponents/Pagination/Pagination';
import { calculatePercentChange } from 'src/utils/app';
import SegmentNoDataPlaceholder from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Segments/SegmentNoDataPlaceholder';
import { SearchTextField } from 'src/components/BeaconRedesignComponents/SearchInput/SearchInputBar';
import TimePeriodDropdown from 'src/components/BeaconRedesignComponents/common/TimePeriodDropdown';
import ComparisonPeriodDropdown from 'src/components/BeaconRedesignComponents/common/ComparisonPeriodDropdown';
import { Box } from '@mui/material';
import { shouldHidePagination } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/utils';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

const FETCH_CHUNK_SIZE = 150;
const GRID_ITEMS_PER_PAGE = 20;
/**
 * Category Page Layout that can be access from main Navigation (top nav)
 *
 */

const CategorySummary = () => {
  const theme = useStacklineTheme();
  const { startWeek: compareStartWeek, endWeek: compareEndWeek } = useAppSelector(
    (state) => state.comparisonTimePeriod
  );
  const categories = useAppSelector((state) => state.categories);
  const [initialCategories, setInitialCategories] = useState(categories);
  const [searchInput, setSearchInput] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(GRID_ITEMS_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [isViewAll, setIsViewAll] = useState(false);
  const { isLoading: metricDataLoading, data: categoryData } = useTableData({
    fields: [{ name: 'retailSales', canBeExported: false }],
    groupByFieldName: 'categoryId',
    indexName: 'sales',
    requestId: 'categoryRetailSales',
    itemsPerPage: FETCH_CHUNK_SIZE,
    pageNumber: 1,
    sortFieldName: 'retailSales'
  });

  useEffect(() => {
    if (categoryData && !metricDataLoading) {
      const categoryRetailSalesMap = categoryData.reduce((map, datum) => {
        const { categoryId } = datum.metadata;
        const currentValue = datum.retailSales_sum_value;
        const changeAmount = datum[`retailSales_sum_value_${compareStartWeek}_${compareEndWeek}`];
        const percentageChange = calculatePercentChange(currentValue, changeAmount);

        return {
          ...map,
          [categoryId]: [datum.retailSales_sum_value, percentageChange]
        };
      }, {});

      // Filter out categories with id === 0 or no sales data
      const advancedCategories = categories
        .filter((category) => category.id !== 0 && categoryRetailSalesMap[category.id])
        .map((category) => {
          const [currentRetailSales, retailSalesChange] = categoryRetailSalesMap[category.id];
          return { ...category, categoryType: category.type, currentRetailSales, retailSalesChange };
        });

      // sort by retail sales
      advancedCategories.sort((a, b) => b.currentRetailSales - a.currentRetailSales);
      setInitialCategories(advancedCategories);
    }
  }, [categories, categoryData, compareEndWeek, compareStartWeek, metricDataLoading]);

  const filteredCategories = initialCategories.filter((category) => {
    // "All categories" won't have its name
    return category.name && category.name.toLowerCase().includes(searchInput.toLowerCase());
  });

  // prevent showing 0 when there is no search list. the total pagination number should still show 1
  const totalPage = Math.ceil(filteredCategories.length / itemsPerPage) || 1;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const categoriesToDisplay = filteredCategories.slice(startIndex, endIndex);

  // Handle next page and previous page clicks
  const handleNextPage = () => {
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleViewAll = () => {
    setIsViewAll(true);
    setItemsPerPage(initialCategories.length);
    setCurrentPage(1);
  };

  return (
    <>
      <BeaconPageContainer sx={{ width: '100%', marginTop: '0px' }}>
        <div style={{ width: '100%', position: 'relative' }}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Text variant="h2">Categories</Text>
            <Flex>
              <Flex paddingX={theme.spacing.md}>
                <TimePeriodDropdown />
              </Flex>
              <ComparisonPeriodDropdown />
            </Flex>
          </Box>

          <Flex paddingY={theme.spacing.lg}>
            <SearchTextField
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search categories"
            />
          </Flex>
          {categoriesToDisplay.length === 0 ? (
            <Flex justifyContent="center" marginTop={theme.spacing.lg}>
              <SegmentNoDataPlaceholder
                title="No search results found"
                subtitle="There is no data available for the search term."
                maxWidth={238}
              />
            </Flex>
          ) : (
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '24px' }}>
              {categoriesToDisplay.map((category) => {
                return <CategoryCard key={category.id} val={category as Category} isLoading={metricDataLoading} />;
              })}
            </div>
          )}
          <div style={{ marginTop: '20px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                zIndex: 1,
                position: 'relative'
              }}
            >
              {/* placeholder for the space between */}
              <div>
                {searchInput.length === 0 && !isViewAll && (
                  <div role="button" onClick={handleViewAll}>
                    <Text variant="subtitle3">View All Categories ({initialCategories.length})</Text>
                  </div>
                )}
              </div>
              {shouldHidePagination({
                page: currentPage,
                pageSize: GRID_ITEMS_PER_PAGE,
                rowCount: (categoriesToDisplay || []).length
              }) ? null : (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPage}
                  onClickNext={handleNextPage}
                  onClickPrevious={handlePreviousPage}
                />
              )}
            </div>
          </div>
        </div>
      </BeaconPageContainer>
    </>
  );
};

export default CategorySummary;
