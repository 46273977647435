import _flatten from 'lodash/flatten';
import _partition from 'lodash/partition';
import _prop from 'lodash/property';
import _sortBy from 'lodash/sortBy';
import queryString from 'qs';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { SlDropdownMenuOption } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlMenu';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import { updateQueryParams } from 'src/store/modules/app/operations';
import { updateAllTimePeriods } from 'src/store/modules/main-time-period/operations';
import { getInitialState as getDefaultTimePeriods } from 'src/store/modules/main-time-period/reducers';
import { QueryResponse } from 'src/types/application/types';
import { AvailableRetailer } from 'src/types/store/storeTypes';
import { useAppSelector } from 'src/utils/Hooks';
import { getAppStage, shouldShowNewBeacon, usesCustomTimePeriods, usesSecondaryRetailers } from 'src/utils/app';
import { computeComparisonTimePeriod, computeMainTimePeriod } from 'src/utils/dateformatting';
import _get from 'lodash/get';
import { BEACON_SUBTABS, BEACON_TABS } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';

const SelectRetailerDropdown = () => {
  const dispatch = useDispatch();
  const { location } = window;
  const app = useAppSelector((state) => state.app);
  const user = useAppSelector((state) => state.user);
  const retailer = useAppSelector((state) => state.retailer);
  const allWeekIdsByRetailerId = useAppSelector((state) => state.allWeekIdsByRetailerId);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const comparisonTimePeriod = useAppSelector((state) => state.comparisonTimePeriod);
  const { availableRetailers } = retailer;

  const isBeaconProRetailerEnabled = (id: string) => {
    const beaconProEnabledInFoundry =
      _get(user, ['config', 'subscriptionsByRetailers', id, 'BeaconProSubscription']) === 'true';

    return beaconProEnabledInFoundry;
  };

  const isRetailerActive = useCallback(
    (r: AvailableRetailer): boolean => {
      if (user.config.secondaryRetailerIds && usesSecondaryRetailers()) {
        return (
          user.config.secondaryRetailerIds.includes(parseInt(r.id, 10)) &&
          user.config.allWeekIdsByRetailerId[+r.id] &&
          r.supportedAppNames.includes(app.name)
        );
      }

      return (
        user.config.allRetailerIds.includes(parseInt(r.id, 10)) &&
        user.config.allWeekIdsByRetailerId[+r.id] &&
        r.supportedAppNames.includes(app.name)
      );
    },
    [app.name, user.config.allRetailerIds, user.config.allWeekIdsByRetailerId, user.config.secondaryRetailerIds]
  );

  const availableRetailersWithActiveProp = useMemo(() => {
    return availableRetailers.map((val) => ({
      ...val,
      active: isRetailerActive(val)
    }));
  }, [availableRetailers, isRetailerActive]);

  // Sort active retailers on top of inactive retailers with each (active and inactive) sorted by display name
  const orderedRetailers = useMemo(() => {
    return _flatten(
      _partition(availableRetailersWithActiveProp, _prop('active')).map((coll) => _sortBy(coll, 'displayName'))
    );
  }, [availableRetailersWithActiveProp]);

  const handleRetailerChange = (retailerSelection: SlDropdownMenuOption) => {
    /**
     * The newly selected retailer ID
     */
    const retailerId = retailerSelection.id;
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const { availableMainTimePeriods } = usesCustomTimePeriods() ? getDefaultTimePeriods() : mainTimePeriod;
    const { availableComparisonTimePeriods } = comparisonTimePeriod;
    const allWeekIds = allWeekIdsByRetailerId[retailerId];

    const comparisonTimePeriodId =
      comparisonTimePeriod && comparisonTimePeriod.id !== undefined
        ? comparisonTimePeriod.id
        : availableComparisonTimePeriods[0].id;

    // Default to first app default time period if using secondary retailers
    const mainPeriodIndex = usesCustomTimePeriods()
      ? availableMainTimePeriods.findIndex((period) => period.default)
      : mainTimePeriod.availableMainTimePeriods.findIndex((x) => x.id === mainTimePeriod.id);
    const newTimePeriod = computeMainTimePeriod(allWeekIds, availableMainTimePeriods[mainPeriodIndex], app);
    const compPeriodId = availableComparisonTimePeriods.find((x) => x.id === comparisonTimePeriodId)!.id;
    const updatedComparisonPeriod = computeComparisonTimePeriod(allWeekIds, newTimePeriod, compPeriodId);

    updatedComparisonPeriod.comparisonIndex = availableComparisonTimePeriods.findIndex(
      (x) => x.id === comparisonTimePeriodId
    );

    // If the user is on beacon pro and the retailer is not beacon pro enabled, navigate to old beacon
    if (shouldShowNewBeacon() && !isBeaconProRetailerEnabled(retailerSelection.id) && !(getAppStage() === 'dev')) {
      const { startWeek, endWeek, startDayId, endDayId } = newTimePeriod;

      const params = new URLSearchParams();
      params.set('rid', retailerId);
      params.set('pid', 'prior-year');
      params.set('sw', startWeek);
      params.set('ew', endWeek);
      params.set('sdid', startDayId);
      params.set('edid', endDayId);
      params.set('wr', 'ytd');
      params.set('tab', BEACON_TABS.SUMMARY);
      params.set('subtab', BEACON_SUBTABS.KEY_METRICS);

      return window.open(`https://beacon.stackline.com${location.pathname}?${params.toString()}`, '_blank');
    } else {
      dispatch(updateAllTimePeriods(allWeekIds, newTimePeriod, updatedComparisonPeriod));
      dispatch(updateQueryParams(app, { id: retailerId }, newTimePeriod, updatedComparisonPeriod, queryParams)).then(
        (response: QueryResponse) => {
          const { additionalParams, dropDownSelectionParams, nonPersistentParams, searchParams } = response.params;
          let customParams = queryParams.tab || queryParams.entityType ? dropDownSelectionParams : additionalParams;
          // Go back to home page if retailer changes on ad manager
          if (app.name === 'advertising') {
            location.pathname = '/overview';
            customParams = '&tab=adManager&subtab=keyMetrics';
          }
          return window.open(`${location.pathname}${searchParams}${customParams}${nonPersistentParams}`, '_self');
        }
      );
    }
    return null;
  };

  const newOptions = useMemo(
    () =>
      orderedRetailers
        .filter((val) => {
          if (retailer.id === '0') {
            return val.id === '0'; // only render the All Retailers item
          }

          return val.id !== '0'; // render all but All Retailers
        })
        .map((rt) => ({ label: rt.displayName, id: rt.id, disabled: !rt.active })),
    [orderedRetailers, retailer.id]
  );

  const handleChange = (retailerSelection: SlDropdownMenuOption) => {
    if (!retailerSelection.disabled) {
      handleRetailerChange(retailerSelection);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 30
      }}
    >
      <SlDropdownMenu
        amountOfOptionsToShow={4}
        options={newOptions}
        defaultLabel={retailer.displayName}
        selectedId={retailer.id}
        onChange={(r) => handleChange(r)}
        buttonVariant="titleSmall"
        iconStyle={{ marginLeft: '8px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        // Allows for truncation of retailer names
        menuStyles={{ width: '160px' }}
      />
    </div>
  );
};

export default SelectRetailerDropdown;
