import axios from 'axios';
import { OmniBaseRequestBody } from 'src/components/Omni/omniRequestUtils';
import { OmniMainEntityMetricsDonut } from 'src/components/EntityPage/Content/OmniContentAccuracy';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import colors from 'src/utils/colors';

const OmniDonutGroupEndPoint = 'omni/content/getAvgContentAccuracy';

const metricConfigOrder = [
  'titleAccuracy_computed',
  'descriptionAccuracy_computed',
  'bulletsAccuracy_computed',
  'imageUrlsAccuracy_computed',
  'videoUrlsAccuracy_computed'
];

const metricConfig: {
  [key: string]: {
    name: string;
    fieldId: string;
    color: string;
  };
} = {
  titleAccuracy_computed: {
    name: 'Title',
    fieldId: 'titleAccuracy',
    color: colors.titleScore
  },
  descriptionAccuracy_computed: {
    name: 'Description',
    fieldId: 'descriptionAccuracy',
    color: '#ED792F'
  },
  bulletsAccuracy_computed: {
    name: 'Bullets',
    fieldId: 'bulletsAccuracy',
    color: colors.bulletsScore
  },
  imageUrlsAccuracy_computed: {
    name: 'Images',
    fieldId: 'imageUrlsAccuracy',
    color: colors.imagesScore
  },
  videoUrlsAccuracy_computed: {
    name: 'Videos',
    fieldId: 'videoUrlsAccuracy',
    color: colors.videosScore
  }
};

const processOmniDonutGroupData = (dataFromApi: any, currencySymbol: string): OmniMainEntityMetricsDonut[] => {
  const res: OmniMainEntityMetricsDonut[] = [];
  const allData = _get(dataFromApi, 'beaconClientId.buckets', []);
  if (allData.length === 0) {
    return res;
  }
  const [dataSet] = allData;

  const keySet = Object.keys(dataSet);

  const sortedKeySet = _sortBy(keySet, (key) => {
    return metricConfigOrder.indexOf(key);
  });

  sortedKeySet.forEach((key) => {
    if (metricConfig[key]) {
      const value = _get(dataSet, `${key}.value`, 0) as number;
      const config = metricConfig[key];
      const title = value;
      const data = [
        { color: config.color, fieldId: config.fieldId, name: config.name, y: value },
        {
          color: '#f6f9fc',
          fieldId: 'Unearned',
          name: '',
          y: 1 - value
        }
      ];
      res.push({
        currencySymbol,
        title,
        titleColor: config.color,
        data
      });
    }
  });

  return res;
};

export const fetchOmniDonutGroup =
  (requestBody: OmniBaseRequestBody, currencySymbol: string = '$') =>
  async () => {
    const res = await axios.post(OmniDonutGroupEndPoint, requestBody);
    if (res && res.status === 200) {
      const { data } = res.data;
      return processOmniDonutGroupData(data, currencySymbol);
    } else {
      throw Error('Can not fetch the data');
    }
  };
