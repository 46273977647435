import React, { memo } from 'react';
import { useMetricFormatter, useQueryParamValue } from 'src/utils/Hooks';
import { SlRow } from '@stackline/ui';
import UnitsSoldPreview from 'src/components/BeaconRedesignComponents/UnitsSoldPreview/UnitsSoldPreview';
import useBeaconUnitsChange from './serverProxy/useBeaconUnitsChange';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import ChangeBarChart from 'src/components/BeaconRedesignComponents/ChangeBarChart/ChangeBarChart';
import { SlColumn } from 'src/components/BeaconRedesignComponents/Generic/SlColumn';
import { KEY_METRICS_CONTAINER_ADJUSTED_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import SmartForecastKeyMetricCard from './SmartForecastKeyMetricCard';
import { getCardTitle } from 'src/components/BeaconRedesignComponents/KeyMetricCards/SmartKeyMetricCard';
import ActiveAdjustments from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/components/ActiveAdjustments';
import { useForecastAdjustmentCount } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useForecastAdjustmentCount';
import _get from 'lodash/get';

const metricCollections = [
  [
    ['traffic', 'organicClicks'],
    ['traffic', 'otherClicks'],
    ['new-content-metrics', 'contentScore'],
    ['ratings-reviews-metrics', 'weightedRating']
  ],
  [
    ['advertising', 'clicks'],
    ['sales', 'retailPrice'],
    ['sales', 'instockRate'],
    ['buybox', 'winPercentage']
  ]
];

interface ForecastPlansKeyMetricCardsProps {
  titleOverrides?: Record<string, Record<string, string>>;
}
export default memo(function ForecastPlansKeyMetricCards({ titleOverrides }: ForecastPlansKeyMetricCardsProps) {
  const formatMetric = useMetricFormatter();
  const { getData, getMetricTotal, isLoading: unitsChangeLoading } = useBeaconUnitsChange();
  const { data, isLoading } = useForecastAdjustmentCount();

  const selectedIndex = useQueryParamValue('indexName', 'sales');
  const selectedField = useQueryParamValue('keyMetric', 'retailSales');

  return (
    <SlColumn
      style={{ transform: 'translate(-16px)', width: KEY_METRICS_CONTAINER_ADJUSTED_WIDTH }}
      spacing="mdl"
      horizontalPosition="center"
    >
      {metricCollections.map((metricCollection) => (
        <SlRow key={metricCollection[0][1]} spacing="mdl">
          {metricCollection.map(([indexName, fieldName]) => {
            const metricTotal = getMetricTotal(fieldName);
            const formattedTotal = `${metricTotal < 0 ? '-' : ''}${formatMetric(
              Math.abs(metricTotal),
              METRICTYPE.VOLUME
            )}`;

            return (
              <SmartForecastKeyMetricCard
                key={fieldName}
                fieldName={fieldName}
                indexName={indexName}
                variant="medium"
                loading={unitsChangeLoading}
                title={getCardTitle(indexName, fieldName, titleOverrides)}
              >
                <SlColumn spacing="md">
                  <UnitsSoldPreview metric={formattedTotal} />
                  <ChangeBarChart width={210} metricType={METRICTYPE.VOLUME} data={getData(fieldName)} preview />
                  {isLoading || unitsChangeLoading ? null : (
                    <ActiveAdjustments
                      hidden={_get(data, fieldName, 0) <= 0}
                      count={formatMetric(_get(data, fieldName, 0), METRICTYPE.VOLUME)}
                      selected={selectedIndex === indexName && selectedField === fieldName}
                    />
                  )}
                </SlColumn>
              </SmartForecastKeyMetricCard>
            );
          })}
        </SlRow>
      ))}
    </SlColumn>
  );
});
