import { PERCENTAGE_METRICS } from 'src/components/AdManager/Search/Models/PercentageMetrics';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import { FormData } from 'src/utils/segments';

function isRangeFilterNotEqual(
  rangeFilter1: { minValue?: number; maxValue?: number },
  rangeFilter2: { minValue?: number; maxValue?: number }
): boolean {
  return (
    `${rangeFilter1.minValue}` !== `${rangeFilter2.minValue}` ||
    `${rangeFilter1.maxValue}` !== `${rangeFilter2.maxValue}`
  );
}

export function buildPayloadForComputedFilters(
  computeFiltersForGroupByField: any,
  groupByField: any,
  formDataToUse: FormData,
  emptyFormData: any,
  aggregationFilters: ISearchFilterConditions
) {
  computeFiltersForGroupByField[groupByField.name].forEach(
    ({ filterName, fieldName }: { filterName: string; fieldName: string }) => {
      if (!formDataToUse.rangeFilters[filterName]) {
        return;
      }

      if (
        emptyFormData &&
        isRangeFilterNotEqual(emptyFormData.rangeFilters[filterName], formDataToUse.rangeFilters[filterName])
      ) {
        aggregationFilters.computeFilters = [
          ...aggregationFilters.computeFilters,
          {
            fieldName,
            // Data in the table converted from value to percentage. Reverse computed filters for percentage metrics back to values. Payload mutation.
            minValue: !PERCENTAGE_METRICS[filterName]
              ? formDataToUse.rangeFilters[filterName].minValue
              : formDataToUse.rangeFilters[filterName].minValue / 100,
            maxValue: !PERCENTAGE_METRICS[filterName]
              ? formDataToUse.rangeFilters[filterName].maxValue
              : formDataToUse.rangeFilters[filterName].maxValue / 100
          }
        ];
      }
    }
  );

  return aggregationFilters;
}
