import React, { Fragment } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import OmniTileHeader from 'src/components/SummaryTile/OmniTIleHeader';
import { Widget as WidgetType } from 'src/types/application/widgetTypes';
import { OmniTileEntity as omniTileEntityType } from 'src/types/application/types';
import './SummaryTile.scss';

interface OmniTileProps {
  widget: WidgetType;
  omniTileEntity: omniTileEntityType;
}

const ChartsSection = ({ clonedSummaryMiniMetrics }: { clonedSummaryMiniMetrics: WidgetType[] }) => {
  return (
    <div className="charts_section">
      {clonedSummaryMiniMetrics.map((widget: WidgetType) => {
        const { CustomComponent } = widget;

        return <Fragment key={widget.name}>{CustomComponent && <CustomComponent widget={widget} />}</Fragment>;
      })}
    </div>
  );
};

const OmniTile: React.FC<OmniTileProps> = ({ widget, omniTileEntity }: OmniTileProps) => {
  const { summaryTrendWidgets } = widget.data;

  const clonedSummaryMiniMetrics = _cloneDeep(summaryTrendWidgets);
  clonedSummaryMiniMetrics.forEach((item) => {
    item.name += `__${omniTileEntity.id}`;
    item.data.queryEntity = {
      ...omniTileEntity
    };
  });
  return (
    <div className="omni_tile_container">
      <div className="section_warpper">
        <OmniTileHeader omniTileEntity={omniTileEntity} customStyle={{ padding: '35px 0', height: 185 }} />
      </div>
      <div className="section_warpper" style={{ borderBottom: 'none' }}>
        <ChartsSection clonedSummaryMiniMetrics={clonedSummaryMiniMetrics} />
      </div>
    </div>
  );
};

export default OmniTile;
