import types from './types';

/**
 * Called with an entity metrics request is made.
 *
 * @param statePropertyName The key under `entitySearchService` where the response will be stored
 * @param mergeIfStatePropertyValueExists If `true`, existing data fetched under that key won't be overwritten.
 *        Instead, new aggregation fields and other datat will be merged in to existing data.
 */
const requestEntitySalesMetrics = (statePropertyName: string, mergeIfStatePropertyValueExists = false) =>
  ({
    type: types.REQUEST_ENTITY_SALES_METRICS,
    statePropertyName,
    mergeIfStatePropertyValueExists
  } as const);

/**
 * Called when entity metric search results are received.  The returned data will be stored under
 * `entitySearchService[statePropertyKey]`.
 */
const receiveEntitySalesMetrics = (statePropertyName: string, statePropertyValue: any) =>
  ({
    type: types.RECEIVE_ENTITY_SALES_METRICS,
    statePropertyName,
    statePropertyValue,
    receivedAt: Date.now()
  } as const);

const clearEntitySearchService = () =>
  ({
    type: types.CLEAR_ENTITY_SEARCH_SERVICE
  } as const);

const requestAdditionalData = () =>
  ({
    type: types.IS_FETCHING_ADDITIONAL_DATA
  } as const);

/**
 * Takes all of the keys of `entitySearchService[fromKey]` and spreads them into `entitySearchService[toKey]`, setting
 * `entitySearchService[fromKey]` to `null` in the process.
 */
const mergeDataKeys = (fromKey: string, toKey: string) => ({ type: types.MERGE_DATA_KEYS, fromKey, toKey } as const);

const setDataKeyAttribute = (dataKey: string, attrName: string, attrValue: any) =>
  ({
    type: types.SET_DATA_KEY_ATTRIBUTE,
    dataKey,
    attrName,
    attrValue
  } as const);

const mergeDataKeyToMapping = (srcKey: string, dstKey: string) =>
  ({
    type: types.MERGE_DATA_KEYS_TO_MAPPING,
    srcKey,
    dstKey
  } as const);

const setKey = (key: string, value: any) => ({ type: types.SET_DATA_KEY, key, value } as const);

const deleteKey = (key: string) => ({ type: types.DELETE_DATA_KEY, key } as const);

/**
 * Maps the value at `enitySearchService[fromKey]` through `mapValueToNewValue` and sets the result into
 * `entitySearchService[toKey]`, blanking the old key.
 *
 * @param mapValueToNewValue Function that takes the value of `fromKey` and returns a new value which will be
 *        set onto `toKey`.
 */
const mapKey = <T, R>(fromKey: string, toKey: string, mapValueToNewValue: (val: T) => R) =>
  ({
    type: types.MAP_KEY,
    fromKey,
    toKey,
    mapValueToNewValue
  } as const);

export default {
  clearEntitySearchService,
  receiveEntitySalesMetrics,
  requestEntitySalesMetrics,
  requestAdditionalData,
  mergeDataKeys,
  setDataKeyAttribute,
  setKey,
  deleteKey,
  mapKey,
  mergeDataKeyToMapping
};
