import {
  FrequentlyPurchasedTogetherAdvancedSearchResponse,
  PurchasedTogetherDocument
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/FrequentlyPurchasedTogether/types';
import _get from 'lodash/get';
import { getProductImageUrl } from 'src/utils/image';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import ReduxStore from 'src/types/store/reduxStore';

export interface ProductData {
  retailerId: number;
  retailerSku: string;
  title: string;
  stacklineSku: string;
  retailerProductFamilyType: string;
  brand: string;
  retailerParentSku: string;
  bc: boolean;
  brandId: number;
  brandNameNormalized: string;
  categoryId: number;
  categoryName: string;
  subcategoryId: number;
  subcategoryName: string;
  upc: string;
  ean: string;
  metadataUpdatedTime: string;
  metadataUpdatedBy: string;
}
export interface PurchasedTogetherRow {
  name: string;
  id: string;
  i: number;
  entity: ProductData;
  leadConversions: number;
  formattedLeadConversions: string;
  overlapConversions: number;
  formattedOverlapConversions: string;
  overlapRate: number;
  formattedOverlapRate: string;
  customData: {
    name: string;
    id: string;
    i: number;
    entity: ProductData;
  };
}

export const adPurchasedTogetherAdvancedSearchParser = (
  response: FrequentlyPurchasedTogetherAdvancedSearchResponse,
  formatMetric
) => {
  const purchasedTogetherData: PurchasedTogetherDocument[] = _get(response, 'documents', []);
  const productData: ProductData[] = _get(response, 'additionalResponseMetaData.productData', []);

  const purchasedTogetherRows = purchasedTogetherData.map((documentObject, index) => {
    // Find the corresponding product data for the lead asin and overlap asin
    const leadProductMatch = productData.find((product) => product.retailerSku === documentObject.leadAsin);
    const overlapProductMatch = productData.find((product) => product.retailerSku === documentObject.overlapAsin);

    // If we can't map a lead product or overlap product for any reason, we shouldn't attempt to create a row.
    if (!leadProductMatch || !overlapProductMatch) {
      return null;
    }

    const modifiedLeadProductEntity = {
      ...leadProductMatch,
      name: leadProductMatch.title,
      keyFieldName: 'stacklineSku',
      type: 'product',
      displayName: 'Lead Product',
      displayNamePlural: 'Lead Products',
      id: leadProductMatch.stacklineSku,
      imageUrl: () => getProductImageUrl(leadProductMatch.stacklineSku)
    };

    const modifiedOverlapProductEntity = {
      ...overlapProductMatch,
      name: overlapProductMatch.title,
      keyFieldName: 'stacklineSku',
      type: 'product',
      displayName: 'Overlap Product',
      displayNamePlural: 'Overlap Products',
      id: overlapProductMatch.stacklineSku,
      imageUrl: () => getProductImageUrl(overlapProductMatch.stacklineSku)
    };

    // Access metric values for each column
    const leadConversions = documentObject.leadAsinUniqueShoppersThatPurchased;
    const overlapConversions = documentObject.leadAndOverlapAsinUniqueShoppersThatPurchased;
    const overlapRate = documentObject.userOverlap;

    const formattedLeadConversions = formatMetric(leadConversions, METRICTYPE.VOLUME, {
      showFullValue: true,
      decimals: 2
    });
    const formattedOverlapConversions = formatMetric(overlapConversions, METRICTYPE.VOLUME, {
      showFullValue: true,
      decimals: 2
    });
    const formattedOverlapRate = formatMetric(overlapRate, METRICTYPE.PERCENT, { showFullValue: true, decimals: 2 });

    return {
      name: leadProductMatch.title,
      id: leadProductMatch.stacklineSku,
      i: index,
      entity: modifiedLeadProductEntity,
      leadConversions,
      formattedLeadConversions,
      overlapConversions,
      formattedOverlapConversions,
      overlapRate,
      formattedOverlapRate,
      customData: {
        name: overlapProductMatch.title,
        id: overlapProductMatch.stacklineSku,
        i: index,
        entity: modifiedOverlapProductEntity
      }
    };
  });

  // Filter out null rows from when a product match was missing.
  // Sort by overlapping conversions.
  return purchasedTogetherRows
    .filter((row) => row !== null)
    .sort((rowA, rowB) => rowB.overlapConversions - rowA.overlapConversions);
};

interface BuildRequestForPurchasedTogetherParameters {
  startDayId: string | number;
  endDayId: string | number;
  retailerId?: string | number;
}

export const buildRequestForPurchasedTogether = ({
  startDayId,
  endDayId,
  retailerId = 1
}: BuildRequestForPurchasedTogetherParameters) => {
  return [
    {
      name: 'frequently_purchased_together_grid',
      id: 'frequently_purchased_together_grid',
      pageNumber: 1,
      pageSize: 50,
      doAggregation: false,
      returnDocuments: true,
      sortBy: 'leadAndOverlapAsinUniqueShoppersThatPurchased',
      sortDirection: 'desc',
      conditions: {
        termFilters: [
          {
            fieldName: 'periodStartDate',
            condition: 'should',
            values: [startDayId]
          },
          {
            fieldName: 'periodEndDate',
            condition: 'should',
            values: [endDayId]
          },
          {
            fieldName: 'retailerId',
            condition: 'should',
            values: [retailerId]
          }
        ],
        computeFilters: []
      },
      searchBy: 'parent',
      searchType: 'advertising-amc-frequently-purchased-together',
      processDocuments: false
    }
  ];
};

interface ConvertPurchasedTogetherGridDataToCsv {
  rowData: PurchasedTogetherRow[];
  dateRange: ReduxStore['mainTimePeriod']['displayName'];
}

/**
 *
 * @param rowData A grid row containing the data we want to export
 * @param dateRange A formatted string for the selected date range
 * @returns A stringified version of our data with line breaks
 */
export const convertPurchasedTogetherGridDataToCsv = ({
  rowData,
  dateRange
}: ConvertPurchasedTogetherGridDataToCsv) => {
  const date = `"${dateRange}",`;
  const headers = [
    'Lead Products',
    'Unique Purchasing Shoppers',
    'Overlap Products',
    'Unique Purchasing Shoppers',
    'Overlap Rate'
  ]
    .map((val) => `"${val}"`)
    .join(',');

  const rowValues = rowData.map((row) => {
    // Get the overlap product name
    const { name } = row.customData;

    // We need to wrap product names in quotes because they can contain commas
    const leadProductName = JSON.stringify(row.name);
    const leadConversionCount = JSON.stringify(row.leadConversions);
    const overlapProductName = JSON.stringify(name);
    const overlapConversionCount = JSON.stringify(row.overlapConversions);
    const overlapRate = JSON.stringify(row.overlapRate);

    return `${leadProductName},${leadConversionCount},${overlapProductName},${overlapConversionCount},${overlapRate}`;
  });

  return `${date}\n${headers}\n${rowValues.join('\n')}`;
};
