import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'qs';
import _isNil from 'lodash/isNil';

import { METRICTYPE } from 'src/utils/entityDefinitions';
import { EntityColumn } from 'src/components/Grids/Data/ColumnTypes';
import 'src/components/EntityGrid/Table/EntityTableContainer.scss';
import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { withProps } from 'src/utils/hoc';
import { warn } from 'src/utils/mixpanel';

// If possible, parameterize this to make it a reusable component - similar to entityTableContainer
class SummaryTableContainer extends Component {
  static propTypes = {
    dataSet: PropTypes.array.isRequired,
    dynamicColumnHeader: PropTypes.string,
    metricHeader: PropTypes.string,
    selectedEntity: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    timePeriodRange: PropTypes.string.isRequired,
    totalsData: PropTypes.array.isRequired,
    selectedItem: PropTypes.object.isRequired
  };

  static defaultProps = {
    dynamicColumnHeader: 'Brand',
    metricHeader: 'Sales'
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    window.addEventListener('resize', () => setTimeout(() => params.api.sizeColumnsToFit()));
    params.api.sizeColumnsToFit();
  };

  render() {
    const { dataSet, dynamicColumnHeader, selectedEntity, metricHeader, timePeriodRange, totalsData, retailer } =
      this.props;

    // If we're on the segments or categories page and have a selected segment, we want to modify the link in the entity column to
    // inlude a segment filter for the currently selected segment.
    const NameColumnCellRendererFramework = (() => {
      if (!this.props.selectedItem) {
        return EntityColumn;
      }

      const { id, type } = this.props.selectedItem;

      // Handle "All Categories" view by not adding any filter
      if (_isNil(id) || id === 0 || id === '0') {
        return EntityColumn;
      }

      const filterStringKey = {
        category: 'c',
        segment: 'sg'
      }[type];
      if (!filterStringKey) {
        warn(`Unhandled \`selectedItem.type\` of "${type}" in \`SummaryTableContainer\``);
        return EntityColumn;
      }

      const filterString = JSON.stringify({ [filterStringKey]: [{ i: id.toString() }] });
      // eslint-disable-next-line no-shadow
      const getLinkOverride = ({ type, id, searchParams, parsedAdditionalParameters }) => {
        const allQueryParams =
          type === 'brand'
            ? {
                ...parsedAdditionalParameters,
                filter: filterString
              }
            : parsedAdditionalParameters;
        return `/${type}/${id}${searchParams}&${queryString.stringify(allQueryParams)}`;
      };

      return withProps({ getLinkOverride })(EntityColumn);
    })();

    const gridOptions = {
      columnDefs: [
        {
          headerName: '',
          field: 'rank',
          width: 130
        },
        {
          headerName: dynamicColumnHeader,
          field: 'name',
          cellRendererFramework: NameColumnCellRendererFramework,
          minWidth: 210
        },
        {
          headerName: metricHeader,
          field: 'currentValue',
          minWidth: 100
        },
        {
          headerName: 'Growth',
          field: 'growth',
          minWidth: 90
        },
        {
          headerName: 'Share',
          field: 'marketShare',
          minWidth: 90
        },
        {
          headerName: 'Change',
          field: 'marketShareChange',
          minWidth: 90
        }
      ],
      buildRows: () => {
        const allRows = [];
        dataSet.forEach((item, index) => {
          const { cardView, value_change } = item;
          const { secondaryDisplayTitle, metricData } = cardView;
          const { currencySymbol, metricType, name } = metricData;
          const currentValue = convertMetricToDisplayValue(
            retailer,
            cardView[`${name}CurrentValue`],
            metricType,
            currencySymbol,
            false
          );
          const growth = convertMetricToDisplayValue(
            retailer,
            cardView[`${name}PercentChange`],
            METRICTYPE.PERCENT,
            currencySymbol,
            true
          );
          const marketShare = convertMetricToDisplayValue(
            retailer,
            item[`value${timePeriodRange}`],
            METRICTYPE.PERCENT,
            currencySymbol,
            true
          );
          const marketShareChange = convertMetricToDisplayValue(
            retailer,
            value_change,
            METRICTYPE.PERCENT,
            currencySymbol,
            true
          );
          // TODO Make the type here dynamic to support other entity types
          allRows.push({
            rank: index + 1,
            name: secondaryDisplayTitle,
            id: cardView[selectedEntity.keyFieldName],
            type: selectedEntity.type,
            currentValue,
            growth,
            marketShare,
            marketShareChange
          });
        });

        const constantMetricType = dataSet[0].cardView.metricData.metricType;
        const constantCurrencySymbol = dataSet[0].cardView.metricData.currencySymbol;
        const [otherMetricAmount, otherCurrentGrowth, otherCurrentShare, otherCurrentChange] = [
          ['Value', constantMetricType],
          ['PercentGrowth'],
          ['PercentShare'],
          ['PercentChange']
        ].map(([key, metricType = METRICTYPE.PERCENT]) => {
          return convertMetricToDisplayValue(
            retailer,
            totalsData[0][`otherCurrent${key}${timePeriodRange}`],
            metricType,
            constantCurrencySymbol,
            key === 'PercentChange' // Other Change can be -, should not use abs value
          );
        });

        allRows.push({
          rank: '-',
          name: 'Other',
          id: 'Other',
          currentValue: otherMetricAmount,
          growth: otherCurrentGrowth,
          marketShare: otherCurrentShare,
          marketShareChange: otherCurrentChange
        });
        const totalMetricAmount = convertMetricToDisplayValue(
          retailer,
          totalsData[0][`value${timePeriodRange}`],
          constantMetricType,
          constantCurrencySymbol,
          false
        );
        const totalGrowthValue = convertMetricToDisplayValue(
          retailer,
          totalsData[0].totalGrowthValue,
          METRICTYPE.PERCENT,
          constantCurrencySymbol,
          true
        );
        allRows.push({
          rank: '',
          name: 'Total',
          id: 'Total',
          currentValue: totalMetricAmount,
          growth: totalGrowthValue,
          marketShare: '100%',
          marketShareChange: '0%'
        });
        return allRows;
      },
      getRowClass: (params) => {
        let value;
        if (params.node.rowIndex === gridOptions.buildRows().length - 1) {
          value = 'last-line';
        }
        if (params.node.rowIndex === 6) {
          value = 'summary__total-row';
        }
        return value;
      }
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }} className="summary-table-container">
        <div style={{ overflow: 'hidden', flexGrow: '1' }}>
          <CustomAgMaterial
            columnDefs={gridOptions.columnDefs}
            buildRows={gridOptions.buildRows}
            onGridReady={this.onGridReady}
            getRowClass={gridOptions.getRowClass}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ retailer }) => ({ retailer });

export default withRouter(connect(mapStateToProps)(SummaryTableContainer));
