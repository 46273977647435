import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

interface OmniDropDownSelectorProps {
  headerDisplayName: string;
  listSelection: { value: number; displayName: string }[];
  initialVal: number;
  triggerFunc: (curVal: number) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      marginTop: 10,
      width: 210,
      marginLeft: '-30px'
    },
    selector: {
      marginLeft: '-10px',
      fontSize: 14,
      display: 'flex',
      justifyContent: 'flex-start',
      fontFamily: 'Roboto, sans-serif'
    }
  })
);

const OmniDropDownSelector: FC<OmniDropDownSelectorProps> = ({
  headerDisplayName,
  initialVal,
  listSelection,
  triggerFunc
}) => {
  const classes = useStyles();
  const [val, setVal] = React.useState<number>(initialVal);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setVal(event.target.value as number);
    triggerFunc(event.target.value as number);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="omni-search-form-container">
      <h4 className="sl-form-label" style={{ marginTop: '50px' }}>
        {headerDisplayName}
      </h4>
      <FormControl variant="standard" className={classes.formControl}>
        <Select
          variant="standard"
          classes={{ select: classes.selector }}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={val}
          onChange={handleChange}
        >
          {listSelection.map((l) => (
            <MenuItem value={l.value} key={l.value}>
              {l.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default OmniDropDownSelector;
