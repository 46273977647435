import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

interface OmniCardViewSearchProps {
  style: React.CSSProperties;
  onSearch: (searchKey: string) => void;
}

const useStyles = makeStyles({
  searchInput: {
    borderBottom: `1px solid #dedede`,
    width: '100%',
    borderRadius: 0
  },
  searchIcon: {
    color: '#7e8fa8',
    opacity: 0.7,
    paddingRight: 5
  }
});

const OmniCardViewSearch: React.FC<OmniCardViewSearchProps> = ({ style, onSearch }: OmniCardViewSearchProps) => {
  const classes = useStyles();
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onSearch(newValue);
  };
  return (
    <div style={style}>
      <TextField
        variant="standard"
        placeholder="Search for location"
        InputProps={{
          disableUnderline: true,
          style: {
            fontWeight: 500,
            fontSize: '18px'
          },
          startAdornment: <SearchIcon classes={{ root: classes.searchIcon }} />
        }}
        classes={{ root: classes.searchInput }}
        onChange={handleSearch}
      />
    </div>
  );
};

export default OmniCardViewSearch;
