import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Text, useStacklineTheme } from '@stackline/ui';
import { GenericNavigationTabs } from 'src/components/BeaconRedesignComponents/SubtabNav/SubtabNavigation';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/utils/Hooks';
import TopicsViewTab from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Topics/TopicsViewTab';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

enum TopicsTab {
  myTopics = 'myTopics',
  teamTopics = 'teamTopics'
}

interface TopicNavigationTabsProps {
  tab: TopicsTab;
  setTab: (tab: TopicsTab) => void;
}

/**
 * List of tabs for the top of the segments page
 */
const TopicNavigationTabs = ({ tab, setTab }: TopicNavigationTabsProps) => {
  return (
    <GenericNavigationTabs
      tab={tab}
      tabs={[
        {
          label: 'My Topics',
          onClick: () => setTab(TopicsTab.myTopics),
          value: TopicsTab.myTopics
        },
        {
          label: 'Team Topics',
          onClick: () => setTab(TopicsTab.teamTopics),
          value: TopicsTab.teamTopics
        }
      ]}
    />
  );
};

/**
 * Topics page container
 */
const Topics = () => {
  const theme = useStacklineTheme();
  const [tab, setTab] = useState(TopicsTab.myTopics);
  const { searchParams } = useAppSelector((state) => state.app.queryParams);

  return (
    <BeaconPageContainer sx={{ width: '100%', marginTop: '0px' }}>
      <Box display="flex" gap="44px" width="100%">
        <Box width="100%">
          <Box display="flex" justifyContent="space-between" width="100%">
            <Text variant="h2">Topics</Text>
            {[TopicsTab.myTopics, TopicsTab.teamTopics].includes(tab) && (
              <Link to={`/search${searchParams}&entityType=searchtermlist`}>
                <SlButton variant="contained">+ Create Topic</SlButton>
              </Link>
            )}
          </Box>
          <Box marginBottom={theme.spacing.lg}>
            <TopicNavigationTabs tab={tab} setTab={setTab} />
          </Box>
          <Box width="100%">{tab === TopicsTab.myTopics && <TopicsViewTab />}</Box>
          <Box width="100%">{tab === TopicsTab.teamTopics && <TopicsViewTab team />}</Box>
        </Box>
      </Box>
    </BeaconPageContainer>
  );
};

export default Topics;
