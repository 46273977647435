/* eslint-disable react/prop-types */

import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import CardEllipsesButton from '../common/Buttons/CardEllipsesButton';
import ExcludeButton from 'src/components/common/Buttons/ExcludeButton';
import './Card.scss';
import ReduxStore from 'src/types/store/reduxStore';
import { store } from 'src/main';

interface Props {
  cardFront: ReactNode;
  cardBack: ReactNode;
  isCardFlipped: boolean;
  isMouseOver: boolean;
  handleFlipButtonClick: (e: React.SyntheticEvent) => void;
  handleExcludeButtonClick: (e: React.SyntheticEvent) => void;
  shouldShowReclassify: boolean;
  isStacklineSuperUser: boolean;
  tall?: boolean;
  handleMouseOver?: (b: boolean) => void;
  isFlippable?: boolean;
}

const getCardContainerClassName = (tall: boolean, isStacklineSuperUser: boolean, shouldShowReclassify: boolean) => {
  const base = 'card-container';
  if (shouldShowReclassify) {
    return `${base} ${base}--tall--reclassify`;
  }

  if (tall || (isStacklineSuperUser && !shouldShowReclassify)) {
    return `${base} ${base}--tall`;
  } else {
    return base;
  }
};

const getCardInnerClassName = (
  base: string,
  shouldShowReclassify: boolean,
  isStacklineSuperUser: boolean,
  isMouseOver: boolean
) => {
  let className = base;

  if (shouldShowReclassify) {
    className += ` ${base}--reclassify`;
  } else if (isStacklineSuperUser) {
    className += ` ${base}--tall`;
  }

  if (isMouseOver) {
    className += ` ${base}--hover`;
  }

  return className;
};

const CardView: React.FC<Props> = ({
  cardFront,
  cardBack,
  tall = false,
  handleMouseOver = () => {},
  handleFlipButtonClick,
  handleExcludeButtonClick,
  isMouseOver,
  isFlippable = true,
  isCardFlipped,
  shouldShowReclassify,
  isStacklineSuperUser
}) => {
  // Only show quick exclude button on Segment builder
  const showQuickExclude =
    store.getState().entityService.mainEntity &&
    store.getState().entityService.mainEntity.type === 'segment' &&
    !isStacklineSuperUser;

  return (
    <div
      className={getCardContainerClassName(tall, isStacklineSuperUser, shouldShowReclassify)}
      onMouseEnter={() => handleMouseOver(true)}
      onMouseLeave={() => handleMouseOver(false)}
    >
      <div className={`card ${isCardFlipped ? 'card--flipped' : ''}`}>
        <div className={getCardInnerClassName('card__front', shouldShowReclassify, isStacklineSuperUser, isMouseOver)}>
          {isFlippable && isMouseOver ? (
            <>
              {showQuickExclude ? <ExcludeButton onClick={handleExcludeButtonClick} /> : null}
              <CardEllipsesButton onClick={handleFlipButtonClick} />
            </>
          ) : null}
          {!isCardFlipped ? cardFront : null}
        </div>
        {isCardFlipped ? (
          <div className={getCardInnerClassName('card__back', shouldShowReclassify, isStacklineSuperUser, isMouseOver)}>
            {isMouseOver ? <CardEllipsesButton onClick={handleFlipButtonClick} /> : null}
            {cardBack}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps: (s: ReduxStore) => { isStacklineSuperUser: boolean } = ({
  user: {
    config: { isStacklineSuperUser }
  }
}) => ({ isStacklineSuperUser });

export default connect(mapStateToProps)(CardView);
