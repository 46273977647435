import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import withStyles from '@mui/styles/withStyles';
import { TOTAL_USER_COLUMNS } from '../utils';
import { UserCustomColumn } from 'src/components/Layout/Advertising/AdManagerPageLayout/types';
import colors from 'src/utils/colors';

const StyledToggleButtonGroup = withStyles(() => ({
  root: {
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 5
  },
  grouped: {
    flex: '0 0 24%',
    '&:not(:first-child)': {
      borderRadius: '5px',
      borderLeft: 'none'
    },
    '&:first-child': {
      borderRadius: '5px'
    }
  }
}))(ToggleButtonGroup);

export interface UserCustomColumnButtonGroupProps {
  /**
   * List of all columns to display as options
   */
  columns: UserCustomColumn[];
  /**
   * String values of the columns that are selected
   * for the group
   */
  selectedColumnValues: string[];
  /**
   * Callback for when a column is selected/deselected
   */
  onSelectColumns: (event: React.MouseEvent<HTMLElement, MouseEvent>, newColumns: UserCustomColumn[]) => void;
  /**
   * A list of all columns string values that are selected
   * across all custom column button groups
   */
  userSettingColumns: string[];
}

/**
 * Button group for displaying custom column
 * options in the Custom Columns page for user
 * settings
 */
const UserCustomColumnButtonGroup = ({
  columns,
  selectedColumnValues,
  onSelectColumns,
  userSettingColumns
}: UserCustomColumnButtonGroupProps) => {
  return (
    <StyledToggleButtonGroup size="small" value={selectedColumnValues} onChange={onSelectColumns} aria-label="">
      {columns.map((column, cidx) => {
        const disabled = userSettingColumns.length >= TOTAL_USER_COLUMNS && !userSettingColumns.includes(column.field);
        return (
          <ToggleButton
            disabled={disabled}
            key={cidx}
            value={column.field}
            aria-label="left aligned"
            sx={{
              padding: 1,
              border: '1px solid #c8ced7 !important',
              borderRadius: '3px !important',
              marginLeft: '0 !important',
              minHeight: '58px'
            }}
          >
            <div
              style={{
                width: 135,
                textTransform: 'none',
                color: disabled
                  ? colors.lightGrey
                  : // If the column is selected make the text dark blue to indicate selection
                  selectedColumnValues.includes(column.field)
                  ? colors.darkBlue
                  : colors.grey
              }}
            >
              {column.displayName}
            </div>
          </ToggleButton>
        );
      })}
    </StyledToggleButtonGroup>
  );
};

export default UserCustomColumnButtonGroup;
