import React from 'react';
import Waypoint from 'react-waypoint';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import queryString from 'qs';
import { OmniTableContainer } from 'src/components/EntityGrid/EntityGrid/OmniEntityGrid';
import { mkChipButtonCellFormatter } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import _get from 'lodash/get';
import OmniStoreListSideBar from 'src/components/Omni/OmniStoreListsContainer/OmniStoreListSideBar';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { formStoreListRequestBody } from 'src/components/Omni/OmniSegmentUtil';
import { getRetailerIdDisplayName } from 'src/utils/stringFormatting';
import { usePagination } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';

const DefaultGridLoading = () => (
  <div className="omni-keyword-search-loading">
    <GridLoading />
  </div>
);

const processStoreListData =
  (dataKey: string[], retailerIdUse: number, retailer: ReduxStore['retailer']) => (rawData: any[]) => {
    return rawData.map((ele) => {
      const { key } = ele;
      const entity = {
        id: key,
        name: getRetailerIdDisplayName(String(retailerIdUse), retailer)
      };
      const aProcessedData = { entity, ...entity };
      dataKey.forEach((dk) => {
        const value = _get(ele, `${dk}.value`, 0);
        aProcessedData[dk] = value;
        aProcessedData[`${dk}Value`] = value;
      });
      return aProcessedData;
    });
  };

const OmniStoreListsContainer: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const mainTimePeriod = useSelector((store: ReduxStore) => store.mainTimePeriod);
  const { location } = props;
  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 10e8 });
  const requestBody = formStoreListRequestBody(queryParams, mainTimePeriod);
  const [retailerIdUse] = requestBody.retailerIds;
  const dataKey = ['locationZip', 'locationCity', 'locationCounty', 'locationStateName'];

  const [pageNumber, pageSize, handleRequestChange, isLoading, dataList] = usePagination(
    '/omni/segment/getStoreSuggestions',
    requestBody,
    'data.data.storeId.buckets',
    'data.pageNumber',
    'data.pageSize',
    processStoreListData(dataKey, retailerIdUse, retailer)
  );

  const excludeColumnDef = {
    name: 'action',
    displayName: '',
    entity: {},
    width: 85,
    dataType: null,
    metricType: null,
    cellRendererFramework: mkChipButtonCellFormatter('addToExcludeSearchTerm', 'Exclude')
  };

  const omniTableViewFieldForTableView = [
    {
      currencySymbol: '',
      name: 'id',
      headerName: 'Store ID',
      metricType: METRICTYPE.TEXT,
      aggregationFunction: 'avg',
      dataKey: 'id',
      widthForColumn: 150
    },
    {
      currencySymbol: '',
      name: 'locationZip',
      headerName: 'Store Zip',
      metricType: METRICTYPE.TEXT,
      aggregationFunction: 'avg',
      dataKey: 'locationZip',
      widthForColumn: 200
    },
    {
      currencySymbol: '',
      name: 'locationStateName',
      headerName: 'Store State',
      metricType: METRICTYPE.TEXT,
      aggregationFunction: 'avg',
      dataKey: 'locationStateName',
      widthForColumn: 200
    },
    {
      currencySymbol: '',
      name: 'locationCounty',
      headerName: 'Store County',
      metricType: METRICTYPE.TEXT,
      aggregationFunction: 'avg',
      dataKey: 'locationCounty',
      widthForColumn: 200
    },
    {
      currencySymbol: '',
      name: 'locationCity',
      headerName: 'Store City',
      metricType: METRICTYPE.TEXT,
      aggregationFunction: 'avg',
      dataKey: 'locationCity',
      widthForColumn: 250
    }
  ];

  const handleWaypointEntered = () => {
    if (isLoading || pageNumber === pageSize || pageSize < 20) {
      return;
    }
    handleRequestChange({}, { pageNumber: pageNumber + 1, pageSize });
  };
  return (
    <div className="omni-search-list">
      <OmniStoreListSideBar {...props} handleRequestChange={handleRequestChange} />
      <div className="omni-search-list__content">
        <div style={{ height: 805, overflow: 'scroll' }}>
          {isLoading && pageNumber === 1 ? (
            <DefaultGridLoading />
          ) : (
            <OmniTableContainer
              dataToRender={dataList}
              omniTableViewFieldForTableView={omniTableViewFieldForTableView}
              extraColumnDef={excludeColumnDef}
              onClickToSort={() => {}}
              groupByField={{
                displayName: 'Retailer Name',
                name: 'store'
              }}
              distanceToFirstCol={200}
              styles={{
                width: '100%',
                height: '100%',
                marginTop: '17px'
              }}
            >
              <Waypoint onEnter={handleWaypointEntered} />
              <br />
              <br />
            </OmniTableContainer>
          )}
          {isLoading && <DefaultGridLoading />}
        </div>
      </div>
    </div>
  );
};

export default withRouter(OmniStoreListsContainer);
