import React, { useMemo, useState } from 'react';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { useBus, useAppSelector } from 'src/utils/Hooks';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import Visualization from 'src/components/Layout/Advertising/ConnectPageLayout/components/Visualization';
import {
  AD_SALES_PERCENTAGE_VARIABLES,
  AD_UNITS_SOLD_PERCENTAGE_VARIABLES,
  ALL_AD_TYPES,
  CONVERSION_PERCENTAGE_VARIABLES,
  DATE_FORMAT,
  DSP_AD_TYPES,
  NTB_PRIMARY_OVERTIME_EVENT,
  NTB_SECONDARY_OVERTIME_EVENT,
  SEARCH_AD_TYPES
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/NewToBrandTab/NewToBrandOverTime/constants';
import { NewToBrandOverTimeDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import NewToBrandLineChart from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/NewToBrandTab/NewToBrandOverTime/NewToBrandLineChart';
import {
  adNewToBrandOverTimeAdvancedSearchParser,
  buildRequestForNewToBrandOverTime
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/NewToBrandTab/NewToBrandOverTime/utils';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import useAmcDateRange from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/hooks/useAmcDateRange';
import _get from 'lodash/get';
import moment from 'moment';

interface NewToBrandOverTimeVisualizationProps {
  eventBus: EventBus;
}

const NewToBrandOverTimeVisualization = ({ eventBus }: NewToBrandOverTimeVisualizationProps) => {
  const [primaryMetric, setPrimaryMetric] = useState(NewToBrandOverTimeDropdownOption.AdSalesPercent);
  const [secondaryMetric, setSecondaryMetric] = useState(NewToBrandOverTimeDropdownOption.AllAdsTypes);
  const { startDayId, endDayId } = useAmcDateRange();
  const { retailer, mainTimePeriod } = useAppSelector((state) => state);
  const { startDayId: mainPeriodStartDay, endDayId: mainPeriodEndDay } = mainTimePeriod;

  useBus(eventBus, NTB_PRIMARY_OVERTIME_EVENT, setPrimaryMetric);
  useBus(eventBus, NTB_SECONDARY_OVERTIME_EVENT, setSecondaryMetric);

  // Advanced Search Request Body
  const newToBrandOverTimeRequestBody = useMemo(
    () => buildRequestForNewToBrandOverTime({ startDayId, endDayId, retailerId: retailer.id }),
    [endDayId, startDayId, retailer.id]
  );

  // Fetching data
  const { data, isLoading } = useGenericAdvancedSearch({
    requestBody: newToBrandOverTimeRequestBody,
    queryKeys: newToBrandOverTimeRequestBody,
    requestId: 'new-to-brand-over-time'
  });

  const metricToAdTypeMap = {
    [NewToBrandOverTimeDropdownOption.AllAdsTypes]: ALL_AD_TYPES,
    [NewToBrandOverTimeDropdownOption.DemandSidePlatformAds]: DSP_AD_TYPES,
    [NewToBrandOverTimeDropdownOption.SearchAds]: SEARCH_AD_TYPES
  };
  const metricToDocumentKeyMap = {
    [NewToBrandOverTimeDropdownOption.AdSalesPercent]: AD_SALES_PERCENTAGE_VARIABLES,
    [NewToBrandOverTimeDropdownOption.AdUnitsSoldPercent]: AD_UNITS_SOLD_PERCENTAGE_VARIABLES,
    [NewToBrandOverTimeDropdownOption.ConversionsPercent]: CONVERSION_PERCENTAGE_VARIABLES
  };

  // Data Access
  const dataForChart = useMemo(() => {
    if (data) {
      const documentData = adNewToBrandOverTimeAdvancedSearchParser(_get(data, 'data[0]'));
      // Get the key to pull metric values from our filtered documents based on the first dropdown option selected
      const { numeratorKey, denominatorKey } = metricToDocumentKeyMap[primaryMetric];
      const formattedMinDate = String(mainPeriodStartDay).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'); // 20230417 --> 2023-04-17
      const formattedMaxDate = String(mainPeriodEndDay).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
      const minEpochTimeStamp = moment.utc(formattedMinDate, DATE_FORMAT).valueOf();
      const maxEpochTimeStamp = moment.utc(formattedMaxDate, DATE_FORMAT).valueOf();

      // Filter our results based on Group By option selected (EX: All Ad Types or just Search Ads).
      // We also filter out documents that are being returned outside of our selected start and end date ranges.
      const filteredDocuments = documentData.filter((document) => {
        const eventDate = Number(document.additionalMetaData.eventDate);
        const isAdTypeMatch: boolean = metricToAdTypeMap[secondaryMetric].includes(document.fieldId);
        const isWithinDateRange: boolean = eventDate >= minEpochTimeStamp && eventDate <= maxEpochTimeStamp;
        return isAdTypeMatch && isWithinDateRange;
      });
      // Create total numerator/denominator sums for the legend value calculation
      let totalNumeratorSum = 0;
      let totalDenominatorSum = 0;

      // Create a map for each unique timestamp to group our values
      const valuesByUniqueEpochDate = new Map();

      // Using our filtered document collection, access the numerator value and denominator values depending on the metric we have selected.
      filteredDocuments.forEach((document) => {
        const numeratorValue = document.additionalValues[numeratorKey];
        const denominatorValue = document.additionalValues[denominatorKey];

        // Maintain total numerator sum and denominator sum for the legend value
        totalNumeratorSum += numeratorValue;
        totalDenominatorSum += denominatorValue;

        // If we haven't seen the date yet, add a new item to our map and set the sums to an initial value
        if (!valuesByUniqueEpochDate.has(document.additionalMetaData.eventDate)) {
          valuesByUniqueEpochDate.set(document.additionalMetaData.eventDate, {
            numeratorSum: numeratorValue,
            denominatorSum: denominatorValue
          });
        } else {
          // Otherwise, pull the item out of the map and add to the sums
          const doc = valuesByUniqueEpochDate.get(document.additionalMetaData.eventDate);
          doc.numeratorSum += numeratorValue;
          doc.denominatorSum += denominatorValue;
        }
      });

      // The chart expects a collection of tuples or subarrays formatted as [[timestamp, value]]
      const tuples = [];

      // For each date in the map, calculate the ratios and add to our collection
      valuesByUniqueEpochDate.forEach((item, date) => {
        // Avoid division by 0 errors
        const ratio = item.denominatorSum !== 0 ? (item.numeratorSum / item.denominatorSum) * 100 : 0;
        const epochDate = Number(date);
        tuples.push([epochDate, ratio]);
      });

      // Calculate legend value
      const legendValue = totalDenominatorSum !== 0 ? totalNumeratorSum / totalDenominatorSum : 0;

      // Sort by epoch date to avoid Highcharts issues
      tuples.sort((a, b) => a[0] - b[0]);

      return { chartData: tuples, legendValue };
    }
    return null;
  }, [data, primaryMetric, secondaryMetric]);

  return (
    <Visualization
      titleProps={{
        title: ''
      }}
    >
      <div style={{ marginTop: '24px' }}>
        {isLoading && <GenericChartLoading />}
        {!isLoading && dataForChart && (
          <NewToBrandLineChart
            data={dataForChart.chartData}
            primaryMetric={primaryMetric}
            secondaryMetric={secondaryMetric}
            legendValue={dataForChart.legendValue}
          />
        )}
      </div>
    </Visualization>
  );
};

export default withBus('eventBus')(NewToBrandOverTimeVisualization);
