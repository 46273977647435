import types from './types';

// ------------------------------------
// Reducer
// ------------------------------------

export default function navSidebar(state = { isVisible: false }, action) {
  switch (action.type) {
    case types.UPDATE_NAV_SIDEBAR_VISIBILITY:
      return { isVisible: action.isVisible };
    default:
      return state;
  }
}
