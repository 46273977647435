import { useStacklineTheme } from '@stackline/ui';
import React, { useRef, useState } from 'react';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { DownChevronBold } from 'src/components/SvgIcons';
import { SlMenu, SlMenuProps } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlMenu';

export interface EntityPillDropdownProps {
  children: React.ReactNode;
  slMenuProps: Omit<SlMenuProps, 'onClose' | 'anchorEl' | 'open' | 'selectedId'>;
}

/**
 * Shows an entity pill with a dropdown option. Used for the topic and segment entity cards
 */
export default function EntityPillDropdown({ children, slMenuProps }: EntityPillDropdownProps) {
  const theme = useStacklineTheme();
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        style={{
          backgroundColor: theme.colors.secondaryPorcelain,
          width: '136px',
          borderRadius: '12px',
          padding: '4px',
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          height: '24px'
        }}
        ref={ref}
        role="button"
        onClick={() => setOpen(true)}
      >
        <Text variant="subtitle3" textAlign="center">
          {children}
        </Text>
        <DownChevronBold
          height="16px"
          width="16px"
          style={{
            position: 'absolute',
            right: '4px'
          }}
        />
      </div>
      <SlMenu
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        open={open}
        selectedId={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 12 // center underneath the dropdown
        }}
        sx={{
          '& .MuiMenuItem-root': {
            width: '113px'
          }
        }}
        autoFocus={false}
        {...slMenuProps}
      ></SlMenu>
    </>
  );
}
