import React, { useState } from 'react';
import './omniSegment.scss';
import { ChevronIcon, CrossIcon } from 'src/components/SvgIcons';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ReactTooltip from 'react-tooltip';
import _toLower from 'lodash/toLower';
import SearchIcon from '@mui/icons-material/Search';
import colors from 'src/utils/colors';

interface ListSegmentComponentProps {
  data: {
    name: string;
    id: string;
    canEdit: boolean;
    ownerProfile: {
      firstName: string;
      lastName: string;
      [key: string]: any;
    };
  }[];
  title: string;
  searchBarInfo: {
    name: string;
    searchHintText: string;
  };
  handleListItemClick: (id: string) => void;
  handleDeleteButtonClick: (id: string, name: string) => void;
}

interface DisplayCellProps {
  name: string;
  id: string;
  canEdit: boolean;
  ownerProfile: {
    firstName: string;
    lastName: string;
    [key: string]: any;
  };
  handleListItemClick: (id: string) => void;
  handleDeleteButtonClick: (id: string, name: string) => void;
}

const DisplayCell: React.FC<DisplayCellProps> = ({
  name,
  id,
  canEdit,
  ownerProfile,
  handleListItemClick,
  handleDeleteButtonClick
}: DisplayCellProps) => {
  return (
    <div className="omni-cell">
      <div>
        <p className="omni-cell_text_style">{name}</p>

        {ownerProfile && (
          <p className="omni-cell_sub_title_style">Created by {`${ownerProfile.firstName} ${ownerProfile.lastName}`}</p>
        )}
      </div>

      <div className="omni-segment-list-operation-button">
        <IconButton
          data-for="edit-segment"
          data-tip={`View ${name}`}
          onClick={() => {
            handleListItemClick(id);
          }}
          style={{ display: 'inline-block', verticalAlign: 'middle', padding: '0', height: '30px', width: '30px' }}
          size="large"
        >
          <ChevronIcon className="omni-view-details__icon" />
        </IconButton>
        {canEdit && (
          <IconButton
            data-for="delete-segment"
            data-tip={`Delete ${name}`}
            onClick={() => {
              handleDeleteButtonClick(id, name);
            }}
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              padding: '0',
              height: '30px',
              width: '30px',
              paddingTop: '4px'
            }}
            size="large"
          >
            <CrossIcon className="omni-segment-delete" />
          </IconButton>
        )}
      </div>
      <ReactTooltip id="edit-segment" effect="solid" className="sl-tooltip" />
      <ReactTooltip id="delete-segment" effect="solid" className="sl-tooltip" />
    </div>
  );
};

const ListSegmentComponent: React.FC<ListSegmentComponentProps> = ({
  data,
  handleListItemClick,
  handleDeleteButtonClick,
  searchBarInfo,
  title
}) => {
  const [textVal, setTextVal] = useState('');
  const searchedData = data.filter((obj) => _toLower(obj.name).includes(_toLower(textVal)));
  const mySegment = searchedData.filter((obj) => obj.canEdit);
  const teamSegment = searchedData.filter((obj) => !obj.canEdit);

  const handleSearchInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setTextVal(evt.target.value);
  };
  return (
    <>
      <TextField
        variant="standard"
        autoComplete="off"
        placeholder={`  ${searchBarInfo.searchHintText}`}
        type="text"
        name="keyword"
        id="keyword"
        value={textVal}
        style={{ width: '100%', marginTop: '7px' }}
        onChange={handleSearchInputChange}
        InputProps={{
          style: {
            fontSize: '18px',
            fontWeight: '500'
          },
          startAdornment: <SearchIcon style={{ color: colors.darkerGrey }} />
        }}
        sx={{
          '& .MuiInputBase-root:before, & .MuiInput-root:before': {
            borderBottom: `1px solid ${colors.borderGrey}`
          }
        }}
      />

      <p className="omni-segment-title" style={{ marginTop: '38px' }}>
        My {title}
      </p>
      <div className="omni-cell-container">
        {mySegment.length === 0 && <p>{`No ${searchBarInfo.name} to show`}</p>}
        {mySegment.map((e) => {
          return (
            <DisplayCell
              {...e}
              key={e.id}
              handleListItemClick={handleListItemClick}
              handleDeleteButtonClick={handleDeleteButtonClick}
            />
          );
        })}
      </div>
      {/* <hr className="omni-divider-gap " /> */}
      <p className="omni-segment-title" style={{ marginTop: '157px', marginBottom: '37px' }}>
        Team {title}
      </p>

      <div className="omni-cell-container">
        {teamSegment.length === 0 && <p>{`No ${searchBarInfo.name} to show`}</p>}
        {teamSegment.map((e) => {
          return (
            <DisplayCell
              {...e}
              key={e.id}
              handleListItemClick={handleListItemClick}
              handleDeleteButtonClick={handleDeleteButtonClick}
            />
          );
        })}
      </div>
    </>
  );
};

export default ListSegmentComponent;
