import ForecastsPlansPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/ForecastsPlansPage';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';

const getForecastsPlansLayout = () => {
  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    widgets: [
      {
        name: 'forecastsPlansLayout',
        CustomComponent: ForecastsPlansPage,
        view: {}
      }
    ],
    containerStyle: {},
    dataConfig: {}
  };
};

export default getForecastsPlansLayout;
