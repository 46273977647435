import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { OmniDonutData } from 'src/components/Omni/OmniDonutChart/OmniDonutUtil';
import { withRouter } from 'react-router';
import { useAsyncHook } from 'src/utils/Hooks/typedHooks';
import { DonutChart } from 'src/components/Charts/Donut';
import { fetchOmniDonutGroup } from 'src/components/EntityPage/Content/OmniContentAccuracyUtil';
import Loading from 'src/components/common/Loading';
import { defaultWidgetWrapperStyle } from 'src/components/Layout/LayoutUtil';
import ReduxStore from 'src/types/store/reduxStore';
import { useDispatch, useSelector } from 'react-redux';
import './ContentAccuracy.scss';
import { Widget } from 'src/types/application/widgetTypes';
import { addFilterToOmniBaseReqBody, OmniBaseRequestBody } from 'src/components/Omni/omniRequestUtils';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import _orderBy from 'lodash/orderBy';
import _isArray from 'lodash/isArray';
import { destructurePathName } from 'src/utils/urlParsing';
import { fetchEntity } from 'src/store/modules/entityService/operations';

interface OmniContentAccuracyProps extends RouteComponentProps {
  widget: Widget;
  eventBus: EventBus;
}

enum DonutType {
  PERCENT = 'percent',
  FRACTION = 'fraction'
}
interface OmniDonutGroupProps {
  pathInfo: string;
  donutType: DonutType;
  retailerUsed: number;
}

export interface OmniMainEntityMetricsDonut {
  currencySymbol: string;
  data: OmniDonutData[];
  title: number;
  titleColor: string;
  name: string;
}

const DonutGroup: FC<OmniDonutGroupProps> = ({ pathInfo, retailerUsed }) => {
  const { data, loading, run } = useAsyncHook<OmniMainEntityMetricsDonut[]>([]);
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const filters = useSelector((state: ReduxStore) => state.filters);
  const { mainEntity } = useSelector((state: ReduxStore) => state.entityService);
  const [entityType] = destructurePathName(pathInfo);
  const { startWeek, endWeek } = mainTimePeriod;
  const widget = {
    view: {
      chartPropsOverride: {
        chart: {
          type: 'pie',
          height: 200,
          width: 250
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            },
            size: 200,
            startAngle: -130,
            endAngle: 130,
            center: ['50%', '63%'],
            innerSize: 172
          }
        },
        tooltip: {
          enabled: false
        },
        disablePointSelect: true,
        enableMultiSelect: false,
        titleFontSize: '32px',
        showFullValue: false
      }
    }
  };

  useEffect(() => {
    const baseRequestBody: OmniBaseRequestBody = {
      startWeekId: startWeek,
      endWeekId: endWeek,
      retailerIds: [],
      includeBrandIds: [],
      includeCategoryIds: [],
      includeSubCategoryIds: [],
      productIds: [],
      groupBy: 'beaconClientId'
    };
    const requestBody = addFilterToOmniBaseReqBody(baseRequestBody, filters, pathInfo, mainEntity.query);

    if (entityType === 'product') {
      requestBody.retailerIds = [retailerUsed];
    }
    run(fetchOmniDonutGroup(requestBody));
  }, [startWeek, endWeek, pathInfo, filters, retailerUsed]);

  return (
    <div className="accuracy__scores">
      {data
        ? data.map((d, index) => {
            const [firstData] = d.data;
            const dataInWidget = {
              mainMetric: {
                metricType: 'PERCENT'
              },
              secondaryMetric: {
                displayName: firstData.name
              }
            };

            const newWidget = { ...widget, data: dataInWidget };
            return loading ? (
              <Loading className="spinner" />
            ) : (
              <div key={index} style={{ flex: '1 1 0%' }}>
                <DonutChart widget={newWidget} mainEntityMetrics={d} retailer={retailer} />
              </div>
            );
          })
        : null}
    </div>
  );
};

export const determineTheDefault = (
  filters: ReduxStore['filters'],
  omniRetailer: ReduxStore['omniRetailers']['data'],
  pathname: string
) => {
  const sortedRetailer = _orderBy(omniRetailer, 'retailerId', 'asc');

  let defaultRetailer = sortedRetailer.length > 0 ? sortedRetailer[0].retailerId : '0';

  const [entityType, entityId] = destructurePathName(pathname);

  const { retailer } = filters;

  if (_isArray(retailer)) {
    const sortedRetailerInFilter = _orderBy(retailer, '', 'asc');
    const [firstRetailer] = sortedRetailerInFilter;
    defaultRetailer = firstRetailer;
  }
  if (entityType === 'retailer') {
    defaultRetailer = Number(entityId);
  }
  return defaultRetailer;
};

const OmniContentAccuracy: FC<OmniContentAccuracyProps> = ({ location, eventBus }) => {
  const filters = useSelector((state: ReduxStore) => state.filters);
  const omniRetailerState = useSelector((state: ReduxStore) => state.omniRetailers);
  const omniRetailer = omniRetailerState.data;
  const { pathname } = location;
  const defaultRetailer = determineTheDefault(filters, omniRetailer, pathname);
  const [retailerUsed, setRetailerUsed] = useState(defaultRetailer);
  const [entityTypeInUrl, entityId] = destructurePathName(pathname);
  const dispatch = useDispatch();

  const handleRetailerDropDown = ({ retailerSelected }: { retailerSelected: number }) => {
    setRetailerUsed(retailerSelected);
  };
  useEffect(() => {
    eventBus.on('omniDropDownSelected', handleRetailerDropDown);
    return () => {
      eventBus.off('omniDropDownSelected', handleRetailerDropDown);
    };
  }, [eventBus]);

  useEffect(() => {
    setRetailerUsed(defaultRetailer);
  }, [defaultRetailer]);

  // This is used for productSidebarCard - we need to re-fetch it for getting new product metaData.

  useEffect(() => {
    dispatch(fetchEntity('mainEntity', entityTypeInUrl, entityId, retailerUsed));
  }, [dispatch, entityId, entityTypeInUrl, retailerUsed]);

  return (
    <div style={{ ...defaultWidgetWrapperStyle, marginTop: 40, marginBottom: 80 }}>
      <div className="accuracy">
        <div className="accuracy__title">Content Accuracy</div>
        <DonutGroup donutType={DonutType.PERCENT} pathInfo={pathname} retailerUsed={retailerUsed} />
      </div>
    </div>
  );
};

export default withRouter(withBus('eventBus')(OmniContentAccuracy));
