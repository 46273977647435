import types from './types';
import parseIssueTypes from 'src/store/modules/chargeBackTypes/selectors';

const receiveIssueTypes = (chargeBackIssueTypes: ReturnType<typeof parseIssueTypes>) => ({
  type: types.RECEIVE_ALL_CHARGEBACK_ISSUE_TYPES,
  chargeBackIssueTypes
});

const clearIssueTypes = () => ({
  type: types.CLEAR_ALL_CHARGEBACK_ISSUE_TYPES
});

export default {
  receiveIssueTypes,
  clearIssueTypes
};
