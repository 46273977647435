import { useStacklineTheme } from '@stackline/ui';
import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { INTEGRATIONS_ACTION_BOX_HEIGHT } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { RightArrow } from 'src/components/SvgIcons';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

interface IntegrationsActionLink {
  description: string;
  descriptionAction: () => void;
}

interface IntegrationsActionBoxProps {
  icon: React.ReactNode;
  title: string;
  action: IntegrationsActionLink;
}

const IntegrationsActionBox = ({ icon, title, action }: IntegrationsActionBoxProps) => {
  const theme = useStacklineTheme();
  return (
    <Flex
      borderRadius={theme.spacing.sm}
      flexDirection="column"
      border={`1px solid ${theme.colors.primaryGray}`}
      width="100%"
      height={INTEGRATIONS_ACTION_BOX_HEIGHT}
      padding={theme.spacing.md}
      marginBottom="14px"
    >
      <Flex
        width="54px"
        height="54px"
        borderRadius="50%"
        marginBottom={theme.spacing.sm}
        style={{ background: theme.colors.secondaryGhost }}
        justifyContent="center"
        alignItems="center"
      >
        <Flex width="26px">{icon}</Flex>
      </Flex>

      <Flex marginTop={theme.spacing.xs}>
        <Text variant="h6">{title}</Text>
      </Flex>
      <Flex marginTop={theme.spacing.xs} alignItems="center">
        <Text
          variant="body2"
          onClick={action.descriptionAction}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          {action.description}
        </Text>
        <RightArrow style={{ height: '14px', width: '14px', marginLeft: '5px' }} />
      </Flex>
    </Flex>
  );
};

export default IntegrationsActionBox;
