import { IconIncrease, IconNoChange, IconDecrease } from 'src/components/BeaconRedesignComponents/icons';

export type Direction = 'increasing' | 'decreasing' | 'neutral';

export const getDirection = (value = 0): Direction => {
  if (value === 0) {
    return 'neutral';
  }

  if (value > 0) {
    return 'increasing';
  }

  return 'decreasing';
};

export const getColorForMetricChangeValue = (
  value?: number,
  direction?: Direction
): 'secondary' | 'success' | 'error' => {
  if (value === 0 || direction === 'neutral') {
    return 'secondary';
  }

  if (value > 0 || direction === 'increasing') {
    return 'success';
  }

  return 'error';
};

export const getIconForMetricChange = (value?: number, direction?: Direction) => {
  if (value === 0 || direction === 'neutral') {
    return IconNoChange;
  }

  if (value > 0 || direction === 'increasing') {
    return IconIncrease;
  }

  return IconDecrease;
};
