import types from './types';
import parseVendorCodes from 'src/store/modules/vendorCodes/selectors';

const receiveVendorCodes = (vendorCodes: ReturnType<typeof parseVendorCodes>) => ({
  type: types.RECEIVE_ALL_VENDOR_CODES,
  vendorCodes
});

const clearVendorCodes = () => ({
  type: types.CLEAR_ALL_VENDOR_CODES
});

export default {
  receiveVendorCodes,
  clearVendorCodes
};
