import React, { useState, Dispatch, SetStateAction, useContext } from 'react';
import { Text } from '@stackline/ui';
import { useAppSelector } from 'src/utils/Hooks';
import EntityTableHeader from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import {
  ForecastsAdjustmentData,
  handleCsvDownload,
  useForecastsAdjustmentsColumnDefinitions,
  useGenerateCSVData
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import { Box } from '@mui/material';
import { DeleteAdjustmentModal } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/DeleteAdjustmentModal/DeleteAdjustmentModal';
import { useDeleteAdjustment } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ViewAdjustmentModal/utils';
import { AdjustmentModal } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/AdjustmentModal';
import useAdjustmentPolling from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAdjustmentPolling';
import { useAsyncFunction } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAsyncFunction';
import * as serverProxy from './Forecasts/RefactoredAdjustmentModals/serverProxy';
import NoDataPlaceHolder, {
  NoDataPlaceHolderTableRow
} from 'src/components/BeaconRedesignComponents/common/NoDataPlaceHolder/NoDataPlaceHolder';
import { RightArrow } from 'src/components/SvgIcons';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { ForecastAdjustmentsTypeContext } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/ForecastAdjustmentsTypeProvider';
import { useCompleteAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useCompleteAdjustmentData/useCompleteAdjustmentData';
import { byNetImpact } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useCompleteAdjustmentData/utils';

interface ForecastPlanGroupByField {
  name: string;
  displayName: string;
}

const FORECASTS_PLANS_GROUP_BY_FIELDS_FOR_TABLE: ForecastPlanGroupByField[] = [
  { name: 'stacklineSku', displayName: 'Product' },
  { name: 'forecastsPlansAdjustmentsForUser', displayName: 'User' }
];

const ForecastsPlansProductTable = ({
  onRowClick,
  rowData,
  selectedGroupByFieldForTable,
  setSelectedGroupByFieldForTable,
  isLoading
}: {
  onRowClick: any;
  rowData: ForecastsAdjustmentData[];
  selectedGroupByFieldForTable: ForecastPlanGroupByField;
  setSelectedGroupByFieldForTable: Dispatch<SetStateAction<ForecastPlanGroupByField>>;
  isLoading: boolean;
}) => {
  const COL_DEFS = useForecastsAdjustmentsColumnDefinitions(selectedGroupByFieldForTable);
  const { setAdjustmentModalOpen } = useContext(ForecastAdjustmentsTypeContext);
  const { mainEntity } = useAppSelector((state) => state.entityService);
  const { type: entityType } = mainEntity;
  const preExportData = useGenerateCSVData(rowData);
  const isProductEntity = entityType === 'product';
  const formattedExportData = rowData ? preExportData : [];
  // pageSize should be changed when user clicks "View more", so we can hide "View more" row when expanded.
  return (
    <>
      <EntityTableHeader
        prefixTitle="Adjustments by "
        title={isProductEntity ? 'Adjustments' : selectedGroupByFieldForTable.displayName}
        groupByFields={
          isProductEntity ? [FORECASTS_PLANS_GROUP_BY_FIELDS_FOR_TABLE[1]] : FORECASTS_PLANS_GROUP_BY_FIELDS_FOR_TABLE
        }
        handleGroupByChange={({ target }) =>
          setSelectedGroupByFieldForTable(
            FORECASTS_PLANS_GROUP_BY_FIELDS_FOR_TABLE.find((field) => field.name === target.value)
          )
        }
        enabledActions={['ExportCsv']}
        handleCSVExport={() => handleCsvDownload(formattedExportData)}
        // We don't want to show export button when data is not available
        rowData={rowData || []}
      />
      <EntityTable
        onRowClick={onRowClick}
        rowData={rowData || []}
        columnDefs={COL_DEFS}
        shouldModifyColumnDefs={false}
        supportViewMore
        initialPageSize={10}
        isLoading={isLoading}
        appTableProps={{
          noDataPlaceholder: isProductEntity ? (
            <NoDataPlaceHolderTableRow
              title="No adjustments?"
              description=""
              link={
                <Flex alignItems="center" onClick={() => setAdjustmentModalOpen(true)} style={{ cursor: 'pointer' }}>
                  <Text variant="body2">Create a new adjustment</Text>
                  <RightArrow style={{ width: '12px', height: '12px', marginLeft: '2px' }} />
                </Flex>
              }
            />
          ) : (
            <NoDataPlaceHolder />
          )
        }}
      />
    </>
  );
};

/**
 * Adjustments table for the forecasts plans page
 */
const ForecastPlansAdjustmentsTable = () => {
  const { mainEntity } = useAppSelector((state) => state.entityService);
  const { type: entityType } = mainEntity;
  const isProductEntity = entityType === 'product';

  const [selectedGroupByFieldForTable, setSelectedGroupByFieldForTable] = useState(
    isProductEntity ? FORECASTS_PLANS_GROUP_BY_FIELDS_FOR_TABLE[1] : FORECASTS_PLANS_GROUP_BY_FIELDS_FOR_TABLE[0]
  );

  const { deleteAdjustment } = useDeleteAdjustment();

  const { wrappedFunction: editAdjustment } = useAsyncFunction(serverProxy.editAdjustment);

  const { data, isLoading } = useCompleteAdjustmentData();

  const adjustmentRowData = data ? data.sort(byNetImpact) : [];

  /**
   * View & Delete modal states
   */
  const [viewAdjustmentModalOpen, setViewAdjustmentModalOpen] = useState(false);
  const [adjustmentDataForModal, setAdjustmentDataForModal] = useState<ForecastsAdjustmentData>(null);
  const [deleteAdjustmentModalOpen, setDeleteAdjustmentModalOpen] = useState(false);
  const [adjustmentToDelete, setAdjustmentToDelete] = useState<ForecastsAdjustmentData>(null);

  const { setIsPolling } = useAdjustmentPolling();

  const handleAdjustmentRowClick = (row: ForecastsAdjustmentData) => {
    setAdjustmentDataForModal(row);
    setViewAdjustmentModalOpen(true);
  };

  const handleAdjustmentModalClose = () => {
    setViewAdjustmentModalOpen(false);
  };

  const handleDeleteButtonClick = ({ adjustmentData }: { adjustmentData: ForecastsAdjustmentData }) => {
    setViewAdjustmentModalOpen(false);
    setAdjustmentToDelete(adjustmentData);
    setDeleteAdjustmentModalOpen(true);
  };

  const handleDeleteAdjustmentModalClose = () => {
    setDeleteAdjustmentModalOpen(false);
  };

  return (
    <>
      <ForecastsPlansProductTable
        onRowClick={handleAdjustmentRowClick}
        isLoading={isLoading}
        rowData={adjustmentRowData}
        selectedGroupByFieldForTable={selectedGroupByFieldForTable}
        setSelectedGroupByFieldForTable={setSelectedGroupByFieldForTable}
      />
      {/* Delete the modal */}
      {deleteAdjustmentModalOpen && adjustmentToDelete ? (
        <DeleteAdjustmentModal
          adjustmentToDelete={adjustmentToDelete}
          open={deleteAdjustmentModalOpen}
          onClose={handleDeleteAdjustmentModalClose}
          deleteAdjustment={deleteAdjustment}
        />
      ) : null}
      {/* View when you click on a row */}
      {adjustmentDataForModal ? (
        <Box position="absolute">
          <AdjustmentModal
            sendAdjustmentUpdate={editAdjustment}
            stacklineSku={adjustmentDataForModal.stacklineSku}
            retailerSku={adjustmentDataForModal.retailerSku}
            categoryId={adjustmentDataForModal.product.categoryId}
            subcategoryId={adjustmentDataForModal.product.subCategoryId}
            product={adjustmentDataForModal.product}
            adjustmentData={adjustmentDataForModal}
            open={viewAdjustmentModalOpen}
            onClose={handleAdjustmentModalClose}
            handleDelete={() => handleDeleteButtonClick({ adjustmentData: adjustmentDataForModal })}
            setIsPolling={setIsPolling}
          />
        </Box>
      ) : null}
    </>
  );
};

export default ForecastPlansAdjustmentsTable;
