import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import _last from 'lodash/last';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';

import colors from 'src/utils/colors';
import GenericDialog from 'src/components/common/Dialog/GenericDialog';
import UploadAnimation from 'src/components/common/UploadAnimation/UploadAnimation';
import FileUpload from 'src/components/common/Form/FileUpload';
import './BulkUpload.scss';

const styles = {
  body: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 25
  },
  uploadWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: 20,
    minHeight: 38,
    alignItems: 'center'
  },
  content: {
    maxWidth: 400,
    display: 'flex',
    flex: 1,
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center'
  },
  pasteArea: {
    position: 'absolute',
    top: 35,
    zIndex: 1,
    padding: 20,
    marginTop: 5,
    width: 600,
    height: 350,
    background: 'none',
    fontFamily: '"Input", "Oxygen Mono", monospace',
    resize: 'none',
    borderRadius: '10px',
    border: `2px dashed ${colors.comparison}`
  },
  includeExcludeRadioButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    top: '-34px',
    position: 'absolute',
    marginTop: 17,
    marginBottom: 15
  },
  errorMessage: {
    color: colors.red,
    fontWeight: 400,
    paddingTop: 8,
    marginBottom: -5
  },
  draggingStyle: {
    backgroundColor: '#9DF',
    border: '2px solid deepskyblue'
  },
  pasteAreaDraggingStyle: {
    backgroundColor: '#bee9ff'
  },
  radioButton: {
    color: colors.darkBlue
  },
  opacityTransition: {
    transition: 'opacity 0.5s ease-in'
  }
};

const getIdFieldNameAndItemListKey = (uploadType, filterType) =>
  ({
    searchtermlist: {
      phrase: { idFieldName: 'searchTermFuzzy', itemListKey: 'searchTermsFuzzy' },
      exact: { idFieldName: 'searchTerm', itemListKey: 'searchTermFuzzy' },
      exclude: { idFieldName: 'excludedSearchTerm', itemListKey: 'excludedSearchTerms' }
    },
    segment: {
      include: { idFieldName: 'keyword', itemListKey: 'keywords' },
      exact: { idFieldName: 'keywordExact', itemListKey: 'keywords' },
      exclude: { idFieldName: 'excludedKeyword', itemListKey: 'excludedKeywords' },
      phrase: { idFieldName: 'keywordPhrase', itemListKey: 'keywordPhrase' },
      excludeExact : { idFieldName: 'excludedKeyWordsExact', itemListKey: 'excludedKeywords' }
    }
  }[uploadType || 'searchtermlist'][filterType]);

const mapUploadTypeToProps = (uploadType) =>
  ({
    searchtermlist: { title: 'Bulk Keyword Upload', itemType: 'Keyword', longItemType: 'Keyword' },
    segment: { title: 'Bulk SKU Upload', itemType: 'SKU', longItemType: 'Retailer SKU' }
  }[uploadType || 'searchtermlist']);

/**
 * Renders a set of radio buttons for selecting whether to include or exclude the uploaded filters.
 */
export const IncludeExcludeSelect = ({ filterOpt, value, onChange }) => (
  <div style={styles.includeExcludeRadioButtons}>
    {filterOpt.map((name) => {
      let fieldLabel;
      if (['phrase', 'exact'].includes(name)) {
        fieldLabel = `Include (${name})`;
      } else if (['exclude'].includes(name)) {
        fieldLabel = `Exclude (phrase)`;
      } else {
        fieldLabel = _capitalize(name);
      }
      return (
        <Fragment key={name}>
          {fieldLabel}
          <Radio checked={name === value} onChange={() => onChange(name)} label={name} style={styles.radioButton} />
        </Fragment>
      );
    })}
  </div>
);

IncludeExcludeSelect.propTypes = {
  filterOpt: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

/**
 * Asynchronously parses the provided file and reads it into a string, passing any errors along as rejections to
 * the returned `Promise`.
 *
 * @param {File} file The file object selected by the user
 */
export const getFileContent = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      if (evt.target.readyState !== 2) {
        return;
      } else if (evt.target.error) {
        reject(new Error(`Error while reading file: ${evt.target.error.message}`));
        return;
      }

      resolve(evt.target.result);
    };

    reader.readAsText(file);
  });

/**
 * Reads the content of the selected file or uses the content of the textarea to create a list of filter items
 * to be added to the advanced search.  Can throw an error if the provided file cannot be read.
 *
 * @param {string} pasteContent The content of the `textarea`
 * @param {File} selectedFile The file selected by the user to be loaded as a single-column CSV
 */
export const parseUploadedItems = async (pasteContent, selectedFile) => {
  const rawContent = _isEmpty(pasteContent) ? await getFileContent(selectedFile) : pasteContent;
  return rawContent
    .match(/[^\r\n]+/g) // handle windows line endings as well as normal line endings
    .map((item) => item.trim())
    .filter((item) => !_isEmpty(item));
};

/**
 * Returns `undefined` if the provided file is valid for use in the bulk upload.  Returns an error message if it is
 * invalid and cannot be used for the bulk upload.
 *
 * @param {File} file The file that was selected by the user
 */
export const validateFile = (file, optionString = ['csv', 'txt']) => {
  const extension = _last(file.name.split('.'));

  if (!['csv', 'txt'].includes(extension)) {
    return `Invalid file type.  You must supply a ${optionString.join(', ')} file.`;
  }
  return undefined;
};

export const validateFileSize = (file, maxSize) => {
  const fileSize = _get(file, maxSize);

  if (fileSize >= maxSize) {
    return `Invalid file size. You mush supply a file that is less than ${fileSize}.`;
  }

  return undefined;
};

export const TextureBackgroundArea = ({
  pasteContent,
  itemType,
  isUploaded,
  setAnimationCallbacks,
  setErrorMsg,
  setSelectedFile,
  enlarge,
  shrink,
  onlyFileUpload,
  fileTitle,
  customClass
}) => (
  <div className={`bulk_textarea_background ${customClass}`}>
    {onlyFileUpload ? null : (
      <span style={styles.opacityTransition} className={pasteContent ? 'bulk_upload_hide' : undefined}>
        <span>Paste {itemType}s here or</span>
      </span>
    )}
    <span style={styles.opacityTransition} className={pasteContent ? 'bulk_upload_hide' : undefined}>
      <label htmlFor="file-upload" onMouseEnter={enlarge} onMouseLeave={shrink} className="pick_file">
        {fileTitle || 'Upload CSV?'}
      </label>
      <input
        id="file-upload"
        type="file"
        onChange={(evt) => {
          const file = evt.target.files[0];
          const errMsg = validateFile(file);
          if (errMsg) {
            setErrorMsg(errMsg);
          } else {
            setSelectedFile(file);
          }
        }}
      />{' '}
    </span>
    <UploadAnimation
      className={`bulk_upload_clip ${!pasteContent || isUploaded ? '' : 'bulk_upload_hide'}`}
      registerCallbacks={setAnimationCallbacks}
      style={styles.opacityTransition}
    />
  </div>
);

TextureBackgroundArea.propTypes = {
  pasteContent: PropTypes.any.isRequired,
  itemType: PropTypes.string.isRequired,
  isUploaded: PropTypes.bool.isRequired,
  setAnimationCallbacks: PropTypes.func.isRequired,
  setErrorMsg: PropTypes.func.isRequired,
  setSelectedFile: PropTypes.func.isRequired,
  onlyFileUpload: PropTypes.bool,
  fileTitle: PropTypes.string,
  enlarge: PropTypes.func,
  shrink: PropTypes.func,
  customClass: PropTypes.string
};

TextureBackgroundArea.defaultProps = {
  enlarge: null,
  shrink: null,
  fileTitle: null,
  onlyFileUpload: false,
  customClass: ''
};

export const UploadWrapper = ({
  pasteContent,
  setSelectedFile,
  selectedFile,
  downloadButtonIsDisabled,
  setIsUploaded,
  upload,
  handleSubmit,
  isUploaded
}) => (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    {!pasteContent ? (
      <div style={{ flex: 1, flexBasis: 500 }}>
        <FileUpload
          accept=".csv, .txt"
          onChange={(evt) => setSelectedFile(evt.target.files[0])}
          label={_get(selectedFile, 'name')}
          uploadLabel="Submit"
        />
      </div>
    ) : null}
    <div style={{ flex: 1 }}>
      <Button
        style={{ background: downloadButtonIsDisabled ? undefined : colors.darkBlue, boxShadow: 'none', color: '#fff' }}
        variant="contained"
        disabled={downloadButtonIsDisabled}
        onClick={() => {
          setIsUploaded(true);
          upload(() => handleSubmit());
        }}
      >
        {isUploaded ? 'Submitted!' : 'Submit'}
      </Button>
    </div>
  </div>
);

UploadWrapper.propTypes = {
  pasteContent: PropTypes.any.isRequired,
  setSelectedFile: PropTypes.func.isRequired,
  selectedFile: PropTypes.object,
  downloadButtonIsDisabled: PropTypes.bool.isRequired,
  setIsUploaded: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isUploaded: PropTypes.bool.isRequired
};

UploadWrapper.defaultProps = {
  selectedFile: null
};

export const TextContent = ({ longItemType }) => (
  <>
    Paste a list of {longItemType}s into the dialog box below or upload a CSV file containing a single list of{' '}
    {longItemType}s. Files should contain {longItemType}s only, one per line, with no commas or other formatting.
    <br />
    <br />
    NOTE: There is a maximum limit of 500 {longItemType}s.
  </>
);

TextContent.propTypes = {
  longItemType: PropTypes.string.isRequired
};

const getFilterOpt = (uploadType, isAtlasDev) => {
  if (_isNil(uploadType)) {
    return null;
  }

  if (isAtlasDev && uploadType === 'segment') {
    return ['phrase', 'exact', 'exclude', 'excludeExact'];
  }

  return uploadType === 'segment' ? ['phrase', 'exclude'] : ['phrase', 'exact', 'exclude'];
};

/**
 * Displays a dialog containing a textarea and a file upload input for adding many filters to the advanced search
 * at once.  It will only be displayed when the `open` prop is `true`.  The `uploadType`, `handleTermFilters`, and
 * `itemLists` props are passed in from the parent `AdvancedSearchSideBar` component.
 */
const BulkUploadDialog = ({
  open,
  isAtlasDev,
  onClose,
  uploadType,
  handleTermFilters,
  itemLists,
  hideRadioButtons,
  appName
}) => {
  const [selectedFile, setSelectedFileInner] = useState(undefined);
  const [isUploaded, setIsUploaded] = useState(false);
  const [pasteContent, setPasteContent] = useState('');
  const [filterType, setFilterType] = useState(
    // when in the dev and uploadType is segment, the filterType should be phrase or it will cause no radio button select in the bulkUpload.
    uploadType === 'segment' && isAtlasDev ? 'phrase' : uploadType === 'segment' ? 'phrase' : 'phrase'
  );
  const [errorMsg, setErrorMsg] = useState(null);
  const [draggingCount, setDraggingCount] = useState(0);
  const [{ enlarge, shrink, upload }, setAnimationCallbacks] = useState({});

  const setSelectedFile = (file) => {
    if (errorMsg) {
      setErrorMsg(null);
    }
    setSelectedFileInner(file);
  };

  const { title, itemType, longItemType } = mapUploadTypeToProps(uploadType);
  const downloadButtonIsDisabled = (!selectedFile && _isEmpty(pasteContent)) || isUploaded;

  const handleClose = () => {
    setSelectedFile(undefined);
    setIsUploaded(false);

    onClose();
  };

  const handleSubmit = async (file = selectedFile) => {
    setIsUploaded(true);
    const { idFieldName, itemListKey } = getIdFieldNameAndItemListKey(uploadType, filterType, appName);
    const existingItems = itemLists[itemListKey] || [];

    try {
      const newItems = await parseUploadedItems(pasteContent, file);
      handleTermFilters({
        key: idFieldName,
        values: [...existingItems, ...newItems.map((item) => ({ i: item }))].slice(0, 500)
      });
    } catch (err) {
      setErrorMsg(err.message);
      setIsUploaded(false);
    }
  };

  const eventHandlers = {
    onDrop: (evt) => {
      setErrorMsg(undefined);
      evt.preventDefault();
      evt.stopPropagation();

      const file = evt.dataTransfer.files[0];
      const errMsg = validateFile(file);
      if (errMsg) {
        shrink();
        setErrorMsg(errMsg);
        return;
      }

      setSelectedFile(evt.dataTransfer.files[0]);
      setIsUploaded(true);
      upload(() => handleSubmit(file));

      setPasteContent('');
      setDraggingCount(0);
    },
    onDragOver: (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
    },
    onDragStart: () => {},
    onDragLeave: () => {
      if (draggingCount === 1) {
        shrink();
      }
      setDraggingCount(draggingCount - 1);
    },
    onDragEnter: () => {
      if (draggingCount === 0) {
        enlarge();
      }
      setDraggingCount(draggingCount + 1);
    },
    onDragExit: shrink
  };

  const filterOpt = getFilterOpt(uploadType, isAtlasDev);
  return (
    <GenericDialog
      open={open}
      onClose={handleClose}
      textContent={<TextContent longItemType={longItemType} />}
      title={title}
      style={{ minHeight: 680 }}
      {...eventHandlers}
    >
      <div style={styles.body}>
        <div style={styles.content}>
          {filterOpt && !hideRadioButtons ? (
            <IncludeExcludeSelect filterOpt={filterOpt} value={filterType} onChange={setFilterType} />
          ) : null}
          <textarea
            className="no-outline"
            value={pasteContent}
            onChange={(e) => {
              if (selectedFile) {
                setSelectedFile(null);
              }

              setPasteContent(e.target.value);
            }}
            style={styles.pasteArea}
          />

          <TextureBackgroundArea
            pasteContent={pasteContent}
            itemType={itemType}
            isUploaded={isUploaded}
            setAnimationCallbacks={setAnimationCallbacks}
            setErrorMsg={setErrorMsg}
            setSelectedFile={setSelectedFile}
            enlarge={enlarge}
            shrink={shrink}
          />
          <div style={styles.uploadWrapper}>
            {selectedFile || pasteContent ? (
              <UploadWrapper
                pasteContent={pasteContent}
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
                downloadButtonIsDisabled={downloadButtonIsDisabled}
                setIsUploaded={setIsUploaded}
                upload={upload}
                handleSubmit={handleSubmit}
                isUploaded={isUploaded}
              />
            ) : null}
            <div style={styles.errorMessage}>{errorMsg}</div>
          </div>
        </div>
      </div>
    </GenericDialog>
  );
};

BulkUploadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  /** Indicates whether this upload is for keywords or ASINs */
  uploadType: PropTypes.string,
  /** This is the method of the same name on `AdvancedSearchSideBar` */
  handleTermFilters: PropTypes.func.isRequired,
  /**
   * This is `this.state` from `AdvancedSearchSideBar`.  It is index into using the key returned from the
   * `getIdFieldNameAndItemListKey` function in order to retrieve the current list of filters.
   */
  itemLists: PropTypes.object,
  hideRadioButtons: PropTypes.bool,
  appName: PropTypes.string.isRequired,
  isAtlasDev: PropTypes.bool.isRequired
};

BulkUploadDialog.defaultProps = {
  uploadType: null,
  itemLists: {},
  hideRadioButtons: false
};

export default connect(({ app }) => ({ appName: app.name, isAtlasDev: app.name === 'atlas' && app.stage === 'dev' }))(
  BulkUploadDialog
);
