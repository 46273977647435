import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import _pick from 'lodash/pick';
import { AppName } from 'sl-api-connector/types';
import { entityOperations } from 'src/store/modules/entityService';
import Loading from 'src/components/common/Loading';
import { combineFilterConditions } from 'src/utils/filters';
import { EntityPageInner, Props as EPIProps } from 'src/components/EntityPage/EntityPage';
import { adCampaignOperations } from 'src/store/modules/adManager/adCampaigns';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import ReduxStore from 'src/types/store/reduxStore';
import { omniRetailersOperations } from 'src/store/modules/omni/omniRetailers';
import 'src/components/EntityPage/EntityPage.scss';

import { ActivatedActionCreators } from 'src/types/utils';

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['app', 'retailer', 'user', 'categories', 'subCategories', 'filters', 'entityService', 'segments']);

const mapDispatchToProps = {
  fetchOmniRetailers: omniRetailersOperations.fetchOmniRetailers,
  fetchMainEntity: entityOperations.fetchMainEntity,
  fetchElasticSearchAdCampaigns: adCampaignOperations.fetchElasticSearchAdCampaigns
};

type Props = RouteComponentProps &
  EntityPageInner &
  ReturnType<typeof mapStateToProps> &
  ActivatedActionCreators<typeof mapDispatchToProps>;

class HomePageContainer extends EntityPageInner<Props> {
  private fetchMainEntity = () => {
    const sortCategories = !this.props.location.search.includes('tab=summary&subtab=beacon&entityType=retailer');
    return this.props.fetchMainEntity(
      'client',
      -1,
      this.props.retailer.id,
      this.props.history.push.bind(this.props.history),
      sortCategories
    );
  };

  public componentDidMount() {
    this.fetchMainEntity();
    if (this.props.app.name === AppName.Beacon) {
      // fetch all campaigns to build the campaign summary page
      this.props.fetchElasticSearchAdCampaigns();
    }
    if (this.props.app.name === AppName.Omni) {
      this.props.fetchOmniRetailers();
    }
  }

  public componentWillReceiveProps(nextProps: Props & EPIProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.fetchMainEntity();
    }
  }

  public render() {
    const { app, filters, categories, subCategories, segments, retailer } = this.props;
    if (!filters) {
      return <Loading style={{ position: 'fixed' }} className="spinner" size={150} thickness={2} />;
    }

    const { conditions, aggregationConditions } = combineFilterConditions(
      app,
      filters,
      categories,
      subCategories,
      segments,
      retailer
    );

    return <GenericPageContainer conditions={conditions} aggregationConditions={aggregationConditions} />;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePageContainer));
