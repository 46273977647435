import Typography, { TypographyProps } from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { StacklineColor, StacklineOpacity, StacklineTextVariant, useStacklineTheme } from '@stackline/ui';
import React, { useMemo } from 'react';

export type TransformType = 'none' | 'uppercase';
export interface TextProps extends Pick<TypographyProps, 'textAlign'> {
  color?: StacklineColor | 'white';
  children: React.ReactNode;
  variant?: StacklineTextVariant;
  opacity?: StacklineOpacity;
  transform?: TransformType;
  /**
   * True if the text should have link-styles.
   * In order to navigate to a page, implement the
   * `onClick` callback.
   */
  link?: boolean;
  /**
   * Callback for when the text is clicked.
   */
  onClick?: () => void;
  /**
   * Title to appear when hovering over the text.
   * Useful when it is truncated.
   */
  title?: string;
  /**
   * Number of lines before the text should be
   * truncated with ellipses.
   */
  truncateLines?: number;
  inline?: boolean;
  underlined?: boolean;
  fontWeight?: string | number;
  sx?: React.ComponentProps<typeof Typography>['sx'];
}

const transformClasses: { [key in TransformType]: SxProps } = {
  none: {},
  uppercase: {
    textTransform: 'uppercase',
    letterSpacing: '0.96px'
  }
};

/**
 * Classes for when the text should appear
 * as a link
 */
const linkClasses: SxProps = {
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
};

/**
 * Styles for when the text should be truncated after
 * a given number of lines.
 */
const getTruncateStyles = (truncateLines: number): SxProps => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: truncateLines,
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

/**
 * The base text component to be used throughout the site. Anywhere text appears,
 * it should be wrapped in this component with the appropriate props.
 */
export const Text = ({
  color = 'primary',
  children,
  variant = 'body1',
  opacity = 100,
  transform = 'none',
  link,
  onClick,
  title,
  truncateLines,
  textAlign,
  inline,
  underlined,
  fontWeight,
  sx
}: TextProps) => {
  const theme = useStacklineTheme();

  const style = useMemo(() => {
    let obj = {
      color: color !== 'white' ? theme.colors[color] : 'white',
      opacity: opacity / 100,
      fontFamily: theme.text.fontFamily,
      lineHeight: 'normal',
      display: inline ? 'inline' : undefined,
      textDecoration: underlined ? 'underline' : undefined,
      '-webkit-font-smoothing': 'antialiased',
      ...(truncateLines ? getTruncateStyles(truncateLines) : {}),
      ...(link ? linkClasses : {}),
      ...(transformClasses[transform] as any),
      ...theme.text.variants[variant]
    } as React.ComponentProps<typeof Typography>['sx'];

    if (fontWeight) {
      // @ts-ignore
      obj.fontWeight = fontWeight;
    }

    if (sx) {
      // @ts-ignore
      obj = { ...obj, ...sx };
    }

    return obj;
  }, [
    color,
    fontWeight,
    inline,
    link,
    opacity,
    sx,
    theme.colors,
    theme.text.fontFamily,
    theme.text.variants,
    transform,
    truncateLines,
    underlined,
    variant
  ]);

  return (
    <Typography title={title} textAlign={textAlign} sx={style} onClick={onClick}>
      {children}
    </Typography>
  );
};
