import { useQuery } from 'react-query';
import { AdWalmartService } from 'src/components/AdManager/walmartUtils';
import { useAppSelector } from 'src/utils/Hooks';
import _get from 'lodash/get';

interface QueryConfig {
  retry: number;
  staleTime: number;
  cacheTime: number;
  refetchOnWindowFocus: boolean;
  refetchOnMount: boolean;
}

const queryConfig: QueryConfig = {
  retry: 3,
  staleTime: 60000 * 2, // 2 min
  cacheTime: 60000 * 10,
  refetchOnWindowFocus: false,
  refetchOnMount: false
};

interface UseAdGetMediaDataParams {
  mediaId?: number | null;
  qryConfig?: QueryConfig;
  beaconClientLoginId?: number;
}

function useAdGetMediaData({ mediaId = null, qryConfig = null, beaconClientLoginId = null }: UseAdGetMediaDataParams) {
  const _beaconClientLoginId = useAppSelector((state) =>
    _get(state, 'adCampaignBuilder.platformSettings.entity.extendedAttributes.beaconClientLoginId', beaconClientLoginId)
  );

  const { data, isLoading, ...rest } = useQuery(
    ['AdWalmartService_getMedia', _beaconClientLoginId, mediaId],
    () =>
      _beaconClientLoginId !== null
        ? AdWalmartService.getMedia({ headers: null, beaconClientLoginId: _beaconClientLoginId, mediaId })
        : Promise.resolve(null),
    { ...queryConfig, ...qryConfig }
  );

  return { data, isLoading, ...rest };
}

export default useAdGetMediaData;
