import React from 'react';

interface CustomPlayButtonWrapperProps {
  outerStyle: React.CSSProperties;
  children: JSX.Element;
  handleClick: () => void;
}

const CustomPlayButtonWrapper: React.FC<CustomPlayButtonWrapperProps> = ({
  outerStyle,
  children,
  handleClick
}: CustomPlayButtonWrapperProps) => {
  return (
    <div role="button" style={outerStyle} onClick={handleClick}>
      {children}
    </div>
  );
};

export default CustomPlayButtonWrapper;
