/**
 * A version of `TrendChart` that use two level tabs to control the field
 */

import React from 'react';

import GenericChart from 'src/components/Charts/GenericChart';
import { TrendChartInner, enhanceTrendChart } from './TrendChart';
import { CustomLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';

class DoubleIndicatorTrendChart extends TrendChartInner {
  componentDidMount() {
    super.componentDidMount.call(this);
  }

  renderTrendChart() {
    const chartParams = super.getChartParams.call(this);
    if (!chartParams) {
      return <CustomLoading height={400} width={1280} padding={1} />;
    }

    return (
      <>
        <GenericChart {...chartParams} />
      </>
    );
  }

  render = () => {
    let zIndex = 2;
    if (!this.shouldShowLoading()) {
      const chartParams = super.getChartParams.call(this);
      if (chartParams) {
        zIndex = 0;
      }
    }
    return (
      <div
        id={this.props.widget.name}
        style={{ zIndex, height: '100%', width: '100%', position: 'absolute', top: 0, left: 0 }}
      >
        {this.shouldShowLoading() ? <CustomLoading height={400} width={1280} padding={1} /> : this.renderTrendChart()}
      </div>
    );
  };
}

const EnhancedDoubleIndicatorTrendChart = enhanceTrendChart(DoubleIndicatorTrendChart);

export default EnhancedDoubleIndicatorTrendChart;
