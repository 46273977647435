/* eslint-disable react/require-default-props */
import { Widget } from 'src/types/application/widgetTypes';
import SearchResultsGridV3, { SearchResultsGridV3Props } from './SearchResultsGridV3';
import React, { useCallback } from 'react';
import useSrgData from './hooks/useSrgData';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import { Location } from 'history';
import EntityColumn from 'src/components/AdManager/Search/CustomColumns/EntityColumn';
import CampaignPortfolioColumn from 'src/components/AdManager/Search/CustomColumns/CampaignPortfolioColumn';
import BudgetColumn from 'src/components/AdManager/Search/CustomColumns/BudgetColumn';
import useFetchCampaignData from './hooks/useFetchCampaignData';
import OutOfBudgetHoursColumn from 'src/components/AdManager/Search/CustomColumns/OutOfBudgetHours';
import useScheduledActionTermFilters from 'src/components/AdManager/Search/hooks/useScheduledActionTermFilters';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { TermFilter } from 'sl-api-connector';
import { ColDef } from 'ag-grid-community';
import { useDispatch } from 'react-redux';
import entitySearchServiceActions from 'src/store/modules/entitySearchService/actions';
import './CampaignsGrid.scss';
import { shouldFetchAllSRGRows, shouldShowCriteo, isCriteo } from 'src/utils/app';
import { isEditScheduledActionPage } from 'src/components/AdManager/Search/searchUtils';
import { getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';

interface CampaignsGridProps {
  widget: Widget;
  location: Location;
}
/**
 * Display a list of Campaigns.
 */
export const CampaignsGridInner: React.FC<CampaignsGridProps> = (props) => {
  const columns = [
    {
      headerName: 'Portfolio',
      field: 'portfolioId',
      width: undefined,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important' },
      minWidth: 200,
      maxWidth: 200,
      cellRendererFramework: CampaignPortfolioColumn,
      headerClass: 'align-left',
      pinned: 'left'
    },
    {
      headerName: 'Budget',
      field: 'projectedSpend',
      width: undefined,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important', 'padding-right': '20px' },
      minWidth: 150,
      maxWidth: 150,
      cellRendererFramework: BudgetColumn,
      headerClass: 'align-left',
      pinnedRowCellRenderer: 'emptyColumn',
      pinned: 'left'
    }
  ];
  const additionalColumns = [];

  if (!isCriteo()) {
    additionalColumns.push(...columns);
  }

  return isEditScheduledActionPage(props.location.search) ? (
    <ScheduledActionsCampaignsGrid {...props} />
  ) : (
    <EntityCampaignsGrid {...props} additionalColumns={additionalColumns} />
  );
};

// Campaigns grid for scheduled actions page
const ScheduledActionsCampaignsGrid = (props: CampaignsGridProps) => {
  const { scheduledActionTermFilters, loading, defaultSelectAll } = useScheduledActionTermFilters({
    location: props.location
  });
  const dispatch = useDispatch();

  /**
   * After campaigns for a scheduled action have been fetched, automatically
   * select them by updating Redux.
   */
  const onFetchedData = useCallback(
    async ({ gridResult }) => {
      if (defaultSelectAll && gridResult.data) {
        const allIds = gridResult.data.map((item) => item.id);
        await dispatch(
          entitySearchServiceActions.receiveEntitySalesMetrics(
            `${props.widget.data.statePropertyName}_selectedMapping`,
            allIds
          )
        );
      }
    },
    [defaultSelectAll, props.widget.data.statePropertyName, dispatch]
  );

  if (loading) {
    return <GridLoading />;
  }

  return (
    <EntityCampaignsGrid
      {...props}
      additionalTermFilters={scheduledActionTermFilters}
      onFetchedData={onFetchedData}
      togglable={false}
      allowSelectAll={false}
      headerClasses="scheduled-action-campaign"
    />
  );
};

// Generic campaigns grid
const EntityCampaignsGrid = ({
  location,
  widget,
  additionalTermFilters,
  additionalColumns,
  onFetchedData,
  headerClasses,
  queryParams,
  ...otherProps
}: CampaignsGridProps & {
  additionalTermFilters?: TermFilter[];
  additionalColumns?: ColDef[];
  /**
   * Additional classes to be appended to the 'Campaigns' header
   */
  headerClasses?: string;
  /** Optional callback for when the data has been fetched */
  onFetchedData?: ({ gridResult }) => void | Promise<void>;
} & Partial<SearchResultsGridV3Props<any>>) => {
  const parentPlatform = getParentPlatform();
  const isCriteoPP = shouldShowCriteo() && parentPlatform === PARENT_PLATFORMS.CRITEO;
  let importantOverrides = [];

  if (isCriteoPP) {
    importantOverrides = [
      {
        action: 'add',
        path: ['conditions', 'termFilters'],
        newObj: {
          fieldName: 'parentPlatform',
          condition: 'must',
          values: [parentPlatform]
        }
      },
      {
        action: 'add',
        path: ['aggregations', '[0]', 'conditions', 'termFilters'],
        newObj: {
          fieldName: 'parentPlatform',
          condition: 'must',
          values: [parentPlatform]
        }
      }
    ];
    // for all retailers remove rid
    if (queryParams.rid === '0') {
      importantOverrides = [
        ...importantOverrides,
        {
          action: 'remove',
          path: ['aggregations', '[0]', 'conditions', 'termFilters'],
          conditionKey: 'fieldName',
          conditionValue: 'retailerId'
        },
        {
          action: 'remove',
          path: ['conditions', 'termFilters'],
          conditionKey: 'fieldName',
          conditionValue: 'retailerId'
        },
        {
          action: 'update',
          path: ['retailerId'],
          newObj: 999999
        }
      ];
    }
  }

  const fetchCampaignData = useFetchCampaignData(importantOverrides);

  const { canEdit, ...rest } = useSrgData({
    widget,
    location,
    fetchEntityData: fetchCampaignData,
    additionalTermFilters: additionalTermFilters || [],
    onFetchedData,
    metadataRowOptions: shouldFetchAllSRGRows(widget.data.entityType, widget.data.groupByFieldName)
      ? {
          getEntityId: (row) => row.campaignId,
          name: 'campaignName',
          type: 'adCampaign'
        }
      : undefined,
    fetchTotalRowCountAmsMetadataType: 'adCampaign',
    importantOverrides
  });

  /**
   * Returns an additional "OOB Hour" column for the budget constraints page
   */
  const getOutOfBudgetColumn = () => {
    const isBudgetConstraintsPage = new URLSearchParams(location.search).get('subtab') === 'budgetConstraints';

    return isBudgetConstraintsPage
      ? [
          {
            headerName: 'OOB Hour',
            field: 'campaignId',
            width: undefined,
            enableRtl: false,
            cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important' },
            minWidth: 110,
            maxWidth: 110,
            cellRendererFramework: OutOfBudgetHoursColumn,
            headerClass: 'align-left',
            isOptional: true,
            pinned: 'left'
          }
        ]
      : [];
  };

  return (
    <SearchResultsGridV3
      editDisabled={isCriteoPP}
      selectable={canEdit && !isCriteoPP}
      togglable={canEdit}
      columnDefs={[
        {
          headerName: 'Campaign',
          field: 'campaignId',
          width: undefined,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          minWidth: 250,
          maxWidth: 800,
          cellRendererFramework: EntityColumn,
          headerClass: `align-left ${headerClasses || ''}`,
          pinnedRowCellRenderer: 'simpleColumn',
          pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
          pinned: 'left'
        },
        ...(additionalColumns || []),
        ...getOutOfBudgetColumn()
      ]}
      getRowNodeId={(row) => row.id}
      widget={widget}
      {...rest}
      {...otherProps}
    />
  );
};

export default withRouter(withBus('eventBus')(CampaignsGridInner));
