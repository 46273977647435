import React from 'react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'qs';
import Truncate from 'react-truncate';

import {
  AdPolicingSuspended,
  Ineligible,
  MissingDecoration,
  MissingImage,
  NotBuyable,
  MissingBuybox
} from 'src/types/application/types';
import colors from 'src/utils/colors';
import 'src/components/Grids/Data/ColumnTypes/Entity.scss';
import ReduxStore from 'src/types/store/reduxStore';
import moment from 'moment-timezone';
import './CampaignColumn.scss';
import { getProductImageUrl } from 'src/utils/image';
import { generateProductLink } from 'src/utils/searchLinks';
import { isDrive, shouldShowCriteo } from 'src/utils/app';
import { availableRetailers } from 'src/store/modules/retailer/reducers';

const styles: { [key: string]: React.CSSProperties } = {
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  textLink: {
    fontSize: 14,
    whiteSpace: 'pre-wrap',
    lineHeight: '1.5em',
    maxHeight: '3em',
    overflow: 'hidden'
  },
  imageWrapper: {
    alignSelf: 'center',
    minWidth: 45,
    textAlign: 'center',
    marginRight: 10
  },
  brandIcon: {
    stroke: colors.lightGrey,
    strokeWidth: 13,
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  imageWrapperInner: {
    width: 45,
    display: 'table'
  },
  imageWrapperInnerInner: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  image: {
    maxWidth: 35,
    maxHeight: 35
  },
  auto: {
    border: `1px solid ${colors.orange}`,
    borderRadius: '4px',
    padding: '0 4px',
    color: colors.orange
  }
};

const shouldShowProductStatus = (displayName: string) => {
  return [AdPolicingSuspended, Ineligible, MissingDecoration, MissingImage, NotBuyable, MissingBuybox].find(
    (status) => status.displayName === displayName
  );
};

const mapStateToProps = ({ app, adCampaigns }: ReduxStore) => ({ app, adCampaigns });

type EntityColumnProps = {
  data?: { [key: string]: any };
  url?: string;
  style?: React.CSSProperties;
  value?: { [key: string]: any };
  getLinkOverride?: (props: {
    type: string;
    id: string | number;
    searchParams: string;
    parsedAdditionalParameters: { [key: string]: any };
  }) => string;
} & ReturnType<typeof mapStateToProps>;

interface EntityColumnState {
  value?: EntityColumnProps['value'];
  imageUri?: string | null;
  didImageLoadError?: boolean;
}

class EntityColumn extends React.Component<EntityColumnProps, EntityColumnState> {
  public static defaultProps = {
    data: {},
    value: {},
    style: {},
    url: undefined,
    getLinkOverride: undefined
  };

  public static propTypes = {
    data: (props: EntityColumnProps, _propName: string, componentName: string) => {
      if (!props.data && !props.value) {
        return new Error(`One of props 'data' or 'url' was not specified in '${componentName}'.`);
      }
      return null;
    },
    value: (props: EntityColumnProps, _propName: string, componentName: string) => {
      if (!props.data && !props.value) {
        return new Error(`One of props 'url' or 'data' was not specified in '${componentName}'.`);
      }
      return null;
    },
    style: PropTypes.object,
    app: PropTypes.object.isRequired,
    url: PropTypes.string,
    getLinkOverride: PropTypes.func
  };

  public state: EntityColumnState = {};

  public componentWillMount() {
    const { value, data } = this.props;
    const val = value && value.name ? value : data;
    this.setState({
      value: val,
      imageUri:
        val && val.entity && val.entity.type === 'product' && val.entity.stacklineSku
          ? getProductImageUrl(val.entity.stacklineSku.toString())
          : null
    });
  }

  private stopPropagation = (evt: { stopPropagation: Function }) => evt.stopPropagation();

  private getValue = () => {
    if (!this.state.value) {
      return null;
    } else if (this.state.value.type) {
      return this.state.value;
    } else if (this.state.value.entity) {
      return this.state.value.entity;
    } else {
      return this.state.value;
    }
  };

  private handleImageLoadingError = () =>
    this.setState({
      imageUri: 'https://placehold.it/50x50'
    });

  private showImageElement({ value }: { value: any }) {
    const { imageUri } = this.state;
    if (!value || value.type !== 'product') {
      return null;
    }

    return (
      <img onError={this.handleImageLoadingError} alt={value.type} style={styles.image} src={imageUri || undefined} />
    );
  }

  private renderImage({ value }: { value: any }) {
    const { searchParams, additionalParams } = this.props.app.queryParams;
    const image = (
      <div style={styles.imageWrapperInner}>
        <div style={styles.imageWrapperInnerInner}>{this.showImageElement({ value })}</div>
      </div>
    );

    /**
     * The following logic fixes an issue in Drive where the Product Image is not a valid link to the product summary page.
     * Because this component was copied to other files under the same name, it is applied there as well. See: Entity.tsx
     */
    let link: string;
    const entityType = value.type || _get(value, 'entity.type', '');
    let uniqueValueId = value.id;
    if (entityType === 'product') {
      uniqueValueId = uniqueValueId.toUpperCase();
    }

    if (isDrive && entityType === 'product') {
      link = `/${entityType}/${uniqueValueId}${searchParams}`;
    } else {
      link = `/${entityType}/${uniqueValueId}${searchParams}${additionalParams}`;
    }

    return (
      <div style={styles.imageWrapper}>
        {link ? (
          <Link onClick={this.stopPropagation} to={link}>
            {image}
          </Link>
        ) : (
          image
        )}
      </div>
    );
  }

  private showEntityData() {
    const {
      app: {
        defaultQueryParams,
        queryParams: { searchParams, additionalParams }
      },
      adCampaigns
    } = this.props;
    const parsedAdditionalParameters = queryString.parse(additionalParams);
    if (!parsedAdditionalParameters.tab) {
      parsedAdditionalParameters.tab = defaultQueryParams.tab;
    }
    if (!parsedAdditionalParameters.subtab) {
      parsedAdditionalParameters.subtab = defaultQueryParams.subtab;
    }
    const value = this.getValue();

    if (!value.type) {
      return (
        <div style={styles.textLink} className={value.isBold ? 'bold' : undefined}>
          {value.name}
        </div>
      );
    }

    if (shouldShowCriteo()) {
      const pageTypeValue = _get(value, ['pageType']);
      if (pageTypeValue && pageTypeValue !== 'search') {
        const pageTypeDisplayLabel = `pageType = "${pageTypeValue}"`;
        value.name = pageTypeDisplayLabel;
        value.targetingText = pageTypeDisplayLabel;
      }
    }

    const textLinkType = value.type;
    const entityId = value.id;
    if (parsedAdditionalParameters.tab) {
      parsedAdditionalParameters.tab = 'adManager';
    }
    if (parsedAdditionalParameters.subtab) {
      parsedAdditionalParameters.subtab = 'keyMetrics';
    }
    if (value.type === 'adCampaign') {
      const link = `/${textLinkType}/${value.campaignId}${searchParams}&${queryString.stringify(
        parsedAdditionalParameters
      )}`;
      if (_get(value, ['extendedAttributes', 'statusDisplay'])) {
        const {
          endDate,
          extendedAttributes: {
            statusDisplay: { displayName: statusName, color: statusColor },
            campaignTypeDisplayName
          }
        } = value;
        let endDateText = '';
        if (endDate) {
          endDateText = `Ends ${moment(endDate).format('MM/DD/YY')}`;
        }
        return (
          <Link
            style={{ display: 'block', height: '100%', textDecoration: 'none' }}
            onClick={this.stopPropagation}
            target="_blank"
            to={link}
          >
            <div className="campaign_column" title={value.name}>
              <Truncate lines={1} ellipsis="...">
                {value.name}
              </Truncate>
              <div className="type_pill">{campaignTypeDisplayName}</div>
              <div style={{ color: statusColor }}>{statusName}</div>
              <div>{endDateText}</div>
            </div>
          </Link>
        );
      }

      const adCampaign = adCampaigns.find((x) => x.campaignId === value.campaignId);

      if (adCampaign) {
        const {
          endDate,
          extendedAttributes: {
            statusDisplay: { displayName: statusName, color: statusColor },
            campaignTypeDisplayName
          }
        } = adCampaign;
        let endDateText = '';
        if (endDate) {
          endDateText = `Ends ${moment(endDate).format('MM/DD/YY')}`;
        }
        return (
          <Link
            style={{ display: 'block', height: '100%', textDecoration: 'none' }}
            onClick={this.stopPropagation}
            target="_blank"
            to={link}
          >
            <div className="campaign_column" title={value.name}>
              <Truncate lines={2} ellipsis="...">
                {value.name || _get(value, 'campaignName', '')}
              </Truncate>
              <div className="type_pill">{campaignTypeDisplayName}</div>
              <div style={{ color: statusColor }}>{statusName}</div>
              <div>{endDateText}</div>
            </div>
          </Link>
        );
      }
    }
    if (value.type === 'adTarget') {
      const link = `/${textLinkType}/${entityId}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`;

      if (value && value.extendedAttributes && value.extendedAttributes.statusDisplay) {
        const {
          extendedAttributes: {
            // statusDisplay: { displayName: statusName, color: statusColor },
            campaignIds
          }
        } = value;
        return (
          <Link
            style={{ display: 'block', height: '100%', textDecoration: 'none' }}
            onClick={this.stopPropagation}
            target="_blank"
            to={link}
          >
            <div className="campaign_column" title={value.name}>
              <div className="title">
                {value.targetingText} {value.isAutoAdded === '1' && <span style={styles.auto}>Auto</span>}
              </div>
              <div className="secondary_label">{campaignIds.length} campaigns</div>
            </div>
          </Link>
        );
      }
    }
    if (value.type === 'adGroup') {
      const link = `/${textLinkType}/${entityId}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`;
      return (
        <Link target="_blank" style={{ display: 'block' }} onClick={this.stopPropagation} to={link}>
          <div style={styles.textLink} className={value.isBold ? 'bold' : undefined} title={value.name}>
            {value.name}
          </div>
        </Link>
      );
    }
    if (value.type === 'retailer' && shouldShowCriteo()) {
      const parsedQuery = queryString.parse(searchParams);
      delete parsedQuery['?rid'];
      delete parsedQuery.rid;
      parsedQuery.rid = value.id;

      const link = `/overview?${queryString.stringify(parsedQuery)}&${queryString.stringify(
        parsedAdditionalParameters
      )}`;

      const getRetailerName = (rid) => {
        const getRetailer = availableRetailers.find((e) => e.id === String(rid));
        return _get(getRetailer, 'displayName', `Retailer ${rid}`);
      };
      const retailerName = getRetailerName(value.retailerId || value.id);
      return (
        <Link target="_blank" style={{ display: 'block' }} onClick={this.stopPropagation} to={link}>
          <div style={styles.textLink} className={value.isBold ? 'bold' : undefined} title={retailerName}>
            {retailerName}
          </div>
        </Link>
      );
    }
    if (value.type === 'product') {
      const link = `/${textLinkType}/${entityId.toUpperCase()}${searchParams}&${queryString.stringify(
        parsedAdditionalParameters
      )}`;
      if (value && value.extendedAttributes && value.extendedAttributes.statusDisplay) {
        const {
          extendedAttributes: {
            statusDisplay: { displayName: statusName, color: statusColor },
            campaignIds
          }
        } = value;
        return (
          <div style={{ display: 'flex', ...this.props.style }}>
            {this.renderImage({ value })}
            <div style={{ flexDirection: 'row' }}>
              <Link target="_blank" style={{ display: 'block' }} onClick={this.stopPropagation} to={link}>
                <div style={styles.textLink} className={value.isBold ? 'bold' : undefined} title={value.name}>
                  {value.name}
                </div>
              </Link>
              {value && value.retailerIds && value.retailerSku && (
                <div className="secondary_label">
                  {campaignIds.length} campaigns //{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="secondary_label"
                    href={generateProductLink(value.retailerIds[0], value.retailerSku)}
                  >
                    {value.retailerSku}
                  </a>
                </div>
              )}
              {shouldShowProductStatus(statusName) && <div style={{ color: statusColor }}>{statusName}</div>}
            </div>
          </div>
        );
      } else {
        return (
          <div style={{ display: 'flex', ...this.props.style }}>
            {this.renderImage({ value })}
            <Link target="_blank" style={{ display: 'block' }} onClick={this.stopPropagation} to={link}>
              <div style={styles.textLink} className={value.isBold ? 'bold' : undefined} title={value.name}>
                {value.name}
              </div>
            </Link>
          </div>
        );
      }
    }
    {
      const link = `/${textLinkType}/${entityId}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`;

      return (
        <Link target="_blank" style={{ display: 'block' }} onClick={this.stopPropagation} to={link}>
          <div style={styles.textLink} className={value.isBold ? 'bold' : undefined} title={value.name}>
            {value.name}
          </div>
        </Link>
      );
    }
  }

  public render() {
    return (
      <div style={{ display: 'flex', ...this.props.style }}>
        <div style={styles.text}>{this.showEntityData()}</div>
      </div>
    );
  }
}

const EnhancedCampaignColumn = connect(mapStateToProps)(EntityColumn);

export default EnhancedCampaignColumn;
