import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { addQueryParams } from '../../../utils/browser';
import { ENTITIES, INDEX_FIELDS } from '../../../utils/entityDefinitions';
import { comparisonParams, filterParams } from '../../../utils/segments';
import EntityGrid from '../../EntityGrid';
import { CompareProductListLoading } from '../../common/Loading/PlaceHolderLoading/PlaceHolderLoading';

const buildProductGridWidget = (app) => ({
  CustomComponent: EntityGrid,
  view: {
    name: 'entityGrid',
    gridOptions: {
      hideGridHeader: true,
      defaultLayout: 'compactList',
      enableSwitchingLayouts: false,
      enableExport: false
    }
  },
  data: {
    groupByFields: [INDEX_FIELDS.getField(app.name, 'sales', 'stacklineSku', 'product')],
    configByGroupByFieldName: {
      stacklineSku: {
        indexName: 'sales',
        entity: ENTITIES[app.name].product,
        mainMetricField: INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', 'product', 'stacklineSku'),
        aggregationFields: [INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', 'product', 'stacklineSku')]
      }
    }
  }
});

class Product extends React.Component {
  static defaultProps = {
    togglePanel: () => {}
  };

  static propTypes = {
    app: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    aggregationConditions: PropTypes.object.isRequired,
    conditions: PropTypes.object.isRequired,
    togglePanel: PropTypes.func
  };

  static contextTypes = {
    router: PropTypes.object
  };

  handleSelectionChange = (selection) => {
    const { togglePanel, retailer, mainTimePeriod, location } = this.props;
    togglePanel();
    const existingParams = addQueryParams(location, retailer, mainTimePeriod, [...filterParams, ...comparisonParams]);
    this.context.router.history.push(`?ctype=product&cid=${selection.id.toUpperCase()}&${existingParams}`);
  };

  render() {
    const { app, aggregationConditions, conditions } = this.props;

    const productGridWidget = buildProductGridWidget(app);

    return (
      <Box>
        <div>
          <EntityGrid
            queryConditions={conditions}
            aggregationConditions={aggregationConditions}
            onSelectItem={this.handleSelectionChange}
            widget={productGridWidget}
            CustomLoading={CompareProductListLoading}
          />
        </div>
      </Box>
    );
  }
}

export default withRouter(connect((state) => state)(Product));
