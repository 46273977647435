/**
 * Custom styled checkbox based off of MUI's
 */
/* eslint-disable react/prop-types */
import React from 'react';
import Radio from '@mui/material/Radio';
import CustomizedCheckbox from 'src/components/common/CustomizedCheckBox/CustomizedCheckBox';

import withStyles from '@mui/styles/withStyles';

import colors from 'src/utils/colors';
import { withProps } from 'src/utils/hoc';

import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from 'src/components/SvgIcons';
import './Checkbox.scss';

const styles = {
  root: {
    padding: '0px 12px',
    color: colors.blue,
    '&$checked': {
      color: colors.blue
    }
  },
  checked: {},
  colorSecondary: {
    '&&&&&:hover': {
      backgroundColor: '#fff'
    }
  }
};

const CustomCheckbox = (props) => {
  return (
    <>
      <CustomizedCheckbox
        styleObj={{
          width: 24,
          height: 24,
          iconBackgroundColor: 'transparent',
          iconColor: 'transparent',
          checkedIconBackgroundColor: 'transparent',
          checkedIconColor: 'transparent',
          paddingRight: '32px'
        }}
        InnerIcon={CheckboxCheckedIcon}
        OuterIcon={CheckboxUncheckedIcon}
        checkboxProps={{
          checked: props.checked,
          disabled: props.disabled,
          onChange: (evt) => {
            props.onChange(evt, evt.target.checked);
          }
        }}
      />
    </>
  );
};

export const AdCampaignBuilderCheckbox = CustomCheckbox;

export const AdCampaignBuilderRadio = withStyles(styles)(withProps({ disableRipple: true })(Radio));
