/**
 * This file is for defining common selectors that can be used across the app.
 */

import { useAppSelector } from 'src/utils/Hooks/typedHooks';
import _get from 'lodash/get';
import ReduxStore from 'src/types/store/reduxStore';

/**
 * Return a combined list of user created segments and team segments
 */
export const useAllSegments = () => {
  return useAppSelector((state) => [...state.segments.mySegments, ...state.segments.teamSegments]);
};

/**
 * Get the main entity type
 */
export const useMainEntityType = () => {
  return useAppSelector((state) => {
    return _get(state, ['entityService', 'mainEntity', 'type'], null);
  });
};

/**
 * Get the main entity name
 */
export const useMainEntityName = () => {
  return useAppSelector((state) => state?.entityService?.mainEntity?.name);
};

/**
 * Get the user id
 */
export const useUserId = () => {
  return useAppSelector((state) => _get(state, ['user', 'session', 'userId']));
};

/**
 * Get the retailer id
 */
export const useRetailerId = () => {
  return useAppSelector((state) => _get(state, ['retailer', 'id']));
};

/**
 * Get the retailer display name
 */
export const useRetailerDisplayName = () => {
  const retailerId = useRetailerId();
  const availableRetailers = useAppSelector((state) => state.retailer.availableRetailers);
  const retailers = availableRetailers;
  const retailerObj = retailers.find((r) => r.id === retailerId);
  if (retailerObj) {
    return retailerObj.displayName;
  }

  // Default, return retailer Id if no match found
  return retailerId;
};

/**
 * Get the beacon client id]
 */
export const useBeaconClientId = () => {
  return useAppSelector((state) => _get(state, ['user', 'config', 'vendor', 'BeaconClientId']));
};

/**
 * Returns a boolean indicating if the main entity is a product and the product entity if it exists
 */
export const useProductEntity = (): {
  productEntity: ReduxStore['entityService']['mainEntity'] | null;
  isProductEntity: boolean;
} => {
  return useAppSelector((state) => {
    if (_get(state, ['entityService', 'mainEntity', 'type']) === 'product') {
      return { productEntity: _get(state, ['entityService', 'mainEntity'], null), isProductEntity: true };
    }

    return { productEntity: null, isProductEntity: false };
  });
};

/**
 * When on the topic view, gets the name of the company
 * that owns the topic
 */
export const useTopicCompanyName = (): string => {
  const companyName = useAppSelector((state) => state?.entityService?.mainEntity?.ownerProfile?.company);

  return companyName;
};
