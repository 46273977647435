export const convertToPx = (value: number) => `${value}px`;

export const BEACON_PRO_CENTER_WIDTH = '1288px';

export const BEACON_PRO_PAGE_MARGIN_BOTTOM = '80px';

const BEACON_PRO_RIGHT_CONTENT_WIDTH_raw = 1040;
export const BEACON_PRO_RIGHT_CONTENT_WIDTH = convertToPx(BEACON_PRO_RIGHT_CONTENT_WIDTH_raw);

export const BEACON_PRO_SIDEBAR_WIDTH = '185px';
export const BEACON_PRO_SIDEBAR_IMAGE_WIDTH = '165px'; // Intentionally smaller, adjusted with lindsey
export const BEACON_PRO_SIDEBAR_ITEM_HEIGHT = '48px';
export const BEACON_PRO_SIDEBAR_IMAGE_MAX_HEIGHT = '95px';
export const BEACON_PRO_SIDEBAR_RIGHT_PADDING = '63px';

// Key metric cards
export const BEACON_PRO_CARD_WIDTH = '336px';
export const BEACON_PRO_LARGE_CARD_HEIGHT = '173px';

// Brand Card
export const BEACON_PRO_ENTITY_CARD_WIDTH = '304px';
export const BEACON_PRO_ENTITY_CARD_HEIGHT = '486px';

export const PRODUCT_CARD_WIDTH = '242px';
export const PRODUCT_CARD_HEIGHT = '302px';

export const FORECAST_KEY_METRICS_CARD_WIDTH = '242px';

export const KEY_METRICS_CONTAINER_ADJUSTED_WIDTH = convertToPx(BEACON_PRO_RIGHT_CONTENT_WIDTH_raw + 16);

// e.g. Granola -> Gr tile
export const ENTITY_CARD_PERIODIC_TABLE_SIZE = '38px';

export const BEACON_SIDEBAR_FILTER_WIDTH = 200;
export const BEACON_SIDEBAR_FILTER_SCROLL_CONTAINER_WIDTH = 224;

export const BEACON_SIDEBAR_FILTER_CONTAINER_WIDTH = '224px';

/**
 * Default number of pixels between Beacon pro chart visualizations
 */
export const BEACON_CHART_SPACING = 104;

/**
 * Spacing between key metric summary cards and next visualization
 */
export const BEACON_KEY_METRIC_CARD_SPACING = 80;

/**
 * Default spacing for Settings -> Integrations each tab's Action box
 */
export const INTEGRATIONS_ACTION_BOX_HEIGHT = '142px';

/**
 * Many grids have a default top spacing of 32, so this is the spacing between the top of the grid and the next visualization
 */
export const BEACON_GRID_TOP_SPACING = BEACON_CHART_SPACING - 32;
/*
  Approach:

  - enforce width
  - do not rely on contents to set the width
  - hide any overflow (or scroll?)

*/
