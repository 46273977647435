import React from 'react';
import './Popups.scss';
import _get from 'lodash/get';
import { store } from 'src/main';
import Conversation from '!svg-react-loader!./Asset/Conversation.svg'; // eslint-disable-line

const AddNewEntityPopup = () => {
  const { app } = store.getState();
  const suportEmail = _get(app, ['config', 'domainConfig', 'customerSupport', 'email'], 'support@stackline.com');

  return (
    <div className="popup_container add_new_entity">
      <div className="image">
        <Conversation />
      </div>
      <div className="label">Want to add a new entity?</div>
      <div className="detail">
        Please contact our customer success team to get assistance by emailing{' '}
        <a href={`mailto:${suportEmail}`}>{suportEmail}</a>
      </div>
    </div>
  );
};

export const popupMapping = { AddNewEntityPopup };
