import moment from 'moment';
import React from 'react';
import { promotionTypeToDisplayName } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/Promotions/constants';
import { PromotionProductRow } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/Promotions/types';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import { getWeekColumnDefinition } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import { getColorForMetricChangeValue } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import { useMetricFormatter } from 'src/utils/Hooks';
import { calculatePercentChange } from 'src/utils/app';
import { getStartAndEndOfWeek } from 'src/utils/dateUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';

export const PromotionsByWeekTable = () => {
  const formatMetric = useMetricFormatter();

  return (
    <MetricListContainer
      fields={[
        { name: 'retailSales', displayName: 'Retail Sales', displayInTable: false },
        { name: 'retailSalesPrev', displayName: 'Old Sales', displayInTable: false },
        { name: 'retailPrice', displayName: 'Retail Price', displayInTable: false },
        { name: 'retailPricePrev', displayName: 'Old Price', displayInTable: false }
      ]}
      iconList={[]}
      postProcessData={(data) => {
        return data.sort((a, b) => b.weekId.localeCompare(a.weekId));
      }}
      showGrid={false}
      entityTableHeaderProps={{
        title: 'Promotions by Week'
      }}
      buildRequest={(request: AdvancedSearchRequestBuilder) => {
        request.modifyAggregation((aggregation) => {
          aggregation.setSortByAggregationFieldAsNull();
          aggregation.setSortDirection(null);
          return aggregation;
        });

        return request;
      }}
      useTableDataProps={{
        aggregationKey: 'stacklineSku',
        itemsPerPage: 100
      }}
      sortFieldName="retailSales"
      groupBy="stacklineSku,weekId,promoType"
      indexName={BEACON_INDEX_NAMES.PROMOTIONS}
      headColumnDefinition={[
        getWeekColumnDefinition({
          getTableColumnProps(defaults) {
            return {
              ...defaults,
              style: {
                ...defaults.style,
                width: '220px'
              }
            };
          }
        })
      ]}
      additionalColumnDefs={[
        {
          headerName: 'Promotions',
          tableColumnProps: {
            style: {
              width: '280px'
            }
          },
          valueFormatter: (_, row: PromotionProductRow) => {
            const promotionType = row?.promoType;
            const displayName = promotionTypeToDisplayName[promotionType] || promotionType;
            const { startDate } = getStartAndEndOfWeek(row?.weekId);
            const formattedDate = moment(startDate, 'YYYYMMDD').format('MMMM - D - YYYY');

            return (
              <Flex flexDirection="column">
                <Flex sx={{ justifyContent: 'flex-start' }}>
                  <Text variant="body2">{displayName}</Text>
                </Flex>
                <Flex sx={{ justifyContent: 'flex-start' }}>
                  <Text variant="body2">{formattedDate}</Text>
                </Flex>
              </Flex>
            );
          }
        },
        {
          headerName: 'Discount',
          tableColumnProps: {
            style: {
              width: '220px'
            }
          },
          valueFormatter: (_, row: PromotionProductRow) => {
            const promotionPrice = row?.retailPrice?.value ?? 0;
            const originalPrice = row?.retailPricePrev?.value ?? 0;
            const percentChange = calculatePercentChange(promotionPrice, originalPrice);

            return (
              <Flex flexDirection="column">
                <Flex gap={8} sx={{ justifyContent: 'flex-start' }}>
                  <Flex>
                    <Text variant="body2">{formatMetric(promotionPrice, METRICTYPE.MONEY)}</Text>
                  </Flex>
                  <Flex>
                    <Text variant="body2" color={getColorForMetricChangeValue(percentChange)}>
                      {formatMetric(percentChange, METRICTYPE.PERCENT, {
                        showFullValue: true,
                        decimalPlaces: 0
                      })}
                    </Text>
                  </Flex>
                </Flex>
                <Flex sx={{ justifyContent: 'flex-start' }}>
                  <Text variant="body2">{`from ${formatMetric(originalPrice, METRICTYPE.MONEY)}`}</Text>
                </Flex>
              </Flex>
            );
          }
        },
        {
          headerName: 'Sales Lift',
          tableColumnProps: {
            style: {
              width: '180px'
            }
          },
          valueFormatter: (_, row: PromotionProductRow) => {
            const promotionSales = row?.retailSales?.value ?? 0;
            const originalSales = row?.retailSalesPrev?.value ?? 0;
            const percentChange = calculatePercentChange(promotionSales, originalSales);
            const increaseSign = percentChange > 0 ? '+' : '';
            return (
              <Flex flexDirection="column">
                <Flex gap={8} sx={{ justifyContent: 'flex-start' }}>
                  <Flex>
                    <Text variant="body2">
                      {formatMetric(promotionSales, METRICTYPE.MONEY, { showFullValue: false })}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text variant="body2" color={getColorForMetricChangeValue(percentChange)}>
                      {increaseSign}
                      {formatMetric(percentChange, METRICTYPE.PERCENT, {
                        showFullValue: true,
                        decimalPlaces: 1
                      })}
                    </Text>
                  </Flex>
                </Flex>
                <Flex sx={{ justifyContent: 'flex-start' }}>
                  <Text variant="body2">
                    {`from ${formatMetric(originalSales, METRICTYPE.MONEY, {
                      showFullValue: false
                    })}`}
                  </Text>
                </Flex>
              </Flex>
            );
          }
        }
      ]}
    />
  );
};
