import React, { useEffect, useMemo, useState } from 'react';
import EntityTableHeader, {
  EntityTableHeaderProps,
  IconsList
} from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';
import { GRID_ITEMS_PER_PAGE, ProductGridProps } from '../ProductGrid/ProductGrid';
import { SlColumn, SlRow, useStacklineTheme } from '@stackline/ui';
import Pagination from 'src/components/BeaconRedesignComponents/Pagination/Pagination';
import { useTableData, UseTableDataProps } from 'src/serverProxy/useTableData';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { calculatePercentChange, getAppName } from 'src/utils/app';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import { Field } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy';
import MetricCell from 'src/components/BeaconRedesignComponents/MetricCell/MetricCell';
import { GenericNavigationTabs } from 'src/components/BeaconRedesignComponents/SubtabNav/SubtabNavigation';
import _get from 'lodash/get';
import { ColDef } from 'ag-grid-community';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import { useAppSelector } from 'src/utils/Hooks';
import { useProductEntity } from 'src/utils/Hooks/reduxSelectors';

export const FETCH_CHUNK_SIZE = 100;

export interface HeadColumnDefinition {
  headerName: string;
  valueFormatter: (value: unknown, row: unknown) => JSX.Element;
}

export interface MetricListContainerField extends Field {
  /**
   * If false, will still fetch the metric but will not show
   * it as a column in the table view
   */
  displayInTable?: boolean;
}

export interface MetricListContainerProps {
  indexName: string;
  /**
   * How the response will be sorted
   */
  sortFieldName: string;
  mainFieldName?: string;
  fields: MetricListContainerField[];
  entityTableHeaderProps: Omit<
    EntityTableHeaderProps,
    'enableSwitchingLayouts' | 'defaultView' | 'verticalInset' | 'handleChangeLayout' | 'eventBus'
  >;
  showGrid?: boolean;
  showHeader?: boolean;
  suffixOverride?: string;
  useTableDataProps?: Partial<Omit<UseTableDataProps, 'fields' | 'indexName'>>;
  headColumnDefinition: HeadColumnDefinition | HeadColumnDefinition[];
  loading?: boolean;
  rowCount?: number;
  renderGrid?: (args: Pick<ProductGridProps, 'loading' | 'metricType' | 'page' | 'data' | 'fieldName'>) => JSX.Element;
  showSortOptions?: boolean;
  groupBy?: string;
  additionalColumnDefs?: HeadColumnDefinition[];
  iconList?: IconsList[];
  skeletonRows?: number;
  columnDefinitions?: ColDef[];
  buildRequest?: (requestBuilder: AdvancedSearchRequestBuilder) => AdvancedSearchRequestBuilder;
  postProcessData?: (data: any[]) => any[];
  onChangePage?: (args: {
    page: number;
    /**
     * Page sent to advanced search, not the one displayed.
     * For example, we could fetch 100 items at at time but only display 20
     * in the grid, so if the page is 6, the adjusted page would be 2.
     *
     * It will be calculated as Math.ceil(page / PAGES_PER_CHUNK)
     */
    chunkedPage: number;
  }) => void;
}

export enum GridSortOptions {
  TopSelling = 'topSelling',
  Increasing = 'increasing',
  Decreasing = 'decreasing'
}

/**
 * Smart container for rendering a grid. It can switch between the grid
 * and table, and displays the pagination
 *
 * TODO Add dropdown options
 */
const MetricListContainer = ({
  indexName,
  sortFieldName,
  mainFieldName,
  fields,
  entityTableHeaderProps,
  showGrid = true,
  showHeader = true,
  suffixOverride,
  useTableDataProps,
  headColumnDefinition,
  loading,
  rowCount,
  renderGrid,
  showSortOptions,
  groupBy = 'stacklineSku',
  additionalColumnDefs = [],
  iconList = [IconsList.export, IconsList.tile, IconsList.table],
  skeletonRows,
  columnDefinitions,
  buildRequest,
  postProcessData,
  onChangePage
}: MetricListContainerProps) => {
  const { isProductEntity } = useProductEntity();

  const PAGES_PER_CHUNK = useMemo(
    () => Math.ceil((_get(useTableDataProps, 'itemsPerPage') || FETCH_CHUNK_SIZE) / GRID_ITEMS_PER_PAGE),
    [useTableDataProps]
  );
  const { startWeek, endWeek } = useAppSelector((state) => state.mainTimePeriod);
  const { startWeek: comparisonStartWeek, endWeek: comparisonEndWeek } = useAppSelector(
    (state) => state.comparisonTimePeriod
  );
  const [sortOption, setSortOption] = useState<GridSortOptions>(GridSortOptions.TopSelling);
  const [page, setPage] = useState(1);
  const [listView, setListView] = useState<'tile' | 'table'>(showGrid && !isProductEntity ? 'tile' : 'table');
  const theme = useStacklineTheme();
  const [dataByPageNumber, setDataByPageNumber] = useState({} as { [key: number]: any[] });

  useEffect(() => {
    if (!showGrid) {
      setListView('table');
    }
  }, [showGrid]);

  const iconsList = useMemo(() => {
    if (showGrid) {
      return iconList;
    }
    return iconList.filter((icon) => icon !== IconsList.tile);
  }, [iconList, showGrid]);

  const handleChangeLayout = () => {
    if (showGrid) {
      setListView((oldView) => (oldView === 'table' ? 'tile' : 'table'));
    }
  };

  const {
    isLoading: tableRowsLoading,
    getValuesByFieldName,
    getValueDifferencesByFieldName,
    data: tableRows
  } = useTableData<{ metadata: any }>({
    fields: [...fields],
    groupByFieldName: groupBy,
    indexName,
    requestId: 'product-grid',
    itemsPerPage: FETCH_CHUNK_SIZE,
    pageNumber: Math.ceil(page / PAGES_PER_CHUNK),
    sortFieldName,
    suffixOverride,
    buildRequest,
    ...useTableDataProps
  });

  const tableData = useMemo(() => {
    const fieldValuesMap = fields.reduce((acc, { name }) => {
      return {
        ...acc,
        [name]: {
          values: getValuesByFieldName(name),
          differences: getValueDifferencesByFieldName(name)
        }
      };
    }, {} as { values: number[]; differences: number[] });

    return tableRows.map((row, index) => ({
      ...row.metadata,
      ...fields.reduce((acc, { name }) => {
        return {
          ...acc,
          [name]: {
            value: fieldValuesMap[name].values[index],
            difference: fieldValuesMap[name].differences[index]
          }
        };
      }, {} as { [key: string]: number }),
      responseRow: row
    }));
  }, [fields, getValueDifferencesByFieldName, getValuesByFieldName, tableRows]);

  // Hide the pagination controls if we have less than the max amount of grid items per page on the first page of results.
  // This does not handle the edge case where we could have the exact amount of grid items per page.
  const hidePagination =
    (rowCount < GRID_ITEMS_PER_PAGE || (tableData || []).length < GRID_ITEMS_PER_PAGE) && page === 1 && !loading;

  // TODO get rid of this. Instead, track `hasMoreDocuments` and stop fetching when it's false
  const totalPages = Math.max(
    Math.ceil((rowCount !== undefined ? rowCount : tableRows.length) / GRID_ITEMS_PER_PAGE),
    1
  ); // Always show page 1 of 1 if no results exist

  /**
   * Updates the full data set so that we can sort
   * by increasing or decreasing
   */
  useEffect(() => {
    setDataByPageNumber((oldData) => ({
      ...oldData,
      [Math.ceil(page / PAGES_PER_CHUNK)]: tableData
    }));
  }, [tableData, page, PAGES_PER_CHUNK]);

  /**
   * The full table data, sorted by the sort option.
   */
  const fullTableData = useMemo(() => {
    const data = Object.keys(dataByPageNumber)
      .sort((a, b) => Number(a) - Number(b))
      .reduce((acc, pageNumber) => {
        return [...acc, ...dataByPageNumber[Number(pageNumber)]];
      }, [])
      .sort((a, b) => {
        switch (sortOption) {
          case GridSortOptions.TopSelling:
            return b[sortFieldName].value - a[sortFieldName].value;
          case GridSortOptions.Increasing:
            return b[sortFieldName].difference - a[sortFieldName].difference;
          case GridSortOptions.Decreasing:
            return a[sortFieldName].difference - b[sortFieldName].difference;
          default:
            return 0;
        }
      })
      .filter((d) => {
        const { difference } = d[mainFieldName || sortFieldName];

        if (sortOption === GridSortOptions.Increasing) {
          return difference >= 0;
        } else if (sortOption === GridSortOptions.Decreasing) {
          return difference <= 0;
        }

        return true;
      });

    if (postProcessData) {
      return postProcessData(data);
    }
    return data;
  }, [dataByPageNumber, mainFieldName, postProcessData, sortFieldName, sortOption]);

  const field = useMemo(
    () => INDEX_FIELDS.getField(getAppName(), indexName, mainFieldName || sortFieldName),
    [indexName, mainFieldName, sortFieldName]
  );

  /**
   * Returns the page number we are using to fetch. For example, if we show 20 items
   * per page and fetch 100 items, then page 1-5 will be 1, page 6-10 will be 2, etc.
   */
  const computeChunkedPage = (displayedPage: number) => {
    return Math.ceil(displayedPage / PAGES_PER_CHUNK);
  };

  const handleNextPage = () => {
    const adjustedPage = ((page - 1) % PAGES_PER_CHUNK) + 1;
    const startIndex = (adjustedPage - 1) * GRID_ITEMS_PER_PAGE;
    const endIndex = startIndex + GRID_ITEMS_PER_PAGE;

    if (tableRows.slice(startIndex, endIndex).length < GRID_ITEMS_PER_PAGE) {
      return;
    }

    const newPage = page + 1;
    setPage(newPage);

    if (onChangePage) {
      onChangePage({
        page: newPage,
        chunkedPage: computeChunkedPage(newPage)
      });
    }
  };

  // go to the first page when sortOption changes or the time period options change
  useEffect(() => {
    setPage(1);
  }, [sortOption, startWeek, endWeek, comparisonStartWeek, comparisonEndWeek]);

  const handlePrevPage = () => {
    const newPage = Math.max(1, page - 1);
    setPage(newPage);

    if (onChangePage) {
      onChangePage({
        page: newPage,
        chunkedPage: computeChunkedPage(newPage)
      });
    }
  };

  return (
    <SlColumn widths="full" spacing={showSortOptions ? 'none' : 'lg'}>
      {showHeader ? (
        <EntityTableHeader
          enableSwitchingLayouts
          defaultView={listView}
          handleChangeLayout={handleChangeLayout}
          iconsList={iconsList}
          paddingTop="0px"
          paddingBottom="0px"
          {...entityTableHeaderProps}
          handleGroupByChange={(event) => {
            setPage(1);
            setDataByPageNumber({});
            if (entityTableHeaderProps.handleGroupByChange) {
              entityTableHeaderProps.handleGroupByChange(event);
            }
          }}
        />
      ) : null}
      {showSortOptions && (
        <GenericNavigationTabs
          tab={sortOption}
          tabs={[
            {
              label: 'Top-Selling',
              value: GridSortOptions.TopSelling,
              onClick: () => setSortOption(GridSortOptions.TopSelling)
            },
            {
              label: 'Increasing',
              value: GridSortOptions.Increasing,
              onClick: () => setSortOption(GridSortOptions.Increasing)
            },
            {
              label: 'Decreasing',
              value: GridSortOptions.Decreasing,
              onClick: () => setSortOption(GridSortOptions.Decreasing)
            }
          ]}
          tabsStyle={{
            paddingTop: theme.spacing.md,
            paddingBottom: theme.spacing.lg
          }}
        />
      )}
      <SlColumn widths="full">
        {listView === 'tile' ? (
          renderGrid ? (
            renderGrid({
              data: fullTableData,
              loading: loading || tableRowsLoading,
              metricType: field.metricType,
              page,
              fieldName: mainFieldName || sortFieldName
            })
          ) : null
        ) : (
          <EntityTable
            skeletonRows={skeletonRows}
            isLoading={loading || tableRowsLoading}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            showPagination={false}
            shouldModifyColumnDefs={false}
            columnDefs={
              columnDefinitions || [
                ...(headColumnDefinition
                  ? Array.isArray(headColumnDefinition)
                    ? headColumnDefinition
                    : [headColumnDefinition]
                  : []),
                ...fields
                  .filter(({ displayInTable }) => displayInTable !== false)
                  .map(({ name: columnFieldName, displayName, comparisonFormatter }) => {
                    const columnField = INDEX_FIELDS.getField(getAppName(), indexName, columnFieldName);

                    return {
                      headerName: displayName || columnField.displayName,
                      valueFormatter: (_, row) => {
                        const metricValue = row[columnFieldName].value;
                        const metricDiff = row[columnFieldName].difference;

                        return (
                          <MetricCell
                            value={metricValue}
                            metricType={columnField.metricType}
                            percentChange={
                              comparisonFormatter
                                ? undefined
                                : calculatePercentChange(metricValue, metricValue - metricDiff)
                            }
                            comparisonNode={
                              comparisonFormatter ? comparisonFormatter(metricValue, metricDiff) : undefined
                            }
                          />
                        );
                      }
                    };
                  }),
                ...additionalColumnDefs
              ]
            }
            rowData={fullTableData}
          />
        )}
        <div style={{ marginTop: listView === 'tile' ? '20px' : undefined }}>
          {hidePagination ? null : (
            <SlRow horizontalPosition="end">
              <Pagination
                currentPage={page}
                shouldUseInfinitePagination
                onClickNext={() => handleNextPage()}
                onClickPrevious={() => handlePrevPage()}
              />
            </SlRow>
          )}
        </div>
      </SlColumn>
    </SlColumn>
  );
};

export default MetricListContainer;
