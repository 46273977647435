import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import { ChevronIcon } from 'src/components/SvgIcons';
import { MetricField } from 'src/types/application/types';
import colors from 'src/utils/colors';

export interface HeaderInnerDropdownProps {
  /**
   * The currently selected value in the dropdown.
   */
  value: MetricField;
  /**
   * List of all fields available for selection in
   * the dropdown.
   */
  groupByFields: MetricField[];
  /**
   * Callback function to be called when an item
   * in the dropdown is selected. It should update `value`
   */
  handleGroupByChange: (item: MetricField) => void;
}

/**
 * A column to be used in a grid that allows toggling between
 * two options. For example, see the "Campaigns" page in Drive
 * that allows toggling between "Product" and "Target" views
 * in the tables.
 */
const HeaderInnerDropdown = ({ value, groupByFields, handleGroupByChange }: HeaderInnerDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick: React.MouseEventHandler<HTMLSpanElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div style={{ display: 'flex', width: '100%' }}>
        <span role="button" style={{ cursor: 'pointer', display: 'flex' }} onClick={handleClick}>
          {value.displayName}
          <ChevronIcon
            style={{
              width: 20,
              height: 20,
              stroke: colors.darkBlue
            }}
          />
        </span>
        <Popover
          open={!!anchorEl}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom'
          }}
        >
          <MenuList>
            {groupByFields.map((item, idx: number) => {
              return (
                <MenuItem key={`menu-list-item-${idx}`} onClick={() => handleGroupByChange(item)}>
                  {item.displayName}
                </MenuItem>
              );
            })}
          </MenuList>
        </Popover>
      </div>
    </>
  );
};

export default HeaderInnerDropdown;
