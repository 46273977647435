import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import _clone from 'lodash/clone';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _startCase from 'lodash/startCase';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { DayPickerSingleDateController, isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import NumberFormat from 'react-number-format';
import StandardSelect from 'src/components/common/DropDown/StandardDropdown';

import { AD_BUDGET_TYPE, IAdCampaign } from 'sl-ad-campaign-manager-data-model';
import { MULTIPLIERS_MAX_VAL, Walmart_Bid_Location } from 'src/components/AdCampaignBuilder/Steps/AdditionalSettings';
import InfoTooltip from 'src/components/AdCampaignBuilder/Widgets/InfoTooltip';
import {
  getCampaignDisplayStatus,
  getParentEntityDisplayInfo,
  getParentPortfolioDisplayInfo,
  getStrategyDisplayInfo,
  updateCampaign
} from 'src/components/AdManager/AmsUtils';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import CalendarClearButton from 'src/components/common/DatePicker/CalendarClearButton';
import Loading from 'src/components/common/Loading';
import 'src/components/Header/Header.scss';
import { ChevronIcon } from 'src/components/SvgIcons';
import { Archived, Delivering, Ended, OutOfBudget, Paused, PendingStart, PushFn } from 'src/types/application/types';
import colors from 'src/utils/colors';
import { UTC_TIMEZONE } from 'src/utils/constants';
import { muiStyles, INSTACART_MIN_DAILY_BUDGET } from './utils';
import '../AdManagerSettings.scss';
import { InstacartOptimizationStatus } from '../utils';
import InstacartBidding from './InstacartBidding';
import StacklineOptimizationRow from './StacklineOptimizationRow';
import Typography from '@mui/material/Typography';
import { isInstacart } from 'src/utils/app';
import { entityOperations } from 'src/store/modules/entityService';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/utils/Hooks';

const bidPlacementOpts = [
  {
    name: 'ON - Dynamically Flex Bids'
  },
  {
    name: 'OFF - Do Not Flex Bids'
  }
];

const CampaignSetting: React.FC<{
  classes: { [key: string]: any };
  push: PushFn;
}> = ({ classes, push }) => {
  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);
  const retailer = useAppSelector((state) => state.retailer);
  const user = useAppSelector((state) => state.user);
  const adPortfolios = useAppSelector((state) => state.adPortfolios);

  const dispatch = useDispatch();
  const [campaignName, setCampaignName] = useState('');
  const [selectedPortfolio, setPortfolio] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [budget, setBudget] = useState(null);
  const [totalBudget, setTotalBudget] = useState(null);
  const [showLifetimeBudget, setShowLifetimeBudget] = useState(false);
  const [minBudget, setMinBudget] = useState(0);
  const [minROAS, setMinROAS] = useState(0);
  const [loading, setLoading] = useState(false);
  const [bidPlacement, setBidPlacement] = useState(bidPlacementOpts[0]);
  const [walmartMultiplier, setWalmartMultiplier] = useState({
    placements: [],
    placementBidMultipliers: [],
    platformBidMultipliers: []
  });
  const [instacartStatus, setInstacartStatus] = useState<InstacartOptimizationStatus>(
    InstacartOptimizationStatus.Disabled
  );
  const [instacartMinRoas, setInstacartMinRoas] = useState(0);
  const hasInstacartError = useMemo(
    () => (!budget || budget < INSTACART_MIN_DAILY_BUDGET) && instacartStatus === InstacartOptimizationStatus.Enabled,
    [budget, instacartStatus]
  );

  const { displayName: statusName, color: statusColor } = getCampaignDisplayStatus(mainEntity.extendedAttributes);
  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);

  const initState = () => {
    if (!mainEntity) {
      return;
    }
    const { parentPortfolio } = getParentPortfolioDisplayInfo(mainEntity.extendedAttributes) || { parentPortfolio: {} };
    const newBudget = _get(mainEntity, ['extendedAttributes', 'liveBudgetSetting', 'amount'], null);
    const newTotalBudget = _get(mainEntity, ['extendedAttributes', 'liveOtherBudgetSettings', 0, 'amount'], null);
    const newMinBudget = _get(
      mainEntity,
      ['extendedAttributes', 'automationAttributes', 'metricsThresholds', 'budgetAmountMinimum'],
      1
    );
    const newMinROAS = _get(
      mainEntity,
      ['extendedAttributes', 'automationAttributes', 'metricsThresholds', 'roasMinimum'],
      0
    );
    const newStartDate = _get(mainEntity, ['extendedAttributes', 'amsApiModel', 'startDate'], mainEntity.startDate);
    const newEndDate = _get(mainEntity, ['extendedAttributes', 'amsApiModel', 'endDate'], mainEntity.endDate);
    const newInstacartBiddingEnabled = _get(mainEntity, ['extendedAttributes', 'optimized_bidding_enabled'], false);
    const newInstacartMinRoas = _get(
      mainEntity,
      ['extendedAttributes', 'optimized_bidding_parameters', 'roas_constraint'],
      0
    );

    setCampaignName(mainEntity.campaignName);
    setPortfolio(parentPortfolio);
    setBudget(newBudget);
    setTotalBudget(newTotalBudget);
    if (
      newTotalBudget &&
      _get(mainEntity, ['extendedAttributes', 'liveOtherBudgetSettings', 0, 'budgetType']) === AD_BUDGET_TYPE.LIFETIME
    ) {
      setShowLifetimeBudget(true);
    }
    setMinBudget(newMinBudget);
    setMinROAS(newMinROAS);
    setStartDate(newStartDate ? moment(newStartDate).tz(retailer.processInTimezone || UTC_TIMEZONE) : newStartDate);
    setEndDate(newEndDate ? moment(newEndDate).tz(retailer.processInTimezone || UTC_TIMEZONE) : newEndDate);
    if (_get(mainEntity, ['extendedAttributes', 'retailerId']) === 2) {
      const newPlacement = _get(mainEntity, ['extendedAttributes', 'placements'], []);
      const newPlacementBidMultipliers = _get(mainEntity, ['extendedAttributes', 'placementBidMultipliers'], []);
      const newPlatformBidMultipliers = _get(mainEntity, ['extendedAttributes', 'platformBidMultipliers'], []);
      setWalmartMultiplier({
        placements: newPlacement,
        placementBidMultipliers: newPlacementBidMultipliers,
        platformBidMultipliers: newPlatformBidMultipliers
      });
    }

    if (isInstacart(retailer.id)) {
      setInstacartStatus(
        newInstacartBiddingEnabled ? InstacartOptimizationStatus.Enabled : InstacartOptimizationStatus.Disabled
      );
      setInstacartMinRoas(newInstacartMinRoas);
    }
  };

  const handlePlacementsChange = (name: string, value: string) => {
    const newPlacement = _cloneDeep(walmartMultiplier.placements) as any[];
    if (newPlacement.find((item) => item.placement === name)) {
      newPlacement.forEach((item) => {
        if (item.placement === name) {
          item.status = value;
        }
      });
    } else {
      newPlacement.push({
        campaignId: _get(mainEntity, ['extendedAttributes', 'campaignId'], ''),
        entityId: _get(mainEntity, ['extendedAttributes', 'entityId'], ''),
        beaconClientId: _get(mainEntity, ['beaconClientId'], ''),
        beaconClientLoginId: _get(mainEntity, ['extendedAttributes', 'beaconClientLoginId'], ''),
        platformType: _get(mainEntity, ['platformType'], 'Walmart'),
        placement: name,
        status: value
      });
    }
    setWalmartMultiplier({
      ...walmartMultiplier,
      placements: newPlacement
    });
  };

  const handlePlacementBidMultipliersChange = (name: string, value: number) => {
    const newPlacementBidMultipliers = _cloneDeep(walmartMultiplier.placementBidMultipliers) as any[];
    if (newPlacementBidMultipliers.find((item) => item.bidLocation === name)) {
      newPlacementBidMultipliers.forEach((item) => {
        if (item.bidLocation === name) {
          item.multiplier = value;
        }
      });
    } else {
      newPlacementBidMultipliers.push({
        campaignId: _get(mainEntity, ['extendedAttributes', 'campaignId'], ''),
        entityId: _get(mainEntity, ['extendedAttributes', 'entityId'], ''),
        beaconClientId: _get(mainEntity, ['beaconClientId'], ''),
        beaconClientLoginId: _get(mainEntity, ['extendedAttributes', 'beaconClientLoginId'], ''),
        platformType: _get(mainEntity, ['platformType'], 'Walmart'),
        bidLocation: name,
        multiplier: value
      });
    }
    setWalmartMultiplier({
      ...walmartMultiplier,
      placementBidMultipliers: newPlacementBidMultipliers
    });
  };

  const handlePlatformBidMultipliersChange = (name: string, value: number) => {
    const newPlatformBidMultipliers = _cloneDeep(walmartMultiplier.platformBidMultipliers) as any[];
    if (newPlatformBidMultipliers.find((item) => item.bidLocation === name)) {
      newPlatformBidMultipliers.forEach((item) => {
        if (item.bidLocation === name) {
          item.multiplier = value;
        }
      });
    } else {
      newPlatformBidMultipliers.push({
        campaignId: _get(mainEntity, ['extendedAttributes', 'campaignId'], ''),
        entityId: _get(mainEntity, ['extendedAttributes', 'entityId'], ''),
        beaconClientId: _get(mainEntity, ['beaconClientId'], ''),
        beaconClientLoginId: _get(mainEntity, ['extendedAttributes', 'beaconClientLoginId'], ''),
        platformType: _get(mainEntity, ['platformType'], 'Walmart'),
        bidLocation: name,
        multiplier: value
      });
    }
    setWalmartMultiplier({
      ...walmartMultiplier,
      platformBidMultipliers: newPlatformBidMultipliers
    });
  };

  const submitChanges = async () => {
    const mainEntityCloned: IAdCampaign = _clone(mainEntity);
    delete mainEntityCloned.adCampaignProducts;
    delete mainEntityCloned.adTargets;
    delete mainEntityCloned.adGroups;

    if (mainEntityCloned.campaignName !== campaignName) {
      mainEntityCloned.campaignName = campaignName;
    }
    if (selectedPortfolio && mainEntityCloned.extendedAttributes.portfolioId !== selectedPortfolio.settingId) {
      mainEntityCloned.extendedAttributes.portfolioId = selectedPortfolio.settingId;
      mainEntityCloned.extendedAttributes.portfolioIdApi = selectedPortfolio.settingId;
    }
    if (
      budget &&
      mainEntityCloned.extendedAttributes.currentMonthBudgetSetting &&
      mainEntityCloned.extendedAttributes.currentMonthBudgetSetting.amount !== budget
    ) {
      mainEntityCloned.extendedAttributes.currentMonthBudgetSetting = {
        ...mainEntityCloned.extendedAttributes.currentMonthBudgetSetting,
        amount: budget || 1
      };
    }
    if (
      totalBudget &&
      mainEntityCloned.extendedAttributes.currentOtherBudgetSettings &&
      mainEntityCloned.extendedAttributes.currentOtherBudgetSettings[0] &&
      mainEntityCloned.extendedAttributes.currentOtherBudgetSettings[0].amount !== totalBudget
    ) {
      mainEntityCloned.extendedAttributes.currentOtherBudgetSettings[0] = {
        ...mainEntityCloned.extendedAttributes.currentOtherBudgetSettings[0],
        amount: totalBudget || 3000
      };
    }
    if (endDate && mainEntityCloned.endDate !== endDate) {
      mainEntityCloned.endDate = endDate.format('YYYY-MM-DD');
    }

    if (!endDate) {
      mainEntityCloned.endDate = null;
    }

    if (startDate && statusName === PendingStart.displayName) {
      mainEntityCloned.startDate = startDate.format('YYYY-MM-DD');
    }

    if (mainEntityCloned.extendedAttributes.automationAttributes) {
      mainEntityCloned.extendedAttributes.automationAttributes.metricsThresholds = {
        budgetAmountMinimum: minBudget,
        roasMinimum: minROAS
      };
    } else {
      mainEntityCloned.extendedAttributes.automationAttributes = {
        metricsThresholds: {
          budgetAmountMinimum: minBudget,
          roasMinimum: minROAS
        }
      };
    }

    if (retailer.id === '2' || retailer.id === '25') {
      if (walmartMultiplier.placements[0] && walmartMultiplier.placements[0].status) {
        mainEntityCloned.extendedAttributes.placements = walmartMultiplier.placements;
      }
      const filteredPlacementBidMultipliers = walmartMultiplier.placementBidMultipliers.filter((item) => {
        return !_isNil(item.multiplier);
      });
      const filteredPlatformBidMultipliers = walmartMultiplier.platformBidMultipliers.filter((item) => {
        return !_isNil(item.multiplier);
      });

      mainEntityCloned.extendedAttributes.placementBidMultipliers = filteredPlacementBidMultipliers;

      mainEntityCloned.extendedAttributes.platformBidMultipliers = filteredPlatformBidMultipliers;
    }

    if (isInstacart(retailer.id)) {
      const isInstacartBiddingEnabled = instacartStatus === InstacartOptimizationStatus.Enabled;
      mainEntityCloned.extendedAttributes.optimized_bidding_enabled = isInstacartBiddingEnabled;
      mainEntityCloned.extendedAttributes.optimized_bidding_parameters = isInstacartBiddingEnabled
        ? {
            roas_constraint: instacartMinRoas
          }
        : {};
      mainEntityCloned.extendedAttributes.optimized_bidding_goal = 'max_attributed_sales';
    }

    setLoading(true);
    await updateCampaign(mainEntityCloned);
    dispatch(
      entityOperations.fetchMainEntity(
        mainEntityCloned.type,
        mainEntityCloned.id,
        mainEntityCloned.extendedAttributes.retailerId,
        push
      )
    );
    setLoading(false);
  };

  useEffect(() => {
    initState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainEntity, adPortfolios]);

  if (!mainEntity || loading) {
    return (
      <div style={{ height: 800 }}>
        <Loading className="spinner" size={150} thickness={2} />
      </div>
    );
  }

  const { campaignId } = mainEntity;
  const adType = _startCase(_get(mainEntity, 'extendedAttributes.campaignType'));
  const { shortDisplayName: parentEntityName } = getParentEntityDisplayInfo(mainEntity.extendedAttributes);

  const setSelectPortfolio = (id: string | number) => {
    const newPortfolio = adPortfolios.find((portfolio) => portfolio.id === id);
    setPortfolio(newPortfolio);
  };

  const { entityId } = mainEntity.extendedAttributes;

  const { strategy } = selectedPortfolio
    ? getStrategyDisplayInfo(selectedPortfolio.extendedAttributes)
    : getStrategyDisplayInfo(mainEntity.extendedAttributes);

  const strategyName = _get(strategy, ['extendedAttributes', 'name'], '--');
  const strategyDesc = _get(strategy, ['extendedAttributes', 'description'], '');

  let disableStartDate = false;
  let disableEndDate = false;
  let disableBudget = false;

  if ([Ended.displayName, Archived.displayName].includes(statusName) && (retailer.id !== '2' || retailer.id !== '25')) {
    disableStartDate = true;
    disableEndDate = true;
    disableBudget = true;
  }

  if ([Paused.displayName, OutOfBudget.displayName, Delivering.displayName].includes(statusName)) {
    disableStartDate = true;
  }

  const startDateLimit = (day) => {
    const todayDate = moment().tz(retailer.processInTimezone || UTC_TIMEZONE);

    if (!endDate) {
      // if endDate not set, use could set startDate to be any day after today.
      return !isInclusivelyAfterDay(day, todayDate);
    } else {
      // if endDate set, use could set startDate to be any day between today and endDate.
      return !isInclusivelyAfterDay(day, todayDate) || !isInclusivelyBeforeDay(day, endDate);
    }
  };
  const endDateLimit = (day) => {
    const todayDate = moment().tz(retailer.processInTimezone || UTC_TIMEZONE);

    let latestDate = todayDate;
    if ((startDate && todayDate.isBefore(moment(startDate))) || retailer.id === '2' || retailer.id === '25') {
      latestDate = moment(startDate);
    }
    // disable all dates before today
    if (!isInclusivelyAfterDay(day, latestDate)) {
      return true;
    }

    return false;
  };

  const handleBitPlacementChange = (newBid) => {
    const newBidOpt = bidPlacementOpts.find((opt) => opt.name === newBid);
    setBidPlacement(newBidOpt);
  };

  const filterdAdPortfolios = adPortfolios.filter((portfolio) => portfolio.extendedAttributes.entityId === entityId);

  const disableDailyBudget = disableBudget;

  const renderWalmartBidMultiplier = () => {
    const isAutoTargeting = _get(mainEntity, ['extendedAttributes', 'amsApiModel', 'targetingType']) === 'auto';
    const placementsOpt: string[] = ['Search Carousel', 'Item Buybox', 'Item Carousel'];
    const placementBidMultipliersOpt: string[] = isAutoTargeting
      ? [
          Walmart_Bid_Location.placements.BUY_BOX,
          Walmart_Bid_Location.placements.HOME_PAGE,
          Walmart_Bid_Location.placements.SEARCH_IN_GRID,
          Walmart_Bid_Location.placements.STOCK_UP
        ]
      : [Walmart_Bid_Location.placements.SEARCH_IN_GRID, Walmart_Bid_Location.placements.BUY_BOX];
    const platformBidMultipliersOpt: string[] = [
      Walmart_Bid_Location.platforms.DESKTOP,
      Walmart_Bid_Location.platforms.MOBILE,
      Walmart_Bid_Location.platforms.APP
    ];
    return (
      <>
        {!isAutoTargeting ? (
          <div className="setting_row">
            <div className="label">Placement:</div>
            <div style={muiStyles.centerRowOverride}>
              {placementsOpt.map((opt, idx) => {
                const current = walmartMultiplier.placements.find((item: any) => item.placement === opt);
                const checked = _get(current, ['status']) === 'included';
                return (
                  <div key={idx} style={muiStyles.centerRowOptions}>
                    <div>{opt}:</div>
                    <Switch
                      checked={checked}
                      onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const newVal = e.target.checked ? 'included' : 'excluded';
                        handlePlacementsChange(opt, newVal);
                      }}
                      color="primary"
                      disabled={isAdAuditUser}
                      classes={{ disabled: classes.switchDisabled }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="setting_row">
          <div className="label">Placement Multiplier(s):</div>
          <div style={muiStyles.centerRowOverride}>
            {placementBidMultipliersOpt.map((opt, idx) => {
              const current = walmartMultiplier.placementBidMultipliers.find((item: any) => item.bidLocation === opt);
              const value = _get(current, ['multiplier']);
              return (
                <div key={idx} style={muiStyles.centerRowOptions}>
                  <div>{opt}:</div>
                  <NumberFormat
                    value={value}
                    thousandSeparator
                    suffix="%"
                    decimalScale={2}
                    fixedDecimalScale
                    allowNegative={false}
                    disabled={isAdAuditUser}
                    isAllowed={({ floatValue }) => {
                      return floatValue <= MULTIPLIERS_MAX_VAL || floatValue === undefined;
                    }}
                    onValueChange={({ floatValue }) => handlePlacementBidMultipliersChange(opt, floatValue)}
                    // Mimic the style of the MUI `TextInput`
                    style={{
                      height: '32px',
                      outline: 'none',
                      border: '0',
                      borderBottomColor: 'currentcolor',
                      borderBottomStyle: 'none',
                      borderBottomWidth: '0px',
                      borderBottom: `${isAdAuditUser ? 0 : 1}px solid #eee`,
                      backgroundColor: 'transparent',
                      width: '120px',
                      fontSize: '16px',
                      color: isAdAuditUser ? colors.labelGrey : 'currentColor',
                      fontFamily: 'Roboto'
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="setting_row">
          <div className="label">Platform Multiplier(s):</div>
          <div style={muiStyles.centerRowOverride}>
            {platformBidMultipliersOpt.map((opt, idx) => {
              const current = walmartMultiplier.platformBidMultipliers.find((item: any) => item.bidLocation === opt);
              const value = _get(current, ['multiplier']);
              return (
                <div key={idx} style={muiStyles.centerRowOptions}>
                  <div>{opt}:</div>
                  <NumberFormat
                    value={value}
                    thousandSeparator
                    suffix="%"
                    decimalScale={2}
                    fixedDecimalScale
                    allowNegative={false}
                    disabled={isAdAuditUser}
                    isAllowed={({ floatValue }) => {
                      return floatValue <= MULTIPLIERS_MAX_VAL || floatValue === undefined;
                    }}
                    onValueChange={({ floatValue }) => handlePlatformBidMultipliersChange(opt, floatValue)}
                    // Mimic the style of the MUI `TextInput`
                    style={{
                      height: '32px',
                      outline: 'none',
                      border: '0',
                      borderBottomColor: 'currentcolor',
                      borderBottomStyle: 'none',
                      borderBottomWidth: '0px',
                      borderBottom: `${isAdAuditUser ? 0 : 1}px solid #eee`,
                      backgroundColor: 'transparent',
                      width: '120px',
                      fontSize: '16px',
                      color: isAdAuditUser ? colors.labelGrey : 'currentColor',
                      fontFamily: 'Roboto'
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="setting_container">
      <div className="setting_row">
        <div className="label">Campaign Name:</div>
        <div>
          <TextField
            variant="standard"
            autoComplete="off"
            id="outlined-bare"
            classes={{ root: classes.root }}
            value={campaignName}
            InputProps={{
              disableUnderline: isAdAuditUser,
              classes: { root: classes.root, disabled: classes.disabled }
            }}
            onChange={({ target: { value } }) => setCampaignName(value)}
            margin="normal"
            disabled={isAdAuditUser}
          />
        </div>
      </div>
      <div className="setting_row">
        <div className="label">Campaign Id:</div>
        <div>{campaignId}</div>
      </div>
      <div className="setting_row">
        <div className="label">Ad Type:</div>
        <div>{adType}</div>
      </div>
      <div className="setting_row">
        <div className="label">Entity:</div>
        <div>{parentEntityName}</div>
      </div>
      <div className="setting_row">
        <div className="label">Entity Id:</div>
        <div>{entityId}</div>
      </div>
      <div className="setting_row">
        <div className="label">Portfolio:</div>
        <div>
          <StandardSelect
            style={{ marginRight: 20 }}
            value={selectedPortfolio && selectedPortfolio.id ? selectedPortfolio.id : ''}
            onChange={({ target: { value } }) => {
              setSelectPortfolio(value);
            }}
            classes={{ select: classes.select, disabled: classes.disabled }}
            IconComponent={() => (isAdAuditUser ? null : <ChevronIcon className="sl-header__drop-down-icon" />)}
            disabled={isAdAuditUser}
            disableUnderline={isAdAuditUser}
            placeholder="Unassigned"
            displayEmpty
            renderValue={selectedPortfolio && selectedPortfolio.id ? undefined : () => <>Unassigned</>}
          >
            {filterdAdPortfolios.map((opt) => (
              <MenuItem key={opt.id} value={opt.id}>
                {opt.displayName}
              </MenuItem>
            ))}
          </StandardSelect>
        </div>
      </div>
      {!isAdAuditUser && (
        <div className="setting_row">
          <div className="label">Optimization:</div>
          <InfoTooltip customNode={<div>{strategyName}</div>}>{strategyDesc}</InfoTooltip>
        </div>
      )}
      {!isAdAuditUser && strategy.id !== 'Manual' && (
        <div className="setting_row">
          <div className="label"></div>
          <div>
            <StacklineOptimizationRow
              value={minBudget}
              setValue={setMinBudget}
              tooltipLabel="Budget entered here will set a minimum budget that automation will not override"
              label="Minimum Daily Budget:"
            />
            <StacklineOptimizationRow
              value={minROAS}
              setValue={setMinROAS}
              label="Minimum ROAS:"
              tooltipLabel="Enter the minimum ROAS for this campaign; automation will minimize spend on targets below this threshold"
            />
          </div>
        </div>
      )}
      <div className="setting_row">
        <div className="label">Daily Budget:</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NumberFormat
            value={budget}
            disabled={disableDailyBudget || isAdAuditUser}
            thousandSeparator
            prefix={retailer.currencySymbol}
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            onValueChange={({ floatValue }) => setBudget(floatValue)}
            // Mimic the style of the MUI `TextInput`
            style={{
              height: '32px',
              outline: 'none',
              border: '0',
              borderBottomColor: 'currentcolor',
              borderBottomStyle: 'none',
              borderBottomWidth: '0px',
              background: 'none',
              borderBottom: `${disableDailyBudget || isAdAuditUser ? 0 : 1}px solid #eee`,
              backgroundColor: 'transparent',
              width: '100px',
              fontSize: '16px',
              color: disableDailyBudget || isAdAuditUser ? colors.labelGrey : 'currentcolor',
              fontFamily: 'Roboto'
            }}
          />
          {hasInstacartError && (
            <Typography ml={2} fontSize={16} color="error">
              {`Optimized bidding campaigns require a minimum daily budget of $${INSTACART_MIN_DAILY_BUDGET}`}
            </Typography>
          )}
        </div>
      </div>
      {['2', '63', '25'].includes(retailer.id) && showLifetimeBudget && (
        <div className="setting_row">
          <div className="label">Lifetime Budget:</div>
          <div>
            <NumberFormat
              value={totalBudget}
              disabled={disableBudget || isAdAuditUser}
              thousandSeparator
              prefix={retailer.currencySymbol}
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              onValueChange={({ floatValue }) => setTotalBudget(floatValue)}
              // Mimic the style of the MUI `TextInput`
              style={{
                height: '32px',
                outline: 'none',
                border: '0',
                borderBottomColor: 'currentcolor',
                borderBottomStyle: 'none',
                borderBottomWidth: '0px',
                background: 'none',
                borderBottom: `${disableDailyBudget || isAdAuditUser ? 0 : 1}px solid #eee`,
                backgroundColor: 'transparent',
                width: '100px',
                fontSize: '16px',
                color: disableDailyBudget || isAdAuditUser ? colors.labelGrey : 'currentColor',
                fontFamily: 'Roboto'
              }}
            />
          </div>
        </div>
      )}
      {isInstacart(retailer.id) && (
        <InstacartBidding
          minRoas={instacartMinRoas}
          setMinRoas={setInstacartMinRoas}
          status={instacartStatus}
          setStatus={setInstacartStatus}
          disabled={isAdAuditUser}
        />
      )}
      {!isAdAuditUser && (
        <div className="setting_row">
          <div className="label">Bid by Placement:</div>
          <div>
            <StandardSelect
              variant="standard"
              style={{ marginRight: 20 }}
              value={bidPlacement.name}
              onChange={({ target: { value } }) => {
                handleBitPlacementChange(value);
              }}
              classes={{ root: classes.selectRoot, select: classes.select }}
              IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
            >
              {bidPlacementOpts.map((opt) => (
                <MenuItem key={opt.name} value={opt.name}>
                  {opt.name}
                </MenuItem>
              ))}
            </StandardSelect>
          </div>
          <InfoTooltip style={{ paddingLeft: 10, paddingTop: 0 }}>
            Turning this feature on will dynamically flex bid prices up (or down) to win more impressions in placement
            types (e.g., top of search or product pages) generating the highest conversion at the lowest cost.
          </InfoTooltip>
        </div>
      )}
      {(retailer.id === '2' || retailer.id === '25') && renderWalmartBidMultiplier()}
      <div className="setting_row">
        <div className="label">Schedule:</div>
        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          <div className="datepicker_container">
            {`Starts on `}&nbsp;
            <TextField
              variant="standard"
              autoComplete="off"
              classes={{ root: classes.dateRoot }}
              InputProps={{
                disableUnderline: disableStartDate || isAdAuditUser,
                classes: { root: classes.dateRoot, disabled: classes.disabled }
              }}
              value={startDate ? moment(startDate).format('MM/DD/YYYY') : 'No Start Date'}
              id="outlined-bare"
              disabled={disableStartDate || isAdAuditUser}
              onClick={() => (disableStartDate || isAdAuditUser ? null : setStartDatePickerOpen(!startDatePickerOpen))}
              margin="normal"
            />
            {startDatePickerOpen && (
              <div style={{ position: 'absolute', zIndex: 1 }}>
                <DayPickerSingleDateController
                  hideKeyboardShortcutsPanel
                  monthFormat="MMMM YYYY"
                  date={startDate} // momentPropTypes.momentObj or null
                  onDateChange={(date) => {
                    setStartDate(date);
                  }} // PropTypes.func.isRequired
                  focused
                  isOutsideRange={startDateLimit}
                  onFocusChange={() => {}} // PropTypes.func.isRequired
                  onOutsideClick={() => setStartDatePickerOpen(!startDatePickerOpen)}
                  id="adPortfolioSettingStartDatePicker"
                />
              </div>
            )}
          </div>

          <div className="datepicker_container">
            and ends on &nbsp;
            <TextField
              variant="standard"
              autoComplete="off"
              classes={{ root: classes.dateRoot }}
              InputProps={{
                disableUnderline: disableEndDate || isAdAuditUser,
                classes: { root: classes.dateRoot, disabled: classes.disabled }
              }}
              value={endDate ? moment(endDate).format('MM/DD/YYYY') : 'No End Date'}
              id="outlined-bare"
              disabled={disableEndDate || isAdAuditUser}
              onClick={() => (disableEndDate || isAdAuditUser ? null : setEndDatePickerOpen(!endDatePickerOpen))}
              margin="normal"
            />
            {endDatePickerOpen && (
              <div style={{ position: 'absolute', zIndex: 1 }}>
                {' '}
                <DayPickerSingleDateController
                  hideKeyboardShortcutsPanel
                  monthFormat="MMMM YYYY"
                  date={endDate} // momentPropTypes.momentObj or null
                  onDateChange={(date) => setEndDate(date)} // PropTypes.func.isRequired
                  focused
                  onFocusChange={() => {}} // PropTypes.func.isRequired
                  isOutsideRange={endDateLimit}
                  onOutsideClick={() => setEndDatePickerOpen(!endDatePickerOpen)}
                  id="adCampaignSettingEndDatePicker"
                  renderCalendarInfo={() => (
                    <CalendarClearButton
                      hasEndDate={!!endDate}
                      handleClear={() => {
                        setEndDate(null);
                      }}
                    />
                  )}
                />{' '}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="setting_row">
        <div className="label">Status:</div>
        <div style={{ color: statusColor }}>{statusName}</div>
      </div>
      {!isAdAuditUser && (
        <div className="control_button">
          <LargeMuiButton secondary style={{ width: 99, marginRight: 14 }} label="Cancel" onClick={initState} />
          <LargeMuiButton
            disabled={hasInstacartError}
            style={{ width: 99, marginRight: 14 }}
            label="Save"
            onClick={submitChanges}
          />
        </div>
      )}
    </div>
  );
};

export default CampaignSetting;
