import React, { useState } from 'react';
import { Text, SlDropdownMenuOption } from '@stackline/ui';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { GenericNavigationTabs } from 'src/components/BeaconRedesignComponents/SubtabNav/SubtabNavigation';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import { ColDef } from 'ag-grid-community';
import _capitalize from 'lodash/capitalize';
import UserTimestampCell from 'src/components/BeaconRedesignComponents/UserTimestampCell/UserTimestampCell';
import UserActionButton, { UserAction } from './UserActionButton';
import { hasPermission, roleConverter } from 'src/utils/UserManagementPermission';
import { USER_SCOPES as permission, USER_ROLES as roles } from 'src/utils/constants';
import { useAppSelector } from 'src/utils/Hooks';
import AddNewUserModal from './AddNewUserModal';
import ApprovedDomains from './ApprovedDomains/ApprovedDomains';
import AddDomainModal from './ApprovedDomains/AddDomainModal';
import useApprovedDomains from './ApprovedDomains/useApprovedDomains';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

interface TeamPageLayoutProps {
  users: any[];
  canAddUser: boolean;
  canViewApprovedDomains: boolean;
  refetchData: () => Promise<void>;
}

interface BaseUser {
  email: string;
  role: string;
}

const USER_ROLES_ORDER = ['support', 'owner', 'admin', 'basic', 'user'];

/**
 * Returns true if the user's role is higher than the current user's role
 */
function isUserHigherRole(userRole: string, currentUserRole: string) {
  return USER_ROLES_ORDER.indexOf(userRole) < USER_ROLES_ORDER.indexOf(currentUserRole);
}

/**
 * Returns true if the current user can delete the user
 */
function canDeleteUser(userRow: BaseUser, currentUser: BaseUser) {
  if (userRow.email === currentUser.email) {
    return false;
  }

  const currentRole = roleConverter(currentUser.email, currentUser.role);

  if (userRow.role === 'admin') {
    return hasPermission(permission.canDeleteAdmin, currentRole);
  }
  return hasPermission(permission.canDeleteUsers, currentRole);
}

/**
 * Returns true if the logged in user can assign the user an
 * admin role
 */
function canAssignAdminRole(userRow: BaseUser, currentUser: BaseUser) {
  const currentRole = roleConverter(currentUser.email, currentUser.role);
  return (
    hasPermission(permission.canAssignAdmins, currentRole) && roleConverter(userRow.email, userRow.role) !== roles.admin
  );
}

/**
 * Returns true if the user is an admin and the logged in user
 * can remove the admin status
 */
function canRemoveAdminRole(userRow: BaseUser, currentUser: BaseUser) {
  const currentRole = roleConverter(currentUser.email, currentUser.role);
  return (
    hasPermission(permission.canDeleteAdmin, currentRole) && roleConverter(userRow.email, userRow.role) === roles.admin
  );
}

/**
 * Returns true if the user can transfer team ownership to another member
 */
function canTransferOwnership(userRow: BaseUser, currentUser: BaseUser) {
  const loggedInUserRole = roleConverter(currentUser.email, currentUser.role);
  const targetUserRole = roleConverter(userRow.email, userRow.role);
  return hasPermission(permission.canTransferOwnership, loggedInUserRole) && targetUserRole !== roles.owner;
}

/**
 * True if the user can edit approved domains
 */
function canEditApprovedDomains(currentUser: BaseUser) {
  const currentRole = roleConverter(currentUser.email, currentUser.role);
  return hasPermission(permission.canEditApprovedDomain, currentRole);
}

/**
 *  Container for displaying team users and approved domains
 */
export default function TeamPageLayout({
  users,
  canAddUser,
  canViewApprovedDomains,
  refetchData
}: TeamPageLayoutProps) {
  const [tab, setTab] = useState<'team' | 'domains'>('team');
  const [addNewUserModalOpen, setAddNewUserModalOpen] = useState(false);
  const [addDomainOpen, setAddDomainOpen] = useState(false);
  const currentUser = useAppSelector((state) => state.user.config.profile);

  const { approvedDomains, fetchApprovedDomains, loading: approvedDomainsLoading } = useApprovedDomains();

  const USER_TABLE_COLUMN_DEFS: ColDef[] = [
    {
      headerName: 'NAME',
      field: 'firstName',
      valueFormatter: (_, row) => {
        // TODO we'll need a way to get the user IDs for the images,
        // either from the backend or we'll need to store the email as the
        // S3 key
        return <UserTimestampCell firstName={row.firstName} lastName={row.lastName} userId="" />;
      },
      tableColumnProps: {
        style: {
          width: '280px'
        }
      },
      tableCellProps: {
        style: {
          padding: '15px 24px 15px 16px'
        }
      }
    },
    {
      headerName: 'EMAIL',
      field: 'email',
      valueFormatter: (_, row) => {
        return row.email;
      },
      tableColumnProps: {
        style: {
          width: '360px'
        }
      }
    },
    {
      headerName: 'ROLE',
      field: 'role',
      valueFormatter: (_, row) => {
        return _capitalize(row.role);
      }
    },
    {
      headerName: '',
      field: '',
      valueFormatter: (_, row) => {
        // Users cannot perform actions on themselves
        const options: SlDropdownMenuOption[] =
          row.email !== currentUser.email && !isUserHigherRole(row.role, currentUser.role)
            ? [
                ...(canAssignAdminRole(row, currentUser)
                  ? [
                      {
                        id: UserAction.ASSIGN_ADMIN,
                        label: 'Assign admin role'
                      }
                    ]
                  : []),
                ...(canRemoveAdminRole(row, currentUser)
                  ? [
                      {
                        id: UserAction.REMOVE_ADMIN,
                        label: 'Remove admin role'
                      }
                    ]
                  : []),
                ...(canTransferOwnership(row, currentUser)
                  ? [
                      {
                        id: UserAction.TRANSFER_OWNERSHIP,
                        label: 'Transfer ownership'
                      }
                    ]
                  : []),
                ...(canDeleteUser(row, currentUser)
                  ? [
                      {
                        id: UserAction.DELETE,
                        label: 'Delete'
                      }
                    ]
                  : [])
              ]
            : [];
        return options.length > 0 ? <UserActionButton options={options} refetchData={refetchData} user={row} /> : null;
      },
      tableColumnProps: {
        align: 'right'
      },
      tableCellProps: {
        style: {
          paddingRight: '27px'
        }
      }
    }
  ];

  return (
    <>
      <BeaconPageContainer sx={{ marginTop: '0px' }}>
        <Flex flexDirection="column" gap="lg">
          <Flex flexDirection="column">
            <Flex justifyContent="space-between">
              <Text variant="h2">Team</Text>
              {canAddUser && tab === 'team' && (
                <SlButton variant="contained" onClick={() => setAddNewUserModalOpen(true)}>
                  + Add User
                </SlButton>
              )}
              {canEditApprovedDomains(currentUser) && tab === 'domains' && (
                <SlButton variant="contained" onClick={() => setAddDomainOpen(true)}>
                  + Add Domain
                </SlButton>
              )}
            </Flex>
            <GenericNavigationTabs
              tab={tab}
              tabs={[
                {
                  label: 'Team',
                  value: 'team',
                  onClick: () => setTab('team')
                },
                ...(canViewApprovedDomains
                  ? [
                      {
                        label: 'Approved Domains',
                        value: 'domains',
                        onClick: () => setTab('domains')
                      }
                    ]
                  : [])
              ]}
            />
          </Flex>
          {tab === 'team' && (
            <EntityTable shouldModifyColumnDefs={false} rowData={users} columnDefs={USER_TABLE_COLUMN_DEFS} />
          )}
          {tab === 'domains' && (
            <ApprovedDomains
              approvedDomains={approvedDomains}
              loading={approvedDomainsLoading}
              refetchData={fetchApprovedDomains}
              canEditDomains={canEditApprovedDomains(currentUser)}
            />
          )}
        </Flex>
        <AddNewUserModal
          refetchData={refetchData}
          open={addNewUserModalOpen}
          onClose={() => setAddNewUserModalOpen(false)}
        />
        <AddDomainModal
          open={addDomainOpen}
          onClose={() => setAddDomainOpen(false)}
          refetchData={fetchApprovedDomains}
        />
      </BeaconPageContainer>
    </>
  );
}
