/**
 * Contains definitions of custom HCFs for the target keywords grid
 */

import React from 'react';
import numeral from 'numeral';
import { Option } from 'funfix-core';
import _isNil from 'lodash/isNil';

import colors from 'src/utils/colors';
import { SingleCellLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import './TargetEntitiesGrid.scss';
import { AGGridValueFormatter } from 'src/types/application/types';
import { panic } from 'src/utils/mixpanel';

const styles: { [key: string]: React.CSSProperties } = {
  roundedBorder: {
    borderRadius: 4,
    border: `1px solid ${colors.lightestGrey}`,
    padding: '2px 6px 2px 6px'
  },
  loadingCell: {
    width: 100,
    height: 35
  }
};

export const LoadingCell = () => (
  <div style={styles.loadingCell}>
    <SingleCellLoading />
  </div>
);

/**
 * HOC that renders a rounded border box around a component.
 *
 * @param {React.Component} Comp
 */
const withRoundedBorder = (Comp: React.ComponentType) => {
  const WithRoundedBorder = ({ ...props }) => {
    return (
      <div style={styles.roundedBorder}>
        <Comp {...props} />
      </div>
    );
  };

  return WithRoundedBorder;
};

export interface MkNumberFormatterCRFOptions {
  /**
   * If `true`, then `null` or `undefined` arguments passed to the returned formatter from `mkNumberFormatterCRF` will
   * be rendered as empty rather than zero.  Defaults to `false`.
   */
  blankOnNil?: boolean;
}

/**
 * HOC that creates a HOF that formats the cell's value with `numeral` using the provided `formatString`.
 *
 * @param {string} formatString The format string passed into `numeral` to format the cell's value
 */
export const mkNumberFormatterCRF =
  (formatString: string, { blankOnNil = false }: MkNumberFormatterCRFOptions = {}): AGGridValueFormatter =>
  ({ data, colDef: { field, name } }) => {
    if (__DEV__) {
      if (!field && !name) {
        return panic('Either `field` or `name` is required in `mkNumberFormatterCRF` but neither was supplied');
      }
    }
    const valueRaw = data[field!] && data[field!].value ? data[field!].value : data[field!] || data[name!];
    if (blankOnNil && _isNil(valueRaw)) {
      return '--';
    }
    if (Number.isNaN(valueRaw)) {
      return valueRaw;
    }
    return numeral(+valueRaw || 0).format(formatString);
  };

export const DollarExactCRF = mkNumberFormatterCRF('$0.00');
export const DollarRounded = mkNumberFormatterCRF('$0');
export const DollarUnitsCRF = mkNumberFormatterCRF('$0,0');
export const NumberUnitsCRF = mkNumberFormatterCRF('0,000.0a');

export const MatchTypeCRF = withRoundedBorder((({ data: { matchType } }: { data: { [key: string]: any } }) =>
  Option.of(matchType)
    .map((s) => s.toUpperCase())
    .getOrElse('EXACT')) as React.FC);
