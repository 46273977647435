import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'qs';
import { entityOperations } from 'src/store/modules/entityService';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import Loading from 'src/components/common/Loading';
import ReduxStore from 'src/types/store/reduxStore';
import { getLayoutForEntityOmni } from './OmniPageLayout/OmniPageLayout';
import { destructurePathName } from 'src/utils/urlParsing';
import { receiveEntity } from 'src/store/modules/entityService/operations';
import { omniEntityOperations } from 'src/store/modules/omni/omniEntity';
import { omniRetailersOperations } from 'src/store/modules/omni/omniRetailers';

const { fetchEntity } = entityOperations;
const { fetchAvailableFilter } = omniEntityOperations;
const { fetchOmniRetailers } = omniRetailersOperations;

enum PageType {
  KeywordSegment = 'keywordSegment',
  Segment = 'segment',
  State = 'state',
  City = 'city',
  Cbsa = 'cbsa',
  County = 'county'
}

const decideDocType = (tab: string | queryString.ParsedQs | string[] | queryString.ParsedQs[] | undefined) => {
  switch (tab) {
    case 'content':
      return 'contentScore';
    case 'reviews':
      return 'review';
    default:
      return 'priceAndAvailability';
  }
};

/**
 * Renders page layout for Omni based off of the current route of the user
 */
// eslint-disable-next-line react/prop-types
const OmniEntity: FC<RouteComponentProps> = ({ location }) => {
  const loadingSpinner = <Loading style={{ position: 'fixed' }} className="spinner" size={150} thickness={2} />;
  const app = useSelector((state: ReduxStore) => state.app);
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const { startWeek, endWeek } = mainTimePeriod;
  const { mainEntity } = useSelector((state: ReduxStore) => state.entityService);
  const user = useSelector((state: ReduxStore) => state.user);
  const omniRetailersState = useSelector((state: ReduxStore) => state.omniRetailers);
  const { vendor } = user.config;
  const { BeaconClientId } = vendor;

  const filters = useSelector((state: ReduxStore) => state.filters);
  const retailersInFilters = filters.retailer;

  const dispatch = useDispatch();

  //  parse out entityType and entityId from path
  const { pathname, search } = location;
  const [entityTypeInUrl, entityId] = destructurePathName(pathname);
  const entityType = ['keywordSegment', 'store'].includes(entityTypeInUrl) ? 'segment' : entityTypeInUrl;
  //  parse out which tab and subtab the current user is on
  const queryParams = queryString.parse(search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const { tab, subtab } = queryParams;

  //  when component mounts, fetch metadata for the current Omni entity based off of the entityType and entityId from the URL
  useEffect(() => {
    if (entityType && entityId) {
      if ([PageType.State, PageType.Cbsa, PageType.City, PageType.County].includes(entityType)) {
        dispatch(receiveEntity('mainEntity', { type: entityType, id: entityId }));
      } else {
        const retailerId = retailersInFilters && retailersInFilters.length === 1 ? String(retailersInFilters[0]) : '0';
        dispatch(fetchEntity('mainEntity', entityType, entityId, retailerId));

        if (entityType === 'product') {
          const docType = decideDocType(tab);
          const availableRetailerReq = {
            startWeekId: startWeek,
            endWeekId: endWeek,
            productIds: [entityId],
            docType
          };
          dispatch(fetchAvailableFilter(availableRetailerReq));
        } else {
          dispatch(fetchOmniRetailers());
        }
      }
    }
  }, [dispatch, entityType, entityId, BeaconClientId, startWeek, endWeek, location]);

  if (!mainEntity || !mainEntity.type || mainEntity.type !== entityType || omniRetailersState.isFetching) {
    return loadingSpinner;
  }

  const pageLayout = getLayoutForEntityOmni(app, tab, subtab, entityType);

  if (!pageLayout.CustomPageContainer) {
    pageLayout.CustomPageContainer = GenericPageContainer;
    console.error(`No \`CustomPageContainer\` found for page layout; we need one.`);
  }
  return <pageLayout.CustomPageContainer pageLayout={pageLayout} entity={mainEntity} />;
};

export default OmniEntity;
