import axios from 'axios';
import { AD_PLATFORM_TYPE } from 'sl-ad-campaign-manager-data-model';
import qs from 'querystring';

import Creators from './actions';
import { parseScheduledActionsIntoGroups, parseSummarizedScheduledActionsIntoGroups } from './selectors';

export const { receiveScheduledActionGroups } = Creators;

export const fetchScheduledActionGroups =
  (fetchSummary: boolean = false, platformType?: AD_PLATFORM_TYPE, scheduledActionGroupId?: string) =>
  (dispatch: any) => {
    if (fetchSummary) {
      return axios
        .get(`/apiAdManager/scheduledActions/summary`)
        .then(({ data }) => dispatch(receiveScheduledActionGroups(parseSummarizedScheduledActionsIntoGroups(data))));
    }
    const params: {
      platformType?: AD_PLATFORM_TYPE;
      scheduledActionGroupId?: string;
    } = {};
    if (platformType && scheduledActionGroupId) {
      params.platformType = platformType;
      params.scheduledActionGroupId = scheduledActionGroupId;
    }
    const stringifiedParams = qs.stringify(params);
    return axios
      .get(`/apiAdManager/scheduledActions${stringifiedParams ? `?${stringifiedParams}` : ''}`)
      .then(({ data }) => dispatch(receiveScheduledActionGroups(parseScheduledActionsIntoGroups(data))));
  };

export const fetchScheduledActionGroupsByEntity = (entity: any) => (dispatch: any) => {
  let apiName = '';
  const entityId = entity.id;
  let entityIdKey = '';
  if (entity.type === 'adCampaign') {
    entityIdKey = 'campaignId';
    apiName = 'getScheduledActionByCampaignId';
  } else if (entity.type === 'adPortfolio') {
    entityIdKey = 'portfolioId';
    apiName = 'getScheduledActionByPortfolioId';
  } else if (entity.type === 'adEntity') {
    entityIdKey = 'entityId';
    apiName = 'getScheduledActionByEntityId';
  }
  const body = {
    [entityIdKey]: entityId,
    platformType: entity.platformType
  };
  return axios
    .post(`/apiAdManager/scheduledActions/${apiName}`, body)
    .then(({ data }) => dispatch(receiveScheduledActionGroups(parseSummarizedScheduledActionsIntoGroups(data))));
};
