import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { hexToRgba, useStacklineTheme } from '@stackline/ui';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import ChevronRight from '@mui/icons-material/ChevronRight';

interface CompareOptionCardProps {
  title: string;
  subtitle: string;
  onClick: () => void;
  selected: boolean;
}

/**
 * Cards for comparison options (Metrics, Groups, Retailers)
 */
export default function CompareOptionCard({ title, subtitle, onClick, selected }: CompareOptionCardProps) {
  const theme = useStacklineTheme();
  return (
    <Flex
      flexDirection="column"
      padding={theme.spacing.md}
      border={`1px solid ${theme.colors.primaryGray}`}
      borderRadius={theme.spacing.sm}
      gap="sm"
      sx={{
        cursor: 'pointer',
        ...(selected
          ? {
              backgroundColor: hexToRgba(theme.colors.primaryLight, 0.3),
              border: `1px solid transparent`
            }
          : {
              '&:hover': {
                backgroundColor: theme.colors.secondaryPorcelain,
                border: `1px solid ${theme.colors.secondaryPorcelain}`
              }
            })
      }}
      onClick={onClick}
    >
      <Flex alignItems="center" gap="xxs">
        <Text
          sx={{
            fontWeight: 'bold'
          }}
          variant="subtitle1"
        >
          {title}
        </Text>
        <ChevronRight
          style={{
            width: theme.spacing.md,
            height: theme.spacing.md
          }}
        />
      </Flex>

      <Text variant="body2">{subtitle}</Text>
    </Flex>
  );
}
