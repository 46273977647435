import React from 'react';
import { SlColumn, SlRow, useStacklineTheme } from '@stackline/ui';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReduxStore from 'src/types/store/reduxStore';
import { useQueryParamValue } from 'src/utils/Hooks';
import { addPersistentQueryParams } from 'src/utils/browser';
import { getProductImageUrl } from 'src/utils/image';
import { ProductEntity } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/types';
import { formatTime } from 'src/utils/dateformatting';
import ArrowRight from '!svg-react-loader!../ProductGrowthMatrixCard/assets/arrowRight.svg'; // eslint-disable-line
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import ReviewStarsV2 from 'src/components/BeaconRedesignComponents/ProductReviewCard/StarsV2';

interface ReviewUserType {
  username: string;
}

interface ReviewType {
  product: ProductEntity;
  stacklineSku: string;
  reviewTime: string;
  reviewText: string;
  user: ReviewUserType;
  reviewId: string;
}

interface ProductReviewCardProps {
  review: ReviewType;
}

/**
 * ProductReviewCard Component
 *
 * Displays a card with information about a product review, including the product image,
 * brand, product name, date, customer, rating, and customer review text. Provides a link
 * to view more details about the product (redirect to product level).
 */
const ProductReviewCard: React.FC<ProductReviewCardProps> = ({ review }) => {
  const { product, stacklineSku, user, reviewTime, reviewText } = review;
  const theme = useStacklineTheme();
  const tab = useQueryParamValue('tab');
  const subTab = useQueryParamValue('subtab');
  const linkedSubtab = tab !== 'reviews' ? 'reviewMetrics' : subTab;
  const { retailer, mainTimePeriod } = useSelector((state: ReduxStore) => state);
  const { targetUrl, name } = useSelector((state: ReduxStore) => state.app);

  const queryParams = `${addPersistentQueryParams(
    retailer,
    mainTimePeriod
  )}&tab=reviews&subtab=${linkedSubtab}&ctype=metric&ctab=reviews&csubtab=reviewAverage`;
  const productUrl = `/product/${stacklineSku}?${queryParams}`;

  return (
    <div
      style={{
        width: '330px',
        height: '382px',
        padding: `12px 28px`,
        borderRadius: theme.spacing.sm,
        border: `1px solid ${theme.colors.primaryGray}`,
        position: 'relative'
      }}
    >
      <SlColumn>
        {/* Image, Brand and Product Name */}
        <SlRow horizontalPosition="center" verticalPosition="center">
          <Link to={productUrl} onClick={(event) => event.stopPropagation()}>
            <img
              style={{
                height: theme.spacing.xxl,
                maxWidth: theme.spacing.xxl,
                objectFit: 'contain'
              }}
              src={getProductImageUrl(stacklineSku)}
              alt={product.title}
            />
          </Link>

          <SlColumn horizontalInset="md">
            <SlRow>
              <Link to={`/brand/${product.brandId}?${queryParams}`}>
                <Text variant="subtitle2">{product.brandName}</Text>
              </Link>
            </SlRow>
            <SlRow verticalInset="xs">
              <Link to={productUrl}>
                <Text variant="body4" truncateLines={2}>
                  {product.title}
                </Text>
              </Link>
            </SlRow>
          </SlColumn>
        </SlRow>

        {/* Date and Customer */}
        <SlRow verticalInset="md">
          <SlColumn>
            <SlRow>
              <Text transform="uppercase" variant="subtitle4">
                Date
              </Text>
            </SlRow>
            <SlRow verticalInset="xs">
              <Text variant="body3">{formatTime(reviewTime, 'MMM D, YYYY')}</Text>
            </SlRow>
          </SlColumn>
          <SlColumn horizontalInset="mdl">
            <SlRow>
              <Text transform="uppercase" variant="subtitle4">
                Customer
              </Text>
            </SlRow>
            <SlRow verticalInset="xs">
              <Text variant="body3">{user.username}</Text>
            </SlRow>
          </SlColumn>
        </SlRow>

        {/* Rating and star */}
        <SlRow>
          <SlColumn>
            <SlRow>
              <Text transform="uppercase" variant="subtitle4">
                RATING
              </Text>
            </SlRow>
            <SlRow verticalInset="sm">
              <ReviewStarsV2 review={review}></ReviewStarsV2>
            </SlRow>
          </SlColumn>
        </SlRow>

        {/* Customer Review */}
        <SlRow>
          <SlColumn>
            <SlRow>
              <Text transform="uppercase" variant="subtitle4">
                CUSTOMER REVIEW
              </Text>
            </SlRow>
            <SlRow verticalInset="xs">
              <Text
                truncateLines={8}
                variant="body3"
                sx={{
                  lineHeight: 1.3
                }}
              >
                {reviewText}
              </Text>
            </SlRow>
          </SlColumn>
        </SlRow>
      </SlColumn>

      {/* View more button and arrow */}
      <div style={{ position: 'absolute', bottom: theme.spacing.md }}>
        <SlRow verticalPosition="center" spacing="xs">
          <Text
            variant="subtitle4"
            link
            onClick={() => {
              window.open(
                `https://${targetUrl}/api/utility/OpenRetailerProductReviewPage?appName=${name}&retailerId=${retailer.id}&stacklineSku=${review.stacklineSku}&reviewId=${review.reviewId}`,
                '_blank'
              );
            }}
          >
            View details
          </Text>
          <ArrowRight
            style={{
              position: 'relative',
              top: '2px'
            }}
          />
        </SlRow>
      </div>
    </div>
  );
};

export default ProductReviewCard;
