import React, { useState, useMemo } from 'react';
import SlCalendarPicker from 'src/components/BeaconRedesignComponents/common/SlCalendarPicker/SlCalendarPicker';
import { useAppSelector } from 'src/utils/Hooks';
import moment from 'moment-timezone';

import {
  capDateToCurrentDate,
  getFirstWeekFirstYear,
  getLastWeek,
  getWeekFirstDate,
  getWeekLastDate
} from 'src/utils/dateformatting';

/**
 * Wrapper containing logic for SlCalendarPicker for custom application time range filters
 */
const AppCalendarContainer = ({
  handleCustomDateRangeChange,
  open,
  setCalendarOpen
}: {
  handleCustomDateRangeChange: ({ startDate, endDate }: { startDate: Date; endDate: Date }) => void;
  open: boolean;
  setCalendarOpen: (b: boolean) => void;
}) => {
  const retailer = useAppSelector((state) => state.retailer);
  const allWeekIdsByRetailerId = useAppSelector((state) => state.allWeekIdsByRetailerId);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const { startWeekStartDate, endWeekEndDate, id: timePeriodType } = mainTimePeriod;

  const preFilledDates = useMemo(() => {
    return timePeriodType === 'cd' ? { start: startWeekStartDate, end: endWeekEndDate } : { start: null, end: null };
  }, []);

  /**
   * Selected date range state
   */
  const [selectedDateRange, setSelectedDateRange] = useState([preFilledDates.start, preFilledDates.end]);

  /**
   * Minimum available calendar date
   */
  const minDate = getWeekFirstDate(getFirstWeekFirstYear(allWeekIdsByRetailerId[+retailer.id]));
  /**
   * Maximum available calendar date
   */
  const maxDate = capDateToCurrentDate(getWeekLastDate(getLastWeek(allWeekIdsByRetailerId[+retailer.id])));

  const shouldDisableDate = (calendar: CalendarDate) => {
    const { date: calendarDate } = calendar;
    if (moment(calendarDate).isAfter(moment(maxDate)) || moment(calendarDate).isBefore(moment(minDate))) {
      return true;
    }
    return false;
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [selectedStartDate, selectedEndDate] = dates;
    setSelectedDateRange([selectedStartDate, selectedEndDate]);
  };

  const handleCalendarClose = () => {
    const [selectedStartDate, selectedEndDate] = selectedDateRange;
    setCalendarOpen(false);

    if (selectedStartDate !== startWeekStartDate && selectedEndDate !== endWeekEndDate) {
      handleCustomDateRangeChange({ startDate: selectedStartDate, endDate: selectedEndDate });
    }
  };

  return (
    <SlCalendarPicker
      open={open}
      onClose={handleCalendarClose}
      selectedDateRange={selectedDateRange}
      handleDateChange={handleDateChange}
      shouldDisableDate={shouldDisableDate}
    />
  );
};

export default AppCalendarContainer;
