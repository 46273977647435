import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';

import { store } from 'src/main';
import { encodeHash } from 'src/utils/hashIds';
import {
  STACKLINE_SUPER_USER_VALIDATOR_REGEX,
  STACKLINE_USER_VALIDATOR_REGEX,
  STACKLINE_DEPLOYMENT_USER_VALIDATOR_REGEX
} from 'src/utils/validators';
import ReduxStore from 'src/types/store/reduxStore';
import { createSelector } from 'reselect';
import { isAuthorizedShortagesClient } from 'src/utils/app';

export function checkIsRestrictedDemoUser(session: ReduxStore['user']['session']) {
  return (
    window.location.href.includes('atlas') &&
    (session.email.toLowerCase() === 'mlagoni+demo2@stackline.com' ||
      session.email.toLowerCase() === 'raj+restricted@stackline.com')
  );
}

export function addHashIdToUserConfig(config: {
  data: {
    userConfig: ReduxStore['user']['config'];
    session: ReduxStore['user']['session'];
  };
}) {
  const { userConfig } = config.data;
  // Add a hashId to the userConfig.vendor object and return the entire config
  const clonedConfig = _cloneDeep(config);
  if (clonedConfig.data && clonedConfig.data.userConfig) {
    clonedConfig.data.userConfig.isStacklineDeploymentSuperUser =
      clonedConfig.data &&
      clonedConfig.data.session &&
      STACKLINE_DEPLOYMENT_USER_VALIDATOR_REGEX.test(clonedConfig.data.session.email);
    clonedConfig.data.userConfig.isStacklineSuperUser =
      clonedConfig.data &&
      clonedConfig.data.session &&
      STACKLINE_SUPER_USER_VALIDATOR_REGEX.test(clonedConfig.data.session.email);
    clonedConfig.data.userConfig.isStacklineUser =
      clonedConfig.data &&
      clonedConfig.data.session &&
      STACKLINE_USER_VALIDATOR_REGEX.test(clonedConfig.data.session.email);
  }
  if (clonedConfig.data && clonedConfig.data.userConfig) {
    clonedConfig.data.userConfig.isRestrictedDemoUser = checkIsRestrictedDemoUser(config.data.session);
  }

  if (userConfig && userConfig.vendor && userConfig.vendor.BeaconClientId !== -1) {
    const { BeaconClientId } = userConfig.vendor;
    const hashId = encodeHash(BeaconClientId);
    clonedConfig.data.userConfig.vendor.hashId = hashId;
    return clonedConfig;
  }

  return clonedConfig;
}

export const onPrimeMode = (
  { retailer, user, app }: Pick<ReduxStore, 'retailer' | 'user' | 'app'> = store.getState()
) => {
  const { isRestrictedDemoUser, isStacklineUser, isStacklineSuperUser } = user.config && user.config;
  if (isRestrictedDemoUser) {
    return false;
  }
  if (
    !isStacklineSuperUser &&
    retailer.dataTypesAvailable.indexOf('traffic') >= 0 &&
    isStacklineUser &&
    app.stage === 'dev'
  ) {
    return true;
  }

  const subscriptionsByRetailers = _get(user, ['config', 'subscriptionsByRetailers'], {} as {});
  // "shopperbehavior" is enabled and retailer has to be (Amazon US any stage) or (Amazon UK only on dev)
  const enableBeaconPrimeMode =
    subscriptionsByRetailers &&
    subscriptionsByRetailers[retailer.id] &&
    subscriptionsByRetailers[retailer.id].PrimeSubscription &&
    subscriptionsByRetailers[retailer.id].PrimeSubscription === 'true';
  return enableBeaconPrimeMode;
};

export const onAmazonUS = ({ retailer }: Pick<ReduxStore, 'retailer'> = store.getState()) => retailer.id === '1';

/**
 * Redux selector that returns the email address of the currently signed in user
 */
export const curUserEmailSelector = createSelector(
  (state: ReduxStore) => state.user.session.email,
  (email) => email
);

/**
 * Redux selector that returns the email address of the currently signed in user
 */
export const curUserIdSelector = createSelector(
  (state: ReduxStore) => state.user.session.userId,
  (userId) => userId
);

/**
 * Redux selector that returns true if the user is a Pulse user, meaning they
 * have readonly access to Drive.
 */
export const isAdAuditUserSelector = createSelector(
  (state: ReduxStore) => _get(state, ['user', 'config', 'adAuditEnabled'], false),
  (isAuditUser) => isAuditUser
);

export const onDisplayAdvertisingMode = (
  { retailer, user, app }: Pick<ReduxStore, 'retailer' | 'user' | 'app'> = store.getState()
) => {
  const { isRestrictedDemoUser, isStacklineUser, isStacklineSuperUser } = user.config && user.config;
  if (isRestrictedDemoUser) {
    return false;
  }
  if (
    !isStacklineSuperUser &&
    retailer.dataTypesAvailable.indexOf('displayAdvertising') >= 0 &&
    isStacklineUser &&
    app.stage === 'dev'
  ) {
    return true;
  }
  const modulesSubscribed = _get(user, ['config', 'modulesSubscribed'], {} as {});
  const enableDisplayAdvertisingMode =
    modulesSubscribed && modulesSubscribed.indexOf('displayadvertising') >= 0 && retailer.id === '1';
  return enableDisplayAdvertisingMode;
};

export const onChargebacksMode = (
  { retailer, user }: Pick<ReduxStore, 'retailer' | 'user' | 'app'> = store.getState()
) => {
  const { isRestrictedDemoUser } = user.config && user.config;
  if (isRestrictedDemoUser) {
    return false;
  }
  const modulesSubscribed: string[] = _get(user, ['config', 'modulesSubscribed'], []);
  const subscribedToChargebacks = modulesSubscribed.indexOf('chargebacks') >= 0;
  const retailerEnabledForChargebacks =
    _get(user, ['config', 'subscriptionsByRetailers', retailer.id, 'chargebacks']) === 'true';
  return subscribedToChargebacks && retailerEnabledForChargebacks;
};

// TODO: We should refactor this to use split, at least for retailer IDs and emails
export const onShortagesMode = (
  { retailer, user }: Pick<ReduxStore, 'retailer' | 'user' | 'app'> = store.getState()
) => {
  const { isRestrictedDemoUser } = user.config && user.config;
  if (isRestrictedDemoUser) {
    return false;
  }

  const modulesSubscribed: string[] = _get(user, ['config', 'modulesSubscribed'], []);
  const enableShortagesMode =
    modulesSubscribed &&
    modulesSubscribed.indexOf('shortagedisputes') >= 0 &&
    ['1', '11'].includes(retailer.id) && // Enabled for Amazon US & Amazon Canada only
    ((user.config.profile.email.endsWith('@stackline.com') && user.config) || isAuthorizedShortagesClient()); // Enable for Client 344 as well as internal users

  return enableShortagesMode;
};

export const onBasicAdvertisingMode = (
  { retailer, user, app }: Pick<ReduxStore, 'retailer' | 'user' | 'app'> = store.getState()
) => {
  const { isRestrictedDemoUser, isStacklineUser, isStacklineSuperUser } = user.config && user.config;
  if (isRestrictedDemoUser) {
    return false;
  }
  if (
    !isStacklineSuperUser &&
    retailer.dataTypesAvailable.indexOf('displayAdvertising') >= 0 &&
    isStacklineUser &&
    app.stage === 'dev'
  ) {
    return true;
  }
  const modulesSubscribed = _get(user, ['config', 'modulesSubscribed'], {} as {});
  const basicAdvertisingMode =
    modulesSubscribed.indexOf('displayadvertising') < 0 ||
    (modulesSubscribed.indexOf('displayadvertising') >= 0 && retailer.id !== '1');
  return basicAdvertisingMode;
};
