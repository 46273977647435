import React, { useEffect, useState } from 'react';
import {
  Dispute,
  DisputeRow
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/types';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import StyledDialog from 'src/components/BeaconRedesignComponents/common/StyledDialog/StyledDialog';
import { Text, useStacklineTheme } from '@stackline/ui';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import { Box } from '@mui/system';
import { BluePlus, DocumentIcon, CheckCircle, SmallCancelIcon, CloseIcon } from 'src/components/SvgIcons';
import { useAppSelector, useGenericPopup, useSnackbar } from 'src/utils/Hooks';
import * as serverProxy from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/serverProxy';
import { Ring } from '@uiball/loaders';
import { useQueryClient } from 'react-query';
import { SUPPORTING_DOCS_QUERY_KEY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/constants';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

enum DOC_TYPE {
  BOL = 'BillOfLading',
  INVOICE = 'Invoice',
  ASN = 'AmazonShippingNotification',
  ARN = 'AmazonRoutingNotification',
  CONTACT_US_CASE = 'ContactUsCase',
  OTHER = 'Other'
}

interface DropdownOption {
  label: string;
  id: DOC_TYPE;
}

const documentOptions: DropdownOption[] = [
  { label: 'Bill of Lading', id: DOC_TYPE.BOL },
  { label: 'Invoice', id: DOC_TYPE.INVOICE },
  { label: 'Amazon Shipping Notification', id: DOC_TYPE.ASN },
  { label: 'Amazon Routing Notification', id: DOC_TYPE.ARN },
  { label: 'Contact Us Case', id: DOC_TYPE.CONTACT_US_CASE },
  { label: 'Other', id: DOC_TYPE.OTHER }
];

const allowedFileTypes = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

const UploadMessage = ({ messageType, message }: { messageType: 'success' | 'error'; message: string }) => {
  const theme = useStacklineTheme();
  const icon =
    messageType === 'success' ? (
      <CheckCircle style={{ fill: theme.colors.success, width: '24px' }} />
    ) : (
      <SmallCancelIcon style={{ fill: theme.colors.error, width: '24px' }} />
    );
  return (
    <Flex marginTop={theme.spacing.md} justifyContent="center">
      <Flex alignItems="center" gap="sm">
        {icon}
        <Text color={messageType} variant="subtitle3">
          {message}
        </Text>
      </Flex>
    </Flex>
  );
};

const DisputeFileUploadModal = ({
  row,
  open,
  handleClose
}: {
  row: DisputeRow | Dispute;
  open: boolean;
  handleClose: () => void;
}) => {
  const theme = useStacklineTheme();
  const [documentType, setDocumentType] = useState<DOC_TYPE | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<{ message: string; status: 'success' | 'error' } | null>(null);
  const queryClient = useQueryClient();

  const { showPopup, closePopup } = useGenericPopup();
  const { showSnackbar } = useSnackbar();

  const retailerId = useAppSelector((state) => state.retailer.id);
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  useEffect(() => {
    if (open) {
      setDocumentType(null);
      setUploadStatus(null);
      setUploadedFile(null);
    }
  }, [open]);

  const handleSubmitDocument = async () => {
    try {
      const { originalInvoice } = row;
      const formData = new FormData();
      formData.append('file', uploadedFile, uploadedFile.name);
      formData.append('Metadata[DocumentType]', documentType);
      formData.append('Metadata[InvoiceNumber]', originalInvoice);
      formData.append('Metadata[RetailerId]', retailerId);
      formData.append('Metadata[BeaconClientId]', String(beaconClientId));
      formData.append('ShouldOverwrite', 'true');

      handleClose();
      showPopup({
        open: true,
        width: 247,
        height: 249,
        titleMessage: 'Submitting updates',
        message:
          'We received your submission and will notify you when the processing is complete. You can close this window.',
        icon: <Ring size={64} speed={2} lineWeight={5} color={theme.colors.primary} />,
        bodyTextVariant: 'body3'
      });
      await serverProxy.uploadFile(formData);
      queryClient.invalidateQueries({ queryKey: [SUPPORTING_DOCS_QUERY_KEY] });
      closePopup();
      showSnackbar({ type: 'success', message: 'Processing is complete. Your file upload was successful.' });
    } catch (err) {
      handleClose();
      closePopup();
      showSnackbar({ type: 'error', message: 'Processing failed. Your file upload was unsuccessful.' });
      console.error('Failed to submit documents: ', err);
    }
  };

  return (
    <StyledDialog padding="0" open={open} onClose={handleClose} width={752} height={641}>
      {/* Header */}
      <Flex justifyContent="flex-start" alignItems="center" paddingX="48px" paddingTop="24px">
        <Text variant="h2">Upload Supporting Documents</Text>
        <CloseIcon
          onClick={handleClose}
          style={{ position: 'absolute', left: '700px', cursor: 'pointer', width: '24px', height: '24px' }}
        ></CloseIcon>
      </Flex>

      <Flex paddingX="48px" paddingTop={theme.spacing.md}>
        <Text variant="body2">Upload relevant documents here. What type of file would you like to upload?</Text>
      </Flex>
      <Flex paddingX="48px" paddingTop={theme.spacing.sm}>
        <Text variant="subtitle2">Note:&nbsp;</Text>
        <Text variant="body2">PDF, PNG or XLSX files only</Text>
      </Flex>
      <Flex flexDirection="column" gap="sm" paddingX="48px" paddingTop={theme.spacing.md}>
        <Text variant="subtitle2">Supporting Document</Text>
        <SlDropdownMenu
          options={documentOptions}
          defaultLabel="Document Type"
          selectedId={documentType}
          shouldShowFullItemText
          onChange={({ id }) => setDocumentType(id as DOC_TYPE)}
        />
      </Flex>

      {/* File upload */}
      <Flex flexDirection="column" paddingX="48px" marginTop={theme.spacing.lg} height="100%">
        <Text variant="subtitle2">Upload Documents</Text>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          marginTop={theme.spacing.md}
          border={`1px solid ${theme.colors.primaryGray}`}
          height="180px"
          borderRadius={theme.spacing.sm}
        >
          <button style={{ background: 'none', border: 'none' }}>
            <label htmlFor="file-input">
              {uploadedFile ? (
                <Flex alignItems="center" gap="sm">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="24px"
                    height="24px"
                    borderRadius="50px"
                    sx={{ backgroundColor: theme.colors.primary }}
                  >
                    <DocumentIcon style={{ fill: '#ffffff', width: '18px', height: '18px' }} />
                  </Box>
                  <Text variant="body2">{uploadedFile.name}</Text>
                </Flex>
              ) : (
                <BluePlus role="button" style={{ width: theme.spacing.mdl, height: theme.spacing.mdl }} />
              )}
            </label>
            <input
              onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                  try {
                    if (allowedFileTypes.includes(file.type)) {
                      setUploadedFile(file);
                      setUploadStatus({
                        message: 'Upload complete. Your file was successfully uploaded.',
                        status: 'success'
                      });
                    } else {
                      throw new Error('The uploaded file does not meet the required specifications. Please try again.');
                    }
                  } catch (err) {
                    setUploadStatus({
                      message: 'The uploaded file does not meet the required specifications. Please try again.',
                      status: 'error'
                    });
                  }
                }
              }}
              style={{ display: 'none' }}
              id="file-input"
              type="file"
              accept=".PDF,.PNG,XLSX"
            />
          </button>
        </Box>
        {!uploadedFile && !uploadStatus ? null : (
          <UploadMessage message={uploadStatus.message} messageType={uploadStatus.status} />
        )}
      </Flex>
      <Flex justifyContent="flex-end" paddingBottom="48px" paddingRight="48px">
        <SlButton
          onClick={handleSubmitDocument}
          disabled={!(documentType && uploadedFile && uploadStatus.status !== 'error')}
          variant="contained"
        >
          Submit
        </SlButton>
      </Flex>
    </StyledDialog>
  );
};

export default DisputeFileUploadModal;
