import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _last from 'lodash/last';
import _union from 'lodash/union';
import moment from 'moment-timezone';
import { store } from 'src/main';
import { getStartAndEndOfWeek, getWeekId } from 'src/utils/dateUtils';
import { panic } from 'src/utils/mixpanel';
import { isDrive } from './app';

const currentYear = 1;
const timeOffset = new Date().getTimezoneOffset() * 60000;
const currentStartWeek = 38;
const startYear = 2016;
const DAY_ID_FORMAT = 'YYYYMMDD';

export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getDayIdFromDate = (date) => +moment(date).format(DAY_ID_FORMAT);

function addDays(dateValue, days) {
  const tmpDate = new Date(dateValue);
  tmpDate.setDate(tmpDate.getDate() + days);
  return tmpDate;
}

export const formatTime = (value, formatStr = 'MM/DD/YY HH:mm:ss') => {
  return moment(value).format(formatStr);
};

/**
 * Returns a string representing the format of the formatStr, based on unix timestamp`.
 */
export const formatUnixToTime = (value, formatStr = 'MM/DD/YYYY') => {
  return moment.unix(value).format(formatStr);
};

export const getFirstWeekOfYear = (weekId) => weekId - (weekId % 100) + 1;

export const getLastWeekOfYear = (weekId) => {
  const year = Math.floor(weekId / 100);
  const lastWeekId = weekId - (weekId % 100) + (moment([year]).isLeapYear() ? 53 : 52);
  return lastWeekId;
};

export const getLastWeek = (weekIds) => weekIds[weekIds.length - 1];

export const getLastWeekPreviousYear = (weekIds) => {
  const lastWeek = getLastWeek(weekIds);
  const previousYear = Math.floor((lastWeek - 100) / 100) * 100;
  return weekIds.reduce((prevVal, currVal) => {
    if (currVal > prevVal && Math.floor(currVal / 100) === Math.floor(previousYear / 100)) {
      return currVal;
    }
    return prevVal;
  }, previousYear);
};

//  This function should be replaced with the Comparison Time Period function
export function getPreviousDateRange(mainTimePeriod, weekIds, comparisonTimePeriod) {
  const currentPeriodStartWeekId = mainTimePeriod.startWeek;
  const currentPeriodEndWeekId = mainTimePeriod.endWeek;
  let difference = 0;
  const currentPeriodStartWeekYear = Math.floor(currentPeriodStartWeekId / 100);
  const currentPeriodEndWeekYear = Math.floor(currentPeriodEndWeekId / 100);
  const lastWeekPreviousYear = getLastWeekPreviousYear(weekIds);
  const firstWeekCurrentYear = getFirstWeekOfYear(currentPeriodEndWeekId);
  if (currentPeriodStartWeekYear === currentPeriodEndWeekYear) {
    difference = currentPeriodEndWeekId - currentPeriodStartWeekId;
  } else {
    difference = currentPeriodEndWeekId - firstWeekCurrentYear + 1 + (lastWeekPreviousYear - currentPeriodStartWeekId);
  }

  if (comparisonTimePeriod.id === 'prior-year') {
    return {
      startWeek: mainTimePeriod.startWeek - 100,
      endWeek: mainTimePeriod.endWeek - 100,
      difference
    };
  }

  const previousPeriodEndWeekId =
    (currentPeriodStartWeekId - 1) % 100 < 1 ? lastWeekPreviousYear : currentPeriodStartWeekId - 1;
  let previousPeriodStartWeekId = null;
  if ((previousPeriodEndWeekId - difference) % 100 < 1 || (previousPeriodEndWeekId - difference) % 100 > 60) {
    const remainder = previousPeriodEndWeekId - firstWeekCurrentYear + 1;
    previousPeriodStartWeekId = lastWeekPreviousYear - (difference - remainder);
    if (weekIds.indexOf(previousPeriodStartWeekId) === 1) {
      previousPeriodStartWeekId = getFirstWeekOfYear(lastWeekPreviousYear);
    }
  } else {
    previousPeriodStartWeekId = previousPeriodEndWeekId - difference;
  }

  return {
    startWeek: previousPeriodStartWeekId,
    endWeek: previousPeriodEndWeekId,
    difference
  };
}

export const getFirstWeekFirstYear = (weekIds) =>
  weekIds.reduce((prevVal, curVal) => (curVal < prevVal ? curVal : prevVal), 400000);

export function getWeekIdMinValue(weekIds, mainTimePeriod) {
  if (mainTimePeriod.id === 'ytd' || mainTimePeriod.id === 'ly') {
    return getFirstWeekOfYear(getLastWeek(weekIds));
  }

  const period = mainTimePeriod.id.toLowerCase() === 'lw' ? '1w' : mainTimePeriod.id;
  const weeksToGoBack = parseInt(period.replace(/w/gi, ''), 10);
  if (weekIds.length >= weeksToGoBack) {
    return weekIds[weekIds.length - weeksToGoBack];
  }
  return weekIds[0];
}

export const getWeekIdRangeFilter = (weekIds, mainTimePeriod) => ({
  fieldName: 'weekId',
  minValue:
    mainTimePeriod && mainTimePeriod.startWeek ? mainTimePeriod.startWeek : getWeekIdMinValue(weekIds, mainTimePeriod),
  maxValue: mainTimePeriod && mainTimePeriod.endWeek ? mainTimePeriod.endWeek : getLastWeek(weekIds)
});

/**
 * Returns a `Date` representing the first day of the provided `weekId`.
 */
export function getWeekFirstDate(weekId, asDayId = false) {
  const hasExtraWeek = weekId % 100 === 53;

  // Moment will return invalid date if it's the 53rd week,
  // so if there are 53 then use the 52nd and add back a week to
  // the final date
  const weekIdToUse = hasExtraWeek ? weekId - 1 : weekId;
  const day = moment(`${weekIdToUse}`, 'YYYYWW').day('Sunday');

  // Add a week if we have an extra week
  const dayToUse = hasExtraWeek ? day.add(1, 'week') : day;
  return asDayId ? +dayToUse.format(DAY_ID_FORMAT) : dayToUse.toDate();
}

export function capDateToCurrentDate(date) {
  if (date > new Date()) {
    return addDays(new Date(), -1);
  }
  return date;
}

/**
 * Returns a `Date` representing the last day of the provided `weekId`.
 */
export function getWeekLastDate(weekId, asDayId = false) {
  const ISOweekStart = getWeekFirstDate(weekId);
  const weekEndDate = addDays(ISOweekStart, 6 - ISOweekStart.getDay());
  return asDayId ? +moment(weekEndDate).format(DAY_ID_FORMAT) : weekEndDate;
}

export const getLastDayPreviousYear = (weekIds) => {
  const lastWeek = getLastWeek(weekIds);
  // this is 31st december of last year. This date will always exist
  return Math.floor((lastWeek - 100) / 100) * 10000 + 1231;
};

export function getWeekLastDay(week, year, asDayId = false) {
  const currDate = getWeekLastDate(week);

  if (asDayId) {
    return +moment(currDate).format(DAY_ID_FORMAT);
  }

  return year
    ? `${monthNames[currDate.getMonth()]} ${currDate.getDate()}, ${currDate.getFullYear()}`
    : `${monthNames[currDate.getMonth()]} ${currDate.getDate()}`;
}

const computeLastWeek = (currentWeek) => {
  const year = Math.floor(currentWeek / 100);
  const week = currentWeek % 100;

  if (week === 1) {
    if (moment([year - 1]).isLeapYear()) {
      return currentWeek - 48;
    }

    return currentWeek - 49;
  }

  return currentWeek - 1;
};

export const subtractWeeks = (startWeek, endWeek) => {
  let count = 1;
  let currWeek = endWeek;

  while (startWeek !== currWeek || count > 999) {
    count++;
    currWeek = computeLastWeek(currWeek);
  }

  return count;
};

export const goBackNWeeks = (endWeek, numberOfWeeks) => {
  let startWeek = endWeek;

  for (let i = 1; i < numberOfWeeks; i++) {
    startWeek = computeLastWeek(startWeek);
  }

  return startWeek;
};

export function buildCustomDateRangeDisplayName(startDayId, endDayId) {
  const displayName = `${moment(startDayId, DAY_ID_FORMAT).format('MMM D, YYYY')} - ${moment(
    endDayId,
    DAY_ID_FORMAT
  ).format('MMM D, YYYY')}`;

  return displayName;
}

export const updateComparisonTimePeriodForExports = (mainTimePeriod, comparisonTimePeriod) => {
  const mainPeriod = _cloneDeep(mainTimePeriod);
  const comparisonPeriod = _cloneDeep(mainTimePeriod);

  comparisonPeriod.endWeek -= 100;
  const numberOfWeeks = subtractWeeks(mainTimePeriod.startWeek, mainTimePeriod.endWeek);
  comparisonPeriod.startWeek = goBackNWeeks(comparisonPeriod.endWeek, numberOfWeeks);
  comparisonPeriod.shortDisplayName = 'Prior-Year';

  // Make sure the same range but different year.
  if (comparisonTimePeriod.id === 'prior-year') {
    comparisonPeriod.endWeek -= 100;
    comparisonPeriod.startWeek = goBackNWeeks(comparisonPeriod.endWeek, numberOfWeeks);

    comparisonPeriod.startDayId = +moment(`${comparisonPeriod.startWeek}`, 'YYYYWW').format(DAY_ID_FORMAT);

    comparisonPeriod.endDayId = +moment(`${comparisonPeriod.endWeek}`, 'YYYYWW').format(DAY_ID_FORMAT);

    comparisonPeriod.displayName = buildCustomDateRangeDisplayName(
      comparisonPeriod.startDayId,
      comparisonPeriod.endDayId
    );

    comparisonPeriod.startWeekStartDate = moment(`${comparisonPeriod.startDayId}`).toDate();
    comparisonPeriod.endWeekEndDate = moment(`${comparisonPeriod.endDayId}`).toDate();
  }

  return {
    mainPeriod,
    comparisonPeriod
  };
};

const computeDriveComparisonTimePeriod = (allWeekIds, mainTimePeriod, comparisonTimeWindow) => {
  const timePeriodToReturn = _cloneDeep(mainTimePeriod);
  timePeriodToReturn.id = comparisonTimeWindow;
  // Prior-Period
  if (comparisonTimeWindow === 'prior-period') {
    timePeriodToReturn.shortDisplayName = 'Prior-Period';
    const startWeekIdIndex = allWeekIds.indexOf(mainTimePeriod.startWeek);
    const endWeekIdIndex = allWeekIds.indexOf(mainTimePeriod.endWeek);
    if (startWeekIdIndex === 0) {
      timePeriodToReturn.startWeek -= 100;
      timePeriodToReturn.endWeek -= 100;
    }
    // MTD
    else if (mainTimePeriod.id === 'mtd') {
      timePeriodToReturn.startDayId = parseInt(
        moment(`${mainTimePeriod.startDayId}`).subtract(1, 'month').startOf('month').format(DAY_ID_FORMAT),
        10
      );
      // Last day of the month
      // timePeriodToReturn.endDayId = parseInt(
      //   moment(`${mainTimePeriod.endDayId}`).subtract(1, 'month').endOf('month').format(DAY_ID_FORMAT),
      //   10
      // );
      timePeriodToReturn.endDayId = parseInt(
        moment(`${mainTimePeriod.endDayId}`).subtract(1, 'month').format(DAY_ID_FORMAT),
        10
      );
      timePeriodToReturn.startWeekStartDate = moment(`${timePeriodToReturn.startDayId}`).toDate();
      timePeriodToReturn.endWeekEndDate = moment(`${timePeriodToReturn.endDayId}`).toDate();
      timePeriodToReturn.startWeek = getWeekId(timePeriodToReturn.startWeekStartDate);
      timePeriodToReturn.endWeek = getWeekId(timePeriodToReturn.endWeekEndDate);
      return timePeriodToReturn;
    }
    // WEEKS
    else {
      let weeksInTimeWindow = endWeekIdIndex - startWeekIdIndex + 1;
      if (weeksInTimeWindow === 1) {
        weeksInTimeWindow = 1;
      }
      timePeriodToReturn.endWeek = allWeekIds[startWeekIdIndex - 1];
      if (startWeekIdIndex - weeksInTimeWindow < 0) {
        [timePeriodToReturn.startWeek] = allWeekIds;
      } else {
        timePeriodToReturn.startWeek = allWeekIds[startWeekIdIndex - weeksInTimeWindow];
      }
    }
    const daysDiff = moment(`${mainTimePeriod.endDayId}`).diff(moment(`${mainTimePeriod.startDayId}`), 'days');
    const endWeekEndDate = moment(`${mainTimePeriod.startDayId}`).subtract(1, 'day').toDate();
    const startWeekStartDate = moment(`${mainTimePeriod.startDayId}`)
      .subtract(daysDiff + 1, 'day')
      .toDate();

    timePeriodToReturn.startWeekStartDate = startWeekStartDate;
    timePeriodToReturn.endWeekEndDate = endWeekEndDate;
    timePeriodToReturn.startDayId = +moment(startWeekStartDate).format(DAY_ID_FORMAT);
    timePeriodToReturn.endDayId = +moment(endWeekEndDate).format(DAY_ID_FORMAT);
    return timePeriodToReturn;
  }

  // -- Prior-Year --
  timePeriodToReturn.endWeek -= 100;
  const numberOfWeeks = subtractWeeks(mainTimePeriod.startWeek, mainTimePeriod.endWeek);
  timePeriodToReturn.startWeek = goBackNWeeks(timePeriodToReturn.endWeek, numberOfWeeks);
  timePeriodToReturn.shortDisplayName = 'Prior-Year';

  // YTD
  if (mainTimePeriod.id === 'ytd') {
    timePeriodToReturn.startDayId = +moment(`${timePeriodToReturn.startWeek}`, 'YYYYWW')
      .startOf('year')
      .startOf('month')
      .format(DAY_ID_FORMAT);

    timePeriodToReturn.endDayId = parseInt(
      moment(`${mainTimePeriod.endDayId}`).subtract(1, 'year').format(DAY_ID_FORMAT),
      10
    );

    timePeriodToReturn.startWeekStartDate = moment(`${timePeriodToReturn.startDayId}`).toDate();
    timePeriodToReturn.endWeekEndDate = moment(`${timePeriodToReturn.endDayId}`).toDate();
    return timePeriodToReturn;
  }
  // MTD
  else if (mainTimePeriod.id === 'mtd') {
    timePeriodToReturn.startDayId = parseInt(getWeekFirstDate(timePeriodToReturn.startWeek, true), 10);
    timePeriodToReturn.startWeekStartDate = moment(`${timePeriodToReturn.startDayId}`).toDate();

    timePeriodToReturn.endDayId = parseInt(
      moment(`${mainTimePeriod.endDayId}`).subtract(1, 'year').format(DAY_ID_FORMAT),
      10
    );
    timePeriodToReturn.endWeekEndDate = moment(`${timePeriodToReturn.endDayId}`).toDate();
    return timePeriodToReturn;
  }
  // Rest: Weeks (All type) and Custom Date Range (CD)
  else {
    timePeriodToReturn.startDayId = parseInt(
      moment(`${mainTimePeriod.startDayId}`, DAY_ID_FORMAT).subtract(1, 'year').format(DAY_ID_FORMAT),
      10
    );
    timePeriodToReturn.endDayId = parseInt(
      moment(`${mainTimePeriod.endDayId}`, DAY_ID_FORMAT).subtract(1, 'year').format(DAY_ID_FORMAT),
      10
    );

    timePeriodToReturn.startWeekStartDate = moment(`${timePeriodToReturn.startDayId}`).toDate();
    timePeriodToReturn.endWeekEndDate = moment(`${timePeriodToReturn.endDayId}`).toDate();
    return timePeriodToReturn;
  }
};

/**
 * NEW FUNCTIONS TO CALCULATE COMPARISON TIME PERIOD
 * @param {string} comparisonTimeWindow is an id string ie: 'prior-year' or 'prior-period'
 */
export function computeComparisonTimePeriod(allWeekIds, mainTimePeriod, comparisonTimeWindow) {
  if (isDrive) {
    return computeDriveComparisonTimePeriod(allWeekIds, mainTimePeriod, comparisonTimeWindow);
  }
  const timePeriodToReturn = _cloneDeep(mainTimePeriod);
  timePeriodToReturn.id = comparisonTimeWindow;
  // Prior-Period
  if (comparisonTimeWindow === 'prior-period') {
    timePeriodToReturn.shortDisplayName = 'Prior-Period';
    const startWeekIdIndex = allWeekIds.indexOf(mainTimePeriod.startWeek);
    const endWeekIdIndex = allWeekIds.indexOf(mainTimePeriod.endWeek);
    if (startWeekIdIndex === 0) {
      timePeriodToReturn.startWeek -= 100;
      timePeriodToReturn.endWeek -= 100;
    } else if (mainTimePeriod.id === 'mtd') {
      timePeriodToReturn.startDayId = parseInt(
        moment(`${mainTimePeriod.startDayId}`).subtract(1, 'month').format(DAY_ID_FORMAT),
        10
      );
      timePeriodToReturn.endDayId = parseInt(
        moment(`${mainTimePeriod.endDayId}`).subtract(1, 'month').format(DAY_ID_FORMAT),
        10
      );
      timePeriodToReturn.startWeekStartDate = moment(`${timePeriodToReturn.startDayId}`).toDate();
      timePeriodToReturn.endWeekEndDate = moment(`${timePeriodToReturn.endDayId}`).toDate();
      timePeriodToReturn.startWeek = getWeekId(timePeriodToReturn.startWeekStartDate);
      timePeriodToReturn.endWeek = getWeekId(timePeriodToReturn.endWeekEndDate);
      return timePeriodToReturn;
    } else {
      let weeksInTimeWindow = endWeekIdIndex - startWeekIdIndex + 1;
      if (weeksInTimeWindow === 1) {
        weeksInTimeWindow = 1;
      }
      timePeriodToReturn.endWeek = allWeekIds[startWeekIdIndex - 1];
      if (startWeekIdIndex - weeksInTimeWindow < 0) {
        [timePeriodToReturn.startWeek] = allWeekIds;
      } else {
        timePeriodToReturn.startWeek = allWeekIds[startWeekIdIndex - weeksInTimeWindow];
      }
    }
    const daysDiff = moment(`${mainTimePeriod.endDayId}`).diff(moment(`${mainTimePeriod.startDayId}`), 'days');
    const endWeekEndDate = moment(`${mainTimePeriod.startDayId}`).subtract(1, 'day').toDate();
    const startWeekStartDate = moment(`${mainTimePeriod.startDayId}`)
      .subtract(daysDiff + 1, 'day')
      .toDate();

    timePeriodToReturn.startWeekStartDate = startWeekStartDate;
    timePeriodToReturn.endWeekEndDate = endWeekEndDate;
    timePeriodToReturn.startDayId = +moment(startWeekStartDate).format(DAY_ID_FORMAT);
    timePeriodToReturn.endDayId = +moment(endWeekEndDate).format(DAY_ID_FORMAT);
    return timePeriodToReturn;
  }

  // Prior-Year
  timePeriodToReturn.endWeek -= 100;
  const numberOfWeeks = subtractWeeks(mainTimePeriod.startWeek, mainTimePeriod.endWeek);
  timePeriodToReturn.startWeek = goBackNWeeks(timePeriodToReturn.endWeek, numberOfWeeks);
  timePeriodToReturn.shortDisplayName = 'Prior-Year';

  timePeriodToReturn.startDayId = parseInt(getWeekFirstDate(timePeriodToReturn.startWeek, true), 10);

  // const comparisonIsLeapYear = moment(Math.floor(timePeriodToReturn.endWeek / 100)).isLeapYear();

  timePeriodToReturn.endDayId = getWeekLastDay(timePeriodToReturn.endWeek, undefined, true);

  timePeriodToReturn.startWeekStartDate = moment(`${timePeriodToReturn.startDayId}`).toDate();
  timePeriodToReturn.endWeekEndDate = moment(`${timePeriodToReturn.endDayId}`).toDate();
  return timePeriodToReturn;
}

export function getDay(dayId) {
  const date = new Date(dayId);
  return `${monthNames[date.getMonth()]} ${date.getDate()}`;
}

export function getWeekNumber(date) {
  // Copy date so don't modify original
  const d = new Date(+date);
  d.setHours(0, 0, 0);
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  // Get first day of year
  const yearStart = new Date(d.getFullYear(), 0, 1);
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getFullYear(), weekNo, d.getFullYear() * 100 + weekNo];
}

export function getFormattedWeekNumber(date) {
  let clonedDate = date;
  if (clonedDate.week === undefined) {
    clonedDate = moment(clonedDate);
  }
  // if week number is 1 and month is 11 (december), then add 1 to year to handle year switch
  let [year, week] = [clonedDate.year(), clonedDate.isoWeek()];
  if (clonedDate.month() === 11 && clonedDate.isoWeek() === 1) {
    [year, week] = [clonedDate.year() + 1, clonedDate.isoWeek()];
  } else if (clonedDate.month() === 0 && clonedDate.isoWeek() === 53) {
    [year, week] = [clonedDate.year() - 1, clonedDate.isoWeek()];
  }

  return `${year}${week < 10 ? `0${week}` : week}`;
}

export const getFirstWeekOfMonth = (weekId) => {
  const startDate = getWeekFirstDate(weekId);
  const monthStartDate = moment(startDate).startOf('month').toDate();
  return +getFormattedWeekNumber(monthStartDate);
};

function addStartAndEndDate(timePeriod) {
  const { startDate } = getStartAndEndOfWeek(timePeriod.startWeek, false);
  const { endDate } = getStartAndEndOfWeek(timePeriod.endWeek, false);
  timePeriod.startDayId = +startDate;
  timePeriod.endDayId = +endDate;
  timePeriod.startWeekStartDate = moment(startDate).toDate();
  timePeriod.endWeekEndDate = moment(endDate).toDate();
}

function computeDatesForLastMonth(timePeriodToReturn) {
  const lastMonth = moment().subtract(1, 'month');
  timePeriodToReturn.startWeekStartDate = lastMonth.startOf('month').toDate();
  timePeriodToReturn.endWeekEndDate = lastMonth.endOf('month').toDate();
  timePeriodToReturn.startWeek = getWeekId(timePeriodToReturn.startWeekStartDate);
  timePeriodToReturn.endWeek = getWeekId(timePeriodToReturn.endWeekEndDate);
  timePeriodToReturn.startDayId = getDayIdFromDate(timePeriodToReturn.startWeekStartDate);
  timePeriodToReturn.endDayId = getDayIdFromDate(timePeriodToReturn.endWeekEndDate);
}

function computeDatesForLast3Months(timePeriodToReturn) {
  const lastMonth = moment().subtract(1, 'month');
  const threeMonthsAgo = moment().subtract(3, 'month');

  timePeriodToReturn.startWeekStartDate = threeMonthsAgo.startOf('month').toDate();
  timePeriodToReturn.endWeekEndDate = lastMonth.endOf('month').toDate();
  timePeriodToReturn.startWeek = getWeekId(timePeriodToReturn.startWeekStartDate);
  timePeriodToReturn.endWeek = getWeekId(timePeriodToReturn.endWeekEndDate);
  timePeriodToReturn.startDayId = getDayIdFromDate(timePeriodToReturn.startWeekStartDate);
  timePeriodToReturn.endDayId = getDayIdFromDate(timePeriodToReturn.endWeekEndDate);
}

function computeDatesForPeriodToDateTimePeriodForDailyData(timePeriodToReturn) {
  if (timePeriodToReturn.id === 'ytd') {
    const endWeekEndDate = moment().subtract(1, 'day').toDate();
    timePeriodToReturn.startWeekStartDate = moment(endWeekEndDate).startOf('year').toDate();
    timePeriodToReturn.endWeekEndDate = endWeekEndDate;
    timePeriodToReturn.startWeek = getWeekId(timePeriodToReturn.startWeekStartDate);
    timePeriodToReturn.endWeek = getWeekId(timePeriodToReturn.endWeekEndDate);
    timePeriodToReturn.startDayId = getDayIdFromDate(timePeriodToReturn.startWeekStartDate);
    timePeriodToReturn.endDayId = getDayIdFromDate(timePeriodToReturn.endWeekEndDate);
  } else if (timePeriodToReturn.id === 'mtd') {
    const endWeekEndDate = moment().subtract(1, 'day').toDate();
    timePeriodToReturn.startWeekStartDate = moment(endWeekEndDate).startOf('month').toDate();
    timePeriodToReturn.endWeekEndDate = endWeekEndDate;
    timePeriodToReturn.startWeek = getWeekId(timePeriodToReturn.startWeekStartDate);
    timePeriodToReturn.endWeek = getWeekId(timePeriodToReturn.endWeekEndDate);
    timePeriodToReturn.startDayId = getDayIdFromDate(timePeriodToReturn.startWeekStartDate);
    timePeriodToReturn.endDayId = getDayIdFromDate(timePeriodToReturn.endWeekEndDate);
  } else if (timePeriodToReturn.id === 'lastMonth') {
    computeDatesForLastMonth(timePeriodToReturn);
  } else if (timePeriodToReturn.id === 'last3Months') {
    computeDatesForLast3Months(timePeriodToReturn);
  }
}

function computeDatesForPeriodToDateTimePeriodForWeeklyData(timePeriodToReturn) {
  if (timePeriodToReturn.id === 'ytd') {
    timePeriodToReturn.startWeek = getFirstWeekOfYear(timePeriodToReturn.endWeek);
    addStartAndEndDate(timePeriodToReturn);
  } else if (timePeriodToReturn.id === 'mtd') {
    const endWeekEndDate = moment().subtract(1, 'day').toDate();
    timePeriodToReturn.startWeekStartDate = moment(endWeekEndDate).startOf('month').toDate();
    timePeriodToReturn.endWeekEndDate = endWeekEndDate;
    timePeriodToReturn.startWeek = getWeekId(timePeriodToReturn.startWeekStartDate);
    timePeriodToReturn.endWeek = getWeekId(timePeriodToReturn.endWeekEndDate);
    timePeriodToReturn.startDayId = getDayIdFromDate(timePeriodToReturn.startWeekStartDate);
    timePeriodToReturn.endDayId = getDayIdFromDate(timePeriodToReturn.endWeekEndDate);
  } else if (timePeriodToReturn.id === 'ly') {
    timePeriodToReturn.startWeek = getFirstWeekOfYear(timePeriodToReturn.endWeek - 100);
    timePeriodToReturn.endWeek = Math.floor((timePeriodToReturn.endWeek - 100) / 100) * 100 + 52;
    addStartAndEndDate(timePeriodToReturn);
  } else if (timePeriodToReturn.id === 'lastMonth') {
    computeDatesForLastMonth(timePeriodToReturn);
  } else if (timePeriodToReturn.id === 'last3Months') {
    computeDatesForLast3Months(timePeriodToReturn);
  }
}

/*
@params
  allWeekIds is an Array of integers
  timePeriod is an object { id: string, startWeek: int,  }
 */
export function computeMainTimePeriod(allWeekIds, timePeriod, app) {
  const timePeriodToReturn = _cloneDeep(timePeriod);
  const lastWeekId = _last(allWeekIds);

  if (['cd', 'select1Month', 'select3Months'].includes(timePeriodToReturn.id)) {
    if (!timePeriodToReturn.startWeek || !timePeriodToReturn.endWeek) {
      timePeriodToReturn.startWeek = getFirstWeekOfYear(lastWeekId);
      timePeriodToReturn.endWeek = lastWeekId;
    }
    addStartAndEndDate(timePeriodToReturn);
    return timePeriodToReturn;
  }

  timePeriodToReturn.endWeek = lastWeekId;
  if (['ytd', 'mtd', 'ly', 'lastMonth', 'last3Months'].includes(timePeriodToReturn.id)) {
    if (app.dataFrequency === 'daily') {
      computeDatesForPeriodToDateTimePeriodForDailyData(timePeriodToReturn);
    } else {
      computeDatesForPeriodToDateTimePeriodForWeeklyData(timePeriodToReturn);
    }
    return timePeriodToReturn;
  }
  const period = timePeriodToReturn.id.toLowerCase() === '1w' ? '1w' : timePeriodToReturn.id;
  let weeksToGoBack = parseInt(period.replace(/w/gi, ''), 10);
  if (app.dataFrequency === 'daily') {
    weeksToGoBack += 1;
    timePeriodToReturn.endWeek = allWeekIds.length > 1 ? allWeekIds[allWeekIds.length - 2] : _last(allWeekIds);
  }
  if (allWeekIds.length >= weeksToGoBack) {
    timePeriodToReturn.startWeek = allWeekIds[allWeekIds.length - weeksToGoBack];
  } else {
    [timePeriodToReturn.startWeek] = allWeekIds;
  }
  addStartAndEndDate(timePeriodToReturn);
  return timePeriodToReturn;
}

export function getWeekCount(year, monthNumber) {
  // month_number is in the range 1..12
  const firstOfMonth = new Date(year, monthNumber - 1, 1);
  const lastOfMonth = new Date(year, monthNumber, 0);

  const used = firstOfMonth.getDay() + lastOfMonth.getDate();

  return Math.ceil(used / 7);
}

export function parseDate(dateStr) {
  return new Date(new Date(dateStr).getTime() + new Date().getTimezoneOffset() * 60000);
}

export function formatDate(date) {
  return `${date.getDate()}-${monthNames[date.getMonth()]}-${date.getFullYear()}`;
}

export function getDateOfWeek(w, y) {
  const d = 1 + (w - 1) * 7; // 1st of January + 7 days for each week
  return new Date(y, 0, d);
}

export function basicDayFormat(date) {
  const timezone = 'America/Los_Angeles'; // convert all timezones to PST to avoid displaying wrong day in legends
  return moment(date).tz(timezone).format('M/D/YY');
}

/**
 * Returns a string representing the last day of the provided date's month.
 */
export function getLastDayOfMonth(date) {
  return moment(date).endOf('month').format('MMMM D, YYYY');
}

export function getLastDayIdOfMonth(date) {
  return moment(date).endOf('month').format(DAY_ID_FORMAT);
}

export function getNextMonth(month) {
  if (month === 12) {
    return '01';
  }

  if (month < 9) {
    return `0${month + 1}`;
  }

  return month + 1;
}

export function getNextDay(date) {
  const dateString = String(date);
  const year = +dateString.substring(0, 4);
  const month = +dateString.substring(4, 6);
  const day = +dateString.substring(6, 8);

  const lastDayOfMonth = getLastDayIdOfMonth(dateString);
  // case end of year
  if (month === 12 && day === 31) {
    return +`${year + 1}0101`;
  }

  if (dateString === lastDayOfMonth) {
    return +`${year}${getNextMonth(month)}01`;
  }

  return +`${year}${month < 10 ? `0${month}` : month}${day < 9 ? `0${day + 1}` : day + 1}`;
}

export function getNextWeek(date) {
  const dateString = String(date);
  const year = +dateString.substring(0, 4);
  const week = +dateString.substring(4, 6);
  const isLeapYear = moment([year]).isLeapYear();

  // case end of year
  if (isLeapYear ? week === 53 : week === 52) {
    return +`${year + 1}01`;
  }

  return +`${year}${week < 9 ? `0${week + 1}` : week + 1}`;
}

let currentWeekIndex = 1;
const monthNameOfWeek = [];
for (let i = 1; i <= 12; i += 1) {
  const weekCount = getWeekCount(currentYear, i);
  for (let j = currentWeekIndex; j < currentWeekIndex + weekCount; j += 1) {
    monthNameOfWeek[j] = monthNames[i - 1];
  }
  currentWeekIndex += weekCount;
}

export function isBeforeDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) {
    return false;
  }

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) {
    return a.date() < b.date();
  }
  if (isSameYear) {
    return aMonth < bMonth;
  }
  return aYear < bYear;
}

export function isInclusivelyAfterDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) {
    return false;
  }
  return !isBeforeDay(a, b);
}

export const getAllRetailerWeekIDs = (allWeekIdsByRetailerId) => {
  const allWeekIds = Object.keys(allWeekIdsByRetailerId).map((key) => allWeekIdsByRetailerId[key]);

  // Union of all available weeks across all retailers
  const weekIds = _union(...allWeekIds).sort(); // temporary rollback for Mitch demo
  return weekIds;
};

export const computeUpdatedTimePeriods = ({
  allWeekIdsByRetailerId,
  mainTimePeriod,
  comparisonTimePeriod,
  retailer,
  app,
  params
}) => {
  const { availableMainTimePeriods } = mainTimePeriod;
  const { availableComparisonTimePeriods } = comparisonTimePeriod;
  const allWeekIds =
    retailer.id === '0' ? getAllRetailerWeekIDs(allWeekIdsByRetailerId) : allWeekIdsByRetailerId[retailer.id];
  if (!allWeekIds) {
    return panic(`Tried to get week IDs for retailer id ${retailer.id} but none exist.`);
  }
  const weekIds =
    retailer.id === '0' ? getAllRetailerWeekIDs(allWeekIdsByRetailerId) : allWeekIdsByRetailerId[retailer.id];
  if (!weekIds) {
    return panic(`No available Week IDs for the currently selected retailer ID: ${retailer.id}`);
  }
  const comparisonId =
    params.pid !== undefined ? `${params.pid}` : app.name === 'advertising' ? 'prior-period' : app.comparisonDefault;

  const hasWeekRange = params.sw !== undefined && params.ew !== undefined && params.wr !== undefined;
  const currentDayOfMonth = parseInt(moment().format('D'), 10);
  if (currentDayOfMonth === 1 && params.wr === 'mtd') {
    params.wr = 'ytd';
  }

  let selectedMainTimePeriod = hasWeekRange
    ? availableMainTimePeriods.find((item) => _get(item, 'id') === params.wr)
    : availableMainTimePeriods.find((x) => x.default) || availableMainTimePeriods[0];

  // Fallback to default time range if we're navigating from AMC dashboard to a summary page via one of the product grids
  if (params.wr === '2w' && params.tab !== 'connectDashboard') {
    selectedMainTimePeriod = availableMainTimePeriods.find((x) => x.default);
  }

  const { shortDisplayName, id } = selectedMainTimePeriod;
  const newTimePeriod = computeMainTimePeriod(weekIds, selectedMainTimePeriod, app);
  // CD is initialized to be YTD to begin with
  let { endWeek, endDayId, endWeekEndDate } = newTimePeriod;

  // What if the last week is not in the available weeks? We should use the default value (last available week for retailer)
  const newEndWeek = hasWeekRange && params.wr === 'cd' ? parseInt(params.ew, 10) : endWeek;
  const newEndDayId = hasWeekRange && params.wr === 'cd' && params.edid ? parseInt(params.edid, 10) : endDayId;
  const newEndWeekEndDate =
    hasWeekRange && params.wr === 'cd' && params.edid ? moment(params.edid).toDate() : endWeekEndDate;

  if (allWeekIdsByRetailerId[retailer.id].includes(newEndWeek)) {
    // If week id is contained in list of retailer's weeks, use this value
    endWeek = newEndWeek; // only set end week, if it's a valid end week
    endDayId = newEndDayId;
    endWeekEndDate = newEndWeekEndDate;
  } // else use default value

  // What if the start week is not in the available weeks? Ignoring this case for now
  const { startWeek, startDayId, startWeekStartDate } = newTimePeriod;
  const updatedMainPeriod = {
    startWeek: hasWeekRange && params.wr === 'cd' ? parseInt(params.sw, 10) : startWeek,
    endWeek,
    startDayId: hasWeekRange && params.wr === 'cd' && params.sdid ? parseInt(params.sdid, 10) : startDayId,
    endDayId,
    startWeekStartDate:
      hasWeekRange && params.wr === 'cd' && params.sdid ? moment(params.sdid).toDate() : startWeekStartDate,
    endWeekEndDate,
    id,
    shortDisplayName
  };

  const updatedComparisonPeriod = computeComparisonTimePeriod(weekIds, updatedMainPeriod, comparisonId, app);
  updatedComparisonPeriod.availableComparisonTimePeriods = availableComparisonTimePeriods;
  updatedComparisonPeriod.comparisonIndex = availableComparisonTimePeriods.findIndex((x) => x.id === comparisonId);

  return { updatedMainPeriod, updatedComparisonPeriod, startWeek, endWeek, allWeekIds };
};

/**
 * Given a time period object, returns a key that is used to identify a piece of data from that time period.
 *
 * @param {object} timePeriod A time period object like `mainTimePeriod` or `comparisonTimePeriod` from Redux
 * @param {string?} key The base key for the beginning of the created key; defaults of `"value"`
 */
export const getDataKeyForTimePeriod = (timePeriod, key = 'value') =>
  `${key}_${timePeriod.startWeek}_${timePeriod.endWeek}`;

const buildDayIdRangeFilter = (startDate, endDate, rawData = false) => ({
  fieldName: 'dayId',
  minValue: rawData ? startDate : getDayIdFromDate(startDate),
  maxValue: rawData ? endDate : getDayIdFromDate(endDate)
});

/**
 * Builds a `RangeFilter` by `dayId` representing the current month from start to end
 */
export const buildFullMonthRangeFilters = () => {
  const startDate = moment().startOf('month').toDate();
  const endDate = moment().endOf('month').toDate();
  const dayIdFilter = buildDayIdRangeFilter(startDate, endDate);
  return [dayIdFilter];
};

/**
 * Builds a `RangeFilter` by `dayId` for the current MTD time period.
 */
export const buildMtdRangeFilters = () => {
  const startDate = moment().startOf('month').toDate();
  const endDate = moment();
  const dayIdFilter = buildDayIdRangeFilter(startDate, endDate);
  return [dayIdFilter];
};

export const buildMtdComparisonRangeFilters = () => {
  const startDate = moment().subtract(1, 'months').startOf('month').toDate();
  const endDate = moment().subtract(1, 'months');
  const dayIdFilter = buildDayIdRangeFilter(startDate, endDate);
  return [dayIdFilter];
};

export const build4wRangeFilters = () => {
  const { mainTimePeriod } = store.getState();
  const last4WeekPeriod = mainTimePeriod.availableMainTimePeriods.find((x) => x.id === '4w');
  const dayIdFilter = buildDayIdRangeFilter(last4WeekPeriod.startDayId, last4WeekPeriod.endDayId, true);
  return [dayIdFilter];
};

export const build4wComparisonRangeFilters = () => {
  const { allWeekIdsByRetailerId, mainTimePeriod, retailer } = store.getState();
  const allWeekIds = allWeekIdsByRetailerId[retailer.id];
  const last4WeekPeriod = mainTimePeriod.availableMainTimePeriods.find((x) => x.id === '4w');
  const last4WeekComparisonPeriod = computeComparisonTimePeriod(allWeekIds, last4WeekPeriod, 'prior-period');
  const dayIdFilter = buildDayIdRangeFilter(
    last4WeekComparisonPeriod.startDayId,
    last4WeekComparisonPeriod.endDayId,
    true
  );
  return [dayIdFilter];
};

export const moveForwardOneWeek = (dataPoint) => {
  // Assumption: we do not go between years when calling this
  dataPoint.weekId += 1;
  dataPoint.name = `${dataPoint.weekId}`;
  dataPoint.weekEnding = getWeekLastDate(dataPoint.weekId);
  dataPoint.weekEndingNextYear = getWeekLastDate(dataPoint.weekId + 100);
  return dataPoint;
};

export const transferWeekIdToLastDayOfWeek = (weekId, format) => {
  return moment(getWeekLastDate(weekId)).format(format);
};

export default {
  startYear,
  currentStartWeek,
  currentYear,
  isBeforeDay,
  isInclusivelyAfterDay,
  timeOffset,
  getWeekLastDate,
  getWeekLastDay,
  getWeekNumber,
  getWeekCount,
  monthNameOfWeek,
  parseDate,
  formatDate,
  getDateOfWeek,
  getDayIdFromDate
};
