import axios from 'axios';
import _get from 'lodash/get';
import { useEffect, useState } from 'react';
import { AdjustmentForm } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import {
  createAdditionalProperties,
  getDerivedAdjustmentAmount
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/utils';
import {
  useForecastStartAndEndWeekIds,
  useLatestForecastModel
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import { useAdvancedSearchRequestBuilder } from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { useAppSelector } from 'src/utils/Hooks';

export enum NetImpactDataType {
  Published = 'published',
  Unpublished = 'unpublished'
}
interface UseNetImpactProps {
  adjustmentForm: AdjustmentForm;
  adjustmentId?: string;
  retailerSku: string | number;
  stacklineSku: string | number;
  categoryId: string | number;
  subCategoryId: string | number;
  /**
   * If the adjustment is in "Draft" status or we're editing an existing adjustment, we use unpublished net impact.
   * Otherwise, we fetch the published net impact.
   */
  netImpactDataType?: NetImpactDataType;
  cpcFromUser?: boolean;
}
export const useNetImpact = ({
  adjustmentForm,
  adjustmentId = null,
  retailerSku,
  stacklineSku,
  categoryId,
  subCategoryId,
  netImpactDataType = NetImpactDataType.Published,
  cpcFromUser = false
}: UseNetImpactProps) => {
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const { startWeek: startWeekId } = useAppSelector((state) => state.mainTimePeriod);
  const { endWeekId: forecastsEndWeekId } = useForecastStartAndEndWeekIds();
  const { modelVersion, currentPublishVersion } = useLatestForecastModel();
  const retailerId = useAppSelector((state) => state.retailer.id);
  const userId = useAppSelector((state) => state.user.session.userId);

  const [netImpact, setNetImpact] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const forecastAdjustmentNetImpactBuilder = useAdvancedSearchRequestBuilder(
    `netimpact-client-${beaconClientId}`,
    'beacon-netimpact-adjustment'
  );

  const fetchNetImpact = async () => {
    try {
      if (adjustmentId && netImpactDataType === NetImpactDataType.Published) {
        const plansAggregationBuilder = new AggregationBuilder('adjustmentId');
        plansAggregationBuilder
          .addAggregationField('Weighted Impact', 'weightedImpact', 'sum', true)
          .addConditionTermFilter('retailerId', [retailerId]);

        forecastAdjustmentNetImpactBuilder
          .setPageSize(1200)
          .setDoAggregation(true)
          .setReturnDocuments(false)
          .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
          .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
          .addConditionTermFilter('adjustmentId', 'must', [adjustmentId])
          .addConditionRangeFilter('forecastWeekId', startWeekId, forecastsEndWeekId)
          .setSearchBy('parent')
          .addAggregation(plansAggregationBuilder.build());

        const forecastsPlansAdjustmentsNetImpactRequest = forecastAdjustmentNetImpactBuilder.build();

        const response = await axios.post('api/beacon/AdvancedSearch?_id=netImpactDataForAdjustment', [
          forecastsPlansAdjustmentsNetImpactRequest
        ]);

        setNetImpact(
          _get(
            response,
            ['data', 0, 'aggregations', 'by_adjustmentId', 0, 'additionalValues', 'weightedImpact_sum_value'],
            0
          )
        );
        setLoading(false);
      } else {
        const { planInputAmounts, planType, startDate, endDate } = adjustmentForm;

        /**
         * For content score, we should reduce the inputs into decimal values before sending.
         * We  also substitute 0 in case any inputs are left empty.
         * For ratings inputs, we can simply use the values as is.
         * Otherwise, we attach an empty ratings object as a placeholder for all other plan types.
         */
        const additionalProperties = createAdditionalProperties(adjustmentForm, cpcFromUser);

        const netImpactPayload = {
          baseAdjustmentRequest: {
            adjustmentId,
            beaconClientId,
            retailerId,
            retailerSku,
            userId,
            planType,
            startWeekId: startDate,
            endWeekId: endDate,
            ignoreConflictWithAdjustmentIds: [adjustmentId]
          },
          additionalProperties,
          adjustmentChangeType: 'Absolute',
          adjustmentChangeValue: getDerivedAdjustmentAmount({ planType, input: planInputAmounts }),
          stacklineSku,
          categoryId,
          subCategoryId,
          requestType: 'Create',
          shouldPublish: false
        };
        const response = await axios.post('api/beaconforecast/GetNetImpact', [netImpactPayload]);

        setNetImpact(_get(response, ['data', '0', 'weightedImpact'], 0));
        setLoading(false);
      }
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchNetImpact();
  }, [adjustmentForm, adjustmentId, retailerSku, stacklineSku, categoryId, subCategoryId]);

  return { netImpact, loading, error };
};
