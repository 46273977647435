import { INDEX_FIELDS, ENTITIES, METRICTYPE } from 'src/utils/entityDefinitions';
import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';

import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import InactiveProducts from 'src/components/EntityPage/Inventory';
import FilterKeywords from 'src/components/EntityPage/Reviews/ReviewsContainer/FilterKeywords';
import ReviewsContainer from 'src/components/EntityPage/Reviews';
import { EnhancedDonutChartContainer } from 'src/components/Charts/Donut';
import { ContentAccuracy, ContentScoreColumnChart } from 'src/components/EntityPage/Content';
import AtlasProductCellFormatter from 'src/components/EntityGrid/Table/AtlasProductCellFormatter';
import EntityGrid from 'src/components/EntityGrid';
import {
  buildTrendWidgetConfig,
  buildTopEntitiesWidgetConfig,
  buildWidgetGroup
} from 'src/components/Layout/LayoutUtil';
import { withManualBrHeader } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import CustomAgGridHeaderTemplate from 'src/components/EntityGrid/Table/CustomAgGridHeaderTemplate';
import { withProps } from 'src/utils/hoc';
import MultiLineHCF, {
  EntityGridNoclipHeaderWrapper
} from 'src/components/EntityGrid/HeaderComponentFrameworks/MultiLineHCF';
import buildConversionRateGridWidget from 'src/components/Layout/util/ConversionRateGrid';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import { parseReviewsMetrics } from 'src/store/modules/entitySearchService/selectors';
import { SellerSummaryBarChart, SellerSummaryEntityGrid } from 'src/components/EntityPage/BuyBox/SellerSummary';
import SelfReliantProductWeeklySalesGrid from 'src/components/EntityPage/Content/SelfReliantProductWeeklySalesGrid';
import PricesSummaryPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/Summary/PricesSummaryPage';
import PricesMAPViolationsPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/MAPViolations/PricesMAPViolationsPage';
import { shouldShowNewBeacon, shouldShowRatingsAndReviewsV2 } from 'src/utils/app';
import ContentScorePage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Content/Score/ContentScorePage';
import BuyBoxSummaryPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/BuyBox/Summary/BuyBoxSummaryPage';
import BuyBoxSellersPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/BuyBox/Sellers/BuyBoxSellersPage';
import ContentAccuracyPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Content/Accuracy/ContentAccuracyPage';
import ReviewTrendsPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Reviews/ReviewTrends/ReviewTrendsPage';
import InventorySummaryPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Inventory/InventorySummaryPage';
import TrafficConversionPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Traffic/Conversion/TrafficConversionPage';
import { getWidgetBarChartStyles } from 'src/components/BeaconRedesignComponents/styles/styles';
import { BEACON_GRID_TOP_SPACING } from './BeaconProLayoutConsts';
import ReviewAnalysisPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Reviews/ReviewAnalysis/ReviewAnalysisPage';
import ReviewRatingsPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Reviews/ReviewRatings/ReviewRatingsPage';
import ReviewSentimentPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Reviews/ReviewSentiment/ReviewSentimentPage';

const getScoreFieldsForRetailer = (retailerId) => {
  const defaultFields = ['titleScore', 'bulletScore', 'imageScore', 'videoScore'];
  switch (retailerId) {
    case 63:
      return ['titleScore', 'imageScore', 'ingredientsScore', 'warningsScore', 'directionsScore', 'descriptionScore'];
    case 3:
      return [...defaultFields, 'descriptionScore'];
    case 6:
      return [...defaultFields, 'aplusScore'];
    default:
      return defaultFields;
  }
};

export default function getConversionPageLayout({ app, retailer, tab, metricType, entity }) {
  const indexName = tab;
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
  let CustomPageContainer = GenericPageContainer;
  let enableComparison = true;
  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.beacon.retailer),
    categoryEntity: _cloneDeep(ENTITIES.beacon.category),
    relatedEntity: _cloneDeep(ENTITIES.beacon.brand),
    weekIdField: _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId')),
    retailerIdField: _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'))
  };

  const widgets = [];
  if (metricType === 'retailPrice') {
    if (shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: PricesSummaryPage,
        name: 'priceSummary',
        view: {}
      });
    } else {
      widgets.push(
        ...['retailPrice', 'wholesalePrice', 'brandCogsPerUnit', 'brandGrossMarginPerUnit'].map((fieldName) =>
          buildTrendWidgetConfig(app, 'sales', entity, 'weekId', [fieldName], weekIdField)
        )
      );
      const noOfTopEnitities = 50;

      widgets.push(
        buildTopEntitiesWidgetConfig(
          app,
          'sales',
          entity,
          [
            {
              groupByFieldName: 'subCategoryId',
              aggregationFieldNames: ['retailPrice']
            }
          ],
          noOfTopEnitities,
          weekIdField,
          {
            replaceSubCategoryWithParent: true,
            view: {
              container: {
                style: getWidgetBarChartStyles()
              },
              titleOverride: 'Retail Price by Subcategory',
              chartPropsOverride: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                },
                xAxis: [
                  {
                    min: 0,
                    max: 9
                  }
                ]
              }
            }
          }
        )
      );
    }

    // Retail Price by Subcategory

    const fieldOverridesByFieldName = {
      wholesalePrice: {
        displayName: 'Wholesale|Price'
      },
      retailerMarginPerUnit: {
        displayName: 'Retailer Margin|per Unit'
      },
      brandCogsPerUnit: {
        displayName: 'Brand COGs|per Unit'
      },
      brandGrossMarginPerUnit: {
        displayName: 'Brand Margin|per Unit'
      }
    };

    const aggregationFields = [
      'retailSales',
      'unitsSold',
      'retailPrice',
      'mapPrice',
      'wholesalePrice',
      'retailerMarginPerUnit',
      'brandCogsPerUnit',
      'brandGrossMarginPerUnit'
    ].map((fieldName) => INDEX_FIELDS.getField(app.name, 'sales', fieldName, 'product', 'stacklineSku'));

    // Table

    const gridAggregationFields = aggregationFields.map((field) => ({
      ...field,
      ...(fieldOverridesByFieldName[field.name] || {}),
      headerComponentFramework: withManualBrHeader(
        withProps({
          sortArrowStyle: !field.name.includes('|') ? { marginTop: '1em' } : undefined
        })(CustomAgGridHeaderTemplate)
      ),
      cellRendererFramework: AtlasProductCellFormatter,
      cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
    }));

    if (!shouldShowNewBeacon()) {
      widgets.push({
        name: 'entityGrid',
        CustomComponent: EntityGrid,
        view: _merge({}, VIEWS.entityGrid, {
          WrapperComponent: EntityGridNoclipHeaderWrapper,
          gridOptions: {}
        }),
        data: {
          defaultSortField: INDEX_FIELDS.getField(app.name, 'sales', 'retailPrice', 'product', 'stacklineSku'),
          groupByFields: [INDEX_FIELDS.getField(app.name, 'sales', 'stacklineSku', 'product')],
          configByGroupByFieldName: {
            stacklineSku: {
              indexName: 'sales',
              entity: ENTITIES.atlas.product,
              mainMetricField: INDEX_FIELDS.getField(app.name, 'sales', 'retailPrice', 'product', 'stacklineSku'),
              aggregationFields,
              tableView: {
                metricFields: gridAggregationFields
              }
            }
          }
        }
      });
    }
  } else if (metricType === 'mapPrice') {
    if (shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: PricesMAPViolationsPage,
        name: 'mapPriceViolations',
        view: {
          container: {
            style: {}
          }
        }
      });
    } else {
      widgets.push(
        buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['mapPriceViolation'], weekIdField, {
          view: {
            chartPropsOverride: {
              title: { text: 'MAP Violations' }
            }
          }
        })
      );
      const aggregationFields = ['retailSales', 'unitsSold', 'retailPrice', 'mapPrice', 'mapPriceViolation'].map(
        (fieldName) => INDEX_FIELDS.getField(app.name, 'sales', fieldName, 'product', 'stacklineSku')
      );

      const fieldOverridesByFieldName = {
        mapPriceViolation: {
          displayName: 'MAP Violations',
          headerComponentFramework: MultiLineHCF,
          cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
        }
      };

      const metricFields = aggregationFields.map((field) => ({
        ...field,
        headerComponentFramework: withManualBrHeader(
          withProps({
            sortArrowStyle: !field.name.includes('|') ? { marginTop: '1em' } : undefined
          })(CustomAgGridHeaderTemplate)
        ),
        ...(fieldOverridesByFieldName[field.name] || {}),
        cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
      }));

      widgets.push({
        name: 'entityGrid',
        CustomComponent: EntityGrid,
        view: _merge({}, VIEWS.entityGrid, {
          gridOptions: {},
          WrapperComponent: EntityGridNoclipHeaderWrapper
        }),
        data: {
          defaultSortField: INDEX_FIELDS.getField(app.name, 'sales', 'mapPriceViolation', 'product', 'stacklineSku'),
          groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
          configByGroupByFieldName: {
            stacklineSku: {
              indexName: 'sales',
              entity: ENTITIES.beacon.product,
              mainMetricField: INDEX_FIELDS.getField(app.name, 'sales', 'mapPriceViolation', 'product', 'stacklineSku'),
              aggregationFields,
              tableView: { metricFields }
            }
          }
        }
      });
    }
  } else if (metricType === 'contentScore') {
    enableComparison = false;

    // change these depending on the retailer
    const aggregationFields = getScoreFieldsForRetailer(+retailer.id).map((fieldName) =>
      INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type, dataConfig.weekIdField.name)
    );

    if (retailer.name.toLowerCase().indexOf('amazon') > -1) {
      aggregationFields.push(
        INDEX_FIELDS.getField(app.name, indexName, 'aplusScore', entity.type, dataConfig.weekIdField.name)
      );
    }

    const gridAggregationFields = getScoreFieldsForRetailer(+retailer.id).map((fieldName) =>
      INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type, 'stacklineSku')
    );

    if (retailer.name.toLowerCase().indexOf('amazon') > -1) {
      gridAggregationFields.push(INDEX_FIELDS.getField(app.name, indexName, 'aplusScore', entity.type, 'stacklineSku'));
    }

    const mainMetricField = INDEX_FIELDS.getField(
      app.name,
      indexName,
      metricType,
      entity.type,
      dataConfig.retailerIdField.name
    );
    if (shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: ContentScorePage,
        view: {},
        name: 'contentScore'
      });
    } else {
      // Content score donut chart
      widgets.push({
        CustomComponent: EnhancedDonutChartContainer,
        view: {
          ...VIEWS.donutChart,
          name: `DonutChartContainer-ContentScore`,
          ...(shouldShowNewBeacon() ? { customHeader: { enabled: true, title: 'Content Score' } } : {}),
          chartPropsOverride: {
            chart: {
              type: 'pie',
              height: 350,
              width: 350
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false
                },
                size: 350,
                startAngle: -130,
                endAngle: 130,
                center: ['50%', '63%'],
                innerSize: 172
              }
            },
            tooltip: {
              enabled: false
            },
            disablePointSelect: true,
            enableMultiSelect: false
          },
          container: {
            style: {
              flex: '1 1 50%',
              width: '50%',
              display: 'inline-block',
              ...(shouldShowNewBeacon() ? {} : { maxHeight: '350px' }),
              overflow: 'hidden',
              marginBottom: '80px'
            }
          }
        },
        data: {
          indexName,
          chartMainField: mainMetricField,
          mainMetric: mainMetricField,
          secondaryMetric: mainMetricField,
          useLatestWeek: false,
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, indexName, metricType, entity.type, dataConfig.retailerIdField.name)
          ],
          groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.retailerIdField.name, entity.type)
        }
      });

      // Content score column chart
      widgets.push({
        CustomComponent: ContentScoreColumnChart,
        view: _merge({}, VIEWS.columnChart, {
          name: 'contentScoreColumnChart',
          chartPropsOverride: {
            xAxis: {
              rotation: -45
            },
            chart: {
              height: 350,
              marginTop: 50
            },
            subtitle: { style: { 'border-bottom': 'none' } },
            dataLabels: {
              x: 15
            }
          },
          container: {
            style: {
              flex: '1 1 50%',
              width: '50%',
              display: 'inline-block',
              maxHeight: '350px',
              overflow: 'hidden',
              marginBottom: '40px'
            }
          }
        }),
        data: {
          aggregationFields,
          groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.retailerIdField.name, entity.type)
        }
      });

      // Content score weekly trend chart
      widgets.push(buildTrendWidgetConfig(app, indexName, entity, 'weekId', ['contentScore'], weekIdField, {}));
    }
    if (entity.type === 'product' && !shouldShowNewBeacon()) {
      widgets.push({
        view: {
          name: 'contentScoreMetricsByWeekId'
        },
        CustomComponent: SelfReliantProductWeeklySalesGrid,
        data: {
          chartMainField: INDEX_FIELDS.getField(app.name, indexName, 'contentScore', undefined, 'weekId'),
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, indexName, 'contentScore', undefined, 'weekId'),
            ...aggregationFields
          ],
          groupByField: INDEX_FIELDS.getField(app.name, indexName, 'weekId'),
          indexName
        }
      });
    } else {
      // TODO
      const gridAggregationFieldsWithFramework = [
        INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
        ...gridAggregationFields
      ].map((field) => ({
        ...field,
        cellRendererFramework: AtlasProductCellFormatter,
        cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
      }));

      if (!shouldShowNewBeacon()) {
        // Content score entity grid
        widgets.push({
          CustomComponent: EntityGrid,
          view: _merge({}, VIEWS.entityGrid, {
            gridOptions: {}
          }),
          data: {
            groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
            configByGroupByFieldName: {
              stacklineSku: {
                indexName,
                entity: ENTITIES.beacon.product,
                mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
                aggregationFields: [
                  INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
                  ...gridAggregationFields
                ],
                tableView: {
                  metricFields: [...gridAggregationFieldsWithFramework]
                }
              }
            }
          }
        });
      }
    }
  } else if (metricType === 'contentAccuracy') {
    if (shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: ContentAccuracyPage,
        name: 'contentAccuracy',
        view: {}
      });
    } else {
      {
        CustomPageContainer = ContentAccuracy;
        enableComparison = false;
        dataConfig.indexName = 'contentChanged';
        const mainMetricNames = ['titleAccuracy', 'bulletsAccuracy', 'imageUrlsAccuracy', 'videoUrlsAccuracy'];
        const gridAggregationFields = [];

        mainMetricNames.forEach((mainMetricName) => {
          gridAggregationFields.push(
            INDEX_FIELDS.getField(app.name, dataConfig.indexName, mainMetricName, entity.type, 'stacklineSku')
          );
          const mainMetricField = INDEX_FIELDS.getField(
            app.name,
            dataConfig.indexName,
            mainMetricName,
            entity.type,
            dataConfig.retailerIdField.name
          );

          widgets.push({
            view: {
              ...VIEWS.donutChart,
              name: `${mainMetricName}DonutChart`,
              chartPropsOverride: {
                chart: {
                  type: 'pie',
                  height: 200,
                  width: 300
                },
                plotOptions: {
                  pie: {
                    dataLabels: {
                      enabled: false
                    },
                    size: 200,
                    startAngle: -130,
                    endAngle: 130,
                    center: ['50%', '63%'],
                    innerSize: 172
                  }
                },
                tooltip: {
                  enabled: false
                },
                disablePointSelect: true,
                enableMultiSelect: false,
                titleFontSize: '32px',
                showFullValue: false
              }
            },
            data: {
              chartMainField: mainMetricField,
              aggregationFields: [mainMetricField],
              groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.retailerIdField.name, entity.type),
              mainMetric: {
                ...mainMetricField,
                metricType: entity.type === 'product' ? METRICTYPE.TEXT : mainMetricField.metricType
              },
              secondaryMetric: mainMetricField
            }
          });
        });

        if (entity.type === 'product') {
          widgets.push({ view: VIEWS.contentMatchingGrid });
        } else {
          widgets.push({
            CustomComponent: EntityGrid,
            view: {
              ...VIEWS.entityGrid,
              gridOptions: {
                defaultLayout: 'table',
                enableSwitchingLayouts: false
              }
            },
            data: {
              groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
              configByGroupByFieldName: {
                stacklineSku: {
                  indexName,
                  entity: ENTITIES.beacon.product,
                  mainMetricField: gridAggregationFields[0],
                  aggregationFields: gridAggregationFields.map((f) => ({
                    ...f,
                    cellRendererFramework: AtlasProductCellFormatter,
                    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
                  })),
                  tableView: {
                    metricFields: gridAggregationFields.map((f) => ({
                      ...f,
                      cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
                    }))
                  }
                }
              }
            }
          });
        }
      }
    }
  } else if (metricType === 'inventory') {
    if (shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: InventorySummaryPage,
        name: 'inventorySummary',
        view: {
          container: {
            style: {
              marginBottom: `${BEACON_GRID_TOP_SPACING}px`
            }
          }
        }
      });
    } else {
      // In-Stock Rate
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['instockRate'], weekIdField));
      // On Hand - Units
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['unitsOnHand'], weekIdField));
      // Weeks on Hand
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['inventoryWeeksOnHand'], weekIdField));
      // On Hand - Retail
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['inventoryRetailValue'], weekIdField));
    }

    // Table
    const aggregationFields = [
      'retailSales',
      'unitsSold',
      'retailPrice',
      'instockRate',
      'unitsOnHand',
      'inventoryWeeksOnHandGrid',
      'inventoryRetailValue'
    ].map((fieldName) => INDEX_FIELDS.getField(app.name, 'sales', fieldName, 'product', 'stacklineSku'));

    const fieldOverridesByFieldName = {
      inventoryWeeksOnHandGrid: {
        displayName: 'Weeks on|Hand'
      },
      inventoryRetailValue: {
        displayName: 'On Hand -|Retail'
      },
      unitsOnHand: {
        displayName: 'Units on Hand'
      }
    };

    const gridAggregationFields = aggregationFields.map((field) => ({
      ...field,
      cellRendererFramework: AtlasProductCellFormatter,
      headerComponentFramework: withManualBrHeader(
        withProps({
          sortArrowStyle: !field.name.includes('|') ? { marginTop: '1em' } : undefined
        })(CustomAgGridHeaderTemplate)
      ),
      ...(fieldOverridesByFieldName[field.name] || {}),
      cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
    }));

    if (!shouldShowNewBeacon()) {
      widgets.push({
        name: 'entityGrid',
        CustomComponent: EntityGrid,
        view: _merge({}, VIEWS.entityGrid, {
          WrapperComponent: EntityGridNoclipHeaderWrapper,
          gridOptions: {}
        }),
        data: {
          defaultSortField: INDEX_FIELDS.getField(app.name, 'sales', 'unitsOnHand', 'product', 'stacklineSku'),
          groupByFields: [INDEX_FIELDS.getField(app.name, 'sales', 'stacklineSku', 'product')],
          configByGroupByFieldName: {
            stacklineSku: {
              indexName: 'sales',
              entity: ENTITIES.atlas.product,
              mainMetricField: INDEX_FIELDS.getField(app.name, 'sales', 'unitsOnHand', 'product', 'stacklineSku'),
              aggregationFields,
              tableView: {
                metricFields: gridAggregationFields
              }
            }
          }
        }
      });
    }
  } else if (metricType === 'inactive') {
    CustomPageContainer = InactiveProducts;
    enableComparison = false;
    dataConfig.indexName = indexName;

    widgets.push({
      CustomComponent: EntityGrid,
      view: {
        ...VIEWS.entityGrid,
        name: 'entityGridInactiveProductRecent',
        gridOptions: {
          title: 'Recently Inactivated Products',
          noDataAvailableMessage: 'No products were inactivated this week'
        }
      },
      data: {
        groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
        configByGroupByFieldName: {
          stacklineSku: {
            indexName,
            entity: ENTITIES.beacon.product,
            entityQueryConditions: {
              termFilters: [
                { fieldName: 'availabilityStatus', condition: 'must', values: ['inactive'] },
                { fieldName: 'availabilityStatusPrevious', condition: 'must', values: ['active'] }
              ]
            },
            mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'retailSales', 'product', 'stacklineSku'),
            aggregationFields: [INDEX_FIELDS.getField(app.name, indexName, 'retailSales', 'product', 'stacklineSku')]
          }
        }
      }
    });

    widgets.push({
      CustomComponent: EntityGrid,
      view: {
        ...VIEWS.entityGrid,
        name: 'entityGridInactiveProductAll',
        gridOptions: {
          title: 'All Inactivated Products',
          noDataAvailableMessage: 'No inactive products were found'
        }
      },
      data: {
        groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
        configByGroupByFieldName: {
          stacklineSku: {
            indexName,
            entity: ENTITIES.beacon.product,
            entityQueryConditions: {
              termFilters: [{ fieldName: 'availabilityStatus', condition: 'must', values: ['inactive'] }]
            },
            mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'retailSales', 'product', 'stacklineSku'),
            aggregationFields: [INDEX_FIELDS.getField(app.name, indexName, 'retailSales', 'product', 'stacklineSku')]
          }
        }
      }
    });
  } else if (metricType === 'reviewTrends') {
    if (shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: ReviewTrendsPage,
        name: 'reviewTrendsPage',
        view: {}
      });
    } else {
      CustomPageContainer = ReviewsContainer;
      enableComparison = false;

      widgets.push({
        CustomComponent: FilterKeywords,
        view: {
          name: 'filterReviewKeywords'
        },
        data: {}
      });

      const weeklyTrendChartWidget = {
        view: _merge({}, VIEWS.weeklyTrendChart, {
          chartPropsOverride: {
            chart: { height: 500 },
            title: { text: 'Rating' },
            subtitle: { text: '', style: { 'border-bottom': 'none' } }
          }
        }),
        data: {
          chartMainField: INDEX_FIELDS.getField(app.name, indexName, 'stars', entity.type, dataConfig.weekIdField.name),
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, indexName, 'stars', entity.type, dataConfig.weekIdField.name)
          ],
          groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.weekIdField.name, entity.type)
        }
      };

      const donutChartWidget = {
        view: _merge(VIEWS.donutChart, {
          chartPropsOverride: {
            plotOptions: {
              pie: { cursor: 'pointer' },
              series: { cursor: 'pointer' }
            }
          }
        }),
        data: {
          chartMainField: {
            ...INDEX_FIELDS.getField(app.name, indexName, 'stars', entity.type, dataConfig.weekIdField.name),
            displayName: 'Review Trends'
          },
          aggregationFields: [
            {
              ...INDEX_FIELDS.getField(app.name, indexName, 'stars', entity.type, dataConfig.weekIdField.name),
              displayName: 'Review Trends'
            }
          ],
          groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.weekIdField.name, entity.type),
          mainMetric: {
            ...INDEX_FIELDS.getField(app.name, indexName, 'stars', entity.type, dataConfig.weekIdField.name),
            displayName: 'Review Trends'
          },
          secondaryMetric: INDEX_FIELDS.getField(app.name, indexName, 'stars', entity.type, 'stars')
        }
      };

      const chartsWidgets = buildWidgetGroup([donutChartWidget, weeklyTrendChartWidget], {
        view: { container: { style: { display: 'flex', flex: 1 } } }
      });

      widgets.push(chartsWidgets);

      widgets.push({
        view: VIEWS.reviewsGrid,
        data: {
          entity: ENTITIES.beacon.product,
          mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'stars', 'product', 'stacklineSku'),
          customResponseParser: parseReviewsMetrics
        }
      });
    }
  } else if (metricType === 'reviews') {
    if (shouldShowRatingsAndReviewsV2()) {
      widgets.push({
        CustomComponent: ReviewAnalysisPage,
        name: 'reviewAnalysisPage',
        view: {}
      });
    }
  } else if (metricType === 'reviewRatings') {
    if (shouldShowRatingsAndReviewsV2()) {
      widgets.push({
        CustomComponent: ReviewRatingsPage,
        name: 'reviewRatings',
        view: {}
      });
    }
  } else if (metricType === 'reviewSentiment') {
    if (shouldShowRatingsAndReviewsV2()) {
      widgets.push({
        CustomComponent: ReviewSentimentPage,
        name: 'reviewSentimentalPage',
        view: {}
      });
    }
  } else if (metricType === 'highRiskReviews') {
    CustomPageContainer = ReviewsContainer;
    enableComparison = false;

    // Creates the title bar with the buttons for uploading high risk keywords
    widgets.push({
      view: {
        name: 'highRiskReviews'
      },
      data: {}
    });

    widgets.push({
      view: _merge({}, VIEWS.columnChart, {
        chartPropsOverride: {
          subtitle: {
            style: {
              borderBottom: 'none'
            }
          }
        }
      }),
      data: {
        chartMainField: INDEX_FIELDS.getField(app.name, indexName, 'stars', entity.type, dataConfig.weekIdField.name),
        aggregationFields: [
          INDEX_FIELDS.getField(app.name, indexName, 'stars', entity.type, dataConfig.weekIdField.name)
        ],
        groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.weekIdField.name, entity.type)
      }
    });

    widgets.push({
      view: VIEWS.reviewsGrid,
      data: {
        entity: ENTITIES.beacon.product,
        mainMetric: INDEX_FIELDS.getField(app.name, indexName, 'stars', 'product', 'stacklineSku'),
        customResponseParser: parseReviewsMetrics
      }
    });
  } else if (metricType === 'sellers') {
    if (shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: BuyBoxSellersPage,
        name: 'sellers',
        view: {}
      });
    } else {
      CustomPageContainer = GenericPageContainer;
      enableComparison = false;

      widgets.push({
        CustomComponent: SellerSummaryBarChart,
        name: 'sellerSummaryChart',
        view: _merge({}, VIEWS.barChart, {
          name: 'sellerSummaryChart',
          chartPropsOverride: {
            chart: {
              height: 2000,
              spacingRight: 50
            },
            subtitle: { style: { 'border-bottom': 'none', 'padding-bottom': 0 } },
            dataLabels: {
              x: 35
            },
            xAxis: {
              labels: {
                y: 6.5
              }
            }
          }
        }),
        data: {
          chartMainField: INDEX_FIELDS.getField(
            app.name,
            indexName,
            'sellerWinPercentage',
            entity.type,
            'merchantName'
          ),
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, indexName, 'sellerWinPercentage', entity.type, 'merchantName')
          ],
          groupByField: INDEX_FIELDS.getField(app.name, indexName, 'merchantName', entity.type)
        }
      });
    }

    if (entity.type === 'product' && !shouldShowNewBeacon()) {
      widgets.push({
        view: {
          name: 'lossPercentageByWeekId'
        },
        CustomComponent: SelfReliantProductWeeklySalesGrid,
        data: {
          entity,
          chartMainField: INDEX_FIELDS.getField(
            app.name,
            indexName,
            'lossPercentage',
            entity.type,
            dataConfig.weekIdField.name
          ),
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, indexName, 'lossPercentage', entity.type, dataConfig.weekIdField.name)
          ],
          groupByField: INDEX_FIELDS.getField(app.name, indexName, 'weekId'),
          indexName
        }
      });
    } else if (!shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: SellerSummaryEntityGrid,
        view: _merge({}, VIEWS.entityGrid, {
          anchorName: 'seller-summary-entity-grid',
          WrapperComponent: EntityGridNoclipHeaderWrapper,
          gridOptions: {},
          container: {
            style: {
              marginTop: '80px',
              marginBottom: '40px'
            }
          }
        }),
        data: {
          groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
          configByGroupByFieldName: {
            stacklineSku: {
              indexName,
              entity: ENTITIES.beacon.product,
              mainMetricField: INDEX_FIELDS.getField(
                app.name,
                indexName,
                'sellerWinPercentage',
                'product',
                'stacklineSku'
              ),
              aggregationFields: [
                INDEX_FIELDS.getField(app.name, indexName, 'sellerWinPercentage', 'product', 'stacklineSku')
              ],
              tableView: {
                metricFields: [
                  {
                    ...INDEX_FIELDS.getField(app.name, indexName, 'sellerWinPercentage', 'product', 'stacklineSku'),
                    displayName: 'Buy Box\nLoss Rate',
                    headerComponentFramework: MultiLineHCF,
                    tableColumnProps: {
                      style: {
                        width: '120px'
                      }
                    },
                    cellStyle: {
                      'text-align': 'right',
                      'padding-right': '20px',
                      'flex-direction': 'row-reverse'
                    }
                  }
                ]
              }
            }
          }
        }
      });
    }
  } else if (metricType === 'winPercentage') {
    // TODO: `retailPrice` index doesn't work; have to investigate backend
    const aggregationFields = ['winPercentage', 'lossPercentage'].map((fieldName) =>
      INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type, dataConfig.weekIdField.name)
    );

    const fieldOverridesByFieldName = {
      winPercentage: {
        displayName: 'Buy Box\nWin Rate',
        tableColumnProps: {
          style: {
            width: '120px'
          }
        }
      },
      lossPercentage: {
        displayName: 'Buy Box\nLoss Rate',
        tableColumnProps: {
          style: {
            width: '120px'
          }
        }
      },
      unitsSold: 'Total Units Sold'
    };

    const gridAggregationFields = aggregationFields.map((field) => ({
      ...field,
      ...(fieldOverridesByFieldName[field.name] || {}),
      headerComponentFramework: withManualBrHeader(
        withProps({
          sortArrowStyle: !field.name.includes('|') ? { marginTop: '1em' } : undefined
        })(CustomAgGridHeaderTemplate)
      ),
      cellStyle: { 'text-align': 'right', 'padding-right': '10px', 'flex-direction': 'row-reverse' }
    }));

    if (shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: BuyBoxSummaryPage,
        view: {
          container: {
            style: {
              marginBottom: `${BEACON_GRID_TOP_SPACING}px`
            }
          }
        },
        name: 'buyBoxSummary'
      });
    } else {
      widgets.push(buildTrendWidgetConfig(app, indexName, entity, 'weekId', [metricType], weekIdField, {}));
    }

    if (entity.type === 'product' && !shouldShowNewBeacon()) {
      widgets.push({
        view: {
          name: 'winPercentageByWeekId'
        },
        CustomComponent: SelfReliantProductWeeklySalesGrid,
        data: {
          chartMainField: INDEX_FIELDS.getField(
            app.name,
            indexName,
            metricType,
            entity.type,
            dataConfig.weekIdField.name
          ),
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, indexName, metricType, entity.type, dataConfig.weekIdField.name)
          ],
          groupByField: INDEX_FIELDS.getField(app.name, indexName, 'weekId'),
          indexName
        }
      });
    } else if (!shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: EntityGrid,
        view: _merge({}, VIEWS.entityGrid, {
          WrapperComponent: EntityGridNoclipHeaderWrapper,
          gridOptions: {}
        }),
        data: {
          defaultSortField: INDEX_FIELDS.getField(app.name, indexName, 'unitsSold', 'product', 'stacklineSku'),
          groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
          configByGroupByFieldName: {
            stacklineSku: {
              indexName,
              entity: ENTITIES.beacon.product,
              mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
              aggregationFields,
              tableView: {
                metricFields: gridAggregationFields
              }
            }
          }
        }
      });
    }
  } else if (metricType === 'purchaseRate') {
    if (shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: TrafficConversionPage,
        name: 'conversionRate',
        view: {
          container: {
            style: {}
          }
        }
      });
    } else {
      widgets.push(
        buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['purchaseRate'], weekIdField, {
          view: {
            chartPropsOverride: {
              title: { text: 'Conversion Rate' }
            }
          }
        })
      );
      widgets.push(buildConversionRateGridWidget({ app, entity }));
    }
  }

  return {
    CustomPageContainer,
    enableComparison,
    widgets,
    dataConfig
  };
}
