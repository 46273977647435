/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import _constant from 'lodash/constant';
import _groupBy from 'lodash/groupBy';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _prop from 'lodash/property';
import _uniqBy from 'lodash/uniqBy';
import { AppName } from 'sl-api-connector/types';
import { Option } from 'funfix-core';
import { withBus } from 'react-bus';
import { mergeConditions, Conditions, TermFilter } from 'sl-api-connector/search/conditions';

// import { mkNumberFormatterCRF } from './TargetEntitiesGrid/GridFrameworks';
import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import { EntityColumn } from 'src/components/Grids/Data/ColumnTypes';
import { getMainConditions } from 'src/utils/conditions';
import { AdCampaignBuilderCheckbox } from 'src/components/AdCampaignBuilder/Widgets/Checkbox';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { getEntityGridParams } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import {
  fetchSecondaryFeaturedProductsData as fetchFeaturedProductsData,
  mkSecondaryFieldCRF
} from 'src/components/Grids/MultiIndexGrid';
import EntityTableContainer from 'src/components/EntityGrid/Table/EntityTableContainer';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { propEq, prop } from 'src/utils/fp';
import { WaterfallNonSortableHeaderComponentFramework } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import './FeaturedProductsGrid.scss';
import MultiLineHCF from 'src/components/EntityGrid/HeaderComponentFrameworks/MultiLineHCF';
import ReduxStore from 'src/types/store/reduxStore';
import { MetricField, AGGridValueFormatter } from 'src/types/application/types';
import { RowDatum, SelectedFeaturedProductsItem } from 'src/types/store/storeTypes';
import { CAMPAIGN_TYPES } from 'src/store/modules/adManager/adCampaignBuilder/types';
import { store } from 'src/main';
import { NoResultWithOpenFilter } from 'src/components/Grids/Data/NoResultTemplate';
import { filterNils } from 'sl-api-connector/util';
import { useBus } from 'src/utils/Hooks';
import { createSelector } from 'reselect';
import { SelectAllCheckbox } from 'src/components/AdCampaignBuilder/Widgets/TargetEntitiesGrid/TargetEntitiesGrid';
import colors from 'src/utils/colors';
import { buildAggregations } from 'src/components/AdManager/Search';

const PAGE_SIZE = 100;

interface ProductDataKey {
  data: { value: number; entity: { id: string; value: number } }[];
}

interface ProductData {
  entity: SelectedFeaturedProductsItem;
  rawData: {
    retailPrice_by_stacklineSku: ProductDataKey;
    wholesalePrice_by_stacklineSku: ProductDataKey;
    retailerGrossMarginPercent_by_stacklineSku: ProductDataKey;
    brandCogsPerUnit_by_stacklineSku: ProductDataKey;
  };
}

/**
 * A custom column renderer that creates checkboxes for the featured products grid.  The state of the checkboxes is
 * managed via Redux.
 */
const CheckboxColumnRenderer = connect(
  ({ adCampaignBuilder }: ReduxStore, { data }: { data: ProductData }) => {
    const { entity } = data;
    return {
      checked: adCampaignBuilder.featured.selectedFeaturedProducts.findIndex((x) => x.id === entity.id) >= 0
    };
  },
  (dispatch, { data }) => {
    const entity: SelectedFeaturedProductsItem = { ...data.entity };
    entity.retailPrice = data.rawData.retailPrice_by_stacklineSku.data.find((x) => x.entity.id === entity.id)!.value;
    entity.wholesalePrice = data.rawData.wholesalePrice_by_stacklineSku.data.find(
      (x) => x.entity.id === entity.id
    )!.value;
    entity.retailerGrossMarginPercent = data.rawData.retailerGrossMarginPercent_by_stacklineSku.data.find(
      (x) => x.entity.id === entity.id
    )!.value;
    entity.brandCogsPerUnit = data.rawData.brandCogsPerUnit_by_stacklineSku.data.find(
      (x) => x.entity.id === entity.id
    )!.value;

    return {
      toggleChecked: () => dispatch(adCampaignBuilderActions.toggleFeaturedProductSelected(entity))
    };
  }
)(({ checked, toggleChecked }) => <AdCampaignBuilderCheckbox checked={checked} onChange={toggleChecked} />);

const groupByField = INDEX_FIELDS.getField(AppName.Beacon, 'sales', 'stacklineSku');
const entity = ENTITIES.beacon.product;

const primaryAggregationFields = [
  'retailSales',
  'retailPrice',
  'wholesalePrice',
  'retailerGrossMarginPercent',
  'brandCogsPerUnit',
  'instockRate',
  'retailerGrossMargin',
  'brandGrossMargin'
].map((fieldName) => INDEX_FIELDS.getField(AppName.Beacon, 'sales', fieldName, 'product', 'stacklineSku'));

const secondaryAggregationFields = [
  ['buybox', 'winPercentage'],
  ['advertising', 'spend'],
  ['content', 'contentScore'],
  ['reviews', 'stars']
].map(([indexName, fieldName]) =>
  INDEX_FIELDS.getField(AppName.Beacon, indexName, fieldName, 'product', 'stacklineSku')
);

const formatEntity = (data: any) => {
  data.retailSales_by_stacklineSku.data.forEach((item) => {
    const productId = item.entity.id;
    const retailPrice = data.retailPrice_by_stacklineSku.data.find((x) => x.entity.id === productId)!.value;
    const wholesalePrice = data.wholesalePrice_by_stacklineSku.data.find((x) => x.entity.id === productId)!.value;
    const retailerGrossMarginPercent = data.retailerGrossMarginPercent_by_stacklineSku.data.find(
      (x) => x.entity.id === productId
    )!.value;
    const brandCogsPerUnit = data.brandCogsPerUnit_by_stacklineSku.data.find((x) => x.entity.id === productId)!.value;
    item.entity = {
      ...item.entity,
      retailPrice,
      wholesalePrice,
      retailerGrossMarginPercent,
      brandCogsPerUnit
    };
  });
};

const valueFormatterMapping = (fieldName: string) =>
  ((
    {
      stacklineSku: undefined,
      retailSales: undefined,
      retailPrice: undefined,
      instockRate: undefined,
      retailerGrossMargin: undefined
    } as { [key: string]: AGGridValueFormatter | undefined }
  )[fieldName]);

const buildGridRendererFields = (retailer: ReduxStore['retailer']) =>
  (
    [
      // Manually add in column for the group-by field since we're overriding the first column definition
      [
        'sales',
        'stacklineSku',
        {
          ...groupByField,
          minWidth: 300,
          maxWidth: 400,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          cellRendererFramework: EntityColumn
        }
      ],
      ['sales', 'retailSales'],
      ['sales', 'retailPrice'],
      ['advertising', 'spend'],
      ['content', 'contentScore'],
      ['reviews', 'stars', { displayName: 'Star Rating' }],
      ['sales', 'instockRate'],
      ['buybox', 'winPercentage', { displayName: 'Buy Box Rate' }],
      ['sales', 'retailerGrossMargin'],
      ['sales', 'brandGrossMargin']
    ] as [string, string, object | undefined][]
  ).map(([indexName, fieldName, overrides = {}]) => {
    const field = INDEX_FIELDS.getField(AppName.Beacon, indexName, fieldName, 'product', 'stacklineSku');
    return {
      ...field,
      valueFormatter:
        indexName !== 'sales' ? mkSecondaryFieldCRF(groupByField, field, retailer) : valueFormatterMapping(fieldName),
      headerComponentFramework: indexName === 'sales' ? MultiLineHCF : WaterfallNonSortableHeaderComponentFramework,
      cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' },
      ...overrides
    };
  });

const buildRequestConditions = (
  retailer: ReduxStore['retailer'],
  indexName: string,
  fields: MetricField[],
  entityIdsConditions?: Conditions | null,
  leftNavConditions?: Conditions | null
) => {
  const { availableMainTimePeriods } = (store.getState() as ReduxStore).mainTimePeriod;
  // Always fetch data for the "4w" time period, only for reviews star rating consider last 52 weeks of data
  const timePeriod =
    indexName === 'reviews'
      ? availableMainTimePeriods.find(propEq('id', '52w'))!
      : availableMainTimePeriods.find(propEq('id', '4w'))!;

  // This emulates the behavior of `EntityPage` in building the API request
  // conditions from the application's global Redux store.
  const { baseAggregationConditions, mainEntityConditions } = getMainConditions();

  const { entityGridAggregationConditions } = getEntityGridParams(
    retailer,
    timePeriod,
    {},
    baseAggregationConditions,
    fields[0]
  );

  // Strip out term filters that have some fields as their field since we will never end up setting that condition
  // ourselves here but it gets set via the main entity when this featured products grid gets rendered inside of
  // the ad manager when editing existing campaigns.
  const mergedMainEntityConditions = mergeConditions(mainEntityConditions, leftNavConditions);
  mergedMainEntityConditions.termFilters = (mergedMainEntityConditions.termFilters || []).filter(
    ({ fieldName }: { fieldName: string }) =>
      !['campaignId', 'portfolioId', 'entityId', 'adGroupId'].includes(fieldName)
  );

  return {
    entityGridAggregationConditions,
    mainEntityConditions: mergedMainEntityConditions,
    entityIdsConditions
  };
};

const mergeResponseData = (res1: { [key: string]: { data?: any[] } }, res2: { [key: string]: { data?: any[] } }) =>
  Object.entries(res2).reduce((acc, [key, val]) => {
    if (!val.data) {
      return { ...acc, [key]: val };
    }

    return { ...acc, [key]: { ...val, data: [...res1[key].data!, ...val.data] } };
  }, {});

const fetchPrimaryData = async ({
  reduxState,
  leftNavConditions,
  sortByAggregationFieldName,
  sortDirection,
  eventBus,
  entityIdsConditions,
  existingFeaturedProductIds,
  bulkUploadedRetailerSkus,
  setBulkUploadWarningMsg,
  shouldSelectBulkProducts
}: {
  reduxState: Pick<ReduxStore, 'retailer' | 'app'> & {
    productsForStoreStacklineSkus?: string[];
    campaignTypeId: string;
  };
  leftNavConditions: Conditions;
  sortByAggregationFieldName: string;
  sortDirection: string;
  eventBus: any;
  entityIdsConditions?: Conditions | null;
  existingFeaturedProductIds?: string[];
  bulkUploadedRetailerSkus?: string[] | null | undefined;
  setBulkUploadWarningMsg?: (msg: string | null) => void;
  shouldSelectBulkProducts?: boolean;
}) => {
  const secondaryRequestConditions = buildRequestConditions(
    reduxState.retailer,
    'sales',
    primaryAggregationFields,
    entityIdsConditions,
    leftNavConditions
  );

  const { aggregations: aggregationFields } = buildAggregations(primaryAggregationFields)[0];
  const rangeFiltersFields = secondaryRequestConditions.entityGridAggregationConditions.rangeFilters;
  secondaryRequestConditions.entityGridAggregationConditions.rangeFilters = [];

  // Explicitly fetch data for any bulk-uploaded retailer skus.
  const bulkUploadedProductsData: any = bulkUploadedRetailerSkus
    ? await fetchFeaturedProductsData(
        { entity, dataKey: 'featuredProductsGridPrimaryData', pageSize: PAGE_SIZE },
        {
          entityGridAggregationConditions: secondaryRequestConditions.entityGridAggregationConditions,
          mainEntityConditions: {},
          entityIdsConditions: mergeConditions(entityIdsConditions, {
            condition: 'must' as const,
            termFilters: [
              {
                fieldName: 'retailerSku',
                condition: 'must' as const,
                values: bulkUploadedRetailerSkus
              }
            ],
            rangeFilters: rangeFiltersFields
          })
        },
        { retailer: reduxState.retailer, app: { ...reduxState.app, name: 'beacon' } },
        primaryAggregationFields[0].indexName,
        aggregationFields,
        groupByField,
        true,
        aggregationFields.find(propEq('name', sortByAggregationFieldName)),
        sortDirection
      )
    : null;

  if (bulkUploadedProductsData) {
    formatEntity(bulkUploadedProductsData);
    // Manually add empty items for bulk uploaded SKUs for which there were no results
    const expectedSkus = new Set(bulkUploadedRetailerSkus);
    bulkUploadedProductsData.retailSales_by_stacklineSku.data.forEach((datum: any) =>
      expectedSkus.delete(datum.entity.retailerSku)
    );

    if (setBulkUploadWarningMsg) {
      if (expectedSkus.size > 0) {
        setBulkUploadWarningMsg(
          'One or more of the retailer SKUs that you uploaded were not found or do not belong to a brand you own.  Metrics for those SKUs will not be displayed.'
        );
      } else {
        setBulkUploadWarningMsg(null);
      }
    }

    const objectKeysToUpdate = Object.keys(bulkUploadedProductsData).filter(
      (key) => !key.includes('dataPointCount') && key.includes('_by_stacklineSku')
    );

    [...expectedSkus.values()].forEach((retailerSku) => {
      const dummyDatum = {
        name: retailerSku,
        count: 0,
        entity: {
          id: retailerSku,
          retailerSku
        },
        cardView: {
          secondaryDisplayTitle: retailerSku
        }
      };

      objectKeysToUpdate.forEach((key) => bulkUploadedProductsData[key].data.push(dummyDatum));
    });

    // Mark them as selected if this is the first data fetch after bulk-uploading SKUs
    if (shouldSelectBulkProducts) {
      const { selectedFeaturedProducts: existingSelectedFeaturedProducts } = (store.getState() as ReduxStore)
        .adCampaignBuilder.featured;
      store.dispatch(
        adCampaignBuilderActions.setSelectedFeaturedProducts(
          _uniqBy(
            [
              ...existingSelectedFeaturedProducts,
              ...bulkUploadedProductsData.retailSales_by_stacklineSku.data.map(prop('entity'))
            ],
            'retailerSku'
          )
        )
      );
    }
  }

  // If this is a sponsored brands campaign and we have a list of products for the store page, we should only show
  // products from that list in this grid, so we construct a condition to enforce that.
  const storeProductsTermFilter: TermFilter | null =
    reduxState.campaignTypeId === CAMPAIGN_TYPES.SponsoredBrands && !_isEmpty(reduxState.productsForStoreStacklineSkus)
      ? {
          fieldName: 'stacklineSku',
          condition: 'should',
          values: reduxState.productsForStoreStacklineSkus!
        }
      : null;

  const maybeMergedEntityIdsConditions = storeProductsTermFilter
    ? mergeConditions(entityIdsConditions, { termFilters: [storeProductsTermFilter] })
    : entityIdsConditions;

  const data = await fetchFeaturedProductsData(
    { entity, dataKey: 'featuredProductsGridPrimaryData', pageSize: PAGE_SIZE },
    {
      ...secondaryRequestConditions,
      entityIdsConditions:
        // Avoid double-fetching products if we've already explicity fetched them to them existing in the campaign
        // that is being edited or bulk uploaded retailer skus
        mergeConditions(maybeMergedEntityIdsConditions, {
          condition: 'must' as const,
          termFilters: filterNils([
            existingFeaturedProductIds
              ? {
                  fieldName: 'stacklineSku',
                  condition: 'must_not' as const,
                  values: existingFeaturedProductIds
                }
              : null,
            Option.of(bulkUploadedRetailerSkus)
              .map((retailerSkus) => ({
                fieldName: 'retailerSku',
                condition: 'must_not' as const,
                values: retailerSkus
              }))
              .orNull()
          ]),
          rangeFilters: rangeFiltersFields
        })
    },
    { retailer: reduxState.retailer, app: { ...reduxState.app, name: 'beacon' } },
    primaryAggregationFields[0].indexName,
    aggregationFields,
    groupByField,
    true,
    aggregationFields.find(propEq('name', sortByAggregationFieldName)),
    sortDirection,
    eventBus
  );

  formatEntity(data);
  return bulkUploadedProductsData ? mergeResponseData(bulkUploadedProductsData, data) : data;
};

const fetchSecondaryData = async ({
  primaryData,
  reduxState,
  leftNavConditions
}: {
  primaryData: {
    retailSales_by_stacklineSku: {
      data: RowDatum[];
    };
  };
  reduxState: Pick<ReduxStore, 'app' | 'retailer'>;
  leftNavConditions: Conditions;
}) => {
  const entityIds = primaryData.retailSales_by_stacklineSku.data.map((datum) => datum.cardView.stacklineSku);

  // Construct a query condition that limits returned results to only those for the already fetched product IDs
  const entityIdsConditions: Conditions = {
    termFilters: [
      {
        condition: 'should',
        fieldName: groupByField.name,
        values: filterNils(entityIds)
      }
    ]
  };

  const secondaryRequestsByIndexName = _groupBy(secondaryAggregationFields, _prop('indexName'));

  const promises = Object.entries(secondaryRequestsByIndexName).map(([indexName, fields]) => {
    const { aggregations } = buildAggregations(fields)[0];

    const secondaryRequestConditions = buildRequestConditions(
      reduxState.retailer,
      indexName,
      fields,
      entityIdsConditions,
      leftNavConditions
    );
    secondaryRequestConditions.mainEntityConditions.rangeFilters =
      secondaryRequestConditions.entityGridAggregationConditions.rangeFilters;
    secondaryRequestConditions.entityGridAggregationConditions.rangeFilters = [];

    return fetchFeaturedProductsData(
      { entity, dataKey: `featuredProductsGridSecondaryRequest_${indexName}`, pageSize: PAGE_SIZE },
      secondaryRequestConditions,
      { retailer: reduxState.retailer, app: { ...reduxState.app, name: 'beacon' } },
      indexName,
      aggregations,
      groupByField,
      true
    ).then((res) => ({ res, fields }));
  });

  const responses = await Promise.all(promises);
  return responses.reduce((acc, { res, fields }) => {
    const mappings = fields.reduce((innerAcc, { name }) => {
      const dataKey = `${name}_by_stacklineSku`;
      const { data }: any = res[dataKey];

      const mapping: { [stacklineSku: string]: any } = {};
      data.forEach((datum: any) => {
        mapping[datum.cardView.stacklineSku as string] = datum;
      });

      return {
        ...innerAcc,
        [dataKey]: mapping
      };
    }, {});

    return {
      ...acc,
      ...mappings
    };
  }, {});
};

const selectedFeaturedProductRetailerSKUsSelector = createSelector(
  ({ selectedFeaturedProducts }: ReduxStore['adCampaignBuilder']['featured']) => selectedFeaturedProducts,
  (selectedFeaturedProducts) => new Set(selectedFeaturedProducts.map(prop('retailerSku')))
);

const mapStateToProps = ({ adCampaignBuilder: { featured, campaignType }, ...state }: ReduxStore) => {
  const props = {
    ..._pick(state, ['retailer', 'app']),
    leftNavConditions: {
      termFilters: Object.entries(featured.filters.termFilters || {}).reduce(
        (acc, [key, termFilter]) =>
          !_isEmpty(termFilter.values)
            ? [
                ...acc,
                {
                  fieldName: key,
                  condition: termFilter.condition,
                  values: termFilter.values!.map(_prop('i')) as (string | number)[]
                }
              ]
            : acc,
        [] as TermFilter[]
      )
    },
    existingFeaturedProductIds: featured.existingFeaturedProductIds,
    productsForStoreStacklineSkus: featured.productsForStoreStacklineSkus,
    selectedFeaturedProductRetailerSKUs: selectedFeaturedProductRetailerSKUsSelector(featured),
    bulkUploadedFeaturedProductRetailerSkus: featured.bulkUploadedFeaturedProductRetailerSkus,
    campaignTypeId: campaignType!.settingId,
    campaign: state.entityService.mainEntity,
    landingType: featured.landingType,
    selectedFeaturedProducts: featured.selectedFeaturedProducts
  };

  if (props.campaign) {
    if (props.campaign.type === 'adGroup') {
      props.campaign = state.entityService.campaignForAdGroup;
    }
  }

  return props;
};

const FeaturedProductsGrid: React.FC<
  {
    eventBus: any;
    selectedAdGroup?: string;
    style?: React.CSSProperties;
    gridStyle?: React.CSSProperties;
    gridContainerStyle?: React.CSSProperties;
  } & ReturnType<typeof mapStateToProps>
> = ({
  eventBus,
  leftNavConditions,
  style,
  selectedAdGroup,
  campaign,
  gridStyle,
  gridContainerStyle,
  selectedFeaturedProductRetailerSKUs,
  bulkUploadedFeaturedProductRetailerSkus,
  selectedFeaturedProducts,
  ...reduxState
}) => {
  const [dataSet, setDataSet] = useState<any>(null);
  const dataFetched = useRef(false);
  const lastLeftNavConditions = useRef<{
    termFilters: TermFilter[];
  } | null>(null);
  const lastSortByAggregationFieldName = useRef<null | string>(null);
  const [sortByAggregationFieldName, setSortByAggregationFieldName] = useState('retailSales');
  const lastSortDirection = useRef<string | null>(null);
  const [sortDirection, setSortDirection] = useState('desc');
  const [bulkUploadWarningMsg, setBulkUploadWarningMsg] = useState<string | null>(null);

  const lastCampaignProducts = useRef<string[]>([]);
  const [campaignProducts, setCampaignProducts] = useState<string[]>([]);

  const [isAllFeaturedProductsSelected, setIsAllFeaturedProductsSelected] = useState(false);

  useEffect(() => {
    if (campaign) {
      if (campaign.type === 'adCampaign') {
        const products = _get(campaign, ['adCampaignProducts'], [])
          .filter((product) => product.adGroupId === selectedAdGroup && product.extendedAttributes.productMetaData)
          .map((product) => product.extendedAttributes.productMetaData.id);
        setCampaignProducts(products);
      }
    }
  }, [campaign, selectedAdGroup]);

  useEffect(() => {
    if (dataSet && selectedFeaturedProducts) {
      if (dataSet.retailSales_by_stacklineSku) {
        const equal = selectedFeaturedProducts.length === dataSet.retailSales_by_stacklineSku.data.length;
        if (equal && !isAllFeaturedProductsSelected) {
          setIsAllFeaturedProductsSelected(equal);
        } else if (!equal && isAllFeaturedProductsSelected) {
          setIsAllFeaturedProductsSelected(equal);
        }
      }
    }
  });

  const fetchData = useCallback(
    async (shouldSelectBulkProducts?: boolean) => {
      setDataSet(null);

      const bulkUploadedRetailerSkus = (store.getState() as ReduxStore).adCampaignBuilder.featured
        .bulkUploadedFeaturedProductRetailerSkus;

      const campaignType = (store.getState() as ReduxStore).adCampaignBuilder.campaignType!.id;

      const primaryData = await fetchPrimaryData({
        reduxState,
        leftNavConditions,
        sortByAggregationFieldName,
        sortDirection,
        eventBus,
        entityIdsConditions: null,
        existingFeaturedProductIds: campaignProducts,
        bulkUploadedRetailerSkus,
        setBulkUploadWarningMsg,
        shouldSelectBulkProducts: campaignType === 'sponsoredDisplay' ? false : shouldSelectBulkProducts
      });

      const secondaryData = await fetchSecondaryData({ primaryData, reduxState, leftNavConditions });
      const newDataSet = { ...(dataSet || {}), ...primaryData, ...secondaryData };
      setDataSet(newDataSet);

      // De-select any products that no longer appear in the products list with the new filters
      store.dispatch(
        adCampaignBuilderActions.setSelectedFeaturedProducts(
          (store.getState() as ReduxStore).adCampaignBuilder.featured.selectedFeaturedProducts.filter(
            (product) =>
              !!newDataSet.retailSales_by_stacklineSku[product.stacklineSku] ||
              (!!bulkUploadedRetailerSkus && bulkUploadedRetailerSkus.includes(product.retailerSku))
          )
        )
      );
    },
    [dataSet, eventBus, campaignProducts, leftNavConditions, reduxState, sortByAggregationFieldName, sortDirection]
  );

  useBus(eventBus, 'fetchFeaturedProductsData', fetchData);

  useEffect(() => {
    const leftNavConditionsChanged = !_isEqual(lastLeftNavConditions.current, leftNavConditions);
    lastLeftNavConditions.current = leftNavConditions;

    const sortByAggregationFieldNameChanged = lastSortByAggregationFieldName.current !== sortByAggregationFieldName;
    lastSortByAggregationFieldName.current = sortByAggregationFieldName;

    const sortDirectionChanged = lastSortDirection.current !== sortDirection;
    lastSortDirection.current = sortDirection;

    const campaignProductsChanged = lastCampaignProducts.current !== campaignProducts;
    lastCampaignProducts.current = campaignProducts;

    if (
      dataFetched.current &&
      !leftNavConditionsChanged &&
      !sortByAggregationFieldNameChanged &&
      !sortDirectionChanged &&
      !campaignProductsChanged
    ) {
      return;
    }
    dataFetched.current = true;

    fetchData();
  }, [leftNavConditions, sortByAggregationFieldName, sortDirection, campaignProducts, fetchData]);

  const nonBulkUploadedFeaturedProductRetailerSKUs: string[] = useMemo(() => {
    if (!dataSet) {
      return [];
    }

    return dataSet.retailSales_by_stacklineSku.data
      .map(({ entity: { retailerSku } }: { entity: SelectedFeaturedProductsItem }) => retailerSku)
      .filter(
        (retailerSku: string) =>
          !bulkUploadedFeaturedProductRetailerSkus || !bulkUploadedFeaturedProductRetailerSkus.includes(retailerSku)
      );
  }, [dataSet, bulkUploadedFeaturedProductRetailerSkus]);

  if (!dataSet) {
    return <GridLoading />;
  }
  const gridRendererFields = buildGridRendererFields(reduxState.retailer);

  const isDataSetEmpty = _get(dataSet, ['retailSales_by_stacklineSku', 'data', 'length'], 0) === 0;
  const height = isDataSetEmpty ? 50 : 880;

  const allNonBulkUploadedFeaturedProductsSelected = nonBulkUploadedFeaturedProductRetailerSKUs.every((sku) =>
    selectedFeaturedProductRetailerSKUs.has(sku)
  );

  const handleSelectAllChange = (isChecked: boolean) => {
    const existingSelectedFeaturedProductEntities = (store.getState() as ReduxStore).adCampaignBuilder.featured
      .selectedFeaturedProducts;
    const selectedBulkUploadedProductEntities = existingSelectedFeaturedProductEntities.filter(
      ({ retailerSku }) =>
        bulkUploadedFeaturedProductRetailerSkus && bulkUploadedFeaturedProductRetailerSkus.includes(retailerSku)
    );

    if (isChecked) {
      const allNonBulkUploadedProductEntities: SelectedFeaturedProductsItem[] = dataSet.retailSales_by_stacklineSku.data
        .filter(
          (product: { entity: SelectedFeaturedProductsItem }) =>
            !bulkUploadedFeaturedProductRetailerSkus ||
            !bulkUploadedFeaturedProductRetailerSkus.includes(product.entity.retailerSku)
        )
        .map(prop('entity'));

      // Include all non-bulk-uploaded products and existing selected bulk-uploaded products
      store.dispatch(
        adCampaignBuilderActions.setSelectedFeaturedProducts([
          ...selectedBulkUploadedProductEntities,
          ...allNonBulkUploadedProductEntities
        ])
      );
    } else {
      // Retain only selected bulk-uploaded products
      store.dispatch(adCampaignBuilderActions.setSelectedFeaturedProducts(selectedBulkUploadedProductEntities));
    }
  };

  return (
    <>
      {bulkUploadWarningMsg ? (
        <span style={{ color: colors.orange, display: 'block', marginLeft: 8, marginTop: 20 }}>
          {bulkUploadWarningMsg}
        </span>
      ) : null}

      <div className="featured-products-grid" style={style}>
        {reduxState.campaignTypeId === CAMPAIGN_TYPES.SponsoredBrands && (
          <div>
            {reduxState.landingType === 'Brand Store' ? null : (
              <SelectAllCheckbox
                checked={isAllFeaturedProductsSelected}
                onChange={() => handleSelectAllChange(!allNonBulkUploadedFeaturedProductsSelected)}
              />
            )}
          </div>
        )}
        <EntityTableContainer
          dataSet={dataSet}
          firstColumnDefOverrides={{
            displayName: ' ',
            cellRendererFramework: CheckboxColumnRenderer,
            minWidth: 50,
            maxWidth: 50
          }}
          columnDefinitions={gridRendererFields}
          buildRows={_constant(dataSet)}
          mainMetricField={primaryAggregationFields[0]}
          groupByField={groupByField}
          metricFields={gridRendererFields}
          deltaRowDataMode
          agGridProps={{
            rowHeight: 77,
            getRowNodeId: (datum: { id: string }) => escape(datum.id),
            domLayout: 'autoHeight',
            suppressNoRowsOverlay: true,
            containerStyle: { width: '100%', ...(gridContainerStyle || {}), height }
          }}
          sortByMetric={sortByAggregationFieldName}
          sortDirection={sortDirection}
          onSortClick={(fieldName: string) => {
            if (fieldName === sortByAggregationFieldName) {
              setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            } else {
              setSortByAggregationFieldName(fieldName);
            }
          }}
          style={{ marginTop: 30, ...(gridStyle || {}) }}
        />
        {isDataSetEmpty && <NoResultWithOpenFilter />}
      </div>
    </>
  );
};

const EnhancedFeaturedProductsGrid = connect(mapStateToProps)(withBus('eventBus')(FeaturedProductsGrid));

export default EnhancedFeaturedProductsGrid;
