import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { truncateWithEllipsis } from '../../../utils/stringFormatting';

const styles = {
  root: {
    verticalAlign: 'middle',
    marginRight: '20px'
  },
  attachmentIcon: {
    verticalAlign: 'middle',
    width: '20px',
    height: '20px',
    marginRight: '-12px',
    marginLeft: '12px'
  }
};

const FileUpload = ({ accept, label, onClick, onChange, style, maxLabelLength }) => {
  const [, setFileUploaded] = useState(false);
  return (
    <Button style={{ ...styles.root, ...style }}>
      {/* {fileUploaded ? <AttachmentIcon style={styles.attachmentIcon} /> : null} */}
      <label htmlFor="file-input" style={{ color: '#06294A', fontWeight: 'bold' }}>
        {label ? truncateWithEllipsis(maxLabelLength, label) : label}
      </label>
      <input
        id="file-input"
        className="sl-file-input"
        type="file"
        accept={accept}
        onChange={(evt) => {
          try {
            setFileUploaded(true);
            onChange(evt);
          } finally {
            // Empty out the file input's value to work around behavior where the `onChange` handler isn't
            // triggered if the same file is selected twice
            evt.target.value = null;
          }
        }}
        onClick={onClick}
      />
    </Button>
  );
};

FileUpload.propTypes = {
  accept: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  maxLabelLength: PropTypes.number
};

FileUpload.defaultProps = {
  accept: '.csv',
  label: 'Choose File',
  onClick: undefined,
  style: {},
  maxLabelLength: 11
};

export default FileUpload;
