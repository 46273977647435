import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'qs';
import Truncate from 'react-truncate';

import colors from 'src/utils/colors';
import 'src/components/Grids/Data/ColumnTypes/Entity.scss';
import ReduxStore from 'src/types/store/reduxStore';
import { getCampaignDisplayStatus, getStrategyDisplayInfo } from 'src/components/AdManager/AmsUtils';
import { isCriteo, shouldShowCriteo } from 'src/utils/app';
import { getRetailerId } from 'src/utils/browser';

const styles: { [key: string]: React.CSSProperties } = {
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  textLink: {
    fontSize: 14,
    whiteSpace: 'pre-wrap',
    lineHeight: '1.5em',
    maxHeight: '3em',
    overflow: 'hidden'
  },
  imageWrapper: {
    alignSelf: 'center',
    minWidth: 45,
    textAlign: 'center',
    marginRight: 10
  },
  brandIcon: {
    stroke: colors.lightGrey,
    strokeWidth: 13,
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  imageWrapperInner: {
    width: 45,
    display: 'table'
  },
  imageWrapperInnerInner: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  image: {
    maxWidth: 35,
    maxHeight: 35
  }
};

const mapStateToProps = ({ app, adCampaigns, adPortfolios }: ReduxStore) => ({ app, adCampaigns, adPortfolios });

type EntityColumnProps = {
  data?: { [key: string]: any };
  url?: string;
  style?: React.CSSProperties;
  value?: { [key: string]: any };
  getLinkOverride?: (props: {
    type: string;
    id: string | number;
    searchParams: string;
    parsedAdditionalParameters: { [key: string]: any };
  }) => string;
} & ReturnType<typeof mapStateToProps>;

interface EntityColumnState {
  value?: EntityColumnProps['value'];
  imageUri?: string | null;
  didImageLoadError?: boolean;
}

class CampaignPortfolioColumn extends React.Component<EntityColumnProps, EntityColumnState> {
  public static defaultProps = {
    data: {},
    value: {},
    style: {},
    url: undefined,
    getLinkOverride: undefined
  };

  public static propTypes = {
    data: (props: EntityColumnProps, _propName: string, componentName: string) => {
      if (!props.data && !props.value) {
        return new Error(`One of props 'data' or 'url' was not specified in '${componentName}'.`);
      }
      return null;
    },
    value: (props: EntityColumnProps, _propName: string, componentName: string) => {
      if (!props.data && !props.value) {
        return new Error(`One of props 'url' or 'data' was not specified in '${componentName}'.`);
      }
      return null;
    },
    style: PropTypes.object,
    app: PropTypes.object.isRequired,
    url: PropTypes.string,
    getLinkOverride: PropTypes.func
  };

  public state: EntityColumnState = {};

  public componentWillMount() {
    const { value, data } = this.props;
    const val = value && value.name ? value : data;

    this.setState({
      value: val
    });
  }

  private stopPropagation = (evt: { stopPropagation: Function }) => evt.stopPropagation();

  private getValue = () => {
    if (!this.state.value) {
      return null;
    } else if (this.state.value.type) {
      return this.state.value;
    } else if (this.state.value.entity) {
      return this.state.value.entity;
    } else {
      return this.state.value;
    }
  };

  private showTextLink() {
    const {
      app: {
        queryParams: { searchParams, additionalParams }
      },
      adCampaigns,
      adPortfolios
    } = this.props;
    const parsedAdditionalParameters = queryString.parse(additionalParams);
    parsedAdditionalParameters.tab = 'adManager';
    parsedAdditionalParameters.subtab = 'keyMetrics';
    const value = this.getValue();
    if (value.type) {
      const adCampaign = adCampaigns.find((x) => x.campaignId === value.campaignId);

      if (value.type === 'adGroup' && adCampaign) {
        let link = '/';
        if (shouldShowCriteo() && isCriteo()) {
          const retailerId = getRetailerId();
          if (retailerId !== '0') {
            const parsedQuery = queryString.parse(searchParams);
            delete parsedQuery['?rid'];
            delete parsedQuery.rid;
            parsedQuery.rid = '0';

            link = `/${adCampaign.type}/${adCampaign.id}?${queryString.stringify(parsedQuery)}&${queryString.stringify(
              parsedAdditionalParameters
            )}`;
          } else {
            const parsedQuery = queryString.parse(searchParams);
            delete parsedQuery['?rid'];
            delete parsedQuery.rid;
            parsedQuery.rid = '0';

            link = `/${adCampaign.type}/${adCampaign.id}?${queryString.stringify(parsedQuery)}&${queryString.stringify(
              parsedAdditionalParameters
            )}`;
          }
        } else {
          link = `/${adCampaign.type}/${adCampaign.id}${searchParams}&${queryString.stringify(
            parsedAdditionalParameters
          )}`;
        }

        const { displayName: statusName, color: statusColor } = getCampaignDisplayStatus(adCampaign.extendedAttributes);
        return (
          <Link
            target="_blank"
            style={{ display: 'block', height: '100%', textDecoration: 'none' }}
            onClick={this.stopPropagation}
            to={link}
          >
            <div title={value.name}>
              <Truncate lines={2} ellipsis="...">
                {adCampaign.name}
              </Truncate>
              <div style={{ color: statusColor }}>{statusName}</div>
            </div>
          </Link>
        );
      }
      if (adCampaign) {
        const adPortfolio = adPortfolios.find((x) => x.id === adCampaign.extendedAttributes.portfolioId);
        if (adPortfolio) {
          const link = `/${adPortfolio.type}/${adPortfolio.id}${searchParams}&${queryString.stringify(
            parsedAdditionalParameters
          )}`;
          const { displayName: statusName, color: statusColor } = getCampaignDisplayStatus(
            adPortfolio.extendedAttributes
          );
          const { displayName: strategyName } = getStrategyDisplayInfo(adPortfolio.extendedAttributes);

          return (
            <Link
              target="_blank"
              style={{ display: 'block', height: '100%', textDecoration: 'none' }}
              onClick={this.stopPropagation}
              to={link}
            >
              <div title={value.name}>
                <Truncate lines={1} ellipsis="...">
                  {adPortfolio.extendedAttributes.name}
                </Truncate>
                <div>{strategyName}</div>
                <div style={{ color: statusColor }}>{statusName}</div>
              </div>
            </Link>
          );
        }
      }
    }
    return null;
  }

  public render() {
    return (
      <div style={{ display: 'flex', ...this.props.style }}>
        <div style={styles.text}>{this.showTextLink()}</div>
      </div>
    );
  }
}

const EnhancedCampaignPortfolioColumn = connect(mapStateToProps)(CampaignPortfolioColumn);

export default EnhancedCampaignPortfolioColumn;
