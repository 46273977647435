import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _capitalize from 'lodash/capitalize';

import colors from 'src/utils/colors';
import * as appOperations from 'src/store/modules/app/operations';
import { VerifiedIcon } from '../SvgIcons/index';

// The logic in this file may not be needed
class LoggingIn extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    fetchConfig: PropTypes.func.isRequired
  };

  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    const { fetchConfig } = this.props;
    fetchConfig().then(() => {
      this.context.router.history.push('/');
    });
  }

  render() {
    const { app } = this.props;
    return (
      <div style={{ textAlign: 'center' }}>
        <div>Your account has been created.</div>
        <VerifiedIcon style={{ marginTop: '15px', stroke: colors.atlasGreen, width: '60px', height: '60px' }} />
        <div style={{ marginTop: '10px', marginBottom: '20px' }}>Logging into {_capitalize(app.name)}...</div>
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => ({ app });

const mapDispatchToProps = {
  fetchConfig: appOperations.fetchConfig
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoggingIn));
