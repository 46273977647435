import React, { Component } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import { AutoSizer, List as VirtualizedList } from 'react-virtualized';
import { ListFilterRow } from 'src/components/EntityPage/Filters/common';
import ReduxStore from 'src/types/store/reduxStore';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'qs';
import TextField from '@mui/material/TextField';
import i18n from 'src/i18n_en';

const NoChargeBackStatusesFound = () => <div style={{ height: 175 }}>{i18n.promoTypes_noneFound}</div>;

interface ChargeBackStatusesForEntity {
  name: string;
  displayName: string;
  isChecked: boolean;
}

interface ChargeBackStatusesFilterProps {
  filters: any;
  chargeBackStatuses: ReduxStore['chargeBackStatuses'];
  entity: any;
  location: any;
  onFilterChange: (arg0: any, arg1: any) => void;
}

interface ChargeBackStatusesFilterState {
  searchText: string;
  chargeBackStatusesForEntity: ChargeBackStatusesForEntity[];
  staticSearchChargeBackStatuses: any[];
}

class ChargeBackStatusesFilter extends Component<ChargeBackStatusesFilterProps, ChargeBackStatusesFilterState> {
  public constructor(props: ChargeBackStatusesFilterProps) {
    super(props);

    this.state = {
      searchText: '',
      chargeBackStatusesForEntity: [],
      staticSearchChargeBackStatuses: []
    };
  }

  public componentWillMount() {
    this.setChargeBackStatuses(this.props);
  }

  private setChargeBackStatuses = (props: ChargeBackStatusesFilterProps) => {
    const { chargeBackStatuses, filters, location } = props;

    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const parsedFilterParams = queryParams.filter && queryString.parse(JSON.parse(queryParams.filter));

    const chargeBackStatusesForEntity = chargeBackStatuses.map((val) => {
      const isChecked =
        filters.chargeBackStatuses !== null &&
        parsedFilterParams &&
        parsedFilterParams.pt &&
        !!parsedFilterParams.pt.find((item: { i: string }) => item.i === val.name);

      return { ...val, isChecked };
    });

    this.setState({
      staticSearchChargeBackStatuses: chargeBackStatusesForEntity,
      chargeBackStatusesForEntity,
      searchText: ''
    });
  };

  public handleCheck = (event: any, isInputChecked: boolean, index: any) => {
    const { onFilterChange } = this.props;
    const { chargeBackStatusesForEntity, staticSearchChargeBackStatuses } = this.state;
    chargeBackStatusesForEntity[index].isChecked = isInputChecked;

    staticSearchChargeBackStatuses.forEach((val) => {
      if (String(val.displayName) === String(chargeBackStatusesForEntity[index].displayName)) {
        val.isChecked = isInputChecked;
      }
    });
    const values = staticSearchChargeBackStatuses.filter((val) => val.isChecked);
    this.setState({ staticSearchChargeBackStatuses, chargeBackStatusesForEntity });
    onFilterChange(values, 'chargeBackStatus');
  };

  private handleInputChange = ({ target: { value } }: any) => {
    const { staticSearchChargeBackStatuses } = this.state;

    const lowerCaseVal = value.toLowerCase();
    const updatedChargeBackStatuses = staticSearchChargeBackStatuses.filter(({ displayName }) =>
      displayName.toLowerCase().includes(lowerCaseVal)
    );

    this.setState({ searchText: value, chargeBackStatusesForEntity: updatedChargeBackStatuses });
  };

  public rowRenderer = ({ index, style }: any) => {
    const { chargeBackStatusesForEntity } = this.state;
    return (
      <ListFilterRow
        index={index}
        key={index}
        style={style}
        isChecked={chargeBackStatusesForEntity[index].isChecked}
        value={chargeBackStatusesForEntity[index].name}
        onCheck={this.handleCheck}
      />
    );
  };

  public render() {
    const { searchText, chargeBackStatusesForEntity, staticSearchChargeBackStatuses } = this.state;

    if (staticSearchChargeBackStatuses && _isEmpty(staticSearchChargeBackStatuses)) {
      return null;
    }

    return (
      <div className="search-form-container search-form-container--sm">
        <TextField
          variant="standard"
          autoComplete="off"
          className="sl-form-input"
          placeholder="Search for Chargebacks By Status"
          type="text"
          name="keyword"
          style={{ width: '100%' }}
          value={searchText}
          onChange={this.handleInputChange}
        />
        {chargeBackStatusesForEntity && _isEmpty(chargeBackStatusesForEntity) ? <NoChargeBackStatusesFound /> : null}
        <AutoSizer disableHeight>
          {({ width }) => (
            <VirtualizedList
              width={width}
              height={300}
              rowCount={chargeBackStatusesForEntity.length}
              rowHeight={45}
              style={{ marginBottom: 10 }}
              rowRenderer={this.rowRenderer}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['app', 'chargeBackStatuses', 'comparisonTimePeriod', 'filters', 'mainTimePeriod', 'retailer']);

export default withRouter(connect(mapStateToProps)(ChargeBackStatusesFilter));
