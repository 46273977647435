import { useStacklineTheme } from '@stackline/ui';
import React, { useState } from 'react';
import ElementCard from 'src/components/BeaconRedesignComponents/ElementCard/ElementCard';
import { getBrandImageUrl } from 'src/utils/image';
import _isNil from 'lodash/isNil';

export const BrandLogo = ({
  brandId,
  displayName,
  width,
  height,
  elementCardVariant
}: {
  brandId: number | string;
  width?: number | string;
  height?: number;
  displayName: string;
  elementCardVariant?: React.ComponentProps<typeof ElementCard>['variant'];
}) => {
  const theme = useStacklineTheme();

  const [error, setError] = useState(false);

  if (error) {
    return (
      <ElementCard color="primary" variant={elementCardVariant}>
        {`${(displayName[0] || '').toUpperCase()}${(displayName[1] || '').toLowerCase()}`}
      </ElementCard>
    );
  }

  return (
    <img
      style={{
        width: _isNil(width) ? theme.spacing.xl : width,
        height: _isNil(height) ? theme.spacing.xl : height,
        objectFit: 'contain'
      }}
      alt={displayName}
      src={getBrandImageUrl(String(brandId))}
      onError={() => setError(true)}
    />
  );
};
