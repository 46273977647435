/**
 * This file handles the parsing of the GETALLSAVEDSEARCH network call
 */

import _flatten from 'lodash/flatten';
import { Option } from 'funfix-core';
import { Conditions, TermFilter } from 'sl-api-connector/types';

import { mapQueryToConditions } from 'src/utils/segments';
import { propEq } from 'src/utils/fp';
import ReduxStore from 'src/types/store/reduxStore';

const sortByDisplayName = (a: { displayName: string }, b: { displayName: string }) =>
  a.displayName.localeCompare(b.displayName);

export default function parseSavedSearches(
  savedSearches: any[],
  categories: { categoryId: number | string }[],
  retailer: ReduxStore['retailer']
) {
  const mySegments: any[] = [];
  const teamSegments: any[] = [];
  const mySearchTermLists: any[] = [];
  const teamSearchTermLists: any[] = [];
  const businessUnits: any[] = [];

  savedSearches
    .filter((savedSearch) => savedSearch.isActive !== false)
    .forEach((savedSearch) => {
      const parsedValue = {
        ...JSON.parse(savedSearch.query),
        canEdit: savedSearch.canEdit,
        owner: savedSearch.ownerProfile,
        entityType: savedSearch.entityType
      };

      const savedSearchConditions = mapQueryToConditions(parsedValue, retailer) as Conditions;
      const categoryTermFilters: TermFilter[] = !(savedSearchConditions.termFilters || []).find(
        propEq('fieldName', 'categoryId')
      )
        ? [
            {
              fieldName: 'categoryId',
              condition: 'should',
              values: categories.map((val) => val.categoryId)
            }
          ]
        : [];

      const rangeFilters = (savedSearchConditions.rangeFilters || []).filter((val) => val.fieldName !== 'weekId');
      const savedSearchConfig = {
        id: parsedValue.id,
        type: parsedValue.entityType,
        entityType: parsedValue.entityType,
        displayName: parsedValue.dn,
        conditions: {
          rangeFilters,
          termFilters: [...(savedSearchConditions.termFilters || []), ...categoryTermFilters]
        },
        segment: parsedValue,
        lastUpdatedTime: savedSearch.updatedTime,
        lastUpdatedBy: savedSearch.updatedBy,
        userId: savedSearch.userId
      };

      if (savedSearch.appName === 'advertising' && savedSearchConditions.computeFilters) {
        savedSearchConfig.conditions.computeFilters = [...savedSearchConditions.computeFilters];
      }

      if (parsedValue.entityType === 'searchtermlist') {
        if (savedSearch.canEdit) {
          mySearchTermLists.push(savedSearchConfig);
        } else {
          teamSearchTermLists.push(savedSearchConfig);
        }
      } else if (savedSearch.entityType === 'businessunit') {
        // Business units are "folders" of other saved searches.  Their children contain other business units or segments.
        delete savedSearchConfig.segment;
        const { children, isTopLevel = false, canEdit } = parsedValue;

        businessUnits.push({
          ...savedSearchConfig,
          children,
          isTopLevel,
          canEdit,
          lastUpdatedTime: Option.of(savedSearch.updatedTime)
            .map((ts) => new Date(ts))
            .orNull(),
          lastUpdatedBy: savedSearch.updatedBy,
          owner: savedSearch.ownerProfile
        });
      } else if (savedSearch.canEdit) {
        mySegments.push(savedSearchConfig);
      } else {
        teamSegments.push(savedSearchConfig);
      }
    });

  [mySegments, teamSegments, mySearchTermLists, teamSearchTermLists, businessUnits].forEach((searches) =>
    searches.sort(sortByDisplayName)
  );

  return {
    mySegments,
    teamSegments,
    mySearchTermLists,
    teamSearchTermLists,
    businessUnits
  };
}

/**
 * Returns the list of segment IDs for segments that aren't assigned to any business unit
 */
export const getUnassignedSegments = (segments: ReduxStore['segments']) => {
  const allSegmentIds = new Set<string>();
  const savedSearchesWithParents = new Set<string>();

  [...segments.mySegments, ...segments.teamSegments].forEach((segment) => allSegmentIds.add(segment.id));
  _flatten(segments.businessUnits.map((bu) => bu.children)).forEach((childId) => savedSearchesWithParents.add(childId));

  return Array.from(allSegmentIds).filter((segmentId) => !savedSearchesWithParents.has(segmentId));
};

export const getUnassignedBusinessUnits = (segments: ReduxStore['segments']) => {
  const allBusinessUnits = new Set<string>();
  const businessUnitsWithParents = new Set<string>();
  segments.businessUnits.forEach((bu) => {
    allBusinessUnits.add(bu.id);
    bu.children.forEach((childBUId) => businessUnitsWithParents.add(childBUId));
    // Top-level BUs are also unassignable as children
    if (bu.isTopLevel) {
      businessUnitsWithParents.add(bu.id);
    }
  });

  return Array.from(allBusinessUnits).filter((buId) => !businessUnitsWithParents.has(buId));
};
