import React, { useState } from 'react';
import { BrandIcon } from 'src/components/SvgIcons';
import discoverStyles from 'src/components/Discover/DiscoverUtils/StyleUtils';

const DiscoverBrandColumn = (rowProps: any) => {
  const styles = { ...discoverStyles };
  const [imageAvailable, setImageAvailable] = useState(true);
  const handleImageLoadingError = (e: any) => {
    e.target.onError = null;
    setImageAvailable(false);
  };
  return (
    <div style={{ display: 'flex' }}>
      <div style={styles.imageWrapper}>
        <div style={styles.imageWrapperInner}>
          <div style={styles.imageWrapperInnerInner}>
            {imageAvailable ? (
              <img
                onError={(e) => handleImageLoadingError(e)}
                alt=""
                style={styles.image}
                src={`https://s3-us-west-2.amazonaws.com/stackline-product-images/${rowProps.row.data.brandId}.jpg`}
              />
            ) : (
              <BrandIcon
                style={{
                  stroke: 'rgb(207, 215, 223)',
                  strokeWidth: 13,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div style={{ fontSize: 14 }} title={rowProps.row.data.brandName}>
        {rowProps.row.data.brandName}
      </div>
    </div>
  );
};

export default DiscoverBrandColumn;
