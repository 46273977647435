import React from 'react';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';

const aggregationBuilder = (aggBuilder: AggregationBuilder): AggregationBuilder => {
  return aggBuilder.clearConditionRangeFilters().addConditionRangeFilter('organicClicks', 1);
};
export default function KeywordsWithOrganicTrafficSplineChart() {
  return (
    <BeaconComparableSplineChart
      title="Keywords with Organic Traffic"
      indexName="traffic"
      fieldName="searchTerm"
      primaryAggregationBuilder={aggregationBuilder}
      secondaryAggregationBuilder={aggregationBuilder}
    />
  );
}
