import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import Waypoint from 'react-waypoint';
import OmniTile from 'src/components/SummaryTile/OmniTile';
import { PERIODS } from 'src/store/modules/omni/constants';
import { Widget as WidgetType } from 'src/types/application/widgetTypes';
import { omniChartsServiceOperations } from 'src/store/modules/omni/omniChartService';
import { StackedColumnChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { OmniBaseRequestBody, addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';
import _intersection from 'lodash/intersection';
import { startFetchChartData } from 'src/store/modules/omni/omniChartService/actions';
import { debounce } from 'lodash';

const { fetchOmniChartsServiceData } = omniChartsServiceOperations;

const PAGESIZE = 8;

interface OmniTileListProps extends RouteComponentProps {
  widget: WidgetType;
}

interface OmniTileListRequestBody extends OmniBaseRequestBody {
  groupBy: string;
  fulfillmentType?: string;
}

const fillTheData = (omniTileEntityList: any[], dataForEachTile: any, totalDataForEachTile: any, groupBy: string) => {
  return omniTileEntityList.map((entityListData) => {
    const findValueObj = dataForEachTile.main.find((ele) => String(ele[groupBy]) === String(entityListData.id));
    const findTotalValueObj = totalDataForEachTile.main.find(
      (ele) => String(ele[groupBy]) === String(entityListData.id)
    );
    const value = findValueObj ? findValueObj.value : 0;
    const totalValue = findTotalValueObj ? findTotalValueObj.value : 0;
    const percent = totalValue === 0 ? 0 : value / totalValue;
    const percentValue = Number(percent * 100).toFixed(2);
    return {
      ...entityListData,
      value,
      totalValue,
      percentValue
    };
  });
};

const getFilteredTileEntity = (filters, selectedEntityName, omniTileEntityList) => {
  const filteredData = filters[selectedEntityName];
  if (!filteredData) {
    return omniTileEntityList;
  }
  const set = new Set(filteredData);
  return omniTileEntityList.filter((e) => set.has(Number(e.id)) || set.has(String(e.id)));
};

const OmniTileList: React.FC<OmniTileListProps> = ({ widget }: OmniTileListProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { name, data, view } = widget;
  const { groupBy } = data;
  const { selectedEntityName } = view;
  const nameForTotal = `${name}Total`;

  const dispatch = useDispatch();
  const filters = useSelector((state: ReduxStore) => state.filters);
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const totalDataForEachTile = useSelector((state: ReduxStore) => state.omniChartsService[nameForTotal]);
  const dataForEachTile = useSelector((state: ReduxStore) => state.omniChartsService[name]);
  const omniCountriesFollowing = useSelector((state: ReduxStore) => state.omniCountriesFollowing);
  const omniBrandsFollowing = useSelector((state: ReduxStore) => state.omniBrandsFollowing);
  const omniCategories = useSelector((state: ReduxStore) => state.omniCategories);
  const omniSubcategories = useSelector((state: ReduxStore) => state.omniSubcategories);
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const omniRetailersState = useSelector((state: ReduxStore) => state.omniRetailers);
  const omniRetailers = omniRetailersState.data;

  let omniEntityListData = [];

  switch (selectedEntityName) {
    case 'brand': {
      omniEntityListData = omniBrandsFollowing.map((element) => ({
        name: element.brandName,
        id: element.brandId,
        selectedEntityName
      }));
      break;
    }

    case 'country': {
      omniEntityListData = omniCountriesFollowing.map((element) => ({
        name: element.countryName,
        id: element.countryCode,
        selectedEntityName
      }));
      break;
    }

    case 'category': {
      omniEntityListData = omniCategories.map((element) => ({
        name: element.categoryName,
        id: element.categoryId,
        selectedEntityName
      }));
      break;
    }

    case 'subcategory': {
      omniEntityListData = omniSubcategories.map((element) => ({
        name: element.subCategoryName,
        id: element.subCategoryId,
        selectedEntityName
      }));
      break;
    }

    case 'retailer':
      {
        let retailerIds = omniRetailers.map((ele) => String(ele.retailerId));
        const { availableRetailers } = retailer;
        if (retailerIds && availableRetailers) {
          retailerIds = retailerIds.map((id) => `${id}`);
          const availableIds = availableRetailers.map((ele) => ele.id);
          const supportRetailerId = _intersection(availableIds, retailerIds);
          omniEntityListData = supportRetailerId.map((id) => {
            const matchingRetailer = availableRetailers.find((element) => element.id === id);
            return {
              id,
              name: matchingRetailer.displayName,
              selectedEntityName,
              orderTypes: matchingRetailer.orderType
            };
          });
        }
      }
      break;
    default:
  }

  const { startWeek, endWeek } = mainTimePeriod;

  const fetchData = useCallback(() => {
    if (!groupBy) {
      return;
    }

    const baseRequestBody: OmniTileListRequestBody = {
      retailerIds: [],
      includeBrandIds: [],
      includeCategoryIds: [],
      includeLocationCountryCode: [],
      includeLocationRegionCode: [],
      productIds: [],
      includeSubCategoryIds: [],
      startWeekId: startWeek,
      endWeekId: endWeek,
      groupBy
    };

    const requestBody = addFilterToOmniBaseReqBody(baseRequestBody, filters, '');

    dispatch(
      startFetchChartData({
        chartName: nameForTotal,
        data: []
      })
    );
    dispatch(
      startFetchChartData({
        chartName: name,
        data: []
      })
    );

    debounce(async () => {
      await dispatch(
        fetchOmniChartsServiceData(requestBody, PERIODS.MAIN, nameForTotal, 'availabilityTotalStoreCount', {
          compareStartWeek: startWeek,
          compareEndWeek: endWeek
        })
      );

      await dispatch(
        fetchOmniChartsServiceData(requestBody, PERIODS.MAIN, name, 'storesWithAvailability', {
          compareStartWeek: startWeek,
          compareEndWeek: endWeek
        })
      );
    })();
  }, [dispatch, startWeek, endWeek, nameForTotal, name, groupBy, filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!dataForEachTile || !totalDataForEachTile || dataForEachTile.isFetching || totalDataForEachTile.isFetching) {
    return (
      <>
        <StackedColumnChartLoading />
        <StackedColumnChartLoading />
        <StackedColumnChartLoading />
        <StackedColumnChartLoading />
      </>
    );
  }
  let omniTileEntityList = [];
  omniTileEntityList = omniEntityListData;
  omniTileEntityList = getFilteredTileEntity(filters, selectedEntityName, omniTileEntityList);
  if (dataForEachTile && totalDataForEachTile && dataForEachTile.main && totalDataForEachTile.main) {
    omniTileEntityList = fillTheData(omniTileEntityList, dataForEachTile, totalDataForEachTile, groupBy);
  }

  const loadNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const dataToDisplay =
    omniTileEntityList
      .sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
      })
      .slice(0, currentPage * PAGESIZE) || [];
  // const dataToDisplay = omniTileEntityList.sort((a, b) => Number(a.id) - Number(b.id));
  return (
    <div>
      {dataToDisplay.map((item, idx) => {
        return <OmniTile omniTileEntity={item} key={idx} widget={widget} />;
      })}
      {omniTileEntityList.length > PAGESIZE ? <Waypoint onEnter={loadNextPage} /> : null}

      <br />
      <br />
    </div>
  );
};
export default withRouter(OmniTileList);
