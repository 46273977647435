import React, { useState, useMemo, Dispatch, SetStateAction } from 'react';
import SlCalendarPicker, {
  CalendarDate
} from 'src/components/BeaconRedesignComponents/common/SlCalendarPicker/SlCalendarPicker';
import { useHistory } from 'src/utils/Hooks';
import moment from 'moment-timezone';
import { getStartAndEndOfWeek, getWeekId } from 'src/utils/dateUtils';
import { updateUrlQueryParams } from 'src/utils/app';
import {
  useForecastPeriod,
  useForecastStartAndEndWeekIds
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import {
  ForecastComparisonPeriod,
  ForecastPeriod
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import { useUpdateComparisonTimePeriod } from 'src/utils/Hooks/useUpdateComparisonTimePeriod';

/**
 * Wrapper containing logic for SlCalendarPicker for custom application time range filters
 */
const ForecastCalendarContainer = ({
  open,
  setCalendarOpen,
  setSelectedId
}: {
  open: boolean;
  setCalendarOpen: (b: boolean) => void;
  setSelectedId: Dispatch<SetStateAction<string>>;
}) => {
  const history = useHistory();
  const updateComparisonTimePeriod = useUpdateComparisonTimePeriod();
  const forecastPeriod = useForecastPeriod();

  // The calendar should always have a preselected start date based on the forecast start week we're using.
  const { startWeekId: forecastStartWeekId, endWeekId: forecastEndWeekId } = useForecastStartAndEndWeekIds();

  const prefilledDates = useMemo(() => {
    if (!(ForecastPeriod.FULL_YEAR === forecastPeriod)) {
      const { startDate: preSelectedStartDateString } = getStartAndEndOfWeek(forecastStartWeekId);
      const { endDate: preSelectedEndDateString } = getStartAndEndOfWeek(forecastEndWeekId);
      return [moment(preSelectedStartDateString).toDate(), moment(preSelectedEndDateString).toDate()];
    }
    return [];
  }, [forecastStartWeekId, forecastEndWeekId, forecastPeriod]);

  /**
   * Selected date range state
   */
  const [selectedDateRange, setSelectedDateRange] = useState([prefilledDates[0], prefilledDates[1]]);

  /**
   * Used to determine which dates in the calendar should be disabled. In this case, we disable all dates before the forecast start week.
   * @returns true if we should disable the date in the calendar, false if it should be available
   */
  const shouldDisableDate = (calendar: CalendarDate) => {
    const { date: calendarDate } = calendar;

    if (moment(calendarDate).isBefore(moment().startOf('week'))) {
      return true;
    }
    return false;
  };

  /**
   * Handles date selections from the calendar picker.
   */
  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [selectedStartDate, selectedEndDate] = dates;
    setSelectedDateRange([selectedStartDate, selectedEndDate]);
  };

  /**
   * On calendar close, we update the URL with the new date range and update Redux with the default comparison period.
   */
  const triggerCustomDateRangeChange = ({ startDate, endDate }) => {
    const startWeekId = getWeekId(startDate);
    const endWeekId = getWeekId(endDate);
    const newParams = updateUrlQueryParams({
      forecastPeriod: `${startWeekId}-${endWeekId}`
    });
    history.push(newParams);
    // Only allow prior year for custom date ranges
    updateComparisonTimePeriod(ForecastComparisonPeriod.PRIOR_YEAR);
  };

  /**
   * Trigger the custom date range change only if we have 2 valid dates
   */
  const handleCalendarClose = () => {
    setCalendarOpen(false);
    const [selectedStartDate, selectedEndDate] = selectedDateRange;
    const selectedStartWeekId = getWeekId(selectedStartDate);
    const selectedEndWeekId = getWeekId(selectedEndDate);

    if (
      selectedStartDate &&
      selectedEndDate &&
      (selectedStartWeekId !== forecastStartWeekId || selectedEndWeekId !== forecastEndWeekId)
    ) {
      triggerCustomDateRangeChange({ startDate: selectedStartDate, endDate: selectedEndDate });
      // We only update the dropdown menu selection if we successfully set a custom date range
      setSelectedId(ForecastPeriod.CUSTOM);
    }
  };

  return (
    <SlCalendarPicker
      open={open}
      onClose={handleCalendarClose}
      selectedDateRange={selectedDateRange}
      handleDateChange={handleDateChange}
      shouldDisableDate={shouldDisableDate}
    />
  );
};

export default ForecastCalendarContainer;
