/**
 * A collection of cell renderer frameworks for the custom entity grids that are used by the waterfall page insights
 */

import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import Chip from '@mui/material/Chip';
import Grow from '@mui/material/Grow';
import { withBus } from 'react-bus';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _isArray from 'lodash/isArray';
import _isString from 'lodash/isString';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { store } from 'src/main';
import colors from 'src/utils/colors';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { cellRendererFrameworkPropTypes } from 'src/components/EntityGrid/HeaderComponentFrameworks';
import MultiLineHCF, { LineBreakHeaderContent } from 'src/components/EntityGrid/HeaderComponentFrameworks/MultiLineHCF';
import { percentColor, formatPercent, computePercentChange } from 'src/utils/metrics';
import EntityColumn from 'src/components/Grids/Data/ColumnTypes/Entity';
import { ChevronIcon } from 'src/components/SvgIcons';
import { truncateWithEllipsis } from 'src/utils/stringFormatting';
import CustomAgGridHeaderTemplate from 'src/components/EntityGrid/Table/CustomAgGridHeaderTemplate';
import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import ActiveMetricSwitcher from './ActiveMetricSwitcher';
import { PopoverBackdrop } from 'src/components/Navigation/AccountMenuPopover';
import 'src/components/Navigation/AccountMenuPopover/AccountMenuPopover.scss';
import 'src/components/EntityPage/WaterfallChart/Insights/ActionCell.scss';
import { AppName } from 'sl-api-connector/types';
import { shouldShowCriteo } from 'src/utils/app';

const headerComponentFrameworkPropTypes = {
  displayName: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired
  ]).isRequired,
  column: PropTypes.shape({ colDef: PropTypes.object.isRequired }).isRequired
};

const styles = {
  chip: {
    left: 0,
    padding: '0 10px',
    marginRight: 0,
    maxHeight: 27
  },
  featuredProduct: {
    outline: 'none',
    cursor: 'pointer'
  },
  activeMetricSwitchHCFRoot: {
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  }
};

const PercentChangeCellInner = ({ value, periodChange, percentChange, retailer, formatPeriodChange }) => {
  const displayPercentChange = convertMetricToDisplayValue(
    retailer,
    percentChange,
    METRICTYPE.PERCENT,
    retailer.currencySymbol,
    true
  );

  const percentChangeFormatted = formatPercent(displayPercentChange);

  return (
    <Fragment>
      <span title={value}>{formatPeriodChange(periodChange)}</span>
      <br /> <span style={{ color: percentColor(percentChangeFormatted) }}>{percentChangeFormatted}</span>
    </Fragment>
  );
};

PercentChangeCellInner.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  periodChange: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  percentChange: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  displayPercentChange: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  formatPeriodChange: PropTypes.func,
  // Redux props
  retailer: PropTypes.object.isRequired
};

PercentChangeCellInner.defaultProps = {
  value: 0,
  periodChange: 0,
  percentChange: 0,
  displayPercentChange: 0,
  formatPeriodChange: (periodChange) => numeral(periodChange).format('1,000.0a')
};

const PercentChangeCell = connect(({ retailer }) => ({ retailer }))(PercentChangeCellInner);

export const mkPercentChangeCRF = (periodChangeKey, percentChangeKey, formatPeriodChange) => {
  const PercentChangeCRF = ({ data }) => {
    const periodChange = _get(data, periodChangeKey);
    const percentChange = _get(data, percentChangeKey);

    return (
      <PercentChangeCell
        value={undefined}
        periodChange={periodChange}
        formatPeriodChange={formatPeriodChange}
        percentChange={percentChange}
      />
    );
  };
  PercentChangeCRF.propTypes = cellRendererFrameworkPropTypes;

  return PercentChangeCRF;
};

export const WaterfallValueCellFormatter = ({ data, colDef: { field } }) => {
  return <span title={data[field]}>{numeral(data[field]).format('√')}</span>;
};

WaterfallValueCellFormatter.propTypes = cellRendererFrameworkPropTypes;

const openURL = (url) => window.open(url, '_blank');

export const openInReview = (data) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('tab', 'reviews');
  searchParams.set('subtab', 'reviewTrends');
  searchParams.set('searchKeyword', data.name || '');
  const newParams = searchParams.toString();
  const url = `${window.location.pathname}?${newParams}`;
  openURL(url);
};

export const viewKeywordInApp = (app, data) => {
  const baseURL = `https://${app.name}${app.stage !== 'prod' ? `-${app.stage}` : ''}.stackline.com/search`;
  const searchParams = new URLSearchParams(window.location.search);
  const newParams = new URLSearchParams();
  newParams.set('rid', searchParams.get('rid'));
  if (shouldShowCriteo()) {
    newParams.set('pp', searchParams.get('pp'));
  }
  newParams.set('sw', searchParams.get('sw'));
  newParams.set('ew', searchParams.get('ew'));
  newParams.set('wr', searchParams.get('wr'));
  newParams.set('doAggregation', 'true');
  newParams.set('entityType', 'searchtermlist');
  newParams.set('dn', '');
  newParams.set('stf[0][i]', data.name);
  const url = `${baseURL}?${newParams.toString()}`;
  openURL(url);
};

const ActionCellChildrenRenderer = ({ open, onClose, actionChildChips, data }) => (
  <>
    {actionChildChips.map(({ onClick, label }, i) => (
      <Grow key={label} in={open} {...(open ? { timeout: 150 * (i + 1) } : {})}>
        <Chip
          className="action-cell-chip-child"
          style={{ ...styles.chip, top: 50 + 33 * i }}
          label={label}
          onClick={() => {
            onClose();
            onClick(data);
          }}
        />
      </Grow>
    ))}
  </>
);

ActionCellChildrenRenderer.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  actionChildChips: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
};

ActionCellChildrenRenderer.defaultProps = {
  data: null
};

/**
 * Creates a CRF that renders a list of dropdown chips when the top chip is clicked.
 *
 * @param {{}[]} actionChildChips An array of action cell chip definitions that will be rendered as the items in the
 *  drop down item list.
 * @param {React.CSSProperties | undefined} [style]
 */
export const mkActionCellFormatter = (
  actionChildChips,
  style,
  customLabel,
  isOrganicsColumn = false,
  useChipActive = false
) => {
  const ActionCellFormatterForOrganicsGrid = ({ data }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isActive, setIsActive] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setIsActive(!isActive);
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setIsActive(false);
    };

    return (
      <div>
        <Chip
          label={customLabel || '＋ Action'}
          clickable
          onClick={handleClick}
          style={styles.chip}
          className={`search-chip action-chip ${isActive || useChipActive ? 'chip-active' : ''}`}
        />

        <Menu
          className="action-cell-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            class: 'action-cell-list'
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {actionChildChips.map(({ onClick, label }, i) => (
            <MenuItem
              className="action-cell-menu-item MuiPaper-elevation1"
              key={i}
              onClick={() => {
                setAnchorEl(null);
                setIsActive(!isActive);
                onClick(data);
              }}
              value={label}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  const ActionCellFormatter = ({ data }) => {
    const [isActive, setIsActive] = useState(false);

    return (
      <div className="grid-chips-group" style={style}>
        <Chip
          label={customLabel || '＋ Action'}
          clickable
          onClick={() => setIsActive(!isActive)}
          onBlur={() => setTimeout(() => setIsActive(false), 300)}
          style={styles.chip}
          className={`search-chip action-chip ${isActive || useChipActive ? 'chip-active' : ''}`}
        />

        {isActive ? (
          <ActionCellChildrenRenderer
            actionChildChips={actionChildChips}
            open={isActive}
            onClose={() => setIsActive(false)}
            data={data}
          />
        ) : null}
      </div>
    );
  };
  ActionCellFormatter.propTypes = cellRendererFrameworkPropTypes;
  ActionCellFormatterForOrganicsGrid.propTypes = cellRendererFrameworkPropTypes;
  return isOrganicsColumn ? ActionCellFormatterForOrganicsGrid : ActionCellFormatter;
};

export const mkChipButtonCellFormatter = (eventName, label) => {
  const ActionCellFormatter = withBus('eventBus')(({ eventBus, data }) => {
    return (
      <div className="grid-chips-group">
        <Chip
          label={label}
          clickable
          onClick={() => {
            eventBus.emit(eventName, data);
          }}
          style={{ backgroundColor: colors.lightestGrey, ...styles.chip }}
          className="action-chip"
        />
      </div>
    );
  });
  ActionCellFormatter.propTypes = cellRendererFrameworkPropTypes;

  return ActionCellFormatter;
};

const getDiffColor = (diff) => {
  if (diff === 0) {
    return colors.stacklineBlue;
  } else if (diff > 0) {
    return colors.green;
  } else {
    return colors.red;
  }
};

const ValueChangeCell = withBus('eventBus')(({ eventBus, curValue, diff, selectedEntity, data }) => (
  <span
    className="value-change-cell"
    style={styles.featuredProduct}
    role="button"
    onClick={() => eventBus.emit('showFeaturedEntities', { selectedEntity, metricCurrentValue: curValue, data })}
  >
    <span style={{ color: colors.stacklineBlue }}>{curValue}</span>
    <span style={{ color: getDiffColor(diff) }}>{` (${diff >= 0 ? '+' : ''}${diff})`}</span>
  </span>
));

const getEntityId = (data) => {
  if (data.type === 'product') {
    return data.id;
  } else if (_get(data, ['entity', 'type']) === 'adPortfolio' && store.getState().app.name === AppName.Advertising) {
    // Work around weird data schema that comes when querying daily data indices on ad manager
    return data.entity.id;
  } else {
    return data.name;
  }
};

export const mkValueChangeCRF = (valueKey, valueChangeKey) => {
  const ValueChangeCRF = ({ data }) => {
    const curValue = data[valueKey];
    const diff = data[valueChangeKey];

    return <ValueChangeCell curValue={curValue} diff={diff} selectedEntity={getEntityId(data)} data={data} />;
  };
  ValueChangeCRF.propTypes = cellRendererFrameworkPropTypes;
  return ValueChangeCRF;
};

export const mkValueChangeGetterCRF = (getValueKey, getValueChangeKey) => {
  const ValueChangeGetterCRF = ({ data }) => {
    const curValue = getValueKey(data);
    const diff = getValueChangeKey(data);

    return <ValueChangeCell curValue={curValue} diff={diff} selectedEntity={getEntityId(data)} data={data} />;
  };
  ValueChangeGetterCRF.propTypes = cellRendererFrameworkPropTypes;
  return ValueChangeGetterCRF;
};

const WaterfallPercentChangeCellFormatter = ({ data, colDef: { field }, groupByFieldName, comparisonValue }) => {
  const mainValue = data[field];

  let periodChange;
  let percentChange;
  if (!_isNil(comparisonValue)) {
    ({ periodChange, percentChange } = computePercentChange(comparisonValue, mainValue));
  } else {
    // `rawData` contains the `entitySearchService` entry for the current grid.
    const rawDatum = _get(data, `rawData[${field}_by_${groupByFieldName}].data[${data.srcIx}]`);
    if (!rawDatum) {
      return null;
    }

    ({ [`${field}PeriodChange`]: periodChange, [`${field}PercentChange`]: percentChange } = rawDatum.cardView);
  }

  return <PercentChangeCell value={mainValue} periodChange={periodChange} percentChange={percentChange} />;
};

WaterfallPercentChangeCellFormatter.propTypes = {
  ...cellRendererFrameworkPropTypes,
  groupByFieldName: PropTypes.string,
  comparisonValue: PropTypes.number
};

WaterfallPercentChangeCellFormatter.defaultProps = {
  groupByFieldName: 'searchTerm',
  comparisonValue: undefined
};

export const FeaturedProductsGridProductCellRenderer = ({ data, ...props }) => {
  const truncatedValue = truncateWithEllipsis(60, data.name);

  return <EntityColumn data={{ ...data, name: truncatedValue }} style={{ paddingLeft: 20 }} {...props} />;
};

FeaturedProductsGridProductCellRenderer.propTypes = cellRendererFrameworkPropTypes;

/**
 * AG Grid column header that doesn't allow sorting and inserts a newline between the first word and the rest of the
 * words of the header's content.
 *
 * @param {object} props
 */
export const WaterfallNonSortableHeaderComponentFramework = ({ displayName, ...props }) => {
  return (
    <span style={{ padding: '0 20px 0 0', display: 'inline-block', ...props.style }}>
      <LineBreakHeaderContent headerContent={displayName} />
    </span>
  );
};

WaterfallNonSortableHeaderComponentFramework.propTypes = {
  style: PropTypes.object,
  displayName: PropTypes.string.isRequired
};

WaterfallNonSortableHeaderComponentFramework.defaultProps = {
  style: {}
};

export const mkManualSortHCF = (sortEventName, InnerHCF = CustomAgGridHeaderTemplate) => {
  const ManualSortHCF = withBus('eventBus')(({ eventBus, column: { colDef, ...column }, displayName, ...props }) => (
    <InnerHCF
      {...props}
      displayName={<LineBreakHeaderContent headerContent={displayName} />}
      column={{
        ...column,
        colDef: {
          ...colDef,
          field: colDef.field,
          onSortFunction: () => {
            eventBus.emit(sortEventName, { fieldName: colDef.field });
          }
        }
      }}
      sortArrowStyle={{
        marginTop:
          (_isString(displayName) && !displayName.includes(' ')) || (_isArray(displayName) && displayName.length === 1)
            ? '1em'
            : undefined
      }}
    />
  ));

  ManualSortHCF.propTypes = { ...headerComponentFrameworkPropTypes, sortKeyOverride: PropTypes.string };
  ManualSortHCF.defaultProps = { sortKeyOverride: undefined };

  return ManualSortHCF;
};

/**
 * Creates a HCF that can be used to switch the metric displayed in a column.
 *
 * @param {object} activeMetricSwitcherProps A set of props that will be passed into the inner `ActiveMetricSwitcher`.
 * @param {func} InnerHCF
 */
export const mkActiveMetricSwitcherHCF = (
  activeMetricSwitcherProps,
  InnerHCF = mkManualSortHCF('waterfallInsightsSortData')
) => {
  const ActiveMetricSwitcherHCF = ({ ...props }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <Fragment>
        {isOpen ? <PopoverBackdrop onClick={() => setIsOpen(false)} /> : null}
        <span role="button" style={styles.activeMetricSwitchHCFRoot}>
          <InnerHCF
            {...props}
            column={
              props.currentFieldName
                ? {
                    ...props.column,
                    colDef: {
                      ...props.column.colDef,
                      field: props.currentFieldName
                    }
                  }
                : props.column
            }
            displayName={props.currentDisplayName || props.displayName}
          />
          <ChevronIcon
            className={`custom-sort-icon custom-sort-icon--${isOpen ? 'asc' : 'desc'}`}
            alt="Dropdown open arrow"
            onClick={() => setIsOpen(!isOpen)}
            style={{
              cursor: 'pointer',
              marginLeft: -20,
              marginTop: !props.displayName.includes(' ') ? '1em' : undefined
            }}
          />
          {isOpen ? (
            <ActiveMetricSwitcher
              currentFieldName={props.currentFieldName || props.column.colDef.field}
              {...activeMetricSwitcherProps}
            />
          ) : null}
        </span>
      </Fragment>
    );
  };

  ActiveMetricSwitcherHCF.propTypes = { ...headerComponentFrameworkPropTypes, currentFieldName: PropTypes.string };
  ActiveMetricSwitcherHCF.defaultProps = { currentFieldName: null };

  return ActiveMetricSwitcherHCF;
};

export const WaterfallDefaultHeaderComponentFramework = MultiLineHCF;

WaterfallDefaultHeaderComponentFramework.propTypes = {
  displayName: PropTypes.string.isRequired
};

/**
 * Header Component Framework that dislays the header left-aligned
 */
export const WaterfallFirstColumnHCF = ({ displayName }) => (
  <span style={{ marginTop: '1em', width: '100%', textAlign: 'left', display: 'inline-block' }}>{displayName}</span>
);

WaterfallFirstColumnHCF.propTypes = headerComponentFrameworkPropTypes;

/**
 * This CRF splits `displayName` at all pipes (`|`) and renders each piece on a new line.
 */
export const withManualBrHeader = (InnerCRF) => {
  const ManualBRHeaderCRF = ({ displayName, ...props }) => {
    const formattedDisplayName = displayName.includes('|')
      ? displayName
          .replaceAll('|', '\n')
          .split('\n')
          .map((s) => (
            <Fragment key={s}>
              {s}
              <br />
            </Fragment>
          ))
      : [
          <span key={displayName} style={{ display: 'inline-block', marginTop: '1em' }}>
            {displayName}
          </span>
        ];

    return <InnerCRF displayName={formattedDisplayName} {...props} />;
  };
  ManualBRHeaderCRF.propTypes = headerComponentFrameworkPropTypes;

  return ManualBRHeaderCRF;
};
