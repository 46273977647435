import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import ReduxStore from 'src/types/store/reduxStore';
import { AppName } from 'sl-api-connector';
import { ViewRowIcon, ViewTileIcon, NewBeaconExportIcon } from 'src/components/SvgIcons';
import { shouldShowNewBeacon } from 'src/utils/app';
import { useStacklineTheme } from '@stackline/ui';
import {
  BuildExportRequestFunction,
  IconsList
} from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';
import AppExportModal from 'src/components/BeaconRedesignComponents/common/AppExportModal/AppExportModal';
import { Box } from '@mui/system';

interface Props {
  handleChangeLayout: (b: boolean) => void;
  defaultView: string;
  selectedGroupByField?: {
    name: string;
    displayName: string;
    isSelected: boolean;
  };
  iconsList: IconsList[];
  buildExportRequest?: BuildExportRequestFunction;
}

export const defaultHeaderIconSpanStyle = { width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' };

const EntityIconButtonGroup: React.FC<Props> = ({
  handleChangeLayout,
  defaultView,
  selectedGroupByField,
  iconsList,
  buildExportRequest
}) => {
  const app = useSelector((state: ReduxStore) => state.app);
  const theme = useStacklineTheme();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  if (shouldShowNewBeacon()) {
    const shouldShowGridOptions = iconsList.filter((e) => e !== IconsList.export).length > 1;
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        {iconsList.includes(IconsList.export) && (
          <>
            <Tooltip title="Export" placement="top">
              <span style={defaultHeaderIconSpanStyle}>
                <NewBeaconExportIcon
                  width="24px"
                  height="24px"
                  id="export"
                  onClick={() => {
                    setIsExportModalOpen(true);
                  }}
                />
              </span>
            </Tooltip>
            {isExportModalOpen && (
              <AppExportModal
                open={isExportModalOpen}
                handleClose={() => setIsExportModalOpen(false)}
                buildExportRequest={buildExportRequest}
              />
            )}
          </>
        )}
        {iconsList.includes(IconsList.tile) && shouldShowGridOptions ? (
          <Tooltip title="Tile View" placement="top">
            <span style={defaultHeaderIconSpanStyle}>
              <ViewTileIcon
                width="24px"
                height="24px"
                id="tile-view"
                onClick={() => handleChangeLayout(false)}
                style={{ fill: theme.colors.primary, opacity: defaultView === 'tile' ? 1 : 0.2 }}
              />
            </span>
          </Tooltip>
        ) : null}
        {iconsList.includes(IconsList.table) && shouldShowGridOptions ? (
          <Tooltip title="Table View" placement="top">
            <span style={defaultHeaderIconSpanStyle}>
              <ViewRowIcon
                data-for="table-view"
                data-tip="Table View"
                width="24px"
                height="24px"
                id="table-view"
                onClick={() => handleChangeLayout(true)}
                style={{ fill: theme.colors.primary, opacity: defaultView === 'table' ? 1 : 0.2 }}
              />
            </span>
          </Tooltip>
        ) : null}
      </Box>
    );
  }

  return (
    <Fragment>
      {app.name !== AppName.Omni ? (
        <Tooltip title="Tile View" placement="top">
          <span>
            <ViewTileIcon
              id="tile-view"
              onClick={() => handleChangeLayout(false)}
              className={`summary-page__view-tile-icon ${
                defaultView === 'tile' ? 'summary-page__view-tile-icon--active' : ''
              }`}
            />
          </span>
        </Tooltip>
      ) : null}

      {app.name === AppName.Omni && selectedGroupByField.name === 'product' ? (
        <Tooltip title="Tile View" placement="top">
          <span>
            <ViewTileIcon
              id="tile-view"
              onClick={() => handleChangeLayout(false)}
              className={`summary-page__view-tile-icon ${
                defaultView === 'tile' ? 'summary-page__view-tile-icon--active' : ''
              }`}
            />
          </span>
        </Tooltip>
      ) : null}

      <Tooltip title="Table View" placement="top">
        <span>
          <ViewRowIcon
            data-for="table-view"
            data-tip="Table View"
            id="table-view"
            onClick={() => handleChangeLayout(true)}
            className={`summary-page__view-row-icon ${
              defaultView === 'table' ? 'summary-page__view-row-icon--active' : ''
            }`}
          />
        </span>
      </Tooltip>
    </Fragment>
  );
};

export default EntityIconButtonGroup;
