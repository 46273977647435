//   This files handles the parsing of the categories being followed
import _get from 'lodash/get';
import { isCriteo, shouldShowCriteo } from 'src/utils/app';
import { getParentPlatform } from 'src/utils/browser';

export function parseAdEntities(adEntities, retailerId) {
  let resFilter = [];
  if (shouldShowCriteo() && isCriteo() && retailerId === '0') {
    const currentParentPlatform = getParentPlatform();
    const platformsByEntities = adEntities.filter((item) => item.parentPlatform === currentParentPlatform);
    resFilter = platformsByEntities.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  } else {
    resFilter = adEntities.filter((item) => `${_get(item, ['extendedAttributes', 'retailerId'], '')}` === retailerId);
  }

  resFilter.map((adEntity) => ({
    ...adEntity,
    id: adEntity.settingId ? adEntity.settingId : adEntity.id,
    entityId: adEntity.settingId ? adEntity.settingId : adEntity.id,
    displayName: adEntity.extendedAttributes ? adEntity.extendedAttributes.name : adEntity.name,
    type: 'adEntity'
  }));
  return resFilter;
}
