import React from 'react';
import PropTypes from 'prop-types';
import { withBus } from 'react-bus';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

const styles = {
  root: {
    position: 'absolute',
    left: 0,
    top: 30,
    paddingRight: 0
  }
};

/**
 * Displays a popup menu in the column header of the each paid traffic meetric columns that allows users to select a
 * different metric to display for the current column.
 *
 * @param {object} props
 */
const ActiveMetricSwitcher = withBus('eventBus')(({ eventBus, inactiveFields, currentFieldName, onChange }) => (
  <Paper style={styles.root}>
    {inactiveFields.map((field) => (
      <MenuItem
        value={field.name}
        key={field.name}
        onClick={() => {
          if (onChange) {
            onChange(field.name);
          } else {
            eventBus.emit('gridSwapActiveFieldName', { currentFieldName, newFieldName: field.name });
          }
        }}
      >
        {field.displayName}
      </MenuItem>
    ))}
  </Paper>
));

ActiveMetricSwitcher.propTypes = {
  inactiveFields: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  eventBus: PropTypes.object.isRequired,
  currentFieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

ActiveMetricSwitcher.defaultProps = {
  onChange: null
};

export default ActiveMetricSwitcher;
