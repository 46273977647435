import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import ReduxStore from 'src/types/store/reduxStore';
import {
  startFetchOmniAccountSetting,
  receiveOmniAccountSetting
} from 'src/store/modules/omni/omniAccountSetting/action';

const endPoint = '/omni/account/getAccountSettings';

export const fetchOmniAccountSetting = () => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
  try {
    dispatch(startFetchOmniAccountSetting({ data: null }));
    const response = await axios.get(endPoint);
    if (response && response.status === 200) {
      const { data } = response;
      dispatch(receiveOmniAccountSetting({ data }));
    }
  } catch (e) {
    console.warn(e);
  }
};
