import React from 'react';
import BeaconDropdownMetricColumnChart from 'src/components/BeaconRedesignComponents/BeaconMetricColumnChart/BeaconDropdownMetricColumnChart';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import PricesSummaryProductGrid from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/Summary/PricesSummaryProductGrid';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import TopEntityChartV2Loading from 'src/components/BeaconRedesignComponents/TopEntityChartV2/TopEntityChartV2Loading';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

/**
 * Prices Summary Page
 */
const PricesSummaryPage = () => {
  return (
    <BeaconPageContainer>
      <Flex gap={BEACON_CHART_SPACING} flexDirection="column">
        <BeaconComparableSplineChart indexName="sales" fieldName="retailPrice" />
        <BeaconComparableSplineChart indexName="sales" fieldName="wholesalePrice" />
        <BeaconComparableSplineChart indexName="sales" fieldName="brandCogsPerUnit" />
        <BeaconComparableSplineChart indexName="sales" fieldName="brandGrossMarginPerUnit" />
        <BeaconDropdownMetricColumnChart
          beaconChartWithLegendPropsBuilder={(props) => ({
            ...props,
            loadingComponent: <TopEntityChartV2Loading />
          })}
          indexName="sales"
          fieldName="retailPrice"
          dropdownOptions={[
            {
              id: 'subCategoryId',
              label: 'Subcategory'
            },
            {
              id: 'categoryId',
              label: 'Category'
            },
            {
              id: 'brandId',
              label: 'Brand'
            }
          ]}
          titlePrefix="Retail Price by"
        />
        <PricesSummaryProductGrid />
      </Flex>
    </BeaconPageContainer>
  );
};

export default PricesSummaryPage;
