import * as types from './types';

// ------------------------------------
// Action Creators - they simply return an action
// An action being a payload of information that send data from your application to your store.
// ------------------------------------

// Post a message for success or failure
export function signInSuccess() {
  return {
    type: types.SIGN_IN_SUCCESS
  };
}

export function signInFailure(errorMessage) {
  return {
    type: types.SIGN_IN_FAILURE,
    errorMessage
  };
}

export function signOutSuccess() {
  return {
    type: types.SIGN_OUT_SUCCESS
  };
}

export function signOutFailure(errorMessage) {
  return {
    type: types.SIGN_OUT_FAILURE,
    errorMessage
  };
}

export function clearMessage() {
  return {
    type: types.CLEAR_MESSAGE
  };
}

export function setErrorMessage(errorMessage) {
  console.error(errorMessage); // perhaps show the full message on the DOM if in dev?
  if (errorMessage.includes('TypeError')) {
    errorMessage = 'An error occurred while logging in.  Please retry or contact support.';
  } else if (errorMessage.includes('status code')) {
    errorMessage += '. Please retry or contact support.';
  }
  return {
    type: types.SET_ERROR_MESSAGE,
    errorMessage
  };
}
