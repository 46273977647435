import React, { ChangeEvent, useEffect, useState } from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import StyledDialog from 'src/components/BeaconRedesignComponents/common/StyledDialog/StyledDialog';
import { CloseIcon } from 'src/components/SvgIcons';
import { Divider, FormControlLabel } from '@mui/material';
import { Text, useStacklineTheme } from '@stackline/ui';
import {
  DISPUTE_STAGE,
  DISPUTE_STATUS
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/constants';
import StyledCheckbox from 'src/components/BeaconRedesignComponents/common/StyledCheckbox';
import MaskedInput from 'src/components/BeaconRedesignComponents/common/StyledInput/MaskedInput';
import { currencyMask } from 'src/components/BeaconRedesignComponents/common/StyledInput/inputMasks';
import { updateUrlQueryParams } from 'src/utils/app';
import { useHistory, useLocation } from 'src/utils/Hooks';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

const DisputeFilterModal = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isStageFilterActive = (option) => ({
    ...option,
    isChecked: searchParams.get('stage') ? searchParams.get('stage') === option.id : false
  });
  const isStatusFilterActive = (option) => ({
    ...option,
    isChecked: searchParams.get('status') ? searchParams.get('status') === option.id : false
  });

  const defaultStageOptions = [
    { id: DISPUTE_STAGE.NEW_SHORTAGE, label: 'New Shortage', isChecked: false },
    { id: DISPUTE_STAGE.INITIAL_DISPUTE, label: 'Initial Dispute', isChecked: false },
    { id: DISPUTE_STAGE.CONTACT_US_CASE, label: 'Contact Us Case', isChecked: false },
    { id: DISPUTE_STAGE.RESOLVED, label: 'Resolved', isChecked: false }
  ].map(isStageFilterActive);

  const [stageOptions, setStageOptions] = useState(defaultStageOptions);

  const [shortageValueInput, setShortageValueInput] = useState({
    min: searchParams.get('minValue') || null,
    max: searchParams.get('maxValue') || null
  });

  // We determine our available status options based on the selected stage filter
  const statusOptionsByStage = {
    [DISPUTE_STAGE.NEW_SHORTAGE]: [
      { id: DISPUTE_STATUS.NEEDS_DOCUMENTS, label: 'Needs Supporting Documents', isChecked: false },
      { id: DISPUTE_STATUS.READY_TO_SUBMIT, label: 'Ready to Submit', isChecked: false },
      { id: DISPUTE_STATUS.FAILED_TO_SUBMIT, label: 'Failed to Submit', isChecked: false },
      { id: DISPUTE_STATUS.QUEUED, label: 'Queued for Submission', isChecked: false },
      { id: DISPUTE_STATUS.SUBMISSION_IN_PROGRESS, label: 'Submission in Progress', isChecked: false }
    ].map(isStatusFilterActive),
    [DISPUTE_STAGE.INITIAL_DISPUTE]: [{ id: DISPUTE_STATUS.PENDING, label: 'Pending', isChecked: false }].map(
      isStatusFilterActive
    ),
    [DISPUTE_STAGE.CONTACT_US_CASE]: [
      { id: DISPUTE_STATUS.NEEDS_ACTION, label: 'Needs Action', isChecked: false },
      { id: DISPUTE_STATUS.READY_TO_SUBMIT, label: 'Ready to Submit', isChecked: false },
      { id: DISPUTE_STATUS.FAILED_TO_SUBMIT, label: 'Failed to Submit', isChecked: false },
      { id: DISPUTE_STATUS.QUEUED, label: 'Queued for Submission', isChecked: false },
      { id: DISPUTE_STATUS.SUBMISSION_IN_PROGRESS, label: 'Submission in Progress', isChecked: false }
    ].map(isStatusFilterActive),
    [DISPUTE_STAGE.RESOLVED]: [
      { id: DISPUTE_STATUS.WON, label: 'Won', isChecked: false },
      { id: DISPUTE_STATUS.PARTIALLY_WON, label: 'Partially Won', isChecked: false },
      { id: DISPUTE_STATUS.LOST, label: 'Lost', isChecked: false }
    ].map(isStatusFilterActive)
  };

  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const newlySelectedStage = stageOptions.find((option) => option.isChecked);
    if (newlySelectedStage) {
      setStatusOptions(statusOptionsByStage[newlySelectedStage.id]);
    } else {
      setStatusOptions([]);
    }
  }, [stageOptions]);

  const handleClear = () => {
    setShortageValueInput({ min: '', max: '' });
    setStageOptions(defaultStageOptions.map((option) => ({ ...option, isChecked: false })));
    setStatusOptions([]);
  };

  const handleStageSelection = (e: React.SyntheticEvent<Element, Event>) => {
    const { value } = e.target;
    setStageOptions(
      stageOptions.map((option) => ({ ...option, isChecked: option.id === value ? !option.isChecked : false }))
    );
  };

  const handleStatusSelection = (e: React.SyntheticEvent<Element, Event>) => {
    const { value } = e.target;
    const selectedStage = stageOptions.find((option) => option.isChecked);
    if (selectedStage) {
      setStatusOptions(
        statusOptions.map((option) => ({
          ...option,
          isChecked: option.id === value ? !option.isChecked : false
        }))
      );
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    setShortageValueInput({ ...shortageValueInput, [key]: event.target.value });
  };

  const handleApply = () => {
    const [selectedStageOption] = stageOptions.filter(({ isChecked }) => isChecked);
    const [selectedStatusOption] = statusOptions.filter(({ isChecked }) => isChecked);
    const { min, max } = shortageValueInput;

    // Setting an unset filter to null results in them being deleted from the URL
    const filters = {
      minValue: typeof min === 'number' && min === 0 ? min : min || null,
      maxValue: typeof max === 'number' && max === 0 ? max : max || null,
      stage: selectedStageOption ? selectedStageOption.id : null,
      status: selectedStatusOption ? selectedStatusOption.id : null
    };

    history.push(updateUrlQueryParams(filters));
    handleClose();
  };

  const theme = useStacklineTheme();

  return (
    <StyledDialog padding="0" open={open} onClose={handleClose} width={752} height={641}>
      {/* Header */}
      <Flex justifyContent="center" alignItems="center" paddingX="48px" paddingY="18px">
        <Text variant="h4">Filter</Text>
        <CloseIcon
          onClick={handleClose}
          style={{ position: 'absolute', left: '700px', cursor: 'pointer', width: '24px', height: '24px' }}
        ></CloseIcon>
      </Flex>
      <Divider />

      {/* Shortage amount inputs */}
      <Flex paddingX="48px" paddingTop="24px" flexDirection="column" gap="md">
        <Text variant="subtitle1">Shortage Value</Text>
        <Flex gap="cardGrid" alignItems="center">
          <MaskedInput
            masker={currencyMask}
            onChange={(e) => handleInputChange(e, 'min')}
            value={shortageValueInput.min || ''}
            placeholder="$0"
            style={{ width: '88px' }}
            autoComplete="off"
            id="type-search"
          />
          <Text variant="body2">to</Text>
          <MaskedInput
            masker={currencyMask}
            value={shortageValueInput.max || ''}
            onChange={(e) => handleInputChange(e, 'max')}
            placeholder="$100,000"
            style={{ width: '88px' }}
            autoComplete="off"
            id="type-search"
          />
        </Flex>
      </Flex>

      {/* Stage options */}
      <Flex paddingX="48px" paddingTop="32px" flexDirection="column" gap="md">
        <Text variant="subtitle1">Dispute Stage</Text>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 140px)',
            justifyContent: 'space-between',
            rowGap: '12px',
            columnGap: '30px'
          }}
        >
          {stageOptions.map((stage) => {
            return (
              <FormControlLabel
                sx={{
                  gap: '8px',
                  height: '24px',
                  color: theme.colors.primary,
                  minWidth: '110px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginLeft: '0px',
                  marginRight: '0px',
                  '& .MuiTypography-root': {
                    width: '110px',
                    fontSize: '14px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }
                }}
                label={stage.label}
                id={stage.id}
                value={stage.id}
                checked={stage.isChecked}
                control={<StyledCheckbox />}
                key={stage.id}
                onChange={handleStageSelection}
              />
            );
          })}
        </div>
      </Flex>

      {/* Status Options */}
      <Flex height="100%" paddingX="48px" paddingTop="32px" flexDirection="column" gap="md">
        <Text variant="subtitle1">Dispute Status</Text>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 140px)',
            justifyContent: 'space-between',
            rowGap: '16px',
            columnGap: '10px'
          }}
        >
          {statusOptions.map((status) => {
            return (
              <FormControlLabel
                sx={{
                  alignItems: 'flex-start',
                  gap: '8px',
                  height: 'auto',
                  color: theme.colors.primary,
                  width: '140px',
                  marginLeft: '0px',
                  marginRight: '0px',
                  '& .MuiTypography-root': {
                    fontSize: '14px'
                  },
                  '& .MuiButtonBase-root': {
                    marginTop: '4px'
                  }
                }}
                value={status.id}
                label={status.label}
                id={status.id}
                checked={status.isChecked}
                control={<StyledCheckbox />}
                key={status.id}
                onChange={handleStatusSelection}
              />
            );
          })}
        </div>
      </Flex>

      {/* Footer */}
      <Flex flexDirection="column">
        <Divider />
        <Flex justifyContent="space-between" alignItems="center" paddingX="48px" paddingY="20px">
          <SlButton onClick={handleClear}>Clear</SlButton>
          <SlButton onClick={handleApply} variant="contained">
            Apply
          </SlButton>
        </Flex>
      </Flex>
    </StyledDialog>
  );
};

export default DisputeFilterModal;
