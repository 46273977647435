import React from 'react';
import PropTypes from 'prop-types';
import { withBus } from 'react-bus';

import Tabs from './Tabs';

const TabsWidget = ({
  widget: {
    view: { tabs, eventName, style, tabStyle, tabRootStyle, value, onChange }
  },
  eventBus
}) => (
  <Tabs
    tabs={tabs}
    tabRootStyle={tabRootStyle}
    onTabChange={(_event, i) => {
      if (eventName) {
        eventBus.emit(eventName, tabs[i]);
      }

      if (onChange) {
        onChange(tabs[i].value);
      }
    }}
    style={style}
    tabStyle={tabStyle}
    value={value}
  />
);

TabsWidget.propTypes = {
  widget: PropTypes.object.isRequired,
  eventBus: PropTypes.object.isRequired
};

const EnhancedTabsWidget = withBus('eventBus')(TabsWidget);

export const buildTabsWidgetConfig = ({ eventName, tabs, style, tabStyle, value }) => ({
  CustomComponent: EnhancedTabsWidget,
  view: { tabs, eventName, style, tabStyle, value },
  data: {}
});

export default EnhancedTabsWidget;
