interface ClientDataExport {
  data: any;
  mimeType: string;
  fileName: string;
}

export function exportData(clientDataExport: ClientDataExport) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  const blob = new Blob([clientDataExport.data], { type: clientDataExport.mimeType });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = clientDataExport.fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

/**
 * Given a set of headers, data and a title export a CSV file
 */
export function exportCsvFile({ headers, data, title }: { headers: string[]; data: any[]; title: string }) {
  const csvContent = `data:text/csv;charset=utf-8,${[headers, ...data]
    .map((row) => row.map((i) => `"${i}"`).join(',')) // Wrap each value in quotes in case there are commas
    .join('\n')}`;

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', title.endsWith('.csv') ? title : `${title}.csv`);
  link.click();
}
