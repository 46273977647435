import { Tab, TabProps, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { brandclub_colors } from '@stackline/ui';
import _get from 'lodash/get';
import React, { CSSProperties, SyntheticEvent, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { TabRoute } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/types';
import { BEACON_SUBTABS, BEACON_TABS } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';
import { PRIMARY_TAB_INDICES } from 'src/components/BeaconRedesignComponents/PrimaryPageNavigation/constants';
import styles from 'src/components/BeaconRedesignComponents/styles/styles';
import { calculateNewQueryParams } from 'src/store/modules/app/selectors';
import { onShortagesMode } from 'src/store/modules/user/selectors';
import { EntityPathname } from 'src/types/application/types';
import { useAppSelector } from 'src/utils/Hooks';
import { getQueryParamValue } from 'src/utils/app';

const useStyles = makeStyles({
  navbar: {
    backgroundColor: brandclub_colors.white,
    boxShadow: '0 1px 4px 0 rgba(21, 34, 50, 0.08)',
    position: 'initial',
    top: 60,
    zIndex: 1000
  },
  tabsContainer: {
    paddingTop: '8px',
    paddingBottom: '8px',
    WebkitMaskImage: 'none'
  },
  tabs: {
    ...styles.pageCenterDiv,
    width: styles.pageCenterDiv.width,
    margin: `0 auto`,
    '& div.MuiTabs-flexContainer': {
      alignItems: 'center',
      paddingLeft: 0,
      overflowX: 'hidden',
      gap: '18px'
    }
  },
  tab: {
    borderRadius: 13,
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    color: '#052849',
    padding: 0,
    textTransform: 'none',
    '&.MuiButtonBase-root': {
      minHeight: 26,
      minWidth: 'unset',
      textDecoration: 'none'
    },
    '&.Mui-selected': {
      padding: '4px 16px',
      backgroundColor: '#052849',
      color: brandclub_colors.white
    }
  }
});

interface LinkTabProps {
  label: string;
  route: string;
  routePrefix?: string;
  containerStyle?: React.ComponentProps<'span'>['style'];
}

const LinkTab = (props: LinkTabProps) => {
  const { route, containerStyle, ...rest } = props;

  return (
    <>
      <div style={{ display: 'inline-flex', alignItems: 'center', ...containerStyle }}>
        {/* @ts-ignore */}
        <Tab
          disableRipple
          LinkComponent={NavLink}
          to={route}
          sx={{
            letterSpacing: 'normal',
            '&.Mui-disabled': {
              color: brandclub_colors.gray5
            }
          }}
          {...(rest as TabProps)}
        />
      </div>
    </>
  );
};

interface AppLinkTabProps {
  tabIndex: number;
  setTabIndex: (num: number) => void;
  tabRoutes: TabRoute[];
  tabsProps: any;
  tabProps: any;
  containerStyle?: CSSProperties;
}

export const AppLinkTabs = ({
  tabIndex,
  setTabIndex,
  tabRoutes,
  tabsProps,
  tabProps,
  containerStyle
}: AppLinkTabProps) => {
  const handleTabChange = (_event: SyntheticEvent<Element>, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Tabs
      value={tabIndex}
      TabIndicatorProps={{
        style: {
          display: 'none'
        }
      }}
      onChange={handleTabChange}
      {...tabsProps}
    >
      {tabRoutes.map((tabRoute, index) => {
        const isSelected = tabIndex === index;

        return (
          <LinkTab
            containerStyle={{
              marginLeft: isSelected ? 0 : 16,
              marginRight: isSelected ? 0 : 16,
              ...containerStyle
            }}
            key={`tab:${tabRoute.route}`}
            label={tabRoute.label}
            disabled={tabRoute.disabled}
            route={tabRoute.route}
            hidden={false}
            {...tabProps}
          />
        );
      })}
    </Tabs>
  );
};

interface PrimaryNavBarProps {
  location: any;
}

export const PrimaryNavBar = ({ location }: PrimaryNavBarProps) => {
  const app = useAppSelector((state) => state.app);
  const user = useAppSelector((state) => state.user);
  const retailer = useAppSelector((state) => state.retailer);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const comparisonTimePeriod = useAppSelector((state) => state.comparisonTimePeriod);
  const allWeekIdsByRetailerId = useAppSelector((state) => state.allWeekIdsByRetailerId);

  // Note: Beacons "Home" is actually the "client" route. Categories, Brands, Segments, etc are under "Home" currently.

  const tabRoutes: TabRoute[] = [
    {
      label: 'Home',
      route: `/client/${user.config.vendor.hashId}${
        calculateNewQueryParams(
          app,
          { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
          mainTimePeriod,
          comparisonTimePeriod
        ).searchParams
      }&tab=${app.defaultQueryParams.tab}&subtab=${app.defaultQueryParams.subtab}`,
      index: PRIMARY_TAB_INDICES.Home
    },
    {
      label: 'Categories',
      route: `/home${
        calculateNewQueryParams(
          app,
          { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
          mainTimePeriod,
          comparisonTimePeriod
        ).searchParams
      }&tab=summary&subtab=${app.name}&entityType=category`,
      index: PRIMARY_TAB_INDICES.Categories
    },
    {
      label: 'Segments',
      route: `/home${
        calculateNewQueryParams(
          app,
          { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
          mainTimePeriod,
          comparisonTimePeriod
        ).searchParams
      }&tab=summary&subtab=${app.name}&entityType=segment`,
      index: PRIMARY_TAB_INDICES.Segments
    },
    {
      label: 'Topics',
      route: `/home${
        calculateNewQueryParams(
          app,
          { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
          mainTimePeriod,
          comparisonTimePeriod
        ).searchParams
      }&tab=summary&subtab=${app.name}&entityType=searchtermlist`,
      index: PRIMARY_TAB_INDICES.Topics
    },
    {
      label: 'Brands',
      route: `/home${
        calculateNewQueryParams(
          app,
          { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
          mainTimePeriod,
          comparisonTimePeriod
        ).searchParams
      }&tab=summary&subtab=${app.name}&entityType=brand`,
      index: PRIMARY_TAB_INDICES.Brands
    },
    {
      label: 'Finance',
      route: `/client/${user.config.vendor.hashId}${
        calculateNewQueryParams(
          app,
          { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
          mainTimePeriod,
          comparisonTimePeriod
        ).searchParams
      }&tab=${BEACON_TABS.OPERATIONS}&subtab=${BEACON_SUBTABS.DISPUTES}`,
      index: PRIMARY_TAB_INDICES.Finance,
      disabled: !onShortagesMode() // TODO: If we split Dispute Management and Shortages subscriptions, we will need to replace this
    }
  ];

  const activeTabIndex = useMemo(() => {
    if (location.pathname === EntityPathname.Search) {
      const entityType = getQueryParamValue('entityType');

      if (entityType === 'segment') {
        return PRIMARY_TAB_INDICES.Segments;
      } else if (entityType === 'searchtermlist') {
        return PRIMARY_TAB_INDICES.Topics;
      } else {
        return PRIMARY_TAB_INDICES.Home;
      }
    } else {
      return _get(
        tabRoutes.find((tab) => {
          return `${location.pathname + location.search}`.startsWith(tab.route);
        }),
        'index',
        0
      );
    }
  }, [location.pathname, location.search, tabRoutes]);

  const classes = useStyles();

  return (
    <div className={classes.navbar}>
      <div className={classes.tabsContainer}>
        <AppLinkTabs
          tabIndex={activeTabIndex}
          setTabIndex={(_) => {}}
          tabRoutes={tabRoutes}
          tabsProps={{
            classes: {
              root: classes.tabs
            }
          }}
          tabProps={{
            classes: {
              root: classes.tab
            }
          }}
        />
      </div>
    </div>
  );
};
