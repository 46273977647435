import React, { useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useStacklineTheme, SlMenu, Text } from '@stackline/ui';
import TeamUserOptionPopup from '../TeamUserOptionPopup';
import * as serverProxy from 'src/routes/UserAccount/UserTeamManagement/serverProxy';
import { useSnackbar, useAppSelector } from 'src/utils/Hooks';
import { settingsErrorMessage } from '../../utils';
import IconButton from 'src/components/BeaconRedesignComponents/Generic/IconButton';

export enum DomainAction {
  DELETE = 'delete'
}

/**
 * Button for approved domains that currently allows deleting
 * approved domains
 */
export default function DomainActionButton({
  domain,
  refetchData
}: {
  domain: string;
  /**
   * Function to refetch the data after an action is performed. This will keep the
   * table up-to-date after performing a change.
   */
  refetchData: () => Promise<void>;
}) {
  const { showSnackbar } = useSnackbar();
  const theme = useStacklineTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteDomainOpen, setDeleteDomainOpen] = useState(false);
  const currentUserEmail = useAppSelector((state) => state.user.config.profile.email);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = () => {
    setDeleteDomainOpen(true);
  };

  const deleteDomain = async () => {
    try {
      await serverProxy.RemoveAuthorizedClientDomain(currentUserEmail, domain);
      await refetchData();
      showSnackbar({
        type: 'success',
        message: `You've successfully removed ${domain} as an approved domain.`
      });
    } catch {
      showSnackbar({
        type: 'error',
        message: settingsErrorMessage
      });
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreHorizIcon
          style={{
            fill: theme.colors.primary
          }}
        />
      </IconButton>
      <SlMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={!!anchorEl}
        selectedId={null}
        onChange={handleClickItem}
        options={[
          {
            id: DomainAction.DELETE,
            label: 'Remove domain'
          }
        ]}
      />
      <TeamUserOptionPopup
        modalTitle="Remove Domain"
        onConfirm={() => deleteDomain()}
        open={deleteDomainOpen}
        onClose={() => setDeleteDomainOpen(false)}
      >
        Users with the domain{' '}
        <Text variant="subtitle2" inline>
          @{domain}
        </Text>{' '}
        will no longer be able to access this account.
      </TeamUserOptionPopup>
    </>
  );
}
