import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';

import { INDEX_FIELDS, ENTITIES, METRICTYPE, DATATYPE } from 'src/utils/entityDefinitions';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import {
  buildTrendWidgetConfig,
  summarySubTrendView,
  summaryTrendStyle,
  buildSummaryTrendColumnGroupsWidget
} from 'src/components/Layout/LayoutUtil';
import CommonSummaryTrend from 'src/components/EntityPage/SummaryTrendChart/CommonSummaryTrend';
import CommonSummaryInfo from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { buildTrendingProductsWidgets } from 'src/components/Grids/TrendingProductsGrid';
import { buildSubtitleDisplayName } from 'src/utils/filters';

export default function getScorecardBasicPageLayout({ app, entity, retailer }) {
  const indexName = 'sales';
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
  const retailerIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'));
  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.atlas.retailer),
    categoryEntity: _cloneDeep(ENTITIES.atlas.category),
    relatedEntity: _cloneDeep(ENTITIES.atlas.brand),
    weekIdField,
    retailerIdField
  };

  const widgets = [];

  const [retailSalesMarketShareField, unitsSoldMarketShareField, retailPriceMarketShareField] = [
    'retailSalesMarketShare',
    'unitsSoldMarketShare',
    'retailPriceMarketShare'
  ].map((fieldName) => INDEX_FIELDS.getField(app.name, 'sales', fieldName, entity.type, weekIdField.name));

  const retailSalesAvgTrendChart = buildTrendWidgetConfig(
    app,
    'sales',
    entity,
    'weekId',
    ['retailSales'],
    weekIdField,
    {
      name: 'retailSalesPerSkuTrend',
      view: {
        chartPropsOverride: {
          legend: {
            enabled: false,
            legendTitle: 'SALES PER SKU'
          },
          ...summarySubTrendView
        },
        linkTo: { tab: 'sales', subtab: 'retailSales' },
        container: { ...summaryTrendStyle.subTrend }
      },
      CustomComponent: CommonSummaryTrend
    }
  );
  const unitsSoldAvgTrendChart = buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['unitsSold'], weekIdField, {
    name: 'unitsSoldPerSkuTrend',
    view: {
      chartPropsOverride: {
        legend: {
          enabled: false,
          legendTitle: 'UNITS PER SKU'
        },
        ...summarySubTrendView
      },
      linkTo: { tab: 'sales', subtab: 'unitsSold' },
      container: { ...summaryTrendStyle.subTrend }
    },
    CustomComponent: CommonSummaryTrend
  });
  if (entity.type !== 'product') {
    retailSalesAvgTrendChart.view.metricFields[0].aggregationFunction = 'avg';
    retailSalesAvgTrendChart.data.configByGroupByFieldName.weekId.aggregationFields[0].aggregationFunction = 'avg';

    unitsSoldAvgTrendChart.view.metricFields[0].aggregationFunction = 'avg';
    unitsSoldAvgTrendChart.data.configByGroupByFieldName.weekId.aggregationFields[0].aggregationFunction = 'avg';
  }
  const productsWithSalesWidgetConfig = buildTrendWidgetConfig(
    app,
    'sales',
    entity,
    'weekId',
    ['stacklineSku'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: {
            legendTitle: 'PRODUCTS',
            enabled: false
          },
          ...summarySubTrendView
        },
        linkTo: { tab: 'sales', subtab: 'retailSales' },
        container: { ...summaryTrendStyle.subTrend }
      },
      CustomComponent: CommonSummaryTrend
    }
  );

  productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'stacklineSku'
  ).conditions = {
    rangeFilters: [
      {
        fieldName: 'unitsSold',
        minValue: 4
      }
    ]
  };

  productsWithSalesWidgetConfig.view.metricFields[0].metricType = METRICTYPE.VOLUME;
  productsWithSalesWidgetConfig.view.metricFields[0].dataType = DATATYPE.INTEGER;
  productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'stacklineSku'
  ).metricType = METRICTYPE.VOLUME;
  productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'stacklineSku'
  ).dataType = DATATYPE.INTEGER;

  widgets.push({
    CustomComponent: CommonSummaryInfo,
    name: 'CommonSummaryInfo',
    view: {
      name: 'CommonSummaryInfo',
      // eslint-disable-next-line no-shadow
      buildSubTitle: ({ retailer, mainEntity, filters, categories, app }) =>
        mainEntity ? buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app).displayName : 'Summary'
    }
  });

  const [retailSalesOverrides, shareUnitsOverrides, priceIndexOverrides] = [
    [retailSalesMarketShareField],
    [unitsSoldMarketShareField],
    [retailPriceMarketShareField, { data: { marketShare: { metricType: METRICTYPE.MULTIPLE } } }]
  ].map(([field, override]) =>
    _merge(
      {
        view: { displayName: field.displayName, metricFields: [field] },
        data: { marketShare: { compute: true } }
      },
      override || {}
    )
  );

  const summaryTrendColumnsWidget = buildSummaryTrendColumnGroupsWidget({ app, entity, weekIdField }, [
    [
      ['sales', 'retailSales', 'Retail Sales', 'sales', 'retailSales'],
      [
        'sales',
        'retailSales',
        `SHARE (${retailer.currencySymbol})`,
        'sales',
        'retailSales',
        'retailSalesByweekId',
        retailSalesOverrides
      ],
      retailSalesAvgTrendChart,
      productsWithSalesWidgetConfig
    ],
    [
      ['sales', 'unitsSold', 'Units Sold', 'sales', 'unitsSold'],
      ['sales', 'unitsSold', 'SHARE (UNITS)', 'sales', 'unitsSold', 'unitsSoldByweekId', shareUnitsOverrides],
      unitsSoldAvgTrendChart,
      _merge(_cloneDeep(unitsSoldAvgTrendChart), {
        name: 'catalogEfficiencyTrend',
        view: {
          metricFields: [unitsSoldMarketShareField],
          chartPropsOverride: { legend: { legendTitle: 'CATALOG EFFICIENCY' } }
        },
        data: { marketShare: { compute: true, metricType: METRICTYPE.MULTIPLE } }
      })
    ],
    [
      ['sales', 'retailPrice', 'Retail Price', 'sales', 'retailPrice'],
      ['sales', 'retailPrice', 'PRICE INDEX', 'sales', 'retailPrice', 'retailPriceByweekId', priceIndexOverrides],
      ['promotions', 'dataPointCount', 'PROMOTIONS', 'promotions', 'retailSales'],
      ['promotions', 'retailSales', 'PROMOTION SALES', 'promotions', 'retailSales']
    ]
  ]);

  widgets.push(summaryTrendColumnsWidget);

  // TRENDING PRODUCTS
  if (entity.type !== 'product') {
    widgets.push(...buildTrendingProductsWidgets({ app, retailer, entity }));
  }

  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    widgets,
    containerStyle: {
      marginTop: 30
    },
    dataConfig
  };
}
