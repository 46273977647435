import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { InputRenderer } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Views/InputRenderer';
import {
  ContentScoreFields,
  PaidTrafficFields,
  PlanTypeOption,
  RatingsReviewFields
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import {
  PaidTrafficMetrics,
  fetchAdSpendMetrics,
  parseAdSpendResponse,
  useAdSpendProjection
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAdSpendProjection';
import { useOriginalProjection } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useOriginalProjection';
import { parseOriginalProjectionResponse } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/parsers';
import { AdjustmentForm } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import {
  getFormattedStartAndEndDates,
  isPaidTrafficInput
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/utils';
import { ForecastsAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import { useDebouncedValue, useMetricFormatter } from 'src/utils/Hooks';
import { calculateWeeksBetweenWeekIds } from 'src/utils/dateUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions';

/**
 * Finalize adjustment view
 */
interface FinalizeAdjustmentProps {
  stacklineSku: string;
  adjustmentForm: AdjustmentForm;
  retailerSku: string;
  categoryId: string | number;
  subcategoryId: string | number;
  handleInputChange: (
    inputTarget: 'total' | ContentScoreFields | RatingsReviewFields | PaidTrafficFields,
    value: any
  ) => void;
  shouldOverrideCostPerClick: boolean;
  setShouldOverrideCostPerClick: Dispatch<SetStateAction<boolean>>;
  adjustmentData: ForecastsAdjustmentData;
  shouldUseInitialValue: boolean;
  setShouldUseInitialValue: Dispatch<SetStateAction<boolean>>;
}

/**
 * Debounce delay in MS
 */
const DELAY_MS = 750;

/**
 * Stage 2 of 3 when creating an adjustment
 */
export const FinalizeAdjustment = ({
  retailerSku,
  categoryId,
  subcategoryId,
  stacklineSku,
  adjustmentForm,
  handleInputChange,
  shouldOverrideCostPerClick,
  setShouldOverrideCostPerClick,
  adjustmentData,
  shouldUseInitialValue,
  setShouldUseInitialValue
}: FinalizeAdjustmentProps) => {
  const { planInputAmounts, planType, startDate, endDate } = adjustmentForm;
  const { formattedStartDate, formattedEndDate } = getFormattedStartAndEndDates(startDate, endDate);
  const numberOfWeeks = calculateWeeksBetweenWeekIds(startDate, endDate) || 1;
  const formatMetric = useMetricFormatter();

  /**
   * Aggregated paid traffic metrics based on debounced inputs.
   */
  const [paidTrafficMetrics, setPaidTrafficMetrics] = useState<Omit<PaidTrafficMetrics, 'weekId'>>(null);

  // Delay the api call after the user inputs ad spend or CPC
  const debouncedAdSpend = useDebouncedValue(
    isPaidTrafficInput(planInputAmounts) ? planInputAmounts.adSpend : null,
    DELAY_MS
  );
  const debouncedCostPerClick = useDebouncedValue(
    isPaidTrafficInput(planInputAmounts) ? planInputAmounts.costPerClick : null,
    DELAY_MS
  );

  /**
   * The original projection is used across all plan types
   */
  const { data: originalProjectionResponse, isLoading } = useOriginalProjection({
    stacklineSku,
    startWeekId: startDate,
    endWeekId: endDate
  });

  const parsedData = originalProjectionResponse ? parseOriginalProjectionResponse(originalProjectionResponse) : null;
  /**
   * Extract function to build the ad spend request
   */
  const { createAdSpendRequest } = useAdSpendProjection({
    adjustmentForm,
    stacklineSku,
    retailerSku,
    categoryId,
    subCategoryId: subcategoryId,
    shouldFetch: false
  });

  // Once we have our original projection data, we can initialize the form value to the returned cost per click
  useEffect(() => {
    if (PlanTypeOption.PaidTraffic === planType && parsedData && shouldUseInitialValue) {
      handleInputChange(PaidTrafficFields.CostPerClick, String(parsedData.costPerClick));
      setShouldUseInitialValue(false);
    }
  }, [parsedData, shouldUseInitialValue]);

  /**
   * Special logic for ad spend adjustments.
   * Whenever ad spend or CPC are changed, we fetch the paid traffic metric and display it in a read-only input.
   * If the user changes the ad spend input amount and has also changed the initial CPC value, we override the CPC input.
   * Otherwise we use the user-inputted CPC
   */
  const fetchAndSetPaidTraffic = async () => {
    try {
      const response = await fetchAdSpendMetrics(
        createAdSpendRequest(
          {
            adSpend: debouncedAdSpend,
            cpc: debouncedCostPerClick,
            cpcFromUser: !shouldOverrideCostPerClick
          },
          [adjustmentData ? adjustmentData.adjustmentId : '']
        )
      );
      const adSpendMetrics = parseAdSpendResponse(response) as Omit<PaidTrafficMetrics, 'weekId'>;
      // Only override CPC if the initial CPC value has been changed and ad spend has changed
      if (shouldOverrideCostPerClick) {
        const { cpc } = adSpendMetrics;
        handleInputChange(PaidTrafficFields.CostPerClick, String(cpc));
      }

      setPaidTrafficMetrics(adSpendMetrics);
    } catch (err) {
      console.error('Failed to get ad spend data: ', err);
    }
  };

  useEffect(() => {
    if (PlanTypeOption.PaidTraffic === planType && debouncedAdSpend && Number(debouncedCostPerClick) >= 0) {
      fetchAndSetPaidTraffic();
    }
  }, [debouncedAdSpend, debouncedCostPerClick]);

  const currentProjectionText = useMemo(() => {
    if (!parsedData) {
      return '';
    }

    switch (planType) {
      case PlanTypeOption.OrganicTraffic:
        return `In total, this product is currently projected to generate ${formatMetric(
          parsedData.organicTraffic_sum_value,
          METRICTYPE.VOLUME
        )} in organic traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;
      case PlanTypeOption.OtherTraffic:
        return `In total, this product is currently projected to generate ${formatMetric(
          parsedData.otherTraffic_sum_value,
          METRICTYPE.VOLUME
        )} in other traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;
      case PlanTypeOption.BuyBox:
        return `This product is currently projected to have a ${formatMetric(
          parsedData.winPercentage_computed_value,
          METRICTYPE.PERCENT
        )} buy box rate during the period of ${formattedStartDate} to ${formattedEndDate}.`;
      case PlanTypeOption.ContentScore:
        return `This product is currently projected to have a ${formatMetric(
          parsedData.contentScore_avg_value,
          METRICTYPE.PERCENT
        )} content score during the period of ${formattedStartDate} to ${formattedEndDate}.`;
      case PlanTypeOption.InStockRate:
        return `This product is currently projected to have a ${formatMetric(
          parsedData.inStockRate_avg_value,
          METRICTYPE.PERCENT
        )} in-stock rate during the period of ${formattedStartDate} to ${formattedEndDate}.`;
      case PlanTypeOption.RetailPrice:
        return `This product is currently projected to have a retail price of ${formatMetric(
          parsedData.retailPrice_avg_value,
          METRICTYPE.MONEY
        )} during the period of ${formattedStartDate} to ${formattedEndDate}.`;
      case PlanTypeOption.RatingsReviews:
        return `In total, this product is currently projected to generate ${formatMetric(
          parsedData.reviewCount,
          METRICTYPE.VOLUME
        )} reviews with an average star rating of ${formatMetric(
          parsedData.weightedRating,
          METRICTYPE.DECIMAL
        )} during the period of ${formattedStartDate} to ${formattedEndDate}.`;

      case PlanTypeOption.PaidTraffic:
        return `In total, this product is currently projected to spend ${formatMetric(
          parsedData.adSpend,
          METRICTYPE.MONEY
        )} on advertising and generate ${formatMetric(
          parsedData.adClicks,
          METRICTYPE.VOLUME
        )} in paid traffic during the period of ${formattedStartDate} to ${formattedEndDate}.`;

      default:
        return '';
    }
  }, [parsedData, planType, formattedStartDate, formattedEndDate, formatMetric]);

  const currentProjectionSubText = useMemo(() => {
    if (!parsedData) {
      return '';
    }

    switch (planType) {
      case PlanTypeOption.OrganicTraffic:
        return `On a weekly basis, this product is currently projected to generate an average of ${formatMetric(
          parsedData.organicTraffic_sum_value / numberOfWeeks,
          METRICTYPE.VOLUME
        )} in organic traffic per week during the period.`;
      case PlanTypeOption.OtherTraffic:
        return `On a weekly basis, this product is currently projected to generate an average of ${formatMetric(
          parsedData.otherTraffic_sum_value / numberOfWeeks,
          METRICTYPE.VOLUME
        )} in other traffic per week during the period.`;
      case PlanTypeOption.BuyBox:
        return '';
      case PlanTypeOption.ContentScore:
        return '';
      case PlanTypeOption.InStockRate:
        return '';
      case PlanTypeOption.RetailPrice:
        return '';
      case PlanTypeOption.RatingsReviews:
        return '';

      case PlanTypeOption.PaidTraffic:
        return `On a weekly basis, this product is currently projected to generate an average of ${formatMetric(
          parsedData.adSpend / numberOfWeeks,
          METRICTYPE.MONEY
        )} in ad spend and ${formatMetric(
          parsedData.adClicks / numberOfWeeks,
          METRICTYPE.VOLUME
        )} in paid traffic per week during the period.`;
      default:
        return '';
    }
  }, [parsedData, planType, startDate, endDate, formatMetric]);

  return (
    <Flex flexDirection="column">
      {/* Current projection metrics */}
      <Flex marginTop="24px" gap="sm" flexDirection="column">
        <Text variant="subtitle2">Current Projection</Text>

        <Flex gap="sm" flexDirection="column">
          {isLoading ? (
            <Flex flexDirection="column" gap="sm">
              <SlSkeleton variant="rounded" height={19} width="100%"></SlSkeleton>
              <SlSkeleton variant="rounded" height={19} width="100%"></SlSkeleton>
            </Flex>
          ) : (
            <>
              <Text variant="body2">{currentProjectionText}</Text>
              <Text variant="body2">{currentProjectionSubText}</Text>
            </>
          )}
        </Flex>
      </Flex>
      {/* New projection metrics */}
      <Flex marginTop="24px" gap="sm" flexDirection="column">
        <Text variant="subtitle2">New Projection</Text>
        <Text variant="body2">Adjust your weekly plan for this product using the inputs below.</Text>
      </Flex>

      {parsedData ? (
        <InputRenderer
          setShouldOverrideCostPerClick={setShouldOverrideCostPerClick}
          paidTrafficMetrics={paidTrafficMetrics}
          parsedData={parsedData}
          adjustmentForm={adjustmentForm}
          handleInputChange={handleInputChange}
          planType={planType}
        />
      ) : null}
    </Flex>
  );
};
