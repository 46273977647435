import React from 'react';
import StyledDialog from 'src/components/BeaconRedesignComponents/common/StyledDialog/StyledDialog';
import TeamUserOptionPopup from '../TeamUserOptionPopup';
import { Text } from '@stackline/ui';
import * as serverProxy from 'src/routes/UserAccount/UserTeamManagement/serverProxy';
import { useAppSelector, useSnackbar } from 'src/utils/Hooks';
import { settingsErrorMessage } from '../../utils';

type RemoveAdminRoleModalProps = Omit<React.ComponentProps<typeof StyledDialog>, 'children' | 'width' | 'padding'> & {
  user: {
    email: string;
  };
  refetchData: () => Promise<void>;
};

/**
 * Popup to remove Admin status from a user and make them a Basic user instead.
 */
export default function RemoveAdminRoleModal({ user, refetchData, ...rest }: RemoveAdminRoleModalProps) {
  const { showSnackbar } = useSnackbar();
  const currentUserEmail = useAppSelector((state) => state.user.config.profile.email);
  const removeAdminRole = async () => {
    try {
      await serverProxy.ChangeUserRoleInCurrentDivision({
        action: 'setBasic',
        currentEmail: currentUserEmail,
        targetEmail: user.email
      });
      await refetchData();
      showSnackbar({
        type: 'success',
        message: `${user.email}'s role has been changed in the division.`
      });
    } catch (error) {
      showSnackbar({
        type: 'error',
        message: settingsErrorMessage
      });
    }
  };

  return (
    <TeamUserOptionPopup modalTitle="Remove Admin Role" onConfirm={() => removeAdminRole()} {...rest}>
      <Text variant="subtitle2" inline>
        {user.email}
      </Text>{' '}
      will no longer have Admin privileges and will remain in the account as a user.
    </TeamUserOptionPopup>
  );
}
