import { FetchAdditionalDataFn } from './types';
import { useCallback } from 'react';
import { AdGroupsDataFetcher } from 'src/components/AdManager/Search/GridDataFetchers/AdGroups/AdGroupsDataFetcher';

/**
 * Returns a callback function for fetching ad groups sales data
 */
const useFetchAdGroupsSalesData = (importantOverrides: any): FetchAdditionalDataFn => {
  return useCallback(async ({ fetchMetricsParams, pageNumber, dataSet }) => {
    const adGroupsDataFetcher = new AdGroupsDataFetcher();
    return adGroupsDataFetcher.fetchAdGroupsData({
      pageNumber,
      dataSet,
      fetchMetricsParams,
      importantOverrides
    });
  }, []);
};

export default useFetchAdGroupsSalesData;
