import React, { useEffect, useMemo, useCallback } from 'react';
import { useStacklineTheme, StacklineTheme } from '@stackline/ui';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import { useAppSelector, useMetricFormatter, useHistory, useLocation } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import GrowthMatrixScatterPlot, {
  GrowthMatrixScatterPlotDatum
} from '../GrowthMatrixScatterPlot/GrowthMatrixScatterPlot';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { parseProductGrowthDataForTable } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/productGrowthParser';
import ProductCell from 'src/components/BeaconRedesignComponents/ProductCell/ProductCell';
import { updateUrlQueryParams } from 'src/utils/app';
import { EntityPathname } from 'src/types/application/types';
import { getPreviousWeekId } from 'src/utils/dateUtils';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import { getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_GRID_TOP_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { TooltipIcon, NewBeaconExportIcon, NewBeaconExportIconDisabled } from 'src/components/SvgIcons';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/styles';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import { useForecastAdjustmentCount } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useForecastAdjustmentCount';
import { AxiosResponse } from 'axios';
import _get from 'lodash/get';
import { ProductGrowthQuadrant } from 'src/components/BeaconRedesignComponents/ProductGrowthModal/ModalComponents/QuadrantFilterSection';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { defaultHeaderIconSpanStyle } from 'src/components/common/Buttons/EntityIconButtonGroup';
import { exportCsvFile } from 'src/utils/export';
import Box, { BoxProps } from '@mui/material/Box';
import { useLatestForecastModel } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';

interface CustomTooltipProps {
  className: string;
  title: React.ReactElement;
  stacklineTheme: StacklineTheme;
  children: React.ReactElement;
  arrow?: boolean;
  placement?: TooltipProps['placement'];
}

export const CustomTooltip = styled(({ className, title, children, ...props }: CustomTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} title={title} arrow>
    {children}
  </Tooltip>
))(({ stacklineTheme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: stacklineTheme.colors.secondaryGhost,
    padding: '6px 12px 7px 12px'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: stacklineTheme.colors.secondaryGhost
  }
}));

export const TooltipIconComponent = React.forwardRef<HTMLDivElement, { style: React.CSSProperties }>(
  function TooltipIconComponent(props, ref) {
    return (
      <div {...props} ref={ref}>
        <TooltipIcon
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        />
      </div>
    );
  }
);

export const TooltipBox = ({ description }: { description: string }) => {
  return (
    <Flex>
      <Text variant="body2">{description}</Text>
    </Flex>
  );
};

// Column Definitions for table
const useFormattedColumnDefinitions = () => {
  const formatMetric = useMetricFormatter(); // Used to format metric values before being displayed in the table
  const {
    defaultQueryParams: { tab: defaultTab, subtab: defaultSubtab }
  } = useAppSelector((state) => state.app);

  const COL_DEFS = [
    // Product Entity
    {
      headerName: 'Products',
      field: 'stacklineSku',
      valueFormatter: (_, row) => {
        return (
          <ProductCell
            key={row.id}
            stacklineSku={row.id}
            title={row.name}
            customLink={updateUrlQueryParams(
              { tab: defaultTab, subtab: defaultSubtab, subcategory: null },
              { path: EntityPathname.Product, routeParam: row.id }
            )}
          />
        );
      }
    },

    // Retail Sales
    {
      field: 'retailSales',
      headerName: 'Retail Sales',
      valueFormatter: (value) => {
        return <div style={{ fontSize: '14px' }}>{formatMetric(value, METRICTYPE.MONEY)}</div>;
      }
    },
    // Total Traffic
    {
      field: 'beaconTotalTraffic',
      headerName: 'Total Traffic',
      valueFormatter: (value) => {
        return <div style={{ fontSize: '14px' }}>{formatMetric(value, METRICTYPE.VOLUME)}</div>;
      }
    },
    // Traffic Index
    {
      field: 'trafficIndex',
      headerName: 'Traffic Index',
      valueFormatter: (value) => {
        return <div style={{ fontSize: '14px' }}>{formatMetric(value, METRICTYPE.PERCENT, { decimalPlaces: 1 })}</div>;
      }
    },
    // Conversion Rate
    {
      field: 'beaconConversion',
      headerName: 'Conversion Rate',
      valueFormatter: (value) => {
        return <div style={{ fontSize: '14px' }}>{formatMetric(value, METRICTYPE.PERCENT, { decimalPlaces: 1 })}</div>;
      }
    },
    // Conversion Index
    {
      field: 'conversionIndex',
      headerName: 'Conversion Index',
      valueFormatter: (value) => {
        return <div style={{ fontSize: '14px' }}>{formatMetric(value, METRICTYPE.PERCENT, { decimalPlaces: 1 })}</div>;
      }
    },
    // Adjustment Count
    {
      field: 'adjustmentCount',
      headerName: 'Adjustment Count',
      valueFormatter: (value) => {
        return (
          <div style={{ fontSize: '14px' }}>{formatMetric(value, METRICTYPE.VOLUME, { showFullValue: true })}</div>
        );
      }
    }
  ];
  return COL_DEFS;
};

const getQuadrant = (x: number, y: number): ProductGrowthQuadrant => {
  if (x < 50 && y < 50) {
    return ProductGrowthQuadrant.Rationalize;
  }
  if (x < 50 && y >= 50) {
    return ProductGrowthQuadrant.Upgrade;
  }
  if (x >= 50 && y < 50) {
    return ProductGrowthQuadrant.Invest;
  }
  return ProductGrowthQuadrant.Leverage;
};

const ProductGrowthProductTable = ({ rowData, isLoading }: { rowData: any[]; isLoading: boolean }) => {
  const COL_DEFS = useFormattedColumnDefinitions();
  const retailer = useAppSelector((state) => state.retailer);

  const exportCsv = () => {
    const headers = [
      'Retailer ID',
      'Retailer Name',
      'Retailer SKU',
      'Title',
      'Brand',
      'Category',
      'Subcategory',
      'Retail Sales',
      'Total Traffic',
      'Traffic Index',
      'Conversion Rate',
      'Conversion Index',
      'Adjustment Count',
      'Quadrant'
    ];

    const rows = rowData.map((row) => {
      const entity = _get(row, 'entity');
      const retailSales = _get(row, 'retailSales');
      const beaconTotalTraffic = _get(row, 'beaconTotalTraffic');
      const trafficIndex = _get(row, 'trafficIndex');
      const beaconConversion = _get(row, 'beaconConversion');
      const conversionIndex = _get(row, 'conversionIndex');
      const adjustmentCount = _get(row, 'adjustmentCount');
      const x = _get(row, 'x');
      const y = _get(row, 'y');

      return [
        retailer.id,
        retailer.displayName,
        _get(entity, 'retailerSku'),
        _get(entity, 'title'),
        _get(entity, 'brandName'),
        _get(entity, 'categoryName'),
        _get(entity, 'subCategoryName'),
        retailSales,
        beaconTotalTraffic,
        trafficIndex,
        beaconConversion,
        conversionIndex,
        adjustmentCount,
        getQuadrant(x, y)
      ];
    });

    // Save as a CSV file
    exportCsvFile({ headers, data: rows, title: 'ProductGrowth' });
  };

  const loadingDownloadProps: BoxProps = {
    sx: {
      ...defaultHeaderIconSpanStyle,
      cursor: 'default'
    },
    children: <NewBeaconExportIconDisabled />
  };

  const downloadProps: BoxProps = {
    sx: defaultHeaderIconSpanStyle,
    onClick: () => exportCsv(),
    children: <NewBeaconExportIcon />
  };

  return (
    <Flex flexDirection="column" gap="xl">
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="h4">Product Details</Text>
        {isLoading ? (
          <Box {...loadingDownloadProps} />
        ) : (
          <Tooltip placement="top" title="Export">
            <Box {...downloadProps} />
          </Tooltip>
        )}
      </Flex>
      <EntityTable isLoading={isLoading} rowData={rowData || []} columnDefs={COL_DEFS} shouldModifyColumnDefs={false} />
    </Flex>
  );
};

/**
 * A custom hook which checks the URL for a subcategory ID, and sets a default if none is found. Returns the subcategory ID
 * and a function used to update it for shared use between the PG page and PG modal.
 * @returns {string} The currently selected subcategory ID
 * @returns {Function} A function to update the URL with a subcategory ID
 */
export const useBeaconSubcategoryIdRouter = () => {
  const history = useHistory();
  const location = useLocation();

  // Access client subcategories
  const entityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const entityId = useAppSelector((state) => state.entityService.mainEntity.id);
  const subCategories = useAppSelector((state) => {
    if (entityType === 'subcategory') {
      return state.subCategories.filter((subcategory) => subcategory.id === entityId);
    }
    if (entityType === 'category') {
      return state.subCategories.filter((subcategory) => `${subcategory.parentCategoryId}` === entityId);
    }
    return state.subCategories;
  });
  const app = useAppSelector((state) => state.app);
  const subCategoryIds = subCategories.map((subcategory) => subcategory.id);

  // Check URL for a selected subcategory
  const currentParams = new URLSearchParams(location.search);
  const subcategoryFromUrl = currentParams.get('subcategory');

  // Helper function for updating URL
  const updateUrlWithSubcategoryId = (id: string | number) => {
    const { searchParams, additionalParams } = app.queryParams;
    const { pathname } = location;
    const newFilterParams = `&subcategory=${id}`;
    const newUrl = `${pathname}${searchParams}${additionalParams}${newFilterParams}`;
    history.push(newUrl);
  };

  let selectedSubcategoryId: string | number;

  if (subcategoryFromUrl) {
    selectedSubcategoryId = subcategoryFromUrl;
  } else {
    [selectedSubcategoryId] = subCategoryIds;
  }

  return { selectedSubcategoryId, updateUrlWithSubcategoryId };
};

const ProductGrowthPage = () => {
  const { searchParams, additionalParams } = useAppSelector((state) => state.app.queryParams);
  const formatMetric = useMetricFormatter();
  const retailer = useAppSelector((state) => state.retailer);
  const subCategories = useAppSelector((state) => state.subCategories);
  const { modelVersion, loading: modelVersionLoading } = useLatestForecastModel();
  const entityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const entityId = useAppSelector((state) => state.entityService.mainEntity.id);
  const theme = useStacklineTheme();
  const { selectedSubcategoryId, updateUrlWithSubcategoryId } = useBeaconSubcategoryIdRouter();
  const location = useLocation();
  const quadrants = new URLSearchParams(location.search).getAll('quadrants') as ProductGrowthQuadrant[];

  const subcategoryName = useMemo(() => {
    return subCategories.find((subcategory) => subcategory.id === selectedSubcategoryId).displayName;
  }, [selectedSubcategoryId]);

  useEffect(() => {
    updateUrlWithSubcategoryId(selectedSubcategoryId);
  }, []);

  const createProductGrowthRequestBody = (weekId: number, filterOnProduct = false) => [
    {
      name: 'productGrowthSubcategoryId',
      id: 'productGrowthSubcategoryId',
      pageNumber: 1,
      pageSize: 1200,
      retailerId: retailer.id,
      period: 'year',
      doAggregation: false,
      returnDocuments: true,
      conditions: {
        termFilters: [
          {
            fieldName: 'subCategoryId',
            values: [selectedSubcategoryId]
          },
          ...(entityType === 'brand'
            ? [
                {
                  fieldName: 'brandId',
                  values: [entityId]
                }
              ]
            : []),
          ...(entityType === 'product' && filterOnProduct
            ? [
                {
                  fieldName: 'stacklineSku',
                  condition: 'should',
                  values: [entityId]
                }
              ]
            : [])
        ],
        rangeFilters: [
          {
            fieldName: 'weekId',
            minValue: `${weekId}`,
            maxValue: `${weekId}`
          }
        ]
      },
      searchBy: 'child',
      searchType: 'beacon-productGrowthMetrics',
      SortFilter: {
        sortFields: [
          {
            FieldName: 'retailSales',
            Direction: 'desc'
          }
        ]
      }
    }
  ];

  const currentPeriodRequestBody = modelVersion ? createProductGrowthRequestBody(Number(modelVersion)) : null;
  const tableDataRequestBody = modelVersion ? createProductGrowthRequestBody(Number(modelVersion), true) : null;

  const previousPeriodRequestBody = modelVersion
    ? createProductGrowthRequestBody(getPreviousWeekId(Number(modelVersion), 1))
    : null;

  // (Matrix) Current Data
  const { data, isLoading: currentDataLoading } = useGenericAdvancedSearch({
    requestId: `productGrowthSubcategoryId-week-${modelVersion}`,
    requestBody: currentPeriodRequestBody,
    shouldPerformFetch: !modelVersionLoading && !!currentPeriodRequestBody,
    queryKeys: currentPeriodRequestBody
  });

  // (Matrix) Comparison Data
  const { data: previousData, isLoading: previousDataLoading } = useGenericAdvancedSearch({
    requestId: `productGrowthSubcategoryId-week-comparison`,
    requestBody: previousPeriodRequestBody,
    shouldPerformFetch: !modelVersionLoading && !!previousPeriodRequestBody,
    queryKeys: previousPeriodRequestBody
  });

  // (Table) Current Data
  const { data: tableResponse, isLoading: tableResponseLoading } = useGenericAdvancedSearch({
    requestId: `productGrowthSubcategoryId-week-${modelVersion}-table`,
    requestBody: tableDataRequestBody,
    shouldPerformFetch: !modelVersionLoading && !!tableDataRequestBody,
    queryKeys: tableDataRequestBody
  });

  // (Table) Adjustment count data
  const { data: adjustmentCountBySku, isLoading: adjustmentCountBySkuLoading } = useForecastAdjustmentCount({
    buildRequest: (builder) => {
      const stacklineSkus = _get(tableResponse, ['data', 0, 'documents'], []).map((row) => row.product.stacklineSku);
      return builder
        .modifyAggregation((aggregation) => aggregation.setGroupByFieldName('stacklineSku'))
        .addConditionTermFilter('stacklineSku', 'should', stacklineSkus)
        .setPageSize(stacklineSkus.length);
    },
    parseResponse: (
      response: AxiosResponse<{ aggregations: { by_stacklineSku: { count: number; fieldId: string }[] } }[]>
    ) => {
      return response.data[0].aggregations.by_stacklineSku.reduce((acc, row) => {
        return {
          ...acc,
          [row.fieldId]: row.count
        };
      }, {} as Record<string, number>);
    },
    useGenericAdvancedSearchArgs: {
      shouldPerformFetch: !!tableResponse
    }
  });

  const isLoading = useMemo(
    () =>
      currentDataLoading ||
      previousDataLoading ||
      modelVersionLoading ||
      tableResponseLoading ||
      adjustmentCountBySkuLoading,
    [currentDataLoading, previousDataLoading, modelVersionLoading, tableResponseLoading, adjustmentCountBySkuLoading]
  );

  /**
   * Used to calculate the difference between retail sales
   * for the current and previous period. Maps the stackline
   * SKU to the retail sales so lookup takes place in constant time
   */
  const stacklineSkuToPreviousRetailSalesMap: { [key: string]: number } = useMemo(() => {
    return previousData
      ? previousData.data[0].documents.reduce(
          (acc, { stacklineSku, retailSales }) => ({
            ...acc,
            [stacklineSku]: retailSales
          }),
          {}
        )
      : {};
  }, [previousData]);

  const productRows = useMemo(() => {
    if (!tableResponse || !adjustmentCountBySku) {
      return [];
    }
    return parseProductGrowthDataForTable({ data: tableResponse, adjustmentCountBySku });
  }, [tableResponse, adjustmentCountBySku]);

  const matrixData: GrowthMatrixScatterPlotDatum[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.data[0].documents.map((row) => {
      const retailSalesDifference = row.retailSales - stacklineSkuToPreviousRetailSalesMap[row.stacklineSku];
      // Detailslink should redirect to keymetrics page instead of growthmatrix page.
      const modifiedAdditionParamsForCard = additionalParams.replace('growthmatrix', 'keymetrics');

      return {
        x: row.conversionIndex * 100,
        y: row.trafficIndex * 100,
        conversionIndex: formatMetric(row.conversionIndex, METRICTYPE.PERCENT, { showFullValue: false }),
        trafficIndex: formatMetric(row.trafficIndex, METRICTYPE.PERCENT, { showFullValue: false }),
        imageUri: `https://s3-us-west-2.amazonaws.com/stackline-product-images/${row.stacklineSku}.jpg`,
        mainDisplayTitle: row.product.brandName,
        secondaryDisplayTitle: row.product.title,
        mainMetric: formatMetric(row.retailSales, METRICTYPE.MONEY, { decimalPlaces: 1, showFullValue: false }),
        secondaryMetric: formatMetric(Math.abs(retailSalesDifference), METRICTYPE.MONEY, {
          showFullValue: false,
          decimalPlaces: 2
        }),
        direction: getDirection(retailSalesDifference),
        statusCode: row.actionType,
        statusColor: {
          Upgrade: 'success',
          Rationalize: 'accentTangerine',
          Leverage: 'info',
          Invest: 'accentTeal'
        }[row.actionType],
        detailsLink: `/product/${row.stacklineSku}${searchParams}${modifiedAdditionParamsForCard}`,
        stacklineSku: row.stacklineSku
      } as GrowthMatrixScatterPlotDatum;
    });
  }, [data, stacklineSkuToPreviousRetailSalesMap]);

  const filterByQuadrant = useCallback(
    (point: Pick<GrowthMatrixScatterPlotDatum, 'x' | 'y'>) => {
      if (quadrants.length === 0) {
        return true;
      }

      return quadrants.includes(getQuadrant(point.x, point.y));
    },
    [quadrants]
  );

  const productGrowthTable = useMemo(() => {
    return (
      <ProductGrowthProductTable
        isLoading={isLoading}
        rowData={productRows
          .map((row) => ({
            ...row,
            x: row.conversionIndex * 100,
            y: row.trafficIndex * 100
          }))
          .filter(filterByQuadrant)}
      />
    );
  }, [isLoading, productRows, filterByQuadrant]);

  return (
    <BeaconPageContainer>
      <Flex flexDirection="column" gap={BEACON_GRID_TOP_SPACING}>
        <Flex flexDirection="column" gap="lg">
          <CommonSummaryInfoSubtitle
            title={
              <Flex alignItems="center">
                <Text variant="h4">Product Growth Matrix</Text>
                <CustomTooltip
                  title={TooltipBox({
                    description: 'This page is filtered by subcategory.'
                  })}
                  stacklineTheme={theme}
                  placement="right"
                >
                  <TooltipIconComponent style={{ width: '16px', marginLeft: '8px' }} />
                </CustomTooltip>
              </Flex>
            }
            subtitleOverride={{
              subtitleDisplayName: subcategoryName,
              href: `/subcategory/${selectedSubcategoryId}${searchParams}${additionalParams}`
            }}
          />
          {isLoading ? (
            <SlSkeleton variant="rounded" width="100%" height={500} />
          ) : (
            <GrowthMatrixScatterPlot
              xAxisLabel="Conversion Index"
              yAxisLabel="Traffic Index"
              data={matrixData.filter(filterByQuadrant)}
            />
          )}
        </Flex>
        {productGrowthTable}
      </Flex>
    </BeaconPageContainer>
  );
};

export default ProductGrowthPage;
