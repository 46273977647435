/* eslint-disable react/prop-types */

import React from 'react';
import { Link } from 'react-router-dom';
import DeleteButton from 'src/components/common/Buttons/DeleteButton';
import ReduxStore from 'src/types/store/reduxStore';
import { trackRetailerProductPageVisit } from 'src/utils/mixpanel';
import NavAtlasBeaconGroup from 'src/components/Card/NavAtlasBeaconGroup';
import { decode } from 'html-entities';

interface Props {
  app: ReduxStore['app'];
  stacklineSku: string;
  gtin: string;
  brandId: number;
  brandName: string;
  categoryId: number;
  categoryName: string;
  retailerId: string;
  queryParams: ReduxStore['app']['queryParams'];
  showCardBackDeleteButton: boolean;
  secondaryDisplayTitle: string;
  availabilityStatusCode?: string;
  retailerSku?: { [index: string]: { sku: string } };
  subCategoryId?: number;
  subCategoryName?: string;
  parentBrandName?: string;
  upc?: string;
  retailerName?: string;
  onDelete?: () => {};
  shouldSupportJumpingToProduct: boolean;
  shouldDisableClick?: boolean;
  shouldShowNav?: boolean;
  passingFilterParams?: string;
}

const CardBack: React.FC<Props> = ({
  availabilityStatusCode = '',
  app: { name, targetUrl, shouldUseGTIN },
  brandId,
  brandName,
  categoryId,
  categoryName,
  retailerSku = {},
  retailerId,
  queryParams: { additionalParams, searchParams },
  secondaryDisplayTitle,
  stacklineSku,
  gtin,
  subCategoryId = -1,
  subCategoryName = '',
  showCardBackDeleteButton,
  parentBrandName = '',
  upc = '',
  onDelete,
  retailerName,
  shouldSupportJumpingToProduct,
  shouldDisableClick = false,
  shouldShowNav = false,
  passingFilterParams = ''
}) => {
  const preventVisit = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (shouldDisableClick) {
      e.preventDefault();
    }
  };

  return (
    <div className="entity-card__info-text">
      <div className="entity-card__small-metric-change">
        <div className="entity-card__block-text">
          <p className="entity-card__label">
            Product Id:{' '}
            <Link
              onClick={(e) => {
                preventVisit(e);
              }}
              to={`/product/${
                shouldUseGTIN ? gtin : stacklineSku
              }${searchParams}${additionalParams}${passingFilterParams}`}
            >
              {shouldUseGTIN ? gtin : stacklineSku}
            </Link>
          </p>
          {retailerName && <p className="entity-card__label">Retailer: {` ${retailerName}`}</p>}
          {shouldSupportJumpingToProduct && (
            <p className="entity-card__label">
              Retailer Sku:{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://${targetUrl}/api/utility/OpenRetailerProductPage?appName=${name}&retailerId=${retailerId}&stacklineSku=${stacklineSku}`}
                onClick={() => {
                  trackRetailerProductPageVisit(
                    `https://${targetUrl}/api/utility/OpenRetailerProductPage?appName=${name}&retailerId=${retailerId}&stacklineSku=${stacklineSku}`,
                    {
                      appName: name,
                      retailerId,
                      retailerSku: retailerSku[retailerId],
                      stacklineSku,
                      categoryId,
                      categoryName,
                      subCategoryId,
                      subCategoryName,
                      brandId,
                      brandName,
                      title: secondaryDisplayTitle
                    }
                  );

                  return true;
                }}
              >
                {retailerSku[retailerId]}
              </a>
            </p>
          )}
          {availabilityStatusCode !== '' && <p className="entity-card__label">Status: {availabilityStatusCode}</p>}
          {upc !== '' && retailerId === '63' ? (
            <p className="entity-card__label">
              UPC:{' '}
              <Link
                onClick={(e) => {
                  preventVisit(e);
                }}
                to={`/product/${stacklineSku}${searchParams}${additionalParams}`}
              >
                {upc}
              </Link>
            </p>
          ) : null}
          <p className="entity-card__label">
            Brand:{' '}
            <Link
              onClick={(e) => {
                preventVisit(e);
              }}
              to={`/brand/${brandId}${searchParams}${additionalParams}`}
            >
              {brandName}
            </Link>
          </p>
          {parentBrandName && <p className="entity-card__label">Manufacturer: {parentBrandName}</p>}
          <p className="entity-card__label">
            Category:{' '}
            <Link
              onClick={(e) => {
                preventVisit(e);
              }}
              to={`/category/${categoryId}${searchParams}${additionalParams}`}
            >
              {categoryName}
            </Link>
          </p>
          <p className="entity-card__label">
            Subcategory:{' '}
            <Link
              onClick={(e) => {
                preventVisit(e);
              }}
              to={`/subcategory/${subCategoryId}${searchParams}${additionalParams}`}
            >
              {subCategoryName}
            </Link>
          </p>
          <p className="entity-card__label" style={{ marginBottom: 0 }}>
            Title: {decode(secondaryDisplayTitle)}
          </p>

          {showCardBackDeleteButton && onDelete ? (
            <DeleteButton label="REMOVE" onClick={onDelete} style={{ marginTop: 20, maxWidth: 250 }} />
          ) : null}
        </div>
        {shouldShowNav && (
          <div
            style={{
              position: 'absolute',
              bottom: 10
            }}
          >
            <NavAtlasBeaconGroup stacklineSku={stacklineSku} retailerId={retailerId} />
          </div>
        )}
      </div>
    </div>
  );
};
export default CardBack;
