import React, { useRef } from 'react';
import MaskedInput, { MaskedInputProps } from 'src/components/BeaconRedesignComponents/common/StyledInput/MaskedInput';
import { percentageMask } from 'src/components/BeaconRedesignComponents/common/StyledInput/inputMasks';

const DELETE_KEY_CODE = 'Backspace';

type PercentageInputProps = Omit<MaskedInputProps, 'masker'>;
/**
 * Wrapper around a MaskedInput with a percentageMask as default.
 * Adds a function to allow for character deletion while maintaining the % sign.
 */
export const PercentageInput = (props: PercentageInputProps) => {
  const inputRef = useRef<HTMLInputElement>();

  const moveCursorBeforeSuffix = () => {
    const inputElement = inputRef.current;
    if (inputElement) {
      const { length } = inputElement.value;

      if (!(inputElement.selectionStart === 0 && inputElement.selectionEnd === length)) {
        inputElement.setSelectionRange(length - 1, length - 1);
        inputElement.focus();
      }
    }
  };

  return (
    <MaskedInput
      masker={percentageMask}
      onKeyDown={(e) => {
        if (e.code === DELETE_KEY_CODE) {
          moveCursorBeforeSuffix();
        }
      }}
      ref={inputRef}
      {...props}
    />
  );
};
