import LogRocket from 'logrocket';
import mixpanel from 'mixpanel-browser';
import queryString from 'qs';
import _get from 'lodash/get';

import ReduxStore from 'src/types/store/reduxStore';
import { getAppName } from './app';
import { AppName } from 'sl-api-connector';
import { store } from 'src/main';
import { MixpanelEvent } from 'src/utils/mixpanelTypes';

let isInitialized = false;
const appName = getAppName();

export function track(eventName: string, eventProps: { [key: string]: any }) {
  const { location } = window;
  const params = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });

  let state;

  try {
    state = store.getState();
  } catch (e) {
    // companyId will be missing
    console.warn(e);
  }

  const pathName = _get(location.pathname.split('/'), '1');
  const companyId = _get(state, ['user', 'session', 'companyId']);

  if (isInitialized) {
    mixpanel.track(`${appName} - ${eventName}`, {
      ...eventProps,
      ...params,
      pathName,
      retailerId: params.rid,
      companyId
    });
  }
}

/**
 *  Logs to mixpanel if in prod, but warns to the console if not.
 *  @param msg a description of the event to be logged
 *  @param obj an optional object of related data to log with the message
 *  @param eventName an optional name for the event, defaults to 'warning'
 * */
export function warn(msg: string, obj?: { [key: string]: any }, eventName: string = 'warning') {
  if (__PROD__) {
    track(eventName, obj || {});
  } else if (obj) {
    console.warn(msg, obj);
  } else {
    console.warn(msg);
  }
}
/**
 *  Logs to mixpanel if in prod, but errors in the console if not.
 *  @param msg a description of the event to be logged
 *  @param obj an optional object of related data to log with the message
 *  @param eventName an optional name for the Mixpanel event, defaults to 'error'
 * */
export function error(msg: string, obj?: { [key: string]: any }, eventName: string = 'error') {
  if (__PROD__) {
    track(eventName, { ...obj, message: msg } || { message: msg });
  } else if (obj) {
    console.error(msg, obj);
  } else {
    console.error(msg);
  }
}

/**
 * This function should be called when the application encounters an untenable state and cannot continue.  It will throw
 * an `Error` with the provided message and log the provided metadata object to the console if provided.  If in production,
 * it will trigger a Mixpanel event for the error and supply the provided metadata.
 *
 * @param msg a description of the error or unhandleable event that occurred
 * @param obj an optional object of related data to log with the message
 * @param eventName an optional name for the Mixpanel event, defaults to 'return panic'
 */
export function panic(msg: string, obj?: { [key: string]: any }, eventName: string = 'return panic'): never {
  if (__PROD__) {
    track(eventName, obj ? { ...obj, msg } : { msg });
  } else if (obj) {
    console.error(msg, obj);
  }

  LogRocket.captureException(new Error(msg));
  throw new Error(msg);
}

function trackCurrentLocation(location: Location, user: ReduxStore['user']) {
  let params = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const clientId = _get(user, ['config', 'vendor', 'BeaconClientId'], 0);
  if (clientId > 0) {
    params = { ...params, cid: clientId };
  }
  return {
    ...params,
    retailerId: params.rid,
    currentUrl: location.pathname,
    currentTab: params.tab,
    currentSubtab: params.subtab
  };
}

function trackCurrentComparison(location: Location) {
  const params = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const comparison = { currentComparisonType: undefined };
  if (params.ctype !== undefined) {
    comparison.currentComparisonType = params.ctype;
  }
  return comparison;
}

export function initializeMixpanel(token: string) {
  mixpanel.init(token);
  isInitialized = true;
}

export function isMixpanelInitialized() {
  return isInitialized;
}

export function setMixpanelUserIdentity({
  session,
  userConfig,
  app
}: {
  session: ReduxStore['user']['session'];
  userConfig: ReduxStore['user']['config'];
  app: ReduxStore['app'];
}) {
  const { firstName, lastName, company, email } = userConfig.profile;
  const { clientIP, companyId, divisionId } = session;
  mixpanel.identify(session.trackingId);

  mixpanel.register({
    FirstName: firstName,
    LastName: lastName,
    CompanyName: company,
    Email: email,
    Ip: clientIP
  });

  const userProperties: Record<string, any> = {
    $first_name: firstName,
    $last_name: lastName,
    $company_name: company,
    $email: email,
    $companyId: companyId,
    $divisionId: divisionId
  };

  if (app.name === AppName.Advertising) {
    userProperties.$access_level = userConfig.adAuditEnabled ? 'adAudit' : 'admanager';
  }

  mixpanel.people.set(userProperties);
}

export function trackLogIn() {
  track('log in', {});
}

export function trackLogOut() {
  track('log out', {});
}

/**
 * Used to mark potentially dead code for future deletion. When code could potentially be dead, but you aren't sure,
 * put a tombstone in it and check the logs if it was ever hit. This helps make dead code deletion safer.
 * Idea taken from https://www.bugsnag.com/blog/javascript-refactoring-with-bugsnag-and-tombstones
 *
 * @param msg a custom message for mixpanel, should include the file and line (e.g. operations.ts:113)
 * @param obj an optional object to log with the message.
 */
export function tombstone(msg: string, obj: {} = {}) {
  track(`tombstone: ${msg}`, obj);
}

export function trackRouteChange(previousLocation: Location, nextLocation: Location, user: ReduxStore['user']) {
  const params = queryString.parse(nextLocation.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const oldParams = queryString.parse(previousLocation.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const comparison = { previousComparisonType: undefined };
  if (oldParams.ctype !== undefined) {
    comparison.previousComparisonType = params.ctype;
  }

  track('page view', {
    ...trackCurrentLocation(nextLocation, user),
    ...trackCurrentComparison(nextLocation),
    previousUrl: previousLocation.pathname,
    previousTab: oldParams.tab,
    previousSubtab: oldParams.subtab,
    ...comparison
  });
}

export function trackExport(location: Location, opts = {}, user: ReduxStore['user']) {
  const clientId = _get(user, ['config', 'vendor', 'BeaconClientId'], 0);
  track('export', {
    ...opts,
    ...trackCurrentLocation(location, user),
    ...trackCurrentComparison(location),
    clientId
  });
}

export function trackFilters(location: Location, filters: any, user: ReduxStore['user']) {
  track('filter', {
    ...trackCurrentLocation(location, user),
    ...trackCurrentComparison(location),
    filters
  });
}

export function trackClearFilters(location: Location, user: ReduxStore['user']) {
  track('clear filters', {
    ...trackCurrentLocation(location, user)
  });
}

export const trackError = (url: string, err: Error) => {
  track('error page', {
    url,
    message: err.message,
    backtrace: err.stack || '',
    lineNumber: (err as any).lineNumber
  });
  LogRocket.captureException(err);
};

export const trackNoSearchResults = (requestBody: { [key: string]: any }, user: ReduxStore['user']) =>
  track('no search results', { requestBody, ...trackCurrentLocation(_get(window, 'location'), user) });

export const trackExportTable = (url: string, data = {}) => track('export table API request', { url, ...data });

export const trackExportDownload = (exportId: string, data = {}) =>
  track('downloaded an export', { exportId, ...data });

export const trackRetailerProductPageVisit = (url: string, data = {}) => {
  try {
    track('retailer product page visit', { url, ...data });
  } catch (e) {
    trackError(url, e as Error);
  }
};

export const trackTheDataSwitching = (
  eventName: string,
  location: Location,
  user: ReduxStore['user'],
  dataToShow: { [key: string]: string }
) => {
  track(eventName, {
    ...trackCurrentLocation(location, user),
    ...trackCurrentComparison(location),
    dataToShow
  });
};

export const trackMixpanelEvent = (event: MixpanelEvent) => {
  const { eventName } = event;
  const data = _get(event, 'data');

  track(eventName, data);
};
