import types from './types';

function receiveUserConfig(json) {
  return {
    type: types.RECEIVE_USER_CONFIG,
    userConfig: {
      ...json.data.userConfig,
      reviews: {
        highRiskStars: ['5', '4'],
        highRiskKeywords: ['good', 'clamping']
      }
    },
    userSession: json.data.session,
    isStacklineUser: false,
    receivedAt: Date.now()
  };
}

function clearUserConfig() {
  return {
    type: types.CLEAR_USER_CONFIG,
    receivedAt: Date.now()
  };
}

function saveUserProfile(profile) {
  return {
    type: types.SAVE_USER_PROFILE,
    profile,
    receivedAt: Date.now()
  };
}

function saveUserBilling(billing) {
  return {
    type: types.SAVE_USER_BILLING,
    billing,
    receivedAt: Date.now()
  };
}

function saveUserTeam(team) {
  return {
    type: types.SAVE_USER_TEAM,
    team,
    receivedAt: Date.now()
  };
}

function setTranslation(translationEnabled) {
  return {
    type: types.SET_USER_TRANSLATION,
    translationEnabled,
    receivedAt: Date.now()
  };
}

function requestUserInfo() {
  return {
    type: types.REQUEST_USER_INFO,
    receivedAt: Date.now()
  };
}

const saveAdCampaigns = (adCampaigns) => ({ type: types.RECEIVE_AD_CAMPAIGNS, adCampaigns });

const setUserRedirectionUrl = (redirectionUrl) => ({ type: types.SET_USER_REDIRECTION_URL, redirectionUrl });

const updateUserDisplayColumns = (displayColumns, platformType) => ({
  type: types.UPDATE_USER_DISPLAY_COLUMNS,
  displayColumns,
  platformType
});

const setAdAuditUserPreference = ({ hasRequestedDemo, nextReminder, hasDisabledPrompt }) => ({
  type: types.SET_AD_AUDIT_USER_PREFERENCE,
  payload: { hasRequestedDemo, nextReminder, hasDisabledPrompt }
});

const setSecondaryRetailerIds = ({ secondaryRetailerIds }) => ({
  type: types.SET_USER_SECONDARY_RETAILERS,
  payload: { secondaryRetailerIds }
});

const requestSecondaryRetailers = () => ({
  type: types.REQUEST_SECONDARY_RETAILERS
});

const saveLastUpdated = () => ({
  type: types.SAVE_LAST_UPDATED
});

export default {
  clearUserConfig,
  receiveUserConfig,
  requestUserInfo,
  saveUserProfile,
  setTranslation,
  saveUserBilling,
  saveUserTeam,
  saveAdCampaigns,
  setUserRedirectionUrl,
  updateUserDisplayColumns,
  setAdAuditUserPreference,
  setSecondaryRetailerIds,
  requestSecondaryRetailers,
  saveLastUpdated
};
