import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import { useAppSelector, useMetricFormatter, MetricFormatterFn } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import colors from 'src/utils/colors';
import { AdFrequencyDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import { getChartMetricsByDropdownMetric, convertAdFrequencyDataToCsv } from './utils';
import { createNonComparisonLegendDivs } from 'src/utils/chartUtils';
import { AdFrequencyBarMetric, AdFrequencyResponse, AdFrequencyMetrics } from './types';
import { getSimpleTooltipDiv } from 'src/components/EntityPage/Renderer/tooltipDiv';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import _sum from 'lodash/sum';
import { MAX_ADS_AGGREGATION, AD_FREQUENCY_EVENT } from './constants';
import ColumnSplineChart from 'src/components/Charts/ColumnSpline/ColumnSplineChart';

interface AdFrequencyBarChartProps {
  metric: AdFrequencyDropdownOption;
  data: AdFrequencyResponse;
}

/**
 * Creates a label for one of the items in the
 * chart legend.
 * @param name The name of the legend metric. e.g. "Impressions"
 * @param param1 Chart data for the selected metric
 * @param formatMetric Formatter function
 * @returns A metric label. e.g. "Impressions (51.81M)"
 */
export const createLegendLabel = (
  name: string,
  { barChartMetrics, totalMetricRate }: AdFrequencyMetrics,
  formatMetric: MetricFormatterFn
) => {
  // Bar chart metric, format as volume
  if ((Object.values(AdFrequencyBarMetric) as string[]).includes(name)) {
    const metricTotal = _sum(barChartMetrics);
    const metricTotalDisplay = formatMetric(metricTotal, METRICTYPE.VOLUME, {
      decimalPlaces: 2,
      showFullValue: false
    });

    return `${name} (${metricTotalDisplay})`;
  }

  // Line chart metric, format as percentage
  const totalRateDisplay = formatMetric(totalMetricRate, METRICTYPE.PERCENT, { decimalPlaces: 2 });
  return `${name} (${totalRateDisplay})`;
};

/**
 * Generates a tooltip label for a data point, including a formatted metric value and label.
 *
 * @param {Object} options - The input object containing the tooltip label and data to format.
 * @param {string} options.label - The label for the tooltip. e.g. "Impressions"
 * @param {number} options.value - The value for the tooltip. e.g. 561100
 * @param {boolean} [options.percentage=false] - Whether the value should be formatted as a percentage.
 * @param {MetricFormatterFn} options.formatMetric - The function to use to format the metric value.
 * @returns {string} The formatted tooltip label. e.g. "Impressions: 561.1K"
 */
export const createTooltipLabel = ({
  label,
  value,
  percentage = false,
  formatMetric
}: {
  label: string;
  value: number;
  percentage?: boolean;
  formatMetric: MetricFormatterFn;
}) => {
  return `${label}: ${formatMetric(percentage ? value : value, percentage ? METRICTYPE.PERCENT : METRICTYPE.VOLUME, {
    decimalPlaces: 2,
    showFullValue: percentage
  })}`;
};

/**
 * Displays column data as well as a line graph
 * overlayed on top.
 */
const AdFrequencyBarChart = ({ metric, data }: AdFrequencyBarChartProps) => {
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const { barMetric, lineMetric } = useMemo(() => getChartMetricsByDropdownMetric(metric), [metric]);
  const formatMetric = useMetricFormatter();

  if (!data) {
    return <GenericChartLoading />;
  }
  const columnSeries: Partial<Highcharts.SeriesColumnOptions> = {
    name: barMetric
  };

  const lineSeries: Partial<Highcharts.SeriesSplineOptions> = {
    name: lineMetric,
    color: colors.darkBlue
  };

  const buttons = [
    [
      {
        displayName: 'Awareness',
        eventId: AdFrequencyDropdownOption.Awareness,
        eventName: AD_FREQUENCY_EVENT,
        isSelected: metric === AdFrequencyDropdownOption.Awareness
      },
      {
        displayName: 'Engagement',
        eventId: AdFrequencyDropdownOption.Engagement,
        eventName: AD_FREQUENCY_EVENT,
        isSelected: metric === AdFrequencyDropdownOption.Engagement
      },
      {
        displayName: 'Conversion',
        eventId: AdFrequencyDropdownOption.Conversion,
        eventName: AD_FREQUENCY_EVENT,
        isSelected: metric === AdFrequencyDropdownOption.Conversion
      }
    ],
    [
      {
        isSelected: true,
        displayName: 'DSP Ad Frequency'
      }
    ]
  ];

  const chartProps: Highcharts.Options = {
    chart: {
      type: 'column',
      spacingLeft: 8
    },
    title: {
      y: -95 // Move the title up to make space for the insight text
    },
    exporting: {
      enabled: true,
      buttons
    },
    legend: {
      labelFormatter() {
        const legendDivs: string[] = [];

        createNonComparisonLegendDivs({
          chartDisplayTimePeriod: mainTimePeriod,
          mainLegendMetricValue: { value: createLegendLabel(this.name, data[metric], formatMetric) },
          isSameMetricComparison: true,
          metricsDataByTimeSeries: null,
          legendDivs
        });
        return legendDivs[0];
      }
    },
    xAxis: {
      title: {
        text: 'DSP Ad Frequency'
      },
      // We are showing data for up to 25 ad exposures, and everything past 25 is aggregated as "25+"
      categories: Array.from(Array(MAX_ADS_AGGREGATION).keys()).map(
        (num) => `${num < MAX_ADS_AGGREGATION - 1 ? num + 1 : `${MAX_ADS_AGGREGATION}+`}`
      )
    },
    tooltip: {
      enabled: true,
      shared: true,
      formatter() {
        const barValue = this.points[0].y;
        const lineValue = this.points[1].y;
        return getSimpleTooltipDiv([
          {
            label: createTooltipLabel({ label: barMetric, value: barValue, formatMetric })
          },
          {
            label: createTooltipLabel({ label: lineMetric, value: lineValue, percentage: true, formatMetric }),
            color: colors.darkBlue
          }
        ]);
      }
    },
    yAxis: [
      {
        labels: {
          formatter() {
            return `${formatMetric(this.value as number, METRICTYPE.VOLUME, {
              decimalPlaces: 2,
              showFullValue: false
            })}`;
          }
        }
      },
      {
        labels: {
          enabled: true,
          formatter() {
            return `${formatMetric(this.value as number, METRICTYPE.PERCENT, { decimalPlaces: 2 })}`;
          }
        }
      }
    ]
  };

  return (
    <ColumnSplineChart
      columnData={data[metric].barChartMetrics}
      splineData={data[metric].lineChartMetrics}
      columnSeriesOptions={columnSeries}
      splineSeriesOptions={lineSeries}
      chartPropsOverride={chartProps}
      convertSeriesToDelimitedData={() =>
        convertAdFrequencyDataToCsv({ data, dateRange: mainTimePeriod.displayName, metric })
      }
    />
  );
};

export default AdFrequencyBarChart;
