import { Option } from 'funfix-core';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import ReduxStore from 'src/types/store/reduxStore';
import { MetricField } from 'src/types/application/types';
import { Widget } from 'src/types/application/widgetTypes';
import { buildMultiGroupByTopEntitiesChartWidgetConfig } from 'src/components/Layout/LayoutUtil';
import { panic } from 'src/utils/mixpanel';
import { getWidgetBarChartStyles } from 'src/components/BeaconRedesignComponents/styles/styles';
import { BEACON_GRID_TOP_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

const allowHorizontalScroll = {
  horizontalScrolling: {
    enabled: true,
    step: 3
  },
  xAxis: [{ min: 0, max: 9 }],
  enableSwitchingGroupBy: true
};

const BEACON_SHORTAGES_METRIC_FIELD_NAMES = [
  'invoiceAmount',
  'updatedShortageAmountBeforeDispute',
  'skusWithShortages',
  'shortageRatio',
  'disputeNeedActionAmount',
  'disputeUnderReviewAmount',
  'disputeWonAmount',
  'disputeLostAmount',
  'disputeSubmittedAmount'
];

export const getShortagesGroupByMapping = (appName: string, indexName: string, metricType: string) => {
  const allGroupByFieldNames = ['categoryId', 'subCategoryId', 'brandId', 'stacklineSku'];
  const allGroupByFieldsForIndex: {
    groupByFieldName: string;
    aggregationFieldNames: string[];
    indexName: string;
  }[] = [];
  allGroupByFieldNames.forEach((groupByFieldName) => {
    if (INDEX_FIELDS.hasField(appName, indexName, groupByFieldName)) {
      allGroupByFieldsForIndex.push({
        groupByFieldName,
        aggregationFieldNames: [metricType],
        indexName
      });
    }
  });
  return allGroupByFieldsForIndex;
};

const getGroupByFieldConfigByEntityType = ({
  app,
  indexName,
  metricType
}: {
  app: ReduxStore['app'];
  indexName: string;
  metricType: string;
}) => {
  const allGroupByFieldsForIndex = getShortagesGroupByMapping(app.name, indexName, metricType);

  const groupByFieldConfigsByEntityType: {
    [key: string]: {
      groupByFieldName: string;
      aggregationFieldNames: string[];
      indexName: string;
    }[];
  } = {
    client: allGroupByFieldsForIndex,
    company: allGroupByFieldsForIndex,
    category: allGroupByFieldsForIndex.filter((x) => x.groupByFieldName !== 'categoryId'),
    subcategory: allGroupByFieldsForIndex.filter(
      (x) => x.groupByFieldName !== 'categoryId' && x.groupByFieldName !== 'subCategoryId'
    ),
    brand: allGroupByFieldsForIndex.filter((x) => x.groupByFieldName !== 'brandIds'),
    product: allGroupByFieldsForIndex.filter(
      (x) =>
        x.groupByFieldName !== 'stacklineSku' &&
        x.groupByFieldName !== 'brandIds' &&
        x.groupByFieldName !== 'categoryId' &&
        x.groupByFieldName !== 'subCategoryId'
    ),
    segment: allGroupByFieldsForIndex,
    businessunit: allGroupByFieldsForIndex
  };

  return groupByFieldConfigsByEntityType;
};

export const buildShortagesTopEntitiesChart = ({
  indexName,
  entity,
  app
}: {
  indexName: string;
  entity: { type: string };
  app: ReduxStore['app'];
  weekIdField: MetricField;
}): Widget => {
  const groupByFieldConfigsByEntityType = getGroupByFieldConfigByEntityType({
    app,
    indexName,
    metricType: 'invoiceAmount'
  });

  const groupByFieldConfigsForCurrentEntity = Option.of(groupByFieldConfigsByEntityType[entity.type]).getOrElseL(() => {
    return panic(`No group-by-field configs found for current entity type: "${entity.type}"`);
  });

  const aggregationFieldNames = BEACON_SHORTAGES_METRIC_FIELD_NAMES;
  const topEntitiesMetricFields = aggregationFieldNames.map((fieldName) => {
    const field = INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type);
    return {
      ...field,
      displayName: field.displayName
    };
  });

  const weekIdFieldOverride = INDEX_FIELDS.getField(app.name, indexName, 'weekId', entity.type);

  return buildMultiGroupByTopEntitiesChartWidgetConfig(
    app,
    indexName,
    entity,
    groupByFieldConfigsForCurrentEntity.map((config) => ({
      ...config,
      aggregationFieldNames
    })),
    10,
    topEntitiesMetricFields,
    weekIdFieldOverride,
    {
      view: {
        chartPropsOverride: { enableSwitchingGroupBy: true, ...allowHorizontalScroll },
        anchorName: 'multiGroupByTopEntitiesChart',
        container: {
          style: getWidgetBarChartStyles({
            marginBottom: `${BEACON_GRID_TOP_SPACING}px`
          })
        }
      }
    }
  );
};
