import _omit from 'lodash/omit';

import * as types from './types';

const initialState = {};

const billingInformationReducer = (globalState = initialState, action) => {
  const reducer = {
    [types.INIT_GENERIC_FORM]: (state) => ({
      ...state,
      [action.formName]: { isSubmitted: false, formState: action.initialState }
    }),
    [types.SET_GENERIC_FORM_VALUE]: (state) => ({
      ...state,
      [action.formName]: {
        ...state[action.formName],
        formState: {
          ...state[action.formName].formState,
          [action.name]: action.value
        }
      }
    }),
    [types.SET_GENERIC_FORM_IS_SUBMITTED]: (state) => ({
      ...state,
      [action.formName]: { ...state[action.formName], isSubmitted: action.isSubmitted }
    }),
    [types.SET_GENERIC_FORM_IS_SUBMITTING]: (state) => ({
      ...state,
      [action.formName]: {
        ...state[action.formName],
        isSubmitting: action.isSubmitting
      }
    }),
    [types.DELETE_FORM]: (state) => _omit(state, action.formName)
  }[action.type];

  return reducer ? reducer(globalState) : globalState;
};

export default billingInformationReducer;
