import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import _cloneDeep from 'lodash/cloneDeep';
import { SearchGridConstants } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConstants';
import { TermFilter } from 'sl-api-connector/types';
import { DataForGroupByField } from 'src/components/AdManager/Search/Models/DataForGroupByField';
import { GridIntermediateData } from 'src/components/AdManager/Search/Models/GridIntermediateData';

export class SearchBarConditionsForAdvancedSearchComputer {
  // eslint-disable-next-line class-methods-use-this
  public getSearchSideBarConditionsForAdvancedSearchRequest(
    searchBarConditions: ISearchFilterConditions,
    groupByField: any,
    dataForGroupByField: DataForGroupByField
  ) {
    const conditionsToReturn = _cloneDeep(searchBarConditions);
    if (groupByField.name === SearchGridConstants.GROUP_BY_PORTFOLIO_ID) {
      // Remove some filters before sending to server, since filtering them is handled at the client side (UI)
      const fieldNamesToRemove = [
        'portfolioNameFuzzy',
        'excludedPortfolioNameFuzzy',
        'statusDerived',
        'automationStrategyId'
      ];
      conditionsToReturn.termFilters = conditionsToReturn.termFilters.filter(
        (filter) => !fieldNamesToRemove.includes(filter.fieldName)
      );

      const gridIntermediateData: GridIntermediateData = dataForGroupByField.portfolioGridIntermediateData;
      if (gridIntermediateData.hasAnyMetadataFilters) {
        const portfolioIds: string[] = gridIntermediateData.data.map((x) => x.id);
        SearchBarConditionsForAdvancedSearchComputer.addOrUpdateTermFilter(conditionsToReturn.termFilters, {
          fieldName: 'portfolioId',
          condition: 'must',
          values: portfolioIds
        });
      }
    }
    return conditionsToReturn;
  }

  private static addOrUpdateTermFilter(termFilters: TermFilter[], termFilterToAdd: TermFilter) {
    termFilters.push(termFilterToAdd);
  }
}
