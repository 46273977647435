export const INIT_AD_BUILDER = 'INIT_AD_BUILDER' as const;

export const SET_PLATFORM = 'SET_PLATFORM' as const;
export const SET_PLATFORM_SETTINGS = 'SET_PLATFORM_SETTINGS' as const;
export const SET_SETUP = 'SET_SETUP' as const;
export const SET_TARGETS = 'SET_TARGETS' as const;
export const SET_CAMPAIGN_BUDGET = 'SET_CAMPAIGN_BUDGET' as const;
export const SET_CAMPAIGN_LIFETIME_BUDGET = 'SET_CAMPAIGN_LIFETIME_BUDGET' as const;
export const CLEAR_CAMPAIGN_LIFETIME_BUDGET = 'CLEAR_CAMPAIGN_LIFETIME_BUDGET' as const;
export const SET_FEATURED = 'SET_FEATURED' as const;
export const SET_CREATIVE = 'SET_CREATIVE' as const;
export const SET_CAMPAIGN_TYPE = 'SET_CAMPAIGN_TYPE' as const;
export const SET_FEATURED_PRODUCTS_FILTERS = 'SET_FEATURED_PRODUCTS_FILTERS' as const;
export const SET_INSTACART_PARAMS = 'SET_INSTACART_PARAMS' as const;

// Check/Uncheck featured product checkbox
export const TOGGLE_FEATURED_PRODUCT_SELECT = 'TOGGLE_FEATURED_PRODUCT_SELECT' as const;
export const ClEAR_FEATURED = 'ClEAR_FEATURED' as const;

export const TOGGLR_OR_ADD_UPC_SELECTED = 'TOGGLR_OR_ADD_UPC_SELECTED' as const;
export const ADD_AUTO_CAMPAIGN_PRODUCTS = 'ADD_AUTO_CAMPAIGN_PRODUCTS' as const;
export const ADD_AUTO_BID_MAPPING = 'ADD_AUTO_BID_MAPPING' as const;
export const CLEAR_UPC = 'CLEAR_UPC' as const;

export const SET_TARGET_TYPE_ID = 'SET_TARGET_TYPE_ID' as const;
export const TOGGLE_TARGET_ENTITY_SELECTED = 'TOGGLE_TARGET_ENTITY_SELECTED' as const;
export const SET_TARGET_SELECTED_ENTITIES = 'SET_TARGET_SELECTED_ENTITIES' as const;
export const SAVE_KEYWORD_LIST = 'SAVE_KEYWORD_LIST' as const;
export const UPDATE_KEYWORD_LIST = 'UPDATE_KEYWORD_LIST' as const;
export const SET_TARGET_ENTITIES_LOADING = 'SET_TARGET_ENTITIES_LOADING' as const;
export const SET_TARGET_FILTER = 'SET_TARGET_FILTER' as const;
export const REPLACE_AD_CAMPAIGN_BUILDER_STATE = 'REPLACE_AD_CAMPAIGN_BUILDER_STATE' as const;
export const BULK_SET_TARGET_ENTITIES_SELECTED = 'BULK_SET_TARGET_ENTITIES_SELECTED' as const;
export const SET_UPLOADED_TARGET_KEYWORDS = 'SET_UPLOADED_TARGET_KEYWORDS' as const;
export const SET_SELECTED_FEATURED_PRODUCTS = 'SET_SELECTED_FEATURED_PRODUCTS' as const;
export const SET_UPLOADED_FEATURED_PRODUCTS = 'SET_UPLOADED_FEATURED_PRODUCTS' as const;
export const SET_TARGET_AUDIENCE = 'SET_TARGET_AUDIENCE' as const;

export const CAMPAIGN_TYPES = {
  SponsoredBrandsVideo: 'sponsoredBrandsVideo', // amazon
  SponsoredProducts: 'sponsoredProducts',
  SponsoredBrands: 'sponsoredBrands', // amazon
  SponsoredDisplay: 'sponsoredDisplay',
  SponsoredWalmartVideo: 'video',
  SponsoredBrandAmplifier: 'sba',
  openAuction: 'openAuction'
} as const;
