import React from 'react';
import { useSnackbar } from 'src/utils/Hooks/typedHooks';

/**
 * Props required for the wrapped component with access to the showSnackbar function.
 */
interface WrappedComponentProps {
  /**
   * Function to display a snackbar message.
   * @param message - The message to display in the snackbar.
   * @param type - The type or variant of the snackbar (e.g., 'success', 'error').
   */
  showSnackbar: (message: string, type: string) => void;
}

/**
 * Higher-Order Component (HOC) for providing access to the showSnackbar function.
 * @param Component - A React component that needs access to the showSnackbar function as a prop.
 * @returns The same component with a showSnackbar function as a prop.
 */
export function withSnackbar(Component: React.ComponentType<WrappedComponentProps>) {
  return function WithSnackbar(props) {
    const snackbar = useSnackbar();

    return <Component {...props} showSnackbar={snackbar.showSnackbar} />;
  };
}
