import React, { useMemo } from 'react';
import DonutChartV2 from 'src/components/BeaconRedesignComponents/DonutChart/DonutChartV2';
import DonutChartLoading from 'src/components/Charts/Donut/DonutChartLoading';
import { useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { getVolumeStringParts } from 'src/utils/stringFormatting';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

export interface TotalTrafficDonutChartProps {
  totalClicks: number;
  organicClicks: number;
  paidTraffic: number;
  otherTraffic: number;
  dspClicks: number;
  loading: boolean;
}

/**
 * Displays the donut chart for total traffic with
 * each traffic type as a percentage
 */
const TotalTrafficDonutChart = ({
  dspClicks,
  loading,
  organicClicks,
  otherTraffic,
  paidTraffic,
  totalClicks
}: TotalTrafficDonutChartProps) => {
  const formatMetric = useMetricFormatter();

  const formattedTotalClicks = useMemo(() => {
    return `${formatMetric(totalClicks, METRICTYPE.VOLUME, { showFullValue: false, decimalPlaces: 1 })}`;
  }, [totalClicks, formatMetric]);

  return loading ? (
    <DonutChartLoading />
  ) : (
    <DonutChartV2
      getData={[
        {
          fieldId: 'organicClicks',
          name: 'Organic Clicks',
          y: organicClicks
        },
        {
          fieldId: 'paidTraffic',
          name: 'Paid Traffic',
          y: paidTraffic
        },
        {
          fieldId: 'otherTraffic',
          name: 'Other Traffic',
          y: otherTraffic
        },
        {
          fieldId: 'dspClicks',
          name: 'DSP Clicks',
          y: dspClicks
        }
      ]}
      title={
        <Flex gap="sm" alignItems="center" justifyContent="center">
          <Text
            sx={{
              fontSize: '48px',
              fontWeight: 'bold'
            }}
          >
            {getVolumeStringParts(formattedTotalClicks).value}
          </Text>
          <Text variant="h4">{getVolumeStringParts(formattedTotalClicks).label}</Text>
        </Flex>
      }
    />
  );
};

export default TotalTrafficDonutChart;
