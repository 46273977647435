import React from 'react';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { EntityCategoryDatum } from 'src/components/BeaconRedesignComponents/TopEntityChartV2/types';

/**
 * Used in the TopEntityChartV2 (Beacon Pro column chart) to create simple x axis labels that don't otherwise conform to the default behavior.
 * @param xAxisCategory
 * @returns a generic x axis label component for the top entity chart without links, click handlers, etc.
 */
export const GenericXAxisLabel = ({ xAxisCategory }: { xAxisCategory: EntityCategoryDatum }) => {
  return (
    <div
      style={{
        fontSize: '12px',
        textAlign: 'center',
        width: '90px',
        wordBreak: 'break-word',
        height: '70px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      <Text variant="body4" truncateLines={4} title={xAxisCategory.name}>
        {xAxisCategory.name}
      </Text>
    </div>
  );
};
