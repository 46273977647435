import React, { useState } from 'react';
import { TableCell, TableRow, IconButton, Menu, MenuItem, Modal, Box } from '@mui/material';
import { MoreVert as MoreVertIcon, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { MediaData } from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdMediaTable/AdMediaTable';
import { getAppStage } from 'src/utils/app';
import CloseIcon from '@mui/icons-material/Close';

interface MediaRowProps {
  data: MediaData;
  handleAction: (data: MediaData, actionType: 'delete' | 'downloadCaption' | 'downloadVideo') => void;
  handleSelect?: (mediaId: number) => void;
  activeTab: 'PENDING' | 'FAILED' | 'AVAILABLE';
  selected: boolean;
}

const FailCell = ({ details }: { details: string }) => {
  try {
    const errorData = JSON.parse(details);
    if (!errorData) {
      return null;
    }

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {errorData.map(({ message, code }, idx) => (
          <span key={code}>{`${idx + 1}. ${message}`}</span>
        ))}
      </div>
    );
  } catch (e) {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <span>{`${details}`}</span>
      </div>
    );
  }
};

export const MediaRow: React.FC<MediaRowProps> = ({ data, handleAction, handleSelect, selected, activeTab }) => {
  const {
    name,
    thumbnail,
    videoUrl,
    autoGenerated,
    mediaId,
    details,
    stackLineMediaDetails = {
      mediaDescription: null,
      createdTime: null
    }
  } = data;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const { mediaDescription, createdTime } = stackLineMediaDetails;

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const deleteMedia = () => handleAction(data, 'delete');
  const getCaption = () => handleAction(data, 'downloadCaption');
  const getVideo = () => handleAction(data, 'downloadVideo');
  const selectMedia = () => handleSelect && handleSelect(mediaId);

  const showMediaCell = activeTab !== 'FAILED';
  const showFailCell = activeTab === 'FAILED';
  const isAvailableTab = activeTab === 'AVAILABLE';

  const isDev = getAppStage() === 'dev';

  return (
    <TableRow>
      {handleSelect && isAvailableTab && (
        <TableCell>
          <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={selectMedia}>
            {selected ? (
              <RadioButtonChecked style={{ color: '#46a8f6' }} />
            ) : (
              <RadioButtonUnchecked style={{ color: '#46a8f6' }} />
            )}
          </IconButton>
        </TableCell>
      )}
      {showMediaCell && (
        <TableCell>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <img
            src={thumbnail}
            alt={name}
            style={{ width: '84px', height: '53px', cursor: 'pointer' }}
            onClick={handleOpen}
          />
        </TableCell>
      )}
      <TableCell>{name}</TableCell>
      <TableCell style={{ maxWidth: 600, overflowWrap: 'break-word' }}>{mediaDescription || `--`}</TableCell>
      {showFailCell && (
        <TableCell>
          <FailCell details={details} />
        </TableCell>
      )}
      <TableCell>{new Date(createdTime).toLocaleDateString()}</TableCell>
      <TableCell>{autoGenerated ? 'Auto Generated' : 'User Uploaded'}</TableCell>
      {(isAvailableTab || isDev) && (
        <TableCell>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
            disableRipple
            sx={{
              '&.MuiButtonBase-root': {
                borderRadius: 1,
                rotate: '90deg',
                width: 32,
                height: 32,
                '&:hover': {
                  backgroundColor: 'rgb(4 40 73)',
                  color: 'white'
                }
              }
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{
              '& .MuiList-root': {
                padding: 0
              },
              '& .MuiMenuItem-root': {
                fontWeight: 500
              }
            }}
          >
            {isDev && <MenuItem onClick={deleteMedia}>Delete media</MenuItem>}
            {isAvailableTab && <MenuItem onClick={getVideo}>Download video</MenuItem>}
            {isAvailableTab && <MenuItem onClick={getCaption}>Download caption</MenuItem>}
          </Menu>
        </TableCell>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 0
          }}
        >
          <CloseIcon
            style={{
              position: 'absolute',
              borderRadius: '50%',
              fill: 'rgb(255, 255, 255)',
              height: 35,
              width: 35,
              translate: '50% -50%',
              background: 'rgb(5, 40, 73)',
              right: 0,
              cursor: 'pointer',
              zIndex: 2,
              padding: 6
            }}
            onClick={handleClose}
          />
          <video style={{ width: '100%', height: '100%' }} controls>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      </Modal>
    </TableRow>
  );
};
