import { Option } from 'funfix-core';
import { AppName, Entity } from 'sl-api-connector/types';

import { propEq, filterNils } from 'src/utils/fp';
import MultiMetricTrendChart from 'src/components/EntityPage/TrendChart/MultiMetricTrendChart';
import {
  buildTrendWidgetConfig,
  buildMultiGroupByTopEntitiesChartWidgetConfig
} from 'src/components/Layout/LayoutUtil';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import ReduxStore from 'src/types/store/reduxStore';
import { MetricField } from 'src/types/application/types';
import { Widget } from 'src/types/application/widgetTypes';
import { panic } from 'src/utils/mixpanel';
import _get from 'lodash/get';
import { getAppName, shouldShowLicenseAtlas } from '../../../utils/app';
import { checkIsAllowedParentPlatform, getRetailerId } from 'src/utils/browser';
import { getWidgetBarChartStyles } from 'src/components/BeaconRedesignComponents/styles/styles';
import { BEACON_KEY_METRIC_CARD_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

const allowHorizontalScroll = {
  horizontalScrolling: {
    enabled: true,
    step: 3
  },
  xAxis: [{ min: 0, max: 9 }],
  enableSwitchingGroupBy: true
};

const BEACON_ADVERTISING_METRIC_FIELD_NAMES = [
  'spend',
  'clicks',
  'impressions',
  'clickThroughRate',
  'costPerClick',
  'costPerMilli',
  'costPerAcquisition',
  'sales',
  'conversionRate',
  'unitsSold',
  'returnOnAdSpend'
];

const BEACON_DISPLAY_ADVERTISING_METRIC_FIELD_NAMES = [
  'impressions',
  'clickThroughRate',
  'spend',
  'costPerClick',
  'costPerMilli',
  'costPerAcquisition',
  'atc',
  'dpv',
  'sales',
  'conversionRate',
  'purchases',
  'unitsSold',
  'returnOnAdSpend'
];

const ATLAS_ADVERTISING_METRIC_FIELD_NAMES = ['adClicks', 'adSpend'];

const advertisingMetricDisplayNamesByFieldName: { [key: string]: string } = {
  returnOnAdSpend: 'ROAS',
  costPerClick: 'CPC',
  costPerAcquisition: 'CPA',
  searchKeyword: 'Keywords with Advertising',
  stacklineSku: 'Products with Advertising'
};

export const getMultiGroupChartIndexByField = (fieldName: string, entityType: string | undefined) => {
  const defaultIndexName = 'adCampaignAdGroupProductTargetDailyMetrics';
  if (entityType && ['product', 'adTarget', 'brand'].includes(entityType)) {
    return defaultIndexName;
  }
  switch (fieldName) {
    case 'campaignId':
    case 'derivedCampaignType':
      return 'adCampaignDailyMetrics';
    case 'targetingText':
      return 'adCampaignAdGroupTargetDailyMetrics';
    case 'entityId':
      return 'adEntityDailyMetrics';
    case 'categoryId':
    case 'subCategoryId':
    case 'brandId':
    case 'stacklineSku':
      return 'adCampaignAdGroupProductDailyMetrics';
    case 'weekDay':
      if (entityType && ['adGroup'].includes(entityType)) {
        return 'adCampaignAdGroupDailyMetrics';
      }
      return 'adCampaignDailyMetrics';
    default:
      return defaultIndexName;
  }
};

export const buildAdvertisingMultiMetricTrendChart = ({
  indexName,
  entity,
  groupByFieldName = 'weekId',
  app,
  weekIdField,
  showOnlyMainSeries = true
}: {
  showOnlyMainSeries: boolean;
  indexName: string;
  entity: { type: string };
  groupByFieldName?: string;
  app: ReduxStore['app'];
  weekIdField: MetricField;
}) => {
  const keywordGroupByFieldName = app.name === AppName.Atlas ? 'searchTerm' : 'searchKeyword';
  const metricFieldNames = filterNils([
    ...(app.name === AppName.Atlas ? ATLAS_ADVERTISING_METRIC_FIELD_NAMES : BEACON_ADVERTISING_METRIC_FIELD_NAMES),
    keywordGroupByFieldName,
    entity.type !== 'product' ? 'stacklineSku' : null
  ]);

  const name = 'multiMetricTrendChart';
  const widget = buildTrendWidgetConfig(app, indexName, entity, groupByFieldName, metricFieldNames, weekIdField, {
    CustomComponent: MultiMetricTrendChart,
    name,
    view: {
      name,
      chartPropsOverride: {
        title: { text: '' }
      },
      showOnlyMainSeries,
      hideSubtitle: true
    }
  });

  // Add in conditions for the cardinality metrics to only include documents that have non-zero of the entity whose
  // cardinality is being queried.
  ['stacklineSku', keywordGroupByFieldName].forEach((aggregationFieldName) => {
    const aggregationFieldConfig: any | undefined = widget.data.configByGroupByFieldName[
      groupByFieldName
    ].aggregationFields.find(propEq('name', aggregationFieldName));
    if (!aggregationFieldConfig) {
      return;
    }

    aggregationFieldConfig.conditions = {
      rangeFilters: [
        {
          fieldName: app.name === AppName.Atlas ? 'adClicks' : 'clicks',
          minValue: 1
        }
      ]
    };
  });

  // Override the display names of the created metric fields
  widget.view.metricFields = widget.view.metricFields.map((metricField: MetricField) => ({
    ...metricField,
    displayName: advertisingMetricDisplayNamesByFieldName[metricField.name!] || metricField.displayName
  }));

  return widget;
};

export const getAdvertisingGroupByMapping = (
  appName: string,
  indexName: string,
  metricType: string,
  entity?: Entity
) => {
  let allGroupByFieldNames = [
    'campaignId',
    'searchTerm',
    'searchKeyword',
    'targetingText',
    'derivedCampaignType',
    'entityId',
    'categoryId',
    'subCategoryId',
    'brandId',
    'stacklineSku',
    'orderId',
    'lineItemId',
    'creativeId',
    'creativeSize',
    'conversionType',
    'weekDay'
  ];
  const criteoCustomFieldNames = !checkIsAllowedParentPlatform('overviewAddAdGroup');
  if (criteoCustomFieldNames) {
    const retailerId = getRetailerId();
    allGroupByFieldNames = [
      'searchTerm',
      'searchKeyword',
      'targetingText',
      'categoryId',
      'subCategoryId',
      'brandId',
      'stacklineSku',
      'orderId',
      'lineItemId',
      'creativeId',
      'creativeSize',
      'conversionType',
      'weekDay'
    ];
    if (retailerId === '0') {
      allGroupByFieldNames.unshift('campaignId');
    }
  }
  if (!window.location.pathname.startsWith('/brand') && appName === 'atlas') {
    allGroupByFieldNames.push('parentBrandId');
  }
  if (shouldShowLicenseAtlas() && appName === AppName.Atlas) {
    allGroupByFieldNames.push('licenseId');
  }
  const allGroupByFieldsForIndex: {
    groupByFieldName: string;
    aggregationFieldNames: string[];
    indexName: string;
  }[] = [];

  const currentAppName = getAppName();

  allGroupByFieldNames.forEach((groupByFieldName) => {
    const hasField = INDEX_FIELDS.hasField(appName, indexName, groupByFieldName);
    if (hasField) {
      const field = INDEX_FIELDS.getField(appName, indexName, groupByFieldName);
      if (field && (!field.supportedAppNames || field.supportedAppNames.includes(currentAppName))) {
        allGroupByFieldsForIndex.push({
          groupByFieldName,
          aggregationFieldNames: [metricType],
          indexName:
            appName === AppName.Advertising
              ? getMultiGroupChartIndexByField(groupByFieldName, _get(entity, 'type'))
              : indexName
        });
      }
    }
  });
  return allGroupByFieldsForIndex;
};

export const getGroupByFieldConfigByEntityType = (
  {
    app,
    indexName,
    metricType
  }: {
    app: ReduxStore['app'];
    indexName: string;
    metricType: string;
  },
  entity?: Entity
) => {
  const allGroupByFieldsForIndex = getAdvertisingGroupByMapping(app.name, indexName, metricType, entity);

  const groupByFieldConfigsByEntityType: {
    [key: string]: {
      groupByFieldName: string;
      aggregationFieldNames: string[];
      indexName: string;
    }[];
  } = {
    client: allGroupByFieldsForIndex,
    company: allGroupByFieldsForIndex,
    category: allGroupByFieldsForIndex.filter((x) => x.groupByFieldName !== 'categoryId'),
    subcategory: allGroupByFieldsForIndex.filter(
      (x) => x.groupByFieldName !== 'categoryId' && x.groupByFieldName !== 'subCategoryId'
    ),
    brand: allGroupByFieldsForIndex.filter((x) => x.groupByFieldName !== 'brandId'),
    product: allGroupByFieldsForIndex.filter(
      (x) =>
        x.groupByFieldName !== 'stacklineSku' &&
        x.groupByFieldName !== 'brandId' &&
        x.groupByFieldName !== 'categoryId' &&
        x.groupByFieldName !== 'subCategoryId'
    ),
    segment: allGroupByFieldsForIndex,
    businessunit: allGroupByFieldsForIndex,
    searchtermlist: allGroupByFieldsForIndex,
    adCampaign: allGroupByFieldsForIndex.filter(
      (x) =>
        x.groupByFieldName !== 'entityId' &&
        x.groupByFieldName !== 'portfolioId' &&
        x.groupByFieldName !== 'campaignId' &&
        x.groupByFieldName !== 'derivedCampaignType'
    ),
    adGroup: allGroupByFieldsForIndex.filter(
      (x) =>
        x.groupByFieldName !== 'entityId' &&
        x.groupByFieldName !== 'portfolioId' &&
        x.groupByFieldName !== 'campaignId' &&
        x.groupByFieldName !== 'derivedCampaignType'
    ),
    adPortfolio: allGroupByFieldsForIndex.filter(
      (x) => x.groupByFieldName !== 'entityId' && x.groupByFieldName !== 'portfolioId'
    ),
    adEntity: allGroupByFieldsForIndex.filter((x) => x.groupByFieldName !== 'entityId'),
    adTarget: allGroupByFieldsForIndex.filter((x) => x.groupByFieldName !== 'targetingText')
  };

  return groupByFieldConfigsByEntityType;
};

export const buildAdvertisingTopEntitiesChart = ({
  indexName,
  entity,
  app,
  weekIdField
}: {
  indexName: string;
  entity: { type: string };
  app: ReduxStore['app'];
  weekIdField: MetricField;
}): Widget => {
  const groupByFieldConfigsByEntityType = getGroupByFieldConfigByEntityType(
    {
      app,
      indexName,
      metricType: 'impressions'
    },
    entity
  );
  const groupByFieldConfigsForCurrentEntity = Option.of(groupByFieldConfigsByEntityType[entity.type])
    .map((configs) =>
      configs.filter(
        ({ groupByFieldName }) =>
          !(app.name === AppName.Atlas && ['campaignId', 'derivedCampaignType'].includes(groupByFieldName))
      )
    )
    .getOrElseL(() => {
      return panic(`No group-by-field configs found for current entity type: "${entity.type}"`);
    });

  const aggregationFieldNames =
    app.name === AppName.Atlas ? ATLAS_ADVERTISING_METRIC_FIELD_NAMES : BEACON_ADVERTISING_METRIC_FIELD_NAMES;

  const topEntitiesMetricFields = aggregationFieldNames.map((fieldName) => {
    const field = INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type);
    return {
      ...field,
      displayName: advertisingMetricDisplayNamesByFieldName[fieldName] || field.displayName
    };
  });
  return buildMultiGroupByTopEntitiesChartWidgetConfig(
    app,
    indexName,
    entity,
    groupByFieldConfigsForCurrentEntity.map((config) => ({
      ...config,
      aggregationFieldNames
    })),
    50,
    topEntitiesMetricFields,
    weekIdField,
    {
      view: {
        enableComparison: true,
        chartPropsOverride: { ...allowHorizontalScroll },
        anchorName: 'multiGroupByTopEntitiesChart'
      }
    }
  );
};

export const buildDisplayAdvertisingMultiMetricTrendChart = ({
  indexName,
  entity,
  groupByFieldName = 'weekId',
  app,
  weekIdField,
  showOnlyMainSeries
}: {
  indexName: string;
  entity: { type: string };
  groupByFieldName?: string;
  app: ReduxStore['app'];
  weekIdField: MetricField;
  showOnlyMainSeries: boolean;
}) => {
  const metricFieldNames = filterNils([
    ...BEACON_DISPLAY_ADVERTISING_METRIC_FIELD_NAMES,
    entity.type !== 'product' ? 'stacklineSku' : null
  ]);
  const name = 'multiMetricTrendChart';
  const widget = buildTrendWidgetConfig(app, indexName, entity, groupByFieldName, metricFieldNames, weekIdField, {
    CustomComponent: MultiMetricTrendChart,
    name,
    view: {
      name,
      chartPropsOverride: {
        title: { text: '' }
      },
      showOnlyMainSeries,
      hideSubtitle: true
    }
  });

  ['stacklineSku'].forEach((aggregationFieldName) => {
    const aggregationFieldConfig: any | undefined = widget.data.configByGroupByFieldName[
      groupByFieldName
    ].aggregationFields.find(propEq('name', aggregationFieldName));
    if (!aggregationFieldConfig) {
      return;
    }

    aggregationFieldConfig.conditions = {
      rangeFilters: [
        {
          fieldName: 'clicks',
          minValue: 1
        }
      ]
    };
  });

  // Override the display names of the created metric fields
  widget.view.metricFields = widget.view.metricFields.map((metricField: MetricField) => ({
    ...metricField,
    displayName: advertisingMetricDisplayNamesByFieldName[metricField.name!] || metricField.displayName
  }));

  return widget;
};

export const buildDisplayAdvertisingTopEntitiesChart = ({
  indexName,
  entity,
  app,
  weekIdField
}: {
  indexName: string;
  entity: { type: string };
  app: ReduxStore['app'];
  weekIdField: MetricField;
}): Widget => {
  const groupByFieldConfigsByEntityType = getGroupByFieldConfigByEntityType({
    app,
    indexName,
    metricType: 'impressions'
  });

  const groupByFieldConfigsForCurrentEntity = Option.of(groupByFieldConfigsByEntityType[entity.type]).getOrElseL(() => {
    return panic(`No group-by-field configs found for current entity type: "${entity.type}"`);
  });

  const aggregationFieldNames = BEACON_DISPLAY_ADVERTISING_METRIC_FIELD_NAMES;
  const topEntitiesMetricsFields = aggregationFieldNames.map((fieldName) => {
    const field = INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type);
    return {
      ...field,
      displayName: field.displayName
    };
  });
  return buildMultiGroupByTopEntitiesChartWidgetConfig(
    app,
    indexName,
    entity,
    groupByFieldConfigsForCurrentEntity.map((config) => ({
      ...config,
      aggregationFieldNames
    })),
    10,
    topEntitiesMetricsFields,
    weekIdField,
    {
      view: {
        chartPropsOverride: { enableSwitchingGroupBy: true, ...allowHorizontalScroll },
        anchorName: 'multiGroupByTopEntitiesChart',
        container: {
          style: getWidgetBarChartStyles({
            marginTop: `${BEACON_KEY_METRIC_CARD_SPACING}px`
          })
        }
      }
    }
  );
};
