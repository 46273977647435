import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'qs';
import IconButton from '@mui/material/IconButton';
import ReactTooltip from 'react-tooltip';
import _pick from 'lodash/pick';

import { extractQueryConditions } from 'src/utils/segments';
import { addPersistentQueryParams } from 'src/utils/browser';
import { CrossIcon, DuplicateIcon, EditIcon } from 'src/components/SvgIcons';
import { segmentOperations } from 'src/store/modules/segments';
import DeleteSavedSearchDialog from 'src/routes/HomePage/SummaryData/DeleteSavedSearchDialog';

const styles = {
  root: {
    flex: '1 1',
    alignSelf: 'center',
    textAlign: 'right'
  },
  editButton: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '0',
    height: '34px',
    marginLeft: '10px'
  },
  duplicateButton: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '0',
    height: '34px'
  }
};

class SavedSearchEditIconsContainer extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    currentSavedSearch: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    deleteRequestedSavedSearch: PropTypes.func.isRequired,
    fetchAllSavedSearch: PropTypes.func.isRequired,
    segments: PropTypes.object.isRequired
  };

  state = {
    isDeleteDialogOpen: false
  };

  stopPropagation = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  handleDuplicateSavedSearch = (event) => {
    this.stopPropagation(event);
    const { currentSavedSearch, retailer } = this.props;
    const conditions = extractQueryConditions(currentSavedSearch, true);
    this.props.history.push(
      `/search?rid=${retailer.id}&entityType=${currentSavedSearch.entityType}&${queryString.stringify(conditions)}`
    );
  };

  //  TODO Remove the addPersistentQuerParams function once REDUX implements storing the params
  handleEditSavedSearch = (event) => {
    this.stopPropagation(event);
    const { currentSavedSearch, retailer, mainTimePeriod } = this.props;
    const conditions = extractQueryConditions(currentSavedSearch, true, true);
    this.props.history.push(
      `/search?${addPersistentQueryParams(retailer, mainTimePeriod)}&doAggregation=true&entityType=${
        currentSavedSearch.entityType
      }&${queryString.stringify(conditions)}`
    );
  };

  handleToggleDialogClick = (event) => {
    if (event) {
      this.stopPropagation(event);
    }
    this.setState({ isDeleteDialogOpen: !this.state.isDeleteDialogOpen });
  };

  render() {
    const { currentSavedSearch } = this.props;
    const entityDisplayName = currentSavedSearch.entityType === 'searchtermlist' ? 'Keyword List' : 'Segment';

    return (
      <div style={styles.root}>
        {/* DUPLICATE SAVED SEARCH BUTTON */}
        <Fragment>
          <IconButton
            data-for="duplicate-segment"
            data-tip={`Duplicate ${entityDisplayName}`}
            onClick={this.handleDuplicateSavedSearch}
            style={styles.duplicateButton}
            size="large"
          >
            <DuplicateIcon className="segment__duplicate-icon" />
          </IconButton>
          <ReactTooltip id="duplicate-segment" effect="solid" class="sl-tooltip" />
        </Fragment>

        {/* SAVED SEARCH EDIT ICONS  */}
        {currentSavedSearch.canEdit ? (
          <Fragment>
            <IconButton
              data-for="edit-segment"
              data-tip={`Edit ${entityDisplayName}`}
              onClick={this.handleEditSavedSearch}
              style={styles.editButton}
              size="large"
            >
              <EditIcon className="segment__edit-icon" />
            </IconButton>
            <IconButton
              data-for="delete-segment"
              data-tip={`Delete ${entityDisplayName}`}
              onClick={this.handleToggleDialogClick}
              style={{ display: 'inline-block', verticalAlign: 'middle', padding: '0', height: '34px' }}
              size="large"
            >
              <CrossIcon className="segment__delete-icon" />
            </IconButton>
            <ReactTooltip id="edit-segment" effect="solid" className="sl-tooltip" />
            <ReactTooltip id="delete-segment" effect="solid" className="sl-tooltip" />
          </Fragment>
        ) : null}

        <DeleteSavedSearchDialog
          isOpen={this.state.isDeleteDialogOpen}
          setIsClosed={() => this.setState({ isDeleteDialogOpen: false })}
          savedSearchId={currentSavedSearch.id}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, ['app', 'comparisonTimePeriod', 'mainTimePeriod', 'retailer', 'segments']);

const mapDispatchToProps = {
  deleteRequestedSavedSearch: segmentOperations.deleteRequestedSavedSearch,
  fetchAllSavedSearch: segmentOperations.fetchAllSavedSearch
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SavedSearchEditIconsContainer));
