import React from 'react';
import useDerivedMetricByWeekId from 'src/serverProxy/useDerivedMetricByWeekId';
import BeaconChartWithLegend from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import SplineChart from 'src/components/BeaconRedesignComponents/SplineChart/SplineChart';
import SplineChartLoading from 'src/components/BeaconRedesignComponents/SplineChartLoading/SplineChartLoading';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { useAppSelector } from 'src/utils/Hooks';
import TrafficConversionRateGrid from './TrafficConversionRateGrid';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import { useProductEntity } from 'src/utils/Hooks/reduxSelectors';
import { TrafficConversionByWeekTable } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Traffic/Conversion/TrafficConversionByWeekTable';

/**
 * Conversion Page for the traffic side tab
 */
export default function TrafficConversionPage() {
  const primaryLegendDisplayName = useAppSelector((state) => state.mainTimePeriod.displayName);
  const comparisonLegendDisplayName = useAppSelector((state) => state.comparisonTimePeriod.displayName);
  const {
    primaryData,
    secondaryData,
    percentChange,
    isLoading,
    formattedPercentChange,
    formattedPrimaryTotal,
    formattedSecondaryTotal
  } = useDerivedMetricByWeekId('sales', 'purchaseRate');

  const { isProductEntity } = useProductEntity();

  return (
    <BeaconPageContainer>
      <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
        <BeaconChartWithLegend
          title="Conversion Rate"
          loading={isLoading}
          loadingComponent={<SplineChartLoading />}
          primaryLegendProps={{
            displayName: primaryLegendDisplayName,
            metric: formattedPrimaryTotal,
            change: formattedPercentChange,
            direction: getDirection(percentChange)
          }}
          comparisonLegendProps={{
            displayName: comparisonLegendDisplayName,
            metric: formattedSecondaryTotal
          }}
        >
          <SplineChart metricType={METRICTYPE.PERCENT} primaryData={primaryData} secondaryData={secondaryData} />
        </BeaconChartWithLegend>
        {isProductEntity ? <TrafficConversionByWeekTable /> : <TrafficConversionRateGrid />}
      </Flex>
    </BeaconPageContainer>
  );
}
