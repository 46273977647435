import { Widget } from 'src/types/application/widgetTypes';
import SearchResultsGridV3 from './SearchResultsGridV3';
import React from 'react';
import useSrgData from './hooks/useSrgData';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import { Location } from 'history';
import { EnhanceEntityColumn } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';
import useFetchEntityData from './hooks/useFetchEntityData';
import { shouldFetchAllSRGRows } from 'src/utils/app';

interface EntitiesGridProps {
  widget: Widget;
  location: Location;
}
/**
 * Display a list of Entities. This can be found on the Drive landing page.
 */
const EntitiesGrid: React.FC<EntitiesGridProps> = ({ widget, location }) => {
  const fetchEntityData = useFetchEntityData();

  const srgData = useSrgData({
    widget,
    location,
    fetchEntityData,
    metadataRowOptions: shouldFetchAllSRGRows(widget.data.entityType, widget.data.groupByFieldName)
      ? {
          getEntityId: (row) => row.entityId,
          type: 'adEntity',
          name: 'entityName'
        }
      : undefined
  });

  return (
    <SearchResultsGridV3
      columnDefs={[
        {
          headerName: 'Entity',
          field: 'entityId',
          width: 100,
          enableRtl: true,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          minWidth: 300,
          maxWidth: 800,
          cellRendererFramework: EnhanceEntityColumn,
          headerClass: 'align-left',
          pinnedRowCellRenderer: 'simpleColumn',
          pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
          pinned: 'left'
        }
      ]}
      getRowNodeId={(row) => row.id}
      widget={widget}
      {...srgData}
    />
  );
};

export default withRouter(withBus('eventBus')(EntitiesGrid));
