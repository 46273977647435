import React from 'react';
import { SlColumn, useStacklineTheme } from '@stackline/ui';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import BeaconMetricSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconMetricSplineChart';
import CompliantSellersDonutChart from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Compliance/Sellers/CompliantSellersDonutChart';

/**
 * The "Sellers" tab on the Compliance page
 */
const ComplianceSellersPage = () => {
  const theme = useStacklineTheme();

  return (
    <div style={{ marginTop: theme.spacing.lg }}>
      <SlColumn spacing="lg" widths="full">
        <CommonSummaryInfoSubtitle title="Compliant Sellers" />
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: '40%'
            }}
          >
            <CompliantSellersDonutChart />
          </div>
          <div
            style={{
              width: '60%'
            }}
          >
            <BeaconMetricSplineChart
              indexName="compliance-selleritem"
              fieldName="authorizedSellerPercent"
              showTitleSummary={false}
              splineChartLoadingProps={{
                width: 543
              }}
              chartOptionsOverride={{
                chart: {
                  height: 300
                }
              }}
            />
          </div>
        </div>
      </SlColumn>
    </div>
  );
};

export default ComplianceSellersPage;
