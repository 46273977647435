import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getRetailerIdDisplayName } from 'src/utils/stringFormatting';
import { Widget } from 'src/types/application/widgetTypes';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { determineTheDefault } from 'src/components/EntityPage/Content/OmniContentAccuracy';
import { ChevronIcon } from 'src/components/SvgIcons';
import OutlinedInput from '@mui/material/OutlinedInput';
import colors from 'src/utils/colors';
import _orderBy from 'lodash/orderBy';

interface OmniRetailerSelectorHeaderProps extends RouteComponentProps {
  widget: Widget;
  eventBus: EventBus;
}

const OmniRetailerSelectorHeader: FC<OmniRetailerSelectorHeaderProps> = ({ widget, eventBus, location }) => {
  const { style } = widget.view;
  const omniRetailersState = useSelector((state: ReduxStore) => state.omniRetailers);
  const omniRetailers = omniRetailersState.data;
  const retailers = useSelector((state: ReduxStore) => state.retailer);
  const filters = useSelector((state: ReduxStore) => state.filters);
  const { pathname } = location;
  const defaultValue = determineTheDefault(filters, omniRetailers, pathname);

  const [selectedVal, setSelectedVal] = useState(defaultValue);

  const omniRetailerData = omniRetailers.map(({ retailerId }) => ({
    value: retailerId,
    displayName: getRetailerIdDisplayName(String(retailerId), retailers)
  }));
  const sortedRetailerData = _orderBy(omniRetailerData, 'displayName', 'asc');
  const isMultiRetailer = omniRetailers.length > 1;

  const handleOnChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const retailerSelected = Number(e.target.value);
    eventBus.emit('omniDropDownSelected', { retailerSelected });
    setSelectedVal(retailerSelected);
  };

  useEffect(() => setSelectedVal(defaultValue), [defaultValue]);

  return (
    <div style={style}>
      <Select
        variant="standard"
        value={selectedVal}
        disabled={!isMultiRetailer}
        onChange={handleOnChange}
        input={
          // when there is only one retailer available, we will hide chevron Icon and disable dropdown
          <OutlinedInput
            labelWidth={0}
            id="outlined-age-simple"
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: colors.black,
                paddingRight: 0
              }
            }}
          />
        }
        IconComponent={() =>
          isMultiRetailer ? <ChevronIcon className="sl-header__drop-down-icon" style={{ top: '25%' }} /> : <></>
        }
      >
        {sortedRetailerData.map(({ value, displayName }) => (
          <MenuItem value={value} key={value}>
            {displayName}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default withRouter(withBus('eventBus')(OmniRetailerSelectorHeader));
