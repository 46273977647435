import React, { useMemo } from 'react';
import EntityTable, {
  EntityTableProps,
  MetricCell
} from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import { shouldHidePagination } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/utils';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import Pagination from 'src/components/BeaconRedesignComponents/Pagination/Pagination';
import ProductCell from 'src/components/BeaconRedesignComponents/ProductCell/ProductCell';
import { usePagination } from 'src/components/EntityGrid/EntityGrid/PaginationProvider';

const GRID_ITEMS_PER_PAGE = 20;

interface BeaconProTableProps extends EntityTableProps {
  fetchData: () => void;
  lastFetchedPage: number;
}

/**
 * Adapter for EntityTable to BeaconProTable
 */
export default function BeaconProTable({
  columnDefs,
  rowData,
  isLoading,
  lastFetchedPage,
  fetchData
}: BeaconProTableProps) {
  const { page, setPage } = usePagination();

  const dataToShow = useMemo(() => {
    const dataStartIndex = (page - 1) * GRID_ITEMS_PER_PAGE;
    return rowData.slice(dataStartIndex, dataStartIndex + GRID_ITEMS_PER_PAGE);
  }, [page, rowData]);

  const handleNextPage = () => {
    // If the last fetched page of data is less than the length of data we
    // can show, than there are no more pages to fetch
    if (!isLoading && dataToShow.length % GRID_ITEMS_PER_PAGE === 0) {
      const nextPage = page + 1;
      setPage(nextPage);
      if (nextPage > lastFetchedPage) {
        fetchData();
      }
    }
  };

  const handlePrevPage = () => {
    setPage(Math.max(1, page - 1));
  };

  /**
   * Adapts the columnDefs to work with the BeaconProTable
   */
  const adaptedColumnDefs = useMemo(() => {
    return columnDefs.map((column) => {
      const CustomCell = column.cellRendererFramework;

      return {
        ...column,
        ...(column.field !== 'name'
          ? {
              valueFormatter: (_, row) =>
                column.headerName === 'Products' ? (
                  <ProductCell stacklineSku={row.id} title={row.name} />
                ) : column.field === 'custom' ? (
                  <CustomCell data={row} />
                ) : (
                  <MetricCell data={row} field={column.field} />
                )
            }
          : {})
      };
    });
  }, [columnDefs]);

  return (
    <Flex flexDirection="column" marginBottom="64px">
      <EntityTable
        columnDefs={adaptedColumnDefs}
        rowData={dataToShow}
        isLoading={isLoading}
        showPagination={false}
        shouldModifyColumnDefs={false}
        skeletonRows={GRID_ITEMS_PER_PAGE}
      />
      {shouldHidePagination({
        page,
        pageSize: GRID_ITEMS_PER_PAGE,
        rowCount: (dataToShow || []).length
      }) ? null : (
        <Flex justifyContent="flex-end">
          <Pagination
            currentPage={page}
            onClickNext={handleNextPage}
            onClickPrevious={handlePrevPage}
            shouldUseInfinitePagination
          />
        </Flex>
      )}
    </Flex>
  );
}
