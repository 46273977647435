import React, { useEffect } from 'react';
import { useStacklineTheme } from '@stackline/ui';
import ScrollableContainer from 'src/components/BeaconRedesignComponents/ScrollableContainer';
import { useInView } from 'react-intersection-observer';

interface CompareScrollableContainerProps extends React.ComponentProps<typeof ScrollableContainer> {
  /**
   * Callback for when the scrollable container is scrolled to the bottom
   * of the content
   */
  onScrollToBottom?: () => void;
}
/**
 * Scrollable container for the compare popup
 */
export default function CompareScrollableContainer({
  onScrollToBottom,
  children,
  ...rest
}: CompareScrollableContainerProps) {
  const theme = useStacklineTheme();
  const { ref, inView } = useInView({ threshold: 0 });

  /**
   * For infinite scroll, call the callback when the ref is in view
   */
  useEffect(() => {
    if (inView && onScrollToBottom) {
      onScrollToBottom();
    }
  }, [inView, onScrollToBottom]);

  return (
    <ScrollableContainer borderRadius={theme.spacing.xs} border={`1px solid ${theme.colors.primaryGray}`} {...rest}>
      {children}
      {/* Ref to be in view for infinite scroll functionality */}
      <div ref={ref}></div>
    </ScrollableContainer>
  );
}
