import { UseMutationOptions, useMutation } from 'react-query';
import { ValidateBulkAdjustmentPayload } from '../serverProxy/types';
import useServerProxy from './useServerProxy';

/**
 * Tells the backend to validate the bulk adjustment file
 */
export default function useValidateBulkAdjustment(
  options: UseMutationOptions<unknown, unknown, ValidateBulkAdjustmentPayload> = {}
) {
  const { validateBulkAdjustment } = useServerProxy();
  return useMutation(validateBulkAdjustment, options);
}
