import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useAppSecondaryColor } from 'src/components/SignIn/utils';
import { useDispatch } from 'react-redux';
import { AnimatedEllipsis } from 'src/components/common/Loading/AnimatedEllipsis';
import { StacklineLogoBlueIcon } from '../SvgIcons/SvgIcons';
import AuthButton from './AuthButton';
import AuthContainer from './AuthContainer';
import AuthTitle from './AuthTitle';
import LogoContainer from './LogoContainer';
import SignInInputField from './SignInInputField';
import ReduxStore from 'src/types/store/reduxStore';
import { useStacklineTheme } from '@stackline/ui';
import { clearMessage } from 'src/store/modules/auth/operations';
import { getAppDisplayName, getAppName } from 'src/utils/app';

interface SignInProps {
  app: ReduxStore['app'];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  email: string;
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  buttonText?: string;
}

const SignIn = ({ handleChange, handleFormSubmit, loading, email, handleEmailChange }: SignInProps) => {
  const title = getAppDisplayName(getAppName());
  const appSecondaryColor = useAppSecondaryColor();
  const theme = useStacklineTheme();

  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    handleChange({ email, password });
  }, [email, handleChange, password]);

  const handleEmailInputChange = (e) => {
    // We want to close error notification when user type email or password
    dispatch(clearMessage());
    handleEmailChange(e.target.value);
  };

  const handlePasswordChange = (e) => {
    // We want to close error notification when user type email or password
    dispatch(clearMessage());
    setPassword(e.target.value);
  };

  return (
    <AuthContainer style={{ height: '671px' }}>
      <form onSubmit={handleFormSubmit}>
        <LogoContainer title={title} />
        <AuthTitle marginTop="24px" marginBottom={theme.spacing.lg}>
          Hey, good to see you again!
        </AuthTitle>
        <Box>
          <SignInInputField id="signin-email" label="Email" value={email} onChange={handleEmailInputChange} />
        </Box>
        <Box sx={{ mt: '24px', mb: '32px' }}>
          <SignInInputField
            showForgotPassword
            value={password}
            id="signin-password"
            onChange={handlePasswordChange}
            label="Password"
            type="password"
            autoComplete="current-password"
          />
        </Box>

        <AuthButton disabled={loading} type="submit">
          {loading ? (
            <Box display="flex" flexDirection="row" maxHeight="100%" alignItems="center" justifyContent="center">
              Loading
              <AnimatedEllipsis width={40} height={40} style={{ margin: 0, transform: 'translate(-8px,5px)' }} />
            </Box>
          ) : (
            'Continue'
          )}
        </AuthButton>

        <Box mt={theme.spacing.xxl} gap="12px" display="flex" flexDirection="column" alignItems="center">
          <StacklineLogoBlueIcon width="97.1px" />
          <Box
            style={{
              fontWeight: 500,
              fontSize: '14px'
            }}
          >
            Don’t have an account?{' '}
            <a style={{ color: appSecondaryColor }} href="https://www.stackline.com/contact">
              {/* This should remain an anchor tag since we are linking outside the web app */}
              Contact us
            </a>
          </Box>
        </Box>
      </form>
    </AuthContainer>
  );
};

export default SignIn;
