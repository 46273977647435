import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import { store } from 'src/main';
import Homepage from 'src/routes/HomePage/HomePage';
import SummaryHeader from 'src/components/AdManager/SummaryHeader';
import ScatterPlotChart from 'src/components/AdManager/ScatterPlotChart';
import AdManagerTileList from 'src/components/SummaryTile/AdManagerTileList';
import TopThreeGrid from 'src/components/EntityGrid/Table/TopThreeGrid';
import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import { buildSearchResultGridWidget } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';
import { buildTrendWidgetConfig } from 'src/components/Layout/LayoutUtil';
import { AppName } from 'sl-api-connector';
import AdManagerSummaryTrend from 'src/components/EntityPage/TrendChart/AdManagerSummaryTrend';

export const getLayout = ({ app, entity, selectedEntityName, subtab }) => {
  const { user } = store.getState();
  const widgets = [];

  if (subtab === 'segmentSummary') {
    widgets.push({
      name: 'home page',
      CustomComponent: Homepage,
      view: {}
    });
    return { widgets };
  }
  const { searchParams } = app.queryParams;
  const indexName = 'adCampaignAdGroupProductTargetDailyMetrics';
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField('beacon', 'sales', 'weekId'));
  const nInOneMetricFieldNames = ['clicks', 'spend', 'sales', 'returnOnAdSpend'];

  const headerTabOpts = [
    {
      name: 'campaignId',
      displayName: 'Campaigns',
      eventName: 'openAdCampaignBuilder',
      eventArg: `/home${searchParams}&tab=adCampaignBuilder`,
      tooltipText: 'Create Campaign'
    },
    {
      name: 'portfolioId',
      displayName: 'Portfolios',
      eventName: 'openCreatePortfolioDialog',
      tooltipText: 'Create Portfolio'
    },
    {
      name: 'entityId',
      displayName: 'Entities',
      eventName: 'openGlobalPopup',
      eventArg: 'AddNewEntityPopup',
      tooltipText: 'Create Entity'
    }
  ];

  const buildMiniTrendConfig = (fieldName, widgetName, legendTitle) => {
    return buildTrendWidgetConfig(app, indexName, entity, 'dayId', [fieldName], weekIdField, {
      name: widgetName,
      view: {
        chartPropsOverride: {
          legend: { enabled: false, legendTitle },
          subtitle: {
            text: '',
            style: {
              'border-bottom': 'none'
            }
          },
          chart: {
            type: 'areaspline',
            height: 130,
            width: 300,
            marginTop: 0
          },
          yAxis: [
            {
              endOnTick: true,
              visible: false,
              minPadding: 0.3
            }
          ],
          xAxis: [
            {
              visible: false
            }
          ],
          tooltip: {
            positioner: (labelWidth, labelHeight, point) => ({
              x: point.plotX - labelWidth / 2,
              y: point.plotY - labelHeight
            })
          },
          title: { text: '' },
          exporting: {
            enabled: false
          }
        },
        showOnlyMainSeries: true,
        container: { style: { display: 'inline-block', marginBottom: '0px', width: 300 } }
      },
      CustomComponent: AdManagerSummaryTrend
    });
  };

  const spendMiniMetrics = buildMiniTrendConfig('spend', 'mini_spend', 'AD SPEND');

  const salesMiniMetrics = buildMiniTrendConfig('sales', 'mini_sales', 'AD SALES');

  const roasMiniMetrics = buildMiniTrendConfig('returnOnAdSpend', 'mini_returnOnAdSpend', 'ROAS');

  const clicksMiniMetrics = buildMiniTrendConfig('clicks', 'mini_clicks', 'CLICKS');

  const summaryTrendwidgets = [spendMiniMetrics, salesMiniMetrics, roasMiniMetrics, clicksMiniMetrics];

  const aggregationMetricFields = [
    INDEX_FIELDS.getField(AppName.Advertising, indexName, 'spend', 'product', 'stacklineSku'),
    INDEX_FIELDS.getField(AppName.Advertising, indexName, 'sales', 'product', 'stacklineSku'),
    INDEX_FIELDS.getField(AppName.Advertising, indexName, 'returnOnAdSpend', 'product', 'stacklineSku')
  ];

  const topThreeGridWidget = {
    name: 'topThreeGrid',
    CustomComponent: TopThreeGrid,
    view: _merge({}, VIEWS.entityGrid, {
      gridOptions: {
        enableGroupBy: true
      },
      container: {
        style: { marginBottom: '40px', marginTop: '30px' }
      }
    }),
    data: {
      groupByFields: [
        INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product'),
        {
          ...INDEX_FIELDS.getField(app.name, indexName, 'targetingText', 'product'),
          displayName: 'Target'
        }
      ],
      configByGroupByFieldName: {
        targetingText: {
          indexName,
          entity: ENTITIES.advertising.adCampaignAdGroupTarget,
          mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'spend', 'product', 'stacklineSku'),
          aggregationFields: aggregationMetricFields
        },
        stacklineSku: {
          indexName,
          entity: ENTITIES.advertising.product,
          mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'spend', 'product', 'stacklineSku'),
          aggregationFields: aggregationMetricFields
        }
      }
    }
  };

  // const tableGridFields = nInOneMetricFieldNames.map((fieldName) =>
  //   INDEX_FIELDS.getField(AppName.Advertising, indexName, fieldName)
  // );

  const getTableGridConfig = (groupByFieldName) => {
    return buildSearchResultGridWidget({ app, entity, groupByFieldName, user });
  };

  const campaignSummary = {
    listType: 'campaign',
    header: 'Campaigns',
    tableGridConfig: getTableGridConfig(selectedEntityName),
    tabOpts: headerTabOpts
  };

  const name = 'entitySummary';

  const listProps = campaignSummary;
  const { listType, header, tabOpts, eventName, eventArg, tableGridConfig } = listProps;

  widgets.push({
    CustomComponent: SummaryHeader,
    name,
    view: {
      name,
      summaryHeader: {
        title: header,
        eventName,
        eventArg,
        headerTabOpts: tabOpts
      }
    },
    data: {}
  });

  widgets.push({
    CustomComponent: ScatterPlotChart,
    name: 'scatterPlot',
    view: {
      container: {
        style: {
          marginTop: 30
        }
      }
    },
    data: {
      weekIdField
    }
  });

  widgets.push({
    CustomComponent: AdManagerTileList,
    name: `summaryTileList_${header}`,
    view: {
      title: 'Ad Score',
      header
    },
    data: {
      adScoreName: 'adScore',
      selectedEntityName,
      weekIdField,
      entity,
      nInOneMetricFieldNames,
      indexName,
      listType,
      tableGridConfig,
      topThreeGridWidget,
      summaryTrendwidgets,
      pacingConfig: {
        indexName: 'adCampaignDailyMetrics',
        pacingFields: [INDEX_FIELDS.getField(app.name, indexName, 'budgetPacing')]
      }
    }
  });

  return {
    widgets
  };
};
