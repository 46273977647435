import { useAppSelector } from 'src/utils/Hooks';

interface AppFilters {
  /**
   * A boolean to determine if there are active filters in the URL
   */
  hasActiveFilters: boolean;
  /**
   * An object containing active filters for category, subcategory, and segments for use in Advanced Search requests
   */
  appFilters: {
    categoryFilters: number[] | string[];
    subcategoryFilters: number[] | string[];
    segmentFilters: {
      termFilters: { fieldName: string; values: string[] | number[]; condition: string }[];
      rangeFilters: { fieldName: string; minValue: string | number; maxValue: string | number }[];
    };
  };
}

export const useBeaconFilters = (): AppFilters => {
  const filters = useAppSelector((state) => state.filters);
  const { category, subcategory, segment } = filters;

  return {
    hasActiveFilters: !!category || !!subcategory || !!segment,
    appFilters: {
      categoryFilters:
        category || subcategory
          ? [
              ...(category ? category.map((filteredCategory) => filteredCategory.id) : []),
              ...(subcategory ? subcategory.map((filteredSubcategory) => filteredSubcategory.parentCategoryId) : [])
            ]
          : null,
      subcategoryFilters: subcategory ? subcategory.map((filteredSubcategory) => filteredSubcategory.id) : null,
      segmentFilters: segment
        ? {
            termFilters: segment.termFilters ? segment.termFilters : [],
            rangeFilters: segment.rangeFilters ? segment.rangeFilters : []
          }
        : null
    }
  };
};
