import React, { useMemo } from 'react';
import GenericBulkAdjustmentModal, { GenericBulkAdjustmentModalProps } from './GenericBulkAdjustmentModal';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BulkAdjustmentUploadSuccessAnimation } from 'src/components/Animations/BulkAdjustmentUploadSuccessAnimation/BulkAdjustmentUploadSuccessAnimation';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/utils/Hooks';
import { useBulkAdjustmentContext } from './hooks/useBulkAdjustmentContext';
import Divider from '@mui/material/Divider';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import BulkTemplateCircularLoadingInner from './BulkTemplateCircularLoadingInner';
import { useStacklineTheme } from '@stackline/ui';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';

export enum TemplateSubmittingStatus {
  Submitting = 'Submitting',
  SubmissionResume = 'SubmissionResume',
  SubmissionSuccess = 'SubmissionSuccess'
}

export type TemplateSubmittingState =
  | {
      status: TemplateSubmittingStatus.Submitting;
      percentage: number;
      saveAsDraft: boolean;
    }
  | {
      status: TemplateSubmittingStatus.SubmissionResume;
      percentage: number;
      bulkAdjustmentId: string;
      planType: PlanTypeOption;
    }
  | {
      status: TemplateSubmittingStatus.SubmissionSuccess;
    };

export interface BulkSubmittingSuccessModalProps extends Omit<GenericBulkAdjustmentModalProps, 'children' | 'header'> {
  state: TemplateSubmittingState;
}

/**
 * Modal for submitting a bulk template
 */
export default React.forwardRef(function BulkTemplateSubmittingModal(
  { state: submittingState, ...rest }: BulkSubmittingSuccessModalProps,
  ref: React.Ref<HTMLDivElement>
) {
  const { queryParams } = useAppSelector((state) => state.app);
  const { closeModal } = useBulkAdjustmentContext();
  const theme = useStacklineTheme();

  const config = useMemo(() => {
    switch (submittingState.status) {
      case TemplateSubmittingStatus.Submitting:
      case TemplateSubmittingStatus.SubmissionResume:
        return {
          content: (
            <BulkTemplateCircularLoadingInner
              circleLabel="Submitted"
              descriptionBody={
                <>
                  We&apos;ll notify you when the submission is complete.
                  <br />
                  You can close this window in the meantime.
                  <br />
                  {submittingState.status === TemplateSubmittingStatus.Submitting && (
                    <>
                      View the status of your submission{' '}
                      {/* TODO this should go to uploads when we finish building that page */}
                      <Link to={`/account/adjustments${queryParams.searchParams}`} onClick={() => closeModal()}>
                        <span style={{ textDecoration: 'underline' }}>here</span>
                      </Link>
                      .
                    </>
                  )}
                </>
              }
              descriptionTitle="Your bulk adjustment is being submitted"
              percentage={submittingState.percentage}
              sx={{ height: '490px' }}
              circleSx={{
                color: theme.colors.primary
              }}
            />
          )
        };
      case TemplateSubmittingStatus.SubmissionSuccess:
        return {
          content: (
            <Flex flexDirection="column" alignItems="center" marginTop="335px">
              <BulkAdjustmentUploadSuccessAnimation
                style={{ position: 'absolute', width: '390px', height: '390px', top: '70px' }}
              />

              <Text textAlign="center" sx={{ width: '270px' }} variant="h3">
                Your bulk adjustment was successfully submitted
              </Text>
              <Text textAlign="center" sx={{ display: 'flex', marginTop: '8px' }} variant="body1">
                View all bulk adjustment submissions&nbsp;
                <Text underlined variant="body1">
                  <Link to={`/account/adjustments${queryParams.searchParams}`} onClick={() => closeModal()}>
                    here
                  </Link>
                </Text>
                .
              </Text>
            </Flex>
          )
        };
      default:
        return null;
    }
  }, [closeModal, queryParams.searchParams, submittingState, theme]);

  if (!config) {
    return null;
  }

  return (
    <GenericBulkAdjustmentModal
      header="Submitting Bulk Adjustment"
      defaultContentContainer={false}
      footer={
        <>
          <div style={{ flex: 1 }} />
          <Flex flexDirection="column">
            <Divider />
            <Flex justifyContent="space-between" alignItems="center" paddingX="48px" paddingY="20px">
              <SlButton disabled>Back</SlButton>
              <SlButton variant="contained" onClick={() => closeModal()}>
                Close
              </SlButton>
            </Flex>
          </Flex>
        </>
      }
      {...rest}
      ref={ref}
    >
      {config.content}
    </GenericBulkAdjustmentModal>
  );
});
