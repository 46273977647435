import React, { useMemo } from 'react';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { AD_PRIMARY_ATTRIBUTION_EVENT, AD_SECONDARY_ATTRIBUTION_EVENT } from './constants';
import GenericBarChart from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/GenericBarChart';
import { createNonComparisonLegendDivs } from 'src/utils/chartUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { FirstTouchAttributionDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import colors from 'src/utils/colors';
import { getSimpleTooltipDiv } from 'src/components/EntityPage/Renderer/tooltipDiv';
import { convertFirstTouchAttributionDataToCSV } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/FirstTouchAttribution/utils';

interface FirstTouchAttributionBarChartProps {
  primaryMetric: string;
  secondaryMetric: string;
  data: any;
}

/**
 *
 * @returns Bar chart displaying First Touch Attribution metrics
 */
const FirstTouchAttributionBarChart = ({
  data,
  primaryMetric,
  secondaryMetric
}: FirstTouchAttributionBarChartProps) => {
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const formatMetric = useMetricFormatter();
  const buttons = useMemo(() => {
    return [
      [
        {
          prependedText: 'First Touch Attributed ', // Text to prepend to the chart title
          displayName: 'Impressions', // Actual button text
          groupByTextOverride: 'and', // Override the default "by" text
          eventId: FirstTouchAttributionDropdownOption.Impressions,
          eventName: AD_PRIMARY_ATTRIBUTION_EVENT,
          isSelected: primaryMetric === FirstTouchAttributionDropdownOption.Impressions
        },
        {
          prependedText: 'First Touch Attributed ',
          displayName: 'Clicks',
          groupByTextOverride: 'and',
          eventId: FirstTouchAttributionDropdownOption.Clicks,
          eventName: AD_PRIMARY_ATTRIBUTION_EVENT,
          isSelected: primaryMetric === FirstTouchAttributionDropdownOption.Clicks
        }
      ],
      [
        {
          displayName: 'Ad Sales',
          eventId: FirstTouchAttributionDropdownOption.AdSales,
          eventName: AD_SECONDARY_ATTRIBUTION_EVENT,
          isSelected: secondaryMetric === FirstTouchAttributionDropdownOption.AdSales
        },
        {
          displayName: 'Ad Units Sold',
          eventId: FirstTouchAttributionDropdownOption.UnitsSold,
          eventName: AD_SECONDARY_ATTRIBUTION_EVENT,
          isSelected: secondaryMetric === FirstTouchAttributionDropdownOption.UnitsSold
        },
        {
          displayName: 'Conversions',
          eventId: FirstTouchAttributionDropdownOption.Conversions,
          eventName: AD_SECONDARY_ATTRIBUTION_EVENT,
          isSelected: secondaryMetric === FirstTouchAttributionDropdownOption.Conversions
        }
      ]
    ];
  }, [primaryMetric, secondaryMetric]);

  const chartProps: Highcharts.Options = useMemo(
    () => ({
      chart: {
        spacingLeft: 8
      },
      exporting: {
        enabled: true,
        buttons
      },
      legend: {
        labelFormatter() {
          // Creates a legend item for each series
          let label = '';
          label = this.name === primaryMetric ? `% of ${primaryMetric}` : `% of ${secondaryMetric}`;

          const legendDivs: string[] = [];
          createNonComparisonLegendDivs({
            chartDisplayTimePeriod: mainTimePeriod,
            mainLegendMetricValue: {
              value: label
            },
            isSameMetricComparison: true,
            metricsDataByTimeSeries: null,
            legendDivs
          });

          return legendDivs[0];
        }
      },
      xAxis: {
        categories: ['Sponsored Products', 'DSP', 'Sponsored Brands', 'Sponsored Display']
      },
      yAxis: {
        labels: {
          formatter() {
            return `${this.value}%`;
          }
        }
      },
      tooltip: {
        shared: true,
        enabled: true,
        positioner(boxWidth, boxHeight, point) {
          return {
            x: point.plotX + this.chart.plotLeft - 35, // center the tooltip horizontally
            y: point.plotY + this.chart.plotTop - boxHeight - 10 // position the tooltip above the column
          };
        },
        formatter() {
          // Use chart data to drive our tooltips
          const toolTips = this.points.map((point) => {
            return {
              label: `${point.series.name}: ${formatMetric(point.y / 100, METRICTYPE.PERCENT, {
                decimalPlaces: 2,
                showFullValue: true
              })}`,
              color: point.series.color
            };
          });

          return getSimpleTooltipDiv(toolTips);
        }
      },

      plotOptions: {
        line: {
          marker: {
            lineColor: 'transparent'
          }
        },
        column: {
          dataLabels: {
            enabled: false,
            crop: false,
            overflow: 'none'
          }
        },
        barWidth: 65
      }
    }),
    [buttons, primaryMetric, secondaryMetric, mainTimePeriod, formatMetric]
  );

  const primaryColumnData: Highcharts.SeriesColumnOptions = useMemo(
    () => ({
      type: 'column',
      name: primaryMetric, // This determines which legend item to render
      data: data.primaryMetricSeries,
      color: colors.darkBlue
    }),
    [data, primaryMetric]
  );

  const secondaryColumnData: Highcharts.SeriesColumnOptions = useMemo(
    () => ({
      type: 'column',
      name: secondaryMetric,
      data: data.secondaryMetricSeries,
      color: colors.stacklineBlue
    }),
    [data, secondaryMetric]
  );

  return (
    <GenericBarChart
      columnData={primaryColumnData}
      secondaryColumnData={secondaryColumnData}
      chartProps={chartProps}
      convertSeriesToDelimitedData={() =>
        convertFirstTouchAttributionDataToCSV({
          data,
          dateRange: mainTimePeriod.displayName,
          primaryMetric,
          secondaryMetric
        })
      }
    />
  );
};

export default FirstTouchAttributionBarChart;
