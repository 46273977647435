import React, { createContext, useContext, useState } from 'react';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';

interface ForecastAdjustmentsTypeContextProvider {
  selectedPlanType: PlanTypeOption;
  setSelectedPlanType: React.Dispatch<React.SetStateAction<PlanTypeOption>>;
  adjustmentModalOpen: boolean;
  setAdjustmentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ForecastAdjustmentsTypeProviderProps {
  children: React.ReactNode;
}

export const ForecastAdjustmentsTypeContext = createContext<ForecastAdjustmentsTypeContextProvider>(null);

/**
 * This Provider prevents props drilling for Beacon Forecasts Pages. each empty planType table should open a create an adjustment modal.
 *
 */
export default function ForecastAdjustmentsTypeProvider({ children }: ForecastAdjustmentsTypeProviderProps) {
  const [selectedPlanType, setSelectedPlanType] = useState(PlanTypeOption.OrganicTraffic);
  const [adjustmentModalOpen, setAdjustmentModalOpen] = useState(false);
  return (
    <ForecastAdjustmentsTypeContext.Provider
      value={{ selectedPlanType, setSelectedPlanType, adjustmentModalOpen, setAdjustmentModalOpen }}
    >
      {children}
    </ForecastAdjustmentsTypeContext.Provider>
  );
}

export const useForecastAdjustmentsType = () => {
  const context = useContext(ForecastAdjustmentsTypeContext);
  return context;
};
