import { ThunkDispatch } from 'redux-thunk';

import Creators from './actions';
import parseBrandsFollowed from './selectors';
import { fetchUserMetadata } from 'src/utils/app';
import ReduxStore from 'src/types/store/reduxStore';

export const { clearBrandsFollowing, receiveBrandsFollowing } = Creators;

export function fetchBrandsFollowing() {
  return (dispatch: ThunkDispatch<ReduxStore, void, any>, getState: () => ReduxStore) => {
    const appName = getState().app.apiAppName;
    const name = `${appName}-brand-following`;

    fetchUserMetadata(appName, name).then((response) => {
      const brands = response.data[0].documents;
      return dispatch(receiveBrandsFollowing(parseBrandsFollowed(brands)));
    });
  };
}
