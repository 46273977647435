import React, { useState } from 'react';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import {
  KeywordColumnDefinition,
  ProductColumnDefinition,
  getWeekColumnDefinition
} from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import { removeComparisonFormatter } from 'src/components/BeaconRedesignComponents/MetricList/utils';
import ProductGrid from 'src/components/BeaconRedesignComponents/ProductGrid/ProductGrid';
import { useProductEntity } from 'src/utils/Hooks/reduxSelectors';

enum OrganicTrafficDropdownOption {
  Keyword = 'searchTerm',
  Product = 'stacklineSku'
}

const organicTrafficDropdownOptionToColumnDefinition = {
  [OrganicTrafficDropdownOption.Keyword]: KeywordColumnDefinition,
  [OrganicTrafficDropdownOption.Product]: ProductColumnDefinition
};

const organicTrafficDropdownOptionToDisplayName = {
  [OrganicTrafficDropdownOption.Keyword]: 'Keyword',
  [OrganicTrafficDropdownOption.Product]: 'Product'
};

const fields = [
  {
    name: 'organicClicks',
    displayName: 'Organic\nTraffic'
  },
  {
    name: 'adClicks',
    displayName: 'Paid\nTraffic'
  },
  {
    name: 'totalClicks',
    displayName: 'Total\nTraffic'
  },
  {
    name: 'adSpend',
    displayName: 'Paid\nAd Spend'
  },
  {
    name: 'adRetailSales',
    displayName: 'Paid\nAd Sales'
  }
];

/**
 * Grid for organic traffic by keyword and product
 */
export default function OrganicTrafficGrid() {
  const [dropdownOption, setDropdownOption] = useState<OrganicTrafficDropdownOption>(
    OrganicTrafficDropdownOption.Keyword
  );

  const { isProductEntity } = useProductEntity();
  const weekEndingFields = fields.map(removeComparisonFormatter);

  return isProductEntity ? (
    <MetricListContainer
      entityTableHeaderProps={{
        title: 'Organic Traffic by Week'
      }}
      indexName="traffic"
      sortFieldName="organicClicks"
      fields={weekEndingFields}
      headColumnDefinition={getWeekColumnDefinition({
        getTableColumnProps(defaults) {
          return {
            ...defaults,
            style: {
              ...defaults.style,
              width: '220px'
            }
          };
        }
      })}
      postProcessData={(data) => {
        return data.sort((a, b) => b.weekId.localeCompare(a.weekId));
      }}
      useTableDataProps={{
        groupByFieldName: 'weekId',
        filterResponse: () => true,
        itemsPerPage: 100
      }}
      showGrid={false}
    />
  ) : (
    <MetricListContainer
      skeletonRows={20}
      showGrid={dropdownOption === OrganicTrafficDropdownOption.Product}
      renderGrid={(args) => <ProductGrid {...args} />}
      headColumnDefinition={organicTrafficDropdownOptionToColumnDefinition[dropdownOption]}
      entityTableHeaderProps={{
        prefixTitle: 'Organic Traffic by',
        title: '',
        groupByFields: [OrganicTrafficDropdownOption.Keyword, OrganicTrafficDropdownOption.Product].map((option) => ({
          name: option,
          displayName: organicTrafficDropdownOptionToDisplayName[option]
        })),
        handleGroupByChange: (event) => setDropdownOption(event.target.value as OrganicTrafficDropdownOption)
      }}
      indexName="traffic"
      fields={fields}
      useTableDataProps={{
        groupByFieldName: dropdownOption,
        filterResponse: () => true
      }}
      sortFieldName="organicClicks"
    />
  );
}
