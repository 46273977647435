import React from 'react';
import removeOtherTraffic from 'src/components/BeaconRedesignComponents/utils/removeOtherTraffic';
import BeaconDropdownMetricColumnChart from 'src/components/BeaconRedesignComponents/BeaconMetricColumnChart/BeaconDropdownMetricColumnChart';
import BeaconMetricColumnChart from 'src/components/BeaconRedesignComponents/BeaconMetricColumnChart';
import { useAppSelector } from 'src/utils/Hooks';
import { useCreateRemoveRetailPriceRangeFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';

/**
 * Bar chart for the organic traffic page
 */
export default function OrganicTrafficBarChart() {
  // TODO: (Topics) Use aggregated-traffic index when available for topics

  const removeRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();
  const mainEntityType = useAppSelector((state) => state.entityService.mainEntity.type);

  return mainEntityType === 'product' ? (
    <BeaconMetricColumnChart
      fieldName="organicClicks"
      indexName="traffic"
      useAdvancedSearchDataArgs={{
        requestBuilder: (builder) => builder.apply(removeOtherTraffic),
        fields: [{ name: 'organicClicks' }],
        groupByField: 'searchTerm'
      }}
      beaconChartWithLegendPropsBuilder={(props) => ({
        ...props,
        title: 'Organic Traffic'
      })}
    />
  ) : (
    <BeaconDropdownMetricColumnChart
      fieldName="organicClicks"
      indexName={({ selectedDropdownId }) =>
        selectedDropdownId === 'searchTerm' || mainEntityType === 'searchtermlist' ? 'traffic' : 'aggregated-traffic'
      }
      dropdownOptions={[
        {
          id: 'searchTerm',
          label: 'Keyword'
        },
        {
          id: 'categoryId',
          label: 'Category'
        },
        {
          id: 'subCategoryId',
          label: 'Subcategory'
        },
        {
          id: 'brandId',
          label: 'Brand'
        },
        {
          id: 'stacklineSku',
          label: 'Product'
        }
      ].filter((option) => !option.id.toLowerCase().startsWith(mainEntityType))}
      useAdvancedSearchDataArgs={{
        requestBuilder: (builder) => builder.apply(removeOtherTraffic).apply(removeRetailPriceRangeFilters())
      }}
      titlePrefix="Organic Traffic by"
    />
  );
}
