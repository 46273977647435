import _merge from 'lodash/merge';

import { ENTITIES, INDEX_FIELDS } from 'src/utils/entityDefinitions';
import EntityGrid from 'src/components/EntityGrid/EntityGrid';
import VIEWS from '../ViewDefaultConfig';

export const buildKeyMetricsEntityGridWidget = ({ tab, app, indexName }) => ({
  CustomComponent: EntityGrid,
  view: _merge({}, VIEWS.entityGrid, {
    gridOptions: {
      enableGroupBy: true
    }
  }),
  data: {
    groupByFields: [
      INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product'),
      INDEX_FIELDS.getField(app.name, indexName, 'brandId', 'product')
    ],
    configByGroupByFieldName: {
      stacklineSku: {
        indexName,
        entity: ENTITIES.beacon.product,
        mainMetricField: INDEX_FIELDS.getField(
          app.name,
          indexName,
          tab === 'advertising' ? 'sales' : tab === 'compliance' ? 'unauthorized' : 'retailSales',
          'product',
          'stacklineSku'
        ),
        aggregationFields: [
          INDEX_FIELDS.getField(
            app.name,
            indexName,
            tab === 'advertising' ? 'sales' : tab === 'compliance' ? 'unauthorized' : 'retailSales',
            'product',
            'stacklineSku'
          )
        ]
      },
      brandId: {
        indexName,
        entity: ENTITIES.beacon.brand,
        mainMetricField: INDEX_FIELDS.getField(
          app.name,
          indexName,
          tab === 'advertising' ? 'sales' : tab === 'compliance' ? 'unauthorized' : 'retailSales',
          'brand',
          'brandId'
        ),
        aggregationFields: [
          INDEX_FIELDS.getField(
            app.name,
            indexName,
            tab === 'advertising' ? 'sales' : tab === 'compliance' ? 'unauthorized' : 'retailSales',
            'brand',
            'brandId'
          )
        ]
      }
    }
  }
});
