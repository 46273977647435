import { Chip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { hexToRgba, useStacklineTheme } from '@stackline/ui';
import React, { useEffect, useState } from 'react';
import { withBus } from 'react-bus';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List as VirtualizedList } from 'react-virtualized';
import { QUICK_EXCLUDE_EVENT } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Segments/SegmentBuilder';
import {
  BlueCancel,
  SelectedExcludeCheckbox,
  SelectedIncludeCheckbox,
  UnselectedCheckbox
} from 'src/components/SvgIcons/SvgIcons';
import CustomizedCheckbox from 'src/components/common/CustomizedCheckBox/CustomizedCheckBox';
import { useBus } from 'src/utils/Hooks';
import { shouldShowNewBeacon } from 'src/utils/app';

interface UpdatedKeywordSearchProps {
  idFieldName: string;
  headerDisplayName: string;
  searchFieldHintText: string;
  keywords: { i: string }[];
  onKeywordsChange: (newChange: { key: string; values: { i: string }[] }) => void;
  eventBus: Function;
  key: any;
  parent: any;
  index: any;
}

const SEARCH_PARAMETER_LIMIT = 500;
const NEW_BEACON_ITEM_HEIGHT = 33;

const UpdatedKeywordSearch: React.FC<UpdatedKeywordSearchProps> = ({
  idFieldName,
  headerDisplayName,
  searchFieldHintText,
  keywords,
  onKeywordsChange,
  eventBus
}) => {
  const [keywordChips, setKeywordChips] = useState([]);
  const [keyword, setKeyword] = useState('');

  function updateKeywords() {
    setKeywordChips(keywords.map((word, index) => ({ key: index, i: word.i })));
  }

  useEffect(() => {
    updateKeywords();
  }, [keywords]);

  const clearInput = () => {
    setKeyword('');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const handleKeywordsChange = (chips: { i: string }[]) => {
    onKeywordsChange({ key: idFieldName, values: chips.map((word) => ({ i: word.i })) });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && e.target.value !== '') {
      const updatedKeywordChips = [...keywordChips, { key: keywordChips.length, i: e.target.value }];
      setKeywordChips(updatedKeywordChips);
      handleKeywordsChange(updatedKeywordChips);
      clearInput();
    }
  };

  const handleRequestDelete = (key: number, clearAll = false) => {
    if (clearAll === true) {
      setKeywordChips([]);
      handleKeywordsChange([]);
    } else {
      const chipToDelete = keywordChips.map((chip) => chip.key).indexOf(key);
      keywordChips.splice(chipToDelete, 1);
      setKeywordChips(keywordChips.map((chip, index) => ({ key: index, i: chip.i })));
      handleKeywordsChange(keywordChips);
    }
  };

  const handleClear = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    handleRequestDelete(undefined, true);
  };

  function handleQuickExclude(term: string) {
    if (idFieldName === 'excludedSearchTerm' || idFieldName === 'excludedKeyword') {
      const updatedKeywordChips = [...keywordChips, { key: keywordChips.length, i: term }];
      setKeywordChips(updatedKeywordChips);
      handleKeywordsChange(updatedKeywordChips);
    }
  }

  useBus(eventBus, QUICK_EXCLUDE_EVENT, handleQuickExclude);

  const rowRenderer = ({ index, style }) => {
    const listItem = keywordChips[index];
    const handleCheck = (i: number) => {
      handleRequestDelete(i);
    };
    return (
      <div key={listItem.key} style={style}>
        <FormControlLabel
          control={
            <CustomizedCheckbox
              styleObj={{
                width: 14,
                height: 14,
                iconBackgroundColor: 'transparent',
                iconColor: 'transparent',
                checkedIconBackgroundColor: 'transparent',
                checkedIconColor: 'transparent'
              }}
              InnerIcon={
                headerDisplayName.toLowerCase().includes('exclude') ? SelectedExcludeCheckbox : SelectedIncludeCheckbox
              }
              OuterIcon={UnselectedCheckbox}
              checkboxProps={{
                checked: true,
                style: { marginLeft: '2px' },
                onChange: () => {
                  handleCheck(index);
                }
              }}
            />
          }
          label={
            <span style={{ fontSize: '12px', fontWeight: '500', letterSpacing: 'normal' }}>{listItem.i || ''}</span>
          }
        />
      </div>
    );
  };

  // Caches row heights for many list items in our virtualized list
  // Currently exhibits weird behavior when placed outside of component; we may not be receiving performance benefits with the way it is setup currently.
  // React Docs advise against using Refs for rendering but we may be able to expose the ref properly with a wrapper function that returns just the "current" property.
  const cache = new CellMeasurerCache({
    defaultHeight: 45,
    fixedWidth: true
  });

  const newBeaconRowRenderer = ({ index, style, theme }) => {
    const listItem = keywordChips[index];
    const handleCheck = (i: number) => {
      handleRequestDelete(i);
    };
    return (
      <div style={style}>
        <Chip
          sx={{
            '&.MuiChip-root': { height: '25px' },
            '& .MuiChip-label': { paddingLeft: '8px', paddingRight: '8px' },
            '& .MuiChip-deleteIcon': {
              margin: '0 8px 0 0'
            },
            maxWidth: '108px',
            backgroundColor: hexToRgba(theme.colors.primaryLight, 0.2)
          }}
          deleteIcon={<BlueCancel style={{ width: '12px', height: '12px' }} />}
          label={
            <div style={{ display: 'flex', height: '21px', alignItems: 'center' }}>
              <span
                style={{
                  fontSize: '12px',
                  color: theme.colors.primary,
                  maxWidth: '54px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {listItem.i || ''}
              </span>
            </div>
          }
          onDelete={() => handleCheck(index)}
        />
      </div>
    );
  };

  const renderRow = shouldShowNewBeacon() ? newBeaconRowRenderer : rowRenderer;
  const theme = useStacklineTheme();
  const textFieldDefaultColor = shouldShowNewBeacon() ? '#e8e8ed' : '#dedede';

  return (
    <div className={shouldShowNewBeacon() ? 'updated-form-container' : 'search-form-container'}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          gap: '15px',
          ...(shouldShowNewBeacon() ? { marginBottom: '4px', gap: '8px' } : {})
        }}
      >
        <h4 className="sl-form-label">{headerDisplayName}</h4>
        <button onClick={(e) => handleClear(e)} className="clear-btn">
          Clear
        </button>
        {keywords.length > SEARCH_PARAMETER_LIMIT && (
          <h4 className="sl-form-label" style={{ color: 'red' }}>
            Maximum number of terms is {SEARCH_PARAMETER_LIMIT}
          </h4>
        )}
      </div>

      <TextField
        variant="standard"
        autoComplete="off"
        className="updated-sl-form-input"
        sx={{
          '&::placeholder': {
            fontSize: '12px',
            color: theme.colors.secondary
          },
          '& .MuiInput-underline:before': {
            borderBottomColor: textFieldDefaultColor
          },
          '& .MuiInput-underline::hover:before': {
            borderBottomColor: textFieldDefaultColor
          },
          '& .MuiInputBase-root:before': {
            borderBottomColor: textFieldDefaultColor
          },
          '& .MuiInputBase-root::hover:before': {
            borderBottomColor: textFieldDefaultColor
          }
        }}
        placeholder={searchFieldHintText}
        type="text"
        name="keyword"
        id="keyword"
        disabled={keywords.length > SEARCH_PARAMETER_LIMIT}
        style={shouldShowNewBeacon() ? { width: '100%', marginBottom: '16.8px' } : { width: '100%' }}
        value={keyword}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />

      {shouldShowNewBeacon() ? (
        <AutoSizer disableHeight>
          {() => (
            <VirtualizedList
              width={170}
              height={Math.min(NEW_BEACON_ITEM_HEIGHT * keywordChips.length, 100)} // We use an "auto" height for the first 3 items and then constrain the virtualized list height
              rowCount={keywordChips.length}
              rowHeight={NEW_BEACON_ITEM_HEIGHT}
              rowRenderer={({ ...props }) => {
                // eslint-disable-next-line react/prop-types
                const { key, parent, index, style } = props;
                return (
                  <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                    {renderRow({ ...props, style, theme })}
                  </CellMeasurer>
                );
              }}
            />
          )}
        </AutoSizer>
      ) : (
        <AutoSizer disableHeight>
          {() => (
            <VirtualizedList
              width={170}
              height={keywordChips.length < 5 ? keywordChips.length * 45 : 175}
              rowCount={keywordChips.length}
              rowHeight={cache.rowHeight}
              rowRenderer={({ ...props }) => {
                const { key, parent, index } = props;
                return (
                  <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                    {renderRow({ ...props, theme })}
                  </CellMeasurer>
                );
              }}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
};

export default withBus('eventBus')(UpdatedKeywordSearch);
