import React from 'react';
import numeral from 'numeral';
import moment from 'moment';

import './CampaignColumn.scss';

const ProjectedBudgetColumn = ({ value }: { data: any; value: any; adCampaigns: any[] }) => {
  if (!value) {
    return null;
  }

  const projectedSpendAmount = value.value || null;
  const projectedSpendDisplay = projectedSpendAmount === null ? '--' : numeral(projectedSpendAmount).format('$0.00a');

  return (
    <div className="budget_column">
      <div className="secondary_label">{`${projectedSpendDisplay.toUpperCase()} projected spend in ${moment().format(
        'MMM YYYY'
      )}`}</div>
    </div>
  );
};

export default ProjectedBudgetColumn;
