import types from './types';

export const startFetchMapData = (entity: any) => ({
  type: types.START_FETCH,
  entity
});

export const receiveGeoMapData = (entity: any) => ({
  type: types.RECEIVE_GEO_MAP_DATA,
  entity
});

export const endFetchMapData = (entity: any) => ({
  type: types.END_FETCH,
  entity
});

export const deleteMapData = (entity: any) => ({
  type: types.DELETE_DATA,
  entity
});
