import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import queryString from 'qs';
import moment from 'moment';
import Truncate from 'react-truncate';

import Dialog from '@mui/material/Dialog';
import { AppButton } from '@stackline/ui';
import Chip from '@mui/material/Chip';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';

import { addPersistentQueryParams } from 'src/utils/browser';
import colors from 'src/utils/colors';
import { getProductImageUrl, getRetailerLogoUrl } from 'src/utils/image';
import { DeleteIcon } from '../SvgIcons';
import Stars from './Stars';
import noResponseIcon from './no-response.svg';
import respondedIcon from './responded.svg';
import attachmentIcon from './attachment.svg';
import verifiedIcon from './verified.svg';
import { AppName } from 'sl-api-connector';
import './ReviewTile.scss';
import { shouldShowNewBeacon } from 'src/utils/app';
import ProductReviewCard from '../BeaconRedesignComponents/ProductReviewCard/ProductReviewCard';

const styles = {
  respondedIconSvg: {
    verticalAlign: 'middle',
    width: '20px',
    height: '20px',
    marginRight: '-4px',
    marginTop: '-2px',
    stroke: colors.green
  },
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    marginBottom: '4px',
    alignItems: 'center'
  },
  attachmentIcon: {
    verticalAlign: 'middle',
    width: '16px',
    height: '16px'
  },
  replyButton: {
    marginTop: '10px',
    color: colors.darkBlue
  },
  verifiedPurchaseIcon: {
    verticalAlign: 'middle',
    height: '22px',
    width: '22px',
    marginLeft: '-4px',
    marginTop: '-2px'
  },
  responseSvg: {
    verticalAlign: 'middle',
    width: '20px',
    height: '20px',
    marginLeft: '4px',
    marginTop: '-2px'
  }
};

const reviewPropType = PropTypes.shape({
  timestamp: PropTypes.number.isRequired,
  user: PropTypes.shape({ username: PropTypes.string.isRequired }).isRequired,
  comments: PropTypes.array,
  hasManufacturerResponded: PropTypes.bool.isRequired,
  reviewTitle: PropTypes.string.isRequired,
  reviewText: PropTypes.string.isRequired,
  videoUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  isVerified: PropTypes.bool.isRequired
});

const formatReviewTimestamp = (timestamp) => moment(timestamp * 1000).format('MMMM DD, YYYY');

const Comment = ({
  comment: {
    commentId,
    commentText,
    isManufacturer,
    user: { username }
  },
  review
}) => (
  <div key={`${commentId}${commentText}`}>
    <p
      className={`review-dialog__commented-on ${isManufacturer ? 'review-dialog__commented-on--is-manufacturer' : ''}`}
    >
      {username} commented on <strong style={{ fontWeight: '500' }}>{formatReviewTimestamp(review.timestamp)}</strong>:
    </p>
    <div
      className={`review-dialog__manufacturer-response-text ${
        isManufacturer ? 'review-dialog__manufacturer-response-text--is-manufacturer' : ''
      }`}
    >
      {commentText}
    </div>
  </div>
);

Comment.propTypes = {
  review: reviewPropType.isRequired,
  comment: PropTypes.object.isRequired
};

const AttachmentIcon = ({ review }) => {
  if ((!review.imageUrls || _isEmpty(review.imageUrls.length)) && (!review.videoUrls || _isEmpty(review.videoUrls))) {
    return null;
  }

  return (
    <svg style={styles.attachmentIcon} viewBox={attachmentIcon.viewBox}>
      <use xlinkHref={`#${attachmentIcon.id}`} />
    </svg>
  );
};

AttachmentIcon.propTypes = {
  review: reviewPropType.isRequired
};

const ReduxPropTypes = {
  app: PropTypes.object.isRequired,
  review: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  mainTimePeriod: PropTypes.object.isRequired,
  entityService: PropTypes.object.isRequired
};

const addReduxProps = (Comp, additionalPropTypes = {}) => {
  Comp.propTypes = { ...ReduxPropTypes, ...additionalPropTypes };

  return compose(
    withRouter,
    connect((state) => {
      return _pick(state, ['app', 'review', 'retailer', 'mainTimePeriod', 'entityService']);
    })
  )(Comp);
};

const VerifiedPurchase = addReduxProps(({ app, review }) => {
  const isOmni = app.name === AppName.Omni;
  const styleUse = isOmni ? { display: 'flex', gap: 10, alignItems: 'center' } : {};
  return (
    <div className="review__verified-purchase" style={styleUse}>
      {isOmni ? (
        <img
          style={{
            maxWidth: 25,
            maxHeight: 25
          }}
          alt="logo"
          src={getRetailerLogoUrl(String(_get(review, 'retailerId')))}
        />
      ) : (
        <svg style={styles.verifiedPurchaseIcon} viewBox={verifiedIcon.viewBox}>
          <use xlinkHref={`#${verifiedIcon.id}`} />
        </svg>
      )}
      <span>Verified Purchase</span>
    </div>
  );
});

const UnverifiedPurchase = addReduxProps(({ app, review }) => {
  const isOmni = app.name === AppName.Omni;
  const styleUse = isOmni ? { display: 'flex', gap: 10, alignItems: 'center' } : {};
  return (
    <div className="review__verified-purchase review__verified-purchase--unverified" style={styleUse}>
      {isOmni ? (
        <img
          style={{
            maxWidth: 25,
            maxHeight: 25
          }}
          alt="logo"
          src={getRetailerLogoUrl(String(_get(review, 'retailerId')))}
        />
      ) : null}
      Unverified Purchase
    </div>
  );
});

const PurchaseVerificationStatus = ({ review }) =>
  review.isVerified ? <VerifiedPurchase review={review} /> : <UnverifiedPurchase review={review} />;

PurchaseVerificationStatus.propTypes = {
  review: reviewPropType.isRequired
};

const MediaContent = ({ review }) => {
  const renderedAttachmentIcon = <AttachmentIcon review={review} />;

  if (renderedAttachmentIcon === null) {
    return null;
  }

  return (
    <div className="review-dialog__media-content">
      {(review.videoUrls || []).map((url) => (
        <a key={url} target="_blank" rel="noopener noreferrer" href={url}>
          <video controls>
            <source src={url} />
          </video>
        </a>
      ))}

      {(review.imageUrls || []).map((url) => (
        <a key={url} target="_blank" rel="noopener noreferrer" href={url}>
          <img src={url} alt="Review" />
        </a>
      ))}
    </div>
  );
};

MediaContent.propTypes = {
  review: reviewPropType.isRequired
};

const InnerReviewDialog = ({ review, replyButton }) => (
  <div className="review-dialog__review">
    <div className="review-dialog__customer-review-container">
      <div className="review-dialog__customer-review">
        <h2 className="review-dialog__customer-review-text">Customer Review</h2>
        <div style={{ marginTop: '20px', marginBottom: '5px' }}>
          <PurchaseVerificationStatus review={review} />
        </div>
        <div className="review-dialog__customer-review-header">
          <div className="review-dialog__icon-container">
            <Stars review={review} />
          </div>
          <div className="review-dialog__date-container">{formatReviewTimestamp(review.timestamp)}</div>
        </div>
        <div className="review-dialog__author" style={{ color: '#717171' }}>
          By {review.user.username} <AttachmentIcon review={review} />
        </div>
        <div className="review-dialog__title">{review.reviewTitle}</div>
        <div className="review-dialog__text">{review.reviewText}</div>
        <MediaContent review={review} />
        <div style={{ marginTop: '20px' }}>
          {(review.comments || []).map((comment) => (
            <Comment key={comment} review={review} comment={comment} />
          ))}
        </div>
        <div style={{ textAlign: 'right' }}>{replyButton}</div>
      </div>
    </div>
  </div>
);

const stopPropagation = (event) => {
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();
};

const ReviewDialog = addReduxProps(
  ({ app, review, retailer, location, mainTimePeriod, isDialogOpen, toggleReviewDialog }) => {
    const { targetUrl } = app;
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const { tab, subtab } = queryParams;
    const linkedSubtab = tab !== 'reviews' ? 'reviewMetrics' : subtab;
    const newWindowLocation = `https://${targetUrl}/api/utility/OpenRetailerProductReviewPage?appName=${app.name}&retailerId=${retailer.id}&stacklineSku=${review.stacklineSku}&reviewId=${review.reviewId}`;

    const replyButton = (
      <AppButton backgroundColor="#fff" style={styles.replyButton} onClick={stopPropagation}>
        <a href={`${newWindowLocation}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          Reply
        </a>
      </AppButton>
    );

    const persistentQueryParams = addPersistentQueryParams(retailer, mainTimePeriod);
    const allQueryParams = `?${persistentQueryParams}&tab=reviews&subtab=${linkedSubtab}&ctype=metric&ctab=reviews&csubtab=reviewAverage`;
    const createToUrl = (path) => `${path}${allQueryParams}`;

    return (
      <Dialog scroll="body" open={isDialogOpen}>
        <div className="review-dialog__header" style={{ borderBottom: '1px solid #F6F9FC' }}>
          <div className="review-dialog__product-image">
            <Link to={createToUrl(`/product/${review.stacklineSku}`)} onClick={stopPropagation}>
              <img alt="product" className="review-dialog__image" src={getProductImageUrl(review.stacklineSku)} />
            </Link>
          </div>
          <div className="review-dialog__product-name-container">
            <div className="review-dialog__brand-name">
              <Link to={createToUrl(`/brand/${review.product.brandId}`)} onClick={stopPropagation}>
                {review.product.brandName}
              </Link>
            </div>
            <div className="review-dialog__product-name">
              <Link to={`/product/${review.stacklineSku}`} onClick={stopPropagation}>
                {review.product.title}
              </Link>
            </div>
          </div>
          <div className="review-dialog__manufacturer">
            <Chip
              className={`chip ${review.hasManufacturerResponded ? 'chip--green' : ''} `}
              label={<Manufacturer review={review} isDialog />}
            ></Chip>
          </div>
          <DeleteIcon className="review-dialog__close" onClick={toggleReviewDialog} />
        </div>
        <hr className="sl-divider sl-divider--no-margin-top sl-divider--no-margin-bottom" />
        <InnerReviewDialog review={review} replyButton={replyButton} />
      </Dialog>
    );
  },
  { isDialogOpen: PropTypes.bool.isRequired, toggleReviewDialog: PropTypes.func.isRequired }
);

InnerReviewDialog.propTypes = {
  review: reviewPropType,
  replyButton: PropTypes.element.isRequired
};

const Product = addReduxProps(({ app, review, entityService, retailer, location, mainTimePeriod }) => {
  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const { tab, subtab } = queryParams;
  const isOmni = app.name === AppName.Omni;
  if (entityService.mainEntity.type === 'product') {
    return null;
  }
  const linkedSubtab = tab !== 'reviews' ? 'reviewMetrics' : subtab;
  return (
    <div>
      <hr className="sl-divider sl-divider--no-margin-bottom sl-divider--no-margin-top" />
      <div className="review__footer">
        <div className="review__image-container">
          <Link
            to={`/product/${review.stacklineSku}?${addPersistentQueryParams(
              retailer,
              mainTimePeriod
            )}&tab=reviews&subtab=${linkedSubtab}&ctype=metric&ctab=reviews&csubtab=reviewAverage`}
            onClick={stopPropagation}
          >
            <img alt="product" className="review__image" src={getProductImageUrl(review.stacklineSku)} />
          </Link>
        </div>
        <div className="review__product-name">
          <Link
            to={`/product/${isOmni ? review.gtin : review.stacklineSku}?${addPersistentQueryParams(
              retailer,
              mainTimePeriod
            )}&tab=reviews&subtab=${linkedSubtab}&ctype=metric&ctab=reviews&csubtab=reviewAverage`}
            onClick={stopPropagation}
          >
            <Truncate lines={2} ellipsis="...">
              {_get(review, ['product', 'title'], '')}
            </Truncate>
          </Link>
        </div>
      </div>
    </div>
  );
});

const NoReply = () => (
  <div>
    <span>No Reply</span>
    <svg style={styles.responseSvg} viewBox={noResponseIcon.viewBox}>
      <use xlinkHref={`#${noResponseIcon.id}`} />
    </svg>
  </div>
);

const ManufacturerResponse = ({ isDialog }) => (
  <div className={`review${isDialog ? '-dialog' : ''}__manufacturer-responded`}>
    <span>Replied</span>
    <svg style={styles.responseSvg} viewBox={respondedIcon.viewBox}>
      <use xlinkHref={`#${respondedIcon.id}`} />
    </svg>
  </div>
);

ManufacturerResponse.propTypes = {
  isDialog: PropTypes.bool.isRequired
};

const Manufacturer = ({ review, isDialog }) => {
  if (!isDialog) {
    return null;
  }

  const ResponseElement = review.hasManufacturerResponded ? ManufacturerResponse : NoReply;

  return (
    <div className={`review${isDialog ? '-dialog' : ''}__manufacturer-response`}>
      <ResponseElement isDialog={isDialog} />
    </div>
  );
};

Manufacturer.propTypes = {
  review: reviewPropType.isRequired,
  isDialog: PropTypes.bool.isRequired
};

const ReviewTile = ({ review }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleReviewDialog = () => setIsDialogOpen(!isDialogOpen);

  if (shouldShowNewBeacon()) {
    return <ProductReviewCard review={review} />;
  }

  return (
    <div // eslint-disable-line
      className="review"
      onClick={toggleReviewDialog}
    >
      <div style={styles.root}>
        <div>
          <PurchaseVerificationStatus review={review} />
        </div>
        <div style={{ display: review.hasManufacturerResponded ? 'flex' : 'none' }}>
          <svg style={styles.respondedIconSvg} viewBox={respondedIcon.viewBox}>
            <use xlinkHref={`#${respondedIcon.id}`} />
          </svg>
        </div>
      </div>
      <div className="review__header">
        <div className="review__icon-container">
          <Stars review={review} />
        </div>
        <div className="review__date-container">{formatReviewTimestamp(review.timestamp)}</div>
      </div>
      <div className="review__content">
        <div className="review__author">
          {review.user ? `By ${review.user.username} ` : ''}
          <AttachmentIcon review={review} />
        </div>
        <Truncate lines={1} ellipsis="...">
          {review.reviewTitle}
        </Truncate>
        <div className="review__text">
          <Truncate lines={2} ellipsis="...">
            {review.reviewText}
          </Truncate>
        </div>
      </div>
      <Product review={review} />
      <ReviewDialog review={review} isDialogOpen={isDialogOpen} toggleReviewDialog={toggleReviewDialog} />
    </div>
  );
};

ReviewTile.propTypes = {
  review: PropTypes.object.isRequired
};

export default ReviewTile;
