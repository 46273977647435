import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import Truncate from 'react-truncate';
import Chip from '@mui/material/Chip';
import _pick from 'lodash/pick';

import colors from 'src/utils/colors';
import 'src/routes/HomePage/Segments.scss';

class SubCategorySidebarCard extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired
  };

  render() {
    const { app, entityService } = this.props;
    const { searchParams } = app.queryParams;
    if (_isEmpty(entityService.mainEntity)) {
      return null;
    }
    const { subCategoryName } = entityService.mainEntity;
    const shortName = subCategoryName ? subCategoryName.slice(0, 2) : '';
    const subtab = app.name === 'atlas' ? 'retailSales' : 'keymetrics';

    return (
      <div className="nav-header-container">
        <div className="sl-image-container">
          <div className="sl-image-center">
            <NavLink style={{ textDecoration: 'none' }} to={`${searchParams}&tab=sales&subtab=${subtab}`}>
              <div className="segment__short-name-container" style={{ backgroundColor: colors.stacklineBlue }}>
                <div className="segment__short-name">{shortName}</div>
              </div>
            </NavLink>
          </div>
        </div>

        <div className="nav-name-container" title={subCategoryName}>
          <Truncate lines={2} ellipsis="...">
            <h4 className="nav-company-name">{subCategoryName}</h4>
          </Truncate>
        </div>

        <Chip className="entity-chip" label="Subcategory" />
      </div>
    );
  }
}
const mapStateToProps = (state) => _pick(state, ['app', 'entityService']);

export default withRouter(connect(mapStateToProps)(SubCategorySidebarCard));
