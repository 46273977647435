import React from 'react';
import PropTypes from 'prop-types';
import invalidContentIcon from '../../SvgIcons/invalid-content.svg';
import validContentIcon from '../../SvgIcons/valid-content.svg';

class Verification extends React.Component {
  static propTypes = {
    isValid: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired
  };

  render() {
    const { isValid, type } = this.props;

    if (isValid) {
      return (
        <div className={`accuracy__item-verification accuracy__item-verification--${type}`}>
          <svg className="accuracy__icon accuracy__icon--valid" viewBox={validContentIcon.viewBox}>
            <use xlinkHref={`#${validContentIcon.id}`} />
          </svg>
        </div>
      );
    }

    return (
      <div className={`accuracy__item-verification accuracy__item-verification--${type}`}>
        <svg className="accuracy__icon accuracy__icon--invalid" viewBox={invalidContentIcon.viewBox}>
          <use xlinkHref={`#${invalidContentIcon.id}`} />
        </svg>
      </div>
    );
  }
}

export default Verification;
