import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';
import queryString from 'qs';
import MenuItem from '@mui/material/MenuItem';
import _pick from 'lodash/pick';

import { extractQueryConditions, comparisonParams, filterParams } from 'src/utils/segments';
import { addQueryParams } from 'src/utils/browser';
import { buildShortName } from 'src/utils/stringFormatting';
import { CompareBrandListLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';

class Segments extends React.Component {
  static defaultProps = {
    searchText: '',
    togglePanel: () => {}
  };

  static propTypes = {
    location: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    searchText: PropTypes.string,
    togglePanel: PropTypes.func,
    history: PropTypes.object.isRequired
  };

  state = {};

  componentDidMount() {
    const { app } = this.props;
    axios.get(`/api/${app.name}/GetAllSavedSearch?appName=${app.apiAppName}`).then((response) => {
      const segments = [];
      response.data.forEach((value) => {
        const parsedValue = JSON.parse(value.query);
        if (parsedValue.dn) {
          const shortName = buildShortName(parsedValue.dn);
          segments.push({
            id: parsedValue.id,
            type: 'segment',
            displayName: parsedValue.dn,
            data: parsedValue,
            shortName
          });
        }
      });

      this.segments = segments;
      this.segments.sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));
      this.setState({ segments: this.segments });
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      segments: this.segments.filter((val) =>
        val.displayName.toLowerCase().includes(nextProps.searchText.toLowerCase())
      )
    });
  }

  handleTouchTap = (selection) => {
    const { togglePanel, location, retailer, mainTimePeriod } = this.props;
    const entity = {
      id: selection.id,
      type: selection.type
    };
    let segmentUrlParams = null;
    if (entity.type === 'segment' || entity.type === 'searchtermlist') {
      const conditions = extractQueryConditions(selection.data, true, true);
      delete conditions.id;
      segmentUrlParams = queryString.stringify(conditions);
    }
    const existingParams = addQueryParams(location, retailer, mainTimePeriod, [...filterParams, ...comparisonParams]);

    togglePanel();
    this.props.history.push(`?${existingParams}&ctype=${entity.type}&cid=${entity.id}&${segmentUrlParams}`);
  };

  showNoSegments() {
    return this.state.segments.length > 0 ? null : <div style={{ padding: '10px' }}>No segments found.</div>;
  }

  render() {
    const { segments } = this.state;

    if (!segments) {
      return <CompareBrandListLoading />;
    }

    return (
      <div style={{ padding: 10 }}>
        {segments.map((val) => (
          <MenuItem key={val.id} style={{ fontSize: 14 }} onClick={() => this.handleTouchTap(val)}>
            {val.displayName}
          </MenuItem>
        ))}
        {this.showNoSegments()}
      </div>
    );
  }
}

const mapStateToProps = (state) => _pick(state, ['app', 'retailer', 'mainTimePeriod']);

const EnhancedSegments = withRouter(connect(mapStateToProps)(Segments));

export default EnhancedSegments;
