import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useStacklineTheme, StacklineTheme, hexToRgba } from '@stackline/ui';
import { FormControlLabel, Checkbox, CheckboxProps, Divider } from '@mui/material';
import { SelectedIncludeCheckbox } from 'src/components/SvgIcons';
import { CloseIcon, SelectedDisabledCheckbox } from 'src/components/SvgIcons/SvgIcons';
import {
  useExportDropdownOption,
  useExportDataFunction,
  useExportCustomOptionBuilder
} from 'src/components/BeaconRedesignComponents/common/AppExportModal/exportDataHooks';
import { ExportContext } from 'src/providers/ExportContextProvider';
import { ExportDropdownOptionIds } from 'src/components/BeaconRedesignComponents/common/AppExportModal/exportDataConfigData';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'src/utils/Hooks';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BuildExportRequestFunction } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';

interface GenericStyledDialogueProps {
  theme: StacklineTheme;
}

const GenericStyledDialogue = styled(Dialog)<GenericStyledDialogueProps>(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '640px',
    height: '545px',
    boxShadow: `0 0 ${theme.spacing.md} 0 ${hexToRgba(theme.colors.primary, 0.4)}`,
    borderRadius: theme.spacing.sm
  }
}));

const ExportCheckBox = ({
  label,
  isSelected,
  id,
  onSelect,
  checkboxProps
}: {
  label: string;
  isSelected: boolean;
  id: string;
  onSelect: (e: ChangeEvent<HTMLInputElement>) => void;
  checkboxProps?: CheckboxProps;
}) => {
  const theme = useStacklineTheme();
  return (
    <FormControlLabel
      title={label}
      sx={{
        color: theme.colors.primary,
        height: '26px',
        marginLeft: '-8px'
      }}
      checked={isSelected}
      label={label}
      value={id}
      control={
        <Checkbox
          checkedIcon={
            checkboxProps && checkboxProps.disabled ? (
              <SelectedDisabledCheckbox
                style={{ width: '18px', height: '18px', marginLeft: '-2px', marginRight: '-2px' }}
              />
            ) : (
              <SelectedIncludeCheckbox style={{ width: '14px', height: '14px' }} />
            )
          }
          icon={
            <div
              style={{
                width: '14px',
                height: '14px',
                border: '1px solid #9ea9b5',
                backgroundColor: '#fff',
                borderRadius: theme.spacing.xxs
              }}
            ></div>
          }
          disableRipple
          disableTouchRipple
          sx={{
            '&, &.Mui-checked': {
              color: theme.colors.primary
            },
            '&.Mui-disabled': {
              color: theme.colors.secondary
            }
          }}
          {...checkboxProps}
        />
      }
      key={id}
      onChange={(e) => onSelect(e)}
    />
  );
};

interface AppFilterModalProps {
  open: boolean;
  handleClose: () => void;
  buildExportRequest?: BuildExportRequestFunction;
}

const AppExportModal = ({ open, handleClose, buildExportRequest }: AppFilterModalProps) => {
  const theme = useStacklineTheme();
  const { getGroupByWeekAvailableFunction, forceWeekSeparation } = useExportCustomOptionBuilder();
  const [isSeparateWeek, setIsSeparateWeek] = useState(forceWeekSeparation);
  const [isIncludeComparison, setIsIncludeComparison] = useState(false);
  const [selectedGroupBy, setSelectedGroupBy] = useState<string>(ExportDropdownOptionIds.PRODUCT);
  const { dropdownOptions } = useExportDropdownOption();
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false);
  const [shouldShowSeparateDataByWeek, setShouldShowSeparateDataByWeek] = useState(true);
  const { setIsTriggered, setShouldStartPolling } = useContext(ExportContext);
  const { showSnackbar } = useSnackbar();
  const { exportRequestFunction, shouldStartPolling: isRequestAccepted } = useExportDataFunction({
    groupByFieldName: isSeparateWeek ? `${selectedGroupBy},weekId` : selectedGroupBy,
    includeCompTimePeriod: isIncludeComparison,
    buildExportRequest
  });

  const handleExportButton = () => {
    setIsTriggered(true);
    setExportButtonDisabled(true);
    exportRequestFunction();
    showSnackbar({ type: 'success', message: 'Your download is processing.' });
  };

  useEffect(() => {
    if (isRequestAccepted) {
      setShouldStartPolling(true);
      handleClose();
      setExportButtonDisabled(false);
    }
  }, [handleClose, isRequestAccepted, setShouldStartPolling]);

  useEffect(() => {
    setSelectedGroupBy(dropdownOptions[0].id);
  }, []);

  const handleDropdownChange = (exportDropdownOptionId: ExportDropdownOptionIds) => {
    setSelectedGroupBy(exportDropdownOptionId);
    const isGroupByWeekAvailable = getGroupByWeekAvailableFunction(exportDropdownOptionId);
    setShouldShowSeparateDataByWeek(isGroupByWeekAvailable);
  };

  return (
    <GenericStyledDialogue open={open} onClose={handleClose} theme={theme}>
      {/* Modal Header */}
      <>
        <Flex height="72px" justifyContent="center" alignItems="center" paddingX="48px" paddingY="18px">
          <Text variant="h4">Export</Text>
          <CloseIcon
            onClick={handleClose}
            style={{ position: 'absolute', cursor: 'pointer', width: '24px', height: '24px', right: '24px' }}
          ></CloseIcon>
        </Flex>
        <Divider />
      </>

      <Flex flexDirection="column" paddingTop="23.5px" marginLeft="48px" height="400px">
        {/* MODAL DESCRIPTION */}
        <Text variant="subtitle1" sx={{ height: '21px' }}>
          Export Data Structure
        </Text>
        <Flex marginTop="8px">
          <Text variant="body2">Select the level of granularity that you would like in the exported file.</Text>
        </Flex>

        {/* GROUP BY DROPDOWN */}
        <Flex marginTop="24px" alignItems="center">
          <Text sx={{ height: '19px' }} variant="subtitle2">
            Group by:
          </Text>
          <Flex marginLeft="8px">
            <SlDropdownMenu
              defaultLabel={ExportDropdownOptionIds.PRODUCT}
              options={dropdownOptions}
              selectedId={selectedGroupBy}
              onChange={(evt) => handleDropdownChange(evt.id as ExportDropdownOptionIds)}
              stacklineButtonProps={{
                buttonSx: {
                  width: '99px',
                  justifyContent: 'space-between'
                }
              }}
            />
          </Flex>
        </Flex>

        {/* CUSTOMIZED EXPORT OPTION - forecasts export does not have any Customizable option. */}

        <>
          <Flex marginTop="24px">
            <Text sx={{ height: '19px' }} variant="subtitle2">
              Customize
            </Text>
          </Flex>
          <Flex flexDirection="column" gap={3} marginTop="6.5px">
            {/* Separate data by week Checkbox */}
            {shouldShowSeparateDataByWeek && (
              <ExportCheckBox
                isSelected={isSeparateWeek}
                id="separateWeek"
                label="Separate data by week"
                onSelect={(e) => setIsSeparateWeek(e.target.checked)}
                checkboxProps={{
                  checked: isSeparateWeek || forceWeekSeparation,
                  disabled: forceWeekSeparation
                }}
              />
            )}
            {/* Include comparison checkbox */}
            <ExportCheckBox
              isSelected={isIncludeComparison}
              id="includeComparison"
              label="Include comparison time period"
              onSelect={(e) => setIsIncludeComparison(e.target.checked)}
            />
          </Flex>
        </>
      </Flex>
      {/* Footer */}
      <>
        <Divider />
        <Flex justifyContent="space-between" alignItems="center" paddingX="48px" paddingY="20px">
          <Link to="/account/downloads">
            <SlButton sx={{ width: '142px' }}>View downloads</SlButton>
          </Link>
          <SlButton
            buttonSx={{ width: '81px' }}
            variant="contained"
            onClick={handleExportButton}
            disabled={exportButtonDisabled}
          >
            Export
          </SlButton>
        </Flex>
      </>
    </GenericStyledDialogue>
  );
};

export default AppExportModal;
