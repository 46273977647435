import React, { useEffect } from 'react';
import { AdCampaignBuilderCheckbox } from 'src/components/AdCampaignBuilder/Widgets/Checkbox';
import ReduxStore, { AdManagerPortfolio } from 'src/types/store/reduxStore';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails } from '../BudgetAllocation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PortfolioColumn from 'src/components/AdManager/Search/CustomColumns/PortfolioColumn';
import DataColumn from 'src/components/AdManager/Search/CustomColumns/DataColumn';
import {
  StacklineBudgetColumn,
  AmsBudgetColumn,
  BudgetColumn
} from 'src/components/AdBudgetEditor/Steps/PortfolioAlign';
import numeral from 'numeral';
import * as adBudgetEditorActions from 'src/store/modules/adManager/adBudgetEditor/actions';
import { List as VirtualizedList, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import NumberFormat from 'react-number-format';
import { StatusIndicatorColor } from 'src/components/SummaryTile/SummaryTile';
import queryString from 'qs';
import Truncate from 'react-truncate';
import { Link } from 'react-router-dom';
import moment from 'moment';

/**
 * Handles updating and displaying the Budget for a campaign
 */
const InputBudgetChangeColumn = ({ data }) => {
  const original = _get(data, ['entity', 'extendedAttributes', 'currentMonthBudgetSetting', 'amount'], 0);
  const newBudget = _get(data, ['entity', 'extendedAttributes', 'newBudgetSetting', 'amount'], 0);
  return numeral(newBudget - original).format('$1,000');
};

/**
 * Keeps track of row heights for virtualization
 */
const cellCache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 145
});

/**
 * Returns true if the portfolio checkbox is selected in the table
 */
const isPortfolioSelected = (portfolio: AdManagerPortfolio): boolean => {
  return _get(portfolio, ['entity', 'extendedAttributes', 'isSelected'], false);
};

/**
 * Returns true if the portfolio has been expanded in the table to display
 * all of its associated campaigns
 */
const isPortfolioExpanded = (expandedId: string, portfolio: AdManagerPortfolio): boolean => {
  return expandedId === portfolio.id && isPortfolioSelected(portfolio);
};

interface PortfolioTableRowProps {
  /**
   * The portfolio from Redux to display
   */
  portfolio: AdManagerPortfolio;
  /**
   * The string ID of the currently expanded panel
   */
  expanded: string;
  /**
   * Callback function factory to handle expanding a portfolio row
   */
  handleExpanded: (panel: string) => (event: any, newExpanded: boolean) => void;
  /**
   * The index of the row in the table
   */
  index: number;
  /**
   * Callback for updating the budget for an individual campaign
   */
  handleCampaignBudgetChange: (id: string | number, value: number | undefined) => void;
}
/**
 * Renders a portfolio as a row in the Budget Allocation page
 */
const PortfolioTableRow = ({
  portfolio,
  expanded,
  handleExpanded,
  index,
  handleCampaignBudgetChange
}: PortfolioTableRowProps) => {
  const isSelected = isPortfolioSelected(portfolio);
  const { adBudgetEditor, retailer, app } = useSelector((state: ReduxStore) => state);
  const dispatch = useDispatch();

  const hideDecimals = retailer.id === '16'; // Amazon Japan
  const budgetValue = _get(portfolio, ['entity', 'extendedAttributes', 'newBudgetSetting', 'amount'], null);
  const daysInMonth = moment().add(adBudgetEditor.timeline.id, 'M').daysInMonth();

  /**
   * Callback for when a portfolio checkbox is toggled
   * @param portfolioId ID of portfolio to be toggled
   */
  const toggleOnePortfolio = (portfolioId: string): void => {
    const clonePortfolios = _cloneDeep(adBudgetEditor.portfolioArray);
    clonePortfolios.forEach(({ entity: portfolioEntity }) => {
      if (portfolioEntity.id === portfolioId) {
        portfolioEntity.extendedAttributes.isSelected = !portfolioEntity.extendedAttributes.isSelected;
      }
    });
    dispatch(adBudgetEditorActions.setPortfolioArray(clonePortfolios));
  };

  /**
   * Update the budget for an entire portfolio
   * @param id The ID of the portfolio whose budget to update
   * @param value
   */
  const handleBudgetChange = (id: string | number, value: number | undefined) => {
    const clonePortfolios = _cloneDeep(adBudgetEditor.portfolioArray);
    clonePortfolios.forEach((item) => {
      if (item.id === id) {
        item.entity.extendedAttributes.newBudgetSetting = { amount: value };
      }
    });
    dispatch(adBudgetEditorActions.setPortfolioArray(clonePortfolios));
  };

  const isAutomated =
    _get(portfolio, ['entity', 'extendedAttributes', 'automationAttributes', 'strategyId'], 'Manual') !== 'Manual';

  const filteredCampaigns = adBudgetEditor.campaignArray.filter((item) => {
    return (
      item.entity.extendedAttributes.portfolioId === portfolio.id &&
      item.entity.extendedAttributes.entityId === adBudgetEditor.entity.entity.id
    );
  });
  const isExpanded = isPortfolioExpanded(expanded, portfolio);

  return (
    <Accordion
      square
      expanded={isExpanded}
      onChange={(e, newExpanded) => {
        cellCache.clear(index, 0);
        handleExpanded(portfolio.id)(e, newExpanded);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
        <div
          role="button"
          className="portfolio_row"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
        >
          <div className="checkbox_col">
            <AdCampaignBuilderCheckbox
              checked={isSelected}
              onChange={() => {
                cellCache.clear(index, 0);
                toggleOnePortfolio(portfolio.id);
              }}
            />
          </div>
          <div className="portfolio">
            <PortfolioColumn data={portfolio} target="_blank" />
          </div>
          <div className="ad_spend">
            <DataColumn value={portfolio.spend} />
          </div>
          <div className="ad_sales">
            <DataColumn value={portfolio.sales} />
          </div>
          <div className="budget">
            <BudgetColumn data={portfolio} />
          </div>
          <div className="current_budget">
            <StacklineBudgetColumn data={portfolio} />
          </div>
          <div className="budget_change">
            <InputBudgetChangeColumn data={portfolio} />
          </div>
          <div className="new_budget">
            {!portfolio.isUnassigned && (
              <div className="budget">
                <NumberFormat
                  isAllowed={({ floatValue }) => {
                    return floatValue >= 1 || floatValue === undefined; // Amazon's rule, all bid price must be greater or equal to $0.02
                  }}
                  value={budgetValue}
                  thousandSeparator
                  prefix={retailer.currencySymbol}
                  decimalScale={0}
                  fixedDecimalScale
                  allowNegative={false}
                  onValueChange={({ floatValue }) => {
                    handleBudgetChange(portfolio.id, floatValue);
                  }}
                />
              </div>
            )}
            {!portfolio.isUnassigned && (
              <div className="percentage">{numeral(budgetValue / adBudgetEditor.amount! || 0).format('0%')}</div>
            )}
          </div>
        </div>
      </AccordionSummary>
      {isExpanded && (
        <div style={{ height: '500px', overflow: 'scroll' }}>
          <AccordionDetails>
            {filteredCampaigns.length > 0 && (
              <div className="campaign_table">
                <div className="header campaign_row">
                  <div className="header campaign_name">Campaign</div>
                  <div className="header campaign_spend">Ad Clicks</div>

                  <div className="header campaign_spend">Ad Spend</div>
                  <div className="header campaign_sales">Ad Sales</div>
                  <div className="header campaign_spend">CPC</div>
                  <div className="header campaign_spend">AMS Budget</div>
                  <div className="header campaign_spend">Stackline Change</div>
                  {!isAutomated && <div className="header campaign_budget">Daily Budget</div>}
                  {!isAutomated && <div className="header campaign_budget">Monthly Budget</div>}
                </div>
                <VirtualizedList
                  height={500}
                  rowCount={filteredCampaigns.length}
                  rowHeight={60}
                  width={1270}
                  rowRenderer={({ index: campaignIndex, style }) => {
                    const campaign = filteredCampaigns[campaignIndex];
                    const campaignBudget = _get(
                      campaign,
                      ['entity', 'extendedAttributes', 'newBudgetSetting', 'amount'],
                      0
                    );
                    const {
                      queryParams: { searchParams, additionalParams }
                    } = app;
                    const parsedAdditionalParameters = queryString.parse(additionalParams);
                    parsedAdditionalParameters.tab = 'adManager';
                    parsedAdditionalParameters.subtab = 'keyMetrics';
                    const linkTo = `/adCampaign/${campaign.id}${searchParams}&${queryString.stringify(
                      parsedAdditionalParameters
                    )}`;

                    return (
                      <div style={style} key={campaign.id} className="campaign_row">
                        <div className="campaign_name">
                          <StatusIndicatorColor
                            {...campaign.entity}
                            style={{
                              minWidth: '10px',
                              width: '10px',
                              borderRadius: '15px',
                              height: '10px',
                              marginRight: 6
                            }}
                          />
                          <Link to={linkTo} target="_blank">
                            <Truncate lines={2} ellipsis="...">
                              {campaign.entity.campaignName}
                            </Truncate>
                          </Link>
                        </div>
                        <div className="campaign_spend">
                          <DataColumn value={campaign.clicks} />
                        </div>

                        <div className="campaign_spend">
                          <DataColumn value={campaign.spend} />
                        </div>
                        <div className="campaign_sales">
                          <DataColumn value={campaign.sales} />
                        </div>
                        <div className="campaign_spend">
                          <DataColumn value={campaign.costPerClick} />
                        </div>
                        <div className="campaign_roas">
                          <AmsBudgetColumn data={campaign} />
                        </div>
                        <div className="campaign_roas">
                          <StacklineBudgetColumn data={campaign} />
                        </div>
                        {!isAutomated && (
                          <div className="campaign_budget">
                            <NumberFormat
                              isAllowed={() => {
                                return true;
                              }}
                              value={campaignBudget}
                              thousandSeparator
                              prefix={retailer.currencySymbol}
                              decimalScale={hideDecimals ? 0 : 2}
                              fixedDecimalScale
                              allowNegative={false}
                              onValueChange={({ floatValue }) => {
                                handleCampaignBudgetChange(campaign.id, floatValue);
                              }}
                            />
                          </div>
                        )}
                        {!isAutomated && (
                          <div className="campaign_budget">
                            {numeral((campaignBudget || 0) * daysInMonth).format(hideDecimals ? '$1,000' : '$1,000.00')}
                          </div>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </AccordionDetails>
        </div>
      )}
    </Accordion>
  );
};

interface PortfoliosTableProps {
  portfolios: AdManagerPortfolio[];
  /**
   * The panel ID that is currently expanded
   */
  expanded: string;
  /**
   * Callback function factory for when a row is expanded
   * @param panel The portfolio ID to be expanded
   */
  handleExpanded: (panel: string) => (event: any, newExpanded: boolean) => void;
  /**
   * Callback for updating the budget for an individual campaign
   */
  handleCampaignBudgetChange: (id: string | number, value: number | undefined) => void;
}
/**
 * Renders all the portfolios whose budgets need to be managed
 * in the Budget Allocation page. Each row is virtualized to
 * optimize performance.
 */
const PortfoliosTable = ({
  portfolios,
  expanded,
  handleExpanded,
  handleCampaignBudgetChange
}: PortfoliosTableProps) => {
  useEffect(() => {
    cellCache.clearAll();
  }, [expanded]);

  return (
    <VirtualizedList
      height={750}
      rowCount={portfolios.length}
      rowHeight={(params) => {
        return isPortfolioExpanded(expanded, portfolios[params.index]) ? 645 : 145;
      }}
      width={1270}
      deferredMeasurementCache={cellCache}
      rowRenderer={({ index, style, parent }) => {
        return (
          <CellMeasurer parent={parent} cache={cellCache} columnIndex={0} key={portfolios[index].id} rowIndex={index}>
            <div
              style={{
                ...style,
                height: isPortfolioExpanded(expanded, portfolios[index]) ? 500 : 145
              }}
            >
              <PortfolioTableRow
                index={index}
                portfolio={portfolios[index]}
                handleExpanded={handleExpanded}
                expanded={expanded}
                handleCampaignBudgetChange={handleCampaignBudgetChange}
              />
            </div>
          </CellMeasurer>
        );
      }}
    />
  );
};

export default PortfoliosTable;
