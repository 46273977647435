import React from 'react';
import PropTypes from 'prop-types';

const PromotionsGridHeaderFormatter = ({ displayName }) => (
  <div
    style={{
      // marginRight: '15px',
      display: 'flex',
      justifyContent: 'flex-end'
    }}
    role="button"
  >
    <span>{displayName}</span>
  </div>
);

PromotionsGridHeaderFormatter.propTypes = {
  column: PropTypes.object.isRequired,
  displayName: PropTypes.string.isRequired,
  reactContainer: PropTypes.object.isRequired
};

export default PromotionsGridHeaderFormatter;
