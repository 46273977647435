import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import _pick from 'lodash/pick';
import _intersection from 'lodash/intersection';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import queryString from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AutoSizer, List as VirtualizedList } from 'react-virtualized';
import i18n from 'src/i18n_en';
import ReduxStore from 'src/types/store/reduxStore';
import { AvailableRetailer } from 'src/types/store/storeTypes';
import { anyNotEq } from 'src/utils/equality';
import { ListFilterRow } from './common';
import './Filter.scss';

const NoRetailersFound = () => <div style={{ height: 175 }}>{i18n.retailers_noneFound}</div>;

type RetailersForEntity = AvailableRetailer & {
  isChecked: boolean;
};

// We have to use lodash's `_.orderBy()` here since it's stable, and JS isn't guaranteed to have a stable sort algorithm
const sortRetailers = (retailers: RetailersForEntity) =>
  _orderBy(retailers, [({ isChecked }) => (isChecked ? 1 : 0)], ['desc']);

interface RetailerFilterProps {
  app: ReduxStore['app'];
  filters: any;
  user: ReduxStore['user'];
  retailer: ReduxStore['retailer'];
  location: any;
  onFilterChange: (any, any) => void;
}

interface RetailerFilterState {
  searchText: string;
  retailersForEntity: RetailersForEntity[];
  staticSearchRetailers: any[];
  countCheckedRetailers: number;
}

class RetailerFilter extends Component<RetailerFilterProps, RetailerFilterState> {
  private static propTypes = {
    app: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired
  };

  public constructor(props: RetailerFilterProps) {
    super(props);

    this.state = {
      searchText: '',
      retailersForEntity: [],
      staticSearchRetailers: [],
      countCheckedRetailers: 0
    };
  }

  public componentWillMount() {
    this.setRetailers(this.props);
  }

  public componentWillReceiveProps(nextProps: RetailerFilterProps) {
    const { filters } = nextProps;
    if (
      (filters.isClearingFilters && this.props.filters.retailer !== null) ||
      anyNotEq(['entity', 'location'], this.props, nextProps)
    ) {
      this.setRetailers(nextProps);
    }
  }

  private setRetailers = (props: RetailerFilterProps) => {
    const {
      app: { name },
      retailer: { availableRetailers },
      user: {
        config: { allRetailerIds, allWeekIdsByRetailerId }
      },
      filters,
      location
    } = props;
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const parsedFilterParams = queryParams.filter && queryString.parse(JSON.parse(queryParams.filter));
    const weekRetailerKeys = Object.keys(allWeekIdsByRetailerId).filter((w) => w !== '0');
    const allRetailers = allRetailerIds.map((id) => String(id));
    const retailerIds = _intersection(weekRetailerKeys, allRetailers).filter((id) => {
      const retailerObj = availableRetailers.find((r) => r.id === id);
      if (retailerObj) {
        if (name === 'beacon') {
          return retailerObj.supportedAppNames.includes('beacon');
        }
        if (name === 'atlas') {
          return retailerObj.supportedAppNames.includes('atlas');
        }
      }
      return false;
    });
    const retailerIdsSet = new Set(retailerIds);

    // check all retailers when first going to the page if there is no retailer in the filter
    let countCheckedRetailers = 0;
    const retailersForEntity = availableRetailers
      .filter(({ id }) => retailerIdsSet.has(id))
      .map((val) => {
        const isChecked =
          filters.retailer === null
            ? true
            : parsedFilterParams &&
              parsedFilterParams.r &&
              // eslint-disable-next-line
              !!parsedFilterParams.r.find((item) => item.i == val.id);
        if (isChecked) {
          countCheckedRetailers += 1;
        }
        return { ...val, isChecked };
      });

    this.setState({
      staticSearchRetailers: retailersForEntity,
      retailersForEntity: sortRetailers(retailersForEntity),
      searchText: '',
      countCheckedRetailers
    });
  };

  private handleCheck = (event, isInputChecked: boolean, index: any) => {
    const { onFilterChange } = this.props;
    const { retailersForEntity, staticSearchRetailers, countCheckedRetailers } = this.state;

    // add the logic to ensure at least have 3 retailers selected
    let newCountCheckedRetailers = countCheckedRetailers;
    if (isInputChecked) {
      newCountCheckedRetailers += 1;
      retailersForEntity[index].isChecked = isInputChecked;
    } else if (newCountCheckedRetailers > 3) {
      newCountCheckedRetailers -= 1;
      retailersForEntity[index].isChecked = isInputChecked;
    }

    staticSearchRetailers.forEach((val) => {
      if (String(val.id) === String(retailersForEntity[index].id)) {
        if (isInputChecked) {
          val.isChecked = isInputChecked;
        } else if (newCountCheckedRetailers > 3) {
          val.isChecked = isInputChecked;
        }
      }
    });
    const values = staticSearchRetailers.filter((val) => val.isChecked);

    this.setState({ staticSearchRetailers, retailersForEntity, countCheckedRetailers: newCountCheckedRetailers });
    onFilterChange(values, 'retailer');
  };

  private handleInputChange = ({ target: { value } }) => {
    const lowerCaseVal = value.toLowerCase();
    const updatedRetailers = this.state.staticSearchRetailers.filter(({ displayName }) =>
      displayName.toLowerCase().includes(lowerCaseVal)
    );

    this.setState({
      searchText: value,
      retailersForEntity: sortRetailers(updatedRetailers)
    });
  };

  private rowRenderer = ({ index, style }) => {
    const { retailersForEntity } = this.state;
    return (
      <ListFilterRow
        index={index}
        key={index}
        style={style}
        isChecked={retailersForEntity[index].isChecked}
        value={retailersForEntity[index].displayName}
        onCheck={this.handleCheck}
      />
    );
  };

  public render() {
    const { retailersForEntity, staticSearchRetailers, searchText } = this.state;
    if (staticSearchRetailers && _isEmpty(staticSearchRetailers)) {
      return null;
    }

    return (
      <div className="search-form-container search-form-container--sm">
        <TextField
          variant="standard"
          autoComplete="off"
          className="sl-form-input"
          placeholder="Search for retailers"
          type="text"
          name="keyword"
          id="keyword"
          style={{ width: '100%' }}
          value={searchText}
          onChange={this.handleInputChange}
        />
        {retailersForEntity && _isEmpty(retailersForEntity) ? <NoRetailersFound /> : null}
        {!_isEmpty(retailersForEntity) ? (
          <AutoSizer disableHeight>
            {({ width }) => (
              <VirtualizedList
                width={width}
                height={300}
                rowCount={retailersForEntity.length}
                rowHeight={45}
                style={{ marginBottom: 10 }}
                rowRenderer={this.rowRenderer}
              />
            )}
          </AutoSizer>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxStore) => _pick(state, ['filters', 'user', 'retailer', 'app']);

export default withRouter(connect(mapStateToProps)(RetailerFilter));
