/* eslint-disable react/prop-types */
import Slide from '@mui/material/Slide';
import _cloneDeep from 'lodash/cloneDeep';
import _constant from 'lodash/constant';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import queryString from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { withBus } from 'react-bus';
import { connect } from 'react-redux';
import { RouteChildrenProps, withRouter } from 'react-router';
import ClearAllFilterButton from 'src/components/AdManager/Search/ClearAllFilterButton';
import AdvancedSearchSideBar from 'src/components/Search/AdvancedSearch/AdvancedSearchSideBar/AdvancedSearchSideBar';
import {
  advertisingMetricFilterDefinitions,
  optimizationCampaignFilterDefinitions,
  optimizationTargetFilterDefinitions,
  segmentFilterDefinitions,
  targetingTextFilterDefinitions,
  handleSubmit
} from 'src/components/Search/AdvancedSearch/AdvancedSearchSideBar/ConnectedAdvancedSearchSidebar';
import i18n from 'src/i18n_en';
import { AdManagerEvents } from 'src/types/application/event';
import { PushFn } from 'src/types/application/types';
import { WidgetProps } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import { EventBus } from 'src/types/utils';
import { addPersistentQueryParams } from 'src/utils/browser';
import { useBus, useOutsideAlerter, useOnce } from 'src/utils/Hooks';
import { mapConditionsToQuery, prefillFormData } from 'src/utils/segments';
import { AD_CAMPAIGN_TYPE } from 'sl-ad-campaign-manager-data-model';
import './SearchSideBar.scss';
import { AdCampaignType } from 'src/store/modules/adManager/adCampaignTypes/types';
import { STACKLINE_UNASSIGNED } from 'src/utils/constants';
import { CAMPAIGN_TYPES } from 'src/store/modules/adManager/adCampaignBuilder/types';
import { isCriteo, shouldShowCriteo } from 'src/utils/app';

const getDefaultFilterDefinitions = (entityService: any) => {
  if (
    _get(entityService, ['mainEntity', 'extendedAttributes', 'retailerId']) === 2 &&
    _get(entityService, ['mainEntity', 'type']) === 'adCampaign' &&
    _get(entityService, ['mainEntity', 'extendedAttributes', 'amsApiModel', 'targetingType']) === 'auto'
  ) {
    return 'stacklineSku';
  }
  if (_get(entityService, ['mainEntity', 'type']) === 'adCampaign') {
    return 'targetingText';
  }

  return 'campaignId';
};

const segmentNameFilterDefinitions = [['displayNameAdvancedSearch', 'dn']];

export const campaignNameFilterDefinitions = [
  [
    'campaignNameSearch',
    'campaignNameFuzzy',
    {
      idFieldName: 'campaignNameFuzzy',
      headerDisplayName: 'Include',
      searchFieldHintText: 'Campaign names to include'
    }
  ],
  [
    'campaignNameSearch',
    'excludedCampaignNameFuzzy',
    {
      idFieldName: 'excludedCampaignNameFuzzy',
      headerDisplayName: 'Exclude',
      searchFieldHintText: 'Campaign names to exclude'
    }
  ]
];

export const adGroupNameFilterDefinitions = [
  [
    'adGroupNameSearch',
    'adGroupNameFuzzy',
    {
      idFieldName: 'adGroupNameFuzzy',
      headerDisplayName: 'Include',
      searchFieldHintText: `${isCriteo() ? 'Line Item' : 'Ad Group'} names to include`
    }
  ]
];

export const portfolioNameFilterDefinitions = [
  [
    'portfolioNameSearch',
    'portfolioNameFuzzy',
    {
      idFieldName: 'portfolioNameFuzzy',
      headerDisplayName: 'Include',
      searchFieldHintText: 'Portfolio names to include'
    }
  ],
  [
    'portfolioNameSearch',
    'excludedPortfolioNameFuzzy',
    {
      idFieldName: 'excludedPortfolioNameFuzzy',
      headerDisplayName: 'Exclude',
      searchFieldHintText: 'Portfolio names to exclude'
    }
  ]
];

export const entityNameFilterDefinitions = [
  [
    'entityNameSearch',
    'entityNameFuzzy',
    {
      idFieldName: 'entityNameFuzzy',
      headerDisplayName: 'Include',
      searchFieldHintText: 'Entity names to include'
    }
  ],
  [
    'entityNameSearch',
    'excludedEntityNameFuzzy',
    {
      idFieldName: 'excludedEntityNameFuzzy',
      headerDisplayName: 'Exclude',
      searchFieldHintText: 'Entity names to exclude'
    }
  ]
];

const buildAdEntitiesListItemFilterDefinition = (adEntities: ReduxStore['adEntities']) => [
  'listItemsSelector',
  'entityId',
  {
    listDisplayName: 'Entities',
    idFieldName: 'entityId',
    nameFieldName: 'name',
    searchFieldHintText: 'Refine by entity',
    searchFieldNotFoundText: i18n.adEntities_noneFound,
    listItems: adEntities,
    listItemDataDependencyPropNames: ['adEntities']
  }
];

// const buildAdRetailersListItemFilterDefinition = () => [
//   'retailerNameSearch',
//   'retailerNameFuzzy',
//   {
//     idFieldName: 'retailerNameFuzzy',
//     headerDisplayName: 'Include',
//     searchFieldHintText: 'Retailer names to include'
//   }
// ];

const buildAdPortfoliosListItemFilterDefinition = (adPortfolios: ReduxStore['adPortfolios']) => [
  'listItemsSelector',
  'portfolioId',
  {
    listDisplayName: 'Portfolios',
    idFieldName: 'portfolioId',
    nameFieldName: 'name',
    searchFieldHintText: 'Refine by portfolio',
    searchFieldNotFoundText: i18n.adPortfolios_noneFound,
    listItems: adPortfolios,
    listItemDataDependencyPropNames: ['adPortfolios'],
    listItemHeight: 60
  }
];

const buildAdCampaignsListItemFilterDefinition = (adCampaigns: ReduxStore['adCampaigns']) => [
  'listItemsSelector',
  'campaignId',
  {
    listDisplayName: 'Campaigns',
    idFieldName: 'campaignId',
    nameFieldName: 'campaignName',
    searchFieldHintText: 'Refine by campaign',
    searchFieldNotFoundText: i18n.adCampaigns_noneFound,
    listItems: adCampaigns,
    listItemDataDependencyPropNames: ['adCampaigns']
  }
];

const buildAdCampaignTypesListItemFilterDefinition = (adCampaignTypes: ReduxStore['adCampaignTypes']) => [
  'listItemsSelector',
  'derivedCampaignType',
  {
    listDisplayName: 'Ad Type',
    idFieldName: 'derivedCampaignType',
    nameFieldName: 'name',
    searchFieldHintText: 'Refine by ad type',
    searchFieldNotFoundText: i18n.adCampaignTypes_noneFound,
    listItems: adCampaignTypes,
    listItemDataDependencyPropNames: ['adCampaignTypes']
  }
];

const buildAdCampaignStatesListItemFilterDefinition = (adCampaignStates: ReduxStore['adCampaignStates']) => [
  'listItemsSelector',
  'statusDerived',
  {
    listDisplayName: 'Campaign Status',
    idFieldName: 'statusDerived',
    nameFieldName: 'name',
    searchFieldHintText: 'Refine by campaign status',
    searchFieldNotFoundText: i18n.adCampaignStates_noneFound,
    listItems: adCampaignStates,
    listItemDataDependencyPropNames: ['adCampaignStates']
  }
];

const buildAdPortfolioStatesListItemFilterDefinition = (adCampaignStates: ReduxStore['adCampaignStates']) => [
  'listItemsSelector',
  'portfolioState',
  {
    listDisplayName: 'Portfolio Status',
    idFieldName: 'portfolioState',
    nameFieldName: 'name',
    searchFieldHintText: 'Refine by portfolio status',
    searchFieldNotFoundText: i18n.adPortfolioStates_noneFound,
    listItems: adCampaignStates,
    listItemDataDependencyPropNames: ['adCampaignStates']
  }
];

const buildAdAutomationStrategiesListItemFilterDefinition = (
  adAutomationStrategies: ReduxStore['adAutomationStrategies']
) => [
  'listItemsSelector',
  'automationStrategyId',
  {
    listDisplayName: 'Optimization',
    idFieldName: 'automationStrategyId',
    nameFieldName: 'name',
    searchFieldHintText: 'Refine by automation strategy',
    searchFieldNotFoundText: i18n.adAutomationStrategies_noneFound,
    listItems: adAutomationStrategies,
    listItemDataDependencyPropNames: ['adAutomationStrategies']
  }
];

const buildInstacartTargetAutoAddedFilterDefinition = (isAutoAddedBooleanOpts: any[]) => [
  'listItemsSelector',
  'isAutoAdded',
  {
    listDisplayName: 'Added method',
    idFieldName: 'isAutoAdded',
    nameFieldName: 'name',
    searchFieldHintText: 'Refine by added method',
    searchFieldNotFoundText: i18n.adAutomationStrategies_noneFound,
    listItems: isAutoAddedBooleanOpts,
    listItemDataDependencyPropNames: []
  }
];

const buildAdTargetMatchTypesListItemFilterDefinition = (adTargetMatchTypes: ReduxStore['adTargetMatchTypes']) => [
  'listItemsSelector',
  'targetingType',
  {
    listDisplayName: 'Match Type',
    idFieldName: 'targetingType',
    nameFieldName: 'name',
    searchFieldHintText: 'Refine by match type',
    searchFieldNotFoundText: i18n.adTargetMatchTypes_noneFound,
    listItems: adTargetMatchTypes,
    listItemDataDependencyPropNames: ['adTargetMatchTypes']
  }
];

const buildAdTargetStatesListItemFilterDefinition = (adTargetStates: ReduxStore['adTargetStates']) => [
  'listItemsSelector',
  'targetState',
  {
    listDisplayName: 'Target Status',
    idFieldName: 'targetState',
    nameFieldName: 'name',
    searchFieldHintText: 'Refine by status',
    searchFieldNotFoundText: i18n.adTargetStates_noneFound,
    listItems: adTargetStates,
    listItemDataDependencyPropNames: ['adTargetStates']
  }
];

const buildAdCampaignProductStatesListItemFilterDefinition = (
  adCampaignProductStates: ReduxStore['adCampaignProductStates']
) => [
  'listItemsSelector',
  'campaignProductState',
  {
    listDisplayName: 'Product status',
    idFieldName: 'campaignProductState',
    nameFieldName: 'name',
    searchFieldHintText: 'Refine by status',
    searchFieldNotFoundText: i18n.adCampaignProductStates_noneFound,
    listItems: adCampaignProductStates,
    listItemDataDependencyPropNames: ['adCampaignProductStates']
  }
];

const filterCampaingTypesByRetailer = (retailerId: string, campaigns: AdCampaignType[]) => {
  if (isCriteo()) {
    return campaigns.filter((campaign) => ['openAuction'].includes(campaign.id));
  }
  switch (retailerId) {
    case '2': // walmart
      return campaigns.filter((campaign) =>
        [
          AD_CAMPAIGN_TYPE.SEARCH_BRAND_AMPLIFIER,
          AD_CAMPAIGN_TYPE.SPONSORED_PRODUCT,
          CAMPAIGN_TYPES.SponsoredWalmartVideo
        ].includes(campaign.id)
      );
    case '63': // instacart us
    case '100': // instacart canada
      return campaigns.filter((campaign) =>
        [
          AD_CAMPAIGN_TYPE.SPONSORED_BRAND,
          AD_CAMPAIGN_TYPE.SPONSORED_DISPLAY,
          AD_CAMPAIGN_TYPE.SPONSORED_PRODUCT
        ].includes(campaign.id)
      );
    default:
      // all amazon
      return campaigns.filter((campaign) =>
        [
          AD_CAMPAIGN_TYPE.SPONSORED_BRAND,
          AD_CAMPAIGN_TYPE.SPONSORED_DISPLAY,
          AD_CAMPAIGN_TYPE.SPONSORED_PRODUCT,
          'sponsoredBrandsVideo'
        ].includes(campaign.id)
      );
  }
};

const getFilterDefinitions = ({
  app,
  mainEntity,
  retailer,
  adEntities,
  adPortfolios,
  adCampaigns,
  adAutomationStrategies,
  adPortfolioStates,
  adCampaignStates,
  adCampaignTypes,
  adTargetMatchTypes,
  adTargetStates,
  adCampaignProductStates,
  groupByFieldName: groupByField,
  isAutoAddedBooleanOpts,
  filterDefinitionOverride,
  showSegmentName
}: {
  app: ReduxStore['app'];
  mainEntity: any;
  retailer: ReduxStore['retailer'];
  adEntities: ReduxStore['adEntities'];
  adPortfolios: ReduxStore['adPortfolios'];
  adCampaigns: ReduxStore['adCampaigns'];
  adAutomationStrategies: ReduxStore['adAutomationStrategies'];
  adPortfolioStates: ReduxStore['adPortfolioStates'];
  adCampaignStates: ReduxStore['adCampaignStates'];
  adCampaignTypes: ReduxStore['adCampaignTypes'];
  adTargetMatchTypes: ReduxStore['adTargetMatchTypes'];
  adTargetStates: ReduxStore['adTargetStates'];
  adCampaignProductStates: ReduxStore['adCampaignProductStates'];
  isAutoAddedBooleanOpts: any[];
  groupByFieldName: string;
  filterDefinitionOverride?: string;
  showSegmentName?: boolean;
}) => {
  const hideMetricFitlersForSegments = showSegmentName;
  const entityType = mainEntity.type;
  const adPortfoliosCloned = [
    {
      id: STACKLINE_UNASSIGNED.portfolioId,
      name: STACKLINE_UNASSIGNED.portfolioDisplayName
    },
    ..._cloneDeep(adPortfolios)
  ];
  adPortfoliosCloned.forEach((adPortfolio) => {
    adPortfolio.portfolioId = adPortfolio.id;
  });
  const adEntityListItemFilterDefinition = buildAdEntitiesListItemFilterDefinition(adEntities);
  // const adRetailerListItemFilterDefinition = buildAdRetailersListItemFilterDefinition();
  const adPortfolioListItemFilterDefinition = buildAdPortfoliosListItemFilterDefinition(adPortfoliosCloned);
  const adCampaignListItemFilterDefinition = buildAdCampaignsListItemFilterDefinition(
    adCampaigns.filter((campaign) => {
      switch (mainEntity.type) {
        case 'adPortfolio':
          return campaign.extendedAttributes.portfolioId === mainEntity.id;
        case 'adEntity':
          return campaign.extendedAttributes.entityId === mainEntity.id;
        default:
          return true;
      }
    })
  );
  const adAutomationStrategiesListItemFilterDefinition =
    buildAdAutomationStrategiesListItemFilterDefinition(adAutomationStrategies);
  const adCampaignStatesListItemFilterDefinition = buildAdCampaignStatesListItemFilterDefinition(adCampaignStates);
  const adCampaignTypesListItemFilterDefinition = buildAdCampaignTypesListItemFilterDefinition(
    filterCampaingTypesByRetailer(retailer.id, adCampaignTypes)
  );
  const adPortfolioStatesListItemFilterDefinition = buildAdPortfolioStatesListItemFilterDefinition(adPortfolioStates);
  const adTargetMatchTypesListItemFilterDefinition =
    buildAdTargetMatchTypesListItemFilterDefinition(adTargetMatchTypes);
  const adTargetStatesListItemFilterDefinition = buildAdTargetStatesListItemFilterDefinition(adTargetStates);
  const isAutoAddedBooleanListItemFilterDefinition =
    buildInstacartTargetAutoAddedFilterDefinition(isAutoAddedBooleanOpts);
  const adCampaignProductStatesListItemFilterDefinition =
    buildAdCampaignProductStatesListItemFilterDefinition(adCampaignProductStates);
  let filterDefinitions: any[] = [];

  const params = _get(app, ['queryParams']);
  let groupByFieldName = groupByField;
  if (params.tab === 'adScheduledActions' && params.groupByField) {
    groupByFieldName = params.groupByField;
  }
  if (groupByFieldName === 'campaignId') {
    filterDefinitions = [
      ...filterDefinitions,
      ...(showSegmentName ? segmentNameFilterDefinitions : []),
      ...campaignNameFilterDefinitions,
      adEntityListItemFilterDefinition,
      adCampaignStatesListItemFilterDefinition,
      adCampaignTypesListItemFilterDefinition,
      ...(hideMetricFitlersForSegments ? [] : advertisingMetricFilterDefinitions)
    ];
    if (!isCriteo()) {
      filterDefinitions.push(adPortfolioListItemFilterDefinition, adAutomationStrategiesListItemFilterDefinition);
    }
  }
  if (groupByFieldName === 'portfolioId') {
    filterDefinitions = [
      ...filterDefinitions,
      ...portfolioNameFilterDefinitions,
      adEntityListItemFilterDefinition,
      adPortfolioStatesListItemFilterDefinition,
      ...(hideMetricFitlersForSegments ? [] : advertisingMetricFilterDefinitions)
    ];
    if (!isCriteo()) {
      filterDefinitions.push(adAutomationStrategiesListItemFilterDefinition);
    }
  }
  if (groupByFieldName === 'entityId') {
    filterDefinitions = [
      ...filterDefinitions,
      ...entityNameFilterDefinitions,
      ...(hideMetricFitlersForSegments ? [] : advertisingMetricFilterDefinitions)
    ];
  }
  if (groupByFieldName === 'stacklineSku') {
    filterDefinitions = [
      ...filterDefinitions,
      ...(showSegmentName ? segmentNameFilterDefinitions : []),
      ...segmentFilterDefinitions,
      adEntityListItemFilterDefinition,
      adCampaignListItemFilterDefinition,
      adCampaignTypesListItemFilterDefinition,
      adCampaignProductStatesListItemFilterDefinition,
      ...(hideMetricFitlersForSegments ? [] : advertisingMetricFilterDefinitions)
    ];
    if (!isCriteo()) {
      filterDefinitions.push(adPortfolioListItemFilterDefinition, adAutomationStrategiesListItemFilterDefinition);
    }
  }
  if (groupByFieldName === 'targetingText') {
    filterDefinitions = [
      ...filterDefinitions,
      ...(showSegmentName ? segmentNameFilterDefinitions : []),
      ...targetingTextFilterDefinitions,
      adEntityListItemFilterDefinition,
      adCampaignListItemFilterDefinition,
      adCampaignTypesListItemFilterDefinition,
      retailer.id === '63' ? isAutoAddedBooleanListItemFilterDefinition : adTargetMatchTypesListItemFilterDefinition,
      adTargetStatesListItemFilterDefinition,
      ...(mainEntity.type === 'adCampaign' && window.location.search.includes('subtab=targeting')
        ? [
            [
              'rangeSelector',
              'bid',
              {
                idFieldName: 'cpcMinBidAmount',
                filterName: 'bid',
                displayName: 'Bid'
              }
            ]
          ]
        : []),
      ...(hideMetricFitlersForSegments ? [] : advertisingMetricFilterDefinitions)
    ];
    if (!isCriteo()) {
      filterDefinitions.splice(
        3,
        0,
        adPortfolioListItemFilterDefinition,
        adAutomationStrategiesListItemFilterDefinition
      );
    }
  }
  if (groupByFieldName === 'adGroupId') {
    filterDefinitions = [
      ...filterDefinitions,
      ...(showSegmentName ? segmentNameFilterDefinitions : []),
      ...adGroupNameFilterDefinitions,
      adEntityListItemFilterDefinition,
      adCampaignListItemFilterDefinition,
      ...(hideMetricFitlersForSegments ? [] : advertisingMetricFilterDefinitions)
    ];

    if (!isCriteo()) {
      filterDefinitions.splice(3, 0, adPortfolioListItemFilterDefinition);
    }
  }
  const isCriteoAllowed = shouldShowCriteo();
  if (isCriteoAllowed && groupByFieldName === 'retailerId') {
    filterDefinitions = [
      ...filterDefinitions,
      ...(hideMetricFitlersForSegments ? [] : advertisingMetricFilterDefinitions)
    ];
  }

  let result = filterDefinitions;

  if (entityType === 'adEntity') {
    result = filterDefinitions.filter((item) => item[1] !== 'entityId');
  }
  if (isCriteoAllowed && entityType === 'retailerId') {
    result = filterDefinitions.filter((item) => item[1] !== 'retailerId');
  }

  if (entityType === 'adPortfolio') {
    result = filterDefinitions.filter((item) => !['entityId', 'portfolioId', 'automationStrategyId'].includes(item[1]));
  }

  if (entityType === 'adCampaign') {
    result = filterDefinitions.filter(
      (item) =>
        ![
          'entityId',
          'portfolioId',
          'campaignId',
          'statusDerived',
          'automationStrategyId',
          'derivedCampaignType'
        ].includes(item[1])
    );
  }

  if (filterDefinitionOverride === 'optimizationTargets') {
    result = optimizationTargetFilterDefinitions;
  } else if (filterDefinitionOverride === 'optimizationCampaigns') {
    result = optimizationCampaignFilterDefinitions;
  } else if (filterDefinitionOverride === 'targetBids') {
    result = [
      ...campaignNameFilterDefinitions,
      adEntityListItemFilterDefinition,
      adCampaignStatesListItemFilterDefinition,
      retailer.id === '63' ? isAutoAddedBooleanListItemFilterDefinition : adTargetMatchTypesListItemFilterDefinition,
      adCampaignTypesListItemFilterDefinition,
      [
        'rangeSelector',
        'bid',
        {
          idFieldName: 'cpcMinBidAmount',
          filterName: 'bid',
          displayName: 'Bid'
        }
      ]
    ];

    if (!isCriteo()) {
      result.splice(3, 0, adPortfolioListItemFilterDefinition, adAutomationStrategiesListItemFilterDefinition);
    }
  }

  return result;
};

const mapStateToProps = ({
  app,
  retailer,
  entityService,
  adEntities,
  adPortfolios,
  adCampaigns,
  adAutomationStrategies,
  adPortfolioStates,
  adCampaignStates,
  adCampaignTypes,
  adTargetMatchTypes,
  adTargetStates,
  adCampaignProductStates
}: ReduxStore) => ({
  app,
  retailer,
  entityService,
  adEntities,
  adPortfolios,
  adCampaigns,
  adAutomationStrategies,
  adPortfolioStates,
  adCampaignStates,
  adCampaignTypes,
  adTargetMatchTypes,
  adTargetStates,
  adCampaignProductStates
});

const mkBlankSearchTerm = () => ({ condition: 'should', values: [] });

const updateUrlParams = (
  {
    doAggregation = true,
    entityType,
    props: { retailer, mainTimePeriod, history, location },
    state: { entityDefinition, segment, businessUnitsByParentId },
    formData
  },
  isCreateSegmentPage
) => {
  const queryParams = queryString.parse(location.search);

  const groupByParam = {};
  if (queryParams.groupByField) {
    groupByParam.groupByField = queryParams.groupByField;
  }

  if (entityType && entityDefinition.type !== entityType) {
    if (entityDefinition.type === 'segment' && entityType === 'searchtermlist') {
      formData.termFilters.searchTermFuzzy = formData.termFilters.keyword;
      formData.termFilters.keyword = mkBlankSearchTerm();
      formData.termFilters.searchTerm = mkBlankSearchTerm();
    } else {
      formData.termFilters.keyword = formData.termFilters.searchTermFuzzy;
      formData.termFilters.searchTermFuzzy = mkBlankSearchTerm();
      formData.termFilters.searchTerm = mkBlankSearchTerm();
      formData.termFilters.excludedSearchTerm = mkBlankSearchTerm();
    }
  }
  const urlParams = mapConditionsToQuery(formData);
  // Remove empty items to keep the url as short as possible
  const filteredBUState = Object.entries(businessUnitsByParentId || {}).reduce(
    (acc, [key, val]) => (_isEmpty(val) ? acc : { ...acc, [key]: val }),
    {}
  );
  const serializedBUs = JSON.stringify(filteredBUState);

  history.push(
    `/search?${addPersistentQueryParams(retailer, mainTimePeriod)}&doAggregation=${doAggregation}&entityType=${
      entityType || entityDefinition.type
    }&${queryString.stringify(urlParams)}&showPriceData=${queryParams.showPriceData === '1' ? '1' : '0'}&segment=${
      segment || ''
    }&businessUnits=${serializedBUs}&${queryString.stringify(groupByParam)}${
      isCreateSegmentPage ? `&segmentStage=${queryParams.segmentStage}` : ''
    }`
  );
};

const AdManagerSearchSideBar: React.FC<
  RouteChildrenProps &
    WidgetProps &
    ReturnType<typeof mapStateToProps> & {
      eventBus: EventBus;
      push: PushFn;
      filterDefinitionOverride?: string;
      formDataOverride?: any;
    }
> = ({
  app,
  widget,
  retailer,
  adEntities,
  entityService,
  adPortfolios,
  adCampaigns,
  adAutomationStrategies,
  adPortfolioStates,
  adCampaignStates,
  adCampaignTypes,
  adTargetMatchTypes,
  adTargetStates,
  adCampaignProductStates,
  eventBus,
  formDataOverride,
  filterDefinitionOverride
}) => {
  const params = JSON.parse(_get(entityService, ['mainEntity', 'query'], '{}'));
  const internalFormData = prefillFormData({ queryParams: params, appName: app.name });
  // Segment edit/create
  const pageRef = useRef(_get(app.queryParams, 'segmentStage'));
  const isCreateSegmentPage = pageRef.current === 'segmentCreation';
  const isEditSegmentPage = pageRef.current === 'segmentEdit';

  const [openFilter, setOpenFilter] = useState(false);
  const [groupByFieldName, setGroupByFieldName] = useState(
    app.queryParams.groupByField || widget.data.defaultGroupByField || getDefaultFilterDefinitions(entityService)
  );
  const [formData, setFormData] = useState(widget.data.useInternalState ? internalFormData : null);

  useEffect(() => {
    eventBus.emit('setSearchFilterButtonText', openFilter);
  }, [openFilter, eventBus]);

  useOnce(() => {
    if (!widget.data.isUnderComponent) {
      // do this only when sidebar and grid in the same level, if side bar is under the grid, it will reload forever
      eventBus.emit(AdManagerEvents.ENTITY_EVENT_METRICS_GRID_FILTER_UPDATED, { formData });
    }
  });

  const { mainEntity } = entityService;

  const isAutoAddedBooleanOpts = [
    {
      isAutoAdded: '0',
      displayName: 'Manual',
      id: '0',
      name: 'Manual',
      type: 'isAutoAdded'
    },
    {
      isAutoAdded: '1',
      displayName: 'Auto',
      id: '1',
      name: 'Auto',
      type: 'isAutoAdded'
    }
  ];
  const showSegmentName = isCreateSegmentPage || isEditSegmentPage;
  const getFilterComponentDefinitions = _constant(
    getFilterDefinitions({
      app,
      mainEntity,
      adEntities,
      adPortfolios,
      adCampaigns,
      adAutomationStrategies,
      adPortfolioStates,
      adCampaignStates,
      adCampaignTypes,
      adTargetMatchTypes,
      adTargetStates,
      adCampaignProductStates,
      groupByFieldName,
      filterDefinitionOverride,
      showSegmentName,
      isAutoAddedBooleanOpts,
      retailer
    })
  );

  const toggleOpen = () => {
    setOpenFilter(!openFilter);
  };
  useBus(eventBus, 'entityTabChange', ({ groupByFieldName: groupByFieldNameNew }: { groupByFieldName: string }) =>
    setGroupByFieldName(groupByFieldNameNew)
  );
  useBus(eventBus, AdManagerEvents.ENTITY_EVENT_METRICS_GRID_ACTION_CLICKED, () => {});
  useBus(eventBus, 'toggleSearchSidebar', toggleOpen);

  const closeSideBar = () => {
    eventBus.emit('setSearchFilterButtonText', false);
    setOpenFilter(false);
  };

  useBus(eventBus, 'closeSearchSidebar', () => {
    closeSideBar();
  });

  const handleOnFilterChange = (args: any) => {
    if (widget.data.useInternalState) {
      setFormData(args.formData);
      eventBus.emit(AdManagerEvents.ENTITY_EVENT_METRICS_GRID_FILTER_UPDATED, { formData: args.formData });
    } else {
      updateUrlParams(args, isCreateSegmentPage);
    }
  };

  const { ref: wrapperRef } = useOutsideAlerter(() => closeSideBar());

  useEffect(() => {
    // PillFilters needs to be notified about closing
    return () => {
      eventBus.emit('setSearchFilterButtonText', false);
    };
  }, []);

  return (
    <Slide ref={wrapperRef} direction="right" in={openFilter} mountOnEnter>
      <div className="admanager_sidebar">
        <div className="header" style={{ marginTop: 50 }}>
          <div className="header_text">{isCreateSegmentPage ? 'Segments' : 'Filters'}</div>
          <div className="hide_button" role="button" onClick={() => closeSideBar()}>
            Hide
          </div>
        </div>
        <AdvancedSearchSideBar
          enabledSegment={isCreateSegmentPage || isEditSegmentPage}
          widget={widget}
          formData={formDataOverride || formData || undefined}
          getFilterComponentDefinitions={getFilterComponentDefinitions}
          hideActionButtons
          onChange={handleOnFilterChange}
          ExtraContentComponent={ClearAllFilterButton}
          onSubmit={handleSubmit}
          eventBus={eventBus}
        />
      </div>
    </Slide>
  );
};

export default withRouter(withBus('eventBus')(connect(mapStateToProps)(AdManagerSearchSideBar)));
