import React, { Component } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import { AutoSizer, List as VirtualizedList } from 'react-virtualized';
import { ListFilterRow } from 'src/components/EntityPage/Filters/common';
import ReduxStore from 'src/types/store/reduxStore';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'qs';
import TextField from '@mui/material/TextField';
import i18n from 'src/i18n_en';

const NoVendorCodesFound = () => <div style={{ height: 175 }}>{i18n.promoTypes_noneFound}</div>;

interface VendorCodeForEntity {
  name: string;
  displayName: string;
  isChecked: boolean;
}

interface VendorCodesFilterProps {
  filters: any;
  vendorCodes: ReduxStore['vendorCodes'];
  entity: any;
  location: any;
  onFilterChange: (arg0: any, arg1: any) => void;
}

interface VendorCodesFilterState {
  searchText: string;
  vendorCodesForEntity: VendorCodeForEntity[];
  staticSearchVendorCodes: any[];
}

class VendorCodesFilter extends Component<VendorCodesFilterProps, VendorCodesFilterState> {
  public constructor(props: VendorCodesFilterProps) {
    super(props);

    this.state = {
      searchText: '',
      vendorCodesForEntity: [],
      staticSearchVendorCodes: []
    };
  }

  public componentWillMount() {
    this.setIssueTypes(this.props);
  }

  private setIssueTypes = (props: VendorCodesFilterProps) => {
    const { vendorCodes, filters, location } = props;

    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const parsedFilterParams = queryParams.filter && queryString.parse(JSON.parse(queryParams.filter));

    const vendorCodesForEntity = vendorCodes.map((val) => {
      const isChecked =
        filters.vendorCodes !== null &&
        parsedFilterParams &&
        parsedFilterParams.pt &&
        !!parsedFilterParams.pt.find((item: { i: string }) => item.i === val.name);

      return { ...val, isChecked };
    });

    this.setState({
      staticSearchVendorCodes: vendorCodesForEntity,
      vendorCodesForEntity,
      searchText: ''
    });
  };

  public handleCheck = (event: any, isInputChecked: boolean, index: any) => {
    const { onFilterChange } = this.props;
    const { vendorCodesForEntity, staticSearchVendorCodes } = this.state;
    vendorCodesForEntity[index].isChecked = isInputChecked;

    staticSearchVendorCodes.forEach((val) => {
      if (String(val.displayName) === String(vendorCodesForEntity[index].displayName)) {
        val.isChecked = isInputChecked;
      }
    });
    const values = staticSearchVendorCodes.filter((val) => val.isChecked);
    this.setState({ staticSearchVendorCodes, vendorCodesForEntity });
    onFilterChange(values, 'vendorCode');
  };

  private handleInputChange = ({ target: { value } }: any) => {
    const { staticSearchVendorCodes } = this.state;
    const lowerCaseVal = value.toLowerCase();
    const updatedVendorCodes = staticSearchVendorCodes.filter(({ displayName }) =>
      displayName.toLowerCase().includes(lowerCaseVal)
    );

    this.setState({ searchText: value, vendorCodesForEntity: updatedVendorCodes });
  };

  public rowRenderer = ({ index, style }: any) => {
    const { vendorCodesForEntity } = this.state;
    return (
      <ListFilterRow
        index={index}
        key={index}
        style={style}
        isChecked={vendorCodesForEntity[index].isChecked}
        value={vendorCodesForEntity[index].name}
        onCheck={this.handleCheck}
      />
    );
  };

  public render() {
    const { vendorCodesForEntity, staticSearchVendorCodes, searchText } = this.state;

    if (staticSearchVendorCodes && _isEmpty(staticSearchVendorCodes)) {
      return null;
    }

    return (
      <div className="search-form-container search-form-container--sm">
        <TextField
          variant="standard"
          autoComplete="off"
          className="sl-form-input"
          placeholder="Search for Vendor Codes"
          type="text"
          name="keyword"
          style={{ width: '100%' }}
          value={searchText}
          onChange={this.handleInputChange}
        />
        {vendorCodesForEntity && _isEmpty(vendorCodesForEntity) ? <NoVendorCodesFound /> : null}
        <AutoSizer disableHeight>
          {({ width }) => (
            <VirtualizedList
              width={width}
              height={300}
              rowCount={vendorCodesForEntity.length}
              rowHeight={45}
              style={{ marginBottom: 10 }}
              rowRenderer={this.rowRenderer}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['app', 'vendorCodes', 'comparisonTimePeriod', 'filters', 'mainTimePeriod', 'retailer']);

export default withRouter(connect(mapStateToProps)(VendorCodesFilter));
