/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

import ReduxStore from 'src/types/store/reduxStore';
import { getSettingMetadata } from 'src/store/modules/adManager/adCampaignBuilder/selectors';
import { SectionWrapper } from '../Widgets/AdCampaignBuilderCommonWidgets';
import '../AdCampaignBuilder.scss';
import * as AdCampaignBuilderOperations from 'src/store/modules/adManager/adCampaignBuilder/operations';
import SubmitScreen from 'src/components/AdManager/SubmitScreen/SubmitScreen';
import { AdFeedbackModal } from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdMediaTable/AdFeedbackModal';

const mapStateToProps = (state: ReduxStore) => {
  const targetingTypeId = _get(state, ['adCampaignBuilder', 'target', 'targetingTypeId'], '');
  const targetingType = getSettingMetadata('targetingType', targetingTypeId!)(state)!;
  return {
    app: state.app,
    adCampaignBuilder: state.adCampaignBuilder,
    resetAdCampaignBuilderReduxState: AdCampaignBuilderOperations.resetAdCampaignBuilderReduxState,
    targetingTypeName: targetingType ? targetingType.name : ''
  };
};

const Submit: React.FC<ReturnType<typeof mapStateToProps> & RouteComponentProps> = ({
  app,
  adCampaignBuilder,
  history
}) => {
  const { searchParams } = app.queryParams;
  const [open, setOpenConfirmation] = React.useState(false);

  React.useEffect(() => {
    // Show submission pop up window for walmart video
    if (
      _get(adCampaignBuilder, ['campaignType', 'id'], '') === 'video' &&
      _get(adCampaignBuilder, ['platformId'], '') === 'Walmart' &&
      !open
    ) {
      setOpenConfirmation(true);
    }
  }, []);

  if (_isNil(adCampaignBuilder.target) || !adCampaignBuilder.inited) {
    // Redirect to start of the flow if Redux is in an invalid state due to entering in the middle of the flow
    history.push(`/home${searchParams}&tab=adCampaignBuilder&subtab=platformSelect`);
    return null;
  }
  const { apiResponse } = adCampaignBuilder;

  return (
    <div className="ad-manager-container">
      <SectionWrapper header="Submission" subheader="Thank you for submitting this campaign." layer={0}>
        <div className="submitted">
          <SubmitScreen respondResult={apiResponse} />
        </div>
        <AdFeedbackModal
          header="Submission Complete"
          body="Your campaign has been submitted. It may take up to 72 hours for Walmart to approve your Sponsored Videos campaign"
          open={open}
          handleClose={() => setOpenConfirmation(false)}
        />
      </SectionWrapper>
    </div>
  );
};

const EnhancedSubmit = connect(mapStateToProps)(withRouter(Submit));

export default EnhancedSubmit;
