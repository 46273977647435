// useUpload.ts
import { useState, useCallback } from 'react';
import { UseQueryResult, useQuery } from 'react-query';
import { AdWalmartService, GetSponsoredVideoMediaUploadLocationsResponse } from 'src/components/AdManager/walmartUtils';

export const useUpload = (beaconClientLoginId: number) => {
  const [file, setFile] = useState<File | null>(null);
  const [caption, setCaption] = useState<File | null>(null);

  const {
    data: { uploadUrl, captionUrl, mediaUploadRequestId } = {
      code: null,
      details: null,
      mediaUploadRequestId: null,
      uploadUrl: null,
      captionUrl: null
    }
  }: UseQueryResult<GetSponsoredVideoMediaUploadLocationsResponse> = useQuery(
    ['AdWalmartService_getLocations', beaconClientLoginId],
    () => {
      if (beaconClientLoginId === null) {
        // Return a Promise that resolves to null
        return Promise.resolve({});
      }
      return AdWalmartService.getMediaLocations({ headers: null, beaconClientLoginId });
    },
    {
      retry: 0,
      staleTime: 60000,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true
    }
  );

  const uploadFiles = useCallback(async () => {
    const uploadConfig = {
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.warn(
          '🚀 «— file: AdMediaManagement.tsx:172 «— handleFileSelected «— percentCompleted:',
          percentCompleted
        );
      }
    };

    // Video preparation
    const videoUploadPromise =
      uploadUrl && file
        ? (() => {
            const uploadingVideoFile = new FormData();
            uploadingVideoFile.append('file', file);
            uploadingVideoFile.append('uploadUrl', uploadUrl);
            return AdWalmartService.uploadMedia({
              config: uploadConfig,
              file: uploadingVideoFile
            });
          })()
        : Promise.resolve(null);

    // Caption preparation
    const captionUploadPromise =
      captionUrl && caption
        ? (() => {
            const uploadingCaptionFile = new FormData();
            uploadingCaptionFile.append('file', caption);
            uploadingCaptionFile.append('uploadUrl', captionUrl);
            return AdWalmartService.uploadMedia({
              config: uploadConfig,
              file: uploadingCaptionFile
            });
          })()
        : Promise.resolve(null);

    const [VideoRes, CaptionRes] = await Promise.all([videoUploadPromise, captionUploadPromise]);

    return [VideoRes, CaptionRes];
  }, [file, caption, uploadUrl, captionUrl]);

  return { file, setFile, caption, setCaption, uploadFiles, mediaUploadRequestId };
};
