/* eslint-disable prefer-const */
import React from 'react';
import moment from 'moment';
import _merge from 'lodash/merge';
import _differenceBy from 'lodash/differenceBy';
import _isEqual from 'lodash/isEqual';
import ReactDOMServer from 'react-dom/server.browser';

import ArrowChange from 'src/components/common/ArrowChange/ArrowChange';
import fontStyle from 'src/utils/fontStyle';
import colors from 'src/utils/colors';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { buildMetricValue } from 'src/utils/metrics';
import { getTooltipDiv } from './tooltipDiv';
import { chartColorsLineChart, getBaseLineChartSeries } from './util';
import { getNextDay, getNextWeek } from 'src/utils/dateformatting';
import { COMMON_FIELDS } from 'src/utils/entityDefinitions/fields/commonFieldDefinitions';
import { isDrive } from 'src/utils/app';
import { createNonComparisonLegendDivs } from 'src/utils/chartUtils';

/**
 * This function returns an object that is used to encapsulate all intermediary state used while computing the
 * chart's configuration and series.  It is designed to be passed around and mutated by helper functions, building
 * up the config incrementally.
 */
export const createInitialChartCompState = ({
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod,
  mainEntityMetrics,
  mainMetricName,
  comparisonEntityMetrics,
  comparisonMetricName,
  groupByFieldName
}) => {
  const metricsDataByTimeSeries = mainEntityMetrics[mainMetricName];
  const metricsComparisonDataByTimeSeries = comparisonEntityMetrics[comparisonMetricName];
  const { currencySymbol: mainCurrencySymbol, locale: mainLocale } = metricsDataByTimeSeries;
  const { currencySymbol: comparisonCurrencySymbol, locale: comparisonLocale } =
    metricsComparisonDataByTimeSeries || {};

  const mainMetricDependentFieldsSum = {};
  if (metricsDataByTimeSeries.dependentFields) {
    metricsDataByTimeSeries.dependentFields.forEach((field) => {
      mainMetricDependentFieldsSum[field.name] = 0;
      mainMetricDependentFieldsSum[`${field.indexName}__${field.name}`] = 0;
    });
  }

  const isSameMetricComparison =
    metricsDataByTimeSeries.displayName === metricsComparisonDataByTimeSeries.displayName &&
    mainCurrencySymbol === comparisonCurrencySymbol;

  return {
    chartSeries: getBaseLineChartSeries(chartDisplayTimePeriod, metricsDataByTimeSeries),
    mainEntityMetrics,
    comparisonEntityMetrics,
    chartComparisonDisplayTimePeriod,
    chartDisplayTimePeriod,
    metricsDataByTimeSeries,
    mainMetricDependentFieldsSum,
    groupByFieldName,
    mainCurrencySymbol,
    comparisonCurrencySymbol,
    mainLocale,
    comparisonLocale,
    metricsComparisonDataByTimeSeries,
    currencySymbols: [mainCurrencySymbol, comparisonCurrencySymbol],
    locales: [mainLocale, comparisonLocale],
    // we should revisit this one to make sure this approach is correct
    isYearOverYearComparison:
      isSameMetricComparison &&
      chartDisplayTimePeriod.endWeek - chartComparisonDisplayTimePeriod.endWeek === 100 &&
      !(chartDisplayTimePeriod.id === '52w' && chartComparisonDisplayTimePeriod.id === 'prior-period'),
    isSameMetricComparison,
    legendDivs: [],
    // The following are set by `populateFirstChartSeries`
    loadLastWeek: undefined,
    mainLegendMetricValue: undefined,
    mainLegendValue: undefined,
    mainMetricSum: undefined
  };
};

/**
  Creates main data for Legend (TrendChart right top corner)
 * @param {*} compState
 * @param {*} dataPoint
 * @param {*} mainMetricTrueSum
 * @param {*} mainMetricDataPointCount
 * @param {*} mainMetricTrueCount
 * @param {*} mainMetricLastValue
 * @param {*} mainEntityMetrics
 * @param {*} metricsDataByTimeSeries
 * @param {*} groupByFieldName
 * @param {*} mainMetricDependentFieldsSum
 */
const mutateCompStateAndFillCurrentPeriod = (
  compState,
  dataPoint,
  mainMetricTrueSum,
  mainMetricDataPointCount,
  mainMetricTrueCount,
  mainMetricLastValue,
  mainEntityMetrics,
  metricsDataByTimeSeries,
  groupByFieldName,
  mainMetricDependentFieldsSum
) => {
  compState.mainMetricSum += dataPoint.value;
  mainMetricTrueSum += dataPoint.value * dataPoint.count || 0;
  mainMetricDataPointCount += 1;
  mainMetricTrueCount += dataPoint.count || 0;
  mainMetricLastValue = dataPoint.value;
  if (metricsDataByTimeSeries.dependentFields) {
    metricsDataByTimeSeries.dependentFields.forEach((field) => {
      let dependentFieldParamValue = 0;
      const dependentFieldDataPoint = mainEntityMetrics[`${field.name}_by_${groupByFieldName}`].data.find(
        (x) => x.name === dataPoint.name
      );
      if (dependentFieldDataPoint) {
        dependentFieldParamValue = dependentFieldDataPoint.value;
      }
      mainMetricDependentFieldsSum[field.name] += dependentFieldParamValue;
      mainMetricDependentFieldsSum[`${field.indexName}__${field.name}`] += dependentFieldParamValue;
    });
  }
};

const populateFirstChartSeries = (compState, mainLegendValueOverride, comparisonLegendValueOverride) => {
  const {
    chartSeries,
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    metricsDataByTimeSeries,
    mainMetricDependentFieldsSum,
    mainEntityMetrics,
    groupByFieldName,
    mainCurrencySymbol,
    mainLocale
  } = compState;
  compState.mainMetricSum = 0.0;
  let mainMetricDataPointCount = 0.0;
  let mainMetricTrueCount = 0.0;
  let mainMetricLastValue = 0.0;
  let mainMetricTrueSum = 0.0;

  compState.loadLastWeek = chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id !== '1w';
  const mainStartDay = chartDisplayTimePeriod.timePeriod.startDayId;

  const selectedOneDay = chartDisplayTimePeriod.timePeriod.startDayId === chartDisplayTimePeriod.timePeriod.endDayId;
  metricsDataByTimeSeries.data.forEach((dataPoint) => {
    if (mainStartDay <= +dataPoint.name && chartDisplayTimePeriod.timePeriod.endDayId >= +dataPoint.name) {
      const day = moment(dataPoint.name);
      if (selectedOneDay) {
        chartSeries[0].zones = [{ color: chartColorsLineChart[2] }];
        if (chartDisplayTimePeriod.timePeriod.endDayId === +dataPoint.name) {
          chartSeries[0].data.push({
            x: day.valueOf(),
            y: dataPoint.value,
            marker: { enabled: true, radius: 3, fillColor: chartColorsLineChart[0] }
          });
        } else {
          chartSeries[0].data.push({ x: day.valueOf(), y: dataPoint.value });
        }
      } else {
        // Fills at least the Prior Period - MTD, main data
        chartSeries[0].data.push([day.valueOf(), dataPoint.value]);
      }

      if (
        chartDisplayTimePeriod.timePeriod.startDayId <= +dataPoint.name &&
        chartDisplayTimePeriod.timePeriod.endDayId >= +dataPoint.name
      ) {
        mutateCompStateAndFillCurrentPeriod(
          compState,
          dataPoint,
          mainMetricTrueSum,
          mainMetricDataPointCount,
          mainMetricTrueCount,
          mainMetricLastValue,
          mainEntityMetrics,
          metricsDataByTimeSeries,
          groupByFieldName,
          mainMetricDependentFieldsSum
        );
      }
    }
  });

  compState.mainLegendValue =
    metricsDataByTimeSeries.aggregationFunction === 'avg' ||
    metricsDataByTimeSeries.timePeriodAggregationFunction === 'avg'
      ? compState.mainMetricSum / (mainMetricDataPointCount > 0 ? mainMetricDataPointCount : 1)
      : compState.mainMetricSum;

  if (metricsDataByTimeSeries.timePeriodAggregationFunctionType === 'lastValue') {
    compState.mainLegendValue = mainMetricLastValue;
  }

  if (metricsDataByTimeSeries.timePeriodAggregationFunctionType === 'trueAvg') {
    compState.mainLegendValue = mainMetricTrueSum / (mainMetricTrueCount > 0 ? mainMetricTrueCount : 1);
  }

  if (
    (metricsDataByTimeSeries.aggregationFunctionType === 'computed' ||
      metricsDataByTimeSeries.aggregationFunctionType === 'derived') &&
    metricsDataByTimeSeries.aggregationFunctionEvaluator
  ) {
    compState.mainLegendValue =
      metricsDataByTimeSeries.aggregationFunctionEvaluator.evaluate(mainMetricDependentFieldsSum);
  }

  if (mainLegendValueOverride) {
    compState.mainLegendValue = mainLegendValueOverride;
  }
  if (comparisonLegendValueOverride) {
    compState.comparisonLegendValue = comparisonLegendValueOverride;
  }

  compState.mainLegendMetricValue = buildMetricValue(
    compState.mainLegendValue,
    metricsDataByTimeSeries.metricType,
    mainCurrencySymbol,
    true,
    metricsDataByTimeSeries.dataType,
    mainLocale
  );
};

const computePercentChangeChecked = (y0, y1) => {
  if (y0 <= 0) {
    return y1 > 0 ? 100 : 0;
  }

  return (y1 - y0) / y0;
};

const computeComparisonMetrics = (compState) => {
  compState.comparisonMetricSum = 0.0;
  compState.comparisonMetricDataPointCount = 0.0;
  compState.comparisonMetricLastValue = 0.0;
  compState.comparisonMetricDependentFieldsSum = {};
  compState.comparisonMetricTrueSum = 0.0;
  compState.comparisonMetricTrueCount = 0.0;

  if (compState.metricsComparisonDataByTimeSeries.dependentFields) {
    compState.metricsComparisonDataByTimeSeries.dependentFields.forEach((field) => {
      compState.comparisonMetricDependentFieldsSum[field.name] = 0;
      compState.comparisonMetricDependentFieldsSum[`${field.indexName}__${field.name}`] = 0;
    });
  }

  compState.chartSeries.push({
    name: compState.chartComparisonDisplayTimePeriod.displayName,
    metricType: compState.metricsComparisonDataByTimeSeries.metricType,
    data: [],
    color: chartColorsLineChart[1],
    marker: {
      lineColor: chartColorsLineChart[1],
      fillColor: chartColorsLineChart[1],
      lineWidth: 3,
      symbol: 'circle'
    }
  });
};

/**
 Creating comparison metrics for Legend (TrendChart right top corner)
 * @param {*} compState
 * @param {*} dataPoint
 * @param {*} metricsComparisonDataByTimeSeries
 * @param {*} comparisonEntityMetrics
 * @param {*} comparisonMetricDependentFieldsSum
 */
const mutateCompStateAndFillWithComparison = (
  compState,
  dataPoint,
  metricsComparisonDataByTimeSeries,
  comparisonEntityMetrics,
  comparisonMetricDependentFieldsSum
) => {
  compState.comparisonMetricSum += dataPoint.value;
  compState.comparisonMetricDataPointCount += 1;
  compState.comparisonMetricTrueSum += dataPoint.value * dataPoint.count || 0;
  compState.comparisonMetricTrueCount += dataPoint.count || 0;
  compState.comparisonMetricLastValue = dataPoint.value;
  if (metricsComparisonDataByTimeSeries.dependentFields) {
    metricsComparisonDataByTimeSeries.dependentFields.forEach((field) => {
      const datum = comparisonEntityMetrics[`${field.name}_by_${compState.groupByFieldName}`].data.find(
        (x) => x.name === dataPoint.name
      );
      const foundValue = datum ? datum.value : 0;
      comparisonMetricDependentFieldsSum[field.name] += foundValue;
      comparisonMetricDependentFieldsSum[`${field.indexName}__${field.name}`] += foundValue;
    });
  }
};

const populateComparisonSeriesData = (compState) => {
  const {
    metricsComparisonDataByTimeSeries,
    chartComparisonDisplayTimePeriod,
    isYearOverYearComparison,
    // chartDisplayTimePeriod,
    comparisonMetricDependentFieldsSum,
    comparisonEntityMetrics
  } = compState;

  const comparisonSeries = compState.chartSeries[1];
  const { startDayId, endDayId } = chartComparisonDisplayTimePeriod.timePeriod;
  const selectedOneDay = startDayId === endDayId;

  metricsComparisonDataByTimeSeries.data.forEach((dataPoint) => {
    const datumIsInRange = startDayId <= +dataPoint.name && endDayId >= +dataPoint.name;
    if (datumIsInRange) {
      const dayIdToUse = isYearOverYearComparison ? `${+dataPoint.name + 10000}` : `${+dataPoint.name}`;

      const day = moment(dayIdToUse);
      if (selectedOneDay) {
        comparisonSeries.zones = [{ color: chartColorsLineChart[2] }];
        if (endDayId === +dataPoint.name) {
          comparisonSeries.data.push({
            x: day.valueOf(),
            y: dataPoint.value,
            marker: { enabled: true, radius: 3, fillColor: chartColorsLineChart[1] }
          });
        } else {
          comparisonSeries.data.push({ x: day.valueOf(), y: dataPoint.value });
        }
      } else {
        comparisonSeries.data.push([day.valueOf(), dataPoint.value]);
      }
      if (
        chartComparisonDisplayTimePeriod.timePeriod.startDayId <= +dataPoint.name &&
        chartComparisonDisplayTimePeriod.timePeriod.endDayId >= +dataPoint.name
      ) {
        mutateCompStateAndFillWithComparison(
          compState,
          dataPoint,
          metricsComparisonDataByTimeSeries,
          comparisonEntityMetrics,
          comparisonMetricDependentFieldsSum
        );
      }
    }
  });
};

const getComparisonLegendValue = ({
  metricsComparisonDataByTimeSeries,
  comparisonMetricSum,
  comparisonMetricTrueSum,
  comparisonMetricTrueCount,
  comparisonMetricDataPointCount,
  comparisonMetricLastValue,
  comparisonMetricDependentFieldsSum
}) => {
  let comparisonLegendValue =
    metricsComparisonDataByTimeSeries.aggregationFunction === 'avg' ||
    metricsComparisonDataByTimeSeries.timePeriodAggregationFunction === 'avg'
      ? comparisonMetricSum / (comparisonMetricDataPointCount > 0 ? comparisonMetricDataPointCount : 1)
      : comparisonMetricSum;

  if (metricsComparisonDataByTimeSeries.timePeriodAggregationFunctionType === 'lastValue') {
    comparisonLegendValue = comparisonMetricLastValue;
  }

  if (metricsComparisonDataByTimeSeries.timePeriodAggregationFunctionType === 'trueAvg') {
    comparisonLegendValue = comparisonMetricTrueSum / (comparisonMetricTrueCount > 0 ? comparisonMetricTrueCount : 1);
  }

  if (
    (metricsComparisonDataByTimeSeries.aggregationFunctionType === 'computed' ||
      metricsComparisonDataByTimeSeries.aggregationFunctionType === 'derived') &&
    metricsComparisonDataByTimeSeries.aggregationFunctionEvaluator
  ) {
    comparisonLegendValue = metricsComparisonDataByTimeSeries.aggregationFunctionEvaluator.evaluate(
      comparisonMetricDependentFieldsSum
    );
  }

  return comparisonLegendValue;
};

const createComparisonLegendDivs = (compState) => {
  const {
    mainLegendMetricValue,
    comparisonLegendValue,
    chartDisplayTimePeriod,
    metricsComparisonDataByTimeSeries,
    comparisonLegendMetricValue,
    chartComparisonDisplayTimePeriod
  } = compState;
  let mainLegendDiv = `<div style="display: flex;">${compState.mainLegendMetricValue.prefix || ''}${
    mainLegendMetricValue.value
  }${compState.mainLegendMetricValue.suffix || ''}`;

  if (compState.isSameMetricComparison) {
    const metricsChangePercent = (compState.mainLegendValue - comparisonLegendValue) / comparisonLegendValue;
    const changeArrow = ReactDOMServer.renderToString(
      <ArrowChange metric={compState.metricsDataByTimeSeries} value={metricsChangePercent} useImageUrl={isDrive} />
    );
    mainLegendDiv += `<span style="margin-left: 8px; display: flex;" class="legend__percent-change">(${changeArrow})</span>`;
  }

  mainLegendDiv += `</div><div class="legend__primary-date" style="margin-top:5px;">${chartDisplayTimePeriod.displayName}</div>`;

  if (
    compState.metricsDataByTimeSeries.retailer &&
    metricsComparisonDataByTimeSeries.retailer &&
    compState.metricsDataByTimeSeries.retailer.id !== metricsComparisonDataByTimeSeries.retailer.id
  ) {
    mainLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${compState.metricsDataByTimeSeries.retailer.displayName}</div>`;
  } else if (
    compState.metricsDataByTimeSeries.entity.type !== metricsComparisonDataByTimeSeries.entity.type ||
    compState.metricsDataByTimeSeries.entity.id !== metricsComparisonDataByTimeSeries.entity.id
  ) {
    const entityName =
      compState.metricsDataByTimeSeries.entity.shortDisplayName ||
      compState.metricsDataByTimeSeries.entity.displayName ||
      compState.metricsDataByTimeSeries.entity.entityName ||
      compState.metricsDataByTimeSeries.entity.portfolioName ||
      compState.metricsDataByTimeSeries.entity.campaignName;
    mainLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${entityName}</div>`;
  } else if (!compState.isSameMetricComparison) {
    mainLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${compState.metricsDataByTimeSeries.displayName}</div>`;
  }
  compState.legendDivs.push(mainLegendDiv);

  let comparisonLegendDiv = `<div>${comparisonLegendMetricValue.prefix || ''}${comparisonLegendMetricValue.value}${
    comparisonLegendMetricValue.suffix || ''
  }</div>
    <div class="legend__primary-date" style="margin-top:5px;">${chartComparisonDisplayTimePeriod.displayName}</div>`;
  if (
    compState.metricsDataByTimeSeries.retailer &&
    metricsComparisonDataByTimeSeries.retailer &&
    compState.metricsDataByTimeSeries.retailer.id !== metricsComparisonDataByTimeSeries.retailer.id
  ) {
    comparisonLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${metricsComparisonDataByTimeSeries.retailer.displayName}</div>`;
  } else if (
    compState.metricsDataByTimeSeries.entity.type !== metricsComparisonDataByTimeSeries.entity.type ||
    compState.metricsDataByTimeSeries.entity.id !== metricsComparisonDataByTimeSeries.entity.id
  ) {
    const entityName =
      metricsComparisonDataByTimeSeries.entity.shortDisplayName ||
      metricsComparisonDataByTimeSeries.entity.displayName ||
      metricsComparisonDataByTimeSeries.entity.entityName ||
      metricsComparisonDataByTimeSeries.entity.portfolioName ||
      metricsComparisonDataByTimeSeries.entity.campaignName;

    comparisonLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${entityName}</div>`;
  } else if (!compState.isSameMetricComparison) {
    comparisonLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${metricsComparisonDataByTimeSeries.displayName}</div>`;
  }
  compState.legendDivs.push(comparisonLegendDiv);
};

export const fillInMissingTimePeriods = (chartDisplayTimePeriod, mainEntityMetrics, groupByFieldName) => {
  const { timePeriod } = chartDisplayTimePeriod;
  if (mainEntityMetrics) {
    // If there are missing weekIds or dayIds, we need to add them with a value of 0
    // Otherwise,  the spline chart will fill in the gaps to make it look like there
    // is a non 0 number present here
    Object.keys(mainEntityMetrics).forEach((key) => {
      /**
       *  This is a short-term fix for Atlas super users crashing when navigating to Retail Sales page.
       *  A mainEntityMetrics key is being created as undefinedMarketShare_by_weekId and then attempting to access a nonexisting array length causing a crash.
       *  ! TODO: determine where these are being set and why it's undefined
       */
      if (key.includes('undefined')) {
        return;
      }

      if (!key.includes('dataPointCount')) {
        if (key.includes('_by_dayId')) {
          const { startDayId } = timePeriod;
          const { endDayId } = timePeriod;

          let xAxisKey = 'dayId';
          if (mainEntityMetrics[key].data.length > 0) {
            const [first] = mainEntityMetrics[key].data;
            if (first.name) {
              xAxisKey = 'name';
            }
          }

          // Build an array of all day ids and value = 0
          let currDay = startDayId;

          const arr = [];
          while (currDay !== endDayId) {
            arr.push({ name: String(currDay), value: 0, count: 0 });
            currDay = getNextDay(currDay);
          }

          // Merge the missing values with the old array
          const diff = _differenceBy(arr, mainEntityMetrics[key].data, xAxisKey);
          mainEntityMetrics[key].data.push(...diff);

          // Sort by key used for the xAxis
          mainEntityMetrics[key].data.sort((a, b) => {
            if (a[xAxisKey] < b[xAxisKey]) {
              return -1;
            }
            if (a[xAxisKey] > b[xAxisKey]) {
              return 1;
            }
            // a must be equal to b
            return 0;
          });
        } else if (key.includes('_by_weekId')) {
          // Find and loop through
          const { startWeek } = timePeriod;
          const { endWeek } = timePeriod;

          let xAxisKey = 'weekId';
          if (mainEntityMetrics[key].data.length > 0) {
            const [first] = mainEntityMetrics[key].data;
            if (first.name) {
              xAxisKey = 'name';
            }
          }

          // Build an array of all day ids and value = 0
          let currWeek = startWeek;

          const arr = [];
          while (currWeek !== endWeek) {
            arr.push({
              name: String(currWeek),
              weekId: currWeek,
              weekEnding: moment(currWeek, 'YYYYww').toDate(),
              value: 0,
              count: 0
            });
            currWeek = getNextWeek(currWeek);
          }

          // Merge the missing values with the old array
          const diff = _differenceBy(arr, mainEntityMetrics[key].data, xAxisKey);
          mainEntityMetrics[key].data.push(...diff);

          // Only applies for weekId right now
          if (COMMON_FIELDS[groupByFieldName]) {
            const field = COMMON_FIELDS[groupByFieldName];
            if (field.processAdditionalMetaData) {
              mainEntityMetrics[key].data = mainEntityMetrics[key].data.map((dp) => {
                const addData = field.processAdditionalMetaData({ name: dp.weekId });
                return { ...addData, ...dp };
              });
            }
          }

          // Sort by weekId
          mainEntityMetrics[key].data.sort((a, b) => {
            if (a[xAxisKey] < b[xAxisKey]) {
              return -1;
            }
            if (a[xAxisKey] > b[xAxisKey]) {
              return 1;
            }
            // a must be equal to b
            return 0;
          });
        }
      }
    });
  }

  return mainEntityMetrics;
};

/**
 * Used to fill main data with comparison data together, to make sure their charts overlap
 * @param {*} compState
 * @param {*} mainLegendValueOverride
 * @param {*} comparisonLegendValueOverride
 */
const populateFirstChartSeriesForComparison = (compState, mainLegendValueOverride, comparisonLegendValueOverride) => {
  const {
    chartSeries,
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    metricsDataByTimeSeries,
    mainMetricDependentFieldsSum,
    mainEntityMetrics,
    groupByFieldName,
    mainCurrencySymbol,
    mainLocale
  } = compState;
  // Modify series for triple data
  chartSeries[0].keys = ['timeStamp', 'y', 'name', 'marker'];
  chartSeries[0].pointPlacement = 'on';

  compState.mainMetricSum = 0.0;
  let mainMetricDataPointCount = 0.0;
  let mainMetricTrueCount = 0.0;
  let mainMetricLastValue = 0.0;
  let mainMetricTrueSum = 0.0;

  compState.loadLastWeek = chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id !== '1w';
  const mainStartDay = chartDisplayTimePeriod.timePeriod.startDayId;
  const selectedOneDay = chartDisplayTimePeriod.timePeriod.startDayId === chartDisplayTimePeriod.timePeriod.endDayId;

  metricsDataByTimeSeries.data.forEach((dataPoint) => {
    if (mainStartDay <= +dataPoint.name && chartDisplayTimePeriod.timePeriod.endDayId >= +dataPoint.name) {
      const daysDiff = +dataPoint.name - chartDisplayTimePeriod.timePeriod.startDayId + 1;
      const day = moment(dataPoint.name);
      // If Only 1 day MTD
      if (selectedOneDay) {
        chartSeries[0].zones = [{ color: chartColorsLineChart[2] }];
        if (chartDisplayTimePeriod.timePeriod.endDayId === +dataPoint.name) {
          chartSeries[0].data.push([
            day.valueOf(),
            dataPoint.value,
            `Day ${daysDiff}`,
            { enabled: true, radius: 3, fillColor: chartColorsLineChart[0] }
          ]);
        } else {
          chartSeries[0].data.push([day.valueOf(), dataPoint.value, `Day ${daysDiff}`]);
        }
      } else {
        // Fills at least the Prior Period - MTD, main data
        chartSeries[0].data.push([day.valueOf(), dataPoint.value, `Day ${daysDiff}`]);
      }

      if (
        chartDisplayTimePeriod.timePeriod.startDayId <= +dataPoint.name &&
        chartDisplayTimePeriod.timePeriod.endDayId >= +dataPoint.name
      ) {
        mutateCompStateAndFillCurrentPeriod(
          compState,
          dataPoint,
          mainMetricTrueSum,
          mainMetricDataPointCount,
          mainMetricTrueCount,
          mainMetricLastValue,
          mainEntityMetrics,
          metricsDataByTimeSeries,
          groupByFieldName,
          mainMetricDependentFieldsSum
        );
      }
    }
  });

  compState.mainLegendValue =
    metricsDataByTimeSeries.aggregationFunction === 'avg' ||
    metricsDataByTimeSeries.timePeriodAggregationFunction === 'avg'
      ? compState.mainMetricSum / (mainMetricDataPointCount > 0 ? mainMetricDataPointCount : 1)
      : compState.mainMetricSum;

  if (metricsDataByTimeSeries.timePeriodAggregationFunctionType === 'lastValue') {
    compState.mainLegendValue = mainMetricLastValue;
  }

  if (metricsDataByTimeSeries.timePeriodAggregationFunctionType === 'trueAvg') {
    compState.mainLegendValue = mainMetricTrueSum / (mainMetricTrueCount > 0 ? mainMetricTrueCount : 1);
  }

  if (
    (metricsDataByTimeSeries.aggregationFunctionType === 'computed' ||
      metricsDataByTimeSeries.aggregationFunctionType === 'derived') &&
    metricsDataByTimeSeries.aggregationFunctionEvaluator
  ) {
    compState.mainLegendValue =
      metricsDataByTimeSeries.aggregationFunctionEvaluator.evaluate(mainMetricDependentFieldsSum);
  }

  if (mainLegendValueOverride) {
    compState.mainLegendValue = mainLegendValueOverride;
  }
  if (comparisonLegendValueOverride) {
    compState.comparisonLegendValue = comparisonLegendValueOverride;
  }

  // compState.

  compState.mainLegendMetricValue = buildMetricValue(
    compState.mainLegendValue,
    metricsDataByTimeSeries.metricType,
    mainCurrencySymbol,
    true,
    metricsDataByTimeSeries.dataType,
    mainLocale
  );
};

/**
 * Used to fill comparison data with main data so charts overlap
 * @param {*} compState
 */
const populateComparisonSeriesDataWithMainData = (compState) => {
  const {
    metricsComparisonDataByTimeSeries,
    chartComparisonDisplayTimePeriod,
    isYearOverYearComparison,
    // chartDisplayTimePeriod,
    comparisonMetricDependentFieldsSum,
    comparisonEntityMetrics
  } = compState;
  const comparisonSeries = compState.chartSeries[1];
  compState.chartSeries[1].keys = ['timeStamp', 'y', 'name', 'marker'];
  compState.chartSeries[1].pointPlacement = 'on';

  const { startDayId, endDayId } = chartComparisonDisplayTimePeriod.timePeriod;
  const selectedOneDay = startDayId === endDayId;

  metricsComparisonDataByTimeSeries.data.forEach((dataPoint) => {
    const datumIsInRange = startDayId <= +dataPoint.name && endDayId >= +dataPoint.name;
    if (datumIsInRange) {
      const dayIdToUse = isYearOverYearComparison ? `${+dataPoint.name + 10000}` : `${+dataPoint.name}`;
      const daysDiff = +dataPoint.name - startDayId + 1;

      const day = moment(dayIdToUse);
      if (selectedOneDay) {
        comparisonSeries.zones = [{ color: chartColorsLineChart[2] }];
        if (endDayId === +dataPoint.name) {
          comparisonSeries.data.push([
            day.valueOf(),
            dataPoint.value,
            `Day ${daysDiff}`,
            { enabled: true, radius: 3, fillColor: chartColorsLineChart[0] }
          ]);
        } else {
          comparisonSeries.data.push([day.valueOf(), dataPoint.value, `Day ${daysDiff}`]);
        }
      } else {
        comparisonSeries.data.push([day.valueOf(), dataPoint.value, `Day ${daysDiff}`]);
      }
      if (
        chartComparisonDisplayTimePeriod.timePeriod.startDayId <= +dataPoint.name &&
        chartComparisonDisplayTimePeriod.timePeriod.endDayId >= +dataPoint.name
      ) {
        mutateCompStateAndFillWithComparison(
          compState,
          dataPoint,
          metricsComparisonDataByTimeSeries,
          comparisonEntityMetrics,
          comparisonMetricDependentFieldsSum
        );
      }
    }
  });
};

export function getTimeSeriesTrendChartParameters({
  chartPropsOverride,
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod,
  mainEntityMetrics,
  mainMetricName,
  comparisonEntityMetrics,
  comparisonMetricName,
  groupByFieldName,
  widget,
  mainLegendValueOverride,
  comparisonLegendValueOverride
}) {
  const isAllowedDriveComparison = () => {
    if (isDrive) {
      return chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id === 'mtd';
    }
    return false;
  };
  fillInMissingTimePeriods(chartDisplayTimePeriod, mainEntityMetrics, groupByFieldName);
  fillInMissingTimePeriods(chartComparisonDisplayTimePeriod, mainEntityMetrics, groupByFieldName);

  // Todo: build this function to make it return trend chart param like getWeeklyTrendChartParameters() do
  // Creates empty data
  const chartCompState = createInitialChartCompState({
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    mainEntityMetrics,
    mainMetricName,
    comparisonEntityMetrics,
    comparisonMetricName,
    groupByFieldName
  });

  const {
    view: { showOnlyMainSeries, hideSubtitle }
  } = widget;

  const { metricsComparisonDataByTimeSeries, mainCurrencySymbol, comparisonCurrencySymbol } = chartCompState;

  // Use different logic to populate data for Drive if comparison data exist
  if (isAllowedDriveComparison() && chartComparisonDisplayTimePeriod && metricsComparisonDataByTimeSeries) {
    populateFirstChartSeriesForComparison(chartCompState, mainLegendValueOverride, comparisonLegendValueOverride);
  } else {
    // Fills up with main series data
    populateFirstChartSeries(chartCompState, mainLegendValueOverride, comparisonLegendValueOverride);
  }

  if (mainLegendValueOverride) {
    chartCompState.mainLegendValue = mainLegendValueOverride;
  }

  if (chartComparisonDisplayTimePeriod && metricsComparisonDataByTimeSeries) {
    computeComparisonMetrics(chartCompState);

    // Filling the comparison data
    if (isAllowedDriveComparison() && chartComparisonDisplayTimePeriod && metricsComparisonDataByTimeSeries) {
      populateComparisonSeriesDataWithMainData(chartCompState);
    } else {
      populateComparisonSeriesData(chartCompState);
    }

    chartCompState.comparisonLegendValue = getComparisonLegendValue(chartCompState);

    if (comparisonLegendValueOverride) {
      chartCompState.comparisonLegendValue = comparisonLegendValueOverride;
    }

    chartCompState.comparisonLegendMetricValue = buildMetricValue(
      chartCompState.comparisonLegendValue,
      metricsComparisonDataByTimeSeries.metricType,
      comparisonCurrencySymbol,
      true,
      metricsComparisonDataByTimeSeries.dataType,
      chartCompState.comparisonLocale
    );

    createComparisonLegendDivs(chartCompState);
    // Apply logic to remove comparison series
    if (
      showOnlyMainSeries &&
      mainMetricName === comparisonMetricName &&
      _isEqual(mainEntityMetrics, comparisonEntityMetrics)
    ) {
      chartCompState.chartSeries.splice(1, 1);
    }
  } else {
    createNonComparisonLegendDivs(chartCompState);
  }

  const { chartSeries, legendDivs } = chartCompState;
  const chartHasMultipleSeries = chartSeries.length > 1;
  if (chartHasMultipleSeries) {
    [chartSeries[0].legendDiv, chartSeries[1].legendDiv] = legendDivs;
  } else {
    [chartSeries[0].legendDiv] = legendDivs;
  }
  const xAxis = [];
  const longestSeriesData = chartHasMultipleSeries
    ? chartSeries[0].data.length > chartSeries[1].data.length
      ? chartSeries[0].data
      : chartSeries[1].data
    : chartSeries[0].data;

  if (chartHasMultipleSeries && longestSeriesData.length > 0) {
    let xAxisMin = longestSeriesData[0][0];
    let xAxisMax = longestSeriesData[longestSeriesData.length - 1][0];
    if (chartSeries[1].data.length === chartSeries[0].data.length) {
      xAxisMin = Math.min(chartSeries[0].data[0][0], chartSeries[1].data[0][0]);
      xAxisMax = Math.max(
        chartSeries[0].data[longestSeriesData.length - 1][0],
        chartSeries[1].data[longestSeriesData.length - 1][0]
      );
    }

    // Changes min, max value, without this it would show comparison chart only
    if (
      chartComparisonDisplayTimePeriod &&
      chartComparisonDisplayTimePeriod.id === 'prior-period' &&
      chartSeries[1].data.length &&
      chartSeries[0].data.length
    ) {
      try {
        [[xAxisMin]] = chartSeries[1].data;
        [xAxisMax] = chartSeries[0].data[chartSeries[0].data.length - 1];
      } catch {
        xAxisMin = chartSeries[1].data[0].x;
        xAxisMax = chartSeries[0].data[0].x;
      }
    }

    /**
     * Creating 'Day' labels for PP, PY - MTD
     * @returns obj
     */
    const getLabels = () => {
      if (isAllowedDriveComparison()) {
        return {
          formatter: (_props) => {
            return _props.value;
          }
        };
      } else {
        return {
          align: 'left'
        };
      }
    };

    const getType = () => {
      return isAllowedDriveComparison() ? 'category' : 'datetime';
    };

    const xAxisProps = () => {
      if (isAllowedDriveComparison()) {
        return {
          className: '',
          lineWidth: 0,
          tickWidth: 0,
          type: getType(),
          labels: getLabels()
        };
      } else {
        return {
          className: '',
          min: xAxisMin,
          max: xAxisMax,
          lineWidth: 0,
          tickWidth: 0,
          labels: getLabels(),
          type: getType(),
          dateTimeLabelFormats: {
            day: '%b %e'
          }
        };
      }
    };

    xAxis.push(xAxisProps());
  } else {
    xAxis.push({
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%b %e'
      }
    });
  }
  let chartProps = {
    chart: { type: 'areaspline' },
    title: { text: chartCompState.metricsDataByTimeSeries.displayName },
    subtitle: { text: hideSubtitle ? '' : chartCompState.metricsDataByTimeSeries.subtitle },
    plotOptions: {
      fillEnabled: true,
      series: {
        states: {
          hover: {
            enabled: true
          }
        }
      }
    },
    legend: {
      labelFormatter() {
        return this.userOptions.legendDiv;
      }
    },
    tooltip: {
      formatter() {
        let tooltipStyle = '';
        let percentChangeDiv = '';
        // compute metric change, only if there are two metrics and they are of same type
        if (chartCompState.isSameMetricComparison && this.points.length > 1) {
          let metricsChange = { icon: '+', color: colors.green };
          let percentChange = computePercentChangeChecked(this.points[0].y, this.points[1].y);

          // if the change is negative, then display in red with minus icon
          if (percentChange < 0) {
            metricsChange = { icon: '\u2212', color: colors.red };
            percentChange *= -1;
          }

          percentChange = buildMetricValue(
            percentChange,
            METRICTYPE.PERCENT,
            mainCurrencySymbol,
            true,
            null,
            chartCompState.mainLocale
          ).value;

          const tooltipWidth =
            100 +
            (this.points[0].x.name
              ? Math.min(this.points[0].x.name.length, 10) * 6
              : percentChange.toString().length * 7);
          percentChangeDiv = `<div style="display:inline-block; float:right; color:${metricsChange.color};">
              ${metricsChange.icon}${percentChange}%
            </div>`;
          tooltipStyle += `width: ${tooltipWidth}px;`;
        }
        let yAxisMetricsDiv = '';

        const isDriveDays = isAllowedDriveComparison();
        let timeStampToUse = '';

        for (let i = this.points.length - 1; i >= 0; i -= 1) {
          const value = this.points[i];
          // Hide first tooltip, why?
          if (!isDrive && value.point.index === 0 && chartCompState.loadLastWeek) {
            percentChangeDiv = '';
          } else {
            const { color } = chartSeries[value.series.index];
            const metricValue = buildMetricValue(
              value.y,
              chartSeries[value.series.index].metricType,
              chartCompState.currencySymbols[value.series.index],
              true,
              null,
              chartCompState.locales[value.series.index]
            );

            // Comparison or main period will have different date on tooltip, based on color
            timeStampToUse =
              color === '#46a8f6'
                ? moment(this.points[0].point.timeStamp).add(1, 'month').format('M/D')
                : moment(this.points[0].point.timeStamp).format('M/D');

            yAxisMetricsDiv += `<div style="margin-top:5px;color:${color};">
                ${metricValue.prefix || ''}${metricValue.value}
                <span class='sl-metric__suffix'>${metricValue.suffix || ''}</span>
                <span class="sl-metric__week-ending">${isDriveDays ? `<span> ${timeStampToUse} </span>` : ''}</span>
              </div>`;
          }
        }

        const driveProps = {
          isDriveDays,
          point: this.points[0].point
        };

        return yAxisMetricsDiv === ''
          ? false
          : getTooltipDiv(tooltipStyle, percentChangeDiv, yAxisMetricsDiv, this.x, true, null, driveProps);
      },
      positioner(labelWidth, labelHeight, point) {
        return { x: point.plotX, y: point.plotY - 20 };
      }
    },
    xAxis,
    yAxis: [
      {
        labels: {
          formatter() {
            const val = buildMetricValue(
              this.value,
              chartCompState.metricsDataByTimeSeries.metricType,
              chartCompState.currencySymbols[0],
              true,
              null,
              chartCompState.locales[0]
            );
            return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
          }
        }
      },
      {
        opposite: true,
        labels: {
          formatter() {
            const val = buildMetricValue(
              this.value,
              metricsComparisonDataByTimeSeries.metricType,
              chartCompState.currencySymbols[1],
              true,
              null,
              chartCompState.locales[1]
            );
            return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
          }
        }
      }
    ]
  };

  chartProps = _merge(chartProps, chartPropsOverride);

  if (chartHasMultipleSeries) {
    // check !shouldMergeAxes if you want to merge to Y-axis with similar max-y value.
    if (!chartCompState.isSameMetricComparison && !chartProps.singleYAxis) {
      const titleStyle = {
        'font-size': '12px',
        'font-family': "'Roboto', sans-serif",
        'font-weight': fontStyle.regularWeight,
        color: chartColorsLineChart[0]
      };
      const cmpTitleStyle = {
        'font-size': '12px',
        'font-family': "'Roboto', sans-serif",
        'font-weight': fontStyle.regularWeight,
        color: chartColorsLineChart[1]
      };

      chartProps.yAxis[0].title = {
        text: chartCompState.metricsDataByTimeSeries.displayName,
        useHTML: true,
        style: titleStyle
      };
      chartProps.yAxis.push({
        title: {
          text: metricsComparisonDataByTimeSeries.displayName,
          useHTML: true,
          style: cmpTitleStyle
        },
        labels: {
          formatter() {
            const val = buildMetricValue(
              this.value,
              metricsComparisonDataByTimeSeries.metricType,
              mainCurrencySymbol,
              true,
              null,
              chartCompState.mainLocale
            );
            return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
          }
        },
        opposite: true
      });
      chartSeries[1].yAxis = 1;
    } else {
      chartSeries[1].yAxis = 0;
    }
  }

  // SWITCH ORDER TO OLD-TO-NEW
  // why?
  chartSeries.push(chartSeries.shift());
  const { mainLegendMetricValue, mainLegendValue, comparisonLegendValue } = chartCompState;

  chartProps.legend.mainLegendDisplayValue = `${mainLegendMetricValue.prefix || ''}${mainLegendMetricValue.value}${
    mainLegendMetricValue.suffix || ''
  }`;

  chartProps.legend.metricsChangePercent = comparisonLegendValue
    ? (mainLegendValue - comparisonLegendValue) / comparisonLegendValue
    : 100;

  return { chartProps, chartSeries };
}
