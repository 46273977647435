/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Entity } from 'sl-api-connector/types';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import 'src/styles/_forms.scss';
import ReduxStore from 'src/types/store/reduxStore';
import fontStyle from 'src/utils/fontStyle';

const EmptyMySavedSearches: React.FC<RouteComponentProps & { entityDefinition: Entity; app: ReduxStore['app'] }> = ({
  entityDefinition,
  history,
  app
}) => (
  <div style={{ padding: '50px 0 70px 0' }}>
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ fontSize: '20px', fontWeight: fontStyle.regularWeight }}>
        No {entityDefinition.displayNamePlural} created
      </h3>
      <LargeMuiButton
        onClick={() => history.push(`/search${app.queryParams.searchParams}&entityType=${entityDefinition.type}`)}
      >
        {`Create New ${entityDefinition.displayName}`}
      </LargeMuiButton>
    </div>
  </div>
);

const mapStateToProps = (state: ReduxStore) => {
  const { app } = state;
  return { app };
};

export default connect(mapStateToProps)(withRouter(EmptyMySavedSearches));
