import React, { useState, memo } from 'react';
import { useQueryParamValue } from 'src/utils/Hooks';
import { SlColumn, Text } from '@stackline/ui';
import { getAppName } from 'src/utils/app';
import { useBuildForecastInsightText } from './hooks';
import ForecastingAlgorithmModal from './ForecastingAlgorithmModal';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import ForecastKeyMetricSummaryCards from './ForecastKeyMetricSummaryCards';
import useForecastMetricByWeekId from './serverProxy/useForecastMetricByWeekId';
import { getCardTitle } from 'src/components/BeaconRedesignComponents/KeyMetricCards/SmartKeyMetricCard';
import useForecastDerivedMetricByWeekId from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useForecastDerivedMetricByWeekId';

function LearnMoreText({ handleOpenModal }: { handleOpenModal: () => void }) {
  return (
    <>
      Learn{' '}
      <Text onClick={handleOpenModal} link underlined inline>
        more
      </Text>{' '}
      about the forecasting algorithm.
    </>
  );
}
interface InsightTextProps {
  indexName: string;
  fieldName: string;
  titleOverrides?: Record<string, Record<string, string>>;
  handleOpenModal: () => void;
}

function DerivedInsightText({ indexName, fieldName, titleOverrides, handleOpenModal }: InsightTextProps) {
  const { isLoading, percentChange, formattedPrimaryTotal } = useForecastDerivedMetricByWeekId({
    indexName,
    fieldName
  });

  const insightText = useBuildForecastInsightText({
    changePercent: percentChange,
    fieldName,
    fieldTitle: getCardTitle(indexName, fieldName, titleOverrides),
    formattedTotal: formattedPrimaryTotal
  });

  return isLoading ? null : (
    <Text>
      {insightText} <LearnMoreText handleOpenModal={handleOpenModal} />
    </Text>
  );
}

function InsightText({ indexName, fieldName, titleOverrides, handleOpenModal }: InsightTextProps) {
  const { isLoading, percentChange, formattedPrimaryTotal } = useForecastMetricByWeekId({
    indexName,
    fieldName
  });

  const insightText = useBuildForecastInsightText({
    changePercent: percentChange,
    fieldName,
    fieldTitle: getCardTitle(indexName, fieldName, titleOverrides),
    formattedTotal: formattedPrimaryTotal
  });

  return isLoading ? null : (
    <Text>
      {insightText} <LearnMoreText handleOpenModal={handleOpenModal} />
    </Text>
  );
}

// eslint-disable-next-line react/display-name
const SmartInsightText = memo(({ indexName, fieldName, titleOverrides, ...rest }: InsightTextProps) => {
  const field = INDEX_FIELDS.getField(getAppName(), indexName, fieldName);

  return field.aggregationFunctionType === 'derived' ? (
    <DerivedInsightText {...{ indexName, fieldName, titleOverrides, ...rest }} />
  ) : (
    <InsightText {...{ indexName, fieldName, titleOverrides, ...rest }} />
  );
});

interface ForecastKeyMetricsSummaryProps {
  titleOverrides?: Record<string, Record<string, string>>;
}
/**
 * Displays a grid of metrics with their
 * forecasted data
 */
export default memo(function ForecastKeyMetricsSummary({ titleOverrides }: ForecastKeyMetricsSummaryProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const selectedIndex = useQueryParamValue('indexName', 'sales');
  const selectedKeyMetric = useQueryParamValue('keyMetric', 'retailSales');

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      <SlColumn spacing="lg" widths="full">
        <SmartInsightText
          fieldName={selectedKeyMetric}
          indexName={selectedIndex}
          titleOverrides={titleOverrides}
          handleOpenModal={handleOpenModal}
        />
        <ForecastKeyMetricSummaryCards titleOverrides={titleOverrides} />
      </SlColumn>
      <ForecastingAlgorithmModal open={modalOpen} onClose={handleCloseModal} />
    </>
  );
});
