import types from './types';
import actions from './actions';
import { ValueOf } from 'sl-api-connector/types';

const chargeBackStatuses = (state = [], action: ReturnType<ValueOf<typeof actions>> | any) => {
  switch (action.type) {
    case types.RECEIVE_ALL_CHARGEBACK_STATUSES:
      return Object.assign([], action.chargeBackStatuses);
    case types.CLEAR_ALL_CHARGEBACK_STATUSES:
      return [];
    default:
      return state;
  }
};

export default chargeBackStatuses;
