import { TrafficDatum, TrafficResponseAdditionalValues } from './types';
import { UseQueryResult } from 'react-query';
import { useMetricDataByWeekId } from './useMetricDataByWeekId';

interface UseBeaconTrafficDataByWeekIdProps {
  requestId?: string;
  startWeekId: number;
  endWeekId: number;
}

/**
 * Gets total traffic by week ID.
 */
export const useBeaconTrafficDataByWeekId = ({
  requestId,
  startWeekId,
  endWeekId
}: UseBeaconTrafficDataByWeekIdProps): UseQueryResult<TrafficDatum[]> => {
  return useMetricDataByWeekId<TrafficResponseAdditionalValues, TrafficDatum>({
    endWeekId,
    startWeekId,
    fields: [
      {
        name: 'totalClicks'
      },
      {
        name: 'organicClicks'
      },
      {
        name: 'otherClicks'
      },
      {
        name: 'stacklineSku'
      }
    ],
    indexName: 'traffic',
    requestId
  });
};
