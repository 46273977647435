import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import {
  updateKeywordListSegment,
  fetchKeywordSegmentListData
} from 'src/store/modules/omni/omniKeywordList/operation';
import { useDispatch, useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import ListSegmentComponent from 'src/components/Omni/OmniSegmentList/ListSegmentComponent';
import OmniSegmentDeleteDialog from 'src/components/Omni/OmniSegmentList/OmniSegmentDeleteDialog';

const OmniKeywordList: React.FC<RouteComponentProps> = ({ history }: RouteComponentProps) => {
  const [selected, setSelected] = useState({ queryId: '', name: '' });
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const keywordListData = useSelector((state: ReduxStore) => state.omniKeywordSegmentService);
  const app = useSelector((state: ReduxStore) => state.app);
  const { searchParams } = app.queryParams;
  const isFetching = keywordListData ? keywordListData.isFetching : true;
  const data = keywordListData ? keywordListData.data : [];
  const displayData = isFetching
    ? []
    : data.map((e) => ({ name: e.name, id: e.queryId, canEdit: e.canEdit, ownerProfile: e.ownerProfile }));

  const handleListItemClick = (id: string) => {
    history.push(`keywordSegment/${id}${searchParams}&tab=shareOfShelf&subtab=total`);
  };

  const handleDeleteButtonClick = (queryId: string, name: string) => {
    setSelected({
      queryId,
      name
    });
    setIsOpen(true);
  };

  const handleDelete = () => {
    const { queryId, name } = selected;

    updateKeywordListSegment({ queryId, name, isActive: false, segmentType: 'keyword' }).then(() => {
      dispatch(fetchKeywordSegmentListData());
      setIsOpen(false);
    });
  };

  return isFetching ? (
    <div>Loading</div>
  ) : (
    <>
      <ListSegmentComponent
        title="Keywords Segment"
        data={displayData}
        handleListItemClick={handleListItemClick}
        handleDeleteButtonClick={handleDeleteButtonClick}
        searchBarInfo={{
          name: 'Keywords Segment',
          searchHintText: 'Search Keywords Segment Name'
        }}
      />
      <OmniSegmentDeleteDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selected={selected}
        deleteButtonText="DELETE"
        handleDelete={handleDelete}
      />
    </>
  );
};

export default withRouter(OmniKeywordList);
