import React from 'react';
import BeaconMetricSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconMetricSplineChart';
import useKeywordSearchRequestBuilder from './useKeywordSearchRequestBuilder';

/**
 * Ad clicks spline chart on the keywords search page
 */
export default function KeywordAdClicksSpline() {
  const keywordSearchRequestBuilder = useKeywordSearchRequestBuilder('advertising');
  return (
    <BeaconMetricSplineChart
      indexName="advertising"
      fieldName="clicks"
      requestBuilder={keywordSearchRequestBuilder((builder) => {
        // Ad clicks uses different terms for keywords
        return builder
          .replaceConditionTermFilterFieldName('searchTermFuzzy', 'searchKeywordFuzzy')
          .replaceConditionTermFilterFieldName('excludedSearchTerm', 'excludedSearchKeyword')
          .replaceConditionTermFilterFieldName('excludedSearchTermFuzzy', 'excludedSearchKeywordFuzzy')
          .replaceConditionTermFilterFieldName('searchTerm', 'searchKeyword');
      })}
    />
  );
}
