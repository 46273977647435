import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _cloneDeep from 'lodash/cloneDeep';
import MenuItem from '@mui/material/MenuItem';
import { comparisonParams, filterParams } from '../../../utils/segments';
import { addQueryParams } from '../../../utils/browser';

class SubCategories extends React.Component {
  static defaultProps = {
    searchText: '',
    togglePanel: () => {}
  };

  static propTypes = {
    entityService: PropTypes.object.isRequired,
    subCategories: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    searchText: PropTypes.string,
    togglePanel: PropTypes.func
  };

  static contextTypes = {
    router: PropTypes.object
  };

  componentWillMount() {
    const { subCategories } = this.props;
    const allSubCategories = _cloneDeep(subCategories);
    allSubCategories.sort(
      (a, b) => b.relevancyScore - a.relevancyScore || a.subCategoryName.localeCompare(b.subCategoryName)
    );
    this.setState({ subCategories: allSubCategories });
  }

  componentWillReceiveProps(nextProps) {
    const { searchText, subCategories } = nextProps;

    const subCategoriesFiltered = subCategories.filter(
      (val) => val.subCategoryName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    );
    this.setState({
      subCategories: subCategoriesFiltered
    });
  }

  handleTouchTap = (selection) => {
    const { togglePanel, location, retailer, mainTimePeriod } = this.props;
    const entity = {
      id: selection.subCategoryId,
      type: 'subcategory'
    };

    const existingParams = addQueryParams(location, retailer, mainTimePeriod, [...filterParams, ...comparisonParams]);

    togglePanel();
    this.context.router.history.push(`?ctype=${entity.type}&cid=${entity.id}&${existingParams}`);
  };

  showNoSubCategories() {
    const { subCategories } = this.state;

    if (subCategories.length > 0) {
      return null;
    }

    return <div style={{ padding: '10px' }}>No subcategories found.</div>;
  }

  render() {
    const { subCategories } = this.state;
    return (
      <div style={{ padding: '10px' }}>
        {subCategories.map((val) => (
          <MenuItem key={val.subCategoryId} style={{ fontSize: '14px' }} onClick={() => this.handleTouchTap(val)}>
            {val.subCategoryName}
          </MenuItem>
        ))}
        {this.showNoSubCategories()}
      </div>
    );
  }
}

export default withRouter(connect((state) => state)(SubCategories));
