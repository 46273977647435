import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { AutoSizer, List as VirtualizedList, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import _orderBy from 'lodash/orderBy';
import _pick from 'lodash/pick';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomizedCheckbox from 'src/components/common/CustomizedCheckBox/CustomizedCheckBox';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from 'src/components/SvgIcons';
import 'src/components/EntityPage/Filters/Filter.scss';
import { anyNotEq } from 'src/utils/equality';
import { shouldShowCriteo } from 'src/utils/app';
import _get from 'lodash/get';

const cache = new CellMeasurerCache({
  defaultHeight: 45,
  fixedWidth: true
});

class ListItemsSelector extends Component {
  static propTypes = {
    listItemDataDependencyPropNames: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.object.isRequired,
    idFieldName: PropTypes.string.isRequired,
    nameFieldName: PropTypes.string.isRequired,
    listDisplayName: PropTypes.string.isRequired,
    searchFieldHintText: PropTypes.string.isRequired,
    searchFieldNotFoundText: PropTypes.string.isRequired,
    listItems: PropTypes.array.isRequired,
    selectedListItems: PropTypes.array.isRequired,
    onSelectedListItemsChange: PropTypes.func.isRequired,
    listItemHeight: PropTypes.number
  };

  static defaultProps = {
    listItemDataDependencyPropNames: [],
    listItemHeight: 45
  };

  state = {
    listItemsToShow: [],
    searchText: ''
  };

  componentDidMount() {
    this.setListItems(this.props);
  }

  componentWillUpdate(nextProps) {
    if (anyNotEq(nextProps.listItemDataDependencyPropNames, this.props, nextProps)) {
      this.setListItems(nextProps);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { location, listItems } = nextProps;
    if (!_isEqual(this.props.location, location) || !_isEqual(this.props.listItems, listItems)) {
      this.setListItems(nextProps);
    }
  }

  setListItems = (props) => {
    const { listItems, selectedListItems, idFieldName } = props;
    let listItemsAvailable = _cloneDeep(listItems);
    if (selectedListItems && selectedListItems.length >= 0) {
      selectedListItems.forEach((selectedListItem) => {
        if (selectedListItem.i) {
          let matchingElement = null;

          if (shouldShowCriteo()) {
            matchingElement = listItemsAvailable.find(
              (x) =>
                `${x[idFieldName]}` === selectedListItem.i.toString() ||
                `${_get(x, ['extendedAttributes', idFieldName])}` === selectedListItem.i.toString()
            );
          } else {
            matchingElement = listItemsAvailable.find((x) => `${x[idFieldName]}` === selectedListItem.i.toString());
          }

          if (matchingElement) {
            matchingElement.isChecked = true;
          }
        }
      });
    }

    listItemsAvailable = _orderBy(listItemsAvailable, [({ isChecked }) => (isChecked ? 1 : 0)], ['desc']);

    this.setState({
      listItemsAvailable,
      listItemsToShow: listItemsAvailable
    });

    this.clearInput();
  };

  clearInput = () => {
    this.setState({ searchText: '' });
  };

  handleCheck = (event, isInputChecked, index) => {
    const { onSelectedListItemsChange, selectedListItems, idFieldName, nameFieldName } = this.props;
    const { listItemsToShow, listItemsAvailable } = this.state;
    listItemsToShow[index].isChecked = isInputChecked;
    const selectedListItem = listItemsToShow[index];
    let findItem = listItemsAvailable.find(
      (x) =>
        x[idFieldName] &&
        x[idFieldName].toString() === selectedListItem[idFieldName] &&
        selectedListItem[idFieldName].toString()
    );
    let selectedListItemsCloned = _cloneDeep(selectedListItems);
    if (!findItem) {
      findItem = listItemsAvailable.find(
        (x) =>
          _get(x, ['extendedAttributes', idFieldName]) &&
          _get(x, ['extendedAttributes', idFieldName]).toString() ===
            _get(selectedListItem, ['extendedAttributes', idFieldName]) &&
          _get(selectedListItem, ['extendedAttributes', idFieldName], '').toString()
      );
      findItem.checked = true;

      if (isInputChecked) {
        selectedListItemsCloned.push({
          i: _get(selectedListItem, ['extendedAttributes', idFieldName]),
          n: _get(selectedListItem, ['extendedAttributes', nameFieldName])
        });
      } else {
        selectedListItemsCloned = selectedListItems.filter(
          (x) => x.i.toString() !== `${_get(selectedListItem, ['extendedAttributes', idFieldName])}`
        );
      }
    } else {
      findItem.checked = true;
      if (isInputChecked) {
        selectedListItemsCloned.push({ i: selectedListItem[idFieldName], n: selectedListItem[nameFieldName] });
      } else {
        selectedListItemsCloned = selectedListItems.filter(
          (x) => x.i.toString() !== `${selectedListItem[idFieldName]}`
        );
      }
    }

    onSelectedListItemsChange({ key: idFieldName, values: selectedListItemsCloned });
  };

  handleCheckOld = (event, isInputChecked, index) => {
    const { onSelectedListItemsChange, selectedListItems, idFieldName, nameFieldName } = this.props;
    const { listItemsToShow, listItemsAvailable } = this.state;
    listItemsToShow[index].isChecked = isInputChecked;
    const selectedListItem = listItemsToShow[index];
    listItemsAvailable.find(
      (x) => x[idFieldName].toString() === selectedListItem[idFieldName].toString()
    ).checked = true;
    let selectedListItemsCloned = _cloneDeep(selectedListItems);

    if (isInputChecked) {
      selectedListItemsCloned.push({ i: selectedListItem[idFieldName], n: selectedListItem[nameFieldName] });
    } else {
      selectedListItemsCloned = selectedListItems.filter((x) => x.i.toString() !== `${selectedListItem[idFieldName]}`);
    }

    onSelectedListItemsChange({ key: idFieldName, values: selectedListItemsCloned });
  };

  handleInputChange = (event) => {
    const { listItemsAvailable } = this.state;
    const { value } = event.target;

    if (!value || value === '') {
      this.setState({
        searchText: value,
        listItemsToShow: listItemsAvailable
      });
    } else {
      const listItemsToShow = [];
      listItemsAvailable.forEach((listItem) => {
        if (listItem[this.props.nameFieldName].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
          listItemsToShow.push(listItem);
        }
      });

      this.setState({
        searchText: value,
        listItemsToShow: listItemsToShow.sort((a, b) => b.isChecked - a.isChecked || a.name.localeCompare(b.name))
      });
    }
  };

  rowRenderer = ({ index, style }) => {
    const { nameFieldName } = this.props;
    const { listItemsToShow } = this.state;
    const listItem = listItemsToShow[index];
    const handleCheckUse = shouldShowCriteo() ? this.handleCheck : this.handleCheckOld;
    return (
      <div key={listItem[nameFieldName]} style={style}>
        <FormControlLabel
          control={
            <CustomizedCheckbox
              styleObj={{
                width: 24,
                height: 24,
                iconBackgroundColor: 'transparent',
                iconColor: 'transparent',
                checkedIconBackgroundColor: 'transparent',
                checkedIconColor: 'transparent'
              }}
              InnerIcon={CheckboxCheckedIcon}
              OuterIcon={CheckboxUncheckedIcon}
              checkboxProps={{
                checked: listItem.isChecked,
                onChange: (evt) => {
                  handleCheckUse(evt, evt.target.checked, index);
                }
              }}
            />
          }
          label={<span style={{ fontSize: '13px' }}>{listItem[nameFieldName] || ''}</span>}
        />
      </div>
    );
  };

  render() {
    const { listDisplayName, searchFieldHintText, searchFieldNotFoundText, listItemHeight } = this.props;
    const { listItemsToShow, searchText } = this.state;

    return (
      <div className="search-form-container search-form-container--sm">
        <h4 className="sl-form-label">{listDisplayName}</h4>
        <TextField
          variant="standard"
          autoComplete="off"
          className="sl-form-input"
          placeholder={searchFieldHintText}
          type="text"
          name="keyword"
          id="keyword"
          style={{ width: '100%' }}
          value={searchText}
          onChange={this.handleInputChange}
        />
        {_isEmpty(listItemsToShow) ? (
          <div style={{ height: '175px' }}>{searchFieldNotFoundText}</div>
        ) : (
          <AutoSizer disableHeight>
            {({ width }) => (
              <VirtualizedList
                width={width}
                height={listItemsToShow.length < 5 ? listItemsToShow.length * listItemHeight : 175}
                rowCount={listItemsToShow.length}
                rowHeight={cache.rowHeight}
                style={{ marginBottom: '10px' }}
                rowRenderer={({ ...props }) => {
                  const { key, parent, index } = props;
                  return (
                    <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                      {this.rowRenderer(props)}
                    </CellMeasurer>
                  );
                }}
              />
            )}
          </AutoSizer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, ['app', 'retailer', 'user', 'categories', 'subCategories', 'filters', 'entityService']);

export default withRouter(connect(mapStateToProps)(ListItemsSelector));
