import _cloneDeep from 'lodash/cloneDeep';

import { INDEX_FIELDS, ENTITIES, METRICTYPE, DATATYPE } from 'src/utils/entityDefinitions';
import { buildTrendingProductsWidgets } from 'src/components/Grids/TrendingProductsGrid';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import {
  buildTrendWidgetConfig,
  buildSummaryTrendColumnGroupsWidget,
  summarySubTrendView,
  summaryTrendStyle
} from 'src/components/Layout/LayoutUtil';
import CommonSummaryTrend from 'src/components/EntityPage/SummaryTrendChart/CommonSummaryTrend';
import CommonSummaryInfo from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import getScorecardBasicPageLayout from './ScorecardPageBasicLayout';
import { onPrimeMode } from 'src/store/modules/user/selectors';
import { buildSubtitleDisplayName } from 'src/utils/filters';

export default function getScorecardPageLayout({ app, retailer, user, tab, metricType, entity }) {
  if (!onPrimeMode()) {
    return getScorecardBasicPageLayout({ app, retailer, user, tab, metricType, entity });
  }

  const indexName = 'sales';
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
  const retailerIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'));
  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.atlas.retailer),
    categoryEntity: _cloneDeep(ENTITIES.atlas.category),
    relatedEntity: _cloneDeep(ENTITIES.atlas.brand),
    weekIdField,
    retailerIdField
  };

  const widgets = [];
  const retailSalesMarketShareField = INDEX_FIELDS.getField(
    app.name,
    indexName,
    'retailSalesMarketShare',
    entity.type,
    weekIdField.name
  );
  const unitsSoldMarketShareField = INDEX_FIELDS.getField(
    app.name,
    indexName,
    'unitsSoldMarketShare',
    entity.type,
    weekIdField.name
  );

  const keywordsWithTrafficWidgetConfig = buildTrendWidgetConfig(
    app,
    'traffic',
    entity,
    'weekId',
    ['searchTerm'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: {
            legendTitle: 'KEYWORDS',
            enabled: false
          },
          ...summarySubTrendView
        },
        linkTo: { tab: 'traffic', subtab: 'totalClicks', scrollTo: 'searchTermByweekId' },
        container: { ...summaryTrendStyle.subTrend }
      },
      CustomComponent: CommonSummaryTrend
    }
  );
  keywordsWithTrafficWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'searchTerm'
  ).conditions = {
    rangeFilters: [
      {
        fieldName: 'totalClicks',
        minValue: 1
      }
    ]
  };

  widgets.push({
    CustomComponent: CommonSummaryInfo,
    name: 'CommonSummaryInfo',
    view: {
      name: 'CommonSummaryInfo',
      // eslint-disable-next-line no-shadow
      buildSubTitle: ({ retailer, mainEntity, filters, categories, app }) => {
        return mainEntity
          ? buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app).displayName
          : 'Summary';
      }
    }
  });

  const productsWithSalesWidgetConfig = buildTrendWidgetConfig(
    app,
    'sales',
    entity,
    'weekId',
    ['stacklineSku'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: {
            legendTitle: 'PRODUCTS',
            enabled: false
          },
          ...summarySubTrendView
        },
        linkTo: { tab: 'sales', subtab: 'retailSales', scrollTo: 'productCountMetric' },
        container: { ...summaryTrendStyle.subTrend }
      },
      CustomComponent: CommonSummaryTrend
    }
  );

  productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'stacklineSku'
  ).conditions = {
    rangeFilters: [
      {
        fieldName: 'unitsSold',
        minValue: 4
      }
    ]
  };

  productsWithSalesWidgetConfig.view.metricFields[0].metricType = METRICTYPE.VOLUME;
  productsWithSalesWidgetConfig.view.metricFields[0].dataType = DATATYPE.INTEGER;
  productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'stacklineSku'
  ).metricType = METRICTYPE.VOLUME;
  productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'stacklineSku'
  ).dataType = DATATYPE.INTEGER;

  const reviewsRatingTrendWidgetConfig = buildTrendWidgetConfig(
    app,
    'sales',
    entity,
    'weekId',
    ['reviewsRating'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: {
            legendTitle: 'RATING',
            enabled: false
          },
          ...summarySubTrendView
        },
        linkTo: { tab: 'sales', subtab: 'rating' },
        container: { ...summaryTrendStyle.subTrend }
      },
      CustomComponent: CommonSummaryTrend,
      data: {
        replaceZeroWithLastKnownValue:true,
        avoidZeros:true,
        additionalQueryConditions: {
          rangeFilters: [
            {
              fieldName: 'reviewsRating',
              minValue: 0.01,
              maxValue: 5.0
            }
          ]
        }
      }
    }
  );

  const contentScoreTrendWidgetConfig = buildTrendWidgetConfig(
    app,
    'sales',
    entity,
    'weekId',
    ['contentScore'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: {
            legendTitle: 'CONTENT SCORE',
            enabled: false
          },
          ...summarySubTrendView
        },
        linkTo: { tab: 'sales', subtab: 'contentScore' },
        container: { ...summaryTrendStyle.subTrend }
      },
      CustomComponent: CommonSummaryTrend
    }
  );

  const summaryTrendColumnsWidget = buildSummaryTrendColumnGroupsWidget({ app, entity, weekIdField }, [
    [
      ['traffic', 'totalClicks', 'Traffic', 'traffic', 'totalClicks'],
      ['traffic', 'organicClicks', 'TRAFFIC - ORGANIC', 'traffic', 'organicClicks'],
      ['traffic', 'adClicks', 'TRAFFIC - PAID', 'traffic', 'adClicks'],
      ['traffic', 'adSpend', 'AD SPEND', 'traffic', 'adClicks', 'adSpendByweekId'],
      keywordsWithTrafficWidgetConfig
    ],
    [
      ['sales', 'purchaseRate', 'Conversion', 'sales', 'purchaseRate'],
      ['sales', 'retailPrice', 'RETAIL PRICE', 'sales', 'retailPrice', ''],
      contentScoreTrendWidgetConfig,
      reviewsRatingTrendWidgetConfig,
      buildTrendWidgetConfig(app, 'promotions', entity, 'weekId', ['dataPointCount'], weekIdField, {
        view: {
          chartPropsOverride: {
            legend: { enabled: false, legendTitle: 'PROMOTIONS' },
            ...summarySubTrendView
          },
          linkTo: { tab: 'promotions', subtab: 'retailSales' },
          container: { ...summaryTrendStyle.subTrend }
        },
        CustomComponent: CommonSummaryTrend,
        data: {
          additionalQueryConditions: {
            rangeFilters: [
              {
                fieldName: 'retailSales',
                minValue: 0.01
              }
            ]
          }
        }
      })
    ],
    [
      ['sales', 'retailSales', 'Retail Sales', 'sales', 'retailSales'],
      ['sales', 'unitsSold', 'UNITS SOLD', 'sales', 'unitsSold'],
      buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['retailSales'], weekIdField, {
        view: {
          displayName: retailSalesMarketShareField.displayName,
          metricFields: [retailSalesMarketShareField],
          chartPropsOverride: {
            legend: {
              legendTitle: `SHARE (${retailer.currencySymbol})`,
              enabled: false,
              showMarketShareTooltip: false
            },
            ...summarySubTrendView
          },
          linkTo: { tab: 'sales', subtab: 'retailSales', scrollTo: 'marketShareBySales' },
          container: { ...summaryTrendStyle.subTrend }
        },
        data: {
          marketShare: { compute: true }
        },
        CustomComponent: CommonSummaryTrend
      }),
      buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['unitsSold'], weekIdField, {
        displayName: unitsSoldMarketShareField.displayName,
        metricFields: [unitsSoldMarketShareField],
        view: {
          displayName: unitsSoldMarketShareField.displayName,
          metricFields: [unitsSoldMarketShareField],
          chartPropsOverride: {
            legend: {
              legendTitle: 'SHARE (UNITS)',
              enabled: false,
              showMarketShareTooltip: false
            },
            ...summarySubTrendView
          },
          linkTo: { tab: 'sales', subtab: 'unitsSold', scrollTo: 'marketShareByUnits' },
          container: { ...summaryTrendStyle.subTrend }
        },
        data: {
          marketShare: { compute: true }
        },
        CustomComponent: CommonSummaryTrend
      }),
      productsWithSalesWidgetConfig
    ]
  ]);

  widgets.push(summaryTrendColumnsWidget);

  // TRENDING PRODUCTS
  if (entity.type !== 'product') {
    widgets.push(...buildTrendingProductsWidgets({ app, retailer, entity }));
  }
  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    widgets,
    containerStyle: {
      marginTop: 30
    },
    dataConfig
  };
}
