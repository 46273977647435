import { TermFilter } from 'sl-api-connector/types';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import { CancelTokenSource } from 'axios';
import _isArray from 'lodash/isArray';
import _mergeWith from 'lodash/mergeWith';
import { Dispatch } from 'react';
import _chunk from 'lodash/chunk';
import { SearchGridConstants } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConstants';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { getParentPlatform } from 'src/utils/browser';
import {
  getESBodyOverridesForParentPlatform,
  modifyESQuery
} from 'src/components/AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';
import { shouldShowCriteo } from 'src/utils/app';

export class ProductChunksFetcher {
  public async fetchProductDocuments(
    allStacklineSkuValues: string[],
    allTermFilters: TermFilter[],
    termFilterIndex: number,
    dispatch: Dispatch<any>,
    mainEntity: any,
    retailer: any,
    app: any,
    indexToUse: string,
    searchSideBarConditions: ISearchFilterConditions,
    cancelSource: CancelTokenSource,
    importantOverrides
  ) {
    // adding a chunk size of 1024 in order to limit the clause so that we
    // don't face the max clause issue
    // JIRA Ticket - https://stackline.atlassian.net/secure/RapidBoard.jspa?rapidView=18&projectKey=ADBUGS&modal=detail&selectedIssue=ADBUGS-184
    const customizerFunction = (srcVal, destVal) => {
      if (_isArray(srcVal)) {
        return srcVal.concat(destVal);
      }
      return undefined;
    };
    const adCampaignProducts = { documents: [] };
    const allChunks = await this.getProductDocumentsForAllChunks(
      allStacklineSkuValues,
      allTermFilters,
      termFilterIndex,
      dispatch,
      mainEntity,
      retailer,
      app,
      indexToUse,
      searchSideBarConditions,
      cancelSource,
      importantOverrides
    );

    // lodash deep merge to merge the documents
    allChunks.map((chunk) => _mergeWith(adCampaignProducts, chunk, customizerFunction));
    return adCampaignProducts.documents;
  }

  // eslint-disable-next-line class-methods-use-this
  private async getProductDocumentsForAllChunks(
    allStacklineSkuValues: string[],
    allTermFilters: TermFilter[],
    termFilterIndex: number,
    dispatch: Dispatch<any>,
    mainEntity: any,
    retailer: any,
    app: any,
    indexToUse: string,
    searchSideBarConditions: ISearchFilterConditions,
    cancelSource: CancelTokenSource,
    importantOverrides: any
  ) {
    const stacklineSkuChunks = _chunk(allStacklineSkuValues, SearchGridConstants.CHUNK_SIZE_1024);
    const baseOverrides = [
      {
        doAggregation: false,
        conditions: {
          termFilters: allTermFilters,
          rangeFilters: [...searchSideBarConditions.rangeFilters]
        },
        pageNumber: 1,
        pageSize: SearchGridConstants.PAGE_SIZE_10K,
        processDocuments: false,
        returnDocuments: true
      }
    ];

    let copyOfOVerrides = _cloneDeep(baseOverrides);

    if (shouldShowCriteo() && importantOverrides && _isArray(importantOverrides) && importantOverrides.length > 0) {
      const parentPlatform = getParentPlatform();
      const newOverride = [];
      copyOfOVerrides.forEach((_, indx) => {
        const mutatedQuery = modifyESQuery(
          copyOfOVerrides[indx],
          getESBodyOverridesForParentPlatform(parentPlatform, importantOverrides)
        );
        newOverride.push(mutatedQuery);
      });
      copyOfOVerrides = newOverride;
    }

    const allChunks = await Promise.all(
      stacklineSkuChunks.map((skuChunk, index) => {
        allTermFilters[termFilterIndex].values = skuChunk;
        return dispatch(
          fetchEntityMetrics(
            `adManager_searchResultsGrid_products_${index}`,
            {
              entity: mainEntity,
              retailer,
              app,
              indexName: indexToUse
            },
            copyOfOVerrides,
            _get(cancelSource, 'token'),
            true
          )
        );
      })
    );
    return allChunks;
  }
}
