import { useStacklineTheme } from '@stackline/ui';
import React from 'react';
import DownloadsPageLayout, {
  DownloadsTab
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/DownloadsPageLayout';
import { NewSettingsLayoutProps } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/NewSettingsLayout';
import ProfileContainerLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Profile/ProfileContainerLayout';
import { useAppSelector } from 'src/utils/Hooks';
import UserTeamManagement from 'src/routes/UserAccount/UserTeamManagement/UserTeamManagement';
import SettingsIntegrationsPageLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Integrations/SettingsIntegrationsPageLayout';
import BulkAdjustmentUploadsPageLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Adjustments/BulkAdjustmentUploadsPageLayout';
import { bulkAdjustmentsEnabled } from 'src/utils/app';
import useNotificationsBadge from 'src/utils/Hooks/useNotificationsBadge';

export enum BEACON_SETTINGS_TABS {
  PROFILE = 'profile',
  DOWNLOADS = 'downloads',
  TEAM = 'team',
  INTEGRATIONS = 'integrations'
}

export enum BEACON_SETTINGS_SUBTABS {
  PROFILE = 'profile',
  SECURITY = 'security'
}

export enum BEACON_SETTINGS_LABELS {
  PROFILE = 'Profile',
  SUMMARY = 'Summary',
  SECURITY = 'Security',
  DOWNLOADS = 'Downloads',
  TEAM = 'Team',
  INTEGRATIONS = 'Integrations',
  ADJUSTMENTS = 'Adjustments'
}

export const useSettingsRoutes = (props: NewSettingsLayoutProps) => {
  const { queryParams } = useAppSelector((state) => state.app);
  const { searchParams } = queryParams;

  const theme = useStacklineTheme();

  const { shouldShowNotificationBadge } = useNotificationsBadge();

  const {
    user,
    handleProfileChange,
    onSubmit,
    handlePhoneNumberChange,
    handleNewPasswordChange,
    handlePasswordConfirmationChange,
    profile
  } = props;

  const tabs = [
    {
      label: BEACON_SETTINGS_LABELS.PROFILE,
      route: `/account/profile${searchParams}`,
      index: 0,
      renderComponent: (
        <ProfileContainerLayout
          user={user}
          handleProfileChange={handleProfileChange}
          onSubmit={onSubmit}
          handlePhoneNumberChange={handlePhoneNumberChange}
          handleNewPasswordChange={handleNewPasswordChange}
          handlePasswordConfirmationChange={handlePasswordConfirmationChange}
          profile={profile}
        />
      )
    },
    {
      label: (
        <div>
          {BEACON_SETTINGS_LABELS.DOWNLOADS}
          {shouldShowNotificationBadge && (
            <span
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: theme.colors.primary,
                display: 'inline-block',
                marginLeft: '12px',
                borderRadius: '50%'
              }}
            ></span>
          )}
        </div>
      ),
      route: `/account/downloads${searchParams}&tab=${DownloadsTab.ExportsDownload}`,
      index: 1,
      renderComponent: <DownloadsPageLayout />
    },
    {
      label: BEACON_SETTINGS_LABELS.TEAM,
      oldBeaconPath: 'account',
      oldBeaconEntity: 'profile',
      route: `/account/team${searchParams}`,
      index: 2,
      renderComponent: <UserTeamManagement />
    },
    {
      label: BEACON_SETTINGS_LABELS.INTEGRATIONS,
      route: `/account/integrations${searchParams}`,
      index: 3,
      renderComponent: <SettingsIntegrationsPageLayout />
    }
  ];

  if (bulkAdjustmentsEnabled()) {
    tabs.push({
      label: BEACON_SETTINGS_LABELS.ADJUSTMENTS,
      route: `/account/adjustments${searchParams}`,
      index: 4,
      renderComponent: <BulkAdjustmentUploadsPageLayout />
    });
  }

  return tabs;
};
