import React from 'react';
import VisualizationTitle, { VisualizationTitleProps } from './VisualizationTitle';
import Box from '@mui/material/Box';

export interface VisualizationProps {
  /**
   * Props to forward to the VisualizationTitle component
   * for the title and tooltip content.
   */
  titleProps: VisualizationTitleProps;
  /**
   * Text content to show inside the bullet point. It should
   * be a React Node instead of just a string in case the text
   * needs to be specially formatted.
   */
  children: React.ReactNode;
}

/**
 * Basic wrapper for a Visualization that positions the title
 * component and renders the children. Each visualization will
 * have a unique layout for ordering of insights and charts
 * but they will all have a common title positioning.
 */
const Visualization = ({ titleProps, children }: VisualizationProps) => {
  return (
    <>
      <VisualizationTitle {...titleProps} />
      <Box marginTop={1}>{children}</Box>
    </>
  );
};

export default Visualization;
