import axios from 'axios';
import { AppName } from 'sl-api-connector/types';
import { ThunkDispatch } from 'redux-thunk';

import { store } from 'src/main';
import ReduxStore from 'src/types/store/reduxStore';
import Creators from './actions';
import { parseAdPlatforms } from './selectors';
import { setRetailerPlatformDetails } from 'src/store/modules/retailer/operations';
import { fetchDataWithCache } from 'src/queryClient';

export const { clearAdPlatforms, receiveAdPlatforms } = Creators;

export const fetchAdPlatforms =
  (retailerId: number | string) => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
    if ((store.getState() as ReduxStore).app.name !== AppName.Advertising) {
      return Promise.resolve([]);
    }

    const { data } = await fetchDataWithCache('adPlatforms', () => axios.get(`/apiAdManager/adPlatforms`));

    // add retailer platformType and timezone
    if (retailerId) {
      const currentRetailer = data.find(
        (platform: { extendedAttributes: { retailerId: any } }) =>
          platform.extendedAttributes.retailerId.toString() === retailerId.toString()
      );
      if (currentRetailer) {
        const currentTimeZone = currentRetailer.extendedAttributes.processInTimezone || 'America/Los_Angeles'; // default value
        dispatch(setRetailerPlatformDetails(currentRetailer.platformType, currentTimeZone));
      }
    }

    return dispatch(receiveAdPlatforms(parseAdPlatforms(data)));
  };
