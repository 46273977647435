// As of Oct 17, Omni does not have a route for Account page
import React, { Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { AppName } from 'sl-api-connector/types';

import { encodeHash } from 'src/utils/hashIds';
import { getAppStage } from 'src/utils/app';

const OmniEntity = React.lazy(() => import('../components/Layout/Omni/OmniEntity'));
const EntityPage = React.lazy(() => import('../components/EntityPage/EntityPage'));
const SearchContainer = React.lazy(() => import('../components/Search'));
const HomePageContainer = React.lazy(() => import('../components/HomePage/HomePageContainer'));
const UserAccount = React.lazy(() => import('./UserAccount'));
const SignInContainer = React.lazy(() => import('../components/SignIn'));
const SignUp = React.lazy(() => import('../components/SignUp'));
const UserTerms = React.lazy(() => import('../components/SignUp/UserTerms'));
const BillingInformation = React.lazy(() => import('../components/SignUp/BillingInformation'));
const ResetPassword = React.lazy(() => import('../components/ResetPassword'));
const AdManagerSearchPage = React.lazy(() => import('../components/AdManager/Search/SearchPage'));
const SegmentPowerTools = React.lazy(() => import('../components/SegmentPowerTools'));
const OmniSearchContainer = React.lazy(() => import('../components/Omni/Search/OmniSearch'));
const DiscoverContainer = React.lazy(() => import('../components/Discover/Discover'));
const OmniKeywordSearchContainer = React.lazy(() =>
  import('../components/Omni/OmniKeywordList/OmniKeywordSearchContainer')
);
const OmniStoreListsContainer = React.lazy(() =>
  import('../components/Omni/OmniStoreListsContainer/OmniStoreListsContainer')
);

const getNotFoundRedirect = (app, user, queryParams) => {
  const qs = `${queryParams ? `?${queryParams}` : ''}`;
  const id = user.config.vendor.BeaconClientId;
  const { isRestrictedDemoUser } = user.config && user.config;

  return {
    atlas: isRestrictedDemoUser
      ? `/home${qs}&tab=summary&subtab=atlas&entityType=category`
      : `/company/${encodeHash(0)}${qs}`,
    beacon: `/client/${user.config.vendor.BeaconClientId}${qs}`,
    omni: `/client/${id}${qs}&tab=scorecard&subtab=all`,
    advertising: `/overview${qs}`,
    discover: `/discover${qs}`
  }[app.name];
};

const AuthenticatedRouter = ({ app, location, user }) => (
  <Switch>
    <Route exact path="/company/:id" component={EntityPage} />
    <Route exact path="/client/:id" component={EntityPage} />
    <Route exact path="/brand/:id" component={EntityPage} />
    <Route exact path="/segment/:id" component={EntityPage} />
    <Route exact path="/businessunit/:id" component={EntityPage} />
    <Route exact path="/searchtermlist/:id" component={EntityPage} />
    <Route exact path="/product/:id" component={EntityPage} />
    <Route exact path="/category/:id" component={EntityPage} />
    <Route exact path="/subcategory/:id" component={EntityPage} />
    <Route exact path="/adCampaign/:id" component={EntityPage} />
    <Route exact path="/adPortfolio/:id" component={EntityPage} />
    <Route exact path="/adEntity/:id" component={EntityPage} />
    <Route exact path="/adTarget/:id" component={EntityPage} />
    <Route exact path="/adGroup/:id" component={EntityPage} />
    <Route exact path="/summary/:id" component={EntityPage} />
    <Route exact path="/overview" component={EntityPage} />
    <Route exact path="/discover" component={DiscoverContainer} />
    <Route
      exact
      path="/search"
      render={({ ...props }) => {
        if (app.name === AppName.Advertising) {
          return <AdManagerSearchPage {...props} />;
        }

        return <SearchContainer {...props} />;
      }}
    />
    <Route path="/account" component={UserAccount} />
    <Route path="/home" component={HomePageContainer} />
    {getAppStage() === 'dev' ? <Route path="/segment-power-tools" component={SegmentPowerTools} /> : null}

    <Route
      path="*"
      render={() => {
        const to = getNotFoundRedirect(app, user);
        // This is necessary to avoid some ridiculous infinite redirect bug that happens for a reason I can't explain
        if (to === location.pathname) {
          return null;
        }

        return <Redirect to={{ pathname: getNotFoundRedirect(app, user), state: { from: location } }} />;
      }}
    />
  </Switch>
);

AuthenticatedRouter.propTypes = {
  app: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
  retailer: PropTypes.object.isRequired,
  mainTimePeriod: PropTypes.object.isRequired
};
AuthenticatedRouter.defaultProps = { user: {} };

const OmniAuthenticatedRouter = ({ location, user }) => {
  return (
    <Switch>
      <Route exact path="/client/:id" component={OmniEntity} />
      <Route exact path="/brand/:id" component={OmniEntity} />
      <Route exact path="/category/:id" component={OmniEntity} />
      <Route exact path="/subcategory/:id" component={OmniEntity} />
      <Route exact path="/product/:id" component={OmniEntity} />
      <Route exact path="/retailer/:id" component={OmniEntity} />
      <Route exact path="/country/:id" component={OmniEntity} />
      <Route exact path="/region/:id" component={OmniEntity} />
      <Route exact path="/search" component={OmniSearchContainer} />
      <Route exact path="/searchtermlist" component={OmniKeywordSearchContainer} />
      <Route exact path="/storeListsSearch" component={OmniStoreListsContainer} />
      <Route path="/home" component={HomePageContainer} />
      <Route exact path="/segment/:id" component={OmniEntity} />
      <Route exact path="/state/:id" component={OmniEntity} />
      <Route exact path="/city/:id" component={OmniEntity} />
      <Route exact path="/cbsa/:id" component={OmniEntity} />
      <Route exact path="/county/:id" component={OmniEntity} />
      <Route exact path="/keywordSegment/:id" component={OmniEntity} />
      <Route exact path="/store/:id" component={OmniEntity} />
      <Route path="/account" component={UserAccount} />

      <Route
        path="*"
        render={() => {
          const id = user.config.vendor.BeaconClientId;
          return <Redirect to={{ pathname: `/client/${id}`, state: { from: location } }} />;
        }}
      />
    </Switch>
  );
};

OmniAuthenticatedRouter.propTypes = {
  app: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired,
  user: PropTypes.object,
  mainTimePeriod: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};
OmniAuthenticatedRouter.defaultProps = { user: {} };

const UnauthenticatedRouter = ({ location }) => (
  <div style={{ height: '100%' }}>
    <Switch>
      <Route exact path="/sign_in" component={SignInContainer} />
      <Route exact path="/sign_up_invite" component={SignUp} />
      <Route exact path="/user_terms" component={UserTerms} />
      <Route exact path="/password_reset" component={ResetPassword} />
      <Route exact path="/subscribe" component={BillingInformation} />
      <Redirect to={{ pathname: '/sign_in', state: { from: location } }} />
    </Switch>
  </div>
);

UnauthenticatedRouter.propTypes = { location: PropTypes.object.isRequired };

const RouteTable = ({ routeType, ...props }) => {
  const {
    app: { shouldUseOmniAuthenticatedRouter }
  } = props;
  const RouterComponent = useMemo(() => {
    if (routeType === 'authenticated') {
      return shouldUseOmniAuthenticatedRouter ? OmniAuthenticatedRouter : AuthenticatedRouter;
    }
    return UnauthenticatedRouter;
  }, [routeType, shouldUseOmniAuthenticatedRouter]);

  return (
    <Suspense fallback={null}>
      <RouterComponent {...props} />
    </Suspense>
  );
};

RouteTable.propTypes = { routeType: PropTypes.string.isRequired, app: PropTypes.object.isRequired };

export default RouteTable;
