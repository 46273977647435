import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import Creator from './actions';
import ReduxStore from 'src/types/store/reduxStore';

const { receiveAllOmniDynamicFilter, startFetchOmniDynamicFilter } = Creator;

export const fetchOmniDynamicFilter = (apiBody: any) => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
  dispatch(startFetchOmniDynamicFilter({ data: [] }));
  try {
    const response = (await axios.post(`omni/entityMetadata/availability`, apiBody)) as any;

    // const response = await axios.get('omni/user/availability', apiBody);
    if (response && response.status === 200) {
      const { data } = response;

      dispatch(receiveAllOmniDynamicFilter(data));
    }
  } catch (e) {
    console.warn(e);
  }
};
