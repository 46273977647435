import React from 'react';
import ProductListContainer from 'src/components/BeaconRedesignComponents/MetricList/ProductListContainer';
import { Field } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy';
import { useProductEntity } from 'src/utils/Hooks/reduxSelectors';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import { getWeekColumnDefinition } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import { removeComparisonFormatter } from 'src/components/BeaconRedesignComponents/MetricList/utils';
import { SALES_TABLE_HEADER_DISPLAY_NAMES } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Summary/Sales/constants';

const fields: Field[] = [
  {
    name: 'retailSales',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.RETAIL_SALES
  },
  {
    name: 'unitsSold',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.UNITS_SOLD
  },
  {
    name: 'wholesaleSales',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.WHOLESALE_SALES
  },
  {
    name: 'retailPrice',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.RETAIL_PRICE
  },
  {
    name: 'instockRate',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.INSTOCK_RATE
  },
  {
    name: 'unitsOnHand',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.UNITS_ON_HAND
  }
];

/**
 * Shows the units sold by product for the sales page
 */
const UnitsSoldProductGrid = () => {
  const { isProductEntity } = useProductEntity();

  const weekEndingFields = fields.map(removeComparisonFormatter);

  return isProductEntity ? (
    <MetricListContainer
      entityTableHeaderProps={{
        title: 'Units Sold by Week'
      }}
      indexName="sales"
      sortFieldName="unitsSold"
      fields={weekEndingFields}
      headColumnDefinition={getWeekColumnDefinition({
        getTableColumnProps(defaults) {
          return {
            ...defaults,
            style: {
              ...defaults.style,
              width: '220px'
            }
          };
        }
      })}
      postProcessData={(data) => {
        return data.sort((a, b) => b.weekId.localeCompare(a.weekId));
      }}
      useTableDataProps={{
        groupByFieldName: 'weekId',
        filterResponse: () => true,
        itemsPerPage: 100
      }}
      showGrid={false}
    />
  ) : (
    <ProductListContainer
      entityTableHeaderProps={{
        title: 'Units Sold by Product'
      }}
      indexName="sales"
      sortFieldName="unitsSold"
      fields={fields}
    />
  );
};

export default UnitsSoldProductGrid;
