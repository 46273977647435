import types, { AdPortfolioState } from './types';
import actionCreators from './actions';
import { ValueOf } from 'sl-api-connector';
import { AD_STATUS_DERIVED } from 'sl-ad-campaign-manager-data-model';

// ------------------------------------
// Reducer
// ------------------------------------

const adPortfolioStatesAvailable: AdPortfolioState[] = [
  {
    id: AD_STATUS_DERIVED.DELIVERING,
    portfolioState: AD_STATUS_DERIVED.DELIVERING,
    name: 'Delivering',
    displayName: 'Delivering',
    type: 'adPortfolioState'
  },
  {
    id: AD_STATUS_DERIVED.OUT_OF_BUDGET,
    portfolioState: AD_STATUS_DERIVED.OUT_OF_BUDGET,
    name: 'Out of budget',
    displayName: 'Out of budget',
    type: 'adPortfolioState'
  },
  {
    id: AD_STATUS_DERIVED.ENDED,
    portfolioState: AD_STATUS_DERIVED.ENDED,
    name: 'Ended',
    displayName: 'Ended',
    type: 'adPortfolioState'
  }
];
type Action = ValueOf<{ [K in keyof typeof actionCreators]: ReturnType<(typeof actionCreators)[K]> }>;

export function adPortfolioStates(state = adPortfolioStatesAvailable, action: Action) {
  switch (action.type) {
    case types.RECEIVE_AD_PORTFOLIO_STATES:
      return action.adPortfolioStates;
    case types.CLEAR_AD_PORTFOLIO_STATES:
      return [];
    default:
      return state;
  }
}
