import { Conditions } from 'sl-api-connector/types';
import { IAdPlatformSettings } from 'sl-ad-campaign-manager-data-model';
import moment from 'moment';

import { formatMoney } from 'src/utils/stringFormatting';
import { SelectedFeaturedProductsItem } from 'src/types/store/storeTypes';
import { updateTargetEntityListMetrics } from './calc';
import queryString from 'qs';
// import devIntialState from './devInitialState.json';

const getInitialBudgetByRetailer = () => {
  const params = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: 100
  });
  switch (params.rid) {
    case '16':
      return 150000;
    default:
      return 2500;
  }
};

const getInitialState = () => {
  const initialBudget = getInitialBudgetByRetailer();
  return {
    inited: false,
    platformId: undefined,
    platformSettings: undefined,
    campaignType: undefined,
    minROAS: 0,
    minBudget: 1,
    setup: {
      name: '',
      startDate: moment().toString(),
      campaignName: undefined,
      portfolioId: undefined,
      businessUnitId: undefined,
      strategyId: undefined,
      automationOptions: [] as IAdPlatformSettings[]
    },
    instacartParams: {
      optimized_bidding_enabled: false,
      optimized_bidding_parameters: {},
      optimized_bidding_goal: 'max_attributed_sales'
    },
    // This is selected on the "Setup" page of the flow and determines many things about the behavior of the rest of it
    // This key holds state for the "Featured Products" page
    featured: {
      filters: {
        isDefault: true,
        termFilters: {} as { [key: string]: { values?: any[]; condition: Conditions['condition'] } },
        rangeFilters: {} as object
      },
      landingType: 'Brand Store',
      storeUrl: '',
      // Holds the state of the checkboxes for the featured products grid, with the product IDs as keys.
      // We want to only retain a maximum of `MAX_FEATURED_PRODUCTS` product IDs here.  The value that they map to
      // corresponds to the order in which they were added.  Checking a previously unchecked product when the maximum
      // is already selected will cause the lowest value to be unchecked.
      selectedFeaturedProducts: [] as SelectedFeaturedProductsItem[],
      selectedFeaturedUPCs: [],
      retailPriceAverage: 13,
      brandCogsPerUnitAverage: 13 * 0.65 * 0.6,
      retailerGrossMarginPercentAverage: 0.2
    },
    target: {
      loading: false,
      filters: {
        isDefault: true,
        termFilters: {} as { [key: string]: { values?: any[]; condition: Conditions['condition'] } },
        rangeFilters: {} as object
      },
      budget: {
        total: initialBudget,
        daily: parseFloat((initialBudget / 30).toFixed(2)),
        displayName: `${formatMoney(initialBudget)} (${formatMoney(initialBudget / 30)} daily)`
      },
      targetingType: null,
      selectedTargetEntities: updateTargetEntityListMetrics({
        totalBudget: initialBudget,
        retailPriceAverage: 13,
        brandCogsPerUnitAverage: 13 * 0.65 * 0.6,
        retailerGrossMarginPercentAverage: 0.2,
        entityList: []
      }),
      autoCampaignProducts: [],
      autoBidMapping: new Map(),
      projections: {
        adSpend: 0,
        adClicks: 0,
        costPerClick: 0,
        adSales: 0,
        grossMargin: 0,
        incrementalMargin: 0,
        roas: 0,
        incrementalROAS: 0
      },
      targetEntitiesListFromAtlas: [] as any,
      uploadedTargetKeywords: null as null | string[]
    },
    marketStats: {
      queryConditions: {}
    },
    creative: {
      headline: '',
      brandLogoAssetId: ''
    }
  };
};

export { getInitialState };
