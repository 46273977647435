/*
Selector functions take a slice of the application state
and return some data based on that.
They never introduce any changes to the application state.
 */

import _cloneDeep from 'lodash/cloneDeep';
import { buildCustomDateRangeDisplayName } from 'src/utils/dateformatting';

export default function currentComparisonPeriod(state, action) {
  // logic is okay here, need to check upstream
  if (action.periodId === -1) {
    return null;
  }
  const updatedComparisonPeriod = _cloneDeep(state.availableComparisonTimePeriods);
  const newComparisonPeriod = updatedComparisonPeriod[action.periodId];
  newComparisonPeriod.startWeek = action.startWeek;
  newComparisonPeriod.endWeek = action.endWeek;
  newComparisonPeriod.startWeekStartDate = action.startWeekStartDate;
  newComparisonPeriod.endWeekEndDate = action.endWeekEndDate;
  newComparisonPeriod.startDayId = action.startDayId;
  newComparisonPeriod.endDayId = action.endDayId;
  newComparisonPeriod.displayName = buildCustomDateRangeDisplayName(action.startDayId, action.endDayId);
  newComparisonPeriod.timePeriodSuffix = {
    dayId: `_${newComparisonPeriod.startDayId}_${newComparisonPeriod.endDayId}`,
    weekId: `_${newComparisonPeriod.startWeek}_${newComparisonPeriod.endWeek}`
  };
  return newComparisonPeriod;
}
