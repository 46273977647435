import React, { ButtonHTMLAttributes, ForwardedRef } from 'react';
import styled from '@emotion/styled';
import { StacklineTheme, useStacklineTheme } from '@stackline/ui';

// Define the props for StyledButton
type StyledButtonInnerProps = {
  stacklineTheme: StacklineTheme;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const StyledButtonInner = styled('button')<StyledButtonInnerProps>(({ stacklineTheme }) => ({
  height: stacklineTheme.spacing.lg,
  padding: '7px 20px 6px 21px',
  borderRadius: stacklineTheme.spacing.xs,
  backgroundColor: stacklineTheme.colors.primary,
  color: 'white',
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: 'Roboto',
  border: `1px solid ${stacklineTheme.colors.primary}`
}));

// eslint-disable-next-line react/display-name
const StyledButton = React.forwardRef(
  (props: ButtonHTMLAttributes<HTMLButtonElement>, ref: ForwardedRef<HTMLButtonElement>): JSX.Element => {
    const theme = useStacklineTheme();
    return <StyledButtonInner ref={ref} stacklineTheme={theme} {...props} />;
  }
);

export default StyledButton;
