/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Waypoint from 'react-waypoint';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';

import Widget from 'src/components/EntityPage/Widget';
import { WidgetProps } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import AdManagerTile from 'src/components/SummaryTile/AdManagerTile';
import SummaryTileSearchbar from 'src/components/SummaryTile/SummaryTileSearchbar';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { store } from 'src/main';
import { buildAggregations, buildTimePeriodRangeFilters } from 'src/components/AdManager/Search';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { NoResultWithCustomButton } from 'src/components/Grids/Data/NoResultTemplate';

import './SummaryTile.scss';
import { useAppSelector } from 'src/utils/Hooks';

const PAGESIZE = 4;
const fileNameMapping = (field: 'campaignId' | 'portfolioId' | 'entityId') =>
  ({
    campaignId: 'adCampaigns',
    portfolioId: 'adPortfolios',
    entityId: 'adEntities'
  }[field]);

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['adEntities', 'adPlatformSettings', 'adPortfolios', 'adPlatforms']),
  adCampaigns: state.adCampaigns
});

type AdManagerTileListProps = WidgetProps & ReturnType<typeof mapStateToProps>;

const AdManagerTileList: React.FC<AdManagerTileListProps> = (props) => {
  const [currentLayout, setCurrentLayout] = useState('tile');
  const [dataSet, updateDataSet] = useState([]);
  const [spendById, setSpendById] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // Redux
  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);
  const app = useAppSelector((state) => state.app);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const retailer = useAppSelector((state) => state.retailer);

  const {
    widget: {
      data: { selectedEntityName }
    }
  } = props;
  const rawData = props[fileNameMapping(selectedEntityName)];
  useEffect(() => {
    if (!_isEmpty(rawData)) {
      updateDataSet(rawData);
    }
  }, [rawData]);

  const loadNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const fetchCampaignDataSet = async () => {
    const dataKey = `adManager_tile_list-${mainEntity!.id}`;
    const groupByFieldName = `${selectedEntityName}`;
    const indexName = 'adCampaignAdGroupProductTargetDailyMetrics';
    const metricFields = ['spend'].map((fieldName) => INDEX_FIELDS.getField(app.name, indexName, fieldName));
    const [{ aggregations: aggregationFields }] = buildAggregations(metricFields);
    const { mainTimePeriodRangeFilters } = buildTimePeriodRangeFilters({
      app,
      indexName,
      mainTimePeriod
    });

    return store.dispatch(
      fetchEntityMetrics(
        dataKey,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName
        },
        [
          {
            doAggregation: true,
            aggregations: [
              {
                aggregationFields,
                conditions: {
                  termFilters: [{ fieldName: 'retailerId', values: [+retailer.id] }],
                  rangeFilters: [...mainTimePeriodRangeFilters]
                },
                groupByFieldName
              }
            ],
            conditions: {
              termFilters: [],
              rangeFilters: [...mainTimePeriodRangeFilters]
            },
            pageSize: 10000,
            processDocuments: true
          }
        ],
        undefined,
        true
      )
    );
  };

  useEffect(() => {
    setCurrentPage(1);
    setIsLoading(true);
    fetchCampaignDataSet().then((res) => {
      const dataKey = `spend_by_${selectedEntityName}`;
      if (!res[dataKey]) {
        console.warn('No data returned from scatter plot data API request');
      }

      const newSpendById = _keyBy(res[dataKey].data, ({ entity: { id } }: { entity: { id: string } }) => id);
      setSpendById(newSpendById);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainTimePeriod.id, selectedEntityName]);

  const handleChangeLayout = (showTableView: boolean) =>
    setCurrentLayout(showTableView ? ('table' as const) : ('tile' as const));

  const onKeywordChange = (keyword: string) => {
    setSearchKeyword(keyword);
    setCurrentPage(1);
  };

  const renderTiles = () => {
    const filteredData = dataSet.filter(
      ({ displayName, id }: { displayName: string; id: string }) =>
        displayName &&
        (displayName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          id.toLowerCase().includes(searchKeyword.toLowerCase()))
    );
    let finalData = filteredData;
    if (!_isEmpty(spendById)) {
      finalData = _orderBy(filteredData, (elem) => (spendById[elem.id] ? -spendById[elem.id].value : Infinity));
    }

    const dataToDisplay = finalData.slice(0, currentPage * PAGESIZE);

    const tileProps = _pick(props, ['widget', 'conditions', 'adPlatforms', 'queryParams', 'comparisonConfig']);

    const tileProps2 = {
      mainEntity,
      app,
      mainTimePeriod,
      retailer
    };

    if (_isEmpty(dataToDisplay)) {
      return (
        <div>
          <NoResultWithCustomButton handleChange={onKeywordChange} />
        </div>
      );
    }

    return (
      <div>
        {dataToDisplay.map((item, idx) => {
          return <AdManagerTile key={idx} currentEntity={item} {...tileProps} {...tileProps2} />;
        })}
        <Waypoint onEnter={loadNextPage} />
        <br />
        <br />
      </div>
    );
  };
  const renderTable = () => {
    return (
      <div style={{ marginTop: 25 }}>
        <Widget {...props} widget={props.widget.data.tableGridConfig} />
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="summarytile_list">
        <GridLoading />
      </div>
    );
  }

  return (
    <div className="summarytile_list">
      <SummaryTileSearchbar
        keyword={searchKeyword}
        placeholderText="Search"
        onKeywordChange={onKeywordChange}
        handleChangeLayout={handleChangeLayout}
        currentLayout={currentLayout}
      />
      {currentLayout === 'tile' ? renderTiles() : renderTable()}
    </div>
  );
};

const WrappedAdManagerTileList = connect(mapStateToProps)(AdManagerTileList);
export default WrappedAdManagerTileList;
