import _cloneDeep from 'lodash/cloneDeep';
import _constant from 'lodash/constant';
import { connect } from 'react-redux';
import { AD_TARGETING_TYPE } from 'sl-ad-campaign-manager-data-model';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import {
  Platform,
  SetupType,
  SetupDetail,
  SetupStrategy,
  FeaturedLanding,
  TargetType,
  TargetDetail,
  TargetMatch,
  Projections,
  Creative,
  CreativeHeadline,
  Confirmation,
  Submit,
  FeaturedGrid,
  VideoUpload,
  AdditionalSettings,
  AudienceSettings,
  WalmartVideo
} from 'src/components/AdCampaignBuilder/Steps';
import AdManagerTabs from 'src/components/AdCampaignBuilder/Widgets/AdCampaignBuilderTabs';
import SlidedAdvancedSearchSideBar from 'src/components/Search/AdvancedSearch/AdvancedSearchSideBar/SlidedAdvancedSearchSideBar';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { buildWidgetGroup } from 'src/components/Layout/LayoutUtil';
import AdManagerStep from 'src/components/AdCampaignBuilder/Widgets/AdCampaignBuilderStep';
import {
  segmentFilterDefinitions,
  searchTermlistFilterDefinitions
} from 'src/components/Search/AdvancedSearch/AdvancedSearchSideBar/ConnectedAdvancedSearchSidebar';
import {
  buildSubcategoryListItemFilterDefinition,
  buildCategoryListItemFilterDefinition
} from 'src/components/Search/AdvancedSearch/AdvancedSearchSideBar/Filters';
import { buildMarketStatsWidgets } from 'src/components/AdCampaignBuilder/Steps/MarketStats';
import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import ReduxStore from 'src/types/store/reduxStore';
import { Widget } from 'src/types/application/widgetTypes';
import TargetBulkUpload from './TargetBulkUpload';
import BulkRetailerSKUUploadButton from 'src/components/AdCampaignBuilder/Widgets/BulkRetailerSKUUploadButton';
import { withProps } from 'src/utils/hoc';

const AdvancedSearchSideBar = SlidedAdvancedSearchSideBar as any;

const getFilterDefinitions = ({
  subtab,
  targetingTypeId,
  categories,
  subCategories
}: {
  subtab: string;
  targetingTypeId: ReduxStore['adCampaignBuilder']['target']['targetingTypeId'];
  categories: ReduxStore['categories'];
  subCategories: ReduxStore['subCategories'];
}) => {
  const categoryListItemFilterDefinition = buildCategoryListItemFilterDefinition(categories);
  const subCategoryListItemFilterDefinition = buildSubcategoryListItemFilterDefinition(subCategories);
  if (subtab === 'targetDetail' && targetingTypeId === 'keywordTargeting') {
    return [
      ...searchTermlistFilterDefinitions,
      ['brandsAdvancedSearch', 'brandId', { autocompleteAppNameOverride: 'atlas' }],
      categoryListItemFilterDefinition,
      subCategoryListItemFilterDefinition,
      ['targetGridCheckbox']
    ];
  }

  return subtab === 'targetDetail'
    ? [
        ...segmentFilterDefinitions,
        ['brandsAdvancedSearch', 'brandId', { autocompleteAppNameOverride: 'atlas' }],
        categoryListItemFilterDefinition,
        subCategoryListItemFilterDefinition,
        ['targetGridCheckbox']
      ]
    : [
        ...segmentFilterDefinitions,
        // Only allow client's brands as filters since we're only showing client's products
        ['brandsAdvancedSearch', 'brandId', { autocompleteAppNameOverride: 'beacon' }],
        categoryListItemFilterDefinition,
        subCategoryListItemFilterDefinition
      ];
};

export const buildAdvancedSearchSidebarWidget = (subtab: string): Widget | null => {
  if (!['featured', 'featuredLanding', 'targetDetail'].includes(subtab)) {
    return null;
  }

  const mapStateToProps = ({
    adCampaignBuilder: {
      featured: { filters: featuredFilters, storeUrl },
      target: { targetingTypeId, filters: targetFilters }
    },
    categories,
    subCategories
  }: ReduxStore) => {
    let ExtraContentComponent = withProps({ style: { marginBottom: 50 } } as { style?: React.CSSProperties })(
      BulkRetailerSKUUploadButton
    );
    if (subtab === 'targetDetail') {
      ExtraContentComponent = TargetBulkUpload;
    }

    if (storeUrl) {
      ExtraContentComponent = null;
    }

    return {
      formData: _cloneDeep(subtab === 'targetDetail' ? targetFilters : featuredFilters),
      getFilterComponentDefinitions: _constant(
        getFilterDefinitions({ subtab, targetingTypeId, categories, subCategories })
      ),
      hideActionButtons: true,
      ExtraContentComponent
    };
  };

  const mapDispatchToProps = {
    onChange:
      subtab === 'targetDetail'
        ? adCampaignBuilderActions.setTargetFilters
        : adCampaignBuilderActions.setFeaturedFilters,
    onSubmit:
      subtab === 'targetDetail'
        ? adCampaignBuilderActions.setTargetFilters
        : adCampaignBuilderActions.setFeaturedFilters
  };

  return {
    CustomComponent: connect(mapStateToProps, mapDispatchToProps)(AdvancedSearchSideBar),
    name: 'advancedSearchSidebar',
    view: {
      name: 'advancedSearchSidebar',
      container: { style: {} }
    },
    data: {}
  };
};

/**
 * Creates a widget group that renders an array of child widgets inside of an `AdManagerStep`.  It makes use of the
 * `WrapperComponent` API to specify a custom wrapper component for the widget.
 */
const buildAdManagerStepWidget = (childWidgets: Widget[], header: any, subheader: any, currentSubtab: string) =>
  buildWidgetGroup(childWidgets, {
    view: {
      WrapperComponent: AdManagerStep,
      childWidgets,
      header,
      subheader,
      currentSubtab
    }
  });

export const buildTargetDetailWidget = (appName: string): Widget => {
  const buildAggregationFields = (groupByFieldName: string) =>
    ['totalClicks', 'organicClicks', 'adClicks', 'adCostPerClick', 'adClickThroughRate', 'adConversionRate'].map(
      (fieldName) => INDEX_FIELDS.getField(appName, 'traffic', fieldName, 'client', groupByFieldName)
    );

  return {
    CustomComponent: TargetDetail,
    name: 'targetDetailsGrid',
    view: { name: 'targetDetailsGrid' },
    data: {},
    configByTargetType: {
      autoTargeting: {
        name: 'targetDetailsGridAutoTargeting',
        view: {
          headerText: 'Target Auto Economics',
          subHeaderText: 'Please select maximum cost per click for the selected items.'
        },
        data: {
          weekIdField: INDEX_FIELDS.getField(appName, 'traffic', 'weekId'),
          groupByFields: [INDEX_FIELDS.getField(appName, 'traffic', 'searchTerm')],
          configByGroupByFieldName: {
            searchTerm: {
              indexName: 'traffic',
              entity: ENTITIES.atlas.searchTerm,
              entityQueryConditions: {
                rangeFilters: [{ fieldName: 'organicClicks', minValue: 1 }],
                termFilters: [
                  {
                    condition: 'must_not',
                    fieldName: 'excludedSearchTerm',
                    values: ['[other traffic]', '[Other Traffic]']
                  }
                ]
              },
              aggregationFields: buildAggregationFields('searchTerm')
            }
          }
        }
      },
      keywordTargeting: {
        name: 'targetDetailsGridKeywordTargeting',
        view: {
          headerText: 'Target Keyword Economics',
          subHeaderText:
            'The table below displays four-week projected target economics for selected criteria. Select up to 1,000 targets.'
        },
        data: {
          weekIdField: INDEX_FIELDS.getField(appName, 'traffic', 'weekId'),
          groupByFields: [INDEX_FIELDS.getField(appName, 'traffic', 'searchTerm')],
          configByGroupByFieldName: {
            searchTerm: {
              indexName: 'traffic',
              entity: ENTITIES.atlas.searchTerm,
              entityQueryConditions: {
                rangeFilters: [{ fieldName: 'organicClicks', minValue: 1 }],
                termFilters: [
                  {
                    condition: 'must_not',
                    fieldName: 'excludedSearchTerm',
                    values: ['[other traffic]', '[Other Traffic]']
                  }
                ]
              },
              aggregationFields: buildAggregationFields('searchTerm')
            }
          }
        }
      },
      [AD_TARGETING_TYPE.PRODUCT_TARGETING]: {
        name: 'targetDetailsGridProductTargeting',
        view: {
          headerText: 'Target Product Economics',
          subHeaderText:
            'The table below displays four-week projected target economics for selected criteria. Select up to 1,000 targets.'
        },
        data: {
          weekIdField: INDEX_FIELDS.getField(appName, 'traffic', 'weekId'),
          groupByFields: [INDEX_FIELDS.getField(appName, 'traffic', 'stacklineSku')],
          configByGroupByFieldName: {
            stacklineSku: {
              indexName: 'traffic',
              entity: ENTITIES.atlas.product,
              entityQueryConditions: {
                rangeFilters: [{ fieldName: 'organicClicks', minValue: 1 }],
                termFilters: [
                  {
                    condition: 'must_not',
                    fieldName: 'excludedSearchTerm',
                    values: ['[other traffic]', '[Other Traffic]']
                  }
                ]
              },
              aggregationFields: buildAggregationFields('stacklineSku')
            }
          }
        }
      }
    }
  };
};

const getWidgetsForSubtab = ({ subtab, app }: { subtab: string; app: ReduxStore['app'] }) => {
  const widgetsBySubtab: { [subtab: string]: Widget[] } = {
    platformSelect: [
      {
        CustomComponent: Platform,
        name: 'platformSelect',
        view: { name: 'platformSelect' },
        data: {}
      }
    ],
    setupType: [
      {
        CustomComponent: SetupType,
        name: 'setupType',
        view: { name: 'setupType' },
        data: {}
      }
    ],
    setupStrategy: [
      {
        CustomComponent: SetupStrategy,
        name: 'setupStrategy',
        view: { name: 'setupStrategy' },
        data: {}
      }
    ],
    videoUpload: [
      {
        CustomComponent: VideoUpload,
        name: 'videoUpload',
        view: { name: 'videoUpload' },
        data: {}
      }
    ],
    walmartVideo: [
      {
        CustomComponent: WalmartVideo,
        name: 'walmartVideo',
        view: { name: 'walmartVideo' },
        data: {}
      }
    ],
    setupDetail: [
      {
        CustomComponent: SetupDetail,
        name: 'setupDetail',
        view: { name: 'setupDetail' },
        data: {}
      }
    ],
    featured: [
      {
        CustomComponent: FeaturedGrid,
        name: 'featured',
        view: { name: 'featured' },
        data: {}
      }
    ],
    featuredLanding: [
      {
        CustomComponent: FeaturedLanding,
        name: 'featuredLanding',
        view: { name: 'featuredLanding' },
        data: {}
      }
    ],
    targetType: [
      {
        CustomComponent: TargetType,
        name: 'targetType',
        view: { name: 'targetType' },
        data: {}
      }
    ],
    audienceSettings: [
      {
        CustomComponent: AudienceSettings,
        name: 'audienceSettings',
        view: { name: 'audienceSettings' },
        data: {}
      }
    ],
    targetDetail: [buildTargetDetailWidget(app.name)],
    targetMatch: [
      {
        CustomComponent: TargetMatch,
        name: 'targetMatch',
        view: { name: 'targetMatch' },
        data: {}
      }
    ],
    projections: [
      {
        CustomComponent: Projections,
        name: 'projections',
        view: { name: 'projections' },
        data: {}
      }
    ],
    market: [
      buildAdManagerStepWidget(
        buildMarketStatsWidgets({ app, entity: ENTITIES[app.name].product }, '4w'),
        'Market Stats',
        'The metrics below highlight the competition for your target audience over the past four weeks.',
        'market'
      )
    ],
    creative: [
      {
        CustomComponent: Creative,
        name: 'creative',
        view: { name: 'creative' },
        data: {}
      }
    ],
    creativeHeadline: [
      {
        CustomComponent: CreativeHeadline,
        name: 'creativeHeadline',
        view: { name: 'creativeHeadline' },
        data: {}
      }
    ],
    additionalSettings: [
      {
        CustomComponent: AdditionalSettings,
        name: 'additionalSettings',
        view: { name: 'additionalSettings' },
        data: {}
      }
    ],
    confirmation: [
      {
        CustomComponent: Confirmation,
        name: 'confirmation',
        view: { name: 'confirmation' },
        data: {}
      }
    ],
    submit: [
      {
        CustomComponent: Submit,
        name: 'submit',
        view: { name: 'submit' },
        data: {}
      }
    ]
  };

  return widgetsBySubtab[subtab];
};

/**
 * Depending on the current subtab, this function will returns a new set of widgets that will render an advanced
 * search sidebar on the left of the main content.  It will bundle the existing widgets into a widget group.
 */
const maybeAddAdvancedSearchSidebar = (subtab: string, widgets: Widget[]) => {
  const emptySidebarPlaceholder = buildWidgetGroup([], {
    data: {},
    view: { container: { style: { minWidth: 0 } } }
  });

  const advancedSearchSideBarWidget = buildAdvancedSearchSidebarWidget(subtab);
  const restOfWidgetsGroup = buildWidgetGroup(
    [
      advancedSearchSideBarWidget || emptySidebarPlaceholder,
      buildWidgetGroup(widgets, { data: {}, view: { container: { style: { minWidth: 1110, maxWidth: 1110 } } } }),
      emptySidebarPlaceholder
    ],
    {
      view: {
        container: {
          className: 'ad_builder-container',
          style: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }
        }
      }
    }
  );
  return [restOfWidgetsGroup];
};

export const getLayoutForEntity = ({
  subtab = 'platformSelect',
  app
}: { subtab: string } & Pick<ReduxStore, 'app'>) => {
  let contentWidgets = getWidgetsForSubtab({ subtab, app });
  if (!contentWidgets) {
    contentWidgets = getWidgetsForSubtab({ subtab: 'platformSelect', app });
  }

  const adCampaignBuilderTabsWidget = {
    CustomComponent: AdManagerTabs,
    view: {
      container: {
        style: {
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }
      }
    }
  };
  const widgets = [adCampaignBuilderTabsWidget, ...maybeAddAdvancedSearchSidebar(subtab, contentWidgets)];

  return {
    containerStyle: { flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' },
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    dataConfig: {},
    widgets
  };
};
