import types from './types';

export const adBusinessUnits = (state = [], action) => {
  switch (action.type) {
    case types.RECEIVE_ADBUSINESSUNITS:
      return action.adBusinessUnits;
    case types.CLEAR_ADBUSINESSUNITS:
      return [];
    default:
      return state;
  }
};
