import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';

import colors from 'src/utils/colors';
import { EMAIL_VALIDATOR_REGEX } from 'src/utils/validators';

class Email extends React.Component {
  static defaultProps = {
    className: '',
    floatingLabelFixed: false,
    hintText: 'Username',
    disabled: false,
    value: ''
  };

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    isFormSubmitted: PropTypes.bool.isRequired,
    className: PropTypes.string,
    value: PropTypes.string,
    floatingLabelFixed: PropTypes.bool,
    hintText: PropTypes.string,
    disabled: PropTypes.bool
  };

  state = {
    isEmailValid: false,
    value: ''
  };

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;

    if (value === this.props.value) {
      return;
    }

    this.setState({
      value
    });
  }

  handleEmailChange = (event) => {
    const { onChange } = this.props;
    const { value } = event.target;
    const isEmailValid = EMAIL_VALIDATOR_REGEX.test(value);
    this.setState({
      value,
      isEmailValid
    });
    onChange({ email: value, isEmailValid });
  };

  showErrorText = () => {
    const { isFormSubmitted } = this.props;
    const { isEmailValid, value } = this.state;

    if (isFormSubmitted) {
      if (value === '') {
        return 'This field is required';
      } else if (!isEmailValid) {
        return 'Please enter a valid email';
      }
    }
    return null;
  };

  render() {
    const { className, floatingLabelFixed, hintText, disabled } = this.props;

    return (
      <TextField
        variant="standard"
        floatingLabelFixed={floatingLabelFixed}
        disabled={disabled}
        className={`${className} ${this.showErrorText() ? 'sl-form-input--error' : ''}`}
        type="text"
        placeholder={hintText}
        floatingLabelText="Email"
        errorText={this.showErrorText()}
        floatingLabelStyle={{ top: '24px', fontSize: '16px', fontWeight: 400 }}
        floatingLabelFocusStyle={{ color: colors.stacklineBlue }}
        inputStyle={{ marginTop: '8px' }}
        errorStyle={{ bottom: '9px', fontWeight: 400 }}
        underlineFocusStyle={{ borderBottom: `1px solid ${colors.stacklineBlue}` }}
        style={{ width: '100%', height: '58px' }}>
        <MaskedInput
          mask={emailMask}
          type="text"
          name="email"
          id="email"
          value={this.state.value}
          onChange={this.handleEmailChange}
        />
      </TextField>
    );
  }
}

export default Email;
