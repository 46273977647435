/* eslint-disable class-methods-use-this */
import localForage from 'localforage';

/**
 * Adapter for caching React Query calls
 * with IndexedDB. We use IndexedDB because
 * localstorage only has a limit of 5MB
 */
class LocalForageAdapter {
  public async getItem(key: string): Promise<string> {
    return localForage.getItem(key);
  }

  public async setItem(key: string, value: any) {
    await localForage.setItem(key, value);
  }

  public async removeItem(key: string) {
    await localForage.removeItem(key);
  }
}

const localForageAdapter = new LocalForageAdapter();

export default localForageAdapter;
