import * as types from './types';

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  userLoggedIn: false,
  message: null
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.SIGN_IN_SUCCESS:
      return Object.assign({}, state, {
        userLoggedIn: true
      });
    case types.SIGN_IN_FAILURE:
      return Object.assign({}, state, {
        userLoggedIn: false,
        message: action.errorMessage || action.statusText
      });
    case types.SIGN_OUT_SUCCESS:
      return Object.assign({}, state, {
        userLoggedIn: false
      });
    case types.SIGN_OUT_FAILURE:
      return Object.assign({}, state, {
        userLoggedIn: true,
        message: 'There was an issue signing out'
        // message: action.errorMessage
      });
    case types.CLEAR_MESSAGE:
      return Object.assign({}, state, {
        message: null
      });
    case types.SET_ERROR_MESSAGE:
      return Object.assign({}, state, {
        message: action.errorMessage
      });
    default:
      return state;
  }
}
