interface SpecValue {
  min?: number;
  max?: number;
  options?: any[];
}
interface FileSpecItem {
  name: string;
  displayValue: string;
  value?: SpecValue;
}
type FileSpecSection = [string, FileSpecItem[]];
export type FileSpec = FileSpecSection[];

export const VIDEO_SPEC: FileSpec = [
  [
    'Video Specification',
    [
      {
        name: 'Video duration',
        displayValue: 'Between 6 and 30 seconds',
        value: { min: 6, max: 30 }
      },
      {
        name: 'Video dimensions',
        displayValue: '1280 x 720, 1920 x 1080 or 3840 x 2160px',
        value: {
          options: [
            [1280, 720],
            [1920, 1080],
            [3840, 2160]
          ]
        }
      },
      {
        name: 'Video format',
        displayValue: '.mp4 or .mov',
        value: { options: ['mp4', 'mov'] }
      },
      {
        name: 'File size',
        displayValue: '500 MB or less',
        value: { max: 500 * 1024 * 1024 }
      }
    ]
  ],
  [
    'Audio Specification',
    [
      {
        name: 'Audio format',
        displayValue: 'Stereo or mono'
      },
      {
        name: 'File codec:',
        displayValue: 'PCM, AAC or MP3'
      }
    ]
  ]
];
export const CAPTION_SPEC: FileSpec = [
  [
    'Caption Specification',
    [
      {
        name: 'File format:',
        displayValue: '.srt or .vtt'
      },
      {
        name: 'Note:',
        displayValue: 'If captions are not uploaded, Walmart will autogenerate.'
      }
    ]
  ]
];
export const ACCEPT_CAPTION_TYPES = ['srt', 'vtt'];
