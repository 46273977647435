import axios from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Dispute } from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeDetailsTable';
import {
  buildRequestForInitialDisputeDetails,
  createSubmitDisputesRequestBody,
  InitialDisputeDetailsParameters,
  InvoiceRequestParameters,
  SubmissionRequestParameters
} from 'src/components/ShortageDisputes/requestBuilders';
import _get from 'lodash/get';
import {
  fetchDisputeData,
  fetchSearchResults,
  generateFilterModifiers,
  initialFilters,
  parseUrlIntoFilters
} from 'src/components/ShortageDisputes/utils';
import { useAppSelector } from 'src/utils/Hooks';
import {
  DISPUTE_DATA_QUERY_KEY,
  DISPUTE_SUBMISSION_EVENT
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/constants';

export const useSubmitDisputes = ({ eventBus }) => {
  const { user, retailer } = useAppSelector((state) => state);
  const { id } = retailer;
  const beaconId = _get(user, 'config.vendor.BeaconClientId', null);
  const cancelSource = useRef(axios.CancelToken.source());

  const sendDataToApi = async (selectedDisputes: Dispute[]) => {
    const submissionParameters: SubmissionRequestParameters = {
      beaconClientId: beaconId,
      retailerId: id,
      disputes: selectedDisputes
    };

    // Send event to DisputeStatusUpdater to begin polling
    eventBus.emit(DISPUTE_SUBMISSION_EVENT);

    const submissionRequestBody = createSubmitDisputesRequestBody(submissionParameters);
    const response = await axios.post(`api/beacon/ShortageDisputeSubmission`, submissionRequestBody, {
      cancelToken: cancelSource.current.token
    });

    return response;
  };

  useEffect(() => {
    const cancelNetworkCalls = cancelSource.current.cancel;
    return () => cancelNetworkCalls();
  }, []);

  const { mutate, isLoading, isError, data, reset } = useMutation(sendDataToApi);

  return {
    submitDisputes: mutate,
    isSubmitting: isLoading,
    submissionError: isError,
    submissionResponse: data,
    reset
  };
};

interface UseFetchDisputeDataParameters {
  onError?: () => void;
  location: { search: string };
  pageNumber: number;
  maxPageSize: string | number;
}

/**
 * Used for fetching and updating dispute data for the table, with filters or search terms applied if present.
 * @param location to access URL changes and fetch accordingly
 * @returns A query object containing the data, loading state, error state, and many other useful keys
 */
export const useFetchDisputeData = ({ onError, location, pageNumber, maxPageSize }: UseFetchDisputeDataParameters) => {
  const { retailer, categories, mainTimePeriod } = useAppSelector((state) => state);
  const { id } = retailer;
  const { startWeek, endWeek } = mainTimePeriod;
  const [searchResults, setSearchResults] = useState([]);

  const searchParameters = new URLSearchParams(location.search);
  const cancelSource = useRef(axios.CancelToken.source());
  const requestConfig = {
    cancelToken: cancelSource.current.token
  };

  // Whenever search or filter parameters are updated, we parse the filter changes from the URL back into a filter object.
  const updatedFilters = useMemo(() => {
    return parseUrlIntoFilters(searchParameters, initialFilters);
  }, [searchParameters]);

  // Define the object to fill based on the URL parameters
  const searchSkeleton = { search: '' };
  // Parse URL into specified object format whenever the search params change
  const updatedSearch = useMemo(() => {
    return parseUrlIntoFilters(searchParameters, searchSkeleton);
  }, [searchParameters]);
  // Pull the search term
  const searchTerm = updatedSearch.search.toUpperCase();
  // If we have a valid search term, fetch the resulting invoice number
  useEffect(() => {
    const handleSearchRequest = async () => {
      try {
        const results = await fetchSearchResults({ searchTerm, retailerId: id, startWeek, endWeek });
        setSearchResults(results);
      } catch (error) {
        setSearchResults(null);
      }
    };

    if (searchTerm && searchTerm.length > 0) {
      handleSearchRequest();
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, onError]);

  const filtersForRequest = generateFilterModifiers(updatedFilters);
  const invoiceDataRequestParameters: InvoiceRequestParameters = {
    pageNumber,
    pageSize: maxPageSize,
    invoiceNumbers: searchResults,
    retailerId: id,
    minWeekId: startWeek,
    maxWeekId: endWeek,
    minShortageValue: filtersForRequest.minShortageValue,
    maxShortageValue: filtersForRequest.maxShortageValue,
    stage: filtersForRequest.stage,
    status: filtersForRequest.status
  };

  const query = useQuery(
    [DISPUTE_DATA_QUERY_KEY, updatedFilters, searchResults, pageNumber, startWeek, endWeek, maxPageSize],
    () => fetchDisputeData({ invoiceDataRequestParameters, requestConfig, categories, searchResults }),
    {
      retry: 0, // fetchEntityMetrics already has retry logic built in
      staleTime: 60 * 10 * 1000,
      refetchOnWindowFocus: false,
      onError
    }
  );

  useEffect(() => {
    const cancelNetworkCalls = cancelSource.current.cancel;
    return () => cancelNetworkCalls();
  }, []);

  return query;
};

interface UseFetchDisputeDetailsParameters {
  invoiceId: string;
  onError?: () => void;
}

export const useFetchDisputeDetails = ({ invoiceId }: UseFetchDisputeDetailsParameters) => {
  const PAGE_SIZE = 10;
  const { retailer, mainTimePeriod, categories } = useAppSelector((state) => state);
  const { id } = retailer;
  const cancelSource = useRef(axios.CancelToken.source());
  const requestConfig = {
    cancelToken: cancelSource.current.token
  };

  // Always use the most recent available week ID as our upper bounds in our request
  const yearToDateTimePeriod = _get(mainTimePeriod, ['availableMainTimePeriods'], []).find(
    (timePeriod) => timePeriod.id === 'ytd'
  );
  const mostRecentWeek = _get(yearToDateTimePeriod, 'endWeek');

  const invoiceDataRequestParameters: InvoiceRequestParameters = {
    pageSize: PAGE_SIZE,
    invoiceNumbers: [invoiceId],
    retailerId: id,
    maxWeekId: mostRecentWeek
  };

  const query = useQuery(
    ['invoiceId', invoiceId],
    async () => {
      if (!mainTimePeriod) {
        return;
      }

      const { combinedDataSet, invoiceSummaryData, shippingDetailsData } = await fetchDisputeData({
        invoiceDataRequestParameters,
        requestConfig,
        categories
      });

      // Initial Details Section
      const initialDetailsDataRequestParameters: InitialDisputeDetailsParameters = {
        invoiceNumbers: [invoiceId],
        retailerId: id,
        categories
      };
      const initialDetailsRequest = buildRequestForInitialDisputeDetails(initialDetailsDataRequestParameters);
      const initialDetailsData = await axios.post(
        `/api/beacon/AdvancedSearch?_id=${initialDetailsRequest[0].id}`,
        initialDetailsRequest,
        requestConfig
      );

      // eslint-disable-next-line
      return {
        section: {
          disputeDetailsBlockData: combinedDataSet[0],
          lifeCycleData: { invoiceSummaryData, shippingDetailsData },
          initialDetailsData
        }
      };
    },
    {
      retry: 0, // fetchEntityMetrics already has retry logic built in
      staleTime: 60 * 10 * 1000,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    const cancelNetworkCalls = cancelSource.current.cancel;
    return () => cancelNetworkCalls();
  }, []);

  return query;
};
