import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

const StyledScheduledActionText = styled(Typography)({
  fontSize: 16.5,
  color: '#9E9E9E',
  fontWeight: 400
});

export default StyledScheduledActionText;
