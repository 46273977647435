import _get from 'lodash/get';

import { IAdCampaign, AD_CAMPAIGN_STATUS } from 'sl-ad-campaign-manager-data-model';
import { AdScoreByEntityId, AdScores } from './types';

const metricBenchmarkByCampaignType: any = {
  sponsoredDisplay: {
    clickThroughRate: 0.00321,
    costPerClick: 0.94,
    returnOnAdSpend: 5.38,
    campaignId: 1
  },
  sponsoredBrands: {
    clickThroughRate: 0.01152,
    costPerClick: 0.96,
    returnOnAdSpend: 10.86,
    campaignId: 1
  },
  sponsoredProducts: {
    clickThroughRate: 0.00629,
    costPerClick: 0.85,
    returnOnAdSpend: 11.27,
    campaignId: 1
  }
};

interface AggregatedAdScoreForEntity {
  reachAdScoreSum: number;
  adSpendAdScoreSum: number;
  adSalesAdScoreSum: number;
  optimizationAdScoreSum: number;
  adScoreSum: number;
  dataPointCount: number;
}

interface AggregatedAdScoreByEntityId {
  [key: string]: AggregatedAdScoreForEntity;
}

const computeAdScoreForMetric = (
  metricByCampaignId: any,
  adCampaigns: IAdCampaign[],
  benchMarks: any
): { [key: string]: number } => {
  const metricAdScoreByCampaignId: { [key: string]: number } = {};
  metricByCampaignId.data.forEach((dataPoint: any) => {
    if (metricByCampaignId.name === 'spend') {
      metricAdScoreByCampaignId[dataPoint.entity.campaignId] = dataPoint.value;
    } else {
      const currentCampaign = adCampaigns.find((campaign) => campaign.campaignId === dataPoint.entity.campaignId);
      const categoryId = _get(currentCampaign, ['categoryIds', 0], undefined);

      let benchMarksToUse = metricBenchmarkByCampaignType[dataPoint.entity.campaignType];
      if (categoryId && benchMarks && benchMarks[dataPoint.entity.campaignType]) {
        benchMarksToUse = benchMarks[dataPoint.entity.campaignType][categoryId];
      }
      if (benchMarksToUse) {
        if (benchMarksToUse[metricByCampaignId.name]) {
          const score = dataPoint.value / benchMarksToUse[metricByCampaignId.name];
          if (score > 1) {
            metricAdScoreByCampaignId[dataPoint.entity.campaignId] = 1;
          } else if (score < 0) {
            metricAdScoreByCampaignId[dataPoint.entity.campaignId] = 0;
          } else {
            metricAdScoreByCampaignId[dataPoint.entity.campaignId] = score;
          }
        }
      }
    }
  });
  return metricAdScoreByCampaignId;
};

const computeAverageAdScoreWithCampaignWeight = (aggregatedScoreByEntityId: any): AdScoreByEntityId => {
  const adScoresByEntityId: AdScoreByEntityId = {};
  Object.keys(aggregatedScoreByEntityId).forEach((entityId: string) => {
    const adScoreAggregated = aggregatedScoreByEntityId[entityId] || [];
    let spendSum = 0;
    adScoreAggregated.forEach((element: any) => {
      spendSum += element.spend;
    });
    adScoreAggregated.forEach((element: any) => {
      if (!adScoresByEntityId[entityId]) {
        adScoresByEntityId[entityId] = {
          reachAdScore: 0,
          adSpendAdScore: 0,
          adSalesAdScore: 0,
          optimizationAdScore: 0,
          adScore: 0
        };
      }
      const weight = element.spend / spendSum || 0;
      adScoresByEntityId[entityId] = {
        reachAdScore: adScoresByEntityId[entityId].reachAdScore + element.reachAdScore * weight,
        adSpendAdScore: adScoresByEntityId[entityId].adSpendAdScore + element.adSpendAdScore * weight,
        adSalesAdScore: adScoresByEntityId[entityId].adSalesAdScore + element.adSalesAdScore * weight,
        optimizationAdScore: adScoresByEntityId[entityId].optimizationAdScore + element.optimizationAdScore * weight,
        adScore: adScoresByEntityId[entityId].adScore + element.adScore * weight
      };
    });
  });
  return adScoresByEntityId;
};

export const computeAdScores = (
  {
    spendByCampaignId,
    clickThroughRateByCampaignId,
    costPerClickByCampaignId,
    returnOnAdSpendByCampaignId,
    optimizationsByCampaignId,
    adCampaigns
  }: {
    spendByCampaignId: any;
    clickThroughRateByCampaignId: any;
    costPerClickByCampaignId: any;
    returnOnAdSpendByCampaignId: any;
    optimizationsByCampaignId: any;
    adCampaigns: IAdCampaign[];
  },
  benchMarks: any
): AdScores => {
  const adScoresByCampaignId: AdScoreByEntityId = {};
  const reachAdScoreByCampaignId = computeAdScoreForMetric(clickThroughRateByCampaignId, adCampaigns, benchMarks);
  const adSpendAdScoreByCampaignId = computeAdScoreForMetric(costPerClickByCampaignId, adCampaigns, benchMarks);
  const adSalesAdScoreByCampaignId = computeAdScoreForMetric(returnOnAdSpendByCampaignId, adCampaigns, benchMarks);
  const optimizationAdScoreByCampaignId = computeAdScoreForMetric(optimizationsByCampaignId, adCampaigns, benchMarks);
  const spendWeightByCampaignId = computeAdScoreForMetric(spendByCampaignId, adCampaigns, benchMarks);
  Object.keys(reachAdScoreByCampaignId).forEach((campaignId: string) => {
    const reachAdScore: number = reachAdScoreByCampaignId[campaignId] || 0;
    const adSpendAdScore: number = adSpendAdScoreByCampaignId[campaignId] || 0;
    const adSalesAdScore: number = adSalesAdScoreByCampaignId[campaignId] || 0;
    const optimizationAdScore: number = optimizationAdScoreByCampaignId[campaignId] || 0;
    const adScore: number = (reachAdScore + adSpendAdScore + adSalesAdScore + optimizationAdScore) / 4;
    const spend: number = spendWeightByCampaignId[campaignId] || 0;
    adScoresByCampaignId[campaignId] = {
      reachAdScore,
      adSpendAdScore,
      adSalesAdScore,
      optimizationAdScore,
      adScore,
      spend
    };
  });
  const adScoreAggregatedByPortfolioId: AggregatedAdScoreByEntityId = {};
  const adScoreAggregatedByEntityId: AggregatedAdScoreByEntityId = {};
  const adScoreAggregatedByClient: any = {
    client: []
  };
  adCampaigns.forEach((adCampaign) => {
    // only include campaigns that are currently active to contribute to ad score
    if (
      adCampaign.extendedAttributes.status !== AD_CAMPAIGN_STATUS.PAUSED &&
      adCampaign.extendedAttributes.status !== 'ended' &&
      adCampaign.extendedAttributes.status !== AD_CAMPAIGN_STATUS.ARCHIVED
    ) {
      if (adScoresByCampaignId[adCampaign.campaignId]) {
        if (!adScoreAggregatedByEntityId[adCampaign.extendedAttributes.entityId]) {
          adScoreAggregatedByEntityId[adCampaign.extendedAttributes.entityId] = [];
        }
        adScoreAggregatedByClient.client.push(adScoresByCampaignId[adCampaign.campaignId]);
        adScoreAggregatedByEntityId[adCampaign.extendedAttributes.entityId].push(
          adScoresByCampaignId[adCampaign.campaignId]
        );

        if (adCampaign.extendedAttributes.portfolioId && adCampaign.extendedAttributes.portfolioId !== '') {
          if (!adScoreAggregatedByPortfolioId[adCampaign.extendedAttributes.portfolioId]) {
            adScoreAggregatedByPortfolioId[adCampaign.extendedAttributes.portfolioId] = [];
          }
          adScoreAggregatedByPortfolioId[adCampaign.extendedAttributes.portfolioId].push(
            adScoresByCampaignId[adCampaign.campaignId]
          );
        }
      }
    }
  });
  const adScoresByPortfolioId: AdScoreByEntityId =
    computeAverageAdScoreWithCampaignWeight(adScoreAggregatedByPortfolioId);
  const adScoresByEntityId: AdScoreByEntityId = computeAverageAdScoreWithCampaignWeight(adScoreAggregatedByEntityId);
  const adScoresByClient: any = computeAverageAdScoreWithCampaignWeight(adScoreAggregatedByClient);
  return {
    adCampaign: adScoresByCampaignId,
    adPortfolio: adScoresByPortfolioId,
    adEntity: adScoresByEntityId,
    client: adScoresByClient.client
  };
};
