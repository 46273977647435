/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import axios from 'axios';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { store } from 'src/main';
import PillFilters from 'src/components/Search/AdvancedSearch/AdvancedSearchSideBar/PillFilters';
import ReduxStore from 'src/types/store/reduxStore';
import FeaturedProductsGrid from 'src/components/AdCampaignBuilder/Widgets/FeaturedProductsGrid';
import InstacartProductGrid from 'src/components/AdCampaignBuilder/Widgets/InstacartProductGrid';
import { SectionWrapper, OperationButtons } from '../Widgets/AdCampaignBuilderCommonWidgets';
import { CAMPAIGN_TYPES } from 'src/store/modules/adManager/adCampaignBuilder/types';

const mapStateToProps = ({ adCampaignBuilder, retailer, entityService }: ReduxStore) => ({
  adCampaignBuilder,
  retailer,
  entityService
});

const localeTable = (retailerId: number) => {
  // the following table comes from https://advertising.amazon.com/API/docs/en-us/eligibility-prod-3p/#/Product%20Eligibility/productEligibility
  // which different from the locale we use in numeral.js
  // ar-AE	Arabic (ar)	United Arab Emirates (AE)
  // zh-CN	Chinese (zh)	China (CN)
  // nl-NL	Dutch (nl)	Netherlands (NL)
  // en-AU	English (en)	Australia (AU)
  // en-CA	English (en)	Canada (CA)
  // en-IN	English (en)	India (IN)
  // en-GB	English (en)	United Kingdom (GB)
  // en-US	English (en)	United States (US)
  // fr-CA	French (fr)	Canada (CA)
  // fr-FR	French (fr)	France (FR)
  // de-DE	German (de)	Germany (DE)
  // it-IT	Italian (it)	Italy (IT)
  // ja-JP	Japanese (ja)	Japan (JP)
  // ko-KR	Korean (ko)	South Korea (KR)
  // pt-BR	Portuguese (pt)	Brazil (BR)
  // es-ES	Spanish (es)	Spain (ES)
  // es-US	Spanish (es)	United States (US)
  // es-MX	Spanish (es)	Mexico (MX)
  // tr-TR	Turkish (tr)	Turkey (TR)
  const table = new Map();
  table.set(1, 'en-US');
  table.set(11, 'en-CA');
  table.set(16, 'ja-JP');
  return table.get(retailerId);
};
const fetchSkuForSellerCentralEntity = (entity: any, products: any[]) => {
  const API = '/apiAdManager/adCampaigns/getProductEligibilityStatus';
  const skus = products.map((product) => product.retailerSku);
  const locale = localeTable(entity.extendedAttributes.retailerId);
  const requestBody = {
    beaconClientId: entity.beaconClientId,
    beaconClientLoginId: entity.extendedAttributes.beaconClientLoginId,
    platformType: entity.platformType,
    entityId: entity.id,
    adType: 'sp',
    asins: skus,
    locale
  };
  return axios.post(API, requestBody);
};

const fetchSkuForUpcs = (retailerId, items) => {
  const API = '/apiAdManager/adCampaigns/getProductMetadata';
  const requestBody = {
    retailerId: +retailerId,
    ...items
  };
  return axios.post(API, requestBody);
};
const FeaturedGrid: React.FC<ReturnType<typeof mapStateToProps>> = ({ adCampaignBuilder, retailer, entityService }) => {
  const minLength = 1;

  const campaignType = _get(adCampaignBuilder, ['campaignType', 'id']);
  let subheader = 'Select products to advertise in your campaign. Metrics below represent the last four weeks.';
  let canContinue = _get(adCampaignBuilder, ['featured', 'selectedFeaturedProducts', 'length'], 0) >= minLength;
  // sponsored brands can only continue with one product
  if (campaignType === 'sponsoredDisplay') {
    subheader = 'Select up to 12 products to advertise in your campaign. Metrics below represent the last four weeks.';
    canContinue =
      _get(adCampaignBuilder, ['featured', 'selectedFeaturedProducts', 'length'], 0) >= 1 &&
      _get(adCampaignBuilder, ['featured', 'selectedFeaturedProducts', 'length'], 0) <= 12;
  } else if (campaignType === 'sponsoredBrandsVideo') {
    subheader = 'Select a product to advertise in your campaign. Metrics below represent the last four weeks.';
    canContinue = _get(adCampaignBuilder, ['featured', 'selectedFeaturedProducts', 'length'], 0) === 1;
  } else if (campaignType === 'sponsoredBrands') {
    subheader =
      'Select up to 3 products to promote from the page you selected earlier. Metrics below represent the last four weeks.';
  }

  if (['63', '2', '25'].includes(retailer.id)) {
    const count = _get(adCampaignBuilder, ['featured', 'selectedFeaturedUPCs', 'length'], 0);
    if (campaignType === 'sponsoredBrands' && (retailer.id === '2' || retailer.id === '25')) {
      canContinue = count >= 3 && count <= 10;
      subheader = 'Select 3 to 10 products to advertise in your campaign.';
    } else if (campaignType === CAMPAIGN_TYPES.SponsoredWalmartVideo) {
      canContinue = count >= 1 && count <= 10;
      subheader = 'Select products to advertise in your campaign.';
    } else {
      canContinue = count >= 1;
      subheader = 'Select products to advertise in your campaign.';
    }
  }

  if (!adCampaignBuilder.campaignType) {
    return null;
  }

  return (
    <div className="ad-manager-container">
      <SectionWrapper header="Products" subheader={subheader} layer={0}>
        {['63', '2', '25'].includes(retailer.id) ? (
          <InstacartProductGrid
            adCampaignBuilder={adCampaignBuilder}
            retailer={retailer}
            mainEntity={entityService.mainEntity}
          />
        ) : (
          <>
            <PillFilters />
            <FeaturedProductsGrid />
          </>
        )}
      </SectionWrapper>
      <OperationButtons
        subtab="featured"
        canContinue={canContinue}
        onBeforeLeave={async () => {
          const selectedProducts = _get(adCampaignBuilder, ['featured', 'selectedFeaturedProducts'], []);
          const entity = _get(adCampaignBuilder, ['platformSettings', 'entity']);
          const entityType = _get(entity, ['extendedAttributes', 'amsApiModel', 'accountInfo', 'type']);
          if (['sponsoredDisplay', 'sponsoredProducts'].includes(campaignType) && entityType === 'seller') {
            const result = await fetchSkuForSellerCentralEntity(entity, selectedProducts);
            if (result.status === 200 && result.data) {
              const asinList = result.data.productResponseList;
              const asinMapping = asinList.map((res: any) => res.productDetails);
              const newProductList: any[] = [];
              asinMapping.forEach((item: any) => {
                const { asin, sku } = item;
                const newProduct = selectedProducts.find((product: any) => product.retailerSku === asin);
                newProductList.push({
                  ...newProduct,
                  retailerSku: sku,
                  retailerSkus: { 1: sku }
                });
              });
              store.dispatch(adCampaignBuilderActions.setSelectedFeaturedProducts(newProductList));
            }
          }
          if (['63', '2', '25'].includes(retailer.id)) {
            const upcs = _get(adCampaignBuilder, ['featured', 'selectedFeaturedUPCs'], []);
            const items = retailer.id === '63' ? { upcs } : { retailerSkus: upcs };
            const result = await fetchSkuForUpcs(retailer.id, items);
            if (result.status === 200 && result.data) {
              const newProductList = _get(result, ['data'], []);
              newProductList.forEach((item) => {
                item.retailerSku = item.upc;
              });
              store.dispatch(adCampaignBuilderActions.setSelectedFeaturedProducts(newProductList));
            }
          }

          return true;
        }}
      />
    </div>
  );
};

const EnhancedFeaturedGrid = connect(mapStateToProps)(FeaturedGrid);

export default EnhancedFeaturedGrid;
