import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { getProductImageUrl } from 'src/utils/image';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import CompareOption, { CompareOptionProps } from './CompareOption';

interface CompareProductOptionProps extends Omit<CompareOptionProps, 'label'> {
  title: string;
  stacklineSku: string;
  retailerSku: string;
}
const MAX_IMAGE_SIZE = '30px';

/**
 * Row inside of product compare popup to display each product
 */
export default function CompareProductOption({
  title,
  retailerSku,
  stacklineSku,
  selected,
  ...rest
}: CompareProductOptionProps) {
  return (
    <CompareOption
      selected={selected}
      label={
        <Flex gap="mdl" alignItems="center">
          <Flex
            alignItems="center"
            justifyContent="center"
            style={{
              height: MAX_IMAGE_SIZE,
              width: MAX_IMAGE_SIZE
            }}
          >
            <img
              style={{
                maxWidth: MAX_IMAGE_SIZE,
                maxHeight: MAX_IMAGE_SIZE,
                objectFit: 'cover'
              }}
              src={getProductImageUrl(stacklineSku)}
              alt={title}
            />
          </Flex>
          <Flex flexDirection="column" gap="xs">
            <Text variant="body2" truncateLines={1}>
              {title}
            </Text>
            <Flex gap="xxs">
              <Text variant="subtitle3" color="secondary" transform="uppercase">
                Retailer SKU:
              </Text>
              <Text variant="body3">{retailerSku}</Text>
            </Flex>
          </Flex>
        </Flex>
      }
      {...rest}
    />
  );
}
