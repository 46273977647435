/* eslint-disable react/prop-types */
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import Tabs from 'src/components/common/Tabs/Tabs';
import { ChevronIcon } from 'src/components/SvgIcons';

interface OmniGeoMapHeaderProps {
  tabOptions: any[];
  subTabOptions: any;
  selectedTab: { name: string; displayName: string };
  selectedSubTab: any;
  updateSelectedTab: (tabName: any) => void;
  updateSelectedSubTab: (subTabName: any) => void;
}

const styles = {
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: '-30px'
  },
  tabs: { borderBottomWidth: '0px', marginLeft: '85px' },
  tab: { padding: '13px 0px 8px 0px', fontSize: 18, fontWeight: 'bold', marginRight: 60 },
  select: {
    paddingLeft: 0,
    fontSize: '24px'
  }
};

const OmniGeoMapHeader: React.FC<{ classes: { [key: string]: any } } & OmniGeoMapHeaderProps> = ({
  tabOptions,
  subTabOptions,
  selectedTab,
  selectedSubTab,
  updateSelectedTab,
  updateSelectedSubTab,
  classes
}) => {
  const subTabs = subTabOptions[selectedTab.name];
  const activeTabIndex = subTabs.findIndex((subTab: any) => subTab.name === selectedSubTab.name);

  const handleTabChange = (tabIndex: number) => {
    const { name } = subTabs[tabIndex];
    updateSelectedSubTab(name);
  };
  return (
    <div style={styles.header}>
      <Select
        variant="standard"
        value={selectedTab.name}
        onChange={(e) => updateSelectedTab(e.target.value)}
        classes={{ select: classes.select }}
        IconComponent={() => (
          <ChevronIcon className="sl-header__drop-down-icon" style={{ transform: 'translateY(-3px)' }} />
        )}
        disableUnderline
        sx={{
          '& .MuiInputBase-input.MuiInput-input': {
            fontSize: '24px',
            display: 'flex',
            alignItems: 'center',
            borderBottomWidth: '0px'
          },
          '&:before': {
            borderBottomWidth: 0
          },
          '& .MuiSelect-nativeInput': {
            borderBottomWidth: '0px'
          }
        }}
      >
        {tabOptions.map((tabOption) => (
          <MenuItem sx={{ fontSize: '15px' }} key={tabOption.name} value={tabOption.name}>
            {tabOption.displayName}
          </MenuItem>
        ))}
      </Select>
      <Tabs
        tabs={subTabs}
        value={activeTabIndex}
        tabStyle={styles.tab}
        tabRootStyle={styles.tabs}
        onTabChange={(_event: React.ChangeEvent<HTMLInputElement>, tabIndex: number) => handleTabChange(tabIndex)}
      />
    </div>
  );
};

export default withStyles(styles)(OmniGeoMapHeader);
