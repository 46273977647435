import React, { useRef, useState, useMemo } from 'react';
import axios, { CancelTokenSource } from 'axios';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import NumberFormat from 'react-number-format';

import InfoTooltip from 'src/components/AdCampaignBuilder/Widgets/InfoTooltip';
import { store } from 'src/main';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import * as adCampaignBuilderOperations from 'src/store/modules/adManager/adCampaignBuilder/operations';
import { useOnce, useAppSelector } from 'src/utils/Hooks';
import ReduxStore from 'src/types/store/reduxStore';
import { getSettingMetadata, getAdPlatform } from 'src/store/modules/adManager/adCampaignBuilder/selectors';
import { SectionWrapper, OperationButtons } from '../Widgets/AdCampaignBuilderCommonWidgets';
import { panic, track } from 'src/utils/mixpanel';
import 'src/components/Layout/Advertising/AdManagerPageLayout/AdManagerSettings.scss';
import { formatMoney } from 'src/utils/stringFormatting';
import InstacartBidding from 'src/components/Layout/Advertising/AdManagerPageLayout/AdManagerSetting/InstacartBidding';
import { InstacartOptimizationStatus } from 'src/components/Layout/Advertising/AdManagerPageLayout/utils';
import { isInstacart } from 'src/utils/app';
import { INSTACART_MIN_DAILY_BUDGET } from 'src/components/Layout/Advertising/AdManagerPageLayout/AdManagerSetting/utils';

const { CancelToken } = axios;

const buildSummary = (state: ReduxStore) => {
  const {
    minBudget,
    minROAS,
    platformId,
    setup: { name: campaignName, portfolioId, strategyId },
    target: {
      budget: { displayName: totalBudgetDisplayName, daily: dailyBudget, total: totalBudget },
      targetingTypeId
    },
    campaignType,
    platformSettings
  } = state.adCampaignBuilder;

  if (!platformSettings) {
    return null;
  }
  const entity = platformSettings!.entity!;

  const portfolio = getSettingMetadata('portfolioId', portfolioId!)(state)!;
  const strategy = getSettingMetadata('campaignStrategy', strategyId!)(state)!;

  const targetingType = getSettingMetadata('targetingType', targetingTypeId!)(state)!;
  if (!targetingType) {
    return panic(`No \`targetingType\` setting for \`targetingTypeId\` ${targetingTypeId}`);
  }
  const platform = getAdPlatform(platformId!)(state)!;
  if (!platform) {
    return panic(`No platform for \`platformId\` ${platformId}`);
  }
  const summary = {
    campaignName,
    adTypeName: campaignType!.name,
    platformName: platform.name,
    entityName: entity.name,
    entityId: entity.id,
    totalBudgetDisplayName,
    minBudget,
    minROAS,
    dailyBudget,
    totalBudget
  };

  if (strategy) {
    summary.strategyName = strategy.name;
  }
  if (portfolio) {
    summary.portfolioName = portfolio.name;
  }

  return summary;
};

const Confirmation: React.FC = () => {
  const retailer = useAppSelector((state) => state.retailer);
  const adCampaignBuilder = useAppSelector((state) => state.adCampaignBuilder);
  const reduxStore = useAppSelector((state) => state);

  const cancelSource = useRef<CancelTokenSource | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  useOnce(() => {
    cancelSource.current = CancelToken.source();
  });
  /**
   * TODO we can just use isInstacart once optimization can be enabled for Canada
   */
  const isInstacartUsOrCa = useMemo(() => isInstacart(retailer.id) || retailer.id === '100', [retailer.id]);

  const baseAdCampaignBuilderState = _cloneDeep(adCampaignBuilder);

  const summary = buildSummary(reduxStore);
  if (!summary) {
    return null;
  }

  const {
    campaignName,
    adTypeName,
    platformName,
    entityName,
    entityId,
    portfolioName,
    strategyName,
    totalBudgetDisplayName,
    minBudget,
    minROAS,
    totalBudget,
    dailyBudget
  } = summary;
  const handleMinBudgetChange = (newMinBudget: number) => {
    store.dispatch(
      adCampaignBuilderActions.replaceState({
        ...baseAdCampaignBuilderState,
        minBudget: newMinBudget
      })
    );
  };
  const handleMinROASChange = (newMinROAS: number) => {
    store.dispatch(
      adCampaignBuilderActions.replaceState({
        ...baseAdCampaignBuilderState,
        minROAS: newMinROAS
      })
    );
  };

  const isSponsoredDisplay = adTypeName === 'Sponsored Display';

  const isSBA = _get(baseAdCampaignBuilderState, 'campaignType.settingId') === 'sba';

  return (
    <div className="ad-manager-container">
      <SectionWrapper
        header="Confirm Details"
        subheader="Please confirm the final details before submitting this campaign."
        layer={0}
      >
        <div className="setting_container">
          <br />
          <br />
          <div className="setting_row">
            <div className="label">Campaign Name:</div>
            <div>{campaignName}</div>
          </div>
          <div className="setting_row">
            <div className="label">Ad Type:</div>
            <div>{adTypeName}</div>
          </div>
          <div className="setting_row">
            <div className="label">Platform:</div>
            <div>{platformName}</div>
          </div>
          <div className="setting_row">
            <div className="label">Entity:</div>
            <div>{entityName}</div>
          </div>
          <div className="setting_row">
            <div className="label">Entity Id:</div>
            <div>{entityId}</div>
          </div>
          {portfolioName && (
            <div className="setting_row">
              <div className="label">Portfolio:</div>
              <div>{portfolioName}</div>
            </div>
          )}
          {strategyName && (
            <div className="setting_row">
              <div className="label">Optimization:</div>
              <div>{strategyName}</div>
            </div>
          )}
          <div className="setting_row">
            <div className="label">Budget:</div>
            <div>
              {retailer.currencySymbol === '$'
                ? totalBudgetDisplayName
                : `${formatMoney(totalBudget, retailer.currencySymbol)} (${formatMoney(
                    dailyBudget,
                    retailer.currencySymbol
                  )} daily)`}
            </div>
          </div>
          {isInstacart(retailer.id) && (
            <InstacartBidding
              error={
                dailyBudget < INSTACART_MIN_DAILY_BUDGET
                  ? `Optimized bidding campaigns require a minimum daily budget of $${INSTACART_MIN_DAILY_BUDGET}`
                  : ''
              }
              disabled={dailyBudget < INSTACART_MIN_DAILY_BUDGET}
              resetWhenDisabled
              minRoas={baseAdCampaignBuilderState.instacartParams.optimized_bidding_parameters.roas_constraint || 0}
              setMinRoas={(newMinRoas) => {
                store.dispatch(
                  adCampaignBuilderActions.setInstacartParams({
                    optimized_bidding_parameters: {
                      roas_constraint: newMinRoas
                    }
                  })
                );
              }}
              setStatus={(newStatus) => {
                store.dispatch(
                  adCampaignBuilderActions.setInstacartParams({
                    optimized_bidding_enabled: newStatus === InstacartOptimizationStatus.Enabled
                  })
                );
              }}
              status={
                baseAdCampaignBuilderState.instacartParams.optimized_bidding_enabled
                  ? InstacartOptimizationStatus.Enabled
                  : InstacartOptimizationStatus.Disabled
              }
            />
          )}
          {!isSponsoredDisplay && !isSBA && !isInstacartUsOrCa && (
            <div className="setting_row">
              <div className="label">Minimum Daily Budget:</div>
              <div>
                <NumberFormat
                  value={minBudget}
                  thousandSeparator
                  prefix={retailer.currencySymbol}
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  onValueChange={({ floatValue }) => handleMinBudgetChange(floatValue)}
                  // Mimic the style of the MUI `TextInput`
                  style={{
                    height: '32px',
                    outline: 'none',
                    border: '0',
                    borderBottomColor: 'currentcolor',
                    borderBottomStyle: 'none',
                    borderBottomWidth: '0px',
                    borderBottom: '1px solid #eee',
                    width: '100px',
                    fontSize: '16px',
                    color: 'currentColor',
                    fontFamily: 'Roboto'
                  }}
                />
              </div>
              <InfoTooltip style={{ paddingLeft: 10, paddingTop: 0 }}>
                Budget entered here will set a minimum budget that automation will not override
              </InfoTooltip>
            </div>
          )}
          {!isSponsoredDisplay && !isSBA && !isInstacartUsOrCa && (
            <div className="setting_row">
              <div className="label">Minimum ROAS:</div>
              <div>
                <NumberFormat
                  value={minROAS}
                  thousandSeparator
                  prefix={retailer.currencySymbol}
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  onValueChange={({ floatValue }) => handleMinROASChange(floatValue)}
                  // Mimic the style of the MUI `TextInput`
                  style={{
                    height: '32px',
                    outline: 'none',
                    border: '0',
                    borderBottomColor: 'currentcolor',
                    borderBottomStyle: 'none',
                    borderBottomWidth: '0px',
                    borderBottom: '1px solid #eee',
                    width: '100px',
                    fontSize: '16px',
                    color: 'currentColor',
                    fontFamily: 'Roboto'
                  }}
                />
              </div>
              <InfoTooltip style={{ paddingLeft: 10, paddingTop: 0 }}>
                Enter the minimum ROAS for this campaign; automation will minimize spend on targets below this threshold
              </InfoTooltip>
            </div>
          )}
        </div>
      </SectionWrapper>
      <br />
      <OperationButtons
        subtab="confirmation"
        isLoading={isLoading}
        onSubmit={async (isNext) => {
          if (!isNext) {
            return;
          }

          setIsLoading(true);

          // Save Campaign in Campaign builder creation flow
          // Fetch the products for the store URL and use to populate the next page
          const saveCampaignResult = await store.dispatch(
            adCampaignBuilderOperations.saveAdCampaign(adCampaignBuilder, cancelSource.current!.token)
          );

          try {
            const { platformId, campaignType: campaignTypeObj } = adCampaignBuilder;
            const campaignType = _get(campaignTypeObj, ['id']);
            track('Created campaign', { platformId, campaignType });
          } catch (e) {
            console.error(e);
          }

          store.dispatch(
            adCampaignBuilderActions.replaceState({
              ...baseAdCampaignBuilderState,
              apiResponse: saveCampaignResult
            })
          );
          setIsLoading(false);
        }}
      />
    </div>
  );
};

export default Confirmation;
