/* eslint-disable react/prop-types */
import React from 'react';
import _isArray from 'lodash/isArray';

import colors from 'src/utils/colors';

const legendItemColors = [
  colors.actualSpend,
  colors.projectedSpend,
  [colors.actualSpend, colors.projectedSpend],
  colors.surplusBudget
];

const LegendItem: React.FC<{ color: string | string[]; text: string; value: number; percentageValue: string }> = ({
  color,
  text,
  value,
  percentageValue
}) => (
  <div className="legend-item">
    <div className="top-row">
      <span
        className="color-swatch"
        style={{
          backgroundColor: _isArray(color) ? undefined : color,
          backgroundImage: _isArray(color) ? `linear-gradient(to right,${color[0]} 50%, ${color[1]} 50%)` : undefined
        }}
      />
      <span className="title">{text}</span>
    </div>

    <div style={{ textTransform: 'uppercase' }} className="value">
      {value}
    </div>
    <div className="secondary-value">{percentageValue}</div>
  </div>
);

const StackedColumnLegend: React.FC<{ mainLegendData: any[] }> = ({ mainLegendData }) => {
  return (
    <>
      <div className="trend-bar-chart-legend">
        {mainLegendData.map((props: { text: string; value: number; percentageValue: string }, i) => (
          <LegendItem key={`${props.text}`} {...mainLegendData[i]} color={legendItemColors[i]} />
        ))}
      </div>
    </>
  );
};

export default StackedColumnLegend;
