import { Dispatch } from 'react';
import { Widget } from 'src/types/application/widgetTypes';
import { Conditions, RangeFilter, TermFilter } from 'sl-api-connector/types';
import _get from 'lodash/get';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { AD_STATUS_DERIVED } from 'sl-ad-campaign-manager-data-model';
import _set from 'lodash/set';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { buildAggregations, zipMetricsResponseIntoArray } from 'src/components/AdManager/Search';
import { buildFullMonthRangeFilters } from 'src/utils/dateformatting';
import { parseEntityMetrics } from 'src/store/modules/entitySearchService/selectors';
import _uniq from 'lodash/uniq';
import { getCampaignDisplayStatus } from 'src/components/AdManager/AmsUtils';
import { CancelTokenSource } from 'axios';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import {
  getESBodyOverridesForParentPlatform,
  getSearchRequestOverrideForGroupByField,
  modifyESQuery
} from 'src/components/AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';
import { SearchGridConstants } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConstants';
import { IGridResultData } from 'src/components/AdManager/Search/Models/IGridResultData';
import {
  fetchEntityMetricsAndParseResponse,
  getClonedResult
} from 'src/components/AdManager/Search/GridDataFetchers/FetchDataForGroupByField';
import { IFetchEntityMetricsAndParseResponseParams } from 'src/components/AdManager/Search/Models/IFetchEntityMetricsAndParseResponseParams';
import { store } from 'src/main';
import { IDataSet } from 'src/components/AdManager/Search/Models/IDataSet';
import { getTargetOrProductDocumentsByCampaignIdMap } from 'src/components/AdManager/Search/GridDataFetchers/Campaigns/CampaignIdToChildDocumentsMapGenerator';
import { CampaignIdToChildDocumentsMap } from 'src/components/AdManager/Search/GridDataFetchers/Campaigns/CampaignIdToChildDocumentsMap';
import { getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS, applyCriteoOverrides } from 'src/store/modules/parentPlatform/platformUtils';
import { shouldShowCriteo } from 'src/utils/app';
import { Props } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';

export class CampaignsDataFetcher {
  public async fetchCampaignsData(
    props: Pick<Props, 'location'>,
    dispatch: Dispatch<any>,
    widget: Widget,
    mainEntity: any,
    retailer: any,
    app: any,
    entityConditions: Conditions,
    cancelSource: CancelTokenSource,
    searchSideBarConditions: ISearchFilterConditions,
    indexName: any,
    groupByField: any,
    onFirstPageAndCount: boolean,
    aggregationFieldsForResultCount: any,
    mainTimePeriodRangeFilters: RangeFilter[],
    aggregationFilters: ISearchFilterConditions,
    sortDirection: string | undefined,
    currentSortFieldName: any,
    scheduledActionTermFilters: TermFilter[],
    customTermFilters: any,
    fetchMetricsParams: IFetchEntityMetricsAndParseResponseParams,
    dataSet: IDataSet,
    pageNumber: number,
    importantOverrides
  ): Promise<IGridResultData> {
    let parentPlatform = null;
    if (shouldShowCriteo()) {
      parentPlatform = getParentPlatform();

      if (
        importantOverrides &&
        importantOverrides.length > 0 &&
        parentPlatform &&
        parentPlatform === PARENT_PLATFORMS.CRITEO
      ) {
        const mutateOverridesForCriteo = fetchMetricsParams.searchRequestOverrides;
        const newOverride = [];

        mutateOverridesForCriteo.forEach((_, indx) => {
          const mutatedQuery = modifyESQuery(
            mutateOverridesForCriteo[indx],
            getESBodyOverridesForParentPlatform(parentPlatform, importantOverrides)
          );
          newOverride.push(mutatedQuery);
        });

        fetchMetricsParams.searchRequestOverrides = newOverride;
      }
    }
    const result = await fetchEntityMetricsAndParseResponse(fetchMetricsParams, dispatch, cancelSource);
    const cloneResult = getClonedResult(result, pageNumber, dataSet);

    // when it is on campaign tab, fetch the projected spend for the Budget column
    const campaignIds = result.data.map((campaign: any) => campaign.id);

    const campaignIndexToUse = this.getCampaignIndexToUse(props);
    const campaignIdFn = (campaignDoc: any) => {
      const campaignId =
        _get(campaignDoc, ['campaignId'], '') || _get(campaignDoc, ['id'], '') || _get(campaignDoc, ['fieldId'], '');
      return campaignId;
    };
    if (campaignIndexToUse) {
      // fetch data without metrics
      const allCampaignDocs = await dispatch(
        fetchEntityMetrics(
          widget.data.statePropertyName,
          {
            entity: mainEntity,
            retailer,
            app,
            indexName: campaignIndexToUse,
            customResponseParser: (action: any) => {
              const {
                state: { adCampaigns }
              } = action;
              const entityByMap = new Map();
              adCampaigns.forEach((adCampaign: any) => {
                entityByMap.set(adCampaign.id, adCampaign);
              });
              const allCampaignObjs: any[] = [];
              _get(action.apiResponse.data[0], ['documents'], []).forEach((currentDoc: any) => {
                const campaignId = campaignIdFn(currentDoc);
                const campaignObj = entityByMap.get(campaignId);
                const campaignStatusDerived = _get(campaignObj, ['extendedAttributes', 'statusDerived'], '');
                if (
                  campaignStatusDerived !== AD_STATUS_DERIVED.ARCHIVED &&
                  campaignStatusDerived !== AD_STATUS_DERIVED.ENDED
                ) {
                  // will add the rest of props to req from adCampaigns state if id matching
                  allCampaignObjs.push({ ...currentDoc, ...campaignObj });
                }
              });
              return allCampaignObjs;
            }
          },
          [
            {
              doAggregation: false,
              conditions: entityConditions,
              pageNumber: 1,
              pageSize: SearchGridConstants.PAGE_SIZE_10K,
              processDocuments: false,
              returnDocuments: true
            }
          ],
          _get(cancelSource, 'token'),
          true
        )
      );
      // add the campaigns which don't have metrics
      const campaignIdSet = new Set(campaignIds);
      const hasSearchFilters = this.hasSearchFilters(aggregationFilters, searchSideBarConditions);
      allCampaignDocs.forEach((adCampaignDoc: any) => {
        const campaignId = campaignIdFn(adCampaignDoc);
        if (!(props.location.pathname.includes('adPortfolio') && hasSearchFilters) && !campaignIdSet.has(campaignId)) {
          campaignIds.push(campaignId);
          _set(adCampaignDoc, ['extendedAttributes', 'status'], adCampaignDoc.status);
          _set(adCampaignDoc, ['extendedAttributes', 'statusDerived'], adCampaignDoc.statusDerived);
          _set(adCampaignDoc, ['extendedAttributes', 'statusReason'], adCampaignDoc.statusReason);
          cloneResult.data.push({
            entity: {
              ...adCampaignDoc,
              name: _get(adCampaignDoc, 'name', ''),
              type: 'adCampaign',
              // missing platform type for data w/o metrics
              platformType: store.getState().platformType,
              displayName: _get(adCampaignDoc, 'name', ''),
              displayNamePlural: 'Campaigns'
            },
            id: _get(adCampaignDoc, 'id', '').toLowerCase()
          });
          campaignIdSet.add(campaignId);
        }
      });
      const dataCount = cloneResult.data ? cloneResult.data.length : 0;
      cloneResult.totalResultCount = Math.max(cloneResult.totalResultCount, dataCount);
    }
    const rawSpendField = INDEX_FIELDS.getField(app.name, indexName, 'spend');
    const [{ aggregations: projectedSpendAggregationFields }] = buildAggregations([rawSpendField]);

    const projectedSpendTimePeriodRangeFilters = buildFullMonthRangeFilters();
    const queryConditions: ISearchFilterConditions = {
      termFilters: [
        {
          fieldName: 'campaignId',
          condition: 'should',
          values: campaignIds
        }
      ],
      rangeFilters: projectedSpendTimePeriodRangeFilters,
      computeFilters: null
    };
    const projectedSpendMapping = await this.getProjectedSpendMapping(
      dispatch,
      mainEntity,
      retailer,
      app,
      projectedSpendAggregationFields,
      projectedSpendTimePeriodRangeFilters,
      groupByField,
      queryConditions,
      cancelSource
    );

    // TODO: need to add the campaigns that are not there in the projected spend metrics
    const adTargetOrProductDocumentsByCampaignId: CampaignIdToChildDocumentsMap =
      await getTargetOrProductDocumentsByCampaignIdMap(
        widget,
        dispatch,
        mainEntity,
        retailer,
        app,
        queryConditions,
        searchSideBarConditions,
        cancelSource,
        onFirstPageAndCount,
        indexName,
        aggregationFieldsForResultCount,
        mainTimePeriodRangeFilters,
        aggregationFilters,
        sortDirection,
        currentSortFieldName,
        scheduledActionTermFilters,
        customTermFilters,
        pageNumber,
        cloneResult
      );

    if (!(mainEntity && ['adTarget', 'product'].includes(mainEntity.type)) && onFirstPageAndCount) {
      const groupByFieldName = 'campaignId';
      // is Criteo

      let searchRequestOverrideForGridAllResultIds = [
        getSearchRequestOverrideForGroupByField(
          aggregationFieldsForResultCount,
          retailer,
          mainTimePeriodRangeFilters,
          aggregationFilters,
          sortDirection,
          currentSortFieldName,
          groupByFieldName,
          searchSideBarConditions,
          scheduledActionTermFilters,
          customTermFilters,
          pageNumber
        )
      ];

      if (shouldShowCriteo()) {
        searchRequestOverrideForGridAllResultIds = applyCriteoOverrides(
          searchRequestOverrideForGridAllResultIds,
          parentPlatform,
          importantOverrides
        );
      }

      const allResults = await dispatch(
        fetchEntityMetrics(
          'adManager_searchResultsGrid_allResultIds',
          {
            entity: mainEntity,
            retailer,
            app,
            indexName,
            customResponseParser: (action: any) => {
              return zipMetricsResponseIntoArray(action, widget);
            }
          },
          searchRequestOverrideForGridAllResultIds,
          _get(cancelSource, 'token'),
          true
        )
      );
      const fullDataSet = allResults.data.map((item) => item.entity);
      cloneResult.fullDataSet = fullDataSet;
    }

    CampaignsDataFetcher.placeDocForCampaignsDataSet(
      cloneResult.data,
      projectedSpendMapping,
      adTargetOrProductDocumentsByCampaignId
    );
    if (onFirstPageAndCount && cloneResult.fullDataSet) {
      CampaignsDataFetcher.placeDocForCampaignsDataSet(
        cloneResult.fullDataSet,
        projectedSpendMapping,
        adTargetOrProductDocumentsByCampaignId
      );
    }

    return {
      gridResult: cloneResult
    };
  }

  private hasSearchFilters(
    aggregationFilters: ISearchFilterConditions,
    searchSideBarConditions: ISearchFilterConditions
  ) {
    let hasSearchFilters = aggregationFilters.computeFilters.length > 0;
    if (hasSearchFilters) {
      return true;
    }
    hasSearchFilters = this.hasSearchSidebarFilters(searchSideBarConditions);
    return hasSearchFilters;
  }

  private static placeDocForCampaignsDataSet(
    dateSetWithoutDoc: any[],
    projectedSpendMapping: any,
    campaignIdToChildDocumentsMap: CampaignIdToChildDocumentsMap
  ) {
    dateSetWithoutDoc.forEach((campaign: any) => {
      const projectedSpend = projectedSpendMapping.spend_by_campaignId.data.find(
        (data: any) => data.entity.id === campaign.id
      );
      campaign.projectedSpend = projectedSpend;

      if (!campaignIdToChildDocumentsMap) {
        return;
      }

      const childDocuments = campaignIdToChildDocumentsMap[campaign.id];
      if (!childDocuments) {
        return;
      }

      const { extendedAttributes } = childDocuments;
      extendedAttributes.campaignIds = _uniq(extendedAttributes.childDocuments.map((target: any) => target.campaignId));
      extendedAttributes.campaignIdsDelivering = _uniq(
        extendedAttributes.childDocuments
          .filter((x: any) => x.statusDerived === AD_STATUS_DERIVED.DELIVERING)
          .map((target: any) => target.campaignId)
      );

      extendedAttributes.statusDisplay = getCampaignDisplayStatus(extendedAttributes);
      childDocuments.extendedAttributes = {
        ...(campaign.entity || campaign).extendedAttributes,
        ...extendedAttributes
      };
      if (campaign.entity) {
        campaign.entity = {
          ...campaign.entity,
          ...childDocuments,
          ..._get(campaignIdToChildDocumentsMap, [campaign.id, 'extendedAttributes'], {}),
          toggleChildDoc: true
        };
        campaign.bid = _get(campaign, ['entity', 'extendedAttributes', 'childDocuments', 0, 'cpcMaxBidAmount']);
      } else {
        campaign.extendedAttributes = {
          ...extendedAttributes
        };
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private async getProjectedSpendMapping(
    dispatch: Dispatch<any>,
    mainEntity: any,
    retailer: any,
    app: any,
    projectedSpendAggregationFields: any,
    projectedSpendTimePeriodRangeFilters: RangeFilter[],
    groupByField: any,
    queryConditions: ISearchFilterConditions,
    cancelSource: CancelTokenSource
  ) {
    const projectedSpendMapping = await dispatch(
      fetchEntityMetrics(
        'projectedSpendMapping_campaigns',
        {
          entity: mainEntity,
          retailer,
          app,
          indexName: 'adCampaignDailyMetrics',
          customResponseParser: (action: any) => {
            return parseEntityMetrics(action);
          }
        },
        [
          {
            doAggregation: true,
            aggregations: [
              {
                aggregationFields: projectedSpendAggregationFields,
                conditions: {
                  termFilters: [{ fieldName: 'retailerId', values: [+retailer.id] }],
                  rangeFilters: projectedSpendTimePeriodRangeFilters
                },
                groupByFieldName: groupByField.name
              }
            ],
            conditions: queryConditions,
            pageNumber: 1,
            pageSize: SearchGridConstants.PAGE_SIZE_10K,
            processDocuments: true
          }
        ],
        _get(cancelSource, 'token'),
        true
      )
    );
    return projectedSpendMapping;
  }

  // eslint-disable-next-line class-methods-use-this
  private hasSearchSidebarFilters(searchSideBarConditions: ISearchFilterConditions) {
    const fieldNames = ['campaignNameFuzzy', 'excludedCampaignNameFuzzy', 'statusDerived', 'derivedCampaignType'];
    for (let i = 0; i < searchSideBarConditions.termFilters.length; i++) {
      const termFilter = searchSideBarConditions.termFilters[i];
      if (fieldNames.includes(termFilter.fieldName) && termFilter.values.length > 0) {
        return true;
      }
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  private getCampaignIndexToUse(props: Pick<Props, 'location'>): string {
    if (props.location) {
      if (props.location.pathname.includes('adPortfolio')) {
        return 'adCampaign';
      }
      if (props.location.pathname.includes('product')) {
        return 'adCampaignAdGroupProduct';
      }
      if (props.location.pathname.includes('adTarget')) {
        return 'adCampaignAdGroupTarget';
      }
    }
    return '';
  }
}
