import React from 'react';
import { useAppSelector, useHistory } from 'src/utils/Hooks';
import EntityCard, { EntityCardProps } from '../EntityCard/EntityCard';
import { getClientImageUrl, getProductImageUrl } from 'src/utils/image';
import ElementCard from '../ElementCard/ElementCard';
import { BrandLogo } from 'src/components/BeaconRedesignComponents/Generic/BrandLogo';
import EntityProductCard from './EntityProductCard';
import {
  BEACON_PRO_SIDEBAR_IMAGE_MAX_HEIGHT,
  BEACON_PRO_SIDEBAR_IMAGE_WIDTH
} from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import EntityPillDropdown from 'src/components/BeaconRedesignComponents/EntityPillDropdown';
import useCreateSegmentLink from 'src/utils/Hooks/useCreateSegmentLink';
import { Segment } from 'sl-api-connector';
import _get from 'lodash/get';
import { useStacklineTheme } from '@stackline/ui';
import { SxProps } from '@mui/system';
import { ProductImage } from 'src/components/BeaconRedesignComponents/ProductCard';

const periodicCardContainerProps: SxProps = {
  height: '72px'
};

export const EntityCardContainer = () => {
  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);
  const { additionalParams, searchParams } = useAppSelector((state) => state.app.queryParams);
  const createSegmentLink = useCreateSegmentLink();
  const savedSearchesById = useAppSelector((state) => state.segments.savedSearchesById);
  const history = useHistory();
  const userId = useAppSelector((state) => state.user.session.userId);
  const theme = useStacklineTheme();

  const getSegmentTopicDropdownOptions = (entityId: string) => {
    return [
      ...(_get(savedSearchesById ? savedSearchesById.get(entityId) : null, 'userId') === userId
        ? [
            {
              id: 'edit',
              label: 'Edit'
            }
          ]
        : []),
      {
        id: 'duplicate',
        label: 'Duplicate'
      }
    ];
  };

  const entityTypeToCardProps: { [key: string]: EntityCardProps } = {
    client: {
      title: mainEntity.name,
      header: (
        <img
          src={getClientImageUrl(mainEntity.beaconClientId)}
          style={{ maxWidth: BEACON_PRO_SIDEBAR_IMAGE_WIDTH, maxHeight: '80px' }}
          alt={mainEntity.name}
        />
      ),
      pill: 'Organization'
    },
    brand: {
      title: mainEntity.name,
      header: (
        <BrandLogo
          brandId={mainEntity.brandId}
          displayName={mainEntity.name}
          elementCardVariant="large"
          height={80}
          width={BEACON_PRO_SIDEBAR_IMAGE_WIDTH}
        />
      ),
      pill: 'Brand'
    },
    category: {
      title: mainEntity.name,
      header: <ElementCard color="accentTeal">{mainEntity.name.slice(0, 2)}</ElementCard>,
      pill: 'Category',
      titleMarginTop: theme.spacing.md,
      headerContainerSx: periodicCardContainerProps
    },
    segment: {
      title: mainEntity.name,
      header: <ElementCard color="info">{mainEntity.name.slice(0, 2)}</ElementCard>,
      pill: (
        <EntityPillDropdown
          slMenuProps={{
            options: getSegmentTopicDropdownOptions(mainEntity.id as string),
            onChange: (option) =>
              history.push(
                createSegmentLink(savedSearchesById.get(mainEntity.id as string) as Segment, option.id === 'edit')
              )
          }}
        >
          Segment
        </EntityPillDropdown>
      ),
      titleMarginTop: theme.spacing.md,
      headerContainerSx: periodicCardContainerProps
    },
    // Topic
    searchtermlist: {
      title: mainEntity.name,
      header: <ElementCard color="accentMagenta">{mainEntity.name.slice(0, 2)}</ElementCard>,
      pill: (
        <EntityPillDropdown
          slMenuProps={{
            options: getSegmentTopicDropdownOptions(mainEntity.id as string),
            onChange: (option) =>
              history.push(
                createSegmentLink(savedSearchesById.get(mainEntity.id as string) as Segment, option.id === 'edit')
              )
          }}
        >
          Topic
        </EntityPillDropdown>
      ),
      titleMarginTop: theme.spacing.md,
      headerContainerSx: periodicCardContainerProps
    },
    subcategory: {
      title: mainEntity.name,
      header: <ElementCard color="accentTangerine">{mainEntity.name.slice(0, 2)}</ElementCard>,
      pill: 'Subcategory',
      titleMarginTop: theme.spacing.md,
      headerContainerSx: periodicCardContainerProps
    },
    product: {
      title: mainEntity.brandName,
      header: (
        <ProductImage
          imageUri={getProductImageUrl(mainEntity.stacklineSku)}
          mainDisplayTitle={mainEntity.name}
          secondaryDisplayTitle={mainEntity.shortDisplayName}
          width={BEACON_PRO_SIDEBAR_IMAGE_WIDTH}
          height={BEACON_PRO_SIDEBAR_IMAGE_MAX_HEIGHT}
          fallbackImageStyle={{ marginBottom: 0 }}
        />
      ),
      subtitleMarginTop: '8px',
      subtitleText: mainEntity.name,
      titleLink: `/brand/${mainEntity.brandId}${searchParams}${additionalParams}`
    }
  };

  return mainEntity.type === 'product' ? (
    <EntityProductCard />
  ) : entityTypeToCardProps[mainEntity.type] ? (
    <EntityCard {...entityTypeToCardProps[mainEntity.type]} />
  ) : (
    <div style={{ width: '140px', height: '175px' }}></div>
  );
};
