import React, { useState, useRef, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useStacklineTheme } from '@stackline/ui';

import ProductGrowthMatrixCard, {
  ProductGrowthMatrixCardProps
} from '../ProductGrowthMatrixCard/ProductGrowthMatrixCard';
import { useAppSelector } from 'src/utils/Hooks';

export interface GrowthMatrixScatterPlotDatum extends ProductGrowthMatrixCardProps {
  x: number;
  y: number;
  stacklineSku: string;
}

export interface GrowthMatrixScatterPlotProps {
  /**
   * Data to display with x and y coordinates
   */
  data: GrowthMatrixScatterPlotDatum[];
  /**
   * Height of the chart
   */
  height?: number;

  /**
   * Title for the x axis
   */
  xAxisLabel: string;

  /**
   * Title for the y axis
   */
  yAxisLabel: string;
}

/**
 * We want to keep the tooltip inside the chart
 * above the x axis line. This defines the offset
 * between the bottom of the chart container
 * and the x axis.
 */
const CHART_BOTTOM_OFFSET = 90;

/**
 * We want to keep the tooltip inside the chart's
 * horizontal edges. If it goes past the edge,
 * this offset sets it back next to the hovered point
 */
const CHART_HORIZONTAL_OFFSET = 32;

/**
 * Puts the tooltip next to the hovered point instead
 * of directly on top
 */
const CHART_POINT_OFFSET = 100;

/**
 * Displays scatterplot data with four quadrants and product information
 * for each point
 */
const GrowthMatrixScatterPlot = ({ data, height = 500, xAxisLabel, yAxisLabel }: GrowthMatrixScatterPlotProps) => {
  const chartContainerRef = useRef(null);
  const tooltipContainerRef = useRef(null);

  const theme = useStacklineTheme();
  const [tooltip, setTooltip] = useState<{ x: number; y: number } | null>(null);
  const [clickedPoint, setClickedPoint] = useState<GrowthMatrixScatterPlotDatum | null>();

  const productView = useAppSelector((state) => state.entityService.mainEntity.type === 'product');
  const stacklineSku = useAppSelector((state) => state.entityService.mainEntity.stacklineSku);

  /**
   * Set the graph back to normal and hide the tooltip.
   */
  const resetGraph = () => {
    setTooltip(null);
    setClickedPoint(null);
  };

  const handleMouseLeaveChart = (event: MouseEvent) => {
    if (productView) {
      return;
    }
    if (chartContainerRef.current && chartContainerRef.current.container) {
      const chartContainer = chartContainerRef.current.container.current;
      const containerRect = chartContainer.getBoundingClientRect();
      const { clientX, clientY } = event;

      // If hovering outside of the chart, reset its state.
      // Clear the tooltip and set the points back to normal
      if (
        clientX < containerRect.left ||
        clientX > containerRect.right ||
        clientY < containerRect.top ||
        clientY > containerRect.bottom
      ) {
        resetGraph();
      }
    }
  };

  useEffect(() => {
    // Reset the graph when hovering outside of the graph
    window.addEventListener('mousemove', handleMouseLeaveChart);

    return () => window.removeEventListener('mousemove', handleMouseLeaveChart);
  }, []);

  /**
   * Determine where to position the tooltip such that it
   * stays inside the grid
   */
  const calculateTooltipPosition = (x: number, y: number) => {
    const chartContainer = chartContainerRef.current.container.current;
    const containerRect = chartContainer.getBoundingClientRect();
    const tooltipContainer = tooltipContainerRef.current;
    const tooltipRect = tooltipContainer.getBoundingClientRect();

    let adjustedX = x + CHART_POINT_OFFSET; // Put the tooltip to the right of the point
    let adjustedY = y;

    // Move the tooltip back to the left of the point
    // if it's going off the edge
    if (adjustedX + tooltipRect.width >= containerRect.width) {
      adjustedX -= tooltipRect.width + CHART_HORIZONTAL_OFFSET;
    }

    // Move the tooltip up if it's going off the bottom of the chart
    if (y + tooltipRect.height >= containerRect.height - CHART_BOTTOM_OFFSET) {
      adjustedY -= y + tooltipRect.height - containerRect.height; // subtract by the amount it hangs off edge of chart
      adjustedY -= CHART_BOTTOM_OFFSET; // add back the chart offset since we compared if the tooltip is past the offset
    }

    return {
      x: adjustedX,
      y: adjustedY
    };
  };

  /**
   * If we are on the product view, the default to showing the
   * card of the product we are viewing. The other products in the subcategory
   * will be on the matrix but not clickable.
   */
  useEffect(() => {
    if (productView) {
      const productRow = data.find((row) => row.stacklineSku === stacklineSku);

      if (productRow && chartContainerRef.current) {
        const { chart } = chartContainerRef.current; // Accessing the actual Highcharts object
        const series = chart.series[0];
        const point = series.data.find((pt) => pt.x === productRow.x && pt.y === productRow.y);

        if (point) {
          const tooltipPosition = calculateTooltipPosition(point.plotX, point.plotY);
          setTooltip(tooltipPosition);
          setClickedPoint(productRow);
        }
      }
    }
  }, [data, productView, stacklineSku]);

  const textStyles = {
    fontFamily: theme.text.fontFamily,
    color: theme.colors.primary
  };

  const getPointIcon = (x: number, y: number): string => {
    let svgPath =
      x < 50 && y < 50 ? 'orangeDot' : x < 50 && y >= 50 ? 'greenDot' : x > 50 && y < 50 ? 'tealDot' : 'blueDot';

    if (clickedPoint && (clickedPoint.x !== x || clickedPoint.y !== y)) {
      // capitalize just the first letter, e.g. mutedBlueDot
      svgPath = `muted${svgPath.charAt(0).toUpperCase()}${svgPath.slice(1)}`;
    }

    return `url(/svg/${svgPath}.svg)`;
  };

  const options: Highcharts.Options = {
    chart: {
      type: 'scatter',
      height,
      spacingLeft: 0,
      events: {
        click: () => {
          if (!productView) {
            resetGraph();
          }
        }
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      tickLength: 0,
      lineColor: 'transparent',
      min: 0,
      max: 100,
      tickInterval: 10,
      title: {
        text: xAxisLabel,
        style: textStyles,
        margin: 32
      },
      labels: {
        formatter: function format() {
          return `${this.value}%`;
        },
        style: textStyles
      },
      plotLines: [
        {
          value: 50,
          color: '#adbdcc', // Color of the dashed line
          dashStyle: 'Dash', // Style of the dashed line
          width: 1, // Width of the dashed line
          zIndex: 5 // Higher zIndex to appear above the scatter plot
        }
      ]
    },
    plotOptions: {
      scatter: {
        cursor: 'pointer'
      }
    },
    yAxis: {
      gridLineWidth: 0,
      min: 0,
      max: 100,
      tickInterval: 10,
      title: {
        text: yAxisLabel,
        style: textStyles,
        margin: 20
      },
      labels: {
        formatter: function format() {
          return `${this.value}%`;
        },
        style: textStyles
      },
      plotLines: [
        {
          value: 50,
          color: '#adbdcc',
          dashStyle: 'Dash',
          width: 1,
          zIndex: 5
        }
      ]
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        type: 'scatter',
        data: data.map((point) => ({
          x: point.x,
          y: point.y,
          marker: {
            states: {
              hover: {
                enabled: false
              }
            },
            symbol: getPointIcon(point.x, point.y)
          },
          events: {
            click: function click() {
              if (!productView) {
                const tooltipPosition = calculateTooltipPosition(this.plotX, this.plotY);
                setTooltip(tooltipPosition);
                setClickedPoint(point);
              }
            }
          }
        }))
      }
    ]
  };

  return (
    <div style={{ position: 'relative' }}>
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartContainerRef} />
      <div
        ref={tooltipContainerRef}
        style={{
          position: 'absolute',
          // make the tooltip invisible and get it out of the way when
          // no points are hovered. that way we can always keep track
          // of its dimensions through a ref, which we couldn't do
          // if it was unmounted
          left: tooltip ? tooltip.x : 0,
          top: tooltip ? tooltip.y : -9999,
          opacity: tooltip ? 1 : 0,
          width: '129px',
          height: '191px'
        }}
      >
        {clickedPoint && <ProductGrowthMatrixCard {...clickedPoint} />}
      </div>
    </div>
  );
};

export default GrowthMatrixScatterPlot;
