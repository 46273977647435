import { useClient } from '@splitsoftware/splitio-react';
import _get from 'lodash/get';
import React, { useEffect } from 'react';
import ReduxStore from 'src/types/store/reduxStore';

const DelightedNPSSurvey = ({ user }: { user: ReduxStore['user'] }) => {
  const client = useClient(undefined, 'user', { email: user.session.email });

  useEffect(() => {
    if (client) {
      const { atlas_nps_survey: npsTreatment } = client.getTreatments(['atlas_nps_survey']);
      if (npsTreatment === 'T') {
        try {
          const {
            config: {
              profile: { email, firstName, lastName, company }
            }
          } = user;
          (window as typeof window & { delighted: { survey: (params: any) => void } }).delighted.survey({
            email, // customer email (optional)
            name: `${firstName} ${lastName}`, // customer name (optional)
            properties: {
              company,
              email,
              companyId: _get(user, ['session', 'companyId']),
              divisionId: _get(user, ['session', 'divisionId']),
              userId: _get(user, ['session', 'userId']),
              beaconClientId: _get(user, ['config', 'vendor', 'BeaconClientId'])
            }
          });
        } catch (e) {
          console.error(`Unable to load nps survey: `, e);
        }
      }
    }
  }, [client, user]);

  return <div />;
};

export default DelightedNPSSurvey;
