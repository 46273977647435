/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';
import { AutoSizer, List as VirtualizedList } from 'react-virtualized';
import NumberFormat from 'react-number-format';
import _pick from 'lodash/pick';
import _get from 'lodash/get';

import { useOnce, useBus } from 'src/utils/Hooks';
import { store } from 'src/main';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { SectionWrapper, OperationButtons } from '../Widgets/AdCampaignBuilderCommonWidgets';
import { AdCampaignBuilderCheckbox } from '../Widgets/Checkbox';
import { withBus } from 'react-bus';

import ReduxStore from 'src/types/store/reduxStore';
import { prop } from 'src/utils/fp';
import { EventBus } from 'src/types/utils';

interface Keyword {
  keyword: string;
  matchType: 'exact' | 'phrase' | 'broad';
  selected: boolean;
  cpcMaxBidAmount: number;
}

const setKeywordList = (newList: Keyword[]) => store.dispatch(adCampaignBuilderActions.setKeywordList(newList));
const updateKeywordList = (kIdx: number[], tIdx: number, key: string, value: any) =>
  store.dispatch(adCampaignBuilderActions.updateKeywordList(kIdx, tIdx, key, value));

const toggleSelectedKeywordType = (kIdx: number, tIdx: number, value: boolean) => {
  updateKeywordList([kIdx], tIdx, 'selected', value);
};

const setKeywordBidPrice = (kIdx: number, tIdx: number, value: number) => {
  updateKeywordList([kIdx], tIdx, 'cpcMaxBidAmount', value);
};

const Checkbox: React.FC<{
  keyword: Keyword;
  onCheck: Function;
  onValueChange: Function;
  retailer: ReduxStore['retailer'];
  minTargetBid: number;
  maxTargetBid: number;
}> = ({ keyword, onCheck, onValueChange, retailer, minTargetBid, maxTargetBid }) => {
  const classname = keyword.selected ? '' : 'disabled';
  return (
    <div className="check_box_warper">
      {/* check box  */}
      <div>
        <AdCampaignBuilderCheckbox disabled={!keyword.enabled} checked={keyword.selected} onChange={() => onCheck()} />
      </div>
      {/* input box */}
      <div className={`bidprice ${classname}`}>
        <NumberFormat
          isAllowed={({ floatValue }) => {
            return floatValue >= minTargetBid && floatValue <= maxTargetBid; // Amazon's rule, all bid price must be greater or equal to $0.02
          }}
          disabled={!keyword.selected}
          value={keyword.cpcMaxBidAmount}
          thousandSeparator
          prefix={retailer.currencySymbol}
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          onValueChange={({ floatValue }) => {
            onValueChange(floatValue);
          }}
        />
      </div>
    </div>
  );
};

const TargetMatchCell: React.FC<{
  keyword: Keyword;
  kIdx: number;
  tIdx: number;
  retailer: ReduxStore['retailer'];
  minTargetBid: number;
  maxTargetBid: number;
}> = ({ keyword, kIdx, tIdx, retailer, minTargetBid, maxTargetBid }) => {
  return (
    <div className="check_box">
      <Checkbox
        keyword={keyword}
        onCheck={useCallback(
          () => toggleSelectedKeywordType(kIdx, tIdx, !keyword.selected),
          [kIdx, tIdx, keyword.selected]
        )}
        onValueChange={useCallback((value: number) => setKeywordBidPrice(kIdx, tIdx, +value), [kIdx, tIdx])}
        retailer={retailer}
        minTargetBid={minTargetBid}
        maxTargetBid={maxTargetBid}
      />
    </div>
  );
};

const TargetMatchRowInner: React.FC<{
  firstColumnClassName: string;
  style: React.CSSProperties;
  keywordType: Keyword[];
  kIdx: number;
  retailer: ReduxStore['retailer'];
  minTargetBid: number;
  maxTargetBid: number;
}> = ({ firstColumnClassName, kIdx, keywordType, style, retailer, minTargetBid, maxTargetBid }) => {
  return (
    <div style={style} className="target-match-row">
      <div className={firstColumnClassName}>{keywordType[0].keyword}</div>
      {keywordType.map((keyword: Keyword, tIdx: number) => (
        <TargetMatchCell
          key={tIdx}
          keyword={keyword}
          kIdx={kIdx}
          tIdx={tIdx}
          retailer={retailer}
          minTargetBid={minTargetBid}
          maxTargetBid={maxTargetBid}
        />
      ))}
    </div>
  );
};

const TargetMatchRow = React.memo(TargetMatchRowInner);

const TargetMatchTable: React.FC<{
  keywordList: any[];
  retailer: ReduxStore['retailer'];
  minTargetBid: number;
  maxTargetBid: number;
  availableType: any[];
}> = ({ keywordList, retailer, minTargetBid, maxTargetBid, availableType }) => {
  const handleSelectAll = (tIdx: number, value: boolean) => {
    const kIdxs = keywordList.map((_keyword, idx) => idx);
    updateKeywordList(kIdxs, tIdx, 'selected', value);
  };

  const params = new URLSearchParams(window.location.search);
  const subtab = params.get('subtab');
  const firstColumnClassName = subtab === 'targetMatch' ? 'name_adBuilder' : 'name';

  const getIsSelectedAll = (tIdx: number) => {
    const haveNotSelect = keywordList.find((keyword) => keyword[tIdx] && !keyword[tIdx].selected);
    return !haveNotSelect;
  };

  const selectAllExact = getIsSelectedAll(0);
  const selectAllPhrase = getIsSelectedAll(1);
  const selectAllBroad = getIsSelectedAll(2);

  return (
    <div className="target-match-table-container">
      <div className="target-match-row header" style={{ fontSize: '15px' }}>
        <div className={firstColumnClassName}>Keyword</div>
        <div className="check_box">
          <div>
            <AdCampaignBuilderCheckbox
              disabled={!availableType.includes('exact')}
              checked={selectAllExact}
              onChange={() => handleSelectAll(0, !selectAllExact)}
            />
          </div>
          Exact Match
        </div>
        <div className="check_box">
          <div>
            <AdCampaignBuilderCheckbox
              disabled={!availableType.includes('phrase')}
              checked={selectAllPhrase}
              onChange={() => handleSelectAll(1, !selectAllPhrase)}
            />
          </div>{' '}
          Phrase Match
        </div>
        <div className="check_box">
          <div>
            <AdCampaignBuilderCheckbox
              disabled={!availableType.includes('broad')}
              checked={selectAllBroad}
              onChange={() => handleSelectAll(2, !selectAllBroad)}
            />
          </div>{' '}
          Broad Match
        </div>
      </div>
      <div className="target-match-table-content">
        <AutoSizer disableHeight>
          {({ width }: { width: number }) => (
            <VirtualizedList
              width={width}
              height={650}
              rowCount={keywordList.length}
              rowHeight={45}
              style={{ marginBottom: 10, outline: 'none' }}
              rowRenderer={({ style, index }: { style: React.CSSProperties; index: number }) => (
                <TargetMatchRow
                  key={`${_get(keywordList, `[${index}][0].cpcMaxBidAmount`, index)}-
                    ${_get(keywordList, `[${index}][1].cpcMaxBidAmount`, index)}-
                    ${_get(keywordList, `[${index}][2].cpcMaxBidAmount`, index)}-${index}`}
                  kIdx={index}
                  firstColumnClassName={firstColumnClassName}
                  style={style}
                  keywordType={keywordList[index]}
                  retailer={retailer}
                  minTargetBid={minTargetBid}
                  maxTargetBid={maxTargetBid}
                />
              )}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state.adCampaignBuilder.target, ['selectedTargetEntities', 'keywordList']),
    retailer: state.retailer,
    adPlatformSettings: state.adPlatformSettings,
    mainEntity: state.entityService.mainEntity,
    adCampaignBuilder: state.adCampaignBuilder
  };
};

const TargetMatch: React.FC<
  { onlyTable?: boolean; hideOperationButtons?: boolean; style?: React.CSSProperties; eventBus: EventBus } & ReturnType<
    typeof mapStateToProps
  >
> = ({
  onlyTable,
  hideOperationButtons,
  style,
  selectedTargetEntities,
  keywordList,
  retailer,
  adPlatformSettings,
  mainEntity,
  adCampaignBuilder,
  eventBus
}) => {
  let availableType = ['exact', 'phrase', 'broad'];
  if (retailer.id === '63') {
    availableType = ['exact'];
  }

  const apiLimits = adPlatformSettings.filter((set) => set.settingType === 'apiLimit');
  let campaignType = _get(mainEntity, ['extendedAttributes', 'campaignType'], null);

  if (mainEntity && mainEntity.type === 'client' && adCampaignBuilder) {
    campaignType = _get(adCampaignBuilder, ['campaignType', 'settingId'], null);
  }

  const match = apiLimits.find((set) => _get(set, ['extendedAttributes', 'campaignType']) === campaignType);
  let minTargetBid = campaignType === 'sponsoredBrands' ? 0.1 : 0.02;
  let maxTargetBid = campaignType === 'sponsoredBrands' ? 49 : 1000;

  if (match) {
    const min = _get(match, ['extendedAttributes', 'targetLimits', 'minimumBid'], null);
    const max = _get(match, ['extendedAttributes', 'targetLimits', 'maximumBid'], null);
    minTargetBid = min || minTargetBid;
    maxTargetBid = max || maxTargetBid;
  }

  const handleBulkBidChange = ({ type, value }: { type: string; value: any }) => {
    const newKeywordList = adCampaignBuilder.target.keywordList;
    if (newKeywordList) {
      for (let i = 0; i < newKeywordList.length; i++) {
        if (Array.isArray(newKeywordList[i])) {
          for (let j = 0; j < newKeywordList[i].length; j++) {
            if (newKeywordList[i][j].selected) {
              const oldValue = newKeywordList[i][j].cpcMaxBidAmount;
              let newValue = oldValue;
              if (type === 'increaseMoney') {
                newValue += value;
              } else if (type === 'increasePercent') {
                newValue *= 1 + value / 100;
              } else if (type === 'setMoney') {
                newValue = value;
              } else if (type === 'decreaseMoney') {
                newValue -= value;
              } else if (type === 'decreasePercent') {
                newValue *= 1 - value / 100;
              }
              if (newValue < minTargetBid) {
                newValue = minTargetBid;
              } else if (newValue > maxTargetBid) {
                newValue = maxTargetBid;
              }
              newKeywordList[i][j].cpcMaxBidAmount = parseFloat(newValue.toFixed(2));
            }
          }
        }
      }
      setKeywordList(newKeywordList);
    }
  };

  useOnce(() => {
    const cloneList = _cloneDeep(selectedTargetEntities || []).filter((keyword) => keyword.selected);

    // Only reset keyword list if the set of keywords is different
    const shouldResetKeywordList =
      window.location.pathname.includes('/adCampaign') ||
      !keywordList ||
      keywordList.length !== cloneList.length ||
      (() => {
        const oldKeywordsSet = new Set();
        keywordList.forEach(([{ keyword }]) => oldKeywordsSet.add(keyword));

        return !!cloneList.find(({ keyword }) => !oldKeywordsSet.has(keyword));
      })();

    if (!shouldResetKeywordList) {
      return;
    }

    // format three types of keyword for each Exact keyword
    const tripleList: any[] = cloneList.map((keyword) => {
      const exactCopy = {
        keyword: keyword.keyword,
        matchType: 'exact',
        selected: true,
        enabled: availableType.includes('exact'),
        cpcMaxBidAmount: +keyword.startBid.toFixed(2)
      };
      const phraseCopy = _cloneDeep(exactCopy);
      const broadCopy = _cloneDeep(exactCopy);
      phraseCopy.matchType = 'phrase';
      phraseCopy.enabled = availableType.includes('phrase');
      phraseCopy.selected = false;
      broadCopy.matchType = 'broad';
      broadCopy.enabled = availableType.includes('broad');
      broadCopy.selected = false;

      return [exactCopy, phraseCopy, broadCopy];
    });
    setKeywordList(tripleList);
  });

  useBus(eventBus, 'bulkApplyBidPrice', handleBulkBidChange);

  const allowUserToContinue = () => {
    if (!keywordList) {
      return false;
    }
    return !!keywordList.find((keywordType) => keywordType.find(prop('selected')));
  };

  if (onlyTable) {
    return (
      <div className="ad-manager-container" style={style}>
        <TargetMatchTable
          keywordList={keywordList || []}
          retailer={retailer}
          minTargetBid={minTargetBid}
          maxTargetBid={maxTargetBid}
          availableType={availableType}
        />
      </div>
    );
  }

  return (
    <div className="ad-manager-container" style={style}>
      <SectionWrapper
        header="Keyword Match Type"
        subheader="Specify match type for selected keywords in the campaign."
        layer={0}
      >
        <TargetMatchTable
          keywordList={keywordList || []}
          retailer={retailer}
          minTargetBid={minTargetBid}
          maxTargetBid={maxTargetBid}
          availableType={availableType}
        />
      </SectionWrapper>
      <br />
      {!hideOperationButtons ? (
        <OperationButtons
          subtab="targetMatch"
          canContinue={allowUserToContinue()}
          eventBus={eventBus}
          showBuldBidUpdate
        />
      ) : null}
    </div>
  );
};

export default connect(mapStateToProps)(withBus('eventBus')(TargetMatch));
