import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { DownloadListIcon } from '../../SvgIcons';
import ExportTable from './ExportTable';
import OverlayScrollbars from 'overlayscrollbars/js/OverlayScrollbars';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { withBus } from 'react-bus';

import GenericDialog from '../../common/Dialog/GenericDialog';
import NavIconWithTooltip from '../../Navigation/NavIconWithTooltip';
import './DownloadList.scss';

const styles = {
  button: {
    width: 38,
    height: 38,
    background: 'none',
    display: 'inline-block',
    verticalAlign: 'bottom',
    padding: 0,
    borderRadius: '50%'
  },
  buttonIcon: {
    width: 38,
    height: 38,
    stroke: 'white'
  }
};

class DownloadList extends Component {
  static propTypes = {
    appName: PropTypes.string.isRequired,
    eventBus: PropTypes.object.isRequired
  };

  state = {
    unreadDownload: 0,
    open: false
  };

  componentDidMount() {
    this.props.eventBus.on('showDownloadNotification', this.showDownloadNotification);
  }

  componentWillUnmount() {
    this.props.eventBus.off('showDownloadNotification', this.showDownloadNotification);
  }

  setExportTableRef = () => {
    this.scrollbar = OverlayScrollbars(document.querySelector('.download-table'), {
      className: 'os-theme-dark',
      scrollbars: {
        visibility: 'auto',
        autoHide: 'scroll',
        autoHideDelay: 1500,
        clickScrolling: true
      }
    });
  };

  openDownloadList = () => this.setState({ open: true, unreadDownload: 0 });

  closeDownloadList = () => {
    this.setState({ open: false });
    if (this.scrollbar && this.scrollbar.destroy) {
      this.scrollbar.destroy();
    }
  };

  showDownloadNotification = () => {
    const notification = document.querySelector('.download-noti');
    notification.classList.add('show');
    setTimeout(() => {
      notification.classList.remove('show');
    }, 3000);
  };

  showDownloadDone = () => {
    const done = document.querySelector('.download-done');
    done.classList.add('show');
    if (!this.state.open) {
      this.setState({ unreadDownload: this.state.unreadDownload + 1 });
    }
    setTimeout(() => {
      done.classList.remove('show');
    }, 3000);
  };

  render() {
    return (
      <Fragment>
        <IconButton style={styles.button} onClick={this.openDownloadList} size="large">
          <NavIconWithTooltip
            IconComponent={DownloadListIcon}
            iconStyle={styles.buttonIcon}
            displayName="Download List"
          />
        </IconButton>

        {this.state.unreadDownload > 0 && (
          <span className="unread-download" style={{ background: 'rgb(255, 210, 47)' }} />
        )}
        <span className="download-noti">File added to download list</span>
        <span className="download-done">File is ready to download</span>
        <GenericDialog
          open={this.state.open}
          onClose={this.closeDownloadList}
          title="Download List"
          textContent="Great data is headed your way. We appreciate your patience."
        >
          <ExportTable
            open={this.state.open}
            closeDownloadList={this.closeDownloadList}
            showDownloadDone={this.showDownloadDone}
            ref={this.setExportTableRef}
          />
        </GenericDialog>
      </Fragment>
    );
  }
}

export default withBus('eventBus')(DownloadList);
