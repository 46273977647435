import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import OverlayScrollbars from 'overlayscrollbars/js/OverlayScrollbars';
import PropTypes from 'prop-types';
import queryString from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppName } from 'sl-api-connector/types';
import DelightedNPSSurvey from 'src/components/Tracking/DelightedNPSSurvey';
import { factory as splitFactory, store } from 'src/main';
import * as AdCampaignBuilderOperations from 'src/store/modules/adManager/adCampaignBuilder/operations';
import { adCampaignOperations } from 'src/store/modules/adManager/adCampaigns';
import { adEntityOperations } from 'src/store/modules/adManager/adEntities';
import { adPlatformOperations } from 'src/store/modules/adManager/adPlatforms';
import { adPlatformSettingsOperations } from 'src/store/modules/adManager/adPlatformSettings';
import { adPortfolioOperations } from 'src/store/modules/adManager/adPortfolios';
import updateAllWeekIdsByRetailerId from 'src/store/modules/allWeekIdsByRetailerId/operations';
import * as appOperations from 'src/store/modules/app/operations';
import { calculateNewQueryParams } from 'src/store/modules/app/selectors';
import { brandOperations } from 'src/store/modules/brands';
import { categoryOperations } from 'src/store/modules/categories';
import { chargeBackStatusesOperations } from 'src/store/modules/chargeBackStatus';
import { chargeBackIssueTypeOperations } from 'src/store/modules/chargeBackTypes';
import { filterOperations, filterSelectors } from 'src/store/modules/filters';
// import { adScoreOperations } from 'src/store/modules/adManager/adScores';
import { updateAllTimePeriods } from 'src/store/modules/main-time-period/operations';
import { promoTypeOperations } from 'src/store/modules/promoTypes';
import { retailerOperations } from 'src/store/modules/retailer';
import { segmentOperations } from 'src/store/modules/segments';
import { subCategoryOperations } from 'src/store/modules/subcategories';
import { onChargebacksMode } from 'src/store/modules/user/selectors';
import { vendorCodeOperations } from 'src/store/modules/vendorCodes';
import { getAppStage, shouldFetchAllCampaignsWithPlatformTypeFilter, shouldShowNewBeacon } from 'src/utils/app';
import { computeUpdatedTimePeriods } from 'src/utils/dateformatting';
import { anyNotEq } from 'src/utils/equality';
import { trackClearFilters, trackFilters } from 'src/utils/mixpanel';
import SignInPrompt from '../AdManager/AdAudit/SignInPrompt/SignInPrompt';
import Loading from '../common/Loading';
import '../common/OverlayScrollbar/theme-overrides.scss';
import GlobalPopup from '../common/Popup/GlobalPopup';
import { HeaderContainer } from '../Header';
import PrimaryNavigationContainer from '../Navigation/PrimaryNavigationContainer';
import { omniRetailersOperations } from 'src/store/modules/omni/omniRetailers';
import { omniBrandsFollowingOperations } from 'src/store/modules/omni/omniBrands';
import { omniCategoriesOperations } from 'src/store/modules/omni/omniCategories';
import { omniSubcategoriesOperations } from 'src/store/modules/omni/omniSubcategories';
import { omniEntityOperations } from 'src/store/modules/omni/omniEntity';
import { omniSegmentOperation } from 'src/store/modules/omni/omniSegment';
import { omniKeywordListOperation } from 'src/store/modules/omni/omniKeywordList';
import { omniStoreListOperation } from 'src/store/modules/omni/OmniStoreList';
import { fetchOmniAccountSetting } from 'src/store/modules/omni/omniAccountSetting/operations';
import { adPlatformSettingsByClientOperations } from 'src/store/modules/adManager/adPlatformSettingsByClient';
import Header from 'src/components/BeaconRedesignComponents/Header/Header';
import { PrimaryNavBar } from 'src/components/BeaconRedesignComponents/PrimaryPageNavigation/PrimaryNavBar';
import styles from 'src/components/BeaconRedesignComponents/styles/styles';

class HomeAuthLayout extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    children: PropTypes.array.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    brandsFollowing: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    promoTypes: PropTypes.array.isRequired,
    chargeBackIssueTypes: PropTypes.array.isRequired,
    vendorCodes: PropTypes.array.isRequired,
    chargeBackStatuses: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    subCategories: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    allSuperUserCategories: PropTypes.array.isRequired,
    allSuperUserSubCategories: PropTypes.array.isRequired,
    resetAdCampaignBuilderReduxState: PropTypes.func.isRequired,
    omniBrandsFollowing: PropTypes.array.isRequired,
    omniCategories: PropTypes.array.isRequired,
    omniSubcategories: PropTypes.array.isRequired,
    omniRetailers: PropTypes.object.isRequired,
    omniInstacartRetailers: PropTypes.object,
    omniEntity: PropTypes.object.isRequired,
    omniSegmentService: PropTypes.object.isRequired,
    omniKeywordSegmentService: PropTypes.object.isRequired,
    omniStoreSegmentService: PropTypes.object.isRequired,
    omniAccountSettingService: PropTypes.object.isRequired,
    omniKeywordCategoriesService: PropTypes.object.isRequired
  };

  state = {
    isInitializing: true
  };

  async componentDidMount() {
    this.scrollbar = OverlayScrollbars(document.querySelectorAll('body'), {
      className: 'os-theme-dark',
      scrollbars: {
        visibility: 'auto',
        autoHide: 'scroll',
        autoHideDelay: 1500,
        clickScrolling: true
      }
    });
    await this.initializeAfterSplitIOReadyForDrive(this.props);
  }

  async initializeAfterSplitIOReadyForDrive(props, nextProps) {
    const { app } = nextProps || props;
    if (app.name !== AppName.Advertising || this.splitClientReady) {
      await this.initialize(props, nextProps);
      return;
    }

    this.splitClientReady = false;
    try {
      const splitClient = splitFactory.client();
      // Wait for SplitIO SDK ready
      await splitClient.ready().catch((e) => {
        throw e;
      });
      if (app.stage !== 'prod') {
        // eslint-disable-next-line no-console
        console.log(`SplitIO SDK is ready`);
      }
      // SDK is ready
      this.splitClientReady = true;
      await this.initialize(props, nextProps);
    } catch (e) {
      console.warn(`SplitIO SDK could have timed-out!: ${e}`);
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { app } = nextProps || this.props;
    if (app === AppName.Advertising && !this.splitClientReady) {
      return; // Wait till SplitIO SDK is ready
    }

    if (!_isEqual(this.props, nextProps)) {
      await this.initializeAfterSplitIOReadyForDrive(this.props, nextProps);
    }
  }

  componentWillUnmount() {
    if (this.scrollbar && this.scrollbar.destroy) {
      this.scrollbar.destroy();
    }
  }

  // eslint-disable-next-line consistent-return
  initialize = async (props, nextProps) => {
    const {
      app,
      categories,
      location,
      retailer,
      filters,
      platforms,
      segments,
      setAppRetailer,
      subCategories,
      allSuperUserCategories,
      allSuperUserSubCategories,
      user,
      brandsFollowing,
      promoTypes,
      chargeBackIssueTypes,
      vendorCodes,
      chargeBackStatuses,
      user: { session, config },
      fetchConfig,
      fetchAllSavedSearch,
      fetchAllAdPortfolios,
      fetchAllBrandsFollowing,
      fetchPromoTypesForRetailer,
      fetchChargeBackIssueTypes,
      fetchVendorCodes,
      fetchChargeBackStatuses,
      fetchAdPlatforms,
      fetchAllAdCampaigns,
      fetchAllAdEntities,
      fetchAllPlatformSettings,
      fetchAdPlatformSettingsByClient,
      // fetchAdScores,
      fetchUnfilteredCategories,
      fetchSubCategories,
      mainTimePeriod,
      comparisonTimePeriod,
      updateAllAppTimePeriods,
      allWeekIdsByRetailerId,
      setWeekIds,
      updateQueryParams,
      clearAllFilters,
      setIsClearingFilters,
      updateFilters,
      categoriesByRetailerId,
      setFilteredCategories,
      // fetchElasticSearchAdCampaigns,
      history,
      // fetch Omni MetaData
      fetchOmniBrandsFollowing,
      fetchOmniRetailers,
      fetchOmniEntity,
      fetchSegmentListData,
      fetchKeywordSegmentListData,
      fetchOmniStoreListData,
      fetchOmniAccountService,
      omniRetailers,
      omniInstacartRetailers,
      omniSubcategories,
      omniCategories,
      omniBrandsFollowing,
      omniCountriesFollowing,
      omniRegionsFollowing,
      omniEntity,
      omniSegmentService,
      omniKeywordSegmentService,
      omniKeywordCategoriesService
    } = nextProps || props;
    let isInitializing = false;
    const { platformEntities } = platforms;
    const { mapQueryFiltersToRedux, mapQueryFiltersToReduxOmni } = filterSelectors;
    const params = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    });
    // `allRetailerIds` can contain retailer IDs that we don't have data for.
    // `availableRetailerIds` can container retailer IDs that client doesn't subscribe.
    // so the logic here is to read the rid on URL first and find it on availableRetailerIds (if it is not 0 (all retailers)),
    // if nothing found, return the first retailer on user's subscription.
    // At omni user config, there is no config.allWeekIdsByRetailerId. The actual available retailer id will in the metaData. so we temp use fake value here
    let availableRetailerIds = [0];

    const beaconProRetailerIds = Object.entries(_get(config, ['subscriptionsByRetailers'], {}))
      .filter(([_, subscription]) => _get(subscription, 'BeaconProSubscription', null) === 'true')
      .map(([id]) => id);

    if (app.name !== AppName.Omni) {
      availableRetailerIds = Object.keys(config.allWeekIdsByRetailerId)
        .filter((id) => {
          return id !== '0';
        })
        .map((id) => +id);
    }

    // in omni don't have the allRetailerIds in config
    const { allRetailerIds } = config;
    if (app.name !== AppName.Omni) {
      availableRetailerIds = _intersection(availableRetailerIds, allRetailerIds).map((id) => {
        return `${id}`;
      });
    }

    let retailerId = params.rid;
    const { entityType } = params;
    if (!retailerId && app.name === AppName.Omni) {
      retailerId = '0';
    }
    // allow 0 if we are on omni or beacon retailer page or when we are in atlas retailers
    // if param.rid is valid, let it be
    // not valid if 0 and is not on beacon or omni or not a valid rid for the client
    if (
      (retailerId === '0' &&
        (app.name !== AppName.Beacon || app.name !== AppName.Omni) &&
        app.name === AppName.Atlas &&
        (entityType === undefined || entityType !== 'retailer')) ||
      (retailerId !== '0' && !availableRetailerIds.includes(params.rid)) ||
      (!params.rid && app.name !== AppName.Omni)
    ) {
      [retailerId] = availableRetailerIds;
    }

    // If we're on beacon pro and the retailer in the URL is not a beacon pro retailer, redirect to the first beacon pro retailer
    if (shouldShowNewBeacon() && !beaconProRetailerIds.includes(params.rid) && !(getAppStage() === 'dev')) {
      [retailerId] = beaconProRetailerIds;
      // If we're on beacon pro and a retailer wasn't found, default to other retailer
      if (!retailerId) {
        [retailerId] = availableRetailerIds;
      }
    }

    if (retailerId !== params.rid) {
      // Redirect to new retailer if param rid is not subscribed to by client
      const newRetailer = retailer.availableRetailers.find((r) => r.id === retailerId);
      if (newRetailer) {
        const additionalParams = { ...params };

        if (app.name === AppName.Discover) {
          const { isStacklineSuperUser } = user.config && user.config;
          if (!isStacklineSuperUser) {
            additionalParams.discoverFilter = ['brand', 'category'].join(',');
          } else {
            additionalParams.discoverFilter = ['noSelection1', 'category'].join(',');
          }
        }

        const {
          searchParams,
          nonPersistentParams,
          additionalParams: calculatedNewAdditionalParams
        } = calculateNewQueryParams(app, newRetailer, mainTimePeriod, comparisonTimePeriod, additionalParams);
        // When we just enter beaconpro.stackline.com => this condition will be triggered because retailerId !== params.rid.
        // and calculatedNewAdditionalParams will have tab and subtab info
        if (shouldShowNewBeacon()) {
          return history.push(`${searchParams}${nonPersistentParams}${calculatedNewAdditionalParams}`);
        }
        history.push(`${searchParams}${nonPersistentParams}`);
      }
    }

    // set the retailer
    if (!retailer || !retailer.id || (params.rid && params.rid !== retailer.id)) {
      isInitializing = true;
      setAppRetailer(retailerId);
    }

    // Fetch config if we do not have one already
    if (!session) {
      isInitializing = true;
      fetchConfig();
    }
    if (app.name === AppName.Omni && _isEmpty(omniEntity)) {
      isInitializing = true;
      fetchOmniEntity('client', config.vendor.BeaconClientId);
    }
    // Set week ids
    if (session && config && _isEmpty(allWeekIdsByRetailerId)) {
      if (app.name !== AppName.Omni) {
        setWeekIds(config.allWeekIdsByRetailerId);
      } else if (!_isEmpty(omniEntity) && omniEntity.weekIds) {
        const clonedWeekId = _cloneDeep(omniEntity.weekIds);
        setWeekIds({ 0: clonedWeekId });
      }
    }

    // Set categories
    if (
      (retailer.id && !_isEmpty(categoriesByRetailerId) && _isEmpty(categories)) ||
      (retailer.id && !_isEmpty(categoriesByRetailerId) && !_isEqual(retailer.id, this.props.retailer.id)) ||
      retailer.id === '0'
    ) {
      setFilteredCategories(categoriesByRetailerId, retailer);
    }

    // Load some stuff into redux, e.g. categories, segments
    if (retailer.id && !mainTimePeriod.startWeek && !_isEmpty(allWeekIdsByRetailerId) && !this.state.isInitializing) {
      if (app.name !== AppName.Omni) {
        fetchAllSavedSearch(app.name);
        fetchAllBrandsFollowing(app.name);
        fetchSubCategories().then(fetchUnfilteredCategories);
      }

      if (app.name === AppName.Advertising) {
        const { config: userConfig } = user;
        const beaconClientId = _get(userConfig, ['vendor', 'BeaconClientId']);
        const userEmail = _get(userConfig, ['profile', 'email'], null);
        if (!this.splitClientReady) {
          console.warn(`Fetching SplitIO treatment before SDK ready`);
          // eslint-disable-next-line consistent-return
          return;
        }
        const shouldAddPlatformTypeFilter = shouldFetchAllCampaignsWithPlatformTypeFilter(
          beaconClientId,
          userEmail,
          app.stage
        );
        // fetch all platforms and all ad entities if user login to Ad manager
        fetchAdPlatforms(retailer.id).then(() => {
          fetchAllAdCampaigns(shouldAddPlatformTypeFilter);
        });
        fetchAllPlatformSettings();
        fetchAdPlatformSettingsByClient().then(() => {
          fetchAllAdEntities(false);
          fetchAllAdPortfolios(false);
        });
        // fetchAdScores();
      } else if (app.name === AppName.Atlas && retailerId !== '0') {
        fetchPromoTypesForRetailer();
      } else if (app.name === AppName.Beacon) {
        fetchChargeBackIssueTypes();
        fetchVendorCodes();
        fetchChargeBackStatuses();
      } else if (app.name === AppName.Omni) {
        fetchOmniBrandsFollowing();
        fetchOmniRetailers();
        fetchSegmentListData();
        fetchKeywordSegmentListData();
        fetchOmniStoreListData();
        fetchOmniAccountService();
      }
      const { updatedMainPeriod, updatedComparisonPeriod, startWeek, endWeek, allWeekIds } = computeUpdatedTimePeriods({
        retailer,
        app,
        params,
        mainTimePeriod,
        allWeekIdsByRetailerId,
        comparisonTimePeriod,
        appName: app.name
      });

      isInitializing = true;
      updateAllAppTimePeriods(allWeekIds, updatedMainPeriod, updatedComparisonPeriod);
      if (params.wr !== 'cd' && (parseInt(params.sw, 10) !== startWeek || parseInt(params.ew, 10) !== endWeek)) {
        updateQueryParams(app, { id: retailer.id }, updatedMainPeriod, updatedComparisonPeriod, params).then(
          (response) => {
            const { additionalParams, nonPersistentParams, searchParams, dropDownSelectionParams } = response.params;

            const mergedParams = {
              ...queryString.parse(dropDownSelectionParams),
              ...queryString.parse(additionalParams)
            };
            const stringifiedMergedParams = queryString.stringify(mergedParams);

            return history.push(`${location.pathname}${searchParams}&${stringifiedMergedParams}${nonPersistentParams}`);
          }
        );
      }
    }

    if (
      (retailer.id &&
        mainTimePeriod.startWeek &&
        comparisonTimePeriod.startWeek &&
        !_isEqual(this.props.retailer, retailer)) ||
      anyNotEq(['mainTimePeriod', 'comparisonTimePeriod', 'location'], this.props, nextProps || props)
    ) {
      updateQueryParams(app, retailer, mainTimePeriod, comparisonTimePeriod, params);
    }

    // Just clear all filters if there is a location change anywhere in the app
    const isAtlas = app.name === 'atlas';
    const isBeacon = app.name === 'beacon';
    const isOnChargebacksMode = onChargebacksMode();
    const useAllCategories = _get(user, ['config', 'isStacklineSuperUser'], false) && app.name === AppName.Atlas;
    const categoriesForEntity = useAllCategories ? allSuperUserCategories : categories;
    const subCategoriesForEntity = useAllCategories ? allSuperUserSubCategories : subCategories;
    const { availableRetailers } = retailer;
    // Check to see that all of the fields we can filter on are populated
    const allFiltersNotEmpty = [
      categoriesForEntity,
      segments,
      subCategoriesForEntity,
      platforms,
      brandsFollowing,
      ...(isAtlas && retailer.id !== '26' ? [promoTypes] : []), // promoTypes is only a filter for Atlas, disable for JD right now
      ...(isBeacon && isOnChargebacksMode ? [chargeBackIssueTypes, vendorCodes, chargeBackStatuses] : [])
    ].every((filter) => !_isEmpty(filter));
    if (allFiltersNotEmpty || (isAtlas && entityType === 'retailer') || app.name === AppName.Omni) {
      const updatedFilters =
        app.name === AppName.Omni
          ? mapQueryFiltersToReduxOmni(
              params,
              filters,
              omniRetailers.data,
              omniSubcategories,
              omniCategories,
              omniBrandsFollowing,
              omniSegmentService.data,
              omniKeywordSegmentService.data,
              omniKeywordCategoriesService.data,
              omniCountriesFollowing,
              omniRegionsFollowing,
              omniInstacartRetailers.data
            )
          : mapQueryFiltersToRedux({
              queryParams: params,
              filters,
              retailer,
              categories: categoriesForEntity,
              segments,
              platforms: platformEntities,
              subcategories: subCategoriesForEntity,
              user,
              brands: brandsFollowing,
              availableRetailers,
              promoTypes,
              chargeBackIssueTypes,
              vendorCodes,
              chargeBackStatuses
            });
      const allFilterNames = [
        'category',
        'subcategory',
        'platform',
        'segment',
        'keywordSegment',
        'retailPrice',
        'businessUnits',
        'brand',
        'region',
        'country',
        'retailer',
        'promoType',
        'chargeBackIssueType',
        'vendorCode',
        'chargeBackStatus',
        'instacartRetailer'
      ];
      const allFiltersAreNull = allFilterNames.every((key) => filters[key] === null || filters[key] === undefined);

      if (params.filter && (!_isEqual(updatedFilters, this.props.filters) || allFiltersAreNull)) {
        updateFilters(updatedFilters);
        const mixPanelUpdatedFilters = { ...updatedFilters };
        if (mixPanelUpdatedFilters.category !== null) {
          // Removing childEntities only for mixPanel purposes otherwise the call fails with 413 (payload too large)
          mixPanelUpdatedFilters.category = mixPanelUpdatedFilters.category.map((item) =>
            _omit(item, ['childEntities'])
          );
        }
        trackFilters(location, mixPanelUpdatedFilters, user);
      } else if (!params.filter) {
        const hasNotNullFilter = !!allFilterNames.find((key) => filters[key] !== null);
        // Only call this if the filters in redux are not equal
        if (!_isEqual(updatedFilters, this.props.filters) && hasNotNullFilter) {
          trackClearFilters(updatedFilters, user);
          clearAllFilters(allFilterNames.reduce((acc, filterName) => ({ ...acc, [filterName]: null }), {})).then(() =>
            setIsClearingFilters(false)
          );
        }
      }
    }

    // Update time period if it does not match params (this usually happens when redirecting to a page from
    // /sign_in with a custom date)
    const needToUpdate = params.wr && mainTimePeriod.id && params.wr !== mainTimePeriod.id;
    if (
      needToUpdate ||
      (params.wr === 'cd' &&
        params.sw &&
        (mainTimePeriod.startWeek
          ? mainTimePeriod.startWeek !== parseInt(params.sw, 10)
          : false || mainTimePeriod.endWeek
          ? mainTimePeriod.endWeek !== parseInt(params.ew, 10)
          : false || mainTimePeriod.startDayId
          ? mainTimePeriod.startDayId !== parseInt(params.sdid, 10)
          : false || mainTimePeriod.endDayId
          ? mainTimePeriod.endDayId !== parseInt(params.edid, 10)
          : false))
    ) {
      const { updatedMainPeriod, updatedComparisonPeriod, allWeekIds } = computeUpdatedTimePeriods({
        retailer,
        app,
        params,
        mainTimePeriod,
        allWeekIdsByRetailerId,
        comparisonTimePeriod,
        appName: app.name
      });

      updateAllAppTimePeriods(allWeekIds, updatedMainPeriod, updatedComparisonPeriod);
    }

    this.setState({ isInitializing });
  };

  isLoading = () => {
    const {
      app,
      categories,
      subCategories,
      brandsFollowing,
      omniBrandsFollowing,
      omniCategories,
      omniSubcategories,
      omniRetailers,
      omniSegmentService,
      omniKeywordSegmentService,
      omniStoreSegmentService,
      omniAccountSettingService,
      omniKeywordCategoriesService
    } = this.props;

    if (this.state.isInitializing) {
      return true;
    }

    if ([AppName.Atlas, AppName.Beacon].includes(app.name)) {
      return _isEmpty(brandsFollowing) || _isEmpty(categories) || _isEmpty(subCategories);
    }

    if (app.name === AppName.Omni) {
      return (
        _isEmpty(omniBrandsFollowing) ||
        _isEmpty(omniCategories) ||
        omniRetailers.isFetching ||
        _isEmpty(omniSubcategories) ||
        omniSegmentService.isFetching ||
        omniKeywordSegmentService.isFetching ||
        omniStoreSegmentService.isFetching ||
        omniAccountSettingService.isFetching ||
        omniKeywordCategoriesService.isFetching
      );
    }

    return false;
  };

  render() {
    const { location, resetAdCampaignBuilderReduxState, app } = this.props;

    if (this.isLoading()) {
      return <Loading style={{ position: 'fixed' }} className="spinner" size={150} thickness={2} />;
    }

    const pathname = location.pathname.includes('account') || location.pathname === '/search' ? '/' : location.pathname;
    const { user } = store.getState();
    const isAdAuditUser = app.name === AppName.Advertising && _get(user, 'config.adAuditEnabled', false);
    const adAuditUiPreference = _get(user, 'config.uiPreference.adAudit', null); // adAudit will be null for new users
    let showSignInPrompt = isAdAuditUser ? adAuditUiPreference === null : false;
    if (isAdAuditUser && adAuditUiPreference !== null) {
      const { hasRequestedDemo, nextReminder, hasDisabledPrompt } = adAuditUiPreference;
      if (hasRequestedDemo !== undefined && hasDisabledPrompt !== undefined) {
        showSignInPrompt =
          !hasRequestedDemo && !hasDisabledPrompt && (!nextReminder || moment().isAfter(moment(nextReminder)));
      }
    }
    const isDev = getAppStage() === 'dev';

    // Beacon Redesign Logic
    const showNewBeacon = shouldShowNewBeacon();

    if (showNewBeacon) {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', flex: 'row nowrap' }}
          className="home-auth-layout-wrapper"
        >
          {/* New header and navigation */}
          <Header />
          <PrimaryNavBar location={location} />
          {/* New main view container */}
          <div style={{ ...styles.pageCenterDiv, paddingTop: '32px' }}>
            <div className="sl-page">
              {app.name === AppName.Atlas ? <DelightedNPSSurvey user={user} /> : null}
              <div>
                {/* AuthenticatedRouter */}
                {this.props.children}
                <div className="sl-page__empty-sidebar" />
              </div>
              <GlobalPopup resetAdCampaignBuilderReduxState={resetAdCampaignBuilderReduxState} />
              <SignInPrompt open={showSignInPrompt && isDev} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', flex: 'row nowrap' }} className="home-auth-layout-wrapper">
          <PrimaryNavigationContainer />
          <div style={{ flex: '1 1', minWidth: 0, minHeight: 0 }} className="home-auth-layout">
            <div className="sl-page">
              {app.name === AppName.Atlas ? <DelightedNPSSurvey user={user} /> : null}
              <HeaderContainer />
              <div className={`sl-page__body ${pathname !== '/' ? 'sl-page__body--atlas' : ''}`}>
                {this.props.children}
                <div className="sl-page__empty-sidebar" />
              </div>
              <GlobalPopup resetAdCampaignBuilderReduxState={resetAdCampaignBuilderReduxState} />
              <SignInPrompt open={showSignInPrompt && isDev} />
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) =>
  _pick(state, [
    'allWeekIdsByRetailerId',
    'app',
    'auth',
    'brandsFollowing',
    'categories',
    'comparisonTimePeriod',
    'filters',
    'mainTimePeriod',
    'navSidebar',
    'platforms',
    'promoTypes',
    'chargeBackIssueTypes',
    'vendorCodes',
    'chargeBackStatuses',
    'retailer',
    'user',
    'segments',
    'subCategories',
    'allSuperUserCategories',
    'allSuperUserSubCategories',
    'categoriesByRetailerId',
    'omniRetailers',
    'omniInstacartRetailers',
    'omniSubcategories',
    'omniCategories',
    'omniBrandsFollowing',
    'omniCountriesFollowing',
    'omniRegionsFollowing',
    'omniEntity',
    'omniSegmentService',
    'omniKeywordSegmentService',
    'omniStoreSegmentService',
    'omniAccountSettingService',
    'omniKeywordCategoriesService'
  ]);

const mapDispatchToProps = {
  setAppRetailer: retailerOperations.updateRetailer,
  updateAllAppTimePeriods: updateAllTimePeriods,
  fetchUnfilteredCategories: categoryOperations.fetchUnfilteredCategories,
  fetchAllSavedSearch: segmentOperations.fetchAllSavedSearch,
  fetchAllBrandsFollowing: brandOperations.fetchBrandsFollowing,
  fetchPromoTypesForRetailer: promoTypeOperations.fetchPromoTypesForRetailer,
  fetchChargeBackIssueTypes: chargeBackIssueTypeOperations.fetchChargeBackIssueTypes,
  fetchVendorCodes: vendorCodeOperations.fetchVendorCodes,
  fetchChargeBackStatuses: chargeBackStatusesOperations.fetchChargeBackStatuses,
  fetchAllAdEntities: adEntityOperations.fetchAdEntities,
  fetchAllAdPortfolios: adPortfolioOperations.fetchAdPortfolios,
  fetchAllAdCampaigns: adCampaignOperations.fetchAdCampaigns,
  fetchAllPlatformSettings: adPlatformSettingsOperations.fetchAdPlatformSettings,
  fetchAdPlatforms: adPlatformOperations.fetchAdPlatforms,
  fetchAdPlatformSettingsByClient: adPlatformSettingsByClientOperations.fetchAdPlatformSettingsByClient,
  fetchSubCategories: subCategoryOperations.fetchSubCategories,
  fetchConfig: appOperations.fetchConfig,
  updateFilters: filterOperations.updateFilters,
  setWeekIds: updateAllWeekIdsByRetailerId,
  updateQueryParams: appOperations.updateQueryParams,
  clearAllFilters: filterOperations.clearAllFilters,
  setIsClearingFilters: filterOperations.setIsClearingFilters,
  setFilteredCategories: categoryOperations.setFilteredCategories,
  fetchElasticSearchAdCampaigns: adCampaignOperations.fetchElasticSearchAdCampaigns,
  resetAdCampaignBuilderReduxState: AdCampaignBuilderOperations.resetAdCampaignBuilderReduxState,
  fetchOmniBrandsFollowing: omniBrandsFollowingOperations.fetchOmniBrandsFollowing,
  fetchOmniCategories: omniCategoriesOperations.fetchOmniCategories,
  fetchOmniSubcategories: omniSubcategoriesOperations.fetchOmniSubcategories,
  fetchOmniRetailers: omniRetailersOperations.fetchOmniRetailers,
  fetchOmniEntity: omniEntityOperations.fetchOmniEntity,
  fetchSegmentListData: omniSegmentOperation.fetchSegmentListData,
  fetchKeywordSegmentListData: omniKeywordListOperation.fetchKeywordSegmentListData,
  fetchOmniStoreListData: omniStoreListOperation.fetchOmniStoreListData,
  fetchOmniAccountService: fetchOmniAccountSetting
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeAuthLayout));
