/* eslint-disable react/prop-types */
import React from 'react';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    marginBottom: 20
  }
};

const ClearAllFilterButton: React.FC<{ context: any }> = ({ context }) => {
  return (
    <div style={styles.root}>
      <LargeMuiButton secondary style={{ marginTop: 14 }} onClick={context.clearAllFilters}>
        Clear Filters
      </LargeMuiButton>
    </div>
  );
};

export default ClearAllFilterButton;
