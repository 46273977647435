import React, { useState } from 'react';
import { connect } from 'react-redux';
import _flatten from 'lodash/flatten';
import _ident from 'lodash/identity';
import _isNil from 'lodash/isNil';
import _memoize from 'lodash/memoize';
import _pick from 'lodash/pick';
import saveAs from 'file-saver';
import { Option } from 'funfix-core';
import axios from 'axios';
import { store } from 'src/main';
import ReduxStore from 'src/types/store/reduxStore';
import { queryMapping } from 'src/utils/segments';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import { filterNils, prop } from 'src/utils/fp';
import { quoteWrap } from 'src/utils/stringFormatting';

interface SegmentLineItem {
  id: string;
  name: string;
  type: string;
  field: string;
  value: string | number;
}

export const mkGetBrandNameByBrandId = ({ app, retailer }: Pick<ReduxStore, 'app' | 'retailer'>) =>
  _memoize(
    async (brandId: string | number): Promise<string> =>
      axios
        .get(`/api/${app.name}/GetEntityMetadata?entityType=brand&entityId=${brandId}&retailerId=${retailer.id}`)
        .then((res) => res.data.brandName as string)
        .catch(() => {
          console.warn(`Failed to fetch brand metadata for brand id ${brandId}`);
          return `Brand ID ${brandId}`;
        }),
    _ident
  );

/**
 * HOF that downloads a CSV containing data about all segments.  All segment items are normalized into line items.
 */

export const mkExportAllSegments =
  (
    {
      segments,
      categories,
      subCategories,
      app,
      retailer
    }: Pick<ReduxStore, 'segments' | 'categories' | 'subCategories' | 'app' | 'retailer'>,
    fileName: string = 'all-saved-searches.csv'
  ) =>
  async () => {
    const isSuperUser = store.getState().user.config.isStacklineSuperUser;

    const lineItems = [
      ..._flatten(
        [
          ...segments.mySegments,
          ...segments.mySearchTermLists,
          ...segments.teamSegments,
          ...segments.teamSearchTermLists
        ].map((segment) =>
          _flatten([
            ...Object.entries(queryMapping.termFilters).map(
              ([key, val]: [keyof typeof queryMapping.termFilters, { fieldName: string }]): SegmentLineItem[] => {
                const items = segment.segment[key as keyof typeof segment.segment] as
                  | { i: string | number }[]
                  | undefined;

                if (!Array.isArray(items)) {
                  return [];
                }

                return items.map(({ i: value }) => ({
                  id: segment.id,
                  name: segment.displayName,
                  type: segment.type,
                  field: val.fieldName,
                  value
                }));
              }
            ),
            ...filterNils(
              Object.entries(queryMapping.rangeFilters).map(([key, val]): SegmentLineItem | null => {
                const range = segment.segment[key as keyof typeof segment.segment] as
                  | { g?: number | string; l?: number | string }
                  | undefined;

                if (!range || !isSuperUser) {
                  return null;
                }

                const gteString = !_isNil(range.g) ? `Greater than or equal to: ${range.g}` : '';
                const lteString = !_isNil(range.l) ? `Less than or equal to: ${range.l}` : '';

                return {
                  id: segment.id,
                  name: segment.displayName,
                  type: segment.type,
                  field: val.fieldName,
                  value: `${gteString}; ${lteString}`
                };
              })
            )
          ])
        )
      )
    ];

    const lookupDisplayName = async (field: string, value: string | number): Promise<string> => {
      const getCategoryNameByCategoryId = (categoryId: number | string) =>
        Option.of(categories.find((cat) => cat.categoryId.toString() === categoryId.toString()))
          .map(prop('categoryName'))
          .getOrElseL(() => `Category ${categoryId}`);

      const getSubCategoryNameBySubCategoryId = (subCategoryId: number | string): string =>
        Option.of(subCategories.find((cat) => cat.subCategoryId.toString() === subCategoryId.toString()))
          .map(({ subCategoryName }: any) => subCategoryName)
          .getOrElseL(() => `Subcategory ${subCategoryId}`);

      const getBrandNameByBrandId = mkGetBrandNameByBrandId({ app, retailer });

      const entityCorpus: { [key: string]: (id: string | number) => string | Promise<string> } = {
        categoryId: getCategoryNameByCategoryId,
        excludedCategoryId: getCategoryNameByCategoryId,
        excludedSubCategoryId: getCategoryNameByCategoryId,
        subCategoryId: getSubCategoryNameBySubCategoryId,
        brandId: getBrandNameByBrandId
      };

      return Option.of(entityCorpus[field])
        .map((getter) => getter(value))
        .getOrElse('');
    };

    const headerRow = ['id', 'name', 'type', 'field', 'value', 'displayName'].map(quoteWrap).join(',');
    const bodyRows: string[] = await Promise.all(
      lineItems.map(async ({ id, name, type, field, value }) =>
        [
          quoteWrap(id),
          quoteWrap(name),
          quoteWrap(type),
          quoteWrap(field),
          typeof value === 'number' ? value : quoteWrap(value),
          quoteWrap(await lookupDisplayName(field, value))
        ].join(',')
      )
    );

    const csvText = [headerRow, ...bodyRows].join('\n');
    const blob = new Blob([csvText], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, fileName);
  };

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['segments', 'categories', 'subCategories', 'retailer', 'app']);

const SegmentExport: React.FC<{} & ReturnType<typeof mapStateToProps>> = ({ ...props }) => {
  const [isExporting, setIsExporting] = useState(false);
  const exportAllSegments = mkExportAllSegments(props);

  return (
    <div className="segment-export">
      <h2 style={{ marginTop: 50 }}>Segment Export</h2>
      <LargeMuiButton
        disabled={isExporting}
        label={isExporting ? 'Exporting...' : 'Export All Saved Searches'}
        onClick={() => {
          setIsExporting(true);
          exportAllSegments().then(() => setIsExporting(false));
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps)(SegmentExport);
