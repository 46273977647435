import { useQuery } from 'react-query';
import axios from 'axios';
import {
  AdjustmentForm,
  PromotionMetricsResponse
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import _get from 'lodash/get';
import { PromotionType } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { BaseAdjustmentRequest } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useCompleteAdjustmentData/types';
import { useBaseAdjustmentRequestBuilder } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useBaseAdjustmentRequestBuilder';
import { safeDivide } from 'src/utils/app';

interface GetPromotionMetricsRequest {
  baseAdjustmentRequest: BaseAdjustmentRequest;
  additionalProperties: {
    [key: string]: string;
  };
  adjustmentChangeType: string;
  adjustmentChangeValue: number;
  stacklineSku: string | number;
  categoryId: string | number;
  subCategoryId: string | number;
  requestType: string;
  shouldPublish: boolean;
}

/**
 * Fetches promotion metrics for a given adjustment
 */
const fetchPromotionMetrics = async (payload: GetPromotionMetricsRequest[]) => {
  const response = await axios.post('api/beaconforecast/GetPromotionMetrics', payload);
  return response;
};

/**
 * Hook that fetches and parses weekly promotion metrics for a given adjustment into a single average value
 */
export const usePromotionAdjustmentMetrics = ({
  adjustmentId = '',
  ignoreConflictWithAdjustmentIds = [],
  adjustmentForm,
  stacklineSku,
  retailerSku,
  categoryId,
  subCategoryId,
  enabled,
  queryKeys
}: {
  adjustmentId?: string;
  ignoreConflictWithAdjustmentIds?: string[];
  stacklineSku: string;
  retailerSku: string;
  categoryId: number;
  subCategoryId: number;
  adjustmentForm: AdjustmentForm;
  enabled: boolean;
  queryKeys: any[];
}) => {
  const { startDate, endDate, planType, planInputAmounts, promotionType } = adjustmentForm;
  const inputAmount = _get(planInputAmounts, 'total', 0);
  const requestPayload = useBaseAdjustmentRequestBuilder({
    adjustmentId,
    ignoreConflictWithAdjustmentIds,
    stacklineSku,
    retailerSku,
    categoryId,
    subCategoryId,
    planType,
    startWeekId: startDate,
    endWeekId: endDate,
    additionalProperties: { promotionType: promotionType === PromotionType.None ? '' : promotionType },
    adjustmentChangeValue: inputAmount
  });

  return useQuery(
    queryKeys,
    async () => {
      try {
        // Fetch the promotion metrics
        const response = await fetchPromotionMetrics([requestPayload]);
        const promotionMetrics = _get(response, ['data', 0, 'promotionMetrics'], []) as PromotionMetricsResponse[];

        // Sum the metrics for each week and then average them
        const summedMetrics = promotionMetrics.reduce((accumulator, metricsByWeek) => {
          Object.keys(metricsByWeek).forEach((key) => {
            if (key !== 'weekId') {
              accumulator[key] = (accumulator[key] || 0) + metricsByWeek[key];
            }
          });
          return accumulator;
        }, {} as Record<string, number>);

        // Average the summed metrics
        return Object.entries(summedMetrics).reduce((acc, [key, value]) => {
          acc[key] = safeDivide(value, promotionMetrics.length, value);
          return acc;
        }, {} as Omit<PromotionMetricsResponse, 'weekId'>);
      } catch (error) {
        console.error('Failed to fetch promotion metrics in usePromotionAdjustmentMetrics', error);
        return {
          retail_price: 0,
          promo_type: '',
          other_traffic: 0
        };
      }
    },
    {
      enabled,
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // data is stale after 5 minutes
      cacheTime: 1000 * 60 * 30 // data is removed from the cache after 30 minutes
    }
  );
};
