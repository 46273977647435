import axios from 'axios';

/**
 * Removes a user from a team
 */
export default function DisableUserFromDivision(userToDeleteEmail: string, currentUserEmail: string) {
  return axios.post(
    'api/user/DisableUserFromDivision',
    {},
    {
      params: {
        activeUserEmail: currentUserEmail,
        userToBeDisabledEmail: userToDeleteEmail
      }
    }
  );
}
