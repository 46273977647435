import { useMemo } from 'react';
import { useAppSelector } from 'src/utils/Hooks';
import _get from 'lodash/get';

/**
 * fetchEntityMetrics requires an entity, app, and retailer.
 * This hook returns memoized values of these objects with the
 * properties on each object required by fetchEntityMetrics.
 */
const useFetchEntityMetricsArgs = () => {
  const entityId = useAppSelector((state) => state.entityService.mainEntity.id);
  const entityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const entityCampaign: string = useAppSelector((state) =>
    _get(state, ['entityService', 'mainEntity', 'campaignId'], '')
  );

  const appName = useAppSelector((state) => state.app.name);
  const appApiAppName = useAppSelector((state) => state.app.apiAppName);

  const retailerId = useAppSelector((state) => state.retailer.id);
  const retailerLocale = useAppSelector((state) => state.retailer.locale);
  const retailerCurrencySymbol = useAppSelector((state) => state.retailer.currencySymbol);

  const entityService = useAppSelector((state) => state.entityService);
  const { mainEntity } = entityService;

  return useMemo(
    () => ({
      entity: {
        ...mainEntity,
        id: entityId,
        type: entityType,
        campaignId: entityCampaign
      },
      retailer: {
        id: retailerId,
        locale: retailerLocale,
        currencySymbol: retailerCurrencySymbol
      },
      app: {
        apiAppName: appApiAppName,
        name: appName
      }
    }),
    [appApiAppName, appName, entityCampaign, entityId, entityType, retailerCurrencySymbol, retailerId, retailerLocale]
  );
};

export default useFetchEntityMetricsArgs;
