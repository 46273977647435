import { useState, useEffect } from 'react';
import { TermFilter } from 'sl-api-connector';
import { handleCurrentScheduledAction } from 'src/components/AdManager/Search/GridDataFetchers/CurrentScheduledActionHandler';
import { useAppSelector } from 'src/utils/Hooks';
import { useDispatch } from 'react-redux';
import { Location } from 'history';
import queryString from 'qs';
import { useDeepCompareMemo } from 'src/utils/app';
import { QUERY_STRING_ARRAY_LIMIT } from '../constants';

/**
 * Returns additional term filters to be included in advanced search requests
 * for tables on the scheduled action pages.
 */
const useScheduledActionTermFilters = ({ location }: { location: Location }) => {
  const [loading, setLoading] = useState(true);
  const [scheduledActionTermFilters, setScheduledActionTermFilters] = useState<TermFilter[]>();
  const [defaultSelectAll, setDefaultSelectAll] = useState(false);
  const retailerPlatformType = useAppSelector((state) => state.retailer.platformType);
  const dispatch = useDispatch();
  const queryParams = useDeepCompareMemo(() => {
    const qp = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: QUERY_STRING_ARRAY_LIMIT });
    return {
      currentScheduledAction: qp.currentScheduledAction
    };
  }, [location.search]) as queryString.ParsedQs;

  useEffect(() => {
    (async () => {
      const { scheduledActionTermFilters: fetchedSATermFilters, defaultSelectAll: fetchedDefaultSelectAll } =
        await handleCurrentScheduledAction(queryParams, dispatch, { platformType: retailerPlatformType });
      setScheduledActionTermFilters(fetchedSATermFilters);
      setDefaultSelectAll(fetchedDefaultSelectAll);
      setLoading(false);
    })();
  }, [dispatch, queryParams, retailerPlatformType]);

  return {
    loading,
    scheduledActionTermFilters,
    defaultSelectAll
  };
};

export default useScheduledActionTermFilters;
