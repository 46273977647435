import React, { useState } from 'react';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import { ProductColumnDefinition } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import ProductGrid from 'src/components/BeaconRedesignComponents/ProductGrid/ProductGrid';

enum TrafficDisplayDropdownOption {
  Product = 'stacklineSku',
  Order = 'orderId',
  LineItem = 'lineItemId',
  Creative = 'creativeId'
}

const makeBaseHeadColumnDefinition = (headerName: string) => ({
  headerName,
  valueFormatter: (_, row: { name: string }) => <div>{row.name}</div>
});

const trafficDisplayDropdownOptionToDisplayName = {
  [TrafficDisplayDropdownOption.Product]: 'Product',
  [TrafficDisplayDropdownOption.Order]: 'Order',
  [TrafficDisplayDropdownOption.LineItem]: 'Line Item',
  [TrafficDisplayDropdownOption.Creative]: 'Creative'
};

const trafficDisplayDropdownOptionToColumnDefinition = {
  [TrafficDisplayDropdownOption.Product]: ProductColumnDefinition,
  [TrafficDisplayDropdownOption.Order]: makeBaseHeadColumnDefinition('Order'),
  [TrafficDisplayDropdownOption.LineItem]: makeBaseHeadColumnDefinition('Line Item'),
  [TrafficDisplayDropdownOption.Creative]: makeBaseHeadColumnDefinition('Creative')
};

/**
 * DPV grid by product, order, line item, or creative
 */
export default function TrafficDisplayGrid() {
  const [dropdownOption, setDropdownOption] = useState<TrafficDisplayDropdownOption>(
    TrafficDisplayDropdownOption.Product
  );

  return (
    <MetricListContainer
      skeletonRows={20}
      showGrid={dropdownOption === TrafficDisplayDropdownOption.Product}
      renderGrid={(args) => <ProductGrid {...args} />}
      headColumnDefinition={trafficDisplayDropdownOptionToColumnDefinition[dropdownOption]}
      entityTableHeaderProps={{
        prefixTitle: 'DPV by',
        title: '',
        groupByFields: Object.values(TrafficDisplayDropdownOption).map((option) => ({
          name: option,
          displayName: trafficDisplayDropdownOptionToDisplayName[option]
        })),
        handleGroupByChange: (event) => setDropdownOption(event.target.value as TrafficDisplayDropdownOption)
      }}
      fields={[
        {
          name: 'dpv'
        },
        {
          name: 'impressions'
        },
        {
          name: 'spend'
        },
        {
          name: 'sales'
        },
        {
          name: 'unitsSold'
        },
        {
          name: 'returnOnAdSpend'
        }
      ]}
      indexName="advertisingDisplay"
      sortFieldName="dpv"
      useTableDataProps={{
        groupByFieldName: dropdownOption,
        filterResponse: () => true
      }}
    />
  );
}
