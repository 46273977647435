import { Box, styled } from '@mui/material';
import { AppTable, AppTableColumnDef, SlRow, Text, useStacklineTheme } from '@stackline/ui';
import { ColDef } from 'ag-grid-community';
import React, { CSSProperties, useState } from 'react';
import { withBus } from 'react-bus';
import { TRENDING_PRODUCTS_TAB_EVENT } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/constants';
import { useStyles } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/styles';
import {
  MetricCellProps,
  determineColor,
  formatOrReturnMetric,
  shouldHidePagination
} from 'src/components/BeaconRedesignComponents/EntityTableRefresh/utils';
import { ExpandRowDef } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/types';
import { BEACON_SUBTABS, BEACON_TABS } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';
import Pagination from 'src/components/BeaconRedesignComponents/Pagination/Pagination';
import NoDataPlaceHolder from 'src/components/BeaconRedesignComponents/common/NoDataPlaceHolder/NoDataPlaceHolder';
import { RightChevron } from 'src/components/SvgIcons/SvgIcons';
import { useBus, useMetricFormatter } from 'src/utils/Hooks';

/**
 * Max number of items shown per page
 */
export const ENTITY_TABLE_PAGE_SIZE = 20;
interface ViewMoreFooterProps {
  borderColor: string;
}
interface AppTableWrapperProps {
  supportViewMore: boolean;
  isViewMoreVisible: boolean;
}

const ViewMoreFooter = styled('div')(({ borderColor }: ViewMoreFooterProps) => ({
  border: `1px solid ${borderColor}`,
  height: '60px',
  marginTop: '-22px',
  borderTop: '0',
  borderBottomRightRadius: '8px',
  paddingLeft: '24px',
  borderBottomLeftRadius: '8px',
  display: 'flex',
  alignItems: 'center'
}));

// For ViewMore, remove need to bottom radius, so we can add another div for "View More"
const AppTableWrapper = styled('div')(({ supportViewMore, isViewMoreVisible }: AppTableWrapperProps) => ({
  width: '100%',
  '[class*="makeStyles-tableBodyRoot-"] .tr-last td:first-of-type': {
    borderBottomLeftRadius: supportViewMore && isViewMoreVisible ? 0 : '8px'
  },
  '[class*="makeStyles-tableBodyRoot-"] .tr-last td:last-of-type': {
    borderBottomRightRadius: supportViewMore && isViewMoreVisible ? 0 : '8px'
  }
}));

interface AppTableWrapperProps {
  supportViewMore: boolean;
}

/**
 * Because the "data" parameters we're given can be anything, we define edge cases for where to find metric values depending on which table we're looking at
 */
const dataGrabber = (data: any) => {
  const params = new URLSearchParams(window.location.search);
  const tab = params.get('tab') || BEACON_TABS.SALES;
  const subtab = params.get('subtab') || BEACON_SUBTABS.KEY_METRICS;
  if (tab === BEACON_TABS.SALES && subtab === BEACON_SUBTABS.WATERFALL) {
    return data.cardView; // For some reason the data is stored under the cardView for this page
  } else {
    return data;
  }
};

export const MetricCell = ({ data, field }: MetricCellProps) => {
  const classes = useStyles();
  const theme = useStacklineTheme();
  const formatMetric = useMetricFormatter();
  const dataToUse = dataGrabber(data);
  // Flatten all unique values
  let percentChangeField = '';
  if (field.includes('PeriodChange')) {
    percentChangeField = field.replace('PeriodChange', 'PercentChange');
  } else {
    percentChangeField = `${field}PercentChange`;
  }

  const potentiallyUnformattedPrimaryValue = dataToUse[field] || 0;
  const potentiallyUnformattedMetricChangeValue = dataToUse[percentChangeField] || 0;

  const primaryDisplayValue = formatOrReturnMetric({ value: potentiallyUnformattedPrimaryValue, field, formatMetric });
  const metricChangeValue = formatOrReturnMetric({
    value: potentiallyUnformattedMetricChangeValue,
    field: percentChangeField,
    formatMetric
  });

  // Determine color depending on increase or decrease
  const valueChangeColor = determineColor(metricChangeValue, theme);
  return (
    <div className={classes.metricCellContainer}>
      <span>{primaryDisplayValue}</span>
      {potentiallyUnformattedMetricChangeValue ? (
        <span style={{ color: valueChangeColor }}>{metricChangeValue}</span>
      ) : null}
    </div>
  );
};

/**
 * Takes in the original props intended for ag-grid-react and modifies them to be used in AppTable (MUI Table).
 * @param columnDefs collection of column definitions originally meant for ag-grid
 */
const modifyColumnDefs = (columnDefs: any[]) => {
  return columnDefs.reduce((memo, column) => {
    const modifiedColumnDefinition: AppTableColumnDef = {
      field: column.field,
      headerName: column.headerName,
      tableColumnProps: { maxWidth: '60px' },
      valueFormatter: (_, data) => {
        if (column.field === 'custom') {
          const Component = column.cellRendererFramework;
          return (
            <div style={{ width: '310px' }}>
              <Component data={data} />
            </div>
          );
        } else if (column.field === 'name') {
          return <div style={{ fontSize: '14px' }}>{data[column.field]}</div>;
        } else {
          return <MetricCell data={data} field={column.field} />;
        }
      }
    };
    memo.push(modifiedColumnDefinition);

    return memo;
  }, [] as AppTableColumnDef[]);
};

export interface EntityTableProps {
  onRowClick: () => any;
  rowData: any[];
  columnDefs: ColDef[];
  eventBus: any;
  shouldModifyColumnDefs?: boolean;
  supportViewMore?: boolean;
  pageSize?: number;
  initialPageSize?: number;
  isLoading?: boolean;
  showPagination?: boolean;
  totalPages?: number;
  page?: number;
  setPage?: (page: number) => void;
  skeletonRows?: number;
  rowStyles?: CSSProperties;
  /**
   * View another (intialPageSize) amount of rows instead of rendering all available rows.
   */
  shouldPaginateViewMore?: boolean;
  expandRowDef?: ExpandRowDef | {};
  /**
   * Expands all rows with expanded row content by default
   */
  openRow?: boolean;
  appTableProps?: Partial<React.ComponentProps<typeof AppTable>>;
}

const EntityTable = ({
  onRowClick = null,
  rowData,
  columnDefs,
  eventBus,
  shouldModifyColumnDefs = true,
  supportViewMore = false,
  initialPageSize = ENTITY_TABLE_PAGE_SIZE,
  isLoading,
  showPagination = true,
  totalPages,
  page,
  setPage,
  shouldPaginateViewMore = false,
  skeletonRows = 20,
  rowStyles = {},
  expandRowDef = {},
  openRow = false,
  appTableProps
}: EntityTableProps) => {
  const columnDefinitions = shouldModifyColumnDefs ? modifyColumnDefs(columnDefs) : columnDefs;
  const theme = useStacklineTheme();
  const classes = useStyles();
  const additionalRowStyles = { height: '60px', ...rowStyles };
  const MAX_PAGE_NUMBER = totalPages || Math.max(Math.ceil(rowData.length / initialPageSize), 1);

  const [_pageNumber, _setPageNumber] = useState(1);
  const originalPageSize = initialPageSize;
  const [pageSize, setPageSize] = useState(initialPageSize);

  const pageNumber = page || _pageNumber;
  const setPageNumber = setPage || _setPageNumber;

  const onClickNext = () => {
    const newPage = Math.min(MAX_PAGE_NUMBER, pageNumber + 1);
    setPageNumber(newPage);
  };
  const onClickPrevious = () => {
    if (MAX_PAGE_NUMBER > 0) {
      setPageNumber(Math.max(1, pageNumber - 1));
    }
  };

  const goToFirstPage = () => {
    setPageNumber(1);
  };

  // when viewMore is disappeared, we need to bring back the rounded end.
  const handleViewMore = () => {
    if (shouldPaginateViewMore) {
      setPageSize(pageSize + originalPageSize);
    } else {
      setPageSize(rowData.length);
    }
  };

  useBus(eventBus, TRENDING_PRODUCTS_TAB_EVENT, goToFirstPage);

  return (
    <AppTableWrapper supportViewMore={supportViewMore} isViewMoreVisible={rowData.length > pageSize}>
      <AppTable
        className={classes.defaultStyles}
        rowStyle={additionalRowStyles}
        onRowClick={onRowClick}
        rows={rowData}
        columns={columnDefinitions}
        pageSize={pageSize}
        hidePagination
        currentPage={pageNumber - 1}
        loading={isLoading}
        skeletonRows={skeletonRows}
        expandRowDef={expandRowDef}
        openRow={openRow}
        noDataPlaceholder={<NoDataPlaceHolder />}
        {...appTableProps}
      />

      {supportViewMore ? (
        rowData.length > pageSize ? (
          <ViewMoreFooter borderColor={theme.colors.primaryGray}>
            <button style={{ cursor: 'pointer', border: 'none', background: 'none' }} onClick={handleViewMore}>
              <Box display="flex" flexDirection="row" alignItems="center" gap="4px">
                <Text variant="subtitle3">View more</Text>
                <RightChevron width="24px" height="24px" />
              </Box>
            </button>
          </ViewMoreFooter>
        ) : null
      ) : showPagination && !shouldHidePagination({ page: pageNumber, pageSize, rowCount: (rowData || []).length }) ? (
        <SlRow horizontalPosition="end">
          <Pagination
            currentPage={pageNumber}
            totalPages={MAX_PAGE_NUMBER}
            onClickNext={onClickNext}
            onClickPrevious={onClickPrevious}
          />
        </SlRow>
      ) : null}
    </AppTableWrapper>
  );
};

export default withBus('eventBus')(EntityTable);
