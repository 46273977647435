import withStyles from '@mui/styles/withStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { store } from 'src/main';
import { setCreative } from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { ENTITIES } from 'src/utils/entityDefinitions';
import '../AdCampaignBuilder.scss';
import { OperationButtons, SectionWrapper } from '../Widgets/AdCampaignBuilderCommonWidgets';

const muiStyles = {
  root: {},
  select: {}
};

const ImageBox = ({ label, sublabel, url, onClick, disabled, selected }) => {
  return (
    <div role="button" className="image-block" onClick={!disabled && onClick}>
      <h3 className="field-header">{label} </h3>
      {selected && (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', color: '#2ab291' }}>
          <h4 style={{ paddingRight: 12 }}>Selected </h4>
          <CheckCircleIcon />
        </div>
      )}
      <div className="field-label" style={{ margin: '10px 0' }}>
        {sublabel}
      </div>
      <div className="creative-img-container">
        <img alt={sublabel} src={url} />
      </div>
    </div>
  );
};

ImageBox.propTypes = {
  label: PropTypes.string.isRequired,
  sublabel: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired
};

const ProductInner = ({ products, selectedProducts, toggleProduct, campaign }) => {
  const { campaignType, featured } = campaign;
  const shouldSelectAsins = campaignType.id === 'sponsoredBrands' && featured.landingType !== 'Brand Store';

  return (
    <SectionWrapper
      header="Creative Assets"
      subheader={
        shouldSelectAsins
          ? 'Select up to 3 products to promote from the page you selected earlier.'
          : `Since you’ve selected a ${campaignType.name} campaign, the main product images will be used.`
      }
      layer={0}
    >
      <div className="image-group">
        {products.map((product, idx) => {
          return (
            <ImageBox
              key={product.idx}
              label={`Product ${idx + 1}`}
              sublabel={product.name}
              url={ENTITIES.advertising.product.imageUrl(product.stacklineSku)}
              onClick={() => {
                if (shouldSelectAsins) {
                  toggleProduct(idx);
                }
              }}
              disabled={false}
              selected={selectedProducts[idx].selected}
            />
          );
        })}
      </div>
    </SectionWrapper>
  );
};

ProductInner.propTypes = {
  products: PropTypes.array.isRequired,
  campaign: PropTypes.object.isRequired,
  selectedProducts: PropTypes.array.isRequired,
  toggleProduct: PropTypes.func.isRequired
};

const Creative = ({ adCampaignBuilder }) => {
  const { campaignType, featured } = adCampaignBuilder;
  const { selectedFeaturedProducts } = featured;

  const [selectedProducts, setSelectedProducts] = useState(
    selectedFeaturedProducts.map((prod) => ({ id: prod.retailerSku, selected: false }))
  );

  const toggleProduct = (idx) => {
    const isAlreadySelected = selectedProducts[idx].selected;
    const selectedItems = selectedProducts.filter((i) => i.selected);

    if (isAlreadySelected || (!isAlreadySelected && selectedItems.length < 3)) {
      setSelectedProducts(
        selectedProducts.map((prod, i) => {
          if (i === idx) {
            prod.selected = !prod.selected;
          }

          return prod;
        })
      );
    }
  };

  useEffect(() => {
    const { creative } = store.getState().adCampaignBuilder;
    store.dispatch(
      setCreative({
        ...creative,
        asins: selectedProducts.filter((prod) => prod.selected).map((prod) => prod.id)
      })
    );
  }, [selectedProducts]);

  if (!campaignType || !selectedFeaturedProducts) {
    return null;
  }

  const shouldSelectAsins =
    campaignType && campaignType.id === 'sponsoredBrands' && featured.landingType !== 'Brand Store';

  return (
    <div className="ad-manager-container">
      <ProductInner
        products={selectedFeaturedProducts}
        selectedProducts={selectedProducts}
        toggleProduct={toggleProduct}
        campaign={adCampaignBuilder}
      />
      <OperationButtons
        canContinue={
          (shouldSelectAsins && selectedProducts.filter((i) => i.selected).length > 0) || !shouldSelectAsins
        }
        subtab="creative"
      />
    </div>
  );
};

Creative.propTypes = {
  classes: PropTypes.object.isRequired,
  adCampaignBuilder: PropTypes.object.isRequired
};

const mapStateToProps = ({ adCampaignBuilder }) => ({
  adCampaignBuilder
});

const CreativeWithState = connect(mapStateToProps)(withStyles(muiStyles)(Creative));

export default CreativeWithState;
