import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { AppName } from 'sl-api-connector';
import ReduxStore from 'src/types/store/reduxStore';
import { getAppName } from './app';

let isInitialized = false;
const appName = getAppName();

export function initializeSentry(app: ReduxStore['app']) {
  const {
    config: { atlasSentryApiKey, omniSentryApiKey, beaconSentryApiKey, adManagerSentryApiKey }
  } = app;
  let token: string | null = null;

  if (appName === AppName.Advertising) {
    token = adManagerSentryApiKey;
  } else if (appName === AppName.Atlas) {
    token = atlasSentryApiKey;
  } else if (appName === AppName.Beacon) {
    token = beaconSentryApiKey;
  } else if (appName === AppName.Omni) {
    token = omniSentryApiKey;
  }

  const release = `${process.env.COMMIT_SHA}${appName}${app.stage === 'dev' ? '-dev' : ''}`;

  if (token) {
    Sentry.init({
      dsn: token,
      integrations: [new Integrations.BrowserTracing()],
      release,
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: appName === AppName.Advertising ? 1 : 0.2 // sample all transactions for drive
    });
    isInitialized = true;
  }
}

export function isSentryInitialized() {
  return isInitialized;
}

export function setSentryUserIdentity(
  session: { clientIP?: string; trackingId: string },
  userConfig: ReduxStore['user']['config']
) {
  const { firstName, lastName, company, email } = userConfig.profile;
  const { clientIP } = session;
  Sentry.setUser({ email, firstName, lastName, company, ip_address: clientIP });
}
