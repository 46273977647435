import axios from 'axios';

import Creators from './actions';
import { categoryOperations } from '../categories';
import { segmentOperations } from '../segments';
import { brandOperations } from '../brands';
import { entitySearchServiceOperations } from '../entitySearchService';
import { userOperations } from '../user';
import { calculateNewQueryParams } from './selectors';
import { addHashIdToUserConfig } from '../user/selectors';
import { Dispatch } from 'react';
import ReduxStore from 'src/types/store/reduxStore';
import { getLoginType, getSSOReferrer, handleSSORedirect } from 'src/utils/sso';

export const { isUpdatingRedux, setAppName, setTargetUrl, receiveAppConfig, requestConfig, setQueryParams } = Creators;

export const fetchConfig = () => (dispatch: Dispatch<{ type: string }>) => {
  dispatch(requestConfig());
  const loginType = getLoginType();
  const ssoReferrer = loginType === 'sso' ? getSSOReferrer() : null;
  // eslint-disable-next-line consistent-return
  return axios.post(`/api/user/GetConfig?ssoReferrer=${ssoReferrer}`).then((response) => {
    if (loginType === 'sso' && response.data.ssoJwtToken && response.data.ssoJwtToken !== '') {
      handleSSORedirect(response.data.ssoJwtToken);
    } else {
      const responseWithHashId = addHashIdToUserConfig(response);
      return [dispatch(receiveAppConfig(response)), dispatch(userOperations.receiveUserConfig(responseWithHashId))];
    }
  });
};

export const clearReduxState = () => (dispatch: Dispatch<{ type: string }>) => {
  //  Clear entity but the the clientSideBarCard has to be redone cause it is remounting
  dispatch(categoryOperations.clearCategories());
  dispatch(segmentOperations.clearAllSavedSearch());
  dispatch(brandOperations.clearBrandsFollowing());
  dispatch(entitySearchServiceOperations.clearEntitySearchService());
  dispatch(userOperations.clearUserConfig());
};

export const updateQueryParams =
  (
    app: ReduxStore['app'],
    retailer: Pick<ReduxStore['retailer'], 'id'>,
    mainTimePeriod: ReduxStore['mainTimePeriod'],
    comparisonTimePeriod: ReduxStore['comparisonTimePeriod'],
    additionalParams = {},
    parentPlatform = { id: null }
  ) =>
  (dispatch: Dispatch<{ type: string; params: any }>) => {
    return new Promise((resolve) => {
      const newQueryParams = calculateNewQueryParams(
        app,
        retailer,
        mainTimePeriod,
        comparisonTimePeriod,
        additionalParams,
        parentPlatform
      );
      resolve(dispatch(setQueryParams(newQueryParams)));
    });
  };
