import * as PauseIconSvg from '@mui/icons-material/Pause';
import React from 'react';
import { AppName } from 'sl-api-connector';
import { getAppName } from 'src/utils/app';
import error404Icon from './404.svg';
import addIcon from './add.svg';
import advertisingIcon from './advertising.svg';
import amcAudiences from './amcAudiences.svg';
import amcCustomize from './amcCustomize.svg';
import amcNewToBrand from './amcNewToBrand.svg';
import amcPathways from './amcPathways.svg';
import amcRegions from './amcRegions.svg';
import amcTouchpoints from './amcTouchpoints.svg';
import amcNoData from './amcNoData.svg';
import arrowDownGreen from './ArrowDownGreen.svg';
import arrowDownRed from './ArrowDownRed.svg';
import arrowGrey from './ArrowGrey.svg';
import arrowUpGreen from './ArrowUpGreen.svg';
import arrowUpRed from './ArrowUpRed.svg';
import assetLibrary from './AssetLibrary_Icon.svg';
import attachmentIcon from './attachment.svg';
import backwardDoubleArrow from './backwardDoubleArrow.svg';
import blankDocuments from './blankDocuments.svg';
import blueCancel from './blueCancel.svg';
import bluePlus from './bluePlus.svg';
import billingIcon from './billing.svg';
import billingWhiteIcon from './billing_white.svg';
import brandIcon from './brand.svg';
import browserIcon from './browserIcon.svg';
import buyboxIcon from './buybox.svg';
import updatedBeaconLogo from './updated_beacon_logo.svg';
import beaconProImageFallback from './beaconProImageFallback.svg';
import calendarIcon from './calendar.svg';
import calendarV2Icon from './calendarV2.svg';
import filterIcon from './filter.svg';
import campaignBuilder from './campaignBuilder.svg';
import clearFilters from './circle-x.svg';
import discoverIcon from './Discover.svg';
import surfaceIcon from './surface1.svg';
import campaignsIcon from './Campaigns_Icon.svg';
import categoryIcon from './category.svg';
import checkboxCheckedIcon from './checkbox-checked.svg';
import checkboxUncheckedIcon from './checkbox-unchecked.svg';
import checkedDocuments from './checkedDocuments.svg';
import checkCircle from './checkcircle.svg';
import chevronDoubleRight from './chevron-double-right.svg';
import chevronIcon from './chevron.svg';
import chevronIconV2 from './chevronV2.svg';
import cloudIcon from './cloud.svg';
import playVideo from './playVideo.svg';
import caption from './caption.svg';
import collaborationIcon from './collaboration.svg';
import compareScaleIcon from './compareScale.svg';
import contentIcon from './content.svg';
import contentCurrentIcon from './contentCurrent.svg';
import conversionIcon from './conversion.svg';
import crossIcon from './cross.svg';
import deleteIcon from './delete.svg';
import Solid from './discover-table-refresh.svg';
import documentIcon from './document.svg';
import doubleArrow from './DoubleArrow.svg';
import downloadIcon from './download.svg';
import downloadIconBlue from './downloadBlue.svg';
import downloadCancel from './downloadCancel.svg';
import downloadListIcon from './downloadList.svg';
import downloadRemove from './downloadRemove.svg';
import duplicateIcon from './duplicate.svg';
import editIcon from './edit.svg';
import ellipsesIcon from './ellipses.svg';
import entityIcon from './entityIcon.svg';
import emptyAdjustmentBuyBox from './emptyAdjustmentBuyBox.svg';
import emptyAdjustmentContentScore from './emptyAdjustmentContentScore.svg';
import emptyAdjustmentInstockRate from './emptyAdjustmentInstockRate.svg';
import emptyAdjustmentOrganicTraffic from './emptyAdjustmentOrganicTraffic.svg';
import emptyAdjustmentOtherTraffic from './emptyAdjustmentOtherTraffic.svg';
import emptyAdjustmentPaidTraffic from './emptyAdjustmentPaidTraffic.svg';
import emptyAdjustmentRatingsReviews from './emptyAdjustmentRatingsReviews.svg';
import emptyAdjustmentRetailPrice from './emptyAdjustmentRetailPrice.svg';
import exportIcon from './export.svg';
import exitIconSmall from './exitIconSmall.svg';
import folder from './folder.svg';
import fastBackwardSingle from './fast-backward-single.svg';
import fastForwardSingle from './fast-forward-single.svg';
import fileStorage from './fileStorage.svg';
import complianceIcon from './compliance.svg';
import exportCSV from './exportCSV.svg';
import exportPNG from './exportPNG.svg';
import FastBackward from './fast-backward.svg';
import FastForward from './fast-forward.svg';
import forwardDoubleArrow from './forwardDoubleArrow.svg';
import gearIcon from './gear.svg';
import geoBackArrowGrey from './geo-back-arrow-gray.svg';
import geoBackArrow from './geo-back-arrow.svg';
import globeIcon from './globe.svg';
import homeIcon from './home.svg';
import inventoryIcon from './inventory.svg';
import keyIcon from './key.svg';
import clipboardChecklist from './clipboardChecklist.svg';
import packageCheckmark from './packageCheckmark.svg';
import documentChecklist from './documentChecklist.svg';
import packageDolly from './packageDolly.svg';
import documentCornerFold from './documentCornerFold.svg';
import documentSubmitted from './documentSubmitted.svg';
import speechBubbleCheckmark from './speechBubbleCheckmark.svg';
import clipboardCheckmark from './clipboardCheckmark.svg';
import loadingGearIcon from './loading_gear.svg';
import logoAdManager from './logo-admanager.svg';
import logoAtlas from './logo-atlas.svg';
import logoBeacon from './logo-beacon.svg';
import logoOmni from './logo-omni.svg';
import logoDiscover from './logo-discover.svg';
import logoutIcon from './logout.svg';
import logoWhite from './logo_white.svg';
import mapPriceIcon from './map-price-icon.svg';
import menuPlusIcon from './menu-plus.svg';
import menuIcon from './menu.svg';
import moreInfo from './moreInfo.svg';
import notificationsIcon from './notifications.svg';
import newBeaconExportIcon from './newBeaconExportIcon.svg';
import newBeaconExportIconDisabled from './newBeaconExportIconDisabled.svg';
import newBeaconExportIconWhite from './newBeaconExportWhite.svg';
import noneFoundIcon from './noneFoundIcon.svg';
import omniArrowChangeRed from './omni-arrow-change-red.svg';
import omniArrowChange from './omni-Arrow-change.svg';
import openInNewIcon from './open_in_new.svg';
import orgChart from './org-chart.svg';
import passwordIcon from './password.svg';
import passwordResetSuccessIcon from './passwordResetSuccess.svg';
import pathwaysSponsoredBrand from './pathways_sponsored_brands.svg';
import pathwaysSponsoredBrandOutlined from './pathways_sponsored_brands_outlined.svg';
import pathwaysDisplayAds from './pathways_display_ads.svg';
import pathwaysDisplayAdsOutlined from './pathways_display_ads_outlined.svg';
import pathwaysSponsoredProducts from './pathways_sponsored_products.svg';
import pathwaysSponsoredProductsOutlined from './pathways_sponsored_products_outlined.svg';
import pathwaysSponsoredDisplay from './pathways_sponsored_display.svg';
import pathwaysSponsoredDisplayOutlined from './pathways_sponsored_display_outlined.svg';
import pathwaysLegendIcon from './pathways_legend_icon.svg';
import piechartIcon from './piechart.svg';
import plusIcon from './plus.svg';
import equal from './equal.svg';
import updatedPlusIcon from './updated-plus.svg';
import portfolioIcon from './Portfolio_Icon.svg';
import retailPriceIcon from './prices.svg';
import promotionsIcon from './promotions.svg';
import radioButtonCheckedIcon from './radio-button-checked.svg';
import radioButtonUncheckedIcon from './radio-button-unchecked.svg';
import regionsIcon from './regions.svg';
import returnsIcon from './returns.svg';
import reviewsIcon from './reviews.svg';
import rocketshipIcon from './rocketship.svg';
import retailSalesIcon from './sales.svg';
import headerSearchIcon from './search.svg';
import shirtIcon from './shirt.svg';
import shopBagIcon from './shop-bag.svg';
import shopcartIcon from './shopcart.svg';
import singleArrow from './SingleArrow.svg';
import stacklineLogoBlue from './stackline-logo-blue.svg';
import stacklineLogoGrey from './stackline-logo-gray.svg';
import stacklineLogoWhite from './stackline-logo-white.svg';
import stacklineLogo from './stackline-logo.svg';
import successCheckIcon from './successCheckIcon.svg';
import smallCancelIcon from './smallCancelIcon.svg';
import smallCancelCircleFilledWhite from './smallCancelCircleFilledWhite.svg';
import smallCheckCircle from './smallCheckCircle.svg';
import navBrandIcon from './stamp-brand.svg';
import storeIcon from './stores.svg';
import subCategoryIcon from './subcategory.svg';
import summaryIcon from './summary.svg';
import tooltipIcon from './tooltipIcon.svg';
import threeBarsIcon from './three-bars-icon.svg';
import trafficIcon from './traffic.svg';
import trashcanIcon from './trashcan.svg';
import upArrowIcon from './upArrow.svg';
import userIcon from './user.svg';
import verifiedIcon from './verified-login.svg';
import videoIcon from './video.svg';
import viewGridIcon from './view-grid.svg';
import viewRowIcon from './view-row.svg';
import viewTileIcon from './view-tile.svg';
import selectIncludeCheckbox from './select-include-checkbox.svg';
import selectedDisabledCheckbox from './selectedDisabledCheckboxIcon.svg';
import selectExcludeCheckbox from './select-exclude-checkbox.svg';
import selectedExcludeCheckbox from './selected-exclude-checkbox.svg';
import selectedIncludeCheckbox from './selected-include-checkbox.svg';
import unselectedCheckbox from './unselected-checkbox.svg';
import omniCloseIcon from './omniClose.svg';
import closeIcon from './closeIcon.svg';
import uploadFile from './upload_file.svg';
import acIcon from './acIcon.svg';
import downChevron from './downChevron.svg';
import downChevronBold from './downChevronBold.svg';
import downChevronWhite from './downChevronWhite.svg';
import rightChevron from './rightChevron.svg';
import rightArrow from './rightArrow.svg';
import beaconProEllipses from './beaconProEllipses.svg';
import snackbarCancel from './snackbarCancel.svg';
import snackbarImportant from './snackbarImportant.svg';
import snackbarCheckmark from './snackbarCheckmark.svg';
import snackbarCheckmarkPrimary from './snackbarCheckmarkPrimary.svg';

export interface SvgElementProps extends React.SVGAttributes<unknown> {
  className?: string;
  dataFor?: string;
  dataTip?: string;
  style?: React.CSSProperties;
  useImageUrl?: boolean;
}
const svgElement = (
  { className, dataFor, dataTip, useImageUrl, style, ...rest }: SvgElementProps,
  icon: SVGAElement & { viewBox: string }
) => {
  const appName = {
    [AppName.Advertising]: 'ad',
    [AppName.Atlas]: 'atlas',
    [AppName.Beacon]: 'beacon',
    [AppName.Omni]: 'omni',
    [AppName.Discover]: 'discover'
  }[getAppName()];
  const imageUrl = `https://${appName}.stackline.com/assets/${icon.id}.svg`;
  return useImageUrl ? (
    // used only in admanager trend chart legend
    // workaround for displaying svg in legend which is being overridden by highcharts
    <img src={imageUrl} alt={icon.id} style={{ width: 17, margin: '0 2px' }} />
  ) : (
    <svg {...rest} data-for={dataFor} data-tip={dataTip} viewBox={icon.viewBox} className={className} style={style}>
      <use xlinkHref={`#${icon.id}`} />
    </svg>
  );
};

const AddIcon = (props: SvgElementProps) => svgElement(props, addIcon);
const PlayVideo = (props: SvgElementProps) => svgElement(props, playVideo);
const CaptionIcon = (props: SvgElementProps) => svgElement(props, caption);
const AdManagerLogo = (props: SvgElementProps) => svgElement(props, logoAdManager);
const AmcAudiences = (props: SvgElementProps) => svgElement(props, amcAudiences);
const AmcCustomize = (props: SvgElementProps) => svgElement(props, amcCustomize);
const AmcNewToBrand = (props: SvgElementProps) => svgElement(props, amcNewToBrand);
const AmcPathways = (props: SvgElementProps) => svgElement(props, amcPathways);
const AmcRegions = (props: SvgElementProps) => svgElement(props, amcRegions);
const AmcTouchpoints = (props: SvgElementProps) => svgElement(props, amcTouchpoints);
const AmcNoData = (props: SvgElementProps) => svgElement(props, amcNoData);
const ArrowGrey = (props: SvgElementProps) => svgElement(props, arrowGrey);
const ArrowDownGreen = (props: SvgElementProps) => svgElement(props, arrowDownGreen);
const ArrowDownRed = (props: SvgElementProps) => svgElement(props, arrowDownRed);
const ArrowUpGreen = (props: SvgElementProps) => svgElement(props, arrowUpGreen);
const ArrowUpRed = (props: SvgElementProps) => svgElement(props, arrowUpRed);
const AdvertisingIcon = (props: SvgElementProps) => svgElement(props, advertisingIcon);
const AssetLibrary = (props: SvgElementProps) => svgElement(props, assetLibrary);
const AtlasLogo = (props: SvgElementProps) => svgElement(props, logoAtlas);
const OmniLogo = (props: SvgElementProps) => svgElement(props, logoOmni);
const DiscoverLogo = (props: SvgElementProps) => svgElement(props, logoDiscover);
const AttachmentIcon = (props: SvgElementProps) => svgElement(props, attachmentIcon);
const BeaconLogo = (props: SvgElementProps) => svgElement(props, logoBeacon);
const BeaconProEllipses = (props: SvgElementProps) => svgElement(props, beaconProEllipses);
const BeaconProImageFallback = (props: SvgElementProps) => svgElement(props, beaconProImageFallback);
const BlankDocumentsIcon = (props: SvgElementProps) => svgElement(props, blankDocuments);
const BlueCancel = (props: SvgElementProps) => svgElement(props, blueCancel);
const UpdatedBeaconLogo = (props: SvgElementProps) => svgElement(props, updatedBeaconLogo);
const BillingIcon = (props: SvgElementProps) => svgElement(props, billingIcon);
const BillingWhiteIcon = (props: SvgElementProps) => svgElement(props, billingWhiteIcon);
const BrandIcon = (props: SvgElementProps) => svgElement(props, brandIcon);
const BrowserIcon = (props: SvgElementProps) => svgElement(props, browserIcon);
const BuyBoxIcon = (props: SvgElementProps) => svgElement(props, buyboxIcon);
const BluePlus = (props: SvgElementProps) => svgElement(props, bluePlus);
const CalendarIcon = (props: SvgElementProps) => svgElement(props, calendarIcon);
const CalendarV2Icon = (props: SvgElementProps) => svgElement(props, calendarV2Icon);
const CategoryIcon = (props: SvgElementProps) => svgElement(props, categoryIcon);
const CheckboxCheckedIcon = (props: SvgElementProps) => svgElement(props, checkboxCheckedIcon);
const CheckboxUncheckedIcon = (props: SvgElementProps) => svgElement(props, checkboxUncheckedIcon);
const CheckedDocumentsIcon = (props: SvgElementProps) => svgElement(props, checkedDocuments);
const ChevronIcon = (props: SvgElementProps) => svgElement(props, chevronIcon);
// This Chevron Icon is thicker than original ChevronICon. We use this for Beacon Redesign
const ChevronIconV2 = (props: SvgElementProps) => svgElement(props, chevronIconV2);
const ChevronDoubleRight = (props: SvgElementProps) => svgElement(props, chevronDoubleRight);
const CheckCircle = (props: SvgElementProps) => svgElement(props, checkCircle);
const CloudIcon = (props: SvgElementProps) => svgElement(props, cloudIcon);
const CampaignBuilder = (props: SvgElementProps) => svgElement(props, campaignBuilder);
const CollaborationIcon = (props: SvgElementProps) => svgElement(props, collaborationIcon);
const CampaignsIcon = (props: SvgElementProps) => svgElement(props, campaignsIcon);
const ClearFilters = (props: SvgElementProps) => svgElement(props, clearFilters);
const ComplianceIcon = (props: SvgElementProps) => svgElement(props, complianceIcon);
const ContentCurrentIcon = (props: SvgElementProps) => svgElement(props, contentCurrentIcon);
const ContentIcon = (props: SvgElementProps) => svgElement(props, contentIcon);
const ConversionIcon = (props: SvgElementProps) => svgElement(props, conversionIcon);
const CompareScaleIcon = (props: SvgElementProps) => svgElement(props, compareScaleIcon);
const OmniCloseIcon = (props: SvgElementProps) => svgElement(props, omniCloseIcon);
const CrossIcon = (props: SvgElementProps) => svgElement(props, crossIcon);
const DeleteIcon = (props: SvgElementProps) => svgElement(props, deleteIcon);
const DocumentIcon = (props: SvgElementProps) => svgElement(props, documentIcon);
const DoubleArrow = (props: SvgElementProps) => svgElement(props, doubleArrow);
const DownloadIcon = (props: SvgElementProps) => svgElement(props, downloadIcon);
const DownloadIconBlue = (props: SvgElementProps) => svgElement(props, downloadIconBlue);
const DownloadListIcon = (props: SvgElementProps) => svgElement(props, downloadListIcon);
const DownloadCancel = (props: SvgElementProps) => svgElement(props, downloadCancel);
const DownloadRemove = (props: SvgElementProps) => svgElement(props, downloadRemove);
const DuplicateIcon = (props: SvgElementProps) => svgElement(props, duplicateIcon);
const DiscoverIcon = (props: SvgElementProps) => svgElement(props, discoverIcon);
const EditIcon = (props: SvgElementProps) => svgElement(props, editIcon);
const EllipsesIcon = (props: SvgElementProps) => svgElement(props, ellipsesIcon);
const EmptyAdjustmentBuyBox = (props: SvgElementProps) => svgElement(props, emptyAdjustmentBuyBox);
const EmptyAdjustmentContentScore = (props: SvgElementProps) => svgElement(props, emptyAdjustmentContentScore);
const EmptyAdjustmentInstockRate = (props: SvgElementProps) => svgElement(props, emptyAdjustmentInstockRate);
const EmptyAdjustmentOrganicTraffic = (props: SvgElementProps) => svgElement(props, emptyAdjustmentOrganicTraffic);
const EmptyAdjustmentOtherTraffic = (props: SvgElementProps) => svgElement(props, emptyAdjustmentOtherTraffic);
const EmptyAdjustmentPaidTraffic = (props: SvgElementProps) => svgElement(props, emptyAdjustmentPaidTraffic);
const EmptyAdjustmentRatingsReviews = (props: SvgElementProps) => svgElement(props, emptyAdjustmentRatingsReviews);
const EmptyAdjustmentRetailPrice = (props: SvgElementProps) => svgElement(props, emptyAdjustmentRetailPrice);
const Error404Icon = (props: SvgElementProps) => svgElement(props, error404Icon);
const EntityIcon = (props: SvgElementProps) => svgElement(props, entityIcon);
const ExportIcon = (props: SvgElementProps) => svgElement(props, exportIcon);
const ExitIconSmall = (props: SvgElementProps) => svgElement(props, exitIconSmall);
const ExportCSV = (props: SvgElementProps) => svgElement(props, exportCSV);
const ExportPNG = (props: SvgElementProps) => svgElement(props, exportPNG);
const FilterIcon = (props: SvgElementProps) => svgElement(props, filterIcon);
const FileStorageIcon = (props: SvgElementProps) => svgElement(props, fileStorage);
const FastForwardIcon = (props: SvgElementProps) => svgElement(props, FastForward);
const FastBackwardIcon = (props: SvgElementProps) => svgElement(props, FastBackward);
const FastBackwardSingleIcon = (props: SvgElementProps) => svgElement(props, fastBackwardSingle);
const FastForwardSingleIcon = (props: SvgElementProps) => svgElement(props, fastForwardSingle);
const GearIcon = (props: SvgElementProps) => svgElement(props, gearIcon);
const GlobeIcon = (props: SvgElementProps) => svgElement(props, globeIcon);
const HeaderSearchIcon = (props: SvgElementProps) => svgElement(props, headerSearchIcon);
const HomeIcon = (props: SvgElementProps) => svgElement(props, homeIcon);
const TakeHomeIcon = (props: SvgElementProps) => svgElement(props, Solid);
const InventoryIcon = (props: SvgElementProps) => svgElement(props, inventoryIcon);
const ClipboardChecklist = (props: SvgElementProps) => svgElement(props, clipboardChecklist);
const PackageCheckmark = (props: SvgElementProps) => svgElement(props, packageCheckmark);
const DocumentChecklist = (props: SvgElementProps) => svgElement(props, documentChecklist);
const PackageDolly = (props: SvgElementProps) => svgElement(props, packageDolly);
const DocumentCornerFold = (props: SvgElementProps) => svgElement(props, documentCornerFold);
const DocumentSubmitted = (props: SvgElementProps) => svgElement(props, documentSubmitted);
const SpeechBubbleCheckmark = (props: SvgElementProps) => svgElement(props, speechBubbleCheckmark);
const ClipboardCheckmark = (props: SvgElementProps) => svgElement(props, clipboardCheckmark);
const LoadingGearIcon = (props: SvgElementProps) => svgElement(props, loadingGearIcon);
const LogoutIcon = (props: SvgElementProps) => svgElement(props, logoutIcon);
const LogoWhite = (props: SvgElementProps) => svgElement(props, logoWhite);
const MapPriceIcon = (props: SvgElementProps) => svgElement(props, mapPriceIcon);
const MoreInfo = (props: SvgElementProps) => svgElement(props, moreInfo);
const MenuIcon = (props: SvgElementProps) => svgElement(props, menuIcon);
const MenuPlusIcon = (props: SvgElementProps) => svgElement(props, menuPlusIcon);
const NavBrandIcon = (props: SvgElementProps) => svgElement(props, navBrandIcon);
const NewBeaconExportIcon = (props: SvgElementProps) => svgElement(props, newBeaconExportIcon);
const NewBeaconExportIconDisabled = (props: SvgElementProps) => svgElement(props, newBeaconExportIconDisabled);
const NewBeaconExportIconWhite = (props: SvgElementProps) => svgElement(props, newBeaconExportIconWhite);
const NavCategoryIcon = (props: SvgElementProps) => svgElement(props, folder);
const NotificationsIcon = (props: SvgElementProps) => svgElement(props, notificationsIcon);
const NoneFoundIcon = (props: SvgElementProps) => svgElement(props, noneFoundIcon);
const OrgChartIcon = (props: SvgElementProps) => svgElement(props, orgChart);
const OpenInNewIcon = (props: SvgElementProps) => svgElement(props, openInNewIcon);
const PasswordIcon = (props: SvgElementProps) => svgElement(props, passwordIcon);
const PasswordResetSuccessIcon = (props: SvgElementProps) => svgElement(props, passwordResetSuccessIcon);
const PathwaysSponsoredBrand = (props: SvgElementProps) => svgElement(props, pathwaysSponsoredBrand);
const PathwaysSponsoredBrandOutlined = (props: SvgElementProps) => svgElement(props, pathwaysSponsoredBrandOutlined);
const PathwaysSponsoredDisplayAds = (props: SvgElementProps) => svgElement(props, pathwaysDisplayAds);
const PathwaysSponsoredDisplayAdsOutlined = (props: SvgElementProps) => svgElement(props, pathwaysDisplayAdsOutlined);
const PathwaysSponsoredProducts = (props: SvgElementProps) => svgElement(props, pathwaysSponsoredProducts);
const PathwaysSponsoredProductsOutlined = (props: SvgElementProps) =>
  svgElement(props, pathwaysSponsoredProductsOutlined);
const PathwaysSponsoredDisplay = (props: SvgElementProps) => svgElement(props, pathwaysSponsoredDisplay);
const PathwaysSponsoredDisplayOutlined = (props: SvgElementProps) =>
  svgElement(props, pathwaysSponsoredDisplayOutlined);
const PathwaysLegendIcon = (props: SvgElementProps) => svgElement(props, pathwaysLegendIcon);
const PortfolioIcon = (props: SvgElementProps) => svgElement(props, portfolioIcon);
const PlusIcon = (props: SvgElementProps) => svgElement(props, plusIcon);
const EqualIcon = (props: SvgElementProps) => svgElement(props, equal);
const UpdatedPlusIcon = (props: SvgElementProps) => svgElement(props, updatedPlusIcon);
const ProductIcon = (props: SvgElementProps) => svgElement(props, shirtIcon);
const PromotionsIcon = (props: SvgElementProps) => svgElement(props, promotionsIcon);
const RadioButtonUncheckedIcon = (props: SvgElementProps) => svgElement(props, radioButtonUncheckedIcon);
const RegionsIcon = (props: SvgElementProps) => svgElement(props, regionsIcon);
const RetailPriceIcon = (props: SvgElementProps) => svgElement(props, retailPriceIcon);
const RetailSalesIcon = (props: SvgElementProps) => svgElement(props, retailSalesIcon);
const ReturnsIcon = (props: SvgElementProps) => svgElement(props, returnsIcon);
const ReviewsIcon = (props: SvgElementProps) => svgElement(props, reviewsIcon);
const RightArrow = (props: SvgElementProps) => svgElement(props, rightArrow);
const RocketshipIcon = (props: SvgElementProps) => svgElement(props, rocketshipIcon);
const RadioButtonCheckedIcon = (props: SvgElementProps) => svgElement(props, radioButtonCheckedIcon);
const SegmentIcon = (props: SvgElementProps) => svgElement(props, piechartIcon);
const SearchTermListIcon = (props: SvgElementProps) => svgElement(props, keyIcon);
const ShopcartIcon = (props: SvgElementProps) => svgElement(props, shopcartIcon);
const ShopBagIcon = (props: SvgElementProps) => svgElement(props, shopBagIcon);
const SingleArrow = (props: SvgElementProps) => svgElement(props, singleArrow);
const SurfaceIcon = (props: SvgElementProps) => svgElement(props, surfaceIcon);
const SuccessCheckIcon = (props: SvgElementProps) => svgElement(props, successCheckIcon);
const StacklineLogoBlueIcon = (props: SvgElementProps) => svgElement(props, stacklineLogoBlue);
const StacklineLogoGreyIcon = (props: SvgElementProps) => svgElement(props, stacklineLogoGrey);
const StacklineLogoWhiteIcon = (props: SvgElementProps) => svgElement(props, stacklineLogoWhite);
const StacklineLogoIcon = (props: SvgElementProps) => svgElement(props, stacklineLogo);
const StoresIcon = (props: SvgElementProps) => svgElement(props, storeIcon);
const SummaryIcon = (props: SvgElementProps) => svgElement(props, summaryIcon);
const SmallCancelIcon = (props: SvgElementProps) => svgElement(props, smallCancelIcon);
const SmallCancelCircleFilledWhite = (props: SvgElementProps) => svgElement(props, smallCancelCircleFilledWhite);
const SmallCheckCircleIcon = (props: SvgElementProps) => svgElement(props, smallCheckCircle);
const SubCategoryIcon = (props: SvgElementProps) => svgElement(props, subCategoryIcon);
const ThreeBarsIcon = (props: SvgElementProps) => svgElement(props, threeBarsIcon);
const TooltipIcon = (props: SvgElementProps) => svgElement(props, tooltipIcon);
const TrafficIcon = (props: SvgElementProps) => svgElement(props, trafficIcon);
const TrashcanIcon = (props: SvgElementProps) => svgElement(props, trashcanIcon);
const TutorialIcon = (props: SvgElementProps) => svgElement(props, videoIcon);
const UpArrowIcon = (props: SvgElementProps) => svgElement(props, upArrowIcon);
const CloseIcon = (props: SvgElementProps) => svgElement(props, closeIcon);
const UploadFileIcon = (props: SvgElementProps) => svgElement(props, uploadFile);
const UserIcon = (props: SvgElementProps) => svgElement(props, userIcon);
const ViewRowIcon = (props: SvgElementProps) => svgElement(props, viewRowIcon);
const ViewGridIcon = (props: SvgElementProps) => svgElement(props, viewGridIcon);
const ViewTileIcon = (props: SvgElementProps) => svgElement(props, viewTileIcon);
const VerifiedIcon = (props: SvgElementProps) => svgElement(props, verifiedIcon);
const ForwardDoubleArrowIcon = (props: SvgElementProps) => svgElement(props, forwardDoubleArrow);
const BackwardDoubleArrow = (props: SvgElementProps) => svgElement(props, backwardDoubleArrow);
const PauseIcon = (props: SvgElementProps) => svgElement(props, PauseIconSvg);
const GeoBackArrow = (props: SvgElementProps) => svgElement(props, geoBackArrow);
const GeoBackArrowGray = (props: SvgElementProps) => svgElement(props, geoBackArrowGrey);
const OmniTriArrowChange = (props: SvgElementProps) => svgElement(props, omniArrowChange);
const OmniTriArrowChangeRed = (props: SvgElementProps) => svgElement(props, omniArrowChangeRed);
const SelectIncludeCheckbox = (props: SvgElementProps) => svgElement(props, selectIncludeCheckbox);
const SelectExcludeCheckbox = (props: SvgElementProps) => svgElement(props, selectExcludeCheckbox);
const SelectedIncludeCheckbox = (props: SvgElementProps) => svgElement(props, selectedIncludeCheckbox);
const SelectedDisabledCheckbox = (props: SvgElementProps) => svgElement(props, selectedDisabledCheckbox);
const SelectedExcludeCheckbox = (props: SvgElementProps) => svgElement(props, selectedExcludeCheckbox);
const SnackbarCancel = (props: SvgElementProps) => svgElement(props, snackbarCancel);
const SnackbarImportant = (props: SvgElementProps) => svgElement(props, snackbarImportant);
const SnackbarCheckmark = (props: SvgElementProps) => svgElement(props, snackbarCheckmark);
const SnackbarCheckmarkPrimary = (props: SvgElementProps) => svgElement(props, snackbarCheckmarkPrimary);
const UnselectedCheckbox = (props: SvgElementProps) => svgElement(props, unselectedCheckbox);
const AcIcon = (props: SvgElementProps) => svgElement(props, acIcon);
const DownChevron = (props: SvgElementProps) => svgElement(props, downChevron);
const DownChevronBold = (props: SvgElementProps) => svgElement(props, downChevronBold);
const RightChevron = (props: SvgElementProps) => svgElement(props, rightChevron);
const DownChevronWhite = (props: SvgElementProps) => svgElement(props, downChevronWhite);

export {
  CaptionIcon,
  AddIcon,
  PlayVideo,
  AdManagerLogo,
  AmcAudiences,
  AmcCustomize,
  AmcNewToBrand,
  AmcPathways,
  AmcRegions,
  AmcTouchpoints,
  AmcNoData,
  ArrowGrey,
  ArrowDownGreen,
  ArrowDownRed,
  ArrowUpRed,
  ArrowUpGreen,
  AdvertisingIcon,
  AttachmentIcon,
  AssetLibrary,
  AtlasLogo,
  BlankDocumentsIcon,
  BlueCancel,
  OmniLogo,
  DiscoverLogo,
  BeaconLogo,
  BeaconProEllipses,
  UpdatedBeaconLogo,
  BillingIcon,
  BillingWhiteIcon,
  BluePlus,
  BrandIcon,
  BrowserIcon,
  BeaconProImageFallback,
  BuyBoxIcon,
  CampaignBuilder,
  CalendarIcon,
  CalendarV2Icon,
  CategoryIcon,
  CheckCircle,
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  CheckedDocumentsIcon,
  ContentIcon,
  ChevronIcon,
  ChevronIconV2,
  ChevronDoubleRight,
  CrossIcon,
  ClearFilters,
  CloudIcon,
  CollaborationIcon,
  CampaignsIcon,
  ConversionIcon,
  ContentCurrentIcon,
  ComplianceIcon,
  CompareScaleIcon,
  DocumentIcon,
  DoubleArrow,
  DownChevron,
  DownloadIcon,
  DownloadIconBlue,
  DownloadListIcon,
  DownloadCancel,
  DownloadRemove,
  DuplicateIcon,
  DeleteIcon,
  EditIcon,
  EntityIcon,
  EllipsesIcon,
  EmptyAdjustmentBuyBox,
  EmptyAdjustmentContentScore,
  EmptyAdjustmentInstockRate,
  EmptyAdjustmentOrganicTraffic,
  EmptyAdjustmentOtherTraffic,
  EmptyAdjustmentPaidTraffic,
  EmptyAdjustmentRatingsReviews,
  EmptyAdjustmentRetailPrice,
  Error404Icon,
  ExportIcon,
  ExportCSV,
  ExitIconSmall,
  ExportPNG,
  FastForwardIcon,
  FastBackwardIcon,
  FastBackwardSingleIcon,
  FileStorageIcon,
  FilterIcon,
  GearIcon,
  GlobeIcon,
  HeaderSearchIcon,
  HomeIcon,
  InventoryIcon,
  ClipboardChecklist,
  PackageCheckmark,
  DocumentChecklist,
  PackageDolly,
  DocumentCornerFold,
  DocumentSubmitted,
  SpeechBubbleCheckmark,
  ClipboardCheckmark,
  LoadingGearIcon,
  LogoutIcon,
  LogoWhite,
  MapPriceIcon,
  MenuIcon,
  MenuPlusIcon,
  MoreInfo,
  NavBrandIcon,
  NavCategoryIcon,
  NewBeaconExportIcon,
  NewBeaconExportIconDisabled,
  NewBeaconExportIconWhite,
  NotificationsIcon,
  NoneFoundIcon,
  RocketshipIcon,
  OrgChartIcon,
  OpenInNewIcon,
  OmniCloseIcon,
  PasswordIcon,
  PasswordResetSuccessIcon,
  PathwaysSponsoredBrand,
  PathwaysSponsoredBrandOutlined,
  PathwaysSponsoredDisplayAds,
  PathwaysSponsoredDisplayAdsOutlined,
  PathwaysSponsoredProducts,
  PathwaysSponsoredProductsOutlined,
  PathwaysSponsoredDisplay,
  PathwaysSponsoredDisplayOutlined,
  PathwaysLegendIcon,
  PlusIcon,
  EqualIcon,
  UpdatedPlusIcon,
  ProductIcon,
  PromotionsIcon,
  PortfolioIcon,
  RadioButtonCheckedIcon,
  RadioButtonUncheckedIcon,
  RegionsIcon,
  RetailPriceIcon,
  RetailSalesIcon,
  ReturnsIcon,
  ReviewsIcon,
  RightChevron,
  RightArrow,
  SegmentIcon,
  SearchTermListIcon,
  ShopcartIcon,
  SmallCancelIcon,
  SmallCancelCircleFilledWhite,
  SmallCheckCircleIcon,
  ShopBagIcon,
  SingleArrow,
  FastForwardSingleIcon,
  StacklineLogoBlueIcon,
  StacklineLogoGreyIcon,
  StacklineLogoIcon,
  StacklineLogoWhiteIcon,
  StoresIcon,
  SummaryIcon,
  SubCategoryIcon,
  SuccessCheckIcon,
  SnackbarCancel,
  SnackbarImportant,
  SnackbarCheckmark,
  SnackbarCheckmarkPrimary,
  ThreeBarsIcon,
  TooltipIcon,
  TrafficIcon,
  TrashcanIcon,
  TutorialIcon,
  TakeHomeIcon,
  UpArrowIcon,
  UserIcon,
  ViewRowIcon,
  ViewGridIcon,
  ViewTileIcon,
  VerifiedIcon,
  ForwardDoubleArrowIcon,
  BackwardDoubleArrow,
  PauseIcon,
  GeoBackArrow,
  GeoBackArrowGray,
  OmniTriArrowChange,
  OmniTriArrowChangeRed,
  DiscoverIcon,
  SurfaceIcon,
  SelectIncludeCheckbox,
  SelectedDisabledCheckbox,
  SelectExcludeCheckbox,
  SelectedIncludeCheckbox,
  SelectedExcludeCheckbox,
  UnselectedCheckbox,
  CloseIcon,
  UploadFileIcon,
  AcIcon,
  DownChevronBold,
  DownChevronWhite
};
