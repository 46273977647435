import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import { useAppSelector } from 'src/utils/Hooks';

// This is a special case when we are in the paid traffic page within the topics tab
// We are getting segments data from redux, and the selected segment is having a query value from backend -- globally used.
// we need to parse the query value and pass it to a newly created requests for any segments data.
// The query from backend has 'stf' (searchTermFuzzy) instead of 'skf'(searchKeywordFuzzy) as a key - which is expected because only paid traffic will need 'skf as a query'.
// so before we make a request for any paid traffic data, we will have to replace the fieldName from searchTermFuzzy to searchKeywordFuzzy otherwise it will return empty array
// here is the mapping - https://gitlab.com/stackline-dev/website/-/blob/0fcf6caa456d38872d866bf2b5847f12dfc9ade9/src/utils/segments.ts#L33

export const useConvertPaidTrafficFieldName = () => {
  const entityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const entityId = useAppSelector((state) => state.entityService.mainEntity.id);
  const segments = useAppSelector((state) => {
    const { mySegments, teamSegments, teamSearchTermLists, mySearchTermLists } = state.segments;
    return [...mySegments, ...teamSegments, ...teamSearchTermLists, ...mySearchTermLists];
  });

  const convertedBuilderFunc = (builder: AdvancedSearchRequestBuilder) => {
    if (entityType === 'searchtermlist') {
      const segment = segments.find((seg) => seg.id === entityId);
      if (segment) {
        if (segment.conditions.termFilters) {
          const searchTermFuzzy = segment.conditions.termFilters.find(
            (filter) => filter.fieldName === 'searchTermFuzzy'
          );
          if (searchTermFuzzy) {
            return builder
              .removeConditionTermFilters((termFilter) => termFilter.fieldName === 'searchTermFuzzy')
              .addConditionTermFilter('searchKeywordFuzzy', 'should', searchTermFuzzy.values);
          }
        }
      }
    }
    return builder;
  };

  return { convertedBuilderFunc };
};
