import React from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import TextField from '@mui/material/TextField';
import ChipsContainer from './ChipsContainer';
import { withBus } from 'react-bus';
import _cloneDeep from 'lodash/cloneDeep';
import { QUICK_EXCLUDE_EVENT } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Segments/SegmentBuilder';

const searchParameterLimit = 500;

class KeywordsSearch extends React.Component {
  static propTypes = {
    idFieldName: PropTypes.string.isRequired,
    headerDisplayName: PropTypes.string.isRequired,
    searchFieldHintText: PropTypes.string.isRequired,
    keywords: PropTypes.array,
    onKeywordsChange: PropTypes.func.isRequired,
    entityDefinition: PropTypes.object.isRequired,
    appName: PropTypes.string,
    renderClearButton: PropTypes.func,
    eventBus: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired
  };

  static defaultProps = {
    keywords: [],
    renderClearButton: () => {}
  };

  // Receives the retailer SKU from the quick-exclude button event
  // We hardcode the key in onKeyWordsChange to ensure we don't mutate the 'Include' input state
  handleExcludeCard(retailerSku) {
    if (this.props.idFieldName === 'excludedKeyword') {
      const { keywordChips } = this.state;
      const chipsCopy = _cloneDeep(keywordChips);
      if (retailerSku) {
        chipsCopy.push({ key: keywordChips.length, i: retailerSku });
      }

      this.setState({ keywordChips: chipsCopy });
      const { onKeywordsChange } = this.props;
      onKeywordsChange({ key: 'excludedKeyword', values: chipsCopy.map((keyword) => ({ i: keyword.i })) });
    }
  }

  componentDidMount() {
    this.props.eventBus.on(QUICK_EXCLUDE_EVENT, this.handleExcludeCard.bind(this));
  }

  componentWillMount() {
    this.updateKeywords();
  }

  componentWillUnmount() {
    this.props.eventBus.off(QUICK_EXCLUDE_EVENT, this.handleExcludeCard);
  }

  componentWillReceiveProps(nextProps) {
    if (!_isEqual(nextProps.keywords, this.props.keywords)) {
      this.updateKeywords(nextProps);
    }
  }

  updateKeywords = (nextProps) => {
    const { keywords } = nextProps || this.props;
    this.setState({ keywordChips: keywords.map((keyword, index) => ({ key: index, i: keyword.i })) });
    this.clearInput();
  };

  clearInput = () => this.setState({ keyword: '' });

  handleInputChange = (event) => {
    this.setState({ keyword: event.target.value });
  };

  handleKeyPress = (event) => {
    const { keywordChips } = this.state;
    if (event.key === 'Enter' && event.target.value !== '') {
      keywordChips.push({ key: keywordChips.length, i: event.target.value });
      this.clearInput();
      this.setState({ keywordChips });
      this.handleKeywordsChange(keywordChips);
    }
  };

  handleRequestDelete = (key) => {
    const { keywordChips } = this.state;
    const chipToDelete = keywordChips.map((chip) => chip.key).indexOf(key);
    keywordChips.splice(chipToDelete, 1);
    this.setState({ keywordChips: keywordChips.map((chip, index) => ({ key: index, i: chip.i })) });
    this.handleKeywordsChange(keywordChips);
  };

  handleKeywordsChange = (keywordChips) => {
    const { onKeywordsChange, idFieldName } = this.props;
    onKeywordsChange({ key: idFieldName, values: keywordChips.map((keyword) => ({ i: keyword.i })) });
  };

  handleClear = (e) => {
    e.preventDefault();
    const { onKeywordsChange, idFieldName } = this.props;
    onKeywordsChange({ key: idFieldName, values: [] });
  };

  render = () => (
    <div className={`${this.props.appName ? `${this.props.appName}-` : ''}search-form-container`}>
      <div style={{ display: 'flex', alignItems: 'flex-end', gap: '1em' }}>
        <h4 className="sl-form-label">{this.props.headerDisplayName}</h4>
        {['atlas', 'beacon'].includes(this.props.appName || this.props.app.name || '') ? (
          <button className="clear-btn" onClick={(e) => this.handleClear(e)}>
            Clear
          </button>
        ) : null}
        {this.props.keywords.length > searchParameterLimit && (
          <h4 className="sl-form-label" style={{ color: 'red' }}>
            Maximum number of terms is {searchParameterLimit}
          </h4>
        )}
        {this.props.renderClearButton()}
      </div>

      <TextField
        variant="standard"
        autoComplete="off"
        className="sl-form-input"
        placeholder={this.props.searchFieldHintText}
        type="text"
        name="keyword"
        id="keyword"
        disabled={this.props.keywords.length > searchParameterLimit}
        style={{ width: '100%' }}
        value={this.state.keyword}
        onChange={this.handleInputChange}
        onKeyPress={this.handleKeyPress}
      />
      <ChipsContainer
        headerDisplayName={this.props.headerDisplayName}
        onDelete={(key) => this.handleRequestDelete(key)}
        chipData={this.state.keywordChips}
      />
    </div>
  );
}

export default withBus('eventBus')(KeywordsSearch);
