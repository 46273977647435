import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';

import Creators from './actions';
import ReduxStore from 'src/types/store/reduxStore';

const { receiveOmniSubcategories } = Creators;

export const fetchOmniSubcategories = () => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
  const response = (await axios.get(`/omni/users/fetchUserMetadata`)) as any;
  if (response && response.status === 200) {
    const { subCategories } = response.data;
    dispatch(receiveOmniSubcategories(subCategories));
  }
};
