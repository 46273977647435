import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import queryString from 'qs';
import React from 'react';
import { withBus } from 'react-bus';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { mergeConditions } from 'sl-api-connector/search/conditions';
import { buildAggregations } from 'src/components/AdManager/Search';
import GenericChart from 'src/components/Charts/GenericChart';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import BaseTrendChart from 'src/components/EntityPage/TrendChart/BaseTrendChart';
import MarketShareTooltip from 'src/components/Layout/util/MarketShareTooltip';
import { store } from 'src/main';
import { updateQueryParams } from 'src/store/modules/app/operations';
import entitySearchServiceActions from 'src/store/modules/entitySearchService/actions';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { removeEntityWithKey } from 'src/store/modules/entityService/operations';
import { replaceConflictingConditions } from 'src/utils/conditions';
import { INDEX_FIELDS, METRICTYPE } from 'src/utils/entityDefinitions';
import { anyNotEq } from 'src/utils/equality';
import { getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import saveAs from 'file-saver';
import convertLineChartSeriesToDelimitedData from 'src/components/Charts/GenericChart/SeriesConverters/lineChart';

import {
  buildAggregationConditions,
  buildComparisonEntityConditions,
  buildDefaultConditions,
  buildEntityConditions,
  buildEntityMarketShareConditions as buildEntityMarketShareDenominatorConditions,
  buildEntityMarketShareNumeratorConditions,
  buildMainEntityConditions
} from '../Renderer/EntityPageRenderer';
import { getTimeSeriesTrendChartParameters } from '../Renderer/TimeSeriesTrendChart';
import { getWeeklyTrendChartParameters } from '../Renderer/WeeklyTrendChart';
import { getTrendChartInfo } from '../summaryTrendUtil';
import { getTrendChartInfo as getShortageDisputeTrendChartInfo } from 'src/components/EntityPage/TrendChart/omniTrendChartUtils';
import { getMultiMetricWeeklyTrendChartParameters } from 'src/components/ShortageDisputes/components/ShortageSummary/shortageSummaryUtils';
import { SHORTAGES_FIELDS } from 'src/utils/entityDefinitions/fields/beaconShortagesFieldDefinitions';
import { shouldShowNewBeacon, formatMetric, shouldShowCriteo, isCriteo } from 'src/utils/app';
import BeaconChartWithLegend from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import SplineChart from 'src/components/BeaconRedesignComponents/SplineChart/SplineChart';
import {
  getESBodyOverridesForParentPlatform,
  modifyESQuery
} from 'src/components/AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';
import { getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';

export class TrendChartInner extends BaseTrendChart {
  getGroupByFields = (widget) => (widget || this.props.widget).data.groupByFields;

  getAggregationFields(groupByField, props = this.props) {
    return props.widget.data.configByGroupByFieldName[groupByField.name].aggregationFields;
  }

  getMetricFields(props = this.props) {
    return props.widget.view.metricFields;
  }

  /**
   * Returns the entity for which to construct conditions to execute the query.  Defaults to the main entity, but can be
   * overridden in extending classes.
   */
  getQueryEntity(props = this.props) {
    return props.widget.data.queryEntity || props.entityService.mainEntity;
  }

  componentDidMount() {
    super.componentDidMount.call(this);
    this.props.eventBus.on('clearComparison', this.handleClearComparison);
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps.call(this, nextProps);
    if (this.isFetching) {
      return;
    }

    if (
      !_isEqual(this.getQueryEntity(this.props), this.getQueryEntity(nextProps)) ||
      anyNotEq(['mainTimePeriod', 'comparisonTimePeriod', 'categoriesByRetailerId'], this.props, nextProps)
    ) {
      this.fetchData(nextProps);
    }
  }

  fetchData = (props = this.props) => {
    this.isFetching = true;
    const {
      app,
      aggregationConditions: propAggregationConditions,
      retailer,
      categories,
      subCategories,
      segments,
      queryParams,
      allWeekIdsByRetailerId,
      mainTimePeriod,
      comparisonTimePeriod,
      conditions,
      match,
      comparisonConfig,
      entityService,
      fetchEntityMetrics,
      widget,
      filters,
      user
    } = props;
    const { comparisonEntity } = entityService;
    const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);

    // This is the entity that we will be limiting our API request down to
    const queryEntity = this.getQueryEntity(props);
    if (!queryEntity) {
      this.isFetching = false;
      return;
    }

    if (queryEntity === this.props.entityService.mainEntity) {
      const { mainEntity } = this.props.entityService;
      let { id: idFromUrl } = match.params;

      if (!idFromUrl || idFromUrl === '') {
        idFromUrl = `${mainEntity.id}`;
      }
      // the main entity has to match with what is in the current url
      if (idFromUrl !== `${mainEntity.id}` && idFromUrl !== mainEntity.hashId) {
        this.isFetching = false;
        return;
      }
    }

    this.setState({ isLoading: true });

    // We must have segments loaded in order to build conditions for segments.  If we don't have them and need them, try
    // fetching again in a bit.
    if (
      ['segment', 'searchtermlist', 'businessunit', 'businessUnit'].includes(queryEntity.type) &&
      !segments.savedSearchesById
    ) {
      setTimeout(() => this.fetchData({ ...props, segments: this.props.segments }), 100);
      return;
    }

    const isMarketShareChart = widget.data.marketShare && widget.data.marketShare.compute;

    const defaultConditions = buildDefaultConditions(conditions, queryParams);
    const entityConditions = buildEntityConditions(app, categories, defaultConditions, queryEntity);
    let mainEntityConditions = replaceConflictingConditions(
      widget.data.groupByFields[0],
      buildMainEntityConditions(entityConditions, queryEntity, app, retailer, queryParams)
    );

    if (widget.data.additionalQueryConditions) {
      mainEntityConditions = mergeConditions(mainEntityConditions, widget.data.additionalQueryConditions);
    }

    // If we are performing market share computations, it may be the case that the conditions for the request to fetch
    // metrics for the numerator (the metrics that are divided by the "market" metrics to compute market share) are differnt
    // than simply the entity metrics for the main entity.  In this case, `buildEntityMarketShareNumeratorConditions` will
    // return an alternative set of conditions to be used to perform the request for the numerator metrics.
    let hasNonStandardNumerator;
    if (isMarketShareChart) {
      const mainEntityMarketShareNumeratorConditions = buildEntityMarketShareNumeratorConditions({
        entity: queryEntity,
        app,
        filters,
        categories,
        subCategories,
        segments,
        retailer,
        user,
        mainEntityConditions
      });

      hasNonStandardNumerator = !!mainEntityMarketShareNumeratorConditions;
      mainEntityConditions = mainEntityMarketShareNumeratorConditions || mainEntityConditions;
    }

    let comparisonRetailer = _cloneDeep(retailer);
    if (queryParams.ctype === 'retailer') {
      [comparisonRetailer] = retailer.availableRetailers.filter((r) => r.id === queryParams.cid);
    }
    let comparisonEntityConditions;
    if (queryParams.ctype === 'metric' || (comparisonEntity && comparisonEntity.type === 'retailer')) {
      comparisonEntityConditions = buildComparisonEntityConditions(
        mainEntityConditions,
        comparisonEntity || queryEntity,
        app,
        retailer,
        true
      );
    } else {
      comparisonEntityConditions = buildComparisonEntityConditions(
        entityConditions,
        comparisonEntity || queryEntity,
        app,
        retailer
      );
    }

    if (isAdAuditUser) {
      // adAudit: update main entity trend chart's term filters
      // if selectedMetric is optimization
      const adAuditOptimizationMetrics = ['changeAction', 'bidChanges', 'budgetChanges'];
      const selectedMetricName = _get(widget, 'view.metricFields[0].name', '');
      if (adAuditOptimizationMetrics.includes(selectedMetricName)) {
        mainEntityConditions.termFilters.push({
          fieldName: 'changeSource',
          condition: 'must',
          values: ['external']
        });
        if (selectedMetricName === 'bidChanges') {
          mainEntityConditions.termFilters.push({
            fieldName: 'changeType',
            condition: 'must',
            values: ['bid']
          });
        } else if (selectedMetricName === 'budgetChanges') {
          mainEntityConditions.termFilters.push({
            fieldName: 'changeType',
            condition: 'must',
            values: ['budget']
          });
        }
      }
      // update comparison entity trend chart's term filters
      // if comparisonMetric is optimization
      const comparisonMetric = _get(queryParams, 'csubtab', '');
      if (adAuditOptimizationMetrics.includes(comparisonMetric)) {
        comparisonEntityConditions.termFilters.push({
          fieldName: 'changeSource',
          condition: 'must',
          values: ['external']
        });
        if (comparisonMetric === 'bidChanges') {
          comparisonEntityConditions.termFilters.push({
            fieldName: 'changeType',
            condition: 'must',
            values: ['bid']
          });
        } else if (comparisonMetric === 'budgetChanges') {
          comparisonEntityConditions.termFilters.push({
            fieldName: 'changeType',
            condition: 'must',
            values: ['budget']
          });
        }
        comparisonEntityConditions.termFilters = comparisonEntityConditions.termFilters.filter(
          (condition) => condition.fieldName !== 'isProjected'
        );
      }
    }

    const promises = [];
    const state = {
      isLoading: true,
      entitySearchServiceStateNamesMainEntity: [],
      entitySearchServiceStateNamesComparisonEntity: [],
      entitySearchServiceStateNamesMarketShare: [],
      mainMetricConfig: {},
      comparisonMetricConfig: {}
    };

    const groupByFields = this.getGroupByFields(widget);
    groupByFields.forEach((groupByField, index) => {
      const { indexName } = widget.data.configByGroupByFieldName[groupByField.name];

      const { aggregationConditions } = buildAggregationConditions(
        app,
        indexName,
        retailer,
        allWeekIdsByRetailerId,
        mainTimePeriod,
        comparisonTimePeriod,
        propAggregationConditions,
        widget.data.weekIdField,
        false
      );
      const aggregationMetricFields = this.getAggregationFields(groupByField, props);
      const aggregationItems = buildAggregations(aggregationMetricFields);
      const mainEntitySearchRequestOverrides = [];
      let derivedFields = null;
      aggregationItems.forEach((aggregationItem) => {
        const { aggregations: aggregationFields, aggregationFieldConditions } = aggregationItem;
        derivedFields = derivedFields || aggregationItem.derivations;

        if (aggregationConditions) {
          if (aggregationConditions.termFilters) {
            aggregationFieldConditions.termFilters = aggregationFieldConditions.termFilters.concat(
              aggregationConditions.termFilters
            );
          }
          if (aggregationConditions.rangeFilters) {
            aggregationFieldConditions.rangeFilters = aggregationFieldConditions.rangeFilters.concat(
              aggregationConditions.rangeFilters
            );
          }
        }

        const aggregations = {
          groupByFieldName: groupByField.name,
          aggregationFields,
          sortDirection: null,
          sortByAggregationField: null,
          conditions: replaceConflictingConditions(groupByField, aggregationFieldConditions)
        };
        const mainEntityConditionsCloned = _cloneDeep(mainEntityConditions);
        const timePeriodRangeFilters = aggregations.conditions.rangeFilters.filter((x) =>
          INDEX_FIELDS.isTimeSeriesField(x.fieldName)
        );
        if (timePeriodRangeFilters && timePeriodRangeFilters.length > 0) {
          mainEntityConditionsCloned.rangeFilters = [
            ...mainEntityConditionsCloned.rangeFilters,
            ...timePeriodRangeFilters
          ];
        }

        const queryConditionsForOptimizationIndex = _cloneDeep(mainEntityConditionsCloned);
        if (
          aggregationItem.indexName === 'adOptimizationHistory' &&
          queryConditionsForOptimizationIndex.termFilters.filter((x) => x.fieldName === 'targetingText').length > 0
        ) {
          queryConditionsForOptimizationIndex.termFilters
            .filter((x) => x.fieldName === 'targetingText')
            .forEach((x) => {
              x.fieldName = 'searchKeyword';
            });
        }

        mainEntitySearchRequestOverrides.push({
          indexName: aggregationItem.indexName,
          conditions: queryConditionsForOptimizationIndex,
          aggregations: [aggregations]
        });
      });

      // Shortages Trend Chart requires an additional range filter to filter out non-shorted SKUs
      const isShortagesTrendChart = _get(queryParams, ['subtab'], '') === 'shortages';
      const isProductsWithShortagesMetric = _get(aggregationMetricFields, [0, 'name'], '') === 'stacklineSku';
      if (isShortagesTrendChart && isProductsWithShortagesMetric) {
        mainEntitySearchRequestOverrides[0].conditions.rangeFilters.push({
          fieldName: SHORTAGES_FIELDS.totalShortages.name,
          minValue: '0.1'
        });
      }

      // Main chart data discrepancy because of missing automationV2
      if (queryParams.adSummaryMetric === 'hoursSaved' || queryParams.adSummaryMetric === 'costsSaved') {
        mainEntitySearchRequestOverrides[0].conditions.termFilters.push({
          fieldName: 'changeSource',
          condition: 'must',
          values: ['automationV2', 'scheduledAction']
        });
      }

      if (widget.name === 'summaryMainChart') {
        const overrides = mainEntitySearchRequestOverrides[0];
        const updatedOverrides = {
          ...overrides,
          shouldCache: false
        };
        mainEntitySearchRequestOverrides[0] = updatedOverrides;
      }

      // Used for turning off aggregationSort for all "mini" api endpoints in Campaigns tab on Drive
      if (widget.name.split('_')[0] === 'mini') {
        const overrides = mainEntitySearchRequestOverrides[0];
        const updatedOverrides = {
          ...overrides,
          useAggregationSort: false
        };
        mainEntitySearchRequestOverrides[0] = updatedOverrides;
      }

      let statePropertyNameMainEntity = widget.data.configByGroupByFieldName[groupByField.name].isShared
        ? `${indexName}_${groupByField.name}`
        : `${widget.name}_${indexName}_${groupByField.name}`;
      const statePropertyNameComparisonEntity = widget.data.configByGroupByFieldName[groupByField.name].isShared
        ? `comparison_${indexName}_${groupByField.name}`
        : `comparison_${widget.name}_${indexName}_${groupByField.name}`;
      statePropertyNameMainEntity = widget.data.metricName ? widget.data.metricName : statePropertyNameMainEntity;

      // If we are market share and we are fetching metrics with a special numerator that has different conditions than just
      // main entity conditions, we can't re-use those metrics, so we have to change the state key name.
      if (hasNonStandardNumerator) {
        statePropertyNameMainEntity += '_marketShareNumerator';
      }

      state.entitySearchServiceStateNamesMainEntity.push(statePropertyNameMainEntity);

      if (widget.view.displayName === 'Target') {
        state.entitySearchServiceStateNamesMainEntity.push(
          'single_legend_container_adCampaignAdGroupTargetDailyMetrics_retailerId.targetingText_by_retailerId.data'
        );
      }
      if (index === 0) {
        state.mainMetricConfig = {
          type: widget.data.configByGroupByFieldName[groupByField.name].entity.type || 'metric',
          indexName,
          metricField: this.getMetricFields(props)[0]
        };
      }

      // only fetch data, if this widget is the primary source of the data or if it specific to this widget
      if (
        widget.data.configByGroupByFieldName[groupByField.name].isPrimarySource ||
        !widget.data.configByGroupByFieldName[groupByField.name].isShared
      ) {
        let copyOfOverrides = _cloneDeep(mainEntitySearchRequestOverrides);
        const parentPlatform = getParentPlatform();

        if (shouldShowCriteo() && parentPlatform && parentPlatform === PARENT_PLATFORMS.CRITEO) {
          const mutateOverridesForCriteo = mainEntitySearchRequestOverrides;
          const newOverride = [];

          let customRules = [
            {
              action: 'add',
              path: ['conditions', 'termFilters'],
              newObj: {
                fieldName: 'parentPlatform',
                condition: 'must',
                values: [parentPlatform]
              }
            },
            {
              action: 'add',
              path: ['aggregations', '[0]', 'conditions', 'termFilters'],
              newObj: {
                fieldName: 'parentPlatform',
                condition: 'must',
                values: [parentPlatform]
              }
            }
          ];

          // for all retailers remove rid
          if (retailer.id === '0') {
            customRules = [
              ...customRules,
              {
                action: 'remove',
                path: ['aggregations', '[0]', 'conditions', 'termFilters'],
                conditionKey: 'fieldName',
                conditionValue: 'retailerId'
              },
              {
                action: 'remove',
                path: ['conditions', 'termFilters'],
                conditionKey: 'fieldName',
                conditionValue: 'retailerId'
              },
              {
                action: 'update',
                path: ['retailerId'],
                newObj: 999999
              }
            ];
          }

          mutateOverridesForCriteo.forEach((_, indx) => {
            const mutatedQuery = modifyESQuery(
              copyOfOverrides[indx],
              getESBodyOverridesForParentPlatform(parentPlatform, customRules)
            );
            newOverride.push(mutatedQuery);
          });

          copyOfOverrides = newOverride;
        }

        promises.push(
          fetchEntityMetrics(
            statePropertyNameMainEntity,
            {
              entity: widget.data.configByGroupByFieldName[groupByField.name].entity,
              retailer,
              app,
              indexName,
              derivedFields,
              aggregationFields: this.getAggregationFields(groupByField, props)
            },
            copyOfOverrides,
            this.cancelSource.token
          )
        );
        if (comparisonConfig.type) {
          let comparisonIndexName = comparisonConfig.indexName;
          if (shouldShowCriteo() && isCriteo()) {
            comparisonIndexName = groupByField.indexName;
          }
          if (comparisonConfig.type && comparisonConfig.type !== 'metric') {
            comparisonIndexName = indexName;
          }
          let comparisonEntityAggregationFieldsConfig = [];
          if (queryParams.ctype === 'metric') {
            comparisonEntityAggregationFieldsConfig = [
              INDEX_FIELDS.getField(
                app.name,
                comparisonIndexName,
                comparisonConfig.metricName,
                comparisonEntity ? comparisonEntity.type : queryEntity.type,
                groupByField.name
              )
            ];
          } else {
            comparisonEntityAggregationFieldsConfig = this.getAggregationFields(groupByField, props);
          }

          const comparisonEntityRequestOverrides = [];
          let comparisonEntityDerivedFields = null;
          const comparisonEntityAggregationItems = buildAggregations(comparisonEntityAggregationFieldsConfig);
          comparisonEntityAggregationItems.forEach((aggregationItem) => {
            const {
              aggregations: comparisonEntityAggregationFields,
              aggregationFieldConditions: comparisonEntityAggregationFieldConditions
            } = aggregationItem;
            comparisonEntityDerivedFields = comparisonEntityDerivedFields || aggregationItem.derivations;

            if (comparisonEntityConditions && comparisonEntityConditions.termFilters) {
              comparisonEntityAggregationFieldConditions.termFilters =
                comparisonEntityAggregationFieldConditions.termFilters.concat(comparisonEntityConditions.termFilters);
            }
            if (aggregationConditions && aggregationConditions.rangeFilters) {
              comparisonEntityAggregationFieldConditions.rangeFilters =
                comparisonEntityAggregationFieldConditions.rangeFilters.concat(aggregationConditions.rangeFilters);
              comparisonEntityConditions.rangeFilters = comparisonEntityConditions.rangeFilters.concat(
                aggregationConditions.rangeFilters
              );
            }
            const comparisonEntityAggregations = {
              groupByFieldName: groupByField.name,
              aggregationFields: comparisonEntityAggregationFields,
              sortDirection: null,
              sortByAggregationField: null,
              conditions: {
                rangeFilters: comparisonEntityAggregationFieldConditions.rangeFilters,
                termFilters: comparisonEntityAggregationFieldConditions.termFilters.filter(
                  (term) => term.fieldName === 'retailerId'
                )
              }
            };

            comparisonEntityRequestOverrides.push({
              indexName: aggregationItem.indexName,
              conditions: _cloneDeep(comparisonEntityConditions),
              aggregations: [comparisonEntityAggregations]
            });
          });

          state.entitySearchServiceStateNamesComparisonEntity.push(statePropertyNameComparisonEntity);

          if (index === 0) {
            state.comparisonMetricConfig = {
              type: comparisonConfig.type || 'metric',
              indexName: comparisonIndexName,
              metricField: comparisonEntityAggregationFieldsConfig[0]
            };
          }

          // Criteo new overrides
          let copyOfOverridesComparison = _cloneDeep(comparisonEntityRequestOverrides);

          if (shouldShowCriteo() && parentPlatform && parentPlatform === PARENT_PLATFORMS.CRITEO) {
            const mutateOverridesForCriteo = mainEntitySearchRequestOverrides;
            const newOverride = [];

            let customRules = [
              {
                action: 'add',
                path: ['conditions', 'termFilters'],
                newObj: {
                  fieldName: 'parentPlatform',
                  condition: 'must',
                  values: [parentPlatform]
                }
              },
              {
                action: 'add',
                path: ['aggregations', '[0]', 'conditions', 'termFilters'],
                newObj: {
                  fieldName: 'parentPlatform',
                  condition: 'must',
                  values: [parentPlatform]
                }
              }
            ];

            // for all retailers remove rid
            if (retailer.id === '0') {
              customRules = [
                ...customRules,
                {
                  action: 'remove',
                  path: ['aggregations', '[0]', 'conditions', 'termFilters'],
                  conditionKey: 'fieldName',
                  conditionValue: 'retailerId'
                },
                {
                  action: 'remove',
                  path: ['conditions', 'termFilters'],
                  conditionKey: 'fieldName',
                  conditionValue: 'retailerId'
                },
                {
                  action: 'update',
                  path: ['retailerId'],
                  newObj: 999999
                }
              ];
            }

            mutateOverridesForCriteo.forEach((_, indx) => {
              const mutatedQuery = modifyESQuery(
                copyOfOverridesComparison[indx],
                getESBodyOverridesForParentPlatform(parentPlatform, customRules)
              );
              newOverride.push(mutatedQuery);
            });

            copyOfOverridesComparison = newOverride;
          }

          promises.push(
            fetchEntityMetrics(
              statePropertyNameComparisonEntity,
              {
                entity: comparisonEntity || queryEntity,
                retailer: comparisonRetailer,
                app,
                indexName: comparisonIndexName,
                derivedFields: comparisonEntityDerivedFields,
                aggregationFields: comparisonEntityAggregationFieldsConfig
              },
              copyOfOverridesComparison,
              this.cancelSource.token
            )
          );
        }

        // check if this is a marketshare chart
        if (isMarketShareChart) {
          const statePropertyNameCategory = widget.data.configByGroupByFieldName[groupByField.name].isShared
            ? `${widget.data.configByGroupByFieldName[groupByField.name].indexName}_${groupByField.name}_marketshare`
            : `${widget.name}_${widget.data.configByGroupByFieldName[groupByField.name].indexName}_${
                groupByField.name
              }_marketshare`;
          const clonedFilters = _cloneDeep(filters);
          if (queryEntity.type === 'category') {
            clonedFilters.subcategory = null;
          }

          const marketShareDenominatorConditions = buildEntityMarketShareDenominatorConditions({
            app,
            filters: clonedFilters,
            entity: queryEntity,
            categories,
            subCategories,
            segments,
            retailer
          });

          promises.push(
            fetchEntityMetrics(
              statePropertyNameCategory,
              {
                entity: widget.data.configByGroupByFieldName[groupByField.name].entity,
                retailer,
                app,
                indexName: widget.data.configByGroupByFieldName[groupByField.name].indexName,
                derivedFields,
                aggregationFields: this.getAggregationFields(groupByField, props)
              },
              [
                {
                  conditions: _cloneDeep(marketShareDenominatorConditions),
                  aggregations: [
                    {
                      ...mainEntitySearchRequestOverrides[0].aggregations[0],
                      conditions: _cloneDeep(aggregationConditions)
                    }
                  ]
                }
              ],
              this.cancelSource.token
            )
          );

          state.entitySearchServiceStateNamesMarketShare.push(statePropertyNameCategory);
        }
      }
    });

    this.setState({ ...state });

    Promise.all(promises)
      .then(() => {
        this.isFetching = false;
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        if (!err.message.includes('Canceled network request')) {
          console.error(err);
        }
      });
  };

  handleClearComparison = (props = this.props) => {
    const { app, retailer, mainTimePeriod, widget } = props;
    const groupByFields = this.getGroupByFields(widget);
    if (groupByFields.length > 0) {
      this.setState((prevState) => ({
        ...prevState,
        isLoading: true
      }));
      const queryParams = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
        arrayLimit: 100
      });
      const { indexName } = widget.data.configByGroupByFieldName[groupByFields[0].name];
      const statePropertyNameComparisonEntity = widget.data.configByGroupByFieldName[groupByFields[0].name].isShared
        ? `comparison_${indexName}_${groupByFields[0].name}`
        : `comparison_${widget.name}_${indexName}_${groupByFields[0].name}`;
      // update entitySearchService
      store.dispatch(entitySearchServiceActions.deleteKey(statePropertyNameComparisonEntity));
      // update entityService
      store.dispatch(removeEntityWithKey('comparisonEntity'));
      // update query params
      store.dispatch(
        updateQueryParams(app, retailer, mainTimePeriod, {}, _omit(queryParams, ['ctype', 'ctab', 'csubtab']))
      );
      // update state
      this.setState((prevState) => ({
        ...prevState,
        isLoading: false,
        entitySearchServiceStateNamesComparisonEntity: [],
        comparisonMetricConfig: {}
      }));
      // update window url
      this.props.history.replace({
        pathname: window.location.pathname,
        search: `?${_omit(queryParams, ['ctype', 'ctab', 'csubtab', 'cid'])}`
      });
    }
  };

  getChartParams(mainMetricField, stateOverrides = {}) {
    /**
     * If this is a Shortages versus Disputes Won trend chart, we want to display 2 different metric series in the same time period
     * instead of 2 series of the same metric within 2 comparison time periods.
     */
    const isShortageDisputeTrendChart =
      _get(this.props, ['widget', 'view', 'name'], '') === 'shortageDisputeTrendChart';

    // This will be use to determine the chart params for a regular comparison trend chart
    let params = null;

    if (isShortageDisputeTrendChart) {
      // Define ESS key for data access
      const ESS_KEY = 'disputeWonAmountByweekId_sd-asinDetailMetrics_weekId';

      // Pull data from Redux
      const disputes = _get(this.props.entitySearchService, [ESS_KEY, 'disputeWonAmount_by_weekId', 'data'], []);
      const shortages = _get(
        this.props.entitySearchService,
        [ESS_KEY, 'updatedShortageAmountBeforeDispute_by_weekId', 'data'],
        []
      );

      // Get initial trend chart info
      const { chartPropsOverride, chartDisplayTimePeriod, chartComparisonDisplayTimePeriod, groupByFieldName } =
        getShortageDisputeTrendChartInfo(
          this.props.widget,
          this.props.mainTimePeriod,
          this.props.comparisonTimePeriod,
          { main: shortages, compare: [] }
        );

      // Create a mocked widget view
      const clonedView = {
        metricFields: [
          INDEX_FIELDS.getField(
            this.props.app.name,
            'sd-asinDetailMetrics',
            'updatedShortageAmountBeforeDispute',
            this.props.entity.type,
            'retailerId'
          )
        ]
      };

      // Generate chart params for weekly trend chart
      const chartParams = getMultiMetricWeeklyTrendChartParameters(
        chartPropsOverride,
        [disputes, shortages],
        chartDisplayTimePeriod,
        chartComparisonDisplayTimePeriod,
        this.props.comparisonTimePeriod,
        groupByFieldName,
        clonedView,
        this.props.retailer
      );
      return chartParams;
    } else {
      params = getTrendChartInfo(this.props, { ...this.state, ...stateOverrides }, mainMetricField);
    }

    if (!params) {
      return null;
    }

    const groupByFields = this.getGroupByFields();
    const { widget } = this.props;
    let mainLegendValueOverride;
    let comparisonLegendValueOverride;

    if (widget.view.displayName === 'Target') {
      const { entitySearchService } = this.props;
      const targetData = _get(entitySearchService, [
        'single_legend_container_adCampaignAdGroupTargetDailyMetrics_retailerId',
        'targetingText_by_retailerId'
      ]);

      if (targetData) {
        const { data } = targetData;
        const targetCountForMainPeriod = _get(data, ['0', 'value']);
        const targetCountForComparisonPeriod = _get(data, ['0', 'cardView', 'targetingTextPreviousValue']); // add the comparison period

        if (targetCountForMainPeriod) {
          mainLegendValueOverride = targetCountForMainPeriod;
        }
        if (targetCountForMainPeriod) {
          comparisonLegendValueOverride = targetCountForComparisonPeriod;
        }
      }
    }

    // TODO; fix in both methods
    return (groupByFields[0].name === 'dayId' ? getTimeSeriesTrendChartParameters : getWeeklyTrendChartParameters)({
      ...params,
      mainLegendValueOverride,
      comparisonLegendValueOverride
    });
  }

  maybeAddTitleTooltip = (chartProps) => {
    const isMarketShareChart = this.props.widget.data.marketShare && this.props.widget.data.marketShare.compute;

    if (!isMarketShareChart) {
      return chartProps;
    }

    if (this.props.widget.data.marketShare.showMarketShareTooltip) {
      return { ...chartProps, title: { ...chartProps.title, TooltipComponent: MarketShareTooltip } };
    }

    return { ...chartProps, title: { ...chartProps.title } };
  };

  renderTrendChart() {
    const chartParams = this.getChartParams();
    if (!chartParams) {
      return <GenericChartLoading />;
    }

    chartParams.chartProps = this.maybeAddTitleTooltip(chartParams.chartProps);

    if (shouldShowNewBeacon()) {
      const { comparisonLegendValue, comparisonLegendMetricValue, mainLegendValue, mainLegendMetricValue } =
        chartParams;

      const change =
        comparisonLegendValue > 0 ? (mainLegendValue - comparisonLegendValue) / comparisonLegendValue : undefined;

      const titleToUse = this.props.widget.view.title
        ? this.props.widget.view.title
        : chartParams.chartProps.title.text;

      return (
        <BeaconChartWithLegend
          title={titleToUse}
          primaryLegendProps={{
            displayName: this.props.widget.view.mainLegendTitle || this.props.mainTimePeriod.displayName,
            metric: `${_get(mainLegendMetricValue, 'prefix', '')}${_get(mainLegendMetricValue, 'value', 0)}${_get(
              mainLegendMetricValue,
              'suffix',
              ''
            )}`,
            direction: getDirection(change),
            change: change !== undefined ? formatMetric(change, METRICTYPE.PERCENT, { decimalPlaces: 2 }) : undefined
          }}
          convertSeriesToDelimitedData={() => {
            // Prepare the arguments for fetching data
            const propsForFetch = [
              chartParams.chartSeries,
              this.props.comparisonTimePeriod.id === 'prior-period',
              this.props.comparisonTimePeriod,
              this.props.mainTimePeriod
            ];

            try {
              // Convert the chart series to delimited data (may return a promise)
              const data = convertLineChartSeriesToDelimitedData(...propsForFetch);
              // Create a blob with the data and save it as a CSV file
              const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
              saveAs(blob, `${titleToUse}.csv`);
            } catch (e) {
              console.error('error exporting trend chart: ', e);
            }
          }}
          comparisonLegendProps={{
            displayName: this.props.widget.view.comparisonLegendTitle || this.props.comparisonTimePeriod.displayName,
            metric: `${_get(comparisonLegendMetricValue, 'prefix', '')}${_get(
              comparisonLegendMetricValue,
              'value',
              0
            )}${_get(comparisonLegendMetricValue, 'suffix', '')}`
          }}
        >
          <SplineChart
            primaryData={chartParams.chartSeries[1].data}
            secondaryData={chartParams.chartSeries[0].data}
            forecastedData={
              this.props.widget.view.buildForecastData
                ? this.props.widget.view.buildForecastData({ chartSeries: chartParams.chartSeries })
                : undefined
            }
            metricType={this.getMetricFields()[0].metricType}
          />
        </BeaconChartWithLegend>
      );
    }

    return <GenericChart {...chartParams} />;
  }

  render = () => (
    <div id={this.props.widget.name}>
      {this.shouldShowLoading() ? <GenericChartLoading /> : this.renderTrendChart()}
    </div>
  );
}

const mapStateToProps = (state) =>
  _pick(state, [
    'app',
    'entityService',
    'entitySearchService',
    'comparisonTimePeriod',
    'mainTimePeriod',
    'retailer',
    'categories',
    'subCategories',
    'segments',
    'filters',
    'allWeekIdsByRetailerId',
    'user',
    'categoriesByRetailerId'
  ]);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

export const enhanceTrendChart = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withBus('eventBus'));

const EnhancedTrendChart = enhanceTrendChart(TrendChartInner);

export default EnhancedTrendChart;
