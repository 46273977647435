import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import colors from 'src/utils/colors';
import fontStyle from 'src/utils/fontStyle';
import { StacklineLogoIcon } from '../../SvgIcons';

const propTypes = {
  appName: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  width: PropTypes.string
};

const defaultProps = { width: '350px' };
const containerMapping = {
  advertising: {
    color: colors.darkBlue,
    name: 'Drive'
  },
  atlas: {
    color: colors.atlasGreen,
    name: 'Atlas'
  },
  beacon: {
    color: colors.beaconPurple,
    name: 'Beacon'
  }
};

const Container = (props) => {
  const { appName, children, width } = props;
  const appColor = containerMapping[appName].color;
  const appDisplayName = containerMapping[appName].name;
  const logoStyle = {
    width: '180px',
    display: 'block',
    margin: 'auto'
  };
  const containerStyle = {
    display: 'flex',
    height: '100%',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: appColor
  };
  const displayNameStyle = {
    textAlign: 'center',
    fontWeight: fontStyle.regularWeight,
    margin: '15px 0 50px 0',
    fontSize: '28px',
    color: appColor
  };

  return (
    <div style={containerStyle}>
      <div style={{ flex: '0 1' }}>
        <div style={{ width }}>
          <Paper elevation={1} rounded={false} style={{ padding: '45px 35px' }}>
            <StacklineLogoIcon style={logoStyle} />
            <h1 style={displayNameStyle}>{appDisplayName}</h1>
            {children}
          </Paper>
        </div>
      </div>
    </div>
  );
};

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;

export default Container;
