import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import Tooltip from '@mui/material/Tooltip';
import { mergeConditions } from 'sl-api-connector/search/conditions';
import { Link } from 'react-router-dom';

import { buildTrendWidgetConfig } from 'src/components/Layout/LayoutUtil';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import Widget from 'src/components/EntityPage/Widget';
import NInOneTrendChart from 'src/components/EntityPage/TrendChart/NInOneTrendChart';
import { buildMainEntityConditions } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { getCampaignDisplayStatus } from 'src/components/AdManager/AmsUtils';
import TopFiveGrid from 'src/components/EntityGrid/Table/TopFiveGrid';
import './SummaryTile.scss';
import { addPersistentQueryParams } from 'src/utils/browser';

const buildCampaignLink = (id, { retailer, mainTimePeriod }) =>
  `/adCampaign/${id}?${addPersistentQueryParams(retailer, mainTimePeriod, { tab: 'traffic', subtab: 'adClicks' })}`;

const BeaconHeaderInfoInner = ({
  currentEntity: { id, name: displayName, campaignType },
  retailer,
  mainTimePeriod
}) => (
  <div className="summary_header_info">
    <div className="header">
      <Link to={buildCampaignLink(id, { retailer, mainTimePeriod })}>{displayName}</Link>
    </div>
    <div className="subheader">CAMPAIGN: {campaignType}</div>
  </div>
);

BeaconHeaderInfoInner.propTypes = {
  currentEntity: PropTypes.any.isRequired,
  // Redux props
  retailer: PropTypes.object.isRequired,
  mainTimePeriod: PropTypes.object.isRequired
};

const BeaconHeaderInfo = connect((state) => _pick(state, ['retailer', 'mainTimePeriod']))(BeaconHeaderInfoInner);

/**
 * A component that renders a small colored circle next to an ad campaign to indicate its status in
 * the above scatter plot chart.
 *
 * Also used in the budget intake next to campaigns.
 *
 * @param {object} extendedAttributes the extendedAttributes of the entity's api response
 * @param {string} id the unique id of the entity, used for associating tooltips
 * @param {object} style is the optional custom styling you can pass to this component which
 *                       will be applied to the dot (not the tooltip)
 */
export const StatusIndicatorColor = ({ extendedAttributes, style }) => {
  const { displayName: status, color } = getCampaignDisplayStatus(extendedAttributes);
  return (
    <Tooltip title={status} placement="top">
      <span className="status_dot" style={{ backgroundColor: color, ...style }} />
    </Tooltip>
  );
};

StatusIndicatorColor.propTypes = {
  extendedAttributes: PropTypes.object.isRequired,
  style: PropTypes.object
};

StatusIndicatorColor.defaultProps = {
  style: {}
};

const SummaryTileMain = ({ nInOneTrendChart, chartName, topFiveGrid, gridName, app, retailer, widgetProps }) => {
  // We merge the base entity conditions for all requests (`widgetProps.conditions`) with conditions that
  // limit search results down to only include those for this tile's entity.
  const childWidgetEntityConditions = mergeConditions(
    widgetProps.conditions,
    buildMainEntityConditions({ termFilters: [], rangeFilters: [] }, topFiveGrid.data.entity, app, retailer)
  );

  return (
    <div className="summary_tile_content">
      <div className="tile_content">
        <div className="trend_chart column_left">
          {nInOneTrendChart && (
            <Widget
              key={chartName}
              {...widgetProps}
              noInnerContainer
              widget={nInOneTrendChart}
              entityConditions={childWidgetEntityConditions}
            />
          )}
        </div>
        <div className="top_five_grid column_right">
          <div style={{ height: 50.3 }} />
          {topFiveGrid ? (
            <Widget
              key={gridName}
              {...widgetProps}
              widget={topFiveGrid}
              entityConditions={childWidgetEntityConditions}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

SummaryTileMain.propTypes = {
  chartName: PropTypes.string.isRequired,
  nInOneTrendChart: PropTypes.object.isRequired,
  gridName: PropTypes.string.isRequired,
  topFiveGrid: PropTypes.object.isRequired,
  widgetProps: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired
};

const buildInnerWidgets = ({ app, widget }) => {
  const { entity, weekIdField, nInOneProps, topFiveProps } = widget.data;
  const { mericFieldNames } = nInOneProps;

  const name = `nInOneTrendChart_${topFiveProps.cardEntity.id}`;
  const nInOneTrendChart = buildTrendWidgetConfig(
    app,
    widget.data.topFiveProps.indexName,
    entity,
    'weekId',
    mericFieldNames,
    weekIdField,
    {
      CustomComponent: NInOneTrendChart,
      name,
      view: {
        name,
        chartPropsOverride: {
          chart: { height: 420, marginTop: 30, marginLeft: 90 },
          title: '',
          legend: {
            enabled: false
          },
          exporting: { enabled: false },
          subtitle: {
            text: '',
            style: {
              borderBottom: 'none'
            }
          }
        },
        container: { style: { flex: '1', height: '100%', marginBottom: 0 } }
      },
      data: {
        queryEntity: {
          ...topFiveProps.cardEntity,
          // Use the `categoryIds` of the global client entity if the card entity doesn't have any.
          categoryIds: topFiveProps.cardEntity.cataegoryIds || entity.categoryIds
        }
      }
    }
  );

  const chartName = `nInOneChart_${entity.id}`;
  const topFiveGrid = {
    name: `topFiveGrid-${widget.data.currentEntity.id}`,
    CustomComponent: TopFiveGrid,
    data: {
      metricFields: topFiveProps.aggregationMetricFields,
      indexName: widget.data.topFiveProps.indexName,
      groupByField: topFiveProps.groupByField,
      entity: topFiveProps.cardEntity
    },
    view: { container: { style: { width: '100%', flex: '1', height: '100%', marginBottom: 0, overflow: 'hidden' } } }
  };
  const gridName = `nInOneChart_${entity.id}`;

  return { chartName, nInOneTrendChart, gridName, topFiveGrid };
};

const SummaryTile = ({ app, widget, retailer, ...widgetProps }) => {
  const { chartName, nInOneTrendChart, gridName, topFiveGrid } = useMemo(
    () => buildInnerWidgets({ app, widget }),
    [app, widget]
  );

  const { currentEntity } = widget.data;
  const { hideHeader } = widget.view;
  if (!nInOneTrendChart || !chartName || !topFiveGrid || !gridName) {
    return 'loading';
  }

  return (
    <div className="summary_tile_container">
      {hideHeader ? null : <BeaconHeaderInfo currentEntity={currentEntity} />}

      <SummaryTileMain
        chartName={chartName}
        nInOneTrendChart={nInOneTrendChart}
        gridName={gridName}
        topFiveGrid={topFiveGrid}
        widgetProps={widgetProps}
        app={app}
        retailer={retailer}
      />
    </div>
  );
};

SummaryTile.propTypes = {
  entity: PropTypes.object.isRequired,
  widget: PropTypes.object.isRequired,
  // Redux Props
  app: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired
};

const mapStateToProps = (state) =>
  _pick(state, [
    'app',
    'entityService',
    'entitySearchService',
    'comparisonTimePeriod',
    'mainTimePeriod',
    'retailer',
    'categories',
    'filters',
    'allWeekIdsByRetailerId'
  ]);
const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTile);
