import _merge from 'lodash/merge';
import EntityGrid from 'src/components/EntityGrid';
import { buildTrendWidgetConfig, buildWidgetGroup } from 'src/components/Layout/LayoutUtil';
import { ENTITIES, INDEX_FIELDS } from 'src/utils/entityDefinitions';
import VIEWS from '../../ViewDefaultConfig';
import { buildShortagesTopEntitiesChart } from 'src/components/Layout/util/PrototypeShortagesWidgetUtils';
import { EntityGridNoclipHeaderWrapper } from 'src/components/EntityGrid/HeaderComponentFrameworks/MultiLineHCF';
import { withManualBrHeader } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import { withProps } from 'src/utils/hoc';
import CustomAgGridHeaderTemplate from 'src/components/EntityGrid/Table/CustomAgGridHeaderTemplate';
import MultiMetricTrendChart from 'src/components/EntityPage/TrendChart/MultiMetricTrendChart';
import { filterNils } from 'src/utils/fp';
import AtlasProductCellFormatter from 'src/components/EntityGrid/Table/AtlasProductCellFormatter';
import { EnhancedDonutChartContainer } from 'src/components/Charts/Donut';
import { SHORTAGES_INDEX } from 'src/components/ShortageDisputes/components/ShortageSummary/constants';
import { Widget as WidgetType } from 'src/types/application/widgetTypes';
import { shouldShowNewBeacon } from 'src/utils/app';
import ShortagesSummaryPageLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Operations/Shortages/ShortageMetrics';
import { ShortagesProductGrid } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Operations/Shortages/ShortagesProductGrid';

// SHORTAGES DONUT
const buildShortagesDonutChart = ({ app, entity, dataConfig }) => {
  const indexName = SHORTAGES_INDEX.shortageDisputes;

  const mainMetric = INDEX_FIELDS.getField(
    app.name,
    indexName,
    'totalShortageAmount',
    entity.type,
    dataConfig.retailerIdField.name
  );
  const mainFields = [
    'notYetDisputedShortageAmountV2',
    'disputeUnderReviewShortageAmountV2',
    'recoveredShortageAmountV2',
    'lostShortageAmountV2'
  ].map((fieldName) =>
    INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type, dataConfig.retailerIdField.name)
  );
  return {
    name: 'NotYetDisputedShortageDonutChart',
    CustomComponent: EnhancedDonutChartContainer,
    data: {
      indexName,
      aggregationFields: mainFields,
      mainField: mainFields[0],
      secondaryMetric: { ...mainMetric, displayName: 'Total Shortages' },
      mainMetric,
      groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.retailerIdField.name, entity.type)
    },
    view: {
      customHeader: {
        enabled: true,
        title: 'Shortages'
      },
      ...VIEWS.donutChart,
      name: `NotYetDisputedShortageDonutChart`,
      chartPropsOverride: {
        chart: {
          type: 'pie',
          height: 350
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            }
          }
        },
        tooltip: {
          enabled: true
        },
        disablePointSelect: false,
        enableMultiSelect: false,
        showFullValue: false
      },

      container: {
        style: {
          width: '500px',
          height: '440px'
        }
      }
    }
  };
};

const buildEntityGrid = ({ app }) => {
  const indexName = SHORTAGES_INDEX.shortageDisputes;
  const metricType = 'invoiceAmount';
  const aggregationFields = [
    'invoiceAmount',
    'updatedShortageAmountBeforeDispute',
    'disputeSubmittedAmount',
    'disputeWonAmount',
    'shortageRatio'
  ].map((fieldName) => INDEX_FIELDS.getField(app.name, indexName, fieldName, 'product', 'stacklineSku'));
  const gridAggregationFields = aggregationFields.map((field) => ({
    ...field,
    fieldOverridesByFieldName: {},
    headerComponentFramework: withManualBrHeader(
      withProps({
        sortArrowStyle: !field.name.includes('|') ? { marginTop: '1em' } : undefined
      })(CustomAgGridHeaderTemplate)
    ),
    cellRenderFramework: AtlasProductCellFormatter,
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  }));

  return {
    name: 'entityGrid',
    CustomComponent: EntityGrid,
    view: _merge({}, VIEWS.entityGrid, {
      WrapperComponent: EntityGridNoclipHeaderWrapper,
      gridOptions: {
        titleOptions: {
          product: shouldShowNewBeacon ? 'Invoices by Product' : 'Products by USD'
        },
        enableAggregateBy: true
      }
    }),
    data: {
      defaultSortField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
      groupByFields: [INDEX_FIELDS.getField(app.name, 'sales', 'stacklineSku', 'product')],
      configByGroupByFieldName: {
        stacklineSku: {
          indexName,
          entity: ENTITIES.atlas.product,
          mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
          aggregationFields,
          tableView: {
            metricFields: gridAggregationFields
          }
        }
      }
    }
  };
};

const buildMultiMetricTrendChart = ({ entity, groupByFieldName = 'weekId', app, showOnlyMainSeries }) => {
  const indexName = SHORTAGES_INDEX.shortageDisputes;

  const BEACON_SHORTAGES_METRIC_FIELD_NAMES = [
    'invoiceAmount',
    'updatedShortageAmountBeforeDispute',
    'skusWithShortages',
    'shortageRatio',
    'disputeSubmittedAmount',
    'disputeNeedActionAmount',
    'disputeUnderReviewAmount',
    'disputeWonAmount',
    'disputeLostAmount'
  ];
  const metricFieldNames = filterNils([...BEACON_SHORTAGES_METRIC_FIELD_NAMES]);
  const name = 'multiMetricTrendChart';
  const weekIdFieldOverride = INDEX_FIELDS.getField(app.name, indexName, 'weekId', entity.type);

  const widget = buildTrendWidgetConfig(
    app,
    indexName,
    entity,
    groupByFieldName,
    metricFieldNames,
    weekIdFieldOverride,
    {
      CustomComponent: MultiMetricTrendChart,
      name,
      view: {
        name,
        chartPropsOverride: {
          title: { text: '' }
        },
        showOnlyMainSeries,
        hideSubtitle: true
      }
    }
  );

  widget.view.metricFields = widget.view.metricFields.map((metricField) => ({
    ...metricField,
    displayName: metricField.displayName || ''
  }));

  return widget;
};

const buildShortagesSummaryWidgets = (
  { app, entity, dataConfig, weekIdField, metricType }: any,
  widgets: WidgetType[]
) => {
  // ------------------------- Total Shortages Donut and Trend Chart  -------------------------
  const indexName = SHORTAGES_INDEX.shortageDisputes;
  const widgetConfig = { app, entity, dataConfig, weekIdField, metricType, indexName };
  const donutChart = buildShortagesDonutChart(widgetConfig, widgets);
  const viewForWidgetGroup = {
    view: {
      container: {
        style: {
          height: '630px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around'
        }
      }
    }
  };
  const widgetGroup = buildWidgetGroup(
    [
      donutChart,
      buildTrendWidgetConfig(
        app,
        indexName,
        entity,
        'weekId',
        ['disputeWonAmount', 'updatedShortageAmountBeforeDispute'],
        weekIdField,
        {
          view: {
            name: 'shortageDisputeTrendChart',
            chartPropsOverride: {
              title: {
                text: ''
              },
              subtitle: {
                text: '',
                style: {
                  'border-bottom': 'none'
                }
              },
              chart: {
                height: 450,
                marginTop: 100
              },
              yAxis: [
                {
                  visible: true
                }
              ]
            },
            container: { style: { width: '60%', display: 'inline-block', marginBottom: '100px' } }
          }
        }
      )
    ],
    viewForWidgetGroup
  );

  // ------------------------- Default Invoices Multi Metric Trend Chart  -------------------------
  const multiMetricTrendChart = buildMultiMetricTrendChart({ entity, app, weekIdField, showOnlyMainSeries: false });

  // ------------------------- Entity Grid/Table -------------------------
  const gridTable = buildEntityGrid(widgetConfig);

  // ------------------------- Assembling all widgets for page layout  -------------------------

  if (shouldShowNewBeacon()) {
    widgets.push({
      name: 'shortageMetrics',
      CustomComponent: ShortagesSummaryPageLayout,
      view: {}
    });
  } else {
    widgets.push(widgetGroup);
    widgets.push(multiMetricTrendChart);
  }

  widgets.push(
    buildShortagesTopEntitiesChart({
      app,
      entity,
      weekIdField,
      indexName: SHORTAGES_INDEX.shortageDisputes,
      metricType: 'updatedShortageAmountBeforeDispute'
    })
  );

  if (shouldShowNewBeacon()) {
    widgets.push({
      name: 'shortagesProductGrid',
      CustomComponent: ShortagesProductGrid,
      view: { container: { style: { marginBottom: '80px' } } }
    });
  } else {
    widgets.push(gridTable);
  }
};

export default buildShortagesSummaryWidgets;
