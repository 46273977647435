/* eslint-disable react/prop-types */
import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { Link } from 'react-router-dom';
import { useAppSecondaryColor } from 'src/components/SignIn/utils';
import { useStacklineTheme } from '@stackline/ui';

const StyledTextField = styled(TextField)(() => ({
  marginTop: '11px',
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    border: '1px solid #e8e8ed',
    backgroundColor: '#fff',
    height: '48px',
    fontSize: '14px'
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#7e8fa8',
    opacity: '0.5'
  }
}));

const SignInInputField: React.FC<React.ComponentProps<typeof TextField> & { showForgotPassword?: boolean }> = ({
  showForgotPassword = false,
  label,
  sx,
  ...props
}) => {
  const theme = useStacklineTheme();
  const appSecondaryColor = useAppSecondaryColor();

  return (
    <Box sx={sx}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="self-end">
        {label ? (
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 500,
              color: theme.colors.primary
            }}
          >
            {label}
          </Typography>
        ) : null}
        {showForgotPassword ? (
          <Link
            style={{
              color: appSecondaryColor,
              fontSize: '14px',
              fontWeight: 500
            }}
            to="/password_reset?step=inputEmail"
          >
            Forgot?
          </Link>
        ) : null}
      </Box>
      <StyledTextField {...props} />
    </Box>
  );
};

export default SignInInputField;
