import React from 'react';
import { SlColumn, SlRow } from '@stackline/ui';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import {
  BEACON_PRO_CARD_WIDTH,
  FORECAST_KEY_METRICS_CARD_WIDTH
} from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

interface MetricSummaryTrendCardLoadingProps {
  variant: 'large' | 'small' | 'medium';
}

/**
 * Shows a skeleton for a metric summary card
 */
const MetricSummaryTrendCardLoading = ({ variant }: MetricSummaryTrendCardLoadingProps) => {
  const largeLoading = (
    <SlColumn verticalInset="md" horizontalInset="md">
      <SlColumn spacing="sm">
        <SlSkeleton variant="rounded" height={16} width={112} />
        <SlSkeleton variant="rounded" height={20} width={157} />
      </SlColumn>
      <div style={{ marginTop: '24px' }}>
        <SlSkeleton
          variant="rounded"
          height={68}
          width={variant === 'medium' ? FORECAST_KEY_METRICS_CARD_WIDTH : BEACON_PRO_CARD_WIDTH}
        />
      </div>
    </SlColumn>
  );

  const smallLoading = (
    <SlRow horizontalPosition="space-between" verticalPosition="center" verticalInset="md" horizontalInset="md">
      <SlColumn spacing="sm">
        <SlSkeleton variant="rounded" height={11} width={112} />
        <SlSkeleton variant="rounded" height={22} width={72} />
        <SlSkeleton variant="rounded" height={13} width={37} />
      </SlColumn>
      <SlSkeleton variant="rounded" height={33} width={116} />
    </SlRow>
  );

  return (
    <div
      style={{
        width: variant === 'medium' ? FORECAST_KEY_METRICS_CARD_WIDTH : BEACON_PRO_CARD_WIDTH,
        height: variant === 'small' ? '96px' : '173px'
      }}
    >
      {variant === 'small' ? smallLoading : largeLoading}
    </div>
  );
};

export default MetricSummaryTrendCardLoading;
