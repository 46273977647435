import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector, useUpdateQueryParams } from 'src/utils/Hooks';
import { computeComparisonTimePeriod } from 'src/utils/dateformatting';
import updateComparisonTimePeriod from 'src/store/modules/comparison-time-period/operations';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { History } from 'history';
import { useBeaconRoutes } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import { useForecastPeriod } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import { ForecastPeriod } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';

const ComparisonPeriodDropdown = ({ history }: { history: History }) => {
  const retailer = useAppSelector((state) => state.retailer);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const comparisonTimePeriod = useAppSelector((state) => state.comparisonTimePeriod);
  const allWeekIdsByRetailerId = useAppSelector((state) => state.allWeekIdsByRetailerId);
  const dispatch = useDispatch();
  const { currentSubtab } = useBeaconRoutes();
  const updateQuery = useUpdateQueryParams(history);
  const forecastPeriod = useForecastPeriod();
  const [selectedPeriodId, setSelectedPeriodId] = useState(comparisonTimePeriod.id);

  const timePeriods = comparisonTimePeriod.availableComparisonTimePeriods;
  const timePeriodOptions = timePeriods.map((period) => {
    return { id: period.id, label: period.shortDisplayName };
  });

  useEffect(() => {
    setSelectedPeriodId(comparisonTimePeriod.id);
  }, [comparisonTimePeriod.id]);

  const selectedTimePeriod = useMemo(() => {
    return timePeriods.find((period) => period.id === selectedPeriodId);
  }, [selectedPeriodId, timePeriods]);

  const handleComparisonPeriodChange = (periodId: string) => {
    setSelectedPeriodId(periodId);
    const updatedTimePeriod = computeComparisonTimePeriod(
      allWeekIdsByRetailerId[retailer.id],
      mainTimePeriod,
      periodId
    );

    const {
      endWeek: comparisonEndWeek,
      startWeek: comparisonStartWeek,
      startDayId,
      endDayId,
      startWeekStartDate,
      endWeekEndDate
    } = updatedTimePeriod;

    dispatch(
      updateComparisonTimePeriod(
        comparisonTimePeriod.availableComparisonTimePeriods
          .map((x: { id: string; shortDisplayName: string }) => x.id)
          .indexOf(periodId),
        comparisonStartWeek,
        comparisonEndWeek,
        startDayId,
        endDayId,
        startWeekStartDate,
        endWeekEndDate
      )
    );

    updateQuery({ updatedComparisonPeriod: updatedTimePeriod });
  };

  const shouldDisableComparisonPeriod = () => {
    return currentSubtab === 'growthmatrix' || forecastPeriod === ForecastPeriod.CUSTOM;
  };

  return (
    <>
      <SlDropdownMenu
        onChange={(period) => handleComparisonPeriodChange(period.id)}
        defaultLabel={selectedTimePeriod.shortDisplayName}
        selectedId={selectedTimePeriod.id}
        options={timePeriodOptions}
        shouldShowFullItemText
        disabled={shouldDisableComparisonPeriod()}
      />
    </>
  );
};

export default withRouter(ComparisonPeriodDropdown);
