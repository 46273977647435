/* eslint-disable react/prop-types */
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomizedCheckbox from 'src/components/common/CustomizedCheckBox/CustomizedCheckBox';
import { SelectedIncludeCheckbox, UnselectedCheckbox } from 'src/components/SvgIcons/SvgIcons';

interface ListFilterRowProps {
  isChecked: boolean;
  onCheck: (event: any, isChecked: boolean, index: number) => void;
  value: string;
  style?: React.CSSProperties;
  index: number;
  className?: string;
}

const OmniListFilterRow: React.FC<ListFilterRowProps> = ({ isChecked, onCheck, value, style, index, className }) => {
  return (
    <div key={value} style={style} className={className}>
      <div style={{ marginLeft: '2px' }}>
        <FormControlLabel
          control={
            <CustomizedCheckbox
              styleObj={{
                width: 16,
                height: 16,
                iconBackgroundColor: 'transparent',
                iconColor: 'blue',
                checkedIconBackgroundColor: 'transparent',
                checkedIconColor: 'transparent'
              }}
              InnerIcon={SelectedIncludeCheckbox}
              OuterIcon={UnselectedCheckbox}
              checkboxProps={{
                checked: isChecked,
                onChange: (evt) => {
                  onCheck(evt, evt.target.checked, index);
                }
              }}
            />
          }
          label={<span style={{ fontSize: '14px', fontWeight: 500, color: '#052849' }}>{value}</span>}
        />
      </div>
    </div>
  );
};

export default OmniListFilterRow;
