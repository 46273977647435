import React from 'react';
import { Text } from '@stackline/ui';
import EntityPill from '../EntityPill/EntityPill';
import { BEACON_PRO_SIDEBAR_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';

export interface EntityCardProps {
  /**
   * Title of the entity, such as the
   * brand/organization/product name
   */
  title: string;
  /**
   * Header displayed above the title, should
   * be an image or ElementCard
   */
  header: React.ReactNode;
  /**
   * If given, displays a pill underneath the
   * title with the text
   */
  pill?: React.ReactNode;
  /**
   * If given, displays a subtitle underneath
   * the title
   */
  subtitleText?: string;

  titleMarginTop?: string;

  subtitleMarginTop?: string;

  /**
   * If the title should have a link (like for products),
   * then this is the link it will navigate to
   */
  titleLink?: string;

  headerContainerSx?: SxProps;
}

/**
 * Information about the main entity that will
 * be displayed above the left nav
 */
export const EntityCardInner = ({
  header,
  title,
  pill,
  subtitleText,
  titleLink,
  titleMarginTop = '8px',
  subtitleMarginTop = '16px',
  headerContainerSx
}: EntityCardProps) => {
  return (
    <div
      style={{
        maxWidth: BEACON_PRO_SIDEBAR_WIDTH
      }}
    >
      <Flex flexDirection="column" alignItems="center">
        <Flex flexDirection="column" alignItems="center">
          <Box
            sx={{
              height: '80px',
              width: BEACON_PRO_SIDEBAR_WIDTH,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...headerContainerSx
            }}
          >
            {header}
          </Box>
          <div style={{ marginTop: titleMarginTop }}>
            {titleLink ? (
              <Link to={titleLink} target="_blank" rel="noreferrer">
                <Text title={title} truncateLines={1} textAlign="center" variant="subtitle2">
                  {title}
                </Text>
              </Link>
            ) : (
              <Text title={title} truncateLines={1} textAlign="center" variant="subtitle2">
                {title}
              </Text>
            )}
          </div>
        </Flex>
        <div style={{ marginTop: subtitleMarginTop }}>
          {pill ? (
            typeof pill === 'string' ? (
              <EntityPill>{pill}</EntityPill>
            ) : (
              pill
            )
          ) : subtitleText ? (
            <Text variant="body3" title={subtitleText} truncateLines={2} textAlign="center">
              {subtitleText}
            </Text>
          ) : null}
        </div>
      </Flex>
    </div>
  );
};

export default EntityCardInner;
