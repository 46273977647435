/* eslint-disable class-methods-use-this */
import { store } from 'src/main';
import { IGridResultData } from 'src/components/AdManager/Search/Models/IGridResultData';
import {
  fetchEntityMetricsAndParseResponse,
  getClonedResult
} from 'src/components/AdManager/Search/GridDataFetchers/FetchDataForGroupByField';
import { IFetchEntityMetricsAndParseResponseParams } from 'src/components/AdManager/Search/Models/IFetchEntityMetricsAndParseResponseParams';
import { IDataSet } from 'src/components/AdManager/Search/Models/IDataSet';

interface FetchAdGroupsDataArgs {
  pageNumber: number;
  fetchMetricsParams: IFetchEntityMetricsAndParseResponseParams;
  dataSet: IDataSet;
  importantOverrides?: [];
}

export class AdGroupsDataFetcher {
  public async fetchAdGroupsData({
    pageNumber,
    fetchMetricsParams,
    dataSet,
    importantOverrides = []
  }: FetchAdGroupsDataArgs): Promise<IGridResultData> {
    const result = await fetchEntityMetricsAndParseResponse(
      fetchMetricsParams,
      store.dispatch,
      undefined,
      null,
      importantOverrides
    );
    const cloneResult = getClonedResult(result, pageNumber, dataSet);

    return {
      gridResult: cloneResult
    };
  }
}
