import React, { FC } from 'react';
import { Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { brandclub_colors } from '@stackline/ui';
import { buildLinkParams } from 'src/components/EntityPage/LeftNav/LeftNavConfig';
import { useAppSelector, useHistory, useLocation } from 'src/utils/Hooks';
import { useBeaconRoutes } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';
import { ChildSubtab } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/types';
import { CSSProperties } from '@mui/styles';
import _merge from 'lodash/merge';
import ReduxStore from 'src/types/store/reduxStore';
import { UpdateUrlQueryParams, updateUrlQueryParams } from 'src/utils/app';
import { Location } from 'history';

const useStyles = (tabsStyles: CSSProperties = {}) =>
  makeStyles({
    tabsDefaultStyles: _merge(
      {
        minHeight: 'unset',
        width: '100%',
        '& div.MuiTabs-scroller': {
          borderBottom: `1px solid #0528491f`,
          '& div.MuiTabs-flexContainer': {
            gap: 32,
            minHeight: 'unset'
          },
          '& span.MuiTabs-indicator': {
            backgroundColor: '#052849',
            borderRadius: 3
          }
        }
      },
      tabsStyles
    ),
    tabDefaultStyles: {
      color: brandclub_colors.gray5,
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Roboto',
      letterSpacing: 0.09,
      minHeight: 'unset',
      minWidth: 'unset',
      justifyContent: 'flex-end',
      paddingBottom: 7.5,
      padding: 0,
      textTransform: 'none',
      cursor: 'pointer',
      '&.Mui-selected': {
        color: '#052849'
      },
      '&.Mui-disabled': {
        color: '#0528491f'
      }
    }
  })();

export interface AppParamTabProps {
  value: string;
  label: string;
}

interface ModifiedTab extends AppParamTabProps {
  /**
   * Example: Sales is the parent tab of the key metrics subtab
   */
  parentTab: string;
}

export interface AppParamTabsProps {
  tabs: ModifiedTab[];
  tab: ModifiedTab['value'];
  setTab?: (value: AppParamTabProps['value']) => void;
  disableNextTabs?: boolean;
  location: any;
  history: any;
}

export const buildTabAndSubtabLink = ({
  app,
  location,
  tab,
  subtab,
  defaultQueryParams = null
}: {
  app: ReduxStore['app'];
  location: Location;
  tab: string;
  subtab: string;
  defaultQueryParams?: UpdateUrlQueryParams;
}) => {
  const { pathname } = location;
  const { linkParams } = buildLinkParams({ tab, subtab }, true);
  const { searchParams, compareParams, filterParams } = app.queryParams;
  const showCompareParams = linkParams.includes('ctab') ? '' : compareParams;
  let subtabLink = `${pathname}${searchParams}&${linkParams}${showCompareParams}${filterParams}`;

  if (tab === 'forecasts') {
    // forecasts tab has specific params that has to persist between the subtab
    const forecastParams = new URLSearchParams(location.search);
    const forecastPeriod = forecastParams.get('forecastPeriod');
    const forecastType = forecastParams.get('forecastType');

    const forecastPeriodParams = forecastPeriod ? `&forecastPeriod=${forecastPeriod}` : '';
    const forecastTypeParams = forecastType ? `&forecastType=${forecastType}` : '';
    subtabLink = `${pathname}${searchParams}&${linkParams}${showCompareParams}${filterParams}${forecastPeriodParams}${forecastTypeParams}`;

    if (defaultQueryParams) {
      subtabLink = `${pathname}${searchParams}&${linkParams}${showCompareParams}${filterParams}${
        defaultQueryParams
          ? updateUrlQueryParams({ forecastPeriod, forecastType, ...defaultQueryParams }, null, true)
          : ''
      }`;
    }
  } else if (defaultQueryParams) {
    subtabLink = `${pathname}${searchParams}&${linkParams}${showCompareParams}${filterParams}${
      defaultQueryParams ? updateUrlQueryParams(defaultQueryParams, null, true) : ''
    }`;
  }

  return subtabLink;
};

/**
 * Generic list of tabs where each tab
 * defines its own onClick handler
 */
export const GenericNavigationTabs = ({
  tab,
  tabs,
  tabsStyle = {}
}: {
  tab: string;
  tabs: { value: string; label: string; disabled?: boolean; onClick: () => void }[];
  tabsStyle?: CSSProperties;
}) => {
  const classes = useStyles(tabsStyle);

  return (
    <Tabs
      value={tab}
      variant="scrollable"
      scrollButtons={false}
      className={classes.tabsDefaultStyles}
      sx={{ paddingTop: tabs.length ? '16px' : '32px' }}
    >
      {tabs.map((t) => {
        return (
          <Tab
            key={t.value}
            value={t.value}
            label={t.label}
            disableRipple
            disabled={t.disabled}
            className={classes.tabDefaultStyles}
            onClick={() => t.onClick()}
          />
        );
      })}
    </Tabs>
  );
};

const AppParamTabs: FC<AppParamTabsProps> = ({ tabs, tab, disableNextTabs, location, history }) => {
  const classes = useStyles();
  const app = useAppSelector((state) => state.app);

  return (
    <Tabs
      value={tab}
      variant="scrollable"
      scrollButtons={false}
      className={classes.tabsDefaultStyles}
      sx={{ paddingTop: tabs.length ? '16px' : '32px' }}
    >
      {tabs.map((t, index) => {
        return (
          <Tab
            key={index}
            value={t.value}
            label={t.label}
            disableRipple
            disabled={disableNextTabs && index > tabs.findIndex((tb) => tb.value === tab)}
            className={classes.tabDefaultStyles}
            onClick={() =>
              history.push(
                buildTabAndSubtabLink({
                  app,
                  location,
                  tab: t.parentTab,
                  subtab: t.value,
                  defaultQueryParams: t.defaultQueryParams
                })
              )
            }
          />
        );
      })}
    </Tabs>
  );
};

const SubtabNavigation = (props: { childSubtabs?: ChildSubtab[] } = {}) => {
  const history = useHistory();
  const location = useLocation();
  const { currentRoute, currentSubtab } = useBeaconRoutes();
  const { childSubtabs } = currentRoute;

  return (
    <AppParamTabs location={location} history={history} tabs={props.childSubtabs || childSubtabs} tab={currentSubtab} />
  );
};

export default SubtabNavigation;
