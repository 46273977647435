import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _prop from 'lodash/property';
import { RangeFilter, AppName, Conditions } from 'sl-api-connector/types';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { getFormattedWeekNumber } from './dateformatting';
import ReduxStore from 'src/types/store/reduxStore';
import { Condition } from 'src/store/modules/segments/operations';

export const DEFAULT_SAVED_SEARCH_ID = '00000000-0000-0000-0000-000000000000';

export const comparisonParams = [
  'ctype',
  'ctab',
  'csubtab',
  'cid',
  'k',
  'xk',
  'st',
  'stf',
  'xst',
  'b',
  'p',
  'id',
  'dn',
  'c',
  'sc',
  'xc'
];
export const filterParams = ['ftype', 'fvalue'];

export const queryMapping: {
  termFilters: { [key: string]: { fieldName: string; condition: Condition } };
  rangeFilters: { [key: string]: { fieldName: string } };
} = {
  termFilters: {
    k: {
      fieldName: 'keyword',
      condition: 'should'
    },
    kp: {
      fieldName: 'keywordPhrase',
      condition: 'should.multi_match.phrase'
    },
    ke: {
      fieldName: 'keywordExact',
      condition: 'should.multi_match.exact'
    },
    xk: {
      fieldName: 'excludedKeyword',
      condition: 'must_not'
    },
    xke: {
      fieldName: 'excludedKeywordExact',
      condition: 'must_not'
    },
    st: {
      fieldName: 'searchTerm',
      condition: 'should'
    },
    stf: {
      fieldName: 'searchTermFuzzy',
      condition: 'should'
    },
    xst: {
      fieldName: 'excludedSearchTerm',
      condition: 'must_not'
    },
    xstf: {
      fieldName: 'excludedSearchTermFuzzy',
      condition: 'must_not'
    },
    sk: {
      fieldName: 'searchKeyword',
      condition: 'should'
    },
    skf: {
      fieldName: 'searchKeywordFuzzy',
      condition: 'should'
    },
    xsk: {
      fieldName: 'excludedSearchKeyword',
      condition: 'must_not'
    },
    xskf: {
      fieldName: 'excludedSearchKeywordFuzzy',
      condition: 'must_not'
    },
    tt: {
      fieldName: 'targetingText',
      condition: 'should'
    },
    ttf: {
      fieldName: 'targetingTextFuzzy',
      condition: 'should'
    },
    xtt: {
      fieldName: 'excludedTargetingText',
      condition: 'must_not'
    },
    xttf: {
      fieldName: 'excludedTargetingTextFuzzy',
      condition: 'must_not'
    },
    bct: {
      fieldName: 'breadCrumbText',
      condition: 'should'
    },

    xbct: {
      fieldName: 'excludedBreadCrumbText',
      condition: 'must_not'
    },

    b: {
      fieldName: 'brandId',
      condition: 'should'
    },
    xb: {
      fieldName: 'excludedBrandId',
      condition: 'must_not'
    },
    pb: {
      fieldName: 'parentBrandId',
      condition: 'should'
    },
    c: {
      fieldName: 'categoryId',
      condition: 'should'
    },
    sc: {
      fieldName: 'subCategoryId',
      condition: 'should'
    },
    pt: {
      fieldName: 'promoType',
      condition: 'should'
    },
    xc: {
      fieldName: 'excludedCategoryId',
      condition: 'must_not'
    },
    xsc: {
      fieldName: 'excludedSubCategoryId',
      condition: 'must_not'
    },
    cpn: {
      fieldName: 'campaignName',
      condition: 'should'
    },
    cpnf: {
      fieldName: 'campaignNameFuzzy',
      condition: 'should'
    },
    agnf: {
      fieldName: 'adGroupNameFuzzy',
      condition: 'should'
    },
    rnf: {
      fieldName: 'retailerNameFuzzy',
      condition: 'should'
    },
    xcpn: {
      fieldName: 'excludedCampaignName',
      condition: 'must_not'
    },
    xcpnf: {
      fieldName: 'excludedCampaignNameFuzzy',
      condition: 'must_not'
    },
    pfn: {
      fieldName: 'portfolioName',
      condition: 'should'
    },
    pfnf: {
      fieldName: 'portfolioNameFuzzy',
      condition: 'should'
    },
    xpfn: {
      fieldName: 'excludedPortfolioName',
      condition: 'must_not'
    },
    xpfnf: {
      fieldName: 'excludedPortfolioNameFuzzy',
      condition: 'must_not'
    },
    etn: {
      fieldName: 'entityName',
      condition: 'should'
    },
    etnf: {
      fieldName: 'entityNameFuzzy',
      condition: 'should'
    },
    xetn: {
      fieldName: 'excludedEntityName',
      condition: 'must_not'
    },
    xetnf: {
      fieldName: 'excludedEntityNameFuzzy',
      condition: 'must_not'
    },
    cpid: {
      fieldName: 'campaignId',
      condition: 'should'
    },
    etid: {
      fieldName: 'entityId',
      condition: 'should'
    },
    pfid: {
      fieldName: 'portfolioId',
      condition: 'should'
    },
    asid: {
      fieldName: 'automationStrategyId',
      condition: 'should'
    },
    cpt: {
      fieldName: 'derivedCampaignType',
      condition: 'should'
    },
    cpst: {
      fieldName: 'statusDerived',
      condition: 'should'
    },
    pfst: {
      fieldName: 'portfolioState',
      condition: 'should'
    },
    tmt: {
      fieldName: 'targetingType',
      condition: 'should'
    },
    tst: {
      fieldName: 'targetState',
      condition: 'should'
    },
    cppst: {
      fieldName: 'campaignProductState',
      condition: 'should'
    },
    iaab: {
      fieldName: 'isAutoAdded',
      condition: 'should'
    }
  },
  rangeFilters: {
    p: {
      fieldName: 'retailPrice'
    },
    adi: {
      fieldName: 'impressions'
    },
    adc: {
      fieldName: 'clicks'
    },
    adsp: {
      fieldName: 'spend'
    },
    ads: {
      fieldName: 'sales'
    },
    adus: {
      fieldName: 'unitsSold'
    },
    adctr: {
      fieldName: 'clickThroughRate'
    },
    adcpm: {
      fieldName: 'costPerMilli'
    },
    adcpa: {
      fieldName: 'costPerAcquisition'
    },
    adcr: {
      fieldName: 'conversionRate'
    },
    adcpc: {
      fieldName: 'costPerClick'
    },
    adroas: {
      fieldName: 'returnOnAdSpend'
    }
  }
} as const;

export const termFilterDef = {
  campaignId: [
    { filterName: 'campaignNameFuzzy', fieldName: 'campaignNameFuzzy' },
    { filterName: 'excludedCampaignNameFuzzy', fieldName: 'excludedCampaignNameFuzzy' },
    { filterName: 'entityId', fieldName: 'entityId' },
    { filterName: 'portfolioId', fieldName: 'portfolioId' },
    { filterName: 'derivedCampaignType', fieldName: 'derivedCampaignType' },
    { filterName: 'statusDerived', fieldName: 'statusDerived' },
    { filterName: 'automationStrategyId', fieldName: 'automationStrategyId' }
  ],
  portfolioId: [
    { filterName: 'portfolioNameFuzzy', fieldName: 'portfolioNameFuzzy' },
    { filterName: 'excludedPortfolioNameFuzzy', fieldName: 'excludedPortfolioNameFuzzy' },
    { filterName: 'entityId', fieldName: 'entityId' },
    { filterName: 'portfolioState', fieldName: 'statusDerived' },
    { filterName: 'automationStrategyId', fieldName: 'automationStrategyId' }
  ],
  targetingText: [
    { filterName: 'entityId', fieldName: 'entityId' },
    { filterName: 'portfolioId', fieldName: 'portfolioId' },
    { filterName: 'campaignId', fieldName: 'campaignId' },
    { filterName: 'derivedCampaignType', fieldName: 'derivedCampaignType' },
    { filterName: 'targetingType', fieldName: 'targetingType' },
    { filterName: 'targetingText', fieldName: 'targetingText' },
    { filterName: 'targetingTextFuzzy', fieldName: 'targetingTextFuzzy' },
    { filterName: 'excludedTargetingText', fieldName: 'excludedTargetingText' },
    { filterName: 'excludedTargetingTextFuzzy', fieldName: 'excludedTargetingTextFuzzy' },
    { filterName: 'targetState', fieldName: 'status' },
    { filterName: 'bid', fieldName: 'cpcMinBidAmount' }
  ],
  stacklineSku: [
    { filterName: 'entityId', fieldName: 'entityId' },
    { filterName: 'portfolioId', fieldName: 'portfolioId' },
    { filterName: 'campaignId', fieldName: 'campaignId' },
    { filterName: 'derivedCampaignType', fieldName: 'derivedCampaignType' },
    { filterName: 'keyword', fieldName: 'keyword' },
    { filterName: 'keywordPhrase', fieldName: 'keywordPhrase' },
    { filterName: 'keywordExact', fieldName: 'keyword' },
    { filterName: 'excludedKeyword', fieldName: 'excludedKeyword' },
    { filterName: 'excludedKeywordExact', fieldName: 'excludedKeyword' },
    { filterName: 'campaignProductState', fieldName: 'status' }
  ],
  entityId: [
    { filterName: 'entityNameFuzzy', fieldName: 'entityNameFuzzy' },
    { filterName: 'excludedEntityNameFuzzy', fieldName: 'excludedEntityNameFuzzy' }
  ]
};

export function extractQueryConditions(query: { [key: string]: unknown }, includeName?: boolean, includeId?: boolean) {
  const conditions: { [key: string]: unknown } = {};
  const segmentType = _get(query, ['segmentType'], '') as string;
  Object.keys(query).forEach((key) => {
    const queryValue = query[key];
    const termFilter = queryMapping.termFilters[key];
    const rangeFilter = queryMapping.rangeFilters[key];
    const isName = includeName ? key === 'dn' : null;
    const isId = includeId ? key === 'id' : null;

    let canContinue = true;
    if (['campaignId', 'stacklineSku', 'targetingText'].includes(segmentType)) {
      const fieldName = _get(termFilter, ['fieldName']);
      const filterDefs = _get(termFilterDef, [segmentType], []);
      if (!filterDefs.find((item) => item.fieldName === fieldName)) {
        canContinue = false;
      }
    }

    if (canContinue && (rangeFilter || termFilter || isName || isId)) {
      conditions[key] = queryValue;
    }
  });

  return conditions;
}

/**
 * Overrides the default retailer ID term filters with parameters from the supplied query
 */
export function mapQueryToConditions(
  query: { [key: string]: any },
  retailer: Pick<ReduxStore['retailer'], 'id'>
): Conditions {
  const conditions: Conditions = {
    termFilters: [
      {
        fieldName: 'retailerId',
        condition: 'must',
        values: [retailer.id]
      }
    ],
    rangeFilters: [] as RangeFilter[]
  };

  const segmentType = _get(query, ['segmentType'], '') as string;

  Object.keys(query).forEach((key) => {
    const queryValue = query[key];
    const termFilter = queryMapping.termFilters[key];
    const rangeFilter = queryMapping.rangeFilters[key];

    let canContinue = true;
    if (['campaignId', 'stacklineSku', 'targetingText'].includes(segmentType)) {
      const fieldName = _get(termFilter, ['fieldName']);
      const filterDefs = _get(termFilterDef, [segmentType], []);
      if (!filterDefs.find((item) => item.fieldName === fieldName)) {
        canContinue = false;
      }
    }

    if (canContinue && termFilter && queryValue && queryValue.length > 0) {
      if (conditions.termFilters) {
        conditions.termFilters.push({
          values: queryValue.map(_prop('i')),
          ...termFilter
        });
      }
      // }  else if (rangeFilter && ['adcpc', 'adroas'].includes(key)) {
      //   const filterDetail = {
      //     minValue: Number.isNaN(queryValue.g) ? getFormattedWeekNumber(moment(queryValue.g)) : queryValue.g,
      //     maxValue: Number.isNaN(queryValue.l) ? getFormattedWeekNumber(moment(queryValue.l)) : queryValue.l,
      //     ...rangeFilter
      //   }
      //   if (conditions.computeFilters) {
      //     conditions.computeFilters.push(filterDetail);
      //   } else {
      //     conditions.computeFilters = [filterDetail];
      //   }
    } else if (canContinue && rangeFilter && !['adcpc', 'adroas', 'adsp'].includes(key)) {
      if (conditions.rangeFilters) {
        conditions.rangeFilters.push({
          minValue: Number.isNaN(queryValue.g) ? getFormattedWeekNumber(moment(queryValue.g)) : queryValue.g,
          maxValue: Number.isNaN(queryValue.l) ? getFormattedWeekNumber(moment(queryValue.l)) : queryValue.l,
          ...rangeFilter
        });
      }
    }
  });
  return conditions;
}

const queryValueMapping: { [key: string]: string } = {};
Object.keys(queryMapping.termFilters).forEach((shortName: string) => {
  queryValueMapping[queryMapping.termFilters[shortName].fieldName] = shortName;
});
Object.keys(queryMapping.rangeFilters).forEach((shortName: string) => {
  queryValueMapping[queryMapping.rangeFilters[shortName].fieldName] = shortName;
});

export function mapConditionsToQuery(conditions: { [key: string]: any }) {
  const queryValues: { [key: string]: any } = {
    id: conditions.id,
    dn: conditions.dn
  };

  Object.keys(conditions.termFilters).forEach((key) => {
    const { values } = conditions.termFilters[key];
    if (_isEmpty(values)) {
      return;
    }

    const mappedValue = queryValueMapping[key];
    if (mappedValue) {
      queryValues[mappedValue] = values;
    }
  });

  Object.keys(conditions.rangeFilters).forEach((key) => {
    const { minValue, maxValue } = conditions.rangeFilters[key];
    if (minValue === undefined || maxValue === undefined) {
      return;
    }

    const mappedValue = queryValueMapping[key];
    if (mappedValue) {
      queryValues[mappedValue] = {};
      if (minValue instanceof Date) {
        queryValues[mappedValue].g = moment(minValue).format('YYYY-MM-DD');
        queryValues[mappedValue].l = moment(maxValue).format('YYYY-MM-DD');
      } else {
        queryValues[mappedValue].g = minValue;
        queryValues[mappedValue].l = maxValue;
      }
    }
  });

  return queryValues;
}

const appSpecificAdditionalFilters: {
  [key: string]: {
    termFilters: { [key: string]: { condition: 'should' | 'must' | 'must_not'; values: { i: string; n?: string }[] } };
    rangeFilters: { [key: string]: { minValue?: number; maxValue?: number } };
  };
} = {
  [`${AppName.Advertising}`]: {
    termFilters: {},
    rangeFilters: {
      costPerClick: { minValue: 0, maxValue: 1000 },
      returnOnAdSpend: { minValue: 0, maxValue: 1000 },
      spendComputed: { minValue: 0, maxValue: 1000000 },
      bid: { minValue: 0, maxValue: 1000 }
    }
  }
};

export interface FormData {
  dn?: string;
  id?: string;
  termFilters: { [key: string]: { condition: Condition; values: { i: string; n?: string }[] } };
  rangeFilters: { [key: string]: { minValue?: number; maxValue?: number } };
}

export function getEmptyFormData({ appName }: { appName: string }): FormData {
  const formData: {
    dn?: string;
    id?: string;
    termFilters: { [key: string]: { condition: Condition; values: { i: string; n?: string }[] } };
    rangeFilters: { [key: string]: { minValue?: number; maxValue?: number } };
  } = {
    termFilters: {
      retailerId: { condition: 'must', values: [] },
      keyword: { condition: 'should', values: [] },
      keywordPhrase: { condition: 'should.multi_match.phrase', values: [] },
      keywordExact: { condition: 'should.multi_match.exact', values: [] },
      excludedKeyword: { condition: 'must_not', values: [] },
      excludedKeywordExact: { condition: 'must_not', values: [] },
      searchTerm: { condition: 'should', values: [] },
      searchTermFuzzy: { condition: 'should', values: [] },
      excludedSearchTerm: { condition: 'must_not', values: [] },
      excludedSearchTermFuzzy: { condition: 'must_not', values: [] },
      searchKeyword: { condition: 'should', values: [] },
      searchKeywordFuzzy: { condition: 'should', values: [] },
      excludedSearchKeyword: { condition: 'must_not', values: [] },
      excludedSearchKeywordFuzzy: { condition: 'must_not', values: [] },
      targetingText: { condition: 'should', values: [] },
      targetingTextFuzzy: { condition: 'should', values: [] },
      excludedTargetingText: { condition: 'must_not', values: [] },
      excludedTargetingTextFuzzy: { condition: 'must_not', values: [] },
      isAutoAdded: { condition: 'should', values: [] },
      brandId: { condition: 'should', values: [] },
      excludedBrandId: { condition: 'must_not', values: [] },
      parentBrandId: { condition: 'should', values: [] },
      categoryId: { condition: 'should', values: [] },
      subCategoryId: { condition: 'should', values: [] },
      excludedCategoryId: { condition: 'must_not', values: [] },
      excludedSubCategoryId: { condition: 'must_not', values: [] },
      campaignName: { condition: 'should', values: [] },
      campaignNameFuzzy: { condition: 'should', values: [] },
      adGroupNameFuzzy: { condition: 'should', values: [] },
      retailerNameFuzzy: { condition: 'should', values: [] },
      excludedCampaignName: { condition: 'must_not', values: [] },
      excludedCampaignNameFuzzy: { condition: 'must_not', values: [] },
      portfolioName: { condition: 'should', values: [] },
      portfolioNameFuzzy: { condition: 'should', values: [] },
      excludedPortfolioName: { condition: 'must_not', values: [] },
      excludedPortfolioNameFuzzy: { condition: 'must_not', values: [] },
      entityName: { condition: 'should', values: [] },
      entityNameFuzzy: { condition: 'should', values: [] },
      excludedEntityName: { condition: 'must_not', values: [] },
      excludedEntityNameFuzzy: { condition: 'must_not', values: [] },
      entityId: { condition: 'should', values: [] },
      campaignId: { condition: 'should', values: [] },
      portfolioId: { condition: 'should', values: [] },
      automationStrategyId: { condition: 'should', values: [] },
      derivedCampaignType: { condition: 'should', values: [] },
      statusDerived: { condition: 'should', values: [] },
      portfolioState: { condition: 'should', values: [] },
      targetingType: { condition: 'should', values: [] },
      targetState: { condition: 'should', values: [] },
      campaignProductState: { condition: 'should', values: [] },
      breadCrumbText: { condition: 'should', values: [] },
      excludedBreadCrumbText: { condition: 'must_not', values: [] }
    },
    rangeFilters: {
      retailPrice: { minValue: 0, maxValue: 1000000 }
    }
  };
  if (appName === 'advertising') {
    delete formData.rangeFilters.retailPrice;
  }
  if (appSpecificAdditionalFilters[appName]) {
    formData.termFilters = {
      ...formData.termFilters,
      ..._cloneDeep(appSpecificAdditionalFilters[appName].termFilters)
    };
    formData.rangeFilters = {
      ...formData.rangeFilters,
      ..._cloneDeep(appSpecificAdditionalFilters[appName].rangeFilters)
    };
  }
  return formData;
}

// REFACTOR
export function prefillFormData({ queryParams, appName }: { queryParams: any; appName: string }) {
  const formData: {
    dn?: string;
    id?: string;
    termFilters: { [key: string]: { condition: 'should' | 'must' | 'must_not'; values: { i: string; n?: string }[] } };
    rangeFilters: { [key: string]: { minValue?: number; maxValue?: number } };
  } = getEmptyFormData({ appName });
  if (queryParams) {
    formData.dn = queryParams.dn;
    formData.id = queryParams.id;

    Object.keys(queryParams).forEach((key) => {
      const value = queryParams[key];
      const termFilter = queryMapping.termFilters[key];
      const rangeFilter = queryMapping.rangeFilters[key];
      if (termFilter) {
        formData.termFilters[termFilter.fieldName].values = value;
      } else if (rangeFilter && formData.rangeFilters[rangeFilter.fieldName]) {
        formData.rangeFilters[rangeFilter.fieldName].minValue = moment(value.g, 'YYYY-MM-DD', true).isValid()
          ? new Date(value.g)
          : value.g;
        formData.rangeFilters[rangeFilter.fieldName].maxValue = moment(value.l, 'YYYY-MM-DD', true).isValid()
          ? new Date(value.l)
          : value.l;
      }
    });
  }

  return formData;
}
