import { Box } from '@mui/system';
import { ExpandRowColumnLayout, hexToRgba, useStacklineTheme } from '@stackline/ui';
import React, { useMemo } from 'react';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import { GenericChip } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/defaultConfigs';
import {
  AccuracyDetails,
  BulkAdjustmentStatus,
  BulkAdjustmentUploadDatum
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/serverProxy/types';
import { planTypeToDisplayName } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { BulkAdjustmentEllipsesMenu } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Adjustments/EllipsesMenu';
import { GET_BULK_ADJUSTMENT_UPLOADS_QUERY_KEY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Adjustments/constants';
import {
  useBulkAdjustmentUploadData,
  useUserData
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Adjustments/hooks';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import UserTimestampCell from 'src/components/BeaconRedesignComponents/UserTimestampCell/UserTimestampCell';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { formatTime } from 'src/utils/dateformatting';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { useBulkAdjustmentContext } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/hooks/useBulkAdjustmentContext';
import { BulkAdjustmentModalType } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkAdjustmentProvider';
import { TemplateProcessingStatus } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkTemplateProcessingModal';
import Pagination from 'src/components/BeaconRedesignComponents/Pagination/Pagination';
import { bulkAdjustmentLevels } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkTemplateDownloadModal';
import { TemplateSubmittingStatus } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkTemplateSubmittingModal';
import { BEACON_PRO_PAGE_MARGIN_BOTTOM } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import CreateBulkAdjustmentButton, {
  BulkAdjustmentOptions
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/CreateBulkAdjustmentButton';
import { NoDataPlaceHolderTableRow } from 'src/components/BeaconRedesignComponents/common/NoDataPlaceHolder/NoDataPlaceHolder';
import { useLatestForecastModel } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';

// TODO: Integrate Export/Delete APIs when available

const ROWS_PER_PAGE = 20;
const BulkAdjustmentUploadsPageLayout = () => {
  const { data, isLoading, isError, page, onNextPage, onPreviousPage } = useBulkAdjustmentUploadData({
    pageSize: ROWS_PER_PAGE
  });
  const { openBulkAdjustmentModal } = useBulkAdjustmentContext();
  const { currentPublishVersion, loading: modelLoading } = useLatestForecastModel();

  // Used for determining the level adjusted for each bulk adjustment
  const client = useAppSelector((state) => state.user.config.vendor.CompanyName);
  const categories = useAppSelector((state) => state.categories);
  const subcategories = useAppSelector((state) => state.subCategories);
  const brands = useAppSelector((state) => state.brandsFollowing);

  // Scrape user IDs from bulk adjustment data
  const userIds = data ? Array.from(new Set(data.result.map(({ userId }) => userId).filter((id) => id))) : null;

  // Fetch user metadata
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError
  } = useUserData({
    userIds,
    enabled: !!userIds && !!userIds.length,
    queryKeys: [GET_BULK_ADJUSTMENT_UPLOADS_QUERY_KEY, userIds]
  });

  // Combine user metadata with bulk adjustment data
  const rowData: BulkAdjustmentUploadDatum[] =
    !isLoading && !isUserDataLoading && data && userData
      ? data.result.map((datum) => ({
          disableRowClick: ![
            BulkAdjustmentStatus.Published,
            BulkAdjustmentStatus.Incomplete,
            BulkAdjustmentStatus.Processing,
            BulkAdjustmentStatus.Draft
          ].includes(datum.bulkAdjustmentStatus),
          ...userData[datum.userId],
          ...datum
        }))
      : isError || isUserDataError
      ? []
      : [];

  /**
   * Map to track revertible bulk adjustments by plan type.
   * We only allow the user to revert the most recent published bulk adjustment of that plan type.
   */
  const revertibleBulkAdjustmentsPerPlan = useMemo(() => {
    try {
      if (!rowData || !rowData.length) {
        return null;
      } else {
        // Create map to track revertible bulk adjustments by plan type
        const revertibleBulkAdjustments = new Map<string, string>();
        // Filter on published bulk adjustments
        const publishedBulkAdjustments = rowData.filter(
          (row) => row.bulkAdjustmentStatus === BulkAdjustmentStatus.Published
        );

        // Map the latest revertible bulk adjustment by plan type
        publishedBulkAdjustments.forEach((adjustment) => {
          const { planType, bulkAdjustmentId } = adjustment;
          if (!revertibleBulkAdjustments.has(planType)) {
            revertibleBulkAdjustments.set(planType, bulkAdjustmentId);
          }
        });
        return revertibleBulkAdjustments;
      }
    } catch (err) {
      console.error('Failed to map revertible bulk adjustments: ', err);
      return null;
    }
  }, [rowData]);

  const formatMetric = useMetricFormatter();
  const theme = useStacklineTheme();

  /**
   * Determines color for the status chip
   */
  const statusToColor = {
    [BulkAdjustmentStatus.Published]: theme.colors.primaryLight,
    [BulkAdjustmentStatus.Draft]: theme.colors.accentGold,
    [BulkAdjustmentStatus.Incomplete]: theme.colors.accentTangerine,
    [BulkAdjustmentStatus.Processing]: theme.colors.success,
    [BulkAdjustmentStatus.Reverting]: theme.colors.primary,
    [BulkAdjustmentStatus.Reverted]: theme.colors.primary,
    [BulkAdjustmentStatus.Deleted]: theme.colors.error
  };

  const handleRowClick = (row: BulkAdjustmentUploadDatum) => {
    if (row.bulkAdjustmentStatus === BulkAdjustmentStatus.Published) {
      const { bulkAdjustmentId, bulkAdjustmentTitle, bulkAdjustmentDescription, planType } = row;
      openBulkAdjustmentModal({
        type: BulkAdjustmentModalType.ViewSummary,
        state: {
          bulkAdjustmentId,
          uploadedAdjustment: {
            bulkAdjustmentTitle,
            bulkAdjustmentDescription,
            planType
          }
        }
      });
    } else if ([BulkAdjustmentStatus.Incomplete, BulkAdjustmentStatus.Draft].includes(row.bulkAdjustmentStatus)) {
      openBulkAdjustmentModal({
        type: BulkAdjustmentModalType.TemplateProcessing,
        state: {
          status: TemplateProcessingStatus.ResumePolling,
          bulkAdjustmentId: row.bulkAdjustmentId,
          uploadedAdjustment: {
            bulkAdjustmentTitle: row.bulkAdjustmentTitle,
            bulkAdjustmentDescription: row.bulkAdjustmentDescription,
            planType: row.planType
          },
          computeNetImpact: row.publishVersion < currentPublishVersion,
          percent: 0
        }
      });
    } else if (row.bulkAdjustmentStatus === BulkAdjustmentStatus.Processing) {
      openBulkAdjustmentModal({
        type: BulkAdjustmentModalType.TemplateSubmitting,
        state: {
          status: TemplateSubmittingStatus.SubmissionResume,
          percentage: 0,
          bulkAdjustmentId: row.bulkAdjustmentId,
          planType: row.planType
        }
      });
    }
  };

  /**
   * Returns the level display name and the entity adjusted for the given level and level-adjusted ID
   */
  const getAdjustedEntityAndLevelDisplayName = (id: number, level: bulkAdjustmentLevels) => {
    let levelDisplayName = '';
    let entityAdjusted = '';

    switch (level) {
      case bulkAdjustmentLevels.client:
        entityAdjusted = client;
        levelDisplayName = 'Organization';
        break;
      case bulkAdjustmentLevels.category: {
        const adjustedCategory = categories.find((category) => category.id === id);
        if (!adjustedCategory) {
          entityAdjusted = '';
          levelDisplayName = '';
        } else {
          entityAdjusted = adjustedCategory.name;
          levelDisplayName = 'Category';
        }
        break;
      }
      case bulkAdjustmentLevels.subcategory: {
        const adjustedSubcategory = subcategories.find((subcategory) => subcategory.subCategoryId === id);
        if (!adjustedSubcategory) {
          entityAdjusted = '';
          levelDisplayName = '';
        } else {
          entityAdjusted = adjustedSubcategory.name;
          levelDisplayName = 'Subcategory';
        }
        break;
      }
      case bulkAdjustmentLevels.brand: {
        const adjustedBrand = brands.find((brand) => brand.id === id);
        if (!adjustedBrand) {
          entityAdjusted = '';
          levelDisplayName = '';
        } else {
          entityAdjusted = adjustedBrand.displayName;
          levelDisplayName = 'Brand';
        }
        break;
      }
      default:
        levelDisplayName = '';
        entityAdjusted = '';
        break;
    }

    return { levelDisplayName, entityAdjusted };
  };

  return (
    <Box>
      <Flex justifyContent="space-between">
        <Text variant="h2">Adjustment Uploads</Text>
        <CreateBulkAdjustmentButton
          options={[
            {
              id: BulkAdjustmentOptions.UploadTemplate,
              label: 'Upload bulk template'
            }
          ]}
          onAddSingularAdjustment={() => {}}
        />
      </Flex>

      <Flex marginTop="32px" flexDirection="column" marginBottom={BEACON_PRO_PAGE_MARGIN_BOTTOM}>
        <EntityTable
          skeletonRows={ROWS_PER_PAGE}
          showPagination={false}
          appTableProps={{
            onToggleRowExpand: (event) => {
              // Prevents opening the bulk adjustment modal when
              // clicking the expand icon
              event.stopPropagation();
            },
            noDataPlaceholder: (
              <NoDataPlaceHolderTableRow
                title="No adjustments yet"
                description="There are currently no adjustments to display. All your adjustment uploads will be available on this page."
              />
            )
          }}
          isLoading={isLoading || isUserDataLoading || modelLoading}
          shouldModifyColumnDefs={false}
          rowData={rowData}
          onRowClick={handleRowClick}
          columnDefs={[
            // OWNED BY
            {
              headerName: 'Owned By',
              field: 'ownedBy',
              tableColumnProps: {
                style: {
                  width: '254px'
                }
              },
              valueFormatter: (_, { firstName, lastName, userId, lastUpdatedTime }: BulkAdjustmentUploadDatum) => {
                return (
                  <UserTimestampCell
                    firstName={firstName}
                    lastName={lastName}
                    userId={userId}
                    subtitle={`Uploaded on ${formatTime(new Date(lastUpdatedTime), 'MMM D, YYYY')}`}
                  />
                );
              }
            },
            // PLAN TYPE
            {
              headerName: 'Plan Type',
              field: 'planType',
              tableColumnProps: {
                style: {
                  width: '200px'
                }
              },
              valueFormatter: (_, { planType }: BulkAdjustmentUploadDatum) => {
                return <Text variant="body2">{planTypeToDisplayName[planType]}</Text>;
              }
            },
            // ACCURACY RATE
            {
              headerName: 'Accuracy Rate',
              field: 'accuracyRate',
              tableColumnProps: {
                style: {
                  width: '220px'
                }
              },
              valueFormatter: (_, row: BulkAdjustmentUploadDatum) => {
                let numberOfErrors = 0;
                let percentAccurate = 0;
                let numberOfSuccessful = 0;

                try {
                  const { accuracyDetails } = row;
                  const details: AccuracyDetails = JSON.parse(accuracyDetails);

                  if ('numOfFailedAdjs' in details) {
                    const { accuracyPercentage, numOfFailedAdjs, totalAdjustments } = details;
                    // TODO:: This number cannot be 100% trusted since we do not know how many adjustments were successful.
                    // e.g.) num of errors returns more than number of adjs, but some adjs were passed.
                    // Product wants to add different error from backend, but until then we will have this error number
                    if (Number(numOfFailedAdjs) > Number(totalAdjustments)) {
                      percentAccurate = 0;
                      numberOfErrors = Number(totalAdjustments);
                      numberOfSuccessful = 0;
                    } else {
                      percentAccurate = Number(accuracyPercentage) / 100;
                      numberOfErrors = Number(numOfFailedAdjs);
                      numberOfSuccessful = Number(totalAdjustments) - Number(numOfFailedAdjs);
                    }
                  } else if ('numOfSuccessfulAdjs' in details) {
                    const { accuracyPercentage, numOfSuccessfulAdjs, totalAdjustments } = details;
                    percentAccurate = Number(accuracyPercentage) / 100;
                    numberOfErrors = Number(totalAdjustments) - Number(numOfSuccessfulAdjs);
                    numberOfSuccessful = Number(numOfSuccessfulAdjs);
                  }
                } catch (err) {
                  console.error('Failed to parse accuracy details: ', err);
                }

                return (
                  <Flex flexDirection="column">
                    <Text variant="body2">{formatMetric(percentAccurate, METRICTYPE.PERCENT)}</Text>
                    <Text color="secondary" variant="body3">
                      {formatMetric(numberOfSuccessful, METRICTYPE.VOLUME)} accurate,{' '}
                      {formatMetric(numberOfErrors, METRICTYPE.VOLUME)} errors
                    </Text>
                  </Flex>
                );
              }
            },
            // STATUS
            {
              headerName: 'Status',
              field: 'status',
              tableColumnProps: {
                style: {
                  width: '180px'
                }
              },
              valueFormatter: (_, { bulkAdjustmentStatus }: BulkAdjustmentUploadDatum) => {
                return (
                  <GenericChip
                    paddingX="20px"
                    content={
                      <Text
                        sx={{
                          color:
                            bulkAdjustmentStatus === BulkAdjustmentStatus.Published
                              ? theme.colors.info
                              : statusToColor[bulkAdjustmentStatus] || theme.colors.primary
                        }}
                        variant="subtitle2"
                      >
                        {bulkAdjustmentStatus}
                      </Text>
                    }
                    color={hexToRgba(statusToColor[bulkAdjustmentStatus] || theme.colors.primary, 0.2)}
                  />
                );
              }
            },
            // ELLIPSES MENU
            {
              field: '',
              headerName: '',
              tableColumnProps: {
                style: {}
              },
              valueFormatter: (_, row: BulkAdjustmentUploadDatum) => {
                const enableRevert = revertibleBulkAdjustmentsPerPlan
                  ? revertibleBulkAdjustmentsPerPlan.get(row.planType) === row.bulkAdjustmentId
                  : false;
                return <BulkAdjustmentEllipsesMenu row={row} enableRevert={enableRevert} />;
              }
            },
            // EXPAND ROW
            {
              field: '',
              headerName: '',
              tableColumnProps: {
                style: {}
              },
              expandRow: true
            }
          ]}
          expandRowDef={{
            containerClassName: '',
            valueFormatter: ({
              bulkAdjustmentTitle,
              bulkAdjustmentLevel,
              bulkAdjustmentLevelId,
              bulkAdjustmentId
            }: BulkAdjustmentUploadDatum) => {
              const { levelDisplayName, entityAdjusted } = getAdjustedEntityAndLevelDisplayName(
                Number(bulkAdjustmentLevelId),
                bulkAdjustmentLevel as bulkAdjustmentLevels
              );

              const levelAdjusted =
                levelDisplayName.length && entityAdjusted.length ? `${levelDisplayName}: ${entityAdjusted}` : '';

              return (
                <Box height="auto" paddingX="24px" paddingY="16px">
                  <Flex>
                    <Flex width="385px" gap="sm" flexDirection="column" marginRight="60px">
                      <Text variant="subtitle2">Title</Text>
                      <div style={{ overflowWrap: 'anywhere' }}>
                        <Text variant="body2">{bulkAdjustmentTitle}</Text>
                      </div>
                    </Flex>

                    <Flex width="170px" gap="sm" flexDirection="column" marginRight="49px">
                      <Text variant="subtitle2">Level Adjusted</Text>
                      <div style={{ overflowWrap: 'anywhere' }}>
                        <Text variant="body2">{levelAdjusted}</Text>
                      </div>
                    </Flex>

                    <Flex width="125px" gap="sm" flexDirection="column">
                      <Text variant="subtitle2">Batch ID</Text>
                      <Text variant="body2">{bulkAdjustmentId}</Text>
                    </Flex>
                  </Flex>
                </Box>
              );
            },
            columns: {
              field: {
                title: '',
                layout: ExpandRowColumnLayout.Table,
                fields: {}
              }
            }
          }}
        />
        <Flex justifyContent="flex-end">
          <Pagination
            shouldUseInfinitePagination
            currentPage={page}
            onClickNext={onNextPage}
            onClickPrevious={onPreviousPage}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default BulkAdjustmentUploadsPageLayout;
