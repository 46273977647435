/**
 * This component handles connecting the `AdvancedSearchSideBar` up to Redux, keeping the application's global filters
 * and query conditions up to date with changes to sidebar's filters.
 */

import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import memoizeOne from 'memoize-one';
import queryString from 'qs';
import { store } from 'src/main';
import { addPersistentQueryParams } from 'src/utils/browser';
import AdvancedSearchSideBar from './AdvancedSearchSideBar';
import { buildSubcategoryListItemFilterDefinition, buildCategoryListItemFilterDefinition } from './Filters';
import { updateSavedSearch } from 'src/store/modules/segments/operations';
import { filterNils, prop } from 'src/utils/fp';
import { AppName } from 'sl-api-connector';
import { prefillFormData, mapConditionsToQuery } from 'src/utils/segments';
import { shouldShowNewBeacon } from 'src/utils/app';

const addKeyToValues = (param) => param.map(({ ...item }) => ({ ...item, isChecked: false }));

export const segmentFilterDefinitions = [
  [
    'keywordsSearch',
    'keywords',
    {
      idFieldName: 'keyword',
      headerDisplayName: 'Include',
      searchFieldHintText: 'Product tags to include'
    }
  ],
  [
    'keywordsSearch',
    'excludedKeywords',
    {
      idFieldName: 'excludedKeyword',
      headerDisplayName: 'Exclude',
      searchFieldHintText: 'Product tags to exclude'
    }
  ]
];

export const segmentFilterDefinitionsForAtlasSuperUser = [
  [
    'keywordsSearch',
    'keywords',
    {
      idFieldName: 'keyword',
      headerDisplayName: 'Include - Keyword',
      searchFieldHintText: 'Add keyword to include'
    }
  ],
  [
    'keywordsSearch',
    'keywordPhrase',
    {
      idFieldName: 'keywordPhrase',
      headerDisplayName: 'Include - Phrase',
      searchFieldHintText: 'Add phrase search to include'
    }
  ],
  [
    'keywordsSearch',
    'excludedKeywords',
    {
      idFieldName: 'excludedKeyword',
      headerDisplayName: 'Exclude - Phrase',
      searchFieldHintText: 'Add phrase search to exclude'
    }
  ]
]

export const segmentFilterDefinitionsForAtlas = [
  [
    'keywordsSearch',
    'keywordPhrase',
    {
      idFieldName: 'keywordPhrase',
      headerDisplayName: 'Include - Phrase',
      searchFieldHintText: 'Add phrase search to include'
    }
  ],
  [
    'keywordsSearch',
    'excludedKeywords',
    {
      idFieldName: 'excludedKeyword',
      headerDisplayName: 'Exclude - Phrase',
      searchFieldHintText: 'Add phrase search to exclude'
    }
  ]

  // TODO: Atlas - Temporarily hiding the following 2 EXACT fields, till Atlas Traffic Elastic Search documents are fixed to support them
  // [
  //   'keywordsSearch',
  //   'keywordExact',
  //   {
  //     idFieldName: 'keywordExact',
  //     headerDisplayName: 'Include - Exact',
  //     searchFieldHintText: 'Add exact search to include'
  //   }
  // ],
  // [
  //   'keywordsSearch',
  //   'excludedKeywordsExact',
  //   {
  //     idFieldName: 'excludedKeywordExact',
  //     headerDisplayName: 'Exclude - Exact',
  //     searchFieldHintText: 'Add exact search to exclude'
  //   }
  // ]
];

export const segmentFilterDefinitionsForDev = [
  [
    'keywordsSearch',
    'keywords',
    {
      idFieldName: 'keyword',
      headerDisplayName: 'Include - Keyword',
      searchFieldHintText: 'Add keyword to include'
    }
  ],
  [
    'keywordsSearch',
    'keywordExact',
    {
      idFieldName: 'keywordExact',
      headerDisplayName: 'Include - Exact',
      searchFieldHintText: 'Add product tags to include'
    }
  ],
  [
    'keywordsSearch',
    'keywordPhrase',
    {
      idFieldName: 'keywordPhrase',
      headerDisplayName: 'Include - Phrase',
      searchFieldHintText: 'Enter keyword phrase to include'
    }
  ],
  [
    'keywordsSearch',
    'excludedKeywords',
    {
      idFieldName: 'excludedKeyword',
      headerDisplayName: 'Exclude',
      searchFieldHintText: 'Add product tags to exclude'
    }
  ],
  [
    'keywordsSearch',
    'excludedKeywordsExact',
    {
      idFieldName: 'excludedKeywordExact',
      headerDisplayName: 'Exclude - Exact',
      searchFieldHintText: 'Add product tags to exclude'
    }
  ]
];

export const searchTermlistFilterDefinitions = [
  [
    'keywordsSearch',
    'searchTermsFuzzy',
    {
      idFieldName: 'searchTermFuzzy',
      headerDisplayName: 'Include - Phrase',
      searchFieldHintText: shouldShowNewBeacon() ? 'Keyword phrase to include' : 'Enter keyword phrase to include'
    }
  ],
  [
    'keywordsSearch',
    'searchTerms',
    {
      idFieldName: 'searchTerm',
      headerDisplayName: 'Include - Exact',
      searchFieldHintText: shouldShowNewBeacon() ? 'Exact keyword to include' : 'Enter exact keyword to include'
    }
  ],
  [
    'keywordsSearch',
    'excludedSearchTermFuzzy',
    {
      idFieldName: 'excludedSearchTermFuzzy',
      headerDisplayName: 'Exclude - Phrase',
      searchFieldHintText: shouldShowNewBeacon() ? 'Keyword phrase to exclude' : 'Enter keyword phrase to exclude'
    }
  ],
  [
    'keywordsSearch',
    'excludedSearchTerms',
    {
      idFieldName: 'excludedSearchTerm',
      headerDisplayName: 'Exclude - Exact',
      searchFieldHintText: shouldShowNewBeacon() ? 'Exact keyword to exclude' : 'Enter exact keyword to exclude'
    }
  ]
];

export const searchKeywordFilterDefinitions = [
  [
    'keywordsSearch',
    'searchKeywordFuzzy',
    {
      idFieldName: 'searchKeywordFuzzy',
      headerDisplayName: 'Include (phrase)',
      searchFieldHintText: 'Enter keyword phrase to include'
    }
  ],
  [
    'keywordsSearch',
    'searchKeyword',
    {
      idFieldName: 'searchKeyword',
      headerDisplayName: 'Include (exact)',
      searchFieldHintText: 'Enter exact keyword to include'
    }
  ],
  [
    'keywordsSearch',
    'excludedSearchKeywordFuzzy',
    {
      idFieldName: 'excludedSearchKeywordFuzzy',
      headerDisplayName: 'Exclude (phrase)',
      searchFieldHintText: 'Enter keyword phrase to exclude'
    }
  ],
  [
    'keywordsSearch',
    'excludedSearchKeyword',
    {
      idFieldName: 'excludedSearchKeyword',
      headerDisplayName: 'Exclude (exact)',
      searchFieldHintText: 'Enter exact keyword to exclude'
    }
  ]
];

export const targetingTextFilterDefinitions = [
  [
    'keywordsSearch',
    'targetingTextFuzzy',
    {
      idFieldName: 'targetingTextFuzzy',
      headerDisplayName: 'Include (phrase)',
      searchFieldHintText: 'Enter target phrase to include'
    }
  ],
  [
    'keywordsSearch',
    'targetingText',
    {
      idFieldName: 'targetingText',
      headerDisplayName: 'Include (exact)',
      searchFieldHintText: 'Enter exact target to include'
    }
  ],
  [
    'keywordsSearch',
    'excludedTargetingTextFuzzy',
    {
      idFieldName: 'excludedTargetingTextFuzzy',
      headerDisplayName: 'Exclude (phrase)',
      searchFieldHintText: 'Enter target phrase to exclude'
    }
  ],
  [
    'keywordsSearch',
    'excludedTargetingText',
    {
      idFieldName: 'excludedTargetingText',
      headerDisplayName: 'Exclude (exact)',
      searchFieldHintText: 'Enter exact target to exclude'
    }
  ]
];

export const optimizationCampaignFilterDefinitions = [
  [
    'keywordsSearch',
    'campaignName',
    {
      idFieldName: 'campaignName',
      headerDisplayName: 'Include (exact)',
      searchFieldHintText: 'Enter exact campaign name'
    }
  ]
];

export const optimizationTargetFilterDefinitions = [
  [
    'keywordsSearch',
    'searchKeywordFuzzy',
    {
      idFieldName: 'searchKeywordFuzzy',
      headerDisplayName: 'Include (phrase)',
      searchFieldHintText: 'Enter target phrase to include'
    }
  ],
  [
    'keywordsSearch',
    'searchKeyword',
    {
      idFieldName: 'searchKeyword',
      headerDisplayName: 'Include (exact)',
      searchFieldHintText: 'Enter exact target to include'
    }
  ]
];

export const advertisingMetricFilterDefinitions = [
  [
    'rangeSelector',
    'spendComputed',
    {
      idFieldName: 'spendComputed',
      filterName: 'spendComputed',
      displayName: 'Ad Spend'
    }
  ],
  [
    'rangeSelector',
    'returnOnAdSpend',
    {
      idFieldName: 'returnOnAdSpend',
      filterName: 'returnOnAdSpend',
      displayName: 'ROAS'
    }
  ],
  [
    'rangeSelector',
    'costPerClick',
    {
      idFieldName: 'costPerClick',
      filterName: 'costPerClick',
      displayName: 'CPC'
    }
  ]
];
const getFilterComponentDefinitions = memoizeOne(
  (
    entityDefinition,
    selectedCategories,
    { app, allSuperUserCategories, allSuperUserSubCategories, categories, subCategories, user, segments, location }
  ) => {
    const queryParams = queryString.parse(location.search);
    const shouldSupportExport = Boolean(queryParams.supportExport);
    const showAllSuperUserCategories =
      user.config.isStacklineSuperUser && location.pathname.includes('/search') && app.name === AppName.Atlas;
    let categoriesAvailable = _cloneDeep(
      (showAllSuperUserCategories ? allSuperUserCategories : categories).filter((x) => x.categoryId !== 0)
    );
    let subCategoriesAvailable = [];

    if (!_isEmpty(selectedCategories)) {
      const selectedCategoryIds = selectedCategories.map((category) => category.i);
      (showAllSuperUserCategories ? allSuperUserSubCategories : subCategories).forEach((subCategory) => {
        if (selectedCategoryIds.includes(`${subCategory.parentCategoryId}`)) {
          subCategoriesAvailable.push(subCategory);
        }
      });
    }

    if (_isEmpty(subCategoriesAvailable)) {
      subCategoriesAvailable = _cloneDeep(showAllSuperUserCategories ? allSuperUserSubCategories : subCategories);
    }

    categoriesAvailable = addKeyToValues(categoriesAvailable);
    subCategoriesAvailable = addKeyToValues(subCategoriesAvailable);

    const onAtlasDev = app && app.name === 'atlas' && app.stage === 'dev';
    const onBeaconDev = app && app.name === 'beacon' && app.stage === 'dev';
    const isAtlas = app && app.name === AppName.Atlas;

    // We parse the URL into our form data to determine which brand values for include/excludes
    const { termFilters } = prefillFormData({ queryParams: app.queryParams, appName: app.name });
    const brandValues = termFilters;
    const { excludedBrandId } = brandValues;
    let brandDriver = excludedBrandId.values.length > 0 ? 'excludedBrandId' : 'brandId';

    if (onAtlasDev && onBeaconDev && user.config.isStacklineSuperUser) {
      brandDriver = 'brandId';
    }

    let searchTermToUse = [];

    switch (entityDefinition.type) {
      case 'searchtermlist':
        searchTermToUse = searchTermlistFilterDefinitions;
        break;
      default:
        if (onAtlasDev && user.config.isStacklineSuperUser) {
          searchTermToUse = segmentFilterDefinitionsForAtlasSuperUser;
        }
        else if (isAtlas) {
          searchTermToUse = segmentFilterDefinitionsForAtlas;
        } else if (onAtlasDev || onBeaconDev) {
          searchTermToUse = segmentFilterDefinitionsForDev;
        } else {
          searchTermToUse = segmentFilterDefinitions;
        }
        break;
    }

    // Controls whether or not we render Include(phrase/exact), Excluded Brands, Excluded Categories/Subcats, BreadCrumbs, and Range as filter choices (used for Dev only)
    return filterNils([
      [
        'displayNameAdvancedSearch',
        'dn',
        {
          shouldSupportExport
        }
      ],
      ...searchTermToUse,
      ['brandsAdvancedSearch', brandDriver],
      user.config.isStacklineSuperUser ? ['excludedBrandsAdvancedSearch', 'excludedBrandId'] : null,
      buildCategoryListItemFilterDefinition(categoriesAvailable),
      buildSubcategoryListItemFilterDefinition(subCategoriesAvailable),
      user.config.isStacklineSuperUser ? ['excludedCategories', 'excludedCategories'] : null,
      user.config.isStacklineSuperUser ? ['excludedSubCategories', 'excludedSubCategories'] : null,

      user.config.isStacklineSuperUser || onBeaconDev
        ? [
            'keywordsSearch',
            'breadCrumbText',
            {
              idFieldName: 'breadCrumbText',
              headerDisplayName: 'Breadcrumb',
              searchFieldHintText: 'Add breadcrumbs to include'
            }
          ]
        : null,
      user.config.isStacklineSuperUser || onBeaconDev
        ? [
            'keywordsSearch',
            'excludedBreadCrumbText',
            {
              idFieldName: 'excludedBreadCrumbText',
              headerDisplayName: 'Breadcrumb - Exclude',
              searchFieldHintText: 'Add breadcrumbs to exclude'
            }
          ]
        : null,
      ...(user.config.profile.email.toLowerCase().includes('nike')
        ? [
            ['segmentsFilter', 'segment'],
            ...segments.businessUnits
              .filter(prop('isTopLevel'))
              .map((businessUnit) => [
                'businessUnitFilter',
                'businessUnitsByParentId',
                { parentBusinessUnit: businessUnit, keyOverride: businessUnit.id }
              ])
          ]
        : []),
      onAtlasDev || onBeaconDev
        ? [
            'rangeSelector',
            'retailPrice',
            {
              idFieldName: 'retailPrice',
              filterName: 'retailPrice',
              displayName: 'Retail Price'
            }
          ]
        : null
    ]);
  }
);

const mkBlankSearchTerm = () => ({ condition: 'should', values: [] });

const updateUrlParams = ({
  doAggregation = true,
  entityType,
  props: { retailer, mainTimePeriod, history, location },
  state: { entityDefinition, segment, businessUnitsByParentId },
  formData
}) => {
  const queryParams = queryString.parse(location.search);
  if (entityType && entityDefinition.type !== entityType) {
    if (entityDefinition.type === 'segment' && entityType === 'searchtermlist') {
      formData.termFilters.searchTermFuzzy = formData.termFilters.keyword;
      formData.termFilters.keyword = mkBlankSearchTerm();
      formData.termFilters.searchTerm = mkBlankSearchTerm();
    } else {
      formData.termFilters.keyword = formData.termFilters.searchTermFuzzy;
      formData.termFilters.searchTermFuzzy = mkBlankSearchTerm();
      formData.termFilters.searchTerm = mkBlankSearchTerm();
      formData.termFilters.excludedSearchTerm = mkBlankSearchTerm();
    }
  }

  const urlParams = mapConditionsToQuery(formData);
  // Remove empty items to keep the url as short as possible
  const filteredBUState = Object.entries(businessUnitsByParentId || {}).reduce(
    (acc, [key, val]) => (_isEmpty(val) ? acc : { ...acc, [key]: val }),
    {}
  );
  const serializedBUs = JSON.stringify(filteredBUState);

  // in atlas dev the key kp mapping is not correct when switch the tab in advanced search, so here we correct it.
  if (entityType === 'searchtermlist' && urlParams.kp) {
    const tempValue = urlParams.kp;
    delete urlParams.kp;
    urlParams.stf = tempValue;
  }

  history.push(
    `/search?${addPersistentQueryParams(retailer, mainTimePeriod)}&doAggregation=${doAggregation}&entityType=${
      entityType || entityDefinition.type
    }&${queryString.stringify(urlParams)}&showPriceData=${queryParams.showPriceData === '1' ? '1' : '0'}&segment=${
      segment || ''
    }&businessUnits=${serializedBUs}`
  );
};

const buildTabAndSubtab = (appName, entityDefinition, pageType) => {
  if (pageType === 'entityPage') {
    if (entityDefinition.type === 'searchtermlist') {
      return 'tab=traffic&subtab=totalClicks';
    }

    return 'tab=sales&subtab=retailSales';
  }

  return `tab=summary&subtab=${appName}&entityType=${entityDefinition.type}`;
};

export const handleSubmit = async (
  { app, retailer, mainTimePeriod, history },
  { isFormValid, id, entityDefinition },
  formData,
  setState
) => {
  const { segments } = store.getState();
  const lowerCaseDisplayName = (formData.dn || '').toLowerCase();

  const hasNameCollision = !!Array.from(segments.savedSearchesById.values()).find(
    (savedSearch) => savedSearch.id !== id && savedSearch.displayName.toLowerCase() === lowerCaseDisplayName
  );
  if (hasNameCollision) {
    // eslint-disable-next-line no-alert
    alert('You must pick a unique name for this segment; a segment/BU with the same name already exists.');
    return;
  }

  setState({ isFormSubmitted: true });

  if (!isFormValid) {
    return;
  }

  const additionalParam = {};
  if (app.name === 'advertising' && app.queryParams.groupByField) {
    additionalParam.segmentType = app.queryParams.groupByField;
  }

  setState({ saveButtonText: `Saving ${entityDefinition.displayName}...` });

  // This is where form data is dispatched; note the boolean value being passed in to determine if refresh should occur.
  const response = await store.dispatch(
    updateSavedSearch(entityDefinition.type, formData, id, null, true, additionalParam)
  );

  setState({ saveButtonText: `Saved ${entityDefinition.displayName}!` });

  setTimeout(() => {
    history.push(
      response.data !== undefined
        ? `/${entityDefinition.type}/${response.data}?${addPersistentQueryParams(
            retailer,
            mainTimePeriod
          )}&${buildTabAndSubtab(app.name, entityDefinition, 'entityPage')}`
        : `/?${addPersistentQueryParams(retailer, mainTimePeriod)}&${buildTabAndSubtab(app.name, entityDefinition)}`
    );
  }, 1000);
};

/**
 * Mimics existing segment creation/update logic but returns a boolean value to determine whether to show a snackbar.
 * @returns true if the segment has been successfully created/updated, false if not
 */
export const handleSubmitUpdated = async (
  { app, retailer, mainTimePeriod, history },
  { isFormValid, id, entityDefinition },
  formData,
  setState
) => {
  const { segments } = store.getState();
  const lowerCaseDisplayName = (formData.dn || '').toLowerCase();

  const hasNameCollision = !!Array.from(segments.savedSearchesById.values()).find(
    (savedSearch) => savedSearch.id !== id && savedSearch.displayName.toLowerCase() === lowerCaseDisplayName
  );
  if (hasNameCollision) {
    // eslint-disable-next-line no-alert
    alert('You must pick a unique name for this segment; a segment/BU with the same name already exists.');
    return false;
  }

  setState({ isFormSubmitted: true });

  if (!isFormValid) {
    return false;
  }

  const additionalParam = {};
  if (app.name === 'advertising' && app.queryParams.groupByField) {
    additionalParam.segmentType = app.queryParams.groupByField;
  }

  // This is where form data is dispatched; note the boolean value being passed in to determine if refresh should occur.
  const response = await store.dispatch(
    updateSavedSearch(entityDefinition.type, formData, id, null, true, additionalParam)
  );
  history.push(
    response.data !== undefined
      ? `/${entityDefinition.type}/${response.data}?${addPersistentQueryParams(
          retailer,
          mainTimePeriod
        )}&${buildTabAndSubtab(app.name, entityDefinition, 'entityPage')}`
      : `/?${addPersistentQueryParams(retailer, mainTimePeriod)}&${buildTabAndSubtab(app.name, entityDefinition)}`
  );
  return true;
};

const ConnectedAdvancedSearchSidebar = () => (
  <AdvancedSearchSideBar
    getFilterComponentDefinitions={getFilterComponentDefinitions}
    onChange={updateUrlParams}
    onSubmit={shouldShowNewBeacon() ? handleSubmitUpdated : handleSubmit}
    hideActionButtons={false}
  />
);

export default ConnectedAdvancedSearchSidebar;
