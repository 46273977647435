import types from './types';

interface OmniShareOfShelfShardsState {
  [name: string]: {
    data: any[];
    isFetching: boolean;
  };
}

const initialState: OmniShareOfShelfShardsState = {};

const omniShareOfShelfShardsService = (
  state: OmniShareOfShelfShardsState = initialState,
  action: {
    type: string;
    entity: { name: string; data: any[] };
  }
) => {
  switch (action.type) {
    case types.START_FETCH:
      return {
        ...state,
        [action.entity.name]: {
          ...state[action.entity.name],
          isFetching: true
        }
      };
    case types.RECEIVE_SHARDS_SERVICE_DATA:
      return {
        ...state,
        [action.entity.name]: {
          ...state[action.entity.name],
          data: action.entity.data
        }
      };

    case types.END_FETCH:
      return {
        ...state,
        [action.entity.name]: {
          ...state[action.entity.name],
          isFetching: false
        }
      };
    default:
      return state;
  }
};

export default omniShareOfShelfShardsService;
