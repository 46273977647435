/* eslint-disable react/display-name */
import React, { Ref } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { StacklineTheme, useStacklineTheme } from '@stackline/ui';

interface StyledDialogInnerProps {
  width: number;
  height?: number;
  padding: string;
  stacklineTheme: StacklineTheme;
}

const StyledDialogInner = styled(Dialog)<StyledDialogInnerProps>(({ width, height, padding }) => ({
  '& .MuiPaper-root': {
    width: `${width}px`,
    height: `${height}px`,
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding
  }
}));

/**
 * Generic styled dialog component
 */
const StyledDialog = React.forwardRef(
  (
    props: React.ComponentProps<typeof Dialog> & {
      width: number;
      height?: number;
      padding: string;
    },
    ref: Ref<HTMLDivElement>
  ): JSX.Element => {
    const theme = useStacklineTheme();
    return <StyledDialogInner ref={ref} stacklineTheme={theme} {...props} />;
  }
);

export default StyledDialog;
