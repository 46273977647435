import React from 'react';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { useStacklineTheme } from '@stackline/ui';

/**
 * Used for donut charts that display a single percentage value.
 */
export default function CircularProgressPercentage({
  percentage,
  size,
  color,
  customLabel
}: {
  /**
   * Should be a decimal between 0 and 1.
   */
  percentage: number;
  /**
   * Size of the chart
   */
  size?: string;
  /**
   * Color of the chart
   */
  color?: string;
  /**
   * Label in the middle of the chart
   */
  customLabel?: React.ReactNode;
}) {
  const formatMetric = useMetricFormatter();
  const theme = useStacklineTheme();

  return (
    <CircularProgressWithLabel
      circleSx={{
        color: color || theme.colors.primary
      }}
      circleProps={{
        size: size || '308px',
        thickness: 3
      }}
      backgroundCircleProps={{
        size: size || '308px',
        sx: {
          color: theme.colors.secondaryPorcelain,
          position: 'absolute'
        },
        thickness: 3
      }}
      customLabel={
        customLabel || (
          <Flex gap="sm" alignItems="center">
            <Text
              sx={{
                fontSize: '48px',
                fontWeight: 'bold'
              }}
            >
              {formatMetric(percentage * 100, METRICTYPE.DECIMAL, { decimalPlaces: 1, showFullValue: true })}
            </Text>
            <Text
              sx={{
                fontSize: '20px',
                fontWeight: 'bold'
              }}
            >
              %
            </Text>
          </Flex>
        )
      }
      percentage={percentage * 100}
    />
  );
}
