import fontVariables from '../styles/_fontStyle.scss';
import { toSnakeCase } from './stringFormatting';

const fontStyle: { [key: string]: number } = {
  regularWeight: 400
};

Object.entries(fontVariables).forEach(([key, value]) => {
  fontStyle[toSnakeCase(key)] = value as number;
});

export default fontStyle;
