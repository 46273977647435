import React from 'react';
import { SlColumn } from '@stackline/ui';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';

const DonutChartLoading = ({ size = 287 }: { size?: number }) => {
  return (
    <SlColumn spacing="sm">
      <SlSkeleton variant="rounded" height={size} width={size} />
    </SlColumn>
  );
};

export default DonutChartLoading;
