import { calculateNewQueryParams } from 'src/store/modules/app/selectors';
import { useAppSelector } from 'src/utils/Hooks';

export const useBeaconHomeLink = () => {
  const retailer = useAppSelector((state) => state.retailer);
  const app = useAppSelector((state) => state.app);
  const allWeekIdsByRetailerId = useAppSelector((state) => state.allWeekIdsByRetailerId);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const comparisonTimePeriod = useAppSelector((state) => state.comparisonTimePeriod);
  const user = useAppSelector((state) => state.user);

  const beaconHomeLink = `/client/${user.config.vendor.hashId}${
    calculateNewQueryParams(
      app,
      { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
      mainTimePeriod,
      comparisonTimePeriod
    ).searchParams
  }&tab=${app.defaultQueryParams.tab}&subtab=${app.defaultQueryParams.subtab}`;

  return beaconHomeLink;
};
