import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

const numberFormatStyles: React.CSSProperties = {
  borderBottomColor: 'currentcolor',
  borderBottom: '1px solid #eee',
  color: 'currentColor'
};

const disabledNumberFormatStyles: React.CSSProperties = {
  color: '#9E9E9E',
  backgroundColor: 'transparent'
};

const ScheduledActionNumberFormat = (props: NumberFormatProps) => {
  return (
    <NumberFormat
      {...props}
      // Mimic the style of the MUI `TextInput`
      style={{
        height: '32px',
        outline: 'none',
        border: '0',
        borderBottomStyle: 'none',
        borderBottomWidth: '0px',
        fontSize: '16px',
        fontFamily: 'Roboto',
        width: '67px',
        ...(props.disabled ? disabledNumberFormatStyles : numberFormatStyles),
        ...props.style
      }}
    />
  );
};

export default ScheduledActionNumberFormat;
