import TextField, { TextFieldProps } from '@mui/material/TextField';
import { AppImage, brandclub_colors, useStacklineTheme } from '@stackline/ui';
import React, { FC, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { handleSearchChange } from 'src/components/Header/HeaderSearchContainer';
import { useAppSelector } from 'src/utils/Hooks';
import mergeSx from 'src/utils/mergeSx';

export const SearchTextField = ({ sx, ...rest }: TextFieldProps) => {
  const theme = useStacklineTheme();

  return (
    <TextField
      variant="standard"
      InputProps={{
        startAdornment: (
          <AppImage
            src="/svg/icons/search.svg"
            alt="search-icon"
            style={{
              marginRight: 10,
              height: 13,
              width: 13
            }}
          />
        ),
        autoComplete: 'off',
        id: 'type-search'
      }}
      sx={mergeSx(
        {
          borderRadius: 17,
          border: `1px solid ${brandclub_colors.blueWhiteBorder}`,
          height: 30,
          width: 290,
          backgroundColor: brandclub_colors.white,
          '& div.MuiInput-root': {
            padding: '0 14px',
            display: 'flex',
            alignItems: 'center',
            height: 30,
            boxSizing: 'content-box',
            '& input': {
              padding: 0,
              fontSize: '14px',
              fontFamily: 'Roboto',
              color: theme.colors.primary
            }
          },
          '.MuiInput-input::placeholder': {
            color: theme.colors.secondary,
            fontSize: '14px'
          }
        },
        sx
      )}
      {...rest}
    />
  );
};

const NewSearchInput: FC = ({ history }) => {
  const [input, setInput] = useState('');
  const { pathname } = window.location;

  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const retailer = useAppSelector((state) => state.retailer);

  useEffect(() => {
    if (pathname !== '/search') {
      setInput('');
    }
  }, [pathname]);

  return (
    <div>
      <SearchTextField
        variant="standard"
        value={input}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            // TODO: fix types, verify functionality
            handleSearchChange(input, -1, 'beacon', retailer, mainTimePeriod, history.push, false);
          }
        }}
        onChange={(event) => setInput(event.target.value)}
      />
    </div>
  );
};

export default withRouter(NewSearchInput);
