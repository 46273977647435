import { Entity } from 'sl-api-connector';

import ReduxStore from 'src/types/store/reduxStore';
import { PageLayout } from 'src/types/application/widgetTypes';
import { buildAdManagerHeaderWidget } from 'src/components/Layout/Advertising/AdManagerPageLayout/LayoutUtil';
import ReviewsPage from 'src/components/AdManager/ReviewsPage';
import ReviewsPageWalmart from 'src/components/AdManager/ReviewsPageWalmart';
import _get from 'lodash/get';

export const getLayout = ({ entity }: { app: ReduxStore['app']; entity: Entity }): Partial<PageLayout> => {
  const headerWidget = buildAdManagerHeaderWidget(entity, 'Ad Score');
  const isVideo = _get(entity, ['extendedAttributes', 'campaignType'], null) === 'video';
  const campaignReviews = {
    CustomComponent: isVideo ? ReviewsPageWalmart : ReviewsPage,
    name: 'campaignReviews',
    view: {
      name: 'campaignReviews'
    },
    data: {}
  };

  return {
    widgets: [headerWidget, campaignReviews]
  };
};
