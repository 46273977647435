import { hexToRgba, useStacklineTheme } from '@stackline/ui';
import React, { createContext, useState, useCallback } from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import StyledSnackbar, {
  ButtonProps,
  SnackbarType,
  StyledSnackbarProps,
  colorMap
} from 'src/components/BeaconRedesignComponents/common/StyledSnackbar/StyledSnackbar';
import {
  SnackbarCancel,
  SnackbarCheckmark,
  SnackbarCheckmarkPrimary,
  SnackbarImportant
} from 'src/components/SvgIcons';
import { create } from 'zustand';

interface ShowSnackbarArgs {
  type: SnackbarType;
  /**
   * Content of the snackbar message
   */
  message: React.ReactNode;
  /**
   * How long before the snackbar disappears,
   * in milliseconds. Defaults to 5000ms.
   */
  timeout?: number;

  /**
   * Boolean to show a similar styled button within the snackbar. Defaults to false.
   */
  showButton?: boolean;
  /**
   * The props forwarded to the button component
   */
  buttonProps?: ButtonProps;
}

interface SnackbarProviderProps {
  children: React.ReactNode;
}

interface SnackbarContextValue {
  showSnackbar: (args: ShowSnackbarArgs) => void;
  closeSnackbar: () => void;
}

export const SnackbarContext = createContext<SnackbarContextValue>(null);

const iconBySnackbarType: Record<SnackbarType, React.ReactNode> = {
  success: <SnackbarCheckmark style={{ width: '24px' }} />,
  error: <SnackbarCancel style={{ width: '24px' }} />,
  info: <SnackbarImportant style={{ width: '24px' }} />,
  primary: <SnackbarCheckmarkPrimary style={{ width: '24px' }} />
};

interface SnackbarButtonProps {
  onClose: () => void;
  type: SnackbarType;
  buttonProps: ButtonProps;
}

const SnackbarButton = ({ ...rest }: SnackbarButtonProps) => {
  const theme = useStacklineTheme();
  return (
    <Flex marginLeft="40px">
      <SlButton
        onClick={(e) => {
          rest.onClose();
          rest.buttonProps.onClick(e);
        }}
        variant="contained"
        buttonSx={{
          paddingLeft: '12px',
          paddingRight: '12px',
          backgroundColor: hexToRgba(theme.colors[colorMap[rest.type]]),
          border: `1px solid ${hexToRgba(theme.colors[colorMap[rest.type]])}`,
          ':hover': {
            backgroundColor: hexToRgba(theme.colors[colorMap[rest.type]])
          }
        }}
        textSx={{
          fontSize: '14px',
          fontWeight: 'bold',
          letterSpacing: 'normal'
        }}
      >
        {rest.buttonProps.text}
      </SlButton>
    </Flex>
  );
};

export const SnackbarWithIcon = ({ message, ...rest }: StyledSnackbarProps) => {
  return (
    <StyledSnackbar
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      {...rest}
      message={
        <Flex gap="sm" alignItems="center">
          {iconBySnackbarType[rest.type]}
          {message}
          {rest.showButton ? <SnackbarButton {...rest} /> : null}
        </Flex>
      }
    />
  );
};

/**
 * Zustand hook for controlling snackbar state. Useful for
 * class components that can't use the other useSnackbar hook
 */
export const useSnackbarState = create<{
  open: boolean;
  message: React.ReactNode;
  type: SnackbarType;
  timeout: number;
}>(() => ({
  open: false,
  message: null,
  type: 'success',
  timeout: null
}));
/**
 * Provides an interface for opening a snackbar from anywhere in the app.
 */
export default function SnackbarProvider({ children }: SnackbarProviderProps) {
  const snackbarTimeout = useSnackbarState((state) => state.timeout);
  const snackbarOpen = useSnackbarState((state) => state.open);
  const snackbarMessage = useSnackbarState((state) => state.message);
  const snackbarType = useSnackbarState((state) => state.type);

  // Button Specific State
  const [showSnackbarButton, setShowSnackbarButton] = useState(false);
  const [snackbarButtonProps, setSnackbarButtonProps] = useState<ButtonProps>({
    text: 'Continue'
  });

  const showSnackbar = useCallback(
    ({ message, type, timeout = 7000, showButton = false, buttonProps = {} }: ShowSnackbarArgs) => {
      useSnackbarState.setState((prev) => ({
        ...prev,
        message,
        type,
        timeout,
        open: true
      }));

      setShowSnackbarButton(showButton);
      setSnackbarButtonProps((defaultProps) => ({ ...defaultProps, ...buttonProps }));
    },
    []
  );

  const closeSnackbar = useCallback(() => {
    useSnackbarState.setState((prev) => ({
      ...prev,
      open: false
    }));
  }, []);

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar,
        closeSnackbar
      }}
    >
      {children}
      <SnackbarWithIcon
        open={snackbarOpen}
        autoHideDuration={snackbarTimeout}
        type={snackbarType}
        onClose={() => closeSnackbar()}
        message={snackbarMessage}
        showButton={showSnackbarButton}
        buttonProps={snackbarButtonProps}
      />
    </SnackbarContext.Provider>
  );
}
