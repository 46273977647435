import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';

import Creators from './actions';
import ReduxStore from 'src/types/store/reduxStore';

import { omniRetailersAction } from 'src/store/modules/omni/omniRetailers';

import _get from 'lodash/get';

const { receiveOmniEntity } = Creators;

const { receiveOmniRetailers } = omniRetailersAction.default;

export const fetchOmniEntity =
  (entityType: string, entityId: string) => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
    const response = await axios.get(`/omni/entityMetadata?entityType=${entityType}&entityId=${entityId}`);
    if (response && response.status === 200) {
      const { data: entity } = response.data;
      dispatch(receiveOmniEntity(entity));
    }
  };

export interface OmniAvailableFilterForProdReq {
  startWeekId: number;
  endWeekId: number;
  productIds: string[];
  docType: string;
}

export const fetchAvailableFilter =
  (requestBody: OmniAvailableFilterForProdReq) => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
    try {
      const res = await axios.post('/omni/entityMetadata/availability', requestBody);
      if (res && res.status === 200) {
        const { data } = res;
        const retailers = _get(data, 'retailers', []);
        dispatch(
          receiveOmniRetailers(
            retailers.map((r: any) => ({
              retailerId: Number(_get(r, 'retailerId', -1))
            }))
          )
        );
      } else {
        throw Error('can not get the data');
      }
    } catch (e) {
      console.warn(e);
    }
  };
