/* eslint-disable react/prop-types */
import React, { ReactNode, useState } from 'react';
import { EllipsesIcon } from '../SvgIcons';
import './Card.scss';

interface Props {
  front: ReactNode;
  back: ReactNode;
  isFlippable: boolean;
  onMouseOver?: (isMouseOver: boolean) => void;
  tall?: boolean;
  isReclassifyEnabled?: boolean;
}

// This was refactored into CardView.jsx
const Card: React.FC<Props> = ({
  front,
  back,
  isFlippable,
  onMouseOver = () => {},
  tall = false,
  isReclassifyEnabled = false
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isCardFlipped, setIsCardFlipped] = useState(false);

  const stopPropagation = (event: React.SyntheticEvent): void => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const handleMouseOver = (mouseOver: boolean): void => {
    setIsMouseOver(mouseOver);
    onMouseOver(mouseOver);
  };

  // Added
  const handleFlipButtonClick = (event: React.SyntheticEvent) => {
    stopPropagation(event);
    setIsCardFlipped(!isCardFlipped);
  };

  // Added to new card
  const showIconButton = () => {
    if (!isMouseOver || !isFlippable) {
      return null;
    }

    return (
      <button className="card__flip-btn" onClick={handleFlipButtonClick}>
        <EllipsesIcon className="card__flip-icon" />
      </button>
    );
  };

  //  Added to card
  const showBack = () => {
    if (!isFlippable) {
      return null;
    }

    return (
      <div className={`card__back ${isMouseOver ? 'card__back--hover' : ''}`}>
        {showIconButton()}
        {back}
      </div>
    );
  };

  return (
    <div
      className={`card-container ${tall ? 'card-container--tall' : ''} ${
        isReclassifyEnabled ? 'card-container--tall--reclassify' : ''
      }`}
      onMouseEnter={() => handleMouseOver(true)}
      onMouseLeave={() => handleMouseOver(false)}
    >
      <div className={`card ${isCardFlipped ? 'card--flipped' : ''}`}>
        <div
          className={`card__front ${isReclassifyEnabled ? 'card__front--reclassify' : ''} ${
            isMouseOver ? 'card__front--hover' : ''
          }`}
        >
          {showIconButton()}
          {front}
        </div>
        {showBack()}
      </div>
    </div>
  );
};

export default Card;
