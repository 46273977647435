import React, { useState, useEffect, useRef } from 'react';
import Slide from '@mui/material/Slide';
import { withBus } from 'react-bus';

import { useBus, useOutsideAlerter } from 'src/utils/Hooks';
import AdvancedSearchSideBar from 'src/components/Search/AdvancedSearch/AdvancedSearchSideBar/AdvancedSearchSideBar';
import 'src/components/AdManager/Search/SearchSideBar.scss';

const SlidedAdvancedSearchSideBar = (props: any) => {
  const [openFilter, setOpenFilter] = useState(false);
  useBus(props.eventBus, 'toggleSearchSidebar', () => setOpenFilter(!openFilter));
  useBus(props.eventBus, 'closeSearchSidebar', () => setOpenFilter(false));
  useEffect(() => {
    props.eventBus.emit('setSearchFilterButtonText', openFilter);
  }, [openFilter, props.eventBus]);

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => {
    setOpenFilter(false);
  });

  return (
    <Slide ref={wrapperRef} direction="right" in={openFilter} mountOnEnter>
      <div className="admanager_sidebar">
        <div className="header" style={{ marginTop: 50 }}>
          <div className="header_text">Filters</div>
          <div className="hide_button" role="button" onClick={() => setOpenFilter(false)}>
            Hide
          </div>
        </div>
        <AdvancedSearchSideBar {...props} />
      </div>
    </Slide>
  );
};

export default withBus('eventBus')(SlidedAdvancedSearchSideBar);
