import React from 'react';
import Waypoint from 'react-waypoint';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import axios from 'axios';
import queryString from 'qs';
import { buildMetricValue } from 'src/utils/metrics';
import { AgGridReact } from 'ag-grid-react';
import { EntityColumn } from 'src/components/Grids/Data/ColumnTypes';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations'; // rendering
import {
  addTotalsToMarketShareGrid,
  buildAggregationConditions,
  buildDefaultConditions,
  buildEntityConditions,
  buildRelatedEntityMarketShareConditions,
  computeEntityMarketShareMetrics,
  computeEntityRetailerMetrics,
  computeOtherEntityRetailerMetrics,
  setSubTitle
} from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { buildSubtitleDisplayName } from 'src/utils/filters';
import Subtitle from '../../Subtitle';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { ENTITIES, METRICTYPE } from 'src/utils/entityDefinitions';
import MenuButton from '../../common/Buttons/MenuButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import colors from '../../../utils/colors';
import { DocumentIcon, ContentIcon } from '../../SvgIcons';
import { track } from '../../../utils/mixpanel';
import saveAs from 'file-saver';
import { getPreviousWeekId, getPreviousYearWeekId, getYearForWeekId } from '../../../utils/dateUtils';
import { createTimePeriodDateObject } from '../../../store/modules/main-time-period/selectors';
import { addKeywordToEntityType } from '../gridUtils';
import { anyNotEq } from 'src/utils/equality';
import moment from 'moment';
import { buildAggregations } from 'src/components/AdManager/Search';
import domtoimage from 'dom-to-image-more';

const { CancelToken } = axios;

const propKeysToCheck = [
  'location.pathname',
  'conditions',
  'entityService.mainEntity',
  'entityService.comparisonEntity',
  'filters',
  'widget',
  'mainTimePeriod',
  'comparisonTimePeriod'
];

class MarketShareGrid extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    allWeekIdsByRetailerId: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    aggregationConditions: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired,
    entitySearchService: PropTypes.object.isRequired,
    conditions: PropTypes.object.isRequired,
    queryConditions: PropTypes.object.isRequired,
    widget: PropTypes.object.isRequired,
    brandsFollowing: PropTypes.array.isRequired
  };

  state = {
    isLoading: true,
    inView: false,
    lastLoadedProps: undefined,
    isAllRetailerTab: false
  };

  componentDidMount() {
    this.cancelSource = CancelToken.source();
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { location, entityService, filters, conditions } = nextProps;
    if (
      location.pathname !== this.props.location.pathname ||
      location.search !== this.props.location.search ||
      !_isEqual(this.props.conditions, conditions) ||
      !_isEqual(this.props.entityService.mainEntity, entityService.mainEntity) ||
      !_isEqual(this.props.entityService.comparisonEntity, entityService.comparisonEntity) ||
      !_isEqual(this.props.filters, filters)
    ) {
      if (typeof this.cancelSource !== typeof undefined) {
        this.cancelSource.cancel('Canceled network request');
      }
      this.cancelSource = CancelToken.source();
      this.fetchData(nextProps);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_isEqual(this.state, nextState)) {
      return true;
    }
    const { entitySearchService } = this.props;
    const { entitySearchService: entitySearchServiceNextProps } = nextProps;
    const { entitySearchServiceStateNamesMainEntity } = this.state;
    let shouldupdate = false;
    if (entitySearchServiceStateNamesMainEntity) {
      entitySearchServiceStateNamesMainEntity.forEach((entitySearchServiceStateName) => {
        if (
          !_isEqual(
            entitySearchService[entitySearchServiceStateName],
            entitySearchServiceNextProps[entitySearchServiceStateName]
          )
        ) {
          shouldupdate = true;
        }
      });
    }
    return shouldupdate;
  }

  componentWillUnmount() {
    this.cancelSource.cancel('Cancel network request');
  }

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  setChartContainerRef = (element) => {
    this.chartContainer = element;
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    params.api.sizeColumnsToFit();
  };

  collectBrandsFollowing = () => {
    const { brandsFollowing } = this.props;
    const copyedBrandsFollowing = brandsFollowing.filter((b) => b.id !== 0);
    return { fieldName: 'brandId', condition: 'should', values: copyedBrandsFollowing.map((b) => b.id) };
  };

  fetchData(props) {
    const {
      app,
      retailer,
      categories,
      allWeekIdsByRetailerId,
      filters,
      mainTimePeriod,
      comparisonTimePeriod,
      conditions,
      match,
      location,
      entityService,
      fetchEntityMetrics,
      widget,
      dataConfig
    } = props;

    const lazyLoad = _get(widget, ['data', 'lazyLoad'], false);

    if (lazyLoad) {
      if (!this.state.inView) {
        return;
      } else {
        this.setState({
          lastLoadedProps: props
        });
      }
    }

    const { mainEntity } = entityService;
    let { id } = match.params;
    const mainEntityId = mainEntity && `${mainEntity.id}`;
    let comparisonEntityId = id === mainEntityId ? mainEntityId : mainEntity.hashId;

    // check if we use this component in the atlas retailer page
    const isUseInMultiRetailer = 'atlas' && dataConfig.indexName === 'multiretailer';
    this.setState({
      isAllRetailerTab: isUseInMultiRetailer
    });
    if (isUseInMultiRetailer) {
      id = 0;
      comparisonEntityId = 0;
    }
    if (!mainEntity || id !== comparisonEntityId) {
      // the main entity has to match with what is in the current url
      return;
    }

    const queryParams = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    });
    if (!queryParams.tab) {
      queryParams.tab = app.defaultQueryParams.tab;
    }
    if (!queryParams.subtab) {
      queryParams.subtab = app.defaultQueryParams.subtab;
    }

    const defaultConditions = buildDefaultConditions(conditions, queryParams);
    const entityConditions = buildEntityConditions(app, categories, defaultConditions, mainEntity);
    const relatedEntityMarketShareConditions = buildRelatedEntityMarketShareConditions(
      filters,
      defaultConditions,
      entityConditions,
      mainEntity,
      app,
      retailer
    );
    // when using in atlas should add all retailerId in the condition
    if (isUseInMultiRetailer) {
      relatedEntityMarketShareConditions.termFilters.push({
        fieldName: 'retailerId',
        values: ['0']
      });
    }

    const promises = [];
    const state = {
      isLoading: true,
      entitySearchServiceStateNamesMainEntity: []
    };

    const indexName = isUseInMultiRetailer ? 'multiretailer' : 'sales';

    // add keyword business and segment to the url when in the business and segment page
    const { pathname } = location;
    const newCategoryEntity = addKeywordToEntityType(pathname, dataConfig.categoryEntity);
    const newRelatedEntity = addKeywordToEntityType(pathname, dataConfig.relatedEntity);
    const newRetailerEntity = addKeywordToEntityType(pathname, dataConfig.retailerEntity);

    widget.data.groupByFields.forEach((groupByField) => {
      const startWeekPrevYear = getPreviousYearWeekId(comparisonTimePeriod.startWeek);
      const { aggregationConditions, marketshareAggregationConditions, comparisonRangeFilters } =
        buildAggregationConditions(
          app,
          indexName,
          retailer,
          allWeekIdsByRetailerId,
          mainTimePeriod,
          comparisonTimePeriod,
          props.aggregationConditions,
          widget.data.weekIdField,
          true,
          false,
          true,
          startWeekPrevYear
        );
      const [{ aggregations: aggregationFields, derivations: derivedFields, aggregationFieldConditions }] =
        buildAggregations(widget.data.configByGroupByFieldName[groupByField.name].aggregationFields);
      // Not sure if this is needed
      if (aggregationConditions && aggregationConditions.termFilters) {
        aggregationFieldConditions.termFilters = aggregationFieldConditions.termFilters.concat(
          aggregationConditions.termFilters
        );
      }
      if (aggregationConditions && aggregationConditions.rangeFilters) {
        aggregationFieldConditions.rangeFilters = aggregationFieldConditions.rangeFilters.concat(
          aggregationConditions.rangeFilters
        );
      }

      const aggregations = {
        groupByFieldName: groupByField.name,
        aggregationFields,
        sortDirection: null,
        sortByAggregationField: null,
        conditions: aggregationFieldConditions,
        comparisonRangeFilters
      };

      const statePropertyNameMainEntity = widget.data.configByGroupByFieldName[groupByField.name].isShared
        ? `${widget.data.configByGroupByFieldName[groupByField.name].indexName}_${groupByField.name}`
        : `${widget.name}_${widget.data.configByGroupByFieldName[groupByField.name].indexName}_${groupByField.name}`;

      state.entitySearchServiceStateNamesMainEntity.push(statePropertyNameMainEntity);

      promises.push(
        fetchEntityMetrics(
          'mainEntityCategoryMetrics',
          {
            entity: newCategoryEntity,
            retailer,
            app,
            indexName,
            derivedFields: []
          },
          [
            {
              conditions: _cloneDeep(relatedEntityMarketShareConditions),
              aggregations: [
                {
                  ...aggregations,
                  groupByFieldName: 'weekId',
                  comparisonRangeFilters: [],
                  conditions: _cloneDeep(aggregationConditions)
                }
              ]
            }
          ],
          _get(this.cancelSource, 'token')
        )
      );
      const pageSize = 30;
      // only fetch data, if this widget is the primary source of the data or if it specific to this widget
      const aggregateByFieldName = isUseInMultiRetailer ? 'retailSales' : `${app.queryParams.subtab || 'retailSales'}`;
      promises.push(
        fetchEntityMetrics(
          'relatedEntityMetrics',
          { entity: newRelatedEntity, retailer, app, indexName },
          [
            {
              pageSize,
              conditions: _cloneDeep(relatedEntityMarketShareConditions),
              aggregations: [
                {
                  aggregationFields: [
                    {
                      aggregateByFieldName,
                      function: 'sum'
                    }
                  ],
                  conditions: _cloneDeep(marketshareAggregationConditions),
                  comparisonRangeFilters,
                  groupByFieldName: ENTITIES[app.name][dataConfig.relatedEntity.type].keyFieldName
                }
              ]
            }
          ],
          _get(this.cancelSource, 'token')
        )
      );
      promises.push(
        fetchEntityMetrics(
          'relatedEntityCategoryMetrics',
          { entity: newRetailerEntity, retailer, app, indexName },
          [
            {
              pageSize,
              conditions: _cloneDeep(relatedEntityMarketShareConditions),
              aggregations: [
                {
                  aggregationFields: [
                    {
                      aggregateByFieldName,
                      function: 'sum'
                    }
                  ],
                  conditions: _cloneDeep(marketshareAggregationConditions),
                  comparisonRangeFilters,
                  groupByFieldName: dataConfig.retailerEntity.keyFieldName
                }
              ]
            }
          ],
          _get(this.cancelSource, 'token')
        )
      );
      if (
        widget.data.configByGroupByFieldName[groupByField.name].isPrimarySource ||
        !widget.data.configByGroupByFieldName[groupByField.name].isShared
      ) {
        const newMarketShareEntity = addKeywordToEntityType(
          pathname,
          widget.data.configByGroupByFieldName[groupByField.name].entity
        );

        promises.push(
          fetchEntityMetrics(
            statePropertyNameMainEntity,
            {
              entity: newMarketShareEntity,
              retailer,
              app,
              indexName: widget.data.configByGroupByFieldName[groupByField.name].indexName,
              derivedFields
            },
            [
              {
                pageSize,
                conditions: _cloneDeep(relatedEntityMarketShareConditions),
                aggregations: [
                  {
                    ...aggregations,
                    conditions: _cloneDeep(marketshareAggregationConditions)
                  }
                ]
              }
            ],
            _get(this.cancelSource, 'token')
          )
        );
      }
    });

    this.setState({ ...state });

    Promise.all(promises)
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (!err.message.includes('Canceled network request')) {
          console.error(err);
        }
      });
  }

  shouldShowLoading() {
    const { isLoading } = this.state;
    return isLoading;
  }

  getTimePeriodForPreviousYear = (timePeriod) => {
    const newTimePeriod = _cloneDeep(timePeriod);

    const endWeek = getPreviousYearWeekId(timePeriod.endWeek);
    const startWeek = getPreviousWeekId(endWeek, timePeriod.value - 1);

    const prevYearTimePeriod = createTimePeriodDateObject(newTimePeriod, startWeek, endWeek);
    prevYearTimePeriod.valuePropertyName = `value${prevYearTimePeriod.timePeriodSuffix.weekId}`;
    return prevYearTimePeriod;
  };

  generateCsvData = (finalMarketShareGrid, timePeriods, rowData, metricPropertyName, metricMarketSharePropertyName) => {
    const { currencySymbol, locale } = finalMarketShareGrid[metricPropertyName];
    let timePeriodPairsCsv = null;

    timePeriodPairsCsv = timePeriods
      .filter((tp) => tp.id !== 'cd')
      .map((timePeriod) => {
        const newTimePeriod = _cloneDeep(timePeriod);
        const prevYearTimePeriod = this.getTimePeriodForPreviousYear(newTimePeriod);
        return [newTimePeriod, prevYearTimePeriod];
      });

    const yearHeaders = ['Date Range ->'];
    const getYearHeader = (timePeriod) =>
      `${getYearForWeekId(timePeriod.startWeek)} (${timePeriod.startWeek} - ${timePeriod.endWeek})`;
    if (!this.state.isAllRetailerTab) {
      timePeriodPairsCsv.forEach((tpp) => {
        yearHeaders.push(
          getYearHeader(tpp[0]),
          '',
          getYearHeader(tpp[1]),
          '',
          '',
          '' // YoY columns
        );
      });
    } else {
      timePeriodPairsCsv.forEach((tpp) => {
        yearHeaders.push(getYearHeader(tpp[0]));
      });
    }

    function getWeeksTitle(timePeriod) {
      let { id } = timePeriod;
      if (id !== 'ytd' && id !== 'ly') {
        id = `${id}ks`;
      }
      return id;
    }

    let csvHeaders = [];
    if (!this.state.isAllRetailerTab) {
      csvHeaders = [
        'Brand',
        ...timePeriodPairsCsv.flatMap((timePeriodPair) => {
          const title = getWeeksTitle(timePeriodPair[0]);
          return [
            `${title} Sales`,
            `${title} Share`,
            `${title} LY Sales`,
            `${title} LY Share`,
            `${title} YoY Sales (%)`,
            `${title} YoY Share (%)`
          ];
        })
      ];
    } else {
      csvHeaders = [
        'Brand',
        ...timePeriodPairsCsv.flatMap((timePeriodPair) => {
          const title = getWeeksTitle(timePeriodPair[0]);
          return [`${title} Share`];
        })
      ];
    }
    const timePeriodPairsColumnDefsCsv = timePeriodPairsCsv.map((timePeriodPair) => ({
      valueGetter: (params, propertyName, index) => {
        const timePeriod = timePeriodPair[index];
        const metricRawValue = params[propertyName][timePeriod.valuePropertyName];
        if (!metricRawValue && metricRawValue !== 0) {
          return { displayValue: '-', value: 0 };
        }
        const { value: valueStr, suffix } = buildMetricValue(
          metricRawValue,
          finalMarketShareGrid[propertyName].metricType,
          currencySymbol,
          false,
          null,
          locale,
          false
        );
        return { displayValue: `${valueStr}${suffix}`, value: metricRawValue };
      }
    }));

    const computeYoYValues = (rowD, currentYearSales, prevYearSales, currentYearShare, prevYearShare) => {
      let yoySalesDisplay = 0;
      let yoyShareDisplay = 0;
      if (
        currentYearSales.value > 0 &&
        prevYearSales.value > 0 &&
        currentYearShare.value > 0 &&
        prevYearShare.value > 0
      ) {
        const yoySales = currentYearSales.value / prevYearSales.value - 1;
        const yoyShare = currentYearShare.value / prevYearShare.value - 1;
        ({ value: yoySalesDisplay } = buildMetricValue(
          yoySales,
          METRICTYPE.PERCENT,
          currencySymbol,
          false,
          null,
          locale,
          false
        ));
        ({ value: yoyShareDisplay } = buildMetricValue(
          yoyShare,
          METRICTYPE.PERCENT,
          currencySymbol,
          false,
          null,
          locale,
          false
        ));
      }
      return { yoySalesDisplay: `${yoySalesDisplay}%`, yoyShareDisplay: `${yoyShareDisplay}%` };
    };

    const rowItems = rowData
      .filter((r) => r.name !== 'Total')
      .map((rowD) => {
        const items = timePeriodPairsColumnDefsCsv.flatMap((colDef) => {
          const currentYearSales = colDef.valueGetter(rowD, metricPropertyName, 0);
          const currentYearShare = colDef.valueGetter(rowD, metricMarketSharePropertyName, 0);
          const prevYearSales = colDef.valueGetter(rowD, metricPropertyName, 1);
          const prevYearShare = colDef.valueGetter(rowD, metricMarketSharePropertyName, 1);
          const { yoySalesDisplay, yoyShareDisplay } = computeYoYValues(
            rowD,
            currentYearSales,
            prevYearSales,
            currentYearShare,
            prevYearShare
          );
          if (!this.state.isAllRetailerTab) {
            return [
              currentYearSales.displayValue,
              currentYearShare.displayValue,
              prevYearSales.displayValue,
              prevYearShare.displayValue,
              yoySalesDisplay,
              yoyShareDisplay
            ];
          } else {
            return [currentYearShare.displayValue];
          }
        });
        return [rowD.name, ...items];
      });
    const csvArray = [yearHeaders, [], csvHeaders, ...rowItems];
    return csvArray;
  };

  saveChart = async () => {
    track('saved chart as image');
    const canvas = await domtoimage.toCanvas(this.chartContainer);
    canvas.toBlob((blob) => saveAs(blob, 'chart_image.png'));
  };

  exportCsvData = async () => {
    track('export market share grid as csv');
    const { timePeriods, rowData, metricPropertyName, metricMarketSharePropertyName, finalMarketShareGrid } =
      this.generateMarketShareGridData();

    const csvArray = this.generateCsvData(
      finalMarketShareGrid,
      timePeriods,
      rowData,
      metricPropertyName,
      metricMarketSharePropertyName
    );
    const csvContent = csvArray.map((rowElements) => rowElements.join('\t')).join('\n');
    const data = `${csvContent}\n`;
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
    let filename = 'market_share_data.tsv';
    if (metricPropertyName === 'retailSales_by_brandId') {
      filename = 'market_share_retailsales_data.tsv';
    } else if (metricPropertyName === 'unitsSold_by_brandId') {
      filename = 'market_share_unitssold_data.tsv';
    }
    saveAs(blob, filename);
  };

  renderExportMenu = () => {
    const allowedRetailerIds = ['0', '1', '14', '15', '17', '29', '30', '2', '4', '11', '16', '18', '28', '32', '53'];
    if (!allowedRetailerIds.includes(this.props.retailer.id)) {
      return <></>;
    }

    const menuInfo = {
      mainButton: {
        icon: <MoreHorizIcon style={{ color: colors.darkBlue }} />,
        direction: 'down'
      },
      subItems: [
        { icon: <ContentIcon />, text: 'Save image', onClick: () => this.saveChart() },
        { icon: <DocumentIcon />, text: 'Export TSV', onClick: () => this.exportCsvData() }
      ]
    };
    return <MenuButton menuInfo={menuInfo} />;
  };

  generateMarketShareGridData = (dataLength) => {
    const timePeriods = [];
    const {
      app,
      categories,
      comparisonTimePeriod,
      entityService,
      entitySearchService,
      filters,
      mainTimePeriod,
      retailer,
      widget
    } = this.props;
    const {
      mainEntityCategoryMetrics: mainEntityCategoryMetricsOriginal,
      relatedEntityCategoryMetrics: relatedEntityCategoryMetricsOriginal
    } = entitySearchService;
    const { mainEntity } = entityService;
    const { entitySearchServiceStateNamesMainEntity } = this.state;
    const marketShareEntityMetricsOriginal = entitySearchService[entitySearchServiceStateNamesMainEntity[0]];
    const mainEntityCategoryMetrics = _cloneDeep(mainEntityCategoryMetricsOriginal);
    const relatedEntityCategoryMetrics = _cloneDeep(relatedEntityCategoryMetricsOriginal);

    const { data } = widget;
    const groupByFieldName = data.groupByFields[0].name;
    const metricName = data.configByGroupByFieldName[groupByFieldName].aggregationFields[0].name;

    const metricPropertyName = `${metricName}_by_${groupByFieldName}`;
    const metricMarketSharePropertyName = `${metricName}MarketShare_by_${groupByFieldName}`;

    const marketShareRelatedEntityMetrics = _cloneDeep(marketShareEntityMetricsOriginal);
    if (dataLength && marketShareRelatedEntityMetrics[metricPropertyName].data.length !== dataLength) {
      // Trim, as per need (CSV needs all; UI needs only top-few)
      marketShareRelatedEntityMetrics[metricPropertyName].data = marketShareRelatedEntityMetrics[
        metricPropertyName
      ].data.slice(0, dataLength);
    }

    computeEntityMarketShareMetrics(
      marketShareRelatedEntityMetrics,
      relatedEntityCategoryMetrics,
      null,
      null,
      groupByFieldName,
      'retailerId'
    );
    const mainTimePeriods = this.getMainTimePeriodsWithValuesPopulated(mainTimePeriod);
    const prevTimePeriods = mainTimePeriods
      .filter((tp) => tp.id !== 'cd')
      .map((timePeriod) => {
        const prevYearTimePeriod = this.getTimePeriodForPreviousYear(timePeriod);
        return prevYearTimePeriod;
      });
    const mainAndPrevTimePeriods = [...mainTimePeriods, ...prevTimePeriods];
    computeEntityRetailerMetrics(mainEntityCategoryMetrics, mainAndPrevTimePeriods, comparisonTimePeriod, retailer);
    computeOtherEntityRetailerMetrics(
      marketShareRelatedEntityMetrics,
      mainEntityCategoryMetrics,
      mainAndPrevTimePeriods,
      comparisonTimePeriod
    );

    if (mainTimePeriod.id === 'cd') {
      timePeriods.push({
        ...mainTimePeriod,
        valuePropertyName: `value${mainTimePeriod.timePeriodSuffix.weekId}`
      });
    }
    const { finalMarketShareGrid } = addTotalsToMarketShareGrid(
      mainAndPrevTimePeriods,
      marketShareRelatedEntityMetrics,
      mainEntityCategoryMetrics,
      metricName,
      groupByFieldName,
      dataLength
    );
    const subtitle = buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app);
    setSubTitle(subtitle, [finalMarketShareGrid]);

    mainTimePeriods.forEach((timePeriod) => {
      if (!timePeriod.startWeek || timePeriod.id === 'cd') {
        return;
      }
      timePeriods.push({
        ...timePeriod,
        valuePropertyName: `value${timePeriod.timePeriodSuffix.weekId}`
      });
    });

    const rowData = [];
    finalMarketShareGrid[metricPropertyName].data.forEach((metricDataPoint, index) => {
      const marketShareDataPoint = finalMarketShareGrid[metricMarketSharePropertyName].data[index];
      const rank =
        metricDataPoint.entity.name === 'Other' || metricDataPoint.entity.name === 'Total'
          ? metricDataPoint.rank
          : index + 1;
      rowData.push({
        rank,
        type: metricDataPoint.entity.type,
        name: metricDataPoint.entity.name,
        id: metricDataPoint.entity.id,
        [metricPropertyName]: metricDataPoint,
        [metricMarketSharePropertyName]: marketShareDataPoint
      });
    });
    return {
      timePeriods,
      rowData,
      metricName,
      metricPropertyName,
      groupByFieldName,
      metricMarketSharePropertyName,
      finalMarketShareGrid
    };
  };

  getMainTimePeriodsWithValuesPopulated = (mainTimePeriod) => {
    const mainTimePeriods = mainTimePeriod.availableMainTimePeriods.map((timePeriod) => _cloneDeep(timePeriod));
    mainTimePeriods.forEach((timePeriod) => {
      if (!timePeriod.value && timePeriod.startWeek) {
        // Calculate the value field for dynamic time-periods
        const startDate = timePeriod.startWeekStartDate;
        const endDate = timePeriod.endWeekEndDate;
        timePeriod.value = moment(endDate).diff(moment(startDate), 'week') + 1;
      }
    });
    return mainTimePeriods;
  };

  renderMarketShareGrid = () => {
    const {
      timePeriods,
      rowData,
      metricName,
      metricPropertyName,
      groupByFieldName,
      metricMarketSharePropertyName,
      finalMarketShareGrid
    } = this.generateMarketShareGridData(10);
    const { currencySymbol, locale } = finalMarketShareGrid[metricPropertyName];

    const timePeriodColumnDefinitions = timePeriods.map((timePeriod) => ({
      headerName: timePeriod.shortDisplayName,
      valueGetter: (params) => {
        const rawValue = params.data[metricMarketSharePropertyName][timePeriod.valuePropertyName];
        if (rawValue === undefined) {
          return '--';
        }
        const { value, suffix } = buildMetricValue(
          rawValue,
          finalMarketShareGrid[metricMarketSharePropertyName].metricType,
          currencySymbol,
          false,
          null,
          locale
        );
        return `${value}${suffix}`;
      },
      cellStyle: {
        'text-align': 'right',
        'padding-right': '10px',
        'flex-direction': 'row-reverse',
        'justify-content': 'flex-start',
        border: 0
      },
      width: 160
    }));

    const handleRowStyle = (params) => (params.node.rowIndex === rowData.length - 1 ? 'last-line' : '');

    const columnDefinitions = [
      {
        headerName: 'Rank',
        field: 'rank',
        width: 60,
        lockPinned: true,
        cellStyle: {
          'justify-content': 'flex-start',
          'text-align': 'center'
        }
      },
      {
        headerName: 'Brand',
        field: 'brand',
        cellRendererFramework: EntityColumn,
        cellStyle: {
          'justify-content': 'flex-start',
          'text-align': 'left',
          'flex-direction': 'row',
          border: '0'
        }
      },
      ...timePeriodColumnDefinitions
    ];

    const relatedEntity = finalMarketShareGrid[`${metricName}_by_${groupByFieldName}`];

    return (
      <div className="line-chart" style={{ position: 'relative' }}>
        {this.renderExportMenu()}
        <div className="chart-title">Market Share Grid</div>
        <Subtitle
          data={{
            displayName: relatedEntity.subtitle,
            type: relatedEntity.subtitleType,
            id: relatedEntity.subtitleId
          }}
        />
        <hr className="sl-divider " />
        <div className="ag-material" ref={this.setChartContainerRef}>
          <AgGridReact
            domLayout="autoHeight"
            columnDefs={columnDefinitions}
            rowData={rowData}
            rowHeight={56}
            headerHeight={34}
            getRowClass={handleRowStyle}
            onGridReady={this.onGridReady}
          />
        </div>
      </div>
    );
  };

  shouldFetchDataLazyLoad = () => {
    // if the conditions changed
    return anyNotEq(propKeysToCheck, this.state.lastLoadedProps, this.props);
  };

  handleWaypointEnter = () => {
    if (!this.state.inView) {
      this.setState(
        {
          inView: true
        },
        () => {
          // if the conditions changed
          if (this.shouldFetchDataLazyLoad()) {
            this.fetchData(this.props);
          }
        }
      );
    }
  };

  handleWaypointExit = () => {
    this.setState({
      inView: false
    });
  };

  render() {
    const showLoading = this.shouldShowLoading();
    const { widget } = this.props;
    const lazyLoad = _get(widget, ['data', 'lazyLoad'], false);

    if (showLoading) {
      return (
        <>
          {lazyLoad && <Waypoint onEnter={this.handleWaypointEnter} onLeave={this.handleWaypointExit} />}
          <GridLoading />
        </>
      );
    }
    return (
      <div>
        {lazyLoad && <Waypoint onEnter={this.handleWaypointEnter} onLeave={this.handleWaypointExit} />}
        {this.renderMarketShareGrid()}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, [
    'app',
    'entityService',
    'entitySearchService',
    'comparisonTimePeriod',
    'mainTimePeriod',
    'retailer',
    'categories',
    'filters',
    'allWeekIdsByRetailerId',
    'brandsFollowing'
  ]);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MarketShareGrid));
