import React from 'react';
import { Box } from '@mui/system';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { EqualIcon, UpdatedPlusIcon } from 'src/components/SvgIcons/SvgIcons';

interface RatingsReviewsPlanInputContainerProps {
  /**
   * Label assigned to the left of the row of inputs
   */
  label: string;
  /**
   * First input to be rendered within the row
   */
  firstInput: React.ReactNode;
  /**
   * Second input to be rendered within the row
   */
  secondInput: React.ReactNode;
  /**
   * Third input to be rendered within the row
   */
  thirdInput: React.ReactNode;
  /**
   * The width of the label container in order to better align the inputs
   */
  width?: string;
  /**
   * Label that appears above the first input. Defaults to "CURRENT".
   */
  firstInputLabel?: string;
  /**
   * Label that appears above the second input. Defaults to "ADDITIONAL".
   */
  secondInputLabel?: string;
  /**
   * Label that appears above the third input. Defaults to "NEW".
   */
  thirdInputLabel?: string;
  /**
   * Label that appears at the end of the inputs. Defaults to "per week"
   */
  forTimePeriodLabel?: string;
}
/**
 * Unique input rendering container specific to Ratings & Reviews adjustments
 */
export const RatingsReviewsPlanInputContainer = ({
  label,
  firstInput,
  secondInput,
  thirdInput,
  width = 'auto',
  firstInputLabel = 'CURRENT',
  secondInputLabel = 'ADDITIONAL',
  thirdInputLabel = 'TOTAL',
  forTimePeriodLabel = 'per week'
}: RatingsReviewsPlanInputContainerProps) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" marginTop="42px" gap="40px">
      <Box width={width} minWidth="80px">
        <Text variant="subtitle2">{label}</Text>
      </Box>
      <Box display="flex" alignItems="center" gap="12px">
        {/* First input */}
        <Flex position="relative" flexDirection="column">
          <Text
            sx={{ position: 'absolute', fontWeight: 'bold', fontSize: '10px', top: '-20px', letterSpacing: '0.8px' }}
          >
            {firstInputLabel}
          </Text>
          {firstInput}
        </Flex>
        <UpdatedPlusIcon width={12} height={12} />

        {/* Second input */}
        <Flex position="relative" flexDirection="column">
          <Text
            sx={{ position: 'absolute', fontWeight: 'bold', fontSize: '10px', top: '-20px', letterSpacing: '0.8px' }}
          >
            {secondInputLabel}
          </Text>
          {secondInput}
        </Flex>
        <EqualIcon style={{ width: '12px', height: '12px' }} />

        {/* Third input */}
        <Flex position="relative" flexDirection="column">
          <Text
            sx={{ position: 'absolute', fontWeight: 'bold', fontSize: '10px', top: '-20px', letterSpacing: '0.8px' }}
          >
            {thirdInputLabel}
          </Text>
          {thirdInput}
        </Flex>
        <Text variant="body2">{forTimePeriodLabel}</Text>
      </Box>
    </Box>
  );
};
