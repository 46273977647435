import types from './types';

// ------------------------------------
// Action Creators - they simply return an action
// An action being a payload of information that send data from your application to your store.
// ------------------------------------

function receiveAdEntities(adEntities) {
  return {
    type: types.RECEIVE_ADENTITIES,
    adEntities,
    receivedAt: Date.now()
  };
}

function clearAdEntities() {
  return {
    type: types.CLEAR_ADENTITIES
  };
}

export default {
  clearAdEntities,
  receiveAdEntities
};
