/**
 * A select component that is just a thin wrapper around Material UI's with some additional styling added
 */

/* eslint-disable react/prop-types */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import MenuItem from '@mui/material/MenuItem';
import StandardSelect from 'src/components/common/DropDown/StandardDropdown';

import colors from 'src/utils/colors';
import Placeholder from 'src/components/common/Placeholder/Placeholder';
import { checkNotEmpty } from 'src/utils/checkEmpty';
import fontStyle from 'src/utils/fontStyle';
import { PropTypesOf } from 'src/types/utils';

const muiStyles = {
  root: {
    maxWidth: 176,
    margin: 0,
    '&:before': {
      content: 'none'
    }
  },
  select: {
    padding: '7px 25px 7px 1px',
    fontWeight: fontStyle.regularWeight,
    fontSize: 15,
    minWidth: 150
  },
  disabled: {
    color: colors.lightGrey
  }
};

const StyledSelect: React.FC<
  {
    items: { id: string | number; value: any; displayName: string }[];
    value: any;
    style?: React.CSSProperties;
    placeholder?: string;
  } & PropTypesOf<typeof StandardSelect>
> = ({ items, value, style, placeholder, ...props }) =>
  checkNotEmpty(placeholder) ? (
    // eslint-disable-next-line react/display-name
    <StandardSelect
      variant="standard"
      style={style}
      value={value}
      {...props}
      displayEmpty
      renderValue={checkNotEmpty(value) ? undefined : () => <Placeholder text={placeholder} />}
    >
      {items.map(({ id, value: itemValue, displayName }) => (
        <MenuItem key={id} value={itemValue}>
          {displayName}
        </MenuItem>
      ))}
    </StandardSelect>
  ) : (
    <StandardSelect variant="standard" style={style} value={value} {...props}>
      {items.map(({ id, value: itemValue, displayName }) => (
        <MenuItem key={id} value={itemValue}>
          {displayName}
        </MenuItem>
      ))}
    </StandardSelect>
  );

const EnhancedStyledSelect = withStyles(muiStyles)(StyledSelect);

export default EnhancedStyledSelect;
