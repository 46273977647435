import types from './types';
import {
  getAppDataFrequency,
  getAppDisplayName,
  getAppName,
  getAppStage,
  buildBaseTarget,
  getAppDefaultParams,
  isDrive
} from 'src/utils/app';

import { checkIsRestrictedDemoUser } from 'src/store/modules/user/selectors';
import { AppName } from 'sl-api-connector';

const defaultQueryParams = getAppDefaultParams();
const appIsOmni = getAppName() === AppName.Omni;
const appIsAd = isDrive;
const initialAppState = {
  isUpdatingRedux: false,
  targetUrl: buildBaseTarget(),
  name: getAppName(),
  apiAppName: getAppName(),
  dataFrequency: getAppDataFrequency(getAppName()),
  stage: getAppStage(),
  displayName: getAppDisplayName(getAppName()),
  defaultQueryParams,
  shouldUseBrandNameNormalized: appIsOmni,
  shouldUseGTIN: appIsOmni,
  shouldHaveRetailerDropDown: !appIsOmni,
  shouldUseOmniCommonSummaryInfo: appIsOmni,
  shouldUseStacklineSkU: appIsOmni,
  shouldHaveCurrencyDropdown: appIsOmni,
  shouldSupportJumpingToRetailerProdPage: !appIsOmni,
  shouldUseOmniAuthenticatedRouter: appIsOmni,
  shouldUseWrappedRetailerSku: appIsOmni,
  comparisonDefault: appIsOmni || appIsAd ? 'prior-period' : 'prior-year',
  shouldUseOmniExport: appIsOmni,
  queryParams: {
    searchParams: '',
    option: '',
    metricType: '',
    tab: '',
    subtab: '',
    pid: '',
    rid: '',
    pp: '',
    sw: '',
    ew: '',
    sdid: '',
    edid: '',
    wr: ''
  }
};

const subReducers = {
  [types.IS_UPDATING_REDUX]: (state, action) => ({ ...state, isUpdatingRedux: action.isUpdatingRedux }),
  [types.SET_APP_TARGET_URL]: (state, action) => ({ ...state, targetUrl: action.targetUrl }),
  [types.SET_APP_NAME]: (state, action) => ({
    ...state,
    name: action.appName,
    stage: action.appStage,
    displayName: getAppDisplayName(action.appName)
  }),
  [types.RECEIVE_APP_CONFIG]: (state, action) => {
    const appName =
      action.appConfig.domainConfig && action.appConfig.domainConfig.appName
        ? action.appConfig.domainConfig.appName
        : state.name;
    if (action.session) {
      if (checkIsRestrictedDemoUser(action.session)) {
        const app = {
          ...state,
          config: action.appConfig,
          lastUpdated: action.receivedAt,
          name: appName,
          apiAppName: appName,
          displayName:
            action.appConfig.domainConfig && action.appConfig.domainConfig.appDisplayName
              ? action.appConfig.domainConfig.appDisplayName
              : state.appDisplayName,
          supportsAppSwitching: window.location.hostname.indexOf('stackline.com') > 0,
          isPoweredByStackline: !window.location.hostname.indexOf('stackline.com') > 0,
          targetUrl: state.targetUrl
        };
        app.defaultQueryParams.tab = 'sales';
        app.defaultQueryParams.subtab = 'retailSales';
        return app;
      }
    }
    return {
      ...state,
      config: action.appConfig,
      lastUpdated: action.receivedAt,
      name: appName,
      apiAppName: appName,
      displayName:
        action.appConfig.domainConfig && action.appConfig.domainConfig.appDisplayName
          ? action.appConfig.domainConfig.appDisplayName
          : getAppDisplayName(appName),
      companyLogoUrl:
        action.appConfig.domainConfig && action.appConfig.domainConfig.companyLogoUrl
          ? action.appConfig.domainConfig.companyLogoUrl
          : null,
      supportsAppSwitching: window.location.hostname.indexOf('stackline.com') > 0,
      isPoweredByStackline: !window.location.hostname.indexOf('stackline.com') > 0
    };
  },
  [types.REQUEST_CONFIG]: (state, _action) => state,
  [types.SET_QUERY_PARAMS]: (state, action) => ({ ...state, queryParams: action.params })
};

const appReducer = (outerState = initialAppState, outerAction) => {
  const subReducer = subReducers[outerAction.type];

  return subReducer ? subReducer(outerState, outerAction) : outerState;
};

export default appReducer;
