import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { withBus } from 'react-bus';
import { propEq } from 'src/utils/fp';
import GenericChart from 'src/components/Charts/GenericChart';
import {
  GenericChartLoading,
  CustomLoading
} from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { computeLastWeek } from 'src/components/EntityPage/Renderer/util';
import { omniChartsServiceOperations } from 'src/store/modules/omni/omniChartService';
import {
  getTrendChartInfo,
  getWeeklyTrendChartParameters
} from 'src/components/EntityPage/TrendChart/omniTrendChartUtils';
import ReduxStore from 'src/types/store/reduxStore';
import { Widget } from 'src/types/application/widgetTypes';
import { PERIODS } from 'src/store/modules/omni/constants';
import { EventBus } from 'src/types/utils';
import _cloneDeep from 'lodash/cloneDeep';
import OmniArrowChangeWithValue from 'src/components/Omni/OmniArrowChange/OmniArrowChangeWithValue';
import { OmniBaseRequestBody, addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';
import { trackTheDataSwitching } from 'src/utils/mixpanel';
import './OmniTrendChart.scss';
import { startFetchChartData, deleteKey } from 'src/store/modules/omni/omniChartService/actions';
import { destructurePathName } from 'src/utils/urlParsing';
import { determineTheDefault } from 'src/components/EntityPage/Content/OmniContentAccuracy';

const { fetchOmniChartsServiceData } = omniChartsServiceOperations;

interface OmniTrendChartProps extends RouteComponentProps {
  widget: Widget;
  eventBus: EventBus;
  reviewKeywordFilter?: string;
  uniqueName: string;
  starFilter?: string[];
}

const MainLegend = ({ legendTitle, mainLegendDisplayValue }) => {
  return (
    <div className="omni_summary_trend_legend">
      <div className="header">{legendTitle}</div>
      <div className="data">{mainLegendDisplayValue}</div>
    </div>
  );
};

MainLegend.propTypes = {
  legendTitle: PropTypes.string.isRequired,
  mainLegendDisplayValue: PropTypes.string.isRequired
};

const ChangeLegend = ({ metric, chartParams }) => {
  const metricsChangePercent = _get(chartParams, ['chartProps', 'legend', 'metricsChangePercent'], '');

  return (
    <div className="omni_summary_trend_legend_change">
      <OmniArrowChangeWithValue metric={metric} value={metricsChangePercent} />
    </div>
  );
};

ChangeLegend.propTypes = {
  metric: PropTypes.object.isRequired,
  chartParams: PropTypes.object.isRequired
};

export interface OmniTrendChartRequestBody extends OmniBaseRequestBody {
  groupBy: string;
  shareOfShelfType?: string;
  fulfillmentType?: string;
  useCombinedBrands?: boolean;
  topSearchResults?: string;
  useWeighted?: boolean;
}

const OmniTrendChart: React.FC<OmniTrendChartProps> = ({
  widget,
  eventBus,
  uniqueName,
  location,
  starFilter,
  reviewKeywordFilter
}) => {
  const {
    name,
    view: { metricFields, isSummary },
    data: { queryEntity }
  } = widget;
  const dispatch = useDispatch();
  const [selectedMetricFieldName, setSelectedMetricFieldName] = useState(metricFields[0].displayName);
  const { mainEntity } = useSelector((state: ReduxStore) => state.entityService);
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const comparisonTimePeriod = useSelector((state: ReduxStore) => state.comparisonTimePeriod);
  const { endWeek: mainEndWeek, id: idForMain } = mainTimePeriod;
  const { startWeek, id: idForComp, endWeek: compareEndWeek } = comparisonTimePeriod;
  const compStarWeek = idForComp === 'prior-period' && idForMain !== '1w' ? computeLastWeek(startWeek) : startWeek;
  const chartName = `${name}_${compStarWeek}_${mainEndWeek}`;
  const chartData = useSelector((state: ReduxStore) => state.omniChartsService[chartName]);
  const isFetching = chartData ? chartData.isFetching : true;
  const filters = useSelector((state: ReduxStore) => state.filters);
  const user = useSelector((state: ReduxStore) => state.user);
  const [topSearchResults, setTopSearchResults] = useState('');
  const { pathname } = location;
  const [entityType] = destructurePathName(pathname);
  const omniRetailersState = useSelector((state: ReduxStore) => state.omniRetailers);
  const omniRetailers = omniRetailersState.data;
  const defaultValue = determineTheDefault(filters, omniRetailers, pathname);
  const [dropdownRetailerId, setDropdownRetailerId] = useState(defaultValue);
  // content Accuracy has special case (using eventBus)
  const isProductContentAccuracy = entityType === 'product' && name === 'contentAccuracyByweekId';

  const shouldSupportToggle = metricFields.length > 1;
  const getSelectedMetricField = () => {
    return metricFields.find(propEq('displayName', selectedMetricFieldName)) || metricFields[0];
  };
  const { fulfillmentType, shareOfShelfType, displayName: displayNameInSelectedMetric } = getSelectedMetricField();

  const fetchData = useCallback(() => {
    const baseRequestBody: OmniTrendChartRequestBody = {
      includeBrandIds: [],
      includeCategoryIds: [],
      retailerIds: [],
      productIds: [],
      includeSubCategoryIds: [],
      startWeekId: compStarWeek,
      endWeekId: mainEndWeek,
      groupBy: 'weekId',
      shareOfShelfType,
      fulfillmentType
    };
    if (shouldSupportToggle) {
      trackTheDataSwitching(displayNameInSelectedMetric, location, user, { displayNameInSelectedMetric });
    }

    const requestBody = addFilterToOmniBaseReqBody(baseRequestBody, filters, pathname, mainEntity.query);

    // this is a special case, for competitor brands support.
    if (entityType === 'brand' && name.includes('ShareOfShelfByweekId')) {
      requestBody.useCombinedBrands = true;
    }
    // share of shelves for top 3,5 10, needs this payload.
    if (name.includes('ShareOfShelfByweekId') && topSearchResults) {
      requestBody.topSearchResults = topSearchResults;
    }
    if (queryEntity) {
      // this is a special case, for competitor brands support.
      if (queryEntity.selectedEntityName === 'brand') {
        if (name.startsWith('miniOmniMetricsTotalShareOfShelf')) {
          requestBody.useCombinedBrands = true;
        }
        requestBody.includeBrandIds = [];
        requestBody.includeBrandIds.push(`${queryEntity.id}`);
      }
      if (queryEntity.selectedEntityName === 'country') {
        requestBody.includeLocationCountryCode = [];
        requestBody.includeLocationCountryCode.push(`${queryEntity.id}`);
      }
      if (queryEntity.selectedEntityName === 'category') {
        requestBody.includeCategoryIds = [];
        requestBody.includeCategoryIds.push(Number(queryEntity.id));
      }
      if (queryEntity.selectedEntityName === 'retailer') {
        requestBody.retailerIds = [];
        requestBody.retailerIds.push(Number(queryEntity.id));
      }
      if (queryEntity.selectedEntityName === 'subcategory') {
        requestBody.includeSubCategoryIds = [];
        requestBody.includeSubCategoryIds.push(Number(queryEntity.id));
      }
      if (queryEntity.selectedEntityName === 'product') {
        requestBody.productIds = [];
        requestBody.productIds.push(`${queryEntity.id}`);
      }
    }

    if (starFilter) {
      requestBody.starsFilter = starFilter;
    }

    if (reviewKeywordFilter) {
      requestBody.reviewKeywords = reviewKeywordFilter ? [reviewKeywordFilter] : [];
    }

    if (isProductContentAccuracy) {
      requestBody.retailerIds = [];

      requestBody.retailerIds.push(Number(dropdownRetailerId));
    }

    if (fulfillmentType === 'all') {
      delete baseRequestBody.fulfillmentType;
    }
    dispatch(
      startFetchChartData({
        chartName,
        data: []
      })
    );
    dispatch(
      fetchOmniChartsServiceData(requestBody, PERIODS.MAIN, chartName, metricFields[0].name, {
        compareStartWeek: compStarWeek,
        compareEndWeek
      })
    );
  }, [
    dispatch,
    compStarWeek,
    mainEndWeek,
    metricFields,
    chartName,
    fulfillmentType,
    queryEntity,
    location,
    filters,
    shareOfShelfType,
    shouldSupportToggle,
    user,
    displayNameInSelectedMetric,
    starFilter,
    topSearchResults
  ]);

  const handleMainMetricToggle = ({ displayName }: { displayName: string }) => {
    setSelectedMetricFieldName(displayName);

    // in share of shelves, by changing Main Metric page, we should update topSearchResult,
    // it will refetch the graph and send event for all other widget Element in the page

    if (displayName.includes('Share of Shelf') || displayName.includes('Placements')) {
      if (displayName && (displayName.includes('3') || displayName.includes('5') || displayName.includes('10'))) {
        const num = displayName.match(/\d+/)[0];
        setTopSearchResults(`top${num}`);

        // getAverageShareOfShelf does not support "all" option, which is required in getShareOfShelfShards
      } else {
        setTopSearchResults('');
      }
      eventBus.emit('noticeShareOfShelveMainMetricFields', { displayName });
    }
  };

  // subscribe and unsubscribe the event
  useEffect(() => {
    if (shouldSupportToggle) {
      eventBus.on(`${uniqueName}toggleChartMainMetric`, handleMainMetricToggle);
      return () => {
        eventBus.off(`${uniqueName}toggleChartMainMetric`, handleMainMetricToggle);
      };
    }
    return () => {};
  }, [eventBus, uniqueName, shouldSupportToggle]);

  useEffect(() => {
    if (isProductContentAccuracy) {
      const onOmniDropDownSelected = (retailer) => {
        setDropdownRetailerId(retailer.retailerSelected);
      };

      eventBus.on('omniDropDownSelected', onOmniDropDownSelected);

      return () => {
        eventBus.off('omniDropDownSelected', onOmniDropDownSelected);
      };
    }

    return () => {};
  }, [entityType, eventBus, isProductContentAccuracy, dropdownRetailerId]);

  useEffect(() => {
    fetchData();
    return () => {
      dispatch(deleteKey(chartName));
    };
  }, [fetchData, eventBus, chartName, dispatch, topSearchResults]);

  const buildDropdownOptions = () => {
    return metricFields.map(({ displayName }: { displayName: string }) => ({
      eventId: { displayName },
      eventName: `${uniqueName}toggleChartMainMetric`,
      displayName,
      isSelected: displayName === selectedMetricFieldName
    }));
  };

  const getChartParams = () => {
    const clonedView = _cloneDeep(widget.view);

    clonedView.metricFields = [getSelectedMetricField()];

    const trendChartInfo = getTrendChartInfo(widget, mainTimePeriod, comparisonTimePeriod, chartData);

    const { chartPropsOverride, omniData, chartDisplayTimePeriod, chartComparisonDisplayTimePeriod, groupByFieldName } =
      trendChartInfo;
    const chartParams = getWeeklyTrendChartParameters(
      chartPropsOverride,
      omniData,
      chartDisplayTimePeriod,
      chartComparisonDisplayTimePeriod,
      comparisonTimePeriod,
      groupByFieldName,
      clonedView,
      widget
    );

    if (chartParams) {
      const { chartProps, chartSeries } = chartParams;
      if (shouldSupportToggle) {
        const options = buildDropdownOptions();
        chartProps.exporting = { buttons: options, enabled: true };
        chartProps.title.dropdownStyle = { marginLeft: -5 };
      }
      return { chartProps, chartSeries };
    }
    return chartParams;
  };

  const renderTrendChart = () => {
    if (isFetching || !chartData || !chartData.main) {
      return isSummary ? <CustomLoading height={237} width={300} padding={10} /> : <GenericChartLoading />;
    } else {
      const chartParams = getChartParams();
      if (!chartParams) {
        return isSummary ? <CustomLoading height={237} width={300} padding={10} /> : <GenericChartLoading />;
      }
      // use for mini metric
      if (isSummary) {
        const legendTitleInfo = _get(chartParams, ['chartProps', 'legend'], {});
        const mainLegendDisplayValue = _get(chartParams, ['chartProps', 'legend', 'mainLegendDisplayValue'], '');
        return (
          <>
            <MainLegend legendTitle={legendTitleInfo.legendTitle} mainLegendDisplayValue={mainLegendDisplayValue} />
            <GenericChart {...chartParams} />
            <ChangeLegend metric={metricFields[0]} chartParams={chartParams} />
          </>
        );
      }
      return <GenericChart {...chartParams} />;
    }
  };
  return <div id={widget.name}>{renderTrendChart()}</div>;
};

export default withRouter(withBus('eventBus')(OmniTrendChart));
