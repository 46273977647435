import {
  BrandSvg,
  ProductSvg,
  DisplaySvg,
  WalmartProductSvg,
  WalmartSBA,
  WalmartVideoSvg,
  SamsClubProductsSvg,
  SamsClubBrandsSvg
} from '../../../../components/AdCampaignBuilder/Asset'; // eslint-disable-line

type ImageType =
  | typeof BrandSvg
  | typeof DisplaySvg
  | typeof WalmartProductSvg
  | typeof WalmartSBA
  | typeof WalmartVideoSvg
  | typeof SamsClubProductsSvg
  | typeof SamsClubBrandsSvg;

// add more types as required

interface ImageMapping {
  [key: string]: ImageType;
}

const imageMappings: { [key: string]: ImageMapping } = {
  default: {
    sponsoredBrands: BrandSvg,
    sponsoredBrandsVideo: BrandSvg,
    sponsoredDisplay: DisplaySvg,
    sponsoredProducts: ProductSvg
  },
  walmart: {
    sponsoredProducts: WalmartProductSvg,
    sba: WalmartSBA,
    video: WalmartVideoSvg
  },
  'samsclub-us': {
    sponsoredProducts: SamsClubProductsSvg,
    sba: SamsClubBrandsSvg
    // video: WalmartVideoSvg
  }
  // Add more platform mappings as required
};

export const getImageByPlatformType = (platformType: string, campaignType: string): ImageType => {
  const platformKey = Object.keys(imageMappings).find((key) => platformType.toLowerCase().includes(key)) || 'default';
  return imageMappings[platformKey][campaignType];
};

export function parseAdPlatformSettings(adPlatformSettings) {
  adPlatformSettings.forEach((adPlatformSetting) => {
    if (adPlatformSetting.settingType === 'campaignType') {
      const currentPlatformType = adPlatformSetting!.platformType.toLowerCase();

      adPlatformSetting.image = getImageByPlatformType(currentPlatformType, adPlatformSetting.id);

      if (!adPlatformSetting.image) {
        console.warn(`No image found for \`campaignType\`: "${adPlatformSetting.id}"`);
      }
    }
    if (adPlatformSetting.settingType === 'campaignStrategy') {
      adPlatformSetting.type = 'adAutomationStrategy';
      adPlatformSetting.automationStrategyId = adPlatformSetting.settingId;
      adPlatformSetting.name = adPlatformSetting.extendedAttributes.name;
      adPlatformSetting.displayName = adPlatformSetting.extendedAttributes.name;
    }
  });
  return adPlatformSettings;
}
