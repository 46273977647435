import React, { useState } from 'react';
import { SlRow } from '@stackline/ui';
import { ProductGrowthFilterModal } from 'src/components/BeaconRedesignComponents/ProductGrowthModal/ModalComponents/ProductGrowthFilterModal';
import { useAppSelector } from 'src/utils/Hooks';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

/**
 *
 * @returns Button + Modal combo for the Product Growth filter controls
 */
export const ProductGrowthFilterButton = () => {
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const handleClose = () => {
    setFilterModalOpen(false);
  };

  /**
   * Doesn't make sense to filter on subcategories when we are already on a subcategory page
   */
  const isDisabled = useAppSelector((state) =>
    ['subcategory', 'product'].includes(state.entityService.mainEntity.type)
  );

  // Default active state for the SlButton since we will always have a subcategory selected
  return (
    <>
      <SlButton active variant="normal" onClick={() => setFilterModalOpen(true)} disabled={isDisabled}>
        <SlRow spacing="sm" verticalPosition="center">
          Filter
        </SlRow>
      </SlButton>
      <ProductGrowthFilterModal open={filterModalOpen} handleClose={handleClose} />
    </>
  );
};
