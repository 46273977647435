import _isNil from 'lodash/isNil';
import numeral from 'numeral';
import { ValueOf } from 'sl-api-connector/types';

import colors from 'src/utils/colors';
import { moneyConverter, volumeConverter } from './money';
import { METRICTYPE, DATATYPE } from './entityDefinitions';

require('numeral/locales');

numeral.register('locale', 'pl_PL', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: () => '.',
  currency: {
    symbol: 'zł'
  }
});

numeral.register('locale', 'es-mx', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'mm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: (number) => {
    const b = number % 10;
    return b === 1 || b === 3
      ? 'er'
      : b === 2
      ? 'do'
      : b === 7 || b === 0
      ? 'mo'
      : b === 8
      ? 'vo'
      : b === 9
      ? 'no'
      : 'to';
  },
  currency: {
    symbol: '$'
  }
});

numeral.register('locale', 'sv-se', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: () => '.',
  currency: {
    symbol: 'kr'
  }
});

numeral.register('locale', 'ms-my', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: () => '.',
  currency: {
    symbol: 'RM'
  }
});

numeral.register('locale', 'id', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: () => '.',
  currency: {
    symbol: 'Rp'
  }
});

export function buildMetricValue(
  rawValue: number,
  metricType: ValueOf<typeof METRICTYPE>,
  currencySymbol: string,
  showTruncatedValue?: boolean,
  dataType?: ValueOf<typeof DATATYPE>,
  locale?: string,
  capPercentage: boolean = true
) {
  if (__DEV__) {
    if (_isNil(currencySymbol)) {
      console.error('Nil `currencySymbol` passed to `buildMetricValue`.');
    }
    if (_isNil(locale)) {
      console.error('Nil `locale` passed to `buildMetricValue`');
    }
  }

  if (locale) {
    numeral.locale(`${locale}`);
  }

  // We cannot override locales and the library has not been updated in ~4 years
  // so this is a DE and FR specific fix (by default, it uses a space as a separator)

  if (locale === 'de') {
    numeral.localeData('de').delimiters.thousands = '.';
  }
  if (locale === 'fr') {
    numeral.localeData('fr').delimiters.thousands = '.';
  }

  switch (metricType) {
    case METRICTYPE.INTEGER: {
      return { value: numeral(rawValue).format('0,0') };
    }
    case METRICTYPE.MONEY: {
      const rawValNum =
        typeof rawValue === 'number'
          ? rawValue
          : numeral((rawValue as string).replace(currencySymbol || '$', '')).value();

      const [prefix, value, suffix] = moneyConverter(rawValNum, currencySymbol, showTruncatedValue);
      // Covert string value to the number with "+" sign, it could be done in moneyConverter function, but not quite sure if it can affect other functions.
      // now we can cover "0.00" string to number. -- numeral library does not cover "0.00" in their Regex
      return { prefix, value: numeral(+value).format(+value <= 1000 ? '0,0.00' : '0,000'), suffix };
    }
    case METRICTYPE.PERCENT:
      if (capPercentage && rawValue > 10) {
        return { value: '1,000', suffix: '%' };
      }
      return { value: `${numeral(rawValue * 100).format('0.00')}`, suffix: '%' };
    case METRICTYPE.VOLUME: {
      const volumeVal = volumeConverter(rawValue, showTruncatedValue);
      const format = (volumeVal[1] !== '' || dataType === DATATYPE.DECIMAL) && showTruncatedValue ? '0,00.00' : '0,00';
      return {
        value:
          typeof volumeVal[0] !== 'number' || Number.isNaN(volumeVal[0]) ? '0' : numeral(volumeVal[0]).format(format),
        suffix: volumeVal[1]
      };
    }
    case METRICTYPE.MULTIPLE:
      return { value: numeral(rawValue).format('0,0.00'), suffix: 'x' };
    default:
      return { value: numeral(rawValue).format('0,0.00') };
  }
}

/**
 * Given a value at one time and another time, returns the percent change between the two periods as well as the
 * absolute change.  Handles cases where divide by zero might occur, capping changes starting at zero to 100%.
 *
 * Returns the percent change as a number between -1 and 1.
 */
export const computePercentChange = (comparisonValue: number, mainValue: number) => {
  const periodChange = mainValue - comparisonValue;
  const percentChange = comparisonValue > 0 ? periodChange / Math.abs(comparisonValue) : mainValue > 0 ? 10 : 0;

  return { periodChange, percentChange };
};

/**
 * Adds a plus sign to a percent string if it's not negative.
 */
export const formatPercent = (percentString: string) => {
  if (_isNil(percentString) || percentString.includes('NaN')) {
    return '+0.00%';
  }

  return percentString.includes('-') ? percentString : `+${percentString}`;
};

/**
 * Returns the color which to display the text for a given `percentString`.
 */
export const percentColor = (percentString: string) => (percentString.includes('-') ? colors.red : colors.green);

/**
 * Allow parseFloat EU formatted number. ()
 */
export const localeParseFloat = (s: string, thousandsSeparator: string, decimalSeparator: string) => {
  // Remove thousand separators, and put a point where the decimal separator occurs
  s = Array.from(s, (c) => (c === thousandsSeparator ? '' : c === decimalSeparator ? '.' : c)).join('');
  // Now it can be parsed
  return parseFloat(s);
};

export const customLocaleString = (s: string, thousandsSeparator: string, decimalSeparator: string) => {
  //  split the digit to a number part and possible decimal part
  const divider = s.toString().split(decimalSeparator);

  const numberPart = parseInt(divider[0], 10).toString();
  const decimalPart = divider[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;

  return numberPart.replace(thousands, thousandsSeparator) + (decimalPart ? decimalSeparator + decimalPart : '');
};
