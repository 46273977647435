import { AxiosResponse } from 'axios';
import { useState } from 'react';

/**
 * Custom hook for wrapping an asynchronous function with loading state and error handling.
 *
 * @template T - The type of parameters expected by the asynchronous function.
 * @template U - The type of the response returned by the asynchronous function.
 *
 * @param {function(T): Promise<U>} cb - The asynchronous function to be wrapped.
 * @returns {{
 *   wrappedFunction: function(T): Promise<void>,
 *   loading: boolean,
 *   error: Error | null
 * }}
 *
 */
export const useAsyncFunction = (cb: (params: any) => any) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const wrappedFunction = async (args: any): Promise<AxiosResponse<any>> => {
    try {
      setLoading(true);
      return await cb(args);
    } catch (err) {
      console.error('Callback failed: ', err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { wrappedFunction, loading, error };
};
