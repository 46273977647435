/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { store } from 'src/main';
import ReduxStore from 'src/types/store/reduxStore';
import SegmentEdit from './SegmentEdit';
import SegmentExport from './SegmentExport';
import './SegmentPowerTools.scss';
import { withPush } from 'src/utils/hoc';
import { PushFn } from 'src/types/application/types';
import BusinessUnitListing from 'src/routes/HomePage/SearchList/BusinessUnitListing';
import BusinessUnitBuilderDialog from 'src/components/BusinessUnitBuilder/BusinessUnitBuilderDialog';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import { useOnce } from 'src/utils/Hooks';
import { createAllCategoryEntity } from 'src/store/modules/entityService/operations';
import SavedSearchProductExport from 'src/components/SegmentPowerTools/SavedSearchProductExport';
import RawSegmentImport from 'src/components/SegmentPowerTools/RawSegmentImport';

const mapStateToProps = (state: ReduxStore) => ({
  isStacklineUser: state.user.config.profile.email.toLowerCase().includes('stackline')
});

const SegmentPowerTools: React.FC<{ push: PushFn } & ReturnType<typeof mapStateToProps>> = ({
  isStacklineUser,
  push
}) => {
  useEffect(() => {
    if (!isStacklineUser) {
      push('/');
    }
  }, [isStacklineUser, push]);

  useOnce(() => store.dispatch(createAllCategoryEntity('mainEntity', 'company')));

  const [buBuilderDialogOpen, setBUBuilderDialogOpen] = useState(false);

  if (!isStacklineUser) {
    return null;
  }

  return (
    <div className="segment-power-tools">
      <h1>Segment Power Tools</h1>
      <SegmentEdit />
      <SegmentExport />
      <h2 style={{ marginTop: 50 }}>Business Unit Listing</h2>
      <LargeMuiButton label="Create Business Unit" onClick={() => setBUBuilderDialogOpen(true)} />
      <BusinessUnitListing style={{ display: 'flex', flexDirection: 'column', width: '100%' }} />
      <BusinessUnitBuilderDialog open={buBuilderDialogOpen} onClose={() => setBUBuilderDialogOpen(false)} />
      <SavedSearchProductExport />
      <RawSegmentImport />
    </div>
  );
};

export default withPush(connect(mapStateToProps)(SegmentPowerTools));
