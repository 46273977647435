import React, { useState } from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import Cycle from '!svg-react-loader!src/components/common/UploadAnimation/cycle.svg'; // eslint-disable-line
import Check from '!svg-react-loader!src/components/common/UploadAnimation/check.svg'; // eslint-disable-line
import CloseButton from 'src/components/common/Buttons/CloseButton';
import './submitScreen.scss';

export const SubmitDialog = ({ dialogDetail }) => {
  const { title, subtitle, items } = dialogDetail;

  const itemsByType = [];
  items.forEach((item) => {
    const indexFound = itemsByType.findIndex((itemBytype) => itemBytype.type === item.type);
    if (indexFound === -1) {
      itemsByType.push({
        type: item.type,
        items: [item]
      });
    } else {
      itemsByType[indexFound].items.push(item);
    }
  });

  return (
    <div className="submit_dialog">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
      <div className="entity_list">
        {itemsByType.map((itemBytype) => {
          return (
            <div key={itemBytype.type} className="type_block" style={{ marginBottom: 20 }}>
              <div>
                <strong>Rejected {itemBytype.type}: </strong>
              </div>
              <div>
                {itemBytype.items.map((item, index) => {
                  return (
                    <div className="entity_line" key={index}>
                      <div>{item.name}</div>
                      <div>{item.reason}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

SubmitDialog.propTypes = {
  dialogDetail: PropTypes.object.isRequired
};

const PartialSuccessScreen = ({ topLine, dialogDetail, buttons, retailer }) => {
  const [open, setOpen] = useState(false);

  const retailerName = _get(retailer, ['displayName'], 'the ad platform');
  return (
    <div className="finish_screen">
      <div className="icon_group">
        <Cycle className="success_cycle" />
        <Check className="success_check" />
      </div>
      <div className="content_area">
        <div className="title">Success</div>
        <div className="subtitle">{topLine}</div>
        <div className="subtitle">
          However, {retailerName} returned{' '}
          <strong role="button" style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
            errors
          </strong>{' '}
          in this request.
        </div>
        <div className="button_group">
          {buttons.map((button, index) => {
            return (
              <LargeMuiButton
                key={index}
                style={{ textDecoration: 'none', cursor: 'pointer', marginLeft: 5, marginRight: 5 }}
                onClick={() => {
                  button.onClick();
                }}
                {...button.buttonProps}
              >
                {button.text}
              </LargeMuiButton>
            );
          })}
        </div>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <SubmitDialog dialogDetail={dialogDetail} />
        <CloseButton onClick={() => setOpen(false)} />
      </Dialog>
    </div>
  );
};

PartialSuccessScreen.propTypes = {
  retailer: PropTypes.object.isRequired,
  dialogDetail: PropTypes.object,
  topLine: PropTypes.string,
  bottomLine: PropTypes.string,
  buttons: PropTypes.array
};

PartialSuccessScreen.defaultProps = {
  dialogDetail: {},
  topLine: '',
  bottomLine: '',
  buttons: []
};

const mapStateToProps = ({ retailer }) => ({ retailer });
export default connect(mapStateToProps)(PartialSuccessScreen);
