import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ViewDetails from 'src/components/ViewDetails';
import SavedSearchEditIconsContainer from './SavedSearchEditIconsContainer';

const styles = {
  root: {
    display: 'flex',
    flexFlow: 'row nowrap'
  },
  titleWrapper: {
    display: 'inline-block',
    verticalAlign: 'middle',
    lineHeight: '34px',
    textDecoration: 'none'
  },
  gridHeaderTitle: {
    display: 'inline-block',
    verticalAlign: 'middle',
    lineHeight: '34px',
    textDecoration: 'none'
  }
};

const propTypes = {
  appName: PropTypes.string.isRequired,
  buildSummaryUrl: PropTypes.func.isRequired,
  currentSegment: PropTypes.object,
  title: PropTypes.string.isRequired
};

const defaultProps = {
  currentSegment: null
};

const SummaryDataHeader = ({ appName, buildSummaryUrl, currentSegment, title }) => (
  <>
    <div className="grid-header" style={styles.root}>
      <div style={{ flex: '1 1' }}>
        {title.includes('All Categories') || title.includes('All Brands') ? (
          <div className="grid-header__title" style={styles.titleWrapper}>
            {title}
            {appName === 'atlas' && title.includes('All Categories') ? <ViewDetails url={buildSummaryUrl()} /> : null}
            {appName === 'beacon' && (title.includes('All Categories') || title.includes('All Brands')) ? (
              <ViewDetails url={buildSummaryUrl()} />
            ) : null}
          </div>
        ) : null}
        {!title.includes('All Categories') && !title.includes('All Brands') ? (
          <NavLink className="grid-header__title" to={buildSummaryUrl()} style={styles.gridHeaderTitle}>
            {title} <ViewDetails url={buildSummaryUrl()} />
          </NavLink>
        ) : null}
        {/*  SEGMENT SUBTITLE _ CREATED BY  */}
        {currentSegment && !currentSegment.canEdit ? (
          <h1 className="sl-subheader-text">
            Created by {currentSegment.owner.firstName} {currentSegment.owner.lastName}
          </h1>
        ) : null}
      </div>
      {currentSegment ? <SavedSearchEditIconsContainer currentSavedSearch={currentSegment} /> : null}
    </div>
    <hr className="sl-divider sl-divider--no-margin-top" />
  </>
);

SummaryDataHeader.propTypes = propTypes;
SummaryDataHeader.defaultProps = defaultProps;

export default SummaryDataHeader;
