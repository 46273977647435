import ReduxStore from 'src/types/store/reduxStore';
import { getLastWeekOfYear, getNextWeek, subtractWeeks } from 'src/utils/dateformatting';

export const mappingPointToWeekID = (
  pointIndex: number,
  mainTimePeriod: ReduxStore['mainTimePeriod'],
  comparisonTimePeriod: ReduxStore['comparisonTimePeriod']
) => {
  const { startWeek: mainStartWeek, endWeek: mainEndWeek } = mainTimePeriod;
  const { startWeek: compStartWeek, endWeek: compEndWeek } = comparisonTimePeriod;
  const countWeekComparison = subtractWeeks(compStartWeek, compEndWeek);

  if (pointIndex + 1 <= countWeekComparison) {
    const isOnSameYear = Math.floor(compStartWeek / 100) === Math.floor(compEndWeek / 100);
    const lastWeekOfYear = getLastWeekOfYear(compStartWeek);
    if (isOnSameYear || compStartWeek + pointIndex <= lastWeekOfYear) {
      return compStartWeek + pointIndex;
    } else {
      const extraWeek = compStartWeek + pointIndex - lastWeekOfYear;
      const startWeekOfNextYear = getNextWeek(lastWeekOfYear);
      return startWeekOfNextYear + extraWeek - 1;
    }
  } else {
    const isOnSameYear = Math.floor(mainStartWeek / 100) === Math.floor(mainEndWeek / 100);
    const lastWeekOfYear = getLastWeekOfYear(mainStartWeek);
    const possibleWeekId = mainStartWeek + (pointIndex - countWeekComparison);
    if (isOnSameYear || possibleWeekId <= lastWeekOfYear) {
      return possibleWeekId;
    } else {
      const extraWeek = possibleWeekId - lastWeekOfYear;
      const startWeekOfNextYear = getNextWeek(lastWeekOfYear);
      return startWeekOfNextYear + extraWeek - 1;
    }
  }
};
