import React from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import InfoTooltip from 'src/components/AdCampaignBuilder/Widgets/InfoTooltip';

interface StacklineOptimizationProps {
  value: number;
  setValue: (newMinBudget: number) => void;
  label: string;
  tooltipLabel: string;
}

const StacklineOptimizationRow = ({ value, setValue, label, tooltipLabel }: StacklineOptimizationProps) => {
  const { retailer } = useSelector((state: ReduxStore) => state);

  return (
    <div className="setting_row">
      <div className="label">{label}</div>
      <div>
        <NumberFormat
          value={value}
          thousandSeparator
          prefix={retailer.currencySymbol}
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          onValueChange={({ floatValue }) => setValue(floatValue)}
          // Mimic the style of the MUI `TextInput`
          style={{
            height: '32px',
            outline: 'none',
            border: '0',
            borderBottomColor: 'currentcolor',
            borderBottomStyle: 'none',
            borderBottomWidth: '0px',
            background: 'none',
            borderBottom: '1px solid #eee',
            width: '100px',
            fontSize: '16px',
            color: 'currentColor',
            fontFamily: 'Roboto'
          }}
        />
      </div>
      <InfoTooltip style={{ paddingLeft: 10, paddingTop: 0 }}>{tooltipLabel}</InfoTooltip>
    </div>
  );
};

export default StacklineOptimizationRow;
