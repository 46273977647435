import { useMemo } from 'react';
import { useBaseMetricRequestBuilder } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { useAppSelector } from 'src/utils/Hooks';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import _get from 'lodash/get';
import { PaginationRequestOverride } from 'src/components/BeaconRedesignComponents/MetricList/ProductListContainer';
import { KEYWORD_COUNT_QUERY_KEY, PRODUCT_COUNT_QUERY_KEY } from './queryKeys';

export interface EntityCountData {
  count: number;
  isLoading: boolean;
}
/**
 * Get the total number of products for a table
 */
export const useTotalProductCount = (requestOverride?: PaginationRequestOverride): EntityCountData => {
  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);
  const { startWeek, endWeek } = useAppSelector((state) => state.mainTimePeriod);
  const requestId = `productCount-${mainEntity.type}-${mainEntity.id}`;
  const requestBuilder = useBaseMetricRequestBuilder({
    requestId,
    fields: [
      {
        name: 'stacklineSku'
      }
    ],
    indexName: 'sales',
    startWeekId: startWeek,
    endWeekId: endWeek,
    groupByFieldName: 'retailerId'
  });

  if (requestOverride) {
    const { termFilters, rangeFilters } = requestOverride;
    termFilters.forEach(({ fieldName, condition, values }) => {
      requestBuilder.replaceConditionTermFilter(fieldName, condition, values);
    });

    rangeFilters.forEach(({ fieldName, minValue, maxValue }) => {
      requestBuilder.addConditionRangeFilter(fieldName, minValue, maxValue);
    });
  }

  const requestBody = [requestBuilder.build()];

  const { data: response, isLoading } = useGenericAdvancedSearch({
    requestId,
    queryKeys: [PRODUCT_COUNT_QUERY_KEY, ...requestBody],
    requestBody,
    allowCancel: false
  });

  const count = useMemo(() => {
    return _get(
      response,
      ['data', 0, 'aggregations', 'by_retailerId', 0, 'additionalValues', 'stacklineSku_cardinality_value'],
      0
    );
  }, [response]);

  return useMemo(
    () => ({
      count,
      isLoading
    }),
    [count, isLoading]
  );
};

/**
 * Get the total number of keywords (search terms) for a table
 */
export const useTotalKeywordCount = (requestOverride?: PaginationRequestOverride): EntityCountData => {
  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);
  const { startWeek, endWeek } = useAppSelector((state) => state.mainTimePeriod);
  const requestId = `keywordCount-${mainEntity.type}-${mainEntity.id}`;
  const requestBuilder = useBaseMetricRequestBuilder({
    requestId,
    fields: [
      {
        name: 'searchTerm'
      }
    ],
    indexName: 'traffic',
    startWeekId: startWeek,
    endWeekId: endWeek,
    groupByFieldName: 'retailerId'
  });

  if (requestOverride) {
    const { termFilters, rangeFilters } = requestOverride;
    termFilters.forEach(({ fieldName, condition, values }) => {
      requestBuilder.replaceConditionTermFilter(fieldName, condition, values);
    });

    rangeFilters.forEach(({ fieldName, minValue, maxValue }) => {
      requestBuilder.addConditionRangeFilter(fieldName, minValue, maxValue);
    });
  }

  const requestBody = [requestBuilder.build()];

  const { data: response, isLoading } = useGenericAdvancedSearch({
    requestId,
    queryKeys: [KEYWORD_COUNT_QUERY_KEY, ...requestBody],
    requestBody,
    allowCancel: false
  });

  const count = useMemo(() => {
    return _get(
      response,
      ['data', 0, 'aggregations', 'by_retailerId', 0, 'additionalValues', 'searchTerm_cardinality_value'],
      0
    );
  }, [response]);

  return useMemo(
    () => ({
      count,
      isLoading
    }),
    [count, isLoading]
  );
};
