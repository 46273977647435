import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';

import colors from 'src/utils/colors';
import { userOperations } from 'src/store/modules/user';
import UserAccountUpload from './UserAccountUpload/UserAccountUpload';

const UserAccountReviews = ({ entityService, retailer, updateUserAccount, getUploadPageDetail }) => {
  if (!entityService.mainEntity) {
    return null;
  }
  const { searchParams, hashId, retailers, buttons, getLatestApi } = getUploadPageDetail('review');

  // const getLatestApi = '/api/account/GetLatestContentUploadRequests';
  return (
    <UserAccountUpload
      headerText="High Risk Reviews"
      retailers={retailers}
      currRetailer={retailer}
      buttons={buttons}
      updateUserAccount={updateUserAccount}
      getLatestApi={getLatestApi}
      uploadType="reviews-upload"
    >
      <div>
        Upload a list of “high risk” keywords to help identify problematic product reviews. After successfully
        uploading, you can visit the{' '}
        <a // eslint-disable-line
          style={{ color: colors.blue, cursor: 'pointer' }}
          onClick={() => window.location.assign(`/client/${hashId}${searchParams}&tab=reviews&subtab=highRiskReviews`)}
        >
          High Risk Reviews
        </a>{' '}
        page to monitor negative feedback from customers.
      </div>
    </UserAccountUpload>
  );
};

UserAccountReviews.propTypes = {
  entityService: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired,
  updateUserAccount: PropTypes.func.isRequired,
  getUploadPageDetail: PropTypes.func.isRequired
};

const mapStateToProps = (state) => _pick(state, ['app', 'entityService', 'retailer']);

const mapDispatchToProps = {
  updateUserAccount: userOperations.updateUserAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccountReviews);
