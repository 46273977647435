/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import _get from 'lodash/get';
import { withBus } from 'react-bus';
import { useBus } from 'src/utils/Hooks';
import { EventBus } from 'src/types/utils';
import pluralize from 'pluralize';
import { AdManagerEvents } from 'src/types/application/event';
import ComponentInView from 'src/components/common/ComponentInView/ComponentInView';
import { isCriteo } from 'src/utils/app';
import { Tooltip } from '@mui/material';
import { TooltipIcon } from 'src/components/SvgIcons';
import useDebouncedEmitter from 'src/components/AdManager/Search/hooks/useDebouncedEmitter';

export const getPills: Function = (apiResult: any) => {
  const pills: any[] = [];
  if (!apiResult) {
    return pills;
  }
  const termFilters = _get(apiResult, ['apiRequest', 0, 'conditions', 'termFilters'], []);
  const computeFilters = _get(apiResult, ['apiRequest', 0, 'aggregations', 0, 'conditions', 'computeFilters'], []);
  termFilters.forEach((filter) => {
    let fieldDisplayName = '';
    const fields = [
      'campaignNameFuzzy',
      'targetingTextFuzzy',
      'portfolioNameFuzzy',
      'adGroupNameFuzzy',
      'entityNameFuzzy',
      'keyword',
      'searchTermFuzzy',
      'searchKeywordFuzzy'
    ];

    // if (shouldShowCriteo()) {
    //   fields.push('retailerNameFuzzy');
    // }
    if (fields.includes(filter.fieldName)) {
      fieldDisplayName = 'Keyword';
    }
    if (['campaignName', 'searchKeyword'].includes(filter.fieldName)) {
      fieldDisplayName = 'Keyword Exact';
    }

    if (
      [
        'excludedKeyword',
        'excludedCampaignNameFuzzy',
        'excludedPortfolioNameFuzzy',
        'excludedEntityNameFuzzy',
        'excludedTargetingTextFuzzy'
        // 'excludedSearchTerm'
      ].includes(filter.fieldName)
    ) {
      fieldDisplayName = 'Exclude';
    }
    if (filter.fieldName === 'entityId') {
      fieldDisplayName = 'Entities';
    }
    if (filter.fieldName === 'portfolioId') {
      fieldDisplayName = 'Portfolios';
    }
    if (filter.fieldName === 'campaignId') {
      fieldDisplayName = 'Campaigns';
    }
    if (filter.fieldName === 'statusDerived') {
      fieldDisplayName = 'Status';
    }
    if (filter.fieldName === 'automationStrategyId') {
      fieldDisplayName = 'Optimization';
    }
    if (filter.fieldName === 'derivedCampaignType') {
      fieldDisplayName = 'Ad Type';
    }
    if (['targetingText'].includes(filter.fieldName)) {
      fieldDisplayName = 'Exact';
    }
    if (filter.fieldName === 'excludedTargetingText') {
      fieldDisplayName = 'Exact Exclude';
    }
    if (filter.fieldName === 'targetingType') {
      fieldDisplayName = 'Match Type';
    }
    if (filter.fieldName === 'isAutoAdded') {
      fieldDisplayName = 'Added Method';
    }
    if (filter.fieldName === 'categoryId') {
      fieldDisplayName = 'Categories';
    }
    if (filter.fieldName === 'subCategoryId') {
      fieldDisplayName = 'Subcategories';
    }
    if (filter.fieldName === 'brandId') {
      fieldDisplayName = 'Brands';
    }

    if (fieldDisplayName) {
      const values = _get(filter, ['values'], []);
      const unionValues = new Set(values);
      if (unionValues.size > 0) {
        pills.push({ name: fieldDisplayName, count: unionValues.size });
      }
    }
  });
  computeFilters.forEach((filter: any) => {
    if (filter.fieldName === 'spendComputed') {
      pills.push({ name: 'Ad Spend', count: 1 });
    }
    if (filter.fieldName === 'costPerClick') {
      pills.push({ name: 'CPC', count: 1 });
    }
    if (filter.fieldName === 'returnOnAdSpend') {
      pills.push({ name: 'ROAS', count: 1 });
    }
  });
  return pills;
};

export const SinglePill: React.FC<{ pill: any; onPillClick: any }> = ({ pill, onPillClick }) => {
  return <div role="button" onClick={onPillClick} className="single_search_pill">{`${pill.name} (${pill.count})`}</div>;
};

export const FILTER_BUTTON_TEXT = {
  OPEN: '+ Add Filters',
  CLOSE: '- Hide Filters',
  CREATE: '+ Create Segment',
  EDIT: '+ Edit Segment'
};

const SearchResultHeader: React.FC<{ eventBus: EventBus; widget: any; queryParams: any }> = ({
  eventBus,
  widget,
  queryParams
}) => {
  const [headerName, setHeader] = useState(_get(widget, 'data.headerOverride', 'Search Result'));
  const [buttonText, setButtonText] = useState(FILTER_BUTTON_TEXT.OPEN);
  const [loading, setIsLoading] = useState(true);
  const [entityType, setEntityType] = useState('');
  const [entityTotalCount, setEntityTotalCount] = useState(0);
  const [apiResult, setApiResult] = useState(undefined);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedBidChange, setSelectedBidChange] = useState(false);
  const [headerTooltip, setHeaderTooltip] = useState('');
  const AUTO_CAMPAIGN_PRODUCTS = '. Showing only products in auto targeting campaigns.';

  useBus(eventBus, 'setSearchHeader', ({ isLoading, entity: { displayName = '', totalCount, result } }) => {
    const displayNameNew = displayName === 'Ad Group' && isCriteo() ? 'Line Item' : displayName;
    const { headerOverride } = widget.data;
    setHeader((prevHeaderName: string) =>
      headerOverride
        ? displayNameNew
          ? `${pluralize(displayNameNew)} Selection`
          : prevHeaderName
        : `${pluralize(displayNameNew)} Detail`
    );
    if (displayNameNew) {
      setEntityType(displayNameNew.toLowerCase());
    }
    setEntityTotalCount(totalCount);
    setApiResult(result);
    setIsLoading(isLoading);
  });

  useBus(eventBus, 'setSearchFilterButtonText', (isOpened: boolean) => {
    setIsSidebarOpen(isOpened);
    if (isOpened) {
      setButtonText(FILTER_BUTTON_TEXT.CLOSE);
    } else {
      setButtonText(FILTER_BUTTON_TEXT.OPEN);
    }
  });

  useBus(eventBus, AdManagerEvents.ENTITY_EVENT_SELECT_SA_ACTION, ({ saAction }: { saAction: string }) => {
    if (saAction === 'bid') {
      setSelectedBidChange(true);
    } else {
      setSelectedBidChange(false);
    }
  });

  useDebouncedEmitter('srg/tooltipHeader', setHeaderTooltip, 300); // 500 ms debounce delay

  const showAdditionalText =
    !loading &&
    selectedBidChange &&
    (_get(queryParams, 'rid') === '2' || _get(queryParams, 'rid') === '25') &&
    _get(queryParams, 'groupByField') === 'stacklineSku';

  const pills = getPills(apiResult);

  const openFilter = () => {
    eventBus.emit('toggleSearchSidebar', null);
  };

  const isCreateSegmentPage = _get(queryParams, 'segmentStage') === 'segmentCreation';
  const isEditSegmentPage = _get(queryParams, 'segmentStage') === 'segmentEdit';

  const { hidePills, hideHeader, disableSearchPlaceholder, subHeaderOverride } = widget.data;

  return (
    <ComponentInView id="details_table_header">
      <div className="search_result_header">
        {!hideHeader && (
          <>
            <div className="header_text">
              {disableSearchPlaceholder ? headerName : loading ? '' : headerName}
              <span
                style={{
                  color: '#46a8f6',
                  fontWeight: 400,
                  fontSize: 18,
                  padding: '0 10px'
                }}
              >
                <Tooltip
                  style={{
                    alignItems: 'center',
                    display: 'flex'
                  }}
                  placement="top"
                  title={headerTooltip}
                >
                  {!loading && entityTotalCount >= 0 && (
                    <span>
                      {`(${entityTotalCount} ${
                        entityType === 'ad group' && isCriteo() ? 'line item' : entityType
                      } results)`}
                      {headerTooltip && (
                        <TooltipIcon
                          style={{
                            width: '1em',
                            marginLeft: 6
                          }}
                        />
                      )}
                    </span>
                  )}
                </Tooltip>
              </span>
            </div>
            {subHeaderOverride && (
              <div className="subheader_override">
                {subHeaderOverride(entityType)}
                {showAdditionalText && AUTO_CAMPAIGN_PRODUCTS}
              </div>
            )}
          </>
        )}
        <div className="subheader_section">
          {loading && <div> </div>}
          {!loading && (
            <>
              {!hidePills && (
                <div className="filter_pills">
                  <div className="pill_box">
                    {pills.map((pill: any[], index: number) => (
                      <SinglePill key={index} pill={pill} onPillClick={openFilter} />
                    ))}
                  </div>
                  {isSidebarOpen ? (
                    <div className="open_sidebar" role="button">
                      {buttonText}
                    </div>
                  ) : (
                    <div className="open_sidebar" role="button" onMouseDown={openFilter}>
                      {isCreateSegmentPage
                        ? FILTER_BUTTON_TEXT.CREATE
                        : isEditSegmentPage
                        ? FILTER_BUTTON_TEXT.EDIT
                        : buttonText}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </ComponentInView>
  );
};

const WrappedSearchResultsHeader = withBus('eventBus')(SearchResultHeader);
export default WrappedSearchResultsHeader;
