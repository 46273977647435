/** component to wrap the placeholder text */
import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { checkEmpty } from 'src/utils/checkEmpty';
import PropTypes from 'prop-types';
import './WrapWithPlaceholder.scss';

const StyledComponentWithPlaceHolder: React.FC<{
  valueToCheck: any;
  placeholder: string;
  children: any;
}> = ({ valueToCheck, placeholder, children }) => (
  <FormControl variant="standard">
    <InputLabel
      shrink={false}
      style={{
        WebkitTextSizeAdjust: '100%',
        overflow: 'visible',
        font: 'inherit'
      }}
    >
      {checkEmpty(valueToCheck) && placeholder}
    </InputLabel>
    {children}
  </FormControl>
);

StyledComponentWithPlaceHolder.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element.isRequired]).isRequired,
  placeholder: PropTypes.string.isRequired,
  valueToCheck: PropTypes.any.isRequired
};
export default StyledComponentWithPlaceHolder;
