/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Set } from 'immutable';
import TextField from '@mui/material/TextField';
import _isEmpty from 'lodash/isEmpty';

import { Column, SelectItem, sortByDisplayName } from './TwoColumnSelect';
import './ColumnSelect.scss';

const OneColumnSelect: React.FC<{
  items: Set<SelectItem>;
  onChange: (newItems: Set<SelectItem>) => void;
  AddInputOverride?: React.ComponentType<{
    value: string;
    onChange: (newVal: string) => void;
    onSubmit: (newItem: SelectItem) => void;
  }>;
}> = ({ items, onChange, AddInputOverride }) => {
  const [searchText, setSearchText] = useState('');
  const [addText, setAddText] = useState('');

  return (
    <div className="one-column-select">
      {AddInputOverride ? (
        <AddInputOverride
          value={addText}
          onChange={(newVal: string) => setAddText(newVal)}
          onSubmit={(newItem) => {
            onChange(items.add(newItem));
            setAddText('');
          }}
        />
      ) : (
        <TextField
          variant="standard"
          autoComplete="off"
          value={addText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setAddText(event.target.value);
          }}
          placeholder="Add Item"
          style={{ paddingBottom: 10, width: 220 }}
          onKeyPress={(evt: React.KeyboardEvent<HTMLDivElement>) => {
            if (evt.key === 'Enter') {
              onChange(items.add({ id: addText, displayName: addText }));
              setAddText('');
            }
          }}
        />
      )}
      <TextField
        variant="standard"
        autoComplete="off"
        value={searchText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.target.value.toLowerCase());
        }}
        placeholder="Filter by ID or Name"
        style={{ paddingBottom: 10, width: 220 }}
      />
      <Column
        items={sortByDisplayName(
          items.toArray().filter(({ id, displayName }) => {
            if (_isEmpty(searchText)) {
              return true;
            }

            return (
              id.toString().toLowerCase() === searchText || displayName.toString().toLowerCase().includes(searchText)
            );
          })
        )}
        onSelect={(item) => onChange(items.remove(item))}
      />
    </div>
  );
};

export default OneColumnSelect;
