import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import { Map, Set as ImmSet } from 'immutable';
import { Option } from 'funfix-core';
import moment from 'moment';

import { store } from 'src/main';
import { MoreInfo } from 'src/components/SvgIcons';
import ReduxStore from 'src/types/store/reduxStore';
import { NULL_UUID } from 'src/utils/constants';
import SegmentDropdown from './SegmentDropdown';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import { Segment, AppName } from 'sl-api-connector/types';
import { prop, propEq } from 'src/utils/fp';
import TwoColumnSelect, { SelectItem } from 'src/components/SegmentPowerTools/TwoColumnSelect';
import OneColumnSelect from 'src/components/SegmentPowerTools/OneColumnSelect';
import Suggestions from 'src/components/Suggestions';
import { mkGetBrandNameByBrandId } from 'src/components/SegmentPowerTools/SegmentExport';
import { error } from 'src/utils/mixpanel';
import { SavedSearchPayload, updateSavedSearch } from 'src/store/modules/segments/operations';
import { UnreachableError } from 'src/utils/errors';
import DeleteSavedSearchDialog from 'src/routes/HomePage/SummaryData/DeleteSavedSearchDialog';
import DeleteButton from 'src/components/common/Buttons/DeleteButton';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import NavIconWithTooltip from 'src/components/Navigation/NavIconWithTooltip';
import { SvgElementProps, PlusIcon } from 'src/components/SvgIcons/SvgIcons';
import GenericDialog from 'src/components/common/Dialog/GenericDialog';
import { withProps } from 'src/utils/hoc';
import colors from 'src/utils/colors';

interface EditingSegmentState {
  keyword: ImmSet<string | number>;
  keywordPhrase: ImmSet<string | number>;
  keywordExact: ImmSet<string | number>;
  excludedKeyword: ImmSet<string | number>;
  excludedKeywordExact: ImmSet<string | number>;
  searchTerm: ImmSet<string | number>;
  searchTermFuzzy: ImmSet<string | number>;
  excludedSearchTerm: ImmSet<string | number>;
  brand: ImmSet<string | number>;
  categoryId: ImmSet<string | number>;
  subCategoryId: ImmSet<string | number>;
  excludedCategory: ImmSet<string | number>;
  excludedSubCategory: ImmSet<string | number>;
  minPrice: number | null;
  maxPrice: number | null;
}

const EMPTY_SEGMENT_EDITING_STATE: EditingSegmentState = {
  keyword: ImmSet(),
  keywordPhrase: ImmSet(),
  keywordExact: ImmSet(),
  excludedKeyword: ImmSet(),
  excludedKeywordExact: ImmSet(),
  searchTerm: ImmSet(),
  searchTermFuzzy: ImmSet(),
  excludedSearchTerm: ImmSet(),
  brand: ImmSet(),
  categoryId: ImmSet(),
  subCategoryId: ImmSet(),
  excludedCategory: ImmSet(),
  excludedSubCategory: ImmSet(),
  minPrice: null,
  maxPrice: null
};

const buildEditingStateFromSegment = ({ segment }: Segment): EditingSegmentState => ({
  keyword: ImmSet(segment.k || []).map(prop('i')),
  keywordPhrase: ImmSet(segment.kp || []).map(prop('i')),
  keywordExact: ImmSet(segment.ke || []).map(prop('i')),
  excludedKeyword: ImmSet(segment.xk || []).map(prop('i')),
  excludedKeywordExact:  ImmSet(segment.xke || []).map(prop('i')),
  searchTerm: ImmSet((segment.st || []).map(prop('i'))),
  searchTermFuzzy: ImmSet(segment.stf || []).map(prop('i')),
  excludedSearchTerm: ImmSet((segment.xst || []).map(prop('i'))),
  brand: ImmSet(segment.b || [])
    .map(prop('i'))
    .map((id) => Number.parseInt(id.toString(), 10))
    .filter((id) => !Number.isNaN(id)),
  categoryId: ImmSet((segment.c || []).map(prop('i')))
    .map((id) => Number.parseInt(id.toString(), 10))
    .filter((id) => !Number.isNaN(id)),
  subCategoryId: ImmSet((segment.sc || []).map(prop('i')))
    .map((id) => Number.parseInt(id.toString(), 10))
    .filter((id) => !Number.isNaN(id)),
  excludedCategory: ImmSet(segment.xc || [])
    .map(prop('i'))
    .map((id) => Number.parseInt(id.toString(), 10))
    .filter((id) => !Number.isNaN(id)),
  excludedSubCategory: ImmSet(segment.xc || [])
    .map(prop('i'))
    .map((id) => Number.parseInt(id.toString(), 10))
    .filter((id) => !Number.isNaN(id)),
  minPrice: Option.of(segment.p)
    .flatMap(({ g }) => Option.of(g))
    .map((n) => +n)
    .filter((n) => !Number.isNaN(n))
    .orNull(),
  maxPrice: Option.of(segment.p)
    .flatMap(({ l }) => Option.of(l))
    .map((n) => +n)
    .filter((n) => !Number.isNaN(n))
    .orNull()
});

const mapEditingStateToUpdatePayload = (
  editingState: EditingSegmentState,
  displayName: string,
  id: string,
  brandNameByBrandId: Map<string | number, string | number>,
  parentBrandNameByParentBrandId: Map<string | number, string | number>,
  { app, categories, subCategories }: Pick<ReduxStore, 'app' | 'categories' | 'subCategories'>
): SavedSearchPayload => ({
  termFilters: {
    retailerId: { condition: 'must' },
    keyword: {
      condition: 'should',
      values: editingState.keyword.toArray().map((i) => ({ i }))
    },
    keywordPhrase: {
      condition: 'should',
      values: editingState.keywordPhrase.toArray().map((i) => ({ i }))
    },
    keywordExact: {
      condition: 'should',
      values: editingState.keywordExact.toArray().map((i) => ({ i }))
    },
    excludedKeyword: {
      condition: 'must_not',
      values: editingState.excludedKeyword.toArray().map((i) => ({ i }))
    },
    excludedKeywordExact: {
      condition: 'must_not',
      values: editingState.excludedKeywordExact.toArray().map((i) => ({ i }))
    },
    // Search terms are only valid on Atlas
    searchTerm: {
      condition: 'should',
      values: app.name === AppName.Atlas ? editingState.searchTerm.toArray().map((i) => ({ i })) : []
    },
    searchTermFuzzy: {
      condition: 'should',
      values: app.name === AppName.Atlas ? editingState.searchTermFuzzy.toArray().map((i) => ({ i })) : []
    },
    excludedSearchTerm: {
      condition: 'should',
      values: app.name === AppName.Atlas ? editingState.excludedSearchTerm.toArray().map((i) => ({ i })) : []
    },
    // Search keywords are only on Beacon
    searchKeyword: {
      condition: 'should',
      values: app.name !== AppName.Atlas ? editingState.searchTerm.toArray().map((i) => ({ i })) : []
    },
    searchKeywordFuzzy: {
      condition: 'should',
      values: app.name !== AppName.Atlas ? editingState.searchTermFuzzy.toArray().map((i) => ({ i })) : []
    },
    // `excludedSearchKeyword` doesn't exist.
    // excludedSearchKeyword: {
    //   condition: 'should',
    //   values: app.name !== AppName.Atlas ? editingState.excludedSearchTerm.toArray().map((i) => ({ i })) : []
    // },
    brandId: {
      condition: 'should',
      values: editingState.brand.toArray().map((i) => ({ i, n: Option.of(brandNameByBrandId.get(i)).orNull() }))
    },
    parentBrandId: {
      condition: 'should',
      values: editingState.parentBrand
        .toArray()
        .map((i) => ({ i, n: Option.of(parentBrandNameByParentBrandId.get(i)).orNull() }))
    },
    categoryId: {
      condition: 'should',
      values: editingState.categoryId.toArray().map((i) => ({
        i,
        n: Option.of(categories.find(({ categoryId }) => categoryId.toString() === i.toString()))
          .map(prop('displayName'))
          .orNull()
      }))
    },
    subCategoryId: {
      condition: 'should',
      values: editingState.subCategoryId.toArray().map((i) => ({
        i,
        n: Option.of(subCategories.find(({ subCategoryId }) => subCategoryId.toString() === i.toString()))
          .map(prop('displayName'))
          .orNull()
      }))
    },
    excludedCategoryId: {
      condition: 'should',
      values: editingState.excludedCategory.toArray().map((i) => ({
        i,
        n: Option.of(categories.find(({ categoryId }) => categoryId.toString() === i.toString()))
          .map(prop('displayName'))
          .orNull()
      }))
    },
    excludedSubCategoryId: {
      condition: 'should',
      values: editingState.excludedSubCategory.toArray().map((i) => ({
        i,
        n: Option.of(subCategories.find(({ subCategoryId }) => subCategoryId.toString() === i.toString()))
          .map(prop('displayName'))
          .orNull()
      }))
    }
  },
  rangeFilters: {
    retailPrice: {
      minValue: editingState.minPrice,
      maxValue: editingState.maxPrice
    }
  },
  dn: displayName,
  id
});

const mapBrandAddInputStateToProps = (state: ReduxStore) => _pick(state, ['app']);

const BrandsAddInputInner: React.FC<
  { value: string; onChange: (newVal: string) => void; onSubmit: (selectedItem: SelectItem) => void } & ReturnType<
    typeof mapBrandAddInputStateToProps
  >
> = ({ value, onChange, app }) => (
  <Suggestions
    autocompleteAppNameOverride={app.apiAppName}
    apiUrl={`/api/${app.apiAppName}/AutoCompleteSuggestions?term=`}
    className="sl-form-input"
    hintText="Add Item"
    type="brand"
    style={{
      height: 48,
      marginTop: -11,
      marginBottom: 2
    }}
    textFieldStyle={{ height: '48px' }}
    value={value}
    onChange={onChange}
    // onSelectionChange={({ id, value: displayName }: { id: string; value: string }) => onSubmit({ id, displayName })}
  />
);

const BrandsAddInput = connect(mapBrandAddInputStateToProps)(BrandsAddInputInner);

interface ColumnGroupProps {
  title: string;
  tooltip?: string | null;
  stateKey: Exclude<keyof EditingSegmentState, 'minPrice' | 'maxPrice'>;
  editingState: EditingSegmentState;
  setEditingState: (newEditingState: EditingSegmentState) => void;
}

const IconWithTooltip: React.FC<{
  tooltip: string;
  classes: { [key: string]: any };
  IconComponent?: React.ComponentType<SvgElementProps>;
  onClick?: () => void;
}> = ({ tooltip, classes, IconComponent = MoreInfo, onClick }) => (
  <NavIconWithTooltip
    displayName={tooltip}
    IconComponent={IconComponent}
    iconStyle={{ width: 30, height: 30, paddingBottom: 6 }}
    tooltipProps={{ classes: { tooltip: classes.noMaxWidth } }}
    onClick={onClick}
  />
);

const TooltipIcon = withStyles({ noMaxWidth: { maxWidth: 'none' } })(IconWithTooltip);

const BulkUploadDialog: React.FC<{
  onSubmit: (selectedItems: string[]) => void;
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose, onSubmit }) => {
  const [content, setContent] = useState('');

  return (
    <GenericDialog title="Bulk-Upload Items" open={open} onClose={onClose}>
      <p style={{ marginLeft: 20, marginRight: 20 }}>
        Paste sub-categories to include in this segment here, one per line. You can specify either names or IDs. If a
        match is not found, you will be alerted.
      </p>

      <textarea
        value={content}
        onChange={(evt) => setContent(evt.target.value)}
        style={{
          height: 300,
          width: 'calc(100% - 20px)',
          marginLeft: 10,
          marginRight: 10,
          marginTop: 20,
          marginBottom: 20
        }}
      />

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <LargeMuiButton
          label="Submit"
          disabled={!content}
          onClick={() => {
            onSubmit(content.split('\n').map((item) => item.trim()));
            onClose();
            setContent('');
          }}
          style={{ marginRight: 10 }}
        />
        <LargeMuiButton label="Cancel" secondary onClick={onClose} />
      </div>
    </GenericDialog>
  );
};

const OneColumnGroup: React.FC<ColumnGroupProps> = ({ title, tooltip, stateKey, editingState, setEditingState }) => (
  <div className="column-group" style={{ flex: 1 }}>
    <h3>
      {title}
      {tooltip ? <TooltipIcon tooltip={tooltip} /> : null}
    </h3>
    <OneColumnSelect
      items={editingState[stateKey].map((item) => ({ id: item, displayName: item }))}
      onChange={(newSelected: ImmSet<SelectItem>) =>
        setEditingState({ ...editingState, [stateKey]: newSelected.map(prop('id')) })
      }
    />
  </div>
);

const TwoColumnGroup: React.FC<
  ColumnGroupProps & { corpus: Map<string | number, SelectItem>; handleBulkUpload?: (uploadedItems: string[]) => void }
> = ({ title, tooltip, stateKey, editingState, setEditingState, corpus, handleBulkUpload }) => {
  const [showBulkUploadDialog, setShowBulkUploadDialog] = useState(false);

  return (
    <div className="column-group" style={{ flex: 2 }}>
      {handleBulkUpload && showBulkUploadDialog ? (
        <BulkUploadDialog
          open={showBulkUploadDialog}
          onClose={() => setShowBulkUploadDialog(false)}
          onSubmit={handleBulkUpload}
        />
      ) : null}
      <h3>
        {title}
        {tooltip ? <TooltipIcon tooltip={tooltip} /> : null}
        {handleBulkUpload && !showBulkUploadDialog ? (
          <TooltipIcon
            IconComponent={withProps({
              style: {
                stroke: colors.darkBlue,
                cursor: 'pointer',
                width: 28,
                height: 28
              } as React.CSSProperties
            })(PlusIcon)}
            tooltip="Bulk-select items"
            onClick={() => setShowBulkUploadDialog(true)}
          />
        ) : null}
      </h3>
      <TwoColumnSelect
        corpus={corpus}
        selected={editingState[stateKey]}
        onChange={(newSelected: ImmSet<string | number>) =>
          setEditingState({ ...editingState, [stateKey]: newSelected })
        }
      />
    </div>
  );
};

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['segments', 'categories', 'subCategories', 'app', 'retailer']);

const SegmentEdit: React.FC<{} & ReturnType<typeof mapStateToProps>> = ({
  segments,
  categories,
  subCategories,
  app,
  retailer
}) => {
  const [selectedSegmentId, setSelectedSegmentId] = useState<string>(NULL_UUID);
  const [displayName, setDisplayName] = useState<string>('');
  const [editingState, setEditingState] = useState(EMPTY_SEGMENT_EDITING_STATE);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [successText, setSuccessText] = useState<string | null>(null);
  const [deletingSavedSearch, setDeletingSavedSearch] = useState(false);
  // mapping of brand id to brand name
  const [brandNameByBrandId, setBrandNameByBrandId] = useState<Map<string | number, string | number>>(Map());

  const categoryCorpus: Map<string | number, SelectItem> = useMemo((): Map<string | number, SelectItem> => {
    if (!categories) {
      return Map();
    }

    return Map(
      (categories as { categoryId: number; categoryName: string }[]).map(({ categoryId, categoryName }) => [
        categoryId,
        { id: typeof categoryId === 'number' ? categoryId : Number.parseInt(categoryId, 10), displayName: categoryName }
      ])
    );
  }, [categories]);

  const subCategoryCorpus: Map<string | number, SelectItem> = useMemo((): Map<string | number, SelectItem> => {
    if (!subCategories) {
      return Map();
    }

    return Map(
      (subCategories as { subCategoryId: number; displayName: string }[]).map(
        ({ subCategoryId, displayName: subCategoryName }) => [
          subCategoryId,
          {
            id: typeof subCategoryId === 'number' ? subCategoryId : Number.parseInt(subCategoryId, 10),
            displayName: subCategoryName
          }
        ]
      )
    );
  }, [subCategories]);

  const normalizedSubCategories = useMemo(
    () =>
      [...subCategoryCorpus.values()].map((item) => ({
        id: item.id,
        displayName: item.displayName
          .toString()
          .toLowerCase()
          .replace(/[\W_]+/g, '')
      })),
    [subCategoryCorpus]
  );

  if (!segments.savedSearchesById) {
    return <GridLoading />;
  }

  const isNewSegment = selectedSegmentId === NULL_UUID;
  const searchFieldName = app.name === AppName.Atlas ? 'Search Term' : 'Search Keyword';
  const selectedSegment = Option.of(segments.savedSearchesById.get(selectedSegmentId) as Segment | undefined).orNull();

  const handleSaveSegmentClick = async () => {
    setErrorText(null);

    if (
      Object.entries(editingState)
        .filter(([key, _val]) => !['minPrice', 'maxPrice'].includes(key))
        .map(([_key, val]) => val)
        .every(propEq('size', 0))
    ) {
      setErrorText('You cannot create an empty segment');
      return;
    }

    const payload = mapEditingStateToUpdatePayload(
      editingState,
      isNewSegment ? displayName : selectedSegment!.displayName,
      selectedSegmentId,
      brandNameByBrandId,
      { app, categories, subCategories }
    );

    if (isNewSegment) {
      // Validate the current segment state
      if (_isEmpty(displayName)) {
        setErrorText('You must enter a name for this segment');
        return;
      } else if (
        [segments.mySegments, segments.teamSegments].find((segmentsList) =>
          segmentsList.find(propEq('displayName', displayName))
        )
      ) {
        setErrorText('A segment with the name you provided already exists; please choose a different name');
        return;
      }
    }

    try {
      setIsSubmitting(true);

      const { data: createdSegmentId, status } = await store.dispatch(
        updateSavedSearch('segment', payload, selectedSegmentId)
      );
      if (status !== 200 || typeof createdSegmentId !== 'string' || _isEmpty(createdSegmentId)) {
        setErrorText('Error while saving segment; received bad response from server');
        return;
      }

      if (isNewSegment) {
        const createdSegment = (store.getState() as ReduxStore).segments.savedSearchesById!.get(createdSegmentId);
        if (!createdSegment) {
          error('Newly created segment not found in Redux');
        } else if (createdSegment.type !== 'segment') {
          throw new UnreachableError();
        } else {
          // Switch the editing state to the newly created segment
          setEditingState(buildEditingStateFromSegment(createdSegment));
          setSelectedSegmentId(createdSegmentId);
        }
      }

      setSuccessText('Successfully saved segment');
      setTimeout(() => setSuccessText(null), 2000);
    } catch (err) {
      error('Error while saving segment', { err });
      setErrorText('Error while saving segment');
    } finally {
      setIsSubmitting(false);
    }
  };

  /**
   * Sets the currently editing segment to match the newly selected segment id.  This resets the current state of the
   * segment editor and triggers the brandid corpus to be updated with any brands included in the new segment.
   *
   * Passing in `NULL_UUID` as the segment will switch the editor to switch to create new segment mode.
   */
  const setEditingSegmentState = async (newSegmentId: string) => {
    if (!segments.savedSearchesById) {
      return;
    }

    setSelectedSegmentId(newSegmentId);

    if (newSegmentId === NULL_UUID) {
      setEditingState(EMPTY_SEGMENT_EDITING_STATE);
      return;
    }

    const newEditingState = buildEditingStateFromSegment(segments.savedSearchesById.get(newSegmentId)! as Segment);
    setEditingState(newEditingState);

    // Fetch brand metadata for any brands that we're missing in the corpus
    const getBrandNameByBrandId = mkGetBrandNameByBrandId({ app, retailer });
    const brandIdsWithMissingMetadata = newEditingState.brand.filter((brandId) =>
      _isNil(brandNameByBrandId.get(brandId))
    );
    const brandMetadataPairs = await Promise.all(
      brandIdsWithMissingMetadata
        .toArray()
        .map(
          async (brandId): Promise<[string | number, string | number]> => [
            brandId,
            await getBrandNameByBrandId(brandId)
          ]
        )
    );
    const addedBrandMetadata: Map<string | number, string | number> = Map(brandMetadataPairs);
    setBrandNameByBrandId(brandNameByBrandId.merge(addedBrandMetadata));
  };

  const duplicateCurrentSegment = () => {
    setSelectedSegmentId(NULL_UUID);
    setDisplayName(`${segments.savedSearchesById!.get(selectedSegmentId)!.displayName} (Copy)`);
  };

  return (
    <div className="segment-edit">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 18,
          height: 187,
          justifyContent: 'center',
          maxWidth: 1200,
          alignSelf: 'center'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', width: 350, flex: 1 }}>
          <h2 style={{ marginTop: 0 }}>Edit Saved Searches</h2>
          <SegmentDropdown value={selectedSegmentId} onChange={setEditingSegmentState} />

          {isNewSegment ? (
            <TextField
              variant="standard"
              label="Segment Name"
              autoComplete="off"
              value={displayName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDisplayName(event.target.value)}
            />
          ) : null}

          {selectedSegment ? (
            <>
              <h2 style={{ marginBottom: 7, marginTop: 20 }}>Segment Information</h2>
              <div style={{ paddingBottom: 4 }}>Owner: {selectedSegment.segment.owner.email}</div>
              <div>Last Edited: {moment(selectedSegment.lastUpdatedTime).format('YYYY-MM-DD')}</div>
            </>
          ) : null}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 350, flex: 1 }}>
          <LargeMuiButton
            style={{ marginBottom: 14 }}
            label={isSubmitting ? 'Saving...' : isNewSegment ? 'Create Segment' : 'Update Segment'}
            disabled={isSubmitting}
            onClick={handleSaveSegmentClick}
          />

          {!isNewSegment ? (
            <LargeMuiButton style={{ marginBottom: 14 }} label="Duplicate Segment" onClick={duplicateCurrentSegment} />
          ) : null}

          {errorText ? <span className="error-text">{errorText}</span> : null}
          {successText ? <span className="success-text">{successText}</span> : null}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 350, flex: 1 }}>
          {!isNewSegment ? (
            <>
              <div style={{ width: 210 }}>
                <DeleteButton
                  style={{ marginBottom: 11 }}
                  label="Delete Segment"
                  onClick={() => setDeletingSavedSearch(true)}
                />
              </div>
              <DeleteSavedSearchDialog
                isOpen={deletingSavedSearch}
                setIsClosed={() => setDeletingSavedSearch(false)}
                savedSearchId={selectedSegmentId}
                onDelete={() => {
                  setSuccessText('Successfully deleted segment');
                  setTimeout(() => setSuccessText(null), 2000);
                  setSelectedSegmentId(NULL_UUID);
                  setEditingState(EMPTY_SEGMENT_EDITING_STATE);
                }}
              />
            </>
          ) : null}

          <LargeMuiButton
            style={{ marginBottom: 14, marginTop: 4 }}
            label="Reset Changes"
            onClick={() => setEditingSegmentState(selectedSegmentId)}
          />
        </div>

        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <p style={{ margin: 0 }}>Click rows to add/remove them from the segment being edited. </p>
          <p>Click the &quot;Reset Changes&quot; button above to clear all edits and reset to the initial state.</p>
        </div>
      </div>

      <div className="segment-edit-columns">
        <TwoColumnGroup
          title="Category"
          corpus={categoryCorpus}
          editingState={editingState}
          setEditingState={setEditingState}
          stateKey="categoryId"
        />

        <TwoColumnGroup
          title="Subcategory"
          corpus={subCategoryCorpus}
          editingState={editingState}
          setEditingState={setEditingState}
          stateKey="subCategoryId"
          handleBulkUpload={(uploadedItems: string[]) => {
            // Not the most efficient but it shouldn't matter; never going to be more than a few thousand in both the
            // corpus and the uploaded set.

            const normalizedUploadedItems = uploadedItems.map((item) => item.toLowerCase().replace(/[\W_]+/g, ''));

            const missingItems = new Set();
            let matchingIds = ImmSet();

            normalizedUploadedItems.forEach((item) => {
              // eslint-disable-next-line no-shadow, eqeqeq
              const match = normalizedSubCategories.find(({ id, displayName }) => id == item || displayName === item);
              if (!match) {
                missingItems.add(item);
              } else {
                matchingIds = matchingIds.add(match.id);
              }
            });

            if (missingItems.size > 0) {
              // eslint-disable-next-line no-alert
              alert(
                `The following subcategories had no match: \n\n${[...missingItems].join(
                  '\n'
                )}\n\nCheck their spellings or price IDs directly`
              );
            }

            setEditingState({ ...editingState, subCategoryId: editingState.subCategoryId.merge(matchingIds) });
          }}
        />

        <OneColumnGroup
          title="Keyword"
          editingState={editingState}
          setEditingState={setEditingState}
          stateKey="keyword"
          tooltip="Keyword matches on many fields including title, subtitle, brand, model number, ISBN, Retailer SKU, and Stackline SKU"
        />

        <OneColumnGroup
          title="Excluded Keyword"
          editingState={editingState}
          setEditingState={setEditingState}
          stateKey="excludedKeyword"
          tooltip="Excluded Keyword filters out based on many fields including title, subtitle, brand, model number, ISBN, Retailer SKU, and Stackline SKU"
        />

        <OneColumnGroup
          title={searchFieldName}
          editingState={editingState}
          setEditingState={setEditingState}
          stateKey="searchTerm"
        />

        <OneColumnGroup
          title={`Excluded ${searchFieldName}`}
          editingState={editingState}
          setEditingState={setEditingState}
          stateKey="excludedSearchTerm"
        />

        <OneColumnGroup
          title={`Fuzzy ${searchFieldName}`}
          editingState={editingState}
          setEditingState={setEditingState}
          tooltip={`Same as ${searchFieldName} but results are returned using fuzzy matching`}
          stateKey="searchTermFuzzy"
        />

        <div className="column-group" style={{ flex: 1 }}>
          <h3>Brand</h3>
          <OneColumnSelect
            items={editingState.brand.map((brandId) => ({
              id: brandId,
              displayName: Option.of(brandNameByBrandId.get(brandId)).getOrElseL(() => `Brand ID ${brandId}`)
            }))}
            onChange={(newSelected: ImmSet<SelectItem>) => {
              if (newSelected.count() > editingState.brand.count()) {
                const diff = newSelected.filter(({ id }) => !editingState.brand.has(id));
                if (diff.count() > 1) {
                  error(`More than one element was added to the selected brands set; ${diff.count()} items added`);
                }
                const newBrandItem: SelectItem = diff.first();
                setBrandNameByBrandId(brandNameByBrandId.set(newBrandItem.id, newBrandItem.displayName));
              }

              setEditingState({ ...editingState, brand: newSelected.map(prop('id')) });
            }}
            AddInputOverride={BrandsAddInput}
          />
        </div>

        <TwoColumnGroup
          title="Excluded Category"
          corpus={categoryCorpus}
          editingState={editingState}
          setEditingState={setEditingState}
          stateKey="excludedCategory"
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(SegmentEdit);
