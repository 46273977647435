import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withBus } from 'react-bus';
import Modal from '@mui/material/Modal';
import { store } from 'src/main';

import { useBus } from 'src/utils/Hooks';
import { popupMapping } from './Popups';
import { withPush } from 'src/utils/hoc';
import * as AdCampaignBuilderOperations from 'src/store/modules/adManager/adCampaignBuilder/operations';

const backdropProps = {
  style: {
    backgroundColor: '#3c485924' // use this instead of colors.darkBlue, because this is a transparent color
  }
};

const style = {
  innerContainer: {
    width: 'fit-content',
    outline: 'none',
    position: 'absolute',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  }
};
const mapDispatchToProps = {
  resetAdCampaignBuilderReduxState: AdCampaignBuilderOperations.resetAdCampaignBuilderReduxState
};

const GlobalPopup = withRouter(
  connect(() => {}, mapDispatchToProps)(
    withPush(
      withBus('eventBus')(({ push, eventBus }) => {
        const [open, setOpen] = React.useState(false);
        const [Inner, setInner] = React.useState(<div />);

        const handleOpen = (name) => {
          const innerWidget = popupMapping[name] || <div />;
          setInner(innerWidget);
          setOpen(true);
        };

        const handleClose = () => {
          setOpen(false);
        };

        const openAdCampaignBuilder = async (url) => {
          store
            .dispatch(AdCampaignBuilderOperations.resetAdCampaignBuilderReduxState())
            .then((_res) => {
              push(url);
            })
            .catch((err) => {
              console.error(err);
              // eslint-disable-next-line no-alert
              alert('Error clearing the campaign builder redux state.');
            });
        };

        useBus(eventBus, 'openAdCampaignBuilder', openAdCampaignBuilder);
        useBus(eventBus, 'openGlobalPopup', handleOpen);

        return (
          // The reason I use Modal instead of Dialog here is because Material-UI warps Dialog with multi layers, and it is hard to style it
          <Modal open={open} onClose={handleClose} disableAutoFocus BackdropProps={backdropProps}>
            <div style={style.innerContainer}>{Inner}</div>
          </Modal>
        );
      })
    )
  )
);

GlobalPopup.propTypes = {
  resetAdCampaignBuilderReduxState: PropTypes.func.isRequired
};

export default GlobalPopup;
