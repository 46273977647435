import React, { useMemo } from 'react';
import BeaconChartWithLegend, {
  BeaconChartWithLegendProps
} from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import SplineChart, {
  SplineChartOptionsOverride,
  SplineChartProps
} from 'src/components/BeaconRedesignComponents/SplineChart/SplineChart';
import { useAppSelector } from 'src/utils/Hooks';
import SplineChartLoading, {
  SplineChartLoadingProps
} from 'src/components/BeaconRedesignComponents/SplineChartLoading/SplineChartLoading';
import { getAppName } from 'src/utils/app';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { useMetricByWeekId, UseMetricByWeekIdArgs } from 'src/serverProxy/useMetricByWeekId';
import { getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import convertLineChartSeriesToDelimitedData, {
  Series
} from 'src/components/Charts/GenericChart/SeriesConverters/lineChart';
import saveAs from 'file-saver';

export interface BeaconMetricSplineChartProps
  extends Pick<
      BeaconChartWithLegendProps,
      'singleDropdownProps' | 'buildDropdownMenuOptions' | 'dropdownOptionHandler'
    >,
    UseMetricByWeekIdArgs {
  indexName: string;
  fieldName: string;
  title?: string;
  /**
   * Hides the title and category subtitle if false
   */
  showTitleSummary?: boolean;
  /**
   * Optional overrides to the default Highcharts chart options
   */
  chartOptionsOverride?: SplineChartOptionsOverride;
  /**
   * Used for indexing overrides for the index field
   */
  entityType?: string;
  splineChartLoadingProps?: SplineChartLoadingProps;
  beaconChartWithLegendOverride?: (
    props: Omit<BeaconChartWithLegendProps, 'children'>,
    metricByWeekIdValues: ReturnType<typeof useMetricByWeekId>
  ) => Omit<BeaconChartWithLegendProps, 'children'>;
  splineChartProps?: Partial<SplineChartProps>;
}

/**
 * Given an index and a field name, displays a weekly spline chart
 */
const BeaconMetricSplineChart = ({
  fieldName,
  indexName,
  title,
  showTitleSummary = true,
  chartOptionsOverride,
  entityType,
  primaryAggregationBuilder,
  secondaryAggregationBuilder,
  splineChartLoadingProps,
  metricTypeDisplay,
  aggregationFunctionForTotal,
  requestBuilder,
  comparisonFieldName,
  comparisonIndexName,
  isComparisonDataCustom,
  buildDropdownMenuOptions,
  dropdownOptionHandler,
  beaconChartWithLegendOverride,
  splineChartProps,
  ...chartWithLegendProps
}: BeaconMetricSplineChartProps) => {
  const metricByWeekIdValues = useMetricByWeekId({
    fieldName,
    indexName,
    entityType,
    primaryAggregationBuilder,
    secondaryAggregationBuilder,
    metricTypeDisplay,
    aggregationFunctionForTotal,
    requestBuilder,
    comparisonFieldName,
    comparisonIndexName,
    isComparisonDataCustom
  });
  const {
    primaryData,
    secondaryData,
    isLoading,
    formattedPrimaryTotal,
    formattedSecondaryTotal,
    formattedPercentChange,
    percentChange,
    formattedLastPrimaryValue,
    formattedLastSecondaryValue,
    percentChangeLastValues,
    formattedPercentChangeLastValues
  } = metricByWeekIdValues;

  const primaryLegendDisplayName = useAppSelector((state) => state.mainTimePeriod.displayName);
  const comparisonLegendDisplayName = useAppSelector((state) => state.comparisonTimePeriod.displayName);

  const comparisonTimePeriod = useAppSelector((state) => state.comparisonTimePeriod);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);

  const field = useMemo(
    () => INDEX_FIELDS.getField(getAppName(), indexName, fieldName, entityType),
    [indexName, fieldName, entityType]
  );

  const useLatestWeek = field && field.timePeriodAggregationFunctionType === 'lastValue';

  const mainMetric = useLatestWeek ? formattedLastPrimaryValue : formattedPrimaryTotal;
  const secondaryMetric = useLatestWeek ? formattedLastSecondaryValue : formattedSecondaryTotal;

  const rawChange = useLatestWeek ? percentChangeLastValues : percentChange;
  const change = useLatestWeek ? formattedPercentChangeLastValues : formattedPercentChange;

  const titleToUse = title || field.displayName;

  const convertSeriesToDelimitedData = () => {
    try {
      // Convert the chart series to delimited data (may return a promise)
      const data = convertLineChartSeriesToDelimitedData(
        [
          {
            type: 'spline',
            name: mainTimePeriod.displayName,
            data: primaryData.map((row) => ({
              x: row[0],
              y: row[1],
              marker: {
                enabled: true
              }
            }))
          } as unknown as Series,
          {
            type: 'spline',
            name: comparisonTimePeriod.displayName,
            data: secondaryData.map((row) => ({
              x: row[0],
              y: row[1],
              marker: {
                enabled: true
              }
            }))
          } as unknown as Series
        ],
        comparisonTimePeriod.id === 'prior-period',
        comparisonTimePeriod,
        mainTimePeriod
      );

      // Create a blob with the data and save it as a CSV file
      const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, `${titleToUse}.csv`);
    } catch (e) {
      console.error('error: ', e);
    }

    return Promise.resolve();
  };

  const getBeaconChartWithLegendProps = () => {
    const defaultProps: Omit<BeaconChartWithLegendProps, 'children'> = {
      title: titleToUse,
      primaryLegendProps: {
        displayName: primaryLegendDisplayName,
        metric: mainMetric,
        change,
        direction: getDirection(rawChange)
      },
      comparisonLegendProps: {
        displayName: comparisonLegendDisplayName,
        metric: secondaryMetric,
        direction: getDirection(rawChange)
      },
      loading: isLoading,
      loadingComponent: <SplineChartLoading {...splineChartLoadingProps} />,
      showTitleSummary,
      buildDropdownMenuOptions,
      dropdownOptionHandler,
      convertSeriesToDelimitedData,
      ...chartWithLegendProps
    };

    if (beaconChartWithLegendOverride) {
      return beaconChartWithLegendOverride(defaultProps, metricByWeekIdValues);
    }

    return defaultProps;
  };

  return (
    <BeaconChartWithLegend {...getBeaconChartWithLegendProps()}>
      <SplineChart
        primaryData={primaryData}
        secondaryData={secondaryData}
        metricType={metricTypeDisplay || field.metricType}
        chartOptionsOverride={chartOptionsOverride}
        {...splineChartProps}
      />
    </BeaconChartWithLegend>
  );
};

export default BeaconMetricSplineChart;
