import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useAppSelector, useUpdateMainTimePeriod, PartialTimePeriod } from 'src/utils/Hooks';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { withRouter } from 'react-router';
import { History } from 'history';
import { CalendarDisplay } from 'src/components/common/DatePicker';
import moment from 'moment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ChevronIcon } from 'src/components/SvgIcons';
import Divider from '@mui/material/Divider';

interface CustomMainTimePeriodDropDownProps {
  history: History;
  /**
   * Will be called with the ID of the selected option
   * when one is clicked. It should return true if it should
   * open the calendar picker for a custom date.
   */
  isCustomDate: (periodId: string) => boolean;
  /**
   * List of time periods to display in the dropdown
   */
  timePeriods: PartialTimePeriod[];
  /**
   * Index at which to show a divider between dropdown items
   */
  dividerIndex?: number;

  /**
   * Minimum available date on the calendar picker
   */
  minDate: Date;
  /**
   * Maximum available date on the calendar picker
   */
  maxDate: Date;
}

/**
 * Should be used when the normal time period dropdown in the
 * header should be replaced with something else.
 */
export const CustomMainTimePeriodDropdownInner = ({
  history,
  isCustomDate,
  timePeriods,
  dividerIndex,
  minDate,
  maxDate
}: CustomMainTimePeriodDropDownProps) => {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const [selectedPeriodId, setSelectedPeriodId] = useState(mainTimePeriod.id);
  const updateMainTimePeriod = useUpdateMainTimePeriod(history, timePeriods);

  useEffect(() => {
    setSelectedPeriodId(mainTimePeriod.id);
  }, [mainTimePeriod.id]);

  const selectedTimePeriod = useMemo(
    () => timePeriods.find((period) => period.id === selectedPeriodId),
    [selectedPeriodId, timePeriods]
  );

  const handleDateRangeChange = (periodId: string) => {
    setSelectedPeriodId(periodId);

    if (isCustomDate(periodId)) {
      return;
    }

    updateMainTimePeriod(periodId);
  };

  const handleCustomDateRangeChange = (customDates: { startDate: string | null; endDate: string | null }) => {
    updateMainTimePeriod(selectedPeriodId, customDates);
    setCalendarOpen(false);
  };

  const handleMenuItemClick = (periodId: string) => {
    if (isCustomDate(periodId)) {
      setCalendarOpen(true);
    }
  };

  const renderDropdownValue = useCallback(() => {
    if (!selectedPeriodId) {
      return '';
    }
    return isCustomDate(selectedPeriodId) ? mainTimePeriod.displayName : mainTimePeriod.shortDisplayName;
  }, [selectedPeriodId, mainTimePeriod.displayName, mainTimePeriod.shortDisplayName, isCustomDate]);

  return (
    <>
      <div style={{ display: 'inline-block' }}>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            }
          }}
          variant="standard"
          renderValue={renderDropdownValue}
          value={mainTimePeriod.id || ''}
          onChange={({ target: { value } }) => handleDateRangeChange(value)}
          displayEmpty
          input={<OutlinedInput labelWidth={0} id="outlined-age-simple" />}
          IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
        >
          {timePeriods.slice(0, dividerIndex !== undefined ? dividerIndex : undefined).map((timePeriod) => (
            <MenuItem key={timePeriod.id} value={timePeriod.id} onClick={() => handleMenuItemClick(timePeriod.id)}>
              {timePeriod.shortDisplayName}
            </MenuItem>
          ))}
          {dividerIndex !== undefined && <Divider />}
          {dividerIndex !== undefined &&
            timePeriods.slice(dividerIndex).map((timePeriod) => (
              <MenuItem key={timePeriod.id} value={timePeriod.id} onClick={() => handleMenuItemClick(timePeriod.id)}>
                {timePeriod.shortDisplayName}
              </MenuItem>
            ))}
        </Select>
      </div>
      {calendarOpen && (
        <CalendarDisplay
          handleCustomDateRangeChange={handleCustomDateRangeChange}
          handleOutsideClick={() => setCalendarOpen(false)}
          maxDate={moment(maxDate)}
          minDate={moment(minDate)}
          onClickDateHandler={
            selectedTimePeriod && selectedTimePeriod.onClickDateHandler
              ? (selectedDate) => selectedTimePeriod.onClickDateHandler(selectedDate, { maxDate, minDate })
              : undefined
          }
        />
      )}
    </>
  );
};

export default withRouter(CustomMainTimePeriodDropdownInner) as unknown as React.FC<
  Omit<CustomMainTimePeriodDropDownProps, 'history'>
>;
