import types from './types';

function requestEntity() {
  return {
    type: types.REQUEST_ENTITY
  };
}

function receiveEntity(statePropertyName, statePropertyValue) {
  return {
    type: types.RECEIVE_ENTITY,
    statePropertyName,
    statePropertyValue,
    receivedAt: Date.now()
  };
}

function removeEntity(statePropertyName) {
  return {
    type: types.RECEIVE_ENTITY,
    statePropertyName,
    receivedAt: Date.now()
  };
}

function clearEntity() {
  return {
    type: types.CLEAR_ENTITY
  };
}

function updateSBAProfile(statePropertyName, sbaProfile) {
  return {
    type: types.UPDATE_SBA_PROFILE,
    statePropertyName,
    sbaProfile
  };
}

export default {
  clearEntity,
  receiveEntity,
  removeEntity,
  requestEntity,
  updateSBAProfile
};
