import React from 'react';
import PropTypes from 'prop-types';
// import AtlasSpace from '!svg-react-loader!./SignInAtlasSpace.svg'; // eslint-disable-line
// import AtlasMountain from '!svg-react-loader!./MountainsAtlas.svg'; // eslint-disable-line
import StarrySky from 'src/components/SignIn/StarrySky/StarrySky';
import Snackbar from '@mui/material/Snackbar';
import ErrorIcon from '@mui/icons-material/Error';
import anime from 'animejs';

import shakeTranslations from '../shakeTranslations';
import './AtlasSignInContainer.scss';

class AtlasSignInContainer extends React.Component {
  static defaultProps = {
    notificationMSG: ''
  };

  static propTypes = {
    notificationMSG: PropTypes.string,
    appName: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
  };

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    const showZoomInAnimation = document.querySelector('.signin-form');
    if (showZoomInAnimation) {
      this.zoomIn();
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
    anime.remove(`.atlas-signin-form-container form, #Particles circle, #Particles rect`);
  }

  shakeLoginBox = () => {
    const shakeky = 2;
    anime({
      targets: `.atlas-signin-form-container form`,
      easing: 'easeInOutSine',
      duration: 550,
      translateX: shakeTranslations(shakeky)
    });
  };

  zoomIn = () => {
    anime({
      targets: '.cross3',
      top: [
        {
          value: ['163vh', '-37vh'],
          duration: 1430,
          easing: 'easeOutCubic'
        }
      ],
      right: [
        {
          value: ['-401vh', '-101vh'],
          duration: 1430,
          easing: 'easeOutCubic'
        }
      ]
    });
    anime({
      targets: '.cross7',
      bottom: [
        {
          value: ['181vh', '-19vh'],
          duration: 1430,
          easing: 'easeOutCubic'
        }
      ],
      left: [
        {
          value: ['-402vh', '-102vh'],
          duration: 1430,
          easing: 'easeOutCubic'
        }
      ]
    });
    anime({
      targets: '.cross1,.cross2,.cross4,.cross5,.cross6,.cross8,.reg_star',
      opacity: [
        {
          value: ['0', '1'],
          duration: 3430,
          delay: 1000,
          easing: 'easeOutCubic'
        }
      ]
    });
  };

  render() {
    const { children, notificationMSG, appName } = this.props;
    return (
      <div className="atlas-signin-container">
        <div className="atlas-sence">
          <StarrySky appName={appName} />
        </div>
        <div className="atlas-signin-form-container">{children}</div>
        <Snackbar
          style={{ position: 'fixed', marginBottom: '20px' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={!!notificationMSG}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={
            <span
              id="message-id"
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <ErrorIcon style={{ marginRight: '10px' }} />
              {`  ${notificationMSG}`}
            </span>
          }
          TransitionProps={{
            onEnter: this.shakeLoginBox
          }}
        />
      </div>
    );
  }
}

export default AtlasSignInContainer;
