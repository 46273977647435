import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import LogRocket from 'logrocket';
import moment from 'moment';
import OverlayScrollbars from 'overlayscrollbars/js/OverlayScrollbars';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ErrorBoundary from 'src/components/common/Errors';
import Loading from 'src/components/common/Loading';
import HomeAuthLayout from 'src/components/layouts/HomeAuthLayout';
import AdHomeAuthLayout from 'src/components/layouts/AdHomeAuthLayout';
import NonAuthLayout from 'src/components/layouts/NonAuthLayout';
import RouteTable from 'src/routes/routeTable';
import * as appOperations from 'src/store/modules/app/operations';
import { authOperations } from 'src/store/modules/auth';
import { configureAxios } from 'src/utils/axios';
import {
  initializeMixpanel,
  isMixpanelInitialized,
  setMixpanelUserIdentity,
  trackLogOut,
  trackRouteChange
} from 'src/utils/mixpanel';
import { initializeSentry, isSentryInitialized, setSentryUserIdentity } from 'src/utils/sentry';
import { registerCustomNumeralFormatters } from 'src/utils/stringFormatting';
import './App.scss';
import { churnZeroConnect } from 'src/utils/churnZero';
import { isEmpty } from 'lodash';
import { AppName } from 'sl-api-connector/types';
import { fetchSecondaryRetailerIds } from 'src/store/modules/user/operations';
import { fetchParentPlatforms } from 'src/store/modules/parentPlatform/operations';
import { isBulkChangeAllowlisted, isDrive, shouldShowCriteo } from 'src/utils/app';
import 'simplebar-react/dist/simplebar.min.css';

let initializedLogRocket = false;
let initializedLogRocketWith = '';

const shouldAutoScroll = (preLocation, currLocation) => {
  const preParams = new URLSearchParams(preLocation.search);
  const currParams = new URLSearchParams(currLocation.search);

  const prePath = preLocation.pathname;
  const preTab = preParams.get('tab');
  const preSubtab = preParams.get('subtab');

  const currPath = currLocation.pathname;
  const currTab = currParams.get('tab');
  const currSubtab = currParams.get('subtab');

  return prePath !== currPath || preTab !== currTab || preSubtab !== currSubtab;
};

class App extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    parentPlatform: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    clearReduxState: PropTypes.func.isRequired,
    signOutSuccess: PropTypes.func.isRequired,
    fetchAppSecondaryRetailerIds: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const {
      app: { targetUrl }
    } = props;
    configureAxios(`https://${targetUrl}`, this.handleServerError);
    registerCustomNumeralFormatters();
    this.state = { isInitializing: true };
  }

  componentDidMount() {
    this.initialize(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!_isEqual(this.props, nextProps)) {
      this.initialize(this.props, nextProps);
    }
  }

  componentDidUpdate(prevProps) {
    this.autoScroll(prevProps);
  }

  initialize(props, nextProps) {
    const {
      app,
      location,
      user,
      fetchConfig,
      retailer,
      parentPlatform,
      fetchAppSecondaryRetailerIds,
      fetchAppParentPlatforms
    } = nextProps || props;
    const { session, config: userConfig } = user;
    const { config } = app;

    // Redirect to bulk change for white listed users
    if (this.props.app.name === AppName.Advertising) {
      const isBulkChange = window.location.href.includes('-bulkupdate.stackline.com');
      if (!isBulkChange) {
        const userEmail = _get(userConfig, ['profile', 'email'], null);
        if (userEmail) {
          const isAllowedForBulkChange = isBulkChangeAllowlisted(userEmail);
          if (isAllowedForBulkChange) {
            window.location.href = 'https://ad-bulkupdate.stackline.com/';
          }
        }
      }
    }

    document.title = `Stackline | ${app.displayName}`;
    let isInitializing = false;

    if (!user || !config || !userConfig) {
      isInitializing = true;
      fetchConfig().then(() => this.setState({ isInitializing: false }));
    }

    if (
      shouldShowCriteo() &&
      isDrive &&
      parentPlatform &&
      (!parentPlatform.availablePlatforms ||
        !Array.isArray(parentPlatform.availablePlatforms) ||
        parentPlatform.availablePlatforms.length === 0) &&
      !parentPlatform.parentPlatformsLoading &&
      user &&
      user.session &&
      !user.config.secondaryRetailerIds
    ) {
      fetchAppParentPlatforms();
    }

    // Currently only Drive fetches secondary retailer IDs, but user must be authenticated
    // before we can make the fetch
    if (user && user.session && !user.config.secondaryRetailerIds && !user.config.secondaryRetailersLoading) {
      fetchAppSecondaryRetailerIds();
    }

    const userEmail = _get(userConfig, ['profile', 'email'], '');

    if (!isEmpty(userConfig) && !isEmpty(userConfig.profile) && !isEmpty(session) && !isEmpty(session.companyId)) {
      const { companyId } = session;
      try {
        churnZeroConnect(userEmail, companyId);
      } catch (e) {
        console.warn(e);
      }
    }

    // Only initialize LogRocket for specific users
    // const userEmail = _get(userConfig, ['profile', 'email'], '');
    // const allowedLogRocketUsers = ['kay.berkowitz@netgear.com'].includes(userEmail) || userEmail.includes('mlagoni');
    let preferenceInUserConfig;
    if (userConfig) {
      const { uiPreference } = userConfig;
      preferenceInUserConfig = uiPreference;
    }
    let allowedLogRocketUsers = false;
    if (preferenceInUserConfig && preferenceInUserConfig.enableLogRocketUntil) {
      const { enableLogRocketUntil } = preferenceInUserConfig;
      const untilTimeNumber = Number(enableLogRocketUntil);
      if (!Number.isNaN(untilTimeNumber) && moment(untilTimeNumber).isAfter(moment())) {
        allowedLogRocketUsers = true;
      }
    }

    if (userEmail.includes('mlagoni')) {
      allowedLogRocketUsers = true;
    }

    const shouldInitLogRocket =
      (allowedLogRocketUsers || app.name === AppName.Omni || window.location.href.includes('beaconpro')) && // opted in user or any user in omni
      !window.location.href.includes('-localhost'); // never enable on localhost

    if (!initializedLogRocket && shouldInitLogRocket) {
      initializedLogRocket = true;
      LogRocket.init('gjdzzt/stackline-ui');
      console.warn('initializing logrocket');
    }

    if (userConfig && initializedLogRocketWith !== _get(userConfig, ['profile', 'email']) && shouldInitLogRocket) {
      const { profile } = userConfig;
      if (profile) {
        initializedLogRocketWith = profile.email;
        LogRocket.identify(profile.email, {
          ...profile,
          ...userConfig,
          ...session,
          retailerId: _get(retailer, 'id'),
          beaconClientId: _get(userConfig, ['vendor', 'BeaconClientId'])
        }); // the user's email
      }
    }

    // Mixpanel tracking
    try {
      if (process.env.NODE_ENV !== 'development' && config && config.mixpanelApiKey && !isMixpanelInitialized()) {
        initializeMixpanel(config.mixpanelApiKey);
      }
      if (nextProps && config && isMixpanelInitialized()) {
        if (!_isEqual(user, props) && user && session && userConfig) {
          setMixpanelUserIdentity({ session, userConfig, app });
        }
        if (!_isEqual(location, props.location)) {
          trackRouteChange({}, location, user);
        }
      } else {
        if (user && session && userConfig && isMixpanelInitialized()) {
          setMixpanelUserIdentity({ session, userConfig, app });
        }
        trackRouteChange({}, location, user);
      }
    } catch (e) {
      console.warn(e);
    }

    // Sentry error tracking
    try {
      if (
        process.env.NODE_ENV !== 'development' &&
        config &&
        config.atlasSentryApiKey &&
        config.beaconSentryApiKey &&
        config.adManagerSentryApiKey &&
        config.omniSentryApiKey &&
        !isSentryInitialized()
      ) {
        initializeSentry(app);
      }
      if (nextProps && config && isSentryInitialized()) {
        if (!_isEqual(user, props) && user && session && userConfig) {
          setSentryUserIdentity(session, userConfig);
        }
      } else if (user && session && userConfig && isSentryInitialized()) {
        setSentryUserIdentity(session, userConfig);
      }
    } catch (e) {
      console.warn(e);
    }

    if (isInitializing === undefined) {
      return;
    }
    this.setState({ isInitializing });
  }

  // TODO: Investigate if we really want this.
  autoScroll = (prevProps) => {
    const instance = OverlayScrollbars(document.querySelectorAll('body'));
    if (instance && instance.scroll && shouldAutoScroll(prevProps.location, this.props.location)) {
      instance.scroll(0); // scroll to top
    }
  };

  handleServerError = (status) => {
    // Need to handle, 404, 500
    const { location, signOutSuccess } = this.props;

    switch (status) {
      case 401: {
        trackLogOut();
        signOutSuccess();
        this.props.history.push({
          pathname: '/sign_in',
          state: { from: location }
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  render() {
    const {
      user: { session },
      user,
      app,
      location,
      retailer,
      mainTimePeriod
    } = this.props;

    if (this.state.isInitializing) {
      return <Loading style={{ position: 'fixed' }} className="spinner" size={150} thickness={2} />;
    }

    const sessionIsValid = session && session.sessionId && session.hasValidBilling;

    const HomeLayoutToUse = isDrive && shouldShowCriteo() ? AdHomeAuthLayout : HomeAuthLayout;

    const renderLayout = sessionIsValid ? (
      <HomeLayoutToUse {...this.props}>
        {' '}
        <RouteTable
          app={app}
          routeType="authenticated"
          location={location}
          user={user}
          retailer={retailer}
          mainTimePeriod={mainTimePeriod}
        />{' '}
      </HomeLayoutToUse>
    ) : (
      <NonAuthLayout>
        {' '}
        <RouteTable
          app={app}
          routeType="non-authenticated"
          location={location}
          retailer={retailer}
          mainTimePeriod={mainTimePeriod}
        />{' '}
      </NonAuthLayout>
    );

    return <ErrorBoundary {...this.props}>{renderLayout}</ErrorBoundary>;
  }
}

const mapStateToProps = (state) =>
  _pick(state, ['app', 'auth', 'user', 'retailer', 'mainTimePeriod', 'parentPlatform']);

const mapDispatchToProps = {
  clearReduxState: appOperations.clearReduxState,
  signOutSuccess: authOperations.signOutSuccess,
  fetchConfig: appOperations.fetchConfig,
  fetchAppSecondaryRetailerIds: fetchSecondaryRetailerIds,
  fetchAppParentPlatforms: fetchParentPlatforms
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
