import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import colors from 'src/utils/colors';
import ReduxStore from 'src/types/store/reduxStore';
import { AppName } from 'sl-api-connector/types';

interface NavButtonProps {
  stacklineSku: string;
  retailerId: number;
}

const styles: { [key: string]: React.CSSProperties } = {
  button: {
    width: '111px',
    height: '33px',
    borderRadius: '21px',
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontFamily: 'Roboto',
    border: 0
  },
  positionButton2: {
    position: 'fixed',
    right: 15
  }
};

const buildProductNavUrl = (subdomain: string, stage: string, id: string, retailerId: number) =>
  `https://${subdomain}${stage !== 'prod' ? `-${stage}` : ''}.stackline.com/product/${id}?rid=${retailerId}`;

const NavAtlasBeaconGroup: FC<NavButtonProps> = ({ stacklineSku, retailerId }) => {
  const app = useSelector((store: ReduxStore) => store.app);
  const user = useSelector((store: ReduxStore) => store.user);
  const { stage } = app;
  const { appSubscriptions } = user.config;
  // demo use. when we have more retailer will add more. also we need the backend give the info for retailer they subscribed in other app.
  const shouldDisableClickAtlas = !([2, 4].includes(retailerId) && appSubscriptions.includes(AppName.Atlas));
  const shouldDisableClickBeacon = !([2, 4].includes(retailerId) && appSubscriptions.includes(AppName.Beacon));
  const preventVisit = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => (shouldDisableClick: boolean) => {
    if (shouldDisableClick) {
      e.preventDefault();
    }
  };
  return (
    <div style={styles.root}>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={buildProductNavUrl(AppName.Atlas, stage, stacklineSku, retailerId)}
        onClick={(e) => preventVisit(e)(shouldDisableClickAtlas)}
      >
        <button
          style={{
            ...styles.button,
            backgroundColor: shouldDisableClickAtlas ? colors.darkGrey : colors.stacklineBlue
          }}
        >
          Open in Atlas
        </button>
      </a>

      <a
        rel="noopener noreferrer"
        target="_blank"
        onClick={(e) => preventVisit(e)(shouldDisableClickBeacon)}
        href={buildProductNavUrl(AppName.Beacon, stage, stacklineSku, retailerId)}
        style={styles.positionButton2}
      >
        <button
          style={{
            ...styles.button,
            backgroundColor: shouldDisableClickBeacon ? colors.darkGrey : colors.stacklineBlue
          }}
        >
          Open in Beacon
        </button>
      </a>
    </div>
  );
};

export default NavAtlasBeaconGroup;
