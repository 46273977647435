import _merge from 'lodash/merge';
import OmniEntityGrid from 'src/components/EntityGrid/EntityGrid/OmniEntityGrid';
import { WaterfallFirstColumnHCF } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import { OmniGridField, OmniTableField } from 'src/components/EntityGrid/EntityGrid/omniGridField';
import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import ReduxStore from 'src/types/store/reduxStore';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { getProductImageUrl, getBrandImageUrl, getRetailerLogoUrl } from 'src/utils/image';
import _cloneDeep from 'lodash/cloneDeep';
import numeral from 'numeral';
import _get from 'lodash/get';
import { decode } from 'html-entities';

export enum EntityTableName {
  CONTENT_SCORE = 'contentScoretrendingProducts',
  CONTENT_ACCURACY = 'contentAccuracytrendingProducts'
}

const THRESHOLD = 0.00005;

export const omniTableFirstColumn = (array: OmniTableField[]) => {
  const numberOfColumns = array.length;

  let value;

  if (numberOfColumns <= 4) {
    value = 675;
  } else if (numberOfColumns > 7) {
    value = 475;
  } else if (numberOfColumns === 5) {
    value = 650;
  } else {
    value = 525;
  }
  // share of shelves will have different first column size due to long size of rest column e.g) Total Top 10 Share of Shelf
  if (array[0].shareOfShelfType) {
    return 550;
  }

  return value;
};

export const buildOmniProductsWidget = (
  omniProductField: OmniGridField,
  omniProductTableViewField: OmniTableField[],
  shouldSupportTableView: boolean,
  gridPage?: string
) => {
  const {
    currencySymbol,
    name,
    displayName,
    metricType,
    aggregationFunction,
    metricName,
    fulfillmentType,
    shareOfShelfType
  } = omniProductField;

  const trendingProductsWidgetName = `${name}trendingProducts`;

  const aggregationMetricsForTable = omniProductTableViewField.map((ele) => {
    const {
      name: nameInTableField,
      headerName,
      aggregationFunction: aggregationFunctionTableView,
      fulfillmentType: fulfillmentTypeTableView,
      metricName: metricNameTableView,
      shareOfShelfType: shareOfShelfTypeTableView,
      topSearchResults
    } = ele;
    if (fulfillmentTypeTableView) {
      return {
        name: nameInTableField,
        displayName: headerName,
        aggregationFunction: aggregationFunctionTableView,
        fulfillmentType: fulfillmentTypeTableView,
        metricName: metricNameTableView
      };
    }
    return {
      name: nameInTableField,
      displayName: headerName,
      aggregationFunction: aggregationFunctionTableView,
      shareOfShelfType: shareOfShelfTypeTableView,
      metricName: metricNameTableView,
      topSearchResults
    };
  });

  const trendingProductsWidget = {
    CustomComponent: OmniEntityGrid,
    name: trendingProductsWidgetName,
    view: _merge({}, VIEWS.entityGrid, {
      container: {
        style: { marginBottom: '40px', marginTop: '-40px' }
      },
      name: trendingProductsWidgetName,
      gridOptions: {
        enableGroupBy: true,
        enableExport: false,
        title: ' ',
        pageSize: 100,
        viewPageSize: 100,
        firstColumnDefOverrides: {
          headerComponentFramework: WaterfallFirstColumnHCF
        },
        onDataFetched: (_uniqueName, { eventBus }) => {
          eventBus.emit('trendingProductsDataSetUpdated');
        }
      }
    }),
    data: {
      shouldSupportTableView,
      aggregationMetrics: [
        {
          name,
          displayName,
          aggregationFunction,
          metricName,
          fulfillmentType,
          shareOfShelfType
        }
      ],
      omniTableViewFieldForTableView: omniProductTableViewField,
      aggregationMetricsForTable,
      metricData: {
        currencySymbol,
        name,
        metricType
      },
      gridPage,
      shouldDisableClick: gridPage === 'retailer'
    }
  };

  return [trendingProductsWidget];
};

export const processGridData = (
  gridData: { main: any[]; compare: any[] },
  metricData: { [key: string]: string },
  groupBy: string
) => {
  const { main, compare } = gridData;
  const { name } = metricData;
  let nameKey = name;
  const mapProduct = new Map();
  compare.forEach((product) => {
    const { entityMetadata } = product;
    mapProduct.set(entityMetadata[groupBy], product.data);
  });
  const dataToRender = main.map((product) => {
    const { entityMetadata } = product;

    const compareProduct = mapProduct.get(entityMetadata[groupBy]);
    if (name === 'promotionPrice') {
      nameKey = 'retailPrice';
    }
    const currentValue = product.data[nameKey].value;

    let previousValue;
    if (compareProduct && compareProduct[nameKey] && compareProduct[nameKey].value !== null) {
      previousValue = compareProduct[nameKey].value;
    }
    let periodChange = previousValue === undefined ? currentValue : currentValue - previousValue;
    if (periodChange > 0 && periodChange < 0.00000005) {
      periodChange = 0.00001;
    } else if (periodChange < 0 && periodChange > -0.00000005) {
      periodChange = -0.00001;
    }

    const percentChange = previousValue === undefined || previousValue === 0 ? 10 : periodChange / previousValue;

    const cardView = {
      ...entityMetadata,
      secondaryDisplayTitle: entityMetadata.title,
      subCategoryId: _get(entityMetadata, 'subcategoryId', -1),
      metricData
    };
    cardView[`${name}CurrentValue`] = currentValue;
    cardView[`${name}PreviousValue`] = previousValue;
    cardView[`${name}PeriodChange`] = periodChange;
    cardView[`${name}PercentChange`] = percentChange;
    return {
      id: entityMetadata.id,
      cardView
    };
  });

  return dataToRender;
};

export interface TableDataToRender {
  cardView?: any;
  entity: any;
  [key: string]: string | number;
}

const fillTheMissingData = (dataNeedFill: any[], omniTableViewFieldForTableView: any[], valueToFill: number) => {
  const clonedData = _cloneDeep(dataNeedFill);
  clonedData.forEach((product) => {
    const { data } = product;
    omniTableViewFieldForTableView.forEach((tableField) => {
      if (!Object.keys(data).includes(tableField.dataKey)) {
        data[tableField.dataKey] = { value: valueToFill };
        product.data = data;
      }
    });
  });
  return clonedData;
};

const groupByImageRenderer = (groupBy, cardView) => {
  switch (groupBy) {
    case 'brandId':
      return getBrandImageUrl(cardView.brandId);

    case 'retailerId':
      return getRetailerLogoUrl(String(cardView.retailerId));

    default:
      return getProductImageUrl(cardView.stacklineSku);
  }
};

export const processTableData = (
  gridData: { main: any[]; compare: any[] },
  omniTableViewFieldForTableView: any[],
  retailer: ReduxStore['retailer'],
  groupBy: string
): TableDataToRender[] => {
  const { main, compare } = gridData;

  let filledData = main;
  if (omniTableViewFieldForTableView[0].dataKey === 'avgOutOfStockRate') {
    filledData = fillTheMissingData(main, omniTableViewFieldForTableView, 1);
  }
  const compareDataMap = new Map();
  compare.forEach((product) => {
    const { entityMetadata } = product;
    compareDataMap.set(entityMetadata[groupBy], product);
  });

  const dataToRender: TableDataToRender[] = [];
  filledData.forEach((row) => {
    const { entityMetadata, data } = row;
    const compareProduct = compareDataMap.get(entityMetadata[groupBy]);

    const cardView = entityMetadata;

    cardView.name = decode(entityMetadata.title);
    const aProcessedData = {
      cardView,
      entity: {
        ...cardView,
        imageUrl: groupByImageRenderer(groupBy, cardView),
        upc: cardView.gtin
      }
    };
    Object.keys(data).forEach((dataKey) => {
      if (dataKey !== 'key') {
        const actTableField = omniTableViewFieldForTableView.find((tableField) => tableField.dataKey === dataKey);
        if (actTableField) {
          const { metricType, currencySymbol } = actTableField;
          let { value } = data[dataKey];
          if (!value) {
            value = 0;
          }
          aProcessedData[`${dataKey}Number`] = value;
          aProcessedData[dataKey] = convertMetricToDisplayValue(retailer, value, metricType, currencySymbol);

          let compareValue =
            compareProduct && compareProduct.data && compareProduct.data[dataKey]
              ? compareProduct.data[dataKey].value
              : 0;
          if (!compareValue) {
            compareValue = 0;
          }
          const periodChange = value - compareValue;
          aProcessedData[`${dataKey}PeriodChange`] = compareProduct === undefined ? 0 : periodChange;
          let percentChange = compareValue === 0 ? 10 : periodChange / compareValue;
          aProcessedData[`${dataKey}PercentValue`] = percentChange;
          aProcessedData[`${dataKey}PercentChange`] = convertMetricToDisplayValue(
            retailer,
            percentChange,
            METRICTYPE.PERCENT,
            currencySymbol
          );

          if (dataKey.includes('ShareOfShelf')) {
            aProcessedData[dataKey] = numeral(Math.round(value * 10000) / 10000).format('0.00%');
            percentChange =
              Math.abs(percentChange) < THRESHOLD ? (percentChange < 0 ? -1 * THRESHOLD : THRESHOLD) : percentChange;
            aProcessedData[`${dataKey}PrevValue`] = compareValue;
            aProcessedData[`${dataKey}PercentChange`] = numeral(Math.round(percentChange * 10000) / 10000).format(
              '0.00%'
            );
          }
        }
      }
    });

    dataToRender.push(aProcessedData);
  });

  return dataToRender;
};

export const processContentScoreGridData = (
  gridData: { main: any[]; compare: any[] },
  metricData: { [key: string]: string },
  groupBy: string
) => {
  const { main, compare } = gridData;
  const { name } = metricData;
  const mainData = _get(main, `data.${groupBy}.buckets`, []);
  const compareData = _get(compare, `data.${groupBy}.buckets`, []);

  const mapProduct = new Map();
  const accessDataKey = groupBy === 'gtin' ? 'aggsByChild.contentScore_avg.value' : 'contentScore_avg.value';
  groupBy = groupBy === 'gtin' ? groupBy : 'id';
  compareData.forEach((element: any) => {
    const product = _get(element, 'entityMetadata', {});
    if (product[groupBy]) {
      const value = _get(element, accessDataKey, 0);
      mapProduct.set(product[groupBy], value);
    }
  });

  return mainData.map((element: any) => {
    const product = _get(element, 'entityMetadata', {});
    const compareVal = mapProduct.get(product[groupBy]) || 0;
    const currentVal = _get(element, accessDataKey, 0);
    let periodChange = currentVal - compareVal;

    // avoid the percent value showing 0
    if (periodChange > 0 && periodChange < 0.00000005) {
      periodChange = 0.00001;
    } else if (periodChange < 0 && periodChange > -0.00000005) {
      periodChange = -0.00001;
    }

    let percentChange = compareVal === 0 ? 10 : periodChange / compareVal;
    if (periodChange === 0) {
      percentChange = 0;
    }

    const cardView = {
      ...product,
      secondaryDisplayTitle: _get(product, 'title', ''),
      metricData
    };

    cardView[`${name}CurrentValue`] = currentVal;
    cardView[`${name}PreviousValue`] = compareVal;
    cardView[`${name}PeriodChange`] = periodChange;
    cardView[`${name}PercentChange`] = percentChange;
    return {
      id: _get(product, 'id'),
      cardView
    };
  });
};

export const processContentScoreTableData = (
  gridData: { main: any[]; compare: any[] },
  omniTableViewFieldForTableView: any[],
  retailer: ReduxStore['retailer'],
  groupBy: string
) => {
  const isGroupByGtin = groupBy === 'gtin';
  const aggsByChildsGroupBy = ['brandId', 'categoryId', 'subCategoryId'];
  const { main, compare } = gridData;

  const mainData = _get(main, `data.${groupBy}.buckets`, []);
  const compareData = _get(compare, `data.${groupBy}.buckets`, []);

  const compareDataMap = new Map();

  // if it is groupBy gtin, the response key should be 'id'
  const getId = isGroupByGtin ? 'id' : groupBy;

  compareData.forEach((element: any) => {
    const product = _get(element, 'entityMetadata', {});

    if (product[getId]) {
      if (isGroupByGtin || aggsByChildsGroupBy.includes(groupBy)) {
        const valueSet = _get(element, 'aggsByChild', {});
        compareDataMap.set(product[getId], valueSet);
      } else {
        compareDataMap.set(product[getId], element);
      }
    }
  });

  const dataToRender: TableDataToRender[] = [];

  mainData.forEach((element: any) => {
    const product = _get(element, 'entityMetadata', {});
    const compareProduct = compareDataMap.get(product[getId]);

    const cardView = product;
    cardView.name = decode(_get(product, 'title', ''));
    const aProcessedData = {
      cardView,
      entity: {
        ...cardView,
        imageUrl: groupByImageRenderer(groupBy, cardView),
        upc: _get(cardView, 'upc')
      }
    };
    const dataStore =
      isGroupByGtin || aggsByChildsGroupBy.includes(groupBy) ? _get(element, 'aggsByChild', {}) : element;

    Object.keys(dataStore).forEach((dataKey) => {
      const actTableField = omniTableViewFieldForTableView.find((tableField) => tableField.dataKey === dataKey);
      if (actTableField) {
        const { metricType, currencySymbol } = actTableField;
        const value = _get(dataStore, `${dataKey}.value`, 0);
        aProcessedData[`${dataKey}Number`] = value;
        aProcessedData[dataKey] = convertMetricToDisplayValue(retailer, value, metricType, currencySymbol);

        const compareValue = _get(compareProduct, `${dataKey}.value`, 0);

        const periodChange = value - compareValue;

        aProcessedData[`${dataKey}PeriodChange`] = periodChange;
        let percentChange = compareValue === 0 ? 10 : periodChange / compareValue;
        if (periodChange === 0) {
          percentChange = 0;
        }

        aProcessedData[`${dataKey}PercentValue`] = percentChange;
        aProcessedData[`${dataKey}PercentChange`] = convertMetricToDisplayValue(
          retailer,
          percentChange,
          METRICTYPE.PERCENT,
          currencySymbol
        );
      }
    });
    dataToRender.push(aProcessedData);
  });

  return dataToRender;
};

const computeAvgAccuracy = (dataSet: { [key: string]: { value: number } | any }) => {
  const keySet = Object.keys(dataSet);
  let total = 0;
  let count = 0;
  keySet.forEach((key) => {
    if (key.includes('computed')) {
      const val = _get(dataSet[key], 'value', 0);
      total += val;
      count++;
    }
  });
  return total / count;
};

export const processContentAccuracyTileData = (
  gridData: { main: any[]; compare: any[] },
  metricData: { [key: string]: string },
  groupBy: string
) => {
  const { main, compare } = gridData;
  const { name } = metricData;
  const mainData = _get(main, `data.${groupBy}.buckets`, []);
  const compareData = _get(compare, `data.${groupBy}.buckets`, []);
  groupBy = groupBy === 'gtin' ? groupBy : 'id';
  const mapProduct = new Map();
  compareData.forEach((element: any) => {
    const product = _get(element, 'entityMetadata', {});
    if (product[groupBy]) {
      const value = computeAvgAccuracy(element);
      mapProduct.set(product[groupBy], value);
    }
  });
  return mainData.map((element: any) => {
    const product = _get(element, 'entityMetadata', {});
    const compareVal = mapProduct.get(product[groupBy]) || 0;
    const currentVal = computeAvgAccuracy(element);

    let periodChange = currentVal - compareVal;

    if (periodChange > 0 && periodChange < 0.00000005) {
      periodChange = 0.00001;
    } else if (periodChange < 0 && periodChange > -0.00000005) {
      periodChange = -0.00001;
    }

    let percentChange = compareVal === 0 ? 10 : periodChange / compareVal;
    if (periodChange === 0) {
      percentChange = 0;
    }

    const cardView = {
      ...product,
      secondaryDisplayTitle: _get(product, 'title', ''),
      metricData
    };
    cardView[`${name}CurrentValue`] = currentVal;
    cardView[`${name}PreviousValue`] = compareVal;
    cardView[`${name}PeriodChange`] = periodChange;
    cardView[`${name}PercentChange`] = percentChange;
    return {
      id: _get(product, 'id'),
      cardView
    };
  });
};

export const processContentAccuracyTableData = (
  gridData: { main: any[]; compare: any[] },
  omniTableViewFieldForTableView: any[],
  retailer: ReduxStore['retailer'],
  groupBy: string
) => {
  const { main } = gridData;
  const mainData = _get(main, `data.${groupBy}.buckets`, []);
  const dataToRender: TableDataToRender[] = [];
  mainData.forEach((element: any) => {
    const product = _get(element, 'entityMetadata', {});
    const cardView = product;
    cardView.name = decode(_get(product, 'title', ''));
    const aProcessedData = {
      cardView,
      entity: {
        ...cardView,
        imageUrl: groupByImageRenderer(groupBy, cardView),
        upc: _get(cardView, 'upc')
      }
    };
    omniTableViewFieldForTableView.forEach((tableField) => {
      const { metricType, currencySymbol, dataKey } = tableField;
      const value = _get(element, `${dataKey}.value`, 0);
      aProcessedData[`${dataKey}Number`] = value;
      aProcessedData[dataKey] = convertMetricToDisplayValue(retailer, value, metricType, currencySymbol);
    });
    dataToRender.push(aProcessedData);
  });

  return dataToRender;
};

export const firstColumnWidth = (groupFieldName: string): { min: number; max: number } => {
  switch (groupFieldName) {
    case 'store':
      return { min: 100, max: 100 };
    case 'keyword':
      return { min: 150, max: 500 };
    case 'product':
      return { min: 150, max: 150 };
    default:
      return { min: 200, max: 1000 };
  }
};
