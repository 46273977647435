import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import colors from 'src/utils/colors';
import { userOperations } from 'src/store/modules/user';
import UserAccountUpload from './UserAccountUpload/UserAccountUpload';

const UserAccountMultiRetailer = ({ entityService, updateUserAccount, getUploadPageDetail }) => {
  if (!entityService.mainEntity) {
    return null;
  }
  const { buttons, getLatestApi, retailers } = getUploadPageDetail('multiRetailer');
  const prevSaturday = moment().day(-1).format('MM/DD/YYYY'); // Last end week day

  if (retailers.length === 0) {
    return null;
  }
  return (
    <UserAccountUpload
      headerText="Retailer Upload"
      retailers={retailers}
      currRetailer={retailers[0]}
      buttons={buttons}
      updateUserAccount={updateUserAccount}
      getLatestApi={getLatestApi}
      uploadType="multi-retailer-upload"
    >
      <div>
        Upload retail sales and other metrics from your retailers, so Beacon can track the performance of your entire
        e-commerce channel.
        <br />
        <br />
        Get started by selecting a retailer from the menu below and downloading the associated template.
        <br />
        <br />
        When you finish populating the template with the new data set for the week ending{' '}
        <strong>{prevSaturday}</strong>, you can then upload the completed file. Reach out to{' '}
        <a
          style={{ color: colors.blue }}
          href="mailto:support@stackline.com?subject=Question About Multi Retailer Upload"
        >
          support@stackline.com
        </a>{' '}
        for any questions.
      </div>
    </UserAccountUpload>
  );
};

UserAccountMultiRetailer.propTypes = {
  entityService: PropTypes.object.isRequired,
  updateUserAccount: PropTypes.func.isRequired,
  getUploadPageDetail: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  updateUserAccount: userOperations.updateUserAccount
};

export default connect((state) => state, mapDispatchToProps)(UserAccountMultiRetailer);
