import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _orderBy from 'lodash/orderBy';
import _pick from 'lodash/pick';
import axios from 'axios';
import { buildMetricValue } from 'src/utils/metrics';
import colors from 'src/utils/colors';
import GenericChart from 'src/components/Charts/GenericChart';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import {
  buildDefaultConditions,
  buildEntityConditions,
  buildMainEntityConditions,
  buildAggregationConditions
} from '../Renderer/EntityPageRenderer';
import { buildSubtitleDisplayName } from 'src/utils/filters';
import { anyNotEq, keyNotEq } from 'src/utils/equality';
import WaterfallLegend from 'src/components/Charts/HighchartsElements/WaterfallLegend';
import { exportData } from 'src/utils/export';
import {
  getDefaultUnitsSoldImpactConsolidated,
  computeImpactDataForProduct,
  allMetrics,
  getMetricFieldForSelectedField,
  getFieldNameFromSeriesName
} from './waterfallCompute';
import TrafficInsights from './Insights/TrafficInsights/TrafficInsights';
import './WaterfallChart.scss';
import { buildAggregations } from 'src/components/AdManager/Search';

const { CancelToken } = axios;

const buildLegendDiv = ({ ...props }) => ReactDOMServer.renderToString(<WaterfallLegend {...props} />);

const BarChart = ({ chartSeries, chartProps }) => {
  if (chartSeries.length === 0) {
    return <div>No data to compare for the selected time ranges</div>;
  }
  return <GenericChart chartSeries={chartSeries} chartProps={chartProps} />;
};

BarChart.propTypes = {
  chartSeries: PropTypes.any.isRequired,
  chartProps: PropTypes.object.isRequired
};

/**
 * Spreads the list of provided `collections` into `baseCollection` one at a time, retaining existing values in the
 * case that the value of one of the `collecitons` is nil.
 *
 * @param {object} baseCollection
 * @param  {...object} collections The list of objects to spread into `baseCollection`
 */
export const spreadIfNotNil = (baseCollection, ...collections) => {
  const merged = baseCollection;
  collections.forEach((collection) => {
    Object.entries(collection).forEach(([key, val]) => {
      if (!_isNil(val)) {
        merged[key] = val;
      }
    });
  });

  return merged;
};

const ChartXAxisLabel = ({ selectedField, name }) => (
  /* eslint-disable react/no-danger */
  <span
    className={`waterfall-x-axis-label ${
      getFieldNameFromSeriesName(name) === selectedField ? 'waterfall-x-axis-label--active' : ''
    }`}
    dangerouslySetInnerHTML={{ __html: name }}
  />
);

ChartXAxisLabel.propTypes = {
  selectedField: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

class WaterfallChart extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    eventBus: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    allWeekIdsByRetailerId: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    aggregationConditions: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired,
    entitySearchService: PropTypes.object.isRequired,
    conditions: PropTypes.object.isRequired,
    queryConditions: PropTypes.object.isRequired,
    widget: PropTypes.object.isRequired,
    comparisonConfig: PropTypes.object.isRequired,
    queryParams: PropTypes.object.isRequired,
    fetchEntityMetrics: PropTypes.func.isRequired,
    clearEntitySearchService: PropTypes.func.isRequired
  };

  state = {
    selectedField: 'unitsSoldComparisonPeriod',
    isLoading: true,
    dataGridTitle: 'Units Sold (Prior)',
    dataToRender: []
  };

  // The result of computing `this.computeWaterfall`.
  waterfallData = null;

  // A flag that indicates that `this.computeWaterfall` is currently running and `this.gridMetric` will be set later.
  waterfallComputing = false;

  componentDidMount() {
    this.cancelSource = CancelToken.source();
    this.fetchData(this.props);
    this.addEventListeners();
  }

  componentWillReceiveProps(nextProps) {
    const propKeysToCompare = [
      'location.pathname',
      'location.search',
      'conditions',
      'entityService.mainEntity',
      'entityService.comparisonEntity',
      'filters',
      'widget'
    ];
    if (anyNotEq(propKeysToCompare, this.props, nextProps)) {
      if (typeof this.cancelSource !== typeof undefined) {
        this.cancelSource.cancel('Canceled network request');
      }
      this.cancelSource = CancelToken.source();
      this.fetchData(nextProps);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_isEqual(this.state, nextState)) {
      return true;
    }
    const { entitySearchService } = this.props;
    const { entitySearchService: nextEntitySearchService } = nextProps;
    const { entitySearchServiceStateNamesMainEntity = [], entitySearchServiceStateNamesComparisonEntity = [] } =
      this.state;

    return !![...entitySearchServiceStateNamesMainEntity, ...entitySearchServiceStateNamesComparisonEntity].find(
      (stateName) => keyNotEq(stateName, entitySearchService, nextEntitySearchService)
    );
  }

  componentWillUnmount() {
    if (typeof this.cancelSource !== typeof undefined) {
      this.cancelSource.cancel('Cancel network request');
    }
    this.removeEventListeners();
  }

  addEventListeners = () => {
    const { eventListeners } = this.props.widget.view;
    const { eventBus } = this.props;
    if (eventListeners && eventListeners.length > 0) {
      eventListeners.forEach((event) => {
        eventBus.on(event.name, this.fetchData);
      });
    }
  };

  removeEventListeners = () => {
    const { eventListeners } = this.props.widget.view;
    const { eventBus } = this.props;
    if (eventListeners && eventListeners.length > 0) {
      eventListeners.forEach((event) => {
        eventBus.off(event.name, this.fetchData);
      });
    }
  };

  fetchData = ({
    app,
    aggregationConditions: propAggregationConditions,
    retailer,
    categories,
    queryParams,
    allWeekIdsByRetailerId,
    mainTimePeriod,
    comparisonTimePeriod,
    conditions,
    match,
    entityService: { mainEntity },
    fetchEntityMetrics,
    widget
  }) => {
    if (!mainEntity) {
      return;
    }
    let { id: idFromUrl } = match.params;
    if (!idFromUrl || idFromUrl === '') {
      idFromUrl = `${mainEntity.id}`;
    }

    // the main entity has to match with what is in the current url
    if (idFromUrl !== `${mainEntity.id}` && idFromUrl !== mainEntity.hashId) {
      return;
    }

    this.setState({ isLoading: true });
    const defaultConditions = buildDefaultConditions(conditions, queryParams);
    const entityConditions = buildEntityConditions(app, categories, defaultConditions, mainEntity);
    const mainEntityConditions = buildMainEntityConditions(entityConditions, mainEntity, app, retailer, queryParams);

    const state = {
      isLoading: true,
      entitySearchServiceStateNamesMainEntity: [],
      entitySearchServiceStateNamesComparisonEntity: [],
      entitySearchServiceStateNamesMarketShare: [],
      mainMetricConfig: {},
      comparisonMetricConfig: {}
    };

    const promises = widget.data.groupByFields.map((groupByField) => {
      const { entity, indexName } = widget.data.configByGroupByFieldName[groupByField.name];
      const { aggregationConditions, comparisonRangeFilters } = buildAggregationConditions(
        app,
        indexName,
        retailer,
        allWeekIdsByRetailerId,
        mainTimePeriod,
        comparisonTimePeriod,
        propAggregationConditions,
        widget.data.weekIdField,
        false,
        true
      );
      const statePropertyNameMainEntity = `${widget.name}_${app.name}_${groupByField.name}`;
      state.entitySearchServiceStateNamesMainEntity.push(statePropertyNameMainEntity);
      const aggregationItems = buildAggregations(
        widget.data.configByGroupByFieldName[groupByField.name].aggregationFields
      );

      let derivedFields = null;

      const mainEntitySearchRequestOverrides = aggregationItems.map((aggregationItem) => {
        const {
          aggregations: aggregationFields,
          aggregationFieldConditions,
          derivations,
          indexName: aggregationIndexName
        } = aggregationItem;

        derivedFields = derivedFields || derivations;
        if (aggregationConditions && aggregationConditions.termFilters) {
          aggregationFieldConditions.termFilters = aggregationFieldConditions.termFilters.concat(
            aggregationConditions.termFilters
          );
        }
        if (aggregationConditions && aggregationConditions.rangeFilters) {
          aggregationFieldConditions.rangeFilters = aggregationFieldConditions.rangeFilters.concat(
            aggregationConditions.rangeFilters
          );
        }

        const aggregations = {
          groupByFieldName: groupByField.name,
          aggregationFields,
          sortDirection: null,
          sortByAggregationField: null,
          conditions: aggregationFieldConditions,
          comparisonRangeFilters
        };

        return {
          name: `${statePropertyNameMainEntity}-${entity.type}-${entity.id}-${aggregationIndexName}`,
          id: `${statePropertyNameMainEntity}-${entity.type}-${entity.id}-${aggregationIndexName}`,
          indexName: aggregationIndexName,
          conditions: _cloneDeep(mainEntityConditions),
          aggregations: [aggregations],
          pageSize: 5000,
          additionalRequestMetaData: {
            returnAdditionalMetaData: true
          }
        };
      });

      const requestContext = {
        entity,
        retailer,
        app,
        indexName,
        derivedFields,
        aggregationFields: widget.data.configByGroupByFieldName[groupByField.name].aggregationFields
      };

      return fetchEntityMetrics(
        statePropertyNameMainEntity,
        requestContext,
        mainEntitySearchRequestOverrides,
        this.cancelSource.token
      );
    });

    this.setState({ ...state });

    Promise.all(promises).then(() => {
      // Force the waterfall data to be re-computed since there is a new dataset
      this.waterfallData = null;
      this.setState({ isLoading: false });
    });
  };

  shouldShowLoading() {
    return this.state.isLoading;
  }

  getDefaultChartProps = () => {
    const {
      entityService: { mainEntity },
      filters,
      categories,
      app,
      retailer
    } = this.props;
    const subtitle = buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const waterfallChartContext = this;

    return {
      chart: {
        type: 'waterfall',
        height: 650
      },
      title: {
        text: 'Waterfall'
      },
      subtitle: { text: subtitle.displayName },
      xAxis: [
        {
          type: 'category',
          labels: {
            rotation: 0,
            maxStaggerLines: 2,
            step: 1,
            style: {
              maxWidth: '50px',
              minWidth: '50px',
              cursor: 'pointer'
            },
            events: {
              click: function handleLabelClick() {
                const { displayName, id } = allMetrics[this.pos];
                waterfallChartContext.handleWaterfallSelect(id, displayName);
              }
            },
            useHTML: true,
            formatter() {
              return ReactDOMServer.renderToString(
                <ChartXAxisLabel name={this.value} selectedField={waterfallChartContext.state.selectedField} />
              );
            }
          }
        }
      ],
      plotOptions: {
        waterfall: {
          pointWidth: 15,
          events: {
            legendItemClick() {
              return false;
            }
          }
        },
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: function handleClick() {
                waterfallChartContext.handleWaterfallSelect(
                  getFieldNameFromSeriesName(this.options.name),
                  this.options.displayName
                );
                return true;
              }
            }
          }
        }
      },
      yAxis: [
        {
          title: {
            text: null
          },
          labels: {
            formatter() {
              const yValue = buildMetricValue(this.value, 'VOLUME', '$', true, null, retailer.locale);
              return `${yValue.value}${yValue.suffix || ''}`;
            }
          }
        }
      ],
      legend: {
        enabled: true,
        symbolWidth: 0,
        symbolHeight: 0,
        labelFormatter() {
          return this.userOptions.legendDiv;
        }
      },
      tooltip: {
        formatter() {
          const categoryName = this.points[0].key;
          const yValue = buildMetricValue(this.points[0].y, 'VOLUME', '$', true, null, retailer.locale);
          const xAxisLabelDiv = `<div style="display:inline-block; float:left; color:${colors.darkBlue};">
              ${categoryName}
            </div>`;
          const yAxisMetricsDiv = `<div style="margin-top:5px;color:${this.points[0].color};">${yValue.value}${
            yValue.suffix || ''
          }</div>`;

          return `<div class="hichart-tooltip">
                    <div style="overflow:hidden;" class="hiline-chart-tooltipx">
                      ${xAxisLabelDiv}
                    </div>
                    <div class="hiline-chart-tooltipy">
                      ${yAxisMetricsDiv}
                    </div>
                  </div>`;
        },
        positioner(labelWidth, labelHeight, { plotX, plotY }) {
          return { x: plotX, y: plotY - 35 };
        }
      }
    };
  };

  static debugWaterFallMetrics({ retailer, gridMetric }) {
    const metricNames = [
      'unitsSold',
      'trafficOrganicClicks',
      'adUnitsSold',
      'trafficOtherClicks',
      'retailPrice',
      'contentScore',
      'avgStars',
      'buyBoxWinPercentage',
      'instockRate'
    ];
    let waterfallMetricsCsv =
      'stacklineSku,retailerId,retailerSku,title,brandId,brandName,categoryId,categoryName,subCategoryId,subCategoryName,availabilityStatus,availabilityStatusCode';
    metricNames.forEach((metricName) => {
      waterfallMetricsCsv += `,${metricName}PreviousValue,${metricName}CurrentValue,${metricName}PeriodChange,${metricName}PercentChange`;
    });
    [
      'trafficOrganicClicks',
      'adUnitsSold',
      'trafficOtherClicks',
      'retailPrice',
      'contentScore',
      'avgStars',
      'instockRate',
      'buyBoxWinPercentage',
      'other'
    ].forEach((metricName) => {
      waterfallMetricsCsv += `,${metricName}Impact`;
    });
    waterfallMetricsCsv += '\n';
    gridMetric.forEach(({ entity, cardView, unitSoldImpact }) => {
      waterfallMetricsCsv += `${entity.stacklineSku},${retailer.id},${entity.retailerSku},${entity.name.replace(
        /,/g,
        ' '
      )},${entity.brandId},${entity.brandName},${entity.categoryId},${entity.categoryName},${entity.subCategoryId},${
        entity.subCategoryName
      },${entity.availabilityStatus},${entity.availabilityStatusCode}`;
      metricNames.forEach((metricName) => {
        waterfallMetricsCsv += `,${cardView[`${metricName}PreviousValue`]},${cardView[`${metricName}CurrentValue`]},${
          cardView[`${metricName}PeriodChange`]
        },${cardView[`${metricName}PercentChange`]}`;
      });
      [
        'trafficOrganicClicks',
        'trafficAdClicks',
        'trafficOtherClicks',
        'retailPrice',
        'contentScore',
        'avgStars',
        'instockRate',
        'buyBoxWinPercentage',
        'other'
      ].forEach((metricName) => {
        waterfallMetricsCsv += `,${unitSoldImpact[metricName]}`;
      });
      waterfallMetricsCsv += '\n';
    });
    exportData({
      data: waterfallMetricsCsv,
      mimeType: 'data:text/csv;charset=utf-8',
      fileName: 'waterfall_metrics.csv'
    });
  }

  computeWaterFall() {
    const { retailer, entitySearchService, mainTimePeriod, comparisonTimePeriod } = this.props;
    const { entitySearchServiceStateNamesMainEntity } = this.state;
    const [entitySearchServiceStateNameMainEntity] = entitySearchServiceStateNamesMainEntity;
    // TODO: Switch to computing the map asynchronously
    const metricsByStacklineSku = new Map();
    const mainTimePeriodSuffix = `${mainTimePeriod.startWeek}_${mainTimePeriod.endWeek}`;
    const comparisonTimePeriodSuffix = `${comparisonTimePeriod.startWeek}_${comparisonTimePeriod.endWeek}`;

    Object.keys(entitySearchService[entitySearchServiceStateNameMainEntity]).forEach((propertyName) => {
      if (propertyName === 'apiRequest' || propertyName.includes('_dataPointCount_')) {
        return;
      }

      const {
        appName,
        name: metricFieldName,
        aggregationFunction
      } = entitySearchService[entitySearchServiceStateNameMainEntity][propertyName];

      const valueFieldName = aggregationFunction === 'stats' ? 'avg' : 'value';
      const mainPeriodValuePropName = `${valueFieldName}_${mainTimePeriodSuffix}`;
      const mainPeriodCountPropName = `count_${mainTimePeriodSuffix}`;
      const comparisonPeriodValuePropName = `${valueFieldName}_${comparisonTimePeriodSuffix}`;
      const comparisonPeriodCountPropName = `count_${comparisonTimePeriodSuffix}`;
      const metricNamePrefix = `${appName}_${metricFieldName}_`;
      entitySearchService[entitySearchServiceStateNameMainEntity][propertyName].data.forEach((dataPoint) => {
        const productId =
          dataPoint.cardView && dataPoint.cardView.productId ? dataPoint.cardView.productId : dataPoint.name;

        let productDatum = metricsByStacklineSku.get(productId) || {};

        const valueChange = dataPoint[mainPeriodValuePropName] - dataPoint[comparisonPeriodValuePropName];
        const valueChangePercent = dataPoint[comparisonPeriodValuePropName]
          ? dataPoint[mainPeriodValuePropName]
            ? valueChange / dataPoint[comparisonPeriodValuePropName]
            : -1
          : dataPoint[mainPeriodValuePropName]
          ? 1
          : 0;

        productDatum = {
          ...productDatum,
          [`${metricNamePrefix}value_maintimeperiod`]: dataPoint[mainPeriodValuePropName],
          [`${metricNamePrefix}count_maintimeperiod`]: dataPoint[mainPeriodCountPropName],
          [`${metricNamePrefix}value_comparisontimeperiod`]: dataPoint[comparisonPeriodValuePropName],
          [`${metricNamePrefix}count_comparisontimeperiod`]: dataPoint[comparisonPeriodCountPropName],
          [`${metricNamePrefix}change`]: valueChange,
          [`${metricNamePrefix}changePercent`]: valueChangePercent
        };
        if (dataPoint.cardView && dataPoint.cardView.availabilityStatus) {
          productDatum.availabilityStatus = dataPoint.cardView.availabilityStatus;
        }
        productDatum.name = dataPoint.name;

        const oldCardView = productDatum.cardView || {};
        productDatum.cardView = spreadIfNotNil(oldCardView, dataPoint.entity, dataPoint.cardView);

        productDatum.entity = { ...productDatum.entity, ...dataPoint.entity };

        metricsByStacklineSku.set(productId, productDatum);
      });
    });

    const {
      retailer: { currencySymbol, locale }
    } = this.props;
    let acc = {
      gridMetric: [],
      unitsSoldImpactConsolidated: getDefaultUnitsSoldImpactConsolidated(),
      metricAggregatedValues: {},
      countOfSkuWithDataToCompare: 0,
      selectedField: this.state.selectedField,
      currencySymbol,
      locale
    };

    const metricsMap = this.props.widget.view.buildWaterfallData
      ? this.props.widget.view.buildWaterfallData({ metricsByStacklineSku })
      : metricsByStacklineSku;

    metricsMap.forEach((val, key) => {
      acc = computeImpactDataForProduct(acc, key, val);
    });

    const { gridMetric, unitsSoldImpactConsolidated, countOfSkuWithDataToCompare } = acc;

    if (countOfSkuWithDataToCompare === 0) {
      return { chartSeries: [], chartProps: {} };
    }

    const comparisonLegendValue = unitsSoldImpactConsolidated.unitsSoldComparisonPeriod.y;
    let mainLegendValue = 0;
    Object.values(unitsSoldImpactConsolidated).forEach((impactMetricValue) => {
      if (impactMetricValue.y) {
        impactMetricValue.y = Math.round(impactMetricValue.y);
        mainLegendValue += impactMetricValue.y;
      }
      impactMetricValue.color = impactMetricValue.y >= 0 ? colors.green : colors.red;
    });

    const extraSeriesPropsByMetricName = {
      unitsSoldComparisonPeriod: { color: colors.comparison },
      unitsSoldMainPeriod: { color: colors.stacklineBlue, isSum: true }
    };

    const chartSeries = [
      {
        borderRadius: 2,
        borderWidth: 0,
        upColor: colors.green,
        color: '#fff',
        pointWidth: 15,
        legendDiv: buildLegendDiv({
          comparisonLegendValue,
          retailer,
          mainLegendValue,
          mainTimePeriod,
          comparisonTimePeriod
        }),
        data: allMetrics.map(({ id, displayName }) => ({
          displayName,
          ...unitsSoldImpactConsolidated[id],
          ...(extraSeriesPropsByMetricName[id] || {})
        }))
      }
    ];

    const chartProps = this.getDefaultChartProps();

    return { chartProps, chartSeries, gridMetric, unitsSoldImpactConsolidated, comparisonLegendValue, mainLegendValue };
  }

  sortAndFilterGridMetricData = (sortField = this.state.selectedField) => {
    const { currencySymbol, locale } = this.props.retailer;

    if (!this.waterfallData.gridMetric) {
      return;
    }

    // Filter out rows that are missing both product metadata as well as units sold data
    const filteredGridMetric = this.waterfallData.gridMetric.filter((elem) => !!elem.name);

    const metricField = getMetricFieldForSelectedField(sortField, 'product', currencySymbol, locale);

    // Add some additional values to the grid metric data items to facilitate sorting and rendering
    filteredGridMetric.forEach((elem) => {
      const value =
        (sortField.includes('unitsSold')
          ? _get(elem, ['cardView', 'unitsSoldCurrentValue'])
          : Math.abs(elem.unitSoldImpact[sortField])) || 0;

      elem.value = value;
      elem.cardView.metricField = metricField;
      elem.cardView.metricData = { ...elem.cardView.metricData, ...metricField };
    });

    this.waterfallData.gridMetric = _orderBy(filteredGridMetric, ['value'], ['desc']);
  };

  getWaterfallData = () => {
    if (!this.waterfallData) {
      this.waterfallData = this.computeWaterFall();
      this.sortAndFilterGridMetricData();
    }

    return { ...this.waterfallData, gridMetric: this.waterfallData.gridMetric };
  };

  handleWaterfallSelect = (fieldName, displayName) => {
    if (!getMetricFieldForSelectedField(fieldName)) {
      return;
    }

    if (this.waterfallData) {
      this.sortAndFilterGridMetricData(fieldName);
    }

    // Re-create the labels so that the newly active one is bolded
    this.waterfallData.chartProps = this.getDefaultChartProps();

    this.setState({ selectedField: fieldName, dataGridTitle: displayName });
  };

  renderContent() {
    if (this.waterfallComputing) {
      return null;
    }

    const { chartProps, chartSeries, gridMetric } = this.getWaterfallData();
    const { selectedField, dataGridTitle } = this.state;
    const { widget, conditions, queryConditions, aggregationConditions, comparisonConfig, queryParams } = this.props;

    const showTrafficInsights = widget.view.showTrafficInsights !== undefined ? widget.view.showTrafficInsights : true;
    return (
      <>
        <BarChart chartSeries={chartSeries} chartProps={chartProps} />
        <br /> <br />
        <br />
        {showTrafficInsights && (
          <TrafficInsights
            conditions={conditions}
            queryConditions={queryConditions}
            aggregationConditions={aggregationConditions}
            comparisonConfig={comparisonConfig}
            queryParams={queryParams}
            widget={widget}
            selectedField={{ name: selectedField }}
            waterfallImpactData={gridMetric}
            waterfallImpactGridTitle={dataGridTitle}
            unitsSoldImpactConsolidated={this.waterfallData.unitsSoldImpactConsolidated}
          />
        )}
      </>
    );
  }

  render = () => (
    <div id={this.props.widget.name}>{this.shouldShowLoading() ? <GenericChartLoading /> : this.renderContent()}</div>
  );
}

const mapStateToProps = (state) =>
  _pick(state, [
    'app',
    'entityService',
    'entitySearchService',
    'comparisonTimePeriod',
    'mainTimePeriod',
    'retailer',
    'categories',
    'filters',
    'allWeekIdsByRetailerId'
  ]);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics,
  clearEntitySearchService: entitySearchServiceOperations.clearEntitySearchService
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withBus('eventBus'))(WaterfallChart);
