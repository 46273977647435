import React from 'react';
import StyledDialog from 'src/components/BeaconRedesignComponents/common/StyledDialog/StyledDialog';
import { CloseIcon } from 'src/components/SvgIcons/SvgIcons';
import { DialogProps } from '@mui/material/Dialog';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { StacklineTextVariant, Text } from '@stackline/ui';
import ProfileSuccessIcon from './ProfileSuccessIcon';

export interface ProfileSuccessPopupProps extends DialogProps {
  icon?: React.ReactNode;
  message: string;
  width?: number;
  height?: number;
  titleMessage?: string;
  bodyTextVariant?: StacklineTextVariant;
}

/**
 * Generic dialog for showing small info popups on the Settings pages
 */
export default function ProfileInfoPopup({
  icon,
  message,
  width = 216,
  height = 218,
  titleMessage,
  bodyTextVariant = 'subtitle2',
  ...rest
}: ProfileSuccessPopupProps) {
  const closeDialog = (event: React.MouseEvent<unknown, MouseEvent>) => {
    if (rest.onClose) {
      rest.onClose(event, 'backdropClick');
    }
  };
  return (
    <StyledDialog height={height} width={width} padding="16px" {...rest}>
      <Flex flexDirection="column">
        <Flex justifyContent="flex-end" marginBottom="22px">
          <CloseIcon
            onClick={closeDialog}
            style={{
              cursor: 'pointer',
              width: '12px',
              height: '12px'
            }}
          />
        </Flex>
        <Flex flexDirection="column" gap="md" alignItems="center">
          {icon || <ProfileSuccessIcon />}

          <Flex flexDirection="column" gap="sm" alignItems="center">
            {titleMessage && (
              <Text variant="subtitle2" textAlign="center">
                {titleMessage}
              </Text>
            )}
            <Text variant={bodyTextVariant} textAlign="center">
              {message}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </StyledDialog>
  );
}
