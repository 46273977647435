import Creators from './actions';
import { parseAdEntities } from './selectors';
import { fetchUserMetadata } from 'src/utils/app';
import { adPlatformSettingsByClientOperations } from 'src/store/modules/adManager/adPlatformSettingsByClient';
import { getAdPlatformSettingsByClientOfType } from 'src/store/modules/adManager/adCampaignBuilder/selectors';
import { AD_PLATFORM_SETTING_TYPE_BY_CLIENT } from 'sl-ad-campaign-manager-data-model';
import { AppName } from 'sl-api-connector/types';
import ReduxStore from 'src/types/store/reduxStore';

export const { receiveAdEntities } = Creators;

export const fetchAdEntities =
  (fetchAdPlatformSettings = true) =>
  async (dispatch: (action: any) => void, getState: () => ReduxStore) => {
    const appName = getState().app.name;
    const retailerId = getState().retailer.id;
    if (appName === AppName.Advertising) {
      if (fetchAdPlatformSettings) {
        await dispatch(adPlatformSettingsByClientOperations.fetchAdPlatformSettingsByClient());
      }
      const adEntities = getAdPlatformSettingsByClientOfType(AD_PLATFORM_SETTING_TYPE_BY_CLIENT.ENTITY_ID)(getState());
      return dispatch(receiveAdEntities(parseAdEntities(adEntities, retailerId)));
    } else {
      const name = `${appName}-adEntity`;
      const response = await fetchUserMetadata(appName, name);
      const adEntities = response.data[0].documents;
      return dispatch(receiveAdEntities(parseAdEntities(adEntities, retailerId)));
    }
  };
