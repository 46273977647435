export enum ForecastPeriod {
  FULL_YEAR = 'fullYear',
  FIFTY_TWO_WEEKS = '52weeks',
  TWENTY_SIX_WEEKS = '26weeks',
  THIRTEEN_WEEKS = '13weeks',
  FOUR_WEEKS = '4weeks',
  CUSTOM = 'custom'
}

export enum ForecastComparisonPeriod {
  PRIOR_YEAR = 'prior-year',
  PRIOR_PERIOD = 'prior-period'
}

export enum ForecastType {
  ADJUSTED = 'adjusted',
  UNADJUSTED = 'unadjusted'
}

export enum AdjustmentPlanType {
  AllPlanTypes = 'AllPlanTypes',
  OrganicTraffic = 'OrganicTraffic',
  PaidTraffic = 'PaidTraffic',
  OtherTraffic = 'OtherTraffic',
  RetailPrice = 'RetailPrice',
  ContentScore = 'ContentScore',
  InstockRate = 'InstockRate',
  RatingsReviews = 'RatingsReviews',
  BuyBox = 'Buybox'
}
