import { Button, ButtonProps, SxProps } from '@mui/material';
import React, { CSSProperties, ForwardedRef } from 'react';
import { useStacklineTheme, StacklineTextVariant, StacklineColor, hexToRgba } from '@stackline/ui';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

export interface ActiveFilterDotProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  color?: StacklineColor | 'white';
  active?: boolean;
  styles?: CSSProperties;
}
export const ActiveFilterDot = ({
  width = '4px',
  height = '4px',
  borderRadius = '25px',
  color = 'primary',
  active = true,
  styles = {}
}: ActiveFilterDotProps) => {
  const theme = useStacklineTheme();

  return active ? (
    <div
      style={{
        width,
        height,
        border: `1px solid ${theme.colors[color]}`,
        backgroundColor: theme.colors[color],
        borderRadius,
        ...styles
      }}
    ></div>
  ) : null;
};

export type SlButtonVariant = 'normal' | 'title' | 'titleSmall' | 'contained';

export type SlButtonProps = Omit<ButtonProps, 'variant'> & {
  endIcon?: React.ReactNode;
  variant?: SlButtonVariant;
  active?: boolean;
  buttonSx?: SxProps;
  textSx?: SxProps;
  iconStyles?: CSSProperties;
};

/**
 * Common button for use through the Stackline website.
 */
export const SlButton = React.forwardRef(function SlButton(
  { children, endIcon, variant = 'normal', active = false, buttonSx = {}, textSx = {}, ...rest }: SlButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const theme = useStacklineTheme();

  const disabledTextProps: SxProps = {
    color: hexToRgba(theme.colors.secondary, 0.5)
  };

  const endIconSx: SxProps = {
    padding: '4px 4px 4px 12px'
  };

  const disabledSx: SxProps =
    variant !== 'title' && variant !== 'titleSmall'
      ? {
          backgroundColor: '#fff',
          border: `solid 1px ${theme.colors.primaryGray}`
        }
      : {};

  const stylesByVariant: { [key in SlButtonVariant]: SxProps } = {
    normal: {
      border: `solid 1px ${active ? theme.colors.primary : theme.colors.primaryGray}`,
      '&:hover': {
        backgroundColor: theme.colors.secondaryPorcelain
      },
      paddingLeft: active ? '9px' : undefined,
      paddingRight: active ? '6px' : undefined
    },
    title: {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    titleSmall: {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    contained: {
      backgroundColor: theme.colors.primary,
      border: `solid 1px ${theme.colors.primary}`,
      paddingX: '20px',
      '&:hover': {
        backgroundColor: theme.colors.primary
      }
    }
  };

  const textVariantByButtonVariant: { [key in SlButtonVariant]: StacklineTextVariant } = {
    normal: 'subtitle2',
    title: 'h4',
    titleSmall: 'subtitle1',
    contained: 'subtitle2'
  };

  return (
    <Button
      ref={ref}
      disableRipple
      {...rest}
      sx={{
        height: 32,
        textTransform: 'none',
        borderRadius: theme.spacing.xs,
        justifyContent: 'center',
        paddingX: '20px',
        ...(stylesByVariant[variant] as any),
        ...(rest.disabled ? disabledSx : {}),
        ...(endIcon ? endIconSx : {}),
        ...buttonSx
      }}
    >
      {active ? <ActiveFilterDot styles={{ marginRight: '8px' }} /> : null}
      <Text
        variant={textVariantByButtonVariant[variant]}
        color={variant === 'contained' && !rest.disabled ? 'white' : 'primary'}
        sx={{
          ...(rest.disabled ? disabledTextProps : {}),
          ...textSx
        }}
      >
        {children}
      </Text>
      {endIcon || null}
    </Button>
  );
});
