import { styled } from '@mui/material';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';

export const SaveButton = styled(LargeMuiButton)(() => ({
  height: '40px',
  color: 'white',
  lineHeight: 'normal',
  borderRadius: '23px',
  backgroundColor: '#052849',
  textTransform: 'none',
  width: '95%',
  ':hover': {
    backgroundColor: '#052849',
    opacity: '90%'
  }
}));

export const BulkUploadButton = styled(SaveButton)(() => ({
  backgroundColor: '#46a7f5',
  '&:hover': {
    backgroundColor: '#46a7f5',
    opacity: '90%'
  }
}));

export const ClearFiltersButton = styled(SaveButton)(() => ({
  color: '#052849',
  border: '1px solid #052849',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: '90%'
  }
}));
