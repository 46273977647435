import types, { AdCampaignType } from './types';
import actionCreators from './actions';
import { ValueOf } from 'sl-api-connector';
import { CAMPAIGN_TYPES } from 'src/store/modules/adManager/adCampaignBuilder/types';

// ------------------------------------
// Reducer
// ------------------------------------

export const adCampaignTypesAvailable: (AdCampaignType & {
  derivedCampaignType: 'sponsoredBrandsVideo' | any;
})[] = [
  {
    campaignType: CAMPAIGN_TYPES.SponsoredBrands,
    derivedCampaignType: CAMPAIGN_TYPES.SponsoredBrands,
    id: CAMPAIGN_TYPES.SponsoredBrands,
    name: 'Sponsored Brands',
    displayName: 'Sponsored Brands',
    shortDisplayName: 'SB',
    type: 'adCampaignType'
  },
  {
    campaignType: CAMPAIGN_TYPES.SponsoredDisplay,
    derivedCampaignType: CAMPAIGN_TYPES.SponsoredDisplay,
    id: CAMPAIGN_TYPES.SponsoredDisplay,
    name: 'Sponsored Display',
    displayName: 'Sponsored Display',
    shortDisplayName: 'SD',
    type: 'adCampaignType'
  },
  {
    campaignType: CAMPAIGN_TYPES.SponsoredProducts,
    derivedCampaignType: CAMPAIGN_TYPES.SponsoredProducts,
    id: CAMPAIGN_TYPES.SponsoredProducts,
    name: 'Sponsored Products',
    displayName: 'Sponsored Products',
    shortDisplayName: 'SP',
    type: 'adCampaignType'
  },
  {
    campaignType: CAMPAIGN_TYPES.SponsoredBrandsVideo,
    derivedCampaignType: CAMPAIGN_TYPES.SponsoredBrandsVideo,
    id: CAMPAIGN_TYPES.SponsoredBrandsVideo,
    name: 'Sponsored Brands Video',
    displayName: 'Sponsored Brands Video',
    shortDisplayName: 'SBV',
    type: 'adCampaignType'
  },
  {
    campaignType: CAMPAIGN_TYPES.SponsoredBrandAmplifier,
    derivedCampaignType: CAMPAIGN_TYPES.SponsoredBrandAmplifier,
    id: CAMPAIGN_TYPES.SponsoredBrandAmplifier,
    name: 'Sponsored Brands',
    displayName: 'Sponsored Brands',
    shortDisplayName: 'SBA',
    type: 'adCampaignType'
  },
  {
    campaignType: CAMPAIGN_TYPES.SponsoredWalmartVideo,
    derivedCampaignType: CAMPAIGN_TYPES.SponsoredWalmartVideo,
    id: CAMPAIGN_TYPES.SponsoredWalmartVideo,
    name: 'Sponsored Videos',
    displayName: 'Sponsored Videos',
    shortDisplayName: 'WSBV',
    type: 'adCampaignType'
  },
  {
    campaignType: CAMPAIGN_TYPES.openAuction,
    derivedCampaignType: CAMPAIGN_TYPES.openAuction,
    id: CAMPAIGN_TYPES.openAuction,
    name: 'Open Auction',
    displayName: 'Open Auction',
    shortDisplayName: 'OA',
    type: 'adCampaignType'
  }
];
type Action = ValueOf<{ [K in keyof typeof actionCreators]: ReturnType<(typeof actionCreators)[K]> }>;

export function adCampaignTypes(state = adCampaignTypesAvailable, action: Action) {
  switch (action.type) {
    case types.RECEIVE_AD_CAMPAIGN_TYPES:
      return action.adCampaignTypes;
    case types.CLEAR_AD_CAMPAIGN_TYPES:
      return [];
    default:
      return state;
  }
}
