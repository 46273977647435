import { Widget } from 'src/types/application/widgetTypes';
import SearchResultsGridV3 from './SearchResultsGridV3';
import React from 'react';
import useSrgData from './hooks/useSrgData';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import { Location } from 'history';
import { getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';
import { withProps } from 'src/utils/hoc';
import EntityColumn from 'src/components/AdManager/Search/CustomColumns/EntityColumn';
import useFetchRetailerData from 'src/components/AdManager/Search/hooks/useFetchRetailerData';

const getLinkOverride = ({ type, id, searchParams }) => {
  return `/${type}/${id}${searchParams}`;
};

const EnhanceEntityColumn = withProps({ getLinkOverride })(EntityColumn);

interface RetailersGridProps {
  widget: Widget;
  location: Location;
  queryParams: any;
}
/**
 * Display a list of Retailers. This can be found on the Drive landing page.
 */
const RetailersGrid: React.FC<RetailersGridProps> = ({ widget, location, queryParams }) => {
  const parentPlatform = getParentPlatform();
  let importantOverrides = [];

  if (parentPlatform === PARENT_PLATFORMS.CRITEO) {
    // for all retailers remove rid
    if (queryParams.rid === '0') {
      importantOverrides = [
        ...importantOverrides,
        {
          action: 'remove',
          path: ['aggregations', '[0]', 'conditions', 'termFilters'],
          conditionKey: 'fieldName',
          conditionValue: 'retailerId'
        },
        {
          action: 'remove',
          path: ['conditions', 'termFilters'],
          conditionKey: 'fieldName',
          conditionValue: 'retailerId'
        },
        {
          action: 'update',
          path: ['retailerId'],
          newObj: 999999
        }
      ];
    }
  }

  const fetchEntityData = useFetchRetailerData(importantOverrides);

  const srgData = useSrgData({
    widget,
    location,
    fetchEntityData,
    importantOverrides
  });

  return (
    <SearchResultsGridV3
      columnDefs={[
        {
          headerName: 'Retailer',
          field: 'retailerId',
          width: 100,
          enableRtl: true,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          minWidth: 300,
          maxWidth: 800,
          cellRendererFramework: EnhanceEntityColumn,
          headerClass: 'align-left',
          pinnedRowCellRenderer: 'simpleColumn',
          pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
          pinned: 'left'
        }
      ]}
      getRowNodeId={(row) => row.id}
      widget={widget}
      {...srgData}
    />
  );
};

export default withRouter(withBus('eventBus')(RetailersGrid));
