import React, { FC, useState, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

export interface KeywordSuggestionsMap {
  [key: string]: number;
}

interface KeywordProps {
  onSubmit: any;
  titleKeywordSuggestion: KeywordSuggestionsMap;
  bulletsKeywordSuggestion: KeywordSuggestionsMap;
  descriptionKeywordSuggestion: KeywordSuggestionsMap;
}

const sortKeywords = (keywordSuggestions: KeywordSuggestionsMap) => {
  return Object.entries(keywordSuggestions)
    .sort(([, volumeA], [, volumeB]) => volumeB - volumeA)
    .map(([searchTerm, searchVolume]) => ({
      searchTerm,
      searchVolume
    }));
};

const KeywordInputForm: FC<KeywordProps> = ({
  onSubmit,
  titleKeywordSuggestion,
  bulletsKeywordSuggestion,
  descriptionKeywordSuggestion
}) => {
  const [titleKeywords, setTitleKeywords] = useState('');
  const [descriptionKeywords, setDescriptionKeywords] = useState('');
  const [bulletKeywords, setBulletKeywords] = useState('');
  const [titleCount, setTitleCount] = useState(0);
  const [bulletCount, setBulletCount] = useState(0);
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [contentVoice, setContentVoice] = useState('');

  const sortedTitleKeywords = useMemo(() => sortKeywords(titleKeywordSuggestion), [titleKeywordSuggestion]);
  const sortedBulletsKeywords = useMemo(() => sortKeywords(bulletsKeywordSuggestion), [bulletsKeywordSuggestion]);
  const sortedDescriptionKeywords = useMemo(
    () => sortKeywords(descriptionKeywordSuggestion),
    [descriptionKeywordSuggestion]
  );

  const renderKeywordList = (sortedKeywords) => {
    return sortedKeywords.map(({ searchTerm, searchVolume }) => (
      <li key={searchTerm}>
        <strong>Search Term:</strong> {searchTerm}, <strong>Search Volume:</strong> {searchVolume}
      </li>
    ));
  };

  const handleSubmission = (e) => {
    e.preventDefault();
    onSubmit({
      titleKeywords: titleKeywords.split(',').map((keyword) => keyword.trim()),
      descriptionKeywords: descriptionKeywords.split(',').map((keyword) => keyword.trim()),
      bulletKeywords: bulletKeywords.split(',').map((keyword) => keyword.trim()),
      titleCount: Number(titleCount),
      bulletCount: Number(bulletCount),
      descriptionCount: Number(descriptionCount),
      contentVoice: contentVoice.toString()
    });
  };

  return (
    <div>
      <form className="keyword-input-form" onSubmit={handleSubmission}>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <h4 className="sl-header-text">Keywords Input</h4>
        </div>
        <hr className="sl-divider sl-divider--no-margin-top sl-divider--lg-margin-bottom" />
        <div style={{ marginLeft: '20px' }}>
          <Flex alignItems="center">
            <h4 className="sl-form-label">Title Keywords</h4>
            <TextField
              variant="standard"
              className="sl-form-input"
              placeholder="Enter Title Keyword"
              value={titleKeywords}
              style={{ marginLeft: '10px', width: '300px' }}
              onChange={(e) => setTitleKeywords(e.target.value)}
            />
            <h4 className="sl-form-label">Title Char Count</h4>
            <TextField
              variant="standard"
              className="sl-form-input"
              placeholder="Character Count Limit"
              type="number"
              value={titleCount}
              style={{ marginLeft: '10px' }}
              onChange={(e) => setTitleCount(e.target.value)}
            />
          </Flex>
          <Flex alignItems="center">
            <h4 className="sl-form-label">Description Keywords</h4>
            <TextField
              variant="standard"
              className="sl-form-input"
              placeholder="Enter Description Keywords"
              value={descriptionKeywords}
              style={{ marginLeft: '10px', width: '300px' }}
              onChange={(e) => setDescriptionKeywords(e.target.value)}
            />
            <h4 className="sl-form-label">Description Char Count</h4>
            <TextField
              variant="standard"
              className="sl-form-input"
              placeholder="Character Count Limit"
              type="number"
              value={descriptionCount}
              style={{ marginLeft: '10px' }}
              onChange={(e) => setDescriptionCount(e.target.value)}
            />
          </Flex>
          <Flex alignItems="center">
            <h4 className="sl-form-label">Bullet Keywords</h4>
            <TextField
              variant="standard"
              className="sl-form-input"
              placeholder="Enter Bullet Keywords"
              value={bulletKeywords}
              style={{ marginLeft: '10px', width: '300px' }}
              onChange={(e) => setBulletKeywords(e.target.value)}
            />
            <h4 className="sl-form-label">Bullet Count</h4>
            <TextField
              variant="standard"
              className="sl-form-input"
              placeholder="Character Count Limit"
              type="number"
              value={bulletCount}
              style={{ marginLeft: '10px' }}
              onChange={(e) => setBulletCount(e.target.value)}
            />
          </Flex>
          <Flex alignItems="center">
            <h4 className="sl-form-label">Content Voice</h4>
            <TextField
              variant="standard"
              className="sl-form-input"
              placeholder="Enter Content Voice"
              value={contentVoice}
              style={{ marginLeft: '10px', width: '300px' }}
              onChange={(e) => setContentVoice(e.target.value)}
            />
          </Flex>
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <LargeMuiButton label="Generate Content" type="submit" />
        </div>
        <div style={{ marginLeft: '20px' }}>
          <div>
            <h3>Title Keyword Suggestions</h3>
            {sortedTitleKeywords.length > 0 ? (
              <ul>{renderKeywordList(sortedTitleKeywords)}</ul>
            ) : (
              'No Keyword Suggestion available at this moment'
            )}
          </div>
          <div>
            <h3>Bullets Keyword Suggestions</h3>
            {sortedBulletsKeywords.length > 0 ? (
              <ul>{renderKeywordList(sortedBulletsKeywords)}</ul>
            ) : (
              'No Keyword Suggestion available at this moment'
            )}
          </div>
          <div>
            <h3>Description Keyword Suggestions</h3>
            {sortedDescriptionKeywords.length > 0 ? (
              <ul>{renderKeywordList(sortedDescriptionKeywords)}</ul>
            ) : (
              'No Keyword Suggestion available at this moment'
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default KeywordInputForm;
