/* eslint react/prop-types: 0 */
import React, { useState, useEffect, useCallback } from 'react';
import { AutoSizer, List as VirtualizedList, ListRowProps } from 'react-virtualized';
import TextField from '@mui/material/TextField';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from 'src/components/SvgIcons';
import CustomizedCheckbox from 'src/components/common/CustomizedCheckBox/CustomizedCheckBox';
import OmniClearButton from 'src/components/Omni/Search/OmniClearButton';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import _toLower from 'lodash/toLower';
import FormControlLabel from '@mui/material/FormControlLabel';

interface OmniSelectorProps {
  title: string;
  idKey: string;
  nameField: string;
  keyForSegment: string;
  shouldInclude: boolean;
  bothUncheck: boolean;
  hintText: string;
  include: string;
  exclude: string;
  showIncludeExclude: boolean;
  initialListData: { [key: string]: string | number | boolean }[];
  handleChange: (newChange: { key: string; values: { i: string }[] }) => void;
  handleIncludeExclude: (checkedType: string, checkedValue: boolean) => void;
  handleClearAll: () => void;
}

const OmniSelector: React.FC<OmniSelectorProps> = ({
  title,
  idKey,
  nameField,
  keyForSegment,
  handleChange,
  shouldInclude,
  bothUncheck,
  handleIncludeExclude,
  hintText,
  showIncludeExclude,
  include,
  exclude,
  initialListData,
  handleClearAll
}) => {
  const [listItemsToShow, setListItemsToShow] = useState(initialListData);

  const [inputValue, setInputValue] = useState('');
  const checkedData = listItemsToShow.filter((obj) => obj.isChecked);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const updateTheSelectorDisplay = useCallback(() => {
    const filteredData = initialListData.filter((item) =>
      _toLower(String(item[nameField])).includes(_toLower(inputValue))
    );

    const sortedData = _orderBy(filteredData, ['isChecked'], 'desc');
    setListItemsToShow(sortedData);
  }, [inputValue, nameField, initialListData]);

  useEffect(() => {
    updateTheSelectorDisplay();
  }, [updateTheSelectorDisplay]);

  const rowRenderer = ({ index, style }: ListRowProps) => {
    const listItem = listItemsToShow[index];
    const handleCheck = (_: React.SyntheticEvent, isInputChecked: boolean) => {
      const selectedObj = listItemsToShow[index];

      let valuesObj = initialListData.filter((o) => o.isChecked);
      if (isInputChecked) {
        valuesObj.push(selectedObj);
      } else {
        valuesObj = valuesObj.filter((o) => o[idKey] !== selectedObj[idKey]);
      }
      setInputValue('');
      handleChange({
        key: `${shouldInclude ? include : exclude}${keyForSegment}`,
        values: valuesObj.map((vObj) => ({
          i: vObj[idKey]
        }))
      });
    };

    return (
      <div key={listItem[nameField]} style={style}>
        <FormControlLabel
          control={
            <CustomizedCheckbox
              styleObj={{
                width: 24,
                height: 24,
                iconBackgroundColor: 'transparent',
                iconColor: 'transparent',
                checkedIconBackgroundColor: 'transparent',
                checkedIconColor: 'transparent'
              }}
              InnerIcon={CheckboxCheckedIcon}
              OuterIcon={CheckboxUncheckedIcon}
              checkboxProps={{
                checked: listItem.isChecked,
                onChange: (evt) => {
                  handleCheck(evt, evt.target.checked);
                }
              }}
            />
          }
          label={<span style={{ fontSize: '13px' }}>{(listItem[nameField] || '').substring(0, 30)}</span>}
        />
      </div>
    );
  };
  return (
    <div className="omni-search-form-container omni-search-form-container--sm">
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <h4 className="sl-form-label">{title}</h4>
        {showIncludeExclude && (
          <>
            <CustomizedCheckbox
              styleObj={{
                paddingRight: 0
              }}
              InnerIcon={DoneIcon}
              OuterIcon={DoneIcon}
              checkboxProps={{
                checked: bothUncheck ? false : shouldInclude,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  handleIncludeExclude('include', event.target.checked);
                }
              }}
            />
            <CustomizedCheckbox
              InnerIcon={CloseIcon}
              OuterIcon={CloseIcon}
              checkboxProps={{
                checked: bothUncheck ? false : !shouldInclude,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  handleIncludeExclude('exclude', event.target.checked);
                }
              }}
            />
          </>
        )}
        <OmniClearButton data={checkedData} handleClearAll={handleClearAll} isToggleGroup />
      </div>

      <TextField
        variant="standard"
        autoComplete="off"
        className="sl-form-input"
        placeholder={hintText}
        type="text"
        name={title}
        id={title}
        style={{ width: '100%' }}
        value={inputValue}
        onChange={handleInputChange}
      />
      {_isEmpty(listItemsToShow) ? (
        <div style={{ height: '175px' }}>No data to show</div>
      ) : (
        <AutoSizer disableHeight>
          {({ width }) => (
            <VirtualizedList
              width={width}
              height={listItemsToShow.length < 5 ? listItemsToShow.length * 45 : 175}
              rowCount={listItemsToShow.length}
              rowHeight={45}
              style={{ marginBottom: '10px' }}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
};

export default OmniSelector;
