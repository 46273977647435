/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import queryString from 'qs';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { AdManagerAdCampaignEntity } from 'sl-api-connector/types';

import { withPush } from 'src/utils/hoc';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import ReduxStore from 'src/types/store/reduxStore';
import { PushFn } from 'src/types/application/types';
import { WidgetProps } from 'src/types/application/widgetTypes';
import { getCampaignDisplayStatus } from 'src/components/AdManager/AmsUtils';
import { fetchData, buildScatterPlotDataKey, buildChartOptions, GraphMarker } from './ScatterPlotUtils';

interface SeriesOption {
  name: string;
  color: string;
  data: GraphMarker[];
}

const DEFAULT_SELECTED_ENTITY_NAME = 'campaignId';

const entityDisplayNameByGroupByFieldName = new Map(
  Object.entries({
    portfolioId: 'Portfolios',
    campaignId: 'Campaigns',
    entityId: 'Entities',
    stacklineSku: 'Products',
    searchKeyword: 'Keywords',
    campaignType: 'Campaign Types'
  })
);

const mapStateToProps = (state: ReduxStore) => {
  // TODO: Abstract
  const queryParams: { [queryParam: string]: any } = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: 100
  });
  const { selectedEntityName = DEFAULT_SELECTED_ENTITY_NAME, subtab } = queryParams;

  const dataKey = buildScatterPlotDataKey(subtab, selectedEntityName);

  return {
    ..._pick(state, ['adCampaigns', 'adEntities', 'adPortfolios', 'retailer', 'mainTimePeriod', 'app']),
    mainEntity: state.entityService.mainEntity,
    dataSet: state.entitySearchService[dataKey] as any as GraphMarker[]
  };
};

/**
 * This function is for when the current scatter plot is summarizing campaigns. It is used
 * when `selectedEntityName` is `'campaignId'`.
 *
 * This will probably be refactored when the API standardizes `extendedAttributes` and we
 * have more accurate status data.
 * @param markers the list of markers where the four subsets will come from.
 * @param adCampaigns the data used to classify the markers into four groups
 */
const dataSplit = (
  selectedEntityName: string,
  markers: GraphMarker[],
  adCampaigns: AdManagerAdCampaignEntity[],
  adPortfolios: any[]
): SeriesOption[] => {
  if (_isEmpty(markers)) {
    return [
      {
        name: entityDisplayNameByGroupByFieldName.get(selectedEntityName),
        color: 'rgba(71, 168, 246, .65)',
        // Highcharts does unspeakable things to this data, mutating the array and corrupting Redux state, unless
        // it is shallow-cloned like this
        data: []
      }
    ];
  }
  if (selectedEntityName === 'campaignId') {
    const adCampaignsById: Map<string, AdManagerAdCampaignEntity['extendedAttributes']> = adCampaigns.reduce(
      (acc, campaign) => {
        acc.set(campaign.campaignId, campaign.extendedAttributes);
        return acc;
      },
      new Map()
    );

    // a normal groupby won't suffice, we must manually group by from within a reduce....i'm so sorry
    const grouped = markers.reduce((acc, marker) => {
      const { displayName, color } = getCampaignDisplayStatus(adCampaignsById.get(marker.id)!);

      // We don't want any campaigns of these statuses to show up in the scatter plot chart.
      if (['Invalid', 'Draft', 'Archived', 'PendingStart'].includes(displayName)) {
        return acc;
      }

      return {
        ...acc,
        [displayName]: {
          name: displayName,
          color,
          data: acc[displayName] ? [...acc[displayName].data, marker] : [marker]
        }
      };
    }, {} as { [key: string]: { name: string; color: string; data: GraphMarker[] } });

    return Object.values(grouped) as SeriesOption[];
  } else if (selectedEntityName === 'portfolioId') {
    const adPortfoliosById: Map<string, AdManagerAdCampaignEntity['extendedAttributes']> = adPortfolios.reduce(
      (acc, portfolio) => {
        acc.set(portfolio.id, portfolio.extendedAttributes);
        return acc;
      },
      new Map()
    );

    // a normal groupby won't suffice, we must manually group by from within a reduce....i'm so sorry
    const grouped = markers.reduce((acc, marker) => {
      const { displayName, color } = getCampaignDisplayStatus(adPortfoliosById.get(marker.id)!);

      // We don't want any campaigns of these statuses to show up in the scatter plot chart.
      if (['Invalid', 'Draft', 'Archived', 'PendingStart'].includes(displayName)) {
        return acc;
      }

      return {
        ...acc,
        [displayName]: {
          name: displayName,
          color,
          data: acc[displayName] ? [...acc[displayName].data, marker] : [marker]
        }
      };
    }, {} as { [key: string]: { name: string; color: string; data: GraphMarker[] } });

    return Object.values(grouped) as SeriesOption[];
  }

  return [
    {
      name: entityDisplayNameByGroupByFieldName.get(selectedEntityName),
      color: 'rgba(71, 168, 246, .65)',
      // Highcharts does unspeakable things to this data, mutating the array and corrupting Redux state, unless
      // it is shallow-cloned like this
      data: markers.slice()
    }
  ];
};

/**
 * The most immediate wrapper around Highcharts that we use to render scatter plot charts.
 */
const ScatterPlotChart: React.FC<WidgetProps & ReturnType<typeof mapStateToProps> & { push: PushFn }> = ({
  mainEntity,
  retailer,
  app,
  mainTimePeriod,
  adCampaigns,
  adPortfolios,
  adEntities,
  dataSet,
  push
}) => {
  const [isLoading, setLoading] = useState(true);

  // TODO: Abstract
  const queryParams: { [queryParam: string]: any } = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: 100
  });

  // This functions as the group-by field for the scatter plot's API requests
  const { selectedEntityName = DEFAULT_SELECTED_ENTITY_NAME, subtab } = queryParams;

  useEffect(() => {
    const fetch = async () => {
      await fetchData(
        selectedEntityName,
        {
          mainEntity,
          retailer,
          app,
          mainTimePeriod,
          adCampaigns: adCampaigns!,
          adPortfolios: adPortfolios!,
          adEntities: adEntities!
        },
        subtab
      );
      setLoading(false);
    };
    setLoading(true);
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainTimePeriod, selectedEntityName, retailer.id]);

  if (isLoading) {
    return <GenericChartLoading />;
  }

  let dataToRander = dataSet;
  if (_isEmpty(dataSet)) {
    dataToRander = [];
  }
  // Data for manual group-by entities is pre-zipped due to their special case handling
  return (
    <div style={{ position: 'relative', paddingBottom: 60 }} className="ad-entity-scatter-plot">
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...buildChartOptions({ push }),
          series: dataSplit(selectedEntityName, dataToRander, adCampaigns, adPortfolios)
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps)(withPush(ScatterPlotChart));
