import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { StacklineTheme, useStacklineTheme } from '@stackline/ui';
import { useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import ReactDOMServer from 'react-dom/server';
import HoverTooltip from '../HoverTooltip/HoverTooltip';

HighchartsMore(Highcharts);

const XAxisLabel = ({ name }: { name: string }) => {
  // Split the name string at each '\n' and create an array
  const nameParts = name.split('\n');

  return (
    <div
      style={{
        maxWidth: '50px',
        lineHeight: 1.5
      }}
      role="button"
    >
      {nameParts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {index < nameParts.length - 1 && <br />} {/* Add <br /> except after the last part */}
        </React.Fragment>
      ))}
    </div>
  );
};

export interface WaterfallChartV2Datum {
  color?: string;
  displayName: string;
  y: number;
}

/**
 *
 */
const WaterfallChartV2 = ({ getData }: { getData: (theme: StacklineTheme) => WaterfallChartV2Datum[] }) => {
  const formatMetric = useMetricFormatter();
  const theme = useStacklineTheme();
  const data = getData(theme).map((datum) => ({
    ...datum,
    color: datum.color || (datum.y < 0 ? theme.colors.accentTangerine : theme.colors.success)
  }));

  const textStyles: Highcharts.CSSObject = {
    fontFamily: theme.text.fontFamily,
    color: theme.colors.primary,
    fontSize: '12px'
  };

  const options: Highcharts.Options = {
    chart: {
      type: 'waterfall',
      height: 500,
      spacingLeft: 0
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: true,
      shared: true,
      shadow: false,
      outside: true,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      useHTML: true,
      formatter() {
        return ReactDOMServer.renderToStaticMarkup(
          <HoverTooltip
            theme={theme}
            title={data[this.x].displayName}
            primaryColor={this.y < 0 ? theme.colors.accentTangerine : theme.colors.success}
            primaryMetric={`${this.y < 0 ? '-' : ''}${formatMetric(this.y, METRICTYPE.VOLUME, {
              showFullValue: false,
              decimalPlaces: 2
            })}`}
            tooltipWrapperStyle={{
              width: '108px',
              whiteSpace: 'normal'
            }}
          />
        );
      }
    },
    xAxis: [
      {
        type: 'category',
        min: 0,
        max: data.length - 1,
        tickLength: 0,
        lineColor: 'transparent',
        title: {
          text: ''
        },
        categories: Array.from({ length: data.length }, (_, i) => i),
        labels: {
          formatter() {
            return ReactDOMServer.renderToStaticMarkup(<XAxisLabel name={data[this.pos].displayName} />);
          },
          rotation: 0,
          step: 1,
          align: 'center',
          style: {
            ...textStyles,
            whiteSpace: 'normal',
            textOverflow: 'none',
            maxWidth: '50px',
            minWidth: '50px'
          }
        }
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: ''
      },
      labels: {
        formatter() {
          return `${formatMetric(this.value, METRICTYPE.VOLUME, {
            showFullValue: false,
            decimalPlaces: 2,
            volumeDecimalMin: 1e6
          })}`;
        },
        style: textStyles
      }
    },
    series: [
      {
        type: 'waterfall',
        data: data.map((datum, index) => ({
          ...datum,
          isSum: index === data.length - 1
        })),
        name: '',
        pointWidth: 16
      }
    ],
    plotOptions: {
      series: {
        borderColor: 'transparent'
      }
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default WaterfallChartV2;
