import { Box } from '@mui/system';
import { SlDropdownMenuOption } from '@stackline/ui';
import React, { Dispatch, SetStateAction } from 'react';
import { EntityRowContainer } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/EntityRowContainer';
import {
  PromotionAdjustmentTypeDropdown,
  PromotionAdjustmentToggle
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Components/RetailPricePromotionInputs';
import {
  FORM_FIELD,
  PlanTypeOption
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { AdjustmentConflictResponse } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useConflictingWeekIds';
import { AdjustmentForm } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import { ForecastsAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import CalendarRangePicker from 'src/components/BeaconRedesignComponents/common/CalendarRangePicker/CalendarRangePicker';
import StyledInput from 'src/components/BeaconRedesignComponents/common/StyledInput';
import useRemainingCharacters from 'src/components/BeaconRedesignComponents/hooks/useRemainingCharacters';
import { shouldShowPromotionsAdjustment } from 'src/utils/app';

const titleCharacterLimit = 50;
const descriptionCharacterLimit = 250;

interface InitializeAdjustmentProps {
  adjustmentData: ForecastsAdjustmentData;
  retailerSku: string;
  adjustmentForm: AdjustmentForm;
  handleFormUpdate: (target: FORM_FIELD, value: any) => void;
  options: SlDropdownMenuOption[];
  product: ForecastsAdjustmentData['product'];
  setError: Dispatch<SetStateAction<{ message: string }>>;
  conflictedDatesData: AdjustmentConflictResponse;
  conflictedDatesLoading: boolean;
}

/**
 * Stage 1 of 3 when creating an adjustment
 */
export const InitializeAdjustment = ({
  adjustmentForm,
  handleFormUpdate,
  options,
  product,
  conflictedDatesData,
  conflictedDatesLoading
}: InitializeAdjustmentProps) => {
  const { title, description, planType, startDate, endDate, promotionType, isPromotionAdjustment } = adjustmentForm;

  const renderPromotionInputs = planType === PlanTypeOption.RetailPrice && shouldShowPromotionsAdjustment();

  const remainingTitleCharacters = useRemainingCharacters(title, titleCharacterLimit);
  const remainingDescriptionCharacters = useRemainingCharacters(description, descriptionCharacterLimit);

  return (
    <Box>
      {/* Product */}
      <EntityRowContainer productEntity={product} />
      <Box height="170px">
        <Flex height="78px" gap="lg">
          <Flex width="160px" flexDirection="column" gap="sm">
            {/* Plan Type */}
            <Text variant="subtitle2" title="Plan Type">
              Plan Type
            </Text>
            <SlDropdownMenu
              stacklineButtonProps={{
                buttonSx: {
                  width: '152px',
                  justifyContent: 'space-between',
                  padding: '7px 0px 6px 8px'
                },
                textSx: { fontWeight: 'normal' }
              }}
              disabled={!!product}
              options={options}
              defaultLabel={options.find(({ id }) => id === planType).label}
              selectedId={planType}
              onChange={({ id }) => handleFormUpdate(FORM_FIELD.TYPE, id)}
            />
          </Flex>

          {renderPromotionInputs ? (
            <Flex gap={32} width="100%">
              <PromotionAdjustmentToggle onChange={handleFormUpdate} isPromotionAdjustment={isPromotionAdjustment} />
              <PromotionAdjustmentTypeDropdown onChange={handleFormUpdate} promotionType={promotionType} />
            </Flex>
          ) : (
            // Title
            <Flex width="100%" flexDirection="column" gap="sm">
              <Text variant="subtitle2" title="Title">
                Title
              </Text>
              <StyledInput
                value={title}
                onChange={(e) => {
                  handleFormUpdate(FORM_FIELD.TITLE, e.target.value.slice(0, titleCharacterLimit));
                }}
                width="100%"
                placeholder="Name your adjustment"
                sx={{ height: '32px' }}
              />
              <Flex justifyContent="flex-end">
                <Text variant="subtitle4" sx={{ fontSize: '10px' }}>
                  {remainingTitleCharacters} characters remaining
                </Text>
              </Flex>
            </Flex>
          )}

          {/* End of container */}
        </Flex>

        {/* Description */}
        <Flex gap={32} marginTop="15px">
          {renderPromotionInputs ? (
            <>
              <Flex width="312px" flexDirection="column" gap="sm">
                <Text variant="subtitle2" title="Title">
                  Title
                </Text>
                <StyledInput
                  value={title}
                  onChange={(e) => {
                    handleFormUpdate(FORM_FIELD.TITLE, e.target.value.slice(0, titleCharacterLimit));
                  }}
                  width="100%"
                  placeholder="Name your adjustment"
                  sx={{ height: '32px' }}
                />
                <Flex justifyContent="flex-end">
                  <Text variant="subtitle4" sx={{ fontSize: '10px' }}>
                    {remainingTitleCharacters} characters remaining
                  </Text>
                </Flex>
              </Flex>

              <Flex width="312px" flexDirection="column" gap="sm">
                <Text variant="subtitle2" title="Description">
                  Description
                </Text>
                <StyledInput
                  value={description}
                  onChange={(e) => {
                    handleFormUpdate(FORM_FIELD.DESCRIPTION, e.target.value.slice(0, descriptionCharacterLimit));
                  }}
                  width="100%"
                  placeholder="Enter a short description"
                  sx={{ height: '32px' }}
                />
                <Flex justifyContent="flex-end">
                  <Text variant="subtitle4" sx={{ fontSize: '10px' }}>
                    {remainingDescriptionCharacters} characters remaining
                  </Text>
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex width="100%" flexDirection="column" gap="sm">
              <Text variant="subtitle2" title="Description">
                Description
              </Text>
              <StyledInput
                value={description}
                onChange={(e) => {
                  handleFormUpdate(FORM_FIELD.DESCRIPTION, e.target.value.slice(0, descriptionCharacterLimit));
                }}
                width="100%"
                placeholder="Enter a short description"
                sx={{ height: '32px' }}
              />
              <Flex justifyContent="flex-end">
                <Text variant="subtitle4" sx={{ fontSize: '10px' }}>
                  {remainingDescriptionCharacters} characters remaining
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Box>

      {/* Date Range */}
      <Flex flexDirection="column" gap="sm" marginTop="17px">
        <Text variant="subtitle2" title="Date Range">
          Date Range
        </Text>
        <CalendarRangePicker
          disabled={conflictedDatesLoading}
          planStartDate={startDate}
          planEndDate={endDate}
          allConflictingWeekIds={
            conflictedDatesLoading
              ? null
              : conflictedDatesData && conflictedDatesData.conflictedAdjustmentIdsAndWeeks !== null
              ? conflictedDatesData.conflictedAdjustmentIdsAndWeeks.map((adjustmentIdAndWeekIdString) => {
                  // For some reason the conflicted week IDs come appended to the adjustment ID as a singular string.
                  // We only take the week ID for use in the calendar.
                  return adjustmentIdAndWeekIdString.slice(-6);
                })
              : []
          }
          handleDateChange={(selectedDates) => {
            const [startDateWeekId, endDateWeekId] = selectedDates;
            if (startDateWeekId) {
              handleFormUpdate(FORM_FIELD.START_DATE, startDateWeekId);
            }
            if (endDateWeekId) {
              handleFormUpdate(FORM_FIELD.END_DATE, endDateWeekId);
            }
          }}
        />
      </Flex>
    </Box>
  );
};
