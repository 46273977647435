import types from './types';

export const receiveOmniChartsServiceDataForMain = (entity: any) => ({
  type: types.RECEIVE_CHART_DATA_FOR_MAIN,
  entity
});

export const receiveOmniChartsServiceDataForCompare = (entity: any) => ({
  type: types.RECEIVE_CHART_DATA_FOR_COMPARE,
  entity
});

export const receiveAllOmniChartServiceData = (entity: any) => ({
  type: types.RECEIVE_CHART_DATA_FOR_BOTH,
  entity
});

export const startFetchChartData = (entity: any) => ({
  type: types.START_FETCH,
  entity
});

export const endFetchData = (entity: any) => ({
  type: types.END_FETCH,
  entity
});

export const deleteKey = (key: string) => ({
  type: types.DELETE_DATA_KEY,
  entity: {
    chartName: key,
    data: []
  }
});
