/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import _get from 'lodash/get';
import Tabs from 'src/components/common/Tabs/Tabs';
import BusinessUnitListing from 'src/routes/HomePage/SearchList/BusinessUnitListing';
import BusinessUnitBuilderDialog from 'src/components/BusinessUnitBuilder/BusinessUnitBuilderDialog';
import { CreateNewSegmentIcon } from '../SubHeader/CreateNewSavedSearchButton';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';

const tabs = [{ displayName: 'Business Units' }, { displayName: 'Segments' }];

const SegmentAndBUListing: React.FC<{ HydratedSavedSearchLists: Function }> = ({ HydratedSavedSearchLists }) => {
  const [selectedSegmentTabIndex, setSelectedSegmentTabIndex] = useState(1);
  const [buBuilderDialogOpen, setBUBuilderDialogOpen] = useState(false);

  const { user: userFromRedux } = useSelector(({ user }: ReduxStore) => ({ user }));

  return (
    <div style={{ marginTop: 40, padding: '0px 30px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <h1>Segments + Business Units Listing</h1>
        {_get(userFromRedux, ['config', 'isStacklineUser'], false) ||
        _get(userFromRedux, ['config', 'profile', 'email'], '').endsWith('piwheel.com') ? (
          <CreateNewSegmentIcon style={{ cursor: 'pointer' }} onClick={() => setBUBuilderDialogOpen(true)} />
        ) : null}
      </div>

      <Tabs
        tabs={tabs}
        value={selectedSegmentTabIndex}
        onTabChange={(_event, newTabIx) => setSelectedSegmentTabIndex(newTabIx)}
      />

      {selectedSegmentTabIndex === 1 ? HydratedSavedSearchLists() : <BusinessUnitListing />}

      <BusinessUnitBuilderDialog open={buBuilderDialogOpen} onClose={() => setBUBuilderDialogOpen(false)} />
    </div>
  );
};

export default SegmentAndBUListing;
