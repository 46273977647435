import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';

import colors from 'src/utils/colors';

const styles = () => ({
  root: {
    width: '305px',
    backgroundColor: '#637186',
    borderRadius: '4px'
  },
  cssLabel: {
    fontSize: '16px',
    color: colors.darkBlue,
    '&$cssFocused': {
      color: colors.darkBlue
    }
  },
  cssFocused: {},

  textField: {
    borderRadius: '6px',
    border: 0,
    background: 'none',
    '&:hover': {
      background: 'none'
    },
    '&:before': {
      content: 'none'
    },
    '&:after': {
      content: 'none'
    }
  }
});

class SignUpEmail extends React.Component {
  static defaultProps = {
    className: ''
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    defaultValue: PropTypes.string.isRequired
  };

  render() {
    const { classes, defaultValue } = this.props;
    return (
      <TextField
        variant="filled"
        type="text"
        label="Email"
        disabled
        defaultValue={defaultValue}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onChange={this.handleEmailChange}
        classes={{
          root: classes.root
        }}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.cssLabel,
            filled: classes.cssFocused
          }
        }}
        InputProps={{
          disabled: true,
          className: 'signin-email',
          classes: {
            underline: classes.textField
          }
        }}
      />
    );
  }
}

export default withStyles(styles)(SignUpEmail);
