import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import ScheduledActionText from './ScheduledActionText';

/**
 * Wrapper around a MUI TextField that will show Typography with the
 * given `value` when the input is disabled
 */
const ScheduledActionTextField: React.FC<TextFieldProps> = (props) => {
  return props.disabled ? <ScheduledActionText>{props.value as string}</ScheduledActionText> : <TextField {...props} />;
};

export default ScheduledActionTextField;
