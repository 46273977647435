import axios from 'axios';

export default async function uploadUserImage(file: File, userEmail: string) {
  const response = await axios.post<{ url: string; status: number }[]>('/api/userprofiledocument/UploadUserDocuments', [
    {
      userEmail,
      documentType: 'ProfileImage'
    }
  ]);

  const { url } = response.data[0];
  await axios.put(url, file);
}
