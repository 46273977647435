/* eslint react/prop-types: 0 */
import React from 'react';
import TextField from '@mui/material/TextField';

interface OmniSegmentNameFieldProps {
  value: string;
  handleChangeInputName: (inputValue: string) => void;
  errorText: string;
  hintText: string;
}

const OmniSegmentNameField: React.FC<OmniSegmentNameFieldProps> = ({
  value,
  handleChangeInputName,
  errorText,
  hintText
}) => {
  return (
    <div className="omni-search-form-container" style={{ marginBottom: '5px' }}>
      <h4 className="sl-form-label">Name</h4>
      <TextField
        variant="standard"
        autoComplete="off"
        className="sl-form-input"
        placeholder={hintText}
        helperText={errorText}
        type="text"
        name="segment"
        id="segment"
        style={{ width: '100%' }}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChangeInputName(e.target.value);
        }}
      />
    </div>
  );
};

export default OmniSegmentNameField;
