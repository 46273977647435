import axios from 'axios';
import { ParsedUserDatum } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Team/BulkUploadUserModal';

/**
 * Creates multiple users in the current division
 */
export default function CreateMultipleUsersInCurrentDivision(users: ParsedUserDatum[], currentUserEmail: string) {
  return axios.post('api/user/CreateMultipleUsersInCurrentDivision', users, {
    params: {
      activeUserEmail: currentUserEmail
    }
  });
}
