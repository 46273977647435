import React, { useState } from 'react';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import { useCreateRemoveRetailPriceRangeFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { useConvertPaidTrafficFieldName } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Traffic/PaidSearch/paidSearchUtil';
import { useAppSelector } from 'src/utils/Hooks';

/**
 * Shows ad trend chart with a dropdown
 */
const AdvertisingMultiMetricTrendChart = () => {
  const [fieldName, setFieldName] = useState('spend');
  // TODO: Use agg-traffic index when available for topics
  const mainEntityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const { convertedBuilderFunc } = useConvertPaidTrafficFieldName();
  const removeRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();

  return (
    <BeaconComparableSplineChart
      indexName={mainEntityType === 'searchtermlist' ? 'advertising' : 'ad-product-metrics'}
      fieldName={fieldName}
      singleDropdownProps={{
        defaultLabel: '',
        options: [
          {
            id: 'spend',
            label: 'Ad Spend'
          },
          {
            id: 'clicks',
            label: 'Ad Clicks'
          },
          {
            id: 'impressions',
            label: 'Impressions'
          },
          {
            id: 'clickThroughRate',
            label: 'CTR %'
          },
          {
            id: 'costPerClick',
            label: 'CPC'
          },
          {
            id: 'costPerMilli',
            label: 'CPM'
          },
          {
            id: 'costPerAcquisition',
            label: 'CPA'
          },
          {
            id: 'sales',
            label: 'Ad Sales'
          },
          {
            id: 'conversionRate',
            label: 'Conversion Rate'
          },
          {
            id: 'unitsSold',
            label: 'Ad Units Sold'
          },
          {
            id: 'returnOnAdSpend',
            label: 'ROAS'
          }
        ],
        selectedId: fieldName,
        onChange: (option) => setFieldName(option.id)
      }}
      requestBuilder={(builder) => convertedBuilderFunc(builder.apply(removeRetailPriceRangeFilters()))}
    />
  );
};

export default AdvertisingMultiMetricTrendChart;
