import { Parser } from 'expr-eval';

import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

export const COMPLIANCE_KEYWORDAD_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  authorized: {
    name: 'authorized',
    displayName: 'Compliant',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  authorizedCount: {
    name: 'authorizedCount',
    displayName: 'Compliant',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  unauthorized: {
    name: 'unauthorized',
    displayName: 'Violations',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  unauthorizedCount: {
    name: 'unauthorizedCount',
    displayName: 'Violations',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  keyword: {
    name: 'keyword',
    displayName: 'Total Keywords',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'value_count'
  },
  compliantKeywordCount: {
    name: 'compliantKeywordCount',
    displayName: 'Compliant Keywords',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('keyword - unauthorized')
  },
  compliantKeywordPercent: {
    name: 'compliantKeywordPercent',
    displayName: 'Compliant Keywords',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('keyword>0?(keyword - unauthorized)/keyword:1')
  },
  KeywordText: {
    name: 'KeywordText',
    displayName: 'Keyword',
    entity: {
      type: 'KeywordText'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.KEYFIELD,
    aggregationFunction: 'cardinality'
  }
};

export const COMPLIANCE_KEYWORDADITEM_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  authorized: {
    name: 'authorized',
    displayName: 'Compliant',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  unauthorized: {
    name: 'unauthorized',
    displayName: 'Violations',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  authorizedKeywordAdPercent: {
    name: 'authorizedKeywordAdPercent',
    displayName: 'Compliant Ads',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('(authorized+unauthorized)>0?authorized/(authorized+unauthorized):1')
  },
  KeywordText: {
    name: 'KeywordText',
    displayName: 'Keyword',
    entity: {
      type: 'KeywordText'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.KEYFIELD,
    aggregationFunction: 'cardinality'
  }
};

export const COMPLIANCE_SELLER_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  authorized: {
    name: 'authorized',
    displayName: 'Compliant',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  unauthorized: {
    name: 'unauthorized',
    displayName: 'Violations',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  authorizedSellerPercent: {
    name: 'authorizedSellerPercent',
    displayName: 'Compliant Sellers',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('(authorized+unauthorized)>0?authorized/(authorized+unauthorized):1')
  },
  merchantName: {
    name: 'merchantName',
    displayName: 'Seller Name',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'value_count',
    entity: {
      type: 'seller'
    }
  }
};

export const COMPLIANCE_CATALOGPRICE_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  authorized: {
    name: 'authorized',
    displayName: 'Authorized',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  unauthorized: {
    name: 'unauthorized',
    displayName: 'Unauthorized',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  authorizedCatalogPercent: {
    name: 'authorizedCatalogPercent',
    displayName: 'Products Authorized',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('(authorized+unauthorized)>0?authorized/(authorized+unauthorized):1')
  },
  mapRetailPrice: {
    name: 'retailPrice',
    displayName: 'Retail Price',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'avg'
  },
  mapPriceCompliant: {
    name: 'mapPriceCompliant',
    displayName: 'Compliant',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  mapPriceNonCompliant: {
    name: 'mapPriceNonCompliant',
    displayName: 'Variations',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  compliantPricesPercent: {
    name: 'compliantPricesPercent',
    displayName: 'Compliant Prices',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      '(mapPriceCompliant+mapPriceNonCompliant)>0?mapPriceCompliant/(mapPriceCompliant+mapPriceNonCompliant):1'
    )
  }
};
