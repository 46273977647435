import { Option } from 'funfix-core';
import { Point, Series } from 'highcharts';
import _constant from 'lodash/constant';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _times from 'lodash/times';
import { Entity, ValueOf } from 'sl-api-connector/types';
import { MetricField } from 'src/types/application/types';
import ReduxStore from 'src/types/store/reduxStore';
import { isDrive } from 'src/utils/app';
import { COMMON_ENTITIES, DATATYPE } from 'src/utils/entityDefinitions';
import { prop, tryGetMulti } from 'src/utils/fp';
import { getRetailerIdDisplayName, quoteWrap } from 'src/utils/stringFormatting';

const getColumnName = (seriesElement: Point) => tryGetMulti(seriesElement, ['fieldValue', '[0]'], '');

export const mkFormatValue =
  (dataTypes: (ValueOf<typeof DATATYPE> | null | undefined | 'PRECISE_DECIMAL')[], noFormatFirstColumn = true) =>
  (val: string | number, colIx: number) => {
    // Don't mess with first column which is the name/id row
    if (colIx === 0 && noFormatFirstColumn) {
      return quoteWrap(val);
    } else if (_isNil(val)) {
      return '';
    }

    const dataType = dataTypes[colIx - (noFormatFirstColumn ? 1 : 0)];
    // If the data type should be integers, strip off decimals
    if ((['INTEGER', 'VOLUME'] as (string | null | undefined)[]).includes(dataType)) {
      const numericVal = +val;
      if (Number.isNaN(numericVal)) {
        return quoteWrap(val);
      }

      return +numericVal.toFixed(0);
    } else if (dataType === 'DECIMAL') {
      const numericVal = +val;
      if (Number.isNaN(numericVal)) {
        return quoteWrap(val);
      }

      return +numericVal.toFixed(2);
    } else if (dataType === 'PRECISE_DECIMAL') {
      // `PRECISE_DECIMAL` isn't a real `DATA_TYPE`; it's only used in this file to support more decimals in
      // market share exports.

      const numericVal = +val;
      if (Number.isNaN(numericVal)) {
        return quoteWrap(val);
      }

      return +numericVal.toFixed(4);
    }

    return quoteWrap(val);
  };

/**
 * Produces a CSV containing the data from a HighCharts bar chart, handling filling in missing data with placeholders
 * and returning the result as a string.  It is generic, handling series data that is in the format of
 * `["columnName", value]` as well as `{fieldValue: "columnName", y: value}`.
 *
 * @param {array} chartSeries The array of all HighCharts series from the chart
 */
const convertBarChartSeriesToDelimitedData = (
  chartSeries: (Series & { timePeriod?: string; categories?: Entity[]; metricField?: MetricField })[],
  retailer: ReduxStore['retailer']
) => {
  let seriesNames = chartSeries.map((series) => {
    const timePeriodString = series.timePeriod ? `: ${series.timePeriod}` : '';
    return `${series.name}${timePeriodString}`;
  });

  const entityName: string | null = Option.of(_get(chartSeries, '[0].categories[0].type'))
    .flatMap((entityType: string) => Option.of(COMMON_ENTITIES[entityType]))
    .flatMap(({ displayName }) => Option.of(displayName))
    .orNull();

  const OmniEntityName: string | null = _get(chartSeries, '[0].metricField.displayName');

  // Omni Content Score is an anti-pattern for bar chart.
  let OmniContentScoreField: string | null = null;

  if (_get(chartSeries, '[0].data[0].fieldId') === 'Title Score' && seriesNames.includes('undefined')) {
    seriesNames = ['Score'];
    OmniContentScoreField = _get(chartSeries, '[0].data[0].fieldId');
  }

  const headers = [entityName || OmniEntityName || OmniContentScoreField || 'Entity', ...seriesNames];

  const createNewCombinedDatum = () => _times(chartSeries.length, _constant(''));

  // Build up a mapping of column name to an array of all of the series values for that column
  const dataByColumn = chartSeries.map(prop('data')).reduce(
    (columnNameMap, points, seriesIndex) =>
      points.reduce((acc, point) => {
        const columnName = getColumnName(point);
        const columnDatum = acc[columnName] || createNewCombinedDatum();
        columnDatum[seriesIndex] = tryGetMulti(point, ['y', '[1]'], '');
        return { ...acc, [columnName]: columnDatum };
      }, columnNameMap),
    {} as { [key: string]: string[] }
  );

  const dataRows = Object.entries(dataByColumn).map(([columnName, values]) => {
    if (entityName) {
      columnName = getRetailerIdDisplayName(columnName, retailer);
    }
    return [columnName, ...values];
  });

  const rowStrings = [headers, ...dataRows].map((row) => {
    const dataTypes: (ValueOf<typeof DATATYPE> | null | undefined | 'PRECISE_DECIMAL')[] = chartSeries.map((series) => {
      const isPreciseDecimal = ['marketshare', 'shareofshelf'].some((value) =>
        _get(series, ['metricField', 'name'], '').toLowerCase().includes(value)
      );
      let dataType = _get(series, ['metricField', 'dataType']);

      if (isDrive && dataType === DATATYPE.INTEGER) {
        dataType = DATATYPE.DECIMAL;
      }

      return isPreciseDecimal ? ('PRECISE_DECIMAL' as const) : dataType;
    });

    return row.map(mkFormatValue(dataTypes)).join(',');
  });

  return `${rowStrings.join('\n')}\n`;
};

export default convertBarChartSeriesToDelimitedData;
