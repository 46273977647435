import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import ControlButtons from 'src/components/common/DatePicker/ControlButtons';
import ReduxStore from 'src/types/store/reduxStore';
import { convertDayToString } from 'src/utils/dateUtils';
import { DoubleArrow, SingleArrow } from '../../SvgIcons/index';
import './CalendarDisplay.scss';
import { CALENDAR_PADDING } from './constants';

const propTypes = {
  handleCustomDateRangeChange: PropTypes.func.isRequired,
  handleOutsideClick: PropTypes.func.isRequired,
  maxDate: PropTypes.object.isRequired,
  minDate: PropTypes.object.isRequired,
  onClickDateHandler: PropTypes.func
};
const defaultProps = {
  onClickDateHandler: null
};

const styles = {
  chevronIconBase: {
    stroke: 'black',
    height: '15px',
    width: '15px'
  },
  root: {
    position: 'fixed',
    display: 'inline-block',
    zIndex: '1',
    right: '180px',
    top: '70px',
    padding: `${CALENDAR_PADDING}px`,
    backgroundColor: 'white',
    boxShadow: '0 2px 6px rgb(0 0 0 / 5%), 0 0 0 1px rgb(0 0 0 / 7%)',
    borderRadius: '3px'
  },
  calendarHeaderSvg: {
    height: '10px',
    width: '20px',
    zIndex: 2,
    position: 'absolute',
    left: '400px',
    top: '-10px'
  },
  yearButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '30px'
  },
  doubleChevron: {}
};

interface CalendarDisplayProps {
  minDate: moment.Moment;
  maxDate: moment.Moment;
  handleOutsideClick: any;
  handleCustomDateRangeChange: ({ startDate, endDate }: { startDate: string; endDate: string }) => void;
  /**
   * Accepts a start date and returns the start and end date that should be set.
   * Used for when clicking on one date should automatically select an entire month, week, etc.
   *
   * @param date The date that was clicked
   * @returns An array of 2 dates where the first is the start date and the second is the end date.
   */
  onClickDateHandler?: (date: Date) => [Date, Date];
  rootStyles?: React.CSSProperties;
  arrowStyles?: React.CSSProperties;
}

const CalendarDisplay = ({
  handleCustomDateRangeChange,
  minDate,
  maxDate,
  handleOutsideClick,
  onClickDateHandler,
  rootStyles = {},
  arrowStyles = {}
}: CalendarDisplayProps) => {
  /**
   * Used to get the width of the picker so that we are
   * not hardcoding pixel values.
   */
  const calendarRef = useRef<HTMLDivElement>(null);
  const mainTimePeriod = useSelector(({ mainTimePeriod: mtp }: ReduxStore) => mtp);
  const [calendarWidth, setCalendarWidth] = useState(0);

  const [startDate, setStartDate] = useState<Date | null>(
    mainTimePeriod.id === 'cd' ? moment(mainTimePeriod.startWeekStartDate).toDate() : null || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    mainTimePeriod.id === 'cd' ? moment(mainTimePeriod.endWeekEndDate).toDate() : null || null
  );

  const onChange = (dates: [Date, Date]) => {
    const [start, end] = onClickDateHandler ? onClickDateHandler(dates[0]) : dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    setCalendarWidth(calendarRef.current && calendarRef.current.clientWidth);
  }, []);

  return (
    <div style={{ ...styles.root, ...rootStyles }} ref={calendarRef}>
      <svg style={{ ...styles.calendarHeaderSvg, ...arrowStyles }} role="presentation" focusable="false">
        <path d="M0,10 20,10 10,0z" />
        <path style={{ fill: 'white', stroke: '#dbdbdb' }} d="M0,10 10,0 20,10" />
      </svg>
      <DatePicker
        dateFormat="MM-DD-YYYY"
        renderCustomHeader={({
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
          prevYearButtonDisabled,
          nextYearButtonDisabled,
          increaseYear,
          decreaseYear
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <button
              aria-label="Previous Year"
              className="react-datepicker__navigation react-datepicker__navigation--previous"
              style={customHeaderCount === 1 ? { visibility: 'hidden' } : undefined}
              onClick={decreaseYear}
              disabled={prevYearButtonDisabled}
            >
              <DoubleArrow
                style={{
                  ...styles.chevronIconBase,
                  ...styles.doubleChevron,
                  transform: 'rotate(90deg)'
                }}
              />
            </button>

            <button
              aria-label="Previous Month"
              className="react-datepicker__navigation react-datepicker__navigation--previous"
              style={customHeaderCount === 1 ? { visibility: 'hidden' } : { left: '20px' }}
              onClick={decreaseMonth}
            >
              <SingleArrow
                style={{
                  ...styles.chevronIconBase,
                  transform: 'rotate(90deg)'
                }}
              />
            </button>

            <span className="react-datepicker__current-month">{moment(monthDate).format('MMMM YYYY')}</span>

            <button
              aria-label="Next Month"
              className="react-datepicker__navigation react-datepicker__navigation--next"
              style={customHeaderCount === 0 ? { visibility: 'hidden' } : { right: '20px' }}
              onClick={increaseMonth}
            >
              <SingleArrow
                style={{
                  ...styles.chevronIconBase,
                  transform: 'rotate(-90deg)'
                }}
              />
            </button>

            <button
              aria-label="Next Year"
              className="react-datepicker__navigation react-datepicker__navigation--next"
              style={customHeaderCount === 0 ? { visibility: 'hidden' } : undefined}
              onClick={increaseYear}
              disabled={nextYearButtonDisabled}
            >
              <DoubleArrow
                style={{
                  ...styles.chevronIconBase,
                  ...styles.doubleChevron,
                  transform: 'rotate(-90deg)'
                }}
              />
            </button>
          </div>
        )}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        monthsShown={2}
        minDate={minDate.toDate()}
        maxDate={maxDate.toDate()}
        inline
        selectsRange
        disabledKeyboardNavigation
        onClickOutside={handleOutsideClick}
      >
        <ControlButtons
          width={calendarWidth}
          startDate={startDate}
          endDate={endDate}
          save={() => {
            if (startDate && endDate) {
              const startStr = convertDayToString(startDate);
              const endStr = convertDayToString(endDate);

              // perhaps we should always use PST for standard behavior?
              // we should pass a date string from here
              handleCustomDateRangeChange({ startDate: startStr, endDate: endStr });
            }
          }}
          clear={() => {
            setStartDate(null);
            setEndDate(null);
          }}
        />
      </DatePicker>
    </div>
  );
};

CalendarDisplay.propTypes = propTypes;
CalendarDisplay.defaultProps = defaultProps;

export default CalendarDisplay;
