/* eslint-disable react/prop-types */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import { mergeConditions } from 'sl-api-connector/search/conditions';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import _unionWith from 'lodash/unionWith';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import queryString from 'qs';
import Highcharts from 'highcharts/highstock';
import Tooltip from '@mui/material/Tooltip';

import GenericChart from 'src/components/Charts/GenericChart';
import {
  AtlasSummaryMainTrendLoading,
  AtlasSummarySubTrendLoading
} from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations'; // rendering
import {
  buildDefaultConditions,
  buildEntityConditions,
  buildMainEntityConditions,
  buildEntityMarketShareNumeratorConditions,
  buildAggregationConditions,
  buildComparisonEntityConditions,
  buildEntityMarketShareConditions,
  getCommonSummaryTrendParameters
} from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { getTrendChartInfo } from 'src/components/EntityPage/summaryTrendUtil';
import BaseTrendChart from 'src/components/EntityPage/TrendChart/BaseTrendChart';
import { panic } from 'src/utils/mixpanel';
import MarketShareTooltip from 'src/components/Layout/util/MarketShareTooltip';
import { buildAggregations } from 'src/components/AdManager/Search';
import { getQueryParamValue, shouldShowNewBeacon } from 'src/utils/app';
import MetricSummaryTrendCard from 'src/components/BeaconRedesignComponents/MetricSummaryTrendCard/MetricSummaryTrendCard';
import { store } from 'src/main';
import { receiveEntitySalesMetrics } from 'src/store/modules/entitySearchService/operations';

const buildFillColor = (color) => ({
  linearGradient: {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0.9
  },
  stops: [
    [0, Highcharts.Color(color).setOpacity(0).get('rgba')],
    [1, Highcharts.Color(color).setOpacity(0).get('rgba')]
  ]
});

const fillColorForSeries = (series) => {
  if (series[0]) {
    series[0].fillColor = buildFillColor('#195AA2');
  }
  if (series[1]) {
    series[1].fillColor = buildFillColor('#46a8f6');
  }
  return series;
};

const SubTrendLegend = ({
  legendTitle,
  legendValue,
  legendChange,
  legendAbsoluteChange,
  legendClassName,
  showMarketShareTooltip,
  isLoading
}) => {
  if (isLoading) {
    return null;
  }

  const title = <div className="summary_subtrend_title">{legendTitle}</div>;

  return (
    <div className="summary_trend_legend">
      {showMarketShareTooltip ? <MarketShareTooltip>{title}</MarketShareTooltip> : title}

      <div className="summary_legend_info">
        <span>{`${legendValue} `}</span>
        <span>
          <Tooltip title={legendAbsoluteChange} placement="top">
            <span className={legendClassName}>{legendChange}</span>
          </Tooltip>
        </span>
      </div>
    </div>
  );
};

class CommonSummaryTrend extends BaseTrendChart {
  fetchData = (props) => {
    const {
      app,
      aggregationConditions: propAggregationConditions,
      retailer,
      categories,
      subCategories,
      segments,
      queryParams,
      allWeekIdsByRetailerId,
      mainTimePeriod,
      comparisonTimePeriod,
      conditions,
      match,
      comparisonConfig,
      entityService,
      fetchEntityMetrics,
      widget,
      filters,
      user
    } = props || this.props;
    const { mainEntity, comparisonEntity } = entityService;
    if (!mainEntity) {
      return;
    }
    let { id: idFromUrl } = match.params;
    if (!idFromUrl || idFromUrl === '') {
      idFromUrl = `${mainEntity.id}`;
    }
    // the main entity has to match with what is in the current url
    if (idFromUrl !== `${mainEntity.id}` && idFromUrl !== mainEntity.hashId) {
      return;
    }
    this.setState({ isLoading: true });

    // We must have segments loaded in order to build conditions for segments.  If we don't have them and need them, try
    // fetching again in a bit.
    if (
      ['segment', 'searchtermlist', 'businessunit', 'businessUnit'].includes(mainEntity.type) &&
      !segments.savedSearchesById
    ) {
      setTimeout(() => this.fetchData(props), 100);
      return;
    }

    const isMarketShareChart = widget.data.marketShare && widget.data.marketShare.compute;

    const defaultConditions = buildDefaultConditions(conditions, queryParams);
    const entityConditions = buildEntityConditions(app, categories, defaultConditions, mainEntity);
    let mainEntityConditions = buildMainEntityConditions(entityConditions, mainEntity, app, retailer, queryParams);

    if (widget.data.additionalQueryConditions) {
      mainEntityConditions = mergeConditions(mainEntityConditions, widget.data.additionalQueryConditions);
    }
    // If we are performing market share computations, it may be the case that the conditions for the request to fetch
    // metrics for the numerator (the metrics that are divided by the "market" metrics to compute market share) are differnt
    // than simply the entity metrics for the main entity.  In this case, `buildEntityMarketShareNumeratorConditions` will
    // return an alternative set of conditions to be used to perform the request for the numerator metrics.
    let hasNonStandardNumerator;
    if (isMarketShareChart) {
      const mainEntityMarketShareNumeratorConditions = buildEntityMarketShareNumeratorConditions({
        entity: mainEntity,
        app,
        filters,
        categories,
        subCategories,
        segments,
        retailer,
        user,
        mainEntityConditions
      });

      hasNonStandardNumerator = !!mainEntityMarketShareNumeratorConditions;
      mainEntityConditions = mainEntityMarketShareNumeratorConditions || mainEntityConditions;
    }

    let comparisonRetailer = _cloneDeep(retailer);
    if (queryParams.ctype === 'retailer') {
      [comparisonRetailer] = retailer.availableRetailers.filter((r) => r.id === queryParams.cid);
    }
    const comparisonEntityConditions = buildComparisonEntityConditions(
      entityConditions,
      comparisonEntity || mainEntity,
      app,
      comparisonRetailer
    );
    const promises = [];
    const state = {
      isLoading: true,
      entitySearchServiceStateNamesMainEntity: [],
      entitySearchServiceStateNamesComparisonEntity: [],
      entitySearchServiceStateNamesMarketShare: [],
      mainMetricConfig: {},
      comparisonMetricConfig: {}
    };
    widget.data.groupByFields.forEach((groupByField, index) => {
      const { indexName } = widget.data.configByGroupByFieldName[groupByField.name];
      const { aggregationConditions } = buildAggregationConditions(
        app,
        indexName,
        retailer,
        allWeekIdsByRetailerId,
        mainTimePeriod,
        comparisonTimePeriod,
        propAggregationConditions,
        widget.data.weekIdField,
        false
      );
      const { replaceZeroWithLastKnownValue } = widget.data;
      const timePeriodRangeFilter = aggregationConditions.rangeFilters.filter((x) => x.fieldName === 'weekId');
      aggregationConditions.rangeFilters = aggregationConditions.rangeFilters.filter((x) => x.fieldName !== 'weekId');

      const aggregationItems = buildAggregations(
        widget.data.configByGroupByFieldName[groupByField.name].aggregationFields
      );
      const mainEntitySearchRequestOverrides = [];
      let derivedFields = null;
      aggregationItems.forEach((aggregationItem) => {
        const { aggregations: aggregationFields, aggregationFieldConditions } = aggregationItem;
        derivedFields = derivedFields || aggregationItem.derivations;
        if (aggregationConditions && aggregationConditions.termFilters) {
          aggregationFieldConditions.termFilters = aggregationFieldConditions.termFilters.concat(
            aggregationConditions.termFilters
          );
        }
        if (aggregationConditions && aggregationConditions.rangeFilters) {
          aggregationFieldConditions.rangeFilters = aggregationFieldConditions.rangeFilters.concat(
            aggregationConditions.rangeFilters
          );
        }
        const aggregations = {
          groupByFieldName: groupByField.name,
          aggregationFields,
          sortDirection: null,
          sortByAggregationField: null,
          conditions: aggregationFieldConditions
        };
        const mainEntityConditionsCloned = _cloneDeep(mainEntityConditions);
        mainEntityConditionsCloned.rangeFilters = _unionWith(
          mainEntityConditionsCloned.rangeFilters || [],
          timePeriodRangeFilter,
          _isEqual
        );
        mainEntitySearchRequestOverrides.push({
          indexName: aggregationItem.indexName,
          conditions: mainEntityConditionsCloned,
          aggregations: [aggregations]
        });
      });

      let statePropertyNameMainEntity = widget.data.configByGroupByFieldName[groupByField.name].isShared
        ? `${indexName}_${groupByField.name}`
        : `${widget.name}_${indexName}_${groupByField.name}`;

      // If we are market share and we are fetching metrics with a special numerator that has different conditions than just
      // main entity conditions, we can't re-use those metrics, so we have to change the state key name.
      if (hasNonStandardNumerator) {
        statePropertyNameMainEntity += '_marketShareNumerator';
      }

      const statePropertyNameComparisonEntity = widget.data.configByGroupByFieldName[groupByField.name].isShared
        ? `comparison_${indexName}_${groupByField.name}`
        : `comparison_${widget.name}_${indexName}_${groupByField.name}`;
      state.entitySearchServiceStateNamesMainEntity.push(statePropertyNameMainEntity);
      if (index === 0) {
        state.mainMetricConfig = {
          type: widget.data.configByGroupByFieldName[groupByField.name].entity.type || 'metric',
          indexName,
          metricField: widget.view.metricFields[0]
        };
      }

      // only fetch data, if this widget is the primary source of the data or if it specific to this widget
      if (
        widget.data.configByGroupByFieldName[groupByField.name].isPrimarySource ||
        !widget.data.configByGroupByFieldName[groupByField.name].isShared
      ) {
        if (replaceZeroWithLastKnownValue) {
          mainEntitySearchRequestOverrides[0].aggregations[0].conditions.rangeFilters =
            mainEntitySearchRequestOverrides[0].conditions.rangeFilters;
        }
        promises.push(
          fetchEntityMetrics(
            statePropertyNameMainEntity,
            {
              entity: widget.data.configByGroupByFieldName[groupByField.name].entity,
              retailer,
              app,
              indexName,
              derivedFields,
              aggregationFields: widget.data.configByGroupByFieldName[groupByField.name].aggregationFields
            },
            mainEntitySearchRequestOverrides,
            this.cancelSource.token
          )
        );

        if (comparisonConfig.type) {
          let comparisonIndexName = comparisonConfig.indexName;
          if (comparisonConfig.type && comparisonConfig.type !== 'metric') {
            comparisonIndexName = indexName;
          }
          let comparisonEntityAggregationFieldsConfig = [];
          if (queryParams.ctype === 'metric') {
            comparisonEntityAggregationFieldsConfig = [
              INDEX_FIELDS.getField(
                app.name,
                comparisonIndexName,
                comparisonConfig.metricName,
                comparisonEntity ? comparisonEntity.type : mainEntity.type,
                groupByField.name
              )
            ];
          } else {
            comparisonEntityAggregationFieldsConfig =
              widget.data.configByGroupByFieldName[groupByField.name].aggregationFields;
          }

          const comparisonEntityRequestOverrides = [];
          let comparisonEntityDerivedFields = null;
          const comparisonEntityAggregationItems = buildAggregations(comparisonEntityAggregationFieldsConfig);
          comparisonEntityAggregationItems.forEach((aggregationItem) => {
            const {
              aggregations: comparisonEntityAggregationFields,
              aggregationFieldConditions: comparisonEntityAggregationFieldConditions
            } = aggregationItem;
            comparisonEntityDerivedFields = comparisonEntityDerivedFields || aggregationItem.derivations;

            if (aggregationConditions && aggregationConditions.termFilters) {
              comparisonEntityAggregationFieldConditions.termFilters =
                comparisonEntityAggregationFieldConditions.termFilters.concat(aggregationConditions.termFilters);
            }
            if (aggregationConditions && aggregationConditions.rangeFilters) {
              comparisonEntityAggregationFieldConditions.rangeFilters =
                comparisonEntityAggregationFieldConditions.rangeFilters.concat(aggregationConditions.rangeFilters);
            }
            const comparisonEntityAggregations = {
              groupByFieldName: groupByField.name,
              aggregationFields: comparisonEntityAggregationFields,
              sortDirection: null,
              sortByAggregationField: null,
              conditions: comparisonEntityAggregationFieldConditions
            };
            comparisonEntityRequestOverrides.push({
              indexName: aggregationItem.indexName,
              conditions: _cloneDeep(comparisonEntityConditions),
              aggregations: [comparisonEntityAggregations]
            });
          });
          state.entitySearchServiceStateNamesComparisonEntity.push(statePropertyNameComparisonEntity);
          if (index === 0) {
            state.comparisonMetricConfig = {
              type: comparisonConfig.type || 'metric',
              indexName: comparisonIndexName,
              metricField: comparisonEntityAggregationFieldsConfig[0]
            };
          }
          promises.push(
            fetchEntityMetrics(
              statePropertyNameComparisonEntity,
              {
                entity: comparisonEntity || mainEntity,
                retailer: comparisonRetailer,
                app,
                indexName: comparisonIndexName,
                derivedFields: comparisonEntityDerivedFields,
                aggregationFields: comparisonEntityAggregationFieldsConfig
              },
              comparisonEntityRequestOverrides,
              this.cancelSource.token
            )
          );
        }

        // check if this is a marketshare chart
        if (isMarketShareChart) {
          const statePropertyNameCategory = widget.data.configByGroupByFieldName[groupByField.name].isShared
            ? `${indexName}_${groupByField.name}_marketshare`
            : `${widget.name}_${indexName}_${groupByField.name}_marketshare`;
          const marketShareDenominatorConditions = buildEntityMarketShareConditions({
            app,
            filters,
            entity: mainEntity,
            categories,
            subCategories,
            segments,
            retailer
          });

          const marketShareDenominatorConditionsCloned = _cloneDeep(marketShareDenominatorConditions);
          marketShareDenominatorConditionsCloned.rangeFilters = _unionWith(
            marketShareDenominatorConditionsCloned.rangeFilters || [],
            timePeriodRangeFilter,
            _isEqual
          );
          promises.push(
            fetchEntityMetrics(
              statePropertyNameCategory,
              {
                entity: widget.data.configByGroupByFieldName[groupByField.name].entity,
                retailer,
                app,
                indexName,
                derivedFields,
                aggregationFields: widget.data.configByGroupByFieldName[groupByField.name].aggregationFields
              },
              [
                {
                  conditions: marketShareDenominatorConditionsCloned,
                  aggregations: [
                    {
                      ...mainEntitySearchRequestOverrides[0].aggregations[0],
                      conditions: _cloneDeep(aggregationConditions)
                    }
                  ]
                }
              ],
              this.cancelSource.token
            )
          );
          state.entitySearchServiceStateNamesMarketShare.push(statePropertyNameCategory);
        }
      }
    });

    this.setState({ ...state });

    Promise.all(promises)
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (!(err.message.includes('Cancel network request') || err.message.includes('Canceled network request'))) {
          console.error(err);
        }
      });
  };

  redirectTo = (linkTo) => {
    const { tab, subtab, scrollTo, ...rest } = linkTo;

    if ((!tab || !subtab) && scrollTo) {
      if (_isString(scrollTo)) {
        window.location.hash = scrollTo;
        return;
      } else if (_isObject(scrollTo) && scrollTo.eventName && scrollTo.eventData) {
        this.props.eventBus.emit(scrollTo.eventName, scrollTo.eventData);
        return;
      } else {
        throw panic(`Invalid \`scrollTo\` prop passed to \`CommonSummaryTrend\``, { scrollTo });
      }
    }

    const { location, history } = this.props;
    const { pathname, search } = location;
    // TODO: Abstract
    const parsedUrl = queryString.parse(search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    parsedUrl.tab = tab;
    parsedUrl.subtab = subtab;
    Object.entries(rest).forEach(([key, val]) => {
      parsedUrl[key] = val;
    });
    if (scrollTo) {
      parsedUrl.scrollTo = scrollTo;
    }

    history.push({
      pathname,
      search: `?${queryString.stringify(parsedUrl)}`,
      hash: scrollTo,
      state: { from: location }
    });
  };

  renderMainTrendLegend(title, value, change, absoluteChange, className, linkTo) {
    const { isLoading } = this.state;
    if (isLoading) {
      return null;
    }

    return (
      <div className="summary_trend_legend" onClick={() => this.redirectTo(linkTo)} role="button">
        <div className="summary_maintrend_title">{title}</div>
        <div className="summary_legend_info">
          <span>{`${value} `}</span>
          <span>
            <Tooltip title={absoluteChange} placement="top">
              <span className={className}>{change}</span>
            </Tooltip>
          </span>
        </div>
      </div>
    );
  }

  renderMainLoading = () => (this.props.widget.view.disableLoadingIndicator ? null : <AtlasSummaryMainTrendLoading />);

  renderSubLoading = () => (this.props.widget.view.disableLoadingIndicator ? null : <AtlasSummarySubTrendLoading />);

  renderMainTrend = (linkTo) => {
    if (this.state.isLoading) {
      return this.renderMainLoading();
    }
    const chartInfo = getTrendChartInfo(this.props, this.state);
    if (!chartInfo) {
      return this.renderMainLoading();
    }

    const { chartSeries, chartProps } = getCommonSummaryTrendParameters(chartInfo);
    const { legendTitle, legendValue, legendChange, legendClassName, legendAbsoluteChange } = chartProps.legend;
    fillColorForSeries(chartSeries);

    if (shouldShowNewBeacon()) {
      const sign = chartProps.legend.legendChange[0];
      const change = chartProps.legend.legendChange.slice(1);
      const metricName = this.props.widget.name.replace('ByweekId', '');
      const selectedKeyMetric = getQueryParamValue('keyMetric', 'retailSales');

      return (
        <MetricSummaryTrendCard
          variant="large"
          title={chartProps.legend.legendTitle}
          value={chartProps.legend.legendValue}
          change={change}
          direction={sign === '+' ? 'increasing' : 'decreasing'}
          splinePreviewProps={{
            primaryData: chartSeries[1].data,
            secondaryData: chartSeries[0].data,
            metricType: this.props.widget.view.metricFields[0].metricType
          }}
          selected={selectedKeyMetric === metricName}
          onClick={() =>
            this.props.widget.view.onCardClick
              ? this.props.widget.view.onCardClick({
                  history: this.props.history,
                  metricName,
                  indexName: this.props.widget.data.indexName
                })
              : this.redirectTo(linkTo)
          }
        />
      );
    } else {
      return (
        <div className="summary_maintrend_wrapper">
          {!chartProps.legend.enabled && !chartProps.legend.disableTrendLegend
            ? this.renderMainTrendLegend(
                legendTitle,
                legendValue,
                legendChange,
                legendAbsoluteChange,
                legendClassName,
                linkTo
              )
            : null}
          <div className="summary_maintrend_plot">
            <GenericChart chartSeries={chartSeries} chartProps={chartProps} />
          </div>
        </div>
      );
    }
  };

  renderSubTrend = (linkTo) => {
    if (this.state.isLoading) {
      return this.renderSubLoading();
    }
    const chartInfo = getTrendChartInfo(this.props, this.state);
    if (!chartInfo) {
      return this.renderSubLoading();
    }

    const chartParameters = getCommonSummaryTrendParameters(chartInfo);
    if (!chartParameters) {
      return null;
    }
    const { chartSeries, chartProps, value } = chartParameters;

    // Save the value so it is globally accessible, for example by the
    // product grid so it knows how many total products there are
    store.dispatch(receiveEntitySalesMetrics(`${this.props.widget.name}_metricValue`, { value }));

    if (shouldShowNewBeacon()) {
      const sign = chartProps.legend.legendChange[0];
      const change = chartProps.legend.legendChange.slice(1);
      const metricName = this.props.widget.name.replace('ByweekId', '');
      const selectedKeyMetric = getQueryParamValue('keyMetric', 'retailSales');

      return (
        <MetricSummaryTrendCard
          variant="small"
          title={chartProps.legend.legendTitle}
          value={chartProps.legend.legendValue}
          change={change}
          direction={sign === '+' ? 'increasing' : 'decreasing'}
          splinePreviewProps={{
            primaryData: chartSeries[1].data,
            secondaryData: chartSeries[0].data,
            metricType: this.props.widget.view.metricFields[0].metricType
          }}
          selected={selectedKeyMetric === metricName}
          onClick={() =>
            this.props.widget.view.onCardClick
              ? this.props.widget.view.onCardClick({
                  history: this.props.history,
                  metricName,
                  indexName: this.props.widget.data.indexName
                })
              : this.redirectTo(linkTo)
          }
        />
      );
    } else {
      return (
        <div className="summary_subtrend_wrapper" onClick={() => this.redirectTo(linkTo)} role="button">
          {!chartProps.legend.enabled && !chartProps.legend.disableTrendLegend ? (
            <SubTrendLegend {...chartProps.legend} isLoading={this.state.isLoading} />
          ) : null}
          <div className="summary_subtrend_plot">
            <GenericChart chartSeries={chartSeries} chartProps={chartProps} />
          </div>
        </div>
      );
    }
  };

  render() {
    const { widget } = this.props;
    const shouldShowSubtrend =
      (shouldShowNewBeacon() && widget.view.isSubtrend) ||
      widget.view.container.className === 'summary_subtrend_parent';
    return shouldShowSubtrend ? this.renderSubTrend(widget.view.linkTo) : this.renderMainTrend(widget.view.linkTo);
  }
}

const mapStateToProps = (state) =>
  _pick(state, [
    'app',
    'entityService',
    'entitySearchService',
    'comparisonTimePeriod',
    'mainTimePeriod',
    'retailer',
    'categories',
    'subCategories',
    'filters',
    'allWeekIdsByRetailerId',
    'segments',
    'user'
  ]);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withBus('eventBus'));

const EnhancedCommonSummaryTrend = enhance(CommonSummaryTrend);

export default EnhancedCommonSummaryTrend;
