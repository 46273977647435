import React from 'react';
import BeaconDoubleDropdownMetricColumnChart from 'src/components/BeaconRedesignComponents/BeaconMetricColumnChart/BeaconDoubleDropdownMetricColumnChart';
import TopEntityChartV2Loading from 'src/components/BeaconRedesignComponents/TopEntityChartV2/TopEntityChartV2Loading';
import { useMainEntityType } from 'src/utils/Hooks/reduxSelectors';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';

const PromotionsTopEntityChart = () => {
  const entityType = useMainEntityType();

  return (
    <BeaconDoubleDropdownMetricColumnChart
      preProcessData={(data) => {
        // We swap count for the value to determine an accurate count of promotions
        return data.map((datum) => ({
          ...datum,
          values: {
            ...datum.values,
            stacklineSku_cardinality_value: datum?.values?.stacklineSku_cardinality_count ?? 0
          }
        }));
      }}
      postProcessData={(data) => {
        if (data) {
          return data.map((row) => {
            const [group, value] = row;
            return [group, Math.abs(value)];
          });
        } else {
          return data;
        }
      }}
      beaconChartWithLegendPropsBuilder={(props) => ({
        ...props,
        loadingComponent: <TopEntityChartV2Loading />
      })}
      fields={[
        { fieldName: 'stacklineSku', label: 'Promotion Count' },
        { fieldName: 'averagePromotionDiscount', label: 'Average Discount' },
        { fieldName: 'shareOfRetailSales', label: 'Share of Retail Sales' },
        { fieldName: 'shareOfUnitsSold', label: 'Share of Units Sold' }
      ]}
      groupByFields={[
        {
          id: 'subCategoryId',
          label: 'Subcategory',
          indexName: BEACON_INDEX_NAMES.PROMOTIONS
        },
        {
          id: 'categoryId',
          label: 'Category',
          indexName: BEACON_INDEX_NAMES.PROMOTIONS
        },
        {
          id: 'brandId',
          label: 'Brand',
          indexName: BEACON_INDEX_NAMES.PROMOTIONS
        }
      ].filter((option) => !option.id.toLowerCase().startsWith(entityType))}
    />
  );
};

export default PromotionsTopEntityChart;
