import {
  AdConversionPathwaysAdvancedSearchResponse,
  AdConversionPathwaysDocument
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/ConversionPathways/types';
import _get from 'lodash/get';
import {
  AdConversionPathwaysDropdownOption,
  AdConversionPathwaysFieldMapping
} from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';

/**
 * Parses advanced search response from Conversion Pathways request
 */
export const adConversionPathwaysAdvancedSearchParser = (
  response: AdConversionPathwaysAdvancedSearchResponse
): AdConversionPathwaysDocument[] => {
  const documents = _get(response, 'documents', []);
  return documents;
};

interface BuildRequestForConversionPathwaysParameters {
  startDayId: string | number;
  endDayId: string | number;
  retailerId?: string | number;
}

export const buildRequestForConversionPathways = ({
  startDayId,
  endDayId,
  retailerId = 1
}: BuildRequestForConversionPathwaysParameters) => {
  return [
    {
      name: 'amc_conversion_pathways',
      id: 'amc_conversion_pathways',
      pageNumber: 1,
      pageSize: 100,
      doAggregation: false,
      returnDocuments: true,
      conditions: {
        termFilters: [
          {
            fieldName: 'periodStartDate',
            condition: 'should',
            values: [startDayId]
          },
          {
            fieldName: 'periodEndDate',
            condition: 'should',
            values: [endDayId]
          },
          {
            fieldName: 'retailerId',
            condition: 'should',
            values: [retailerId]
          }
        ],
        computeFilters: []
      },
      searchBy: 'parent',
      searchType: 'advertising-amc-conversion-pathways',
      processDocuments: false
    }
  ];
};

export const calculateMetricValues = (documentObject: AdConversionPathwaysDocument, metric: string) => {
  const metricFieldName = AdConversionPathwaysFieldMapping[metric].name;
  if (metric === AdConversionPathwaysDropdownOption.ReturnOnAdSpend) {
    return documentObject.adSales / documentObject.spend;
  }

  return documentObject[metricFieldName];
};

export const safelyParsePathwayJson = (input) => {
  const readyToParseJson = input.replace(/(\b\D\w+\b)/g, '"$1"');
  return JSON.parse(readyToParseJson);
};

// Should create unit tests for this
const getPathwayCollection = (document: AdConversionPathwaysDocument) => {
  const pathway = _get(document, 'path', '[]');

  // Potentially bad JSON. Attempting to parse this --> "[[1, DSP], [2, SP]" will throw an error.
  // Regex converts "[[1, DSP], [2, SP]" --> '[[1, "DSP"], [2, "SP"]]' for safe parsing.
  const touchPoints = safelyParsePathwayJson(pathway);
  return touchPoints;
};

export const convertPathwaysDataToCsv = ({ seriesData, sortedDocuments, dateRange, metric }): string => {
  // Get the metric values from our seriesData
  const { data } = seriesData;

  // Generate column headers
  const headers = ['Touchpoint 1', 'Touchpoint 2', 'Touchpoint 3', 'Touchpoint 4', `${metric}: ${dateRange}`]
    .map((val) => `"${val}"`)
    .join(',');

  // Create rows
  const values = data.map((dataPoint: number, index: number) => {
    const pathway = getPathwayCollection(sortedDocuments[index]);

    // Append the existing touchpoints to the string
    let dataRow = '';
    pathway.forEach((touchPoint) => {
      dataRow += `${touchPoint[1]}, `;
    });

    // Fill any gaps if we have less than 4 touchpoints to place our metric value on the last column
    const pathwayLength = pathway.length;
    if (pathwayLength < 4) {
      for (let i = pathwayLength; i < 4; i++) {
        dataRow += ', ';
      }
    }

    // Append the metric value to the string
    dataRow += `${dataPoint}`;

    return dataRow;
  });

  return `${headers}\n${values.join('\n')}`;
};
