import ReactDOMServer from 'react-dom/server.browser';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEmpty';
import _merge from 'lodash/merge';
import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import GenericChart from 'src/components/Charts/GenericChart';
import { computeMarketShareComparisonLegendValue } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { fillInMissingTimePeriods } from 'src/components/EntityPage/Renderer/TimeSeriesTrendChart';
import { store } from 'src/main';
import colors from 'src/utils/colors';
import { getWeekLastDate, moveForwardOneWeek } from 'src/utils/dateformatting';
import { getWeekId } from 'src/utils/dateUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import fontStyle from 'src/utils/fontStyle';
import { buildMetricValue } from 'src/utils/metrics';
import { error } from 'src/utils/mixpanel';
import { computeMainLegendValueAdjustment } from './helpers';
import { getTooltipDiv } from './tooltipDiv';
import { chartColorsLineChart, computeLastWeek, getBaseLineChartSeries } from './util';
import ArrowChange from 'src/components/common/ArrowChange/ArrowChange';
import { isDrive } from 'src/utils/app';
import { SHORTAGES_FIELDS } from 'src/utils/entityDefinitions/fields/beaconShortagesFieldDefinitions';

/**
 * This function returns an object that is used to encapsulate all intermediary state used while computing the
 * chart's configuration and series.  It is designed to be passed around and mutated by helper functions, building
 * up the config incrementally.
 */
export const createInitialChartCompState = ({
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod,
  mainEntityMetrics,
  mainMetricName,
  comparisonEntityMetrics,
  comparisonMetricName,
  groupByFieldName,
  widget
}) => {
  let avoidZeroComputations = false;
  if (widget !== undefined && widget.data !== undefined && widget.data.avoidZeros) {
    avoidZeroComputations = widget.data.avoidZeros;
  }
  let isRatingView = false;
  if (widget !== undefined && widget.data !== undefined && widget.data.isRatingView) {
    isRatingView = widget.data;
  }

  const metricsDataByWeekId = mainEntityMetrics[mainMetricName];
  const metricsComparisonDataByWeekId = comparisonEntityMetrics[comparisonMetricName];
  const { currencySymbol: mainCurrencySymbol, locale: mainLocale } = metricsDataByWeekId;
  const { currencySymbol: comparisonCurrencySymbol, locale: comparisonLocale } = metricsComparisonDataByWeekId;

  const mainMetricDependentFieldsSum = {};
  if (metricsDataByWeekId.dependentFields) {
    metricsDataByWeekId.dependentFields.forEach((field) => {
      mainMetricDependentFieldsSum[field.name] = 0;
      mainMetricDependentFieldsSum[`${field.indexName}__${field.name}`] = 0;
    });
  }

  const isSameMetricComparison =
    metricsDataByWeekId.displayName === metricsComparisonDataByWeekId.displayName &&
    mainCurrencySymbol === comparisonCurrencySymbol;

  return {
    chartSeries: getBaseLineChartSeries(chartDisplayTimePeriod, metricsDataByWeekId),
    mainEntityMetrics,
    comparisonEntityMetrics,
    chartComparisonDisplayTimePeriod,
    chartDisplayTimePeriod,
    metricsDataByWeekId,
    mainMetricDependentFieldsSum,
    groupByFieldName,
    mainCurrencySymbol,
    comparisonCurrencySymbol,
    mainLocale,
    comparisonLocale,
    metricsComparisonDataByWeekId,
    currencySymbols: [mainCurrencySymbol, comparisonCurrencySymbol],
    locales: [mainLocale, comparisonLocale],
    // we should revisit this one to make sure this approach is correct
    isYearOverYearComparison:
      isSameMetricComparison &&
      chartDisplayTimePeriod.endWeek - chartComparisonDisplayTimePeriod.endWeek === 100 &&
      !(chartDisplayTimePeriod.id === '52w' && chartComparisonDisplayTimePeriod.id === 'prior-period'),
    isSameMetricComparison,
    legendDivs: [],
    // The following are set by `populateFirstChartSeries`
    loadLastWeek: undefined,
    mainLegendMetricValue: undefined,
    mainLegendValue: undefined,
    mainMetricSum: undefined,
    mainMetricName,
    avoidZeroComputations,
    isRatingView
  };
};

const getComparisonLegendValue = ({
  metricsComparisonDataByWeekId,
  comparisonMetricSum,
  comparisonMetricTrueSum,
  comparisonMetricTrueCount,
  comparisonMetricDataPointCount,
  comparisonMetricLastValue,
  comparisonMetricDependentFieldsSum
}) => {
  let comparisonLegendValue =
    metricsComparisonDataByWeekId.aggregationFunction === 'avg' ||
    metricsComparisonDataByWeekId.timePeriodAggregationFunction === 'avg'
      ? comparisonMetricSum / (comparisonMetricDataPointCount > 0 ? comparisonMetricDataPointCount : 1)
      : comparisonMetricSum;

  if (metricsComparisonDataByWeekId.timePeriodAggregationFunctionType === 'lastValue') {
    comparisonLegendValue = comparisonMetricLastValue;
  } else if (metricsComparisonDataByWeekId.timePeriodAggregationFunctionType === 'trueAvg') {
    comparisonLegendValue = comparisonMetricTrueSum / (comparisonMetricTrueCount > 0 ? comparisonMetricTrueCount : 1);
  } else if (
    (metricsComparisonDataByWeekId.aggregationFunctionType === 'computed' ||
      metricsComparisonDataByWeekId.aggregationFunctionType === 'derived') &&
    metricsComparisonDataByWeekId.aggregationFunctionEvaluator
  ) {
    comparisonLegendValue = metricsComparisonDataByWeekId.aggregationFunctionEvaluator.evaluate(
      comparisonMetricDependentFieldsSum
    );
  }

  return comparisonLegendValue;
};

const populateFirstChartSeries = (compState, mainLegendValueOverride, comparisonLegendValueOverride) => {
  const {
    chartSeries,
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    metricsDataByWeekId,
    mainMetricDependentFieldsSum,
    mainEntityMetrics,
    groupByFieldName,
    mainCurrencySymbol,
    mainLocale,
    mainMetricName
  } = compState;
  compState.mainMetricSum = 0.0;
  let mainMetricDataPointCount = 0.0;
  let mainMetricTrueCount = 0.0;
  let mainMetricLastValue = 0.0;
  let mainMetricTrueSum = 0.0;

  const avoidZeroComputation = compState.avoidZeroComputations;
  compState.loadLastWeek = chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id !== '1w';
  const mainStartWeek = compState.loadLastWeek
    ? computeLastWeek(chartDisplayTimePeriod.startWeek)
    : chartDisplayTimePeriod.startWeek;
  const filteredVal = metricsDataByWeekId.data.filter(
    (dataPoint) => mainStartWeek <= dataPoint.weekId && chartDisplayTimePeriod.endWeek >= dataPoint.weekId
  );
  filteredVal.forEach((dataPoint, index) => {
    let { weekEnding } = dataPoint;
    const parsedData = Date.parse(weekEnding);
    if (!Number.isNaN(parsedData)) {
      weekEnding = typeof weekEnding === 'string' ? new Date(parsedData) : weekEnding;
      if (compState.isRatingView) {
        dataPoint.value = index !== 0 && dataPoint.value === 0 ? filteredVal[index - 1].value : dataPoint.value;
      }
      if (chartDisplayTimePeriod.id === '1w' || chartDisplayTimePeriod.startWeek === chartDisplayTimePeriod.endWeek) {
        chartSeries[0].zones = [{ color: chartColorsLineChart[2] }];
        if (chartDisplayTimePeriod.endWeek === dataPoint.weekId) {
          chartSeries[0].data.push({
            x: weekEnding.getTime(),
            y: dataPoint.value,
            marker: { enabled: true, radius: 3, fillColor: chartColorsLineChart[0] }
          });
        } else {
          chartSeries[0].data.push({ x: weekEnding.getTime(), y: dataPoint.value });
        }
      } else {
        chartSeries[0].data.push([weekEnding.getTime(), dataPoint.value]);
      }

      if (
        chartDisplayTimePeriod.timePeriod.startWeek <= dataPoint.weekId &&
        chartDisplayTimePeriod.timePeriod.endWeek >= dataPoint.weekId
      ) {
        compState.mainMetricSum += dataPoint.value;
        mainMetricTrueSum += dataPoint.value * dataPoint.count || 0;
        if (avoidZeroComputation && dataPoint.value > 0) {
          mainMetricDataPointCount += 1;
        }
        if (!avoidZeroComputation) {
          mainMetricDataPointCount += 1;
        }

        mainMetricTrueCount += dataPoint.count || 0;
        mainMetricLastValue = dataPoint.value;
        if (metricsDataByWeekId.dependentFields) {
          metricsDataByWeekId.dependentFields.forEach((field) => {
            let dependentFieldParamValue = 0;
            const dependentFieldDataPoint = mainEntityMetrics[`${field.name}_by_${groupByFieldName}`].data.find(
              (x) => x.weekId === dataPoint.weekId
            );
            if (dependentFieldDataPoint) {
              dependentFieldParamValue = dependentFieldDataPoint.value;
            }
            mainMetricDependentFieldsSum[field.name] += dependentFieldParamValue;
            mainMetricDependentFieldsSum[`${field.indexName}__${field.name}`] += dependentFieldParamValue;
          });
        }
      }
    }
  });

  // This is where the "main" legend value is being set.
  compState.mainLegendValue = compState.mainMetricSum;

  if (
    metricsDataByWeekId.aggregationFunction === 'avg' ||
    metricsDataByWeekId.timePeriodAggregationFunction === 'avg'
  ) {
    compState.mainLegendValue = compState.mainMetricSum / (mainMetricDataPointCount > 0 ? mainMetricDataPointCount : 1);
  } else if (metricsDataByWeekId.timePeriodAggregationFunctionType === 'lastValue') {
    compState.mainLegendValue = mainMetricLastValue;
  } else if (metricsDataByWeekId.timePeriodAggregationFunctionType === 'trueAvg') {
    compState.mainLegendValue = mainMetricTrueSum / (mainMetricTrueCount > 0 ? mainMetricTrueCount : 1);
  } else if (
    (metricsDataByWeekId.aggregationFunctionType === 'computed' ||
      metricsDataByWeekId.aggregationFunctionType === 'derived') &&
    metricsDataByWeekId.aggregationFunctionEvaluator
  ) {
    compState.mainLegendValue = metricsDataByWeekId.aggregationFunctionEvaluator.evaluate(mainMetricDependentFieldsSum);
  }

  /* ----Main Legend Value Corrections----
  This code originally only handled MarketShare calculations; it's been refactored to also handle Beacon's Buy Box
  win percentage calculations. Both were incorrectly taking averages of averages.

  When computing average market share over a time period, we can't compute market share for each week and then
  average them because that's an average of averages and thus invalid.
  Instead, we must manually look up the real data keys and divide the sums.
  */
  if (mainMetricName.includes('MarketShare') || mainMetricName.includes('winPercentage')) {
    (() => {
      // Find the key of `entitySearchService` from which `mainEntityMetrics` comes.  This is necessary in order to
      // determine the data key of the market share denominator metrics.
      const tuple = Object.entries(store.getState().entitySearchService).find(
        ([_key, val]) => val === mainEntityMetrics
      );
      if (!tuple) {
        error(`No key in ESS matching \`mainEntityMetrics\` for \`mainMetricName\` "${mainMetricName}"`);
        return;
      }

      let marketShareDenominatorESSDataKey = '';
      const mainEntityMetricsESSDataKey = tuple[0];

      if (mainMetricName.includes('winPercentage')) {
        marketShareDenominatorESSDataKey = `${mainEntityMetricsESSDataKey}`;
      } else {
        marketShareDenominatorESSDataKey = `${mainEntityMetricsESSDataKey}_marketshare`;
      }

      const accurateComputedLegendValue = computeMainLegendValueAdjustment({
        mainStartWeek,
        mainMetricName,
        mainEntityMetrics,
        chartDisplayTimePeriod,
        marketShareDenominatorESSDataKey,
        chartComparisonDisplayTimePeriod
      });

      if (accurateComputedLegendValue !== null) {
        compState.legendValue = accurateComputedLegendValue;
        compState.mainLegendValue = accurateComputedLegendValue;
      }
    })();
  }

  if (mainLegendValueOverride) {
    compState.mainLegendValue = mainLegendValueOverride;
  }
  if (comparisonLegendValueOverride) {
    compState.comparisonLegendValue = comparisonLegendValueOverride;
  }
  compState.mainLegendMetricValue = buildMetricValue(
    compState.mainLegendValue,
    metricsDataByWeekId.metricType,
    mainCurrencySymbol,
    true,
    metricsDataByWeekId.dataType,
    mainLocale
  );

  if (chartDisplayTimePeriod.showExtendedMonths) {
    for (let i = chartDisplayTimePeriod.endWeek + 1; i <= chartDisplayTimePeriod.lastWeekOfYear; i += 1) {
      const weekEnding = getWeekLastDate(i);
      chartSeries[0].data.push([weekEnding.getTime(), null]);
    }
  }
};

/**
 * Using the data from `metricsComparisonDataByWeekId`, `compState.chartSeries[1]` (the second series which is
 * rendered as the comparison) is built up.
 */
const populateComparisonSeriesData = (compState) => {
  const {
    metricsComparisonDataByWeekId,
    comparisonStartWeek,
    chartComparisonDisplayTimePeriod,
    isYearOverYearComparison,
    chartDisplayTimePeriod,
    comparisonMetricDependentFieldsSum,
    comparisonEntityMetrics
  } = compState;

  // Contains 53 when starts in a leap year and the week ends in 53 or the end week is ina different year
  const mainStartYear = Math.floor(chartDisplayTimePeriod.startWeek / 100);
  const mainEndYear = Math.floor(chartDisplayTimePeriod.endWeek / 100);
  const mainTimePeriodContains53 = moment([mainStartYear]).isLeapYear() && mainStartYear !== mainEndYear;

  const comparisonSeries = compState.chartSeries[1];
  metricsComparisonDataByWeekId.data.forEach((dataPoint, index) => {
    const datumIsInRange =
      comparisonStartWeek <= dataPoint.weekId && chartComparisonDisplayTimePeriod.endWeek >= dataPoint.weekId;
    if (datumIsInRange) {
      if (compState.isRatingView) {
        dataPoint.value =
          index !== 0 && dataPoint.value === 0 ? metricsComparisonDataByWeekId.data[index - 1].value : dataPoint.value;
      }
      // We move forward week to handle the leap year case where the main time period has a week 53 but the
      // comparison period does not (and the main time period ccontinues into the next year)
      // So, we would compare: Last 4 weeks: 202051, 202052, 202053, 202101 Prior Year: 201950, 201951, 201952, 202001
      const shouldMoveForwardOneWeek = dataPoint.weekId < +`${mainStartYear - 1}53` && mainTimePeriodContains53;
      if (shouldMoveForwardOneWeek) {
        dataPoint = moveForwardOneWeek(_cloneDeep(dataPoint));
      }
      let weekEnding = isYearOverYearComparison ? dataPoint.weekEndingNextYear : dataPoint.weekEnding;
      weekEnding = typeof weekEnding === 'string' ? new Date(Date.parse(weekEnding)) : weekEnding;
      if (chartDisplayTimePeriod.id === '1w' || chartDisplayTimePeriod.startWeek === chartDisplayTimePeriod.endWeek) {
        comparisonSeries.zones = [{ color: chartColorsLineChart[2] }];
        if (chartComparisonDisplayTimePeriod.endWeek === dataPoint.weekId) {
          comparisonSeries.data.push({
            x: weekEnding.getTime(),
            y: dataPoint.value,
            marker: { enabled: true, radius: 3, fillColor: chartColorsLineChart[1] }
          });
        } else {
          comparisonSeries.data.push({ x: weekEnding.getTime(), y: dataPoint.value });
        }
      } else {
        comparisonSeries.data.push([weekEnding.getTime(), dataPoint.value]);
      }

      if (
        chartComparisonDisplayTimePeriod.timePeriod.startWeek <= dataPoint.weekId &&
        chartComparisonDisplayTimePeriod.timePeriod.endWeek >= dataPoint.weekId
      ) {
        compState.comparisonMetricSum += dataPoint.value;
        compState.comparisonMetricDataPointCount += 1;
        compState.comparisonMetricTrueSum += dataPoint.value * dataPoint.count || 0;
        compState.comparisonMetricTrueCount += dataPoint.count || 0;
        compState.comparisonMetricLastValue = dataPoint.value;
        if (metricsComparisonDataByWeekId.dependentFields) {
          metricsComparisonDataByWeekId.dependentFields.forEach((field) => {
            const datum = comparisonEntityMetrics[`${field.name}_by_${compState.groupByFieldName}`].data.find(
              (x) => x.weekId === dataPoint.weekId
            );
            const foundValue = datum ? datum.value : 0;
            comparisonMetricDependentFieldsSum[field.name] += foundValue;
            comparisonMetricDependentFieldsSum[`${field.indexName}__${field.name}`] += foundValue;
          });
        }
      }
    } else if (
      chartDisplayTimePeriod.showExtendedMonths &&
      chartComparisonDisplayTimePeriod.firstWeekOfYear <= dataPoint.weekId &&
      chartComparisonDisplayTimePeriod.lastWeekOfYear >= dataPoint.weekId
    ) {
      const rawWeekEnding = isYearOverYearComparison ? dataPoint.weekEndingNextYear : dataPoint.weekEnding;
      const weekEnding = typeof rawWeekEnding === 'string' ? new Date(Date.parse(rawWeekEnding)) : rawWeekEnding;

      if (chartDisplayTimePeriod.id === '1w') {
        if (
          chartComparisonDisplayTimePeriod.id === 'prior-period' &&
          chartComparisonDisplayTimePeriod.endWeek < dataPoint.weekId
        ) {
          return;
        }

        comparisonSeries.data.push({ x: weekEnding.getTime(), y: dataPoint.value });
      } else {
        comparisonSeries.data.push([weekEnding.getTime(), dataPoint.value]);
      }
    }
  });
};

const computeComparisonMetrics = (compState) => {
  compState.comparisonMetricSum = 0.0;
  compState.comparisonMetricDataPointCount = 0.0;
  compState.comparisonMetricLastValue = 0.0;
  compState.comparisonMetricDependentFieldsSum = {};
  compState.comparisonMetricTrueSum = 0.0;
  compState.comparisonMetricTrueCount = 0.0;

  if (compState.metricsComparisonDataByWeekId.dependentFields) {
    compState.metricsComparisonDataByWeekId.dependentFields.forEach((field) => {
      compState.comparisonMetricDependentFieldsSum[field.name] = 0;
      compState.comparisonMetricDependentFieldsSum[`${field.indexName}__${field.name}`] = 0;
    });
  }

  compState.chartSeries.push({
    name: compState.chartComparisonDisplayTimePeriod.displayName,
    metricType: compState.metricsComparisonDataByWeekId.metricType,
    data: [],
    color: chartColorsLineChart[1],
    marker: {
      lineColor: chartColorsLineChart[1],
      fillColor: chartColorsLineChart[1],
      lineWidth: 3,
      symbol: 'circle'
    }
  });

  compState.comparisonStartWeek = compState.loadLastWeek
    ? computeLastWeek(compState.chartComparisonDisplayTimePeriod.startWeek)
    : compState.chartComparisonDisplayTimePeriod.startWeek;
};

const createComparisonLegendDivs = (compState) => {
  const {
    mainLegendMetricValue,
    mainCurrencySymbol,
    comparisonLegendValue,
    chartDisplayTimePeriod,
    metricsComparisonDataByWeekId,
    comparisonLegendMetricValue,
    chartComparisonDisplayTimePeriod,
    isSameMetricComparison
  } = compState;

  let mainLegendDiv = `<div ${isDrive ? 'style="display: flex;"' : ''}>${compState.mainLegendMetricValue.prefix || ''}${
    mainLegendMetricValue.value
  }${compState.mainLegendMetricValue.suffix || ''}`;

  if (isSameMetricComparison) {
    let metricsChangeIcon = { icon: '+', className: 'increase' };
    let metricsChangePercent =
      comparisonLegendValue !== 0
        ? (compState.mainLegendValue - comparisonLegendValue) / Math.abs(comparisonLegendValue)
        : compState.mainLegendValue === 0
        ? 0
        : 1000;
    const metricsChange = numeral(compState.mainLegendValue - comparisonLegendValue).format('+1,000.00');

    const metric = {
      isNegativeMetric: false
    };

    if (metricsChangePercent && metricsChangePercent < 0) {
      metric.isNegativeMetric = true;
      metricsChangePercent *= -1;
      metricsChangeIcon = { icon: '\u2212', className: 'decrease' };
    }

    const metricsChangePercentMetricValue = buildMetricValue(
      metricsChangePercent,
      METRICTYPE.PERCENT,
      mainCurrencySymbol,
      true,
      null,
      compState.mainLocale
    );

    const changeArrow = ReactDOMServer.renderToString(
      <ArrowChange metric={metric} value={metricsChangePercent} useImageUrl />
    );

    // On the shortages summary tab, we invert the styles for certain metrics, (green to red, red to green)
    const selectedMetric = _get(compState, ['mainMetricName'], '');
    const params = new URLSearchParams(window.location.search);
    const subtab = params.get('subtab');
    const onShortagesPage = subtab === 'shortages';

    if (onShortagesPage) {
      const shouldInvertStyle = [
        `${SHORTAGES_FIELDS.totalShortages.name}_by_weekId`,
        `${SHORTAGES_FIELDS.shortageRatio.name}_by_weekId`,
        `${SHORTAGES_FIELDS.skusWithShortages.name}_by_weekId`
      ].includes(selectedMetric);
      if (shouldInvertStyle) {
        if (metricsChangeIcon.className === 'decrease') {
          metricsChangeIcon.className = 'increase';
        } else if (metricsChangeIcon.className === 'increase') {
          metricsChangeIcon.className = 'decrease';
        }
      }
    }

    if (isDrive) {
      mainLegendDiv += `<span style="display: flex;" class="legend__percent-change">(<span class="legend__percent-change-metric legend__percent-change-metric"><span>${changeArrow}</span></span>)</span>`;
    } else {
      mainLegendDiv += `<span class="legend__percent-change">(<span class="legend__percent-change-metric legend__percent-change-metric--${metricsChangeIcon.className}"><span>${metricsChangeIcon.icon}</span><span class="sl-metric"><span class="sl-metric__value" title="${metricsChange}">${metricsChangePercentMetricValue.value}</span><span class="sl-metric__suffix">${metricsChangePercentMetricValue.suffix}</span></span></span>)</span>`;
    }
  }

  mainLegendDiv += `</div><div class="legend__primary-date" style="margin-top:5px;">${chartDisplayTimePeriod.displayName}</div>`;

  if (
    compState.metricsDataByWeekId.retailer &&
    metricsComparisonDataByWeekId.retailer &&
    compState.metricsDataByWeekId.retailer.id !== metricsComparisonDataByWeekId.retailer.id
  ) {
    mainLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${compState.metricsDataByWeekId.retailer.displayName}</div>`;
  } else if (
    compState.metricsDataByWeekId.entity.type !== metricsComparisonDataByWeekId.entity.type ||
    compState.metricsDataByWeekId.entity.id !== metricsComparisonDataByWeekId.entity.id
  ) {
    const entityName =
      compState.metricsDataByWeekId.entity.shortDisplayName ||
      compState.metricsDataByWeekId.entity.displayName ||
      compState.metricsDataByWeekId.entity.entityName ||
      compState.metricsDataByWeekId.entity.portfolioName ||
      compState.metricsDataByWeekId.entity.campaignName;

    mainLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${entityName}</div>`;
  } else if (!compState.isSameMetricComparison) {
    mainLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${compState.metricsDataByWeekId.displayName}</div>`;
  }
  compState.legendDivs.push(mainLegendDiv);

  let comparisonLegendDiv = `<div>${comparisonLegendMetricValue.prefix || ''}${comparisonLegendMetricValue.value}${
    comparisonLegendMetricValue.suffix || ''
  }</div>
    <div class="legend__primary-date" style="margin-top:5px;">${chartComparisonDisplayTimePeriod.displayName}</div>`;
  if (
    compState.metricsDataByWeekId.retailer &&
    metricsComparisonDataByWeekId.retailer &&
    compState.metricsDataByWeekId.retailer.id !== metricsComparisonDataByWeekId.retailer.id
  ) {
    comparisonLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${metricsComparisonDataByWeekId.retailer.displayName}</div>`;
  } else if (
    compState.metricsDataByWeekId.entity.type !== metricsComparisonDataByWeekId.entity.type ||
    compState.metricsDataByWeekId.entity.id !== metricsComparisonDataByWeekId.entity.id
  ) {
    const entityName =
      metricsComparisonDataByWeekId.entity.shortDisplayName ||
      metricsComparisonDataByWeekId.entity.displayName ||
      metricsComparisonDataByWeekId.entity.entityName ||
      metricsComparisonDataByWeekId.entity.portfolioName ||
      metricsComparisonDataByWeekId.entity.campaignName;
    comparisonLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${entityName}</div>`;
  } else if (!compState.isSameMetricComparison) {
    comparisonLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${metricsComparisonDataByWeekId.displayName}</div>`;
  }
  compState.legendDivs.push(comparisonLegendDiv);
};

const createNonComparisonLegendDivs = ({
  chartDisplayTimePeriod,
  mainLegendMetricValue,
  isSameMetricComparison,
  metricsDataByWeekId,
  legendDivs
}) => {
  let mainLegendDiv = `<div>${mainLegendMetricValue.prefix || ''}${mainLegendMetricValue.value}${
    mainLegendMetricValue.suffix || ''
  }</div>
  <div class="legend__primary-date" style="margin-top:5px;">${chartDisplayTimePeriod.displayName}</div>`;
  if (!isSameMetricComparison) {
    mainLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${metricsDataByWeekId.displayName}</div>`;
  }
  legendDivs.push(mainLegendDiv);
};

const computePercentChangeChecked = (y0, y1) => {
  if (y0 <= 0) {
    return y1 > 0 ? 100 : 0;
  }

  return (y1 - y0) / y0;
};

const comparingSamePeriod = (chartSeries) => {
  return chartSeries && chartSeries.length === 2 && chartSeries[0].name === chartSeries[1].name;
};

export function getWeeklyTrendChartParameters({
  chartPropsOverride,
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod,
  mainEntityMetrics,
  mainMetricName,
  comparisonEntityMetrics,
  comparisonMetricName,
  groupByFieldName,
  widget,
  mainLegendValueOverride,
  comparisonLegendValueOverride
}) {
  const isAllowedDriveComparison = () => {
    if (isDrive) {
      return chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id === 'mtd';
    }
    return false;
  };

  if (isAllowedDriveComparison()) {
    fillInMissingTimePeriods(chartComparisonDisplayTimePeriod, mainEntityMetrics, groupByFieldName);
  }

  mainEntityMetrics = fillInMissingTimePeriods(chartDisplayTimePeriod, mainEntityMetrics, groupByFieldName);
  const chartCompState = createInitialChartCompState({
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    mainEntityMetrics,
    mainMetricName,
    comparisonEntityMetrics,
    comparisonMetricName,
    groupByFieldName,
    widget
  });

  const showOnlyMainSeries = _get(widget, ['view', 'showOnlyMainSeries'], false);
  const { metricsComparisonDataByWeekId, mainCurrencySymbol, comparisonCurrencySymbol } = chartCompState;
  populateFirstChartSeries(chartCompState, mainLegendValueOverride, comparisonLegendValueOverride);

  if (mainLegendValueOverride) {
    chartCompState.mainLegendValue = mainLegendValueOverride;
  }

  if (chartComparisonDisplayTimePeriod && metricsComparisonDataByWeekId) {
    computeComparisonMetrics(chartCompState);

    // Populates with correct timestamp
    populateComparisonSeriesData(chartCompState);

    chartCompState.comparisonLegendValue = getComparisonLegendValue(chartCompState);

    if (comparisonLegendValueOverride) {
      chartCompState.comparisonLegendValue = comparisonLegendValueOverride;
    }

    // use the correct marketshare logic here
    if (mainMetricName.includes('MarketShare')) {
      (() => {
        // Find the key of `entitySearchService` from which `mainEntityMetrics` comes.  This is necessary in order to
        // determine the data key of the market share denominator metrics.
        const tuple = Object.entries(store.getState().entitySearchService).find(
          ([_key, val]) => val === mainEntityMetrics
        );
        if (!tuple) {
          error(`No key in ESS matching \`mainEntityMetrics\` for \`mainMetricName\` "${mainMetricName}"`);
          return;
        }

        const mainStartWeek = chartCompState.loadLastWeek
          ? computeLastWeek(chartComparisonDisplayTimePeriod.startWeek)
          : chartComparisonDisplayTimePeriod.startWeek;

        const mainEntityMetricsESSDataKey = tuple[0];
        const marketShareDenominatorESSDataKey = `${mainEntityMetricsESSDataKey}_marketshare`;

        let accurateComputedMarketShare = computeMarketShareComparisonLegendValue({
          mainStartWeek, // actually the comparison week
          mainMetricName,
          mainEntityMetrics,
          chartDisplayTimePeriod,
          marketShareDenominatorESSDataKey,
          chartComparisonDisplayTimePeriod
        });

        if (
          chartComparisonDisplayTimePeriod.startWeek === chartDisplayTimePeriod.startWeek &&
          chartComparisonDisplayTimePeriod.endWeek === chartDisplayTimePeriod.endWeek
        ) {
          accurateComputedMarketShare = null;
        }

        if (accurateComputedMarketShare !== null) {
          chartCompState.comparisonLegendValue = accurateComputedMarketShare;
        }
      })();
    }

    chartCompState.comparisonLegendMetricValue = buildMetricValue(
      chartCompState.comparisonLegendValue,
      metricsComparisonDataByWeekId.metricType,
      comparisonCurrencySymbol,
      true,
      metricsComparisonDataByWeekId.dataType,
      chartCompState.comparisonLocale
    );
    createComparisonLegendDivs(chartCompState);
    if (
      showOnlyMainSeries &&
      mainMetricName === comparisonMetricName &&
      _isEqual(mainEntityMetrics, comparisonEntityMetrics)
    ) {
      chartCompState.chartSeries.splice(1, 1);
    }
  } else {
    createNonComparisonLegendDivs(chartCompState);
  }

  const { chartSeries, legendDivs } = chartCompState;
  [chartSeries[0].legendDiv, chartSeries[1].legendDiv] = legendDivs;
  const xAxis = [];

  // Realigns data if the start weeks do not line up
  // A case where this happens is when we have to shift the comparison period to account for 202053
  if (
    chartComparisonDisplayTimePeriod.id === 'prior-year' &&
    chartSeries[0].data.length > 0 &&
    chartSeries[1].data.length > 0 &&
    chartSeries[0].data.length === chartSeries[1].data.length &&
    chartSeries[0].data[0][0] !== chartSeries[1].data[0][0]
  ) {
    chartSeries[0].data.forEach((dataPoint, index) => {
      // Update time stamps so time periods line up
      if (chartSeries[1].data && chartSeries[1].data[index]) {
        chartSeries[1].data[index] = [dataPoint[0], chartSeries[1].data[index][1]];
      }
    });
  }

  const longestSeriesData =
    chartSeries[0].data.length > chartSeries[1].data.length ? chartSeries[0].data : chartSeries[1].data;

  if (longestSeriesData.length > 0) {
    let xAxisMin = longestSeriesData[0][0];
    let xAxisMax = longestSeriesData[longestSeriesData.length - 1][0];
    if (chartDisplayTimePeriod.id === 'ytd' || chartDisplayTimePeriod.id === '1w') {
      // Always show the full year so we can show the comparison period "tail"
      xAxisMin = getWeekLastDate(chartDisplayTimePeriod.firstWeekOfYear).getTime();
      xAxisMax = getWeekLastDate(chartDisplayTimePeriod.lastWeekOfYear).getTime();
    }

    if (chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id === '1w') {
      xAxisMin = getWeekLastDate(chartComparisonDisplayTimePeriod.startWeek).getTime();
      xAxisMax = getWeekLastDate(chartDisplayTimePeriod.endWeek).getTime();
    }

    let mainSeriesClone = chartSeries[0].data;

    if (chartDisplayTimePeriod.id === 'ytd' && showOnlyMainSeries) {
      const endTimeString = chartDisplayTimePeriod.timePeriod.endWeekEndDate.getTime();
      mainSeriesClone = chartSeries[0].data.filter((item) => item[0] < endTimeString);
      [xAxisMax] = mainSeriesClone[mainSeriesClone.length - 1];
    }

    if (
      chartComparisonDisplayTimePeriod &&
      chartComparisonDisplayTimePeriod.id === 'prior-period' &&
      chartDisplayTimePeriod.id !== '1w' &&
      chartDisplayTimePeriod.startWeek !== chartDisplayTimePeriod.endWeek &&
      chartSeries[1].data.length &&
      chartSeries[0].data.length &&
      (!showOnlyMainSeries || chartDisplayTimePeriod.id !== 'ytd')
    ) {
      [[xAxisMin]] = chartSeries[1].data;
      [xAxisMax] = mainSeriesClone[mainSeriesClone.length - 1];
    }

    if (chartComparisonDisplayTimePeriod.id === 'prior-period' && showOnlyMainSeries) {
      if (Array.isArray(chartSeries[0].data[0])) {
        [[xAxisMin]] = chartSeries[0].data;
      } else {
        xAxisMin = chartSeries[0].data[0].x;
      }
    }

    xAxis.push({
      className:
        chartDisplayTimePeriod.id === 'ytd' || chartDisplayTimePeriod.id === '1w'
          ? 'highcharts-axis-labels highcharts-xaxis-labels-weekly-trend'
          : '',
      min: xAxisMin,
      max: xAxisMax,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        align: 'left'
      },
      showLastLabel: chartDisplayTimePeriod.id !== 'ytd' && chartDisplayTimePeriod.endWeek % 100 !== 53
    });
  } else {
    xAxis.push({});
  }

  // setting the max yAxis to 100% for In-Stock Rate and Buy Box - Rate
  let maxValueForYAxis;
  if (
    widget &&
    widget.view &&
    (widget.view.displayName === 'In-Stock Rate' || widget.view.displayName === 'Buy Box - Rate')
  ) {
    maxValueForYAxis = 1;
  }

  let chartProps = {
    chart: { type: 'areaspline' },
    title: { text: chartCompState.metricsDataByWeekId.displayName },
    subtitle: { text: chartCompState.metricsDataByWeekId.subtitle },
    plotOptions: {
      fillEnabled: true,
      series: {
        states: {
          hover: {
            enabled: true
          }
        }
      }
    },
    legend: {
      labelFormatter() {
        return this.userOptions.legendDiv;
      }
    },
    tooltip: {
      formatter() {
        let tooltipStyle = '';
        let percentChangeDiv = '';
        // compute metric change, only if there are two metrics and they are of same type
        if (chartCompState.isSameMetricComparison && this.points.length > 1) {
          let metricsChange = { icon: '+', color: colors.green };
          let percentChange = computePercentChangeChecked(this.points[0].y, this.points[1].y);

          // if the change is negative, then display in red with minus icon
          if (percentChange < 0) {
            metricsChange = { icon: '\u2212', color: colors.red };
            percentChange *= -1;
          }

          percentChange = buildMetricValue(
            percentChange,
            METRICTYPE.PERCENT,
            mainCurrencySymbol,
            true,
            null,
            chartCompState.mainLocale
          ).value;

          const tooltipWidth =
            100 +
            (this.points[0].x.name
              ? Math.min(this.points[0].x.name.length, 10) * 6
              : percentChange.toString().length * 7);
          percentChangeDiv = `<div style="display:inline-block; float:right; color:${metricsChange.color};">
              ${metricsChange.icon}${percentChange}%
            </div>`;
          tooltipStyle += `width: ${tooltipWidth}px;`;
        }

        const mainWeekId = getWeekId(this.points[0].x);
        const { lastWeekOfYear: mainLastWeekOfYear } = chartDisplayTimePeriod;
        let weekId = mainWeekId;
        if (mainWeekId > mainLastWeekOfYear && mainWeekId % 100 === 1) {
          // do 53 as the week
          weekId = +`${mainLastWeekOfYear}`;
        }

        let yAxisMetricsDiv = '';
        for (let i = this.points.length - 1; i >= 0; i -= 1) {
          const value = this.points[i];
          if (value.point.index === 0 && chartCompState.loadLastWeek) {
            percentChangeDiv = '';
          } else {
            const { color } = chartSeries[value.series.index];
            const metricValue = buildMetricValue(
              value.y,
              chartSeries[value.series.index].metricType,
              chartCompState.currencySymbols[value.series.index],
              true,
              null,
              chartCompState.locales[value.series.index]
            );
            let weekIdToUse =
              i === 0
                ? color === '#46a8f6' ||
                  chartComparisonDisplayTimePeriod.id === 'prior-period' ||
                  comparingSamePeriod(chartSeries)
                  ? weekId
                  : weekId - 100
                : weekId;

            // Used to display the correct week ending after shifting to account for week 53
            // need to shift if start week in 2020 (or other leap year) and end week is 53 or in 2021
            const comparisonStartYear = Math.floor(chartComparisonDisplayTimePeriod.startWeek / 100);
            const comparisonEndYear = Math.floor(chartComparisonDisplayTimePeriod.endWeek / 100);
            const comparisonEndWeek = chartComparisonDisplayTimePeriod.endWeek % 100;

            const needToMoveForward =
              moment([comparisonStartYear]).isLeapYear() &&
              (comparisonStartYear !== comparisonEndYear || comparisonEndWeek === 53);
            if (i === 0 && chartComparisonDisplayTimePeriod.id === 'prior-year') {
              if (needToMoveForward && weekIdToUse < +`${comparisonStartYear}53`) {
                weekIdToUse++;
              }
            }

            yAxisMetricsDiv += `
            <div style="margin-top:5px;color:${color};">
                ${metricValue.prefix || ''}${metricValue.value.toString().trim()}
                <span class='sl-metric__suffix'>${metricValue.suffix || ''}</span>
                <span class="sl-metric__week-ending">${moment(getWeekLastDate(weekIdToUse)).format('M/D')}</span>
            </div>`;
          }
        }

        return yAxisMetricsDiv === ''
          ? false
          : getTooltipDiv(tooltipStyle, percentChangeDiv, yAxisMetricsDiv, this.x, undefined, weekId);
      },
      positioner(labelWidth, labelHeight, point) {
        let tooltipX;
        let tooltipY;
        const { chart } = this;

        // X position
        if (point.plotX + labelWidth + 10 < chart.plotWidth) {
          tooltipX = point.plotX + chart.plotLeft;
        } else {
          tooltipX = point.plotX + chart.plotLeft - labelWidth - 10;
        }

        // Y position
        if (point.plotY - labelHeight - 10 > 0) {
          tooltipY = point.plotY + chart.plotTop - labelHeight - 10;
        } else {
          tooltipY = point.plotY + chart.plotTop + 10;
        }

        return { x: tooltipX, y: tooltipY };
      }
    },
    xAxis,
    yAxis: [
      {
        max: maxValueForYAxis,
        labels: {
          formatter() {
            const val = buildMetricValue(
              this.value,
              chartCompState.metricsDataByWeekId.metricType,
              chartCompState.currencySymbols[0],
              true,
              null,
              chartCompState.locales[0]
            );
            return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
          }
        }
      },
      {
        opposite: true,
        labels: {
          formatter() {
            const val = buildMetricValue(
              this.value,
              metricsComparisonDataByWeekId.metricType,
              chartCompState.currencySymbols[1],
              true,
              null,
              chartCompState.locales[1]
            );
            return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
          }
        }
      }
    ]
  };
  // const shouldMergeAxes = getShouldMergeAxes(chartCompState);

  chartProps = _merge(chartProps, chartPropsOverride);

  // check !shouldMergeAxes if you want to merge to Y-axisx with similar max-y value.
  if (!chartCompState.isSameMetricComparison && !chartProps.singleYAxis) {
    const titleStyle = {
      'font-size': '12px',
      'font-family': "'Roboto', sans-serif",
      'font-weight': fontStyle.regularWeight,
      color: chartColorsLineChart[0]
    };
    const cmpTitleStyle = {
      'font-size': '12px',
      'font-family': "'Roboto', sans-serif",
      'font-weight': fontStyle.regularWeight,
      color: chartColorsLineChart[1]
    };

    chartProps.yAxis[0].title = {
      text: chartCompState.metricsDataByWeekId.displayName,
      useHTML: true,
      style: titleStyle
    };
    chartProps.yAxis.push({
      title: {
        text: metricsComparisonDataByWeekId.displayName,
        useHTML: true,
        style: cmpTitleStyle
      },
      labels: {
        formatter() {
          const val = buildMetricValue(
            this.value,
            metricsComparisonDataByWeekId.metricType,
            mainCurrencySymbol,
            true,
            null,
            chartCompState.mainLocale
          );
          return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
        }
      },
      opposite: true
    });
    chartSeries[1].yAxis = 1;
  } else {
    chartSeries[1].yAxis = 0;
  }

  // SWITCH ORDER TO OLD-TO-NEW
  chartSeries.push(chartSeries.shift());

  const { mainLegendMetricValue, mainLegendValue, comparisonLegendValue } = chartCompState;

  chartProps.legend.mainLegendDisplayValue = `${mainLegendMetricValue.prefix || ''}${mainLegendMetricValue.value}${
    mainLegendMetricValue.suffix || ''
  }`;

  chartProps.legend.metricsChangePercent = (mainLegendValue - comparisonLegendValue) / comparisonLegendValue;
  return {
    chartProps,
    chartSeries,
    comparisonLegendValue,
    mainLegendValue,
    comparisonLegendMetricValue: chartCompState.comparisonLegendMetricValue,
    mainLegendMetricValue
  };
}

const WeeklyTrendChartInner = ({
  view,
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod,
  mainEntityMetrics,
  comparisonEntityMetrics,
  metricName,
  indexName,
  groupByFieldName,
  comparisonMetricConfig
}) => {
  const comparisonMetricName =
    comparisonMetricConfig.type === 'metric'
      ? _get(comparisonMetricConfig.metricField, 'name', metricName)
      : metricName;

  // if comparison is selected, then you compare them over the same period
  let chartComparisonTimePeriod =
    comparisonMetricConfig.type &&
    (comparisonMetricConfig.indexName !== indexName || comparisonMetricConfig.metricName !== metricName)
      ? chartDisplayTimePeriod
      : chartComparisonDisplayTimePeriod;

  if (comparisonMetricConfig.type && comparisonMetricConfig.type !== 'metric') {
    chartComparisonTimePeriod = chartDisplayTimePeriod;
  }

  if (
    !mainEntityMetrics ||
    !metricName ||
    !_get(mainEntityMetrics, [`${metricName}_by_${groupByFieldName}`, 'currencySymbol'])
  ) {
    return null;
  }

  const { chartSeries, chartProps } = getWeeklyTrendChartParameters({
    chartPropsOverride: view.chartPropsOverride,
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod: chartComparisonTimePeriod,
    mainEntityMetrics,
    mainMetricName: `${metricName}_by_${groupByFieldName}`,
    comparisonEntityMetrics,
    comparisonMetricName: `${comparisonMetricName}_by_${groupByFieldName}`,
    groupByFieldName
  });
  return <GenericChart chartSeries={chartSeries} chartProps={chartProps} />;
};

WeeklyTrendChartInner.propTypes = {
  view: PropTypes.object.isRequired,
  chartDisplayTimePeriod: PropTypes.any.isRequired,
  chartComparisonDisplayTimePeriod: PropTypes.any.isRequired,
  mainEntityMetrics: PropTypes.any.isRequired,
  comparisonEntityMetrics: PropTypes.any.isRequired,
  metricName: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  groupByFieldName: PropTypes.any.isRequired,
  comparisonMetricConfig: PropTypes.any.isRequired
};

export const WeeklyTrendChart = React.memo(WeeklyTrendChartInner);

export const renderWeeklyTrendChart = (
  view,
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod,
  mainEntityMetrics,
  comparisonEntityMetrics,
  mainMetricConfig,
  groupByFieldName,
  comparisonMetricConfig
) => {
  const props = {
    view,
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    mainEntityMetrics,
    comparisonEntityMetrics,
    ...mainMetricConfig,
    groupByFieldName,
    comparisonMetricConfig
  };
  return <WeeklyTrendChart {...props} />;
};
