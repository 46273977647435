/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { AppButton } from '@stackline/ui';
import _get from 'lodash/get';
import { IconButton, Modal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CrossIcon, LoadingGearIcon } from 'src/components/SvgIcons/SvgIcons';

const STACKLN_COLOR = '#052849';

const AdButton = styled(AppButton)({
  minWidth: 100,
  height: 30,
  borderRadius: 15,
  border: `solid 1px ${STACKLN_COLOR}`,
  ontFamily: 'Roboto',
  fontSize: 14,
  fontWeight: 500,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 2.86,
  letterSpacing: 'normal',
  textAlign: 'left',
  padding: '6px 8px'
});

const StyledModalBox = styled('main')({
  width: 660,
  height: 610,
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 4,
  '&:focus-visible': {
    outline: 'none'
  }
});

const ModalActionAvatar = styled('div')({
  width: 104,
  height: 104,
  margin: '0px auto',
  padding: '24.6px 24.9px 32.3px 24.9px',
  objectFit: 'contain',
  backgroundColor: '#f2f4f6',
  borderRadius: '50%'
});

const MainContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '100%',
  flex: 1
});

const MainContentInner = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 32,
  textAlign: 'center',
  padding: '0 150px'
});

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '20px',
  paddingBottom: 0
});

export const AdFeedbackModal = ({
  open = false,
  handleClose,
  header = '',
  body = ''
}: {
  open: boolean;
  handleClose: () => void;
  header: string;
  body: string;
}) => {
  return (
    <Modal open={open}>
      <StyledModalBox>
        <Header>
          <IconButton style={{ padding: 0 }} onClick={handleClose}>
            <CrossIcon style={{ padding: 0, height: '40px', width: '40px' }} />
          </IconButton>
        </Header>

        <MainContent>
          <MainContentInner>
            <ModalActionAvatar>
              <LoadingGearIcon className="sl-rotate-animation" />
            </ModalActionAvatar>
            <Typography fontSize={28} fontWeight={500}>
              {header}
            </Typography>
            <Typography fontSize={18}>{body}</Typography>

            <AdButton style={{ minWidth: 180 }} invertStyle onClick={handleClose}>
              Close
            </AdButton>
          </MainContentInner>
        </MainContent>
      </StyledModalBox>
    </Modal>
  );
};
