import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import Waypoint from 'react-waypoint';
import OmniKeywordSideBar from 'src/components/Omni/OmniKeywordList/OmniKeywordSideBar/OmniKeywordSideBar';
import { OmniTableContainer } from 'src/components/EntityGrid/EntityGrid/OmniEntityGrid';
import { mkChipButtonCellFormatter } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import { usePagination } from 'src/utils/Hooks';
import queryString from 'qs';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import {
  processListData,
  formKeywordSegmentRequestBody
} from 'src/components/Omni/OmniKeywordList/omniKeywordListUtil';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import './omniKeywordList.scss';

const DefaultGridLoading = () => (
  <div className="omni-keyword-search-loading">
    <GridLoading />
  </div>
);

const OmniKeywordSearchContainer: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const mainTimePeriod = useSelector((store: ReduxStore) => store.mainTimePeriod);
  const { location } = props;
  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 10e8 });
  const requestBody = formKeywordSegmentRequestBody(queryParams, mainTimePeriod);
  const [pageNumber, pageSize, handleRequestChange, isLoading, dataList] = usePagination(
    '/omni/segment/getShareOfShelfKeywords',
    requestBody,
    'data.data.searchTerm.buckets',
    'data.pageNumber',
    'data.pageSize',
    processListData
  );

  const excludeColumnDef = {
    name: 'action',
    displayName: '',
    entity: {},
    width: 85,
    dataType: null,
    metricType: null,
    cellRendererFramework: mkChipButtonCellFormatter('addToExcludeSearchTerm', 'Exclude')
  };

  const omniTableViewFieldForTableView = [
    {
      currencySymbol: '',
      name: 'shareOfShelfRate',
      headerName: 'Total Share of Shelf',
      metricType: METRICTYPE.PERCENT,
      aggregationFunction: 'avg',
      metricName: 'shareOfShelfRate',
      shareOfShelfType: 'total',
      dataKey: 'totalShareOfShelf',
      widthForColumn: 200
    },
    {
      currencySymbol: '',
      name: 'shareOfShelfRate',
      headerName: 'Paid Share of Shelf',
      metricType: METRICTYPE.PERCENT,
      aggregationFunction: 'avg',
      metricName: 'shareOfShelfRate',
      shareOfShelfType: 'paid',
      dataKey: 'paidShareOfShelf',
      widthForColumn: 200
    },
    {
      currencySymbol: '',
      name: 'shareOfShelfRate',
      headerName: 'Organic Share of Shelf',
      metricType: METRICTYPE.PERCENT,
      aggregationFunction: 'avg',
      metricName: 'shareOfShelfRate',
      shareOfShelfType: 'organic',
      dataKey: 'organicShareOfShelf',
      widthForColumn: 200
    }
  ];

  const handleWaypointEntered = () => {
    if (isLoading || pageNumber === pageSize || pageSize < 20) {
      return;
    }
    handleRequestChange({}, { pageNumber: pageNumber + 1, pageSize });
  };

  return (
    <div className="omni-search-list">
      <OmniKeywordSideBar {...props} handleRequestChange={handleRequestChange} />
      <div className="omni-search-list__content">
        <div style={{ height: 805, overflow: 'scroll' }}>
          {isLoading && pageNumber === 1 ? (
            <DefaultGridLoading />
          ) : (
            <OmniTableContainer
              dataToRender={dataList}
              omniTableViewFieldForTableView={omniTableViewFieldForTableView}
              extraColumnDef={excludeColumnDef}
              onClickToSort={() => {}}
              groupByField={{
                displayName: 'Keyword',
                name: 'keyword'
              }}
              styles={{
                width: '100%',
                height: '100%',
                marginTop: '17px'
              }}
              distanceToFirstCol={600}
            >
              <Waypoint onEnter={handleWaypointEntered} />
              <br />
              <br />
            </OmniTableContainer>
          )}
          {isLoading && <DefaultGridLoading />}
        </div>
      </div>
    </div>
  );
};

export default withRouter(OmniKeywordSearchContainer);
