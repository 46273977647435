import _merge from 'lodash/merge';
import { getBaseLineChartSeries, chartColorsLineChart } from 'src/components/EntityPage/Renderer/util';
import { buildMetricValue, computePercentChange } from 'src/utils/metrics';
import { getNextWeek, getWeekLastDate } from 'src/utils/dateformatting';
import _differenceBy from 'lodash/differenceBy';
import moment from 'moment';
import { getTooltipDiv } from 'src/components/EntityPage/Renderer/tooltipDiv';
import colors from 'src/utils/colors';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { getWeekId } from 'src/utils/dateUtils';
import ReduxStore from 'src/types/store/reduxStore';
import { ChartDisplayTimePeriod, ChartCompState } from 'src/components/EntityPage/TrendChart/types';

interface ParsedShortageResponse {
  weekId: number;
  name: number;
  value: number;
  weekEnding: Date;
  weekEndingNextYear: Date;
}

export const fillInMissingTimePeriodsForShortagesMultiMetric = (
  chartDisplayTimePeriod: ChartDisplayTimePeriod,
  data: ParsedShortageResponse[][]
) => {
  const { timePeriod } = chartDisplayTimePeriod;

  const { startWeek, endWeek } = timePeriod;
  const xAxisKey = 'name';
  let currWeek = startWeek;
  const arr = [];
  while (currWeek <= endWeek) {
    arr.push({
      name: String(currWeek),
      weekId: currWeek,
      weekEnding: getWeekLastDate(currWeek),
      weekEndingNextYear: getWeekLastDate(currWeek * 1 + 100),
      isFilled: true,
      value: 0
    });
    currWeek = getNextWeek(currWeek);
  }

  // We need to loop through the different series in `data`
  data.forEach((series) => {
    const diffForMain = _differenceBy(arr, series, xAxisKey);
    series.push(...diffForMain);

    series.sort((a, b) => {
      if (a[xAxisKey] < b[xAxisKey]) {
        return -1;
      }
      if (a[xAxisKey] > b[xAxisKey]) {
        return 1;
      }
      return 0;
    });
  });

  return data;
};

export const createInitialMultiMetricChartState = (
  chartDisplayTimePeriod: ChartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod: ChartDisplayTimePeriod,
  data: any[],
  groupByFieldName: string,
  view: any,
  retailer: any
): ChartCompState => {
  const { metricFields } = view;
  const [metricField] = metricFields;
  const metricsDataByWeekId = {
    data: data[0],
    aggregationFunction: metricField.aggregationFunction,
    metricType: metricField.metricType,
    dataType: metricField.dataType
  };

  const { currencySymbol, locale } = retailer;

  const mainCurrencySymbol = currencySymbol;
  const comparisonCurrencySymbol = currencySymbol;
  const mainLocale = locale;
  const comparisonLocale = locale;
  const currencySymbols = [mainCurrencySymbol, comparisonCurrencySymbol];
  const locales = [mainLocale, comparisonLocale];
  const isSameMetricComparison = true;
  return {
    chartSeries: getBaseLineChartSeries(chartDisplayTimePeriod, data),
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    groupByFieldName,
    metricsDataByWeekId,
    mainLocale,
    mainCurrencySymbol,
    currencySymbols,
    isSameMetricComparison,
    locales,
    legendDivs: [],
    // The following are set by `populateFirstChartSeries`
    loadLastWeek: false,
    mainLegendMetricValue: 0,
    mainLegendValue: 0,
    mainMetricSum: 0,
    comparisonLegendValue: 0,
    comparisonCurrencySymbol,
    comparisonMetricSum: 0,
    comparisonMetricDataPointCount: 0,
    comparisonMetricLastValue: 0,
    comparisonMetricTrueSum: 0,
    comparisonMetricTrueCount: 0
  };
};

// This is when we are plotting two different metrics in the same time period, e.g. beacon shortages
export const getMultiMetricWeeklyTrendChartParameters = (
  chartPropsOverride: any,
  data: ParsedShortageResponse[][],
  chartDisplayTimePeriod: ChartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod: ChartDisplayTimePeriod,
  comparisonTimePeriod: ReduxStore['comparisonTimePeriod'],
  groupByFieldName: string,
  chartViewConfig: any,
  retailer: any
) => {
  if (!data || !data.length) {
    return null;
  }

  const filledData = fillInMissingTimePeriodsForShortagesMultiMetric(chartDisplayTimePeriod, data);
  const chartCompState = createInitialMultiMetricChartState(
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    filledData,
    groupByFieldName,
    chartViewConfig,
    retailer
  );

  const { metricFields } = chartViewConfig;
  const [metricField] = metricFields;
  const newChartSeries = [];
  data.forEach((series, index) => {
    newChartSeries.push({
      name: 'COMPARISON',
      metricType: metricField.metricType,
      data: series.map((item) => [item.weekEnding.getTime(), item.value]),
      color: chartColorsLineChart[index],
      marker: {
        lineColor: chartColorsLineChart[index],
        fillColor: chartColorsLineChart[index],
        lineWidth: 3,
        symbol: 'circle'
      }
    });
  });

  newChartSeries[0].legendDiv = `
    <div>Disputes Won</div>\n
    <div class="legend__primary-date" style="margin-top:5px;">${chartDisplayTimePeriod.displayName}</div>`;

  newChartSeries[1].legendDiv = `
  <div>Total Shortages</div>\n
  <div class="legend__primary-date" style="margin-top:5px;">${chartDisplayTimePeriod.displayName}</div>`;

  // We start deriving our min/max values base don the time period selected
  const generateAxisFromTimePeriod = (period) => {
    // Occasionally, the period startWeekStartDate and endWeekEndDate is a string instead of a Date object.
    // We check if getTime exists on our property and if not, we slice the string and convert to a date object.
    if (typeof period.startWeekStartDate.getTime !== 'function') {
      const startYear = period.startWeekStartDate.slice(0, 4);
      const startMonth = period.startWeekStartDate.slice(4, 6) - 1;
      const startDay = period.startWeekStartDate.slice(6, 8);
      const min = new Date(startYear, startMonth, startDay);

      const endYear = period.endWeekEndDate.slice(0, 4);
      const endMonth = period.endWeekEndDate.slice(4, 6) - 1;
      const endDay = period.endWeekEndDate.slice(6, 8);
      const max = new Date(endYear, endMonth, endDay);
      return { min, max };
    }
    const min = period.startWeekStartDate.getTime();
    const max = period.endWeekEndDate.getTime();
    return { min, max };
  };

  const chartSeries = newChartSeries;
  const xAxis = [];
  const { timePeriod } = chartDisplayTimePeriod;
  const { min, max } = generateAxisFromTimePeriod(timePeriod);

  xAxis.push({
    className:
      chartDisplayTimePeriod.id === 'ytd' || chartDisplayTimePeriod.id === '1w'
        ? 'highcharts-axis-labels highcharts-xaxis-labels-weekly-trend'
        : '',
    min,
    max,
    lineWidth: 0,
    tickWidth: 0,
    labels: {
      align: 'left'
    },
    showLastLabel: chartDisplayTimePeriod.id !== 'ytd' && chartDisplayTimePeriod.endWeek % 100 !== 53
  });

  let chartProps = {
    chart: { type: 'areaspline' },
    title: { text: metricField.displayName },
    subtitle: { text: 'All Categories' },
    plotOptions: {
      fillEnabled: true,
      series: {
        states: {
          hover: {
            enabled: true
          }
        }
      }
    },
    legend: {
      labelFormatter() {
        return this.userOptions.legendDiv;
      }
    },
    tooltip: {
      formatter() {
        let tooltipStyle = '';
        let percentChangeDiv = '';

        if (chartCompState.isSameMetricComparison && this.points.length > 1) {
          let metricsChange = { icon: '+', color: colors.green };
          let { percentChange } = computePercentChange(this.points[0].y, this.points[1].y);

          // if the change is negative, then display in red with minus icon
          if (percentChange < 0) {
            metricsChange = { icon: '\u2212', color: colors.red };
            percentChange *= -1;
          }

          percentChange = buildMetricValue(
            percentChange,
            METRICTYPE.PERCENT,
            '',
            true,
            metricField.dataType,
            chartCompState.mainLocale
          ).value;

          const tooltipWidth =
            100 +
            (this.points[0].x.name
              ? Math.min(this.points[0].x.name.length, 10) * 6
              : percentChange.toString().length * 7);
          percentChangeDiv = `<div style="display:inline-block; float:right; color:${metricsChange.color};">
              ${metricsChange.icon}${percentChange}%
            </div>`;
          tooltipStyle += `width: ${tooltipWidth}px;`;
        }

        const mainWeekId = getWeekId(this.points[0].x);
        const { lastWeekOfYear: mainLastWeekOfYear } = chartDisplayTimePeriod;
        let weekId = mainWeekId;
        if (mainWeekId > mainLastWeekOfYear && mainWeekId % 100 === 1) {
          // do 53 as the week
          weekId = +`${mainLastWeekOfYear}`;
        }

        let yAxisMetricsDiv = '';
        for (let i = this.points.length - 1; i >= 0; i -= 1) {
          const pointValue = this.points[i];
          if (pointValue.point.index === 0 && chartCompState.loadLastWeek) {
            percentChangeDiv = '';
          } else {
            const { color } = chartSeries[pointValue.series.index];
            const metricValue = buildMetricValue(
              pointValue.y,
              metricField.metricType,
              '',
              true,
              metricField.dataType,
              'en'
            );
            yAxisMetricsDiv += `
            <div style="margin-top:5px;color:${color};">
                ${metricValue.prefix || ''}${metricValue.value.toString().trim()}
                <span class='sl-metric__suffix'>${metricValue.suffix || ''}</span>
                <span class="sl-metric__week-ending">${moment(
                  getWeekLastDate(
                    i === 0
                      ? color === '#46a8f6' || chartComparisonDisplayTimePeriod.id === 'prior-period'
                        ? weekId
                        : weekId - 100
                      : weekId
                  )
                ).format('M/D')}</span>

            </div>`;
          }
        }

        return yAxisMetricsDiv === ''
          ? false
          : getTooltipDiv(tooltipStyle, percentChangeDiv, yAxisMetricsDiv, this.x, undefined, weekId);
      },
      positioner(labelWidth, labelHeight, point) {
        return { x: point.plotX, y: point.plotY - 20 };
      }
    },
    xAxis,
    yAxis: [
      {
        labels: {
          formatter() {
            try {
              const val = buildMetricValue(this.value, metricField.metricType, '', true, metricField.dataType, 'en');
              return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
            } catch (e) {
              console.warn(e);
            }
            return '';
          }
        }
      },
      {
        opposite: true,
        labels: {
          formatter() {
            const val = buildMetricValue(
              this.value,
              metricField.metricType,
              '',
              true,
              metricField.dataType,
              chartCompState.locales[1]
            );
            return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
          }
        }
      }
    ]
  };
  chartProps = _merge(chartProps, chartPropsOverride);
  const { mainLegendMetricValue, mainLegendValue, comparisonLegendValue } = chartCompState;
  chartProps.legend.mainLegendDisplayValue = `${mainLegendMetricValue.prefix || ''}${mainLegendMetricValue.value}${
    mainLegendMetricValue.suffix || ''
  }`;

  chartProps.legend.metricsChangePercent =
    comparisonLegendValue === 0 ? 1000 : (mainLegendValue - comparisonLegendValue) / comparisonLegendValue;
  return { chartProps, chartSeries };
};
