import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';

import { trackLogIn } from 'src/utils/mixpanel';
import { authOperations } from 'src/store/modules/auth';
import BeaconSignInContainer from './BeaconSignIn';
import AtlasSignInContainer from './AtlasSignIn';
import AdvertisingSignInContainer from './AdvertisingSignIn';
import './SignIn.scss';
import SignIn from './SignIn';
import i18n from 'src/i18n_en';
import colors from 'src/utils/colors';
import { AppName } from 'sl-api-connector';
import { isBulkChangeAllowlisted, logDev } from 'src/utils/app';
import { logInFireAndForget } from '../../store/modules/auth/operations';
import UploadProfile from 'src/components/UploadProfile/UploadProfile';
import { getLoginType, getSSOReferrer, handleSSORedirect } from 'src/utils/sso';
import Loading from '../common/Loading';

export const LoginContainers = {
  advertising: { container: AdvertisingSignInContainer, color: '#46a8f6' },
  atlas: { container: AtlasSignInContainer, color: '#3AB2AE' },
  omni: { container: AtlasSignInContainer, color: '#3AB2AE' },
  beacon: { container: BeaconSignInContainer, color: colors.purple },
  discover: { container: AtlasSignInContainer, color: '#3AB2AE' }
};

class SignInContainer extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    clearErrorMessage: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    userLogIn: PropTypes.func.isRequired,
    userSSOLogIn: PropTypes.func.isRequired,
    isProfileImageUploaded: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      buttonText: i18n.button_login,
      loading: false,
      // we assume that user has profile image, and render normal sign in component. - we will check if they have the image at - userLogIn() - handleResponse in Auth operations.js
      isProfileImageUploaded: true,
      email: ''
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  componentWillUnmount() {
    const { clearErrorMessage } = this.props;
    clearErrorMessage();
  }

  handleSignInChange = (value) => {
    const { clearErrorMessage } = this.props;
    clearErrorMessage();
    this.setState(value);
  };

  handleFormSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const { email, password } = this.state;
    const { location, history, setErrorMessage, userLogIn, userSSOLogIn } = this.props;
    if (!email || !password) {
      setErrorMessage(i18n.errorMessage_login_noEmailPassword);
      this.setState({ loading: false });
      return;
    }

    if (this.props.app.name === AppName.Advertising) {
      // Redirect for bulk change
      const isAllowedForBulkChange = isBulkChangeAllowlisted(email);
      if (isAllowedForBulkChange) {
        window.location.href = 'https://ad-bulkupdate.stackline.com/';
      }
    }

    this.setState({
      buttonText: i18n.button_loggingIn
    });

    const {
      app: { targetUrl }
    } = this.props;

    const loginType = getLoginType();

    const { status, redirect, redirectType, isProfileImageUploaded, data } =
      loginType === 'sso'
        ? await userSSOLogIn(targetUrl, email, password, getSSOReferrer())
        : await userLogIn(targetUrl, email, password);

    let pathname;
    let search = '';
    if (status === 'bad') {
      this.setState({ loading: false });
      if (redirectType === 'local' && redirect) {
        history.push(redirect);
      } else {
        this.setState({ buttonText: i18n.button_login });
      }
    } else if (status === 'good') {
      if (loginType === 'sso') {
        handleSSORedirect(data.ssoJwtToken);
      } else {
        await this.loginIntoAdditionalSubDomainsIfApplicable(targetUrl, email, password);

        this.setState({ loading: false });
        if (redirectType === 'window') {
          window.location.href = redirect;
          return;
        } else if (redirectType === 'local') {
          pathname = redirect;
          if (redirect !== '/subscribe' && location.state && location.state.from) {
            ({ pathname, search } = location.state.from);
          }
        }

        if (!isProfileImageUploaded) {
          this.setState({ isProfileImageUploaded: false });
          return;
        } else {
          this.setState({ isProfileImageUploaded: true });
        }

        trackLogIn();

        this.setState({ loading: false });

        history.push({ pathname, search });
      }
    }
  };

  /**
   * Issue a fire-and-forget call to applicable api subdomain so that switching from Drive to Atlas/Beacon/Omni
   * does not require logging-in another time
   */
  async loginIntoAdditionalSubDomainsIfApplicable(originalBaseUrl, email, password) {
    const { app } = this.props;
    // eslint-disable-next-line no-constant-condition
    if (false && app.name === AppName.Advertising && originalBaseUrl.startsWith('ad')) {
      const apiBaseUrl = this.getApiBaseUrl(originalBaseUrl);
      if (originalBaseUrl !== apiBaseUrl) {
        logDev(app.stage, `Triggering an additional login request to ${apiBaseUrl}`);
        await logInFireAndForget(apiBaseUrl, email, password);
      }
    }
  }

  getApiBaseUrl = (hostname) => {
    if (hostname.indexOf('stackline.com') >= 0) {
      return hostname.replace(/atlas|beacon-pro|beacon|ad|omni|discover/, 'api');
    } else {
      return 'api.stackline.com';
    }
  };

  handleEmailChange(email) {
    this.setState({ email });
  }

  render() {
    const { buttonText, loading } = this.state;
    const { app } = this.props;
    const { message } = this.props.auth;

    // show loading spinner if we are on sso domain and get config call is not yet completed
    if (window.location.host.includes('enterprise-sso') && !app?.config?.domainConfig?.domainName) {
      return <Loading style={{ position: 'fixed' }} className="spinner" size={150} thickness={2} />;
    }

    const LoginContainer = BeaconSignInContainer;

    return (
      <div>
        <LoginContainer appName={app.name} notificationMSG={message}>
          {!this.state.isProfileImageUploaded ? (
            <UploadProfile
              email={this.state.email}
              clearErrorMessage={this.props.clearErrorMessage}
              setErrorMessage={this.props.setErrorMessage}
              handleFormSubmit={this.handleFormSubmit}
            />
          ) : (
            <SignIn
              app={app}
              buttonText={buttonText}
              handleChange={this.handleSignInChange}
              handleFormSubmit={this.handleFormSubmit}
              loading={loading}
              email={this.state.email}
              handleEmailChange={this.handleEmailChange}
            />
          )}
        </LoginContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => _pick(state, ['app', 'auth']);

const mapDispatchToProps = {
  userLogIn: authOperations.logIn,
  userSSOLogIn: authOperations.ssoLogIn,
  clearErrorMessage: authOperations.clearMessage,
  setErrorMessage: authOperations.setErrorMessage
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInContainer));
