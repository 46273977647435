import React from 'react';
import './TrafficAD.scss';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';

const TrafficAd = (props) => {
  const items = [
    'Measure organic and paid traffic across the entire competitive landscape.',
    'Get full access to the keywords driving traffic to your category.',
    'Compare your ad spend, conversion rates and ROAS to competitors.',
    'Segment your shoppers based on search behavior.'
  ];
  return (
    <Dialog open={props.open} onClose={props.close} transitionDuration={600}>
      <div className="traffic-ad-container">
        <div // eslint-disable-line
          className="traffic-close"
          onClick={props.close}
        />
        <h1>Send your competitive intelligence off the charts</h1>
        <ul>
          {items.map((item, idx) => (
            <li key={idx} style={{ padding: '8px 0' }}>
              <div className="traffic-list-icon" />
              {item}
            </li>
          ))}
        </ul>
        <div // eslint-disable-line
          id="traffic-button"
          onClick={() => {
            window.open('https://calendly.com/stackline-team', '_blank');
            props.close();
          }}
        >
          SCHEDULE A DEMO
        </div>
      </div>
    </Dialog>
  );
};
TrafficAd.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default TrafficAd;
