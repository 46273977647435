import React, { useMemo } from 'react';
import { useGetWaterfallData } from './serverProxy';
import WaterfallChartV2 from 'src/components/BeaconRedesignComponents/WaterfallChart/WaterfallChartV2';
import { useStacklineTheme } from '@stackline/ui';
import BeaconChartWithLegend from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import { useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { FORECAST_COMPARISON_TO_LABEL_MAP, FORECAST_PERIOD_TO_LABEL_MAP } from './dropdowns';
import { useForecastPeriod, useForecastComparisonPeriod } from './serverProxy/hooks';
import { calculatePercentChange } from 'src/utils/app';
import SplineChartLoading from 'src/components/BeaconRedesignComponents/SplineChartLoading/SplineChartLoading';
import { getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import saveAs from 'file-saver';

/**
 * Display a waterfall chart with forecasted data
 */
const ForecastWaterfallChart = () => {
  const theme = useStacklineTheme();
  const formatMetric = useMetricFormatter();
  const { data: waterfallData, isLoading } = useGetWaterfallData();
  const forecastPeriod = useForecastPeriod();
  const forecastComparisonPeriod = useForecastComparisonPeriod();

  const percentChange = useMemo(() => {
    if (!waterfallData) {
      return 0;
    }
    return calculatePercentChange(waterfallData.currentUnitsSold_sum_value, waterfallData.priorUnitsSold_sum_value);
  }, [waterfallData]);

  const getWaterfallColor = (value: number) => {
    return value >= 0 ? theme.colors.success : theme.colors.accentTangerine;
  };

  const convertSeriesToDelimitedData = () => {
    try {
      let csvString = '';
      const header = [
        `Units Sold (Prior)`,
        'Organic Traffic',
        'Other Traffic',
        'Paid Traffic',
        'Retail Price',
        'Content Score',
        'Ratings & Reviews',
        'In-Stock Rate',
        'Buy Box Rate',
        'Units Sold (Current)'
      ];

      const waterFallContent = [
        Math.round(waterfallData.priorUnitsSold_sum_value),
        Math.round(waterfallData.organicTrafficScaledContribution_sum_value),
        Math.round(waterfallData.otherTrafficScaledContribution_sum_value),
        Math.round(waterfallData.paidTrafficScaledContribution_sum_value),
        Math.round(waterfallData.retailPriceScaledContribution_sum_value),
        Math.round(waterfallData.contentScoreScaledContribution_sum_value),
        Math.round(waterfallData.ratingScaledContribution_sum_value),
        Math.round(waterfallData.inStockRateScaledContribution_sum_value),
        Math.round(waterfallData.buyBoxScaledContribution_sum_value),
        Math.round(waterfallData.currentUnitsSold_sum_value)
      ];

      [header, waterFallContent].forEach((row) => {
        csvString += `${row.join(',')}\n`;
      });
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, `waterfall projections by units sold`);
    } catch (error) {
      console.error('Error - Exporting Forecasts Waterfall charts', error);
    }
    return Promise.resolve();
  };

  return (
    <BeaconChartWithLegend
      title="Waterfall Projection by Units Sold"
      loading={isLoading}
      loadingComponent={<SplineChartLoading />}
      disableExportCsv
      primaryLegendProps={{
        metric: `${formatMetric(waterfallData.currentUnitsSold_sum_value, METRICTYPE.VOLUME, {
          showFullValue: false,
          decimalPlaces: 2
        })}`,
        displayName: FORECAST_PERIOD_TO_LABEL_MAP[forecastPeriod],
        change: `${formatMetric(percentChange, METRICTYPE.PERCENT, { decimalPlaces: 2 })}`,
        direction: getDirection(percentChange)
      }}
      comparisonLegendProps={{
        metric: `${formatMetric(waterfallData.priorUnitsSold_sum_value, METRICTYPE.VOLUME, {
          showFullValue: false,
          decimalPlaces: 2
        })}`,
        displayName: FORECAST_COMPARISON_TO_LABEL_MAP[forecastComparisonPeriod]
      }}
      convertSeriesToDelimitedData={convertSeriesToDelimitedData}
    >
      <WaterfallChartV2
        getData={() => {
          if (isLoading || !waterfallData) {
            return [];
          }

          return [
            {
              color: theme.colors.casper,
              displayName: 'Units Sold\n(Prior)',
              y: waterfallData.priorUnitsSold_sum_value
            },
            {
              color: getWaterfallColor(waterfallData.organicTrafficScaledContribution_sum_value),
              displayName: 'Organic\nTraffic',
              y: waterfallData.organicTrafficScaledContribution_sum_value
            },
            {
              color: getWaterfallColor(waterfallData.otherTrafficScaledContribution_sum_value),
              displayName: 'Other\nTraffic',
              y: waterfallData.otherTrafficScaledContribution_sum_value
            },
            {
              color: getWaterfallColor(waterfallData.paidTrafficScaledContribution_sum_value),
              displayName: 'Paid\nTraffic',
              y: waterfallData.paidTrafficScaledContribution_sum_value
            },
            {
              color: getWaterfallColor(waterfallData.retailPriceScaledContribution_sum_value),
              displayName: 'Retail\nPrice',
              y: waterfallData.retailPriceScaledContribution_sum_value
            },
            {
              color: getWaterfallColor(waterfallData.contentScoreScaledContribution_sum_value),
              displayName: 'Content\nScore',
              y: waterfallData.contentScoreScaledContribution_sum_value
            },
            {
              color: getWaterfallColor(waterfallData.ratingScaledContribution_sum_value),
              displayName: 'Ratings\n& Reviews',
              y: waterfallData.ratingScaledContribution_sum_value
            },
            {
              color: getWaterfallColor(waterfallData.inStockRateScaledContribution_sum_value),
              displayName: 'In-Stock\nRate',
              y: waterfallData.inStockRateScaledContribution_sum_value
            },
            {
              color: getWaterfallColor(waterfallData.buyBoxScaledContribution_sum_value),
              displayName: 'Buy Box\nRate',
              y: waterfallData.buyBoxScaledContribution_sum_value
            },
            {
              color: theme.colors.primary,
              displayName: 'Units Sold\n(Current)',
              y: waterfallData.currentUnitsSold_sum_value
            }
          ];
        }}
      />
    </BeaconChartWithLegend>
  );
};

export default ForecastWaterfallChart;
