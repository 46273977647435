import axios from 'axios';
import _get from 'lodash/get';
import { ThunkDispatch } from 'redux-thunk';

import { buildSubCategoriesAsChildren } from '../subcategories/selectors';
import types from './types';
import Creators from './actions';
import { parseCategories, filterCategoriesByRetailerId } from './selectors';
import ReduxStore from 'src/types/store/reduxStore';
import { CategoryEntity } from 'sl-api-connector';
import { warn } from 'src/utils/mixpanel';

export const {
  clearCategories,
  receiveCategoriesByRetailerId,
  receiveFilteredCategories,
  receiveAllSuperUserCategories,
  sortCategories
} = Creators;

export function setFilteredCategories(categoriesByRetailerId: any, retailer: ReduxStore['retailer']) {
  const filteredCategories = filterCategoriesByRetailerId(categoriesByRetailerId, retailer);
  return (dispatch: ThunkDispatch<ReduxStore, void, any>) => dispatch(receiveFilteredCategories(filteredCategories));
}

export const maybeSortCategories =
  () => (dispatch: ThunkDispatch<ReduxStore, void, any>, getState: () => ReduxStore) => {
    // Check to see if we've already fetched both subcategories and sales by category for the current main entity
    const { entitySearchService, categories } = getState();
    const salesByCategoryId = entitySearchService[types.SALES_BY_CATEGORY_STATE_KEY];
    if (!salesByCategoryId || !categories) {
      return;
    }

    // Build up a mapping of `{ [categoryId]: sales }`
    const salesByCategoryIdMapping = new Map();
    const data = _get(salesByCategoryId, ['retailSales_by_categoryId', 'data']);
    if (!data) {
      warn('Failed to sort category data because none existed to sort');
      return;
    }

    data.forEach(({ entity: { categoryId }, value }: { entity: CategoryEntity; value: number }) =>
      salesByCategoryIdMapping.set(+categoryId, value)
    );

    dispatch(sortCategories(({ categoryId }: CategoryEntity) => salesByCategoryIdMapping.get(+categoryId) || 0));
  };

export const fetchUnfilteredCategories =
  () => async (dispatch: ThunkDispatch<ReduxStore, void, any>, getState: () => ReduxStore) => {
    const appName = getState().app.apiAppName;
    const { retailer, subCategories, user, allSuperUserSubCategories } = getState();
    const request = [
      {
        name: `${appName}-category-following`,
        pageNumber: 1,
        pageSize: 20,
        doAggregation: false,
        returnDocuments: true,
        searchBy: 'parent',
        searchType: `${appName}-category-following`,
        aggregations: null
      }
    ];

    const response = await axios.post(`/api/${appName}/AdvancedSearch?_id=fetchUnfilteredCategories`, request);
    const categories = response.data[0].documents;
    const superUserCategories = _get(
      response,
      ['data', '0', 'additionalResponseMetaData', 'allSuperUserCategories'],
      []
    );
    const subcategoriesByCategoryId = buildSubCategoriesAsChildren(
      user.config.isStacklineSuperUser ? allSuperUserSubCategories : subCategories
    );
    const regularParsedCategories = parseCategories(appName, categories, subcategoriesByCategoryId, retailer);
    const superUserParsedCategories = parseCategories(
      appName,
      superUserCategories,
      subcategoriesByCategoryId,
      retailer
    );

    // We combine the two for the super user, so if there is a retailer without any categories from
    // the client categories, we fall back to all categories for that retailer
    dispatch(receiveCategoriesByRetailerId({ ...superUserParsedCategories, ...regularParsedCategories }));

    dispatch(receiveAllSuperUserCategories(superUserParsedCategories));

    // It's possible that the request to fetch the main entity completed before the request to fetch subcategories, so
    // try to sort subcategories again.
    dispatch(maybeSortCategories());
  };
