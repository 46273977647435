/* eslint-disable react/prop-types */
import React from 'react';
import _ident from 'lodash/identity';
import MetricsGrid from 'src/components/AdManager/MetricsGrid';
import { buildTrendWidgetConfig, summaryTrendView } from 'src/components/Layout/LayoutUtil';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import CommonSummaryTrend from 'src/components/EntityPage/SummaryTrendChart/CommonSummaryTrend';
import 'src/components/AdManager/PortfolioSummaryInfo.scss';
import { connect } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { PageLayout } from 'src/types/application/widgetTypes';
import { MetricField } from 'src/types/application/types';
import { Omit } from 'src/types/utils';
import { withDisplayName } from 'src/utils/hoc';

const mapStateToProps = ({ mainTimePeriod }: ReduxStore) => ({ mainTimePeriod });

const YtdCellHeaderInner: React.FC<ReturnType<typeof mapStateToProps>> = ({ mainTimePeriod }) => (
  <>{mainTimePeriod.shortDisplayName}</>
);

const YtdCellHeader = connect(mapStateToProps)(YtdCellHeaderInner);

export const getLayout = ({
  app,
  entity
}: {
  app: ReduxStore['app'];
  entity: { type: string; [key: string]: any };
}): Partial<PageLayout> => {
  const indexName = 'adCampaignAdGroupProductTargetDailyMetrics' as const;
  const weekIdField = INDEX_FIELDS.getField(app.name, indexName, 'weekId', entity.type);

  const trendChartContainerStyle = {
    padding: 2,
    marginBottom: 0
  };

  const trendChartPropsOverride: Omit<typeof summaryTrendView, 'chart'> & {
    chart: { [key: string]: any };
  } = summaryTrendView;
  trendChartPropsOverride.chart.height = 70;
  trendChartPropsOverride.chart.width = 300;

  const gridFieldDefs: ({ fieldName: string; stripDecimals: boolean } | null)[] = [
    { fieldName: 'impressions', stripDecimals: true },
    { fieldName: 'clickThroughRate', stripDecimals: false },
    { fieldName: 'clicks', stripDecimals: false },
    null,
    { fieldName: 'spend', stripDecimals: false },
    { fieldName: 'costPerClick', stripDecimals: false },
    { fieldName: 'costPerAcquisition', stripDecimals: false },
    null,
    { fieldName: 'sales', stripDecimals: false },
    { fieldName: 'conversionRate', stripDecimals: false },
    { fieldName: 'unitsSold', stripDecimals: true },
    { fieldName: 'returnOnAdSpend', stripDecimals: false },
    // The beacon-advertising-brandGrossMargin is fake; it's currently calculated as 18% of the average ad sale price.
    // Computing the true value for this will require:
    //  1) Fetching the full list of products for the campaign
    //  2) Computing the gross margin for each of them
    //  3) Creating a weighted average of these gross margin values, weighting by (retailSales * unitsSold) for each
    { fieldName: 'brandGrossMargin', stripDecimals: false }
  ];

  const gridFields: ((MetricField & { stripDecimals: boolean }) | null)[] = gridFieldDefs.map((def) =>
    def
      ? {
          ...INDEX_FIELDS.getField(app.name, 'adCampaignAdGroupProductTargetDailyMetrics', def.fieldName),
          stripDecimals: def.stripDecimals
        }
      : null
  );

  // These fields correspond to the grid fields and represent the Atlas metrics that will be used as the divisor when
  // calculating market share for each of them.
  const totalMarketFields = [
    'searchVolume',
    'adClickThroughRate',
    'adClicks',
    // null, // target count, which I assume is cardinality of either or both `stacklineSku`/`searchKeyword`
    null,
    'adSpend',
    'adCostPerClick',
    'costPerAcquisition',
    null,
    'adRetailSales',
    'adConversionRate',
    'adUnitsSold',
    'returnOnAdSpend',
    null
  ].map((fieldName) => (fieldName ? INDEX_FIELDS.getField(app.name, 'traffic', fieldName) : null));
  const marketShareFieldNames = new Set(['searchVolume', 'adClicks', 'adSpend', 'adRetailSales', 'adUnitsSold']);

  // These widgets are rendered inside of the rows of the metrics grid
  const trendChartWidgets = gridFields.map((field) =>
    field
      ? buildTrendWidgetConfig(app, field.indexName!, entity, 'weekId', [field.name!], weekIdField, {
          view: {
            chartPropsOverride: {
              legend: { enabled: false, disableTrendLegend: true },
              ...trendChartPropsOverride
            },
            disableLoadingIndicator: true,
            container: { style: trendChartContainerStyle }
          },
          CustomComponent: CommonSummaryTrend
        })
      : null
  );

  const widgets = [
    {
      CustomComponent: withDisplayName('ClientSummaryInfo')(() => (
        <div className="ad-manager-portfolio-summary">
          <div style={{ marginBottom: 0, paddingBottom: 0, height: 75 }}>
            <div style={{ flex: 1, flexDirection: 'column' }}>
              <h1
                style={{
                  fontSize: 31,
                  marginBottom: 12,
                  paddingBottom: 0
                }}
              >
                Benchmark
              </h1>
              <p
                style={{
                  fontSize: 18,
                  marginTop: 0,
                  paddingTop: 0,
                  marginBottom: 0,
                  paddingBottom: 78
                }}
              >
                The table below showcases the organization&apos;s advertising benchmarks in respect to the overall
                platform.
              </p>
            </div>
          </div>
        </div>
      )),
      name: 'clientSummaryInfo',
      view: {
        name: 'clientSummaryInfo'
      },
      data: {}
    },
    {
      CustomComponent: MetricsGrid,
      name: 'adManagerSummaryMetricsGrid',
      view: {
        name: 'adManagerSummaryMetricsGrid',
        columnNames: ['metrics', 'mainMetrics', 'marketAverage', 'marketShare', 'trend'],
        headerCells: [
          'Metrics',
          <YtdCellHeader key="mainMetrics" />,
          <>
            Market
            <br />
            Average
          </>,
          <>
            Market
            <br />
            Share
          </>,
          ''
        ],
        colWidthPercents: [22, 17, 17, 17, 27]
      },
      data: {
        trendChartWidgets,
        gridFields,
        totalMarketFields,
        marketShareFieldNames
      }
    }
  ].filter(_ident);

  return {
    widgets
  };
};
