import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import numeral from 'numeral';

import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { buildTopEntitiesWidgetConfig } from 'src/components/Layout/LayoutUtil';
import { SectionWrapper, OperationButtons } from '../Widgets/AdCampaignBuilderCommonWidgets';
import { store } from 'src/main';

import '../AdCampaignBuilder.scss';

const muiStyles = {
  root: {},
  select: {}
};

export const buildMarketStatsWidgets = ({ app, entity }, timePeriodOverride) => {
  const {
    adCampaignBuilder: {
      marketStats: { queryConditions }
    }
  } = store.getState();
  return ['adSpend', 'adClicks'].map((metricFieldName, i) => {
    const isMarketShare = i !== 0;
    const indexName = 'traffic-all';
    const metricField = INDEX_FIELDS.getField(app.name, indexName, metricFieldName);
    const weekIdField = INDEX_FIELDS.getField(app.name, indexName, 'weekId');

    const valueFormatter = (value, metric) => {
      if (metric === 'adSpend') {
        return numeral(value).format('$0.0a')
      } else {
        return numeral(value).format('0.0%')
      }
    }
    
    return buildTopEntitiesWidgetConfig(
      app,
      indexName,
      entity,
      [{ groupByFieldName: 'brandId', aggregationFieldNames: [metricFieldName] }],
      10,
      weekIdField,
      {
        data: { isMarketShare, additionalQueryConditions: queryConditions },
        view: {
          metricFields: isMarketShare
            ? [INDEX_FIELDS.getField(app.name, indexName, `${metricFieldName}MarketShare`, entity.type)]
            : [metricField],
          chartPropsOverride: {
            chart: {
              height: 440
            },
            plotOptions: {
              series: {
                dataLabels: {
                  formatter: function formatter() {
                    return valueFormatter(this.y, metricFieldName)
                  }
                }
              }
            },
            title: { x: -10, text: `${metricField.displayName}${isMarketShare ? ' Share' : ''} by Brand` },
            subtitle: {
              text: ' ',
              x: -10
            },
            horizontalScrolling: {
              enabled: false,
              step: 3
            }
          },
          container: {
            style: {
             marginTop: "100px"
            }
          }
        }
      },
      undefined,
      timePeriodOverride
    );
  });
};

const MarketStats = () => (
  <div className="ad-manager-container">
    <SectionWrapper
      header="Market Stats"
      subheader="The metrics below highlight the current competitive environment for your target audience over the past four weeks."
      layer={0}
    >
      Some labels and charts here
    </SectionWrapper>
    <OperationButtons subtab="market" />
  </div>
);

MarketStats.propTypes = {
  classes: PropTypes.object.isRequired
};

const EnhancedMarketStats = withStyles(muiStyles)(MarketStats);

export default EnhancedMarketStats;
