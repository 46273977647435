/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { withBus } from 'react-bus';
import { Option } from 'funfix-core';
import _get from 'lodash/get';
import { Conditions } from 'sl-api-connector/types';
import { mergeConditions } from 'sl-api-connector/search/conditions';

import { useBus } from 'src/utils/Hooks';
import FeaturedEntitiesDialog from 'src/components/EntityPage/WaterfallChart/Insights/FeaturedEntitiesDialog';
import { WidgetProps } from 'src/types/application/widgetTypes';
import { EventBus } from 'src/types/utils';
import { tryGetMulti } from 'src/utils/fp';

const FeaturedCampaignsDialog: React.FC<WidgetProps & { eventBus: EventBus }> = ({ widget, eventBus }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEntityName, setSelectedEntityName] = useState<string | undefined>(undefined);
  const [additionalConditions, setAdditionalConditions] = useState<Conditions | null>(null);
  const [selectedEntity, setSelectedEntity] = useState<string | null>(null);
  const [fieldName, setFieldName] = useState('stacklineSku');

  useBus(
    eventBus,
    'showFeaturedEntities',
    ({ selectedEntity: newSelectedEntity, data }: { selectedEntity: string; data?: { [key: string]: any } }) => {
      const name = Option.of(data)
        // eslint-disable-next-line no-shadow
        .map((data) => tryGetMulti<null | string>(data, [['entity', 'name'], 'name'], null))
        .orNull();
      if (name) {
        setSelectedEntityName(name);
      }

      const matchingType = _get(data, ['entity', 'matchingType']);
      if (matchingType) {
        setAdditionalConditions({ termFilters: [{ fieldName: 'matchingType', values: [matchingType] }] });
      }

      setSelectedEntity(newSelectedEntity);
      setIsOpen(true);
    }
  );

  useBus(eventBus, 'entityGridGroupByChange', ({ groupByFieldName }: { groupByFieldName: string }) =>
    setFieldName(groupByFieldName)
  );

  if (!selectedEntity) {
    return null;
  }

  return (
    <FeaturedEntitiesDialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      // This function takes two arguments: The `mainEntityConditions` created from the global application
      // environment, and `selectedEntity`, which is the `keyword`/`searchKeyword` that corresponds to the
      // currently selected row.  The return value should be a merged conditions object taking into account both
      // the global filters from the main application as well as custom filters derived from the
      // `selectedEntity`.
      //
      // TODO: type these arguments
      mergeCustomFilters={
        // eslint-disable-next-line no-shadow
        (mainEntityConditions: any, selectedEntity?: string) => {
          const selectedEntityCondition: Conditions = {
            termFilters: [
              {
                condition: 'should' as const,
                fieldName,
                values: [selectedEntity!]
              }
            ],
            rangeFilters: []
          };

          const mergedConditions = mergeConditions(mainEntityConditions, selectedEntityCondition);
          return additionalConditions ? mergeConditions(mergedConditions, additionalConditions) : mergedConditions;
        }
      }
      widget={widget}
      selectedEntity={selectedEntity}
      name={selectedEntityName}
    />
  );
};

const EnhancedFeaturedCampaignsDialog = withBus('eventBus')(FeaturedCampaignsDialog);

export default EnhancedFeaturedCampaignsDialog;
