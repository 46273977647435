import * as React from 'react';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import debounce from 'lodash/debounce';
import { FormControl } from '@mui/material';

interface LimitedTextFieldProps {
  characterLimit: number;
  onChange: (value: string) => void;
  initValue: string;
  label: string;
  specialCharsRegex?: RegExp;
  hideLimit?: boolean;
}

const AdLimitedTextField: React.FC<LimitedTextFieldProps> = ({
  characterLimit,
  onChange,
  label,
  initValue = '',
  hideLimit = false,
  specialCharsRegex, // Default value for specialCharsRegex
  ...props
}) => {
  const [value, setValue] = React.useState(initValue);

  const debouncedOnChange = React.useCallback(debounce(onChange, 150), [onChange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (newValue.length <= characterLimit && (specialCharsRegex === undefined || !specialCharsRegex.test(newValue))) {
      setValue(newValue);
      debouncedOnChange(newValue); // Call the debounced function here
    }
  };

  const handleBlur = () => {
    if (value !== initValue) {
      debouncedOnChange(value);
    }
  };

  // This effect is only for handling external changes to initValue
  React.useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  return (
    <Box component="form" noValidate autoComplete="off" {...props}>
      <FormControl sx={{ width: '100%', maxWidth: 1000 }}>
        <TextField
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ maxLength: characterLimit, style: { padding: 0 } }}
          variant="outlined"
          placeholder={label}
          sx={{
            marginTop: 2,
            border: '1px solid #dedede',
            borderRadius: '4px',
            padding: '6px 13px',
            fontSize: 14,
            letterSpacing: 'normal',
            color: '#7e8fa8'
          }}
        />
        {!hideLimit && (
          <FormHelperText
            style={{
              color: 'inherit',
              textAlign: 'right',
              fontSize: 11,
              fontWeight: 500,
              marginRight: 0,
              letterSpacing: 'normal'
            }}
          >
            {characterLimit - value.length} characters remaining
            {specialCharsRegex && ' (no special characters allowed)'}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default AdLimitedTextField;
