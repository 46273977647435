import React, { useMemo, useState } from 'react';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { useBus, useAppSelector } from 'src/utils/Hooks';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import Visualization from 'src/components/Layout/Advertising/ConnectPageLayout/components/Visualization';
import { FirstTouchAttributionDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import FirstTouchAttributionBarChart from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/FirstTouchAttribution/FirstTouchAttributionBarChart';
import {
  AD_PRIMARY_ATTRIBUTION_EVENT,
  AD_SECONDARY_ATTRIBUTION_EVENT,
  FIRST_TOUCH_AD_SALES,
  FIRST_TOUCH_AD_UNITS_SOLD,
  FIRST_TOUCH_CLICKS,
  FIRST_TOUCH_CONVERSIONS,
  FIRST_TOUCH_IMPRESSIONS
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/FirstTouchAttribution/constants';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import useAmcDateRange from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/hooks/useAmcDateRange';
import {
  adFirstTouchAttributionAdvancedSearchParser,
  buildRequestForFirstTouchAttribution
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/FirstTouchAttribution/utils';
import _get from 'lodash/get';

interface FirstTouchAttributionVisualizationProps {
  eventBus: EventBus;
}

const FirstTouchAttributionVisualization = ({ eventBus }: FirstTouchAttributionVisualizationProps) => {
  const [primaryMetric, setPrimaryMetric] = useState(FirstTouchAttributionDropdownOption.Impressions);
  const [secondaryMetric, setSecondaryMetric] = useState(FirstTouchAttributionDropdownOption.AdSales);
  const { startDayId, endDayId } = useAmcDateRange();
  const retailer = useAppSelector((state) => state.retailer);

  useBus(eventBus, AD_PRIMARY_ATTRIBUTION_EVENT, setPrimaryMetric);
  useBus(eventBus, AD_SECONDARY_ATTRIBUTION_EVENT, setSecondaryMetric);

  // Advanced Search Request Body
  const firstTouchAttributionRequestBody = useMemo(
    () => buildRequestForFirstTouchAttribution({ startDayId, endDayId, retailerId: retailer.id }),
    [endDayId, startDayId, retailer.id]
  );

  // Fetching data
  const { data, isLoading } = useGenericAdvancedSearch({
    requestBody: firstTouchAttributionRequestBody,
    queryKeys: firstTouchAttributionRequestBody,
    requestId: 'first-touch-attribution'
  });

  // We will map our dropdown options to the key on our document object to access the data
  const metricToDocumentKeyMap = {
    [FirstTouchAttributionDropdownOption.Impressions]: FIRST_TOUCH_IMPRESSIONS,
    [FirstTouchAttributionDropdownOption.Clicks]: FIRST_TOUCH_CLICKS,
    [FirstTouchAttributionDropdownOption.AdSales]: FIRST_TOUCH_AD_SALES,
    [FirstTouchAttributionDropdownOption.UnitsSold]: FIRST_TOUCH_AD_UNITS_SOLD,
    [FirstTouchAttributionDropdownOption.Conversions]: FIRST_TOUCH_CONVERSIONS
  };

  // Data Access
  const chartData = useMemo(() => {
    if (data) {
      const documentData = adFirstTouchAttributionAdvancedSearchParser(_get(data, 'data[0]'));
      const primaryMetricKey = metricToDocumentKeyMap[primaryMetric];
      const secondaryMetricKey = metricToDocumentKeyMap[secondaryMetric];

      // For the selected primary and secondary dropdown options, get the total of each selected metric
      const metricValueTotals = documentData.reduce(
        (accumulator, current) => {
          if (current.fieldId) {
            accumulator[primaryMetricKey] += current.additionalValues[primaryMetricKey];
            accumulator[secondaryMetricKey] += current.additionalValues[secondaryMetricKey];
          }
          return accumulator;
        },
        {
          [primaryMetricKey]: 0,
          [secondaryMetricKey]: 0
        }
      );

      const metricRatiosByAdType = documentData.reduce(
        (accumulator, current) => {
          if (current.fieldId) {
            const primaryRatio =
              metricValueTotals[primaryMetricKey] !== 0
                ? (current.additionalValues[primaryMetricKey] / metricValueTotals[primaryMetricKey]) * 100
                : 0;

            const secondaryRatio =
              metricValueTotals[secondaryMetricKey] !== 0
                ? (current.additionalValues[secondaryMetricKey] / metricValueTotals[secondaryMetricKey]) * 100
                : 0;

            accumulator[current.fieldId].primaryMetricRatio = primaryRatio;
            accumulator[current.fieldId].secondaryMetricRatio = secondaryRatio;
          }
          return accumulator;
        },
        {
          sponsored_products: { primaryMetricRatio: 0, secondaryMetricRatio: 0 },
          dsp: { primaryMetricRatio: 0, secondaryMetricRatio: 0 },
          sponsored_brands: { primaryMetricRatio: 0, secondaryMetricRatio: 0 },
          sponsored_display: { primaryMetricRatio: 0, secondaryMetricRatio: 0 }
        }
      );

      // We only want the ratio values in an array for use in our chart
      const chartSeries = {
        primaryMetricSeries: Object.values(metricRatiosByAdType).map((adType) => adType.primaryMetricRatio),
        secondaryMetricSeries: Object.values(metricRatiosByAdType).map((adType) => adType.secondaryMetricRatio)
      };

      return chartSeries;
    }
    return null;
  }, [data, primaryMetric, secondaryMetric]);

  return (
    <Visualization
      titleProps={{
        title: ''
      }}
    >
      <div style={{ marginTop: '24px' }}>
        {isLoading && <GenericChartLoading />}
        {!isLoading && data && (
          <FirstTouchAttributionBarChart
            data={chartData}
            primaryMetric={primaryMetric}
            secondaryMetric={secondaryMetric}
          />
        )}
      </div>
    </Visualization>
  );
};

export default withBus('eventBus')(FirstTouchAttributionVisualization);
