/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { DispatchProp, connect } from 'react-redux';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { store } from 'src/main';
import { setCreative } from 'src/store/modules/adManager/adCampaignBuilder/actions';
import fontStyle from 'src/utils/fontStyle';
import ReduxStore from 'src/types/store/reduxStore';
import { SectionWrapper, OperationButtons } from '../Widgets/AdCampaignBuilderCommonWidgets';
import { AdMediaTable } from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdMediaTable/AdMediaTable';
import { useUpdateQueryParams } from 'src/utils/Hooks';
import { AD_MEDIA_MANAGEMENT_URL } from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdMediaManagementPageLayout';
import { RouteComponentProps, withRouter } from 'react-router';

export const muiStyles = {
  root: {
    margin: '20px 0 0 0',
    maxWidth: 250,
    '&:before': {
      content: 'none'
    }
  },
  select: {
    padding: '7px 27px 7px 0px',
    fontWeight: fontStyle.regularWeight,
    width: 250
  },
  textField: {
    width: 250
  }
};

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['adCampaignBuilder'])
});

interface WalmartVideoProps extends RouteComponentProps, DispatchProp {
  adCampaignBuilder: ReduxStore['adCampaignBuilder'];
  history: RouteComponentProps['history'];
}

const WalmartVideo: React.FC<WalmartVideoProps> = ({ adCampaignBuilder, history }) => {
  const [selectedMediaId, setSelectedMediaId] = useState<number | null>(null);
  const updateQuery = useUpdateQueryParams(history);

  const canContinue = _get(adCampaignBuilder, ['creative', 'mediaId']);

  useEffect(() => {
    const { creative } = store.getState().adCampaignBuilder;
    if (selectedMediaId) {
      store.dispatch(
        setCreative({
          ...creative,
          mediaId: selectedMediaId
        })
      );
    } else {
      store.dispatch(
        setCreative({
          ...creative,
          mediaId: null
        })
      );
    }
  }, [selectedMediaId]);

  const handleRedirect = () => {
    // add redirect
    updateQuery({
      additionalParams: {
        tab: AD_MEDIA_MANAGEMENT_URL,
        subtab: null
      }
    });
  };

  return (
    <div className="ad-manager-container">
      <SectionWrapper
        header="Creative Assets"
        subheader={
          <div>
            Select from list of validated assets. To add a new video, please go to{' '}
            <strong
              style={{
                textDecoration: 'underline'
              }}
              role="button"
              onClick={handleRedirect}
            >
              Media Management.
            </strong>
          </div>
        }
      >
        <AdMediaTable setSelectedMediaId={setSelectedMediaId} selectedMediaId={selectedMediaId} />
      </SectionWrapper>

      <OperationButtons subtab="walmartVideo" canContinue={canContinue} />
    </div>
  );
};

export default connect(mapStateToProps)(withRouter(WalmartVideo));
