/* eslint-disable react/prop-types */
import React from 'react';

import starV2 from './star-v2.svg';
import starFilledV2 from './star-filled-v2.svg';

interface Review {
  stars: number;
}

interface StarsV2Props {
  review: Review;
}

/**
 * This Component is rendering stars (1 star => 5 stars) in the ProductReviewCard.
 */

const ReviewStarsV2: FC<StarsV2Props> = ({ review }) => (
  <div>
    {[0, 1, 2, 3, 4].map((val, index) => (
      <svg
        key={val}
        style={{ width: '10.2px', height: '9.8px', marginRight: '6px', marginBottom: '2px' }}
        viewBox={review.stars > index ? starFilledV2.viewBox : starV2.viewBox}
      >
        <use href={`#${review.stars > index ? starFilledV2.id : starV2.id}`} />
      </svg>
    ))}
  </div>
);

export default ReviewStarsV2;
