import React, { ReactNode, useState } from 'react';
import { ExpandRowColumnLayout, SlMenu, hexToRgba, useStacklineTheme } from '@stackline/ui';
import {
  DISPUTE_STAGE,
  DISPUTE_STATUS
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/constants';
import StyledCheckbox from 'src/components/BeaconRedesignComponents/common/StyledCheckbox';
import { Filters } from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeManagementDashboard';
import { DisputeRow } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/types';
import moment from 'moment';
import { formatToDollar } from 'src/utils/money';
import { Box } from '@mui/system';
import { CSSProperties } from '@mui/styles';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BeaconProEllipses } from 'src/components/SvgIcons';
import DisputeFileUploadModal from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/modals/DisputeFileUploadModal';
import { useHistory } from 'src/utils/Hooks';
import { updateUrlQueryParams } from 'src/utils/app';
import { ToggleRowProps } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/DisputeDashboard';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

export const keyMetricCardConfigs = [
  {
    label: 'NEW SHORTAGE - \nNEEDS SUPPORTING DOCS',
    stage: DISPUTE_STAGE.NEW_SHORTAGE,
    status: DISPUTE_STATUS.NEEDS_DOCUMENTS
  },
  {
    label: 'NEW SHORTAGE - \nREADY TO SUBMIT',
    stage: DISPUTE_STAGE.NEW_SHORTAGE,
    status: DISPUTE_STATUS.READY_TO_SUBMIT
  },
  {
    label: 'CONTACT US CASE - \nNEEDS ACTION',
    stage: DISPUTE_STAGE.CONTACT_US_CASE,
    status: DISPUTE_STATUS.NEEDS_ACTION
  },
  {
    label: 'CONTACT US CASE - \nREADY TO SUBMIT',
    stage: DISPUTE_STAGE.CONTACT_US_CASE,
    status: DISPUTE_STATUS.READY_TO_SUBMIT
  },
  { label: 'DISPUTES RESOLVED - \nWON', stage: DISPUTE_STAGE.RESOLVED, status: DISPUTE_STATUS.WON },
  { label: 'DISPUTES RESOLVED - \nPARTIALLY WON', stage: DISPUTE_STAGE.RESOLVED, status: DISPUTE_STATUS.PARTIALLY_WON },
  { label: 'DISPUTES RESOLVED - \nLOST', stage: DISPUTE_STAGE.RESOLVED, status: DISPUTE_STATUS.LOST },
  { label: 'TOTAL DISPUTES - \nSUBMITTED', stage: DISPUTE_STAGE.INITIAL_DISPUTE, status: DISPUTE_STATUS.PENDING }
];

export const initialFilters: Filters = {
  startDate: '',
  endDate: '',
  minValue: '',
  maxValue: '',
  stage: '',
  status: ''
};

interface StageChipProps {
  stage: DISPUTE_STAGE;
  label?: string;
}

interface GenericChipProps {
  content: string | ReactNode;
  color: string;
  width?: string;
  height?: string;
  paddingX?: string;
  paddingY?: string;
  borderRadius?: string;
  sx?: CSSProperties;
}
export const GenericChip = ({
  content,
  color,
  width = 'auto',
  height = '25px',
  paddingX = '25px',
  paddingY = '3px',
  borderRadius = '25px',
  sx = {}
}: GenericChipProps) => {
  return (
    <Box
      display="inline-block"
      paddingX={paddingX}
      paddingY={paddingY}
      sx={{ borderRadius, backgroundColor: color, ...sx }}
      width={width}
      height={height}
    >
      <Box display="inline-block">{content}</Box>
    </Box>
  );
};

const StageChip = ({ stage, label = null }: StageChipProps) => {
  const theme = useStacklineTheme();
  const stageToColor = {
    [DISPUTE_STAGE.NEW_SHORTAGE]: hexToRgba(theme.colors.primaryLight, 0.2),
    [DISPUTE_STAGE.INITIAL_DISPUTE]: hexToRgba(theme.colors.accentGold, 0.2),
    [DISPUTE_STAGE.CONTACT_US_CASE]: hexToRgba(theme.colors.accentMagenta, 0.2),
    [DISPUTE_STAGE.RESOLVED]: hexToRgba(theme.colors.success, 0.2)
  };

  const stageToTextColor = {
    [DISPUTE_STAGE.NEW_SHORTAGE]: theme.colors.info,
    [DISPUTE_STAGE.INITIAL_DISPUTE]: theme.colors.accentGamboge,
    [DISPUTE_STAGE.CONTACT_US_CASE]: theme.colors.accentMagenta,
    [DISPUTE_STAGE.RESOLVED]: theme.colors.success
  };

  return (
    <GenericChip
      content={
        <Text
          variant="subtitle2"
          sx={{
            color: stageToTextColor[stage]
          }}
        >
          {label || stage}
        </Text>
      }
      color={stageToColor[stage]}
    />
  );
};

enum DROPDOWN_ACTION {
  UPLOAD_DOCS = 'uploadDocs',
  VIEW_DETAILS = 'viewDetails'
}
const EllipsesMenu = ({ row }: { row: DisputeRow }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const history = useHistory();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUploadModalOpen = () => {
    setUploadModalOpen(true);
  };
  const handleUploadModalClose = () => {
    setUploadModalOpen(false);
  };

  const handleOptionClick = (id: DROPDOWN_ACTION) => {
    if (id === DROPDOWN_ACTION.UPLOAD_DOCS) {
      handleUploadModalOpen();
    } else if (id === DROPDOWN_ACTION.VIEW_DETAILS) {
      try {
        const { originalInvoice } = row;
        // Clear any filters from dispute management
        history.push(
          updateUrlQueryParams({
            invoiceId: originalInvoice,
            stage: null,
            status: null,
            search: null,
            minValue: null,
            maxValue: null
          })
        );
      } catch (err) {
        console.error('Failed to find invoice number.');
      }
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <BeaconProEllipses onClick={handleOpen} style={{ width: '24px', height: '24px' }} />
        <SlMenu
          anchorEl={anchorEl}
          onClose={handleClose}
          open={!!anchorEl}
          selectedId={null}
          onChange={({ id }) => handleOptionClick(id as DROPDOWN_ACTION)}
          options={[
            {
              id: DROPDOWN_ACTION.VIEW_DETAILS,
              label: 'View Details'
            },
            {
              id: DROPDOWN_ACTION.UPLOAD_DOCS,
              label: 'Upload Docs'
            }
          ]}
        />
        <DisputeFileUploadModal row={row} open={uploadModalOpen} handleClose={handleUploadModalClose} />
      </Box>
    </>
  );
};

const PADDING = '16px 0px 16px 18px';

export const getDisputeTableColumnDefinitions = ({
  handleToggleRow
}: {
  handleToggleRow: (args: ToggleRowProps) => void;
}) => {
  const COLUMN_DEFINITIONS = [
    // Checkbox
    {
      field: '',
      headerName: (
        <StyledCheckbox
          onClick={(e) => {
            const { checked } = e.target;
            handleToggleRow({ toggleAll: checked });
          }}
        />
      ),
      tableColumnProps: {
        style: {
          padding: PADDING,
          width: '24px'
        },
        align: 'left'
      },
      valueFormatter: (_, row: DisputeRow) => {
        const { isChecked } = row;
        const { disputeStatus } = row;

        return (
          <StyledCheckbox
            disabled={disputeStatus !== DISPUTE_STATUS.READY_TO_SUBMIT}
            checked={isChecked}
            onClick={() => handleToggleRow({ toggledRow: row })}
          />
        );
      }
    },
    // Invoice #
    {
      field: 'originalInvoice',
      headerName: 'Original Invoice',
      tableColumnProps: {
        style: {
          padding: PADDING,
          width: '140px'
        },
        align: 'left'
      },
      valueFormatter: (_, row: DisputeRow) => {
        const { originalInvoice } = row;
        const link = updateUrlQueryParams({ invoiceId: originalInvoice });
        return (
          <div style={{ width: '140px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
            <a target="_blank" rel="noreferrer" href={link}>
              {originalInvoice}
            </a>
          </div>
        );
      }
    },
    // Date
    {
      field: 'invoiceDate',
      headerName: 'Date',
      tableColumnProps: {
        style: {
          padding: PADDING,
          width: '100px'
        },
        align: 'left'
      },
      valueFormatter: (_, row: DisputeRow) => {
        const { invoiceDate } = row;
        const formattedDate = moment(invoiceDate).format('MM/DD/YYYY');
        return formattedDate;
      }
    },
    // Type
    {
      field: 'disputeType',
      headerName: 'Type',
      tableColumnProps: {
        style: {
          padding: PADDING,
          width: '60px'
        },
        align: 'left'
      }
    },
    // Value
    {
      field: 'disputeValue',
      headerName: 'Value',
      tableColumnProps: {
        width: '90px',
        style: {
          padding: PADDING
        },
        align: 'left'
      },
      valueFormatter: (_, row: DisputeRow) => {
        const { disputeValue } = row;
        return formatToDollar(disputeValue);
      }
    },
    // Dispute Stage
    {
      field: 'disputeStage',
      headerName: 'Dispute Stage',
      tableColumnProps: {
        style: {
          padding: PADDING,
          width: '170px'
        },
        align: 'left'
      },
      valueFormatter: (_, row: DisputeRow) => {
        const { disputeStage } = row;
        return <StageChip stage={disputeStage as DISPUTE_STAGE} />;
      }
    },
    // Status
    {
      field: 'disputeStatus',
      headerName: 'Status',
      tableColumnProps: {
        style: {
          padding: PADDING,
          width: '120px'
        },
        align: 'left'
      },
      valueFormatter: (_, row: DisputeRow) => {
        const { disputeStatus } = row;
        const displayStatus =
          (disputeStatus as DISPUTE_STATUS) === DISPUTE_STATUS.NEEDS_DOCUMENTS ? 'Needs Docs' : disputeStatus;
        return displayStatus;
      }
    },
    // Dropdown menu
    {
      field: '',
      headerName: '',
      tableColumnProps: {
        style: {
          padding: PADDING,
          width: '24px'
        },
        align: 'left'
      },
      valueFormatter: (_, row: DisputeRow) => {
        return <EllipsesMenu row={row} />;
      }
    },
    // Expand row
    {
      field: '',
      headerName: '',
      tableColumnProps: {
        style: {
          padding: PADDING,
          paddingRight: '16px',
          width: '24px'
        },
        align: 'left'
      },
      expandRow: true
    }
  ];

  /**
   * Only show the first 4 ASINs from the collection
   */
  const MAX_ASINS_TO_RENDER = 4;

  const EXPANDED_COLUMN_DEFINITIONS = {
    containerClassName: '',
    valueFormatter: (data: DisputeRow) => {
      const {
        purchaseOrder,
        disputeId,
        asin,
        submittedValue,
        recoveredValue,
        lostValue,
        trackingNumbers,
        arn,
        carrier
      } = data;

      const asinsToRender = asin.slice(0, MAX_ASINS_TO_RENDER);

      return (
        <Flex height="180px" paddingX="24px" paddingY="26px" gap="xxl">
          <Flex width="250px" flexDirection="column" gap="sm">
            <Text variant="subtitle2">Invoice Details</Text>
            <Flex gap="xs">
              <Text variant="subtitle2">PO #: </Text>
              <Text variant="body2">{purchaseOrder}</Text>
            </Flex>
            {disputeId ? (
              <Flex gap="xs">
                <Text variant="subtitle2">Dispute ID: </Text>
                <Text variant="body2">{disputeId}</Text>
              </Flex>
            ) : null}
            <Flex gap="xs">
              <Text variant="subtitle2">ASIN(s): </Text>
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, auto)', columnGap: '5px', rowGap: '4px' }}>
                {asinsToRender.map((asinId, index) => (
                  <Text key={asinId} variant="body2">
                    {asinId}
                    {index === asinsToRender.length - 1 ? '' : ','}
                  </Text>
                ))}
              </div>
            </Flex>
          </Flex>

          <Flex minWidth="176px" flexDirection="column" gap="sm">
            <Text variant="subtitle2">Financial Details</Text>
            <Flex gap="xs">
              <Text variant="subtitle2">Submitted Value: </Text>
              <Text variant="body2">{formatToDollar(submittedValue)}</Text>
            </Flex>
            <Flex gap="xs">
              <Text variant="subtitle2">Recovered Value: </Text>
              <Text variant="body2">{formatToDollar(recoveredValue)}</Text>
            </Flex>
            <Flex gap="xs">
              <Text variant="subtitle2">Lost Value: </Text>
              <Text variant="body2">{formatToDollar(lostValue)}</Text>
            </Flex>
          </Flex>

          <Flex minWidth="176px" flexDirection="column" gap="sm">
            <Text variant="subtitle2">Shipping Details</Text>
            <Flex gap="xs">
              <Text variant="subtitle2">PRO/Tracking #: </Text>
              <Text variant="body2">{trackingNumbers.slice(0, 4).join(',').replaceAll(',', ', ')}</Text>
            </Flex>
            <Flex gap="xs">
              <Text variant="subtitle2">ARN: </Text>
              <Text variant="body2">{arn}</Text>
            </Flex>
            <Flex gap="xs">
              <Text variant="subtitle2">Carrier: </Text>
              <Text variant="body2">{carrier}</Text>
            </Flex>
          </Flex>
        </Flex>
      );
    },
    columns: {
      invoiceDetails: {
        title: 'Invoice Details',
        layout: ExpandRowColumnLayout.Table,
        fields: {
          purchaseOrder: {
            title: 'PO #'
          },
          disputeId: {
            title: 'Dispute ID'
          },
          asin: {
            title: 'ASIN(s)'
          }
        }
      },
      financialDetails: {
        title: 'Financial Details',
        layout: ExpandRowColumnLayout.Table,
        fields: {
          submittedValue: {
            title: 'Submitted Value'
          },
          recoveredValue: {
            title: 'Recovered Value'
          },
          lostValue: {
            title: 'Lost Value'
          }
        }
      },
      shippingDetails: {
        title: 'Shipping Details',
        layout: ExpandRowColumnLayout.Table,
        fields: {
          trackingNumbers: {
            title: 'PRO/Tracking #'
          },
          arn: {
            title: 'ARN'
          },
          carrier: {
            title: 'Carrier'
          }
        }
      }
    }
  };

  return { COLUMN_DEFINITIONS, EXPANDED_COLUMN_DEFINITIONS };
};
