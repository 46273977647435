import React from 'react';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import CardViewOmniGeo, {
  CardViewOmniGeoProps
} from 'src/components/Omni/OmniGeoMap/OmniGeoCardViewRenewal/CardViewOmniGeoRenewal';
import './CardViewContainer.scss';

export interface CardViewContainerProps {
  geoInfo: string;
  data: CardViewOmniGeoProps[];
  searchKey: string;
  mapName: string;
}

const mappingGeoInfoToPageType = {
  locationRegionCode: 'region',
  locationCountryCode: 'country',
  locationState: 'state',
  locationCity: 'city',
  locationCountyCode: 'county'
};

// const emptyLineChartProps = {
//   id: 'id',
//   name: 'No Data Available',
//   shortName: '  ',
//   metric: {
//     name: 'omniInStock',
//     displayName: 'In-Stock Rate',
//     aggregationFunction: 'avg',
//     metricName: 'InStockRate',
//     dataType: 'DECIMAL',
//     metricType: 'PERCENT'
//   },
//   trendData: [
//     [0, 0]
//     // [1676707200000, 0.9681604288640941],
//     // [1677312000000, 0.8997442305940927],
//     // [1677916800000, 0.74676432165746],
//     // [1678521600000, 0.7006221579814393],
//     // [1679122800000, 0.7023311322716526]
//   ],
//   legendValue: 'Not',
//   // percentChange: 0,
//   dataForCompare: [
//     [0, 0]
//     // [1675497600000, 0.9673425166382913],
//     // [1676102400000, 0.9716846017017523],
//     // [1676707200000, 0.9681604288640941]
//   ]
// };

const CardViewContainer: React.FC<CardViewContainerProps> = ({
  geoInfo,
  data,
  searchKey,
  mapName
}: CardViewContainerProps) => {
  const omniRegionsFollowing = useSelector((state: ReduxStore) => state.omniRegionsFollowing);

  const omniCountriesFollowing = useSelector((state: ReduxStore) => state.omniCountriesFollowing);

  const searchNameGenerator = (dName: string) => {
    if (geoInfo === 'locationRegionCode') {
      const currentRegion = omniRegionsFollowing.find((region) => region.regionCode === dName);

      return currentRegion.regionName;
    }

    if (geoInfo === 'locationCountryCode') {
      const currentCountry = omniCountriesFollowing.find((country) => country.countryCode === dName);
      return currentCountry.countryName;
    }

    return dName;
  };

  const filteredData = data.filter((d) => {
    return (
      d.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
      d.shortName.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
      searchNameGenerator(d.name.toLocaleUpperCase()).toUpperCase().includes(searchKey.toUpperCase())
    );
  });

  return (
    <div className="card-view-outer">
      {data.length === 0 ? (
        <div
          style={{
            paddingBottom: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '18px',
            fontWeight: '700',
            paddingTop: '30px'
          }}
        >
          <div>No Result Available</div>
        </div>
      ) : (
        <div className="card-view-container">
          {filteredData.map((d, index) => (
            <CardViewOmniGeo
              mapName={mapName}
              geoInfo={mappingGeoInfoToPageType[geoInfo]}
              key={`${d.id}-${index}`}
              {...d}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CardViewContainer;
