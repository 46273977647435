import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';

import { compose } from 'redux';
import _identity from 'lodash/identity';
import _pick from 'lodash/pick';

import * as Icons from 'src/components/SvgIcons';
import TrafficAD from './PopupAD/TrafficAD';
import { AppName } from 'sl-api-connector';
import QueryString from 'qs';

const omniShareOfShelvesDisabledEntity = ['category', 'subcategory', 'product', 'segment'];
const omniShareOfShelvesDisabledName = ['organicShareOfShelf', 'paidShareOfShelf', 'totalShareOfShelf'];

const listItemInnerDivStyle = { padding: '8px 8px 8px 0px', fontSize: '14px', height: '32px' };
const subMenuInnerDivStyle = { padding: '8px 8px 8px 20px', fontSize: '14px', height: '32px' };

const MainLabel = ({ text, className }) => <div className={`${className} left_nav_main_label`}>{text}</div>;

const UnenhancedLeftNavSubTree = ({
  menuItem,
  subTreeLevel,
  app,
  history,

  location: { pathname, search },
  entity,
  entityService
}) => {
  const [trafficAdOpen, setTrafficAdOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleTrafficAD = () => setTrafficAdOpen(!trafficAdOpen);

  if (![entity, app, app.queryParams].every(_identity)) {
    return null;
  }

  const { searchParams, compareParams, filterParams } = app.queryParams;
  const showCompareParams = menuItem.linkParams && menuItem.linkParams.includes('ctab') ? '' : compareParams;
  const Icon = menuItem.iconName ? Icons[menuItem.iconName] : 'span';
  const className = menuItem.className || '';
  // Display main label
  if (menuItem.isMainLabel) {
    return <MainLabel text={menuItem.displayName} className={className} />;
  }

  // comment the following if statement to enable traffic
  if (menuItem.name === 'trafficPopup') {
    return (
      <div>
        <ListItem
          onClick={toggleTrafficAD}
          key={`LeftNavSubTree_${menuItem.displayName}_${subTreeLevel}`}
          leftIcon={<Icon className="nav-list-item__icon" />}
          className={`${className} nav-list-item`}
          innerDivStyle={listItemInnerDivStyle}
        >
          {menuItem.displayName}
        </ListItem>

        <TrafficAD open={trafficAdOpen} close={toggleTrafficAD} />
      </div>
    );
  }

  if (!menuItem.subMenuItems || menuItem.subMenuItems.length === 0) {
    let to = `${pathname}${searchParams}&${menuItem.linkParams}${showCompareParams}${filterParams}`;

    // In Omni, we have two different filter scope.
    // share of shelves will share their own filter, all other tabs will share other filter.
    if (app.name === AppName.Omni) {
      const parsedSearchParams = QueryString.parse(search);
      const currentTab = parsedSearchParams.tab;

      const menuItemTab = (menuItem.queryParams && menuItem.queryParams.tab) || '';

      if (currentTab === 'shareOfShelf' && menuItemTab === 'shareOfShelf') {
        to = `${pathname}${searchParams}&${menuItem.linkParams}${showCompareParams}${filterParams}`;
      } else if (currentTab === 'shareOfShelf' && menuItemTab !== 'shareOfShelf') {
        to = `${pathname}${searchParams}&${menuItem.linkParams}${showCompareParams}`;
      } else if (currentTab !== 'shareOfShelf' && menuItemTab === 'shareOfShelf') {
        to = `${pathname}${searchParams}&${menuItem.linkParams}${showCompareParams}`;
      }
    }

    const { mainEntity } = entityService;

    return (
      <>
        <ListItem
          disableTouchRipple
          key={`LeftNavSubTree_${menuItem.displayName}_${subTreeLevel}`}
          leftIcon={
            <Icon className={`nav-list-item__icon ${menuItem.isSelected ? 'nav-list-item__icon--active' : ''}`} />
          }
          className={`${className} nav-list-item ${menuItem.isSelected ? 'nav-list-item--active-tab' : ''}`}
          innerDivStyle={menuItem.ident ? subMenuInnerDivStyle : listItemInnerDivStyle}
          onClick={() => history.push(to)}
        >
          {app.name === 'omni' &&
          omniShareOfShelvesDisabledName.includes(menuItem.name) &&
          omniShareOfShelvesDisabledEntity.includes(mainEntity.type) ? (
            <span className={className}>{menuItem.displayName}</span>
          ) : (
            <NavLink
              to={to}
              activeClassName="nav-list-item__text--active active-subtab"
              className=""
              isActive={() => menuItem.isSelected}
            >
              {menuItem.displayName}
            </NavLink>
          )}
        </ListItem>
      </>
    );
  }

  menuItem.subMenuItems = menuItem.subMenuItems.map((item) => {
    return { ...item, ident: true };
  });

  // we will reach here only they have subMenuItems. I can only observe this at Beacon and Omni.
  return (
    <ListItem
      disableTouchRipple
      key={`LeftNavSubTree_${menuItem.displayName}_${subTreeLevel}`}
      initiallyOpen={menuItem.isSelected}
      open={menuItem.isSelected}
      className={`${className} nav-list-item ${menuItem.isSelected ? 'nav-list-item--active-tab' : ''}`}
      innerDivStyle={listItemInnerDivStyle}
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}
    >
      <div
        style={{ cursor: 'pointer' }}
        role="link"
        onClick={() => {
          menuItem.isSelected = !isOpen;
          setIsOpen((prev) => !prev);
        }}
      >
        {menuItem.displayName}
      </div>
      {isOpen || menuItem.isSelected ? (
        <div style={{ marginTop: '5px' }}>
          {menuItem.subMenuItems.map((subMenuItem) => {
            let subMenuTo = `${pathname}${searchParams}&${subMenuItem.linkParams}${showCompareParams}${filterParams}`;

            if (app.name === AppName.Omni) {
              const parsedSearchParams = QueryString.parse(search);
              const currentTab = parsedSearchParams.tab;
              const subMenuItemTab = subMenuItem.queryParams.tab;

              if (currentTab === 'shareOfShelf' && subMenuItemTab === 'shareOfShelf') {
                subMenuTo = `${pathname}${searchParams}&${subMenuItem.linkParams}${showCompareParams}${filterParams}`;
              } else if (currentTab === 'shareOfShelf' && subMenuItemTab !== 'shareOfShelf') {
                subMenuTo = `${pathname}${searchParams}&${subMenuItem.linkParams}${showCompareParams}`;
              } else if (currentTab !== 'shareOfShelf' && subMenuItemTab === 'shareOfShelf') {
                subMenuTo = `${pathname}${searchParams}&${subMenuItem.linkParams}${showCompareParams}`;
              }
            }

            return (
              <ListItem key={subMenuItem.name} activeClassName="nav-list-item__text--active active-subtab">
                <NavLink
                  to={subMenuTo}
                  activeClassName="nav-list-item__text--active active-subtab"
                  className=""
                  isActive={() => subMenuItem.isSelected}
                >
                  {subMenuItem.displayName}
                </NavLink>
              </ListItem>
            );
          })}
        </div>
      ) : null}
    </ListItem>
  );
};

MainLabel.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};
UnenhancedLeftNavSubTree.propTypes = {
  app: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  entityService: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  menuItem: PropTypes.object.isRequired,
  subTreeLevel: PropTypes.number.isRequired
};

const LeftNavSubTree = compose(
  withRouter,
  connect((state) => _pick(state, ['app', 'entityService']))
)(UnenhancedLeftNavSubTree);

export default LeftNavSubTree;
