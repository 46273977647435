/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import numeral from 'numeral';
import NumberFormat from 'react-number-format';
import { Option } from 'funfix-core';
import moment, { Moment } from 'moment-timezone';
import Picker from 'react-month-picker';
import { AggregationField } from 'src/types/application/types';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import TextField from '@mui/material/TextField';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';

import { store } from 'src/main';
import * as adBudgetEditorActions from 'src/store/modules/adManager/adBudgetEditor/actions';
import {
  SectionWrapper,
  OperationButtons
} from 'src/components/AdCampaignBuilder/Widgets/AdCampaignBuilderCommonWidgets';
import ReduxStore from 'src/types/store/reduxStore';
import 'react-month-picker/scss/month-picker.scss';
import './BudgetEditor.scss';
import { extractMonthYear, lang, yearMonthToMoment } from 'src/utils/dateUtils';
import MonthPicker from 'src/components/common/DatePicker/MonthPicker';
import { UTC_TIMEZONE } from 'src/utils/constants';
import { build4wComparisonRangeFilters, build4wRangeFilters } from 'src/utils/dateformatting';
import { formatList } from 'src/components/AdBudgetEditor/Steps/Platform';
import { buildAggregations, zipMetricsResponseIntoArray } from 'src/components/AdManager/Search';

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, ['app', 'adBudgetEditor', 'retailer', 'adCampaigns', 'adPortfolios']),
    mainEntity: state.entityService.mainEntity
  };
};

const BudgetAmount: React.FC<ReturnType<typeof mapStateToProps>> = ({
  app,
  adBudgetEditor,
  retailer,
  mainEntity,
  widget,
  adPortfolios,
  adCampaigns
}) => {
  const [loading, setLoading] = useState(false);
  const { timeline, amount: newBudget, entityBudget, endMonth } = adBudgetEditor;
  const retailerTimezone = _get(retailer, 'processInTimezone', UTC_TIMEZONE);
  const monthPickerRef = useRef<Picker>(null);

  const initEndMonth =
    timeline.id === 0
      ? {
          year: +moment().tz(retailerTimezone).format('Y'),
          month: +moment().tz(retailerTimezone).format('M')
        }
      : {
          year: +moment().tz(retailerTimezone).add(1, 'M').format('Y'),
          month: +moment().tz(retailerTimezone).add(1, 'M').format('M')
        };

  const getEndMonthValue = () => {
    const month = endMonth && endMonth.month ? endMonth.month : 0;
    const year = endMonth && endMonth.year ? endMonth.year : 0;

    return month && year ? `${lang[month - 1]}. ${year}` : 'Select Month';
  };

  if (_isEmpty(adBudgetEditor.platform)) {
    return null;
  }

  const handleEndMonthChange = (val: Moment) => {
    const { year, month } = extractMonthYear(val);
    store.dispatch(
      adBudgetEditorActions.replaceState({
        ...adBudgetEditor,
        endMonth: { year, month }
      })
    );
  };

  const setBudgetAmount = (value: number) => store.dispatch(adBudgetEditorActions.setBudgetAmount(value));

  const setBudgetEntityBudgetAomunt = (value: number) =>
    store.dispatch(
      adBudgetEditorActions.replaceState({
        ...adBudgetEditor,
        entityBudget: value
      })
    );
  const handleNewBudgetChange = (value: any) => {
    if (!Number.isNaN(+value)) {
      setBudgetAmount(+value);
    }
  };

  const handleEntityBudgetChange = (value: any) => {
    if (!Number.isNaN(+value)) {
      setBudgetEntityBudgetAomunt(+value);
    }
  };

  const fetchListData = async (metrics: string[], metricName: string, groupByFieldName: string) => {
    const entityId = _get(adBudgetEditor, ['entity', 'id']);
    const entityIds = [entityId];
    const termFilters = [{ fieldName: 'entityId', values: entityIds }];
    const timePeriodRangeFilters = build4wRangeFilters();
    const comparisonRangeFilters = build4wComparisonRangeFilters();
    const indexName = 'adCampaignDailyMetrics';
    const aggregations: AggregationField[] = metrics.map((fieldName) =>
      INDEX_FIELDS.getField(app.name, indexName, fieldName)
    );

    const [{ aggregations: aggregationFields }] = buildAggregations(aggregations);
    const fetchResult = await store.dispatch(
      fetchEntityMetrics(
        metricName,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName: 'adCampaignDailyMetrics',
          customResponseParser: (action: any) => {
            return zipMetricsResponseIntoArray(action, widget);
          }
        },
        [
          {
            doAggregation: true,
            aggregations: [
              {
                aggregationFields,
                conditions: {
                  termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
                  rangeFilters: [...timePeriodRangeFilters]
                },
                sortDirection: 'desc',
                comparisonRangeFilters,
                groupByFieldName
              }
            ],
            conditions: {
              termFilters,
              rangeFilters: [...timePeriodRangeFilters]
            },
            pageSize: 10000,
            processDocuments: false
          }
        ],
        null,
        true
      )
    );
    return fetchResult;
  };

  const writeNewBudgetAttribute = async () => {
    setLoading(true);
    const portfolioList = await fetchListData(
      ['clicks', 'spend', 'sales', 'returnOnAdSpend'],
      'budgetIntakePortfolioGrid',
      'portfolioId'
    );
    const campaignList = await fetchListData(
      ['clicks', 'costPerClick', 'clickThroughRate', 'spend', 'sales', 'returnOnAdSpend'],
      'budgetIntakeCampaignGrid',
      'campaignId'
    );

    const formattedPortfolioList = formatList(portfolioList.data, adPortfolios);
    await store.dispatch(adBudgetEditorActions.setPortfolioArray(formattedPortfolioList));

    const formattedCampaignList = formatList(campaignList.data, adCampaigns);
    await store.dispatch(adBudgetEditorActions.setCampaignArray(formattedCampaignList));

    if (!newBudget) {
      store.dispatch(adBudgetEditorActions.setBudgetAmount(entityBudget));
    }

    await store.dispatch(adBudgetEditorActions.setCampaignArray(formattedCampaignList));
    await store.dispatch(adBudgetEditorActions.setPortfolioArray(formattedPortfolioList));
    setLoading(false);
    return true;
  };

  const { entity } = adBudgetEditor.entity;
  const mtdAdSpend = _get(adBudgetEditor, ['entity', 'spend', 'value'], 0);

  let canContinue =
    entityBudget && (timeline.id === 1 ? +entityBudget >= 1 : +entityBudget >= mtdAdSpend && entityBudget > 0);

  if (entityBudget && newBudget && entityBudget < newBudget) {
    canContinue = false;
  }
  const nextMonthName = moment().add(1, 'M').format('MMMM');
  const currentMonthName = moment().format('MMMM');
  const showPicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      monthPickerRef.current.show();
    }
  };

  const hideDecimals = retailer.id === '16';

  return (
    <div className="ad-manager-container">
      <OperationButtons
        isLoading={loading}
        subtab="budgetAmount"
        canContinue={!!canContinue}
        customFlow={adBudgetEditor.flow}
        onBeforeLeave={writeNewBudgetAttribute}
      />
      <SectionWrapper
        header={`${timeline.id === 0 ? 'Monthly' : timeline.displayName} Budget`}
        subheader="Enter budget adjustments below."
        layer={0}
      >
        <div className="platform-select">
          <div className="amount_container">
            <div className="amount_row">
              <div className="disc_side">
                {timeline.id === 0 ? 'New Entity Budget' : `${nextMonthName} Entity Budget`}
              </div>
              <div className="amount_side">
                <NumberFormat
                  className="number-formatter"
                  value={entityBudget}
                  thousandSeparator
                  prefix={retailer.currencySymbol}
                  decimalScale={0}
                  placeholder={hideDecimals ? '0' : '0.00'}
                  fixedDecimalScale
                  allowNegative={false}
                  onValueChange={({ floatValue }) => handleEntityBudgetChange(floatValue)}
                  // Mimic the style of the MUI `TextInput`
                  style={{
                    height: '32px',
                    outline: 'none',
                    border: '0',
                    borderBottomColor: 'currentcolor',
                    borderBottomStyle: 'none',
                    borderBottomWidth: '0px',
                    borderBottom: '1px solid #eee',
                    width: '100px',
                    color: 'currentColor',
                    fontFamily: 'Roboto'
                  }}
                />
              </div>
            </div>
            <div className="amount_row">
              <div className="disc_side">Budget to allocate</div>
              <div className="amount_side">
                <NumberFormat
                  className="number-formatter"
                  placeholder={entityBudget ? `$${entityBudget}` : hideDecimals ? '0' : '0.00'}
                  value={newBudget}
                  thousandSeparator
                  prefix={retailer.currencySymbol}
                  decimalScale={0}
                  fixedDecimalScale
                  allowNegative={false}
                  onValueChange={({ floatValue }) => handleNewBudgetChange(floatValue)}
                  // Mimic the style of the MUI `TextInput`
                  style={{
                    height: '32px',
                    outline: 'none',
                    border: '0',
                    borderBottomColor: 'currentcolor',
                    borderBottomStyle: 'none',
                    borderBottomWidth: '0px',
                    borderBottom: '1px solid #eee',
                    width: '100px',
                    color: 'currentColor',
                    fontFamily: 'Roboto'
                  }}
                />
              </div>
            </div>
            <div className="amount_row">
              <div className="disc_side">Budget End Date</div>
              <div className="amount_side">
                {/* <div className="datepicker_container"> */}
                <TextField
                  variant="standard"
                  autoComplete="off"
                  // classes={{ root: classes.dateRoot }}
                  // InputProps={{ classes: { root: classes.dateRoot } }}
                  value={getEndMonthValue()}
                  id="outlined-bare"
                  onClick={() => showPicker()}
                  margin="normal"
                />
                <MonthPicker
                  endDate={
                    endMonth
                      ? yearMonthToMoment(endMonth.year, endMonth.month)
                      : yearMonthToMoment(initEndMonth.year, initEndMonth.month)
                  }
                  setEndDate={(val) => handleEndMonthChange(val)}
                  monthPickerRef={monthPickerRef}
                  calendarRange={{
                    min: initEndMonth,
                    max: { year: initEndMonth.year + 4, month: initEndMonth.month } // four years in future
                  }}
                  onDismiss={() => {}}
                  hasEndDate={false}
                  containerClass="budgetAmount"
                />
              </div>
            </div>

            <div className="amount_row">
              <div className="disc_side">{timeline.id === 0 ? 'Current Budget' : `${currentMonthName} Budget`}</div>
              <div className="amount_side">
                {numeral(_get(entity, ['extendedAttributes', 'currentMonthBudgetSetting', 'amount'], 0)).format(
                  '$1,000'
                )}
              </div>
            </div>
            {timeline.id === 0 && (
              <div className="amount_row">
                <div className="disc_side">MTD Ad Spend</div>
                <div className="amount_side">
                  {Option.of(mtdAdSpend)
                    .map((spend) => numeral(spend).format('$1,000'))
                    .getOrElse('...')}
                </div>
              </div>
            )}
            {timeline.id === 0 && (
              <div className="amount_row">
                <div className="disc_side">Remaining Entity Budget</div>
                <div className="amount_side">
                  {Option.of(mtdAdSpend)
                    .map((spend) => numeral(entityBudget - spend).format('$1,000'))
                    .getOrElse('...')}
                </div>
              </div>
            )}
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};

const EnhancedBudgetAmount = connect(mapStateToProps)(BudgetAmount);

export default EnhancedBudgetAmount;
