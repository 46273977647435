import axios from 'axios';
import _get from 'lodash/get';
import { store } from 'src/main';
import {
  AD_UPDATE_STATUS,
  AD_UPDATE_TYPE,
  IUpdateStatusRequest,
  IBulkBudgetUpdateRequest,
  IAdCampaign,
  IAdPortfolioAdPlatformSettingsByClient,
  AD_CAMPAIGN_STATUS,
  IAdEntityAdPlatformSettingsByClient,
  IAdCampaignProduct,
  IAdTarget,
  IAdCampaignProductBase,
  AD_PLATFORM_TYPE,
  IAdPlatformSettingsByClient,
  IUpdateAdTargetBidRequest,
  IUpdateStatusScheduledAction,
  IBulkBudgetUpdateScheduledAction,
  SCHEDULED_ACTION_HANDLER_TYPE,
  SCHEDULED_ACTION_STATUS,
  IUpdateAdTargetBidScheduledAction,
  AD_STATUS_DERIVED
} from 'sl-ad-campaign-manager-data-model';
import { STACKLINE_UNASSIGNED } from 'src/utils/constants';
import {
  CHANGE_PERCENT_OPTIONS,
  getActionMethodFromOption
} from 'src/store/modules/adManager/adScheduledActionGroups/selectors';

const checkArchived = (statusDerived: string, platformType: string) => {
  return (
    (statusDerived === AD_STATUS_DERIVED.ARCHIVED || statusDerived === AD_STATUS_DERIVED.ENDED) &&
    platformType.includes('AmazonAMS')
  );
};

export class AdCampaignManagerServiceClient {
  public static async pauseEntityOnSchedule({
    beaconClientId,
    platformType,
    entities,
    entityType,
    startCronSchedule,
    endCronSchedule,
    scheduleGroupName,
    actionType,
    action,
    period,
    metric,
    condition
  }: {
    beaconClientId: number;
    platformType: AD_PLATFORM_TYPE;
    entities: (
      | IAdEntityAdPlatformSettingsByClient
      | IAdPortfolioAdPlatformSettingsByClient
      | IAdCampaign
      | IAdCampaignProduct
      | IAdTarget
    )[];
    entityType: string;
    updatedStatus?: AD_CAMPAIGN_STATUS;
    startCronSchedule: string;
    endCronSchedule: string;
    scheduleGroupName: string;
    actionType: string;
    action: any;
    period: any;
    metric: any;
    condition: any;
  }) {
    const UPDATE_STATUS_SCHEDULED_ACTION_API = `/apiAdManager/scheduledActions/updateStatus`;
    const statusToUse =
      action.method === 'Pause'
        ? [AD_CAMPAIGN_STATUS.PAUSED, AD_CAMPAIGN_STATUS.ENABLED]
        : [AD_CAMPAIGN_STATUS.ENABLED, AD_CAMPAIGN_STATUS.PAUSED];
    if (entities.length > 0 && entities[0].type === 'campaign') {
      entities.forEach((entity) => {
        delete entity.extendedAttributes.childDocuments;
      });
    }

    const startTime = actionType === 'timeBase' ? period.startDate : metric.startDate;
    const endTime = actionType === 'timeBase' ? period.endDate : metric.endDate;
    const firstStatusRequest = AdCampaignManagerServiceClient.buildUpdateStatusRequest({
      beaconClientId,
      platformType,
      entities,
      entityType,
      updatedStatus: statusToUse[0]
    });
    const firstStatusScheduledAction: IUpdateStatusScheduledAction = {
      beaconClientId,
      platformType,
      scheduledActionHandlerType: SCHEDULED_ACTION_HANDLER_TYPE.UPDATE_STATUS_ACTION_HANDLER,
      cronSchedule: startCronSchedule,
      startTime,
      endTime,
      extendedAttributes: {
        actionType,
        statusActionType: statusToUse[0],
        name: `${scheduleGroupName}-${statusToUse[0]}`,
        groupName: scheduleGroupName,
        status: SCHEDULED_ACTION_STATUS.ENABLED,
        adUpdateRequest: firstStatusRequest,
        actionDescription: action.name,
        actionPeriodDescription: period.name,
        sequenceId: 1,
        condition
      }
    };
    const secondStatusRequest = AdCampaignManagerServiceClient.buildUpdateStatusRequest({
      beaconClientId,
      platformType,
      entities,
      entityType,
      updatedStatus: statusToUse[1]
    });
    const secondStatusScheduledAction: IUpdateStatusScheduledAction = {
      beaconClientId,
      platformType,
      scheduledActionHandlerType: SCHEDULED_ACTION_HANDLER_TYPE.UPDATE_STATUS_ACTION_HANDLER,
      cronSchedule: endCronSchedule,
      startTime,
      endTime,
      extendedAttributes: {
        actionType,
        statusActionType: statusToUse[1],
        name: `${scheduleGroupName}-${statusToUse[1]}`,
        groupName: scheduleGroupName,
        status: SCHEDULED_ACTION_STATUS.ENABLED,
        adUpdateRequest: secondStatusRequest,
        actionDescription: action.name,
        actionPeriodDescription: period.name,
        sequenceId: 2
      }
    };
    const finalRequest = [firstStatusScheduledAction];
    if (actionType === 'timeBase') {
      finalRequest.push(secondStatusScheduledAction);
    }

    if (
      firstStatusRequest.extendedAttributes.adEntities.length > 0 ||
      firstStatusRequest.extendedAttributes.adPortfolios.length > 0 ||
      firstStatusRequest.extendedAttributes.adCampaigns.length > 0 ||
      firstStatusRequest.extendedAttributes.adGroups.length > 0 ||
      firstStatusRequest.extendedAttributes.adCampaignProducts.length > 0 ||
      firstStatusRequest.extendedAttributes.adTargets.length > 0
    ) {
      return axios.post(UPDATE_STATUS_SCHEDULED_ACTION_API, finalRequest);
    }
    return null;
  }

  public static async updateTargetBidOnSchedule({
    beaconClientId,
    platformType,
    entities,
    entityType,
    startCronSchedule,
    endCronSchedule,
    scheduleGroupName,
    actionType,
    minValueLimit,
    maxValueLimit,
    action,
    period,
    metric,
    condition
  }: {
    beaconClientId: number;
    platformType: AD_PLATFORM_TYPE;
    entities: (
      | IAdEntityAdPlatformSettingsByClient
      | IAdPortfolioAdPlatformSettingsByClient
      | IAdCampaign
      | IAdCampaignProduct
      | IAdTarget
    )[];
    entityType: string;
    updatedStatus?: AD_CAMPAIGN_STATUS;
    startCronSchedule: string;
    endCronSchedule: string;
    scheduleGroupName: string;
    actionType: string;
    minValueLimit?: number;
    maxValueLimit?: number;
    action: any;
    period: any;
    metric: any;
    condition: any;
  }) {
    const UPDATE_BID_SCHEDULED_ACTION_API = `/apiAdManager/scheduledActions/${
      platformType === 'Walmart' ? 'updateProductTargetBid' : 'updateTargetBid'
    }`;
    const changeRateOverwrite = {
      method: action.method,
      value: action.percent
    };
    const startTime = actionType === 'timeBase' ? period.startDate : metric.startDate;
    const endTime = actionType === 'timeBase' ? period.endDate : metric.endDate;
    const changeBidRequest = AdCampaignManagerServiceClient.buildUpdateBidRequest({
      beaconClientId,
      platformType,
      entities,
      entityType,
      changeRateOverwrite
    });
    const changeBidScheduledAction: IUpdateAdTargetBidScheduledAction = {
      beaconClientId,
      platformType,
      scheduledActionHandlerType: SCHEDULED_ACTION_HANDLER_TYPE.UPDATE_TARGET_BID_ACTION_HANDLER,
      cronSchedule: startCronSchedule,
      startTime,
      endTime,
      extendedAttributes: {
        actionType,
        name: `${scheduleGroupName}-change`,
        groupName: scheduleGroupName,
        status: SCHEDULED_ACTION_STATUS.ENABLED,
        adUpdateRequest: changeBidRequest,
        actionMethod: getActionMethodFromOption(action.method),
        actionPercent: CHANGE_PERCENT_OPTIONS.includes(action.method) ? action.value : null,
        actionAmount: CHANGE_PERCENT_OPTIONS.includes(action.method) ? null : action.value,
        minValueLimit,
        maxValueLimit,
        actionDescription: action.name,
        actionPeriodDescription: period.name,
        sequenceId: 1,
        condition
      }
    };
    const recoverBidStatusRequest = AdCampaignManagerServiceClient.buildUpdateBidRequest({
      beaconClientId,
      platformType,
      entities,
      entityType
    });
    const recoverBidScheduledAction: IUpdateAdTargetBidScheduledAction = {
      beaconClientId,
      platformType,
      scheduledActionHandlerType: SCHEDULED_ACTION_HANDLER_TYPE.UPDATE_TARGET_BID_ACTION_HANDLER,
      cronSchedule: endCronSchedule,
      startTime,
      endTime,
      extendedAttributes: {
        actionType,
        name: `${scheduleGroupName}-recover`,
        groupName: scheduleGroupName,
        status: SCHEDULED_ACTION_STATUS.ENABLED,
        adUpdateRequest: recoverBidStatusRequest,
        actionMethod: getActionMethodFromOption(action.method),
        actionPercent: CHANGE_PERCENT_OPTIONS.includes(action.method) ? action.value : null,
        actionAmount: CHANGE_PERCENT_OPTIONS.includes(action.method) ? null : action.value,
        actionDescription: action.name,
        minValueLimit,
        maxValueLimit,
        actionPeriodDescription: period.name,
        sequenceId: 2
      }
    };
    const finalRequest = [changeBidScheduledAction];
    if (actionType === 'timeBase') {
      finalRequest.push(recoverBidScheduledAction);
    }
    if (
      changeBidRequest.extendedAttributes.adCampaigns.length > 0 ||
      changeBidRequest.extendedAttributes.adTargets.length > 0 ||
      changeBidRequest.extendedAttributes.adCampaignProducts.length > 0
    ) {
      return axios.post(UPDATE_BID_SCHEDULED_ACTION_API, finalRequest);
    }
    return null;
  }

  public static async updateBudgetOnSchedule({
    beaconClientId,
    platformType,
    entities,
    entityType,
    startCronSchedule,
    endCronSchedule,
    scheduleGroupName,
    actionType,
    minValueLimit,
    maxValueLimit,
    action,
    period,
    metric,
    condition
  }: {
    beaconClientId: number;
    platformType: AD_PLATFORM_TYPE;
    entities: (
      | IAdEntityAdPlatformSettingsByClient
      | IAdPortfolioAdPlatformSettingsByClient
      | IAdCampaign
      | IAdCampaignProduct
      | IAdTarget
    )[];
    entityType: string;
    updatedStatus?: AD_CAMPAIGN_STATUS;
    startCronSchedule: string;
    endCronSchedule: string;
    scheduleGroupName: string;
    actionType: string;
    minValueLimit?: number;
    maxValueLimit?: number;
    action: any;
    period: any;
    metric: any;
    condition: any;
  }) {
    const UPDATE_BUDGET_SCHEDULED_ACTION_API = `/apiAdManager/scheduledActions/updateBulkBudget`;
    const changeRateOverwrite = {
      method: action.method,
      value: action.percent
    };
    const startTime = actionType === 'timeBase' ? period.startDate : metric.startDate;
    const endTime = actionType === 'timeBase' ? period.endDate : metric.endDate;
    const changeBudgetRequest = AdCampaignManagerServiceClient.buildBulkBudgetRequest({
      beaconClientId,
      platformType,
      entities,
      entityType,
      changeRateOverwrite
    });
    const changeBudgetScheduledAction: IBulkBudgetUpdateScheduledAction = {
      beaconClientId,
      platformType,
      scheduledActionHandlerType: SCHEDULED_ACTION_HANDLER_TYPE.BULK_BUDGET_UPDATE_ACTION_HANDLER,
      cronSchedule: startCronSchedule,
      startTime,
      endTime,
      extendedAttributes: {
        actionType,
        name: `${scheduleGroupName}-change`,
        groupName: scheduleGroupName,
        status: SCHEDULED_ACTION_STATUS.ENABLED,
        minValueLimit,
        maxValueLimit,
        adUpdateRequest: changeBudgetRequest,
        actionDescription: action.name,
        actionPeriodDescription: period.name,
        actionMethod: getActionMethodFromOption(action.method),
        actionPercent: CHANGE_PERCENT_OPTIONS.includes(action.method) ? action.value : null,
        actionAmount: CHANGE_PERCENT_OPTIONS.includes(action.method) ? null : action.value,
        sequenceId: 1,
        condition
      }
    };
    const recoverBudgetStatusRequest = AdCampaignManagerServiceClient.buildBulkBudgetRequest({
      beaconClientId,
      platformType,
      entities,
      entityType
    });
    const recoverBudgetScheduledAction: IBulkBudgetUpdateScheduledAction = {
      beaconClientId,
      platformType,
      scheduledActionHandlerType: SCHEDULED_ACTION_HANDLER_TYPE.BULK_BUDGET_UPDATE_ACTION_HANDLER,
      cronSchedule: endCronSchedule,
      startTime,
      endTime,
      extendedAttributes: {
        actionType,
        name: `${scheduleGroupName}-recover`,
        groupName: scheduleGroupName,
        status: SCHEDULED_ACTION_STATUS.ENABLED,
        adUpdateRequest: recoverBudgetStatusRequest,
        actionMethod: getActionMethodFromOption(action.method),
        actionPercent: CHANGE_PERCENT_OPTIONS.includes(action.method) ? action.value : null,
        actionAmount: CHANGE_PERCENT_OPTIONS.includes(action.method) ? null : action.value,
        actionDescription: action.name,
        minValueLimit,
        maxValueLimit,
        actionPeriodDescription: period.name,
        sequenceId: 2
      }
    };
    const finalRequest = [changeBudgetScheduledAction];
    if (actionType === 'timeBase') {
      finalRequest.push(recoverBudgetScheduledAction);
    }
    if (changeBudgetRequest.extendedAttributes.adCampaignsBudgetSetting.length > 0) {
      return axios.post(UPDATE_BUDGET_SCHEDULED_ACTION_API, finalRequest);
    }
    return null;
  }

  public static async updateEntityStatus({
    beaconClientId,
    platformType,
    entities,
    entityType,
    updatedStatus,
    override
  }: {
    beaconClientId: number;
    platformType: AD_PLATFORM_TYPE;
    entities: (
      | IAdEntityAdPlatformSettingsByClient
      | IAdPortfolioAdPlatformSettingsByClient
      | IAdCampaign
      | IAdCampaignProduct
      | IAdTarget
    )[];
    entityType: string;
    updatedStatus?: AD_CAMPAIGN_STATUS;
    override?: any;
  }) {
    const UPDATE_STATUS_API = '/apiAdManager/adUpdateRequests/updateStatus';
    const updateStatusRequest =
      override ||
      AdCampaignManagerServiceClient.buildUpdateStatusRequest({
        beaconClientId,
        platformType,
        entities,
        entityType,
        updatedStatus
      });

    if (
      updateStatusRequest.extendedAttributes.adEntities.length > 0 ||
      updateStatusRequest.extendedAttributes.adPortfolios.length > 0 ||
      updateStatusRequest.extendedAttributes.adCampaigns.length > 0 ||
      updateStatusRequest.extendedAttributes.adGroups.length > 0 ||
      updateStatusRequest.extendedAttributes.adCampaignProducts.length > 0 ||
      updateStatusRequest.extendedAttributes.adTargets.length > 0
    ) {
      return axios.post(UPDATE_STATUS_API, updateStatusRequest);
    }
    return null;
  }

  public static async updateTargetBid({
    beaconClientId,
    platformType,
    entities,
    entityType
  }: {
    beaconClientId: number;
    platformType: AD_PLATFORM_TYPE;
    entities: IAdTarget[];
    entityType: string;
    updatedStatus?: AD_CAMPAIGN_STATUS;
  }) {
    const UPDATE_TARGET_BID_API = '/apiAdManager/adUpdateRequests/updateTargetBid';
    const updateTargetBidRequest = AdCampaignManagerServiceClient.buildUpdateBidRequest({
      beaconClientId,
      platformType,
      entities,
      entityType
    });
    if (updateTargetBidRequest.extendedAttributes.adTargets.length > 0) {
      return axios.post(UPDATE_TARGET_BID_API, updateTargetBidRequest);
    }
    return null;
  }

  public static async updateProductBid({
    beaconClientId,
    platformType,
    entities,
    entityType
  }: {
    beaconClientId: number;
    platformType: AD_PLATFORM_TYPE;
    entities: IAdCampaignProductBase[];
    entityType: string;
    updatedStatus?: AD_CAMPAIGN_STATUS;
  }) {
    const UPDATE_PRODUCT_BID_API = '/apiAdManager/adUpdateRequests/updateProductBid';
    const updateProductBidRequest = AdCampaignManagerServiceClient.buildUpdateProductBidRequest({
      beaconClientId,
      platformType,
      entities,
      entityType
    });
    if (updateProductBidRequest.extendedAttributes.adCampaignProducts.length > 0) {
      return axios.post(UPDATE_PRODUCT_BID_API, updateProductBidRequest);
    }
    return null;
  }

  public static async createCampaignTargets({ adCampaign }: { adCampaign: IAdCampaign }) {
    const CREATE_CAMPAIGN_TARGETS_API = '/apiAdManager/adUpdateRequests/createCampaignTargets';
    return axios.post(CREATE_CAMPAIGN_TARGETS_API, adCampaign);
  }

  public static async createCampaignProducts({ adCampaign }: { adCampaign: IAdCampaign }) {
    const CREATE_CAMPAIGN_PRODUCTS_API = '/apiAdManager/adUpdateRequests/createCampaignProducts';
    return axios.post(CREATE_CAMPAIGN_PRODUCTS_API, adCampaign);
  }

  private static buildUpdateStatusRequest({
    beaconClientId,
    platformType,
    entities,
    entityType,
    updatedStatus
  }: {
    beaconClientId: number;
    platformType: AD_PLATFORM_TYPE;
    entities: (
      | IAdEntityAdPlatformSettingsByClient
      | IAdPortfolioAdPlatformSettingsByClient
      | IAdCampaign
      | IAdCampaignProduct
      | IAdTarget
    )[];
    entityType: string;
    updatedStatus?: AD_CAMPAIGN_STATUS;
  }) {
    const updateStatusRequest: IUpdateStatusRequest = {
      beaconClientId,
      platformType,
      updateType: AD_UPDATE_TYPE.UPDATE_STATUS,
      extendedAttributes: {
        status: AD_UPDATE_STATUS.NEW,
        adEntities: [],
        adPortfolios: [],
        adCampaigns: [],
        adGroups: [],
        adCampaignProducts: [],
        adTargets: []
      }
    };

    const entitiesModified: (
      | IAdEntityAdPlatformSettingsByClient
      | IAdPortfolioAdPlatformSettingsByClient
      | IAdCampaign
      | IAdCampaignProduct
      | IAdTarget
    )[] = [];
    const entityTypeToUse = _get(entities, [0, 'extendedAttributes', 'childDocuments', 0, 'type'], entityType);

    entities.forEach((entity) => {
      switch (entityTypeToUse) {
        case 'adEntity':
        case 'adPortfolio': {
          const { settingType, settingId, extendedAttributes } = entity as IAdPlatformSettingsByClient;
          entitiesModified.push({
            beaconClientId: entity.beaconClientId,
            platformType: entity.platformType,
            settingType,
            settingId,
            extendedAttributes: {
              ...extendedAttributes,
              status: updatedStatus
            }
          });
          break;
        }
        case 'adcampaignadgrouptarget':
        case 'adCampaign': {
          const { campaignId, extendedAttributes } = entity as IAdCampaign;
          const statusDerived = _get(entity, ['extendedAttributes', 'statusDerived'], '');
          if (checkArchived(statusDerived, platformType)) {
            return;
          }
          entitiesModified.push({
            beaconClientId,
            platformType: entity.platformType,
            campaignId,
            extendedAttributes: {
              ...extendedAttributes,
              status: updatedStatus
            }
          } as IAdCampaign);
          break;
        }
        case 'adTarget':
          {
            const childDocuments = _get(entity, ['extendedAttributes', 'childDocuments'], []);
            childDocuments.forEach((childDocument: any) => {
              if (checkArchived(childDocument.statusDerived, childDocument.platformType)) {
                return;
              }
              if (childDocument.targetingClass && childDocument.targetId && childDocument.platformType) {
                entitiesModified.push({
                  beaconClientId: childDocument.beaconClientId,
                  platformType: childDocument.platformType,
                  campaignId: childDocument.campaignId,
                  adGroupId: childDocument.adGroupId,
                  targetId: childDocument.targetId,
                  targetingClass: childDocument.targetingClass,
                  targetingText: childDocument.targetingText,
                  extendedAttributes: {
                    retailerId: childDocument.retailerId,
                    beaconClientLoginId: childDocument.beaconClientLoginId,
                    entityId: childDocument.entityId,
                    campaignId: childDocument.campaignId,
                    adGroupId: childDocument.adGroupId,
                    cpcMaxBidAmount: childDocument.cpcMaxBidAmount,
                    cpcMinBidAmount: childDocument.cpcMinBidAmount,
                    targetId: childDocument.targetId,
                    campaignType: childDocument.campaignType,
                    status: updatedStatus
                  }
                } as IAdTarget);
              }
            });
          }
          break;
        case 'adCampaignProduct':
        case 'adcampaignadgroupproduct':
        case 'product': // this will be deprecated once we fix the entity types returned from the api
          {
            const {
              extendedAttributes: { childDocuments }
            } = entity;
            childDocuments.forEach((childDocument: any) => {
              if (checkArchived(childDocument.statusDerived, childDocument.platformType)) {
                return;
              }
              if (childDocument.adId && childDocument.adId !== '-1' && childDocument.platformType) {
                entitiesModified.push({
                  beaconClientId: childDocument.beaconClientId,
                  platformType: childDocument.platformType,
                  campaignId: childDocument.campaignId,
                  adGroupId: childDocument.adGroupId,
                  retailerId: childDocument.retailerId,
                  retailerSku: childDocument.retailerSku,
                  stacklineSku: childDocument.stacklineSku,
                  extendedAttributes: {
                    stacklineSku: childDocument.stacklineSku,
                    retailerId: childDocument.retailerId,
                    beaconClientLoginId: childDocument.beaconClientLoginId,
                    entityId: childDocument.entityId,
                    campaignId: childDocument.campaignId,
                    adGroupId: childDocument.adGroupId,
                    adId: childDocument.adId,
                    campaignType: childDocument.campaignType,
                    status: updatedStatus
                  }
                } as unknown as IAdCampaignProduct);
              }
            });
          }
          break;
        default:
          break;
      }
    });
    switch (entityTypeToUse) {
      case 'adEntity':
        updateStatusRequest.extendedAttributes.adEntities = entitiesModified as IAdEntityAdPlatformSettingsByClient[];
        break;
      case 'adPortfolio':
        updateStatusRequest.extendedAttributes.adPortfolios =
          entitiesModified as IAdPortfolioAdPlatformSettingsByClient[];
        break;
      case 'adCampaign':
      case 'adcampaignadgrouptarget':
        updateStatusRequest.extendedAttributes.adCampaigns = entitiesModified as IAdCampaign[];
        break;
      case 'adCampaignProduct':
      case 'adcampaignadgroupproduct':
      case 'product': // this will be deprecated once we fix the entity types returned from the api
        updateStatusRequest.extendedAttributes.adCampaignProducts = entitiesModified as IAdCampaignProduct[];
        break;
      case 'adTarget':
        updateStatusRequest.extendedAttributes.adTargets = entitiesModified as IAdTarget[];
        break;
      default:
        break;
    }
    return updateStatusRequest;
  }

  private static buildUpdateProductBidRequest({
    beaconClientId,
    platformType,
    entities,
    entityType
  }: {
    beaconClientId: number;
    platformType: AD_PLATFORM_TYPE;
    entities: (
      | IAdEntityAdPlatformSettingsByClient
      | IAdPortfolioAdPlatformSettingsByClient
      | IAdCampaign
      | IAdCampaignProduct
      | IAdCampaignProductBase
    )[];
    entityType: string;
    updatedStatus?: AD_CAMPAIGN_STATUS;
    changeRateOverwrite?: any;
  }) {
    const updateProductBidRequest: IUpdateAdTargetBidRequest = {
      beaconClientId,
      platformType,
      updateType: 'updateProductBid',
      extendedAttributes: {
        status: AD_UPDATE_STATUS.NEW,
        adCampaignProducts: []
      }
    };
    const entitiesModified: IAdCampaignProductBase[] = [];
    entities.forEach((entity) => {
      switch (entityType) {
        case 'adCampaign':
        case 'adcampaignadgroupproduct':
        case 'product':
          {
            const { extendedAttributes } = entity;
            entitiesModified.push({
              beaconClientId: entity.beaconClientId,
              platformType: entity.platformType,
              campaignId: entity.campaignId,
              adGroupId: entity.adGroupId,
              retailerSku: entity.retailerSku,
              stacklineSku: entity.stacklineSku,
              extendedAttributes: {
                retailerId: entity.retailerId,
                beaconClientLoginId: entity.beaconClientLoginId,
                entityId: entity.entityId,
                campaignId: entity.campaignId,
                adGroupId: entity.adGroupId,
                portfolioId: entity.portfolioId === STACKLINE_UNASSIGNED.portfolioId ? undefined : entity.portfolioId,
                bid: +extendedAttributes.bid.toFixed(2),
                campaignType: entity.campaignType
              }
            });
          }
          break;
        default:
          break;
      }
    });
    switch (entityType) {
      case 'adCampaign':
      case 'adcampaignadgroupproduct':
      case 'product':
        updateProductBidRequest.extendedAttributes.adCampaignProducts = entitiesModified as IAdTarget[];
        break;
      default:
        break;
    }
    return updateProductBidRequest;
  }

  private static buildUpdateBidRequest({
    beaconClientId,
    platformType,
    entities,
    entityType,
    changeRateOverwrite = null
  }: {
    beaconClientId: number;
    platformType: AD_PLATFORM_TYPE;
    entities: (
      | IAdEntityAdPlatformSettingsByClient
      | IAdPortfolioAdPlatformSettingsByClient
      | IAdCampaign
      | IAdCampaignProduct
      | IAdTarget
    )[];
    entityType: string;
    updatedStatus?: AD_CAMPAIGN_STATUS;
    changeRateOverwrite?: any;
  }) {
    const updateTargetBidRequest: IUpdateAdTargetBidRequest = {
      beaconClientId,
      platformType,
      updateType: AD_UPDATE_TYPE.UPDATE_TARGET_BID,
      extendedAttributes: {
        status: AD_UPDATE_STATUS.NEW,
        adCampaigns: [],
        adGroups: [],
        adCampaignProducts: [],
        adTargets: []
      }
    };

    const entitiesModified: IAdTarget[] = [];
    entities.forEach((entity) => {
      switch (entityType) {
        case 'adCampaign': {
          const { campaignId, extendedAttributes } = entity as IAdCampaign;
          const statusDerived = _get(entity, ['extendedAttributes', 'statusDerived'], '');
          if (checkArchived(statusDerived, platformType)) {
            return;
          }
          entitiesModified.push({
            beaconClientId: entity.beaconClientId,
            platformType: entity.platformType,
            campaignId,
            extendedAttributes: {
              ...extendedAttributes
            }
          });
          break;
        }
        case 'adTarget':
          {
            const {
              extendedAttributes: { childDocuments }
            } = entity;
            childDocuments.forEach((childDocument: any) => {
              if (checkArchived(childDocument.statusDerived, childDocument.platformType)) {
                return;
              }
              if (childDocument.targetingClass && childDocument.targetId && childDocument.platformType) {
                const min = +childDocument.cpcMinBidAmount;
                const max = +childDocument.cpcMaxBidAmount;
                let minBid = min;
                let maxBid = max;

                if (changeRateOverwrite) {
                  if (changeRateOverwrite.method === 'Increase') {
                    minBid = (100 + changeRateOverwrite.value) * 0.01 * min;
                    maxBid = (100 + changeRateOverwrite.value) * 0.01 * max;
                  } else if (changeRateOverwrite.method === 'Reduce') {
                    minBid = (100 - changeRateOverwrite.value) * 0.01 * min;
                    maxBid = (100 - changeRateOverwrite.value) * 0.01 * max;
                  }
                }
                entitiesModified.push({
                  beaconClientId: childDocument.beaconClientId,
                  platformType: childDocument.platformType,
                  campaignId: childDocument.campaignId,
                  adGroupId: childDocument.adGroupId,
                  targetId: childDocument.targetId,
                  targetingClass: childDocument.targetingClass,
                  targetingText: childDocument.targetingText,
                  extendedAttributes: {
                    retailerId: childDocument.retailerId,
                    beaconClientLoginId: childDocument.beaconClientLoginId,
                    entityId: childDocument.entityId,
                    campaignId: childDocument.campaignId,
                    adGroupId: childDocument.adGroupId,
                    portfolioId: childDocument.portfolioId,
                    cpcMaxBidAmount: +maxBid.toFixed(2),
                    cpcMinBidAmount: +minBid.toFixed(2),
                    targetId: childDocument.targetId,
                    campaignType: childDocument.campaignType
                  }
                });
              }
            });
          }
          break;
        case 'product': {
          const {
            extendedAttributes: { childDocuments }
          } = entity;
          childDocuments.forEach((childDocument: any) => {
            if (checkArchived(childDocument.statusDerived, childDocument.platformType)) {
              return;
            }
            if (childDocument.adId && childDocument.adId !== '-1' && childDocument.platformType) {
              entitiesModified.push({
                beaconClientId: childDocument.beaconClientId,
                platformType: childDocument.platformType,
                campaignId: childDocument.campaignId,
                adGroupId: childDocument.adGroupId,
                retailerId: childDocument.retailerId,
                retailerSku: childDocument.retailerSku,
                stacklineSku: childDocument.stacklineSku,
                extendedAttributes: {
                  stacklineSku: childDocument.stacklineSku,
                  retailerId: childDocument.retailerId,
                  beaconClientLoginId: childDocument.beaconClientLoginId,
                  entityId: childDocument.entityId,
                  campaignId: childDocument.campaignId,
                  adGroupId: childDocument.adGroupId,
                  adId: childDocument.adId,
                  campaignType: childDocument.campaignType,
                  status: childDocument.status
                }
              });
            }
          });
          break;
        }
        default:
          break;
      }
    });

    switch (entityType) {
      case 'adCampaign':
        updateTargetBidRequest.extendedAttributes.adCampaigns = entitiesModified as IAdTarget[];
        break;
      case 'adTarget':
        updateTargetBidRequest.extendedAttributes.adTargets = entitiesModified as IAdTarget[];
        break;
      case 'product':
        updateTargetBidRequest.extendedAttributes.adCampaignProducts = entitiesModified as IAdTarget[];
        break;
      default:
        break;
    }

    return updateTargetBidRequest;
  }

  private static buildBulkBudgetRequest({
    beaconClientId,
    platformType,
    entities,
    entityType,
    changeRateOverwrite = null
  }: {
    beaconClientId: number;
    platformType: AD_PLATFORM_TYPE;
    entities: IAdCampaign[];
    entityType: string;
    updatedStatus?: AD_CAMPAIGN_STATUS;
    changeRateOverwrite?: any;
  }) {
    const updateBudgetRequest: IBulkBudgetUpdateRequest = {
      beaconClientId,
      platformType,
      updateType: AD_UPDATE_TYPE.BULK_BUDGET_UPDATE,
      extendedAttributes: {
        status: AD_UPDATE_STATUS.NEW,
        adCampaignsBudgetSetting: []
      }
    };

    const entitiesModified: IAdCampaign[] = [];
    const { adCampaigns } = store.getState();
    entities.forEach((entity) => {
      const campaignEntity = adCampaigns.find((campaign: any) => campaign.id === entity.id);
      const budgetAmount = _get(campaignEntity, ['extendedAttributes', 'liveBudgetSetting', 'amount'], null);
      let newBudgetAmount = budgetAmount || null;
      if (changeRateOverwrite && budgetAmount) {
        if (changeRateOverwrite.method === 'Increase') {
          newBudgetAmount = +((100 + changeRateOverwrite.value) * 0.01 * budgetAmount).toFixed(2);
        } else if (changeRateOverwrite.method === 'Reduce') {
          newBudgetAmount = +((100 - changeRateOverwrite.value) * 0.01 * budgetAmount).toFixed(2);
        }
      }
      switch (entityType) {
        case 'adCampaign': {
          const { extendedAttributes } = entity;
          const statusDerived = _get(entity, ['extendedAttributes', 'statusDerived'], '');
          if (checkArchived(statusDerived, platformType)) {
            return;
          }
          entitiesModified.push({
            beaconClientId: entity.beaconClientId,
            platformType: entity.platformType,
            beaconClientLoginId: extendedAttributes.beaconClientLoginId,
            entityId: extendedAttributes.entityId,
            entityIdApi: extendedAttributes.entityIdApi,
            entityIdUi: extendedAttributes.entityIdUi,
            portfolioId: extendedAttributes.portfolioId,
            portfolioIdApi: extendedAttributes.portfolioIdApi,
            campaignId: extendedAttributes.campaignId,
            campaignIdApi: extendedAttributes.campaignId,
            campaignType: extendedAttributes.campaignType,
            campaignTypeApi: extendedAttributes.campaignTypeApi,
            currentMonthBudgetSetting:
              newBudgetAmount !== null
                ? {
                    amount: newBudgetAmount
                  }
                : null
          } as IAdCampaign);
          break;
        }
        default:
          break;
      }
    });
    switch (entityType) {
      case 'adCampaign':
      case 'adTarget':
        updateBudgetRequest.extendedAttributes.adCampaignsBudgetSetting = entitiesModified as IAdCampaign[];
        break;
      default:
        break;
    }
    return updateBudgetRequest;
  }
}
