import colors from 'src/utils/colors';

/**
 * Instacart requires a minimum daily budget of $20
 * to opt-in to their optimization.
 */
export const INSTACART_MIN_DAILY_BUDGET = 20;

export const muiStyles = {
  root: {
    margin: '0 0 0 0',
    fontSize: '16px',
    width: 500,
    '& input': {
      '&::placeholder': {
        color: colors.labelGrey,
        opacity: 0.8
      }
    }
  },
  selectRoot: {
    width: 300,
    maxWidth: 300,
    margin: 20,
    marginLeft: 0
  },
  select: {
    padding: '7px 27px 7px 0px',
    // minHeight: 15,
    width: 300,
    maxWidth: 300
  },
  dateRoot: {
    margin: '0 0 0 0',
    fontSize: '16px',
    width: 125,
    lineHeight: '1.1em',
    '&:before': {
      borderBottom: '1px solid #eee'
    }
  },
  actionType: {
    margin: '0 0 0 0',
    fontSize: '16px',
    width: 195,
    lineHeight: '1.1em',
    '&:before': {
      borderBottom: '1px solid #eee'
    }
  },
  shortRoot: {
    margin: '0 0 0 0',
    fontSize: '16px',
    width: 80,
    lineHeight: '1.1em',
    '&:before': {
      borderBottom: '1px solid #eee'
    }
  },
  longRoot: {
    margin: '0 0 0 0',
    fontSize: '16px',
    width: 200,
    lineHeight: '1.1em',
    '&:before': {
      borderBottom: '1px solid #eee'
    }
  },
  centerRow: { display: 'flex', alignItems: 'center', position: 'relative' },
  centerRowOverride: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    position: 'relative'
  },
  centerRowOptions: {
    position: 'relative',
    alignItems: 'center',
    margin: '1px 20px',
    display: 'grid',
    gridTemplateColumns: '150px auto',
    paddingBottom: 15
  },
  disabled: {
    color: colors.labelGrey
  },
  switchDisabled: {
    color: `${colors.labelGrey} !important`
  }
};
