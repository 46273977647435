import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Checkbox from '@mui/material/Checkbox';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import { withBus } from 'react-bus';
import { shouldShowReclassify, shouldShowNewBeacon } from 'src/utils/app';
import { UNCLASSIFIED_SUBCATEGORY_ID } from 'src/utils/constants';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { getProductImageUrl } from 'src/utils/image';
import CardView from '../../Card/CardView';
import CardFront from '../../Card/CardFront';
import CardBack from '../../Card/CardBack';
import convertMetricToDisplayValue, { createFilterParams } from '../gridUtils';
import './TileContainer.scss';
import { error } from 'src/utils/mixpanel';
import { AppName } from 'sl-api-connector';
import { destructurePathName } from 'src/utils/urlParsing';
import { compose } from 'redux';
import ProductCard from 'src/components/BeaconRedesignComponents/ProductCard/ProductCard';
import { getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import { QUICK_EXCLUDE_EVENT } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Segments/SegmentBuilder';

const styles = {
  categoryMismatch: {
    borderColor: '#e77600',
    boxShadow: '0 0 3px 2px rgba(228,121,17,.5)',
    minHeight: 600
  }
};

export const getIsBreadcrumbCategoryMismatch = (cardView) => {
  if (!cardView) {
    error('Missing cardview in `getIsBreadcrumbCategoryMismatch`');
    return false;
  }

  const { subCategoryId, breadCrumbSubCategoryId } = cardView;
  return (
    !!breadCrumbSubCategoryId &&
    breadCrumbSubCategoryId > 0 &&
    breadCrumbSubCategoryId !== UNCLASSIFIED_SUBCATEGORY_ID &&
    breadCrumbSubCategoryId.toString() !== subCategoryId.toString()
  );
};

export const getIsSkuAlreadyClassified = (cardView, classifiedUniqueStacklineSkus) => {
  if (!cardView) {
    error('Missing stacklineSku in `getIsSkuAlreadyClassified`');
    return false;
  }
  const { stacklineSku } = cardView;
  return classifiedUniqueStacklineSkus.has(stacklineSku);
};

class TileContainer extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    dataItem: PropTypes.object.isRequired,
    isFlippable: PropTypes.bool,
    isPromotionsPage: PropTypes.bool,
    location: PropTypes.object.isRequired,
    onReclassifyChecked: PropTypes.func,
    reclassifyProducts: PropTypes.array.isRequired,
    retailer: PropTypes.object.isRequired,
    showStatusBar: PropTypes.bool,
    user: PropTypes.object.isRequired,
    showPriceData: PropTypes.bool.isRequired,
    showCardBackDeleteButton: PropTypes.bool.isRequired,
    onDelete: PropTypes.func,
    shouldDisableClick: PropTypes.bool,
    shouldShowNav: PropTypes.bool,
    eventBus: PropTypes.object.isRequired
  };

  static defaultProps = {
    showStatusBar: false,
    isFlippable: true,
    isPromotionsPage: false,
    onReclassifyChecked: () => {},
    onDelete: undefined,
    shouldDisableClick: false,
    shouldShowNav: false
  };

  state = {
    isMouseOver: false,
    isCardFlipped: false
  };

  stopPropagation = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  handleMouseOver = (isMouseOver) => this.setState({ isMouseOver });

  handleFlipButtonClick = (event) => {
    this.stopPropagation(event);
    this.setState({ isCardFlipped: !this.state.isCardFlipped });
  };

  // Handles quick exclude button click
  handleExcludeButtonClick = (event) => {
    this.stopPropagation(event);
    const {
      dataItem: {
        entity: { retailerSku }
      }
    } = this.props;
    const { eventBus } = this.props;
    eventBus.emit(QUICK_EXCLUDE_EVENT, retailerSku);
  };

  handleReclassifyClick = (evt) => {
    const { dataItem, onReclassifyChecked } = this.props;
    const { checked } = evt.target;
    this.stopPropagation(evt);
    onReclassifyChecked({
      ...dataItem,
      isChecked: checked
    });
  };

  showReclassifyCheckbox = (stacklineSku) => {
    if (!shouldShowReclassify(this.props.app, this.props.user, this.props.location)) {
      return null;
    }

    return (
      <div>
        <Checkbox
          id={`${stacklineSku}`}
          className="reclassify__product-checkbox"
          checked={this.props.reclassifyProducts.includes(stacklineSku)}
          onChange={this.handleReclassifyClick}
          onClick={this.stopPropagation}
          color="primary"
        />
      </div>
    );
  };

  render() {
    const {
      app,
      comparisonTimePeriod,
      user,
      dataItem,
      retailer,
      showStatusBar,
      isFlippable,
      isPromotionsPage,
      location,
      showCardBackDeleteButton,
      onDelete,
      shouldDisableClick,
      shouldShowNav
    } = this.props;

    const {
      availabilityStatusCode,
      brandName,
      brandId,
      categoryId,
      categoryName,
      metricData,
      mainMetric,
      secondaryMetric,
      gtin,
      retailerSku,
      secondaryDisplayTitle,
      stacklineSku,
      subCategoryName,
      subCategoryId,
      translatedTitles,
      breadCrumbSubCategoryName,
      breadCrumbSubCategoryId,
      breadCrumbNodeId,
      breadCrumbText,
      upc,
      retailerId,
      brandNameNormalized
    } = dataItem.cardView;
    const { cardView } = dataItem;
    const { isMouseOver, isCardFlipped } = this.state;
    const { isStacklineSuperUser } = user.config;
    const { pathname } = location;
    const [entityType, entityId] = destructurePathName(pathname);

    let shouldSupportJumpingToProduct = true;
    const wrapRetailerSku = {};
    if (!app.shouldSupportJumpingToRetailerProdPage && (entityType === 'retailer' || entityType === 'product')) {
      shouldSupportJumpingToProduct = true;
      if (retailerId) {
        wrapRetailerSku[retailerId] = retailerSku;
      }
    } else {
      shouldSupportJumpingToProduct = app.shouldSupportJumpingToRetailerProdPage;
    }
    let retailerName;
    if (retailerId && app.name === AppName.Omni) {
      retailerName = null;
    }

    // // In Omni retailer view, clicking the product should add filter of retailer.
    let passingFilterParams = '';
    if (entityType === 'retailer' && app.name === AppName.Omni) {
      passingFilterParams = `&filter=${JSON.stringify({ r: [{ i: String(entityId) }] })}`;
    }
    const parentBrandName = _get(dataItem, ['entity', 'parentBrandName']);
    const showReclassifyInterface = shouldShowReclassify(app, user, location);
    let displayedMainMetric = mainMetric;

    let secondaryMetricRaw = null;
    let secondaryMetricClassName = '';
    let displayedComparisonTimePeriod = '';
    let displayedSecondaryMetric = '';
    if (metricData) {
      const { currencySymbol, name, metricType } = metricData;
      displayedSecondaryMetric = `${convertMetricToDisplayValue(
        retailer,
        secondaryMetric,
        metricType,
        currencySymbol,
        false
      )} Stars`;

      if (!isPromotionsPage) {
        displayedMainMetric = convertMetricToDisplayValue(
          retailer,
          cardView[`${name}CurrentValue`],
          metricType,
          currencySymbol,
          metricType === METRICTYPE.PERCENT
        );

        let mainValueArgs = convertMetricToDisplayValue(
          retailer,
          cardView[`${name}PeriodChange`],
          metricType,
          currencySymbol,
          metricType === METRICTYPE.PERCENT
        );

        const periodChange = (args) => {
          let metricIcon = { name: '+', className: 'plus' };
          const mainValue = args;

          if (cardView[`${name}PeriodChange`] < 0) {
            metricIcon = { name: '\u2212', className: 'minus' };
          }

          const combinedValue = `${metricIcon.name}${mainValue.startsWith('-') ? mainValue.slice(1) : mainValue}`;
          return { value: combinedValue, className: metricIcon.className };
        };
        const percentChange = convertMetricToDisplayValue(
          retailer,
          cardView[`${name}PercentChange`],
          METRICTYPE.PERCENT,
          currencySymbol,
          true
        );

        /* Omni Tile - */
        if (app.name === AppName.Omni) {
          if (name.includes('shareOfShelf')) {
            displayedMainMetric = convertMetricToDisplayValue(
              retailer,
              cardView[`${name}CurrentValue`],
              metricType,
              currencySymbol,
              metricType === METRICTYPE.PERCENT,
              { decimalPlaces: 2 }
            );

            mainValueArgs = convertMetricToDisplayValue(
              retailer,
              cardView[`${name}PeriodChange`],
              metricType,
              currencySymbol,
              metricType === METRICTYPE.PERCENT,
              { decimalPlaces: 2 }
            );
          }
        }

        secondaryMetricRaw = cardView[`${name}PercentChange`];
        displayedSecondaryMetric = `${periodChange(mainValueArgs).value} (${percentChange})`;
        secondaryMetricClassName = periodChange(mainValueArgs).className;
        displayedComparisonTimePeriod = comparisonTimePeriod.shortDisplayName;
      }
    }

    const categoryAndBreadcrumbMismatch =
      !!showReclassifyInterface && getIsBreadcrumbCategoryMismatch(dataItem.cardView);

    const translationEnabled = _get(user, ['config', 'translationEnabled', +retailer.id], []);
    let translatedTitle;
    translationEnabled.forEach((locale) => {
      if (!translatedTitle && translatedTitles && translatedTitles[locale]) {
        translatedTitle = translatedTitles[locale];
      }
    });

    const cardFront = (
      <CardFront
        app={app}
        retailerId={retailerId || retailer.id}
        availabilityStatusCode={availabilityStatusCode}
        brandId={brandId}
        cardView={dataItem.cardView}
        categoryName={categoryName}
        categoryId={categoryId}
        comparisonTimePeriodDisplayName={displayedComparisonTimePeriod}
        imageUri={getProductImageUrl(stacklineSku)}
        isSuperUser={isStacklineSuperUser}
        isMouseOver={isMouseOver}
        mainDisplayTitle={app.shouldUseBrandNameNormalized ? brandNameNormalized : brandName}
        mainMetric={displayedMainMetric}
        queryParams={app.queryParams}
        secondaryDisplayTitle={translationEnabled && translatedTitle ? translatedTitle : secondaryDisplayTitle}
        secondaryMetric={displayedSecondaryMetric}
        secondaryMetricClassName={secondaryMetricClassName}
        showReclassifyCheckbox={() => this.showReclassifyCheckbox(stacklineSku)}
        stacklineSku={app.shouldUseGTIN ? gtin : stacklineSku}
        subCategoryName={subCategoryName}
        subCategoryId={subCategoryId}
        breadCrumbSubCategoryName={breadCrumbSubCategoryName}
        breadCrumbSubCategoryId={breadCrumbSubCategoryId}
        breadCrumbNodeId={breadCrumbNodeId}
        breadCrumbText={breadCrumbText}
        shouldShowReclassify={showReclassifyInterface}
        shouldDisableClick={shouldDisableClick}
        passingFilterParams={passingFilterParams}
        isAlreadyClassified={dataItem.isAlreadyClassified}
      />
    );
    const cardBack = (
      <CardBack
        availabilityStatusCode={availabilityStatusCode}
        app={app}
        retailerId={retailerId || retailer.id}
        retailerSku={app.shouldUseWrappedRetailerSku ? wrapRetailerSku : retailerSku}
        brandName={app.name === AppName.Omni ? brandNameNormalized : brandName}
        brandId={brandId}
        parentBrandName={parentBrandName}
        categoryName={categoryName}
        categoryId={categoryId}
        queryParams={app.queryParams}
        secondaryDisplayTitle={translationEnabled && translatedTitle ? translatedTitle : secondaryDisplayTitle}
        gtin={gtin}
        stacklineSku={stacklineSku}
        subCategoryName={subCategoryName}
        subCategoryId={subCategoryId}
        showCardBackDeleteButton={showCardBackDeleteButton}
        onDelete={onDelete}
        upc={upc}
        retailerName={retailerName}
        shouldSupportJumpingToProduct={shouldSupportJumpingToProduct}
        shouldDisableClick={shouldDisableClick}
        shouldShowNav={shouldShowNav}
        passingFilterParams={passingFilterParams}
        isAlreadyClassified={dataItem.isAlreadyClassified}
      />
    );

    if (shouldShowNewBeacon()) {
      const productCardSecondaryMetric = displayedSecondaryMetric.slice(1);
      const { searchParams, additionalParams, filter } = app.queryParams;
      const filterParams = createFilterParams(passingFilterParams, filter);
      const { targetUrl, name } = app;

      return (
        <ProductCard
          availabilityStatusCode={availabilityStatusCode}
          imageUri={getProductImageUrl(stacklineSku)}
          mainDisplayTitle={app.shouldUseBrandNameNormalized ? brandNameNormalized : brandName}
          mainMetric={displayedMainMetric}
          secondaryDisplayTitle={translationEnabled && translatedTitle ? translatedTitle : secondaryDisplayTitle}
          secondaryMetric={productCardSecondaryMetric}
          direction={getDirection(secondaryMetricRaw)}
          stacklineSku={stacklineSku}
          retailerSku={retailerSku[retailer.id]}
          categoryName={categoryName}
          subCategoryName={subCategoryName}
          statusColor={availabilityStatusCode === 'In stock' ? 'success' : 'error'}
          productUrl={`/product/${stacklineSku}${searchParams}${additionalParams}${filterParams}`}
          brandUrl={`/brand/${brandId}${searchParams}${additionalParams}`}
          retailerUrl={`https://${targetUrl}/api/utility/OpenRetailerProductPage?appName=${name}&retailerId=${retailer.id}&stacklineSku=${stacklineSku}`}
          categoryUrl={`/category/${categoryId}${searchParams}${additionalParams}`}
          subcategoryUrl={`/subcategory/${subCategoryId}${searchParams}${additionalParams}`}
        />
      );
    }

    return (
      <div className="entity-card" style={categoryAndBreadcrumbMismatch ? styles.categoryMismatch : undefined}>
        <CardView
          dataItem={dataItem}
          isFlippable={isFlippable}
          cardFront={cardFront}
          cardBack={cardBack}
          handleMouseOver={this.handleMouseOver}
          handleFlipButtonClick={this.handleFlipButtonClick}
          handleExcludeButtonClick={this.handleExcludeButtonClick}
          isCardFlipped={isCardFlipped}
          isMouseOver={isMouseOver}
          onFocus={() => {}}
          tall={showStatusBar}
          shouldShowReclassify={showReclassifyInterface}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => _pick(state, ['app', 'comparisonTimePeriod', 'retailer', 'user']);

export default compose(withRouter, connect(mapStateToProps), withBus('eventBus'))(TileContainer);
