import React, { FC } from 'react';
import OmniDropDownSelector from 'src/components/Omni/OmniDropDownSelector/OmniDropDownSelector';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { getRetailerIdDisplayName } from 'src/utils/stringFormatting';
import { SegmentState, KeyWordSegment, StoreSegment } from 'src/components/Omni/OmniSegmentUtil';
import { StoreListNotSupportedRetailer } from 'src/store/modules/retailer/reducers';

interface OmniRetailerDropDownSelector {
  parsedState: SegmentState | KeyWordSegment | StoreSegment;

  handleChange: (newChange: { key: string; values: { i: string }[] }) => void;
}

const OmniRetailerDropDownSelector: FC<OmniRetailerDropDownSelector> = ({ parsedState, handleChange }) => {
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const omniRetailers = useSelector((state: ReduxStore) => state.omniRetailers);
  // let filteredRetailer = omniRetailers.filter((r) => ![43, 63].includes(r.retailerId));
  const filteredRetailer = omniRetailers.data.filter((r) => !StoreListNotSupportedRetailer.includes(r.retailerId));
  const [initialVal] = (parsedState as StoreSegment).retailerIds;
  const listSelection = filteredRetailer.map((r) => ({
    value: r.retailerId,
    displayName: getRetailerIdDisplayName(String(r.retailerId), retailer)
  }));
  const triggerFunc = (curVal: number) => {
    handleChange({ key: 'retailerIds', values: [{ i: String(curVal) }] });
  };
  return (
    <div>
      <OmniDropDownSelector
        headerDisplayName="Retailer"
        listSelection={listSelection}
        initialVal={initialVal || listSelection[0].value}
        triggerFunc={triggerFunc}
      />
    </div>
  );
};

export default OmniRetailerDropDownSelector;
