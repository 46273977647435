import { styled } from '@mui/material/styles';
import React, { ButtonHTMLAttributes } from 'react';

const AuthButtonInner = styled('button')({
  height: '47px',
  borderRadius: '24px',
  backgroundColor: '#052849',
  color: 'white',
  fontSize: '18px',
  fontWeight: 500,
  border: 'none',
  width: '100%'
});

const AuthButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  return <AuthButtonInner id="signin-button" {...props} />;
};

export default AuthButton;
