import React, { useCallback } from 'react';
import usePublishBulkAdjustment from '../hooks/usePublishBulkAdjustment';
import { PublishBulkAdjustmentPayload } from '../serverProxy/types';
import useServerProxy from '../hooks/useServerProxy';
import { StartPollingError, usePollingContext } from 'src/providers/PollingContextProvider';
import { useAppSelector, useSnackbar } from 'src/utils/Hooks';
import { TemplateSubmittingState, TemplateSubmittingStatus } from '../BulkTemplateSubmittingModal';
import { BulkAdjustmentModalType } from '../BulkAdjustmentProvider';
import { Link } from 'react-router-dom';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { trackMixpanelEvent } from 'src/utils/mixpanel';
import { GenericErrorMessage } from 'src/components/BeaconRedesignComponents/common/CommonSnackbarMessages/GenericErrorMessage';

/**
 * Gets a unique polling ID for publishing a bulk adjustment
 */
const getPublishPollingId = (bulkAdjustmentId: string) => `PUBLISH_BULK_ADJUSTMENT_${bulkAdjustmentId}`;
/**
 * Publishes a bulk adjustment and then polls for its status
 */
export default function usePublishAndPollBulkAdjustment({
  setState,
  bulkAdjustmentIdRef,
  bulkAdjustmentModalRef,
  closeModal
}: {
  bulkAdjustmentIdRef: React.MutableRefObject<string | null>;
  bulkAdjustmentModalRef: React.MutableRefObject<BulkAdjustmentModalType | null>;
  setState: React.Dispatch<React.SetStateAction<TemplateSubmittingState>>;
  closeModal: () => void;
}) {
  const { mutateAsync: publishBulkAdjustment } = usePublishBulkAdjustment();
  const { startPolling, stopPolling } = usePollingContext();
  const { pollBulkAdjustments } = useServerProxy();
  const { showSnackbar, closeSnackbar } = useSnackbar();
  const { queryParams } = useAppSelector((state) => state.app);

  /**
   * Increase the completion percent in the popup
   */
  const incrementPublishPercent = useCallback(
    (amount = 1) => {
      setState((prevState) => {
        if (
          prevState &&
          (prevState.status === TemplateSubmittingStatus.Submitting ||
            prevState.status === TemplateSubmittingStatus.SubmissionResume)
        ) {
          return {
            ...prevState,
            percentage: Math.min(prevState.percentage + amount, 99)
          };
        }
        return prevState;
      });
    },
    [setState]
  );

  /**
   * True if the user stayed on the template publishing popup
   * while the adjustment was publishing
   */
  const getStayedOnPopup = useCallback(
    (bulkAdjustmentId: string) => {
      return (
        bulkAdjustmentId === bulkAdjustmentIdRef.current &&
        bulkAdjustmentModalRef.current === BulkAdjustmentModalType.TemplateSubmitting
      );
    },
    [bulkAdjustmentIdRef, bulkAdjustmentModalRef]
  );

  /**
   * Returns a promise that resolves when polling is finished and the adjusted
   * forecast status is either Success or Error
   */
  const pollForPublishCompletion = useCallback(
    async (bulkAdjustmentId: string, planType: string) => {
      const publishPollingId = getPublishPollingId(bulkAdjustmentId);
      return new Promise((resolve, reject) => {
        startPolling(
          publishPollingId,
          async () => {
            const responses = await pollBulkAdjustments({
              bulkAdjustmentId,
              bulkAdjustmentStage: 'Publish',
              planType
            });

            if (responses.length > 0 && responses.every((response) => response.processingStatus === 'Success')) {
              stopPolling(publishPollingId);
              resolve(responses);
            } else if (
              responses.length === 0 ||
              (responses.length > 0 && responses.some((response) => response.processingStatus === 'Error'))
            ) {
              stopPolling(publishPollingId);
              reject();
            }
          },
          {
            interval: 10000,
            leading: true
          }
        );
      });
    },
    [pollBulkAdjustments, startPolling, stopPolling]
  );

  const handleError = useCallback(() => {
    closeModal();
    showSnackbar({
      message: <GenericErrorMessage />,
      type: 'error'
    });
  }, [closeModal, showSnackbar]);

  /**
   * Waits for adjustment to finish publishing. When it's done, will either
   * show a snackbar or the success modal
   */
  const pollBulkAdjustment = useCallback(
    async (bulkAdjustmentId: string, planType: PlanTypeOption) => {
      const INCREMENT_AMOUNT = 5; // increment by a random number up to this value
      const INCREMENT_INTERVAL = 3000; // number of ms between increments

      // Gradually increment completion percentage
      // TODO discuss with design
      const incrementInterval = setInterval(() => {
        incrementPublishPercent(Math.floor(Math.random() * INCREMENT_AMOUNT));
      }, INCREMENT_INTERVAL);

      try {
        await pollForPublishCompletion(bulkAdjustmentId, planType);

        if (getStayedOnPopup(bulkAdjustmentId)) {
          setState({
            status: TemplateSubmittingStatus.SubmissionSuccess
          });
        } else {
          showSnackbar({
            message: (
              <Flex>
                Your bulk adjustment was successful. View results{' '}
                <Link
                  to={`/account/adjustments${queryParams.searchParams}`}
                  onClick={() => closeSnackbar()}
                  style={{
                    textDecoration: 'underline',
                    marginLeft: '2px'
                  }}
                >
                  here
                </Link>
                .
              </Flex>
            ),
            type: 'success'
          });
        }
      } catch (error) {
        // Handle start polling error
        if (error instanceof StartPollingError) {
          return;
        }

        handleError();
      } finally {
        clearInterval(incrementInterval);
      }
    },
    [
      closeSnackbar,
      getStayedOnPopup,
      handleError,
      incrementPublishPercent,
      pollForPublishCompletion,
      queryParams.searchParams,
      setState,
      showSnackbar
    ]
  );

  /**
   * Publishes the bulk adjustment and handles success/error flows
   */
  const publishAndPollBulkAdjustment = useCallback(
    async (payload: PublishBulkAdjustmentPayload) => {
      try {
        trackMixpanelEvent({
          eventName: 'publish bulk adjustment',
          data: {
            bulkAdjustmentId: payload.bulkAdjustmentId,
            planType: payload.planType,
            title: payload.bulkAdjustmentTitle,
            description: payload.bulkAdjustmentDescription
          }
        });
        // Tell the backend we've published the adjustment
        await publishBulkAdjustment(payload);
        incrementPublishPercent(10);

        // Poll for publish status
        await pollBulkAdjustment(payload.bulkAdjustmentId, payload.planType);
      } catch {
        trackMixpanelEvent({
          eventName: 'error publishing bulk adjustment',
          data: {
            bulkAdjustmentId: payload.bulkAdjustmentId
          }
        });
        handleError();
      }
    },
    [handleError, incrementPublishPercent, pollBulkAdjustment, publishBulkAdjustment]
  );

  return { publishAndPollBulkAdjustment, pollBulkAdjustment };
}
