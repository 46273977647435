/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import { AdjustmentDataItem } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/utils';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { useUserId } from 'src/utils/Hooks/reduxSelectors';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { formatISODate } from 'src/utils/dateUtils';
import useAdjustmentDownloadServerProxy from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/serverProxy/useAdjustmentDownloadServerProxy';
import Pagination from 'src/components/BeaconRedesignComponents/Pagination/Pagination';
import { useQuery, useQueryClient } from 'react-query';
import { NoDataPlaceHolderTableRow } from 'src/components/BeaconRedesignComponents/common/NoDataPlaceHolder/NoDataPlaceHolder';
import { useStacklineTheme } from '@stackline/ui';
import { useAppSelector } from 'src/utils/Hooks';
import axios from 'axios';
import { trackMixpanelEvent } from 'src/utils/mixpanel';

const ADJUSTMENT_DOWNLOAD_PAGE_SIZE = 20;

const AdjustmentStatusDisplayName = {
  Success: 'Download',
  Error: 'Failed',
  Queued: 'Processing',
  InProgress: 'Processing'
};

const onDownloadCellClick = async (row: AdjustmentDataItem, targetUrl: string) => {
  try {
    const { fileName, fileUri, processingStatus, sk, pk } = row;

    if (processingStatus === 'Success' && fileUri) {
      const skParser = sk.split('-');
      const pkParser = pk.split('-');
      const [planType, bulkAdjustmentId, bulkAdjustmentStage] = skParser;
      const [beaconClientId, retailerId] = pkParser;
      const fileUrl = `https://${targetUrl}/api/beaconbulkadjustment/DownloadBulkAdjustmentFile?beaconClientId=${beaconClientId}&retailerId=${retailerId}&planType=${planType}&bulkAdjustmentId=${bulkAdjustmentId}&bulkAdjustmentStage=${bulkAdjustmentStage}`;
      const tempRes = await axios.post(fileUrl, {});

      const { url: s3URL } = tempRes.data;
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = s3URL;

      anchor.download = fileName;
      anchor.click();
    }
  } catch (error) {
    console.error('Error:: Downloading adjustment file - ', error);
    const { sk, pk } = row;
    trackMixpanelEvent({
      eventName: 'error downloading adjustment template export file',
      data: {
        sk,
        pk
      }
    });
  }
};

const DownloadCell = ({ value, row }: { value: string; row: AdjustmentDataItem }) => {
  const { targetUrl } = useAppSelector((state) => state.app);
  const theme = useStacklineTheme();
  const isSuccessful = value === 'Success';
  const { numberOfDownloads, fileUri } = row;
  const hasFileUri = Boolean(fileUri);
  const [shouldShowNotifications, setShouldShowNotifications] = useState(false);

  useEffect(() => {
    const downloaded = numberOfDownloads !== 0;
    if (!downloaded && isSuccessful) {
      setShouldShowNotifications(true);
    }
  }, [isSuccessful, numberOfDownloads]);

  const handleDownloadButtonClick = () => {
    setShouldShowNotifications(false);
    onDownloadCellClick(row, targetUrl);
  };

  if (!isSuccessful || hasFileUri) {
    return (
      <Flex style={{ alignItems: 'center' }}>
        <a style={{ textDecoration: isSuccessful ? '' : 'none' }}>
          <div onClick={() => handleDownloadButtonClick()} role={isSuccessful ? 'button' : 'cell'}>
            <Text variant="body2">{AdjustmentStatusDisplayName[value]}</Text>
          </div>
        </a>
        <div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: theme.colors.primary,
            marginLeft: '8px',
            display: shouldShowNotifications ? 'block' : 'none'
          }}
        ></div>
      </Flex>
    );
  }

  return null;
};

const downloadAdjustmentColumnDefinition = [
  {
    field: 'sk',
    headerName: 'FILE NAME',
    tableColumnProps: {
      style: {
        width: '530px'
      }
    },
    valueFormatter: (value: string) => {
      const fileNameWithoutExtension = value.replace('.csv', '');
      return (
        <Flex paddingLeft="8px">
          <Text variant="body2">{fileNameWithoutExtension.substring('.csv')}</Text>
        </Flex>
      );
    }
  },
  {
    field: 'requestedDate',
    headerName: 'DATE',
    tableColumnProps: {
      style: {
        width: '221px'
      }
    },
    valueFormatter: (value) => {
      return (
        <Flex>
          <div>
            <Text variant="body2">{formatISODate(value, 'M/D/YY')}</Text>
          </div>
        </Flex>
      );
    }
  },
  {
    field: 'processingStatus',
    headerName: 'STATUS',
    valueFormatter: (value: string, row: AdjustmentDataItem) => {
      return <DownloadCell row={row} value={value} />;
    }
  }
];

const ADJUSTMENT_DOWNLOADS_QUERY_KEY = 'BULK_ADJUSTMENT_DOWNLOADS';

/**
 * In settings page, there is a download menu, inside of the menu we have tab - "Export"  and"Adjustments"
 * This table is for the adjustment download
 */
export const AdjustmentDownloadTable = () => {
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState<number | null>(null);
  const [pageToToken, setPageToToken] = useState<Record<number, string | null>>({ 1: null });
  const queryClient = useQueryClient();
  const COL_DEFS = downloadAdjustmentColumnDefinition;

  const userId = useUserId();
  const { fetchAdjustmentsList } = useAdjustmentDownloadServerProxy();

  useEffect(() => {
    return () => {
      // We will lose the pagination tokens so clear the cache on unmount
      queryClient.removeQueries([ADJUSTMENT_DOWNLOADS_QUERY_KEY]);
    };
  }, [queryClient]);

  const { data: adjustmentsTemplateList, isLoading } = useQuery(
    [ADJUSTMENT_DOWNLOADS_QUERY_KEY, pageToToken[page]],
    async () => {
      try {
        const response = await fetchAdjustmentsList(pageToToken[page]);

        const { result: adjustmentList, paginationToken } = response;
        setPageToToken({ ...pageToToken, [page + 1]: paginationToken });
        if (!paginationToken) {
          setMaxPage(page);
        }

        return adjustmentList;
      } catch (error) {
        console.error('Error:: Fetching initial data for ADJUSTMENT DOWNLOAD SETTING for user - ', userId, error);
      }
      return [];
    },
    {
      refetchInterval: 10000, // refetch every 10 seconds
      retry: 0
    }
  );

  const onNextPage = () => {
    if (isLoading) {
      return;
    }

    if (maxPage) {
      setPage(Math.min(page + 1, maxPage));
    } else {
      setPage(page + 1);
    }
  };

  const onPreviousPage = () => {
    setPage(Math.max(page - 1, 1));
  };

  return (
    <>
      <Flex flexDirection="column">
        <EntityTable
          isLoading={isLoading}
          rowData={adjustmentsTemplateList ? [...adjustmentsTemplateList] : []}
          columnDefs={COL_DEFS}
          shouldModifyColumnDefs={false}
          initialPageSize={ADJUSTMENT_DOWNLOAD_PAGE_SIZE}
          showPagination={false}
          skeletonRows={ADJUSTMENT_DOWNLOAD_PAGE_SIZE}
          appTableProps={{
            noDataPlaceholder: (
              <NoDataPlaceHolderTableRow
                title="No adjustments yet"
                description="After downloading adjustments from your Beacon account, visit this page to access them."
              />
            )
          }}
        />
        <Flex justifyContent="flex-end">
          <Pagination
            currentPage={page}
            onClickNext={onNextPage}
            onClickPrevious={onPreviousPage}
            shouldUseInfinitePagination
          />
        </Flex>
      </Flex>
    </>
  );
};

export default AdjustmentDownloadTable;
