import { Masker } from './MaskedInput';
import numeral from 'numeral';

/**
 * Input mask for displaying phone numbers
 */
export const phoneNumberMask: Masker = {
  applyMask(value: string): string {
    const raw = this.extractRawValue(value);

    const pattern = '(___) ___-____';
    let masked = '';
    let rawIndex = 0;

    for (let i = 0; i < pattern.length; i++) {
      if (raw[rawIndex] && pattern[i] === '_') {
        masked += raw[rawIndex];
        rawIndex++;
      } else if (raw[rawIndex]) {
        masked += pattern[i];
      } else {
        break;
      }
    }

    return masked;
  },

  extractRawValue(maskedValue: string): string {
    return maskedValue.replace(/\D/g, '').slice(0, 10);
  }
};

/**
 * @deprecated Use the CurrencyInput component instead
 *
 * Input mask for displaying currency
 */
export const currencyMask: Masker = {
  applyMask(value: string): string {
    const raw = this.extractRawValue(value);
    if (raw === '') {
      return raw;
    }

    let formattedDollarValue = '';
    if (raw.includes('.')) {
      let [, right] = raw.split('.');
      if (right) {
        right = right.slice(-2);
        if (right.length === 2) {
          formattedDollarValue = `${numeral(raw).format('$0,0.00')}`;
        } else {
          formattedDollarValue = `${numeral(raw).format('$0,0.0')}`;
        }
      } else {
        formattedDollarValue = `${numeral(raw).format('$0,0')}.`;
      }
    } else {
      formattedDollarValue = numeral(raw).format('$0,0');
    }

    return formattedDollarValue;
  },

  extractRawValue(maskedValue: string): string {
    // Remove non-digit and non-decimal characters and the dollar sign
    return maskedValue.replace(/[^\d.]/g, '');
  }
};

/**
 * Input mask for formatting integers with proper commas
 */
export const integerMask: Masker = {
  applyMask(value: string): string {
    if (!value) {
      return '';
    }
    const raw = this.extractRawValue(value);

    return numeral(raw).format('0,0');
  },

  extractRawValue(maskedValue: string): string {
    // Remove non-digit characters
    return maskedValue.replace(/\D/g, '');
  }
};

/**
 * Input mask for formatting values with a percentage sign
 */
export const percentageMask: Masker = {
  applyMask(value: string): string {
    if (!value) {
      return '';
    }
    const raw = this.extractRawValue(value);

    return `${raw}%`;
  },

  extractRawValue(maskedValue: string): string {
    return String(maskedValue.replaceAll('%', ''));
  }
};

/**
 * Input mask for numbers from 1 to 5 with up to 1 decimal place
 */
export const oneToFiveWithDecimalMask: Masker = {
  applyMask(value: string): string {
    const raw = this.extractRawValue(value);

    // Validate the input
    const validValue = this.validateInput(raw);

    // If the input is not valid, pop the last char of the input
    if (validValue === null) {
      return raw.slice(0, raw.length - 1);
    }

    return validValue;
  },

  extractRawValue(maskedValue: string): string {
    return maskedValue.replace(/[^\d.]|(\.(?=.*\.))|(?<=\.\d{1})\d+/, '');
  },

  validateInput(rawValue: string): string | null {
    // Parse the raw value to a floating-point number
    const parsedValue = Number(rawValue);

    // Check if the parsed value is within the range [1, 5] and has at most 1 decimal place
    if (!Number.isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= 5) {
      // Format the validated value with up to 1 decimal place
      return rawValue;
    }

    // If the input is not valid, return null
    return null;
  }
};
