import React from 'react';
import {
  IntegrationTab,
  IntegrationsTabsDisplayName
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Integrations/SettingsIntegrationsPageLayout';
import { useAppSelector } from 'src/utils/Hooks';
import { Text, useStacklineTheme } from '@stackline/ui';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { formatUnixToTime } from 'src/utils/dateformatting';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { formattedLastSaturday } from 'src/utils/dateUtils';
import { AMAZON_RETAILER_IDS } from 'src/store/modules/retailer/reducers';

interface FetchLastUpdatedTimeParams {
  tabInfo: IntegrationTab;
  retailerId: number;
  targetUrl: string;
}

interface FetchLastUpdatedTimeResponse {
  isLoading: boolean;
  errorMessage?: string;
  uploadedTime?: string;
}

const downloadTemplate = async (apiUrl: string) => {
  const response = await axios.get(apiUrl);
  if (response.data) {
    const a = document.createElement('a');
    a.href = response.data;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      a.remove();
    }, 100);
  }
};

const tabUploadType = (tab: IntegrationTab) => {
  switch (tab) {
    case IntegrationTab.Content:
      return 'master-content-upload';
    case IntegrationTab.Prices:
      return 'map-price-upload';
    case IntegrationTab.RetailerUpload:
      return 'multi-retailer-upload';
    case IntegrationTab.COGS:
      return 'brand-cogs-upload';
    default:
      return 'master-content-upload';
  }
};

export const fetchLastUpdatedTime = async ({
  tabInfo,
  retailerId,
  targetUrl
}: FetchLastUpdatedTimeParams): Promise<FetchLastUpdatedTimeResponse> => {
  const url = `https://${targetUrl}/api/account/GetLastUploadTime`;
  const uploadType = tabUploadType(tabInfo);
  const params = { retailer: retailerId, uploadType };

  try {
    const res = await axios.get(url, { params });

    if (res.status === 200) {
      return { isLoading: false, uploadedTime: formatUnixToTime(res.data.lastUploadTime, 'DD MMM YYYY hh:mm A') };
    }
  } catch (e) {
    return { isLoading: false, errorMessage: e.response.data };
  }

  return { isLoading: false };
};

export const downloadCurrentIntegration = async ({
  tabInfo,
  retailerId,
  targetUrl
}: FetchLastUpdatedTimeParams): Promise<{ errorMessage: string }> => {
  const url = `https://${targetUrl}/api/account/DownloadLatestUploadedFile`;
  const uploadType = tabUploadType(tabInfo);
  const params = { retailer: retailerId, uploadType };
  try {
    const res = await axios.get(url, { params });
    if (res.data) {
      const a = document.createElement('a');
      a.href = res.data;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        a.remove();
      }, 100);
    }

    return { errorMessage: '' };
  } catch (e) {
    return { errorMessage: e.response.data };
  }
};

export const useSettingsIntegrationsConfig = (tab: IntegrationTab) => {
  const theme = useStacklineTheme();
  const { targetUrl, queryParams } = useAppSelector((state) => state.app);
  const { searchParams } = queryParams;
  const { mainEntity } = useAppSelector((state) => state.entityService);
  const lastSaturday = formattedLastSaturday();

  const { id: currentRetailerId, availableRetailers } = useAppSelector((state) => state.retailer);
  const {
    config: { allRetailerIds }
  } = useAppSelector((state) => state.user);

  const retailersData = availableRetailers.filter((retailer) => allRetailerIds.includes(Number(retailer.id)));
  const retailersOption = retailersData.map((datum) => {
    return { id: datum.id, name: datum.name, label: datum.displayName };
  });
  const defaultRetailer =
    retailersOption.find((retailer) => retailer.id === String(currentRetailerId)) || retailersOption[0];

  const amazonRetailersOption = retailersOption.filter((retailer) => AMAZON_RETAILER_IDS.includes(String(retailer.id)));
  const defaultAmazonRetailer =
    amazonRetailersOption.find((retailer) => retailer.id === String(currentRetailerId)) || amazonRetailersOption[0];
  if (!mainEntity || !retailersOption) {
    return { isInitialLoading: true };
  }

  const { hashId } = mainEntity;

  switch (tab) {
    case IntegrationTab.Content:
      return {
        displayName: IntegrationsTabsDisplayName.content,
        description: (
          <Text variant="body2">
            Upload content for products in your catalog. After successfully uploading, you can visit the{' '}
            <Link to={`/client/${hashId}${searchParams}&tab=content&subtab=contentAccuracy`}>
              <span style={{ fontWeight: 500, fontSize: '14px', textDecoration: 'underline' }}>Content Accuracy </span>
            </Link>
            <br />
            page to measure how this compares to the live content on the retailer’s website.
          </Text>
        ),
        defaultStatusUploadName: 'master content',
        isInitialLoading: false,
        downloadTemplateFunction: (apiUrl) => downloadTemplate(apiUrl),
        downloadCurrentIntegrationFunction: ({ tabInfo, retailerId }) =>
          downloadCurrentIntegration({ tabInfo, retailerId, targetUrl }),
        templateUrl: `https://${targetUrl}/api/account/DownloadUploadTemplate?templateType=master-content-upload`,
        lastUpdatedUrl: `https://${targetUrl}/api/account/GetLastUploadTime`,
        updateLatestAPIUrl: `https://${targetUrl}/api/account/GetLatestUserUploadRequests?uploadType=map-price-upload`,
        fileUploadUrl: `https://${targetUrl}/api/account/UserUpload?uploadType=master-content-upload`,
        currentIntegrationUrl: `https://${targetUrl}/api/account/DownloadLatestUploadedFile`,
        fetchLastUpdatedFunction: ({ tabInfo, retailerId }) => fetchLastUpdatedTime({ tabInfo, retailerId, targetUrl }),
        retailersOption,
        defaultRetailer
      };
    case IntegrationTab.Prices:
      return {
        displayName: IntegrationsTabsDisplayName.mapprice,
        description: (
          <Text variant="body2">
            Upload MAP Prices for products in your catalog. After successfully uploading, you can visit the{' '}
            <Link to={`/client/${hashId}${searchParams}&tab=sales&subtab=mapPrice`}>
              <span style={{ fontWeight: 500, fontSize: '14px', textDecoration: 'underline' }}>MAP Violations </span>
            </Link>
            <br />
            page to identify violations.
          </Text>
        ),
        defaultStatusUploadName: 'MAP prices',
        isInitialLoading: false,
        downloadTemplateFunction: (apiUrl) => downloadTemplate(apiUrl),
        downloadCurrentIntegrationFunction: ({ tabInfo, retailerId }) =>
          downloadCurrentIntegration({ tabInfo, retailerId, targetUrl }),
        templateUrl: `https://${targetUrl}/api/account/DownloadUploadTemplate?templateType=map-price-upload`,
        lastUpdatedUrl: `https://${targetUrl}/api/account//GetLastUploadTime?uploadType=map-price-upload`,
        updateLatestAPIUrl: `https://${targetUrl}/api/account/GetLatestUserUploadRequests?uploadType=map-price-upload`,
        fileUploadUrl: `https://${targetUrl}/api/account/UserUpload?uploadType=map-price-upload`,
        currentIntegrationUrl: `https://${targetUrl}/api/account/DownloadLatestUploadedFile`,
        fetchLastUpdatedFunction: ({ tabInfo, retailerId }) => fetchLastUpdatedTime({ tabInfo, retailerId, targetUrl }),
        retailersOption,
        defaultRetailer
      };

    case IntegrationTab.RetailerUpload:
      return {
        displayName: IntegrationsTabsDisplayName.retailerUpload,
        description: (
          <Flex flexDirection="column">
            <Flex marginTop={theme.spacing.sm}>
              <Text variant="body2">
                Upload retail sales and other metrics from your retailers, to track the performance of your entire
                e-commerce channel. When you finish <br />
                populating the template with the new data set for the week ending
                <span style={{ fontWeight: 500, fontSize: '14px' }}> {lastSaturday}</span>, you can then upload the
                completed file. Reach out to
                <br />
                <a
                  style={{ fontWeight: 500, fontSize: '14px', textDecoration: 'underline' }}
                  href="mailto:support@stackline.com?subject=Question About Multi Retailer Upload"
                >
                  support@stackline.com
                </a>{' '}
                for any questions.
              </Text>
            </Flex>
          </Flex>
        ),
        defaultStatusUploadName: 'retailer upload file',
        customTemplateDescription: 'Download a blank template to populate retailer metrics.',
        isInitialLoading: false,
        downloadTemplateFunction: (apiUrl) => downloadTemplate(apiUrl),
        downloadCurrentIntegrationFunction: ({ tabInfo, retailerId }) =>
          downloadCurrentIntegration({ tabInfo, retailerId, targetUrl }),
        templateUrl: `https://${targetUrl}/api/account/DownloadUploadTemplate?templateType=multi-retailer-upload`,
        lastUpdatedUrl: `https://${targetUrl}/api/account//GetLastUploadTime?uploadType=multi-retailer-upload`,
        updateLatestAPIUrl: `https://${targetUrl}/api/account/GetLatestUserUploadRequests?uploadType=multi-retailer-upload`,
        fileUploadUrl: `https://${targetUrl}/api/account/UserUpload?uploadType=multi-retailer-upload`,
        currentIntegrationUrl: '',
        fetchLastUpdatedFunction: ({ tabInfo, retailerId }) => fetchLastUpdatedTime({ tabInfo, retailerId, targetUrl }),
        retailersOption,
        defaultRetailer
      };

    case IntegrationTab.COGS:
      return {
        displayName: IntegrationsTabsDisplayName.cogs,
        description: (
          <Text variant="body2">
            Upload your Brand COGS for products in your catalog to better optimize your advertising campaigns.
          </Text>
        ),
        defaultStatusUploadName: 'brand COGs',
        isInitialLoading: false,
        downloadTemplateFunction: (apiUrl) => downloadTemplate(apiUrl),
        downloadCurrentIntegrationFunction: ({ tabInfo, retailerId }) =>
          downloadCurrentIntegration({ tabInfo, retailerId, targetUrl }),
        templateUrl: `https://${targetUrl}/api/account/DownloadUploadTemplate?templateType=brand-cogs-upload`,
        lastUpdatedUrl: `https://${targetUrl}/api/account//GetLastUploadTime?uploadType=brand-cogs-upload`,
        updateLatestAPIUrl: `https://${targetUrl}/api/account/GetLatestUserUploadRequests?uploadType=brand-cogs-upload`,
        fileUploadUrl: `https://${targetUrl}/api/account/UserUpload?uploadType=brand-cogs-upload`,
        currentIntegrationUrl: `https://${targetUrl}/api/account/DownloadLatestUploadedFile`,
        fetchLastUpdatedFunction: ({ tabInfo, retailerId }) => fetchLastUpdatedTime({ tabInfo, retailerId, targetUrl }),
        retailersOption,
        defaultRetailer
      };

    default:
      // this falls into the API tab
      return {
        displayName: IntegrationsTabsDisplayName.api,
        customTitleName: 'Authorize Amazon Seller Accounts',
        description: <Text variant="body2">Please select an Amazon retailer from the following menu:</Text>,
        isInitialLoading: false,
        retailersOption: amazonRetailersOption,
        defaultRetailer: defaultAmazonRetailer,
        targetUrl
      };
  }
};
