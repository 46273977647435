import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Truncate from 'react-truncate';
import Chip from '@mui/material/Chip';
import _pick from 'lodash/pick';

import { getClientImageUrl } from 'src/utils/image';

class CompanySidebarCard extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  };

  state = {};

  handleImageError = () => {
    this.setState({ logoNotAvailable: true });
  };

  showLogo(vendor, name, logoNotAvailable) {
    // We need to cover some companies that does not have BeaconID
    const {
      user: { session, config }
    } = this.props;
    if (vendor && vendor.BeaconClientId === -1) {
      // We only store companyId to localStorage if the user is Stackline user.
      // we store companyId in localStorage so, we can access the companyID in "Beacon client login manager extension"

      if (config.isStacklineUser) {
        localStorage.setItem('companyId', session.companyId);
        localStorage.setItem('companyName', config.vendor.CompanyName);
      }
    }

    if (logoNotAvailable) {
      const shortName = name ? name.slice(0, 2) : '';
      return (
        <div className="segment__short-name-container" style={{ backgroundColor: '#468AF6' }}>
          <div className="segment__short-name">{shortName}</div>
        </div>
      );
    }

    return (
      <div className="brand__image-container sl-image-container">
        <div className="brand__image-content sl-image-center">
          <img
            className="sl-image"
            alt="logo"
            // If the client is not part of Beacon, render image via companyId
            src={
              vendor && vendor.BeaconClientId !== -1
                ? getClientImageUrl(vendor && vendor.BeaconClientId)
                : getClientImageUrl(vendor && session.companyId)
            }
            onError={this.handleImageError}
          />
        </div>
      </div>
    );
  }

  render() {
    const { app, user } = this.props;
    const { searchParams } = app.queryParams;
    const { logoNotAvailable } = this.state;
    const { vendor } = user.config;
    const { tab, subtab } = app.defaultQueryParams;

    return (
      <div className="nav-header-container">
        <div className="sl-image-container">
          <div className="sl-image-center">
            <NavLink to={`${searchParams}&tab=${tab}&subtab=${subtab}`}>
              {this.showLogo(vendor, vendor.CompanyName, logoNotAvailable)}
            </NavLink>
          </div>
        </div>
        <div className="nav-name-container" title={vendor.CompanyName}>
          <Truncate lines={2} ellipsis="...">
            <h4 className="nav-company-name">{vendor.CompanyName}</h4>
          </Truncate>
        </div>
        <Chip className="entity-chip" label="Organization" />
      </div>
    );
  }
}

const mapStateToProps = (state) => _pick(state, ['app', 'user']);

const EnhancedCompanySidebarCard = withRouter(connect(mapStateToProps, null)(CompanySidebarCard));

export default EnhancedCompanySidebarCard;
