/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { withBus } from 'react-bus';
import _get from 'lodash/get';
import { store } from 'src/main';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import { setUploadedTargetKeywords } from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { EventBus } from 'src/types/utils';
import BulkUploadDialog from 'src/components/Search/AdvancedSearch/BulkUpload/BulkUploadDialog';
import { prop } from 'src/utils/fp';

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    marginTop: 30,
    marginBottom: 20
  }
};

export const ClearFilter: React.FC<{ context: any }> = ({ context }) => {
  return (
    <div style={styles.root}>
      <LargeMuiButton secondary style={{ marginTop: 14 }} onClick={context.clearAllFilters}>
        Clear Filters
      </LargeMuiButton>
    </div>
  );
};

const TargetBulkUpload: React.FC<{ context: any; eventBus: EventBus }> = ({ context, eventBus }) => {
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);

  const entityDefinitionType = _get(store.getState(), ['adCampaignBuilder', 'target', 'targetingTypeId'], null);

  return (
    <div style={styles.root}>
      <LargeMuiButton secondary style={{ marginTop: 14 }} onClick={() => setBulkUploadOpen(true)}>
        Bulk Upload
      </LargeMuiButton>
      <LargeMuiButton secondary style={{ marginTop: 14 }} onClick={context.clearAllFilters}>
        Clear Filters
      </LargeMuiButton>

      {bulkUploadOpen ? (
        <BulkUploadDialog
          open={bulkUploadOpen}
          onClose={() => setBulkUploadOpen(false)}
          uploadType={entityDefinitionType === 'productTargeting' ? 'segment' : 'searchtermlist'}
          handleTermFilters={({ values: keywordItems }: { values: { i: string }[] }) => {
            const keywords = keywordItems.map(prop('i'));
            store.dispatch(setUploadedTargetKeywords(keywords));
            setBulkUploadOpen(false);

            // This event is handled in `TargetEntitiesGrid`, where it will kick off a new chain of requests to fetch
            // target keywords and metrics for all target keywords including bulk uploaded keywords set above.
            eventBus.emit('fetchTargetEntities', null);
          }}
        />
      ) : null}
    </div>
  );
};

export default withBus('eventBus')(TargetBulkUpload);
