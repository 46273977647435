import _get from 'lodash/get';

const parseChargeBackStatuses = (response: any) => {
  const data = _get(response, ['data', '0', 'aggregations', 'by_status'], []).map((type: { fieldId: string }) => {
    const { fieldId } = type;
    return {
      name: fieldId,
      displayName: fieldId,
      id: fieldId
    };
  });

  return data;
};

export default parseChargeBackStatuses;
