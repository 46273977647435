import React from 'react';
import ProductListContainer from 'src/components/BeaconRedesignComponents/MetricList/ProductListContainer';
import { Field } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy';
import { ColDef } from 'ag-grid-community';
import MetricCell from 'src/components/BeaconRedesignComponents/MetricCell/MetricCell';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import ProductCell from 'src/components/BeaconRedesignComponents/ProductCell/ProductCell';
import { calculatePercentChange } from 'src/utils/app';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';

const fields: Field[] = [
  {
    name: 'retailSales'
  },
  {
    name: 'unitsSold'
  }
];

/**
 * Shows the units sold by product for the sales page
 */
const BeaconProWaterfallUnitsSoldTable = () => {
  const columnDefinitions: ColDef[] = [
    {
      headerName: 'Products',
      valueFormatter: (_, row) => <ProductCell stacklineSku={row.stacklineSku} title={row.name} />
    },
    {
      headerName: 'Retail Sales (Current)',
      valueFormatter: (_, row) => <MetricCell value={row.retailSales.value} metricType={METRICTYPE.MONEY} />
    },
    {
      headerName: 'Retail Sales (Prior)',
      valueFormatter: (_, row) => (
        <MetricCell value={row.retailSales.value - row.retailSales.difference} metricType={METRICTYPE.MONEY} />
      )
    },
    {
      headerName: 'Retail Sales (Change)',
      valueFormatter: (_, row) => (
        <MetricCell
          value={row.retailSales.difference}
          metricType={METRICTYPE.MONEY}
          percentChange={calculatePercentChange(
            row.retailSales.value,
            row.retailSales.value - row.retailSales.difference
          )}
        />
      )
    },
    {
      headerName: 'Units Sold (Current)',
      valueFormatter: (_, row) => <MetricCell value={row.unitsSold.value} metricType={METRICTYPE.VOLUME} />
    },
    {
      headerName: 'Units Sold (Prior)',
      valueFormatter: (_, row) => (
        <MetricCell value={row.unitsSold.value - row.unitsSold.difference} metricType={METRICTYPE.VOLUME} />
      )
    },
    {
      headerName: 'Units Sold (Change)',
      valueFormatter: (_, row) => (
        <MetricCell
          value={row.unitsSold.difference}
          metricType={METRICTYPE.VOLUME}
          percentChange={calculatePercentChange(row.unitsSold.value, row.unitsSold.value - row.unitsSold.difference)}
        />
      )
    }
  ];

  return (
    <ProductListContainer
      entityTableHeaderProps={{
        title: 'Units Sold (Prior)'
      }}
      indexName={BEACON_INDEX_NAMES.SALES}
      sortFieldName="unitsSold"
      fields={fields}
      columnDefinitions={columnDefinitions}
    />
  );
};

export default BeaconProWaterfallUnitsSoldTable;
