import React from 'react';
import { StacklineSizing, useStacklineTheme } from '@stackline/ui';

// eslint-disable-next-line import/no-unresolved
import { Property } from 'csstype';

export type SlRowHorizontalPosition = 'space-between' | 'start' | 'end' | 'center';

export type SlRowVerticalPosition = 'start' | 'end' | 'center';

export interface SlRowProps {
  children: React.ReactNode;
  /**
   * Amount of space between each item
   */
  spacing?: StacklineSizing | number;
  /**
   * Padding on left and right
   */
  horizontalInset?: StacklineSizing;
  /**
   * Padding on top and bottom
   */
  verticalInset?: StacklineSizing;
  /**
   * Horizontal position of all items
   */
  horizontalPosition?: SlRowHorizontalPosition;
  /**
   * Vertical position of all items
   */
  verticalPosition?: SlRowVerticalPosition;

  style?: React.CSSProperties;
}

const horizontalPositionToFlex: { [key in SlRowHorizontalPosition]: Property.JustifyContent } = {
  'space-between': 'space-between',
  center: 'center',
  end: 'flex-end',
  start: 'flex-start'
};

const verticalPositionToFlex: { [key in SlRowVerticalPosition]: Property.AlignItems } = {
  center: 'center',
  end: 'flex-end',
  start: 'flex-start'
};

/**
 * Common component for positioning items horizontally
 */
export const SlRow = ({
  children,
  spacing = 'none',
  horizontalInset = 'none',
  verticalInset = 'none',
  horizontalPosition = 'start',
  verticalPosition = 'start',
  style
}: SlRowProps) => {
  const theme = useStacklineTheme();

  const getMarginLeft = (index: number): number | string => {
    if (index > 0) {
      if (typeof spacing === 'string') {
        return theme.spacing[spacing];
      }

      return spacing || 0;
    }

    return 0;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        paddingTop: theme.spacing[verticalInset],
        paddingBottom: theme.spacing[verticalInset],
        paddingLeft: theme.spacing[horizontalInset],
        paddingRight: theme.spacing[horizontalInset],
        justifyContent: horizontalPositionToFlex[horizontalPosition],
        alignItems: verticalPositionToFlex[verticalPosition],
        ...style
      }}
    >
      {React.Children.toArray(children)
        .filter((child) => child !== undefined && child !== null)
        .map((child, index) => (
          // eslint-disable-next-line react/jsx-key
          <div
            style={{
              marginLeft: getMarginLeft(index)
            }}
          >
            {child}
          </div>
        ))}
    </div>
  );
};
