import { Option } from 'funfix-core';
import { createSelector, OutputSelector } from 'reselect';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _memoize from 'lodash/memoize';
import _orderBy from 'lodash/orderBy';
import numeral from 'numeral';

import ReduxStore from 'src/types/store/reduxStore'; // TODO: Figure out why absolute path doesn't work here
import { propEq, prop, count, pathEq } from 'src/utils/fp';
import {
  IAdPlatformSettingsByClient,
  AD_PLATFORM_SETTING_TYPE_BY_CLIENT,
  IAdPlatformSettings,
  AD_PLATFORM_SETTING_TYPE,
  IAdPortfolioAdPlatformSettingsByClient,
  IAdPlatform
} from 'sl-ad-campaign-manager-data-model';

export const getSettingMetadata = (settingType: string, settingId: string) => (state: ReduxStore) =>
  state.adPlatformSettings.find((setting) => setting.settingType === settingType && setting.settingId === settingId) ||
  state.adPlatformSettingsByClient.find(
    (setting) => setting.settingType === settingType && setting.settingId === settingId
  ) ||
  null;

export const getAdBusinessUnitMetadata = (businessUnitId: string) => (state: ReduxStore) =>
  state.adBusinessUnits.find(propEq('businessUnitId', businessUnitId));

export const getAdPlatform = (platformId: string) =>
  createSelector<ReduxStore, ReduxStore['adPlatforms'], IAdPlatform | undefined>([prop('adPlatforms')], (adPlatforms) =>
    adPlatforms.find(propEq('id', platformId))
  );

export const getPlatformType = createSelector(
  [prop('adPlatforms'), (state: ReduxStore) => Option.of(state.adCampaignBuilder.platformId)],
  (adPlatforms, platformIdOpt) =>
    platformIdOpt
      .flatMap((platformId) => Option.of(adPlatforms.find(propEq('id', platformId))))
      .map(prop('platformType'))
      .orNull()
);

export const adPlatformSettingsByClientSelector = (state: ReduxStore) => state.adPlatformSettingsByClient;

/**
 * Memoized selector used to retrieve `AdPlatformSettingByClient`s that have a `settingType` and optionally
 * `platformType` that match provided values.  The return type is automatically narrowed to match the type of only
 * settings with specified `settingType` as long as the first argument is provided as a constant (`as const`).
 *
 * Both the higher-order function that creates the selector itself and the return value of the created selector are
 * memoized which has the effect of eliminating duplicate re-renders of inner hooks and child components of components
 * that make use of them.
 *
 * Example:
 *
 * ```ts
 * const mapStateToProps = (state: ReduxStore) => ({
 *  // `adEntities` will automatically receive a type of `AdEntityAdPlatformSettingByClient[]`
 *  adEntities: getAdPlatformSettingsByClientOfType('entityId')(state),
 * // `adPortfolios` will automatically receive a type of `AdPortfolioAdPlatformSettingByClient[]`
 *  adPortfolios: getAdPlatformSettingsByClientOfType('portfolioId')(state),
 * });
 * ```
 */
export const getAdPlatformSettingsByClientOfType: <S extends AD_PLATFORM_SETTING_TYPE_BY_CLIENT>(
  settingType: S,
  platformType?: string | null
) => OutputSelector<
  ReduxStore,
  Extract<IAdPlatformSettingsByClient, { settingType: S }>[],
  (res1: IAdPlatformSettingsByClient[]) => Extract<IAdPlatformSettingsByClient, { settingType: S }>[]
> = _memoize(
  <S extends AD_PLATFORM_SETTING_TYPE_BY_CLIENT>(settingType: S, platformType?: string | null) =>
    createSelector(
      adPlatformSettingsByClientSelector,
      (adPlatformSettingsByClient) =>
        adPlatformSettingsByClient.filter(
          (setting) => setting.settingType === settingType && (!platformType || platformType === setting.platformType)
        ) as Extract<IAdPlatformSettingsByClient, { settingType: S }>[]
    ),
  (settingType: string, platformType?: string | null): string => `${settingType}${platformType || ''}`
);

export const adPlatformSettingsSelector = (state: ReduxStore) => state.adPlatformSettings;

/**
 * Memoized selector used to retrieve `AdPlatformSetting`s that have a `settingType` and optionally `platformType` that
 * match provided values.
 *
 * Both the higher-order function that creates the selector itself and the return value of the created selector are
 * memoized which has the effect of eliminating duplicate re-renders of inner hooks and child components of components
 * that make use of them.
 *
 * Example:
 *
 * ```ts
 * const mapStateToProps = (state: ReduxStore) => ({
 *  campaignTypeSettings: getAdPlatformSettingsOfType(AD_PLATFORM_SETTING_TYPE.CAMPAIGN_TYPE)(state),
 * });
 * ```
 */
export const getAdPlatformSettingsOfType: (
  settingType: AD_PLATFORM_SETTING_TYPE,
  platformType?: string | null
) => OutputSelector<ReduxStore, IAdPlatformSettings[], (res1: IAdPlatformSettings[]) => IAdPlatformSettings[]> =
  _memoize(
    (settingType: string, platformType?: string | null) =>
      createSelector(adPlatformSettingsSelector, (adPlatformSettings) =>
        adPlatformSettings.filter(
          (setting) => setting.settingType === settingType && (!platformType || platformType === setting.platformType)
        )
      ),
    (settingType: string, platformType?: string | null): string => `${settingType}${platformType || ''}`
  );

/**
 * Memoized selector that returns a list of all `AdPortfolioAdPlatformSettingByClient` items that have the provided
 * automation `strategyId` and are in the entity with the provided `entityId`.
 */
export const getAdCampaignsOfStrategyId: (
  strategyId: string,
  entityId: string
) => OutputSelector<
  ReduxStore,
  IAdPortfolioAdPlatformSettingsByClient[],
  (res1: IAdPortfolioAdPlatformSettingsByClient[]) => IAdPortfolioAdPlatformSettingsByClient[]
> = _memoize(
  (strategyId: string, entityId: string) =>
    createSelector(
      getAdPlatformSettingsByClientOfType(AD_PLATFORM_SETTING_TYPE_BY_CLIENT.PORTFOLIO_ID),
      (adPortfolioAdPlatformSettingsByClient) =>
        adPortfolioAdPlatformSettingsByClient.filter(
          (adPortfolioSetting) =>
            _get(adPortfolioSetting, ['extendedAttributes', 'automationAttributes', 'strategyId'], 'Manual') ===
              strategyId && adPortfolioSetting.extendedAttributes.entityId === entityId
        )
    ),
  (strategyId: string, entityId: string): string => `${strategyId}${entityId}`
);

/**
 * Selector that returns the currently selected entity in the current ad builder flow
 */
export const getCampaignEntity = createSelector(
  (state: ReduxStore) => Option.of(state.adCampaignBuilder.platformSettings),
  (platformSettingsOpt) => platformSettingsOpt.flatMap(({ entity }) => Option.of(entity)).orNull()
);

export const sortedAdEntitiesWithCampaignCountSelector = createSelector(
  [
    getPlatformType,
    prop('adCampaigns'),
    getAdPlatformSettingsByClientOfType(AD_PLATFORM_SETTING_TYPE_BY_CLIENT.ENTITY_ID),
    getAdPlatformSettingsByClientOfType(AD_PLATFORM_SETTING_TYPE_BY_CLIENT.PORTFOLIO_ID)
  ],
  (platformType, adCampaigns, adEntities, adPortfolios) => {
    if (_isEmpty(adEntities)) {
      return [];
    }

    const adEntitiesWithCount = adEntities.filter(propEq('platformType', platformType)).map((adEntity) => {
      const campaignCountForEntity = count(
        pathEq(['extendedAttributes', 'entityId'], adEntity.extendedAttributes.entityId),
        adCampaigns
      );

      const portfolioCountForEntity = count(
        pathEq(['extendedAttributes', 'entityId'], adEntity.extendedAttributes.entityId),
        adPortfolios
      );

      const formattedPortfolioCount = numeral(portfolioCountForEntity).format('√');
      const formattedCampaignCount = numeral(campaignCountForEntity).format('√');

      return {
        id: adEntity.id,
        value: adEntity.id,
        // Show the number of campaigns in the entitiy in the title
        displayName: `${adEntity.name} (${formattedPortfolioCount} Portfolios) (${formattedCampaignCount} Campaigns)`,
        campaignCount: campaignCountForEntity,
        portfolioCount: portfolioCountForEntity
      };
    });

    // const filterAdEntity = adEntitiesWithCount.filter((adEntity) => adEntity.portfolioCount > 0);

    // Display entities from most to least campaigns
    return _orderBy(adEntitiesWithCount, ['campaignCount'], ['desc']);
  }
);
