import React from 'react';
import colors from 'src/utils/colors';
import makeStyles from '@mui/styles/makeStyles';

const usePlaceholderStyles = makeStyles(() => ({
  placeholder: {
    color: colors.labelGrey,
    opacity: 0.8
  }
}));

const Placeholder = ({ text }: { text: any }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{text}</div>;
};

export default Placeholder;
