import { ThunkDispatch } from 'redux-thunk';

import Creators from './actions';
import ReduxStore from 'src/types/store/reduxStore';

export const { setRetailer, setRetailerPlatformDetails } = Creators;

export const updateRetailer =
  (id: string | number, parentPlatform: string) => (dispatch: ThunkDispatch<ReduxStore, void, any>) =>
    Promise.resolve(dispatch(setRetailer(id, parentPlatform)));
