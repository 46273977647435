import types, { IScheduledActionsGroup } from './types';
// ------------------------------------
// Action Creators - they simply return an action
// An action being a payload of information that send data from your application to your store.
// ------------------------------------

const receiveScheduledActionGroups = (scheduledActionGroups: IScheduledActionsGroup[]) => ({
  type: types.RECEIVE_SCHEDULED_ACTION_GROUPS,
  scheduledActionGroups,
  receivedAt: Date.now()
});

const clearScheduledActionGroups = () => ({ type: types.CLEAR_SCHEDULED_ACTION_GROUPS });

export default {
  clearScheduledActionGroups,
  receiveScheduledActionGroups
};
