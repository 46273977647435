import React, { useEffect, useCallback, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Widget } from 'src/types/application/widgetTypes';
import { useDispatch, useSelector } from 'react-redux';
import ShareOfShelfContainer from 'src/components/Omni/OmniShareOfShelf/ShareOfShelfContainer';
import { omniShareOfShelfShardsServiceOperation } from 'src/store/modules/omni/omniShareOfShelfShardsService';
import ReduxStore from 'src/types/store/reduxStore';
import { processShardsData } from 'src/components/Omni/OmniShareOfShelf/shareOfShelfTableUtil';
import { EventBus } from 'src/types/utils';
import { withBus } from 'react-bus';
import { OmniShareOfShelfShardsBody } from 'src/store/modules/omni/omniShareOfShelfShardsService/operations';
import { addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';

const { fetchOmniShareOfShelfShardsData } = omniShareOfShelfShardsServiceOperation;

interface ShareOfShelfTableProps extends RouteComponentProps {
  widget: Widget;
  eventBus: EventBus;
}

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingBottom: '100px'
  }
};

const ShareOfShelfTable: React.FC<ShareOfShelfTableProps> = ({
  widget,
  eventBus,
  location
}: ShareOfShelfTableProps) => {
  const { name } = widget;
  const { shareOfShelfType } = widget.data;
  const [topSearchResults, setTopSearchResults] = useState('all');
  const dispatch = useDispatch();
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const shardsData = useSelector((state: ReduxStore) => state.omniShareOfShelfShardsService)[name];
  const omniBrandsFollowing = useSelector((state: ReduxStore) => state.omniBrandsFollowing);
  const filters = useSelector((state: ReduxStore) => state.filters);
  const { mainEntity } = useSelector((state: ReduxStore) => state.entityService);
  const { startWeek, endWeek } = mainTimePeriod;
  const handleReceiveInfoFromShareOfShelf = ({ displayName }: { displayName: string }) => {
    if (displayName && (displayName.includes('3') || displayName.includes('5') || displayName.includes('10'))) {
      const num = displayName.match(/\d+/)[0];
      setTopSearchResults(`top${num}`);
    } else {
      setTopSearchResults('all');
    }
  };
  useEffect(() => {
    eventBus.on('noticeShareOfShelveMainMetricFields', handleReceiveInfoFromShareOfShelf);
    return () => {
      eventBus.off('noticeShareOfShelveMainMetricFields', handleReceiveInfoFromShareOfShelf);
    };
  }, [eventBus]);

  const fetchData = useCallback(() => {
    if (name && shareOfShelfType) {
      let baseReqBody: OmniShareOfShelfShardsBody = {
        startWeekId: startWeek,
        endWeekId: endWeek,
        shareOfShelfType,
        includeCategoryIds: [],
        includeLocationCountryCode: [],
        includeLocationRegionCode: [],
        retailerIds: [],
        includeSubCategoryIds: []
      };
      if (topSearchResults !== 'all') {
        baseReqBody.topSearchResults = topSearchResults;
      }
      const { pathname } = location;
      baseReqBody = addFilterToOmniBaseReqBody(baseReqBody, filters, pathname, mainEntity.query);

      dispatch(fetchOmniShareOfShelfShardsData(baseReqBody, name));
    }
  }, [startWeek, endWeek, dispatch, name, shareOfShelfType, filters, location, topSearchResults]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // TODO: choose a loading here
  if (!shardsData || shardsData.isFetching) {
    return null;
  }
  const { data } = shardsData;

  const processData = processShardsData(data, omniBrandsFollowing);

  return (
    <div style={styles.root}>
      {processData.map((retailerData) => {
        const { retailerId, brandData } = retailerData;

        return <ShareOfShelfContainer key={retailerId} data={brandData} retailerId={retailerId} />;
      })}
    </div>
  );
};

export default withRouter(withBus('eventBus')(ShareOfShelfTable));
