import React from 'react';
import { AppTable } from '@stackline/ui';
import _get from 'lodash/get';
import { makeStyles } from '@mui/styles';
import { formatToDollar } from 'src/utils/money';
import { formatDateForDisputeTable } from 'src/utils/dateUtils';

const useStyles = makeStyles(() => ({
  upperStyleOverrides: {
    '& .MuiTableCell-head': {
      textTransform: 'none',
      fontSize: '18px',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: '#052849',
      borderTop: '1px solid #e8e8ed',
      paddingTop: '35px'
    },
    '& .MuiTableRow-root': {
      '& td': {
        paddingBottom: '35px'
      },
      '& th:first-child': {
        borderTopLeftRadius: '8px',
        borderLeft: '1px solid #e8e8ed',
        paddingLeft: '32px'
      },
      '& th:last-child': {
        borderTopRightRadius: '8px',
        borderRight: '1px solid #e8e8ed',
        paddingRight: '32px'
      }
    },

    '& .MuiTableCell-body': {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.5',
      letterSpacing: 'normal',
      color: '#052849'
    },

    '& .MuiTableBody-root': {
      '& td': {
        borderTop: 'none'
      },
      '& tr:first-child td:first-child': {
        borderTopLeftRadius: '0',
        paddingLeft: '32px'
      },
      '& tr:first-child td:last-child': {
        borderTopRightRadius: '0',
        paddingRight: '32px'
      }
    }
  },
  lowerStyleOverrides: {
    '& .MuiTableCell-head': {
      textTransform: 'none',
      fontSize: '18px',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: '#052849'
    },
    '& .MuiTableCell-body': {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.5',
      letterSpacing: 'normal',
      color: '#052849'
    }
  }
}));

const UPPER_DISPUTE_DETAILS_COLUMNS = [
  {
    field: 'disputeId',
    headerName: 'Dispute ID',
    tableColumnProps: {
      align: 'left',
      padding: 'none',
      color: '#052849',
      style: {
        width: '225px'
      }
    }
  },
  {
    field: 'payeeCode',
    headerName: 'Payee Code',
    tableColumnProps: {
      align: 'left',
      padding: 'none',
      color: '#052849',
      style: {
        width: '145px'
      }
    }
  },
  {
    field: 'purchaseOrder',
    headerName: 'PO #',
    tableColumnProps: {
      align: 'left',
      padding: 'none',
      color: '#052849',
      style: {
        width: '145px'
      }
    }
  },
  {
    field: 'invoiceDate',
    headerName: 'Invoice Date',
    tableColumnProps: {
      align: 'left',
      padding: 'none',
      color: '#052849',
      style: {
        width: '145px'
      }
    }
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    tableColumnProps: {
      align: 'left',
      padding: 'none',
      color: '#052849',
      style: {
        width: '145px'
      }
    }
  },
  {
    field: 'invoiceQuantity',
    headerName: 'Invoiced Qty',
    tableColumnProps: {
      align: 'left',
      padding: 'none',
      color: '#052849',
      style: {
        width: '145px'
      }
    }
  },
  {
    field: 'disputeQuantity',
    headerName: 'Dispute Qty',
    tableColumnProps: {
      align: 'left',
      padding: 'none',
      color: '#052849',
      style: {
        width: '145px'
      }
    }
  },
  {
    field: 'disputeAmount',
    headerName: 'Dispute Amt',
    tableColumnProps: {
      align: 'left',
      padding: 'none',
      color: '#052849',
      style: {
        width: '145px'
      }
    }
  }
];

const LOWER_DISPUTE_DETAILS_COLUMNS = [
  {
    field: 'asin',
    headerName: 'ASIN',
    tableColumnProps: {
      style: {
        paddingLeft: '32px',
        width: '100px',
        height: '85px'
      },
      align: 'left',
      padding: 'none',
      color: '#052849'
    }
  },
  {
    field: 'invoiceQuantity',
    headerName: 'Invoiced Qty',
    tableColumnProps: {
      style: {
        width: '65px',
        paddingLeft: '15px'
      },
      align: 'left',
      padding: 'none',
      color: '#052849'
    }
  },
  {
    field: 'invoiceAmount',
    headerName: 'Invoiced Amt',
    tableColumnProps: {
      style: {
        width: '65px',
        paddingLeft: '15px'
      },
      align: 'left',
      padding: 'none',
      color: '#052849'
    }
  },
  {
    field: 'disputeQuantity',
    headerName: 'Dispute Qty',
    tableColumnProps: {
      style: {
        width: '65px',
        paddingLeft: '15px'
      },
      align: 'left',
      padding: 'none',
      color: '#052849'
    }
  },
  {
    field: 'disputeAmount',
    headerName: 'Dispute Amt',
    tableColumnProps: {
      style: {
        width: '65px',
        paddingLeft: '15px'
      },
      align: 'left',
      padding: 'none',
      color: '#052849'
    }
  },
  {
    field: 'shortageQuantity',
    headerName: 'Shortage Qty',
    tableColumnProps: {
      style: {
        width: '65px',
        paddingLeft: '15px'
      },
      align: 'left',
      padding: 'none',
      color: '#052849'
    }
  },
  {
    field: 'shortageAmount',
    headerName: 'Shortage Amt',
    tableColumnProps: {
      style: {
        width: '65px',
        paddingLeft: '15px'
      },
      align: 'left',
      padding: 'none',
      color: '#052849'
    }
  },
  {
    field: 'unitPrice',
    headerName: 'Unit Price',
    tableColumnProps: {
      style: {
        width: '65px',
        paddingLeft: '15px'
      },
      align: 'left',
      padding: 'none',
      color: '#052849'
    }
  }
];

const generateRowsForAsinTable = (data) => {
  return data.map((asinDataPoint) => {
    return {
      asin: asinDataPoint.retailerSku,
      invoiceQuantity: asinDataPoint.invoiceQuantity,
      invoiceAmount: formatToDollar(asinDataPoint.invoiceAmount),
      disputeQuantity: asinDataPoint.disputeQuantity,
      disputeAmount: formatToDollar(asinDataPoint.disputeAmount),
      shortageQuantity: asinDataPoint.shortageQuantity,
      shortageAmount: formatToDollar(asinDataPoint.shortageAmount),
      unitPrice: formatToDollar(asinDataPoint.invoiceUnitCost)
    };
  });
};

const InitialDisputeDetailsBlock = ({ rawData }: any) => {
  const classes = useStyles();

  let topTableRows = [];
  let asinTableRows = [];
  const quantityData = _get(rawData, 'data[0].aggregations.by_originalInvoiceNumber[0].additionalValues', null);
  const asinData = _get(rawData, 'data[0].documents', null);

  if (quantityData) {
    topTableRows = [
      {
        disputeId: _get(asinData, '[0].disputeId', ''),
        payeeCode: _get(asinData, '[0].payeeCode', ''),
        purchaseOrder: _get(asinData, '[0].purchaseOrderNumber', ''),
        invoiceDate: formatDateForDisputeTable(_get(asinData, '[0].originalInvoiceDate', '')),
        dueDate: formatDateForDisputeTable(_get(asinData, '[0].paymentDueDate', '')),
        invoiceQuantity: _get(quantityData, 'invoiceQuantity_sum_value', ''),
        disputeQuantity: _get(quantityData, 'disputeQuantity_sum_value', ''),
        disputeAmount: formatToDollar(_get(quantityData, 'disputeAmount_sum_value', ''))
      }
    ];
  }
  if (asinData) {
    asinTableRows = generateRowsForAsinTable(asinData);
  }

  return (
    <>
      <AppTable
        className={classes.upperStyleOverrides}
        columns={UPPER_DISPUTE_DETAILS_COLUMNS}
        rows={topTableRows}
        hidePagination
        noDataPlaceholder={<h2>No Data Available</h2>}
        sx={{ marginBottom: '50px' }}
      />
      <AppTable
        className={classes.lowerStyleOverrides}
        columns={LOWER_DISPUTE_DETAILS_COLUMNS}
        rows={asinTableRows}
        hidePagination
        noDataPlaceholder={<h2>No Data Available</h2>}
        sx={{ tableLayout: 'fixed', marginBottom: '100px' }}
      />
    </>
  );
};

export default InitialDisputeDetailsBlock;
