import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import _capitalize from 'lodash/capitalize';
import MenuItem from '@mui/material/MenuItem';
import queryString from 'qs';
import _find from 'lodash/find';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import colors from 'src/utils/colors';
import { addQueryParams } from 'src/utils/browser';
import { comparisonParams, filterParams } from 'src/utils/segments';
import { ChevronIcon, BrandIcon, CategoryIcon, ProductIcon, SegmentIcon, SubCategoryIcon } from '../../SvgIcons';
import Suggestions from '../../Suggestions';
import SubCategories from './SubCategories';
import Categories from './Categories';
import Brands from './Brands';
import Segments from './Segments';
import Product from './Product';
import Entities from './Entities';
import Portfolios from './Portfolios';
import Campaigns from './Campaigns';

const styles = {
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    textAlign: 'center',
    paddingLeft: '26px',
    marginTop: '10px'
  }
};

class Group extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    aggregationConditions: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    subCategories: PropTypes.array.isRequired,
    onSelectionChange: PropTypes.func.isRequired
  };

  static contextTypes = {
    router: PropTypes.object
  };

  componentWillMount() {
    const { filters, app } = this.props;
    let options = [
      {
        icon: CategoryIcon,
        name: 'categories',
        displayName: 'Categories'
      },
      {
        icon: SubCategoryIcon,
        name: 'subCategories',
        displayName: 'Subcategories'
      },
      {
        icon: SegmentIcon,
        name: 'segments',
        displayName: 'Segments'
      },
      {
        icon: BrandIcon,
        name: 'brand',
        displayName: 'Brands'
      },
      {
        icon: ProductIcon,
        name: 'product',
        displayName: 'Products'
      }
    ];
    if (app.name === 'advertising') {
      options = [
        {
          icon: CategoryIcon,
          name: 'entities',
          displayName: 'Entities'
        },
        {
          icon: SubCategoryIcon,
          name: 'portfolios',
          displayName: 'Portfolios'
        },
        {
          icon: SegmentIcon,
          name: 'campaigns',
          displayName: 'Campaigns'
        },
        {
          icon: ProductIcon,
          name: 'product',
          displayName: 'Products'
        }
      ];
    }
    this.setState({
      options,
      selectedOption: filters.category || filters.segment ? options[3].name : options[0].name,
      conditions: this.buildConditions(''),
      open: false,
      anchorEl: null
    });
  }

  buildConditions(keyword) {
    return {
      termFilters: [
        {
          fieldName: 'keyword',
          condition: 'must',
          values: [keyword]
        },
        {
          fieldName: 'categoryId',
          condition: 'should',
          values: this.props.categories.map((val) => val.categoryId)
        }
      ]
    };
  }

  handleInputChange = (event) => {
    this.setState({ searchText: event.target.value });
  };

  handleMenuTitleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    this.setState({ open: true });
  };

  handleSelectedOptionChange = (option) => {
    const { name } = option;
    if (name === 'brand') {
      // show empty search for brands
      this.handleSelectionChange('', -1);
    }

    this.setState({ selectedOption: name });
  };

  handleSelectionChange = (selection, index) => {
    const { retailer, mainTimePeriod, location, onSelectionChange } = this.props;

    if (index === -1) {
      this.setState({ conditions: this.buildConditions(selection) });
      return;
    }

    const existingParams = addQueryParams(location, retailer, mainTimePeriod, [...filterParams, ...comparisonParams]);
    onSelectionChange();
    this.context.router.history.push(`?ctype=${selection.type}&cid=${selection.id}&${existingParams}`);
  };

  showSearch() {
    const { categories, retailer, app } = this.props;
    const { selectedOption } = this.state;
    if (selectedOption === 'product' || selectedOption === 'brand') {
      return (
        <div>
          <Suggestions
            apiUrl={`/api/${app.apiAppName}/AutoCompleteSuggestions?${
              selectedOption === 'product' ? `retailerId=${retailer.id}&` : ''
            }${queryString.stringify({
              categoryId: categories.map((val) => val.id)
            })}&term=`}
            className="compare__search sl-form-input"
            placeholder={`Search for ${selectedOption}s`}
            type={selectedOption}
            onSelectionChange={this.handleSelectionChange}
          />
        </div>
      );
    }
    return (
      <div>
        <TextField
          variant="standard"
          className="compare__search sl-form-input"
          placeholder={`Search for ${selectedOption}`}
          type="text"
          name="keyword"
          id="keyword"
          style={{ height: '56px' }}
          onChange={this.handleInputChange} />
      </div>
    );
  }

  showResults() {
    const { onSelectionChange, aggregationConditions } = this.props;
    const { selectedOption, searchText, conditions } = this.state;
    switch (selectedOption) {
      case 'entities':
        return <Entities searchText={searchText} togglePanel={onSelectionChange} />;
      case 'portfolios':
        return <Portfolios searchText={searchText} togglePanel={onSelectionChange} />;
      case 'campaigns':
        return <Campaigns searchText={searchText} togglePanel={onSelectionChange} />;
      case 'categories':
        return <Categories searchText={searchText} togglePanel={onSelectionChange} />;
      case 'subCategories':
        return <SubCategories searchText={searchText} togglePanel={onSelectionChange} />;
      case 'segments':
        return <Segments searchText={searchText} togglePanel={onSelectionChange} />;
      case 'brand':
        return <Brands conditions={conditions} togglePanel={onSelectionChange} />;
      case 'product':
      default:
        return (
          <Product
            hideSearch
            conditions={conditions}
            aggregationConditions={aggregationConditions}
            togglePanel={onSelectionChange}
          />
        );
    }
  }

  isSelectionDisabled(val) {
    const { filters } = this.props;
    return (filters.category || filters.segment) && (val.name === 'categories' || val.name === 'segments');
  }

  render() {
    const { options, selectedOption } = this.state;

    const selectedOptionMeta = _find(options, { name: selectedOption });
    const CurrentIcon = selectedOptionMeta.icon;
    return (
      <div>
        <div style={styles.root}>
          {this.showSearch()}
          <div>
            <MenuItem className="compare__group-menu-item" onClick={this.handleMenuTitleClick}>
              <>
                <ListItemIcon sx={{ height: '30px', minWidth: '30px' }}>
                  <CurrentIcon style={{ strokeWidth: '13px' }} />
                </ListItemIcon>
                <ListItemText sx={{ paddingLeft: 0 }}>{_capitalize(this.state.selectedOption)}</ListItemText>
                <ListItemIcon sx={{ height: '30px' }}>
                  <ChevronIcon className="sl-header__drop-down-icon" />
                </ListItemIcon>
              </>
            </MenuItem>
            <Menu
              className="compare__drop-down"
              open={this.state.open}
              anchorEl={this.state.anchorEl}
              onClose={() => this.setState({ open: false })}
              value={selectedOption}
              onChange={this.handleSelectedOptionChange}
              underlineStyle={{ borderTop: `1px solid ${colors.lightestGrey}` }}
              selectedMenuItemStyle={{ color: colors.blue, fontWeight: 400 }}
              selectionRenderer={(_value, child) => (
                <span>
                  {child.props.leftIcon} {child.props.primaryText}
                </span>
              )}
              iconButton={<ChevronIcon className="sl-header__drop-down-icon" />}
            >
              {options.map((val) => {
                const Icon = val.icon;
                return (
                  <MenuItem
                    className="compare__group-menu-item"
                    disabled={this.isSelectionDisabled(val)}
                    style={{ opacity: this.isSelectionDisabled(val) ? 0.5 : 1 }}
                    key={val.name}
                    value={val.name}
                    primary={val.displayName}
                    onClick={() => this.handleSelectedOptionChange(val)}
                  >
                    <>
                      <ListItemIcon sx={{ height: '30px', minWidth: '30px' }}>
                        <Icon style={{ strokeWidth: '13px' }} />
                      </ListItemIcon>
                    </>
                    <ListItemText sx={{ paddingLeft: 0 }}>{val.displayName}</ListItemText>
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </div>
        {this.showResults()}
      </div>
    );
  }
}
export default withRouter(connect((state) => state)(Group));
