/* eslint-disable react/prop-types */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import Truncate from 'react-truncate';
import Chip from '@mui/material/Chip';
import { AppName, AdManagerAdCampaignEntity } from 'sl-api-connector/types';
import { Option } from 'funfix-core';

import ReduxStore from 'src/types/store/reduxStore';
import colors from 'src/utils/colors';
import { isPhone } from 'src/utils/browser';
import Card from 'src/components/Card';
import 'src/routes/HomePage/Segments.scss';
import './Cards.scss';

const mapStateToProps = (state: ReduxStore) => _pick(state, ['app', 'entityService', 'retailer']);

type AdCampaignSidebarCardProps = ReturnType<typeof mapStateToProps>;

const CardFront: React.FC<AdCampaignSidebarCardProps> = ({ entityService, app }) => {
  const { searchParams } = app.queryParams;
  if (!entityService.mainEntity) {
    return null;
  }

  const campaignName = _get(entityService.mainEntity, 'campaignName') || entityService.mainEntity.displayName;
  const shortName = campaignName ? campaignName.slice(0, 2) : '';
  const subtab = app.name === 'atlas' ? 'retailSales' : 'keyMetrics';

  return (
    <div>
      <div className="sl-image-container">
        <div className="sl-image-center">
          <NavLink
            style={{ textDecoration: 'none' }}
            to={`${searchParams}&tab=${app.name === AppName.Advertising ? 'adManager' : 'sales'}&subtab=${subtab}`}
          >
            <div className="segment__short-name-container" style={{ backgroundColor: colors.stacklineBlue }}>
              <div className="segment__short-name">{shortName}</div>
            </div>
          </NavLink>
        </div>
      </div>

      <div className="nav-name-container" title={campaignName}>
        <Truncate lines={2} ellipsis="...">
          <h4 className="nav-company-name">{campaignName}</h4>
        </Truncate>
      </div>

      <Chip className="entity-chip" label="Ad Campaign" />
    </div>
  );
};

const CardBack: React.FC<AdCampaignSidebarCardProps> = ({ entityService }) => {
  const campaign = entityService.mainEntity;
  if (!campaign) {
    return null;
  }

  const platformCampaignUrl = Option.of(_get(campaign, 'extendedAttributes'))
    .map(
      ({ campaignType, campaignIdUi, entityIdUi }: AdManagerAdCampaignEntity['extendedAttributes']) =>
        `https://advertising.amazon.com/cm/${
          campaignType === 'sponsoredBrands' ? 'hsa' : 'sp'
        }/campaigns/${campaignIdUi}/ads?entityId=${entityIdUi}`
    )
    .orNull();

  const campaignId = _get(campaign, ['extendedAttributes', 'campaignId']) || campaign.id;
  const campaignName = _get(campaign, 'campaignName') || campaign.displayName;

  return (
    <div className="product-card__info-text" style={{ textAlign: 'left', paddingTop: 20 }}>
      <div className="product-card__small-metric-change">
        <div className="product-card__block-text">
          <p className="product-card__label">
            Campaign Id:
            {platformCampaignUrl ? (
              <a rel="noopener noreferrer" target="_blank" href={platformCampaignUrl}>
                {campaignId}
              </a>
            ) : (
              campaignId
            )}
          </p>
          <p className="product-card__label">Name: {campaignName}</p>
        </div>
      </div>
    </div>
  );
};

const AdCampaignSidebarCard: React.FC<AdCampaignSidebarCardProps> = ({ ...props }) => {
  if (!props.entityService.mainEntity) {
    return null;
  }

  return (
    <div className="nav-header-product-container">
      <Card front={<CardFront {...props} />} back={<CardBack {...props} />} isFlippable={!isPhone()} />
    </div>
  );
};

export default connect(mapStateToProps)(AdCampaignSidebarCard);
