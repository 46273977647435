import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { SectionWrapper, OperationButtons } from './AdCampaignBuilderCommonWidgets';

const AdManagerStep = ({ widget, children }) => (
  <div className="ad-manager-container">
    <SectionWrapper header={widget.view.header} subheader={<Fragment>{widget.view.subheader}</Fragment>}>
      {children}
    </SectionWrapper>

    <OperationButtons subtab={widget.view.currentSubtab} />
  </div>
);

AdManagerStep.propTypes = {
  widget: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default AdManagerStep;
