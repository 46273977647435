/* eslint-disable react/prop-types */

/**
 * This is a generic presentational NAV component that renders whatever it is given. A nice wrapper for nav bars
 */

import React from 'react';

interface NavigationProps {
  containerClassName: string;
  navClassName: string;
}
// Make this even more generic
const Navigation: React.FC<NavigationProps> = ({ children, containerClassName, navClassName }) => (
  <nav className={navClassName}>
    <div className={containerClassName}>{children}</div>
  </nav>
);

export default Navigation;
