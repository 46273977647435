import types from './types';

// ------------------------------------
// Action Creators - they simply return an action
// An action being a payload of information that send data from your application to your store.
// ------------------------------------

const receiveAdAutomationStrategies = (adAutomationStrategies: any[]) => ({
  type: types.RECEIVE_AD_AUTOMATION_STRATEGIES,
  adAutomationStrategies,
  receivedAt: Date.now()
});

const clearAdAutomationStrategies = () => ({
  type: types.CLEAR_AD_AUTOMATION_STRATEGIES
});

export default {
  clearAdAutomationStrategies,
  receiveAdAutomationStrategies
};
