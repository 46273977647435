import IconExit from '!svg-react-loader!./assets/iconExit.svg'; // eslint-disable-line
import Card from '@mui/material/Card';
import Modal, { ModalProps } from '@mui/material/Modal';
import { useStacklineTheme } from '@stackline/ui';
import React from 'react';
import { Text } from '../../Generic/Text';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

/**
 * Modal on forecasting summary page explaining the forecasting algorithm
 */
const ForecastingAlgorithmModal = (props: Omit<ModalProps, 'children'>) => {
  const theme = useStacklineTheme();

  return (
    <Modal {...props}>
      <Card
        sx={{
          padding: '24px',
          borderRadius: theme.spacing.sm,
          paddingBottom: '48px',
          maxWidth: '500px',
          '&:focus': {
            outline: 'none' // removes blue outline since it gets automatically focused when opened
          },
          '& p': {
            lineHeight: 1.36 // TODO line height and spacing in our text seems a little off from Zeplin, we should revise for all text
          }
        }}
      >
        <Flex flexDirection="column" gap="md">
          <Flex justifyContent="space-between">
            <Text variant="h4" sx={{ marginBottom: '8px' }}>
              Forecasting Algorithm
            </Text>
            <IconExit onClick={(event) => props.onClose(event, 'backdropClick')} cursor="pointer" />
          </Flex>
          <Text variant="body2">
            Powered by artificial intelligence, the forecast incorporates numerous data sets, including:
          </Text>
          <Flex flexDirection="column" gap="xs">
            <Text variant="body2">• Product-level traffic, conversion, and retail sales history</Text>
            <Text variant="body2">• Category projections and seasonality</Text>
            <Text variant="body2">• Market and competitive intelligence from more than 1 billion products</Text>
            <Text variant="body2">• Direct inputs from your business plan adjustments</Text>
          </Flex>
          <Text variant="body2">
            All of the above are utilized in a tree-based convolutional time series additive decomposition model to
            generate accurate forecasts of every economic variable driving your sales performance.
          </Text>
        </Flex>
      </Card>
    </Modal>
  );
};

export default ForecastingAlgorithmModal;
