import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import Truncate from 'react-truncate';
import Chip from '@mui/material/Chip';
import { entityOperations } from 'src/store/modules/entityService';
import { getClientImageUrl } from 'src/utils/image';

class AggregatedRetailersSidebarCard extends React.Component {
  static propTypes = {
    disableFetchMainEntity: PropTypes.bool,
    // From `withRouter`
    history: PropTypes.object.isRequired,
    // Redux Props
    app: PropTypes.object.isRequired,
    fetchMainEntity: PropTypes.func.isRequired,
    entityService: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired
  };

  static defaultProps = {
    disableFetchMainEntity: false
  };

  state = {};

  componentWillReceiveProps(nextProps) {
    const { entityService } = nextProps;
    const { companyName } = entityService.mainEntity;
    if (_isEqual(entityService, this.props.entityService)) {
      return;
    }

    this.setState({ name: companyName });
  }

  handleImageError = () => this.setState({ logoNotAvailable: true });

  showLogo(vendor, name, logoNotAvailable) {
    if (logoNotAvailable) {
      const shortName = name ? name.slice(0, 2) : '';
      return (
        <div className="segment__short-name-container" style={{ backgroundColor: '#468AF6' }}>
          <div className="segment__short-name">{shortName}</div>
        </div>
      );
    }

    return (
      <div className="brand__image-container sl-image-container">
        <div className="brand__image-content sl-image-center">
          <img
            className="sl-image"
            alt="logo"
            src={getClientImageUrl(vendor && vendor.BeaconClientId)}
            onError={this.handleImageError}
          />
        </div>
      </div>
    );
  }

  render() {
    const { user } = this.props;
    const { name, logoNotAvailable } = this.state;
    const { vendor } = user.config;

    return (
      <div className="nav-header-container">
        <div className="sl-image-container">
          <div className="sl-image-center">{this.showLogo(vendor, name, logoNotAvailable)}</div>
        </div>
        <div className="nav-name-container" title={name}>
          <Truncate lines={2} ellipsis="...">
            <h4 className="nav-company-name">{name}</h4>
          </Truncate>
        </div>
        <Chip className="entity-chip" label="Organization" />
      </div>
    );
  }
}

const mapStateToProps = (state) => _pick(state, ['app', 'entityService', 'user', 'retailer']);

const mapDispatchToProps = {
  fetchMainEntity: entityOperations.fetchMainEntity
};

const EnhancedClientSidebarCard = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AggregatedRetailersSidebarCard);

export default EnhancedClientSidebarCard;
