/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';

import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import EntityGridInner from 'src/components/EntityGrid';
import EmptyTeamSavedSearches from './EmptyTeamSavedSearches';
import EmptyMySavedSearches from './EmptyMySavedSearches';
import SearchList from './SearchList';
import ReduxStore from 'src/types/store/reduxStore';
import { MetricField } from 'src/types/application/types';
import { PageLayout } from 'src/types/application/widgetTypes';
import { Conditions, Entity } from 'sl-api-connector/types';

const EntityGrid = EntityGridInner as any as React.ComponentType<any>;

const mapStateToProps = (state: ReduxStore) => _pick(state, ['app', 'segments']);

type SavedSearchListsProps = {
  entityType: string;
  selectedMetricField: MetricField;
  aggregationMetricFields: MetricField[];
  searchListObject: any;
  pageLayout: PageLayout;
  selectedItem: any;
  queryConditions: Conditions;
  aggregationConditions: Conditions;
  entityDefinition: Entity;
  handleSearchInputChange: (evt: React.ChangeEvent, name: any) => void;
  handleSearchItemClick: (value: any, name: any) => void;
} & ReturnType<typeof mapStateToProps>;

const SavedSearchLists: React.FC<SavedSearchListsProps> = ({
  entityType,
  selectedMetricField,
  aggregationMetricFields,
  searchListObject,
  pageLayout,
  selectedItem,
  queryConditions,
  aggregationConditions,
  entityDefinition,
  handleSearchInputChange,
  handleSearchItemClick,
  app,
  segments: { mySegments, teamSegments, mySearchTermLists, teamSearchTermLists }
}) => {
  const productGridWidget = {
    CustomComponent: EntityGrid,
    view: {
      name: 'entityGrid',
      gridOptions: {
        enableExport: false,
        enableAggregateBy: true
      }
    },
    data: {
      groupByFields: [INDEX_FIELDS.getField(app.name, selectedMetricField.indexName!, 'stacklineSku', 'product')],
      configByGroupByFieldName: {
        stacklineSku: {
          indexName: selectedMetricField.indexName,
          entity: ENTITIES[app.name].product,
          mainMetricField: selectedMetricField,
          aggregationFields: aggregationMetricFields
        }
      }
    }
  };

  const showEmptyMySavedSearches =
    (entityType === 'segment' && mySegments.length === 0) ||
    (entityType === 'searchtermlist' && mySearchTermLists.length === 0);
  let showEmptyTeamSavedSearches =
    (entityType === 'segment' && teamSegments.length === 0) ||
    (entityType === 'searchtermlist' && teamSearchTermLists.length === 0);

  if (app.name === 'advertising') {
    showEmptyTeamSavedSearches = false;
  }
  return (
    <>
      {showEmptyMySavedSearches ? <EmptyMySavedSearches entityDefinition={entityDefinition} /> : null}
      {searchListObject && entityType !== 'product' ? (
        <SearchList
          pageLayout={pageLayout}
          entityDefinition={entityDefinition}
          currentSelectedItem={selectedItem}
          handleSearchInputChange={handleSearchInputChange}
          handleSearchItemClick={handleSearchItemClick}
          searchListOptions={searchListObject}
          queryParams={app.queryParams}
        />
      ) : null}
      {showEmptyTeamSavedSearches ? <EmptyTeamSavedSearches entityDefinition={entityDefinition} /> : null}
      {searchListObject && entityType === 'product' ? (
        <div style={{ padding: '0 30px', marginTop: '-20px' }}>
          <EntityGrid
            queryConditions={queryConditions}
            aggregationConditions={aggregationConditions}
            widget={productGridWidget}
          />
        </div>
      ) : null}
    </>
  );
};

const EnhancedSavedSearchLists = connect(mapStateToProps)(SavedSearchLists);

export default EnhancedSavedSearchLists;
