export interface UserCustomColumn {
  field: string;
  displayName: string;
}

export enum Indices {
  adCampaignDailyMetrics = 'adCampaignDailyMetrics',
  adEntityDailyMetrics = 'adEntityDailyMetrics',
  adCampaignAdGroupTargetDailyMetrics = 'adCampaignAdGroupTargetDailyMetrics',
  adCampaignAdGroupProductDailyMetrics = 'adCampaignAdGroupProductDailyMetrics',
  adCampaignAdGroupProductTargetDailyMetrics = 'adCampaignAdGroupProductTargetDailyMetrics',
  adCampaignAdGroupTarget = 'adCampaignAdGroupTarget',
  adCampaignAdGroupProduct = 'adCampaignAdGroupProduct'
}
