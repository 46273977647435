import { useMemo, useCallback } from 'react';
import { useMetricByWeekId, UseMetricByWeekIdArgs } from 'src/serverProxy/useMetricByWeekId';
import { calculatePercentChange, getAppName } from 'src/utils/app';
import { timestampToWeekId } from 'src/utils/dateUtils';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { FIELD_PATH_SEPARATOR } from 'src/utils/entityDefinitions/entityDefinitionTypes';
import { useMetricFormatter, useWeekRange, WeekRange, useComparisonPeriod, ComparisonPeriod } from 'src/utils/Hooks';

/**
 * useMetricByWeekId can only query for one index type, but purchase
 * rate depends on traffic and sales, therefore we manually
 * compute purchase rate
 */
const useDerivedMetricByWeekId = (indexName: string, fieldName: string, rest?: Partial<UseMetricByWeekIdArgs>) => {
  const formatMetric = useMetricFormatter();
  const weekRange = useWeekRange();
  const comparisonPeriod = useComparisonPeriod();

  const field = useMemo(() => {
    return INDEX_FIELDS.getField(getAppName(), indexName, fieldName);
  }, [fieldName, indexName]);

  const [[index1, field1], [index2, field2]] = useMemo(() => {
    // All derived fields will have 2 variables, each of the format
    // {indexName}__{fieldName}, like traffic__totalClicks
    const [variable1, variable2]: string[] = field.aggregationFunction.variables();

    return [variable1.split(FIELD_PATH_SEPARATOR), variable2.split(FIELD_PATH_SEPARATOR)];
  }, [field.aggregationFunction]);

  // Data for dependent field 1
  const {
    primaryData: primaryData1,
    secondaryData: secondaryData1,
    isLoading: loading1,
    primaryTotal: primary1Total
  } = useMetricByWeekId({
    indexName: index1,
    fieldName: field1,
    ...rest
  });

  // Data for dependent field 2
  const {
    primaryData: primaryData2,
    secondaryData: secondaryData2,
    isLoading: loading2,
    primaryTotal: primary2Total
  } = useMetricByWeekId({
    indexName: index2,
    fieldName: field2,
    ...rest
  });

  const buildSplineData = useCallback(
    (data1: number[][], data2: number[][]) => {
      if (loading1 || loading2) {
        return [];
      }

      // Map timestamp to its value
      const data1TimeToValue = data1.reduce(
        (acc, row) => ({
          ...acc,
          [row[0]]: row[1]
        }),
        {} as Record<number, number>
      );
      const data2TimeToValue = data2.reduce(
        (acc, row) => ({
          ...acc,
          [row[0]]: row[1]
        }),
        {} as Record<number, number>
      );

      // Get all timestamps in order because there may be week ids in one data
      // set missing in the other
      const timestamps = Array.from(new Set([...data1.map((row) => row[0]), ...data2.map((row) => row[0])])).sort();

      // Build spline data
      return timestamps.map((timestamp) => {
        return [
          timestamp,
          field.aggregationFunction.evaluate({
            [`${index1}${FIELD_PATH_SEPARATOR}${field1}`]: data1TimeToValue[timestamp] || 0,
            [`${index2}${FIELD_PATH_SEPARATOR}${field2}`]: data2TimeToValue[timestamp] || 0
          })
        ];
      });
    },
    [field.aggregationFunction, field1, field2, index1, index2, loading1, loading2]
  );

  // Main time period --- seems to only have the YTD values
  const primaryData = useMemo(() => {
    return buildSplineData(primaryData1, primaryData2);
  }, [buildSplineData, primaryData1, primaryData2]);

  // Comparison time period?
  const secondaryData = useMemo(() => {
    return buildSplineData(secondaryData1, secondaryData2);
  }, [buildSplineData, secondaryData1, secondaryData2]);

  const metricTotal = useMemo(() => {
    // For last week we just show the most recent value
    if (weekRange === WeekRange.LAST_WEEK && primaryData.length > 0) {
      return primaryData[primaryData.length - 1][1];
    }
    const variableValues = {
      [`${index1}${FIELD_PATH_SEPARATOR}${field1}`]: primary1Total,
      [`${index2}${FIELD_PATH_SEPARATOR}${field2}`]: primary2Total
    };
    return field.aggregationFunction.evaluate(variableValues);
  }, [weekRange, primaryData, index1, field1, primary1Total, index2, field2, primary2Total, field.aggregationFunction]);

  const metricTotalSecondary = useMemo(() => {
    const mainWeekIds = primaryData.map((row) => timestampToWeekId(row[0]));
    const filterSecondaryData =
      rest && rest.customFilterComparisonForTotal
        ? rest.customFilterComparisonForTotal
        : (row: number[]) =>
            comparisonPeriod === ComparisonPeriod.PRIOR_YEAR ? mainWeekIds.includes(timestampToWeekId(row[0])) : true;

    if (weekRange === WeekRange.LAST_WEEK && primaryData.length > 0) {
      // For the prior period, compare against the last secondary data point
      if (comparisonPeriod === ComparisonPeriod.PRIOR_PERIOD) {
        return secondaryData.length > 0 ? secondaryData[secondaryData.length - 1][1] : 0;
      }
      const lastWeekId = timestampToWeekId(primaryData[primaryData.length - 1][0]);
      const lastComparisonRow = secondaryData.find((row) => timestampToWeekId(row[0]) === lastWeekId);
      return lastComparisonRow ? lastComparisonRow[1] : 0;
      // For prior year, compare against the secondary data point for the same week as the last primary data point
    }

    const filteredSecondary1 = secondaryData1.filter(filterSecondaryData);
    const filteredSecondary2 = secondaryData2.filter(filterSecondaryData);

    const secondary1Total = filteredSecondary1.reduce((acc, row) => acc + row[1], 0);
    const secondary2Total = filteredSecondary2.reduce((acc, row) => acc + row[1], 0);

    const variableValues = {
      [`${index1}${FIELD_PATH_SEPARATOR}${field1}`]: secondary1Total,
      [`${index2}${FIELD_PATH_SEPARATOR}${field2}`]: secondary2Total
    };

    return field.aggregationFunction.evaluate(variableValues);
  }, [
    comparisonPeriod,
    field.aggregationFunction,
    field1,
    field2,
    index1,
    index2,
    primaryData,
    secondaryData,
    secondaryData1,
    secondaryData2,
    weekRange
  ]);

  const percentChange = useMemo(() => {
    return calculatePercentChange(metricTotal, metricTotalSecondary);
  }, [metricTotal, metricTotalSecondary]);

  const formattedPercentChange = useMemo(() => {
    return `${formatMetric(percentChange, field.metricType, { decimalPlaces: 2 })}`.replace('-', '');
  }, [field.metricType, formatMetric, percentChange]);

  return {
    isLoading: loading1 || loading2,
    primaryData,
    secondaryData,
    field,
    formattedPrimaryTotal: `${formatMetric(metricTotal, field.metricType, { decimalPlaces: 2 })}`,
    formattedSecondaryTotal: `${formatMetric(metricTotalSecondary, field.metricType, { decimalPlaces: 2 })}`,
    percentChange,
    formattedPercentChange
  };
};

export default useDerivedMetricByWeekId;
