import axios, { CancelToken } from 'axios';
import { Option } from 'funfix-core';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _merge from 'lodash/merge';
import _orderBy from 'lodash/orderBy';
// import moment from 'moment';
import momentTz from 'moment-timezone';
import {
  AD_BUDGET_TYPE,
  AD_CAMPAIGN_TYPE,
  AD_TARGETING_CLASS,
  AD_TARGETING_TYPE,
  IAdTarget
} from 'sl-ad-campaign-manager-data-model';
import { AdManagerAdCampaignMetadata, AppName, Conditions, Entity, TermFilter } from 'sl-api-connector/types';
import { getIsEditing } from 'src/components/AdCampaignBuilder/util';
import { store } from 'src/main';
import { getInitialState as adCampaignBuilderInitialState } from 'src/store/modules/adManager/adCampaignBuilder/initialState';
import { getAdPlatform } from 'src/store/modules/adManager/adCampaignBuilder/selectors';
import { buildSearchRequests, requestEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { parseEntityMetrics } from 'src/store/modules/entitySearchService/selectors';
import { ReduxAction } from 'src/types/application/types';
import ReduxStore from 'src/types/store/reduxStore';
import { UTC_TIMEZONE } from 'src/utils/constants';
import { addProp, prop, propEq } from 'src/utils/fp';
import { error, panic } from 'src/utils/mixpanel';
import { replaceState, setTargetEntities } from './actions';
import { isInstacart } from 'src/utils/app';

async function fetchTargetEntitiesBrandLevelTrafficMetrics(
  appName: string,
  allWeekIdsByRetailerId: any,
  apiRequest: any,
  cancelToken: CancelToken,
  metricsByEntityId: any,
  requestContext: any,
  statePropertyName: string,
  searchRequestsOverrides: any,
  searchRequestsOverridesWithAdditionalRequests: any,
  featuredProductBrandIds: any
) {
  // Fetch Brand-Level Metrics - brand level metrics are required for incrementality calculation
  const { groupByFieldName } = searchRequestsOverrides[0].aggregations[0];
  const apiRequestForBrandLevelMetrics = _cloneDeep(apiRequest);
  apiRequestForBrandLevelMetrics[0].id = 'adCampaignBuilder_targetEntities_brandLevelMetrics';
  apiRequestForBrandLevelMetrics[0].name = 'adCampaignBuilder_targetEntities_brandLevelMetrics';
  apiRequestForBrandLevelMetrics[0].conditions.rangeFilters =
    apiRequestForBrandLevelMetrics[0].conditions.rangeFilters.filter((x: any) => x.fieldName !== 'organicClicks');
  apiRequestForBrandLevelMetrics[0].conditions.termFilters.push({
    fieldName: 'brandId',
    values: featuredProductBrandIds
  });
  apiRequestForBrandLevelMetrics[0].conditions.termFilters.push({
    fieldName: searchRequestsOverrides[0].aggregations[0].groupByFieldName,
    values: Object.keys(metricsByEntityId)
  });
  const apiResponseForBrandLevelMetrics = await requestEntityMetrics(
    appName,
    apiRequestForBrandLevelMetrics,
    cancelToken
  );
  const statePropertyValueForBrandLevelMetrics = parseEntityMetrics({
    apiRequest: apiRequestForBrandLevelMetrics,
    apiResponse: apiResponseForBrandLevelMetrics,
    statePropertyName,
    requestContext,
    searchRequestsOverrides: searchRequestsOverridesWithAdditionalRequests,
    allWeekIdsByRetailerId
  });

  searchRequestsOverrides[0].aggregations[0].aggregationFields.forEach(
    ({ aggregateByFieldName }: { aggregateByFieldName: string }) => {
      const metricNameByGroupByField = `${aggregateByFieldName}_by_${groupByFieldName}`;
      statePropertyValueForBrandLevelMetrics[metricNameByGroupByField].data.forEach(({ entity, value }: any) => {
        const { id } = entity;
        if (metricsByEntityId[id]) {
          metricsByEntityId[id][`${aggregateByFieldName}BrandLevelBaseValue`] = value;
        }
      });
    }
  );
}

async function fetchTargetEntitiesAdvertisingMetrics(
  appName: string,
  allWeekIdsByRetailerId: any,
  apiRequest: any,
  cancelToken: CancelToken,
  metricsByEntityId: any,
  requestContext: any,
  statePropertyName: string
) {
  // Code to fetch actual advertising metrics from beacon advertising index (ams data) and use it for conversion
  try {
    const groupByFieldName = 'targetingText';
    const apiRequestForSearchTermLevelMetrics = _cloneDeep(apiRequest);
    apiRequestForSearchTermLevelMetrics[0].id = 'adCampaignBuilder_targetEntities_advertisingMetrics';
    apiRequestForSearchTermLevelMetrics[0].name = 'adCampaignBuilder_targetEntities_advertisingMetrics';
    apiRequestForSearchTermLevelMetrics[0].conditions.rangeFilters =
      apiRequestForSearchTermLevelMetrics[0].conditions.rangeFilters.filter(
        (x: any) => x.fieldName !== 'organicClicks'
      );
    apiRequestForSearchTermLevelMetrics[0].conditions.termFilters =
      apiRequestForSearchTermLevelMetrics[0].conditions.termFilters.filter(
        (x: any) => x.fieldName !== 'subCategoryId' && x.fieldName !== 'excludedSearchTerm'
      );
    if (
      apiRequestForSearchTermLevelMetrics[0].conditions.termFilters.find((x: any) => x.fieldName === 'searchTermFuzzy')
    ) {
      apiRequestForSearchTermLevelMetrics[0].conditions.termFilters.find(
        (x: any) => x.fieldName === 'searchTermFuzzy'
      ).fieldName = 'targetingTextFuzzy';
    }
    const targetingTextValues: any[] = [];
    const targetingTextValueToIdMap: any = {};
    Object.keys(metricsByEntityId).forEach((id) => {
      targetingTextValues.push(metricsByEntityId[id].keyword);
      targetingTextValueToIdMap[metricsByEntityId[id].keyword] = id;
    });
    apiRequestForSearchTermLevelMetrics[0].conditions.termFilters.push({
      fieldName: 'targetingText',
      values: targetingTextValues
    });
    apiRequestForSearchTermLevelMetrics[0].aggregations = [
      {
        ...apiRequestForSearchTermLevelMetrics[0].aggregations[0],
        groupByFieldName,
        aggregationFields: [
          { aggregateByFieldDisplayName: 'Impressions', aggregateByFieldName: 'impressions', function: 'sum' },
          { aggregateByFieldDisplayName: 'Ad Clicks', aggregateByFieldName: 'clicks', function: 'sum' },
          { aggregateByFieldDisplayName: 'Ad Spend', aggregateByFieldName: 'spend', function: 'sum' },
          { aggregateByFieldDisplayName: 'Ad Units Sold', aggregateByFieldName: 'unitsSold', function: 'sum' },
          {
            aggregateByFieldDisplayName: 'CTR %',
            aggregateByFieldName: 'clickThroughRate',
            function: 'computed',
            aggregateByFormula: '((impressions_sum > 0) ? ((clicks_sum / impressions_sum)) : (0))'
          },
          {
            aggregateByFieldDisplayName: 'Conversion Rate',
            aggregateByFieldName: 'conversionRate',
            function: 'computed',
            aggregateByFormula: '((clicks_sum > 0) ? ((unitsSold_sum / clicks_sum)) : (0))'
          },
          {
            aggregateByFieldDisplayName: 'Cost per Click',
            aggregateByFieldName: 'costPerClick',
            function: 'computed',
            aggregateByFormula: '((clicks_sum > 0) ? ((spend_sum / clicks_sum)) : (0))'
          }
        ],
        sortDirection: null,
        sortByAggregationField: null
      }
    ];
    apiRequestForSearchTermLevelMetrics[0].searchType = 'advertising-adCampaignAdGroupProductTargetDailyMetrics';
    const apiResponseForSearchTermLevelMetrics = await requestEntityMetrics(
      appName,
      apiRequestForSearchTermLevelMetrics,
      cancelToken
    );
    const statePropertyValueForSearchTermLevelMetrics = parseEntityMetrics({
      apiRequest: apiRequestForSearchTermLevelMetrics,
      apiResponse: apiResponseForSearchTermLevelMetrics,
      statePropertyName,
      requestContext: {
        ...requestContext,
        indexName: 'adCampaignAdGroupProductTargetDailyMetrics'
      },
      searchRequestsOverrides: [apiRequestForSearchTermLevelMetrics],
      allWeekIdsByRetailerId
    });
    apiRequestForSearchTermLevelMetrics[0].aggregations[0].aggregationFields.forEach(
      ({ aggregateByFieldName }: { aggregateByFieldName: string }) => {
        const metricNameByGroupByField = `${aggregateByFieldName}_by_${groupByFieldName}`;
        statePropertyValueForSearchTermLevelMetrics[metricNameByGroupByField].data.forEach(({ entity, value }: any) => {
          const { id } = entity;
          if (metricsByEntityId[targetingTextValueToIdMap[id]]) {
            metricsByEntityId[targetingTextValueToIdMap[id]][
              `ad${aggregateByFieldName.substring(0, 1).toUpperCase()}${aggregateByFieldName.substring(
                1
              )}SearchTermLevelAdvertisingBaseValue`
            ] = value;
          }
        });
      }
    );
  } catch (e) {
    console.warn(e);
  }
}

async function fetchCategoryLevelTrafficMetrics(
  appName: AppName,
  allWeekIdsByRetailerId: any,
  apiRequest: any,
  cancelToken: CancelToken,
  metricsByEntityId: { [entityId: string]: { [key: string]: any } },
  requestContext: any,
  statePropertyName: string,
  searchRequestsOverrides: any,
  searchRequestsOverridesWithAdditionalRequests: any,
  featuredProductCategoryIds: (string | number)[],
  featuredProductSubCategoryIds: (string | number)[]
) {
  try {
    const searchRequestsOverridesCloned = _cloneDeep(searchRequestsOverrides);
    searchRequestsOverridesCloned[0].aggregations[0].groupByFieldName = 'retailerId';
    const apiRequestForCategoryLevelMetrics = _cloneDeep(apiRequest);
    apiRequestForCategoryLevelMetrics[0].id = 'adCampaignBuilder_targetEntities_categoryLevelMetrics';
    apiRequestForCategoryLevelMetrics[0].name = 'adCampaignBuilder_targetEntities_categoryLevelMetrics';
    apiRequestForCategoryLevelMetrics[0].aggregations[0].groupByFieldName = 'retailerId';
    const { conditions }: { conditions: Conditions } = apiRequestForCategoryLevelMetrics[0];
    conditions.rangeFilters = (conditions.rangeFilters || []).filter((x: any) => x.fieldName !== 'organicClicks');
    conditions.termFilters = (conditions.termFilters || []).filter(
      ({ fieldName }) => !['categoryId', 'subCategoryId'].includes(fieldName)
    );
    conditions.termFilters.push(
      {
        fieldName: 'categoryId',
        values: featuredProductCategoryIds
      },
      {
        fieldName: 'subCategoryId',
        values: featuredProductSubCategoryIds
      }
    );

    apiRequestForCategoryLevelMetrics[0].searchType = 'beacon-traffic';
    const apiResponseForSearchLevelMetrics = await requestEntityMetrics(
      appName,
      apiRequestForCategoryLevelMetrics,
      cancelToken
    );
    const statePropertyValueForSearchTermLevelMetrics = parseEntityMetrics({
      apiRequest: apiRequestForCategoryLevelMetrics,
      apiResponse: apiResponseForSearchLevelMetrics,
      statePropertyName,
      requestContext,
      searchRequestsOverrides: searchRequestsOverridesWithAdditionalRequests,
      allWeekIdsByRetailerId
    });

    const categoryLevelTrafficBaseValue: any = {};
    // Code to fetch relevant traffic metrics for the current searchTerms from beacon traffic index and if available use it for conversion
    const { groupByFieldName } = searchRequestsOverridesCloned[0].aggregations[0];
    searchRequestsOverridesCloned[0].aggregations[0].aggregationFields.forEach(
      ({ aggregateByFieldName }: { aggregateByFieldName: string }) => {
        const metricNameByGroupByField = `${aggregateByFieldName}_by_${groupByFieldName}`;
        statePropertyValueForSearchTermLevelMetrics[metricNameByGroupByField].data.forEach(({ value }: any) => {
          categoryLevelTrafficBaseValue[`${aggregateByFieldName}CategoryLevelTrafficBaseValue`] = value;
        });
      }
    );
    Object.keys(metricsByEntityId).forEach((metricsId: string) => {
      metricsByEntityId[metricsId] = {
        ...metricsByEntityId[metricsId],
        ...categoryLevelTrafficBaseValue
      };
    });
  } catch (e) {
    error(`Error fetching category level metrics from traffic: ${e}`, { error: e });
  }
}

async function fetchBrandLevelSalesMetrics(
  allWeekIdsByRetailerId: any,
  apiRequest: any,
  cancelToken: CancelToken,
  metricsByEntityId: any,
  requestContext: any,
  statePropertyName: string,
  featuredProductBrandIds: (string | number)[]
) {
  // Fetch Brand-Level Sales Metrics - brand level sales metrics are required for incrementality calculation
  const groupByFieldName = 'retailerId';
  const apiRequestForBrandLevelSalesMetrics = _cloneDeep(apiRequest);
  apiRequestForBrandLevelSalesMetrics[0].id = 'adCampaignBuilder_targetEntities_brandLevelSalesMetrics';
  apiRequestForBrandLevelSalesMetrics[0].name = 'adCampaignBuilder_targetEntities_brandLevelSalesMetrics';
  apiRequestForBrandLevelSalesMetrics[0].conditions.rangeFilters =
    apiRequestForBrandLevelSalesMetrics[0].conditions.rangeFilters.filter((x: any) => x.fieldName !== 'organicClicks');
  apiRequestForBrandLevelSalesMetrics[0].conditions.termFilters =
    apiRequestForBrandLevelSalesMetrics[0].conditions.termFilters.filter(
      (x: any) => x.fieldName !== 'excludedSearchTerm'
    );
  apiRequestForBrandLevelSalesMetrics[0].conditions.termFilters.push({
    fieldName: 'brandId',
    values: featuredProductBrandIds
  });
  apiRequestForBrandLevelSalesMetrics[0].searchType = 'atlas-sales';
  apiRequestForBrandLevelSalesMetrics[0].aggregations = [
    {
      ...apiRequestForBrandLevelSalesMetrics[0].aggregations[0],
      groupByFieldName,
      aggregationFields: [
        { aggregateByFieldDisplayName: 'Retail Sales', aggregateByFieldName: 'retailSales', function: 'sum' },
        { aggregateByFieldDisplayName: 'Units Sold', aggregateByFieldName: 'unitsSold', function: 'sum' }
      ],
      sortDirection: null,
      sortByAggregationField: null
    }
  ];
  apiRequestForBrandLevelSalesMetrics[0].sortFilter = {
    sortFields: [apiRequestForBrandLevelSalesMetrics[0].aggregations[0].aggregationFields[0]]
  };
  const apiResponseForBrandLevelMetrics = await requestEntityMetrics(
    AppName.Atlas,
    apiRequestForBrandLevelSalesMetrics,
    cancelToken
  );
  const statePropertyValueForBrandLevelSalesMetrics = parseEntityMetrics({
    apiRequest: apiRequestForBrandLevelSalesMetrics,
    apiResponse: apiResponseForBrandLevelMetrics,
    statePropertyName,
    requestContext,
    searchRequestsOverrides: [apiRequestForBrandLevelSalesMetrics],
    allWeekIdsByRetailerId
  });

  const brandLevelSalesBaseValue: any = {};
  // Code to fetch relevant traffic metrics for the current searchTerms from beacon traffic index and if available use it for conversion

  apiRequestForBrandLevelSalesMetrics[0].aggregations[0].aggregationFields.forEach(
    ({ aggregateByFieldName }: { aggregateByFieldName: string }) => {
      const metricNameByGroupByField = `${aggregateByFieldName}_by_${groupByFieldName}`;
      statePropertyValueForBrandLevelSalesMetrics[metricNameByGroupByField].data.forEach(({ value }: any) => {
        brandLevelSalesBaseValue[
          `sales${aggregateByFieldName.substring(0, 1).toUpperCase()}${aggregateByFieldName.substring(
            1
          )}BrandLevelSalesBaseValue`
        ] = value;
      });
    }
  );
  Object.keys(metricsByEntityId).forEach((metricsId: string) => {
    metricsByEntityId[metricsId] = {
      ...metricsByEntityId[metricsId],
      ...brandLevelSalesBaseValue
    };
  });
}

async function fetchCategoryLevelSalesMetrics(
  allWeekIdsByRetailerId: any,
  apiRequest: any,
  cancelToken: CancelToken,
  metricsByEntityId: any,
  requestContext: any,
  statePropertyName: string
) {
  // Fetch Brand-Level Sales Metrics - brand level sales metrics are required for incrementality calculation
  const groupByFieldName = 'retailerId';
  const apiRequestForCategoryLevelSalesMetrics = _cloneDeep(apiRequest);
  apiRequestForCategoryLevelSalesMetrics[0].id = 'adCampaignBuilder_targetEntities_categoryLevelSalesMetrics';
  apiRequestForCategoryLevelSalesMetrics[0].name = 'adCampaignBuilder_targetEntities_categoryLevelSalesMetrics';
  apiRequestForCategoryLevelSalesMetrics[0].conditions.rangeFilters =
    apiRequestForCategoryLevelSalesMetrics[0].conditions.rangeFilters.filter(
      (x: any) => x.fieldName !== 'organicClicks'
    );
  apiRequestForCategoryLevelSalesMetrics[0].conditions.termFilters =
    apiRequestForCategoryLevelSalesMetrics[0].conditions.termFilters.filter(
      (x: any) => x.fieldName !== 'excludedSearchTerm'
    );
  apiRequestForCategoryLevelSalesMetrics[0].searchType = 'atlas-sales';
  apiRequestForCategoryLevelSalesMetrics[0].aggregations = [
    {
      ...apiRequestForCategoryLevelSalesMetrics[0].aggregations[0],
      groupByFieldName,
      aggregationFields: [
        { aggregateByFieldDisplayName: 'Retail Sales', aggregateByFieldName: 'retailSales', function: 'sum' },
        { aggregateByFieldDisplayName: 'Units Sold', aggregateByFieldName: 'unitsSold', function: 'sum' }
      ],
      sortDirection: null,
      sortByAggregationField: null
    }
  ];
  apiRequestForCategoryLevelSalesMetrics[0].sortFilter = {
    sortFields: [apiRequestForCategoryLevelSalesMetrics[0].aggregations[0].aggregationFields[0]]
  };
  const apiResponseForCategoryLevelMetrics = await requestEntityMetrics(
    AppName.Atlas,
    apiRequestForCategoryLevelSalesMetrics,
    cancelToken
  );
  const statePropertyValueForBrandLevelSalesMetrics = parseEntityMetrics({
    apiRequest: apiRequestForCategoryLevelSalesMetrics,
    apiResponse: apiResponseForCategoryLevelMetrics,
    statePropertyName,
    requestContext,
    searchRequestsOverrides: [apiRequestForCategoryLevelSalesMetrics],
    allWeekIdsByRetailerId
  });

  const categoryLevelSalesBaseValue: any = {};
  // Code to fetch relevant traffic metrics for the current searchTerms from beacon traffic index and if available use it for conversion

  apiRequestForCategoryLevelSalesMetrics[0].aggregations[0].aggregationFields.forEach(
    ({ aggregateByFieldName }: { aggregateByFieldName: string }) => {
      const metricNameByGroupByField = `${aggregateByFieldName}_by_${groupByFieldName}`;
      statePropertyValueForBrandLevelSalesMetrics[metricNameByGroupByField].data.forEach(({ value }: any) => {
        categoryLevelSalesBaseValue[
          `sales${aggregateByFieldName.substring(0, 1).toUpperCase()}${aggregateByFieldName.substring(
            1
          )}CategoryLevelSalesBaseValue`
        ] = value;
      });
    }
  );
  Object.keys(metricsByEntityId).forEach((metricsId: string) => {
    metricsByEntityId[metricsId] = {
      ...metricsByEntityId[metricsId],
      ...categoryLevelSalesBaseValue
    };
  });
}

export const fetchEmptyList = () => async (dispatch: (action: ReduxAction) => void, getState: () => ReduxStore) => {
  const reduxState = getState();
  const {
    adCampaignBuilder: {
      target: { uploadedTargetKeywords }
    }
  } = reduxState;
  if (uploadedTargetKeywords) {
    dispatch(
      setTargetEntities(
        uploadedTargetKeywords.map((word) => ({
          selected: true,
          isBulkUploaded: 'true',
          keyword: word,
          matchType: 'exact',
          id: word,
          entity: { id: word, name: word }
        }))
      )
    );
  } else {
    dispatch(setTargetEntities([]));
  }
};

export const fetchTargetEntities =
  (
    statePropertyName: string,
    requestContext: { [key: string]: any },
    searchRequestsOverrides: { [key: string]: any },
    cancelToken: CancelToken,
    eventBus?: any,
    adGroupId?: string
  ) =>
  async (dispatch: (action: ReduxAction) => void, getState: () => ReduxStore) => {
    const reduxState = getState();
    const {
      app,
      allWeekIdsByRetailerId,
      adCampaignBuilder: {
        featured: { featuredProductBrandIds, featuredProductCategoryIds, featuredProductSubCategoryIds },
        target: { previouslySelectedTargetEntityIds, uploadedTargetKeywords },
        campaignType
      },
      entityService: {
        // @ts-ignore
        mainEntity: { adTargets }
      },
      adPlatformSettings
    } = reduxState;
    let { targetingTypeId } = reduxState.adCampaignBuilder.target;

    let minimumBid;
    // select campaign type from campaign page or ad campaign builder page
    const adCampaignType =
      _get(reduxState.entityService, ['mainEntity', 'extendedAttributes', 'campaignType']) ||
      _get(campaignType, 'settingId');
    if (adCampaignType) {
      const match = adPlatformSettings.find(
        (set) => _get(set, ['extendedAttributes', 'campaignType']) === adCampaignType
      );
      if (match) {
        minimumBid = _get(match, ['extendedAttributes', 'targetLimits', 'minimumBid'], undefined);
      }
    }

    const appName = app.apiAppName;

    const adTargetsForGroup = adGroupId
      ? (adTargets || [])
          .filter((target) => target.adGroupId === adGroupId)
          .filter((target) => target.targetingClass !== 'negativeKeyword')
          .map((target) => target.targetingText)
      : previouslySelectedTargetEntityIds;

    // Fetch entity-level data.  This is either keyword or product data depending on which flow the user chose.
    const { searchRequests: apiRequest, searchRequestsOverridesWithAdditionalRequests } = buildSearchRequests(
      statePropertyName,
      requestContext,
      searchRequestsOverrides
    );

    if (!targetingTypeId) {
      targetingTypeId = AD_TARGETING_TYPE.KEYWORD_TARGETING;
    }

    apiRequest[0].id = 'adCampaignBuilder_targetEntities_searchTermLevelMetrics';
    apiRequest[0].name = 'adCampaignBuilder_targetEntities_searchTermLevelMetrics';

    apiRequest[0].conditions.rangeFilters = apiRequest[0].conditions.rangeFilters.filter(
      (x: any) => x.fieldName !== 'organicClicks'
    );
    apiRequest[0].conditions.termFilters = apiRequest[0].conditions.termFilters || ([] as TermFilter[]);

    const isEditing = getIsEditing();
    const entityIdFieldName = targetingTypeId === AD_TARGETING_TYPE.KEYWORD_TARGETING ? 'searchTerm' : 'stacklineSku';

    let bulkUploadedKeywordsAPIRequest: any[] | null = null;
    if (uploadedTargetKeywords && targetingTypeId === AD_TARGETING_TYPE.KEYWORD_TARGETING) {
      const keywordsCondition = {
        fieldName: entityIdFieldName,
        condition: 'should',
        values: uploadedTargetKeywords || []
      };

      // If the user has bulk-uploaded target keywords, we want to force metrics to get fetched for them as well as for
      // suggested keywords.
      bulkUploadedKeywordsAPIRequest = _cloneDeep(apiRequest);
      bulkUploadedKeywordsAPIRequest[0].id = 'adCampaignBuilder_targetEntities_searchTermLevelMetrics_bulkUploaded';
      bulkUploadedKeywordsAPIRequest[0].name = 'adCampaignBuilder_targetEntities_searchTermLevelMetrics_bulkUploaded';
      bulkUploadedKeywordsAPIRequest[0].pageSize = uploadedTargetKeywords.length;
      bulkUploadedKeywordsAPIRequest[0].conditions.termFilters = [
        keywordsCondition,
        {
          condition: 'must_not',
          fieldName: 'excludedSearchTerm',
          values: ['[other traffic]', '[Other Traffic]']
        }
      ];

      // We also want to avoid double-fetching them as recommended keywords
      apiRequest[0].conditions.termFilters.push({ ...keywordsCondition, condition: 'must_not' });
    }

    if (uploadedTargetKeywords && targetingTypeId === AD_TARGETING_TYPE.PRODUCT_TARGETING) {
      const keywordsCondition = {
        fieldName: 'retailerSku',
        condition: 'should',
        values: uploadedTargetKeywords || []
      };

      // If the user has bulk-uploaded target keywords, we want to force metrics to get fetched for them as well as for
      // suggested keywords.
      bulkUploadedKeywordsAPIRequest = _cloneDeep(apiRequest);
      bulkUploadedKeywordsAPIRequest[0].id = 'adCampaignBuilder_targetEntities_searchTermLevelMetrics_bulkUploaded';
      bulkUploadedKeywordsAPIRequest[0].name = 'adCampaignBuilder_targetEntities_searchTermLevelMetrics_bulkUploaded';
      bulkUploadedKeywordsAPIRequest[0].pageSize = uploadedTargetKeywords.length;
      bulkUploadedKeywordsAPIRequest[0].conditions.termFilters = [keywordsCondition];

      // We also want to avoid double-fetching them as recommended keywords
      apiRequest[0].conditions.termFilters.push({ ...keywordsCondition, condition: 'must_not' });
    }

    // If we are editing the campaign, we want to avoid showing keywords that are already in the campaign
    if (isEditing) {
      const cond = {
        fieldName: entityIdFieldName,
        condition: 'must_not',
        values: adTargetsForGroup
      };

      apiRequest[0].conditions.termFilters.push(cond);
      if (bulkUploadedKeywordsAPIRequest) {
        bulkUploadedKeywordsAPIRequest[0].conditions.termFilters.push(cond);
      }
    }

    const [statePropertyValue, bulkAPIStatePropertyValue] = await Promise.all([
      (async () => {
        const apiResponse = await requestEntityMetrics(appName, apiRequest, cancelToken);
        return parseEntityMetrics({
          apiRequest,
          apiResponse,
          statePropertyName,
          requestContext,
          searchRequestsOverrides: searchRequestsOverridesWithAdditionalRequests,
          allWeekIdsByRetailerId
        });
      })(),
      bulkUploadedKeywordsAPIRequest
        ? (async () => {
            const apiResponse = await requestEntityMetrics(appName, bulkUploadedKeywordsAPIRequest, cancelToken);
            return parseEntityMetrics({
              apiRequest: bulkUploadedKeywordsAPIRequest,
              apiResponse,
              statePropertyName,
              requestContext,
              searchRequestsOverrides: searchRequestsOverridesWithAdditionalRequests,
              allWeekIdsByRetailerId
            });
          })()
        : Promise.resolve(null)
    ]);

    const { groupByFieldName } = searchRequestsOverrides[0].aggregations[0];
    const metricsByEntityId: { [entityId: string]: any } & { [entityId: number]: any } = {};

    // Convert the fetched data into mapping by entity ID
    searchRequestsOverrides[0].aggregations[0].aggregationFields.forEach(
      ({ aggregateByFieldName }: { aggregateByFieldName: string }) => {
        const metricNameByGroupByField = `${aggregateByFieldName}_by_${groupByFieldName}`;

        if (bulkAPIStatePropertyValue) {
          // Add in entries for all keywords for which we don't have metrics.
          //
          // Time complexity here sucks but so do many other things in the world.
          (uploadedTargetKeywords || []).forEach((keyword) => {
            if (
              !bulkAPIStatePropertyValue[metricNameByGroupByField].data.find(
                ({ entity }: any) => entity.id === keyword || entity.retailerSku === keyword
              )
            ) {
              bulkAPIStatePropertyValue[metricNameByGroupByField].data.push({
                entity: { id: keyword, name: keyword },
                name: keyword,
                value: 0
              });
            }
          });
        }

        const allData = [
          ...(bulkAPIStatePropertyValue
            ? bulkAPIStatePropertyValue[metricNameByGroupByField].data.map(addProp('isBulkUploaded', true))
            : []),
          ...statePropertyValue[metricNameByGroupByField].data
        ];

        allData.forEach(({ entity, value, isBulkUploaded, startBid }: any) => {
          const { id, name, retailerSku } = entity;
          if (!metricsByEntityId[id]) {
            metricsByEntityId[id] = {
              entity,
              id,
              keyword: retailerSku ? `asinSameAs|${retailerSku}` : name,
              retailerSku,
              selected: bulkUploadedKeywordsAPIRequest ? isBulkUploaded : true,
              isBulkUploaded,
              startBid
            };
          }
          metricsByEntityId[id][`${aggregateByFieldName}BaseValue`] = value;
        });
      }
    );

    if (!featuredProductBrandIds) {
      console.warn('Missing `productBrandIds` in `fetchTargetEntities`');
    }
    const targetEntitiesAdditonalMetricsFetchPromises = [];
    targetEntitiesAdditonalMetricsFetchPromises.push(
      fetchTargetEntitiesBrandLevelTrafficMetrics(
        appName,
        allWeekIdsByRetailerId,
        apiRequest,
        cancelToken,
        metricsByEntityId,
        requestContext,
        statePropertyName,
        searchRequestsOverrides,
        searchRequestsOverridesWithAdditionalRequests,
        featuredProductBrandIds
      )
    );

    targetEntitiesAdditonalMetricsFetchPromises.push(
      fetchTargetEntitiesAdvertisingMetrics(
        appName,
        allWeekIdsByRetailerId,
        apiRequest,
        cancelToken,
        metricsByEntityId,
        requestContext,
        statePropertyName
      )
    );
    await Promise.all(targetEntitiesAdditonalMetricsFetchPromises);
    const metricNameByGroupByFieldDataKey = `${searchRequestsOverrides[0].aggregations[0].aggregationFields[0].aggregateByFieldName}_by_${groupByFieldName}`;

    const targetEntitiesMetrics = [
      ...(bulkAPIStatePropertyValue ? bulkAPIStatePropertyValue[metricNameByGroupByFieldDataKey].data : []),
      ...statePropertyValue[metricNameByGroupByFieldDataKey].data
    ];
    await fetchCategoryLevelTrafficMetrics(
      appName,
      allWeekIdsByRetailerId,
      apiRequest,
      cancelToken,
      metricsByEntityId,
      requestContext,
      statePropertyName,
      searchRequestsOverrides,
      searchRequestsOverridesWithAdditionalRequests,
      featuredProductCategoryIds,
      featuredProductSubCategoryIds
    );

    if (targetingTypeId === AD_TARGETING_TYPE.PRODUCT_TARGETING) {
      await fetchBrandLevelSalesMetrics(
        allWeekIdsByRetailerId,
        apiRequest,
        cancelToken,
        metricsByEntityId,
        requestContext,
        statePropertyName,
        featuredProductBrandIds
      );

      await fetchCategoryLevelSalesMetrics(
        allWeekIdsByRetailerId,
        apiRequest,
        cancelToken,
        metricsByEntityId,
        requestContext,
        statePropertyName
      );
    }

    const targetEntitiesList = targetEntitiesMetrics.map(({ entity: { id } }: { entity: Entity }) => ({
      ...metricsByEntityId[id],
      selected: bulkUploadedKeywordsAPIRequest ? metricsByEntityId[id].isBulkUploaded : true,
      matchType: 'exact'
    }));
    // Put selected items first in the list
    const sortedTargetEntitiesList = _orderBy(
      targetEntitiesList,
      [({ isBulkUploaded }) => (isBulkUploaded ? 1 : 0), ({ selected }) => (selected ? 1 : 0)],
      ['desc']
    );

    if (eventBus) {
      eventBus.emit('setSearchHeader', {
        isLoading: false,
        entity: { result: { apiRequest } }
      });
    }
    dispatch(setTargetEntities(sortedTargetEntitiesList, minimumBid));
  };

export const saveAdCampaign =
  (adCampaignBuilder: ReduxStore['adCampaignBuilder'], cancelToken?: CancelToken) =>
  async (_dispatch: (action: ReduxAction) => void, getState: () => ReduxStore) => {
    if (!adCampaignBuilder.platformId || !adCampaignBuilder.platformSettings || !adCampaignBuilder.campaignType) {
      return panic('Invalid `adCampaignsProduct` state: missing one or more top-level keys.');
    } else if (!adCampaignBuilder.platformSettings.entity) {
      return panic('Tried to save ad campign without `adCampaignBuilder.platformSettings.entity`.');
    }

    const { retailer } = getState();
    const {
      setup: { name: campaignName, startDate, endDate, portfolioId, businessUnitId = -1, strategyId },
      platformId,
      featured: { selectedFeaturedProducts, selectedFeaturedUPCs, storeUrl, landingType },
      target: {
        audiences,
        budget: { daily: budgetDaily },
        targetingTypeId,
        selectedTargetEntities,
        autoCampaignProducts,
        keywordList
      },
      creative: { headline, brandLogoAssetId, asins, videoMedias, creativeType, sbaLogo, brandName, clickUrl, mediaId },
      platformSettings: {
        entity: {
          id: entityId,
          beaconClientId,
          extendedAttributes: { beaconClientLoginId, entityIdUi }
        }
      },
      minBudget,
      minROAS,
      bidMultifiers
    } = adCampaignBuilder;

    const platform = getAdPlatform(platformId!)(store.getState() as any as ReduxStore)!;
    const {
      extendedAttributes: { currencyCode }
    } = platform;
    const creative = {
      brandName: '',
      brandLogoAssetId,
      headlineText: headline === null || headline === undefined || headline === '' ? headline : headline.trim(),
      retailerSkus: [],
      creativeType
    };

    const landingPage: {
      url?: string;
      asins?: string[];
    } = {};
    let {
      campaignType: { id: campaignType }
    } = adCampaignBuilder;
    campaignType = campaignType === 'sponsoredBrandsVideo' ? AD_CAMPAIGN_TYPE.SPONSORED_BRAND : campaignType;
    if (campaignType === AD_CAMPAIGN_TYPE.SPONSORED_BRAND) {
      const retailerSkus = selectedFeaturedProducts.map(({ retailerSku }) => retailerSku);

      if (creativeType === 'VIDEO' && videoMedias) {
        creative.videoMediaIds = [videoMedias[0].mediaCode];
        creative.retailerSkus = retailerSkus;
      } else if (landingType === 'Brand Store') {
        landingPage.url = storeUrl;
        creative.retailerSkus = retailerSkus;
      } else {
        landingPage.asins = retailerSkus;
        creative.retailerSkus = asins || [];
      }

      creative.brandName = _get(selectedFeaturedProducts, [0, 'brandName'], '');
    }
    const brandEntityId = entityIdUi;
    const startDateByTimezone = momentTz(startDate).tz(retailer.processInTimezone || UTC_TIMEZONE);
    const endDateByTimezone = endDate ? momentTz(endDate).tz(retailer.processInTimezone || UTC_TIMEZONE) : null;

    const adCampaign = {
      campaignName: campaignName.trim(),
      beaconClientId,
      platformType: platform.platformType,
      businessUnitId,
      startDate: startDateByTimezone.format('YYYY-MM-DD'),
      endDate: endDateByTimezone
        ? endDateByTimezone.format('YYYY-MM-DD')
        : retailer.id === '2' || retailer.id === '25'
        ? '9999-12-30'
        : null,
      adType: 'SEARCH', // TODO: this needs to retrieved
      extendedAttributes: {
        retailerId: parseInt(retailer.id, 10),
        entityId,
        entityIdApi: entityId,
        targetingType: targetingTypeId,
        portfolioId,
        portfolioIdApi: portfolioId,
        campaignType,
        campaignTypeApi: campaignType,
        beaconClientLoginId,
        automationAttributes: {
          strategyId,
          metricsThresholds: {
            budgetAmountMinimum: minBudget,
            roasMinimum: minROAS
          }
        },
        platformSpecificAttributes: {
          biddingStrategy: 'LEGACY',
          amsTargetingType: 'manual',
          brandEntityId,
          landingPage,
          creative
        },
        currentMonthBudgetSetting: {
          amount: budgetDaily,
          startDate: momentTz(new Date()).tz(retailer.processInTimezone || UTC_TIMEZONE),
          endDate: momentTz(new Date())
            .tz(retailer.processInTimezone || UTC_TIMEZONE)
            .endOf('month'),
          budgetType: 'daily'
        },
        ...(isInstacart(retailer.id) ? adCampaignBuilder.instacartParams : null)
      },
      adCampaignProducts: [] as any[],
      adTargets: null as any
    };
    const isSBA = _get(adCampaignBuilder, 'campaignType.settingId') === 'sba';
    const isWalmartVideo = _get(adCampaignBuilder, 'campaignType.settingId') === 'video';
    const lifetimeBudget = _get(adCampaignBuilder, 'target.budget.lifetimeBudget', 0);
    if (['2', '63', '25'].includes(retailer.id) && lifetimeBudget !== 0) {
      adCampaign.extendedAttributes.currentOtherBudgetSettings = [
        {
          amount: lifetimeBudget,
          budgetType: AD_BUDGET_TYPE.LIFETIME
        }
      ];
    }

    if (isSBA) {
      adCampaign.extendedAttributes.platformSpecificAttributes.brandName = brandName;
      adCampaign.extendedAttributes.platformSpecificAttributes.headline = headline;
      adCampaign.extendedAttributes.platformSpecificAttributes.brandLogoUrl = clickUrl;
      adCampaign.extendedAttributes.platformSpecificAttributes.imageLogoBase64 = sbaLogo;
    }

    if (isWalmartVideo) {
      if (mediaId) {
        adCampaign.adGroupMedia = { mediaId };
      } else {
        throw Error('Missing mediaId');
      }
    }

    if (retailer.id === '2' && !isSBA && !isWalmartVideo) {
      const placementBidMultipliersOpt: string[] =
        targetingTypeId === 'autoTargeting' ? ['Buy-Box', 'Search Ingrid', 'Home Page', 'Stock Up'] : ['Search Ingrid'];
      const newBidMultifiers = {
        placements: targetingTypeId === 'autoTargeting' ? [] : bidMultifiers.placements,
        placementBidMultipliers: bidMultifiers.placementBidMultipliers.filter((item) =>
          placementBidMultipliersOpt.includes(item.bidLocation)
        ),
        platformBidMultipliers: bidMultifiers.platformBidMultipliers
      };
      adCampaign.extendedAttributes.placements = newBidMultifiers.placements;
      adCampaign.extendedAttributes.placementBidMultipliers = newBidMultifiers.placementBidMultipliers;
      adCampaign.extendedAttributes.platformBidMultipliers = newBidMultifiers.platformBidMultipliers;
    }
    adCampaign.adCampaignProducts = selectedFeaturedProducts.map(({ retailerSku }: { retailerSku: string }) => {
      return {
        beaconClientId,
        platformType: platform.platformType,
        retailerId: parseInt(retailer.id, 10),
        retailerSku,
        status: 'enabled',
        startDate: startDateByTimezone,
        endDate: endDateByTimezone,
        extendedAttributes: {}
      };
    });

    if (campaignType === AD_CAMPAIGN_TYPE.SPONSORED_DISPLAY) {
      adCampaign.extendedAttributes.platformSpecificAttributes.tactic = 'T00020';
      if (targetingTypeId === 'audienceTargeting') {
        adCampaign.extendedAttributes.platformSpecificAttributes.tactic = 'T00030';
        // adCampaign.extendedAttributes.platformSpecificAttributes.audiences = audiences;
        adCampaign.adTargets = audiences.map((item) => {
          if (item.product === 'similarProduct') {
            return {
              bid: item.bid,
              expression: [
                {
                  type: 'views',
                  value: [
                    {
                      type: 'similarProduct'
                    },
                    {
                      type: 'lookback',
                      value: 30
                    }
                  ]
                }
              ]
            };
          } else if (item.product === 'exactProduct') {
            return {
              bid: item.bid,
              expression: [
                {
                  type: 'views',
                  value: [
                    {
                      type: 'exactProduct'
                    },
                    {
                      type: 'lookback',
                      value: 30
                    }
                  ]
                }
              ]
            };
          } else {
            return {
              bid: item.bid,
              expression: [
                {
                  type: 'views',
                  value: [
                    {
                      type: 'asinCategorySameAs',
                      value: item.category
                    }
                  ]
                }
              ]
            };
          }
        });
      }
    }

    if (['2', '63', '25'].includes(retailer.id)) {
      adCampaign.adCampaignProducts = selectedFeaturedUPCs.map((item) => {
        return {
          beaconClientId,
          platformType: platform.platformType,
          retailerId: parseInt(retailer.id, 10),
          retailerSku: item,
          status: 'enabled',
          startDate: startDateByTimezone,
          endDate: endDateByTimezone
        };
      });
    }

    if (targetingTypeId === 'autoTargeting') {
      adCampaign.adCampaignProducts = autoCampaignProducts.map((item) => {
        return {
          beaconClientId,
          platformType: platform.platformType,
          retailerId: parseInt(retailer.id, 10),
          retailerSku: item.id,
          status: 'enabled',
          startDate: startDateByTimezone,
          endDate: endDateByTimezone,
          extendedAttributes: {
            bid: item.bid
          }
        };
      });
    } else if (targetingTypeId === AD_TARGETING_TYPE.PRODUCT_TARGETING && !!selectedTargetEntities) {
      adCampaign.adTargets = selectedTargetEntities
        .filter(prop('selected'))
        .map(({ entity: { retailerSku, stacklineSku }, startBid: cpcBid, status, ...keyword }: any) => ({
          beaconClientId,
          platformType: platform.platformType,
          targetingClass: AD_TARGETING_CLASS.TARGET,
          targetingText: `asinSameAs|${retailerSku}`,
          extendedAttributes: {
            status: Option.of(status)
              .map((s: string) => s.toUpperCase())
              .getOrElse('ENABLED'),
            cpcMinBidAmount: _isNil(cpcBid)
              ? Math.round(keyword.cpcMinBidAmount * 1e2) / 1e2
              : Math.round(cpcBid * 1e2) / 1e2, // need to round to 2 decimals?
            cpcMaxBidAmount: _isNil(cpcBid)
              ? Math.round(keyword.cpcMaxBidAmount * 1e2) / 1e2
              : Math.round(cpcBid * 1e2) / 1e2, // need to round to 2
            cpcBidCurrency: currencyCode,
            productMetaData: {
              retailerId: parseInt(retailer.id, 10),
              retailerSku,
              stacklineSku
            }
          }
        }));
    } else if (keywordList) {
      const flattenKeywordList: any[] = [];
      keywordList.forEach((keywordType: any[]) => {
        keywordType.forEach((keyword: { [key: string]: any }) => {
          if (keyword.selected) {
            flattenKeywordList.push(keyword);
          }
        });
      });

      // Keyword Targeting
      adCampaign.adTargets = flattenKeywordList.map(({ keyword, matchType, cpcMaxBidAmount }) => ({
        beaconClientId,
        platformType: platform.platformType,
        targetingClass: AD_TARGETING_CLASS.KEYWORD,
        targetingText: keyword,
        extendedAttributes: {
          status: 'ENABLED',
          cpcMinBidAmount: cpcMaxBidAmount, // yes, use cpcMaxBidAmount for cpcMinBidAmount for now.
          cpcMaxBidAmount,
          cpcBidCurrency: currencyCode,
          keywordMetaData: {
            matchType
          }
        }
      }));
    }

    if (adCampaignBuilder.isEditing) {
      console.warn(adCampaign);
      return null;
    } else {
      return axios.post('/apiAdManager/adCampaigns', adCampaign, cancelToken ? { cancelToken } : undefined);
    }
  };

export const resetAdCampaignBuilderReduxState = () => async (dispatch: (action: ReduxAction) => void) => {
  const newState = _merge({}, adCampaignBuilderInitialState(), { isEditing: false, hasNoData: true });
  await dispatch(replaceState(newState));
};

export const rehydrateReduxFromAdCampaign =
  (campaignId: string) => async (dispatch: (action: ReduxAction) => void, getState: () => ReduxStore) => {
    const bail = () => {
      dispatch(replaceState(_merge({}, adCampaignBuilderInitialState(), { isEditing: true, hasNoData: true })));
    };

    // First we have to fetch the full metadata for the campaign that we're loading
    const res = await axios.get(`/apiAdManager/adCampaigns/${campaignId}`);
    if (res.status === 404) {
      console.warn(`No metadata available for campaign ${campaignId}`);
      bail();
      return;
    }

    const adCampaignMetadata: AdManagerAdCampaignMetadata | null | undefined = res.data;
    if (!adCampaignMetadata) {
      console.warn(`Fetching metadata for campaign ${campaignId} failed; empty response.`);
      bail();
      return;
    }

    if (!adCampaignMetadata.extendedAttributes.targetingType) {
      throw panic('Missing `adCampaignMetadata.extendedAttributes.targetingType` on loaded ad campaign metadata');
    }

    if ((adCampaignMetadata.extendedAttributes.targetingType as any) === 'manual') {
      throw panic(`Invalid targeting type of "${adCampaignMetadata.extendedAttributes.targetingType}" provided.`);
    }

    const entityForCampaign = Option.of(adCampaignMetadata.extendedAttributes.entityId)
      .flatMap((entityId) => Option.of(getState().adEntities.find(propEq('id', entityId))))
      .orNull();

    // TODO pending campaigns with target products -- see adManager.ts AdTargetProductItem
    const previouslySelectedTargetEntityIds = Option.of(adCampaignMetadata.adTargets)
      .map((adTargets) => adTargets.map((entity) => entity.targetingText))
      .getOrElseL(() => panic('No targets provided for campaign.'));

    // Build up a bunch of pre-filled Redux state with the retrieved campaign metadata
    const newAdCampaignBuilderState = _merge({}, adCampaignBuilderInitialState, {
      isEditing: true,
      platformId: adCampaignMetadata.platformType,
      platformSettings: {
        entity: entityForCampaign
      },
      campaignType: {
        platformType: adCampaignMetadata.platformType,
        settingType: 'campaignType',
        settingId: adCampaignMetadata.extendedAttributes.campaignType,
        extendedAttributes: null
      },
      setup: {
        portfolioId: adCampaignMetadata.extendedAttributes.portfolioId,
        businessUnitId: adCampaignMetadata.businessUnitId,
        strategyId: _get(adCampaignMetadata, 'automationAttributes.strategyId'),
        name: adCampaignMetadata.campaignName,
        startDate: adCampaignMetadata.startDate,
        endDate: adCampaignMetadata.endDate
      },
      featured: {
        existingFeaturedProductIds: adCampaignMetadata.adCampaignProducts.map(
          (product) => product.extendedAttributes.productMetaData.stacklineSku
        ),
        selectedFeaturedProducts: adCampaignMetadata.adCampaignProducts.map(({ retailerSku }) => ({
          retailerSku
        })),
        featuredProductBrandIds: Array.from(
          new Set(
            adCampaignMetadata.adCampaignProducts.map((product) => product.extendedAttributes.productMetaData.brandId)
          )
        ),
        featuredProductCategoryIds: Array.from(
          new Set(
            adCampaignMetadata.adCampaignProducts.map(
              (product) => product.extendedAttributes.productMetaData.categoryId
            )
          )
        )
      },
      target: {
        previouslySelectedTargetEntityIds,
        targetingTypeId: adCampaignMetadata.extendedAttributes.targetingType,
        selectedTargetEntities: ((adCampaignMetadata.adTargets || []) as IAdTarget[]).map(addProp('selected', true))
      }
    });

    // Replace the full ad campaign builder state with this new one that we've built up
    dispatch(replaceState(newAdCampaignBuilderState));
  };
