/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'qs';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { ValueOf } from 'sl-api-connector/types';

import { store } from 'src/main';
import * as adCampaignBuilderOperations from 'src/store/modules/adManager/adCampaignBuilder/operations';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { CAMPAIGN_TYPES } from 'src/store/modules/adManager/adCampaignBuilder/types';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import ReduxStore from 'src/types/store/reduxStore';
import { panic } from 'src/utils/mixpanel';
import { getAppStage } from 'src/utils/app';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { BulkAdjustBid } from 'src/components/AdManager/TargetingPage';
import { EventBus } from 'src/types/utils';
import { useUpdateQueryParams } from 'src/utils/Hooks';
import { AD_MEDIA_MANAGEMENT_URL } from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdMediaManagementPageLayout';

function getURLParam(paramName: string): string | null {
  return new URLSearchParams(window.location.search).get(paramName);
}

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    position: 'absolute',
    right: 21,
    top: 83,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: 14,
    width: 120,
    cursor: 'pointer'
  }
};

const getStepsForCampaignType = (
  campaignType: ValueOf<typeof CAMPAIGN_TYPES>,
  sponsoredBrandLandingType: string,
  retailer: ReduxStore['retailer'],
  shouldSkipCampaignTypeSelect: boolean,
  shouldSkipTargetTypeSelect: boolean
) => {
  const NO_PORTFOLIO_SELECTION = ['63'].includes(retailer.id);
  const NO_PROJECTION = ['2', '63', '25'].includes(retailer.id);
  const NO_ADDITIONAL_SETTINGS = retailer.id !== '2' || retailer.id === '25';
  if (campaignType === CAMPAIGN_TYPES.SponsoredProducts) {
    return [
      // flow for Sponsored Product: Product
      'platformSelect',
      ...(shouldSkipCampaignTypeSelect ? [] : ['setupType']),
      'setupDetail',
      ...(NO_PORTFOLIO_SELECTION ? [] : ['setupStrategy']),
      'featured',
      ...(shouldSkipTargetTypeSelect ? [] : ['targetType']),
      'targetDetail',
      ...(NO_PROJECTION ? [] : ['projections']),
      ...(NO_PROJECTION ? [] : ['market']),
      ...(NO_ADDITIONAL_SETTINGS ? [] : ['additionalSettings']),
      'confirmation'
    ];
  } else if (campaignType === CAMPAIGN_TYPES.SponsoredBrandsVideo) {
    return [
      'platformSelect',
      'setupType',
      'setupDetail',
      ...(NO_PORTFOLIO_SELECTION ? [] : ['setupStrategy']),
      'featured',
      'videoUpload',
      ...(shouldSkipTargetTypeSelect ? [] : ['targetType']),
      'targetDetail',
      'projections',
      ...(NO_PORTFOLIO_SELECTION ? [] : ['market']),
      'confirmation'
    ];
  } else if (campaignType === CAMPAIGN_TYPES.SponsoredBrands) {
    if (sponsoredBrandLandingType === 'Brand Store') {
      return [
        // flow for Sponsored Brands: Keyword
        'platformSelect',
        ...(shouldSkipCampaignTypeSelect ? [] : ['setupType']),
        'setupDetail',
        ...(NO_PORTFOLIO_SELECTION ? [] : ['setupStrategy']),
        'featuredLanding',
        'featured',
        'creative',
        'creativeHeadline',
        'targetDetail',
        ...(NO_PROJECTION ? [] : ['projections']),
        ...(NO_PROJECTION ? [] : ['market']),
        'confirmation'
      ];
    } else {
      return [
        // flow for Sponsored Brands: Keyword
        'platformSelect',
        ...(shouldSkipCampaignTypeSelect ? [] : ['setupType']),
        'setupDetail',
        ...(NO_PORTFOLIO_SELECTION ? [] : ['setupStrategy']),
        'featuredLanding',
        'creative',
        'creativeHeadline',
        'targetDetail',
        ...(NO_PROJECTION ? [] : ['projections']),
        ...(NO_PROJECTION ? [] : ['market']),
        'confirmation'
      ];
    }
  } else if (campaignType === CAMPAIGN_TYPES.SponsoredDisplay) {
    return [
      // flow for Sponsored Display
      'platformSelect',
      ...(shouldSkipCampaignTypeSelect ? [] : ['setupType']),
      'setupDetail',
      ...(NO_PORTFOLIO_SELECTION ? [] : ['setupStrategy']),
      'featured',
      // 'creativeHeadline',
      ...(shouldSkipTargetTypeSelect ? [] : ['targetType']),
      'targetDetail',
      'projections',
      ...(NO_PORTFOLIO_SELECTION ? [] : ['market']),
      ...(NO_ADDITIONAL_SETTINGS ? [] : ['additionalSettings']),
      'confirmation'
    ];
  } else if (campaignType === 'sba') {
    return [
      // flow for Sponsored Brands: Keyword
      'platformSelect',
      ...(shouldSkipCampaignTypeSelect ? [] : ['setupType']),
      'setupDetail',
      ...(NO_PORTFOLIO_SELECTION ? [] : ['setupStrategy']),
      'featured',
      ...(shouldSkipTargetTypeSelect ? [] : ['targetType']),
      'targetDetail',
      'creativeHeadline',
      'confirmation'
    ];
  } else if (campaignType === 'video') {
    // flow for Walmart Sponsored VIdeos
    return [
      'platformSelect',
      'setupType',
      'setupDetail',
      ...(NO_PORTFOLIO_SELECTION ? [] : ['setupStrategy']),
      'featured',
      'walmartVideo',
      ...(shouldSkipTargetTypeSelect ? [] : ['targetType']),
      'targetDetail',
      'confirmation'
    ];
  }
  return undefined;
};

const getPreAndNextSubtab = (
  campaignType: ValueOf<typeof CAMPAIGN_TYPES>,
  targetingType: string,
  subtab: string,
  sponsoredBrandLandingType: string,
  retailer: ReduxStore['retailer'],
  shouldSkipCampaignTypeSelect: boolean,
  shouldSkipTargetTypeSelect: boolean,
  customFlow?: string[]
) => {
  let flow = getStepsForCampaignType(
    campaignType,
    sponsoredBrandLandingType,
    retailer,
    shouldSkipCampaignTypeSelect,
    shouldSkipTargetTypeSelect
  );
  if (customFlow) {
    flow = customFlow;
  }
  if (!flow) {
    return panic(`Invalid campaign type provided: "${campaignType}"`);
  }
  if (targetingType === 'keywordTargeting') {
    flow.splice(flow.indexOf('targetDetail') + 1, 0, 'targetMatch');
  }
  if (targetingType === 'audienceTargeting') {
    flow = ['platformSelect', 'setupType', 'setupDetail', 'featured', 'targetType', 'audienceSettings', 'confirmation'];
  }
  const idx = flow.indexOf(subtab);
  return { prevSubtab: flow[idx - 1], nextSubtab: flow[idx + 1] };
};

export const SectionWrapper: React.FC<{
  header?: React.ReactNode;
  subheader?: React.ReactNode;
  layer?: number;
}> = ({ header, subheader, children, layer = 0 }) => {
  if (layer > 0) {
    return (
      <div className="ad-manager-section">
        <SectionWrapper header={header} subheader={subheader} layer={layer - 1}>
          {children}
        </SectionWrapper>
      </div>
    );
  }

  return (
    <div className="ad-manager-section">
      {header ? <h2 className="ad-manager-header">{header}</h2> : null}
      {subheader ? <div className="ad-manager-subheader">{subheader}</div> : null}
      <div className="underline" />
      {children}
    </div>
  );
};

export const buildSubtabLink = (subtab: string) => {
  const curParams = queryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const newSearchString = queryString.stringify({ ...curParams, subtab });
  return `?${newSearchString}`;
};

const mapStateToProps = ({ adCampaignBuilder, adPlatformSettings, entityService, retailer }: ReduxStore) => ({
  adPlatformSettings,
  adCampaignBuilder,
  entityService,
  retailer
});

type OperationButtonsInnerProps = {
  openDialog?: boolean;
  dialogTitle?: string;
  dialogContent?: string;
  subtab: string;
  onBeforeLeave?: (isNext: boolean) => boolean | Promise<boolean>;
  canContinue?: boolean;
  onSubmit?: (isNext: boolean) => void | Promise<void>;
  customFlow?: string[];
  isLoading?: boolean;
  eventBus?: EventBus;
  showBuldBidUpdate?: boolean;
} & ReturnType<typeof mapStateToProps> &
  RouteComponentProps;

const OperationButtonsInner: React.FC<OperationButtonsInnerProps> = ({
  openDialog,
  dialogTitle,
  dialogContent,
  adPlatformSettings,
  adCampaignBuilder,
  subtab,
  onBeforeLeave,
  canContinue = true,
  history,
  location,
  onSubmit,
  entityService: { mainEntity },
  customFlow,
  isLoading,
  eventBus,
  showBuldBidUpdate,
  retailer
}) => {
  const [open, setOpen] = React.useState(false);
  const updateQuery = useUpdateQueryParams(history);

  const campaignType = _get(adCampaignBuilder, 'campaignType.settingId', 'sponsoredProducts');
  const targetingType = _get(adCampaignBuilder, 'target.targetingTypeId', '');
  const sponsoredBrandLandingType = _get(adCampaignBuilder, ['featured', 'landingType'], '');
  const queryParams = queryString.parse(location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: 100
  });
  const isEditing = queryParams.tab === 'adManager';
  const campaignTypeOptions = adPlatformSettings.filter((item) => item.settingType === 'campaignType');

  const adTargetingTypes = adPlatformSettings.filter((item) => item.settingType === 'targetingType');
  const isSponsoredDisplay =
    _get(adCampaignBuilder, 'campaignType.settingId', 'sponsoredProducts') === 'sponsoredDisplay';
  const isSBV = _get(adCampaignBuilder, 'campaignType.settingId', 'sponsoredProducts') === 'sba';
  const isSBA = _get(adCampaignBuilder, 'campaignType.settingId') === 'sba';
  const isWalmartVideo = _get(adCampaignBuilder, 'campaignType.settingId') === 'video';
  const isDev = getAppStage() === 'dev';
  const targetingTypes = adTargetingTypes.filter((type) => {
    if (isSponsoredDisplay) {
      return type.id === 'productTargeting' || (isDev && type.id === 'audienceTargeting');
    } else if (isSBV || isSBA || isWalmartVideo) {
      return type.id === 'keywordTargeting';
    } else {
      return type.id !== 'audienceTargeting';
    }
  });
  const shouldSkipCampaignTypeSelect = campaignTypeOptions.length === 1;
  const shouldSkipTargetTypeSelect = targetingTypes.length === 1;

  const sponsoredBrandVideo = _get(adCampaignBuilder, ['creative', 'creativeType']);
  const { prevSubtab, nextSubtab } = getPreAndNextSubtab(
    campaignType,
    targetingType,
    subtab,
    sponsoredBrandVideo || sponsoredBrandLandingType,
    retailer,
    shouldSkipCampaignTypeSelect,
    shouldSkipTargetTypeSelect,
    customFlow
  );

  useEffect(() => {
    if (campaignTypeOptions.length === 1 && !adCampaignBuilder.campaignType) {
      store.dispatch(adCampaignBuilderActions.setCampaignType({ ...campaignTypeOptions[0] }));
    }
  }, [campaignTypeOptions, adCampaignBuilder.campaignType]);

  useEffect(() => {
    if (targetingTypes.length === 1 && !adCampaignBuilder.target.targetingTypeId) {
      store.dispatch(adCampaignBuilderActions.setTargetTypeId(targetingTypes[0].id));
    }
  }, [targetingTypes, adCampaignBuilder.target.targetingTypeId]);

  const isAwaitingOnBeforeLeave = useRef(false);

  if (isEditing) {
    return (
      <div style={styles.root}>
        <LargeMuiButton
          style={{ ...styles.button, marginRight: 20 }}
          onClick={() =>
            store
              .dispatch(adCampaignBuilderOperations.saveAdCampaign(adCampaignBuilder))
              .then((_res: any) => {
                history.push(`/adCampaign/${mainEntity!.id}?tab=adManager&subtab=keyMetrics`);
              })
              .catch((err: any) => {
                console.error(err);
                // eslint-disable-next-line no-alert
                alert('Error updating campaign; server failed to process the request.');
              })
          }
        >
          Save
        </LargeMuiButton>
      </div>
    );
  }

  const nextButtonDisabled = isLoading || !canContinue;

  return (
    <div style={styles.root}>
      {showBuldBidUpdate && (
        <span style={{ marginTop: 14 }}>
          <BulkAdjustBid eventBus={eventBus} retailer={retailer} />
        </span>
      )}
      {prevSubtab ? (
        <LargeMuiButton
          secondary
          style={{ ...styles.button, marginRight: 20 }}
          onClick={async () => {
            // Only allow one `onBeforeLeave` handler to run at once
            if (isAwaitingOnBeforeLeave.current) {
              return;
            }

            isAwaitingOnBeforeLeave.current = true;
            let canLeave = true;
            if (onBeforeLeave) {
              canLeave = await onBeforeLeave(false);
            }
            isAwaitingOnBeforeLeave.current = false;
            if (canLeave) {
              history.push(buildSubtabLink(prevSubtab));
            }
          }}
        >
          BACK
        </LargeMuiButton>
      ) : null}

      {nextSubtab ? (
        <LargeMuiButton
          disabled={nextButtonDisabled}
          style={{ ...styles.button, cursor: nextButtonDisabled ? 'pointer' : undefined }}
          onClick={async () => {
            // Only allow one `onBeforeLeave` handler to run at once
            if (openDialog) {
              setOpen(true);
            } else {
              if (isAwaitingOnBeforeLeave.current) {
                return;
              }

              isAwaitingOnBeforeLeave.current = true;
              let canLeave = true;
              if (onBeforeLeave) {
                canLeave = await onBeforeLeave(true);
              }
              isAwaitingOnBeforeLeave.current = false;
              if (canLeave) {
                const isMediaManageRedirect = getURLParam('mediaManagementActionType');
                if (isMediaManageRedirect) {
                  // add redirect
                  updateQuery({
                    additionalParams: {
                      tab: AD_MEDIA_MANAGEMENT_URL,
                      subtab: null
                    }
                  });
                } else {
                  history.push(buildSubtabLink(nextSubtab));
                }
              }
            }
          }}
        >
          {isLoading ? 'LOADING...' : 'NEXT'}
        </LargeMuiButton>
      ) : null}
      {onSubmit ? (
        <LargeMuiButton
          style={styles.button}
          onClick={async () => {
            if (isAwaitingOnBeforeLeave.current) {
              return;
            }

            isAwaitingOnBeforeLeave.current = true;
            if (onSubmit) {
              await onSubmit(true);
            }
            isAwaitingOnBeforeLeave.current = false;

            history.push(buildSubtabLink('submit'));
          }}
        >
          {isLoading ? 'SUBMITTING...' : 'SUBMIT'}
        </LargeMuiButton>
      ) : null}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <div>{dialogContent}</div>
        </DialogContent>
        <DialogActions
          id="alert-dialog-action"
          style={{ paddingTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <LargeMuiButton
            style={{ ...styles.button }}
            onClick={async () => {
              // Only allow one `onBeforeLeave` handler to run at once
              if (isAwaitingOnBeforeLeave.current) {
                return;
              }

              isAwaitingOnBeforeLeave.current = true;
              let canLeave = true;
              if (onBeforeLeave) {
                canLeave = await onBeforeLeave(true);
              }
              isAwaitingOnBeforeLeave.current = false;
              if (canLeave) {
                history.push(buildSubtabLink(nextSubtab));
              }
            }}
          >
            Confirm
          </LargeMuiButton>
          <LargeMuiButton secondary style={{ ...styles.button, marginRight: 20 }} onClick={() => setOpen(false)}>
            Cancel
          </LargeMuiButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const OperationButtons = connect(mapStateToProps)(withRouter(OperationButtonsInner));
