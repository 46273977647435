import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { SlInput, StacklineTheme, useStacklineTheme } from '@stackline/ui';
import { Box, FormControlLabel, Radio } from '@mui/material';
import { BluePlus, DocumentIcon, CheckCircle, SmallCancelIcon, CloseIcon } from 'src/components/SvgIcons';
import { parseUploadedItems, validateFile } from 'src/components/Search/AdvancedSearch/BulkUpload/BulkUploadDialog';
import { getQueryParamValue } from 'src/utils/app';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

// Define the props for StyledInput
interface DialogProps {
  stacklineTheme: StacklineTheme;
}

const GenericStyledDialogue = styled(Dialog)(({ stacklineTheme }: DialogProps) => ({
  '& .MuiPaper-root': {
    width: '757px',
    height: '631px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px',
    textarea: {
      color: stacklineTheme.colors.primary
    }
  }
}));

enum ToggleOption {
  Include = 'include',
  Exclude = 'exclude',
  IncludePhrase = 'includePhrase',
  IncludeExact = 'includeExact',
  ExcludeKeyword = 'excludeKeyword'
}

const IncludeExcludeRadioButtons = ({
  setToggleOption,
  toggleOption,
  options
}: {
  setToggleOption: (o: ToggleOption) => void;
  options: ToggleOption[];
  toggleOption: ToggleOption;
}) => {
  const theme = useStacklineTheme();

  return (
    <Flex gap="lg">
      {options.map((option) => (
        <FormControlLabel
          key={option.key}
          title={option.label}
          sx={{
            color: theme.colors.primary,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginLeft: '0px',
            marginRight: '0px',
            gap: '8px',
            '& .MuiTypography-root': {
              fontWeight: '500px',
              fontSize: '12px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }
          }}
          checked={toggleOption === option.key}
          label={option.label}
          value={option.key}
          control={
            <Radio
              disableRipple
              disableTouchRipple
              sx={{
                svg: {
                  height: '15px',
                  width: '15px'
                },
                '&, &.Mui-checked': {
                  color: theme.colors.primary,
                  padding: '0px'
                }
              }}
            />
          }
          onClick={(event) => {
            event.preventDefault(); // Prevent event firing twice from label click
            setToggleOption(option.key);
          }}
        />
      ))}
    </Flex>
  );
};

const MAX_INPUT = 500;
interface ToggleFilterKey {
  filterKey: string;
  termFilterKey: string;
}

const toggleFilterKeys: { [key in ToggleOption]: ToggleFilterKey } = {
  [ToggleOption.Include]: { filterKey: 'keywords', termFilterKey: 'keyword' },
  [ToggleOption.Exclude]: { filterKey: 'excludedKeywords', termFilterKey: 'excludedKeyword' },
  [ToggleOption.IncludePhrase]: { filterKey: 'searchTermsFuzzy', termFilterKey: 'searchTermFuzzy' },
  [ToggleOption.IncludeExact]: { filterKey: 'searchTerms', termFilterKey: 'searchTerm' },
  [ToggleOption.ExcludeKeyword]: { filterKey: 'excludedSearchTerms', termFilterKey: 'excludedSearchTerm' }
};

const SegmentBulkUploadInner = ({
  handleTermFilterChange,
  currentFilters,
  handleClose
}: {
  handleTermFilterChange: (obj: any, filterOptions?: any) => void;
  currentFilters: any; // Untyped from JS file
  handleClose: () => void;
}) => {
  const theme = useStacklineTheme();
  const isKeywordUpload = getQueryParamValue('entityType') === 'searchtermlist';
  const [toggleOption, setToggleOption] = useState(isKeywordUpload ? ToggleOption.IncludePhrase : ToggleOption.Include);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadError, setUploadError] = useState(false);
  const [input, setInput] = useState('');
  const inputCount = input.split('\n').filter(Boolean).length;

  const options = isKeywordUpload
    ? [
        { label: 'Include - Phrase', key: ToggleOption.IncludePhrase },
        { label: 'Include - Exact', key: ToggleOption.IncludeExact },
        { label: 'Exclude', key: ToggleOption.ExcludeKeyword }
      ]
    : [
        { label: 'Include', key: ToggleOption.Include },
        { label: 'Exclude', key: ToggleOption.Exclude }
      ];

  const handleInputChange = (i: string) => {
    const formattedInput = i.replaceAll(' ', '\n');
    const formattedSlicedInput = formattedInput.split('\n').slice(0, MAX_INPUT).join('\n');
    setInput(formattedSlicedInput);
  };

  const handleSave = async () => {
    try {
      const newItems = await parseUploadedItems(input, uploadedFile);
      const { filterKey, termFilterKey } = toggleFilterKeys[toggleOption];
      const existingTerms = currentFilters[filterKey];

      handleTermFilterChange({
        key: termFilterKey,
        values: [...existingTerms, ...newItems.map((item) => ({ i: item }))]
      });
    } catch (err) {
      setUploadError(true);
    }
  };

  const uploadMessage = uploadError
    ? 'The uploaded file does not meet the required specifications. Please try again.'
    : 'Upload complete. Your file was successfully uploaded.';

  const bulkUploadType = isKeywordUpload ? 'Keywords' : 'Retailer SKUs';

  return (
    <Flex
      height="100%"
      flexDirection="column"
      paddingX={theme.spacing.xl}
      paddingTop={theme.spacing.mdl}
      paddingBottom="38px"
    >
      <Text variant="h4">Bulk {isKeywordUpload ? 'Keyword' : 'SKU'} Upload</Text>
      <Box position="absolute" left="699px">
        <button onClick={handleClose} style={{ border: 'none', background: 'none' }}>
          <CloseIcon style={{ width: theme.spacing.mdl, height: theme.spacing.mdl }} />
        </button>
      </Box>

      <Flex paddingTop={theme.spacing.md}>
        <Text variant="body2">
          Paste a list of {bulkUploadType} into the dialog box below or upload a CSV file containing a single list of{' '}
          {bulkUploadType}. Files should contain {bulkUploadType} only, one per line, with no commas or other
          formatting.
        </Text>
      </Flex>
      <Flex paddingTop={theme.spacing.sm}>
        <Text variant="subtitle2">Note:&nbsp;</Text>
        <Text variant="body2">There is maximum limit of 500 {bulkUploadType}.</Text>
      </Flex>
      <Flex paddingTop="14px">
        <IncludeExcludeRadioButtons options={options} toggleOption={toggleOption} setToggleOption={setToggleOption} />
      </Flex>
      <Flex paddingTop="22px" flexDirection="column" width="100%">
        <Flex paddingBottom={theme.spacing.md}>
          <Text variant="subtitle1">Paste {bulkUploadType}</Text>
        </Flex>
        <SlInput
          width="100%"
          isTextArea
          handleChange={handleInputChange}
          value={input || ''}
          placeholder={`Paste ${bulkUploadType} here`}
        />
        <Flex justifyContent="flex-end" paddingTop={theme.spacing.sm}>
          <Text
            sx={{
              fontSize: '10px',
              fontWeight: 500
            }}
          >
            {MAX_INPUT - inputCount} {bulkUploadType} remaining
          </Text>
        </Flex>

        <Flex flexDirection="column" marginTop={theme.spacing.mdl}>
          <Text variant="subtitle1">Upload CSV file</Text>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            marginTop={theme.spacing.md}
            marginBottom={theme.spacing.mdl}
            border={`1px solid ${theme.colors.primaryGray}`}
            height="160px"
            borderRadius={theme.spacing.sm}
          >
            <button style={{ background: 'none', border: 'none' }}>
              <label htmlFor="file-input">
                {uploadedFile ? (
                  <Flex alignItems="center" gap="sm">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="24px"
                      height="24px"
                      borderRadius="50px"
                      sx={{ backgroundColor: theme.colors.primary }}
                    >
                      <DocumentIcon style={{ fill: '#ffffff', width: '18px', height: '18px' }} />
                    </Box>
                    <Text variant="body2">{uploadedFile.name}</Text>
                  </Flex>
                ) : (
                  <BluePlus role="button" style={{ width: theme.spacing.mdl, height: theme.spacing.mdl }} />
                )}
              </label>
              <input
                onChange={(evt) => {
                  const file = evt.target.files[0];
                  const errMsg = validateFile(file);
                  if (errMsg) {
                    setUploadError(true);
                  } else {
                    setUploadedFile(file);
                  }
                }}
                style={{ display: 'none' }}
                id="file-input"
                type="file"
                accept=".csv,.txt"
              />
            </button>
          </Box>
          <Flex height="32px" alignItems="center" justifyContent="flex-end" position="relative">
            {!uploadedFile && !uploadError ? null : (
              <Box position="absolute" left={uploadError ? '100px' : '170px'} top="-27%">
                <Flex gap="xxs" alignItems="center">
                  {uploadError ? (
                    <SmallCancelIcon style={{ fill: theme.colors.error, width: '24px' }} />
                  ) : uploadedFile ? (
                    <CheckCircle style={{ fill: theme.colors.success, width: '24px' }} />
                  ) : null}

                  <Text color={uploadError ? 'error' : 'success'} variant="subtitle3">
                    {uploadMessage}
                  </Text>
                </Flex>
              </Box>
            )}

            <Box width="auto">
              <SlButton
                onClick={handleSave}
                disabled={(inputCount <= 0 || inputCount > MAX_INPUT) && !uploadedFile}
                variant="contained"
              >
                Save
              </SlButton>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const SegmentBulkUploadModal = ({
  open,
  handleClose,
  handleTermFilterChange,
  currentFilters
}: {
  open: boolean;
  handleClose: () => void;
  handleTermFilterChange: (obj: any, filterOptions?: any) => void; // untyped from JS file
  currentFilters: any;
}) => {
  const theme = useStacklineTheme();
  return (
    <GenericStyledDialogue stacklineTheme={theme} open={open} onClose={handleClose}>
      <SegmentBulkUploadInner
        handleClose={handleClose}
        handleTermFilterChange={handleTermFilterChange}
        currentFilters={currentFilters}
      />
    </GenericStyledDialogue>
  );
};
