import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as BusProvider } from 'react-bus';
import _get from 'lodash/get';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import RedBox from 'redbox-react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import createStore from './store/createStore';
import './styles/layout.scss';
import App from './components/App';
import { buildMuiTheme } from 'src/muiTheme';
import { SplitSdk, SplitFactory } from '@splitsoftware/splitio-react';
import queryClient from './queryClient';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider } from 'react-query';
import { StacklineThemeProvider } from '@stackline/ui';
import RouterPropsProvider from './providers/RouterPropsProvider';
import { LicenseInfo } from '@mui/x-license-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import ExportContextProvider from 'src/providers/ExportContextProvider';
import SnackbarProvider from './providers/SnackbarProvider';
import PopupProvider from 'src/providers/PopupProvider';
import UserProfileImageProvider from './providers/UserProfileImageProvider';
import NotificationProvider from 'src/providers/NotificationProvider';
import { BulkAdjustmentProvider } from './components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkAdjustmentProvider';
import { PollingContextProvider } from 'src/providers/PollingContextProvider';

// ========================================================
// A/B Test Provider Instantiation (Split.io)
// ========================================================
export const factory = SplitSdk({
  core: {
    authorizationKey: 'fm4ur66cflc3lt0jjrh6j3olsu0iebp4ji1j',
    key: 'key'
  },
  startup: {
    requestTimeoutBeforeReady: 5,
    readyTimeout: 5
  },
  debug: __DEV__ ? 'WARN' : 'ERROR'
});

// ========================================================
// Store Instantiation
// ========================================================
export const store = createStore();

store.subscribe(() => {
  store.getState();
});

// ========================================================
// MUI X License
// ========================================================
LicenseInfo.setLicenseKey(
  'cf5cbed7d3487f7270e48f115ee63a45T1JERVI6Mzk5MjEsRVhQSVJZPTE2NzkxNzQyMzcwMDAsS0VZVkVSU0lPTj0x'
);

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root');

let render = () => {
  ReactDOM.render(
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          <BusProvider>
            <SplitFactory factory={factory}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={buildMuiTheme()}>
                  <StacklineThemeProvider>
                    <Router>
                      <NotificationProvider>
                        <PopupProvider>
                          <SnackbarProvider>
                            <RouterPropsProvider>
                              <ExportContextProvider>
                                <UserProfileImageProvider>
                                  <PollingContextProvider>
                                    <BulkAdjustmentProvider>
                                      <App />
                                    </BulkAdjustmentProvider>
                                  </PollingContextProvider>
                                </UserProfileImageProvider>
                              </ExportContextProvider>
                            </RouterPropsProvider>
                          </SnackbarProvider>
                        </PopupProvider>
                      </NotificationProvider>
                    </Router>
                  </StacklineThemeProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </SplitFactory>
          </BusProvider>
        </QueryClientProvider>
      </Provider>
    </LocalizationProvider>,
    MOUNT_NODE
  );
};

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render;
    const renderError = (error) => {
      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
    };

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp();
      } catch (error) {
        renderError(error);
      }
    };

    // Setup hot module replacement
    module.hot.accept('./routes/index', () => {
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render();
      });
    });
  }
}

if (store.getState().app.stage === 'dev') {
  // Enable these on dev and localhost
  // Make the Redux store available as a global variable if in debug mode
  window.store = store;
  window.getState = () => store.getState();
  window.ess = () => store.getState().entitySearchService;
  window.getMainEntity = () => _get(store.getState(), ['entityService', 'mainEntity']);
  window.queryClient = queryClient;
}

// ========================================================
// Go!
// ========================================================
if (process.env.NODE_ENV !== 'test') {
  render();
}
