interface OmniRegionsFollowing {
  regionCode: string;
  regionName: string;
}

interface OmniCountriesFollowing {
  countryCode: string;
  countryName: string;
}

// Our dataset does not support normal gb-all.topo json file, this is a special case for rendering a country.
const GREAT_BRITAIN_MAP_FULL_PATH = 'countries/gb/custom/gb-countries' as const;

export const deConstructTheHKGetGeoInfo = (key: string) => {
  return key.split('-');
};

export const regionAbbreviations: { [continent: string]: string } = {
  europe: 'eu',
  oceania: 'oc',
  africa: 'af',
  asia: 'as',
  'north-america': 'na',
  'south-america': 'sa'
};

// eu
const regionFullNames: { [abbreviation: string]: string } = Object.entries(regionAbbreviations).reduce(
  (acc, [continent, abbreviation]) => {
    acc[abbreviation] = continent;
    return acc;
  },
  {}
);

export const getRegionFullName = (abbreviation: string) => {
  return regionFullNames[abbreviation.toLowerCase()];
};

export const regionPathFullName = (continentName: string) => {
  const abbreviation = regionAbbreviations[continentName.toLowerCase()];
  return `custom/${abbreviation}`;
};

export const regionFullDisplayName = (regionLowercased: string) => {
  switch (regionLowercased) {
    case 'eu':
      return 'Europe';
    case 'na':
      return 'North America';
    case 'sa':
      return 'South America';
    case 'af':
      return 'Africa';
    case 'oc':
      return 'Oceania';
    case 'as':
      return 'Asia';
    default:
      return regionLowercased;
  }
};

/**
 * We need to find a way to track current map. -> there is no way to track the map since
 * 'hc-key': "sa" can be either South America Saudi Arabia, and for the state level, there should be many duplicates
 *
 * // ['WORLD', 'CONTINENT', 'COUNTRY', 'STATE', 'CITY'] is current supporting level
 * @param regions how many regions to be supported.
 * @param countries how may countries to be supported.
 * @returns number of index,
 */
export const mapIndexGenerator = (regions: OmniRegionsFollowing[], countries: OmniCountriesFollowing[]) => {
  if (regions.length > 1) {
    return 0; // WORLD
  }
  if (countries.length > 1) {
    return 1; // CONTINENT
  }
  return 2; // COUNTRY
};

/**
 * returns mapName to dynamically import corresponding map geo.json from module.
 *
 * @param regions fetched regions list data from API
 * @param countries fetched countries list from API
 */
export const mapNameGenerator = (
  regions: OmniRegionsFollowing[],
  countries: OmniCountriesFollowing[],
  state: string[]
) => {
  if (countries.length === 1) {
    const lowerCasedCountry = countries[0].countryCode.toLocaleLowerCase();

    // if state is available, the format should be countries/us/us-wa-all
    // else countries/us/us-all
    return state.length === 1
      ? `countries/${lowerCasedCountry}/${lowerCasedCountry}-${state}-all`
      : `countries/${lowerCasedCountry}/${lowerCasedCountry}-all`;
  }

  // If there is more than 2 regions, we need to render world map
  if (regions.length > 1) {
    return 'custom/world-continents';
  } else {
    // if the client has 1 region, and more than 1 countries we need to return the region map
    // ['eu', 'oc', 'af', 'as', 'na', 'sa'] is an available continents (regions) option.
    return regionPathFullName(regions[0].regionCode.toLowerCase());
  }
};

export const mapDetectorByClick = (mapKey: string, eventName: string, mapName: string) => {
  const stateLevelCountry = ['us-all'];

  const [source, , stateAvailable] = mapName.split('/');

  if (mapName === 'custom/world-continents') {
    return `custom/${getRegionFullName(mapKey)}`;
  }
  if (source === 'custom') {
    // For Great Britain the path will be => countries/gb/custom/gb-countries.topo.json
    // mapKey can be found in other State, City as a key, so add eventName

    if (mapKey === 'gb' && eventName === 'United Kingdom') {
      return GREAT_BRITAIN_MAP_FULL_PATH;
    }

    return `countries/${mapKey}/${mapKey}-all`;
  }

  // As of Mar 23, we only support US to county level data.
  if (stateLevelCountry.includes(stateAvailable)) {
    const [country] = mapKey.split('-');
    return `countries/${country}/${mapKey}-all`;
  }

  return null;
};

// this will be needed for key as map highchart format
export const groupByGenerator = (mapNameArg) => {
  // countryLevel can be either ex) us-all, us-wa-all
  const [source, mapFullName, countryLevel] = mapNameArg.split('/');

  // custom means it will be world-map, region map
  if (source === 'custom') {
    if (mapFullName === 'world-continents') {
      return 'locationRegionCode';
    } else {
      return 'locationCountryCode';
    }
  }

  if (source === 'countries') {
    if (mapNameArg === GREAT_BRITAIN_MAP_FULL_PATH) {
      return 'locationState';
    }

    const countyLevelArray = countryLevel.split('-');
    // e.g.) us-all
    if (countyLevelArray.length === 2) {
      return 'locationState';
      // e.g.) us-ca-all
    } else if (countyLevelArray.length === 3) {
      return 'locationCountyCode';
    }
  }

  return 'locationCountyCode';
};

export const groupByGeneratorForCard = (dropDownValue) => {
  const initialGroupBy = dropDownValue;

  if (initialGroupBy === 'locationState') {
    return 'locationStateName';
  }

  if (initialGroupBy === 'locationCountyCode') {
    return 'locationCounty';
  }

  return initialGroupBy;
};

// base on the mapName, generate payload key and value
export const payloadGenerator = (mapName: string, hcMapKey: string): [string, string] | [null, null] => {
  if (hcMapKey) {
    const [source, mapFullName, countryLevel] = mapName.split('/');

    if (mapName === GREAT_BRITAIN_MAP_FULL_PATH) {
      return ['includeLocationCountryCode', hcMapKey.toUpperCase()];
    }

    // if the mapName starts with "custom" => it should be world-continent, or one region
    if (source === 'custom') {
      if (mapFullName === 'world-continents') {
        return [null, null];
      }
      return ['includeLocationRegionCode', regionAbbreviations[mapFullName].toUpperCase()];
    }

    if (source === 'countries') {
      const countyLevelArray = countryLevel.split('-');
      if (countyLevelArray.length === 2) {
        // e.g.) us-all

        const hcMapKeyArray = hcMapKey.split('-');
        if (hcMapKeyArray.length === 2) {
          // in case a user came from the state level using back button, the hcMapKey will remain as hcMapKey as "us-wa"
          return ['includeLocationCountryCode', hcMapKeyArray[0].toUpperCase()];
        }
        return ['includeLocationCountryCode', hcMapKey.toUpperCase()];
        // e.g.) us-ca-all
      } else if (countyLevelArray.length === 3) {
        return ['includeLocationState', hcMapKey];
      }
    }
  }
  return [null, null];
};

export const dropDownGenerator = (mapName: string) => {
  const dropDownOption: { name: string; displayName: string }[] = [];

  if (mapName === 'custom/world-continents') {
    dropDownOption.push(
      { name: 'locationRegionCode', displayName: 'Continent' },
      { name: 'locationCountryCode', displayName: 'Country' }
    );
  }

  if (mapName.startsWith('custom/') && !mapName.endsWith('world-continents')) {
    dropDownOption.push({ name: 'locationCountryCode', displayName: 'Country' });
  }

  if (mapName.startsWith('countries/us') && mapName.match(/^countries\/us\/us-[a-z]{2}-all$/)) {
    dropDownOption.push(
      { name: 'locationCity', displayName: 'City' },
      { name: 'locationCounty', displayName: 'County' }
    );
  } else if (mapName.startsWith('countries/us')) {
    dropDownOption.push(
      { name: 'locationState', displayName: 'State' },
      { name: 'locationCity', displayName: 'City' },
      { name: 'locationCounty', displayName: 'County' }
    );
  } else if (mapName.startsWith('countries/') && !mapName.match(/^country\/us-\w+-all$/)) {
    dropDownOption.push({ name: 'locationState', displayName: 'State' }, { name: 'locationCity', displayName: 'City' });
  }

  return dropDownOption;
};

export const headerTabOptsForMap = {
  global: [
    {
      name: 'global',
      displayName: 'Global',
      tooltipText: 'Create Global'
    }
  ],
  continent: [
    {
      name: 'continent',
      displayName: 'Continent',
      tooltipText: 'Create Continent'
    }
  ],
  country: [
    {
      name: 'country',
      displayName: 'Country',
      tooltipText: 'Create country'
    }
  ],
  state: [
    {
      name: 'state',
      displayName: 'State',
      tooltipText: 'Create State'
    }
  ],
  cities: [
    {
      name: 'city',
      displayName: 'City',
      tooltipText: 'Create City'
    }
  ]
};

export const tabNameGenerator = (mapName) => {
  const [source, , countryStateInfo] = mapName.split('/');

  if (mapName === 'custom/world-continents') {
    return headerTabOptsForMap.global;
  }
  if (source === 'custom') {
    return headerTabOptsForMap.continent;
  }

  // As of Mar 23, we only support US to county level data. e.g) it us-wa-all
  if (countryStateInfo.split('-').length === 3) {
    return headerTabOptsForMap.state;
  }

  return headerTabOptsForMap.country;
};
