import _cloneDeep from 'lodash/cloneDeep';
import { COMMON_FIELDS } from 'src/utils/entityDefinitions/fields/commonFieldDefinitions';

const PROMOTION_FIELDS = _cloneDeep(COMMON_FIELDS);
PROMOTION_FIELDS.retailSales.displayName = 'Promotion Sales';
PROMOTION_FIELDS.dataPointCount.displayName = 'Promotion Count';
PROMOTION_FIELDS.unitsSold.displayName = 'Promotion Units';
PROMOTION_FIELDS.retailPrice.displayName = 'Promotion Price';

export { PROMOTION_FIELDS };
