/* eslint-disable react/prop-types */
import React from 'react';
import Checkbox from '@mui/material/Checkbox';

import { PropTypesOf } from 'src/types/utils';

const CheckboxInput: React.FC<
  {
    value: boolean;
    onChange: (newValue: boolean) => void;
    label?: string;
    wrapperStyle?: React.CSSProperties;
    checkboxStyle?: React.CSSProperties;
  } & PropTypesOf<typeof Checkbox>
> = ({ value, onChange, label, wrapperStyle, checkboxStyle, ...props }) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...(wrapperStyle || {}) }}>
    <Checkbox checked={value} onChange={(_evt, isChecked) => onChange(isChecked)} style={checkboxStyle} {...props} />
    {label ? <span style={{ marginTop: 4 }}>{label}</span> : null}
  </div>
);

export default CheckboxInput;
