import axios from 'axios';
import { SegmentState } from 'src/components/Omni/OmniSegmentUtil';
import { ThunkDispatch } from 'redux-thunk';
import ReduxStore from 'src/types/store/reduxStore';
import { receiveOmniSegmentList, startFetchOmniSegment } from './actions';

interface UpdateSegmentRequestBody {
  query?: SegmentState;
  name: string;
  queryId?: string;
  isActive?: boolean;
  segmentType: string;
}

export const updateTheOmniSegment = async (updateSegmentBody: UpdateSegmentRequestBody) => {
  return axios.post('/omni/segment/updateSegment', updateSegmentBody);
};

export const autoCompleteSearch = async (searchUrl: string) => {
  const response = await axios.get(searchUrl);
  if (response && response.status === 200) {
    const { results } = response.data;
    return results[0].options.map(({ text, _id }) => ({ value: text, id: _id }));
  }

  return [];
};

export const fetchSegmentData = async () => {
  return axios.get('omni/segment/getAllSegments?segmentType=product');
};

export const fetchSegmentListData = () => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
  try {
    dispatch(startFetchOmniSegment({ data: [] }));
    const response = await fetchSegmentData();
    if (response && response.status === 200) {
      const { data } = response;
      dispatch(receiveOmniSegmentList({ data }));
    }
  } catch (e) {
    console.warn(e);
  }
};
