import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';

import i18n from 'src/i18n_en';
import { mapProps, withProps } from 'src/utils/hoc';
import ListItemsSelector from 'src/components/Search/AdvancedSearch/ListItemsSelector';
import RangeSelector from '../RangeSelector';
import DisplayNameAdvancedSearch from '../DisplayNameAdvancedSearch';
import BrandsAdvancedSearch from '../BrandsAdvancedSearch';
import ExcludedBrandsAdvancedSearch from '../ExcludedBrandsAdvancedSearch';
import ParentBrandsAdvancedSearch from '../ParentBrandsAdvancedSearch';
import KeywordsSearch from '../KeywordsSearch';
import TargetGridCheckbox from '../TargetGridCheckbox';
import { panic } from 'src/utils/mixpanel';
import ShimBusinessUnitFilter from './ShimBusinessUnitFilter';
import ShimSegmentFilter from './ShimSegmentFilter';
import { AppName } from 'sl-api-connector';
import { store } from 'src/main';
import UpdatedKeywordSearch from '../UpdatedAdvancedSearch/UpdatedKeywordSearch';
import UpdatedBrandsSearch from '../UpdatedAdvancedSearch/UpdatedBrandsSearch';
import UpdatedListItemSelector from '../UpdatedAdvancedSearch/UpdatedListItemSelector';

export const buildSubcategoryListItemFilterDefinition = (subCategoriesAvailable) => [
  'UpdatedListItemSelector',
  'subcategories',
  {
    listDisplayName: 'Subcategory',
    idFieldName: 'subCategoryId',
    nameFieldName: 'subCategoryName',
    searchFieldHintText: 'Refine by subcategories',
    searchFieldNotFoundText: i18n.subCategories_noneFound,
    listItems: subCategoriesAvailable,
    listItemDataDependencyPropNames: ['filters.subcategory', 'entity', 'location', 'subcategories']
  }
];

export const buildCategoryListItemFilterDefinition = (categoriesAvailable) => [
  'UpdatedListItemSelector',
  'categories',
  {
    listDisplayName: 'Category',
    idFieldName: 'categoryId',
    nameFieldName: 'categoryName',
    searchFieldHintText: 'Refine by categories',
    searchFieldNotFoundText: i18n.categories_noneFound,
    listItems: categoriesAvailable,
    listItemDataDependencyPropNames: ['filters.category', 'entity', 'location', 'categories']
  }
];

const ExcludedCategoriesInner = ({ onChange, value, categories, allSuperUserCategories, user, app }) => {
  const isSuperUser = _get(user, ['config', 'isStacklineSuperUser'], false);
  const categoriesAvailable = _cloneDeep(
    (isSuperUser && app.name === AppName.Atlas ? allSuperUserCategories : categories).filter((x) => x.categoryId !== 0)
  );

  return (
    <ListItemsSelector
      listDisplayName="Excluded Categories"
      idFieldName="categoryId"
      nameFieldName="categoryName"
      searchFieldHintText="Refine by excluding categories"
      searchFieldNotFoundText={i18n.categories_noneFound}
      listItems={categoriesAvailable}
      selectedListItems={value}
      onSelectedListItemsChange={onChange}
    />
  );
};

ExcludedCategoriesInner.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  // From Redux
  categories: PropTypes.array.isRequired,
  allSuperUserCategories: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired
};

ExcludedCategoriesInner.defaultProps = {
  value: undefined
};

const ExcludedCategories = connect(({ categories, allSuperUserCategories, user, app, eventBus }) => ({
  categories,
  allSuperUserCategories,
  user,
  app,
  eventBus
}))(ExcludedCategoriesInner);

const ExcludedSubCategoriesInner = ({ onChange, value, subCategories, allSuperUserSubCategories, user, app }) => {
  const isSuperUser = _get(user, ['config', 'isStacklineSuperUser'], false);
  const subCategoriesAvailable = _cloneDeep(
    (isSuperUser && app.name === AppName.Atlas ? allSuperUserSubCategories : subCategories).filter(
      (x) => x.categoryId !== 0
    )
  );

  return (
    <ListItemsSelector
      listDisplayName="Excluded Subcategories"
      idFieldName="subCategoryId"
      nameFieldName="subCategoryName"
      searchFieldHintText="Refine by excluding subca..."
      searchFieldNotFoundText={i18n.categories_noneFound}
      listItems={subCategoriesAvailable}
      selectedListItems={value}
      onSelectedListItemsChange={onChange}
    />
  );
};

ExcludedSubCategoriesInner.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  // From Redux
  subCategories: PropTypes.array.isRequired,
  allSuperUserSubCategories: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired
};

ExcludedSubCategoriesInner.defaultProps = {
  value: undefined
};

const ExcludedSubCategories = connect(({ subCategories, allSuperUserSubCategories, user, app }) => ({
  subCategories,
  allSuperUserSubCategories,
  user,
  app
}))(ExcludedSubCategoriesInner);

// Used to check if we should render (old) Advanced Search components for Dev only or Updated Advanced Search
const { user, app } = store.getState();
const isSuperUser = user.config.isStacklineSuperUser;
const appName = app.name || '';

const filterNameToComponentMapping = {
  displayNameAdvancedSearch: mapProps(({ value }) => ({ dn: value }))(DisplayNameAdvancedSearch),
  keywordsSearch: mapProps(({ onChange, value }) => ({ onKeywordsChange: onChange, keywords: value }))(
    isSuperUser || appName === 'advertising' ? KeywordsSearch : UpdatedKeywordSearch
  ),
  campaignNameSearch: mapProps(({ onChange, value }) => ({ onKeywordsChange: onChange, keywords: value }))(
    KeywordsSearch
  ),
  retailerNameSearch: mapProps(({ onChange, value }) => ({ onKeywordsChange: onChange, keywords: value }))(
    KeywordsSearch
  ),
  adGroupNameSearch: mapProps(({ onChange, value }) => ({ onKeywordsChange: onChange, keywords: value }))(
    KeywordsSearch
  ),
  portfolioNameSearch: mapProps(({ onChange, value }) => ({ onKeywordsChange: onChange, keywords: value }))(
    KeywordsSearch
  ),
  entityNameSearch: mapProps(({ onChange, value }) => ({ onKeywordsChange: onChange, keywords: value }))(
    KeywordsSearch
  ),
  brandsAdvancedSearch: mapProps(({ onChange, value }) => ({ onBrandsChange: onChange, brands: value || [] }))(
    isSuperUser || appName === 'advertising' ? BrandsAdvancedSearch : UpdatedBrandsSearch
  ),
  excludedBrandsAdvancedSearch: mapProps(({ onChange, value }) => ({ onBrandsChange: onChange, brands: value || [] }))(
    ExcludedBrandsAdvancedSearch
  ),
  parentBrandsAdvancedSearch: mapProps(({ onChange, value }) => ({
    onParentBrandsChange: onChange,
    parentBrands: value || []
  }))(ParentBrandsAdvancedSearch),
  UpdatedListItemSelector: mapProps(({ onChange, value }) => ({
    onSelectedListItemsChange: onChange,
    selectedListItems: value
  }))(isSuperUser || appName === 'advertising' ? ListItemsSelector : UpdatedListItemSelector),
  listItemsSelector: mapProps(({ onChange, value }) => ({
    onSelectedListItemsChange: onChange,
    selectedListItems: value
  }))(ListItemsSelector),
  rangeSelector: mapProps(({ filterName, displayName, onChange, value }) => {
    return {
      filterName,
      displayName,
      min: value ? value.min || 0 : 0,
      max: value ? value.max || 0 : 0,
      onChange
    };
  })(RangeSelector),
  excludedCategories: ExcludedCategories,
  excludedSubCategories: ExcludedSubCategories,
  targetGridCheckbox: TargetGridCheckbox,
  segmentsFilter: ShimSegmentFilter,
  businessUnitFilter: ShimBusinessUnitFilter
};

/**
 *
 * @param {string} filterName The name of the filter that you're looking to build.
 * @param {object?} props Optional additional props to pass to the created filter component
 */
export const mapFilterNameToFilterComponent = (filterName, props) => {
  const Comp = filterNameToComponentMapping[filterName];

  if (!Comp) {
    return panic(`Attempted to get filter with name ${filterName} but none exists`);
  }

  if (props) {
    return withProps(props)(Comp);
  }

  return Comp;
};
