import types from './types';
import actions from './actions';
import { ValueOf } from 'sl-api-connector/types';

export default function brandsFollowing(state = [], action: ReturnType<ValueOf<typeof actions>>) {
  switch (action.type) {
    case types.RECEIVE_ALL_BRANDS_FOLLOWING:
      return Object.assign([], action.brands);
    case types.CLEAR_ALL_BRANDS_FOLLOWING:
      return [];
    default:
      return state;
  }
}
