import types, { AdScores } from './types';

// ------------------------------------
// Action Creators - they simply return an action
// An action being a payload of information that send data from your application to your store.
// ------------------------------------

const receiveAdScores = (adScores: AdScores) => ({
  type: types.RECEIVE_ADSCORES,
  adScores,
  receivedAt: Date.now()
});

const clearAdScores = () => ({ type: types.CLEAR_ADSCORES });

export default {
  clearAdScores,
  receiveAdScores
};
