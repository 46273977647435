import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _orderBy from 'lodash/orderBy';
import _prop from 'lodash/property';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import queryString from 'qs';
import TextField from '@mui/material/TextField';
import CustomizedCheckbox from 'src/components/common/CustomizedCheckBox/CustomizedCheckBox';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from 'src/components/SvgIcons';
import { AutoSizer, List as VirtualizedList, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { AppName } from 'sl-api-connector';
import FormControlLabel from '@mui/material/FormControlLabel';
import './Filter.scss';
import i18n from 'src/i18n_en';
import { anyNotEq } from 'src/utils/equality';

const cache = new CellMeasurerCache({
  defaultHeight: 45,
  fixedWidth: true
});

// We have to use lodash's `_.orderBy()` here since it's stable, and JS isn't guarenteed to have a stable sort algorithm
const sortSubCategories = (subCategories) =>
  _orderBy(subCategories, [({ isChecked }) => (isChecked ? 1 : 0)], ['desc']);

class SubCategoryFilter extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    subCategories: PropTypes.array.isRequired,
    entity: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      subCategoriesForEntity: [],
      staticSearchSubCategories: []
    };
  }

  componentWillMount() {
    this.setSubCategories(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      anyNotEq(['filters.category', 'entity', 'location'], this.props, nextProps) ||
      (nextProps.filters.isClearingFilters && this.props.filters.subcategory !== null) ||
      this.props.subCategories !== nextProps.subCategories // shallow equality for increased speed
    ) {
      this.setSubCategories(nextProps);
    }
  }

  setSubCategories = (props) => {
    const {
      app,
      entity,
      categories,
      allSuperUserCategories,
      allSuperUserSubCategories,
      subCategories,
      filters,
      location,
      user
    } = props;
    if (!categories || !subCategories) {
      return;
    }

    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const parsedFilterParams = queryParams.filter && queryString.parse(JSON.parse(queryParams.filter));
    const useAllCategories = _get(user, ['config', 'isStacklineSuperUser'], false) && app.name === AppName.Atlas;

    const activeSubCategoryIds = new Set();

    if (filters.category !== null) {
      // If we have active category filters, limit the list of displayed subcategories to filter by down to those which
      // belong to the list of filtered categories.
      const filteredCategoryIds = new Set();
      filters.category.forEach(({ id }) => filteredCategoryIds.add(id));

      (useAllCategories ? allSuperUserCategories : categories).forEach((category) => {
        if (filteredCategoryIds.has(category.id)) {
          category.childEntities.forEach(({ subCategoryId }) => activeSubCategoryIds.add(subCategoryId));
        }
      });
    } else {
      // Include all subcategories that are children of all subscribed categories
      (useAllCategories ? allSuperUserCategories : categories).forEach((val) => {
        if (_get(entity, 'categoryIds', []).includes(val.categoryId)) {
          if (!val.childEntities) {
            return;
          }

          val.childEntities.forEach(({ subCategoryId }) => activeSubCategoryIds.add(subCategoryId));
        }
      });
    }

    // Use the list of `subCategories` from Redux as the base here since it's sorted, filtering out items that aren't
    // available to be displayed from that.
    const subCategoriesForEntity = (useAllCategories ? allSuperUserSubCategories : subCategories)
      .filter(({ subCategoryId }) => activeSubCategoryIds.has(subCategoryId))
      .map((subCategory) => {
        const isChecked =
          filters.subcategory !== null &&
          parsedFilterParams &&
          parsedFilterParams.sc &&
          // eslint-disable-next-line eqeqeq
          !!parsedFilterParams.sc.find((item) => item.i == subCategory.id);

        return { ...subCategory, isChecked };
      });

    this.setState({
      staticSearchSubCategories: subCategoriesForEntity,
      subCategoriesForEntity: sortSubCategories(subCategoriesForEntity),
      searchText: ''
    });
  };

  handleCheck = (event, isInputChecked, index) => {
    const { subCategoriesForEntity, staticSearchSubCategories } = this.state;

    subCategoriesForEntity[index].isChecked = isInputChecked;
    staticSearchSubCategories.forEach((val) => {
      if (val.subCategoryId === subCategoriesForEntity[index].subCategoryId) {
        val.isChecked = isInputChecked;
      }
    });
    const values = staticSearchSubCategories.filter(_prop('isChecked'));
    this.setState({ subCategoriesForEntity });
    this.props.onFilterChange(values, 'subcategory');
  };

  handleInputChange = (event) => {
    const { value } = event.target;

    const lowerCaseVal = value.toLowerCase();
    const updatedSubCategories = this.state.staticSearchSubCategories.filter((subcategory) =>
      subcategory.subCategoryName.toLowerCase().includes(lowerCaseVal)
    );

    this.setState({
      searchText: value,
      subCategoriesForEntity: sortSubCategories(updatedSubCategories)
    });
  };

  rowRenderer = ({ index, style }) => {
    const { subCategoriesForEntity } = this.state;
    const { subCategoryName, isChecked } = subCategoriesForEntity[index];

    return (
      <div style={{ ...style, marginBottom: '10px' }} key={subCategoryName} className="category-filter">
        <FormControlLabel
          control={
            <CustomizedCheckbox
              styleObj={{
                width: 24,
                height: 24,
                iconBackgroundColor: 'transparent',
                iconColor: 'transparent',
                checkedIconBackgroundColor: 'transparent',
                checkedIconColor: 'transparent'
              }}
              InnerIcon={CheckboxCheckedIcon}
              OuterIcon={CheckboxUncheckedIcon}
              checkboxProps={{
                checked: isChecked,
                onChange: (evt) => {
                  this.handleCheck(evt, evt.target.checked, index);
                }
              }}
            />
          }
          label={<span style={{ fontSize: '13px' }}>{subCategoryName}</span>}
        />
      </div>
    );
  };

  render() {
    const { subCategoriesForEntity, staticSearchSubCategories, searchText } = this.state;
    if (staticSearchSubCategories && staticSearchSubCategories.length === 0) {
      return null;
    }

    return (
      <div className="search-form-container search-form-container--sm">
        <TextField
          variant="standard"
          autoComplete="off"
          className="sl-form-input"
          placeholder="Search for subcategories"
          type="text"
          name="keyword"
          id="keyword"
          style={{ width: '100%' }}
          value={searchText}
          onChange={this.handleInputChange}
        />
        {subCategoriesForEntity && subCategoriesForEntity.length === 0 ? (
          <div style={{ height: '175px' }}> {i18n.subCategories_noneFound}</div>
        ) : null}
        {subCategoriesForEntity && subCategoriesForEntity.length > 0 ? (
          <AutoSizer disableHeight>
            {({ width }) => (
              <VirtualizedList
                overscanRowCount={4}
                className="category-filter"
                width={width}
                height={175}
                rowCount={subCategoriesForEntity.length}
                rowHeight={cache.rowHeight}
                style={{ marginBottom: '10px' }}
                rowRenderer={({ ...props }) => {
                  const { key, parent, index } = props;
                  return (
                    <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                      {this.rowRenderer(props)}
                    </CellMeasurer>
                  );
                }}
              />
            )}
          </AutoSizer>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, [
    'allSuperUserCategories',
    'allSuperUserSubCategories',
    'app',
    'categories',
    'comparisonTimePeriod',
    'filters',
    'mainTimePeriod',
    'retailer',
    'subCategories',
    'user'
  ]);

export default withRouter(connect(mapStateToProps)(SubCategoryFilter));
