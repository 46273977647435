import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from '@stackline/ui';

export interface AdjustmentDataItem {
  pk: string;
  sk: string;
  beaconClientId: number;
  retailerId: number;
  userId: string;
  planType: number;
  bulkAdjustmentId: string;
  bulkAdjustmentStage: number;
  bulkAdjustmentLevel: string;
  bulkAdjustmentLevelId: string;
  fileUri: string;
  fileName: string;
  isUserDownload: boolean;
  processingStatus: 'Success' | 'Error' | 'Queued' | 'InProgress';
  requestedDate: string;
  processedDate: string;
  lastUpdatedTime: number;
  additionalDetails: string;
  numberOfDownloads: number;
}

export interface ExportDataItem {
  requestId: string;
  userId: string;
  appName: string;
  uniqueUserAppKey: string;
  originalRequest: string;
  dataSourceType: string;
  additionalExportOptions: {
    weekIdRanges1: string;
    Tab: string;
    SubTab: string;
    exportRequestSource: string;
  };
  requestedTime: number;
  beganProcessing: number;
  finishedProcessing: number;
  status: string;
  fileUri: string;
  fileSize: number;
  fileName: string;
  numberOfDownloads: number;
  lastDownloadTimestamp: number | null;
  isDeleted: boolean;
  error: string | null;
  debug: string | null;
  appStage: string;
  serializedExportRequestJsonFileUrl: string;
}

/**
 * Default error message for when profile settings
 * calls fail.
 */
export const settingsErrorMessage = (
  <Flex gap="xs">
    <Text inline variant="subtitle2">
      An error occurred. Please reach out to
    </Text>
    <a href="mailto:support@stackline.com">
      <Text inline variant="subtitle2" underlined>
        support@stackline.com
      </Text>
    </a>
    <Text inline variant="subtitle2">
      for further guidance.
    </Text>
  </Flex>
);

/**
 * In the Adjustment download if the status is Queued or InProgress
 */
export const findProgressAdjustments = (dataList: AdjustmentDataItem[]) => {
  return dataList.filter((datum) => datum.processingStatus === 'Queued' || datum.processingStatus === 'InProgress');
};
