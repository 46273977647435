import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAppSelector, useQueryParamValue } from 'src/utils/Hooks';
import {
  getFirstWeekIdOfYear,
  getLastWeekIdOfYear,
  getNextWeekId,
  getPreviousWeekId,
  getPreviousYearWeekId,
  yearPartOfWeekId
} from 'src/utils/dateUtils';
import { AdjustmentPlanType, ForecastComparisonPeriod, ForecastPeriod, ForecastType } from '../types';
import axios from 'axios';
import { useQuery } from 'react-query';
import _get from 'lodash/get';

/**
 * Fetches the latest forecast model for the client + retailer combination.
 * @returns an object contain the modelVersion and a loading state
 */
export const useLatestForecastModel = (): {
  modelVersion: string | null;
  loading: boolean;
  currentPublishVersion: string;
  previousPublishVersion: string;
} => {
  const cancelSource = useRef(axios.CancelToken.source());
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const retailer = useAppSelector((state) => state.retailer);

  const fetchLatestModelVersion = async () => {
    try {
      const response = await axios.get(
        `api/beaconforecast/GetModelVersion?beaconClientId=${beaconClientId}&retailerId=${retailer.id}`
      );
      return response;
    } catch (error) {
      console.error('Failed to fetch forecasting model');
      return null;
    }
  };

  const query = useQuery(['forecastModelRequest', beaconClientId, retailer.id], () => fetchLatestModelVersion(), {
    retry: 0, // Axios configured for 3 retries already
    staleTime: 0,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    const cancelNetworkCalls = cancelSource.current.cancel;

    return () => cancelNetworkCalls();
  }, []);

  // TODO: Remove this once we support all clients and retailers. Use fallback model for Weleda
  if (!query.isLoading && !_get(query, ['data', 'data', 'modelVersion'])) {
    return {
      modelVersion: '202323',
      loading: query.isLoading,
      currentPublishVersion: '202323_01',
      previousPublishVersion: '202323_01'
    };
  }

  return {
    modelVersion: _get(query, ['data', 'data', 'modelVersion'], null),
    loading: query.isLoading,
    currentPublishVersion: _get(query, ['data', 'data', 'currentPublishVersion'], null),
    previousPublishVersion: _get(query, ['data', 'data', 'previousPublishVersion'], null)
  };
};

/**
 * Given a week ID, get the previous week ID to compare it to.
 * If the comparison period is the prior year, then it will return
 * the week ID one year prior. If the comparison period is the prior
 * period, it will return the week ID `n` weeks back, where `n` is the
 * number of weeks in the forecast period.
 */
export const usePreviousForecastWeekId = () => {
  const forecastPeriod: ForecastPeriod = useQueryParamValue('forecastPeriod', ForecastPeriod.FULL_YEAR);
  const comparisonPeriod: ForecastComparisonPeriod = useQueryParamValue('pid', ForecastComparisonPeriod.PRIOR_YEAR);

  return useCallback(
    (weekId: number) => {
      if (comparisonPeriod === ForecastComparisonPeriod.PRIOR_YEAR || forecastPeriod === ForecastPeriod.FULL_YEAR) {
        return getPreviousYearWeekId(weekId);
      } else {
        const weeksToSubtract: number = {
          [ForecastPeriod.FIFTY_TWO_WEEKS]: 52,
          [ForecastPeriod.TWENTY_SIX_WEEKS]: 26,
          [ForecastPeriod.THIRTEEN_WEEKS]: 13,
          [ForecastPeriod.FOUR_WEEKS]: 4
        }[forecastPeriod];

        return getPreviousWeekId(weekId, weeksToSubtract);
      }
    },
    [forecastPeriod, comparisonPeriod]
  );
};

/**
 * Gets the start and end week IDs for the custom forecast period
 * @param forecastPeriod
 * @returns The new start or end week ID
 */
export const getCustomForecastDateWeek = (startOrEnd: 'start' | 'end') => {
  const searchParams = new URLSearchParams(window.location.search);
  const forecastPeriodQueryParam = searchParams.get('forecastPeriod');
  const [startWeekId, endWeekId] = forecastPeriodQueryParam.split('-');
  if (startOrEnd === 'start') {
    return startWeekId;
  }
  return endWeekId;
};

export const customForecastDateRegex = /^\d{6}-\d{6}$/;
export const useForecastPeriod = (): ForecastPeriod => {
  const forecastPeriod = useQueryParamValue('forecastPeriod', ForecastPeriod.FULL_YEAR);
  // Regex: 6 digits followed by a hyphen and then 6 more digits
  // If the forecast period matches the custom format, return custom enum
  if (customForecastDateRegex.test(forecastPeriod)) {
    return ForecastPeriod.CUSTOM;
  }
  return forecastPeriod;
};

export const useForecastComparisonPeriod = (): ForecastComparisonPeriod => {
  const forecastComparisonPeriod = useQueryParamValue('pid', ForecastComparisonPeriod.PRIOR_YEAR);
  return forecastComparisonPeriod;
};

export const useForecastType = (): ForecastType => {
  const forecastType = useQueryParamValue('forecastType', ForecastType.ADJUSTED);
  return forecastType;
};

export const useForecastPlanType = (): AdjustmentPlanType => {
  const forecastPlanType = useQueryParamValue('forecastPlansType', AdjustmentPlanType.AllPlanTypes);
  return forecastPlanType;
};

/**
 * Given the forecast period in the URL, calculate the start
 * and end week IDs. For example, if the next 13 weeks are chosen,
 * it will return the end week ID of the 13 weeks from the current
 * week id.
 */
export const useForecastStartAndEndWeekIds = ({
  forecastPeriodOverride
}: { forecastPeriodOverride?: ForecastPeriod } = {}) => {
  const { modelVersion } = useLatestForecastModel();
  // Start the forecast the week after the current week
  const startWeekId = useMemo(() => getNextWeekId(Number(modelVersion)), [modelVersion]);
  const forecastPeriod = useForecastPeriod();
  const customStartWeek = forecastPeriod === ForecastPeriod.CUSTOM ? getCustomForecastDateWeek('start') : null;
  const customEndWeek = forecastPeriod === ForecastPeriod.CUSTOM ? getCustomForecastDateWeek('end') : null;
  // Get the last week ID, then we will use the next
  // one to get the forecast data for the rest of the year
  const endWeekId: number = useMemo(
    () =>
      ({
        // We are subtracting 1 from each of these because the end week ID is inclusive
        [ForecastPeriod.FULL_YEAR]: getLastWeekIdOfYear(yearPartOfWeekId(startWeekId)),
        [ForecastPeriod.FIFTY_TWO_WEEKS]: getNextWeekId(startWeekId, 51),
        [ForecastPeriod.TWENTY_SIX_WEEKS]: getNextWeekId(startWeekId, 25),
        [ForecastPeriod.THIRTEEN_WEEKS]: getNextWeekId(startWeekId, 12),
        [ForecastPeriod.FOUR_WEEKS]: getNextWeekId(startWeekId, 3)
      }[forecastPeriodOverride || forecastPeriod] || customEndWeek),
    [forecastPeriod, forecastPeriodOverride, startWeekId, customEndWeek]
  );

  return useMemo(
    () => ({ startWeekId: customStartWeek || startWeekId, endWeekId }),
    [endWeekId, startWeekId, customStartWeek]
  );
};

/**
 * Given the forecast comparison period in the URL, calculate the start
 * and end week IDs.
 */
export const useForecastComparisonStartAndEndWeekIds = () => {
  const forecastPeriod = useQueryParamValue('forecastPeriod', ForecastPeriod.FULL_YEAR);
  const { startWeekId: forecastStartWeekId, endWeekId: forecastEndWeekId } = useForecastStartAndEndWeekIds();
  const previousForecastWeekId = usePreviousForecastWeekId();

  return useMemo(
    () => ({
      startWeekId:
        forecastPeriod === ForecastPeriod.FULL_YEAR
          ? getFirstWeekIdOfYear(yearPartOfWeekId(forecastStartWeekId) - 1)
          : previousForecastWeekId(forecastStartWeekId),
      endWeekId: previousForecastWeekId(forecastEndWeekId)
    }),
    [forecastEndWeekId, forecastPeriod, forecastStartWeekId, previousForecastWeekId]
  );
};

/**
 * With forecasted data in the YTD view, we want to show the actual
 * data for YTD along with the forecasted data. This returns the date
 * range for YTD.
 */
export const useYtdStartAndEndWeekIdsForForecast = () => {
  // TODO: There is a bug here where the start week is returned as 0 which causes issues, e.g. 189952 being returned as the end week
  const { startWeekId: forecastStartWeekId } = useForecastStartAndEndWeekIds();

  const endWeekId = getPreviousWeekId(forecastStartWeekId);

  return {
    startWeekId: getFirstWeekIdOfYear(yearPartOfWeekId(endWeekId)),
    endWeekId
  };
};

/**
 * Exports forecasts needs this weekIds, for historic data, and forecasts data
 */
export const useExportStartAndEndWeekIds = () => {
  const forecastPeriod = useForecastPeriod();
  const { modelVersion } = useLatestForecastModel();
  const { startWeekId: forecastStartWeekId, endWeekId: forecastEndWeekId } = useForecastStartAndEndWeekIds();
  const { startWeekId: historicStartWeekId, endWeekId: historicEndWeekId } = useForecastComparisonStartAndEndWeekIds();
  // (Assume today is 202410)  if full, historic data request range should start from year 202401. not 202301 like normal forecastComparisonStartAndWeekIds()
  if (forecastPeriod === ForecastPeriod.FULL_YEAR) {
    return {
      historicStartWeekId: getFirstWeekIdOfYear(yearPartOfWeekId(Number(forecastStartWeekId))),
      historicEndWeekId: Number(modelVersion),
      forecastStartWeekId,
      forecastEndWeekId
    };
  }
  return {
    historicStartWeekId,
    historicEndWeekId,
    forecastStartWeekId,
    forecastEndWeekId
  };
};
