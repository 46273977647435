import '../CalendarRangePicker/CalendarRangePickerStyles.scss';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import React, { FC, useRef } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { TextField } from '@mui/material';
import { styled } from '@mui/system';
import { AppInputProps, SlColumn, useStacklineTheme } from '@stackline/ui';
import NumberFormat from 'react-number-format';
import moment from 'moment-timezone';
import { RightArrow } from 'src/components/SvgIcons';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

const CustomTextField = styled(TextField)({
  // override the styles of the MUI classes
  '& .MuiInputBase-root': {
    height: '32px',
    width: '112px',
    borderRadius: '4px',
    border: '1px solid #e8e8ed',
    backgroundColor: '#fff',
    padding: '5px'
  },
  '& .MuiInputLabel-root': {
    display: 'none'
  },

  input: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    padding: '0px'
  }
});

/**
 * Styles for the inputs at the top of the calendar picker
 */
const StaticInputField = styled(TextField)({
  // override the styles of the MUI classes
  '& .MuiInputBase-root': {
    height: '19px',
    width: '96px',
    padding: '3px 8px 3px 9px',
    objectFit: 'contain',
    borderRadius: '4px',
    border: 'solid 1px #e8e8ed',
    backgroundColor: '#fff'
  },
  '& .MuiInputLabel-root': {
    display: 'none'
  },
  'input::-webkit-outer-spin-button,input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: '0'
  },

  'input[type=number]': {
    '-moz-appearance': 'textfield'
  },

  input: {
    height: '19px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#052849',
    padding: '0px',
    '::placeholder': {
      opacity: 1,
      color: '#052849'
    }
  }
});

export interface CalendarRangePickerCustomProps {
  showSelector?: boolean;
  appInputProps?: AppInputProps;
}

const dateTimePaperPropsStyles = {
  sx: {
    '.MuiPickersCalendarHeader-root': {
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center'
    },
    '.MuiPickersCalendarHeader-root:first-child': {
      order: 0,
      paddingRight: '20px',
      paddingLeft: '20px'
    },
    '.MuiPickersArrowSwitcher-root': {
      display: 'inline-flex',
      color: '#052849',
      marginLeft: '-2px'
    },
    '.MuiPickersCalendarHeader-label': {
      textAlign: 'center'
    },
    '.MuiPickersArrowSwitcher-spacer': {
      width: '220px'
    },
    '.MuiPickersFadeTransitionGroup-root': {
      display: 'flex',
      position: 'absolute',
      paddingLeft: '80px'
    },

    '.MuiPickersArrowSwitcher-button': {
      color: '#052849',
      paddingRight: '7px'
    }
  }
};

export const ReadOnlyCustomTextField = (props: any) => {
  return (
    <StaticInputField
      {...props}
      InputProps={{
        readOnly: true
      }}
      id="type-search"
    />
  );
};

export interface CalendarDate {
  date: Date;
}

interface SlCalendarPickerProps {
  /**
   * Determines whether to show calendar popup
   */
  open: boolean;
  /**
   * Handles close event
   */
  onClose: () => void;
  /**
   * Takes in a date and returns a boolean whether or not to disable that date in the picker
   * @param date
   * @returns boolean
   */
  shouldDisableDate: (calendar: CalendarDate) => boolean;
  /**
   * Handler function that captures dates when a date is picked
   * @param selectedDates a collection of 1 or 2 date objects
   */
  handleDateChange: (selectedDates: [Date, Date]) => void;
  /**
   * Controlled selection of dates used to populate the selected calendar dates
   */
  selectedDateRange: Date[];
}
const SlCalendarPicker: FC<SlCalendarPickerProps> = ({
  open,
  handleDateChange,
  shouldDisableDate,
  selectedDateRange,
  onClose
}) => {
  const theme = useStacklineTheme();
  const inputRef = useRef(null);
  const [selectedStartDate, selectedEndDate] = selectedDateRange;

  const formattedStartDate = selectedStartDate ? moment(selectedStartDate).format('MM/DD/YYYY') : '';
  const formattedEndDate = selectedEndDate ? moment(selectedEndDate).format('MM/DD/YYYY') : '';
  return (
    <div style={{ position: 'absolute' }}>
      <DateRangePicker
        open={open}
        onClose={onClose}
        dayOfWeekFormatter={(day) => {
          return day;
        }}
        shouldDisableDate={(date) => shouldDisableDate({ date })}
        closeOnSelect={false}
        value={selectedDateRange}
        onChange={handleDateChange}
        components={{
          PaperContent: ({ children }) => {
            return (
              <SlColumn horizontalPosition="center">
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    backgroundColor: '#ffffff',
                    alignItems: 'center',
                    height: '46px',
                    borderBottom: `1px solid ${theme.colors.primaryGray}`
                  }}
                >
                  <div style={{ marginTop: '14px' }}>
                    <Flex gap="sm" alignItems="center">
                      <NumberFormat
                        value={formattedStartDate}
                        format="##/##/####"
                        placeholder="MM/DD/YYYY"
                        customInput={ReadOnlyCustomTextField}
                      ></NumberFormat>
                      <RightArrow style={{ width: '12px', height: '12px', color: theme.colors.primary }} />
                      <NumberFormat
                        value={formattedEndDate}
                        format="##/##/####"
                        placeholder="MM/DD/YYYY"
                        customInput={ReadOnlyCustomTextField}
                      ></NumberFormat>
                    </Flex>
                  </div>
                  {children}
                </div>
              </SlColumn>
            );
          }
        }}
        PopperProps={{
          ref: inputRef.current,
          placement: 'top',
          popperOptions: {
            placement: 'top'
          },
          sx: dateTimePaperPropsStyles.sx,
          // for some reason PaperProps and not being applied
          // accessing Paper from Popper
          className: 'app-date-range-picker-popper'
        }}
        PaperProps={dateTimePaperPropsStyles}
        renderInput={(startProps, endProps) => {
          startProps.inputProps.placeholder = 'Start week';
          endProps.inputProps.placeholder = 'End week';
          return (
            <div style={{ display: 'none', flexDirection: 'row', alignItems: 'center' }}>
              <CustomTextField
                InputProps={{
                  autoComplete: 'off', // Fix for lastpass bug
                  id: 'type-search', // Fix for lastpass bug
                  startAdornment: (
                    <CalendarTodayIcon
                      style={{
                        color: theme.colors.primary,
                        width: '20px',
                        paddingRight: '5px'
                      }}
                    />
                  )
                }}
                {...startProps}
              ></CustomTextField>
              <span style={{ marginLeft: theme.spacing.md, marginRight: theme.spacing.md }}>to</span>
              <CustomTextField
                InputProps={{
                  autoComplete: 'off', // Fix for lastpass bug
                  id: 'type-search', // Fix for lastpass bug
                  startAdornment: (
                    <CalendarTodayIcon
                      style={{
                        color: theme.colors.primary,
                        width: '20px',
                        paddingRight: '5px'
                      }}
                    />
                  )
                }}
                {...endProps}
              ></CustomTextField>
            </div>
          );
        }}
      />
    </div>
  );
};

export default SlCalendarPicker;
