import React from 'react';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

/**
 * Shows skeletons for product loading row in the compare popup
 */
export default function CompareProductLoadingRow() {
  return (
    <Flex gap="mdl" padding="12px">
      <SlSkeleton variant="rounded" width={30} height={30} />
      <Flex flexDirection="column" gap="sm">
        <SlSkeleton variant="rounded" width={225} height={14} />
        <SlSkeleton variant="rounded" width={175} height={14} />
      </Flex>
    </Flex>
  );
}
