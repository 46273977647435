import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import Waypoint from 'react-waypoint';
import { withBus } from 'react-bus';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';

import { trackExportDownload, trackExportTable } from 'src/utils/mixpanel';
import { DownloadRemove, DownloadCancel } from 'src/components/SvgIcons';

function formatBytes(bytes, decimals) {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1000;
  const dm = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

function disallowRetry(val) {
  return val.error.includes('too many rows') || val.error.includes('shorter time frame');
}

const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

const DownloadTable = ({ items, loadNextPage }) => (
  <div className="download-table">
    <div style={{ width: '100%', height: `${items.length * 72}px` }}>
      {items.map(
        (val, idx) =>
          !val.isDeleted && (
            <div key={val.requestId} className="download-item">
              {idx === items.length - 1 && <Waypoint onEnter={loadNextPage} />}
              <div className="download-item-left">
                <Tooltip title={val.fileName} placement="top">
                  <p>{val.fileName}</p>
                </Tooltip>

                <div className="item-info">
                  <span>{val.date}</span>
                  <span>{val.size}</span>
                </div>
              </div>

              {val.status === 'READY' ? (
                <div className="download-item-right">
                  <button
                    className={val.status === 'READY' && val.downloads ? 'downloaded' : 'download'}
                    onClick={val.download}
                  >
                    DOWNLOAD
                  </button>
                  <button className="delete" onClick={val.delete}>
                    <DownloadRemove />
                  </button>
                </div>
              ) : val.status === 'IN PROGRESS' ? (
                <div className="download-item-right">
                  <span className="item-state in-progress">{val.status}</span>
                  <button className="delete" onClick={val.cancel}>
                    <DownloadCancel />
                  </button>
                </div>
              ) : val.status === 'IN QUEUE' ? (
                <div className="download-item-right">
                  <span className="item-state queued">{val.status}</span>
                  <button className="delete" onClick={val.cancel}>
                    <DownloadCancel />
                  </button>
                </div>
              ) : val.status === 'FAILED' && disallowRetry(val) ? (
                <Tooltip title={val.error} placement="top">
                  <div className="download-item-right">
                    <button className="retry">FAILED</button>
                    <button className="delete" onClick={val.delete}>
                      <DownloadRemove />
                    </button>
                  </div>
                </Tooltip>
              ) : (
                // canceled and failed show the same result
                <div className="download-item-right">
                  <Tooltip title={val.error} placement="top">
                    <button className="retry" onClick={val.retry}>
                      RETRY
                    </button>
                  </Tooltip>
                  <button className="delete" onClick={val.delete}>
                    <DownloadRemove />
                  </button>
                </div>
              )}
            </div>
          )
      )}
    </div>
  </div>
);

DownloadTable.propTypes = {
  items: PropTypes.array.isRequired,
  loadNextPage: PropTypes.func.isRequired
};

class ExportTable extends Component {
  static defaultProps = {};

  static propTypes = {
    app: PropTypes.object.isRequired,
    closeDownloadList: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    showDownloadDone: PropTypes.func.isRequired,
    eventBus: PropTypes.object.isRequired
  };

  state = {
    items: [],
    inProgressItems: [],
    paginationToken: null
  };

  componentDidMount() {
    this.props.eventBus.on('fetchDownloadList', this.fetchDownloads);
    this.fetchDownloads();
    this.ckeckUpdate = setInterval(() => this.fetchForInprogress(), 15000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open && this.props.open) {
      this.fetchDownloads();
      this.loadNextPage();
    }
  }

  componentWillUnmount() {
    this.props.eventBus.off('fetchDownloadList', this.fetchDownloads);
    clearInterval(this.ckeckUpdate);
  }

  fetchDownloads = async () => {
    const apiUrl = `/api/user/GetExportRequests`;
    const response = await axios.post(`${apiUrl}`, {});
    trackExportTable(apiUrl, {});

    const { paginationToken, result } = response.data;
    const items = this.generateItems(result);
    const inProgressItems = this.inProgressItems(items);
    this.setState({ items, inProgressItems, paginationToken });
  };

  fetchNextPage = async (nextPageToken) => {
    const apiUrl = `/api/user/GetExportRequests`;
    const response = await axios.post(`${apiUrl}`, { paginationToken: nextPageToken, pageSize: 15 });
    trackExportTable(apiUrl, { pageSize: 15 });

    const { paginationToken, result } = response.data;
    const items = [...this.state.items, ...this.generateItems(result)];
    const inProgressItems = this.inProgressItems(items);
    this.setState({ items, inProgressItems, paginationToken });
  };

  fetchSpecific = async (requestIds) => {
    const apiUrl = `/api/user/GetFilteredExportRequests`;
    const response = await axios.post(`${apiUrl}`, { requestIds });
    trackExportTable(apiUrl, { requestIds });

    let successfulTasks = response.data.filter(({ status }) => status.toLowerCase().startsWith('finished'));
    if (!_isEmpty(successfulTasks)) {
      successfulTasks = this.generateItems(successfulTasks);
      this.props.showDownloadDone();
      const { items } = this.state;
      const newItems = items.map((item) => successfulTasks.find((task) => task.requestId === item.requestId) || item);
      const inProgress = this.inProgressItems(newItems);
      this.setState({ items: newItems, inProgressItems: inProgress });
    }
  };

  fetchForInprogress = () => {
    // fetch data and update the inProgressItems
    const { inProgressItems } = this.state;

    const now = moment(new Date());
    const requestIds = inProgressItems
      .filter(({ date }) => {
        // Only fetch updates if the download is less than 2 days old
        try {
          const exportTime = moment(date);
          const diff = now.diff(exportTime, 'days');
          return diff <= 1;
        } catch (e) {
          return false;
        }
      })
      .map((item) => item.requestId);

    if (requestIds.length !== 0) {
      this.fetchSpecific(requestIds);
    }
  };

  itemOperation = async (requestId, action) => {
    const apiUrl = `/api/user/UpdateExportRequest?requestId=${requestId}&actionType=${action}`;
    const response = await axios.get(`${apiUrl}`);
    trackExportTable(apiUrl);

    if (_get(response, 'data.status')) {
      const respondTask = this.generateItems([response.data]);
      const { items } = this.state;
      const newItems = items.map((item) => respondTask.find((task) => task.requestId === item.requestId) || item);
      const inProgress = this.inProgressItems(newItems);
      this.setState({ items: newItems, inProgressItems: inProgress });
    }
  };

  inProgressItems = (allItems) =>
    allItems.filter((item) => item.status === 'IN PROGRESS' || item.status === 'IN QUEUE');

  generateItems = (res) => {
    const items = [];
    if (res) {
      res.forEach((item) => {
        const date = new Date(item.requestedTime * 1000);
        const status = this.mapResponseStatusToDisplayStatus(item.status);
        items.push({
          fileName: item.fileName,
          date: formatDate(date),
          size:
            item.status === 'in progress'
              ? 'Calculating...'
              : item.status === 'finished successfully'
              ? formatBytes(item.fileSize)
              : '--',
          status,
          error: status === 'FAILED' ? item.error || 'Internal error' : '',
          isDeleted: item.isDeleted,
          requestId: item.requestId,
          downloads: item.numberOfDownloads,
          download: () => {
            trackExportDownload(item.requestId);
            this.downloadItem(item.requestId);
          },
          delete: () => this.itemOperation(item.requestId, 'delete'),
          cancel: () => this.itemOperation(item.requestId, 'cancel'),
          retry: () => this.itemOperation(item.requestId, 'retry')
        });
      });
    }
    return items;
  };

  mapResponseStatusToDisplayStatus = (responseStatus) =>
    ({
      'finished successfully': 'READY',
      'in progress': 'IN PROGRESS',
      'failed with error': 'FAILED',
      canceled: 'CANCELED'
    }[responseStatus] || 'IN QUEUE');

  downloadItem = (requestId) => {
    const { app } = this.props;
    const { targetUrl } = app;
    const apiUrl = `https://${targetUrl}/api/user/DownloadExportFile?requestId=${requestId}`;
    const a = document.createElement('a');
    a.style = 'display: none';
    a.href = apiUrl;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      a.remove();
      const { items } = this.state;
      items.forEach((item) => {
        if (item.requestId === requestId) {
          item.downloads += 1;
        }
      });
      this.setState(items);
    }, 100);
  };

  loadNextPage = () => {
    const { paginationToken } = this.state;
    if (paginationToken) {
      this.fetchNextPage(paginationToken);
    }
  };

  retryItem = () => {};

  render = () => (
    <div className="export-container">
      {this.props.open && <DownloadTable items={this.state.items} loadNextPage={this.loadNextPage} />}
    </div>
  );
}

const mapStateToProps = ({ app }) => ({ app });

export default connect(mapStateToProps, null)(withBus('eventBus')(ExportTable));
