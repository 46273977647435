import { AdManagerAdCampaignEntity } from 'sl-api-connector/types';

import types from './types';
// ------------------------------------
// Action Creators - they simply return an action
// An action being a payload of information that send data from your application to your store.
// ------------------------------------

const receiveAdCampaigns = (adCampaigns: AdManagerAdCampaignEntity[]) => ({
  type: types.RECEIVE_ADCAMPAIGNS,
  adCampaigns,
  receivedAt: Date.now()
});

const clearAdCampaigns = () => ({ type: types.CLEAR_ADCAMPAIGNS });

export default {
  clearAdCampaigns,
  receiveAdCampaigns
};
