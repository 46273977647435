const REQUEST_SUB_CATEGORIES = 'src/subcategories/REQUEST_SUB_CATEGORIES';
const RECEIVE_SUB_CATEGORIES = 'src/subcategories/RECEIVE_SUB_CATEGORIES';
const CLEAR_SUB_CATEGORIES = 'src/subcategories/CLEAR_SUB_CATEGORIES';
const SORT_SUB_CATEGORIES = 'src/subcategories/SORT_SUB_CATEGORIES';
const RECEIVE_SUPER_USER_SUB_CATEGORIES = 'src/subcategories/RECEIVE_SUPER_USER_SUB_CATEGORIES';

const SALES_BY_SUB_CATEGORY_STATE_KEY = 'mainEntity__retailSales_by_subCategoryId';

export default {
  REQUEST_SUB_CATEGORIES,
  RECEIVE_SUB_CATEGORIES,
  RECEIVE_SUPER_USER_SUB_CATEGORIES,
  CLEAR_SUB_CATEGORIES,
  SORT_SUB_CATEGORIES,
  SALES_BY_SUB_CATEGORY_STATE_KEY
};
