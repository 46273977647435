import React, { CSSProperties, ReactNode, useMemo, useState } from 'react';
import { TableRow, TableCell, Tab, Table, TableHead, TableBody, Typography, Stack, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MediaRow } from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdMediaTable/AdMediaTableRow';
import Tabs from '@mui/material/Tabs';
import useAdGetMediaData from 'src/components/Layout/Advertising/AdMediaManagementLayout/hooks/useAdGetMedia';
import { useAdDeleteMedia } from 'src/components/Layout/Advertising/AdMediaManagementLayout/hooks/useAdDeleteMedia';
import _get from 'lodash/get';
import { AdMediaTableSerch } from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdMediaTable/AdMediaTableSearch';
import { downloadFile } from 'src/utils/app';

const MM_TABS = {
  AVAILABLE: 'AVAILABLE' as const,
  PENDING: 'PENDING' as const,
  FAILED: 'FAILED' as const
};

type TabStatuses = keyof typeof MM_TABS;
export interface MediaData {
  mediaId: number;
  details: string;
  status: TabStatuses;
  name: string;
  thumbnail: string;
  videoUrl: string;
  captionUrl: string;
  autoGenerated: boolean;
  stackLineMediaDetails: {
    mediaDescription: string;
    createdTime: string;
  };
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}
interface StyledTabProps {
  label: string;
}

const SLTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  fontSize: 16,
  marginBottom: 64,
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#052849'
  }
});

const SLTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  marginRight: theme.spacing(1),
  fontSize: 18,
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'left',
  color: '#7e8fa8',

  '&.Mui-focusVisible': {
    backgroundColor: '#052849'
  },
  '&.Mui-selected': {
    color: '#052849',
    fontWeight: 500
  }
}));

const TableCellSL = styled(TableCell)({
  fontSize: 16,
  color: '#052849'
});

interface TabProps {
  tabs: {
    value: string;
    label: string;
    count: number;
  }[];
  activeTab: string;
  onTabChange: (tab: TabStatuses) => void;
}

const MediaTabs: React.FC<TabProps> = ({ tabs, activeTab, onTabChange }) => {
  const activeTabIndex = tabs.findIndex((tab) => tab.value === activeTab);
  return (
    <SLTabs value={activeTabIndex} onChange={(event, newValue) => onTabChange(tabs[newValue].value as TabStatuses)}>
      {tabs.map((tab) => (
        <SLTab key={tab.value} label={`${tab.label} (${tab.count})`} />
      ))}
    </SLTabs>
  );
};

interface MediaTableProps {
  data: MediaData[];
  handleAction: (data: MediaData, actionType: 'delete' | 'downloadCaption' | 'downloadVideo') => void;
  generateTableHeaders: () => ReactNode;
  activeTab: TabStatuses;
  selectedMediaId?: number;
  handleSelect?: (mediaId: number) => void;
}

const MediaTable: React.FC<MediaTableProps> = ({
  data,
  handleAction,
  handleSelect,
  selectedMediaId,
  generateTableHeaders,
  activeTab
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>{generateTableHeaders()}</TableRow>
      </TableHead>
      <TableBody>
        {data &&
          data.map((rowData) => (
            <MediaRow
              activeTab={activeTab}
              handleSelect={handleSelect}
              handleAction={handleAction}
              key={rowData.mediaId}
              data={rowData}
              selected={rowData.mediaId === selectedMediaId}
            />
          ))}
      </TableBody>
    </Table>
  );
};

const dynamicHeaders = [
  { property: 'thumbnail', label: 'Media' },
  { property: 'name', label: 'Media Name' },
  { property: 'stackLineMediaDetails.mediaDescription', label: 'Media Description' },
  { property: 'details', label: 'Failed Reason' },
  { property: 'stackLineMediaDetails.createdTime', label: 'Date Created' },
  { property: 'autoGenerated', label: 'Captions' }
] as const;

const TABS_OPTIONS = [
  {
    value: MM_TABS.AVAILABLE,
    label: 'Available'
  },
  {
    value: MM_TABS.PENDING,
    label: 'Pending'
  },
  {
    value: MM_TABS.FAILED,
    label: 'Failed'
  }
];

const NO_DATA_MESSAGE = {
  [MM_TABS.AVAILABLE]: 'You have no media assets in available status, please create a new media asset.',
  [MM_TABS.FAILED]: 'You have no media assets in failed status.',
  [MM_TABS.PENDING]: 'You have no media assets in pending status.'
};

const AdSkeletonRow = () => {
  const randomLongWidth = () => (Math.random() * (1 - 0.7) + 0.7) * 250;
  const bgColor = '#f7f9fc';

  return (
    <Stack
      spacing={1}
      style={{
        gap: 5,
        marginBottom: 5
      }}
    >
      <div style={{ display: 'flex ', gap: 5, alignItems: 'center', justifyContent: 'space-between' }}>
        <Skeleton sx={{ bgcolor: bgColor }} variant="rectangular" width={randomLongWidth()} height={50} />
        <Skeleton sx={{ bgcolor: bgColor }} variant="rectangular" width={randomLongWidth()} height={50} />
        <Skeleton sx={{ bgcolor: bgColor }} variant="rectangular" width={randomLongWidth()} height={50} />
        <Skeleton sx={{ bgcolor: bgColor }} variant="rectangular" width={randomLongWidth()} height={50} />
      </div>
    </Stack>
  );
};
const AdSkeleton: React.FC<{
  rows: number;
}> = ({ rows }) => {
  const numberOfRows = rows || 6;

  return (
    <div
      style={{
        marginTop: 15,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}
    >
      {[...Array(numberOfRows).keys()].map((i) => {
        return <AdSkeletonRow key={i} />;
      })}
    </div>
  );
};

export const AdMediaTable: React.FC<{
  style?: CSSProperties;
  selectedMediaId?: number;
  setSelectedMediaId?: (mediaId: number) => void;
}> = ({ style = {}, selectedMediaId, setSelectedMediaId }) => {
  const [activeTab, setActiveTab] = useState<TabStatuses>(MM_TABS.AVAILABLE);
  const [searchValue, setSearch] = useState<string | null>(null);

  const { isLoading, data: mediaData, refetch } = useAdGetMediaData({});

  const deleteMediaMutation = useAdDeleteMedia();

  const handleAction = (data: MediaData, actionType: 'delete' | 'downloadCaption' | 'downloadVideo') => {
    if (data && !deleteMediaMutation.isLoading) {
      switch (actionType) {
        case 'delete':
          deleteMediaMutation.mutate(data.mediaId);
          break;
        case 'downloadCaption':
          downloadFile(data.captionUrl, data.name);
          break;
        case 'downloadVideo':
          downloadFile(data.videoUrl, data.name);
          break;
        default:
          break;
      }
    }
  };

  const tabsData = useMemo(() => {
    if (!mediaData) {
      return {};
    }

    return TABS_OPTIONS.reduce((acc, tab) => {
      acc[tab.value] = mediaData.filter((data) => data.status === tab.value);
      return acc;
    }, {} as Record<string, typeof mediaData>);
  }, [mediaData]);

  const tabsWithCounts = TABS_OPTIONS.map((tab) => ({
    ...tab,
    count: (tabsData[tab.value] && tabsData[tab.value].length) || 0
  }));

  const filteredTabsData = useMemo(() => {
    if (!mediaData || !searchValue) {
      return tabsData;
    }

    return TABS_OPTIONS.reduce((acc, tab) => {
      acc[tab.value] = tabsData[tab.value].filter((data) =>
        data.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      return acc;
    }, {} as Record<string, typeof mediaData>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsData, searchValue]);

  const generateTableHeaders = () => {
    if (tabsData && tabsData[activeTab]) {
      return (
        <>
          {setSelectedMediaId &&
            tabsData[activeTab] &&
            tabsData[activeTab].length > 0 &&
            activeTab === MM_TABS.AVAILABLE && <TableCellSL></TableCellSL>}

          {dynamicHeaders.map(({ property, label }) => {
            if (activeTab === MM_TABS.FAILED && property === 'thumbnail') {
              return null;
            } else if (activeTab !== MM_TABS.FAILED && property === 'details') {
              return null;
            } else {
              return (
                _get(tabsData[activeTab], `[0]${property}`) !== undefined && (
                  <TableCellSL key={property}>{label}</TableCellSL>
                )
              );
            }
          })}
          {tabsData[activeTab] && tabsData[activeTab].length > 0 && activeTab === MM_TABS.AVAILABLE && (
            <TableCellSL>Actions</TableCellSL>
          )}
        </>
      );
    }

    return null;
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setSearch(val);
  };

  React.useEffect(() => {
    refetch();
  }, [activeTab]);

  if (isLoading) {
    return <AdSkeleton />;
  }

  return (
    <div style={{ minHeight: 500, ...style }}>
      {/* Your search component goes here */}
      <AdMediaTableSerch doSearch={handleSearch} value={searchValue} />

      <MediaTabs tabs={tabsWithCounts} activeTab={activeTab} onTabChange={setActiveTab} />
      <MediaTable
        generateTableHeaders={generateTableHeaders}
        handleSelect={setSelectedMediaId}
        selectedMediaId={selectedMediaId}
        handleAction={handleAction}
        data={filteredTabsData[activeTab]}
        activeTab={activeTab}
      />
      {tabsData && tabsData[activeTab] && tabsData[activeTab].length === 0 && (
        <div
          style={{
            height: 500,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Typography fontSize={20} fontWeight={500}>
            No data available
          </Typography>
          <Typography fontSize={16}>{NO_DATA_MESSAGE[activeTab]}</Typography>
        </div>
      )}
    </div>
  );
};
