import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import ProductListContainer from 'src/components/BeaconRedesignComponents/MetricList/ProductListContainer';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import { IconsList } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';
import { useProductEntity } from 'src/utils/Hooks/reduxSelectors';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import { removeComparisonFormatter } from 'src/components/BeaconRedesignComponents/MetricList/utils';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import { getWeekColumnDefinition } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import { useCreateRemoveRetailPriceRangeFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';

const fields = [
  { name: 'organicClicks', displayName: 'Organic\nTraffic' },
  {
    name: 'adClicks',
    displayName: 'Paid\nTraffic'
  },
  {
    name: 'otherClicks',
    displayName: 'Other\nTraffic'
  },
  {
    name: 'totalClicks',
    displayName: 'Total\nTraffic'
  },
  {
    name: 'adSpend',
    displayName: 'Paid\nAd Spend'
  },
  {
    name: 'adRetailSales',
    displayName: 'Paid\nAd Sales'
  }
];

/**
 * Aggregation builder for getting the number of products that have other traffic
 */
const aggregationBuilder = (aggBuilder: AggregationBuilder): AggregationBuilder => {
  return aggBuilder.clearConditionRangeFilters().addConditionRangeFilter('otherClicks', 1);
};

/**
 * Other Traffic tab page layout
 */
export default function OtherTrafficPage() {
  const { isProductEntity } = useProductEntity();
  const weekEndingFields = fields.map(removeComparisonFormatter);
  const removeRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();

  return (
    <BeaconPageContainer>
      <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
        <BeaconComparableSplineChart
          indexName="aggregated-traffic"
          fieldName="otherClicks"
          requestBuilder={removeRetailPriceRangeFilters()}
        />
        {!isProductEntity && (
          <BeaconComparableSplineChart
            title="Products with Other Traffic"
            indexName="aggregated-traffic"
            fieldName="stacklineSku"
            requestBuilder={removeRetailPriceRangeFilters()}
            primaryAggregationBuilder={aggregationBuilder}
            secondaryAggregationBuilder={aggregationBuilder}
          />
        )}
        {isProductEntity ? (
          <MetricListContainer
            entityTableHeaderProps={{
              title: 'Other Traffic by Week'
            }}
            indexName="aggregated-traffic"
            sortFieldName="otherClicks"
            fields={weekEndingFields}
            headColumnDefinition={getWeekColumnDefinition({
              getTableColumnProps(defaults) {
                return {
                  ...defaults,
                  style: {
                    ...defaults.style,
                    width: '220px'
                  }
                };
              }
            })}
            postProcessData={(data) => {
              return data.sort((a, b) => b.weekId.localeCompare(a.weekId));
            }}
            useTableDataProps={{
              groupByFieldName: 'weekId',
              filterResponse: () => true,
              itemsPerPage: 100
            }}
            showGrid={false}
          />
        ) : (
          <ProductListContainer
            entityTableHeaderProps={{
              title: 'Other Traffic by Product',
              iconsList: [IconsList.tile, IconsList.table]
            }}
            fields={fields}
            indexName="aggregated-traffic"
            sortFieldName="otherClicks"
            useTableDataProps={{
              buildRequest: removeRetailPriceRangeFilters()
            }}
          />
        )}
      </Flex>
    </BeaconPageContainer>
  );
}
