import { AD_TARGETING_TYPE, ICampaignTypeAdPlatformSettings } from 'sl-ad-campaign-manager-data-model';

import ReduxStore from 'src/types/store/reduxStore';
import { Filters, InstacartParams, SelectedFeaturedProductsItem } from 'src/types/store/storeTypes';
import * as types from './types';

export const initAdBuilder = () => ({ type: types.INIT_AD_BUILDER });

export const setPlatform = (platformId: string) => ({ type: types.SET_PLATFORM, platformId });

export const setPlatformSettings = (
  platformSettings: Partial<NonNullable<ReduxStore['adCampaignBuilder']['platformSettings']>>
) => ({
  type: types.SET_PLATFORM_SETTINGS,
  platformSettings
});

export const setSetup = (setup: Partial<ReduxStore['adCampaignBuilder']['setup']>) => ({
  type: types.SET_SETUP,
  setup
});

export const setCampaignType = (campaignType: ICampaignTypeAdPlatformSettings) => ({
  type: types.SET_CAMPAIGN_TYPE,
  campaignType
});

export const setFeaturedFilters = ({ formData: filters }: { formData: Filters }) => ({
  type: types.SET_FEATURED_PRODUCTS_FILTERS,
  filters
});

export const setFeatured = (featured: Partial<ReduxStore['adCampaignBuilder']['featured']>) => ({
  type: types.SET_FEATURED,
  featured
});

export const setTargetTypeId = (targetingTypeId: AD_TARGETING_TYPE) => ({
  type: types.SET_TARGET_TYPE_ID,
  targetingTypeId
});

export const setCampaignBudget = (totalBudget: string, minimumBid: number, maximumBid: number) => ({
  type: types.SET_CAMPAIGN_BUDGET,
  totalBudget: parseInt(totalBudget, 10),
  minimumBid,
  maximumBid
});

export const setCampaignLifetimeBudget = (lifetimeBudget: number, lifetimeBudgetError: string) => ({
  type: types.SET_CAMPAIGN_LIFETIME_BUDGET,
  lifetimeBudget,
  lifetimeBudgetError
});

export const setInstacartParams = (instacartParams: Partial<InstacartParams>) => ({
  type: types.SET_INSTACART_PARAMS,
  instacartParams
});

export const clearCampaignLifetimeBudget = () => ({
  type: types.CLEAR_CAMPAIGN_LIFETIME_BUDGET
});

export const setCreative = (creative: ReduxStore['adCampaignBuilder']['creative']) => ({
  type: types.SET_CREATIVE,
  creative
});

export const toggleFeaturedProductSelected = (product: SelectedFeaturedProductsItem) => ({
  type: types.TOGGLE_FEATURED_PRODUCT_SELECT,
  product
});

export const toggleOrAddUpcSelected = (upc: string | string[]) => ({
  type: types.TOGGLR_OR_ADD_UPC_SELECTED,
  upc
});

export const addAutoCompaignProducts = (products: any[]) => ({
  type: types.ADD_AUTO_CAMPAIGN_PRODUCTS,
  products
});

export const addAutoBidMapping = (mapping: any) => ({
  type: types.ADD_AUTO_BID_MAPPING,
  mapping
});

export const clearUpc = () => ({
  type: types.CLEAR_UPC
});

export const setSelectedFeaturedProducts = (
  selectedFeaturedProducts: ReduxStore['adCampaignBuilder']['featured']['selectedFeaturedProducts']
) => ({ type: types.SET_SELECTED_FEATURED_PRODUCTS, selectedFeaturedProducts });

export const clearFeatured = () => ({
  type: types.ClEAR_FEATURED
});

export const toggleTargetEntitySelected = (entity: { id: string | number }) => ({
  type: types.TOGGLE_TARGET_ENTITY_SELECTED,
  entity
});

export const bulkSetTargetEntitiesSelected = (entityIds: string[], isSelected: boolean) => ({
  type: types.BULK_SET_TARGET_ENTITIES_SELECTED,
  entityIds,
  isSelected
});

export const setTargetEntities = (targetEntitiesList: { id: string; selected: boolean }[], minimumBid?: number) => ({
  type: types.SET_TARGET_SELECTED_ENTITIES,
  targetEntitiesList,
  minimumBid
});

export const setKeywordList = (newList: any[] | null) => ({
  type: types.SAVE_KEYWORD_LIST,
  newList
});

export const updateKeywordList = (kIdx: number[], tIdx: number, key: string, value: any) => ({
  type: types.UPDATE_KEYWORD_LIST,
  kIdx,
  tIdx,
  key,
  value
});

export const setTargetEntitiesLoading = (loading = true) => ({ type: types.SET_TARGET_ENTITIES_LOADING, loading });

export const setTargetFilters = ({ formData: filters }: { formData: Filters }) => ({
  type: types.SET_TARGET_FILTER,
  filters
});

export const replaceState = (newState: ReduxStore['adCampaignBuilder']) => ({
  type: types.REPLACE_AD_CAMPAIGN_BUILDER_STATE,
  newState
});

export const setUploadedTargetKeywords = (targetKeywords: string[]) => ({
  type: types.SET_UPLOADED_TARGET_KEYWORDS,
  targetKeywords
});

export const setUploadedFeaturedProducts = (retailerSkus: string[]) => ({
  type: types.SET_UPLOADED_FEATURED_PRODUCTS,
  retailerSkus
});

export const setTargetAudience = (audiences: string[]) => ({
  type: types.SET_TARGET_AUDIENCE,
  audiences
});
