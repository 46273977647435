/* eslint-disable react/prop-types */
import React, { useState, Fragment, useMemo } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Truncate from 'react-truncate';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AcIcon } from 'src/components/SvgIcons';
import axios from 'axios';
import colors from 'src/utils/colors';
import Reclassify from '../Reclassify';
import CardView from 'src/components/Card/CardView';
import ReduxStore from 'src/types/store/reduxStore';
import { PropTypesOf } from 'src/types/utils';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { decode } from 'html-entities';
import _get from 'lodash/get';
import { SHORTAGES_FIELDS } from 'src/utils/entityDefinitions/fields/beaconShortagesFieldDefinitions';
import { createFilterParams } from 'src/components/EntityGrid/gridUtils';

const CLASSIFICATION_REASON = {
  WRONG_BREADCRUMB: 'Wrong breadcrumb',
  IS_CORRECT: 'Correctly classified'
};

interface ReclassificationProps {
  Icon: React.ComponentType<SvgIconProps>;
  iconColor: string;
  tooltip: string;
  classificationReason: string;
  retailerId?: number | string;
  productId?: number | string;
  subCategoryId?: number | string;
  onSubmit?: () => {};
  style: {};
}

const ReclassificationIconButton: React.FC<ReclassificationProps> = ({
  Icon,
  iconColor,
  tooltip,
  classificationReason,
  retailerId,
  productId,
  subCategoryId,
  onSubmit = () => {},
  style
}) => {
  const [showRequestError, setShowRequestError] = useState(false);
  const requestUrl = `/api/atlas/Reclassify?productId=${productId}&retailerId=${retailerId}&subCategoryId=${subCategoryId}&classificationReason=${classificationReason}`;

  return (
    <Fragment>
      <Snackbar
        open={showRequestError}
        onClose={() => setShowRequestError(false)}
        autoHideDuration={3000}
        message="Error submitting reclassification request"
      />
      <div style={style}>
        <Tooltip title={tooltip}>
          <IconButton
            onClick={() =>
              axios
                .post(requestUrl)
                .then(onSubmit)
                .catch(() => setShowRequestError(true))
            }
            size="large"
          >
            <Icon style={{ color: iconColor }} />
          </IconButton>
        </Tooltip>
      </div>
    </Fragment>
  );
};

const MarkAsCorrectButton = ({ ...props }) => (
  <ReclassificationIconButton
    Icon={CheckCircleIcon}
    iconColor={colors.green}
    tooltip="Mark as correctly classified"
    classificationReason={CLASSIFICATION_REASON.IS_CORRECT}
    style={{
      position: 'absolute',
      bottom: 35,
      right: 0
    }}
    {...props}
  />
);

interface Props extends RouteComponentProps {
  app: ReduxStore['app'];
  brandId: number;
  cardView: PropTypesOf<typeof CardView> & { stacklineSku: string }; // this type was a bit of improv
  categoryId: number;
  categoryName: string;
  retailerId: string;
  isMouseOver: boolean;
  queryParams: ReduxStore['app']['queryParams'];
  stacklineSku: string;
  subCategoryId?: number;
  subCategoryName?: string;
  breadCrumbSubCategoryName?: string;
  breadCrumbSubCategoryId?: number;
  breadCrumbNodeId?: string;
  breadCrumbText?: string;
  shouldShowReclassify: boolean;
  availabilityStatusCode?: string;
  comparisonTimePeriodDisplayName?: string;
  secondaryMetric?: string;
  isSuperUser?: boolean;
  showReclassifyCheckbox?: () => {};
  imageUri?: string;
  mainDisplayTitle?: string;
  mainMetric?: string;
  secondaryDisplayTitle?: string;
  secondaryMetricClassName?: string;
  shouldDisableClick?: boolean;
  passingFilterParams?: string;
  isAlreadyClassified: boolean;
}

const CardFront: React.FC<Props> = ({
  app,
  availabilityStatusCode = '',
  retailerId,
  brandId,
  cardView,
  categoryName,
  categoryId,
  comparisonTimePeriodDisplayName = '',
  imageUri = 'https://placehold.it/120x120',
  isSuperUser = false,
  isMouseOver,
  mainDisplayTitle = '',
  mainMetric = '',
  queryParams,
  secondaryDisplayTitle = '',
  secondaryMetric = '',
  secondaryMetricClassName = 'plus',
  showReclassifyCheckbox = () => {},
  stacklineSku,
  subCategoryName = '',
  subCategoryId = -1,
  breadCrumbSubCategoryName = '',
  breadCrumbSubCategoryId = -1,
  breadCrumbNodeId = '',
  breadCrumbText = 'Missing breadcrumb text',
  shouldShowReclassify = false,
  shouldDisableClick = false,
  passingFilterParams = '',
  isAlreadyClassified = false
}) => {
  const [hasBeenManuallyReclassified, setHasBeenManuallyReclassified] = useState(false);
  const { name, targetUrl } = app;
  // Get query params from redux
  const { additionalParams, searchParams, filter, subtab } = queryParams;

  const isShortagesSummaryCard = useMemo(() => {
    return subtab === 'shortages';
  }, [subtab]);

  const invertSecondaryMetricClassName: string | null = useMemo(() => {
    // Within the Shortages tab, we only invert colors for certain metrics selected.
    const selectedMetric = _get(cardView, ['metricData', 'name'], '');
    const shouldInvertStyles = [SHORTAGES_FIELDS.totalShortages.name, SHORTAGES_FIELDS.shortageRatio.name].includes(
      selectedMetric
    );

    if (isShortagesSummaryCard && shouldInvertStyles) {
      if (secondaryMetricClassName === 'plus') {
        return 'minus';
      } else if (secondaryMetricClassName === 'minus') {
        return 'plus';
      }
    }
    return null;
  }, [isShortagesSummaryCard, secondaryMetricClassName]);

  // If we have platform filters on the current view, pass them along to the product but don't pass any others. -- Omni retailer page is an exception.
  // If we are in "Omni" retailer view, clicking a product should be applied retailer filter. -- Design team suggest.
  // passingFilterParams are coming from Omni <TileContainer />, it will have filterParams for the retailer .

  const filterParams = createFilterParams(passingFilterParams, filter);

  const productUrl = `/product/${stacklineSku}${searchParams}${additionalParams}${filterParams}`;

  const breadCrumbUrl = `https://${targetUrl}/api/utility/OpenRetailerCategoryPage?appName=${name}&retailerId=${retailerId}&categoryId=${breadCrumbNodeId}`;

  const reclassifyIconButtonProps = {
    productId: cardView.stacklineSku,
    onSubmit: () => setHasBeenManuallyReclassified(true)
  };

  const isAtlasSuperUser = app.name === 'atlas' && isSuperUser;
  const linksTargetValue = isAtlasSuperUser ? '_blank' : '_self';

  const preventVisit = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (shouldDisableClick) {
      e.preventDefault();
    }
  };

  const renderReclassifyCheckBox = () => {
    if (shouldShowReclassify && !hasBeenManuallyReclassified) {
      return (
        <MarkAsCorrectButton {...reclassifyIconButtonProps} subCategoryId={subCategoryId} retailerId={retailerId} />
      );
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      {showReclassifyCheckbox()}
      {shouldShowReclassify && isAlreadyClassified ? (
        <Tooltip title="Already Classified Product">
          <IconButton style={{
            position: 'absolute',
            bottom: 40,
            right: 10
          }}>
            <AcIcon style={{ height: 21, width: 21 }} />
          </IconButton>
        </Tooltip>
      ) : (
        renderReclassifyCheckBox()
      )}
      <div className="entity-card__image-container sl-image-container">
        <div className="sl-image-center">
          <Link
            to={productUrl}
            target={linksTargetValue}
            onClick={(e) => {
              preventVisit(e);
            }}
          >
            <img
              alt={secondaryDisplayTitle || mainDisplayTitle}
              className="entity-card__image sl-image"
              src={imageUri}
              onError={() => {}}
            />
          </Link>
        </div>
      </div>
      <div className="entity-card__info-container">
        <Link
          className="entity-card__brand-name"
          to={`/brand/${brandId}${searchParams}${additionalParams}`}
          target={linksTargetValue}
          onClick={(e) => {
            preventVisit(e);
          }}
        >
          <Truncate lines={1} ellipsis="...">
            {decode(mainDisplayTitle)}
          </Truncate>
        </Link>

        {shouldShowReclassify || isSuperUser ? (
          <div>
            <Link
              className="entity-card__category-name"
              to={`/category/${categoryId}${searchParams}${additionalParams}`}
              target={linksTargetValue}
              onClick={(e) => {
                preventVisit(e);
              }}
            >
              <Truncate lines={1} ellipsis="...">
                {categoryName}
              </Truncate>
            </Link>
            <Link
              className="entity-card__category-name"
              to={`/subcategory/${subCategoryId}${searchParams}${additionalParams}`}
              target={linksTargetValue}
              onClick={(e) => {
                preventVisit(e);
              }}
            >
              <Truncate lines={1} ellipsis="...">
                {subCategoryName}
              </Truncate>
            </Link>
          </div>
        ) : null}

        {shouldShowReclassify && !!breadCrumbText ? (
          <div style={{ fontSize: '10px' }}>
            <div>
              BreadCrumb:&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={breadCrumbUrl}
                onClick={(e) => {
                  preventVisit(e);
                }}
              >
                {breadCrumbText}
              </a>
            </div>
          </div>
        ) : null}
        {shouldShowReclassify ? (
          <Reclassify
            products={[cardView.stacklineSku]}
            predicatedSubCategoryName={breadCrumbSubCategoryName}
            predicatedSubCategoryId={breadCrumbSubCategoryId}
          />
        ) : null}

        <div>
          <Link
            className="entity-card__entity-name"
            to={productUrl}
            title={decode(secondaryDisplayTitle)}
            target={linksTargetValue}
            onClick={(e) => {
              preventVisit(e);
            }}
          >
            {/* TODO HIDE WHEN NOT A PRODUCT page pass the option param down and check it */}
            <Truncate lines={2} ellipsis="...">
              {decode(secondaryDisplayTitle)}
            </Truncate>
          </Link>
        </div>

        <div className="entity-card__metric-container">
          <div className="entity-card__metric">
            <span className="sl-metric__value">{mainMetric}</span>
          </div>
          <div className="entity-card__metric-change">
            <div
              className={`entity-card__icon entity-card__icon--${
                invertSecondaryMetricClassName || secondaryMetricClassName
              }`}
            >
              <span>{secondaryMetric}</span>
            </div>
            <div className="entity-card__period-name">{comparisonTimePeriodDisplayName}</div>
          </div>
        </div>

        {isMouseOver && availabilityStatusCode !== '' ? (
          <div className="entity-card__status-bar">{availabilityStatusCode}</div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default CardFront;
