import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import {
  receiveOmniSearchServiceDataForCompare,
  receiveOmniSearchServiceDataForMain,
  startFetchData,
  endFetchData
} from './actions';
import ReduxStore from 'src/types/store/reduxStore';
import { PERIODS } from '../constants';
import { OmniBaseRequestBody } from 'src/components/Omni/omniRequestUtils';

export interface OmniSearchBody extends OmniBaseRequestBody {
  startWeekId: number;
  endWeekId: number;
}

export const fetchOmniSearchServiceData =
  (apiBody: OmniSearchBody, name: string, period: string) => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
    dispatch(startFetchData({ name, data: [] }));
    try {
      const response = await axios.post('/omni/entityGrid/getSearchEntityGrid', apiBody);
      if (response && response.status === 200) {
        const { data } = response;
        const entity = {
          name,
          data
        };
        if (period === PERIODS.MAIN) {
          dispatch(receiveOmniSearchServiceDataForMain(entity));
        } else {
          dispatch(receiveOmniSearchServiceDataForCompare(entity));
        }
        dispatch(endFetchData({ name, data: [] }));
      }
    } catch (e) {
      console.warn(e);
    }
  };
