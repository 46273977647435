import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useStacklineTheme } from '@stackline/ui';

export default function AuthTitle(props: TypographyProps) {
  const theme = useStacklineTheme();
  return (
    <Typography
      textAlign="center"
      fontSize="34px"
      fontWeight="bold"
      fontFamily="Roboto"
      letterSpacing="normal"
      color={theme.colors.primary}
      {...props}
    />
  );
}
