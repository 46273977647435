import GenericSidebar from 'src/components/BeaconRedesignComponents/GenericSidebarNav/GenericSidebar';
import {
  BEACON_PRO_RIGHT_CONTENT_WIDTH,
  BEACON_PRO_SIDEBAR_RIGHT_PADDING
} from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { buildWidgetGroup, defaultWidgetWrapperStyle } from 'src/components/Layout/LayoutUtil';
import { PageLayout } from 'src/types/application/widgetTypes';

export const withNewBeaconSidebar = (pageLayout: PageLayout) => {
  // Create a widget group out of the base widgets for the layout
  const baseWidgetsGroup = buildWidgetGroup(pageLayout.widgets, {
    view: {
      container: {
        style: {
          ...defaultWidgetWrapperStyle,
          width: BEACON_PRO_RIGHT_CONTENT_WIDTH,
          maxWidth: BEACON_PRO_RIGHT_CONTENT_WIDTH
        }
      }
    }
  });

  // Create a widget for the left nav and add it to the layout along with the base widgets group
  const newBeaconSidebar = {
    CustomComponent: GenericSidebar,
    name: 'leftNav',
    view: {
      name: 'leftNav',
      container: {
        style: {
          display: 'flex',
          flexBasis: 270
        }
      }
    },
    data: {}
  };

  // Style the div that wraps the widgets so that they sidebar and the rest of the widgets display side by side.
  // This container is rendered around both the left nav and the widget group containing the rest of the widgets.
  const containerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: BEACON_PRO_SIDEBAR_RIGHT_PADDING
  };

  return { ...pageLayout, containerStyle, widgets: [newBeaconSidebar, baseWidgetsGroup] };
};
