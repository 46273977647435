import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import OmniGeoMapHeader from './OmniGeoMapHeader';
import ReduxStore from 'src/types/store/reduxStore';
import { omniGeoMapServiceOperations } from 'src/store/modules/omni/omniGeoMap';
import { OmniGeoMapServiceBody } from 'src/store/modules/omni/omniGeoMap/operations';
import { DATATYPE, METRICTYPE } from 'src/utils/entityDefinitions/entityDefinitionTypes';
import Loading from 'src/components/common/Loading';
import { Widget } from 'src/types/application/widgetTypes';
// import OmniGeoCardPartContainerRenewal from 'src/components/Omni/OmniGeoMap/OmniGeoCardViewRenewal/OmniGeoCardPartContainerRenewal';
import OmniGeoTimeLapseControls from 'src/components/Omni/OmniGeoMap/OmniGeoTimeLapseControls';
import { mappingPointToWeekID } from 'src/components/Omni/OmniGeoMap/OmniGeoTimeLapseControls/omniSliderUtils';
import { trackTheDataSwitching } from 'src/utils/mixpanel';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';
import { deleteMapData } from 'src/store/modules/omni/omniGeoMap/actions';
import { subtractWeeks } from 'src/utils/dateformatting';
import {
  mapDetectorByClick,
  mapIndexGenerator,
  mapNameGenerator,
  groupByGenerator,
  payloadGenerator,
  tabNameGenerator
} from 'src/components/Omni/OmniGeoMap/geoMapUtils';
import OmniGeoMapRenewal from 'src/components/Omni/OmniGeoMap/OmniGeoMapRenewal';
import OmniGeoCardContainerRenewal from 'src/components/Omni/OmniGeoMap/OmniGeoCardViewRenewal/OmniGeoCardContainerRenewal';
import OmniSummaryHeader from 'src/components/Omni/OmniSummaryHeader';
import { useQuery } from 'react-query';
import axios from 'axios';

const { fetchOmniGeoMapServiceData } = omniGeoMapServiceOperations;

interface OmniGeoMapProps extends RouteComponentProps {
  widget: Widget;
  eventBus: EventBus;
}

export interface OmniMapDataMetric {
  name: string;
  displayName: string;
  fulfillmentType?: string;
  aggregationFunction: string;
  metricName: string;
  dataType: string;
  metricType: METRICTYPE;
}

const OmniGeoMapContainer: React.FC<OmniGeoMapProps> = ({ widget, location, eventBus }: OmniGeoMapProps) => {
  const tabsForCardContainer: {
    name: string;
    displayName: string;
  }[] = [
    {
      name: 'all',
      displayName: 'ALL'
    },
    {
      name: 'increasing',
      displayName: 'INCREASING'
    },
    {
      name: 'decreasing',
      displayName: 'DECREASING'
    }
  ];
  const { name } = widget;
  const [reloadData, setReloadData] = useState(false);
  const [curCardContainerTab, setCurCardContainerTab] = useState(tabsForCardContainer[0]);
  const [selectedOptions, setSelectedOptions] = useState({
    tab: {
      name: 'inventory',
      displayName: 'Inventory'
    },
    subTab: {
      name: 'omniInStock',
      displayName: 'In-Stock Rate',
      aggregationFunction: 'avg',
      metricName: 'InStockRate',
      dataType: DATATYPE.DECIMAL,
      metricType: METRICTYPE.PERCENT
    }
  });

  const omniRegionsFollowing = useSelector((store: ReduxStore) => store.omniRegionsFollowing);
  const omniCountriesFollowing = useSelector((store: ReduxStore) => store.omniCountriesFollowing);

  const [currentRegion] = useState(omniRegionsFollowing);
  const [currentCountry] = useState(omniCountriesFollowing);

  const [, setCurrentMapLevelIndex] = useState(mapIndexGenerator(currentRegion, currentCountry));

  const [currentSelectedHCKey, setCurrentSelectedHCKey] = useState(null);

  const [mapName, setMapName] = useState(mapNameGenerator(currentRegion, currentCountry, []));

  const [tabInfo, setTabName] = useState(tabNameGenerator(mapName));

  // To detect top level, to bottom level, we will store mapName is history to go back function
  const [mapHistory, setMapHistory] = useState([mapNameGenerator(omniRegionsFollowing, omniCountriesFollowing, [])]);

  const [currentSliderValue, setCurrentSliderValue] = useState(0);

  const [playing, setPlaying] = useState(false);

  const { dataType, metricType, metricName } = selectedOptions.subTab;

  const mainTimePeriod = useSelector((store: ReduxStore) => store.mainTimePeriod);

  const comparisonTimePeriod = useSelector((state: ReduxStore) => state.comparisonTimePeriod);

  const geoName = `${name}-mapName`;
  // Need more than 1 week to show the time lapse effect
  const shouldDisableTimeLapse = true;

  // const [shouldDisableTimeLapse, setShouldDisableTimeLapse] = useState(
  //   mainTimePeriod.endWeek - mainTimePeriod.startWeek < 2
  // );

  const mapDataState = useSelector((store: ReduxStore) => store.omniGeoMapService[geoName]);

  const mapDataStateByWeekPrePeriod = useSelector(
    (store: ReduxStore) => store.omniGeoMapService[`${geoName}byWeekPrePeriod`]
  );

  const mapDataStateSeparateByWeek = useSelector(
    (store: ReduxStore) => store.omniGeoMapService[`${name}byWeeklocationStateName`]
  );

  const mapDataStateSeparateByWeekPrePeriod = useSelector(
    (store: ReduxStore) => store.omniGeoMapService[`${name}byWeekPrePeriodlocationStateName`]
  );

  const user = useSelector((state: ReduxStore) => state.user);

  const { startWeek, endWeek, id: mainTimePeriodID } = mainTimePeriod;
  const { startWeek: startWeekForCompare, endWeek: preEndWeekForCompare } = comparisonTimePeriod;

  const maxSliderValue = subtractWeeks(startWeekForCompare, endWeek) - 1;

  const mapData = mapDataState && mapDataState.data ? mapDataState.data : [];

  const mapDataByWeekDataPrePeriod =
    mapDataStateByWeekPrePeriod && mapDataStateByWeekPrePeriod.data ? mapDataStateByWeekPrePeriod.data : [];

  const mapDataStateWeek =
    !mapDataStateSeparateByWeek || mapDataStateSeparateByWeek.isFetching ? null : mapDataStateSeparateByWeek.data;

  const mapDataStateWeekPrePeriod =
    !mapDataStateSeparateByWeekPrePeriod || mapDataStateSeparateByWeekPrePeriod.isFetching
      ? null
      : mapDataStateSeparateByWeekPrePeriod.data;

  const { data: mapGeoJson, isLoading: geoJsonLoading } = useQuery({
    queryKey: ['omniGeoMapService', mapName],
    queryFn: () => {
      return axios.get(
        `https://s3.us-west-2.amazonaws.com/stackline-website-repo-static-assets/@highcharts/map-collection/${mapName}.geo.json`
      );
    },
    staleTime: 10 * 60 * 1000 // 10 minutes
  });

  const isFetchingMapData = geoJsonLoading || (mapDataState?.isFetching ?? true);

  const filters = useSelector((state: ReduxStore) => state.filters);
  const dispatch = useDispatch();

  const resetMap = () => {
    setMapName(mapNameGenerator(currentRegion, currentCountry, []));
    setMapHistory([mapNameGenerator(omniRegionsFollowing, omniCountriesFollowing, [])]);
  };

  const fetchData = useCallback(() => {
    const { tab, subTab } = selectedOptions;
    const { displayName: tabDisplayName } = tab;
    const { displayName: subTabDisplayName } = subTab;
    trackTheDataSwitching(`Map-${tabDisplayName}-${subTabDisplayName}`, location, user, {
      tabDisplayName,
      subTabDisplayName
    });

    const groupBy = groupByGenerator(mapName);
    const baseRequestBody: OmniGeoMapServiceBody = {
      retailerIds: [],
      includeBrandIds: [],
      includeCategoryIds: [],
      includeSubCategoryIds: [],
      includeLocationRegionCode: [],
      includeLocationCountryCode: [],
      includeLocationState: [],
      startWeekId: startWeek,
      endWeekId: endWeek,
      groupBy,
      aggregationMetrics: [subTab]
    };

    const [payloadProperty, value] = payloadGenerator(mapName, currentSelectedHCKey);

    if (payloadProperty) {
      baseRequestBody[payloadProperty].push(value);
    }
    const requestBody = addFilterToOmniBaseReqBody(baseRequestBody, filters, '');
    setCurCardContainerTab({
      name: 'all',
      displayName: 'ALL'
    });
    dispatch(fetchOmniGeoMapServiceData(requestBody, geoName));

    const requestBodyByWeekPrePeriod = {
      ...requestBody,
      startWeekId: startWeekForCompare,
      endWeekId: preEndWeekForCompare
    };
    dispatch(fetchOmniGeoMapServiceData(requestBodyByWeekPrePeriod, `${geoName}byWeekPrePeriod`));
  }, [
    mapName,
    selectedOptions,
    location,
    user,
    startWeek,
    endWeek,
    filters,
    dispatch,
    name,
    startWeekForCompare,
    preEndWeekForCompare
  ]);

  useEffect(() => {
    dispatch(
      deleteMapData({
        mapName: name,
        data: []
      })
    );
    dispatch(
      deleteMapData({
        mapName: `${name}byWeekPrePeriodlocationState`,
        data: []
      })
    );
    dispatch(
      deleteMapData({
        mapName: `${name}byWeeklocationState`,
        data: []
      })
    );
    // setShouldDisableTimeLapse(true);
    setCurrentSliderValue(0);
    fetchData();
    setReloadData(false);
  }, [fetchData, dispatch, name]);

  useEffect(() => {
    eventBus.on('omniPrimaryNavBarMapClick', resetMap);
    return () => {
      eventBus.off('omniPrimaryNavBarMapClick', resetMap);
    };
  }, [eventBus]);

  useEffect(() => {
    setTabName(tabNameGenerator(mapName));
  }, [mapName]);

  const buildMapSeries = useCallback(() => {
    const chartData: {
      'hc-key': string;
      value: number;
      preValue?: number;
    }[] = [];
    const { subTab } = selectedOptions;
    const { name: dataIndexName } = subTab;
    // showing slider = > country level
    if (!shouldDisableTimeLapse) {
      if (mapDataStateWeekPrePeriod && mapDataStateWeek) {
        const correctWeekId = mappingPointToWeekID(currentSliderValue, mainTimePeriod, comparisonTimePeriod);
        let searchArray = mapDataStateWeekPrePeriod;
        if (correctWeekId >= startWeek && correctWeekId <= endWeek) {
          searchArray = mapDataStateWeek;
        }
        searchArray.forEach((d) => {
          const weekDataObj = d.weekId.buckets.find((e) => e.key === correctWeekId);
          if (weekDataObj && weekDataObj[dataIndexName] && weekDataObj[dataIndexName].value !== 0) {
            chartData.push({
              'hc-key': d.key,
              value: weekDataObj[dataIndexName].value
            });
          }
        });
      }

      return _orderBy(chartData, 'hc-key', 'asc');
    } else {
      const mapPrePeriodData = new Map();

      mapDataByWeekDataPrePeriod.forEach((d) => {
        if (d[dataIndexName]) {
          mapPrePeriodData.set(d.key, d[dataIndexName].value);
        }
      });

      let percentChange = 0;

      mapData.forEach((item) => {
        if (item[dataIndexName] && item[dataIndexName].value !== 0) {
          const preDataValue = mapPrePeriodData.get(item.key);

          percentChange = preDataValue === 0 ? 1 : (item[dataIndexName].value - preDataValue) / preDataValue;
          if (item[dataIndexName].value === preDataValue) {
            percentChange = 0;
          }
          if (percentChange > 0 && percentChange <= 0.0005) {
            percentChange = 0.001;
          }
          if (percentChange < 0 && percentChange >= -0.0005) {
            percentChange = -0.001;
          }

          chartData.push({
            'hc-key': item.key.toLowerCase(),
            value: item[dataIndexName].value,
            preValue: percentChange
          });
        }
      });
    }

    return _orderBy(chartData, 'hc-key', 'asc');
  }, [
    selectedOptions,
    shouldDisableTimeLapse,
    mapDataStateWeekPrePeriod,
    mapDataStateWeek,
    currentSliderValue,
    mainTimePeriod,
    comparisonTimePeriod,
    startWeek,
    endWeek,
    mapData,
    mapDataByWeekDataPrePeriod
  ]);

  const chartSeries = buildMapSeries();

  const tabOptions = [
    {
      name: 'price',
      displayName: 'Price'
    },
    {
      name: 'inventory',
      displayName: 'Inventory'
    },
    {
      name: 'orderType',
      displayName: 'Order Type'
    }
  ];

  const subTabOptions: {
    [key: string]: OmniMapDataMetric[];
  } = {
    price: [
      {
        name: 'omniRetailPrice',
        displayName: 'RETAIL PRICE',
        aggregationFunction: 'avg',
        metricName: 'RetailPrice',
        dataType: DATATYPE.DECIMAL,
        metricType: METRICTYPE.MONEY
      },
      {
        name: 'omniPromotions',
        displayName: 'PROMOTIONS',
        aggregationFunction: 'value_count',
        metricName: 'Promotion',
        dataType: DATATYPE.INTEGER,
        metricType: METRICTYPE.VOLUME
      },
      {
        name: 'omniPriceViolations',
        displayName: 'VIOLATIONS',
        aggregationFunction: 'value_count',
        metricName: 'PriceViolation',
        dataType: DATATYPE.INTEGER,
        metricType: METRICTYPE.VOLUME
      }
    ],
    inventory: [
      {
        name: 'omniInStock',
        displayName: 'IN-STOCK RATE',
        aggregationFunction: 'avg',
        metricName: 'InStockRate',
        dataType: DATATYPE.DECIMAL,
        metricType: METRICTYPE.PERCENT
      },
      {
        name: 'omniStoresInStock',
        displayName: 'STORES IN-STOCK',
        aggregationFunction: 'cardinality',
        metricName: 'locationId.keyword',
        dataType: DATATYPE.INTEGER,
        metricType: METRICTYPE.VOLUME
      },
      {
        name: 'omniStoresOutOfStock',
        displayName: 'STORES OUT-OF-STOCK',
        aggregationFunction: 'cardinality',
        metricName: 'locationId.keyword',
        dataType: DATATYPE.INTEGER,
        metricType: METRICTYPE.VOLUME
      }
    ],
    orderType: [
      {
        name: 'omniDeliveryTime',
        displayName: 'DELIVERY TIME',
        fulfillmentType: 'delivery',
        aggregationFunction: 'avg',
        metricName: 'Time',
        dataType: DATATYPE.DECIMAL,
        metricType: METRICTYPE.DECIMAL
      },
      {
        name: 'omniShippingTime',
        displayName: 'SHIPPING TIME',
        fulfillmentType: 'shipping',
        aggregationFunction: 'avg',
        metricName: 'Time',
        dataType: DATATYPE.DECIMAL,
        metricType: METRICTYPE.DECIMAL
      },
      {
        name: 'omniPickupTime',
        displayName: 'PICKUP TIME',
        fulfillmentType: 'pickUp',
        aggregationFunction: 'avg',
        metricName: 'Time',
        dataType: DATATYPE.DECIMAL,
        metricType: METRICTYPE.DECIMAL
      }
    ]
  };

  const handleCardContainerTabChange = (tabIndex: number) => {
    setCurCardContainerTab(tabsForCardContainer[tabIndex]);
  };

  const updateSelectedTab = (tabName: any) => {
    const [newTab] = tabOptions.filter((option) => option.name === tabName);
    const subTabs = subTabOptions[newTab.name];
    const newSubTab = subTabs[0];
    setReloadData(true);
    setSelectedOptions({
      tab: newTab,
      subTab: newSubTab
    });
  };

  const updateSelectedSubTab = (subTabName: any) => {
    const { tab } = selectedOptions;
    const subTabs = subTabOptions[tab.name];
    const [newSubTab] = subTabs.filter((option: any) => option.name === subTabName);
    setReloadData(true);
    setSelectedOptions({
      tab,
      subTab: newSubTab
    });
  };

  const onSliderChange = (nextValue: number | number[]) => {
    setCurrentSliderValue(nextValue);
  };

  const onForwardButtonClick = useCallback(() => {
    if (!shouldDisableTimeLapse) {
      setCurrentSliderValue((preSlideValue) => {
        if (preSlideValue + 1 <= maxSliderValue) {
          return preSlideValue + 1;
        }
        setPlaying(false);
        return maxSliderValue;
      });
    }
  }, [maxSliderValue, shouldDisableTimeLapse]);

  const onReverseButtonClick = () => {
    if (!shouldDisableTimeLapse) {
      setCurrentSliderValue((preSlideValue) => {
        if (preSlideValue - 1 >= 0) {
          return currentSliderValue - 1;
        }
        return 0;
      });
    }
  };

  // const handleSliderToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setShouldDisableSlider(!event.target.checked);
  //   if (!event.target.checked) {
  //     setPlaying(false);
  //   } else {
  //     setPlaying(true);
  //   }
  //   // give the default value
  //   onSliderChange(0);
  // };

  const handlePlayingButton = () => {
    if (!shouldDisableTimeLapse) {
      setPlaying(!playing);
    }
  };

  useEffect(() => {
    if (playing) {
      const intervalId = setInterval(() => {
        onForwardButtonClick();
      }, 800);
      return () => clearInterval(intervalId);
    }
    return () => {};
  }, [playing, maxSliderValue, onForwardButtonClick]);

  const propsForCardPartContainer = {
    name,
    metricFroData: selectedOptions.subTab,
    tabsForCardContainer,
    handleCardContainerTabChange,
    curCardContainerTab,
    startWeek,
    endWeek,
    comparisonTimePeriod,
    location,
    user,
    chartSeries,
    mapName,
    mapHistory
  };

  const extractRegion = (event: any) => {
    // if us in the county level, and not-us state level, we prevent the event
    if (mapName.split('/').length === 3 && mapName !== 'countries/us/us-all') {
      return;
    }

    if (mapName === 'countries/gb/custom/gb-countries') {
      return;
    }

    const mapKey = _get(event, "target.point['hc-key']", '');
    const eventName = _get(event, "target.point['name']", '');

    setReloadData(true);
    setCurrentMapLevelIndex((prev) => prev + 1);
    setCurrentSelectedHCKey(mapKey);
    setMapHistory((prev) => [...prev, mapDetectorByClick(mapKey, eventName, mapName)]);
    setMapName(mapDetectorByClick(mapKey, eventName, mapName));
  };

  const onBackClickHandler = () => {
    const currentIndex = mapHistory.findIndex((hist) => hist === mapName);
    setCurrentMapLevelIndex((prev) => prev - 1);

    setReloadData(true);
    // we don't have to think about if it goes less then 0, since the back button will be disappear.
    setMapName(mapHistory[currentIndex - 1]);
    setMapHistory((prev) => prev.slice(0, -1));
  };

  return (
    <div>
      <OmniSummaryHeader
        widget={{
          name: 'summaryHeaderForMap',
          data: {},
          view: {
            name: 'summaryHeaderForMap',
            summaryHeader: {
              headerTabOpts: tabInfo
            }
          }
        }}
        i={0}
        noInnerContainer={false}
        pageLayout={undefined}
        conditions={undefined}
        entityConditions={undefined}
        queryConditions={undefined}
        aggregationConditions={undefined}
      />
      <OmniGeoMapHeader
        tabOptions={tabOptions}
        subTabOptions={subTabOptions}
        selectedTab={selectedOptions.tab}
        selectedSubTab={selectedOptions.subTab}
        updateSelectedTab={updateSelectedTab}
        updateSelectedSubTab={updateSelectedSubTab}
      />
      {reloadData || isFetchingMapData ? (
        <div
          style={{
            position: 'relative',
            margin: 'auto',
            height: '400px',
            width: '400px'
          }}
        >
          <Loading className="spinner" size={150} thickness={2} />
        </div>
      ) : (
        <>
          <div>
            <OmniGeoMapRenewal
              mapHistory={mapHistory}
              shouldDisableSlider={shouldDisableTimeLapse}
              chartSeries={chartSeries}
              mapName={mapName}
              mapGeoJson={mapGeoJson?.data}
              metricName={metricName}
              dataType={dataType}
              metricType={metricType}
              mapClick={extractRegion}
              onBackClickHandler={onBackClickHandler}
            />
          </div>

          {(mainTimePeriodID === '4w' || mainTimePeriodID === '13w') &&
          comparisonTimePeriod.id === 'prior-period' &&
          mapName === 'us-all' ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <OmniGeoTimeLapseControls
                currentSliderValue={currentSliderValue}
                onSliderChange={onSliderChange}
                mainTimePeriod={mainTimePeriod}
                comparisonTimePeriod={comparisonTimePeriod}
                shouldDisableSlider={shouldDisableTimeLapse}
                handlePlayingButton={handlePlayingButton}
                playing={playing}
                onForwardButtonClick={onForwardButtonClick}
                onReverseButtonClick={onReverseButtonClick}
                max={maxSliderValue}
              />
            </div>
          ) : null}
          <OmniGeoCardContainerRenewal
            {...propsForCardPartContainer}
            mapName={mapName}
            currentSelectedHCKey={currentSelectedHCKey}
          />
        </>
      )}
    </div>
  );
};

export default withRouter(withBus('eventBus')(OmniGeoMapContainer));
