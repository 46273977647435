import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _unionWith from 'lodash/unionWith';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _orderBy from 'lodash/orderBy';
import _pick from 'lodash/pick';
import _prop from 'lodash/property';
import axios from 'axios';
import numeral from 'numeral';

import { propEq, not } from 'src/utils/fp';
import { buildWidgetUniqueName } from 'src/components/EntityPage/Widget';
import Tabs from 'src/components/common/Tabs/Tabs';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { mergeConditions } from 'sl-api-connector/search/conditions';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { getMetricFieldForSelectedField } from 'src/components/EntityPage/WaterfallChart/waterfallCompute';
import EntityMetricDropDown from 'src/components/common/DropDown/EntityMetricDropDown';
import { anyNotEq, keyNotEq } from 'src/utils/equality';
import {
  buildDefaultConditions,
  buildEntityConditions,
  buildMainEntityConditions,
  buildAggregationConditions,
  buildEntityMarketShareConditions as buildEntityMarketShareDenominatorConditions
} from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { mkActiveMetricSwitcherHCF } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import EntityGridRenderer from 'src/components/EntityGrid/EntityGrid/EntityGridRenderer';
import KeywordsFilter from 'src/components/EntityPage/WaterfallChart/Insights/KeywordsFilter';
import FeaturedEntitiesDialog from 'src/components/EntityPage/WaterfallChart/Insights/FeaturedEntitiesDialog';
import { buildWidgetConfig } from './TrafficInsightsLayout';
import './TrafficInsights.scss';
import { filterNils } from 'sl-api-connector/util';
import { buildAggregations } from 'src/components/AdManager/Search';

const { CancelToken } = axios;

export const invertSortDirection = (sortDirection) => (sortDirection === 'asc' ? 'desc' : 'asc');

const createSearchTermsFromQuery = (query) => query.split(/\s+/g).filter(not(_isEmpty));

const buildFilterRows = ({ include, exclude, range }, isOnPaidTrafficTab) => {
  const rangeValue = range && range.value ? parseFloat(range.value) : null;
  const checkIsRangeValid = range
    ? range.cmp === 'above'
      ? (value) => value >= rangeValue
      : (value) => value <= rangeValue
    : null;
  const includeWords = include ? createSearchTermsFromQuery(include) : null;
  const excludeWords = exclude ? createSearchTermsFromQuery(exclude) : null;

  return (rowDatum) => {
    if (include) {
      // TODO: Swap for some distance metric rather than exact matches
      if (!includeWords.every((word) => rowDatum.name.includes(word))) {
        return false;
      }
    }

    if (exclude) {
      if (excludeWords.find((word) => rowDatum.name.includes(word))) {
        return false;
      }
    }

    if (range && rangeValue && isOnPaidTrafficTab) {
      const dataKeyName = `${range.metric}_by_searchKeyword_current`;
      const value = rowDatum[dataKeyName];
      const isValid = checkIsRangeValid(value);
      if (!isValid) {
        return false;
      }
    }

    // Retain the row by default if no filters apply
    return true;
  };
};

const buildCampaignTermFilters = (campaignFilters) => {
  const termFilters = [];
  if (!campaignFilters) {
    return termFilters;
  }
  const { names: campaignIds = [], types = [] } = campaignFilters;

  if (!_isEmpty(campaignIds) && campaignIds[0] && campaignIds[0] !== 'allCampaigns') {
    termFilters.push({
      condition: 'should',
      fieldName: 'campaignId',
      values: campaignIds
    });
  }

  if (!_isEmpty(types) && !!types[0] && types[0] !== 'allAdTypes') {
    termFilters.push({
      condition: 'should',
      fieldName: 'campaignType',
      values: types
    });
  }

  return termFilters;
};

const EMPTY_FILTERS = {
  include: '',
  exclude: '',
  campaign: { names: [], types: [] },
  range: { metric: 'costPerClick', cmp: 'above', value: '' }
};

class TrafficInsights extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    eventBus: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    allWeekIdsByRetailerId: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    aggregationConditions: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired,
    entitySearchService: PropTypes.object.isRequired,
    conditions: PropTypes.object.isRequired,
    queryConditions: PropTypes.object.isRequired,
    widget: PropTypes.object.isRequired,
    queryParams: PropTypes.object.isRequired,
    fetchEntityMetrics: PropTypes.func.isRequired,
    clearEntitySearchService: PropTypes.func.isRequired,
    selectedField: PropTypes.object.isRequired,
    waterfallImpactData: PropTypes.array.isRequired,
    waterfallImpactGridTitle: PropTypes.string.isRequired,
    unitsSoldImpactConsolidated: PropTypes.object.isRequired
  };

  state = {
    isLoading: true,
    pageNum: 1,
    selectedTab: null,
    sortField: null,
    featuredEntitiesData: { open: false, selectedEntity: '', selectEntityMetricCurrentValue: 0 },
    filters: EMPTY_FILTERS
  };

  componentDidMount() {
    this.cancelSource = CancelToken.source();
    this.fetchData(this.props);
    this.addEventListeners();
  }

  componentWillUnmount() {
    this.removeEventListeners();
    this.cancelSource.cancel('Cancel network request');
  }

  componentWillReceiveProps(nextProps) {
    const propKeysToCompare = [
      'location.pathname',
      'location.search',
      'conditions',
      'entityService.mainEntity',
      'entityService.comparisonEntity',
      'filters',
      'widget',
      'selectedField'
    ];

    if (anyNotEq(propKeysToCompare, this.props, nextProps)) {
      if (this.cancelSource) {
        this.cancelSource.cancel('Canceled network request');
      }

      this.cancelSource = CancelToken.source();
      this.fetchData(nextProps);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_isEqual(this.state, nextState)) {
      return true;
    }
    const { entitySearchService, selectedField } = this.props;
    const { entitySearchService: nextEntitySearchService, selectedField: nextSelectedField } = nextProps;
    if (!_isEqual(selectedField, nextSelectedField)) {
      return true;
    }

    const {
      entitySearchServiceStateNamesMainEntity = [],
      entitySearchServiceStateNamesComparisonEntity = [],
      entitySearchServiceStateNamesMarketShare = []
    } = this.state;

    return !![
      ...entitySearchServiceStateNamesMainEntity,
      ...entitySearchServiceStateNamesComparisonEntity,
      ...entitySearchServiceStateNamesMarketShare
    ].find((stateName) => keyNotEq(stateName, entitySearchService, nextEntitySearchService));
  }

  addEventListeners = () => {
    const {
      eventBus,
      widget: {
        view: { eventListeners }
      }
    } = this.props;

    if (eventListeners && eventListeners.length > 0) {
      eventListeners.forEach((event) => {
        eventBus.on(event.name, this.fetchData);
      });
    }

    eventBus.on('showFeaturedEntities', this.showFeaturedEntitiesDialog);
    eventBus.on('waterfallInsightsSortData', this.handleSortData);
    eventBus.on('gridSwapActiveFieldName', this.handleSwapActiveFieldName);
    eventBus.on('waterfallTrafficInsightsToggleGroupBy', this.handleGroupByChange);
  };

  removeEventListeners = () => {
    const {
      eventBus,
      widget: {
        view: { eventListeners }
      }
    } = this.props;

    if (eventListeners && eventListeners.length > 0) {
      eventListeners.forEach(({ name }) => eventBus.off(name, this.fetchData));
    }

    eventBus.off('showFeaturedEntities', this.showFeaturedEntitiesDialog);
    eventBus.off('waterfallInsightsSortData', this.handleSortData);
    eventBus.off('gridSwapActiveFieldName', this.handleSwapActiveFieldName);
    eventBus.off('waterfallTrafficInsightsToggleGroupBy', this.handleGroupByChange);
  };

  fetchData = (props = this.props) => {
    const {
      app,
      conditions,
      aggregationConditions: propAggregationConditions,
      retailer,
      allWeekIdsByRetailerId,
      categories,
      subCategories,
      segments,
      queryParams,
      filters,
      mainTimePeriod,
      comparisonTimePeriod,
      match,
      entityService: { mainEntity },
      fetchEntityMetrics,
      selectedField
    } = props;

    if (!mainEntity) {
      return;
    }

    let { id: idFromUrl } = match.params;
    if (!idFromUrl || idFromUrl === '') {
      idFromUrl = mainEntity.id.toString();
    }

    // the main entity has to match with what is in the current url
    if (idFromUrl !== `${mainEntity.id}` && idFromUrl !== mainEntity.hashId) {
      return;
    }

    const parentWidget = buildWidgetConfig({ app, entity: mainEntity, retailer, selectedField });
    const configForSelectedField = parentWidget.widgetConfigByMetric[selectedField.name];

    // if this is not supported metric (only traffic metrics), we don't need to render the widget
    if (!selectedField || !configForSelectedField) {
      this.setState({ isLoading: false, parentWidget });
      return;
    }

    const selectedGroupByField = configForSelectedField.groupByFields.find(_prop('isSelected'));
    const trafficInsightsWidget = configForSelectedField.widgetConfigByGroupByField[selectedGroupByField.name];

    if (trafficInsightsWidget.CustomComponent) {
      this.setState({ isLoading: false, parentWidget, trafficInsightsWidget });
      return;
    }
    this.setState({ isLoading: true, parentWidget, trafficInsightsWidget });

    // We must have segments loaded in order to build conditions for segments.  If we don't have them and need them, try
    // fetching again in a bit.
    if (
      ['segment', 'searchtermlist', 'businessunit', 'businessUnit'].includes(mainEntity.type) &&
      !segments.savedSearchesById
    ) {
      setTimeout(() => this.fetchData(props), 100);
      return;
    }

    const defaultConditions = buildDefaultConditions(conditions, queryParams);
    const entityConditions = buildEntityConditions(app, categories, defaultConditions, mainEntity);
    const mainEntityConditions = buildMainEntityConditions(entityConditions, mainEntity, app, retailer, queryParams);
    const mainEntityMarketShareDenominatorConditions = buildEntityMarketShareDenominatorConditions({
      app,
      filters,
      entity: mainEntity,
      categories,
      subCategories,
      segments,
      retailer
    });

    if (mainEntity.type === 'product') {
      mainEntityMarketShareDenominatorConditions.termFilters.push({
        fieldName: 'subCategoryId',
        values: [mainEntity.subCategoryId]
      });
    }

    const promises = [];
    const state = {
      isLoading: true,
      pageNum: 1,
      trafficInsightsWidget,
      selectedTab: trafficInsightsWidget.view.tabs[0],
      sortField: trafficInsightsWidget.view.tabs[0].defaultSortField,
      activeFieldNames: trafficInsightsWidget.view.tabs[0].defaultActiveFieldNames,
      entitySearchServiceStateNamesMainEntity: [],
      entitySearchServiceStateNamesComparisonEntity: [],
      entitySearchServiceStateNamesMarketShare: [],
      mainMetricConfig: {},
      comparisonMetricConfig: {},
      metricFieldNamesByGroupByField: []
    };

    const { groupByFields, marketShareConfig, configByGroupByFieldName, weekIdField } = trafficInsightsWidget.data;
    groupByFields.forEach((groupByField) => {
      if (!configByGroupByFieldName[groupByField.name]) {
        return;
      }

      [mainTimePeriod, comparisonTimePeriod].forEach((timePeriod) => {
        configByGroupByFieldName[groupByField.name].forEach((aggregationFieldsConfig) => {
          const { entity, indexName, indexNameOverride, entityQueryConditions, isMarketShare } =
            aggregationFieldsConfig;
          const { aggregationConditions } = buildAggregationConditions(
            app,
            indexName,
            retailer,
            allWeekIdsByRetailerId,
            timePeriod,
            null,
            propAggregationConditions,
            weekIdField,
            false,
            true
          );

          const statePropertyNameMainEntity = `${trafficInsightsWidget.name}_${app.name}_${groupByField.name}_${
            indexNameOverride || indexName
          }_${timePeriod.startWeek}_${timePeriod.endWeek}`;

          if (isMarketShare) {
            state.entitySearchServiceStateNamesMarketShare.push(statePropertyNameMainEntity);
          } else {
            state.entitySearchServiceStateNamesMainEntity.push(statePropertyNameMainEntity);
          }

          const aggregationItems = buildAggregations(aggregationFieldsConfig.aggregationFields);

          let derivedFields = null;
          const mainEntitySearchRequestOverrides = aggregationItems.map((aggregationItem) => {
            const {
              aggregations: aggregationFields,
              aggregationFieldConditions,
              derivations,
              indexName: aggregationIndexName
            } = aggregationItem;

            derivedFields = derivedFields || derivations;
            if (aggregationConditions && aggregationConditions.termFilters) {
              aggregationFieldConditions.termFilters = aggregationFieldConditions.termFilters.concat(
                aggregationConditions.termFilters
              );
            }

            if (aggregationConditions && aggregationConditions.rangeFilters) {
              aggregationFieldConditions.rangeFilters = aggregationFieldConditions.rangeFilters.concat(
                aggregationConditions.rangeFilters
              );
            }

            const queryConditionsMerged = _cloneDeep(
              isMarketShare ? mainEntityMarketShareDenominatorConditions : mainEntityConditions
            );

            queryConditionsMerged.termFilters = _unionWith(
              queryConditionsMerged.termFilters || [],
              _get(entityQueryConditions, 'termFilters', []),
              _get(aggregationFieldConditions, 'termFilters', []),
              buildCampaignTermFilters(this.state.filters.campaign),
              _isEqual
            );

            queryConditionsMerged.rangeFilters = _unionWith(
              queryConditionsMerged.rangeFilters || [],
              entityQueryConditions && entityQueryConditions.rangeFilters ? entityQueryConditions.rangeFilters : [],
              aggregationFieldConditions && aggregationFieldConditions.rangeFilters
                ? aggregationFieldConditions.rangeFilters
                : [],
              _isEqual
            );

            const aggregations = {
              groupByFieldName: groupByField.name,
              aggregationFields,
              sortDirection: null,
              sortByAggregationField: null,
              conditions: null
            };

            aggregationFields.forEach((aggregationField) => {
              state.metricFieldNamesByGroupByField.push({
                name: `${aggregationField.aggregateByFieldName}_by_${groupByField.name}`,
                prefix: isMarketShare ? marketShareConfig.prefix || '' : ''
              });
            });

            const name = `${statePropertyNameMainEntity}-${entity.type}-${entity.id}-${
              indexNameOverride || aggregationIndexName
            }`;

            return {
              name,
              id: name,
              indexName: indexNameOverride || aggregationIndexName,
              conditions: queryConditionsMerged,
              aggregations: [aggregations],
              pageSize: 10000,
              additionalRequestMetaData: {
                returnAdditionalMetaData: (indexNameOverride || aggregationIndexName) === 'sales'
              }
            };
          });

          const requestContext = {
            entity,
            retailer,
            app,
            indexName,
            derivedFields,
            aggregationFields: aggregationFieldsConfig.aggregationFields
          };

          promises.push(
            fetchEntityMetrics(
              statePropertyNameMainEntity,
              requestContext,
              mainEntitySearchRequestOverrides,
              this.cancelSource.token
            )
          );
        });
      });
    });

    this.setState(state);

    Promise.all(promises).then(() => {
      // Force the traffic insights data to be re-computed since there is a new dataset
      this.trafficInsightsData = null;
      this.setState({ isLoading: false });
    });
  };

  computeTrafficInsights = () => {
    const { mainTimePeriod, entitySearchService } = this.props;
    const {
      entitySearchServiceStateNamesMainEntity: [
        entitySearchServiceStateNamesMainEntityMainTimePeriod,
        entitySearchServiceStateNamesMainEntityComparisonTimePeriod
      ],
      entitySearchServiceStateNamesMarketShare: [
        entitySearchServiceStateNamesMarketShareMainTimePeriod,
        entitySearchServiceStateNamesMarketShareComparisonTimePeriod
      ],
      metricFieldNamesByGroupByField,
      trafficInsightsWidget: {
        data: { marketShareConfig }
      }
    } = this.state;

    if (this.trafficInsightsData) {
      return;
    }

    const trafficMetricsByGroupByFieldDefaultDataPoint = metricFieldNamesByGroupByField.reduce(
      (acc, { name, prefix }) => ({
        ...acc,
        [`${prefix}${name}_current`]: 0,
        [`${prefix}${name}_previous`]: 0
      }),
      {}
    );

    const trafficMetricsByGroupByField = {};
    [
      { statePropName: entitySearchServiceStateNamesMainEntityMainTimePeriod, metricNamePrefix: '' },
      { statePropName: entitySearchServiceStateNamesMainEntityComparisonTimePeriod, metricNamePrefix: '' },
      {
        statePropName: entitySearchServiceStateNamesMarketShareMainTimePeriod,
        metricNamePrefix: marketShareConfig.prefix
      },
      {
        statePropName: entitySearchServiceStateNamesMarketShareComparisonTimePeriod,
        metricNamePrefix: marketShareConfig.prefix
      }
    ].forEach(({ statePropName, metricNamePrefix }) => {
      metricFieldNamesByGroupByField.forEach(({ name: metricPropName, prefix }) => {
        const timePeriodPropString =
          statePropName.indexOf(`${mainTimePeriod.startWeek}_${mainTimePeriod.endWeek}`) > 0 ? 'current' : 'previous';

        if (entitySearchService[statePropName][metricPropName] && metricNamePrefix === prefix) {
          entitySearchService[statePropName][metricPropName].data.forEach((dataPoint) => {
            if (trafficMetricsByGroupByField[dataPoint.name] === undefined) {
              trafficMetricsByGroupByField[dataPoint.name] = Object.assign(
                {},
                trafficMetricsByGroupByFieldDefaultDataPoint
              );
              trafficMetricsByGroupByField[dataPoint.name].insightsType = 'none';
            }

            if (!trafficMetricsByGroupByField[dataPoint.name].cardView) {
              trafficMetricsByGroupByField[dataPoint.name].cardView = dataPoint.cardView;
            }

            trafficMetricsByGroupByField[dataPoint.name][`${prefix}${metricPropName}_${timePeriodPropString}`] =
              dataPoint.value;
          });
        }
      });
    });

    const insightsByTab = {
      increasing: {
        count: 0,
        data: []
      },
      decreasing: {
        count: 0,
        data: []
      },
      opportunity: {
        count: 0,
        data: []
      }
    };

    const {
      prefix: marketSharePrefix,
      mainMetricFieldName,
      mainMetricGroupByFieldName,
      marketShareMetricFieldName,
      marketShareMetricGroupByFieldName
    } = marketShareConfig;

    Object.entries(trafficMetricsByGroupByField).forEach(([groupByFieldValue, trafficMetricsForKeyword]) => {
      const trafficMetricsForKeywordWithChange = metricFieldNamesByGroupByField.reduce((acc, { name, prefix }) => {
        const { [`${prefix}${name}_current`]: current, [`${prefix}${name}_previous`]: previous } = acc;

        acc[`${prefix}${name}_change`] = current - previous;
        acc[`${prefix}${name}_changePercent`] = previous > 0 ? (current - previous) / previous : 1;
        return acc;
      }, trafficMetricsForKeyword);

      const {
        [`${mainMetricFieldName}_by_${mainMetricGroupByFieldName}_change`]: metricValueChange,
        [`${mainMetricFieldName}_by_${mainMetricGroupByFieldName}_current`]: metricValueCurrent,
        [`${marketSharePrefix}${marketShareMetricFieldName}_by_${marketShareMetricGroupByFieldName}_current`]:
          marketShareMetricValueCurrent
      } = trafficMetricsForKeywordWithChange;

      let insightsType = 'none';
      if (metricValueCurrent === 0 && marketShareMetricValueCurrent > 0) {
        insightsType = 'opportunity';
      } else if (metricValueChange > 0) {
        insightsType = 'increasing';
      } else if (metricValueChange < 0) {
        insightsType = 'decreasing';
      }

      if (insightsByTab[insightsType]) {
        trafficMetricsForKeywordWithChange.name = groupByFieldValue;
        trafficMetricsForKeywordWithChange.value = 0;

        insightsByTab[insightsType].count += 1;
        insightsByTab[insightsType].data.push(trafficMetricsForKeywordWithChange);
      }
    });

    this.trafficInsightsData = {
      insightsByTab
    };
  };

  getSelectedGroupByField = () =>
    this.state.parentWidget.widgetConfigByMetric[this.props.selectedField.name].groupByFields.find(_prop('isSelected'));

  sortData = ({ sortField, selectedTab }) => {
    this.trafficInsightsData.insightsByTab[selectedTab.name].data = _orderBy(
      this.trafficInsightsData.insightsByTab[selectedTab.name].data,
      [sortField.name],
      [sortField.direction]
    );
  };

  handleSortData = (eventData) => {
    const { selectedTab, sortField } = this.state;
    const sortDirection = sortField.name === eventData.fieldName ? invertSortDirection(sortField.direction) : 'desc';
    const newSortField = {
      name: eventData.fieldName,
      direction: sortDirection
    };

    this.sortData({ sortField: newSortField, selectedTab });
    this.setState({ sortField: newSortField });
  };

  handleSwapActiveFieldName = (eventData) => {
    const { currentFieldName, newFieldName } = eventData;
    const { activeFieldNames } = this.state;

    const activeFieldNamesModified = [...activeFieldNames];
    activeFieldNamesModified[activeFieldNamesModified.findIndex((fieldName) => fieldName === currentFieldName)] =
      newFieldName;

    this.setState({ activeFieldNames: activeFieldNamesModified });
  };

  showFeaturedEntitiesDialog = (eventData) =>
    this.setState({
      featuredEntitiesData: {
        open: true,
        selectedEntity: eventData.selectedEntity,
        selectEntityMetricCurrentValue: eventData.metricCurrentValue
      }
    });

  handleTabChange = (tabIndex) => {
    const selectedTab = _cloneDeep(this.state.trafficInsightsWidget.view.tabs[tabIndex]);
    const newSortField = selectedTab.defaultSortField;

    this.sortData({ sortField: newSortField, selectedTab });

    this.setState({
      selectedTab,
      sortField: newSortField
    });
  };

  handleGroupByChange = ({ target: { value } }) => {
    const parentWidget = _cloneDeep(this.state.parentWidget);

    parentWidget.widgetConfigByMetric[this.props.selectedField.name].groupByFields.forEach((metricsField) => {
      metricsField.isSelected = metricsField.name === value;
    });

    this.setState({ parentWidget });
    this.clearAllFilters();
  };

  clearAllFilters = () => this.handleKeywordFilterChange(EMPTY_FILTERS);

  handleKeywordFilterChange = (filters) => {
    const lastFilters = this.state.filters;

    this.setState({ filters }, () => {
      if (!_isEqual(filters.campaign, lastFilters.campaign)) {
        this.fetchData();
      }
    });
  };

  loadNextPage = () => this.setState(({ pageNum }) => ({ pageNum: pageNum + 1 }));

  renderHeader = ({ title, groupByFields, selectedGroupByField }) => (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="grid-header__title" style={{ display: 'flex', alignItems: 'center', height: 48 }}>
          {title}
        </div>

        <div>
          <EntityMetricDropDown
            handleMetricsFieldChange={(evt) => this.props.eventBus.emit('waterfallTrafficInsightsToggleGroupBy', evt)}
            metricFields={groupByFields}
            value={selectedGroupByField}
          />
        </div>
      </div>

      <hr className="sl-divider sl-divider--no-margin-top" />
    </>
  );

  renderFilter() {
    return (
      <KeywordsFilter
        filters={this.state.filters}
        enableFilters={this.state.trafficInsightsWidget.enableFilters}
        setFilter={this.handleKeywordFilterChange}
      />
    );
  }

  renderTabs = ({ tabs }) => (
    <Tabs
      tabs={tabs}
      tabStyle={{ fontWeight: 400 }}
      onTabChange={(_event, tabIndex) => this.handleTabChange(tabIndex)}
    />
  );

  getGridMetricFields = (selectedTab) => {
    const { activeFieldNames } = this.state;

    if (!activeFieldNames) {
      return selectedTab.gridMetricFields;
    }

    const inactiveGridMetricFields = selectedTab.gridMetricFields.filter(
      (metricField) => metricField.swappable && !activeFieldNames.includes(metricField.name)
    );
    const activeGridMetricFields = activeFieldNames.map((activeFieldName) =>
      selectedTab.gridMetricFields.find(propEq('name', activeFieldName))
    );

    let activeGridMetricFieldsIndex = 0;
    return filterNils(
      selectedTab.gridMetricFields.map((metricField) => {
        if (!metricField.swappable) {
          return metricField;
        }

        if (activeFieldNames.includes(metricField.name)) {
          return {
            ...activeGridMetricFields[activeGridMetricFieldsIndex++],
            headerComponentFramework: mkActiveMetricSwitcherHCF({ inactiveFields: inactiveGridMetricFields })
          };
        }

        return null;
      })
    );
  };

  renderGrid = ({ selectedTab }) => {
    if (!this.trafficInsightsData || !this.trafficInsightsData.insightsByTab[selectedTab.name]) {
      return null;
    }

    const { trafficInsightsWidget, pageNum, sortField } = this.state;
    const selectedGroupByField = this.getSelectedGroupByField();

    const pageSize = 20;

    const widget = {
      data: {
        configByGroupByFieldName: {
          [selectedGroupByField.name]: { tableView: { gridMetricFields: selectedTab.gridMetricFields } }
        }
      }
    };

    return (
      <div className="waterfall-keyword-insight">
        <EntityGridRenderer
          hideGridHeader
          rowsToRender={pageSize * pageNum}
          dataToRender={this.trafficInsightsData.insightsByTab[selectedTab.name].data}
          handleWaypointEntered={this.loadNextPage}
          layoutToUse="table"
          title={trafficInsightsWidget.view.title}
          handleChangeLayout={() => {}}
          mainMetricField={getMetricFieldForSelectedField(this.props.selectedField.name)}
          enableSwitchingLayouts={false}
          uniqueName="waterfall-keyword-table"
          pageNumber={pageNum}
          isLoading={false}
          sortDirection={sortField.direction}
          sortByMetric={sortField.name}
          filterRows={buildFilterRows(this.state.filters, !!this.state.activeFieldNames)}
          widget={widget}
          firstColumnDefOverrides={selectedTab.gridFirstColumnDefinition}
          metricFields={this.getGridMetricFields(selectedTab)}
          groupByField={trafficInsightsWidget.data.groupByFields[0]}
          noDataAvailableMessage={<div style={{ marginTop: 40 }}>No Rows to Show</div>}
        />
      </div>
    );
  };

  renderPopupDialog = () => {
    const { trafficInsightsWidget, featuredEntitiesData } = this.state;
    const selectedGroupByField = this.getSelectedGroupByField();

    const { featuredEntitiesGridWidget } = trafficInsightsWidget.childWidgets;

    // These props are passed into the `FeaturedEntitiesDialog` component that is rendered inside.  They govern the
    // behavior of the dialog that is displayed when a user clicks on one of the rows to expand either the list of
    // "Featured Products" for a particular keyword or the list of campaigns that correspond to a `searchKeyword`.
    const featuredEntitiesDialogProps = {
      uniqueName: buildWidgetUniqueName(featuredEntitiesGridWidget.view.name),
      // This function takes two arguments: The `mainEntityConditions` created from the global application
      // environment, and `selectedEntity`, which is the `keyword`/`searchKeyword` that corresponds to the
      // currently selected row.  The return value should be a merged conditions object taking into account both
      // the global filters from the main application as well as custom filters derived from the
      // `selectedEntity`.
      mergeCustomFilters: (mainEntityConditions, selectedEntity) => {
        const selectedEntityCondition = {
          termFilters: [
            {
              condition: 'should',
              fieldName: selectedGroupByField.name,
              values: [selectedEntity]
            }
          ],
          rangeFilters: []
        };

        return mergeConditions(mainEntityConditions, selectedEntityCondition);
      },
      widget: featuredEntitiesGridWidget,
      indexName: 'traffic'
    };

    return (
      <FeaturedEntitiesDialog
        onClose={() => this.setState({ featuredEntitiesData: { open: false } })}
        {...this.props}
        {...featuredEntitiesDialogProps}
        {...featuredEntitiesData}
        inverseComparisonTimePeriod={featuredEntitiesData.selectEntityMetricCurrentValue === 0}
      />
    );
  };

  renderCustomCompent = ({ widget }) => {
    const {
      app,
      categories,
      retailer,
      conditions,
      aggregationConditions,
      queryParams,
      entityService: { mainEntity }
    } = this.props;
    const { data, view, CustomComponent } = widget;

    const defaultConditions = buildDefaultConditions(conditions, queryParams);
    const entityConditions = buildEntityConditions(app, categories, defaultConditions, mainEntity);
    const mainEntityConditions = buildMainEntityConditions(entityConditions, mainEntity, app, retailer, queryParams);

    return (
      <CustomComponent
        key={`trafficinsights_custom_component_${view.name}`}
        uniqueName={`trafficinsights_custom_component_${view.name}`}
        hideGridHeader
        conditions={entityConditions}
        entityConditions={entityConditions}
        entity={mainEntity}
        selectedEntity={data ? data.entity : undefined}
        queryConditions={mainEntityConditions}
        aggregationConditions={aggregationConditions}
        queryParams={queryParams}
        widget={widget}
      />
    );
  };

  render = () => {
    const { selectedField, unitsSoldImpactConsolidated } = this.props;
    const { parentWidget, selectedTab, sortField, isLoading } = this.state;

    if (isLoading) {
      return <GridLoading />;
    }

    // if this is not traffic metrics, we render the impact grid
    if (!parentWidget || !selectedField || !parentWidget.widgetConfigByMetric[selectedField.name]) {
      const { CustomComponent } = parentWidget.widgetConfigByMetric.defaultWidget;
      const { waterfallImpactData, waterfallImpactGridTitle } = this.props;
      return (
        <CustomComponent
          waterfallImpactData={waterfallImpactData}
          waterfallImpactGridTitle={waterfallImpactGridTitle}
          selectedField={selectedField}
          unitsSoldImpactConsolidated={unitsSoldImpactConsolidated}
        />
      );
    }

    const selectedGroupByField = this.getSelectedGroupByField();
    // if group by is product, let us render impact grid
    if (selectedGroupByField.name === 'stacklineSku') {
      const { CustomComponent } = parentWidget.widgetConfigByMetric.defaultWidget;
      const { waterfallImpactData, waterfallImpactGridTitle } = this.props;
      return (
        <CustomComponent
          waterfallImpactData={waterfallImpactData}
          waterfallImpactGridTitle={waterfallImpactGridTitle}
          selectedField={selectedField}
          parentWidget={parentWidget.widgetConfigByMetric[selectedField.name]}
          unitsSoldImpactConsolidated={unitsSoldImpactConsolidated}
        />
      );
    }

    const childWidget =
      parentWidget.widgetConfigByMetric[selectedField.name].widgetConfigByGroupByField[selectedGroupByField.name];

    this.computeTrafficInsights();
    this.sortData({ sortField, selectedTab });

    childWidget.view.tabs.forEach((tab) => {
      const numberValue = numeral(this.trafficInsightsData.insightsByTab[tab.name].count).format('1,000');
      tab.displayName = `${tab.displayNamePrefix} (${numberValue})`;
    });

    return (
      <>
        {this.renderHeader({
          title: parentWidget.widgetConfigByMetric[selectedField.name].view.title,
          selectedGroupByField,
          groupByFields: parentWidget.widgetConfigByMetric[selectedField.name].groupByFields
        })}
        {this.renderFilter()}
        {this.renderTabs({ tabs: childWidget.view.tabs, selectedGroupByField })}
        {this.renderGrid({ selectedTab })}
        {this.renderPopupDialog()}
      </>
    );
  };
}

const mapStateToProps = (state) =>
  _pick(state, [
    'app',
    'entityService',
    'entitySearchService',
    'comparisonTimePeriod',
    'mainTimePeriod',
    'retailer',
    'categories',
    'subCategories',
    'segments',
    'filters',
    'allWeekIdsByRetailerId'
  ]);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics,
  clearEntitySearchService: entitySearchServiceOperations.clearEntitySearchService
};

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withBus('eventBus'));

const EnhancedTrafficInsights = enhance(TrafficInsights);

export default EnhancedTrafficInsights;
