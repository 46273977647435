import React, { useMemo } from 'react';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { useMetricFormatter, useAppSelector } from 'src/utils/Hooks';
import { withBus } from 'react-bus';
import Visualization from 'src/components/Layout/Advertising/ConnectPageLayout/components/Visualization';
import useConnectFetchEntityMetrics from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/hooks/useConnectFetchEntityMetrics';
import NewToBrandProductGrid from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/NewToBrandTab/NewToBrandProducts/NewToBrandProductGrid';
import {
  NTBProductGridAdvancedSearchParser,
  buildRequestForNTBProductGrid,
  convertProductGridDataToCsv
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/NewToBrandTab/NewToBrandProducts/utils';
import useAmcDateRange from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/hooks/useAmcDateRange';
import { ESSRequestContext } from 'src/store/modules/entitySearchService/operationTypes';
import _get from 'lodash/get';
import { AD_MANAGER_STATE_PROPERTY_NAME } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/constants';
import { Divider } from '@mui/material';
import colors from 'src/utils/colors';
import GridExportMenu from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/FrequentlyPurchasedTogether/GridExportMenu';

const NewToBrandProductVisualization = () => {
  const { startDayId, endDayId } = useAmcDateRange();
  const retailer = useAppSelector((state) => state.retailer);
  const { mainTimePeriod } = useAppSelector((state) => state);
  const formatMetric = useMetricFormatter();

  // Advanced Search Request Overrides
  const searchRequestOverrides = useMemo(
    () => buildRequestForNTBProductGrid({ startDayId, endDayId, retailerId: retailer.id }),
    [endDayId, startDayId, retailer.id]
  );

  // ESS Context Overrides
  const requestContextOverrides: Partial<ESSRequestContext> = useMemo(
    () => ({
      customResponseParser: ({ apiResponse, state }) => {
        return {
          ...state[AD_MANAGER_STATE_PROPERTY_NAME],
          newToBrandProductGrid: NTBProductGridAdvancedSearchParser(apiResponse.data[0], formatMetric)
        };
      }
    }),
    []
  );

  // Fetching
  const { data, isLoading } = useConnectFetchEntityMetrics({
    indexName: 'ntb-product-grid',
    visualizationKey: 'visualization_key',
    searchRequestOverrides,
    requestContextOverrides
  });

  // Data Access
  const gridRows = useMemo(() => {
    return _get(data, 'newToBrandProductGrid', []);
  }, [data]);

  return (
    <>
      <GridExportMenu
        styles={{ position: 'relative', zIndex: '2', width: '40px', height: '40px', left: '96%', top: '45px' }}
        exportCsvData={() => convertProductGridDataToCsv({ rowData: gridRows, dateRange: mainTimePeriod.displayName })}
      />
      <Visualization
        titleProps={{
          title: 'Products'
        }}
      >
        <Divider sx={{ color: colors.borderGrey }}></Divider>

        <div style={{ marginTop: '64px' }}>
          {isLoading && <GridLoading rows={3} />}
          {!isLoading && gridRows && <NewToBrandProductGrid gridRows={gridRows} />}
        </div>
      </Visualization>
    </>
  );
};

export default withBus('eventBus')(NewToBrandProductVisualization);
