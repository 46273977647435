import { useContext } from 'react';
import { BulkAdjustmentContext } from '../BulkAdjustmentProvider';

/**
 * Exposes the BulkAdjustmentContext
 */
export const useBulkAdjustmentContext = () => {
  const context = useContext(BulkAdjustmentContext);

  if (!context) {
    throw new Error('useBulkAdjustmentContext must be used within a BulkAdjustmentProvider');
  }

  return context;
};
