import types from './types';
import actions from './actions';
import { ValueOf } from 'sl-api-connector/types';

export interface OmniRegionsState {
  isFetching: boolean;
  data: { regionCode: string; regionName: string }[];
}

const initialState: OmniRegionsState = {
  isFetching: true,
  data: []
};

const omniRegionsService = (state: OmniRegionsState = initialState, action: ReturnType<ValueOf<typeof actions>>) => {
  switch (action.type) {
    case types.RECEIVE_ALL_OMNI_REGIONS:
      return {
        isFetching: false,
        data: Object.assign([], action.data)
      };
    default:
      return state;
  }
};

export default omniRegionsService;
