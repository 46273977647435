/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';

import { Omit } from 'src/types/utils';
import { MetricField, ESSDataKey } from 'src/types/application/types';
import EmptyColumn from 'src/components/AdManager/Search/CustomColumns/EmptyColumn';
import SimpleColumn from 'src/components/AdManager/Search/CustomColumns/SimpleColumn';
import { getAppName } from 'src/utils/app';
import { AppName } from 'sl-api-connector';

const styles = {
  root: {
    width: '100%',
    height: '100%',
    marginTop: '17px'
  }
};

interface CustomAgMaterialProps {
  buildRows: () => object[] | ESSDataKey;
  containerStyle?: React.CSSProperties;
  columnDefs: MetricField[];
  domLayout: 'autoHeight' | 'normal';
}

type PropTypesOf<T> = T extends React.Component<infer PropTypes> ? PropTypes : never;

const CustomAgMaterial: React.FC<
  Omit<PropTypesOf<AgGridReact>, keyof CustomAgMaterialProps & keyof PropTypesOf<AgGridReact>> & CustomAgMaterialProps
> = ({ buildRows, children, containerStyle, columnDefs, domLayout, ...props }) => {
  // admanager: make all table's header row + total metrics row as sticky
  const appName = getAppName();
  const useStickyHeaderAndTotalRow = appName === AppName.Advertising && domLayout === 'autoHeight';

  const entityTable = useMemo(() => {
    return (
      <div
        className={`custom-ag-material ${appName}-custom-ag-material ${
          useStickyHeaderAndTotalRow ? `${appName}-sticky-header` : ''
        }`}
        style={containerStyle}
      >
        <div style={styles.root}>
          <AgGridReact
            columnDefs={columnDefs as any}
            domLayout={domLayout || 'autoHeight'}
            rowData={buildRows() as any}
            rowHeight={props.rowHeight || 56}
            headerHeight={34}
            frameworkComponents={{ emptyColumn: EmptyColumn, simpleColumn: SimpleColumn }}
            {...props}
          />
          {children}
        </div>
      </div>
    );
  }, [children, columnDefs]);

  return entityTable;
};

export default CustomAgMaterial;
