import types from './types';

const receiveAdPlatformSettings = (adPlatformSettings) => ({
  type: types.RECEIVE_ADPLATFORM_SETTINGS,
  adPlatformSettings,
  receivedAt: Date.now()
});

const clearAdPlatformSettings = () => ({
  type: types.CLEAR_ADPLATFORM_SETTINGS
});

export default {
  clearAdPlatformSettings,
  receiveAdPlatformSettings
};
