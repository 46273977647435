import _orderBy from 'lodash/orderBy';

import types from './types';

export default function subCategories(state = [], action) {
  switch (action.type) {
    case types.RECEIVE_SUB_CATEGORIES:
      return action.categories;
    case types.CLEAR_SUB_CATEGORIES:
      return [];
    case types.SORT_SUB_CATEGORIES: {
      return [..._orderBy(state, [action.sorter], [action.direction || 'desc'])];
    }
    case types.REQUEST_SUB_CATEGORIES:
    default:
      return state;
  }
}

export function allSuperUserSubCategories(state = [], action) {
  switch (action.type) {
    case types.RECEIVE_SUPER_USER_SUB_CATEGORIES:
      return action.categories;
    case types.CLEAR_SUB_CATEGORIES:
      return [];
    case types.REQUEST_SUB_CATEGORIES:
    default:
      return state;
  }
}
