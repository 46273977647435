import { Dispatch } from 'react';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import { CancelTokenSource } from 'axios';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import _get from 'lodash/get';
import { constructNewChildDocumentsMapByCampaignId } from 'src/components/AdManager/Search/GridDataFetchers/Campaigns/CampaignIdToChildDocumentsMapGenerator';
import { SearchGridConstants } from '../SearchGridConstants';
import { CampaignIdToChildDocumentsMap } from 'src/components/AdManager/Search/GridDataFetchers/Campaigns/CampaignIdToChildDocumentsMap';

export class CampaignsForAdditionalTargets {
  // eslint-disable-next-line class-methods-use-this
  public async fetchAdditionalCampaignTargets(
    dispatch: Dispatch<any>,
    mainEntity: any,
    retailer: any,
    app: any,
    queryConditions: ISearchFilterConditions,
    searchSideBarConditions: ISearchFilterConditions,
    cancelSource: CancelTokenSource
  ): Promise<CampaignIdToChildDocumentsMap> {
    const { documents: adTargetDocuments } = await dispatch(
      fetchEntityMetrics(
        'adManager_searchResultsGrid_targets',
        {
          entity: mainEntity,
          retailer,
          app,
          indexName: 'adCampaignAdGroupTargetDailyMetrics'
        },
        [
          {
            doAggregation: false,
            conditions: {
              termFilters: [...queryConditions.termFilters],
              rangeFilters: [...searchSideBarConditions.rangeFilters]
            },
            pageNumber: 1,
            pageSize: SearchGridConstants.PAGE_SIZE_25K,
            processDocuments: false,
            returnDocuments: true
          }
        ],
        _get(cancelSource, 'token'),
        true
      )
    );

    const campaignToChildDocumentsMap = constructNewChildDocumentsMapByCampaignId(adTargetDocuments);
    return campaignToChildDocumentsMap;
  }
}
