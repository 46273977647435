import React, { useState } from 'react';
import StyledDialog from 'src/components/BeaconRedesignComponents/common/StyledDialog/StyledDialog';
import TeamPopupCloseIcon from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Team/TeamPopupCloseIcon';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import CompareLanding from './CompareLanding';
import CompareMetrics from './CompareMetrics';
import CompareGroups from './CompareGroups';
import { CompareScreens } from './types';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

/**
 * Popup for handling the compare feature for line charts
 */
export default function ComparePopup(
  props: Omit<React.ComponentProps<typeof StyledDialog>, 'width' | 'height' | 'padding'>
) {
  const [screen, setScreen] = useState<CompareScreens>(CompareScreens.Landing);

  const handleClose = (event?: React.MouseEvent<unknown, MouseEvent>) => {
    if (props.onClose) {
      props.onClose(event, 'backdropClick');
      setScreen(CompareScreens.Landing);
    }
  };

  const screenToInnerContent = {
    [CompareScreens.Landing]: <CompareLanding setScreen={setScreen} />,
    [CompareScreens.Metrics]: <CompareMetrics handleClose={() => handleClose()} />,
    [CompareScreens.Groups]: <CompareGroups handleClose={() => handleClose()} />,
    [CompareScreens.Retailers]: <div>Retailers</div>
  };

  return (
    <StyledDialog
      padding="24px"
      width={360}
      height={screen === CompareScreens.Landing ? 398 : undefined}
      sx={{
        '& .MuiPaper-root': {
          position: 'fixed',
          bottom: 0,
          right: 0
        },
        maxHeight: screen !== CompareScreens.Landing ? 600 : undefined
      }}
      {...props}
    >
      <Flex flexDirection="column">
        <Flex
          justifyContent={screen === CompareScreens.Landing ? 'flex-end' : 'space-between'}
          marginBottom={screen === CompareScreens.Landing ? '12px' : '26px'}
        >
          {screen !== CompareScreens.Landing && (
            <Flex
              alignItems="center"
              onClick={() => setScreen(CompareScreens.Landing)}
              sx={{
                cursor: 'pointer'
              }}
            >
              <ChevronLeft />
              <Text variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Back
              </Text>
            </Flex>
          )}
          <TeamPopupCloseIcon onClick={handleClose} />
        </Flex>
        {screenToInnerContent[screen]}
      </Flex>
    </StyledDialog>
  );
}
