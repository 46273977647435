/**
 * Enum representing display names that are assigned to a field. Example - Field: Return on Ad Spend. Display name: ROAS
 */
export enum BEACON_FIELD_DISPLAY_NAMES {
  RETURN_ON_AD_SPEND = 'ROAS',
  AD_SPEND = 'Ad Spend',
  COST_PER_CLICK = 'Cost per Click',
  PAID_TRAFFIC = 'Paid Traffic',
  AD_SALES = 'Ad Sales'
}
