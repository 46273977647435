// All component here are fake, only for demo purpose

import React from 'react';
import Lottie from 'react-lottie';
import { replaceColor } from 'lottie-colorify';
import * as authSuccessAnimation from 'src/components/Animations/AuthSuccessAnimation/AuthSuccessAnimation.json';
import { getAppName } from 'src/utils/app';

const appColorMap = {
  atlas: '#46A8F6',
  beacon: '#A21671',
  omni: '#A21671',
  trends: '#46A8F6',
  advertising: '#9EE0D0',
  pulse: '#9EE0D0',
  discover: '#9EE0D0'
};

// In Auth page, we will render a animation that is successful
export const AuthSuccessAnimation = ({ ...props }: Omit<React.ComponentPropsWithRef<typeof Lottie>, 'options'>) => {
  const appName = getAppName();
  const replacedColor = appColorMap[appName] || '#A21671';
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: replaceColor('#A21671', replacedColor, authSuccessAnimation),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return <Lottie options={defaultOptions} height={120} width={120} {...props} />;
};
