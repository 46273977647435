import types from './types';

// ------------------------------------
// Reducer
// ------------------------------------

export function adEntities(state = [], action) {
  switch (action.type) {
    case types.RECEIVE_ADENTITIES:
      return action.adEntities;
    case types.CLEAR_ADENTITIES:
      return [];
    default:
      return state;
  }
}
