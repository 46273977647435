/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import withStyles from '@mui/styles/withStyles';

import { NULL_UUID } from 'src/utils/constants';
import Select from 'src/components/common/Form/Select';
import ReduxStore from 'src/types/store/reduxStore';
import { filterNils } from 'sl-api-connector/util';

const mapStateToProps = (state: ReduxStore) => _pick(state, ['segments']);

const SegmentDropdown: React.FC<
  {
    value: string | null | undefined;
    onChange: (newSegmentId: string) => void;
    classes: { [key: string]: any };
    allowCreateNew?: boolean;
    includeBusinessUnits?: boolean;
    includeSharedSegments?: boolean;
  } & ReturnType<typeof mapStateToProps>
> = ({
  value,
  onChange,
  segments,
  classes,
  includeBusinessUnits,
  allowCreateNew = true,
  includeSharedSegments = true
}) => {
  const items = useMemo(
    () =>
      filterNils([
        allowCreateNew
          ? { displayName: `New ${includeBusinessUnits ? 'Saved Search' : 'Segment'}`, id: NULL_UUID, value: NULL_UUID }
          : null,
        ...(includeSharedSegments ? [...segments.mySegments, ...segments.teamSegments] : segments.mySegments).map(
          ({ displayName, id }) => ({ displayName, id, value: id })
        )
      ]),
    [segments.mySegments, segments.teamSegments, allowCreateNew, includeBusinessUnits, includeSharedSegments]
  );

  return (
    <Select
      items={items}
      value={value || NULL_UUID}
      onChange={(
        evt: React.ChangeEvent<{
          name?: string | undefined;
          value: unknown;
        }>
      ) => onChange(evt.target.value as string)}
      classes={classes}
      style={{ width: 350 }}
    />
  );
};

export default connect(mapStateToProps)(
  withStyles({
    select: { fontSize: 14, width: 350, maxWidth: 350 },
    root: { width: 350, display: 'block', maxWidth: 350 }
  })(SegmentDropdown)
);
