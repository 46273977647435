import React from 'react';
import { Text } from '@stackline/ui';
import { DialogProps } from '@mui/material/Dialog';
import * as serverProxy from 'src/routes/UserAccount/UserTeamManagement/serverProxy';
import { useAppSelector, useSnackbar } from 'src/utils/Hooks';
import _get from 'lodash/get';
import { settingsErrorMessage } from '../../utils';
import TeamUserOptionPopup from '../TeamUserOptionPopup';

type RemoveUserPopupProps = Omit<DialogProps, 'children' | 'sx'> & {
  user: {
    email: string;
  };
  refetchData: () => Promise<void>;
};

/**
 * Popup for removing a user from a team
 */
export default function RemoveUserPopup({ user, refetchData, ...rest }: RemoveUserPopupProps) {
  const { showSnackbar } = useSnackbar();
  const currentEmail = useAppSelector((state) => state.user.config.profile.email);

  const deleteUser = async () => {
    try {
      // Delete the user and then refetch the users
      await serverProxy.DisableUserFromDivision(user.email, currentEmail);
      await refetchData();

      showSnackbar({
        message: `You've deleted ${user.email} from your division.`,
        type: 'success'
      });
    } catch (error) {
      console.error(error);
      showSnackbar({
        message: _get(error, 'response.data', settingsErrorMessage),
        type: 'error'
      });
    }
  };

  return (
    <TeamUserOptionPopup modalTitle="Remove User From Team" onConfirm={() => deleteUser()} {...rest}>
      <Text variant="subtitle2" inline>
        {user.email}
      </Text>{' '}
      will no longer be able to access this account. All segments created by this user will be permanently deleted.
    </TeamUserOptionPopup>
  );
}
