import _get from 'lodash/get';
import { formatPromoType } from 'src/utils/stringFormatting';

const parsePromoTypes = (response: any) => {
  const data = _get(response, ['data', '0', 'aggregations', 'by_promoType'], []).map((type: { fieldId: string }) => {
    const { fieldId } = type;
    return {
      name: fieldId,
      displayName: formatPromoType(fieldId),
      id: fieldId
    };
  });

  return data;
};

export default parsePromoTypes;
