import types from './types';

export const receiveOmniStoreList = (entity: { data: any[] }) => ({
  type: types.RECEIVE_OMNI_STORE_LIST_DATA,
  entity
});

export const startFetchOmniStoreList = (entity: { data: any[] }) => ({
  type: types.START_FETCH,
  entity
});

export const endFetchOmniStoreList = (entity: { data: any[] }) => ({
  type: types.END_FETCH,
  entity
});
