import React from 'react';
import ProductCell from 'src/components/BeaconRedesignComponents/ProductCell/ProductCell';
import { ProductEntity } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/types';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/utils/Hooks';
import { getBrandImageUrl } from 'src/utils/image';
import { Text } from '@stackline/ui';
import { styled } from '@mui/styles';
import { splitCamelCase } from 'src/utils/stringFormatting';
import { getStartAndEndOfWeek } from 'src/utils/dateUtils';
import { formatTime } from 'src/utils/dateformatting';

const TableP = styled('p')({
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': 2,
  overflow: 'hidden',
  'word-break': 'break-word',
  margin: 0
});

const TableSpan = styled('span')({
  margin: 0
});

export const ProductColumnDefinition = {
  headerName: 'Products',
  valueFormatter: (_, row: { product: ProductEntity }) => (
    <div style={{ width: '260px' }}>
      <ProductCell key={row.product.stacklineSku} stacklineSku={row.product.stacklineSku} title={row.product.title} />
    </div>
  )
};

interface GetWeekColumnDefinitionProps {
  headerName?: string;
  getTableColumnProps?: (defaults: { style: { paddingLeft: string; width: string } }) => any;
  valueFormatter?: (_: any, row: any) => React.ReactElement;
}

/**
 * Function that returns the column definition for the week ending column used in MetricListContainers.
 * Allows for easier modification of the column definition for styling/formatting.
 */
export const getWeekColumnDefinition = ({
  headerName = 'Week Ending',
  getTableColumnProps = null,
  valueFormatter = (_, row: any) => {
    const { weekId } = row;
    const { endDate } = getStartAndEndOfWeek(weekId);
    const weekEndingDate = formatTime(endDate, 'MMM D, YYYY');
    return (
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        <span style={{ margin: 0 }} title={weekEndingDate}>
          {weekEndingDate}
        </span>
      </div>
    );
  }
}: GetWeekColumnDefinitionProps) => {
  const defaultTableColumnProps = {
    style: {
      paddingLeft: '24px',
      width: '500px'
    }
  };

  const tableColumnProps = getTableColumnProps ? getTableColumnProps(defaultTableColumnProps) : defaultTableColumnProps;

  return {
    headerName,
    tableColumnProps,
    valueFormatter
  };
};

export const EntityLink = ({
  id,
  children,
  entityType
}: {
  id: string;
  children: React.ReactNode;
  entityType: string;
}) => {
  const { searchParams, additionalParams } = useAppSelector((state) => state.app.queryParams);
  return <Link to={`/${entityType}/${id}${searchParams}${additionalParams}`}>{children}</Link>;
};

export const CategoryColumnDefinition = {
  headerName: 'Categories',
  valueFormatter: (_, row: { category: { id: string; name: string } }) => (
    <EntityLink id={row.category.id} entityType="category">
      {row.category.name}
    </EntityLink>
  )
};

export const SubcategoryColumnDefinition = {
  headerName: 'Subcategories',
  valueFormatter: (_, row: { subcategory: { id: string; name: string } }) => (
    <EntityLink id={row.subcategory.id} entityType="subcategory">
      {row.subcategory.name}
    </EntityLink>
  )
};

export const BrandColumnDefinition = {
  headerName: 'Brands',
  valueFormatter: (_, row: { brand: { id: string; name: string } }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={getBrandImageUrl(row.brand.id)}
        style={{ width: '30px', height: '30px', marginRight: '12px', objectFit: 'cover' }}
        alt={row.brand.name}
      />
      <EntityLink id={row.brand.id} entityType="brand">
        {row.brand.name}
      </EntityLink>
    </div>
  )
};

export const CampaignColumnDefinition = {
  headerName: 'Campaigns',
  valueFormatter: (_, row: { campaign: { campaignId; campaignName } }) => (
    <div
      style={{
        maxWidth: '138px'
      }}
    >
      <TableP title={row.campaign.campaignName}>{row.campaign.campaignName}</TableP>
    </div>
  )
};

export const CampaignTypeForCampaignColumnDefinition = {
  headerName: 'Campaign Type',
  valueFormatter: (_, { campaign: { campaignType } }) => (
    <div
      style={{
        maxWidth: '77px'
      }}
    >
      <TableP title={campaignType}>{splitCamelCase(campaignType)}</TableP>
    </div>
  )
};

export const CampaignStatusColumnDefinition = {
  headerName: 'Campaign Status',
  valueFormatter: (_, { campaign: { state } }) => <Text variant="body2">{state}</Text>
};

export const KeywordColumnDefinition = {
  headerName: 'Keywords',
  valueFormatter: (_, row) => (
    <div style={{ width: '135px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      <TableSpan title={row.name}>{row.name}</TableSpan>
    </div>
  )
};

export const CampaignTypeColumnDefinition = {
  headerName: 'Campaign Type',
  valueFormatter: (_, row) => {
    return <Text variant="body2">{splitCamelCase(row.name)}</Text>;
  }
};
