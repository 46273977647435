import React, { useContext } from 'react';
import { AdjustmentModal } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/AdjustmentModal';
import { useAppSelector } from 'src/utils/Hooks';
import useAdjustmentPolling from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAdjustmentPolling';
import * as serverProxy from '../RefactoredAdjustmentModals/serverProxy';
import { ForecastAdjustmentsTypeContext } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/ForecastAdjustmentsTypeProvider';
import { bulkAdjustmentsEnabled } from 'src/utils/app';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { useMainEntityType } from 'src/utils/Hooks/reduxSelectors';
import CreateBulkAdjustmentButton from './CreateBulkAdjustmentButton';

export const CreateAdjustmentButton = () => {
  const { adjustmentModalOpen, setAdjustmentModalOpen } = useContext(ForecastAdjustmentsTypeContext);

  const retailerSku = useAppSelector((state) => state.entityService.mainEntity.retailerSku);
  const stacklineSku = useAppSelector((state) => state.entityService.mainEntity.stacklineSku);
  const categoryId = useAppSelector((state) => state.entityService.mainEntity.categoryId);
  const subcategoryId = useAppSelector((state) => state.entityService.mainEntity.subCategoryId);
  const entityType = useMainEntityType();

  const { setIsPolling } = useAdjustmentPolling();

  const handleClose = () => {
    setAdjustmentModalOpen(false);
  };

  if (!bulkAdjustmentsEnabled() && entityType !== 'product') {
    return null;
  }

  return (
    <>
      {bulkAdjustmentsEnabled() ? (
        <CreateBulkAdjustmentButton onAddSingularAdjustment={() => setAdjustmentModalOpen(true)} />
      ) : (
        <SlButton onClick={() => setAdjustmentModalOpen(true)} variant="contained">
          + Add Adjustment
        </SlButton>
      )}
      <AdjustmentModal
        sendAdjustmentUpdate={serverProxy.createAdjustment}
        stacklineSku={stacklineSku}
        retailerSku={retailerSku}
        categoryId={categoryId}
        subcategoryId={subcategoryId}
        open={adjustmentModalOpen}
        onClose={handleClose}
        setIsPolling={setIsPolling}
      />
    </>
  );
};
