import _isEmpty from 'lodash/isEmpty';

import { prop } from 'src/utils/fp';
import { AD_TARGETING_TYPE, AD_CAMPAIGN_TYPE } from 'sl-ad-campaign-manager-data-model';

const winRateByBidTier: { [tier: string]: number } = {
  '1': 0.1,
  '2': 0.2,
  '3': 0.3,
  '4': 0.4,
  '5': 0.5,
  '6': 0.6,
  '7': 0.7,
  '8': 0.8,
  '9': 0.9,
  '10': 1.0
} as const;

const BASE_METRIC_VALUES = {
  cpc: 0.8,
  cpcTier: 3,
  cpcTierChangePercent: 0.15,
  ctr: 0.025,
  conversionRate: 0.15,
  winRateByBidTier,
  incremetalityAdjustedMultiplier: 1.2
};

const CAMPAIGN_TYPE_MULTIPLIERS: any = {
  [AD_CAMPAIGN_TYPE.SPONSORED_DISPLAY]: {
    conversionRate: 0.5
  }
};

const computeValueTier = (
  baseValue: number,
  baseValueTier: number,
  currentValue: number,
  tierChangePercent: number
) => {
  const tierDirectionMultiple = currentValue < baseValue ? -1 : 1;
  let nextTierValue = baseValue + tierDirectionMultiple * (baseValue * tierChangePercent);
  let tier = baseValueTier;
  while (
    (tierDirectionMultiple > 0 ? currentValue >= nextTierValue : currentValue <= nextTierValue) &&
    tier > 1 &&
    tier < 10
  ) {
    tier += 1 * tierDirectionMultiple;
    nextTierValue += tierDirectionMultiple * (baseValue * tierChangePercent);
  }
  return tier;
};

const capMinMaxValue = (metricValue: number, minValue: number, maxValue: number) => {
  if (Number.isNaN(metricValue)) {
    return 0;
  }
  if (metricValue < minValue) {
    return minValue;
  }
  if (metricValue > maxValue) {
    return maxValue;
  }
  return metricValue;
};

const computeFinalAdMetrics = ({
  impressionsWon,
  currentBid,
  clicksRatio,
  adClickThroughRate,
  adConversionRate,
  retailPriceAverage,
  wholesalePriceAverage,
  brandCogsPerUnitAverage,
  incrementalityMultiple
}: {
  impressionsWon: number;
  currentBid: number;
  clicksRatio: number;
  adClickThroughRate: number;
  adConversionRate: number;
  retailPriceAverage: number;
  wholesalePriceAverage: number;
  brandCogsPerUnitAverage: number;
  incrementalityMultiple: number;
}) => {
  const adClicks = Math.round(impressionsWon * adClickThroughRate * clicksRatio);
  // console.log(`adClicks: ${adClicks}`);
  const adSpend = adClicks * currentBid;
  // console.log(`adSpend: ${adSpend}`);
  const adUnitsSold = Math.round(adClicks * adConversionRate);
  // console.log(`adUnitsSold: ${adUnitsSold}`);
  const adSales = adUnitsSold * retailPriceAverage;
  const adWholesaleSales = adUnitsSold * wholesalePriceAverage;
  // console.log(`adSales: ${adSales}`);
  const adBrandCogs = adUnitsSold * brandCogsPerUnitAverage;
  // console.log(`adBrandCogs: ${adBrandCogs}`);
  const grossMargin = adWholesaleSales - adBrandCogs;
  // console.log(`grossMargin: ${grossMargin}`);
  const returnOnAdSpend = adSales / adSpend;
  // console.log(`incrementalityMultiple: ${incrementalityMultiple}`);
  const incrementalSales = adSales * incrementalityMultiple;
  // console.log(`incrementalSales: ${incrementalSales}`);
  const incrementalMargin = grossMargin * incrementalityMultiple;
  // console.log(`incrementalMargin: ${incrementalMargin}`);
  const incrementalReturnOnAdSpend = incrementalSales / adSpend;
  // console.log(`incrementalReturnOnAdSpend: ${incrementalReturnOnAdSpend}`);
  return {
    adClicks,
    adSpend,
    adUnitsSold,
    adSales,
    adWholesaleSales,
    adBrandCogs,
    grossMargin,
    returnOnAdSpend,
    incrementalSales,
    incrementalMargin,
    incrementalReturnOnAdSpend
  };
};

const computeTargetEntityMetrics = (
  {
    entityLineItem,
    retailPriceAverage,
    wholesalePriceAverage,
    brandCogsPerUnitAverage,
    cpcTierDirectionMultiple,
    targetingTypeId,
    campaignTypeId
  }: {
    entityLineItem: any;
    retailPriceAverage: number;
    wholesalePriceAverage: number;
    brandCogsPerUnitAverage: number;
    cpcTierDirectionMultiple: number;
    targetingTypeId: AD_TARGETING_TYPE;
    campaignTypeId: string;
  },
  minimumBid?: number,
  maximumBid?: number
) => {
  // only take actual advertising data from the platform, if the data has more than 10 clicks for the search term
  const canUseSearchTermLevelAdvertisingBaseValue = entityLineItem.adClicksSearchTermLevelAdvertisingBaseValue >= 10;
  const baseCPC = capMinMaxValue(
    (canUseSearchTermLevelAdvertisingBaseValue
      ? entityLineItem.adCostPerClickSearchTermLevelAdvertisingBaseValue
      : 0) ||
      entityLineItem.adCostPerClickBaseValue ||
      BASE_METRIC_VALUES.cpc,
    0.5,
    19.99
  );

  let currentBid = entityLineItem.startBid
    ? entityLineItem.startBid +
      entityLineItem.startBid * BASE_METRIC_VALUES.cpcTierChangePercent * cpcTierDirectionMultiple
    : baseCPC;

  if (minimumBid && currentBid < minimumBid) {
    currentBid = minimumBid;
  } else if (maximumBid && currentBid > maximumBid) {
    currentBid = maximumBid;
  }

  const bidTier = computeValueTier(
    baseCPC,
    BASE_METRIC_VALUES.cpcTier,
    currentBid,
    BASE_METRIC_VALUES.cpcTierChangePercent
  );

  const winRate = BASE_METRIC_VALUES.winRateByBidTier[`${bidTier}`];
  const impressions = capMinMaxValue(entityLineItem.totalClicksBaseValue, 50, 3000000);
  const impressionsWon = impressions * winRate;
  const brandOrganicClickShare =
    entityLineItem.organicClicksBaseValue > 0
      ? entityLineItem.organicClicksBrandLevelBaseValue / entityLineItem.organicClicksBaseValue
      : 1;

  // const overallAdClickThroughRateBaseValueComputed =
  //   entityLineItem.totalClicksBaseValue > 0
  //     ? entityLineItem.adClicksBaseValue / entityLineItem.totalClicksBaseValue
  //     : 0;

  let clickThroughRateAdvertisingMultiplier = 0;
  if (
    canUseSearchTermLevelAdvertisingBaseValue &&
    entityLineItem.adClicksSearchTermLevelAdvertisingBaseValue &&
    entityLineItem.adClicksSearchTermLevelAdvertisingBaseValue / entityLineItem.adClicksBaseValue > 0.33
  ) {
    clickThroughRateAdvertisingMultiplier = 1.0;
  }
  const adClickThroughRate = capMinMaxValue(
    (canUseSearchTermLevelAdvertisingBaseValue
      ? entityLineItem.adClickThroughRateSearchTermLevelAdvertisingBaseValue * clickThroughRateAdvertisingMultiplier
      : 0) || // From Beacon Advertising (AMS)
      brandOrganicClickShare || // From Atlas Traffic -  brands organic share
      // overallAdClickThroughRateBaseValueComputed || // From Atlas Traffic -  search terms over all adClickThroughRate
      // entityLineItem.adClickThroughRateSearchTermLevelTrafficBaseValue || // From Beacon Traffic
      // entityLineItem.adClickThroughRateBrandLevelBaseValue || // From Atlas Traffic With Brand Id Filter
      entityLineItem.adClickThroughRateCategoryLevelTrafficBaseValue ||
      BASE_METRIC_VALUES.ctr,
    (canUseSearchTermLevelAdvertisingBaseValue
      ? entityLineItem.adClickThroughRateSearchTermLevelAdvertisingBaseValue
      : 0) || entityLineItem.adClickThroughRateCategoryLevelTrafficBaseValue,
    0.125
  );
  let conversionRateCampaignTypeMultiplier = 1.0;
  if (CAMPAIGN_TYPE_MULTIPLIERS[campaignTypeId] && CAMPAIGN_TYPE_MULTIPLIERS[campaignTypeId].conversionRate) {
    conversionRateCampaignTypeMultiplier = CAMPAIGN_TYPE_MULTIPLIERS[campaignTypeId].conversionRate;
  }
  let adConversionRateCategoryLevelMultiplier = 1.0;
  if (brandOrganicClickShare === 0) {
    adConversionRateCategoryLevelMultiplier = 0.5;
  }
  // console.log(`adClickThroughRate: ${adClickThroughRate}`);
  const adConversionRate =
    capMinMaxValue(
      (canUseSearchTermLevelAdvertisingBaseValue
        ? entityLineItem.adConversionRateSearchTermLevelAdvertisingBaseValue
        : 0) || // From Beacon Advertising (AMS)
        // entityLineItem.adConversionRateBrandLevelBaseValue || // From Atlas Traffic With Brand Id Filter
        // entityLineItem.adConversionRateSearchTermLevelTrafficBaseValue || // From Beacon Traffic
        entityLineItem.adConversionRateBaseValue ||
        entityLineItem.adConversionRateCategoryLevelTrafficBaseValue * adConversionRateCategoryLevelMultiplier ||
        BASE_METRIC_VALUES.conversionRate,
      0.01,
      0.2
    ) * conversionRateCampaignTypeMultiplier;
  // console.log(`returnOnAdSpend: ${returnOnAdSpend}`);
  // console.log(`totalClicksBaseValue: ${entityLineItem.totalClicksBaseValue}`);
  // console.log(`organicClicksBrandLevelBaseValue: ${entityLineItem.organicClicksBrandLevelBaseValue}`);
  let incrementalityMultiple = 1.0;
  if (targetingTypeId === AD_TARGETING_TYPE.PRODUCT_TARGETING) {
    const salesShare =
      entityLineItem.salesRetailSalesCategoryLevelSalesBaseValue > 0
        ? (entityLineItem.salesRetailSalesBrandLevelSalesBaseValue || 0) /
          entityLineItem.salesRetailSalesCategoryLevelSalesBaseValue
        : 0.85;
    // console.log(`trafficShare: ${trafficShare}`);
    const incrementalityUnadjustedMultiple = 1.0 - salesShare;
    // console.log(`incrementalityUnadjustedMultiple: ${incrementalityUnadjustedMultiple}`);
    incrementalityMultiple = capMinMaxValue(
      incrementalityUnadjustedMultiple * (salesShare > 0.5 ? BASE_METRIC_VALUES.incremetalityAdjustedMultiplier : 1.0), // only employ the multipliers for branded terms
      0,
      1
    );
  } else {
    const trafficShare =
      entityLineItem.totalClicksBaseValue > 0
        ? (entityLineItem.organicClicksBrandLevelBaseValue || 0) / entityLineItem.totalClicksBaseValue
        : 0.85;
    // console.log(`trafficShare: ${trafficShare}`);
    const incrementalityUnadjustedMultiple = 1.0 - trafficShare;
    // console.log(`incrementalityUnadjustedMultiple: ${incrementalityUnadjustedMultiple}`);
    incrementalityMultiple = capMinMaxValue(
      incrementalityUnadjustedMultiple *
        (trafficShare > 0.5 ? BASE_METRIC_VALUES.incremetalityAdjustedMultiplier : 1.0), // only employ the multipliers for branded terms
      0,
      1
    );
  }
  // console.log(
  //   `${entityLineItem.id} : adConversionRateSearchTermLevelAdvertisingBaseValue: ${entityLineItem.adConversionRateSearchTermLevelAdvertisingBaseValue}`
  // );
  // console.log(`${entityLineItem.id} : adConversionRateBaseValue: ${entityLineItem.adConversionRateBaseValue}`);
  // console.log(`${entityLineItem.id} : adConversionRate: ${adConversionRate}`);

  return {
    ...entityLineItem,
    startBid: currentBid,
    bidTier,
    impressionsWon,
    adClickThroughRate,
    adConversionRate,
    incrementalityMultiple,
    ...computeFinalAdMetrics({
      impressionsWon,
      currentBid,
      clicksRatio: 1,
      adClickThroughRate,
      adConversionRate,
      incrementalityMultiple,
      retailPriceAverage,
      brandCogsPerUnitAverage,
      wholesalePriceAverage
    })
  };
};

export const updateTargetEntityListMetrics = (
  {
    totalBudget,
    retailPriceAverage,
    wholesalePriceAverage,
    brandCogsPerUnitAverage,
    entityList,
    targetingTypeId,
    campaignTypeId
  }: {
    totalBudget: number;
    retailPriceAverage: number;
    wholesalePriceAverage: number;
    brandCogsPerUnitAverage: number;
    retailerGrossMarginPercentAverage: number;
    entityList: { selected: boolean; isBulkUploaded?: boolean; startBid?: number }[];
    targetingTypeId: AD_TARGETING_TYPE;
    campaignTypeId: string;
  },
  minimumBid?: number,
  maximumBid?: number
) => {
  let updatedEntityList = entityList.map((entityLineItem) => {
    if (!entityLineItem.selected) {
      return { ...entityLineItem };
    }

    return {
      ...computeTargetEntityMetrics(
        {
          entityLineItem: {
            ...entityLineItem,
            startBid: null,
            bidTier: BASE_METRIC_VALUES.cpcTier
          },
          retailPriceAverage,
          wholesalePriceAverage,
          brandCogsPerUnitAverage,
          cpcTierDirectionMultiple: 1,
          targetingTypeId,
          campaignTypeId
        },
        minimumBid,
        maximumBid
      )
    };
  });

  let totalSpend = updatedEntityList
    .filter((x) => x.selected)
    .reduce((totalSpendAcc, entityLineItem) => totalSpendAcc + entityLineItem.adSpend, 0);

  const cpcTierDirectionMultiple = totalSpend > totalBudget ? -1 : 1;
  let currentBidTier = BASE_METRIC_VALUES.cpcTier;
  let continuousIteration = 0;
  let prevSpend = null;
  while (!_isEmpty(updatedEntityList) && currentBidTier < 10 && currentBidTier > 1) {
    const newTargetEntityList = updatedEntityList.map((entityLineItem) => {
      if (!entityLineItem.selected) {
        return { ...entityLineItem };
      }

      return computeTargetEntityMetrics(
        {
          entityLineItem,
          retailPriceAverage,
          wholesalePriceAverage,
          brandCogsPerUnitAverage,
          cpcTierDirectionMultiple,
          targetingTypeId,
          campaignTypeId
        },
        minimumBid,
        maximumBid
      );
    });

    totalSpend = newTargetEntityList
      .filter(prop('selected'))
      .reduce((totalSpendAcc, entityLineItem) => totalSpendAcc + entityLineItem.adSpend, 0);
    if (prevSpend !== totalSpend) {
      prevSpend = totalSpend;
      continuousIteration = 0;
    } else {
      continuousIteration += 1;
    }

    if (cpcTierDirectionMultiple < 0 && totalSpend <= totalBudget) {
      break;
    } else if (cpcTierDirectionMultiple > 0 && totalSpend >= totalBudget) {
      break;
    }
    if (continuousIteration >= 3) {
      // it means we have been spinning in a loop for a while
      // and hence break
      break;
    }
    updatedEntityList = newTargetEntityList;
    currentBidTier = updatedEntityList[0].bidTier;
  }
  if (totalSpend > totalBudget) {
    return updatedEntityList
      .map((updatedEntity) => {
        if (updatedEntity.selected) {
          return {
            ...updatedEntity,
            ...computeFinalAdMetrics({
              impressionsWon: updatedEntity.impressionsWon,
              currentBid: updatedEntity.startBid,
              clicksRatio: totalBudget / totalSpend,
              adClickThroughRate: updatedEntity.adClickThroughRate,
              adConversionRate: updatedEntity.adConversionRate,
              incrementalityMultiple: updatedEntity.incrementalityMultiple,
              retailPriceAverage,
              wholesalePriceAverage,
              brandCogsPerUnitAverage
            })
          };
        } else {
          return updatedEntity;
        }
      })
      .sort((x, y) => {
        return x.adSales > y.adSales ? -1 : 1;
      });
  } else {
    return updatedEntityList.sort((x, y) => {
      return x.adSales > y.adSales ? -1 : 1;
    });
  }
};
