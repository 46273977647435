import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ChevronIcon, CheckboxCheckedIcon, CheckboxUncheckedIcon } from 'src/components/SvgIcons';
import withStyles from '@mui/styles/withStyles';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import colors from 'src/utils/colors';
import Input from '@mui/material/Input';
import ListSubheader from '@mui/material/ListSubheader';
import { AutoSizer, List as VirtualizedList } from 'react-virtualized';
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import _prop from 'lodash/property';

import { propEq, ne } from 'src/utils/fp';
import fontStyle from 'src/utils/fontStyle';

const INCLUDE_EXCLUDE_FILTER_DEBOUNCE_MS = 370;

const muiStyles = {
  root: {
    maxWidth: 176,
    margin: '0',
    '&:before': {
      content: 'none'
    }
  },
  disabled: {
    color: colors.lightGrey
  },
  progressWhite: {
    color: 'white'
  },
  progressBlack: {
    color: colors.darkBlue
  },
  select: {
    padding: '7px 25px 7px 1px',
    fontWeight: fontStyle.regularWeight,
    fontSize: 15,
    minWidth: '150px'
  },
  rangeSelect: {
    padding: '7px 25px 7px 1px',
    fontWeight: fontStyle.regularWeight,
    fontSize: 15,
    minWidth: '40px'
  },
  textField: {
    height: 31.81,
    width: '140px'
  },
  rangeTextField: {
    height: 32,
    width: '65px'
  },
  longTextField: {
    height: 32,
    width: '100%',
    margin: '0 20px'
  },
  listItemRoot: {
    fontWeight: fontStyle.regularWeight
  }
};

const styles = {
  campaignSelectMenuItemText: {
    textOverflow: 'ellipsis',
    overflowX: 'hidden'
  }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const CampaignSelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 11 + ITEM_PADDING_TOP,
      minWidth: 150,
      maxWidth: 600
    }
  }
};

const microsegments = [
  { displayName: 'segment1', name: 'sm1' },
  { displayName: 'segment2', name: 'sm2' },
  { displayName: 'segment3', name: 'sm3' }
];

// This is disabled for now, but may be re-enabled in the future.
const MicrosegmentFilter = ({ value, onChange, classes }) => (
  <div className="filter-box">
    <h4 className="sl-form-label">Microsegment</h4>
    <Select
      variant="standard"
      value={value}
      displayEmpty
      onChange={onChange}
      classes={{ root: classes.root, select: classes.select, disabled: classes.disabled }}
      IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
    >
      <MenuItem value="" disabled>
        Select Segment
      </MenuItem>

      {microsegments.map((item, idx) => (
        <MenuItem key={idx} value={item.name}>
          {item.displayName}
        </MenuItem>
      ))}
    </Select>
  </div>
);

MicrosegmentFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

const ALL_CAMPAIGNS = 'allCampaigns';
const ALL_AD_TYPES = 'allAdTypes';
const ALL_CAMPAIGNS_OPTION = { name: ALL_CAMPAIGNS, displayName: 'All Campaigns', id: ALL_CAMPAIGNS };
const ALL_AD_TYPES_OPTION = { name: ALL_AD_TYPES, displayName: 'All Ad Types', id: ALL_AD_TYPES };

const renderCampaignMenuValue = (selected) => {
  const selectedCount = selected.filter(ne(ALL_CAMPAIGNS)).length;
  return selectedCount > 0 ? `${selectedCount} Campaign${selectedCount > 1 ? 's' : ''} Selected` : 'All Campaigns';
};

// Temporarily disabled
const campaignSelectEnabled = false;

const CampaignFilterInner = ({
  campaign: campaignFilterState,
  applyCampaignsFilters: applyCampaignsFiltersInner,
  classes,
  adCampaigns
}) => {
  const [selectedCampaignsFilterOpen, setSelectedCampaignsFilterOpen] = useState(false);
  const [campaignTypeFilterOpen, setCampaignTypeFilterOpen] = useState(false);
  const [localCampaignSelection, setLocalCampaignSelectionInner] = useState(campaignFilterState);

  const applyCampaignsFilters = useCallback(
    ({ names, types }) =>
      applyCampaignsFiltersInner({
        names: names.filter(ne(ALL_CAMPAIGNS)),
        types: types.filter(ne(ALL_AD_TYPES))
      }),
    [applyCampaignsFiltersInner]
  );

  const setLocalCampaignSelection = useCallback(
    ({ names, types }) => {
      const filteredNames = names.filter(ne(ALL_CAMPAIGNS));
      const filteredTypes = types.filter(ne(ALL_AD_TYPES));

      setLocalCampaignSelectionInner({
        names:
          names.includes(ALL_CAMPAIGNS) &&
          !localCampaignSelection.names.includes(ALL_CAMPAIGNS) &&
          localCampaignSelection.names.length !== 0
            ? [ALL_CAMPAIGNS]
            : filteredNames,
        types:
          types.includes(ALL_AD_TYPES) &&
          !localCampaignSelection.types.includes(ALL_AD_TYPES) &&
          localCampaignSelection.types.length !== 0
            ? [ALL_AD_TYPES]
            : filteredTypes
      });
    },
    [localCampaignSelection.names, localCampaignSelection.types]
  );

  const nameOpts = (adCampaigns || []).map(({ name, id }) => ({
    name,
    campaignName: name,
    displayName: <span style={styles.campaignSelectMenuItemText}>{name}</span>,
    id
  }));
  const [campaignSearch, setCampaignSearch] = useState('');
  const typeOpts = [
    ALL_AD_TYPES_OPTION,
    { name: 'SponsoredProductAds', displayName: 'Sponsored Products' },
    { name: 'SearchAds', displayName: 'Sponsored Brands' },
    { name: 'DisplayAds', displayName: 'Display' }
  ];

  // This is a list of strings that represent the IDs of selected campaigns
  const selectedCampaigns = _isEmpty(localCampaignSelection.names) ? [ALL_CAMPAIGNS] : localCampaignSelection.names;
  // The following code sort and put the selected campaign on top
  nameOpts.sort((opt1, opt2) => selectedCampaigns.indexOf(opt2.id) - selectedCampaigns.indexOf(opt1.id));
  nameOpts.unshift(ALL_CAMPAIGNS_OPTION);

  // The following code makes the searchbar on top work
  const filteredCampaigns = nameOpts.filter((opt) =>
    (opt.campaignName || '').toLowerCase().includes(campaignSearch.toLowerCase())
  );

  const rowRenderer = useCallback(
    ({ index, style }) => {
      const { id, displayName } = filteredCampaigns[index];

      const handleClick = () => {
        const newSelects = selectedCampaigns.includes(id)
          ? selectedCampaigns.filter((cmp) => cmp !== id)
          : [...selectedCampaigns, id];
        setLocalCampaignSelection({ ...localCampaignSelection, names: newSelects });
      };

      return (
        <MenuItem style={{ ...style, padding: '0 16px' }} key={id} value={id} onClick={handleClick}>
          <Checkbox
            checkedIcon={<CheckboxCheckedIcon height={24} width={24} />}
            icon={<CheckboxUncheckedIcon height={24} width={24} />}
            disableRipple
            checked={selectedCampaigns.includes(id)}
            color="primary"
          />

          {displayName}
        </MenuItem>
      );
    },
    [filteredCampaigns, selectedCampaigns, localCampaignSelection, setLocalCampaignSelection]
  );

  const renderCampaignTypeValue = useCallback(
    (selected) => {
      const namesOfSelectedTypes = typeOpts.filter(({ name }) => selected.includes(name)).map(_prop('displayName'));

      if (namesOfSelectedTypes.length === 0) {
        return 'All Ad Types';
      }

      return namesOfSelectedTypes.join(', ');
    },
    [typeOpts]
  );

  return (
    <div className="filter-box">
      <h4 className="sl-form-label">Campaign</h4>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {campaignSelectEnabled ? (
          <Select
            variant="standard"
            multiple
            MenuProps={CampaignSelectMenuProps}
            renderValue={renderCampaignMenuValue}
            style={{ marginRight: 25 }}
            value={localCampaignSelection.names}
            displayEmpty
            onChange={(event) => setLocalCampaignSelection({ ...localCampaignSelection, names: event.target.value })}
            open={selectedCampaignsFilterOpen}
            onOpen={() => setSelectedCampaignsFilterOpen(true)}
            onClose={() => {
              applyCampaignsFilters(localCampaignSelection);
              setSelectedCampaignsFilterOpen(false);
            }}
            classes={{ root: classes.root, select: classes.select, disabled: classes.disabled }}
            IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
          >
            <ListSubheader key="placeholder" style={{ padding: 0, outline: 'none' }}>
              <div className="long-dropdown-search-box">
                {' '}
                <Input
                  name="include"
                  classes={{ root: classes.longTextField }}
                  placeholder="Search for Campaign"
                  value={campaignSearch}
                  onChange={({ target: { value } }) => setCampaignSearch(value)}
                />
              </div>
            </ListSubheader>
            <AutoSizer disableHeight style={{ minWidth: 450 }}>
              {({ width }) => (
                <VirtualizedList
                  overscanRowCount={4}
                  width={width}
                  height={415}
                  rowCount={filteredCampaigns.length}
                  rowHeight={45}
                  rowRenderer={rowRenderer}
                />
              )}
            </AutoSizer>
          </Select>
        ) : null}

        <Select
          variant="standard"
          multiple
          MenuProps={CampaignSelectMenuProps}
          renderValue={renderCampaignTypeValue}
          open={campaignTypeFilterOpen}
          onOpen={() => setCampaignTypeFilterOpen(true)}
          onClose={() => {
            applyCampaignsFilters(localCampaignSelection);
            setCampaignTypeFilterOpen(false);
          }}
          value={localCampaignSelection.types}
          displayEmpty
          onChange={({ target: { value } }) => setLocalCampaignSelection({ ...localCampaignSelection, types: value })}
          classes={{ root: classes.root, select: classes.select, disabled: classes.disabled }}
          IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
        >
          {typeOpts.map((item, idx) => (
            <MenuItem key={idx} value={item.name}>
              <Checkbox
                checkedIcon={<CheckboxCheckedIcon height={24} width={24} />}
                icon={<CheckboxUncheckedIcon height={24} width={24} />}
                disableRipple
                checked={
                  localCampaignSelection.types.includes(item.name) ||
                  (item.name === ALL_AD_TYPES && _isEmpty(localCampaignSelection.types))
                }
                color="primary"
              />
              {item.displayName}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

CampaignFilterInner.propTypes = {
  campaign: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  adCampaigns: PropTypes.arrayOf(PropTypes.object),
  applyCampaignsFilters: PropTypes.func.isRequired
};

CampaignFilterInner.defaultProps = {
  adCampaigns: null
};

const mapCampaignManagerStateToProps = ({ user: { adCampaigns } }) => ({ adCampaigns });

const CampaignFilter = connect(mapCampaignManagerStateToProps)(CampaignFilterInner);

const RangeFilter = ({ range, updateRange, classes }) => {
  const metricOpts = [
    { name: 'costPerClick', displayName: 'CPC' },
    { name: 'returnOnAdSpend', displayName: 'ROAS' }
  ];
  const cmpOpts = [
    { name: 'above', displayName: 'above' },
    { name: 'below', displayName: 'below' }
  ];
  const [value, setValue] = useState(range.value || '');

  return (
    <div className="filter-box" style={{ display: 'flex', flexDirection: 'column' }}>
      <h4 className="sl-form-label">Metrics</h4>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Select
          variant="standard"
          style={{ marginRight: 25 }}
          value={range.metric}
          displayEmpty
          onChange={(evt) => updateRange({ metric: evt.target.value })}
          classes={{ root: classes.root, select: classes.rangeSelect, disabled: classes.disabled }}
          IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
        >
          {metricOpts.map((item, idx) => (
            <MenuItem key={idx} value={item.name}>
              {item.displayName}
            </MenuItem>
          ))}
        </Select>
        <Select
          variant="standard"
          style={{ marginRight: 25 }}
          value={range.cmp}
          displayEmpty
          onChange={(evt) => updateRange({ cmp: evt.target.value })}
          classes={{ root: classes.root, select: classes.rangeSelect, disabled: classes.disabled }}
          IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
        >
          {cmpOpts.map((item, idx) => (
            <MenuItem key={idx} value={item.name}>
              {item.displayName}
            </MenuItem>
          ))}
        </Select>
        <span style={{ paddingBottom: 2, paddingRight: 1 }}>$</span>
        <Input
          name="include"
          classes={{ root: classes.rangeTextField }}
          placeholder="0.00"
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
          onKeyPress={(evt) => {
            if (evt.key !== 'Enter') {
              return;
            }

            updateRange({ value });
          }}
        />
      </div>
    </div>
  );
};

RangeFilter.propTypes = {
  range: PropTypes.object.isRequired,
  updateRange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

const KeywordsFilter = ({
  filters,
  enableFilters: { enableCampaign, enableRange },
  classes,
  setFilter,
  adCampaigns
}) => {
  const [include, setInclude] = useState(filters.include);
  const [exclude, setExclude] = useState(filters.exclude);

  const updateFiltersInner = useCallback(
    (event, filterOverrides = {}, capturedInclude, capturedExclude) => {
      if (event && event.key !== 'Enter') {
        return;
      }

      const campaignNames = adCampaigns
        ? filters.campaign.names.map((id) => (adCampaigns.find(propEq('campaignId', id)) || { name: '' }).name)
        : [];

      setFilter({
        include: capturedInclude,
        exclude: capturedExclude,
        campaign: { ...filters.campaign, names: campaignNames },
        range: filters.range,
        ...filterOverrides
      });
    },
    [setFilter, filters, adCampaigns]
  );

  const updateFilters = useCallback(
    (event, filterOverrides) => updateFiltersInner(event, filterOverrides, include, exclude),
    [include, exclude, updateFiltersInner]
  );

  const updateRange = useCallback(
    (newRange) => updateFilters(null, { range: { ...filters.range, ...newRange } }),
    [filters, updateFilters]
  );

  const debouncedUpdateFiltersInner = useCallback(_debounce(updateFiltersInner, INCLUDE_EXCLUDE_FILTER_DEBOUNCE_MS), [
    updateFiltersInner
  ]);

  return (
    <div className="keyword-filter-container">
      {enableCampaign ? (
        <CampaignFilter
          campaign={filters.campaign}
          applyCampaignsFilters={(campaignFilters) => updateFilters(null, { campaign: campaignFilters })}
          classes={classes}
        />
      ) : null}

      {enableRange ? <RangeFilter range={filters.range} updateRange={updateRange} classes={classes} /> : null}

      <div className="filter-box">
        <h4 className="sl-form-label">Include</h4>
        <Input
          name="include"
          onKeyPress={updateFilters}
          classes={{ root: classes.textField }}
          placeholder="Keyword to include"
          value={include}
          onChange={({ target: { value } }) => {
            debouncedUpdateFiltersInner(undefined, undefined, value, exclude);
            setInclude(value);
          }}
        />
      </div>

      <div className="filter-box">
        <h4 className="sl-form-label">Exclude</h4>
        <Input
          name="exclude"
          onKeyPress={updateFilters}
          classes={{ root: classes.textField }}
          placeholder="Keyword to exclude"
          value={exclude}
          onChange={({ target: { value } }) => {
            debouncedUpdateFiltersInner(undefined, undefined, include, value);
            setExclude(value);
          }}
        />
      </div>
    </div>
  );
};

KeywordsFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  enableFilters: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  // Redux Props
  adCampaigns: PropTypes.array
};

KeywordsFilter.defaultProps = {
  enableFilters: {},
  adCampaigns: []
};

export default connect(({ user: { adCampaigns } }) => ({ adCampaigns }))(withStyles(muiStyles)(KeywordsFilter));
