// This file renders the AGGridReact.

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _startCase from 'lodash/startCase';
import _pick from 'lodash/pick';
import _toLower from 'lodash/toLower';
import Waypoint from 'react-waypoint';

import { METRICTYPE } from 'src/utils/entityDefinitions';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { parsePromotionMetrics } from 'src/store/modules/entitySearchService/selectors';

import { EntityColumn } from '../../Grids/Data/ColumnTypes';
import PromotionsCellFormatter from './PromotionsCellFormatter';
import PromotionsGridHeaderFormatter from './PromotionsGridHeaderFormatter';
import convertMetricToDisplayValue, { addKeywordToEntityType } from '../gridUtils';
import EntityGridHeader from '../Header/EntityGridHeader';
import { anyNotEq } from '../../../utils/equality';
import './EntityTableContainer.scss';

const mkBuildRow = (retailer, currencySymbol) => (item) => {
  const {
    product,
    dealDate,
    promoType,
    stacklineSku,
    previousRetailPrice,
    previousRetailSales,
    retailPrice,
    retailSales,
    retailPriceChangePercent,
    retailSalesChangePercent
  } = item;
  const { brandName, brandId, title } = product;
  const parsedPromoType = _startCase(_toLower(promoType.split('_')));
  const parsedDealDate = moment(parseInt(dealDate, 10)).format('MMM-D-YYYY'); // dealDate received as string in some cases
  const currentDiscountPrice = convertMetricToDisplayValue(
    retailer,
    retailPrice,
    METRICTYPE.MONEY,
    currencySymbol,
    false
  );
  const previousDiscountPrice = convertMetricToDisplayValue(
    retailer,
    previousRetailPrice,
    METRICTYPE.MONEY,
    currencySymbol,
    false
  );

  const currentSalesLift = convertMetricToDisplayValue(retailer, retailSales, METRICTYPE.MONEY, currencySymbol, false);
  const previousSalesLift = convertMetricToDisplayValue(
    retailer,
    previousRetailSales,
    METRICTYPE.MONEY,
    currencySymbol,
    false
  );

  const percentFormatting = (percentValue) => {
    let metricIcon = { name: '+', className: 'plus' };
    const mainValue = convertMetricToDisplayValue(retailer, percentValue, METRICTYPE.PERCENT, currencySymbol, false);
    if (percentValue < 0) {
      metricIcon = { name: '\u2212', className: 'minus' };
    }
    const combinedPercentValue = `${metricIcon.name}${mainValue}`;
    return { value: combinedPercentValue, className: metricIcon.className };
  };
  const discountPricePercent = percentFormatting(retailPriceChangePercent);
  const salesLiftPercent = percentFormatting(retailSalesChangePercent);

  // TODO Make the type here dynamic to support other entity types
  return {
    name: title,
    id: stacklineSku,
    type: 'promotions',
    brandName,
    brandId,
    currentDiscountPrice,
    previousDiscountPrice,
    discountPricePercent,
    currentSalesLift,
    previousSalesLift,
    salesLiftPercent,
    parsedPromoType,
    parsedDealDate
  };
};

class PromotionsGridContainer extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    entitySearchService: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    fetchEntityMetrics: PropTypes.func.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    queryConditions: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    selectedEntity: PropTypes.object.isRequired
  };

  state = {
    pageNumber: 1
  };

  componentDidMount() {
    this.fetchPromotionDetails(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      anyNotEq(
        ['retailer', 'mainTimePeriod', 'queryConditions', 'selectedEntity', 'location.search'],
        this.props,
        nextProps
      )
    ) {
      this.fetchPromotionDetails(nextProps || this.props);
    }
  }

  onGridReady = ({ api, columnApi }) => {
    this.gridApi = api;
    this.gridColumnApi = columnApi;
    api.sizeColumnsToFit();
    window.addEventListener('resize', () => {
      setTimeout(() => {
        api.sizeColumnsToFit();
      });
    });
    api.sizeColumnsToFit();
  };

  fetchPromotionDetails = (props) => {
    const {
      app,
      comparisonTimePeriod,
      filters,
      retailer,
      fetchEntityMetrics,
      mainTimePeriod,
      queryConditions,
      selectedEntity,
      history
    } = props;
    // Initialize min and maxValue if no retailPrice filters set
    const { minValue, maxValue } = _get(filters, 'retailPrice') || { minValue: 0, maxValue: 1000000 };
    let clonedConditions = _cloneDeep(queryConditions);
    clonedConditions = {
      ...clonedConditions,
      rangeFilters: [
        {
          fieldName: 'weekId',
          minValue: mainTimePeriod.startWeek,
          maxValue: mainTimePeriod.endWeek
        },
        {
          fieldName: 'retailPrice',
          minValue,
          maxValue
        },
        {
          fieldName: 'retailSales',
          minValue: 0.01
        }
      ]
    };

    // add the keyword to the url when in the businessunit or segment page
    const { location } = history;
    const { pathname } = location;
    const newSelectedEntity = addKeywordToEntityType(pathname, selectedEntity);

    const requestOverrides = {
      additionalFieldsToReturn: [],
      aggregations: null,
      conditions: clonedConditions,
      pageNumber: this.state.pageNumber,
      pageSize: 10,
      returnDocuments: true,
      searchBy: 'child',
      period: comparisonTimePeriod.id.split('-')[1],
      sortFilter: {
        sortFields: [
          {
            fieldName: 'retailSales',
            direction: 'desc'
          }
        ]
      }
    };
    this.setState({ isLoading: true });
    const promises = [
      fetchEntityMetrics(
        'promotionsGridMetrics',
        {
          entity: newSelectedEntity,
          retailer,
          app,
          indexName: 'promotions',
          customResponseParser: parsePromotionMetrics
        },
        [requestOverrides]
      )
    ];

    Promise.all(promises)
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (!err.message.includes('Canceled network request')) {
          console.error(err);
        }
      });
  };

  //  Implement this- DO NOT DELETE
  handleWaypointEntered = () => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({ pageNumber: this.state.pageNumber + 1 }, () => {
      this.fetchPromotionDetails(this.props);
    });
  };

  render() {
    const { app, entitySearchService, retailer } = this.props;
    let gridOptions = {};

    const { promotionsGridMetrics } = entitySearchService;
    if (!promotionsGridMetrics) {
      return null;
    }
    promotionsGridMetrics.data = promotionsGridMetrics.data.filter((item) => {
      const currentRetailPrice = item.retailPrice ? item.retailPrice : 0;
      const prevRetailPrice = item.previousRetailPrice ? item.previousRetailPrice : 0;
      return currentRetailPrice !== prevRetailPrice && prevRetailPrice !== 0;
    });
    const { currencySymbol } = promotionsGridMetrics;
    const buildRow = mkBuildRow(retailer, currencySymbol);
    const cellStyle = { 'text-align': 'right', 'flex-direction': 'row-reverse', 'padding-right': '0', border: 0 };

    gridOptions = {
      columnDefs: [
        {
          headerName: 'Product',
          field: 'name',
          minWidth: 200,
          width: 600,
          cellRendererFramework: EntityColumn,
          cellStyle: {
            'justify-content': 'flex-start',
            'text-align': 'left',
            'flex-direction': 'row',
            border: '0'
          },
          headerClass: 'align-left'
        },
        {
          // Add total number of promotions in the header
          headerComponentFramework: PromotionsGridHeaderFormatter,
          headerName: `Promotions`,
          field: 'promotions',
          cellRendererFramework: PromotionsCellFormatter,
          cellStyle
        },
        {
          // Add total Discount in header
          headerComponentFramework: PromotionsGridHeaderFormatter,
          headerName: `Discount`,
          field: 'combinedDiscount',
          cellRendererFramework: PromotionsCellFormatter,
          cellStyle
        },
        {
          // Add total percent sales Lift
          headerComponentFramework: PromotionsGridHeaderFormatter,
          headerName: `Sales Lift`,
          field: 'salesLift',
          cellRendererFramework: PromotionsCellFormatter,
          cellStyle
        }
      ],
      buildRows: () => promotionsGridMetrics.data.map(buildRow)
    };

    const apiRequest = entitySearchService.promotionsGridMetrics
      ? entitySearchService.promotionsGridMetrics.apiRequest
      : [];

    return (
      <Fragment>
        <EntityGridHeader app={app} enableSwitchingLayouts={false} title="Products" exportRequest={apiRequest} />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ overflow: 'hidden', flexGrow: '1' }}>
            <div className="promotions-ag-material">
              <div style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                  domLayout="autoHeight"
                  columnDefs={gridOptions.columnDefs}
                  rowData={gridOptions.buildRows()}
                  rowHeight={65}
                  headerHeight={34}
                  onGridReady={this.onGridReady}
                />
                <Waypoint onEnter={this.handleWaypointEntered} />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, ['app', 'comparisonTimePeriod', 'entitySearchService', 'filters', 'mainTimePeriod', 'retailer']);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromotionsGridContainer));
