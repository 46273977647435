import React, { FC } from 'react';

import { Chip, FormControl, InputAdornment, TextField, Autocomplete } from '@mui/material';
import { Search } from '@mui/icons-material';

interface DiscoverCategorySearchProps {
  isSuperUser: boolean;
  handleCategoryChange: (event: any, values: any) => void;
  selectedCategory: any;
  categories: any;
  superUserCategories: any;
  onDeleteCategory: (value: string) => void;
}
const DiscoverCategorySearch: FC<DiscoverCategorySearchProps> = ({
  isSuperUser,
  categories,
  superUserCategories,
  handleCategoryChange,
  selectedCategory,
  onDeleteCategory
}) => {
  let options = !isSuperUser ? categories : superUserCategories;
  options = options === undefined ? [] : options;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '3em', width: '90%' }}>
      <>
        <div
          style={{
            marginTop: '1em',
            border: '1px solid #f6f9fd',
            borderRadius: '5px',
            padding: '7px 11px',
            width: '100%',
            background: '#f6f9fd',
            height: '60px'
          }}
        >
          <FormControl style={{ width: '100%' }}>
            <Autocomplete
              id="tags-outlined-cat"
              multiple
              options={options}
              getOptionLabel={(option) => (option ? option.categoryName : '')}
              onChange={handleCategoryChange}
              value={selectedCategory}
              renderTags={() => null}
              // renderOption={(option) => (
              //   <span className="popOverText" style={{ fontSize: '14px', fontWeight: 500, color: '#052849 ' }}>
              //     {option.categoryName}
              //   </span>
              // )}
              renderInput={(selected) => {
                return (
                  <>
                    <TextField
                      hiddenLabel
                      variant="standard"
                      style={{ width: '100%' }}
                      {...selected}
                      InputLabelProps={{
                        style: { color: '#052849 ' }
                      }}
                      InputProps={{
                        ...selected.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search className="filter-blue" />
                          </InputAdornment>
                        )
                      }}
                      placeholder="Search Categories"
                    />
                  </>
                );
              }}
            ></Autocomplete>
          </FormControl>
        </div>
      </>
      <>
        <div className="chipContainer ">
          {/* <ChipsContainer
            dataKey={'displayName'}
            chipData={selectedCategory.map((category) => {
              return { ...category, key: category.id };
            })}
            style={{ marginTop: '1rem' }}
            onDelete={(key) => {
              const result = selectedCategory.filter((category) => category.id !== key)
              setSelectedCategory
            }}
          /> */}
          {selectedCategory &&
            selectedCategory.map((value: { categoryName: string }) => {
              return (
                <Chip
                  key={value.categoryName}
                  label={value.categoryName}
                  onDelete={() => {
                    onDeleteCategory(value.categoryName);
                  }}
                  className="search-chip discover-search-chip"
                />
              );
            })}
        </div>
      </>
    </div>
  );
};

export default DiscoverCategorySearch;
