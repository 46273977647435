import React from 'react';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/utils/Hooks';
import { calculateNewQueryParams } from 'src/store/modules/app/selectors';

export const SegmentCreationSnackbar = ({ isTopicBuilder }: { isTopicBuilder: boolean }) => {
  const app = useAppSelector((state) => state.app);
  const retailer = useAppSelector((state) => state.retailer);
  const allWeekIdsByRetailerId = retailer;
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const comparisonTimePeriod = useAppSelector((state) => state.comparisonTimePeriod);

  const route = `/home${
    calculateNewQueryParams(
      app,
      { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
      mainTimePeriod,
      comparisonTimePeriod
    ).searchParams
  }&tab=summary&subtab=${app.name}&entityType=${isTopicBuilder ? 'searchtermlist' : 'segment'}`;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text variant="subtitle3">
        {isTopicBuilder ? 'Topic' : 'Segment'} created successfully. View all {isTopicBuilder ? 'topics' : 'segments'}{' '}
        <Link to={route} style={{ textDecoration: 'underline' }}>
          here.
        </Link>
      </Text>
    </div>
  );
};
