import React from 'react';
import StyledInput from 'src/components/BeaconRedesignComponents/common/StyledInput/StyledInput';
import numeral from 'numeral';

/**
 * Represents an abstraction of the string type by adding more readable methods
 */
class FormattedString {
  private formattedString: string;

  public constructor() {
    this.formattedString = '';
  }

  /**
   * Adds a character to the formatted string.
   * @param {string} char - The character to add.
   */
  public addChar(char: string) {
    this.formattedString += char;
  }

  /**
   * Returns the current formatted string.
   */
  public create() {
    return this.formattedString;
  }

  /**
   * Checks if the formatted string contains a specific character.
   */
  public has(char: string) {
    return this.formattedString.indexOf(char) > -1;
  }

  /**
   * Adds a prefix to the formatted string.
   * @param {string} char - The prefix to add.
   */
  public addPrefix(char: string) {
    const chars = [char, ...Array.from(this.formattedString)];
    this.formattedString = chars.join('');
  }

  /**
   * Separates a string based on it decimal separator character.
   * @param decimalSeparator a string character representing the decimal separator
   */
  private _splitIntegersAndDecimals(decimalSeparator = '.') {
    const [int, decimalValues] = this.formattedString.split(decimalSeparator);
    return { int, decimalValues };
  }

  /**
   * Adds (thousands) separators to the formatted string.
   */
  public addSeparators(thousands = ',', decimalSeparator = '.') {
    const { int, decimalValues } = this._splitIntegersAndDecimals(decimalSeparator);
    this.formattedString = `${int.replace(/\B(?=(\d{3})+(?!\d))/g, thousands)}${
      this.has(decimalSeparator) ? decimalSeparator : ''
    }${decimalValues || ''}`;
  }

  /**
   * Converted the formatted string to a number
   * @param decimalSeparator The locale specific decimal separator
   * @returns a number
   */
  private _convertToNumber(decimalSeparator = '.') {
    const numVal = Number(this.formattedString.replace(decimalSeparator, '.'));
    return numVal;
  }

  /**
   * Limits the amount of trailing decimals allowed. Defaults to 2
   */
  public limitDecimals(decimalSeparator = '.', limit = 2) {
    const { decimalValues } = this._splitIntegersAndDecimals(decimalSeparator);
    if (decimalValues && decimalValues.length > limit) {
      const numericValue = this._convertToNumber(decimalSeparator);
      const fixedValue = numericValue.toFixed(limit);
      this.formattedString = String(fixedValue).replace('.', decimalSeparator);
    }
  }

  /**
   * Limits the leading zero to 1 instance, otherwise splices the leading 0 from the string
   */
  public limitLeadingZeroes(decimalSeparator = '.') {
    const { int } = this._splitIntegersAndDecimals(decimalSeparator);
    if (int && int[0] === '0' && int.length > 1) {
      this.formattedString = Array.from(int).splice(1).join('');
    }
  }
}

/** Utils for converting a string to a valid stringified number */

/**
 * Escape regex char
 *
 * See: https://stackoverflow.com/questions/17885855/use-dynamic-variable-string-as-regex-pattern-in-javascript
 */
const escapeRegExp = (stringToGoIntoTheRegex: string): string => {
  return stringToGoIntoTheRegex.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
};

/**
 * Removes invalid characters from a string based on a list of valid characters.
 */

const removeInvalidChars = (value: string, validChars: string[]): string => {
  const chars = escapeRegExp(validChars.join(''));
  const reg = new RegExp(`[^\\d${chars}]`, 'gi');
  return value.replace(reg, '');
};

/**
 *  Removes extra separators from a numeric string, based on the decimal separator and a limit.
 */
const removeExtraSeparators = (value: string, decimalSeparator: string, decimalLimit = 2) => {
  const [int, decimals] = value.split(decimalSeparator);

  const trimmedDecimals = decimalLimit && decimals ? decimals.slice(0, decimalLimit) : decimals;
  const decimalValues = trimmedDecimals || '';

  return `${int}${value.includes(decimalSeparator) ? decimalSeparator : ''}${decimalValues}`;
};

/**
 * Replaces comma-decimal separators for certain currencies if necessary
 */
const replaceDecimalSeparators = (value: string) => {
  return value.replace(',', '.');
};

/**
 * Parses through a formatted string and returns a valid stringified number
 * @param value the FormattedString instance
 * @returns a string with only digits and '.' as a decimal separator if necessary
 */
const getRawValues = (value: string, decimalSeparator = '.') => {
  const withoutInvalidChars = removeInvalidChars(value, [decimalSeparator]);
  const withValidSeparators = removeExtraSeparators(withoutInvalidChars, decimalSeparator);
  return replaceDecimalSeparators(withValidSeparators);
};

interface CurrencyInputProps extends Omit<React.ComponentProps, typeof StyledInput> {
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  modifyInitialValues?: ((s: string) => string) | null;
}
const useCurrencyHelpers = () => {
  // Establish locale
  const { currency, delimiters } = numeral.localeData();

  /**
   * We should only ever accept valid digits and "." decimal separators
   */
  const decimalSeparatorPattern = new RegExp(`[\\d${escapeRegExp('.')}]`, 'g');

  const inputToCurrency = (value: string | number, decimalSeparator = delimiters.decimal): string => {
    const input = String(value);

    if (input === '' || input === undefined) {
      return input;
    }

    // Create a new formatted string instance to build
    const formattedString = new FormattedString();

    // Iterate through the users input
    Array.from(input).forEach((char) => {
      if (char.match(decimalSeparatorPattern)) {
        if (char === '.') {
          // Add the decimal separator if one does not already exist
          if (!formattedString.has('.')) {
            formattedString.addChar(decimalSeparator);
          }
        } else {
          // Otherwise just add the valid character
          formattedString.addChar(char);
        }
      }
    });

    // Limit leading zeroes
    formattedString.limitLeadingZeroes(delimiters.decimal);

    // Limit trailing decimal values
    formattedString.limitDecimals(delimiters.decimal);

    // Add separators to leading integers
    formattedString.addSeparators(delimiters.thousands, delimiters.decimal);

    // Add the prefixed currency symbol
    formattedString.addPrefix(currency.symbol);

    // Create the string
    return formattedString.create();
  };
  const currencyToNum = (formattedCurrency: string): string => {
    return getRawValues(formattedCurrency, delimiters.decimal);
  };
  return {
    inputToCurrency,
    currencyToNum
  };
};

/**
 * A wrapper around StyledInput that intercepts the provided value and converts it to a currency.
 * The value provided to onChange will always be a valid stringified version of a number
 */
export function CurrencyInput({ value, onChange, ...rest }: CurrencyInputProps) {
  const { inputToCurrency, currencyToNum } = useCurrencyHelpers();

  return (
    <StyledInput
      value={inputToCurrency(value)}
      onChange={(e) => {
        const event: React.ChangeEvent<HTMLInputElement> = {
          ...e,
          target: { ...e.target, value: currencyToNum(e.target.value) }
        };
        onChange(event);
      }}
      {...rest}
    />
  );
}
