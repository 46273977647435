import React, { useCallback, useMemo, useState } from 'react';
import { BusinessUnit, Segment } from 'sl-api-connector';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import UserTimestampCell from 'src/components/BeaconRedesignComponents/UserTimestampCell/UserTimestampCell';
import { Link } from 'react-router-dom';
import { useTableData } from 'src/serverProxy/useTableData';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import { INDEX_FIELDS, METRICTYPE } from 'src/utils/entityDefinitions';
import { getAppName } from 'src/utils/app';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import { EMPTY_BUSINESS_UNIT_NAME } from './utils';
import { BusinessUnitsCreationModal } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Segments/BusinessUnitsCreation/BusinessUnitsCreationModal';
import { BusinessUnitsDeletionModal } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Segments/BusinessUnitsCreation/BusinessUnitsDeletionModal';
import { truncateWithEllipsis } from 'src/utils/stringFormatting';
import NoDataPlaceHolder from 'src/components/BeaconRedesignComponents/common/NoDataPlaceHolder/NoDataPlaceHolder';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

interface BusinessUnitMetricCellProps {
  segmentConditions: Segment['conditions'];
  fieldName: string;
  segmentId: string;
}
/**
 * Renders a metric cell for the business unit table. It will represent
 * the total metric value for the segment in the table row.
 */
const BusinessUnitMetricCell = ({ segmentConditions, fieldName, segmentId }: BusinessUnitMetricCellProps) => {
  const formatMetric = useMetricFormatter();
  const field = useMemo(() => {
    return INDEX_FIELDS.getField(getAppName(), 'sales', fieldName);
  }, [fieldName]);

  const buildRequest = useCallback(
    (body: AdvancedSearchRequestBuilder) => {
      body.clearConditionRangeFilters().clearConditionTermFilters();

      if (segmentConditions.rangeFilters) {
        segmentConditions.rangeFilters.forEach((rangeFilter) => {
          body.addConditionRangeFilter(rangeFilter.fieldName, rangeFilter.minValue, rangeFilter.maxValue);
        });
      }

      if (segmentConditions.termFilters) {
        segmentConditions.termFilters.forEach((termFilter) => {
          body.addConditionTermFilter(termFilter.fieldName, termFilter.condition, termFilter.values);
        });
      }
      return body;
    },
    [segmentConditions]
  );

  const { getValuesByFieldName, isLoading } = useTableData({
    fields: [
      {
        name: 'retailSales'
      },
      {
        name: 'unitsSold'
      },
      {
        name: 'retailPrice'
      },
      {
        name: 'instockRate'
      },
      {
        name: 'unitsOnHand'
      }
    ],
    groupByFieldName: 'retailerId',
    indexName: 'sales',
    itemsPerPage: 10000,
    pageNumber: 1,
    requestId: `metricsBySegmentIdForBusinessUnit-${segmentId}`,
    buildRequest
  });

  return (
    <Text variant="body2">
      {isLoading ? (
        <SlSkeleton height={12} width="100%" />
      ) : (
        formatMetric(getValuesByFieldName(fieldName)[0], field.metricType, {
          showFullValue: field.metricType === METRICTYPE.PERCENT,
          decimalPlaces: 2
        })
      )}
    </Text>
  );
};

export interface BusinessUnitTableProps {
  businessUnit: BusinessUnit;
}

/**
 * Displays a table of the segments inside of a business unit
 */
const BusinessUnitTable = ({ businessUnit }: BusinessUnitTableProps) => {
  const { searchParams, additionalParams } = useAppSelector((state) => state.app.queryParams);
  const mySegments = useAppSelector((state) => state.segments.mySegments);
  const teamSegments = useAppSelector((state) => state.segments.teamSegments);
  const segments = useMemo(
    () => [...mySegments, ...teamSegments].filter((segment) => businessUnit.children.includes(segment.id)),
    [mySegments, businessUnit, teamSegments]
  );

  // Edit Business Unit Modal State
  const [businessUnitEditModalOpen, setBusinessUnitEditModalOpen] = useState(false);
  const [businessUnitSelected, setBusinessUnitSelected] = useState(null);

  // Delete Business Unit Modal State
  const [businessUnitDeleteModalOpen, setBusinessUnitDeleteModalOpen] = useState(false);
  const [businessUnitForDeletion, setBusinessUnitForDeletion] = useState(null);

  // Handle "Edit" button click
  const handleEditClick = (businessUnitToEdit: BusinessUnit) => {
    setBusinessUnitSelected(businessUnitToEdit);
    setBusinessUnitEditModalOpen(true);
  };

  // Handle closing of BU Edit modal
  const handleClose = () => {
    setBusinessUnitEditModalOpen(false);
    setBusinessUnitSelected(null);
  };

  // Handle "Delete" button click
  const handleDeleteClick = (businessUnitToDelete: BusinessUnit) => {
    setBusinessUnitDeleteModalOpen(true);
    setBusinessUnitForDeletion(businessUnitToDelete);
  };

  // Handle closing of BU Delete modal
  const handleDeleteModalClose = () => {
    setBusinessUnitDeleteModalOpen(false);
    setBusinessUnitForDeletion(null);
  };

  const segmentIdToSegmentMap = useMemo(() => {
    return segments.reduce(
      (acc, segment) => ({
        ...acc,
        [segment.id]: segment
      }),
      {} as { [key: string]: Segment }
    );
  }, [segments]);

  return (
    <Flex flexDirection="column" gap="lg">
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="h4">{businessUnit.displayName || EMPTY_BUSINESS_UNIT_NAME}</Text>
        <Flex gap="md">
          <SlButton disabled={!businessUnit.canEdit} onClick={() => handleEditClick(businessUnit)}>
            Edit
          </SlButton>
          <SlButton disabled={!businessUnit.canEdit} onClick={() => handleDeleteClick(businessUnit)}>
            Delete
          </SlButton>
        </Flex>
      </Flex>
      <EntityTable
        appTableProps={{
          noDataPlaceholder: <NoDataPlaceHolder />
        }}
        initialPageSize={10}
        supportViewMore
        shouldModifyColumnDefs={false}
        rowData={businessUnit.children
          .filter((segmentId) => segmentIdToSegmentMap[segmentId])
          .map((segmentId) => {
            return {
              segment: segmentIdToSegmentMap[segmentId],
              segmentName: segmentIdToSegmentMap[segmentId].displayName,
              retailSales: 0,
              unitsSold: 0,
              retailPrice: 0,
              instockRate: 0,
              unitsOnHand: 0,
              lastUpdated: 'N/A'
            };
          })}
        columnDefs={[
          {
            headerName: 'Segment Name',
            field: 'segmentName',
            tableColumnProps: {
              style: {
                width: '300px'
              }
            },
            valueFormatter(_, row: { segment: Segment }) {
              return (
                <Link to={`/segment/${row.segment.id}${searchParams}${additionalParams}`}>
                  <Text variant="body2">{truncateWithEllipsis(40, row.segment.displayName, '...')}</Text>
                </Link>
              );
            }
          },
          {
            headerName: 'Retail Sales',
            field: 'retailSales',
            valueFormatter(_, row: { segment: Segment }) {
              return (
                <BusinessUnitMetricCell
                  segmentConditions={row.segment.conditions}
                  fieldName="retailSales"
                  segmentId={row.segment.id}
                />
              );
            }
          },
          {
            headerName: 'Units Sold',
            field: 'unitsSold',
            valueFormatter(_, row: { segment: Segment }) {
              return (
                <BusinessUnitMetricCell
                  segmentConditions={row.segment.conditions}
                  fieldName="unitsSold"
                  segmentId={row.segment.id}
                />
              );
            }
          },
          {
            headerName: 'Retail Price',
            field: 'retailPrice',
            valueFormatter(_, row: { segment: Segment }) {
              return (
                <BusinessUnitMetricCell
                  segmentConditions={row.segment.conditions}
                  fieldName="retailPrice"
                  segmentId={row.segment.id}
                />
              );
            }
          },
          {
            headerName: 'In-Stock Rate',
            field: 'instockRate',
            valueFormatter(_, row: { segment: Segment }) {
              return (
                <BusinessUnitMetricCell
                  segmentConditions={row.segment.conditions}
                  fieldName="instockRate"
                  segmentId={row.segment.id}
                />
              );
            }
          },
          {
            headerName: 'Units on Hand',
            field: 'unitsOnHand',
            valueFormatter(_, row: { segment: Segment }) {
              return (
                <BusinessUnitMetricCell
                  segmentConditions={row.segment.conditions}
                  fieldName="unitsOnHand"
                  segmentId={row.segment.id}
                />
              );
            }
          },
          {
            headerName: 'Last Updated',
            field: 'lastUpdated',
            valueFormatter(_, row: { segment: Segment }) {
              const { userId } = row.segment;
              const { firstName, lastName } = row.segment.segment.owner;
              return (
                <UserTimestampCell
                  userId={userId}
                  date={row.segment.lastUpdatedTime}
                  firstName={firstName}
                  lastName={lastName}
                />
              );
            }
          }
        ]}
      />
      <div style={{ position: 'absolute' }}>
        {businessUnitEditModalOpen && businessUnitSelected ? (
          <BusinessUnitsCreationModal
            businessUnitToEdit={businessUnitSelected}
            open={businessUnitEditModalOpen}
            handleClose={handleClose}
          />
        ) : null}
      </div>
      <div style={{ position: 'absolute' }}>
        {businessUnitDeleteModalOpen && businessUnitForDeletion ? (
          <BusinessUnitsDeletionModal
            businessUnitToDelete={businessUnitForDeletion}
            open={businessUnitDeleteModalOpen}
            handleClose={handleDeleteModalClose}
          />
        ) : null}
      </div>
    </Flex>
  );
};

export default BusinessUnitTable;
