import { Category } from '@mui/icons-material';
import { Box } from '@mui/system';
import { SlColumn, useStacklineTheme, hexToRgba } from '@stackline/ui';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import { SlRow } from 'src/components/BeaconRedesignComponents/Generic/SlRow';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import {
  getColorForMetricChangeValue,
  getIconForMetricChange
} from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import {
  BEACON_PRO_ENTITY_CARD_HEIGHT,
  BEACON_PRO_ENTITY_CARD_WIDTH,
  ENTITY_CARD_PERIODIC_TABLE_SIZE
} from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { DownChevron } from 'src/components/SvgIcons/SvgIcons';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

interface SubCategory {
  id: number;
  subCategoryName: string;
  name: string;
  type: string;
  displayName: string;
}
export interface Category {
  id: number;
  categoryId: number;
  categoryName: string;
  name: string;
  displayName: string;
  childEntities: SubCategory[];
  value: number;
  categoryType: string;
  currentRetailSales: number;
  retailSalesChange: number;
}

interface CategoryCardProps {
  val: Category;
  isLoading: boolean;
}

const SubcategoryList = ({
  childEntities,
  searchParams,
  additionalParams
}: Pick<Category, 'childEntities'> & {
  searchParams: string;
  additionalParams: string;
}) => {
  const max_to_show = 12;
  const [showAll, setShowAll] = useState(false);

  return (
    <Box
      maxHeight="300px"
      sx={{
        width: '100%',
        p: 0, // remove padding
        m: 0, // remove margin if any
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '&': {
          msOverflowStyle: 'none',
          scrollbarWidth: 'none'
        }
      }}
      overflow={showAll ? 'scroll' : undefined}
      display="flex"
      flexDirection="column"
      gap="8px"
    >
      {childEntities.slice(0, showAll ? undefined : max_to_show).map((child) => (
        <Link
          to={`/${child.type}/${child.id}${searchParams}${additionalParams}`}
          key={`link:${child.type}/${child.id}`}
          onClick={(event) => event.stopPropagation()}
        >
          <Text variant="body2">{child.name}</Text>
        </Link>
      ))}
      {
        // If there are more than max_to_show subcategories, show a link to show all
        childEntities.length > max_to_show ? (
          <Box
            key={`link:${showAll ? 'view-less' : 'view-more'}}`}
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            onClick={() => setShowAll(!showAll)}
          >
            <Text variant="subtitle3">{showAll ? 'View less' : 'View more'}</Text>
            <div
              style={{
                width: '16px',
                height: '16px',
                transform: showAll ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            >
              <DownChevron width="16px" height="16px" />
            </div>
          </Box>
        ) : null
      }
    </Box>
  );
};

/**
 * Generic container Category Card
 */
const CategoryCard: React.FC<CategoryCardProps> = ({ val, isLoading }) => {
  const theme = useStacklineTheme();
  const { displayName, childEntities, id, currentRetailSales, retailSalesChange } = val;
  const { searchParams, additionalParams } = useAppSelector((state) => state.app.queryParams);
  const formatMetric = useMetricFormatter();

  return (
    <div
      style={{
        width: BEACON_PRO_ENTITY_CARD_WIDTH,
        minHeight: BEACON_PRO_ENTITY_CARD_HEIGHT,
        borderRadius: theme.spacing.sm,
        border: `1px solid ${theme.colors.primaryGray}`,
        position: 'relative'
      }}
    >
      <SlColumn verticalInset="md" horizontalInset="md" widths="full">
        <SlRow>
          <div
            style={{
              backgroundColor: hexToRgba(theme.colors.accentTeal, 0.1),
              width: ENTITY_CARD_PERIODIC_TABLE_SIZE,
              height: ENTITY_CARD_PERIODIC_TABLE_SIZE,
              borderRadius: theme.spacing.xs,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Text variant="subtitle1" color="accentTeal">
              {displayName.substring(0, 2)}
            </Text>
          </div>
        </SlRow>
        <SlRow verticalInset="sm" style={{ paddingBottom: '10px', marginTop: '6px' }}>
          <Link to={`/category/${id}${searchParams}${additionalParams}`}>
            <Text variant="subtitle1" sx={{ fontSize: '18px' }}>
              {displayName}
            </Text>
          </Link>
        </SlRow>
        <SubcategoryList
          childEntities={childEntities}
          searchParams={searchParams}
          additionalParams={additionalParams}
        />
      </SlColumn>
      <Flex paddingX="16px">
        <div style={{ position: 'absolute', bottom: theme.spacing.md }}>
          <SlColumn verticalPosition="center" spacing="xs">
            <Link to={`/category/${id}${searchParams}&tab=sales&subtab=retailSales`}>
              <Text transform="uppercase" variant="subtitle2">
                Retail Sales
              </Text>
            </Link>
            {isLoading ? (
              <SlSkeleton style={{ marginLeft: '-2px' }} variant="rounded" height={20} width={80} />
            ) : (
              <SlRow verticalPosition="center">
                <Text variant="body1">
                  {formatMetric(currentRetailSales, METRICTYPE.MONEY, {
                    decimalPlaces: 1,
                    showFullValue: false
                  })}
                </Text>
                <SlRow horizontalInset="sm" verticalPosition="center">
                  {getIconForMetricChange(retailSalesChange)}
                  <SlRow horizontalInset="xs">
                    <Text variant="subtitle2" color={getColorForMetricChangeValue(retailSalesChange)}>
                      {' '}
                      {formatMetric(Math.abs(retailSalesChange), METRICTYPE.PERCENT, {
                        decimalPlaces: 2,
                        showFullValue: true,
                        showNegative: true
                      })}
                    </Text>
                  </SlRow>
                </SlRow>
              </SlRow>
            )}
          </SlColumn>
        </div>
      </Flex>
    </div>
  );
};

export default CategoryCard;
