import { SlRow, StacklineColor } from '@stackline/ui';
import React, { FC } from 'react';
import { useAppSelector } from 'src/utils/Hooks';
import LegendIndicator from './LegendIndicator';

export interface LegendProps {
  mainIndicatorColor?: StacklineColor;
  comparisonIndicatorColor?: StacklineColor;
  showComparisonPeriod?: boolean;
}

/**
 *
 * @param mainIndicatorColor overrides the main legend pill color
 * @param comparisonIndicatorColor overrides the comparison legend pill color
 * @returns simple div containing a main legend and an optional comparison legend
 */
export const PageLegend: FC<LegendProps> = ({
  showComparisonPeriod = true,
  mainIndicatorColor = 'primary',
  comparisonIndicatorColor = 'casper'
}) => {
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const { displayName: mainDisplayName } = mainTimePeriod;

  const comparisonTimePeriod = useAppSelector((state) => state.comparisonTimePeriod);
  const { displayName: comparisonDisplayName } = comparisonTimePeriod;

  return (
    <SlRow spacing="lg">
      {showComparisonPeriod ? (
        <LegendIndicator color={comparisonIndicatorColor} displayName={comparisonDisplayName} />
      ) : null}
      <LegendIndicator color={mainIndicatorColor} displayName={mainDisplayName} />
    </SlRow>
  );
};
