import { SxProps, styled } from '@mui/material/styles';
import { StacklineTheme, hexToRgba, useStacklineTheme } from '@stackline/ui';
import React from 'react';
import { SlButton, SlButtonProps } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

const StyledButton = styled(SlButton)((props: { stacklineTheme: StacklineTheme }) => {
  const { stacklineTheme } = props;
  return {
    '& p': {
      fontSize: '12px',
      fontWeight: 500
    },
    border: 'none',
    padding: '6px 8px',
    minWidth: 'unset',
    height: 'unset',
    backgroundColor: hexToRgba(stacklineTheme.colors.primaryLight, 0.1)
  };
});

export type MiniButtonProps = Pick<SlButtonProps, 'children' | 'onClick'> & { sx?: SxProps };

/**
 * Tiny button with 8px font size and minimal padding
 */
const MiniButton = (props: MiniButtonProps) => {
  const theme = useStacklineTheme();
  return <StyledButton stacklineTheme={theme} {...props} />;
};

export default MiniButton;
