/**
 * Mock bulk adjustment server proxy for testing the
 * bulk adjustment flow without hitting the backend
 */

import { ServerProxy } from './types';

/**
 * Util for adding an artifical delay to a function
 */
export function createDelayedFunction<T>(
  fn: (...args: any[]) => Promise<T> | T,
  delay: number = 0
): (...args: any[]) => Promise<T> {
  return (...args) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(fn(...args));
      }, delay);
    });
  };
}

export default function createMockBulkAdjustmentServerProxy(): ServerProxy {
  return {
    uploadBulkAdjustment: createDelayedFunction(() => {
      return {
        url: 'https://www.google.com',
        isSuccess: true,
        message: 'Success',
        bulkAdjustmentId: '123',
        userId: '123',
        planType: 1,
        beaconClientId: 1,
        retailerId: 1
      };
    }),
    updateUserUploadBulkAdjustmentStatus: createDelayedFunction(() => {
      return [
        {
          bulkAdjustmentId: '123',
          message: 'Success',
          isSuccess: true,
          userId: '123',
          planType: 1,
          beaconClientId: 1,
          retailerId: 1
        }
      ];
    }),
    validateBulkAdjustment: createDelayedFunction(() => {
      return [
        {
          isSuccess: true,
          message: 'Success',
          bulkAdjustmentId: '123',
          userId: '123',
          planType: 1,
          beaconClientId: 1,
          retailerId: 1
        }
      ];
    }),
    triggerDownloadBulkAdjustmentTemplate: createDelayedFunction(() => {
      return [
        {
          bulkAdjustmentId: 'bulk_20231220233844450_243c2670',
          message: 'Initiated',
          isSuccess: true,
          userId: '73cb712c-d771-4dbb-882d-e3475edabbc7',
          planType: 0,
          beaconClientId: 162,
          retailerId: 1
        }
      ];
    }),
    pollBulkAdjustments: createDelayedFunction(() => {
      return [
        {
          pk: '162-1',
          sk: 'OrganicTraffic-bulk_20231219232052157_40ecfe51-Template',
          beaconClientId: 162,
          retailerId: 1,
          userId: '73cb712c-d771-4dbb-882d-e3475edabbc7',
          planType: 0,
          bulkAdjustmentId: 'bulk_20231219232052157_40ecfe51',
          bulkAdjustmentStage: 0,
          bulkAdjustmentLevel: 'Org',
          bulkAdjustmentLevelId: '162',
          fileUri:
            '{"s3Bucket": "beacon-bulk-adjustments", "s3Key": "Downloads/162/1/bulk_20231219232052157_40ecfe51.xlsx"}',
          fileName: null,
          isUserDownload: false,
          processingStatus: 'Success',
          requestedDate: '2023-12-19T18:20:52.158-05:00',
          processedDate: '2023-12-19T18:29:54.498-05:00',
          lastUpdatedTime: 1703028594498.0,
          additionalDetails: '{"totalNetImpact": 5406}',
          summaryDetails:
            '{"min_weekId": 202410, "max_weekId": 202413, "avg_unadjusted_forecast": 325688, "avg_adjusted_forecast": 400000}'
        }
      ] as const;
    }),
    publishBulkAdjustment: createDelayedFunction(() => {
      return [
        {
          bulkAdjustmentId: 'bulk_280_Organic_Traffic',
          message: 'Initiated_NetImpact',
          isSuccess: true,
          userId: '73cb712c-d771-4dbb-882d-e3475edabbc7',
          planType: 0,
          beaconClientId: 280,
          retailerId: 1
        },
        {
          bulkAdjustmentId: 'bulk_280_Organic_Traffic',
          message: 'Initiated_AdjustedForecast',
          isSuccess: true,
          userId: '73cb712c-d771-4dbb-882d-e3475edabbc7',
          planType: 0,
          beaconClientId: 280,
          retailerId: 1
        },
        {
          bulkAdjustmentId: 'bulk_280_Organic_Traffic',
          message: 'Initiated_AdjustedCandleStick',
          isSuccess: true,
          userId: '73cb712c-d771-4dbb-882d-e3475edabbc7',
          planType: 0,
          beaconClientId: 280,
          retailerId: 1
        }
      ];
    }),
    computeNetImpactForBulkAdjustment: createDelayedFunction(() => {
      return [
        {
          bulkAdjustmentId: 'bulk_20231220233844450_243c2670',
          message: 'Initiated',
          isSuccess: true,
          userId: '73cb712c-d771-4dbb-882d-e3475edabbc7',
          planType: 0,
          beaconClientId: 162,
          retailerId: 1
        }
      ];
    })
  };
}
