import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { BrandIcon } from 'src/components/SvgIcons';
import ReduxStore from 'src/types/store/reduxStore';
import Truncate from 'react-truncate';
import Chip from '@mui/material/Chip';
import { getRetailerLogoUrl } from 'src/utils/image';
import colors from 'src/utils/colors';

const RetailerSideCard: React.FC<any> = () => {
  const [logoAvailable, setLogoAvailable] = useState(true);
  const { mainEntity } = useSelector((state: ReduxStore) => state.entityService);
  const app = useSelector((state: ReduxStore) => state.app);

  const { searchParams } = app.queryParams;

  return (
    <div className="nav-header-container">
      <div className="sl-image-container">
        <div className="sl-image-center">
          <NavLink style={{ textDecoration: 'none' }} to={`${searchParams}&tab=scorecard&subtab=all`}>
            {logoAvailable && (
              <div className="brand__image-container sl-image-container">
                <div className="brand__image-content sl-image-center">
                  <img
                    className="sl-image"
                    alt="logo"
                    src={getRetailerLogoUrl(String(mainEntity.id))}
                    onError={() => setLogoAvailable(false)}
                  />
                </div>
              </div>
            )}
            {!logoAvailable && (
              <BrandIcon
                style={{
                  stroke: colors.lightGrey,
                  strokeWidth: '7px',
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
              />
            )}
          </NavLink>
        </div>
      </div>
      <div className="nav-name-container" title={mainEntity.name}>
        <Truncate lines={2} ellipsis="...">
          <h4 className="nav-company-name">{mainEntity.name}</h4>
        </Truncate>
      </div>

      <Chip className="entity-chip" label="Retailer" />
    </div>
  );
};

export default RetailerSideCard;
