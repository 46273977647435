import React, { useState } from 'react';
import _get from 'lodash/get';

import NumberFormat from 'react-number-format';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { withBus } from 'react-bus';
import SubmitScreen from 'src/components/AdManager/SubmitScreen/SubmitScreen';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import { connect } from 'react-redux';
import Tabs from 'src/components/common/Tabs/Tabs';
import ReduxStore from 'src/types/store/reduxStore';
import { useOnce, useBus } from 'src/utils/Hooks';
import ExistingProducts from 'src/components/AdManager/ExistingProducts';
import AddProductsToCampaign from 'src/components/AdManager/AddProductsToCampaign';
import AddNewPillFilters from 'src/components/Search/AdvancedSearch/AdvancedSearchSideBar/PillFilters';
import { buildAdvancedSearchSidebarWidget as buildAddNewSideBar } from 'src/components/Layout/Advertising/AdCampaignBuilderPageLayout/AdCampaignBuilderPageLayout';
import { withProps } from 'src/utils/hoc';
import AdManagerSearchSideBar from 'src/components/AdManager/Search/SearchSideBar';
import { buildAdvancedSearchSideBarWidget as buildExistingSideBar } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';
import Popover from '@mui/material/Popover';
import { ChevronIcon } from 'src/components/SvgIcons';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { store } from 'src/main';
import { fetchEntity } from 'src/store/modules/entityService/operations';

const defaultBulkUpdate = (currencySymbol: string) => ({
  name: 'increaseMoney',
  value: 0,
  prefix: currencySymbol
});

const style = {
  tabStyle: { paddingBottom: 8, fontSize: 18 },
  button: {
    width: 99
  },
  textInput: {
    height: '32px',
    outline: 'none',
    border: 'none',
    borderRadius: '5px',
    width: '80px',
    padding: 10,
    fontSize: '14px',
    color: 'currentColor',
    background: '#eff1f5',
    fontFamily: 'Roboto'
  }
};

const bulkAdjustOpt = (currencySymbol: string) => [
  {
    name: 'increaseMoney',
    displayName: `Increase Bid By (${currencySymbol})`,
    prefix: currencySymbol
  },
  {
    name: 'increasePercent',
    displayName: 'Increase Bid By (%)',
    suffix: '%'
  },
  {
    name: 'setMoney',
    displayName: `Set Bid to (${currencySymbol})`,
    prefix: currencySymbol
  },
  {
    name: 'decreaseMoney',
    displayName: `Decrease Bid By (${currencySymbol})`,
    prefix: currencySymbol
  },
  {
    name: 'decreasePercent',
    displayName: 'Decrease Bid By (%)',
    suffix: '%'
  }
];

const BulkAdjustBid = ({ eventBus, retailer }: { eventBus: any; retailer: ReduxStore['retailer'] }) => {
  // const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [bulkUpdateObj, setBulkUpdateObj] = useState(defaultBulkUpdate(retailer.currencySymbol));
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const setBulkUpdate = ({ name, value, prefix, suffix }) => {
    const newBulkUpdateObj = {
      name,
      value,
      prefix,
      suffix
    };
    setBulkUpdateObj(newBulkUpdateObj);
  };

  const handleBulkValueChange = (floatValue) => {
    setBulkUpdate({
      ...bulkUpdateObj,
      value: floatValue
    });
  };

  const handleAdjustTypeChange = (name) => {
    const value = 0;
    const newBulkAdjust = bulkAdjustOpt(retailer.currencySymbol).find((item) => {
      return item.name === name;
    });
    setBulkUpdate({ ...newBulkAdjust, value });
  };

  const handleApplyClick = () => {
    eventBus.emit('bulkApplyProductBidPrice', { type: bulkUpdateObj.name, value: bulkUpdateObj.value });
  };

  const adjustType = bulkAdjustOpt(retailer.currencySymbol).find((item) => {
    return item.name === bulkUpdateObj.name;
  });

  return (
    <div className="bulk_bid_update">
      <LargeMuiButton
        style={{ width: 160, marginRight: 18 }}
        secondary
        label="BULK BID UPDATE"
        onClick={handlePopoverOpen}
      />
      <Popover
        open={open}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        style={{ marginTop: 10 }}
      >
        <div className="bulk_bid_update_content">
          <div className="content">
            <Select
              variant="standard"
              style={{ marginLeft: -30 }}
              renderValue={() => adjustType.displayName}
              value={adjustType.name}
              // The type given by Material UI disagrees with the type of the actual event.
              onChange={(evt) => handleAdjustTypeChange(evt.target.value as string)}
              input={<OutlinedInput labelWidth={0} id="outlined-age-simple" />}
              IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
            >
              {bulkAdjustOpt(retailer.currencySymbol).map((opt) => {
                return (
                  <MenuItem key={opt.name} value={opt.name}>
                    {opt.displayName}
                  </MenuItem>
                );
              })}
            </Select>
            <NumberFormat
              value={bulkUpdateObj.value}
              thousandSeparator
              prefix={bulkUpdateObj.prefix}
              suffix={bulkUpdateObj.suffix}
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              onValueChange={({ floatValue }) => {
                handleBulkValueChange(floatValue);
              }}
              // Mimic the style of the MUI `TextInput`
              style={{ ...style.textInput, width: '230px' }}
            />
          </div>
          <div className="button_group">
            <LargeMuiButton style={{ width: 230 }} label="PREVIEW BID PRICE CHANGES" onClick={handleApplyClick} />
          </div>
        </div>
      </Popover>
    </div>
  );
};

const AddNewSearchSidebar = buildAddNewSideBar('targetDetail')!.CustomComponent!;

const TargetingPage = ({
  eventBus,
  mainEntity,
  campaign,
  retailer
}: {
  eventBus: any;
  mainEntity: any;
  campaign: any;
  retailer: ReduxStore['retailer'];
}) => {
  const [adGroupId, setAdGroupId] = useState(undefined);
  //   const [adGroups, setAdGroups] = useState(); // Used as a way to determine whether or not ad groups have loaded
  const [activeTabIx, setActiveTabIx] = useState(0);
  const [submitStatus, setSubmitStatus] = useState({ status: 'normal' });
  const isAdGroup = mainEntity.type === 'adGroup';
  const isAutoTargeting = _get(campaign, ['extendedAttributes', 'amsApiModel', 'targetingType']) === 'auto';
  const isSba = _get(campaign, 'extendedAttributes.campaignType') === 'sba';
  // const [bulkUpdateObj, setBulkUpdateObj] = useState(defaultBulkUpdate)

  useBus(eventBus, 'targetPageButtonStatus', setSubmitStatus);

  useOnce(() => {
    const allAdGroups = _get(mainEntity, 'adGroups', []);
    // setAdGroups(allAdGroups);
    const newAdGroupId = _get(allAdGroups, [0, 'adGroupId'], undefined); // from dropdown
    const adGroupIdFromUrl = _get(mainEntity, 'adGroupId');

    if (adGroupIdFromUrl) {
      setAdGroupId(adGroupIdFromUrl);
    } else {
      setAdGroupId(newAdGroupId);
    }
  });

  useOnce(() => {
    if (isAdGroup) {
      store.dispatch(
        fetchEntity('campaignForAdGroup', 'adCampaign', mainEntity.campaignId, mainEntity.extendedAttributes.retailerId)
      );
    }
  });

  if (!mainEntity || !['adCampaign', 'adGroup'].includes(mainEntity.type)) {
    return <GenericChartLoading />;
  }

  const submitExisting = () => {
    eventBus.emit('submitExistingTarget');
  };

  const submitAddNewProduct = () => {
    eventBus.emit('submitAddNewFeaturedProducts');
  };

  const isSubmitting = submitStatus.status === 'submitting';

  if (submitStatus.status === 'submitted') {
    return (
      <div className="targeting_container">
        <SubmitScreen respondResult={submitStatus.result} />
      </div>
    );
  }

  const renderExistingButtons = () => {
    return (
      <div style={{ display: 'flex' }}>
        <BulkAdjustBid eventBus={eventBus} retailer={retailer} />
        <LargeMuiButton
          disabled={isSubmitting}
          style={style.button}
          label={isSubmitting ? 'Submitting' : 'Submit'}
          onClick={submitExisting}
        />
      </div>
    );
  };

  const renderAddNewButtons = () => {
    return (
      <LargeMuiButton
        disabled={isSubmitting}
        style={style.button}
        label={isSubmitting ? 'Submitting' : 'Submit'}
        onClick={submitAddNewProduct}
      />
    );
  };

  const renderAddNewFilter = () => {
    if (['2', '63', '25'].includes(retailer.id)) {
      return null;
    }
    return (
      <>
        <AddNewSearchSidebar />
        <AddNewPillFilters />
      </>
    );
  };

  const renderExistingFilter = () => {
    const advancedSearchSideBarWidget = buildExistingSideBar();
    advancedSearchSideBarWidget.data.useInternalState = true;
    advancedSearchSideBarWidget.data.defaultGroupByField = 'stacklineSku';
    const ExistingSearchSidebar = withProps({ widget: advancedSearchSideBarWidget })(AdManagerSearchSideBar);
    return (
      <>
        <ExistingSearchSidebar />
        <AddNewPillFilters />
      </>
    );
  };

  const handleTabChange = (newTabIx: number) => {
    setActiveTabIx(newTabIx);
  };

  const adGroupOpts = _get(mainEntity, ['adGroups'], []);

  const EXISTING_PRODUCTS = 'Update Existing Products';
  const NEW_PRODUCTS = 'Add New Products';
  let tabs = [{ displayName: NEW_PRODUCTS }];
  if (isAutoTargeting && (retailer.id === '2' || retailer.id === '25')) {
    tabs = [{ displayName: EXISTING_PRODUCTS }, { displayName: NEW_PRODUCTS }];
  }
  const renderTab = (tabIndex: number) => {
    if (tabs[tabIndex].displayName === EXISTING_PRODUCTS) {
      return <ExistingProducts adGroupId={adGroupId} />;
    } else {
      return <AddProductsToCampaign adGroupId={adGroupId} />;
    }
  };

  const isGroupPage = window.location.pathname.includes('adGroup');

  const headerText =
    tabs[activeTabIx].displayName === EXISTING_PRODUCTS
      ? `${isGroupPage ? 'Ad Group' : 'Campaign'} Products`
      : `Add Featured Products to ${isGroupPage ? 'ad group' : 'campaign'}`;
  const subHeaderTest =
    tabs[activeTabIx].displayName === EXISTING_PRODUCTS
      ? `Use tables below to update existing products or add new products to this ${
          isGroupPage ? 'ad group' : 'campaign'
        }.`
      : `Select additional products to advertise in this ${isGroupPage ? 'ad group' : 'campaign'}. ${
          isSba
            ? 'Maximum 10 enabled products are allowed in an SBA campaign'
            : 'Metrics below represent the last four weeks.'
        }`;

  return (
    <div className="recommendations_container">
      <div className="header">{headerText}</div>
      <div className="sub_header" style={{ marginBottom: 20 }}>
        {subHeaderTest}
      </div>

      {adGroupOpts.length > 1 && (
        <>
          <div className="sub_header" style={{ marginBottom: 20 }}>
            Please select an ad group from the dropdown below.
          </div>
          <Select
            variant="standard"
            value={adGroupId}
            onChange={({ target: { value } }) => setAdGroupId(value)}
            IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
            style={{ margin: 0 }}
          >
            {adGroupOpts.map((val) => (
              <MenuItem key={val.name} value={val.adGroupId}>
                {_get(val, ['extendedAttributes', 'adGroupName'], '')}
              </MenuItem>
            ))}
          </Select>
          <br /> <br />
        </>
      )}

      <div className="sub_header" style={{ marginBottom: 20 }}>
        <div className="filter_area">
          {tabs[activeTabIx].displayName === EXISTING_PRODUCTS ? renderExistingFilter() : renderAddNewFilter()}
        </div>
        <div className="button_area" style={{ marginTop: -83 }}>
          {tabs[activeTabIx].displayName === EXISTING_PRODUCTS ? renderExistingButtons() : renderAddNewButtons()}
        </div>
      </div>
      <>
        <Tabs
          tabStyle={style.tabStyle}
          tabs={tabs}
          value={activeTabIx}
          onTabChange={(_evt, newTabIx) => handleTabChange(newTabIx)}
        />
        {renderTab(activeTabIx)}
      </>
    </div>
  );
};

const mapStateToProps = (state: ReduxStore) => {
  const props = {
    mainEntity: state.entityService.mainEntity,
    campaign: state.entityService.mainEntity,
    retailer: state.retailer
  };

  if (props.mainEntity) {
    if (props.mainEntity.type === 'adGroup' && state.entityService.campaignForAdGroup) {
      props.campaign = state.entityService.campaignForAdGroup;
    }
  }

  return props;
};

export default connect(mapStateToProps)(withBus('eventBus')(TargetingPage));
