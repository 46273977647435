import React from 'react';
import { useMetricFormatter } from 'src/utils/Hooks';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import { getWeekColumnDefinition } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import { removeComparisonFormatter } from 'src/components/BeaconRedesignComponents/MetricList/utils';
import { Field } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy';
import { useMetricByWeekId } from 'src/serverProxy/useMetricByWeekId';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';
import { getWeekId } from 'src/utils/dateUtils';
import { safeDivide } from 'src/utils/app';
import { METRICTYPE } from 'src/utils/entityDefinitions/entityDefinitionTypes';

interface SalesDatum {
  weekId: string;
  name: string;
  retailSales: {
    value: number;
    difference: number;
  };
  unitsSold: {
    value: number;
    difference: number;
  };
  retailPrice: {
    value: number;
    difference: number;
  };
  responseRow: {
    unitsSold_sum_value: number;
    unitsSold_sum_count: number;
    retailSales_sum_value: number;
    retailSales_sum_count: number;
    retailPrice_computed_value: number;
    retailPrice_computed_count: number;
    metadata: {
      weekId: string;
      name: string;
    };
  };
}

type CompleteConversionDatum = SalesDatum & { conversionRate: string; totalClicks: string };

export const TrafficConversionByWeekTable = () => {
  const weekEndingFields: Field[] = [
    { name: 'retailSales', displayName: 'Retail\nSales' },
    { name: 'unitsSold', displayName: 'Units\nSold' },
    { name: 'retailPrice', displayName: 'Retail\nPrice' }
  ].map(removeComparisonFormatter);

  const formatMetric = useMetricFormatter();

  const { primaryData, isLoading } = useMetricByWeekId({
    indexName: BEACON_INDEX_NAMES.TRAFFIC,
    fieldName: 'totalClicks'
  });

  const totalClicksData =
    primaryData && !isLoading
      ? primaryData.map(([timeStamp, totalClicks]) => {
          const weekId = getWeekId(timeStamp);
          return { [`${weekId}`]: totalClicks };
        })
      : null;

  return (
    <MetricListContainer
      loading={isLoading}
      entityTableHeaderProps={{
        title: 'Conversion by Week'
      }}
      indexName="sales"
      sortFieldName="retailSales"
      fields={weekEndingFields}
      headColumnDefinition={[
        getWeekColumnDefinition({
          getTableColumnProps(defaults) {
            return {
              ...defaults,
              style: {
                ...defaults.style,
                width: '220px'
              }
            };
          }
        }),
        {
          headerName: 'Conversion\nRate',
          valueFormatter: (_, datum: CompleteConversionDatum) => <div>{datum.conversionRate}</div>
        },
        {
          headerName: 'Total\nTraffic',
          valueFormatter: (_, datum: CompleteConversionDatum) => <div>{datum.totalClicks}</div>
        }
      ]}
      postProcessData={(data: SalesDatum[]) => {
        if (totalClicksData) {
          const sortedData = data.sort((a, b) => b.weekId.localeCompare(a.weekId));

          const mergedData = sortedData.map((row) => {
            const { weekId, unitsSold } = row;
            const totalClicksByWeekId = totalClicksData.find((item) => item[weekId]);
            const totalClicks = totalClicksByWeekId ? totalClicksByWeekId[weekId] : 0;
            const conversionRate = formatMetric(safeDivide(unitsSold.value, totalClicks), METRICTYPE.PERCENT, {
              decimalPlaces: 1
            });

            return { ...row, conversionRate, totalClicks: formatMetric(totalClicks, METRICTYPE.VOLUME) };
          });
          return mergedData;
        } else {
          return [];
        }
      }}
      useTableDataProps={{
        groupByFieldName: 'weekId',
        filterResponse: () => true,
        itemsPerPage: 100
      }}
      showGrid={false}
    />
  );
};
