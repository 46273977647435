import React from 'react';
import { AdSpendInputTable } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkSubmissionReviewModal';
import { PaidTrafficNetImpactSummaryDetails } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/serverProxy/types';
import { useAdjustmentPaidTrafficMetrics } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAdjustmentPaidTrafficMetrics';
import { ParsedOriginalProjectionResponse } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import { ForecastsAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import _get from 'lodash/get';
import { calculateWeeksBetweenWeekIds } from 'src/utils/dateUtils';
import { safeDivide } from 'src/utils/app';

export const PaidTrafficSummaryMetrics = ({
  adjustmentData,
  parsedData
}: {
  adjustmentData: ForecastsAdjustmentData;
  parsedData: ParsedOriginalProjectionResponse;
}) => {
  const { data } = useAdjustmentPaidTrafficMetrics(adjustmentData, true);

  const numberOfWeeks = calculateWeeksBetweenWeekIds(
    _get(adjustmentData, 'startWeekId'),
    _get(adjustmentData, 'endWeekId')
  );

  // Join datasets
  const paidTrafficNetImpactSummaryDetails: PaidTrafficNetImpactSummaryDetails =
    data && parsedData
      ? {
          min_weekId: _get(adjustmentData, 'startWeekId'),
          max_weekId: _get(adjustmentData, 'endWeekId'),
          avg_unadjusted_forecast_adspend: 0,
          avg_adjusted_forecast_adspend: 0,
          avg_unadjusted_forecast_cpc: 0,
          avg_adjusted_forecast_cpc: 0,
          individual_new_avg_ad_spend: _get(data, 'ad_spend', 0),
          individual_new_avg_cpc: _get(data, 'cpc', 0),
          individual_new_avg_paid_traffic: _get(data, 'paid_traffic', 0),
          individual_new_avg_ad_sales: _get(data, 'ad_sales', 0),
          individual_new_avg_roas: _get(data, 'roas', 0),
          individual_current_avg_ad_spend: safeDivide(_get(parsedData, 'adSpend', 0), numberOfWeeks),
          individual_current_avg_cpc: _get(parsedData, 'costPerClick', 0),
          individual_current_avg_paid_traffic: safeDivide(
            _get(parsedData, 'paidTrafficValue_sum_value', 0),
            numberOfWeeks
          ),
          individual_current_avg_ad_sales: safeDivide(_get(parsedData, 'adSales', 0), numberOfWeeks),
          individual_current_avg_roas: _get(parsedData, 'returnOnAdSpend', 0)
        }
      : null;

  return (
    <Flex flexDirection="column" marginTop="24px" gap="sm" height="auto" marginBottom="32px">
      <Text variant="subtitle2">Advertising Summary</Text>
      <Text variant="body2">
        The table below displays how this plan change will impact key advertising metrics on a weekly basis.
      </Text>

      <AdSpendInputTable summaryDetails={paidTrafficNetImpactSummaryDetails} />
    </Flex>
  );
};
