import React, { useState } from 'react';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import { METRICTYPE } from 'src/utils/entityDefinitions';

/**
 * Displays the dropdown spline chart for chargebacks
 */
const ChargebacksSplineChart = () => {
  const [fieldName, setFieldName] = useState('financialCharge');

  return (
    <BeaconComparableSplineChart
      fieldName={fieldName}
      indexName="chargebacks"
      metricTypeDisplay={fieldName === 'issueId' ? METRICTYPE.DECIMAL : undefined}
      aggregationFunctionForTotal={fieldName === 'issueId' ? 'sum' : undefined}
      singleDropdownProps={{
        defaultLabel: '',
        options: [
          {
            id: 'financialCharge',
            label: 'Financial Charge'
          },
          {
            id: 'prepCharges',
            label: 'Prep Charges'
          },
          {
            id: 'logisticCharges',
            label: 'Logistic Charges'
          },
          {
            id: 'labelingCharges',
            label: 'Labeling Charges'
          },
          {
            id: 'chargebackRate',
            label: 'Chargeback Rate'
          },
          {
            id: 'stacklineSku',
            label: 'Products with Charges'
          },
          {
            id: 'issueId',
            label: 'Count of Charges'
          }
        ],
        selectedId: fieldName,
        onChange: (option) => setFieldName(option.id)
      }}
    />
  );
};

export default ChargebacksSplineChart;
