/* eslint-disable no-restricted-syntax */
export const dynamicFilterApiBodyInitial = {
  docType: 'priceAndAvailability',
  includeBrandIds: [],
  includeCategoryIds: [],
  retailerIds: [],
  productIds: [],
  includeSubCategoryIds: [],
  includeLocationRegionCode: [],
  includeLocationCountryCode: []
};

export const updateDynamicFilterApiBody = (entityType: string, entityId: string | number) => {
  const updatedBody = { ...dynamicFilterApiBodyInitial };

  switch (entityType) {
    case 'brand':
      updatedBody.includeBrandIds = [String(entityId)];
      break;
    case 'product':
      updatedBody.productIds = [String(entityId)];
      break;
    case 'retailer':
      updatedBody.retailerIds = [Number(entityId)];
      break;
    case 'category':
      updatedBody.includeCategoryIds = [Number(entityId)];
      break;
    case 'subcategory':
      updatedBody.includeSubCategoryIds = [Number(entityId)];
      break;
    default:
      break;
  }

  return updatedBody;
};

export const filterKeyMapping = {
  brand: 'b',
  category: 'c',
  retailer: 'r',
  subcategory: 'sc',
  segment: 'sg',
  keywordSegment: 'ks',
  keywordCategories: 'kc',
  region: 'rg',
  country: 'co',
  instacartRetailer: 'ir'
};

export const filterReduxKeyMapping = {
  b: 'brands',
  c: 'categories',
  r: 'retailers',
  sc: 'subCategories',
  sg: 'sg',
  ks: 'ks',
  kc: 'kc',
  rg: 'regions',
  co: 'countries',
  ir: 'instacartRetailers'
};
/**
 * Generate new filterParam, that remember current checked selection after parent changes, and uncheck its not available from its parents
 * @param filterInParsedFilterParam Value from filterParam react component state (not from the window.location) => we use this to track the current checkboxes
 * @param dynamicFiltersValue Returned value from based on API (availability) + spread object of all available options. (E.g) user clicks region "NA", Api will return just "NA", not "EU", "AS"
 * @returns if the current filter from the component (not window.location), is available in the dynamic filter value
 */
export const matchingInParsedFilterParamWithDynamicFilter = (filterInParsedFilterParam, dynamicFiltersValue) => {
  const filteredResult = {};

  for (const [paramKey, paramValue] of Object.entries(filterInParsedFilterParam)) {
    const dynamicFilterKey = filterReduxKeyMapping[paramKey];

    if (dynamicFilterKey && Array.isArray(paramValue)) {
      const matchingDynamicFilters =
        dynamicFiltersValue[dynamicFilterKey] &&
        dynamicFiltersValue[dynamicFilterKey].filter((dynamicFilterItem) => {
          return paramValue.some((paramItem) => {
            // retailerId is number from the API
            return (
              JSON.stringify(paramItem) ===
              JSON.stringify({
                i:
                  dynamicFilterItem.countryCode ||
                  dynamicFilterItem.regionCode ||
                  dynamicFilterItem.brandId ||
                  dynamicFilterItem.categoryId ||
                  dynamicFilterItem.subCategoryId ||
                  dynamicFilterItem.instacartRetailerId ||
                  String(dynamicFilterItem.retailerId)
              })
            );
          });
        });
      if (matchingDynamicFilters && matchingDynamicFilters.length > 0) {
        filteredResult[paramKey] = matchingDynamicFilters.map((dynamicFilterItem) => {
          return {
            // retailerId is number from the API
            i:
              dynamicFilterItem.countryCode ||
              dynamicFilterItem.regionCode ||
              dynamicFilterItem.brandId ||
              dynamicFilterItem.categoryId ||
              dynamicFilterItem.subCategoryId ||
              dynamicFilterItem.instacartRetailerId ||
              String(dynamicFilterItem.retailerId)
          };
        });
      }
    }
  }

  return filteredResult;
};
