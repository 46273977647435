import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { buildWidgetGroup, buildTrendWidgetConfig } from 'src/components/Layout/LayoutUtil';
import { EnhancedDonutChartContainer } from 'src/components/Charts/Donut';
import { Widget as WidgetType } from 'src/types/application/widgetTypes';
import VIEWS from '../../ViewDefaultConfig';
import { MetricField } from 'src/types/application/types';
import ReduxStore from 'src/types/store/reduxStore';

interface ChargeBackSummaryTypes {
  app: ReduxStore['app'];
  entity: { type: string };
  weekIdField: MetricField;
  indexName: string;
  dataConfig: any;
  metricType: string;
}

export const buildChargeBacksSummaryWidget = ({
  app,
  entity,
  weekIdField,
  indexName,
  dataConfig,
  metricType
}: ChargeBackSummaryTypes): WidgetType => {
  const mainFields = ['prepCharges', 'logisticCharges', 'labelingCharges'].map((fieldName) =>
    INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type, dataConfig.retailerIdField.name)
  );

  const widget = buildWidgetGroup(
    [
      {
        CustomComponent: EnhancedDonutChartContainer,
        name: 'Chargebacks Metrics',
        view: {
          ...VIEWS.donutChart,
          name: 'ChargeBackMetricsDonutChart',
          chartPropsOverride: {
            chart: {
              type: 'pie',
              height: 350
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false
                }
              }
            },
            tooltip: {
              enabled: true
            },
            disablePointSelect: true,
            enableMultiSelect: false,
            showFullValue: false
          },
          customHeader: {
            enabled: true,
            title: 'Chargeback Metrics'
          },
          container: {
            style: {
              width: '500px',
              height: '440px'
            }
          }
        },
        data: {
          indexName,
          mainMetric: mainFields[0],
          secondaryMetric: { ...mainFields, displayName: 'Chargeback Metrics' },
          aggregationFields: mainFields,
          groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.retailerIdField.name, entity.type)
        }
      },
      buildTrendWidgetConfig(app, indexName, entity, 'weekId', [metricType], weekIdField, {
        view: {
          chartPropsOverride: {
            title: {
              text: ''
            },
            subtitle: {
              text: '',
              style: {
                'border-bottom': 'none'
              }
            },
            chart: {
              height: 450,
              marginTop: 100
            },
            yAxis: [
              {
                visible: false
              }
            ]
          },
          container: { style: { width: '60%', display: 'inline-block', marginBottom: '100px' } }
        }
      })
    ],
    {
      view: {
        container: {
          style: {
            height: '630px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around'
          }
        }
      }
    }
  );

  return widget;
};
