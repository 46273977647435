import React, { memo } from 'react';
import { withBus } from 'react-bus';
import { Field } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import ProductListContainer from 'src/components/BeaconRedesignComponents/MetricList/ProductListContainer';
import { KeywordColumnDefinition } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import StatusPill from 'src/components/BeaconRedesignComponents/StatusPill/StatusPill';
import { EventBus } from 'src/types/utils';
import useKeywordSearchRequestBuilder from './KeywordsSearch/useKeywordSearchRequestBuilder';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import SegmentNoDataPlaceholder from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Segments/SegmentNoDataPlaceholder';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

const segmentFields: Field[] = [
  {
    name: 'retailSales'
  },
  {
    name: 'unitsSold'
  },
  {
    name: 'retailPrice'
  }
];

const topicFields: Field[] = [
  {
    name: 'organicClicks',
    comparisonFormatter() {
      return null;
    }
  },
  {
    name: 'adClicks',
    comparisonFormatter() {
      return null;
    }
  },
  {
    name: 'totalClicks',
    comparisonFormatter() {
      return null;
    }
  },
  {
    name: 'adSpend',
    comparisonFormatter() {
      return null;
    }
  }
];

export const QUICK_EXCLUDE_EVENT = 'builderQuickExcludeEvent';

export default withBus('eventBus')(
  memo(function SegmentBuilder({ eventBus, topics }: { eventBus: EventBus; topics: boolean }) {
    const indexName = topics ? 'traffic' : 'sales';
    const fields = topics ? topicFields : segmentFields;
    const sortFieldName = topics ? 'totalClicks' : 'retailSales';
    const keywordSearchRequestBuilder = useKeywordSearchRequestBuilder(indexName);

    // Handles quick exclude button click
    const handleExcludeButtonClick = (term: string) => {
      eventBus.emit(QUICK_EXCLUDE_EVENT, term);
    };

    /**
     * Adds quick exclude column to the keywords/topics table
     */
    const additionalColumnDefs = [
      {
        headerName: 'Exclude',
        valueFormatter: (_, row) => {
          const { searchTerm } = row;
          return (
            <button
              style={{ border: 'none', background: 'none', padding: 0 }}
              onClick={() => handleExcludeButtonClick(searchTerm)}
            >
              <StatusPill color="secondary" backgroundColor="secondaryPorcelain" opacity={1}>
                Exclude
              </StatusPill>
            </button>
          );
        }
      }
    ];

    return !topics ? (
      <BeaconPageContainer sx={{ marginTop: '0px' }}>
        <ProductListContainer
          productGridProps={{
            showExcludeProductButton: true,
            handleExcludeProduct: handleExcludeButtonClick,
            noDataPlaceholder: (
              <Flex justifyContent="center">
                <SegmentNoDataPlaceholder
                  title="No results found"
                  subtitle="Please refine your filters and try again."
                  maxWidth={150}
                />
              </Flex>
            )
          }}
          fields={fields}
          showHeader={false}
          showGrid={!topics}
          indexName={indexName}
          sortFieldName={sortFieldName}
          useTableDataProps={{
            buildRequest: keywordSearchRequestBuilder()
          }}
        />
      </BeaconPageContainer>
    ) : (
      <BeaconPageContainer sx={{ marginTop: '20px', marginBottom: `${BEACON_CHART_SPACING}px` }}>
        <MetricListContainer
          additionalColumnDefs={additionalColumnDefs}
          loading={false}
          headColumnDefinition={KeywordColumnDefinition}
          fields={fields}
          showHeader={false}
          showGrid={!topics}
          indexName={indexName}
          sortFieldName={sortFieldName}
          groupBy="searchTerm"
          skeletonRows={20}
          useTableDataProps={{
            buildRequest: keywordSearchRequestBuilder()
          }}
        />
      </BeaconPageContainer>
    );
  })
);
