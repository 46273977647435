import types from './types';

// ------------------------------------
// Action Creators - they simply return an action
// An action being a payload of information that send data from your application to your store.
// ------------------------------------

function requestSubcategories() {
  return {
    type: types.REQUEST_SUB_CATEGORIES
  };
}

function receiveSubcategories(categories) {
  return {
    type: types.RECEIVE_SUB_CATEGORIES,
    categories,
    receivedAt: Date.now()
  };
}

function receiveAllSuperUserSubcategories(categories) {
  return {
    type: types.RECEIVE_SUPER_USER_SUB_CATEGORIES,
    categories,
    receivedAt: Date.now()
  };
}

function clearSubcategories() {
  return {
    type: types.CLEAR_SUB_CATEGORIES
  };
}

const sortSubCategories = (sorter, direction = 'desc') => ({ type: types.SORT_SUB_CATEGORIES, sorter, direction });

export default {
  clearSubcategories,
  requestSubcategories,
  receiveSubcategories,
  receiveAllSuperUserSubcategories,
  sortSubCategories
};
