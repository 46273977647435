import React, { useCallback, useMemo, useState } from 'react';
import { useTableData } from 'src/serverProxy/useTableData';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import ProductCell from 'src/components/BeaconRedesignComponents/ProductCell/ProductCell';
import { ProductEntity } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/types';
import MetricCell from 'src/components/BeaconRedesignComponents/MetricCell/MetricCell';
import { INDEX_FIELDS, METRICTYPE } from 'src/utils/entityDefinitions';
import { calculatePercentChange, getAppName, safeDivide } from 'src/utils/app';
import { useAppSelector } from 'src/utils/Hooks';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import EntityTableHeader from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';
import ProductGrid, { ProductGridDatum } from 'src/components/BeaconRedesignComponents/ProductGrid/ProductGrid';
import Pagination from 'src/components/BeaconRedesignComponents/Pagination/Pagination';
import _get from 'lodash/get';
import { shouldHidePagination } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/utils';

const PAGE_SIZE = 20;

const METRICS = [
  ['purchaseRate', 'sales'],
  ['retailSales', 'sales'],
  ['unitsSold', 'sales'],
  ['totalClicks', 'traffic'],
  ['retailPrice', 'sales']
];

/**
 * Conversion rate grid in the traffic conversion page
 */
export default function TrafficConversionRateGrid() {
  const [page, setPage] = useState(1);
  const [viewMode, setViewMode] = useState<'tile' | 'table'>('tile');
  const { startWeek: comparisonStartWeek, endWeek: comparisonEndWeek } = useAppSelector(
    (state) => state.comparisonTimePeriod
  );

  const getKey = useCallback(
    (indexName: string, fieldName: string, comparison?: boolean) => {
      const field = INDEX_FIELDS.getField(getAppName(), indexName, fieldName);
      const fieldPrefix = `${fieldName}_${field.aggregationFunctionType || field.aggregationFunction}_value`;

      return comparison ? `${fieldPrefix}_${comparisonStartWeek}_${comparisonEndWeek}` : fieldPrefix;
    },
    [comparisonEndWeek, comparisonStartWeek]
  );

  const { data: salesData, isLoading: salesLoading } = useTableData({
    indexName: 'sales',
    fields: [
      {
        name: 'retailSales'
      },
      {
        name: 'unitsSold'
      },
      {
        name: 'retailPrice'
      }
    ],
    groupByFieldName: 'stacklineSku',
    itemsPerPage: PAGE_SIZE,
    pageNumber: page,
    requestId: 'traffic-conversion-rate-grid'
  });

  /**
   * Returned SKUs so that we can query traffic data for each SKU
   */
  const skus = salesData.map((row: { metadata: { product: ProductEntity } }) => row.metadata.product.stacklineSku);

  const { data: trafficData, isLoading: trafficLoading } = useTableData({
    indexName: 'traffic',
    fields: [
      {
        name: 'totalClicks'
      }
    ],
    groupByFieldName: 'stacklineSku',
    itemsPerPage: PAGE_SIZE,
    pageNumber: 1,
    requestId: 'traffic-conversion-rate-grid-traffic',
    useGenericAdvancedSearchArgs: {
      shouldPerformFetch: !salesLoading
    },
    buildRequest: (builder) => {
      return builder.addConditionTermFilter('stacklineSku', 'should', skus);
    }
  });

  /**
   * Combines sales and traffic data and computes conversion rate
   */
  const conversionRows = useMemo(() => {
    const stacklineSkuToTrafficData = trafficData.reduce((acc, row: { metadata: { product: ProductEntity } }) => {
      acc[row.metadata.product.stacklineSku] = row;
      return acc;
    }, {});

    const rows = salesData.map((row: { metadata: { product: ProductEntity } }) => {
      const trafficRow = stacklineSkuToTrafficData[row.metadata.product.stacklineSku];
      const totalClicks = _get(trafficRow, getKey('traffic', 'totalClicks'), 0);
      const totalClicksComparison = _get(trafficRow, getKey('traffic', 'totalClicks', true), 0);

      const productGridDatum: ProductGridDatum = {
        product: row.metadata.product,
        ...['retailSales', 'unitsSold', 'retailPrice'].reduce((acc, fieldName) => {
          const key = getKey('sales', fieldName);
          const comparisonKey = getKey('sales', fieldName, true);

          const metric = _get(row, key, 0);
          const comparison = _get(row, comparisonKey, 0);

          return {
            ...acc,
            [fieldName]: {
              value: metric,
              difference: metric - comparison
            }
          };
        }, {}),
        totalClicks: {
          value: totalClicks,
          difference: totalClicks - totalClicksComparison
        }
      };

      const unitsSold = _get(productGridDatum, ['unitsSold', 'value'], 0);
      const unitsSoldComparison = unitsSold - _get(productGridDatum, ['unitsSold', 'difference'], 0);

      const purchaseRate = safeDivide(unitsSold, totalClicks);
      const purchaseRateComparison = safeDivide(unitsSoldComparison, totalClicksComparison);

      return {
        ...productGridDatum,
        purchaseRate: {
          value: purchaseRate,
          difference: purchaseRate - purchaseRateComparison
        }
      };
    });

    return rows;
  }, [getKey, salesData, trafficData]);

  const handleNextPage = () => {
    if (conversionRows.length < PAGE_SIZE) {
      return;
    }
    setPage(page + 1);
  };

  const handlePreviousPage = () => {
    setPage(Math.max(page - 1, 1));
  };

  return (
    <Flex gap="lg" flexDirection="column">
      <EntityTableHeader
        enableSwitchingLayouts
        defaultView={viewMode}
        handleChangeLayout={() => setViewMode(viewMode === 'table' ? 'tile' : 'table')}
        paddingTop="0px"
        paddingBottom="0px"
      />
      <Flex flexDirection="column" gap={viewMode === 'table' ? 0 : 20}>
        {viewMode === 'table' ? (
          <EntityTable
            skeletonRows={PAGE_SIZE}
            isLoading={salesLoading || trafficLoading}
            showPagination={false}
            shouldModifyColumnDefs={false}
            columnDefs={[
              {
                headerName: 'Products',
                valueFormatter: (_, row: ProductGridDatum) => (
                  <div style={{ width: '300px' }}>
                    <ProductCell
                      key={row.product.stacklineSku}
                      stacklineSku={row.product.stacklineSku}
                      title={row.product.title}
                    />
                  </div>
                )
              },
              ...METRICS.map(([fieldName, indexName]) => {
                const field = INDEX_FIELDS.getField(getAppName(), indexName, fieldName);

                return {
                  headerName: field.displayName,
                  valueFormatter: (_, row: ProductGridDatum) => {
                    const metricValue = _get(row, [fieldName, 'value'], 0);
                    const comparisonValue = metricValue - _get(row, [fieldName, 'difference'], 0);
                    const percentChange = calculatePercentChange(metricValue, comparisonValue);

                    return (
                      <MetricCell
                        metricType={field.metricType}
                        value={metricValue}
                        percentChange={fieldName === 'totalClicks' ? undefined : percentChange}
                      />
                    );
                  }
                };
              })
            ]}
            rowData={conversionRows}
          />
        ) : (
          <ProductGrid
            data={conversionRows}
            loading={salesLoading || trafficLoading}
            metricType={METRICTYPE.PERCENT}
            fieldName="purchaseRate"
            page={1}
          />
        )}
        {shouldHidePagination({ page, pageSize: PAGE_SIZE, rowCount: (conversionRows || []).length }) ? null : (
          <Flex justifyContent="flex-end">
            <Pagination
              currentPage={page}
              shouldUseInfinitePagination
              onClickNext={handleNextPage}
              onClickPrevious={handlePreviousPage}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
