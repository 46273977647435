import _get from 'lodash/get';

export interface SplineRatingResponse {
  data: {
    aggregations: {
      by_weekId: {
        additionalValues: {
          stars_stats_avg: number;
          stars_stats_count: number;
          stars_stats_max: number;
          stars_stats_min: number;
          stars_stats_sum: number;
        };
      }[];
    };
  }[];
}

interface SumValues {
  sumCounts: number;
  sumValues: number;
}

/**
 * Calculates the weighted average rating over a period of weeks instead of taking the unweighted average
 * @returns number: weighted average rating
 */
export const calculateWeightedRatingForLegend = (response: SplineRatingResponse, dataIndex = 0) => {
  const valuesByWeek = _get(response, ['data', dataIndex, 'aggregations', 'by_weekId'], []);

  const { sumValues, sumCounts } = valuesByWeek.reduce(
    (acc: SumValues, { additionalValues }) => {
      acc.sumCounts += additionalValues.stars_stats_count;
      acc.sumValues += additionalValues.stars_stats_sum;
      return acc;
    },
    {
      sumCounts: 0,
      sumValues: 0
    }
  );

  return sumValues / (sumCounts > 0 ? sumCounts : 1);
};
