import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'qs';
import _get from 'lodash/get';

import { getStrategyDisplayInfo } from 'src/components/AdManager/AmsUtils';
import colors from 'src/utils/colors';
import 'src/components/Grids/Data/ColumnTypes/Entity.scss';
import ReduxStore from 'src/types/store/reduxStore';
import { STACKLINE_UNASSIGNED } from 'src/utils/constants';

const styles: { [key: string]: React.CSSProperties } = {
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  textLink: {
    fontSize: 14,
    whiteSpace: 'pre-wrap',
    lineHeight: '1.5em',
    maxHeight: '3em',
    overflow: 'hidden'
  },
  imageWrapper: {
    alignSelf: 'center',
    minWidth: 45,
    textAlign: 'center',
    marginRight: 10
  },
  brandIcon: {
    stroke: colors.lightGrey,
    strokeWidth: 13,
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  imageWrapperInner: {
    width: 45,
    display: 'table'
  },
  imageWrapperInnerInner: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  image: {
    maxWidth: 35,
    maxHeight: 35
  },
  auto: {
    border: `1px solid ${colors.orange}`,
    borderRadius: '4px',
    padding: '0 4px',
    color: colors.orange
  }
};

const mapStateToProps = ({ app, adCampaigns, adPortfolios }: ReduxStore) => ({ app, adCampaigns, adPortfolios });

type EntityColumnProps = {
  data?: { [key: string]: any };
  url?: string;
  style?: React.CSSProperties;
  value?: { [key: string]: any };
} & ReturnType<typeof mapStateToProps>;

interface EntityColumnState {
  value?: EntityColumnProps['value'];
  imageUri?: string | null;
  didImageLoadError?: boolean;
}

class PortfolioColumn extends React.Component<EntityColumnProps, EntityColumnState> {
  public static defaultProps = {
    data: {},
    value: {},
    style: {},
    url: undefined,
    target: ''
  };

  public static propTypes = {
    data: (props: EntityColumnProps, _propName: string, componentName: string) => {
      if (!props.data && !props.value) {
        return new Error(`One of props 'data' or 'url' was not specified in '${componentName}'.`);
      }
      return null;
    },
    value: (props: EntityColumnProps, _propName: string, componentName: string) => {
      if (!props.data && !props.value) {
        return new Error(`One of props 'url' or 'data' was not specified in '${componentName}'.`);
      }
      return null;
    },
    style: PropTypes.object,
    app: PropTypes.object.isRequired,
    url: PropTypes.string,
    target: PropTypes.string
  };

  public state: EntityColumnState = {};

  public componentWillMount() {}

  private stopPropagation = (evt: { stopPropagation: Function }) => evt.stopPropagation();

  private showEntityData() {
    const {
      app: {
        queryParams: { searchParams, additionalParams }
      },
      data
    } = this.props;
    const parsedAdditionalParameters = queryString.parse(additionalParams);

    parsedAdditionalParameters.tab = 'adManager';

    parsedAdditionalParameters.subtab = 'keyMetrics';

    const { entity } = data || { entity: { name: '' } };

    if (!entity.type) {
      return (
        <div style={styles.textLink} className={entity.isBold ? 'bold' : undefined}>
          {entity.name}
        </div>
      );
    }

    const textLinkType = entity.type;
    const entityId = entity.id;
    const link = `/${textLinkType}/${entityId}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`;

    if (entity) {
      const { displayName: statusName, color: statusColor } = _get(entity, 'extendedAttributes.statusDisplay', {
        displayName: '',
        color: ''
      });
      const { displayName: strategyName } = getStrategyDisplayInfo(entity.extendedAttributes);
      return (
        <Link
          style={{ display: 'block', height: '100%', textDecoration: 'none' }}
          onClick={this.stopPropagation}
          to={link}
          target={this.props.target}
        >
          <div className="campaign_column" title={(entity || { name: '' }).name}>
            {entity.name === 'Unassigned' ? (
              <>
                {STACKLINE_UNASSIGNED.portfolioDisplayName} <span style={styles.auto}>Unassigned</span>
              </>
            ) : (
              (entity.extendedAttributes || { name: '' }).name
            )}
            <div>{strategyName}</div>
            <div style={{ color: statusColor }}>{statusName}</div>
          </div>
        </Link>
      );
    }

    return null;
  }

  public render() {
    return (
      <div style={{ display: 'flex', ...this.props.style }}>
        <div style={styles.text}>{this.showEntityData()}</div>
      </div>
    );
  }
}

const EnhancedPortfolioColumn = connect(mapStateToProps)(PortfolioColumn);

export default EnhancedPortfolioColumn;
