import { useCallback } from 'react';
import { useAppSelector } from 'src/utils/Hooks/typedHooks';
import { getAppName } from 'src/utils/app';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';

/**
 * Advanced search responses will contain response keys that are of the format
 * {fieldName}_{aggregationFunction}_value, or {fieldName}_{aggregationFunction}_value_{compareStartWeekId}_{compareEndWeekId}
 * for comparison values.
 *
 * This makes it easier to get these kinds of keys.
 */
export default function useGetAdvancedSearchFieldKey() {
  const { startWeek: compareStartWeek, endWeek: compareEndWeek } = useAppSelector(
    (state) => state.comparisonTimePeriod
  );

  return useCallback(
    (indexName: string, fieldName: string, comparison?: boolean) => {
      const field = INDEX_FIELDS.getField(getAppName(), indexName, fieldName);

      return `${field.name}_${field.aggregationFunction}_value${
        comparison ? `_${compareStartWeek}_${compareEndWeek}` : ''
      }`;
    },
    [compareEndWeek, compareStartWeek]
  );
}
