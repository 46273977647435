import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useStacklineTheme } from '@stackline/ui';
import { ValueOf } from 'sl-api-connector';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { useMetricFormatter } from 'src/utils/Hooks';
import HoverTooltip from 'src/components/BeaconRedesignComponents/HoverTooltip/HoverTooltip';
import ReactDOMServer from 'react-dom/server';
import { timestampToWeekId } from 'src/utils/dateUtils';
import _merge from 'lodash/merge';

interface ChangeBarChartProps {
  /**
   * Double array where first element is a timestamp for the x value
   * and the second is the y value
   */
  data: number[][];

  metricType: ValueOf<METRICTYPE>;

  preview?: boolean;

  width?: number | string;
}

/**
 * A bar chart that shows green bars for positive values and
 * orange ones for negative values
 */
const ChangeBarChart = ({ data, metricType, preview, width = 181 }: ChangeBarChartProps) => {
  const theme = useStacklineTheme();
  const formatMetric = useMetricFormatter();

  const textStyles: Highcharts.CSSObject = useMemo(
    () => ({
      fontFamily: theme.text.fontFamily,
      color: theme.colors.primary,
      fontSize: '12px'
    }),
    [theme]
  );

  const previewOptions: Partial<Highcharts.Options> = useMemo(
    () => ({
      chart: {
        height: 50,
        width,
        spacing: [0, 0, 0, 0],
        margin: [0, 0, 0, 0],
        spacingLeft: 0
      },
      xAxis: {
        visible: false,
        height: 0
      },
      yAxis: {
        visible: false,
        min: Math.min.apply(
          null,
          data.map(([, y]) => y)
        ),
        max: Math.max.apply(
          null,
          data.map(([, y]) => y)
        )
      }
    }),
    [data]
  );

  const options: Highcharts.Options = useMemo(
    () =>
      _merge(
        {
          chart: {
            type: 'column',
            backgroundColor: 'transparent',
            spacingLeft: 0
          },
          title: {
            text: '',
            style: {
              height: 0
            }
          },
          exporting: {
            enabled: false
          },
          credits: {
            enabled: false
          },
          legend: {
            enabled: false
          },
          tooltip: {
            enabled: true,
            shadow: false,
            outside: true,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            useHTML: true,
            shared: true,
            formatter() {
              const [{ y, x, color }] = this.points;
              return ReactDOMServer.renderToStaticMarkup(
                <HoverTooltip
                  theme={theme}
                  title={`Week ${timestampToWeekId(x) % 100}`}
                  primaryColor={color as string}
                  primaryMetric={`${y < 0 ? '-' : ''}${formatMetric(y, METRICTYPE.VOLUME, {
                    decimalPlaces: 2,
                    showFullValue: false
                  })}`}
                />
              );
            }
          },
          xAxis: {
            tickLength: 0,
            lineColor: 'transparent',
            labels: {
              style: textStyles,
              rotation: 0,
              formatter() {
                return Highcharts.dateFormat('%b %e', this.value);
              }
            }
          },
          yAxis: {
            allowDecimals: false,
            gridLineWidth: 0,
            title: {
              text: ''
            },
            labels: {
              x: -28,
              formatter() {
                return `${this.value < 0 ? '-' : ''}${formatMetric(this.value, metricType, {
                  decimalPlaces: 2,
                  showFullValue: false
                })}`;
              },
              style: textStyles
            }
          },
          series: [
            {
              data: data.map(([x, y]) => ({
                x,
                y,
                color: y >= 0 ? theme.colors.success : theme.colors.accentTangerine
              })),
              type: 'column',
              maxPointWidth: preview ? 2 : 16
            }
          ],
          plotOptions: {
            column: {
              borderRadius: 1
            }
          }
        },
        preview ? previewOptions : {}
      ),
    [data, formatMetric, metricType, preview, previewOptions, textStyles, theme]
  );

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ChangeBarChart;
