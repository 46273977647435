import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import queryString from 'qs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';

import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import _pick from 'lodash/pick';
import _get from 'lodash/get';

import colors from 'src/utils/colors';
import { entityOperations } from 'src/store/modules/entityService';
import { ChevronIcon } from '../../SvgIcons';
import Group from './Group';
import './Compare.scss';
import { store } from 'src/main';
import { AppName } from 'sl-api-connector';
import TabPanel from 'src/components/common/Tabs/TabPanel';

const style = {
  compareHeader: {
    padding: '20px 30px 10px 30px',
    color: colors.labelGrey,
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'uppercase'
  }
};

const COMPARISON_TYPE = {
  METRIC: 'METRIC',
  RETAILER: 'RETAILER',
  PREVIOUS_YEAR: 'PREVIOUS_YEAR',
  REVIEWS: 'REVIEWS'
};

const buildSingleCompareConfig = ({ name, displayName, index, field, isHeader }) => {
  if (isHeader) {
    return { displayName, isHeader, name };
  }
  return {
    name,
    displayName,
    comparisonLinkParams: `ctab=${index}&csubtab=${field}`
  };
};

const getConfigParams = (appName) => {
  const { app, retailer, user } = store.getState();
  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);
  const params = {
    atlas: [
      { name: 'trafficTitle', displayName: 'Traffic', index: 'traffic', isHeader: true },
      { name: 'totalClicks', displayName: 'Total Traffic', index: 'traffic', field: 'totalClicks' },
      { name: 'organicClicks', displayName: 'Organic Traffic', index: 'traffic', field: 'organicClicks' },
      { name: 'adClicks', displayName: 'Paid Traffic', index: 'traffic', field: 'adClicks' },
      { name: 'adSpend', displayName: 'Paid Traffic spend', index: 'traffic', field: 'adSpend' },
      { name: 'conversionTitle', displayName: 'Conversion', isHeader: true },
      { name: 'purchaseRate', displayName: 'Conversion Rate', index: 'sales', field: 'purchaseRate' },
      { name: 'retailPrice', displayName: 'Retail Price', index: 'sales', field: 'retailPrice' },
      { name: 'contentScore', displayName: 'Content Score', index: 'sales', field: 'contentScore' },
      { name: 'reviewsRating', displayName: 'Rating', index: 'sales', field: 'reviewsRating' },
      { name: 'salesTitle', displayName: 'Sales', isHeader: true },
      { name: 'retailSales', displayName: 'Retail Sales', index: 'sales', field: 'retailSales' },
      { name: 'unitsSold', displayName: 'Units Sold', index: 'sales', field: 'unitsSold' }
    ],
    beacon: [
      { name: 'trafficTitle', displayName: 'Traffic', isHeader: true },
      { name: 'totalClicks', displayName: 'Total Traffic', index: 'traffic', field: 'totalClicks' },
      { name: 'organicClicks', displayName: 'Organic Traffic', index: 'traffic', field: 'organicClicks' },
      { name: 'adClicks', displayName: 'Ad Clicks', index: 'advertising', field: 'clicks' },
      { name: 'adSpend', displayName: 'Ad Spend', index: 'advertising', field: 'spend' },
      { name: 'adSales', displayName: 'Ad Sales', index: 'advertising', field: 'sales' },
      { name: 'adUnitsSold', displayName: 'Ad Units Sold', index: 'advertising', field: 'unitsSold' },
      { name: 'impressions', displayName: 'Ad Impressions', index: 'advertising', field: 'impressions' },
      { name: 'clickThroughRate', displayName: 'Ad CTR %', index: 'advertising', field: 'clickThroughRate' },
      { name: 'costPerClick', displayName: 'Ad CPC', index: 'advertising', field: 'costPerClick' },
      { name: 'costPerMilli', displayName: 'Ad CPM', index: 'advertising', field: 'costPerMilli' },
      { name: 'costPerAcquisition', displayName: 'Ad CPA', index: 'advertising', field: 'costPerAcquisition' },
      { name: 'conversionRate', displayName: 'Ad Conversion Rate', index: 'advertising', field: 'conversionRate' },
      { name: 'returnOnAdSpend', displayName: 'Ad ROAS', index: 'advertising', field: 'returnOnAdSpend' },
      { name: 'conversionTitle', displayName: 'Conversion', isHeader: true },
      { name: 'purchaseRate', displayName: 'Conversion Rate', index: 'sales', field: 'purchaseRate' },
      { name: 'retailPrice', displayName: 'Retail Price', index: 'sales', field: 'retailPrice' },
      { name: 'instockRate', displayName: 'In-Stock Rate', index: 'sales', field: 'instockRate' },
      { name: 'unitsOnHand', displayName: 'Units on Hand', index: 'sales', field: 'unitsOnHand' },
      { name: 'inventoryWeeksOnHand', displayName: 'Weeks on Hand', index: 'sales', field: 'inventoryWeeksOnHand' },
      {
        name: 'inventoryWeeksOnHandGrid',
        displayName: 'Weeks on Hand',
        index: 'sales',
        field: 'inventoryWeeksOnHandGrid'
      },
      { name: 'inventoryRetailValue', displayName: 'On Hand - Retail', index: 'sales', field: 'inventoryRetailValue' },
      { name: 'winPercentage', displayName: 'Buy Box - Rate', index: 'buybox', field: 'winPercentage' },
      { name: 'salesTitle', displayName: 'Sales', isHeader: true },
      { name: 'retailSales', displayName: 'Retail Sales', index: 'sales', field: 'retailSales' },
      { name: 'wholesaleSales', displayName: 'Wholesale Sales', index: 'sales', field: 'wholesaleSales' },
      { name: 'unitsSold', displayName: 'Units Sold', index: 'sales', field: 'unitsSold' },
      { name: 'retailerGrossMargin', displayName: 'Retailer Margin', index: 'sales', field: 'retailerGrossMargin' },
      {
        name: 'retailerGrossMarginPercent',
        displayName: 'Retailer Margin (%)',
        index: 'sales',
        field: 'retailerGrossMarginPercent'
      },
      { name: 'returnRate', displayName: 'Return Rate (%)', index: 'sales', field: 'returnRate' },
      { name: 'unitsReturned', displayName: 'Units Returned', index: 'sales', field: 'unitsReturned' }
    ],
    advertising: [
      {
        name: 'impressions',
        displayName: 'Ad Impression',
        index: 'adCampaignAdGroupProductTargetDailyMetrics',
        field: 'impressions'
      },
      {
        name: 'clicks',
        displayName: 'Ad Clicks',
        index: 'adCampaignAdGroupProductTargetDailyMetrics',
        field: 'clicks'
      },
      {
        name: 'clickThroughRate',
        displayName: 'CTR %',
        index: 'adCampaignAdGroupProductTargetDailyMetrics',
        field: 'clickThroughRate'
      },
      { name: 'spend', displayName: 'Ad Spend', index: 'adCampaignAdGroupProductTargetDailyMetrics', field: 'spend' },
      {
        name: 'costPerClick',
        displayName: 'CPC',
        index: 'adCampaignAdGroupProductTargetDailyMetrics',
        field: 'costPerClick'
      },
      {
        name: 'costPerAcquisition',
        displayName: 'CPA',
        index: 'adCampaignAdGroupProductTargetDailyMetrics',
        field: 'costPerAcquisition'
      },
      { name: 'sales', displayName: 'Ad Sales', index: 'adCampaignAdGroupProductTargetDailyMetrics', field: 'sales' },
      {
        name: 'returnOnAdSpend',
        displayName: 'ROAS',
        index: 'adCampaignAdGroupProductTargetDailyMetrics',
        field: 'returnOnAdSpend'
      },
      {
        name: 'conversionRate',
        displayName: 'Conversion Rate',
        index: 'adCampaignAdGroupProductTargetDailyMetrics',
        field: 'conversionRate'
      },
      { name: 'bidSavings', displayName: 'Bid Savings', index: 'adOptimizationHistory', field: 'bidSavings' },
      isAdAuditUser
        ? {
            name: 'bidChanges',
            displayName: 'Bid Optimizations',
            index: 'adOptimizationHistory',
            field: 'bidChanges'
          }
        : { name: 'hoursSaved', displayName: 'Hour Savings', index: 'adOptimizationHistory', field: 'hoursSaved' },
      isAdAuditUser
        ? {
            name: 'budgetChanges',
            displayName: 'Budget Optimizations',
            index: 'adOptimizationHistory',
            field: 'budgetChanges'
          }
        : { name: 'costsSaved', displayName: 'Labor Savings', index: 'adOptimizationHistory', field: 'costsSaved' },
      {
        name: 'changeAction',
        displayName: `${isAdAuditUser ? 'Total ' : ''}Optimizations`,
        index: 'adOptimizationHistory',
        field: 'changeAction'
      }
    ]
  }[appName];

  if (appName === AppName.Advertising && ['1'].includes(retailer.id.toString())) {
    params.push(
      ...[
        {
          name: 'brandTotalClicksIncrementalSales',
          displayName: 'Incremental Sales',
          index: 'adCampaignAdGroupProductTargetDailyMetrics',
          field: 'brandTotalClicksIncrementalSales'
        },
        {
          name: 'brandTotalClicksIncrementalROAS',
          displayName: 'Incremental ROAS',
          index: 'adCampaignAdGroupProductTargetDailyMetrics',
          field: 'brandTotalClicksIncrementalROAS'
        },
        {
          name: 'brandTotalClicksIncrementalClicks',
          displayName: 'Incremental Clicks',
          index: 'adCampaignAdGroupProductTargetDailyMetrics',
          field: 'brandTotalClicksIncrementalClicks'
        },
        {
          name: 'avgIncrementality',
          displayName: 'Incrementality',
          index: 'adCampaignAdGroupProductTargetDailyMetrics',
          field: 'avgIncrementality'
        }
      ]
    );
  }

  return appName === 'advertising'
    ? // hide currently selected metric from comparison metrics
      params.filter((option) => option.name !== _get(app, 'queryParams.adSummaryMetric'))
    : params;
};

const buildCompareConfig = (appName, retailer) => {
  const configParams = getConfigParams(appName);
  const hasTrafficData = retailer.dataTypesAvailable && retailer.dataTypesAvailable.includes('traffic');
  return (configParams || [])
    .filter((p) => p.index !== 'traffic' || hasTrafficData)
    .map((param) => buildSingleCompareConfig(param));
};

class Compare extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    aggregationConditions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    entity: PropTypes.object.isRequired,
    setEntity: PropTypes.func.isRequired,
    eventBus: PropTypes.object.isRequired
  };

  static contextTypes = {
    router: PropTypes.object
  };

  state = {
    isFilterPanelOpen: false,
    isComparePanelOpen: false,
    selectedComparison: {},
    hasComparisonFilters: true,
    currentTab: 'Metric'
  };

  componentDidMount() {
    this.setSelectedComparison(this.props.location);
    this.props.eventBus.on('showCompare', this.handleComparePanelOpen);
    this.props.eventBus.on('clearCompareFilters', this.handleClearCompareFiltersClick);
  }

  componentWillReceiveProps(nextProps) {
    const { location } = nextProps;
    if (location.search === this.props.location.search && location.pathname === this.props.location.pathname) {
      return;
    }

    this.setSelectedComparison(location);
  }

  componentWillUnmount() {
    this.props.eventBus.off('showCompare', this.handleComparePanelOpen);
    this.props.eventBus.off('clearCompareFilters', this.handleClearCompareFiltersClick);
  }

  setSelectedComparison() {
    this.setState({ selectedComparison: { type: COMPARISON_TYPE.PREVIOUS_YEAR } });
  }

  handleComparePanelOpen = () => {
    this.setState({ isComparePanelOpen: true });
  };

  handleToggleComparePanelClick = () => {
    this.setState({ isComparePanelOpen: !this.state.isComparePanelOpen });
    this.setState({ hasComparisonFilters: true });
  };

  handleTabTitleClick = (e, v) => {
    this.setState({ currentTab: v });
  };

  handleToggleFilterPanelClick = () => {
    this.setState({ isFilterPanelOpen: !this.state.isFilterPanelOpen });
  };

  handleChangeRetailerCompareClick = (retailer) => {
    const { app } = this.props;
    const { searchParams, additionalParams } = app.queryParams;
    this.handleToggleComparePanelClick();
    this.context.router.history.push(`${searchParams}${additionalParams}&ctype=retailer&cid=${retailer.id}`);
    this.setState({ hasComparisonFilters: true });
  };

  handleChangeMetricCompareClick = (menuItem) => {
    const { app } = this.props;
    const { searchParams, additionalParams, filterParams, adSummaryMetric } = app.queryParams;
    this.handleToggleComparePanelClick();
    this.context.router.history.push(
      `${searchParams}${additionalParams}${adSummaryMetric ? `&adSummaryMetric=${adSummaryMetric}` : ''}&ctype=metric&${
        menuItem.comparisonLinkParams
      }${filterParams}`
    );
    this.setState({ hasComparisonFilters: true });
  };

  handleClearCompareFiltersClick = () => {
    if (this.state.hasComparisonFilters) {
      const { app } = this.props;
      const { searchParams, additionalParams } = app.queryParams;
      // Should this clear the comparison filter?
      this.context.router.history.push(`${searchParams}${additionalParams}`);
      this.setState({ hasComparisonFilters: false });
    }
  };

  showComparisonDrawerMiddleTabContent() {
    const { aggregationConditions } = this.props;
    if (!this.state.isComparePanelOpen) {
      return null;
    }

    return (
      <div>
        <Group onSelectionChange={this.handleToggleComparePanelClick} aggregationConditions={aggregationConditions} />
      </div>
    );
  }

  showComparePanel(compareConfig) {
    const { retailer: currentRetailer, user, app } = this.props;
    const onAtlasSummaryPage = _get(app, ['queryParams', 'additionalParams'], '').indexOf('scorecard') !== -1;
    const onAdManager = app.name === 'advertising';

    return (
      <Drawer
        // width={isPhone() ? '90%' : 400}
        PaperProps={{
          sx: { width: 400 }
        }}
        className="haramie"
        anchor="right"
        open={this.state.isComparePanelOpen}
        // onClose={this.setState({ isComparePanelOpen: false })}
      >
        <div className="comparison-panel">
          <div className="comparison-panel__header">
            <button
              className="sl-panel-close-button"
              style={{ display: 'block' }}
              onClick={this.handleToggleComparePanelClick}
            >
              <ChevronIcon className="legend__chevron-icon" />
            </button>
            <h1 className="comparison-panel__header-text">Compare</h1>
          </div>
          <Tabs
            sx={{ backgroundColor: colors.darkBlue, fontWeight: 400 }}
            indicatorColor="secondary"
            onChange={this.handleTabTitleClick}
            variant="fullWidth" // tabItemContainerStyle={{ backgroundColor: colors.darkBlue }}
            // inkBarStyle={{ backgroundColor: colors.darkBlue }}
          >
            {!onAtlasSummaryPage && (
              <Tab
                label="Metric"
                sx={{
                  fontSize: '16px',
                  fontWeight: '400',
                  textTransform: 'none',
                  backgroundColor: colors.darkBlue,
                  color: '#fff'
                }}
                value="Metric"
              />
            )}
            {!onAdManager && (
              <Tab
                label="Group"
                sx={{
                  fontSize: '16px',
                  fontWeight: '400',
                  textTransform: 'none',
                  backgroundColor: colors.darkBlue,
                  color: '#fff'
                }}
                value="Group"
              />
            )}

            {!onAtlasSummaryPage && !onAdManager && (
              <Tab
                label="Retailer"
                sx={{
                  fontSize: '16px',
                  fontWeight: '400',
                  textTransform: 'none',
                  backgroundColor: colors.darkBlue,
                  color: '#fff'
                }}
                value="Retail"
              />
            )}
          </Tabs>
        </div>
        {/* Materic Tab Panel - */}
        <TabPanel representTab="Metric" currentTab={this.state.currentTab}>
          <List className="metric-tab__menu-list">
            {compareConfig.map((menuItem) => this.renderMetricsMenuItem(menuItem, 0))}
          </List>
        </TabPanel>

        {/* Group Tab Panel */}
        {!onAdManager && (
          <TabPanel representTab="Group" currentTab={this.state.currentTab}>
            {this.showComparisonDrawerMiddleTabContent()}
          </TabPanel>
        )}

        {/* Retailer Tab Panel */}
        <TabPanel representTab="Retail" currentTab={this.state.currentTab}>
          <ul className="retailer-tab__menu-list">
            {currentRetailer.availableRetailers.map((retailer) =>
              app.name === 'atlas' ? (
                currentRetailer.id !== retailer.id && (
                  <li key={retailer.id}>
                    <MenuItem
                      className={`retailer-tab__comparison-choice ${
                        retailer.name === this.state.selectedComparison.name
                          ? 'retailer-tab__comparison-choice--active'
                          : ''
                      }`}
                      onClick={() => this.handleChangeRetailerCompareClick(retailer)}
                      disabled={user.config.allRetailerIds.indexOf(parseInt(retailer.id, 10)) === -1}
                      title={
                        user.config.allRetailerIds.indexOf(parseInt(retailer.id, 10)) === -1
                          ? `It appears that you don't currently have "${retailer.displayName}" data integrated with Stackline.\nPlease contact us at support@stackline.com to get started.`
                          : ''
                      }
                    >
                      {retailer.displayName}
                    </MenuItem>
                  </li>
                )
              ) : (
                <li key={retailer.id}>
                  <MenuItem
                    className={`retailer-tab__comparison-choice ${
                      retailer.name === this.state.selectedComparison.name
                        ? 'retailer-tab__comparison-choice--active'
                        : ''
                    }`}
                    onClick={() => this.handleChangeRetailerCompareClick(retailer)}
                    disabled={user.config.allRetailerIds.indexOf(parseInt(retailer.id, 10)) === -1}
                    title={
                      user.config.allRetailerIds.indexOf(parseInt(retailer.id, 10)) === -1
                        ? `It appears that you don't currently have "${retailer.displayName}" data integrated with Stackline.\nPlease contact us at support@stackline.com to get started.`
                        : ''
                    }
                  >
                    {retailer.displayName}
                  </MenuItem>
                </li>
              )
            )}
          </ul>
        </TabPanel>
      </Drawer>
    );
  }

  renderMetricsMenuItem(menuItem, level) {
    const { entity } = this.props;
    if (menuItem.isHeader) {
      return (
        <div className="compare_header" style={style.compareHeader}>
          {menuItem.displayName}
        </div>
      );
    }
    if (
      menuItem.compareDisabledForEntityType &&
      (menuItem.compareDisabledForEntityType.indexOf(entity.type) >= 0 ||
        menuItem.compareDisabledForEntityType.indexOf('all') >= 0)
    ) {
      return null;
    }

    if (!menuItem.subMenuItems || menuItem.subMenuItems.length === 0) {
      return (
        <ListItemButton sx={{ padding: '0px 32px', fontSize: '16px', minHeight: '48px', cursor: 'pointer' }}>
          <ListItemText
            primary={menuItem.displayName}
            key={menuItem.name}
            className={`metric-tab__comparison-choice compare__metric ${
              menuItem.isCompared ? 'metric-tab__comparison-choice--active' : ''
            }`}
            onClick={() => this.handleChangeMetricCompareClick(menuItem)}
          />
        </ListItemButton>
      );
    }

    return (
      <ListItem
        autoGenerateNestedIndicator={false}
        primaryText={menuItem.displayName}
        primaryTogglesNestedList
        open
        key={menuItem.name}
        className="metric-tab__title"
        style={{ fontWeight: 500 }}
        innerDivStyle={{ padding: '16px 32px', fontSize: '16px', minHeight: '48px' }}
        nestedItems={menuItem.subMenuItems
          .filter((subMenuItem) => subMenuItem.comparisonLinkParams !== undefined)
          .map((subMenuItem) => this.renderMetricsMenuItem(subMenuItem, level + 1))}
      />
    );
  }

  render() {
    const { retailer } = this.props;
    const appName = this.props.app.name;
    const queryParams = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    if (!queryParams.tab) {
      queryParams.tab = 'sales';
    }
    if (!queryParams.subtab) {
      queryParams.subtab = 'retailSales';
    }
    if (!this.props.entity) {
      return null;
    }
    const compareConfig = buildCompareConfig(appName, retailer);
    return <div>{this.showComparePanel(compareConfig)}</div>;
  }
}

const mapStateToProps = (state) => _pick(state, ['app', 'retailer', 'mainTimePeriod', 'user']);

const mapDispatchToProps = {
  setEntity: entityOperations.setEntity
};

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withBus('eventBus'));

const EnhancedCompare = enhance(Compare);

export default EnhancedCompare;
