import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { SwitchClassKey, SwitchProps } from '@mui/material/Switch';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from 'react';
import CustomPlayButtonWrapper from 'src/components/common/Buttons/CustomPlayButtonWrapper';
import { mappingPointToWeekID } from 'src/components/Omni/OmniGeoMap/OmniGeoTimeLapseControls/omniSliderUtils';
import { BackwardDoubleArrow, ForwardDoubleArrowIcon } from 'src/components/SvgIcons/SvgIcons';
import ReduxStore from 'src/types/store/reduxStore';
import { transferWeekIdToLastDayOfWeek } from 'src/utils/dateformatting';
import './omniGeoTimeLapseControls.scss';

interface OmniGeoTimeLapseControlsProps {
  currentSliderValue: number;
  onSliderChange: (nextValue: number | number[]) => void;
  mainTimePeriod: ReduxStore['mainTimePeriod'];
  comparisonTimePeriod: ReduxStore['comparisonTimePeriod'];
  shouldDisableSlider: boolean;
  onForwardButtonClick: () => void;
  onReverseButtonClick: () => void;
  playing: boolean;
  handlePlayingButton: () => void;
  max: number;
}

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}
interface Props extends SwitchProps {
  classes: Styles;
}

const { createSliderWithTooltip } = Slider;
const SliderWithToolTip = createSliderWithTooltip(Slider);

const OmniGeoTimeLapseControls = ({
  currentSliderValue,
  onSliderChange,
  mainTimePeriod,
  comparisonTimePeriod,
  shouldDisableSlider: shouldDisableTimeLapse,
  onForwardButtonClick,
  onReverseButtonClick: onBackWardButtonClick,
  playing,
  handlePlayingButton,
  max
}: OmniGeoTimeLapseControlsProps) => {
  const { endWeek } = mainTimePeriod;
  const { startWeek } = comparisonTimePeriod;

  const startDate = transferWeekIdToLastDayOfWeek(startWeek, 'M/DD');
  const endDate = transferWeekIdToLastDayOfWeek(endWeek, 'M/DD');

  const playButtonStyle: React.CSSProperties = {
    borderRadius: '50%',
    width: 27,
    height: 27,
    backgroundColor: shouldDisableTimeLapse ? '#cfd7df' : '#043048',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10
  };

  const playButtonIconStyle: React.CSSProperties = {
    color: '#ffffff',
    width: 13.5,
    height: 13.5
  };

  const dateFontStyle: React.CSSProperties = {
    color: '#98a5b9',
    fontSize: 18,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left'
  };

  return (
    <div
      style={{
        margin: '38.7px 31px 30px 10.4px',
        width: '946px',
        height: '180px',
        padding: '16.4px 58px 33px 58px',
        backgroundColor: !shouldDisableTimeLapse ? '#f6f9fd' : 'transparent',
        borderRadius: 15
      }}
    >
      <div className={`${shouldDisableTimeLapse ? 'omni_eclipse--tools__hidden' : ''}`}>
        <div
          style={{
            marginTop: 50,
            width: 816
          }}
        >
          <SliderWithToolTip
            min={0}
            max={max}
            value={currentSliderValue}
            onChange={onSliderChange}
            handleStyle={{
              height: 15,
              width: 15,
              backgroundColor: '#052849',
              border: 0,
              visibility: shouldDisableTimeLapse ? 'hidden' : 'visible',
              marginTop: '-3px'
            }}
            dotStyle={{ marginLeft: '-10px' }}
            step={1}
            trackStyle={{
              background: '#052849',
              paddingBottom: 10,
              left: '4px'
            }}
            tipFormatter={(value) => {
              const weekId = mappingPointToWeekID(value, mainTimePeriod, comparisonTimePeriod);
              if (weekId) {
                return transferWeekIdToLastDayOfWeek(weekId, 'M/DD');
              }
              return '';
            }}
            tipProps={{
              placement: 'top',
              visible: !shouldDisableTimeLapse
            }}
            disabled={shouldDisableTimeLapse}
          />
          <div
            style={{
              display: 'flex',
              marginTop: '5px',
              justifyContent: 'space-between'
            }}
          >
            <span style={dateFontStyle}>{startDate}</span>
            <span style={dateFontStyle}>{endDate}</span>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CustomPlayButtonWrapper outerStyle={playButtonStyle} handleClick={() => onBackWardButtonClick()}>
            <BackwardDoubleArrow style={{ ...playButtonIconStyle, marginRight: 2.5 }} />
          </CustomPlayButtonWrapper>
          <CustomPlayButtonWrapper
            outerStyle={{ ...playButtonStyle, width: 33, height: 33 }}
            handleClick={() => {
              handlePlayingButton();
            }}
          >
            {!playing ? (
              <PlayArrowIcon style={{ ...playButtonIconStyle, width: 30, height: 37 }} />
            ) : (
              <PauseIcon style={{ ...playButtonIconStyle, width: 20, height: 37 }} />
            )}
          </CustomPlayButtonWrapper>
          <CustomPlayButtonWrapper outerStyle={playButtonStyle} handleClick={() => onForwardButtonClick()}>
            <ForwardDoubleArrowIcon style={{ ...playButtonIconStyle, marginLeft: 2.5 }} />
          </CustomPlayButtonWrapper>
        </div>
      </div>
    </div>
  );
};

export default OmniGeoTimeLapseControls;
