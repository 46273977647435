import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Widget } from 'src/types/application/widgetTypes';
import { withRouter } from 'react-router';
import { EventBus } from 'src/types/utils';
import { withBus } from 'react-bus';
import Tooltip from '@mui/material/Tooltip';
import Highcharts from 'highcharts/highstock';
import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';
import queryString from 'qs';
import ReduxStore from 'src/types/store/reduxStore';
import MarketShareTooltip from 'src/components/Layout/util/MarketShareTooltip';
import { computeLastWeek } from 'src/components/EntityPage/Renderer/util';
import { panic } from 'src/utils/mixpanel';
import {
  AtlasSummaryMainTrendLoading,
  AtlasSummarySubTrendLoading
} from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { getTrendChartInfo, getCommonSummaryTrendParameters } from './omniCommonTrendUtil';
import { omniChartsServiceOperations } from 'src/store/modules/omni/omniChartService';
import { PERIODS } from 'src/store/modules/omni/constants';
import GenericChart from 'src/components/Charts/GenericChart';
import _cloneDeep from 'lodash/cloneDeep';
import { addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';
import { OmniTrendChartRequestBody } from 'src/components/EntityPage/TrendChart/OmniTrendChart';
import PropTypes from 'prop-types';
import { startFetchChartData, deleteKey } from 'src/store/modules/omni/omniChartService/actions';

// TODO: give more parameters type
const { fetchOmniChartsServiceData } = omniChartsServiceOperations;
interface OmniCommonSummaryTrend extends RouteComponentProps {
  widget: Widget;
  eventBus: EventBus;
}

const buildFillColor = (color: string) => ({
  linearGradient: {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0.9
  },
  stops: [
    [0, Highcharts.Color(color).setOpacity(0).get('rgba')],
    [1, Highcharts.Color(color).setOpacity(0).get('rgba')]
  ]
});

const fillColorForSeries = (series) => {
  if (series[0]) {
    series[0].fillColor = buildFillColor('#195AA2');
  }
  if (series[1]) {
    series[1].fillColor = buildFillColor('#46a8f6');
  }
  return series;
};

interface SubTrendLegendProps {
  legendTitle: string;
  legendValue: string;
  legendChange: string;
  legendAbsoluteChange: string;
  legendClassName: string;
  showMarketShareTooltip?: boolean;
  isLoading: boolean;
}

const SubTrendLegend: React.FC<SubTrendLegendProps> = ({
  legendTitle,
  legendValue,
  legendChange,
  legendAbsoluteChange,
  legendClassName,
  showMarketShareTooltip,
  isLoading
}) => {
  if (isLoading) {
    return null;
  }

  const title = <div className="summary_subtrend_title">{legendTitle}</div>;

  return (
    <div className="summary_trend_legend">
      {showMarketShareTooltip ? <MarketShareTooltip>{title}</MarketShareTooltip> : title}

      <div className="summary_legend_info">
        <span>{`${legendValue} `}</span>
        <span>
          <Tooltip title={legendAbsoluteChange} placement="top">
            <span className={legendClassName}>{legendChange}</span>
          </Tooltip>
        </span>
      </div>
    </div>
  );
};

SubTrendLegend.defaultProps = {
  showMarketShareTooltip: false
};

SubTrendLegend.propTypes = {
  legendTitle: PropTypes.string.isRequired,
  legendValue: PropTypes.string.isRequired,
  legendChange: PropTypes.string.isRequired,
  legendAbsoluteChange: PropTypes.string.isRequired,
  legendClassName: PropTypes.string.isRequired,
  showMarketShareTooltip: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired
};

const OmniCommonSummaryTrend: React.FC<OmniCommonSummaryTrend> = ({ widget, eventBus, history, location }) => {
  const {
    name,
    view: { metricFields }
  } = widget;
  const dispatch = useDispatch();
  const { mainEntity } = useSelector((state: ReduxStore) => state.entityService);
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const comparisonTimePeriod = useSelector((state: ReduxStore) => state.comparisonTimePeriod);
  const { endWeek, id: idForMain } = mainTimePeriod;
  const { startWeek, id: idForComp, endWeek: compareEndWeek } = comparisonTimePeriod;
  const compStartWeek = idForComp === 'prior-period' && idForMain !== '1w' ? computeLastWeek(startWeek) : startWeek;
  const chartName = `${name}_${compStartWeek}_${endWeek}`;
  const chartData = useSelector((state: ReduxStore) => state.omniChartsService[chartName]);
  const isFetching = chartData ? chartData.isFetching : true;
  const filters = useSelector((state: ReduxStore) => state.filters);
  const clonedChartData = _cloneDeep(chartData);

  const { fulfillmentType } = metricFields[0];
  const fetchData = useCallback(() => {
    const baseRequestBody: OmniTrendChartRequestBody = {
      retailerIds: [],
      includeBrandIds: [],
      includeCategoryIds: [],
      productIds: [],
      includeSubCategoryIds: [],
      startWeekId: compStartWeek,
      groupBy: 'weekId',
      endWeekId: endWeek,
      fulfillmentType
    };
    const { pathname } = location;
    const requestBody = addFilterToOmniBaseReqBody(baseRequestBody, filters, pathname, mainEntity.query);
    if (fulfillmentType === 'all') {
      delete baseRequestBody.fulfillmentType;
    }

    dispatch(
      startFetchChartData({
        chartName,
        data: []
      })
    );
    dispatch(
      fetchOmniChartsServiceData(requestBody, PERIODS.MAIN, chartName, metricFields[0].name, {
        compareStartWeek: startWeek,
        compareEndWeek
      })
    );
  }, [dispatch, compStartWeek, endWeek, metricFields, chartName, location, fulfillmentType, filters, compareEndWeek]);

  useEffect(() => {
    fetchData();
    return () => {
      dispatch(deleteKey(chartName));
    };
  }, [fetchData, chartName, dispatch]);

  const renderMainLoading = () => (widget.view.disableLoadingIndicator ? null : <AtlasSummaryMainTrendLoading />);
  const renderSubLoading = () => (widget.view.disableLoadingIndicator ? null : <AtlasSummarySubTrendLoading />);

  const redirectTo = (linkTo): void => {
    const { tab, subtab, scrollTo, ...rest } = linkTo;

    if ((!tab || !subtab) && scrollTo) {
      if (_isString(scrollTo)) {
        window.location.hash = scrollTo;
        return;
      } else if (_isObject(scrollTo) && scrollTo.eventName && scrollTo.eventData) {
        eventBus.emit(scrollTo.eventName, scrollTo.eventData);
        return;
      } else {
        throw panic(`Invalid \`scrollTo\` prop passed to \`CommonSummaryTrend\``, { scrollTo });
      }
    }

    const { pathname, search } = location;

    const parsedUrl = queryString.parse(search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    parsedUrl.tab = tab;
    parsedUrl.subtab = subtab;
    Object.entries(rest).forEach(([key, val]) => {
      parsedUrl[key] = val;
    });
    if (scrollTo) {
      parsedUrl.scrollTo = scrollTo;
    }

    history.push({
      pathname,
      search: `?${queryString.stringify(parsedUrl)}`,
      hash: scrollTo,
      state: { from: location }
    });
  };

  const renderMainTrendLegend = (title, value, change, absoluteChange, className, linkTo) => {
    if (isFetching) {
      return null;
    }
    return (
      <div className="summary_trend_legend" onClick={() => redirectTo(linkTo)} role="button">
        <div className="summary_maintrend_title">{title}</div>
        <div className="summary_legend_info">
          <span>{`${value} `}</span>
          <span>
            <Tooltip title={absoluteChange} placement="top">
              <span className={className}>{change}</span>
            </Tooltip>
          </span>
        </div>
      </div>
    );
  };

  const renderMainTrend = (linkTo) => {
    if (isFetching || !clonedChartData || !clonedChartData.main) {
      return renderMainLoading();
    }
    const chartInfo = getTrendChartInfo(widget, clonedChartData, mainTimePeriod, comparisonTimePeriod);
    if (!chartInfo) {
      return renderMainLoading();
    }
    const { chartSeries, chartProps } = getCommonSummaryTrendParameters(chartInfo);
    if (chartSeries === null || chartProps === null) {
      return renderMainLoading();
    }
    const { legendTitle, legendValue, legendChange, legendClassName, legendAbsoluteChange } = chartProps.legend;
    fillColorForSeries(chartSeries);
    return (
      <div className="summary_maintrend_wrapper">
        {!chartProps.legend.enabled && !chartProps.legend.disableTrendLegend
          ? renderMainTrendLegend(legendTitle, legendValue, legendChange, legendAbsoluteChange, legendClassName, linkTo)
          : null}
        <div className="summary_maintrend_plot">
          <GenericChart chartSeries={chartSeries} chartProps={chartProps} />
        </div>
      </div>
    );
  };

  const renderSubTrend = (linkTo) => {
    if (isFetching || !clonedChartData || !clonedChartData.main) {
      return renderSubLoading();
    }
    const chartInfo = getTrendChartInfo(widget, clonedChartData, mainTimePeriod, comparisonTimePeriod);
    if (!chartInfo) {
      return renderSubLoading();
    }
    const chartParameters = getCommonSummaryTrendParameters(chartInfo);
    if (!chartParameters) {
      return null;
    }
    const { chartSeries, chartProps } = chartParameters;
    return (
      <div className="summary_subtrend_wrapper" onClick={() => redirectTo(linkTo)} role="button">
        {!chartProps.legend.enabled && !chartProps.legend.disableTrendLegend ? (
          <SubTrendLegend {...chartProps.legend} isLoading={isFetching} />
        ) : null}
        <div className="summary_subtrend_plot">
          <GenericChart chartSeries={chartSeries} chartProps={chartProps} />
        </div>
      </div>
    );
  };

  return widget.view.container.className === 'summary_subtrend_parent'
    ? renderSubTrend(widget.view.linkTo)
    : renderMainTrend(widget.view.linkTo);
};

export default withRouter(withBus('eventBus')(OmniCommonSummaryTrend));
