import React, { useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { AppImage, Text, useStacklineTheme } from '@stackline/ui';
import { useAppSelector } from 'src/utils/Hooks';
import _flatten from 'lodash/flatten';
import _cloneDeep from 'lodash/cloneDeep';
import { History, Location } from 'history';
import { FormControlLabel, Divider, TextField, Checkbox, Radio } from '@mui/material';
import { ProductGrowthFilterModalHeader } from 'src/components/BeaconRedesignComponents/ProductGrowthModal/ModalComponents/ProductGrowthFilterModal';
import {
  FilterKey,
  generateFilterUrlString
} from 'src/components/BeaconRedesignComponents/common/AppFilterButton/utils';
import { SelectedIncludeCheckbox } from 'src/components/SvgIcons';
import './appFilterStyles.scss';
import { CategoryEntity, Segment, SubCategoryEntity } from 'sl-api-connector';
import { SlRow } from 'src/components/BeaconRedesignComponents/Generic/SlRow';
import { adjustmentStatusOptions } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/filters/ForecastFilterButton';
import { BEACON_SUBTABS } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

const GenericStyledDialogue = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '752px',
    height: '641px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px'
  }
});

export const AppFilterModalFooter = ({
  handleApplyFilters,
  handleClearFilters,
  handleClose
}: {
  handleApplyFilters: () => void;
  handleClearFilters: () => void;
  handleClose: () => void;
}) => {
  const handleSubmit = () => {
    handleApplyFilters();
    handleClose();
  };
  const theme = useStacklineTheme();

  return (
    <>
      <Divider sx={{ borderColor: theme.colors.primaryGray }} />
      <SlRow
        horizontalInset="xl"
        style={{ paddingTop: '20px', paddingBottom: '20px' }}
        spacing="md"
        verticalPosition="center"
        horizontalPosition="space-between"
      >
        <SlButton onClick={handleClearFilters}>Clear</SlButton>
        <SlButton onClick={handleSubmit} variant="contained">
          Apply
        </SlButton>
      </SlRow>
    </>
  );
};

interface FilterSearchInputProps {
  input: string;
  handleChange: (s: string) => void;
  placeholderText?: string;
}
const FilterSearchInput = ({ input, handleChange, placeholderText = '' }: FilterSearchInputProps) => {
  const theme = useStacklineTheme();
  return (
    <TextField
      variant="standard"
      value={input}
      onChange={(event) => handleChange(event.target.value)}
      InputProps={{
        placeholder: placeholderText,
        disableUnderline: true,
        startAdornment: (
          <AppImage
            src="https://media.brandclub.com/brandclub/image_asset/icon_search.svg"
            alt="search-icon"
            style={{
              marginRight: 10
            }}
          />
        ),
        autoComplete: 'off',
        id: 'type-search'
      }}
      sx={{
        borderRadius: 17,
        border: `1px solid ${theme.colors.primaryGray}`,
        height: 30,
        width: 290,
        backgroundColor: '#fff',
        '& div.MuiInput-root': {
          padding: '0 16px',
          display: 'flex',
          alignItems: 'center',
          height: 30,
          '& input': {
            padding: 0,
            fontSize: '12px',
            fontFamily: 'Roboto',
            color: theme.colors.primary
          }
        }
      }}
    />
  );
};

/**
 * The max options to show/increase by in the filter groups
 */
const MAX_OPTIONS_SHOWN = 6;

interface AdjustmentStatusOption {
  isSelected: boolean;
  displayName: string;
  label: string;
  id: string;
}

type CategoryOption = CategoryEntity & {
  isSelected: boolean;
};
type SubcategoryOption = SubCategoryEntity & {
  isSelected: boolean;
};
type SegmentOption = Segment & {
  isSelected: boolean;
};

interface AppFilterGroupProps {
  filterTitle: string;
  options: AdjustmentStatusOption[] | CategoryOption[] | SubcategoryOption[] | SegmentOption[];
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRadio?: boolean;
  placeholderText: string;
  hideSearchInput?: boolean;
}

const FilterGroup = ({
  filterTitle,
  options,
  onSelect,
  isRadio = false,
  placeholderText = '',
  hideSearchInput = false
}: AppFilterGroupProps) => {
  // Pagination for "Show more" button
  const [optionsPage, setOptionsPage] = useState(1);
  // Input used for searching categories or subcategories
  const [input, setInput] = useState('');
  const theme = useStacklineTheme();

  const userInput = useMemo(() => {
    return input;
  }, [input]);

  /**
   * If the user types in the search bar, we should search all available options.
   * Otherwise we should only show subcategories under a selected parent category.
   * If no category is selected, we can make all options available.
   * Note that we limit (paginate) available options unless the user is searching.
   */
  const availableOptions = useMemo(() => {
    if (userInput.length > 0) {
      return options
        .filter((option) => option.id !== 0 && option.displayName.toLowerCase().includes(userInput.toLowerCase()))
        .map((option) => {
          return {
            id: option.id,
            label: option.displayName,
            isSelected: option.isSelected
          };
        });
    } else {
      return options
        .slice(0, optionsPage * MAX_OPTIONS_SHOWN)
        .filter((cat) => cat.id !== 0)
        .map((option) => {
          return {
            id: option.id,
            label: option.displayName,
            isSelected: option.isSelected
          };
        });
    }
  }, [optionsPage, userInput, options]);

  const showMoreOptions = () => {
    setOptionsPage(optionsPage + 1);
  };

  return (
    <Flex flexDirection="column">
      {/* FilterTitle */}
      <Flex marginTop={theme.spacing.mdl}>
        <Text variant="subtitle1">{filterTitle}</Text>
      </Flex>

      {/* Search Input  */}
      {!hideSearchInput && (
        <Flex marginTop={theme.spacing.md} marginBottom="10px">
          <FilterSearchInput handleChange={setInput} input={input} placeholderText={placeholderText} />
        </Flex>
      )}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, auto)',
          color: theme.colors.primary,
          justifyContent: 'space-between',
          rowGap: '3px',
          marginTop: '10px',
          marginBottom: theme.spacing.sm
        }}
      >
        <>
          {availableOptions
            ? availableOptions.map((option) => (
                <FormControlLabel
                  title={option.label}
                  sx={{
                    color: theme.colors.primary,
                    minWidth: '190px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginLeft: '-7px',
                    marginRight: '0px',
                    '& .MuiTypography-root': {
                      width: '160px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }
                  }}
                  checked={option.isSelected}
                  label={option.label}
                  value={option.id}
                  control={
                    isRadio ? (
                      <Radio
                        disableRipple
                        disableTouchRipple
                        sx={{
                          svg: {
                            height: '15px',
                            width: '15px'
                          },
                          '&, &.Mui-checked': {
                            color: theme.colors.primary
                          }
                        }}
                      />
                    ) : (
                      <Checkbox
                        checkedIcon={<SelectedIncludeCheckbox style={{ width: '14px', height: '14px' }} />}
                        icon={
                          <div
                            style={{
                              width: '14px',
                              height: '14px',
                              border: '1px solid #9ea9b5',
                              backgroundColor: '#fff',
                              borderRadius: '2px'
                            }}
                          ></div>
                        }
                        disableRipple
                        disableTouchRipple
                        sx={{
                          '&, &.Mui-checked': {
                            color: theme.colors.primary
                          }
                        }}
                      />
                    )
                  }
                  key={option.id}
                  onChange={(e) => onSelect(e)}
                />
              ))
            : null}
        </>
      </div>

      {/* Show more button */}

      {!hideSearchInput ? (
        <button
          style={{
            paddingLeft: 0,
            border: 'none',
            background: 'none',
            textAlign: 'left',
            marginTop: theme.spacing.mdl,
            marginBottom: theme.spacing.sm
          }}
          onClick={showMoreOptions}
        >
          <Text variant="subtitle2" underlined>
            Show more
          </Text>
        </button>
      ) : null}
    </Flex>
  );
};

const AppFilterModalInner = ({
  history,
  handleClose
}: {
  location: Location;
  history: History;
  handleClose: () => void;
}) => {
  const app = useAppSelector((state) => state.app);
  const { subtab } = app.queryParams;
  const filters = useAppSelector((state) => state.filters);
  const filterParamsFromUrl = new URLSearchParams(window.location.search);
  const adjustmentStatusSet = new Set(filterParamsFromUrl.getAll('adjustmentStatus'));
  const [adjustmentSelection, setAdjustmentSelection] = useState(adjustmentStatusSet);
  const categories = useAppSelector((state) => state.categories);
  const subCategories = useAppSelector((state) => state.subCategories);
  const segments = useAppSelector((state) => state.segments);
  const theme = useStacklineTheme();

  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);

  // TODO: We need to merge mySegments here as well
  const { teamSegments } = segments;

  const sortByUnitsSold = (a, b) => {
    return b.unitsSold - a.unitsSold;
  };

  // Sort to bring checked items to the front of the collection
  const sortBySelected = (a, b) => {
    return a.isSelected === b.isSelected ? 0 : a.isSelected ? -1 : 1;
  };

  const adjustmentStatusSelectionOption = adjustmentStatusOptions.map((adjustmentStatus) => {
    return {
      ...adjustmentStatus,
      isSelected: adjustmentSelection.has(adjustmentStatus.id),
      displayName: adjustmentStatus.label
    };
  });

  /**
   * A list of available categories to filter on with an additional isSelected property.
   */
  const defaultCategories = _cloneDeep(categories)
    .map((category) => ({
      ...category,
      isSelected: filters.category
        ? !!filters.category.find((categoryFilteredOn) => String(categoryFilteredOn.id) === String(category.id))
        : false
    }))
    .sort(sortByUnitsSold)
    .sort(sortBySelected);

  const [availableCategories, setAvailableCategories] = useState(defaultCategories);

  /**
   * A list of available subcategories to filter on with an additional isSelected property.
   */
  const defaultSubcategories = _cloneDeep(subCategories)
    .map((subcategory) => ({
      ...subcategory,
      isSelected: filters.subcategory
        ? !!filters.subcategory.find(
            (subcategoryFilteredOn) => String(subcategoryFilteredOn.id) === String(subcategory.id)
          )
        : false
    }))
    .sort(sortByUnitsSold)
    .sort(sortBySelected);

  const [availableSubcategories, setAvailableSubcategories] = useState(defaultSubcategories);

  const defaultSegments = _cloneDeep(teamSegments)
    .map((segment) => ({
      ...segment,
      isSelected: filters.segment ? !!(filters.segment.id === String(segment.id)) : false
    }))
    .sort(sortBySelected);
  const [availableSegments, setAvailableSegments] = useState(defaultSegments);

  const categoryOptions = useMemo(() => {
    return availableCategories.map((category) => {
      return {
        ...category,
        isSelected: category.isSelected
      };
    });
  }, [availableCategories]);

  const onSelectAdjustmentStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAdjustmentId = e.target.value;
    const updatedSection = new Set(adjustmentSelection);
    if (updatedSection.has(selectedAdjustmentId)) {
      updatedSection.delete(selectedAdjustmentId);
    } else {
      updatedSection.add(selectedAdjustmentId);
    }
    setAdjustmentSelection(updatedSection);
  };

  const onSelectCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCategoryId = String(e.target.value);
    const newCategories = categoryOptions.map((category) => {
      return {
        ...category,
        isSelected: selectedCategoryId === String(category.id) ? !category.isSelected : category.isSelected
      };
    });

    setAvailableCategories(newCategories);
    const selectedCategories = newCategories.filter(({ isSelected }) => isSelected);
    if (selectedCategories.length < 1) {
      setAvailableSubcategories(defaultSubcategories.map((subcategory) => ({ ...subcategory, isSelected: false })));
    } else {
      // Clean up subcategories
      const validSubcategoriesById = _flatten(
        selectedCategories.map(({ childEntities }) => childEntities.map(({ id }) => String(id)))
      );
      const validSubcategories = availableSubcategories.map((subcategory) => {
        return {
          ...subcategory,
          isSelected: validSubcategoriesById.includes(String(subcategory.id)) ? subcategory.isSelected : false
        };
      });
      setAvailableSubcategories(validSubcategories);
    }
  };

  const subCategoryOptions = useMemo(() => {
    let selectedCategories = categoryOptions.filter(({ isSelected }) => isSelected);

    if (mainEntity.type === 'category') {
      selectedCategories = categoryOptions.filter(({ id }) => String(id) === String(mainEntity.id));
    }

    if (selectedCategories.length > 0) {
      const availableSubcategoriesById = _flatten(
        selectedCategories.map(({ childEntities }) => childEntities.map(({ id }) => String(id)))
      );

      return availableSubcategories
        .filter(({ id }) => availableSubcategoriesById.includes(String(id)))
        .map((subcategory) => {
          return {
            ...subcategory,
            isSelected: subcategory.isSelected
          };
        });
    } else {
      return availableSubcategories.map((subcategory) => ({
        ...subcategory,
        isSelected: subcategory.isSelected
      }));
    }
  }, [availableSubcategories, categoryOptions, mainEntity]);

  const onSelectSubcategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSubcategoryId = String(e.target.value);
    const newSubcategories = availableSubcategories.map((subcategory) => {
      return {
        ...subcategory,
        isSelected: selectedSubcategoryId === String(subcategory.id) ? !subcategory.isSelected : subcategory.isSelected
      };
    });
    setAvailableSubcategories(newSubcategories);
  };

  const segmentOptions = useMemo(() => {
    return availableSegments.map((segment) => {
      return {
        ...segment,
        isSelected: segment.isSelected
      };
    });
  }, [availableSegments]);

  const onSelectSegment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSegmentId = String(e.target.value);
    const newSegments = availableSegments.map((segment) => {
      return {
        ...segment,
        isSelected: selectedSegmentId === String(segment.id)
      };
    });
    setAvailableSegments(newSegments);
  };

  const handleApplyFilters = () => {
    const { queryParams } = app;
    const { searchParams, additionalParams } = queryParams;

    // Forecasts, adjustment page has special case, that the adjustmentStatus filter url is appending, I think it is intended for using new URLSearchparams getAll
    const adjustmentFilterParams = Array.from(adjustmentSelection.values()).reduce(
      (prevString, currentAdjustmentType) => {
        return `${prevString}&adjustmentStatus=${currentAdjustmentType}`;
      },
      ''
    );

    // Get all selected categories, subcategories, and segments
    const selectedCategories = categoryOptions.filter((category) => category.isSelected);
    const selectedSubcategories = availableSubcategories.filter((subcategory) => subcategory.isSelected);
    const selectedSegments = availableSegments.filter((segment) => segment.isSelected);

    const filterValues = {
      [FilterKey.Category]: selectedCategories,
      [FilterKey.Subcategory]: selectedSubcategories,
      [FilterKey.Segment]: selectedSegments
    };

    const { generatedFilterParams } = generateFilterUrlString(filterValues);

    if (generatedFilterParams) {
      history.push(`${searchParams}${additionalParams}${generatedFilterParams}${adjustmentFilterParams}`);
    } else {
      history.push(`${searchParams}${additionalParams}${adjustmentFilterParams}`);
    }
  };

  const clearSelection = () => {
    const clearedCategories = availableCategories.map((category) => ({ ...category, isSelected: false }));
    const clearedSubcategories = availableSubcategories.map((subcategory) => ({ ...subcategory, isSelected: false }));
    const clearedSegments = availableSegments.map((segment) => ({ ...segment, isSelected: false }));
    setAdjustmentSelection(new Set());
    setAvailableCategories(clearedCategories);
    setAvailableSubcategories(clearedSubcategories);
    setAvailableSegments(clearedSegments);
  };

  /**
   * Determines whether the filter should be shown for a given entity type. This is to avoid
   * having category filters show when user is viewing a category already.  Applying this filter
   * would not be correct, so we should not give them the option.
   * @param entityType - The type of entity filter to check.
   * @returns Whether the filter should be shown.
   */
  const shouldShowFilter = (entityType: string) => {
    const currentEntityType = mainEntity.type;

    if (entityType === 'category' && ['category', 'subcategory'].includes(currentEntityType)) {
      return false;
    }

    if (entityType === 'subcategory' && currentEntityType === 'subcategory') {
      return false;
    }

    if (entityType === 'segment' && currentEntityType === 'segment') {
      return false;
    }

    return true;
  };

  // Adjustment status is only applicable for one tab -- forecast - adjustment .
  const shouldShowAdjustmentTypeFilter = [BEACON_SUBTABS.FORECAST_ADJUSTMENTS].includes(subtab);

  return (
    <>
      <ProductGrowthFilterModalHeader handleClose={handleClose} />
      <div className="inner-container" style={{ height: '500px', overflow: 'scroll' }}>
        <Flex flexDirection="column" paddingLeft={theme.spacing.xl} paddingRight={theme.spacing.lg}>
          {shouldShowAdjustmentTypeFilter ? (
            <FilterGroup
              filterTitle="Adjustment Status"
              hideSearchInput
              options={adjustmentStatusSelectionOption}
              onSelect={onSelectAdjustmentStatus}
              placeholderText="Search categories"
            />
          ) : null}
          {shouldShowFilter('category') ? (
            <FilterGroup
              filterTitle="Categories"
              options={categoryOptions}
              onSelect={onSelectCategory}
              placeholderText="Search categories"
            />
          ) : null}
          {shouldShowFilter('subcategory') ? (
            <FilterGroup
              filterTitle="Subcategories"
              options={subCategoryOptions}
              onSelect={onSelectSubcategory}
              placeholderText="Search subcategories"
            />
          ) : null}
          {shouldShowFilter('segment') ? (
            <FilterGroup
              filterTitle="Segments"
              options={segmentOptions}
              onSelect={onSelectSegment}
              isRadio
              placeholderText="Search segments"
            />
          ) : null}
        </Flex>
      </div>
      <AppFilterModalFooter
        handleClearFilters={clearSelection}
        handleApplyFilters={handleApplyFilters}
        handleClose={handleClose}
      />
    </>
  );
};

interface AppFilterModalProps {
  open: boolean;
  handleClose: () => void;
  history: History;
  location: Location;
}
export const AppFilterModal = ({ open, handleClose, history, location }: AppFilterModalProps) => {
  return (
    <GenericStyledDialogue open={open} onClose={handleClose}>
      <AppFilterModalInner handleClose={handleClose} history={history} location={location} />
    </GenericStyledDialogue>
  );
};
