import types from './types';

export const receiveOmniAccountSetting = (entity: { data: any }) => ({
  type: types.RECEIVE_OMNI_ACCOUNT,
  entity
});

export const startFetchOmniAccountSetting = (entity: { data: any }) => ({
  type: types.START_FETCH,
  entity
});
