// ------------------------------------
// Actions Creators
// ------------------------------------

import types from './types';

function updateNavSidebarVisibility(isVisible) {
  return {
    type: types.UPDATE_NAV_SIDEBAR_VISIBILITY,
    isVisible
  };
}

export default {
  updateNavSidebarVisibility
};
