import { SlRow, Text, SlColumn } from '@stackline/ui';
import React from 'react';
import { styled } from '@mui/material/styles';
import { GenericStyledDialogue } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/CreateAdjustmentModal';
import { BusinessUnit } from 'sl-api-connector';
import { useDispatch } from 'react-redux';
import { deleteRequestedSavedSearch, fetchAllSavedSearch } from 'src/store/modules/segments/operations';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

const DeleteBusinessUnitDialogue = styled(GenericStyledDialogue)({
  '& .MuiPaper-root': {
    width: '500px',
    height: '180px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px'
  }
});

export const BusinessUnitsDeletionModal = ({
  businessUnitToDelete,
  open,
  handleClose
}: {
  businessUnitToDelete: BusinessUnit;
  open: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useDispatch();

  const handleDeleteClick = async () => {
    const { id } = businessUnitToDelete;
    handleClose();
    await dispatch(deleteRequestedSavedSearch('beacon', id));
    dispatch(fetchAllSavedSearch());
  };

  return (
    <DeleteBusinessUnitDialogue open={open} onClose={handleClose}>
      <SlColumn widths={[undefined, undefined, 'full']} spacing="md" verticalInset="mdl" horizontalInset="mdl">
        <SlRow>
          <Text variant="h4">Delete Business Unit</Text>
        </SlRow>
        <Text variant="body2">
          Are you sure you want to delete this business unit? Once this business unit is deleted, it cannot be
          recovered.
        </Text>
        <SlRow spacing="md" horizontalPosition="end">
          <SlButton onClick={handleClose}>Cancel</SlButton>
          <SlButton onClick={handleDeleteClick} variant="contained">
            Delete
          </SlButton>
        </SlRow>
      </SlColumn>
    </DeleteBusinessUnitDialogue>
  );
};
