/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';

import { store } from 'src/main';
import * as adBudgetEditorActions from 'src/store/modules/adManager/adBudgetEditor/actions';
import { getTimelineOpt } from 'src/store/modules/adManager/adBudgetEditor/selectors';
import {
  SectionWrapper,
  OperationButtons
} from 'src/components/AdCampaignBuilder/Widgets/AdCampaignBuilderCommonWidgets';
import ReduxStore from 'src/types/store/reduxStore';
import { AdCampaignBuilderRadio } from 'src/components/AdCampaignBuilder/Widgets/Checkbox';
import './BudgetEditor.scss';

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, ['adBudgetEditor']),
    timelineOpt: getTimelineOpt(state.retailer)
  };
};

const BudgetPeriod: React.FC<
  {
    timelineOpt: {
      id: number;
      label: string;
      displayName: string;
      disc: string;
    }[];
  } & ReturnType<typeof mapStateToProps>
> = ({ timelineOpt, adBudgetEditor }) => {
  const { timeline: selectedTimeline } = adBudgetEditor;

  if (_isEmpty(adBudgetEditor.platform)) {
    return null;
  }

  const setTimeline = (value: object) => store.dispatch(adBudgetEditorActions.setTimeline(value));

  return (
    <div className="ad-manager-container">
      <OperationButtons subtab="budgetPeriod" canContinue={!!selectedTimeline} customFlow={adBudgetEditor.flow} />
      <SectionWrapper header="Budget Time Period" subheader="Select the time period for this budget." layer={0}>
        <div className="platform-select">
          {timelineOpt.map((timeline) => (
            <div key={timeline.id} className="timeline_box">
              <AdCampaignBuilderRadio
                disableRipple
                checked={selectedTimeline.id === timeline.id}
                onChange={() => setTimeline(timeline)}
                value={timeline}
                style={{ marginLeft: -12 }}
              />
              <label className="radio-label">{timeline.label}</label>
              <div className="timeline_displayName">{timeline.displayName}</div>
              <div>{timeline.disc}</div>
            </div>
          ))}
        </div>
      </SectionWrapper>
    </div>
  );
};

const EnhancedBudgetPeriod = connect(mapStateToProps)(BudgetPeriod);

export default EnhancedBudgetPeriod;
