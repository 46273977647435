import React, { Dispatch, SetStateAction, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { Box, Checkbox, Divider, FormControlLabel, Radio } from '@mui/material';
import { useStacklineTheme, SlColumn, SlInput, SlRow, Text } from '@stackline/ui';
import { CloseIcon, SelectedIncludeCheckbox, SmallCancelIcon } from 'src/components/SvgIcons/SvgIcons';
import { useAppSelector } from 'src/utils/Hooks';
import { BusinessUnit, Segment } from 'sl-api-connector';
import { useDispatch } from 'react-redux';
import { updateSavedSearch } from 'src/store/modules/segments/operations';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { FilterSearchInput } from 'src/components/BeaconRedesignComponents/ProductGrowthModal/ModalComponents/FilterSearchInput';
import ScrollableContainer from 'src/components/BeaconRedesignComponents/ScrollableContainer';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

const GenericStyledDialogue = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '752px',
    height: '641px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px'
  }
});

const BusinessUnitCreationError = ({ message }: { message: string }) => {
  return (
    <SlRow spacing="xs" verticalPosition="center">
      <div style={{ height: '19px' }}>
        <SmallCancelIcon style={{ width: '20px', height: '20px' }} />
      </div>
      <Text variant="subtitle3" color="error">
        {message}
      </Text>
    </SlRow>
  );
};

// Modal Header
const BusinessUnitsModalHeader = ({ handleClose }: { handleClose: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <>
      <Flex justifyContent="center" alignItems="center" paddingX="48px" paddingY="18px" position="relative">
        <Text variant="h2" textAlign="center">
          Business Unit Builder
        </Text>
        <CloseIcon
          onClick={handleClose}
          style={{ cursor: 'pointer', width: '24px', height: '24px', position: 'absolute', right: 24 }}
        ></CloseIcon>
      </Flex>
      <Divider />
    </>
  );
};

// Modal Footer
const BusinessUnitsModalFooter = ({
  handleClose,
  handleSubmit,
  disableSubmit
}: {
  handleClose: Dispatch<SetStateAction<boolean>>;
  handleSubmit: () => void;
  disableSubmit: boolean;
}) => {
  return (
    <>
      <Divider />
      <Flex justifyContent="space-between" alignItems="center" paddingX="48px" paddingY="20px">
        <SlButton onClick={handleClose}>Clear</SlButton>
        <SlButton onClick={handleSubmit} disabled={disableSubmit} variant="contained">
          Save
        </SlButton>
      </Flex>
    </>
  );
};

const TopLevelRadioButton = ({
  handleRadioButtonClick,
  isTopLevelChecked
}: {
  handleRadioButtonClick: () => void;
  isTopLevelChecked: boolean;
}) => {
  const theme = useStacklineTheme();
  return (
    <FormControlLabel
      title="Top Level"
      sx={{
        color: theme.colors.primary,
        minWidth: '190px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginLeft: '0px',
        marginRight: '0px',
        gap: '8px',
        '& .MuiTypography-root': {
          width: '160px',
          fontWeight: '500',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }}
      checked={isTopLevelChecked}
      label="Top Level"
      value="id"
      control={
        <Radio
          disableRipple
          disableTouchRipple
          sx={{
            svg: {
              height: '15px',
              width: '15px'
            },
            '&, &.Mui-checked': {
              color: theme.colors.primary,
              padding: '0px'
            }
          }}
        />
      }
      onClick={(event) => {
        event.preventDefault(); // Prevent event firing twice from label click
        handleRadioButtonClick();
      }}
    />
  );
};

/**
 * How many more options to show per "Show more" click & also determines the default number of options shown
 */
const MAX_OPTIONS_SHOWN = 6;
/**
 * A component containing our segment options
 */
const AssignSegmentOptions = ({ options, onSelect }: { options: SegmentOption[]; onSelect: (e: Event) => void }) => {
  const theme = useStacklineTheme();

  // Pagination for "Show more" button
  const [optionsPage, setOptionsPage] = useState(1);
  const [input, setInput] = useState('');

  const showMoreOptions = () => {
    setOptionsPage(optionsPage + 1);
  };

  const handleInputChange = (s: string) => {
    setInput(s);
  };

  const availableOptions =
    input.length > 0
      ? options.filter((option) => option.displayName.toLowerCase().includes(input.toLowerCase()))
      : options.slice(0, optionsPage * MAX_OPTIONS_SHOWN);

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" marginBottom="82px">
      <Flex marginTop="16px" width="100%" justifyContent="flex-start">
        <FilterSearchInput
          input={input}
          handleChange={handleInputChange}
          placeholderText="Search segments"
          sx={{
            '& input::placeholder': {
              fontSize: '14px'
            }
          }}
        />
      </Flex>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, auto)',
          marginTop: '24px',
          color: theme.colors.primary,
          justifyContent: 'space-between',
          rowGap: '12px',
          columnGap: '30px'
        }}
      >
        <>
          {availableOptions
            ? availableOptions.map((option) => (
                <FormControlLabel
                  title={option.label}
                  sx={{
                    gap: '8px',
                    height: '24px',
                    color: theme.colors.primary,
                    minWidth: '190px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginLeft: '0px',
                    marginRight: '0px',
                    '& .MuiTypography-root': {
                      width: '160px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }
                  }}
                  checked={option.isSelected}
                  label={option.label}
                  value={option.id}
                  control={
                    <Checkbox
                      checkedIcon={<SelectedIncludeCheckbox style={{ width: '14px', height: '14px' }} />}
                      icon={
                        <div
                          style={{
                            width: '14px',
                            height: '14px',
                            border: '1px solid #9ea9b5',
                            backgroundColor: '#fff',
                            borderRadius: '2px'
                          }}
                        ></div>
                      }
                      disableRipple
                      disableTouchRipple
                      sx={{
                        '&, &.Mui-checked': {
                          color: theme.colors.primary,
                          padding: '0'
                        }
                      }}
                    />
                  }
                  key={option.id}
                  onChange={(e) => onSelect(e)}
                />
              ))
            : null}
          {/* Show more button */}
        </>
      </div>
      <Box marginTop="24px">
        <button style={{ border: 'none', background: 'none', padding: '0' }} onClick={showMoreOptions}>
          <Text variant="subtitle2" underlined>
            Show more
          </Text>
        </button>
      </Box>
    </Box>
  );
};

type BusinessUnitOption = BusinessUnit & {
  isSelected: boolean;
  label: string;
};

type SegmentOption = Segment & {
  isSelected: boolean;
  label: string;
};

interface OptionNone {
  label: string;
  id: null;
  children: [];
}

// TODO: Add ability to add Business Units to other Business Units (similar to segment options) once Designs are updated

const BusinessUnitsCreationModalInner = ({
  businessUnitToEdit,
  handleClose
}: {
  /**
   * An optional business unit that has been selected to be edited.
   * If provided, we prefill all of the modal state as necessary from it.
   */
  businessUnitToEdit: BusinessUnit;
  handleClose: () => void;
}) => {
  const { businessUnits, mySegments, teamSegments, savedSearchesById } = useAppSelector((state) => state.segments);
  const dispatch = useDispatch();
  const theme = useStacklineTheme();
  // Format our business units to be used in our dropdown menu
  let businessUnitOptions: (BusinessUnitOption | OptionNone)[] = businessUnits
    .map((businessUnit) => ({
      ...businessUnit,
      isSelected: false,
      label: businessUnit.displayName
    }))
    .filter((option) => option.id !== (businessUnitToEdit ? businessUnitToEdit.id : null)); // Do not allow a BU to be a child of itself

  const optionNone: OptionNone = { label: 'None', id: null, children: [] };

  businessUnitOptions = [...businessUnitOptions, optionNone];

  // Merge our segments and our team segments into one collection and then create our checkbox options
  const defaultSegmentOptions: SegmentOption[] = [...mySegments, ...teamSegments].map((segment) => ({
    ...segment,
    isSelected: businessUnitToEdit ? businessUnitToEdit.children.includes(segment.id) : false,
    label: segment.displayName
  }));

  // Form state
  // Business Unit Name
  const [businessUnitName, setBusinessUnitName] = useState(businessUnitToEdit ? businessUnitToEdit.displayName : '');
  // "Top level" toggle state
  const [isTopLevelChecked, setIsTopLevelChecked] = useState(
    businessUnitToEdit ? businessUnitToEdit.isTopLevel : false
  );

  const existingParentBusinessUnitOption = businessUnitToEdit
    ? businessUnitOptions.find((businessUnit: BusinessUnitOption) =>
        businessUnit.children.includes(businessUnitToEdit.id)
      )
    : null;
  // Parent Business Unit Assigned
  const [selectedParentBusinessUnit, setSelectedParentBusinessUnit] = useState<BusinessUnitOption | OptionNone>(
    existingParentBusinessUnitOption || optionNone
  );

  // Segment options
  const [segmentOptions, setSegmentOptions] = useState(defaultSegmentOptions);

  // Possible Error States
  // Error state when attempting to assign a parent BU and also trying to set the BU as top level
  const [topLevelAndParentErrorOpen, setTopLevelAndParentErrorOpen] = useState(false);

  // Error state when inputting a name that already exists
  const [nameCollisionErrorOpen, setNameCollisionErrorOpen] = useState(false);

  // Form Handlers

  /**
   * Handles business unit name input change
   */
  const handleBusinessUnitNameChange = (s: string) => {
    setNameCollisionErrorOpen(false);
    setBusinessUnitName(s);
  };

  /**
   * Handles Top Level radio button click
   */
  const handleRadioButtonClick = () => {
    const { id } = selectedParentBusinessUnit;

    // If we have a valid BU selected to be the parent, we shouldn't allow the user to make the new BU top level.
    if (id) {
      setTopLevelAndParentErrorOpen(true);
    } else {
      setTopLevelAndParentErrorOpen(false);
      setIsTopLevelChecked(!isTopLevelChecked);
    }
  };

  /**
   * Handles parent business unit dropdown option selection
   */
  const handleDropdownChange = (selectedBusinessUnit: BusinessUnitOption) => {
    setTopLevelAndParentErrorOpen(false);
    setSelectedParentBusinessUnit(selectedBusinessUnit);
  };

  /**
   * Handles segment selection
   */
  const handleSegmentSelection = (e: Event) => {
    const { value } = e.target;
    const newSegmentOptions = segmentOptions.map((segmentOption) => ({
      ...segmentOption,
      isSelected: value === segmentOption.id ? !segmentOption.isSelected : segmentOption.isSelected
    }));
    setSegmentOptions(newSegmentOptions);
  };

  /**
   * Handles clearing all BU creation inputs
   */
  const handleClearInputs = () => {
    // Clear form states
    setBusinessUnitName('');
    setSelectedParentBusinessUnit(optionNone);
    setIsTopLevelChecked(false);

    // Clear any errors
    setTopLevelAndParentErrorOpen(false);
    setNameCollisionErrorOpen(false);

    const newSegmentOptions = segmentOptions.map((segmentOption) => ({
      ...segmentOption,
      isSelected: false
    }));
    setSegmentOptions(newSegmentOptions);
  };

  const checkValidName = () => {
    // We have a name but we still need to check for collisions
    if (businessUnitName && businessUnitName.length > 0 && businessUnitName.trim() !== '') {
      const potentialBusinessUnitId = businessUnitToEdit ? businessUnitToEdit.id : null;
      const collisionFound = !!Array.from(savedSearchesById.values()).find(
        ({ displayName, id }) =>
          displayName.toLowerCase() === businessUnitName.toLowerCase() && potentialBusinessUnitId !== id
      );

      // We only want to flag collisions if we're not editing a Business Unit
      if (collisionFound) {
        setNameCollisionErrorOpen(true);
        return false;
      } else {
        setNameCollisionErrorOpen(false);
        return true;
      }
    } else {
      return false;
    }
  };

  /**
   * Contains validation logic for BU Creation and Editing
   * @returns true if valid, false if invalid
   */
  const validateForm = () => {
    const isValidName = checkValidName();

    return isValidName;
  };

  /**
   * Handle BU Creation submission
   */
  const handleSubmit = async () => {
    setBusinessUnitName(businessUnitName.replace(/\s+/g, ' ').trim()); // Trim white space

    if (validateForm()) {
      const selectedSegmentIds = segmentOptions
        .filter((segment) => segment.isSelected)
        .map((selectedSegment) => selectedSegment.id);

      const allBusinessUnitIds = businessUnits.map(({ id }) => id);

      // We want to include existing BUs that are under the BU we're editing but we only want the segments that we've selected
      const allowedBusinessUnitIds = businessUnitToEdit
        ? businessUnitToEdit.children.filter((potentialBusinessUnitId) => {
            return allBusinessUnitIds.includes(potentialBusinessUnitId);
          })
        : [];

      const uniqueSegmentAndBusinessUnitIds = Array.from(new Set([...selectedSegmentIds, ...allowedBusinessUnitIds]));

      const response = await dispatch(
        updateSavedSearch(
          'businessunit',
          {
            children: uniqueSegmentAndBusinessUnitIds, // Collection of selected segment/BU IDs
            dn: businessUnitName,
            isTopLevel: isTopLevelChecked
          },
          businessUnitToEdit ? businessUnitToEdit.id : undefined, // This is undefined if building a new BU and set if we're editing
          businessUnitToEdit ? businessUnitToEdit.userId : undefined
        )
      );

      // Successful BU creation. "Data" in this case will be a new saved search ID.
      if (response && response.data) {
        // Set BU as a child to a parent BU if applicable
        // If we have selected a parent BU, we will have a valid ID. If we've selected 'None', the ID will be null.
        const { id, displayName, children, isTopLevel, userId } = selectedParentBusinessUnit as BusinessUnit;
        if (id) {
          const newlyCreatedBusinessUnitId = response.data;
          dispatch(
            updateSavedSearch(
              'businessunit',
              {
                children: [...children, newlyCreatedBusinessUnitId],
                dn: displayName,
                isTopLevel
              },
              id,
              userId
            )
          );
        }
      }
      handleClose();
      return null;
    } else {
      return null;
    }
  };

  const handleBlur = () => {
    setBusinessUnitName(businessUnitName.replace(/\s+/g, ' ').trim());
  };

  /**
   * Require a name for the BU + at least 1 selected segment
   */
  const disableSubmit =
    businessUnitName.length === 0 ||
    businessUnitName.trim() === '' ||
    segmentOptions.length === 0 ||
    segmentOptions.filter((option) => option.isSelected).length === 0;

  return (
    <>
      {/* header */}
      <BusinessUnitsModalHeader clearSelection={handleClearInputs} handleClose={handleClose} />
      {/* content container */}
      <ScrollableContainer sx={{ height: '500px', overflow: 'scroll', overflowX: 'hidden' }} scrollbarWidth="5px">
        {/* horizontally inset content */}
        <SlColumn widths="full" horizontalInset="xl" verticalInset="mdl">
          <SlColumn spacing="sm">
            <Text variant="subtitle2">Create a Business Unit</Text>
            <Text variant="body2">Aggregate multiple segments by creating a business unit. </Text>
          </SlColumn>
          {/* Name Input */}
          <Flex position="relative" flexDirection="column" paddingTop={theme.spacing.md} gap="sm">
            <Text variant="subtitle2">Name</Text>
            <SlInput
              value={businessUnitName}
              handleChange={handleBusinessUnitNameChange}
              placeholder="Name your business unit"
              showCharacterCount
              maxLength={100}
              width="100%"
              onBlur={handleBlur} // Don't allow excessive whitespace between chars
              sx={{
                '& input': {
                  '&::placeholder': {
                    color: theme.colors.secondary
                  }
                }
              }}
            />
            {nameCollisionErrorOpen && (
              <Box position="absolute" top="78px">
                <BusinessUnitCreationError message="A segment or business unit with the same name already exists. Please try again." />
              </Box>
            )}
          </Flex>
          {/* Top Level Radio Button */}
          <Flex flexDirection="column" marginTop={theme.spacing.sm}>
            <SlColumn spacing="sm">
              <Text variant="subtitle2">Set as Top Level</Text>
              <Text variant="body2">
                Make this business unit and the segments housed under it accessible as a top-level filter option.
              </Text>
            </SlColumn>
            <Flex flexDirection="column" marginTop="12px" gap="xs">
              <TopLevelRadioButton
                isTopLevelChecked={isTopLevelChecked}
                handleRadioButtonClick={handleRadioButtonClick}
              />
              <SlColumn horizontalInset="mdl">
                <Text variant="body3">
                  The segments assigned to a top level business unit are visible by default on the platform’s filter
                  window.
                </Text>
              </SlColumn>
            </Flex>
          </Flex>
          {/* Dropdown Menu */}
          <SlColumn verticalInset="mdl" spacing="sm" widths="full">
            <Text variant="subtitle2">Assign Parent Business Unit</Text>
            <SlDropdownMenu
              disabled={isTopLevelChecked}
              onChange={handleDropdownChange}
              options={businessUnitOptions}
              selectedId={selectedParentBusinessUnit.id}
              defaultLabel={selectedParentBusinessUnit.label}
              menuStyles={{ maxWidth: '160px' }}
              stacklineButtonProps={{
                textSx: {
                  fontWeight: 'normal'
                },
                buttonSx: {
                  width: '104px',
                  justifyContent: 'space-between'
                }
              }}
            />
            {topLevelAndParentErrorOpen && (
              <BusinessUnitCreationError message="You cannot set a business unit as a top level and also assign a parent. Please try again." />
            )}
          </SlColumn>
          {/* Segment Options */}
          <SlColumn widths="full">
            <Text variant="subtitle2">Assign Segments</Text>
            <AssignSegmentOptions onSelect={handleSegmentSelection} options={segmentOptions} />
          </SlColumn>
        </SlColumn>
      </ScrollableContainer>
      {/* footer */}
      <BusinessUnitsModalFooter handleClose={handleClose} handleSubmit={handleSubmit} disableSubmit={disableSubmit} />
    </>
  );
};

export const BusinessUnitsCreationModal = ({
  businessUnitToEdit = null,
  open,
  handleClose
}: {
  businessUnitToEdit?: BusinessUnit | null;
  open: boolean;
  handleClose: () => void;
}) => {
  return (
    <GenericStyledDialogue open={open} onClose={handleClose}>
      <BusinessUnitsCreationModalInner businessUnitToEdit={businessUnitToEdit} handleClose={handleClose} />
    </GenericStyledDialogue>
  );
};
