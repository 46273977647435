import { AppName, ValueOf } from 'sl-api-connector/types';

import actions from './actions';
import types from './types';
import currentRetailer from './selectors';
import { RetailerOrderType } from 'src/types/store/reduxStore';

const reviewAverage = { min: 0, max: 5 };
const contentScore = {
  aggregationNames: ['titleScore', 'bulletScore', 'aplusScore', 'imageScore', 'videoScore'],
  categoryNames: ['Title', 'Bullet', 'A Plus', 'Image', 'Video']
};

const contentScoreWithoutAPlus = {
  aggregationNames: contentScore.aggregationNames.filter((name) => name !== 'aplusScore'),
  categoryNames: contentScore.categoryNames.filter((name) => name !== 'A Plus')
};

// List of supported locales: https://github.com/adamwdraper/Numeral-js/tree/master/locales
// Amazon UAE(id: '53') and KSA(id: '72') have an optional property 'isRTLLanguage'. use this property in number input override.
export const availableRetailers = [
  {
    id: '1',
    name: 'amazon',
    displayName: 'Amazon US',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Omni, AppName.Discover],
    searchUrl: 'https://www.amazon.com/s?k=',
    skuUrl: 'https://www.amazon.com/dp/',
    countryCode: 'US',
    countryDisplayName: 'United States',
    orderTypes: [RetailerOrderType.SHIPPING]
  },
  {
    id: '0',
    name: 'allRetailers',
    displayName: 'All Retailers',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore,
    dataTypesAvailable: [],
    supportedAppNames: [AppName.Beacon, AppName.Advertising]
  },
  {
    id: '11',
    name: 'Amazon Canada',
    displayName: 'Amazon Canada',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising],
    searchUrl: 'https://www.amazon.ca/s?k=',
    skuUrl: 'https://www.amazon.ca/dp/'
  },
  {
    id: '17',
    name: 'Amazon UK',
    displayName: 'Amazon UK',
    type: 'retailer',
    currencySymbol: '£',
    locale: 'en-gb',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Discover],
    searchUrl: 'https://www.amazon.co.uk/s?k=',
    skuUrl: 'https://www.amazon.co.uk/dp/',
    countryCode: 'GB',
    countryDisplayName: 'United Kingdom'
  },
  {
    id: '14',
    name: 'Amazon Germany',
    displayName: 'Amazon Germany',
    type: 'retailer',
    currencySymbol: '€',
    locale: 'de',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Discover],
    searchUrl: 'https://www.amazon.de/s?k=',
    skuUrl: 'https://www.amazon.de/dp/',
    countryCode: 'DE',
    countryDisplayName: 'Germany'
  },
  {
    id: '15',
    name: 'Amazon France',
    displayName: 'Amazon France',
    type: 'retailer',
    currencySymbol: '€',
    locale: 'fr',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Discover],
    searchUrl: 'https://www.amazon.fr/s?k=',
    skuUrl: 'https://www.amazon.fr/dp/'
  },
  {
    id: '20',
    name: `Asda`,
    displayName: `Asda`,
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'GB',
    countryDisplayName: 'United Kingdom',
    currencySymbol: '£'
  },
  {
    id: '29',
    name: 'Amazon Italy',
    displayName: 'Amazon Italy',
    type: 'retailer',
    currencySymbol: '€',
    locale: 'it',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Discover],
    searchUrl: 'https://www.amazon.it/s?k=',
    skuUrl: 'https://www.amazon.it/dp/'
  },
  {
    id: '30',
    name: 'Amazon Spain',
    displayName: 'Amazon Spain',
    type: 'retailer',
    currencySymbol: '€',
    locale: 'es-es',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Discover],
    searchUrl: 'https://www.amazon.es/s?k=',
    skuUrl: 'https://www.amazon.es/dp/'
  },
  {
    id: '2',
    name: 'walmart',
    displayName: 'Walmart US',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising],
    searchUrl: 'https://www.walmart.com/search/?query=',
    skuUrl: 'https://www.walmart.com/ip/',
    countryCode: 'US',
    countryDisplayName: 'United States',
    orderTypes: [RetailerOrderType.DELIVERY, RetailerOrderType.PICKUP, RetailerOrderType.SHIPPING]
  },
  {
    id: '12',
    name: 'Walmart Canada',
    displayName: 'Walmart Canada',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising],
    searchUrl: 'https://www.walmart.ca/search/?q=',
    skuUrl: 'https://www.walmart.ca/ip/'
  },
  {
    id: '49',
    name: 'Walmart OPD',
    displayName: 'Walmart OPD',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.walmart.com/grocery/search/?query=',
    skuUrl: 'https://www.walmart.com/grocery/ip/',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '3',
    name: 'Best Buy',
    displayName: 'Best Buy US',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising],
    searchUrl: 'https://www.bestbuy.com/site/searchpage.jsp?st=',
    skuUrl: 'https://www.bestbuy.com/site/searchpage.jsp?st=',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '4',
    name: 'Target',
    displayName: 'Target',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising],
    searchUrl: 'https://www.target.com/s?searchTerm=',
    skuUrl: 'https://www.target.com/s?searchTerm=',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '5',
    name: 'staples',
    displayName: 'Staples',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    supportedAppNames: [AppName.Omni, AppName.Advertising],
    countryCode: 'US',
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales']
  },
  {
    id: '6',
    name: 'Home Depot',
    displayName: 'Home Depot',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.homedepot.com/s/',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },

  {
    id: '38',
    name: 'office depot',
    displayName: 'Office Depot',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },

  {
    id: '31',
    name: 'Amazon Australia',
    displayName: 'Amazon Australia',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en-au',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Discover],
    searchUrl: 'https://www.amazon.com.au/s?k=',
    skuUrl: 'https://www.amazon.com.au/dp/'
  },
  {
    id: '33',
    name: 'Amazon Brazil',
    displayName: 'Amazon Brazil',
    type: 'retailer',
    currencySymbol: 'R$',
    locale: 'pt-br',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Discover],
    searchUrl: 'https://www.amazon.com.br/s?k=',
    skuUrl: 'https://www.amazon.com.br/dp/'
  },
  {
    id: '34',
    name: 'Amazon China',
    displayName: 'Amazon China',
    type: 'retailer',
    currencySymbol: '¥',
    locale: 'chs',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.amazon.cn/s?k=',
    skuUrl: 'https://www.amazon.cn/dp/'
  },
  {
    id: '28',
    name: 'Amazon India',
    displayName: 'Amazon India',
    type: 'retailer',
    currencySymbol: '₹',
    locale: 'en',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Discover],
    searchUrl: 'https://www.amazon.in/s?k=',
    skuUrl: 'https://www.amazon.in/dp/'
  },
  {
    id: '53',
    name: 'Amazon UAE',
    displayName: 'Amazon UAE',
    type: 'retailer',
    currencySymbol: 'د.إ',
    locale: 'en',
    isRTLLanguage: true,
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Discover],
    searchUrl: 'https://www.amazon.ae/s?k=',
    skuUrl: 'https://www.amazon.ae/dp/'
  },
  {
    id: '16',
    name: 'Amazon Japan',
    displayName: 'Amazon Japan',
    type: 'retailer',
    currencySymbol: '¥',
    locale: 'ja',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Discover],
    searchUrl: 'https://www.amazon.co.jp/s?k=',
    skuUrl: 'https://www.amazon.co.jp/dp/'
  },
  {
    id: '32',
    name: 'Amazon Mexico',
    displayName: 'Amazon Mexico',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'es',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising],
    searchUrl: 'https://www.amazon.com.mx/s?k=',
    skuUrl: 'https://www.amazon.com.mx/dp/'
  },
  {
    id: '62',
    name: 'Amazon Netherlands',
    displayName: 'Amazon Netherlands',
    type: 'retailer',
    currencySymbol: '€',
    locale: 'nl-nl',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Discover],
    searchUrl: 'https://www.amazon.nl/s?k=',
    skuUrl: 'https://www.amazon.nl/dp/'
  },
  {
    id: '72',
    name: 'Amazon KSA',
    displayName: 'Amazon KSA',
    type: 'retailer',
    currencySymbol: 'ر.س.‏',
    locale: 'en',
    reviewAverage,
    isRTLLanguage: true,
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Discover],
    searchUrl: 'https://www.amazon.sa/s?k=',
    skuUrl: 'https://www.amazon.sa/dp/'
  },
  {
    id: '77',
    name: 'Amazon Sweden',
    displayName: 'Amazon Sweden',
    type: 'retailer',
    currencySymbol: 'kr',
    locale: 'sv-se',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising, AppName.Discover],
    searchUrl: 'https://www.amazon.se/s?k=',
    skuUrl: 'https://www.amazon.se/dp/'
  },
  {
    id: '74',
    name: 'Amazon Turkey',
    displayName: 'Amazon Turkey',
    type: 'retailer',
    currencySymbol: '₺',
    locale: 'tr',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising],
    searchUrl: 'https://www.amazon.com.tr/s?k=',
    skuUrl: 'https://www.amazon.com.tr/dp/'
  },
  {
    id: '18',
    name: 'chewy',
    displayName: 'Chewy',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.chewy.com/s?query=',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '37',
    name: 'NewEgg.com',
    displayName: 'NewEgg.com',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },

  {
    id: '35',
    name: 'flipkart',
    displayName: 'Flipkart',
    type: 'retailer',
    currencySymbol: '₹',
    locale: 'en-gb',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.flipkart.com/search?q='
  },
  {
    id: '26',
    name: 'jd',
    displayName: 'JD.com',
    type: 'retailer',
    currencySymbol: '¥',
    locale: 'chs',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://search.jd.com/Search?keyword='
  },
  {
    id: '43',
    name: 'Amazon Fresh',
    displayName: 'Amazon Fresh',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Omni],
    searchUrl: 'https://www.amazon.com/',
    skuUrl: 'https://www.amazon.com/',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '48',
    name: 'kroger',
    displayName: 'Kroger',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.kroger.com/search?query=',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '50',
    name: 'sephora',
    displayName: 'Sephora',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.sephora.com/search?keyword=',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },

  {
    id: '58',
    name: 'Whole Foods',
    displayName: 'Whole Foods',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Omni],
    searchUrl: 'https://www.wholefoodsmarket.com/product/',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },

  {
    id: '59',
    name: 'Mercado Libre Brazil',
    displayName: 'Mercado Libre Brazil',
    type: 'retailer',
    currencySymbol: 'R$',
    locale: 'pt-br',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://lista.mercadolivre.com.br/'
  },

  {
    id: '56',
    name: 'Lazada Singapore',
    displayName: 'Lazada Singapore',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.lazada.sg/catalog/?q='
  },
  {
    id: '57',
    name: 'Lazada Philippines',
    displayName: 'Lazada Philippines',
    type: 'retailer',
    currencySymbol: '₱',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.lazada.com.ph/catalog/?q='
  },
  {
    id: '63',
    name: 'Instacart',
    displayName: 'Instacart US',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising],
    searchUrl: 'https://www.instacart.com/store/s?k=',
    skuUrl: 'https://www.instacart.com/products/',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '73',
    name: 'Lowes',
    displayName: 'Lowes US',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising],
    searchUrl: 'https://www.lowes.com/pd',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '75',
    name: 'Mercado Libre Mexico',
    displayName: 'Mercado Libre Mexico',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'es-mx',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://listado.mercadolivre.com.mx/'
  },
  {
    id: '22',
    name: 'Ocado',
    displayName: 'Ocado',
    type: 'retailer',
    currencySymbol: '£',
    locale: 'en-gb',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.ocado.com/search?entry='
  },
  {
    id: '24',
    name: 'Tesco',
    displayName: 'Tesco',
    type: 'retailer',
    currencySymbol: '£',
    locale: 'en-gb',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.tesco.com/groceries/en-GB/search?query='
  },
  {
    id: '7',
    name: 'Wayfair',
    displayName: 'Wayfair',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.wayfair.com/keyword.php?keyword=',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '78',
    name: 'Shopee Malaysia',
    displayName: 'Shopee Malaysia',
    type: 'retailer',
    currencySymbol: 'RM',
    locale: 'ms-my',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://shopee.com.my/search?keyword='
  },
  {
    id: '79',
    name: 'Shopee Vietnam',
    displayName: 'Shopee Vietnam',
    type: 'retailer',
    currencySymbol: '₫',
    locale: 'vi',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://shopee.vn/search?keyword='
  },
  {
    id: '80',
    name: 'Shopee Philippines',
    displayName: 'Shopee Philippines',
    type: 'retailer',
    currencySymbol: '₱',
    locale: 'en-gb',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://shopee.ph/search?keyword='
  },
  {
    id: '81',
    name: 'Shopee Indonesia',
    displayName: 'Shopee Indonesia',
    type: 'retailer',
    currencySymbol: 'Rp',
    locale: 'id',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Omni],
    searchUrl: 'https://shopee.co.id/search?keyword=',
    countryCode: 'ID',
    countryDisplayName: 'Indonesia'
  },
  {
    id: '82',
    name: 'Shopee Singapore',
    displayName: 'Shopee Singapore',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en-gb',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://shopee.sg/search?keyword='
  },
  {
    id: '83',
    name: 'Shopee Thailand',
    displayName: 'Shopee Thailand',
    type: 'retailer',
    currencySymbol: '฿',
    locale: 'en-gb',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://shopee.co.th/search?keyword='
  },
  {
    id: '84',
    name: 'Shoppers Drug Mart',
    displayName: 'Shoppers Drug Mart',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales', 'traffic'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://shop.shoppersdrugmart.ca/search?text='
  },
  {
    id: '89',
    name: 'Lazada Malaysia',
    displayName: 'Lazada Malaysia',
    type: 'retailer',
    currencySymbol: 'RM',
    locale: 'ms-my',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.lazada.com.my/catalog/?q='
  },
  {
    id: '90',
    name: 'Amazon Poland',
    displayName: 'Amazon Poland',
    type: 'retailer',
    currencySymbol: 'zł',
    locale: 'pl_PL',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising],
    searchUrl: 'https://www.amazon.pl/s?k=',
    skuUrl: 'https://www.amazon.pl/dp/'
  },
  {
    id: '91',
    name: 'Meijer',
    displayName: 'Meijer',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising],
    searchUrl: 'https://www.meijer.com/shopping/search.html?text=',
    skuUrl: 'https://www.meijer.com/shopping/product/p/',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '99',
    name: 'Lazada Thailand',
    displayName: 'Lazada Thailand',
    type: 'retailer',
    currencySymbol: '฿',
    locale: 'th',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon],
    searchUrl: 'https://www.lazada.co.th/catalog/?ajax=true&q='
  },
  {
    id: '100',
    name: 'Instacart Canada',
    displayName: 'Instacart Canada',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Advertising],
    searchUrl: 'https://www.instacart.com/store/search_v3',
    skuUrl: 'https://www.instacart.com/products/'
  },
  {
    id: '93',
    name: 'QFC',
    displayName: 'QFC',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    dataTypesAvailable: 'sales',
    supportedAppNames: [AppName.Omni],
    searchUrl: 'https://www.qfc.com',
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  //  INSTACART RETAILERS
  {
    id: '63_1',
    name: 'Instacart Safeway',
    displayName: 'Instacart Safeway',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '63_648',
    name: 'Instacart Target',
    displayName: 'Instacart Target',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_58',
    name: 'Instacart Kroger',
    displayName: 'Instacart Kroger',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_133',
    name: 'Instacart Food Lion',
    displayName: 'Instacart Food Lion',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_57',
    name: 'Instacart Publix',
    displayName: 'Instacart Publix',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_1487',
    name: 'Instacart Walmart',
    displayName: 'Instacart Walmart',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_231',
    name: 'Instacart Wegmans',
    displayName: 'Instacart Wegmans',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_26',
    name: "Instacart BJ's Wholesale Club",
    displayName: "Instacart BJ's Wholesale Club",
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_352',
    name: "Instacart Sam's Club",
    displayName: "Instacart Sam's Club",
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_42',
    name: 'Instacart QFC',
    displayName: 'Instacart QFC',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_12',
    name: 'Instacart ALDI',
    displayName: 'Instacart ALDI',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_5',
    name: 'Instacart Costco',
    displayName: 'Instacart Costco',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_45',
    name: 'Instacart H-E-B',
    displayName: 'Instacart H-E-B',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_233',
    name: 'Instacart Albertsons',
    displayName: 'Instacart Albertsons',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_38',
    name: 'Instacart Ralphs',
    displayName: 'Instacart Ralphs',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_375',
    name: 'Instacart Shaw’s',
    displayName: 'Instacart Shaw’s',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '92',
    name: 'Safeway',
    displayName: 'Safeway',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '63_279',
    name: 'Instacart Sprouts Farmers Market',
    displayName: 'Instacart Sprouts Farmers Market',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_144',
    name: 'Instacart Total Wine & More',
    displayName: 'Instacart Total Wine & More',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_128',
    name: `Instacart Pick 'n Save`,
    displayName: `Instacart Pick 'n Save`,
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_10',
    name: 'Instacart Jewel-Osco',
    displayName: 'Instacart Jewel-Osco',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_44',
    name: 'Instacart ACME Markets',
    displayName: 'Instacart ACME Markets',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_235',
    name: 'Instacart Stop & Shop',
    displayName: 'Instacart Stop & Shop',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_201',
    name: `Instacart Fry's`,
    displayName: `Instacart Fry's`,
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_56',
    name: 'Instacart King Soopers',
    displayName: 'Instacart King Soopers',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_252',
    name: `Instacart Smith's`,
    displayName: `Instacart Smith's`,
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '63_290',
    name: `Instacart Vons`,
    displayName: `Instacart Vons`,
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '94',
    name: 'Albertsons',
    displayName: 'Albertsons',
    type: 'retailer',
    supportedAppNames: [AppName.Omni, AppName.Advertising],
    currencySymbol: '$',
    countryCode: 'US',
    countryDisplayName: 'United States',
    locale: 'en',
    searchUrl: 'https://www.albertsons.com/shop/search-results.html?q=',
    skuUrl: 'https://www.albertsons.com/shop/search-results.html?q='
  },
  {
    id: '98',
    name: 'Petco',
    displayName: 'Petco',
    type: 'retailer',
    supportedAppNames: [AppName.Omni]
  },
  {
    id: '140',
    name: 'Gopuff',
    displayName: 'Gopuff',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '113',
    name: 'Stop & Shop',
    displayName: 'Stop & Shop',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '114',
    name: 'Giant Food',
    displayName: 'Giant Food',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '115',
    name: "Giant Martin's",
    displayName: "Giant Martin's",
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '116',
    name: 'PetSmart',
    displayName: 'PetSmart',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '117',
    name: 'H-E-B',
    displayName: 'H-E-B',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '9',
    name: 'Costco',
    displayName: 'Costco US',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    supportedAppNames: [AppName.Omni, AppName.Advertising],
    countryCode: 'US',
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales'],
    searchUrl: 'https://www.costco.com/CatalogSearch?dept=All&keyword=',
    skuUrl: 'https://www.costco.com/CatalogSearch?dept=All&keyword='
  },
  {
    id: '25',
    name: "Sam's Club",
    displayName: "Sam's Club US",
    type: 'retailer',
    supportedAppNames: [AppName.Omni, AppName.Advertising],
    countryCode: 'US',
    countryDisplayName: 'United States',
    currencySymbol: '$',
    locale: 'en',
    reviewAverage,
    contentScore: contentScoreWithoutAPlus,
    dataTypesAvailable: ['sales', 'traffic'],
    searchUrl: 'https://www.samsclub.com/s/',
    skuUrl: 'https://www.samsclub.com/p/',
    orderTypes: [RetailerOrderType.DELIVERY, RetailerOrderType.PICKUP, RetailerOrderType.SHIPPING]
  },
  {
    id: '103',
    name: 'Amazon Egypt',
    displayName: 'Amazon Egypt',
    type: 'retailer',
    currencySymbol: 'جنيه',
    locale: 'ar-ae',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising],
    searchUrl: 'https://www.amazon.eg/s?k=',
    skuUrl: 'https://www.amazon.eg/dp/'
  },
  {
    id: '118',
    name: 'Noon',
    displayName: 'Noon',
    type: 'retailer',
    currencySymbol: 'د.إ',
    locale: 'ar-ae',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Omni],
    searchUrl: 'https://www.noon.com/search/?q=',
    countryCode: 'AE',
    countryDisplayName: 'United Arab Emirates'
  },
  {
    id: '119',
    name: 'Amazon Belgium',
    displayName: 'Amazon Belgium',
    type: 'retailer',
    currencySymbol: '€',
    locale: 'nl-be',
    reviewAverage,
    contentScore,
    dataTypesAvailable: ['sales'],
    supportedAppNames: [AppName.Atlas, AppName.Beacon, AppName.Advertising],
    searchUrl: 'https://www.amazon.com.be/s?k=',
    skuUrl: 'https://www.amazon.com.be/dp/'
  },
  {
    id: '120',
    name: 'Dollar General',
    displayName: 'Dollar General',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '121',
    name: 'GameStop',
    displayName: 'GameStop',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },

  {
    id: '126',
    name: 'FoodLion',
    displayName: 'Food Lion',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },

  {
    id: '127',
    name: 'Fred Meyer',
    displayName: 'Fred Meyer',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '128',
    name: 'Ralphs',
    displayName: 'Ralphs',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '142',
    name: `Hannaford`,
    displayName: 'Hannaford',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '145',
    name: `Fry's Food`,
    displayName: 'Fry’s Food',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '146',
    name: 'Giant Company',
    displayName: 'Giant Company',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '147',
    name: `ShopRite`,
    displayName: 'ShopRite',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '148',
    name: `Hy-Vee`,
    displayName: 'Hy-Vee',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '149',
    name: `Giant Eagle`,
    displayName: 'Giant Eagle',
    type: 'retailer',
    supportedAppNames: [AppName.Omni, AppName.Advertising],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '150',
    name: `Publix`,
    displayName: 'Publix',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '170',
    name: `Albertsons Companies`,
    displayName: 'Albertsons Companies',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '171',
    name: `Shaw's`,
    displayName: `Shaw's`,
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '172',
    name: `Pet Supplies Plus`,
    displayName: 'Pet Supplies Plus',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '173',
    name: `BJ's`,
    displayName: `BJ's`,
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '174',
    name: `Wegmans`,
    displayName: `Wegmans`,
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '176',
    name: `Sam's Club Mexico`,
    displayName: `Sam's Club Mexico`,
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'MX',
    countryDisplayName: 'Mexico',
    currencySymbol: '$'
  },
  {
    id: '177',
    name: `First Cry UAE`,
    displayName: `First Cry UAE`,
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'AE',
    countryDisplayName: 'United Arab Emirates',
    currencySymbol: 'د.إ'
  },
  {
    id: '178',
    name: `Mumz World`,
    displayName: `Mumz World`,
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'AE',
    countryDisplayName: 'United Arab Emirates',
    currencySymbol: 'د.إ'
  },
  {
    id: '131',
    name: `Smith's Food and Drug`,
    displayName: `Smith's Food and Drug`,
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '132',
    name: 'King Soopers',
    displayName: 'King Soopers',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '133',
    name: 'Food 4 Less',
    displayName: 'Food 4 Less',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '134',
    name: `Pick'n Save`,
    displayName: `Pick'n Save`,
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '183',
    name: 'Jewel Osco',
    displayName: 'Jewel Osco',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '184',
    name: 'ACME',
    displayName: 'ACME',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '185',
    name: 'Tom Thumb',
    displayName: 'Tom Thumb',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '186',
    name: 'Randalls',
    displayName: 'Randalls',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '187',
    name: 'Pavilions',
    displayName: 'Pavilions',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '188',
    name: 'Vons',
    displayName: 'Vons',
    type: 'retailer',
    supportedAppNames: [AppName.Omni],
    countryCode: 'US',
    countryDisplayName: 'United States'
  },
  {
    id: '193',
    name: 'ASOS-US',
    displayName: 'ASOS',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales'],
    searchUrl: 'https://www.asos.com/search/?q=',
    skuUrl: 'https://www.asos.com/search/?q='
  },
  {
    id: '194',
    name: 'Dermstore-US',
    displayName: 'Dermstore',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales'],
    searchUrl: 'https://www.dermstore.com/elysium.search?search=',
    skuUrl: 'https://www.dillards.com/search-term/'
  },
  {
    id: '195',
    name: 'Dillards-US',
    displayName: "Dillard's",
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales'],
    searchUrl: 'https://www.dillards.com/search-term/',
    skuUrl: 'https://www.dillards.com/search-term/'
  },
  {
    id: '196',
    name: 'RiteAid-US',
    displayName: 'Rite Aid',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales'],
    searchUrl: 'https://www.riteaid.com/shop/catalogsearch/result/?q=',
    skuUrl: 'https://www.riteaid.com/shop/catalogsearch/result/?q='
  },
  {
    id: '197',
    name: 'SaksFifthAve-US',
    displayName: 'Saks Fifth Ave',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales'],
    searchUrl: 'https://www.saksfifthavenue.com/s/SaksFifthAvenue/search?q=',
    skuUrl: 'https://www.saksfifthavenue.com/s/SaksFifthAvenue/search?q='
  },
  {
    id: '198',
    name: 'Skinstore-US',
    displayName: 'Skinstore',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales'],
    searchUrl: 'https://www.skinstore.com/elysium.search?search=',
    skuUrl: 'https://www.skinstore.com/elysium.search?search=' // TODO: elysium.search can be replaced to root/skuUrl.html
  },
  // Criteo US Retailers
  {
    id: '154',
    name: 'FreshDirect-US',
    displayName: 'Fresh Direct',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales'],
    searchUrl: 'https://www.freshdirect.com/search?search='
  },
  {
    id: '158',
    name: 'Shipt-US',
    displayName: 'Shipt',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales']
  },
  {
    id: '73',
    name: 'Lowes-US',
    displayName: "Lowe's",
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales']
  },
  {
    id: '152',
    name: 'Bloomingdales-US',
    displayName: 'Bloomingdales',
    type: 'retailer',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales']
  },
  {
    id: '153',
    name: 'CostcoBusiness-US',
    displayName: 'Costco Business Delivery US',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales']
  },
  {
    id: '95',
    name: 'CVS-US',
    displayName: 'CVS',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales'],
    searchUrl: 'https://www.cvs.com/search?searchTerm=',
    skuUrl: 'https://www.cvs.com/search?searchTerm='
  },
  {
    id: '155',
    name: 'Freshop-US',
    displayName: 'Freshop',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales']
  },
  {
    id: '149',
    name: 'GiantEagle-US',
    displayName: 'Giant Eagle',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales']
  },
  {
    id: '139',
    name: 'Macys-US',
    displayName: "Macy's",
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales'],
    searchUrl: 'https://www.macys.com/shop/featured/',
    skuUrl: 'https://www.macys.com/shop/product/p?ID='
  },
  {
    id: '156',
    name: 'Michaels-US',
    displayName: "Michael's",
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales']
  },
  {
    id: '52',
    name: 'Nordstrom-US',
    displayName: 'Nordstrom',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales']
  },
  {
    id: '157',
    name: 'NordstromRack-US',
    displayName: 'Nordstrom Rack',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales']
  },
  {
    id: '159',
    name: 'UberEats-US',
    displayName: 'Uber Eats US',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales']
  },
  {
    id: '160',
    name: 'Ulta-US',
    displayName: 'Ulta',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales'],
    searchUrl: 'https://www.ulta.com/search?search=',
    skuUrl: 'https://www.ulta.com/search?search='
  },
  {
    id: '96',
    name: 'Walgreens-US',
    displayName: 'Walgreens',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'US',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'United States',
    dataTypesAvailable: ['sales'],
    searchUrl: 'https://www.walgreens.com/search/results.jsp?Ntt=',
    skuUrl: 'https://www.walgreens.com/store/c/p/ID=' // needs to have '-product' at the end
  },

  // Criteo - Canada
  {
    id: '161',
    name: 'BabiesRUs-CA',
    displayName: 'Babies R Us Canada',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'CA',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'Canada',
    dataTypesAvailable: ['sales']
  },
  {
    id: '107',
    name: 'Costco-CA',
    displayName: 'Costco Canada',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'CA',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'Canada',
    dataTypesAvailable: ['sales'],
    searchUrl: 'https://www.costco.ca/CatalogSearch?dept=All&keyword=',
    skuUrl: 'https://www.costco.ca/CatalogSearch?dept=All&keyword='
  },
  {
    id: '106',
    name: 'BestBuy-CA',
    displayName: 'Best Buy Canada',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'CA',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'Canada',
    dataTypesAvailable: ['sales']
  },
  {
    id: '163',
    name: 'Lowes-CA',
    displayName: "Lowe's Canada",
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'CA',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'Canada',
    dataTypesAvailable: ['sales']
  },
  {
    id: '162',
    name: 'LEquipeur-CA',
    displayName: "L'Equipeur Canada",
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'CA',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'Canada',
    dataTypesAvailable: ['sales']
  },
  {
    id: '164',
    name: 'Marks-CA',
    displayName: "Mark's Canada",
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'CA',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'Canada',
    dataTypesAvailable: ['sales']
  },
  {
    id: '165',
    name: 'Metro-CA',
    displayName: 'Metro Canada',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'CA',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'Canada',
    dataTypesAvailable: ['sales']
  },
  {
    id: '166',
    name: 'RONA-CA',
    displayName: 'RONA Canada',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'CA',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'Canada',
    dataTypesAvailable: ['sales']
  },
  {
    id: '167',
    name: 'Sportchek-CA',
    displayName: 'Sportchek Canada',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'CA',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'Canada',
    dataTypesAvailable: ['sales']
  },
  {
    id: '168',
    name: 'ToysRUs-CA',
    displayName: 'Toys R Us Canada',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'CA',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'Canada',
    dataTypesAvailable: ['sales']
  },
  {
    id: '169',
    name: 'UberEats-CA',
    displayName: 'Uber Eats Canada',
    type: 'retailer',
    currencySymbol: '$',
    locale: 'en',
    countryCode: 'CA',
    supportedAppNames: [AppName.Advertising],
    reviewAverage,
    countryDisplayName: 'Canada',
    dataTypesAvailable: ['sales']
  }
];

export function findRetailerFromAvailable(retailerId: string | number = '') {
  const ridStr = retailerId.toString();
  return availableRetailers.find((r) => r.id === ridStr);
}

export const StoreListSupportedRetailer = [
  2, 4, 6, 48, 92, 93, 58, 73, 98, 120, 121, 126, 127, 128, 131, 132, 133, 134, 142, 145, 146, 147, 148, 149, 150, 170,
  172, 174, 176, 183, 184, 185, 186, 187, 188
];

export const StoreListNotSupportedRetailer = [1, 18, 20, 37, 43, 63, 118, 81, 140, 173, 177, 178];

export const AMAZON_RETAILER_IDS = availableRetailers.reduce((result, retailerInfo) => {
  if (retailerInfo.name.toLowerCase().includes('amazon')) {
    result.push(retailerInfo.id);
  }
  return result;
}, []);

const initialState = {
  reviewAverage,
  contentScore,
  availableRetailers
};

export default function retailer(state = initialState, action: ReturnType<ValueOf<typeof actions>>) {
  switch (action.type) {
    case types.SET_RETAILER:
      return Object.assign({}, state, currentRetailer(state, action));
    case types.SET_RETAILER_PLATFORM_DETAILS:
      return {
        ...state,
        platformType: action.platformType,
        processInTimezone: action.processInTimezone
      };
    default:
      return state;
  }
}
