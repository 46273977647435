import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.array.isRequired
};

function NonAuthLayout({ children }) {
  return <div style={{ height: '100%' }}>{children}</div>;
}

NonAuthLayout.propTypes = propTypes;

export default NonAuthLayout;
