import React from 'react';

export default function AuthContainer({
  children,
  style = {}
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <div
      style={{
        background: 'white',
        padding: '40px 58px',
        width: '536px',
        minHeight: '341px',
        boxSizing: 'border-box',
        borderRadius: '8px',
        ...style
      }}
    >
      {children}
    </div>
  );
}
