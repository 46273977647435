import { useBaseMetricRequestBuilder } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { useAppSelector } from 'src/utils/Hooks';
import { waterfallFieldsToQuery } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy';
import { useMemo } from 'react';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import {
  AdvancedSearchByRetailerIdResponse,
  WaterfallUnadjustedAdditionalValues
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/types';
import { parseWaterfallResponse } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/responseParsers';
import { calculatePercentChange } from 'src/utils/app';

/**
 * Fetches data for the summary waterfall chart
 */
export default function useWaterfallData() {
  const { startWeek, endWeek } = useAppSelector((state) => state.mainTimePeriod);
  const { startWeek: compareStartWeek, endWeek: compareEndWeek } = useAppSelector(
    (state) => state.comparisonTimePeriod
  );
  const retailerId = useAppSelector((state) => state.retailer.id);

  const baseWaterfallRequestBuilder = useBaseMetricRequestBuilder({
    requestId: 'actualYoyUnitsChangeWaterfall',
    indexName: 'actual-yoy-units-change',
    fields: waterfallFieldsToQuery.map((fieldName) => ({ name: fieldName })),
    sortFieldName: 'organicTrafficScaledContribution',
    groupByFieldName: 'retailerId',
    buildAggregationBuilder: (aggBuilder) => aggBuilder.clearConditionRangeFilters()
  });

  const actualUnitsASRequest = useMemo(() => {
    return baseWaterfallRequestBuilder
      .clone()
      .clearConditionRangeFilters()
      .addConditionRangeFilter('weekId', startWeek, endWeek)
      .setReturnAdditionalMetadata(false)
      .build();
  }, [baseWaterfallRequestBuilder, endWeek, startWeek]);

  const compareUnitsASRequest = useMemo(() => {
    return baseWaterfallRequestBuilder
      .clone()
      .clearConditionRangeFilters()
      .addConditionRangeFilter('weekId', compareStartWeek, compareEndWeek)
      .setReturnAdditionalMetadata(false)
      .build();
  }, [baseWaterfallRequestBuilder, compareEndWeek, compareStartWeek]);

  const { data: waterfallResponse, ...rest } = useGenericAdvancedSearch<
    AdvancedSearchByRetailerIdResponse<WaterfallUnadjustedAdditionalValues>[]
  >({
    requestId: 'actualYoyUnitsChangeWaterfall',
    queryKeys: [actualUnitsASRequest, compareUnitsASRequest],
    requestBody: [actualUnitsASRequest, compareUnitsASRequest]
  });

  const response: WaterfallUnadjustedAdditionalValues = useMemo(() => {
    const emptyResponse: WaterfallUnadjustedAdditionalValues = {
      actualTotalUnitsSoldChange_sum_value: 0,
      buyBoxScaledContribution_sum_value: 0,
      contentScoreScaledContribution_sum_value: 0,
      currentUnitsSold_sum_value: 0,
      inStockRateScaledContribution_sum_value: 0,
      organicTrafficScaledContribution_sum_value: 0,
      otherTrafficScaledContribution_sum_value: 0,
      paidTrafficScaledContribution_sum_value: 0,
      priorUnitsSold_sum_value: 0,
      ratingScaledContribution_sum_value: 0,
      retailPriceScaledContribution_sum_value: 0
    };

    if (!waterfallResponse) {
      return emptyResponse;
    }

    const parsedResponse = parseWaterfallResponse(retailerId, waterfallResponse.data[0]);

    const comparisonRow = waterfallResponse.data[1].aggregations.by_retailerId.find(
      ({ fieldId }) => fieldId === retailerId
    );
    const comparisonUnits = comparisonRow ? comparisonRow.additionalValues.currentUnitsSold_sum_value : 0;

    return parsedResponse
      ? {
          ...parsedResponse,
          priorUnitsSold_sum_value: comparisonUnits
        }
      : emptyResponse;
  }, [retailerId, waterfallResponse]);

  return {
    data: response,
    percentChange: response
      ? calculatePercentChange(response.currentUnitsSold_sum_value, response.priorUnitsSold_sum_value)
      : 0,
    ...rest
  };
}
