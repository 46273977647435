import _get from 'lodash/get';
import _memoize from 'lodash/memoize';
import { AppName } from 'sl-api-connector/types';

import { getLayoutForEntity as getAdBudgetEditorPageLayout } from 'src/components/Layout/Advertising/AdBudgetEditorPageLayout';
import { getLayoutForEntity as getAdCampaignBuilderPageLayout } from 'src/components/Layout/Advertising/AdCampaignBuilderPageLayout';
import { getLayoutForEntity as getAdManagerPageLayout } from 'src/components/Layout/Advertising/AdManagerPageLayout';
import { getLayoutForEntity as getScheduledActionsPageLayout } from 'src/components/Layout/Advertising/AdScheduledActionsPageLayout';
import { getLayoutForEntity as getConnectPageLayout } from 'src/components/Layout/Advertising/ConnectPageLayout/ConnectPageLayout';
import {
  AD_MEDIA_MANAGEMENT_URL,
  getLayoutForEntity as getAdMediaManagement
} from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdMediaManagementPageLayout';
import { getLayoutForEntityOmni as getOmniPageLayout } from 'src/components/Layout/Omni/OmniPageLayout/OmniPageLayout';
import atlasEntityPageLayout from './AtlasPageLayout';
import beaconEntityPageLayout from './BeaconPageLayout';
import newBeaconPageLayout from './NewBeaconPageLayout';
import searchPageLayout from './SearchPageLayout';
import { addLeftNavToLayout } from 'src/components/Layout/LayoutUtil';
import { warn } from 'src/utils/mixpanel';
import { shouldShowNewBeacon } from 'src/utils/app';
import { withNewBeaconSidebar } from 'src/components/BeaconRedesignComponents/utils/withNewBeaconSidebar';

const getLayoutByEntityType = () => {
  const beaconPageLayout = shouldShowNewBeacon() ? newBeaconPageLayout : beaconEntityPageLayout;

  return {
    [AppName.Atlas]: {
      product: {
        entityPage: atlasEntityPageLayout,
        summaryPage: atlasEntityPageLayout
      },
      brand: {
        entityPage: atlasEntityPageLayout,
        summaryPage: atlasEntityPageLayout
      },
      category: {
        entityPage: atlasEntityPageLayout,
        summaryPage: atlasEntityPageLayout
      },
      subcategory: {
        entityPage: atlasEntityPageLayout,
        summaryPage: atlasEntityPageLayout
      },
      segment: {
        entityPage: atlasEntityPageLayout,
        summaryPage: atlasEntityPageLayout,
        searchPage: searchPageLayout
      },
      businessunit: {
        entityPage: atlasEntityPageLayout,
        summaryPage: atlasEntityPageLayout,
        searchPage: searchPageLayout
      },
      searchtermlist: {
        entityPage: atlasEntityPageLayout,
        summaryPage: atlasEntityPageLayout,
        searchPage: searchPageLayout
      },
      company: {
        entityPage: atlasEntityPageLayout,
        summaryPage: atlasEntityPageLayout
      },
      client: {
        summaryPage: atlasEntityPageLayout,
        searchPage: searchPageLayout
      }
    },
    [AppName.Beacon]: {
      product: {
        entityPage: beaconPageLayout,
        summaryPage: beaconPageLayout
      },
      brand: {
        entityPage: beaconPageLayout,
        summaryPage: beaconPageLayout
      },
      company: {
        entityPage: beaconPageLayout,
        summaryPage: beaconPageLayout
      },
      category: {
        entityPage: beaconPageLayout,
        summaryPage: beaconPageLayout
      },
      subcategory: {
        entityPage: beaconPageLayout,
        summaryPage: beaconPageLayout
      },
      segment: {
        entityPage: beaconPageLayout,
        summaryPage: beaconPageLayout,
        searchPage: searchPageLayout
      },
      businessunit: {
        entityPage: beaconPageLayout,
        summaryPage: beaconPageLayout,
        searchPage: searchPageLayout
      },
      searchtermlist: {
        entityPage: beaconPageLayout,
        summaryPage: beaconPageLayout,
        searchPage: searchPageLayout
      },
      client: {
        entityPage: beaconPageLayout,
        summaryPage: beaconPageLayout,
        searchPage: searchPageLayout
      },
      adCampaign: {
        entityPage: beaconPageLayout,
        summaryPage: beaconPageLayout,
        searchPage: searchPageLayout
      }
    }
  };
};

const checkForNonSubscribedEntity = (entity, entityType, user) => {
  if (entity && (entity.type === 'subcategory' || entity.type === 'category') && entityType === 'category') {
    if (
      user &&
      user.config &&
      entity.categoryIds &&
      !user.config.CategoriesSubscribed.includes(entity.categoryIds[0]) &&
      !user.config.isStacklineSuperUser &&
      !(entity.categoryIds[0] === 0) // All categories
    ) {
      return true;
    }
  }
  return false;
};
const getLayoutForEntityInner = (args) => {
  const { app, retailer, user, entity, entityType, filters, selectedEntityName, subtab } = args;
  let { tab, metricType, pageType } = args;

  // Beacon Redesign Logic
  const showNewBeacon = shouldShowNewBeacon();

  if (app.name === AppName.Advertising) {
    const layoutGetter = {
      adCampaignBuilder: () =>
        getAdCampaignBuilderPageLayout({ ...args, tab: tab || 'adCampaignBuilder', subtab: metricType }),
      adBudgetEditor: getAdBudgetEditorPageLayout,
      adManager: getAdManagerPageLayout,
      adScheduledActions: getScheduledActionsPageLayout,
      connectDashboard: getConnectPageLayout,
      [AD_MEDIA_MANAGEMENT_URL]: getAdMediaManagement
    }[tab];

    if (layoutGetter) {
      return layoutGetter({ ...args, tab, subtab: metricType });
    }

    // If no tab is provided, bring user to the ad manager
    return getAdManagerPageLayout({ ...args, tab, subtab: 'keyMetrics' });
  }

  if (app.name === AppName.Omni) {
    const layoutGetter = {
      omni: getOmniPageLayout
    }[tab];

    if (layoutGetter) {
      return layoutGetter(app, tab, subtab, entityType);
    }

    return getOmniPageLayout(app, tab, subtab, entityType);
  }

  if (!pageType) {
    pageType = 'summaryPage';
  }
  switch (pageType) {
    case 'entityPage':
      if (!tab) {
        // eslint-disable-next-line prefer-destructuring
        tab = app.defaultQueryParams.tab;
      }
      if (!metricType) {
        metricType = app.defaultQueryParams.subtab;
      }
      break;
    case 'summaryPage':
      if (!tab) {
        tab = 'summary';
      }
      if (!metricType) {
        metricType = `${app.name}`;
      }
      break;
    case 'searchPage':
      if (entity.type === 'searchtermlist') {
        if (!tab) {
          tab = 'traffic';
        }
        if (!metricType) {
          metricType = 'totalClicks';
        }
      } else {
        if (!tab) {
          tab = 'sales';
        }
        if (!metricType) {
          metricType = 'retailSales';
        }
      }
      break;
    default:
      if (!tab) {
        tab = 'summary';
      }
      if (!metricType) {
        metricType = `${app.name}`;
      }
      break;
  }

  if (!entity) {
    return null;
  }

  const layoutByEntityType = getLayoutByEntityType();
  if (layoutByEntityType[app.name][entity.type][pageType]) {
    let layout = null;
    const entityLayout = layoutByEntityType[app.name][entity.type][pageType];
    if (entityLayout[`${tab}:${metricType}`]) {
      layout = entityLayout[`${tab}:${metricType}`];
    } else if (entityLayout.getLayoutForEntity) {
      layout = entityLayout.getLayoutForEntity({
        app,
        retailer,
        user,
        tab,
        metricType,
        entity,
        pageType,
        filters,
        entityType,
        selectedEntityName
      });
    }

    if (layout) {
      if (pageType === 'entityPage') {
        if (showNewBeacon) {
          return withNewBeaconSidebar(layout);
        }
        if (app && app.name === 'atlas') {
          if (checkForNonSubscribedEntity(entity, entityType, user)) {
            return null;
          }
        }

        // The `LeftNav` widget should be displayed on every `entityPage`, so we add it explicity here.
        return addLeftNavToLayout(layout);
      } else {
        return layout;
      }
    }
  }

  warn('Layout not defined for entity', { app, retailer, user, entity, pageType, metricType, filters });
  return null;
};

/**
 * Returns a cache key based on the provided parameters.
 *
 * @param {Object} params - The parameters used to generate the cache key.
 * @param {Object} params.app - The app object.
 * @param {Object} params.retailer - The retailer object.
 * @param {string} params.tab - The current tab.
 * @param {string} params.subtab - The current subtab.
 * @param {string} params.metricType - The metric type.
 * @param {Object} params.entity - The entity object.
 * @param {string} params.pageType - The page type.
 * @param {Object} params.filters - The filters object.
 * @param {string} params.entityType - The entity type.
 * @param {string} params.currentScheduledAction - The current scheduled action.
 * @param {string} params.selectedEntityName - The selected entity name.
 * @param {boolean} params.showPriceData - Whether to show price data.
 * @param {Object} params.user - The user object.
 * @returns {string} The cache key.
 */
const cacheResolver = ({
  app,
  retailer,
  tab,
  subtab,
  metricType,
  entity,
  pageType,
  filters,
  entityType,
  currentScheduledAction,
  selectedEntityName,
  showPriceData,
  user
}) => {
  return `${_get(app, 'name')}${_get(
    retailer,
    'id'
  )}${entityType}${selectedEntityName}${currentScheduledAction}${tab}${subtab}${metricType}${showPriceData}${_get(
    entity,
    'id'
  )}${_get(entity, 'type')}${pageType}${JSON.stringify(filters || {})}${_get(user, ['session', 'sessionId'], '')}`;
};

/**
 * This function is memoized in order to product referentially equal layouts given the same inputs.  The reason for
 * this is to reduce re-renders of components that create this layout and then pass them as a prop to children.
 * Since non-referentially-equal props trigger re-renders, this was a source of many extraneous re-renders.
 */
const memoizedGetLayoutForEntity = _memoize(getLayoutForEntityInner, cacheResolver);

export const getLayoutForEntity = ({ ...args }) => {
  const params = new URLSearchParams(window.location.search);
  const entityType = params.get('entityType') || 'category';
  const selectedEntityName = params.get('selectedEntityName');
  const currentScheduledAction = params.get('currentScheduledAction');
  const showPriceData = params.get('showPriceData');
  return memoizedGetLayoutForEntity({
    ...args,
    entityType,
    selectedEntityName,
    currentScheduledAction,
    showPriceData
  });
};
