import React, { Component } from 'react';
import axios from 'axios';
import _capitalize from 'lodash/capitalize';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _set from 'lodash/set';
import PropTypes from 'prop-types';
import { withBus } from 'react-bus';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { AppName } from 'sl-api-connector';

import { transferKeywordSegmentToExpReqFormat, SubtabToDocTypes } from 'src/components/Export/exportUtils';
import { getLastDayPreviousYear, getLastWeekPreviousYear } from 'src/utils/dateformatting';
import { propEq } from 'src/utils/fp';
import { validIndexForIncrementality } from 'src/utils/incrementalityColumn';
import { trackExport } from 'src/utils/mixpanel';
import { getRetailerIdDisplayName } from 'src/utils/stringFormatting';
import ExportDialog from './ExportDialog';
import {
  exportDataOmniAggregationFieldsGenerator,
  exportOmniContentAccuracyAggregationFields,
  exportOmniContentScoreAggregationFields,
  exportOmniShareOfShelfAggregationFieldsGenerator
} from './OmniAggregationField';
import { checkIsSamsClubRetailer, getParentPlatform } from 'src/utils/browser';
import { isCriteo, shouldShowCriteo } from 'src/utils/app';
import {
  getESBodyOverridesForParentPlatform,
  modifyESQuery
} from '../AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';

export const getDefaultExportConfig = (fileName) => [
  {
    fileName,
    additionalFieldsToReturn: [],
    aggregations: null,
    conditions: {},
    pageNumber: 1,
    pageSize: 200,
    period: 'year',
    sortFilter: {
      sortFields: []
    },
    exportResults: true,
    searchBy: 'parent',
    returnDocuments: true,
    doAggregation: false,
    retailerId: 1,
    searchType: `atlas-sales`,
    id: -1,
    name: 'export'
  }
];

const amazonRetailersForAdvertising = ['1', '11', '14', '15', '16', '17', '28', '29', '30', '31', '32', '53', '62'];
/**
 * This function handles modifying the requests sent off to the data export service in order to do things like add
 * in additional fields to the exports and adjust some other params of the request used for ElasticSearch query
 * generation so that the query can be successfully run by the data export service.
 *
 * @param {AppName} appName
 * @param {any[]} requests
 * @param {string} tab
 * @param {string} subtab
 */
export const maybeOverrideAggregationFields = (appName, requests, tab, subtab) => {
  const [firstRequest, secondRequest] = requests;

  if (appName === AppName.Beacon) {
    if (tab === 'traffic' && subtab === 'adClicks') {
      firstRequest.aggregations[0].aggregationFields = [
        {
          aggregateByFieldDisplayName: 'Ad Clicks',
          aggregateByFieldName: 'clicks',
          function: 'sum'
        },
        {
          aggregateByFieldDisplayName: 'Ad Spend',
          aggregateByFieldName: 'spend',
          function: 'sum'
        },
        {
          aggregateByFieldDisplayName: 'Ad Sales',
          aggregateByFieldName: 'sales',
          function: 'sum'
        },
        {
          aggregateByFieldDisplayName: 'Impressions',
          aggregateByFieldName: 'impressions',
          function: 'sum'
        },
        {
          aggregateByFieldDisplayName: 'Ad Units Sold',
          aggregateByFieldName: 'unitsSold',
          function: 'sum'
        },
        {
          aggregateByFieldDisplayName: 'ROAS',
          aggregateByFieldName: 'returnOnAdSpend',
          function: 'computed',
          aggregateByFormula: '((spend_sum > 0) ? ((sales_sum / spend_sum)) : (0))'
        },
        // We updated to Ad Sales/Ad Units - 28th Nov 2023. this will apply to both oldBeacon and newBeacon
        {
          aggregateByFieldDisplayName: 'ASP',
          aggregateByFieldName: 'asp',
          function: 'computed',
          aggregateByFormula: 'unitsSold_sum>0?sales_sum/unitsSold_sum:0'
        },
        {
          aggregateByFieldDisplayName: 'ClickThroughRate',
          aggregateByFieldName: 'clickThroughRate',
          function: 'computed',
          aggregateByFormula: 'impressions_sum>0?clicks_sum/impressions_sum:0'
        },
        {
          aggregateByFieldDisplayName: 'ConversionRate',
          aggregateByFieldName: 'conversionRate',
          function: 'computed',
          aggregateByFormula: 'clicks_sum>0?unitsSold_sum/clicks_sum:0'
        },
        {
          aggregateByFieldDisplayName: 'CostPerClick',
          aggregateByFieldName: 'costPerClick',
          function: 'computed',
          aggregateByFormula: 'clicks_sum>0?spend_sum/clicks_sum:0'
        },
        {
          aggregateByFieldDisplayName: 'CostPerAcquisition',
          aggregateByFieldName: 'costPerAcquisition',
          function: 'computed',
          aggregateByFormula: 'unitsSold_sum>0?spend_sum/unitsSold_sum:0'
        },
        {
          aggregateByFieldDisplayName: 'AdCostOfSales',
          aggregateByFieldName: 'adCostOfSales',
          function: 'computed',
          aggregateByFormula: 'sales_sum>0?spend_sum/sales_sum:0'
        }
      ];
    } else if (tab === 'sales' && subtab !== 'mapPrice') {
      firstRequest.aggregations[0].aggregationFields.push(
        {
          aggregateByFieldDisplayName: 'Retailer Gross Margin',
          aggregateByFieldName: 'retailerGrossMargin',
          function: 'sum'
        },
        {
          aggregateByFieldDisplayName: 'Retailer Margin per Unit',
          aggregateByFieldName: 'retailerMarginPerUnit',
          function: 'computed',
          aggregateByFormula: 'unitsSold_sum > 0 ? (retailerGrossMargin_sum / unitsSold_sum) : 0'
        },
        {
          aggregateByFieldDisplayName: 'Brand Gross Margin',
          aggregateByFieldName: 'brandGrossMargin',
          function: 'sum'
        },
        {
          aggregateByFieldDisplayName: 'Brand Gross Margin per Unit',
          aggregateByFieldName: 'brandGrossMarginPerUnit',
          function: 'computed',
          aggregateByFormula: 'unitsSold_sum>0?brandGrossMargin_sum/unitsSold_sum:0'
        },
        {
          aggregateByFieldDisplayName: 'Wholesale Price',
          aggregateByFieldName: 'wholesalePrice',
          function: 'avg'
        },
        {
          aggregateByFieldDisplayName: 'Units Returned',
          aggregateByFieldName: 'unitsReturned',
          function: 'sum'
        },
        {
          aggregateByFieldDisplayName: 'Return Rate',
          aggregateByFieldName: 'returnRate',
          function: 'computed',
          aggregateByFormula: 'unitsSold_sum > 0 ? (unitsReturned_sum/unitsSold_sum) : (unitsReturned_sum > 0 ? 1 : 0)'
        },
        {
          aggregateByFieldDisplayName: 'Inventory Retail Value',
          aggregateByFieldName: 'inventoryRetailValue',
          function: 'sum'
        },
        {
          aggregateByFieldDisplayName: 'Wholesale Inventory Value',
          aggregateByFieldName: 'inventoryWholesaleValue',
          function: 'sum'
        },
        {
          aggregateByFieldDisplayName: 'Return Inventory Value',
          aggregateByFieldName: 'returnRetailValue',
          function: 'sum'
        },
        {
          aggregateByFieldDisplayName: 'Weeks On-Hand',
          aggregateByFieldName: 'inventoryWeeksOnHand',
          function: 'avg'
        }
      );
      if (
        firstRequest.aggregations[0].aggregationFields.findIndex((x) => x.aggregateByFieldName === 'instockRate') === -1
      ) {
        firstRequest.aggregations[0].aggregationFields.push(
          {
            aggregateByFieldDisplayName: 'Products In-Stock',
            aggregateByFieldName: 'inStock',
            function: 'sum'
          },
          {
            aggregateByFieldDisplayName: 'Products Active',
            aggregateByFieldName: 'isActive',
            function: 'sum'
          },
          {
            aggregateByFieldDisplayName: 'In-Stock Rate',
            aggregateByFieldName: 'instockRate',
            function: 'computed',
            aggregateByFormula: 'isActive_sum > 0 ? (inStock_sum < isActive_sum ? inStock_sum/isActive_sum : 1) : 0'
          }
        );
      }
    } else if (tab === 'buybox' && subtab === 'sellers') {
      const derivedfieldsJSON = JSON.parse(firstRequest.additionalRequestMetaData.derivedFieldsJson);
      derivedfieldsJSON[0].dependentFields[0].aggregationFunction = 'max';
      firstRequest.additionalRequestMetaData.derivedFieldsJson = JSON.stringify(derivedfieldsJSON);
      // Making sure aggregation level to calculate winsBySeller and unitsSold are same
      const firstRequestAggregation = _get(firstRequest, 'aggregations[0].groupByFieldName');
      if (_get(secondRequest, 'aggregations[0].groupByFieldName') != null) {
        secondRequest.aggregations[0].groupByFieldName = firstRequestAggregation.slice();
      }
      secondRequest.searchType = 'beacon-sales-v2';
      if (
        _get(secondRequest, 'aggregations') != null &&
        secondRequest.aggregations.length > 0 &&
        _get(secondRequest, 'aggregations[0].aggregationFields[0].aggregateByFieldName') === 'unitsSold'
      ) {
        secondRequest.aggregations[0].aggregationFields = [
          {
            aggregateByFieldDisplayName: 'Units Sold',
            aggregateByFieldName: 'unitsSold',
            function: 'max',
            canBeExported: false
          }
        ];
      }
    } else if (tab === 'buybox' && subtab === 'winPercentage') {
      firstRequest.aggregations[0].aggregationFields.push({
        aggregateByFieldDisplayName: 'Units Sold',
        aggregateByFieldName: 'unitsSold',
        function: 'max'
      });
    } else if (tab === 'operations' && subtab === 'chargebacks') {
      const extraChargebacksGroupByFields = ['issueId'];
      const groupByFieldNames = firstRequest.aggregations[0].groupByFieldName;
      const newGroupByFields = groupByFieldNames.split(',');
      if (groupByFieldNames.indexOf('weekId') >= 0) {
        newGroupByFields.splice(-1, 0, ...extraChargebacksGroupByFields);
      } else {
        newGroupByFields.splice(newGroupByFields.length, 0, ...extraChargebacksGroupByFields);
      }
      firstRequest.aggregations[0].groupByFieldName = newGroupByFields.join(',');
    }
  } else if (appName === AppName.Advertising) {
    const isSamsClub = checkIsSamsClubRetailer();

    firstRequest.aggregations[0].aggregationFields = [
      {
        aggregateByFieldDisplayName: 'Impressions',
        aggregateByFieldName: 'impressions',
        function: 'sum',
        canBeExported: true
      },
      {
        aggregateByFieldDisplayName: 'Ad Clicks',
        aggregateByFieldName: 'clicks',
        function: 'sum',
        canBeExported: true
      },
      {
        aggregateByFieldDisplayName: 'Ad Spend',
        aggregateByFieldName: 'spend',
        function: 'sum',
        canBeExported: true
      },
      {
        aggregateByFieldDisplayName: 'Ad Sales',
        aggregateByFieldName: 'sales',
        function: 'sum',
        canBeExported: true
      },
      {
        aggregateByFieldDisplayName: 'Ad Units Sold',
        aggregateByFieldName: 'unitsSold',
        function: 'sum',
        canBeExported: true
      },
      {
        aggregateByFieldDisplayName: 'Cost per Click',
        aggregateByFieldName: 'costPerClick',
        function: 'computed',
        aggregateByFormula: '((clicks_sum > 0) ? ((spend_sum / clicks_sum)) : (0))',
        canBeExported: true
      },
      {
        aggregateByFieldDisplayName: 'ROAS',
        aggregateByFieldName: 'returnOnAdSpend',
        function: 'computed',
        aggregateByFormula: '((spend_sum > 0) ? ((sales_sum / spend_sum)) : (0))',
        canBeExported: true
      },
      {
        aggregateByFieldDisplayName: 'Cost per Acquisition',
        aggregateByFieldName: 'costPerAcquisition',
        function: 'computed',
        aggregateByFormula: '((unitsSold_sum > 0) ? ((spend_sum / unitsSold_sum)) : (0))',
        canBeExported: true
      },
      {
        aggregateByFieldDisplayName: 'Ad Cost of Sales',
        aggregateByFieldName: 'advertisingCostOfSale',
        function: 'computed',
        aggregateByFormula: '((sales_sum > 0) ? ((spend_sum / sales_sum)) : (0))',
        canBeExported: true
      },
      ...(isSamsClub
        ? [
            {
              aggregateByFieldName: 'sameCategorySales',
              aggregateByFieldDisplayName: 'Same Category Sales',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'newToBrandUnitsSold',
              aggregateByFieldDisplayName: 'NTB Units Sold',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'newToBrandSales',
              aggregateByFieldDisplayName: 'NTB Sales',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'offlineSales',
              aggregateByFieldDisplayName: 'Offline Sales',
              function: 'sum',
              canBeExported: true
            }
          ]
        : []),
      ...(isCriteo() && shouldShowCriteo()
        ? [
            {
              aggregateByFieldName: 'sameSKUSales',
              aggregateByFieldDisplayName: 'Same SKU Sales',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'sameSKUConversions',
              aggregateByFieldDisplayName: 'Same SKU Conversions',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'sameCategorySales',
              aggregateByFieldDisplayName: 'Same Category Sales',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'sameCategoryConversions',
              aggregateByFieldDisplayName: 'Same Category Conversions',
              function: 'sum',
              canBeExported: true
            }
          ]
        : [])
    ];

    if (secondRequest) {
      secondRequest.exportResults = true;
    }
  }
};

class Export extends Component {
  static defaultProps = {
    className: '',
    exportRequest: [{}],
    fileName: 'file',
    mime: 'text/csv',
    method: 'post',
    omniRetailerSelectedFromDropdown: 0,
    onClick: () => {}
  };

  static propTypes = {
    omniRetailerSelectedFromDropdown: PropTypes.number,
    app: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired,
    adPlatforms: PropTypes.object.isRequired,
    allWeekIdsByRetailerId: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    apiUrl: PropTypes.string.isRequired,
    exportRequest: PropTypes.array,
    className: PropTypes.string,
    fileName: PropTypes.string,
    mainTimePeriod: PropTypes.object.isRequired,
    mime: PropTypes.string,
    method: PropTypes.string,
    onClick: PropTypes.func,
    eventBus: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    omniEntity: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    omniKeywordSegmentService: PropTypes.object.isRequired,
    omniRetailers: PropTypes.object.isRequired,
    omniAccountSettingService: PropTypes.object.isRequired
  };

  // doExportForOmni became too long to be ExportDialog component.
  constructor(props) {
    super(props);
    this.doExportForOmni = this.doExportForOmni.bind(this);
  }

  state = {};

  componentDidMount() {
    this.props.eventBus.on('downloadEntityGrid', this.openExportDialog);
    this.props.eventBus.on('downloadCurrentContent', this.exportCurrent);
    this.props.eventBus.on('downloadMasterContent', this.exportMaster);
    this.props.eventBus.on('downloadContentCombine', this.exportContentCombine);
    this.props.eventBus.on('downloadAdManagerUnaggregated', this.exportUnaggregated);
    this.props.eventBus.on('downloadAdManagerAggregated', this.exportAggregated);
    this.props.eventBus.on('downloadAdManagerIneligible', this.exportIneligible);
    this.props.eventBus.on('downloadOmniDataProductLive', this.exportOmniContentProductLive);
    this.props.eventBus.on('downloadOmniDataProductMaster', this.exportOmniContentProductMaster);
    this.props.eventBus.on('downloadOmniData', this.openExportDialog);
    this.props.eventBus.on('downloadOmniDataSegments', this.exportOmniSegmentPages);
    this.props.eventBus.on('downloadOmniDataContentAccuracy', this.openExportDialog);
    this.props.eventBus.on('downloadOmniDataContentScore', this.openExportDialog);
    this.props.eventBus.on('downloadOmniDataReviews', this.openExportDialog);
  }

  componentWillUnmount() {
    this.props.eventBus.off('downloadEntityGrid', this.openExportDialog);
    this.props.eventBus.off('downloadCurrentContent', this.exportCurrent);
    this.props.eventBus.off('downloadMasterContent', this.exportMaster);
    this.props.eventBus.off('downloadContentCombine', this.exportContentCombine);
    this.props.eventBus.off('downloadAdManagerUnaggregated', this.exportUnaggregated);
    this.props.eventBus.off('downloadAdManagerAggregated', this.exportAggregated);
    this.props.eventBus.off('downloadAdManagerIneligible', this.exportIneligible);
    this.props.eventBus.off('downloadOmniDataProductLive', this.exportOmniContentProductLive);
    this.props.eventBus.off('downloadOmniDataProductMaster', this.exportOmniContentProductMaster);
    this.props.eventBus.off('downloadOmniData', this.openExportDialog);
    this.props.eventBus.off('downloadOmniDataSegments', this.exportOmniSegmentPages);
    this.props.eventBus.off('downloadOmniDataContentAccuracy', this.openExportDialog);
    this.props.eventBus.off('downloadOmniDataContentScore', this.openExportDialog);
    this.props.eventBus.off('downloadOmniDataReviews', this.openExportDialog);
  }

  exportOmniContentProductMaster = () => {
    this.exportOmniContentProductCurrentMaster('MasterContent');
  };

  exportOmniContentProductLive = () => {
    this.exportOmniContentProductCurrentMaster('LiveCurrentContent');
  };

  exportOmniSegmentPages = () => {
    this.exportOmniSegment();
  };

  exportContentCombine = () => {
    this.exportData('ContentCombine');
  };

  exportCurrent = () => {
    this.exportData('Current');
  };

  exportMaster = () => {
    this.exportData('Master');
  };

  exportUnaggregated = () => {
    this.exportData('Unaggregated');
  };

  exportAggregated = () => {
    this.exportData('Aggregated');
  };

  exportIneligible = () => {
    this.exportData('Ineligible', 'stacklineSku', false);
  };

  makeGroupByConsistent = (mergedRequest, groupByFieldName) => {
    if (groupByFieldName) {
      // Make sure the groupBy field is consistent, if one is set via the UI
      let groupBy = groupByFieldName;
      if (mergedRequest.length > 1) {
        mergedRequest.forEach((req, index) => {
          if (_get(req, ['aggregations', '0'])) {
            if (groupByFieldName && index === 1 && groupByFieldName.includes(',merchantId')) {
              // special case: Buy box sellers export. Second request cannot group by merchant id
              // but we still need to update the group by field
              groupBy = groupByFieldName.replace(',merchantId', '');
            }
            _set(req, ['aggregations', '0', 'groupByFieldName'], groupBy);
          }
        });
      }
    }
  };

  downloadFile = (content) => {
    const { fileName, mime } = this.props;
    const blobObject = new Blob([content], { type: mime });
    const downloadAttrSupported = true;

    // MS specific. Check this first because of bug with Edge (#76)
    if (window.Blob && window.navigator.msSaveOrOpenBlob) {
      // Falls to msSaveOrOpenBlob if download attribute is not supported
      window.navigator.msSaveOrOpenBlob(blobObject, fileName);

      // Download attribute supported
    } else if (downloadAttrSupported) {
      const a = document.createElement('a');
      a.style = 'display: none';
      a.href = window.URL.createObjectURL(blobObject);
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => a.remove(), 100);
    } else {
      alert('Download not supported!'); // eslint-disable-line
    }
  };

  generateFileName = (prefix, groupByFieldName, request) => {
    // This is used during Cypress tests to generate custom export filenames that are easily identifiable
    if (window.localStorage.exportFileNameOverride) {
      return window.localStorage.exportFileNameOverride;
    }

    let retailerName = '';
    if (request.length > 0) {
      const [requestObj] = request;
      if (requestObj && requestObj.retailerId) {
        retailerName = getRetailerIdDisplayName(requestObj.retailerId, this.props.retailer);
      }
    }

    const {
      app,
      entityService,
      mainTimePeriod: { startWeek: mainStartWeek, endWeek: mainEndWeek }
    } = this.props;
    const { type, shortDisplayName: name } = entityService.mainEntity;
    const { tab = app.defaultQueryParams.tab, subtab = app.defaultQueryParams.subtab } = app.queryParams;

    // Default: use main time period start and end week
    let startWeek = mainStartWeek;
    let endWeek = mainEndWeek;

    // Override the field name with the weekId filter, if there is one
    _get(request, ['0', 'aggregations', '0', 'conditions', 'rangeFilters'], []).forEach((filter) => {
      if (filter.fieldName === 'weekId') {
        startWeek = filter.minValue;
        endWeek = filter.maxValue;
      }
    });

    if (app.name === 'advertising') {
      const [firstGroupByField, secondGroupByField, thirdGroupByField] = groupByFieldName.split(',');
      return `AdManager ${type} ${name || ''} ${firstGroupByField || ''} ${secondGroupByField || ''} ${
        thirdGroupByField || ''
      } ${startWeek} ${endWeek}`.replace(/ /g, '_');
    }
    const fileName = `${prefix ? `${prefix} ` : ''}${type} ${
      name || ''
    } ${tab} ${subtab} ${retailerName} ${startWeek} ${endWeek}`;
    return fileName.replace(/ /g, '_');
  };

  //
  exportOmniSegment = () => {
    const { app, apiUrl, mainTimePeriod, entityService, omniEntity } = this.props;
    const { tab = app.queryParams.tab, subtab = app.queryParams.subtab } = app.queryParams;
    // In segment view, name will be the segment name/
    const { name, queryId, userId } = entityService.mainEntity;
    const { startWeek, endWeek } = mainTimePeriod;

    const priceAndAvailabilityPartialDocType = SubtabToDocTypes[subtab];

    const { beaconClientId } = omniEntity;

    const additionalRequestMetaData = {
      useNewExports: 'True',
      entityDocumentKeyColumn: 'Retailer SKU',
      entitySearchType: 'omni-product-retailersku',
      includeUserInfo: 'True',
      beaconClientId,
      segmentExport: 'True',
      segmentUserId: userId,
      segmentQueryId: queryId
    };

    // priceAndAvailabilityParseFullDoc is needed in key metric page,
    // All other tabs (except promotion) are needed to have specify partialDoc and partialDocType in the payload
    if (tab === 'scorecard') {
      additionalRequestMetaData.priceAndAvailabilityParseFullDoc = 'True';
    } else if (subtab !== 'promotions') {
      additionalRequestMetaData.priceAndAvailabilityParsePartialDoc = 'True';
      additionalRequestMetaData.priceAndAvailabilityPartialDocType = priceAndAvailabilityPartialDocType;
    }

    const requestBodyObject = {
      fileName: '',
      additionalFieldsToReturn: [],
      aggregations: [],
      conditions: {
        termFilters: [],
        rangeFilters: [
          {
            fieldName: 'weekId',
            minValue: startWeek,
            maxValue: endWeek
          }
        ],
        computeFilters: []
      },
      pageNumber: 1,
      pageSize: 50,
      period: 'year',
      exportResults: true,
      searchBy: 'child',
      returnDocuments: true,
      doAggregation: false,
      retailerId: '1',
      searchType: subtab === 'promotions' ? 'omni-promotions' : 'omni-priceAndAvailability',
      id: '',
      name: 'Omni-client-gl9avgmbG1',
      processDocuments: false,
      additionalRequestMetaData,

      entityType: 'segment',
      entityName: name,
      sourceFilter: {
        excludes: ['dayIds']
      }
    };

    const exportBody = [requestBodyObject];

    axios.post(apiUrl, exportBody).then(
      () => {
        this.props.eventBus.emit('downloadIconOff');
        this.props.eventBus.emit('showDownloadNotification');
        setTimeout(() => this.props.eventBus.emit('fetchDownloadList'), 3000);
      },
      (err) => {
        throw err;
      }
    );
  };

  exportDataOmni = (firstGroupByValue, secondGroupByValue, thirdGroupByValue, aggByWeek, selectedWeekID, subtab) => {
    const { apiUrl, mainTimePeriod, entityService, omniEntity, location, user, filters } = this.props;
    const { retailerId, retailerName } = entityService.mainEntity;

    const { startWeek, endWeek } = mainTimePeriod;
    const { companyName, beaconClientId } = omniEntity;
    const fileName = ['Omni', 'client', companyName, retailerName, firstGroupByValue, secondGroupByValue];

    const groupByFieldName = [firstGroupByValue, secondGroupByValue];

    const termFilters = [];
    const { brand, category, subcategory } = filters;
    let shouldUseRetailerAggExport = false;
    if (
      firstGroupByValue === 'retailerId' &&
      (secondGroupByValue === 'stacklineSku.keyword' ||
        secondGroupByValue === 'brandId' ||
        secondGroupByValue === 'categoryId')
    ) {
      shouldUseRetailerAggExport = true;
    }

    if (brand) {
      termFilters.push({
        fieldName: 'brandId',
        condition: 'must',
        values: brand.map(Number)
      });
    }
    if (category) {
      termFilters.push({
        fieldName: 'categoryId',
        condition: 'must',
        values: category.map(Number)
      });
    }
    if (subcategory) {
      termFilters.push({
        fieldName: 'subCategoryId',
        condition: 'must',
        values: subcategory.map(Number)
      });
    }
    if (firstGroupByValue === 'stacklineSku.keyword' && secondGroupByValue === 'locationState.keyword') {
      if (secondGroupByValue === 'locationState.keyword') {
        groupByFieldName.unshift('retailerId');
      } else {
        groupByFieldName.push('retailerId');
      }
    }
    if (aggByWeek) {
      fileName.push('weekId');
      if (firstGroupByValue !== 'retailerId' && secondGroupByValue === 'locationState.keyword') {
        groupByFieldName.unshift('weekId');
      } else {
        groupByFieldName.push('weekId');
      }
    }
    fileName.push(`week${startWeek}_${endWeek}`);

    const additionalRequestMetaData = {
      useNewExports: 'True'
    };
    if (!shouldUseRetailerAggExport) {
      additionalRequestMetaData.exportFulfillmentColumn = `${_capitalize(thirdGroupByValue)}`;
    }

    const exportBodyObj = {
      fileName: fileName.join('_').replace('keyword', ''),
      additionalFieldsToReturn: [],
      aggregations: [
        {
          aggregationFields: shouldUseRetailerAggExport
            ? exportDataOmniAggregationFieldsGenerator(thirdGroupByValue, true, subtab)
            : exportDataOmniAggregationFieldsGenerator(thirdGroupByValue, false, subtab),
          conditions: {
            termFilters: [
              ...(shouldUseRetailerAggExport
                ? []
                : [
                    {
                      fieldName: `${thirdGroupByValue}Eligibility`,
                      condition: 'must',
                      values: [true]
                    }
                  ]),
              ...(!(firstGroupByValue === 'stacklineSku.keyword' && secondGroupByValue === 'locationId.keyword')
                ? [
                    {
                      fieldName: 'retailerId',
                      condition: 'must',
                      values: [String(retailerId)]
                    }
                  ]
                : [])
            ],
            rangeFilters: [
              ...(shouldUseRetailerAggExport
                ? [
                    {
                      fieldName: 'weekId',
                      minValue: startWeek,
                      maxValue: endWeek
                    }
                  ]
                : [
                    {
                      fieldName: `${thirdGroupByValue}RetailPrice`,
                      minValue: 0
                    }
                  ])
            ]
          },
          groupByFieldName: groupByFieldName.join(',')
        }
      ],
      conditions: {
        termFilters: [
          {
            fieldName: 'retailerId',
            condition: 'must',
            values: [String(retailerId)]
          },
          ...termFilters
        ],
        rangeFilters: [
          {
            fieldName: 'weekId',
            minValue: startWeek,
            maxValue: endWeek
          }
        ],
        computeFilters: []
      },
      pageNumber: 1,
      pageSize: 50,
      period: 'year',
      exportResults: true,
      searchBy:
        firstGroupByValue === 'stacklineSku.keyword' && secondGroupByValue === 'locationId.keyword'
          ? 'child'
          : 'parent',
      returnDocuments: false,
      doAggregation: true,
      retailerId: '0',
      searchType: shouldUseRetailerAggExport ? 'omni-ops-product-aggs' : 'omni-priceAndAvailability',
      id: '',
      name: 'OmniSearchResultData-client-gl9avgmbG1',
      processDocuments: false,
      additionalRequestMetaData,
      entityType: 'client',
      entityName: companyName
    };

    if (firstGroupByValue === 'stacklineSku.keyword' && secondGroupByValue !== 'locationState.keyword') {
      exportBodyObj.fileName = `Omni_client_${companyName}_${retailerName}_Full_Products_weekId_${selectedWeekID}`;
      exportBodyObj.conditions.rangeFilters = [
        {
          fieldName: 'weekId',
          minValue: selectedWeekID,
          maxValue: selectedWeekID
        }
      ];
      exportBodyObj.aggregations = [];
      exportBodyObj.returnDocuments = true;
      exportBodyObj.doAggregation = false;
      exportBodyObj.name = 'Omni-client-gl9avgmbG1';
      exportBodyObj.additionalRequestMetaData = {
        useNewExports: 'True',
        priceAndAvailabilityParseFullDoc: 'True'
      };

      // product x store combination
      if (firstGroupByValue === 'stacklineSku.keyword' && secondGroupByValue === 'locationId.keyword') {
        exportBodyObj.retailerId = String(retailerId);
        exportBodyObj.additionalRequestMetaData = {
          useNewExports: 'True',
          priceAndAvailabilityParseFullDoc: 'True',
          entityDocumentKeyColumn: 'Retailer SKU',
          entitySearchType: 'omni-product-retailersku',
          includeUserInfo: 'True',
          beaconClientId: String(beaconClientId)
        };
      }

      exportBodyObj.sourceFilter = {
        excludes: ['dayIds']
      };
    }

    const exportBody = [exportBodyObj];

    axios.post(apiUrl, exportBody).then(
      () => {
        this.props.eventBus.emit('downloadIconOff');
        this.props.eventBus.emit('showDownloadNotification');
        setTimeout(() => this.props.eventBus.emit('fetchDownloadList'), 3000);
      },
      (err) => {
        throw err;
      }
    );

    trackExport(location, user);
  };

  // Omni Content accuracy -> current, master export
  exportOmniContentProductCurrentMaster = (type) => {
    const {
      apiUrl,
      mainTimePeriod,
      entityService,
      omniEntity,
      location,
      user,
      retailer,
      filters,
      omniRetailerSelectedFromDropdown,
      omniRetailers
    } = this.props;
    const { gtin, type: entityType, retailerId } = entityService.mainEntity;

    const { retailer: retailerInFilter, brand, category, subcategory } = filters;

    const { availableRetailers } = retailer;
    let retailerName = '';
    const { startWeek, endWeek } = mainTimePeriod;
    const { companyName } = omniEntity;
    const retailerEntity = availableRetailers.find((r) => r.id === String(omniRetailerSelectedFromDropdown));
    if (retailerEntity) {
      retailerName = retailerEntity.displayName;
    }

    let searchType = '';
    if (type === 'MasterContent') {
      searchType = 'omni-contentApproved';
    }
    if (type === 'LiveCurrentContent') {
      searchType = 'omni-contentCurrent';
    }
    // file name configuration
    const fileName = [type, 'client', companyName, 'contentAccuracy'];
    if (entityType === 'product') {
      fileName.push('gtin', gtin, retailerName);
    }
    if (entityType === 'client') {
      fileName.push('organization');
    }
    fileName.push(startWeek, endWeek);

    // generate termFilters
    const termFilters = [];

    // For content score export, UI should send available retailers for the product, or it will export all retailers following with empty cells.
    // so, if filter has retailer, we can pass it. If not, we should send all available retailers
    let retailerTermFilterIds = retailerInFilter;

    if (!retailerInFilter) {
      retailerTermFilterIds = _get(omniRetailers, 'data', []).map((omniRetailer) => String(omniRetailer.retailerId));
    }
    if (entityType === 'retailer') {
      retailerTermFilterIds = [retailerId];
    }

    termFilters.push({
      fieldName: 'retailerId',
      condition: 'must',
      values: retailerTermFilterIds.map(String)
    });

    if (brand) {
      termFilters.push({
        fieldName: 'brandId',
        condition: 'must',
        values: brand.map(Number)
      });
    }
    if (category) {
      termFilters.push({
        fieldName: 'categoryId',
        condition: 'must',
        values: category.map(Number)
      });
    }
    if (subcategory) {
      termFilters.push({
        fieldName: 'subCategoryId',
        condition: 'must',
        values: subcategory.map(Number)
      });
    }

    const exportBodyObj = {
      additionalFieldsToReturn: [],
      additionalRequestMetaData: {
        derivedFieldsJson: '[]',
        useNewExports: 'True'
      },
      aggregations: [],
      conditions: {
        computeFilters: [],
        condition: 'must',
        nestedFilterConditions: [],
        rangeFilters: [
          {
            fieldName: 'weekId',
            maxValue: endWeek,
            minValue: startWeek
          }
        ],
        // if it is product level we ignore all the previous filters, since they have to select retailerId
        ...(entityType === 'product'
          ? {
              termFilters: [
                ...(type === 'LiveCurrentContent'
                  ? [
                      {
                        condition: 'must',
                        fieldName: 'retailerId',
                        values: [String(omniRetailerSelectedFromDropdown)]
                      }
                    ]
                  : []),
                {
                  condition: 'must',
                  fieldName: 'gtin.keyword',
                  values: [gtin]
                }
              ]
            }
          : { termFilters: [...termFilters] })
      },
      doAggregation: false,
      entityName: companyName,
      entityType: 'client',
      exportResults: true,
      fileName: fileName.join('_'),
      id: 'entityGridMetricsentityGrid-product--1',
      name: 'entityGridMetricsentityGrid-product--1',
      pageNumber: 1,
      pageSize: 20,
      period: 'year',
      processDocuments: true,
      retailerId: '0',
      returnDocuments: true,
      returnParentDocuments: true,
      searchBy: 'child',
      searchType,
      sortFilter: null,
      subtab: 'contentAccuracy',
      tab: 'content'
    };

    const exportBody = [exportBodyObj];

    axios.post(apiUrl, exportBody).then(
      () => {
        this.props.eventBus.emit('downloadIconOff');
        this.props.eventBus.emit('showDownloadNotification');
        setTimeout(() => this.props.eventBus.emit('fetchDownloadList'), 3000);
      },
      (err) => {
        throw err;
      }
    );

    trackExport(location, user);
  };

  // Omni Content Score Page export
  exportOmniContentScore = (firstGroupByValue, aggByWeek, selectedWeekID, tab, subtab) => {
    const { apiUrl, mainTimePeriod, omniEntity, location, user, filters, entityService, omniRetailers } = this.props;

    const { startWeek, endWeek } = mainTimePeriod;
    const { companyName } = omniEntity;
    const { gtin, type: entityType, retailerId } = entityService.mainEntity;
    const fileName = ['client', companyName, `${tab}`, `${subtab}`, ...(aggByWeek ? ['ByWeek'] : [])];

    if (entityType === 'product') {
      fileName.push('gtin', gtin);
    }
    if (entityType === 'client') {
      fileName.push('organization');
    }

    const groupByFieldName = ['retailerId', ...(aggByWeek ? ['weekId'] : []), firstGroupByValue];

    const termFilters = [];

    const { retailer: retailerInFilter, brand, category, subcategory } = filters;

    // For content score export, UI should send available retailers for the product, or it will export all retailers following with empty cells.
    // so, if filter has retailer, we can pass it. If not, we should send all available retailers
    let retailerTermFilterIds = retailerInFilter;

    if (!retailerInFilter) {
      retailerTermFilterIds = _get(omniRetailers, 'data', []).map((retailer) => String(retailer.retailerId));
    }

    if (entityType === 'retailer') {
      retailerTermFilterIds = [retailerId];
    }

    termFilters.push({
      fieldName: 'retailerId',
      condition: 'must',
      values: retailerTermFilterIds.map(String)
    });

    if (entityType === 'product') {
      termFilters.push({
        condition: 'must',
        fieldName: 'gtin.keyword',
        values: [gtin]
      });
    }

    // For the 'product' entity type, only items that match the user's previous filters are shown on
    if (entityType !== 'product') {
      if (brand) {
        termFilters.push({
          fieldName: 'brandId',
          condition: 'must',
          values: brand.map(Number)
        });
      }

      if (category) {
        termFilters.push({
          fieldName: 'categoryId',
          condition: 'must',
          values: category.map(Number)
        });
      }

      if (subcategory) {
        termFilters.push({
          fieldName: 'subCategoryId',
          condition: 'must',
          values: subcategory.map(Number)
        });
      }
    }
    const additionalRequestMetaData = {
      derivedFieldsJson: '[]',
      useNewExports: 'True'
    };
    if (entityType === 'segment') {
      const { name, userId, queryId } = entityService.mainEntity;
      fileName.push(`segment${name}`);
      additionalRequestMetaData.segmentExport = 'True';
      additionalRequestMetaData.segmentUserId = userId;
      additionalRequestMetaData.segmentQueryId = queryId;
    }
    fileName.push(`week${startWeek}_${endWeek}`);

    const exportBodyObj = {
      fileName: fileName.join('_').replace('keyword', ''),
      additionalFieldsToReturn: [],
      aggregations: [
        {
          aggregationFields: exportOmniContentScoreAggregationFields,
          conditions: {
            rangeFilters: [
              {
                fieldName: 'weekId',
                minValue: startWeek,
                maxValue: endWeek
              }
            ]
          },
          groupByFieldName: groupByFieldName.join(','),
          sortDirection: 'desc',
          sortByAggregationField: {
            aggregateByFieldDisplayName: 'Content Score',
            aggregateByFieldName: 'contentScore',
            function: 'avg',
            canBeExported: true
          }
        }
      ],
      conditions: {
        termFilters: [...termFilters],
        ...(entityType === 'segment'
          ? {
              rangeFilters: [
                {
                  fieldName: 'weekId',
                  minValue: startWeek,
                  maxValue: endWeek
                }
              ]
            }
          : {})
      },
      pageNumber: 1,
      pageSize: 20,
      period: 'year',
      sortFilter: {
        sortFields: [
          {
            aggregateByFieldDisplayName: 'Content Score',
            aggregateByFieldName: 'contentScore',
            function: 'avg',
            canBeExported: true
          }
        ]
      },
      exportResults: true,
      searchBy: 'parent',
      returnDocuments: false,
      doAggregation: true,
      retailerId: '0',
      searchType: 'omni-content',
      id: 'entityGridMetricsentitypagecontainer_entityGrid_3-product--1',
      name: 'entityGridMetricsentitypagecontainer_entityGrid_3-product--1',
      processDocuments: true,
      additionalRequestMetaData,
      entityType: 'client',
      entityName: companyName,
      tab,
      subtab
    };

    const exportBody = [exportBodyObj];

    axios.post(apiUrl, exportBody).then(
      () => {
        this.props.eventBus.emit('downloadIconOff');
        this.props.eventBus.emit('showDownloadNotification');
        setTimeout(() => this.props.eventBus.emit('fetchDownloadList'), 3000);
      },
      (err) => {
        throw err;
      }
    );

    trackExport(location, user);
  };

  // Omni Review Trends Page export
  exportOmniReviews = (aggByWeek, tab, subtab) => {
    const {
      apiUrl,
      mainTimePeriod,
      omniEntity,
      location,
      user,
      filters,
      entityService,
      omniRetailers,
      omniAccountSettingService
    } = this.props;
    const { startWeek, endWeek } = mainTimePeriod;
    const { companyName, beaconClientId } = omniEntity;
    const { gtin, type: entityType, retailerId, brandId } = entityService.mainEntity;
    const fileName = ['client', `${companyName}`, `${tab}`, `${subtab}`, ...(aggByWeek ? ['ByWeek'] : [])];

    const termFilters = [];

    const additionalRequestMetaData = {
      useNewExports: 'True',
      entityDocumentKeyColumn: 'Retailer SKU',
      entitySearchType: 'omni-product-content-review-retailersku',
      includeUserInfo: 'True',
      beaconClientId
    };

    const { retailer: retailerInFilter, brand, category, subcategory } = filters;

    let retailerTermFilterIds = retailerInFilter;

    if (entityType === 'product') {
      fileName.push('gtin', gtin);
    }
    if (entityType === 'client') {
      fileName.push('organization');
    }

    if (!retailerInFilter) {
      retailerTermFilterIds = _get(omniRetailers, 'data', []).map((retailer) => String(retailer.retailerId));
    }

    if (entityType === 'retailer') {
      retailerTermFilterIds = [retailerId];
      fileName.push('retailer');
      fileName.push(retailerId);
    }

    if (subtab === 'highRiskReviews') {
      termFilters.push({
        fieldName: 'stars',
        condition: 'should',
        values: ['1', '2']
      });

      const highRiskKeywords = _get(omniAccountSettingService, 'data.highRiskReviews.keywords', []);
      if (highRiskKeywords.length > 0) {
        termFilters.push({
          fieldName: 'reviewText',
          condition: 'should',
          values: highRiskKeywords
        });
      }
    }

    termFilters.push({
      fieldName: 'retailerId',
      condition: 'must',
      values: retailerTermFilterIds.map(String)
    });

    if (retailerInFilter) {
      termFilters.push({
        fieldName: 'retailerId',
        condition: 'must',
        values: retailerInFilter.map(String)
      });
    }

    if (brand) {
      termFilters.push({
        fieldName: 'brandId',
        condition: 'must',
        values: brand.map(Number)
      });
    }
    if (category) {
      termFilters.push({
        fieldName: 'categoryId',
        condition: 'must',
        values: category.map(Number)
      });
    }
    if (subcategory) {
      termFilters.push({
        fieldName: 'subCategoryId',
        condition: 'must',
        values: subcategory.map(Number)
      });
    }

    // === EntityType TermFilter Starts ===

    if (entityType === 'brand') {
      termFilters.push({
        fieldName: 'brandId',
        condition: 'must',
        values: [String(brandId)]
      });
      fileName.push('brand');
      fileName.push(brandId);
    }

    if (entityType === 'product') {
      termFilters.push({
        condition: 'must',
        fieldName: 'gtin.keyword',
        values: [gtin]
      });
    }

    if (entityType === 'retailer') {
      termFilters.push({
        fieldName: 'retailerId',
        condition: 'must',
        values: [retailerId]
      });
    }

    if (entityType === 'segment') {
      const { name, userId, queryId } = entityService.mainEntity;
      fileName.push(`segment${name}`);
      additionalRequestMetaData.segmentExport = 'True';
      additionalRequestMetaData.segmentUserId = userId;
      additionalRequestMetaData.segmentQueryId = queryId;
    }

    fileName.push(`week${startWeek}_${endWeek}`);

    const exportBodyObj = {
      fileName: fileName.join('_').replace('keyword', ''),
      additionalFieldsToReturn: [],

      conditions: {
        termFilters: [...termFilters],
        rangeFilters: [
          {
            fieldName: 'weekId',
            minValue: startWeek,
            maxValue: endWeek
          }
        ]
      },
      pageNumber: 1,
      pageSize: 20,
      period: 'year',
      exportResults: true,
      searchBy: 'child',
      returnDocuments: false,
      doAggregation: false,
      retailerId: '0',
      searchType: 'omni-reviews',
      id: '',
      name: '',
      processDocuments: true,
      additionalRequestMetaData,
      entityType,
      entityName: companyName,
      tab,
      subtab
    };

    const exportBody = [exportBodyObj];

    axios.post(apiUrl, exportBody).then(
      () => {
        this.props.eventBus.emit('downloadIconOff');
        this.props.eventBus.emit('showDownloadNotification');
        setTimeout(() => this.props.eventBus.emit('fetchDownloadList'), 3000);
      },
      (err) => {
        throw err;
      }
    );

    trackExport(location, user);
  };

  // Omni Content Accuracy with Normal Export with weekly condition
  exportOmniContentAccuracy = (firstGroupByValue, aggByWeek, selectedWeekID, tab, subtab) => {
    const { apiUrl, mainTimePeriod, omniEntity, location, user, filters, entityService } = this.props;
    const { type: entityType, retailerId, brandId } = entityService.mainEntity;
    const { startWeek, endWeek } = mainTimePeriod;
    const { companyName } = omniEntity;
    const fileName = ['client', companyName, `${tab}`, `${subtab}`, ...(aggByWeek ? ['ByWeek'] : [])];
    const groupByFieldName = ['retailerId', ...(aggByWeek ? ['weekId'] : []), firstGroupByValue];

    const termFilters = [];
    const { retailer: retailerInFilter, brand, category, subcategory } = filters;

    // ==== Filter TermFilter Starts =====

    if (retailerInFilter) {
      termFilters.push({
        fieldName: 'retailerId',
        condition: 'must',
        values: retailerInFilter.map(String)
      });
    }

    if (brand) {
      termFilters.push({
        fieldName: 'brandId',
        condition: 'must',
        values: brand.map(Number)
      });
    }
    if (category) {
      termFilters.push({
        fieldName: 'categoryId',
        condition: 'must',
        values: category.map(Number)
      });
    }
    if (subcategory) {
      termFilters.push({
        fieldName: 'subCategoryId',
        condition: 'must',
        values: subcategory.map(Number)
      });
    }

    // === EntityType TermFilter Starts ===

    if (entityType === 'retailer') {
      termFilters.push({
        fieldName: 'retailerId',
        condition: 'must',
        values: [retailerId]
      });
    }

    if (entityType === 'brand') {
      termFilters.push({
        fieldName: 'brandId',
        condition: 'must',
        values: [String(brandId)]
      });
    }

    const additionalRequestMetaData = {
      derivedFieldsJson: '[]',
      useNewExports: 'True'
    };

    if (entityType === 'segment') {
      const { name, userId, queryId } = entityService.mainEntity;
      fileName.push(`segment${name}`);
      additionalRequestMetaData.segmentExport = 'True';
      additionalRequestMetaData.segmentUserId = userId;
      additionalRequestMetaData.segmentQueryId = queryId;
    }

    fileName.push(`week${startWeek}_${endWeek}`);

    const exportBodyObj = {
      fileName: fileName.join('_').replace('keyword', ''),
      additionalFieldsToReturn: [],
      aggregations: [
        {
          aggregationFields: exportOmniContentAccuracyAggregationFields,
          conditions: {
            rangeFilters: [
              {
                fieldName: 'weekId',
                minValue: startWeek,
                maxValue: endWeek
              }
            ],
            ...(entityType === 'retailer'
              ? {
                  termFilters: [
                    {
                      fieldName: 'retailerId',
                      condition: 'must',
                      values: [retailerId]
                    }
                  ]
                }
              : {})
          },
          groupByFieldName: groupByFieldName.join(','),
          sortDirection: 'desc',
          sortByAggregationField: {
            aggregateByFieldDisplayName: 'Title',
            aggregateByFieldName: 'titleAccuracy',
            function: 'computed',
            aggregateByFormula:
              '((titleStats.approvedContentCount_sum > 0) ? ((titleStats.matchedContentCount_sum / titleStats.approvedContentCount_sum)) : (1))',
            canBeExported: false
          }
        }
      ],
      conditions: {
        termFilters: [...termFilters],
        ...(entityType === 'segment'
          ? {
              rangeFilters: [
                {
                  fieldName: 'weekId',
                  minValue: startWeek,
                  maxValue: endWeek
                }
              ]
            }
          : {})
      },
      pageNumber: 1,
      pageSize: 20,
      period: 'year',
      sortFilter: {
        sortFields: [
          {
            aggregateByFieldDisplayName: 'Approved Title Count',
            aggregateByFieldName: 'titleStats.approvedContentCount',
            function: 'sum',
            canBeExported: false
          }
        ]
      },
      exportResults: true,
      searchBy: 'parent',
      returnDocuments: false,
      doAggregation: true,
      retailerId: '0',
      searchType: 'omni-contentChanged',
      id: 'entityGridMetricsentityGrid-product--1',
      name: 'entityGridMetricsentityGrid-product--1',
      processDocuments: true,
      additionalRequestMetaData,
      entityType: 'client',
      entityName: companyName,
      tab,
      subtab
    };

    const exportBody = [exportBodyObj];

    axios.post(apiUrl, exportBody).then(
      () => {
        this.props.eventBus.emit('downloadIconOff');
        this.props.eventBus.emit('showDownloadNotification');
        setTimeout(() => this.props.eventBus.emit('fetchDownloadList'), 3000);
      },
      (err) => {
        throw err;
      }
    );

    trackExport(location, user);
  };

  exportOmniShareOfShelf = (opt, subtab) => {
    const { firstGroupByValue, includeComparison, aggByWeek } = opt;
    const {
      apiUrl,
      mainTimePeriod,
      comparisonTimePeriod,
      omniEntity,
      entityService,
      location,
      user,
      filters,
      omniKeywordSegmentService
    } = this.props;
    const { startWeek, endWeek } = mainTimePeriod;
    const { startWeek: compareStartWeek, endWeek: compareEndWeek } = comparisonTimePeriod;
    const { retailerId, retailerName } = entityService.mainEntity;
    const { companyName } = omniEntity;

    const groupByFieldName = [firstGroupByValue, 'retailerId', ...(aggByWeek ? [`weekId`] : [])];
    const fileName = ['Omni', 'client', companyName, retailerName, firstGroupByValue];
    let actStartWeek = startWeek;

    const keywordSegmentFilter = [];
    const { keywordSegment } = filters;
    if (keywordSegment) {
      const [keywordSegmentId] = keywordSegment;
      const keywordSegmentNeedToFill = transferKeywordSegmentToExpReqFormat(
        keywordSegmentId,
        omniKeywordSegmentService
      );
      keywordSegmentFilter.push(...keywordSegmentNeedToFill);
    }

    if (includeComparison) {
      fileName.push(`week${compareStartWeek}_${endWeek}`);
      actStartWeek = compareStartWeek;
    } else {
      fileName.push(`week${startWeek}_${endWeek}`);
    }
    const exportBodyObj = {
      fileName: fileName.join('_').replace('keyword', ''),
      additionalFieldsToReturn: [],
      aggregations: [
        {
          aggregationFields: exportOmniShareOfShelfAggregationFieldsGenerator(subtab),
          ...(includeComparison
            ? {
                comparisonRangeFilters: [
                  {
                    fieldName: 'weekId',
                    minValue: compareStartWeek,
                    maxValue: compareEndWeek
                  }
                ]
              }
            : {}),
          conditions: {
            ...(aggByWeek
              ? {}
              : {
                  rangeFilters: [
                    {
                      fieldName: 'weekId',
                      minValue: startWeek,
                      maxValue: endWeek
                    }
                  ]
                })
          },
          groupByFieldName: groupByFieldName.join(',')
        }
      ],
      conditions: {
        termFilters: [
          {
            fieldName: 'retailerId',
            values: [String(retailerId)]
          },
          ...keywordSegmentFilter
        ],
        computeFilters: []
      },
      pageNumber: 1,
      pageSize: 50,
      exportResults: true,
      searchBy: firstGroupByValue === 'brandId' ? 'child' : 'parent',
      returnDocuments: false,
      doAggregation: true,
      retailerId: String(retailerId),
      searchType: 'omni-share-of-shelf',
      id: '',
      name: 'OmniSearchResultData-client-gl9avgmbG1',
      processDocuments: false,
      additionalRequestMetaData: {
        useNewExports: 'True',
        modifyAggregation: 'True'
      }
    };

    const exportBody = aggByWeek
      ? this.createShareOfShelfExportBody(exportBodyObj, actStartWeek, endWeek)
      : [exportBodyObj];

    axios.post(apiUrl, exportBody).then(
      () => {
        this.props.eventBus.emit('downloadIconOff');
        this.props.eventBus.emit('showDownloadNotification');
        setTimeout(() => this.props.eventBus.emit('fetchDownloadList'), 3000);
      },
      (err) => {
        throw err;
      }
    );

    trackExport(location, user);
  };

  createShareOfShelfExportBody = (exportBodyObj, startWeek, endWeek) => {
    const requests = [];
    const termFilter = {
      fieldName: 'weekId',
      values: []
    };
    for (let i = startWeek; i <= endWeek; i++) {
      const tempObj = JSON.parse(JSON.stringify(exportBodyObj));
      termFilter.values.push(i);
      tempObj.conditions.termFilters.push(JSON.parse(JSON.stringify(termFilter)));
      termFilter.values.pop();
      requests.push(tempObj);
    }
    return requests;
  };

  exportData = (type, groupByFieldName, includeCompTimePeriod) => {
    const {
      app,
      exportRequest,
      apiUrl,
      method,
      mainTimePeriod,
      allWeekIdsByRetailerId,
      retailer,
      entityService,
      user,
      adPlatforms
    } = this.props;

    const { tab, subtab } = app.queryParams;
    const weekIds = allWeekIdsByRetailerId[retailer.id];

    const request = _cloneDeep(exportRequest);

    if (mainTimePeriod.id === 'ytd') {
      const firstComparisonRangeFilter = _get(request, '[0].aggregations[0].comparisonRangeFilters[0]');
      if (firstComparisonRangeFilter) {
        switch (firstComparisonRangeFilter.fieldName) {
          case 'weekId': {
            firstComparisonRangeFilter.maxValue = getLastWeekPreviousYear(weekIds);
            break;
          }
          case 'dayId': {
            firstComparisonRangeFilter.maxValue = getLastDayPreviousYear(weekIds);
            break;
          }
          default: {
            // nothing
          }
        }
      }
    }

    const entityType = _get(entityService, ['mainEntity', 'type'], 'noType');
    const entityDisplayName = _get(entityService, ['mainEntity', 'displayName'], entityType);
    const entityName =
      entityType === 'adCampaign'
        ? _get(entityService, ['mainEntity', 'displayName'], 'no name')
        : _get(entityService, ['mainEntity', 'name'], 'no name');

    let requestOverride = [{}];

    if (type === 'Master' || type === 'Current') {
      requestOverride[0] = {
        sortFilter: null,
        returnDocuments: true,
        searchType: type === 'Master' ? 'beacon-contentApproved' : 'beacon-contentCurrent',
        doAggregation: false,
        searchBy: 'child',
        conditions: { rangeFilters: request[0].aggregations[0].conditions.rangeFilters }
      };
    } else if (type === 'ContentCombine') {
      // Change to most recent week
      request[0].aggregations[0].conditions.rangeFilters.forEach((filter) => {
        if (filter.fieldName === 'weekId') {
          filter.minValue = mainTimePeriod.endWeek;
          filter.maxValue = mainTimePeriod.endWeek;
        }
      });
      requestOverride = [
        {
          sortFilter: null,
          returnDocuments: true,
          searchType: 'beacon-contentApproved',
          doAggregation: false,
          searchBy: 'child',
          conditions: { rangeFilters: request[0].aggregations[0].conditions.rangeFilters }
        }
      ];
    } else if (['Unaggregated', 'Aggregated'].includes(type)) {
      if (request[0].aggregations[0].groupByFieldName === 'searchKeyword,matchingType') {
        request[0].searchType = 'advertising-adKeywordDailyMetrics';
        request[0].pageSize = 500;
      }
      request[0].aggregations[0].aggregationFields.forEach((field) => {
        if (field.aggregateByFieldDisplayName === 'Campaign Name' && field.aggregateByFieldName === 'campaignId') {
          field.aggregateByFieldDisplayName = 'No of Campaigns';
        }
      });

      request[0].additionalRequestMetaData = request[0].additionalRequestMetaData || {};
      if (groupByFieldName) {
        request[0].additionalRequestMetaData = {
          ...request[0].additionalRequestMetaData,
          EntityColumnToExport: JSON.stringify({ [entityDisplayName]: entityName }),
          Aggregations: { groupByField: [groupByFieldName] }
        };
      } else {
        request[0].additionalRequestMetaData = {
          ...request[0].additionalRequestMetaData,
          EntityColumnToExport: JSON.stringify({ [entityDisplayName]: entityName })
        };
      }

      if (type === 'Aggregated') {
        const requestClone = _cloneDeep(request[0]);
        requestClone.aggregations[0].conditions.rangeFilters = requestClone.aggregations[0].comparisonRangeFilters;
        request.push(requestClone);
      } else if (type === 'Unaggregated') {
        request[0].aggregations[0].groupByFieldName += ',dayId';
        request[0].conditions.rangeFilters = request[0].aggregations[0].conditions.rangeFilters;
      }
    }

    // As of the 2nd Aug, we got this error reported, but to be extra safe, I added all the conditions available,
    // but can be handled in EntityGridHeader export
    if (
      app.name === 'atlas' &&
      !type &&
      groupByFieldName &&
      groupByFieldName === 'stacklineSku' &&
      !includeCompTimePeriod &&
      tab !== 'promotions'
    ) {
      request[0].aggregations[0].conditions.rangeFilters.forEach((filter) => {
        if (filter.fieldName === 'weekId') {
          filter.minValue = mainTimePeriod.startWeek;
          filter.maxValue = mainTimePeriod.endWeek;
        }
      });
      requestOverride = [
        {
          conditions: { rangeFilters: request[0].aggregations[0].conditions.rangeFilters }
        }
      ];
    }
    if (app.name === 'atlas') {
      if (request[0].aggregations) {
        request[0].aggregations[0].aggregationFields.forEach((aggregationField) => {
          if (aggregationField.aggregateByFieldName === 'reviewsCount' && !groupByFieldName.includes('weekId')) {
            aggregationField.aggregateByTopHits = true;
            aggregationField.topHitsConditions = { sortByFieldName : "weekId" };
            aggregationField.function = "top_hits";
          }
          if ((aggregationField.aggregateByFieldName === 'reviewsCount' || aggregationField.aggregateByFieldName === 'reviewsRating') && groupByFieldName.includes('weekId')) {
            delete aggregationField.conditions;
          }
        });
      }
    }
    let fileNamePrefix;
    if (type === 'Master') {
      fileNamePrefix = 'MasterContent';
    } else if (type === 'Current') {
      fileNamePrefix = 'LiveCurrentContent';
    } else if (type === 'ContentCombine') {
      fileNamePrefix = 'MasterContent';
    } else if (['Unaggregated', 'Aggregated'].includes(type)) {
      fileNamePrefix = type;
    }

    // Enable break down by week even if there is a last value main metric, e.g. unitsOnHand
    // This way we can export multiple weeks but only display most recent in the UI
    // If we want to disable this for an export, we should disable the "Separate data by week" for the export

    if (groupByFieldName && groupByFieldName.includes('weekId')) {
      request[0].aggregations[0].conditions.rangeFilters.forEach((filter) => {
        if (filter.fieldName === 'weekId') {
          filter.minValue = mainTimePeriod.startWeek;
          filter.maxValue = mainTimePeriod.endWeek;
        }
      });
    }

    const fileName = this.generateFileName(fileNamePrefix, groupByFieldName, request);
    const defaultExportConfig = getDefaultExportConfig(fileName);

    const mergedRequest = _merge([], defaultExportConfig, request, requestOverride);

    if (Array.isArray(mergedRequest[0].aggregations) && mergedRequest[0].aggregations[0] && groupByFieldName) {
      mergedRequest[0].aggregations[0].groupByFieldName = groupByFieldName;
    }

    if (_get(mergedRequest, '[0].searchBy') === 'child') {
      mergedRequest[0].returnParentDocuments = true;
    }

    // Remove comparison time period if the user hasn't opted to include it in their export
    const hasComparisonTimePeriod = _get(mergedRequest, '[0].aggregations[0].comparisonRangeFilters');
    if (hasComparisonTimePeriod && !includeCompTimePeriod) {
      mergedRequest.forEach((req) => {
        delete req.aggregations[0].comparisonRangeFilters;
      });
    }

    if (mergedRequest[0] && mergedRequest[0].doAggregation && !!mergedRequest[0].aggregations) {
      maybeOverrideAggregationFields(
        app.name,
        mergedRequest,
        tab || app.defaultQueryParams.tab,
        subtab || app.defaultQueryParams.subtab
      );
      if (
        validIndexForIncrementality(retailer.id, app.stage) &&
        app.name === 'advertising' &&
        !isCriteo() &&
        shouldShowCriteo()
      ) {
        mergedRequest[0].aggregations[0].aggregationFields = [
          ...mergedRequest[0].aggregations[0].aggregationFields,
          {
            aggregateByFieldDisplayName: 'Incrementality',
            aggregateByFieldName: 'avgIncrementality',
            function: 'computed',
            aggregateByFormula: '((sales_sum > 0) ? ((brandTotalClicksIncrementalSales_sum / sales_sum)) : (0))',
            canBeExported: true
          },
          {
            aggregateByFieldDisplayName: 'Incremental Clicks',
            aggregateByFieldName: 'brandTotalClicksIncrementalClicks',
            function: 'computed',
            aggregateByFormula:
              '((sales_sum > 0) ? ((brandTotalClicksIncrementalSales_sum / sales_sum) * clicks_sum) : (0))',
            canBeExported: true
          },
          {
            aggregateByFieldDisplayName: 'Incremental Sales',
            aggregateByFieldName: 'brandTotalClicksIncrementalSales',
            function: 'sum',
            aggregateByFormula: 'brandTotalClicksIncrementalSales',
            canBeExported: true
          },
          {
            aggregateByFieldDisplayName: 'Incremental Units',
            aggregateByFieldName: 'brandTotalClicksIncrementalUnitsOrders',
            function: 'computed',
            aggregateByFormula:
              '((sales_sum > 0) ? ((brandTotalClicksIncrementalSales_sum / sales_sum) * unitsSold_sum) : (0))',
            canBeExported: true
          },
          {
            aggregateByFieldDisplayName: 'Incremental ROAS',
            aggregateByFieldName: 'brandTotalClicksIncrementalROAS',
            function: 'computed',
            aggregateByFormula: '((spend_sum > 0) ? ((brandTotalClicksIncrementalSales_sum / spend_sum)) : (0))',
            canBeExported: true
          }
        ];
      }
    }

    if (app.name === 'advertising' && !isCriteo() && amazonRetailersForAdvertising.includes(retailer.id)) {
      mergedRequest[0].aggregations[0].aggregationFields = [
        ...mergedRequest[0].aggregations[0].aggregationFields,
        {
          aggregateByFieldDisplayName: 'Same SKU Sales',
          aggregateByFieldName: 'sameSKUSales',
          function: 'sum',
          canBeExported: true
        },
        {
          aggregateByFieldDisplayName: 'Same SKU Conversions',
          aggregateByFieldName: 'sameSKUConversions',
          function: 'sum',
          canBeExported: true
        }
      ];
    }

    // If the time period is YTD and we're pulling prior period, we want the comparison time period to have
    // the same number of weeks as the main time period.
    if (hasComparisonTimePeriod && includeCompTimePeriod && mainTimePeriod.id === 'ytd') {
      mergedRequest.forEach((req) => {
        const comparisonRangeFilters = _get(req, 'aggregations[0].comparisonRangeFilters');
        if (Array.isArray(comparisonRangeFilters) && this.props.comparisonTimePeriod.id !== 'prior-period') {
          comparisonRangeFilters.filter(propEq('fieldName', 'weekId')).forEach((compRangeFilter) => {
            compRangeFilter.minValue = mainTimePeriod.startWeek - 100;
            compRangeFilter.maxValue = mainTimePeriod.endWeek - 100;
          });
        }
      });
    }

    const translationEnabled = _get(user, 'config.translationEnabled');

    // Add some additional fields to indicate that this is to use the new exports pipeline
    mergedRequest.forEach((req) => {
      if (!req.additionalRequestMetaData) {
        req.additionalRequestMetaData = {};
      }
      req.additionalRequestMetaData.useNewExports = 'True';
      // Used by segment export
      req.entityType = entityType;
      req.entityName = entityName;
      // Used by Atlas translated product titles
      if (app.name === AppName.Atlas && translationEnabled !== null) {
        const languageCode = _get(translationEnabled, req.retailerId, []);
        if (languageCode && languageCode.length > 0) {
          req.additionalRequestMetaData.translationEnabled = 'True';
          // eslint-disable-next-line prefer-destructuring
          req.additionalRequestMetaData.translationLanguageCode = languageCode[0];
        }
      }
    });

    const parentPlatform = getParentPlatform();
    if (app.name === AppName.Advertising) {
      // in the additionalRequestMetadata add the platform type
      // information as well
      const currentRetailerId = retailer.rid || retailer.id;
      // now find the platform type for this retailer id
      let currentPlatform = adPlatforms.find((x) => x.retailerId === currentRetailerId);

      if (!currentPlatform) {
        currentPlatform = adPlatforms.find((x) => x.extendedAttributes.retailerId === Number(currentRetailerId));
      }
      mergedRequest[0].additionalRequestMetaData = {
        ...mergedRequest[0].additionalRequestMetaData,
        platformType: _get(currentPlatform, 'platformType', null)
      };
      if (groupByFieldName.includes('campaignId')) {
        mergedRequest[0].additionalRequestMetaData = {
          ...mergedRequest[0].additionalRequestMetaData,
          isBudgetAdded: true
        };
      }
      if (
        exportRequest &&
        exportRequest.length > 0 &&
        exportRequest[0].retailerId === '63' &&
        groupByFieldName.includes('adGroupId') &&
        groupByFieldName.includes('stacklineSku')
      ) {
        mergedRequest[0].searchType = 'advertising-adCampaignAdGroupProductTargetDailyMetrics';
      } else if (groupByFieldName.includes('stacklineSku,targetingText,targetingType')) {
        mergedRequest[0].searchType = 'advertising-adCampaignAdGroupProductTargetDailyMetrics';
        // remove is auto added if this is the case
        if (groupByFieldName.includes('isAutoAdded')) {
          const splitGroupByFields = groupByFieldName.split(',');
          const findIndex = splitGroupByFields.indexOf('isAutoAdded');
          splitGroupByFields.splice(findIndex, 1);
          groupByFieldName = splitGroupByFields.join(',');
          mergedRequest[0].aggregations[0].groupByFieldName = groupByFieldName;
        }
      } else if (
        groupByFieldName.includes('adGroupId') &&
        !(groupByFieldName.includes('stacklineSku') || groupByFieldName.includes('targetingText,targetingType'))
      ) {
        mergedRequest[0].searchType = 'advertising-adCampaignAdGroupDailyMetrics';
      } else if (groupByFieldName.includes('targetingText,targetingType')) {
        mergedRequest[0].searchType = 'advertising-adCampaignAdGroupTargetDailyMetrics';
      } else if (
        ['brandId', 'categoryId', 'subCategoryId', 'stacklineSku'].find((str) => groupByFieldName.includes(str))
      ) {
        mergedRequest[0].searchType = 'advertising-adCampaignAdGroupProductDailyMetrics';
      } else if (parentPlatform && parentPlatform === PARENT_PLATFORMS.CRITEO && shouldShowCriteo()) {
        // for single retailer on Criteo we use adGroup
        if (groupByFieldName.includes('retailerId') && retailer.id !== 0) {
          mergedRequest[0].searchType = 'advertising-adCampaignAdGroupDailyMetrics';
        } else {
          // handle all retailer lvl
          // TODO:
          mergedRequest[0].searchType = 'advertising-adCampaignDailyMetrics';
        }
      } else {
        mergedRequest[0].searchType = 'advertising-adCampaignDailyMetrics';
      }
      const isProjectedFilter = _get(mergedRequest[0].aggregations[0], ['conditions', 'termFilters'], []).find(
        (filter) => filter.fieldName === 'isProjected'
      );
      mergedRequest[0].conditions.termFilters.push(isProjectedFilter);
      delete mergedRequest[0].aggregations[0].conditions.termFilters;
    }
    this.props.eventBus.emit('downloadIconOn');
    mergedRequest[0].tab = tab;
    mergedRequest[0].subtab = subtab;

    let copyOfOVerrides = _cloneDeep(mergedRequest);

    // CRITEO overrides
    if (parentPlatform && parentPlatform === PARENT_PLATFORMS.CRITEO && shouldShowCriteo()) {
      const newOverride = [];
      let customRules = [
        {
          action: 'add',
          path: ['conditions', 'termFilters'],
          newObj: {
            fieldName: 'parentPlatform',
            condition: 'must',
            values: [parentPlatform]
          }
        },
        {
          action: 'add',
          path: ['aggregations', '[0]', 'conditions', 'termFilters'],
          newObj: {
            fieldName: 'parentPlatform',
            condition: 'must',
            values: [parentPlatform]
          }
        }
      ];

      // for all retailers remove rid
      if (retailer.id === '0') {
        customRules = [
          ...customRules,
          {
            action: 'remove',
            path: ['aggregations', '[0]', 'conditions', 'termFilters'],
            conditionKey: 'fieldName',
            conditionValue: 'retailerId'
          },
          {
            action: 'remove',
            path: ['conditions', 'termFilters'],
            conditionKey: 'fieldName',
            conditionValue: 'retailerId'
          },
          {
            action: 'delete',
            path: ['retailerId']
          }
        ];
      }

      copyOfOVerrides.forEach((_, indx) => {
        const mutatedQuery = modifyESQuery(
          copyOfOVerrides[indx],
          getESBodyOverridesForParentPlatform(parentPlatform, customRules)
        );
        newOverride.push(mutatedQuery);
      });

      copyOfOVerrides = newOverride;
    }

    this.makeGroupByConsistent(copyOfOVerrides, groupByFieldName);

    axios({
      method,
      url: `${apiUrl}`,
      data: copyOfOVerrides
    }).then(
      () => {
        this.props.eventBus.emit('downloadIconOff');
        this.props.eventBus.emit('showDownloadNotification');
        setTimeout(() => this.props.eventBus.emit('fetchDownloadList'), 3000);
      },
      (err) => {
        throw err;
      }
    );

    trackExport(
      this.props.location,
      {
        doAggregation: copyOfOVerrides[0].doAggregation,
        groupByFieldName,
        type,
        includeCompTimePeriod,
        weekIdBreakdown: (groupByFieldName || '').includes('weekId'),
        dayIdBreakdown: (groupByFieldName || '').includes('dayId'),
        fileName,
        numberOfRequests: copyOfOVerrides.length
      },
      user
    ); //  TODO: add group by pararms, etc
  };

  openExportDialog = (type) => {
    this.setState({ exportDialogOpen: true, exportType: type });
  };

  doExportForOmni(opt) {
    const {
      queryParams: { tab, subtab }
    } = this.props.app;
    const { firstGroupByValue, aggByWeek, selectedWeekID } = opt;

    // Share of shelf does not follow subtab pattern.
    if (tab === 'shareOfShelf') {
      this.exportOmniShareOfShelf(opt, subtab);
      this.setState({ exportDialogOpen: false });
      return;
    }
    const exportFunctions = {
      contentAccuracy: () => this.exportOmniContentAccuracy(firstGroupByValue, aggByWeek, selectedWeekID, tab, subtab),
      contentScore: () => this.exportOmniContentScore(firstGroupByValue, aggByWeek, selectedWeekID, tab, subtab),
      reviewTrends: () => this.exportOmniReviews(aggByWeek, tab, subtab),
      highRiskReviews: () => this.exportOmniReviews(aggByWeek, tab, subtab),
      default: () => {
        const { secondGroupByValue, thirdGroupByValue } = opt;
        this.exportDataOmni(
          firstGroupByValue,
          secondGroupByValue,
          thirdGroupByValue,
          aggByWeek,
          selectedWeekID,
          subtab
        );
      }
    };

    const exportFunction = exportFunctions[subtab] || exportFunctions.default;

    exportFunction();
    this.setState({ exportDialogOpen: false });
  }

  render() {
    return (
      <ExportDialog
        open={this.state.exportDialogOpen}
        onClose={() => this.setState({ exportDialogOpen: false })}
        doExport={(groupByFieldName, includeCompTimePeriod) => {
          this.exportData(this.state.exportType, groupByFieldName, includeCompTimePeriod);
          this.setState({ exportDialogOpen: false });
        }}
        doExportForOmni={this.doExportForOmni}
      />
    );
  }
}

const enhance = compose(
  withRouter,
  connect((state) => state),
  withBus('eventBus')
);

const EnhancedExport = enhance(Export);

export default EnhancedExport;
