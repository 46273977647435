import { IScheduledAction } from 'sl-ad-campaign-manager-data-model';

const RECEIVE_SCHEDULED_ACTION_GROUPS = 'src/adEntities/RECEIVE_SCHEDULED_ACTION_GROUPS' as const;
const CLEAR_SCHEDULED_ACTION_GROUPS = 'src/adEntities/CLEAR_SCHEDULED_ACTION_GROUPS' as const;

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IScheduledActionsGroup {
  id: string;
  name: string;
  displayName: string;
  shortDisplayName: string;
  scheduleDetail: IScheduleDetail | null;
  scheduledActions: { scheduledAction: IScheduledAction; id: string; name: string; displayName: string }[];
  type: 'scheduledActionsGroup';
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IScheduleDetail {
  action: string;
  period: string;
  plan: string;
  startTime: string;
  endTime: string;
  nextStartDate: string;
  nextEndDate: string;
  prefill: any;
}

export default {
  RECEIVE_SCHEDULED_ACTION_GROUPS,
  CLEAR_SCHEDULED_ACTION_GROUPS
};
