import React from 'react';
import ProductCard from '../ProductCard/ProductCard';
import _chunk from 'lodash/chunk';
import { ProductEntity } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/types';
import { getProductImageUrl } from 'src/utils/image';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import ProductGridLoading from './ProductGridLoading';
import { ValueOf } from 'sl-api-connector';
import { calculatePercentChange } from 'src/utils/app';
import { Direction, getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import _get from 'lodash/get';
import { SlRow } from 'src/components/BeaconRedesignComponents/Generic/SlRow';
import { SlColumn } from 'src/components/BeaconRedesignComponents/Generic/SlColumn';

export const GRID_ITEMS_PER_PAGE = 20;
const NUM_COLUMNS = 4;

export type ProductGridDatum = {
  product: ProductEntity;
} & {
  [fieldName: string]: {
    value: number;
    difference: number;
  };
};

export interface ProductGridProps {
  data: ProductGridDatum[];
  showExcludeProductButton?: boolean;
  loading: boolean;
  metricType: ValueOf<typeof METRICTYPE>;
  page: number;
  fieldName: string;
  mainMetricOverride?: (metricValue: number, dataRow: any) => string;
  secondaryMetricOverride?: (valueDifference: number, dataRow: any) => string;
  directionOverride?: (dataRow: any) => Direction | null;
  showDirectionOverride?: (dataRow: any) => boolean;
  handleExcludeProduct?: (retailerSku: string) => void;
  noDataPlaceholder?: React.ReactNode;
}

/**
 * Takes a list of products and renders product cards in a grid.
 */
const ProductGrid = ({
  data,
  loading,
  metricType,
  page,
  mainMetricOverride,
  secondaryMetricOverride,
  directionOverride,
  showDirectionOverride,
  fieldName,
  showExcludeProductButton,
  handleExcludeProduct,
  noDataPlaceholder
}: ProductGridProps) => {
  const formatMetric = useMetricFormatter();
  const { searchParams, additionalParams } = useAppSelector((state) => state.app.queryParams);
  const { targetUrl, name } = useAppSelector((state) => state.app);
  const retailerId = useAppSelector((state) => state.retailer.id);

  return (
    <SlColumn widths="full" spacing="cardGrid">
      {loading ? (
        <ProductGridLoading />
      ) : data.length < 1 && noDataPlaceholder ? (
        noDataPlaceholder
      ) : (
        <SlColumn spacing="cardGrid" widths="full">
          {_chunk(
            data.slice((page - 1) * GRID_ITEMS_PER_PAGE, (page - 1) * GRID_ITEMS_PER_PAGE + GRID_ITEMS_PER_PAGE),
            NUM_COLUMNS
          ).map((items, rowIndex) => (
            <SlRow key={items.map((i) => i.product.stacklineSku).join('')} spacing="mdl" horizontalPosition="start">
              {items.map((row, colIndex) => {
                const indexOnPage = rowIndex * NUM_COLUMNS + colIndex;
                const dataIndex = (page - 1) * GRID_ITEMS_PER_PAGE + indexOnPage;
                const { product } = row;
                const metricValue = _get(row, [fieldName, 'value'], 0);
                const valueDifference = _get(row, [fieldName, 'difference'], 0);

                return (
                  <ProductCard
                    key={product.stacklineSku}
                    availabilityStatusCode={product.availabilityStatusCode}
                    brandUrl={`/brand/${product.brandId}${searchParams}${additionalParams}`}
                    categoryName={product.categoryName}
                    categoryUrl={`/category/${product.categoryId}${searchParams}${additionalParams}`}
                    direction={directionOverride ? directionOverride(data[dataIndex]) : getDirection(valueDifference)}
                    showDirection={showDirectionOverride ? showDirectionOverride(data[dataIndex]) : true}
                    imageUri={getProductImageUrl(product.stacklineSku)}
                    mainDisplayTitle={product.brandName}
                    showExcludeProductButton={showExcludeProductButton}
                    handleExcludeProduct={handleExcludeProduct}
                    mainMetric={`${
                      mainMetricOverride
                        ? mainMetricOverride(metricValue, data[dataIndex])
                        : formatMetric(metricValue, metricType, {
                            showFullValue: metricType === METRICTYPE.PERCENT,
                            decimalPlaces: 1,
                            showNegative: true
                          })
                    }`}
                    productUrl={`/product/${product.stacklineSku}${searchParams}${additionalParams}`}
                    retailerSku={product.retailerSku}
                    retailerUrl={`https://${targetUrl}/api/utility/OpenRetailerProductPage?appName=${name}&retailerId=${retailerId}&stacklineSku=${product.stacklineSku}`}
                    secondaryDisplayTitle={product.title}
                    secondaryMetric={
                      secondaryMetricOverride
                        ? secondaryMetricOverride(valueDifference, data[dataIndex])
                        : `${formatMetric(valueDifference, metricType, {
                            decimalPlaces: 1,
                            showFullValue: metricType === METRICTYPE.PERCENT
                          })} (${formatMetric(
                            calculatePercentChange(metricValue, metricValue - valueDifference),
                            METRICTYPE.PERCENT,
                            {
                              formatString: '0,0%',
                              showFullValue: true
                            }
                          )})`
                    }
                    stacklineSku={product.stacklineSku}
                    statusColor={product.availabilityStatusCode === 'In stock' ? 'success' : 'error'}
                    subCategoryName={product.subCategoryName}
                    subcategoryUrl={`/subcategory/${product.subCategoryId}${searchParams}${additionalParams}`}
                  />
                );
              })}
            </SlRow>
          ))}
        </SlColumn>
      )}
    </SlColumn>
  );
};

export default ProductGrid;
