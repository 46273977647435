export const AD_PRIMARY_ATTRIBUTION_EVENT = 'adPrimaryAttributionEvent';
export const AD_SECONDARY_ATTRIBUTION_EVENT = 'adSecondaryAttributionEvent';

/**
 * The key used to access First Touch metrics on the returned Adv. Search documents
 */
export const FIRST_TOUCH_IMPRESSIONS = 'impressions_sum_value';
export const FIRST_TOUCH_CLICKS = 'clicks_sum_value';
export const FIRST_TOUCH_AD_SALES = 'adSales_sum_value';
export const FIRST_TOUCH_AD_UNITS_SOLD = 'adUnitsSold_sum_value';
export const FIRST_TOUCH_CONVERSIONS = 'usersThatConverted_sum_value';
