import axios from 'axios';

interface CreateUserInCurrentDivisionResponse {
  response: number;
}

export default function CreateUserInCurrentDivision(payload: {
  email: string;
  firstName: string;
  lastName: string;
  currentUserEmail: string;
  multiUserEmail?: string;
}) {
  const { currentUserEmail, email, firstName, lastName, multiUserEmail } = payload;
  return axios.post<CreateUserInCurrentDivisionResponse>(
    'api/user/CreateUserInCurrentDivision',
    {
      email,

      firstName,
      lastName,
      ...(multiUserEmail && { emailAlias: multiUserEmail })
    },
    {
      params: {
        activeUserEmail: currentUserEmail
      }
    }
  );
}
