import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppSelector, useUpdateQueryParams } from 'src/utils/Hooks';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import {
  buildCustomDateRangeDisplayName,
  computeComparisonTimePeriod,
  getDayIdFromDate
} from 'src/utils/dateformatting';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { updateAllTimePeriods, updateMainTimePeriod } from 'src/store/modules/main-time-period/operations';
import { History, Location } from 'history';
import { getWeekId, getStartAndEndOfWeek } from 'src/utils/dateUtils';
import { useBeaconRoutes } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';
import AppCalendarContainer from 'src/components/BeaconRedesignComponents/common/SlCalendarPicker/AppCalendarContainer';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';

interface TimePeriodDropdownProps {
  location: Location;
  history: History;
}

/**
 * Hook which returns a function used to update the main and comparison time periods and dispatch actions for updating Redux state and query parameters.
 *
 * @param {Object} options - Options object containing the history object.
 * @param {History} options.history - The history object from react-router-dom.
 * @returns {Function} - A function to update time periods.
 */
export const useUpdateTimePeriods = ({ history }: { history: History }) => {
  const retailer = useAppSelector((state) => state.retailer);
  const comparisonTimePeriod = useAppSelector((state) => state.comparisonTimePeriod);
  const allWeekIdsByRetailerId = useAppSelector((state) => state.allWeekIdsByRetailerId);
  const weekIdCollection = allWeekIdsByRetailerId[retailer.id];
  const dispatch = useDispatch();
  const updateQuery = useUpdateQueryParams(history);

  const { id: comparisonTimeWindow } = _cloneDeep(comparisonTimePeriod);

  return (timePeriod) => {
    const updatedMainPeriod = {
      ...timePeriod,
      startWeek: timePeriod.startWeek,
      endWeek: timePeriod.endWeek,
      startDaydId: timePeriod.startDayId,
      endDayId: timePeriod.endDayId,
      startWeekStartDate: timePeriod.startWeekStartDate,
      endWeekEndDate: timePeriod.endWeekEndDate,
      id: timePeriod.id,
      shortDisplayName: timePeriod.displayName
    };

    const updatedComparisonPeriod = computeComparisonTimePeriod(
      weekIdCollection,
      updatedMainPeriod,
      comparisonTimeWindow
    );

    updatedComparisonPeriod.availableComparisonTimePeriods = comparisonTimePeriod.availableComparisonTimePeriods;
    updatedComparisonPeriod.comparisonIndex = comparisonTimePeriod.availableComparisonTimePeriods
      .map((x) => x.id)
      .indexOf(comparisonTimePeriod.id);
    dispatch(updateAllTimePeriods(weekIdCollection, updatedMainPeriod, updatedComparisonPeriod));
    updateQuery({ updatedMainPeriod, updatedComparisonPeriod });
  };
};

const TimePeriodDropdown: FC<TimePeriodDropdownProps> = ({ history, location }) => {
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const comparisonTimePeriod = useAppSelector((state) => state.comparisonTimePeriod);
  const retailer = useAppSelector((state) => state.retailer);
  const allWeekIdsByRetailerId = useAppSelector((state) => state.allWeekIdsByRetailerId);
  const app = useAppSelector((state) => state.app);
  const { dataFrequency } = app;
  const [selectedPeriodId, setSelectedPeriodId] = useState(mainTimePeriod.id);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const dispatch = useDispatch();
  const updateQuery = useUpdateQueryParams(history);

  const { currentSubtab } = useBeaconRoutes({ location });

  const timePeriods = mainTimePeriod.availableMainTimePeriods;
  const updateTimePeriods = useUpdateTimePeriods({ history });

  const selectedTimePeriod = useMemo(() => {
    return (
      timePeriods.find((period) => period.id === selectedPeriodId) ||
      timePeriods.find((period) => _get(period, 'default') === true)
    );
  }, [selectedPeriodId, timePeriods]);

  useEffect(() => {
    const { availableMainTimePeriods } = mainTimePeriod;
    const selectedOption = _cloneDeep(
      availableMainTimePeriods.filter((val) => val.id === mainTimePeriod.id)[0] || availableMainTimePeriods[4]
    );

    if (selectedOption.id === 'cd') {
      const shortDisplayName = buildCustomDateRangeDisplayName(mainTimePeriod.startDayId, mainTimePeriod.endDayId);
      dispatch(
        updateMainTimePeriod(
          mainTimePeriod.startWeek,
          mainTimePeriod.endWeek,
          selectedOption.id,
          shortDisplayName,
          availableMainTimePeriods,
          mainTimePeriod.startWeekStartDate,
          mainTimePeriod.startDayId,
          mainTimePeriod.endWeekEndDate,
          mainTimePeriod.endDayId
        )
      );
    }
  }, []);

  useEffect(() => {
    setSelectedPeriodId(mainTimePeriod.id);
  }, [mainTimePeriod.id]);

  const handleDateRangeChange = (periodId: string) => {
    if (periodId === 'cd') {
      setIsCalendarOpen(true);
    } else {
      setSelectedPeriodId(periodId);
      const newTimePeriod = timePeriods.find((period) => period.id === periodId);
      updateTimePeriods(newTimePeriod);
    }
  };

  const handleOutsideClick = () => {
    setIsCalendarOpen(false);
  };

  const updateWeeks = (
    mainStartWeek: number,
    mainEndWeek: number,
    mainId: string,
    shortDisplayName: string | null,
    isCustomDate: boolean,
    startDayId: number,
    endDayId: number,
    startWeekStartDate: string,
    endWeekEndDate: string
  ) => {
    const allWeekIds = allWeekIdsByRetailerId[retailer.id];
    const { id: comparisonTimeWindow } = _cloneDeep(comparisonTimePeriod);
    let updatedMainPeriodDisplayName = shortDisplayName;

    if (isCustomDate) {
      updatedMainPeriodDisplayName = buildCustomDateRangeDisplayName(startDayId, endDayId);
      // You cannot use `setState` in a functional component. Instead, handle the state using hooks.
      // For this specific case, you can use another state variable for 'isCalendarOpen' and set it to 'false' when needed.
    }

    const updatedMainPeriod = {
      startWeek: mainStartWeek,
      endWeek: mainEndWeek,
      startDayId,
      endDayId,
      startWeekStartDate,
      endWeekEndDate,
      id: mainId,
      shortDisplayName: updatedMainPeriodDisplayName
    };

    const updatedComparisonPeriod = computeComparisonTimePeriod(allWeekIds, updatedMainPeriod, comparisonTimeWindow);
    updatedComparisonPeriod.availableComparisonTimePeriods = comparisonTimePeriod.availableComparisonTimePeriods;
    updatedComparisonPeriod.comparisonIndex = comparisonTimePeriod.availableComparisonTimePeriods
      .map((x) => x.id)
      .indexOf(comparisonTimePeriod.id);

    dispatch(updateAllTimePeriods(allWeekIds, updatedMainPeriod, updatedComparisonPeriod));

    updateQuery({ updatedMainPeriod, updatedComparisonPeriod });
  };

  const shouldDisableMainTimePeriod = () => {
    return currentSubtab === 'growthmatrix';
  };

  const handleCustomDateRangeChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    if (startDate === null || endDate === null) {
      return;
    }

    const startWeekId = getWeekId(startDate);
    const endWeekId = getWeekId(endDate);
    let { startDate: startWeekStartDate } = getStartAndEndOfWeek(startWeekId);
    let { endDate: endWeekEndDate } = getStartAndEndOfWeek(endWeekId);

    if (dataFrequency === 'daily') {
      startWeekStartDate = startDate;
      endWeekEndDate = endDate;
    }

    updateWeeks(
      startWeekId,
      endWeekId,
      'cd',
      null,
      true,
      getDayIdFromDate(startWeekStartDate),
      getDayIdFromDate(endWeekEndDate),
      startWeekStartDate,
      endWeekEndDate
    );
    handleOutsideClick();
  };

  const timePeriodOptions = timePeriods.map((period) => {
    return { id: period.id, label: period.shortDisplayName };
  });

  return (
    <>
      <SlDropdownMenu
        onChange={(period) => handleDateRangeChange(period.id)}
        defaultLabel={selectedTimePeriod.shortDisplayName}
        selectedId={selectedTimePeriod.id}
        options={timePeriodOptions}
        disabled={shouldDisableMainTimePeriod()}
      />
      {isCalendarOpen && (
        <AppCalendarContainer
          handleCustomDateRangeChange={handleCustomDateRangeChange}
          open={isCalendarOpen}
          setCalendarOpen={setIsCalendarOpen}
        />
      )}
    </>
  );
};

export default withRouter(TimePeriodDropdown);
