import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import queryString from 'qs';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import _flatten from 'lodash/flatten';
import _partition from 'lodash/partition';
import _pick from 'lodash/pick';
import _prop from 'lodash/property';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import colors from 'src/utils/colors';
import fontStyle from 'src/utils/fontStyle';
import * as appOperations from 'src/store/modules/app/operations';
import { updateAllTimePeriods } from 'src/store/modules/main-time-period/operations';
import { computeMainTimePeriod, computeComparisonTimePeriod } from 'src/utils/dateformatting';
import { ChevronIcon } from '../SvgIcons';
import ReduxStore from 'src/types/store/reduxStore';
import { QueryResponse } from 'src/types/application/types';
import { AvailableRetailer } from 'src/types/store/storeTypes';
import { shouldShowBeaconProForUser, usesCustomTimePeriods, usesSecondaryRetailers } from 'src/utils/app';
import { getInitialState as getDefaultTimePeriods } from 'src/store/modules/main-time-period/reducers';
import { BEACON_SUBTABS, BEACON_TABS } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['allWeekIdsByRetailerId', 'app', 'comparisonTimePeriod', 'mainTimePeriod', 'retailer', 'user']);

const mapDispatchToProps = {
  updateQueryParams: appOperations.updateQueryParams,
  updateAllAppTimePeriods: updateAllTimePeriods
};

type RetailerDropDownProps = RouteComponentProps &
  ReturnType<typeof mapStateToProps> & {
    labelStyle?: { color: string; fontWeight: number };
    updateQueryParams: any;
    updateAllAppTimePeriods: any;
  };

class RetailerDropDown extends Component<RetailerDropDownProps> {
  public static readonly defaultProps = {
    labelStyle: { color: colors.darkBlue, fontWeight: fontStyle.regularWeight }
  };

  // Determines if the retailer is a Beacon Pro enabled retailer
  private getIsBeaconProRetailerEnabled = (id: number, user: ReduxStore['user']) => {
    const beaconProEnabledInFoundry =
      _get(user, ['config', 'subscriptionsByRetailers', id, 'BeaconProSubscription']) === 'true';

    return beaconProEnabledInFoundry;
  };

  private handleRetailerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      allWeekIdsByRetailerId,
      app,
      location,
      mainTimePeriod,
      comparisonTimePeriod,
      updateQueryParams,
      updateAllAppTimePeriods,
      user
    } = this.props;
    const retailerId = +event.target.value;
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const { availableMainTimePeriods } = usesCustomTimePeriods() ? getDefaultTimePeriods() : mainTimePeriod;
    const { availableComparisonTimePeriods } = comparisonTimePeriod;
    const allWeekIds = allWeekIdsByRetailerId[retailerId];

    const comparisonTimePeriodId =
      comparisonTimePeriod && comparisonTimePeriod.id !== undefined
        ? comparisonTimePeriod.id
        : availableComparisonTimePeriods[0].id;

    // Default to first app default time period if using secondary retailers
    const mainPeriodIndex = usesCustomTimePeriods()
      ? availableMainTimePeriods.findIndex((period) => period.default)
      : mainTimePeriod.availableMainTimePeriods.findIndex((x) => x.id === mainTimePeriod.id);
    const newTimePeriod = computeMainTimePeriod(allWeekIds, availableMainTimePeriods[mainPeriodIndex], app);
    const compPeriodId = availableComparisonTimePeriods.find((x) => x.id === comparisonTimePeriodId)!.id;
    const updatedComparisonPeriod = computeComparisonTimePeriod(allWeekIds, newTimePeriod, compPeriodId);
    updatedComparisonPeriod.comparisonIndex = availableComparisonTimePeriods.findIndex(
      (x) => x.id === comparisonTimePeriodId
    );

    // If we're on legacy Beacon and we click a Beacon Pro subscribed retailer, open Beacon Pro
    if (this.getIsBeaconProRetailerEnabled(retailerId, user) && shouldShowBeaconProForUser()) {
      const { startWeek, endWeek, startDayId, endDayId } = newTimePeriod;

      const params = new URLSearchParams();
      params.set('rid', `${retailerId}`);
      params.set('pid', 'prior-year');
      params.set('sw', startWeek);
      params.set('ew', endWeek);
      params.set('sdid', startDayId);
      params.set('edid', endDayId);
      params.set('wr', 'ytd');
      params.set('tab', BEACON_TABS.SUMMARY);
      params.set('subtab', BEACON_SUBTABS.KEY_METRICS);

      return window.open(`https://beaconpro.stackline.com${location.pathname}?${params.toString()}`, '_blank');
    } else {
      updateAllAppTimePeriods(allWeekIds, newTimePeriod, updatedComparisonPeriod);

      updateQueryParams(app, { id: retailerId }, newTimePeriod, updatedComparisonPeriod, queryParams).then(
        (response: QueryResponse) => {
          const { additionalParams, dropDownSelectionParams, nonPersistentParams, searchParams } = response.params;
          let customParams = queryParams.tab || queryParams.entityType ? dropDownSelectionParams : additionalParams;
          // Go back to home page if retailer changes on ad manager
          if (app.name === 'advertising') {
            location.pathname = '/overview';
            customParams = '&tab=adManager&subtab=keyMetrics';
          }
          return window.open(`${location.pathname}${searchParams}${customParams}${nonPersistentParams}`, '_self');
        }
      );
    }
    return null;
  };

  public render() {
    const { retailer, user, app } = this.props;
    const { availableRetailers } = retailer;

    const isRetailerActive = (r: AvailableRetailer): boolean => {
      if (user.config.secondaryRetailerIds && usesSecondaryRetailers()) {
        return (
          user.config.secondaryRetailerIds.includes(parseInt(r.id, 10)) &&
          user.config.allWeekIdsByRetailerId[+r.id] &&
          r.supportedAppNames.includes(app.name)
        );
      }

      return (
        user.config.allRetailerIds.includes(parseInt(r.id, 10)) &&
        user.config.allWeekIdsByRetailerId[+r.id] &&
        r.supportedAppNames.includes(app.name)
      );
    };
    const availableRetailersWithActiveProp = availableRetailers.map((val) => ({
      ...val,
      active: isRetailerActive(val)
    }));
    // Sort active retailers on top of inactive retailers with each (active and inactive) sorted by display name
    const orderedRetailers = _flatten(
      _partition(availableRetailersWithActiveProp, _prop('active')).map((coll) => _sortBy(coll, 'displayName'))
    );

    return (
      <Select
        variant="standard"
        value={retailer.id}
        // The type given by Material UI disagrees with the type of the actual event.
        onChange={this.handleRetailerChange as any}
        input={<OutlinedInput labelWidth={0} id="outlined-age-simple" />}
        IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
        className="retailer-dropdown"
      >
        {orderedRetailers
          .filter((val) => {
            if (retailer.id === '0') {
              return val.id === '0'; // only render the All Retailers item
            }

            return val.id !== '0'; // render all but All Retailers
          })
          .map((val) => (
            <MenuItem
              key={val.name}
              value={val.id}
              disabled={!val.active}
              title={
                !user.config.allRetailerIds.includes(parseInt(val.id, 10))
                  ? `It appears that you don't currently have ${val.displayName} data integrated with Stackline.\nPlease contact us at support@stackline.com to get started.`
                  : ''
              }
            >
              {val.displayName}
            </MenuItem>
          ))}
      </Select>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RetailerDropDown));
