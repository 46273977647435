import { ValueOf } from 'sl-api-connector/types';

import types from './types';
import actionCreators from './actions';

type Action = ValueOf<{ [K in keyof typeof actionCreators]: ReturnType<(typeof actionCreators)[K]> }>;

export default (state = [] as any[], action: Action) => {
  switch (action.type) {
    case types.RECEIVE_SCHEDULED_ACTION_GROUPS:
      return action.scheduledActionGroups;
    case types.CLEAR_SCHEDULED_ACTION_GROUPS:
      return [];
    default:
      return state;
  }
};
