import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import ReduxStore from 'src/types/store/reduxStore';
import { PageLayout, Widget } from 'src/types/application/widgetTypes';
import OmniWidgetGroupReview from 'src/components/Omni/OmniWidgetGroup/OmniWidgetGroupReview';
import FilterKeywords from 'src/components/EntityPage/Reviews/ReviewsContainer/FilterKeywords';
import { buildTrendWidgetConfig } from 'src/components/Layout/LayoutUtil';
import { buildOmniTopEntitiesWidgetConfig } from 'src/components/EntityPage/TopEntitiesChart/OmniTopEntitesChartUtil';
import OmniTrendChart from 'src/components/EntityPage/TrendChart/OmniTrendChart';
import _cloneDeep from 'lodash/cloneDeep';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { AppName } from 'sl-api-connector/types';
import OmniReviewHighRisk from 'src/components/Omni/OmniReviewColumn/OmniReviewHighRisk';
import OmniFilterWrapper from 'src/components/Omni/OmniFilter/OmniFIlterWrapper';
import OmniTopEntitiesChart from 'src/components/EntityPage/TopEntitiesChart/OmniTopEntitiesChart';
import { ExportIcon } from 'src/components/SvgIcons';
import PageContextMenu from 'src/components/PageContextMenu';

const OMNI_REVIEW_INDEX = 'reviews';
const indexName = OMNI_REVIEW_INDEX;

export const getLayoutForEntity = (app: ReduxStore['app'], subtab: string, entityType: string): PageLayout => {
  const widgets: Widget[] = [];
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(AppName.Omni, indexName, 'weekId'));
  widgets.push({
    CustomComponent: OmniFilterWrapper,
    name: 'omniFilter',
    view: {
      name: 'omniFilter',
      style: {
        display: 'flex',
        justifyContent: 'end',
        marginTop: 41
      },
      shouldShowFilter: !(entityType === 'segment')
    },
    data: {}
  });
  switch (subtab) {
    case 'reviewTrends': {
      widgets.push({
        name: 'filterReviewKeywords',
        CustomComponent: FilterKeywords,
        view: {
          name: 'filterReviewKeywords',
          container: {
            style: {
              marginBottom: 100,
              marginTop: 30,
              verticalAlign: 'top'
            }
          }
        },
        data: {}
      });
      const group = {
        name: 'groupChart',
        CustomComponent: OmniWidgetGroupReview,
        view: { name: 'groupChart' },
        data: [],
        rightWidget: buildTrendWidgetConfig(app, indexName, { type: entityType }, 'weekId', ['stars'], weekIdField, {
          CustomComponent: OmniTrendChart,
          view: {
            container: {
              style: {
                marginTop: 30,
                verticalAlign: 'top'
              }
            },
            chartPropsOverride: {
              title: { text: 'Rating' }
            }
          }
        }),
        extraWidget: buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          ['retailerId'],
          ['stars'],
          weekIdField,
          {
            CustomComponent: OmniTopEntitiesChart,
            view: {
              chartPropsOverride: {
                title: { text: 'Rating' }
              }
            }
          }
        )
      };
      widgets.push(group);
      const subMenuItems = [];
      subMenuItems.push({
        icon: ExportIcon,
        text: 'Add to Download List',
        eventName: 'downloadOmniDataReviews'
      });
      widgets.push({
        CustomComponent: PageContextMenu,
        name: 'pageContextMenu',
        view: {
          name: 'pageContextMenu',
          buttons: subMenuItems
        },
        data: {}
      });

      break;
    }
    case 'highRiskReviews': {
      widgets.push({
        name: 'OmniReviewColumn',
        CustomComponent: OmniReviewHighRisk,
        view: {
          name: 'columnChart',
          chartPropsOverride: {
            chart: {
              height: 350,
              type: 'column'
            },

            disablePointSelect: false,
            ignoreZeroValues: true,
            xAxis: {
              rotation: -45
            },
            subtitle: {
              style: {
                borderBottom: 'none'
              }
            }
          }
        },
        data: {
          chartMainField: {
            name: 'stars',
            displayName: 'Avg. Star Rating',
            metricType: 'DECIMAL',
            aggregationFunction: 'stats',
            dataType: 'DECIMAL',
            overrides: {
              weekId_Override: {
                timePeriodAggregationFunctionType: 'trueAvg',
                timePeriodAggregationFunction: 'avg'
              }
            },
            timePeriodAggregationFunctionType: 'trueAvg',
            timePeriodAggregationFunction: 'avg',
            appName: 'omni',
            indexName: 'reviews'
          },
          aggregationFields: [
            {
              name: 'stars',
              displayName: 'Avg. Star Rating',
              metricType: 'DECIMAL',
              aggregationFunction: 'stats',
              dataType: 'DECIMAL',
              overrides: {
                weekId_Override: {
                  timePeriodAggregationFunctionType: 'trueAvg',
                  timePeriodAggregationFunction: 'avg'
                }
              },
              timePeriodAggregationFunctionType: 'trueAvg',
              timePeriodAggregationFunction: 'avg',
              appName: 'omni',
              indexName: 'reviews'
            }
          ],
          groupByField: {
            name: 'weekId',
            displayName: 'Week Ending',
            dataType: 'DATETIME',
            metricType: 'DATETIME',
            aggregationFunction: 'avg',
            appName: 'omni',
            indexName: 'reviews'
          }
        }
      });
      const subMenuItems = [];
      subMenuItems.push({
        icon: ExportIcon,
        text: 'Add to Download List',
        eventName: 'downloadOmniDataReviews'
      });
      widgets.push({
        CustomComponent: PageContextMenu,
        name: 'pageContextMenu',
        view: {
          name: 'pageContextMenu',
          buttons: subMenuItems
        },
        data: {}
      });
      break;
    }

    default:
      break;
  }

  return {
    widgets,
    CustomPageContainer: GenericPageContainer as any,
    enableComparison: false
  };
};
