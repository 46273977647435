/* eslint-disable react/prop-types */
import React from 'react';
// import { Link } from 'react-router-dom';
import invariant from 'invariant';
import numeral from 'numeral';
import { AdManagerAdCampaignEntity } from 'sl-api-connector/types';

import AutomatedBadge from './AutomatedBadge';
import automatedColors from './AutomatedBadge.scss';
import ReduxStore from 'src/types/store/reduxStore';
import { connect } from 'react-redux';

export const BidPricesOptimized: React.FC<{ isAutomated: boolean }> = ({ isAutomated }) => (
  <>
    <AutomatedBadge isAutomated={isAutomated} style={{ marginBottom: 24 }} />
    <p style={{ paddingTop: 26 }}>
      Automated bidding has been{' '}
      <b style={{ color: isAutomated ? automatedColors['automated-color'] : automatedColors['not-automated-color'] }}>
        turned {isAutomated ? 'on' : 'off'}
      </b>{' '}
      to optimize the campaign goal that you have set.
    </p>
    {/* <p>
      To change your automation settings, visit the <Link to="/TODO">Edit Campaign</Link> page.
    </p> */}
  </>
);

const mapBudgetOptimizedStateToProps = (state: ReduxStore) => ({
  mainEntity: state.entityService.mainEntity as AdManagerAdCampaignEntity | null | undefined
});

const BudgetOptimizedInner: React.FC<{ isAutomated?: boolean } & ReturnType<typeof mapBudgetOptimizedStateToProps>> = ({
  isAutomated = true,
  mainEntity
}) => {
  if (!mainEntity) {
    return null;
  }

  invariant(
    mainEntity.type === 'adCampaign',
    `Accessing recommendations page budget tab when main entity is of invalid type ${mainEntity.type}`
  );

  const formattedBudget = numeral(
    mainEntity.extendedAttributes.currentMonthBudgetSetting
      ? mainEntity.extendedAttributes.currentMonthBudgetSetting.amount
      : null
  ).format('$1,000');

  return (
    <>
      <span style={{ fontSize: 24 }}>0</span>% of days reached out of the{' '}
      <span style={{ fontSize: 24 }}>{formattedBudget}</span> daily budget
      <p style={{ paddingTop: 12 }}>
        Automated bidding has been{' '}
        <b style={{ color: isAutomated ? automatedColors['automated-color'] : automatedColors['not-automated-color'] }}>
          turned {isAutomated ? 'on' : 'off'}
        </b>{' '}
        to optimize the campaign goal that you have set.
      </p>
      {/* <p>
        To change your automation settings, visit the <Link to="/TODO">Edit Campaign</Link> page.
      </p> */}
    </>
  );
};

export const BudgetOptimized = connect(mapBudgetOptimizedStateToProps)(BudgetOptimizedInner);

export const OptimizationFailure: React.FC<{
  typeName: string;
}> = ({ typeName }) => (
  <div style={{ marginTop: 30 }}>
    <p style={{ paddingTop: 20 }}>{`No bid optimizations are available for this ${typeName}.`}</p>
  </div>
);

export const ManualBiddingNoOptimizations: React.FC<{
  typeName: string;
}> = ({ typeName }) => (
  <div style={{ marginTop: 30 }}>
    <AutomatedBadge isAutomated={false} style={{ marginBottom: 24 }} />
    <p style={{ paddingTop: 20 }}>{`Automated optimization have not been turned on for this ${typeName}.`}</p>
    {/* <p>
      To change your automation settings, visit the <Link to="/TODO">Campaign Details</Link> page.
    </p> */}
    <p style={{ paddingTop: 20 }}>{`No bid optimizations are available for this ${typeName}.`}</p>
  </div>
);

export const AdAuditNoOptimizations: React.FC<{ optimizationType: string; typeName: string }> = ({
  optimizationType,
  typeName
}) => {
  return (
    <div style={{ marginTop: 30 }}>
      <p style={{ paddingTop: 20 }}>{`No ${optimizationType} are available for this ${typeName}.`}</p>
    </div>
  );
};
