import React from 'react';
import PropTypes from 'prop-types';

class ProductItem extends React.Component {
  static propTypes = {
    product: PropTypes.object.isRequired
  };

  handleImageLoadingError = () => {
    // this.setState({
    //   imageUri: 'https://placehold.it/120x120'
    // });
  };

  render() {
    const { product } = this.props;
    return (
      <div className="product-item">
        <div className="product-item__img-container">
          <img
            className="product-item__img"
            alt="Product"
            src={product.imageUrl(product.stacklineSku)}
            onError={this.handleImageLoadingError}
          />
        </div>
        <div className="product-item__name-container">
          <div className="product-item__brand">{product.brand}</div>
          <div>{product.title}</div>
        </div>
      </div>
    );
  }
}

export default ProductItem;
