import React, { useEffect, useState } from 'react';
import EntityTableHeader, {
  IconsList
} from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTableHeader';
import ForecastProductGrid from './ForecastProductGrid';
import { useProductGridData } from './serverProxy';
import EntityTable, {
  ENTITY_TABLE_PAGE_SIZE
} from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import MetricCell from 'src/components/BeaconRedesignComponents/MetricCell/MetricCell';
import { ProductSalesDatum } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/types';
import ProductCell from 'src/components/BeaconRedesignComponents/ProductCell/ProductCell';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import Pagination from 'src/components/BeaconRedesignComponents/Pagination/Pagination';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import {
  useForecastPeriod,
  useForecastType,
  useExportStartAndEndWeekIds,
  useForecastComparisonStartAndEndWeekIds
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import { shouldHidePagination } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/utils';
import {
  useAdjustedForecastExportRequest,
  useUnadjustedForecastExportRequest
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/hooks';
import {
  ForecastPeriod,
  ForecastType
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import { useAppSelector } from 'src/utils/Hooks';
import { useRetailerDisplayName } from 'src/utils/Hooks/reduxSelectors';

const FETCH_CHUNK_SIZE = 100;
const ITEMS_PER_PAGE = 20;
const PAGES_PER_CHUNK = FETCH_CHUNK_SIZE / ITEMS_PER_PAGE;

/**
 * Handles displaying either the product grid or table and
 * handles switching between the two views.
 */
const ForecastProductListView = () => {
  const [page, setPage] = useState(1);
  const forecastPeriod = useForecastPeriod();
  const { historicStartWeekId, historicEndWeekId, forecastEndWeekId } = useExportStartAndEndWeekIds();
  const forecastType = useForecastType();
  const retailerDisplayName = useRetailerDisplayName();
  const [forecastGroupByFieldName, setForecastGroupByFieldName] = useState<string>('stacklineSku');
  const [includeCompTimePeriodForExport, setIncludeCompTimePeriodForExport] = useState(false);
  const { startWeekId: comparisonStartWeekId, endWeekId: comparisonEndWeekId } =
    useForecastComparisonStartAndEndWeekIds();
  const { type: mainEntityType, name: clientName } = useAppSelector((state) => state.entityService.mainEntity);
  const unadjustedExportRequest = useUnadjustedForecastExportRequest({
    groupByFieldName: forecastGroupByFieldName,
    includeCompTimePeriodForExport
  });
  const adjustedExportRequest = useAdjustedForecastExportRequest({
    groupByFieldName: forecastGroupByFieldName,
    includeCompTimePeriodForExport
  });

  useEffect(() => {
    setPage(1);
  }, [forecastPeriod]);

  const chunkIndex = ((page - 1) % PAGES_PER_CHUNK) + 1;
  const chunkStartIndex = (chunkIndex - 1) * ITEMS_PER_PAGE;

  const { data: productGridData, isLoading: productGridDataLoading } = useProductGridData({
    page: Math.ceil(page / PAGES_PER_CHUNK),
    pageSize: FETCH_CHUNK_SIZE
  });
  const [listView, setListView] = useState<'tile' | 'table'>('tile');

  const handleChangeLayout = () => {
    setListView((oldView) => (oldView === 'table' ? 'tile' : 'table'));
  };

  const onClickNext = () => {
    const adjustedPage = ((page - 1) % PAGES_PER_CHUNK) + 1;
    const dataStartIndex = (adjustedPage - 1) * ITEMS_PER_PAGE;
    // If the last fetched page of data is less than the length of data we
    // can show, than there are no more pages to fetch
    if (
      !productGridDataLoading &&
      productGridData.slice(dataStartIndex, dataStartIndex + ITEMS_PER_PAGE).length < ITEMS_PER_PAGE
    ) {
      return;
    }
    setPage(page + 1);
  };

  const onClickPrevious = () => {
    setPage(Math.max(1, page - 1));
  };

  return (
    <Flex flexDirection="column">
      <EntityTableHeader
        title="Retail Sales by Product"
        showDropdown={false}
        enableSwitchingLayouts
        defaultView={listView}
        handleChangeLayout={handleChangeLayout}
        iconsList={[IconsList.export, IconsList.tile, IconsList.table]}
        buildExportRequest={({ originalRequest, groupByFieldName, includeCompTimePeriod }) => {
          // get the groupByFieldName from the modal dropdown
          setForecastGroupByFieldName(groupByFieldName);
          setIncludeCompTimePeriodForExport(includeCompTimePeriod);
          const convertHistoricRequest = originalRequest.map((exportRequest) => {
            // this is a special case for the ratings and reviews forecasts export - won't happen in general export so added in here.
            if (exportRequest.searchType === 'beacon-ratings-reviews-metrics') {
              exportRequest.conditions.rangeFilters = [
                { fieldName: 'weekId', minValue: historicStartWeekId, maxValue: historicEndWeekId }
              ];
            }
            // override the filename to include the forecast week id
            exportRequest.fileName = `${mainEntityType}_${clientName}_forecasts_${forecastType}_${retailerDisplayName}_${
              includeCompTimePeriod ? comparisonStartWeekId : historicStartWeekId
            }_${forecastEndWeekId}`;
            return {
              ...exportRequest,
              exportResults: true,
              aggregations: [
                {
                  ...exportRequest.aggregations[0],
                  ...(includeCompTimePeriod && forecastPeriod === ForecastPeriod.FULL_YEAR
                    ? {
                        comparisonRangeFilters: [
                          { fieldName: 'weekId', maxValue: comparisonEndWeekId, minValue: comparisonStartWeekId }
                        ]
                      }
                    : {}),
                  conditions: {
                    ...exportRequest.aggregations[0].conditions,
                    rangeFilters: [{ fieldName: 'weekId', minValue: historicStartWeekId, maxValue: historicEndWeekId }]
                  }
                }
              ]
            };
          });

          return [
            ...(includeCompTimePeriod || forecastPeriod === ForecastPeriod.FULL_YEAR ? convertHistoricRequest : []),
            unadjustedExportRequest,
            ...(forecastType === ForecastType.ADJUSTED ? [adjustedExportRequest] : [])
          ];
        }}
      />
      <Flex flexDirection="column" gap="md">
        {listView === 'tile' ? (
          <ForecastProductGrid productSalesData={productGridData} loading={productGridDataLoading} page={chunkIndex} />
        ) : (
          <EntityTable
            showPagination={false}
            isLoading={productGridDataLoading}
            shouldModifyColumnDefs={false}
            columnDefs={[
              {
                headerName: 'Products',
                valueFormatter: (_, row: ProductSalesDatum) => (
                  <ProductCell key={row.stacklineSku} stacklineSku={row.stacklineSku} title={row.productTitle} />
                )
              },
              {
                headerName: 'Retail Sales',
                valueFormatter: (_, row: ProductSalesDatum) => (
                  <MetricCell
                    value={row.retailSales_sum_value}
                    metricType={METRICTYPE.MONEY}
                    percentChange={row.retailSalesPercentChange}
                  />
                )
              },
              {
                headerName: 'Units Sold',
                valueFormatter: (_, row: ProductSalesDatum) => (
                  <MetricCell
                    value={row.unitsSold_sum_value}
                    metricType={METRICTYPE.VOLUME}
                    percentChange={row.unitsSoldPercentChange}
                  />
                )
              },
              {
                headerName: 'Total Traffic',
                valueFormatter: (_, row: ProductSalesDatum) => (
                  <MetricCell value={row.totalClicks_sum_value} metricType={METRICTYPE.VOLUME} />
                )
              },
              {
                field: 'conversionRate',
                headerName: 'Conversion Rate',
                valueFormatter: (_, row: ProductSalesDatum) => (
                  <MetricCell
                    value={row.conversionRate}
                    metricType={METRICTYPE.PERCENT}
                    percentChange={row.conversionRatePercentChange}
                  />
                )
              }
            ]}
            rowData={productGridData.slice(chunkStartIndex, chunkStartIndex + ITEMS_PER_PAGE)}
            initialPageSize={ITEMS_PER_PAGE}
          />
        )}
        {/* There is a margin on the table in stack ui, add negative margin to make it look right */}
        {shouldHidePagination({
          page,
          pageSize: ENTITY_TABLE_PAGE_SIZE,
          rowCount: (productGridData || []).length
        }) ? null : (
          <Flex justifyContent="flex-end" marginTop={listView === 'table' ? '-20px' : undefined}>
            <Pagination
              currentPage={page}
              shouldUseInfinitePagination
              onClickNext={onClickNext}
              onClickPrevious={onClickPrevious}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default ForecastProductListView;
