import { PageLayout } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import { addLeftNavToLayout } from 'src/components/Layout/LayoutUtil';
import { getLayoutForEntity as getPricePageLayoutOmni } from './OmniPricePageLayout';
import { getLayoutForEntity as getInventoryPageLayoutOmni } from './OmniInventoryPageLayout';
import { getLayoutForEntity as getKeyMetricsPageLayout } from './OmniKeyMetricsPageLayout';
import { getLayoutForEntity as getEntitySummaryPageLayoutOmni } from './OmniEntitySummaryPageLayout';
import { getLayoutForEntity as getTimePageLayout } from './OmniTimePageLayout';
import { getLayoutForEntity as getShareOfShelfPageLayout } from './OmniShareOfShelfLayout';
import { getLayoutForEntity as getReviewPageLayout } from './OmniReviewPageLayout';
import { getLayoutForEntity as getContentPageLayout } from './OmniContentPageLayout';

const layoutGetterByTab: {
  [tab: string]: (app: ReduxStore['app'], subtab: string, entityType: string) => PageLayout;
} = {
  // populate all potential page layouts here
  scorecard: getKeyMetricsPageLayout,
  price: getPricePageLayoutOmni,
  inventory: getInventoryPageLayoutOmni,
  omni: getEntitySummaryPageLayoutOmni,
  time: getTimePageLayout,
  shareOfShelf: getShareOfShelfPageLayout,
  reviews: getReviewPageLayout,
  content: getContentPageLayout
};

/**
 * helper function which will return the page layout based off of the tab and subtab
 * ex: if on 'price' and 'retailPrice' return PriceLayout with retail price line chart, price comparison by retailer, and stores with price increases and decreases
 */

export const getLayoutForEntityOmni = (
  app: ReduxStore['app'],
  tab: string,
  subtab: string,
  entityType: string
): PageLayout => {
  const layoutGetter = layoutGetterByTab[tab] || layoutGetterByTab.scorecard;
  const layout =
    tab !== 'omni' ? addLeftNavToLayout(layoutGetter(app, subtab, entityType)) : layoutGetter(app, subtab, entityType);

  return layout as PageLayout;
};
