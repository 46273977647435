import { Divider } from '@mui/material';
import { useStacklineTheme } from '@stackline/ui';
import React, { Dispatch, SetStateAction } from 'react';
import { PlanInputContainer } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Components/PlanInputContainer';
import { RatingsReviewsPlanInputContainer } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Components/RatingsReviewsInputContainer';
import { inputStyles } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Views/ConfirmAdjustment';
import {
  ContentScoreFields,
  PaidTrafficFields,
  PlanTypeOption,
  RatingsReviewFields
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { PaidTrafficMetrics } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAdSpendProjection';
import {
  AdjustmentForm,
  ContentScoreInput,
  DefaultInput,
  PaidTrafficInput,
  ParsedOriginalProjectionResponse,
  RatingsReviewInput
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import {
  calculateAverage,
  calculateWeightedAverageRating
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/utils';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import StyledInput from 'src/components/BeaconRedesignComponents/common/StyledInput';
import { CurrencyInput } from 'src/components/BeaconRedesignComponents/common/StyledInput/CurrencyInput';
import MaskedInput from 'src/components/BeaconRedesignComponents/common/StyledInput/MaskedInput';
import { PercentageInput } from 'src/components/BeaconRedesignComponents/common/StyledInput/PercentageInput';
import { integerMask } from 'src/components/BeaconRedesignComponents/common/StyledInput/inputMasks';
import { useMetricFormatter } from 'src/utils/Hooks';
import { safeDivide } from 'src/utils/app';
import { calculateWeeksBetweenWeekIds } from 'src/utils/dateUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions';

interface InputRendererProps {
  setShouldOverrideCostPerClick: Dispatch<SetStateAction<boolean>>;
  paidTrafficMetrics: Omit<PaidTrafficMetrics, 'weekId'>;
  planType: PlanTypeOption;
  adjustmentForm: AdjustmentForm;
  parsedData: ParsedOriginalProjectionResponse;
  handleInputChange: (
    inputTarget: 'total' | ContentScoreFields | RatingsReviewFields | PaidTrafficFields,
    value: any
  ) => void;
}

/**
 * Renders a varying set of inputs depending on the plan type.
 * Each input has unique masking depending on the metric type that belongs to plan type input.
 * Each set of inputs contains a read-only input with a current projected value and an editable input to complete the adjustment form.
 * Special cases include: Content Score, Ratings & Reviews, and Paid Traffic/Ad Spend adjustments
 * where we render multiple inputs, each with unique logic specific to that plan type.
 * @returns Components used for inputting adjustment amounts
 */
export const InputRenderer = ({
  setShouldOverrideCostPerClick,
  paidTrafficMetrics,
  planType,
  adjustmentForm,
  parsedData,
  handleInputChange
}: InputRendererProps) => {
  const formatMetric = useMetricFormatter();
  const { startDate, endDate } = adjustmentForm;
  const numberOfWeeks = calculateWeeksBetweenWeekIds(startDate, endDate);
  const theme = useStacklineTheme();

  switch (planType) {
    case PlanTypeOption.OrganicTraffic: {
      const planInputAmount = adjustmentForm.planInputAmounts as DefaultInput;
      return (
        <PlanInputContainer
          label="Organic Traffic"
          firstInput={
            <StyledInput
              sx={inputStyles}
              readOnly
              value={formatMetric(parsedData.organicTraffic_sum_value / numberOfWeeks, METRICTYPE.VOLUME)}
            />
          }
          secondInput={
            <MaskedInput
              onChange={(e) => handleInputChange('total', e.target.value)}
              sx={inputStyles}
              value={planInputAmount.total}
              masker={integerMask}
              placeholder={String(formatMetric(parsedData.organicTraffic_sum_value / numberOfWeeks, METRICTYPE.VOLUME))}
            />
          }
        />
      );
    }
    case PlanTypeOption.OtherTraffic: {
      const planInputAmount = adjustmentForm.planInputAmounts as DefaultInput;
      return (
        <PlanInputContainer
          label="Other Traffic"
          firstInput={
            <StyledInput
              sx={inputStyles}
              readOnly
              value={formatMetric(parsedData.otherTraffic_sum_value / numberOfWeeks, METRICTYPE.VOLUME)}
            />
          }
          secondInput={
            <MaskedInput
              onChange={(e) => handleInputChange('total', e.target.value)}
              sx={inputStyles}
              value={planInputAmount.total}
              masker={integerMask}
              placeholder={String(formatMetric(parsedData.otherTraffic_sum_value / numberOfWeeks, METRICTYPE.VOLUME))}
            />
          }
        />
      );
    }
    case PlanTypeOption.BuyBox: {
      const planInputAmount = adjustmentForm.planInputAmounts as DefaultInput;
      return (
        <PlanInputContainer
          label="Buy Box"
          firstInput={
            <StyledInput
              sx={inputStyles}
              readOnly
              value={formatMetric(parsedData.winPercentage_computed_value, METRICTYPE.PERCENT)}
            />
          }
          secondInput={
            <PercentageInput
              onBlur={(e) => {
                const { value } = e.target;
                if (value !== '') {
                  const strippedValue = value.replaceAll('%', '');
                  const fixedValue = Number(strippedValue).toFixed(1);
                  handleInputChange('total', fixedValue);
                }
              }}
              onChange={(e) => handleInputChange('total', e.target.value)}
              sx={inputStyles}
              value={planInputAmount.total}
              placeholder={String(formatMetric(parsedData.winPercentage_computed_value, METRICTYPE.PERCENT))}
            />
          }
        />
      );
    }
    case PlanTypeOption.InStockRate: {
      const planInputAmount = adjustmentForm.planInputAmounts as DefaultInput;
      return (
        <PlanInputContainer
          label="In Stock Rate"
          firstInput={
            <StyledInput
              sx={inputStyles}
              readOnly
              value={formatMetric(parsedData.inStockRate_avg_value, METRICTYPE.PERCENT)}
            />
          }
          secondInput={
            <PercentageInput
              onBlur={(e) => {
                const { value } = e.target;
                if (value !== '') {
                  const strippedValue = value.replaceAll('%', '');
                  const fixedValue = Number(strippedValue).toFixed(1);
                  handleInputChange('total', fixedValue);
                }
              }}
              onChange={(e) => handleInputChange('total', e.target.value)}
              sx={inputStyles}
              value={planInputAmount.total}
              placeholder={String(formatMetric(parsedData.inStockRate_avg_value, METRICTYPE.PERCENT))}
            />
          }
        />
      );
    }
    case PlanTypeOption.RetailPrice: {
      const planInputAmount = adjustmentForm.planInputAmounts as DefaultInput;
      return (
        <PlanInputContainer
          label="Retail Price"
          firstInput={
            <StyledInput
              sx={inputStyles}
              readOnly
              value={formatMetric(parsedData.retailPrice_avg_value, METRICTYPE.MONEY)}
            />
          }
          secondInput={
            <CurrencyInput
              onChange={(e) =>
                handleInputChange('total', e.target.value, {
                  currentRetailPrice: parsedData.retailPrice_avg_value.toFixed(2)
                })
              }
              sx={inputStyles}
              value={planInputAmount.total}
              placeholder={String(formatMetric(parsedData.retailPrice_avg_value, METRICTYPE.MONEY))}
            />
          }
        />
      );
    }
    case PlanTypeOption.RatingsReviews: {
      const planInputAmount = adjustmentForm.planInputAmounts as RatingsReviewInput;
      return (
        <>
          <RatingsReviewsPlanInputContainer
            label="Review Quantity"
            secondInputLabel="ADDITIONAL"
            firstInput={
              <StyledInput
                sx={inputStyles}
                readOnly
                value={formatMetric(safeDivide(parsedData.reviewCount, numberOfWeeks), METRICTYPE.VOLUME)}
              />
            }
            secondInput={
              <MaskedInput
                onChange={(e) => handleInputChange(RatingsReviewFields.ReviewQuantity, e.target.value)}
                sx={inputStyles}
                value={planInputAmount.reviewQuantityAmount}
                masker={integerMask}
                placeholder="0"
              />
            }
            thirdInput={
              <MaskedInput
                readOnly
                sx={inputStyles}
                value={formatMetric(
                  safeDivide(parsedData.reviewCount, numberOfWeeks) + Number(planInputAmount.reviewQuantityAmount),
                  METRICTYPE.VOLUME
                )}
                masker={integerMask}
                placeholder={String(formatMetric(parsedData.reviewCount, METRICTYPE.VOLUME))}
              />
            }
          />
          <RatingsReviewsPlanInputContainer
            label="AVG Star Rating"
            secondInputLabel="ADDITIONAL"
            firstInput={
              <StyledInput
                sx={inputStyles}
                readOnly
                value={formatMetric(parsedData.weightedRating, METRICTYPE.DECIMAL)}
              />
            }
            secondInput={
              <StyledInput
                onBlur={(e) => {
                  const { value } = e.target;
                  if (value !== '') {
                    let fixedValue = Number(value).toFixed(1);

                    if (Number(fixedValue) > 5) {
                      fixedValue = '5.0';
                    }

                    handleInputChange(RatingsReviewFields.AverageRating, fixedValue);
                  }
                }}
                onChange={(e) => handleInputChange(RatingsReviewFields.AverageRating, e.target.value)}
                sx={inputStyles}
                value={planInputAmount.averageStarRatingAmount}
                placeholder={String(formatMetric(parsedData.weightedRating, METRICTYPE.DECIMAL))}
              />
            }
            thirdInput={
              <StyledInput
                readOnly
                sx={inputStyles}
                value={String(
                  calculateWeightedAverageRating({
                    currentReviewCount: parsedData.reviewCount,
                    currentAverageStarRating: parsedData.weightedRating,
                    additionalAverageStarRating: Number(planInputAmount.averageStarRatingAmount),
                    additionalReviewCount: Number(planInputAmount.reviewQuantityAmount)
                  }).toFixed(1)
                )}
                placeholder={String(formatMetric(parsedData.reviewCount, METRICTYPE.VOLUME))}
              />
            }
          />
        </>
      );
    }
    case PlanTypeOption.ContentScore: {
      const planInputAmount = adjustmentForm.planInputAmounts as ContentScoreInput;

      const {
        imageScore_avg_value,
        titleScore_avg_value,
        videoScore_avg_value,
        aplusScore_avg_value,
        bulletScore_avg_value
      } = parsedData;

      return (
        <>
          <PlanInputContainer
            label="Title Score"
            firstInput={
              <StyledInput
                sx={inputStyles}
                readOnly
                value={formatMetric(parsedData.titleScore_avg_value, METRICTYPE.PERCENT)}
              />
            }
            secondInput={
              <PercentageInput
                onBlur={(e) => {
                  const { value } = e.target;
                  if (value !== '') {
                    const strippedValue = value.replaceAll('%', '');
                    const fixedValue = Number(strippedValue).toFixed(1);
                    handleInputChange(ContentScoreFields.TitleScore, fixedValue);
                  }
                }}
                onChange={(e) => handleInputChange(ContentScoreFields.TitleScore, e.target.value)}
                sx={inputStyles}
                value={planInputAmount.titleScore}
                placeholder={String(formatMetric(parsedData.titleScore_avg_value, METRICTYPE.PERCENT))}
              />
            }
          />
          <PlanInputContainer
            label="Bullet Score"
            firstInput={
              <StyledInput
                sx={inputStyles}
                readOnly
                value={formatMetric(parsedData.bulletScore_avg_value, METRICTYPE.PERCENT)}
              />
            }
            secondInput={
              <PercentageInput
                onBlur={(e) => {
                  const { value } = e.target;
                  if (value !== '') {
                    const strippedValue = value.replaceAll('%', '');
                    const fixedValue = Number(strippedValue).toFixed(1);
                    handleInputChange(ContentScoreFields.BulletScore, fixedValue);
                  }
                }}
                onChange={(e) => handleInputChange(ContentScoreFields.BulletScore, e.target.value)}
                sx={inputStyles}
                value={planInputAmount.bulletScore}
                placeholder={String(formatMetric(parsedData.bulletScore_avg_value, METRICTYPE.PERCENT))}
              />
            }
          />
          <PlanInputContainer
            label="Image Score"
            firstInput={
              <StyledInput
                sx={inputStyles}
                readOnly
                value={formatMetric(parsedData.imageScore_avg_value, METRICTYPE.PERCENT)}
              />
            }
            secondInput={
              <PercentageInput
                onBlur={(e) => {
                  const { value } = e.target;
                  if (value !== '') {
                    const strippedValue = value.replaceAll('%', '');
                    const fixedValue = Number(strippedValue).toFixed(1);
                    handleInputChange(ContentScoreFields.ImageScore, fixedValue);
                  }
                }}
                onChange={(e) => handleInputChange(ContentScoreFields.ImageScore, e.target.value)}
                sx={inputStyles}
                value={planInputAmount.imageScore}
                placeholder={String(formatMetric(parsedData.imageScore_avg_value, METRICTYPE.PERCENT))}
              />
            }
          />
          <PlanInputContainer
            label="Video Score"
            firstInput={
              <StyledInput
                sx={inputStyles}
                readOnly
                value={formatMetric(parsedData.videoScore_avg_value, METRICTYPE.PERCENT)}
              />
            }
            secondInput={
              <PercentageInput
                onBlur={(e) => {
                  const { value } = e.target;
                  if (value !== '') {
                    const strippedValue = value.replaceAll('%', '');
                    const fixedValue = Number(strippedValue).toFixed(1);
                    handleInputChange(ContentScoreFields.VideoScore, fixedValue);
                  }
                }}
                onChange={(e) => handleInputChange(ContentScoreFields.VideoScore, e.target.value)}
                sx={inputStyles}
                value={planInputAmount.videoScore}
                placeholder={String(formatMetric(parsedData.videoScore_avg_value, METRICTYPE.PERCENT))}
              />
            }
          />
          <PlanInputContainer
            label="A+ Score"
            firstInput={
              <StyledInput
                sx={inputStyles}
                readOnly
                value={formatMetric(parsedData.aplusScore_avg_value, METRICTYPE.PERCENT)}
              />
            }
            secondInput={
              <PercentageInput
                onBlur={(e) => {
                  const { value } = e.target;
                  if (value !== '') {
                    const strippedValue = value.replaceAll('%', '');
                    const fixedValue = Number(strippedValue).toFixed(1);
                    handleInputChange(ContentScoreFields.A_Score, fixedValue);
                  }
                }}
                onChange={(e) => handleInputChange(ContentScoreFields.A_Score, e.target.value)}
                sx={inputStyles}
                value={planInputAmount.aplusScore}
                placeholder={String(formatMetric(parsedData.aplusScore_avg_value, METRICTYPE.PERCENT))}
              />
            }
          />
          <Flex flexDirection="column" marginTop="50px" marginBottom="50px">
            <Divider sx={{ backgroundColor: theme.colors.primaryGray }} />
            <PlanInputContainer
              label="Total Content Score"
              firstInput={
                <StyledInput
                  sx={inputStyles}
                  readOnly
                  value={formatMetric(
                    calculateAverage([
                      imageScore_avg_value,
                      titleScore_avg_value,
                      videoScore_avg_value,
                      aplusScore_avg_value,
                      bulletScore_avg_value
                    ]),
                    METRICTYPE.PERCENT
                  )}
                />
              }
              secondInput={
                <StyledInput
                  readOnly
                  sx={inputStyles}
                  value={formatMetric(calculateAverage(Object.values(planInputAmount)) / 100, METRICTYPE.PERCENT)}
                />
              }
            />
          </Flex>
        </>
      );
    }
    case PlanTypeOption.PaidTraffic: {
      const planInputAmount = adjustmentForm.planInputAmounts as PaidTrafficInput;
      return (
        <>
          <PlanInputContainer
            label="Ad Spend"
            firstInput={
              <StyledInput
                sx={inputStyles}
                readOnly
                value={formatMetric(parsedData.adSpend / numberOfWeeks, METRICTYPE.MONEY)}
              />
            }
            secondInput={
              <CurrencyInput
                onChange={(e) => {
                  setShouldOverrideCostPerClick(true);
                  handleInputChange(PaidTrafficFields.AdSpend, e.target.value);
                }}
                sx={inputStyles}
                value={planInputAmount.adSpend}
                placeholder={String(formatMetric(parsedData.adSpend / numberOfWeeks, METRICTYPE.MONEY))}
              />
            }
            width="90px"
          />
          <PlanInputContainer
            label="Cost per Click"
            firstInput={
              <StyledInput sx={inputStyles} readOnly value={formatMetric(parsedData.costPerClick, METRICTYPE.MONEY)} />
            }
            secondInput={
              <CurrencyInput
                onChange={(e) => {
                  setShouldOverrideCostPerClick(false);
                  return handleInputChange(PaidTrafficFields.CostPerClick, e.target.value);
                }}
                sx={inputStyles}
                value={planInputAmount.costPerClick}
                placeholder={String(formatMetric(parsedData.costPerClick, METRICTYPE.MONEY))}
              />
            }
            width="90px"
          />
          <PlanInputContainer
            label="Paid Traffic"
            firstInput={
              <StyledInput
                sx={inputStyles}
                readOnly
                value={formatMetric(parsedData.paidTrafficValue_sum_value / numberOfWeeks, METRICTYPE.VOLUME)}
              />
            }
            secondInput={
              <StyledInput
                readOnly
                sx={inputStyles}
                value={paidTrafficMetrics ? formatMetric(paidTrafficMetrics.paid_traffic, METRICTYPE.VOLUME) : ''}
                placeholder={String(
                  formatMetric(parsedData.paidTrafficValue_sum_value / numberOfWeeks, METRICTYPE.VOLUME)
                )}
              />
            }
            width="90px"
          />
        </>
      );
    }

    default:
      return null;
  }
};
