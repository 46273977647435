import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { EllipsesIcon } from '../../SvgIcons';

const propTypes = {
  onClick: PropTypes.func.isRequired
};

const CardEllipsesButton = ({ onClick }) => (
  <Fragment>
    <button className="card__flip-btn" onClick={onClick}>
      <EllipsesIcon className="card__flip-icon" />
    </button>
  </Fragment>
);

CardEllipsesButton.propTypes = propTypes;

export default CardEllipsesButton;
