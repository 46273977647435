import { useStacklineTheme } from '@stackline/ui';
import React, { useState } from 'react';
import {
  StyledGridDiv,
  StyledSmallCancelCircleFilledWhite,
  StyledSmallCheckCircle
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Content/Accuracy/ContentAccuracyImagesViewer';
import ContentAccuracyNodataBox from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Content/Accuracy/ContentAccuracyNodataBox';

import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

interface ContentAccuracyVideosViewerProps {
  videos: string[];
  changedVideos?: string[];
}

/**
 * Content Accuracy's Product Page video viewer
 *
 */
const ContentAccuracyVideoViewer = ({ videos, changedVideos }: ContentAccuracyVideosViewerProps) => {
  const [selectedVideo, setSelectedVideo] = useState(videos[0] || '');
  const theme = useStacklineTheme();
  const isCurrentContent = !!changedVideos;

  if (videos.length === 0) {
    return <ContentAccuracyNodataBox isCurrentContent={isCurrentContent} contentField="videos" />;
  }

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="center" marginBottom={theme.spacing.md}>
        <div style={{ minHeight: '200px' }}>
          <video controls style={{ width: '286px', height: '160px', objectFit: 'contain' }} src={selectedVideo}>
            <source src={`${selectedVideo}#t=1`} />
          </video>
        </div>
      </Flex>
      <Flex justifyContent="center">
        <StyledGridDiv imagesLength={videos.length}>
          {videos.map((video, index) => (
            <div key={video} style={{ position: 'relative' }}>
              {isCurrentContent ? (
                changedVideos && !changedVideos[index] ? (
                  <StyledSmallCheckCircle stacklineTheme={theme} />
                ) : (
                  <StyledSmallCancelCircleFilledWhite stacklineTheme={theme} />
                )
              ) : null}

              <video
                style={{
                  width: '40px',
                  height: '40px',
                  objectFit: 'contain',
                  cursor: 'pointer',
                  border: `1px solid ${video === selectedVideo ? theme.colors.info : 'transparent'}`
                }}
                onClick={() => setSelectedVideo(video)}
                src={video}
                role="button"
              >
                <source src={`${selectedVideo}#t=1`} />
              </video>
            </div>
          ))}
        </StyledGridDiv>
      </Flex>
    </Flex>
  );
};

export default ContentAccuracyVideoViewer;
