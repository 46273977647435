import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'qs';
import axios from 'axios';
import _pick from 'lodash/pick';

// data store
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { entityOperations } from 'src/store/modules/entityService';

// rendering
import {
  buildDefaultConditions,
  buildEntityConditions,
  buildMainEntityConditions
} from '../Renderer/EntityPageRenderer';

// layout
import { getLayoutForEntity } from 'src/components/Layout/EntityPageLayout';
import { flattenWidgets, defaultWidgetWrapperStyle } from 'src/components/Layout/LayoutUtil';

import Loading from '../../common/Loading';
import EntityGrid from '../../EntityGrid';
import { anyNotEq } from '../../../utils/equality';

const { CancelToken } = axios;

class InactiveProducts extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    aggregationConditions: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired
  };

  state = {
    isLoading: true
  };

  componentDidMount() {
    this.source = CancelToken.source();
    this.fetchMetrics(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const propKeysToCompare = ['location.pathname', 'location.search', 'entityService.mainEntity', 'filters'];
    if (anyNotEq(propKeysToCompare, this.props, nextProps)) {
      this.fetchMetrics(nextProps);
    }
  }

  componentWillUnmount() {
    this.source.cancel('Cancel network request');
  }

  fetchMetrics(props) {
    const { app, conditions, entityService, retailer, user, categories, location, match } = props;
    const { mainEntity } = entityService;
    // the main entity has to match with what is in the current url
    const { id } = match.params;
    const mainEntityId = mainEntity && `${mainEntity.id}`;
    const comparisonEntityId = id === mainEntityId ? mainEntityId : mainEntity.hashId;
    // the main entity has to match with what is in the current url
    if (!app || !mainEntity || id !== comparisonEntityId) {
      return;
    }
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const { tab, subtab } = queryParams;
    const pageLayout = getLayoutForEntity({
      app,
      retailer,
      user,
      tab,
      metricType: subtab,
      entity: mainEntity,
      pageType: 'entityPage'
    });

    const defaultConditions = buildDefaultConditions(conditions, queryParams);
    const entityConditions = buildEntityConditions(app, categories, defaultConditions, mainEntity);

    const mainEntityConditions = buildMainEntityConditions(entityConditions, mainEntity, app, retailer, queryParams);

    this.setState({
      pageLayout,
      entityConditions,
      mainEntityConditions,
      isLoading: false
    });
  }

  render() {
    const { entityService, aggregationConditions } = this.props;
    const { isLoading, entityConditions, mainEntityConditions, pageLayout } = this.state;
    const { mainEntity } = entityService;
    if (
      isLoading ||
      !pageLayout ||
      !mainEntity ||
      !entityConditions ||
      !mainEntityConditions ||
      !aggregationConditions
    ) {
      return (
        <div className="spinner-wrapper">
          <Loading className="spinner__table--entity" />
        </div>
      );
    }

    const flattenedWidgets = flattenWidgets(pageLayout.widgets);
    const leftNavWidget = flattenedWidgets.find((widget) => widget.name === 'leftNav');

    return (
      <div style={{ ...(pageLayout.containerStyle || {}), flex: 1 }}>
        <leftNavWidget.CustomComponent widget={leftNavWidget} pageLayout={pageLayout} />
        <div className="entity-page-container">
          <div style={{ ...defaultWidgetWrapperStyle, marginTop: 40, paddingTop: 10 }}>
            {flattenedWidgets.map((widget, index) => {
              const { view } = widget;
              if (view.name.indexOf('entityGrid') === 0) {
                // Necessary aggregationConditions for the productContainer
                return (
                  <div
                    key={`entitypagecontainer_${view.name}_${index}`}
                    style={widget.view.container && widget.view.container.style ? widget.view.container.style : {}}
                    className={
                      widget.view.container && widget.view.container.className ? widget.view.container.className : ''
                    }
                  >
                    <EntityGrid
                      key={`${widget.view.name}`}
                      uniqueName={widget.view.name}
                      queryConditions={mainEntityConditions}
                      aggregationConditions={aggregationConditions}
                      widget={widget}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => _pick(state, ['app', 'retailer', 'user', 'categories', 'filters', 'entityService']);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics,
  fetchEntity: entityOperations.fetchEntity,
  setEntity: entityOperations.setEntity
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InactiveProducts));
