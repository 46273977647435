import _orderBy from 'lodash/orderBy';

import actions from './actions';
import types from './types';
import { ValueOf } from 'sl-api-connector';
import ReduxStore from 'src/types/store/reduxStore';

/**
 * This reducer is mounted into the `categories` key of the global store
 */
export const filteredCategories = (
  state = [],
  action: Extract<
    ReturnType<ValueOf<typeof actions>>,
    { type: (typeof types)['RECEIVE_FILTERED_CATEGORIES'] | (typeof types)['SORT_CATEGORIES'] }
  >
) => {
  switch (action.type) {
    case types.RECEIVE_FILTERED_CATEGORIES:
      return action.filteredCategories;
    case types.SORT_CATEGORIES: {
      return [..._orderBy(state, [action.sorter], [action.direction || 'desc'])];
    }
    default:
      return state;
  }
};

/**
 * This reducer is mounted into the `categoriesByRetailerId` key of the global store
 */
export const categoriesByRetailerId = (
  state = [],
  action: Exclude<
    ReturnType<ValueOf<typeof actions>>,
    { type: (typeof types)['RECEIVE_FILTERED_CATEGORIES'] | (typeof types)['SORT_CATEGORIES'] }
  >
) => {
  switch (action.type) {
    case types.RECEIVE_UNFILTERED_CATEGORIES:
      return action.categoriesByRetailerId;
    case types.CLEAR_CATEGORIES:
      return [];
    default:
      return state;
  }
};

/**
 * This reducer is mounted into the `allSuperUserCategories` key of the global store
 */
export const allSuperUserCategories = (
  state = [],
  action: {
    type: (typeof types)['RECEIVE_UNFILTERED_SUPERUSER_CATEGORIES'] | (typeof types)['CLEAR_CATEGORIES'];
    allSuperUserCategories?: ReduxStore['allSuperUserCategories'];
  }
) => {
  switch (action.type) {
    case types.RECEIVE_UNFILTERED_SUPERUSER_CATEGORIES:
      return action.allSuperUserCategories;
    case types.CLEAR_CATEGORIES:
      return [];
    default:
      return state;
  }
};
