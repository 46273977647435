/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { withBus } from 'react-bus';
import { HeaderSearchIcon, ChevronIcon } from 'src/components/SvgIcons';
import { EventBus } from 'src/types/utils';
import colors from 'src/utils/colors';
import _orderBy from 'lodash/orderBy';
import _toLower from 'lodash/toLower';

import OmniListFilterRow from 'src/components/Omni/OmniFilter/OmniListRows';

interface OmniFilterSectionPropsOptions {
  isChecked: boolean;
  id: string;
  name: string;
}
interface OmniFilterSectionProps {
  filterConfig: {
    name: string;
    pluralName: string;
    mappingName: string;
    originalOptions: OmniFilterSectionPropsOptions[];
    filterOptions: OmniFilterSectionPropsOptions[];
  };
  order: number;
  onFilterChange: (filterValue: any, filterType: string) => void;
  isFetching: boolean;
  eventBus: EventBus;
}
const getStyles = (filterOptionsToRenderLength: number) => {
  return {
    root: {
      padding: '0 30px',
      // If the original filter options are more than 6, we need to take care of show all 'optionName' div
      // so, it the line is not moving when dynamically change the value -- suggested by Design team
      ...(filterOptionsToRenderLength > 6 ? { minHeight: '178px' } : {})
    },
    container: {
      padding: '0 28px'
    },
    headerText: {
      fontSize: '16px',
      fontWeight: 700
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '219px',
      borderBottom: '1px solid #dedede'
    },
    searchInput: {
      border: 'none',
      outline: 'none',
      fontWeight: 500
    },
    filterOption: {
      display: 'grid',
      marginTop: '10px',
      gridTemplateColumns: 'repeat(3, 1fr)',
      minHeight: filterOptionsToRenderLength > 3 ? '68px' : '45px'
    },
    filterDropDown: {
      marginTop: '10px',
      padding: 0,
      border: 'none',
      outline: 'none',
      background: 'none',
      color: colors.darkBlue,
      fontWeight: 550,
      display: 'flex',
      alignItems: 'center'
    },
    mainBorder: {
      width: '100%',
      height: '1px',
      backgroundColor: '#dedede',
      margin: '25px 0'
    }
  };
};

const OmniFilterSection: React.FC<OmniFilterSectionProps> = ({ filterConfig, onFilterChange, eventBus, order }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { name, mappingName, filterOptions, originalOptions } = filterConfig;
  const [staticFilterOption, setStaticFilterOption] = useState(filterOptions);
  const [searchVal, setSearchVal] = useState('');

  let filterOptionsToRender = useMemo(
    () => staticFilterOption && staticFilterOption.filter((obj) => _toLower(obj.name).includes(_toLower(searchVal))),
    [searchVal, staticFilterOption]
  );

  const sortedFilterOptions = _orderBy(filterOptionsToRender, ['name'], 'asc');

  filterOptionsToRender = useMemo(() => {
    return isExpanded ? sortedFilterOptions : sortedFilterOptions.filter((_e, index) => index < 6);
  }, [isExpanded, sortedFilterOptions]);

  const shouldShowExpanded = staticFilterOption.length > 6;

  useEffect(() => {
    setStaticFilterOption(filterConfig.filterOptions);
  }, [filterConfig.filterOptions]);

  const handleCheck = (event: any, isInputChecked: boolean, index: number) => {
    const checkedVal = filterOptionsToRender[index];

    staticFilterOption.forEach((obj) => {
      if (obj.id === checkedVal.id) {
        obj.isChecked = isInputChecked;
      }
    });
    setStaticFilterOption([...staticFilterOption]);
    const values = staticFilterOption.filter((val) => val.isChecked);

    onFilterChange(values, mappingName);
  };

  const handleSearchVal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(event.target.value);
  };

  const handleUncheckAll = useCallback(() => {
    const newStaticFilterOption = staticFilterOption.map((option) => {
      option.isChecked = false;
      return { ...option };
    });
    setStaticFilterOption(newStaticFilterOption);
  }, [filterConfig, staticFilterOption]);

  useEffect(() => {
    eventBus.on('clearFilter', handleUncheckAll);
    return () => {
      eventBus.off('clearFilter', handleUncheckAll);
    };
  }, [eventBus, handleUncheckAll]);

  const styles = getStyles(originalOptions.length);

  return (
    <div style={styles.root}>
      {order !== 0 ? <hr style={styles.mainBorder}></hr> : <div style={{ marginTop: '30px' }}></div>}
      <div style={styles.container}>
        <div>
          <p style={styles.headerText}>{name}</p>
        </div>
        <div style={styles.searchContainer}>
          <HeaderSearchIcon
            style={{
              height: '23px',
              width: '23px'
            }}
          />
          <input style={styles.searchInput} placeholder="Search" value={searchVal} onChange={handleSearchVal} />
        </div>
        <div style={{ ...styles.filterOption }}>
          {filterOptionsToRender.map((filterOption, index) => {
            return (
              <OmniListFilterRow
                index={index}
                key={`${filterOption.id}${index}`}
                value={filterOption.name}
                isChecked={filterOption.isChecked}
                onCheck={handleCheck}
              />
            );
          })}
        </div>
        <div
          style={{
            display: 'flex'
          }}
        >
          {shouldShowExpanded && (
            <button style={styles.filterDropDown} onClick={() => setIsExpanded(!isExpanded)}>
              {!isExpanded ? `Show more ` : `Show less `}
              <ChevronIcon
                style={{
                  stroke: '#052849',
                  height: '28px',
                  width: '28px',
                  color: colors.darkBlue,
                  fontWeight: 550,
                  transform: isExpanded ? 'translateY(0px) rotate(180deg)' : 'translateY(2px)'
                }}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withBus('eventBus')(OmniFilterSection);
