/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { useAppSelector } from 'src/utils/Hooks';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import colors from 'src/utils/colors';
import Divider from '@mui/material/Divider';
import { CALENDAR_PADDING } from './constants';

const dateInputStyle = {
  width: '100px',
  padding: '6px 8px',
  border: 'solid 1px #dedede',
  fontWeight: 500,
  borderRadius: '4px',
  fontSize: '14px',
  textAlign: 'center'
};

const ControlButtons = ({ startDate, endDate, save, clear, width }) => {
  const locale = useAppSelector((state) => state.retailer.locale);

  return (
    <div
      style={{
        width: width ? width - CALENDAR_PADDING * 2 : '100%', // multiply by 2 for padding on each side
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Divider
        sx={{
          marginY: '32px',
          width: `calc(100% + ${CALENDAR_PADDING * 2}px)`, // multiply by 2 for padding on each side
          marginLeft: '-32px'
        }}
      />
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center'
        }}
      >
        {startDate && endDate && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <TextField
              value={moment(startDate).locale(locale).format('L').split('/').join(' / ')}
              InputProps={{
                readOnly: true
              }}
              inputProps={{
                style: dateInputStyle
              }}
              variant="outlined"
            />
            <ArrowForwardIcon sx={{ marginX: '8px' }} />
            <TextField
              value={moment(endDate).locale(locale).format('L').split('/').join(' / ')}
              InputProps={{
                readOnly: true
              }}
              inputProps={{
                style: dateInputStyle
              }}
              variant="outlined"
            />
          </div>
        )}
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ display: 'flex' }}>
          <Button
            variant="outlined"
            sx={{
              color: 'black',
              borderColor: colors.lightGrey
            }}
            onClick={() => clear()}
            disabled={!endDate}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            onClick={() => save()}
            color="primary"
            sx={{
              color: 'white',
              marginLeft: '8px'
            }}
            disabled={!endDate}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

ControlButtons.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  save: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  width: PropTypes.number
};

export default ControlButtons;
