// ------------------------------------
// Constants/Types
// ------------------------------------

const IS_UPDATING_REDUX = 'src/app/IS_UPDATING_REDUX';
const SET_APP_NAME = 'src/app/SET_APP_NAME';
const SET_APP_TARGET_URL = 'src/app/GET_APP_TARGET_URL';
const REQUEST_CONFIG = 'src/app/REQUEST_CONFIG';
const RECEIVE_APP_CONFIG = 'src/app/RECEIVE_APP_CONFIG';
const SET_QUERY_PARAMS = 'src/app/SET_QUERY_PARAMS';

export default {
  IS_UPDATING_REDUX,
  SET_APP_NAME,
  SET_APP_TARGET_URL,
  REQUEST_CONFIG,
  RECEIVE_APP_CONFIG,
  SET_QUERY_PARAMS
};
