/* eslint-disable react/prop-types */
import React from 'react';
import _isNil from 'lodash/isNil';
import numeral from 'numeral';

import './Badge.scss';

interface BadgeProps {
  text: string;
  color: string;
  backgroundColor?: string;
  amount?: number | null;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const Badge: React.FC<BadgeProps> = ({ text, color, amount, onClick, style, backgroundColor = '#fff' }) => (
  <span className="badge" style={{ ...style }}>
    <span className="text">{text}</span>

    <span onClick={onClick} className="badge-amount-wrapper" role="button" style={{ backgroundColor }}>
      <span className="badge-amount" style={{ color }} title={_isNil(amount) ? 'Loading...' : amount.toString()}>
        {_isNil(amount) ? '...' : numeral(amount).format('1,000a')}
      </span>
    </span>
  </span>
);

export default Badge;
