import React, { useState, useEffect } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { HeaderSearchIcon, ChevronIcon } from 'src/components/SvgIcons';
import { EventBus } from 'src/types/utils';

import colors from 'src/utils/colors';
import { withBus } from 'react-bus';

import _toLower from 'lodash/toLower';
import './omniFilterRadioButton.scss';

interface OmniFilterRadioSelectionProps {
  filterConfig: {
    name: string;
    pluralName: string;
    mappingName: string;
    filterOptions: { isChecked: boolean; id: string; name: string }[];
  };
  onFilterChange: (filterValue: any, filterType: string) => void;
  eventBus: EventBus;
}

const styles = {
  root: {
    padding: '0 30px'
  },
  container: {
    padding: '0 28px'
  },
  headerText: {
    fontSize: '16px',
    fontWeight: 700
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '219px',
    borderBottom: '1px solid #dedede'
  },
  searchInput: {
    border: 'none',
    outline: 'none',
    fontWeight: 500
  },
  filterDropDown: {
    marginTop: '10px',
    padding: 0,
    border: 'none',
    outline: 'none',
    background: 'none',
    color: colors.darkBlue,
    fontWeight: 550,
    display: 'flex',
    alignItems: 'center'
  },
  mainBorder: {
    width: '100%',
    height: '1px',
    backgroundColor: '#dedede',
    margin: '25px 0'
  }
};

const OmniFilterRadioSelection: React.FC<OmniFilterRadioSelectionProps> = ({
  filterConfig,
  onFilterChange,
  eventBus
}: OmniFilterRadioSelectionProps) => {
  const { filterOptions, name, mappingName, pluralName } = filterConfig;
  const checkedValue = filterOptions.find((item) => item.isChecked);
  const [selectedValue, setSelectedValue] = useState(checkedValue || { id: '' });
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [staticFilterOption, setStaticFilterOption] = useState(filterOptions);
  const shouldShowExpanded = staticFilterOption.length > 6;
  // bugfix// it was searching the value within 6 when it is collapsed
  let filterOptionsToRender = staticFilterOption.filter((obj) => _toLower(obj.name).includes(_toLower(searchVal)));
  filterOptionsToRender = isExpanded ? filterOptionsToRender : filterOptionsToRender.filter((_e, index) => index < 6);

  const handleRadioChange = (_event, value) => {
    const selectedItem = filterOptions.find((item) => item.id === value);
    const newStaticFilterOption = staticFilterOption.map((obj) => ({
      ...obj,
      isChecked: obj.id === value
    }));
    setSelectedValue(selectedItem);
    setStaticFilterOption(newStaticFilterOption);
    onFilterChange(selectedItem, mappingName);
  };

  const handleUncheckAll = () => {
    setSelectedValue({ id: '' });
    const newStaticFilterOption = staticFilterOption.map((option) => {
      option.isChecked = false;
      return { ...option };
    });
    setStaticFilterOption(newStaticFilterOption);
  };

  const handleSearchVal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(event.target.value);
  };

  useEffect(() => {
    eventBus.on('clearFilter', handleUncheckAll);
    return () => {
      eventBus.off('clearFilter', handleUncheckAll);
    };
  }, [eventBus, handleUncheckAll]);

  return (
    <div style={styles.root}>
      <hr style={styles.mainBorder}></hr>
      <div style={styles.container}>
        <div>
          <p style={styles.headerText}>{name}</p>
        </div>
        <div style={styles.searchContainer}>
          <HeaderSearchIcon
            style={{
              height: '23px',
              width: '23px'
            }}
          />
          <input style={styles.searchInput} placeholder="Search" value={searchVal} onChange={handleSearchVal} />
        </div>
        <div
          style={{
            marginTop: '5px'
          }}
        >
          <RadioGroup
            className="omni_segment-filter__radio-buttons"
            name="segment"
            row
            value={selectedValue ? selectedValue.id : ''}
            onChange={handleRadioChange}
          >
            {(filterOptionsToRender || []).map((item, index) => (
              <FormControlLabel
                value={item.id}
                key={`omni_radio${item.id}_${index}`}
                label={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: '13px',
                      lineHeight: '18px',
                      alignSelf: 'center',
                      width: '100%',
                      color: colors.darkBlue
                    }}
                  >
                    {item.name}
                  </span>
                }
                control={
                  <Radio
                    disableRipple
                    color="primary"
                    sx={{
                      color: colors.darkBlue,

                      '&.Mui-checked': {
                        color: colors.darkBlue
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 18
                      }
                    }}
                  />
                }
              />
            ))}
          </RadioGroup>
        </div>
        <div
          style={{
            marginTop: '12px',
            display: 'flex'
          }}
        >
          {shouldShowExpanded && (
            <button style={styles.filterDropDown} onClick={() => setIsExpanded(!isExpanded)}>
              {!isExpanded ? `Show all ${pluralName}` : `Show less ${pluralName}`}
              <ChevronIcon
                style={{
                  stroke: '#052849',
                  height: '28px',
                  width: '28px',
                  color: colors.darkBlue,
                  fontWeight: 550,
                  transform: isExpanded ? 'translateY(0px) rotate(180deg)' : 'translateY(2px)'
                }}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withBus('eventBus')(OmniFilterRadioSelection);
