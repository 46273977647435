/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

import { store } from 'src/main';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { adPlatformSettingsOperations } from 'src/store/modules/adManager/adPlatformSettings';
import colors from 'src/utils/colors';
import { useOnce } from 'src/utils/Hooks';
import { SectionWrapper, OperationButtons } from '../Widgets/AdCampaignBuilderCommonWidgets';
import Tooltip from '@mui/material/Tooltip';
import ReduxStore from 'src/types/store/reduxStore';
import axios from 'axios';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import Badge from '@mui/material/Badge';

import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import CloseButton from 'src/components/common/Buttons/CloseButton';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(() => ({
  badge: {
    right: -3,
    top: 5,
    border: '2px solid #fff',
    padding: '0 4px',
    color: '#fff'
  }
}));

const mapStateToProps = (state: ReduxStore) => ({
  adCampaignBuilder: state.adCampaignBuilder,
  adPlatformSettings: state.adPlatformSettings
});

const onGridReady = (params: any) => {
  params.api.sizeColumnsToFit();
};

const AudienceColumn = ({ data }: any) => {
  const { name, path } = data;
  const pathName = path ? path.join('/') : null;
  return (
    <div>
      <div>{name}</div>
      {pathName && <div style={{ color: colors.labelGrey }}>{pathName}</div>}
    </div>
  );
};
const TypeColumn = ({ data }: any) => {
  if (data.category) {
    return 'Category';
  }
  return 'Product';
};
const ChangeColumn = connect(mapStateToProps)(({ data, adCampaignBuilder }: any) => {
  const currentAudiences = _get(adCampaignBuilder, ['target', 'audiences'], []);
  const id = data.category || data.product;

  const isAdded = currentAudiences.find((item) => item.category === id || item.product === id);
  const handleAddClick = () => {
    const currentData = {
      ...data,
      bid: 1
    };
    store.dispatch(adCampaignBuilderActions.setTargetAudience([...currentAudiences, currentData]));
  };
  return isAdded ? (
    <div style={{ color: colors.labelGrey }}>added</div>
  ) : (
    <div role="button" style={{ color: colors.stacklineBlue, cursor: 'pointer' }} onClick={handleAddClick}>
      add
    </div>
  );
});

const columnDefs = [
  {
    headerName: 'Name',
    field: 'stacklineSku',
    width: undefined,
    enableRtl: true,
    cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
    headerClass: 'align-left',
    minWidth: 600,
    maxWidth: 1000,
    cellRendererFramework: AudienceColumn
  },
  {
    headerName: 'Type',
    cellRendererFramework: TypeColumn,
    minWidth: 100,
    maxWidth: 100,
    headerClass: 'align-right',
    cellStyle: {
      'justify-content': 'flex-end',
      'text-align': 'right',
      'padding-right': '20px',
      'flex-direction': 'row-reverse'
    }
  },
  {
    headerName: '',
    cellRendererFramework: ChangeColumn,
    minWidth: 100,
    maxWidth: 100,
    pinned: 'right',
    cellStyle: {
      'justify-content': 'flex-end',
      'text-align': 'right',
      'padding-right': '20px',
      'flex-direction': 'row-reverse'
    }
  }
];

const SelectedAudienceDialog = connect(({ adCampaignBuilder }: ReduxStore) => {
  return {
    audiences: _get(adCampaignBuilder, ['target', 'audiences'], []),
    entity: _get(adCampaignBuilder, ['platformSettings', 'entity'])
  };
})(({ open, audiences, setDialogOpen }: { open: boolean; audiences: any[]; setDialogOpen: Function }) => {
  const handleRemove = (id) => {
    const newAudienceList = _cloneDeep(audiences).filter((item) => {
      if (item.category) {
        return item.category !== id;
      } else if (item.product) {
        return item.product !== id;
      }
      return false;
    });
    store.dispatch(adCampaignBuilderActions.setTargetAudience([...newAudienceList]));
  };
  const handleBidChange = (id, value) => {
    const newAudienceList = _cloneDeep(audiences);
    newAudienceList.forEach((item) => {
      if (item.category === id || item.product === id) {
        item.bid = value;
      }
    });
    store.dispatch(adCampaignBuilderActions.setTargetAudience([...newAudienceList]));
  };
  const renderProductList = () => {
    return (
      <div>
        <div
          style={{
            fontSize: 20,
            padding: 19,
            borderBottom: `1px solid ${colors.lightestGrey}`
          }}
        >
          Selected Audiences
        </div>
        {audiences.map((item: any, idx: number) => {
          const { name, category, product, bid } = item;
          return (
            <div
              key={idx}
              style={{
                display: 'flex',
                padding: 19,
                alignItems: 'center',
                fontSize: 14,
                borderBottom: `1px solid ${colors.lightestGrey}`
              }}
            >
              <div style={{ flex: 1 }}>
                <div>{name}</div>
                {category && <div>Category: {category}</div>}
              </div>
              <div>
                Bid:
                <NumberFormat
                  value={bid}
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  placeholder="0.00"
                  fixedDecimalScale
                  allowNegative={false}
                  onValueChange={({ floatValue }) => handleBidChange(category || product, floatValue)}
                  // Mimic the style of the MUI `TextInput`
                  style={{
                    height: '32px',
                    outline: 'none',
                    border: 'none',
                    borderRadius: '5px',
                    width: '80px',
                    padding: 10,
                    marginLeft: 5,
                    fontSize: '14px',
                    color: 'currentColor',
                    background: '#eff1f5',
                    fontFamily: 'Roboto'
                  }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 40 }}>
                <CloseButton
                  onClick={() => handleRemove(category || product)}
                  style={{ position: 'relative', width: 17, height: 17, margin: 0 }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={() => setDialogOpen(false)}>
      <div className="dialog_content" style={{ height: 500, width: 470 }}>
        {renderProductList()}
      </div>
    </Dialog>
  );
});

const AudiencePickUp = ({ selectedFeaturedProducts, entity, adCampaignBuilder }: any) => {
  const [loading, setLoading] = useState(true);
  const [dataset, setDataSet] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();
  const fetchRecommendations = async () => {
    setLoading(true);
    const API = '/apiAdManager/adCampaigns/getSDKeywordRecommendations';
    const skus = selectedFeaturedProducts.map((item) => {
      return item.retailerSku;
    });
    const requestBody = {
      tactic: 'T00030',
      entityId: entity.id,
      retailerSkus: skus,
      typeFilter: ['CATEGORY']
    };
    const res = await axios.post(API, requestBody);

    const newDataSet = _get(res, ['data', 'recommendations', 'categories'], []);

    setDataSet([
      { product: 'exactProduct', bid: 1, name: 'Advertised products' },
      { product: 'similarProduct', bid: 1, name: 'Similar to advertised products' },
      ...newDataSet
    ]);
    setLoading(false);
  };
  useEffect(() => {
    fetchRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <GridLoading />;
  }
  const badgetCount = _get(adCampaignBuilder, ['target', 'audiences', 'length'], 0);
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <div style={{ fontSize: 20 }}>Audiences</div>
        <div>
          {' '}
          <Tooltip title="Selected Audiences" placement="top">
            <Badge badgeContent={badgetCount} color="primary" classes={{ badge: classes.badge }}>
              <ShoppingCartRoundedIcon onClick={() => setDialogOpen(true)} style={{ cursor: 'pointer' }} />
            </Badge>
          </Tooltip>
          <SelectedAudienceDialog open={dialogOpen} setDialogOpen={setDialogOpen} />
        </div>
      </div>
      <CustomAgMaterial
        onGridReady={onGridReady}
        onCellValueChanged={onGridReady}
        onModelUpdated={onGridReady}
        onRowValueChanged={onGridReady}
        onRowDataChanged={onGridReady}
        buildRows={() => dataset}
        columnDefs={columnDefs}
        suppressNoRowsOverlay
        domLayout="autoHeight"
      >
        {loading && <GridLoading />}
        <br />
      </CustomAgMaterial>
    </div>
  );
};

const audienceSettings: React.FC<{} & ReturnType<typeof mapStateToProps>> = ({
  adCampaignBuilder,
  adPlatformSettings
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useOnce(() => {
    if (_isEmpty(adPlatformSettings)) {
      store.dispatch(adPlatformSettingsOperations.fetchAdPlatformSettings());
    }
  });

  const { featured, platformSettings } = adCampaignBuilder;

  if (_isEmpty(adPlatformSettings) || _isEmpty(platformSettings)) {
    return null;
  }
  const { selectedFeaturedProducts } = featured;
  const { entity } = platformSettings;
  return (
    <div className="ad-manager-container">
      <SectionWrapper header="Audience Setting" subheader="Select the audiences for this campaign." layer={0}>
        <AudiencePickUp
          selectedFeaturedProducts={selectedFeaturedProducts}
          entity={entity}
          adCampaignBuilder={adCampaignBuilder}
        />
      </SectionWrapper>
      <br />
      <OperationButtons subtab="audienceSettings" canContinue />
    </div>
  );
};

export default connect(mapStateToProps)(audienceSettings);
