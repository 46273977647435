import React from 'react';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import { BEACON_PRO_RIGHT_CONTENT_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

const TopEntityChartV2Loading = () => {
  const legendSkeleton = (
    <Flex gap="sm">
      <SlSkeleton variant="rounded" height={23} width={152} />
    </Flex>
  );

  return (
    <Flex flexDirection="column" gap="lg">
      <Flex gap="lg">
        {legendSkeleton}
        {legendSkeleton}
      </Flex>
      <SlSkeleton variant="rounded" height={422} width={BEACON_PRO_RIGHT_CONTENT_WIDTH} />
    </Flex>
  );
};

export default TopEntityChartV2Loading;
