/* eslint-disable react/display-name */
import React, { Ref } from 'react';
import Snackbar from '@mui/material/Snackbar';
import styled from '@emotion/styled';
import { StacklineTheme, useStacklineTheme, hexToRgba } from '@stackline/ui';

export type SnackbarType = 'success' | 'error' | 'info' | 'primary';

// Define the props for StyledSnackbarInner
interface StyledSnackbarInnerProps {
  stacklineTheme: StacklineTheme;
  type: SnackbarType;
}

type SnackbarTypeColorMap = {
  [key in SnackbarType]: StacklineTheme['colors'][key];
};

export const colorMap: SnackbarTypeColorMap = {
  success: 'success',
  error: 'error',
  info: 'accentGold',
  primary: 'primary'
};

const StyledSnackbarInner = styled(Snackbar)<StyledSnackbarInnerProps>(({ stacklineTheme, type }) => ({
  '& .MuiSnackbarContent-root': {
    minWidth: 'unset' // MUI defaults to 228 but we want the padding on either side to stay the same
  },
  '& .MuiPaper-root': {
    height: '56px',
    backgroundColor: hexToRgba(stacklineTheme.colors[colorMap[type]], 0.2),
    color: stacklineTheme.colors.primary,
    fontSize: '14px',
    fontWeight: 500
  },

  '&.MuiSnackbar-root': {
    backgroundColor: 'white',
    borderRadius: '4px'
  }
}));

/**
 * Generic styled snackbar component
 */
const StyledSnackbar = React.forwardRef(
  (props: React.ComponentProps<typeof Snackbar> & { type: SnackbarType }, ref: Ref<HTMLDivElement>): JSX.Element => {
    const theme = useStacklineTheme();
    return <StyledSnackbarInner ref={ref} stacklineTheme={theme} {...props} />;
  }
);

export interface ButtonProps {
  text?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export type StyledSnackbarProps = React.ComponentProps<typeof StyledSnackbar> & {
  buttonProps: ButtonProps;
  showButton: boolean;
};

export default StyledSnackbar;
