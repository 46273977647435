import { UseMutationOptions, useMutation } from 'react-query';
import { RevertBulkAdjustmentPayload, RevertBulkAdjustmentResponse } from '../serverProxy/types';
import useServerProxy from './useServerProxy';

/**
 * Returns a function which reverts a bulk adjustment
 */
export default function useRevertBulkAdjustment(
  options: UseMutationOptions<RevertBulkAdjustmentResponse, unknown, RevertBulkAdjustmentPayload> = {}
) {
  const { revertBulkAdjustment } = useServerProxy();
  return useMutation(revertBulkAdjustment, options);
}
