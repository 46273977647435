import React, { useMemo } from 'react';
import { AppLinkTabs } from 'src/components/BeaconRedesignComponents/PrimaryPageNavigation/PrimaryNavBar';
import styled from '@emotion/styled';
import { SlColumn, Text } from '@stackline/ui';
import { useSettingsRoutes } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/settingsPageConfigs';
import { useLocation } from 'src/utils/Hooks';
import { useGenericSideBarStyles } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/GenericSidebar';
import ReduxStore from 'src/types/store/reduxStore';
import {
  BEACON_PRO_SIDEBAR_WIDTH,
  BEACON_PRO_RIGHT_CONTENT_WIDTH,
  BEACON_PRO_SIDEBAR_RIGHT_PADDING
} from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

const SidebarContainer = styled('div')({
  width: BEACON_PRO_SIDEBAR_WIDTH
});

export interface SettingsProfile {
  company?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  jobTitle?: string;
}

export interface NewSettingsLayoutProps {
  user: ReduxStore['user'];
  onSubmit: () => void;
  handlePhoneNumberChange: () => void;
  handleNewPasswordChange: () => void;
  handlePasswordConfirmationChange: () => void;
  handleProfileChange: () => void;
  entityService?: ReduxStore['entityService'];
  profile: SettingsProfile;
}

/**
 *  New settings page layout with Navigation.
 */
const NewSettingsLayout = (props: NewSettingsLayoutProps) => {
  const classes = useGenericSideBarStyles();

  const settingRoutes = useSettingsRoutes(props);

  const { pathname } = useLocation();

  const activeIndex = useMemo(() => {
    const currentRoute = settingRoutes.find((routeObject) => routeObject.route.includes(pathname)) || settingRoutes[0];
    return currentRoute.index;
  }, [pathname, settingRoutes]);

  return (
    <div style={{ display: 'flex', gap: BEACON_PRO_SIDEBAR_RIGHT_PADDING }}>
      <SidebarContainer>
        <div>
          <Text variant="h4">Settings</Text>
        </div>
        <AppLinkTabs
          tabIndex={activeIndex}
          setTabIndex={() => {}}
          tabRoutes={settingRoutes}
          tabsProps={{
            classes: {
              root: classes.tabs
            }
          }}
          containerStyle={{
            marginLeft: 0,
            marginRight: 0
          }}
          tabProps={{
            classes: {
              root: classes.tab
            }
          }}
        />
      </SidebarContainer>
      <SlColumn>
        <div style={{ width: BEACON_PRO_RIGHT_CONTENT_WIDTH }}>{settingRoutes[activeIndex].renderComponent}</div>
      </SlColumn>
    </div>
  );
};

export default NewSettingsLayout;
