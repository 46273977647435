/* eslint-disable */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { RouteComponentProps } from 'react-router';
import { useAppSelector, useHistory, useUpdateQueryParams } from 'src/utils/Hooks';
import _last from 'lodash/last';
import AdMediaUpload from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdMediaUpload';
import Typography from '@mui/material/Typography';
import _get from 'lodash/get';
import { AdWalmartService } from 'src/components/AdManager/walmartUtils';
import AdLimitedTextField from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdLimitedTextField';
import { AdMediaTable } from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdMediaTable/AdMediaTable';
import { useUpload } from 'src/components/Layout/Advertising/AdMediaManagementLayout/hooks/useUpload';
import { useQueryClient } from 'react-query';
import { CAPTION_SPEC, FileSpec, VIDEO_SPEC } from 'src/components/Layout/Advertising/AdMediaManagementLayout/specs';
import {
  validateCaptionFile,
  validateVideoFile
} from 'src/components/Layout/Advertising/AdMediaManagementLayout/validators';
import { AdFeedbackModal } from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdMediaTable/AdFeedbackModal';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';

interface AdMediaManagementProps {
  history: RouteComponentProps['history'];
}

const renderSecondColumnBody = (_SPEC: FileSpec) => {
  return (
    <div>
      {_SPEC.map(([sectionTitle, specifications], index) => (
        <div style={{ marginBottom: 25 }} key={index}>
          <Typography fontSize={20} fontWeight={500} marginBottom={2}>
            {sectionTitle}
          </Typography>
          <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
            {specifications.map((specification, index) => (
              <li key={index}>
                <Typography fontSize={16} marginBottom={1}>
                  <strong style={{ fontWeight: 500 }}>{specification.name}</strong> {specification.displayValue}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

function sanitizeString(str = '') {
  return str
    .replace(/\n/g, ' ') // Replaces newline characters
    .replace(/\r/g, ' ') // Replaces carriage return characters
    .replace(/\t/g, '') // Replaces tab characters
    .replace(/\b/g, ' ') // Replaces backspace characters
    .replace(/\f/g, ' '); // Replaces form feed characters
}

export const AdMediaManagement = (_props: AdMediaManagementProps) => {
  const beaconClientLoginId = useAppSelector((state) =>
    _get(state, 'adCampaignBuilder.platformSettings.entity.extendedAttributes.beaconClientLoginId', null)
  );

  const history = useHistory();
  const queryClient = useQueryClient();

  const updateQuery = useUpdateQueryParams(history);

  if (!beaconClientLoginId) {
    updateQuery({
      additionalParams: {
        tab: 'adCampaignBuilder',
        subtab: 'platformSelect'
      }
    });
  }

  const { file, caption, setFile, setCaption, uploadFiles, mediaUploadRequestId } = useUpload(beaconClientLoginId);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [open, setOpenFeedback] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const noFile = !file;
  const notValid = noFile || isSubmitting || !name;

  const clearState = () => {
    setFile(null);
    setCaption(null);
    setName('');
    setDescription('');
  };

  /**
   * Step 3. Complete.
   */
  const submitForm = async () => {
    if (notValid) {
      return;
    }
    try {
      setSubmitting(true);
      setOpenFeedback(true);
      await uploadFiles();

      const payload = {
        mediaName: sanitizeString(name).trim(),
        mediaDescription: sanitizeString(description).trim(),
        mediaUploadRequestId,
        beaconClientLoginId
      };

      const submitRes = await AdWalmartService.completeUpload(payload);

      if (submitRes.status === 200) {
        // invalidate cache
        queryClient.invalidateQueries({ queryKey: ['AdWalmartService_getMedia'] });
      }
    } catch (e) {
    } finally {
      setSubmitting(false);
      clearState();
    }
  };

  const handleFileSelected = async (selectedFile: File | null) => {
    setFile(selectedFile);
  };

  const handleCaptionSelected = (selectedFile: File | null) => {
    setCaption(selectedFile);
  };

  const handleClose = () => {
    setOpenFeedback(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '1340px',
        paddingTop: '64px'
      }}
    >
      <header>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          <Typography fontSize={28}>Media Management</Typography>

          <LargeMuiButton
            disabled={notValid}
            onClick={submitForm}
            style={{
              height: 40,
              padding: '12px 38px',
              objectFit: 'contain',
              borderRadius: 2,
              position: 'absolute',
              right: 0,
              fontSize: 12,
              fontWeight: 400,
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 3.33,
              textAlign: 'left',
              width: 120
            }}
          >
            SUBMIT
          </LargeMuiButton>
        </div>

        {/* Divider */}
        <div style={{ border: '1px solid #dedede', marginTop: 32 }}></div>

        <div>
          <Typography fontSize={20} fontWeight={500} marginTop={4}>
            Media Creation
          </Typography>
          <Typography fontSize={16} marginTop={2}>
            Media name
          </Typography>

          <AdLimitedTextField
            characterLimit={45}
            label="Enter media title"
            specialCharsRegex={/[!@#$%^&*(),.?":{}|<>]/}
            initValue={name}
            onChange={setName}
          />
        </div>
        <div>
          <Typography fontSize={16} marginTop={4}>
            Media description (Optional)
          </Typography>
          <AdLimitedTextField
            characterLimit={200}
            label="Enter media description"
            initValue={description}
            onChange={setDescription}
          />
        </div>
        <div>
          {/* Theme: 1 margin = 8px */}
          <Typography fontSize={16} fontWeight={500} marginTop={4} marginBottom={2}>
            Video
          </Typography>
          <Typography fontSize={16} marginBottom={2}>
            Choose a video to showcase in your campaign.
          </Typography>
          <AdMediaUpload
            file={file}
            onFileSelected={handleFileSelected}
            validateFile={validateVideoFile}
            secondColumnBody={renderSecondColumnBody(VIDEO_SPEC)}
          />
          {file && <p>Selected file: {file.name}</p>}
        </div>
        <div>
          <Typography fontSize={16} fontWeight={500} marginTop={4} marginBottom={2}>
            Caption (Optional)
          </Typography>
          <Typography fontSize={16} marginBottom={2}>
            Captions are optional but recommended.
          </Typography>
          <AdMediaUpload
            file={caption}
            onFileSelected={handleCaptionSelected}
            validateFile={validateCaptionFile}
            secondColumnBody={renderSecondColumnBody(CAPTION_SPEC)}
            isCaptions
          />
          {caption && <p>Selected caption: {caption.name}</p>}
        </div>
      </header>
      <AdFeedbackModal
        header="Submitting Media"
        body="Your media asset has been submitted to Walmart for processing. This may take up to 30 minutes. You can close this window in the meantime."
        open={open}
        handleClose={handleClose}
      />
      <section>
        <Typography fontSize={28} marginTop={8}>
          Media Library
        </Typography>
        <Typography fontSize={16} marginTop={2}>
          View all uploaded media in this entity.
        </Typography>
        <AdMediaTable style={{ marginTop: 32 }} />
      </section>
    </Box>
  );
};

export default AdMediaManagement;
