import React from 'react';
import { connect } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { WidgetProps } from 'src/types/application/widgetTypes';
import DailySpendBarChart from './DailySpendBarChart';
import './AdPerformanceBarChart.scss';
import ComponentInView from 'src/components/common/ComponentInView/ComponentInView';

const mapStateToProps = ({ entityService }: ReduxStore) => ({ entityService });

type AdPerformanceBarChartProps = WidgetProps & ReturnType<typeof mapStateToProps>;

const AdPerformanceBarChart: React.FC<AdPerformanceBarChartProps> = ({ ...widgetProps }) => (
  <ComponentInView id="budget_pacing_chart">
    <div className="ad-performance-bar-chart">
      <DailySpendBarChart {...widgetProps} />
    </div>
  </ComponentInView>
);

const EnhancedAdPerformanceBarChart = connect(mapStateToProps)(AdPerformanceBarChart);

export default EnhancedAdPerformanceBarChart;
