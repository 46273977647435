import types, { AdTargetMatchType } from './types';
import actionCreators from './actions';
import { ValueOf } from 'sl-api-connector';
import { AD_TARGET_MATCH_TYPE } from 'sl-ad-campaign-manager-data-model';

// ------------------------------------
// Reducer
// ------------------------------------

const adTargetMatchTypesAvailable: AdTargetMatchType[] = [
  {
    id: AD_TARGET_MATCH_TYPE.BROAD,
    targetingType: AD_TARGET_MATCH_TYPE.BROAD,
    name: 'Broad',
    displayName: 'Broad',
    type: 'adTargetMatchType'
  },
  {
    id: AD_TARGET_MATCH_TYPE.PHRASE,
    targetingType: AD_TARGET_MATCH_TYPE.PHRASE,
    name: 'Phrase',
    displayName: 'Phrase',
    type: 'adTargetMatchType'
  },
  {
    id: AD_TARGET_MATCH_TYPE.EXACT,
    targetingType: AD_TARGET_MATCH_TYPE.EXACT,
    name: 'Exact',
    displayName: 'Exact',
    type: 'adTargetMatchType'
  }
];
type Action = ValueOf<{ [K in keyof typeof actionCreators]: ReturnType<(typeof actionCreators)[K]> }>;

export function adTargetMatchTypes(state = adTargetMatchTypesAvailable, action: Action) {
  switch (action.type) {
    case types.RECEIVE_AD_TARGET_MATCH_TYPES:
      return action.adTargetStates;
    case types.CLEAR_AD_TARGET_MATCH_TYPES:
      return [];
    default:
      return state;
  }
}
