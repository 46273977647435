import React, { useMemo, memo } from 'react';
import _get from 'lodash/get';
import queryString from 'qs';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { getCampaignDisplayStatus } from 'src/components/AdManager/AmsUtils';
import { StatusIndicatorColor } from 'src/components/SummaryTile/SummaryTile';
import DataColumn from 'src/components/AdManager/Search/CustomColumns/DataColumn';
import { AmsBudgetColumn } from 'src/components/AdBudgetEditor/Steps/PortfolioAlign';
import NumberFormat from 'react-number-format';
import numeral from 'numeral';
import moment from 'moment';
import { List as VirtualizedList } from 'react-virtualized';

interface OrphanCampaignsTableProps {
  /**
   * Callback for updating the budget for an individual campaign
   */
  handleCampaignBudgetChange: (id: string | number, value: number | undefined) => void;
}

/**
 * Renders a virtualized AG Grid table with all of the orphan
 * campaigns in the Budget Allocation page in Drive.
 */
const OrphanCampaignsTable = ({ handleCampaignBudgetChange }: OrphanCampaignsTableProps) => {
  const { adBudgetEditor, app, retailer } = useSelector((state: ReduxStore) => state);
  const { entity } = adBudgetEditor.entity;
  const daysInMonth = moment().add(adBudgetEditor.timeline.id, 'M').daysInMonth();
  const {
    queryParams: { searchParams, additionalParams }
  } = app;
  const parsedAdditionalParameters = queryString.parse(additionalParams);
  parsedAdditionalParameters.tab = 'adManager';
  parsedAdditionalParameters.subtab = 'keyMetrics';

  const unassignedCampaigns = useMemo(() => {
    return adBudgetEditor.campaignArray.filter(
      (campaign) =>
        campaign.entity.extendedAttributes.portfolioIdApi === 'unassigned' &&
        !['Archived', 'Ended'].includes(
          getCampaignDisplayStatus(campaign.entity.extendedAttributes as never).displayName
        ) &&
        campaign.entity.extendedAttributes.entityId === entity.id
    );
  }, [entity.id, adBudgetEditor.campaignArray]);

  return (
    <div className="campaign_table">
      <div className="header campaign_row">
        <div className="header campaign_name">Campaign</div>
        <div className="header campaign_spend">Ad Spend</div>
        <div className="header campaign_sales">Ad Sales</div>
        <div className="header campaign_spend">CPC</div>
        <div className="header campaign_roas">ROAS</div>
        <div className="header campaign_spend">AMS Budget</div>
        <div className="header campaign_budget">Daily Budget</div>
        <div className="header campaign_budget">Monthly Budget</div>
      </div>
      <VirtualizedList
        height={500}
        rowCount={unassignedCampaigns.length}
        rowHeight={56}
        width={1270}
        rowRenderer={({ index, style }) => {
          const campaign = unassignedCampaigns[index];
          const campaignBudget = _get(campaign, ['entity', 'extendedAttributes', 'newBudgetSetting', 'amount'], null);
          const linkTo = `/adCampaign/${campaign.id}${searchParams}&${queryString.stringify(
            parsedAdditionalParameters
          )}`;

          return (
            <div style={style} key={campaign.id} className="campaign_row">
              <div className="campaign_name">
                <StatusIndicatorColor
                  {...campaign.entity}
                  style={{
                    minWidth: '10px',
                    width: '10px',
                    borderRadius: '15px',
                    height: '10px',
                    marginRight: 6
                  }}
                />
                <Link to={linkTo} target="_blank">
                  {campaign.entity.campaignName}
                </Link>
              </div>

              <div className="campaign_spend">
                <DataColumn value={campaign.spend} />
              </div>
              <div className="campaign_sales">
                <DataColumn value={campaign.sales} />
              </div>
              <div className="campaign_spend">
                <DataColumn value={campaign.costPerClick} />
              </div>
              <div className="campaign_roas">
                <DataColumn value={campaign.returnOnAdSpend} />
              </div>
              <div className="campaign_roas">
                <AmsBudgetColumn data={campaign} />
              </div>
              <div className="campaign_budget">
                <NumberFormat
                  isAllowed={() => {
                    return true;
                  }}
                  value={campaignBudget}
                  thousandSeparator
                  prefix={retailer.currencySymbol}
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  onValueChange={({ floatValue }) => {
                    handleCampaignBudgetChange(campaign.id, floatValue);
                  }}
                />
              </div>
              <div className="campaign_budget">{numeral((campaignBudget || 0) * daysInMonth).format('$1,000.00')}</div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default memo(OrphanCampaignsTable);
