import axios from 'axios';
import { userOperations } from '../user';
import { appOperations } from '../app';
import { addHashIdToUserConfig } from '../user/selectors';
import * as Creators from './actions';
import { checkS3FileExists } from 'src/components/SignIn/utils';
import { shouldShowNewBeaconWithoutRedux } from 'src/utils/app';
import _get from 'lodash/get';
import { isSingleBeaconProRetailerSubscribed } from './utils/utils';

export const { clearMessage, signInFailure, signInSuccess, signOutFailure, signOutSuccess, setErrorMessage } = Creators;

async function handleResponse(response, dispatch, { email }) {
  const { session, userConfig } = response.data;
  const responseObject = {
    status: 'good',
    data: response.data
  };

  const beaconProSubscribed = _get(response.data, ['userConfig', 'appSubscriptions'], []).includes('beacon-pro');

  const responseWithHashId = addHashIdToUserConfig({ data: response.data });
  if (session.status === 'bad') {
    if (session.statusMessage === 'passwordExpired') {
      responseObject.status = session.status;
      responseObject.redirect = `/password_reset?step=passwordExpired&username=${encodeURIComponent(email)}`;
      responseObject.redirectType = 'local';
      return responseObject;
    } else {
      dispatch(signInFailure('Invalid username or password.'));
      responseObject.status = session.status;
      return responseObject;
    }
  }

  if (session.status === 'super_user_not_allowed') {
    responseObject.status = 'bad';
    dispatch(signInFailure(session.statusMessage));
  } else if (session.hasValidBilling) {
    if (session.statusMessage === 'beaconRedirect') {
      responseObject.redirect = 'https://beacon.stackline.com';
      responseObject.redirectType = 'window';
    } else if (
      // If the user has 1 retailer subscribed, and its subbed to beacon pro, and we're on legacy beacon, redirect to beacon pro
      isSingleBeaconProRetailerSubscribed(response.data) &&
      beaconProSubscribed &&
      window.location.host.includes('beacon.stackline.com')
    ) {
      responseObject.redirect = 'https://beaconpro.stackline.com';
      responseObject.redirectType = 'window';
    } else if (
      // If we're on beaconpro and the user is not subscribed to beaconpro, redirect to beacon
      window.location.host.includes('beaconpro') &&
      !beaconProSubscribed
    ) {
      responseObject.redirect = 'https://beacon.stackline.com';
      responseObject.redirectType = 'window';
    } else if (window.location.host.includes('enterprise-sso')) {
      responseObject.redirectType = 'window';
    } else {
      const isProfileImageUploaded = await checkS3FileExists(session.userId);

      if (
        !isProfileImageUploaded &&
        shouldShowNewBeaconWithoutRedux({
          userEmail: session.email,
          beaconClientId: userConfig.vendor.BeaconClientId,
          appSubscriptions: userConfig.appSubscriptions
        })
      ) {
        responseObject.isProfileImageUploaded = false;
      } else {
        responseObject.isProfileImageUploaded = true;
        dispatch(appOperations.receiveAppConfig(responseWithHashId));
        dispatch(userOperations.receiveUserConfig(responseWithHashId));
        dispatch(signInSuccess());
      }
      responseObject.redirect = '/';
      responseObject.redirectType = 'local';

      return responseObject;
    }
  } else if (session.statusMessage === 'trialExpired' || session.statusMessage === 'billingFailed') {
    if (session.isAdmin) {
      responseObject.redirect = '/subscribe';
      responseObject.redirectType = 'local';
      dispatch(userOperations.receiveUserConfig(responseWithHashId));
      dispatch(signInSuccess());
    } else {
      let message = '';
      if (session.statusMessage === 'trialExpired') {
        message = 'Free trial expired';
      } else if (session.statusMessage === 'billingFailed') {
        message = 'Billing failed';
      }
      responseObject.status = 'bad';
      dispatch(signInFailure(`${message}. Please contact ${session.adminEmail} about your stackline subscription.`));
    }
  } else if (session.statusMessage === 'beaconRedirect') {
    responseObject.redirect = 'https://beacon.stackline.com';
    responseObject.redirectType = 'window';
  } else if (session.statusMessage === 'accountLocked') {
    responseObject.status = 'bad';
    dispatch(signInFailure('Account is locked. Please contact support@stackline.com about your subscription.'));
  } else {
    responseObject.status = 'bad';
    dispatch(signInFailure('Billing failed. Please contact support@stackline.com about your subscription.'));
  }

  return responseObject;
}

/**
 * Issue a login API call using the given baseUrl and credentials
 */
const logInCall = (baseUrl, email, password) =>
  axios.post(
    `https://${baseUrl}/api/user/Login`,
    {
      username: email,
      password
    },
    {
      headers: {
        Authorization: `Basic ${btoa(`${email}:${password}`)}`
      }
    }
  );

/**
 * Issue a sso login API call using the given baseUrl and credentials
 */
export const ssoLogInCall = (baseUrl, email, password, referrer) =>
  axios.post(
    `https://${baseUrl}/api/user/LoginSSO`,
    {
      username: email,
      password,
      referrer
    },
    {
      headers: {
        Authorization: `Basic ${btoa(`${email}:${password}`)}`
      }
    }
  );

/**
 * Issue a login API call using the given baseUrl and ignore any response or error
 */
export const logInFireAndForget = (baseUrl, email, password) =>
  logInCall(baseUrl, email, password)
    .then(() => {})
    .catch(() => {});

/**
 * Issue a login API call and handle its response or error
 */
export const logIn = (baseUrl, email, password) => (dispatch) =>
  logInCall(baseUrl, email, password)
    .then((response) => handleResponse(response, dispatch, { email }))
    .catch((error) => {
      dispatch(signInFailure(error));
    });

/**
 * Issue a login API call and handle its response or error
 */
export const ssoLogIn = (baseUrl, email, password, referrer) => (dispatch) =>
  ssoLogInCall(baseUrl, email, password, referrer)
    .then((response) => handleResponse(response, dispatch, { email }))
    .catch((error) => {
      dispatch(signInFailure(error));
    });

export const logOut = () => (dispatch) =>
  axios
    .get('/api/user/Logout')
    .then((response) => {
      dispatch(signOutSuccess(false));
      return response;
    })
    .catch((err) => {
      dispatch(signOutFailure(err));
      throw err;
    });

export const signUpByInvite = (requestBody) => (dispatch) =>
  axios
    .post('/api/user/UserSignupByInvite', requestBody)
    .then((response) => handleResponse(response, dispatch, { email: requestBody.email }))
    .catch((err) => {
      dispatch(signInFailure(err));
      throw err;
    });

export const getUserByInviteCode = (inviteCode) => () =>
  axios.post(`/api/user/GetUserByInviteCode?code=${inviteCode}&rid=1`);

export const resetPassword = (email) => () => axios.post(`/api/user/SendPasswordReset?email=${email}`);

export const createNewPassword = (requestBody) => () => axios.post('/api/user/ResetPassword', requestBody);
