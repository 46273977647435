/* eslint-disable react/prop-types */
import React from 'react';
import fontStyle from 'src/utils/fontStyle';

const EmptyTeamSavedSearches: React.FC<{ entityDefinition: { type: string } }> = ({ entityDefinition }) => {
  const noDataText = entityDefinition.type === 'searchtermlist' ? 'No team keyword lists' : 'No team segments';
  const teamSavedSearchDataText =
    entityDefinition.type === 'searchtermlist'
      ? "Your team's keyword lists will display here."
      : "Your team's segments will display here.";

  return (
    <>
      <hr className="sl-divider" style={{ margin: '80px 30px 100px 30px' }} />
      <div style={{ padding: '0 0 100px 0' }}>
        <div style={{ textAlign: 'center' }}>
          <h3 style={{ fontSize: '20px', fontWeight: fontStyle.regularWeight }}>{noDataText}</h3>
          <p style={{ fontSize: '12px' }}>{teamSavedSearchDataText}</p>
        </div>
      </div>
    </>
  );
};

export default EmptyTeamSavedSearches;
