import { useDispatch } from 'react-redux';
import updateComparisonTimePeriod from 'src/store/modules/comparison-time-period/operations';
import { useAppSelector, useHistory } from 'src/utils/Hooks/typedHooks';
import { useUpdateQueryParams } from 'src/utils/Hooks/useUpdateQueryParams';
import { computeComparisonTimePeriod } from 'src/utils/dateformatting';

// Updates the comparison time period in the store and the URL query params
export const useUpdateComparisonTimePeriod = () => {
  const retailer = useAppSelector((state) => state.retailer);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const comparisonTimePeriod = useAppSelector((state) => state.comparisonTimePeriod);
  const allWeekIdsByRetailerId = useAppSelector((state) => state.allWeekIdsByRetailerId);
  const history = useHistory();
  const updateQuery = useUpdateQueryParams(history);
  const dispatch = useDispatch();

  return (periodId: string) => {
    const updatedTimePeriod = computeComparisonTimePeriod(
      allWeekIdsByRetailerId[retailer.id],
      mainTimePeriod,
      periodId
    );

    const {
      endWeek: comparisonEndWeek,
      startWeek: comparisonStartWeek,
      startDayId,
      endDayId,
      startWeekStartDate,
      endWeekEndDate
    } = updatedTimePeriod;

    dispatch(
      updateComparisonTimePeriod(
        comparisonTimePeriod.availableComparisonTimePeriods
          .map((x: { id: string; shortDisplayName: string }) => x.id)
          .indexOf(periodId),
        comparisonStartWeek,
        comparisonEndWeek,
        startDayId,
        endDayId,
        startWeekStartDate,
        endWeekEndDate
      )
    );

    updateQuery({ updatedComparisonPeriod: updatedTimePeriod });
  };
};
