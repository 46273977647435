import { shouldShowNewBeacon } from 'src/utils/app';
import { CSSProperties } from 'react';

interface ShouldUseBeaconStyleParams {
  propertyName: keyof CSSProperties;
  originalValue: string;
  newBeaconValue: string;
}

export const getNewBeaconWidgetStyles = (properties: ShouldUseBeaconStyleParams[]): CSSProperties => {
  const newCssObj: CSSProperties = {};

  properties.forEach(({ propertyName, originalValue, newBeaconValue }) => {
    if (shouldShowNewBeacon()) {
      newCssObj[propertyName as any] = newBeaconValue;
    } else {
      newCssObj[propertyName as any] = originalValue;
    }
  });

  return newCssObj;
};
