import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import Creators from './actions';
import parseVendorCodes from './selectors';
import ReduxStore from 'src/types/store/reduxStore';

export const { receiveVendorCodes, clearVendorCodes } = Creators;

export const fetchVendorCodes =
  () => async (dispatch: ThunkDispatch<ReduxStore, void, any>, getState: () => ReduxStore) => {
    const appName = getState().app.apiAppName;

    const request = [
      {
        name: `${appName}-vendorCodes`,
        pageNumber: 1,
        pageSize: 500,
        doAggregation: false,
        returnDocuments: true,
        searchBy: 'parent',
        searchType: `${appName}-vendorCodes`,
        aggregations: null
      }
    ];

    const response = await axios.post(`/api/${appName}/AdvancedSearch?_id=fetchVendorCodes`, request);
    dispatch(receiveVendorCodes(parseVendorCodes(response)));
  };
