import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import _get from 'lodash/get';

import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import { EnhancedDonutChartContainer } from 'src/components/Charts/Donut';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import AtlasProductCellFormatter from 'src/components/EntityGrid/Table/AtlasProductCellFormatter';
import EntityGrid from 'src/components/EntityGrid';
import PromotionsGridContainer from 'src/components/EntityGrid/Table/PromotionsGridContainer';
import buildConversionRateGridWidget from 'src/components/Layout/util/ConversionRateGrid';
import {
  buildTrendWidgetConfig,
  buildTopEntitiesWidgetConfig,
  buildWidgetGroup,
  buildMultiGroupByTopEntitiesChartWidgetConfig
} from 'src/components/Layout/LayoutUtil';
import { AtlasContentScoreColumnChart } from 'src/components/EntityPage/Content';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';

const conditionsToFilterZeroReview = {
  rangeFilters: [
    {
      fieldName: 'reviewsRating',
      minValue: 0.01,
      maxValue: 5.0
    }
  ]
};

const conditionsToFilterZeroContentScore = {
  // rangeFilters: [
  //   {
  //     fieldName: 'contentScore',
  //     minValue: 0.1
  //   }
  // ]
};

const buildRatingsWidgets = ({ app, indexName, dataConfig, entity, weekIdField }) => {
  const mainMetricField = {
    ...INDEX_FIELDS.getField(app.name, 'sales', 'reviewsRating', entity.type, dataConfig.retailerIdField.name),
    conditions: conditionsToFilterZeroReview
  };
  const ratingDonutChart = {
    CustomComponent: EnhancedDonutChartContainer,
    view: {
      ...VIEWS.donutChart,
      name: `AverageReviewsDonutChart`,
      avoidIsLatestComputation: true,
      chartPropsOverride: {
        chart: {
          type: 'pie',
          title: { text: 'Rating' }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            }
          }
        },
        tooltip: {
          enabled: false
        },
        disablePointSelect: true,
        enableMultiSelect: false
      },
      customHeader: {
        enabled: true,
        title: 'Rating'
      },
      container: {
        style: {
          width: '500px',
          height: '440px'
        }
      }
    },
    data: {
      indexName: 'sales',
      chartMainField: mainMetricField,
      mainMetric: mainMetricField,
      secondaryMetric: { ...mainMetricField, displayName: 'Rating' },
      aggregationFields: [mainMetricField],
      groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.retailerIdField.name, entity.type)
    }
  };

  const ratingTrendChart = buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['reviewsRating'], weekIdField, {
    view: {
      chartPropsOverride: {
        title: {
          text: ''
        },
        subtitle: {
          text: '',
          style: {
            'border-bottom': 'none'
          }
        },
        chart: {
          height: 450,
          marginTop: 100
        },
        legend: { enabled: true },
        yAxis: [
          {
            visible: false
          }
        ]
      },
      container: { style: { width: '60%', display: 'inline-block', marginBottom: '0px' } }
    }
  });
  ratingTrendChart.data.avoidZeros = true;
  ratingTrendChart.data.isRatingView = true;
  ratingTrendChart.data.configByGroupByFieldName.weekId.aggregationFields[0] = {
    ...ratingTrendChart.data.configByGroupByFieldName.weekId.aggregationFields[0],
    conditions: conditionsToFilterZeroReview
  };

  const mainWidgetsGroup = buildWidgetGroup([ratingDonutChart, ratingTrendChart], {
    view: {
      container: {
        style: {
          height: '550px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around'
        }
      }
    }
  });

  const reviewsRatingField = {
    ...INDEX_FIELDS.getField(app.name, 'sales', 'reviewsRating', 'product', 'stacklineSku'),
    conditions: conditionsToFilterZeroReview
  };

  const gridAggregationFields = [
    reviewsRatingField,
    INDEX_FIELDS.getField(app.name, 'sales', 'reviewsCount', 'product', 'stacklineSku'),
    INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', 'product', 'stacklineSku')
  ].map((field) => ({
    ...field,
    cellRendererFramework: AtlasProductCellFormatter,
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  }));

  // Ratings grid
  const gridWidget = {
    name: 'entityGrid',
    CustomComponent: EntityGrid,
    view: _merge({}, VIEWS.entityGrid, { gridOptions: {} }),
    data: {
      defaultSortField: INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', 'product', 'stacklineSku'),
      groupByFields: [INDEX_FIELDS.getField(app.name, 'sales', 'stacklineSku', 'product')],
      configByGroupByFieldName: {
        stacklineSku: {
          indexName: 'sales',
          entity: ENTITIES.atlas.product,
          mainMetricField: reviewsRatingField,
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', 'product', 'stacklineSku'),
            reviewsRatingField,
            INDEX_FIELDS.getField(app.name, 'sales', 'reviewsCount', 'product', 'stacklineSku')
          ],
          tableView: {
            metricFields: gridAggregationFields
          }
        }
      }
    }
  };

  return [mainWidgetsGroup, gridWidget];
};

export const getGroupByMapping = (metricType) => {
  const [category, subcategory, brand, parentBrand, license] = ['categoryId', 'subCategoryId', 'brandId', 'parentBrandId', 'licenseId'].map(
    (groupByFieldName) => ({
      groupByFieldName,
      aggregationFieldNames: [metricType]
    })
  );
  return { category, subcategory, brand, parentBrand, license };
};

export default function getConversionPageLayout({ app, tab, metricType, entity }) {
  const indexName = tab;
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));

  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.atlas.retailer),
    categoryEntity: _cloneDeep(ENTITIES.atlas.category),
    relatedEntity: _cloneDeep(ENTITIES.atlas.brand),
    weekIdField: _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId')),
    retailerIdField: _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'))
  };

  const widgets = [];
  if (metricType === 'rating') {
    widgets.push(...buildRatingsWidgets({ app, indexName, dataConfig, entity, weekIdField }));
  }

  if (tab === 'promotions') {
    const mainMetricField = INDEX_FIELDS.getField(
      app.name,
      indexName,
      metricType,
      entity.type,
      dataConfig.retailerIdField.name
    );

    widgets.push(
      buildWidgetGroup(
        [
          // promotion sales donut
          {
            CustomComponent: EnhancedDonutChartContainer,
            view: {
              ...VIEWS.donutChart,
              name: `PromoDonutChart`,
              chartPropsOverride: {
                chart: {
                  type: 'pie'
                },
                plotOptions: {
                  pie: {
                    dataLabels: {
                      enabled: false
                    }
                  }
                },
                tooltip: {
                  enabled: false
                },
                subtitle: {
                  widthAdjust: -40,
                  text: 'Share of Total Sales'
                },
                disablePointSelect: true,
                enableMultiSelect: false
              },
              customHeader: {
                enabled: true,
                title: 'Promotion Count'
              },
              container: {
                style: {
                  width: '500px',
                  height: '440px'
                }
              }
            },
            data: {
              indexName: 'promotions',
              chartMainField: mainMetricField,
              mainMetric: mainMetricField,
              secondaryMetric: mainMetricField,
              aggregationFields: [
                INDEX_FIELDS.getField(app.name, indexName, metricType, entity.type, dataConfig.retailerIdField.name)
              ],
              groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.retailerIdField.name, entity.type)
            }
          },
          // promotion trend
          buildTrendWidgetConfig(app, 'promotions', entity, 'weekId', ['dataPointCount'], weekIdField, {
            view: {
              chartPropsOverride: {
                title: {
                  text: ''
                },
                subtitle: {
                  text: '',
                  style: {
                    'border-bottom': 'none'
                  }
                },
                chart: {
                  height: 450,
                  marginTop: 100
                },
                yAxis: [
                  {
                    visible: false
                  }
                ]
              },
              container: { style: { width: '60%', display: 'inline-block', marginBottom: '0px' } }
            },
            data: {
              additionalQueryConditions: {
                rangeFilters: [
                  {
                    fieldName: 'retailSales',
                    minValue: 0.01
                  }
                ]
              }
            }
          })
        ],
        {
          view: {
            container: {
              style: {
                height: '550px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around'
              }
            }
          }
        }
      )
    );

    let groupByOptions = ['categoryId', 'subCategoryId', 'brandId', 'parentBrandId'];
    // Change options depending on which view we are on
    const view = _get(window.location.pathname.split('/'), ['1']);
    if (view) {
      switch (view) {
        case 'company':
          // all options
          break;
        case 'product':
          // all options? unsure
          break;
        case 'category':
          groupByOptions = ['brandId', 'subCategoryId', 'parentBrandId'];
          break;
        case 'subcategory':
          groupByOptions = ['brandId', 'parentBrandId'];
          break;
        case 'brand':
          groupByOptions = ['categoryId', 'subCategoryId'];
          break;
        case 'segment':
          // all options
          break;
        default:
          // all options
          break;
      }
    }

    const promotionFields = ['dataPointCount', 'retailSales', 'promoSalesPercentage', 'retailPriceChangePercent'];
    const retailSalesMetricFields = promotionFields.map((fieldName) => {
      const field = INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type);
      return {
        ...field,
        displayName: field.displayName
      };
    });
    widgets.push(
      buildMultiGroupByTopEntitiesChartWidgetConfig(
        app,
        indexName,
        entity,
        groupByOptions.map((groupByFieldName) => ({
          groupByFieldName,
          indexName,
          aggregationFieldNames: ['dataPointCount']
        })),
        50,
        retailSalesMetricFields,
        weekIdField,
        {
          view: {
            enableComparison: true,
            chartPropsOverride: {
              enableSwitchingGroupBy: false,
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            },
            anchorName: 'multiGroupByTopEntitiesChart'
          },
          data: {
            hideEmptyCategories: true,
            sortByAggregationField: {
              aggregateByFieldDisplayName: 'Promotion Sales',
              aggregateByFieldName: 'retailSales',
              function: 'sum',
              canBeExported: true
            },
            additionalQueryConditions: {
              rangeFilters: [
                {
                  fieldName: 'retailSales',
                  minValue: 0.01
                }
              ]
            }
          }
        }
      )
    );

    const dealTypeFields = ['dataPointCount', 'retailSales', 'retailPriceChangePercent'];
    const dealTypeMetricFields = dealTypeFields.map((fieldName) => {
      const field = INDEX_FIELDS.getField(app.name, indexName, fieldName, entity.type);
      return {
        ...field,
        displayName: field.displayName
      };
    });
    widgets.push(
      buildMultiGroupByTopEntitiesChartWidgetConfig(
        app,
        indexName,
        entity,
        [
          {
            groupByFieldName: 'promoType',
            indexName,
            aggregationFieldNames: ['dataPointCount']
          }
        ],
        20,
        dealTypeMetricFields,
        weekIdField,
        {
          view: {
            enableComparison: true,
            chartPropsOverride: { hideGroupBy: false },
            anchorName: 'multiGroupByTopEntitiesChart'
          },
          data: {
            hideEmptyCategories: true,
            sortByAggregationField: {
              aggregateByFieldDisplayName: 'Promotion Sales',
              aggregateByFieldName: 'retailSales',
              function: 'sum',
              canBeExported: true
            },
            additionalQueryConditions: {
              rangeFilters: [
                {
                  fieldName: 'retailSales',
                  minValue: 0.01
                }
              ]
            }
          }
        }
      )
    );

    // grid for all
    widgets.push({
      name: 'promotionsGrid',
      view: VIEWS.promotionsGrid,
      CustomComponent: PromotionsGridContainer,
      data: {
        entity: ENTITIES.atlas.product,
        mainMetricField: INDEX_FIELDS.getField(
          app.name,
          indexName,
          metricType,
          entity.type,
          dataConfig.weekIdField.name
        )
      }
    });
  }

  if (metricType === 'purchaseRate') {
    widgets.push(
      buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['purchaseRate'], weekIdField, {
        view: {
          chartPropsOverride: {
            title: { text: 'Conversion Rate' }
          }
        }
      })
    );

    const { category, subcategory, brand, parentBrand } = getGroupByMapping(metricType);
    const aggregationFieldMapping = {
      company: [brand, category, subcategory, parentBrand],
      category: [brand, subcategory, parentBrand],
      subcategory: [brand, parentBrand],
      brand: [category, subcategory],
      segment: [brand, category, subcategory, parentBrand]
    };
    const noOfEntities = 50;
    const allowHorizontalScroll = {
      horizontalScrolling: {
        enabled: true,
        step: 3
      },
      xAxis: [
        {
          min: 0,
          max: 9
        }
      ]
    };

    if (['company', 'brand', 'category', 'subcategory', 'segment'].includes(entity.type)) {
      widgets.push(
        buildTopEntitiesWidgetConfig(
          app,
          indexName,
          entity,
          aggregationFieldMapping[entity.type],
          noOfEntities,
          weekIdField,
          {
            view: {
              enableComparison: true,
              chartPropsOverride: {
                ...allowHorizontalScroll,
                enableSwitchingGroupBy: true
              }
            }
          }
        )
      );
    }

    widgets.push(buildConversionRateGridWidget({ app, entity }));
  } else if (metricType === 'contentScore') {
    const mainMetricField = {
      ...INDEX_FIELDS.getField(app.name, 'sales', metricType, entity.type, dataConfig.weekIdField.name),
      conditions: conditionsToFilterZeroContentScore
    };

    widgets.push(
      // Content score donut chart
      buildWidgetGroup(
        [
          {
            CustomComponent: EnhancedDonutChartContainer,
            view: {
              ...VIEWS.donutChart,
              name: `ContentScoreDonutChart`,
              chartPropsOverride: {
                chart: {
                  type: 'pie'
                },
                plotOptions: {
                  pie: {
                    dataLabels: {
                      enabled: false
                    }
                  }
                },
                tooltip: {
                  enabled: false
                },
                disablePointSelect: true,
                enableMultiSelect: false
              },
              container: {
                style: {
                  width: '500px',
                  height: '440px'
                }
              }
            },
            data: {
              indexName: 'sales',
              chartMainField: mainMetricField,
              mainMetric: mainMetricField,
              secondaryMetric: mainMetricField,
              useLatestWeek: true,
              aggregationFields: [mainMetricField],
              groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.weekIdField.name, entity.type)
            }
          },
          // Content score column chart
          buildTopEntitiesWidgetConfig(
            app,
            'sales',
            entity,
            [
              {
                groupByFieldName: 'weekId',
                aggregationFieldNames: ['titleScore', 'bulletScore', 'imageScore', 'videoScore', 'aplusScore']
              }
            ],
            50,
            weekIdField,
            {
              CustomComponent: AtlasContentScoreColumnChart,
              view: {
                chartPropsOverride: {
                  chart: {
                    height: 450,
                    marginTop: 50
                  },
                  subtitle: { style: { 'border-bottom': 'none' } },
                  exporting: {
                    enabled: true
                  },
                  dataLabels: { x: 15 }
                },
                container: {
                  style: {
                    height: '450px',
                    flex: '1 1 50%',
                    width: '50%'
                  }
                }
              },
              data: {
                mainMetric: { ...mainMetricField },
                indexName: 'sales',
                groupByField: INDEX_FIELDS.getField(app.name, 'sales', dataConfig.retailerIdField.name, entity.type)
              }
            }
          )
        ],
        {
          view: {
            container: {
              style: {
                display: 'flex',
                justifyContent: 'space-around',
                flex: 1
              }
            }
          }
        }
      )
    );

    const contentScoreTrendChart = buildTrendWidgetConfig(
      app,
      'sales',
      entity,
      'weekId',
      ['contentScore'],
      weekIdField,
      {
        view: {
          chartPropsOverride: {
            title: { text: 'Content Score' },
            chart: {
              height: 650,
              marginTop: 100
            },
            legend: {
              enabled: true
            }
          },
          container: {}
        }
      }
    );
    contentScoreTrendChart.data.configByGroupByFieldName.weekId.aggregationFields[0] = {
      ...contentScoreTrendChart.data.configByGroupByFieldName.weekId.aggregationFields[0],
      conditions: conditionsToFilterZeroContentScore
    };
    widgets.push(contentScoreTrendChart);

    const contentScoreField = {
      ...INDEX_FIELDS.getField(app.name, 'sales', 'contentScore', 'product', 'stacklineSku'),
      conditions: conditionsToFilterZeroContentScore
    };
    const aggregationFields = [
      contentScoreField,
      INDEX_FIELDS.getField(app.name, 'sales', 'titleScore', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, 'sales', 'bulletScore', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, 'sales', 'imageScore', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, 'sales', 'videoScore', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, 'sales', 'aplusScore', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', 'product', 'stacklineSku')
    ];
    const gridAggregationFields = aggregationFields.map((field) => ({
      ...field,
      cellRendererFramework: AtlasProductCellFormatter,
      cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
    }));

    // Showing entire time period content score, not only last week;
    const clonedContentScoreField = _cloneDeep(contentScoreField);
    clonedContentScoreField.aggregationFunctionType = '';

    // Content score entity grid
    widgets.push({
      name: 'entityGrid',
      CustomComponent: EntityGrid,
      view: _merge({}, VIEWS.entityGrid, { gridOptions: {} }),
      data: {
        defaultSortField: INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', 'product', 'stacklineSku'),
        groupByFields: [INDEX_FIELDS.getField(app.name, 'sales', 'stacklineSku', 'product')],
        configByGroupByFieldName: {
          stacklineSku: {
            indexName: 'sales',
            entity: ENTITIES.atlas.product,
            mainMetricField: clonedContentScoreField,
            aggregationFields,
            tableView: {
              metricFields: gridAggregationFields
            }
          }
        }
      }
    });
  }

  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison: true,
    widgets,
    dataConfig
  };
}
