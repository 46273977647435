import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PaperClip from '!svg-react-loader!./paperClip.svg'; // eslint-disable-line
import Cycle from '!svg-react-loader!./cycle.svg'; // eslint-disable-line
import Check from '!svg-react-loader!./check.svg'; // eslint-disable-line
import File from '!svg-react-loader!./file.svg'; // eslint-disable-line
import anime from 'animejs';

import colors from 'src/utils/colors';
import './UploadAnimation.scss';

const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  /**
   * This will be called one time after the component mounts.  Its argument will be an object containing callbacks
   * that can be used to manipulate the animation.
   */
  registerCallbacks: PropTypes.func.isRequired,
  style: PropTypes.object
};

const defaultProps = {
  className: 'sl-header__logo',
  onClick: () => {},
  style: undefined
};

const enlarge = () => {
  anime
    .timeline()
    .add(
      {
        targets: 'svg.main_paperclip, svg.second_paperclip, svg.white_paperclip ',
        scale: [
          {
            value: [1, 1.2],
            duration: 330,
            easing: 'easeOutCubic'
          }
        ]
      },
      0
    )
    .add(
      {
        targets: 'svg.main_paperclip path',
        stroke: [
          {
            value: [colors.darkGrey, colors.darkBlue],
            duration: 330,
            easing: 'easeOutCubic'
          }
        ]
      },
      0
    )
    .add(
      {
        targets: 'svg.upload_file_background',
        scale: [
          {
            value: [1, 1.2],
            duration: 330,
            easing: 'easeOutCubic'
          }
        ]
      },
      0
    );
};

const shrink = () => {
  anime
    .timeline()
    .add(
      {
        targets: 'svg.main_paperclip, svg.second_paperclip, svg.white_paperclip',
        scale: [
          {
            value: [1.2, 1],
            duration: 330,
            easing: 'easeOutCubic'
          }
        ]
      },
      0
    )
    .add(
      {
        targets: 'svg.main_paperclip path ',
        stroke: [
          {
            value: [colors.darkBlue, colors.darkGrey],
            duration: 330,
            easing: 'easeOutCubic'
          }
        ]
      },
      0
    )
    .add(
      {
        targets: 'svg.upload_file_background',
        scale: [
          {
            value: [1.2, 1],
            duration: 330,
            easing: 'easeOutCubic'
          }
        ]
      },
      0
    );
};

const upload = (callBack) => {
  anime
    .timeline({
      complete: callBack
    })
    .add(
      {
        targets: 'svg.main_paperclip path',
        stroke: [
          {
            value: [colors.darkGrey],
            duration: 0
          }
        ],
        strokeDashoffset: [0, anime.setDashoffset],
        easing: 'easeInOutCubic',
        duration: 1500
      },
      0
    )
    .add(
      {
        targets: 'svg.main_paperclip, svg.second_paperclip',
        scale: [
          {
            value: [1.2, 1],
            duration: 700,
            easing: 'easeOutQuart'
          }
        ]
      },
      0
    )
    .add(
      {
        targets: '.paperclip_group',
        translateY: {
          value: ['22px'],
          duration: 700,
          easing: 'easeOutQuart'
        }
      },
      0
    )
    .add(
      {
        targets: 'svg.second_paperclip path',
        strokeDashoffset: [0, anime.setDashoffset],
        easing: 'easeInQuad',
        duration: 700
      },
      2500
    )
    .add(
      {
        targets: 'svg.second_paperclip',
        rotate: '-120deg',
        duration: 700,
        easing: 'easeInQuart'
      },
      2500
    )
    .add(
      {
        targets: 'svg.paperclip_cycle path.cycle',
        opacity: 1,
        strokeDashoffset: {
          value: [anime.setDashoffset, 0],
          duration: 700,
          easing: 'linear'
        }
      },
      2750
    )
    .add(
      {
        targets: 'svg.paperclip_cycle',
        rotateY: '180deg',
        rotate: {
          value: ['-30deg', '300deg'],
          duration: 1350,
          easing: 'linear'
        }
      },
      2050
    )
    .add(
      {
        targets: 'svg.paperclip_check path',
        opacity: 1,
        strokeDashoffset: {
          value: [anime.setDashoffset, 0],
          duration: 700,
          easing: 'linear'
        }
      },
      3150
    )
    .add(
      {
        targets: '.file_background',
        opacity: 0,
        translateY: '-50px',
        duration: 3000
      },
      0
    )
    .add(
      {
        targets: '.paperclip_progress',
        value: ['In Progress 0 %', 'In Progress 100 %'],
        round: 1,
        opacity: 1,
        // color: { value: ['#dcdfe4', '#489eec'], easing: 'linear' },
        easing: 'easeOutQuint',
        duration: 2400
      },
      0
    );
};

function UploadAnimation({ className, registerCallbacks, style }) {
  useEffect(() => {
    registerCallbacks({ enlarge, shrink, upload });
  }, [registerCallbacks]);

  return (
    <div className={className} style={style}>
      <div className="upload_animation_group">
        <div className="paperclip_group">
          <PaperClip className="white_paperclip" />
          <PaperClip className="second_paperclip" />
          <PaperClip className="main_paperclip" />
          <Cycle className="paperclip_cycle" />
          <Check className="paperclip_check" />
        </div>
        <input className="paperclip_progress" readOnly />
        <div className="file_background">
          <File className="upload_file_background" />
        </div>
      </div>
    </div>
  );
}

UploadAnimation.propTypes = propTypes;
UploadAnimation.defaultProps = defaultProps;

export default UploadAnimation;
