import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { AdMediaManagement } from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdMediaManagement';

export const AD_MEDIA_MANAGEMENT_URL = 'adMediaManagement';

export const getLayoutForEntity = () => {
  return {
    containerStyle: { flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' },
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    dataConfig: {},
    widgets: [
      {
        CustomComponent: AdMediaManagement,
        view: {
          container: {
            style: {
              width: '100%',
              display: 'flex',
              alignItems: 'start'
            }
          }
        }
      }
    ]
  };
};
