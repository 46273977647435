/**
 * A version of `TrendChart` that allows the displayed N trendchart in One
 */
import React from 'react';

import { propEq } from 'src/utils/fp';
import { TrendChartInner, enhanceTrendChart } from './TrendChart';
import NInOneTabs from './NInOneTabs';

class NInOneTrendChart extends TrendChartInner {
  getSelectedMetricField = () => {
    const { metricFields } = this.props.widget.view;
    return metricFields.find(propEq('name', this.state.selectedMetricFieldName)) || metricFields[0];
  };

  handleNInOneTabs = (tab) => {
    this.setState({ selectedMetricFieldName: tab.name });
  };

  getAggregationFields(groupByField) {
    return super.getAggregationFields.call(this, groupByField);
  }

  getMetricFields() {
    return [this.getSelectedMetricField()];
  }

  getChartParams() {
    const currentField = this.getSelectedMetricField();
    const chartParams = super.getChartParams.call(this, currentField, {
      mainMetricConfig: {
        indexName: currentField.indexName,
        metricField: currentField,
        type: this.props.widget.data.configByGroupByFieldName[this.getGroupByFields()[0].name].entity.type || 'metric'
      }
    });
    if (!chartParams) {
      return chartParams;
    }
    const { chartProps, chartSeries } = chartParams;
    return { chartProps, chartSeries };
  }

  /**
   * Returns the entity for which to construct conditions to execute the query.  Defaults to the main entity, but can be
   * overridden in extending classes.
   */
  getQueryEntity(props = this.props) {
    return props.widget.data.queryEntity;
  }

  renderTrendChart() {
    const { metricFields } = this.props.widget.view;
    const allParams = metricFields.map((field) => ({
      name: field.name,
      displayName: field.displayName,
      isSelected: field.name === this.state.selectedMetricFieldName,
      ...super.getChartParams.call(this, field, {
        mainMetricConfig: {
          indexName: field.indexName,
          metricField: field,
          type: this.props.widget.data.configByGroupByFieldName[this.getGroupByFields()[0].name].entity.type || 'metric'
        }
      })
    }));

    return (
      <>
        <NInOneTabs handleNInOneTabs={this.handleNInOneTabs} opts={allParams || []} />
        <div style={{ padding: '0 0 1px 1px' }}>{super.renderTrendChart.call(this)}</div>
      </>
    );
  }
}

const EnhancedMultiMetricTrendChart = enhanceTrendChart(NInOneTrendChart);

export default EnhancedMultiMetricTrendChart;
