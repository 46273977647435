import ReduxStore from 'src/types/store/reduxStore';

interface CreateNonComparisonLegendArgs {
  /**
   * mainTimePeriod from Redux. Only the displayName property is needed.
   */
  chartDisplayTimePeriod: Pick<ReduxStore['mainTimePeriod'], 'displayName'>;
  /**
   * Main label for the legend div.
   */
  mainLegendMetricValue: { value: string | number; prefix?: string; suffix?: string };
  /**
   * If false, will not display `metricsDataByTimeSeries`
   */
  isSameMetricComparison: boolean;
  /**
   * Additional information that will be appended after the time period.
   * Will not be used and can be null if `isSameMetricComparison` is false.
   */
  metricsDataByTimeSeries: {
    displayName: string;
  } | null;
  /**
   * An array of legend divs that will be rendered in the chart
   * legend. The new legend div will be appended to the end of this array.
   */
  legendDivs: string[];
}

/**
 * Creates a legend label for a chart with the main time
 * period displayed as a subtitle.
 */
export const createNonComparisonLegendDivs = ({
  chartDisplayTimePeriod,
  mainLegendMetricValue,
  isSameMetricComparison,
  metricsDataByTimeSeries,
  legendDivs
}: CreateNonComparisonLegendArgs) => {
  let mainLegendDiv = `<div>${mainLegendMetricValue.prefix || ''}${mainLegendMetricValue.value}${
    mainLegendMetricValue.suffix || ''
  }</div>
  <div class="legend__primary-date" style="margin-top:5px;">${chartDisplayTimePeriod.displayName}</div>`;
  if (!isSameMetricComparison) {
    mainLegendDiv += `<br/><div class="legend__primary-date" style="margin-top:5px;">${metricsDataByTimeSeries.displayName}</div>`;
  }
  legendDivs.push(mainLegendDiv);
};
