import { Parser } from 'expr-eval';

import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

export const SHORTAGES_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  // Fields are commented on a chart-by-chart basis

  /* Shortages Summary Breakdown [Donut Chart] */
  notYetDisputedShortageAmount: {
    name: 'notYetDisputedShortageAmount',
    displayName: 'Not Yet Disputed',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.INTEGER,
    aggregationFunction: 'sum'
  },

  // Not yet disputes shortage amount for V2
  notYetDisputedShortageAmountV2: {
    name: 'notYetDisputedShortageAmountV2',
    displayName: 'Not Yet Disputed',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.INTEGER,
    aggregationFunction: 'sum'
  },

  disputeUnderReviewShortageAmount: {
    name: 'disputeUnderReviewShortageAmount',
    displayName: 'Disputes Under Review',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.INTEGER,
    aggregationFunction: 'sum'
  },

  disputeUnderReviewShortageAmountV2: {
    name: 'disputeUnderReviewShortageAmountV2',
    displayName: 'Disputes Under Review',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.INTEGER,
    aggregationFunction: 'sum'
  },

  recoveredShortageAmount: {
    name: 'recoveredShortageAmount',
    displayName: 'Recovered Shortages',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.INTEGER,
    aggregationFunction: 'sum'
  },

  recoveredShortageAmountV2: {
    name: 'recoveredShortageAmountV2',
    displayName: 'Recovered Shortages',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.INTEGER,
    aggregationFunction: 'sum'
  },

  lostShortageAmount: {
    name: 'lostShortageAmount',
    displayName: 'Lost Shortages',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.INTEGER,
    aggregationFunction: 'sum'
  },

  lostShortageAmountV2: {
    name: 'lostShortageAmountV2',
    displayName: 'Lost Shortages',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.INTEGER,
    aggregationFunction: 'sum'
  },

  totalShortageAmount: {
    name: 'totalShortageAmount',
    displayName: 'Total',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  // -----------------------------------------

  /* Shortages versus Disputes Won [Trend Chart] */
  currentTotalShortageAmount: {
    name: 'currentTotalShortageAmount',
    displayName: 'Shortages',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },

  updatedShortageAmountBeforeDispute: {
    name: 'updatedShortageAmountBeforeDispute',
    displayName: 'Shortages',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },

  // -----------------------------------------

  // Metric Time Period Comparison [Trend Chart]
  // This 'amount' field refers to Invoice amount at this time

  invoiceAmount: {
    name: 'invoiceAmount',
    displayName: 'Invoices',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },

  initialShortageAmount: {
    name: 'initialShortageAmount',
    displayName: 'Shortages',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },

  disputeNeedActionAmount: {
    name: 'disputeNeedActionAmount',
    displayName: 'Disputes Needing Action',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },

  disputeUnderReviewAmount: {
    name: 'disputeUnderReviewAmount',
    displayName: 'Disputes Under Review',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  disputeWonAmount: {
    name: 'disputeWonAmount',
    displayName: 'Disputes Won',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  disputeLostAmount: {
    name: 'disputeLostAmount',
    displayName: 'Disputes Lost',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  disputeSubmittedAmount: {
    name: 'disputeSubmittedAmount',
    displayName: 'Disputes Submitted',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },

  shortageRatio: {
    name: 'shortageRatio',
    displayName: 'Shortage Ratio',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('invoiceAmount>0?updatedShortageAmountBeforeDispute/invoiceAmount:0')
  },

  skusWithShortages: {
    name: 'stacklineSku',
    displayName: 'SKUs with Shortages',
    displayNamePlural: 'SKUs with Shortages',
    entity: {
      type: 'product'
    },
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.INTEGER,
    aggregationFunction: 'cardinality',
    timePeriodAggregationFunctionType: 'lastValue',
    timePeriodAggregationFunction: 'sum'
  },

  // -----------------------------------------

  /* Top Entities Chart (default Shortages by Product) [Bar Chart] */
  totalShortages: {
    name: 'updatedShortageAmountBeforeDispute',
    displayName: 'Shortages',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  }
};
