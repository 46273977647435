import { COMMON_FIELDS } from 'src/utils/entityDefinitions/fields/commonFieldDefinitions';
import { BEACON_FIELDS } from 'src/utils/entityDefinitions/fields/beaconSalesFieldDefinitions';
import {
  CONTENT_ACCURACY_FIELDS,
  CONTENT_SCORE_FIELDS
} from 'src/utils/entityDefinitions/fields/contentScoreFieldDefinitions';
import {
  COMPLIANCE_CATALOGPRICE_FIELDS,
  COMPLIANCE_KEYWORDADITEM_FIELDS,
  COMPLIANCE_KEYWORDAD_FIELDS,
  COMPLIANCE_SELLER_FIELDS
} from 'src/utils/entityDefinitions/fields/complianceFieldDefinitions';
import { TRAFFIC_FIELDS_FOR_BEACON } from 'src/utils/entityDefinitions/fields/beaconTrafficFieldDefinitions';
import { BUYBOX_FIELDS } from 'src/utils/entityDefinitions/fields/buyboxFieldDefinitions';
import { SALES_FIELDS } from 'src/utils/entityDefinitions/fields/salesFieldDefintions';

import {
  ADVERTISING_DISPLAY_METRICS_FIELDS,
  ADVERTISING_DISPLAY_METADATA_FIELDS,
  ADVERTISING_METADATA_FIELDS,
  ADVERTISING_METRICS_FIELDS
} from 'src/utils/entityDefinitions/fields/beaconAdvertisingFieldDefinitions';

import {
  CHARGEBACK_FIELDS,
  CHARGEBACK_METADATA_FIELDS
} from 'src/utils/entityDefinitions/fields/operationsFieldDefinitions';

import { REVIEWS_FIELDS } from 'src/utils/entityDefinitions/fields/reviewsFieldDefinitions';
import { PRODUCT_WEEKLY_METRICS_FIELDS } from 'src/utils/entityDefinitions/fields/productWeeklyFieldDefinitions';

import { SHORTAGES_FIELDS } from 'src/utils/entityDefinitions/fields/beaconShortagesFieldDefinitions';

import {
  UNADJUSTED_YOY_UNITS_CHANGE_FIELDS,
  ADJUSTED_YOY_UNITS_CHANGE_FIELDS
} from 'src/utils/entityDefinitions/fields/beaconForecastYoyUnitsChange';

import { NEW_CONTENT_METRICS } from 'src/utils/entityDefinitions/fields/beaconNewContentMetricsFieldDefinitions';

import { BEACON_AD_PRODUCT_METRIC_FIELDS } from 'src/utils/entityDefinitions/fields/beaconAdProductMetrics';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';
import { BEACONPRO_PROMOTION_FIELDS } from 'src/utils/entityDefinitions/fields/beaconProPromotionFieldDefinitions';

export const BEACON_INDEX_FIELDS = {
  sales: {
    ...COMMON_FIELDS,
    ...BEACON_FIELDS,
    ...SALES_FIELDS
  },
  salesbyplatform: {
    ...COMMON_FIELDS,
    ...BEACON_FIELDS
  },
  traffic: {
    ...COMMON_FIELDS,
    ...TRAFFIC_FIELDS_FOR_BEACON
  },
  'traffic-all': {
    ...COMMON_FIELDS,
    ...TRAFFIC_FIELDS_FOR_BEACON
  },
  advertising: {
    ...COMMON_FIELDS,
    ...ADVERTISING_METADATA_FIELDS,
    ...ADVERTISING_METRICS_FIELDS
  },
  advertisingDisplay: {
    ...COMMON_FIELDS,
    ...ADVERTISING_DISPLAY_METRICS_FIELDS,
    ...ADVERTISING_DISPLAY_METADATA_FIELDS
  },
  chargebacks: {
    ...COMMON_FIELDS,
    ...CHARGEBACK_FIELDS,
    ...CHARGEBACK_METADATA_FIELDS
  },
  reviews: {
    ...COMMON_FIELDS,
    ...REVIEWS_FIELDS
  },
  content: {
    ...COMMON_FIELDS,
    ...CONTENT_SCORE_FIELDS,
    ...CONTENT_ACCURACY_FIELDS
  },
  /**
   * @deprecated We should be using the new-content-metrics index from now on
   */
  contentChanged: {
    ...COMMON_FIELDS,
    ...CONTENT_ACCURACY_FIELDS
  },
  buybox: {
    ...COMMON_FIELDS,
    ...BUYBOX_FIELDS
  },
  'compliance-keywordad': {
    ...COMMON_FIELDS,
    ...COMPLIANCE_KEYWORDAD_FIELDS
  },
  'compliance-keywordaditem': {
    ...COMMON_FIELDS,
    ...COMPLIANCE_KEYWORDADITEM_FIELDS
  },
  'compliance-selleritem': {
    ...COMMON_FIELDS,
    ...COMPLIANCE_SELLER_FIELDS
  },
  'compliance-catalogitem': {
    ...COMMON_FIELDS,
    ...COMPLIANCE_CATALOGPRICE_FIELDS
  },
  conversion: {
    ...COMMON_FIELDS
  },
  operations: {
    ...COMMON_FIELDS
  },
  productWeeklyMetrics: {
    ...COMMON_FIELDS,
    ...BEACON_FIELDS,
    ...CONTENT_SCORE_FIELDS,
    ...PRODUCT_WEEKLY_METRICS_FIELDS
  },
  'shortage-dispute': {
    ...COMMON_FIELDS,
    ...SHORTAGES_FIELDS
  },

  'sd-invoiceMetrics': {
    ...COMMON_FIELDS,
    ...SHORTAGES_FIELDS
  },

  'sd-asinDetailMetrics': {
    ...COMMON_FIELDS,
    ...SHORTAGES_FIELDS
  },
  'unadjusted-forecast': {
    ...COMMON_FIELDS,
    ...BEACON_FIELDS
  },
  'actual-yoy-units-change': {
    ...COMMON_FIELDS,
    ...UNADJUSTED_YOY_UNITS_CHANGE_FIELDS
  },
  'unadjusted-yoy-units-change': {
    ...COMMON_FIELDS,
    ...UNADJUSTED_YOY_UNITS_CHANGE_FIELDS
  },
  'adjusted-yoy-units-change': {
    ...COMMON_FIELDS,
    ...ADJUSTED_YOY_UNITS_CHANGE_FIELDS
  },
  'ratings-reviews-metrics': {
    ...COMMON_FIELDS,
    ...REVIEWS_FIELDS
  },
  'new-content-metrics': {
    ...COMMON_FIELDS,
    ...NEW_CONTENT_METRICS
  },
  'aggregated-traffic': {
    ...COMMON_FIELDS,
    ...TRAFFIC_FIELDS_FOR_BEACON
  },
  'ad-product-metrics': {
    ...COMMON_FIELDS,
    ...BEACON_AD_PRODUCT_METRIC_FIELDS
  },
  'ad-entity-campaign-product-metrics': {
    ...COMMON_FIELDS,
    ...BEACON_AD_PRODUCT_METRIC_FIELDS
  },
  [BEACON_INDEX_NAMES.PROMOTIONS]: {
    ...COMMON_FIELDS,
    ...BEACONPRO_PROMOTION_FIELDS
  }
};
