import queryString from 'qs';
import { FormData, prefillFormData } from 'src/utils/segments';
import _get from 'lodash/get';
import { useAppSelector } from 'src/utils/Hooks';
import { Location } from 'history';
import { useMemo } from 'react';
import useFormData from './useFormData';
import _cloneDeep from 'lodash/cloneDeep';
import { useDeepCompareMemo } from 'src/utils/app';
import { QUERY_STRING_ARRAY_LIMIT } from '../constants';

interface BuildFormDataAndQueryParamsArgs {
  location: Location;
  useInternalState: boolean;
}

interface BuildFormDataAndQueryParamsReturn {
  formDataToUse: FormData;
  queryParams: queryString.ParsedQs;
}

const useBuildFormDataAndQueryParams = ({
  location,
  useInternalState
}: BuildFormDataAndQueryParamsArgs): BuildFormDataAndQueryParamsReturn => {
  const retailerId = useAppSelector((state) => state.retailer.id);
  const appName = useAppSelector((state) => state.app.name);
  const formData = useFormData();
  const queryParams = useDeepCompareMemo(() => {
    const params = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: QUERY_STRING_ARRAY_LIMIT
    });
    delete params.adSummaryMetric; // clicking on different summary metric shouldn't cause table to re-render
    delete params.groupByField; // clicking on home screen should not cause re-render
    return params;
  }, [location.search]) as queryString.ParsedQs;

  return useMemo(() => {
    const isWalmart = retailerId === '2' || retailerId === '25';

    let formDataToUse: FormData;
    if (useInternalState) {
      formDataToUse = _cloneDeep(formData);
    } else {
      formDataToUse = prefillFormData({ queryParams, appName });
    }
    if (isWalmart) {
      const targetStatus = _get(formDataToUse, ['termFilters', 'targetState', 'values'], []);
      const campaignProductStatus = _get(formDataToUse, ['termFilters', 'campaignProductState', 'values'], []);
      if (targetStatus.find((item: any) => item.i === 'paused')) {
        formDataToUse.termFilters.targetState.values.push({ i: 'disabled', n: 'Disabled' });
      }
      if (campaignProductStatus.find((item: any) => item.i === 'paused')) {
        formDataToUse.termFilters.campaignProductState.values.push({ i: 'disabled', n: 'Disabled' });
      }
    }
    return { formDataToUse, queryParams };
  }, [appName, formData, retailerId, useInternalState, queryParams]);
};

export default useBuildFormDataAndQueryParams;
