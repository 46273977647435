import React from 'react';
import Select, { SelectProps } from '@mui/material/Select';
import { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material';
import ScheduledActionText from './ScheduledActionText';

export interface ScheduledActionSelectProps extends SelectProps {
  /**
   * When the select is disabled, it will show grayed out text
   * that should be defined with `disabledText`.
   */
  disabledText?: string;

  /**
   * Props to be forwarded to the Typography component that is
   * displayed when the dropdown is disabled.
   */
  disabledTextProps?: TypographyProps;
}

/**
 * Wrapper around a MUI Select that will show a Typography
 * component when the select is disabled.
 */
const ScheduledActionSelect: React.FC<ScheduledActionSelectProps> = (props) => {
  return props.disabled ? (
    <ScheduledActionText {...props.disabledTextProps}>{props.disabledText}</ScheduledActionText>
  ) : (
    <Select {...props} />
  );
};

ScheduledActionSelect.defaultProps = {
  disabledText: '',
  disabledTextProps: {}
};

const StyledScheduledActionSelect = styled(ScheduledActionSelect, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== 'classes'
})({
  margin: 0,
  fontSize: 16,
  '& .MuiSelect-select': {
    margin: '0px !important',
    minHeight: '1.2em !important',
    minWidth: '200px !important'
  }
});

export default StyledScheduledActionSelect;
