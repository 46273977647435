import React, { useState } from 'react';
import { withBus } from 'react-bus';
import { connect } from 'react-redux';

import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import BulkUploadDialog from 'src/components/Search/AdvancedSearch/BulkUpload/BulkUploadDialog';
import { prop } from 'src/utils/fp';
import { store } from 'src/main';
import { setUploadedFeaturedProducts } from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { EventBus } from 'src/types/utils';
import { CAMPAIGN_TYPES } from 'src/store/modules/adManager/adCampaignBuilder/types';
import ReduxStore from 'src/types/store/reduxStore';

const mapStateToProps = (state: ReduxStore) => ({
  campaignType: state.adCampaignBuilder.campaignType
});

const BulkRetailerSKUUploadButtonInner: React.FC<
  { eventBus: EventBus; style?: React.CSSProperties } & ReturnType<typeof mapStateToProps>
> = ({ eventBus, campaignType, style }) => {
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);

  const allowedCampaignTypes = [
    CAMPAIGN_TYPES.SponsoredBrands,
    CAMPAIGN_TYPES.SponsoredProducts,
    CAMPAIGN_TYPES.SponsoredDisplay
  ];

  if (!campaignType || !allowedCampaignTypes.includes(campaignType.id)) {
    return null;
  }

  return (
    <>
      <LargeMuiButton
        secondary
        label="Bulk Upload"
        onClick={() => setBulkUploadOpen(true)}
        style={{ marginTop: 25, ...(style || {}) }}
      />
      {bulkUploadOpen ? (
        <BulkUploadDialog
          hideRadioButtons
          uploadType="segment"
          open={bulkUploadOpen}
          onClose={() => setBulkUploadOpen(false)}
          handleTermFilters={({ values: keywordItems }: { values: { i: string }[] }) => {
            const retailerSkus = keywordItems.map(prop('i'));
            store.dispatch(setUploadedFeaturedProducts(retailerSkus));
            setBulkUploadOpen(false);

            // This event is handled in `FeaturedProductsGrid`, where it will kick off a new chain of requests to fetch
            // featured products and metrics for all featured products including bulk uploaded SKUs set above.
            eventBus.emit('fetchFeaturedProductsData', true);
          }}
        />
      ) : null}
    </>
  );
};

export default connect(mapStateToProps)(withBus('eventBus')(BulkRetailerSKUUploadButtonInner));
