import AdBudgetConstraints from 'src/components/AdManager/AdBudgetConstraints';
import ReduxStore from 'src/types/store/reduxStore';
import { PageLayout } from 'src/types/application/widgetTypes';
import colors from 'src/utils/colors';
import { EventBus } from 'src/types/utils';
import AdManagerEntityHeader from 'src/components/AdManager/AdManagerEntityHeader';
import {
  buildAdvancedSearchSideBarWidget,
  buildTabbedSearchResultGridWidget,
  reachColums,
  spendColums,
  salesColums,
  sortOrder
} from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';
import { buildWidgetGroup } from 'src/components/Layout/LayoutUtil';
import EntityColumn from 'src/components/AdManager/Search/CustomColumns/EntityColumn';
import CampaignPortfolioColumn from 'src/components/AdManager/Search/CustomColumns/CampaignPortfolioColumn';
import BudgetColumn from 'src/components/AdManager/Search/CustomColumns/BudgetColumn';
import DataColumn from 'src/components/AdManager/Search/CustomColumns/DataColumn';
import SelectEntityColumn from 'src/components/AdManager/Search/CustomColumns/SelectEntityColumn';
import EntityStatusColumn from 'src/components/AdManager/Search/CustomColumns/EntityStatusColumn';
import OutOfBudgetHoursColumn from 'src/components/AdManager/Search/CustomColumns/OutOfBudgetHours';
import { getUserColumns } from 'src/routes/UserAccount/UserCustomColumn';
import { store } from 'src/main';
import _get from 'lodash/get';

const baseColDef = {
  disableSort: true,
  width: 100
};

export const getLayout = ({
  app,
  entity,
  eventBus
}: {
  app: ReduxStore['app'];
  entity: { type: string };
  eventBus: EventBus;
}): Partial<PageLayout> => {
  const HeaderWidget = {
    CustomComponent: AdManagerEntityHeader,
    name: 'adManagerHeader',
    view: {
      name: 'adManagerHeader',
      title: 'Ad Score',
      container: {
        style: { borderBottom: `1px solid ${colors.lightestGrey}`, marginBottom: 50 }
      }
    },
    data: {
      adScoreName: 'adScore'
    }
  };
  const { user } = store.getState();
  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);

  const userSettingColumns = [...reachColums, ...spendColums, ...salesColums].sort((a, b) => {
    const aIdx = sortOrder.indexOf(a.field);
    const bIdx = sortOrder.indexOf(b.field);
    return aIdx - bIdx;
  });

  const fields = getUserColumns();

  const metricColDef = userSettingColumns
    .filter((column) => fields.includes(column.field))
    .map((item) => {
      return {
        headerName: item.displayName,
        disableSort: false,
        cellRendererFramework: DataColumn,
        field: item.field,
        minWidth: 100,
        maxWidth: 200
      };
    });

  const customColumnDefs = [
    ...(isAdAuditUser
      ? []
      : [
          {
            headerName: 'Campaign',
            field: 'campaignId',
            width: undefined,
            enableRtl: true,
            cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
            minWidth: 65,
            maxWidth: 65,
            cellRendererFramework: SelectEntityColumn,
            headerComponentFramework: SelectEntityColumn,
            pinned: 'left'
          }
        ]),
    {
      headerName: 'Campaign',
      field: 'campaignId',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 250,
      maxWidth: 800,
      cellRendererFramework: EntityColumn,
      headerClass: 'align-left',
      pinnedRowCellRenderer: 'simpleColumn',
      pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
      pinned: 'left'
    },
    {
      headerName: 'Portfolio',
      field: 'portfolioId',
      width: undefined,
      enableRtl: false,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important' },
      minWidth: 200,
      maxWidth: 200,
      cellRendererFramework: CampaignPortfolioColumn,
      headerClass: 'align-left',
      isOptional: true,
      pinned: 'left'
    },
    {
      headerName: 'Budget',
      field: 'projectedSpend',
      width: undefined,
      enableRtl: false,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important', 'padding-right': '20px' },
      minWidth: 150,
      maxWidth: 150,
      cellRendererFramework: BudgetColumn,
      headerClass: 'align-left',
      isOptional: true,
      pinned: 'left'
    },
    {
      headerName: 'OOB Hour',
      field: 'campaignId',
      width: undefined,
      enableRtl: false,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important' },
      minWidth: 110,
      maxWidth: 110,
      cellRendererFramework: OutOfBudgetHoursColumn,
      headerClass: 'align-left',
      isOptional: true,
      pinned: 'left'
    },
    ...metricColDef,
    ...(isAdAuditUser
      ? []
      : [
          {
            headerName: 'Status',
            field: 'campaignId',
            width: undefined,
            enableRtl: true,
            cellStyle: { 'justify-content': 'flex-end', 'text-align': 'left' },
            minWidth: 65,
            maxWidth: 100,
            cellRendererFramework: EntityStatusColumn,
            pinned: 'right'
          }
        ])
  ].map(({ headerName, ...colDef }) => ({ ...baseColDef, ...colDef, headerName }));

  const advancedSearchSideBarWidget = buildAdvancedSearchSideBarWidget();
  advancedSearchSideBarWidget.view.hideByDefault = true;
  advancedSearchSideBarWidget.view.container.style = {
    ...advancedSearchSideBarWidget.view.container.style,
    paddingTop: 0
  };
  advancedSearchSideBarWidget.data.defaultGroupByField = 'stacklineSku';
  advancedSearchSideBarWidget.data.useInternalState = true;

  const tabbedSearchResultGridWidget = buildTabbedSearchResultGridWidget({
    app,
    entity: { type: 'budgetConstraints' },
    eventBus,
    searchResultsGridWidgetDataOverride: {
      useInternalState: true,
      customColumnDefs
    },
    headerWidgetDataOverride: {
      hideHeader: true
    }
  });

  const searchResultGridWidgetGroupWidget = buildWidgetGroup(
    [advancedSearchSideBarWidget, tabbedSearchResultGridWidget],
    {
      view: {
        container: {
          style: {
            height: '550px',
            width: '100%',
            display: 'flex'
          }
        }
      }
    }
  );

  const groupByFieldNameByEntityType = {
    client: 'retailerId',
    adEntity: 'entityId',
    adPortfolio: 'portfolioId',
    adCampaign: 'campaignId'
  };
  return {
    widgets: [
      HeaderWidget,
      {
        name: 'adManagerInfoPage',
        CustomComponent: AdBudgetConstraints,
        view: {
          noInnerContainer: true,
          name: 'adManagerInfoPage',
          gridOptions: {
            pageSize: 50
          }
        },
        data: {
          groupByFieldName: groupByFieldNameByEntityType[entity.type],
          statePropertyName: 'budget_constraints_info',
          gridWidget: searchResultGridWidgetGroupWidget
        }
      }
    ]
  };
};
