import {
  Entity,
  Segment,
  AdEntityEntity,
  AdPortfolioEntity,
  BusinessUnit,
  SavedSearch,
  CategoryEntity,
  AdManagerAdCampaignEntity,
  SubCategoryEntity,
  Conditions,
  ElasticSearchAdCampaignEntity,
  AppName
} from 'sl-api-connector/types';
import {
  IAdBusinessUnit,
  IAdPlatform,
  IAdPlatformSettings,
  IAdEntityAdPlatformSettingsByClient,
  IAdPortfolioAdPlatformSettingsByClient,
  IAdPlatformSettingsByClient,
  AD_BUDGET_TYPE,
  AD_PLATFORM_TYPE
} from 'sl-ad-campaign-manager-data-model';

import { ESSDataKey } from 'src/types/application/types';
import { AvailableRetailer, TimePeriod, AdCampaignBuilderState } from 'src/types/store/storeTypes';
import parseBrandsFollowed from 'src/store/modules/brands/selectors';
import { AdScores } from 'src/store/modules/adManager/adScores/types';
import { AdCampaignType } from 'src/store/modules/adManager/adCampaignTypes/types';
import { AdCampaignState } from 'src/store/modules/adManager/adCampaignStates/types';
import { AdTargetMatchType } from 'src/store/modules/adManager/adTargetMatchTypes/types';
import { AdTargetState } from 'src/store/modules/adManager/adTargetStates/types';
import { AdCampaignProductState } from 'src/store/modules/adManager/adCampaignProductStates/types';
import { AdPortfolioState } from 'src/store/modules/adManager/adPortfolioStates/types';
import { OmniSegmentListState } from 'src/store/modules/omni/omniSegment/reducers';
import { OmniKeywordListState } from 'src/store/modules/omni/omniKeywordList/reducers';
import { OmniStoreListState } from 'src/store/modules/omni/OmniStoreList/reducers';
import { OmniAccountSettingState } from 'src/store/modules/omni/omniAccountSetting/reducers';
import { OmniRetailersState, OmniInstacartRetailersState } from 'src/store/modules/omni/omniRetailers/reducers';
import { OmniKeywordCategoriesState } from 'src/store/modules/omni/omniKeywordCategories/reducers';
import { OmniRetailerContentConfigureServiceState } from 'src/store/modules/omni/omniRetailerContentScoreConfigure/reducers';
import {
  OmniBrand,
  OmniCategory,
  OmniCountry,
  OmniRegion,
  OmniRetailerInFilter,
  OmniSubCategory,
  OmniInstacartRetailer
} from 'src/store/modules/omni/omniDynamicFilter/types';
import { ParentPlatforms, SingleParentPlatform } from 'src/store/modules/parentPlatform/platformUtils';

/**
 * Special extended format of budgets used internally in budget editor component and friends
 */

export enum RetailerOrderType {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
  SHIPPING = 'shipping'
}

export interface BudgetCampaign extends AdManagerAdCampaignEntity {
  adjustedValue: number | null;
}

export type BudgetPortfolio = IAdPortfolioAdPlatformSettingsByClient & {
  campaigns: BudgetCampaign[];
  unMatchCampaigns?: BudgetCampaign[];
  isUnmatch?: boolean;
  adjustedValue?: number | null;
  isUnassigned?: boolean;
  isAutomated: any;
  recommendedBudget?: number;
  extendedAttributes: {
    currentMonthBudgetSetting: { amount?: number | null; budgetType: AD_BUDGET_TYPE };
    nextMonthBudgetSetting: { amount?: number | null; budgetType: AD_BUDGET_TYPE };
  };
};

/**
 * Special extended format of ad entity ad platform setting used in the budget editor component and friends
 */
export type BudgetEntity = IAdEntityAdPlatformSettingsByClient & {
  portfolios: BudgetPortfolio[];
  flattened?: boolean;
  extendedAttributes: {
    currentMonthBudgetSetting: { amount: number | null };
    nextMonthBudgetSetting: { amount: number | null };
  };
  entity: {
    id: string;
  };
};

export interface SubscriptionsByRetailers {
  primeSubscriptions: string;
}

/**
 * The type of the full Redux store.  This is the first argument of all `mapStateToProps` functions provided to
 * `connect` used throughout the application.
 *
 * You can access the Redux store's state during development by calling `getState()` in the JS console of your browser.
 */
export default interface ReduxStore {
  app: {
    name: AppName;
    apiAppName: AppName;
    dataFrequency: 'daily' | 'weekly';
    displayName: string;
    defaultQueryParams: {
      [key: string]: any;
    };
    queryParams: {
      searchParams?: string;
      [key: string]: any;
    };
    shouldUseBrandNameNormalized: boolean;
    shouldUseGTIN: boolean;
    shouldHaveRetailerDropDown: boolean;
    shouldUseOmniCommonSummaryInfo: boolean;
    shouldUseStacklineSkU: boolean;
    shouldHaveCurrencyDropdown: boolean;
    shouldSupportJumpingToRetailerProdPage: boolean;
    shouldUseOmniAuthenticatedRouter: boolean;
    shouldUseWrappedRetailerSku: boolean;
    shouldUseOmniExport: boolean;
    comparisonDefault: string;
    targetUrl: string;
    stage: string;
    supportsAppSwitching: boolean;
    config: any;
  };
  entityService: {
    mainEntity?: Entity & {
      hashId?: string;
      beaconClientId?: number;
      stacklineSku?: string;
      companyName?: string;
      shortDisplayName?: string;
      categoryIds?: number[];
      name?: string;
      brandId?: string;
    };
    comparisonEntity?: Entity;
  };
  entitySearchService: { [dataKey: string]: ESSDataKey };

  retailer: {
    id: string;
    name: string;
    displayName: string;
    type: string;
    currencySymbol: string;
    locale: string;
    reviewAverage: {
      min: number;
      max: number;
    };
    contentScore: {
      aggregationNames: string[];
      categoryNames: string[];
    };
    dataTypesAvailable: string[];
    availableRetailers: AvailableRetailer[];
    supportedAppNames: string[];
    // adding additional optional properties for easy accessibility
    // set from response of GET /adPlatforms
    processInTimezone?: string;
    orderType?: RetailerOrderType[];
    platformType?: AD_PLATFORM_TYPE;
    parentPlatform?: string;
  };
  parentPlatform: {
    availablePlatforms: ParentPlatforms;
    current: SingleParentPlatform;
  };
  swappableGrid: {
    activeMetrics?: string[];
    inactiveMetrics?: string[];
  };
  mainTimePeriod: TimePeriod & {
    availableMainTimePeriods: TimePeriod[];
    displayName: string;
    startWeek: number;
    endWeek: number;
    startWeekDate: Date;
    endWeekDate: Date;
    shortDisplayName: string;
    id: string;
  };
  comparisonTimePeriod: TimePeriod & { availableComparisonTimePeriods: { id: string; shortDisplayName: string }[] };
  adCampaigns: AdManagerAdCampaignEntity[];
  adGroups: any[];
  adPortfolios: AdPortfolioEntity[];
  adEntities: AdEntityEntity[];
  adPlatforms: IAdPlatform[];
  adScores: AdScores;
  adCampaignStates: AdCampaignState[];
  adPortfolioStates: AdPortfolioState[];
  adCampaignTypes: AdCampaignType[];
  adAutomationStrategies: any[];
  adTargetMatchTypes: AdTargetMatchType[];
  adTargetStates: AdTargetState[];
  adCampaignProductStates: AdCampaignProductState[];
  allWeekIdsByRetailerId: { [retailerId: number]: number[] };
  user: {
    config: {
      BrandsOwned: string;
      appSubscriptions: AppName[];
      modulesSubscribed: string[];
      allRetailerIds: number[];
      /**
       * List of secondary retailer IDs for pages within apps
       * that don't use main list of retailers. For example,
       * AMC dashboard
       */
      secondaryRetailerIds?: number[];
      secondaryRetailersLoading?: boolean;
      allWeekIds: number[];
      allWeekIdsByRetailerId: { [retailerId: number]: number[] };
      allPlatformIdsByRetailerId: null;
      vendor: {
        CompanyName: string;
        BeaconClientId: number;
        hashId: string;
      };
      reviews: {
        highRiskStars: string[];
        highRiskKeywords: string[];
      };
      subscriptionsByRetailers: { [retailerId: number]: SubscriptionsByRetailers };
      clientAccountSettings: {
        entityType: string;
        entityId: string;
        updatedAt: string;
        updateBy: string;
        value: unknown; // idk what this is
      };
      firstName: string;
      lastName: string;
      profile: {
        firstName: string;
        lastName: string;
        email: string;
        company: string;
        jobTitle: string | null;
        phoneNumber: string | null;
        photoId: string | null;
        role: string | null;
      };
      exportEnabled: boolean;
      isStacklineDeploymentSuperUser: boolean;
      isStacklineSuperUser: boolean;
      isStacklineUser: boolean;
      isRestrictedDemoUser: boolean;
      translationEnabled: Record<string, any>;
      adAuditEnabled: boolean;
    };
    session: {
      trackingId: string;
      sessionId: string;
      data: null;
      status: string;
      userMetaData: {
        firstName: string;
        lastName: string;
        email: string;
        companyName: string;
      };
      companyId: string;
      divisionId: string;
      hasValidBilling: boolean;
      statusMessage: string;
      isAdmin: boolean;
      adminEmail: string;
      email: string;
      clientIP: string;
      userId: string;
    };
    // Only used in Beacon, will be null for other services.
    adCampaigns?: ElasticSearchAdCampaignEntity[];
  };
  filters: {
    category: null | CategoryEntity[];
    segment: null | any[];
    platform: null | any[];
    retailer: null | number[] | string[];
    instacartRetailer: null | string[];
    subcategory:
      | null
      // this is a subcategory entity with some additional derived props
      | {
          categoryIds: number[];
          categoryType: 'subCategory';
          conditions: Conditions;
          displayName: string;
          id: number;
          name: string;
          parentCategoryId: number;
          retailerIds: number[];
          subCategoryId: number;
          subCategoryName: string;
          type: 'subcategory';
        }[];
    retailPrice: null | any[];
    // The IDs of all selected/activated business units
    businessUnits: null | string[];
    isClearingFilters: boolean;
  };
  segments: {
    mySegments: Segment[];
    teamSegments: Segment[];
    mySearchTermLists: Segment[];
    teamSearchTermLists: [];
    businessUnits: BusinessUnit[];
    savedSearchesById: null | Map<string, SavedSearch>;
  };
  navSidebar: { isVisible: boolean };
  categories: CategoryEntity[];
  subCategories: SubCategoryEntity[];
  scratch: { [key: string]: any };
  adCampaignBuilder: AdCampaignBuilderState;
  adPlatformSettingsByClient: IAdPlatformSettingsByClient[];
  adBusinessUnits: IAdBusinessUnit[];
  adPlatformSettings: IAdPlatformSettings[];
  brandsFollowing: ReturnType<typeof parseBrandsFollowed>[];
  categoriesByRetailerId: { [key: number]: CategoryEntity[] };
  allSuperUserCategories: { [key: number]: CategoryEntity[] };
  allSuperUserSubCategories: { [key: number]: CategoryEntity[] };
  genericForm: {
    [formName: string]: {
      formState: { [key: string]: any };
      isSubmitted: boolean;
    };
  };
  auth: any;
  platforms: {
    pantryIds: number[];
    platformEntities: { id: number; isChecked: boolean; label: string }[];
    showPlatformFilter: boolean;
  };
  promoTypes: { name: string; displayName: string; id: string }[];
  chargeBackIssueTypes: { name: string; displayName: string; id: string }[];
  vendorCodes: { name: string; displayName: string; id: string }[];
  chargeBackStatuses: { name: string; displayName: string; id: string }[];
  adBudgetEditor: {
    platform: IAdPlatformSettings;
    entity?: BudgetEntity;
    timeline: {
      id: number;
      label: string;
      displayName: string;
      disc: string;
      name: 'currentMonthBudgetSetting' | 'nextMonthBudgetSetting';
    };
    amount: number | null;
    mtdAdSpend?: number | null;
    campaignArray: AdManagerCampaign[];
    portfolioArray: AdManagerPortfolio[];
  };
  //  Omni
  omniBrandsFollowing: OmniBrand[];
  omniDynamicFilters: {
    isFetching: boolean;
    data: {
      brands: OmniBrand[];
      categories: OmniCategory[];
      subCategories: OmniSubCategory[];
      retailers: OmniRetailerInFilter[];
      countries: OmniCountry[];
      regions: OmniRegion[];
      instacartRetailers: OmniInstacartRetailer[];
    };
  };
  omniRegionsFollowing: OmniRegion[];
  omniCountriesFollowing: OmniCountry[];
  omniCategories: OmniCategory[];
  omniSubcategories: OmniSubCategory[];
  omniRetailers: OmniRetailersState;
  omniInstacartRetailers: OmniInstacartRetailersState;
  omniRetailerContentConfigureService: OmniRetailerContentConfigureServiceState;
  omniKeywordCategoriesService: OmniKeywordCategoriesState;
  omniEntity: {
    weekIds: number[];
    beaconClientId: number;
    brandIds: string[];
    companyName: string;
    id: string;
    name: string;
    retailerIds: number[];
    shortDisplayName: string;
    type: string;
    websiteUrl: string;
  };
  omniChartsService: {
    [chartName: string]: {
      compare: any[];
      main: any[];
      isFetching: boolean;
    };
  };
  omniAccountSettingService: OmniAccountSettingState;
  omniSegmentService: OmniSegmentListState;
  omniKeywordSegmentService: OmniKeywordListState;
  omniStoreSegmentService: OmniStoreListState;
  omniProductService: {
    [chartName: string]: {
      compare: any[];
      main: any[];
      isFetching: boolean;
    };
  };
  omniGeoMapService: {
    [mapName: string]: {
      data: any[];
      isFetching: boolean;
    };
  };
  omniSearchService: {
    [name: string]: {
      main: any[];
      compare: any[];
      isFetching: boolean;
    };
  };
  omniShareOfShelfShardsService: {
    [name: string]: {
      data: any[];
      isFetching: boolean;
    };
  };
}

/**
 * NOTE This is not a complete type and should be
 * updated as necessary.
 */
export interface AdManagerCampaign {
  id: string;
  entity: {
    campaignName: string;
    extendedAttributes: {
      portfolioId: string;
      portfolioIdApi: string;
      entityId: string;
      newBudgetSetting?: {
        amount: number;
      };
    };
  };
  spend?: any;
  sales?: any;
  clicks?: any;
  costPerClick?: any;
  returnOnAdSpend?: any;
}

/**
 * NOTE This is not a complete type and should be
 * updated as necessary.
 */
export interface AdManagerPortfolio {
  id: string;
  isUnassigned?: boolean;
  spend?: any;
  sales?: any;
  entity: {
    name: string;
    id: string;
    extendedAttributes: {
      entityId: string;
      isSelected?: boolean;
      newBudgetSetting?: {
        amount: number;
      };
    };
  };
}
