import types from './types';
import actions from './actions';
import { ValueOf } from 'sl-api-connector/types';

const vendorCodes = (state = [], action: ReturnType<ValueOf<typeof actions>> | any) => {
  switch (action.type) {
    case types.RECEIVE_ALL_VENDOR_CODES:
      return Object.assign([], action.vendorCodes);
    case types.CLEAR_ALL_VENDOR_CODES:
      return [];
    default:
      return state;
  }
};

export default vendorCodes;
