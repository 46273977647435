import React, { useState } from 'react';
import { connect } from 'react-redux';
import OutlinedInput from '@mui/material/OutlinedInput';
import withStyles from '@mui/styles/withStyles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AppName } from 'sl-api-connector/types';
import _pick from 'lodash/pick';
import _get from 'lodash/get';

import colors from 'src/utils/colors';
import { ChevronIcon } from 'src/components/SvgIcons';
import { filterNils, propEq } from 'src/utils/fp';
import ReduxStore from 'src/types/store/reduxStore';
import { error } from 'src/utils/mixpanel';
import './Logo.scss';
import Tooltip from '@mui/material/Tooltip';
import { getHomeMapping } from 'src/components/Navigation/utils';

const styles = {
  select: {
    '& span': {
      paddingLeft: 0,
      fontSize: 18,
      fontWeight: 600
    }
  },
  tooltip: {
    width: 315,
    textAlign: 'center',
    position: 'relative'
  },
  tooltipPlacementRightStart: {
    right: -10
  },
  tooltipPlacementRightEnd: {
    top: 50,
    left: -45
  }
};

const buildAppUrl = (subdomain: string, route: string, stage: string) => {
  let stageToUse = stage;
  if (window.location.href.includes('-localhost.stackline.com')) {
    stageToUse = 'localhost';
  }

  return `https://${subdomain}${stageToUse !== 'prod' ? `-${stageToUse}` : ''}.stackline.com${route}`;
};

const buildDiscoverAppUrl = (stage: string, subdomain: string, user: ReduxStore['user'], retailerId: string) => {
  const { isStacklineSuperUser } = user.config;
  return isStacklineSuperUser
    ? `https://${subdomain}${stage !== 'prod' ? `-dev` : ''}.stackline.com/discover?rid=${retailerId}&discoverFilter=${[
        'noSelection1',
        'category'
      ].join(',')}`
    : `https://${subdomain}${stage !== 'prod' ? `-dev` : ''}.stackline.com/discover?rid=${retailerId}&discoverFilter=${[
        'brand',
        'category'
      ].join(',')}`;
};

const buildHeaderOpts = ({
  stage,
  hidePulse,
  hideDiscover,
  ...reduxValues
}: {
  stage: string;
  hidePulse: boolean;
  hideDiscover: boolean;
} & Pick<
  ReduxStore,
  'user' | 'app' | 'retailer' | 'mainTimePeriod' | 'comparisonTimePeriod' | 'allWeekIdsByRetailerId'
>) => {
  const homeMapping = getHomeMapping('', reduxValues);
  return filterNils([
    {
      name: AppName.Atlas,
      color: colors.atlasGreen,
      displayName: 'Atlas',
      url: buildAppUrl('atlas', homeMapping.atlas, stage)
    },
    {
      name: AppName.Beacon,
      color: colors.beaconPurple,
      displayName: 'Beacon',
      url: buildAppUrl('beacon', homeMapping.beacon, stage)
    },
    {
      name: 'drive',
      color: colors.darkBlue,
      displayName: 'Drive',
      url: buildAppUrl('ad', homeMapping.advertising, stage)
    },
    hidePulse
      ? null
      : {
          name: 'pulse',
          color: colors.darkBlue,
          displayName: 'Pulse',
          url: buildAppUrl('ad', homeMapping.advertising, stage)
        },
    {
      name: AppName.Omni,
      color: colors.darkBlue,
      displayName: 'Omni',
      url: buildAppUrl('omni', homeMapping.omni, stage)
    },
    hideDiscover
      ? null
      : {
          name: AppName.Discover,
          color: colors.darkBlue,
          displayName: 'Discover',
          url: buildDiscoverAppUrl(stage, 'discover', reduxValues.user, reduxValues.retailer.id)
        }
  ]);
};
const openURL = (url: string) => window.open(url, '_blank');

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['app', 'user', 'retailer', 'mainTimePeriod', 'comparisonTimePeriod', 'allWeekIdsByRetailerId']);

const Logo: React.FC<{ classes: { [key: string]: any } } & ReturnType<typeof mapStateToProps>> = ({
  app,
  user,
  classes,
  retailer,
  mainTimePeriod,
  comparisonTimePeriod,
  allWeekIdsByRetailerId
}) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const appName = app.name;

  const driveSubscribed = _get(user, 'config.appSubscriptions', []).includes(AppName.Advertising);
  const pulseSubscribed = _get(user, 'config.adAuditEnabled', false);
  const discoverSubscribed = _get(user, 'config.appSubscriptions', []).includes(AppName.Discover);

  const headerOpts = buildHeaderOpts({
    retailer,
    user,
    mainTimePeriod,
    comparisonTimePeriod,
    app,
    allWeekIdsByRetailerId,
    stage: app.stage,
    hidePulse: driveSubscribed && !pulseSubscribed,
    hideDiscover: !discoverSubscribed
  });
  const product = headerOpts.find((item) => {
    if (appName === AppName.Advertising) {
      if (driveSubscribed && !pulseSubscribed) {
        return item.name === 'drive';
      }
      if (pulseSubscribed) {
        return item.name === 'pulse';
      }
    }
    return item.name === appName;
  });
  if (!product) {
    error('Unable to find entry for current product when creating logo; rendering nothing');
    return null;
  }

  // show tooltip on header (selected menuitem) for ad audit user using ad audit
  // show tooltip on menuitem (expanded select) for ad audit user using other product
  const adTooltipMessage = 'Drive is the next evolution of Ad Manager';
  const adAuditTooltipMessage =
    "You are using the read-only version of Stackline's full read/write ad tech solution, Drive.";

  const isSubscribed = (name: string) => {
    if (name === 'drive') {
      return driveSubscribed && !pulseSubscribed;
    } else if (name === 'pulse') {
      return pulseSubscribed;
    }
    return user.config.appSubscriptions.includes(name as unknown as AppName);
  };

  const handleProductChange = ({
    target: { value }
  }: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>) => {
    if (value === appName) {
      return;
    }

    const { url } = headerOpts.find(propEq('name', value))!;
    openURL(url);
  };

  const handleSelectOpen = () => setSelectOpen(true);

  const handleSelectClose = () => setSelectOpen(false);

  const getTooltipTitleForProductName = (productName: string): string => {
    if (!['Drive', 'Pulse'].includes(productName)) {
      return '';
    }
    if (pulseSubscribed && !selectOpen) {
      return adAuditTooltipMessage;
    }
    if (driveSubscribed && !selectOpen) {
      return adTooltipMessage;
    }
    return '';
  };

  return (
    <Select
      variant="standard"
      open={selectOpen}
      value={product.name}
      onChange={handleProductChange}
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        }
      }}
      classes={{ select: classes.select }}
      input={<OutlinedInput labelWidth={0} id="outlined-age-simple" />}
      IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
      onOpen={handleSelectOpen}
      onClose={handleSelectClose}
    >
      {headerOpts.map((val) => (
        <MenuItem key={val.name} value={val.name} disabled={!isSubscribed(val.name as AppName)}>
          <Tooltip
            title={getTooltipTitleForProductName(val.displayName)}
            placement={driveSubscribed || pulseSubscribed ? 'right-end' : 'right-start'}
            classes={{
              tooltip: classes.tooltip,
              tooltipPlacementRight:
                driveSubscribed || pulseSubscribed
                  ? classes.tooltipPlacementRightEnd
                  : classes.tooltipPlacementRightStart
            }}
          >
            <span>{val.displayName}</span>
          </Tooltip>
        </MenuItem>
      ))}
    </Select>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(Logo));
