import React, { useState } from 'react';
import './discoverFilter.scss';
import {
  formatDecimalNumber,
  numeralFormattingForNumbersWithoutNeg,
  numeralFormattingForNumbersWithoutNegWithDollar,
  formatDecimalNumberWithoutNeg,
  formatIntegerWithoutNeg
} from 'src/utils/stringFormatting';
import Filter from 'src/components/Discover/Filter';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import numeral from 'numeral';
import { localeParseFloat } from 'src/utils/metrics';

const PERCENT_MIN = 0;
const PERCENT_MAX = 100;
const BPS_MAX = 10000;
const BPS_MIN = -10000;
const AVG_MAX = 5;
interface Range {
  rangeBegin: number;
  rangeEnd: number;
}
interface RetailSalesStateProp {
  retailSalesRange: Range;
  popRetailSalesChangePct: Range;
  avgRating: Range;
  marketShare: Range;
  marketShareChange: Range;
  unitsSold: Range;
  popUnitsSoldChangePct: Range;
}

interface DiscoverFilterProps {
  setFilter: RetailSalesStateProp;
  sendFilterContent: string[];
  filterTitles: string;
  setIsDisabled: (value: boolean) => void;
  helperText: any;
  setHelperText: (value: any) => void;
}

const formatValueMapping = {
  'Retail Sales': numeralFormattingForNumbersWithoutNegWithDollar,
  'Sales Change': formatDecimalNumber,
  'Market Share': formatDecimalNumberWithoutNeg,
  'Share Change': formatIntegerWithoutNeg,
  'Units Sold': numeralFormattingForNumbersWithoutNeg,
  'Unit Change': formatDecimalNumber,
  'Traffic Share': formatDecimalNumberWithoutNeg,
  Conversion: formatDecimalNumberWithoutNeg,
  'Average Rating': formatDecimalNumberWithoutNeg
};

const filterTagsToBackendMapping = () => {
  return {
    'Retail Sales': 'retailSalesRange',
    'Sales Change': 'popRetailSalesChangePct',
    'Market Share': 'marketShare',
    'Share Change': 'marketShareChange',
    'Units Sold': 'unitsSold',
    'Unit Change': 'popUnitsSoldChangePct',
    'Traffic Share': 'trafficShare',
    Conversion: 'conversion',
    'Average Rating': 'avgRating'
  };
};

const mapping = {
  'Retail Sales': 'retailSalesRange',
  'Sales Change': 'popRetailSalesChangePct',
  'Market Share': 'marketShare',
  'Share Change': 'marketShareChange',
  'Units Sold': 'unitsSold',
  'Unit Change': 'popUnitsSoldChangePct',
  'Traffic Share': 'trafficShare',
  Conversion: 'conversion',
  'Average Rating': 'avgRating'
};

export type Props = DiscoverFilterProps;
const DiscoverFilter: React.FC<Props> = (props: DiscoverFilterProps) => {
  const [, setFilterObj] = useState(props.setFilter);
  const [filterTags] = useState(props.sendFilterContent);
  const { helperText, setHelperText } = props;

  const retailerObj = useSelector(({ retailer }: ReduxStore) => retailer);

  const onChangeApplyFilters = (event, filterTag, minOrMax) => {
    // instead of event.target.value.replaceAll('%', '').replaceAll(retailerObj.currencySymbol, '').replaceAll(',', '') we will use numeral to get locale separator.

    const { decimal: currentDecimal, thousands: currentThousand } = numeral.localeData().delimiters;
    const value = event.target.value
      .replaceAll('%', '')
      .replaceAll(retailerObj.currencySymbol, '')
      .replaceAll(currentThousand, '');

    const filterTagsMap = filterTagsToBackendMapping();
    const mapFilter = filterTagsMap[filterTag];
    const copyFilerObj = { ...props.setFilter };
    const rangeBegin = 'rangeBegin';
    const rangeEnd = 'rangeEnd';

    if (minOrMax === 'min') {
      setHelperText({
        ...helperText,
        [filterTag]: { rangeEndError: helperText[filterTag].rangeEndError, rangeBeginError: '' }
      });

      copyFilerObj[props.filterTitles][mapFilter][rangeBegin] =
        value[value.length - 1] === currentDecimal
          ? value
          : Number.isNaN(localeParseFloat(value, currentThousand, currentDecimal))
          ? value
          : value;
      if (Number.isNaN(localeParseFloat(value, currentThousand, currentDecimal)) && value !== '-') {
        copyFilerObj[props.filterTitles][mapFilter][rangeBegin] = '';
      }
    }

    if (minOrMax === 'max') {
      setHelperText({
        ...helperText,
        [filterTag]: { rangeBeginError: helperText[filterTag].rangeBeginError, rangeEndError: '' }
      });
      copyFilerObj[props.filterTitles][mapFilter][rangeEnd] =
        value[value.length - 1] === '.'
          ? value
          : Number.isNaN(localeParseFloat(value, currentThousand, currentDecimal))
          ? value
          : value;
      if (Number.isNaN(localeParseFloat(value, currentThousand, currentDecimal)) && value !== '-') {
        copyFilerObj[props.filterTitles][mapFilter][rangeEnd] = '';
      }
    }

    setFilterObj(copyFilerObj);
  };

  const getHelperText = (e, filterTag, minOrMax) => {
    const copyFilerObj = { ...props.setFilter };
    const filterTagsMap = filterTagsToBackendMapping();
    const mapFilter = filterTagsMap[filterTag];
    let minVal = copyFilerObj[props.filterTitles][mapFilter].rangeBegin;
    let maxVal = copyFilerObj[props.filterTitles][mapFilter].rangeEnd;
    minVal = Number.isNaN(parseFloat(minVal)) ? minVal : parseFloat(minVal);
    maxVal = Number.isNaN(parseFloat(maxVal)) ? maxVal : parseFloat(maxVal);
    if (
      [
        'Retail Sales',
        'Market Share',
        'Sales Change',
        'Units Sold',
        'Conversion',
        'Unit Change',
        'Traffic Share',
        'Share Change',
        'Average Rating'
      ].includes(filterTag)
    ) {
      if (filterTag === 'Market Share' || filterTag === 'Traffic Share') {
        maxVal = maxVal === '' ? 100 : maxVal;
      }
      if (filterTag === 'Market Share') {
        maxVal = maxVal === '' ? 10000 : maxVal;
      }
      if (filterTag === 'Average Rating') {
        maxVal = maxVal === '' ? 5 : maxVal;
      }
      if (filterTag === 'Share Change') {
        maxVal = maxVal === '' ? 10000 : maxVal;
        minVal = minVal === '' ? -10000 : minVal;
      }
      if (filterTag === 'Conversion') {
        maxVal = maxVal === '' ? 10000000000000000 : maxVal;
        minVal = minVal === '' ? 0 : minVal;
      }
      if (filterTag === 'Sales Change' || filterTag === 'Unit Change') {
        maxVal = maxVal === '' ? 10000000000000000 : maxVal;
        minVal = minVal === '' ? -10000000000000000 : minVal;
      } else {
        maxVal = maxVal === '' ? 10000000000000000 : maxVal;
      }
    }

    if (minOrMax === 'min') {
      if (minVal > maxVal) {
        helperText[filterTag].rangeBeginError = 'Min value must be less than max';
      }
      if (minVal < maxVal) {
        helperText[filterTag].rangeEndError = '';
      }
    }
    if (minOrMax === 'max') {
      if (minVal > maxVal) {
        helperText[filterTag].rangeEndError = 'Max value must be greater than min';
      }
      if (minVal < maxVal) {
        helperText[filterTag].rangeBeginError = '';
      }
    }

    if (['Market Share'].includes(filterTag)) {
      if (minOrMax === 'max') {
        if (maxVal > 100 && filterTag === 'Market Share') {
          helperText[filterTag].rangeEndError = `Max value must be less than ${PERCENT_MAX}`;
        }
        if (maxVal < 0) {
          helperText[filterTag].rangeEndError = `Max value must be greater than ${PERCENT_MIN}`;
        }
      }
      if (minOrMax === 'min') {
        if (minVal > 100) {
          helperText[filterTag].rangeBeginError = `Min value cannot be greater than ${PERCENT_MAX}`;
        }
        if (minVal < 0 && filterTag === 'Market Share') {
          helperText[filterTag].rangeBeginError = `Min value must be greater than ${PERCENT_MIN}`;
        }
      }
    }
    if (['Conversion'].includes(filterTag)) {
      if (minOrMax === 'max') {
        if (maxVal < 0) {
          helperText[filterTag].rangeEndError = `Max value must be greater than ${PERCENT_MIN}`;
        }
      }
      if (minOrMax === 'min') {
        if (minVal < 0 && filterTag === 'Conversion') {
          helperText[filterTag].rangeBeginError = `Min value must be greater than ${PERCENT_MIN}`;
        }
      }
    }
    if (['Average Rating'].includes(filterTag)) {
      if (minOrMax === 'min') {
        if (minVal > 5) {
          helperText[filterTag].rangeBeginError = `Min value must be less than ${AVG_MAX}`;
        }
        if (maxVal <= 5) {
          helperText[filterTag].rangeEndError = '';
        }
      }
      if (minOrMax === 'max') {
        if (maxVal > 5) {
          helperText[filterTag].rangeEndError = `Max value must be less than ${AVG_MAX}`;
        }
        if (minVal <= 5) {
          helperText[filterTag].rangeBeginError = '';
        }
      }
    }

    if (
      ['Retail Sales', 'Sales Change', 'Units Sold', 'Traffic Share', 'Conversion', 'Average Rating'].includes(
        filterTag
      )
    ) {
      if (minOrMax === 'min') {
        if (minVal < 0 && filterTag !== 'Sales Change') {
          helperText[filterTag].rangeBeginError = `Min value must be greater than ${PERCENT_MIN}`;
        }

        if (filterTag === 'Traffic Share') {
          if (minVal > 100) {
            helperText[filterTag].rangeBeginError = `Min value cannot be greater than ${PERCENT_MAX}`;
          }
        }
      }
      if (minOrMax === 'max') {
        if (
          maxVal > 100 &&
          filterTag !== 'Retail Sales' &&
          filterTag === 'Traffic Share' &&
          filterTag !== 'Sales Change' &&
          filterTag !== 'Units Sold'
        ) {
          helperText[filterTag].rangeEndError = `Max value must be less than ${PERCENT_MAX}`;
        }
      }
    }
    if (['Share Change'].includes(filterTag)) {
      if (minVal < -10000) {
        helperText[filterTag].rangeBeginError = `Min value cannot be less than ${BPS_MIN}`;
      }
      if (maxVal > 10000) {
        helperText[filterTag].rangeEndError = `Max value cannot be greater than ${BPS_MAX}`;
      }
    }
    Object.keys(helperText).every((key) => {
      const isValid = helperText[key].rangeBeginError === '' && helperText[key].rangeEndError === '';
      props.setIsDisabled(!isValid);
      if (!isValid) {
        return false;
      }
      return true;
    });
  };

  return (
    <div className="filterContainer">
      <h3 className="filterHeader">{props.filterTitles}</h3>
      <div className="filters">
        <div>
          <div className="filterLegend">
            <span className="headerLabel minLabel">Min</span>
            <span className="headerLabel maxLabel">Max</span>
          </div>
          <div>
            {filterTags
              ? filterTags.map((filterTag, index) => (
                  <>
                    {/* {filterTag === 'Sales Change' ? ( */}
                    <Filter
                      key={filterTag}
                      setFilter={props.setFilter}
                      filterTitles={props.filterTitles}
                      filterTag={filterTag}
                      index={index}
                      mapping={mapping}
                      helperText={helperText}
                      onChangeApplyFilters={onChangeApplyFilters}
                      filterTagsToBackendMapping={filterTagsToBackendMapping}
                      getHelperText={getHelperText}
                      setFilterObj={setFilterObj}
                      formatValueMapping={formatValueMapping}
                    />
                    {/* ) : null} */}
                  </>
                ))
              : null}
          </div>
        </div>
        <div className="errorContainer">
          {filterTags &&
            filterTags.map(
              (filterTag, i) =>
                !(
                  ['category', 'subcategory', 'department'].includes(props.filterTitles.toLowerCase()) &&
                  ['Market Share', 'Share Change', 'Average Rating'].includes(filterTag)
                ) && (
                  <div key={i} className="errorContainer-row">
                    <span className="errorContainer-column">
                      {helperText[filterTag].rangeBeginError
                        ? helperText[filterTag].rangeBeginError
                        : helperText[filterTag].rangeEndError}
                    </span>
                  </div>
                )
            )}
        </div>
      </div>
    </div>
  );
};

export default DiscoverFilter;
