/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { compose } from 'redux';

import _get from 'lodash/get';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'qs';
import { Entity } from 'sl-api-connector/types';

import { withLocation } from 'src/utils/hoc';
import { fetchAdOptimizationItemCountForEntity } from 'src/components/AdManager/AdOptimizationHistory';
import { fetchIneligibleCountForEntity } from 'src/components/AdManager/AdIneligibleInfo';
import { fetchBudgetPacingCountForEntity } from 'src/components/AdManager/AdBudgetPacing';
import { fetchBudgetConstraintsCountForEntity } from 'src/components/AdManager/AdBudgetConstraints';
import Badge from 'src/components/AdManager/Badge';
import ReduxStore from 'src/types/store/reduxStore';
import { WidgetProps, Widget } from 'src/types/application/widgetTypes';
import { filterNils } from 'src/utils/fp';
import Tabs from 'src/components/common/Tabs/Tabs';
import './AdManagerLeftNav.scss';
import { AD_CAMPAIGN_TYPE } from 'sl-ad-campaign-manager-data-model';
import colors from 'src/utils/colors';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';
import { shouldShowCriteo } from 'src/utils/app';

const OptimizationBadge: React.FC<{ mainEntity?: Entity; mainTimePeriod: ReduxStore['mainTimePeriod'] }> = ({
  mainEntity,
  mainTimePeriod
}) => {
  const [adOptimizationItemCount, setAdOptimizationItemCount] = useState<number | null>(null);
  useDeepCompareEffect(() => {
    if (!mainEntity) {
      return;
    }

    fetchAdOptimizationItemCountForEntity(mainEntity, mainTimePeriod).then(setAdOptimizationItemCount);
  }, [mainEntity, mainTimePeriod]);
  if (adOptimizationItemCount === 0) {
    return null;
  }
  return (
    <Badge
      text=""
      color={colors.blue}
      backgroundColor="#2a8ade40"
      amount={adOptimizationItemCount}
      onClick={() => {}}
    />
  );
};

const IneligibleBadge: React.FC<{ mainEntity?: Entity; mainTimePeriod: ReduxStore['mainTimePeriod'] }> = ({
  mainEntity,
  mainTimePeriod
}) => {
  const [ineligibleCount, setIneligibleCount] = useState<number | null>(null);

  const setCount = (res: any) => {
    setIneligibleCount(res.totalCount);
  };
  useDeepCompareEffect(() => {
    if (!mainEntity) {
      return;
    }

    fetchIneligibleCountForEntity(mainEntity).then(setCount);
  }, [mainEntity, mainTimePeriod]);
  if (ineligibleCount === 0) {
    return null;
  }
  return <Badge text="" color={colors.red} backgroundColor="#f3537940" amount={ineligibleCount} onClick={() => {}} />;
};

const BudgetPacingBadge: React.FC<{ mainEntity?: Entity }> = ({ mainEntity }) => {
  const [budgetPacingCount, setBudgetPacingCount] = useState<number | null>(null);
  useDeepCompareEffect(() => {
    if (!mainEntity) {
      return;
    }

    fetchBudgetPacingCountForEntity(mainEntity).then(setBudgetPacingCount);
  }, [mainEntity]);
  if (budgetPacingCount === 0) {
    return null;
  }
  return <Badge text="" color={colors.red} backgroundColor="#f3537940" amount={budgetPacingCount} onClick={() => {}} />;
};

const BudgetConstraintsBadge: React.FC<{ mainEntity?: Entity }> = ({ mainEntity }) => {
  const [budgetConstraintsCount, setBudgetConstraintsCount] = useState<number | null>(null);
  useDeepCompareEffect(() => {
    if (!mainEntity) {
      return;
    }

    fetchBudgetConstraintsCountForEntity(mainEntity).then(setBudgetConstraintsCount);
  }, [mainEntity]);
  if (budgetConstraintsCount === 0) {
    return null;
  }
  return (
    <Badge text="" color={colors.red} backgroundColor="#f3537940" amount={budgetConstraintsCount} onClick={() => {}} />
  );
};

const getNavItems = (entity: Entity, isAdAuditUser: boolean, rid: string, pp: string) => {
  const RETAILER_ID_WALMART = '2';
  const AUTO_TARGET = 'auto';
  const AD_CAMPAIGN = 'adCampaign';
  const { SPONSORED_PRODUCT, SPONSORED_DISPLAY } = AD_CAMPAIGN_TYPE;

  const retailerId = _get(entity, 'extendedAttributes.retailerId', null);
  const retailerIdString = retailerId && retailerId.toString();

  const isWalmartEntity = retailerIdString === RETAILER_ID_WALMART || retailerIdString === '25';
  const hideTargetTab = isWalmartEntity && _get(entity, 'extendedAttributes.amsApiModel.targetingType') === AUTO_TARGET;
  const isAutoTargetWalmartEntity = isWalmartEntity && _get(entity, 'targetingText') === AUTO_TARGET;
  const isSBAorVideoCampaign =
    _get(entity, 'type') === AD_CAMPAIGN &&
    isWalmartEntity &&
    ['sba', 'video'].includes(_get(entity, 'extendedAttributes.campaignType'));
  const showProductTab =
    (!isAdAuditUser &&
      ['adCampaign', 'adGroup'].includes(_get(entity, 'type')) &&
      [SPONSORED_PRODUCT, SPONSORED_DISPLAY].includes(_get(entity, 'extendedAttributes.campaignType'))) ||
    (isWalmartEntity && _get(entity, 'type') !== 'adTarget');
  const showScheduleTab = !isAdAuditUser && ['adCampaign', 'adPortfolio', 'adEntity'].includes(_get(entity, 'type'));

  if (shouldShowCriteo()) {
    const navConfig = [
      { condition: () => true, value: { text: 'Summary', subtab: 'keyMetrics' } },
      {
        condition: () => ['adCampaign', 'adGroup'].includes(entity.type) && !hideTargetTab,
        value: { text: 'Targets', subtab: 'targeting' },
        notAllowedParentPlatforms: [PARENT_PLATFORMS.CRITEO]
      },
      {
        condition: () =>
          (['adTarget'].includes(entity.type) && !isAutoTargetWalmartEntity) ||
          (retailerIdString === RETAILER_ID_WALMART && entity.type === 'product'),
        value: { text: isAdAuditUser ? 'Bid Comparison' : 'Update Bids', subtab: 'targetBids' },
        notAllowedParentPlatforms: [PARENT_PLATFORMS.CRITEO]
      },
      { condition: () => showProductTab, value: { text: 'Products', subtab: 'products' } },
      { condition: () => isSBAorVideoCampaign, value: { text: 'Reviews', subtab: 'reviews' } },
      {
        condition: () => entity.type === AD_CAMPAIGN,
        value: { text: 'Recommendations', subtab: 'recommendations' },
        notAllowedParentPlatforms: [PARENT_PLATFORMS.CRITEO]
      },
      {
        condition: () => ['adCampaign', 'adPortfolio'].includes(entity.type),
        value: { text: 'Settings', subtab: 'campaignDetails' },
        notAllowedParentPlatforms: [PARENT_PLATFORMS.CRITEO]
      },
      {
        condition: () => showScheduleTab,
        value: { text: 'Schedule', subtab: 'schedule' },
        notAllowedParentPlatforms: [PARENT_PLATFORMS.CRITEO]
      },
      {
        condition: () => ['client', 'adEntity'].includes(entity.type),
        value: { text: 'Budget Pacing', subtab: 'budgetPacing' },
        notAllowedParentPlatforms: [PARENT_PLATFORMS.CRITEO]
      },
      {
        condition: () => ['client', 'adEntity', 'adPortfolio'].includes(entity.type),
        value: { text: 'Budget Constraints', subtab: 'budgetConstraints', groupByField: 'campaignId' },
        notAllowedParentPlatforms: [PARENT_PLATFORMS.CRITEO]
      },
      {
        condition: () => ['adCampaign', 'adPortfolio', 'adEntity', 'client'].includes(entity.type),
        value: { text: 'Ineligible', subtab: 'ineligible', groupByField: 'stacklineSku' },
        notAllowedParentPlatforms: [PARENT_PLATFORMS.CRITEO, PARENT_PLATFORMS.SAMSCLUB]
      },
      {
        condition: () => !['product', 'adTarget', 'segment', 'brand'].includes(entity.type),
        value: { text: 'Optimizations', subtab: 'optimizations' },
        notAllowedParentPlatforms: [PARENT_PLATFORMS.CRITEO]
      }
    ];

    const res = filterNils(
      navConfig.map((config) => {
        if (rid && config.notAllowedParentPlatforms && config.notAllowedParentPlatforms.includes(pp)) {
          return null;
        }
        return config.condition() ? config.value : null;
      })
    );
    return res;
  } else {
    return filterNils([
      { text: 'Summary', subtab: 'keyMetrics' },
      ['adCampaign', 'adGroup'].includes(entity.type) && !hideTargetTab
        ? { text: 'Targets', subtab: 'targeting' }
        : null,
      (['adTarget'].includes(entity.type) && !isAutoTargetWalmartEntity) ||
      (_get(entity, 'retailerId', '') === 2 && entity.type === 'product')
        ? { text: isAdAuditUser ? 'Bid Comparison' : 'Update Bids', subtab: 'targetBids' }
        : null,
      showProductTab ? { text: 'Products', subtab: 'products' } : null,
      isSBAorVideoCampaign ? { text: 'Reviews', subtab: 'reviews' } : null,
      entity.type === 'adCampaign' ? { text: 'Recommendations', subtab: 'recommendations' } : null,
      ['adCampaign', 'adPortfolio'].includes(entity.type) ? { text: 'Settings', subtab: 'campaignDetails' } : null,
      showScheduleTab ? { text: 'Schedule', subtab: 'schedule' } : null,
      ['client', 'adEntity'].includes(entity.type) ? { text: 'Budget Pacing', subtab: 'budgetPacing' } : null,
      ['client', 'adEntity', 'adPortfolio'].includes(entity.type)
        ? { text: 'Budget Constraints', subtab: 'budgetConstraints', groupByField: 'campaignId' }
        : null,
      ['adCampaign', 'adPortfolio', 'adEntity', 'client'].includes(entity.type)
        ? {
            text: 'Ineligible',
            subtab: 'ineligible',
            groupByField: 'stacklineSku'
          }
        : null,
      !['product', 'adTarget', 'segment', 'brand'].includes(entity.type)
        ? {
            text: 'Optimizations',
            subtab: 'optimizations'
          }
        : null
    ]);
  }
};

const mapStateToProps = ({ entityService: { mainEntity }, mainTimePeriod, user, app }: ReduxStore) => ({
  app,
  user,
  mainEntity,
  mainTimePeriod
});

const NavItemTemplate: React.FC<{
  mainEntity: any;
  mainTimePeriod: ReduxStore['mainTimePeriod'];
  navItem: {
    text: string;
    subtab: string;
    groupByField?: string;
  };
  location: { search: string; pathname: string };
  currentSubtab: string;
  queryParams: any;
}> = ({ navItem, location, mainEntity, mainTimePeriod, currentSubtab, queryParams }) => {
  const { text, subtab, groupByField } = navItem;
  const isActive = subtab.toLowerCase() === (currentSubtab || 'keyMetrics').toLowerCase();
  const updatedQueryParams = { ...queryParams, tab: 'adManager', subtab };
  if (groupByField) {
    updatedQueryParams.groupByField = groupByField;
  }
  const to = `${location.pathname}?${queryString.stringify(updatedQueryParams)}`;

  return (
    <div key={to} className="nav-item">
      <Link className={isActive ? 'selected' : ''} to={to}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {text}

          {text === 'Optimizations' ? (
            <OptimizationBadge mainEntity={mainEntity} mainTimePeriod={mainTimePeriod} />
          ) : null}

          {text === 'Ineligible' ? <IneligibleBadge mainEntity={mainEntity} mainTimePeriod={mainTimePeriod} /> : null}

          {text === 'Budget Pacing' ? <BudgetPacingBadge mainEntity={mainEntity} /> : null}
          {text === 'Budget Constraints' ? <BudgetConstraintsBadge mainEntity={mainEntity} /> : null}
        </div>
      </Link>
    </div>
  );
};

const AdManagerLeftNav: React.FC<
  {
    location: { search: string; pathname: string };
    widget: Widget;
  } & ReturnType<typeof mapStateToProps>
> = ({ mainEntity, location, mainTimePeriod, user }) => {
  const [opts, setOpts] = useState([]);
  // TODO: Make this a helper function
  const { subtab: currentSubtab, ...queryParams } = queryString.parse(location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: 100
  });
  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);
  const navItems = mainEntity ? getNavItems(mainEntity, isAdAuditUser, queryParams.rid, queryParams.pp) : [];
  useDeepCompareEffect(() => {
    const customParams = _cloneDeep(queryParams);
    delete customParams.adSummaryMetric;
    const navOpts = navItems.map((navItem) => ({
      displayTemplate: (
        <NavItemTemplate
          navItem={navItem}
          location={location}
          mainEntity={mainEntity}
          mainTimePeriod={mainTimePeriod}
          currentSubtab={currentSubtab}
          queryParams={queryParams}
        />
      )
    }));
    setOpts(navOpts);
  }, [mainEntity, mainTimePeriod]);

  const activeIdx = navItems.findIndex(
    ({ subtab }) => subtab.toLowerCase() === (currentSubtab || 'keyMetrics').toLowerCase()
  );
  return (
    <div className="ad-manager-top-nav">
      <Tabs
        value={activeIdx}
        tabs={opts}
        tabStyle={{ padding: '0 0 0 0', fontSize: 18, minWidth: 40, marginRight: 40 }}
        onTabChange={() => {}}
      />
    </div>
  );
};

const EnhancedAdManagerLeftNav = compose(
  withLocation,
  connect(mapStateToProps)
)(AdManagerLeftNav) as React.ComponentType<WidgetProps>;

export default EnhancedAdManagerLeftNav;
