import { Box } from '@mui/system';
import _debounce from 'lodash/debounce';
import _isString from 'lodash/isString';
import queryString from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { BulkUploadButton, ClearFiltersButton, SaveButton } from 'src/components/Omni/OmniButton/OmniButton';
import { fillSegmentFieldInURL, generateQueryParamObj } from 'src/components/Omni/OmniSegmentUtil';
import OmniClearButton from 'src/components/Omni/Search/OmniClearButton';
import OmniKeywordSearchWrapper from 'src/components/Omni/Search/OmniKeywordSearchWrapper';
import OmniSegmentNameField from 'src/components/Omni/Search/OmniSegmentNameField';
import OmniSelectorWrapper from 'src/components/Omni/Search/OmniSelectorWrapper';
import OmniBulkUpload from 'src/components/Search/AdvancedSearch/BulkUpload/OmniBulkUpload';
import KeywordsSearch from 'src/components/Search/AdvancedSearch/KeywordsSearch';
import { fetchSegmentListData, updateTheOmniSegment } from 'src/store/modules/omni/omniSegment/operation';
import ReduxStore from 'src/types/store/reduxStore';
import { addPersistentQueryParams } from 'src/utils/browser';

const TextContent = () => (
  <p>
    Paste a list of Titles, Retailer SKUs, GTINs or a list of Zip Codes to create a segment view. You can upload a CSV
    file containing the information with one per line, no comma or other formatting.
    <br />
    <br />
    Please note: there is a max limit of 500 records per upload and please select Location when entering Zip Codes.
  </p>
);

const wrapperComponentList = [
  { type: 'country', CustomizeComponent: OmniSelectorWrapper },
  {
    type: 'retailer',
    CustomizeComponent: OmniSelectorWrapper
  },
  {
    type: 'brand',
    CustomizeComponent: OmniSelectorWrapper
  },
  {
    type: 'category',
    CustomizeComponent: OmniSelectorWrapper
  },
  {
    type: 'subCategory',
    CustomizeComponent: OmniSelectorWrapper
  },
  {
    type: 'state',
    CustomizeComponent: OmniKeywordSearchWrapper
  },
  // {
  //   type: 'CBSA',
  //   CustomizeComponent: OmniKeywordSearchWrapper
  // },
  // {
  //   type: 'county',
  //   CustomizeComponent: OmniKeywordSearchWrapper
  // },

  {
    type: 'city',
    CustomizeComponent: OmniKeywordSearchWrapper
  },
  {
    type: 'zipCode',
    CustomizeComponent: OmniKeywordSearchWrapper
  }
];

const OmniSearchSideBar: React.FC<RouteComponentProps> = ({ location, history }: RouteComponentProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();
  const app = useSelector((state: ReduxStore) => state.app);
  const { searchParams } = app.queryParams;
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const mainTimePeriod = useSelector((store: ReduxStore) => store.mainTimePeriod);
  const segmentListData = useSelector((state: ReduxStore) => state.omniSegmentService);
  const { data: allSegmentData } = segmentListData;
  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 10e8 });
  const parsedState = fillSegmentFieldInURL(queryParams, 'product');
  const { name } = parsedState;
  const [nameForSegment, setNameForSegment] = useState(name || '');

  const debounceNav = useRef(
    _debounce((queryId: string) => {
      history.push(`segment/${queryId}${searchParams}&tab=scorecard&subtab=all`);
    }, 700)
  ).current;

  useEffect(() => {
    debounceNav.cancel();
  }, [debounceNav]);

  const handleBulkUploadClick = () => {
    setOpenDialog((preVla) => {
      return !preVla;
    });
  };
  const handleChange = (newChange: { key: string; values: { i: string }[] }) => {
    parsedState.name = nameForSegment;
    const newParamObj = generateQueryParamObj(parsedState, newChange);

    history.push(
      `/search?${addPersistentQueryParams(retailer, mainTimePeriod)}&entityType=segment&${queryString.stringify(
        newParamObj
      )}`
    );
  };

  const handleChangeInputName = (inputValue: string) => {
    setNameForSegment(inputValue);
  };

  const handleSubmit = async () => {
    if (nameForSegment.length === 0) {
      return;
    }
    setIsUpdating(true);
    const { queryId } = parsedState;

    if (allSegmentData.find((element) => element.name === nameForSegment && element.queryId !== queryId)) {
      alert(`You must pick a unique name for this segment; ${nameForSegment} already exists.`);
      setIsUpdating(false);
      return;
    }

    delete parsedState.name;
    delete parsedState.queryId;
    let requestBody = { name: nameForSegment, query: { ...parsedState }, segmentType: 'product' };
    if (_isString(queryId)) {
      requestBody = { ...requestBody, queryId };
    }
    const response = updateTheOmniSegment(requestBody);
    response
      .then((data) => {
        setIsUpdating(false);
        const { data: newSegment } = data;
        const { queryId: newQueryId } = newSegment;
        dispatch(fetchSegmentListData());
        debounceNav(newQueryId);
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const handleClear = () => {
    const { queryId } = parsedState;
    const newParamObj = generateQueryParamObj({ name, queryId }, {});
    history.push(
      `/search?${addPersistentQueryParams(retailer, mainTimePeriod)}&entityType=segment&${queryString.stringify(
        newParamObj
      )}`
    );
  };

  const getIdFieldNameAndItemListKey = (filterType: string, uploadType: string) => {
    const uploadTypeMap = {
      product: 'Keywords',
      location: 'LocationKeywords'
    };
    return `${filterType}${uploadTypeMap[uploadType]}`;
  };

  const handleClearFunction = (key: string) => {
    handleChange({ key, values: [] });
  };

  return (
    <div
      className="omni-search-nav-container"
      style={{
        padding: '30px 0'
      }}
    >
      <Box>
        <form>
          <OmniSegmentNameField
            value={nameForSegment}
            handleChangeInputName={handleChangeInputName}
            errorText={nameForSegment.length === 0 ? 'This field is required' : ''}
            hintText="Name your segment"
          />
          <KeywordsSearch
            idFieldName="includeKeywords"
            headerDisplayName="Include"
            searchFieldHintText="Add product tags to include"
            keywords={parsedState.includeKeywords.map((e) => ({ i: e }))}
            onKeywordsChange={handleChange}
            entityDefinition={{}}
            appName={app.name}
            renderClearButton={() => (
              <OmniClearButton
                data={parsedState.includeKeywords}
                handleClearAll={() => {
                  handleClearFunction('includeKeywords');
                }}
              />
            )}
          />
          <KeywordsSearch
            idFieldName="excludeKeywords"
            headerDisplayName="Exclude"
            searchFieldHintText="Add product tags to exclude"
            keywords={parsedState.excludeKeywords.map((e) => ({ i: e }))}
            onKeywordsChange={handleChange}
            entityDefinition={{}}
            appName={app.name}
            renderClearButton={() => (
              <OmniClearButton
                data={parsedState.excludeKeywords}
                handleClearAll={() => {
                  handleClearFunction('excludeKeywords');
                }}
              />
            )}
          />
          {wrapperComponentList.map(({ type, CustomizeComponent }, index) => (
            <CustomizeComponent
              navEndPoint="search"
              nameForSegment={nameForSegment}
              parsedState={parsedState}
              selectorType={type}
              retailer={retailer}
              mainTimePeriod={mainTimePeriod}
              history={history}
              handleChange={handleChange}
              key={index}
            />
          ))}

          <SaveButton
            style={{ marginTop: 14 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {isUpdating ? 'SAVE SEGMENT...' : 'SAVE SEGMENT'}
          </SaveButton>
          <>
            <BulkUploadButton style={{ marginTop: 14 }} onClick={handleBulkUploadClick}>
              Bulk Upload
            </BulkUploadButton>
            <ClearFiltersButton secondary style={{ marginTop: 14 }} onClick={handleClear}>
              Clear Filters
            </ClearFiltersButton>

            <OmniBulkUpload
              open={openDialog}
              filterOpt={['include', 'exclude']}
              uploadOpt={['product', 'location']}
              title="Segment Bulk Upload"
              onClose={handleBulkUploadClick}
              parsedState={parsedState}
              getIdFieldNameAndItemListKey={getIdFieldNameAndItemListKey}
              onKeywordsChange={(newChange: { key: string; values: { i: string }[] }) => {
                handleChange(newChange);
              }}
              TextContent={<TextContent />}
            />
          </>
        </form>
      </Box>
    </div>
  );
};

export default OmniSearchSideBar;
