import { Button } from '@mui/material';
import React, { FC, useEffect } from 'react';

interface DiscoverFilterDialogFooterProps {
  isDisabled: boolean;
  initialApiCallConfig: (value: boolean) => void;
  alterColumns: () => void;
  updateColsOnChipClick: () => void;
  setColDef: any;
  gridApi: any;
  setShowFilter: any;
  setTempFilterObj: any;
  filterObj: any;
  onApplyFilter: any;
}

const DiscoverFilterDialogFooter: FC<DiscoverFilterDialogFooterProps> = ({
  isDisabled,
  initialApiCallConfig,
  alterColumns,
  updateColsOnChipClick,
  setColDef,
  gridApi,
  setShowFilter,
  setTempFilterObj,
  filterObj,
  onApplyFilter
}) => {
  const applyFilters = () => {
    const tempObj = JSON.parse(JSON.stringify(filterObj));
    setTempFilterObj({ ...tempObj });
    onApplyFilter();
    initialApiCallConfig(true);
    alterColumns();
    if (updateColsOnChipClick) {
      setColDef(updateColsOnChipClick);
    }
    if (gridApi) {
      gridApi.setColumnDefs(updateColsOnChipClick);
    }
    // Would it be better to do false/true?
    setShowFilter(false);
  };

  const keydownHandler = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey) && !isDisabled) {
      applyFilters();
    }
  };

  useEffect(() => {
    // So we can do Cmd/Ctrl + Enter to apply filters
    document.addEventListener('keydown', keydownHandler);

    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, []);

  return (
    <div className="buttonContainer">
      <Button
        disabled={isDisabled}
        variant="contained"
        style={{ marginRight: '10px', borderRadius: '23px', color: '#fff' }}
        onClick={applyFilters}
        className="applyButton"
      >
        Apply Filters
      </Button>
    </div>
  );
};

export default DiscoverFilterDialogFooter;
