export const CONTENT_TABLE_HEADER_DISPLAY_NAMES = {
  CONTENT_SCORE: 'Content \nScore',
  TITLE_SCORE: 'Title \nScore',
  BULLET_SCORE: 'Bullet \nScore',
  IMAGE_SCORE: 'Image \nScore',
  VIDEO_SCORE: 'Video \nScore',
  APLUS_SCORE: 'A-Plus \nScore',
  TITLE_ACCURACY: 'Title \nAccuracy',
  BULLET_ACCURACY: 'Bullet \nAccuracy',
  IMAGE_ACCURACY: 'Image \nAccuracy',
  VIDEO_ACCURACY: 'Video \nAccuracy'
};
