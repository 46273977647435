import React, { useCallback, useEffect } from 'react';
import DisputeDetails from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeDetails/DisputeDetails';
import DisputeDashboard from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/DisputeDashboard';
import { useLinkForDisputeManagement } from 'src/components/ShortageDisputes/components/DisputeManagement/hooks';
import { useHistory, useLocation } from 'src/utils/Hooks';

const DisputeManagement = () => {
  const history = useHistory();
  const location = useLocation();

  const searchParameters = new URLSearchParams(location.search);
  const disputeManagementLink = useLinkForDisputeManagement();

  const redirectToClientEntity = useCallback(() => {
    history.push(disputeManagementLink);
  }, [disputeManagementLink, history]);

  useEffect(() => {
    if (!location.pathname.includes('client')) {
      redirectToClientEntity();
    }
  }, [location, redirectToClientEntity]);

  const renderView = () => {
    if (searchParameters.has('invoiceId')) {
      return <DisputeDetails />;
    }
    return <DisputeDashboard />;
  };

  return <>{renderView()}</>;
};
export default DisputeManagement;
