import types from './types';

// ------------------------------------
// Actions Creators
// ------------------------------------

function updateComparisonTimePeriod(
  periodId,
  startWeek,
  endWeek,
  startDayId,
  endDayId,
  startWeekStartDate,
  endWeekEndDate
) {
  return {
    type: types.UPDATE_COMPARISON_TIME_PERIOD,
    periodId,
    startWeek,
    endWeek,
    startDayId,
    endDayId,
    startWeekStartDate,
    endWeekEndDate
  };
}

export default {
  updateComparisonTimePeriod
};
