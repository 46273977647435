import React from 'react';
import { SlRow, Text } from '@stackline/ui';
import ChevronLeft from '!svg-react-loader!./assets/chevronLeft.svg'; // eslint-disable-line
import ChevronRight from '!svg-react-loader!./assets/chevronRight.svg'; // eslint-disable-line

export interface PaginationProps {
  currentPage: number;
  totalPages?: number;
  onClickNext: () => void;
  onClickPrevious: () => void;
  shouldUseInfinitePagination?: boolean;
}

/**
 * Inside of the row, the icon is positioned
 * at the bottom of the div so this moves it up
 */
const iconStyle: React.CSSProperties = {
  position: 'relative',
  top: '2px',
  cursor: 'pointer'
};

/**
 * Shows page for paginated results and handles going to the next
 * and previous pages
 */
const Pagination = ({
  currentPage,
  onClickNext,
  onClickPrevious,
  totalPages,
  shouldUseInfinitePagination = false
}: PaginationProps) => {
  return (
    <SlRow spacing="xs" verticalPosition="center">
      <ChevronLeft style={iconStyle} onClick={onClickPrevious} role="button" />
      <Text variant="subtitle3">
        Page {currentPage} {!shouldUseInfinitePagination && `of ${totalPages}`}
      </Text>
      <ChevronRight style={iconStyle} onClick={onClickNext} role="button" />
    </SlRow>
  );
};

export default Pagination;
