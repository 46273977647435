/* eslint-disable react/prop-types */
import React from 'react';
import { AppButton } from '@stackline/ui';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import colors from 'src/utils/colors';
import DeleteButton from 'src/components/common/Buttons/DeleteButton';

const styles: { [key: string]: React.CSSProperties } = {
  dialogButtonsWrapper: {
    textAlign: 'right',
    margin: '30px',
    display: 'flex',
    flexDirection: 'row'
  },
  cancelButtonWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  cancelButton: {
    marginTop: '10px',
    color: colors.darkBlue,
    display: 'inline-block'
  }
};

const DeleteConfirmationDialog: React.FC<{
  title?: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  deleteButtonText?: string;
}> = ({ title = 'Confirm Action', open, onClose, children, onDelete, deleteButtonText = 'Delete' }) => (
  <Dialog open={open} sx={{ padding: '15px' }}>
    <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>
    <div style={{ textAlign: 'center', color: colors.darkBlue, width: '450px' }}>{children}</div>
    <div style={styles.dialogButtonsWrapper}>
      <div style={styles.cancelButtonWrapper}>
        <AppButton
          backgroundColor="#fff"
          sx={{ marginTop: '10px', color: colors.darkBlue, display: 'inline-block' }}
          onClick={onClose}
        >
          Cancel
        </AppButton>
      </div>

      <DeleteButton onClick={onDelete} label={deleteButtonText} />
    </div>
  </Dialog>
);

export default DeleteConfirmationDialog;
