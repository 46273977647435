import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import _isString from 'lodash/isString';

import { EnhancedDonutChartContainer } from 'src/components/Charts/Donut';
import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';

import EntityGrid from 'src/components/EntityGrid';
import VIEWS from '../ViewDefaultConfig';
import { buildKeyMetricsEntityGridWidget } from './KeyMetricsWidgets';
import beaconKeyMetricsConfig from './KeyMetricsConfig';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { buildSummaryTrendColumnGroupsWidget } from 'src/components/Layout/LayoutUtil';
import { shouldShowNewBeacon } from 'src/utils/app';
import ComplianceAdsPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Compliance/Advertising/ComplianceAdsPage';
import ComplianceSellersPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Compliance/Sellers/ComplianceSellersPage';
import { BEACON_GRID_TOP_SPACING } from './BeaconProLayoutConsts';
import ComplianceSummaryPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Compliance/Summary/ComplianceSummaryPage';

const tabAndMetricTypeToIndexNameMapping = {
  compliance: {
    keymetrics: 'compliance-selleritem',
    keywordCompliance: 'compliance-keywordaditem',
    sellerCompliance: 'compliance-selleritem',
    catalogCompliance: 'compliance-catalogitem',
    priceCompliance: 'compliance-catalogitem'
  }
};

export const getIndexName = (tab, metricType) => {
  const mappingForTab = tabAndMetricTypeToIndexNameMapping[tab] || tab;
  const mappingForMetricType = _isString(mappingForTab) ? mappingForTab : mappingForTab[metricType] || tab;
  return mappingForMetricType;
};

export default function getCompliancePageLayout({ app, retailer, user, tab, metricType, entity }) {
  const indexName = getIndexName(tab, metricType);

  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.beacon.retailer),
    categoryEntity: _cloneDeep(ENTITIES.beacon.category),
    relatedEntity: _cloneDeep(ENTITIES.beacon.brand),
    weekIdField: _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId')),
    retailerIdField: _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'))
  };
  const widgets = [];

  if (metricType === 'keymetrics') {
    dataConfig.aggregationsConfig = beaconKeyMetricsConfig[tab].getAggregationsConfig(
      app,
      retailer,
      user,
      metricType,
      tab,
      entity,
      dataConfig
    );

    const adComplianceLinkToRest = { ctab: 'compliance-keywordaditem', csubtab: 'unauthorized', ctype: 'metric' };
    const sellerComplianceLinkToRest = { ctab: 'compliance-selleritem', csubtab: 'unauthorized', ctype: 'metric' };

    const summaryTrendColumnWidgetGroup = buildSummaryTrendColumnGroupsWidget({ app, entity, weekIdField }, [
      [
        [
          'compliance-keywordaditem',
          'authorizedKeywordAdPercent',
          'Ad Compliance',
          'compliance',
          'keywordCompliance',
          null,
          null,
          adComplianceLinkToRest
        ],
        [
          'compliance-keywordaditem',
          'authorized',
          'COMPLIANT',
          'compliance',
          'keywordCompliance',
          null,
          null,
          adComplianceLinkToRest
        ],
        [
          'compliance-keywordaditem',
          'unauthorized',
          'VIOLATIONS',
          'compliance',
          'keywordCompliance',
          null,
          null,
          adComplianceLinkToRest
        ]
      ],
      [
        [
          'compliance-selleritem',
          'authorizedSellerPercent',
          'Seller Compliance',
          'compliance',
          'sellerCompliance',
          null,
          null,
          sellerComplianceLinkToRest
        ],
        [
          'compliance-selleritem',
          'authorized',
          'COMPLIANT',
          'compliance',
          'sellerCompliance',
          null,
          null,
          sellerComplianceLinkToRest
        ],
        [
          'compliance-selleritem',
          'unauthorized',
          'VIOLATIONS',
          'compliance',
          'sellerCompliance',
          null,
          null,
          sellerComplianceLinkToRest
        ]
      ],
      [
        ['contentChanged', 'contentAccuracy', 'Content Accuracy', 'content', 'contentAccuracy'],
        ['content', 'contentScore', 'CONTENT SCORE', 'content', 'contentAccuracy'],
        ['content', 'timestamp', 'VIOLATIONS', 'content', 'contentAccuracy']
      ]
    ]);

    if (shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: ComplianceSummaryPage,
        view: {
          container: {
            style: {}
          }
        },
        name: 'complianceSummaryKeyMetricCards'
      });
    } else {
      widgets.push(summaryTrendColumnWidgetGroup);
    }

    if (!shouldShowNewBeacon()) {
      widgets.push(buildKeyMetricsEntityGridWidget({ tab, app, indexName }));
    }
  } else if (tab === 'compliance' && metricType === 'keywordCompliance') {
    const aggregationFields = [
      INDEX_FIELDS.getField(app.name, indexName, 'authorized', entity.type, dataConfig.retailerIdField.name),
      INDEX_FIELDS.getField(app.name, indexName, 'unauthorized', entity.type, dataConfig.retailerIdField.name)
    ];

    const gridAggregationFields = [
      INDEX_FIELDS.getField(app.name, indexName, 'unauthorized', entity.type, 'stacklineSku')
    ];

    if (shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: ComplianceAdsPage,
        view: {
          container: {
            style: {}
          }
        },
        name: 'complianceAdsPage'
      });
    } else {
      widgets.push({
        CustomComponent: EnhancedDonutChartContainer,
        view: {
          ...VIEWS.donutChart,
          name: `DonutChartContainer`,
          chartPropsOverride: {
            chart: {
              type: 'pie',
              height: 350,
              width: 350
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false
                },
                size: 350,
                startAngle: -130,
                endAngle: 130,
                center: ['50%', '63%'],
                innerSize: 172
              }
            },
            tooltip: {
              enabled: false
            },
            disablePointSelect: true,
            enableMultiSelect: false
          },
          container: {
            style: {
              flex: '1 1 40%',
              width: '40%',
              display: 'inline-block',
              maxHeight: '500px',
              overflow: 'hidden',
              marginBottom: '120px'
            }
          }
        },
        data: {
          indexName: 'compliance-keywordaditem',
          chartMainField: INDEX_FIELDS.getField(
            app.name,
            'compliance-keywordaditem',
            'authorizedKeywordAdPercent',
            entity.type,
            dataConfig.retailerIdField.name
          ),
          mainMetric: INDEX_FIELDS.getField(
            app.name,
            'compliance-keywordaditem',
            'authorizedKeywordAdPercent',
            entity.type,
            dataConfig.retailerIdField.name
          ),
          secondaryMetric: INDEX_FIELDS.getField(
            app.name,
            'compliance-keywordaditem',
            'authorizedKeywordAdPercent',
            entity.type,
            dataConfig.retailerIdField.name
          ),
          aggregationFields: [
            INDEX_FIELDS.getField(
              app.name,
              'compliance-keywordaditem',
              'authorizedKeywordAdPercent',
              entity.type,
              dataConfig.retailerIdField.name
            )
          ],
          groupByField: INDEX_FIELDS.getField(app.name, indexName, 'retailerId', entity.type)
        }
      });
    }

    if (!shouldShowNewBeacon()) {
      widgets.push({
        view: _merge({}, VIEWS.weeklyTrendChart, {
          chartPropsOverride: { chart: { height: 500 }, singleYAxis: true, title: { text: 'Ad Compliance' } },
          container: {
            style: {
              flex: '1 1 60%',
              width: '60%',
              display: 'inline-block',
              maxHeight: '500px',
              overflow: 'hidden',
              marginBottom: '10px'
            }
          }
        }),
        data: {
          chartMainField: aggregationFields[0],
          aggregationFields: [...aggregationFields],
          groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.weekIdField.name, entity.type)
        }
      });
    }

    if (!shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: EntityGrid,
        view: _merge({}, VIEWS.entityGrid, {
          gridOptions: {
            defaultLayout: 'table',
            pageSize: 500,
            enableGroupBy: true,
            enableSwitchingLayouts: false
          }
        }),
        data: {
          groupByFields: [
            INDEX_FIELDS.getField(app.name, indexName, 'KeywordText', 'KeywordText'),
            INDEX_FIELDS.getField(app.name, indexName, 'brandId', 'brand')
          ],
          configByGroupByFieldName: {
            KeywordText: {
              indexName,
              entity: ENTITIES.beacon.KeywordText,
              mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'unauthorized', 'product', 'stacklineSku'),
              aggregationFields: [...gridAggregationFields],
              tableView: {
                metricFields: [...gridAggregationFields]
              }
            },
            brandId: {
              indexName,
              entity: ENTITIES.beacon.brand,
              mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'unauthorized', 'product', 'stacklineSku'),
              aggregationFields: [...gridAggregationFields],
              tableView: {
                metricFields: [...gridAggregationFields]
              }
            }
          }
        }
      });
    }
  } else if (tab === 'compliance' && metricType === 'sellerCompliance') {
    const aggregationFields = [
      INDEX_FIELDS.getField(app.name, indexName, 'authorized', entity.type, dataConfig.retailerIdField.name),
      INDEX_FIELDS.getField(app.name, indexName, 'unauthorized', entity.type, dataConfig.retailerIdField.name),
      INDEX_FIELDS.getField(
        app.name,
        indexName,
        'authorizedSellerPercent',
        entity.type,
        dataConfig.retailerIdField.name
      )
    ];

    const gridAggregationFields = [
      INDEX_FIELDS.getField(app.name, indexName, 'unauthorized', entity.type, 'stacklineSku')
    ];

    if (shouldShowNewBeacon()) {
      widgets.push({
        CustomComponent: ComplianceSellersPage,
        view: {
          container: {
            style: {
              marginBottom: `${BEACON_GRID_TOP_SPACING}px`
            }
          }
        },
        name: 'complianceSellersPage'
      });
    } else {
      widgets.push({
        CustomComponent: EnhancedDonutChartContainer,
        view: {
          ...VIEWS.donutChart,
          name: `DonutChartContainer`,
          chartPropsOverride: {
            chart: {
              type: 'pie',
              height: 350,
              width: 350
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false
                },
                size: 350,
                startAngle: -130,
                endAngle: 130,
                center: ['50%', '63%'],
                innerSize: 172
              }
            },
            tooltip: {
              enabled: false
            },
            disablePointSelect: true,
            enableMultiSelect: false
          },
          container: {
            style: {
              flex: '1 1 40%',
              width: '40%',
              display: 'inline-block',
              maxHeight: '500px',
              overflow: 'hidden',
              marginBottom: '120px'
            }
          }
        },
        data: {
          indexName,
          chartMainField: aggregationFields[2],
          mainMetric: aggregationFields[2],
          secondaryMetric: aggregationFields[2],
          aggregationFields: [aggregationFields[2]],
          groupByField: INDEX_FIELDS.getField(app.name, indexName, 'retailerId', entity.type)
        }
      });

      widgets.push({
        view: _merge({}, VIEWS.weeklyTrendChart, {
          chartPropsOverride: {
            chart: { height: 500 },
            singleYAxis: true,
            title: { text: 'Seller Compliance' }
          },
          container: {
            style: {
              flex: '1 1 60%',
              width: '60%',
              display: 'inline-block',
              maxHeight: '500px',
              overflow: 'hidden',
              marginBottom: '10px'
            }
          }
        }),
        data: {
          chartMainField: aggregationFields[0],
          aggregationFields: [...aggregationFields],
          groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.weekIdField.name, entity.type)
        }
      });
    }

    widgets.push({
      CustomComponent: EntityGrid,
      view: _merge({}, VIEWS.entityGrid, {
        gridOptions: {
          defaultLayout: 'table',
          enableGroupBy: true,
          enableSwitchingLayouts: false
        }
      }),
      data: {
        groupByFields: [
          INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product'),
          INDEX_FIELDS.getField(app.name, indexName, 'merchantName', 'seller')
        ],
        configByGroupByFieldName: {
          stacklineSku: {
            indexName,
            entity: ENTITIES.beacon.product,
            mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'unauthorized', 'product', 'stacklineSku'),
            aggregationFields: [...gridAggregationFields],
            tableView: {
              metricFields: [...gridAggregationFields]
            }
          },
          merchantName: {
            indexName,
            entity: ENTITIES.beacon.seller,
            mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'unauthorized', 'product', 'stacklineSku'),
            aggregationFields: [...gridAggregationFields],
            tableView: {
              metricFields: [...gridAggregationFields]
            }
          }
        }
      }
    });
  } else if (tab === 'compliance' && metricType === 'catalogCompliance') {
    const aggregationFields = [
      INDEX_FIELDS.getField(app.name, indexName, 'authorized', entity.type, dataConfig.retailerIdField.name),
      INDEX_FIELDS.getField(app.name, indexName, 'unauthorized', entity.type, dataConfig.retailerIdField.name),
      INDEX_FIELDS.getField(
        app.name,
        indexName,
        'authorizedCatalogPercent',
        entity.type,
        dataConfig.retailerIdField.name
      )
    ];

    const gridAggregationFields = [
      INDEX_FIELDS.getField(app.name, indexName, 'unauthorized', entity.type, 'stacklineSku')
    ];

    widgets.push({
      CustomComponent: EnhancedDonutChartContainer,
      view: {
        ...VIEWS.donutChart,
        name: `DonutChartContainer`,
        chartPropsOverride: {
          chart: {
            type: 'pie',
            height: 350,
            width: 350
          },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false
              },
              size: 350,
              startAngle: -130,
              endAngle: 130,
              center: ['50%', '63%'],
              innerSize: 172
            }
          },
          tooltip: {
            enabled: false
          },
          disablePointSelect: true,
          enableMultiSelect: false
        },
        container: {
          style: {
            flex: '1 1 40%',
            width: '40%',
            display: 'inline-block',
            maxHeight: '500px',
            overflow: 'hidden',
            marginBottom: '120px'
          }
        }
      },
      data: {
        indexName,
        chartMainField: aggregationFields[2],
        mainMetric: aggregationFields[2],
        secondaryMetric: aggregationFields[2],
        aggregationFields: [aggregationFields[2]],
        groupByField: INDEX_FIELDS.getField(app.name, indexName, 'retailerId', entity.type)
      }
    });

    widgets.push({
      view: _merge({}, VIEWS.weeklyTrendChart, {
        chartPropsOverride: { chart: { height: 500 }, singleYAxis: true, title: { text: 'Catalog Compliance' } },
        container: {
          style: {
            flex: '1 1 60%',
            width: '60%',
            display: 'inline-block',
            maxHeight: '500px',
            overflow: 'hidden',
            marginBottom: '10px'
          }
        }
      }),
      data: {
        chartMainField: aggregationFields[0],
        aggregationFields: [...aggregationFields],
        groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.weekIdField.name, entity.type)
      }
    });

    widgets.push({
      CustomComponent: EntityGrid,
      view: _merge({}, VIEWS.entityGrid, {
        gridOptions: {
          defaultLayout: 'table',
          enableGroupBy: false,
          enableSwitchingLayouts: false
        }
      }),
      data: {
        groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
        configByGroupByFieldName: {
          stacklineSku: {
            indexName,
            entity: ENTITIES.beacon.product,
            mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'unauthorized', 'product', 'stacklineSku'),
            aggregationFields: [...gridAggregationFields],
            tableView: {
              metricFields: [...gridAggregationFields]
            }
          }
        }
      }
    });
  } else if (tab === 'compliance' && metricType === 'priceCompliance') {
    const aggregationFields = [
      INDEX_FIELDS.getField(app.name, indexName, 'mapPriceCompliant', entity.type, dataConfig.retailerIdField.name),
      INDEX_FIELDS.getField(app.name, indexName, 'mapPriceNonCompliant', entity.type, dataConfig.retailerIdField.name),
      INDEX_FIELDS.getField(app.name, indexName, 'compliantPricesPercent', entity.type, dataConfig.retailerIdField.name)
    ];

    const gridAggregationFields = [
      INDEX_FIELDS.getField(app.name, indexName, 'mapPriceNonCompliant', entity.type, 'stacklineSku')
    ];

    widgets.push({
      CustomComponent: EnhancedDonutChartContainer,
      view: {
        ...VIEWS.donutChart,
        name: `DonutChartContainer`,
        chartPropsOverride: {
          chart: {
            type: 'pie',
            height: 350,
            width: 350
          },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false
              },
              size: 350,
              startAngle: -130,
              endAngle: 130,
              center: ['50%', '63%'],
              innerSize: 172
            }
          },
          tooltip: {
            enabled: false
          },
          disablePointSelect: true,
          enableMultiSelect: false
        },
        container: {
          style: {
            flex: '1 1 40%',
            width: '40%',
            display: 'inline-block',
            maxHeight: '500px',
            overflow: 'hidden',
            marginBottom: '120px'
          }
        }
      },
      data: {
        indexName,
        chartMainField: aggregationFields[2],
        mainMetric: aggregationFields[2],
        secondaryMetric: aggregationFields[2],
        aggregationFields: [aggregationFields[2]],
        groupByField: INDEX_FIELDS.getField(app.name, indexName, 'retailerId', entity.type)
      }
    });

    widgets.push({
      view: _merge({}, VIEWS.weeklyTrendChart, {
        chartPropsOverride: { chart: { height: 500 }, singleYAxis: true, title: { text: 'Price Compliance' } },
        container: {
          style: {
            flex: '1 1 60%',
            width: '60%',
            display: 'inline-block',
            maxHeight: '500px',
            overflow: 'hidden',
            marginBottom: '10px'
          }
        }
      }),
      data: {
        chartMainField: aggregationFields[0],
        aggregationFields: [...aggregationFields],
        groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.weekIdField.name, entity.type)
      }
    });

    widgets.push({
      CustomComponent: EntityGrid,
      view: _merge({}, VIEWS.entityGrid, {
        gridOptions: {
          defaultLayout: 'table',
          enableGroupBy: false,
          enableSwitchingLayouts: false
        }
      }),
      data: {
        groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
        configByGroupByFieldName: {
          stacklineSku: {
            indexName,
            entity: ENTITIES.beacon.product,
            mainMetricField: INDEX_FIELDS.getField(
              app.name,
              indexName,
              'mapPriceNonCompliant',
              'product',
              'stacklineSku'
            ),
            aggregationFields: [...gridAggregationFields],
            tableView: {
              metricFields: [...gridAggregationFields]
            }
          }
        }
      }
    });
  }

  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    dataConfig,
    widgets
  };
}
