import _cloneDeep from 'lodash/cloneDeep';

import { INDEX_FIELDS, ENTITIES, METRICTYPE, DATATYPE } from 'src/utils/entityDefinitions';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import {
  buildTrendWidgetConfig,
  buildSummaryTrendColumnGroupsWidget,
  summarySubTrendView,
  summaryTrendStyle
} from 'src/components/Layout/LayoutUtil';
import CommonSummaryTrend from 'src/components/EntityPage/SummaryTrendChart/CommonSummaryTrend';
import CommonSummaryInfo from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { buildTrendingProductsWidgets } from 'src/components/Grids/TrendingProductsGrid';
import { buildSubtitleDisplayName } from 'src/utils/filters';

export default function getBeaconBasicPageLayout({ app, retailer, entity }) {
  const indexName = 'sales';
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
  const retailerIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'));
  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.beacon.retailer),
    categoryEntity: _cloneDeep(ENTITIES.beacon.category),
    relatedEntity: _cloneDeep(ENTITIES.beacon.brand),
    weekIdField,
    retailerIdField
  };
  const widgets = [];

  const retailSalesAvgTrendChart = buildTrendWidgetConfig(
    app,
    'sales',
    entity,
    'weekId',
    ['retailSales'],
    weekIdField,
    {
      name: 'retailSalesPerSkuTrend',
      view: {
        chartPropsOverride: {
          legend: {
            enabled: false,
            legendTitle: 'SALES PER SKU'
          },
          ...summarySubTrendView
        },
        linkTo: { tab: 'sales', subtab: 'retailSales' },
        container: { ...summaryTrendStyle.subTrend }
      },
      CustomComponent: CommonSummaryTrend
    }
  );
  const unitsSoldAvgTrendChart = buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['unitsSold'], weekIdField, {
    name: 'unitsSoldPerSkuTrend',
    view: {
      chartPropsOverride: {
        legend: {
          enabled: false,
          legendTitle: 'UNITS PER SKU'
        },
        ...summarySubTrendView
      },
      linkTo: { tab: 'sales', subtab: 'unitsSold' },
      container: { ...summaryTrendStyle.subTrend }
    },
    CustomComponent: CommonSummaryTrend
  });
  if (entity.type !== 'product') {
    retailSalesAvgTrendChart.view.metricFields[0].aggregationFunction = 'avg';
    retailSalesAvgTrendChart.data.configByGroupByFieldName.weekId.aggregationFields[0].aggregationFunction = 'avg';

    unitsSoldAvgTrendChart.view.metricFields[0].aggregationFunction = 'avg';
    unitsSoldAvgTrendChart.data.configByGroupByFieldName.weekId.aggregationFields[0].aggregationFunction = 'avg';
  }
  const productsWithSalesWidgetConfig = buildTrendWidgetConfig(
    app,
    'sales',
    entity,
    'weekId',
    ['stacklineSku'],
    weekIdField,
    {
      view: {
        chartPropsOverride: {
          legend: {
            legendTitle: 'PRODUCTS',
            enabled: false
          },
          ...summarySubTrendView
        },
        linkTo: { tab: 'sales', subtab: 'retailSales' },
        container: { ...summaryTrendStyle.subTrend }
      },
      CustomComponent: CommonSummaryTrend
    }
  );

  productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'stacklineSku'
  ).conditions = {
    rangeFilters: [
      {
        fieldName: 'unitsSold',
        minValue: 1
      }
    ]
  };

  productsWithSalesWidgetConfig.view.metricFields[0].metricType = METRICTYPE.VOLUME;
  productsWithSalesWidgetConfig.view.metricFields[0].dataType = DATATYPE.INTEGER;
  productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'stacklineSku'
  ).metricType = METRICTYPE.VOLUME;
  productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
    (x) => x.name === 'stacklineSku'
  ).dataType = DATATYPE.INTEGER;

  widgets.push({
    CustomComponent: CommonSummaryInfo,
    name: 'CommonSummaryInfo',
    view: {
      name: 'CommonSummaryInfo',
      // eslint-disable-next-line no-shadow
      buildSubTitle: ({ retailer, mainEntity, filters, categories, app }) =>
        mainEntity ? buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app).displayName : 'Summary'
    }
  });

  const summaryTrendColumnWidgetGroup = buildSummaryTrendColumnGroupsWidget({ app, entity, weekIdField }, [
    [
      ['sales', 'retailSales', 'Retail Sales', 'sales', 'retailSales'],
      ['sales', 'unitsSold', 'UNITS SOLD', 'sales', 'unitsSold'],
      ['sales', 'wholesaleSales', 'WHOLESALE SALES', 'sales', 'retailSales'],
      ['sales', 'retailerGrossMargin', 'RETAILER MARGIN', 'sales', 'retailerGrossMargin'],
      unitsSoldAvgTrendChart
    ],
    [
      ['advertising', 'spend', 'Ad Spend', 'traffic', 'adClicks'],
      ['advertising', 'sales', 'AD SALES', 'traffic', 'adClicks'],
      ['advertising', 'clicks', 'TRAFFIC - ADS', 'traffic', 'adClicks'],
      ['advertising', 'costPerClick', 'CPC', 'traffic', 'adClicks'],
      ['advertising', 'returnOnAdSpend', 'ROAS', 'traffic', 'adClicks']
    ],
    [
      ['sales', 'retailPrice', 'Retail Price', 'sales', 'retailPrice'],
      ['content', 'contentScore', 'CONTENT SCORE', 'content', 'contentScore'],
      ['reviews', 'stars', 'RATING', 'reviews', 'reviewTrends'],
      ['sales', 'instockRate', 'IN-STOCK RATE', 'sales', 'inventory'],
      productsWithSalesWidgetConfig
    ]
  ]);

  widgets.push(summaryTrendColumnWidgetGroup);

  // TRENDING PRODUCTS
  if (entity.type !== 'product') {
    widgets.push(...buildTrendingProductsWidgets({ app, retailer, entity }));
  }

  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    widgets,
    containerStyle: {
      marginTop: 30
    },
    dataConfig
  };
}
