import ReduxStore from 'src/types/store/reduxStore';
import { Entity, Conditions, RangeFilter } from 'sl-api-connector/types';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { buildFullMonthRangeFilters } from 'src/utils/dateformatting';
import { buildAggregations } from 'src/components/AdManager/Search';
import { Option } from 'funfix-core';
import { store } from 'src/main';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import mitt from 'mitt';

export const emitter = mitt();

export const fetchDailySpendAndProjections = (
  baseDataKey: string,
  { app, retailer, mainEntity }: Pick<ReduxStore, 'app' | 'retailer'> & { mainEntity: Entity },
  conditions: Conditions
) => {
  const fetchData = (dataKey: string, indexName: string, fields: string[], isProjected: number) => {
    const timePeriodRangeFilters: RangeFilter[] = buildFullMonthRangeFilters();
    const metricFields = fields.map((fieldName) =>
      INDEX_FIELDS.getField(app.name, indexName, fieldName, 'campaign', 'dayId')
    );
    const groupByField = INDEX_FIELDS.getField(app.name, indexName, 'dayId');
    let useAggregationSort = true;
    const baseKey = baseDataKey.split('-')[0];

    // We only want to turn off aggregation based sort for the donutChart & dailyAdSpendBarChart
    if (['scoreDonutChart', 'dailyAdSpendBarChart'].includes(baseKey)) {
      useAggregationSort = false;
    }

    const [{ aggregations: aggregationFields }] = buildAggregations(metricFields);
    const queryConditions = {
      // Strip out `categoryId` conditions since they don't work with `dayId` aggregations on these special indices
      termFilters: Option.of(conditions.termFilters)
        .map((termFilters) => termFilters.filter(({ fieldName }) => fieldName !== 'categoryId'))
        .getOrElse([
          {
            fieldName: 'isProjected',
            condition: 'must',
            values: [isProjected]
          }
        ]),
      rangeFilters: timePeriodRangeFilters
    };
    if (queryConditions.termFilters.findIndex((x) => x.fieldName === 'isProjected') === -1) {
      queryConditions.termFilters.push({
        fieldName: 'isProjected',
        condition: 'must',
        values: [isProjected]
      });
    }
    store.dispatch(
      fetchEntityMetrics(
        dataKey,
        {
          entity: mainEntity,
          retailer,
          app,
          indexName
        },
        [
          {
            doAggregation: true,
            aggregations: [
              {
                aggregationFields,
                conditions: {
                  termFilters: [{ fieldName: 'retailerId', values: [+retailer.id] }],
                  rangeFilters: timePeriodRangeFilters
                },
                groupByFieldName: groupByField.name
              }
            ],
            conditions: queryConditions,
            shouldCache: false,
            useAggregationSort,
            pageSize: 100
          }
        ]
      )
    );
  };

  // Fetch projections to use when no real data is available
  fetchData(`${baseDataKey}-projections`, 'adCampaignDailyMetrics', ['spend', 'budgetAmount', 'isProjected'], 1);
  fetchData(`${baseDataKey}-actual`, 'adCampaignDailyMetrics', ['spend', 'budgetAmount', 'isProjected'], 0);
};
