import React from 'react';
import { Widget } from 'src/types/application/widgetTypes';
import { EventBus } from 'src/types/utils';
import { Location } from 'history';
import EntitiesGrid from './EntitiesGrid';
import CampaignsGrid from './CampaignsGrid';
import PortfoliosGrid from './PortfoliosGrid';
import ProductsGrid from './ProductsGrid';
import TargetsGrid from './TargetsGrid';
import AdGroupsGrid from './AdGroupsGrid';
import RetailersGrid from './RetailersGrid';
import { withBus } from 'react-bus';
import { withRouter } from 'react-router';
import FormDataProvider from './FormDataProvider';
import QueryString from 'qs';

interface SearchResultsGridContainerProps {
  widget: Widget;
  eventBus: EventBus;
  location: Location;
  queryParams: string | QueryString.ParsedQs;
}

/** Outer container for all search results grids */
export const SearchResultsGridContainerInner = (props: SearchResultsGridContainerProps) => {
  const GridComponent = {
    entityId: EntitiesGrid,
    campaignId: CampaignsGrid,
    portfolioId: PortfoliosGrid,
    stacklineSku: ProductsGrid,
    targetingText: TargetsGrid,
    adGroupId: AdGroupsGrid,
    retailerId: RetailersGrid
  }[props.widget.data.groupByFieldName];

  const queryParams = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: 100
  });

  return GridComponent ? (
    <FormDataProvider>
      <GridComponent {...props} queryParams={queryParams} />
    </FormDataProvider>
  ) : null;
};

export default withRouter(withBus('eventBus')(SearchResultsGridContainerInner));
