import React from 'react';
import Dialog from '@mui/material/Dialog';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';

interface OmniSegmentDeleteDialogProps {
  isOpen: boolean;
  setIsOpen: (openOrClose: boolean) => void;
  selected: { name: string; queryId: string };
  deleteButtonText: string;
  handleDelete: () => void;
}

const OmniSegmentDeleteDialog: React.FC<OmniSegmentDeleteDialogProps> = ({
  isOpen,
  setIsOpen,
  selected,
  deleteButtonText,
  handleDelete
}: OmniSegmentDeleteDialogProps) => {
  const { name } = selected;
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="confirm_dialog">
        <div className="confirm_title">Confirmation</div>
        <div className="confirm_body">
          Are you sure you want to delete <strong>{name}</strong>? This cannot be undone.
        </div>
        <div className="confirm_buttons">
          <LargeMuiButton
            style={{ width: 88, height: 35, marginRight: 5 }}
            label={deleteButtonText}
            onClick={() => {
              handleDelete();
            }}
          />
          <LargeMuiButton
            secondary
            style={{ width: 88, height: 35, marginLeft: 5, marginRight: 5 }}
            label="Cancel"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default OmniSegmentDeleteDialog;
