import React from 'react';
import { useStacklineTheme } from '@stackline/ui';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import { PRODUCT_CARD_HEIGHT, PRODUCT_CARD_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { SlColumn } from 'src/components/BeaconRedesignComponents/Generic/SlColumn';

/**
 * Displays a card with the loading state
 */
const ProductCardLoading = () => {
  const theme = useStacklineTheme();

  return (
    <div
      style={{
        width: PRODUCT_CARD_WIDTH,
        height: PRODUCT_CARD_HEIGHT,
        border: `1px solid ${theme.colors.primaryGray}`,
        borderRadius: theme.spacing.sm,
        padding: `52px ${theme.spacing.md} ${theme.spacing.md}`,
        boxSizing: 'border-box'
      }}
    >
      <SlColumn spacing={12} horizontalPosition="center">
        <SlSkeleton variant="rounded" height={106} width={106} />
        <SlColumn spacing="sm">
          <SlSkeleton variant="rounded" height={19} width={64} />
          <SlSkeleton variant="rounded" height={24} width={128} />
          <SlSkeleton variant="rounded" height={57} width={204} />
        </SlColumn>
      </SlColumn>
    </div>
  );
};

export default ProductCardLoading;
