import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import ReduxStore from 'src/types/store/reduxStore';
import { PageLayout, Widget } from 'src/types/application/widgetTypes';
import OmniDonutChartContent from 'src/components/Omni/OmniDonutChart/OmniDonutChartContent';
import OmniContentColumnWrapper from 'src/components/Omni/OmniContentColumn/OmniContentColumnWrapper';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import _merge from 'lodash/merge';
import OmniTrendChart from 'src/components/EntityPage/TrendChart/OmniTrendChart';
import { buildTrendWidgetConfig } from 'src/components/Layout/LayoutUtil';
import { AppName } from 'sl-api-connector/types';
import _cloneDeep from 'lodash/cloneDeep';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { buildOmniProductsWidget } from 'src/components/EntityGrid/EntityGrid/OmniEntityGridUtils';
import { omniGridField, omniTableViewField } from 'src/components/EntityGrid/EntityGrid/omniGridField';
import OmniContentAccuracy from 'src/components/EntityPage/Content/OmniContentAccuracy';
import OmniFilterWrapper from 'src/components/Omni/OmniFilter/OmniFIlterWrapper';
import OmniContentCompare from 'src/components/EntityPage/Content/OmniContentCompare';
import OmniRetailerSelectorHeader from 'src/components/Omni/OmniRetailerSelectorHeader/OmniRetailerSelectorHeader';
import { buildOmniTopEntitiesWidgetConfig } from 'src/components/EntityPage/TopEntitiesChart/OmniTopEntitesChartUtil';
import { ExportIcon, ContentCurrentIcon, InventoryIcon } from 'src/components/SvgIcons';
import PageContextMenu from 'src/components/PageContextMenu';
import OmniContentRewriteCompare from 'src/components/EntityPage/Content/OmniContentRewriteCompare';
import { isContentRewriteAllowed } from 'src/utils/app';

const CONTENT_SCORE_INDEX_NAME = 'content';
const indexName = CONTENT_SCORE_INDEX_NAME;
const weekIdField = _cloneDeep(INDEX_FIELDS.getField(AppName.Omni, indexName, 'weekId'));

const CONTENT_SCORE_EXPORT_SUPPORT_ENTITY = ['client', 'product', 'retailer', 'segment'];

export const getLayoutForEntity = (app: ReduxStore['app'], subtab: string, entityType: string): PageLayout => {
  const widgets: Widget[] = [];
  const filterWidget = {
    CustomComponent: OmniFilterWrapper,
    name: 'omniFilter',
    view: {
      name: 'omniFilter',
      style: {
        display: 'flex',
        justifyContent: 'end',
        marginTop: 41
      },
      shouldShowFilter: !(entityType === 'segment')
    },
    data: {}
  };
  switch (subtab) {
    case 'contentScore': {
      widgets.push(filterWidget);
      const donutWidget: Widget = {
        name: 'DonutChartContent',
        CustomComponent: OmniDonutChartContent,
        data: {
          indexName: 'content',
          mainMetric: {
            name: 'contentScore',
            displayName: 'Content Score',
            dataType: 'INTEGER',
            metricType: 'PERCENT',
            aggregationFunction: 'avg',
            timePeriodAggregationFunctionType: 'lastValue',
            timePeriodAggregationFunction: 'avg',
            overrides: {
              retailerId_Override: {
                aggregationFunctionType: 'lastValue',
                aggregationFunction: 'avg'
              },
              stacklineSku_Override: {
                aggregationFunctionType: 'lastValue',
                aggregationFunction: 'avg'
              }
            },
            fillWithLastKnownValue: true,
            maxValue: 1,
            aggregationFunctionType: 'lastValue',
            appName: 'omni',
            indexName: 'content'
          },
          secondaryMetric: {
            name: 'contentScore',
            displayName: 'Content Score',
            dataType: 'INTEGER',
            metricType: 'PERCENT',
            aggregationFunction: 'avg',
            timePeriodAggregationFunctionType: 'lastValue',
            timePeriodAggregationFunction: 'avg',
            overrides: {
              retailerId_Override: {
                aggregationFunctionType: 'lastValue',
                aggregationFunction: 'avg'
              },
              stacklineSku_Override: {
                aggregationFunctionType: 'lastValue',
                aggregationFunction: 'avg'
              }
            },
            fillWithLastKnownValue: true,
            maxValue: 1,
            aggregationFunctionType: 'lastValue',
            appName: 'omni',
            indexName: 'content'
          }
        },
        view: {
          name: 'DonutChartContainer-ContentScore',
          chartPropsOverride: {
            chart: {
              type: 'pie',
              height: 350,
              width: 350
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false
                },
                size: 350,
                startAngle: -130,
                endAngle: 130,
                center: ['50%', '63%'],
                innerSize: 172
              }
            },
            tooltip: {
              enabled: false
            },
            disablePointSelect: true,
            enableMultiSelect: false
          },
          container: {
            style: {
              flex: '1 1 50%',
              width: '50%',
              display: 'inline-block',
              maxHeight: '350px',
              overflow: 'hidden',
              marginBottom: '80px'
            }
          }
        }
      };
      const columnWidget: Widget = {
        name: 'omniContentColumn',
        CustomComponent: OmniContentColumnWrapper,
        view: _merge({}, VIEWS.columnChart, {
          name: 'contentScoreColumnChart',
          chartPropsOverride: {
            xAxis: {
              rotation: -45
            },
            chart: {
              height: 350,
              marginTop: 50
            },
            subtitle: { style: { 'border-bottom': 'none' } },
            dataLabels: {
              x: 15
            }
          },
          container: {
            style: {
              flex: '1 1 50%',
              width: '50%',
              display: 'inline-block',
              maxHeight: '350px',
              overflow: 'hidden',
              marginBottom: '40px'
            }
          }
        }),
        data: {
          indexName: 'content',
          chartMainField: {
            name: 'contentScore',
            displayName: 'Content Score',
            dataType: 'INTEGER',
            metricType: 'PERCENT',
            aggregationFunction: 'avg',
            timePeriodAggregationFunctionType: 'lastValue',
            timePeriodAggregationFunction: 'avg',
            overrides: {
              retailerId_Override: {
                aggregationFunctionType: 'lastValue',
                aggregationFunction: 'avg'
              },
              stacklineSku_Override: {
                aggregationFunctionType: 'lastValue',
                aggregationFunction: 'avg'
              }
            },
            fillWithLastKnownValue: true,
            maxValue: 1,
            aggregationFunctionType: 'lastValue',
            appName: 'beacon',
            indexName: 'content'
          },
          mainMetric: {
            name: 'contentScore',
            displayName: 'Content Score',
            dataType: 'INTEGER',
            metricType: 'PERCENT',
            aggregationFunction: 'avg',
            timePeriodAggregationFunctionType: 'lastValue',
            timePeriodAggregationFunction: 'avg',
            overrides: {
              retailerId_Override: {
                aggregationFunctionType: 'lastValue',
                aggregationFunction: 'avg'
              },
              stacklineSku_Override: {
                aggregationFunctionType: 'lastValue',
                aggregationFunction: 'avg'
              }
            },
            fillWithLastKnownValue: true,
            maxValue: 1,
            aggregationFunctionType: 'lastValue',
            appName: 'beacon',
            indexName: 'content'
          },
          secondaryMetric: {
            name: 'contentScore',
            displayName: 'Content Score',
            dataType: 'INTEGER',
            metricType: 'PERCENT',
            aggregationFunction: 'avg',
            timePeriodAggregationFunctionType: 'lastValue',
            timePeriodAggregationFunction: 'avg',
            overrides: {
              retailerId_Override: {
                aggregationFunctionType: 'lastValue',
                aggregationFunction: 'avg'
              },
              stacklineSku_Override: {
                aggregationFunctionType: 'lastValue',
                aggregationFunction: 'avg'
              }
            },
            fillWithLastKnownValue: true,
            maxValue: 1,
            aggregationFunctionType: 'lastValue',
            appName: 'beacon',
            indexName: 'content'
          },
          useLatestWeek: false,
          aggregationFields: [
            {
              name: 'contentScore',
              displayName: 'Content Score',
              dataType: 'INTEGER',
              metricType: 'PERCENT',
              aggregationFunction: 'avg',
              timePeriodAggregationFunctionType: 'lastValue',
              timePeriodAggregationFunction: 'avg',
              overrides: {
                retailerId_Override: {
                  aggregationFunctionType: 'lastValue',
                  aggregationFunction: 'avg'
                },
                stacklineSku_Override: {
                  aggregationFunctionType: 'lastValue',
                  aggregationFunction: 'avg'
                }
              },
              fillWithLastKnownValue: true,
              maxValue: 1,
              aggregationFunctionType: 'lastValue',
              appName: 'beacon',
              indexName: 'content'
            }
          ],
          groupByField: {
            name: 'retailerId',
            displayName: 'Retailer',
            entity: {
              type: 'retailer'
            },
            dataType: 'INTEGER',
            metricType: 'KEYFIELD',
            aggregationFunction: 'cardinality',
            groupBy: 'retailerId',
            appName: 'beacon',
            indexName: 'content'
          }
        }
      };
      const contentScoreTrendChart = buildTrendWidgetConfig(
        app,
        indexName,
        { type: entityType },
        'weekId',
        ['contentScore'],
        weekIdField,
        {
          CustomComponent: OmniTrendChart,
          view: {}
        }
      );

      const contentScoreBarChart = buildOmniTopEntitiesWidgetConfig(
        app,
        indexName,
        { type: entityType },
        ['retailerId'],
        ['contentScore'],
        weekIdField,
        {
          view: {
            horizontalScrolling: {
              enabled: true,
              step: 3
            }
          }
        }
      );

      const subMenuItems = [];

      if (CONTENT_SCORE_EXPORT_SUPPORT_ENTITY.includes(entityType)) {
        subMenuItems.push({
          icon: ExportIcon,
          text: 'Add to Download List',
          eventName: 'downloadOmniDataContentScore'
        });
      }

      widgets.push(donutWidget);
      widgets.push(columnWidget);
      widgets.push(contentScoreBarChart);
      widgets.push(contentScoreTrendChart);
      widgets.push(...buildOmniProductsWidget(omniGridField.contentScore, omniTableViewField.content, true));
      if (CONTENT_SCORE_EXPORT_SUPPORT_ENTITY.includes(entityType)) {
        widgets.push({
          CustomComponent: PageContextMenu,
          name: 'pageContextMenu',
          view: {
            name: 'pageContextMenu',
            buttons: subMenuItems
          },
          data: {}
        });
      }

      break;
    }
    case 'contentAccuracy': {
      if (entityType !== 'product') {
        widgets.push(filterWidget);
      } else {
        widgets.push({
          CustomComponent: OmniRetailerSelectorHeader,
          name: 'retailerDropDown',
          data: {},
          view: {
            name: 'retailerDropDown',
            style: {
              display: 'flex',
              justifyContent: 'end',
              marginTop: 41
            }
          }
        });
      }

      widgets.push({
        CustomComponent: OmniContentAccuracy,
        name: 'contentAccuracy',
        data: [],
        view: {
          name: 'contentAccuracy'
        }
      });

      const contentAccuracyTrendChart = buildTrendWidgetConfig(
        app,
        'content',
        { type: entityType },
        'weekId',
        ['contentAccuracy'],
        weekIdField,
        {
          CustomComponent: OmniTrendChart,
          view: {
            name: 'contentAccuracy'
          }
        }
      );

      widgets.push(contentAccuracyTrendChart);

      if (entityType !== 'product') {
        widgets.push(
          ...buildOmniProductsWidget(omniGridField.contentAccuracy, omniTableViewField.contentAccuracy, true)
        );
      } else {
        widgets.push({
          CustomComponent: OmniContentCompare,
          name: 'omniContentCompare',
          view: { name: 'omniContentCompare' },
          data: []
        });
      }

      const subMenuItems = [];

      // this should be refactored after we finish 15 exports, as we roll out one by one, it is still separated as of 12th Apr.

      if (entityType === 'client' || entityType === 'retailer' || entityType === 'brand' || entityType === 'segment') {
        subMenuItems.push({
          icon: ExportIcon,
          text: 'Add to Download List',
          eventName: 'downloadOmniDataContentAccuracy'
        });

        subMenuItems.push({
          icon: ContentCurrentIcon,
          text: 'Download Live Content',
          eventName: 'downloadOmniDataProductLive'
        });

        subMenuItems.push({
          icon: InventoryIcon,
          text: 'Download Master Content',
          eventName: 'downloadOmniDataProductMaster'
        });
      }

      if (entityType === 'product') {
        subMenuItems.push({
          icon: ContentCurrentIcon,
          text: 'Download Live Content',
          eventName: 'downloadOmniDataProductLive'
        });

        subMenuItems.push({
          icon: InventoryIcon,
          text: 'Download Master Content',
          eventName: 'downloadOmniDataProductMaster'
        });
      }

      if (subMenuItems.length > 0) {
        widgets.push({
          CustomComponent: PageContextMenu,
          name: 'pageContextMenu',
          view: {
            name: 'pageContextMenu',
            buttons: subMenuItems
          },
          data: {}
        });
      }

      break;
    }
    case 'contentRewrite': {
      if (isContentRewriteAllowed()) {
        if (entityType !== 'product') {
          widgets.push(filterWidget);
        } else {
          widgets.push({
            CustomComponent: OmniRetailerSelectorHeader,
            name: 'retailerDropDown',
            data: {},
            view: {
              name: 'retailerDropDown',
              style: {
                display: 'flex',
                justifyContent: 'end',
                marginTop: 41
              }
            }
          });
        }

        if (entityType !== 'product') {
          widgets.push(
            ...buildOmniProductsWidget(omniGridField.contentAccuracy, omniTableViewField.contentAccuracy, true)
          );
        } else {
          widgets.push({
            CustomComponent: OmniContentRewriteCompare,
            name: 'omniContentRewriteCompare',
            view: { name: 'omniContentRewriteCompare' },
            data: []
          });
        }
      }
      break;
    }

    default:
      break;
  }

  return {
    widgets,
    CustomPageContainer: GenericPageContainer as any,
    enableComparison: false
  };
};
