import { UseMutationOptions, useMutation } from 'react-query';
import {
  ComputeNetImpactForBulkAdjustmentPayload,
  ComputeNetImpactForBulkAdjustmentResponse
} from '../serverProxy/types';
import useServerProxy from './useServerProxy';

/**
 * Tells the backend to start computing net impact for the bulk adjustment
 */
export default function useComputeNetImpactForBulkAdjustment(
  options: UseMutationOptions<
    ComputeNetImpactForBulkAdjustmentResponse,
    unknown,
    ComputeNetImpactForBulkAdjustmentPayload
  > = {}
) {
  const { computeNetImpactForBulkAdjustment } = useServerProxy();
  return useMutation(computeNetImpactForBulkAdjustment, options);
}
