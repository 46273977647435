/* eslint-disable no-restricted-syntax */
import { RangeFilter, TermFilter } from 'sl-api-connector/types';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import { SearchGridConstants } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConstants';
import _get from 'lodash/get';
import _remove from 'lodash/remove';
import _cloneDeep from 'lodash/cloneDeep';
import _isObject from 'lodash/isObject';
import _unset from 'lodash/unset';
import _set from 'lodash/set';

export function getSearchRequestOverrideForGroupByField(
  aggregationFieldsForResultCount: any,
  retailer: any,
  mainTimePeriodRangeFilters: RangeFilter[],
  aggregationFilters: ISearchFilterConditions,
  sortDirection: string | undefined,
  currentSortFieldName: any,
  groupByFieldName: string,
  searchSideBarConditions: ISearchFilterConditions,
  scheduledActionTermFilters: TermFilter[],
  customTermFilters: any,
  pageNumber: number
) {
  return {
    doAggregation: true,
    additionalRequestMetaData: {
      returnAdditionalMetaData: false
    },
    aggregations: [
      {
        aggregationFields: aggregationFieldsForResultCount,
        conditions: {
          termFilters: [
            { fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] },
            {
              fieldName: 'isProjected',
              condition: 'must',
              values: [0]
            }
          ],
          rangeFilters: [...mainTimePeriodRangeFilters, ...aggregationFilters.rangeFilters]
        },
        sortDirection,
        sortByAggregationField: aggregationFieldsForResultCount.find(
          (x) => x.aggregateByFieldName === currentSortFieldName
        ),
        groupByFieldName
      }
    ],
    conditions: {
      termFilters: [...searchSideBarConditions.termFilters, ...scheduledActionTermFilters, ...customTermFilters],
      rangeFilters: [...mainTimePeriodRangeFilters, ...searchSideBarConditions.rangeFilters]
    },
    pageNumber,
    pageSize: SearchGridConstants.PAGE_SIZE_5K,
    processDocuments: false
  };
}

interface RemoveAction {
  action: 'remove';
  path: (string | number)[];
  conditionKey: string;
  conditionValue: any;
}

interface AddAction {
  action: 'add';
  path: (string | number)[];
  newObj: any; // You can make this more specific if you know the shape of newObj
}

interface ConcatAction {
  action: 'concat';
  path: (string | number)[];
  newObj: string;
}

interface UpdateAction {
  action: 'update';
  path: (string | number)[];
  newObj: any; // You can make this more specific if you know the shape of newObj
}
interface DeleteAction {
  action: 'update';
  path: (string | number)[];
}

export type ModifyESQueryAction = RemoveAction | AddAction | ConcatAction | UpdateAction | DeleteAction;

export function modifyESQuery(query, actions) {
  const result = _cloneDeep(query); // Create a deep copy to avoid mutation

  actions.forEach((action) => {
    const path = action.path.map((segment) =>
      // eslint-disable-next-line radix
      segment.startsWith('[') && segment.endsWith(']') ? parseInt(segment.replace(/\[|\]/g, '')) : segment
    );

    switch (action.action) {
      case 'remove':
        // Remove the element from the array based on the conditionKey and conditionValue
        _remove(_get(result, path), (item) => item[action.conditionKey] === action.conditionValue);
        break;

      case 'add':
        // Get the array from the path and add the new object to it

        if (_get(result, path) && Array.isArray(_get(result, path))) {
          _get(result, path).push(action.newObj);
        }
        break;

      case 'concat': {
        const targetToConcat = _get(result, path);
        if (typeof targetToConcat === 'string') {
          _set(result, path, targetToConcat + action.newObj);
        }
        break;
      }

      case 'update': {
        if (action.newObj === null) {
          _set(result, path, null);
        } else if (typeof action.newObj === 'object' && !Array.isArray(action.newObj)) {
          const targetToUpdate = _get(result, path);
          if (_isObject(targetToUpdate)) {
            Object.assign(targetToUpdate, action.newObj);
          }
        } else {
          _set(result, path, action.newObj);
        }
        break;
      }

      case 'delete': {
        _unset(result, path); // Use _unset to remove the property from the object
        break;
      }

      default:
        break;
    }
  });

  return result;
}

export const getESBodyOverridesForParentPlatform = (
  parentPlatform: string,
  custom?: null | ModifyESQueryAction[]
): ModifyESQueryAction[] => {
  if (custom) {
    return [...custom];
  }
  return [
    {
      action: 'add',
      path: ['conditions', 'termFilters'],
      newObj: {
        fieldName: 'parentPlatform',
        condition: 'must',
        values: [parentPlatform]
      }
    },
    {
      action: 'add',
      path: ['aggregations', '[0]', 'conditions', 'termFilters'],
      newObj: {
        fieldName: 'parentPlatform',
        condition: 'must',
        values: [parentPlatform]
      }
    }
  ];
};
