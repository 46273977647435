/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { withRouter, RouteComponentProps } from 'react-router';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

import DoubleIndicatorTrendChart from 'src/components/EntityPage/TrendChart/DoubleIndicatorTrendChart';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import Widget from 'src/components/EntityPage/Widget';
import { ChevronIcon } from 'src/components/SvgIcons';
import { withPush } from 'src/utils/hoc';
import { PushFn } from 'src/types/application/types';
import ReduxStore from 'src/types/store/reduxStore';
import { WidgetProps } from 'src/types/application/widgetTypes';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { RangeFilter, TermFilter, Conditions } from 'sl-api-connector/types';
import {
  AdManagerKeyMetricsColumn,
  AdManagerKeyMetricWidget,
  buildTrendWidgetConfig
} from 'src/components/Layout/LayoutUtil';
import ComponentInView from 'src/components/common/ComponentInView/ComponentInView';
import { buildAggregations } from 'src/components/AdManager/Search';
import { disabledWeekly } from 'src/components/EntityPage/Renderer/util';
import {
  getESBodyOverridesForParentPlatform,
  modifyESQuery
} from 'src/components/AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';
import { getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';
import { shouldShowCriteo } from 'src/utils/app';

const trendChartGroupByOpt = [
  { name: 'dayId', displayName: 'Daily' },
  { name: 'weekId', displayName: 'Weekly' }
];

const pivotByIndexName = (keyMetricsColumns: AdManagerKeyMetricsColumn[]) => {
  const result = new Map();

  keyMetricsColumns.forEach((keyMetricsColumn: AdManagerKeyMetricsColumn) => {
    const { widgets } = keyMetricsColumn;
    widgets.forEach((keyMetricWidget: AdManagerKeyMetricWidget) => {
      if (result.has(keyMetricWidget.data.indexName)) {
        result.get(keyMetricWidget.data.indexName).push(keyMetricWidget.data);
      } else {
        result.set(keyMetricWidget.data.indexName, [keyMetricWidget.data]);
      }
    });
  });
  return result;
};

const SingleColumn = ({
  keyMetricsColumn,
  selectedMetric
}: {
  keyMetricsColumn: AdManagerKeyMetricsColumn;
  selectedMetric: any;
}) => {
  const { widgets } = keyMetricsColumn;
  return (
    <div className="column_container">
      {widgets.map((widget: AdManagerKeyMetricWidget, idx: number) => {
        const { CustomComponent } = widget;
        if (CustomComponent) {
          return <CustomComponent key={idx} widget={widget} selectedMetric={selectedMetric} />;
        } else {
          return null;
        }
      })}
    </div>
  );
};

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, [
    'mainTimePeriod',
    'comparisonTimePeriod',
    'entitySearchService',
    'retailer',
    'app',
    'adScores',
    'user'
  ]),
  mainEntity: state.entityService.mainEntity,
  adScores: state.adScores
});

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

const SingleLegendContainer: React.FC<
  { fetchEntityMetrics: Function; push: PushFn } & RouteComponentProps &
    WidgetProps &
    ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    retailer,
    app,
    comparisonTimePeriod,
    mainTimePeriod,
    fetchEntityMetrics,
    mainEntity,
    widget,
    queryConditions,
    location,
    comparisonConfig,
    user,
    queryParams
  } = props;

  const [selectedMetric, setSelectedMetric] = useState(null);
  const [selectedTrendGroupBy, setSelectedTrendGroupBy] = useState(trendChartGroupByOpt[0]);

  const indexNameAdOptimizationHistory = 'adOptimizationHistory';

  const selectedMetricIndex = _get(selectedMetric, ['data', 'indexName']);
  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);
  const cloneConditions: Conditions = _cloneDeep(queryConditions);
  if (
    selectedMetricIndex === 'adCampaignDailyMetrics' &&
    !['bidSavings', 'costsSaved', 'hoursSaved', 'changeAction'].includes(_get(comparisonConfig, 'metricName'))
  ) {
    cloneConditions.termFilters.push({
      fieldName: 'isProjected',
      condition: 'must',
      values: [0]
    });
  }

  const handleTrendGroupByChange = (event: any) => {
    const fieldName = event.target.value;
    const newField = trendChartGroupByOpt.find((item) => item.name === fieldName);
    setSelectedTrendGroupBy(newField);
  };

  const titleText = _get(selectedMetric, ['data', 'metricField', 'displayName'], '');

  const showComparisonGraph = useMemo(() => {
    const timePeriodId = props.mainTimePeriod.id;
    const comparisonTimePeriodId = props.comparisonTimePeriod.id;
    const selectedTrendName = selectedTrendGroupBy.name;
    const excludeTimePeriods = ['1w', '4w', '13w', '26w', '52w', 'ytd', 'cd'];

    // Remove the comparison line for Daily aggregation for the following time periods:
    // All weekly, YTD, CD
    if (
      comparisonTimePeriodId === 'prior-year' &&
      selectedTrendName === 'dayId' &&
      excludeTimePeriods.includes(timePeriodId)
    ) {
      return false;
    }

    // Always show for PY or weekly chart
    if (comparisonTimePeriodId !== 'prior-period' || selectedTrendName === 'weekId') {
      return true;
    }

    if (selectedTrendName === 'dayId' && timePeriodId === 'cd') {
      return false;
    }
    // For PP show only for those ranges
    return ['mtd', 'cd'].includes(timePeriodId);
  }, [props.comparisonTimePeriod.id, props.mainTimePeriod.id, selectedTrendGroupBy.name]);

  const buildChartWidget = () => {
    if (_isEmpty(selectedMetric)) {
      return null;
    }

    const fieldName = selectedMetric.data.metricField.name;
    const { indexName } = selectedMetric.data;
    const weekIdField = INDEX_FIELDS.getField(app.name, indexName, 'weekId', mainEntity.type);

    return buildTrendWidgetConfig(app, indexName, mainEntity, selectedTrendGroupBy.name, [fieldName], weekIdField, {
      name: `summaryMainChart`,
      CustomComponent: DoubleIndicatorTrendChart,
      view: {
        chartPropsOverride: {
          chart: {
            // marginTop: 40,
            height: 500
          },
          legend: { enabled: true },
          subtitle: {
            text: '',
            x: -10,
            style: {
              height: '51px'
            }
          },
          xAxis: {
            type: 'datetime'
          },
          title: { x: -10, text: titleText },
          exporting: {
            enabled: true,
            showCompare: true
          }
        },
        showOnlyMainSeries: !showComparisonGraph,
        container: { style: { height: 400, marginBottom: 20 } }
      }
    });
  };
  const searchParamsUrl = new URLSearchParams(location.search);

  const chartWidget =
    searchParamsUrl.get('adSummaryMetric') &&
    selectedMetric &&
    searchParamsUrl.get('adSummaryMetric') !== selectedMetric.data.metricField.name
      ? null
      : buildChartWidget();

  const { wr, edid, sdid } = queryParams;

  const isDisableWeekly = disabledWeekly(wr, sdid, edid);

  useDeepCompareEffect(() => {
    if (isDisableWeekly && selectedTrendGroupBy.name === 'weekId') {
      setSelectedTrendGroupBy(trendChartGroupByOpt[0]);
    }
    const searchParams = new URLSearchParams(location.search);
    const { keyMetricsColumns } = widget.data;
    const defaultSelectedField = isAdAuditUser ? 'changeAction' : keyMetricsColumns[0].widgets[1].name;
    const currentSelectedFieldName = searchParams.get('adSummaryMetric') || defaultSelectedField;
    keyMetricsColumns.forEach((column: any) => {
      column.widgets.forEach((item: any) => {
        if (item.data.metricField.name === currentSelectedFieldName) {
          setSelectedMetric(item);
        }
      });
    });
  }, [widget, location]);

  useDeepCompareEffect(() => {
    const metricsByIndexName = pivotByIndexName(widget.data.keyMetricsColumns);
    const fetchEntityMetricsPromises: any[] = [];

    const queryConditionsForOptimizationIndex: Conditions = _cloneDeep(queryConditions);
    const isProjectedConditions: Conditions = _cloneDeep(queryConditions);
    isProjectedConditions.termFilters.push({
      fieldName: 'isProjected',
      condition: 'must',
      values: [0]
    });

    if (
      queryConditionsForOptimizationIndex.termFilters.filter((x: TermFilter) => x.fieldName === 'targetingText')
        .length > 0
    ) {
      queryConditionsForOptimizationIndex.termFilters
        .filter((x: TermFilter) => x.fieldName === 'targetingText')
        .forEach((x: TermFilter) => {
          x.fieldName = 'searchKeyword';
        });
    }
    const hourSaveConditions = _cloneDeep(queryConditionsForOptimizationIndex);
    const hourSaveConditionFields = ['automationV2', 'scheduledAction'];
    if (retailer.id === '63' || retailer.id === '2' || retailer.id === '25') {
      hourSaveConditionFields.push('manualInAdManager');
    }
    hourSaveConditions.termFilters.push({
      fieldName: 'changeSource',
      condition: 'must',
      values: hourSaveConditionFields
    });
    metricsByIndexName.forEach((metrics) => {
      const fieldsByGroupByFieldName = new Map();
      metrics
        .filter((metric) => !metric.adScoreName)
        .forEach(({ groupByField, metricField }) => {
          if (!fieldsByGroupByFieldName.get(groupByField.name)) {
            fieldsByGroupByFieldName.set(groupByField.name, [metricField]);
          } else {
            fieldsByGroupByFieldName.get(groupByField.name).push(metricField);
          }
        });
      fieldsByGroupByFieldName.forEach((fields, groupByFieldName) => {
        const aggregations = buildAggregations(fields);
        const [{ aggregations: aggregationFields, indexName, derivations }] = aggregations;

        const mainTimePeriodRangeFilters: RangeFilter[] = [
          {
            fieldName: 'dayId',
            minValue: mainTimePeriod.startDayId,
            maxValue: mainTimePeriod.endDayId
          }
        ];

        const comparisonTimePeriodRangeFilters: RangeFilter[] = [
          {
            fieldName: 'dayId',
            minValue: comparisonTimePeriod.startDayId,
            maxValue: comparisonTimePeriod.endDayId
          }
        ];

        const mainEntitySearchRequestOverrides = [
          {
            indexName,
            doAggregation: true,
            aggregations: [
              {
                aggregationFields:
                  isAdAuditUser && indexName === 'adOptimizationHistory'
                    ? aggregationFields.filter((field) =>
                        ['changeAction', 'bidSavings'].includes(field.aggregateByFieldName)
                      )
                    : aggregationFields,
                conditions: {
                  termFilters: [{ fieldName: 'retailerId', values: [+retailer.id] }],
                  rangeFilters: mainTimePeriodRangeFilters
                },
                comparisonRangeFilters: comparisonTimePeriodRangeFilters,
                groupByFieldName
              }
            ],
            conditions: indexName === indexNameAdOptimizationHistory ? hourSaveConditions : isProjectedConditions,
            pageSize: 3000,
            shouldCache: false,
            useAggregationSort: false,
            processDocuments: true
          }
        ];

        const updatedConditions = {
          ...mainEntitySearchRequestOverrides[0].conditions,
          rangeFilters: mainTimePeriodRangeFilters
        };
        mainEntitySearchRequestOverrides[0].conditions = updatedConditions;

        if (derivations && derivations.length > 0 && aggregations.length > 0) {
          mainEntitySearchRequestOverrides.push({
            indexName: aggregations[1].indexName,
            doAggregation: true,
            aggregations: [
              {
                aggregationFields: aggregations[1].aggregations,
                conditions: {
                  termFilters: [{ fieldName: 'retailerId', values: [+retailer.id] }],
                  rangeFilters: mainTimePeriodRangeFilters
                },
                comparisonRangeFilters: comparisonTimePeriodRangeFilters,
                groupByFieldName
              }
            ],
            conditions:
              aggregations[1].indexName === indexNameAdOptimizationHistory ? hourSaveConditions : isProjectedConditions,
            pageSize: 3000,
            shouldCache: false,
            useAggregationSort: false,
            processDocuments: true
          });
        }

        let copyOfOVerrides = _cloneDeep(mainEntitySearchRequestOverrides);

        const parentPlatform = getParentPlatform();

        if (shouldShowCriteo() && parentPlatform && parentPlatform === PARENT_PLATFORMS.CRITEO) {
          let customRules = [
            {
              action: 'add',
              path: ['conditions', 'termFilters'],
              newObj: {
                fieldName: 'parentPlatform',
                condition: 'must',
                values: [parentPlatform]
              }
            },
            {
              action: 'add',
              path: ['aggregations', '[0]', 'conditions', 'termFilters'],
              newObj: {
                fieldName: 'parentPlatform',
                condition: 'must',
                values: [parentPlatform]
              }
            }
          ];

          // for all retailers remove rid
          if (retailer.id === '0') {
            customRules = [
              ...customRules,
              {
                action: 'remove',
                path: ['aggregations', '[0]', 'conditions', 'termFilters'],
                conditionKey: 'fieldName',
                conditionValue: 'retailerId'
              },
              {
                action: 'remove',
                path: ['conditions', 'termFilters'],
                conditionKey: 'fieldName',
                conditionValue: 'retailerId'
              },
              {
                action: 'update',
                path: ['retailerId'],
                newObj: 999999
              },
              {
                action: 'update',
                path: ['aggregations', '[0]', 'groupByFieldName'],
                newObj: 'parentPlatform'
              }
            ];
          }

          const mutateOverridesForCriteo = mainEntitySearchRequestOverrides;
          const newOverride = [];

          mutateOverridesForCriteo.forEach((_, indx) => {
            const mutatedQuery = modifyESQuery(
              copyOfOVerrides[indx],
              getESBodyOverridesForParentPlatform(parentPlatform, customRules)
            );
            newOverride.push(mutatedQuery);
          });

          copyOfOVerrides = newOverride;
        }

        fetchEntityMetricsPromises.push(
          fetchEntityMetrics(
            `single_legend_container_${indexName}_${groupByFieldName}`,
            {
              entity: mainEntity,
              retailer,
              app,
              indexName,
              derivedFields: derivations
            },
            copyOfOVerrides
          )
        );

        if (indexName === 'adOptimizationHistory') {
          const optimazationRequestOverrides = [
            {
              indexName,
              doAggregation: true,
              aggregations: [
                {
                  aggregationFields: aggregationFields.filter((field) =>
                    ['changeAction', 'bidSavings'].includes(field.aggregateByFieldName)
                  ),
                  conditions: {
                    termFilters: [
                      { fieldName: 'retailerId', values: [+retailer.id] },
                      ...(isAdAuditUser
                        ? [
                            {
                              fieldName: 'changeSource',
                              condition: 'must',
                              values: ['external']
                            }
                          ]
                        : [])
                    ],
                    rangeFilters: mainTimePeriodRangeFilters
                  },
                  comparisonRangeFilters: comparisonTimePeriodRangeFilters,
                  groupByFieldName
                }
              ],
              conditions:
                indexName === indexNameAdOptimizationHistory
                  ? queryConditionsForOptimizationIndex
                  : isProjectedConditions,
              pageSize: 3000,
              useAggregationSort: false,
              processDocuments: true
            }
          ];

          const updatedOptimizationConditions = {
            ...optimazationRequestOverrides[0].conditions,
            rangeFilters: mainTimePeriodRangeFilters
          };
          optimazationRequestOverrides[0].conditions = updatedOptimizationConditions;

          fetchEntityMetricsPromises.push(
            fetchEntityMetrics(
              `single_legend_container_optimization`,
              {
                entity: mainEntity,
                retailer,
                app,
                indexName,
                derivedFields: derivations
              },
              optimazationRequestOverrides
            )
          );

          if (isAdAuditUser) {
            // calculate total bid and budget changes
            const bidChangesRequestOverrides = [
              {
                indexName,
                doAggregation: true,
                aggregations: [
                  {
                    aggregationFields: aggregationFields.filter((field) =>
                      ['changeAction', 'bidChanges'].includes(field.aggregateByFieldName)
                    ),
                    conditions: {
                      termFilters: [
                        { fieldName: 'retailerId', values: [+retailer.id] },
                        {
                          fieldName: 'changeSource',
                          condition: 'must',
                          values: ['external']
                        },
                        {
                          fieldName: 'changeType',
                          condition: 'must',
                          values: ['bid']
                        }
                      ],
                      rangeFilters: mainTimePeriodRangeFilters
                    },
                    comparisonRangeFilters: comparisonTimePeriodRangeFilters,
                    groupByFieldName
                  }
                ],
                conditions:
                  indexName === indexNameAdOptimizationHistory
                    ? queryConditionsForOptimizationIndex
                    : isProjectedConditions,
                pageSize: 3000,
                processDocuments: true
              }
            ];
            const budgetChangesRequestOverrides = [
              {
                indexName,
                doAggregation: true,
                aggregations: [
                  {
                    aggregationFields: aggregationFields.filter((field) =>
                      ['changeAction', 'budgetChanges'].includes(field.aggregateByFieldName)
                    ),
                    conditions: {
                      termFilters: [
                        { fieldName: 'retailerId', values: [+retailer.id] },
                        {
                          fieldName: 'changeSource',
                          condition: 'must',
                          values: ['external']
                        },
                        {
                          fieldName: 'changeType',
                          condition: 'must',
                          values: ['budget']
                        }
                      ],
                      rangeFilters: mainTimePeriodRangeFilters
                    },
                    comparisonRangeFilters: comparisonTimePeriodRangeFilters,
                    groupByFieldName
                  }
                ],
                conditions:
                  indexName === indexNameAdOptimizationHistory
                    ? queryConditionsForOptimizationIndex
                    : isProjectedConditions,
                pageSize: 3000,
                processDocuments: true
              }
            ];

            fetchEntityMetricsPromises.push(
              fetchEntityMetrics(
                `single_legend_container_optimization_bidChanges`,
                {
                  entity: mainEntity,
                  retailer,
                  app,
                  indexName,
                  derivedFields: derivations
                },
                bidChangesRequestOverrides
              )
            );

            fetchEntityMetricsPromises.push(
              fetchEntityMetrics(
                `single_legend_container_optimization_budgetChanges`,
                {
                  entity: mainEntity,
                  retailer,
                  app,
                  indexName,
                  derivedFields: derivations
                },
                budgetChangesRequestOverrides
              )
            );
          }
        }
      });
    });
  }, [mainTimePeriod.displayName, comparisonTimePeriod.displayName, retailer.id, mainEntity]);

  return (
    <div>
      <ComponentInView id="metrics_table">
        <div className="single_legend_container" style={{ marginBottom: 120 }}>
          {widget.data.keyMetricsColumns.map((keyMetricsColumn: AdManagerKeyMetricsColumn, idx: number) => {
            return <SingleColumn key={idx} keyMetricsColumn={keyMetricsColumn} selectedMetric={selectedMetric} />;
          })}
        </div>
      </ComponentInView>

      <ComponentInView id="metric_trend_chart">
        <div className="summary_chart_section">
          {chartWidget && (
            <>
              <Widget {...props} noInnerContainer entityConditions={cloneConditions} widget={chartWidget} />
              <div className="groupby">
                <Select
                  variant="standard"
                  value={selectedTrendGroupBy.name}
                  // The type given by Material UI disagrees with the type of the actual event.
                  onChange={(event) => handleTrendGroupByChange(event)}
                  input={<OutlinedInput labelWidth={0} id="outlined-age-simple" />}
                  IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
                  className="retailer-dropdown"
                >
                  {trendChartGroupByOpt.map((val) => (
                    <MenuItem key={val.name} value={val.name} disabled={val.name === 'weekId' && isDisableWeekly}>
                      {val.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </>
          )}
        </div>
      </ComponentInView>
    </div>
  );
};

export default withPush(connect(mapStateToProps, mapDispatchToProps)(withRouter(SingleLegendContainer)));
