import { Entity } from 'sl-api-connector';
import _get from 'lodash/get';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import AdPerformanceBarChart from 'src/components/AdManager/AdPerformanceBarChart';
import { buildAdvertisingTopEntitiesChart } from 'src/components/Layout/util/DynamicAdvertisingWidgets';
import 'src/components/AdManager/PortfolioSummaryInfo.scss';
import ReduxStore from 'src/types/store/reduxStore';
import { PageLayout } from 'src/types/application/widgetTypes';
import { ExportIcon } from 'src/components/SvgIcons';
import PageContextMenu from 'src/components/PageContextMenu';
import { AdManagerKeyMetricsColumn, buildWidgetGroup } from 'src/components/Layout/LayoutUtil';
import SingleLegendContainer from 'src/components/AdManager/SingleLegendContainer';
import ScoreBarChart from 'src/components/AdManager/ScoreBarChart';
import SingleLegend from 'src/components/AdManager/SingleLegend';
import {
  buildTabbedSearchResultGridWidget,
  buildAdvancedSearchSideBarWidget
} from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';
import { EventBus } from 'src/types/utils';
import { buildAdManagerHeaderWidget } from 'src/components/Layout/Advertising/AdManagerPageLayout/LayoutUtil';
import { validIndexForIncrementality } from 'src/utils/incrementalityColumn';
import { store } from 'src/main';
import { checkIsAllowedParentPlatform, getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';
import { shouldShowCriteo } from 'src/utils/app';

export const getLayout = ({
  app,
  retailer,
  entity,
  eventBus
}: {
  app: ReduxStore['app'];
  retailer: ReduxStore['retailer'];
  entity: Entity;
  eventBus: EventBus;
}): Partial<PageLayout> => {
  const { user } = store.getState();
  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);
  const isCriteo = getParentPlatform() === PARENT_PLATFORMS.CRITEO;
  const isCriteoAllowed = shouldShowCriteo();

  let adCampaignAdGroupProductTargetDailyMetrics;

  switch (entity.type) {
    case 'adGroup':
      adCampaignAdGroupProductTargetDailyMetrics = 'adCampaignAdGroupDailyMetrics';
      break;
    case 'product':
    case 'brand':
      adCampaignAdGroupProductTargetDailyMetrics = 'adCampaignAdGroupProductDailyMetrics';
      break;
    case 'adTarget':
      adCampaignAdGroupProductTargetDailyMetrics = 'adCampaignAdGroupTargetDailyMetrics';
      break;
    default: {
      if (isCriteo && retailer.id !== '0' && isCriteoAllowed) {
        adCampaignAdGroupProductTargetDailyMetrics = 'adCampaignAdGroupDailyMetrics';
      } else {
        adCampaignAdGroupProductTargetDailyMetrics = 'adCampaignDailyMetrics';
      }
    }
  }

  const targetIndexName =
    entity.type === 'product' ? 'adCampaignAdGroupProductTargetDailyMetrics' : 'adCampaignAdGroupTargetDailyMetrics';

  // if (['client', 'adEntity'].includes(entity.type)) {
  //   adCampaignAdGroupProductTargetDailyMetrics = 'adEntityDailyMetrics';
  // } else if (entity.type === 'adPortfolio') {
  //   adCampaignAdGroupProductTargetDailyMetrics = 'adPortfolioDailyMetrics';
  // } else if (entity.type === 'adCampaign') {
  //   adCampaignAdGroupProductTargetDailyMetrics = 'adCampaignDailyMetrics';
  // } else if (entity.type === 'product') {
  //   adCampaignAdGroupProductTargetDailyMetrics = 'adCampaignAdGroupProductDailyMetrics';
  // }
  if (entity.type === 'segment') {
    const querys = {
      ...JSON.parse(entity.query)
    };

    if (querys.segmentType) {
      if (querys.segmentType === 'stacklineSku') {
        adCampaignAdGroupProductTargetDailyMetrics = 'adCampaignAdGroupProductDailyMetrics';
      } else if (querys.segmentType === 'targetingText') {
        adCampaignAdGroupProductTargetDailyMetrics = 'adCampaignAdGroupTargetDailyMetrics';
      }
    }
  }

  const indexNameAdOptimizationHistory = 'adOptimizationHistory';

  const HeaderWidget = buildAdManagerHeaderWidget(entity, 'Ad Score');

  const groupByFieldRetailerId = INDEX_FIELDS.getField(
    app.name,
    adCampaignAdGroupProductTargetDailyMetrics,
    'retailerId',
    entity.type
  );

  const groupByFieldCampaignId = INDEX_FIELDS.getField(
    app.name,
    adCampaignAdGroupProductTargetDailyMetrics,
    'campaignId',
    entity.type
  );

  const groupByFieldParentPlatform = INDEX_FIELDS.getField(
    app.name,
    adCampaignAdGroupProductTargetDailyMetrics,
    'parentPlatform',
    entity.type
  );

  const groupByFieldToUse =
    isCriteo && shouldShowCriteo() && retailer.id === '0' ? groupByFieldParentPlatform : groupByFieldRetailerId;

  const keyMetricsColumns: AdManagerKeyMetricsColumn[] = [
    {
      widgets: [
        {
          CustomComponent: ScoreBarChart,
          name: 'reachAdScore',
          view: {
            name: 'reachAdScore',
            title: 'Reach'
          },
          data: {
            adScoreName: 'reachAdScore',
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'clickThroughRate',
                entity.type,
                groupByFieldCampaignId.name
              ),
              displayName: 'Reach'
            },
            groupByField: groupByFieldCampaignId
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'impressions',
          view: {
            name: 'impressions',
            title: 'AD IMPRESSIONS'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'impressions',
                entity.type,
                groupByFieldToUse.name
              ),
              displayName: 'Ad Impressions'
            },
            groupByField: groupByFieldToUse
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'clicks',
          view: {
            name: 'clicks',
            title: 'AD CLICKS'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'clicks',
                entity.type,
                groupByFieldToUse.name
              )
            },
            groupByField: groupByFieldToUse
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'clickThroughRate',
          view: {
            name: 'clickThroughRate',
            title: 'CTR %'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'clickThroughRate',
                entity.type,
                groupByFieldToUse.name
              )
            },
            groupByField: groupByFieldToUse
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'targetingText',
          view: {
            name: 'targetingText',
            title: 'TARGETS'
          },
          data: {
            indexName: targetIndexName,
            metricField: {
              ...INDEX_FIELDS.getField(app.name, targetIndexName, 'targetingText', entity.type, groupByFieldToUse.name),
              displayName: 'Targets'
            },
            groupByField: groupByFieldToUse
          }
        }
      ]
    },
    {
      widgets: [
        {
          CustomComponent: ScoreBarChart,
          name: 'adSpendAdScore',
          view: {
            name: 'adSpendAdScore',
            title: 'Ad Spend'
          },
          data: {
            adScoreName: 'adSpendAdScore',
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'costPerClick',
                entity.type,
                groupByFieldCampaignId.name
              )
            },
            groupByField: groupByFieldCampaignId
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'adSpend',
          view: {
            name: 'adSpend',
            title: 'AD SPEND'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'spend',
                entity.type,
                groupByFieldToUse.name
              )
            },
            groupByField: groupByFieldToUse
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'costPerClick',
          view: {
            name: 'costPerClick',
            title: 'CPC'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'costPerClick',
                entity.type,
                groupByFieldToUse.name
              ),
              displayName: 'CPC'
            },
            groupByField: groupByFieldToUse
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'costPerAcquisition',
          view: {
            name: 'costPerAcquisition',
            title: 'CPA'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'costPerAcquisition',
                entity.type,
                groupByFieldToUse.name
              ),
              displayName: 'CPA'
            },
            groupByField: groupByFieldToUse
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'advertisingCostOfSale',
          view: {
            name: 'advertisingCostOfSale',
            title: 'ACOS'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'advertisingCostOfSale',
                entity.type,
                groupByFieldToUse.name
              )
            },
            groupByField: groupByFieldToUse
          }
        }
      ]
    },
    {
      widgets: [
        {
          CustomComponent: ScoreBarChart,
          name: 'adSalesAdScore',
          view: {
            name: 'adSalesAdScore',
            title: 'Ad Sales'
          },
          data: {
            adScoreName: 'adSalesAdScore',
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'returnOnAdSpend',
                entity.type,
                groupByFieldCampaignId.name
              ),
              displayName: 'Ad Sales'
            },
            groupByField: groupByFieldCampaignId
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'adSales',
          view: {
            name: 'adSales',
            title: 'AD SALES'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'sales',
                entity.type,
                groupByFieldToUse.name
              )
            },
            groupByField: groupByFieldToUse
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'returnOnAdSpend',
          view: {
            name: 'returnOnAdSpend',
            title: 'ROAS'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'returnOnAdSpend',
                entity.type,
                groupByFieldToUse.name
              ),
              displayName: `ROAS`
            },
            groupByField: groupByFieldToUse
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'conversionRate',
          view: {
            name: 'conversionRate',
            title: 'CONVERSION RATE'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'conversionRate',
                entity.type,
                groupByFieldToUse.name
              )
            },
            groupByField: groupByFieldToUse
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'adUnitsSoldOrders',
          view: {
            name: 'adUnitsSold',
            title: retailer.id === '63' ? 'UNITS' : 'ORDERS'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'conversions',
                entity.type,
                groupByFieldToUse.name
              ),
              displayName: retailer.id === '63' ? 'Units' : 'Orders'
            },
            groupByField: groupByFieldToUse
          }
        }
      ]
    }
  ];
  const showOptimizations = checkIsAllowedParentPlatform('keyMetricsPageOptimizations');
  const isAllowedBudgetPacingWidget = checkIsAllowedParentPlatform('overviewBudgetPacing');

  if (showOptimizations) {
    keyMetricsColumns.push({
      widgets: [
        {
          CustomComponent: ScoreBarChart,
          name: 'optimizationsAdScore',
          view: {
            name: 'optimizationsAdScore',
            title: 'Optimizations'
          },
          data: {
            adScoreName: 'optimizationAdScore',
            indexName: indexNameAdOptimizationHistory,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                indexNameAdOptimizationHistory,
                'campaignId',
                entity.type,
                groupByFieldCampaignId.name
              ),
              displayName: 'Optimizations'
            },
            groupByField: groupByFieldCampaignId
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'bidSavings',
          view: {
            name: 'bidSavings',
            title: 'BID SAVINGS'
          },
          data: {
            indexName: indexNameAdOptimizationHistory,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                indexNameAdOptimizationHistory,
                'bidSavings',
                entity.type,
                groupByFieldToUse.name
              )
            },
            groupByField: groupByFieldToUse
          }
        },
        isAdAuditUser
          ? {
              CustomComponent: SingleLegend,
              name: 'bidChanges',
              view: {
                name: 'bidChanges',
                title: 'BID OPTIMIZATIONS'
              },
              data: {
                indexName: indexNameAdOptimizationHistory,
                metricField: {
                  ...INDEX_FIELDS.getField(
                    app.name,
                    indexNameAdOptimizationHistory,
                    'bidChanges',
                    entity.type,
                    groupByFieldToUse.name
                  )
                },
                groupByField: groupByFieldToUse
              }
            }
          : {
              CustomComponent: SingleLegend,
              name: 'costsSaved',
              view: {
                name: 'costsSaved',
                title: 'LABOR SAVINGS'
              },
              data: {
                indexName: indexNameAdOptimizationHistory,
                metricField: {
                  ...INDEX_FIELDS.getField(
                    app.name,
                    indexNameAdOptimizationHistory,
                    'costsSaved',
                    entity.type,
                    groupByFieldToUse.name
                  )
                },
                groupByField: groupByFieldToUse
              }
            },
        isAdAuditUser
          ? {
              CustomComponent: SingleLegend,
              name: 'budgetChanges',
              view: {
                name: 'budgetChanges',
                title: 'BUDGET OPTIMIZATIONS'
              },
              data: {
                indexName: indexNameAdOptimizationHistory,
                metricField: {
                  ...INDEX_FIELDS.getField(
                    app.name,
                    indexNameAdOptimizationHistory,
                    'budgetChanges',
                    entity.type,
                    groupByFieldToUse.name
                  )
                },
                groupByField: groupByFieldToUse
              }
            }
          : {
              CustomComponent: SingleLegend,
              name: 'hoursSaved',
              view: {
                name: 'hoursSaved',
                title: 'HOUR SAVINGS'
              },
              data: {
                indexName: indexNameAdOptimizationHistory,
                metricField: {
                  ...INDEX_FIELDS.getField(
                    app.name,
                    indexNameAdOptimizationHistory,
                    'hoursSaved',
                    entity.type,
                    groupByFieldToUse.name
                  )
                },
                groupByField: groupByFieldToUse
              }
            },
        {
          CustomComponent: SingleLegend,
          name: 'optimizationsCount',
          view: {
            name: 'optimizationsCount',
            title: `${isAdAuditUser ? 'TOTAL ' : ''}OPTIMIZATIONS`
          },
          data: {
            indexName: indexNameAdOptimizationHistory,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                indexNameAdOptimizationHistory,
                'changeAction',
                entity.type,
                groupByFieldToUse.name
              )
            },
            groupByField: groupByFieldToUse
          }
        }
      ]
    });
  }

  if (showOptimizations && validIndexForIncrementality(retailer.id, 'dev')) {
    keyMetricsColumns.splice(3, 0, {
      widgets: [
        {
          CustomComponent: ScoreBarChart,
          name: 'adSalesAdScore',
          view: {
            name: 'adSalesAdScore',
            title: 'Incrementality'
          },
          data: {
            adScoreName: 'adSalesAdScore',
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'returnOnAdSpend',
                entity.type,
                groupByFieldCampaignId.name
              )
            },
            groupByField: groupByFieldCampaignId
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'brandTotalClicksIncrementalSales',
          view: {
            name: 'brandTotalClicksIncrementalSales',
            title: 'INCREMENTAL SALES'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'brandTotalClicksIncrementalSales',
                entity.type,
                groupByFieldToUse.name
              )
            },
            groupByField: groupByFieldToUse
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'brandTotalClicksIncrementalROAS',
          view: {
            name: 'brandTotalClicksIncrementalROAS',
            title: 'INCREMENTAL ROAS'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'brandTotalClicksIncrementalROAS',
                entity.type,
                groupByFieldToUse.name
              )
            },
            groupByField: groupByFieldToUse
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'brandTotalClicksIncrementalClicks',
          view: {
            name: 'brandTotalClicksIncrementalClicks',
            title: 'INCREMENTAL CLICKS'
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'brandTotalClicksIncrementalClicks',
                entity.type,
                groupByFieldToUse.name
              )
            },
            groupByField: groupByFieldToUse
          }
        },
        {
          CustomComponent: SingleLegend,
          name: 'avgIncrementality',
          view: {
            name: 'avgIncrementality',
            title: `INCREMENTALITY`
          },
          data: {
            indexName: adCampaignAdGroupProductTargetDailyMetrics,
            metricField: {
              ...INDEX_FIELDS.getField(
                app.name,
                adCampaignAdGroupProductTargetDailyMetrics,
                'avgIncrementality',
                entity.type,
                groupByFieldToUse.name
              ),
              displayName: `Incrementality`
            },
            groupByField: groupByFieldToUse
          }
        }
      ]
    });
  }

  const isRelevantEntityType = ['product', 'adGroup', 'segment', 'brand'].includes(entity.type);

  // Pop the last column if either "shouldShowCriteo" is false, or it's true but "isCriteo" is false
  if (isRelevantEntityType && (!shouldShowCriteo || (shouldShowCriteo && !isCriteo))) {
    keyMetricsColumns.pop();
  }

  const AdManagerColumnWidget = {
    CustomComponent: SingleLegendContainer,
    name: 'adManagerColumnWidget',
    view: {
      container: {
        style: { marginBottom: 120 }
      }
    },
    data: {
      keyMetricsColumns
    }
  };

  const weekIdField = INDEX_FIELDS.getField(app.name, adCampaignAdGroupProductTargetDailyMetrics, 'weekId');

  const DailyPerformanceBarChartWidget = {
    CustomComponent: AdPerformanceBarChart,
    name: 'adPerformanceBarChart',
    view: {
      name: 'adPerformanceBarChart',
      container: {
        style: { marginTop: 62, marginBottom: 120 }
      }
    },
    data: {}
  };

  const widgets = [];
  widgets.push(HeaderWidget);
  widgets.push(AdManagerColumnWidget);
  if (isAllowedBudgetPacingWidget && !['product', 'adTarget', 'adGroup', 'segment', 'brand'].includes(entity.type)) {
    widgets.push(DailyPerformanceBarChartWidget);
  }
  if (!['segment'].includes(entity.type)) {
    const topEntityWidget = buildAdvertisingTopEntitiesChart({
      indexName: 'adCampaignAdGroupProductTargetDailyMetrics',
      entity,
      app,
      weekIdField
    });
    topEntityWidget.view.chartPropsOverride.subtitle = {
      text: ''
    };
    topEntityWidget.data.lazyLoad = true;
    widgets.push(topEntityWidget);
  }
  const advancedSearchSideBarWidget = buildAdvancedSearchSideBarWidget();
  advancedSearchSideBarWidget.view.hideByDefault = true;
  advancedSearchSideBarWidget.view.container.style = {
    ...advancedSearchSideBarWidget.view.container.style,
    paddingTop: 0
  };
  advancedSearchSideBarWidget.data.useInternalState = true;

  const hideTab = [];
  if (
    _get(entity, ['extendedAttributes', 'retailerId']) === 2 &&
    _get(entity, ['type']) === 'adCampaign' &&
    _get(entity, ['extendedAttributes', 'amsApiModel', 'targetingType']) === 'auto'
  ) {
    hideTab.push('Targets');
  }
  const tabbedSearchResultGridWidget = buildTabbedSearchResultGridWidget({
    app,
    entity,
    eventBus,
    searchResultsGridWidgetDataOverride: { useInternalState: true },
    hideTab
  });

  const searchResultGridWidgetGroupWidget = buildWidgetGroup(
    [advancedSearchSideBarWidget, tabbedSearchResultGridWidget],
    {
      view: {
        container: {
          style: {
            width: '100%',
            display: 'flex'
          },
          className: 'search-result-grid-widget-group-widget'
        }
      }
    }
  );

  widgets.push(searchResultGridWidgetGroupWidget);

  const subMenuItems = [];

  subMenuItems.push({
    icon: ExportIcon,
    text: 'Add to Download List',
    eventName: 'downloadEntityGrid'
  });

  // subMenuItems.push({
  //   icon: ExportIcon,
  //   text: 'Download Aggregated Data',
  //   eventName: 'downloadAdManagerAggregated'
  // });

  // subMenuItems.push({
  //   icon: DownloadIconBlue,
  //   text: 'Download Unaggregated Data',
  //   eventName: 'downloadAdManagerUnaggregated'
  // });

  widgets.push({
    CustomComponent: PageContextMenu,
    name: 'pageContextMenu',
    view: {
      name: 'pageContextMenu',
      buttons: subMenuItems
    },
    data: {
      appName: app.name
    }
  });
  // }

  return {
    widgets
  };
};
