import React from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server.browser';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _merge from 'lodash/merge';
import _prop from 'lodash/property';
import Paper from '@mui/material/Paper';

const DROPDOWN_CLASS_NAMES = {
  CARET: 'title-category-select-caret',
  DISPLAYED: 'title-category-select-displayed',
  ITEMS: 'title-category-select-items',
  ITEM: 'title-category-select-item',
  HIDDEN: 'title-category-select-items-hidden',
  VISIBLE: 'title-category-select-items-visible'
};

const Dropdown = ({ uniqId, options }) => (
  <Paper
    elevation={2}
    variant="outlined"
    style={{
      position: 'absolute',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
    }}
  >
    <div id={`${uniqId}-items`} className={`${DROPDOWN_CLASS_NAMES.ITEMS} ${DROPDOWN_CLASS_NAMES.HIDDEN}`}>
      {options.map(({ displayName }, i) => (
        <div key={i} className={DROPDOWN_CLASS_NAMES.ITEM}>
          {displayName}
        </div>
      ))}
    </div>
  </Paper>
);

Dropdown.propTypes = {
  uniqId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
};

const TitleCategorySelect = ({ oldTitle, uniqId, options, style, optionalSubtitle }) => {
  const selectedOption = options.find(_prop('isSelected'));
  const currentOptionName = selectedOption ? selectedOption.displayName : options[0].displayName;
  const titleBase = oldTitle.slice(0, oldTitle.indexOf(currentOptionName));

  return (
    <>
      <div style={{ display: 'flex', ...style }}>
        {titleBase}
        <div id={`${uniqId}-dropdown`} className="group-by-dropdown" style={{ cursor: 'pointer' }}>
          <span className="group-by-dropdown-selected">{currentOptionName}</span>
          <i className={DROPDOWN_CLASS_NAMES.CARET} />

          <Dropdown uniqId={uniqId} options={options} />
        </div>
      </div>
      {optionalSubtitle && (
        // eslint-disable-next-line react/no-danger
        <div style={{ fontSize: '14px', paddingTop: '4px' }} dangerouslySetInnerHTML={{ __html: optionalSubtitle }} />
      )}
    </>
  );
};

TitleCategorySelect.propTypes = {
  oldTitle: PropTypes.string.isRequired,
  uniqId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.object,
  optionalSubtitle: PropTypes.string
};

TitleCategorySelect.defaultProps = {
  style: {},
  optionalSubtitle: ''
};

const TitleCategoryMultiSelect = ({
  uniqId1,
  uniqId2,
  options1,
  options2,
  optionalSubtitle,
  hideDropDown,
  hideGroupBy
}) => {
  const currentOption1 = options1.find(_prop('isSelected'));
  const currentOption2 = options2.find(_prop('isSelected'));
  // sometimes the isSelected didn't renew when switch from different page. in this case, give the first value;
  const currentOption1Name = currentOption1 ? currentOption1.displayName : options1[0].displayName;
  const currentOption2Name = currentOption2 ? currentOption2.displayName : options2[0].displayName;

  if (hideDropDown) {
    return <></>;
  }

  const prependedText = _get(currentOption1, 'prependedText', '');
  const groupByTextOverride = _get(currentOption1, 'groupByTextOverride', null);
  const groupByText = groupByTextOverride || ' by ';

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div
          id={`${uniqId1}-dropdown`}
          className="group-by-dropdown"
          style={{ cursor: 'pointer', paddingRight: 14, marginLeft: -7 }}
        >
          <span className="group-by-dropdown-selected">{prependedText}{currentOption1Name}</span>
          <i className={DROPDOWN_CLASS_NAMES.CARET} />

          <Dropdown uniqId={uniqId1} options={options1} />
        </div>
        {!hideGroupBy && (
          <>
            {options2.length > 1 ? (
              <>
                {groupByText}
                <div
                  id={`${uniqId2}-dropdown`}
                  className="group-by-dropdown"
                  style={{ cursor: 'pointer', paddingLeft: 7 }}
                >
                  <span className="group-by-dropdown-selected">{currentOption2Name}</span>
                  <i className={DROPDOWN_CLASS_NAMES.CARET} />

                  <Dropdown uniqId={uniqId2} options={options2} />
                </div>
              </>
            ) : (
              <>
                {' by '}
                <div id={`${uniqId2}-dropdown`} className="group-by-dropdown" style={{ paddingLeft: 7 }}>
                  <span className="group-by-dropdown-selected">{currentOption2Name}</span>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {optionalSubtitle && (
        // eslint-disable-next-line react/no-danger
        <div style={{ fontSize: '14px', paddingTop: '4px' }} dangerouslySetInnerHTML={{ __html: optionalSubtitle }} />
      )}
    </>
  );
};

TitleCategoryMultiSelect.defaultProps = {
  optionalSubtitle: '',
  hideDropDown: false,
  hideGroupBy: false
};

TitleCategoryMultiSelect.propTypes = {
  uniqId1: PropTypes.string.isRequired,
  uniqId2: PropTypes.string.isRequired,
  options1: PropTypes.arrayOf(PropTypes.object).isRequired,
  options2: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionalSubtitle: PropTypes.string,
  hideDropDown: PropTypes.bool,
  hideGroupBy: PropTypes.bool
};

const installDropdownEventListeners = (uniqId, options, eventBus) => {
  const selectElem = document.getElementById(`${uniqId}-dropdown`);
  const dropdownItemsElement = document.getElementById(`${uniqId}-items`);

  const toggleDropdownVisibility = () => {
    dropdownItemsElement.classList.toggle(DROPDOWN_CLASS_NAMES.HIDDEN);
    const isNowVisible = dropdownItemsElement.classList.toggle(DROPDOWN_CLASS_NAMES.VISIBLE);

    // We can't do CSS transitions between `display: none` and `display: block`, so we have to do a manual
    // transition later in order to get the animation.
    if (isNowVisible) {
      setTimeout(() => {
        dropdownItemsElement.classList.add(DROPDOWN_CLASS_NAMES.DISPLAYED);

        // Add an event listener on the document that closes the dropdown if clicking on the background
        // (or anywhere else).
        document.addEventListener(
          'click',
          (evt) => {
            // If this click is on the dropdown toggle itself, ignore it since it will be handled there.
            if (evt.target.closest('.group-by-dropdown')) {
              return;
            }

            toggleDropdownVisibility();
          },
          { once: true }
        );
      });
    } else {
      dropdownItemsElement.classList.remove(DROPDOWN_CLASS_NAMES.DISPLAYED);
    }
  };

  if (selectElem) {
    // Manually an event listener on the created dropdown element to toggle the visibility of the dropdown
    selectElem.onclick = toggleDropdownVisibility;
  }

  if (dropdownItemsElement) {
    dropdownItemsElement.childNodes.forEach((childNode, i) => {
      childNode.onclick = () => {
        const { eventName, eventId } = options[i];
        // `setTimeout` is used here due to the fact that HighCharts has its own event listeners for click events
        // on the HighCharts container.  If we fire the event bus event immediately upon receiving the click event,
        // it will trigger an error in HighCharts.
        //
        // By delaying it until the next turn of the event loop after HighCharts has finished processing its
        // event, we prevent any problems.

        setTimeout(() => eventBus.emit(eventName, eventId));
      };
    });
  }
  // Create event listeners on all of the dropdown items to send the event to change the group by
};

const buildSingleGroupByTitle = (buttons, chartProps, uniqId, eventBus) => {
  const options = buttons.map(({ text, ...option }) => ({ displayName: text, ...option }));

  const oldTitle = chartProps.title.text;
  let optionalSubtitle;
  if (chartProps.subtitleOverride) {
    optionalSubtitle = chartProps.subtitleOverride.displayName;
  }

  const interactiveTitleHtml = ReactDOMServer.renderToString(
    <TitleCategorySelect
      oldTitle={oldTitle}
      uniqId={uniqId}
      options={options}
      style={chartProps.title.dropdownStyle}
      optionalSubtitle={optionalSubtitle}
    />
  );

  const oldLoadCb = _get(chartProps, 'chart.events.load');
  function onChartLoad(...args) {
    installDropdownEventListeners(uniqId, options, eventBus);

    // Call the old load callback if one existed
    if (oldLoadCb) {
      oldLoadCb(...args);
    }
  }

  return { interactiveTitleHtml, onChartLoad };
};

const buildMultiGroupByTitle = (buttons, chartProps, baseUniqId, eventBus) => {
  const uniqId1 = `${baseUniqId}-mainMetric`;
  const uniqId2 = `${baseUniqId}-groupBy`;

  let optionalSubtitle;
  if (chartProps.subtitleOverride) {
    optionalSubtitle = chartProps.subtitleOverride.displayName;
  }

  const hideDropDown = chartProps.hideFieldDropdowns || false;
  const hideGroupBy = chartProps.hideGroupBy || false;

  let options2 = buttons[1];
  const tab = _get(chartProps, 'tab', '');
  const subtab = _get(chartProps, 'subtab', '');
  if (tab === 'traffic' && subtab === 'adClicks') {
    options2 = options2.filter((option) => option.displayName !== 'Day');
  }

  const interactiveTitleHtml = ReactDOMServer.renderToString(
    <TitleCategoryMultiSelect
      uniqId1={uniqId1}
      uniqId2={uniqId2}
      options1={buttons[0]}
      options2={options2}
      optionalSubtitle={optionalSubtitle}
      hideDropDown={hideDropDown}
      hideGroupBy={hideGroupBy}
    />
  );

  const oldLoadCb = _get(chartProps, 'chart.events.load');
  function onChartLoad(...args) {
    [
      [uniqId1, buttons[0]],
      [uniqId2, options2]
    ].forEach(([uniqId, options]) => {
      if (options.length > 1) {
        installDropdownEventListeners(uniqId, options, eventBus);
      }
    });

    // Call the old load callback if one existed
    if (oldLoadCb) {
      oldLoadCb(...args);
    }
  }

  return { interactiveTitleHtml, onChartLoad };
};

export const enhanceChartPropsWithGroupByOptions = (chartProps, uniqId, eventBus) => {
  // `chartProps.exporting.buttons` is abused as the place to store the group-by options.
  const buttons = _get(chartProps, 'exporting.buttons');
  if (!buttons) {
    return chartProps;
  }
  // If buttons is a 2D array, that means we want to build a multi-select title
  const buildInteractiveTitle = _isArray(_get(buttons, 0)) ? buildMultiGroupByTitle : buildSingleGroupByTitle;
  const { interactiveTitleHtml, onChartLoad } = buildInteractiveTitle(buttons, chartProps, uniqId, eventBus);

  const addedChartProps = {
    title: {
      useHTML: true,
      text: interactiveTitleHtml,
      style: {
        zIndex: 100
      }
    },
    chart: {
      events: {
        render: onChartLoad,
        redraw: onChartLoad
      }
    }
  };

  const merged = _merge(chartProps, addedChartProps);
  return merged;
};
