import { CAMPAIGN_TYPES } from 'src/store/modules/adManager/adCampaignBuilder/types';

const RECEIVE_AD_CAMPAIGN_TYPES = 'src/adCampaignTypes/RECEIVE_AD_CAMPAIGN_TYPES' as const;
const CLEAR_AD_CAMPAIGN_TYPES = 'src/adCampaignTypes/CLEAR_AD_CAMPAIGN_TYPES' as const;
export type CAMPAIGN_TYPES_DYNAMO = (typeof CAMPAIGN_TYPES)[keyof typeof CAMPAIGN_TYPES];

export interface AdCampaignType {
  id: CAMPAIGN_TYPES_DYNAMO;
  campaignType: CAMPAIGN_TYPES_DYNAMO;
  name: string;
  displayName: string;
  shortDisplayName: string;
  type: 'adCampaignType';
}

export default {
  CLEAR_AD_CAMPAIGN_TYPES,
  RECEIVE_AD_CAMPAIGN_TYPES
};
