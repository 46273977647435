import _cloneDeep from 'lodash/cloneDeep';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import _sum from 'lodash/sum';
import { ValueOf, Conditions } from 'sl-api-connector/types';
import { AD_TARGETING_TYPE, AD_CAMPAIGN_TYPE } from 'sl-ad-campaign-manager-data-model';

import { formatMoney } from 'src/utils/stringFormatting';
import { prop, propEq, sumByKey } from 'src/utils/fp';
import { updateTargetEntityListMetrics } from './calc';
import * as actions from './actions';
import * as types from './types';
import { getInitialState } from './initialState';
import ReduxStore from 'src/types/store/reduxStore';
import { error, panic } from 'src/utils/mixpanel';
import { AdCampaignBuilderState } from 'src/types/store/storeTypes';
import { SV_TEST_DATA_SETUP } from 'src/components/AdCampaignBuilder/Widgets/campaignBuilderFakeDATA';

interface SelectedTargetEntityMetricsItem {
  selected: boolean;
  id: string | number;
  adSpend: number;
  adClicks: number;
  adSales: number;
  incrementalSales: number;
  grossMargin: number;
  incrementalMargin: number;
}

const buildMarketStatsQueryConditions = (
  targetingTypeId: ValueOf<typeof AD_TARGETING_TYPE>,
  selectedTargetEntityMetrics: SelectedTargetEntityMetricsItem[]
): Conditions => ({
  termFilters: [
    {
      fieldName: targetingTypeId === AD_TARGETING_TYPE.PRODUCT_TARGETING ? 'stacklineSku' : 'searchTerm',
      values: selectedTargetEntityMetrics
        .filter((x) => x.selected)
        .map((selectedTargetEntityMetric) => selectedTargetEntityMetric.id)
    }
  ],
  rangeFilters: []
});

const computeProjections = (selectedTargetEntityMetrics: SelectedTargetEntityMetricsItem[]) => {
  const selectedTargetEntityMetricsFiltered = selectedTargetEntityMetrics.filter(prop('selected'));
  const [adSpend, adClicks, adSales, incrementalSales, grossMargin, incrementalMargin] = [
    'adSpend' as const,
    'adClicks' as const,
    'adSales' as const,
    'incrementalSales' as const,
    'grossMargin' as const,
    'incrementalMargin' as const
  ].map((key) => sumByKey(key, selectedTargetEntityMetricsFiltered));

  return {
    adClicks,
    adSpend,
    adSales,
    cpc: adSpend / adClicks,
    incrementalSales,
    grossMargin,
    incrementalMargin,
    returnOnAdSpend: adSales / adSpend,
    incrementalReturnOnAdSpend: incrementalSales / adSpend
  };
};

const MAX_FEATURED_PRODUCTS = 1e10; // why so large?

type ActionTypes = ValueOf<{ [K in keyof typeof actions]: ReturnType<(typeof actions)[K]>['type'] }>;

const computeUpdatedProjectionsAndMarketStats = (state: AdCampaignBuilderState): AdCampaignBuilderState => {
  const {
    campaignType,
    target: {
      budget: { total },
      targetEntitiesListFromAtlas,
      targetingTypeId
    },
    featured: { retailPriceAverage, wholesalePriceAverage, brandCogsPerUnitAverage, retailerGrossMarginPercentAverage }
  } = state;

  let campaignTypeId: string = AD_CAMPAIGN_TYPE.SPONSORED_PRODUCT;
  if (campaignType) {
    campaignTypeId = campaignType.settingId;
  }

  const targetEntitiesListFromAtlasCloned = _cloneDeep(targetEntitiesListFromAtlas);
  const targetEntityListMetrics = updateTargetEntityListMetrics({
    totalBudget: total,
    retailPriceAverage,
    wholesalePriceAverage,
    brandCogsPerUnitAverage,
    retailerGrossMarginPercentAverage,
    entityList: targetEntitiesListFromAtlasCloned,
    targetingTypeId: targetingTypeId || AD_TARGETING_TYPE.KEYWORD_TARGETING,
    campaignTypeId
  });

  if (!state.target.targetingTypeId) {
    return panic('Tried to toggle target entity but no `reduxStore.adCampaignBuilder.target.targetingTypeId` is set.');
  }
  const marketStats = {
    queryConditions: buildMarketStatsQueryConditions(state.target.targetingTypeId, targetEntityListMetrics)
  };
  const projections = computeProjections(targetEntityListMetrics);

  return {
    ...state,
    target: {
      ...state.target,
      targetEntitiesListFromAtlas: targetEntitiesListFromAtlasCloned,
      selectedTargetEntities: targetEntityListMetrics,
      projections,
      loading: false
    },
    marketStats: {
      ...state.marketStats,
      ...marketStats
    }
  };
};

const toggleTargetEntitySelected = (
  state: ReduxStore['adCampaignBuilder'],
  entityId: number | string,
  isSelected?: boolean
): ReduxStore['adCampaignBuilder'] => {
  const { targetEntitiesListFromAtlas } = state.target;

  const matchingAtlasEntityIx = targetEntitiesListFromAtlas.findIndex(propEq('id', entityId));
  if (targetEntitiesListFromAtlas && matchingAtlasEntityIx >= 0) {
    const matchingAtlasEntity = targetEntitiesListFromAtlas[matchingAtlasEntityIx];
    const newSelected = _isNil(isSelected) ? !matchingAtlasEntity.selected : isSelected;

    if (newSelected === matchingAtlasEntity.selected) {
      return state;
    }

    targetEntitiesListFromAtlas[matchingAtlasEntityIx] = { ...matchingAtlasEntity, selected: newSelected };
  } else {
    console.error(`No matching entity in \`targetEntitiesListFromAtlas\` for \`toggledEntity.id\` ${entityId}`);
    return state;
  }

  return { ...state, target: { ...state.target, targetEntitiesListFromAtlas: [...targetEntitiesListFromAtlas] } };
};

const adCampaignBuilder = (
  adCampaignBuilderState: ReduxStore['adCampaignBuilder'] = getInitialState(),
  outerAction: ReturnType<ValueOf<typeof actions>>
) => {
  const isLocalhost = window.location.href.includes('-localhost');
  let initialState = getInitialState();

  // TODO: Remove after development
  // Part of Walmart SV development for Blue Buffalo

  if (isLocalhost) {
    // Init filled state for adCampaignBuilder
    if (false) {
      adCampaignBuilderState = SV_TEST_DATA_SETUP;
      initialState = SV_TEST_DATA_SETUP;
    }
  }

  const handlers: {
    [K in ActionTypes]: (
      state: ReduxStore['adCampaignBuilder'],
      action: Extract<ReturnType<ValueOf<typeof actions>>, { type: K }>
    ) => ReduxStore['adCampaignBuilder'];
  } = {
    [types.INIT_AD_BUILDER]: ({ ...state }, _action) => ({ ...state, inited: true }),
    [types.SET_PLATFORM]: ({ ...state }, action) => ({ ...state, platformId: action.platformId }),
    [types.SET_PLATFORM_SETTINGS]: ({ ...state }, action) => ({
      ...state,
      platformSettings: { ...state.platformSettings, ...action.platformSettings }
    }),
    [types.SET_SETUP]: ({ ...state }, action) => ({ ...state, setup: { ...state.setup, ...action.setup } }),
    [types.SET_CAMPAIGN_TYPE]: ({ ...state }, action) => ({ ...state, campaignType: action.campaignType }),
    [types.SET_FEATURED_PRODUCTS_FILTERS]: ({ featured, ...state }, action) => ({
      ...state,
      featured: { ...featured, filters: action.filters }
    }),
    [types.SET_FEATURED]: ({ ...state }, action) => ({ ...state, featured: { ...state.featured, ...action.featured } }),
    [types.SET_TARGET_TYPE_ID]: ({ ...state }, action) => ({
      ...state,
      target: { ...state.target, targetingTypeId: action.targetingTypeId, selectedTargetEntities: [] }
    }),
    [types.SET_CAMPAIGN_BUDGET]: ({ ...state }, action) => {
      let campaignTypeId: string = AD_CAMPAIGN_TYPE.SPONSORED_PRODUCT;
      if (state.campaignType) {
        campaignTypeId = state.campaignType.settingId;
      }
      const { targetEntitiesListFromAtlas, targetingTypeId } = state.target;
      const { retailPriceAverage, wholesalePriceAverage, brandCogsPerUnitAverage, retailerGrossMarginPercentAverage } =
        state.featured;
      const targetEntityListMetrics = updateTargetEntityListMetrics(
        {
          totalBudget: action.totalBudget,
          retailPriceAverage,
          wholesalePriceAverage,
          brandCogsPerUnitAverage,
          retailerGrossMarginPercentAverage,
          entityList: targetEntitiesListFromAtlas,
          targetingTypeId: targetingTypeId || AD_TARGETING_TYPE.KEYWORD_TARGETING,
          campaignTypeId
        },
        action.minimumBid,
        action.maximumBid
      );

      if (!state.target.targetingTypeId) {
        return panic('Tried to set budget but no `reduxStore.adCampaignBuilder.target.targetingTypeId` is set.');
      }
      const marketStats = {
        queryConditions: buildMarketStatsQueryConditions(state.target.targetingTypeId, targetEntityListMetrics)
      };

      const projections = computeProjections(targetEntityListMetrics);

      return {
        ...state,
        target: {
          ...state.target,
          budget: {
            ...state.target.budget,
            total: action.totalBudget,
            daily: parseFloat((action.totalBudget / 30).toFixed(2)),
            displayName: `${formatMoney(action.totalBudget)} (${formatMoney(action.totalBudget / 30)} daily)`
          },
          selectedTargetEntities: targetEntityListMetrics,
          projections
        },
        marketStats: {
          ...state.marketStats,
          ...marketStats
        }
      };
    },
    [types.SET_CAMPAIGN_LIFETIME_BUDGET]: ({ ...state }, action) => {
      return {
        ...state,
        target: {
          ...state.target,
          budget: {
            ...state.target.budget,
            lifetimeBudget: action.lifetimeBudget,
            lifetimeBudgetError: action.lifetimeBudgetError
          }
        }
      };
    },
    [types.CLEAR_CAMPAIGN_LIFETIME_BUDGET]: ({ ...state }) => {
      return {
        ...state,
        target: {
          ...state.target,
          budget: {
            total: state.target.budget.total,
            daily: state.target.budget.daily,
            displayName: state.target.budget.displayName
          }
        }
      };
    },
    [types.SET_CREATIVE]: (state, action) => ({ ...state, creative: { ...state.creative, ...action.creative } }),
    [types.ClEAR_FEATURED]: ({ featured, ...state }) => ({
      ...state,
      featured: {
        ...initialState.featured,
        storeUrl: featured.storeUrl
      }
    }),
    [types.ADD_AUTO_CAMPAIGN_PRODUCTS]: (state, action) => {
      const { products } = action;
      return {
        ...state,
        target: {
          ...state.target,
          autoCampaignProducts: products
        }
      };
    },
    [types.ADD_AUTO_BID_MAPPING]: (state, action) => {
      const { mapping } = action;
      return {
        ...state,
        target: {
          ...state.target,
          autoBidMapping: mapping
        }
      };
    },
    [types.TOGGLR_OR_ADD_UPC_SELECTED]: (state, action) => {
      const { selectedFeaturedUPCs } = state.featured;
      let newUpcs = _cloneDeep(selectedFeaturedUPCs);
      if (Array.isArray(action.upc)) {
        action.upc.forEach((item) => {
          if (!newUpcs.includes(item)) {
            newUpcs.push(item);
          }
        });
      } else if (newUpcs.includes(action.upc)) {
        newUpcs = newUpcs.filter((item: string) => item !== action.upc);
      } else {
        newUpcs.push(action.upc);
      }
      return {
        ...state,
        featured: {
          ...state.featured,
          selectedFeaturedUPCs: newUpcs
        }
      };
    },
    [types.CLEAR_UPC]: (state) => ({
      ...state,
      featured: {
        ...state.featured,
        selectedFeaturedUPCs: initialState.selectedFeaturedUPCs
      }
    }),
    [types.TOGGLE_FEATURED_PRODUCT_SELECT]: (state, action) => {
      const { selectedFeaturedProducts } = state.featured;
      const toggledProduct = action.product;

      if (selectedFeaturedProducts && selectedFeaturedProducts.findIndex((x) => x.id === toggledProduct.id) >= 0) {
        selectedFeaturedProducts.splice(
          selectedFeaturedProducts.findIndex((x) => x.id === toggledProduct.id),
          1
        );
      } else {
        let maxProductCount =
          _get(state, ['campaignType', 'settingId']) === 'sponsoredBrands' &&
          _get(state, ['featured', 'landingType']) === 'Brand Store'
            ? 3
            : MAX_FEATURED_PRODUCTS;

        if (_get(state, ['campaignType', 'settingId']) === 'sponsoredDisplay') {
          maxProductCount = 12;
        }
        if (_get(state, ['campaignType', 'settingId']) === 'sponsoredBrandsVideo') {
          maxProductCount = 1;
        }

        if (selectedFeaturedProducts && selectedFeaturedProducts.length >= maxProductCount) {
          selectedFeaturedProducts.splice(0, 1);
        }
        selectedFeaturedProducts.push(toggledProduct);
      }

      const filters = _cloneDeep(state.target.filters);
      if (filters.isDefault) {
        const subCategoryFilter = {
          condition: 'should' as const,
          values: [] as { i: number }[]
        };
        subCategoryFilter.values = selectedFeaturedProducts.map((product) => ({ i: product.subCategoryId }));
        filters.termFilters = {
          subCategoryId: {
            ...subCategoryFilter
          }
        };
      }
      const featuredProductBrandIds = [
        ...new Set(selectedFeaturedProducts.filter(prop('brandId')).map(prop('brandId')))
      ];
      const featuredProductCategoryIds = [
        ...new Set(selectedFeaturedProducts.filter(prop('categoryId')).map(prop('categoryId')))
      ];
      const featuredProductSubCategoryIds = [
        ...new Set(selectedFeaturedProducts.filter(prop('subCategoryId')).map(prop('subCategoryId')))
      ];
      const retailPrices = selectedFeaturedProducts.map(prop('retailPrice'));
      const retailPriceAverage = _sum(retailPrices) / retailPrices.length;

      const wholesalePrices = selectedFeaturedProducts.map(prop('wholesalePrice'));
      const wholesalePriceAverage = _sum(wholesalePrices) / wholesalePrices.length;

      const brandCogsPerUnitList = selectedFeaturedProducts.map((product) => {
        if (_isNil(product.brandCogsPerUnit) && _isNil(product.wholesalePrice)) {
          error('Missing `brandCogsPerUnit` AND `wholesalePrice` on featured product data item');
        }
        return product.brandCogsPerUnit || (product.wholesalePrice || 0) * 0.6;
      });
      const brandCogsPerUnitAverage =
        brandCogsPerUnitList.reduce((a, b) => {
          return a + +(b > 0 ? b : 0.18);
        }, 0) / brandCogsPerUnitList.length;

      const retailerGrossMarginPercents = selectedFeaturedProducts.map(({ retailerGrossMarginPercent }) => {
        if (_isNil(retailerGrossMarginPercent)) {
          error('Missing `retailerGrossMarginPercent` on featured product datum');
          return 0;
        }
        return retailerGrossMarginPercent;
      });
      const retailerGrossMarginPercentAverage =
        retailerGrossMarginPercents.reduce((a, b) => a + (b > 0 ? b : 0.2), 0) / retailerGrossMarginPercents.length;
      let stateUpdated: ReduxStore['adCampaignBuilder'] = {
        ...state,
        featured: {
          ...state.featured,
          selectedFeaturedProducts,
          retailPriceAverage,
          wholesalePriceAverage,
          brandCogsPerUnitAverage,
          retailerGrossMarginPercentAverage,
          featuredProductBrandIds,
          featuredProductCategoryIds,
          featuredProductSubCategoryIds
        },
        target: {
          ...state.target,
          filters
        }
      };

      if (
        stateUpdated.target.targetEntitiesListFromAtlas &&
        stateUpdated.target.targetEntitiesListFromAtlas.length > 0
      ) {
        stateUpdated = computeUpdatedProjectionsAndMarketStats(stateUpdated) as ReduxStore['adCampaignBuilder'];
      }
      return stateUpdated;
    },
    [types.TOGGLE_TARGET_ENTITY_SELECTED]: (state, action) =>
      computeUpdatedProjectionsAndMarketStats(toggleTargetEntitySelected(state, action.entity.id)),
    [types.BULK_SET_TARGET_ENTITIES_SELECTED]: (state, action) =>
      computeUpdatedProjectionsAndMarketStats(
        action.entityIds.reduce((acc, entityId) => toggleTargetEntitySelected(acc, entityId, action.isSelected), state)
      ),
    [types.SET_TARGET_ENTITIES_LOADING]: (state, action) => ({
      ...state,
      target: { ...state.target, loading: action.loading }
    }),
    [types.SET_TARGET_FILTER]: ({ target, ...state }, action) => ({
      ...state,
      target: { ...target, filters: { isDefault: false, ...action.filters } }
    }),
    [types.SET_TARGET_SELECTED_ENTITIES]: (state, action) => {
      let campaignTypeId: string = AD_CAMPAIGN_TYPE.SPONSORED_PRODUCT;
      if (state.campaignType) {
        campaignTypeId = state.campaignType.settingId;
      }
      const {
        budget: { total },
        targetingTypeId
      } = state.target;
      const { retailPriceAverage, wholesalePriceAverage, brandCogsPerUnitAverage, retailerGrossMarginPercentAverage } =
        state.featured;
      const targetEntityListMetrics = updateTargetEntityListMetrics(
        {
          totalBudget: total,
          retailPriceAverage,
          wholesalePriceAverage,
          brandCogsPerUnitAverage,
          retailerGrossMarginPercentAverage,
          entityList: action.targetEntitiesList,
          targetingTypeId: targetingTypeId || AD_TARGETING_TYPE.KEYWORD_TARGETING,
          campaignTypeId
        },
        action.minimumBid
      );

      if (!state.target.targetingTypeId) {
        throw panic(
          'Tried to toggle target entity but no `reduxStore.adCampaignBuilder.target.targetingTypeId` is set.'
        );
      }
      const marketStats = {
        queryConditions: buildMarketStatsQueryConditions(state.target.targetingTypeId, targetEntityListMetrics)
      };
      const projections = computeProjections(targetEntityListMetrics);

      return {
        ...state,
        target: {
          ...state.target,
          targetEntitiesListFromAtlas: action.targetEntitiesList,
          selectedTargetEntities: targetEntityListMetrics,
          projections,
          loading: false
        },
        marketStats: {
          ...state.marketStats,
          ...marketStats
        }
      };
    },
    [types.SAVE_KEYWORD_LIST]: (state, action) => ({
      ...state,
      target: {
        ...state.target,
        keywordList: action.newList || undefined
      }
    }),
    [types.UPDATE_KEYWORD_LIST]: (state, action) => {
      const { keywordList } = state.target;
      const keywordListCopy = _cloneDeep(keywordList || []);
      keywordListCopy.forEach((keyword, idx) => {
        if (action.kIdx.includes(idx)) {
          keyword[action.tIdx][action.key] = action.value;
        }
      });
      return {
        ...state,
        target: {
          ...state.target,
          keywordList: keywordListCopy
        }
      };
    },
    [types.REPLACE_AD_CAMPAIGN_BUILDER_STATE]: (_state, action) => action.newState,
    [types.SET_UPLOADED_TARGET_KEYWORDS]: (state, { targetKeywords }) => ({
      ...state,
      target: { ...state.target, uploadedTargetKeywords: targetKeywords }
    }),
    [types.SET_SELECTED_FEATURED_PRODUCTS]: (state, { selectedFeaturedProducts }) => {
      const filters = _cloneDeep(state.target.filters);
      if (filters.isDefault) {
        const subCategoryFilter = {
          condition: 'should' as const,
          values: [] as { i: number }[]
        };
        subCategoryFilter.values = selectedFeaturedProducts.map((product) => ({ i: product.subCategoryId }));
        filters.termFilters = {
          subCategoryId: {
            ...subCategoryFilter
          }
        };
      }
      const featuredProductBrandIds = [
        ...new Set(selectedFeaturedProducts.filter(prop('brandId')).map(prop('brandId')))
      ];
      const featuredProductCategoryIds = [
        ...new Set(selectedFeaturedProducts.filter(prop('categoryId')).map(prop('categoryId')))
      ];
      const featuredProductSubCategoryIds = [
        ...new Set(selectedFeaturedProducts.filter(prop('subCategoryId')).map(prop('subCategoryId')))
      ];
      const retailPrices = selectedFeaturedProducts.map(prop('retailPrice'));
      const retailPriceAverage = _sum(retailPrices) / retailPrices.length;

      const wholesalePrices = selectedFeaturedProducts.map(prop('wholesalePrice'));
      const wholesalePriceAverage = _sum(wholesalePrices) / wholesalePrices.length;

      const brandCogsPerUnitList = selectedFeaturedProducts.map((product) => {
        if (_isNil(product.brandCogsPerUnit) && _isNil(product.wholesalePrice)) {
          error('Missing `brandCogsPerUnit` AND `wholesalePrice` on featured product data item');
        }
        return product.brandCogsPerUnit || (product.wholesalePrice || 0) * 0.6;
      });
      const brandCogsPerUnitAverage =
        brandCogsPerUnitList.reduce((a, b) => {
          return a + +(b > 0 ? b : 0.18);
        }, 0) / brandCogsPerUnitList.length;

      const retailerGrossMarginPercents = selectedFeaturedProducts.map(({ retailerGrossMarginPercent }) => {
        if (_isNil(retailerGrossMarginPercent)) {
          error('Missing `retailerGrossMarginPercent` on featured product datum');
          return 0;
        }
        return retailerGrossMarginPercent;
      });
      const retailerGrossMarginPercentAverage =
        retailerGrossMarginPercents.reduce((a, b) => a + (b > 0 ? b : 0.2), 0) / retailerGrossMarginPercents.length;
      let stateUpdated: ReduxStore['adCampaignBuilder'] = {
        ...state,
        featured: {
          ...state.featured,
          selectedFeaturedProducts,
          retailPriceAverage,
          wholesalePriceAverage,
          brandCogsPerUnitAverage,
          retailerGrossMarginPercentAverage,
          featuredProductBrandIds,
          featuredProductCategoryIds,
          featuredProductSubCategoryIds
        },
        target: {
          ...state.target,
          filters
        }
      };

      if (
        stateUpdated.target.targetEntitiesListFromAtlas &&
        stateUpdated.target.targetEntitiesListFromAtlas.length > 0
      ) {
        stateUpdated = computeUpdatedProjectionsAndMarketStats(stateUpdated) as ReduxStore['adCampaignBuilder'];
      }
      return stateUpdated;
    },
    [types.SET_TARGET_AUDIENCE]: (state, { audiences }) => ({
      ...state,
      target: { ...state.target, audiences }
    }),
    [types.SET_UPLOADED_FEATURED_PRODUCTS]: (state, { retailerSkus }) => ({
      ...state,
      featured: { ...state.featured, bulkUploadedFeaturedProductRetailerSkus: retailerSkus }
    }),
    [types.SET_INSTACART_PARAMS]: (state, { instacartParams }) => ({
      ...state,
      instacartParams: {
        ...state.instacartParams,
        ...instacartParams
      }
    })
  };

  const handler = handlers[outerAction.type];
  return handler ? handler(adCampaignBuilderState, outerAction as any) : adCampaignBuilderState;
};

export default adCampaignBuilder;
