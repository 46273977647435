import { Widget } from 'src/types/application/widgetTypes';
import _cloneDeep from 'lodash/cloneDeep';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { zipMetricsResponseIntoArray } from '../index';
import { useEffect, useState } from 'react';
import { ISearchRequestOverride } from 'src/components/AdManager/Search/Models/ISearchRequestOverride';
import { useDispatch } from 'react-redux';
import useFetchEntityMetricsArgs from './useFetchEntityMetricsArgs';
import { getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS, applyCriteoOverrides } from 'src/store/modules/parentPlatform/platformUtils';
import _get from 'lodash/get';
import { shouldShowCriteo } from 'src/utils/app';

const useFetchAndUpdateTotals = ({
  searchRequestOverrides,
  indexName,
  widget,
  onFirstPage,
  importantOverrides
}: {
  searchRequestOverrides: ISearchRequestOverride[];
  indexName: string;
  widget: Widget;
  onFirstPage: boolean;
  importantOverrides: any;
}) => {
  const args = useFetchEntityMetricsArgs();
  const [totalRow, setTotalRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTotal = async () => {
      if (onFirstPage) {
        setLoading(true);
        let copyOverrides = _cloneDeep(searchRequestOverrides);
        copyOverrides[0].aggregations[0].groupByFieldName = 'retailerId';
        copyOverrides[0].pageNumber = 1; // we should return the same total even if on separate page, and it returns nothing when given the actual page

        if (shouldShowCriteo()) {
          const parentPlatform = getParentPlatform();

          if (parentPlatform === PARENT_PLATFORMS.CRITEO) {
            let importantOverridesFiltered: any[] = [];
            // filters overrides
            if (importantOverrides && importantOverrides.length > 0) {
              importantOverridesFiltered = importantOverrides.filter(
                (ov) => ov && ov.types && !ov.types.includes('NOT_TOTAL_TARGETS')
              );
            }
            if (_get(args, ['retailer', 'id']) === '0') {
              copyOverrides[0].aggregations[0].groupByFieldName = 'parentPlatform';
            }

            copyOverrides = applyCriteoOverrides(copyOverrides, parentPlatform, importantOverridesFiltered);
          }
        }

        const resultTotal = await dispatch(
          fetchEntityMetrics(
            `${widget.data.statePropertyName}_total`,
            {
              ...args,
              indexName,
              customResponseParser: (action: any) => {
                return zipMetricsResponseIntoArray(action, widget);
              }
            },
            copyOverrides
          )
        );
        setTotalRow(resultTotal);
        setLoading(false);
      }
    };
    fetchTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args, dispatch, indexName, searchRequestOverrides, onFirstPage]);

  return { totalRow, loading };
};

export default useFetchAndUpdateTotals;
