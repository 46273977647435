import React from 'react';
// eslint-disable-next-line
import IconDecreaseSvg from '!svg-react-loader!./assets/icon-decrease.svg';
// eslint-disable-next-line
import IconIncreaseSvg from '!svg-react-loader!./assets/icon-increase.svg';
// eslint-disable-next-line
import IconRightArrowSvg from '!svg-react-loader!./assets/icon-rightArrow.svg';

/**
 * Inside of the row, the icon is positioned
 * at the bottom of the div so this moves it up
 */
const iconStyle: React.CSSProperties = {
  position: 'relative',
  top: '-3px'
};

const IconIncrease = <IconIncreaseSvg style={iconStyle} />;
const IconNoChange = (
  <IconIncreaseSvg
    style={{
      ...iconStyle,
      // Rotate 90 deg
      transform: 'rotate(90deg)'
    }}
  />
);
const IconDecrease = <IconDecreaseSvg style={iconStyle} />;

/**
 * RightArrow Icon that is used in topEntityChartV2 in product view XAxis hover status
 */
const IconRightArrow = <IconRightArrowSvg style={{ display: 'inline-block', width: '12px', marginLeft: '4px' }} />;

export { IconIncrease, IconDecrease, IconNoChange, IconRightArrow };
