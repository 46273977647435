import React from 'react';
import { ProductEntity } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/types';
import ProductGrid, { GRID_ITEMS_PER_PAGE } from 'src/components/BeaconRedesignComponents/ProductGrid/ProductGrid';
import { useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import Pagination from 'src/components/BeaconRedesignComponents/Pagination/Pagination';
import { ValueOf } from 'sl-api-connector';
import _get from 'lodash/get';
import { usePagination } from './PaginationProvider';
import { shouldHidePagination } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/utils';

interface BeaconProEntityGridProps {
  data: {
    cardView: {
      metricData: {
        metricType: ValueOf<typeof METRICTYPE>;
      };
    };
    entity: ProductEntity;
    value: number;
  }[];
  fetchData: () => void;
  isLoading: boolean;
  lastFetchedPage: number;
}
/**
 * Adapter from EntityGrid to ProductGrid (beacon pro)
 */
export default function BeaconProEntityGrid({ data, fetchData, isLoading, lastFetchedPage }: BeaconProEntityGridProps) {
  const formatMetric = useMetricFormatter();
  const { page, setPage } = usePagination();
  const handleNextPage = () => {
    const dataStartIndex = (page - 1) * GRID_ITEMS_PER_PAGE;
    // If the last fetched page of data is less than the length of data we
    // can show, than there are no more pages to fetch
    if (
      !isLoading &&
      data.slice(dataStartIndex, dataStartIndex + GRID_ITEMS_PER_PAGE).length % GRID_ITEMS_PER_PAGE === 0
    ) {
      const nextPage = page + 1;
      setPage(nextPage);
      if (nextPage > lastFetchedPage) {
        fetchData();
      }
    }
  };

  const handlePrevPage = () => {
    setPage(Math.max(1, page - 1));
  };

  const metricType = _get(data, [0, 'cardView', 'metricData', 'metricType']);

  return (
    <Flex flexDirection="column" gap="md" marginBottom="64px">
      <ProductGrid
        data={data.map((row) => {
          const previousValueKey = Object.keys(row.cardView).find((key) => key.endsWith('PreviousValue'));
          return {
            product: row.entity,
            metricValue: {
              value: row.value,
              difference: previousValueKey ? row.value - row.cardView[previousValueKey] : 0
            }
          };
        })}
        fieldName="metricValue"
        metricType={metricType}
        loading={isLoading && page === lastFetchedPage}
        page={page}
        mainMetricOverride={(metricValue) =>
          `${formatMetric(metricValue, metricType, {
            decimalPlaces: 1,
            showFullValue: metricType === METRICTYPE.PERCENT,
            showNegative: true
          })}`
        }
      />
      {shouldHidePagination({ page, pageSize: GRID_ITEMS_PER_PAGE, rowCount: (data || []).length }) ? null : (
        <Flex justifyContent="flex-end">
          <Pagination
            currentPage={page}
            onClickNext={handleNextPage}
            onClickPrevious={handlePrevPage}
            shouldUseInfinitePagination
          />
        </Flex>
      )}
    </Flex>
  );
}
