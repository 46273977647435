import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _pick from 'lodash/pick';

import { renderComparisonChart } from './Renderer/EntityPageRenderer';
import { WeeklyTrendChart } from './Renderer/WeeklyTrendChart';
import { computeChartProps } from './widgetUtil';
import './Widget.scss';

export const buildWidgetUniqueName = (name, i = 0) => `entitypagecontainer_${name}_${i}`;

export const DefaultWidgetWrapper = ({ children, widget }) => {
  return (
    <div
      style={{
        marginTop: widget
          ? widget.name === 'multiMetricDonutChart' && widget.data.indexName === 'multiretailer'
            ? '6%'
            : 0
          : 0,
        marginLeft: widget
          ? widget.name === 'multiMetricDonutChart' && widget.data.indexName === 'multiretailer'
            ? '4%'
            : 0
          : 0,
        ..._get(widget, 'view.container.style')
      }}
      className={`${_get(widget, 'view.container.className', '')} widget-container-wrapper`}
    >
      {children}
    </div>
  );
};

DefaultWidgetWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  widget: PropTypes.object.isRequired
};

/**
 * This component handles all of the special cases that are necessary for actually rendering a widget from its
 * definition.  It is passed the `widget` itself as a prop along with the parent `pageLayout` and various other
 * application state including `aggregationConditions` and `queryParams`.
 *
 * Using `widget.view.name`, it determines which widget component to render and passes in props as needed.  In
 * situations where it can (the necessary props are there and have values), it also computes intermediary
 * chart props that are passed to the `WeeklyTrendChart` or `CustomComponent`.  (See `computeChartProps` for
 * more information about that).
 *
 * @param {object} props
 */
const WidgetInner = ({
  pageLayout,
  widget,
  i,
  comparisonConfig,
  mainEntityConditions,
  aggregationConditions,
  entityConditions,
  queryParams,
  widgetNames,
  noInnerContainer,
  WrapperComponent,
  // Redux props
  app,
  mainTimePeriod,
  comparisonTimePeriod,
  entitySearchService,
  entitySearchService: { mainEntityMetrics },
  entityService,
  entityService: { mainEntity },
  filters,
  categories,
  retailer,
  ...rest
}) => {
  // Use the provided `WrapperComponent` or the default (a `div` styled with `widget.view.container.style` and
  // `widget.view.container.className`)
  const Wrapper = useMemo(
    () => WrapperComponent || widget.view.WrapperComponent || DefaultWidgetWrapper,
    [WrapperComponent, widget]
  );

  if (!widget) {
    return null;
  }

  const { data, view, CustomComponent } = widget;
  const key = buildWidgetUniqueName(view.name, i);

  // `computeChartProps` handles memoization internally, so we don't need to `useMemo` this here.
  const {
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    comparisonEntityMetrics,
    clonedRelatedEntityMetrics
  } =
    computeChartProps(
      app,
      mainTimePeriod,
      comparisonTimePeriod,
      entityService,
      mainEntityMetrics,
      entitySearchService.comparisonEntityMetrics,
      filters,
      categories,
      retailer,
      comparisonConfig,
      pageLayout,
      widgetNames
    ) || {};

  const widgetContent = (
    <Fragment>
      {view.anchorName ? (
        <a style={{ display: 'block', marginTop: -100, paddingBottom: 100 }} name={view.anchorName} />
      ) : null}

      {view.name === 'weeklyTrendChart' && !CustomComponent && !!comparisonEntityMetrics ? (
        <WeeklyTrendChart
          view={view}
          chartDisplayTimePeriod={chartDisplayTimePeriod}
          chartComparisonDisplayTimePeriod={chartComparisonDisplayTimePeriod}
          mainEntityMetrics={mainEntityMetrics}
          comparisonEntityMetrics={comparisonEntityMetrics}
          metricName={_get(data, 'chartMainField.name')}
          indexName={_get(pageLayout, 'dataConfig.indexName')}
          groupByFieldName={data.groupByField.name}
          comparisonMetricConfig={comparisonConfig}
        />
      ) : null}

      {view.name === 'comparisonBarChart' && !widget.name.includes('Top')
        ? renderComparisonChart(
            view,
            mainTimePeriod,
            comparisonTimePeriod,
            clonedRelatedEntityMetrics,
            clonedRelatedEntityMetrics,
            _get(data, 'chartMainField.name'),
            _get(pageLayout, 'dataConfig.relatedEntity.keyFieldName')
          )
        : null}

      {CustomComponent ? (
        <CustomComponent
          {...rest}
          entity={mainEntity}
          conditions={entityConditions}
          entityConditions={entityConditions}
          queryConditions={mainEntityConditions}
          aggregationConditions={aggregationConditions}
          chartDisplayTimePeriod={chartDisplayTimePeriod}
          chartComparisonDisplayTimePeriod={chartComparisonDisplayTimePeriod}
          widget={widget}
          comparisonConfig={comparisonConfig}
          queryParams={queryParams}
          uniqueName={key}
          pageLayout={pageLayout}
          selectedEntity={_get(widget, 'data.entity')}
        />
      ) : null}
    </Fragment>
  );

  if (noInnerContainer) {
    return widgetContent;
  }

  return <Wrapper widget={widget}>{widgetContent}</Wrapper>;
};

WidgetInner.propTypes = {
  widgetNames: PropTypes.array,
  pageLayout: PropTypes.object,
  widget: PropTypes.object.isRequired,
  i: PropTypes.number,
  comparisonConfig: PropTypes.object.isRequired,
  mainEntityConditions: PropTypes.object,
  aggregationConditions: PropTypes.object.isRequired,
  mainTimePeriod: PropTypes.object.isRequired,
  comparisonTimePeriod: PropTypes.object.isRequired,
  entitySearchService: PropTypes.object.isRequired,
  entityService: PropTypes.object.isRequired,
  entityConditions: PropTypes.object,
  noInnerContainer: PropTypes.bool,
  queryParams: PropTypes.any.isRequired,
  app: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  retailer: PropTypes.object.isRequired,
  WrapperComponent: PropTypes.func
};

WidgetInner.defaultProps = {
  mainEntityConditions: {},
  i: 0,
  pageLayout: undefined,
  entityConditions: undefined,
  widgetNames: [],
  noInnerContainer: false,
  WrapperComponent: undefined
};

/**
 * The most minimal possible widget renderer.  It takes a `widget` as a prop, rendering `widget.CustomComponent` and
 * passing it `widget` and whatever other props it is passed.
 *
 * @param {object} props
 */
export const BareWidget = ({ widget, ...props }) => <widget.CustomComponent widget={widget} {...props} />;

BareWidget.propTypes = {
  widget: PropTypes.object.isRequired
};

const mapWidgetContainerStateToProps = (state) =>
  _pick(state, [
    'app',
    'mainTimePeriod',
    'comparisonTimePeriod',
    'entityService',
    'entitySearchService',
    'filters',
    'categories',
    'retailer'
  ]);

const Widget = connect(mapWidgetContainerStateToProps)(WidgetInner);

export default Widget;
