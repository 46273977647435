import React, { useState } from 'react';
import { CONTENT_TABLE_HEADER_DISPLAY_NAMES } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Content/constants';
import { ProductEntity } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/types';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import ProductListContainer from 'src/components/BeaconRedesignComponents/MetricList/ProductListContainer';
import { getWeekColumnDefinition } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import { removeComparisonFormatter } from 'src/components/BeaconRedesignComponents/MetricList/utils';
import useRetailSalesSortedSkus from 'src/serverProxy/useRetailSalesSortedSkus';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { useProductEntity } from 'src/utils/Hooks/reduxSelectors';
import { METRICTYPE } from 'src/utils/entityDefinitions';

const fields = [
  {
    name: 'contentScore',
    displayName: CONTENT_TABLE_HEADER_DISPLAY_NAMES.CONTENT_SCORE
  },
  {
    name: 'titleScore',
    displayName: CONTENT_TABLE_HEADER_DISPLAY_NAMES.TITLE_SCORE
  },
  {
    name: 'bulletScore',
    displayName: CONTENT_TABLE_HEADER_DISPLAY_NAMES.BULLET_SCORE
  },
  {
    name: 'imageScore',
    displayName: CONTENT_TABLE_HEADER_DISPLAY_NAMES.IMAGE_SCORE
  },
  {
    name: 'videoScore',
    displayName: CONTENT_TABLE_HEADER_DISPLAY_NAMES.VIDEO_SCORE
  },
  {
    name: 'aplusScore',
    displayName: CONTENT_TABLE_HEADER_DISPLAY_NAMES.APLUS_SCORE
  }
];

/**
 * Shows the content score for each product in a grid,
 * sorted by retail sales
 */
export default function ContentScoreByProductGrid() {
  const [page, setPage] = useState(1);

  // Get SKUs sorted by retail sales so we can use it as a condition for
  // fetching the content score
  const {
    isLoading: skusLoading,
    skuIndexMap,
    slicedSkus
  } = useRetailSalesSortedSkus({
    pageNumber: page,
    aggregatePages: true
  });
  const endWeek = useAppSelector((state) => state.mainTimePeriod.endWeek);
  const formatMetric = useMetricFormatter();

  const { isProductEntity } = useProductEntity();
  const weekEndingFields = fields.map(removeComparisonFormatter);

  return isProductEntity ? (
    <MetricListContainer
      entityTableHeaderProps={{
        title: 'Content Score by Week'
      }}
      indexName="new-content-metrics"
      sortFieldName="contentScore"
      fields={weekEndingFields}
      headColumnDefinition={getWeekColumnDefinition({
        getTableColumnProps(defaults) {
          return {
            ...defaults,
            style: {
              ...defaults.style,
              width: '220px'
            }
          };
        }
      })}
      postProcessData={(data) => {
        return data.sort((a, b) => b.weekId.localeCompare(a.weekId));
      }}
      useTableDataProps={{
        groupByFieldName: 'weekId',
        filterResponse: () => true,
        itemsPerPage: 100
      }}
      showGrid={false}
    />
  ) : (
    <ProductListContainer
      loading={skusLoading}
      entityTableHeaderProps={{
        title: 'Content Score by Product'
      }}
      fields={fields}
      indexName="new-content-metrics"
      sortFieldName="contentScore"
      useTableDataProps={{
        startWeekId: endWeek, // We only want to show content score for the current week
        useGenericAdvancedSearchArgs: {
          shouldPerformFetch: slicedSkus.length > 0 && !skusLoading
        },
        buildRequest: (builder) => {
          return builder.addConditionTermFilter('stacklineSku', 'should', slicedSkus);
        },
        pageNumber: 1
      }}
      productGridProps={{
        mainMetricOverride: (value) => `${formatMetric(value, METRICTYPE.PERCENT, { decimalPlaces: 1 })}`
      }}
      postProcessData={(data: { product: ProductEntity }[]) => {
        const res = [...data].sort((a, b) => {
          const aIndex = skuIndexMap[a.product.stacklineSku.toLowerCase()];
          const bIndex = skuIndexMap[b.product.stacklineSku.toLowerCase()];
          return aIndex - bIndex;
        });
        return res;
      }}
      onChangePage={(args) => {
        setPage(args.chunkedPage);
      }}
    />
  );
}
