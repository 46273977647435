import _last from 'lodash/last';
import MultiLineHCF, {
  EntityGridNoclipHeaderWrapper
} from 'src/components/EntityGrid/HeaderComponentFrameworks/MultiLineHCF';
import { RetailerColumn } from 'src/components/Grids/Data/ColumnTypes';
import buildMultiIndexGridWidget from 'src/components/Grids/MultiIndexGrid';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';

const buildAggregatedRetailerGridWidget = (app, entity) => {
  let indexName = 'sales';
  if (app.name === 'atlas') {
    indexName = 'multiretailer';
  }
  const groupByField = INDEX_FIELDS.getField(app.name, indexName, 'retailerId', 'retailer');
  let primaryRequestField = ['retailSales_convertedUSD', 'unitsSold', 'retailPrice_convertedUSD'];
  if (app.name === 'atlas') {
    primaryRequestField = [...primaryRequestField, 'totalClicks', 'convertRate'];
  }

  const primaryRequestAggregationFields = primaryRequestField.map((fieldName) =>
    INDEX_FIELDS.getField(app.name, indexName, fieldName, 'product', groupByField.name)
  );
  let secondaryRequestAggregationFields = [];

  if (app.name === 'beacon') {
    secondaryRequestAggregationFields = [
      INDEX_FIELDS.getField(app.name, 'reviews', 'stars', 'retailer', 'retailerId'),
      INDEX_FIELDS.getField(app.name, 'content', 'contentScore', 'retailer', 'retailerId')
    ];
  }

  const gridRendererFields = [...primaryRequestAggregationFields, ...secondaryRequestAggregationFields].map((field) => {
    return {
      cellRendererFramework: field.cellRendererFramework,
      headerComponentFramework: MultiLineHCF,
      ...field,
      cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
    };
  });
  let data = { disableSortFor: ['stars', 'contentScore'] };

  // sort the grid retail sales desc
  data = { defaultSortField: INDEX_FIELDS.getField(app.name, indexName, 'retailSales') };

  return buildMultiIndexGridWidget(
    primaryRequestAggregationFields,
    secondaryRequestAggregationFields,
    gridRendererFields,
    entity,
    groupByField,
    { mainMetricField: _last(primaryRequestAggregationFields), pageSize: 20 },
    {
      view: {
        WrapperComponent: EntityGridNoclipHeaderWrapper,
        gridOptions: {
          title: 'Retailers',
          firstColumnDefOverrides: {
            cellRendererFramework: RetailerColumn
          },
          enableExport: false
        }
      },
      data
    }
  );
};

export default buildAggregatedRetailerGridWidget;
