import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import EntityGrid from 'src/components/EntityGrid';
import AtlasProductCellFormatter from 'src/components/EntityGrid/Table/AtlasProductCellFormatter';
import MarketShareGrid from 'src/components/EntityGrid/Table/MarketShareGrid';
import WeeklyMetricsGridContainer from 'src/components/EntityGrid/Table/WeeklyMetricsGridContainer';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { buildTopEntitiesWidgetConfig, buildTrendWidgetConfig } from 'src/components/Layout/LayoutUtil';
import { DATATYPE, ENTITIES, INDEX_FIELDS, METRICTYPE } from 'src/utils/entityDefinitions';
import { addProp } from 'src/utils/fp';
import VIEWS from '../ViewDefaultConfig';
import { getGroupByMapping } from './ConversionPageLayout';
import { shouldShowLicenseAtlas } from "../../../utils/app";

export const getRetailSalesAggregationFields = ({ app, indexName = 'sales' }) => {
  const aggregationFields = ['retailSales', 'retailPrice', 'unitsSold'].map((fieldName) =>
    INDEX_FIELDS.getField(app.name, indexName, fieldName, 'product', 'stacklineSku')
  );

  const metricFields = aggregationFields.map(addProp('cellRendererFramework', AtlasProductCellFormatter));

  return { aggregationFields, metricFields };
};

export default function getSalesPageLayout({ app, retailer, user, tab, metricType, entity }) {
  const indexName = tab;
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
  const retailerIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'));
  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.atlas.retailer),
    categoryEntity: _cloneDeep(ENTITIES.atlas.category),
    subcategoryEntity: _cloneDeep(ENTITIES.atlas.category),
    relatedEntity: _cloneDeep(ENTITIES.atlas.brand),
    weekIdField,
    retailerIdField
  };
  const onAllCategoriesPage = entity.type === 'category' && entity.id === '0';
  const retailPriceMarketShareField = INDEX_FIELDS.getField(
    app.name,
    'sales',
    'retailPriceMarketShare',
    entity.type,
    weekIdField.name
  );
  const widgets = [];
  const fields = ['retailSales', 'retailPrice', 'unitsSold'];
  if (fields.indexOf(metricType) > 0) {
    fields.splice(fields.indexOf(metricType), 1);
    fields.unshift(metricType);
  }
  let widgetConfig = buildTrendWidgetConfig(app, indexName, entity, 'weekId', [...fields], weekIdField, {
    data: {
      metricName: 'mainEntityMetrics'
    }
  })
  if (widgetConfig.name === "retailPriceByweekId") {
    widgetConfig.data.avoidZeroes = true;

  }
  widgets.push(
    widgetConfig
  ); // replace Above


  if (metricType === 'retailPrice' && !onAllCategoriesPage) {

    widgets.push(
      buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['retailPrice'], weekIdField, {
        view: {
          displayName: retailPriceMarketShareField.displayName,
          metricFields: [retailPriceMarketShareField],
          chartPropsOverride: {
            title: { text: 'Price Index' }
          }
        },
        data: {
          marketShare: {
            compute: true,
            metricType: METRICTYPE.MULTIPLE,
            showMarketShareTooltip: false
          }
        }
      })
    );
  }

  if (entity.type === 'businessunit' && tab === 'sales' && metricType === 'retailPrice') {

    widgets.push(
      buildTopEntitiesWidgetConfig(
        app,
        indexName,
        entity,
        [
          {
            groupByFieldName: 'brandId',
            aggregationFieldNames: ['retailPrice']
          }
        ],
        50,
        weekIdField,
        {
          view: {
            chartPropsOverride: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              },
              xAxis: [
                {
                  min: 0,
                  max: 9
                }
              ]
            }
          }
        },
        {
          aggregateByFieldDisplayName: 'Retail Sales',
          aggregateByFieldName: 'retailSales',
          function: 'sum',
          canBeExported: true
        }
      )
    );
  }

  if (metricType !== 'retailPrice' && tab !== 'promotions') {
    widgetConfig = buildTrendWidgetConfig(app, indexName, entity, 'weekId', ['retailPrice'], weekIdField, {})
    if (widgetConfig.name === "retailPriceByweekId") {
      widgetConfig.data.avoidZeroes = true;
    }
    widgets.push(widgetConfig); // replace Above
  }

  if (
    entity.type !== 'category' &&
    entity.type !== 'subcategory' &&
    entity.type !== 'segment' &&
    entity.type !== 'businessunit' &&
    tab !== 'promotions' &&
    metricType !== 'retailPrice'
  ) {
    if (metricType === 'retailSales') {
      const retailSalesMarketShareField = INDEX_FIELDS.getField(
        app.name,
        'sales',
        'retailSalesMarketShare',
        entity.type,
        weekIdField.name
      );

      widgetConfig = buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['retailSales'], weekIdField, {
        name: 'marketShareBySales',
        view: {
          displayName: retailSalesMarketShareField.displayName,
          metricFields: [retailSalesMarketShareField],
          chartPropsOverride: {}
        },
        data: {
          marketShare: { compute: true }
        }
      })


      widgets.push(
        widgetConfig
      );
    } else if (metricType === 'unitsSold') {
      const unitsSoldMarketShareField = INDEX_FIELDS.getField(
        app.name,
        'sales',
        'unitsSoldMarketShare',
        entity.type,
        weekIdField.name
      );

      widgets.push(
        buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['unitsSold'], weekIdField, {
          name: 'marketShareByUnits',
          displayName: unitsSoldMarketShareField.displayName,
          metricFields: [unitsSoldMarketShareField],
          view: {
            displayName: unitsSoldMarketShareField.displayName,
            metricFields: [unitsSoldMarketShareField],
            chartPropsOverride: {}
          },
          data: {
            marketShare: { compute: true }
          }
        })
      );
    }
  }

  if (user.config && !user.config.isRestrictedDemoUser) {
    if (entity.type !== 'product' && tab === 'sales' && (metricType === 'retailSales' || metricType === 'unitsSold')) {
      const productsWithSalesWidgetConfig = buildTrendWidgetConfig(
        app,
        'sales',
        entity,
        'weekId',
        ['stacklineSku'],
        weekIdField,
        {
          name: 'productCountMetric',
          view: {
            chartPropsOverride: {
              title: { text: 'Product Count' }
            }
          }
        }
      );
      productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
        (x) => x.name === 'stacklineSku'
      ).conditions = {
        rangeFilters: [
          {
            fieldName: 'unitsSold',
            minValue: 4
          }
        ]
      };

      productsWithSalesWidgetConfig.view.metricFields[0].metricType = METRICTYPE.VOLUME;
      productsWithSalesWidgetConfig.view.metricFields[0].dataType = DATATYPE.INTEGER;
      const stacklineSkuField = productsWithSalesWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
        (x) => x.name === 'stacklineSku'
      );
      stacklineSkuField.metricType = METRICTYPE.VOLUME;
      stacklineSkuField.dataType = DATATYPE.INTEGER;

      widgets.push(productsWithSalesWidgetConfig);
    }

    if (
      entity.type !== 'company' &&
      entity.type !== 'product' &&
      tab !== 'promotions' &&
      metricType !== 'retailPrice'
    ) {
      if (entity.type !== 'brand') {
        let aggregationFieldMappingMarketShare = [];
        if (shouldShowLicenseAtlas())
        {
          aggregationFieldMappingMarketShare = [
            {
              groupByFieldName: 'brandId',
              aggregationFieldNames: [metricType]
            },
            {
              groupByFieldName: 'parentBrandId',
              aggregationFieldNames: [metricType]
            },
            {
              groupByFieldName: 'licenseId',
              aggregationFieldNames: [metricType] 
            }
          ]
        }
        else 
        {
          aggregationFieldMappingMarketShare = [
            {
              groupByFieldName: 'brandId',
              aggregationFieldNames: [metricType]
            },
            {
              groupByFieldName: 'parentBrandId',
              aggregationFieldNames: [metricType]
            }
          ]
        }
        widgets.push(
          buildTopEntitiesWidgetConfig(
            app,
            indexName,
            entity,
            aggregationFieldMappingMarketShare,
            50, // this is page size
            weekIdField,
            {
              isMarketShare: true,
              view: {
                enableComparison: true,
                metricFields: [INDEX_FIELDS.getField(app.name, indexName, `${metricType}MarketShare`, entity.type)],
                chartPropsOverride: {
                  title: { text: 'Market Share by ' },
                  horizontalScrolling: {
                    enabled: true,
                    step: 3
                  },
                  xAxis: [
                    {
                      min: 0,
                      max: 9
                    }
                  ],
                  enableSwitchingGroupBy: true
                }
              }
            }
          )
        );
      }

      widgets.push({
        name: 'marketShareGrid',
        view: VIEWS.marketShareGrid,
        CustomComponent: MarketShareGrid,
        data: {
          groupByFields: [INDEX_FIELDS.getField(app.name, indexName, dataConfig.relatedEntity.keyFieldName)],
          configByGroupByFieldName: {
            brandId: {
              indexName,
              entity: ENTITIES.atlas.brand,
              mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, entity.type),
              aggregationFields: [INDEX_FIELDS.getField(app.name, indexName, metricType, entity.type)],
              isPrimarySource: true
            }
          },
          weekIdField: INDEX_FIELDS.getField(app.name, indexName, 'weekId')
        }
      });
    }
  }

  if (!['product'].includes(entity.type)) {
    const noOfTopEntities = 50;
    const allowHorizontalScroll = {
      horizontalScrolling: {
        enabled: true,
        step: 3
      },
      xAxis: [
        {
          min: 0,
          max: 9
        }
      ]
    };

    if (['company', 'brand', 'category', 'subcategory', 'segment', 'businessunit'].includes(entity.type)) {
      const { category, subcategory, brand, parentBrand, license } = getGroupByMapping(metricType);
      let aggregationFieldMapping = {};
      aggregationFieldMapping = {
        company: [brand, category, subcategory, parentBrand],
        category: [brand, subcategory, parentBrand],
        subcategory: [brand, parentBrand],
        brand: [category, subcategory],
        segment: [brand, category, subcategory, parentBrand],
        businessunit: [brand, category, subcategory, parentBrand]
      };
      if (shouldShowLicenseAtlas()) {
        aggregationFieldMapping = {
          company: [brand, category, subcategory, parentBrand, license],
          category: [brand, subcategory, parentBrand, license],
          subcategory: [brand, parentBrand, license],
          brand: [category, subcategory],
          segment: [brand, category, subcategory, parentBrand, license],
          businessunit: [brand, category, subcategory, parentBrand, license]
        };
      }

      widgets.push(
        buildTopEntitiesWidgetConfig(
          app,
          indexName,
          entity,
          aggregationFieldMapping[entity.type],
          noOfTopEntities,
          weekIdField,
          {
            view: {
              enableComparison: true,
              chartPropsOverride: {
                ...allowHorizontalScroll,
                enableSwitchingGroupBy: true
              }
            }
          }
        )
      );
    } else {
      widgets.push(
        buildTopEntitiesWidgetConfig(
          app,
          indexName,
          entity,
          [
            {
              groupByFieldName: 'subCategoryId',
              aggregationFieldNames: [metricType]
            }
          ],
          noOfTopEntities,
          weekIdField,
          {
            replaceSubCategoryWithParent: true,
            view: {
              enableComparison: true,
              chartPropsOverride: allowHorizontalScroll
            }
          }
        )
      );
    }
  }

  if (user.config && !user.config.isRestrictedDemoUser) {
    const { aggregationFields, metricFields } = getRetailSalesAggregationFields({ app, retailer, indexName });

    if (entity.type !== 'product' && tab !== 'promotions' && entity.type !== 'brand') {
      widgets.push({
        name: 'entityGrid',
        CustomComponent: EntityGrid,
        view: _merge({}, VIEWS.entityGrid, {
          gridOptions: {}
        }),
        data: {
          defaultSortField: INDEX_FIELDS.getField(
            app.name,
            'sales',
            metricType === 'retailSales' ? 'retailSales' : 'unitsSold',
            'product',
            'stacklineSku'
          ),
          groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
          configByGroupByFieldName: {
            stacklineSku: {
              indexName,
              entity: ENTITIES.atlas.product,
              mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
              aggregationFields: [
                INDEX_FIELDS.getField(app.name, indexName, 'retailSales', 'product', 'stacklineSku'),
                INDEX_FIELDS.getField(app.name, indexName, 'retailPrice', 'product', 'stacklineSku'),
                INDEX_FIELDS.getField(app.name, indexName, 'unitsSold', 'product', 'stacklineSku')
              ],
              tableView: {
                metricFields
              }
            }
          }
        }
      });
    }

    if (entity.type === 'brand' && tab !== 'promotions') {
      widgets.push({
        name: 'entityGrid',
        CustomComponent: EntityGrid,
        view: _merge({}, VIEWS.entityGrid, {
          gridOptions: {}
        }),
        data: {
          groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
          configByGroupByFieldName: {
            stacklineSku: {
              indexName,
              entity: ENTITIES.atlas.product,
              mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
              aggregationFields,
              tableView: {
                metricFields
              }
            }
          }
        }
      });
    }

    if (entity.type === 'product') {
      widgets.push({
        view: VIEWS.weeklyMetricsGridContainer,
        CustomComponent: WeeklyMetricsGridContainer,
        data: {
          avoidZeroes: false,
          entity: ENTITIES.atlas.product,
          chartMainField: INDEX_FIELDS.getField(
            app.name,
            indexName,
            metricType,
            entity.type,
            dataConfig.weekIdField.name
          ),
          aggregationFields: [
            INDEX_FIELDS.getField(app.name, indexName, 'unitsSold', entity.type, dataConfig.weekIdField.name),
            INDEX_FIELDS.getField(app.name, indexName, 'retailSales', entity.type, dataConfig.weekIdField.name),
            INDEX_FIELDS.getField(app.name, indexName, 'retailPrice', entity.type, dataConfig.weekIdField.name)
          ],
          groupByField: dataConfig.weekIdField
        }
      });
    }
  }
  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison: true,
    dataConfig,
    widgets
  };
}
