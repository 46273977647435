import types from './types';

export const adPlatforms = (state = [], action) => {
  switch (action.type) {
    case types.RECEIVE_ADPLATFORMS:
      return action.adPlatforms;
    case types.CLEAR_ADPLATFORMS:
      return [];
    default:
      return state;
  }
};
