import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _merge from 'lodash/merge';
import _prop from 'lodash/property';
import _startCase from 'lodash/startCase';
import _upperFirst from 'lodash/upperFirst';
import _toLower from 'lodash/toLower';
import { AppName } from 'sl-api-connector/types';
import { Option } from 'funfix-core';

import { EntityColumn } from 'src/components/Grids/Data/ColumnTypes';
import { store } from 'src/main';
import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import EntityGrid from 'src/components/EntityGrid';
import convertMetricToDisplayValue, {
  buildCampaignStatusMappings,
  adManagerCampaignStatsMetricFields
} from 'src/components/EntityGrid/gridUtils';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import { propEq, not, filterNils } from 'src/utils/fp';
import {
  PAID_TRAFFIC_CAMPAIGNS_COLUMN,
  getSelectedCampaignsGridWidget
} from 'src/components/EntityPage/WaterfallChart/Insights/TrafficInsights/TrafficInsightsLayout';
import {
  mkActiveMetricSwitcherHCF,
  mkValueChangeGetterCRF,
  WaterfallNonSortableHeaderComponentFramework,
  WaterfallFirstColumnHCF
} from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import * as swappableGridActions from 'src/store/modules/swappableGrid/actions';
import MultiLineHCF, {
  EntityGridNoclipHeaderWrapper
} from 'src/components/EntityGrid/HeaderComponentFrameworks/MultiLineHCF';
import AtlasProductCellFormatter, {
  mkAtlasProductCellFormatter
} from 'src/components/EntityGrid/Table/AtlasProductCellFormatter';
import { mkNumeralFormatter } from 'src/utils/stringFormatting';
import FeaturedCampaignsDialog from 'src/components/EntityGrid/DynamicAdvertisingGrid/FeaturedCampaignsDialog';
import { buildWidgetGroup } from 'src/components/Layout/LayoutUtil';
import ReduxStore from 'src/types/store/reduxStore';
import { Widget } from 'src/types/application/widgetTypes';
import { MetricField } from 'src/types/application/types';
import { RowDatum } from 'src/types/store/storeTypes';

export const buildCampaignsDialogWidget = ({
  app,
  indexName
}: {
  app: ReduxStore['app'];
  indexName: string;
}): Widget => {
  const name = 'featuredCampaignsDialog';
  return _merge(getSelectedCampaignsGridWidget({ app, indexName }), {
    name,
    CustomComponent: FeaturedCampaignsDialog,
    view: {
      name
    }
  });
};

/**
 * Creates an entity grid widget definition that allows switching the currently displayed columns to view a larger set
 * of available data columns.
 *
 * The state for which columns are currently selected lives in Redux under the `swappableGrid` key.
 */
export const buildSwappableAdvertisingGrid = (
  { app, entity }: { app: ReduxStore['app']; entity: any },
  adIndexName: string,
  gridWidgetOverrides?: object
): Widget => {
  const mainMetricField = INDEX_FIELDS.getField(app.name, adIndexName, 'clicks', 'product', 'stacklineSku');
  const gridAggregationFields = ['clicks', 'spend', 'sales', 'unitsSold', 'returnOnAdSpend', 'campaignId'].map(
    (fieldName) => INDEX_FIELDS.getField(app.name, adIndexName, fieldName, 'product', 'stacklineSku')
  );

  const { productLookup, keywordStatusLookup } = buildCampaignStatusMappings(
    entity.adCampaignProducts,
    entity.adTargets || []
  );

  const metricFieldOverridesByFieldName: { [key: string]: Partial<MetricField> } = {
    impressions: {
      cellRendererFramework: mkAtlasProductCellFormatter(mkNumeralFormatter('0,0'))
    },
    clicks: {
      cellRendererFramework: mkAtlasProductCellFormatter(mkNumeralFormatter('0,0'))
    },
    spend: {
      cellRendererFramework: mkAtlasProductCellFormatter(mkNumeralFormatter('$0,0'))
    },
    sales: {
      cellRendererFramework: mkAtlasProductCellFormatter(mkNumeralFormatter('$10,0'))
    },
    unitsSold: {
      cellRendererFramework: mkAtlasProductCellFormatter(mkNumeralFormatter('0,0'))
    },
    returnOnAdSpend: {
      headerComponentFramework: undefined
    }
  };

  const statusCellRenderer = ({ data }: { data: { [key: string]: any } }) =>
    _startCase(productLookup[data.entity.stacklineSku] || 'inactive');

  const buildCampaignCardinalityColumn = (groupByFieldName: string): MetricField => ({
    ...PAID_TRAFFIC_CAMPAIGNS_COLUMN,
    cellRendererFramework: mkValueChangeGetterCRF(
      (data: RowDatum) =>
        _get(data, [
          'rawData',
          `campaignId_by_${groupByFieldName}`,
          'data',
          data.srcIx,
          'cardView',
          'campaignIdCurrentValue'
        ]) || 0,
      (data: RowDatum) =>
        _get(data, [
          'rawData',
          `campaignId_by_${groupByFieldName}`,
          'data',
          data.srcIx,
          'cardView',
          'campaignIdPeriodChange'
        ]) || 0
    )
  });

  const buildBaseDataGroupNameConfig = (groupByFieldName: string | null) => ({
    beaconAdIndex: adIndexName,
    mainMetricField,
    aggregationFields: gridAggregationFields,
    tableView: {
      metricFields: filterNils([
        entity.type === 'adCampaign' && groupByFieldName === 'stacklineSku'
          ? {
              displayName: 'Status',
              name: 'status',
              cellRendererFramework: statusCellRenderer,
              headerComponentFramework: MultiLineHCF
            }
          : null,
        groupByFieldName ? buildCampaignCardinalityColumn(groupByFieldName) : null,
        ...gridAggregationFields.filter(not(propEq('name', 'campaignId'))).map((field: MetricField) => ({
          ...field,
          headerComponentFramework: MultiLineHCF,
          ...(metricFieldOverridesByFieldName[field.name!] || {})
        }))
      ])
    }
  });

  const keywordAggregationFields: MetricField[] = [
    ...gridAggregationFields,
    ...['conversionRate', 'costPerAcquisition'].map((fieldName) =>
      INDEX_FIELDS.getField(app.name, adIndexName, fieldName, 'product', 'stacklineSku')
    )
  ];

  const defaultActiveMetrics = ['unitsSold', 'clicks', 'spend', 'sales', 'returnOnAdSpend'];
  store.dispatch(
    swappableGridActions.setInactiveMetrics(
      keywordAggregationFields
        .map(_prop<MetricField, string>('name' as const))
        .filter((fieldName) => fieldName !== 'campaignId' && !defaultActiveMetrics.includes(fieldName)),
      defaultActiveMetrics
    )
  );
  const nonSortableMetricFieldNames = new Set(
    gridAggregationFields.filter(propEq('aggregationFunctionType', 'computed')).map(_prop('name'))
  );

  const getKeywordStatus = ({ data }: any): string =>
    _startCase(keywordStatusLookup[data.entity.name as string] || 'inactive');

  const getKeywordMatchType = ({ data }: any) => _upperFirst(_toLower(data.entity.matchingType as string));

  const keywordMetricFields = filterNils([
    entity.type === 'adCampaign'
      ? {
          displayName: 'Status',
          headerComponentFramework: MultiLineHCF,
          name: 'status',
          valueFormatter: getKeywordStatus
        }
      : null,
    app.name === AppName.Advertising
      ? {
          displayName: 'Match Type',
          headerComponentFramework: MultiLineHCF,
          name: 'matchType',
          valueFormatter: getKeywordMatchType
        }
      : null,
    buildCampaignCardinalityColumn('searchKeyword'),
    ...defaultActiveMetrics.map((fieldName, i) => {
      const mapHCFStateToProps = ({
        swappableGrid: { activeMetrics = defaultActiveMetrics, inactiveMetrics = [] }
      }: ReduxStore) => ({
        currentFieldName: activeMetrics[i],
        currentDisplayName: INDEX_FIELDS.getField(app.name, adIndexName, activeMetrics[i], 'product', 'stacklineSku')
          .displayName,
        inactiveMetrics
      });

      const ConnectedMetricSwitcherHCFInner: React.FC<ReturnType<typeof mapHCFStateToProps>> = ({
        inactiveMetrics,
        currentFieldName,
        ...props
      }) =>
        (() => {
          const MetricSwitcherHCF = mkActiveMetricSwitcherHCF(
            {
              inactiveFields: keywordAggregationFields.filter(({ name }) => inactiveMetrics.includes(name!)),
              onChange: (newFieldName: string) => store.dispatch(swappableGridActions.setActiveMetric(i, newFieldName))
            },
            nonSortableMetricFieldNames.has(currentFieldName)
              ? WaterfallNonSortableHeaderComponentFramework
              : MultiLineHCF
          );

          return <MetricSwitcherHCF {...props} currentFieldName={currentFieldName} />;
        })();

      const ConnectedSwappableHCF = connect(mapHCFStateToProps)(ConnectedMetricSwitcherHCFInner);

      return {
        ...INDEX_FIELDS.getField(app.name, adIndexName, fieldName, 'product', 'stacklineSku'),
        ...(metricFieldOverridesByFieldName[fieldName] || {}),
        headerComponentFramework: ConnectedSwappableHCF,
        cellRendererFramework: connect(({ swappableGrid: { activeMetrics = defaultActiveMetrics } }: ReduxStore) => {
          const field: MetricField = INDEX_FIELDS.getField(
            app.name,
            adIndexName,
            activeMetrics[i],
            'product',
            'stacklineSku'
          );

          return {
            headerNameOverride: field.displayName,
            getValue: (data: RowDatum) => {
              const value = _get(data, [
                'rawData',
                `${activeMetrics[i]}_by_searchKeyword`,
                'data',
                data.srcIx,
                'cardView',
                `${activeMetrics[i]}CurrentValue`
              ]);

              const { retailer } = store.getState();
              return _isNil(value)
                ? value
                : convertMetricToDisplayValue(retailer, value, field.metricType!, retailer.currencySymbol, true);
            },
            getPercentChange: (data: RowDatum) =>
              numeral(
                _get(
                  data,
                  [
                    'rawData',
                    `${activeMetrics[i]}_by_searchKeyword`,
                    'data',
                    data.srcIx,
                    'cardView',
                    `${activeMetrics[i]}PercentChange`
                  ],
                  0
                )
              ).format('0.00%')
          };
        })(AtlasProductCellFormatter as any)
      };
    })
  ]);

  const gridWidget = {
    CustomComponent: EntityGrid as any,
    name: VIEWS.entityGrid.name,
    view: _merge({}, VIEWS.entityGrid, {
      WrapperComponent: EntityGridNoclipHeaderWrapper,
      gridOptions: {
        enableGroupBy: true,
        titleOptions: {
          product: 'Products (Ad Clicks)'
        }
      }
    }),
    data: {
      groupByFields: filterNils([
        entity.type !== 'product' ? INDEX_FIELDS.getField(app.name, adIndexName, 'stacklineSku', 'product') : null,
        entity.type !== 'adCampaign'
          ? {
              ...INDEX_FIELDS.getField(app.name, adIndexName, 'campaignId', 'product'),
              displayName: 'Campaign'
            }
          : null,
        app.name === AppName.Advertising && entity.type !== 'adCampaign'
          ? {
              ...INDEX_FIELDS.getField(app.name, adIndexName, 'portfolioId', 'product'),
              displayName: 'Portfolio'
            }
          : null,
        entity.type !== 'adCampaign'
          ? {
              ...INDEX_FIELDS.getField(app.name, adIndexName, 'campaignType', 'product')
            }
          : null,
        entity.type !== 'adTarget'
          ? {
              ...INDEX_FIELDS.getField(app.name, adIndexName, 'searchKeyword', 'product'),
              // This comma facilitates nested group-by functionality on the backend.  That functionality is not general
              // purpose and should not be relied upon for other use cases in a robust manner.
              apiNameOverride: app.name === AppName.Advertising ? 'targetingText,matchingType' : 'searchKeyword'
            }
          : null
      ]),
      configByGroupByFieldName: {
        stacklineSku: {
          ...buildBaseDataGroupNameConfig('stacklineSku'),
          entity: ENTITIES.beacon.product
        },
        campaignId:
          entity.type !== 'adCampaign'
            ? (() => {
                const config = {
                  ...buildBaseDataGroupNameConfig(null),
                  entity: ENTITIES.beacon.adCampaign
                };
                config.tableView.metricFields = [
                  ...(app.name === AppName.Advertising
                    ? adManagerCampaignStatsMetricFields
                    : [
                        {
                          displayName: ' Campaign Type',
                          name: 'campaignType',
                          valueFormatter: ({ data }: any) => _get(data, 'entity.campaignType', null),
                          minWidth: 200,
                          maxWidth: 200
                        },
                        {
                          displayName: 'Status',
                          name: 'status',
                          valueFormatter: ({ data }: any) =>
                            Option.of(_get(data, 'entity.state', null)).map(_startCase).getOrElse('')
                        }
                      ]),
                  ...config.tableView.metricFields
                ];

                return config;
              })()
            : undefined,
        portfolioId: {
          ...buildBaseDataGroupNameConfig('portfolioId'),
          entity: ENTITIES.beacon.adPortfolio
        },
        campaignType:
          entity.type !== 'adCampaign'
            ? {
                ...buildBaseDataGroupNameConfig('campaignType'),
                entity: ENTITIES.beacon.campaignType
              }
            : undefined,
        searchKeyword: {
          beaconAdIndex: adIndexName,
          mainMetricField: INDEX_FIELDS.getField(app.name, adIndexName, 'clicks', 'product', 'stacklineSku'),
          aggregationFields: keywordAggregationFields,
          tableView: {
            metricFields: keywordMetricFields
          },
          firstColumnDefOverrides: {
            minWidth: 420,
            maxWidth: 420,
            cellRendererFramework: EntityColumn,
            headerComponentFramework: WaterfallFirstColumnHCF
          },
          entity: ENTITIES.beacon.searchKeyword
        }
      }
    }
  };

  const campaignsDialogWidget = buildCampaignsDialogWidget({ app, indexName: adIndexName });

  return buildWidgetGroup([
    gridWidgetOverrides ? _merge(gridWidget, gridWidgetOverrides) : gridWidget,
    campaignsDialogWidget
  ]) as any;
};
