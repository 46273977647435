import _get from 'lodash/get';

import { buildSubtitleDisplayName } from 'src/utils/filters';
import { getChartDisplayTimePeriod, computeWeeklyMarketShareMetrics, setSubTitle } from './Renderer/EntityPageRenderer';

export const getTrendChartInfo = (
  {
    widget,
    entitySearchService,
    mainTimePeriod,
    comparisonTimePeriod,
    retailer,
    filters,
    categories,
    app,
    entityService
  },
  {
    entitySearchServiceStateNamesMainEntity,
    entitySearchServiceStateNamesComparisonEntity,
    entitySearchServiceStateNamesMarketShare,
    mainMetricConfig,
    comparisonMetricConfig,
    essName
  },
  mainMetricField = widget.view.metricFields[0]
) => {

  const { mainEntity } = entityService;
  const {
    view: { chartPropsOverride },
    data
  } = widget;
  const groupByField = data.groupByFields[0];
  const mainEntityMetrics = entitySearchService[essName || entitySearchServiceStateNamesMainEntity[0]];

  const chartDisplayTimePeriod = getChartDisplayTimePeriod(mainTimePeriod);
  let chartComparisonDisplayTimePeriod = getChartDisplayTimePeriod(comparisonTimePeriod);

  const comparisonEntityMetrics =
    entitySearchServiceStateNamesComparisonEntity.length > 0
      ? entitySearchService[entitySearchServiceStateNamesComparisonEntity[0]]
      : entitySearchService[essName || entitySearchServiceStateNamesMainEntity[0]];

  if (!comparisonEntityMetrics) {
    return null;
  }

  const avoidZeros = _get(widget, 'data.avoidZeros', false);
  const replaceZeroWithLastKnownValue = _get(widget, 'data.replaceZeroWithLastKnownValue', false);
  let marketShareDenominatorESSDataKey = null;

  if (data.marketShare && data.marketShare.compute) {
    const mainEntityMarketShareMetrics = entitySearchService[entitySearchServiceStateNamesMarketShare[0]];
    computeWeeklyMarketShareMetrics(
      mainEntityMetrics,
      mainEntityMarketShareMetrics,
      comparisonEntityMetrics,
      groupByField.name,
      data.marketShare.metricType
    );
    [marketShareDenominatorESSDataKey] = entitySearchServiceStateNamesMarketShare;
  }

  const subtitle = buildSubtitleDisplayName(retailer, mainEntity, filters, categories, app);
  setSubTitle(subtitle, [mainEntityMetrics, comparisonEntityMetrics]);

  if (_get(mainEntityMetrics, [`${mainMetricField.name}_by_${groupByField.name}`, `currencySymbol`]) === undefined) {
    return null;
  }

  const comparisonMetricName =
    comparisonMetricConfig.type === 'metric'
      ? comparisonMetricConfig.metricField
        ? comparisonMetricConfig.metricField.name
        : mainMetricConfig.metricField.name
      : mainMetricConfig.metricField.name;

  // if comparison is selected, then you compare them over the same period
  chartComparisonDisplayTimePeriod =
    comparisonMetricConfig.type &&
    (comparisonMetricConfig.indexName !== mainMetricConfig.indexName ||
      comparisonMetricConfig.metricField.name !== mainMetricConfig.metricField.name)
      ? chartDisplayTimePeriod
      : chartComparisonDisplayTimePeriod;

  if (comparisonMetricConfig.type && comparisonMetricConfig.type !== 'metric') {
    chartComparisonDisplayTimePeriod = chartDisplayTimePeriod;
  }

  return {
    chartPropsOverride,
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    mainEntityMetrics,
    mainMetricName: `${mainMetricConfig.metricField.name}_by_${groupByField.name}`,
    comparisonEntityMetrics,
    comparisonMetricName: `${comparisonMetricName}_by_${groupByField.name}`,
    groupByFieldName: groupByField.name,
    widget,
    marketShareDenominatorESSDataKey,
    avoidZeros,
    replaceZeroWithLastKnownValue
  };
};
