import axios from 'axios';
/**
 * For use in Dispute Management. Downloads a supporting document for a specific dispute case given an invoice number and document id.
 */
export default function downloadFile({ invoiceNumber, documentId, retailerId }) {
  return axios.get(
    `api/shortagesdisputedocument/GetDocument?invoiceNumber=${invoiceNumber}&documentId=${documentId}&retailerId=${retailerId}`,
    { responseType: 'blob' }
  );
}
