import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { useFetchAllAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useCompleteAdjustmentData/useFetchAllAdjusmentData';
import {
  createPublishedDataMap,
  createDraftedNetImpactMap,
  createDraftedPaidTrafficMap
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useCompleteAdjustmentData/utils';
import _get from 'lodash/get';
import { ForecastsAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';

/**
 * This hook is responsible for fetching all adjustment data and joining it together into one dataset for use in the adjustment tables.
 * These datasets also populate the adjustment modals when clicking on an adjustment row.
 * If additional data is needed to be fetched, it should be added to the useFetchAllAdjustmentData hook.
 */
export const useCompleteAdjustmentData = () => {
  // Fetch all adjustment data
  const {
    isLoading,
    isError,
    data,
    publishedNetImpactData,
    draftedNetImpactData,
    publishedPaidTrafficData,
    draftedPaidTrafficData,
    userData
  } = useFetchAllAdjustmentData();

  // Create maps/buckets for datasets to easily look up data by adjustmentId depending on certain conditions
  const publishedNetImpactMap = publishedNetImpactData
    ? _get(publishedNetImpactData, [0, 'aggregations', 'by_adjustmentId'], []).reduce(createPublishedDataMap, new Map())
    : null;

  const draftedNetImpactMap = draftedNetImpactData
    ? draftedNetImpactData.reduce(createDraftedNetImpactMap, new Map())
    : null;

  const publishedPaidTrafficMap = publishedPaidTrafficData
    ? _get(publishedPaidTrafficData, [0, 'aggregations', 'by_adjustmentId'], []).reduce(
        createPublishedDataMap,
        new Map()
      )
    : null;

  const draftedPaidTrafficMap = draftedPaidTrafficData
    ? draftedPaidTrafficData.reduce(createDraftedPaidTrafficMap, new Map())
    : null;

  // Join datasets together into one cohesive dataset, sourcing metrics from specific buckets where necessary
  const completeData =
    data && !isLoading
      ? _get(data, [0, 'documents'], []).map(
          (document: Omit<ForecastsAdjustmentData, 'netImpact' | 'userMetaData'>) => {
            const { planType, status } = document;

            // Depending on the plan type, we need to source different metrics from different buckets
            switch (planType) {
              case PlanTypeOption.PaidTraffic:
                switch (status) {
                  case 'Draft':
                    return {
                      ...document,
                      netImpact: draftedNetImpactMap.get(document.adjustmentId) || 0,
                      adjustmentChangeValue: _get(draftedPaidTrafficMap.get(document.adjustmentId), 'paid_traffic', 0),
                      userMetaData: userData[document.createdByUserId]
                    };
                  default:
                    return {
                      ...document,
                      netImpact: publishedNetImpactMap.get(document.adjustmentId) || 0,
                      adjustmentChangeValue: publishedPaidTrafficMap.get(document.adjustmentId) || 0,
                      userMetaData: userData[document.createdByUserId]
                    };
                }

              // For most plan types, we can source metrics from the original dataset except for net impact and user metadata.
              default:
                switch (status) {
                  case 'Draft':
                    return {
                      ...document,
                      netImpact: draftedNetImpactMap.get(document.adjustmentId) || 0,
                      userMetaData: userData[document.createdByUserId]
                    };
                  default:
                    return {
                      ...document,
                      netImpact: publishedNetImpactMap.get(document.adjustmentId) || 0,
                      userMetaData: userData[document.createdByUserId]
                    };
                }
            }
          }
        )
      : null;

  return { data: completeData, isLoading, isError };
};
