import { useStacklineTheme } from '@stackline/ui';
import React, { useRef } from 'react';
import TopEntityChartV2 from 'src/components/BeaconRedesignComponents/TopEntityChartV2/TopEntityChartV2';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import BeaconChartWithLegend from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { calculatePercentChange } from 'src/utils/app';
import { getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import ReactDOMServer from 'react-dom/server';
import { useMainEntityType } from 'src/utils/Hooks/reduxSelectors';
import _capitalize from 'lodash/capitalize';

export interface TrafficBarChartsProps {
  organicTraffic: {
    current: number;
    comparison: number;
  };
  paidTraffic: {
    current: number;
    comparison: number;
  };
  otherTraffic: {
    current: number;
    comparison: number;
  };
  loading: boolean;
}

/**
 * Shows bar charts for total traffic page
 */
export const TrafficBarCharts = ({ organicTraffic, paidTraffic, otherTraffic, loading }: TrafficBarChartsProps) => {
  const formatMetric = useMetricFormatter();
  const theme = useStacklineTheme();
  const primaryDisplayName = useAppSelector((state) => state.mainTimePeriod.displayName);
  const comparisonDisplayName = useAppSelector((state) => state.comparisonTimePeriod.displayName);
  const chartRef = useRef(null);
  const chartValues = [...Object.values(organicTraffic), ...Object.values(paidTraffic)];
  const entityType = useMainEntityType();
  const isTopic = entityType === 'searchtermlist';
  const entityName = useAppSelector((state) => state.entityService.mainEntity.name);

  const chartOptionsOverride = (defaultOptions: Highcharts.Options): Highcharts.Options => ({
    ...defaultOptions,
    chart: {
      ...defaultOptions.chart,
      height: 250
    },
    yAxis: {
      ...defaultOptions.yAxis,
      max: Math.max(...chartValues) * 1.1
    },
    xAxis: defaultOptions?.xAxis?.map((xAxis) => ({
      ...xAxis,
      max: isTopic ? 2 : 3,
      labels: {
        rotation: 0,
        align: 'center',
        useHTML: true,
        formatter() {
          return ReactDOMServer.renderToStaticMarkup(
            <div
              style={{
                fontSize: '12px',
                color: theme.colors.primary,
                fontFamily: 'Roboto',
                marginTop: '12px'
              }}
            >
              {this.value.name}
            </div>
          );
        }
      }
    })),
    series: [
      {
        ...defaultOptions.series[0],
        data: defaultOptions.series[0]?.data?.map?.(([label, value], index) => ({
          name: label,
          y: value,
          color: [theme.colors.primaryLight, theme.colors.success, theme.colors.accentTeal][index]
        }))
      }
    ],
    plotOptions: {
      ...defaultOptions.plotOptions,
      column: {
        pointPadding: 0.2,
        groupPadding: 0.3
      }
    }
  });

  const categories = [
    {
      id: 'Organic',
      name: 'Organic'
    },
    {
      id: 'Paid Search',
      name: 'Paid Search'
    },
    ...(!isTopic ? [{ id: 'Other', name: 'Other' }] : [])
  ];

  const totalTraffic = organicTraffic.current + paidTraffic.current + otherTraffic.current;
  const totalTrafficComparison = organicTraffic.comparison + paidTraffic.comparison + otherTraffic.comparison;

  return (
    <div
      ref={chartRef}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative'
      }}
    >
      {[
        {
          displayName: comparisonDisplayName,
          traffic: totalTrafficComparison,
          organicTrafficValue: organicTraffic.comparison,
          paidTrafficValue: paidTraffic.comparison,
          otherTrafficValue: otherTraffic.comparison
        },
        {
          displayName: primaryDisplayName,
          traffic: totalTraffic,
          trafficPercentChange: calculatePercentChange(totalTraffic, totalTrafficComparison),
          organicTrafficValue: organicTraffic.current,
          paidTrafficValue: paidTraffic.current,
          otherTrafficValue: otherTraffic.current
        }
      ].map(
        (
          { displayName, traffic, trafficPercentChange, organicTrafficValue, paidTrafficValue, otherTrafficValue },
          index
        ) => (
          <div key={displayName} style={{ width: '300px' }}>
            <BeaconChartWithLegend
              title=""
              showTitleSummary={false}
              primaryLegendProps={{
                displayName,
                legendIndicatorProps: {
                  showDot: false
                },
                metric: `${formatMetric(traffic, METRICTYPE.VOLUME, {
                  showFullValue: false,
                  decimalPlaces: 2
                })}`,
                direction: index > 0 ? getDirection(trafficPercentChange) : undefined,
                change:
                  index > 0
                    ? `${formatMetric(Math.abs(trafficPercentChange), METRICTYPE.PERCENT, {
                        decimalPlaces: 2
                      })}`
                    : undefined
              }}
              loading={loading}
              loadingComponent={<SlSkeleton variant="rounded" height={330} width={280} />}
              showExportMenu={index > 0}
              saveImageRef={chartRef}
              selectedMainMetric={
                {
                  displayName: `${_capitalize(entityType)} - ${entityName} - Traffic` // Sets the export file name
                } as any
              }
            >
              <TopEntityChartV2
                chartSeries={
                  [
                    {
                      type: 'column',
                      color: theme.colors.primaryLight,
                      data: [
                        ['Organic', organicTrafficValue],
                        ['Paid Search', paidTrafficValue],
                        ...(!isTopic ? [['Other', otherTrafficValue]] : [])
                      ],
                      categories,
                      metricType: METRICTYPE.VOLUME
                    }
                  ] as any[]
                }
                chartOptionsOverride={chartOptionsOverride}
                containerSx={{
                  left: isTopic ? '-32px' : '-24px',
                  position: 'relative'
                }}
              />
            </BeaconChartWithLegend>
          </div>
        )
      )}
    </div>
  );
};
