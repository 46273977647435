/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import { connect, useSelector } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';
import { Option } from 'funfix-core';

import { withPush } from 'src/utils/hoc';
import { store } from 'src/main';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import fontStyle from 'src/utils/fontStyle';
import ReduxStore from 'src/types/store/reduxStore';
import { getAdCampaignsOfStrategyId } from 'src/store/modules/adManager/adCampaignBuilder/selectors';
import StartEndDatePicker from 'src/components/AdCampaignBuilder/Widgets/StartEndDatePicker';
import { PushFn } from 'src/types/application/types';
import { SectionWrapper, OperationButtons, buildSubtabLink } from '../Widgets/AdCampaignBuilderCommonWidgets';
import colors from 'src/utils/colors';
import _get from 'lodash/get';
import { UTC_TIMEZONE } from 'src/utils/constants';
import AdLimitedTextField from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdLimitedTextField';

const clearFeatured: Function = () => store.dispatch(adCampaignBuilderActions.clearFeatured());

export const muiStyles = {
  root: {
    margin: '20px 0 0 0',
    maxWidth: 250,
    '&:before': {
      content: 'none'
    }
  },
  select: {
    padding: '7px 27px 7px 0px',
    fontWeight: fontStyle.regularWeight,
    width: 250
  },
  textField: {
    width: 250
  }
};

const dateActualRoot = {
  border: '1px solid #dedede',
  borderRadius: 4,
  padding: '2px 13px',
  fontSize: 14,
  letterSpacing: 'normal'
};

export const FieldWapper: React.FC<{ title: string; style?: React.CSSProperties; className?: string }> = ({
  title,
  children,
  style,
  className
}) => (
  <div style={style} className={className}>
    <h3 className="field-header">{title}</h3>
    {children}
  </div>
);

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['adCampaignBuilder']),
  ..._pick(state, ['retailer']),
  allPortfolios: Option.of(state.adCampaignBuilder.platformSettings)
    .flatMap(({ entity }) => Option.of(entity))
    .flatMap(({ settingId: entityId }) =>
      Option.of(state.adCampaignBuilder.setup.strategyId).map((strategyId) =>
        getAdCampaignsOfStrategyId(strategyId, entityId)(state)
      )
    )
    .orNull()
});

const SetupDetail: React.FC<{ classes: { [key: string]: any }; push: PushFn } & ReturnType<typeof mapStateToProps>> = ({
  // classes,
  adCampaignBuilder,
  retailer,
  push
}) => {
  const retailerTimezone = _get(retailer, 'processInTimezone', UTC_TIMEZONE);
  const { name, startDate } = useSelector((state: ReduxStore) => state.adCampaignBuilder.setup);
  const [startDateByTimezone, setStartDateByTimezone] = useState(moment().tz(retailerTimezone).toString());
  const [endDateByTimezone, setEndDateByTimezone] = useState<string | undefined>(undefined);

  useEffect(() => {
    store.dispatch(
      adCampaignBuilderActions.setSetup({
        startDate: startDateByTimezone
      })
    );
  }, [startDateByTimezone]);

  useEffect(() => {
    store.dispatch(
      adCampaignBuilderActions.setSetup({
        endDate: endDateByTimezone
      })
    );
  }, [endDateByTimezone]);

  if (_isNil(adCampaignBuilder.platformSettings)) {
    // Redirect to start of the flow if Redux is in an invalid state due to entering in the middle of the flow
    push(buildSubtabLink('platformSelect'));
    return null;
  }

  const canContinue = !_isNil(name) && !_isEmpty(name) && !_isNil(startDate) && name.length <= 128;

  const handleStartDateChange = (sd?: Moment | null) => {
    setStartDateByTimezone(sd ? sd.toString() : '');
  };

  const handleEndDateChange = (ed?: Moment | null) => {
    // for some reason ed.tz() throws error, so typecasting with moment
    setEndDateByTimezone(
      ed
        ? moment(`${moment(ed.toString()).format('YYYY-MM-DD')}`)
            .tz(retailerTimezone)
            .toString()
        : undefined
    );
  };

  const lengthLimit = name && name.length > 128;
  return (
    <div className="ad-manager-container">
      <SectionWrapper header="Settings" subheader="Choose the name and schedule for this campaign.">
        <div className="settings">
          <FieldWapper title="Name" style={{ margin: '45px 0px' }}>
            <AdLimitedTextField
              characterLimit={500}
              hideLimit
              label="Campaign Name"
              initValue={name}
              onChange={(value) => store.dispatch(adCampaignBuilderActions.setSetup({ name: value }))}
            />
          </FieldWapper>
          {lengthLimit && (
            <div style={{ color: colors.red }}>*Campaign name should not be longer than 128 characters</div>
          )}

          <StartEndDatePicker
            value={{ startDate: startDateByTimezone, endDate: endDateByTimezone }}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            timezone={retailerTimezone}
            styleRoot={dateActualRoot}
          />

          <div className="underline" />
        </div>
      </SectionWrapper>

      <OperationButtons
        subtab="setupDetail"
        canContinue={canContinue}
        onBeforeLeave={() => {
          clearFeatured();
          return true;
        }}
      />
    </div>
  );
};

const EnhancedSetupDetail = connect(mapStateToProps)(withPush(withStyles(muiStyles)(SetupDetail)));

export default EnhancedSetupDetail;
