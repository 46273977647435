import React from 'react';
import { useSelector } from 'react-redux';
import { AppName } from 'sl-api-connector';
import {
  AdManagerLogo,
  AtlasLogo,
  BeaconLogo,
  DiscoverLogo,
  OmniLogo,
  SvgElementProps
} from 'src/components/SvgIcons/SvgIcons';
import ReduxStore from 'src/types/store/reduxStore';

const LogoIcon = (props: SvgElementProps) => {
  const app = useSelector((state: ReduxStore) => state.app);

  let LogoIconComponent = AtlasLogo;
  if (app.name === AppName.Atlas) {
    LogoIconComponent = AtlasLogo;
  } else if (app.name === AppName.Omni) {
    LogoIconComponent = OmniLogo;
  } else if (app.name === AppName.Beacon) {
    LogoIconComponent = BeaconLogo;
  } else if (app.name === AppName.Discover) {
    LogoIconComponent = DiscoverLogo;
  } else {
    LogoIconComponent = AdManagerLogo;
  }

  return <LogoIconComponent {...props} />;
};

export default LogoIcon;
