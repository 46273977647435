import { useEffect } from 'react';
import debounce from 'lodash/debounce';
import { emitter } from 'src/components/AdManager/utils';

function useDebouncedEmitter(eventName, callback, delay = 300) {
  useEffect(() => {
    // Create a debounced version of the callback
    const debouncedCallback = debounce(callback, delay);

    // Set up the event listener
    emitter.on(eventName, debouncedCallback);

    // Clean up
    return () => {
      emitter.off(eventName, debouncedCallback);
      debouncedCallback.cancel();
    };
  }, [eventName, callback, delay]); // Re-run the effect if these dependencies change
}

export default useDebouncedEmitter;
