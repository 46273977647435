const RECEIVE_GEO_MAP_DATA = 'src/omniGeoMap/RECEIVE_GEO_MAP_DATA' as const;
const START_FETCH = 'src/omniGeoMap/START_FETCH' as const;
const END_FETCH = 'src/omniGeoMap/END_FETCH' as const;
const DELETE_DATA = 'src/omniGeoMap/DELETE_DATA';

export default {
  RECEIVE_GEO_MAP_DATA,
  START_FETCH,
  END_FETCH,
  DELETE_DATA
};
