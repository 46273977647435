import { useMemo } from 'react';
import { ISearchFilterConditions } from 'src/components/AdManager/Search/Models/ISearchFilterConditions';
import { useAppSelector } from 'src/utils/Hooks';
import { Widget } from 'src/types/application/widgetTypes';
import { FormData } from 'src/utils/segments';
import {
  constructAdvancedSearchRequest,
  constructAdvancedSearchRequestForResultCount
} from 'src/components/AdManager/Search/GridDataFetchers/SearchRequestOverrideConstructor';
import { SearchGridConstants } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConstants';
import { AggregationField } from 'src/types/application/types';
import { buildSearchRequests } from 'src/store/modules/entitySearchService/operations';
import { ISearchRequestOverride } from 'src/components/AdManager/Search/Models/ISearchRequestOverride';
import useBuildFormDataAndQueryParams from './useBuildFormDataAndQueryParams';
import { Location } from 'history';
import { RangeFilter, TermFilter } from 'sl-api-connector';
import useSearchBarConditions from './useSearchBarConditions';
import { getParentPlatform } from 'src/utils/browser';
import {
  ModifyESQueryAction,
  getESBodyOverridesForParentPlatform,
  modifyESQuery
} from 'src/components/AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';
import { isArray } from 'lodash';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';
import { shouldShowCriteo } from 'src/utils/app';

interface UseAdvancedSearchArgsReturn {
  apiRequest: any[];
  searchRequestOverrides: ISearchRequestOverride[];
  formData: FormData;
  searchSideBarConditions: ISearchFilterConditions;
  aggregationFilters: ISearchFilterConditions;
  onFirstPageAndCount: boolean;
  mainTimePeriodRangeFilters: RangeFilter[];
  aggregationFieldsForResultCount: any;
  searchBarConditionsForAdvancedSearch: ISearchFilterConditions;
}

const useAdvancedSearchArgs = ({
  widget,
  pageNumber,
  sortDirection,
  sortFieldName,
  location,
  additionalTermFilters,
  importantOverrides
}: {
  widget: Widget;
  pageNumber: number;
  sortDirection: string;
  sortFieldName: string;
  location: Location;
  additionalTermFilters?: TermFilter[];
  importantOverrides?: ModifyESQueryAction[];
}): UseAdvancedSearchArgsReturn => {
  const retailerId = useAppSelector((state) => state.retailer.id);
  const appName = useAppSelector((state) => state.app.name);
  const appApiAppName = useAppSelector((state) => state.app.apiAppName);
  const mainEntityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const mainEntityId = useAppSelector((state) => state.entityService.mainEntity.id);
  const isInstacart = useMemo(() => retailerId === '63', [retailerId]);
  const groupByField: AggregationField = useMemo(() => widget.data.groupByFields[0], [widget.data.groupByFields]);
  const { formDataToUse } = useBuildFormDataAndQueryParams({
    location,
    useInternalState: true
  });

  const {
    aggregationFilters,
    searchSideBarConditions,
    aggregationFields,
    aggregationFieldsForResultCount,
    comparisonTimePeriodRangeFilters,
    customConditionTermFilters,
    customTermFilters,
    entityConditions,
    indexName,
    mainTimePeriodRangeFilters,
    searchBarConditionsForAdvancedSearch
  } = useSearchBarConditions({
    groupByField,
    location,
    widget
  });
  const onFirstPageAndCount = useMemo(() => {
    const noComputeFilter = !aggregationFilters.computeFilters || aggregationFilters.computeFilters.length === 0;
    return pageNumber === 1 && noComputeFilter;
  }, [aggregationFilters.computeFilters, pageNumber]);

  return useMemo(() => {
    const currentSortFieldName = sortFieldName || widget.data.defaultSortField.name;
    const targetingGroupBy = isInstacart ? 'targetingText,isAutoAdded' : 'targetingText,targetingType';
    const searchRequestOverride: ISearchRequestOverride = constructAdvancedSearchRequest(
      aggregationFields,
      { id: retailerId },
      customConditionTermFilters,
      mainTimePeriodRangeFilters,
      aggregationFilters,
      searchBarConditionsForAdvancedSearch,
      sortDirection,
      currentSortFieldName,
      comparisonTimePeriodRangeFilters,
      groupByField,
      targetingGroupBy,
      additionalTermFilters,
      customTermFilters,
      pageNumber,
      SearchGridConstants.AD_SEARCH_PAGINATION_SIZE
    );
    let searchRequestOverrides: ISearchRequestOverride[] = [searchRequestOverride];

    // TODO customize for targeting text, not here
    if (aggregationFieldsForResultCount[0].aggregateByFieldName === 'targetingText') {
      aggregationFieldsForResultCount[0].aggregateByFieldName = 'targetId';
    }
    const searchRequestOverride2 = constructAdvancedSearchRequestForResultCount(
      aggregationFieldsForResultCount,
      { id: retailerId },
      mainTimePeriodRangeFilters,
      aggregationFilters,
      searchBarConditionsForAdvancedSearch,
      additionalTermFilters,
      customTermFilters,
      sortDirection,
      currentSortFieldName,
      1,
      SearchGridConstants.AD_SEARCH_PAGINATION_SIZE
    );
    searchRequestOverrides.push(searchRequestOverride2);

    if (shouldShowCriteo()) {
      const parentPlatform = getParentPlatform();

      // Criteo overrides
      if (
        parentPlatform &&
        parentPlatform === PARENT_PLATFORMS.CRITEO &&
        importantOverrides &&
        isArray(importantOverrides)
      ) {
        // const mutateOverridesForCriteo = searchRequestOverrides;
        const newOverride = [];

        searchRequestOverrides.forEach((_, indx) => {
          const mutatedQuery = modifyESQuery(
            searchRequestOverrides[indx],
            getESBodyOverridesForParentPlatform(null, importantOverrides)
          );
          newOverride.push(mutatedQuery);
        });

        searchRequestOverrides = newOverride;
      }
    }

    const { searchRequests: apiRequest } = buildSearchRequests(
      widget.data.statePropertyName,
      {
        entity: { id: mainEntityId, type: mainEntityType },
        retailer: { id: retailerId },
        app: { name: appName, apiAppName: appApiAppName },
        indexName
      },
      searchRequestOverrides
    );

    return {
      apiRequest,
      searchRequestOverrides,
      formData: formDataToUse,
      searchSideBarConditions,
      onFirstPageAndCount,
      aggregationFilters,
      mainTimePeriodRangeFilters,
      aggregationFieldsForResultCount,
      searchBarConditionsForAdvancedSearch
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appApiAppName,
    appName,
    comparisonTimePeriodRangeFilters,
    entityConditions.computeFilters,
    entityConditions.rangeFilters,
    entityConditions.termFilters,
    formDataToUse,
    indexName,
    isInstacart,
    mainEntityId,
    mainEntityType,
    mainTimePeriodRangeFilters,
    pageNumber,
    retailerId,
    sortDirection,
    sortFieldName,
    widget.data.customTermFilters,
    widget.data.defaultSortField.name,
    onFirstPageAndCount,
    aggregationFilters,
    widget.data.statePropertyName
  ]);
};

export default useAdvancedSearchArgs;
