import React from 'react';
import _get from 'lodash/get';

const timeZoneMapping = (retailerId: number) => {
  const mappingtable = new Map();
  // 1,2,11,32,63 -> PST
  // 17 -> GMT
  // 14,15,29,30,62 -> CET
  // 16-> JST
  // 53 -> GST
  // 28 -> IST
  // 31 -> AEST
  [1, 2, 11, 32, 63].forEach((rId: number) => mappingtable.set(rId, 'PST'));
  [17].forEach((rId: number) => mappingtable.set(rId, 'GMT'));
  [14, 15, 29, 30, 62].forEach((rId: number) => mappingtable.set(rId, 'CET'));
  [16].forEach((rId: number) => mappingtable.set(rId, 'JST'));
  [53].forEach((rId: number) => mappingtable.set(rId, 'GST'));
  [28].forEach((rId: number) => mappingtable.set(rId, 'IST'));
  [31].forEach((rId: number) => mappingtable.set(rId, 'AEST'));
  return mappingtable.get(retailerId) || 'PST';
};

const OutOfBudgetHoursColumn = ({ data }: { data: any }) => {
  const retailerId = _get(data, ['entity', 'retailerId']);
  const timeZoneString = timeZoneMapping(retailerId);
  let hour = _get(data, ['entity', 'outOfBudgetHourYesterday'], 0);
  let offset = 'am';
  if (hour > 12) {
    hour -= 12;
    offset = 'pm';
  }
  if (hour === 0) {
    return (
      <div>
        <div> -- </div>
      </div>
    );
  }
  return (
    <div>
      <div>
        {hour}:00{offset} {timeZoneString}
      </div>
    </div>
  );
};

// const mapStateToProps = (state: ReduxStore) =>
//   _pick(state, ['mainTimePeriod', 'comparisonTimePeriod']);

export default OutOfBudgetHoursColumn;
