/**
 * These are constants that are used in waterfall unitsold impact calculation
 */
export const waterfallImpactByCategoryId = {
  '1001': { name: 'Telephones', retailPriceElasticity: -0.789133333333338 },
  '1002': { name: 'Occupational Health & Safety', retailPriceElasticity: -0.519266666666661 },
  '1003': { name: 'Laptops', retailPriceElasticity: -0.866866666666675 },
  '1004': { name: 'Puzzles', retailPriceElasticity: -0.411466666666663 },
  '1005': { name: 'Gaming Accessories', retailPriceElasticity: -0.728266666666669 },
  '1006': { name: '3D Printers & Supplies', retailPriceElasticity: -0.764933333333337 },
  '1007': { name: 'Painting Supplies & Wall Treatments', retailPriceElasticity: -0.555933333333329 },
  '1008': { name: 'Abrasive & Finishing Products', retailPriceElasticity: -0.707000000000002 },
  '1009': { name: 'Kitchen Plates', retailPriceElasticity: -0.696733333333335 },
  '1010': { name: 'Door Security', retailPriceElasticity: -0.821400000000006 },
  '1011': { name: 'Recordkeeping Supplies', retailPriceElasticity: -0.328599999999999 },
  '1012': { name: 'Amino Acids', retailPriceElasticity: -0.526599999999994 },
  '1013': { name: 'Photo Albums', retailPriceElasticity: -0.376999999999997 },
  '1014': { name: 'Action Figures & Statues', retailPriceElasticity: -0.521466666666661 },
  '1015': { name: 'Baking Mixes & Ingredients', retailPriceElasticity: -0.330066666666666 },
  '1016': { name: 'Baby & Toddler Toys', retailPriceElasticity: -0.460599999999994 },
  '1017': { name: 'Wearable Technology', retailPriceElasticity: -0.829466666666673 },
  '1018': { name: 'Massage Tools & Equipment', retailPriceElasticity: -0.641733333333332 },
  '1020': { name: 'Indexes & Stamps', retailPriceElasticity: -0.368933333333331 },
  '1021': { name: 'Building Supplies', retailPriceElasticity: -0.544199999999995 },
  '1022': { name: 'First Aid', retailPriceElasticity: -0.416599999999996 },
  '1023': { name: 'Adhesives & Sealants', retailPriceElasticity: -0.400466666666663 },
  '1024': { name: 'Power Hand Tools', retailPriceElasticity: -0.6696 },
  '1025': { name: 'Wrenches', retailPriceElasticity: -0.709933333333335 },
  '1026': { name: 'Skin Care', retailPriceElasticity: -0.464999999999994 },
  '1027': { name: 'Syrups', retailPriceElasticity: -0.414399999999996 },
  '1028': { name: 'Wheels & Tires', retailPriceElasticity: -0.808200000000006 },
  '1029': { name: 'Power Tool Parts', retailPriceElasticity: -0.59773333333333 },
  '1030': { name: 'Air Compressors', retailPriceElasticity: -0.776666666666671 },
  '1031': { name: 'Air Conditioners & Accessories', retailPriceElasticity: -0.852933333333341 },
  '1032': { name: 'Hunting Accessories', retailPriceElasticity: -0.627799999999998 },
  '1033': { name: 'Game Room', retailPriceElasticity: -0.647599999999999 },
  '1034': { name: 'Air Purifiers', retailPriceElasticity: -0.770066666666671 },
  '1035': { name: 'Air Mattresses', retailPriceElasticity: -0.745133333333337 },
  '1036': { name: 'Measuring & Inspecting Equipment', retailPriceElasticity: -0.731200000000003 },
  '1037': { name: 'Toy Model', retailPriceElasticity: -0.643933333333332 },
  '1038': { name: 'Airsoft Guns & Accessories', retailPriceElasticity: -0.631466666666665 },
  '1039': { name: 'Desktops', retailPriceElasticity: -0.866133333333342 },
  '1040': { name: 'Household Cleaners', retailPriceElasticity: -0.402666666666663 },
  '1041': { name: 'Allergy, Sinus & Asthma', retailPriceElasticity: -0.48846666666666 },
  '1042': { name: 'Single Spices', retailPriceElasticity: -0.3132 },
  '1043': { name: 'Nut & Seed Butters', retailPriceElasticity: -0.344733333333332 },
  '1044': { name: 'Flours', retailPriceElasticity: -0.39093333333333 },
  '1046': { name: 'Nuts & Seeds', retailPriceElasticity: -0.391666666666663 },
  '1047': { name: 'Herbal Supplements', retailPriceElasticity: -0.500199999999993 },
  '1048': { name: 'Alternative Medicine', retailPriceElasticity: -0.604333333333331 },
  '1049': { name: 'Tablet Accessories', retailPriceElasticity: -0.528066666666661 },
  '1050': { name: 'Home Audio', retailPriceElasticity: -0.836800000000007 },
  '1051': { name: 'Streaming Media', retailPriceElasticity: -0.767866666666671 },
  '1052': { name: 'Tactical Bags & Packs', retailPriceElasticity: -0.516333333333327 },
  '1053': { name: 'Clamps', retailPriceElasticity: -0.51706666666666 },
  '1054': { name: 'Sanders & Grinders', retailPriceElasticity: -0.709200000000002 },
  '1055': { name: 'Crackers', retailPriceElasticity: -0.310266666666666 },
  '1056': { name: 'Braces, Splints & Supports', retailPriceElasticity: -0.502399999999993 },
  '1057': { name: 'Digestion & Nausea', retailPriceElasticity: -0.445199999999995 },
  '1058': { name: 'Monitors', retailPriceElasticity: -0.861000000000008 },
  '1059': { name: 'Supplements', retailPriceElasticity: -0.567666666666663 },
  '1060': { name: 'Deodorants & Antiperspirants', retailPriceElasticity: -0.360866666666665 },
  '1061': { name: 'Single Vitamin', retailPriceElasticity: -0.467199999999994 },
  '1062': { name: 'Cooking Vinegars', retailPriceElasticity: -0.374066666666664 },
  '1063': { name: 'Fruit Cups', retailPriceElasticity: -0.305133333333333 },
  '1064': { name: 'Electrical Cables and Cords', retailPriceElasticity: -0.433466666666662 },
  '1065': { name: 'Aquarium Parts & Accessories', retailPriceElasticity: -0.49066666666666 },
  '1066': { name: 'Lamps & Light Fixtures', retailPriceElasticity: -0.6762 },
  '1067': { name: 'Aquariums & Fish Bowls', retailPriceElasticity: -0.751000000000003 },
  '1068': { name: 'Swimming Equipment', retailPriceElasticity: -0.403399999999996 },
  '1069': { name: 'Welding Equipment', retailPriceElasticity: -0.777400000000005 },
  '1071': { name: 'Cutting Tools', retailPriceElasticity: -0.533199999999994 },
  '1072': { name: 'Foot Health', retailPriceElasticity: -0.461333333333328 },
  '1073': { name: 'Archery', retailPriceElasticity: -0.682066666666667 },
  '1074': { name: 'Area Rugs, Runners & Pads', retailPriceElasticity: -0.794266666666672 },
  '1075': { name: 'Wearable Technology Accessories', retailPriceElasticity: -0.343999999999998 },
  '1076': { name: 'Occupational & Physical Therapy Aids', retailPriceElasticity: -0.602133333333331 },
  '1077': { name: 'Slipcovers', retailPriceElasticity: -0.681333333333334 },
  '1078': { name: 'Aromatherapy', retailPriceElasticity: -0.453999999999995 },
  '1079': { name: 'Arts and Crafts', retailPriceElasticity: -0.457666666666661 },
  '1080': { name: 'Storage & Organization', retailPriceElasticity: -0.585999999999997 },
  '1081': { name: 'Artificial Plants', retailPriceElasticity: -0.467933333333328 },
  '1082': { name: 'Home Décor', retailPriceElasticity: -0.451799999999995 },
  '1083': { name: 'Pasta & Noodles', retailPriceElasticity: -0.312466666666666 },
  '1084': { name: 'Cutlery & Knife Accessories', retailPriceElasticity: -0.633666666666665 },
  '1085': { name: 'Packaged Meals & Side Dishes', retailPriceElasticity: -0.3044 },
  '1086': { name: 'Snack Foods', retailPriceElasticity: -0.330799999999999 },
  '1087': { name: 'Men Athletic Clothing', retailPriceElasticity: -0.470133333333327 },
  '1088': { name: 'Speciality Cookware', retailPriceElasticity: -0.668866666666667 },
  '1089': { name: 'Headsets', retailPriceElasticity: -0.665933333333333 },
  '1090': { name: 'Computer Accessories', retailPriceElasticity: -0.674 },
  '1091': { name: 'Audio & Video Cables', retailPriceElasticity: -0.363066666666664 },
  '1093': { name: 'TV & Media Furniture', retailPriceElasticity: -0.846333333333341 },
  '1094': { name: 'Drill Bits', retailPriceElasticity: -0.548599999999995 },
  '1096': { name: 'Automotive Air Compressors & Inflators', retailPriceElasticity: -0.759800000000004 },
  '1097': { name: 'Car Sprays & Sealants', retailPriceElasticity: -0.50606666666666 },
  '1098': { name: 'RV Parts & Accessories', retailPriceElasticity: -0.618999999999998 },
  '1099': { name: 'Automotive Air Bags & Tools', retailPriceElasticity: -0.635133333333332 },
  '1100': { name: 'Plumbing Supplies', retailPriceElasticity: -0.522199999999994 },
  '1101': { name: 'Bumpers & Accessories', retailPriceElasticity: -0.705533333333335 },
  '1102': { name: 'Fuel Filters', retailPriceElasticity: -0.462066666666661 },
  '1103': { name: 'Air Fresheners', retailPriceElasticity: -0.356466666666665 },
  '1104': { name: 'Automotive Sensors', retailPriceElasticity: -0.723866666666669 },
  '1105': { name: 'Shocks & Struts', retailPriceElasticity: -0.784733333333338 },
  '1106': { name: 'Transmission & Parts', retailPriceElasticity: -0.772266666666671 },
  '1107': { name: 'Car & Vehicle Electronics', retailPriceElasticity: -0.679133333333334 },
  '1108': { name: 'Car Antitheft', retailPriceElasticity: -0.544933333333328 },
  '1109': { name: 'Car Exterior Attachments', retailPriceElasticity: -0.429066666666662 },
  '1110': { name: 'Automotive Windows & Mirrors', retailPriceElasticity: -0.693800000000001 },
  '1111': { name: 'Power Transmission Products', retailPriceElasticity: -0.493599999999993 },
  '1112': { name: 'Cargo Racks', retailPriceElasticity: -0.800866666666672 },
  '1113': { name: 'Automotive Lighting', retailPriceElasticity: -0.698933333333335 },
  '1114': { name: 'Automotive Tools', retailPriceElasticity: -0.621199999999998 },
  '1115': { name: 'Vehicle Batteries', retailPriceElasticity: -0.674733333333334 },
  '1116': { name: 'Automotive Mats', retailPriceElasticity: -0.798666666666672 },
  '1117': { name: 'Camping & Hiking Equipment', retailPriceElasticity: -0.682800000000001 },
  '1118': { name: 'Car Seat Accessories', retailPriceElasticity: -0.662266666666666 },
  '1120': { name: 'Gas Tanks & Storage', retailPriceElasticity: -0.642466666666666 },
  '1121': { name: 'Motorcycle Protective Gear', retailPriceElasticity: -0.693066666666668 },
  '1122': { name: 'Cargo Bed Covers', retailPriceElasticity: -0.836066666666674 },
  '1123': { name: 'Car Steering Wheels', retailPriceElasticity: -0.573533333333329 },
  '1124': { name: 'Car Accessories', retailPriceElasticity: -0.753200000000004 },
  '1125': { name: 'Testing Equipment', retailPriceElasticity: -0.558133333333329 },
  '1126': { name: 'Automotive Parts', retailPriceElasticity: -0.660066666666666 },
  '1127': { name: 'Power Winches', retailPriceElasticity: -0.81993333333334 },
  '1128': { name: 'Windshield Sunshades', retailPriceElasticity: -0.564733333333329 },
  '1129': { name: 'Running Boards', retailPriceElasticity: -0.852200000000008 },
  '1130': { name: 'Camera Accessories', retailPriceElasticity: -0.581599999999996 },
  '1131': { name: 'Actuators', retailPriceElasticity: -0.672533333333334 },
  '1132': { name: 'Refrigerators', retailPriceElasticity: -0.860266666666675 },
  '1133': { name: 'Fenders', retailPriceElasticity: -0.73706666666667 },
  '1134': { name: 'Fire Safety', retailPriceElasticity: -0.698200000000001 },
  '1135': { name: 'A/C & Ventilation', retailPriceElasticity: -0.713600000000002 },
  '1136': { name: 'Electrical Supplies', retailPriceElasticity: -0.603599999999997 },
  '1137': { name: 'Door Hardware', retailPriceElasticity: -0.694533333333334 },
  '1138': { name: 'Multimeters', retailPriceElasticity: -0.775933333333338 },
  '1139': { name: 'Motor Oil', retailPriceElasticity: -0.570599999999996 },
  '1140': { name: 'Hammocks, Stands & Accessories', retailPriceElasticity: -0.671066666666667 },
  '1141': { name: 'Hitches', retailPriceElasticity: -0.778133333333338 },
  '1142': { name: 'Hoses & Extension Tools', retailPriceElasticity: -0.615333333333331 },
  '1144': { name: 'Jump Starters', retailPriceElasticity: -0.815533333333339 },
  '1145': { name: 'Jacks & Jack Stands', retailPriceElasticity: -0.761266666666671 },
  '1146': { name: 'Windshield Wipers & Blades', retailPriceElasticity: -0.448133333333328 },
  '1148': { name: 'Lab & Scientific Products', retailPriceElasticity: -0.601399999999997 },
  '1149': { name: 'Automotoive Power System', retailPriceElasticity: -0.785466666666672 },
  '1150': { name: 'Generators & Portable Power', retailPriceElasticity: -0.853666666666674 },
  '1151': { name: 'Screwdrivers', retailPriceElasticity: -0.500933333333326 },
  '1152': { name: 'Automotive Snow Removal', retailPriceElasticity: -0.763466666666671 },
  '1156': { name: 'Outdoor Kitchen Equipment', retailPriceElasticity: -0.654199999999999 },
  '1161': { name: 'Drones', retailPriceElasticity: -0.814800000000006 },
  '1162': { name: 'Classroom Arts & Crafts Supplies', retailPriceElasticity: -0.465733333333328 },
  '1164': { name: 'Baby Gear', retailPriceElasticity: -0.773733333333338 },
  '1165': { name: 'Baby Care Products', retailPriceElasticity: -0.409266666666663 },
  '1166': { name: 'Baby Food', retailPriceElasticity: -0.506799999999993 },
  '1167': { name: 'Baby Bibs', retailPriceElasticity: -0.378466666666664 },
  '1168': { name: 'Baby Highchairs', retailPriceElasticity: -0.766400000000004 },
  '1169': { name: 'Bottles & Accessoreis', retailPriceElasticity: -0.383599999999997 },
  '1170': { name: 'Baby Boy Clothing', retailPriceElasticity: -0.393866666666663 },
  '1171': { name: 'Baby Boy Shoes', retailPriceElasticity: -0.49946666666666 },
  '1172': { name: 'Baby Accessories', retailPriceElasticity: -0.324199999999999 },
  '1173': { name: 'Swaddling Blankets', retailPriceElasticity: -0.538333333333328 },
  '1174': { name: 'Breast Pumps & Accessories', retailPriceElasticity: -0.525133333333327 },
  '1175': { name: 'Car Seats', retailPriceElasticity: -0.769333333333338 },
  '1176': { name: 'Costumes & Accessories', retailPriceElasticity: -0.559599999999996 },
  '1177': { name: 'Baby Feeding Accessories', retailPriceElasticity: -0.346933333333332 },
  '1178': { name: 'Baby Gifts', retailPriceElasticity: -0.449599999999995 },
  '1179': { name: 'Baby Girl Clothing', retailPriceElasticity: -0.371866666666664 },
  '1180': { name: 'Baby Girl Shoes', retailPriceElasticity: -0.354999999999998 },
  '1181': { name: 'Baby Grooming', retailPriceElasticity: -0.341799999999999 },
  '1182': { name: 'Baby Health Products', retailPriceElasticity: -0.435666666666662 },
  '1183': { name: 'Baby Monitors', retailPriceElasticity: -0.82433333333334 },
  '1184': { name: 'Nursing Supplies', retailPriceElasticity: -0.421733333333329 },
  '1185': { name: 'Baby Pacifiers', retailPriceElasticity: -0.316866666666666 },
  '1186': { name: 'Potty Training Supplies', retailPriceElasticity: -0.539799999999995 },
  '1187': { name: 'Baby Safety', retailPriceElasticity: -0.572799999999996 },
  '1188': { name: 'Solid Feeding', retailPriceElasticity: -0.326399999999999 },
  '1189': { name: 'Strollers', retailPriceElasticity: -0.83753333333334 },
  '1190': { name: 'Office Furniture Accessories', retailPriceElasticity: -0.630733333333332 },
  '1191': { name: 'Cabinent & Furniture Knobs', retailPriceElasticity: -0.51266666666666 },
  '1192': { name: 'Backpacks', retailPriceElasticity: -0.685000000000001 },
  '1193': { name: 'Jerky & Dried Meats', retailPriceElasticity: -0.430533333333329 },
  '1194': { name: 'Tennis & Raquet Sports', retailPriceElasticity: -0.661533333333333 },
  '1195': { name: 'Bakeware', retailPriceElasticity: -0.440066666666662 },
  '1196': { name: 'Canned, Jarred & Packaged Foods', retailPriceElasticity: -0.3088 },
  '1197': { name: 'Baking Tools & Accessories', retailPriceElasticity: -0.334466666666665 },
  '1198': { name: 'Chocolate', retailPriceElasticity: -0.422466666666662 },
  '1199': { name: 'Decorating Tools', retailPriceElasticity: -0.342533333333332 },
  '1200': { name: 'Fitness Equipment & Accessories', retailPriceElasticity: -0.689400000000001 },
  '1201': { name: 'Other Sports', retailPriceElasticity: -0.568399999999996 },
  '1202': { name: 'Hammer Hand Tools', retailPriceElasticity: -0.534666666666661 },
  '1203': { name: 'Window Treatment Blinds & Shades', retailPriceElasticity: -0.664466666666667 },
  '1204': { name: 'Writing & Correction Supplies', retailPriceElasticity: -0.340333333333332 },
  '1205': { name: 'Musical Instruments', retailPriceElasticity: -0.73926666666667 },
  '1206': { name: 'Saw Blades, Parts & Accessories', retailPriceElasticity: -0.612399999999998 },
  '1207': { name: 'Saws', retailPriceElasticity: -0.850733333333341 },
  '1208': { name: 'Event & Party Supplies', retailPriceElasticity: -0.352066666666665 },
  '1209': { name: 'Home Bar Furniture', retailPriceElasticity: -0.831666666666673 },
  '1210': { name: 'Printers & Scanners', retailPriceElasticity: -0.844866666666674 },
  '1211': { name: 'Bar & Wine Tools', retailPriceElasticity: -0.455466666666661 },
  '1212': { name: 'Sink Faucets', retailPriceElasticity: -0.838266666666674 },
  '1213': { name: 'Kitchen & Bathroom Sinks & Accessories', retailPriceElasticity: -0.764200000000004 },
  '1214': { name: 'Sauces & Gravies', retailPriceElasticity: -0.337399999999999 },
  '1215': { name: 'Mixed Spices & Seasonings', retailPriceElasticity: -0.327133333333332 },
  '1216': { name: 'Grilling & Barbecue Utensils', retailPriceElasticity: -0.487733333333327 },
  '1217': { name: 'Baseball & Softball Equipment', retailPriceElasticity: -0.734133333333336 },
  '1218': { name: 'Baseball & Softball Training', retailPriceElasticity: -0.781066666666671 },
  '1219': { name: 'Men Athletic Shoes', retailPriceElasticity: -0.803066666666672 },
  '1221': { name: 'Calculators', retailPriceElasticity: -0.719466666666669 },
  '1222': { name: 'Basketball Equipment', retailPriceElasticity: -0.655666666666666 },
  '1223': { name: 'Dried Grains & Rice', retailPriceElasticity: -0.316133333333333 },
  '1224': { name: 'Meat & Poultry Tools', retailPriceElasticity: -0.651999999999999 },
  '1225': { name: 'Mobility & Daily Living Aids', retailPriceElasticity: -0.714333333333335 },
  '1226': { name: 'Bath Towels & Linen Sets', retailPriceElasticity: -0.530266666666661 },
  '1227': { name: 'Bathing Accessories', retailPriceElasticity: -0.38213333333333 },
  '1228': { name: 'Bathroom Décor', retailPriceElasticity: -0.51046666666666 },
  '1229': { name: 'Bathroom Accessories', retailPriceElasticity: -0.464266666666661 },
  '1230': { name: 'Bathroom Furniture', retailPriceElasticity: -0.6564 },
  '1231': { name: 'Mirrors', retailPriceElasticity: -0.707733333333335 },
  '1232': { name: 'Hooks & Hanging Supplies', retailPriceElasticity: -0.379199999999997 },
  '1233': { name: 'Bathtub & Shower Fixtures', retailPriceElasticity: -0.718000000000002 },
  '1235': { name: 'Batteries', retailPriceElasticity: -0.381399999999997 },
  '1236': { name: 'Power Management', retailPriceElasticity: -0.580866666666663 },
  '1237': { name: 'Camera Batteries & Chargers', retailPriceElasticity: -0.583799999999997 },
  '1239': { name: 'Electrical Testing Equipment', retailPriceElasticity: -0.637333333333332 },
  '1240': { name: 'Commercial Lighting', retailPriceElasticity: -0.786200000000005 },
  '1241': { name: 'Lawn Sports', retailPriceElasticity: -0.596999999999997 },
  '1242': { name: 'Water Play Toys', retailPriceElasticity: -0.57793333333333 },
  '1243': { name: 'Drawing & Craft Toys', retailPriceElasticity: -0.380666666666664 },
  '1244': { name: 'Game & Recreation Room Furniture', retailPriceElasticity: -0.774466666666671 },
  '1245': { name: 'Sports & Outdoor Play', retailPriceElasticity: -0.569133333333329 },
  '1246': { name: 'Men Beard & Mustache Care', retailPriceElasticity: -0.49506666666666 },
  '1248': { name: 'Blankets & Quilts', retailPriceElasticity: -0.734866666666669 },
  '1249': { name: 'Bed Accessories', retailPriceElasticity: -0.50386666666666 },
  '1250': { name: 'Beds & Bed Frames', retailPriceElasticity: -0.854400000000008 },
  '1252': { name: 'Comforters, Sheets & Sets', retailPriceElasticity: -0.727533333333336 },
  '1254': { name: 'Beds & Bedroom Furniture', retailPriceElasticity: -0.83313333333334 },
  '1255': { name: 'Tapestries', retailPriceElasticity: -0.574266666666663 },
  '1256': { name: 'Soups, Stocks & Broths', retailPriceElasticity: -0.308066666666666 },
  '1257': { name: 'Meat', retailPriceElasticity: -0.318333333333333 },
  '1258': { name: 'Home Brewing & Wine Making', retailPriceElasticity: -0.639533333333332 },
  '1259': { name: 'Glassware & Drinkware', retailPriceElasticity: -0.473066666666661 },
  '1260': { name: 'To-Go Food Containers', retailPriceElasticity: -0.396799999999997 },
  '1261': { name: 'Kitchen Serveware', retailPriceElasticity: -0.541999999999995 },
  '1262': { name: 'Coffee Makers', retailPriceElasticity: -0.806000000000006 },
  '1263': { name: 'Toilets & Toilet Parts', retailPriceElasticity: -0.704066666666668 },
  '1264': { name: 'Bike Racks & Bags', retailPriceElasticity: -0.720933333333336 },
  '1265': { name: 'Bike Accessories', retailPriceElasticity: -0.448866666666662 },
  '1266': { name: 'Bike Parts', retailPriceElasticity: -0.618266666666665 },
  '1268': { name: 'Bicycle Helmets', retailPriceElasticity: -0.651266666666666 },
  '1269': { name: 'Bicycle Tools', retailPriceElasticity: -0.478199999999994 },
  '1271': { name: 'Billiard & Accessories', retailPriceElasticity: -0.691600000000001 },
  '1274': { name: 'Binders & Binding Systems', retailPriceElasticity: -0.39973333333333 },
  '1275': { name: 'Presentation Products', retailPriceElasticity: -0.712866666666669 },
  '1276': { name: 'Camera Bags & Cases', retailPriceElasticity: -0.649799999999999 },
  '1277': { name: 'Binoculars & Scopes', retailPriceElasticity: -0.823600000000006 },
  '1278': { name: 'Bird Cages & Accessories', retailPriceElasticity: -0.695266666666668 },
  '1279': { name: 'Bird Toys', retailPriceElasticity: -0.369666666666664 },
  '1280': { name: 'Backyard Birding & Wildlife', retailPriceElasticity: -0.59333333333333 },
  '1281': { name: 'Bird Food & Treats', retailPriceElasticity: -0.511199999999994 },
  '1282': { name: 'Bird Health Supplies', retailPriceElasticity: -0.39533333333333 },
  '1283': { name: 'Cookies', retailPriceElasticity: -0.335199999999999 },
  '1284': { name: 'Screwdriver Bits', retailPriceElasticity: -0.392399999999997 },
  '1285': { name: 'Beverage Mixers & Additives', retailPriceElasticity: -0.40853333333333 },
  '1287': { name: 'Olives, Pickles & Relishes', retailPriceElasticity: -0.319799999999999 },
  '1288': { name: 'Tea', retailPriceElasticity: -0.385066666666664 },
  '1289': { name: 'Electrical Breakers & Fuses', retailPriceElasticity: -0.507533333333327 },
  '1290': { name: 'Cookware Accessories', retailPriceElasticity: -0.542733333333328 },
  '1291': { name: 'Health Monitors', retailPriceElasticity: -0.610933333333331 },
  '1292': { name: 'Computer External Components', retailPriceElasticity: -0.778866666666671 },
  '1293': { name: 'Blu Ray & DVD Players', retailPriceElasticity: -0.816266666666673 },
  '1294': { name: 'Headphones', retailPriceElasticity: -0.74586666666667 },
  '1295': { name: 'Bikes', retailPriceElasticity: -0.858800000000008 },
  '1296': { name: 'Protective Gear', retailPriceElasticity: -0.518533333333327 },
  '1297': { name: 'Board, Tile and Dice Games', retailPriceElasticity: -0.475266666666661 },
  '1298': { name: 'Boating & Sailing', retailPriceElasticity: -0.715800000000002 },
  '1299': { name: 'GPS & Navigation', retailPriceElasticity: -0.746600000000003 },
  '1300': { name: 'Soaps & Body Washes', retailPriceElasticity: -0.40193333333333 },
  '1301': { name: 'Body Lotions, Creams & Wipes', retailPriceElasticity: -0.438599999999995 },
  '1302': { name: 'Makeup', retailPriceElasticity: -0.345466666666665 },
  '1303': { name: 'Body Weight Scales', retailPriceElasticity: -0.620466666666665 },
  '1304': { name: 'Other Skin Care', retailPriceElasticity: -0.442999999999995 },
  '1305': { name: 'Sun & Tanning', retailPriceElasticity: -0.486999999999994 },
  '1306': { name: 'Fasteners', retailPriceElasticity: -0.40413333333333 },
  '1308': { name: 'Gardening Hand Tools', retailPriceElasticity: -0.543466666666662 },
  '1309': { name: 'Classroom Furniture', retailPriceElasticity: -0.768600000000004 },
  '1310': { name: 'Novelty Lighting', retailPriceElasticity: -0.485533333333327 },
  '1311': { name: 'Office Cabinents & Shelves', retailPriceElasticity: -0.833866666666674 },
  '1312': { name: 'Mailers & Shipping Supplies', retailPriceElasticity: -0.547866666666662 },
  '1313': { name: 'Warranty, Installation and Protection Plans', retailPriceElasticity: -0.303666666666667 },
  '1314': { name: 'Portable Audio & Speakers', retailPriceElasticity: -0.756133333333337 },
  '1315': { name: 'Winter Sports', retailPriceElasticity: -0.724600000000002 },
  '1316': { name: 'Nails, Screws & Fasteners', retailPriceElasticity: -0.657866666666666 },
  '1317': { name: 'Coffee', retailPriceElasticity: -0.58453333333333 },
  '1318': { name: 'Climbing Equipment', retailPriceElasticity: -0.638066666666665 },
  '1319': { name: 'Kitchen Bowls', retailPriceElasticity: -0.491399999999993 },
  '1320': { name: 'Bowling Equipment & Accessories', retailPriceElasticity: -0.687933333333334 },
  '1322': { name: 'Boxing Equipment', retailPriceElasticity: -0.690866666666668 },
  '1323': { name: 'Boys Active Clothing', retailPriceElasticity: -0.515599999999994 },
  '1324': { name: 'Boys Clothing', retailPriceElasticity: -0.431999999999995 },
  '1325': { name: 'Boys Athletic Shoes', retailPriceElasticity: -0.720200000000002 },
  '1326': { name: 'Boys Accessories', retailPriceElasticity: -0.358666666666665 },
  '1327': { name: 'Boys Shoes', retailPriceElasticity: -0.563266666666662 },
  '1328': { name: 'Boys Coat and Jacket', retailPriceElasticity: -0.632199999999999 },
  '1330': { name: 'Rash Guards', retailPriceElasticity: -0.458399999999995 },
  '1332': { name: 'Sunglasses & Eyeglasses', retailPriceElasticity: -0.574999999999996 },
  '1333': { name: 'Watches & Accessories', retailPriceElasticity: -0.75466666666667 },
  '1334': { name: 'Bread Machines', retailPriceElasticity: -0.825066666666673 },
  '1335': { name: 'Breadcrumbs & Seasoned Coatings', retailPriceElasticity: -0.321999999999999 },
  '1336': { name: 'Granola & Nutrition Bars', retailPriceElasticity: -0.412933333333329 },
  '1337': { name: 'Kitchen Storage & Organization', retailPriceElasticity: -0.524399999999994 },
  '1338': { name: 'Candy', retailPriceElasticity: -0.444466666666662 },
  '1339': { name: 'Other Girls Athletic Apparel', retailPriceElasticity: -0.617533333333331 },
  '1340': { name: 'Briefcase', retailPriceElasticity: -0.797200000000005 },
  '1341': { name: 'Tool Storage & Organizers', retailPriceElasticity: -0.6718 },
  '1342': { name: 'Building Materials', retailPriceElasticity: -0.666666666666667 },
  '1343': { name: 'Building HVAC', retailPriceElasticity: -0.758333333333337 },
  '1344': { name: 'Electrical Wiring & Connecting', retailPriceElasticity: -0.50166666666666 },
  '1345': { name: 'Food Storage', retailPriceElasticity: -0.440799999999995 },
  '1346': { name: 'Teacher Materials', retailPriceElasticity: -0.644666666666666 },
  '1347': { name: 'Cards & Card Stock', retailPriceElasticity: -0.338866666666665 },
  '1348': { name: 'Office Paper', retailPriceElasticity: -0.509733333333327 },
  '1349': { name: 'Dairy Foods', retailPriceElasticity: -0.300733333333333 },
  '1350': { name: 'Pulling & Lifting Equipment', retailPriceElasticity: -0.710666666666668 },
  '1352': { name: 'Material Handling', retailPriceElasticity: -0.640266666666666 },
  '1353': { name: 'Condiments & Salad Dressings', retailPriceElasticity: -0.311733333333333 },
  '1354': { name: 'Window Treatment Hardware', retailPriceElasticity: -0.49286666666666 },
  '1355': { name: 'Kitchen Flatware', retailPriceElasticity: -0.535399999999995 },
  '1356': { name: 'Cake Makers', retailPriceElasticity: -0.419533333333329 },
  '1357': { name: 'Cake Decorating Tools', retailPriceElasticity: -0.331533333333332 },
  '1358': { name: 'Breads & Bakery', retailPriceElasticity: -0.3066 },
  '1359': { name: 'Minerals', retailPriceElasticity: -0.540533333333328 },
  '1361': { name: 'Video Cameras', retailPriceElasticity: -0.855133333333341 },
  '1362': { name: 'Camera Lenses', retailPriceElasticity: -0.856600000000008 },
  '1363': { name: 'Camera Filters & Accessories', retailPriceElasticity: -0.680600000000001 },
  '1364': { name: 'Camera Flash & Accessories', retailPriceElasticity: -0.795000000000005 },
  '1365': { name: 'Tripods & Monopods', retailPriceElasticity: -0.629266666666665 },
  '1366': { name: 'Camp Bedding', retailPriceElasticity: -0.677666666666667 },
  '1367': { name: 'Camp Tents and Shelters', retailPriceElasticity: -0.784000000000005 },
  '1368': { name: 'Coolers', retailPriceElasticity: -0.755400000000004 },
  '1369': { name: 'Trash, Recycling & Compost', retailPriceElasticity: -0.494333333333327 },
  '1370': { name: 'Can & Jar Openers', retailPriceElasticity: -0.364533333333331 },
  '1371': { name: 'Candles & Holders', retailPriceElasticity: -0.489199999999993 },
  '1372': { name: 'Tabletop Accessories', retailPriceElasticity: -0.434933333333329 },
  '1373': { name: 'Vacuums', retailPriceElasticity: -0.841933333333341 },
  '1374': { name: 'Canning', retailPriceElasticity: -0.425399999999996 },
  '1375': { name: 'Kayak & Canoeing', retailPriceElasticity: -0.643199999999999 },
  '1376': { name: 'Cooking Oils', retailPriceElasticity: -0.413666666666663 },
  '1377': { name: 'Canopies, Gazebos & Pergolas', retailPriceElasticity: -0.799400000000005 },
  '1378': { name: 'Car Audio', retailPriceElasticity: -0.788400000000005 },
  '1379': { name: 'Car Subwoofers & Amplifiers', retailPriceElasticity: -0.847800000000007 },
  '1381': { name: 'Collars, Harnesses & Leashes', retailPriceElasticity: -0.40633333333333 },
  '1382': { name: 'Cell Phone Accessories', retailPriceElasticity: -0.442266666666662 },
  '1383': { name: 'Magnets', retailPriceElasticity: -0.311 },
  '1385': { name: 'Fan Shop', retailPriceElasticity: -0.546399999999995 },
  '1386': { name: 'Car Video', retailPriceElasticity: -0.828000000000007 },
  '1389': { name: 'Household Sensors & Alarms', retailPriceElasticity: -0.697466666666668 },
  '1390': { name: 'Card Games', retailPriceElasticity: -0.351333333333332 },
  '1391': { name: 'Carpet Cleaners & Deodorizers', retailPriceElasticity: -0.733400000000003 },
  '1392': { name: 'Cell Phones', retailPriceElasticity: -0.863933333333341 },
  '1393': { name: 'Casino Games', retailPriceElasticity: -0.454733333333328 },
  '1394': { name: 'Cable & Assemblies', retailPriceElasticity: -0.339599999999999 },
  '1395': { name: 'Pet Beds & Furniture', retailPriceElasticity: -0.700400000000001 },
  '1396': { name: 'Pet Feeding & Watering Supplies', retailPriceElasticity: -0.434199999999995 },
  '1397': { name: 'Grooming Supplies', retailPriceElasticity: -0.497999999999993 },
  '1398': { name: 'Crates, Houses & Pens', retailPriceElasticity: -0.757600000000004 },
  '1399': { name: 'Cat Food', retailPriceElasticity: -0.476733333333327 },
  '1400': { name: 'Pet Carriers & Travel', retailPriceElasticity: -0.688666666666668 },
  '1401': { name: 'Pet Health Supplies', retailPriceElasticity: -0.561799999999996 },
  '1402': { name: 'Pet Apparel & Accessories', retailPriceElasticity: -0.398999999999997 },
  '1403': { name: 'Houses & Habitats', retailPriceElasticity: -0.426866666666662 },
  '1404': { name: 'Pet Toys & Accessories', retailPriceElasticity: -0.336666666666665 },
  '1405': { name: 'Automatic Feeders', retailPriceElasticity: -0.683533333333334 },
  '1406': { name: 'Flea & Tick Control', retailPriceElasticity: -0.616066666666665 },
  '1407': { name: 'Cat Litter & Housebreaking', retailPriceElasticity: -0.541266666666661 },
  '1408': { name: 'Pet Memorials', retailPriceElasticity: -0.450333333333328 },
  '1409': { name: 'Pet Odor & Stain Removers', retailPriceElasticity: -0.484799999999994 },
  '1410': { name: 'Training & Behavior Aids', retailPriceElasticity: -0.6652 },
  '1412': { name: 'Doors, Gates & Ramps', retailPriceElasticity: -0.839000000000007 },
  '1413': { name: 'Telescopes', retailPriceElasticity: -0.841200000000007 },
  '1415': { name: 'Two-Way Radios', retailPriceElasticity: -0.753933333333337 },
  '1416': { name: 'Cases & Screen Protectors', retailPriceElasticity: -0.347666666666665 },
  '1417': { name: 'Ceiling Lighting', retailPriceElasticity: -0.779600000000005 },
  '1418': { name: 'Ceiling Fans', retailPriceElasticity: -0.810400000000006 },
  '1419': { name: 'Garage Storage', retailPriceElasticity: -0.6784 },
  '1420': { name: 'Memory Cards & Flash Drives', retailPriceElasticity: -0.545666666666662 },
  '1421': { name: 'Juicers', retailPriceElasticity: -0.742200000000003 },
  '1422': { name: 'Cereals', retailPriceElasticity: -0.321266666666666 },
  '1423': { name: 'Power Tools Replacement Parts', retailPriceElasticity: -0.475999999999994 },
  '1424': { name: 'Power Tools', retailPriceElasticity: -0.814066666666673 },
  '1425': { name: 'Store Fixtures & Displays', retailPriceElasticity: -0.579399999999996 },
  '1426': { name: 'Grills & Outdoor Cooking', retailPriceElasticity: -0.804533333333339 },
  '1427': { name: 'Graters, Peelers & Slicers', retailPriceElasticity: -0.446666666666662 },
  '1428': { name: 'Cookware', retailPriceElasticity: -0.725333333333336 },
  '1429': { name: 'Cough & Cold', retailPriceElasticity: -0.389466666666664 },
  '1431': { name: 'Hangers', retailPriceElasticity: -0.47966666666666 },
  '1432': { name: 'Outdoor Décor', retailPriceElasticity: -0.577199999999996 },
  '1433': { name: 'Fireplaces & Accessories', retailPriceElasticity: -0.760533333333337 },
  '1434': { name: 'Vitamins', retailPriceElasticity: -0.572066666666663 },
  '1435': { name: 'Kitchen Utensils', retailPriceElasticity: -0.387266666666664 },
  '1436': { name: 'Workout Supplements', retailPriceElasticity: -0.614599999999998 },
  '1438': { name: 'Picture Frames', retailPriceElasticity: -0.634399999999999 },
  '1439': { name: 'Office Tape & Fasteners', retailPriceElasticity: -0.329333333333332 },
  '1440': { name: 'Clocks', retailPriceElasticity: -0.613133333333331 },
  '1441': { name: 'Patio Lights', retailPriceElasticity: -0.81773333333334 },
  '1442': { name: 'Clothing & Closet Storage', retailPriceElasticity: -0.605799999999997 },
  '1443': { name: 'Laundry Storage', retailPriceElasticity: -0.481133333333327 },
  '1444': { name: 'Salt & Pepper Seasoning', retailPriceElasticity: -0.335933333333332 },
  '1446': { name: 'Entryway Furniture', retailPriceElasticity: -0.793533333333339 },
  '1447': { name: 'Chips & Pretzels', retailPriceElasticity: -0.322733333333333 },
  '1448': { name: 'Bottled Beverages', retailPriceElasticity: -0.418066666666663 },
  '1450': { name: 'Milk & Cream', retailPriceElasticity: -0.309533333333333 },
  '1451': { name: 'Coffee & Tea Equipment', retailPriceElasticity: -0.531733333333328 },
  '1452': { name: 'Coffee & Tea Accessories', retailPriceElasticity: -0.370399999999998 },
  '1453': { name: 'Living Room Furniture', retailPriceElasticity: -0.855866666666674 },
  '1454': { name: 'Collecting Hobbies', retailPriceElasticity: -0.498733333333326 },
  '1455': { name: 'Strainers', retailPriceElasticity: -0.377733333333331 },
  '1456': { name: 'Pain Relievers', retailPriceElasticity: -0.49726666666666 },
  '1457': { name: 'Microwave Ovens', retailPriceElasticity: -0.848533333333341 },
  '1458': { name: 'Security & Surveillance', retailPriceElasticity: -0.819200000000006 },
  '1459': { name: 'Commercial Kitchen Equipment', retailPriceElasticity: -0.74146666666667 },
  '1460': { name: 'Travel & To-Go Drinkware', retailPriceElasticity: -0.517799999999994 },
  '1461': { name: 'Light Bulbs', retailPriceElasticity: -0.530999999999994 },
  '1462': { name: 'Notebooks & Writing Pads', retailPriceElasticity: -0.348399999999998 },
  '1463': { name: 'Composting & Yard Waste Bins', retailPriceElasticity: -0.613866666666664 },
  '1464': { name: 'Monitor Mounts', retailPriceElasticity: -0.75026666666667 },
  '1465': { name: 'Computer Internal Components', retailPriceElasticity: -0.83533333333334 },
  '1466': { name: 'Office Desks & Workstations', retailPriceElasticity: -0.842666666666674 },
  '1467': { name: 'Power Supply Components', retailPriceElasticity: -0.792800000000005 },
  '1468': { name: 'Sexual Wellness', retailPriceElasticity: -0.468666666666661 },
  '1469': { name: 'Office Tables', retailPriceElasticity: -0.699666666666668 },
  '1470': { name: 'Small Kitchen Appliance', retailPriceElasticity: -0.808933333333339 },
  '1471': { name: 'Vision Care', retailPriceElasticity: -0.418799999999996 },
  '1472': { name: 'Ovens & Toasters', retailPriceElasticity: -0.787666666666672 },
  '1473': { name: 'Kitchen Accessories', retailPriceElasticity: -0.514133333333327 },
  '1474': { name: 'Cooking Utensils', retailPriceElasticity: -0.367466666666664 },
  '1475': { name: 'Cooktops', retailPriceElasticity: -0.864666666666675 },
  '1477': { name: 'Strength Training Equipment', retailPriceElasticity: -0.742933333333336 },
  '1478': { name: 'Fruit & Vegetable Tools', retailPriceElasticity: -0.332266666666666 },
  '1479': { name: 'Packaging & Mailing Supplies', retailPriceElasticity: -0.483333333333327 },
  '1480': { name: 'Luggage & Travel Accessories', retailPriceElasticity: -0.431266666666662 },
  '1481': { name: 'Ear Care', retailPriceElasticity: -0.346199999999998 },
  '1482': { name: 'Blenders', retailPriceElasticity: -0.795733333333339 },
  '1483': { name: 'Sewing', retailPriceElasticity: -0.622666666666665 },
  '1484': { name: 'Salsas, Dips & Spreads', retailPriceElasticity: -0.302933333333333 },
  '1485': { name: 'Cycling Shoes', retailPriceElasticity: -0.820666666666673 },
  '1486': { name: 'Climbing & Hiking Bags', retailPriceElasticity: -0.552266666666662 },
  '1488': { name: 'Dance Apparel', retailPriceElasticity: -0.424666666666662 },
  '1489': { name: 'Eye Care', retailPriceElasticity: -0.562533333333329 },
  '1490': { name: 'Film Photography Equipment', retailPriceElasticity: -0.723133333333336 },
  '1491': { name: 'Darts & Accessories', retailPriceElasticity: -0.598466666666664 },
  '1492': { name: 'Pool Cleaning Supplies', retailPriceElasticity: -0.749533333333337 },
  '1493': { name: 'Outdoor Lighting', retailPriceElasticity: -0.650533333333333 },
  '1494': { name: 'Baking Decorating Tools', retailPriceElasticity: -0.359399999999998 },
  '1495': { name: 'Shower Accessories', retailPriceElasticity: -0.405599999999996 },
  '1496': { name: 'Dehumidifiers & Accessories', retailPriceElasticity: -0.822866666666673 },
  '1497': { name: 'Speciality Kitchen Tools', retailPriceElasticity: -0.696000000000001 },
  '1498': { name: 'Deli Foods', retailPriceElasticity: -0.305866666666666 },
  '1500': { name: 'Office Storage Supplies', retailPriceElasticity: -0.594799999999997 },
  '1501': { name: 'Office Chairs', retailPriceElasticity: -0.840466666666674 },
  '1503': { name: 'Calendars & Planners', retailPriceElasticity: -0.39753333333333 },
  '1504': { name: 'Staplers & Punches', retailPriceElasticity: -0.366733333333331 },
  '1505': { name: 'Diabetes Care', retailPriceElasticity: -0.529533333333328 },
  '1506': { name: 'Diapers', retailPriceElasticity: -0.635866666666665 },
  '1507': { name: 'Baby Changing Table', retailPriceElasticity: -0.75906666666667 },
  '1508': { name: 'Toy Remote Control & Play Vehicles', retailPriceElasticity: -0.609466666666664 },
  '1509': { name: 'Camera Lighting', retailPriceElasticity: -0.717266666666669 },
  '1513': { name: 'Dishwashers & Dishwashing', retailPriceElasticity: -0.376266666666664 },
  '1514': { name: 'Medical Apparel & Gloves', retailPriceElasticity: -0.353533333333331 },
  '1515': { name: 'Disposable Kitchenware', retailPriceElasticity: -0.407799999999996 },
  '1517': { name: 'Diving & Snorkeling', retailPriceElasticity: -0.718733333333335 },
  '1518': { name: 'Pooper Scoopers & Bags', retailPriceElasticity: -0.390199999999997 },
  '1519': { name: 'Dog Treats', retailPriceElasticity: -0.41073333333333 },
  '1521': { name: 'Dog Food', retailPriceElasticity: -0.6586 },
  '1522': { name: 'Training Pads and Trays', retailPriceElasticity: -0.554466666666662 },
  '1523': { name: 'Dolls & Accessories', retailPriceElasticity: -0.592599999999997 },
  '1526': { name: 'Dried Fruit & Raisins', retailPriceElasticity: -0.313933333333333 },
  '1527': { name: 'Vegetables', retailPriceElasticity: -0.3 },
  '1528': { name: 'Drills & Drivers', retailPriceElasticity: -0.832400000000007 },
  '1529': { name: 'Automatic Grounds Irrigation', retailPriceElasticity: -0.701866666666668 },
  '1530': { name: 'Sockets Hand Tools', retailPriceElasticity: -0.616799999999998 },
  '1531': { name: 'Diagnostic & Examination Supplies', retailPriceElasticity: -0.74366666666667 },
  '1532': { name: 'Cameras', retailPriceElasticity: -0.863200000000008 },
  '1533': { name: 'Television Accessories', retailPriceElasticity: -0.623399999999998 },
  '1534': { name: 'Video Games', retailPriceElasticity: -0.599199999999997 },
  '1535': { name: 'Computer Cables', retailPriceElasticity: -0.427599999999996 },
  '1536': { name: 'Microphones', retailPriceElasticity: -0.813333333333339 },
  '1537': { name: 'Eczema, Psoriasis & Rosacea Care', retailPriceElasticity: -0.470866666666661 },
  '1538': { name: 'Exhaust Systems', retailPriceElasticity: -0.762733333333337 },
  '1539': { name: 'Mattress Accessories', retailPriceElasticity: -0.730466666666669 },
  '1540': { name: 'Electrical Contols & Indicators', retailPriceElasticity: -0.726800000000002 },
  '1541': { name: "Men's Shaving", retailPriceElasticity: -0.558866666666662 },
  '1542': { name: 'Electrical Accessories', retailPriceElasticity: -0.426133333333329 },
  '1543': { name: 'Kids Toy Electronics', retailPriceElasticity: -0.591866666666664 },
  '1544': { name: 'Pill Distribution', retailPriceElasticity: -0.350599999999998 },
  '1545': { name: 'Accent Furniture', retailPriceElasticity: -0.811866666666673 },
  '1546': { name: 'Endurance & Energy Supplements', retailPriceElasticity: -0.587466666666663 },
  '1547': { name: 'Energy Drinks', retailPriceElasticity: -0.594066666666664 },
  '1549': { name: 'Equestrian', retailPriceElasticity: -0.668133333333333 },
  '1550': { name: 'Professional Medical Supplies', retailPriceElasticity: -0.51486666666666 },
  '1552': { name: 'Exotic Apparel', retailPriceElasticity: -0.320533333333333 },
  '1554': { name: 'Makeup Brushes & Tools', retailPriceElasticity: -0.333733333333332 },
  '1555': { name: 'Eye Makeup', retailPriceElasticity: -0.371133333333331 },
  '1556': { name: 'Face Makeup', retailPriceElasticity: -0.396066666666663 },
  '1557': { name: 'Facial Skin Care', retailPriceElasticity: -0.478933333333327 },
  '1558': { name: 'Paper Household Supplies', retailPriceElasticity: -0.522933333333327 },
  '1559': { name: 'Pregancy & Maternity Tools', retailPriceElasticity: -0.571333333333329 },
  '1560': { name: 'Men Clothing', retailPriceElasticity: -0.550066666666662 },
  '1562': { name: 'Fan Shop Artwork', retailPriceElasticity: -0.420999999999996 },
  '1564': { name: 'Men Wallets', retailPriceElasticity: -0.471599999999994 },
  '1565': { name: 'Women Handbags', retailPriceElasticity: -0.670333333333333 },
  '1566': { name: 'Weight Gain & Loss Supplements', retailPriceElasticity: -0.610199999999998 },
  '1567': { name: 'Faucet Parts', retailPriceElasticity: -0.649066666666666 },
  '1568': { name: 'Water Filtration & Softeners', retailPriceElasticity: -0.692333333333334 },
  '1569': { name: "Women's Health", retailPriceElasticity: -0.436399999999995 },
  '1570': { name: 'Lawn Care', retailPriceElasticity: -0.58893333333333 },
  '1571': { name: 'Soils, Fertilizers & Mulches', retailPriceElasticity: -0.482599999999994 },
  '1573': { name: 'Novelty Toys', retailPriceElasticity: -0.372599999999997 },
  '1574': { name: 'Outdoor Heating', retailPriceElasticity: -0.807466666666672 },
  '1576': { name: 'Fish Finders', retailPriceElasticity: -0.83093333333334 },
  '1577': { name: 'Fish Food', retailPriceElasticity: -0.417333333333329 },
  '1578': { name: 'Fishing Equipment & Accessories', retailPriceElasticity: -0.58013333333333 },
  '1579': { name: 'Showerheads', retailPriceElasticity: -0.673266666666667 },
  '1580': { name: 'Shelves & Accessories', retailPriceElasticity: -0.6674 },
  '1582': { name: 'Household Fans', retailPriceElasticity: -0.690133333333334 },
  '1583': { name: 'Decorative Pillows', retailPriceElasticity: -0.415133333333329 },
  '1584': { name: 'Professional Clothing', retailPriceElasticity: -0.645399999999999 },
  '1585': { name: 'Football Equipment', retailPriceElasticity: -0.627066666666665 },
  '1586': { name: 'Home Fragrance', retailPriceElasticity: -0.398266666666663 },
  '1587': { name: 'Wine Racks', retailPriceElasticity: -0.648333333333333 },
  '1589': { name: 'Plants & Flowers', retailPriceElasticity: -0.456199999999995 },
  '1590': { name: 'Herbs, Spices & Seasonings', retailPriceElasticity: -0.352799999999998 },
  '1591': { name: 'Seafood', retailPriceElasticity: -0.59113333333333 },
  '1592': { name: 'Frozen Food', retailPriceElasticity: -0.301466666666667 },
  '1593': { name: 'Jams, Jellies & Sweet Spreads', retailPriceElasticity: -0.319066666666666 },
  '1594': { name: 'Juices', retailPriceElasticity: -0.317599999999999 },
  '1595': { name: 'Fruit Snacks', retailPriceElasticity: -0.324933333333332 },
  '1596': { name: 'Soldering & Brazing Equipment', retailPriceElasticity: -0.585266666666663 },
  '1597': { name: 'Appliance Accessories', retailPriceElasticity: -0.726066666666669 },
  '1598': { name: 'Furniture Hardware', retailPriceElasticity: -0.412199999999996 },
  '1600': { name: 'Game Consoles', retailPriceElasticity: -0.845600000000007 },
  '1603': { name: 'Outdoor Carts', retailPriceElasticity: -0.82213333333334 },
  '1604': { name: 'Lawn Watering Equipment', retailPriceElasticity: -0.553733333333329 },
  '1605': { name: 'Irons & Steamers', retailPriceElasticity: -0.686466666666667 },
  '1606': { name: 'Gate Hardware', retailPriceElasticity: -0.740000000000003 },
  '1607': { name: 'Coach & Referee', retailPriceElasticity: -0.523666666666661 },
  '1608': { name: 'Pudding & Gelatin', retailPriceElasticity: -0.357933333333331 },
  '1610': { name: 'Girls Coats and Jackets', retailPriceElasticity: -0.657133333333333 },
  '1611': { name: 'Girls Active Clothing', retailPriceElasticity: -0.451066666666661 },
  '1612': { name: 'Bracelets', retailPriceElasticity: -0.495799999999993 },
  '1613': { name: 'Girls Clothing', retailPriceElasticity: -0.404866666666663 },
  '1614': { name: 'Earrings', retailPriceElasticity: -0.459866666666661 },
  '1615': { name: 'Girls Athletic Shoes', retailPriceElasticity: -0.599933333333331 },
  '1616': { name: 'Girls Accessories', retailPriceElasticity: -0.355733333333331 },
  '1617': { name: 'Girls Sleepwear', retailPriceElasticity: -0.456933333333328 },
  '1618': { name: 'Girls Shoes', retailPriceElasticity: -0.552999999999995 },
  '1619': { name: 'Pins', retailPriceElasticity: -0.379933333333331 },
  '1620': { name: 'Necklace', retailPriceElasticity: -0.528799999999994 },
  '1621': { name: 'Rings', retailPriceElasticity: -0.536866666666661 },
  '1623': { name: 'Girls Swimwear', retailPriceElasticity: -0.496533333333327 },
  '1625': { name: 'Golf Accessories', retailPriceElasticity: -0.6608 },
  '1626': { name: 'Men Hats & Caps', retailPriceElasticity: -0.445933333333328 },
  '1627': { name: 'Golf Cart Accessories', retailPriceElasticity: -0.782533333333338 },
  '1628': { name: 'Golf Clubs', retailPriceElasticity: -0.850000000000008 },
  '1630': { name: 'Umbrellas', retailPriceElasticity: -0.505333333333327 },
  '1631': { name: 'Laptop Accessories', retailPriceElasticity: -0.605066666666664 },
  '1632': { name: 'Greenhouses & Accessories', retailPriceElasticity: -0.800133333333339 },
  '1633': { name: 'Grill Parts & Accessories', retailPriceElasticity: -0.590399999999997 },
  '1634': { name: 'Dress Up & Pretend Play', retailPriceElasticity: -0.48406666666666 },
  '1635': { name: 'Safes', retailPriceElasticity: -0.806733333333339 },
  '1636': { name: 'Field Court & Rink Equipment', retailPriceElasticity: -0.765666666666671 },
  '1637': { name: 'Gym Bags', retailPriceElasticity: -0.58673333333333 },
  '1638': { name: 'Hair Care', retailPriceElasticity: -0.492133333333327 },
  '1639': { name: 'Hair Loss Products', retailPriceElasticity: -0.629999999999998 },
  '1640': { name: 'Hair Care Accessories', retailPriceElasticity: -0.315399999999999 },
  '1641': { name: 'Hair Coloring', retailPriceElasticity: -0.363799999999998 },
  '1643': { name: 'Hair Care Appliances', retailPriceElasticity: -0.588199999999997 },
  '1644': { name: 'Hair Styling', retailPriceElasticity: -0.437866666666662 },
  '1646': { name: 'Hand Care', retailPriceElasticity: -0.443733333333328 },
  '1647': { name: 'Mixers', retailPriceElasticity: -0.82873333333334 },
  '1649': { name: 'Shears', retailPriceElasticity: -0.721666666666669 },
  '1650': { name: 'Pots, Planters & Container Accessories', retailPriceElasticity: -0.525866666666661 },
  '1651': { name: 'Women Hats & Caps', retailPriceElasticity: -0.360133333333331 },
  '1653': { name: 'Outdoor Knives & Tools', retailPriceElasticity: -0.628533333333332 },
  '1654': { name: 'Hiking Safety & Survival', retailPriceElasticity: -0.354266666666665 },
  '1655': { name: 'Outdoor Hydration & Filtration', retailPriceElasticity: -0.557399999999995 },
  '1656': { name: 'Outdoor Personal Lights & Lanterns', retailPriceElasticity: -0.480399999999994 },
  '1657': { name: 'Camping Personal Care', retailPriceElasticity: -0.469399999999994 },
  '1659': { name: 'Home Audio Accessories', retailPriceElasticity: -0.652733333333333 },
  '1660': { name: 'Home Automation Devices', retailPriceElasticity: -0.773000000000004 },
  '1661': { name: 'Livestock Supplies', retailPriceElasticity: -0.676933333333334 },
  '1663': { name: 'Pipe & Pipe Fittings', retailPriceElasticity: -0.560333333333329 },
  '1664': { name: 'Pool Parts & Accessories', retailPriceElasticity: -0.805266666666672 },
  '1665': { name: 'Pools, Hot Tubs & Saunas', retailPriceElasticity: -0.843400000000007 },
  '1666': { name: 'Brooms & Sweeping Tools', retailPriceElasticity: -0.504599999999993 },
  '1667': { name: 'Household Cleaning Supplies', retailPriceElasticity: -0.357199999999998 },
  '1668': { name: 'Cleaning Gloves', retailPriceElasticity: -0.441533333333328 },
  '1669': { name: 'Dusting Tools', retailPriceElasticity: -0.394599999999997 },
  '1670': { name: 'Household Lighters & Matches', retailPriceElasticity: -0.453266666666661 },
  '1671': { name: 'Mopping', retailPriceElasticity: -0.409999999999996 },
  '1672': { name: 'Humidifiers', retailPriceElasticity: -0.675466666666667 },
  '1674': { name: 'Ice Cream', retailPriceElasticity: -0.3022 },
  '1675': { name: 'Hockey & Ice Hockey', retailPriceElasticity: -0.58233333333333 },
  '1676': { name: 'Ice Makers', retailPriceElasticity: -0.859533333333341 },
  '1677': { name: 'Snow Removal', retailPriceElasticity: -0.74806666666667 },
  '1678': { name: 'Ice Skating', retailPriceElasticity: -0.738533333333336 },
  '1679': { name: 'Printer & Scanner Accessories', retailPriceElasticity: -0.751733333333337 },
  '1680': { name: 'Indoor Gardening & Hydroponics', retailPriceElasticity: -0.701133333333335 },
  '1682': { name: 'Safety Signs & Signals', retailPriceElasticity: -0.600666666666664 },
  '1683': { name: 'Industrial Tools', retailPriceElasticity: -0.327866666666666 },
  '1684': { name: 'Ink & Toner', retailPriceElasticity: -0.740733333333336 },
  '1685': { name: 'Printer Paper', retailPriceElasticity: -0.549333333333328 },
  '1686': { name: 'Rollerblades and Roller Skates', retailPriceElasticity: -0.747333333333337 },
  '1687': { name: 'Modems & Routers', retailPriceElasticity: -0.849266666666674 },
  '1690': { name: 'Keyboard & Mice', retailPriceElasticity: -0.563999999999996 },
  '1692': { name: 'Locks', retailPriceElasticity: -0.447399999999995 },
  '1694': { name: 'Electric Bicycle & Scooters', retailPriceElasticity: -0.834600000000007 },
  '1696': { name: 'Lunch Boxes', retailPriceElasticity: -0.429799999999996 },
  '1697': { name: 'Kids Room Décor', retailPriceElasticity: -0.555199999999995 },
  '1700': { name: 'Kids Bicycle', retailPriceElasticity: -0.812600000000006 },
  '1701': { name: 'Luggage & Suitcases', retailPriceElasticity: -0.801600000000006 },
  '1702': { name: 'Tablets', retailPriceElasticity: -0.847066666666674 },
  '1705': { name: 'Kitchen Measuring Tools', retailPriceElasticity: -0.437133333333329 },
  '1707': { name: 'Dining Chairs, Tables & Sets', retailPriceElasticity: -0.851466666666674 },
  '1708': { name: 'Thermometers & Timers', retailPriceElasticity: -0.432733333333329 },
  '1710': { name: 'Toy Sports Equipment', retailPriceElasticity: -0.771533333333338 },
  '1711': { name: 'Door Chimes & Bells', retailPriceElasticity: -0.790600000000005 },
  '1712': { name: 'Knife Hand Tools', retailPriceElasticity: -0.463533333333328 },
  '1714': { name: 'Office Electronics', retailPriceElasticity: -0.767133333333337 },
  '1715': { name: 'Lacrosse Equipment', retailPriceElasticity: -0.646133333333332 },
  '1716': { name: 'Track & Field Equipment', retailPriceElasticity: -0.473799999999994 },
  '1717': { name: 'Electronics Stands', retailPriceElasticity: -0.708466666666668 },
  '1718': { name: 'Laundry Supplies', retailPriceElasticity: -0.428333333333329 },
  '1719': { name: 'Filing Products', retailPriceElasticity: -0.423199999999996 },
  '1720': { name: 'Lighting Accessories', retailPriceElasticity: -0.365999999999998 },
  '1722': { name: 'Lip Care & Makeup', retailPriceElasticity: -0.349133333333332 },
  '1723': { name: 'Skateboarding', retailPriceElasticity: -0.687200000000001 },
  '1724': { name: 'Basket Storage', retailPriceElasticity: -0.606533333333331 },
  '1725': { name: 'Magnetic Sweepers', retailPriceElasticity: -0.401199999999997 },
  '1726': { name: 'Mailboxes', retailPriceElasticity: -0.702600000000001 },
  '1729': { name: 'Masonry Hand Tools', retailPriceElasticity: -0.459133333333328 },
  '1730': { name: 'Marine Electronics', retailPriceElasticity: -0.796466666666672 },
  '1733': { name: 'Martial Arts', retailPriceElasticity: -0.556666666666662 },
  '1735': { name: 'Mattresses', retailPriceElasticity: -0.862466666666675 },
  '1737': { name: 'Men Active Clothing', retailPriceElasticity: -0.565466666666662 },
  '1738': { name: 'Men Accessories', retailPriceElasticity: -0.452533333333328 },
  '1739': { name: 'Men Boots', retailPriceElasticity: -0.827266666666673 },
  '1740': { name: 'Men Coats & Jackets', retailPriceElasticity: -0.775200000000004 },
  '1741': { name: "Men's Fragrance", retailPriceElasticity: -0.684266666666667 },
  '1743': { name: 'Men Shoes', retailPriceElasticity: -0.737800000000003 },
  '1744': { name: 'Key Chains', retailPriceElasticity: -0.314666666666666 },
  '1745': { name: 'Men Sleepwear', retailPriceElasticity: -0.569866666666663 },
  '1747': { name: 'Microscopes', retailPriceElasticity: -0.735600000000003 },
  '1748': { name: 'Popcorn', retailPriceElasticity: -0.382866666666664 },
  '1749': { name: 'Water', retailPriceElasticity: -0.338133333333332 },
  '1750': { name: 'Outdoor Cooling', retailPriceElasticity: -0.638799999999999 },
  '1751': { name: 'Networking Equipment', retailPriceElasticity: -0.791333333333338 },
  '1752': { name: 'Measuring & Layout Tools', retailPriceElasticity: -0.596266666666664 },
  '1753': { name: 'Nail Care', retailPriceElasticity: -0.341066666666665 },
  '1754': { name: 'Building Toys', retailPriceElasticity: -0.653466666666666 },
  '1755': { name: 'Weed & Moss Control', retailPriceElasticity: -0.621933333333331 },
  '1756': { name: 'Motorcycle Accessories', retailPriceElasticity: -0.732666666666669 },
  '1757': { name: 'Motorcycle Parts', retailPriceElasticity: -0.712133333333335 },
  '1758': { name: 'Motorcycle Fluids & Maintenance', retailPriceElasticity: -0.48626666666666 },
  '1759': { name: 'Motorcycle Wheels & Tires', retailPriceElasticity: -0.809666666666673 },
  '1760': { name: 'Motorcycle Exhaust Systems', retailPriceElasticity: -0.811133333333339 },
  '1762': { name: 'Portable Audio Accessories', retailPriceElasticity: -0.323466666666666 },
  '1763': { name: 'Multivitamin', retailPriceElasticity: -0.551533333333329 },
  '1764': { name: 'Steamers & Pasta Pots', retailPriceElasticity: -0.607999999999998 },
  '1766': { name: 'Nailers & Staples', retailPriceElasticity: -0.83973333333334 },
  '1767': { name: 'Sleep & Snoring', retailPriceElasticity: -0.415866666666663 },
  '1768': { name: 'Pliers Hand Tools', retailPriceElasticity: -0.511933333333327 },
  '1770': { name: 'Smoking Cessation', retailPriceElasticity: -0.640999999999999 },
  '1771': { name: 'Video Game Accessories', retailPriceElasticity: -0.803800000000006 },
  '1773': { name: 'Nursery Décor', retailPriceElasticity: -0.547133333333328 },
  '1774': { name: 'Bassinet Bedding', retailPriceElasticity: -0.537599999999995 },
  '1775': { name: 'Cradle Bedding', retailPriceElasticity: -0.611666666666664 },
  '1776': { name: 'Nursery Furniture', retailPriceElasticity: -0.770800000000004 },
  '1777': { name: 'Crib Bedding', retailPriceElasticity: -0.561066666666662 },
  '1778': { name: 'Toddler Bedding', retailPriceElasticity: -0.679866666666667 },
  '1780': { name: 'Incontinence & Ostomy', retailPriceElasticity: -0.625599999999998 },
  '1782': { name: 'Ladders', retailPriceElasticity: -0.818466666666673 },
  '1784': { name: 'Vases', retailPriceElasticity: -0.602866666666664 },
  '1786': { name: 'Patio Furniture & Accessories', retailPriceElasticity: -0.82653333333334 },
  '1787': { name: 'Pest Control', retailPriceElasticity: -0.513399999999994 },
  '1788': { name: 'Playground Equipment', retailPriceElasticity: -0.781800000000005 },
  '1789': { name: 'Pond Equipment', retailPriceElasticity: -0.729733333333336 },
  '1790': { name: 'Raw Materials', retailPriceElasticity: -0.39313333333333 },
  '1792': { name: 'Retail Store Fixtures', retailPriceElasticity: -0.583066666666663 },
  '1794': { name: 'Seasonal Décor', retailPriceElasticity: -0.566199999999996 },
  '1795': { name: 'Shoe Accessories', retailPriceElasticity: -0.349866666666665 },
  '1797': { name: 'Shower Fixtures', retailPriceElasticity: -0.729000000000003 },
  '1799': { name: 'Sugars & Sweeteners', retailPriceElasticity: -0.38873333333333 },
  '1800': { name: 'Ping Pong', retailPriceElasticity: -0.75246666666667 },
  '1801': { name: 'Telephone Accessories', retailPriceElasticity: -0.374799999999997 },
  '1802': { name: 'Routers & Parts', retailPriceElasticity: -0.792066666666672 },
  '1804': { name: 'Vacuum Parts & Accessories', retailPriceElasticity: -0.462799999999994 },
  '1805': { name: 'Video Projectors', retailPriceElasticity: -0.857333333333341 },
  '1806': { name: 'Water Pumps', retailPriceElasticity: -0.817000000000006 },
  '1807': { name: 'Maternity Clothing', retailPriceElasticity: -0.532466666666661 },
  '1808': { name: 'Yoga Equipment & Accessories', retailPriceElasticity: -0.533933333333328 },
  '1809': { name: 'Outdoor Storage', retailPriceElasticity: -0.802333333333339 },
  '1810': { name: 'Dimmers, Switches & Outlets', retailPriceElasticity: -0.578666666666663 },
  '1812': { name: 'Pasta & Pizza Tools', retailPriceElasticity: -0.646866666666666 },
  '1814': { name: 'Personal Defense', retailPriceElasticity: -0.57573333333333 },
  '1818': { name: 'Protein', retailPriceElasticity: -0.654933333333333 },
  '1820': { name: 'Playground Games', retailPriceElasticity: -0.420266666666663 },
  '1821': { name: 'Stuffed Animals & Plush Toys', retailPriceElasticity: -0.477466666666661 },
  '1823': { name: 'Pressure Cookers', retailPriceElasticity: -0.789866666666672 },
  '1824': { name: 'TV Mounts & Stands', retailPriceElasticity: -0.731933333333336 },
  '1825': { name: 'Pry Bars', retailPriceElasticity: -0.607266666666664 },
  '1826': { name: 'Speciality Kitchen Appliance', retailPriceElasticity: -0.736333333333336 },
  '1827': { name: 'Ranges & Accessories', retailPriceElasticity: -0.830200000000007 },
  '1828': { name: 'Recessed Lighting', retailPriceElasticity: -0.744400000000003 },
  '1829': { name: 'Reptile Food & Supplies', retailPriceElasticity: -0.375533333333331 },
  '1831': { name: 'Respiratory Aids & Accessories', retailPriceElasticity: -0.704800000000002 },
  '1832': { name: 'Bathroom Fixtures', retailPriceElasticity: -0.519999999999994 },
  '1834': { name: 'Rotary Tools', retailPriceElasticity: -0.762000000000004 },
  '1835': { name: 'Cycling Apparel', retailPriceElasticity: -0.539066666666661 },
  '1836': { name: 'Skin Care Devices', retailPriceElasticity: -0.685733333333334 },
  '1837': { name: 'Sand & Beach Toys', retailPriceElasticity: -0.703333333333335 },
  '1838': { name: 'Bed Pillows & Pillowcases', retailPriceElasticity: -0.619733333333331 },
  '1842': { name: 'Small Animal Food & Treats', retailPriceElasticity: -0.38433333333333 },
  '1844': { name: 'Soccer Equipment', retailPriceElasticity: -0.520733333333327 },
  '1848': { name: 'Space Heaters & Accessories', retailPriceElasticity: -0.783266666666671 },
  '1849': { name: 'Women Clothing', retailPriceElasticity: -0.474533333333327 },
  '1850': { name: 'Stand-Up Paddleboard', retailPriceElasticity: -0.858066666666675 },
  '1851': { name: 'Stethoscopes', retailPriceElasticity: -0.75686666666667 },
  '1852': { name: 'Surfing & Accessories', retailPriceElasticity: -0.663733333333333 },
  '1854': { name: 'Televisions', retailPriceElasticity: -0.865400000000008 },
  '1857': { name: 'Time Clocks & Cards', retailPriceElasticity: -0.797933333333339 },
  '1860': { name: 'Tobacco-Related Products', retailPriceElasticity: -0.423933333333329 },
  '1861': { name: 'Toilet Accessories', retailPriceElasticity: -0.362333333333331 },
  '1866': { name: 'Women Hair Removal', retailPriceElasticity: -0.472333333333327 },
  '1868': { name: 'Volleyball Equipment', retailPriceElasticity: -0.624133333333332 },
  '1869': { name: 'Water Sports & Accessories', retailPriceElasticity: -0.825800000000007 },
  '1870': { name: 'Christmas Products', retailPriceElasticity: -0.632933333333332 },
  '1871': { name: 'Water Heaters', retailPriceElasticity: -0.844133333333341 },
  '1874': { name: 'Window Treatment Shutters', retailPriceElasticity: -0.566933333333329 },
  '1875': { name: 'Window Hardware', retailPriceElasticity: -0.508999999999993 },
  '1876': { name: 'Window Treatment Draperies & Curtains', retailPriceElasticity: -0.527333333333328 },
  '1878': { name: 'Women Active Clothing', retailPriceElasticity: -0.576466666666663 },
  '1879': { name: 'Women Athletic Socks', retailPriceElasticity: -0.466466666666661 },
  '1880': { name: 'Women Boots', retailPriceElasticity: -0.780333333333338 },
  '1881': { name: 'Women Accessories', retailPriceElasticity: -0.38653333333333 },
  '1882': { name: 'Women Sleep & Lingerie', retailPriceElasticity: -0.489933333333327 },
  '1883': { name: 'Women Athletic Shoes', retailPriceElasticity: -0.786933333333338 },
  '1884': { name: 'Women Swimwear', retailPriceElasticity: -0.503133333333327 },
  '1885': { name: 'Bridal Clothing', retailPriceElasticity: -0.368199999999998 },
  '1886': { name: 'Women Wallets', retailPriceElasticity: -0.407066666666663 },
  '1887': { name: 'Women Coats & Jackets', retailPriceElasticity: -0.722400000000002 },
  '1889': { name: "Women's Fragrance", retailPriceElasticity: -0.636599999999999 },
  '1890': { name: 'Women Shoes', retailPriceElasticity: -0.716533333333335 },
  '1891': { name: 'Women Sandals', retailPriceElasticity: -0.706266666666668 },
  '1901': { name: 'Motorcycle Lighting', retailPriceElasticity: -0.711400000000002 },
  '1902': { name: 'Motorcycles', retailPriceElasticity: -0.861733333333341 },
  '1903': { name: 'Automotive Gauge', retailPriceElasticity: -0.624866666666665 },
  '1904': { name: 'Automotive Fuel & Ignition', retailPriceElasticity: -0.59553333333333 },
  '1906': { name: 'Automotive Pulling Tools', retailPriceElasticity: -0.589666666666663 },
  '1907': { name: 'Car Care', retailPriceElasticity: -0.439333333333329 },
  '1908': { name: 'CDs & Software', retailPriceElasticity: -0.48186666666666 },
  '1909': { name: 'Lawn Mowers & Tractors', retailPriceElasticity: -0.748800000000003 },
  '1910': { name: 'Travel Beverage Containers', retailPriceElasticity: -0.50826666666666 },
  '1911': { name: 'Classroom Supplies', retailPriceElasticity: -0.373333333333331 },
  '1912': { name: 'Office Desk Accessories', retailPriceElasticity: -0.332999999999999 },
  '1913': { name: 'Livestock Management', retailPriceElasticity: -0.659333333333333 },
  '1914': { name: 'Gift Cards', retailPriceElasticity: -0.715066666666669 },
  '1915': { name: 'Drawing Mediums', retailPriceElasticity: -0.343266666666665 },
  '1916': { name: 'Art Paint', retailPriceElasticity: -0.385799999999997 },
  '1917': { name: 'Clay & Dough', retailPriceElasticity: -0.361599999999998 },
  '1918': { name: 'School Supply Paper', retailPriceElasticity: -0.307333333333333 },
  '1919': { name: 'Craft Kits & Projects', retailPriceElasticity: -0.387999999999997 },
  '1920': { name: 'Craft Supplies', retailPriceElasticity: -0.365266666666664 },
  '1921': { name: 'Glue & Adhesives', retailPriceElasticity: -0.325666666666666 },
  '1922': { name: 'Snack Food Gifts', retailPriceElasticity: -0.626333333333332 },
  '1923': { name: 'Classroom Curriculum', retailPriceElasticity: -0.536133333333328 },
  '1924': { name: 'Kids Indoor/Outdoor Play', retailPriceElasticity: -0.663 },
  '1925': { name: 'Classroom Furnishings', retailPriceElasticity: -0.608733333333331 },
  '9999': { name: 'Unclassified', retailPriceElasticity: -0.550799999999995 }
};
