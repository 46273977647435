import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import {
  AdjustmentModalHeader,
  AdjustmentSuccessMessage
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Components/ModalComponents';
import { Divider } from '@mui/material';
import {
  ContentScoreFields,
  FORM_FIELD,
  PaidTrafficFields,
  PlanTypeOption,
  PromotionType,
  RatingsReviewFields,
  View
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import {
  AdjustmentForm,
  ContentScoreInput,
  DefaultInput,
  PaidTrafficInput,
  PublishOptions,
  RatingsReviewInput
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { useStacklineTheme } from '@stackline/ui';
import { useAdjustmentRequest } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAdjustmentRequest';
import { ConfirmAdjustment } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Views/ConfirmAdjustment';
import { FinalizeAdjustment } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Views/FinalizeAdjustment';
import { InitializeAdjustment } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Views/InitializeAdjustment';
import { useQueryClient } from 'react-query';
import {
  ADJUSTMENT_TABLE_QUERY,
  FORECAST_SUMMARY_KEYMETRIC_QUERY
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastQueryKeys';
import { ForecastsAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import { ViewAdjustment } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Views/ViewAdjustment';
import {
  isContentScoreInput,
  isDefaultInput,
  isPaidTrafficInput,
  isRatingsReviewInput
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/utils';
import { AxiosResponse } from 'axios';
import { PostAdjustmentParams } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/serverProxy/createAdjustment';
import { useForecastAdjustmentsType } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/ForecastAdjustmentsTypeProvider';
import ScrollableContainer from 'src/components/BeaconRedesignComponents/ScrollableContainer';
import { SmallCancelIcon } from 'src/components/SvgIcons';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { useConflictingWeekIds } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useConflictingWeekIds';
import BulkTemplateCircularLoadingInner from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/BulkTemplateCircularLoadingInner';
import { BulkAdjustmentUploadSuccessAnimation } from 'src/components/Animations/BulkAdjustmentUploadSuccessAnimation/BulkAdjustmentUploadSuccessAnimation';
import { Link } from 'react-router-dom';
import { useAppSelector, useSnackbar } from 'src/utils/Hooks';
import { BEACON_SUBTABS, BEACON_TABS } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';
import _get from 'lodash/get';
import { settingsErrorMessage } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/utils';
import { usePollingContext } from 'src/providers/PollingContextProvider';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { shouldShowPromotionsAdjustment } from 'src/utils/app';

export const GenericStyledDialogue = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '757px',
    height: '641px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px'
  },
  textarea: {
    color: '#052849 !important'
  },
  overflowX: 'hidden',
  '&::-webkit-scrollbar:': {
    width: '5px'
  },

  '&:hover': {
    '&::-webkit-scrollbar-thumb': {
      'background-color': '#7e8fa8',
      'border-radius': '15px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff'
    }
  }
});

const options = [
  { label: 'Organic Traffic', id: PlanTypeOption.OrganicTraffic },
  { label: 'Paid Traffic', id: PlanTypeOption.PaidTraffic },
  { label: 'Other Traffic', id: PlanTypeOption.OtherTraffic },
  { label: 'Retail Price', id: PlanTypeOption.RetailPrice },
  { label: 'Content Score', id: PlanTypeOption.ContentScore },
  { label: 'Ratings & Reviews', id: PlanTypeOption.RatingsReviews },
  { label: 'In-Stock Rate', id: PlanTypeOption.InStockRate },
  { label: 'Buy Box', id: PlanTypeOption.BuyBox }
];

/**
 * Default adjustment input.
 * Used for: Organic traffic, Other traffic, Retail price, In-stock rate, Buy box adjustments
 */
const defaultInput: DefaultInput = {
  total: ''
};

/**
 * Content score adjustment input.
 * Used for: Content score adjustments
 */
const contentScoreInput: ContentScoreInput = {
  [ContentScoreFields.TitleScore]: '',
  [ContentScoreFields.BulletScore]: '',
  [ContentScoreFields.ImageScore]: '',
  [ContentScoreFields.VideoScore]: '',
  [ContentScoreFields.A_Score]: ''
};

/**
 * Ratings & reviews adjustment input.
 * Used for: Ratings & reviews adjustments
 */
const ratingsReviewInput: RatingsReviewInput = {
  [RatingsReviewFields.AverageRating]: '',
  [RatingsReviewFields.ReviewQuantity]: ''
};

/**
 * Ad spend/Paid traffic adjustment input.
 * Used for: Ratings & reviews adjustments
 */
const paidTrafficInput: PaidTrafficInput = {
  [PaidTrafficFields.AdSpend]: '',
  [PaidTrafficFields.CostPerClick]: ''
};

const initialInputValuesByPlanType = {
  [PlanTypeOption.OrganicTraffic]: defaultInput,
  [PlanTypeOption.OtherTraffic]: defaultInput,
  [PlanTypeOption.PaidTraffic]: paidTrafficInput,
  [PlanTypeOption.ContentScore]: contentScoreInput,
  [PlanTypeOption.BuyBox]: defaultInput,
  [PlanTypeOption.InStockRate]: defaultInput,
  [PlanTypeOption.RatingsReviews]: ratingsReviewInput,
  [PlanTypeOption.RetailPrice]: defaultInput
};

/**
 * From the selected planType option from context (default is organic traffic), blankFormState will be used for rendering initial modal page.
 */
export const blankFormState = (planType: PlanTypeOption): AdjustmentForm => {
  return {
    [FORM_FIELD.TYPE]: planType,
    [FORM_FIELD.TITLE]: '',
    [FORM_FIELD.DESCRIPTION]: '',
    [FORM_FIELD.START_DATE]: null,
    [FORM_FIELD.END_DATE]: null,
    [FORM_FIELD.INPUT_VALUES]: initialInputValuesByPlanType[planType],
    [FORM_FIELD.PROMOTION_TYPE]: PromotionType.None,
    [FORM_FIELD.IS_PROMOTION_ADJUSTMENT]: false
  };
};

const populateInputValues = (
  blankInput: DefaultInput | PaidTrafficInput | ContentScoreInput | RatingsReviewInput,
  adjustmentData: ForecastsAdjustmentData
) => {
  switch (true) {
    case isContentScoreInput(blankInput): {
      return {
        [ContentScoreFields.TitleScore]: String(Math.round(adjustmentData.titleScore * 100)),
        [ContentScoreFields.BulletScore]: String(Math.round(adjustmentData.bulletScore * 100)),
        [ContentScoreFields.ImageScore]: String(Math.round(adjustmentData.imageScore * 100)),
        [ContentScoreFields.VideoScore]: String(Math.round(adjustmentData.videoScore * 100)),
        [ContentScoreFields.A_Score]: String(Math.round(adjustmentData.aplusScore * 100))
      };
    }
    case isRatingsReviewInput(blankInput): {
      return {
        [RatingsReviewFields.AverageRating]: String(adjustmentData.reviewAvgRating),
        [RatingsReviewFields.ReviewQuantity]: String(adjustmentData.reviewCount)
      };
    }
    case isPaidTrafficInput(blankInput): {
      return {
        [PaidTrafficFields.AdSpend]: String(adjustmentData.adSpend),
        [PaidTrafficFields.CostPerClick]: String(adjustmentData.cpc)
      };
    }
    case isDefaultInput(blankInput): {
      const { planType } = adjustmentData;
      if ([PlanTypeOption.BuyBox, PlanTypeOption.InStockRate].includes(planType as PlanTypeOption)) {
        return {
          total: String(Math.round(adjustmentData.adjustmentChangeValue * 100)) // Convert decimal to integer
        };
      }
      return {
        total: String(adjustmentData.adjustmentChangeValue)
      };
    }
    default:
      return {
        total: String(adjustmentData.adjustmentChangeValue)
      };
  }
};

/**
 * Used to populate the adjustment form when viewing a Published/Draft adjustment or a recommendation.
 * @param adjustmentData The adjustment data from the adjustment row
 * @param shouldPrefillDates Boolean to determine if we should use the adjustment data dates in the initial form. Defaults to true.
 * @returns A prefilled adjustment form based on an adjustment row.
 */
const populateAdjustmentForm = (adjustmentData: ForecastsAdjustmentData, isRecommendation = false): AdjustmentForm => {
  return {
    [FORM_FIELD.TYPE]: adjustmentData.planType as PlanTypeOption,
    [FORM_FIELD.TITLE]: isRecommendation ? '' : adjustmentData.adjustmentTitle,
    [FORM_FIELD.DESCRIPTION]: isRecommendation ? '' : adjustmentData.adjustmentDescription,
    [FORM_FIELD.START_DATE]: isRecommendation ? null : String(adjustmentData.startWeekId),
    [FORM_FIELD.END_DATE]: isRecommendation ? null : String(adjustmentData.endWeekId),
    [FORM_FIELD.IS_PROMOTION_ADJUSTMENT]:
      adjustmentData.planType === PlanTypeOption.RetailPrice && !!adjustmentData.promotionType,
    [FORM_FIELD.PROMOTION_TYPE]: adjustmentData.promotionType ? adjustmentData.promotionType : PromotionType.None,
    [FORM_FIELD.INPUT_VALUES]: populateInputValues(
      initialInputValuesByPlanType[adjustmentData.planType],
      adjustmentData
    )
  };
};

interface AdjustmentModalProps {
  open: boolean;
  retailerSku: string;
  stacklineSku: string;
  categoryId: string | number;
  subcategoryId: string | number;
  onClose: () => void;
  /**
   * Triggers the polling behavior in the parent component
   */
  setIsPolling: Dispatch<SetStateAction<boolean>>;
  /**
   * Product metadata for the adjustment
   */
  product?: ForecastsAdjustmentData['product'];
  /**
   * Adjustment data from an adjustment row. Can be sourced from a Published/Draft adjustment or a Recommended adjustment
   */
  adjustmentData?: ForecastsAdjustmentData;
  /**
   * Handles the delete functionality when viewing an adjustment
   */
  handleDelete?: () => void;
  /**
   * Sends the adjustment creation or update payload
   */
  sendAdjustmentUpdate: ({ adjustmentPostRequest }: PostAdjustmentParams) => Promise<AxiosResponse<any>>;

  /**
   * Boolean to determine whether we're viewing a recommendation or not.
   * A recommendation is considered a partially completed adjustment form that a user can choose to publish or draft.
   */
  isRecommendation?: boolean;
}

interface AdjustmentLoadingState {
  loading: boolean;
  error: boolean;
  percentage: number;
  success: boolean;
}

const AdjustmentModalInner = ({
  stacklineSku,
  retailerSku,
  categoryId,
  subcategoryId,
  onClose,
  setIsPolling,
  product,
  adjustmentData,
  handleDelete,
  sendAdjustmentUpdate,
  open,
  isRecommendation = false
}: AdjustmentModalProps) => {
  const { showSnackbar, closeSnackbar } = useSnackbar();

  const { startPolling } = usePollingContext();

  // Interval used to mock the loading state
  const loadingInterval = useRef<NodeJS.Timeout | null>(null);
  const isModalOpenRef = useRef(open);

  useEffect(() => {
    isModalOpenRef.current = open;
  }, [open]);

  useEffect(() => {
    // cleanup the interval when the component unmounts
    return () => {
      if (loadingInterval.current) {
        clearInterval(loadingInterval.current);
      }
    };
  }, []);

  const theme = useStacklineTheme();
  const { searchParams } = useAppSelector((state) => state.app.queryParams);
  // View State
  const [view, setView] = useState(adjustmentData && !isRecommendation ? View.Viewing : View.Initializing);

  /**
   *  A temporary error state that exists for conflicting date ranges.
   */
  const [error, setError] = useState<{ message: string }>(null);

  const [disablePublish, setDisablePublish] = useState(adjustmentData ? adjustmentData.status !== 'Draft' : true);

  const { selectedPlanType } = useForecastAdjustmentsType();

  // Form State
  const [adjustmentForm, setAdjustmentForm] = useState<AdjustmentForm>(
    adjustmentData ? populateAdjustmentForm(adjustmentData, isRecommendation) : blankFormState(selectedPlanType)
  );

  // Adjustment submission loading state
  const [adjustmentUpdateStatus, setAdjustmentUpdateStatus] = useState<AdjustmentLoadingState>({
    success: false,
    loading: false,
    error: false,
    percentage: 0
  });

  /**
   * Special state value for when we need to track if the user has overridden the Cost Per Click
   * input on an Paid Traffic/Ad Spend adjustment.
   * If the user inputs ad spend and they have input their own CPC, then we can override the CPC with the value from the API response
   */
  const [shouldOverrideCostPerClick, setShouldOverrideCostPerClick] = useState(
    adjustmentData ? !adjustmentData.cpcFromUserFlag : false
  );
  /**
   * For Paid Traffic/Ad Spend adjustments only.
   * On initial page load, use the current CPC value as the default initial value.
   * Exception: If we're editing/viewing an adjustment, we should use the CPC that the user had previously input.
   */
  const [shouldUseInitialValue, setShouldUseInitialValue] = useState(
    adjustmentData ? !adjustmentData.cpcFromUserFlag : true
  );

  // Populates the calendar picker with the currently available weeks depending on the plan type selected
  const { data: initialConflictedDatesData, isLoading: initialConflictedDatesLoading } = useConflictingWeekIds({
    adjustmentId: adjustmentData ? adjustmentData.adjustmentId : undefined,
    retailerSku,
    planType: adjustmentForm.planType
  });

  // Checks for conflicting week IDs when viewing a an existing draft adjustment to determine if its still valid to publish
  const { data: adjustmentLevelConflictedDatesData } = useConflictingWeekIds({
    adjustmentId: adjustmentData ? adjustmentData.adjustmentId : undefined,
    startWeekId: adjustmentForm.startDate ? adjustmentForm.startDate : undefined,
    endWeekId: adjustmentForm.endDate ? adjustmentForm.endDate : undefined,
    retailerSku,
    planType: adjustmentForm.planType
  });

  // If the drafted adjustment is no longer valid (an adjustment has been published using the drafted weeks for example) then we notify the user and disable the publish button
  useEffect(() => {
    if (adjustmentLevelConflictedDatesData) {
      if (adjustmentLevelConflictedDatesData.isConflicted && adjustmentData) {
        setError({
          message: 'The adjustment above conflicts with an existing adjustment during the date range you selected.'
        });
        // Permanently disable the "Draft" adjustment Publish functionality since the draft is invalid
        setDisablePublish(true);
      }
    }
  }, [adjustmentLevelConflictedDatesData, adjustmentData]);

  const queryClient = useQueryClient();

  // Request builders for adjustment CRUD ops
  const { buildAdjustmentCreationRequest } = useAdjustmentRequest();

  const handleFormUpdate = (target: FORM_FIELD, value: string | boolean) => {
    // Changing the plan type also changes the inputs we use.
    // We also clear the selected dates in case adjustments already exist for that plan type.
    if (target === FORM_FIELD.TYPE) {
      setAdjustmentForm((prevForm) => ({
        ...prevForm,
        [target]: value as PlanTypeOption,
        [FORM_FIELD.INPUT_VALUES]: initialInputValuesByPlanType[value as PlanTypeOption],
        [FORM_FIELD.START_DATE]: null,
        [FORM_FIELD.END_DATE]: null,
        [FORM_FIELD.IS_PROMOTION_ADJUSTMENT]: value === PlanTypeOption.RetailPrice && shouldShowPromotionsAdjustment(), // Set promotion type to Lightning Deal if the user selects Retail Price
        [FORM_FIELD.PROMOTION_TYPE]:
          value === PlanTypeOption.RetailPrice && shouldShowPromotionsAdjustment()
            ? PromotionType.LightningDeal
            : PromotionType.None
      }));
    }
    // We can clear the conflicting date range error if the user selects a new valid date range.
    if (target === FORM_FIELD.START_DATE || target === FORM_FIELD.END_DATE) {
      setError(null);
      setAdjustmentForm((prevForm) => ({
        ...prevForm,
        [target]: value
      }));
    } else if (target === FORM_FIELD.IS_PROMOTION_ADJUSTMENT) {
      // If the user marks "No" for promotion, we clear the promotion type and set the promotion adjustment flag to false
      if (value === false) {
        setAdjustmentForm((prevForm) => ({
          ...prevForm,
          [FORM_FIELD.INPUT_VALUES]: initialInputValuesByPlanType[prevForm.planType], // Reset input values if the user toggles between promotion/not a promotion
          [FORM_FIELD.PROMOTION_TYPE]: PromotionType.None,
          [target]: value
        }));
      } else if (value === true) {
        // If the user marks "Yes" for promotion, we set the promotion type to Lightning Deal by default or keep the current value if it's already set
        setAdjustmentForm((prevForm) => ({
          ...prevForm,
          [FORM_FIELD.INPUT_VALUES]: initialInputValuesByPlanType[prevForm.planType], // Reset input values if the user toggles between promotion/not a promotion
          [FORM_FIELD.PROMOTION_TYPE]:
            prevForm.promotionType === PromotionType.None ? PromotionType.LightningDeal : prevForm.promotionType,
          [target]: value
        }));
      }
    } else {
      setAdjustmentForm((prevForm) => ({
        ...prevForm,
        [target]: value
      }));
    }
  };

  const getControlledValue = (
    value: string,
    inputTarget: 'total' | ContentScoreFields | RatingsReviewFields | PaidTrafficFields,
    form: AdjustmentForm,
    additionalData: { [key: string]: any }
  ) => {
    switch (form.planType) {
      case PlanTypeOption.RatingsReviews:
        if (inputTarget === RatingsReviewFields.AverageRating) {
          const strippedValue = value.replaceAll(/[^\d.]|(\.(?=.*\.))|(?<=\.\d{1})\d+/g, '');
          if (Number(strippedValue) >= 1 && Number(strippedValue) <= 5) {
            return strippedValue;

            // Case where decimals are allowed but we don't want to let the user start the value with them.
          } else if (strippedValue === '.') {
            return '';
          } else {
            return value.slice(0, value.length - 1);
          }
        } else {
          return value;
        }
      case PlanTypeOption.BuyBox:
      case PlanTypeOption.InStockRate:
      case PlanTypeOption.ContentScore:
        /**
         * We don't want to let the user input a value higher than 100.
         * Edge case: The user can inject a digit at the integer part of the input and it can be higher than 100.
         * We can prevent this by truncating the value to below 100.
         */
        if (Number(value) > 100) {
          let index = value.length - 1;
          while (Number(value.slice(0, index)) > 100) {
            index -= 1;
          }
          return value.slice(0, index);
        } else if (Number(value) >= 0 && Number(value) <= 100) {
          return value;
        } else {
          return value.slice(0, value.length - 1);
        }

      case PlanTypeOption.RetailPrice:
        if (form[FORM_FIELD.IS_PROMOTION_ADJUSTMENT]) {
          if (additionalData && 'currentRetailPrice' in additionalData) {
            const { currentRetailPrice } = additionalData;
            // A promotion cannot be the same or higher than the current retail price
            // Edge case: The user can inject a digit at the integer part of the input and it can be higher than the current retail price.
            // We can prevent this by truncating the value to below the current retail price.
            // Example: Current price: 115.00, user inputs 11[0]5.00, we set the input to 110.00
            if (Number(value) >= currentRetailPrice) {
              let index = value.length - 1;
              while (Number(value.slice(0, index)) >= currentRetailPrice) {
                index -= 1;
              }
              return value.slice(0, index);
            } else {
              return value;
            }
          }
        } else if (Number(value) > 0) {
          return value;
        }
        break;
      default:
        return value;
    }
    return value;
  };

  const handleInputChange = (
    inputTarget: 'total' | ContentScoreFields | RatingsReviewFields | PaidTrafficFields,
    value: string,
    /**
     * Used to pass additional data to the handleInputChange function, such as the current product retail price when creating a promotion
     */
    additionalData?: { [key: string]: any }
  ) => {
    const controlledValue = getControlledValue(value, inputTarget, adjustmentForm, additionalData);
    setAdjustmentForm((prevForm) => ({
      ...prevForm,
      [FORM_FIELD.INPUT_VALUES]: {
        ...prevForm.planInputAmounts,
        [inputTarget]: controlledValue
      }
    }));
  };

  const handlePublish = async ({
    saveAsDraft = false,
    publishFromDraft = false,
    adjustmentId = ''
  }: PublishOptions) => {
    try {
      const createAdjustmentRequest = buildAdjustmentCreationRequest({
        adjustmentForm,
        retailerSku,
        saveAsDraft,
        publishFromDraft,
        adjustmentId,
        cpcFromUser: !shouldOverrideCostPerClick,
        adjustmentStatus: adjustmentData ? adjustmentData.status : 'Published'
      });

      /**
       * Creates or updates an adjustment
       */
      if (!saveAsDraft) {
        setView(View.Submitting);
        setAdjustmentUpdateStatus((prev) => ({ ...prev, loading: true }));
        // Increment loading percentage every 500ms up to 99%
        loadingInterval.current = setInterval(() => {
          setAdjustmentUpdateStatus((prev) => ({ ...prev, percentage: Math.min(prev.percentage + 1, 99) }));
        }, 500);
      }

      // Create/update adjustment

      // Capture promise in global context so we can open a snackbar whenever the request resolves
      const pendingAdjustmentPromise = sendAdjustmentUpdate({ adjustmentPostRequest: createAdjustmentRequest });

      startPolling(
        `CREATE_ADJUSTMENT_${Date.now()}`,
        async () => {
          const response = await pendingAdjustmentPromise;
          const isSuccess = _get(response, ['data', 0, 'isSuccess'], false);
          const successMessage = saveAsDraft
            ? 'Your adjustment was successfully saved as a draft. View all adjustments'
            : 'Your adjustment was successfully submitted. View all adjustments';
          // Only open the snackbar if the modal is closed
          if (!isModalOpenRef.current) {
            if (isSuccess) {
              showSnackbar({
                message: <AdjustmentSuccessMessage message={successMessage} onClick={closeSnackbar} />,
                type: 'success',
                timeout: 10000
              });
            } else {
              showSnackbar({
                message: settingsErrorMessage,
                type: 'error',
                timeout: 10000
              });
            }
          }
        },
        { interval: 5000, leading: true, maxTries: 1 }
      );

      // Only show the loading wheel if we're not saving as a draft
      if (!saveAsDraft) {
        // Wait for request to finish if the modal is still open (otherwise we'll open a snackbar)
        await pendingAdjustmentPromise;

        clearInterval(loadingInterval.current);
        // Set loading to 100% for submitted animation
        setAdjustmentUpdateStatus((prev) => ({ ...prev, percentage: 100 }));
        // Wait 5 seconds on 100% before showing submitted animation
        setTimeout(() => {
          setAdjustmentUpdateStatus((prev) => ({ ...prev, loading: false, success: true, percentage: 100 }));
        }, 5000);

        // Trigger polling in parent
        setIsPolling(true);
        queryClient.invalidateQueries({ queryKey: [ADJUSTMENT_TABLE_QUERY] });
        queryClient.invalidateQueries({ queryKey: [FORECAST_SUMMARY_KEYMETRIC_QUERY] });
      } else {
        onClose();
        setIsPolling(true);
        queryClient.invalidateQueries({ queryKey: [ADJUSTMENT_TABLE_QUERY] });
        queryClient.invalidateQueries({ queryKey: [FORECAST_SUMMARY_KEYMETRIC_QUERY] });
      }
    } catch (err) {
      console.error('Failed to create adjustment: ', err);
      setAdjustmentUpdateStatus((prev) => ({ ...prev, loading: false, success: false, error: true }));
      setIsPolling(false);
    }
  };

  /**
   * View renderer
   */
  const renderView = () => {
    switch (view) {
      case View.Initializing:
        return (
          <InitializeAdjustment
            conflictedDatesData={initialConflictedDatesData}
            conflictedDatesLoading={initialConflictedDatesLoading}
            adjustmentData={adjustmentData}
            retailerSku={retailerSku}
            adjustmentForm={adjustmentForm}
            handleFormUpdate={handleFormUpdate}
            options={options}
            product={product}
            setError={setError}
          />
        );

      case View.Finalizing:
        return (
          <FinalizeAdjustment
            shouldOverrideCostPerClick={shouldOverrideCostPerClick}
            setShouldOverrideCostPerClick={setShouldOverrideCostPerClick}
            categoryId={categoryId}
            subcategoryId={subcategoryId}
            stacklineSku={stacklineSku}
            retailerSku={retailerSku}
            adjustmentForm={adjustmentForm}
            adjustmentData={adjustmentData}
            handleInputChange={handleInputChange}
            setShouldUseInitialValue={setShouldUseInitialValue}
            shouldUseInitialValue={shouldUseInitialValue}
          />
        );

      case View.Confirming:
        return (
          <ConfirmAdjustment
            shouldOverrideCostPerClick={shouldOverrideCostPerClick}
            adjustmentData={adjustmentData}
            categoryId={categoryId}
            subcategoryId={subcategoryId}
            retailerSku={retailerSku}
            stacklineSku={stacklineSku}
            adjustmentForm={adjustmentForm}
          />
        );

      case View.Viewing:
        return <ViewAdjustment adjustmentData={adjustmentData} product={product} adjustmentForm={adjustmentForm} />;

      case View.Submitting:
        if (adjustmentUpdateStatus.loading) {
          return (
            <BulkTemplateCircularLoadingInner
              sx={{ padding: '72.5px 120px 71.5px 120px' }}
              percentage={adjustmentUpdateStatus.percentage}
              circleLabel="Submitted"
              descriptionTitle="Your adjustment is being submitted"
              descriptionBody="We’ve received your submission and will notify you when processing is complete. You can close this window in the meantime."
              circleSx={{
                color: adjustmentUpdateStatus.percentage < 100 ? theme.colors.primary : theme.colors.success
              }}
            />
          );
        } else if (adjustmentUpdateStatus.success && !adjustmentUpdateStatus.loading) {
          const planType = options.find((option) => option.id === adjustmentForm.planType) || { label: '' };

          return (
            <Flex flexDirection="column" alignItems="center" marginTop="335px">
              <BulkAdjustmentUploadSuccessAnimation
                style={{ position: 'absolute', width: '390px', height: '390px', top: '70px' }}
              />

              <Text textAlign="center" sx={{ width: '340px' }} variant="h3">
                Your {planType.label.toLowerCase()} adjustment was successfully submitted
              </Text>
              <Text textAlign="center" sx={{ display: 'flex', marginTop: '8px' }} variant="body1">
                View all adjustments&nbsp;
                <Text underlined variant="body1">
                  <Link
                    to={`${searchParams}&tab=${BEACON_TABS.FORECASTS}&subtab=${BEACON_SUBTABS.FORECAST_ADJUSTMENTS}`}
                    onClick={() => onClose()}
                  >
                    here
                  </Link>
                </Text>
                .
              </Text>
            </Flex>
          );
        }
        return null;

      default:
        return null;
    }
  };

  /**
   * Validation helpers
   */

  /**
   * Enables next button from Initialization view
   */
  const enableNext =
    adjustmentForm.title.trim() &&
    adjustmentForm.description.trim() &&
    adjustmentForm.planType &&
    adjustmentForm.startDate &&
    adjustmentForm.endDate &&
    !error;

  const isValid = (value: string) => {
    return !Number.isNaN(Number(value)) && Number(value) >= 0 && value !== '';
  };

  /**
   * Enables next button from Finalization view
   */
  const enableFinalizeNext = Object.values(adjustmentForm.planInputAmounts).every(isValid) && !error;

  const renderButtons = () => {
    switch (view) {
      case View.Initializing:
        return (
          <>
            {adjustmentData && !isRecommendation ? (
              <SlButton onClick={() => setView(View.Viewing)}>Back</SlButton>
            ) : (
              <SlButton onClick={() => onClose()}>Cancel</SlButton>
            )}

            {error && adjustmentData && adjustmentData.status === 'Draft' ? (
              <Flex alignItems="center" width="440px" gap="sm">
                <SmallCancelIcon style={{ width: '24px', height: '24px' }} />
                <Text variant="subtitle3" color="error">
                  {error.message}
                </Text>
              </Flex>
            ) : null}

            <SlButton disabled={!enableNext} onClick={() => setView(View.Finalizing)} variant="contained">
              Next
            </SlButton>
          </>
        );

      case View.Finalizing:
        return (
          <>
            <SlButton onClick={() => setView(View.Initializing)}>Back</SlButton>
            <SlButton disabled={!enableFinalizeNext} onClick={() => setView(View.Confirming)} variant="contained">
              Next
            </SlButton>
          </>
        );

      case View.Confirming:
        return (
          <>
            <SlButton onClick={() => setView(View.Finalizing)}>Back</SlButton>
            <Flex gap="md">
              {adjustmentData && !isRecommendation ? null : (
                <SlButton
                  onClick={() =>
                    handlePublish({
                      saveAsDraft: true,
                      publishFromDraft: false,
                      adjustmentId: ''
                    })
                  }
                >
                  Save as draft
                </SlButton>
              )}
              {/* Draft status should be saved as draft again */}
              {adjustmentData && adjustmentData.status === 'Draft' && (
                <SlButton
                  onClick={() =>
                    handlePublish({
                      saveAsDraft: true,
                      publishFromDraft: false,
                      adjustmentId: adjustmentData.adjustmentId
                    })
                  }
                >
                  Save as draft
                </SlButton>
              )}

              <SlButton
                onClick={() =>
                  handlePublish({
                    saveAsDraft: undefined,
                    publishFromDraft: adjustmentData ? adjustmentData.status === 'Draft' : false,
                    adjustmentId: adjustmentData ? adjustmentData.adjustmentId : ''
                  })
                }
                variant="contained"
              >
                Publish
              </SlButton>
            </Flex>
          </>
        );

      case View.Viewing:
        return (
          <>
            <SlButton onClick={() => setView(View.Initializing)}>Edit</SlButton>
            {disablePublish && adjustmentData.status === 'Draft' ? (
              <Flex alignItems="center" width="440px" gap="sm">
                <SmallCancelIcon style={{ width: '24px', height: '24px' }} />
                <Text variant="subtitle3" color="error">
                  The adjustment above conflicts with an existing adjustment during the date range you selected.
                </Text>
              </Flex>
            ) : null}
            <Flex gap="md">
              <SlButton
                disabled={disablePublish}
                onClick={() =>
                  handlePublish({
                    publishFromDraft: adjustmentData.status === 'Draft',
                    adjustmentId: adjustmentData.adjustmentId
                  })
                }
                variant="contained"
              >
                Publish
              </SlButton>
            </Flex>
          </>
        );

      case View.Submitting:
        return (
          <>
            <SlButton disabled>Back</SlButton>
            <SlButton onClick={() => onClose()} variant="contained">
              Close
            </SlButton>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <AdjustmentModalHeader
        handleDelete={handleDelete}
        title={
          view === View.Submitting
            ? 'Submitting Adjustment'
            : !adjustmentData || isRecommendation
            ? 'Create Adjustment'
            : view === View.Viewing
            ? 'View Adjustment'
            : 'Edit Adjustment'
        }
        handleClose={onClose}
      />
      <ScrollableContainer
        sx={{
          overflow: 'scroll',
          overflowX: 'hidden',
          paddingX: view === View.Submitting ? '0px' : '48px',
          height: '100%'
        }}
        scrollbarWidth="5px"
      >
        {renderView()}
      </ScrollableContainer>
      <Divider />
      <Flex justifyContent="space-between" paddingX="48px" paddingY="19.5px">
        {renderButtons()}
      </Flex>
    </>
  );
};

export const AdjustmentModal = (props: AdjustmentModalProps) => {
  return (
    <GenericStyledDialogue open={props.open} onClose={props.onClose}>
      <AdjustmentModalInner {...props} />
    </GenericStyledDialogue>
  );
};
