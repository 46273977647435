import React from 'react';

interface CircleComponentProps {
  color: string;
}

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    width: '10%',
    height: '10%',
    borderRadius: '50%',
    border: 'solid 1px #fff'
  }
};

const CircleComponent: React.FC<CircleComponentProps> = ({ color }: CircleComponentProps) => {
  return <div style={{ ...styles.root, backgroundColor: color }}></div>;
};

export default CircleComponent;
