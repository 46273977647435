import { TextField } from '@mui/material';
import React from 'react';
import { localeParseFloat } from 'src/utils/metrics';
import numeral from 'numeral';

interface FilterProps {
  setFilter: any;
  filterTitles: string;
  filterTag: string;
  index: number;
  mapping: any;
  helperText?: any;
  onChangeApplyFilters: any;
  filterTagsToBackendMapping: any;
  getHelperText: any;
  setFilterObj: any;
  formatValueMapping: any;
}

const Filter = (props: FilterProps) => {
  const {
    filterTitles,
    filterTag,
    // looks like setFilter is FilterObj from Discover.tsx.....
    setFilter,
    index,
    mapping,
    onChangeApplyFilters,
    filterTagsToBackendMapping,
    getHelperText,
    setFilterObj,
    formatValueMapping
  } = props;

  const { thousands: currentThousand, decimal: currentDecimal } = numeral.localeData().delimiters;
  return (
    <>
      {!(
        ['category', 'subcategory', 'department'].includes(filterTitles.toLowerCase()) &&
        ['Market Share', 'Share Change', 'Average Rating'].includes(filterTag)
      ) ? (
        <div key={index} className="filter">
          <p className="filtertagtitle">{filterTag || ''}</p>
          {setFilter[filterTitles][mapping[filterTag]] ? (
            <>
              <div className="textField_container">
                <TextField
                  type="text"
                  style={{
                    width: '180px',
                    marginBottom: '1em',
                    borderRadius: '7px',
                    marginRight: '0.5em',
                    paddingLeft: '10px',
                    zIndex: '1'
                  }}
                  variant="standard"
                  onChange={(event) => onChangeApplyFilters(event, filterTag, 'min')}
                  onBlur={(e) => {
                    const copyFilerObj = { ...setFilter };
                    const filterTagsMap = filterTagsToBackendMapping();
                    const mapFilter = filterTagsMap[filterTag];
                    const val = setFilter[filterTitles][mapping[filterTag]].rangeBegin;

                    if (filterTag === 'Share Change' || filterTag === 'Units Sold' || filterTag === 'Retail Sales') {
                      copyFilerObj[filterTitles][mapFilter].rangeBegin = Number.isNaN(
                        localeParseFloat(val, currentThousand, currentDecimal)
                      )
                        ? val
                        : val; // to fixed if we want set decimal
                    }

                    // localeParseFloat(val, currentThousand, currentDecimal).toFixed(decimal);

                    getHelperText(e, filterTag, 'min');

                    setFilterObj(copyFilerObj);
                  }}
                  value={(() => {
                    return formatValueMapping[filterTag]
                      ? formatValueMapping[filterTag](setFilter[filterTitles][mapping[filterTag]].rangeBegin)
                      : setFilter[filterTitles][mapping[filterTag]].rangeBegin;
                  })()}
                  InputProps={{
                    inputProps: {
                      min: !['Sales Change', 'Share Change', 'Unit Change'].includes(filterTag) ? 0 : '',
                      style: {
                        textAlign: 'right',
                        paddingRight: ['Share Change'].includes(filterTag) ? '35px' : '20px'
                      }
                    }
                  }}
                />
                {formatValueMapping[filterTag] &&
                  ['Sales Change', 'Market Share', 'Unit Change', 'Conversion', 'Traffic Share'].includes(filterTag) &&
                  formatValueMapping[filterTag](setFilter[filterTitles][mapping[filterTag]].rangeBegin) !== '' && (
                    <span style={{ marginLeft: '-25px', zIndex: 9, marginTop: '6px', width: '25px', color: '#052849' }}>
                      %
                    </span>
                  )}
                {formatValueMapping[filterTag] &&
                  ['Share Change'].includes(filterTag) &&
                  formatValueMapping[filterTag](setFilter[filterTitles][mapping[filterTag]].rangeBegin) !== '' && (
                    <span style={{ marginLeft: '-35px', zIndex: 9, marginTop: '6px', width: '25px', color: '#052849' }}>
                      bps
                    </span>
                  )}
              </div>
              <span
                style={{
                  marginTop: '6px',
                  marginLeft:
                    ['Share Change'].includes(filterTag) &&
                    formatValueMapping[filterTag](setFilter[filterTitles][mapping[filterTag]].rangeBegin) !== ''
                      ? '13px'
                      : '2px',
                  marginRight: '2px',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#052849'
                }}
              >
                {' '}
                -{' '}
              </span>
              <div className="textField_container">
                <TextField
                  type="text"
                  style={{
                    width: '180px',
                    marginBottom: '2em',
                    borderRadius: '7px',
                    marginRight: '1em',
                    marginLeft: '0.5em',
                    zIndex: '1'
                  }}
                  variant="standard"
                  onChange={(event) => onChangeApplyFilters(event, filterTag, 'max')}
                  InputProps={{
                    inputProps: {
                      min: !['Sales Change', 'Share Change', 'Unit Change'].includes(filterTag) ? 0 : '',
                      style: {
                        textAlign: 'right',
                        paddingRight: ['Share Change'].includes(filterTag) ? '35px' : '20px'
                      }
                    }
                  }}
                  onBlur={(e) => {
                    const copyFilerObj = { ...setFilter };
                    const filterTagsMap = filterTagsToBackendMapping();
                    const mapFilter = filterTagsMap[filterTag];
                    const val = setFilter[filterTitles][mapping[filterTag]].rangeEnd;

                    if (filterTag === 'Share Change' || filterTag === 'Units Sold' || filterTag === 'Retail Sales') {
                      copyFilerObj[filterTitles][mapFilter].rangeEnd = Number.isNaN(
                        localeParseFloat(val, currentThousand, currentDecimal)
                      )
                        ? val
                        : val;
                    }

                    getHelperText(e, filterTag, 'max');
                    setFilterObj(copyFilerObj);
                  }}
                  value={(() => {
                    return formatValueMapping[filterTag]
                      ? formatValueMapping[filterTag](setFilter[filterTitles][mapping[filterTag]].rangeEnd)
                      : setFilter[filterTitles][mapping[filterTag]].rangeEnd;
                  })()}
                />
                {formatValueMapping[filterTag] &&
                  ['Sales Change', 'Market Share', 'Unit Change', 'Conversion', 'Traffic Share'].includes(filterTag) &&
                  formatValueMapping[filterTag](setFilter[filterTitles][mapping[filterTag]].rangeEnd) !== '' && (
                    <span style={{ marginLeft: '-29px', zIndex: 9, marginTop: '6px', width: '25px', color: '#052849' }}>
                      %
                    </span>
                  )}
                {formatValueMapping[filterTag] &&
                  ['Share Change'].includes(filterTag) &&
                  formatValueMapping[filterTag](setFilter[filterTitles][mapping[filterTag]].rangeEnd) !== '' && (
                    <span style={{ marginLeft: '-44px', zIndex: 9, marginTop: '6px', width: '25px', color: '#052849' }}>
                      bps
                    </span>
                  )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Filter;
