import types from './types';
import actions from './actions';
import { ValueOf } from 'sl-api-connector/types';

export default function promoTypes(state = [], action: ReturnType<ValueOf<typeof actions>>) {
  switch (action.type) {
    case types.RECEIVE_ALL_PROMO_TYPES:
      return Object.assign([], action.promoTypes);
    case types.CLEAR_ALL_PROMO_TYPES:
      return [];
    default:
      return state;
  }
}
