import React, { useMemo, useState } from 'react';
import { TimeToConversionDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { useBus, useAppSelector } from 'src/utils/Hooks';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import Visualization from 'src/components/Layout/Advertising/ConnectPageLayout/components/Visualization';
import { AD_CONVERSION_EVENT } from './constants';
import TimeToConversionBarChart from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/TimeToConversion/TimeToConversionBarChart';
import useAmcDateRange from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/hooks/useAmcDateRange';
import {
  adTimeToConversionAdvancedSearchParser,
  buildRequestForTimeToConversion
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/TimeToConversion/utils';
import _get from 'lodash/get';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import { TimeToConversionData } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/TimeToConversion/types';

interface TimeToConversionVisualizationProps {
  eventBus: EventBus;
}

const TimeToConversionVisualization = ({ eventBus }: TimeToConversionVisualizationProps) => {
  const [metric, setMetric] = useState(TimeToConversionDropdownOption.AllAdTypes);
  const { startDayId, endDayId } = useAmcDateRange();
  const retailer = useAppSelector((state) => state.retailer);

  useBus(eventBus, AD_CONVERSION_EVENT, setMetric);

  // Advanced Search Request Body
  const timeToConversionRequestBody = useMemo(
    () => buildRequestForTimeToConversion({ startDayId, endDayId, retailerId: retailer.id }),
    [endDayId, startDayId, retailer.id]
  );

  // Fetching data
  /**
   * We avoid using fetchEntityMetrics because it will mutate our request overrides
   */
  const { data, isLoading } = useGenericAdvancedSearch({
    requestBody: timeToConversionRequestBody,
    queryKeys: timeToConversionRequestBody,
    requestId: 'time-to-conversion'
  });

  // Filters to apply to our collection of data depending on which dropdown option is selected
  const metricToAdTypeMap = {
    [TimeToConversionDropdownOption.AllAdTypes]: ['dsp', 'sponsored_brands', 'sponsored_products', 'sponsored_display'],
    [TimeToConversionDropdownOption.DemandSidePlatformAds]: ['dsp'],
    [TimeToConversionDropdownOption.SearchAds]: ['sponsored_brands', 'sponsored_products', 'sponsored_display']
  };

  // Data Access and formatting
  const chartData = useMemo(() => {
    if (data) {
      const documentData = adTimeToConversionAdvancedSearchParser(_get(data, 'data[0]'));
      const filteredData = documentData.filter((document: TimeToConversionData) =>
        metricToAdTypeMap[metric].includes(_get(document, ['additionalMetaData', 'adProductType.keyword']))
      );
      // Reduce our documents into their respective time period buckets
      const reducedSeriesData = filteredData.reduce(
        (accumulator, current) => {
          accumulator[current.fieldId] += current.additionalValues.orders_sum_value;
          return accumulator;
        },
        {
          '1 | < 10 MIN': 0,
          '2 | 10 - 60 MIN': 0,
          '3 | 1 - 6 HRS': 0,
          '4 | 6 - 24 HRS': 0,
          '5 | 1 - 7 DAYS': 0,
          '6 | 7+ DAYS': 0
        }
      );
      return Object.values(reducedSeriesData);
    }
    return null;
  }, [data, metric, metricToAdTypeMap]);

  return (
    <Visualization
      titleProps={{
        title: ''
      }}
    >
      <div style={{ marginTop: '24px' }}>
        {isLoading && <GenericChartLoading />}
        {!isLoading && chartData && <TimeToConversionBarChart data={chartData} metric={metric} />}
      </div>
    </Visualization>
  );
};

export default withBus('eventBus')(TimeToConversionVisualization);
