import React, { useRef, useState } from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text, useStacklineTheme } from '@stackline/ui';
import StyledInput from 'src/components/BeaconRedesignComponents/common/StyledInput';
import { Ring } from '@uiball/loaders';
import ProfileInfoPopup from './ProfileInfoPopup';
import { useSnackbar } from 'src/utils/Hooks';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

type PasswordChangeHandler = (values: { password: string; isPasswordValid: boolean; id: string }) => void;

interface ProfileSecurityLayoutProps {
  handleNewPasswordChange: PasswordChangeHandler;
  handlePasswordConfirmationChange: PasswordChangeHandler;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

const isPasswordValid = (value: string) => {
  // Minimum eight characters
  return value.length >= 8;
};

type PasswordErrors = { [key in 'newPassword' | 'confirmPassword']: boolean };

/**
 * Profile security page for updating password
 */
export default function ProfileSecurityLayout({
  handleNewPasswordChange,
  handlePasswordConfirmationChange,
  onSubmit
}: ProfileSecurityLayoutProps) {
  const theme = useStacklineTheme();
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const [errors, setErrors] = useState<PasswordErrors>({
    confirmPassword: false,
    newPassword: false
  });

  const clearForm = () => {
    if (passwordRef.current) {
      passwordRef.current.value = '';
    }
    if (confirmPasswordRef.current) {
      confirmPasswordRef.current.value = '';
    }
  };

  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    passwordChangeHandler: PasswordChangeHandler
  ) => {
    const { value, id } = event.target;
    const passwordValid = isPasswordValid(value);

    passwordChangeHandler({ password: value, isPasswordValid: passwordValid, id });
  };

  const validateForm = (): PasswordErrors => {
    const passwordValid = passwordRef.current && isPasswordValid(passwordRef.current.value);
    const confirmPasswordValid =
      confirmPasswordRef.current &&
      passwordRef.current &&
      confirmPasswordRef.current.value === passwordRef.current.value;

    return {
      newPassword: !passwordValid,
      confirmPassword: !confirmPasswordValid
    };
  };

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const updatedErrors = validateForm();
    setErrors(updatedErrors);
    if (Object.values(updatedErrors).some((error) => error)) {
      if (updatedErrors.newPassword) {
        showSnackbar({
          message: 'Your password needs to be at least 8 characters long. Please try again.',
          type: 'error'
        });
      } else if (updatedErrors.confirmPassword) {
        showSnackbar({
          message: "The passwords don't match. Please try again.",
          type: 'error'
        });
      }
      event.preventDefault();
      return;
    }
    try {
      setUpdatePasswordLoading(true);
      await onSubmit(event);
      clearForm();
      setSuccessPopupOpen(true);
    } finally {
      setUpdatePasswordLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={submitForm}>
        <Flex flexDirection="column" gap={56}>
          <Flex flexDirection="column" gap="mdl">
            <Flex flexDirection="column" width="320px">
              <Text variant="subtitle1">New Password</Text>
              <StyledInput
                style={{
                  marginTop: theme.spacing.md // We do this instead of a flex gap because last pass will add in a hidden div
                }}
                ref={passwordRef}
                id="newPassword"
                onChange={(event) => handlePasswordChange(event, handleNewPasswordChange)}
                type="password"
                error={errors.newPassword}
              />
            </Flex>
            <Flex flexDirection="column" width="320px">
              <Text variant="subtitle1">Confirm New Password</Text>
              <StyledInput
                style={{
                  marginTop: theme.spacing.md // We do this instead of a flex gap because last pass will add in a hidden div
                }}
                ref={confirmPasswordRef}
                id="confirmPassword"
                onChange={(event) => handlePasswordChange(event, handlePasswordConfirmationChange)}
                type="password"
                error={errors.confirmPassword}
              />
            </Flex>
          </Flex>
          <Flex justifyContent="flex-end" gap="md">
            <SlButton onClick={() => clearForm()}>Cancel</SlButton>
            <SlButton variant="contained" type="submit">
              Save
            </SlButton>
          </Flex>
        </Flex>
      </form>
      <ProfileInfoPopup
        message="Updating password, please stay on this page."
        icon={<Ring size={64} speed={2} lineWeight={5} color={theme.colors.primary} />}
        open={updatePasswordLoading}
        onClose={() => setUpdatePasswordLoading(false)}
      />
      <ProfileInfoPopup
        message="Your password has been updated successfully!"
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
      />
    </>
  );
}
