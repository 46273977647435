/**
 * Possible stages of a dispute. Used in requests across Dispute Management.
 */
export enum DISPUTE_STAGE {
  NEW_SHORTAGE = 'New Shortage',
  CONTACT_US_CASE = 'Contact Us Case',
  RESOLVED = 'Resolved',
  INITIAL_DISPUTE = 'Initial Dispute'
}

/**
 * Possible statuses of a dispute. Used in requests across Dispute Management.
 */
export enum DISPUTE_STATUS {
  READY_TO_SUBMIT = 'Ready to Submit',
  NEEDS_DOCUMENTS = 'Needs Supporting Documents',
  NEEDS_ACTION = 'Needs Action',
  WON = 'Won',
  LOST = 'Lost',
  PARTIALLY_WON = 'Partially Won',
  PENDING = 'Pending',
  QUEUED = 'Queued for Submission',
  SUBMISSION_IN_PROGRESS = 'Submission in Progress',
  FAILED_TO_SUBMIT = 'Failed to Submit'
}

/**
 * Query Keys
 */

export const DISPUTE_DATA_QUERY_KEY = 'fetchDisputesQueryKey';
export const DISPUTE_KEY_METRIC_QUERY_KEY = 'fetchBoxFilterMetricsQueryKey';
export const SUPPORTING_DOCS_QUERY_KEY = 'supportingDocsQueryKey';
export const CONTACT_US_CASE_QUERY_KEY = 'contactUsQueryKey';

/**
 * Event Bus Names
 */
export const DISPUTE_SUBMISSION_EVENT = 'disputeSubmission';
