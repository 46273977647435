/**
 * A text input component based off of material-ui's `TextField`.  It contains some default functionality for things
 * like error handling/state, validation, and integration with a form containing other input elements.
 */

import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const getRequiredErrMsg = (required, value) => (required && !value ? 'This field is required' : undefined);

const getErrorText = ({ validator, required, formIsSubmitted }, value) => {
  if (!formIsSubmitted) {
    return null;
  }

  return validator(value) || getRequiredErrMsg(required, value);
};

const TextInput = ({ value, onChange, validator, required, placeholder, formIsSubmitted, ...props }) => {
  const errorText = getErrorText({ validator, required, formIsSubmitted }, value);

  return (
    <TextField
      variant="standard"
      value={value}
      onChange={({ target: { value: newValue } }) => onChange(newValue)}
      helperText={errorText}
      InputProps={{ placeholder }}
      error={!!errorText}
      className="compare__search"
      {...props} />
  );
};

TextInput.propTypes = {
  value: PropTypes.string,
  // `validator` should be a function that accepts the current value of the input and either returns `undefined` if
  // the provided value is valid or returns error text to be displayed.  The validator is run after the formatter.
  validator: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string, // eslint-disable-line react/require-default-props
  formIsSubmitted: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  variant: PropTypes.string, // eslint-disable-line react/require-default-props
  type: PropTypes.string,
  placeholder: PropTypes.string
};

TextInput.defaultProps = {
  value: '',
  validator: () => undefined,
  required: true,
  type: 'text',
  placeholder: undefined
};

export default TextInput;
