import { IsActiveElement } from 'src/components/Discover/DiscoverUtils/CommonUtils';

export const brandManufacturerActiveState: IsActiveElement[] = [
  {
    name: 'brand',
    isActive: false
  },
  {
    name: 'manufacturer',
    isActive: false
  },
  {
    name: 'no selection',
    isActive: false
  }
];
export const departCatSubcatActiveState: IsActiveElement[] = [
  { name: 'department', isActive: false },
  { name: 'category', isActive: false },
  { name: 'subcategory', isActive: false },
  { name: 'no selection', isActive: false }
];

export const DEFAULT_BASE_FILTER_OBJ = {
  retailSalesRange: { rangeBegin: '', rangeEnd: '' },
  popRetailSalesChangePct: { rangeBegin: '', rangeEnd: '' },
  avgRating: { rangeBegin: '', rangeEnd: '' },
  marketShare: { rangeBegin: '', rangeEnd: '' },
  marketShareChange: { rangeBegin: '', rangeEnd: '' },
  unitsSold: { rangeBegin: '', rangeEnd: '' },
  trafficShare: { rangeBegin: '', rangeEnd: '' },
  conversion: { rangeBegin: '', rangeEnd: '' },
  popUnitsSoldChangePct: { rangeBegin: '', rangeEnd: '' }
};

export const DEFAULT_BRAND_FILTER_OBJ = {
  brand: {
    ...DEFAULT_BASE_FILTER_OBJ
  }
};

export const filterContent = [
  'Retail Sales',
  'Sales Change',
  'Market Share',
  'Share Change',
  'Units Sold',
  'Unit Change',
  'Traffic Share',

  'Conversion',
  'Average Rating'
];

export const initError = {
  'Retail Sales': { rangeBeginError: '', rangeEndError: '' },
  'Sales Change': { rangeBeginError: '', rangeEndError: '' },
  'Market Share': { rangeBeginError: '', rangeEndError: '' },
  'Share Change': { rangeBeginError: '', rangeEndError: '' },
  'Units Sold': { rangeBeginError: '', rangeEndError: '' },
  'Unit Change': { rangeBeginError: '', rangeEndError: '' },
  'Traffic Share': { rangeBeginError: '', rangeEndError: '' },
  Conversion: { rangeBeginError: '', rangeEndError: '' },
  'Average Rating': { rangeBeginError: '', rangeEndError: '' }
};

export const chipFields = ['manufacturer', 'department', 'brand', 'category', 'subCategory'];
