import React, { useRef, useState } from 'react';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { EntityColumn } from 'src/components/Grids/Data/ColumnTypes';
import AdPaginationGroup from 'src/components/AdManager/AdPaginationGroup';
import { PurchasedTogetherRow } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/FrequentlyPurchasedTogether/utils';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { ArrowGrey } from 'src/components/SvgIcons';

/**
 *
 * @param data a row object contained the metric we care about (formattedLeadConversions)
 * @returns a span that renders the metric value alongside an arrow SVG
 */
const LeadShoppersColumn = ({ data }: { data: PurchasedTogetherRow }) => {
  return (
    <span style={{ display: 'flex', width: '120px', justifyContent: 'space-between' }}>
      {data.formattedLeadConversions} <ArrowGrey style={{ width: '15px' }} />{' '}
    </span>
  );
};

const PurchasedTogetherGrid = ({ rowData }: { rowData: PurchasedTogetherRow[] }) => {
  const gridRef = useRef(null);

  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);

  const PAGE_SIZE = 10;
  const HEADER_HEIGHT = 55;
  const ROW_HEIGHT = 130;

  const onGridReady = (params: GridReadyEvent) => {
    gridRef.current = params.api as GridApi;
    if (gridRef.current) {
      gridRef.current.sizeColumnsToFit();

      setMaxPages(gridRef.current.paginationGetTotalPages());
    }
  };

  const columnDef: ColDef[] = [
    {
      headerName: 'Lead Products',
      field: 'leadStacklineSku',
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left', paddingRight: '35px' },
      headerClass: 'align-left',
      width: 580,
      cellRendererFramework: EntityColumn,
      cellRendererParams: {
        entityColumnTextStyles: {
          fontSize: 16,
          maxHeight: 'auto'
        }
      }
    },
    {
      headerName: 'Unique Purchasing Shoppers',
      field: 'formattedLeadConversions',
      cellRendererFramework: LeadShoppersColumn,
      width: 250,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'conversions-grid-header'
    },
    {
      headerName: 'Overlap Products',
      field: 'overlapStacklineSku',
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left', paddingRight: '35px' },
      headerClass: 'align-left',
      width: 580,
      cellRendererFramework: EntityColumn,
      cellRendererParams: {
        useCustomData: true,
        entityColumnTextStyles: {
          fontSize: 16,
          maxHeight: 'auto'
        }
      }
    },
    {
      headerName: 'Unique Purchasing Shoppers',
      field: 'formattedOverlapConversions',
      width: 250,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'conversions-grid-header'
    },
    {
      headerName: 'Overlap Rate',
      field: 'formattedOverlapRate',
      width: undefined,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left'
    }
  ];

  const nextPage = () => {
    const currentPage = gridRef.current.paginationGetCurrentPage();
    if (currentPage !== maxPages - 1) {
      gridRef.current.paginationGoToNextPage();
    }
  };

  const goToFirstPage = () => {
    gridRef.current.paginationGoToFirstPage();
  };

  const previousPage = () => {
    const currentPage = gridRef.current.paginationGetCurrentPage();
    if (currentPage !== 0) {
      gridRef.current.paginationGoToPreviousPage();
    }
  };

  const onPaginationChanged = () => {
    if (gridRef.current) {
      setPage(gridRef.current.paginationGetCurrentPage() + 1);
    }
  };

  return (
    <div className="product-overlap-grid">
      <CustomAgMaterial
        onGridReady={onGridReady}
        onCellValueChanged={onGridReady}
        onModelUpdated={onGridReady}
        onRowValueChanged={onGridReady}
        onRowDataChanged={onGridReady}
        domLayout="autoHeight"
        rowHeight={ROW_HEIGHT}
        columnDefs={columnDef}
        containerStyle={{ width: '100%', marginBottom: '30px' }}
        suppressNoRowsOverlay
        buildRows={() => rowData}
        applyColumnDefOrder
        pagination
        paginationPageSize={PAGE_SIZE}
        cacheBlockSize={100}
        cacheOverflowSize={2}
        maxBlocksInCache={4}
        suppressPaginationPanel
        alwaysShowHorizontalScroll
        headerHeight={HEADER_HEIGHT}
        onPaginationChanged={onPaginationChanged}
      ></CustomAgMaterial>
      <AdPaginationGroup
        onBtFirst={() => goToFirstPage()}
        onBtNext={() => nextPage()}
        onBtPrevious={() => previousPage()}
        currentPage={page}
        totalPage={maxPages}
        isLoading={false}
      />
    </div>
  );
};

export default PurchasedTogetherGrid;
