export const SV_TEST_DATA_SETUP = {
  inited: true,
  platformId: 'Walmart',
  platformSettings: {
    entity: {
      beaconClientId: 55,
      platformType: 'Walmart',
      settingType: 'entityId',
      settingId: '237856',
      extendedAttributes: {
        name: 'General Mills Inc - 1P',
        status: 'enabled',
        entityId: '237856',
        entityIdUi: '',
        retailerId: 2,
        amsApiModel: {
          timezone: 'America/Los_Angeles',
          profileId: '237856',
          accountInfo: {
            id: '',
            name: '',
            type: 'vendor',
            marketplaceStringId: ''
          },
          countryCode: '',
          currencyCode: ''
        },
        entityIdApi: '237856',
        statusReason: 'enabled',
        statusDerived: 'delivering',
        beaconClientLoginId: 2950,
        currentMonthBudgetSetting: {
          amount: 1500000,
          budgetType: 'monthly'
        }
      },
      createdBy: 'jcohen@stackline.com',
      createdTime: '2021-02-26T19:01:04.488Z',
      lastUpdatedBy: 'AdMetricsService',
      lastUpdatedTime: '2023-07-23T00:47:49.722Z',
      id: '237856',
      name: 'General Mills Inc - 1P'
    }
  },
  campaignType: {
    platformType: 'Walmart',
    settingType: 'campaignType',
    settingId: 'video',
    extendedAttributes: {
      name: 'Sponsored Videos',
      retailerId: 2,
      description: 'Engage customers on Walmart with a featured product and an auto-playing video.'
    },
    createdBy: 'jlin@stackline.com',
    createdTime: '2023-06-15T22:59:46.934Z',
    lastUpdatedBy: 'jlin@stackline.com',
    lastUpdatedTime: '2023-06-15T22:59:46.934Z',
    id: 'video',
    name: 'Sponsored Videos'
  },
  minROAS: 0,
  minBudget: 1,
  setup: {
    name: 'Test1',
    startDate: 'Thu Aug 24 2023 12:00:00 GMT-0700',
    portfolioId: '1636050606304',
    strategyId: 'Manual',
    automationOptions: []
  },
  instacartParams: {
    optimized_bidding_enabled: false,
    optimized_bidding_parameters: {},
    optimized_bidding_goal: 'max_attributed_sales'
  },
  target: {
    loading: false,
    filters: {
      isDefault: true,
      termFilters: {
        subCategoryId: {
          condition: 'should',
          values: [
            {
              i: 51156
            }
          ]
        }
      },
      rangeFilters: {}
    },
    budget: {
      total: 2500,
      daily: 83.33,
      displayName: '$2,500 ($83 daily)'
    },
    targetingType: null,
    selectedTargetEntities: [],
    autoCampaignProducts: [],
    autoBidMapping: {},
    projections: {
      adSpend: 0,
      adClicks: 0,
      costPerClick: 0,
      adSales: 0,
      grossMargin: 0,
      incrementalMargin: 0,
      roas: 0,
      incrementalROAS: 0
    },
    targetEntitiesListFromAtlas: [],
    uploadedTargetKeywords: null,
    targetingTypeId: 'keywordTargeting'
  },
  marketStats: {
    queryConditions: {}
  },
  creative: {
    headline: '',
    brandLogoAssetId: '',
    mediaId: null
  },
  featured: {
    filters: {
      isDefault: true,
      termFilters: {},
      rangeFilters: {}
    },
    landingType: 'Brand Store',
    storeUrl: '',
    selectedFeaturedProducts: [
      {
        id: 'PR74032D',
        title: 'General Mills General Mills  Milk N Cereal Bars, 6 ea',
        brandName: 'General Mills',
        retailerId: 2,
        retailerSku: '016000664500',
        stacklineSku: 'PR74032D',
        brandId: 139108,
        categoryId: 1336,
        retailerIds: [2],
        categoryName: 'Granola & Nutrition Bars',
        subCategoryId: 51156,
        subCategoryName: 'Breakfast & Cereal Bars',
        upc: '016000664500'
      }
    ],
    selectedFeaturedUPCs: ['10311585'],
    retailPriceAverage: null,
    brandCogsPerUnitAverage: 0.18,
    retailerGrossMarginPercentAverage: 0.2,
    wholesalePriceAverage: null,
    featuredProductBrandIds: [139108],
    featuredProductCategoryIds: [1336],
    featuredProductSubCategoryIds: [51156]
  }
};
