import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import {
  receiveOmniProductServiceDataForCompare,
  receiveOmniProductServiceDataForMain,
  startFetchData,
  endFetchData
} from './actions';
import ReduxStore from 'src/types/store/reduxStore';
import { PERIODS } from '../constants';
import { OmniEntityGridRequestBody } from 'src/components/EntityGrid/EntityGrid/OmniEntityGrid';

export const getOmniProductUrlEndpointByMetricFieldName = (metricFieldName: string) => {
  switch (metricFieldName) {
    case 'commonEndPointForOmniGrid':
      return '/omni/entityGrid/getEntityGridMetrics';
    case 'endPointForTableView':
      return '/omni/entityTable/getEntityTableMetrics';
    case 'contentScoreView':
      return '/omni/content/getAvgContentScore';
    case 'contentAccuracyView':
      return '/omni/content/getAvgContentAccuracy';
    default:
      return '';
  }
};

export const fetchOmniProductServiceData =
  (apiBody: OmniEntityGridRequestBody, period: string, chartName: string, metricFieldName: string) =>
  async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
    dispatch(
      startFetchData({
        chartName,
        data: []
      })
    );
    const urlEndpoint = getOmniProductUrlEndpointByMetricFieldName(metricFieldName);
    try {
      const response = await axios.post(urlEndpoint, apiBody);
      if (response && response.status === 200) {
        const { data } = response;
        const entity = {
          chartName,
          data
        };
        if (period === PERIODS.MAIN) {
          dispatch(receiveOmniProductServiceDataForMain(entity));
        } else {
          dispatch(receiveOmniProductServiceDataForCompare(entity));
        }
        dispatch(
          endFetchData({
            chartName,
            data: []
          })
        );
      }
    } catch (e) {
      console.warn(e);
    }
  };
