import { availableRetailers } from '../store/modules/retailer/reducers';

/**
 * Generates a link to the search results on the retailer
 * given the retailer and search term
 *
 * @param {string} retailerId Stackline retailer ID
 * @param {string} searchTerm The search term, e.g. 'headphones'
 *
 */

export const generateSearchLink = (retailerId: string, searchTerm: string): string => {
  const index = availableRetailers.findIndex((retailer) => retailer.id === retailerId);

  if (index > -1) {
    const retailer = availableRetailers[index];
    let url = retailer.searchUrl + encodeURIComponent(searchTerm);
    if (['59', '75'].includes(retailerId)) {
      // MercadoLibre uses - for search string
      url = retailer.searchUrl + searchTerm.replace(/ /g, '-');
    }
    return url;
  }

  return '#';
};

/**
 * Generates a link to the product page on the retailer
 * given the retailer and retailer sku.  Only supports
 * Amazon (all countries) at the moment
 *
 * @param {string} retailerId Stackline retailer ID
 * @param {string} sku The retailer SKU, i.e. asin
 *
 */

export const generateProductLink = (retailerId: string | number, sku: string): string => {
  const index = availableRetailers.findIndex((retailer) => retailer.id === String(retailerId));

  if (index > -1) {
    const retailer = availableRetailers[index];
    if (retailer.skuUrl) {
      return retailer.skuUrl + sku;
    }
  }

  return '#';
};
