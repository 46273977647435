import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import _get from 'lodash/get';

import colors from 'src/utils/colors';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { getChartDisplayTimePeriod } from '../Renderer/EntityPageRenderer';
import ColumnChartContainer from 'src/components/Charts/Column';
import { buildAggregations } from 'src/components/AdManager/Search';

class AtlasContentScoreColumnChart extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object,
    entity: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object,
    entitySearchService: PropTypes.object.isRequired,
    widget: PropTypes.object.isRequired,
    aggregationConditions: PropTypes.object.isRequired,
    uniqueName: PropTypes.string,
    queryConditions: PropTypes.object.isRequired,
    fetchEntityMetrics: PropTypes.func.isRequired
  };

  static defaultProps = {
    mainTimePeriod: null,
    comparisonTimePeriod: null,
    uniqueName: 'columnChart'
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    this.fetchContentScore(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { comparisonTimePeriod, location, queryConditions, mainTimePeriod, retailer, entitySearchService } =
      nextProps;
    if (
      !_isEqual(retailer, this.props.retailer) ||
      !_isEqual(queryConditions, this.props.queryConditions) ||
      !_isEqual(mainTimePeriod, this.props.mainTimePeriod) ||
      !_isEqual(comparisonTimePeriod, this.props.comparisonTimePeriod) ||
      !_isEqual(location.search, this.props.location.search) ||
      !_isEqual(entitySearchService.mainEntityMetrics, this.props.entitySearchService.mainEntityMetrics)
    ) {
      this.fetchContentScore(nextProps || this.props);
    }
  }

  getColumChartSeries = () => {
    const { mainTimePeriod, entity, entitySearchService, widget, uniqueName } = this.props;
    const mainEntityMetrics = entitySearchService[`contentScoreColumnChart${uniqueName}`];
    const seriesData = [];
    widget.view.metricFields.forEach((field) => {
      if (mainEntityMetrics[`${field.name}_by_weekId`]) {
        const dataPoints = mainEntityMetrics[`${field.name}_by_weekId`].data;
        const score = this.computeScore(dataPoints, mainTimePeriod);
        seriesData.push({
          fieldId: field.name,
          name: field.displayName,
          value: score,
          entity,
          color: colors.stacklineBlue
        });
      }
    });

    return {
      entity,
      data: seriesData,
      groupByField: widget.data.groupByField,
      metricType: mainEntityMetrics[`${widget.view.metricFields[0].name}_by_weekId`].metricType,
      currencySymbol: mainEntityMetrics[`${widget.view.metricFields[0].name}_by_weekId`].currencySymbol,
      dataType: mainEntityMetrics[`${widget.view.metricFields[0].name}_by_weekId`].dataType
    };
  };

  fetchContentScore = (props) => {
    const {
      app,
      retailer,
      entity,
      fetchEntityMetrics,
      widget,
      mainTimePeriod,
      queryConditions,
      aggregationConditions,
      uniqueName
    } = props;
    const { indexName } = widget.data;
    const groupByField = widget.data.groupByFields[0];
    const [{ aggregations }] = buildAggregations(widget.view.metricFields);
    const aggregationConditionsCloned = _cloneDeep(aggregationConditions);
    if (!aggregationConditionsCloned.rangeFilters) {
      aggregationConditionsCloned.rangeFilters = [];
    }
    aggregationConditionsCloned.rangeFilters = aggregationConditionsCloned.rangeFilters.filter(
      (x) => x.fieldName !== 'weekId'
    );

    const isLatest = _get(widget, ['data', 'mainMetric', 'aggregationFunctionType']) === 'lastValue';

    aggregationConditionsCloned.rangeFilters.push({
      fieldName: 'weekId',
      minValue: isLatest ? mainTimePeriod.endWeek : mainTimePeriod.startWeek,
      maxValue: mainTimePeriod.endWeek
    });

    const requestOverrides = {
      aggregations: [
        {
          aggregationFields: aggregations,
          conditions: aggregationConditionsCloned,
          groupByFieldName: groupByField.name
        }
      ],
      conditions: queryConditions,
      pageNumber: 1,
      pageSize: 20
    };

    this.setState({ isLoading: true });
    const promises = [
      fetchEntityMetrics(`contentScoreColumnChart${uniqueName}`, { entity, retailer, app, indexName }, [
        requestOverrides
      ])
    ];

    Promise.all(promises)
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (!err.message.includes('Canceled network request')) {
          console.error(err);
        }
      });
  };

  computeScore = (dataPoints, timePeriod) => {
    let totalSum = 0;
    let totalPoints = 0;
    dataPoints.forEach((dataPoint) => {
      if (dataPoint.weekId >= timePeriod.startWeek && dataPoint.weekId <= timePeriod.endWeek) {
        totalSum += dataPoint.value;
        totalPoints += 1;
      }
    });

    return totalSum / totalPoints;
  };

  render() {
    const { widget, entity, retailer, mainTimePeriod, comparisonTimePeriod, app } = this.props;
    const { isLoading } = this.state;
    const chartDisplayTimePeriod = getChartDisplayTimePeriod(mainTimePeriod);
    const chartComparisonDisplayTimePeriod = getChartDisplayTimePeriod(comparisonTimePeriod);
    if (isLoading) {
      return null;
    }
    const columnChartSeries = this.getColumChartSeries();
    return (
      <div key={widget.view.name}>
        <ColumnChartContainer
          appDetails={app}
          mainEntity={entity}
          mainEntityMetrics={columnChartSeries}
          widget={widget}
          chartDisplayTimePeriod={chartDisplayTimePeriod}
          chartComparisonDisplayTimePeriod={chartComparisonDisplayTimePeriod}
          retailer={retailer}
          labelRotation={0}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, ['app', 'entitySearchService', 'comparisonTimePeriod', 'mainTimePeriod', 'retailer']);

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AtlasContentScoreColumnChart));
