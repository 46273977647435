import axios from 'axios';
import { useQuery } from 'react-query';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import {
  calculatePaidTrafficAverages,
  createDraftPaidTrafficPayloads
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useCompleteAdjustmentData/utils';
import { ForecastsAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import { useLatestForecastModel } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { useAppSelector } from 'src/utils/Hooks';

interface AdSpendMetrics {
  ad_spend: number;
  cpc: number;
  paid_traffic: number;
  ad_sales: number;
  roas: number;
}

interface AdSpendAdjustmentMetricsResponse {
  aggregations: { by_adjustmentId: { fieldId: string; additionalValues: { [key: string]: number } }[] };
}

const parsePublishedPaidTrafficMetrics = (response: AdSpendAdjustmentMetricsResponse[]): AdSpendMetrics => {
  return response[0].aggregations.by_adjustmentId.reduce(
    (accumulator, document) => {
      accumulator.paid_traffic = document.additionalValues.paidTrafficValue_sum_value;
      accumulator.ad_spend = document.additionalValues.adSpend_sum_value;
      return accumulator;
    },
    {
      ad_spend: 0,
      cpc: 0,
      paid_traffic: 0,
      ad_sales: 0,
      roas: 0
    }
  );
};

/**
 * Fetches the ad spend metrics for the specific adjustment
 */
export const useAdjustmentPaidTrafficMetrics = (
  adjustmentData: ForecastsAdjustmentData,
  useUnpublishedData = false
) => {
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const retailer = useAppSelector((state) => state.retailer);
  const { modelVersion, loading: modelLoading, currentPublishVersion } = useLatestForecastModel();

  const { adjustmentId } = adjustmentData;

  const adSpendMetricsBuilder = new AdvancedSearchRequestBuilder(
    `paidTraffic-client-${beaconClientId}`,
    'beacon-adjustment-paidtrafficmetrics'
  );

  const adSpendAggregations = new AggregationBuilder('adjustmentId')
    .addAggregationField('Paid Traffic', 'paidTrafficValue', 'sum', true)
    .addAggregationField('Ad Spend', 'adSpend', 'sum', true)
    .setSortDirection(null);

  adSpendMetricsBuilder
    .setSearchBy('parent')
    .setPageNumber(1)
    .setPageSize(1200)
    .setDoAggregation(true)
    .setReturnDocuments(false)
    .setRetailerId(retailer.id)
    .addConditionTermFilter('retailerId', 'should', [retailer.id])
    .addConditionTermFilter('forecastModelVersion', 'must', [modelVersion])
    .addConditionTermFilter('publishVersion', 'must', [currentPublishVersion])
    .addConditionTermFilter('adjustmentId', 'must', [adjustmentId])
    .addAggregation(adSpendAggregations.build());

  const adSpendMetricsRequest = adSpendMetricsBuilder.build();

  const {
    data: publishedPaidTrafficData,
    isLoading: publishedPaidTrafficLoading,
    isError: publishedPaidTrafficError
  } = useQuery(
    [adjustmentData, 'publishedPaidTrafficData'],
    async () => {
      try {
        const response = await axios.post(`api/beacon/AdvancedSearch?_id=published-paidTrafficMetricsForAdjustmentId`, [
          adSpendMetricsRequest
        ]);
        return response.data;
      } catch (error) {
        console.error('Failed to fetch published paid traffic metrics', error);
        return null;
      }
    },
    {
      enabled: adjustmentData.status === 'Published' && adjustmentData.planType === PlanTypeOption.PaidTraffic,
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // data is stale after 5 minutes
      cacheTime: 1000 * 60 * 30 // data is removed from the cache after 30 minutes
    }
  );

  const {
    data: draftedPaidTrafficData,
    isLoading: draftedPaidTrafficLoading,
    isError: draftedPaidTrafficError
  } = useQuery(
    [adjustmentData, 'draftedPaidTrafficData'],
    async () => {
      try {
        const response = await axios.post(`api/beaconforecast/GetPaidTrafficMetrics`, [
          createDraftPaidTrafficPayloads(adjustmentData)
        ]);
        return response.data;
      } catch (error) {
        console.error('Failed to fetch drafted paid traffic metrics', error);
        return null;
      }
    },
    {
      enabled:
        (useUnpublishedData || adjustmentData.status === 'Draft') &&
        adjustmentData.planType === PlanTypeOption.PaidTraffic,
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // data is stale after 5 minutes
      cacheTime: 1000 * 60 * 30 // data is removed from the cache after 30 minutes
    }
  );

  const draftedPaidTrafficMetrics = draftedPaidTrafficData
    ? calculatePaidTrafficAverages(draftedPaidTrafficData[0].paidTrafficMetrics)
    : null;

  const publishedPaidTrafficMetrics = publishedPaidTrafficData
    ? parsePublishedPaidTrafficMetrics(publishedPaidTrafficData)
    : null;

  return {
    isLoading: publishedPaidTrafficLoading || draftedPaidTrafficLoading || modelLoading,
    isError: publishedPaidTrafficError || draftedPaidTrafficError,
    data: draftedPaidTrafficMetrics || publishedPaidTrafficMetrics || null
  };
};
