import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

const Subtitle = ({ app: { queryParams: { additionalParams, searchParams } }, data }) => {
  const [subTitle, linkTitle] = data.displayName.split(':');

  if (data.id !== undefined && /<a.*>(.+?)<\/a>/g.test(linkTitle)) {
    const linkTitleText = linkTitle.match(/<a.*>(.+?)<\/a>/)[1];

    return (
      <div className="chart-subtitle">
        <span>{subTitle}: </span>
        <NavLink to={`/${data.type}/${data.id}${searchParams}${additionalParams}`}>{linkTitleText}</NavLink>
      </div>
    );
  }

  return <div className="chart-subtitle">{subTitle}</div>;
};

Subtitle.propTypes = {
  app: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

const EnhancedSubtitle = connect(({ app }) => ({ app }))(Subtitle);

export default EnhancedSubtitle;
