import { calculateNewQueryParams, getValidRetailerId } from 'src/store/modules/app/selectors';
import ReduxStore from 'src/types/store/reduxStore';
import { encodeHash } from 'src/utils/hashIds';
import { usesCustomTimePeriods } from 'src/utils/app';

/**
 * Filter a list of parameters from a string of query params
 */
const filteredParams = (searchParams: string, paramsToRemove: string[]) => {
  const params = new URLSearchParams(searchParams);
  paramsToRemove.forEach((param) => {
    params.delete(param);
  });
  return `?${params.toString()}`;
};

export const getHomeMapping = (
  searchParams = '',
  {
    user,
    app,
    retailer,
    mainTimePeriod,
    comparisonTimePeriod,
    allWeekIdsByRetailerId
  }: {} & Pick<
    ReduxStore,
    'user' | 'app' | 'retailer' | 'mainTimePeriod' | 'comparisonTimePeriod' | 'allWeekIdsByRetailerId'
  >
) => {
  const { isRestrictedDemoUser } = user.config && user.config;
  const filteredSearchParams = usesCustomTimePeriods() ? filteredParams(searchParams, ['wr']) : searchParams;

  const beaconParams = calculateNewQueryParams(
    app,
    { id: retailer.id === '0' ? Object.keys(allWeekIdsByRetailerId)[1] : retailer.id },
    mainTimePeriod,
    comparisonTimePeriod,
    {},
    {
      wr: null
    }
  ).searchParams;

  const discoverParams = calculateNewQueryParams(
    app,
    { id: getValidRetailerId(retailer, allWeekIdsByRetailerId) },
    mainTimePeriod,
    comparisonTimePeriod
  ).searchParams;

  return {
    atlas: isRestrictedDemoUser
      ? `/home${filteredSearchParams}&tab=summary&subtab=atlas&entityType=category`
      : `/company/${encodeHash(0)}?rid=${retailer.id}`,
    beacon: `/client/${user.config.vendor.hashId}${
      usesCustomTimePeriods() ? filteredParams(beaconParams, ['wr']) : beaconParams
    }`,
    advertising: `/overview${searchParams}&tab=${app.defaultQueryParams.tab}&subtab=${app.defaultQueryParams.subtab}`,
    omni: `/client/${user.config.vendor.BeaconClientId}${filteredSearchParams}`,
    discover: `/discover${
      usesCustomTimePeriods() ? filteredParams(discoverParams, ['wr']) : discoverParams
    }&tab=summary&subtab=${app.name}&discoverFilter=${['brand', 'category'].join(',')}`
  };
};
