import _indexOf from 'lodash/indexOf';
import _sortBy from 'lodash/sortBy';
import { DiscoverColumn } from 'src/components/Discover/DiscoverUtils/ColumnUtils';
import { ApiCallBody, initialApiBody } from 'src/components/Discover/DiscoverUtils/CommonUtils';
import ReduxStore from 'src/types/store/reduxStore';
import { localeParseFloat } from 'src/utils/metrics';
import numeral from 'numeral';

/* created a list of categories to add it to the API call so as the get the results */
const createSubscribedCategoriesArray = (categoriesArray: ReduxStore['categories']) => {
  const listOfCategories: number[] = [];
  categoriesArray.forEach((category) => {
    listOfCategories.push(+category.id);
  });
  return listOfCategories;
};

export const createBodyForApiCall = ({
  columnsSelected,
  isApply,
  filterObj,
  filterTitles,
  retailerObj,
  isSuperUser,
  superUserCategories,
  categories,
  comparisonTimePeriodObj,
  mainTimePeriodObj,
  selectedCategory,
  selectedSubCategory,
  brandChips,
  history,
  location
}: {
  columnsSelected: string[];
  isApply: boolean;
  filterObj: any;
  filterTitles: string[];
  retailerObj: ReduxStore['retailer'];
  isSuperUser: boolean;
  superUserCategories: ReduxStore['allSuperUserCategories'];
  categories: ReduxStore['categories'];
  mainTimePeriodObj: ReduxStore['mainTimePeriod'];
  comparisonTimePeriodObj: ReduxStore['comparisonTimePeriod'];
  selectedCategory: any;
  selectedSubCategory: any;
  brandChips: any[];
  history: any;
  location: any;
}) => {
  const apiBody: ApiCallBody = { ...initialApiBody };
  const filterHeaders = filterTitles;
  const filterFinalTitle = filterHeaders ? filterHeaders.join('/') : '';

  if (columnsSelected) {
    for (let i = 0; i < columnsSelected.length; i++) {
      if (columnsSelected.includes('brand')) {
        apiBody.shouldDisplayBrand = true;
      } else {
        apiBody.shouldDisplayBrand = false;
      }
      if (columnsSelected.includes('category')) {
        apiBody.shouldDisplayCategory = true;
      } else {
        apiBody.shouldDisplayCategory = false;
      }
      if (columnsSelected.includes('subcategory')) {
        apiBody.shouldDisplaySubCategory = true;
      } else {
        apiBody.shouldDisplaySubCategory = false;
      }
      if (columnsSelected.includes('department')) {
        apiBody.shouldDisplayDepartment = true;
      } else {
        apiBody.shouldDisplayDepartment = false;
      }
      if (columnsSelected.includes('manufacturer')) {
        apiBody.shouldDisplayManufacturer = true;
      } else {
        apiBody.shouldDisplayManufacturer = false;
      }
    }
    apiBody.retailerId = parseInt(retailerObj.id, 10);
    const subscribedCategoryIds = isSuperUser
      ? // @ts-ignore TODO: verify this behavior in super user mode
        createSubscribedCategoriesArray(superUserCategories)
      : createSubscribedCategoriesArray(categories);

    const mainTimePeriodStartWeek = mainTimePeriodObj.startWeek;
    const mainTimePeriodEndWeek = mainTimePeriodObj.endWeek;
    const comparisonTimePeriodStartWeek = comparisonTimePeriodObj.startWeek;
    const comparisonTimePeriodEndWeek = comparisonTimePeriodObj.endWeek;

    apiBody.clientInformation.subscribedCategoryIds.push(...subscribedCategoryIds);
    apiBody.currentPeriod.customPeriod.fromWeek = mainTimePeriodStartWeek;
    apiBody.currentPeriod.customPeriod.toWeek = mainTimePeriodEndWeek;
    apiBody.pastPeriod.customPeriod.fromWeek = comparisonTimePeriodStartWeek;
    apiBody.pastPeriod.customPeriod.toWeek = comparisonTimePeriodEndWeek;
    apiBody.resultsCountAtLowestGranularity = 150;
  }
  if (isApply) {
    let finalObj: Record<string, any> = {};
    const { decimal: currentDecimal, thousands: currentThousand } = numeral.localeData().delimiters;
    Object.keys(filterObj).forEach((key: string) => {
      Object.keys(filterObj[key]).forEach((filterKey: string) => {
        if (filterObj[key][filterKey].rangeBegin !== 0 || filterObj[key][filterKey].rangeEnd !== 0) {
          // This will create dynamic keys and values if the range end and range begin are not zero
          // Also, using [] operator we can create dynamic keys in a looping object
          // { {brand/cat,cat/brand}, brand : { {retailSales/%change/else..}, retailSales : {rangeBegin: non_zero_value, rangeEnd: non_zerov_alue }} }

          let begin = filterObj[key][filterKey].rangeBegin;
          let end = filterObj[key][filterKey].rangeEnd;
          if (['Retail Sales', 'Units Sold'].includes(filterKey)) {
            begin = begin === '' ? 0 : parseInt(begin, 10);
            end = end === '' ? 0 : parseInt(end, 10);
          }
          if (['Unit Change', 'Sales Change'].includes(filterKey)) {
            begin = begin === '' ? -100000000000 : localeParseFloat(begin, currentThousand, currentDecimal);
          }
          if (['Share Change'].includes(filterKey)) {
            begin = begin === '' ? -10000 : parseInt(begin, 10);
          } else {
            begin = begin === '' ? 0 : localeParseFloat(begin, currentThousand, currentDecimal);
            end = end === '' ? 0 : localeParseFloat(end, currentThousand, currentDecimal);
          }
          if (
            filterObj[key][filterKey].rangeBegin !== '' &&
            filterObj[key][filterKey].rangeBegin !== 0 &&
            (filterObj[key][filterKey].rangeEnd === 0 || filterObj[key][filterKey].rangeEnd === '')
          ) {
            finalObj = {
              ...finalObj,
              [key]: {
                ...finalObj[key],
                [filterKey]: { rangeBegin: begin, rangeEnd: 100000000000 }
              }
            };
          }
          if (
            (filterObj[key][filterKey].rangeBegin === '' || filterObj[key][filterKey].rangeBegin === 0) &&
            filterObj[key][filterKey].rangeEnd !== 0 &&
            filterObj[key][filterKey].rangeEnd !== ''
          ) {
            finalObj = {
              ...finalObj,
              [key]: {
                ...finalObj[key],
                [filterKey]: {
                  rangeBegin:
                    filterKey === 'marketShareChange' ||
                    filterKey === 'popRetailSalesChangePct' ||
                    filterKey === 'popUnitsSoldChangePct'
                      ? -10000000000
                      : 0,
                  rangeEnd: end
                }
              }
            };
          }
          if (
            (filterObj[key][filterKey].rangeBegin === '' && filterObj[key][filterKey].rangeEnd === '') ||
            (filterObj[key][filterKey].rangeBegin === 0 && filterObj[key][filterKey].rangeEnd === 0)
          ) {
            finalObj = { ...finalObj };
          }
          if (
            filterObj[key][filterKey].rangeBegin !== '' &&
            filterObj[key][filterKey].rangeEnd !== '' &&
            filterObj[key][filterKey].rangeBegin !== 0 &&
            filterObj[key][filterKey].rangeEnd !== 0
          ) {
            finalObj = {
              ...finalObj,
              [key]: {
                ...finalObj[key],
                [filterKey]: {
                  rangeBegin: begin,
                  rangeEnd: end
                }
              }
            };
          }
        }
      });
    });
    if (finalObj) {
      Object.keys(finalObj).forEach((key: string) => {
        /* when finalFiltertitle does not match up with the key, we do not apply this filter to the api call */
        if (key !== filterFinalTitle) {
          return;
        }
        if (key === 'brand') {
          apiBody.brandFilter = finalObj[key];
        }
        if (key === 'category') {
          apiBody.categoryFilter = finalObj[key];
        }
        if (key === 'brand/category') {
          apiBody.brandFilterAtGranularity = finalObj[key];
        }
        if (key === 'manufacturer') {
          apiBody.manufacturerFilter = finalObj[key];
        }
        if (key === 'subcategory') {
          apiBody.subCategoryFilter = finalObj[key];
        }
        if (key === 'department') {
          apiBody.departmentFilter = finalObj[key];
        }
        if (key === 'manufacturer/department') {
          apiBody.manufacturerFilterAtGranularity = finalObj[key];
        }
        if (key === 'brand/department') {
          apiBody.brandFilterAtGranularity = finalObj[key];
        }
        if (key === 'brand/subcategory') {
          apiBody.brandFilterAtGranularity = finalObj[key];
        }
        if (key === 'manufacturer/category') {
          apiBody.manufacturerFilterAtGranularity = finalObj[key];
        }
        if (key === 'manufacturer/subcategory') {
          apiBody.manufacturerFilterAtGranularity = finalObj[key];
        }
      });
      history.push(location.pathname + location.search, finalObj);
    }
    if (selectedCategory.length > 0 && isApply) {
      const ids: number[] = [];
      selectedCategory.forEach((cat: any) => ids.push(cat.categoryId));
      if (apiBody.categoryFilter != null) {
        apiBody.categoryFilter = { ...apiBody.categoryFilter, includeIds: ids };
      }
      if (apiBody.categoryFilter == null) {
        let selectedIds: number[] = [];
        if (ids.includes(0)) {
          categories.forEach((cat) => selectedIds.push(cat.categoryId));
        } else {
          selectedIds = [...ids];
        }
        apiBody.categoryFilter = { includeIds: selectedIds };
      }
    }

    if (selectedSubCategory.length > 0 && isApply) {
      const subcat: number[] = [];
      selectedSubCategory.forEach((cat: any) => subcat.push(parseInt(cat.subCategoryId, 10)));
      if (apiBody.subCategoryFilter != null) {
        apiBody.subCategoryFilter = { ...apiBody.subCategoryFilter, includeIds: subcat };
      }
      if (apiBody.subCategoryFilter == null) {
        apiBody.subCategoryFilter = { includeIds: subcat };
      }
    }
    if (brandChips.length > 0 && isApply) {
      const brandIds = brandChips.map((brand) => brand.i);
      if (apiBody.brandFilter != null) {
        apiBody.brandFilter = { ...apiBody.brandFilter, includeIds: brandIds };
      }
      if (apiBody.brandFilter == null) {
        apiBody.brandFilter = { includeIds: brandIds };
      }
    }
  }
  return apiBody;
};

export const sortColumnArrangement = (columns: DiscoverColumn[]) => {
  const unArrangedColumns = columns;
  const orderToBeArranged = [
    'manufacturerName',
    'brandName',
    'departmentName',
    'categoryName',
    'subCategoryName',
    'retailSales',
    'retailSalesGrowth',
    'marketShare',
    'marketShareChange',
    'unitsSold',
    'unitsSoldGrowth',
    'trafficShare',
    'conversionRate',
    'avgRating'
  ];
  const sorted = _sortBy(unArrangedColumns, (obj) => {
    return _indexOf(orderToBeArranged, obj.field);
  });
  const uniqueArray = sorted.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      sorted.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  return uniqueArray;
};
