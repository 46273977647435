import React from 'react';
import {
  SelectIncludeCheckbox,
  SelectExcludeCheckbox,
  SelectedIncludeCheckbox,
  SelectedExcludeCheckbox
} from 'src/components/SvgIcons/SvgIcons';
import { Tooltip } from '@mui/material';

/* Used to render the include/exclude checkboxes next to certain Advanced Search filters (Brands, Categories, Subcategories).
   Parameters: the component filter selection, and the related setState function.
   Renders a tooltip on hover for Include/Exclude
*/

const checkboxRenderer = (filter = 'default', selectFilter: Function) => {
  const toolTipOptions = {
    include: { title: 'Include', style: { display: 'flex' } },
    exclude: { title: 'Exclude', style: { display: 'flex' } }
  };
  return filter === 'include' ? (
    <>
      <SelectedIncludeCheckbox className="custom-svg" id="include" onClick={() => selectFilter('include')} />
      <SelectExcludeCheckbox className="custom-svg" id="exclude" onClick={() => selectFilter('exclude')} />
    </>
  ) : filter === 'exclude' ? (
    <>
      <SelectIncludeCheckbox className="custom-svg" id="include" onClick={() => selectFilter('include')} />
      <SelectedExcludeCheckbox className="custom-svg" id="exclude" onClick={() => selectFilter('exclude')} />
    </>
  ) : filter === 'default' ? (
    <>
      <Tooltip title={toolTipOptions.include.title}>
        <div style={toolTipOptions.include.style}>
          <SelectIncludeCheckbox className="custom-svg" id="include" onClick={() => selectFilter('include')} />
        </div>
      </Tooltip>
      <Tooltip title={toolTipOptions.exclude.title}>
        <div style={toolTipOptions.exclude.style}>
          <SelectExcludeCheckbox className="custom-svg" id="exclude" onClick={() => selectFilter('exclude')} />
        </div>
      </Tooltip>
    </>
  ) : null;
};

export default checkboxRenderer;
