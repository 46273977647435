/* eslint-disable react/prop-types */
import React from 'react';
import { NavLink } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { PropTypesOf } from 'src/types/utils';
import Tooltip from '@mui/material/Tooltip';

const NavLinkWrapper: React.FC<{
  avatarExists?: boolean;
  avatarClassName?: string;
  avatarContent?: string;
  containerClassName: string;
  displayName: string;
  displayTextClassName: string;
  icon?: object;
  iconExists?: boolean;
  isActive: PropTypesOf<typeof NavLink>['isActive'];
  linkTo: string;
  navLinkClassName?: string;
  onClick?: () => void;
}> = ({
  avatarExists,
  avatarClassName = '',
  avatarContent = '',
  containerClassName,
  displayName,
  displayTextClassName,
  icon,
  iconExists,
  isActive,
  linkTo,
  navLinkClassName = 'navigation__item-link',
  onClick
}) => {
  return (
    <div className={containerClassName}>
      <NavLink
        activeClassName="navigation__item-link--active"
        className={navLinkClassName}
        isActive={isActive}
        to={linkTo}
        onClick={onClick}
      >
        {iconExists ? icon : null}

        {avatarExists && (
          <Tooltip title={displayName} placement="right">
            <Avatar className={avatarClassName}>{avatarContent}</Avatar>
          </Tooltip>
        )}
        <div className={displayTextClassName}>{displayName}</div>
      </NavLink>
    </div>
  );
};

export default NavLinkWrapper;
