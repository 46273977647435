import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { EntityRowContainer } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/EntityRowContainer';
import { PaidTrafficSummaryMetrics } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Components/PaidTrafficSummaryMetrics';
import { PROMOTION_OPTIONS } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/Components/RetailPricePromotionInputs';
import {
  PlanTypeOption,
  planTypeToDisplayName
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { useAdjustmentPaidTrafficMetrics } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useAdjustmentPaidTrafficMetrics';
import { useOriginalProjection } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/useOriginalProjection';
import { usePromotionAdjustmentMetrics } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/hooks/usePromotionAdjustmentMetrics';
import { parseOriginalProjectionResponse } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/parsers';
import { AdjustmentForm } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import {
  generateNetImpactText,
  generateNewProjectionText,
  generateOriginalPlanText
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/utils';
import { AdjustmentRecordItem } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ViewAdjustmentModal/AdjustmentRecordItem';
import { useFetchAdjustmentMetaData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ViewAdjustmentModal/utils';
import { ForecastsAdjustmentData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/forecastsAdjustmentsTableUtils';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import { useMetricFormatter } from 'src/utils/Hooks';
import { shouldShowPromotionsAdjustment } from 'src/utils/app';

/**
 * Returns the default view after clicking on an adjustment. Renders a variety of summarized metrics including a summary of the adjustment plan
 * as well as records of changes that have been made to the adjustment over time.
 */
export const ViewAdjustment = ({
  product,
  adjustmentForm,
  adjustmentData
}: {
  product: ForecastsAdjustmentData['product'];
  adjustmentForm: AdjustmentForm;
  adjustmentData: ForecastsAdjustmentData;
}) => {
  /**
   * Only fetch ad spend once to display in the summarized plan text
   */

  const [showMore, setShowMore] = useState(false);

  const { title, planType, description, startDate, endDate, isPromotionAdjustment, promotionType } = adjustmentForm;
  const formatMetric = useMetricFormatter();
  const fetchAdjustmentMetaData = useFetchAdjustmentMetaData();

  const { data: originalProjectionResponse, isLoading: originalProjectionLoading } = useOriginalProjection({
    stacklineSku: product.stacklineSku,
    startWeekId: startDate,
    endWeekId: endDate
  });

  const { data: promotionMetrics } = usePromotionAdjustmentMetrics({
    enabled: isPromotionAdjustment && planType === PlanTypeOption.RetailPrice,
    queryKeys: [isPromotionAdjustment, promotionType, adjustmentForm],
    adjustmentId: adjustmentData.adjustmentId,
    ignoreConflictWithAdjustmentIds: [adjustmentData.adjustmentId],
    adjustmentForm,
    retailerSku: product.retailerSku,
    stacklineSku: product.stacklineSku,
    categoryId: product.categoryId,
    subCategoryId: product.subCategoryId
  });

  const parsedData = originalProjectionResponse ? parseOriginalProjectionResponse(originalProjectionResponse) : null;

  const { data, isLoading } = useQuery(
    [adjustmentData.adjustmentId],
    async () =>
      fetchAdjustmentMetaData({
        adjustmentId: adjustmentData.adjustmentId,
        retailerSku: adjustmentData.retailerSku,
        retailerId: adjustmentData.retailerId
      }),
    {
      staleTime: 0,
      retry: 0,
      refetchOnWindowFocus: false
    }
  );

  const { data: paidTrafficData, isLoading: paidTrafficDataLoading } = useAdjustmentPaidTrafficMetrics(adjustmentData);

  const originalProjectionText = useMemo(() => {
    return generateOriginalPlanText({ adjustmentForm, parsedData, formatMetric, isPromotionAdjustment });
  }, [adjustmentForm, parsedData, formatMetric, isPromotionAdjustment]);

  const newProjectionText = useMemo(() => {
    return generateNewProjectionText({
      adjustmentForm,
      parsedData,
      formatMetric,
      paidTrafficMetrics: paidTrafficData,
      promotionProjection: promotionMetrics,
      isPromotionAdjustment
    });
  }, [adjustmentForm, parsedData, formatMetric, paidTrafficData, promotionMetrics, isPromotionAdjustment]);

  const netImpactText = useMemo(() => {
    return generateNetImpactText({ netImpact: adjustmentData.netImpact, planType, formatMetric });
  }, [adjustmentData.netImpact, planType, formatMetric]);

  return (
    <>
      <EntityRowContainer productEntity={product} />
      <Flex gap="xxl">
        <Flex flexDirection="column" gap="sm" width="100px">
          <Text variant="subtitle2" title="Plan Type">
            Plan Type
          </Text>
          <Text variant="body2">{planTypeToDisplayName[planType]}</Text>
        </Flex>
        <Flex flexDirection="column" gap="sm" width="135px" sx={{ wordBreak: 'break-word' }}>
          <Text variant="subtitle2">Title</Text>
          <Text variant="body2">{title}</Text>
        </Flex>
        <Flex flexDirection="column" gap="sm" width="255px" sx={{ wordBreak: 'break-word' }}>
          <Text variant="subtitle2">Description</Text>
          <Text variant="body2">{description}</Text>
        </Flex>
      </Flex>
      <Flex marginTop="24px" gap="sm" flexDirection="column">
        <Text variant="subtitle2">Plan Change Confirmation</Text>
        <Flex gap="sm" flexDirection="column" minHeight="100px">
          {originalProjectionLoading || paidTrafficDataLoading ? (
            <>
              <SlSkeleton variant="rounded" height={19} width="100%" />
              <SlSkeleton variant="rounded" height={19} width="100%" />
            </>
          ) : (
            <>
              <Text variant="body2">{originalProjectionText}</Text>
              <Text variant="body2">{newProjectionText}</Text>
            </>
          )}
        </Flex>
      </Flex>
      <Flex height="60px" gap="sm" flexDirection="column">
        <Text variant="subtitle2">
          {isPromotionAdjustment
            ? `This price adjustment is a ${
                PROMOTION_OPTIONS.find((option) => promotionType === option.id)?.label ?? ''
              } promotion.`
            : planType === PlanTypeOption.RetailPrice && shouldShowPromotionsAdjustment()
            ? 'This price adjustment is not a promotion.'
            : 'Sales Net Impact'}
        </Text>
        {originalProjectionLoading || paidTrafficDataLoading ? (
          <>
            <SlSkeleton variant="rounded" height={19} width="100%" />
            <SlSkeleton variant="rounded" height={19} width="100%" />
          </>
        ) : (
          <Text variant="body2">{netImpactText}</Text>
        )}
      </Flex>

      {/* Paid Traffic Summary Metrics (only for paid traffic adjustment) */}
      {adjustmentData.planType === PlanTypeOption.PaidTraffic && (
        <PaidTrafficSummaryMetrics adjustmentData={adjustmentData} parsedData={parsedData} />
      )}
      <Flex alignItems="flex-start" marginTop="24px" marginBottom="32px" flexDirection="column">
        {data && !isLoading && !originalProjectionLoading && !paidTrafficDataLoading
          ? data.adjustmentLogs.slice(0, showMore ? undefined : 1).map((record, index) => {
              return (
                <AdjustmentRecordItem
                  adjustmentRecord={record}
                  userData={data.userMetaData[record.updatedByUserId]} // Access the user meta data based on the record user ID
                  key={record.lastUpdatedDate}
                  isFirst={index === 0}
                  isLast={showMore ? index === data.adjustmentLogs.length - 1 : true}
                />
              );
            })
          : null}

        {/* Show more button  */}
        {data && data.adjustmentLogs.length > 1 && !showMore && !originalProjectionLoading ? (
          <button
            style={{ marginLeft: '28px', marginTop: '8px', border: 'none', background: 'none', padding: '0' }}
            onClick={() => setShowMore(true)}
          >
            <Text variant="subtitle2" underlined>
              Show more
              {showMore}
            </Text>
          </button>
        ) : null}
      </Flex>
    </>
  );
};
