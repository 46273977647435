/* eslint-disable no-var */
declare var later: any;

import axios from 'axios';
import { store } from 'src/main';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';
import { AdCampaignManagerServiceClient } from 'src/utils/adCampaignManagerServiceClient';
import {
  IScheduledAction,
  IAdCampaign,
  IAdPortfolioAdPlatformSettingsByClient,
  IAdTarget,
  IScheduledActionSummary,
  AD_CAMPAIGN_STATUS
} from 'sl-ad-campaign-manager-data-model';
import { IScheduledActionsGroup, IScheduleDetail } from 'src/store/modules/adManager/adScheduledActionGroups/types';
import moment from 'moment-timezone';
import isNil from 'lodash/isNil';

const timeZoneOffsetInMinutes = new Date().getTimezoneOffset();
const timeZone = moment.tz.guess();
const timeZoneString = moment.tz.zone(timeZone)!.abbr(timeZoneOffsetInMinutes);

require('later/later');

const tomorrow = moment().add(1, 'days');

export const weekDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
const endOfMonth = moment(tomorrow).endOf('month');

enum PeriodType {
  HOURS_IN_DAY = 'hoursInDay',
  DAYS_OF_THE_WEEK = 'daysOfTheWeek',
  DATE_OR_DATE_RANGE = 'dateOrDateRange'
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IPeriodOption {
  name: PeriodType;
  displayName: string;
  startTime: string;
  endTime: string;
  startDate: any;
  endDate: any;
  startDay?: any;
  endDay?: any;
  weekDaysMapping?: boolean[];
}

export const periodOptions: {
  [key: string]: IPeriodOption;
} = {
  hoursInDay: {
    name: PeriodType.HOURS_IN_DAY,
    displayName: 'Hours in the day',
    startTime: '06:00',
    endTime: '13:00',
    startDate: tomorrow.startOf('day'),
    endDate: endOfMonth.endOf('day'),
    weekDaysMapping: [true, true, true, true, true, true, true]
  },
  daysOfTheWeek: {
    name: PeriodType.DAYS_OF_THE_WEEK,
    displayName: 'Days of the week',
    startTime: '00:01',
    endTime: '23:59',
    startDay: 'SAT',
    endDay: 'SUN',
    startDate: tomorrow.startOf('day'),
    endDate: endOfMonth.endOf('day')
  },
  dateOrDateRange: {
    name: PeriodType.DATE_OR_DATE_RANGE,
    displayName: 'Date or date range',
    startTime: '00:01',
    endTime: '23:59',
    startDate: tomorrow.startOf('day'),
    endDate: endOfMonth.endOf('day')
  }
};

export const metricOptions = {
  campaign: {
    level: 'campaign',
    levelDisplayName: 'Campaign',
    operator: 'AND',
    startDate: tomorrow.startOf('day'),
    endDate: endOfMonth.endOf('day'),
    rules: [
      {
        days: 3,
        metric: 'impressions',
        value: 0,
        direction: 'gt' // "lt"
      }
    ]
  },
  target: {
    level: 'target',
    levelDisplayName: 'Target',
    operator: 'AND',
    startDate: tomorrow.startOf('day'),
    endDate: endOfMonth.endOf('day'),
    rules: [
      {
        days: 3,
        metric: 'impressions',
        value: 0,
        direction: 'gt' // "lt"
      }
    ]
  },
  product: {
    level: 'product',
    levelDisplayName: 'Product',
    operator: 'AND',
    startDate: tomorrow.startOf('day'),
    endDate: endOfMonth.endOf('day'),
    rules: [
      {
        days: 3,
        metric: 'impressions',
        value: 0,
        direction: 'gt' // "lt"
      }
    ]
  }
};

export enum ActionMethod {
  Increase = 'Increase',
  Reduce = 'Reduce',
  Set = 'Set'
}

export enum ChangeAmountOptions {
  IncreaseAmount = 'increaseAmount',
  DecreaseAmount = 'decreaseAmount',
  SetAmount = 'setAmount'
}

export enum ChangePercentOptions {
  IncreasePercent = 'increasePercent',
  DecreasePercent = 'decreasePercent'
}

export const CHANGE_AMOUNT_OPTIONS = [
  ChangeAmountOptions.IncreaseAmount,
  ChangeAmountOptions.DecreaseAmount,
  ChangeAmountOptions.SetAmount
];

export const CHANGE_PERCENT_OPTIONS = [ChangePercentOptions.IncreasePercent, ChangePercentOptions.DecreasePercent];

export const SA_DEFAULT_AMOUNT_CHANGE = 1;
export const SA_DEFAULT_PERCENT_CHANGE = 10;

/**
 * map actionMethod field values for new SA
 * @param actionMethod
 */
export const getActionMethodFromOption = (actionMethod: ChangeAmountOptions | ChangePercentOptions): ActionMethod => {
  if (actionMethod === ChangeAmountOptions.SetAmount) {
    return ActionMethod.Set;
  } else if ([ChangeAmountOptions.IncreaseAmount, ChangePercentOptions.IncreasePercent].includes(actionMethod)) {
    return ActionMethod.Increase;
  }
  return ActionMethod.Reduce;
};

/**
 * decode scheduled action details for exisitng SA
 * @param actionMehtod
 * @param actionPercent
 * @param actionAmount
 */
export const getActionObject = (
  actionMehtod: ActionMethod,
  actionPercent: number | null,
  actionAmount: number | null
) => {
  if (isNil(actionPercent) && actionAmount !== null) {
    return {
      method:
        actionMehtod === ActionMethod.Increase
          ? ChangeAmountOptions.IncreaseAmount
          : actionMehtod === ActionMethod.Reduce
          ? ChangeAmountOptions.DecreaseAmount
          : ChangeAmountOptions.SetAmount,
      value: actionAmount
    };
  }
  return {
    method:
      actionMehtod === ActionMethod.Increase
        ? ChangePercentOptions.IncreasePercent
        : ChangePercentOptions.DecreasePercent,
    value: actionPercent
  };
};

export enum ActionType {
  Status = 'status',
  TargetStatus = 'targetStatus',
  ProductStatus = 'productStatus',
  Budgets = 'budgets',
  Bid = 'bid'
}

export interface ActionOption {
  name: ActionType;
  displayName: string;
  actionDescription: string;
  periodOptions: IPeriodOption[];
  metricOptions: any[];
  amount?: number;
  method?: string;
  value?: number;
}

// Changing order of this options will affect SA page.
// Ref: actionOpts on Create.tsx
export const actionOptions: ActionOption[] = [
  {
    name: ActionType.Status,
    displayName: 'Change my campaign status',
    actionDescription: 'Change campaign status',
    method: 'Pause',
    periodOptions: [periodOptions.hoursInDay, periodOptions.daysOfTheWeek, periodOptions.dateOrDateRange],
    metricOptions: [metricOptions.campaign]
  },
  {
    name: ActionType.TargetStatus,
    displayName: 'Change my target status',
    actionDescription: 'Change target status',
    method: 'Pause',
    periodOptions: [periodOptions.hoursInDay, periodOptions.daysOfTheWeek, periodOptions.dateOrDateRange],
    metricOptions: [metricOptions.target]
  },
  {
    name: ActionType.ProductStatus,
    displayName: 'Change my product status',
    actionDescription: 'Change product status',
    method: 'Pause',
    periodOptions: [periodOptions.hoursInDay, periodOptions.daysOfTheWeek, periodOptions.dateOrDateRange],
    metricOptions: [metricOptions.product]
  },
  {
    name: ActionType.Budgets,
    displayName: 'Change my budget',
    actionDescription: 'Change budgets',
    method: ChangeAmountOptions.IncreaseAmount,
    value: SA_DEFAULT_AMOUNT_CHANGE,
    periodOptions: [periodOptions.dateOrDateRange, periodOptions.daysOfTheWeek],
    metricOptions: [metricOptions.campaign]
  },
  {
    name: ActionType.Bid,
    displayName: 'Change my bid prices',
    actionDescription: 'Change bids',
    method: ChangeAmountOptions.IncreaseAmount,
    value: SA_DEFAULT_AMOUNT_CHANGE,
    periodOptions: [periodOptions.hoursInDay, periodOptions.daysOfTheWeek, periodOptions.dateOrDateRange],
    metricOptions: [metricOptions.campaign, metricOptions.target]
  }
];

const getUTCHourMinute = (localHourMinute: string): string => {
  const localHour = +localHourMinute.substring(0, 2);
  const localMinute = +localHourMinute.substring(3, 5);
  const localDateTime = moment.tz(timeZone).startOf('day');
  localDateTime.hours(localHour).minutes(localMinute);
  return localDateTime.tz('UTC').format('HH:mm');
};

// this function should convert a schedule object to be a cronSchedule string in a more robust manner
const encodeCronSchedule = (period: IPeriodOption) => {
  if (period.name === PeriodType.DAYS_OF_THE_WEEK) {
    const { startDay, endDay, startTime, endTime } = period;

    // start
    const startDateInLocal = moment(`${_capitalize(startDay)} ${startTime}`, 'ddd HH:mm');
    const startDateInUtc = moment.utc(startDateInLocal);

    // end
    const endDateInLocal = moment(`${_capitalize(endDay)} ${endTime}`, 'ddd HH:mm');
    const endDateInUtc = moment.utc(endDateInLocal);

    const startCron = startDateInUtc.format('0 m H ? * ddd *').toUpperCase();
    const endCron = endDateInUtc.format('0 m H ? * ddd *').toUpperCase();

    return { startCron, endCron };
  } else if (period.name === PeriodType.DATE_OR_DATE_RANGE) {
    const { startDate, endDate } = period;
    // start
    const startDay = moment.utc(startDate).format('DD');
    const startMonth = moment.utc(startDate).format('MMM').toUpperCase();
    const startYear = moment.utc(startDate).format('YYYY');
    const startTime = moment.utc(startDate).format('1 H'); // hardcoded: should always start with minute = 1

    // end
    const endDay = moment.utc(endDate).format('DD');
    const endMonth = moment.utc(endDate).format('MMM').toUpperCase();
    const endYear = moment.utc(endDate).format('YYYY');
    const endTime = moment.utc(endDate).format('m H');

    const startCron = `0 ${startTime} ${startDay} ${startMonth} ? ${startYear}`;
    const endCron = `0 ${endTime} ${endDay} ${endMonth} ? ${endYear}`;
    return { startCron, endCron };
  } else {
    const { startTime, endTime, weekDaysMapping } = period;
    const startTimeUTC = getUTCHourMinute(startTime);
    const endTimeUTC = getUTCHourMinute(endTime);
    const startHour = startTimeUTC.substring(0, 2);
    const startMinute = startTimeUTC.substring(3, 5);
    const endHour = endTimeUTC.substring(0, 2);
    const endMinute = endTimeUTC.substring(3, 5);
    let weekDayCron = '?';
    if (weekDaysMapping.some((day) => !day)) {
      const weekDaysApply: string[] = [];
      weekDaysMapping.forEach((day, idx) => {
        if (day) {
          weekDaysApply.push(weekDays[idx]);
        }
      });
      weekDayCron = weekDaysApply.join();
    }
    // const startCronExpressionBuilder = new CronBuilder('0 0 0 1/1 * ? *');
    // const endCronExpressionBuilder = new CronBuilder('0 0 0 1/1 * ? *');
    // startCronExpressionBuilder.set('hour', startHour);
    // startCronExpressionBuilder.set('minute', startMinute);
    // endCronExpressionBuilder.set('hour', endHour);
    // endCronExpressionBuilder.set('minute', endMinute);

    // return { startCron: startCronExpressionBuilder.build(), endCron: endCronExpressionBuilder.build() };
    // 0 0 12 * * ? *
    const startCron = `0 ${startMinute} ${startHour} * * ${weekDayCron} *`;
    const endCron = `0 ${endMinute} ${endHour} * * ${weekDayCron} *`;

    return { startCron, endCron };
  }
};

const getScheduleActionPlanDisplayName = (scheduledAction: IScheduledAction, isSummarized: boolean = false): string => {
  const actionOptionSelected = actionOptions.find(
    (x) =>
      x.name ===
      (isSummarized ? scheduledAction.actionDescription : scheduledAction.extendedAttributes.actionDescription)
  );
  if (actionOptionSelected) {
    return actionOptionSelected.displayName;
  }
  return scheduledAction.scheduledActionHandlerType;
};

const getAnyOneEntity = (
  scheduledAction: IScheduledAction
): IAdCampaign | IAdPortfolioAdPlatformSettingsByClient | IAdTarget => {
  if (
    scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adPortfolios &&
    scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adPortfolios.length
  ) {
    return scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adPortfolios[0];
  }

  if (
    scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adCampaigns &&
    scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adCampaigns.length
  ) {
    return scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adCampaigns[0];
  }

  if (
    scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adCampaignsBudgetSetting &&
    scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adCampaignsBudgetSetting.length
  ) {
    return scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adCampaignsBudgetSetting[0];
  }

  if (
    scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adTargets &&
    scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adTargets.length
  ) {
    return scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adTargets[0];
  }
  if (
    scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adCampaignProducts &&
    scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adCampaignProducts.length
  ) {
    return scheduledAction.extendedAttributes.adUpdateRequest.extendedAttributes.adCampaignProducts[0];
  }
  throw new Error('No entities found on the scheduled action');
};

const getAnyOneEntityFromSummarizedSA = (
  scheduledAction: IScheduledActionSummary
): {
  numberOfEntities: number;
  entityType: string;
  status: string;
} => {
  const { adCampaignProducts, adCampaigns, adTargets, adCampaignsBudgetSetting } =
    scheduledAction.adUpdateRequestSummary;

  const numberOfCampaignProducts = _get(adCampaignProducts, 'numberOfCampaignProducts');
  if (!isNil(numberOfCampaignProducts)) {
    return {
      numberOfEntities: numberOfCampaignProducts,
      entityType: 'product',
      status: _get(adCampaignProducts, 'campaignProductStatus', '')
    };
  }

  const numberOfCampaigns = _get(adCampaigns, 'numberOfCampaigns');
  if (!isNil(numberOfCampaigns)) {
    return {
      numberOfEntities: numberOfCampaigns,
      entityType: 'campaign',
      status: _get(adCampaigns, 'campaignStatus', '')
    };
  }

  const numberOfTargets = _get(adTargets, 'numberOfTargets');
  if (!isNil(numberOfTargets)) {
    return {
      numberOfEntities: numberOfTargets,
      entityType: 'target',
      status: _get(adTargets, 'targetStatus', '')
    };
  }

  const numberOfCampaignsBudgetSetting = _get(adCampaignsBudgetSetting, 'numberOfCampaignsBudgetSetting');
  if (!isNil(numberOfCampaignsBudgetSetting)) {
    return {
      numberOfEntities: numberOfCampaignsBudgetSetting,
      entityType: 'campaign',
      status: _get(adCampaignsBudgetSetting, 'campaignStatus', '')
    };
  }
  throw new Error('No entities found on the scheduled action');
};

const getScheduleActionPlanDescription = (
  scheduledAction: IScheduledAction | IScheduledActionSummary,
  isSummarized: boolean = false
): string => {
  const actionOptionSelected = actionOptions.find(
    (x) =>
      x.name ===
      (isSummarized
        ? (scheduledAction as IScheduledActionSummary).actionDescription
        : (scheduledAction as IScheduledAction).extendedAttributes.actionDescription)
  );
  if (actionOptionSelected) {
    if (actionOptionSelected.name === 'status') {
      if (isSummarized) {
        const entity = getAnyOneEntityFromSummarizedSA(scheduledAction as IScheduledActionSummary);
        const action = entity.status === 'paused' ? 'Pause' : 'Run';
        return `${action} campaigns`;
      }
      const anyOneEntity = getAnyOneEntity(scheduledAction as IScheduledAction);
      const method = anyOneEntity.extendedAttributes.status === 'paused' ? 'Pause' : 'Run';
      return `${method} campaigns`;
    }
    return actionOptionSelected.actionDescription;
  }
  return scheduledAction.scheduledActionHandlerType;
};

const getScheduleActionPeriodDisplayName = (
  scheduledAction: IScheduledAction | any,
  isSummarized: boolean = false
): string => {
  if (isSummarized) {
    if (periodOptions[scheduledAction.actionPeriodDescription]) {
      return periodOptions[scheduledAction.actionPeriodDescription as IPeriodOption].displayName;
    }
    return 'Recurring';
  }
  if (periodOptions[scheduledAction.extendedAttributes.actionPeriodDescription]) {
    return periodOptions[scheduledAction.extendedAttributes.actionPeriodDescription].displayName;
  }
  return 'Recurring';
};

const decodeCronSchedule = (scheduledActionsGroup: IScheduledActionsGroup): IScheduleDetail => {
  const cronForFirstAction = later.parse.cron(
    scheduledActionsGroup.scheduledActions[0].scheduledAction.cronSchedule,
    true
  );
  const scheduleForFirstAction = later.schedule(cronForFirstAction);
  const executionTimesForFirstAction = scheduleForFirstAction.next(2);

  const cronForSecondAction = later.parse.cron(
    scheduledActionsGroup.scheduledActions[1].scheduledAction.cronSchedule,
    true
  );
  const scheduleForSecondAction = later.schedule(cronForSecondAction);
  const executionTimesForSecondAction = scheduleForSecondAction.next(2);

  const actionType = scheduledActionsGroup.scheduledActions[0].scheduledAction.extendedAttributes.actionDescription;
  const periodType =
    scheduledActionsGroup.scheduledActions[0].scheduledAction.extendedAttributes.actionPeriodDescription;

  let prefillPeriod = {
    ...periodOptions[periodType],
    startTime: moment(executionTimesForFirstAction[0]).format('HH:mm'),
    endTime: moment(executionTimesForSecondAction[0]).format('HH:mm'),
    startDate: moment(scheduledActionsGroup.scheduledActions[0].scheduledAction.startTime),
    endDate: moment(scheduledActionsGroup.scheduledActions[0].scheduledAction.endTime)
  };

  let periodDisplay = 'from N/A';

  let weekDaysMapping = [false, false, false, false, false, false, false];

  if (periodType === 'hoursInDay') {
    weekDays.forEach((weekDay, idx) => {
      if (scheduledActionsGroup.scheduledActions[0].scheduledAction.cronSchedule.includes(weekDay)) {
        weekDaysMapping[idx] = true;
      }
    });
    if (!weekDaysMapping.some((item) => item === true)) {
      weekDaysMapping = [true, true, true, true, true, true, true];
    }
    prefillPeriod.weekDaysMapping = weekDaysMapping;
  }

  if (periodType === PeriodType.HOURS_IN_DAY) {
    periodDisplay = `from ${moment(executionTimesForFirstAction[0]).format('hh:mm A')} ${timeZoneString} to ${moment(
      executionTimesForSecondAction[0]
    ).format('hh:mm A')} ${timeZoneString}`;
  } else if (periodType === PeriodType.DAYS_OF_THE_WEEK) {
    const startDay = moment(executionTimesForFirstAction[0]).format('ddd').toUpperCase();
    const endDay = moment(executionTimesForSecondAction[0]).format('ddd').toUpperCase();
    periodDisplay = `from ${startDay} to ${endDay}`;
    prefillPeriod = {
      ...periodOptions[periodType],
      startTime: moment(executionTimesForFirstAction[0]).format('HH:mm'),
      endTime: moment(executionTimesForSecondAction[0]).format('HH:mm'),
      startDay,
      endDay,
      startDate: moment(scheduledActionsGroup.scheduledActions[0].scheduledAction.startTime),
      endDate: moment(scheduledActionsGroup.scheduledActions[0].scheduledAction.endTime)
    };
  }

  const expiredDay = moment(scheduledActionsGroup.scheduledActions[1].scheduledAction.endTime);
  const today = moment();
  const isExpired = today.isAfter(expiredDay);

  const plan = !isExpired
    ? `${getScheduleActionPlanDescription(scheduledActionsGroup.scheduledActions[0].scheduledAction)} ${periodDisplay}`
    : 'Expired';
  const anyOneEntity = getAnyOneEntity(scheduledActionsGroup.scheduledActions[0].scheduledAction);
  const { platformType } = anyOneEntity;
  const entityId =
    actionType === _get(anyOneEntity, ['extendedAttributes', 'entityId']) || _get(anyOneEntity, ['entityId']);

  const { actionMethod, actionPercent, actionAmount } =
    scheduledActionsGroup.scheduledActions[0].scheduledAction.extendedAttributes;
  let prefillMethod;

  if (actionMethod || actionPercent || actionAmount) {
    const { method, value } = getActionObject(actionMethod, actionPercent, actionAmount);
    prefillMethod = { method, value };
  } else {
    const { status } = anyOneEntity.extendedAttributes;
    prefillMethod = {
      method: status === 'paused' ? 'Pause' : 'Run',
      value: null
    };
  }

  const prefillAction = {
    ...actionOptions.find((action) => action.name === actionType),
    ...prefillMethod
  };

  let campaignIds = [];
  if (actionType === 'bid') {
    const campaigns = _get(
      scheduledActionsGroup,
      [
        'scheduledActions',
        0,
        'scheduledAction',
        'extendedAttributes',
        'adUpdateRequest',
        'extendedAttributes',
        'adCampaigns'
      ],
      []
    );
    campaignIds = campaigns.map((campaign) => campaign.campaignId);
  } else if (actionType === 'budgets') {
    const campaigns = _get(
      scheduledActionsGroup,
      [
        'scheduledActions',
        0,
        'scheduledAction',
        'extendedAttributes',
        'adUpdateRequest',
        'extendedAttributes',
        'adCampaignsBudgetSetting'
      ],
      []
    );
    campaignIds = campaigns.map((campaign) => campaign.campaignId);
  } else {
    const campaigns = _get(
      scheduledActionsGroup,
      [
        'scheduledActions',
        0,
        'scheduledAction',
        'extendedAttributes',
        'adUpdateRequest',
        'extendedAttributes',
        'adCampaigns'
      ],
      []
    );
    campaignIds = campaigns.map((campaign) => campaign.campaignId);
  }

  const prefillMax = _get(
    scheduledActionsGroup,
    ['scheduledActions', 0, 'scheduledAction', 'extendedAttributes', 'maxValueLimit'],
    null
  );
  const prefillMin = _get(
    scheduledActionsGroup,
    ['scheduledActions', 0, 'scheduledAction', 'extendedAttributes', 'minValueLimit'],
    null
  );

  return {
    action: getScheduleActionPlanDisplayName(scheduledActionsGroup.scheduledActions[0].scheduledAction),
    period: getScheduleActionPeriodDisplayName(scheduledActionsGroup.scheduledActions[0].scheduledAction),
    plan,
    campaignIds,
    startTime: `${moment(scheduledActionsGroup.scheduledActions[0].scheduledAction.startTime).format('MMM DD, YYYY')}`,
    endTime: `${moment(scheduledActionsGroup.scheduledActions[0].scheduledAction.endTime).format('MMM DD, YYYY')}`,
    nextStartDate: `${moment(executionTimesForFirstAction[0]).format('MMM DD, YYYY HH:mm A')} ${timeZoneString}`,
    nextEndDate: `${moment(executionTimesForSecondAction[0]).format('MMM DD, YYYY HH:mm A')} ${timeZoneString}`,
    prefill: {
      platformType,
      entityId,
      action: prefillAction,
      period: prefillPeriod,
      prefillMax,
      prefillMin
    }
  };
};

const decodeMetricCondition = (scheduledActionsGroup: IScheduledActionsGroup) => {
  const scheduledAction = _get(scheduledActionsGroup, ['scheduledActions', 0, 'scheduledAction']);
  const expiredDay = moment(scheduledAction.endTime);
  const today = moment();
  const isExpired = today.isAfter(expiredDay);

  const actionDesc = scheduledAction.extendedAttributes.actionDescription;
  const anyOneEntity = getAnyOneEntity(scheduledAction);
  const entityId =
    actionDesc === _get(anyOneEntity, ['extendedAttributes', 'entityId']) || _get(anyOneEntity, ['entityId']);
  const plan = !isExpired ? `${getScheduleActionPlanDescription(scheduledAction)}` : 'Expired';
  const periodType = _get(scheduledAction, 'extendedAttributes.actionPeriodDescription');

  let campaignIds = [];
  if (actionDesc === 'bid') {
    const campaigns = _get(
      scheduledActionsGroup,
      [
        'scheduledActions',
        0,
        'scheduledAction',
        'extendedAttributes',
        'adUpdateRequest',
        'extendedAttributes',
        'adCampaigns'
      ],
      []
    );
    campaignIds = campaigns.map((campaign) => campaign.campaignId);
  } else if (actionDesc === 'budgets') {
    const campaigns = _get(
      scheduledActionsGroup,
      [
        'scheduledActions',
        0,
        'scheduledAction',
        'extendedAttributes',
        'adUpdateRequest',
        'extendedAttributes',
        'adCampaignsBudgetSetting'
      ],
      []
    );
    campaignIds = campaigns.map((campaign) => campaign.campaignId);
  } else {
    const campaigns = _get(
      scheduledActionsGroup,
      [
        'scheduledActions',
        0,
        'scheduledAction',
        'extendedAttributes',
        'adUpdateRequest',
        'extendedAttributes',
        'adCampaigns'
      ],
      []
    );
    campaignIds = campaigns.map((campaign) => campaign.campaignId);
  }

  const { actionMethod, actionPercent, actionAmount } = scheduledAction.extendedAttributes;
  let prefillMethod;

  if (actionMethod || actionPercent || actionAmount) {
    const { method, value } = getActionObject(actionMethod, actionPercent, actionAmount);
    prefillMethod = { method, value };
  } else {
    const { status } = anyOneEntity.extendedAttributes;
    prefillMethod = {
      method: status === 'paused' ? 'Pause' : 'Run',
      value: null
    };
  }

  const prefillAction = {
    ...actionOptions.find((action) => action.name === actionDesc),
    ...prefillMethod
  };

  const prefillPeriod = {
    ...periodOptions[periodType],
    startDate: moment(scheduledAction.startTime),
    endDate: moment(scheduledAction.endTime)
  };
  let weekDaysMapping = [false, false, false, false, false, false, false];
  weekDays.forEach((weekDay, idx) => {
    if (scheduledActionsGroup.scheduledActions[0].scheduledAction.cronSchedule.includes(weekDay)) {
      weekDaysMapping[idx] = true;
    }
  });
  if (!weekDaysMapping.some((item) => item === true)) {
    weekDaysMapping = [true, true, true, true, true, true, true];
  }
  prefillPeriod.weekDaysMapping = weekDaysMapping;

  const { condition } = scheduledAction.extendedAttributes;
  const metricCondition = condition.campaign || condition.product || condition.target;
  const prefillRules = metricCondition.subterms.map((term) => ({
    days: term.numeratorDays,
    metric: term.metric,
    value: term.thresholdValue,
    direction: term.direction // "lt"
  }));

  const prefillMetric = {
    level: metricCondition.subterms[0].appliesToType,
    levelDisplayName: _capitalize(metricCondition.subterms[0].appliesToType),
    operator: metricCondition.op,
    startDate: moment(scheduledAction.startTime).startOf('day'),
    endDate: moment(scheduledAction.endTime).endOf('day'),
    rules: prefillRules
  };

  const prefillMax = _get(
    scheduledActionsGroup,
    ['scheduledActions', 0, 'scheduledAction', 'extendedAttributes', 'maxValueLimit'],
    null
  );
  const prefillMin = _get(
    scheduledActionsGroup,
    ['scheduledActions', 0, 'scheduledAction', 'extendedAttributes', 'minValueLimit'],
    null
  );

  return {
    action: getScheduleActionPlanDisplayName(scheduledActionsGroup.scheduledActions[0].scheduledAction),
    period: getScheduleActionPeriodDisplayName(scheduledActionsGroup.scheduledActions[0].scheduledAction),
    plan,
    campaignIds,
    startTime: `${moment(scheduledActionsGroup.scheduledActions[0].scheduledAction.startTime).format('MMM DD, YYYY')}`,
    endTime: `${moment(scheduledActionsGroup.scheduledActions[0].scheduledAction.endTime).format('MMM DD, YYYY')}`,
    prefill: {
      entityId,
      action: prefillAction,
      metric: prefillMetric,
      period: prefillPeriod,
      prefillMax,
      prefillMin
    }
  };
};

const decodeSummarizedSA = (scheduledActionsGroup: IScheduledActionsGroup) => {
  const scheduledAction: IScheduledActionSummary = _get(scheduledActionsGroup, [
    'scheduledActions',
    0,
    'scheduledAction'
  ]);
  let plan = '';
  const expiredDay = moment(scheduledAction.endTime);
  const today = moment();
  const isExpired = today.isAfter(expiredDay);

  if (isExpired) {
    plan = 'Expired';
  } else if (scheduledAction.actionType === 'metricBase') {
    plan = getScheduleActionPlanDescription(scheduledAction, true);
  } else {
    const cronForFirstAction = later.parse.cron(scheduledAction.primaryCronSchedule, true);
    const scheduleForFirstAction = later.schedule(cronForFirstAction);
    const executionTimesForFirstAction = scheduleForFirstAction.next(2);

    const cronForSecondAction = later.parse.cron(scheduledAction.secondaryCronSchedule, true);
    const scheduleForSecondAction = later.schedule(cronForSecondAction);
    const executionTimesForSecondAction = scheduleForSecondAction.next(2);

    const periodType = scheduledAction.actionPeriodDescription;
    let periodDisplay = '';

    if (periodType === PeriodType.HOURS_IN_DAY) {
      periodDisplay = `from ${moment(executionTimesForFirstAction[0]).format('hh:mm A')} ${timeZoneString} to ${moment(
        executionTimesForSecondAction[0]
      ).format('hh:mm A')} ${timeZoneString}`;
    } else if (periodType === PeriodType.DAYS_OF_THE_WEEK) {
      const startDay = moment(executionTimesForFirstAction[0]).format('ddd').toUpperCase();
      const endDay = moment(executionTimesForSecondAction[0]).format('ddd').toUpperCase();
      periodDisplay = `from ${startDay} to ${endDay}`;
    }
    plan = `${getScheduleActionPlanDescription(scheduledAction, true)} ${periodDisplay}`;
  }

  const entity = getAnyOneEntityFromSummarizedSA(scheduledAction);
  const appliesTo = `${entity.numberOfEntities} ${entity.entityType}${entity.numberOfEntities === 1 ? '' : 's'}`;

  return {
    action: getScheduleActionPlanDisplayName(scheduledAction, true),
    period: getScheduleActionPeriodDisplayName(scheduledAction, true),
    startTime: `${moment(scheduledActionsGroup.scheduledActions[0].scheduledAction.startTime).format('MMM DD, YYYY')}`,
    endTime: `${moment(scheduledActionsGroup.scheduledActions[0].scheduledAction.endTime).format('MMM DD, YYYY')}`,
    plan,
    appliesTo
  };
};

export const parseScheduledActionsIntoGroups = (scheduledActions: IScheduledAction[]): IScheduledActionsGroup[] => {
  const { retailer, adPlatforms } = store.getState();

  const currentPlatforms = adPlatforms.find(
    (platform: any) => `${platform.extendedAttributes.retailerId}` === `${retailer.id}`
  );

  const scheduledActionsGroupsByGroupId: { [key: string]: IScheduledActionsGroup } = {};
  scheduledActions.forEach((scheduledAction: IScheduledAction) => {
    if (
      currentPlatforms &&
      scheduledAction.extendedAttributes.status !== 'archived' &&
      scheduledAction.platformType === currentPlatforms.id
    ) {
      if (!scheduledActionsGroupsByGroupId[scheduledAction.scheduledActionGroupId]) {
        scheduledActionsGroupsByGroupId[scheduledAction.scheduledActionGroupId] = {
          id: scheduledAction.scheduledActionGroupId,
          name: scheduledAction.extendedAttributes.groupName,
          displayName: scheduledAction.extendedAttributes.groupName,
          shortDisplayName: scheduledAction.extendedAttributes.groupName,
          scheduledActions: [],
          scheduleDetail: null,
          type: 'scheduledActionsGroup'
        };
      }
      scheduledActionsGroupsByGroupId[scheduledAction.scheduledActionGroupId].scheduledActions.push({
        scheduledAction,
        id: scheduledAction.scheduledActionId,
        name: scheduledAction.extendedAttributes.name,
        displayName: scheduledAction.extendedAttributes.name
      });
    }
  });
  Object.values(scheduledActionsGroupsByGroupId).forEach((scheduledActionsGroup) => {
    scheduledActionsGroup.scheduledActions = scheduledActionsGroup.scheduledActions.sort(
      (a, b) => a.scheduledAction.extendedAttributes.sequenceId - b.scheduledAction.extendedAttributes.sequenceId
    );
    const currentActionType = _get(scheduledActionsGroup, [
      'scheduledActions',
      0,
      'scheduledAction',
      'extendedAttributes',
      'actionType'
    ]);
    if (currentActionType === 'metricBase') {
      scheduledActionsGroup.scheduleDetail = decodeMetricCondition(scheduledActionsGroup);
    } else {
      scheduledActionsGroup.scheduleDetail = decodeCronSchedule(scheduledActionsGroup);
    }
  });
  return Object.values(scheduledActionsGroupsByGroupId).sort(
    (a, b) =>
      moment(b.scheduledActions[0].scheduledAction.endTime) - moment(a.scheduledActions[0].scheduledAction.endTime)
  );
};

export const parseSummarizedScheduledActionsIntoGroups = (scheduledActions: IScheduledActionSummary[]) => {
  const { retailer, adPlatforms } = store.getState();
  scheduledActions = scheduledActions.sort((a, b) => -(moment(a.endTime) - moment(b.endTime)));

  const currentPlatforms = adPlatforms.find(
    (platform: any) => `${platform.extendedAttributes.retailerId}` === `${retailer.id}`
  );

  const scheduledActionsGroupsByGroupId: { [key: string]: IScheduledActionsGroup } = {};
  scheduledActions.forEach((scheduledAction) => {
    if (
      currentPlatforms &&
      scheduledAction.status !== 'archived' &&
      scheduledAction.platformType === currentPlatforms.id
    ) {
      if (!scheduledActionsGroupsByGroupId[scheduledAction.scheduledActionGroupId]) {
        scheduledActionsGroupsByGroupId[scheduledAction.scheduledActionGroupId] = {
          id: scheduledAction.scheduledActionGroupId,
          name: scheduledAction.groupName,
          displayName: scheduledAction.groupName,
          shortDisplayName: scheduledAction.groupName,
          scheduledActions: [],
          scheduleDetail: null,
          type: 'scheduledActionsGroup',
          isSummarized: true
        };
      }
      scheduledActionsGroupsByGroupId[scheduledAction.scheduledActionGroupId].scheduledActions.push({
        scheduledAction,
        id: scheduledAction.scheduledActionId,
        name: scheduledAction.name,
        displayName: scheduledAction.name
      });
    }
  });

  Object.values(scheduledActionsGroupsByGroupId).forEach((scheduledActionsGroup) => {
    scheduledActionsGroup.scheduleDetail = decodeSummarizedSA(scheduledActionsGroup);
  });

  return Object.values(scheduledActionsGroupsByGroupId);
};

export const deleteScheduleActions = async (originRequests: any[]) => {
  const DELETE_SCHEDULED_ACTION_API = `/apiAdManager/scheduledActions/deleteScheduledActionsByGroupId`;
  const { scheduledActionGroupId, platformType } = originRequests[0];
  return axios.post(DELETE_SCHEDULED_ACTION_API, {
    scheduledActionGroupId,
    platformType
  });
};

// gives a map from platform -> timezone
const getCronScheduleForMetricBasedSA = (period: any) => {
  const { adPlatforms, retailer } = store.getState();
  const { weekDaysMapping } = period;

  const currentRetailer = adPlatforms.find(
    (platform: { extendedAttributes: { retailerId: any } }) =>
      platform.extendedAttributes.retailerId.toString() === retailer.id.toString()
  );
  const currentTimeZone = currentRetailer.extendedAttributes.processInTimezone || 'America/Los_Angeles'; // default value
  // find 11:45 time
  const scheduledTime = moment().tz(currentTimeZone).set({ hour: 11, minute: 45 }).utc();
  let weekDayCron = '?';
  if (weekDaysMapping && weekDaysMapping.some((day) => !day)) {
    const weekDaysApply: string[] = [];
    weekDaysMapping.forEach((day, idx) => {
      if (day) {
        weekDaysApply.push(weekDays[idx]);
      }
    });
    weekDayCron = weekDaysApply.join();
  }
  return `0 ${scheduledTime.minute()} ${scheduledTime.hour()} * * ${weekDayCron} *`;
};

export const encodeMetricCondition = (metric: any) => {
  const { level, operator, rules } = metric;

  const result = {
    [level]: {
      type: 'expr',
      op: operator || 'AND',
      subterms: rules.map((rule: any) => {
        return {
          type: 'term',
          metric: rule.metric,
          direction: rule.direction,
          thresholdType: ['roas', 'acos'].includes(rule.metric) ? 'ratio' : 'value',
          thresholdValue: rule.value,
          appliesToType: level,
          numeratorDays: parseInt(rule.days, 10)
        };
      })
    }
  };
  return result;
};

export const editScheduleActions = async ({
  actionTitle,
  actionType,
  period,
  metric,
  selectedAction,
  selectedCampaigns,
  minValueLimit,
  maxValueLimit,
  originRequests
}: {
  actionTitle: any;
  actionType: string;
  period: any;
  metric: any;
  selectedAction: any;
  selectedCampaigns: any;
  minValueLimit: number | undefined;
  maxValueLimit: number | undefined;
  originRequests: any[];
}) => {
  const { updateType } = originRequests[0].extendedAttributes.adUpdateRequest;
  let newMetricConditions = null;

  const UPDATE_STATUS_SCHEDULED_ACTION_API = `/apiAdManager/scheduledActions/${
    updateType === 'bulkBudgetUpdate' ? 'updateBulkBudget' : updateType
  }`;

  let [startCronSchedule, endCronSchedule, startTime, endTime] = ['', '', '', ''];
  if (actionType === 'metricBase') {
    // Set new metric condition
    newMetricConditions = metric && encodeMetricCondition(metric);
    endTime = moment(metric.endDate).toISOString();
    startTime = moment(metric.startDate).toISOString();
    startCronSchedule = getCronScheduleForMetricBasedSA(period);
  } else {
    endTime = moment(period.endDate).toISOString();
    startTime = moment(period.startDate).toISOString();
    const res = encodeCronSchedule(period);
    startCronSchedule = res.startCron;
    endCronSchedule = res.endCron;
  }
  const handleEntityChange = (req: any, selectedEntities: any[]) => {
    let entityType = 'adCampaigns';
    const { adTargets, adCampaignProducts, adCampaigns, adCampaignsBudgetSetting } =
      req.extendedAttributes.adUpdateRequest.extendedAttributes;
    if (adTargets && adTargets.length > 0) {
      entityType = 'adTargets';
    } else if (adCampaignProducts && adCampaignProducts.length > 0) {
      entityType = 'adCampaignProducts';
    } else if (adCampaigns && adCampaigns.length > 0) {
      entityType = 'adCampaigns';
    } else if (adCampaignsBudgetSetting && adCampaignsBudgetSetting.length > 0) {
      entityType = 'adCampaignsBudgetSetting';
    }
    switch (entityType) {
      case 'adTargets': {
        const adTargetIds: string[] = [];
        selectedEntities.forEach((entity) => {
          const childDocuments = _get(entity, 'extendedAttributes.childDocuments', []) || [];
          childDocuments.forEach((doc: any) => {
            adTargetIds.push(doc.targetId);
          });
        });
        let cloneTargets = _cloneDeep(adTargets);
        cloneTargets = cloneTargets.filter((entity: any) => adTargetIds.includes(entity.targetId));
        req.extendedAttributes.adUpdateRequest.extendedAttributes.adTargets = cloneTargets;
        break;
      }
      case 'adCampaignProducts': {
        const skus: string[] = [];
        selectedEntities.forEach((entity) => {
          const childDocuments = _get(entity, 'extendedAttributes.childDocuments', []) || [];
          childDocuments.forEach((doc: any) => {
            skus.push(doc.adGroupId + doc.retailerSku);
          });
        });
        let cloneProducts = _cloneDeep(adCampaignProducts);
        cloneProducts = cloneProducts.filter((entity: any) => skus.includes(entity.adGroupId + entity.retailerSku));
        req.extendedAttributes.adUpdateRequest.extendedAttributes.adCampaignProducts = cloneProducts;
        break;
      }
      case 'adCampaigns':
      case 'adCampaignsBudgetSetting': {
        const campaignIds = selectedEntities.map((item) => item.campaignId);
        let cloneCampaigns = _cloneDeep(req.extendedAttributes.adUpdateRequest.extendedAttributes[entityType] || []);
        cloneCampaigns = cloneCampaigns.filter((entity: any) => campaignIds.includes(entity.campaignId));
        req.extendedAttributes.adUpdateRequest.extendedAttributes[entityType] = cloneCampaigns;
        break;
      }
      default:
        // all options
        break;
    }
  };
  originRequests[0].extendedAttributes.groupName = actionTitle;
  originRequests[0].cronSchedule = startCronSchedule;
  originRequests[0].endTime = endTime;
  originRequests[0].startTime = startTime;
  originRequests[0].extendedAttributes.minValueLimit = minValueLimit;
  originRequests[0].extendedAttributes.maxValueLimit = maxValueLimit;
  originRequests[0].extendedAttributes.condition = newMetricConditions;

  if (selectedAction && selectedAction.method.includes('ercent')) {
    // percent
    originRequests[0].extendedAttributes.actionPercent = selectedAction.value;
    originRequests[0].extendedAttributes.actionAmount = undefined;
  } else {
    // non persent
    originRequests[0].extendedAttributes.actionPercent = undefined;
    originRequests[0].extendedAttributes.actionAmount = selectedAction.value;
  }

  // Run or Pause
  const [primaryStatus, recoverStatus] =
    selectedAction.method === 'Pause'
      ? [AD_CAMPAIGN_STATUS.PAUSED, AD_CAMPAIGN_STATUS.ENABLED]
      : [AD_CAMPAIGN_STATUS.ENABLED, AD_CAMPAIGN_STATUS.PAUSED];
  originRequests[0].extendedAttributes.statusActionType = primaryStatus;

  handleEntityChange(originRequests[0], selectedCampaigns);

  if (originRequests[1]) {
    // if both presented need to remove one of them
    if (selectedAction && selectedAction.method.includes('ercent')) {
      originRequests[1].extendedAttributes.actionPercent = selectedAction.value;
      originRequests[1].extendedAttributes.actionAmount = undefined;
    } else {
      // non persent
      originRequests[1].extendedAttributes.actionPercent = undefined;
      originRequests[1].extendedAttributes.actionAmount = selectedAction.value;
    }
    originRequests[1].extendedAttributes.statusActionType = recoverStatus;
    originRequests[1].extendedAttributes.groupName = actionTitle;
    originRequests[1].cronSchedule = endCronSchedule;
    originRequests[1].endTime = endTime;
    originRequests[1].startTime = startTime;
    originRequests[1].extendedAttributes.minValueLimit = minValueLimit;
    originRequests[1].extendedAttributes.maxValueLimit = maxValueLimit;
    handleEntityChange(originRequests[1], selectedCampaigns);
  }

  return axios.post(UPDATE_STATUS_SCHEDULED_ACTION_API, originRequests);
};

export const saveScheduledActions = async (
  scheduleGroupName: string,
  actionType: string,
  minValueLimit: number,
  maxValueLimit: number,
  action: any,
  period: IPeriodOption,
  metric: any,
  campaigns = []
) => {
  let [startCronSchedule, endCronSchedule, condition] = ['', '', {}];
  if (actionType === 'metricBase') {
    condition = encodeMetricCondition(metric);
    startCronSchedule = getCronScheduleForMetricBasedSA(period);
  } else {
    const res = encodeCronSchedule(period);
    startCronSchedule = res.startCron;
    endCronSchedule = res.endCron;
  }

  const { beaconClientId, platformType, type } = campaigns[0];

  const propsForSchedule = {
    beaconClientId,
    platformType,
    entities: campaigns,
    entityType: type,
    startCronSchedule,
    endCronSchedule,
    scheduleGroupName,
    actionType,
    action,
    minValueLimit,
    maxValueLimit,
    period,
    metric,
    condition
  };

  const actionStrategies = {
    bid: async (props) => AdCampaignManagerServiceClient.updateTargetBidOnSchedule(props),
    budgets: async (props) => AdCampaignManagerServiceClient.updateBudgetOnSchedule(props),
    default: async () =>
      AdCampaignManagerServiceClient.pauseEntityOnSchedule({
        beaconClientId,
        platformType,
        entities: campaigns,
        entityType: type,
        startCronSchedule,
        endCronSchedule,
        scheduleGroupName,
        actionType,
        action,
        period,
        metric,
        condition
      })
  };

  const performAction = async (_actionName, _propsForSchedule) => {
    const doAction = actionStrategies[_actionName] || actionStrategies.default;
    return doAction(_propsForSchedule);
  };

  // Usage
  const res = await performAction(action.name, propsForSchedule);
  return res;
};
