import { Widget } from 'src/types/application/widgetTypes';
import SearchResultsGridV3, { SearchResultsGridV3Props } from './SearchResultsGridV3';
import React from 'react';
import useSrgData from './hooks/useSrgData';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import { Location } from 'history';
import EntityColumn from 'src/components/AdManager/Search/CustomColumns/EntityColumn';
import _upperFirst from 'lodash/upperFirst';
import { splitSnakeCase } from 'src/utils/stringFormatting';
import useFetchTargetsSalesData from './hooks/useFetchTargetsSalesData';
import useScheduledActionTermFilters from './hooks/useScheduledActionTermFilters';
import { useIsScheduledActionsPage } from 'src/utils/Hooks';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { TermFilter } from 'sl-api-connector';
import useSelectScheduledActionRows from './hooks/useSelectScheduledActionRows';
import { isEditScheduledActionPage } from 'src/components/AdManager/Search/searchUtils';
import { shouldFetchAllSRGRows, shouldShowCriteo } from 'src/utils/app';
import { getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';

interface TargetsGridProps {
  widget: Widget;
  location: Location;
}

const TargetsGridOuter: React.FC<TargetsGridProps> = (props) => {
  const isScheduledActionsPage = useIsScheduledActionsPage(props.location.search);

  return isScheduledActionsPage ? <ScheduledActionsTargetsGrid {...props} /> : <TargetsGrid {...props} />;
};

const ScheduledActionsTargetsGrid: React.FC<TargetsGridProps> = (props) => {
  const { loading, scheduledActionTermFilters, defaultSelectAll } = useScheduledActionTermFilters({
    location: props.location
  });
  const selectScheduledActionRows = useSelectScheduledActionRows({
    selectAll: defaultSelectAll,
    statePropertyName: props.widget.data.statePropertyName
  });

  if (loading) {
    return <GridLoading />;
  }

  return (
    <TargetsGrid
      {...props}
      togglable={false}
      allowSelectAll={!isEditScheduledActionPage(props.location.search)}
      additionalTermFilters={scheduledActionTermFilters}
      onFetchedData={selectScheduledActionRows}
    />
  );
};

/**
 * Display a list of Targets.
 */
const TargetsGrid: React.FC<
  TargetsGridProps &
    Partial<SearchResultsGridV3Props<any>> & {
      additionalTermFilters?: TermFilter[];
      onFetchedData?: ({ gridResult }) => void | Promise<void>;
    }
> = ({ widget, location, additionalTermFilters, onFetchedData, queryParams, ...otherProps }) => {
  let importantOverrides = [];
  const parentPlatform = getParentPlatform();
  const isCriteo = parentPlatform === PARENT_PLATFORMS.CRITEO;

  if (shouldShowCriteo()) {
    if (isCriteo) {
      // for all retailers remove rid
      if (queryParams.rid === '0') {
        importantOverrides = [
          ...importantOverrides,
          {
            action: 'remove',
            path: ['aggregations', '[0]', 'conditions', 'termFilters'],
            conditionKey: 'fieldName',
            conditionValue: 'retailerId'
          },
          {
            action: 'remove',
            path: ['conditions', 'termFilters'],
            conditionKey: 'fieldName',
            conditionValue: 'retailerId'
          },
          {
            action: 'update',
            path: ['retailerId'],
            newObj: 999999
          },
          {
            types: ['NOT_TOTAL_TARGETS'],
            action: 'concat',
            path: ['aggregations', '[0]', 'groupByFieldName'],
            newObj: ',pageType'
          }
        ];
      } else {
        importantOverrides = [
          {
            types: ['NOT_TOTAL_TARGETS'],
            action: 'concat',
            path: ['aggregations', '[0]', 'groupByFieldName'],
            newObj: ',pageType'
          }
        ];
      }
    }
  }

  const fetchTargetsSalesData = useFetchTargetsSalesData({ widget, importantOverrides });

  const { canEdit, ...rest } = useSrgData({
    widget,
    location,
    fetchEntityData: fetchTargetsSalesData,
    additionalTermFilters: additionalTermFilters || [],
    onFetchedData,
    metadataRowOptions: shouldFetchAllSRGRows(widget.data.entityType, widget.data.groupByFieldName)
      ? {
          getEntityId: (row) => `${row.targetingText}${row.targetingType}`,
          getRowId: (row) => row.targetingText,
          type: 'adTarget',
          name: 'targetingText'
        }
      : undefined,
    importantOverrides
  });

  let colDefs = [
    {
      headerName: 'Target',
      field: 'targetingText',
      width: undefined,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 300,
      maxWidth: 800,
      cellRendererFramework: EntityColumn,
      headerClass: 'align-left',
      pinnedRowCellRenderer: 'simpleColumn',
      pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
      pinned: 'left'
    },
    {
      headerName: 'Match',
      field: 'entity',
      width: undefined,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      minWidth: 100,
      maxWidth: 100,
      valueFormatter: ({ value }) => _upperFirst(splitSnakeCase(((value && value.targetingType) || '').toLowerCase())),
      headerClass: 'align-left',
      pinnedRowCellRenderer: 'emptyColumn',
      pinned: 'left'
    }
  ];

  if (shouldShowCriteo()) {
    colDefs = [
      {
        headerName: 'Target',
        field: 'targetingText',
        width: undefined,
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        minWidth: 300,
        maxWidth: 800,
        cellRendererFramework: EntityColumn,
        headerClass: 'align-left',
        pinnedRowCellRenderer: 'simpleColumn',
        pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
        pinned: 'left'
      },
      // Parent platforms doesn't have match type
      parentPlatform !== PARENT_PLATFORMS.CRITEO && {
        headerName: 'Match',
        field: 'entity',
        width: undefined,
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        minWidth: 100,
        maxWidth: 100,
        valueFormatter: ({ value }) =>
          _upperFirst(splitSnakeCase(((value && value.targetingType) || '').toLowerCase())),
        headerClass: 'align-left',
        pinnedRowCellRenderer: 'emptyColumn',
        pinned: 'left'
      },
      parentPlatform === PARENT_PLATFORMS.CRITEO && {
        headerName: 'Page Type',
        field: 'entity',
        width: undefined,
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        minWidth: 100,
        maxWidth: 100,
        valueFormatter: ({ value }) => {
          if (value && value.pageType && value.pageType === 'productdetail') {
            return 'Product detail';
          }
          return _upperFirst(splitSnakeCase(((value && value.pageType) || '').toLowerCase()));
        },
        headerClass: 'align-left',
        pinnedRowCellRenderer: 'emptyColumn',
        pinned: 'left'
      }
    ];
  }

  return (
    <SearchResultsGridV3
      editDisabled={isCriteo}
      selectable={canEdit && !isCriteo}
      togglable={canEdit && !window.location.pathname.includes('product') && !isCriteo}
      columnDefs={colDefs}
      getRowNodeId={(row) => row.id}
      widget={widget}
      {...rest}
      {...otherProps}
    />
  );
};

export default withRouter(withBus('eventBus')(TargetsGridOuter));
