import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { filterOperations } from '../../../store/modules/filters';
import { stripNonNumberCharacters } from '../../../utils/money';
import fontStyle from 'src/utils/fontStyle';
import Input from '@mui/material/Input';

// We may need to give MaskedInput Component a ref.
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { ...other } = props;

  return (
    <MaskedInput
      mask={other.numberMask}
      type="text"
      name={other.name}
      className="range_selector_input"
      value={other.value}
      id={other.id}
      onChange={other.onChange}
    />
  );
});

class RetailPriceFilter extends React.Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    updateFilters: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    const { retailPrice: retailPriceFilter } = props.filters;
    let retailPriceMin = 0;
    let retailPriceMax = 1000000;
    if (retailPriceFilter !== null) {
      retailPriceMax = retailPriceFilter.maxValue;
      retailPriceMin = retailPriceFilter.minValue;
    }
    this.state = {
      retailPriceMin,
      retailPriceMax
    };
  }

  componentWillMount() {
    this.debounce = null;

    let numberMask = createNumberMask({
      prefix: this.props.retailer.currencySymbol,
      allowDecimal: true
    });

    // Overwrite numberMask for 'RTL' Language Retailers.
    // If the input is right to left, we need to leave prefix empty to make our filter work

    if (this.props.retailer.isRTLLanguage) {
      numberMask = createNumberMask({
        prefix: '',
        allowDecimal: true
      });
    }

    this.setState({ numberMask });
  }

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps;

    if (filters.isClearingFilters) {
      this.setState({
        retailPriceMin: 0,
        retailPriceMax: 1000000
      });
    }
  }

  handleMinInputChange = (event) => {
    const { value, name } = event.target;

    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.handleInputChange({ value, name }, { minValue: stripNonNumberCharacters(value) });
    }, 500);
  };

  handleMaxInputChange = (event) => {
    const { value, name } = event.target;
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.handleInputChange({ value, name }, { maxValue: stripNonNumberCharacters(value) });
    }, 500);
  };

  handleInputChange = (target, obj) => {
    const { onFilterChange } = this.props;
    const { retailPriceMax, retailPriceMin } = this.state;
    if (target.value === '') {
      return;
    }

    const callbackValues = {
      minValue: stripNonNumberCharacters(retailPriceMin),
      maxValue: stripNonNumberCharacters(retailPriceMax),
      ...obj
    };

    if (callbackValues.minValue > callbackValues.maxValue) {
      this.setState({
        retailPriceMin,
        retailPriceMax
      });
      return;
    }
    this.setState({
      [target.name]: target.value
    });
    onFilterChange(callbackValues, 'retailPrice');
  };

  render() {
    const { numberMask, retailPriceMin, retailPriceMax } = this.state;

    if (!numberMask) {
      return null;
    }
    return (
      <div className="search-form-container search-form-container--lg">
        <h4 className="sl-form-label">Price Range</h4>
        <div style={{ display: 'flex', marginTop: '10px' }}>
          <label
            className="sl-form-label"
            htmlFor="retailPriceMin"
            style={{ alignSelf: 'center', marginRight: '20px', fontWeight: fontStyle.regularWeight }}
          >
            Min
          </label>
          <div style={{ flex: '1 1' }}>
            <Input
              value={retailPriceMin}
              className="sl-form-input"
              variant="standard"
              fullWidth
              name="retailPriceMin"
              id="retailPriceMin"
              inputComponent={TextMaskCustom}
              inputProps={{
                numberMask,
                name: 'retailPriceMin',
                id: 'retailPriceMin',
                value: retailPriceMin,
                onChange: this.handleMinInputChange
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', marginTop: '10px' }}>
          <label
            className="sl-form-label"
            htmlFor="retailPriceMax"
            style={{ alignSelf: 'center', marginRight: '20px', fontWeight: fontStyle.regularWeight }}
          >
            Max
          </label>
          <div style={{ flex: '1 1' }}>
            <Input
              value={retailPriceMax}
              className="sl-form-input"
              variant="standard"
              fullWidth
              name="retailPriceMax"
              id="retailPriceMax"
              inputComponent={TextMaskCustom}
              inputProps={{
                numberMask,
                name: 'retailPriceMax',
                id: 'retailPriceMax',
                value: retailPriceMax,
                onChange: this.handleMaxInputChange
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { retailer, filters } = state;
  return {
    retailer,
    filters
  };
};

const mapDispatchToProps = {
  updateFilters: filterOperations.updateFilters
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RetailPriceFilter));
