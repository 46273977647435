import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Select from '@mui/material/Select';
import { ChevronIcon } from 'src/components/SvgIcons';
import MenuItem from '@mui/material/MenuItem';

interface OmniDropDownOption {
  tabOptions: { name: string; displayName: string }[];
  style: React.CSSProperties;
  onOptionChange: (newValue: string) => void;
  currentValue: string;
}

const useStyles = makeStyles({
  text: {
    fontSize: '24px',
    fontFamily: 'Roboto'
  }
});

const OmniDropDownOption: React.FC<OmniDropDownOption> = ({
  tabOptions,
  style,
  onOptionChange,
  currentValue
}: OmniDropDownOption) => {
  const classes = useStyles();
  const handleDropDownChange = (e: React.ChangeEvent<{ name?: string | undefined; value: any }>) => {
    const newValue = e.target.value;
    onOptionChange(newValue);
  };
  return (
    <div style={style}>
      <Select
        variant="standard"
        value={currentValue}
        disableUnderline
        disabled={tabOptions.length === 1}
        onChange={(e) => handleDropDownChange(e)}
        classes={{ select: classes.text }}
        IconComponent={() =>
          tabOptions.length === 1 ? (
            <></>
          ) : (
            <ChevronIcon className="sl-header__drop-down-icon" style={{ transform: 'translate(-10px,-3px)' }} />
          )
        }
        sx={{
          '& .MuiInputBase-input.MuiInput-input': {
            fontSize: '24px',
            display: 'flex',
            alignItems: 'center'
          },
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: '#000000'
          }
        }}
      >
        {tabOptions.map((tabOption) => (
          <MenuItem
            key={tabOption.name}
            value={tabOption.name}
            sx={{
              fontFamily: 'Roboto',
              fontSize: '18px'
            }}
          >
            {tabOption.displayName}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default OmniDropDownOption;
