import { AppName } from 'sl-api-connector';

import { error } from 'src/utils/mixpanel';

enum TabType {
  Sales,
  Traffic,
  Other,
  AdTraffic,
  Content,
  Sellers,
  Compliance,
  KeywordCompliance,
  SellerCompliance,
  Forecasts
}

const getGroupByFieldNames = (appName: AppName, entityType: string, tabType: TabType): string[] => {
  if (appName === AppName.Beacon) {
    switch (entityType) {
      case 'client':
      case 'company': {
        return (
          {
            [TabType.Sales]: ['stacklineSku', 'brandId', 'categoryId', 'subCategoryId', 'brandId,subCategoryId'],
            [TabType.Sellers]: [
              'stacklineSku,merchantId',
              'brandId,merchantId',
              'categoryId,merchantId',
              'subCategoryId,merchantId',
              'brandId,subCategoryId,merchantId'
            ],
            [TabType.Traffic]: [
              'stacklineSku',
              'brandId',
              'categoryId',
              'subCategoryId',
              'brandId,subCategoryId',
              'searchTerm',
              'brandId,searchTerm',
              'stacklineSku,searchTerm'
            ],
            [TabType.Other]: ['stacklineSku'],
            [TabType.AdTraffic]: [
              'stacklineSku',
              'brandId',
              'campaignType',
              'campaignId',
              'categoryId',
              'subCategoryId',
              'brandId,subCategoryId',
              'searchKeyword',
              'brandId,searchKeyword',
              'campaignId,searchKeyword',
              'stacklineSku,searchKeyword',
              'stacklineSku,campaignId,searchKeyword'
            ],
            [TabType.Content]: ['stacklineSku'],
            [TabType.Compliance]: ['stacklineSku', 'brandId'],
            [TabType.KeywordCompliance]: ['KeywordText', 'brandId'],
            [TabType.SellerCompliance]: ['stacklineSku', 'merchantName'],
            [TabType.Forecasts]: ['stacklineSku', 'categoryId', 'subCategoryId', 'brandId']
          } as { [K in TabType]: string[] }
        )[tabType];
      }
      case 'category': {
        return (
          {
            [TabType.Sales]: ['stacklineSku', 'brandId', 'subCategoryId', 'brandId,subCategoryId'],
            [TabType.Sellers]: [
              'stacklineSku,merchantId',
              'brandId,merchantId',
              'subCategoryId,merchantId',
              'brandId,subCategoryId,merchantId'
            ],
            [TabType.Traffic]: [
              'stacklineSku',
              'brandId',
              'subCategoryId',
              'brandId,subCategoryId',
              'searchTerm',
              'brandId,searchTerm',
              'stacklineSku,searchTerm'
            ],
            [TabType.Other]: ['stacklineSku'],
            [TabType.AdTraffic]: [
              'stacklineSku',
              'brandId',
              'campaignType',
              'campaignId',
              'subCategoryId',
              'brandId,subCategoryId',
              'searchKeyword',
              'brandId,searchKeyword',
              'campaignId,searchKeyword',
              'stacklineSku,searchKeyword',
              'stacklineSku,campaignId,searchKeyword'
            ],
            [TabType.Content]: ['stacklineSku'],
            [TabType.Forecasts]: ['stacklineSku', 'subCategoryId', 'brandId']
          } as { [K in TabType]: string[] }
        )[tabType];
      }
      case 'subcategory': {
        return (
          {
            [TabType.Sales]: ['stacklineSku', 'brandId'],
            [TabType.Sellers]: ['stacklineSku,merchantId', 'brandId,merchantId'],
            [TabType.Traffic]: [
              'stacklineSku',
              'brandId',
              'searchTerm',
              'brandId,searchTerm',
              'stacklineSku,searchTerm'
            ],
            [TabType.Other]: ['stacklineSku'],
            [TabType.AdTraffic]: [
              'stacklineSku',
              'brandId',
              'campaignType',
              'campaignId',
              'searchKeyword',
              'brandId,searchKeyword',
              'campaignId,searchKeyword',
              'stacklineSku,searchKeyword',
              'stacklineSku,campaignId,searchKeyword'
            ],
            [TabType.Forecasts]: ['stacklineSku', 'brandId'],
            [TabType.Content]: ['stacklineSku', 'brandId']
          } as { [K in TabType]: string[] }
        )[tabType];
      }
      case 'brand': {
        return (
          {
            [TabType.Sales]: ['stacklineSku', 'categoryId', 'subCategoryId'],
            [TabType.Sellers]: ['stacklineSku,merchantId', 'categoryId,merchantId', 'subCategoryId,merchantId'],
            [TabType.Traffic]: ['stacklineSku', 'categoryId', 'subCategoryId', 'searchTerm', 'stacklineSku,searchTerm'],
            [TabType.Other]: ['stacklineSku'],
            [TabType.AdTraffic]: [
              'stacklineSku',
              'categoryId',
              'subCategoryId',
              'campaignType',
              'campaignId',
              'searchKeyword',
              'campaignId,searchKeyword',
              'stacklineSku,searchKeyword',
              'stacklineSku,campaignId,searchKeyword'
            ],
            [TabType.Forecasts]: ['stacklineSku', 'categoryId', 'subCategoryId'],
            [TabType.Content]: ['stacklineSku']
          } as { [K in TabType]: string[] }
        )[tabType];
      }
      case 'product': {
        return (
          {
            [TabType.Sales]: ['stacklineSku'],
            [TabType.Sellers]: ['stacklineSku,merchantId'],
            [TabType.Traffic]: ['stacklineSku', 'searchTerm'],
            [TabType.Other]: ['stacklineSku'],
            [TabType.AdTraffic]: [
              'stacklineSku',
              'campaignType',
              'campaignId',
              'searchKeyword',
              'campaignId,searchKeyword',
              'stacklineSku,campaignId,searchKeyword'
            ],
            [TabType.Forecasts]: ['stacklineSku'],
            [TabType.Content]: ['stacklineSku']
          } as { [K in TabType]: string[] }
        )[tabType];
      }
      case 'segment':
      case 'businessunit':
      case 'businessUnit':
      case 'searchtermlist': {
        return (
          {
            [TabType.Sales]: ['stacklineSku', 'brandId', 'categoryId', 'subCategoryId', 'brandId,subCategoryId'],
            [TabType.Sellers]: [
              'stacklineSku,merchantId',
              'brandId,merchantId',
              'subCategoryId,merchantId',
              'brandId,subCategoryId,merchantId'
            ],
            [TabType.Traffic]: [
              'stacklineSku',
              'brandId',
              'categoryId',
              'subCategoryId',
              'brandId,subCategoryId',
              'searchTerm',
              'brandId,searchTerm',
              'campaignId,searchKeyword',
              'stacklineSku,searchTerm'
            ],
            [TabType.Other]: ['stacklineSku'],
            [TabType.AdTraffic]: [
              'stacklineSku',
              'brandId',
              'categoryId',
              'subCategoryId',
              'campaignType',
              'campaignId',
              'brandId,subCategoryId',
              'searchKeyword',
              'brandId,searchKeyword',
              'campaignId,searchKeyword',
              'stacklineSku,searchKeyword'
            ],
            [TabType.Forecasts]: ['stacklineSku', 'categoryId', 'subCategoryId', 'brandId'],
            [TabType.Content]: ['stacklineSku']
          } as { [K in TabType]: string[] }
        )[tabType];
      }
      default: {
        error(`Unhandled entity type of \`${entityType}\` when looking up export group-by fields`);
        return ['stacklineSku'];
      }
    }
  } else if (appName === AppName.Atlas) {
    switch (entityType) {
      case 'client':
      case 'company': {
        return (
          {
            [TabType.Sales]: ['stacklineSku', 'brandId', 'categoryId', 'subCategoryId', 'brandId,subCategoryId'],
            [TabType.Traffic]: [
              'stacklineSku',
              'brandId',
              'categoryId',
              'subCategoryId',
              'brandId,subCategoryId',
              'searchTerm',
              'searchTerm,brandId',
              'stacklineSku,searchTerm',
              'searchTerm,subCategoryId'
            ],
            [TabType.Other]: ['stacklineSku'],
            [TabType.AdTraffic]: [],
            [TabType.Content]: ['stacklineSku'],
            [TabType.Sellers]: []
          } as { [K in TabType]: string[] }
        )[tabType];
      }
      case 'category': {
        return (
          {
            [TabType.Sales]: ['stacklineSku', 'brandId', 'subCategoryId', 'brandId,subCategoryId'],
            [TabType.Traffic]: [
              'stacklineSku',
              'brandId',
              'subCategoryId',
              'brandId,subCategoryId',
              'searchTerm',
              'searchTerm,brandId',
              'stacklineSku,searchTerm',
              'searchTerm,subCategoryId'
            ],
            [TabType.Other]: ['stacklineSku'],
            [TabType.AdTraffic]: [],
            [TabType.Content]: ['stacklineSku'],
            [TabType.Sellers]: []
          } as { [K in TabType]: string[] }
        )[tabType];
      }
      case 'subcategory': {
        return (
          {
            [TabType.Sales]: ['stacklineSku', 'brandId'],
            [TabType.Traffic]: [
              'stacklineSku',
              'brandId',
              'searchTerm',
              'searchTerm,brandId',
              'stacklineSku,searchTerm'
            ],
            [TabType.Other]: ['stacklineSku'],
            [TabType.AdTraffic]: [],
            [TabType.Content]: ['stacklineSku'],
            [TabType.Sellers]: []
          } as { [K in TabType]: string[] }
        )[tabType];
      }
      case 'brand': {
        return (
          {
            [TabType.Sales]: ['stacklineSku', 'categoryId', 'subCategoryId'],
            [TabType.Traffic]: ['stacklineSku', 'categoryId', 'subCategoryId', 'searchTerm', 'stacklineSku,searchTerm'],
            [TabType.Other]: ['stacklineSku'],
            [TabType.AdTraffic]: [],
            [TabType.Content]: ['stacklineSku'],
            [TabType.Sellers]: []
          } as { [K in TabType]: string[] }
        )[tabType];
      }
      case 'product': {
        return (
          {
            [TabType.Sales]: ['stacklineSku'],
            [TabType.Traffic]: ['stacklineSku', 'searchTerm'],
            [TabType.Other]: ['stacklineSku'],
            [TabType.AdTraffic]: [],
            [TabType.Content]: ['stacklineSku'],
            [TabType.Sellers]: []
          } as { [K in TabType]: string[] }
        )[tabType];
      }
      case 'segment':
      case 'businessunit':
      case 'businessUnit':
      case 'searchtermlist': {
        return (
          {
            [TabType.Sales]: ['stacklineSku', 'brandId', 'brandId,subCategoryId', 'categoryId', 'subCategoryId'],
            [TabType.Traffic]: [
              'stacklineSku',
              'brandId',
              'categoryId',
              'subCategoryId',
              'brandId,subCategoryId',
              'searchTerm',
              'searchTerm,brandId',
              'stacklineSku,searchTerm',
              'searchTerm,subCategoryId'
            ],
            [TabType.Other]: ['stacklineSku'],
            [TabType.AdTraffic]: [],
            [TabType.Content]: ['stacklineSku'],
            [TabType.Sellers]: []
          } as { [K in TabType]: string[] }
        )[tabType];
      }
      default: {
        error(`Unhandled entity type of \`${entityType}\` when looking up export group-by fields`);
        return ['stacklineSku'];
      }
    }
  }
  return ['stacklineSku'];
};

const tabTypeMappingByAppName: { [key: string]: Map<string, TabType> } = {
  [AppName.Atlas]: new Map(
    Object.entries({
      'traffic-purchaseRate': TabType.Sales,
      'sales-purchaseRate': TabType.Sales, //
      'sales-retailPrice': TabType.Sales,
      'sales-rating': TabType.Sales,
      'sales-retailSales': TabType.Sales,
      'sales-unitsSold': TabType.Sales,
      'traffic-totalClicks': TabType.Traffic,
      'traffic-organicClicks': TabType.Traffic,
      'traffic-adClicks': TabType.Traffic,
      'promotions-retailSales': TabType.Other,
      'sales-contentScore': TabType.Content,
      'scorecard-all': TabType.Sales
    })
  ),
  [AppName.Beacon]: new Map(
    Object.entries({
      'conversion-purchaseRate': TabType.Sales,
      'sales-retailPrice': TabType.Sales,
      'sales-mapPrice': TabType.Sales,
      'sales-inventory': TabType.Sales,
      'buybox-winPercentage': TabType.Sales,
      'buybox-sellers': TabType.Sellers,
      'sales-retailSales': TabType.Sales,
      'sales-unitsSold': TabType.Sales,
      'sales-retailerGrossMargin': TabType.Sales,
      'sales-returnRate': TabType.Sales,
      'compliance-keymetrics': TabType.Compliance,
      'compliance-keywordCompliance': TabType.KeywordCompliance,
      'compliance-sellerCompliance': TabType.SellerCompliance,
      'traffic-totalClicks': TabType.Traffic,
      'traffic-organicClicks': TabType.Traffic,
      'content-contentScore': TabType.Content,
      'content-contentAccuracy': TabType.Other,
      'reviews-reviewTrends': TabType.Other,
      'reviews-highRiskReviews': TabType.Other,
      'traffic-adClicks': TabType.AdTraffic,
      'sales-keymetrics': TabType.Sales,
      'operations-chargebacks': TabType.Sales,
      'forecasts-forecastSummary': TabType.Forecasts
    })
  )
};

const getTabType = (appName: AppName, tab: string, subtab: string): TabType | null => {
  const mapping = tabTypeMappingByAppName[appName];

  if (!mapping) {
    return null;
  }
  const tabType = mapping.get(`${tab}-${subtab}`);
  if (tabType !== undefined) {
    return tabType;
  }
  return null;
};

export const getExportGroupByFieldNames = ({
  appName,
  entityType,
  tab,
  subtab
}: {
  appName: AppName;
  entityType: string;
  tab: string;
  subtab: string;
}): { groupByFieldNames: string[]; isAggregatable: boolean } => {
  const tabType = getTabType(appName, tab, subtab);
  if (tabType === null) {
    // error(`No group-by field mapping entries found for tab: "${tab}", subtab: "${subtab}"`);
    return { groupByFieldNames: ['stacklineSku'], isAggregatable: false };
  }

  return {
    groupByFieldNames: getGroupByFieldNames(appName, entityType, tabType),
    isAggregatable: tabType !== TabType.Other
  };
};

export const displayNameByFieldName = new Map(
  Object.entries({
    brandId: 'Brand',
    categoryId: 'Category',
    subCategoryId: 'Subcategory',
    stacklineSku: 'Product',
    searchTerm: 'Keyword',
    searchKeyword: 'Keyword',
    campaignId: 'Campaign',
    campaignType: 'Campaign Type',
    company: 'Company',
    client: 'Client',
    'searchTerm,brandId': 'Brand & Keyword',
    'brandId,searchKeyword': 'Brand & Keyword',
    'campaignId,searchKeyword': 'Campaign & Keyword',
    'stacklineSku,searchTerm': 'Product & Keyword',
    'stacklineSku,merchantId': 'Product & Seller',
    'brandId,merchantId': 'Brand & Seller',
    'categoryId,merchantId': 'Category & Seller',
    'subCategoryId,merchantId': 'Subcategory & Seller',
    'brandId,subCategoryId,merchantId': 'Brand & Subcategory & Seller', // do we support triple?
    'stacklineSku,searchKeyword': 'Product & Keyword',
    'brandId,subCategoryId': 'Brand & Subcategory',
    'stacklineSku,campaignId,searchKeyword': 'Campaign & Product & Keyword',
    'searchTerm,subCategoryId': 'Subcategory & Keyword',
    merchantName: 'Seller Name',
    KeywordText: 'Keyword'
  })
);

const BaseDoubleAggregatableFieldNames: Set<string | null> = new Set([
  'brandId',
  'categoryId',
  'subCategoryId',
  'stacklineSku',
  'stacklineSku,merchantId',
  'searchTerm',
  'campaignId',
  'campaignType'
]);

export const getDoubleAggregatableFieldNames = (appName: AppName): Set<string | null> => {
  const doubleAggregatableFieldNames = new Set(BaseDoubleAggregatableFieldNames);

  if (appName === AppName.Atlas || appName === AppName.Beacon) {
    doubleAggregatableFieldNames.add('brandId,subCategoryId');
    // doubleAggregatableFieldNames.add('campaignId,stacklineSku,searchKeyword');
  }

  return doubleAggregatableFieldNames;
};

const NonCompTimePeriodCombos = new Set([
  'atlas-promotions-retailSales',
  'beacon-content-contentAccuracy',
  'beacon-reviews-reviewTrends',
  'beacon-reviews-highRiskReviews'
  // 'beacon-buybox-sellers'
]);

export const getCompTimePeriodEnabled = ({ appName, tab, subtab }: { appName: AppName; tab: string; subtab: string }) =>
  !NonCompTimePeriodCombos.has(`${appName}-${tab}-${subtab}`);
