import React, { useEffect, useState } from 'react';
import { AppName } from 'sl-api-connector';
import { EventBus } from 'src/types/utils';
import { getEmptyFormData } from 'src/utils/segments';
import { AdManagerEvents } from 'src/types/application/event';
import { withBus } from 'react-bus';

interface FormDataProviderProps {
  children: React.ReactNode;
  eventBus: EventBus;
}

export const FormDataContext = React.createContext(null);

/** Provides the form data across tabbed tables */
export const FormDataProviderInner = ({ children, eventBus }: FormDataProviderProps) => {
  const [formData, setFormData] = useState(getEmptyFormData({ appName: AppName.Advertising }));

  useEffect(() => {
    const sideBarFilterUpdated = ({ formData: newFormData }) => {
      setFormData(newFormData);
    };

    eventBus.on(AdManagerEvents.ENTITY_EVENT_METRICS_GRID_FILTER_UPDATED, sideBarFilterUpdated);

    return () => {
      eventBus.off(AdManagerEvents.ENTITY_EVENT_METRICS_GRID_FILTER_UPDATED, sideBarFilterUpdated);
    };
  }, [eventBus]);

  return <FormDataContext.Provider value={formData}>{children}</FormDataContext.Provider>;
};

export default withBus('eventBus')(FormDataProviderInner);
