import React from 'react';
import _get from 'lodash/get';
import { buildMetricValue } from 'src/utils/metrics';
import ArrowChange from 'src/components/common/ArrowChange/ArrowChange';

const DataColumn = ({ value, ...props }: { value: any }) => {
  const conputeChange = () => {
    if (!value) {
      return {
        mainValueDisplay: '--',
        renderChange: function renderChange() {
          return <div>--</div>;
        }
      };
    }
    const { metricData } = value.cardView;
    const { name: fieldName, metricType, currencySymbol, dataType, locale } = metricData;
    const mainValue = _get(value, ['cardView', `${fieldName}CurrentValue`]);
    // const comparisonValue = _get(value, ['cardView', `${fieldName}PreviousValue`], 0);
    const percentChange = _get(value, ['cardView', `${fieldName}PercentChange`]);
    const previousValue = _get(value, ['cardView', `${fieldName}PreviousValue`]);

    const mainValueDisplayObj = buildMetricValue(mainValue, metricType, currencySymbol, false, dataType, locale);

    const mainValueDisplay = `${mainValueDisplayObj.prefix || ''}${mainValueDisplayObj.value}${
      mainValueDisplayObj.suffix || ''
    }`;

    if (mainValue === undefined || previousValue === undefined) {
      return {
        mainValueDisplay,
        renderChange: function renderChange() {
          return <div>--</div>;
        }
      };
    }
    return {
      mainValueDisplay,
      renderChange: function renderChange() {
        return <ArrowChange metric={metricData} value={percentChange} width={13} />;
      }
    };
  };
  const { mainValueDisplay, renderChange } = conputeChange();
  const style = _get(props, ['data', 'id']) === 'total_row' ? { fontWeight: 500 } : {};
  return (
    <div style={style}>
      <div style={{ fontWeight: 'inherit' }}>{mainValueDisplay}</div>
      <div style={{ fontWeight: 'inherit' }}>{renderChange()}</div>
    </div>
  );
};

export default DataColumn;
