import React from 'react';
import PropTypes from 'prop-types';

const SimpleColumn = ({ text, style }: { text: string; style: any }) => {
  return <div style={style}>{text}</div>;
};

SimpleColumn.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object
};

SimpleColumn.defaultProps = {
  style: {}
};

export default SimpleColumn;
