import React from 'react';
import { Text, useStacklineTheme, StacklineColor, hexToRgba } from '@stackline/ui';

export interface StatusPillProps {
  children: string;
  color: StacklineColor;
  backgroundColor: StacklineColor;
  // eslint-disable-next-line react/require-default-props
  opacity?: number;
}

/**
 * A pill for use in the table status.
 * This pill will be placed on the table status
 */
const StatusPill = ({ children, color, backgroundColor, opacity = 0.2 }: StatusPillProps) => {
  const theme = useStacklineTheme();

  return (
    <div
      style={{
        backgroundColor: hexToRgba(theme.colors[backgroundColor], opacity),
        borderRadius: '12.5px',
        padding: '3px 24px',
        display: 'inline-flex',
        height: '25px',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Text variant="subtitle2" color={color}>
        {children}
      </Text>
    </div>
  );
};

export default StatusPill;
