import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import CircularProgressWithLabel from 'src/components/common/UploadAnimation/CircularProgressWithLabel';
import { useStacklineTheme } from '@stackline/ui';
import { CircularProgressProps } from '@mui/material';
import { SxProps } from '@mui/material/styles';

interface BulkTemplateProcessingInnerProps {
  percentage: number;
  circleLabel: string;
  descriptionTitle: string;
  descriptionBody: React.ReactNode;
  circleProps?: CircularProgressProps;
  circleSx?: SxProps;
  sx?: SxProps;
}

/**
 *
 * This component is used for the modal that appears after a user uploads a bulk template.
 */
const BulkTemplateLoadingInner = ({
  circleLabel,
  descriptionTitle,
  descriptionBody,
  circleProps,
  circleSx = {},
  percentage,
  sx = {}
}: BulkTemplateProcessingInnerProps) => {
  const theme = useStacklineTheme();

  return (
    <Flex padding="72.5px 135px 71.5px 120px" flexDirection="column" alignItems="center" sx={sx}>
      <Flex>
        <CircularProgressWithLabel
          percentage={percentage}
          label={circleLabel}
          circleProps={circleProps}
          circleSx={circleSx}
        />
      </Flex>

      <Flex marginTop={theme.spacing.lg}>
        <Text variant="h3">{descriptionTitle}</Text>
      </Flex>

      <Flex textAlign="center" marginTop={theme.spacing.sm} minHeight="58px">
        <Text variant="body1">{descriptionBody}</Text>
      </Flex>
    </Flex>
  );
};

export default BulkTemplateLoadingInner;
