import React, { createContext, useState, useCallback } from 'react';

interface UserProfileImageProviderProps {
  children: React.ReactNode;
}

interface UserProfileImageContext {
  updateProfileImageTimestamp: () => void;
  profileImageUpdatedTimestamp: number;
}

export const UserProfileImageContext = createContext<UserProfileImageContext>(null);

/**
 * Provides an interface for retrieving and updating the timestamp for when the user profile image was last updated
 */
export default function UserProfileImageProvider({ children }: UserProfileImageProviderProps) {
  const [profileImageUpdatedTimestamp, setProfileImageUpdatedTimestamp] = useState(Date.now());

  const updateProfileImageTimestamp = useCallback((timestamp = Date.now()) => {
    setProfileImageUpdatedTimestamp(timestamp);
  }, []);

  return (
    <UserProfileImageContext.Provider value={{ updateProfileImageTimestamp, profileImageUpdatedTimestamp }}>
      {children}
    </UserProfileImageContext.Provider>
  );
}
