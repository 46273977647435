import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import NumberFormat from 'react-number-format';
import Axios from 'axios';
import _get from 'lodash/get';
import ReduxStore from 'src/types/store/reduxStore';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { formatWalmartResult } from 'src/components/AdCampaignBuilder/Widgets/InstacartProductGrid';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import { store } from 'src/main';
import { EntityColumn } from 'src/components/Grids/Data/ColumnTypes';

const onGridReady = (params: any) => {
  params.api.sizeColumnsToFit();
  params.api.refreshHeader();
};

const mapStateToPropsForColumn = (state: ReduxStore) => {
  const { retailer, adPlatformSettings, adCampaignBuilder } = state;
  return {
    adCampaignBuilder,
    retailer,
    adPlatformSettings
  };
};

const getMinBid = (campaignType, adPlatformSettings) => {
  const FALLBACK_VALUE = 0.3;
  const match = adPlatformSettings.find((set) => _get(set, ['extendedAttributes', 'campaignType']) === campaignType);
  if (match) {
    return _get(match, ['extendedAttributes', 'productLimits', 'minimumBid'], FALLBACK_VALUE);
  }
  return FALLBACK_VALUE;
};

const getMaxBid = (campaignType, adPlatformSettings) => {
  const FALLBACK_VALUE = 20;
  const match = adPlatformSettings.find((set) => _get(set, ['extendedAttributes', 'campaignType']) === campaignType);
  if (match) {
    return _get(match, ['extendedAttributes', 'productLimits', 'maximumBid'], FALLBACK_VALUE);
  }
  return FALLBACK_VALUE;
};

const EditBitColumn = connect(mapStateToPropsForColumn)(({ data, retailer, adPlatformSettings, adCampaignBuilder }) => {
  const apiLimits = adPlatformSettings.filter((set) => set.settingType === 'apiLimit');
  const campaignType = _get(adCampaignBuilder, ['campaignType', 'id']);
  const minBid = getMinBid(campaignType, apiLimits);
  const maxBid = getMaxBid(campaignType, apiLimits);
  const autoBidMapping = _get(adCampaignBuilder, ['target', 'autoBidMapping'], new Map());
  const currentBid = autoBidMapping.get(data.id) || 0.75;
  const handleValueChange = (id, newBid) => {
    const newBidMapping = new Map(autoBidMapping);
    newBidMapping.set(id, newBid);
    store.dispatch(adCampaignBuilderActions.addAutoBidMapping(newBidMapping));
  };

  return (
    <NumberFormat
      value={currentBid}
      isAllowed={({ floatValue }) => {
        return floatValue >= minBid && floatValue <= maxBid; // Amazon's rule, all bid price must be greater or equal to $0.02
      }}
      thousandSeparator
      prefix={retailer.currencySymbol}
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
      onValueChange={({ floatValue }) => handleValueChange(data.id, floatValue)}
      // Mimic the style of the MUI `TextInput`
      style={{
        height: '32px',
        outline: 'none',
        border: 'none',
        borderRadius: '5px',
        width: '80px',
        padding: 10,
        fontSize: '14px',
        color: 'currentColor',
        background: '#eff1f5',
        fontFamily: 'Roboto'
      }}
    />
  );
});

const columnDef = [
  {
    headerName: 'Product',
    field: 'stacklineSku',
    width: undefined,
    enableRtl: true,
    cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
    headerClass: 'align-left',
    minWidth: 600,
    maxWidth: 1000,
    cellRendererFramework: EntityColumn
  },
  {
    headerName: 'Bid',
    cellRendererFramework: EditBitColumn,
    field: 'bid',
    minWidth: 110,
    maxWidth: 110,
    cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
    headerClass: 'align-left',
    disableSort: false,
    onSortFunction: () => {}
  }
];

const mapStateToProps = ({ adCampaignBuilder, retailer, entityService }: ReduxStore) => ({
  adCampaignBuilder,
  retailer,
  entityService
});

const AutoTargetingAssign = ({ adCampaignBuilder }: { adCampaignBuilder: any }) => {
  const [loading, setLoading] = useState(false);

  const entity = _get(adCampaignBuilder, ['platformSettings', 'entity']);
  const fetchProducts = async () => {
    const upcs = adCampaignBuilder.featured.selectedFeaturedUPCs;
    const UPC_API = '/apiAdManager/adCampaigns/getRetailerProductMetadata';
    // const STACKLINE_API = '/apiAdManager/adCampaigns/getProductMetadata';
    const requestBody_Upc = {
      entityId: entity.id,
      platformType: entity.platformType,
      upcs
    };

    const res = await Axios.post(UPC_API, requestBody_Upc);

    setLoading(true);
    const formattedRes = formatWalmartResult(res);
    store.dispatch(adCampaignBuilderActions.addAutoCompaignProducts(formattedRes.data));
    setLoading(false);
  };

  const dataSet = _get(adCampaignBuilder, ['target', 'autoCampaignProducts'], []);

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <GridLoading />;
  }
  return (
    <>
      <CustomAgMaterial
        onGridReady={onGridReady}
        onCellValueChanged={onGridReady}
        onModelUpdated={onGridReady}
        onRowValueChanged={onGridReady}
        onRowDataChanged={onGridReady}
        buildRows={() => dataSet}
        suppressNoRowsOverlay
        columnDefs={columnDef}
        getRowNodeId={(datum: any) => escape(datum.id)}
        domLayout="autoHeight"
        rowHeight={120}
        containerStyle={{ width: '100%' }}
      />
    </>
  );
};

export default connect(mapStateToProps)(AutoTargetingAssign);
