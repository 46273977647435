/* eslint-disable react/prop-types */
/**
 * This component is the presentational component for the header it does not contain any data logic only presentational
 * content/logic
 */
import React from 'react';
import {
  MainTimePeriodDropDown,
  ComparisonTimePeriodDropDown,
  RetailerDropDown,
  HeaderSearchContainer,
  CurrencyDropDown
} from '.';
import Logo from '../common/Logo';
import './Header.scss';
import ReduxStore from 'src/types/store/reduxStore';
import { RouteComponentProps } from 'react-router';
import app, { isDrive, isSuperUser, shouldShowComparisonTimePeriod, shouldShowCriteo } from 'src/utils/app';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AppName } from 'sl-api-connector';
import { useTreatments } from '@splitsoftware/splitio-react';
import AdRetailerDropDown from 'src/components/Header/AdRetailerDropDown';

const featureName = 'translation_toggle_split';

const Header: React.FC<
  RouteComponentProps & {
    app: ReduxStore['app'];
    user: ReduxStore['user'];
    retailer: ReduxStore['retailer'];
    enableTranslation: any;
    disableTranslation: any;
    navSidebar: { isVisible: boolean };
  }
> = ({
  retailer,
  user: {
    session: { email },
    config: { isRestrictedDemoUser, translationEnabled }
  },
  enableTranslation,
  disableTranslation,
  app: { supportsAppSwitching, name, shouldHaveRetailerDropDown, shouldHaveCurrencyDropdown },
  navSidebar
}) => {
  const hideDropdowns = window.location.href.includes('stackline.com/account');
  const isAdvertising = name === AppName.Advertising;
  const isDiscoverApp = name === AppName.Discover;

  // evaluate splits with the `useTreatments` hook
  const treatments = useTreatments([featureName]);
  const showTranslationToggle =
    (['16'].includes(retailer.id) && !isAdvertising && treatments[featureName].treatment === 'on') ||
    (['72'].includes(retailer.id) && !isAdvertising && treatments[featureName].treatment === 'on');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let req: { [key: string]: string[] };
    switch (retailer.id) {
      case '16':
        req = {
          '16': ['ja']
        };
        break;
      case '72':
        req = {
          '72': ['ar']
        };
        break;
      default:
        req = {
          '16': ['ja']
        };
    }
    // Makes API call and updates user config
    if (event.target.checked) {
      enableTranslation(req);
    } else {
      disableTranslation(req);
    }
  };

  let translationTo: string;
  switch (retailer.id) {
    case '72':
      translationTo = 'ARA';
      break;
    case '16':
      translationTo = 'JP';
      break;
    default:
      translationTo = 'JP';
  }

  const TranslationSwitch = () => (
    <div style={{ display: 'inline-block' }}>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>EN</Grid>
          <Grid item>
            <Switch
              checked={translationEnabled !== null}
              onChange={handleChange}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'translation toggle' }}
            />{' '}
          </Grid>
          <Grid item>{translationTo}</Grid>
        </Grid>
      </Typography>
    </div>
  );

  const isCriteoAllowed = shouldShowCriteo();
  const DropdownToUseForDrive = isCriteoAllowed ? AdRetailerDropDown : RetailerDropDown;

  return (
    <header className="sl-page__header">
      <div className={`sl-header ${navSidebar.isVisible ? 'sl-header--expanded' : ''}`}>
        <div className={isDiscoverApp ? 'sl-header__discover-container' : 'sl-header__container'}>
          {isSuperUser(email) ? (
            <h2 style={{ color: 'red', fontWeight: 'bold' }}>SUPERUSER</h2>
          ) : isRestrictedDemoUser || !supportsAppSwitching ? (
            <span />
          ) : (
            <Logo />
          )}
          {!isRestrictedDemoUser && !isDiscoverApp && <HeaderSearchContainer />}
          <div className="sl-header__my-account">
            {hideDropdowns ? null : (
              <div className="sl-header__dropdowns">
                {showTranslationToggle ? <TranslationSwitch /> : null}
                <MainTimePeriodDropDown />
                {shouldShowComparisonTimePeriod() && <ComparisonTimePeriodDropDown />}
                {!isDrive && !isRestrictedDemoUser && shouldHaveRetailerDropDown ? <RetailerDropDown /> : null}
                {isDrive && !isRestrictedDemoUser && shouldHaveRetailerDropDown ? <DropdownToUseForDrive /> : null}
                {shouldHaveCurrencyDropdown ? <CurrencyDropDown /> : null}
              </div>
            )}
          </div>
        </div>

        {hideDropdowns ? null : (
          <div className="sl-header__dropdowns--mobile">
            <MainTimePeriodDropDown />
            {shouldShowComparisonTimePeriod() && <ComparisonTimePeriodDropDown />}
            {showTranslationToggle ? <TranslationSwitch /> : null}
            {!isDrive && !isRestrictedDemoUser && shouldHaveRetailerDropDown ? <RetailerDropDown /> : null}
            {isDrive && !isRestrictedDemoUser && shouldHaveRetailerDropDown ? <DropdownToUseForDrive /> : null}
            {shouldHaveCurrencyDropdown && <CurrencyDropDown />}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
