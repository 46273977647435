import types from './types';

const receiveAdBusinessUnits = (adBusinessUnits) => ({
  type: types.RECEIVE_ADBUSINESSUNITS,
  adBusinessUnits,
  receivedAt: Date.now()
});

const clearAdBusinessUnits = () => ({
  type: types.CLEAR_ADBUSINESSUNITS
});

export default {
  clearAdBusinessUnits,
  receiveAdBusinessUnits
};
