import React, { useEffect, useState } from 'react';
import { store } from 'src/main';
import { withRouter } from 'react-router';
import moment from 'moment-timezone';
import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles, withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import { ChevronIcon } from 'src/components/SvgIcons';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import { DayPickerSingleDateController, isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import { muiStyles } from 'src/components/Layout/Advertising/AdManagerPageLayout/AdManagerSetting/utils';
import CalendarClearButton from 'src/components/common/DatePicker/CalendarClearButton';
import ReduxStore from 'src/types/store/reduxStore';
import {
  saveScheduledActions,
  editScheduleActions,
  periodOptions,
  ChangeAmountOptions,
  ChangePercentOptions,
  CHANGE_PERCENT_OPTIONS,
  CHANGE_AMOUNT_OPTIONS,
  SA_DEFAULT_PERCENT_CHANGE,
  SA_DEFAULT_AMOUNT_CHANGE,
  ActionOption,
  IPeriodOption,
  metricOptions
} from 'src/store/modules/adManager/adScheduledActionGroups/selectors';
import './scheduledActions.scss';
import 'src/components/Layout/Advertising/AdManagerPageLayout/AdManagerSettings.scss';
import { fetchScheduledActionGroups } from 'src/store/modules/adManager/adScheduledActionGroups/operations';
import { format24H } from 'src/utils/inputFormatting';
import colors from 'src/utils/colors';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import Widget from 'src/components/EntityPage/Widget';
import './Create.scss';
import { EventBus } from 'src/types/utils';
import { withBus } from 'react-bus';
import { AdManagerEvents } from 'src/types/application/event';
import { ScheduledActionPageWidgetData } from './types';
import ScheduledActionSelect from './components/ScheduledActionSelect';
import ScheduledActionNumberFormat from './components/ScheduledActionNumberFormat';
import ScheduledActionTextField from './components/ScheduledActionTextField';
import { useSnackbar } from 'src/utils/Hooks';

const NEVER_END_DATE = '12/31/9999';
const MOMENT_NEVER_END_DATE = moment(NEVER_END_DATE);

const usePlaceholderStyles = makeStyles(() => ({
  placeholder: {
    color: colors.labelGrey,
    opacity: 0.8
  }
}));

const Placeholder = ({ text }: { text: any }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{text}</div>;
};

const timeZoneOffsetInMinutes = new Date().getTimezoneOffset();
const timeZone = moment.tz.guess();
const timeZoneString = moment.tz.zone(timeZone)!.abbr(timeZoneOffsetInMinutes);

const weekdays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

interface DisplayableOption {
  name: string;
  displayName: string;
}

const bidMethodsGenerator = (currencySymbol = '$'): DisplayableOption[] => [
  {
    name: ChangeAmountOptions.IncreaseAmount,
    displayName: `Increase Bid by (${currencySymbol})`
  },
  {
    name: ChangePercentOptions.IncreasePercent,
    displayName: 'Increase Bid by (%)'
  },
  {
    name: ChangeAmountOptions.SetAmount,
    displayName: `Set Bid to (${currencySymbol})`
  },
  { name: ChangeAmountOptions.DecreaseAmount, displayName: `Decrease Bid by (${currencySymbol})` },
  { name: ChangePercentOptions.DecreasePercent, displayName: 'Decrease Bid by (%)' }
];

const budgetMethodsGenerator = (currencySymbol = '$'): DisplayableOption[] => [
  {
    name: ChangeAmountOptions.IncreaseAmount,
    displayName: `Increase Budget by (${currencySymbol})`
  },
  {
    name: ChangePercentOptions.IncreasePercent,
    displayName: 'Increase Budget by (%)'
  },
  {
    name: ChangeAmountOptions.SetAmount,
    displayName: `Set Budget to (${currencySymbol})`
  },
  { name: ChangeAmountOptions.DecreaseAmount, displayName: `Decrease Budget by (${currencySymbol})` },
  { name: ChangePercentOptions.DecreasePercent, displayName: 'Decrease Budget by (%)' }
];

const actionTypes: DisplayableOption[] = [
  {
    name: 'timeBase',
    displayName: 'Time Based'
  },
  { name: 'metricBase', displayName: 'Metrics Based' }
];

const allOps: DisplayableOption[] = [
  {
    name: 'AND',
    displayName: 'And'
  },
  {
    name: 'OR',
    displayName: 'Or'
  }
];

const allMetrics: DisplayableOption[] = [
  {
    name: 'impressions',
    displayName: 'Impressions'
  },
  {
    name: 'clicks',
    displayName: 'Clicks'
  },
  {
    name: 'spend',
    displayName: 'Spend'
  },
  {
    name: 'sales',
    displayName: 'Sales'
  },
  {
    name: 'cpc',
    displayName: 'CPC'
  },
  {
    name: 'acos',
    displayName: 'ACOS'
  },
  {
    name: 'roas',
    displayName: 'ROAS'
  }
];

const allDays: DisplayableOption[] = [
  {
    name: '1',
    displayName: '1 day'
  },
  {
    name: '3',
    displayName: '3 days'
  },
  {
    name: '7',
    displayName: '7 days'
  },
  {
    name: '14',
    displayName: '14 days'
  },
  {
    name: '28',
    displayName: '28 days'
  }
];

const allDirections: DisplayableOption[] = [
  {
    name: 'gt',
    displayName: 'Greater than'
  },
  {
    name: 'ge',
    displayName: 'Greater than or equal to'
  },
  {
    name: 'lt',
    displayName: 'Less than'
  },
  {
    name: 'le',
    displayName: 'Less than or equal to'
  }
];

const getSelectedOptionDisplayName = (selectedOption: string, options: DisplayableOption[]): string | undefined => {
  const option = options.find((op) => op.name === selectedOption);
  return option ? option.displayName : undefined;
};

const statusMethods = ['Pause', 'Run'];

const formatWeekDaysSelected = (weekDaysSelected = []) => {
  let result = '';
  const indexSelected: number[] = [];
  weekDaysSelected.forEach((day, idx) => {
    if (day) {
      indexSelected.push(idx);
    }
  });
  let head = '';
  let countRange = 0;

  indexSelected.forEach((dayId, idx) => {
    if (idx === 0) {
      head = weekdays[dayId];
    } else if (dayId === indexSelected[idx - 1] + 1) {
      countRange++;
    } else {
      if (countRange <= 1) {
        if (countRange === 0) {
          result += `${head}, `;
        } else {
          result += `${head} - ${weekdays[indexSelected[idx - 1]]}, `;
        }
      } else {
        result += `${head} - ${weekdays[indexSelected[idx - 1]]}, `;
      }
      countRange = 0;
      head = weekdays[dayId];
    }
  });
  if (countRange < 1) {
    result += head;
  } else {
    result += `${head} - ${weekdays[indexSelected[indexSelected.length - 1]]}`;
  }
  if (result === '') {
    result = 'Choose days';
  }

  return result;
};

const Create = ({
  classes,
  widget,
  history,
  app,
  retailer,
  dataSet,
  selectedMapping,
  eventBus,
  ...props
}: {
  classes: any;
  widget: any;
  history: any;
  app: ReduxStore['app'];
  retailer: ReduxStore['retailer'];
  dataSet: any;
  selectedMapping: any;
  eventBus: EventBus;
}) => {
  const { searchParams } = app.queryParams;
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [weekDaysPickerOpen, setWeekDaysPickerOpen] = useState<HTMLElement | null>(null);
  const [actionTitle, setActionTitle] = useState('');
  const [valueLimit, setValueLimit] = useState<number | undefined>();
  const [actionType, setActionType] = useState('');
  const [selectedAction, setSelectedAction] = useState<Partial<ActionOption>>({});
  const [selectedPeriod, setSelectedPeriod] = useState<Partial<IPeriodOption>>({});
  const [selectedMetric, setSelectedMetric] = useState<any>({});
  const [editRequest, setEditRequest] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSARunning, setIsSARunning] = useState(false);
  const { showSnackbar } = useSnackbar();

  const DISABLE_SAVE_MESSAGE = 'Please update this scheduled action after secondary action has run';

  const budgetMethods = React.useMemo(() => {
    return budgetMethodsGenerator(retailer.currencySymbol);
  }, [retailer.currencySymbol]);

  const bidMethods = React.useMemo(() => {
    return bidMethodsGenerator(retailer.currencySymbol);
  }, [retailer.currencySymbol]);

  const selectedActionBidDisplayName = React.useMemo(() => {
    const bidMethod = bidMethods.find((method) => method.name === selectedAction.method);
    return bidMethod && bidMethod.displayName;
  }, [bidMethods, selectedAction]);

  const uniqueEntities: any[] = [];
  // select campaigns and products from fullDataSet
  // becuase they have option to select all;
  // select targets from data
  const selectedCampaigns =
    dataSet && dataSet.fullDataSet
      ? _get(dataSet, ['fullDataSet'], [])
          .filter((data: any) => {
            if (!uniqueEntities.includes(data.id) && selectedMapping.includes(data.id)) {
              uniqueEntities.push(data.id);
              return true;
            }
            return false;
          })
          .map((data: any) => data)
      : _get(dataSet, ['data'], [])
          .filter((data: any) => selectedMapping.includes(data.id))
          .map((data: any) => data.entity);
  const { actionOpts: actionsOpt }: ScheduledActionPageWidgetData = widget.data;
  const actionOpts = _cloneDeep(actionsOpt);
  const { currentScheduledAction: currentScheduledActionId } = app.queryParams;
  const isEditMode = currentScheduledActionId;
  const isWalmart = retailer.id === '2' || retailer.id === '25';

  // For Walmart bids, add products tab
  if (isWalmart) {
    actionOpts[4].metricOptions.push(metricOptions.product);
  }

  React.useEffect(() => {
    const prefill = async () => {
      const res = await store.dispatch(
        fetchScheduledActionGroups(false, retailer.platformType, currentScheduledActionId)
      );
      const scheduledActionGroups = _get(res, ['scheduledActionGroups'], []);
      const foundActionGroups = scheduledActionGroups.find((action) => action.id === currentScheduledActionId);
      const { action, period, metric, prefillMax, prefillMin } = _get(foundActionGroups, 'scheduleDetail.prefill', {});
      const scheduledActions = _get(foundActionGroups, 'scheduledActions', []);

      if (scheduledActions.length === 2) {
        // disable save edit button when primary sa has
        // started and secondary sa is yet to be completed
        // for time based sa with period = hoursInDay, daysOfTheWeek
        const today = moment();
        const startTime = moment(_get(scheduledActions[0], 'scheduledAction.startTime'));
        const lockedUntil = moment(_get(scheduledActions[0], 'scheduledAction.extendedAttributes.lockedUntil'));
        const actionPeriodDescription = _get(
          scheduledActions[0],
          'scheduledAction.extendedAttributes.actionPeriodDescription'
        );
        if (
          today.isAfter(startTime) &&
          today.isBefore(lockedUntil) &&
          ['hoursInDay', 'daysOfTheWeek'].includes(actionPeriodDescription)
        ) {
          setIsSARunning(true);
        } else {
          setIsSARunning(false);
        }
      }

      setEditRequest(foundActionGroups.scheduledActions.map((item) => item.scheduledAction));
      const prefillActionType = _get(scheduledActions[0], 'scheduledAction.extendedAttributes.actionType', 'timeBase');
      setActionTitle(foundActionGroups.name);
      if (prefillActionType === 'timeBase') {
        setActionType('timeBase');
      } else {
        setActionType('metricBase');
      }
      if (
        action &&
        [ChangeAmountOptions.IncreaseAmount, ChangePercentOptions.IncreasePercent].includes(action.method)
      ) {
        setValueLimit(prefillMax);
      } else if (
        action &&
        [ChangeAmountOptions.DecreaseAmount, ChangePercentOptions.DecreasePercent].includes(action.method)
      ) {
        setValueLimit(prefillMin);
      }
      setSelectedAction(action || {});
      setSelectedPeriod(period || {});
      setSelectedMetric(metric || {});
    };

    if (isEditMode && retailer) {
      prefill();
    }
  }, [isEditMode, retailer]);

  useEffect(() => {
    if (actionType === 'metricBase') {
      setSelectedPeriod(periodOptions.hoursInDay);
    } else {
      setSelectedPeriod({});
    }
  }, [actionType]);

  const { startDate, endDate, startTime, endTime, weekDaysMapping } = selectedPeriod;

  const handleSetActionType = (newType: string) => {
    setSelectedMetric({});
    setSelectedAction({});
    setSelectedPeriod({});
    setActionType(newType);
  };

  const setAction = (typeName: string) => {
    const newAction = actionOpts.find((item) => item.name === typeName);
    setSelectedMetric({});
    setSelectedAction(newAction || {});
  };

  const setPeriod = (periodName: string) => {
    if (selectedAction.periodOptions) {
      const newPeriod = selectedAction.periodOptions.find((item) => item.name === periodName);
      setSelectedPeriod(newPeriod || {});
    }
  };

  const setMetric = (levelName: string) => {
    if (selectedAction.metricOptions) {
      const newMetric = selectedAction.metricOptions.find((item) => item.level === levelName);
      // if metric exist copy rules for reselect during edit
      if (selectedMetric.level === levelName) {
        newMetric.rules = selectedMetric.rules;
      }
      setSelectedMetric(newMetric);
    }
  };

  const editMetric = (items: any) => {
    const newMetric = {
      ...selectedMetric,
      ...items
    };
    setSelectedMetric(newMetric);
  };

  const updateMetricRule = (idx, newItems) => {
    const newMetricRules = [...selectedMetric.rules];
    newMetricRules[idx] = {
      ...newMetricRules[idx],
      ...newItems
    };
    editMetric({ rules: newMetricRules });
  };

  const editAction = (items) => {
    const newAction = {
      ...selectedAction,
      ...items
    };
    if (items.method && selectedAction.method) {
      if (
        CHANGE_PERCENT_OPTIONS.includes(items.method) &&
        (CHANGE_AMOUNT_OPTIONS as string[]).includes(selectedAction.method)
      ) {
        newAction.value = SA_DEFAULT_PERCENT_CHANGE;
      } else if (
        CHANGE_AMOUNT_OPTIONS.includes(items.method) &&
        (CHANGE_PERCENT_OPTIONS as string[]).includes(selectedAction.method)
      ) {
        newAction.value = SA_DEFAULT_AMOUNT_CHANGE;
      }
    }
    setSelectedAction(newAction);
  };

  const editPeriod = (items) => {
    const newPeriod = {
      ...selectedPeriod,
      ...items
    };
    setSelectedPeriod(newPeriod);
  };

  const handleAddCol = () => {
    if (selectedMetric.rules && selectedMetric.rules.length < 3) {
      const newMetricRules = [...selectedMetric.rules];
      newMetricRules.push({
        days: 3,
        metric: 'impressions',
        value: 0,
        direction: 'gt'
      });
      editMetric({ rules: newMetricRules });
    }
  };

  const handleRemoveCol = (idx: number) => {
    const newMetricRules = [...selectedMetric.rules];
    newMetricRules.splice(idx, 1);
    editMetric({ rules: newMetricRules });
  };

  const startDateLimit = (day) => {
    const tomorrowDate = moment().add(1, 'days');

    if (!endDate) {
      // if endDate not set, use could set startDate to be any day after today.
      return !isInclusivelyAfterDay(day, tomorrowDate);
    } else {
      // if endDate set, use could set startDate to be any day between today and endDate.
      return !isInclusivelyAfterDay(day, tomorrowDate) || !isInclusivelyBeforeDay(day, endDate);
    }
  };

  const endDateLimit = (day) => {
    const tomorrowDate = moment().add(1, 'days');

    let latestDate = tomorrowDate;
    if (startDate && tomorrowDate.isBefore(moment(startDate))) {
      latestDate = moment(startDate);
    }
    // disable all dates before today
    if (!isInclusivelyAfterDay(day, latestDate)) {
      return true;
    }

    return false;
  };

  const showErrorMessageForHours = () => {
    if (selectedPeriod.name === 'hoursInDay' && !isEditMode) {
      const start = startTime.replace(':', '');
      const end = endTime.replace(':', '');
      const diff = +end - +start;
      return diff < 5;
    }
    return false;
  };

  let disabledStartDate = false;

  const canCreateScheduledAction = () => {
    return (
      actionTitle !== '' &&
      !_isEmpty(selectedAction) &&
      (!_isEmpty(selectedPeriod) || !_isEmpty(selectedMetric)) &&
      !_isEmpty(selectedCampaigns)
      // selectedCampaigns.length < 50
    );
  };

  const toggleWeekDays = (idx) => {
    const weekDaysMappingClone = weekDaysMapping;
    weekDaysMappingClone[idx] = !weekDaysMappingClone[idx];
    editPeriod({ weekDaysMapping: weekDaysMappingClone });
  };
  const notAllDaysSelect = (weekDaysMapping || []).some((item) => !item);

  const toggleAllWeekDays = () => {
    editPeriod({ weekDaysMapping: [true, true, true, true, true, true, true] });
  };

  const currentStartDate = actionType === 'metricBase' ? selectedMetric.startDate : selectedPeriod.startDate;
  const currentEndDate = actionType === 'metricBase' ? selectedMetric.endDate : selectedPeriod.endDate;

  if (currentStartDate) {
    const startDateAfterToday = moment().subtract(1, 'day').isBefore(currentStartDate);
    disabledStartDate = !startDateAfterToday && isEditMode;
  }
  const gridWidget = widget.view.bottomGrid;

  const getComputedGridWidget = (grid) => {
    const newGrid = _cloneDeep(grid);
    const originDefs =
      grid.data.widgets[gridWidget.data.widgets.length - 1].data.widgets[
        grid.data.widgets[gridWidget.data.widgets.length - 1].data.widgets.length - 1
      ].view.tabDefinitions;

    const lastWidget = newGrid.data.widgets[gridWidget.data.widgets.length - 1].data.widgets;
    const targetWidget = lastWidget[lastWidget.length - 1];
    const updateWidget = (_tabs: string[]) => {
      targetWidget.view.tabDefinitions = originDefs.filter((item) => _tabs.includes(item.displayName));
    };

    if (!isEditMode) {
      const isTimeBasedAction = actionType === 'timeBase';

      const actionTabs: Record<string, string[]> = {
        status: ['Campaigns'],
        budget: ['Campaigns'],
        targetStatus: isTimeBasedAction ? ['Targets'] : ['Targets', 'Campaigns'],
        productStatus: isTimeBasedAction ? ['Products'] : ['Products', 'Campaigns'],
        bid:
          _get(selectedMetric, ['level']) === 'campaign'
            ? ['Campaigns']
            : _get(selectedMetric, ['level']) === 'product'
            ? ['Campaigns', 'Products']
            : isWalmart
            ? ['Targets', 'Campaigns']
            : ['Targets', 'Campaigns']
      };

      // Replace the switch statement with a lookup in the actionTabs object
      const tabs = actionTabs[selectedAction.name] || ['Campaigns'];

      updateWidget(tabs);
    } else if (editRequest && editRequest.length) {
      const saEntities = _get(editRequest, [0, 'extendedAttributes', 'adUpdateRequest', 'extendedAttributes'], {});

      if (saEntities.adTargets && saEntities.adTargets.length > 0) {
        updateWidget(['Targets']);
      }

      if (saEntities.adCampaignProducts && saEntities.adCampaignProducts.length > 0) {
        updateWidget(['Products']);
      }

      if (
        (saEntities.adCampaigns && saEntities.adCampaigns.length > 0) ||
        (saEntities.adCampaignsBudgetSetting && saEntities.adCampaignsBudgetSetting.length > 0)
      ) {
        updateWidget(['Campaigns']);
      }
    }

    return newGrid;
  };
  const gridClone = getComputedGridWidget(gridWidget);

  const handleCreateScheduledAction = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);

      const maxValueLimit =
        selectedAction.method &&
        ([ChangeAmountOptions.IncreaseAmount, ChangePercentOptions.IncreasePercent] as string[]).includes(
          selectedAction.method
        )
          ? valueLimit
          : undefined;
      const minValueLimit =
        selectedAction.method &&
        ([ChangeAmountOptions.DecreaseAmount, ChangePercentOptions.DecreasePercent] as string[]).includes(
          selectedAction.method
        )
          ? valueLimit
          : undefined;

      const res = await saveScheduledActions(
        actionTitle,
        actionType,
        minValueLimit,
        maxValueLimit,
        selectedAction,
        selectedPeriod,
        selectedMetric,
        selectedCampaigns
      );

      if (res) {
        if (res.status === 200) {
          showSnackbar({
            type: 'success',
            timeout: 3000,
            message: <div>Scheduled Action creation successful</div>
          });
        } else {
          showSnackbar({
            type: 'error',
            timeout: 3000,
            message: <div>Scheduled Action creation failed</div>
          });
        }
      }

      history.push(`/home${searchParams}&tab=adScheduledActions&subtab=view`);
    }
  };

  const handleSaveEditScheduledAction = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      const maxValueLimit =
        selectedAction.method &&
        ([ChangeAmountOptions.IncreaseAmount, ChangePercentOptions.IncreasePercent] as string[]).includes(
          selectedAction.method
        )
          ? valueLimit
          : undefined;
      const minValueLimit =
        selectedAction.method &&
        ([ChangeAmountOptions.DecreaseAmount, ChangePercentOptions.DecreasePercent] as string[]).includes(
          selectedAction.method
        )
          ? valueLimit
          : undefined;
      const res = await editScheduleActions({
        actionTitle,
        actionType,
        period: selectedPeriod,
        metric: selectedMetric,
        selectedAction,
        selectedCampaigns,
        minValueLimit,
        maxValueLimit,
        originRequests: editRequest
      });
      if (res) {
        if (res.status === 200) {
          showSnackbar({
            type: 'success',
            timeout: 3000,
            message: <div>Your update was successful</div>
          });
        } else {
          showSnackbar({
            type: 'error',
            timeout: 3000,
            message: <div>Your update failed</div>
          });
        }
      }
      setIsSubmitting(false);
      history.push(`/home${searchParams}&tab=adScheduledActions&subtab=view`);
    }
  };

  const openWeekDaysPickerWithTarget = (target: HTMLElement) => {
    setWeekDaysPickerOpen(target);
  };

  const getActionTargetName = (_name) => {
    switch (_name) {
      case 'productStatus':
        return 'products';
      case 'targetStatus':
        return 'targets';
      case 'status':
      default:
        return 'campaigns';
    }
  };

  return (
    <div className="scheduled_actions_container">
      <div className="header_create">
        <div>{isEditMode ? 'Edit' : 'Create'} Scheduled Action</div>
        <div>
          <LargeMuiButton
            secondary
            style={{ width: 99, marginRight: 15 }}
            label="Cancel"
            onClick={() => {
              history.push(`/home${searchParams}&tab=adScheduledActions&subtab=view`);
            }}
          />
          {isEditMode ? (
            <Tooltip title={isSARunning ? DISABLE_SAVE_MESSAGE : ''}>
              <span>
                <LargeMuiButton
                  loading={isSubmitting}
                  loadingPosition="start"
                  style={{ width: 130, marginRight: 15 }}
                  disabled={_isEmpty(selectedCampaigns) || isSARunning}
                  onClick={handleSaveEditScheduledAction}
                >
                  Save Edit
                </LargeMuiButton>
              </span>
            </Tooltip>
          ) : (
            <LargeMuiButton
              style={{ width: 99, marginRight: 15 }}
              label="Create"
              disabled={!canCreateScheduledAction()}
              onClick={handleCreateScheduledAction}
            />
          )}
        </div>
      </div>
      <div className="setting_container" style={{ margin: '40px 0' }}>
        <div className="setting_row">
          <div className="label">Name:</div>
          <div>
            <TextField
              variant="standard"
              placeholder="Scheduled Action Name"
              autoComplete="off"
              id="outlined-bare"
              classes={{ root: classes.root }}
              value={actionTitle}
              InputProps={{ classes: { root: classes.root } }}
              onChange={({ target: { value } }) => setActionTitle(value)}
              margin="normal"
            />
          </div>
        </div>
        <div className="setting_row">
          <div className="label">Type:</div>
          <div>
            <ScheduledActionSelect
              disabledTextProps={{
                paddingTop: '3px'
              }}
              disabled={isEditMode}
              disabledText={getSelectedOptionDisplayName(actionType, actionTypes)}
              variant="standard"
              value={actionType}
              onChange={({ target: { value } }) => handleSetActionType(value)}
              classes={{ root: classes.selectRoot, select: classes.select }}
              IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
              displayEmpty
              // eslint-disable-next-line react/display-name
              renderValue={actionType !== '' ? undefined : () => <Placeholder text="Select Action Type" />}
            >
              {actionTypes.map((item) => (
                <MenuItem
                  // disabled={app.stage !== 'dev' && item.name === 'metricBase'}
                  key={item.name}
                  value={item.name}
                >
                  {item.displayName}
                </MenuItem>
              ))}
            </ScheduledActionSelect>
          </div>
        </div>
        <div className="setting_row">
          <div className="label">Action:</div>
          <div>
            <ScheduledActionSelect
              variant="standard"
              disabled={isEditMode}
              disabledText={selectedAction.displayName}
              disabledTextProps={{
                paddingTop: '3px'
              }}
              value={selectedAction.name || null}
              onChange={({ target: { value } }) => {
                setAction(value);
                eventBus.emit(AdManagerEvents.ENTITY_EVENT_SELECT_SA_ACTION, { saAction: value });
              }}
              IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
              displayEmpty
              // eslint-disable-next-line react/display-name
              renderValue={selectedAction.name ? null : () => <Placeholder text="Select Action" />}
            >
              {actionOpts.map((item) => (
                <MenuItem key={item.name} value={item.name}>
                  {item.displayName}
                </MenuItem>
              ))}
            </ScheduledActionSelect>
          </div>
        </div>
        {actionType === 'timeBase' && (
          <div className="setting_row">
            <div className="label">Period:</div>
            <div>
              <ScheduledActionSelect
                variant="standard"
                style={{ marginRight: 20 }}
                value={selectedPeriod.name}
                onChange={({ target: { value } }) => setPeriod(value)}
                IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                disabled={!selectedAction.periodOptions || isEditMode}
                disabledText={selectedPeriod.displayName}
                disabledTextProps={{
                  paddingTop: '3px'
                }}
                displayEmpty
                // eslint-disable-next-line react/display-name
                renderValue={selectedPeriod.name ? undefined : () => <Placeholder text="Select Time Period" />}
              >
                {selectedAction.periodOptions &&
                  selectedAction.periodOptions.map((item) => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.displayName}
                    </MenuItem>
                  ))}
              </ScheduledActionSelect>
            </div>
          </div>
        )}
        {actionType === 'metricBase' && (
          <div className="setting_row">
            <div className="label">Metric Level:</div>
            <div>
              <ScheduledActionSelect
                variant="standard"
                value={selectedMetric.level || null}
                onChange={({ target: { value } }) => setMetric(value)}
                classes={{ root: classes.selectRoot, select: classes.select }}
                IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                disabled={isEditMode || !selectedAction.metricOptions}
                disabledText={
                  selectedAction.metricOptions &&
                  getSelectedOptionDisplayName(
                    selectedMetric.level,
                    selectedAction.metricOptions.map(({ level, levelDisplayName }) => ({
                      name: level,
                      displayName: levelDisplayName
                    }))
                  )
                }
                disabledTextProps={{
                  paddingTop: '3px'
                }}
                displayEmpty
                // eslint-disable-next-line react/display-name
                renderValue={
                  isEditMode
                    ? () =>
                        selectedAction.metricOptions &&
                        getSelectedOptionDisplayName(
                          selectedMetric.level,
                          selectedAction.metricOptions.map(({ level, levelDisplayName }) => ({
                            name: level,
                            displayName: levelDisplayName
                          }))
                        )
                    : selectedMetric.level
                    ? null
                    : () => <Placeholder text="Select Metric Level" />
                }
              >
                {selectedAction.metricOptions &&
                  selectedAction.metricOptions.map((item) => (
                    <MenuItem key={item.level} value={item.level}>
                      {item.levelDisplayName}
                    </MenuItem>
                  ))}
              </ScheduledActionSelect>
            </div>
          </div>
        )}
        {actionType === 'metricBase' && (
          <div className="setting_row">
            <div className="label">Metric Rule(s):</div>
            <div>
              {selectedMetric && selectedMetric.rules && (
                <div>
                  {selectedMetric.rules.map((rule, idx) => {
                    return (
                      <div key={idx} className="setting_row--flex-container" style={{ position: 'relative' }}>
                        {idx > 0 && (
                          <ScheduledActionSelect
                            variant="standard"
                            style={{ marginRight: 5 }}
                            value={selectedMetric.operator}
                            onChange={({ target: { value } }) => editMetric({ operator: value })}
                            classes={{ root: classes.shortRoot, select: classes.select }}
                            IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                            displayEmpty
                            renderValue={
                              selectedMetric.operator ? undefined : () => <Placeholder text="Select Metric Operator" />
                            }
                          >
                            {allOps.map((op) => (
                              <MenuItem key={op.name} value={op.name}>
                                {op.displayName}
                              </MenuItem>
                            ))}
                          </ScheduledActionSelect>
                        )}
                        {/* Metric type */}
                        <ScheduledActionSelect
                          variant="standard"
                          style={{ marginRight: 5 }}
                          renderValue={() => {
                            return getSelectedOptionDisplayName(rule.metric, allMetrics);
                          }}
                          value={rule.metric}
                          onChange={({ target: { value } }) => updateMetricRule(idx, { metric: value })}
                          classes={{ root: classes.dateRoot, select: classes.select }}
                          IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                        >
                          {allMetrics.map((metric) => (
                            <MenuItem key={metric.name} value={metric.name}>
                              {metric.displayName}
                            </MenuItem>
                          ))}
                        </ScheduledActionSelect>
                        in last &nbsp;
                        {/* In last days */}
                        <ScheduledActionSelect
                          variant="standard"
                          style={{ marginRight: 5 }}
                          renderValue={() => {
                            return getSelectedOptionDisplayName(`${rule.days}`, allDays);
                          }}
                          value={rule.days}
                          onChange={({ target: { value } }) => updateMetricRule(idx, { days: value })}
                          classes={{ root: classes.shortRoot, select: classes.select }}
                          IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                        >
                          {allDays.map((day) => (
                            <MenuItem key={day.name} value={day.name}>
                              {day.displayName}
                            </MenuItem>
                          ))}
                        </ScheduledActionSelect>
                        &nbsp;
                        <ScheduledActionSelect
                          variant="standard"
                          style={{ marginRight: 5 }}
                          value={rule.direction}
                          renderValue={() => {
                            return getSelectedOptionDisplayName(rule.direction, allDirections);
                          }}
                          onChange={({ target: { value } }) => updateMetricRule(idx, { direction: value })}
                          classes={{ root: classes.actionType, select: classes.select }}
                          IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                        >
                          {allDirections.map((day) => (
                            <MenuItem key={day.name} value={day.name}>
                              {day.displayName}
                            </MenuItem>
                          ))}
                        </ScheduledActionSelect>
                        &nbsp;
                        <ScheduledActionNumberFormat
                          value={rule.value}
                          thousandSeparator
                          prefix={
                            ['spend', 'sales', 'cpc', 'roas'].includes(rule.metric) ? retailer.currencySymbol : ''
                          }
                          suffix={['acos'].includes(rule.metric) ? '%' : ''}
                          decimalScale={rule.metric === 'impressions' || rule.metric === 'clicks' ? 0 : 2}
                          fixedDecimalScale
                          allowNegative={false}
                          onValueChange={({ floatValue }) => updateMetricRule(idx, { value: floatValue })}
                          style={{
                            width: '70px'
                          }}
                        />
                        {/* Add remove metric conditions, up to 3 */}
                        &nbsp;
                        {idx === 0 ? (
                          <AddCircleOutlineRoundedIcon onClick={handleAddCol} style={{ cursor: 'pointer' }} />
                        ) : (
                          <RemoveCircleOutlineRoundedIcon
                            onClick={() => {
                              handleRemoveCol(idx);
                            }}
                            style={{ color: colors.labelGrey, cursor: 'pointer' }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="setting_row">
          <div className="label">Plan:</div>
          <div>
            {/* 1. TimeBased - Status - hoursInDay */}
            {selectedAction.name &&
              ['status', 'targetStatus', 'productStatus'].includes(selectedAction.name) &&
              ((selectedPeriod.name === 'hoursInDay' && actionType === 'timeBase' && (
                <div>
                  <div className="setting_row--flex-container" style={{ position: 'relative' }}>
                    <ScheduledActionSelect
                      variant="standard"
                      style={{ marginRight: 5 }}
                      disabled={isEditMode}
                      disabledText={statusMethods.find((method) => method === selectedAction.method)}
                      value={selectedAction.method}
                      onChange={({ target: { value } }) => editAction({ method: value })}
                      classes={{ root: classes.dateRoot, select: classes.select }}
                      IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                      displayEmpty
                      renderValue={
                        selectedAction.method ? undefined : () => <Placeholder text="Select Action Method" />
                      }
                    >
                      {statusMethods.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </ScheduledActionSelect>
                    {`${getActionTargetName(selectedAction.name)} from `}
                    <ScheduledActionNumberFormat
                      disabled={isEditMode}
                      value={startTime}
                      format={format24H}
                      placeholder="HH:MM"
                      thousandSeparator
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={({ formattedValue }) => editPeriod({ startTime: formattedValue })}
                      style={{
                        fontSize: '16px'
                      }}
                    />
                    {timeZoneString} to &nbsp;
                    <ScheduledActionNumberFormat
                      disabled={isEditMode}
                      value={endTime}
                      format={format24H}
                      placeholder="HH:MM"
                      thousandSeparator
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={({ formattedValue }) => editPeriod({ endTime: formattedValue })}
                      style={{
                        width: '67px'
                      }}
                    />
                    {timeZoneString}&nbsp; on&nbsp;
                    <ScheduledActionTextField
                      disabled={isEditMode}
                      variant="standard"
                      autoComplete="off"
                      classes={{ root: classes.longRoot }}
                      InputProps={{ classes: { root: classes.longRoot } }}
                      value={formatWeekDaysSelected(weekDaysMapping)}
                      id="outlined-bare"
                      onClick={(event) => openWeekDaysPickerWithTarget(event.currentTarget)}
                      margin="normal"
                    />
                    <Popover
                      open={Boolean(weekDaysPickerOpen)}
                      anchorEl={weekDaysPickerOpen}
                      onClose={() => setWeekDaysPickerOpen(null)}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                    >
                      <div className="platform_drowdown">
                        <MenuItem onClick={toggleAllWeekDays}>
                          <Checkbox color="primary" checked={!notAllDaysSelect} style={{ marginLeft: -9 }} />
                          All Days
                        </MenuItem>
                        {weekdays.map((item, idx) => {
                          return (
                            <MenuItem key={idx} onClick={() => toggleWeekDays(idx)}>
                              <Checkbox color="primary" checked={weekDaysMapping[idx]} style={{ marginLeft: -9 }} />
                              {item}
                            </MenuItem>
                          );
                        })}
                      </div>
                    </Popover>
                  </div>
                </div>
              )) ||
                // 2.  daysOfTheWeek
                (selectedPeriod.name === 'daysOfTheWeek' && (
                  <div className="setting_row--flex-container" style={{ position: 'relative' }}>
                    {actionType === 'timeBase' && (
                      <ScheduledActionSelect
                        variant="standard"
                        style={{ marginRight: 5 }}
                        disabled={isEditMode}
                        disabledText={statusMethods.find((method) => method === selectedAction.method)}
                        value={selectedAction.method}
                        onChange={({ target: { value } }) => editAction({ method: value })}
                        classes={{ root: classes.dateRoot, select: classes.select }}
                        IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                        displayEmpty
                        renderValue={
                          selectedAction.method ? undefined : () => <Placeholder text="Select Action Method" />
                        }
                      >
                        {statusMethods.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </ScheduledActionSelect>
                    )}
                    <ScheduledActionSelect
                      disabled={isEditMode}
                      disabledText={weekdays.find((weekday) => weekday === selectedPeriod.startDay)}
                      variant="standard"
                      style={{ marginRight: 10 }}
                      value={selectedPeriod.startDay}
                      onChange={({ target: { value } }) => editPeriod({ startDay: value })}
                      classes={{ root: classes.shortRoot, select: classes.select }}
                      IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                    >
                      {weekdays.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </ScheduledActionSelect>
                    <ScheduledActionNumberFormat
                      disabled={isEditMode}
                      value={startTime}
                      format={format24H}
                      placeholder="HH:MM"
                      thousandSeparator
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={({ formattedValue }) => editPeriod({ startTime: formattedValue })}
                      // Mimic the style of the MUI `TextInput`
                      style={{
                        width: '67px'
                      }}
                    />
                    {timeZoneString} to &nbsp;
                    <ScheduledActionSelect
                      disabled={isEditMode}
                      disabledText={weekdays.find((weekday) => weekday === selectedPeriod.endDay)}
                      variant="standard"
                      style={{ marginRight: 20 }}
                      value={selectedPeriod.endDay}
                      onChange={({ target: { value } }) => editPeriod({ endDay: value })}
                      classes={{ root: classes.shortRoot, select: classes.select }}
                      IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                    >
                      {weekdays.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </ScheduledActionSelect>
                    <ScheduledActionNumberFormat
                      disabled={isEditMode}
                      value={endTime}
                      format={format24H}
                      placeholder="HH:MM"
                      // mask={['0', '0', '0', '0']}
                      thousandSeparator
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={({ formattedValue }) => editPeriod({ endTime: formattedValue })}
                      // Mimic the style of the MUI `TextInput`
                      style={{
                        width: '67px'
                      }}
                    />
                    {timeZoneString}
                  </div>
                )) ||
                // 3. metricBase ||  dateOrDateRange
                ((selectedPeriod.name === 'dateOrDateRange' || actionType === 'metricBase') && (
                  <div className="setting_row--flex-container">
                    {' '}
                    <ScheduledActionSelect
                      variant="standard"
                      style={{ marginRight: 5 }}
                      disabled={isEditMode}
                      disabledText={statusMethods.find((statusMethod) => statusMethod === selectedAction.method)}
                      value={selectedAction.method}
                      onChange={({ target: { value } }) => editAction({ method: value })}
                      classes={{ root: classes.dateRoot, select: classes.select }}
                      displayEmpty
                      IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                    >
                      {statusMethods.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </ScheduledActionSelect>{' '}
                    {actionType === 'metricBase' ? selectedMetric.level : getActionTargetName(selectedAction.name)}
                    {actionType === 'metricBase' && (
                      <>
                        {' '}
                        on{' '}
                        <ScheduledActionTextField
                          variant="standard"
                          autoComplete="off"
                          classes={{ root: classes.longRoot }}
                          InputProps={{ classes: { root: classes.longRoot } }}
                          value={formatWeekDaysSelected(weekDaysMapping)}
                          id="outlined-bare"
                          onClick={(event) => openWeekDaysPickerWithTarget(event.currentTarget)}
                          margin="normal"
                        />
                        <Popover
                          open={Boolean(weekDaysPickerOpen)}
                          anchorEl={weekDaysPickerOpen}
                          onClose={() => setWeekDaysPickerOpen(null)}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                        >
                          <div className="platform_drowdown">
                            <MenuItem onClick={toggleAllWeekDays}>
                              <Checkbox color="primary" checked={!notAllDaysSelect} style={{ marginLeft: -9 }} />
                              All Days
                            </MenuItem>
                            {weekdays.map((item, idx) => {
                              return (
                                <MenuItem key={idx} onClick={() => toggleWeekDays(idx)}>
                                  <Checkbox
                                    color="primary"
                                    checked={weekDaysMapping && weekDaysMapping[idx]}
                                    style={{ marginLeft: -9 }}
                                  />
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </div>
                        </Popover>
                      </>
                    )}
                  </div>
                )))}
            {/* End */}
            {selectedAction.name === 'budgets' &&
              (((selectedPeriod.name === 'dateOrDateRange' || actionType === 'metricBase') && (
                <div className="setting_row--flex-container">
                  <ScheduledActionSelect
                    variant="standard"
                    style={{ marginRight: 5 }}
                    disabled={isEditMode}
                    disabledText={
                      selectedAction.method && getSelectedOptionDisplayName(selectedAction.method, budgetMethods)
                    }
                    value={selectedAction.method}
                    onChange={({ target: { value } }) => editAction({ method: value })}
                    classes={{ root: classes.actionType, select: classes.select }}
                    IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                  >
                    {budgetMethods.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.displayName}
                      </MenuItem>
                    ))}
                  </ScheduledActionSelect>
                  &nbsp;
                  <ScheduledActionNumberFormat
                    value={selectedAction.value}
                    thousandSeparator
                    prefix={CHANGE_PERCENT_OPTIONS.includes(selectedAction.method) ? '' : retailer.currencySymbol}
                    suffix={CHANGE_PERCENT_OPTIONS.includes(selectedAction.method) ? '%' : ''}
                    decimalScale={2}
                    fixedDecimalScale
                    allowNegative={false}
                    onValueChange={({ floatValue }) => editAction({ value: floatValue })}
                    // Mimic the style of the MUI `TextInput`
                    style={{
                      width: '70px'
                    }}
                  />
                  {/* {selectedAction.method !== ChangeAmountOptions.SetAmount && (
                    <>
                      &nbsp; up to &nbsp;
                      <ScheduledActionNumberFormat
                        className="number-formatter"
                        value={valueLimit}
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                        placeholder="optional"
                        prefix={retailer.currencySymbol}
                        allowNegative={false}
                        disabled={isEditMode}
                        onValueChange={({ floatValue }) => setValueLimit(floatValue)}
                        style={{
                          width: '100px',
                          '::placeholder': {
                            color: colors.stacklineBlue
                          }
                        }}
                      />
                    </>
                  )} */}
                  {/* Metric, Budget Days  */}
                  {actionType === 'metricBase' && (
                    <>
                      {' '}
                      on{' '}
                      <ScheduledActionTextField
                        variant="standard"
                        autoComplete="off"
                        classes={{ root: classes.longRoot }}
                        InputProps={{ classes: { root: classes.longRoot } }}
                        value={formatWeekDaysSelected(weekDaysMapping)}
                        id="outlined-bare"
                        onClick={(event) => openWeekDaysPickerWithTarget(event.currentTarget)}
                        margin="normal"
                        style={{
                          '::after': undefined
                        }}
                      />
                      <Popover
                        open={Boolean(weekDaysPickerOpen)}
                        anchorEl={weekDaysPickerOpen}
                        onClose={() => setWeekDaysPickerOpen(null)}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                      >
                        <div className="platform_drowdown">
                          <MenuItem onClick={toggleAllWeekDays}>
                            <Checkbox color="primary" checked={!notAllDaysSelect} style={{ marginLeft: -9 }} />
                            All Days
                          </MenuItem>
                          {weekdays.map((item, idx) => {
                            return (
                              <MenuItem key={idx} onClick={() => toggleWeekDays(idx)}>
                                <Checkbox
                                  color="primary"
                                  checked={weekDaysMapping && weekDaysMapping[idx]}
                                  style={{ marginLeft: -9 }}
                                />
                                {item}
                              </MenuItem>
                            );
                          })}
                        </div>
                      </Popover>
                    </>
                  )}
                </div>
              )) ||
                // For timebased?
                (selectedPeriod.name === 'daysOfTheWeek' && (
                  <div className="setting_row--flex-container">
                    <ScheduledActionSelect
                      variant="standard"
                      style={{ marginRight: 5 }}
                      disabled={isEditMode}
                      disabledText={
                        selectedAction.method && getSelectedOptionDisplayName(selectedAction.method, budgetMethods)
                      }
                      value={selectedAction.method}
                      onChange={({ target: { value } }) => editAction({ method: value })}
                      classes={{ root: classes.actionType, select: classes.select }}
                      IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                    >
                      {budgetMethods.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.displayName}
                        </MenuItem>
                      ))}
                    </ScheduledActionSelect>
                    &nbsp;
                    <ScheduledActionNumberFormat
                      // disabled={isEditMode}
                      value={selectedAction.value}
                      thousandSeparator
                      prefix={CHANGE_PERCENT_OPTIONS.includes(selectedAction.method) ? '' : retailer.currencySymbol}
                      suffix={CHANGE_PERCENT_OPTIONS.includes(selectedAction.method) ? '%' : ''}
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={({ floatValue }) => editAction({ value: floatValue })}
                      // Mimic the style of the MUI `TextInput`
                      style={{
                        width: '70px'
                      }}
                    />
                    &nbsp;
                    <ScheduledActionSelect
                      variant="standard"
                      style={{ marginRight: 10 }}
                      value={selectedPeriod.startDay}
                      disabled={isEditMode}
                      disabledText={weekdays.find((weekday) => weekday === selectedPeriod.startDay)}
                      onChange={({ target: { value } }) => editPeriod({ startDay: value })}
                      classes={{ root: classes.shortRoot, select: classes.select }}
                      IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                    >
                      {weekdays.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </ScheduledActionSelect>
                    <ScheduledActionNumberFormat
                      disabled={isEditMode}
                      value={startTime}
                      format={format24H}
                      placeholder="HH:MM"
                      thousandSeparator
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={({ formattedValue }) => editPeriod({ startTime: formattedValue })}
                      // Mimic the style of the MUI `TextInput`
                      style={{
                        width: '67px'
                      }}
                    />
                    {timeZoneString} to &nbsp;
                    <ScheduledActionSelect
                      disabled={isEditMode}
                      disabledText={weekdays.find((weekday) => weekday === selectedPeriod.endDay)}
                      variant="standard"
                      style={{ marginRight: 20 }}
                      value={selectedPeriod.endDay}
                      onChange={({ target: { value } }) => editPeriod({ endDay: value })}
                      classes={{ root: classes.shortRoot, select: classes.select }}
                      IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                    >
                      {weekdays.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </ScheduledActionSelect>
                    <ScheduledActionNumberFormat
                      disabled={isEditMode}
                      value={endTime}
                      format={format24H}
                      placeholder="HH:MM"
                      // mask={['0', '0', '0', '0']}
                      thousandSeparator
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={({ formattedValue }) => editPeriod({ endTime: formattedValue })}
                      // Mimic the style of the MUI `TextInput`
                      style={{
                        width: '67px'
                      }}
                    />
                    {timeZoneString}
                  </div>
                )))}
            {selectedAction.name === 'bid' &&
              ((selectedPeriod.name === 'hoursInDay' && actionType === 'timeBase' && (
                <div className="setting_row--flex-container">
                  <ScheduledActionSelect
                    variant="standard"
                    style={{ marginRight: 5 }}
                    disabled={isEditMode}
                    disabledText={selectedActionBidDisplayName}
                    value={selectedAction.method}
                    onChange={({ target: { value } }) => editAction({ method: value })}
                    classes={{ root: classes.actionType, select: classes.select }}
                    IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                  >
                    {bidMethods.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.displayName}
                      </MenuItem>
                    ))}
                  </ScheduledActionSelect>
                  &nbsp;
                  <ScheduledActionNumberFormat
                    value={selectedAction.value}
                    thousandSeparator
                    prefix={CHANGE_PERCENT_OPTIONS.includes(selectedAction.method) ? '' : retailer.currencySymbol}
                    suffix={CHANGE_PERCENT_OPTIONS.includes(selectedAction.method) ? '%' : ''}
                    decimalScale={2}
                    fixedDecimalScale
                    allowNegative={false}
                    onValueChange={({ floatValue }) => editAction({ value: floatValue })}
                    // Mimic the style of the MUI `TextInput`
                    style={{
                      width: '70px'
                    }}
                  />
                  &nbsp; from &nbsp;
                  <ScheduledActionNumberFormat
                    value={startTime}
                    disabled={isEditMode}
                    format={format24H}
                    placeholder="HH:MM"
                    // mask={['0', '0', '0', '0']}
                    thousandSeparator
                    fixedDecimalScale
                    allowNegative={false}
                    onValueChange={({ formattedValue }) => editPeriod({ startTime: formattedValue })}
                    style={{
                      width: '67px'
                    }}
                  />
                  {timeZoneString} to &nbsp;
                  <ScheduledActionNumberFormat
                    value={endTime}
                    disabled={isEditMode}
                    format={format24H}
                    placeholder="HH:MM"
                    thousandSeparator
                    fixedDecimalScale
                    allowNegative={false}
                    onValueChange={({ formattedValue }) => editPeriod({ endTime: formattedValue })}
                    style={{
                      width: '67px'
                    }}
                  />
                  {timeZoneString}&nbsp; on&nbsp;
                  <ScheduledActionTextField
                    variant="standard"
                    disabled={isEditMode}
                    autoComplete="off"
                    classes={{ root: classes.longRoot }}
                    InputProps={{ classes: { root: classes.longRoot } }}
                    value={formatWeekDaysSelected(weekDaysMapping)}
                    id="outlined-bare"
                    onClick={(event) => openWeekDaysPickerWithTarget(event.currentTarget)}
                    margin="normal"
                  />
                  <Popover
                    open={Boolean(weekDaysPickerOpen)}
                    anchorEl={weekDaysPickerOpen}
                    onClose={() => setWeekDaysPickerOpen(null)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                  >
                    <div className="platform_drowdown">
                      <MenuItem onClick={toggleAllWeekDays}>
                        <Checkbox color="primary" checked={!notAllDaysSelect} style={{ marginLeft: -9 }} />
                        All Days
                      </MenuItem>
                      {weekdays.map((item, idx) => {
                        return (
                          <MenuItem key={idx} onClick={() => toggleWeekDays(idx)}>
                            <Checkbox color="primary" checked={weekDaysMapping[idx]} style={{ marginLeft: -9 }} />
                            {item}
                          </MenuItem>
                        );
                      })}
                    </div>
                  </Popover>
                </div>
              )) ||
                (selectedPeriod.name === 'daysOfTheWeek' && (
                  <div className="setting_row--flex-container">
                    <ScheduledActionSelect
                      variant="standard"
                      style={{ marginRight: 5 }}
                      disabled={isEditMode}
                      disabledText={selectedActionBidDisplayName}
                      value={selectedAction.method}
                      onChange={({ target: { value } }) => editAction({ method: value })}
                      classes={{ root: classes.actionType, select: classes.select }}
                      IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                    >
                      {bidMethods.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.displayName}
                        </MenuItem>
                      ))}
                    </ScheduledActionSelect>
                    &nbsp;
                    <ScheduledActionNumberFormat
                      value={selectedAction.value}
                      thousandSeparator
                      prefix={
                        selectedAction.method && (CHANGE_PERCENT_OPTIONS as string[]).includes(selectedAction.method)
                          ? ''
                          : retailer.currencySymbol
                      }
                      suffix={
                        selectedAction.method && (CHANGE_PERCENT_OPTIONS as string[]).includes(selectedAction.method)
                          ? '%'
                          : ''
                      }
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={({ floatValue }) => editAction({ value: floatValue })}
                      // Mimic the style of the MUI `TextInput`
                      style={{
                        width: '70px'
                      }}
                    />
                    &nbsp;
                    <ScheduledActionSelect
                      disabled={isEditMode}
                      disabledText={weekdays.find((weekday) => weekday === selectedPeriod.startDay)}
                      variant="standard"
                      style={{ marginRight: 10 }}
                      value={selectedPeriod.startDay}
                      onChange={({ target: { value } }) => editPeriod({ startDay: value })}
                      classes={{ root: classes.shortRoot, select: classes.select }}
                      IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                    >
                      {weekdays.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </ScheduledActionSelect>
                    <ScheduledActionNumberFormat
                      disabled={isEditMode}
                      value={startTime}
                      format={format24H}
                      placeholder="HH:MM"
                      thousandSeparator
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={({ formattedValue }) => editPeriod({ startTime: formattedValue })}
                      // Mimic the style of the MUI `TextInput`
                      style={{
                        width: '67px'
                      }}
                    />
                    {timeZoneString} to &nbsp;
                    <ScheduledActionSelect
                      disabled={isEditMode}
                      disabledText={weekdays.find((weekday) => weekday === selectedPeriod.endDay)}
                      variant="standard"
                      style={{ marginRight: 20 }}
                      value={selectedPeriod.endDay}
                      onChange={({ target: { value } }) => editPeriod({ endDay: value })}
                      classes={{ root: classes.shortRoot, select: classes.select }}
                      IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                    >
                      {weekdays.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </ScheduledActionSelect>
                    <ScheduledActionNumberFormat
                      disabled={isEditMode}
                      value={endTime}
                      format={format24H}
                      placeholder="HH:MM"
                      // mask={['0', '0', '0', '0']}
                      thousandSeparator
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={({ formattedValue }) => editPeriod({ endTime: formattedValue })}
                      // Mimic the style of the MUI `TextInput`
                      style={{
                        width: '67px'
                      }}
                    />
                    {timeZoneString}
                  </div>
                )) ||
                ((selectedPeriod.name === 'dateOrDateRange' || actionType === 'metricBase') && (
                  <div className="setting_row--flex-container">
                    <ScheduledActionSelect
                      variant="standard"
                      disabled={isEditMode}
                      disabledText={selectedActionBidDisplayName}
                      style={{ marginRight: 5 }}
                      value={selectedAction.method}
                      onChange={({ target: { value } }) => {
                        editAction({ method: value });
                      }}
                      classes={{ root: classes.actionType, select: classes.select }}
                      IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon_in_content" />}
                    >
                      {bidMethods.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.displayName}
                        </MenuItem>
                      ))}
                    </ScheduledActionSelect>
                    &nbsp;
                    <ScheduledActionNumberFormat
                      value={selectedAction.value}
                      thousandSeparator
                      prefix={
                        selectedAction.method && (CHANGE_PERCENT_OPTIONS as string[]).includes(selectedAction.method)
                          ? ''
                          : retailer.currencySymbol
                      }
                      suffix={
                        selectedAction.method && (CHANGE_PERCENT_OPTIONS as string[]).includes(selectedAction.method)
                          ? '%'
                          : ''
                      }
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={({ floatValue }) => editAction({ value: floatValue })}
                      // Mimic the style of the MUI `TextInput`
                      style={{
                        width: '70px'
                      }}
                    />
                    {/* {selectedAction.method !== ChangeAmountOptions.SetAmount && (
                      <>
                        &nbsp; up to &nbsp;
                        <ScheduledActionNumberFormat
                          className="number-formatter"
                          value={valueLimit}
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          allowNegative={false}
                          placeholder="optional"
                          prefix={retailer.currencySymbol}
                          disabled={isEditMode}
                          onValueChange={({ floatValue }) => setValueLimit(floatValue)}
                          style={{
                            width: '100px'
                          }}
                        />
                      </>
                    )} */}
                    {/* Metric Bid Days */}
                    {actionType === 'metricBase' && (
                      <>
                        {' '}
                        on{' '}
                        <ScheduledActionTextField
                          variant="standard"
                          autoComplete="off"
                          classes={{ root: classes.longRoot }}
                          InputProps={{ classes: { root: classes.longRoot } }}
                          value={formatWeekDaysSelected(weekDaysMapping)}
                          id="outlined-bare"
                          onClick={(event) => openWeekDaysPickerWithTarget(event.currentTarget)}
                          margin="normal"
                        />
                        <Popover
                          open={Boolean(weekDaysPickerOpen)}
                          anchorEl={weekDaysPickerOpen}
                          onClose={() => setWeekDaysPickerOpen(null)}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                        >
                          <div className="platform_drowdown">
                            <MenuItem onClick={toggleAllWeekDays}>
                              <Checkbox color="primary" checked={!notAllDaysSelect} style={{ marginLeft: -9 }} />
                              All Days
                            </MenuItem>
                            {weekdays.map((item, idx) => {
                              return (
                                <MenuItem key={idx} onClick={() => toggleWeekDays(idx)}>
                                  <Checkbox
                                    color="primary"
                                    checked={weekDaysMapping && weekDaysMapping[idx]}
                                    style={{ marginLeft: -9 }}
                                  />
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </div>
                        </Popover>
                      </>
                    )}
                  </div>
                )))}
          </div>
        </div>
        {showErrorMessageForHours() && (
          <p style={{ color: colors.red }}>
            Invalid hour range. End time must be at least 5 minutes after the start time.
          </p>
        )}
        <div className="setting_row">
          <div className="label">Schedule:</div>
          {(selectedPeriod.name || selectedMetric.level) && (
            <div className="setting_row--flex-container" style={{ position: 'relative' }}>
              <div className="datepicker_container">
                {`Starts on `}&nbsp;
                <ScheduledActionTextField
                  variant="standard"
                  disabled={disabledStartDate || isEditMode}
                  autoComplete="off"
                  classes={{ root: classes.dateRoot }}
                  InputProps={{ classes: { root: classes.dateRoot } }}
                  value={currentStartDate ? moment(currentStartDate).format('MM/DD/YYYY') : 'No Start Date'}
                  id="outlined-bare"
                  onClick={() =>
                    disabledStartDate || isEditMode ? null : setStartDatePickerOpen(!startDatePickerOpen)
                  }
                  margin="normal"
                />
                {startDatePickerOpen && (
                  <div style={{ position: 'absolute', zIndex: 1 }}>
                    <DayPickerSingleDateController
                      hideKeyboardShortcutsPanel
                      monthFormat="MMMM YYYY"
                      date={currentStartDate} // momentPropTypes.momentObj or null
                      onDateChange={(date) => {
                        if (actionType === 'timeBase') {
                          editPeriod({ startDate: date ? date.startOf('day') : null });
                        } else {
                          editMetric({ startDate: date ? date.startOf('day') : null });
                        }
                      }} // PropTypes.func.isRequired
                      focused
                      isOutsideRange={startDateLimit}
                      onFocusChange={() => {}} // PropTypes.func.isRequired
                      onOutsideClick={() => setStartDatePickerOpen(!startDatePickerOpen)}
                      id="adPortfolioSettingStartDatePicker"
                    />
                  </div>
                )}
              </div>

              <div className="datepicker_container">
                and ends on &nbsp;
                <TextField
                  variant="standard"
                  autoComplete="off"
                  classes={{ root: classes.dateRoot }}
                  InputProps={{ classes: { root: classes.dateRoot } }}
                  value={
                    moment(currentEndDate).format('MM/DD/YYYY') !== MOMENT_NEVER_END_DATE.format('MM/DD/YYYY')
                      ? moment(currentEndDate).format('MM/DD/YYYY')
                      : 'No End Date'
                  }
                  id="outlined-bare"
                  onClick={() => setEndDatePickerOpen(!endDatePickerOpen)}
                  margin="normal"
                />
                {endDatePickerOpen && (
                  <div style={{ position: 'absolute', zIndex: 1 }}>
                    {' '}
                    <DayPickerSingleDateController
                      hideKeyboardShortcutsPanel
                      monthFormat="MMMM YYYY"
                      date={
                        currentEndDate === MOMENT_NEVER_END_DATE || currentEndDate === NEVER_END_DATE
                          ? null
                          : currentEndDate
                      } // momentPropTypes.momentObj or null
                      onDateChange={(date) => {
                        if (actionType === 'timeBase') {
                          editPeriod({ endDate: date ? date.endOf('day') : MOMENT_NEVER_END_DATE });
                        } else {
                          editMetric({ endDate: date ? date.endOf('day') : MOMENT_NEVER_END_DATE });
                        }
                      }} // PropTypes.func.isRequired
                      focused
                      onFocusChange={() => {}} // PropTypes.func.isRequired
                      isOutsideRange={endDateLimit}
                      onOutsideClick={() => setEndDatePickerOpen(!endDatePickerOpen)}
                      id="adCampaignSettingEndDatePicker"
                      renderCalendarInfo={() => (
                        <CalendarClearButton
                          hasEndDate={!!currentEndDate}
                          handleClear={() => {
                            if (actionType === 'timeBase') {
                              editPeriod({ endDate: MOMENT_NEVER_END_DATE });
                            } else {
                              editMetric({ endDate: MOMENT_NEVER_END_DATE });
                            }
                            setEndDatePickerOpen(!endDatePickerOpen);
                          }}
                        />
                      )}
                    />{' '}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {isSARunning && <p style={{ color: colors.red }}>{DISABLE_SAVE_MESSAGE}</p>}
      </div>
      <div>
        <Widget app={app} {...props} noInnerContainer widget={gridClone} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['app', 'retailer']),
  dataSet: state.entitySearchService.scheduledActionsCreateGrid,
  selectedMapping: state.entitySearchService.scheduledActionsCreateGrid_selectedMapping
});

const WrappedCreate = withRouter(connect(mapStateToProps)(withBus('eventBus')(withStyles(muiStyles)(Create))));
export default WrappedCreate;
