import { RetailSalesDatum, RetailSalesResponseAdditionalValues } from './types';
import { UseQueryResult } from 'react-query';
import { useMetricDataByWeekId } from './useMetricDataByWeekId';

interface UseBeaconSalesDataByWeekIdProps {
  requestId?: string;
  startWeekId: number;
  endWeekId: number;
}

/**
 * Gets metrics for retail sales and units sold by week ID.
 */
export const useBeaconSalesDataByWeekId = ({
  startWeekId,
  endWeekId,
  requestId = 'retailSalesByweekId_sales_weekId'
}: UseBeaconSalesDataByWeekIdProps): UseQueryResult<RetailSalesDatum[]> => {
  return useMetricDataByWeekId<RetailSalesResponseAdditionalValues, RetailSalesDatum>({
    endWeekId,
    startWeekId,
    fields: [
      {
        name: 'retailSales'
      },
      {
        name: 'unitsSold'
      },
      {
        name: 'retailPrice'
      },
      {
        name: 'wholesaleSales'
      },
      {
        name: 'retailerGrossMargin'
      },
      {
        name: 'brandGrossMargin'
      },
      {
        name: 'brandGrossMarginPercent'
      },
      {
        name: 'inStockWeighted'
      },
      {
        name: 'instockRate'
      }
    ],
    indexName: 'sales',
    requestId
  });
};
