import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import entitySearchServiceActions from 'src/store/modules/entitySearchService/actions';

/**
 * Memoized callback for selecting all the rows in a
 * scheduled action table after the rows have been loaded.
 */
const useSelectScheduledActionRows = ({
  selectAll,
  statePropertyName
}: {
  selectAll: boolean;
  statePropertyName: string;
}) => {
  const dispatch = useDispatch();
  return useCallback(
    async ({ gridResult }) => {
      if (selectAll && gridResult.data) {
        const allIds = gridResult.data.map((item) => item.id);
        await dispatch(
          entitySearchServiceActions.receiveEntitySalesMetrics(`${statePropertyName}_selectedMapping`, allIds)
        );
      }
    },
    [selectAll, statePropertyName, dispatch]
  );
};

export default useSelectScheduledActionRows;
