import { Location } from 'history';
import { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { Widget } from 'src/types/application/widgetTypes';
import { AggregationField } from 'src/types/application/types';
import useFetchAndUpdateTotals from './useFetchAndUpdateTotals';
import useAdvancedSearchArgs from './useAdvancedSearchArgs';
import useSrgFetchEntityMetrics, { FetchDataFn, MetadataRowOptions, AmsMetadataType } from './useSrgFetchEntityMetrics';
import { SrgApi, SearchResultsGridV3Props } from '../SearchResultsGridV3';
import usePagination from './usePagination';
import { FormData } from 'src/utils/segments';
import { FetchAdditionalDataFn } from './types';
import useFetchEntityMetricsArgs from './useFetchEntityMetricsArgs';
import { SearchGridConstants } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConstants';
import { useAppSelector } from 'src/utils/Hooks';
import { useDispatch, useSelector } from 'react-redux';
import entitySearchServiceActions from 'src/store/modules/entitySearchService/actions';
import { receiveEntitySalesMetrics } from 'src/store/modules/entitySearchService/operations';
import _get from 'lodash/get';
import { isAdAuditUserSelector } from 'src/store/modules/user/selectors';
import { TermFilter } from 'sl-api-connector';
import { buildMainEntityConditions } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { ModifyESQueryAction } from 'src/components/AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';
import { getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';
import { shouldShowCriteo } from 'src/utils/app';

type UseSrgDataReturn<T> = Pick<
  SearchResultsGridV3Props<T>,
  'data' | 'dataTotalRow' | 'totalItems' | 'onReady' | 'loading' | 'onSelectChanged' | 'selectedItemIds'
> & {
  gridApi: SrgApi;
  pageNumber: number;
  sortFieldName: string;
  sortDirection: string;
  apiRequest: any[];
  formData: FormData;
  onResetData: () => void;
  /** True if the user should be able to toggle the status.
   * Will be false for Pulse users and on the search page.
   */
  canEdit: boolean;
  disabled: boolean;
};

export interface UseSrgDataArgs {
  location: Location;
  widget: Widget;
  fetchEntityData: FetchAdditionalDataFn;
  /**
   * Optional callback to compute the total result count
   * if not relying on totalResultCount from ESS
   */
  computeTotalResultCount?: ({ result }) => number;
  additionalTermFilters?: TermFilter[];
  /** Optional callback for when the data has been fetched */
  onFetchedData?: ({ gridResult }) => void | Promise<void>;
  metadataRowOptions?: MetadataRowOptions;
  fetchTotalRowCountAmsMetadataType?: AmsMetadataType;
  importantOverrides?: ModifyESQueryAction[];
}

/**
 * Comprehensive hook for passing the required props to SearchResultsGridV3.
 * It handles fetching the rows to be displayed for the current entity.
 * `fetchEntityData` is a function that should be given to define how to fetch
 * data for the currently selected entity.
 */
const useSrgData = <T>({
  location,
  widget,
  fetchEntityData,
  computeTotalResultCount,
  additionalTermFilters,
  onFetchedData,
  metadataRowOptions,
  fetchTotalRowCountAmsMetadataType,
  importantOverrides
}: UseSrgDataArgs): UseSrgDataReturn<T> => {
  const [fetchedFirstPage, setFetchedFirstPage] = useState(false);
  const [gridApi, setGridApi] = useState<SrgApi | null>(null);
  const gridApiRef = useRef<SrgApi>(null);
  const { page: pageNumber, sortDirection, sortField: sortFieldName } = usePagination(gridApi);
  const groupByField: AggregationField = useMemo(() => widget.data.groupByFields[0], [widget.data.groupByFields]);
  const configForGroupByField = useMemo(
    () => widget.data.configByGroupByFieldName[groupByField.name],
    [groupByField.name, widget.data.configByGroupByFieldName]
  );
  const indexName: string = useMemo(() => configForGroupByField.indexName, [configForGroupByField.indexName]);
  const { entity, retailer, app } = useFetchEntityMetricsArgs();
  const dispatch = useDispatch();

  const selectedItemIds = useAppSelector((state) =>
    _get(state, ['entitySearchService', `${widget.data.statePropertyName}_selectedMapping`], [])
  ) as string[];
  const fullDataSet = useAppSelector((state) =>
    _get(state, ['entitySearchService', widget.data.statePropertyName, 'fullDataSet'], [])
  );
  const totalResultCount = useAppSelector((state) =>
    _get(state, ['entitySearchService', widget.data.statePropertyName, 'totalResultCount'], null)
  );
  const isAdAuditUser = useSelector(isAdAuditUserSelector);
  const isReadOnlyCriteo = shouldShowCriteo() && getParentPlatform() === PARENT_PLATFORMS.CRITEO;

  const canEdit = useMemo(() => {
    const isSearchPage = location.pathname === '/search';
    return !isAdAuditUser && !isSearchPage;
  }, [location.pathname, isAdAuditUser]);

  const disabled = useMemo(() => {
    return !isReadOnlyCriteo;
  }, [isReadOnlyCriteo]);

  // Keep a grid api ref so that we can access the grid API in
  // useEffects without having to put the gridApi as a dependency,
  // otherwise we will have an infinite render loop when updating
  // pagination
  useEffect(() => {
    gridApiRef.current = gridApi;
  }, [gridApi]);

  const {
    apiRequest,
    searchRequestOverrides,
    formData,
    searchSideBarConditions,
    aggregationFilters,
    onFirstPageAndCount,
    mainTimePeriodRangeFilters,
    aggregationFieldsForResultCount,
    searchBarConditionsForAdvancedSearch
  } = useAdvancedSearchArgs({
    widget,
    location,
    pageNumber,
    sortDirection,
    sortFieldName,
    additionalTermFilters: additionalTermFilters || [],
    importantOverrides
  });

  const { loading: totalLoading, totalRow } = useFetchAndUpdateTotals({
    searchRequestOverrides,
    indexName,
    widget,
    onFirstPage: onFirstPageAndCount && !fetchedFirstPage,
    importantOverrides
  });

  const fetchData: FetchDataFn = useCallback(
    ({ customizeFetchMetricsParams } = {}) => {
      const fetchMetricsParams = {
        app,
        indexName,
        mainEntity: entity,
        pageSizeOverride: SearchGridConstants.AD_SEARCH_PAGINATION_SIZE,
        retailer,
        searchRequestOverrides,
        statePropertyName: widget.data.statePropertyName,
        widget
      };

      return fetchEntityData({
        aggregationFieldsForResultCount,
        aggregationFilters,
        currentSortFieldName: sortFieldName,
        groupByField,
        indexName,
        location,
        mainTimePeriodRangeFilters,
        onFirstPageAndCount,
        pageNumber,
        searchSideBarConditions,
        sortDirection,
        widget,
        dataSet: {
          fullDataSet,
          totalResultCount
        },
        searchRequestOverrides,
        fetchMetricsParams: customizeFetchMetricsParams
          ? customizeFetchMetricsParams(fetchMetricsParams)
          : fetchMetricsParams,
        additionalTermFilters: additionalTermFilters || [],
        searchBarConditionsForAdvancedSearch,
        importantOverrides
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      aggregationFieldsForResultCount,
      aggregationFilters,
      fetchEntityData,
      indexName,
      location,
      mainTimePeriodRangeFilters,
      onFirstPageAndCount,
      pageNumber,
      searchRequestOverrides,
      searchSideBarConditions,
      sortDirection,
      sortFieldName,
      app,
      entity,
      retailer
    ]
  );

  const { rowData, loading: fetchEntityLoading } = useSrgFetchEntityMetrics({
    indexName,
    searchRequestOverrides,
    fetchData,
    pageNumber,
    computeTotalResultCount,
    setFetchedFirstPage,
    widget,
    onFetchedData,
    metadataRowOptions,
    apiRequest,
    fetchTotalRowCountAmsMetadataType,
    importantOverrides
  });

  // Clear redux state since we need to fetch a new batch of data.
  // If this is called, it means page has been reset to 1 due to
  // form data or sorting changing
  const onResetData = useCallback(() => {
    dispatch(entitySearchServiceActions.deleteKey(widget.data.statePropertyName));
    dispatch(entitySearchServiceActions.deleteKey(`${widget.data.statePropertyName}_total`));
    dispatch(
      entitySearchServiceActions.receiveEntitySalesMetrics(`${widget.data.statePropertyName}_selectedMapping`, [])
    );
    setFetchedFirstPage(false);
  }, [dispatch, widget.data.statePropertyName]);

  // SRG action bar still relies on Redux to know what items are selected.
  // We keep this in this hook to keep the logic separate from SRG UI logic
  const onSelectChanged = useCallback(
    (selectedIds: string[]) => {
      dispatch(receiveEntitySalesMetrics(`${widget.data.statePropertyName}_selectedMapping`, selectedIds));
    },
    [dispatch, widget.data.statePropertyName]
  );

  return {
    dataTotalRow: _get(totalRow, 'data', null),
    data: rowData || null,
    totalItems: totalResultCount,
    loading: totalLoading || fetchEntityLoading,
    onReady: setGridApi,
    gridApi,
    pageNumber,
    sortFieldName,
    sortDirection,
    apiRequest: [
      {
        ..._get(apiRequest, 0),
        conditions: {
          ..._get(apiRequest, [0, 'conditions']),
          ...buildMainEntityConditions({ termFilters: [], rangeFilters: [] }, entity, app, retailer)
        }
      }
    ],
    formData,
    onResetData,
    onSelectChanged,
    selectedItemIds,
    canEdit,
    disabled
  };
};

export default useSrgData;
