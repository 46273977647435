/**
 * Promotion metric options for the promotion metric cards and column chart options
 */
export enum PromotionMetricOptions {
  PROMOTION_COUNT = 'promoCount',
  AVG_DISCOUNT = 'averagePromotionDiscount',
  RETAIL_SALES = 'shareOfRetailSales',
  UNITS_SOLD = 'shareOfUnitsSold'
}

/**
 * Mapping of promotion metric options to the field name in the request for the promotion metric cards
 */
export const promotionMetricToFieldName = {
  [PromotionMetricOptions.PROMOTION_COUNT]: 'stacklineSku',
  [PromotionMetricOptions.AVG_DISCOUNT]: 'averagePromotionDiscount',
  [PromotionMetricOptions.RETAIL_SALES]: 'shareOfRetailSales',
  [PromotionMetricOptions.UNITS_SOLD]: 'shareOfUnitsSold'
};

/**
 * Mapping of metric id to advanced search key to access the data from the response
 */
export const metricIdToAdvancedSearchKey = {
  [PromotionMetricOptions.PROMOTION_COUNT]: 'weekId_avg_count',
  [PromotionMetricOptions.AVG_DISCOUNT]: 'averagePromotionDiscount_computed_value',
  [PromotionMetricOptions.RETAIL_SALES]: 'shareOfRetailSales_computed_value',
  [PromotionMetricOptions.UNITS_SOLD]: 'shareOfUnitsSold_computed_value'
};

/**
 * Query param key for the promotion metric cards
 */
export const PROMOTION_QUERY_PARAM = 'promotionMetric';

export const promotionTypeToDisplayName = {
  coupon: 'Coupon',
  best_deal: 'Best Deal',
  prime_day_deal: 'Prime Day Deal',
  cyber_monday_deal: 'Cyber Monday Deal',
  top_deal: 'Top Deal',
  prime_member: 'Prime Member',
  black_friday_deal: 'Black Friday Deal',
  deal_of_the_day: 'Deal of the Day',
  lightning_deal: 'Lightning Deal'
};
