export interface IsActiveElement {
  name: string;
  isActive: boolean;
}

export interface RetailerInformation {
  subscribedCategoryIds: number[];
}

export interface CustomTimePeriod {
  fromWeek: number;
  toWeek: number;
}
export interface TimePeriod {
  periodType: number;
  comparisionTimePeriod: number;
  customPeriod: CustomTimePeriod;
}
export interface Range {
  rangeBegin: string;
  rangeEnd: string;
}
export interface Filter {
  popUnitsSoldChangePct?: Range;
  unitsSold?: Range;
  popRetailSalesChangePct?: Range;
  marketShare?: Range;
  marketShareChange?: Range;
  trafficShare?: Range;
  conversion?: Range;
  avgRating?: Range;
  includeIds?: (string | number)[];
  retailSalesRange?: Range;
}
export interface ApiCallBody {
  shouldDisplayBrand: boolean;
  shouldDisplayDepartment: boolean;
  shouldDisplayCategory: boolean;
  shouldDisplaySubCategory: boolean;
  shouldDisplayManufacturer: boolean;
  retailerId: number;
  clientInformation: RetailerInformation;
  currentPeriod: TimePeriod;
  pastPeriod: TimePeriod;
  categoryFilter: Filter | null;
  departmentFilter: Filter | null;
  subCategoryFilter: Filter | null;
  brandFilter: Filter | null;
  manufacturerFilter: Filter | null;
  brandFilterAtGranularity: null | number;
  manufacturerFilterAtGranularity: null | number;
  resultsCursor: null;
  resultsCountAtLowestGranularity: number;
}

export const initialApiBody = {
  shouldDisplayBrand: false,
  shouldDisplayDepartment: false,
  shouldDisplayCategory: false,
  shouldDisplaySubCategory: false,
  shouldDisplayManufacturer: false,
  retailerId: 0,
  clientInformation: { subscribedCategoryIds: [] },
  currentPeriod: { periodType: 0, comparisionTimePeriod: 0, customPeriod: { fromWeek: 0, toWeek: 0 } },
  pastPeriod: { periodType: 0, comparisionTimePeriod: 0, customPeriod: { fromWeek: 0, toWeek: 0 } },
  categoryFilter: null,
  departmentFilter: null,
  subCategoryFilter: null,
  brandFilter: null,
  manufacturerFilter: null,
  brandFilterAtGranularity: null,
  manufacturerFilterAtGranularity: null,
  resultsCursor: null,
  resultsCountAtLowestGranularity: 0
};
