import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useStacklineTheme } from '@stackline/ui';
import {
  useMetricFormatter,
  useComparisonPeriod,
  ComparisonPeriod,
  useWeekRange,
  WeekRange,
  useQueryParamValue
} from 'src/utils/Hooks';
import ReactDOMServer from 'react-dom/server';
import HoverTooltip from '../HoverTooltip/HoverTooltip';
import { ValueOf } from 'sl-api-connector';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { calculatePercentChange } from 'src/utils/app';
import { getStartAndEndOfWeek, timestampToWeekId } from 'src/utils/dateUtils';
import moment from 'moment-timezone';
import _merge from 'lodash/merge';
import _get from 'lodash/get';

type SplineChartHighchartsOptions = Omit<Highcharts.Options, 'series'> & {
  series: Partial<Highcharts.SeriesOptionsType>[];
};

export type SplineChartOptionsOverride = Partial<SplineChartHighchartsOptions>;

export interface SplineChartProps {
  primaryData: number[][];
  secondaryData?: number[][];
  /**
   * Optional series to display with the primary
   * data as a forecast, which will appear as a
   * dashed line.
   */
  forecastedData?: number[][];
  metricType: ValueOf<typeof METRICTYPE>;
  preview?: 'large' | 'small' | { width: number; height: number };
  width?: number | string;
  chartOptionsOverride?: SplineChartOptionsOverride;
  removeSpacing?: boolean;
  /**
   * By default we use some logic to filter which points should appear
   * in the tooltip. This is useful for when you want to override that
   * logic, e.g. to show all points.
   */
  buildPointsForTooltip?: (
    points: Highcharts.TooltipFormatterContextObject[]
  ) => Highcharts.TooltipFormatterContextObject[];
}

export const getFormattedMetricDateLabel = (weekId: number, priorYear?: boolean): string => {
  const { endDate } = getStartAndEndOfWeek(weekId);
  const weekDate = moment(endDate, 'YYYYMMDD');

  if (priorYear) {
    return weekDate.subtract(1, 'year').day('Saturday').format('M/D');
  }

  return weekDate.format('M/D');
};

/**
 * Generic container for spline charts.
 */
const SplineChart = ({
  primaryData,
  secondaryData = [],
  forecastedData,
  metricType,
  preview,
  chartOptionsOverride,
  width,
  removeSpacing = false,
  buildPointsForTooltip
}: SplineChartProps) => {
  const theme = useStacklineTheme();
  const formatMetric = useMetricFormatter();
  const comparisonPeriod = useComparisonPeriod();
  const weekRange = useWeekRange();
  const tab = useQueryParamValue('tab');

  const chartDimensionsMap = useMemo(
    () => ({
      large: {
        width: width || 299,
        height: 85 // top card
      },
      small: {
        width: width || 178,
        height: 85 // middle card
      },
      custom: typeof preview === 'object' ? preview : undefined
    }),
    [width, preview]
  );

  const chartMin = useMemo(() => {
    const allYValues = [
      ...primaryData.map((row) => row[1]),
      ...(secondaryData || []).map((row) => row[1]),
      ...(forecastedData || []).map((row) => row[1])
    ];
    return Math.min(...allYValues);
  }, [forecastedData, primaryData, secondaryData]);

  // Special case, when comparing last week with prior year we want
  // to show the graph for YTD but the legend should just be last week
  const isLastWeekWithPriorYear = useMemo(() => {
    return tab !== 'forecasts' && weekRange === WeekRange.LAST_WEEK && comparisonPeriod === ComparisonPeriod.PRIOR_YEAR;
  }, [comparisonPeriod, weekRange, tab]);

  const isLastWeekWithPriorPeriod = useMemo(() => {
    return (
      tab !== 'forecasts' && weekRange === WeekRange.LAST_WEEK && comparisonPeriod === ComparisonPeriod.PRIOR_PERIOD
    );
  }, [comparisonPeriod, weekRange, tab]);

  const textStyles: Highcharts.CSSObject = {
    fontFamily: theme.text.fontFamily,
    color: theme.colors.primary,
    fontSize: '12px'
  };

  const seriesMarker = {
    enabled: false,
    symbol: 'circle',
    states: {
      hover: {
        radius: 4,
        lineWidth: 0
      }
    }
  };

  const previewOptions: Partial<Highcharts.Options> = {
    chart: {
      ...(chartDimensionsMap[typeof preview === 'object' ? 'custom' : preview] || {}),
      marginBottom: 10,
      backgroundColor: 'transparent'
    },
    xAxis: {
      visible: false,
      height: 0
    },
    yAxis: [
      {
        visible: false
      }
    ],
    plotOptions: {
      series: {
        lineWidth: 1.5
      }
    }
  };

  const options: Highcharts.Options = _merge(
    {
      chart: {
        type: 'spline',
        ...(removeSpacing ? { spacing: [0, 0, 0, 0] } : {}),
        spacingLeft: 0
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        shared: true,
        shadow: false,
        hideDelay: 0, //
        outside: true,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        useHTML: true,
        formatter() {
          const atForecastIntersection =
            this.points[0].series.index === 0 && this.points[1] && this.points[1].series.index === 1;
          const atPriorPeriodIntersection =
            comparisonPeriod === ComparisonPeriod.PRIOR_PERIOD && this.points.length > 1;

          const [primaryPoint, secondaryPoint] = buildPointsForTooltip
            ? buildPointsForTooltip(this.points)
            : this.points.slice(
                atForecastIntersection || atPriorPeriodIntersection ? 1 : 0 // Remove forecast point (first point) if we're looking at intersection of all three, because forecast and actual data are connected
              );

          const percentChange = secondaryPoint ? calculatePercentChange(primaryPoint.y, secondaryPoint.y) : undefined;
          const weekId = timestampToWeekId(primaryPoint.x);

          return ReactDOMServer.renderToStaticMarkup(
            <HoverTooltip
              theme={theme}
              title={`Week ${weekId % 100}`}
              primaryColor={(primaryPoint.color as string) || theme.colors.primary}
              primaryMetric={`${formatMetric(primaryPoint.y, metricType, {
                decimalPlaces: 2,
                showFullValue: metricType === METRICTYPE.PERCENT,
                showNegative: true
              })}`}
              primaryMetricLabel={getFormattedMetricDateLabel(
                weekId,
                primaryPoint.series.index === 2 && comparisonPeriod === ComparisonPeriod.PRIOR_YEAR // the secondary series will always have index 2, subtract a year if comparing against prior year
              )}
              change={
                percentChange !== undefined
                  ? `${formatMetric(Math.abs(percentChange), METRICTYPE.PERCENT, { decimalPlaces: 2 })}`
                  : undefined
              } // get rid of negative sign if there is one
              rawChange={percentChange}
              secondaryMetric={
                secondaryPoint
                  ? `${formatMetric(secondaryPoint.y, metricType, {
                      decimalPlaces: 2,
                      showFullValue: metricType === METRICTYPE.PERCENT,
                      showNegative: true
                    })}`
                  : ''
              }
              secondaryMetricLabel={getFormattedMetricDateLabel(
                weekId,
                comparisonPeriod === ComparisonPeriod.PRIOR_YEAR
              )}
              secondaryColor={secondaryPoint ? (secondaryPoint.color as string) : theme.colors.primaryLight}
            />
          );
        }
      },
      xAxis: {
        tickLength: 0,
        lineColor: 'transparent',
        labels: {
          style: textStyles,
          rotation: 0,
          formatter() {
            return Highcharts.dateFormat('%b %e', this.value);
          }
        }
      },
      yAxis: Array.from({ length: 3 }) // 3 for forecast, primary, and secondary
        .map(() => ({
          gridLineWidth: 0,
          title: {
            text: ''
          },
          labels: {
            formatter() {
              return `${formatMetric(this.value, metricType, {
                decimalPlaces: 2,
                showFullValue: false,
                showNegative: true
              })}`;
            },
            style: textStyles
          },
          min: Math.min(0, chartMin)
        }))
        .map((axis, index) => _merge(axis, _get(chartOptionsOverride, ['yAxis', index], {}))),
      series: [
        {
          type: 'spline',
          data: forecastedData || [],
          dashStyle: 'Dash',
          color: theme.colors.primary,
          marker: seriesMarker,
          enableMouseTracking: true,
          zIndex: 1
        },
        {
          type: 'spline',
          data: primaryData.map((row, index) => ({
            x: row[0],
            y: row[1],
            marker: {
              enabled: isLastWeekWithPriorPeriod || (isLastWeekWithPriorYear && index === primaryData.length - 1)
            }
          })),
          color: theme.colors.primary,
          marker: seriesMarker,
          zIndex: 1
        },
        {
          type: 'spline',
          data: secondaryData.map((row, index) => ({
            x: row[0],
            y: row[1],
            marker: {
              enabled: isLastWeekWithPriorPeriod || (isLastWeekWithPriorYear && index === primaryData.length - 1)
            }
          })),
          color: theme.colors.casper,
          marker: seriesMarker
        }
      ].map((series, index) => _merge(series, _get(chartOptionsOverride, ['series', index], {})))
    } as Highcharts.Options,
    preview ? previewOptions : {},
    chartOptionsOverride || {}
  );

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default SplineChart;
