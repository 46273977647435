/**
 * Used to identify the event that fires when the first metric dropdown option is selected New to Brand Over Time trend chart - Ex: Selecting "% of Ad Sales NTB"
 */
export const NTB_PRIMARY_OVERTIME_EVENT = 'newToBrandOverTimePrimaryEvent';

/**
 * Used to identify the event that fires when the second dropdown option (group-by) is selected in the New to Brand Over Time trend chart - Ex: Selecting "All Ads"
 */
export const NTB_SECONDARY_OVERTIME_EVENT = 'newToBrandOverTimeSecondaryEvent';

// Determine how we define DSP vs Search Ads, etc
export const ALL_AD_TYPES = ['dsp', 'sponsored_brands', 'sponsored_products', 'sponsored_display'];
export const DSP_AD_TYPES = ['dsp'];
export const SEARCH_AD_TYPES = ['sponsored_brands', 'sponsored_products', 'sponsored_display'];

// Allows for easy lookup of document keys when doing ratio calculations for certain metrics
/**
 * @param numeratorKey newToBrandAdSales_sum_value
 * @param denominatorKey adSales_sum_value
 */
export const AD_SALES_PERCENTAGE_VARIABLES = {
  numeratorKey: 'newToBrandAdSales_sum_value',
  denominatorKey: 'adSales_sum_value'
};

/**
 * @param numeratorKey newToBrandAdUnitsSold_sum_value
 * @param denominatorKey adUnitsSold_sum_value
 */
export const AD_UNITS_SOLD_PERCENTAGE_VARIABLES = {
  numeratorKey: 'newToBrandAdUnitsSold_sum_value',
  denominatorKey: 'adUnitsSold_sum_value'
};

/**
 * @param numeratorKey newToBrandOrders_sum_value
 * @param denominatorKey orders_sum_value
 */
export const CONVERSION_PERCENTAGE_VARIABLES = {
  numeratorKey: 'newToBrandOrders_sum_value',
  denominatorKey: 'orders_sum_value'
};

export const DATE_FORMAT = 'YYYY-MM-DD';
