import types from './types';

function updateFilters(conditions) {
  return {
    type: types.UPDATE_FILTERS,
    filters: conditions
  };
}

function clearFilters(conditions) {
  return {
    type: types.CLEAR_FILTERS,
    filters: conditions
  };
}

function setIsClearingFilters(bool) {
  return {
    type: types.UPDATE_IS_CLEARING_FILTERS_BOOL,
    bool
  };
}

export default {
  clearFilters,
  updateFilters,
  setIsClearingFilters
};
