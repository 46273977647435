import { useStacklineTheme, hexToRgba } from '@stackline/ui';
import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import mergeSx from 'src/utils/mergeSx';

export interface CompareOptionProps extends React.ComponentProps<typeof Flex> {
  label: React.ReactNode;
  selected?: boolean;
}

/**
 * Single option for comparing groups and metrics
 */
export default function CompareOption({ label, selected, sx, ...rest }: CompareOptionProps) {
  const theme = useStacklineTheme();

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      sx={mergeSx(
        {
          padding: theme.spacing.sm,
          cursor: 'pointer',
          ...(selected
            ? {
                backgroundColor: hexToRgba(theme.colors.primaryLight, 0.3)
              }
            : {
                '&:hover': {
                  backgroundColor: theme.colors.secondaryPorcelain
                }
              })
        },
        sx
      )}
      {...rest}
    >
      {typeof label === 'string' ? <Text variant="body2">{label}</Text> : label}
    </Flex>
  );
}
