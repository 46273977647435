import { Box } from '@mui/system';
import { StacklineTheme, hexToRgba, useStacklineTheme } from '@stackline/ui';
import React, { MouseEventHandler, useCallback } from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { styled } from '@mui/material';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import {
  getColorForMetricChangeValue,
  getIconForMetricChange
} from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { useHistory, useMetricFormatter } from 'src/utils/Hooks';
import {
  PROMOTION_QUERY_PARAM,
  PromotionMetricOptions
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/Promotions/constants';
import { getQueryParamValue, updateUrlQueryParams } from 'src/utils/app';

import useAdvancedSearchData from 'src/serverProxy/useAdvancedSearchData';

const UnstyledButton = styled('button')(
  ({ stacklineTheme, selected }: { stacklineTheme: StacklineTheme; selected: boolean }) => ({
    border: 'none',
    borderRadius: '4px',
    background: selected ? hexToRgba(stacklineTheme.colors.secondaryPorcelain) : 'none',
    padding: '0',
    ':hover': {
      backgroundColor: hexToRgba(stacklineTheme.colors.secondaryPorcelain)
    }
  })
);

interface PromotionMetricCardProps {
  label: string;
  percentChange: number;
  selected: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  formattedValue: string;
  isLoading?: boolean;
}

export const PromotionMetricCard = ({
  onClick,
  label,
  percentChange,
  selected,
  formattedValue,
  isLoading
}: PromotionMetricCardProps) => {
  const theme = useStacklineTheme();
  const formatMetric = useMetricFormatter();

  return (
    <UnstyledButton
      onClick={onClick}
      stacklineTheme={theme}
      selected={selected}
      sx={{
        width: '232px'
      }}
    >
      <Box height="82px" borderRadius="4px" padding="16px">
        <div style={{ textAlign: 'left', whiteSpace: 'break-spaces' }}>
          <Text sx={{ height: '16px' }} color="secondary" variant="subtitle3" transform="uppercase">
            {label}
          </Text>
        </div>
        <Flex justifyContent="space-between" marginTop="8px" alignItems="center">
          {isLoading ? (
            <SlSkeleton width="55px" height="34px" />
          ) : (
            <Text sx={{ height: '26px' }} variant="body0">
              {formattedValue}
            </Text>
          )}

          <Flex alignItems="center" gap="xs" sx={{ svg: { top: '0px !important' } }}>
            {isLoading ? (
              <SlSkeleton width="35px" height="25px" />
            ) : (
              <>
                {getIconForMetricChange(percentChange)}
                <Text variant="subtitle4" color={getColorForMetricChangeValue(percentChange)}>
                  {formatMetric(percentChange, METRICTYPE.PERCENT, { decimalPlaces: 1, showFullValue: true })}
                </Text>
              </>
            )}
          </Flex>
        </Flex>
      </Box>
    </UnstyledButton>
  );
};

// TODO: replace ids with actual metric ids/fields
const promotionMetricConfigs = [
  // Volume
  { label: 'Promotion Count', id: PromotionMetricOptions.PROMOTION_COUNT },
  // Percentage
  { label: 'Average Discount', id: PromotionMetricOptions.AVG_DISCOUNT },
  // Percentage
  { label: 'Share of Retail Sales', id: PromotionMetricOptions.RETAIL_SALES },
  // Percentage
  { label: 'Share of Units Sold', id: PromotionMetricOptions.UNITS_SOLD }
];

export const PromotionMetricCards = () => {
  const history = useHistory();
  const formatMetric = useMetricFormatter();

  /**
   * Updates the URL query params when a promotion metric card is clicked
   */
  const handlePromotionMetricCardClick = useCallback(
    (metric: string) => {
      history.push(updateUrlQueryParams({ promotionMetric: metric }));
    },
    [history]
  );

  const { data, isLoading } = useAdvancedSearchData({
    fields: [
      { name: 'stacklineSku' },
      { name: 'averagePromotionDiscount' },
      { name: 'shareOfRetailSales' },
      { name: 'shareOfUnitsSold' }
    ],
    groupByField: 'stacklineSku',
    indexName: 'promotions'
  });

  const cardData = data
    ? {
        [PromotionMetricOptions.PROMOTION_COUNT]: formatMetric(
          data?.[0]?.values?.stacklineSku_cardinality_count ?? 0,
          METRICTYPE.VOLUME
        ),
        [PromotionMetricOptions.AVG_DISCOUNT]: formatMetric(
          Math.abs(data?.[0]?.values?.averagePromotionDiscount_computed_value ?? 0),
          METRICTYPE.PERCENT
        ),
        [PromotionMetricOptions.RETAIL_SALES]: formatMetric(
          data?.[0]?.values?.shareOfRetailSales_computed_value ?? 0,
          METRICTYPE.PERCENT
        ),
        [PromotionMetricOptions.UNITS_SOLD]: formatMetric(
          data?.[0]?.values?.shareOfUnitsSold_computed_value ?? 0,
          METRICTYPE.PERCENT
        )
      }
    : null;

  return (
    <Flex gap={24} flexWrap="wrap">
      {promotionMetricConfigs.map((config) => (
        <PromotionMetricCard
          formattedValue={cardData ? cardData[config.id] : '0'}
          isLoading={isLoading || !cardData}
          key={config.id}
          label={config.label}
          percentChange={Math.random()}
          selected={getQueryParamValue(PROMOTION_QUERY_PARAM, PromotionMetricOptions.PROMOTION_COUNT) === config.id}
          onClick={() => handlePromotionMetricCardClick(config.id)}
        />
      ))}
    </Flex>
  );
};
