const S3_IMAGES_BUCKET_BASE = 'https://s3-us-west-2.amazonaws.com/stackline-product-images';
const S3_RETAILER_LOGO_BASE = 'https://stackline-retailer-logos.s3-us-west-2.amazonaws.com';

export function getProductImageUrl(providedId: string) {
  const id = providedId || '';
  // if the id is a parent sku, we go to different image folder
  if (id && `${id}`.includes('_')) {
    return `${S3_IMAGES_BUCKET_BASE}/parent-products/${id.toUpperCase()}.jpg`;
  }
  return `${S3_IMAGES_BUCKET_BASE}/${id.toUpperCase()}.jpg`;
}

export function getRetailerLogoUrl(retailerId: string) {
  const id = retailerId || '';
  return `${S3_RETAILER_LOGO_BASE}/${id.toUpperCase()}.jpg`;
}
export const getCountryImageUrl = (id: string) =>
  `${S3_IMAGES_BUCKET_BASE}/country/${id}.jpg?t=${new Date().getTime()}`;

export const getBrandImageUrl = (id: string) => `${S3_IMAGES_BUCKET_BASE}/${id}.jpg?t=${new Date().getTime()}`;

export const getCategoryImageUrl = (id: string) => `${S3_IMAGES_BUCKET_BASE}/${id}.jpg`;

export const getSubCategoryImageUrl = (id: string) => `${S3_IMAGES_BUCKET_BASE}/${id}.jpg`;

export const getClientImageUrl = (id: string | number) =>
  `${S3_IMAGES_BUCKET_BASE}/client/${id}.jpg?t=${new Date().getTime()}`;

export const getUserProfileImageUrl = (userId: string) => {
  return `https://enterprise-user-images.s3.us-west-2.amazonaws.com/profile-images/${userId}.jpeg`;
};

export default {
  getProductImageUrl,
  getBrandImageUrl,
  getCategoryImageUrl,
  getSubCategoryImageUrl,
  getRetailerLogoUrl
};
