import React from 'react';
import { styled, keyframes } from '@mui/material';
import TaskIcon from '@mui/icons-material/Task';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import _get from 'lodash/get';
import { InvoiceSummaryResponse, ShipmentSummaryResponse } from 'src/components/ShortageDisputes/responseTypes';
import {
  ClipboardChecklist,
  PackageCheckmark,
  DocumentChecklist,
  PackageDolly,
  DocumentCornerFold,
  DocumentSubmitted,
  SpeechBubbleCheckmark,
  ClipboardCheckmark
} from 'src/components/SvgIcons/SvgIcons';
import { formatDateForDisputeTable } from 'src/utils/dateUtils';

const pulse = keyframes`
    from {
        transform: scale(0);
        opacity: .9;
    } to {
        transform: scale(3.5);
        opacity: 0;
    }
`;

const StageContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: '1',
  justifyContent: 'center'
});

const OuterPoint = styled('div')({
  display: 'flex',
  position: 'absolute',
  zIndex: '1',
  alignItems: 'center',
  justifyContent: 'center',
  width: '15px',
  height: '15px',
  borderRadius: '20px',
  backgroundColor: '#b5dcfb'
});

const InnerPoint = styled(OuterPoint)({
  backgroundColor: 'black',
  width: '7px',
  height: '7px'
});

const ConnectorUp = styled('div')({
  height: '60px',
  width: '1px',
  backgroundColor: 'black',
  position: 'relative',
  alignSelf: 'end',
  top: '-7px'
});

const Point = ({ active }: { active: boolean }) => {
  const animation = active ? { animation: `${pulse} 1s infinite ease-in-out` } : {};

  return (
    <OuterPoint>
      <ConnectorUp />
      <InnerPoint sx={animation} />
    </OuterPoint>
  );
};

const TopContainer = styled('div')({
  display: 'flex',
  position: 'absolute',
  top: '35px',
  flexDirection: 'column'
});

const BottomContainer = styled(TopContainer)({
  top: '185px',
  width: '87%',
  gap: '5px'
});

const StageTitle = styled('div')({
  fontSize: '16px',
  fontWeight: '500',
  fontFamily: 'Roboto',
  textAlign: 'center',
  color: '#052849',
  marginBottom: '0.5em',
  height: '50px'
});

const StageDate = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '20px',
  fontWeight: '500'
});

interface StageParameters {
  title: string;
  description?: string;
  date: any;
  active?: boolean;
  completed?: boolean;
  start?: boolean;
  end?: boolean;
  icon?: any;
}

const Stage = ({
  title,
  date,
  active = false,
  completed = false,
  start = false,
  end = false,
  icon = <TaskIcon sx={{ fontSize: '40px' }} />
}: StageParameters) => {
  const splitLines = title.split('-');
  const firstLine = splitLines[0];
  const secondLine = splitLines[1];

  const formattedDate = formatDateForDisputeTable(date);

  const value = active ? 50 : completed ? 100 : 0;

  // Our first and last stage in the lifecycle should have it's progress bar styled according to its position at the start or end
  const getBorderRadius = () => {
    if (start) {
      return { borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' };
    }
    if (end) {
      return { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' };
    }
    return {};
  };

  const borderRadius = getBorderRadius();

  return (
    <StageContainer>
      <TopContainer>{icon}</TopContainer>

      <div style={{ display: 'flex', height: '10%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <Point active={active}></Point>
        <Box sx={{ width: '100%' }}>
          <LinearProgress
            variant="determinate"
            value={value}
            sx={{
              '&.MuiLinearProgress-root': {
                height: '7px',
                position: 'relative',
                alignItems: 'center',
                backgroundColor: '#00000021',
                display: 'flex',
                ...borderRadius
              },
              '.MuiLinearProgress-bar': {
                position: 'relative',
                backgroundColor: '#b5dcfb',
                height: '7px',
                ...borderRadius
              }
            }}
          />
        </Box>
      </div>

      <BottomContainer>
        <StageTitle>
          {firstLine}
          <br />
          {secondLine}
        </StageTitle>
        <StageDate>{formattedDate}</StageDate>
      </BottomContainer>
    </StageContainer>
  );
};

const LifeCycleContainer = styled('div')({
  display: 'flex',
  minHeight: '300px',
  height: 'auto',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  position: 'relative',
  marginBottom: '100px'
});

const stageCollection = [
  {
    start: true,
    title: 'PO-Issued Date',
    description: 'Date in which Amazon transmitted the PO to the vendor',
    dateKey: 'poIssuedDate',
    icon: <ClipboardChecklist style={{ width: '40px' }} />
  },
  {
    title: 'PO-Confirmation Date',
    description: 'Date in which the vendor confirmed the PO',
    dateKey: 'poConfirmationDate',
    icon: <PackageCheckmark style={{ width: '40px' }} />
  },
  {
    title: 'ASN-Submission Date',
    description: 'Date the vendor submitted the ASN to Amazon',
    dateKey: 'asnSubmissionDate',
    icon: <DocumentChecklist style={{ width: '40px' }} />
  },
  {
    title: 'Invoice-Created Date',
    description: 'Invoice creation date',
    dateKey: 'invoiceCreatedDate',
    icon: <DocumentCornerFold style={{ width: '40px' }} />
  },
  {
    title: 'Inventory-Received Date',
    description: 'Initial date that inventory was received at Amazon',
    dateKey: 'inventoryReceivedDate',
    icon: <PackageDolly style={{ width: '40px' }} />
  },
  {
    title: 'Initial Dispute-Submitted Date',
    description: 'Date the initial dispute was submitted on VC',
    dateKey: 'initialDisputeSubmittedDate',
    icon: <DocumentSubmitted style={{ width: '40px' }} />
  },
  {
    title: 'Contact Us-Submission Date',
    description: 'Date the contact us case was submitted on VC',
    dateKey: 'contactUsCaseSubmissionDate',
    icon: <SpeechBubbleCheckmark style={{ width: '40px' }} />
  },
  {
    title: 'Resolved-Date',
    description: 'Date the contact us case was resolved on VC',
    dateKey: 'resolvedDate',
    icon: <ClipboardCheckmark style={{ width: '40px' }} />,
    end: true
  }
];
const determinePointInLifeCycle = (rawData) => {
  const { invoiceSummaryData, shippingDetailsData } = rawData;
  let completedStages = 0;
  // Keys we care about. Some keys may not be present at all on the response, even if the later keys in the lifecycle are.
  const poIssuedDate = _get(shippingDetailsData, '[0]poOrderedOnDate', null);
  const poConfirmationDate = _get(shippingDetailsData, '[0]poOrderedOnDate', null);
  const asnSubmissionDate = _get(shippingDetailsData, '[0]shipmentAsnCreationDate', null);
  const invoiceCreatedDate = _get(invoiceSummaryData, '[0]invoiceCreationDate', null);
  const inventoryReceivedDate = _get(shippingDetailsData, '[0]shipmentDeliveryDate', null);
  const initialDisputeSubmittedDate = _get(invoiceSummaryData, '[0]disputeSubmissionDate', null);
  const contactUsCaseSubmissionDate = _get(invoiceSummaryData, '[0]contactUsCreationDate', null);
  const resolvedDate = _get(invoiceSummaryData, '[0]finalDisputeResolutionDate', null);

  // If the date is missing, we can use the stage to determine it's point in the lifecycle
  const stage = _get(invoiceSummaryData, '[0]customDisputeStage', '');

  if (poIssuedDate) {
    completedStages = 1;
  }
  if (poConfirmationDate) {
    completedStages = 2;
  }
  if (asnSubmissionDate) {
    completedStages = 3;
  }
  if (invoiceCreatedDate) {
    completedStages = 4;
  }
  if (inventoryReceivedDate) {
    completedStages = 5;
  }
  if (initialDisputeSubmittedDate || stage === 'Initial Dispute') {
    completedStages = 6;
  }
  if (contactUsCaseSubmissionDate) {
    completedStages = 7;
  }
  if (resolvedDate) {
    completedStages = 8;
  }

  return {
    completedStages,
    poIssuedDate,
    poConfirmationDate,
    asnSubmissionDate,
    invoiceCreatedDate,
    inventoryReceivedDate,
    initialDisputeSubmittedDate,
    contactUsCaseSubmissionDate,
    resolvedDate
  };
};

interface DisputeLifeCycleParameters {
  rawData: {
    invoiceSummaryData: InvoiceSummaryResponse[];
    shippingDetailsData: ShipmentSummaryResponse[];
  };
}

const DisputeLifeCycle = ({ rawData }: DisputeLifeCycleParameters) => {
  const lifeCycleResults = determinePointInLifeCycle(rawData);
  return (
    <>
      <LifeCycleContainer>
        {stageCollection.map((stage, index) => (
          <Stage
            key={index}
            {...stage}
            date={lifeCycleResults[stage.dateKey]}
            completed={index + 1 < lifeCycleResults.completedStages}
            active={index + 1 === lifeCycleResults.completedStages}
          />
        ))}
      </LifeCycleContainer>
    </>
  );
};

export default DisputeLifeCycle;
