const RECEIVE_PRODUCT_DATA_FOR_MAIN = 'src/omniProductService/RECEIVE_PRODUCT_DATA_FOR_MAIN' as const;
const RECEIVE_PRODUCT_DATA_FOR_COMPARE = 'src/omniProductService/RECEIVE_PRODUCT_DATA_FOR_COMPARE' as const;
const START_FETCH = 'src/omniProductService/START_FETCH' as const;
const END_FETCH = 'src/omniProductService/END_FETCH' as const;

export default {
  RECEIVE_PRODUCT_DATA_FOR_MAIN,
  RECEIVE_PRODUCT_DATA_FOR_COMPARE,
  START_FETCH,
  END_FETCH
};
