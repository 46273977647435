import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import _isEqual from 'lodash/isEqual';
import _merge from 'lodash/merge';
import _pick from 'lodash/pick';

import colors from 'src/utils/colors';
import { entityOperations } from 'src/store/modules/entityService';
import { userOperations } from 'src/store/modules/user';
import UserAccountNavItems from './UserAccountNavItems';
import UserProfile from './UserProfile';
import UserSecurity from './UserSecurity';
import UserTeam from './UserTeam';
import UserAccountCogs from './UserAccountCogs';
import UserAccountContent from './UserAccountContent';
import UserAccountReviews from './UserAccountReviews';
import UserAccountMapPrice from './UserAccountMapPrice';
import UserAccountMultiRetailer from './UserAccountMultiRetailer';
import UserAlert from './UserAlert';
import CustomColumns from './UserCustomColumn';
import StacklineDataAdmin from './StacklineDataAdmin';
import UserAuthorize from './UserAuthorize';
import 'src/routes/HomePage/Segments.scss';

import {
  AdvertisingIcon,
  EditIcon,
  UserIcon,
  PasswordIcon,
  CollaborationIcon,
  ReviewsIcon,
  ContentIcon,
  MapPriceIcon,
  ShopcartIcon,
  ThreeBarsIcon,
  TrashcanIcon
} from '../../components/SvgIcons';
import './UserAccount.scss';
import i18n from '../../i18n_en';
import * as appOperations from 'src/store/modules/app/operations';
import DownloadsPageLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/DownloadsPageLayout';
import { shouldShowNewBeacon } from 'src/utils/app';
import ProfileSummaryLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Profile/ProfileSummaryLayout';
import ProfileSecurityLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Profile/ProfileSecurityLayout';
import NewSettingsLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/NewSettingsLayout';

const OMNI_DEV_MEMBER = {
  ids: ['jbaik', 'czhou', 'jvaghasiya', 'karthik', 'mkeidan', 'ksagar', 'mcomerford', 'cgoyel'],
  emailEnds: '@stackline.com'
};

const isOmniDevMember = (email) => {
  const { ids, emailEnds } = OMNI_DEV_MEMBER;
  return ids.includes(email.split('-')[0]) && email.endsWith(emailEnds);
};

const routes = [
  {
    path: '/account/profile',
    sidebarName: 'Profile',
    sidebarIcon: UserIcon,
    exact: true,
    mainComponent: shouldShowNewBeacon() ? ProfileSummaryLayout : UserProfile
  },
  {
    path: '/account/security',
    sidebarName: 'Security',
    sidebarIcon: PasswordIcon,
    mainComponent: shouldShowNewBeacon() ? ProfileSecurityLayout : UserSecurity
  },
  {
    path: '/account/team',
    sidebarName: 'Team',
    sidebarIcon: CollaborationIcon,
    mainComponent: UserTeam
  },
  {
    path: '/account/content',
    sidebarName: 'Content',
    app: 'beacon',
    sidebarIcon: ContentIcon,
    mainComponent: UserAccountContent
  },
  {
    path: '/account/reviews',
    sidebarName: 'Reviews',
    app: 'beacon',
    sidebarIcon: ReviewsIcon,
    mainComponent: UserAccountReviews
  },
  {
    path: '/account/mapprice',
    sidebarName: 'MAP Price',
    app: 'beacon',
    sidebarIcon: MapPriceIcon,
    mainComponent: UserAccountMapPrice
  },
  {
    path: '/account/multiRetailer',
    sidebarName: 'Retailer Upload',
    app: 'beacon',
    sidebarIcon: ThreeBarsIcon,
    mainComponent: UserAccountMultiRetailer
  },
  {
    path: '/account/brandCogs',
    sidebarName: 'COGS',
    app: 'beacon',
    sidebarIcon: ShopcartIcon,
    mainComponent: UserAccountCogs
  },
  ...(shouldShowNewBeacon()
    ? [
        {
          path: '/account/downloads',
          sidebarName: 'Downloads',
          app: 'beacon',
          sidebarIcon: ShopcartIcon,
          mainComponent: DownloadsPageLayout
        }
      ]
    : [])
];

const apiEndpointSelector = (pathname, methodType) => {
  const apiStrings = {
    '/account/profile:get': '/api/user/GetUserProfile',
    '/account:get': '/api/user/GetUserProfile',
    '/account/profile:post': '/api/user/UpdateProfile',
    '/account/billing:get': '/api/user/GetBillingInfo',
    '/account/billing:post': '/api/user/updateBillingInfo',
    '/account/security:post': '/api/user/UpdateSecurity',
    '/account/team:post': '/api/user/GetAllUsers',
    '/account/authorize:get': '/api/user/SpApiSignUp'
  };

  return apiStrings[`${pathname}:${methodType}`];
};
class UserAccountContainer extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    fetchMainEntity: PropTypes.func.isRequired,
    fetchUserData: PropTypes.func.isRequired,
    entityService: PropTypes.object.isRequired,
    fetchUserTeam: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    updateUserAccount: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    // from `withRouter`
    history: PropTypes.object.isRequired,
    omniRetailers: PropTypes.object
  };

  constructor(props) {
    const { account_buttonUpdate } = i18n;
    super(props);
    this.state = {
      buttonText: account_buttonUpdate,
      isFormSubmitted: false,
      clearInput: false,
      serverErrorMessage: '',
      profileErrorText: '',
      profile: {},
      passwordInfo: {
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      passwordValidators: {
        isPasswordConfirmationSame: true
      }
    };
  }

  componentDidMount() {
    const { fetchUserData, fetchUserTeam, location, retailer, fetchMainEntity } = this.props;
    const { pathname } = location;

    fetchMainEntity('client', -1, retailer.id, this.props.history.push.bind(this.props.history));

    // if the condition below is for checking if the client's current retailer select is an amazon retailer, and if so,
    // they will be presented the option to authorize their seller account(s) with amazon's selling partner api in the authorize section of their profile page
    if (
      [
        '1',
        '11',
        '14',
        '15',
        '16',
        '17',
        '28',
        '29',
        '30',
        '31',
        '32',
        '33',
        '53',
        '55',
        '62',
        '72',
        '74',
        '77',
        '90'
      ].includes(retailer.id)
    ) {
      if (!routes.find((r) => r.path === '/account/authorize')) {
        routes.push({
          path: '/account/authorize',
          sidebarName: 'Authorize SP API',
          app: 'beacon',
          sidebarIcon: CollaborationIcon,
          mainComponent: UserAuthorize
        });
      }
    }

    if (
      ['security', 'content', 'reviews', 'products', 'mapprice', 'multiRetailer', 'brandCogs', 'authorize'].find((s) =>
        pathname.includes(s)
      )
    ) {
      return;
    }

    if (pathname === '/account/team') {
      fetchUserTeam(apiEndpointSelector(pathname, 'post'));
      return;
    }

    // apiEndpointSelector is a legacy function that returns the correct api endpoint based on the pathname and method type
    // however with expansion of the users/setting page, we no longer specify api endpoints in the function, so we prevent the undefined api call.
    if (apiEndpointSelector(pathname, 'get')) {
      fetchUserData(apiEndpointSelector(pathname, 'get'));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { fetchUserData, fetchUserTeam, location } = nextProps;
    const { pathname } = location;

    if (
      ['security', 'content', 'reviews', 'products', 'mapprice', 'multiRetailer', 'brandCogs', 'authorize'].find((s) =>
        pathname.includes(s)
      )
    ) {
      return;
    }

    if (!_isEqual(pathname, this.props.location.pathname)) {
      if (pathname === '/account/team') {
        fetchUserTeam(apiEndpointSelector(pathname, 'post'));

        // apiEndpointSelector is a legacy function that returns the correct api endpoint based on the pathname and method type
        // however with expansion of the users/setting page, we no longer specify api endpoints in the function, so we prevent the undefined api call.
      } else if (apiEndpointSelector(pathname, 'get')) {
        fetchUserData(apiEndpointSelector(pathname, 'get'));
      }
    }
  }

  isFormValid = () => {
    const { pathname } = this.props.location;
    const { isPhoneNumberValid, profile, passwordValidators } = this.state;
    let isFormValid = true;

    if (pathname.includes('profile')) {
      Object.keys(profile).forEach((key) => {
        if (profile[key] === '') {
          isFormValid = false;
        }
      });
      return isFormValid && isPhoneNumberValid;
    } else if (pathname.includes('security')) {
      const { isPasswordConfirmationSame, isNewPasswordValid, isPasswordConfirmationValid } = passwordValidators;
      const passwordCheck = isPasswordConfirmationSame && isNewPasswordValid && isPasswordConfirmationValid;
      isFormValid = passwordCheck;
      return isFormValid;
    }

    return isFormValid;
  };

  handleFormSubmit = (event) => {
    const { fetchUserData, updateUserAccount, location, user } = this.props;
    const { pathname } = location;
    const { profile: propsProfile } = user;
    const pageView = pathname.split('/', 3)[2];
    const { account_buttonUpdate, account_buttonUpdating, account_buttonUpdated, account_passwordIssueMessage } = i18n;
    const { profile, passwordInfo } = this.state;
    event.preventDefault();

    this.setState({
      isFormSubmitted: true,
      serverErrorMessage: ''
    });

    if (!this.isFormValid()) {
      return Promise.resolve();
    }

    this.setState({
      buttonText: account_buttonUpdating
    });

    const bodyRequest = () => {
      let request = {};
      switch (pageView) {
        case 'profile':
          request = _merge(propsProfile, { ...profile });
          break;
        case 'security':
          request = { ...passwordInfo };
          break;
        default:
          break;
      }
      return request;
    };

    return updateUserAccount(apiEndpointSelector(pathname, 'post'), bodyRequest()).then(
      (response) => {
        if (pathname.includes('security') && !response.data) {
          const errorMessage = pathname.includes('security') ? account_passwordIssueMessage : response.data;
          this.setState({
            buttonText: account_buttonUpdate,
            serverErrorMessage: errorMessage
          });
          return;
        } else if (!pathname.includes('security') && response.data !== 'success') {
          this.setState({
            buttonText: account_buttonUpdate,
            serverErrorMessage: response.data
          });
          return;
        }
        fetchUserData(apiEndpointSelector(pathname, 'get'));
        this.setState({
          buttonText: account_buttonUpdated,
          clearInput: true
        });
        setTimeout(() => {
          this.setState({
            clearInput: false,
            isFormSubmitted: false,
            passwordInfo: {
              password: '',
              newPassword: '',
              confirmPassword: ''
            }
          });
        }, 1000);
      },
      (error) => {
        this.setState({
          serverErrorMessage: error.data.message
        });
      }
    );
  };

  handleNewPasswordChange = (value) => {
    const { passwordValidators, passwordInfo } = this.state;
    this.setState({
      passwordInfo: {
        ...passwordInfo,
        newPassword: value.password
      },
      passwordValidators: {
        ...passwordValidators,
        isNewPasswordValid: value.isPasswordValid,
        isPasswordConfirmationSame: value.password === passwordInfo.confirmPassword
      }
    });
  };

  handlePasswordConfirmationChange = (value) => {
    const { passwordValidators, passwordInfo } = this.state;

    this.setState({
      passwordInfo: {
        ...passwordInfo,
        confirmPassword: value.password
      },
      passwordValidators: {
        ...passwordValidators,
        isPasswordConfirmationValid: value.isPasswordValid,
        isPasswordConfirmationSame: value.password === passwordInfo.newPassword
      }
    });
  };

  handleProfileChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      serverErrorMessage: '',
      profile: {
        ...this.state.profile,
        [name]: value
      }
    });
  };

  handlePhoneNumberChange = (values) => {
    const { profile } = this.state;
    const { validator, ...fieldName } = values;
    this.setState({
      ...validator,
      profile: {
        ...profile,
        ...fieldName
      }
    });
  };

  showErrorText = (field) => {
    const { isFormSubmitted } = this.state;
    const fieldValue = this.state.profile[field];

    if (isFormSubmitted && fieldValue === '') {
      return 'This field is required';
    }
    return null;
  };

  getUploadPageDetail = (page) => {
    const { app, retailer, user, entityService, omniRetailers } = this.props;
    const { searchParams } = app.queryParams;
    if (!entityService.mainEntity) {
      return null;
    }
    const { hashId } = entityService.mainEntity;
    const isRetailerActive = (retailerId) => user.config.allRetailerIds.includes(parseInt(retailerId, 10));

    const isOmniRetailerActive = (retailerId) =>
      omniRetailers.data.map((r) => r.retailerId).includes(parseInt(retailerId, 10));

    const retailers =
      app.name === 'omni'
        ? retailer.availableRetailers.filter(
            (val) => isOmniRetailerActive(val.id) && val.id.indexOf('63') === -1 && val.id.indexOf('63_') === -1
          )
        : retailer.availableRetailers.filter((val) => isRetailerActive(val.id));

    const { targetUrl } = app;
    const apiPathName = app.name === 'omni' ? 'omni' : 'api';

    let buttons = {
      downloadTemplete: {
        text: 'VIEW TEMPLATE',
        desc: 'Download a blank MAP Price template to populate.',
        api: `https://${targetUrl}/api/account/DownloadUploadTemplate?templateType=map-price-upload`
      },
      downloadLatest: {
        text: 'VIEW MAP PRICES',
        desc: 'Download the current MAP Prices you’ve uploaded previously.',
        api: `https://${targetUrl}/api/account/DownloadLatestUploadedFile?uploadType=map-price-upload`
      },
      uploadFile: {
        text: 'UPLOAD MAP PRICES',
        desc: 'Upload a CSV of your new MAP Prices for',
        api: '/api/account/UserUpload?uploadType=map-price-upload',
        success: () =>
          'You’ve successfully uploaded your file.  It takes 24 hours to process in your account. Please check back tomorrow to view the results.'
      }
    };
    let getLatestApi = '/api/account/GetLatestUserUploadRequests?uploadType=map-price-upload';
    switch (page) {
      case 'brandCogs':
        buttons = {
          downloadTemplete: {
            text: 'VIEW TEMPLATE',
            desc: 'Download a Brand COGS template to populate.',
            api: `https://${targetUrl}/api/account/DownloadUploadTemplate?templateType=brand-cogs-upload`
          },
          downloadLatest: {
            text: 'VIEW Current COGS',
            desc: 'Download the current Brand COGS you’ve uploaded previously.',
            api: `https://${targetUrl}/api/account/DownloadLatestUploadedFile?uploadType=brand-cogs-upload`
          },
          uploadFile: {
            text: 'UPLOAD COGS',
            desc: 'Upload a CSV of your new Brand COGS for',
            api: '/api/account/UserUpload?uploadType=brand-cogs-upload',
            success: () => <div>You’ve successfully uploaded your file.</div>
          }
        };
        getLatestApi = '/api/account/GetLatestUserUploadRequests?uploadType=brand-cogs-upload';
        break;
      case 'content':
        buttons = {
          downloadTemplete: {
            text: 'VIEW TEMPLATE',
            desc: 'Download a blank Master Content template to populate.',
            api: `https://${targetUrl}/api/account/DownloadUploadTemplate?templateType=master-content-upload`
          },

          downloadLatest: {
            text: 'VIEW MASTER CONTENT',
            desc: 'Download the current Master Content you’ve uploaded previously.',
            api: `https://${targetUrl}/api/account/DownloadLatestUploadedFile?uploadType=master-content-upload`
          },
          uploadFile: {
            text: 'UPLOAD MASTER CONTENT',
            desc: 'Upload a CSV of your new Master Content for',
            api: '/api/account/UserUpload?uploadType=master-content-upload',
            success: () => (
              <div>
                You’ve successfully uploaded your file. Please visit{' '}
                <a // eslint-disable-line
                  style={{ color: colors.blue, cursor: 'pointer' }}
                  onClick={() =>
                    window.location.assign(`/client/${hashId}${searchParams}&tab=content&subtab=contentAccuracy`)
                  }
                >
                  Content Accuracy
                </a>{' '}
                page to see the details.
              </div>
            )
          }
        };
        getLatestApi = '/api/account/GetLatestUserUploadRequests?uploadType=master-content-upload';
        break;
      case 'omniContent':
        buttons = {
          downloadTemplete: {
            text: 'VIEW TEMPLATE',
            desc: 'Download a blank Master Content template to populate.',
            api: `https://${targetUrl}/omni/account/DownloadUploadTemplate?templateType=master-content-upload`
          },

          downloadLatest: {
            text: 'VIEW MASTER CONTENT',
            desc: 'Download the current Master Content you’ve uploaded previously.',
            api: `https://${targetUrl}/omni/account/downloadMasterContentData`
          },
          uploadFile: {
            text: 'UPLOAD MASTER CONTENT',
            desc: 'Upload a CSV of your new Master Content for',
            // api won't work in local env.
            // check this code in C# api https://gitlab.com/stackline-dev/api/-/blob/master/Stackline.Api/Proxy/ProxyHelpers.cs#L80-112

            api: `https://${targetUrl}/omni/account/uploadMasterContentData`,
            success: () => (
              <div>
                You’ve successfully uploaded your file. Please visit{' '}
                <a // eslint-disable-line
                  style={{ color: colors.blue, cursor: 'pointer' }}
                  onClick={() =>
                    window.location.assign(
                      `/client/${entityService.mainEntity.id}${searchParams}&tab=content&subtab=contentAccuracy`
                    )
                  }
                >
                  Content Accuracy
                </a>{' '}
                page to see the details.
              </div>
            )
          }
        };
        getLatestApi = 'omni/account/getLastUploadTimeMasterContentData';

        break;
      case 'review':
        buttons = {
          downloadTemplete: {
            text: 'VIEW TEMPLATE',
            desc: 'Download a template with suggested keywords pre-populated.',
            api: `https://${targetUrl}/${apiPathName}/account/DownloadUploadTemplate?templateType=high-risk-keywords-upload`
          },
          downloadLatest: {
            text: 'VIEW KEYWORDS',
            desc: 'Download the current High Risk keywords you’ve uploaded previously.',
            api: `https://${targetUrl}/${apiPathName}/account/DownloadHighRiskKeywords?`
          },
          uploadFile: {
            text: 'UPLOAD KEYWORDS',
            desc: 'Upload a CSV of your new High Risk keywords for',
            api: `/${apiPathName}/account/UploadHighRiskKeywords?`,
            success: () => (
              <div>
                You’ve successfully uploaded your file. Please visit{' '}
                <a // eslint-disable-line
                  style={{ color: colors.blue, cursor: 'pointer' }}
                  onClick={() =>
                    window.location.assign(`/client/${hashId}${searchParams}&tab=reviews&subtab=highRiskReviews`)
                  }
                >
                  High Risk Reviews
                </a>{' '}
                page to see the details.
              </div>
            )
          }
        };
        getLatestApi = '';
        break;
      case 'multiRetailer':
        buttons = {
          downloadTemplete: {
            text: 'VIEW TEMPLATE',
            desc: 'Download a blank Retailer Upload template.',
            api: `https://${targetUrl}/api/account/DownloadUploadTemplate?templateType=multi-retailer-upload`
          },
          uploadFile: {
            text: 'Upload',
            desc: 'Upload a CSV of your Retailer Upload file for',
            api: '/api/account/UserUpload?uploadType=multi-retailer-upload',
            success: () => (
              <div>
                You’ve successfully uploaded your file. The data will be published into your Beacon account within 24
                hours.
              </div>
            )
          }
        };
        getLatestApi = '/api/account/GetLatestUserUploadRequests?uploadType=multi-retailer-upload';
        break;
      default:
    }

    return { searchParams, hashId, retailers, buttons, getLatestApi };
  };

  render() {
    const { app, user, location, retailer } = this.props;
    const {
      config: { isStacklineDeploymentSuperUser },
      session: {
        isAdmin,
        userMetaData: { firstName, lastName }
      }
    } = user;
    const { isFormSubmitted, buttonText, clearInput, profileErrorText, serverErrorMessage, passwordValidators } =
      this.state;

    const routesForUser = [...routes];
    if (isStacklineDeploymentSuperUser) {
      routesForUser.push({
        path: '/account/stacklineDataAdmin',
        sidebarName: 'Stackline Data Admin',
        sidebarIcon: TrashcanIcon,
        mainComponent: StacklineDataAdmin
      });
    }
    if (app.name === 'advertising') {
      routesForUser.push({
        path: '/account/alert',
        sidebarName: 'Notifications',
        sidebarIcon: AdvertisingIcon,
        mainComponent: UserAlert
      });
      routesForUser.push({
        path: '/account/custom',
        sidebarName: 'Custom Columns',
        sidebarIcon: EditIcon,
        mainComponent: CustomColumns
      });
    }

    if (app.name === 'omni') {
      routesForUser.push({
        path: '/account/content',
        sidebarName: 'Content',
        sidebarIcon: ContentIcon,
        mainComponent: UserAccountContent
      });
    }

    if (app.name === 'omni' && isOmniDevMember(user.session.email)) {
      routesForUser.push({
        path: '/account/reviews',
        sidebarName: 'Reviews (DEV)',
        sidebarIcon: ReviewsIcon,
        mainComponent: UserAccountReviews
      });
      routesForUser.push({
        path: '/account/mapprice',
        sidebarName: 'MAP Price (DEV)',
        sidebarIcon: MapPriceIcon,
        mainComponent: UserAccountMapPrice
      });
    }

    if (shouldShowNewBeacon()) {
      return (
        <NewSettingsLayout
          getUploadPageDetail={this.getUploadPageDetail}
          buttonText={buttonText}
          clearInput={clearInput}
          handleProfileChange={this.handleProfileChange}
          handlePhoneNumberChange={this.handlePhoneNumberChange}
          handlePasswordConfirmationChange={this.handlePasswordConfirmationChange}
          handleNewPasswordChange={this.handleNewPasswordChange}
          isFormSubmitted={isFormSubmitted}
          isPasswordConfirmationSame={passwordValidators.isPasswordConfirmationSame}
          isAdmin={isAdmin}
          onSubmit={this.handleFormSubmit}
          showErrorText={this.showErrorText}
          profileErrorText={profileErrorText}
          serverErrorMessage={serverErrorMessage}
          user={user}
          retailer={retailer}
          profile={this.state.profile}
        />
      );
    }

    return (
      <div>
        <div className="account-container">
          <nav className="account-nav">
            <div className="nav-header-container" style={{ margin: 'auto', paddingTop: 25 }}>
              <div className="segment__short-name-container" style={{ backgroundColor: colors.blue }}>
                <div className="segment__short-name">
                  {firstName.charAt(0)}
                  {lastName.charAt(0)}
                </div>
              </div>
              <div style={{ margin: '10px 12px 0', textAlign: 'center' }}>
                {firstName} {lastName}
              </div>
            </div>
            <div style={{ margin: '17px auto', height: 1, background: colors.lighterGrey, width: '80%' }} />
            <UserAccountNavItems app={app} location={location} routes={routesForUser} />
          </nav>

          <div style={{ flex: '1 1' }}>
            <Switch>
              {routesForUser.map((route) => {
                if (location.pathname === '/account') {
                  return <Redirect key={route.path} to="/account/profile" />;
                }
                const RenderedComponent = route.mainComponent;

                return (
                  <Route
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    render={() => (
                      <RenderedComponent
                        getUploadPageDetail={this.getUploadPageDetail}
                        buttonText={buttonText}
                        clearInput={clearInput}
                        handleProfileChange={this.handleProfileChange}
                        handlePhoneNumberChange={this.handlePhoneNumberChange}
                        handlePasswordConfirmationChange={this.handlePasswordConfirmationChange}
                        handleNewPasswordChange={this.handleNewPasswordChange}
                        isFormSubmitted={isFormSubmitted}
                        isPasswordConfirmationSame={passwordValidators.isPasswordConfirmationSame}
                        isAdmin={isAdmin}
                        onSubmit={this.handleFormSubmit}
                        showErrorText={this.showErrorText}
                        profileErrorText={profileErrorText}
                        serverErrorMessage={serverErrorMessage}
                        user={user}
                        retailer={retailer}
                      />
                    )}
                  />
                );
              })}
            </Switch>
          </div>
          <div />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => _pick(state, ['app', 'entityService', 'retailer', 'user', 'omniRetailers']);

const mapDispatchToProps = {
  fetchConfig: appOperations.fetchConfig,
  fetchMainEntity: entityOperations.fetchMainEntity,
  fetchUserData: userOperations.fetchUserAccount,
  updateUserAccount: userOperations.updateUserAccount,
  fetchUserTeam: userOperations.fetchUserTeam
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAccountContainer));
