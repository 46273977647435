import React from 'react';
import GenericChart from 'src/components/Charts/GenericChart';
import Highcharts from 'highcharts';
import _merge from 'lodash/merge';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { ConvertSeriesToDelimitedDataFunction } from 'src/components/Charts/types';

const chartProps: Highcharts.Options = {
  chart: {
    type: 'venn'
  },
  exporting: {
    enabled: true
  },
  tooltip: {
    shared: false,
    backgroundColor: 'white',
    /**
     * GenericChart uses the `y` property on
     * the point to format, but a Venn diagram
     * doesn't have a `y` value so this overrides
     * it to use the default formatter
     */
    formatter(tooltip) {
      return tooltip.defaultFormatter.call(this, tooltip);
    }
  },
  plotOptions: {
    series: {
      states: {
        hover: {
          enabled: true
        }
      }
    },
    venn: {
      opacity: 1
    }
  }
};

interface ConnectVennDiagramProps {
  /**
   * Intersection data to be displayed
   * in the Venn diagram
   */
  data: Highcharts.PointOptionsObject[];
  /**
   * Show GenericChartLoading if true
   */
  loading?: boolean;
  options?: Partial<Highcharts.Options>;
  /**
   * GenericChart prop for converting the stacked column data
   * to CSV. Should be given if `exporting.enabled` is true
   * in `chartProps`.
   */
  convertSeriesToDelimitedData?: ConvertSeriesToDelimitedDataFunction;
}

/**
 * Wrapper around GenericChart to display
 * a Venn diagram
 */
const ConnectVennDiagram = ({ data, loading, options, ...rest }: ConnectVennDiagramProps) => {
  const chartSeries: Highcharts.SeriesVennOptions[] = [
    {
      type: 'venn',
      dataLabels: {
        useHTML: true
      },
      data
    }
  ];
  return loading ? (
    <GenericChartLoading />
  ) : (
    <GenericChart chartSeries={chartSeries} chartProps={_merge(chartProps, options)} {...rest} />
  );
};

ConnectVennDiagram.defaultProps = {
  loading: false,
  options: {}
};

export default ConnectVennDiagram;
