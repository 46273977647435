import { SlRow, SlColumn, Text } from '@stackline/ui';
import React from 'react';
import { withRouter } from 'react-router';

interface EntityRowProps {
  /**
   * Title of the product entity parent brand
   */
  title: string;
  /**
   * Header displayed left of the title, should
   * be an image
   */
  header: React.ReactNode;
  /**
   * If given, displays a subtitle underneath
   * the title
   */
  subtitleText?: string;
  /**
   * If given, displays a retailer SKU underneath
   * the subtitle
   */
  retailerSku?: string;
}
const EntityRowInner = ({ title, header, subtitleText, retailerSku }: EntityRowProps) => {
  return (
    <SlRow horizontalInset="none" verticalInset="mdl" spacing="md">
      {header}
      <SlColumn spacing="sm">
        <Text textAlign="center" variant="subtitle1">
          {title}
        </Text>
        {subtitleText ? (
          <Text variant="body2" title={subtitleText} textAlign="left">
            {subtitleText}
          </Text>
        ) : null}
        {retailerSku ? (
          <>
            <SlColumn spacing="sm">
              <Text color="secondary" variant="subtitle4" title="retailerSku" textAlign="left">
                RETAILER SKU
              </Text>
              <Text variant="body3" title={retailerSku} textAlign="left">
                {retailerSku}
              </Text>
            </SlColumn>
          </>
        ) : null}
      </SlColumn>
    </SlRow>
  );
};

export default withRouter(EntityRowInner);
