/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';
import { CrossIcon, HeaderSearchIcon } from '../SvgIcons';

interface SearchBarProps {
  handleSearchChange(e: Event, n: number): void;
}

const SearchBar: React.FC<SearchBarProps> = ({ handleSearchChange }) => {
  const ref = useRef<HTMLInputElement>(null);
  const { pathname } = window.location;

  const clearSearchInput = () => {
    ref.current!.value = '';
  };

  useEffect(() => {
    if (pathname !== '/search') {
      clearSearchInput();
    }
  }, [pathname]);

  const handleSearch = (e: any) => {
    if (e.key === 'Enter') {
      ref.current!.blur();
      handleSearchChange(e.target.value, -1);
      clearSearchInput();
    }
  };

  return (
    <div className="sl-header__search-bar sl-header__search-bar--visible">
      <button className={`sl-header__cross-button 'sl-header__cross-button--visible`}>
        <CrossIcon className="sl-header__cross-icon" />
      </button>
      <input onKeyDown={handleSearch} ref={ref} />
    </div>
  );
};

const Search = (props: SearchBarProps) => {
  return (
    <div className="sl-header__search">
      <div className="searchbar_wraper" {...props}>
        <div className="sl-header__search-button-container">
          <button className="sl-header__search-button">
            <HeaderSearchIcon className="sl-header__search-icon" />
          </button>
        </div>
        <SearchBar {...props} />
      </div>
    </div>
  );
};

export default Search;
