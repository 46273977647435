/* eslint-disable react/prop-types */
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'qs';
import _pick from 'lodash/pick';

import { Omit } from 'src/types/utils';

/**
 * Takes a React component and returns a new one with a `push` prop that can be used to change the current page via
 * `react-router` supplied to it.  This can be thought of as exactly the same behavior as `withRouter` but without the
 * `location`, `match`, and `history` props being passed in as well.
 *
 * Returns a new component that has a `push` prop provided to it.
 *
 * @param {React.ComponentType<any>} Comp The component to which the `push` prop should be provided
 */
export function withPush<OwnProps extends { [key: string]: any }>(
  Comp: React.ComponentType<OwnProps & { push: RouteComponentProps['history']['push'] }>
) {
  const WithPushInner: React.FC<RouteComponentProps<any> & Omit<OwnProps, 'push'>> = ({
    match: _match,
    location: _location,
    history: { push },
    staticContext: _staticContext,
    push: _push,
    ...props
  }) => <Comp push={push} {...(props as any as OwnProps)} />;
  const WithPush = withRouter(WithPushInner);

  return WithPush;
}

/**
 * Takes a React component and returns a new one with a `location` prop that can be used to view the current page URL
 * and query params from `react-router` supplied to it.  This can be thought of as exactly the same behavior as
 * `withRouter` but without the `match` and `history` props being passed in as well.
 *
 * Returns a new component that has a `push` prop provided to it.
 *
 * @param {React.ComponentType<any>} Comp The component to which the `location` prop should be provided
 */
export function withLocation<OwnProps extends { [key: string]: any }>(
  Comp: React.ComponentType<OwnProps & { location: RouteComponentProps['location'] }>
) {
  const WithLocationInner: React.FC<RouteComponentProps<any> & OwnProps> = ({
    location,
    match: _match,
    history: _history,
    staticContext: _staticContext,
    ...props
  }) => <Comp location={location} {...(props as any as OwnProps)} />;
  const WithLocation = withRouter(WithLocationInner);

  return WithLocation;
}

/**
 * A further specialization of `withLocation` that parses the current query params and returns the `subtab` parameter
 * under the `querySubtab` prop.
 *
 * @param {React.Component} Comp The component to which the `querySubtab` prop should be provided
 */
export function withQuerySubtab<OwnProps extends { [key: string]: any }>(
  Comp: React.ComponentType<OwnProps & { querySubtab: string }>
) {
  const WithQuerySubtabInner: React.FC<RouteComponentProps<any> & OwnProps> = ({ location, ...props }) => {
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const { subtab: querySubtab } = queryParams;

    return <Comp querySubtab={querySubtab} {...(props as any as OwnProps)} />;
  };
  const WithQuerySubtab = withLocation(WithQuerySubtabInner);

  return WithQuerySubtab;
}

const wrapDisplayName = (Comp: React.ComponentType<any>, wrapperName: string) =>
  `${wrapperName}(${Comp.displayName || 'Unknown'})`;

/**
 * HOC that adds a `displayName` to a component.
 *
 * @param {string} displayName
 */
export const withDisplayName = (displayName: string) => (Comp: React.ComponentType<any>) => {
  Comp.displayName = displayName;
  return Comp;
};

/**
 * A Higher Order Component that takes an object of props and a component and creates a new component that has those
 * extra props passed to it automatically.  Note that these added props will override any other props passed to the
 * created component explicitly.
 *
 * @param {object} addedProps An object of props to add to the provided `Comp`
 */
export function withProps<O extends { [key: string]: any }, P extends { [key: string]: any }>(addedProps: P) {
  return (Comp: React.ComponentType<O & P>) => {
    const WithProps: React.FC<O> = ({ ...props }) => <Comp {...props} {...addedProps} />;

    WithProps.displayName = wrapDisplayName(Comp, 'WithProps');
    return WithProps;
  };
}

/**
 * A HOC that maps the provided props object of a component to a new one using `mapPropsFn`.
 *
 * @param {func} mapPropsFn A function that maps the props object passed into the component into a new props object
 *  which will be supplied to the component.
 */
export function mapProps<O extends { [key: string]: any }, P extends { [key: string]: any }>(
  mapPropsFn: (existingProps: O) => P
) {
  return (Comp: React.ComponentType<O & P>) => {
    const WithMappedProps: React.FC<O & ReturnType<typeof mapPropsFn>> = ({ ...props }) => (
      <Comp {...props} {...mapPropsFn(props)} />
    );
    WithMappedProps.displayName = wrapDisplayName(Comp, 'WithMappedProps');
    return WithMappedProps;
  };
}

/**
 * A HOC that picks only props whose names exist in `propsToPick` and passes them through to `Comp`.
 *
 * @param {string[]} propsToPick An array of prop names to retain
 */
export const pickProps = (propsToPick: string[]) => (Comp: React.ComponentType<any>) => {
  const WithPickedProps: React.FC<any> = ({ ...props }) => <Comp {..._pick(props, propsToPick)} />;
  return WithPickedProps;
};
