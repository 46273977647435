import React, { useState } from 'react';
import colors from 'src/utils/colors';
import _get from 'lodash/get';
import { buildBaseTarget } from 'src/utils/app';
import _isEmpty from 'lodash/isEmpty';
import Radio from '@mui/material/Radio';

const getAmazonMarketFromRetailerId = (retailerId) => {
  switch (retailerId) {
    case 1:
      return 'Amazon-US';
    case 11:
      return 'Amazon-Canada';
    case 14:
      return 'Amazon-Germany';
    case 15:
      return 'Amazon-France';
    case 16:
      return 'Amazon-Japan';
    case 17:
      return 'Amazon-UK';
    case 28:
      return 'Amazon-India';
    case 29:
      return 'Amazon-Italy';
    case 30:
      return 'Amazon-Spain';
    case 31:
      return 'Amazon-Australia';
    case 32:
      return 'Amazon-Mexico';
    case 33:
      return 'Amazon-Brazil';
    case 53:
      return 'Amazon-UAE';
    case 55:
      return 'Amazon-Signapore';
    case 62:
      return 'Amazon-Netherlands';
    case 72:
      return 'Amazon-SA';
    case 74:
      return 'Amazon-TR';
    case 77:
      return 'Amazon-Sweden';
    case 90:
      return 'Amazon-PL';
    default:
      return '';
  }
};
// eslint-disable-next-line react/prop-types
const UserAuthorize = ({ serverErrorMessage, user }) => {
  const [value, setValue] = useState();
  const handleClick = () => {
    // eslint-disable-next-line no-alert
    alert('An Amazon retailer was not selected');
  };

  const getOnlyAmazonRetailers = (retailers) => {
    const amazonRetailers = new Map();
    if (retailers) {
      // eslint-disable-next-line no-restricted-syntax
      for (const retailerId of retailers.values()) {
        if ([1, 11, 14, 15, 16, 17, 28, 29, 30, 31, 32, 33, 53, 55, 62, 72, 74, 77, 90].includes(retailerId)) {
          amazonRetailers.set(getAmazonMarketFromRetailerId(retailerId), retailerId);
        }
      }
    }
    return amazonRetailers;
  };

  const getAllIntegratedRetailers = (allSellingPartnerIds) => {
    const integratedRetailers = new Map();
    if (allSellingPartnerIds) {
      // eslint-disable-next-line no-restricted-syntax
      for (const retailerId of Object.keys(allSellingPartnerIds)) {
        const marketplace = getAmazonMarketFromRetailerId(parseInt(retailerId, 10));
        const sellerAccounts = allSellingPartnerIds[retailerId];
        if (sellerAccounts && sellerAccounts.length !== 0) {
          integratedRetailers.set(marketplace, sellerAccounts);
        }
      }
    }
    return integratedRetailers;
  };

  const targetUrl = buildBaseTarget();
  const allData = _get(user, ['config', 'spAuthorize', 'Authorized']);
  const userRetailers = _get(user, ['config', 'allRetailerIds']);
  const userAmazonRetailers = getOnlyAmazonRetailers(userRetailers);
  const menu = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, val] of userAmazonRetailers) {
    menu.push({
      id: key,
      value: val
    });
  }

  const onChange = (newVal) => {
    setValue(newVal);
  };
  const sellerAccountsMap = getAllIntegratedRetailers(allData);
  let retailersWithSellerInfo;
  if (allData) {
    retailersWithSellerInfo = Object.entries(allData).map(([key, val]) => {
      if (!_isEmpty(val)) {
        return key;
      } else {
        return null;
      }
    });
  }
  const sellerArr = Array.from(sellerAccountsMap, ([name, val]) => ({
    name,
    value: val
  }));

  return (
    <form className="authorize">
      <h4 className="sl-header-text">Authorize Amazon Seller Accounts</h4>
      <hr className="sl-divider sl-divider--no-margin-top sl-divider--lg-margin-bottom" />
      {serverErrorMessage !== '' && (
        <div style={{ textAlign: 'center', fontSize: '16px', color: 'red', marginTop: '10px', marginBottom: '20px' }}>
          {serverErrorMessage}
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div className="select-container">
          <h3 className="sl-header-text" style={{ fontWeight: 'bold' }}>
            Authorize SP API
          </h3>
          <span>Please select an Amazon retailer from the following menu: </span>
          <br></br>
          {menu.map((val, idx) => (
            <div key={idx} style={{ display: 'flex', alignItems: 'center' }}>
              <Radio checked={value === val.value} onChange={() => onChange(val.value)} value={val.value} />
              {val.id}
            </div>
          ))}
          <br />

          {value && retailersWithSellerInfo.includes(`${value}`) ? (
            <div>
              Add and authorize another seller account by clicking{' '}
              <a
                style={{ color: colors.blue, cursor: 'pointer' }}
                href={`https://${targetUrl}/api/user/SpApiSignUp?retailerId=${value}`}
              >
                Sign Up
              </a>{' '}
            </div>
          ) : value ? (
            <div>
              Click{' '}
              <a
                style={{ color: colors.blue, cursor: 'pointer' }}
                href={`https://${targetUrl}/api/user/SpApiSignUp?retailerId=${value}`}
              >
                Sign Up
              </a>{' '}
              to authorize your selling partner account.
            </div>
          ) : (
            <div>
              Click{' '}
              <a role="button" style={{ color: colors.grey, cursor: 'pointer' }} onClick={handleClick}>
                Sign Up
              </a>{' '}
              to authorize your selling partner account.
            </div>
          )}
          <br />
          <br />
          <br />
        </div>
        <div className="integrated-container">
          <div className="table">
            <h3 className="sl-header-text" style={{ display: 'flex', fontWeight: 'bold', marginTop: 26 }}>
              Integrated Seller Accounts
            </h3>
            {sellerArr &&
              sellerArr.map(({ name, value: val }, idx) => {
                return (
                  <div key={idx} style={{ marginBottom: 10 }}>
                    <strong>{name}</strong>
                    {val &&
                      val.map((v, index) => {
                        return <div key={index}>{v}</div>;
                      })}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </form>
  );
};
export default UserAuthorize;
