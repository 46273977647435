import _uniq from 'lodash/uniq';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _orderBy from 'lodash/orderBy';
import _prop from 'lodash/property';
import _capitalize from 'lodash/capitalize';
import { TermFilter, Conditions, Entity } from 'sl-api-connector/types';
import { mergeConditions } from 'sl-api-connector/search/conditions';

import { buildBusinessUnitConditions } from 'src/components/EntityPage/Filters/businessUnitFilters';
import ReduxStore from 'src/types/store/reduxStore';
import convertMetricToDisplayValue from '../components/EntityGrid/gridUtils';
import { METRICTYPE } from 'src/utils/entityDefinitions';

const salesTabSubtabsToAddPlatformFilterTo = [
  'retailSales',
  'wholesaleSales',
  'unitsSold',
  'retailerGrossMargin',
  'retailerGrossMarginPercent',
  'retailPrice',
  'wholesalePrice'
];

export const shouldUsePlatformFilters = ({ tab, subtab }: ReduxStore['app']['queryParams']) =>
  tab === 'sales' && salesTabSubtabsToAddPlatformFilterTo.includes(subtab);

function buildConditions(type: string, values: any, { queryParams }: ReduxStore['app']) {
  if (!values) {
    return [];
  }

  switch (type) {
    case 'platform':
      if (!shouldUsePlatformFilters(queryParams)) {
        return [];
      }

      return [
        {
          fieldName: 'platformId',
          condition: 'must',
          values: values.map(_prop('id'))
        }
      ];
    case 'category':
      return [
        {
          fieldName: 'categoryId',
          condition: 'should',
          values: values.map(_prop('categoryId'))
        }
      ];
    case 'brand':
      return [
        {
          fieldName: 'brandId',
          condition: 'should',
          values
        }
      ];
    case 'subcategory':
      return [
        {
          fieldName: 'subCategoryId',
          condition: 'should',
          values: values.map(_prop('subCategoryId'))
        }
      ];
    case 'retailPrice':
      return [
        {
          fieldName: 'retailPrice',
          minValue: values.minValue,
          maxValue: values.maxValue
        }
      ];
    case 'promoType':
      return [
        {
          fieldName: 'promoType',
          condition: 'should',
          values: values.map(_prop('name'))
        }
      ];
    case 'chargeBackIssueType':
      return [
        {
          fieldName: 'issueTypeNormalized',
          condition: 'should',
          values: values.map(_prop('name'))
        }
      ];
    case 'vendorCode':
      return [
        {
          fieldName: 'vendorCode',
          condition: 'should',
          values: values.map(_prop('name'))
        }
      ];
    case 'chargeBackStatus':
      return [
        {
          fieldName: 'status',
          condition: 'should',
          values: values.map(_prop('name'))
        }
      ];
    default:
      return [];
  }
}

function combineKeywordValues(platform: TermFilter[], segment?: Conditions): TermFilter[] {
  const segmentKeywords = segment ? (segment.termFilters || []).filter((val) => val.fieldName === 'keyword') : [];
  const keywords = [...segmentKeywords, ...platform];
  const combinedKeywordValues: (number | string)[] = keywords.reduce(
    (accumulator, currentValue) => [...accumulator, ...(currentValue.values || [])],
    [] as (number | string)[]
  );

  return combinedKeywordValues.length === 0
    ? []
    : [
        {
          fieldName: 'keyword',
          condition: 'should' as const,
          values: combinedKeywordValues
        }
      ];
}

function combineCategoryValues(
  app: ReduxStore['app'],
  category: any[],
  segment: Conditions | null | undefined,
  categories: { categoryId: string | number }[]
) {
  const segmentCategories = segment ? (segment.termFilters || []).filter((val) => val.fieldName === 'categoryId') : [];
  let combinedCategories = [...segmentCategories, ...category];
  if (segmentCategories.length === 0 && (!category || category.length === 0)) {
    combinedCategories = categories.map((val) => val.categoryId);
  }

  const combinedCategoryValues = combinedCategories.reduce((acc, currentValue) => {
    let value = currentValue && currentValue.values;
    if (value === undefined) {
      value = currentValue !== undefined ? [currentValue] : [];
    }

    return [...acc, ...(_isObject(value) ? value : [value])]; // no idea how this works, but it gets called a lot
  }, []);

  const categoryConditions =
    combinedCategoryValues.length === 0
      ? []
      : [
          {
            fieldName: 'categoryId',
            condition: 'should',
            values: _uniq(combinedCategoryValues)
          }
        ];

  return categoryConditions;
}

//  Refactor to combine this function with the one above - this is a quick fix
function combineSubCategoryValues(
  subcategory: any,
  segment: Conditions | null | undefined,
  subcategories: { subCategoryId: string | number }[]
) {
  const segmentCategories = segment
    ? (segment.termFilters || []).filter((val) => val.fieldName === 'subCategoryId')
    : [];
  let combinedCategories: any = [...segmentCategories, ...subcategory];
  if (segmentCategories.length === 0 && (!subcategory || subcategory.length === 0)) {
    combinedCategories = subcategories && subcategories.map((val) => val.subCategoryId);
  }
  const combinedCategoryValues = combinedCategories.reduce((accumulator: any[], currentValue: any) => {
    let value = currentValue && currentValue.values;
    if (value === undefined) {
      value = currentValue !== undefined ? [currentValue] : [];
    }
    return [...accumulator, ...value];
  }, []);
  let subCategoryConditions: any;

  if (combinedCategoryValues.length === subcategories.length) {
    subCategoryConditions = [];
  } else {
    subCategoryConditions =
      combinedCategoryValues.length === 0
        ? []
        : [
            {
              fieldName: 'subCategoryId',
              condition: 'should',
              values: _uniq(combinedCategoryValues)
            }
          ];
  }
  return subCategoryConditions;
}

// TODO: make return type consistent
function buildFiltersDisplayName(
  retailer: ReduxStore['retailer'],
  filters: { [key: string]: any },
  currencySymbol: string,
  additionalParams: string,
  searchParams: string,
  app: ReduxStore['app']
) {
  let filter: { [key: string]: any } = { displayName: 'Custom Filters' };

  if (!_isEmpty(filters.businessUnits)) {
    return filter;
  } else if (
    filters.platform &&
    filters.platform.length === 1 &&
    !filters.segment &&
    !filters.category &&
    !filters.subcategory &&
    (!filters.retailPrice || (filters.retailPrice.minValue === 0 && filters.retailPrice.maxValue === 1000000))
  ) {
    filter = { displayName: `Platform: ${filters.platform[0].label}` };
  } else if (
    filters.subcategory &&
    filters.subcategory.length === 1 &&
    !filters.category &&
    !filters.platform &&
    !filters.segment &&
    (!filters.retailPrice || (filters.retailPrice.minValue === 0 && filters.retailPrice.maxValue === 1000000))
  ) {
    filter = {
      type: 'subcategory',
      id: filters.subcategory[0].id,
      displayName: `Subcategory: <a href="/subcategory/${filters.subcategory[0].id}${searchParams}${additionalParams}">${filters.subcategory[0].subCategoryName}</a>`,
      entityDisplayName: filters.subcategory[0].subCategoryName
    };
  } else if (
    filters.category &&
    filters.category.length === 1 &&
    !filters.subcategory &&
    !filters.platform &&
    !filters.segment &&
    (!filters.retailPrice || (filters.retailPrice.minValue === 0 && filters.retailPrice.maxValue === 1000000))
  ) {
    filter = {
      type: 'category',
      id: filters.category[0].categoryId,
      displayName: `Category: <a href="/category/${filters.category[0].id}${searchParams}${additionalParams}">${filters.category[0].categoryName}</a>`,
      href: `/category/${filters.category[0].id}${searchParams}${additionalParams}`,
      entityDisplayName: filters.category[0].categoryName
    };
  } else if (
    filters.segment &&
    !filters.subcategory &&
    !filters.category &&
    !filters.platform &&
    (!filters.retailPrice || (filters.retailPrice.minValue === 0 && filters.retailPrice.maxValue === 1000000))
  ) {
    filter = {
      type: 'segment',
      id: filters.segment.id,
      displayName: `Segment: <a href="/segment/${filters.segment.id}${searchParams}${additionalParams}">${filters.segment.dn}</a>`,
      href: `/segment/${filters.segment.id}${searchParams}${additionalParams}`,
      entityDisplayName: filters.segment.dn
    };
  } else if (
    !filters.segment &&
    !filters.subcategory &&
    !filters.category &&
    !filters.platform &&
    filters.retailPrice &&
    (filters.retailPrice.minValue !== 0 || filters.retailPrice.maxValue !== 1000000)
  ) {
    const minValue = convertMetricToDisplayValue(
      retailer,
      filters.retailPrice.minValue,
      METRICTYPE.MONEY,
      currencySymbol,
      true
    );
    const maxValue = convertMetricToDisplayValue(
      retailer,
      filters.retailPrice.maxValue,
      METRICTYPE.MONEY,
      currencySymbol,
      true
    );
    filter = { displayName: `Price Range: ${minValue} to ${maxValue}` };
  }

  if (app.name === 'omni') {
    filter = { displayName: 'Custom Filters' };
  }

  return filter;
}

export function isFilterApplied(filters: { [key: string]: any }, categories: unknown[]) {
  return (
    filters.platform ||
    filters.segment ||
    (filters.retailPrice && filters.retailPrice.minValue !== 0) ||
    (filters.retailPrice && filters.retailPrice.maxValue !== 1000000) ||
    (filters.category && filters.category.length !== categories.length) ||
    filters.brand ||
    filters.retailer ||
    (filters.subcategory && filters.subcategory.length > 0) ||
    !_isEmpty(filters.businessUnits)
  );
}

export function buildSubtitleDisplayName(
  retailer: ReduxStore['retailer'],
  entity: Entity & {
    categoryIds?: unknown[];
    subCategoryId: string | number;
    name: string;
    subCategoryName: string;
  },
  filters: { [key: string]: unknown },
  unsortedCategories: { categoryId: string | number; id: string | number; categoryName: string }[],
  app: ReduxStore['app']
) {
  // Sort the categories so that "All Categories" comes first and is properly detected
  const categories = _orderBy(unsortedCategories, ['id'], ['asc']);

  const { currencySymbol } = retailer;
  const entityCategoryIdExist = !_isEmpty(entity.categoryIds);

  const category = entityCategoryIdExist && categories.find((val) => entity.categoryIds!.includes(val.categoryId));

  const { additionalParams = undefined, searchParams = '' } = app ? app.queryParams : {};

  const buildDisplayName = (entityType: string, entityId: string | number, subtitleDisplayName: string) => {
    const href = `/${entityType}/${entityId}${searchParams}${additionalParams}`;

    return {
      type: entityType,
      id: entityId,
      displayName: `${_capitalize(entityType)}: <a href="${href}">${subtitleDisplayName}</a>`,
      href,
      subtitleDisplayName
    };
  };

  const subtitleByEntityType: {
    [entityType: string]: {
      type?: string;
      id?: string | number;
      displayName: string;
      subtitleDisplayName?: string;
    } | null;
  } = {
    searchtermlist: { displayName: 'Keyword List', subtitleDisplayName: 'Keyword List' },
    product: category ? buildDisplayName('category', category.id, category.categoryName) : null,
    category: category ? buildDisplayName(entity.type, category.id, category.categoryName) : null,
    subcategory: category ? buildDisplayName(entity.type, entity.subCategoryId, entity.subCategoryName) : null,
    segment: category ? buildDisplayName(entity.type, entity.id, entity.name) : null
  };
  const subtitle = subtitleByEntityType[entity.type] || {
    displayName: 'All Categories',
    entityDisplayName: 'All Categories',
    subtitleDisplayName: 'All Categories'
  };

  return isFilterApplied(filters, categories)
    ? buildFiltersDisplayName(retailer, filters, currencySymbol, additionalParams, searchParams, app)
    : subtitle;
}

// Refactor
export function combineFilterConditions(
  app: ReduxStore['app'],
  filters: { [key: string]: any },
  categories: {
    categoryId: string | number;
  }[],
  subcategories: ReduxStore['subCategories'],
  segments: ReduxStore['segments'],
  retailer?: ReduxStore['retailer']
): { conditions: Conditions; aggregationConditions: Conditions } {
  // stuff happens here
  const {
    brand,
    platform,
    segment,
    category,
    promoType,
    retailPrice,
    subcategory,
    chargeBackIssueType,
    vendorCode,
    chargeBackStatus
  } = filters;
  const categoryConditions = buildConditions('category', category, app);
  const brandConditions = buildConditions('brand', brand, app);
  const subCategoryConditions = buildConditions('subcategory', subcategory, app);
  const platformConditions = buildConditions('platform', platform, app);
  const promoTypeConditions = buildConditions('promoType', promoType, app);
  const chargeBackIssueTypeConditions = buildConditions('chargeBackIssueType', chargeBackIssueType, app);
  const vendorCodeConditions = buildConditions('vendorCode', vendorCode, app);
  const chargeBackStatusConditions = buildConditions('chargeBackStatus', chargeBackStatus, app);
  const retailPriceConditions = buildConditions('retailPrice', retailPrice, app);
  const keywordConditions = combineKeywordValues([], segment);
  const combinedCategoryConditions = combineCategoryValues(app, categoryConditions, segment, categories);
  const combinedSubCategoryConditions = combineSubCategoryValues(subCategoryConditions, segment, subcategories);
  const segmentConditions = {
    ...segment,
    termFilters:
      segment && segment.termFilters
        ? segment.termFilters.filter((val: TermFilter) => val.fieldName !== 'keyword' && val.fieldName !== 'categoryId')
        : []
  };
  const businessUnitConditions = buildBusinessUnitConditions(
    segments.businessUnits,
    segments.savedSearchesById!,
    retailer,
    new Set(filters.businessUnits)
  );

  return {
    conditions: mergeConditions(
      {
        termFilters: [
          ...brandConditions,
          ...combinedCategoryConditions,
          ...combinedSubCategoryConditions,
          ...keywordConditions,
          ...platformConditions,
          ...promoTypeConditions,
          ...chargeBackIssueTypeConditions,
          ...vendorCodeConditions,
          ...chargeBackStatusConditions,
          ...segmentConditions.termFilters
        ]
      },
      businessUnitConditions
    ),
    aggregationConditions: {
      termFilters: [...promoTypeConditions],
      rangeFilters: [...retailPriceConditions]
    }
  };
}

export default combineFilterConditions;
