import React from 'react';
import Typography from '@mui/material/Typography';
import colors from 'src/utils/colors';

export interface VisualizationTitleProps {
  /**
   * The title to display
   */
  title: string;
}

/**
 * A title with a tooltip that should be used
 * at the top of every visualization.
 */
const VisualizationTitle = ({ title }: VisualizationTitleProps) => {
  return (
    <Typography fontWeight="normal" color={colors.darkBlue} fontSize={28}>
      {title}
    </Typography>
  );
};

export default VisualizationTitle;
