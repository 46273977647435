import { Parser } from 'expr-eval';

import {
  DATATYPE,
  METRICTYPE,
  FieldDefinition,
  FIELD_PATH_SEPARATOR
} from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

export const BUYBOX_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  buyBoxWon: {
    name: 'buyBoxWon',
    displayName: 'Buy Box - Won',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum'
  },
  wins: {
    name: 'wins',
    displayName: 'Buy Box - Rate',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum',
    canBeExported: false
  },
  winsByClient: {
    name: 'winsByClient',
    displayName: 'Buy Box - Rate',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum',
    canBeExported: false
  },
  winsBySeller: {
    name: 'winsBySeller',
    displayName: 'Buy Box - Seller',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'sum',
    canBeExported: false
  },
  buyBoxWeighted: {
    name: 'buyBoxWeighted',
    displayName: 'Weighted Buy Box  Rate',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.DECIMAL,
    aggregationFunction: 'sum',
    canBeExported: false
  },
  winPercentage: {
    name: 'winPercentage',
    displayName: 'Buy Box - Rate',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(`buyBoxWeighted > 0 ? winsByClient / buyBoxWeighted : 0`),
    timePeriodAggregationFunctionType: 'simple',
    timePeriodAggregationFunction: 'avg'
  },
  lossPercentage: {
    name: 'lossPercentage',
    displayName: 'Buy Box - Loss',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(`buyBoxWeighted > 0 ? winsBySeller / buyBoxWeighted : 0`),
    timePeriodAggregationFunctionType: 'simple',
    timePeriodAggregationFunction: 'avg'
  },
  sellerWinPercentage: {
    name: 'sellerWinPercentage',
    displayName: 'Buy Box - Seller Win Rate',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'derived',
    aggregationFunction: expressionParser.parse(
      `buybox${FIELD_PATH_SEPARATOR}buyBoxWeighted>0?winsBySeller/buybox${FIELD_PATH_SEPARATOR}buyBoxWeighted:0`
    ),
    aggregationFunctionExpressionString: `buybox${FIELD_PATH_SEPARATOR}buyBoxWeighted>0?winsBySeller/buybox${FIELD_PATH_SEPARATOR}buyBoxWeighted:0`,
    timePeriodAggregationFunctionType: 'simple',
    timePeriodAggregationFunction: 'avg',
    dependantFieldOverrides: {
      primaryDependantFieldName: 'winsBySeller',
      overridesByFieldName: {
        [`buybox${FIELD_PATH_SEPARATOR}buyBoxWeighted`]: {
          dataFetchStrategy: 'parallel',
          groupByFieldName: 'retailerId',
          groupByResultType: 'onevalue'
        }
      }
    },
    overrides: {
      stacklineSku_Override: {
        aggregationFunctionType: 'derived',
        aggregationFunction: expressionParser.parse(
          `sales${FIELD_PATH_SEPARATOR}unitsSold>0?winsBySeller/sales${FIELD_PATH_SEPARATOR}unitsSold:0`
        ),
        aggregationFunctionExpressionString: `sales${FIELD_PATH_SEPARATOR}unitsSold>0?winsBySeller/sales${FIELD_PATH_SEPARATOR}unitsSold:0`,
        timePeriodAggregationFunctionType: 'simple',
        timePeriodAggregationFunction: 'avg',
        dependantFieldOverrides: {
          primaryDependantFieldName: 'winsBySeller',
          overridesByFieldName: {
            [`sales${FIELD_PATH_SEPARATOR}unitsSold`]: {
              dataFetchStrategy: 'chained',
              groupByFieldName: 'stacklineSku',
              groupByResultType: 'multivalue'
            }
          }
        }
      }
    }
  },
  sellerWinPercentageExport: {
    name: 'sellerWinPercentageExport',
    displayName: 'Buy Box - Win Rate',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold>0?(wins/unitsSold):0'),
    timePeriodAggregationFunctionType: 'simple',
    timePeriodAggregationFunction: 'avg'
  },
  merchantName: {
    name: 'merchantName',
    displayName: 'Seller Name',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'value_count',
    entity: {
      type: 'seller'
    }
  },
  merchantId: {
    name: 'merchantId',
    displayName: 'Seller Id',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'value_count',
    entity: {
      type: 'seller'
    }
  },
  unitsSold: {
    name: 'unitsSold',
    displayName: 'Units Sold',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum',
    canBeExported: true
  }
};
