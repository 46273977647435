import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';
// import _isEmpty from 'lodash/isEmpty';

import { NULL_UUID } from 'src/utils/constants';
import { mapConditionsToQuery } from 'src/utils/segments';
import Creators from './actions';
import parseSavedSearches from './selectors';
import ReduxStore from 'src/types/store/reduxStore';

export const { colors, clearAllSavedSearch, receiveAllSavedSearch } = Creators;

export const fetchAllSavedSearch =
  () =>
  async (dispatch: any, getState: () => ReduxStore): Promise<any> => {
    const {
      categories,
      retailer,
      app: { apiAppName: appName }
    } = getState();

    // If we haven't loaded categories yet, wait for them to load and try again.
    // if (_isEmpty(categories)) {
    //   return new Promise((resolve) => setTimeout(() => dispatch(fetchAllSavedSearch()).then(resolve), 100));
    // }

    const response = await axios.get(`/api/${appName}/GetAllSavedSearch?appName=${appName}&timestamp=${Date.now()}`);
    const { mySegments, teamSegments, mySearchTermLists, teamSearchTermLists, businessUnits } = parseSavedSearches(
      response.data,
      categories,
      retailer
    );

    return dispatch(
      receiveAllSavedSearch(mySegments, teamSegments, mySearchTermLists, teamSearchTermLists, businessUnits)
    );
  };

export const deleteRequestedSavedSearch = (appName: string, id: string) => () =>
  axios.post(`/api/${appName}/DeleteSavedSearch?queryId=${id}`);

export type Condition = 'should' | 'must' | 'must_not' | 'should.multi_match.phrase' | 'should.multi_match.exact';

export interface ConditionKeywordField {
  condition: Condition;
  values: { i: string | number }[];
}

export interface ConditionEntityField {
  condition: Condition;
  values: { i: string | number; n?: string | number | null }[];
}

export interface SegmentSavedSearchPayload {
  /**
   * These must be provided if
   */
  termFilters: {
    retailerId: { condition: Condition };
    keyword: ConditionKeywordField;
    keywordPhrase: ConditionKeywordField;
    keywordExact: ConditionKeywordField;
    excludedKeyword: ConditionKeywordField;
    excludedKeywordExact: ConditionKeywordField;
    searchTerm: ConditionKeywordField;
    searchTermFuzzy: ConditionKeywordField;
    searchKeyword: ConditionKeywordField;
    searchKeywordFuzzy: ConditionKeywordField;
    excludedSearchTerm: ConditionKeywordField;
    brandId: ConditionEntityField;
    parentBrandId: ConditionEntityField;
    categoryId: ConditionEntityField;
    subCategoryId: ConditionEntityField;
    excludedCategoryId: ConditionEntityField;
    excludedSubCategoryId: ConditionEntityField;
  };
  rangeFilters: {
    retailPrice: {
      minValue?: string | number | null;
      maxValue?: string | number | null;
    };
  };
  /**
   * The display name of the segment
   */
  dn: string;
  id: string;
}

export interface BusinessUnitSavedSearchPayload {
  /**
   * The display name of the business unit
   */
  dn: string;
  id?: string;
  /**
   * Contains a list of the IDs of all child segments and business units.
   */
  children: string[];
  /**
   * If set to true, the business unit will be displayed in the left bar and be usable as a filter on entity pages.
   */
  isTopLevel: boolean;
}

export type SavedSearchPayload = SegmentSavedSearchPayload | BusinessUnitSavedSearchPayload;

export const updateSavedSearch =
  (
    entityType: string,
    payload: SavedSearchPayload,
    id?: string,
    userId?: string,
    refreshSavedSearchesAfterSubmit = true,
    additionalParam = {}
  ) =>
  async (dispatch: ThunkDispatch<ReduxStore, void, any>, getState: () => ReduxStore) => {
    const { app, user } = getState();
    const query =
      entityType === 'businessunit'
        ? payload
        : {
            ...mapConditionsToQuery(payload),
            qv: 1.1,
            id: null,
            ...additionalParam
          };
    query.id = id || NULL_UUID;

    const res = await axios.post(`/api/${app.name}/UpdateSavedSearch`, {
      appName: app.name,
      entityType,
      query: JSON.stringify(query),
      queryId: id || NULL_UUID,
      userId: userId || user.session.userId
    });

    if (refreshSavedSearchesAfterSubmit) {
      await dispatch(fetchAllSavedSearch());
    }

    return res;
  };
