/**
 * This file renders the AGGridReact.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Waypoint from 'react-waypoint';
import _get from 'lodash/get';
import _ident from 'lodash/identity';
import _pick from 'lodash/pick';
import _sortBy from 'lodash/sortBy';

import { METRICTYPE } from 'src/utils/entityDefinitions';
import { EntityColumn } from 'src/components/Grids/Data/ColumnTypes';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import './EntityTableContainer.scss';
import convertMetricToDisplayValue from 'src/components/EntityGrid/gridUtils';
import CustomAgGridHeaderTemplate from 'src/components/EntityGrid/Table/CustomAgGridHeaderTemplate';
import { getAppName, shouldShowNewBeacon } from 'src/utils/app';
import { AppName } from 'sl-api-connector';
import BeaconProTable from 'src/components/Grids/Data/BeaconProTable';

const getAggregatedMetricsData = (props, index, currencySymbol) => {
  const { groupByField, metricFields, dataSet, retailer } = props;

  if (!metricFields) {
    return {};
  }

  return metricFields.reduce(
    (acc, metricField) => {
      const dataKey = `${metricField.name}_by_${groupByField.name}`;
      const entityData = dataSet[dataKey];
      if (!entityData || !entityData.data) {
        return acc;
      }
      const datum = entityData.data[index];

      if (!datum) {
        return acc;
      }

      if (props.disableMetricFormatting) {
        acc[metricField.name] = datum.value;
        acc[`${metricField.name}PercentChange`] = datum.cardView[`${metricField.name}PercentChange`];
        return acc;
      }

      return {
        ...acc,
        [metricField.name]: convertMetricToDisplayValue(
          retailer,
          datum.value,
          entityData.metricType,
          currencySymbol,
          true
        ),
        [`${metricField.name}PercentChange`]: convertMetricToDisplayValue(
          retailer,
          datum.cardView[`${metricField.name}PercentChange`],
          METRICTYPE.PERCENT,
          currencySymbol,
          true
        )
      };
    },
    { rawData: dataSet }
  );
};

const buildRowsDefault = (props) => {
  const {
    mainMetricField,
    groupByField,
    metricFields,
    dataSet,
    retailer,
    rowsToRender,
    filterRows,
    sortRows,
    computePerRowAggregatedMetrics
  } = props;

  const allRows = [];

  if (!dataSet) {
    return allRows;
  }

  const aggregationData = Array.isArray(dataSet)
    ? dataSet
    : _get(dataSet, [`${mainMetricField.name}_by_${groupByField.name}`, `data`]);
  // Apply the `filterRows` filter to all row data before paginating
  // We add a `srcIx` prop to each datum to mark its original, pre-filtered index in the source data set.  This
  // allows us to pick data from the other aggregations based on index.
  const fullEntityData = Array.from(aggregationData || dataSet.documentData);
  const fullFilteredEntityData = fullEntityData
    .map((datum, i) => {
      // Since it may be necessary to use data from aggregations other than the main metric to sort the rows, we
      // spread that data in manually here before passing it into the sorter function.
      const currencySymbol = _get(datum, ['cardView', 'metricData', 'currencySymbol']);
      const aggregationMetricsData =
        currencySymbol && computePerRowAggregatedMetrics ? getAggregatedMetricsData(props, i, currencySymbol) : null;

      datum.srcIx = i;

      if (aggregationMetricsData) {
        return { ...datum, ...aggregationMetricsData };
      }

      datum.rawData = props.dataSet;
      return datum;
    })
    .filter(filterRows);
  const fullSortedFilteredEntityData = sortRows ? _sortBy(fullFilteredEntityData, sortRows) : fullFilteredEntityData;
  // If the `rowsToRender` prop is supplied, manually limit the number of rows that we render.
  const entityData = rowsToRender ? fullSortedFilteredEntityData.slice(0, rowsToRender) : fullSortedFilteredEntityData;

  if (!entityData) {
    return [];
  }

  entityData.forEach((item) => {
    const { cardView, srcIx } = item;
    const { secondaryDisplayTitle, metricData = {}, stacklineSku, brandId } = cardView;
    const { currencySymbol, metricType, name } = metricData;

    if (metricFields) {
      const baseRowProps = {
        stacklineSku: { type: 'product', id: stacklineSku },
        brandId: { type: 'brand', id: brandId }
      }[groupByField.name] || { id: secondaryDisplayTitle };

      const row = {
        ...item,
        ...baseRowProps,
        name: secondaryDisplayTitle,
        ...(currencySymbol ? getAggregatedMetricsData(props, srcIx, currencySymbol) : {}),
        srcIx
      };

      allRows.push(row);
    } else {
      const rowBase = {
        name: secondaryDisplayTitle,
        currentValue: convertMetricToDisplayValue(
          retailer,
          cardView[`${name}CurrentValue`],
          metricType,
          currencySymbol,
          true
        ),
        previousValue: convertMetricToDisplayValue(
          retailer,
          cardView[`${name}PreviousValue`],
          metricType,
          currencySymbol,
          true
        ),
        percentChange: convertMetricToDisplayValue(
          retailer,
          cardView[`${name}PercentChange`],
          METRICTYPE.PERCENT,
          currencySymbol,
          true
        )
      };

      if (groupByField.name === 'stacklineSku') {
        allRows.push({ ...rowBase, id: stacklineSku, type: 'product' });
      } else if (groupByField.name === 'brandId') {
        allRows.push({ ...rowBase, id: brandId, type: 'brand' });
      } else {
        allRows.push({ ...rowBase, id: secondaryDisplayTitle });
      }
    }
  });

  allRows.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (key.includes('.')) {
        row[key.replace('.', '')] = row[key];
      }
    });
  });
  return allRows;
};

class EntityTableContainer extends Component {
  static propTypes = {
    dataSet: PropTypes.any.isRequired,
    mainMetricField: PropTypes.object.isRequired,
    groupByField: PropTypes.object,
    metricFields: PropTypes.array,
    onWaypointEntered: PropTypes.func,
    onSortClick: PropTypes.func.isRequired,
    sortDirection: PropTypes.string.isRequired,
    sortByMetric: PropTypes.string,
    firstColumnDefOverrides: PropTypes.object,
    buildRowsOverrides: PropTypes.func,
    filterRows: PropTypes.func,
    sortRows: PropTypes.func,
    rowsToRender: PropTypes.number,
    computePerRowAggregatedMetrics: PropTypes.bool,
    disableMetricFormatting: PropTypes.bool,
    agGridProps: PropTypes.object,
    // Redux Props
    comparisonTimePeriod: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    overlayNoRowsTemplate: PropTypes.node,
    style: PropTypes.object,
    isLoading: PropTypes.bool,
    pageNumber: PropTypes.number
  };

  static defaultProps = {
    metricFields: null,
    firstColumnDefOverrides: {},
    buildRowsOverrides: undefined,
    filterRows: () => true,
    sortRows: undefined,
    rowsToRender: undefined,
    sortByMetric: undefined,
    computePerRowAggregatedMetrics: true,
    disableMetricFormatting: false,
    overlayNoRowsTemplate: undefined,
    agGridProps: {},
    groupByField: undefined,
    onWaypointEntered: undefined,
    style: undefined
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    params.api.sizeColumnsToFit();
  };

  getFirstColumnDef = (props) => {
    const { groupByField, firstColumnDefOverrides } = props;
    if (!groupByField) {
      return null;
    }
    const baseColumnDef = {
      field: 'custom',
      // pinned: 'left',
      minWidth: 150
    };

    const defaultFirstColumnDef = {
      headerName: `${groupByField.displayName}`,
      field: 'name',
      minWidth: 200,
      maxWidth: 1000,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left'
    };

    const firstColumnDefBase = {
      ...baseColumnDef,
      cellRendererFramework: EntityColumn,
      cellStyle: {
        'justify-content': 'flex-start',
        'text-align': 'left',
        border: 0,
        'flex-direction': 'row'
      },
      headerClass: 'align-left'
    };

    const firstColumnDef = {
      stacklineSku: { ...firstColumnDefBase, headerName: 'Products' },
      brandId: { ...firstColumnDefBase, headerName: 'Brands' },
      campaignId: { ...firstColumnDefBase, headerName: 'Campaigns' },
      portfolioId: { ...firstColumnDefBase, headerName: 'Portfolios' },
      entityId: { ...firstColumnDefBase, headerName: 'Entities' },
      categoryId: { ...firstColumnDefBase, headerName: 'Category' },
      subCategoryId: { ...firstColumnDefBase, headerName: 'Subcategory' }
    }[groupByField.name];

    return { ...(firstColumnDef || defaultFirstColumnDef), ...firstColumnDefOverrides };
  };

  buildMetricFieldColumnDefs = () =>
    this.props.metricFields.map((metricField) => ({
      ...metricField,
      headerName: `${metricField.displayName}`,
      field: metricField.name,
      width: metricField.width || 50,
      headerComponentFramework: metricField.headerComponentFramework || CustomAgGridHeaderTemplate,
      cellRendererFramework: metricField.cellRendererFramework,
      onSortFunction: metricField.onSortFunction || (() => this.props.onSortClick(metricField.name)),
      sortDirection: this.props.sortDirection,
      sortByMetric: this.props.sortByMetric,
      displayName: undefined,
      cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
    }));

  buildNonMetricFieldColumnDefs = () => {
    const { comparisonTimePeriod, mainTimePeriod, onSortClick, sortDirection } = this.props;

    const columnDefBase = { minWidth: 150, maxWidth: 155 };
    const comparator = (a, b) => a.replace(/[^\d\- ]/g, '') - b.replace(/[^\d\- ]/g, '');

    return [
      {
        ...columnDefBase,
        headerName: `${mainTimePeriod.shortDisplayName}`,
        field: 'currentValue',
        headerComponentFramework: CustomAgGridHeaderTemplate,
        onSortFunction: onSortClick,
        sortDirection,
        displayName: undefined,
        cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
      },
      {
        ...columnDefBase,
        headerName: `${comparisonTimePeriod.shortDisplayName}`,
        field: 'previousValue',
        comparator,
        displayName: undefined,
        cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
      },
      {
        ...columnDefBase,
        headerName: 'Percentage Change',
        field: 'percentChange',
        comparator,
        displayName: undefined,
        cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
      }
    ];
  };

  render() {
    const primaryColumnDefs = this.props.metricFields
      ? this.buildMetricFieldColumnDefs()
      : this.buildNonMetricFieldColumnDefs();
    const columnDefs = [this.getFirstColumnDef(this.props), ...primaryColumnDefs].filter(_ident).map((col) => ({
      ...col,
      field: col.field.replace('.', ''),
      name: (col.name || '').replace('.', ''),
      sortByMetric: (col.field.sortByMetric || '').replace('.', '')
    }));
    const buildRows = () => {
      if (this.props.buildRowsOverrides) {
        return this.props.buildRowsOverrides(this.props);
      } else {
        return buildRowsDefault(this.props);
      }
    };
    const hideTableOverflow = getAppName() !== AppName.Advertising;

    if (shouldShowNewBeacon()) {
      return (
        <BeaconProTable
          columnDefs={columnDefs}
          rowData={buildRows()}
          isLoading={this.props.isLoading}
          lastFetchedPage={this.props.pageNumber}
          fetchData={this.props.onWaypointEntered}
        />
      );
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'row', ...(this.props.style || {}) }}>
        <div style={{ overflow: hideTableOverflow ? 'hidden' : 'visible', flexGrow: '1' }}>
          <CustomAgMaterial
            onGridReady={this.onGridReady}
            onCellValueChanged={this.onGridReady}
            onModelUpdated={this.onGridReady}
            onRowValueChanged={this.onGridReady}
            onRowDataChanged={this.onGridReady}
            buildRows={buildRows}
            columnDefs={columnDefs}
            overlayNoRowsTemplate={this.props.overlayNoRowsTemplate}
            {...this.props.agGridProps}
          >
            {this.props.onWaypointEntered ? <Waypoint onEnter={this.props.onWaypointEntered} /> : null}
            <br />
            <br />
          </CustomAgMaterial>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => _pick(state, ['comparisonTimePeriod', 'mainTimePeriod', 'retailer']);

export default withRouter(connect(mapStateToProps)(EntityTableContainer));
