import types from './types';

export const receiveOmniProductServiceDataForMain = (entity: any) => ({
  type: types.RECEIVE_PRODUCT_DATA_FOR_MAIN,
  entity
});

export const receiveOmniProductServiceDataForCompare = (entity: any) => ({
  type: types.RECEIVE_PRODUCT_DATA_FOR_COMPARE,
  entity
});

export const startFetchData = (entity: any) => ({
  type: types.START_FETCH,
  entity
});

export const endFetchData = (entity: any) => ({
  type: types.END_FETCH,
  entity
});
