import { Entity } from 'sl-api-connector';

import colors from 'src/utils/colors';
import AdManagerEntityHeader from 'src/components/AdManager/AdManagerEntityHeader';
import { Widget } from 'src/types/application/widgetTypes';

export const buildAdManagerHeaderWidget = (entity: Entity, title: string): Widget => ({
  CustomComponent: AdManagerEntityHeader,
  name: 'adManagerHeader',
  view: {
    name: 'adManagerHeader',
    title,
    container: {
      style: { borderBottom: `1px solid ${colors.lightestGrey}`, marginBottom: 50 }
    }
  },
  data: {
    mainEntity: entity,
    adScoreName: 'adScore'
  }
});
