import axios from 'axios';
import Creators from './actions';
import _cloneDeep from 'lodash/cloneDeep';
import { modifyESQuery } from 'src/components/AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';
import { getParentPlatform } from 'src/utils/browser';

export const { receiveAdGroups } = Creators;

export const fetchAllAdGroups = () => (dispatch, getState) => {
  const { retailer, mainTimePeriod } = getState();
  const parentPlatform = getParentPlatform();

  const payload = [
    {
      name: 'adGroups',
      id: 'adGroups',
      pageNumber: 1,
      pageSize: 100000,
      period: 'year',
      doAggregation: true,
      returnDocuments: false,
      conditions: {
        termFilters: [
          {
            fieldName: 'adGroupNameFuzzy',
            values: [],
            condition: 'should'
          },
          {
            fieldName: 'campaignId',
            values: [],
            condition: 'should'
          },
          {
            fieldName: 'entityId',
            values: [],
            condition: 'should'
          },
          {
            fieldName: 'portfolioId',
            values: [],
            condition: 'should'
          },
          {
            fieldName: 'parentPlatform',
            condition: 'must',
            values: [parentPlatform]
          },
          {
            fieldName: 'parentPlatform',
            condition: 'must',
            values: [parentPlatform]
          }
        ],
        rangeFilters: [
          {
            fieldName: 'dayId',
            minValue: mainTimePeriod.startDayId,
            maxValue: mainTimePeriod.endDayId
          }
        ]
      },
      searchBy: 'parent',
      aggregations: [
        {
          aggregationFields: [
            {
              aggregateByFieldDisplayName: 'Line Item',
              aggregateByFieldName: 'adGroupId',
              function: 'cardinality',
              canBeExported: true
            }
          ],
          conditions: {
            termFilters: [
              {
                fieldName: 'retailerId',
                values: [`${retailer.id}`]
              },
              {
                fieldName: 'isProjected',
                condition: 'must',
                values: [0]
              },
              {
                fieldName: 'parentPlatform',
                condition: 'must',
                values: [parentPlatform]
              }
            ],
            rangeFilters: [
              {
                fieldName: 'dayId',
                minValue: mainTimePeriod.startDayId,
                maxValue: mainTimePeriod.endDayId
              }
            ],
            computeFilters: []
          },
          groupByFieldName: 'retailerId'
        }
      ],
      searchType: 'advertising-adCampaignAdGroupDailyMetrics',
      parentPlatform,
      processDocuments: false
    }
  ];

  let copyOfOVerrides = _cloneDeep(payload);
  if (retailer.id !== 0) {
    const newOverride = [];
    copyOfOVerrides.forEach((_, indx) => {
      const mutatedQuery = modifyESQuery(copyOfOVerrides[indx], [
        {
          action: 'update',
          path: ['retailerId'],
          newObj: `${retailer.id}`
        },
        {
          action: 'add',
          path: ['conditions', 'termFilters'],
          newObj: {
            fieldName: 'retailerId',
            condition: 'should',
            values: [`${retailer.id}`]
          }
        }
      ]);
      newOverride.push(mutatedQuery);
    });
    copyOfOVerrides = newOverride;
  }

  return axios.post(`/api/advertising/AdvancedSearch?_id=fetchAdAllGroups`, copyOfOVerrides).then(({ data }) => {
    return dispatch(receiveAdGroups(data));
  });
};
