import React, { useMemo } from 'react';
import WaterfallChartV2 from 'src/components/BeaconRedesignComponents/WaterfallChart/WaterfallChartV2';
import BeaconChartWithLegend from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import { useStacklineTheme } from '@stackline/ui';
import useWaterfallData from './useWaterfallData';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import SplineChartLoading from 'src/components/BeaconRedesignComponents/SplineChartLoading/SplineChartLoading';

/**
 * Waterfall chart for the summary page
 */
export default function BeaconProWaterfallChart() {
  const theme = useStacklineTheme();
  const { data: waterfallData, isLoading, percentChange } = useWaterfallData();
  const mainDisplayName = useAppSelector((state) => state.mainTimePeriod.displayName);
  const comparisonDisplayName = useAppSelector((state) => state.comparisonTimePeriod.displayName);
  const formatMetric = useMetricFormatter();

  const otherVariablesSum = useMemo(() => {
    if (!waterfallData) {
      return 0;
    }
    const unitsSoldWithDiffs = [
      waterfallData.priorUnitsSold_sum_value,
      waterfallData.organicTrafficScaledContribution_sum_value,
      waterfallData.otherTrafficScaledContribution_sum_value,
      waterfallData.paidTrafficScaledContribution_sum_value,
      waterfallData.retailPriceScaledContribution_sum_value,
      waterfallData.contentScoreScaledContribution_sum_value,
      waterfallData.ratingScaledContribution_sum_value,
      waterfallData.inStockRateScaledContribution_sum_value,
      waterfallData.buyBoxScaledContribution_sum_value
    ].reduce((acc, value) => acc + value, 0);

    return waterfallData.currentUnitsSold_sum_value - unitsSoldWithDiffs;
  }, [waterfallData]);

  return (
    <BeaconChartWithLegend
      title="Waterfall"
      disableExportCsv
      primaryLegendProps={{
        displayName: mainDisplayName,
        metric: waterfallData
          ? `${formatMetric(waterfallData.currentUnitsSold_sum_value, METRICTYPE.VOLUME, {
              showFullValue: false,
              decimalPlaces: 2
            })}`
          : '',
        change: `${formatMetric(percentChange, METRICTYPE.PERCENT, { decimalPlaces: 2 })}`.replace('-', ''),
        direction: percentChange >= 0 ? 'increasing' : 'decreasing'
      }}
      comparisonLegendProps={{
        displayName: comparisonDisplayName,
        metric: waterfallData
          ? `${formatMetric(waterfallData.priorUnitsSold_sum_value, METRICTYPE.VOLUME, {
              showFullValue: false,
              decimalPlaces: 2
            })}`
          : ''
      }}
      loading={isLoading}
      loadingComponent={<SplineChartLoading />}
    >
      <WaterfallChartV2
        getData={() => {
          if (isLoading || !waterfallData) {
            return [];
          }
          return [
            {
              color: theme.colors.casper,
              displayName: 'Units Sold\n(Prior)',
              y: waterfallData.priorUnitsSold_sum_value
            },
            {
              displayName: 'Organic\nTraffic',
              y: waterfallData.organicTrafficScaledContribution_sum_value
            },
            {
              displayName: 'Other\nTraffic',
              y: waterfallData.otherTrafficScaledContribution_sum_value
            },
            {
              displayName: 'Paid\nTraffic',
              y: waterfallData.paidTrafficScaledContribution_sum_value
            },
            {
              displayName: 'Retail\nPrice',
              y: waterfallData.retailPriceScaledContribution_sum_value
            },
            {
              displayName: 'Content\nScore',
              y: waterfallData.contentScoreScaledContribution_sum_value
            },
            {
              displayName: 'Ratings\n& Reviews',
              y: waterfallData.ratingScaledContribution_sum_value
            },
            {
              displayName: 'In-Stock\nRate',
              y: waterfallData.inStockRateScaledContribution_sum_value
            },
            {
              displayName: 'Buy Box\nRate',
              y: waterfallData.buyBoxScaledContribution_sum_value
            },
            {
              displayName: 'Other\nVariables',
              y: otherVariablesSum
            },
            {
              color: theme.colors.primary,
              displayName: 'Units Sold\n(Current)',
              y: waterfallData.currentUnitsSold_sum_value
            }
          ];
        }}
      />
    </BeaconChartWithLegend>
  );
}
