// Most of the time you visit this file would be conditionally rendering tab and subTabs
// Please refer `conditionallyMergedChildRoutes` and `currentRoutes` where we can conditionally render tab and subTab

import { buildTabAndSubtabLink } from 'src/components/BeaconRedesignComponents/SubtabNav/SubtabNavigation';
import { useAppSelector, useLocation } from 'src/utils/Hooks';
import { onChargebacksMode, onDisplayAdvertisingMode, onShortagesMode } from 'src/store/modules/user/selectors';
import { getQueryParamValue, isApple, shouldShowPromotionPage, shouldShowRatingsAndReviewsV2 } from 'src/utils/app';
import { TabRoute, TabRouteWithoutIndex } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/types';

export enum BEACON_TABS {
  SUMMARY = 'sales',
  FORECASTS = 'forecasts',
  SALES = 'sales',
  TRAFFIC = 'traffic',
  PRICES = 'prices',
  CONTENT = 'content',
  BUY_BOX = 'buybox',
  REVIEWS = 'reviews',
  INVENTORY = 'inventory',
  OPERATIONS = 'operations',
  CONVERSION = 'conversion',
  COMPLIANCE = 'compliance' // Apple only
}

export enum BEACON_SUBTABS {
  KEY_METRICS = 'keymetrics',
  GROWTH_MATRIX = 'growthmatrix',
  WATERFALL = 'waterfall',
  FORECAST_SUMMARY = 'forecastSummary',
  FORECAST_PLANS = 'forecastsPlans',
  FORECAST_ADJUSTMENTS = 'forecastsAdjustments',
  FORECAST_RECOMMENDATIONS = 'recommendations',
  RETAIL_SALES = 'retailSales',
  UNITS_SOLD = 'unitsSold',
  MARGINS = 'retailerGrossMargin',
  RETURNS = 'returnRate',
  TOTAL_CLICKS = 'totalClicks',
  ORGANIC_CLICKS = 'organicClicks',
  OTHER_CLICKS = 'otherClicks',
  AD_CLICKS = 'adClicks',
  DISPLAY_CLICKS = 'dpvViews',
  RETAIL_PRICE = 'retailPrice',
  CONTENT_SCORE = 'contentScore',
  CONTENT_ACCURACY = 'contentAccuracy',
  WIN_PERCENTAGE = 'winPercentage',
  SELLERS = 'sellers',
  // labeled as Summary in BeaconPro
  REVIEW_TRENDS = 'reviewTrends',
  REVIEW_RATINGS = 'reviewRatings',
  REVIEW_REVIEW = 'reviews',
  REVIEW_SENTIMENT = 'reviewSentiment',
  INVENTORY = 'inventory',
  SHORTAGES = 'shortages',
  DISPUTES = 'disputes',
  PURCHASE_RATE = 'purchaseRate',
  MAP_VIOLATIONS = 'mapPrice',
  CHARGEBACKS = 'chargebacks',
  PROMOTIONS = 'promotions',
  // Apple only subtabs
  KEYWORD_COMPLIANCE = 'keywordCompliance', // TODO: Figure out significance -> &ctab=compliance-keywordaditem&csubtab=unauthorized&ctype=metric
  SELLER_COMPLIANCE = 'sellerCompliance' // TODO: Figure out significance -> &ctab=compliance-selleritem&csubtab=unauthorized&ctype=metric
}

/**
 * Takes in the location from React Router and a collection of tab routes and returns the active route based on the URL.
 */
const findCurrentTabAndSubtab = ({ location, childRoutes }) => {
  let currentRoute: TabRoute; // The tab route object we return at the end
  let currentSubtab: string; // The selected subtab as a string if applicable
  try {
    const currentParams = new URLSearchParams(location.search);
    const currentTab = currentParams.get('tab');
    currentSubtab = currentParams.get('subtab');

    /**
     * The purpose of the following logic is to map the old Beacon navigation to the new navigation components.
     * For each route object, we create an collection of the subtab values and subtab parent values.
     * The subtab values are the new subtabs per each new Beacon page. i.e. Key Metrics, Product Growth, Waterfall under the Summary sidebar tab.
     * The subtab parents are just the tabs that are used to render page layouts based on the old system. Using the previous example, this would NOT be "Summary" but "Sales".
     * We do 2 different checks: The first tries to determine a match based on tab and subtab values.
     * The second match is a fallback when the sidebar tab does not have child subtabs.
     */
    currentRoute = childRoutes.find((route) => {
      const childSubtabValues = route.childSubtabs.map((subtab) => subtab.value);
      const childSubtabParentTabs = route.childSubtabs.map((subtab) => subtab.parentTab);

      return (
        (childSubtabValues.includes(currentSubtab) && childSubtabParentTabs.includes(currentTab)) ||
        (route.tabMappedFrom === currentTab && route.subtabMappedFrom === currentSubtab)
      );
    });

    if (!currentRoute) {
      throw new Error('No matching route found');
    }
  } catch (error) {
    console.error(error);
    [currentRoute] = childRoutes;
    currentSubtab = currentRoute.childSubtabs[0].value;
  }

  return { currentRoute, currentSubtab };
};

// ! TODO: Insights pages at some point

/**
 * @param location the location object from react router
 * @returns a collection of all tab routes and the current route that is active for use in AppLinkTabs
 */
export const useBeaconRoutes = () => {
  const location = useLocation();
  const app = useAppSelector((state) => state.app);
  const entityType = useAppSelector((state) => state.entityService.mainEntity.type);

  const showGrowthMatrix = ['client', 'brand', 'category', 'subcategory', 'product'].includes(entityType);

  const childRoutes: TabRouteWithoutIndex[] = [
    {
      label: 'Summary',
      tabMappedFrom: BEACON_TABS.SALES,
      subtabMappedFrom: BEACON_SUBTABS.KEY_METRICS,
      route: buildTabAndSubtabLink({ app, location, tab: BEACON_TABS.SALES, subtab: BEACON_SUBTABS.KEY_METRICS }),
      childSubtabs: [
        { label: 'Key Metrics', value: BEACON_SUBTABS.KEY_METRICS, parentTab: BEACON_TABS.SALES },
        ...(showGrowthMatrix
          ? [
              {
                label: 'Growth Matrix',
                value: BEACON_SUBTABS.GROWTH_MATRIX,
                parentTab: BEACON_TABS.SALES
              }
            ]
          : []),
        { label: 'Waterfall', value: BEACON_SUBTABS.WATERFALL, parentTab: BEACON_TABS.SALES }
      ]
    },
    {
      label: 'Forecasts',
      tabMappedFrom: BEACON_TABS.FORECASTS,
      subtabMappedFrom: BEACON_SUBTABS.FORECAST_SUMMARY,
      route: buildTabAndSubtabLink({
        app,
        location,
        tab: BEACON_TABS.FORECASTS,
        subtab: BEACON_SUBTABS.FORECAST_SUMMARY,
        defaultQueryParams: { forecastPeriod: 'fullYear', forecastType: 'adjusted' }
      }),
      childSubtabs: [
        { label: 'Summary', value: BEACON_SUBTABS.FORECAST_SUMMARY, parentTab: BEACON_TABS.FORECASTS },
        {
          label: 'Plans',
          value: BEACON_SUBTABS.FORECAST_PLANS,
          parentTab: BEACON_TABS.FORECASTS,
          defaultQueryParams: { adjustmentStatus: ['Published', 'Draft'] }
        },
        {
          label: 'Adjustments',
          value: BEACON_SUBTABS.FORECAST_ADJUSTMENTS,
          parentTab: BEACON_TABS.FORECASTS,
          defaultQueryParams: { adjustmentStatus: ['Published', 'Draft'] }
        },
        {
          label: 'Recommendations',
          value: BEACON_SUBTABS.FORECAST_RECOMMENDATIONS,
          parentTab: BEACON_TABS.FORECASTS
        }
      ]
    },
    {
      label: 'Sales',
      tabMappedFrom: BEACON_TABS.SALES,
      subtabMappedFrom: BEACON_SUBTABS.RETAIL_SALES,
      route: buildTabAndSubtabLink({ app, location, tab: BEACON_TABS.SALES, subtab: BEACON_SUBTABS.RETAIL_SALES }),
      childSubtabs: [
        { label: 'Retail Sales', value: BEACON_SUBTABS.RETAIL_SALES, parentTab: BEACON_TABS.SALES },
        { label: 'Units Sold', value: BEACON_SUBTABS.UNITS_SOLD, parentTab: BEACON_TABS.SALES },
        { label: 'Margins', value: BEACON_SUBTABS.MARGINS, parentTab: BEACON_TABS.SALES },
        { label: 'Returns', value: BEACON_SUBTABS.RETURNS, parentTab: BEACON_TABS.SALES }
      ]
    },
    {
      label: 'Traffic',
      tabMappedFrom: BEACON_TABS.TRAFFIC,
      subtabMappedFrom: BEACON_SUBTABS.TOTAL_CLICKS,
      route: buildTabAndSubtabLink({ app, location, tab: BEACON_TABS.TRAFFIC, subtab: BEACON_SUBTABS.TOTAL_CLICKS }),
      childSubtabs: [
        { label: 'Total', value: BEACON_SUBTABS.TOTAL_CLICKS, parentTab: BEACON_TABS.TRAFFIC },
        { label: 'Organic', value: BEACON_SUBTABS.ORGANIC_CLICKS, parentTab: BEACON_TABS.TRAFFIC },
        { label: 'Other', value: BEACON_SUBTABS.OTHER_CLICKS, parentTab: BEACON_TABS.TRAFFIC },
        { label: 'Paid Search', value: BEACON_SUBTABS.AD_CLICKS, parentTab: BEACON_TABS.TRAFFIC },
        ...(onDisplayAdvertisingMode()
          ? [{ label: 'Display', value: BEACON_SUBTABS.DISPLAY_CLICKS, parentTab: BEACON_TABS.TRAFFIC }]
          : []),
        { label: 'Conversion', value: BEACON_SUBTABS.PURCHASE_RATE, parentTab: BEACON_TABS.CONVERSION }
      ]
    },
    {
      label: 'Prices',
      tabMappedFrom: BEACON_TABS.SALES,
      subtabMappedFrom: BEACON_SUBTABS.RETAIL_PRICE,
      route: buildTabAndSubtabLink({ app, location, tab: BEACON_TABS.SALES, subtab: BEACON_SUBTABS.RETAIL_PRICE }),
      childSubtabs: [
        { label: 'Summary', value: BEACON_SUBTABS.RETAIL_PRICE, parentTab: BEACON_TABS.SALES },
        ...(shouldShowPromotionPage()
          ? [{ label: 'Promotions', value: BEACON_SUBTABS.PROMOTIONS, parentTab: BEACON_TABS.SALES }]
          : []),
        { label: 'MAP Violations', value: BEACON_SUBTABS.MAP_VIOLATIONS, parentTab: BEACON_TABS.SALES }
      ]
    },
    {
      label: 'Content',
      tabMappedFrom: BEACON_TABS.CONTENT,
      subtabMappedFrom: BEACON_SUBTABS.CONTENT_SCORE,
      route: buildTabAndSubtabLink({ app, location, tab: BEACON_TABS.CONTENT, subtab: BEACON_SUBTABS.CONTENT_SCORE }),
      childSubtabs: [
        { label: 'Score', value: BEACON_SUBTABS.CONTENT_SCORE, parentTab: BEACON_TABS.CONTENT },
        { label: 'Accuracy', value: BEACON_SUBTABS.CONTENT_ACCURACY, parentTab: BEACON_TABS.CONTENT }
      ]
    },
    {
      label: 'Buy Box',
      tabMappedFrom: BEACON_TABS.BUY_BOX,
      subtabMappedFrom: BEACON_SUBTABS.WIN_PERCENTAGE,
      route: buildTabAndSubtabLink({ app, location, tab: BEACON_TABS.BUY_BOX, subtab: BEACON_SUBTABS.WIN_PERCENTAGE }),
      childSubtabs: [
        { label: 'Summary', value: BEACON_SUBTABS.WIN_PERCENTAGE, parentTab: BEACON_TABS.BUY_BOX },
        { label: 'Sellers', value: BEACON_SUBTABS.SELLERS, parentTab: BEACON_TABS.BUY_BOX }
      ]
    },
    ...(shouldShowRatingsAndReviewsV2()
      ? [
          {
            label: 'Reviews - V2',
            tabMappedFrom: BEACON_TABS.REVIEWS,
            subtabMappedFrom: BEACON_SUBTABS.REVIEW_TRENDS,
            route: buildTabAndSubtabLink({
              app,
              location,
              tab: BEACON_TABS.REVIEWS,
              subtab: BEACON_SUBTABS.REVIEW_TRENDS
            }),
            childSubtabs: [
              { label: 'Summary', value: BEACON_SUBTABS.REVIEW_TRENDS, parentTab: BEACON_TABS.REVIEWS },
              { label: 'Ratings', value: BEACON_SUBTABS.REVIEW_RATINGS, parentTab: BEACON_TABS.REVIEWS },
              { label: 'Reviews', value: BEACON_SUBTABS.REVIEW_REVIEW, parentTab: BEACON_TABS.REVIEWS },
              { label: 'Sentiment', value: BEACON_SUBTABS.REVIEW_SENTIMENT, parentTab: BEACON_TABS.REVIEWS }
            ]
          }
        ]
      : [
          {
            label: 'Reviews',
            tabMappedFrom: BEACON_TABS.REVIEWS,
            subtabMappedFrom: BEACON_SUBTABS.REVIEW_TRENDS,
            route: buildTabAndSubtabLink({
              app,
              location,
              tab: BEACON_TABS.REVIEWS,
              subtab: BEACON_SUBTABS.REVIEW_TRENDS
            }),
            childSubtabs: [{ label: 'Summary', value: BEACON_SUBTABS.REVIEW_TRENDS, parentTab: BEACON_TABS.REVIEWS }]
          }
        ]),
    {
      label: 'Inventory',
      tabMappedFrom: BEACON_TABS.SALES,
      subtabMappedFrom: BEACON_SUBTABS.INVENTORY,
      route: buildTabAndSubtabLink({ app, location, tab: BEACON_TABS.SALES, subtab: BEACON_SUBTABS.INVENTORY }),
      childSubtabs: [{ label: 'Summary', value: BEACON_SUBTABS.INVENTORY, parentTab: BEACON_TABS.SALES }]
    },
    ...(onChargebacksMode() || onShortagesMode()
      ? [
          {
            label: 'Operations',
            tabMappedFrom: BEACON_TABS.OPERATIONS,
            subtabMappedFrom: BEACON_SUBTABS.CHARGEBACKS,
            route: buildTabAndSubtabLink({
              app,
              location,
              tab: BEACON_TABS.OPERATIONS,
              subtab: onChargebacksMode() ? BEACON_SUBTABS.CHARGEBACKS : BEACON_SUBTABS.SHORTAGES
            }),
            childSubtabs: [
              ...(onChargebacksMode()
                ? [{ label: 'Chargebacks', value: BEACON_SUBTABS.CHARGEBACKS, parentTab: BEACON_TABS.OPERATIONS }]
                : []),
              ...(onShortagesMode()
                ? [{ label: 'Shortages', value: BEACON_SUBTABS.SHORTAGES, parentTab: BEACON_TABS.OPERATIONS }]
                : [])
            ]
          }
        ]
      : []),
    ...(isApple()
      ? [
          {
            label: 'Compliance',
            tabMappedFrom: BEACON_TABS.COMPLIANCE,
            subtabMappedFrom: BEACON_SUBTABS.KEY_METRICS,
            route: buildTabAndSubtabLink({
              app,
              location,
              tab: BEACON_TABS.COMPLIANCE,
              subtab: BEACON_SUBTABS.KEY_METRICS
            }),
            childSubtabs: [
              { label: 'Summary', value: BEACON_SUBTABS.KEY_METRICS, parentTab: BEACON_TABS.COMPLIANCE },
              { label: 'Advertising', value: BEACON_SUBTABS.KEYWORD_COMPLIANCE, parentTab: BEACON_TABS.COMPLIANCE },
              { label: 'Sellers', value: BEACON_SUBTABS.SELLER_COMPLIANCE, parentTab: BEACON_TABS.COMPLIANCE }
            ]
          }
        ]
      : [])
  ];

  const financeRoutes: TabRouteWithoutIndex[] = [
    {
      label: 'Disputes',
      tabMappedFrom: BEACON_TABS.OPERATIONS,
      subtabMappedFrom: BEACON_SUBTABS.DISPUTES,
      route: buildTabAndSubtabLink({ app, location, tab: BEACON_TABS.OPERATIONS, subtab: BEACON_SUBTABS.DISPUTES }),
      childSubtabs: []
    }
  ];

  const topicsRoutes: TabRouteWithoutIndex[] = [
    {
      label: 'Traffic',
      tabMappedFrom: BEACON_TABS.TRAFFIC,
      subtabMappedFrom: BEACON_SUBTABS.TOTAL_CLICKS,
      route: buildTabAndSubtabLink({ app, location, tab: BEACON_TABS.TRAFFIC, subtab: BEACON_SUBTABS.TOTAL_CLICKS }),
      childSubtabs: [
        { label: 'Total', value: BEACON_SUBTABS.TOTAL_CLICKS, parentTab: BEACON_TABS.TRAFFIC },
        { label: 'Organic', value: BEACON_SUBTABS.ORGANIC_CLICKS, parentTab: BEACON_TABS.TRAFFIC },
        { label: 'Paid Search', value: BEACON_SUBTABS.AD_CLICKS, parentTab: BEACON_TABS.TRAFFIC }
      ]
    }
  ];

  // Create a new array with dynamically assigned indices. This allows MUI to avoid gaps in indices in order to reconcile which tab is selected.
  const defaultChildRoutesWithIndexes: TabRoute[] = childRoutes.map((route, index) => ({
    ...route,
    index
  }));

  const financeRoutesWithIndexes: TabRoute[] = financeRoutes.map((route, index) => ({
    ...route,
    index
  }));

  const topicsRoutesWithIndexes: TabRoute[] = topicsRoutes.map((route, index) => ({ ...route, index }));

  // made it as a full length function, so we can add many conditions as we want later.
  const conditionallyMergedChildRoutes = (): TabRoute[] => {
    if (entityType === 'searchtermlist') {
      return [...topicsRoutesWithIndexes, ...financeRoutesWithIndexes];
    }
    return [...defaultChildRoutesWithIndexes, ...financeRoutesWithIndexes];
  };

  const { currentRoute, currentSubtab }: { currentRoute: TabRoute; currentSubtab: string } = findCurrentTabAndSubtab({
    location,
    childRoutes: conditionallyMergedChildRoutes()
  });

  const getCurrentRoutes = () => {
    const tab = getQueryParamValue('tab');
    const subtab = getQueryParamValue('subtab');

    if (tab === 'operations' && ['disputes'].includes(subtab)) {
      return financeRoutesWithIndexes;
    }
    if (entityType === 'searchtermlist') {
      return topicsRoutesWithIndexes;
    }
    return defaultChildRoutesWithIndexes;
  };

  return {
    routes: {
      defaultRoutes: defaultChildRoutesWithIndexes,
      financeRoutes: financeRoutesWithIndexes,
      topicsRoutes: topicsRoutesWithIndexes
    },
    currentRoute,
    getCurrentRoutes,
    currentSubtab
  };
};
