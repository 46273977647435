import React from 'react';
import { Text, useStacklineTheme } from '@stackline/ui';
import { NoDataIcon } from 'src/components/BeaconRedesignComponents/common/NoDataPlaceHolder/NoDataPlaceHolder';
import { Typography } from '@mui/material';
import { NoneFoundIcon } from 'src/components/SvgIcons';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

export interface SegmentNoDataPlaceholderProps {
  title: string;
  subtitle: string;
  maxWidth?: number;
}

/**
 * Placeholder for when there is no data for either segments or business units
 * on the segments page
 */
const SegmentNoDataPlaceholder = ({ title, subtitle, maxWidth = 244 }: SegmentNoDataPlaceholderProps) => {
  const theme = useStacklineTheme();

  return (
    <Flex gap="md" flexDirection="column" alignItems="start">
      <NoDataIcon
        icon={<NoneFoundIcon />}
        iconBackgroundStyle={{
          backgroundColor: theme.colors.secondaryGhost,
          opacity: 1
        }}
      />
      <Flex flexDirection="column" gap="xs" alignItems="start">
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#052849'
          }}
          variant="subtitle1"
        >
          {title}
        </Typography>
        <div style={{ maxWidth: `${maxWidth}px` }}>
          <Text variant="body2">{subtitle}</Text>
        </div>
      </Flex>
    </Flex>
  );
};

export default SegmentNoDataPlaceholder;
