import React from 'react';
import { Text } from '@stackline/ui';
import { formatTime } from 'src/utils/dateformatting';
import AppProfileIcon from 'src/components/BeaconRedesignComponents/Header/AppProfileIcon';
import _get from 'lodash/get';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

interface UserTimestampCellProps {
  date?: string;
  firstName: string;
  lastName: string;
  userId?: string;
  subtitle?: string | null;
}

/**
 * Displays a user icon and timestamp
 */
const UserTimestampCell = ({ date, firstName, lastName, userId, subtitle = null }: UserTimestampCellProps) => {
  const fullName = `${firstName} ${lastName}`;

  return (
    <Flex alignItems="center" gap={12}>
      <AppProfileIcon userId={userId} initials={`${_get(firstName, 0, '')}${_get(lastName, 0, '')}`} />
      <Flex flexDirection="column" gap="xxs">
        <div>
          <Text variant="body2">{fullName}</Text>
        </div>
        {subtitle && (
          <div>
            <Text color="secondary" variant="body3">
              {subtitle}
            </Text>
          </div>
        )}
        {date && (
          <div>
            <Text color="secondary" variant="body3">
              {formatTime(date, 'MMM D, YYYY')}
            </Text>
          </div>
        )}
      </Flex>
    </Flex>
  );
};

export default UserTimestampCell;
