import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { UpArrowIcon } from 'src/components/SvgIcons';

class CustomAgGridHeaderTemplate extends Component {
  static propTypes = {
    column: PropTypes.object.isRequired,
    displayName: PropTypes.node.isRequired,
    reactContainer: PropTypes.object.isRequired,
    sortArrowStyle: PropTypes.object
  };

  static defaultProps = {
    sortArrowStyle: undefined
  };

  constructor(props) {
    super(props);
    props.reactContainer.style.display = 'inline-block';
  }

  render() {
    // Pass `disableSort` into the column definition to disable sorting functionality for the column
    const { onSortFunction, sortDirection, sortByMetric, field, disableSort } = this.props.column.colDef;

    const renderName = () => {
      return <span>{this.props.displayName}</span>;
    };

    return (
      <div
        style={{ cursor: disableSort ? undefined : 'pointer', display: 'flex' }}
        onClick={disableSort ? undefined : onSortFunction}
        role="button"
        onKeyPress={disableSort ? undefined : onSortFunction}
      >
        <span>
          {sortByMetric === field && !disableSort ? (
            <UpArrowIcon
              className={`custom-sort-icon custom-sort-icon--${sortDirection}`}
              alt="sorting-arrow"
              style={this.props.sortArrowStyle}
            />
          ) : null}
        </span>
        <span style={{ fontSize: 14 }}>{renderName()}</span>
      </div>
    );
  }
}

export default CustomAgGridHeaderTemplate;
