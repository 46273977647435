import React, { useRef, useState } from 'react';
import StyledDialog from 'src/components/BeaconRedesignComponents/common/StyledDialog/StyledDialog';
import { Text, useStacklineTheme } from '@stackline/ui';
import TeamPopupCloseIcon from './TeamPopupCloseIcon';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BluePlus, DocumentIcon, CheckCircle, SmallCancelIcon } from 'src/components/SvgIcons';
import { parseUploadedItems } from 'src/components/Search/AdvancedSearch/BulkUpload/BulkUploadDialog';
import * as serverProxy from 'src/routes/UserAccount/UserTeamManagement/serverProxy';
import {
  bulkUserUploadFileParser,
  isValidBulkUserCsvFile
} from 'src/routes/UserAccount/UserTeamManagement/UserTeamManagementForm';
import { useAppSelector, useSnackbar } from 'src/utils/Hooks';
import { settingsErrorMessage } from '../utils';
import _get from 'lodash/get';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { PROCESSING_REQUEST_MESSAGE } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Team/constants';

type BulkUploadUserModalProps = Omit<React.ComponentProps<typeof StyledDialog>, 'width' | 'padding'> & {
  /**
   * Callback for when the user clicks the back button
   */
  onBack: () => void;
  refetchData: () => Promise<void>;
};

/**
 * A user that has been parsed from an uploaded
 * CSV file
 */
export interface ParsedUserDatum {
  firstName: string;
  lastName: string;
  email: string;
}

/**
 * Parses error from backend to get the total number of duplicate
 * users, if there are any
 */
function getTotalDuplicateUsers(errorResponse: any): number {
  const userCreationResponses: any[] = _get(errorResponse, 'response.data', []);
  const duplicateUserErrorResponses = userCreationResponses.filter((response: any) => {
    const { error = '' } = response;
    return error.includes('An account already exists');
  });

  return duplicateUserErrorResponses.length;
}

function getGenericErrorMessageWithCount(errorResponse: any): string {
  const userCreationResponses: any[] = _get(errorResponse, 'response.data', []);
  const userCreationErrorResponses = userCreationResponses.filter(
    (userCreationResponse) => !userCreationResponse.success
  );

  if (userCreationErrorResponses.length === 0) {
    return '';
  }
  return `Bulk upload has failed for ${userCreationErrorResponses.length} of the uploaded`;
}

/**
 * Modal for bulk uploading users to a team.
 */
export default function BulkUploadUserModal({ onBack, onClose, refetchData, open, ...rest }: BulkUploadUserModalProps) {
  const theme = useStacklineTheme();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [parsedUserData, setParsedUserData] = useState<ParsedUserDatum[]>([]);
  const currentUserEmail = useAppSelector((state) => state.user.config.profile.email);
  const { showSnackbar } = useSnackbar();
  const [fileError, setFileError] = useState('');

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clearFile = () => {
    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setUploadedFile(null);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const newItems: string[] = await parseUploadedItems('', file);

      if (!isValidBulkUserCsvFile(newItems)) {
        setFileError('The uploaded file contains an incorrect format. Please try again.');
        clearFile();
        return;
      } else {
        setFileError('');
      }

      setUploadedFile(file);
      setParsedUserData(bulkUserUploadFileParser(newItems));
    }
  };

  /**
   * Download the blank CSV template for bulk uploads
   */
  const handleDownloadTemplate = () => {
    const csvContent = 'FirstName,LastName,Email\n';
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'DefaultTemplate_BulkAddUser.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  const clearModal = () => {
    setUploadedFile(null);
    clearFile();
  };

  const handleGoBack = () => {
    clearModal();
    onBack();
  };

  const handleCloseModal = (event?: React.MouseEvent<unknown, MouseEvent>) => {
    clearModal();
    setFileError('');
    if (onClose) {
      onClose(event, 'backdropClick');
    }
  };

  const handleUploadUsers = async () => {
    try {
      handleCloseModal();
      showSnackbar({
        type: 'info',
        message: PROCESSING_REQUEST_MESSAGE
      });
      await serverProxy.CreateMultipleUsersInCurrentDivision(parsedUserData, currentUserEmail);
      await refetchData();
      showSnackbar({
        type: 'success',
        message: 'Invitations have been successfully sent.'
      });
    } catch (error) {
      handleCloseModal();

      const duplicateUsers = getTotalDuplicateUsers(error);
      if (duplicateUsers > 0) {
        clearFile();
        showSnackbar({
          message: `An account already exists for ${duplicateUsers} of the uploaded users.`,
          type: 'error'
        });
      } else {
        showSnackbar({
          type: 'error',
          message: getGenericErrorMessageWithCount(error) || settingsErrorMessage
        });
      }
    }
  };

  return (
    <>
      <StyledDialog width={450} height={532} padding="24px" onClose={handleCloseModal} open={open} {...rest}>
        <Flex flexDirection="column" justifyContent="space-between" flex={1}>
          <Flex gap="mdl" flexDirection="column">
            <Flex justifyContent="space-between">
              <Text variant="h4">Bulk Upload</Text>
              <TeamPopupCloseIcon onClick={handleCloseModal} />
            </Flex>
            <Text variant="body2">
              Please download the template below. All columns must be populated for each user you wish to add. One user
              per line with no additional formatting. Once you&apos;re done, upload the template with the same download
              name.
            </Text>
            <Flex gap="cardGrid" flexDirection="column">
              <Flex gap="md" flexDirection="column">
                <Text variant="subtitle1">Upload CSV file</Text>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  border={`1px solid ${theme.colors.primaryGray}`}
                  height="180px"
                  borderRadius={theme.spacing.sm}
                >
                  {uploadedFile ? (
                    <Flex alignItems="center" gap="sm">
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        width="24px"
                        height="24px"
                        borderRadius="50%"
                        sx={{ backgroundColor: theme.colors.primary }}
                      >
                        <DocumentIcon style={{ fill: '#ffffff', width: '18px', height: '18px' }} />
                      </Flex>
                      <Text variant="body2">{uploadedFile.name}</Text>
                    </Flex>
                  ) : (
                    <BluePlus
                      role="button"
                      style={{ width: theme.spacing.mdl, height: theme.spacing.mdl }}
                      onClick={() => handleButtonClick()}
                    />
                  )}
                </Flex>
              </Flex>
              {fileError ? (
                <Flex gap="sm" justifyContent="center" alignItems="center">
                  <SmallCancelIcon style={{ fill: theme.colors.error, width: '24px', height: '24px' }} />
                  <Text color="error" variant="subtitle3">
                    {fileError}
                  </Text>
                </Flex>
              ) : (
                uploadedFile && (
                  <Flex gap="sm" justifyContent="center" alignItems="center">
                    <CheckCircle style={{ fill: theme.colors.success, width: '24px', height: '24px' }} />
                    <Text color="success" variant="subtitle3">
                      Upload complete. Your file was successfully uploaded.
                    </Text>
                  </Flex>
                )
              )}
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <SlButton
              onClick={() => handleGoBack()}
              buttonSx={{
                paddingX: '20px'
              }}
            >
              Back
            </SlButton>
            <Flex gap="md">
              <SlButton
                onClick={() => handleDownloadTemplate()}
                buttonSx={{
                  paddingX: '20px'
                }}
              >
                Download template
              </SlButton>
              <SlButton variant="contained" disabled={!uploadedFile} onClick={() => handleUploadUsers()}>
                Save
              </SlButton>
            </Flex>
          </Flex>
        </Flex>
      </StyledDialog>
      <input type="file" accept=".csv" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} />
    </>
  );
}
