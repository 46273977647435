import { Box, Button, Menu, MenuItem, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AppAvatar, brandclub_colors, hexToRgba, useStacklineTheme } from '@stackline/ui';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import AppProfileIcon from 'src/components/BeaconRedesignComponents/Header/AppProfileIcon';
import SelectRetailerDropdown from 'src/components/BeaconRedesignComponents/Header/SelectRetailerDropdown';
import SearchInput from 'src/components/BeaconRedesignComponents/SearchInput/SearchInputBar';
import { ExportStatus } from 'src/components/BeaconRedesignComponents/common/AppExportModal/exportDataUtils';
import { useBeaconHomeLink } from 'src/components/BeaconRedesignComponents/hooks/useBeaconHomeLink';
import { BEACON_PRO_CENTER_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import DisputeStatusUpdater from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeStatusUpdater';
import { UpdatedBeaconLogo } from 'src/components/SvgIcons/SvgIcons';
import { useExportContext } from 'src/providers/ExportContextProvider';
import queryClient from 'src/queryClient';
import * as appOperations from 'src/store/modules/app/operations';
import { authOperations } from 'src/store/modules/auth';
import { onShortagesMode } from 'src/store/modules/user/selectors';
import { useAppSelector, useLocation, useSnackbar } from 'src/utils/Hooks';
import useNotificationsBadge from 'src/utils/Hooks/useNotificationsBadge';
import { churnZeroLogout } from 'src/utils/churnZero';
import { trackLogOut } from 'src/utils/mixpanel';

const useStyles = makeStyles({
  header: {
    height: 60,
    backgroundColor: brandclub_colors.blueWhite,
    position: 'initial',
    top: 0,
    zIndex: 1000
  },
  centerDiv: {
    margin: '0  auto',
    width: BEACON_PRO_CENTER_WIDTH,
    boxSizing: 'border-box',
    height: 60,
    display: 'grid',
    gridTemplateColumns: `1fr 330px 1fr`,
    alignItems: 'center'
  },
  productLogo: {
    height: 30,
    width: 30,
    marginLeft: '-1px',
    '& :hover': {
      cursor: 'pointer'
    }
  },
  userOptionsDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '4px'
  },
  companyName: {
    fontSize: '1.4rem',
    fontWeight: 500,
    lineHeight: 1.43,
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  settingsIconButton: {
    height: 24,
    width: 24,
    padding: 0,
    margin: '0 26px 0 20px',
    borderRight: '1ps solid black'
  },
  accountIconButton: {
    height: 30,
    width: 30,
    padding: 0
  },
  accountIcon: {
    height: 36,
    width: 36
  },
  // drawer
  drawerButtonContainer: {
    textAlign: 'right'
  },
  drawerButtonRoot: {
    paddingRight: 0
  },
  menuIcon: {
    transform: 'scale(1.4, 1.7)'
  },
  menuCloseIcon: {
    transform: 'scale(1.5)'
  },
  drawerRoot: {
    fontFamily: 'Roboto'
  },
  drawerPaperRoot: {
    top: 60,
    left: 0,
    padding: 20
  },
  drawerCompanyName: {
    color: brandclub_colors.darkBlue,
    fontSize: '1.8rem',
    fontWeight: 'bold',
    lineHeight: 1.43,
    marginTop: 10,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  listRoot: {
    color: brandclub_colors.darkBlue
  },
  listItemRoot: {
    padding: '20px 0',
    '& a': {
      color: brandclub_colors.darkBlue,
      textDecoration: 'none'
    }
  }
});

export const StyledMenu = styled(Menu)({
  '& div.MuiPaper-root': {
    borderRadius: 8,
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.2)',
    '& ul.MuiList-root': {
      padding: 0
    }
  },
  // product details - product status
  '&.entity-status li:hover': {
    backgroundColor: brandclub_colors.lightGreenLogo3
  }
});

export const StyledMenuItem = styled(MenuItem)({
  fontFamily: 'Roboto',
  color: brandclub_colors.darkBlue,
  fontSize: '1.2rem',
  fontWeight: 500,
  textAlign: 'left',
  paddingLeft: 15,
  paddingRight: 15,
  '&:not(:last-child)': {
    borderBottom: `1px solid ${brandclub_colors.grayLight}`
  },
  '&:hover': {
    backgroundColor: brandclub_colors.blueWhite,
    transition: 'background-color 0.25s ease'
  }
});

export const StyledButton = styled(Button)({
  textTransform: 'none',
  color: brandclub_colors.darkBlue,
  '&:hover': {
    backgroundColor: 'transparent'
  },
  'span.MuiButton-endIcon': {
    marginLeft: 0,
    padding: 8
  }
});

const SuccessSnackbarMessage = () => {
  const { queryParams } = useAppSelector((state) => state.app);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text variant="subtitle2">
        Your download is complete. View all downloads{' '}
        <Link to={`/account/downloads${queryParams.searchParams}`} style={{ textDecoration: 'underline' }}>
          here
        </Link>
        .
      </Text>
    </div>
  );
};

// -------------------------------------------------------------

export const SettingIcon = () => {
  return <div style={{ margin: '0 26px 0 20px' }}>settings</div>;
};

interface ButtonOption {
  label: string | ReactNode;
  id: string;
  onClick: () => void;
}

export const UserIcon = ({ history }: { history: any }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const containerRef = useRef(null);
  const { searchParams } = useAppSelector((state) => state.app.queryParams);
  const dispatch = useDispatch();
  const theme = useStacklineTheme();

  const { email, isAdmin } = useAppSelector((state) => state.user.session);
  const { statusChangedItems } = useExportContext();
  const { shouldShowNotificationBadge, setShowNotificationBadge, setRemoveNotificationBadge } = useNotificationsBadge();
  const { pathname } = useLocation();

  const hoverBgColor = hexToRgba(theme.colors.secondaryPorcelain, 1);
  const selectedBackground = hexToRgba(theme.colors.primaryLight, 0.3);

  const role = isAdmin ? 'Admin' : 'User';

  const handleOpen = () => {
    setAnchorEl(containerRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChange = (selection) => {
    selection.onClick();
  };
  // handles export notification status
  useEffect(() => {
    if (statusChangedItems.length > 0) {
      statusChangedItems.forEach((changedItem) => {
        if (changedItem.currentStatus === ExportStatus.SUCCESS) {
          setShowNotificationBadge();
        }
      });
    }
  }, [setShowNotificationBadge, statusChangedItems]);

  // handles if user navigates to downloads tab, remove all the notifications
  useEffect(() => {
    if (pathname.includes('/account/downloads')) {
      setRemoveNotificationBadge();
    }
  }, [pathname, setRemoveNotificationBadge]);

  const buttonOptions: ButtonOption[] = [
    {
      label: (
        <div>
          Settings
          {shouldShowNotificationBadge && (
            <span
              style={{
                width: '8px',
                height: '8px',
                backgroundColor: theme.colors.primary,
                display: 'inline-block',
                marginLeft: '8px',
                borderRadius: '50%'
              }}
            ></span>
          )}
        </div>
      ),
      id: 'settings',
      onClick: () => {
        handleClose();
        if (!window.location.pathname.includes('account')) {
          history.push(`/account/profile${searchParams}`);
        }
      }
    },
    {
      label: 'Sign out',
      id: 'signOut',
      onClick: async () => {
        churnZeroLogout();
        dispatch(authOperations.logOut())
          .then(() => {
            trackLogOut();
            dispatch(appOperations.fetchConfig());
            history.push('/sign_in');
          })
          .then(() => {
            queryClient.clear();
            dispatch(appOperations.clearReduxState());
          })
          .catch((err: Error) => {
            throw err;
          });
      }
    }
  ];

  return (
    <>
      <div style={{ height: 30, width: 30, cursor: 'pointer' }} role="button" onClick={handleOpen} ref={containerRef}>
        <AppProfileIcon shouldShowNotificationBadge={shouldShowNotificationBadge} size={30} />
      </div>
      <Menu
        autoFocus={false}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        open={!!anchorEl}
        onClose={handleClose}
        sx={{
          marginTop: '8px',
          boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.15)',
          '& ul': {
            paddingY: 0
          },
          '.MuiPaper-root': {
            minWidth: '209px', // instead of width to allow for long emails
            overflowY: 'clip',
            padding: 0
          }
        }}
      >
        <MenuItem
          disabled
          sx={{
            paddingY: '14px',
            paddingX: '12px',
            borderBottom: `1px solid ${theme.colors.primaryGray}`,
            width: '100%',
            '.MuiMenuItem-root': {
              paddingLeft: '11px'
            },
            '&.Mui-disabled': {
              opacity: 1
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px'
            }}
          >
            <Text variant="subtitle2" fontWeight="bold">
              {email}
            </Text>
            <Text opacity={50} variant="body3">
              {role}
            </Text>
          </Box>
        </MenuItem>
        {buttonOptions.map((option, index) => {
          const onSettingsPage = window.location.pathname === `/account/profile` && option.id === 'settings';

          return (
            <MenuItem
              key={option.id}
              sx={{
                backgroundColor: onSettingsPage ? selectedBackground : undefined,
                paddingY: '14px',
                paddingX: '12px',
                '&:hover': {
                  backgroundColor: onSettingsPage ? selectedBackground : hoverBgColor
                },
                borderBottom: index !== buttonOptions.length - 1 ? `1px solid ${theme.colors.primaryGray}` : undefined,
                width: '100%',
                '.MuiMenuItem-root': {
                  paddingLeft: '11px'
                }
              }}
              onClick={() => {
                onChange(option);
                handleClose();
              }}
            >
              <Text variant="subtitle2" fontWeight={500}>
                {option.label}
              </Text>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

interface HeaderProps {
  location: any;
  history: any;
}

const Header: FC = ({ location, history }: HeaderProps) => {
  const classes = useStyles();
  const beaconHomeLink = useBeaconHomeLink();
  const { showSnackbar } = useSnackbar();
  const { statusChangedItems } = useExportContext();
  const { pathname } = useLocation();
  const app = useAppSelector((state) => state.app);

  useEffect(() => {
    if (statusChangedItems.length > 0) {
      if (!pathname.includes("'/account/downloads'")) {
        statusChangedItems.forEach((changedItem) => {
          if (changedItem.currentStatus === ExportStatus.FAILED) {
            showSnackbar({
              message: 'Export failed. We have encountered an issue processing your request. Please try again.',
              type: 'error'
            });
          }
          if (changedItem.currentStatus === ExportStatus.SUCCESS) {
            showSnackbar({ message: <SuccessSnackbarMessage />, type: 'success' });
          }
        });
      }
    }
  }, [statusChangedItems]);

  return (
    <div className={classes.header}>
      <div className={classes.centerDiv}>
        <AppAvatar
          isRawSrc
          variant="square"
          sx={{ backgroundColor: 'transparent' }}
          classes={{
            root: classes.productLogo
          }}
          imgProps={{
            style: {
              objectFit: 'contain'
            }
          }}
          onClick={() => {
            if (`${location.pathname + location.search}` !== beaconHomeLink) {
              history.push(beaconHomeLink);
            }
          }}
        >
          <UpdatedBeaconLogo />
        </AppAvatar>
        <SearchInput />
        <div>
          <div className={classes.userOptionsDiv}>
            <SelectRetailerDropdown />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <UserIcon history={history} />
                  {onShortagesMode() && app.name === 'beacon' ? <DisputeStatusUpdater /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Header);
