import { ServerProxy } from '../serverProxy/types';
import createMockBulkAdjustmentServerProxy from '../serverProxy/createMockBulkAdjustmentServerProxy';
import createBulkAdjustmentServerProxy from '../serverProxy/createBulkAdjustmentServerProxy';
import { useBeaconClientId, useRetailerId, useUserId } from 'src/utils/Hooks/reduxSelectors';

/**
 * Returns a server proxy for bulk adjustments. Any implementation
 * of ServerProxy can be returned.
 *
 * TODO - replace this with a real server proxy
 */
export default function useServerProxy(): ServerProxy {
  const userId = useUserId();
  const retailerId = useRetailerId();
  const beaconClientId = useBeaconClientId();

  const mockProxy = createMockBulkAdjustmentServerProxy();
  const realProxy = createBulkAdjustmentServerProxy({
    userId,
    retailerId: +retailerId,
    beaconClientId
  });

  return {
    ...mockProxy,
    uploadBulkAdjustment: realProxy.uploadBulkAdjustment,
    updateUserUploadBulkAdjustmentStatus: realProxy.updateUserUploadBulkAdjustmentStatus,
    validateBulkAdjustment: realProxy.validateBulkAdjustment,
    pollBulkAdjustments: realProxy.pollBulkAdjustments,
    publishBulkAdjustment: realProxy.publishBulkAdjustment,
    revertBulkAdjustment: realProxy.revertBulkAdjustment,
    computeNetImpactForBulkAdjustment: realProxy.computeNetImpactForBulkAdjustment
  };
}
