import { Box } from '@mui/system';
import React from 'react';
import { SlColumn } from 'src/components/BeaconRedesignComponents/Generic/SlColumn';
import KeyMetricCards, {
  KeyMetricCardDefinition
} from 'src/components/BeaconRedesignComponents/KeyMetricCards/KeyMetricCards';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { KEY_METRICS_CONTAINER_ADJUSTED_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

/**
 * Card title overrides
 */
const titleOverrides = {
  authorizedKeywordAdPercent: 'Ad Compliance',
  authorizedSellerPercent: 'Seller Compliance',
  timestamp: 'Violations'
};

const aggBuilder = (builder: AggregationBuilder): AggregationBuilder => {
  return builder.addConditionRangeFilter('contentScore', 0, 0.99);
};

// Index names for each column. The 3rd column uses 2 different indices.
const indexNameCollection = ['compliance-keywordaditem', 'compliance-selleritem', ['contentChanged', 'content']];
const cardDefinitionsByColumn: KeyMetricCardDefinition[][] = [
  ['authorizedKeywordAdPercent', 'authorized', 'unauthorized'],
  ['authorizedSellerPercent', 'authorized', 'unauthorized'],
  ['contentAccuracy', 'contentScore', 'timestamp']
].map((fieldNames, index) =>
  fieldNames.map((fieldName, fieldIndex) => {
    const row: KeyMetricCardDefinition = {
      indexName:
        index !== 2 // For the 3rd collection of field names, we use 2 different index names instead of 1 i.e (contentChanged and content)
          ? indexNameCollection[index]
          : fieldIndex === 0 // Within the 3rd collection, for the first field, we can use the first index name in the collection
          ? indexNameCollection[index][0]
          : indexNameCollection[index][1], // Within the 3rd collection, for the second and third fields, we use the 2nd index name in the collection
      fieldName,
      title: titleOverrides[fieldName],
      primaryAggregationBuilder: fieldName === 'timestamp' ? aggBuilder : undefined,
      secondaryAggregationBuilder: fieldName === 'timestamp' ? aggBuilder : undefined
    };
    return row;
  })
);

/**
 * Shows the key metric cards on the traffic display page
 */
const ComplianceSummaryKeyMetricCards = () => {
  return (
    <SlColumn widths="full">
      <SlColumn spacing="lg" widths="full">
        <CommonSummaryInfoSubtitle title="Key Metrics" includeLegend />
        <Box
          sx={{
            width: KEY_METRICS_CONTAINER_ADJUSTED_WIDTH,
            transform: 'translate(-16px)'
          }}
        >
          <KeyMetricCards cardDefinitionsByColumn={cardDefinitionsByColumn} />
        </Box>
      </SlColumn>
    </SlColumn>
  );
};

export default ComplianceSummaryKeyMetricCards;
