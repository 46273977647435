/* eslint-disable react/display-name */
import React, { useState, ForwardedRef, useEffect } from 'react';
import StyledInput, { StyledInputProps } from './StyledInput';

export interface Masker {
  applyMask(value: string): string;
  extractRawValue(maskedValue: string): string;
}

export interface MaskedInputProps extends Omit<StyledInputProps, 'value'>, Pick<Required<StyledInputProps>, 'value'> {
  masker: Masker;
}

/**
 * Input component that applies a mask to the value.
 *
 * `value` is required in order for the mask to properly update.
 */
const MaskedInput = React.forwardRef(
  ({ masker, value, onChange, ...rest }: MaskedInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [maskedValue, setMaskedValue] = useState(
      masker.applyMask((value as string) || (rest.defaultValue as string) || '')
    );

    useEffect(() => {
      const newMaskedValue = masker.applyMask((value as string) || '');
      setMaskedValue(newMaskedValue);
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newMaskedValue = masker.applyMask(event.target.value);

      if (onChange) {
        onChange({
          ...event,
          target: {
            ...event.target,
            value: masker.extractRawValue(newMaskedValue)
          }
        });
      }
    };

    return <StyledInput ref={ref} value={maskedValue} onChange={handleChange} {...rest} />;
  }
);

export default MaskedInput;
