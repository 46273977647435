import types from './types';
import actions from './actions';
import { ValueOf } from 'sl-api-connector/types';

export interface OmniRetailersState {
  isFetching: boolean;
  data: {
    retailerId: number;
  }[];
}

export interface OmniInstacartRetailersState {
  isFetching: boolean;
  data: {
    instacartRetailerId: string;
  }[];
}

const initialState: OmniRetailersState | OmniInstacartRetailersState = {
  isFetching: true,
  data: []
};

export const omniRetailers = (state = initialState, action: ReturnType<ValueOf<typeof actions>>) => {
  switch (action.type) {
    case types.START_FETCH:
      return { isFetching: true, data: [] };
    case types.RECEIVE_ALL_OMNI_RETAILERS:
      return { isFetching: false, data: Object.assign([], action.retailers) };
    default:
      return state;
  }
};

export const omniInstacartRetailers = (state = initialState, action: ReturnType<ValueOf<typeof actions>>) => {
  switch (action.type) {
    case types.START_FETCH:
      return { isFetching: true, data: [] };
    case types.RECEIVE_ALL_OMNI_INSTACART_RETAILERS:
      return { isFetching: false, data: Object.assign([], action.instacartRetailers) };
    default:
      return state;
  }
};
