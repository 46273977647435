import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { FORECAST_SUMMARY_KEYMETRIC_QUERY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastQueryKeys';
import { PlanTypeOption } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';
import { useApplyEntityFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { useAppSelector } from 'src/utils/Hooks';
import useGenericAdvancedSearch, { UseGenericAdvancedSearchParameters } from 'src/utils/Hooks/useGenericAdvancedSearch';

interface AdjustmentCountResponse {
  data: { aggregations: { by_planType: { count: number; fieldId: string }[] } }[];
}

/**
 * We can use this map to match plan type to the field name used to populate the key metric cards
 */
const planTypeToFieldNames = {
  [PlanTypeOption.OrganicTraffic]: 'organicClicks',
  [PlanTypeOption.OtherTraffic]: 'otherClicks',
  [PlanTypeOption.ContentScore]: 'contentScore',
  [PlanTypeOption.RatingsReviews]: 'weightedRating',
  [PlanTypeOption.PaidTraffic]: 'clicks',
  [PlanTypeOption.RetailPrice]: 'retailPrice',
  [PlanTypeOption.InStockRate]: 'instockRate',
  [PlanTypeOption.BuyBox]: 'winPercentage'
};

/**
 * Fetches and parses the adjustment counts for each key metric card/plan type
 * @returns an object containing the counts of each adjustment type under their associated key metric field name.
 * @returns a loading state for pending data
 */
export const useForecastAdjustmentCount = ({
  buildRequest,
  parseResponse,
  useGenericAdvancedSearchArgs
}: {
  buildRequest?: (builder: AdvancedSearchRequestBuilder) => AdvancedSearchRequestBuilder;
  parseResponse?: (response: AxiosResponse<unknown>) => Record<string, number>;
  useGenericAdvancedSearchArgs?: Partial<UseGenericAdvancedSearchParameters>;
} = {}) => {
  const retailer = useAppSelector((state) => state.retailer);

  const applyEntityFilters = useApplyEntityFilters();

  const aggregationBuilder = new AggregationBuilder('planType')
    .addAggregationField('Plan Type', 'planType', 'cardinality', true)
    .addConditionTermFilter('retailerId', [retailer.id]);

  const adjustmentsByPlanTypeRequest = new AdvancedSearchRequestBuilder('adjustmentsByPlanType', 'beacon-adjustments')
    .setRetailerId(retailer.id)
    .addConditionTermFilter('retailerId', 'should', [retailer.id])
    .addConditionTermFilter('status', 'must', ['Published'])
    .setDoAggregation(true)
    .setReturnDocuments(false)
    .setPageSize(1200)
    .setSearchBy('parent')
    .addAggregation(aggregationBuilder.build())
    .apply(applyEntityFilters)
    .apply((builder) => (buildRequest ? buildRequest(builder) : builder))
    .build();

  const { data, isLoading, isError } = useGenericAdvancedSearch({
    queryKeys: [FORECAST_SUMMARY_KEYMETRIC_QUERY, adjustmentsByPlanTypeRequest],
    requestBody: [adjustmentsByPlanTypeRequest],
    requestId: 'adjustmentCounts',
    ...useGenericAdvancedSearchArgs
  });

  const countsByFieldName = useMemo(() => {
    if (data) {
      if (parseResponse) {
        return parseResponse(data);
      }
      const response = data as AdjustmentCountResponse;
      return response.data[0].aggregations.by_planType.reduce((acc, adjustmentByPlanType) => {
        acc[planTypeToFieldNames[adjustmentByPlanType.fieldId]] = adjustmentByPlanType.count;
        return acc;
      }, {});
    } else {
      return null;
    }
  }, [data, parseResponse]);

  return { data: countsByFieldName, isLoading, isError };
};
