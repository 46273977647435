export const UNCLASSIFIED_SUBCATEGORY_ID = 80001 as const;

export const NULL_UUID = '00000000-0000-0000-0000-000000000000' as const;

export const hashIdOfZero = 'WjnegYbwZ1' as const;

export const UTC_TIMEZONE = 'Africa/Abidjan';

export const STACKLINE_UNASSIGNED = {
  portfolioId: 'stackline_unassigned',
  portfolioDisplayName: 'Orphan Campaigns'
};

export const USER_ROLES = {
  support: 'SUPPORT',
  owner: 'OWNER',
  admin: 'ADMIN',
  piwheel: 'PIWHEEL',
  stackline: 'STACKLINE',
  user: 'USER'
};

export const USER_SCOPES = {
  canViewStacklineUser: 'can-view-stackline-user',
  canAddUser: 'can-invite-user',
  canAssignAdmins: 'can-assign-admin',
  canDeleteAdmin: 'can-delete-admin',
  canDeleteUsers: 'can-delete-users',
  canTransferOwnership: 'can-transfer-ownership',
  canViewApprovedDomainTab: 'can-view-approved-domain',
  canEditApprovedDomain: 'can-edit-approved-domain'
};

export const ADVANCED_SEARCH_KEYWORD_PARAMS = ['cpnf', 'pfnf', 'etnf', 'ttf', 'k'] as const;
