import { Dialog, DialogProps, Divider, styled } from '@mui/material';
import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { CloseIcon } from 'src/components/SvgIcons';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import ScrollableContainer from 'src/components/BeaconRedesignComponents/ScrollableContainer';

export const GenericStyledDialogue = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '757px',
    height: '641px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px'
  },
  textarea: {
    color: '#052849 !important'
  },
  overflowX: 'hidden',
  '&::-webkit-scrollbar:': {
    width: '5px'
  },

  '&:hover': {
    '&::-webkit-scrollbar-thumb': {
      'background-color': '#7e8fa8',
      'border-radius': '15px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff'
    }
  }
});
const DefaultHeader = ({ headerTitle, onClose }: { headerTitle: string; onClose: () => void }) => {
  return (
    <>
      <Flex height="72px" justifyContent="center" alignItems="center" paddingX="48px" paddingY="18px">
        <Text variant="h4">{headerTitle}</Text>
        <CloseIcon
          onClick={onClose}
          style={{ position: 'absolute', left: '700px', cursor: 'pointer', width: '24px', height: '24px' }}
        ></CloseIcon>
      </Flex>
      <Divider />
    </>
  );
};

/**
 * Props for the GenericBulkAdjustmentModal component.
 */
export interface GenericBulkAdjustmentModalProps extends DialogProps {
  /**
   * Custom header node or header string.
   * Passing a string will render a default header with the string as the title.
   * Passing a node will render the node as the header.
   */
  header: React.ReactNode | string;

  /**
   * If true, wraps children in a default container with correct scrollbars when content exceeds modal height
   */
  defaultContentContainer?: boolean;

  /**
   * Optional function to call when the modal is requested to be closed via the close button
   */
  onClose?: () => void;

  /**
   * Optional function to call when the default cancel button is clicked
   */
  onCancel?: () => void;

  /**
   * Optional function to call when the default next button is clicked
   */
  onNext?: () => void;

  /**
   * Optional custom footer node
   */
  footer?: React.ReactNode;

  /**
   * The content of the modal
   */
  children: React.ReactNode;
}
// eslint-disable-next-line react/display-name
const GenericBulkAdjustmentModal = React.forwardRef(
  (
    {
      header,
      defaultContentContainer = true,
      onClose = () => {},
      onCancel = () => {},
      onNext = () => {},
      children,
      footer = null,
      ...props
    }: GenericBulkAdjustmentModalProps,
    ref: React.Ref<HTMLDivElement>
  ): JSX.Element => {
    return (
      <GenericStyledDialogue ref={ref} onClose={onClose} {...props}>
        {/* Header */}
        {typeof header === 'string' ? <DefaultHeader headerTitle={header} onClose={onClose} /> : header || null}

        {/* Content */}
        {defaultContentContainer ? (
          <ScrollableContainer scrollbarWidth="5px" flexDirection="column" paddingX="48px" height="100%">
            {children}
          </ScrollableContainer>
        ) : (
          children
        )}

        {/* Footer */}
        {footer || (
          <>
            <Divider />
            <Flex justifyContent="space-between" alignItems="center" paddingX="48px" paddingY="20px">
              <SlButton onClick={onCancel}>Cancel</SlButton>
              <SlButton onClick={onNext} variant="contained">
                Next
              </SlButton>
            </Flex>
          </>
        )}
      </GenericStyledDialogue>
    );
  }
);

export default GenericBulkAdjustmentModal;
