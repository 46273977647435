import { METRICTYPE } from 'src/utils/entityDefinitions';

export interface OmniGridField {
  currencySymbol: string;
  name: string;
  displayName: string;
  metricType: string;
  aggregationFunction: string;
  metricName: string;
  fulfillmentType?: string;
  shareOfShelfType?: string;
}

export interface OmniTableField {
  currencySymbol: string;
  name: string;
  displayName: string;
  metricType: string;
  aggregationFunction: string;
  metricName: string;
  fulfillmentType?: string;
  widthForColumn?: number;
  shareOfShelfType?: string;
  dataKey?: string;
}

export const omniGridField = {
  retailPrice: {
    currencySymbol: '$',
    name: 'retailPrice',
    displayName: 'Retail Price',
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'avg',
    metricName: 'RetailPrice'
  },
  promotion: {
    currencySymbol: '',
    name: 'promotion',
    displayName: 'Retail Promotion',
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'value_count',
    metricName: 'Promotion'
  },
  promotionPrice: {
    currencySymbol: '$',
    name: 'promotionPrice',
    displayName: 'Retail Price',
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'avg',
    metricName: 'RetailPrice'
  },
  priceViolation: {
    currencySymbol: '',
    name: 'priceViolation',
    displayName: 'Price Violation',
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'value_count',
    metricName: 'PriceViolation'
  },
  inStockRate: {
    currencySymbol: '',
    name: 'inStockRate',
    displayName: 'In-Stock Rate',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'InStockRate'
  },
  outOfStockRate: {
    currencySymbol: '',
    name: 'outOfStockRate',
    displayName: 'Out-of-Stock Rate',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'OutOfStockRate'
  },
  placementRate: {
    currencySymbol: '',
    name: 'placementRate',
    displayName: 'Placement Rate',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'InStockRate',
    fulfillmentType: 'delivery'
  },
  availabilityRate: {
    currencySymbol: '',
    name: 'availabilityRate',
    displayName: 'Availability Rate',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'AvailabilityRate'
  },

  fulfillmentTime: {
    currencySymbol: '',
    name: 'fulfillmentTime',
    displayName: 'Fulfillment Time',
    metricType: METRICTYPE.DECIMAL,
    aggregationFunction: 'avg',
    metricName: 'Time'
  },

  deliveryTime: {
    currencySymbol: '',
    name: 'fulfillmentTime',
    displayName: 'Delivery Time',
    metricType: METRICTYPE.DECIMAL,
    aggregationFunction: 'avg',
    metricName: 'Time',
    fulfillmentType: 'delivery'
  },
  shippingTime: {
    currencySymbol: '',
    name: 'fulfillmentTime',
    displayName: 'Shipping Time',
    metricType: METRICTYPE.DECIMAL,
    aggregationFunction: 'avg',
    metricName: 'Time',
    fulfillmentType: 'shipping'
  },
  pickupTime: {
    currencySymbol: '',
    name: 'fulfillmentTime',
    displayName: 'Pickup Time',
    metricType: METRICTYPE.DECIMAL,
    aggregationFunction: 'avg',
    metricName: 'Time',
    fulfillmentType: 'pickUp'
  },
  totalShareOfShelf: {
    currencySymbol: '',
    name: 'shareOfShelfRate',
    displayName: 'shareOfShelfRate',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'shareOfShelfRate',
    shareOfShelfType: 'total'
  },
  paidShareOfShelf: {
    currencySymbol: '',
    name: 'shareOfShelfRate',
    displayName: 'shareOfShelfRate',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'shareOfShelfRate',
    shareOfShelfType: 'paid'
  },
  organicShareOfShelf: {
    currencySymbol: '',
    name: 'shareOfShelfRate',
    displayName: 'shareOfShelfRate',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'shareOfShelfRate',
    shareOfShelfType: 'organic'
  },
  contentScore: {
    currencySymbol: '',
    name: 'contentScore',
    displayName: 'contentScore',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'contentScore'
  },
  contentAccuracy: {
    currencySymbol: '',
    name: 'contentAccuracy',
    displayName: 'contentAccuracy',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'contentAccuracy'
  }
};

export const fieldForTable = {
  retailPrice: {
    currencySymbol: '$',
    metricType: METRICTYPE.MONEY,
    name: 'retailPrice',
    headerName: 'Retail Price',
    aggregationFunction: 'avg',
    metricName: 'retailPrice',
    dataKey: 'avgRetailPrice'
  },
  inStockRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'inStockRate',
    headerName: 'In-Stock Rate',
    aggregationFunction: 'avg',
    metricName: 'inStockRate',
    dataKey: 'avgInStockRate'
  },
  promotion: {
    currencySymbol: '',
    metricType: METRICTYPE.VOLUME,
    name: 'promotion',
    headerName: 'Promotion Count',
    aggregationFunction: 'cardinality',
    metricName: 'promotion',
    dataKey: 'cardinalityPromotion'
  },
  promotionPrice: {
    currencySymbol: '$',
    metricType: METRICTYPE.MONEY,
    name: 'promotionPrice',
    headerName: 'Average Promotion Price',
    aggregationFunction: 'avg',
    metricName: 'retailPrice',
    dataKey: 'avgPromotionPrice'
  },
  priceViolation: {
    currencySymbol: '',
    name: 'priceViolation',
    metricType: METRICTYPE.VOLUME,
    headerName: 'Price Violation',
    aggregationFunction: 'sum',
    metricName: 'priceViolation',
    dataKey: 'sumPriceViolation'
  },
  retailers: {
    currencySymbol: '',
    metricType: METRICTYPE.VOLUME,
    name: 'retailers',
    headerName: 'Retailer Count',
    aggregationFunction: 'cardinality',
    metricName: 'retailers',
    dataKey: 'cardinalityRetailers'
  },
  stores: {
    currencySymbol: '',
    metricType: METRICTYPE.VOLUME,
    name: 'stores',
    headerName: 'Store Count',
    aggregationFunction: 'cardinality',
    metricName: 'stores',
    dataKey: 'cardinalityStores'
  },
  pickUpRetailPrice: {
    currencySymbol: '$',
    metricType: METRICTYPE.MONEY,
    name: 'retailPrice',
    headerName: 'Pickup Retail Price',
    aggregationFunction: 'avg',
    metricName: 'retailPrice',
    fulfillmentType: 'pickUp',
    dataKey: 'avgPickUpRetailPrice'
  },
  deliveryRetailPrice: {
    currencySymbol: '$',
    metricType: METRICTYPE.MONEY,
    name: 'retailPrice',
    headerName: 'Delivery Retail Price',
    aggregationFunction: 'avg',
    metricName: 'retailPrice',
    fulfillmentType: 'delivery',
    dataKey: 'avgDeliveryRetailPrice'
  },
  shippingRetailPrice: {
    currencySymbol: '$',
    metricType: METRICTYPE.MONEY,
    name: 'retailPrice',
    headerName: 'Shipping Retail Price',
    aggregationFunction: 'avg',
    metricName: 'retailPrice',
    fulfillmentType: 'shipping',
    dataKey: 'avgShippingRetailPrice'
  },
  pickUpPromotion: {
    currencySymbol: '',
    metricType: METRICTYPE.VOLUME,
    name: 'promotion',
    headerName: 'Pickup Promotions',
    aggregationFunction: 'sum',
    metricName: 'promotion',
    fulfillmentType: 'pickUp',
    dataKey: 'sumPickUpPromotion'
  },
  pickUpPromotionPrice: {
    currencySymbol: '$',
    metricType: METRICTYPE.MONEY,
    name: 'promotionPrice',
    displayName: 'Pickup Promotion Price',
    headerName: 'Pickup Promotion Price',
    aggregationFunction: 'avg',
    metricName: 'retailPrice',
    dataKey: 'avgPickUpPromotionPrice',
    fulfillmentType: 'pickUp'
  },
  deliveryPromotion: {
    currencySymbol: '',
    metricType: METRICTYPE.VOLUME,
    name: 'promotion',
    headerName: 'Delivery Promotions',
    aggregationFunction: 'sum',
    metricName: 'promotion',
    fulfillmentType: 'delivery',
    dataKey: 'sumDeliveryPromotion'
  },
  deliveryPromotionPrice: {
    currencySymbol: '$',
    metricType: METRICTYPE.MONEY,
    name: 'promotionPrice',
    displayName: 'Delivery Promotion Price',
    headerName: 'Delivery Promotion Price',
    aggregationFunction: 'avg',
    metricName: 'retailPrice',
    dataKey: 'avgDeliveryPromotionPrice',
    fulfillmentType: 'delivery'
  },

  shippingPromotion: {
    currencySymbol: '',
    metricType: METRICTYPE.VOLUME,
    name: 'promotion',
    headerName: 'Shipping Promotions',
    aggregationFunction: 'sum',
    metricName: 'promotion',
    fulfillmentType: 'shipping',
    dataKey: 'sumShippingPromotion'
  },

  shippingPromotionPrice: {
    currencySymbol: '$',
    metricType: METRICTYPE.MONEY,
    name: 'promotionPrice',
    displayName: 'Shipping Promotion Price',
    headerName: 'Shipping Promotion Price',
    aggregationFunction: 'avg',
    metricName: 'retailPrice',
    dataKey: 'avgShippingPromotionPrice',
    fulfillmentType: 'shipping'
  },
  pickUpPriceViolation: {
    currencySymbol: '',
    metricType: METRICTYPE.VOLUME,
    name: 'priceViolation',
    headerName: 'Pickup Price Violation',
    aggregationFunction: 'sum',
    metricName: 'priceViolation',
    fulfillmentType: 'pickUp',
    dataKey: 'sumPickUpPriceViolation'
  },
  deliveryPriceViolation: {
    currencySymbol: '',
    metricType: METRICTYPE.VOLUME,
    name: 'priceViolation',
    headerName: 'Delivery Price Violation',
    aggregationFunction: 'sum',
    metricName: 'priceViolation',
    fulfillmentType: 'delivery',
    dataKey: 'sumDeliveryPriceViolation'
  },
  shippingPriceViolation: {
    currencySymbol: '',
    metricType: METRICTYPE.VOLUME,
    name: 'priceViolation',
    headerName: 'Shipping Price Violation',
    aggregationFunction: 'sum',
    metricName: 'priceViolation',
    fulfillmentType: 'shipping',
    dataKey: 'sumShippingPriceViolation'
  },
  pickUpInStockRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'inStockRate',
    headerName: 'Pickup In-Stock Rate',
    aggregationFunction: 'avg',
    metricName: 'inStockRate',
    fulfillmentType: 'pickUp',
    dataKey: 'avgPickUpInStockRate'
  },
  shippingInStockRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'inStockRate',
    headerName: 'Shipping In-Stock Rate',
    aggregationFunction: 'avg',
    metricName: 'inStockRate',
    fulfillmentType: 'shipping',
    dataKey: 'avgShippingInStockRate'
  },
  deliveryInStockRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'inStockRate',
    headerName: 'Delivery In-Stock Rate',
    aggregationFunction: 'avg',
    metricName: 'inStockRate',
    fulfillmentType: 'delivery',
    dataKey: 'avgDeliveryInStockRate'
  },
  outOfStockRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'outOfStockRate',
    headerName: 'Out-of-Stock Rate',
    aggregationFunction: 'avg',
    metricName: 'outOfStockRate',
    dataKey: 'avgOutOfStockRate'
  },
  shippingOutOfStockRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'outOfStockRate',
    headerName: 'Shipping Out-of-Stock Rate',
    aggregationFunction: 'avg',
    metricName: 'outOfStockRate',
    fulfillmentType: 'shipping',
    dataKey: 'avgShippingOutOfStockRate'
  },
  pickUpOutOfStockRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'outOfStockRate',
    headerName: 'Pickup Out-of-Stock Rate',
    aggregationFunction: 'avg',
    metricName: 'outOfStockRate',
    fulfillmentType: 'pickUp',
    dataKey: 'avgPickUpOutOfStockRate'
  },
  deliveryOutOfStockRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'outOfStockRate',
    headerName: 'Delivery Out-of-Stock Rate',
    aggregationFunction: 'avg',
    metricName: 'outOfStockRate',
    fulfillmentType: 'delivery',
    dataKey: 'avgDeliveryOutOfStockRate'
  },
  placementRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'placementRate',
    headerName: 'placementRate',
    aggregationFunction: 'avg',
    metricName: 'placementRate',
    dataKey: 'avgPlacementRate'
  },
  pickUpPlacementRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'placementRate',
    headerName: 'Pickup Placement Rate',
    aggregationFunction: 'avg',
    fulfillmentType: 'pickUp',
    metricName: 'placementRate',
    dataKey: 'avgPickUpPlacementRate'
  },
  shippingPlacementRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'placementRate',
    headerName: 'Shipping Placement Rate',
    aggregationFunction: 'avg',
    fulfillmentType: 'shipping',
    metricName: 'placementRate',
    dataKey: 'avgShippingPlacementRate'
  },
  deliveryPlacementRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'placementRate',
    headerName: 'Delivery Placement Rate',
    aggregationFunction: 'avg',
    fulfillmentType: 'delivery',
    metricName: 'placementRate',
    dataKey: 'avgDeliveryPlacementRate'
  },
  availabilityRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'availabilityRate',
    headerName: 'Availability Rate',
    aggregationFunction: 'avg',
    metricName: 'availabilityRate',
    dataKey: 'avgAvailabilityRate'
  },
  pickUpAvailabilityRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'availabilityRate',
    headerName: 'Pickup Availability Rate',
    aggregationFunction: 'avg',
    fulfillmentType: 'pickUp',
    metricName: 'availabilityRate',
    dataKey: 'avgPickUpAvailabilityRate'
  },
  deliveryAvailabilityRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'availabilityRate',
    headerName: 'Delivery Availability Rate',
    aggregationFunction: 'avg',
    fulfillmentType: 'delivery',
    metricName: 'availabilityRate',
    dataKey: 'avgDeliveryAvailabilityRate'
  },
  shippingAvailabilityRate: {
    currencySymbol: '',
    metricType: METRICTYPE.PERCENT,
    name: 'availabilityRate',
    headerName: 'Shipping Availability Rate',
    aggregationFunction: 'avg',
    fulfillmentType: 'shipping',
    metricName: 'availabilityRate',
    dataKey: 'avgShippingAvailabilityRate'
  },
  totalShareOfShelf: {
    currencySymbol: '',
    name: 'shareOfShelfRate',
    headerName: 'Total Share of Shelf',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'shareOfShelfRate',
    shareOfShelfType: 'total',
    dataKey: 'totalShareOfShelf'
  },
  paidShareOfShelf: {
    currencySymbol: '',
    name: 'shareOfShelfRate',
    headerName: 'Paid Share of Shelf',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'shareOfShelfRate',
    shareOfShelfType: 'paid',
    dataKey: 'paidShareOfShelf'
  },
  organicShareOfShelf: {
    currencySymbol: '',
    name: 'shareOfShelfRate',
    headerName: 'Organic Share of Shelf',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'shareOfShelfRate',
    shareOfShelfType: 'organic',
    dataKey: 'organicShareOfShelf'
  },
  contentScore: {
    currencySymbol: '',
    name: 'contentScore',
    headerName: 'Content Score',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'contentScore',
    dataKey: 'contentScore_avg'
  },
  titleScore: {
    currencySymbol: '',
    name: 'titleScore',
    headerName: 'Title',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'titleScore',
    dataKey: 'titleScore_avg'
  },

  bulletsScore: {
    currencySymbol: '',
    name: 'bulletsScore',
    headerName: 'Bullet',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'bulletsScore',
    dataKey: 'bulletScore_avg'
  },
  imageScore: {
    currencySymbol: '',
    name: 'imageScore',
    headerName: 'Image',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'imageScore',
    dataKey: 'imageScore_avg'
  },
  videoScore: {
    currencySymbol: '',
    name: 'videoScore',
    headerName: 'Video',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'videoScore',
    dataKey: 'videoScore_avg'
  },
  // descriptionScore,enhancedScore,healthScore
  descriptionScore: {
    currencySymbol: '',
    name: 'descriptionScore',
    headerName: 'Description',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'descriptionScore',
    dataKey: 'descriptionScore_avg'
  },
  enhancedScore: {
    currencySymbol: '',
    name: 'enhancedScore',
    headerName: 'Enhanced',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'enhancedScore',
    dataKey: 'enhancedScore_avg'
  },
  healthScore: {
    currencySymbol: '',
    name: 'healthScore',
    headerName: 'Label',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'healthScore',
    dataKey: 'healthScore_avg'
  },
  titleAccuracy: {
    currencySymbol: '',
    name: 'titleAccuracy',
    headerName: 'Title',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'titleAccuracy',
    dataKey: 'titleAccuracy_computed'
  },
  descriptionAccuracy: {
    currencySymbol: '',
    name: 'descriptionAccuracy',
    headerName: 'Description',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'descriptionAccuracy',
    dataKey: 'descriptionAccuracy_computed'
  },
  bulletsAccuracy: {
    currencySymbol: '',
    name: 'bulletsAccuracy',
    headerName: 'Bullets',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'bulletsAccuracy',
    dataKey: 'bulletsAccuracy_computed'
  },
  imageUrlsAccuracy: {
    currencySymbol: '',
    name: 'imageUrlsAccuracy',
    headerName: 'Images',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'imageUrlsAccuracy',
    dataKey: 'imageUrlsAccuracy_computed'
  },
  videoUrlsAccuracy: {
    currencySymbol: '',
    name: 'videoUrlsAccuracy',
    headerName: 'Videos',
    metricType: METRICTYPE.PERCENT,
    aggregationFunction: 'avg',
    metricName: 'videoUrlsAccuracy',
    dataKey: 'videoUrlsAccuracy_computed'
  }
};

export const omniTableViewField = {
  keyMetric: [
    {
      ...fieldForTable.retailPrice
    },
    {
      ...fieldForTable.inStockRate
    },
    {
      ...fieldForTable.promotion
    },
    {
      ...fieldForTable.priceViolation
    },
    {
      ...fieldForTable.stores
    },
    {
      ...fieldForTable.retailers
    }
  ],
  retailPrice: [
    {
      ...fieldForTable.retailPrice
    },
    {
      ...fieldForTable.pickUpRetailPrice
    },
    {
      ...fieldForTable.deliveryRetailPrice
    },
    {
      ...fieldForTable.shippingRetailPrice
    },
    {
      ...fieldForTable.stores
    },
    {
      ...fieldForTable.retailers
    }
  ],
  promotion: [
    {
      ...fieldForTable.promotionPrice
    },
    {
      ...fieldForTable.pickUpPromotionPrice
    },
    {
      ...fieldForTable.deliveryPromotionPrice
    },
    {
      ...fieldForTable.shippingPromotionPrice
    },
    {
      ...fieldForTable.stores
    },
    {
      ...fieldForTable.retailers
    }
  ],
  priceViolation: [
    {
      ...fieldForTable.priceViolation
    },
    {
      ...fieldForTable.pickUpPriceViolation
    },
    {
      ...fieldForTable.deliveryPriceViolation
    },
    {
      ...fieldForTable.shippingPriceViolation
    },
    {
      ...fieldForTable.stores
    },
    {
      ...fieldForTable.retailers
    }
  ],
  inStockRate: [
    {
      ...fieldForTable.inStockRate
    },
    {
      ...fieldForTable.pickUpInStockRate
    },
    {
      ...fieldForTable.deliveryInStockRate
    },
    {
      ...fieldForTable.shippingInStockRate
    },
    {
      ...fieldForTable.stores
    },
    {
      ...fieldForTable.retailers
    }
  ],
  outOfStockRate: [
    {
      ...fieldForTable.outOfStockRate
    },
    {
      ...fieldForTable.pickUpOutOfStockRate
    },
    {
      ...fieldForTable.deliveryOutOfStockRate
    },
    {
      ...fieldForTable.shippingOutOfStockRate
    },
    {
      ...fieldForTable.stores
    },
    {
      ...fieldForTable.retailers
    }
  ],
  placementRate: [
    {
      ...fieldForTable.placementRate
    },
    {
      ...fieldForTable.pickUpPlacementRate
    },
    { ...fieldForTable.deliveryPlacementRate },
    { ...fieldForTable.shippingPlacementRate },
    {
      ...fieldForTable.stores
    },
    {
      ...fieldForTable.retailers
    }
  ],
  availabilityRate: [
    {
      ...fieldForTable.availabilityRate
    },
    {
      ...fieldForTable.pickUpAvailabilityRate
    },
    {
      ...fieldForTable.deliveryAvailabilityRate
    },
    {
      ...fieldForTable.shippingAvailabilityRate
    },
    {
      ...fieldForTable.stores
    },
    {
      ...fieldForTable.retailers
    }
  ],
  totalShareOfShelf: [
    { ...fieldForTable.totalShareOfShelf },
    {
      ...fieldForTable.totalShareOfShelf,
      headerName: 'Total Top 3 Share of Shelf',
      topSearchResults: 'top3',
      dataKey: 'totalTop3ShareOfShelf'
    },
    {
      ...fieldForTable.totalShareOfShelf,
      headerName: 'Total Top 5 Share of Shelf',
      topSearchResults: 'top5',
      dataKey: 'totalTop5ShareOfShelf'
    },
    {
      ...fieldForTable.totalShareOfShelf,
      headerName: 'Total Top 10 Share of Shelf',
      topSearchResults: 'top10',
      dataKey: 'totalTop10ShareOfShelf'
    }
  ],
  paidShareOfShelf: [
    { ...fieldForTable.paidShareOfShelf },
    {
      ...fieldForTable.paidShareOfShelf,
      headerName: 'Paid Top 3 Share of Shelf',
      topSearchResults: 'top3',
      dataKey: 'paidTop3ShareOfShelf'
    },
    {
      ...fieldForTable.paidShareOfShelf,
      headerName: 'Paid Top 5 Share of Shelf',
      topSearchResults: 'top5',
      dataKey: 'paidTop5ShareOfShelf'
    },
    {
      ...fieldForTable.paidShareOfShelf,
      headerName: 'Paid Top 10 Share of Shelf',
      topSearchResults: 'top10',
      dataKey: 'paidTop10ShareOfShelf'
    }
  ],
  organicShareOfShelf: [
    { ...fieldForTable.organicShareOfShelf },
    {
      ...fieldForTable.organicShareOfShelf,
      headerName: 'Organic Top 3 Share of Shelf',
      topSearchResults: 'top3',
      dataKey: 'organicTop3ShareOfShelf'
    },
    {
      ...fieldForTable.organicShareOfShelf,
      headerName: 'Organic Top 5 Share of Shelf',
      topSearchResults: 'top5',
      dataKey: 'organicTop5ShareOfShelf'
    },
    {
      ...fieldForTable.organicShareOfShelf,
      headerName: 'Organic Top 10 Share of Shelf',
      topSearchResults: 'top10',
      dataKey: 'organicTop10ShareOfShelf'
    }
  ],
  fulfillmentTime: [],
  deliveryTime: [],
  shippingTime: [],
  pickupTime: [],
  content: [
    { ...fieldForTable.contentScore },
    { ...fieldForTable.titleScore },
    { ...fieldForTable.descriptionScore },
    { ...fieldForTable.bulletsScore },
    { ...fieldForTable.imageScore },
    { ...fieldForTable.videoScore },
    { ...fieldForTable.enhancedScore },
    { ...fieldForTable.healthScore }
  ],
  contentAccuracy: [
    { ...fieldForTable.titleAccuracy },
    { ...fieldForTable.descriptionAccuracy },
    { ...fieldForTable.bulletsAccuracy },
    { ...fieldForTable.imageUrlsAccuracy },
    { ...fieldForTable.videoUrlsAccuracy }
  ]
};
