export const limit = (val: string, max: string) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = `0${val}`;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '00';
      // this can happen when user pastes a number in the input
    } else if (val > max) {
      val = max;
    }
  }

  return val;
};

export const format24H = (val: string) => {
  const hours = limit(val.substring(0, 2), '23');
  const minutes = limit(val.substring(2, 4), '59');

  return hours + (minutes.length ? `:${minutes}` : '');
};
