import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';

import colors from 'src/utils/colors';
import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import EntityGrid from 'src/components/EntityGrid';
import { EnhancedDonutChartContainer } from 'src/components/Charts/Donut';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import { buildAdvertisingTopEntitiesChart } from 'src/components/Layout/util/DynamicAdvertisingWidgets';
import {
  buildTrendWidgetConfig,
  buildTopEntitiesWidgetConfig,
  buildWidgetGroup
} from 'src/components/Layout/LayoutUtil';
import { panic } from 'src/utils/mixpanel';
import { propEq } from 'src/utils/fp';
import { shouldShowLicenseAtlas } from '../../../utils/app';

const getGroupByMapping = (metricType) => {
  const [category, subcategory, brand, product, keyword, parentBrand, license] = [
    'categoryId',
    'subCategoryId',
    'brandId',
    'stacklineSku',
    'searchTerm',
    'parentBrandId',
    'licenseId'
  ].map((groupByFieldName) => ({
    groupByFieldName,
    aggregationFieldNames: [metricType]
  }));
  return { category, subcategory, brand, product, keyword, parentBrand, license };
};

export default function getTrafficPageLayout({ app, tab, metricType, entity }) {
  const indexName = tab;
  const onAllCategoriesPage = entity.type === 'category' && entity.id === '0';
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));
  const retailerIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'));
  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.atlas.retailer),
    categoryEntity: _cloneDeep(ENTITIES.atlas.category),
    relatedEntity: _cloneDeep(ENTITIES.atlas.brand),
    weekIdField,
    retailerIdField
  };
  const widgets = [];
  const allowHorizontalScroll = {
    horizontalScrolling: {
      enabled: true,
      step: 3
    },
    xAxis: [
      {
        min: 0,
        max: 9
      }
    ],
    enableSwitchingGroupBy: true
  };
  const marketShareField = INDEX_FIELDS.getField(
    app.name,
    indexName,
    `${metricType}MarketShare`,
    entity.type,
    weekIdField.name
  );
  const noOfTopEntities = 50;
  const { category, subcategory, brand, product, keyword, parentBrand, license } = getGroupByMapping(metricType);
  let aggregationFieldMapping = {};
  aggregationFieldMapping = {
    company: [brand, keyword, category, subcategory, product, parentBrand],
    category: [brand, keyword, subcategory, product, parentBrand],
    subcategory: [brand, keyword, product, parentBrand],
    brand: [keyword, category, subcategory, product],
    product: [keyword],
    segment: [brand, keyword, category, subcategory, product, parentBrand],
    businessunit: [brand, keyword, category, subcategory, product, parentBrand],
    searchtermlist: [keyword, brand, subcategory, category, product, parentBrand]
  };
  if (shouldShowLicenseAtlas()) {
    aggregationFieldMapping = {
      company: [brand, keyword, category, subcategory, product, parentBrand, license],
      category: [brand, keyword, subcategory, product, parentBrand, license],
      subcategory: [brand, keyword, product, parentBrand, license],
      brand: [keyword, category, subcategory, product],
      product: [keyword],
      segment: [brand, keyword, category, subcategory, product, parentBrand, license],
      businessunit: [brand, keyword, category, subcategory, product, parentBrand, license],
      searchtermlist: [keyword, brand, subcategory, category, product, parentBrand, license]
    }
  }

  let enableComparison = true;
  if (metricType === 'totalClicks') {
    enableComparison = false;
    const mainFields = [
      INDEX_FIELDS.getField(app.name, indexName, 'otherClicks', entity.type, dataConfig.retailerIdField.name),
      INDEX_FIELDS.getField(app.name, indexName, 'organicClicks', entity.type, dataConfig.retailerIdField.name),
      INDEX_FIELDS.getField(app.name, indexName, 'adClicks', entity.type, dataConfig.retailerIdField.name)
    ];
    const otherTrafficDonutChart = {
      CustomComponent: EnhancedDonutChartContainer,
      view: {
        ...VIEWS.donutChart,
        name: `OtherTrafficDonutChart`,
        chartPropsOverride: {
          chart: {
            type: 'pie',
            height: 350
          },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false
              }
            }
          },
          tooltip: {
            enabled: true
          },
          disablePointSelect: true,
          enableMultiSelect: false,
          showFullValue: false
        },
        customHeader: {
          enabled: true,
          title: 'Total Traffic'
        },
        container: {
          style: {
            minWidth: '500px',
            height: '440px'
          }
        }
      },
      data: {
        indexName: 'traffic',
        mainMetric: mainFields[0],
        secondaryMetric: { ...mainFields, displayName: 'Total Traffic' },
        aggregationFields: mainFields,
        groupByField: INDEX_FIELDS.getField(app.name, indexName, dataConfig.retailerIdField.name, entity.type)
      }
    };
    widgets.push(
      buildWidgetGroup(
        [
          otherTrafficDonutChart,
          buildTrendWidgetConfig(app, indexName, entity, 'weekId', [metricType], weekIdField, {
            view: {
              chartPropsOverride: {
                title: {
                  text: ''
                },
                subtitle: {
                  text: '',
                  style: {
                    'border-bottom': 'none'
                  }
                },
                chart: {
                  height: 450,
                  marginTop: 100
                },
                // legend: { enabled: false },
                yAxis: [
                  {
                    visible: false
                  }
                ]
              },
              container: { style: { width: '60%', display: 'inline-block', marginBottom: '100px' } }
            }
          })
        ],
        {
          view: {
            container: {
              style: {
                height: '630px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around'
              }
            }
          }
        }
      )
    );

    // Organic Traffic by XXX
    if (!aggregationFieldMapping[entity.type]) {
      return panic(`No entity type mapping for entity type: ${entity.type}`);
    }

    widgets.push(
      buildTopEntitiesWidgetConfig(
        app,
        indexName,
        entity,
        aggregationFieldMapping[entity.type],
        noOfTopEntities,
        weekIdField,
        {
          data: {
            configByGroupByFieldName: {
              searchTerm: {
                conditions: {
                  termFilters: [
                    {
                      fieldName: 'searchTerm',
                      condition: 'must_not',
                      values: ['[other traffic]', '[Other Traffic]']
                    }
                  ]
                }
              }
            }
          },
          view: {
            enableComparison: true,
            chartPropsOverride: {
              ...allowHorizontalScroll,
              enableSwitchingGroupBy: true
            },
            // Group by keyword by default if keyword is a valid group-by field option
            defaultGroupByField: aggregationFieldMapping[entity.type].find(propEq('groupByFieldName', 'searchTerm'))
              ? INDEX_FIELDS.getField(app.name, indexName, 'searchTerm', entity.type)
              : undefined
          }
        }
      )
    );

    if (!onAllCategoriesPage) {
      widgets.push(
        buildTrendWidgetConfig(app, indexName, entity, 'weekId', [metricType], weekIdField, {
          view: {
            displayName: marketShareField.displayName,

            metricFields: [marketShareField],
            chartPropsOverride: {
              title: {
                text: marketShareField.displayName
              }
            }
          },
          data: {
            marketShare: { compute: true }
          }
        })
      );
    }

    const keywordsWithTrafficWidgetConfig = buildTrendWidgetConfig(
      app,
      indexName,
      entity,
      'weekId',
      ['searchTerm'],
      weekIdField,
      {
        view: { chartPropsOverride: { title: { text: 'Keywords with Traffic' } } }
      }
    );
    keywordsWithTrafficWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
      (x) => x.name === 'searchTerm'
    ).conditions = {
      rangeFilters: [
        {
          fieldName: 'totalClicks',
          minValue: 1
        }
      ]
    };
    widgets.push(keywordsWithTrafficWidgetConfig);

    if (entity.type !== 'product') {
      // COME BACK HERE
      const keywordWithTrafficByBrandWidgetConfig = buildTopEntitiesWidgetConfig(
        app,
        indexName,
        entity,
        [
          {
            groupByFieldName: 'brandId',
            aggregationFieldNames: ['searchTerm']
          },
          {
            groupByFieldName: 'retailerId',
            aggregationFieldNames: ['searchTerm']
          }
        ],
        noOfTopEntities,
        weekIdField,
        {
          view: {
            seriesNames: ['Total Keywords', 'Brand Coverage'],
            chartSeriesColors: ['#ECF6FF', colors.comparison, colors.stacklineBlue],
            chartPropsOverride: {
              ...allowHorizontalScroll,
              title: { text: 'Keywords with Traffic by Brand' },
              plotOptions: {
                column: {
                  grouping: false,
                  shadow: false,
                  borderWidth: 0
                }
              }
            }
          }
        }
      );
      keywordWithTrafficByBrandWidgetConfig.data.configByGroupByFieldName.brandId.aggregationFields.find(
        (x) => x.name === 'searchTerm'
      ).conditions = {
        rangeFilters: [
          {
            fieldName: 'organicClicks',
            minValue: 1
          }
        ]
      };
      keywordWithTrafficByBrandWidgetConfig.data.configByGroupByFieldName.retailerId.aggregationFields.find(
        (x) => x.name === 'searchTerm'
      ).conditions = {
        rangeFilters: [
          {
            fieldName: 'organicClicks',
            minValue: 1
          }
        ]
      };
    }
  }
  if (metricType === 'organicClicks') {
    widgets.push(
      buildTrendWidgetConfig(app, indexName, entity, 'weekId', [metricType], weekIdField, {
        view: { chartPropsOverride: { title: { text: 'Organic Traffic' } } }
      })
    );
    // Organic Traffic by XXX
    widgets.push(
      buildTopEntitiesWidgetConfig(
        app,
        indexName,
        entity,
        aggregationFieldMapping[entity.type],
        noOfTopEntities,
        weekIdField,
        {
          data: {
            configByGroupByFieldName: {
              searchTerm: {
                conditions: {
                  termFilters: [
                    {
                      fieldName: 'searchTerm',
                      condition: 'must_not',
                      values: ['[other traffic]', '[Other Traffic]']
                    }
                  ]
                }
              }
            }
          },
          view: {
            enableComparison: true,
            chartPropsOverride: {
              ...allowHorizontalScroll,
              enableSwitchingGroupBy: true
            }
          }
        }
      )
    );
    if (!onAllCategoriesPage) {
      widgets.push(
        buildTrendWidgetConfig(app, indexName, entity, 'weekId', [metricType], weekIdField, {
          view: {
            displayName: marketShareField.displayName,

            metricFields: [marketShareField],
            chartPropsOverride: {
              title: {
                text: marketShareField.displayName
              }
            }
          },
          data: {
            marketShare: { compute: true }
          }
        })
      );
    }

    if (entity.type !== 'product') {
      const productsWithOrganicTrafficWidgetConfig = buildTrendWidgetConfig(
        app,
        indexName,
        entity,
        'weekId',
        ['stacklineSku'],
        weekIdField,
        {
          view: { chartPropsOverride: { title: { text: 'Products with Organic Traffic' } } }
        }
      );
      productsWithOrganicTrafficWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
        (x) => x.name === 'stacklineSku'
      ).conditions = {
        rangeFilters: [
          {
            fieldName: 'organicClicks',
            minValue: 1
          }
        ]
      };
      widgets.push(productsWithOrganicTrafficWidgetConfig);
    }
    const keywordsWithOrganicTrafficWidgetConfig = buildTrendWidgetConfig(
      app,
      indexName,
      entity,
      'weekId',
      ['searchTerm'],
      weekIdField,
      {
        view: { chartPropsOverride: { title: { text: 'Keywords with Organic Traffic' } } }
      }
    );
    keywordsWithOrganicTrafficWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
      (x) => x.name === 'searchTerm'
    ).conditions = {
      rangeFilters: [
        {
          fieldName: 'organicClicks',
          minValue: 1
        }
      ]
    };
    widgets.push(keywordsWithOrganicTrafficWidgetConfig);

    if (entity.type !== 'product' && entity.type !== 'brand') {
      const keywordsWithOrganicTrafficByBrandWidgetConfig = buildTopEntitiesWidgetConfig(
        app,
        indexName,
        entity,
        [
          {
            groupByFieldName: 'brandId',
            aggregationFieldNames: ['searchTerm']
          },
          {
            groupByFieldName: 'retailerId',
            aggregationFieldNames: ['searchTerm']
          }
        ],
        noOfTopEntities,
        weekIdField,
        {
          view: {
            seriesNames: ['Total Keywords', 'Brand Coverage'],
            chartSeriesColors: ['#ECF6FF', colors.comparison, colors.stacklineBlue],
            chartPropsOverride: {
              ...allowHorizontalScroll,
              title: { text: 'Keywords with Organic Traffic by Brand' },
              plotOptions: {
                column: {
                  grouping: false,
                  shadow: false,
                  borderWidth: 0
                }
              }
            }
          }
        }
      );

      keywordsWithOrganicTrafficByBrandWidgetConfig.data.configByGroupByFieldName.brandId.aggregationFields.find(
        (x) => x.name === 'searchTerm'
      ).conditions = {
        rangeFilters: [
          {
            fieldName: 'organicClicks',
            minValue: 1
          }
        ]
      };
      keywordsWithOrganicTrafficByBrandWidgetConfig.data.configByGroupByFieldName.retailerId.aggregationFields.find(
        (x) => x.name === 'searchTerm'
      ).conditions = {
        rangeFilters: [
          {
            fieldName: 'organicClicks',
            minValue: 1
          }
        ]
      };
    }
  } else if (metricType === 'adClicks') {
    widgets.push(buildTrendWidgetConfig(app, indexName, entity, 'weekId', [metricType], weekIdField));

    if (!onAllCategoriesPage) {
      widgets.push(
        buildTrendWidgetConfig(app, indexName, entity, 'weekId', [metricType], weekIdField, {
          view: {
            displayName: marketShareField.displayName,
            metricFields: [marketShareField],
            chartPropsOverride: {
              title: {
                text: marketShareField.displayName
              }
            }
          },
          data: {
            marketShare: { compute: true }
          }
        })
      );
    }

    if (entity.type !== 'product') {
      // Multi-group-by top entities chart
      widgets.push(buildAdvertisingTopEntitiesChart({ indexName, entity, app, weekIdField }));
    }

    widgets.push(buildTrendWidgetConfig(app, indexName, entity, 'weekId', ['adSpend'], weekIdField));

    if (entity.type !== 'product' && entity.type !== 'brand') {
      const aggregationFieldsConfig = [
        {
          groupByFieldName: 'brandId',
          aggregationFieldNames: ['adSpend']
        },
        {
          groupByFieldName: 'parentBrandId',
          aggregationFieldNames: ['adSpend']
        }
      ];
      if (shouldShowLicenseAtlas()) {
        aggregationFieldsConfig.push({
          groupByFieldName: 'licenseId',
          aggregationFieldNames: ['adSpend']
        })
      }
      widgets.push(
        buildTopEntitiesWidgetConfig(app, indexName, entity, aggregationFieldsConfig, noOfTopEntities, weekIdField, {
          view: {
            enableComparison: true,
            chartPropsOverride: {
              ...allowHorizontalScroll,
              enableSwitchingGroupBy: true
            }
          }
        })
      );
    }

    if (entity.type !== 'product') {
      const productsWithOrganicTrafficWidgetConfig = buildTrendWidgetConfig(
        app,
        indexName,
        entity,
        'weekId',
        ['stacklineSku'],
        weekIdField,
        {
          view: { chartPropsOverride: { title: { text: 'Products with Paid Traffic' } } }
        }
      );
      productsWithOrganicTrafficWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
        (x) => x.name === 'stacklineSku'
      ).conditions = {
        rangeFilters: [
          {
            fieldName: 'adClicks',
            minValue: 1
          }
        ]
      };

      widgets.push(productsWithOrganicTrafficWidgetConfig);
    }

    const keywordsWithAdTrafficWidgetConfig = buildTrendWidgetConfig(
      app,
      indexName,
      entity,
      'weekId',
      ['searchTerm'],
      weekIdField,
      {
        view: { chartPropsOverride: { title: { text: 'Keywords with Paid Traffic' } } }
      }
    );
    keywordsWithAdTrafficWidgetConfig.data.configByGroupByFieldName.weekId.aggregationFields.find(
      (x) => x.name === 'searchTerm'
    ).conditions = {
      rangeFilters: [
        {
          fieldName: 'adClicks',
          minValue: 1
        }
      ]
    };
    widgets.push(keywordsWithAdTrafficWidgetConfig);

    if (entity.type !== 'product') {
      // TEMPORARY
      if (entity.type !== 'brand') {
        const keywordsWithAdTrafficByBrandWidgetConfig = buildTopEntitiesWidgetConfig(
          app,
          indexName,
          entity,
          [
            {
              groupByFieldName: 'brandId',
              aggregationFieldNames: ['searchTerm']
            },
            {
              groupByFieldName: 'retailerId',
              aggregationFieldNames: ['searchTerm']
            }
          ],
          noOfTopEntities,
          weekIdField,
          {
            view: {
              seriesNames: ['Total Keywords', 'Brand Coverage'],
              chartSeriesColors: ['#ECF6FF', colors.comparison, colors.stacklineBlue],
              chartPropsOverride: {
                ...allowHorizontalScroll,
                title: { text: 'Keywords with Paid Traffic by Brand' },
                plotOptions: {
                  column: {
                    grouping: false,
                    shadow: false,
                    borderWidth: 0
                  }
                }
              }
            }
          }
        );
        keywordsWithAdTrafficByBrandWidgetConfig.data.configByGroupByFieldName.brandId.aggregationFields.find(
          (x) => x.name === 'searchTerm'
        ).conditions = {
          rangeFilters: [
            {
              fieldName: 'adClicks',
              minValue: 1
            },
            {
              fieldName: 'organicClicks',
              minValue: 1
            }
          ]
        };
        keywordsWithAdTrafficByBrandWidgetConfig.data.configByGroupByFieldName.retailerId.aggregationFields.find(
          (x) => x.name === 'searchTerm'
        ).conditions = {
          rangeFilters: [
            {
              fieldName: 'organicClicks',
              minValue: 1
            }
          ]
        };
        // widgets.push(keywordsWithAdTrafficByBrandWidgetConfig);
      }
    }
  }

  const gridAggregationFields = [
    INDEX_FIELDS.getField(app.name, indexName, 'organicClicks', 'product', 'stacklineSku'),
    INDEX_FIELDS.getField(app.name, indexName, 'adClicks', 'product', 'stacklineSku'),
    INDEX_FIELDS.getField(app.name, indexName, 'totalClicks', 'product', 'stacklineSku'),
    INDEX_FIELDS.getField(app.name, indexName, 'adSpend', 'product', 'stacklineSku')
    // INDEX_FIELDS.getField(app.name, indexName, 'adRetailSales', 'product', 'stacklineSku')
  ];
  const skuAggregationFields = _cloneDeep(gridAggregationFields);
  skuAggregationFields.splice(
    2,
    0,
    INDEX_FIELDS.getField(app.name, indexName, 'otherClicks', 'product', 'stacklineSku')
  );

  widgets.push({
    CustomComponent: EntityGrid,
    view: _merge({}, VIEWS.entityGrid, {
      gridOptions: {
        enableGroupBy: true
      }
    }),
    data: {
      groupByFields: [
        {
          ...INDEX_FIELDS.getField(app.name, indexName, 'searchTerm', 'product'),
          displayName: 'Keyword'
        },
        INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')
      ],
      configByGroupByFieldName: {
        searchTerm: {
          indexName,
          entity: ENTITIES.atlas.searchTerm,
          mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
          aggregationFields: gridAggregationFields,
          tableView: {
            metricFields: gridAggregationFields
          }
        },
        stacklineSku: {
          indexName,
          entity: ENTITIES.atlas.product,
          mainMetricField: INDEX_FIELDS.getField(app.name, indexName, metricType, 'product', 'stacklineSku'),
          aggregationFields: skuAggregationFields,
          tableView: {
            metricFields: skuAggregationFields
          }
        }
      }
    }
  });

  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison,
    dataConfig,
    widgets
  };
}
