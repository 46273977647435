import React from 'react';
import { StacklineSizing, useStacklineTheme } from '@stackline/ui';
// eslint-disable-next-line import/no-unresolved
import { Property } from 'csstype';

export type SlColumnHorizontalPosition = 'start' | 'end' | 'center';

export type SlColumnVerticalPosition = 'start' | 'end' | 'center' | 'space-between';

export type SlColumnWidth = 'normal' | 'full';

export interface SlColumnProps {
  children: React.ReactNode;
  /**
   * Amount of space between each item. Note that this will not have any effect
   * if `verticalPosition` is `space-between`.
   */
  spacing?: StacklineSizing | number;
  /**
   * Padding on left and right
   */
  horizontalInset?: StacklineSizing;
  /**
   * Padding on top and bottom
   */
  verticalInset?: StacklineSizing;
  /**
   * Horizontal position of all items
   */
  horizontalPosition?: SlColumnHorizontalPosition;
  /**
   * Vertical position of all items
   */
  verticalPosition?: SlColumnVerticalPosition;
  /**
   * Width of each child. If a string, will be the widths for all
   * the items in the column. If an array, will apply the width
   * individually to each item.
   */
  widths?: SlColumnWidth | SlColumnWidth[];

  style?: React.CSSProperties;
}

const horizontalPositionToFlex: { [key in SlColumnHorizontalPosition]: Property.AlignItems } = {
  center: 'center',
  end: 'flex-end',
  start: 'flex-start'
};

const verticalPositionToFlex: { [key in SlColumnVerticalPosition]: Property.JustifyContent } = {
  'space-between': 'space-between',
  center: 'center',
  end: 'flex-end',
  start: 'flex-start'
};

/**
 * Common component to position items vertically
 */
export const SlColumn = ({
  children,
  spacing = 'none',
  horizontalInset = 'none',
  verticalInset = 'none',
  horizontalPosition = 'start',
  verticalPosition = 'start',
  widths = 'normal',
  style
}: SlColumnProps) => {
  const theme = useStacklineTheme();

  const itemWidths = (
    typeof widths === 'string' ? React.Children.map(children, () => widths) : widths
  ) as SlColumnWidth[];

  const getMarginTop = (index: number): number | string => {
    if (index > 0) {
      if (typeof spacing === 'string') {
        return theme.spacing[spacing];
      }

      return spacing || 0;
    }

    return 0;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing[verticalInset],
        paddingBottom: theme.spacing[verticalInset],
        paddingLeft: theme.spacing[horizontalInset],
        paddingRight: theme.spacing[horizontalInset],
        alignItems: horizontalPositionToFlex[horizontalPosition],
        justifyContent: verticalPositionToFlex[verticalPosition],
        height: '100%',
        boxSizing: 'border-box',
        ...style
      }}
    >
      {React.Children.toArray(children)
        .filter((child) => child !== null && child !== undefined)
        .map((child, index) => (
          // eslint-disable-next-line react/jsx-key
          <div
            style={{
              marginTop: getMarginTop(index),
              width: itemWidths[index] === 'full' ? '100%' : undefined
            }}
          >
            {child}
          </div>
        ))}
    </div>
  );
};
