import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Verification from './Verification';
import fontStyle from 'src/utils/fontStyle';

class VideoPicker extends React.Component {
  static defaultProps = {
    contentChanged: null
  };

  static propTypes = {
    videos: PropTypes.array.isRequired,
    contentChanged: PropTypes.array
  };

  state = {};

  componentWillMount() {
    const { videos, contentChanged } = this.props;

    if (!videos || (contentChanged && contentChanged.length === 0)) {
      return;
    }

    this.setState({ selectedVideo: videos[0] });
  }

  isValid = (val) => val === '';

  handleVideoClick = (val) => {
    this.setState({
      selectedVideo: val
    });
  };

  showVerification(contentChangedVal) {
    const { contentChanged } = this.props;

    if (!contentChanged) {
      return null;
    }

    return (
      <div className="accuracy__video-validate">
        <Verification isValid={this.isValid(contentChangedVal)} type="image" />
      </div>
    );
  }

  showVideo(contentChangedVal, index) {
    const { videos, contentChanged } = this.props;
    const { selectedVideo } = this.state;

    let value = null;
    let valueElement = null;
    if (contentChangedVal !== 'Missing required bullet') {
      value = videos[index];
      valueElement = (
        <video className="accuracy__video-element">
          <source src={`${value}#t=1`} />
        </video>
      );
    }

    return (
      <div // eslint-disable-line
        key={`${value}${index}`}
        className="accuracy__video-container"
        onClick={() => this.handleVideoClick(value)}
      >
        <div
          className={`accuracy__video-content ${
            !contentChanged || this.isValid(contentChangedVal) ? '' : 'accuracy__video-content--invalid'
          } ${value !== selectedVideo ? '' : 'accuracy__video-content--selected'}`}
        >
          {this.showVerification(contentChangedVal)}
          <div className="accuracy__video">{valueElement}</div>
        </div>
      </div>
    );
  }

  showSelectedVideo() {
    const { selectedVideo } = this.state;

    if (!selectedVideo) {
      return null;
    }

    return (
      <div className="accuracy__selected-video-container">
        <div className="accuracy__selected-video-content">
          <video controls className="accuracy__selected-video" src={selectedVideo} id={selectedVideo}>
            <source src={`${selectedVideo}#t=1`} />
          </video>
        </div>
      </div>
    );
  }

  render() {
    const { videos, contentChanged } = this.props;

    if (!videos || videos.length === 0) {
      return (
        <span style={{ fontWeight: fontStyle.regularWeight }}>
          This product has no{contentChanged === null ? ' approved ' : ' '}videos.
        </span>
      );
    }

    return (
      <Fragment>
        {this.showSelectedVideo()}
        <div className="accuracy__videos-content">
          {(contentChanged || videos).map((val, index) => this.showVideo(val, index))}
        </div>
      </Fragment>
    );
  }
}

export default VideoPicker;
