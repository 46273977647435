import React, { useState, useMemo } from 'react';
import _get from 'lodash/get';
import { StacklineColor, useStacklineTheme, SlMenu, SlDropdownMenuOption } from '@stackline/ui';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import LegendWithMetric, { LegendWithMetricProps } from '../Legend/LegendWithMetric';
import { AggregationField } from 'src/types/application/types';
import CommonSummaryWithDropdown from 'src/components/BeaconRedesignComponents/common/CommonSummaryWithDropdown';
import { useStyles } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import {
  SlDropdownMenu,
  SlDropdownMenuProps
} from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import Box from '@mui/material/Box';
import domtoimage from 'src/utils/dom-to-image';
import saveAs from 'file-saver';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

export interface BeaconChartWithLegendProps {
  children: React.ReactNode;
  title: React.ReactNode;
  comparisonLegendProps?: Omit<LegendWithMetricProps, 'color'>;
  primaryLegendProps?: Omit<LegendWithMetricProps, 'color'> & { color?: StacklineColor };
  groupByFields?: React.ComponentProps<typeof CommonSummaryWithDropdown>['groupByFields'];
  selectedGroupBy?: AggregationField;
  handleGroupBy?: (id: string) => void;
  handleMainMetric?: (id: string) => void;
  mainMetricFields?: React.ComponentProps<typeof CommonSummaryWithDropdown>['mainMetricFields'];
  selectedMainMetric?: AggregationField;
  loading?: boolean;
  loadingComponent?: React.ReactNode;
  subtitle?: string;
  disableExportCsv?: boolean;
  convertSeriesToDelimitedData?: () => Promise<any>;
  /**
   * Diplays the primary legend indicator as dashed when true
   */
  primaryDashed?: boolean;
  /**
   * Color for the secondary legend indicator
   */
  secondaryColor?: StacklineColor;
  showTitleSummary?: boolean;
  singleDropdownProps?: SlDropdownMenuProps & { prefix?: string };
  /**
   * Optional builder for the chart dropdown options. Should be combined with
   * `dropdownOptionHandler` to handle the click event. Passes in the default
   * dropdown options and returns the new options.
   */
  buildDropdownMenuOptions?: (options: SlDropdownMenuOption[]) => SlDropdownMenuOption[];
  /**
   * Optional handler for the dropdown options. Can be combined with
   * `buildDropdownMenuOptions` to build the options if adding
   * custom options.
   */

  dropdownOptionHandler?: (option: SlDropdownMenuOption, defaultDropdownHandler: () => Promise<void>) => void;
  showExportMenu?: boolean;
  saveImageRef?: React.RefObject<HTMLDivElement>;
  includeSummaryInfo?: boolean;
}

/**
 * Generic container for charts with a legend. The chart to render should be passed in as a child.
 */
const BeaconChartWithLegend = ({
  children,
  comparisonLegendProps,
  primaryLegendProps,
  title,
  groupByFields,
  handleGroupBy,
  handleMainMetric,
  selectedGroupBy,
  mainMetricFields,
  selectedMainMetric,
  loading,
  loadingComponent,
  subtitle,
  primaryDashed,
  secondaryColor,
  disableExportCsv = false,
  showTitleSummary = true,
  singleDropdownProps,
  convertSeriesToDelimitedData,
  buildDropdownMenuOptions,
  dropdownOptionHandler,
  showExportMenu = true,
  includeSummaryInfo = true,
  /**
   * Ref to the container to save as an image
   */
  saveImageRef
}: BeaconChartWithLegendProps) => {
  const classes = useStyles();
  const theme = useStacklineTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenExports = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const chartContainerRef = React.useRef<HTMLDivElement>(null);

  const handleDropdownClick = (option: SlDropdownMenuOption) => {
    const defaultDropdownHandler = async () => {
      const file_name = title || _get(selectedMainMetric, 'displayName');
      try {
        const blob = await domtoimage.toBlob(saveImageRef?.current ?? (chartContainerRef.current as HTMLDivElement), {
          additionalOptions: {
            scale: 2
          }
        });
        if (option.id === 'saveImage') {
          saveAs(blob, `${file_name}.png`);
        } else if (option.id === 'copyImage') {
          if (blob) {
            const clipboardItem = new ClipboardItem({ 'image/png': blob });
            await navigator.clipboard.write([clipboardItem]);
          } else {
            console.error(`Unable to generate blob for chart: ${title}`);
          }
        } else if (option.id === 'exportCsv' && convertSeriesToDelimitedData) {
          // Export as csv
          await convertSeriesToDelimitedData();
        }
      } catch (e) {
        console.error(e);
      }
      setAnchorEl(null);
    };

    if (dropdownOptionHandler) {
      dropdownOptionHandler(option, defaultDropdownHandler);
    } else {
      defaultDropdownHandler();
    }
  };

  const handleCloseExports = () => {
    setAnchorEl(null);
  };

  const exportButton = showExportMenu ? (
    <IconButton
      onClick={handleOpenExports}
      sx={{
        height: theme.spacing.mdl,
        width: theme.spacing.mdl,
        borderRadius: theme.spacing.xs,
        color: theme.colors.primary
      }}
    >
      <MoreHorizIcon />
    </IconButton>
  ) : null;

  const dropdownOptions: SlDropdownMenuOption[] = useMemo(() => {
    const defaultOptions: SlDropdownMenuOption[] = [
      {
        id: 'saveImage',
        label: 'Save image'
      },
      {
        id: 'copyImage',
        label: 'Copy image'
      },
      ...(disableExportCsv
        ? []
        : [
            {
              id: 'exportCsv',
              label: 'Export CSV'
            }
          ])
    ];
    if (buildDropdownMenuOptions) {
      return buildDropdownMenuOptions(defaultOptions);
    }
    return defaultOptions;
  }, [disableExportCsv, buildDropdownMenuOptions]);

  return (
    <div style={{ backgroundColor: 'white' }} ref={chartContainerRef}>
      <Flex gap="md" flexDirection="column">
        {showTitleSummary && (
          <Box marginBottom={subtitle ? theme.spacing.none : theme.spacing.md}>
            <Flex justifyContent="space-between">
              {groupByFields && groupByFields.length > 1 ? (
                <Flex flexDirection="column">
                  <CommonSummaryWithDropdown
                    prefixTitle={`${title} `}
                    handleGroupByChange={(id) => handleGroupBy(id)}
                    groupByFields={groupByFields}
                    selectedGroupBy={selectedGroupBy}
                    mainMetricFields={mainMetricFields}
                    handleMainMetric={handleMainMetric}
                    selectedMainMetric={selectedMainMetric}
                    shouldShowFullItemText
                  />
                  <CommonSummaryInfoSubtitle title="" includeSubtitle={includeSummaryInfo} />
                </Flex>
              ) : (
                <CommonSummaryInfoSubtitle
                  title={
                    singleDropdownProps ? (
                      <div className={classes.entityTableHeaderContainer}>
                        <Flex gap="sm" alignItems="center">
                          {singleDropdownProps.prefix && <Text variant="h4">{singleDropdownProps.prefix}</Text>}
                          <SlDropdownMenu {...singleDropdownProps} buttonVariant="title" shouldShowFullItemText />
                        </Flex>
                      </div>
                    ) : (
                      title
                    )
                  }
                  includeSubtitle={includeSummaryInfo}
                />
              )}
              {!loading && exportButton}
            </Flex>
          </Box>
        )}
        {!loading && subtitle && (
          <Text variant="body1" sx={{ marginBottom: theme.spacing.md }}>
            {subtitle}
          </Text>
        )}
        <Flex flexDirection="column" gap="lg">
          {loading ? (
            loadingComponent
          ) : (
            <Flex gap="lg" flexDirection="column">
              {primaryLegendProps && (
                <Flex justifyContent="space-between">
                  <Flex gap="lg">
                    {comparisonLegendProps && (
                      <LegendWithMetric {...comparisonLegendProps} color={secondaryColor || 'casper'} />
                    )}
                    <LegendWithMetric
                      {...primaryLegendProps}
                      color={primaryLegendProps.color || 'primary'}
                      dashed={primaryDashed}
                    />
                  </Flex>
                  {!showTitleSummary && exportButton}
                </Flex>
              )}
              {children}
            </Flex>
          )}
        </Flex>
      </Flex>
      <SlMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        selectedId={null}
        onChange={handleDropdownClick}
        onClose={handleCloseExports}
        options={dropdownOptions}
      />
    </div>
  );
};

export default BeaconChartWithLegend;
