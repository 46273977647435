import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { KeyMetricsPageLayout } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Keymetrics/KeyMetricsPageLayout';

export default function getNewBeaconKeyMetricsLayout() {
  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    widgets: [
      {
        name: 'keyMetricsPageLayout',
        CustomComponent: KeyMetricsPageLayout,
        view: {}
      }
    ],
    containerStyle: {},
    dataConfig: {}
  };
}
