import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Verification from './Verification';
import fontStyle from 'src/utils/fontStyle';

class ImagePicker extends React.Component {
  static defaultProps = {
    contentChanged: null
  };

  static propTypes = {
    images: PropTypes.array.isRequired,
    contentChanged: PropTypes.array
  };

  state = {};

  componentWillMount() {
    const { images, contentChanged } = this.props;

    if (!images || (contentChanged && contentChanged.length === 0)) {
      return;
    }

    this.setState({ selectedImage: images[0] });
  }

  isValid = (val) => val === '';

  handleImageClick = (val) => {
    this.setState({
      selectedImage: val
    });
  };

  showVerification(contentChangedVal) {
    const { contentChanged } = this.props;

    if (!contentChanged) {
      return null;
    }

    return (
      <div className="accuracy__image-validate">
        <Verification isValid={this.isValid(contentChangedVal)} type="image" />
      </div>
    );
  }

  showImage(contentChangedVal, index) {
    const { images, contentChanged } = this.props;
    const { selectedImage } = this.state;

    let value = null;
    let valueElement = null;
    if (contentChangedVal !== 'Missing required bullet') {
      value = images[index];
      valueElement = <img src={value} alt={value} />;
    }

    return (
      <div // eslint-disable-line
        key={`${value}${index}`}
        className="accuracy__image-container"
        onClick={() => this.handleImageClick(value)}
      >
        <div
          className={`accuracy__image-content ${
            !contentChanged || this.isValid(contentChangedVal) ? '' : 'accuracy__image-content--invalid'
          } ${value !== selectedImage ? '' : 'accuracy__image-content--selected'}`}
        >
          {this.showVerification(contentChangedVal)}
          <div className="accuracy__image">{valueElement} </div>
        </div>
      </div>
    );
  }

  render() {
    const { images, contentChanged } = this.props;
    const { selectedImage } = this.state;

    if (!images || images.length === 0) {
      return (
        <span style={{ fontWeight: fontStyle.regularWeight }}>
          This product has no{contentChanged === null ? ' approved ' : ' '}images.
        </span>
      );
    }

    return (
      <Fragment>
        <div className="accuracy__selected-image-container">
          <div className="accuracy__selected-image-content">
            <img className="accuracy__selected-image" src={selectedImage} alt={selectedImage} />
          </div>
        </div>
        <div className="accuracy__images-content">
          {(contentChanged || images).map((val, index) => this.showImage(val, index))}
        </div>
      </Fragment>
    );
  }
}

export default ImagePicker;
