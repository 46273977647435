import React, { useRef, useState } from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import AppProfileIcon, { AppProfileIconProps } from 'src/components/BeaconRedesignComponents/Header/AppProfileIcon';
import { StacklineTheme, useStacklineTheme, hexToRgba } from '@stackline/ui';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import ImageCropper from './ImageCropper';
import { useAppSelector, useProfileImageContext, useSnackbar } from 'src/utils/Hooks';

const StyledFab = styled(Fab)(
  ({ stacklineTheme, dimensions }: { stacklineTheme: StacklineTheme; dimensions: number }) => ({
    '&.MuiFab-root': {
      backgroundColor: stacklineTheme.colors.primary,
      width: `${dimensions}px`,
      minHeight: `${dimensions}px`,
      height: `${dimensions}px`,
      transition: 'none',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: `0 0 0 8px ${hexToRgba(stacklineTheme.colors.primaryGray, 0.5)}`
      }
    }
  })
);

interface ProfilePictureWithUploadProps {
  /**
   * Optional email if not using the one in Redux. This is useful for the signup
   * page when uploading an image but the user hasn't been created yet.
   */
  email?: string;
  /**
   * Optional props for the rendered AppProfileIcon component
   */
  appProfileIconProps?: Partial<AppProfileIconProps>;
  /**
   * Optional size of the FAB over the profile picture, in pixels
   */
  fabSize?: number;
  /**
   * Optional icon for the FAB on the profile picture
   */
  fabIcon?: React.ReactNode;
  /**
   * Callback for uploading the image that will be called after the
   * user crops the image.
   */
  uploadImage: (file: File, userEmail: string) => Promise<void>;
}

/**
 * Large display of profile photo.
 */
export default function ProfilePictureWithUpload({
  email,
  appProfileIconProps = {},
  fabSize = 28,
  fabIcon,
  uploadImage
}: ProfilePictureWithUploadProps) {
  const theme = useStacklineTheme();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null); // data URL encoded image so we can display it before uploading to S3
  const [cropperOpen, setCropperOpen] = useState(false);
  const userEmail = useAppSelector((state) => email || state.user.config.profile.email);
  const { showSnackbar } = useSnackbar();
  const { updateProfileImageTimestamp } = useProfileImageContext();

  const handleSetCropperOpen = (open: boolean) => {
    setCropperOpen(open);

    // Reset uploaded image to null after closing the modal
    if (!open) {
      setUploadedImage(null);
      setImagePreview(null);
    }
  };

  const handleFabClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      // Handle the file, e.g., upload it to the server
      setUploadedImage(file);
      setCropperOpen(true);

      // Resets the input value so that we can still upload the same image if the user selects it again
      event.target.value = '';
    }
  };

  // Save a data URL encoded image so we can display it before uploading to S3
  const saveImagePreview = (file: File) => {
    // Display a preview of the image
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        setImagePreview(e.target.result as string);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleImageCropped = async (file: File) => {
    try {
      await uploadImage(file, userEmail);
      saveImagePreview(file);
      updateProfileImageTimestamp();
    } catch (e) {
      showSnackbar({
        type: 'error',
        message: 'There was an error uploading your image, please try again.'
      });
    }
  };

  return (
    <>
      <Flex position="relative" justifyContent="center" maxWidth={appProfileIconProps.size || 120}>
        <StyledFab
          stacklineTheme={theme}
          size="small"
          dimensions={fabSize}
          sx={{
            position: 'absolute',
            top: '3px',
            right: '3px'
          }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          onClick={handleFabClick}
        >
          {fabIcon || <AddIcon sx={{ color: 'white' }} />}
        </StyledFab>
        <AppProfileIcon size={128} fontSize="64px" imageSrc={imagePreview} {...appProfileIconProps} />
      </Flex>
      <input accept="image/*" type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
      <ImageCropper
        cropperProps={{
          cropShape: 'round',
          aspect: 1
        }}
        open={cropperOpen}
        setOpen={handleSetCropperOpen}
        file={uploadedImage}
        onFileChange={handleImageCropped}
      />
    </>
  );
}
