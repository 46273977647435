import * as types from './types';
import { getTimelineOpt } from './selectors';
// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  platform: {},
  entity: {},
  entityArray: null,
  timeline: getTimelineOpt()[0],
  amount: null,
  adjustedCampaigns: [],
  mtdAdSpend: null
};

export function adBudgetEditor(state = initState, action) {
  switch (action.type) {
    case types.RESET_BUDGETEDITOR:
      return { ...initState };
    case types.SET_BUDGET_FLOW:
      return { ...state, flow: action.flow };
    case types.SET_BUDGET_PLATFORM:
      return { ...state, platform: action.platform };
    case types.SET_BUDGET_ENTITY:
      return { ...state, entity: action.entity };
    case types.SET_BUDGET_ENTITY_ARRAY:
      return { ...state, entityArray: action.entityArray };
    case types.SET_BUDGET_PORTFOLIO_ARRAY:
      return { ...state, portfolioArray: action.portfolioArray };
    case types.SET_BUDGET_CAMPAIGN_ARRAY:
      return { ...state, campaignArray: action.campaignArray };
    case types.SET_BUDGET_TIMELINE:
      return { ...state, timeline: action.timeline };
    case types.SET_BUDGET_AMOUNT:
      return { ...state, amount: action.amount };
    case types.SET_ADJUSTED_CAMPAIGNS:
      return { ...state, adjustedCampaigns: action.campaigns };
    case types.SET_MTD_AD_SPEND:
      return { ...state, mtdAdSpend: action.mtdAdSpend };
    case types.REPLACE_AD_BUDGET_EDITOR_STATE:
      return { ...action.newState };
    default:
      return state;
  }
}
