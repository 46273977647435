import React, { useState, useRef, useEffect } from 'react';
import { AppButton } from '@stackline/ui';
import './DisputeManagement.scss';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DescriptionIcon from '@mui/icons-material/Description';
import axios from 'axios';
import { Row } from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeDetailsTable';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { InputBase, MenuItem, Select } from '@mui/material';
import { UploadFileIcon, CloseIcon } from 'src/components/SvgIcons/SvgIcons';
import { useAppSelector } from 'src/utils/Hooks';

const UploadDialogue = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '670px',
    height: 'auto',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff'
  }
});

const CustomSelector = styled(InputBase)({
  width: '220px',
  height: '30px',
  padding: '6px 8px',
  borderRadius: '4px',
  border: 'solid 1px #dedede',
  backgroundColor: '#fff',
  alignSelf: 'flex-start',
  marginTop: '15px',
  '& .MuiList-root-MuiMenu-list': {
    width: '210px'
  },
  '.MuiSelect-select.MuiInputBase-input.MuiSelect-select': {
    height: '19px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#7e8fa8',
    margin: '0',
    alignItems: 'center'
  }
});

const CustomLoadingBar = styled(LinearProgress)({
  '.MuiLinearProgress-bar': {
    transition: 'none'
  },

  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#052849'
  }
});

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '28px',
    marginLeft: '60px',
    '& > :last-child': {
      flex: 'grow'
    }
  },
  mainModalText: {
    fontFamily: 'Roboto',
    fontSize: '28px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#052849',
    margin: '0'
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#dedede',
    margin: 0
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flexStart',
    height: 'auto',
    marginLeft: '60px',
    marginRight: '60px',
    marginTop: '35px'
  },
  secondaryModalText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#052849',
    alignSelf: 'flex-start'
  },

  fileTypeText: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#052849',
    alignSelf: 'flex-start',
    marginTop: '15px'
  },
  uploadContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '105px',
    borderRadius: '6px',
    border: 'solid 1px #dedede',
    marginTop: '8px'
  },
  customButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    height: 'auto',
    gap: '0.5em',
    background: 'transparent',
    border: 'none',
    '& label': {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#7e8fa8',
      '&:hover': {
        cursor: 'pointer'
      }
    },

    '&:hover': {
      cursor: 'pointer'
    }
  },
  progressContainer: {
    width: '100%',
    height: '85px',
    borderRadius: '6px',
    border: 'solid 1px #dedede',
    marginTop: '16px',
    padding: '15px 20px 15px 20px'
  },
  uploadHeader: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#052849',
    alignSelf: 'flex-start',
    marginTop: '32px'
  },
  documentIconBackdrop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    backgroundColor: '#052849',
    borderRadius: '50px',
    marginRight: '15px'
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <CustomLoadingBar
          sx={{ height: '6px', borderRadius: '5px', backgroundColor: '#06294a21' }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function LinearWithValueLabel(isUploading, uploadStatus) {
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    if (isUploading) {
      setProgress(0);
      setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1));
      }, 30);
    } else if (uploadStatus === 'success') {
      setProgress(100);
    } else if (uploadStatus === 'failed') {
      setProgress(0);
    }
  }, [isUploading, uploadStatus]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}

const UploadProgress = ({
  classes,
  selectedFile,
  isUploading,
  uploadStatus
}: {
  classes: any;
  selectedFile: File | null;
  isUploading: boolean;
  uploadStatus: string;
}) => {
  let uploadSectionText = isUploading ? 'Uploading' : 'Upload Progress';
  uploadSectionText = uploadStatus === 'success' ? 'Upload Complete' : uploadSectionText;

  const fileName = selectedFile ? selectedFile.name : 'your-file-here.pdf';

  const showStatus = () => {
    if (isUploading || uploadStatus === 'success') {
      return LinearWithValueLabel(isUploading, uploadStatus);
    } else if (uploadStatus === 'failed') {
      return <span style={{ fontSize: '14px', color: '#f35379' }}>Upload failed, please try again.</span>;
    } else {
      return null;
    }
  };

  const renderUploadStatus = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: 'auto',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <div className={classes.documentIconBackdrop}>
            <DescriptionIcon sx={{ color: 'white', fontSize: '15px' }} />
          </div>
          <span>{fileName}</span>
        </div>
        <div style={{ marginTop: '8px' }}>{showStatus()}</div>
      </>
    );
  };

  return (
    <>
      <span className={classes.uploadHeader}>{uploadSectionText}</span>
      <div
        className={classes.progressContainer}
        style={{
          borderColor: uploadStatus === 'failed' ? '#f35379' : uploadStatus === 'success' ? 'green' : '#dedede'
        }}
      >
        {isUploading || uploadStatus === 'success' || uploadStatus === 'failed' || selectedFile
          ? renderUploadStatus()
          : null}
      </div>
    </>
  );
};

enum DOC_TYPE {
  BillOfLading = 'BillOfLading',
  Invoice = 'Invoice',
  AmazonShippingNotification = 'AmazonShippingNotification',
  AmazonRoutingNotification = 'AmazonRoutingNotification',
  ContactUsCase = 'ContactUsCase',
  Other = 'Other'
}

const FileTypeSelector = ({
  fileTypeSelectionHandler
}: {
  fileTypeSelectionHandler: (documentType: string) => void;
}) => {
  const [selection, setSelection] = useState(DOC_TYPE.BillOfLading);

  const handleChange = (event: { target: { value: DOC_TYPE } }) => {
    setSelection(event.target.value);
    fileTypeSelectionHandler(event.target.value);
  };

  return (
    <Select value={selection} onChange={handleChange} input={<CustomSelector />}>
      <MenuItem value={DOC_TYPE.BillOfLading}>Bill of Lading</MenuItem>
      <MenuItem value={DOC_TYPE.Invoice}>Invoice</MenuItem>
      <MenuItem value={DOC_TYPE.AmazonShippingNotification}>Amazon Shipping Notification</MenuItem>
      <MenuItem value={DOC_TYPE.AmazonRoutingNotification}>Amazon Routing Notification</MenuItem>
      <MenuItem value={DOC_TYPE.ContactUsCase}>Contact Us Case</MenuItem>
      <MenuItem value={DOC_TYPE.Other}>Other</MenuItem>
    </Select>
  );
};

interface UploadFileModalProps {
  open: boolean;
  onClose: () => void;
  selectedDisputeForFileUpload: Row | { originalInvoice: string };
}

export const UploadFileModal: React.FC<UploadFileModalProps> = ({ open, onClose, selectedDisputeForFileUpload }) => {
  const [fileTypeSelection, setFileTypeSelection] = useState(DOC_TYPE.BillOfLading);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  const beaconClientId = useAppSelector((state) => state.entityService.mainEntity.beaconClientId);
  const user = useAppSelector((state) => state.user);
  const retailer = useAppSelector((state) => state.retailer);
  const { email } = user.session;
  const { id } = retailer;
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!open) {
      setSelectedFile(null);
      setUploadStatus(null);
      setFileTypeSelection(DOC_TYPE.BillOfLading);
    }
  }, [open]);

  useEffect(() => {
    setUploadStatus(null);
  }, [selectedFile, fileTypeSelection]);

  const { originalInvoice } = selectedDisputeForFileUpload;
  const cancelSource = useRef(axios.CancelToken.source());
  const classes = useStyles();

  const validateFormData = () => {
    if (!selectedDisputeForFileUpload || !fileTypeSelection || !selectedFile) {
      return false;
    }
    return true;
  };

  const fileTypeSelectionHandler = (documentType: DOC_TYPE) => {
    setFileTypeSelection(documentType);
  };

  const selectFileForUpload = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      'application/pdf',
      'image/png',
      'image/jpeg',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];
    if (file && allowedTypes.includes(file.type)) {
      setSelectedFile(file);
    }
  };

  const submitUpload = async (e) => {
    setUploadStatus(null);
    e.preventDefault();
    if (validateFormData()) {
      const formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);
      formData.append('Metadata[DocumentType]', fileTypeSelection);
      formData.append('Metadata[InvoiceNumber]', originalInvoice);
      formData.append('Metadata[RetailerId]', id);
      formData.append('Metadata[BeaconClientId]', beaconClientId);
      formData.append('ShouldOverwrite', 'true');
      setIsUploading(true);
      // api/shortagesdisputedocument/UploadSupportingDocument
      try {
        const response = await axios.post('api/shortagesdisputedocument/UploadSupportingDocument', formData, {
          cancelToken: cancelSource.current.token,
          headers: {
            'x-sl-ui-user-email': email,
            'sl-beacon-retailer-id': id || 1
          }
        });
        setTimeout(() => {
          setIsUploading(false);
          setUploadStatus('success');
        }, 4000);

        return response;
      } catch (error) {
        console.error(error);
        setTimeout(() => {
          setIsUploading(false);
          setUploadStatus('failed');
        }, 4000);
        return error;
      }
    } else {
      alert('Please select a file to upload.');
    }
    return null;
  };

  const handleButtonClick = (e) => {
    if (fileInputRef.current) {
      e.stopPropagation();
      fileInputRef.current.click();
    }
  };

  return (
    <UploadDialogue open={open} onClose={onClose}>
      <div>
        <div className={classes.headerContainer}>
          <h2 className={classes.mainModalText}>Upload Supporting Documents</h2>
          <CloseIcon style={{ width: '24px', cursor: 'pointer', marginLeft: 'auto' }} onClick={onClose} />
        </div>
        <div className={classes.divider}></div>
        <div className={classes.bodyContainer}>
          <span className={classes.secondaryModalText}>
            Upload relevant documents here. What type of file would you like to upload?
          </span>
          <FileTypeSelector fileTypeSelectionHandler={fileTypeSelectionHandler} />

          <span className={classes.fileTypeText}>PDF, PNG, or XLS only</span>
          <div className={classes.uploadContainer}>
            <button className={classes.customButton} onClick={handleButtonClick}>
              <label>
                <UploadFileIcon style={{ width: '40px' }} />
              </label>
              <input
                onClick={(e) => e.stopPropagation()}
                ref={fileInputRef}
                style={{ display: 'none' }}
                id="file-input"
                type="file"
                onChange={selectFileForUpload}
                accept=".pdf,.png,.jpeg,.xlsx"
              />
              <label>Click to Upload</label>
            </button>
          </div>
          <UploadProgress
            classes={classes}
            selectedFile={selectedFile}
            isUploading={isUploading}
            uploadStatus={uploadStatus}
          />
        </div>
        <div className={classes.divider} style={{ marginTop: '50px' }}></div>
        <div className={classes.footerContainer}>
          <AppButton
            disabled={!validateFormData() || isUploading || uploadStatus === 'success'}
            onClick={(e) => submitUpload(e)}
            sx={{
              width: '190px',
              height: '30px',
              borderRadius: '15.5px',
              border: 'solid 1px #052849',
              backgroundColor: '#052849',
              marginTop: '25px',
              marginBottom: '25px',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStretch: 'normal',
              fontStyle: 'normal',
              letterSpacing: 'normal',
              textAlign: 'left',
              color: '#fff',
              fontWeight: '400'
            }}
            invertStyle
          >
            Submit
          </AppButton>
        </div>
      </div>
    </UploadDialogue>
  );
};

export default UploadFileModal;
