import React from 'react';
import { useStacklineTheme } from '@stackline/ui';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { NoneFoundIcon, BrowserIcon } from 'src/components/SvgIcons';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

interface NoDataIconProps {
  icon?: React.ReactNode;
  dimensions?: number;
  iconBackgroundStyle?: React.CSSProperties;
}
export const NoDataIcon = ({ dimensions = 60, icon, iconBackgroundStyle }: NoDataIconProps) => {
  const theme = useStacklineTheme();
  return (
    <div
      style={{
        width: `${dimensions}px`,
        height: `${dimensions}px`,
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      }}
    >
      <div
        style={{
          width: `${dimensions}px`,
          height: `${dimensions}px`,
          backgroundColor: theme.colors.secondaryGhost,
          borderRadius: '50%',
          position: 'absolute',
          ...iconBackgroundStyle
        }}
      ></div>
      <div
        style={{
          width: '28px',
          opacity: 1,
          marginTop: '6px',
          position: 'relative'
        }}
      >
        {icon || <BrowserIcon />}
      </div>
    </div>
  );
};

/**
 * No dataPlaceHolder for cards, (segment, topics, etc)
 */
const NoDataPlaceHolder = ({
  title = 'No data available',
  description = 'There is no data available for the selected date range. Please try again.'
}: {
  title?: string;
  description?: string;
}) => {
  const theme = useStacklineTheme();

  return (
    <Flex flexDirection="column" gap="md" padding={theme.spacing.sm} alignItems="start">
      {/* Icon with opacity 0.2, but the actual icon should stay as same color  */}
      <NoDataIcon />
      <Flex flexDirection="column" gap="xs" alignItems="start">
        <Text variant="h6">{title}</Text>
        <Text
          variant="body2"
          sx={{
            maxWidth: '238px',
            textAlign: 'left'
          }}
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

/**
 * No data placeholder for table Row
 */
export const NoDataPlaceHolderTableRow = ({
  title = 'No data available',
  description = null,
  link = null,
  icon
}: {
  title?: string;
  description?: string | null;
  link?: React.ReactElement | null;
  icon?: React.ReactNode;
}) => {
  const theme = useStacklineTheme();
  return (
    <Flex flexDirection="column" padding={`${theme.spacing.sm} 0 ${theme.spacing.sm} ${theme.spacing.sm}`}>
      <NoDataIcon icon={icon || <NoneFoundIcon />} />
      <Flex marginTop={theme.spacing.md}>
        <Text variant="h6">{title}</Text>
      </Flex>
      {description && <Flex marginTop={theme.spacing.xs}>{description}</Flex>}
      {link && <Flex marginTop={theme.spacing.xs}>{link}</Flex>}
    </Flex>
  );
};

export default NoDataPlaceHolder;
