import types from './types';
import actions from './actions';
import { ValueOf } from 'sl-api-connector/types';
import { IAdPlatformSettingsByClient } from 'sl-ad-campaign-manager-data-model';

export const adPlatformSettingsByClient = (
  state = [] as IAdPlatformSettingsByClient[],
  action: ReturnType<ValueOf<typeof actions>>
) => {
  switch (action.type) {
    case types.RECEIVE_ADPLATFORM_SETTINGS_BY_CLIENT: {
      return action.adPlatformSettingsByClient;
    }
    case types.CLEAR_ADPLATFORM_SETTINGS_BY_CLIENT: {
      return [];
    }
    default: {
      return state;
    }
  }
};
