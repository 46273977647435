import axios from 'axios';
import _get from 'lodash/get';
import { OmniBaseRequestBody } from 'src/components/Omni/omniRequestUtils';
import { OmniReviewTileDataState } from 'src/components/Omni/OmniReviewTable/OmniReviewTable';

const OmniReviewTileEndPoint = 'omni/reviews/getStarsRatingStats' as const;

const defaultMetricData = {
  currencySymbol: '$',
  locale: 'en'
};

export const processTileDataToRender = (rawData: any[], groupBy: string): OmniReviewTileDataState[] => {
  const extraLevel = `${groupBy === 'gtin' ? 'aggsByChild.' : ''}`;
  return rawData.map((r) => {
    const fieldId = _get(r, 'key', '');
    const cardView = _get(r, 'entityMetadata', {});
    const reviewCount = _get(r, 'doc_count', 0);

    const totalStars = _get(r, `${extraLevel}stars_sum.value`, 0);
    const totalGiveStars = _get(r, `${extraLevel}stars_value_count.value`, 1);
    const mainMetric = `${reviewCount} review`;
    cardView.mainMetric = mainMetric;
    cardView.secondaryMetric = totalStars / totalGiveStars;
    cardView.metricData = defaultMetricData;
    cardView.secondaryDisplayTitle = _get(cardView, 'title', '');
    return { cardView, fieldId };
  });
};

export const reviewTileAsync = (requestBody: OmniBaseRequestBody, groupBy: string) => async () => {
  const res = await axios.post(OmniReviewTileEndPoint, requestBody);
  if (res && res.status === 200) {
    const { data } = res.data;

    const rawData = _get(data, `${groupBy}.buckets`, []);

    return processTileDataToRender(rawData, groupBy);
  } else {
    throw Error('Can not fetch the data');
  }
};
