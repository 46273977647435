import React, { useEffect, useRef } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Options } from 'highcharts/highmaps';
import { Chart } from 'highcharts';
import { DATATYPE, METRICTYPE } from 'src/utils/entityDefinitions/entityDefinitionTypes';
import _merge from 'lodash/merge';
import { usePrevious } from 'src/utils/Hooks';

import colors from 'src/utils/colors';
import _isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { tooltipFormatter } from 'src/utils/geoUtils';
import { GeoBackArrow, GeoBackArrowGray } from 'src/components/SvgIcons/SvgIcons';
import AppIconButton from 'src/components/Omni/OmniGeoMap/AppIconButton';
import makeStyles from '@mui/styles/makeStyles';
import './OmniGeoMap.scss';

interface OmniGeoMapProps {
  mapName: string;
  mapHistory: string[];
  chartOptions?: Options;
  chartSeries: { 'hc-key': string; value: number; preValue?: number }[];
  metricName: string;
  dataType: string;
  metricType: string;
  shouldDisableSlider: boolean;
  mapClick: (event: any) => void;
  onBackClickHandler: () => void;
  mapGeoJson?: any;
}

const useStyles = makeStyles({
  backButtonContainer: {
    position: 'absolute',
    bottom: '88px',
    right: '145px',
    zIndex: 100
  },
  backButton: {
    width: 32,
    height: 32,
    border: `1px solid #052849`,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: colors.lightGrey
    }
  },
  backButtonDisabled: {
    backgroundColor: `${colors.white} !important`
  },
  backArrow: {
    transform: 'scale(5)',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  tooltip: {
    fontFamily: 'Roboto',
    backgroundColor: colors.white,
    borderRadius: 8,
    boxShadow: `0 0 6px 0 rgba(0, 0, 0, 0.16)`,
    padding: '10px 14px',
    textAlign: 'left',
    '& div': {
      color: '#444555',
      letterSpacing: 0
    },
    '& .geo-region': {
      fontSize: 18,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      '& .region-dot': {
        height: 10,
        width: 10,
        borderRadius: 50,
        marginRight: 8
      }
    },
    '& .geo-metric': {
      fontSize: 14,
      marginTop: 10,
      display: 'grid',
      gridTemplateColumns: 'minmax(50px, min-content) auto 1fr',
      gap: 4,

      '& .metric-type': {
        fontSize: 8,
        fontWeight: 'bold',
        letterSpacing: 0.8,
        textTransform: 'uppercase'
      },

      '& .omni__geo--tooltip_comparison-arrow': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 2
      },

      '& .arrow--negative': {
        transform: 'rotate(180deg)',

        '& svg path': {
          fill: 'red !important'
        }
      },

      '& .arrow--zero': {
        transform: 'rotate(90deg)',

        '& svg path': {
          fill: '#233333 !important'
        }
      }
    }
  }
});

function renewMap(
  this: { chart: Chart; container: React.RefObject<HTMLDivElement> },
  chartSeries: { 'hc-key': string; value: number; preValue?: number }[]
) {
  this.chart.update({
    series: [
      {
        name: '',
        type: 'map',
        data: chartSeries,
        states: {
          hover: {
            borderColor: '#ADBAC6'
          }
        },
        borderColor: 'white',
        nullColor: '#ADBAC6'
      }
    ]
  });
}

const OmniGeoMap: React.FC<OmniGeoMapProps> = ({
  chartSeries,
  mapHistory,
  mapName,
  chartOptions = {},
  metricName,
  dataType,
  metricType,
  shouldDisableSlider,
  mapClick,
  onBackClickHandler,
  mapGeoJson
}) => {
  const chartRef = useRef(null);
  const classes = useStyles();
  const retailer = useSelector((store: ReduxStore) => store.retailer);
  const backDisabled = mapHistory.length === 1;
  const previousChartSeries = usePrevious(chartSeries);
  useEffect(() => {
    if (chartRef.current && !_isEqual(previousChartSeries, chartSeries)) {
      renewMap.call(chartRef.current, chartSeries);
    }
  }, [chartSeries, previousChartSeries]);

  const shouldEnableLegend = chartSeries.length > 0;
  let min = Number.MAX_VALUE;
  let max = Number.MIN_VALUE;

  /* eslint-disable */
  if (chartSeries.length > 0) {
    chartSeries.forEach((ele) => {
      if (min > ele.value) {
        min = ele.value;
      }
      if (max < ele.value) {
        max = ele.value;
      }
    });
  }
  if (max >= 1 && metricType === METRICTYPE.PERCENT) {
    max = 0.99;
  }

  const defaultOptions: Options = {
    chart: {
      map: mapGeoJson,
      height: 650,
      marginRight: 200,
      marginLeft: 50,
      animation: false
    },
    tooltip: {
      useHTML: true,
      hideDelay: 100,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderWidth: 0,
      padding: 0,
      shadow: false,
      formatter: function () {
        return tooltipFormatter(retailer, this, classes.tooltip, metricType, shouldDisableSlider);
      }
    },
    colorAxis: {
      max,
      min,
      className: 'omni-map-color-axis',
      labels: {
        formatter: function () {
          if (metricName === 'InStockRate') {
            if ((this.tick.axis.dataMax - this.tick.axis.dataMin) * 100 < 1.5) {
              return `${Number(this.value * 100).toFixed(1)}%`;
            }
            return `${Number(this.value * 100).toFixed(0)}%`;
          }
          return dataType === DATATYPE.DECIMAL
            ? metricType === METRICTYPE.MONEY
              ? `$${Number(this.value).toFixed(2)}`
              : `${Number(this.value).toFixed(2)}`
            : `${this.value}`;
        },
        style: {
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontWeight: '500',
          color: '#052849'
        }
      },
      reversed: false,
      type: 'linear',
      minColor: '#b6e3ef',
      gridLineWidth: 0.5,
      maxColor: '#043048',
      stops: [
        [0, '#b6e3ef'],
        [0.333, '#6cbaec'],
        [0.666, '#3383d3'],
        [1, '#043048']
      ]
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: shouldEnableLegend,
      layout: 'vertical',

      borderWidth: 0,
      align: 'right',
      verticalAlign: 'middle',
      x: -100,
      symbolHeight: 300,
      borderRadius: 24,
      itemStyle: {
        borderRadius: 5
      },
      itemHiddenStyle: {
        borderRadius: 5
      },
      symbolWidth: 8
    },
    plotOptions: {
      map: {
        borderWidth: 0.5,
        borderColor: colors.darkGrey,
        animation: false
      }
    },
    // mapNavigation is deleted - check https://gitlab.com/stackline-dev/website/-/blob/5f2fc8746e27f31d894e68df4ce6603453e39c70/src/components/Omni/OmniGeoMap/OmniGeoMapRenewal.tsx#L271

    series: [
      {
        name: '',
        type: 'map',
        data: chartSeries,
        states: {
          hover: {
            borderColor: '#ADBAC6'
          }
        },
        borderColor: 'white',
        nullColor: '#ADBAC6',
        point: {
          events: {
            click: mapClick,
            mouseOver: function () {
              if (this.value) {
                this.series.chart.container.style.cursor = 'pointer';
                if (mapName.match(/^countries\/us\/us-[a-z]{2}-all$/)) {
                  this.series.chart.container.style.cursor = 'default';
                } else if (mapName.startsWith('countries/us')) {
                  // us-all
                  this.series.chart.container.style.cursor = 'pointer';
                } else if (mapName.startsWith('countries/')) {
                  this.series.chart.container.style.cursor = 'default';
                }
              }
            },
            mouseOut: function () {
              this.series.chart.tooltip.hide();
              if (this.value) {
                this.series.chart.container.style.cursor = 'default';
              }
            }
          }
        }
      }
    ],
    title: {
      text: ''
    }
  };

  const mergedMapOptions = _merge(defaultOptions, chartOptions);

  return (
    // position relative is important for tooltip position
    <div style={{ marginTop: '50px', position: 'relative' }}>
      <HighchartsReact
        constructorType="mapChart"
        highcharts={Highcharts}
        options={mergedMapOptions}
        updateArgs={[true, true, true]}
        ref={chartRef}
      />
      {backDisabled ? null : (
        <div className={classes.backButtonContainer}>
          <AppIconButton
            title="Return"
            disabled={backDisabled}
            onClick={onBackClickHandler}
            classes={{
              root: classes.backButton,
              disabled: classes.backButtonDisabled
            }}
          >
            {backDisabled ? (
              <GeoBackArrowGray className={classes.backArrow} />
            ) : (
              <GeoBackArrow className={classes.backArrow} />
            )}
          </AppIconButton>
        </div>
      )}
    </div>
  );
};

export default OmniGeoMap;
