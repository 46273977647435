import { Entity } from 'sl-api-connector';

import ReduxStore from 'src/types/store/reduxStore';
import { PageLayout } from 'src/types/application/widgetTypes';
import { buildAdManagerHeaderWidget } from 'src/components/Layout/Advertising/AdManagerPageLayout/LayoutUtil';
import ProductsPage from 'src/components/AdManager/ProductsPage';

export const getLayout = ({ entity }: { app: ReduxStore['app']; entity: Entity }): Partial<PageLayout> => {
  const headerWidget = buildAdManagerHeaderWidget(entity, 'Ad Score');

  const addProductsToCampaignWidget = {
    CustomComponent: ProductsPage,
    name: 'addProductsToCampaign',
    view: {
      name: 'addProductsToCampaign'
    },
    data: {}
  };

  return {
    widgets: [headerWidget, addProductsToCampaignWidget]
  };
};
