import { ParentPlatforms, SingleParentPlatform } from 'src/store/modules/parentPlatform/platformUtils';
import types from './types';

export default {
  setParentPlatform: (parentPlatform: SingleParentPlatform) => ({
    type: types.SET_PARENT_PLATFORM,
    parentPlatform
  }),
  setAllParentPlatform: (availablePlatforms: ParentPlatforms) => ({
    type: types.SET_ALL_PARENT_PLATFORMS,
    availablePlatforms
  }),
  requestParentPlatforms: () => ({
    type: types.REQUEST_PARENT_PLATFORMS
  })
};
