import {
  Delivering,
  Archived,
  OutOfBudget,
  Paused,
  Invalid,
  Ended,
  Incomplete,
  PendingStart,
  Err,
  Ineligible,
  MissingDecoration,
  MissingImage,
  NotBuyable,
  MissingBuybox,
  AdPolicingSuspended
} from 'src/types/application/types';
import { Option } from 'funfix-core';
import axios from 'axios';
import _get from 'lodash/get';
import _startCase from 'lodash/startCase';
import _toLower from 'lodash/toLower';
import queryString from 'qs';
import { AdManagerAdCampaignEntity, AdManagerAdPortfolioEntity } from 'sl-api-connector/types';
import {
  IAdPlatformSettings,
  IAdCampaign,
  IAdPortfolioAdPlatformSettingsByClient,
  AD_STATUS_DERIVED
} from 'sl-ad-campaign-manager-data-model';

import { store } from 'src/main';
import { propEq } from 'src/utils/fp';

/**
 * This file is an attempt to compartmentalize the necessarily messy pattern matching
 * for the AMS API's responses.
 */

/**
 * What follows are attempts to enumerate all possible response pairs of status and status
 * reason from Amazon's AMS API.  This could change, and will
 * hopefully be moved to a microservice later.
 *
 * these are tuples of type ([status, statusReason], responseType)
 *
 * These are currently awaiting Sagar's input for the UI display values.
 */
const campaignStatusMatches = new Map([
  [['paused', 'portfolio_pending_start_date'], PendingStart],
  [['enabled', 'ended'], Ended],
  [['archived', 'advertiser_archived'], Archived],
  [['archived', 'portfolio_out_of_budget'], Archived],
  [['paused', 'account_out_of_budget'], OutOfBudget],
  [[null, 'portfolio_ended'], Ended],
  [[null, 'campaign_archived'], Archived],
  [['enabled', 'campaign_paused'], Paused],
  [['enabled', 'portfolio_ended'], Ended],
  [[null, 'campaign_out_of_budget'], OutOfBudget],
  [[null, 'advertiser_payment_failure'], OutOfBudget],
  [['archived', 'portfolio_ended'], Archived],
  [[null, 'pending_start_date'], PendingStart],
  [['paused', 'portfolio_ended'], Ended],
  [['enabled', 'portfolio_pending_start_date'], PendingStart],
  [['paused', 'campaign_paused'], Paused],
  [['ended', 'campaign_paused'], Ended],
  [['enabled', 'advertiser_payment_failure'], OutOfBudget],
  [['enabled', 'pending_start_date'], PendingStart],
  [['paused', 'advertiser_payment_failure'], OutOfBudget],
  [['archived', 'campaign_archived'], Archived],
  [['enabled', 'portfolio_out_of_budget'], OutOfBudget],
  [[null, 'advertiser_archived'], Archived],
  [['paused', 'portfolio_out_of_budget'], OutOfBudget],
  [['enabled', 'advertiser_archived'], Archived],
  [[null, 'ended'], Ended],
  [['archived', 'portfolio_pending_start_date'], Archived],
  [[null, 'campaign_paused'], Paused],
  [['enabled', 'campaign_out_of_budget'], OutOfBudget],
  [['paused', 'advertiser_archived'], Archived],
  [[null, 'portfolio_out_of_budget'], OutOfBudget],
  [['enabled', 'campaign_status_enabled'], Delivering],
  [[null, 'campaign_incomplete'], Incomplete],
  [['archived', 'advertiser_payment_failure'], Archived],
  [['enabled', 'campaign_incomplete'], Incomplete],
  [['enabled', 'account_out_of_budget'], OutOfBudget],
  [[null, 'campaign_status_enabled'], Delivering],
  [['enabled', 'ad_status_live'], Delivering],
  [['enabled', null], Delivering],
  [['enabled', 'portfolio_status_enabled'], Delivering],
  // sponsored brand campaigns below
  [['enabled', 'scheduled'], PendingStart],
  [['archived', 'terminated'], Archived],
  [['enabled', 'ended'], Ended],
  [['enabled', 'portfolioEnded'], Ended],
  [['paused', 'billingError'], OutOfBudget],
  [['paused', 'other'], Paused],
  [['enabled', 'outofbudget'], OutOfBudget],
  [['paused', 'paused'], Paused],
  [['enabled', 'portfolioOutOfBudget'], OutOfBudget],
  [['paused', 'portfolioOutOfBudget'], OutOfBudget],
  [['paused', 'pendingReview'], PendingStart],
  [['paused', 'pendingreview'], PendingStart],
  [['paused', 'ended'], Ended],
  [['enabled', 'running'], Delivering],
  [['paused', 'landingPageNotAvailable'], Paused],
  [['paused', 'landingpagenotavailable'], Paused],
  [['paused', null], Paused],
  [['enabled', 'ad_policing_suspended'], AdPolicingSuspended],
  [['enabled', 'ineligible'], Ineligible],
  [['enabled', 'missing_decoration'], MissingDecoration],
  [['enabled', 'missing_image'], MissingImage],
  [['enabled', 'not_buyable'], NotBuyable],
  [['enabled', 'not_in_buybox'], MissingBuybox],

  // Criteo
  [['inactive', 'inactive'], Paused],
  [['active', 'active'], Delivering]
  // [['enabled', 'active'], Delivering],
]);

const productStatusMatches = new Map([
  [['archived', 'ad_group_archived'], Archived],
  [['archived', 'ad_policing_pending_review'], Archived],
  [['paused', 'account_out_of_budget'], Paused],
  [['paused', 'pending_start_date'], PendingStart],
  [['enabled', 'ineligible'], Err],
  [['archived', 'portfolio_out_of_budget'], Archived],
  [['enabled', 'ad_group_incomplete'], Incomplete],
  [['enabled', 'campaign_paused'], Paused], // wat
  [['paused', 'ad_paused'], Paused],
  [['paused', 'portfolio_ended'], Ended],
  [['enabled', 'not_buyable'], Err],
  [['enabled', 'portfolio_pending_start_date'], PendingStart],
  [['archived', 'campaign_archived'], Archived],
  [['enabled', 'portfolio_out_of_budget'], OutOfBudget],
  [['enabled', 'ad_group_paused'], Paused],
  [['archived', 'ad_archived'], Archived],
  [['paused', 'ended'], Ended],
  [['paused', 'advertiser_payment_failure'], OutOfBudget],
  [['archived', 'portfolio_ended'], Archived],
  [['paused', 'ad_policing_pending_review'], PendingStart],
  [['archived', 'campaign_out_of_budget'], Archived],
  [['paused', 'ad_group_paused'], Paused],
  [['archived', 'ended'], Archived],
  [['enabled', 'ad_policing_suspended'], Err],
  [['paused', 'portfolio_pending_start_date'], PendingStart],
  [['enabled', 'portfolio_ended'], Ended],
  [['enabled', 'ended'], Ended], // wat
  [['enabled', 'not_in_buybox'], Err],
  [['archived', 'advertiser_archived'], Archived],
  [['paused', 'campaign_paused'], Paused],
  [['archived', 'advertiser_payment_failure'], OutOfBudget],
  [['paused', 'campaign_incomplete'], Incomplete],
  [['paused', 'campaign_archived'], Archived],
  [['enabled', 'missing_decoration'], Err],
  [['paused', 'portfolio_out_of_budget'], OutOfBudget],
  [['archived', 'ad_policing_suspended'], Archived],
  [['enabled', 'advertiser_archived'], Archived],
  [['enabled', 'campaign_incomplete'], Incomplete],
  [['enabled', 'ad_policing_pending_review'], PendingStart],
  [['enabled', 'account_out_of_budget'], OutOfBudget],
  [['enabled', 'campaign_out_of_budget'], OutOfBudget],
  [['paused', 'advertiser_archived'], Archived],
  [['paused', 'campaign_out_of_budget'], OutOfBudget],
  [['enabled', 'campaign_archived'], Archived],
  [['archived', 'portfolio_pending_start_date'], Archived],
  [['paused', 'paused'], Paused],
  [['enabled', 'ad_status_live'], Delivering],
  [['enabled', null], Delivering]
]);

const keywordStatusMatches = new Map([
  [['archived', 'ad_group_archived'], Archived],
  [['paused', 'pending_start_date'], PendingStart],
  [['paused', 'targeting_clause_paused'], Paused],
  [['archived', 'portfolio_out_of_budget'], Archived],
  [['paused', 'portfolio_pending_start_date'], PendingStart],
  [['enabled', 'ended'], Ended],
  [['archived', 'advertiser_archived'], Archived],
  [['enabled', 'campaign_paused'], Paused],
  [['paused', 'campaign_paused'], Paused],
  [['enabled', 'advertiser_payment_failure'], OutOfBudget],
  [['archived', 'targeting_clause_archived'], Archived],
  [['enabled', 'pending_start_date'], PendingStart],
  [['archived', 'campaign_out_of_budget'], Archived],
  [['enabled', 'portfolio_ended'], Ended],
  [['archived', 'campaign_paused'], Archived],
  [['paused', 'campaign_archived'], Archived],
  [['archived', 'campaign_archived'], Archived],
  [['enabled', 'portfolio_out_of_budget'], OutOfBudget],
  [['paused', 'ended'], Ended],
  [['paused', 'advertiser_payment_failure'], OutOfBudget],
  [['enabled', 'ad_group_paused'], Paused],
  [['enabled', 'campaign_out_of_budget'], OutOfBudget],
  [['archived', 'portfolio_ended'], Archived],
  [['paused', 'campaign_out_of_budget'], OutOfBudget],
  [['enabled', 'campaign_archived'], Archived],
  [['archived', 'portfolio_pending_start_date'], Archived],
  [['paused', 'ad_group_paused'], Paused],
  [['archived', 'ended'], Archived],
  [['paused', 'portfolio_out_of_budget'], OutOfBudget],
  [['enabled', 'advertiser_archvied'], Archived],
  [['enabled', 'targeting_clause_status_live'], Delivering],
  [['archived', 'advertiser_payment_failure'], Archived],
  [['enabled', 'campaign_incomplete'], Incomplete],
  [['paused', 'paused'], Paused],
  [['enabled', 'ad_status_live'], Delivering],
  [['enabled', null], Delivering],
  [['paused', null], Paused]
]);

/**
 * Uses string rendering to index a map with tuples for key values. Without this, the
 * index is done via reference, which won't work here. This essentially lets us use
 * tuples as key values in a hash map.
 */
const tupleMapLookup = (
  status: string,
  statusReason: string,
  map: Map<(string | null)[], { value: AD_STATUS_DERIVED; displayName: string; color: string }>,
  debugInfo: {} = { status, statusReason }
) => {
  let result = _get(
    [...map].filter(([x, _y]) => x.toString().toLowerCase() === [status, statusReason].toString().toLowerCase()),
    '[0][1]'
  );

  if (!result) {
    if (__DEV__ || __TEST__ || __STAGING__) {
      console.warn('Unaccounted status or status reason: ', debugInfo);
    }

    if (__PROD__) {
      // ADSPRT-1647 - Commenting out the following, since it is causing UI performance issue when loading 1000s of campaigns which have statuses not present in our list
      // track('Status and status reason unaccounted for: ', debugInfo || { status, statusReason });
    }
    result = { ...Delivering, displayName: status === 'enabled' ? 'Delivering' : _startCase(_toLower(status)) };
  }
  return result || Delivering;
};

/**
 * Takes in the extended attributes of an ad campaign and returns a tuple of its status display name and display color.
 * @param extendedAttributes the extended attributes of an ad campaign
 *
 * @return - {displayName, color} an object containing the display name and color.
 *
 */
export const getCampaignDisplayStatus = (
  extendedAttributes: AdManagerAdCampaignEntity['extendedAttributes']
): { value: AD_STATUS_DERIVED; displayName: string; color: string } => {
  // Sometimes we are missing adCampaign data for campaigns. ¯\_(ツ)_/¯
  if (!extendedAttributes) {
    return Invalid;
  }
  const { status, statusReason } = extendedAttributes;
  const res = tupleMapLookup(status, statusReason, campaignStatusMatches);
  return res;
};

/**
 * This function takes in a product's status and status reason and maps it to its UI display
 * name and color.
 * @param status the status from AMS after being .toLower'd by the ad campaign service
 * @param statusReason the status reason from AMS after being .toLower'd by the ad campaign service
 */
export const getProductDisplayStatus = (
  status: string,
  statusReason: string
): { value: AD_STATUS_DERIVED; displayName: string; color: string } => {
  return tupleMapLookup(status, statusReason, productStatusMatches, { status, statusReason, type: 'product' });
};

/**
 * This function takes in a keyword's status and status reason and maps it to its UI display
 * name and color.
 * @param status the status from AMS after being .toLower'd by the ad campaign service
 * @param statusReason the status reason from AMS after being .toLower'd by the ad campaign servic
 */
export const getKeywordDisplayStatus = (
  status: string,
  statusReason: string
): { value: AD_STATUS_DERIVED; displayName: string; color: string } => {
  return tupleMapLookup(status, statusReason, keywordStatusMatches, { status, statusReason, type: 'keyword' });
};

export const buildEntityLink = (entityType: string, entityId: string | number, subtab: string = 'keyMetrics') => {
  const queryParams = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: 100
  });

  return `/${entityType}/${entityId}?${queryString.stringify({ ...queryParams, tab: 'adManager', subtab })}`;
};

/**
 * Takes in the extended attributes of an ad campaign and returns it's parent portfolio's name and link.
 * @param extendedAttributes the extended attributes of an ad campaign
 *
 * @return - { displayName, shortDisplayName, url } an object containing the display names and link.
 *
 */
export const getParentPortfolioDisplayInfo = (
  extendedAttributes: AdManagerAdCampaignEntity['extendedAttributes']
): { displayName: string; shortDisplayName: string; url: string } => {
  const { adPortfolios } = store.getState();
  const parentPortfolio = adPortfolios.find(propEq('id', _get(extendedAttributes, ['portfolioId'])));

  const displayName = _get(parentPortfolio, ['name'], 'Unassigned');

  const shortDisplayName = `${displayName.slice(0, 30)}${displayName.length >= 30 ? '...' : ''}`;
  const url = Option.of(parentPortfolio)
    .map((portfolio) => buildEntityLink('adPortfolio', portfolio.id))
    .getOrElse('');
  return { displayName, shortDisplayName, url, parentPortfolio };
};

export const getParentCampaignDisplayInfo = (
  extendedAttributes: AdManagerAdCampaignEntity['extendedAttributes']
): { displayName: string; shortDisplayName: string; url: string } => {
  const { adCampaigns } = store.getState();
  const parentCampaign = adCampaigns.find(propEq('id', _get(extendedAttributes, ['campaignId'])));

  const displayName = _get(parentCampaign, ['name'], 'Unassigned');

  const shortDisplayName = `${displayName.slice(0, 30)}${displayName.length >= 30 ? '...' : ''}`;
  const url = Option.of(parentCampaign)
    .map((campaign) => buildEntityLink('adCampaign', campaign.id))
    .getOrElse('');
  return { displayName, shortDisplayName, url, parentCampaign };
};

/**
 * Takes in the extended attributes of an ad campaign or portfolio and returns it's parent entity's name and link.
 * @param extendedAttributes the extended attributes of an ad campaign or portfolio
 *
 * @return - { displayName, shortDisplayName, url } an object containing the display names and link.
 *
 */
export const getParentEntityDisplayInfo = (
  extendedAttributes: AdManagerAdCampaignEntity['extendedAttributes'] | AdManagerAdPortfolioEntity['extendedAttributes']
): { displayName: string; shortDisplayName: string; url: string } => {
  const { adEntities } = store.getState();
  const parentEntity = adEntities.find(propEq('id', _get(extendedAttributes, ['entityId'])));

  const displayName = _get(parentEntity, ['name'], 'Unassigned');
  const shortDisplayName = `${displayName.slice(0, 30)}${displayName.length >= 30 ? '...' : ''}`;
  const url = Option.of(parentEntity)
    .map((entity) => buildEntityLink('adEntity', entity.id))
    .getOrElse('');
  return { displayName, shortDisplayName, url };
};

/**
 * Takes in the extended attributes of an ad campaign or portfolio and returns it's strategy name.
 * @param extendedAttributes the extended attributes of an ad campaign or portfolio
 *
 * @return - { displayName } an object containing the display names
 *
 */
export const getStrategyDisplayInfo = (
  extendedAttributes: AdManagerAdCampaignEntity['extendedAttributes'] | AdManagerAdPortfolioEntity['extendedAttributes']
): { displayName: string; strategy: any } => {
  const { adPlatformSettings }: { adPlatformSettings: IAdPlatformSettings[] } = store.getState();
  const strategy = adPlatformSettings.find(
    (item) =>
      item.settingType === 'campaignStrategy' &&
      item.settingId === _get(extendedAttributes, ['automationAttributes', 'strategyId'], 'Manual')
  );
  const displayName = _get(strategy, ['name'], '...');
  return { displayName, strategy };
};

export const getPlatformDisplayInfo = (platformType: string): { displayName: string } => {
  const { adPlatforms }: { adPlatforms: any[] } = store.getState();
  const platform = adPlatforms.find((item) => item.platformType === platformType);
  const displayName = _get(platform, ['name'], '...');
  return { displayName };
};

export const updateCampaign = async (entity: IAdCampaign) => {
  const UPDATE_STATUS_API = '/apiAdManager/adUpdateRequests/updateCampaign';
  await axios.post(UPDATE_STATUS_API, entity);
};

export const updatePortfolio = async (entity: IAdPortfolioAdPlatformSettingsByClient) => {
  const UPDATE_STATUS_API = '/apiAdManager/adUpdateRequests/updatePortfolio';
  await axios.post(UPDATE_STATUS_API, entity);
};

export const getActiveCampaignNumber = (entity: any) => {
  const { adCampaigns } = store.getState();
  const activeCampaigns = adCampaigns.filter((item) => {
    return ['enabled', 'delivering', 'outOfBudget'].includes(item.extendedAttributes.statusDerived);
  });
  if (entity.type === 'client') {
    return activeCampaigns.length;
  } else if (['entity', 'adEntity'].includes(entity.type)) {
    const matchCampaigns = activeCampaigns.filter((item) => item.extendedAttributes.entityId === entity.id);
    return matchCampaigns.length;
  } else if (entity.type === 'adPortfolio') {
    const matchCampaigns = activeCampaigns.filter((item) => item.extendedAttributes.portfolioId === entity.id);
    return matchCampaigns.length;
  }

  return 0;
};

export const getActiveEntityNumber = (entity: any) => {
  const { adEntities } = store.getState();

  if (entity.type === 'client') {
    return adEntities.filter(
      (x) =>
        !x.extendedAttributes ||
        !x.extendedAttributes.amsApiModel ||
        !x.extendedAttributes.amsApiModel.accountInfo ||
        !x.extendedAttributes.amsApiModel.accountInfo.type ||
        ['vendor', 'seller'].includes(x.extendedAttributes.amsApiModel.accountInfo.type)
    ).length;
  }

  return 0;
};

export const getActivePortfolioNumber = (entity: any) => {
  const { adPortfolios, adCampaigns } = store.getState();
  const activePortfolios = adPortfolios.filter((item) => {
    return ['delivering'].includes(item.extendedAttributes.statusDerived);
  });
  if (['product', 'adTarget'].includes(entity.type)) {
    const activeCampaigns = adCampaigns.filter((item) => entity.campaignIds.includes(item.campaignId));

    const matchPortfolioIds: string[] = [];
    activeCampaigns.forEach((item) => {
      if (matchPortfolioIds.indexOf(item.extendedAttributes.portfolioId) < 0) {
        matchPortfolioIds.push(item.extendedAttributes.portfolioId);
      }
    });
    return matchPortfolioIds.length;
  } else if (entity.type === 'client') {
    return activePortfolios.length;
  } else if (['entity', 'adEntity'].includes(entity.type)) {
    const matchPortfolios = activePortfolios.filter((item) => item.extendedAttributes.entityId === entity.id);
    return matchPortfolios.length;
  }

  return 0;
};

export const getActiveAdGroupsNumber = () => {
  const { adGroups, retailer } = store.getState();
  const retailerId = retailer.id;

  const totalCount = _get(adGroups, '[0].aggregations.by_retailerId', 0);
  let number = 0;
  if (totalCount) {
    if (retailerId === '0') {
      // all retailers
      totalCount.forEach((item) => {
        number += _get(item, 'value', 0);
      });
    } else {
      // single retailer
      totalCount.forEach((item) => {
        if (item.fieldId === retailerId) {
          number += _get(item, 'value', 0);
        }
      });
    }
  }
  return number;
};
