import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import queryString from 'qs';
import { FilterContainer } from '../Filters';
import LeftNavTree from './LeftNavTree';
import {
  ClientSidebarCard,
  CategorySidebarCard,
  CompanySidebarCard,
  SubCategorySidebarCard,
  BrandSidebarCard,
  ProductSidebarCard,
  SegmentSidebarCard,
  AdCampaignSidebarCard,
  AggregatedRetailersSidebarCard,
  RetailerSideCard,
  OmniSegmentSideBarCard,
  OmniGeoSidebarCard
} from './Cards';

const SideBarCards = {
  '/brand': BrandSidebarCard,
  '/product': ProductSidebarCard,
  '/segment': SegmentSidebarCard,
  '/businessunit': SegmentSidebarCard,
  '/category': CategorySidebarCard,
  '/subcategory': SubCategorySidebarCard,
  '/client': ClientSidebarCard,
  '/searchtermlist': SegmentSidebarCard,
  '/company': CompanySidebarCard,
  '/adCampaign': AdCampaignSidebarCard,
  '/retailer': RetailerSideCard,
  '/': AggregatedRetailersSidebarCard
};

const styles = {
  aside: {
    display: 'flex',
    padding: '0 20px 0 0px'
  }
};

export const LeftNav = ({ location: { search, pathname }, mainEntity, name }) => {
  const parsedPathname = pathname.substring(0, pathname.lastIndexOf('/'));
  const { entityType } = queryString.parse(search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  let SideBarCard = SideBarCards[parsedPathname || '/'];
  if (name === 'atlas' && entityType === 'retailer') {
    SideBarCard = SideBarCards['/category'];
  }
  if (name === 'omni' && ['/segment', '/keywordSegment', '/store'].includes(parsedPathname)) {
    SideBarCard = OmniSegmentSideBarCard;
  }
  if (['/state', '/city', '/cbsa', '/county', '/country', '/region'].includes(parsedPathname)) {
    SideBarCard = OmniGeoSidebarCard;
  }
  const entity = mainEntity;
  return (
    <aside style={styles.aside}>
      <nav style={{ width: 240 }}>
        <SideBarCard entity={entity} />
        <LeftNavTree entity={entity} />
        <FilterContainer entity={entity} />
      </nav>
    </aside>
  );
};

LeftNav.propTypes = {
  location: PropTypes.object.isRequired,
  mainEntity: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  mainEntity: state.entityService.mainEntity,
  name: state.app.name
});

const EnhancedLeftNav = withRouter(connect(mapStateToProps)(LeftNav));

export default EnhancedLeftNav;
