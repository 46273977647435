import { AdvancedSearchQuery, AdvancedSearchQueryAggregation, RangeFilter, TermFilter } from 'sl-api-connector/search';
import { useAppSelector } from 'src/utils/Hooks';
import _cloneDeep from 'lodash/cloneDeep';
import { useCallback } from 'react';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';

/**
 * Builds an advanced search request body.
 */
export default class AdvancedSearchRequestBuilder {
  private request: AdvancedSearchQuery;

  public constructor(id: string, searchType: string, initialRequest?: AdvancedSearchQuery) {
    if (initialRequest) {
      this.request = initialRequest;
      this.request.id = id;
      this.request.name = id;
      this.request.searchType = searchType;
      return;
    }

    this.request = {
      id,
      name: id,
      searchType,
      conditions: {
        termFilters: [],
        rangeFilters: [],
        condition: 'must',
        computeFilters: [],
        nestedFilterConditions: []
      },
      aggregations: [],
      sortFilter: {
        sortFields: []
      }
    };
  }

  public setProcessDocuments(processDocuments: boolean): AdvancedSearchRequestBuilder {
    this.request.processDocuments = processDocuments;
    return this;
  }

  public clone(): AdvancedSearchRequestBuilder {
    return new AdvancedSearchRequestBuilder(this.request.id, this.request.searchType, _cloneDeep(this.request));
  }

  public setId(id: string): AdvancedSearchRequestBuilder {
    this.request.id = id;
    this.request.name = id;
    return this;
  }

  public setSearchBy(searchBy: string): AdvancedSearchRequestBuilder {
    this.request.searchBy = searchBy;
    return this;
  }

  public setSearchType(searchType: string): AdvancedSearchRequestBuilder {
    this.request.searchType = searchType;
    return this;
  }

  public clearSortFields(): AdvancedSearchRequestBuilder {
    this.request.sortFilter.sortFields = [];
    return this;
  }

  public setDoAggregation(doAggregation: boolean): AdvancedSearchRequestBuilder {
    this.request.doAggregation = doAggregation;
    return this;
  }

  public setReturnDocuments(returnDocuments: boolean): AdvancedSearchRequestBuilder {
    this.request.returnDocuments = returnDocuments;
    return this;
  }

  public setPeriod(period?: string): AdvancedSearchRequestBuilder {
    this.request.period = period;
    return this;
  }

  public setRetailerId(retailerId: string): AdvancedSearchRequestBuilder {
    this.request.retailerId = retailerId;
    return this;
  }

  public setPageNumber(pageNumber: number): AdvancedSearchRequestBuilder {
    this.request.pageNumber = pageNumber;
    return this;
  }

  public setPageSize(pageSize: number): AdvancedSearchRequestBuilder {
    this.request.pageSize = pageSize;
    return this;
  }

  public addSortField(
    aggregateByFieldDisplayName: string,
    aggregateByFieldName: string,
    func: string,
    canBeExported: boolean
  ): AdvancedSearchRequestBuilder {
    this.request.sortFilter.sortFields.push({
      aggregateByFieldDisplayName,
      aggregateByFieldName,
      function: func,
      canBeExported
    });
    return this;
  }

  public replaceSortField(
    aggregateByFieldDisplayName: string,
    aggregateByFieldName: string,
    func: string,
    canBeExported: boolean
  ): AdvancedSearchRequestBuilder {
    this.request.sortFilter.sortFields = this.request.sortFilter.sortFields.filter(
      (row) => row.aggregateByFieldName !== aggregateByFieldName
    );

    this.addSortField(aggregateByFieldDisplayName, aggregateByFieldName, func, canBeExported);

    return this;
  }

  public addSortFieldWithOnlyFieldName(fieldName: string, direction: string) {
    this.request.sortFilter.sortFields.push({ fieldName, direction });
    return this;
  }

  public setSortDirectionAsNull() {
    this.request.sortDirection = null;
    return this;
  }

  public addConditionTermFilter(fieldName: string, condition: string, values: any[]): AdvancedSearchRequestBuilder {
    this.request.conditions.termFilters.push({
      fieldName,
      condition,
      values
    });
    return this;
  }

  /**
   * Removes conditionTerm Filter, if predicateFunc returns true. if not, it will just return original.
   */

  public removeConditionTermFilters(predicateFunc: (termFilter: TermFilter) => boolean): AdvancedSearchRequestBuilder {
    this.request.conditions.termFilters = this.request.conditions.termFilters.filter(
      (termFilter) => !predicateFunc(termFilter)
    );
    return this;
  }

  /**
   * Replaces an existing term filter with a new value if it exists. Otherwise, it adds the term filter.
   */
  public replaceConditionTermFilter(fieldName: string, condition: string, values: any[]): AdvancedSearchRequestBuilder {
    const updatedTermFilters = this.request.conditions.termFilters.filter(
      ({ fieldName: existingFieldName }: string) => existingFieldName !== fieldName
    );

    updatedTermFilters.push({
      fieldName,
      condition,
      values
    });

    this.request.conditions.termFilters = updatedTermFilters;
    return this;
  }

  /**
   * Replaces an existing range filter with a new min and max value if it exists. Otherwise, it adds the range filter.
   */
  public replaceConditionRangeFilter(
    fieldName: string,
    minValue: string | number,
    maxValue: string | number
  ): AdvancedSearchRequestBuilder {
    this.request.conditions.rangeFilters = this.request.conditions.rangeFilters.filter((rangeFilter) => {
      const { fieldName: filterFieldName } = rangeFilter;
      return filterFieldName !== fieldName;
    });

    this.addConditionRangeFilter(fieldName, minValue, maxValue);

    return this;
  }

  public addConditionRangeFilter(
    fieldName: string,
    minValue?: string | number,
    maxValue?: string | number
  ): AdvancedSearchRequestBuilder {
    const rangeFilter: { [key: string]: string | number } = {
      fieldName,
      ...(minValue !== undefined && { minValue }), // Add minValue only if defined
      ...(maxValue !== undefined && { maxValue }) // Add maxValue only if defined
    };

    this.request.conditions.rangeFilters.push(rangeFilter);
    return this;
  }

  /**
   * Removes all condition range filters that return true for the callback function
   */
  public removeConditionRangeFilters(func: (rangeFilter: RangeFilter) => boolean): AdvancedSearchRequestBuilder {
    this.request.conditions.rangeFilters = this.request.conditions.rangeFilters.filter(
      (rangeFilter) => !func(rangeFilter)
    );
    return this;
  }

  public clearConditionTermFilters(): AdvancedSearchRequestBuilder {
    this.request.conditions.termFilters = [];
    return this;
  }

  public clearConditionRangeFilters(): AdvancedSearchRequestBuilder {
    this.request.conditions.rangeFilters = [];
    return this;
  }

  public addAggregation(aggregation: AdvancedSearchQueryAggregation): AdvancedSearchRequestBuilder {
    this.request.aggregations.push(aggregation);
    return this;
  }

  public setAggregation(aggregation: AdvancedSearchQueryAggregation): AdvancedSearchRequestBuilder {
    this.request.aggregations = [aggregation];
    return this;
  }

  public clearAggregations(): AdvancedSearchRequestBuilder {
    this.request.aggregations = [];
    return this;
  }

  // Review Export needs to have aggregation as null
  public makeNullAggregations(): AdvancedSearchRequestBuilder {
    this.request.aggregations = null;
    return this;
  }

  public setReturnAdditionalMetadata(returnMetadata: boolean): AdvancedSearchRequestBuilder {
    if (!this.request.additionalRequestMetaData) {
      this.request.additionalRequestMetaData = {};
    }
    this.request.additionalRequestMetaData.returnAdditionalMetaData = returnMetadata;
    return this;
  }

  public setAdditionalRequestMetaDataDerivedFieldsJson(derivedFieldsJson: string): AdvancedSearchRequestBuilder {
    if (!this.request.additionalRequestMetaData) {
      this.request.additionalRequestMetaData = {};
    }
    this.request.additionalRequestMetaData.derivedFieldsJson = derivedFieldsJson;
    return this;
  }

  public setCustomAdditionalMetadata(obj: { [key: string]: any }): AdvancedSearchRequestBuilder {
    if (!this.request.additionalRequestMetaData) {
      this.request.additionalRequestMetaData = {};
    }

    this.request.additionalRequestMetaData = obj;
    return this;
  }

  /**
   * Replaces the field name of a term filter with a new field name
   * while keeping the same values
   */
  public replaceConditionTermFilterFieldName(oldFieldName: string, newFieldName: string): AdvancedSearchRequestBuilder {
    this.request.conditions.termFilters = this.request.conditions.termFilters.map((termFilter) => {
      if (termFilter.fieldName === oldFieldName) {
        termFilter.fieldName = newFieldName;
      }
      return termFilter;
    });
    return this;
  }

  public modifyAggregation(
    aggBuilder: (aggregation: AggregationBuilder, index: number) => AggregationBuilder
  ): AdvancedSearchRequestBuilder {
    this.request.aggregations = this.request.aggregations.map((aggregation, index) => {
      return aggBuilder(new AggregationBuilder(aggregation.groupByFieldName, aggregation), index).build();
    });
    return this;
  }

  /**
   * Applies a function to the request builder. It allows for a more functional approach to building the request,
   * as opposed to calling a function that directly modifies the builder
   */
  public apply(
    builder: (requestBuilder: AdvancedSearchRequestBuilder) => AdvancedSearchRequestBuilder
  ): AdvancedSearchRequestBuilder {
    return builder(this);
  }

  public build(): AdvancedSearchQuery {
    return this.request;
  }
}

export const useCreateAdvancedSearchRequestBuilder = () => {
  const retailerId = useAppSelector((state) => state.retailer.id);

  return useCallback(
    (id: string, searchType: string) => {
      const builder = new AdvancedSearchRequestBuilder(id, searchType);
      builder.setPageNumber(1).setRetailerId(retailerId).addConditionTermFilter('retailerId', 'should', [retailerId]);

      return builder;
    },
    [retailerId]
  );
};

/**
 * Returns an advanced search request builder with the retailer filled
 * in for the condition term filter by default.
 */
export const useAdvancedSearchRequestBuilder = (id: string, searchType: string) => {
  const createAdvancedSearchRequestBuilder = useCreateAdvancedSearchRequestBuilder();
  return createAdvancedSearchRequestBuilder(id, searchType);
};
