import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import numeral from 'numeral';
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import moment from 'moment-timezone';
import { Conditions, mergeConditions } from 'sl-api-connector/search/conditions';
import { AdManagerAdCampaignEntity, AdManagerAdPortfolioEntity, Entity } from 'sl-api-connector/types';
import AdManagerEntityHeader, { correctEntityTypeForURL } from 'src/components/AdManager/AdManagerEntityHeader';
import { fetchAdOptimizationItemCountForEntity } from 'src/components/AdManager/AdOptimizationHistory';
import { buildEntityLink } from 'src/components/AdManager/AmsUtils';
import Badge from 'src/components/AdManager/Badge';
import { buildAggregations } from 'src/components/AdManager/Search';
import { buildMainEntityConditions } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import Widget from 'src/components/EntityPage/Widget';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { MetricField } from 'src/types/application/types';
import { Widget as WidgetType } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import { useAppSelector } from 'src/utils/Hooks';
import colors from 'src/utils/colors';
import { buildFullMonthRangeFilters } from 'src/utils/dateformatting';

const ChartsSection = ({
  cloneSummaryMiniMetrics,
  childWidgetEntityConditions,
  ...props
}: {
  cloneSummaryMiniMetrics: WidgetType[];
  childWidgetEntityConditions: Conditions;
}) => {
  return (
    <div className="charts_section">
      {cloneSummaryMiniMetrics.map((singleTrend) => (
        <Widget
          key={singleTrend.name}
          {...props}
          // noInnerContainer
          widget={singleTrend}
          entityConditions={childWidgetEntityConditions}
        />
      ))}
    </div>
  );
};

const GridSection = ({
  cloneTopThreeGridWidget,
  childWidgetEntityConditions,
  ...props
}: {
  cloneTopThreeGridWidget: WidgetType;
  childWidgetEntityConditions: Conditions;
}) => {
  return (
    <div className="grid_section">
      <Widget
        // noInnerContainer
        {...props}
        widget={cloneTopThreeGridWidget}
        entityConditions={childWidgetEntityConditions}
      />
    </div>
  );
};

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics
};

const Footer = ({
  currentEntity,
  fetchEntityMetrics,
  pacingConfig,
  childWidgetEntityConditions
}: {
  currentEntity: Entity & AdManagerAdCampaignEntity & AdManagerAdPortfolioEntity;
  childWidgetEntityConditions: Conditions;
  pacingConfig: MetricField[];
  fetchEntityMetrics: Function;
}) => {
  const [adOptimizationItemCount, setAdOptimizationItemCount] = useState(null);
  const dataKey = `pacing-${currentEntity.type}-${currentEntity.id}`;

  // Redux
  const currentEntityService = useAppSelector((state) => state.entitySearchService[dataKey]);
  const app = useAppSelector((state) => state.app);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const retailer = useAppSelector((state) => state.retailer);

  let useAggregationSort = true;

  // Setting useAggregationSort as false to reduce ES latency
  if (currentEntity.type === 'adCampaign') {
    useAggregationSort = false;
  }

  useEffect(() => {
    // fetch pacing
    const currentMonthRangeFilters = buildFullMonthRangeFilters();
    const [{ aggregations: aggregationFields }] = buildAggregations(pacingConfig.pacingFields);
    fetchEntityMetrics(
      dataKey,
      {
        entity: currentEntity,
        retailer,
        app,
        indexName: pacingConfig.indexName
      },
      [
        {
          doAggregation: true,
          aggregations: [
            {
              aggregationFields,
              conditions: {
                termFilters: [{ fieldName: 'retailerId', values: [+retailer.id] }],
                rangeFilters: currentMonthRangeFilters
              },
              groupByFieldName: 'retailerId'
            }
          ],
          conditions: {
            termFilters: childWidgetEntityConditions.termFilters,
            rangeFilters: currentMonthRangeFilters
          },
          pageSize: 1,
          processDocuments: true,
          useAggregationSort
        }
      ]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailer, currentEntity]);

  useEffect(() => {
    fetchAdOptimizationItemCountForEntity(currentEntity, mainTimePeriod).then(setAdOptimizationItemCount);
  }, [currentEntity, mainTimePeriod]);

  const optimizationsLink = buildEntityLink(
    correctEntityTypeForURL(currentEntity.type),
    currentEntity.id,
    'optimizations'
  );

  const getPacingData = () => {
    const adSpend = _get(currentEntityService, ['spend_by_retailerId', 'data', 0, 'value'], null);
    const budgetAmount = _get(currentEntityService, ['budgetAmount_by_retailerId', 'data', 0, 'value'], null);
    const budgetPacing = _get(currentEntityService, ['budgetPacing_by_retailerId', 'data', 0, 'value'], null);
    return { adSpend, budgetAmount, budgetPacing };
  };

  const renderPacing = () => {
    if (!currentEntityService) {
      return '';
    }
    const getPacingNumber = (number: number | null) => {
      if (!number && number !== 0) {
        return '--';
      }
      const color = number > 1.2 || number < 0.8 ? colors.red : colors.green;
      return (
        <strong style={{ color }}>
          <span>&nbsp;&nbsp;</span>
          {`${numeral(number).format('%0')}`}
          <span>&nbsp;&nbsp;</span>
        </strong>
      );
    };

    const getBudgetNumber = (number: number | null) => {
      if (number === null) {
        return '--';
      }
      return numeral(number).format('$1,000.00');
    };

    const { adSpend } = getPacingData();
    switch (currentEntity.type) {
      case 'adCampaign': {
        const adSpendDisplay = numeral(adSpend || 0).format('$1,000.00');
        return (
          <>
            Campaign is on pace to spend{' '}
            <strong style={{ color: colors.darkBlue }}>&nbsp;{adSpendDisplay}&nbsp;</strong> for this month.
          </>
        );
      }
      case 'adPortfolio': {
        // const portfolioBudgetEndDate = moment(
        //   _get(currentEntity, ['extendedAttributes', 'liveBudgetSetting', 'endDate'], '')
        // ).format('MMMM D, YYYY');
        const portfolioBudgetAmount = +_get(currentEntity, ['extendedAttributes', 'liveBudgetSetting', 'amount'], 0);
        const pacingValue = portfolioBudgetAmount === 0 ? '--' : getPacingNumber(adSpend / portfolioBudgetAmount);
        return (
          <>
            Portfolio is on pace to spend {pacingValue} of its {getBudgetNumber(portfolioBudgetAmount)} monthly budget.
          </>
        );
      }
      case 'entity': {
        const entityBudgetAmount = +_get(currentEntity, ['extendedAttributes', 'currentBudgetSetting', 'amount'], 0);
        if (entityBudgetAmount) {
          return (
            <>
              Entity is on pace to spend {getPacingNumber(adSpend / entityBudgetAmount)} of its{' '}
              {getBudgetNumber(entityBudgetAmount)} monthly budget.
            </>
          );
        } else {
          const adSpendDisplay = numeral(adSpend || 0).format('$1,000.00');
          return (
            <>
              Entity is on pace to spend{' '}
              <strong style={{ color: colors.darkBlue }}>&nbsp;{adSpendDisplay}&nbsp;</strong> for this month.
            </>
          );
        }
      }
      default:
        return '';
    }
  };

  return (
    <div className="footer_section">
      <div className="pacing">{renderPacing()}</div>
      <div className="badges">
        <Link to={optimizationsLink}>
          <Badge
            text=""
            color={colors.blue}
            backgroundColor="#2a8ade40"
            onClick={() => {}}
            amount={adOptimizationItemCount}
          />
        </Link>
      </div>
    </div>
  );
};

const FooterSection = connect(null, mapDispatchToProps)(Footer);

const AdManagerTile: React.FC<{
  currentEntity: Entity & AdManagerAdCampaignEntity & AdManagerAdPortfolioEntity;
  conditions: Conditions;
  widget: WidgetType;
  app: ReduxStore['app'];
  retailer: ReduxStore['retailer'];
  refreshEntity?: Function;
}> = ({ currentEntity, widget, app, retailer, conditions, ...props }) => {
  const { summaryTrendwidgets: summaryTrendWidgets, topThreeGridWidget, pacingConfig } = widget.data;
  const childWidgetEntityConditions = mergeConditions(
    conditions,
    buildMainEntityConditions({ termFilters: [], rangeFilters: [] }, currentEntity, app, retailer)
  );
  childWidgetEntityConditions.termFilters = childWidgetEntityConditions.termFilters.filter(
    (x) => x.fieldName !== 'categoryId' && x.fieldName !== 'brandId'
  );

  // add a currentEntity.id to the widget name to make it unique
  const cloneSummaryMiniMetrics: WidgetType[] = _cloneDeep(summaryTrendWidgets);
  cloneSummaryMiniMetrics.forEach((item) => {
    item.name += `__${currentEntity.id}`;
    item.data.queryEntity = {
      ...currentEntity
    };
  });
  const cloneTopThreeGridWidget = _cloneDeep(topThreeGridWidget);
  cloneTopThreeGridWidget.name += `__${currentEntity.id}`;

  return (
    <div className="adManager_tile_container">
      {/* this section contain score, header */}
      <div className="section_warpper">
        <AdManagerEntityHeader
          customStyle={{ padding: '35px 0', height: 185 }}
          currentEntity={currentEntity}
          widget={widget}
        />
      </div>

      {/* this section contain four mini trend chart */}
      <div className="section_warpper">
        <ChartsSection
          cloneSummaryMiniMetrics={cloneSummaryMiniMetrics}
          childWidgetEntityConditions={childWidgetEntityConditions}
          {...props}
        />
      </div>

      {/* this section contain top 3 product/keyword grid */}
      <div className="section_warpper">
        <GridSection
          cloneTopThreeGridWidget={cloneTopThreeGridWidget}
          childWidgetEntityConditions={childWidgetEntityConditions}
          {...props}
        />
      </div>

      {/* this section contain one sentence */}
      <div className="section_warpper">
        <FooterSection
          currentEntity={currentEntity}
          pacingConfig={pacingConfig}
          childWidgetEntityConditions={childWidgetEntityConditions}
        />
      </div>
    </div>
  );
};

export default AdManagerTile;
