import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withBus } from 'react-bus';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import axios from 'axios';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { withRouter } from 'react-router';
import TextField from '@mui/material/TextField';
import { track } from 'src/utils/mixpanel';
import { shouldShowReclassify } from 'src/utils/app';
import { UNCLASSIFIED_SUBCATEGORY_ID } from 'src/utils/constants';
import colors from 'src/utils/colors';
import fontStyle from 'src/utils/fontStyle';
import './Reclassify.scss';
import DeleteConfirmationDialog from '../common/Dialog/DeleteConfirmationDialog';

const styles = {
  dialogTitle: {
    color: colors.darkBlue,
    fontWeight: fontStyle.regularWeight
  }
};
const OPTIONS_LIMIT = 20;

class Reclassify extends Component {
  static defaultProps = {
    className: '',
    reclassifyRequest: [],
    products: [],
    isVertical: true,
    predicatedSubCategoryName: '',
    predicatedSubCategoryId: -1
  };

  static propTypes = {
    app: PropTypes.object.isRequired,
    eventBus: PropTypes.object.isRequired,
    allSuperUserSubCategories: PropTypes.array.isRequired,
    subCategories: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    className: PropTypes.string,
    reclassifyRequest: PropTypes.array,
    products: PropTypes.array,
    isVertical: PropTypes.bool,
    predicatedSubCategoryName: PropTypes.string,
    predicatedSubCategoryId: PropTypes.number,
    location: PropTypes.object.isRequired
  };

  defaultFilterOptions = createFilterOptions();

  componentWillMount() {
    const { allSuperUserSubCategories, subCategories, predicatedSubCategoryId, predicatedSubCategoryName } = this.props;
    const categoriesDatasource = (allSuperUserSubCategories.length > 0 ? allSuperUserSubCategories : subCategories).map(
      (subCategory) => ({
        subCategoryId: subCategory.subCategoryId,
        subCategoryName: subCategory.subCategoryName
      })
    );
    const categoriesDatasourceConfig = {
      text: 'subCategoryName',
      value: 'subCategoryId',
      hintText: 'subcategory name to reclassify'
    };
    this.setState({
      isDialogOpen: false,
      reclassifiedCategoryId:
        predicatedSubCategoryId && predicatedSubCategoryId !== UNCLASSIFIED_SUBCATEGORY_ID
          ? predicatedSubCategoryId
          : null,
      reclassifiedCategoryName:
        predicatedSubCategoryId && predicatedSubCategoryId !== UNCLASSIFIED_SUBCATEGORY_ID
          ? predicatedSubCategoryName
          : null,
      categoriesDatasource,
      categoriesDatasourceConfig,
      reclassifyText: this.buildButtonText()
    });
  }

  componentWillReceiveProps(nextProps) {
    const { products } = nextProps;
    if (products.length === this.props.products.length) {
      return;
    }

    this.setState({ reclassifyText: this.buildButtonText(nextProps) });
  }

  setNewCategoryName = (event) => {
    this.setState({ reclassifiedCategoryName: event.target.value });
  };

  buildButtonText(props = this.props) {
    const { products } = props;
    return products && products.length > 0 ? 'Reclassify' : 'Reclassify All';
  }

  stopPropagation = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  filterOptions = (options, state) => {
    return this.defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  handleToggleDialogClick = () => this.setState({ isDialogOpen: !this.state.isDialogOpen });

  handleReclassifyProductsClick = (evt) => {
    const { products } = this.props;
    this.stopPropagation(evt);
    if (products.length === 0) {
      return;
    }
    if (products.length > 3) {
      this.handleToggleDialogClick();
      return;
    }

    this.reclassifyProducts();
  };

  handleDialogReclassifyProductsClick = (evt) => {
    this.stopPropagation(evt);
    this.handleToggleDialogClick();
    this.reclassifyProducts();
  };

  reclassifyProducts() {
    const { eventBus } = this.props;
    const { reclassifyText } = this.state;

    if (reclassifyText !== this.buildButtonText()) {
      return;
    }

    this.setState({
      reclassifyText: 'Reclassifying...'
    });

    this.reclassify().then(
      () => {
        this.setState({ reclassifyText: 'Reclassified!' });
        setTimeout(() => {
          this.setState({ reclassifyText: this.buildButtonText() });
          eventBus.emit('reclassify');
        }, 2000);
      },
      () => {
        this.setState({ reclassifyText: 'Error!!!' });
        setTimeout(() => {
          this.setState({ reclassifyText: this.buildButtonText() });
          eventBus.emit('reclassify');
        }, 2000);
      }
    );
  }

  reclassify() {
    const { products, reclassifyRequest, retailer, app } = this.props;
    const { reclassifiedCategoryId, reclassifiedCategoryName, categoriesDatasourceConfig } = this.state;

    track('reclassify', {
      numberOfProducts: products.length,
      numberOfRequests: reclassifyRequest.length,
      reclassifiedCategoryId,
      reclassifiedCategoryName
    });

    if (reclassifyRequest.length > 0) {
      let clonedReclassifyRequest = _cloneDeep(reclassifyRequest);
      const termFilters = clonedReclassifyRequest[0].conditions.termFilters.filter(
        (val) => val.fieldName !== 'stacklineSku'
      );
      if (products.length > 0) {
        termFilters.push({
          fieldName: 'stacklineSku',
          condition: 'must',
          values: products.map((val) => val)
        });
      }
      clonedReclassifyRequest = [
        {
          ...clonedReclassifyRequest[0],
          searchType: `${app.name}-reclassify`,
          additionalRequestMetaData: {
            [categoriesDatasourceConfig.value]: reclassifiedCategoryId,
            classificationReason: 'manually_reclassified'
          },
          conditions: {
            ...clonedReclassifyRequest[0].conditions,
            termFilters
          }
        }
      ];
      return axios.post(`/api/${app.name}/AdvancedSearch?_id=reclassify`, clonedReclassifyRequest);
    }
    return axios.post(
      `/api/${app.name}/Reclassify?productId=${products[0]}&retailerId=${retailer.id}&classificationReason=manually_reclassified&${categoriesDatasourceConfig.value}=${reclassifiedCategoryId}`
    );
  }

  handleChangeReclassifyCategory = (chosenRequest, index) => {
    const { categoriesDatasource, categoriesDatasourceConfig } = this.state;
    if (index >= 0) {
      this.setState({
        reclassifiedCategoryId: categoriesDatasource[index][categoriesDatasourceConfig.value],
        reclassifiedCategoryName: categoriesDatasource[index][categoriesDatasourceConfig.text]
      });
    }
  };

  handleChangeReclassifyCategory = (_, value) => {
    this.setState({
      reclassifiedCategoryId: value.subCategoryId,
      reclassifiedCategoryName: value.subCategoryName
    });
  };

  showReclassifyButton(isVertical, reclassifiedCategoryId) {
    if (!reclassifiedCategoryId || reclassifiedCategoryId < 0) {
      return null;
    }

    const verticalClass = isVertical ? 'reclassify__button--vertical' : '';
    const buttonClassName = `reclassify__button ${verticalClass} sl-delete-button sl-delete-button--sm`;

    return (
      <Button className={buttonClassName} onClick={this.handleReclassifyProductsClick}>
        {this.state.reclassifyText}
      </Button>
    );
  }

  render() {
    const { app, user, className, isVertical, products, location } = this.props;
    if (!shouldShowReclassify(app, user, location)) {
      return null;
    }

    const { categoriesDatasource, isDialogOpen, reclassifyText, reclassifiedCategoryId, reclassifiedCategoryName } =
      this.state;

    return (
      <div className={`reclassify ${className}`}>
        <Autocomplete
          // autoFocus={autoFocus}
          openOnFocus={false}
          className={className}
          onChange={this.handleChangeReclassifyCategory}
          fullWidth
          filterOptions={this.filterOptions}
          options={categoriesDatasource}
          getOptionLabel={(option) => option.subCategoryName}
          clearIcon={<></>}
          popupIcon={<></>}
          inputValue={reclassifiedCategoryName || ''}
          clearOnEscape
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              InputProps={{
                ...params.InputProps,
                className
              }}
              type="text"
              onChange={this.setNewCategoryName}
              placeholder="search subcategory to reclassify"
              id={className}
            />
          )}
        />

        {this.showReclassifyButton(isVertical, reclassifiedCategoryId)}
        <DeleteConfirmationDialog
          open={isDialogOpen}
          onClose={this.handleToggleDialogClick}
          onDelete={this.handleDialogReclassifyProductsClick}
          deleteButtonText={reclassifyText}
        >
          <h3 style={styles.dialogTitle}>Reclassify Products</h3>
          <p style={{ color: colors.darkBlue }}>
            Are you sure you want to reclassify {products.length} {products.length > 1 ? 'products' : 'product'} ? This
            cannot be undone.
          </p>
        </DeleteConfirmationDialog>
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, ['app', 'allSuperUserSubCategories', 'user', 'retailer', 'subCategories']);

export default withRouter(connect(mapStateToProps)(withBus('eventBus')(Reclassify)));
