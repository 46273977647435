import { useStacklineTheme } from '@stackline/ui';
import React, { useMemo } from 'react';
import DonutChartV2 from 'src/components/BeaconRedesignComponents/DonutChart/DonutChartV2';
import { useReviewTrendsDonutChartData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Reviews/ReviewTrends/ReviewTrendsDonutChartData';
import DonutChartLoading from 'src/components/Charts/Donut/DonutChartLoading';

interface DonutDataType {
  fieldId: string;
  value: number;
  count: number;
}

const donutValueSelector = (data: DonutDataType[], fieldId: string) => {
  const selectedData = data.find((datum) => datum.fieldId === fieldId);
  if (!selectedData) {
    return 0;
  }
  return selectedData.value;
};

/**
 * Displays the donut chart for review page
 */
const ReviewTrendsDonutChart = ({ onSelectDonut }: { onSelectDonut: Highcharts.PointSelectCallbackFunction }) => {
  const { donutData, isLoading }: { donutData: DonutDataType[]; isLoading: boolean } = useReviewTrendsDonutChartData();
  const theme = useStacklineTheme();

  const formattedAverage = useMemo(() => {
    if (donutData) {
      const { sumValues, sumCounts } = donutData.reduce(
        (accumulator, currentValue) => {
          accumulator.sumValues += currentValue.value;
          accumulator.sumCounts += currentValue.count;
          return accumulator;
        },
        { sumValues: 0, sumCounts: 0 }
      );
      // For the new product sumCounts might be 0
      if (!sumCounts) {
        return 0;
      }
      return `${(sumValues / sumCounts).toFixed(1)}`;
    } else {
      return 0;
    }
  }, [donutData]);

  return isLoading ? (
    <DonutChartLoading />
  ) : (
    <DonutChartV2
      onSelect={onSelectDonut}
      getData={[
        {
          fieldId: '5',
          name: '5 Stars',
          y: donutValueSelector(donutData, '5'),
          color: theme.colors.primaryLight
        },
        {
          fieldId: '4',
          name: '4 Stars',
          y: donutValueSelector(donutData, '4'),
          color: theme.colors.primary
        },
        {
          fieldId: '3',
          name: '3 Stars',
          y: donutValueSelector(donutData, '3'),
          color: theme.colors.success
        },
        {
          fieldId: '2',
          name: '2 Stars',
          y: donutValueSelector(donutData, '2'),
          color: theme.colors.accentTeal
        },
        {
          fieldId: '1',
          name: '1 Stars',
          y: donutValueSelector(donutData, '1'),
          color: theme.colors.accentPine
        }
      ]}
      title={String(formattedAverage)}
      sortByGetDataOrder
      tooltipWrapperStyle={{ width: '91px' }}
    />
  );
};

export default ReviewTrendsDonutChart;
