/* eslint-disable react/require-default-props */
import { Widget } from 'src/types/application/widgetTypes';
import SearchResultsGridV3, { SearchResultsGridV3Props } from './SearchResultsGridV3';
import React from 'react';
import useSrgData from './hooks/useSrgData';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import { Location } from 'history';
import EntityColumn from 'src/components/AdManager/Search/CustomColumns/EntityColumn';
import useFetchProductsTableSalesData from './hooks/useFetchProductsTableSalesData';
import _get from 'lodash/get';
import { useIsScheduledActionsPage } from 'src/utils/Hooks';
import useScheduledActionTermFilters from 'src/components/AdManager/Search/hooks/useScheduledActionTermFilters';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { TermFilter } from 'sl-api-connector';
import useSelectScheduledActionRows from 'src/components/AdManager/Search/hooks/useSelectScheduledActionRows';
import { isEditScheduledActionPage } from 'src/components/AdManager/Search/searchUtils';
import { shouldFetchAllSRGRows, shouldShowCriteo } from 'src/utils/app';
import { getParentPlatform } from 'src/utils/browser';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';

interface ProductsGridProps {
  widget: Widget;
  location: Location;
}

/**
 * Ineligible products are fetched all at once. The total result count will
 * not be returned from any of the network calls because the list is aggregated
 * from multiple calls, so we take the length of the aggregated data.
 */
const computeTotalResultCountForIneligibleProducts = ({ result }) => _get(result, ['data', 'length'], 0);

const ProductsGridOuter: React.FC<ProductsGridProps> = (props) => {
  const isScheduledActionsPage = useIsScheduledActionsPage(props.location.search);

  return isScheduledActionsPage ? <ScheduledActionsProductsGrid {...props} /> : <ProductsGrid {...props} />;
};

/**
 * Products grid for scheduled action page.
 */
const ScheduledActionsProductsGrid: React.FC<ProductsGridProps> = (props) => {
  const { loading, scheduledActionTermFilters, defaultSelectAll } = useScheduledActionTermFilters({
    location: props.location
  });

  const selectScheduledActionRows = useSelectScheduledActionRows({
    selectAll: defaultSelectAll,
    statePropertyName: props.widget.data.statePropertyName
  });

  if (loading) {
    return <GridLoading />;
  }

  return (
    <ProductsGrid
      {...props}
      togglable={false}
      allowSelectAll={!isEditScheduledActionPage(props.location.search)}
      additionalTermFilters={scheduledActionTermFilters}
      onFetchedData={selectScheduledActionRows}
    />
  );
};

/**
 * Display a list of products.
 */
const ProductsGrid: React.FC<
  ProductsGridProps &
    Partial<SearchResultsGridV3Props<any>> & {
      additionalTermFilters?: TermFilter[];
      onFetchedData?: ({ gridResult }) => void | Promise<void>;
    }
> = ({ widget, location, additionalTermFilters, onFetchedData, queryParams, ...otherProps }) => {
  const parentPlatform = getParentPlatform();
  const isCriteo = shouldShowCriteo() && parentPlatform === PARENT_PLATFORMS.CRITEO;

  let importantOverrides = [];

  // for all retailers remove rid
  if (queryParams.rid === '0') {
    importantOverrides = [
      ...importantOverrides,
      {
        action: 'remove',
        path: ['aggregations', '[0]', 'conditions', 'termFilters'],
        conditionKey: 'fieldName',
        conditionValue: 'retailerId'
      },
      {
        action: 'remove',
        path: ['conditions', 'termFilters'],
        conditionKey: 'fieldName',
        conditionValue: 'retailerId'
      },
      {
        action: 'update',
        path: ['retailerId'],
        newObj: 999999
      }
    ];
  }

  const fetchProductsData = useFetchProductsTableSalesData(importantOverrides);

  const { canEdit, ...rest } = useSrgData({
    widget,
    location,
    fetchEntityData: fetchProductsData,
    computeTotalResultCount: location.search.includes('ineligible')
      ? computeTotalResultCountForIneligibleProducts
      : undefined,
    additionalTermFilters,
    onFetchedData,
    metadataRowOptions: shouldFetchAllSRGRows(widget.data.entityType, widget.data.groupByFieldName)
      ? {
          getEntityId: (row) => row.stacklineSku.toLowerCase(),
          name: 'title',
          type: 'product'
        }
      : undefined,
    importantOverrides
  });

  return (
    <SearchResultsGridV3
      editDisabled={isCriteo}
      selectable={canEdit && !isCriteo}
      togglable={!window.location.pathname.includes('adTarget') && canEdit && !isCriteo}
      columnDefs={[
        {
          headerName: 'Product',
          field: 'stacklineSku',
          width: undefined,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          minWidth: 300,
          maxWidth: 800,
          cellRendererFramework: EntityColumn,
          headerClass: 'align-left',
          pinnedRowCellRenderer: 'simpleColumn',
          pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
          pinned: 'left'
        }
      ]}
      getRowNodeId={(row) => row.id}
      widget={widget}
      {...rest}
      {...otherProps}
    />
  );
};

export default withRouter(withBus('eventBus')(ProductsGridOuter));
