import React from 'react';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import { useCreateRemoveRetailPriceRangeFilters } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';
import { useAppSelector } from 'src/utils/Hooks';

export default function OrganicTrafficSplineChart() {
  // TODO: Use agg-traffic index when available for topics
  const mainEntityType = useAppSelector((state) => state.entityService.mainEntity.type);
  const removeRetailPriceRangeFilters = useCreateRemoveRetailPriceRangeFilters();

  return (
    <BeaconComparableSplineChart
      indexName={mainEntityType === 'searchtermlist' ? 'traffic' : 'aggregated-traffic'}
      fieldName="organicClicks"
      requestBuilder={removeRetailPriceRangeFilters()}
    />
  );
}
