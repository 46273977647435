import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import queryString from 'qs';
import saveAs from 'file-saver';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import _get from 'lodash/get';
import _identity from 'lodash/identity';
import _pick from 'lodash/pick';

import colors from 'src/utils/colors';
import { addPersistentQueryParams } from 'src/utils/browser';
import { withPush, withProps } from 'src/utils/hoc';
import { getDataKeyForTimePeriod } from 'src/utils/dateformatting';
import { quoteWrap } from 'src/utils/stringFormatting';

/**
 * Given the `entitySearchService` entry for the featured product grid's data in Redux, serializes it to CSV and
 * downloads it to the user's computer as a file.
 *
 * @param {object} featuredProductsData The `entitySearchService` entry for the given
 * @param {object} mainTimePeriod `mainTimePeriod` from Redux
 * @param {object} comparisonTimePeriod `comparisonTimePeriod` from Redux
 */
const downloadFeaturedProductData = (featuredProductsData, mainTimePeriod, comparisonTimePeriod) => {
  if (!featuredProductsData) {
    return;
  }

  const columnTitles = [
    'Product Name',
    'Retailer SKU',
    `Organic Clicks (${comparisonTimePeriod.displayName})`,
    `Organic Clicks (${mainTimePeriod.displayName})`
  ];
  const headerRow = columnTitles.map(quoteWrap).join(',');
  const {
    organicClicks_by_stacklineSku: { data: organicClicksData }
  } = featuredProductsData;
  const mainTimePeriodDataKey = 'value';
  const comparisonTimePeriodDataKey = getDataKeyForTimePeriod(comparisonTimePeriod);

  const csvData = organicClicksData
    .filter(
      (datum) =>
        !!(datum[mainTimePeriodDataKey] || datum[comparisonTimePeriodDataKey]) && !!_get(datum, ['entity', 'title'])
    )
    .reduce((acc, { name, entity: { retailerSku }, ...datum }) => {
      const values = [
        quoteWrap(name),
        quoteWrap(retailerSku),
        Math.round(datum[comparisonTimePeriodDataKey]),
        Math.round(datum[mainTimePeriodDataKey])
      ];

      const row = values.join(',');
      return `${acc}\n${row}`;
    }, headerRow);

  const blob = new Blob([csvData], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, 'featured-products-data.csv');
};

/**
 *
 * @param {array} productIds An array of retailer SKUs for the featured products
 */
const visitAdvancedSearchPageForProducts = (productIds, retailer, mainTimePeriod, push) => {
  const queryParams = queryString.stringify({ k: productIds.map((i) => ({ i })) });

  const url = `/search?${addPersistentQueryParams(retailer, mainTimePeriod)}&${queryParams}`;
  push(url);
};

const styles = {
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
    justifyContent: 'center'
  }
};

const dialogButtonsStyles = {
  greyRoot: {
    backgroundColor: colors.lightestGrey,
    '&:hover': {
      backgroundColor: colors.lightestGrey
    }
  },
  blackLabel: {
    color: colors.darkBlue
  },
  blackRoot: {
    backgroundColor: colors.darkBlue,
    '&:hover': {
      backgroundColor: colors.darkBlue
    }
  }
};

const FeaturedProductsDialogButtonsInner = ({
  featuredProductsData,
  classes,
  mainTimePeriod,
  comparisonTimePeriod,
  retailer,
  push
}) => (
  <div style={styles.buttonsWrapper}>
    <Button
      onClick={useCallback(
        () => downloadFeaturedProductData(featuredProductsData, mainTimePeriod, comparisonTimePeriod),
        [featuredProductsData, mainTimePeriod, comparisonTimePeriod]
      )}
      style={{ marginRight: 20 }}
      classes={{ root: classes.greyRoot, label: classes.blackLabel }}
    >
      Download
    </Button>

    <Button
      onClick={useCallback(() => {
        const productIds = _get(featuredProductsData, 'organicClicks_by_stacklineSku.data', [])
          .map((datum) => _get(datum, 'entity.retailerSku'))
          .filter(_identity);

        return visitAdvancedSearchPageForProducts(productIds, retailer, mainTimePeriod, push);
      }, [featuredProductsData, mainTimePeriod, push, retailer])}
      classes={{ root: classes.blackRoot }}
    >
      View Sales
    </Button>
  </div>
);

FeaturedProductsDialogButtonsInner.propTypes = {
  featuredProductsData: PropTypes.object,
  // From `withStyles`
  classes: PropTypes.object.isRequired,
  // Redux props
  mainTimePeriod: PropTypes.object.isRequired,
  comparisonTimePeriod: PropTypes.object.isRequired,
  retailer: PropTypes.object.isRequired,
  // From `withPush`
  push: PropTypes.func.isRequired
};

FeaturedProductsDialogButtonsInner.defaultProps = {
  featuredProductsData: null
};

const mapFeaturedProductsDialogButtonsStateToProps = (state, { featuredProductsDataKey }) => ({
  ..._pick(state, ['mainTimePeriod', 'comparisonTimePeriod', 'retailer']),
  featuredProductsData: state.entitySearchService[featuredProductsDataKey]
});

const FeaturedProductsDialogButtons = compose(
  connect(mapFeaturedProductsDialogButtonsStateToProps),
  withStyles(dialogButtonsStyles),
  withPush
)(FeaturedProductsDialogButtonsInner);

const mkFeaturedProductsDialogButtons = (featuredProductsDataKey) =>
  withProps({ featuredProductsDataKey })(FeaturedProductsDialogButtons);

export default mkFeaturedProductsDialogButtons;
