import React, { useEffect, useState, useMemo } from 'react';
import _isNil from 'lodash/isNil';
import { useAppSelector } from 'src/utils/Hooks';
import { useTableData } from 'src/serverProxy/useTableData';
import { Text, useStacklineTheme } from '@stackline/ui';
import Pagination from 'src/components/BeaconRedesignComponents/Pagination/Pagination';
import { calculatePercentChange } from 'src/utils/app';
import SegmentNoDataPlaceholder from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Segments/SegmentNoDataPlaceholder';
import { SearchTextField } from 'src/components/BeaconRedesignComponents/SearchInput/SearchInputBar';
import TimePeriodDropdown from 'src/components/BeaconRedesignComponents/common/TimePeriodDropdown';
import ComparisonPeriodDropdown from 'src/components/BeaconRedesignComponents/common/ComparisonPeriodDropdown';
import { Box } from '@mui/material';
import { useBrandSplineChartData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/BrandSummary/brandSplineChartData';
import BrandCard from 'src/components/BeaconRedesignComponents/BrandCard/BrandCard';
import SlSkeleton from 'src/components/BeaconRedesignComponents/SlSkeleton/SlSkeleton';
import { BEACON_PRO_ENTITY_CARD_WIDTH } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { shouldHidePagination } from 'src/components/BeaconRedesignComponents/EntityTableRefresh/utils';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

const FETCH_CHUNK_SIZE = 150;
const GRID_ITEMS_PER_PAGE = 20;

/**
 * Brand Page Layout that can be access from main Navigation (top nav)
 *
 */
const BrandSummary = () => {
  const { startWeek: compareStartWeek, endWeek: compareEndWeek } = useAppSelector(
    (state) => state.comparisonTimePeriod
  );

  const theme = useStacklineTheme();
  const [initialBrands, setInitialBrands] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(GRID_ITEMS_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [isViewAll, setIsViewAll] = useState(false);
  const { isLoading: metricDataLoading, data: brandsData } = useTableData({
    fields: [{ name: 'retailSales', canBeExported: false }],
    groupByFieldName: 'brandId',
    indexName: 'sales',
    requestId: 'brandsRetailSales',
    itemsPerPage: FETCH_CHUNK_SIZE,
    pageNumber: 1,
    sortFieldName: 'retailSales',
    fetchWithoutProduct: true
  });

  const { primaryData, secondaryData, isSpineChartLoading } = useBrandSplineChartData();

  useEffect(() => {}, [primaryData]);

  const isLoading = useMemo(() => {
    return isSpineChartLoading || metricDataLoading;
  }, [isSpineChartLoading, metricDataLoading]);

  useEffect(() => {
    if (brandsData && primaryData && !_isNil(secondaryData)) {
      const advancedBrands = brandsData.map((datum) => {
        const { brandId } = datum.metadata;
        const currentValue = datum.retailSales_sum_value;
        const compareValue = datum[`retailSales_sum_value_${compareStartWeek}_${compareEndWeek}`];
        const percentageChange = calculatePercentChange(currentValue, compareValue);

        // Main Series
        const selectedGroupedCurrentData = primaryData.groupedCurrentData.find((brand) => brand.brandId === brandId);

        // Comparison Series
        const selectedGroupedComparisonData = secondaryData.groupedCurrentData.find(
          (brand) => brand.brandId === brandId
        );

        return {
          id: Number(brandId),
          currentRetailSales: currentValue,
          retailSalesChange: currentValue - compareValue,
          retailSalesChangePercentage: percentageChange,
          name: datum.metadata.name,
          displayName: datum.metadata.name,
          currentSplineChartData: selectedGroupedCurrentData ? selectedGroupedCurrentData.weeklyData : 0,
          currentSplineChartComparisonData: selectedGroupedComparisonData ? selectedGroupedComparisonData.weeklyData : 0
        };
      });
      advancedBrands.sort((a, b) => b.currentRetailSales - a.currentRetailSales);
      setInitialBrands(advancedBrands);
    }
  }, [brandsData, compareEndWeek, compareStartWeek, metricDataLoading, primaryData, secondaryData]);

  const filteredBrands = initialBrands.filter((brand) => {
    // "All brands" won't have its name
    return brand.name && brand.name.toLowerCase().includes(searchInput.toLowerCase());
  });

  // prevent showing 0 when there is no search list. the total pagination number should still show 1
  const totalPage = Math.ceil(filteredBrands.length / itemsPerPage) || 1;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const brandsToDisplay = filteredBrands.slice(startIndex, endIndex);

  // Handle next page and previous page clicks
  const handleNextPage = () => {
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleViewAll = () => {
    setIsViewAll(true);
    setItemsPerPage(initialBrands.length);
    setCurrentPage(1);
  };

  return (
    <>
      <BeaconPageContainer sx={{ width: '100%', marginTop: '0px' }}>
        <div style={{ width: '100%', position: 'relative' }}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Text variant="h2">Brands</Text>
            <Flex>
              <Flex paddingX={theme.spacing.md}>
                <TimePeriodDropdown />
              </Flex>
              <ComparisonPeriodDropdown />
            </Flex>
          </Box>

          <Flex paddingY={theme.spacing.lg}>
            <SearchTextField
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search brands"
            />
          </Flex>
          {brandsToDisplay.length === 0 && !isLoading ? (
            <Flex marginTop={theme.spacing.lg} justifyContent="center">
              <SegmentNoDataPlaceholder
                title="No search results found"
                subtitle="There is no data available for the search term."
                maxWidth={238}
              />
            </Flex>
          ) : (
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: theme.spacing.mdl }}>
              {isLoading
                ? Array.from({ length: 20 }).map((ele, index) => (
                    <SlSkeleton
                      variant="rounded"
                      height={415}
                      width={+BEACON_PRO_ENTITY_CARD_WIDTH.replace('px', '')}
                      key={index}
                    />
                  ))
                : brandsToDisplay.map((brand) => {
                    return <BrandCard key={brand.id} val={brand} />;
                  })}
            </div>
          )}
          <div style={{ marginTop: '20px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                zIndex: 1,
                position: 'relative'
              }}
            >
              {/* placeholder for the space between */}
              <div>
                {searchInput.length === 0 && !isViewAll && (
                  <div role="button" onClick={handleViewAll}>
                    <Text variant="subtitle3">View All Brands ({initialBrands.length})</Text>
                  </div>
                )}
              </div>
              {shouldHidePagination({
                page: currentPage,
                pageSize: GRID_ITEMS_PER_PAGE,
                rowCount: (brandsToDisplay || []).length
              }) ? null : (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPage}
                  onClickNext={handleNextPage}
                  onClickPrevious={handlePreviousPage}
                />
              )}
            </div>
          </div>
        </div>
      </BeaconPageContainer>
    </>
  );
};

export default BrandSummary;
