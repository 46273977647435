import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';
import { Parser } from 'expr-eval';

const expressionParser = new Parser();

export const NEW_CONTENT_METRICS: {
  [key: string]: FieldDefinition;
} = {
  unitsSoldForContentScore: {
    name: 'unitsSoldForContentScore',
    displayName: 'Units Sold for Content Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  weightedAplusScoreByUnits: {
    name: 'weightedAplusScoreByUnits',
    displayName: 'Weighted Aplus Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  weightedBulletScoreByUnits: {
    name: 'weightedBulletScoreByUnits',
    displayName: 'Weighted Bullet Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  weightedContentScoreByUnits: {
    name: 'weightedContentScoreByUnits',
    displayName: 'Weighted Content Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  weightedImageScoreByUnits: {
    name: 'weightedImageScoreByUnits',
    displayName: 'Weighted Image Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  weightedTitleScoreByUnits: {
    name: 'weightedTitleScoreByUnits',
    displayName: 'Weighted Title Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  weightedVideoScoreByUnits: {
    name: 'weightedVideoScoreByUnits',
    displayName: 'Weighted Video Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  contentScore: {
    name: 'contentScore',
    displayName: 'Content Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold > 0 ? (weightedContentScoreByUnits / unitsSold) : 0')
  },
  titleScore: {
    name: 'titleScore',
    displayName: 'Title Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      'unitsSoldForContentScore > 0 ? (weightedTitleScoreByUnits / unitsSoldForContentScore) : 0'
    )
  },
  bulletScore: {
    name: 'bulletScore',
    displayName: 'Bullet Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      'unitsSoldForContentScore > 0 ? (weightedBulletScoreByUnits / unitsSoldForContentScore) : 0'
    )
  },
  imageScore: {
    name: 'imageScore',
    displayName: 'Image Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      'unitsSoldForContentScore > 0 ? (weightedImageScoreByUnits / unitsSoldForContentScore) : 0'
    )
  },
  videoScore: {
    name: 'videoScore',
    displayName: 'Video Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      'unitsSoldForContentScore > 0 ? (weightedVideoScoreByUnits / unitsSoldForContentScore) : 0'
    )
  },
  aplusScore: {
    name: 'aplusScore',
    displayName: 'A-Plus Score',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      'unitsSoldForContentScore > 0 ? (weightedAplusScoreByUnits / unitsSoldForContentScore) : 0'
    )
  },
  approvedAplusContentCount: {
    name: 'approvedAplusContentCount',
    displayName: 'Approved APlus Content Count',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  matchedAplusContentCount: {
    name: 'matchedAplusContentCount',
    displayName: 'Matched APlus Content Count',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  aplusAccuracy: {
    name: 'aplusAccuracy',
    displayName: 'APlus Accuracy',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      '((approvedAplusContentCount_sum > 0) ? ((matchedAplusContentCount_sum / approvedAplusContentCount_sum)) : (1))'
    )
  },
  approvedBulletsCount: {
    name: 'approvedBulletsCount',
    displayName: 'Approved Bullets Count',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  matchedBulletsCount: {
    name: 'matchedBulletsCount',
    displayName: 'Matched Bullets Count',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  bulletsAccuracy: {
    name: 'bulletsAccuracy',
    displayName: 'Bullets Accuracy',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      '((approvedBulletsCount > 0) ? ((matchedBulletsCount / approvedBulletsCount)) : (1))'
    )
  },
  approvedImageUrlsCount: {
    name: 'approvedImageUrlsCount',
    displayName: 'Approved Image Urls Count',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  matchedImageUrlsCount: {
    name: 'matchedImageUrlsCount',
    displayName: 'Matched Image Urls Count',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  imageUrlsAccuracy: {
    name: 'imageUrlsAccuracy',
    displayName: 'Image Urls Accuracy',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      '((approvedImageUrlsCount > 0) ? ((matchedImageUrlsCount / approvedImageUrlsCount)) : (1))'
    )
  },
  approvedTitleCount: {
    name: 'approvedTitleCount',
    displayName: 'Approved Title Count',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  matchedTitleCount: {
    name: 'matchedTitleCount',
    displayName: 'Matched Title Count',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  titleAccuracy: {
    name: 'titleAccuracy',
    displayName: 'Title Accuracy',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      '((approvedTitleCount > 0) ? ((matchedTitleCount / approvedTitleCount)) : (1))'
    )
  },
  approvedVideoUrlsCount: {
    name: 'approvedVideoUrlsCount',
    displayName: 'Approved Video Urls Count',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  matchedVideoUrlsCount: {
    name: 'matchedVideoUrlsCount',
    displayName: 'Matched Video Urls Count',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  videoUrlsAccuracy: {
    name: 'videoUrlsAccuracy',
    displayName: 'Video Urls Accuracy',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse(
      '((approvedVideoUrlsCount > 0) ? ((matchedVideoUrlsCount / approvedVideoUrlsCount)) : (1))'
    )
  }
};
