import { useCallback } from 'react';
import { getAppName } from 'src/utils/app';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { useAppSelector } from 'src/utils/Hooks';

export interface Field {
  name: string;
  canBeExported?: boolean;
  /**
   * Column title for the field name in a table.
   */
  displayName?: string;
  /**
   * Optional formatter for the comparison value in
   * the table. If not provided, will default to percent
   * change.
   */
  comparisonFormatter?: (value: number, difference: number) => React.ReactNode;
}

export interface UseAggregationBuilderWithMetricsArgs {
  indexName: string;
  fields: Field[];
  groupByFieldName?: string;
}

export const useCreateAggregationBuilderWithMetrics = () => {
  const retailerId = useAppSelector((state) => state.retailer.id);

  return useCallback(
    ({ indexName, fields, groupByFieldName }: UseAggregationBuilderWithMetricsArgs) => {
      const indexFields = fields.map((field) => INDEX_FIELDS.getField(getAppName(), indexName, field.name));

      const builder = new AggregationBuilder(groupByFieldName || 'weekId').addConditionTermFilter('retailerId', [
        retailerId
      ]);

      indexFields.forEach((indexField, index) => {
        if (indexField.dependentFields) {
          indexField.dependentFields.forEach((dependentField) => {
            builder.addAggregationField(
              dependentField.displayName,
              dependentField.name,
              dependentField.aggregationFunction,
              true
            );
          });
        }
        builder.addAggregationField(
          indexField.displayName,
          indexField.name,
          indexField.aggregationFunction as string,
          fields[index].canBeExported || true,
          indexField.aggregationFunctionExpression
        );
      });

      return builder;
    },
    [retailerId]
  );
};

/**
 * Returns an aggregation builder given a list of metrics.
 */
export const useAggregationBuilderWithMetrics = (args: UseAggregationBuilderWithMetricsArgs) => {
  const createAggregationBuilderWithMetrics = useCreateAggregationBuilderWithMetrics();
  return createAggregationBuilderWithMetrics(args);
};
