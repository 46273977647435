import React, { FC, useState, useEffect } from 'react';
import { Widget, Widget as WidgetType } from 'src/types/application/widgetTypes';
import OmniDonutChartReview from 'src/components/Omni/OmniDonutChart/OmniDonutChartReview';
import OmniReviewTable from 'src/components/Omni/OmniReviewTable/OmniReviewTable';
import { get } from 'lodash';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import _get from 'lodash/get';
import 'src/components/EntityPage/Reviews/ReviewMetrics.scss';

interface OmniWidgetGroupProps {
  eventBus: EventBus;
  widget: Widget;
}

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    display: 'flex',
    flex: '1 1 0%',
    marginBottom: '30px'
  },
  left: {
    flex: '1 1',
    maxWidth: 500
  },
  right: {
    flex: '1 1'
  }
};

const OmniWidgetGroupReview: FC<OmniWidgetGroupProps> = ({ widget, eventBus }) => {
  const [starFilter, setStarFilter] = useState<string[]>(['1', '2', '3', '4', '5']);
  const [keywordFilter, setKeywordFilter] = useState('');
  const { rightWidget, extraWidget } = widget;
  const childRightWidget = rightWidget as WidgetType;
  const CustomComponent = get(rightWidget, 'CustomComponent');
  const childExtraWidget = extraWidget as WidgetType;
  const ExtraWidgetCustom = get(extraWidget, 'CustomComponent');
  const handleStarValuesChange = (selectedPoints: Highcharts.Point[]) => {
    const newStarFilter = selectedPoints.map((v) => {
      const fieldId = _get(v, 'fieldId', 1);
      return String(fieldId);
    });
    setStarFilter(newStarFilter);
  };

  const handleKeywordFilter = (value: string) => {
    setKeywordFilter(value);
  };

  useEffect(() => {
    eventBus.on('filterReviewKeyword', handleKeywordFilter);
    return () => {
      eventBus.off('filterReviewKeyword', handleKeywordFilter);
    };
  }, [eventBus]);

  return (
    <>
      <div style={styles.root}>
        <div className="review-metrics__donut" style={{ marginTop: 56 }}>
          <OmniDonutChartReview reviewKeyword={keywordFilter} handleStarValuesChange={handleStarValuesChange} />
        </div>
        <div style={styles.right}>
          {CustomComponent ? (
            <CustomComponent widget={childRightWidget} starFilter={starFilter} reviewKeywordFilter={keywordFilter} />
          ) : null}
        </div>
      </div>
      <div>
        {ExtraWidgetCustom ? (
          <ExtraWidgetCustom widget={childExtraWidget} starFilter={starFilter} reviewKeywordFilter={keywordFilter} />
        ) : null}
      </div>

      <OmniReviewTable
        starFilter={starFilter}
        reviewKeywordFilter={keywordFilter.length === 0 ? [] : [keywordFilter]}
      />
    </>
  );
};

export default withBus('eventBus')(OmniWidgetGroupReview);
