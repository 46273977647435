import moment from 'moment';
import { Series as HighchartsSeries } from 'highcharts';
import { Option } from 'funfix-core';
import _findIndex from 'lodash/findIndex';
import _range from 'lodash/range';
import _uniq from 'lodash/uniq';
import { ValueOf } from 'sl-api-connector/types';
import ReduxStore from 'src/types/store/reduxStore';
import { quoteWrap } from 'src/utils/stringFormatting';
import { prop } from 'src/utils/fp';
import { mkFormatValue } from 'src/components/Charts/GenericChart/SeriesConverters/barChart';
import { DATATYPE } from 'src/utils/entityDefinitions';

export interface Series extends Omit<HighchartsSeries, 'data'> {
  data: ([number, number] | { x: number; y: number })[];
}

interface NormalizedSeries extends Omit<HighchartsSeries, 'data'> {
  data: [number, number][];
  metricType?: ValueOf<typeof DATATYPE> | null | undefined;
}

const computeHeadersAndMaxDataLength = (chartSeries: Series[]) => {
  return chartSeries.reduce(
    ({ headers, maxDataLength }, series) => ({
      headers: [...headers, quoteWrap(series.name)],
      maxDataLength: Math.max(maxDataLength, series.data.length)
    }),
    { headers: ['WeekEnding'], maxDataLength: 0 }
  );
};

const normalizeSeries = (series: Series): NormalizedSeries => ({
  ...series,
  data: series.data.map((datum) => (Array.isArray(datum) ? datum : [datum.x, datum.y]))
});

const formatWeekEnding = (firstWeekEnding: number | undefined) => {
  const formattedFirstDate = Option.of(firstWeekEnding)
    .map((weekEnding) => moment(weekEnding).format('MMMM DD, YYYY'))
    .getOrElse('');

  return quoteWrap(formattedFirstDate);
};

/**
 * Given the X value and a series, fetches the Y value for a given series
 * and formats the string. Returns placeholder string for missing values
 *
 * @param {number} i The reducer index
 * @param {NormalizedSeries} i The series containing the target value
 * @param {function} formatValue Value formatting functino
 */
const getValue = (
  i: number,
  weekEnding: number,
  chartSeries: NormalizedSeries,
  formatValue: (val: string | number, colIx: number) => string | number
) => {
  const index = _findIndex(chartSeries.data, (o) => o[0] === weekEnding);

  if (index > -1) {
    return formatValue(chartSeries.data[index][1], i);
  }

  return '';
};

/**
 * Creates a CSV row of the data from a HighCharts line chart, handling getting data from multiple series for a
 * given time period, e.g. a week
 *
 * @param {array} chartSeries The array of all HighCharts series from the chart
 */
const mkCreateDataRow = (chartSeries: NormalizedSeries[]) => (j: number, weeks: number[]) => {
  const weekEnding = formatWeekEnding(weeks[j]);
  const dataTypes: (ValueOf<typeof DATATYPE> | null | undefined)[] = chartSeries.map(prop('metricType'));
  const formatValue = mkFormatValue(dataTypes, false);

  return _range(chartSeries.length).reduce(
    (acc, i) => {
      return [...acc, getValue(i, weeks[j], chartSeries[i], formatValue)];
    },
    [weekEnding] as (string | number)[]
  );
};

/**
 * Removes overlapping weekending values for the last weeks of the year from the current period, when the comparison year is prior year
 *
 * @param chartSeries
 */
const removeCommonWeekDataFromCurrentPeriod = (chartSeries) => {
  for (let i = chartSeries[0].data.length - 1; i >= Math.max(chartSeries[0].data.length - 2, 0); i--) {
    for (let j = 0; j < Math.min(chartSeries[1].data.length, 2); j++) {
      if (chartSeries[0].data[i][0] === chartSeries[1].data[j][0]) {
        chartSeries[1].data.splice(j, 1);
        break;
      }
    }
  }
  return chartSeries;
};

/**
 * Produces a CSV containing the data from a HighCharts line chart, handling filling in missing data with placeholders
 * and returning the result as a string.
 *
 * @param {array} chartSeries The array of all HighCharts series from the chart
 */
const convertLineChartSeriesToDelimitedData = (
  chartSeries: Series[],
  isPriorPeriod: boolean = false,
  comparisonTimePeriod?: ReduxStore['comparisonTimePeriod'],
  mainTimePeriod?: ReduxStore['mainTimePeriod']
) => {
  if (chartSeries.length === 2 && isPriorPeriod) {
    chartSeries = removeCommonWeekDataFromCurrentPeriod(chartSeries);
  }

  const { headers, maxDataLength } = computeHeadersAndMaxDataLength(chartSeries);

  const normalizedSeries = chartSeries.map(normalizeSeries);

  // build an array of all period ending timestamps
  let uniqueXValues: number[] = [];
  chartSeries.forEach((series: Series) => {
    series.data.forEach((val) => {
      if (Array.isArray(val)) {
        uniqueXValues.push(val[0]);
      } else {
        uniqueXValues.push(val.x);
      }
    });
  });
  // remove duplicates and sort ascending
  uniqueXValues = _uniq(uniqueXValues);
  uniqueXValues.sort();

  // in case of 4w, an extra 5th week is showing up. To overcome this, we are filtering out that extra week which does not fall in to that timerange
  if (mainTimePeriod && mainTimePeriod.id === '4w' && comparisonTimePeriod) {
    const startDate = Date.parse(comparisonTimePeriod.startWeekStartDate.toDateString());
    const endDate = Date.parse(mainTimePeriod.endWeekEndDate.toDateString());
    uniqueXValues = uniqueXValues.filter((timeSeries) => timeSeries >= startDate && timeSeries <= endDate);
  }
  const createDataRow = mkCreateDataRow(normalizedSeries);

  const dataRows = _range(maxDataLength * 2 - 1).map((j) => createDataRow(j, uniqueXValues), []);

  const csvContent = [headers, ...dataRows].map((rowElements) => rowElements.join(',')).join('\n');

  return `${csvContent}\n`;
};

export default convertLineChartSeriesToDelimitedData;
