import React from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';
import { store } from 'src/main';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import _get from 'lodash/get';

import 'src/components/Grids/Data/ColumnTypes/Entity.scss';
import ReduxStore from 'src/types/store/reduxStore';
import { AdCampaignBuilderCheckbox } from 'src/components/AdCampaignBuilder/Widgets/Checkbox';
import { receiveEntitySalesMetrics } from 'src/store/modules/entitySearchService/operations';
import './CampaignColumn.scss';

const mapStateToProps = (state: ReduxStore, { column }: { column: any }) => {
  const {
    colDef: { widget }
  } = column;
  const [groupByField] = widget.data.groupByFields;
  const dataSet =
    state.entitySearchService[widget.data.statePropertyName] &&
    state.entitySearchService[widget.data.statePropertyName].groupByFieldName.includes(groupByField.name)
      ? state.entitySearchService[widget.data.statePropertyName]
      : null;
  const selectedMapping = state.entitySearchService[`${widget.data.statePropertyName}_selectedMapping`] || [];
  return {
    ..._pick(state, [
      'app',
      'entityService',
      'comparisonTimePeriod',
      'mainTimePeriod',
      'retailer',
      'categories',
      'filters',
      'allWeekIdsByRetailerId',
      'adCampaigns',
      'adPortfolios',
      'adEntities',
      'entitySearchService'
    ]),
    dataSet,
    column,
    selectedMapping
  };
};

type EntityColumnProps = {
  column: any;
  data?: { [key: string]: any };
  dataSet: { [key: string]: any };
} & ReturnType<typeof mapStateToProps>;

class SelectEntityColumn extends React.Component<EntityColumnProps> {
  public static defaultProps = {
    data: {}
  };

  public render() {
    const {
      dataSet,
      selectedMapping,
      column: {
        colDef: { widget }
      }
    } = this.props;
    // omit this property to match data.entity with dataSetCloned[i].entity
    // to enable campaign selection on create scheduled action page
    const data = _omit(this.props.data, 'entity.projectedSpend');

    if (data.disableToggle) {
      return null;
    }

    const isHeaderColumn = !data.id;
    let isSelected: boolean | undefined =
      (isHeaderColumn && _get(dataSet, 'data', false) && selectedMapping.length > 0) ||
      selectedMapping.includes(data.id);
    if (isHeaderColumn) {
      isSelected = _get(dataSet, 'data', []).length <= selectedMapping.length;
    }
    const onChange = (event) => {
      const dataSetCloned = _cloneDeep(dataSet);
      let selectedMappingCloned: any = _cloneDeep(selectedMapping);

      _get(dataSetCloned, 'data', []).forEach((dataPoint: any) => {
        if (!dataPoint.disableToggle && (_isEqual(dataPoint.entity, data.entity) || isHeaderColumn)) {
          if (event.target.checked && !selectedMappingCloned.includes(dataPoint.id)) {
            selectedMappingCloned.push(dataPoint.id);
          } else if (!event.target.checked) {
            if (isHeaderColumn) {
              selectedMappingCloned = [];
            } else {
              selectedMappingCloned = selectedMappingCloned.filter((item) => item !== dataPoint.id);
            }
          }
          // dataPoint.isSelected = event.target.checked;
        }
      });
      store.dispatch(
        receiveEntitySalesMetrics(`${widget.data.statePropertyName}_selectedMapping`, selectedMappingCloned)
      );
    };

    return _get(dataSet, 'data', []).length > 0 ? (
      <AdCampaignBuilderCheckbox checked={isSelected} onChange={onChange} />
    ) : null;
  }
}

const EnhancedSelectEntityColumn = connect(mapStateToProps)(SelectEntityColumn);

export default EnhancedSelectEntityColumn;
