const { ChurnZero } = window;
const churnZeroAppKey = '1!jbBCkGxDEA8epE3dF486pnI2P6AWo8IM67iEvOwLG58t1085';

// temp use my account info
export const churnZeroConnect = (email = 'wli@stackline.com', companyId) => {
  ChurnZero.push(['setAppKey', churnZeroAppKey]);
  ChurnZero.push(['setContact', companyId, email]);
};

export const trackLogInChurn = (userEmail) => {
  ChurnZero.push(['trackEvent', 'UserLogIn', `${userEmail} logged in`]);
};

export const trackThePageStay = (pageName) => {
  ChurnZero.push(['setModule', pageName]);
};

export const churnZeroLogout = () => {
  ChurnZero.push(['trackEvent', 'Logout']);
  ChurnZero.push(['stop']);
};
