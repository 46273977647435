import React, { createContext } from 'react';

interface PaginationContextValue {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const PaginationContext = createContext<PaginationContextValue>(null);

interface PaginationProviderProps {
  children: React.ReactNode;
}

/**
 * Provides an interface for setting the page of a paginated component from anywhere in the app.
 * Useful for something like the grid renderer which can display either a grid or a table, so we can control
 * the page of both from a single location.
 */
export default function PaginationProvider({ children }: PaginationProviderProps) {
  const [page, setPage] = React.useState(1);

  return (
    <PaginationContext.Provider
      value={{
        page,
        setPage
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
}

/**
 * Hook for getting the page and setPage function from the PaginationProvider
 */
export const usePagination = () => {
  const context = React.useContext(PaginationContext);
  if (!context) {
    throw new Error('usePagination must be used within a PaginationProvider');
  }
  return context;
};
