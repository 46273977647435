/* eslint-disable no-restricted-syntax */
// import axios from 'axios';
// import _get from 'lodash/get';
import Axios from 'axios';
import _isArray from 'lodash/isArray';
// import { AD_NEW_PLATFORM_RETAILERS } from 'src/store/modules/app/dataMocks';
import _get from 'lodash/get';
import {
  ModifyESQueryAction,
  getESBodyOverridesForParentPlatform,
  modifyESQuery
} from 'src/components/AdManager/Search/GridDataFetchers/GetSearchRequestOverrideForGroupByField';

export interface Retailer {
  platformType: string;
  parentPlatform: string;
  parentPlatformRetailerId: string;
  extendedAttributes: {
    name: string;
    status: string;
    retailerId: number;
    countryCode: string;
    currencyCode: string;
  };
  createdBy: string;
  createdTime: string;
  lastUpdatedBy: string;
  lastUpdatedTime: string;
  id: string;
  name: string;
}

export type ParentPlatforms = SingleParentPlatform[];

export interface SingleParentPlatform {
  parentPlatform: string;
  id: string;
  name: string;
  retailers: Retailer[];
}

export class ParentPlatformUtils {
  /**
   * Get all platforms and retailers for them.
   */
  public static getPlatforms = async () => {
    try {
      const adParentPLatforms = await Axios.get(`apiAdManager/adParentPlatforms`);
      const data = _get(adParentPLatforms, 'data', []);
      return data as ParentPlatforms;
    } catch (error) {
      return [];
    }
  };

  /**
   * Finds the first retailer within a given platform that matches a specified retailer ID.
   *
   * @param { { retailers?: Array<{ extendedAttributes: { retailerId: string | number } }> } } platform - An object representing a platform, which may contain an array of retailers.
   * @returns { { extendedAttributes: { retailerId: string | number } } | {} } - Returns the first matching retailer object if found, otherwise returns an empty object.
   */
  public static getFirstRetailer = (platform) => {
    if (!platform || (platform.retailers && platform.retailers.length === 0)) {
      return {};
    }
    return platform.retailers[0];
  };

  /**
   * Finds the first retailer within a given platform that matches a specified retailer ID.
   *
   * @param { { retailers?: Array<{ extendedAttributes: { retailerId: string | number } }> } } platform - An object representing a platform, which may contain an array of retailers.
   * @returns { { extendedAttributes: { retailerId: string | number } } | {} } - Returns the first matching retailer object if found, otherwise returns an empty object.
   */
  public static getFirstRetailerWithConfig = (platform, availableRetailers) => {
    if (!platform || (platform.retailers && platform.retailers.length === 0)) {
      return {};
    }
    return platform.retailers.find((r) => availableRetailers.includes(`${r.extendedAttributes.retailerId}`));
  };

  /**
   * Finds the first retailer within a given platform that matches a specified retailer ID.
   *
   * @param { { retailers?: Array<{ extendedAttributes: { retailerId: string | number } }> } } platform - An object representing a platform, which may contain an array of retailers.
   * @param {string | number} rid - The retailer ID to search for.
   * @returns { { extendedAttributes: { retailerId: string | number } } | {} } - Returns the first matching retailer object if found, otherwise returns an empty object.
   */
  public static getRetailer = (platform, rid) => {
    if (!platform || (platform.retailers && platform.retailers.length === 0)) {
      return {};
    }
    return platform.retailers.find(
      (r) => r.extendedAttributes.retailerId === rid || r.extendedAttributes.retailerId === +rid
    );
  };

  /**
   * Finds the first parent platform and retailer that match a given retailer ID.
   *
   * @param {Array<{ retailers: Array<{ extendedAttributes: { retailerId: string | number } } }>} availablePlatforms - An array of parent platforms, each containing an array of retailers.
   * @param {string | number} rid - The retailer ID to search for.
   * @returns {Array<null | { retailers: Array<{ extendedAttributes: { retailerId: string | number } } }, null | { extendedAttributes: { retailerId: string | number } }>} - An array containing the first matching parent platform and retailer, or null if not found.
   */
  public static getFirstParentPlatformByRetailer = (availablePlatforms, rid) => {
    // Return an empty array if no platforms are available
    if (!availablePlatforms || availablePlatforms.length === 0) {
      return [null, null];
    }

    let foundParentPlatform = null;
    let foundRetailer = null;
    let found = false; // Flag to indicate a match

    for (const parentPlatform of availablePlatforms) {
      if (found) {
        break;
      } // Break the outer loop if a match was found

      for (const retailer of parentPlatform.retailers) {
        if (retailer.extendedAttributes.retailerId === rid || retailer.extendedAttributes.retailerId === +rid) {
          foundParentPlatform = parentPlatform;
          foundRetailer = retailer;
          found = true;
          break; // Break the inner loop
        }
      }
    }

    return [foundParentPlatform, foundRetailer];
  };

  /**
   * Finds the first parent platform and retailer that match a given retailer ID.
   *
   * @param {Array<{ retailers: Array<{ extendedAttributes: { retailerId: string | number } } }>} availablePlatforms - An array of parent platforms, each containing an array of retailers.
   * @returns {Array<null | { retailers: Array<{ extendedAttributes: { retailerId: string | number } } }, null | { extendedAttributes: { retailerId: string | number } }>} - An array containing the first matching parent platform and retailer, or null if not found.
   */
  public static getFirstResult = (availablePlatforms) => {
    // If rid is not provided, return the first retailer of the first parent platform
    if (availablePlatforms.length > 0 && availablePlatforms[0].retailers.length > 0) {
      return [availablePlatforms[0], availablePlatforms[0].retailers[0]];
    }

    // Return an empty array if no platforms are available
    return [null, null];
  };

  public static setAvailabilityForParentPlatforms(platforms, availableRetailers) {
    try {
      if (!platforms || !platforms.length) {
        return [];
      }
      const retailersSet = new Set(availableRetailers);

      const inactivePlatforms = [];
      const activePlatforms = [];

      platforms.forEach((platform) => {
        const active = platform.retailers.some((retailer) =>
          retailersSet.has(retailer.extendedAttributes.retailerId.toString())
        );
        const updatedPlatform = { ...platform, active };

        if (active) {
          activePlatforms.push(updatedPlatform);
        } else {
          inactivePlatforms.push(updatedPlatform);
        }
      });

      return [...activePlatforms, ...inactivePlatforms];
    } catch {
      return platforms;
    }
  }
}

export const PARENT_PLATFORMS = {
  CRITEO: 'Criteo',
  SAMSCLUB: 'SamsClub',
  WALMART: 'Walmart',
  AMAZON: 'Amazon',
  INSTACART: 'Instacart'
};

export const applyCriteoOverrides = (
  overrides: object[],
  parentPlatform: string,
  importantOverrides: ModifyESQueryAction[]
) => {
  if (
    parentPlatform &&
    parentPlatform === PARENT_PLATFORMS.CRITEO &&
    importantOverrides &&
    _isArray(importantOverrides)
  ) {
    const newOverride = [];
    overrides.forEach((_, indx) => {
      const mutatedQuery = modifyESQuery(
        overrides[indx],
        getESBodyOverridesForParentPlatform(null, importantOverrides)
      );
      newOverride.push(mutatedQuery);
    });
    return newOverride;
  }
  return overrides; // return original if no overrides applied
};
