import { AdPortfolioEntity } from 'sl-api-connector/types';

import types from './types';

const receiveAdPortfolios = (adPortfolios: AdPortfolioEntity[]) => ({
  type: types.RECEIVE_ADPORTFOLIOS,
  adPortfolios,
  receivedAt: Date.now()
});

const clearAdPortfolios = () => ({ type: types.CLEAR_ADPORTFOLIOS });

export default {
  clearAdPortfolios,
  receiveAdPortfolios
};
