import _isEqual from 'lodash/isEqual';

import * as types from './types';
import { eq } from 'src/utils/fp';

const initialState = {
  inactiveMetrics: [],
  activeMetrics: []
};

const reducer = (state = initialState, action) => {
  const subReducer = {
    [types.SET_ACTIVE_METRIC]: () => {
      const curValue = state.activeMetrics[action.i];
      if (curValue === action.fieldName) {
        return state;
      }

      const newInactiveMetrics = [...state.inactiveMetrics];
      const changedIx = state.inactiveMetrics.findIndex(eq(action.fieldName));
      newInactiveMetrics[changedIx] = curValue;

      const newActiveMetrics = [...state.activeMetrics];
      newActiveMetrics[action.i] = action.fieldName;
      return { ...state, activeMetrics: newActiveMetrics, inactiveMetrics: newInactiveMetrics };
    },
    [types.SET_INACTIVE_METRICS]: () => {
      if (
        _isEqual(action.inactiveMetrics, state.inactiveMetrics) &&
        _isEqual(action.defaultActiveMetrics, state.activeMetrics)
      ) {
        return state;
      }

      return {
        ...state,
        inactiveMetrics: action.inactiveMetrics,
        activeMetrics: action.defaultActiveMetrics
      };
    }
  }[action.type];

  return subReducer ? subReducer() : state;
};

export default reducer;
