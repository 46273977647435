/* eslint-disable react/prop-types */
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomizedCheckbox from 'src/components/common/CustomizedCheckBox/CustomizedCheckBox';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from 'src/components/SvgIcons';

interface ListFilterRowProps {
  isChecked: boolean;
  onCheck: (event: any, isChecked: boolean, index: number) => void;
  value: string;
  style?: React.CSSProperties;
  index: number;
  className?: string;
}

export const ListFilterRow: React.FC<ListFilterRowProps> = ({ isChecked, onCheck, value, style, index, className }) => {
  return (
    <div key={value} style={style} className={className}>
      <div style={{ marginLeft: '2px' }}>
        <FormControlLabel
          control={
            <CustomizedCheckbox
              styleObj={{
                width: 24,
                height: 24,
                iconBackgroundColor: 'transparent',
                iconColor: 'transparent',
                checkedIconBackgroundColor: 'transparent',
                checkedIconColor: 'transparent'
              }}
              InnerIcon={CheckboxCheckedIcon}
              OuterIcon={CheckboxUncheckedIcon}
              checkboxProps={{
                checked: isChecked,
                onChange: (evt) => {
                  onCheck(evt, evt.target.checked, index);
                }
              }}
            />
          }
          label={<span style={{ fontSize: '13px' }}>{value}</span>}
        />
      </div>
    </div>
  );
};
