import _get from 'lodash/get';
import _set from 'lodash/set';
import { AdvancedSearchQueryAggregation, Conditions, SortField } from 'sl-api-connector/search';
import axios from 'axios';
import { EXPORT_DOWNLOAD_PAGE_SIZE } from 'src/providers/ExportContextProvider';

const EXPORT_REQUEST_API_URL = '/api/user/GetExportRequests';

export interface ExportConfig {
  name: string;
  id: string;
  processDocuments: boolean;
  pageNumber: number;
  pageSize: number;
  retailerId: string;
  period: string;
  doAggregation: boolean;
  returnDocuments: boolean;
  conditions: Conditions;
  searchBy: string;
  aggregations: AdvancedSearchQueryAggregation[];
  sortFilter: {
    sortFields: SortField[];
  };
  searchType: string;
  additionalRequestMetaData?: {
    derivedFieldsJson: string;
    toCompare?: boolean;
    forecastSelection?: string;
  };
}

export interface ExportDownloadItem {
  requestId: string;
  userId: string;
  appName: string;
  uniqueUserAppKey: string;
  originalRequest: string;
  dataSourceType: string;
  elasticSearchUri: string | null;
  columnDelimiter: string;
  additionalExportOptions: {
    weekIdRanges1: string;
    Tab: string;
    SubTab: string;
    exportRequestSource: string;
  };
  requestedTime: number;
  beganProcessing: number;
  finishedProcessing: number;
  status: string;
  fileUri: string;
  fileSize: number;
  fileName: string;
  numberOfDownloads: number;
  lastDownloadTimestamp: number | null;
  isDeleted: boolean;
  error: string;
  appStage: string;
  serializedExportRequestJsonFileUrl: string;
}

export const enum ExportStatus {
  SUCCESS = 'finished successfully',
  PROGRESS = 'in progress',
  FAILED = 'failed with error',
  CANCELED = 'canceled',
  QUEUE = 'queued'
}

export const makeGroupByConsistent = (mergedRequest: ExportConfig[], groupByFieldName: string) => {
  if (groupByFieldName) {
    // Make sure the groupBy field is consistent, if one is set via the UI
    let groupBy = groupByFieldName;
    if (mergedRequest.length > 1) {
      mergedRequest.forEach((req, index) => {
        if (_get(req, ['aggregations', '0'])) {
          if (groupByFieldName && index === 1 && groupByFieldName.includes(',merchantId')) {
            // special case: Buy box sellers export. Second request cannot group by merchant id
            // but we still need to update the group by field
            groupBy = groupByFieldName.replace(',merchantId', '');
          }
          _set(req, ['aggregations', '0', 'groupByFieldName'], groupBy);
        }
      });
    }
  }
};

// INITIAL FETCH REQUEST -- FETCH EXPORTABLE DATA LIST -- To see if there is a in Progress Item.
export const fetchExportDownloadList = async (): Promise<{
  result: ExportDownloadItem[];
}> => {
  try {
    const requestData = { pageSize: EXPORT_DOWNLOAD_PAGE_SIZE };
    const response = await axios.post(EXPORT_REQUEST_API_URL, requestData);

    const { result } = response.data;

    if (result) {
      return { result };
    } else {
      console.error('No data was found in the response');
      return [];
    }
  } catch (error) {
    console.error('Error fetching export download list:', error);
  }
  return [];
};

// if there is "in progress" or "queued", this will return false
export const checkDownloadComplete = (exportResponseData: ExportDownloadItem[]) => {
  return exportResponseData.every((datum) => {
    return datum.status !== ExportStatus.PROGRESS && datum.status !== ExportStatus.QUEUE;
  });
};

// We need to fetch what is in progress,
// we will have to fetch by pending's requestID
export const findProgressItems = (downloadList: ExportDownloadItem[]) => {
  return downloadList.filter((datum) => datum.status === ExportStatus.PROGRESS || datum.status === ExportStatus.QUEUE);
};

export const fetchProgressItems = async (requestItemIds: string[]) => {
  try {
    const requestData = { requestIds: requestItemIds, pageSize: requestItemIds.length };
    const { data } = await axios.post(EXPORT_REQUEST_API_URL, requestData);

    const { result } = data;

    return result;
  } catch (error) {
    console.error('fetch in progressItems fails', error);
  }
  return [];
};
