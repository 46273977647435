import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import queryString from 'qs';
import TextField from '@mui/material/TextField';
import { AutoSizer, List as VirtualizedList, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import i18n from 'src/i18n_en';
import { anyNotEq } from 'src/utils/equality';
import { ListFilterRow } from './common';
import './Filter.scss';
import { AppName } from 'sl-api-connector';

const NoCategoriesFound = () => <div style={{ height: 175 }}>{i18n.categories_noneFound}</div>;

// We have to use lodash's `_.orderBy()` here since it's stable, and JS isn't guaranteed to have a stable sort algorithm
const sortCategories = (categories) => _orderBy(categories, [({ isChecked }) => (isChecked ? 1 : 0)], ['desc']);

const cache = new CellMeasurerCache({
  defaultHeight: 45,
  fixedWidth: true
});
class CategoryFilter extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    entity: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    allSuperUserCategories: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      categoriesForEntity: [],
      staticSearchCategories: []
    };
  }

  componentWillMount() {
    this.setCategories(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps;
    if (
      (filters.isClearingFilters && this.props.filters.category !== null) ||
      anyNotEq(['entity', 'location'], this.props, nextProps) ||
      this.props.categories !== nextProps.categories || // shallow equality for increased speed
      this.props.allSuperUserCategories !== nextProps.allSuperUserCategories // shallow equality for increased speed
    ) {
      this.setCategories(nextProps);
    }
  }

  setCategories = (props) => {
    const { allSuperUserCategories, app, categories, entity, filters, location, user } = props;

    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const parsedFilterParams = queryParams.filter && queryString.parse(JSON.parse(queryParams.filter));

    // intersection of client's categories and categoryIds
    const useAllCategories = _get(user, ['config', 'isStacklineSuperUser'], false) && app.name === AppName.Atlas;

    const categoriesForEntity = (useAllCategories ? allSuperUserCategories : categories)
      .filter(({ categoryId }) => _get(entity, 'categoryIds', []).includes(categoryId))
      .map((val) => {
        const isChecked =
          filters.category !== null &&
          parsedFilterParams &&
          parsedFilterParams.c &&
          // eslint-disable-next-line
          !!parsedFilterParams.c.find((item) => item.i == val.id);
        return { ...val, isChecked };
      });

    this.setState({
      staticSearchCategories: categoriesForEntity,
      categoriesForEntity: sortCategories(categoriesForEntity),
      searchText: ''
    });
  };

  handleCheck = (event, isInputChecked, index) => {
    const { onFilterChange } = this.props;
    const { categoriesForEntity, staticSearchCategories } = this.state;
    categoriesForEntity[index].isChecked = isInputChecked;
    staticSearchCategories.forEach((val) => {
      if (val.categoryId === categoriesForEntity[index].categoryId) {
        val.isChecked = isInputChecked;
      }
    });
    const values = staticSearchCategories.filter((val) => val.isChecked);
    this.setState({
      staticSearchCategories: [...staticSearchCategories],
      categoriesForEntity: [...categoriesForEntity]
    });
    onFilterChange(values, 'category');
  };

  handleInputChange = ({ target: { value } }) => {
    const lowerCaseVal = value.toLowerCase();
    const updatedCategories = this.state.staticSearchCategories.filter(({ categoryName }) =>
      categoryName.toLowerCase().includes(lowerCaseVal)
    );

    this.setState({
      searchText: value,
      categoriesForEntity: sortCategories(updatedCategories)
    });
  };

  rowRenderer = ({ index, style }) => {
    const { categoriesForEntity } = this.state;
    return (
      <ListFilterRow
        index={index}
        key={index}
        style={style}
        isChecked={categoriesForEntity[index].isChecked}
        value={categoriesForEntity[index].categoryName}
        onCheck={this.handleCheck}
      />
    );
  };

  render() {
    const { categoriesForEntity, staticSearchCategories, searchText } = this.state;
    if (staticSearchCategories && _isEmpty(staticSearchCategories)) {
      return null;
    }

    return (
      <div className="search-form-container search-form-container--sm">
        <TextField
          variant="standard"
          autoComplete="off"
          className="sl-form-input"
          placeholder="Search for categories"
          type="text"
          name="keyword"
          id="keyword"
          style={{ width: '100%' }}
          value={searchText}
          onChange={this.handleInputChange}
        />
        {categoriesForEntity && _isEmpty(categoriesForEntity) ? <NoCategoriesFound /> : null}
        {!_isEmpty(categoriesForEntity) ? (
          <AutoSizer disableHeight>
            {({ width }) => (
              <VirtualizedList
                width={width}
                height={300}
                rowCount={categoriesForEntity.length}
                rowHeight={cache.rowHeight}
                style={{ marginBottom: 10 }}
                rowRenderer={({ ...props }) => {
                  const { key, parent, index } = props;
                  return (
                    <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                      {this.rowRenderer(props)}
                    </CellMeasurer>
                  );
                }}
              />
            )}
          </AutoSizer>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, [
    'app',
    'allSuperUserCategories',
    'categories',
    'comparisonTimePeriod',
    'filters',
    'mainTimePeriod',
    'retailer',
    'user'
  ]);

export default withRouter(connect(mapStateToProps)(CategoryFilter));
