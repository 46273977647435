import React from 'react';
import PropTypes from 'prop-types';
import _identity from 'lodash/identity';
import { percentColor, formatPercent } from 'src/utils/metrics';

const cellContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 65
};

export const OmniProductCell = ({ formattedValue, percentChange }) => (
  <div style={cellContentStyle}>
    <div>{formattedValue}</div>
    {percentChange && (
      <div>
        <span style={{ color: percentColor(percentChange) }}>{formatPercent(percentChange)}</span>
      </div>
    )}
  </div>
);

OmniProductCell.propTypes = {
  formattedValue: PropTypes.node.isRequired,
  percentChange: PropTypes.string
};

export const mkOmniProductCellFormatter = (formatValue) => {
  const OmniProductCellFormatterInner = ({ data, colDef }) => {
    const { dataKey } = colDef;
    const value = data[dataKey];
    const percentChange = data[`${dataKey}PercentChange`];
    if (value) {
      return (
        <OmniProductCell
          formattedValue={formatValue(value)}
          percentChange={percentChange}
          key={`${dataKey}${value}${percentChange}`}
        />
      );
    } else {
      return <span>NA</span>;
    }
  };
  OmniProductCellFormatterInner.propTypes = {
    data: PropTypes.object.isRequired,
    colDef: PropTypes.object.isRequired
  };
  OmniProductCellFormatterInner.defaultProps = {};

  return OmniProductCellFormatterInner;
};

const OmniProductCellFormatter = mkOmniProductCellFormatter(_identity);

export default OmniProductCellFormatter;
