/* eslint-disable react/prop-types */
import React, { useMemo, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _constant from 'lodash/constant';
import _first from 'lodash/first';
import _ident from 'lodash/identity';
import _isNil from 'lodash/isNil';
import _isNumber from 'lodash/isNumber';
import _pick from 'lodash/pick';
import { Conditions } from 'sl-api-connector/types';
import { Option } from 'funfix-core';

import { buildAggregations, buildTimePeriodRangeFilters } from 'src/components/AdManager/Search';
import { store } from 'src/main';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { EntityColumn } from 'src/components/Grids/Data/ColumnTypes';
import { buildMetricValue } from 'src/utils/metrics';
import { prop } from 'src/utils/fp';
import './TopFiveGrid.scss';
import { MetricField } from 'src/types/application/types';
import ReduxStore from 'src/types/store/reduxStore';
import { RowDatum } from 'src/types/store/storeTypes';
import { WidgetProps } from 'src/types/application/widgetTypes';

export const fetchData = (
  dataKey: string,
  groupByField: MetricField,
  indexName: string,
  {
    app,
    retailer,
    mainTimePeriod,
    mainEntity
  }: Pick<ReduxStore, 'app' | 'retailer' | 'mainTimePeriod'> & {
    mainEntity: NonNullable<ReduxStore['entityService']['mainEntity']>;
  },
  queryConditions: Conditions,
  fields: MetricField[],
  pageSize: number = 20
) => {
  const [{ aggregations: aggregationFields }] = buildAggregations(fields);
  const { mainTimePeriodRangeFilters } = buildTimePeriodRangeFilters({
    app,
    indexName,
    mainTimePeriod
  });
  store.dispatch(
    fetchEntityMetrics(dataKey, { entity: mainEntity, retailer, app, indexName }, [
      {
        doAggregation: true,
        aggregations: [
          {
            aggregationFields,
            conditions: {
              termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
              rangeFilters: [...mainTimePeriodRangeFilters]
            },
            groupByFieldName: groupByField.name
          }
        ],
        conditions: { ...queryConditions, rangeFilters: [...mainTimePeriodRangeFilters] },
        pageSize
      }
    ])
  );
};

export interface CellRendererArgs {
  data: { [key: string]: any };
  colDef: { name?: string; field?: string };
}

export const mkCellRenderer =
  ({ field, retailer }: { field: MetricField; retailer: ReduxStore['retailer'] }) =>
  ({ data, colDef: { name } }: CellRendererArgs) => {
    const {
      prefix = '',
      value = '',
      suffix = ''
    } = buildMetricValue(
      data[name!],
      field.metricType!,
      retailer.currencySymbol,
      true,
      field.dataType!,
      retailer.locale
    );

    const formattedValue = `${prefix}${value}${suffix}`;
    return data.isBold ? `<span style="font-weight: 500;">${formattedValue}</span>` : formattedValue;
  };

const buildColDefs = ([firstCol, ...cols]: MetricField[], retailer: ReduxStore['retailer']): MetricField[] => [
  {
    displayName: '',
    name: 'rank',
    maxWidth: 61,
    valueFormatter: ({ data: { i } }) => (_isNil(i) ? null : i + 1)
  },
  {
    displayName: firstCol.displayName,
    name: firstCol.name,
    cellRendererFramework: EntityColumn,
    minWidth: 250,
    width: 250
  },
  ...cols.map((field) => ({
    ...field,
    maxWidth: 100,
    cellRenderer: mkCellRenderer({ field, retailer })
  }))
];

// Some metrics don't make sense being summed, such as Return on Ad Spend.  Instead of summing these for the other and
// total rows, we instead compute weighted averages of them using the fields specified in this mapping.
const weighByKeyByMetricFieldName = new Map(
  Object.entries({
    returnOnAdSpend: 'sales'
  })
);

const sumByKey = (items: { [key: string]: number }[]) =>
  items.reduce((acc, item) => {
    Object.entries(item).forEach(([key, val]) => {
      if (_isNumber(val)) {
        if (_isNil(acc[key])) {
          acc[key] = 0;
        }

        // If the metric is specified in `weighByKeyByMetricFieldName`, we weigh it by the value of the weigh-by key.
        const weighByKey = weighByKeyByMetricFieldName.get(key);
        if (weighByKey) {
          acc[key] += val * item[weighByKey];
        } else {
          acc[key] += val;
        }
      } else {
        acc[key] = val;
      }
    });
    return acc;
  }, {});

/**
 * For each of the entries of the supplied object, if the key is in the `weighByKeyByMetricFieldName` mapping,
 * its value is divided by the sum of the weigh-by key to produce the correct weighted average.
 */
const averageByKey = (sumsByKey: { [key: string]: number }) => {
  const averaged = Object.entries(sumsByKey).reduce((acc, [key, sum]) => {
    const weighByKey = weighByKeyByMetricFieldName.get(key);

    return {
      ...acc,
      [key]: weighByKey ? sum / sumsByKey[weighByKey] : sum
    };
  }, {});

  return { ...averaged, id: null };
};

export const parseDataSet = (metricData: { [dataKey: string]: { data: RowDatum[] } }, computeTotal: boolean = true) => {
  // Discard non-metrics entries and convert the data into a mapping from data key to array of row data
  const initialAcc: Map<string, RowDatum[]> = new Map();
  const entries = Object.entries(metricData).filter(([key]) => !key.includes('dataPointCount') && key !== 'apiRequest');
  const keys = entries.map(_first) as string[];
  const metrics = entries.reduce((acc, [key, val]) => {
    acc.set(key, val.data);
    return acc;
  }, initialAcc);

  // Flatten the fetched metrics into a single array of objects, with each object having keys for each metric
  const flattenedEntries = entries[0][1].data.map((_, i) => {
    // eslint-disable-next-line no-shadow
    const initialAcc: { [dataKey: string]: any } = {};
    return keys.reduce((acc, dataKey, metricNum) => {
      const datum = metrics.get(dataKey)![i];
      acc[dataKey.split('_by_')[0]] = datum.value;

      if (metricNum === 0) {
        acc.name = datum.cardView.secondaryDisplayTitle || 'Unknown';
        acc.entity = datum.entity;
        acc.id = Option.of(datum.entity).map(prop('id')).getOrElse(null);
      }

      return acc;
    }, initialAcc);
  });

  if (!computeTotal) {
    return [...flattenedEntries.map((rowDatum, i) => ({ ...rowDatum, i }))].filter(_ident) as typeof flattenedEntries;
  }

  const top5 = flattenedEntries.slice(0, 5);

  if (top5.length === 0) {
    return top5;
  }

  // Compute sums/averages for each of the metrics
  const totals = sumByKey(flattenedEntries);
  const top5Sum = sumByKey(top5);
  const other = Object.entries(totals)
    .filter(([_key, val]) => _isNumber(val))
    .reduce(
      (acc, [key, totalForMetric]) => ({
        ...acc,
        [key]: totalForMetric - top5Sum[key]
      }),
      {}
    );

  return [
    ...top5.map((rowDatum, i) => ({ ...rowDatum, i })),
    flattenedEntries.length > 5 && {
      // Compute weighted averages for any metrics that were summed up as such
      ...averageByKey(other),
      name: 'Other',
      isBold: true
    },
    {
      ...averageByKey(totals),
      name: 'Total',
      entity: null,
      isBold: true
    }
  ].filter(_ident) as typeof top5;
};

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['app', 'entitySearchService', 'mainTimePeriod', 'retailer']),
  mainEntity: state.entityService.mainEntity
});

export const updateGridSize = (params: any) => {
  if (!params) {
    console.warn('No `params` returned from top N grid `onGridReady`');
    return;
  }
  params.api.sizeColumnsToFit();
};

const TopFiveGrid: React.FC<WidgetProps & ReturnType<typeof mapStateToProps>> = ({
  widget,
  app,
  entitySearchService,
  mainTimePeriod,
  retailer,
  mainEntity,
  ...widgetProps
}) => {
  const dataKey = `topFiveGrid-${widget.data.groupByField.name}-${widget.name}`;
  const { groupByField, metricFields, indexName } = widget.data;

  const dataSet = entitySearchService[dataKey];

  const lastMainTimePeriodId = useRef<string | null>(null);
  const needsRefetch = lastMainTimePeriodId.current !== mainTimePeriod.id;
  useEffect(() => {
    // Avoid re-fetching data if we've already got it.
    if (dataSet && !needsRefetch) {
      return;
    }
    lastMainTimePeriodId.current = mainTimePeriod.id;

    fetchData(
      dataKey,
      groupByField,
      indexName,
      { app, retailer, mainTimePeriod, mainEntity: mainEntity! },
      widgetProps.conditions,
      metricFields
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKey, mainTimePeriod, widget.data.groupByField]);
  const colDefs = useMemo(
    () => buildColDefs([groupByField, ...metricFields], retailer),
    [groupByField, retailer, metricFields]
  );

  const rows = useMemo(() => (dataSet ? parseDataSet(dataSet) : null), [dataSet]);

  if (!rows) {
    return <GenericChartLoading />;
  }

  return (
    <div className="top-5-grid">
      <CustomAgMaterial
        columnDefs={colDefs}
        buildRows={_constant(rows)}
        domLayout="autoHeight"
        containerStyle={{ height: 450, width: 615 }}
        onGridReady={updateGridSize}
        onCellValueChanged={updateGridSize}
        onModelUpdated={updateGridSize}
        onRowValueChanged={updateGridSize}
        onRowDataChanged={updateGridSize}
      />
    </div>
  );
};

const EnhancedTopFiveGrid = connect(mapStateToProps)(TopFiveGrid);

export default EnhancedTopFiveGrid;
