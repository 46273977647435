/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import moment from 'moment-timezone';
import { AppName } from 'sl-api-connector';
import { connect } from 'react-redux';
import { Option } from 'funfix-core';
import _isNil from 'lodash/isNil';
import { withStyles } from '@mui/styles';
import queryString from 'qs';
import colors from 'src/utils/colors';
import ReduxStore from 'src/types/store/reduxStore';
import { withLocation } from 'src/utils/hoc';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import GenericDialog from 'src/components/common/Dialog/GenericDialog';
import Select from 'src/components/common/Form/Select';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import {
  displayNameByFieldName,
  getExportGroupByFieldNames,
  getDoubleAggregatableFieldNames,
  getCompTimePeriodEnabled
} from './exportGroupByFields';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { propEq } from 'src/utils/fp';
import { disabledWeekly } from 'src/components/EntityPage/Renderer/util';
import { isCriteo, shouldShowCriteo } from 'src/utils/app';
import OmniContentExportGroup from 'src/components/Omni/OmniExportService/OmniContentExportGroup';
import OmniExportGroupBy, { OmniSelectedField } from 'src/components/Omni/OmniExportService/OmniExportGroupBy';
import OmniShareOfShelfExportGroup from 'src/components/Omni/OmniExportService/OmniShareOfShelfExportGroup';

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    paddingLeft: 40,
    paddingRight: 40,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 380,
    width: 550,
    color: colors.darkBlue
  }
};

export const ColoredCheckbox = withStyles({
  root: {
    color: colors.darkBlue,
    '&$checked': {
      color: colors.darkBlue
    }
  },
  checked: {}
})(Checkbox);

const getSelectItemsForEntityType = (args: {
  appName: AppName;
  entityType: string;
  tab: string;
  subtab: string;
}): { selectItems: { displayName: string; id: string; value: string }[]; isAggregatable: boolean } => {
  const { groupByFieldNames, isAggregatable } = getExportGroupByFieldNames(args);
  return {
    selectItems: (groupByFieldNames || []).map((fieldName) => ({
      displayName: displayNameByFieldName.get(fieldName) || fieldName,
      id: fieldName,
      value: fieldName
    })),
    isAggregatable
  };
};

export const isFullMonthRange = (_start: string, _end: string): boolean => {
  const startDate = moment(_start);
  const endDate = moment(_end);
  // Check if the input dates are valid
  if (!startDate.isValid() || !endDate.isValid()) {
    return false;
  }
  // Check if the start date is the first day of the month
  const isFirstDay = startDate.date() === 1;
  // Check if the end date is the last day of the month
  const isLastDay = endDate.date() === endDate.daysInMonth();
  return isFirstDay && isLastDay;
};

const AdManagerGroupby = ({
  classes,
  setValue,
  mainEntity,
  retailer,
  entityLocation
}: {
  classes: any;
  setValue: Function;
  mainEntity: any;
  retailer: any;
  entityLocation: any;
}) => {
  const isInstacartRetailer = /instacart/i.test(retailer.name);
  const targetID = isInstacartRetailer ? 'targetingText,targetingType,isAutoAdded' : 'targetingText,targetingType';
  const isAllRetailers = retailer.id === '0';
  const isCriteoAllowed = shouldShowCriteo();

  let firstOpt = [
    {
      displayName: 'Organization',
      id: 'retailerId',
      value: 'retailerId'
    },
    {
      displayName: 'Entity',
      id: 'entityId',
      value: 'entityId'
    },
    {
      displayName: 'Portfolio',
      id: 'portfolioId',
      value: 'portfolioId'
    },
    {
      displayName: 'Campaign',
      id: 'campaignId',
      value: 'campaignId'
    },
    {
      displayName: 'Product',
      id: 'stacklineSku',
      value: 'stacklineSku'
    },
    {
      displayName: 'Target',
      id: targetID,
      value: targetID
    }
  ];

  const fullSecondOpt = [
    {
      displayName: 'Organization',
      id: 'retailerId',
      value: 'retailerId'
    },
    {
      displayName: 'Entity',
      id: 'entityId',
      value: 'entityId'
    },
    {
      displayName: 'Portfolio',
      id: 'portfolioId',
      value: 'portfolioId'
    },
    {
      displayName: 'Ad Type',
      id: 'derivedCampaignType',
      value: 'derivedCampaignType'
    },
    {
      displayName: 'Campaign',
      id: 'campaignId',
      value: 'campaignId'
    },
    {
      displayName: 'Brand',
      id: 'brandId',
      value: 'brandId'
    },
    {
      displayName: 'Category',
      id: 'categoryId',
      value: 'categoryId'
    },
    {
      displayName: 'Subcategory',
      id: 'subCategoryId',
      value: 'subCategoryId'
    },
    {
      displayName: 'Product',
      id: 'stacklineSku',
      value: 'stacklineSku'
    },
    {
      displayName: 'Target',
      id: targetID,
      value: targetID
    }
  ];

  if (isCriteo() && isCriteoAllowed) {
    firstOpt = [
      {
        displayName: 'Organization',
        id: 'retailerId',
        value: 'retailerId'
      }
    ];
    if (isAllRetailers) {
      firstOpt.push(
        {
          displayName: 'Account',
          id: 'entityId',
          value: 'entityId'
        },
        {
          displayName: 'Campaign',
          id: 'campaignId',
          value: 'campaignId'
        }
      );
    }
    firstOpt.push(
      {
        displayName: 'Line Items',
        id: 'adGroupId',
        value: 'adGroupId'
      },
      {
        displayName: 'Product',
        id: 'stacklineSku',
        value: 'stacklineSku'
      },
      {
        displayName: 'Target',
        id: targetID,
        value: targetID
      }
    );
  }

  // add ad group after campaign
  if (isInstacartRetailer) {
    const campaign_index = firstOpt.findIndex((item) => item.displayName === 'Campaign');
    firstOpt.splice(campaign_index + 1, 0, {
      displayName: 'Ad Group',
      id: 'adGroupId',
      value: 'adGroupId'
    });
  }

  const getEntityForSelection = () => {
    if (entityLocation.includes('overview')) {
      return 'retailerId';
    } else if (entityLocation.includes('adEntity')) {
      return 'entityId';
    } else if (entityLocation.includes('adPortfolio')) {
      return 'portfolioId';
    } else if (entityLocation.includes('adCampaign')) {
      return 'campaignId';
    } else if (entityLocation.includes('adGroup')) {
      return isInstacartRetailer ? 'adGroupId' : 'campaignId';
    } else if (entityLocation.includes('product')) {
      return 'stacklineSku';
    } else if (entityLocation.includes('adTarget')) {
      return targetID;
    } else {
      return 'retailerId';
    }
  };

  const decidingEntity = getEntityForSelection();
  // now let's remove from the firstOpt
  const firstIndex = firstOpt.findIndex((item) => item.id === decidingEntity);
  firstOpt = firstOpt.slice(firstIndex);

  const [firstGroupByValue, setFirstGroupByValue] = useState(firstOpt[0].value);
  const [secondGroupByValue, setSecondGroupByValue] = useState('none');
  const index = fullSecondOpt.findIndex((item) => item.value === firstGroupByValue);
  let secondOpt = [
    {
      displayName: 'None',
      id: 'none',
      value: 'none'
    },
    ...fullSecondOpt.slice(index + 1)
  ];

  const shouldFilterOutOption = (first: string, second: string) => {
    if (first === 'retailerId') {
      if (isCriteo() && isCriteoAllowed) {
        return !['none'].includes(second);
      }
      return ['portfolioId', 'campaignId', 'stacklineSku', targetID].includes(second);
    }
    if (first === 'entityId') {
      return ['portfolioId', 'campaignId'].includes(second);
    }
    if (first === 'portfolioId') {
      return second === 'campaignId';
    }
    if (first === 'adGroupId') {
      return !['none', 'stacklineSku', targetID].includes(second);
    }
    return false;
  };

  secondOpt = secondOpt.filter((opt) => !shouldFilterOutOption(firstGroupByValue, opt.value));

  const handleGroupByChange = (firstValue: string, secondValue: string) => {
    const firstIdx = fullSecondOpt.findIndex((item) => item.value === firstValue);
    const secondIdx = fullSecondOpt.findIndex((item) => item.value === secondValue);
    let secondValueToUse = secondValue;
    if (shouldFilterOutOption(firstValue, secondValue) || firstIdx >= secondIdx) {
      secondValueToUse = 'none';
    }
    setFirstGroupByValue(firstValue);
    setSecondGroupByValue(secondValueToUse);
  };

  useEffect(() => {
    const combineValue = `${firstGroupByValue}${secondGroupByValue === 'none' ? '' : `,${secondGroupByValue}`}`;
    setValue(combineValue);
  }, [firstGroupByValue, secondGroupByValue, setValue]);

  useEffect(() => {
    if (mainEntity.type === 'segment') {
      const segmentQuery = JSON.parse(mainEntity.query);
      let { segmentType } = segmentQuery;
      if (segmentType === 'targetingText') {
        segmentType = targetID;
      }
      setFirstGroupByValue(segmentType);
      setSecondGroupByValue('none');
    }
  }, [mainEntity, targetID]);

  const disableSelect = mainEntity.type === 'segment';
  return (
    <>
      <div className="setting_row">
        <div className="label" style={{ width: 100 }}>
          Group by 1:
        </div>
        <div>
          <Select
            items={firstOpt}
            disabled={disableSelect}
            value={firstGroupByValue}
            onChange={(evt: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
              handleGroupByChange(evt.target.value as string, secondGroupByValue)
            }
            style={{ width: 300 }}
            classes={classes}
          />
        </div>
      </div>
      <div className="setting_row">
        <div className="label" style={{ width: 100 }}>
          Group by 2:
        </div>
        <div>
          <Select
            items={secondOpt}
            disabled={disableSelect}
            value={secondGroupByValue}
            onChange={(evt: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
              handleGroupByChange(firstGroupByValue, evt.target.value as string)
            }
            style={{ width: 300 }}
            classes={classes}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxStore) => ({
  mainEntity: state.entityService.mainEntity,
  mainTimePeriod: state.mainTimePeriod,
  appName: state.app.name,
  app: state.app,
  retailer: state.retailer
});

const ExportDialog: React.FC<
  {
    open: boolean;
    onClose: () => void;
    doExport: (entityType: string, includeCompTimePeriod: boolean) => void;
    doExportForOmni: (opt: OmniSelectedField) => void;
    classes: { [key: string]: any };
    location: RouteComponentProps['location'];
  } & ReturnType<typeof mapStateToProps>
> = ({
  open,
  onClose,
  doExport,
  mainEntity,
  appName,
  classes,
  location,
  retailer,
  mainTimePeriod,
  app,
  doExportForOmni
}) => {
  const [aggByWeeks, setAggByWeeks] = useState(true);
  const [includeCompTimePeriod, setIncludeCompTimePeriod] = useState(false);
  const [adManagerAggregate, setAdManagerAggregate] = useState('');
  const [omniFieldValue, setOmniFieldValue] = useState({});
  const { tab, subtab, wr, edid, sdid } = useMemo(
    () =>
      queryString.parse(location.search, {
        ignoreQueryPrefix: true,
        arrayLimit: 100
      }),
    [location.search]
  );

  const selectConfig = useMemo(
    () => (mainEntity ? getSelectItemsForEntityType({ appName, entityType: mainEntity.type, tab, subtab }) : null),
    [appName, mainEntity, tab, subtab]
  );
  // Use a default value of "Product" if an option for that exists
  const [value, setValue] = useState<string | null>(
    Option.of(selectConfig)
      .flatMap(({ selectItems }) =>
        Option.of(selectItems.find(propEq('id', 'stacklineSku'))).orElse(Option.of(selectItems[0]))
      )
      .map(({ id }) => id)
      .orNull()
  );
  const canAggByWeeks = useMemo(() => getDoubleAggregatableFieldNames(appName).has(value), [value, appName]);
  const disableComparisonTimePeriod = useMemo(
    () => !getCompTimePeriodEnabled({ appName, tab, subtab }),
    [appName, tab, subtab]
  );

  const forceWeekSeparation = tab === 'buybox' && subtab === 'sellers';
  const disableCompare =
    (value &&
      value.includes('targetingText,targetingType') &&
      value !== 'stacklineSku,targetingText,targetingType' &&
      value !== 'targetingText,targetingType') === true;

  useEffect(() => {
    if (disableCompare) {
      setAdManagerAggregate('');
      setIncludeCompTimePeriod(false);
    }
  }, [disableCompare]);

  if (!selectConfig || _isNil(value)) {
    return <GridLoading />;
  }

  const { selectItems, isAggregatable } = selectConfig;

  const aggByWeekDisabled = !canAggByWeeks || !isAggregatable;

  const parseMomentDate = (x: number) => moment(x.toString(), 'YYYYMMDD');
  const dateDifference = parseMomentDate(mainTimePeriod.endDayId).diff(
    parseMomentDate(mainTimePeriod.startDayId),
    'days'
  );

  const disableGroupByMonthId = ['1w'].includes(mainTimePeriod.id) || dateDifference < 27;
  const disableGroupByDayId =
    [
      'stacklineSku,targetingText,targetingType',
      'targetingText,targetingType,isAutoAdded',
      'targetingText,targetingType'
    ].includes(value) && dateDifference > 30;
  const disableComparisionForTargetAsKey = value && value.startsWith('target');
  const disableCheckBox = disableComparisonTimePeriod || disableComparisionForTargetAsKey;
  const disableWeekly = disabledWeekly(wr, sdid, edid);

  const OmniGroupByUse = (tabValue: string) => {
    switch (tabValue) {
      case 'shareOfShelf':
        return (
          <OmniShareOfShelfExportGroup
            classes={classes}
            setShareOfShelfSelection={(omniSelectedField: OmniSelectedField) => {
              setOmniFieldValue({ ...omniFieldValue, ...omniSelectedField });
            }}
            entityType={mainEntity.type}
          />
        );

      case 'content':
        return (
          <OmniContentExportGroup
            classes={classes}
            setShareOfShelfSelection={(omniSelectedField: OmniSelectedField) => {
              setOmniFieldValue({ ...omniFieldValue, ...omniSelectedField });
            }}
          />
        );
      case 'reviews':
        return (
          <OmniContentExportGroup
            classes={classes}
            setShareOfShelfSelection={(omniSelectedField: OmniSelectedField) => {
              setOmniFieldValue({ ...omniFieldValue, ...omniSelectedField });
            }}
          />
        );

      default:
        return (
          <OmniExportGroupBy
            classes={classes}
            setOmniSelectedValue={(omniSelectedField: OmniSelectedField) => {
              setOmniFieldValue({ ...omniFieldValue, ...omniSelectedField });
            }}
          />
        );
    }
  };
  return (
    <GenericDialog title={null} open={open || false} onClose={onClose} showCloseButton={false} style={styles.root}>
      <h1 style={{ marginBottom: 0 }}>Export</h1>
      <hr className="sl-divider" style={{ width: '100%', marginTop: 10, marginBottom: 20 }} />
      <div className="setting_container">
        {appName === 'advertising' ? (
          <AdManagerGroupby
            classes={classes}
            setValue={setValue}
            mainEntity={mainEntity}
            entityLocation={location.pathname}
            retailer={retailer}
          />
        ) : appName === 'omni' ? (
          OmniGroupByUse(tab)
        ) : (
          <div className="setting_row">
            <div className="label" style={{ width: 100 }}>
              Group by:
            </div>
            <div>
              <Select
                items={selectItems}
                value={value}
                onChange={(evt: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                  setValue(evt.target.value as string)
                }
                style={{ width: 300 }}
                classes={classes}
              />
            </div>
          </div>
        )}

        {appName === 'advertising' && (
          <div className="setting_row" style={{ alignItems: 'flex-start' }}>
            <div className="label" style={{ width: 100, marginTop: 7 }}>
              Aggregate:
            </div>
            <div>
              <RadioGroup
                style={{ display: 'flex', flexDirection: 'row' }}
                aria-label="gender"
                name="gender1"
                value={adManagerAggregate}
                onChange={(event) => setAdManagerAggregate(event.target.value as string)}
              >
                <FormControlLabel value="" control={<Radio color="primary" />} label="None" />
                <FormControlLabel
                  value="monthId"
                  control={<Radio color="primary" />}
                  label="Monthly"
                  disabled={disableGroupByMonthId}
                />
                <FormControlLabel
                  value="weekId"
                  control={<Radio color="primary" />}
                  label="Weekly"
                  disabled={disableWeekly}
                />
                <FormControlLabel
                  disabled={disableGroupByDayId}
                  value="dayId"
                  control={<Radio color="primary" />}
                  label="Daily"
                />
              </RadioGroup>
            </div>
          </div>
        )}

        {appName !== 'omni' && (
          <div className="setting_row" style={{ alignItems: 'flex-start' }}>
            <div className="label" style={{ width: 100, marginTop: 7 }}>
              Customize:
            </div>
            <div>
              {!aggByWeekDisabled && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <ColoredCheckbox
                    checked={disableComparisonTimePeriod || (!aggByWeekDisabled && aggByWeeks) || forceWeekSeparation}
                    onChange={() => setAggByWeeks((prevVal) => !prevVal)}
                    disabled={aggByWeekDisabled || disableComparisonTimePeriod || disableCompare || forceWeekSeparation}
                    style={{ marginLeft: -9 }}
                    id="weekId-checkbox"
                  />
                  <div
                    style={{
                      paddingTop: 9,
                      color: aggByWeekDisabled || disableComparisonTimePeriod ? colors.lightGrey : undefined
                    }}
                  >
                    Separate data by week
                  </div>
                </div>
              )}
              <div style={{ marginTop: -4, display: 'flex', flexDirection: 'row' }}>
                <ColoredCheckbox
                  checked={!disableComparisonTimePeriod && includeCompTimePeriod}
                  disabled={disableCheckBox}
                  onChange={() => setIncludeCompTimePeriod((prevVal) => !prevVal)}
                  style={{ marginLeft: -9 }}
                  id="comparison-checkbox"
                />
                <div style={{ paddingTop: 9, color: disableCheckBox ? colors.lightGrey : undefined }}>
                  Include comparison time period
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="setting_row">
          <LargeMuiButton
            label="Cancel"
            secondary
            onClick={() => {
              onClose();
            }}
            style={{ marginTop: 40, marginRight: 20 }}
          />

          <LargeMuiButton
            label="Export"
            onClick={() => {
              if (app.shouldUseOmniExport) {
                doExportForOmni(omniFieldValue);
              } else {
                const groupByFieldName = adManagerAggregate
                  ? `${value},${adManagerAggregate}`
                  : isAggregatable && canAggByWeeks && aggByWeeks
                  ? `${value},weekId`
                  : value;
                doExport(groupByFieldName, includeCompTimePeriod);
              }
            }}
            style={{ marginTop: 40 }}
          />
        </div>
      </div>
    </GenericDialog>
  );
};

export default withLocation(
  withStyles({
    select: { fontSize: 16, width: 300, maxWidth: 300 },
    root: { width: 300, display: 'block', maxWidth: 300 }
  })(connect(mapStateToProps)(ExportDialog))
);
