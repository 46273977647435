import React from 'react';
import { styled, keyframes } from '@mui/material';
import TaskIcon from '@mui/icons-material/Task';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import _get from 'lodash/get';
import { InvoiceSummaryResponse, ShipmentSummaryResponse } from 'src/components/ShortageDisputes/responseTypes';
import {
  ClipboardChecklist,
  PackageCheckmark,
  DocumentChecklist,
  PackageDolly,
  DocumentCornerFold,
  DocumentSubmitted,
  SpeechBubbleCheckmark,
  ClipboardCheckmark
} from 'src/components/SvgIcons/SvgIcons';
import { Text } from '@stackline/ui';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { formatTime } from 'src/utils/dateformatting';

const pulse = keyframes`
    from {
        transform: scale(0);
        opacity: .9;
    } to {
        transform: scale(3.5);
        opacity: 0;
    }
`;

const StageContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: '1',
  justifyContent: 'center'
});

const OuterPoint = styled('div')({
  display: 'flex',
  position: 'absolute',
  zIndex: '1',
  alignItems: 'center',
  justifyContent: 'center',
  width: '8px',
  height: '8px',
  borderRadius: '20px',
  backgroundColor: '#b5dcfb'
});

const InnerPoint = styled(OuterPoint)({
  backgroundColor: 'black',
  width: '4px',
  height: '4px'
});

const ConnectorUp = styled('div')({
  height: '35px',
  width: '1px',
  backgroundColor: 'black',
  position: 'relative',
  alignSelf: 'end',
  top: '-4px'
});

const Point = ({ active }: { active: boolean }) => {
  const animation = active ? { animation: `${pulse} 1s infinite ease-in-out` } : {};

  return (
    <OuterPoint>
      <ConnectorUp />
      <InnerPoint sx={animation} />
    </OuterPoint>
  );
};

const TopContainer = styled('div')({
  display: 'flex',
  position: 'absolute',
  top: '16px',
  flexDirection: 'column'
});

interface StageParameters {
  title: string;
  description?: string;
  date: any;
  active?: boolean;
  completed?: boolean;
  start?: boolean;
  end?: boolean;
  icon?: any;
}

const Stage = ({
  title,
  description,
  date,
  active = false,
  completed = false,
  start = false,
  end = false,
  icon = <TaskIcon sx={{ fontSize: '40px' }} />
}: StageParameters) => {
  const value = active || completed ? 100 : 0;

  // Our first and last stage in the lifecycle should have it's progress bar styled according to its position at the start or end
  const getBorderRadius = () => {
    if (start) {
      return { borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' };
    }
    if (end) {
      return { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' };
    }
    return {};
  };

  const borderRadius = getBorderRadius();

  return (
    <StageContainer>
      <TopContainer>{icon}</TopContainer>

      <div style={{ display: 'flex', height: '10%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <Point active={active}></Point>
        <Box sx={{ width: '100%' }}>
          <LinearProgress
            variant="determinate"
            value={value}
            sx={{
              '&.MuiLinearProgress-root': {
                height: `${!active && !completed ? '1px' : '3px'}`,
                position: 'relative',
                alignItems: 'center',
                backgroundColor: `${!active && !completed ? 'black' : 'transparent'}`,
                display: 'flex',
                ...borderRadius
              },
              '.MuiLinearProgress-bar': {
                position: 'relative',
                backgroundColor: '#b5dcfb',
                height: '3px',
                ...borderRadius
              }
            }}
          />
        </Box>
      </div>

      <Flex
        whiteSpace="break-spaces"
        top="122px"
        width="90%"
        textAlign="center"
        position="absolute"
        flexDirection="column"
        gap="sm"
      >
        <Box height="auto">
          <Text variant="subtitle3">{title}</Text>
        </Box>
        <Box height="14px">
          <Text variant="subtitle3">{date ? formatTime(date, 'M/DD/YYYY') : ''}</Text>
        </Box>
        <Text variant="body4">{description}</Text>
      </Flex>
    </StageContainer>
  );
};

const LifeCycleContainer = styled('div')({
  display: 'flex',
  height: '180px',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  position: 'relative',
  marginBottom: '64px',
  marginTop: '32px'
});

const stageConfigs = [
  {
    start: true,
    title: 'PO \nIssued Date',
    description: 'Date in which Amazon transmitted the PO to the vendor',
    dateKey: 'poOrderedOnDate',
    icon: <ClipboardChecklist style={{ width: '27px' }} />
  },
  {
    title: 'PO \nConfirmation Date',
    description: 'Date in which the vendor confirmed the PO',
    dateKey: 'poOrderedOnDate',
    icon: <PackageCheckmark style={{ width: '27px' }} />
  },
  {
    title: 'ASN \nSubmission Date',
    description: 'Date the vendor submitted the ASN to Amazon',
    dateKey: 'shipmentAsnCreationDate',
    icon: <DocumentChecklist style={{ width: '27px' }} />
  },
  {
    title: 'Invoice \nCreated Date',
    description: 'Initial date that inventory was received at Amazon',
    dateKey: 'invoiceCreationDate',
    icon: <DocumentCornerFold style={{ width: '27px' }} />
  },
  {
    title: 'Inventory \nReceived Date',
    description: 'Invoice creation date',
    dateKey: 'shipmentDeliveryDate',
    icon: <PackageDolly style={{ width: '27px' }} />
  },
  {
    title: 'Initial Dispute \nSubmitted Date',
    description: 'Date the initial dispute was submitted on VC',
    dateKey: 'disputeSubmissionDate',
    icon: <DocumentSubmitted style={{ width: '27px' }} />
  },
  {
    title: 'Contact Us \nSubmission Date',
    description: 'Date the contact us case was submitted on VC',
    dateKey: 'contactUsCreationDate',
    icon: <SpeechBubbleCheckmark style={{ width: '27px' }} />
  },
  {
    title: 'Resolved \nDate',
    description: 'Date the contact us case was resolved on VC',
    dateKey: 'finalDisputeResolutionDate',
    icon: <ClipboardCheckmark style={{ width: '27px' }} />,
    end: true
  }
];

export const LifecycleTimeline = ({ data }: { data: InvoiceSummaryResponse & ShipmentSummaryResponse }) => {
  const dateKeysAndValues = [
    { key: 'poOrderedOnDate', value: 2 },
    { key: 'shipmentAsnCreationDate', value: 3 },
    { key: 'invoiceCreationDate', value: 4 },
    { key: 'shipmentDeliveryDate', value: 5 },
    { key: 'disputeSubmissionDate', value: 6 },
    { key: 'contactUsCreationDate', value: 7 },
    { key: 'finalDisputeResolutionDate', value: 8 }
  ];

  let currentStageIndex = 1;
  const dates = dateKeysAndValues.reduce((acc, { key, value }) => {
    const date = _get(data, [key], null);
    if (date) {
      currentStageIndex = Math.max(currentStageIndex, value);
    }
    acc[key] = date;
    return acc;
  }, {});

  return (
    <LifeCycleContainer>
      {stageConfigs.map((stage, index) => (
        <Stage
          key={index}
          {...stage}
          date={dates[stage.dateKey]}
          completed={index + 1 < currentStageIndex}
          active={index + 1 === currentStageIndex}
        />
      ))}
    </LifeCycleContainer>
  );
};
