/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { NoDataPlaceHolderTableRow } from 'src/components/BeaconRedesignComponents/common/NoDataPlaceHolder/NoDataPlaceHolder';
import { EXPORT_DOWNLOAD_PAGE_SIZE, useExportContext } from 'src/providers/ExportContextProvider';
import { useAppSelector } from 'src/utils/Hooks';
import { formatUnixToTime } from 'src/utils/dateformatting';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { useStacklineTheme } from '@stackline/ui';
import { ExportDataItem } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/utils';
import { trackMixpanelEvent } from 'src/utils/mixpanel';

const enum ExportStatus {
  'finished successfully' = 'Download',
  'failed with error' = 'Failed',
  'queued' = 'Processing',
  'in progress' = 'Processing'
}

const onDownloadCellClick = (row: ExportDataItem, targetUrl: string) => {
  try {
    const { requestId, fileName, status } = row;
    if (status === 'finished successfully') {
      const fileUrl = `https://${targetUrl}/api/user/DownloadExportFile?requestId=${requestId}`;
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = fileUrl;
      anchor.download = fileName;
      anchor.click();
    }
  } catch (error) {
    const { requestId, fileName, status } = row;
    console.error('Error:: Downloading normal export file - ', error);
    trackMixpanelEvent({
      eventName: 'error download normal export file',
      data: {
        fileUrl: `https://${targetUrl}/api/user/DownloadExportFile?requestId=${requestId}`,
        fileName,
        status
      }
    });
  }
};

const DownloadCell = ({
  value,
  row,
  numberOfDownloads
}: {
  value: string;
  row: ExportDataItem;
  numberOfDownloads: number;
}) => {
  const theme = useStacklineTheme();
  const [shouldShowNotifications, setShouldShowNotifications] = useState(false);
  const isFinishedSuccessfully = value === 'finished successfully';
  const textDecoration = isFinishedSuccessfully ? '' : 'none';

  useEffect(() => {
    const downloaded = numberOfDownloads && numberOfDownloads !== 0;
    if (!downloaded && isFinishedSuccessfully) {
      setShouldShowNotifications(true);
    }
  }, [isFinishedSuccessfully, numberOfDownloads]);

  const { targetUrl } = useAppSelector((state) => state.app);

  const handleDownloadButtonClick = () => {
    setShouldShowNotifications(false);
    onDownloadCellClick(row, targetUrl);
  };

  return (
    <Flex style={{ alignItems: 'center' }}>
      <a style={{ textDecoration }}>
        <div onClick={() => handleDownloadButtonClick()} role={isFinishedSuccessfully ? 'button' : 'cell'}>
          <Text variant="body2">{ExportStatus[value]}</Text>
        </div>
      </a>
      <div
        style={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: theme.colors.primary,
          marginLeft: '8px',
          display: shouldShowNotifications ? 'block' : 'none'
        }}
      ></div>
    </Flex>
  );
};

const downloadColumnDefinition = [
  {
    field: 'fileName',
    headerName: 'FILE NAME',
    tableColumnProps: {
      style: {
        width: '530px'
      }
    },
    valueFormatter: (value) => {
      const fileNameWithoutExtension = value.replace('.csv', '');
      return (
        <Flex paddingLeft="8px">
          <Text variant="body2">{fileNameWithoutExtension.substring('.csv')}</Text>
        </Flex>
      );
    }
  },
  {
    field: 'requestedTime',
    headerName: 'DATE',
    tableColumnProps: {
      style: {
        width: '221px'
      }
    },
    valueFormatter: (value) => {
      return (
        <Flex>
          <div>
            <Text variant="body2">{formatUnixToTime(value, 'M/D/YY')}</Text>
          </div>
        </Flex>
      );
    }
  },
  {
    field: 'status',
    headerName: 'STATUS',
    valueFormatter: (value: string, row: ExportDataItem) => {
      return <DownloadCell value={value} numberOfDownloads={row.numberOfDownloads} row={row} />;
    }
  }
];

const ExportDownloadTable = () => {
  const { downloadList, setShouldFetchDownloadList, isDownloadLoading, setDownloadList } = useExportContext();
  const COL_DEFS = downloadColumnDefinition;

  useEffect(() => {
    setShouldFetchDownloadList(true);
  }, [setShouldFetchDownloadList]);

  useEffect(() => {
    setDownloadList(null);
    return () => {
      setDownloadList(null);
    };
  }, []);

  return (
    <EntityTable
      isLoading={isDownloadLoading}
      rowData={downloadList || []}
      columnDefs={COL_DEFS}
      shouldModifyColumnDefs={false}
      initialPageSize={EXPORT_DOWNLOAD_PAGE_SIZE}
      showPagination={false}
      skeletonRows={EXPORT_DOWNLOAD_PAGE_SIZE}
      appTableProps={{
        noDataPlaceholder: (
          <NoDataPlaceHolderTableRow
            title="No downloads yet"
            description="After downloading files from your Beacon account, visit this page to access them."
          />
        )
      }}
    />
  );
};

export default ExportDownloadTable;
