import types from './types';
import actionCreators from './actions';
import { ValueOf } from 'sl-api-connector';

type Action = ValueOf<{ [K in keyof typeof actionCreators]: ReturnType<(typeof actionCreators)[K]> }>;

export function adAutomationStrategies(state = [], action: Action) {
  switch (action.type) {
    case types.RECEIVE_AD_AUTOMATION_STRATEGIES:
      return action.adAutomationStrategies;
    case types.CLEAR_AD_AUTOMATION_STRATEGIES:
      return [];
    default:
      return state;
  }
}
