import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import ForecastSummaryPage from './Forecasts/ForecastSummaryPage';

const getForecastSummaryPageLayout = () => {
  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    widgets: [
      {
        name: 'forecastSummaryPage',
        CustomComponent: ForecastSummaryPage,
        view: {}
      }
    ],
    containerStyle: {},
    dataConfig: {}
  };
};

export default getForecastSummaryPageLayout;
