import { COMMON_ENTITIES } from 'src/utils/entityDefinitions/entities/commonEntities';

export const ADVERTISING_ENTITIES = {
  ...COMMON_ENTITIES,
  adCampaignAdGroupProduct: {
    id: -1,
    keyFieldName: 'stacklineSku',
    nameFieldName: 'title',
    type: 'adCampaignAdGroupProduct',
    displayName: 'Product',
    displayNamePlural: 'Products',
    imageUrl: (id: string | number) => {
      // if the id is a parent sku, we go to different image folder
      if (id && `${id}`.indexOf('_') > -1) {
        return `https://s3-us-west-2.amazonaws.com/stackline-product-images/parent-products/${id}.jpg`;
      }
      return `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`;
    }
  },
  adCampaignAdGroupTarget: {
    id: -1,
    keyFieldName: 'targetingText',
    nameFieldName: 'targetingText',
    type: 'adCampaignAdGroupTarget',
    displayName: 'Ad Target',
    displayNamePlural: 'Ad Targets'
  },
  adOptimizationHistoryItem: {
    id: -1,
    nameFieldName: 'change_reason',
    type: 'adOptimizationHistoryItem',
    displayName: 'Ad Optimization History Item',
    displayNamePlural: 'Ad Optimization History Items'
  }
};
