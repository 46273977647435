// import { Parser } from 'expr-eval';

import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

import { Parser } from 'expr-eval';

const expressionParser = new Parser();

// const expressionParser = new Parser();

export const CHARGEBACK_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  financialCharge: {
    name: 'financialCharge',
    displayName: 'Financial Charge',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  prepCharges: {
    name: 'prepCharges',
    displayName: 'Prep Charges',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  logisticCharges: {
    name: 'logisticCharges',
    displayName: 'Logistic Charges',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  labelingCharges: {
    name: 'labelingCharges',
    displayName: 'Labeling Charges',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  otherCharges: {
    name: 'otherCharges',
    displayName: 'Other Charges',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  retailSales: {
    name: 'retailSales',
    displayName: 'Chargeback Retail Sales',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  chargebackRate: {
    name: 'chargebackRate',
    displayName: 'Chargeback Rate',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('financialCharge>0?financialCharge/retailSales:0')
  },
  quantity: {
    name: 'quantity',
    displayName: 'Quantity',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  }
};

export const CHARGEBACK_METADATA_FIELDS: { [key: string]: FieldDefinition } = {
  issueId: {
    name: 'issueId',
    displayName: 'Issue ID',
    displayNamePlural: 'Issue IDs',
    entity: {
      type: 'issueId'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  issueType: {
    name: 'issueType',
    displayName: 'Issue Type',
    displayNamePlural: 'Issue Types',
    entity: {
      type: 'issueType'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  issueTypeNormalized: {
    name: 'issueTypeNormalized',
    displayName: 'Issue Type Normalized',
    displayNamePlural: 'Issue Types Normalized',
    entity: {
      type: 'issueTypeNormalized'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  subTypeOfTheNonCompliance: {
    name: 'subTypeOfTheNonCompliance',
    displayName: 'Sub Type Of The Non-Compliance',
    displayNamePlural: 'Sub Types Of The Non-Compliance',
    entity: {
      type: 'subTypeOfTheNonCompliance'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  status: {
    name: 'status',
    displayName: 'Status',
    displayNamePlural: 'Statuses',
    entity: {
      type: 'status'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  vendorCode: {
    name: 'vendorCode',
    displayName: 'Vendor Code',
    displayNamePlural: 'Vendor Codes',
    entity: {
      type: 'vendorCode'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  purchaseOrder: {
    name: 'purchaseOrder',
    displayName: 'Purchase Order',
    displayNamePlural: 'Purchase Orders',
    entity: {
      type: 'purchaseOrder'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  }
};
