import React from 'react';
import { useStacklineTheme, Text, StacklineColor, hexToRgba } from '@stackline/ui';

export interface ElementCardProps {
  children: React.ReactNode;
  color: StacklineColor;
  variant?: 'large' | 'small' | 'medium';
}

/**
 * Displays text inside of colored container, for use
 * inside of the entity card in the left nav
 */
const ElementCard = ({ children, color, variant = 'large' }: ElementCardProps) => {
  const theme = useStacklineTheme();

  const variantSizeMapping: { [key in ElementCardProps['variant']]: string } = {
    large: '72px',
    medium: '48px',
    small: '38px'
  };

  const backgroundColorOverride: Partial<Record<StacklineColor, string>> = {
    primary: '#a5cfff'
  };

  return (
    <div
      style={{
        backgroundColor: hexToRgba(backgroundColorOverride[color] || theme.colors[color], 0.1),
        width: variantSizeMapping[variant],
        height: variantSizeMapping[variant],
        padding: '12px 17px 12px 18px',
        borderRadius: variant === 'large' ? theme.spacing.sm : theme.spacing.xs,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box'
      }}
    >
      <Text variant={variant === 'large' ? 'h1' : 'subtitle1'} color={color}>
        {children}
      </Text>
    </div>
  );
};

export default ElementCard;
