import React, { FC, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { DonutChart } from 'src/components/Charts/Donut';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { useAsyncHook } from 'src/utils/Hooks/typedHooks';
import { OmniBaseRequestBody, addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';
import { OmniDonutAsyncContent, OmniDonutData } from 'src/components/Omni/OmniDonutChart/OmniDonutUtil';
import 'src/components/Charts/Donut/DonutChart.scss';
import { Widget } from 'src/types/application/widgetTypes';
import _cloneDeep from 'lodash/cloneDeep';

interface OmniDonutChartContentProps extends RouteComponentProps {
  widget: Widget;
}

const OmniDonutChartContent: FC<OmniDonutChartContentProps> = ({ widget, location }) => {
  const { data, loading, run } = useAsyncHook<OmniDonutData[]>([]);
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const filters = useSelector((state: ReduxStore) => state.filters);
  const { pathname } = location;
  const { startWeek, endWeek } = mainTimePeriod;
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const mainEntityMetrics: { name: string; data: OmniDonutData[]; title: number } = {
    name: 'donutChart',
    data: data === null ? [] : _cloneDeep(data),
    title: 0
  };

  if (!loading && data && data[0]) {
    mainEntityMetrics.title = data[0] ? data[0].y : 0;
    mainEntityMetrics.data.push({
      color: '#f6f9fc',
      name: '',
      fieldId: 1,
      y: 1 - data[0].y
    });
  }

  const fetchData = useCallback(() => {
    const baseRequestBody: OmniBaseRequestBody = {
      startWeekId: startWeek,
      endWeekId: endWeek,
      groupBy: 'beaconClientId',
      detailedScores: false
    };
    const requestBody = addFilterToOmniBaseReqBody(baseRequestBody, filters, pathname);
    run(OmniDonutAsyncContent(requestBody));
  }, [filters, pathname]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      {loading ? null : (
        <DonutChart widget={widget} mainEntityMetrics={mainEntityMetrics} isStarChart retailer={retailer} />
      )}
    </div>
  );
};

export default withRouter(OmniDonutChartContent);
