import Hashids from 'hashids';

const hashids = new Hashids('', 10);

export function encodeHash(id: number) {
  return hashids.encode(id);
}

export function decodeHash(id: string) {
  return hashids.decode(id);
}
