import { useStacklineTheme } from '@stackline/ui';
import { AppName } from 'sl-api-connector';
import { useAppSelector } from 'src/utils/Hooks';
import { getUserProfileImageUrl } from 'src/utils/image';

export const useAppSecondaryColor = () => {
  const theme = useStacklineTheme();
  const app = useAppSelector((state) => state.app);

  const getSecondaryTextColor = (appName: AppName) => {
    switch (appName) {
      case AppName.Beacon:
        return theme.colors.accentMagenta;
      case AppName.Omni:
        return theme.colors.accentMagenta;
      default:
        return theme.colors.info;
    }
  };

  return getSecondaryTextColor(app.name);
};

/**
 *   check if user profile image exists in our S# bucket.
 */
export function checkS3FileExists(userId) {
  return new Promise((resolve) => {
    fetch(getUserProfileImageUrl(userId))
      .then((response) => {
        if (response.status === 200) {
          resolve(true); // File exists
        } else if (response.status === 403) {
          resolve(false); // File does not exist
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        console.error('ERROR: Cannot check s3 file - in utils.ts', error);
      });
  });
}
