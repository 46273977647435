import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { withBus } from 'react-bus';
import { propEq } from 'src/utils/fp';
import GenericChart from 'src/components/Charts/GenericChart';
import { GenericChartLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { omniChartsServiceOperations } from 'src/store/modules/omni/omniChartService';
import { buildChartProps } from 'src/components/EntityPage/TopEntitiesChart/OmniTopEntitesChartUtil';
import { PERIODS } from 'src/store/modules/omni/constants';
import ReduxStore from 'src/types/store/reduxStore';
import { Widget } from 'src/types/application/widgetTypes';
import _cloneDeep from 'lodash/cloneDeep';
import { EventBus } from 'src/types/utils';
import { OmniBaseRequestBody, addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';
import convertBarChartSeriesToDelimitedData from 'src/components/Charts/GenericChart/SeriesConverters/barChart';
import { trackTheDataSwitching } from 'src/utils/mixpanel';
import { startFetchChartData, deleteKey } from 'src/store/modules/omni/omniChartService/actions';
import { destructurePathName } from 'src/utils/urlParsing';
import _capitalize from 'lodash/capitalize';

const { fetchOmniChartsServiceData, fetchSummaryForTopEntitiesGroupByOrderType } = omniChartsServiceOperations;

interface OmniTopEntitiesChartProps extends RouteComponentProps {
  widget: Widget;
  eventBus: EventBus;
  uniqueName: string;
  reviewKeywordFilter?: string;
  starFilter?: string[];
}

interface OmniTopEntitiesRequestBody extends OmniBaseRequestBody {
  groupBy?: string;
  fulfillmentType?: string;
  shareOfShelfType?: string;
  topSearchResults?: string;
  useCombinedBrands?: boolean;
  useWeighted?: boolean;
}

const OmniTopEntitiesChart: React.FC<OmniTopEntitiesChartProps> = ({
  widget,
  eventBus,
  uniqueName,
  location,
  starFilter,
  reviewKeywordFilter
}) => {
  const {
    name,
    view: { mainMetricFields, fetchList },
    data
  } = widget;
  const { valueGroups } = data;

  const dispatch = useDispatch();
  const { mainEntity } = useSelector((state: ReduxStore) => state.entityService);
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const comparisonTimePeriod = useSelector((state: ReduxStore) => state.comparisonTimePeriod);
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const omniRegionsFollowing = useSelector((state: ReduxStore) => state.omniRegionsFollowing);
  const omniCountriesFollowing = useSelector((state: ReduxStore) => state.omniCountriesFollowing);
  const { startWeek: mainStarWeek, endWeek: mainEndWeek } = mainTimePeriod;
  const { startWeek: compStarWeek, endWeek: compEndWeek } = comparisonTimePeriod;
  const chartName = `${name}_${mainStarWeek}_${mainEndWeek}_${compStarWeek}_${compEndWeek}`;
  const chartData = useSelector((state: ReduxStore) => state.omniChartsService[chartName]);
  const isFetching = chartData ? chartData.isFetching : true;
  const [selectedGroupByField, setSelectedGroupByField] = useState(valueGroups[0].displayName);
  const [selectedMetricFieldName, setSelectedMetricFieldName] = useState(mainMetricFields[0].displayName);
  const [topSearchResults, setTopSearchResults] = useState('');
  const app = useSelector((state: ReduxStore) => state.app);
  const { subtab } = app.queryParams;
  const filters = useSelector((state: ReduxStore) => state.filters);
  const user = useSelector((state: ReduxStore) => state.user);
  const shouldSupportGroupByToggle = valueGroups.length > 1;
  const shouldSupportMetricsToggle = mainMetricFields.length > 1;
  const getSelectedMetricField = () => {
    return mainMetricFields.find(propEq('displayName', selectedMetricFieldName)) || mainMetricFields[0];
  };

  const handleReceiveInfoFromShareOfShelf = ({ displayName }: { displayName: string }) => {
    if (displayName && (displayName.includes('3') || displayName.includes('5') || displayName.includes('10'))) {
      const num = displayName.match(/\d+/)[0];
      setTopSearchResults(`top${num}`);

      // getAverageShareOfShelf does not support "all" option, which is required in getShareOfShelfShards
    } else {
      setTopSearchResults('');
    }
  };

  const handleMainMetricToggle = ({ displayName }: { displayName: string }) => {
    setSelectedMetricFieldName(displayName);
  };

  const handleGroupByToggle = ({ displayName }: { displayName: string }) => {
    setSelectedGroupByField(displayName);
  };

  const getSelectedGroupBy = () => {
    return valueGroups.find(propEq('displayName', selectedGroupByField)) || valueGroups[0];
  };
  const { fulfillmentType, shareOfShelfType } = getSelectedMetricField();
  const groupFiled = getSelectedGroupBy();
  const { groupBy, name: groupName, excludeBrandAndProduct } = groupFiled;

  const fetchData = useCallback(() => {
    let baseRequestBody: OmniTopEntitiesRequestBody = {
      includeLocationCountryCode: [],
      includeLocationRegionCode: [],
      retailerIds: [],
      includeBrandIds: [],
      includeCategoryIds: [],
      productIds: [],
      includeSubCategoryIds: [],
      startWeekId: mainStarWeek,
      endWeekId: mainEndWeek,
      shareOfShelfType
    };

    if (shouldSupportGroupByToggle || shouldSupportMetricsToggle) {
      trackTheDataSwitching(`${selectedMetricFieldName} by ${selectedGroupByField}`, location, user, {
        selectedMetricFieldName,
        selectedGroupByField
      });
    }

    if (name.includes('ShareOfShelfBy') && topSearchResults) {
      baseRequestBody.topSearchResults = topSearchResults;
    }

    const { pathname } = location;
    const [entityType] = destructurePathName(pathname);

    baseRequestBody = addFilterToOmniBaseReqBody(baseRequestBody, filters, pathname, mainEntity.query);
    if (excludeBrandAndProduct) {
      delete baseRequestBody.includeBrandIds;
      delete baseRequestBody.productIds;
    }

    if (entityType === 'brand' && name.includes('ShareOfShelfByretailerId')) {
      baseRequestBody.useCombinedBrands = true;
    }
    if (groupName === 'orderType') {
      const requestBody = _cloneDeep(baseRequestBody);
      const requestBodyForCompare = { ...requestBody, startWeekId: compStarWeek, endWeekId: compEndWeek };
      dispatch(
        startFetchChartData({
          chartName,
          data: []
        })
      );

      dispatch(
        fetchSummaryForTopEntitiesGroupByOrderType(
          fetchList,
          requestBody,
          requestBodyForCompare,
          chartName,
          mainMetricFields[0].name
        )
      );
    } else {
      let requestBody = _cloneDeep(baseRequestBody);

      requestBody = {
        ...requestBody,
        groupBy,
        fulfillmentType
      };
      if (starFilter) {
        requestBody.starsFilter = starFilter;
      }

      if (reviewKeywordFilter) {
        requestBody.reviewKeywords = reviewKeywordFilter ? [reviewKeywordFilter] : [];
      }
      dispatch(
        startFetchChartData({
          chartName,
          data: []
        })
      );
      dispatch(
        fetchOmniChartsServiceData(requestBody, PERIODS.COMPARE, chartName, mainMetricFields[0].name, {
          compareStartWeek: compStarWeek,
          compareEndWeek: compEndWeek
        })
      );
    }
  }, [
    dispatch,
    mainMetricFields,
    chartName,
    compEndWeek,
    compStarWeek,
    mainEndWeek,
    mainStarWeek,
    location,
    groupBy,
    groupName,
    fulfillmentType,
    fetchList,
    filters,
    shareOfShelfType,
    topSearchResults,
    selectedMetricFieldName,
    selectedGroupByField,
    user,
    shouldSupportGroupByToggle,
    shouldSupportMetricsToggle,
    excludeBrandAndProduct
  ]);

  useEffect(() => {
    if (shouldSupportGroupByToggle && shouldSupportMetricsToggle) {
      eventBus.on(`${uniqueName}${name}toggleChartGroupByMetric`, handleGroupByToggle);
      eventBus.on(`${uniqueName}${name}toggleChartMainMetric`, handleMainMetricToggle);
      return () => {
        eventBus.off(`${uniqueName}${name}toggleChartGroupByMetric`, handleGroupByToggle);
        eventBus.off(`${uniqueName}${name}toggleChartMainMetric`, handleMainMetricToggle);
      };
    } else if (shouldSupportGroupByToggle) {
      eventBus.on(`${uniqueName}${name}toggleChartGroupByMetric`, handleGroupByToggle);
      return () => {
        eventBus.off(`${uniqueName}${name}toggleChartGroupByMetric`, handleGroupByToggle);
      };
    } else if (shouldSupportMetricsToggle) {
      eventBus.on(`${uniqueName}${name}toggleChartMainMetric`, handleMainMetricToggle);
      return () => {
        eventBus.off(`${uniqueName}${name}toggleChartMainMetric`, handleMainMetricToggle);
      };
    }
    return () => {};
  }, [eventBus, uniqueName, handleGroupByToggle, shouldSupportGroupByToggle, name, shouldSupportMetricsToggle]);

  useEffect(() => {
    // if share of shelves page, if one of our topEntityChart changes, we want to update trendChart too.

    if (name.includes('ShareOfShelfBy')) {
      eventBus.on('noticeShareOfShelveMainMetricFields', handleReceiveInfoFromShareOfShelf);
      return () => {
        // set the TopSearchResults to empty string, so new rendered total share of shelves have a new mainMetric field
        setTopSearchResults('');
        eventBus.off('noticeShareOfShelveMainMetricFields', handleReceiveInfoFromShareOfShelf);
      };
    }
    return () => {};
  }, [eventBus, name]);

  useEffect(() => {
    fetchData();
    return () => {
      dispatch(deleteKey(chartName));
    };
  }, [fetchData, dispatch, chartName, topSearchResults]);

  const buildDropdownOptions = () => {
    const groupOptions = valueGroups.map(({ displayName }: { displayName: string }) => ({
      eventId: { displayName },
      eventName: `${uniqueName}${name}toggleChartGroupByMetric`,
      displayName,
      isSelected: displayName === selectedGroupByField
    }));

    const mainMetricOptions = mainMetricFields.map(({ displayName }: { displayName: string }) => ({
      eventId: { displayName },
      eventName: `${uniqueName}${name}toggleChartMainMetric`,
      displayName,
      isSelected: displayName === selectedMetricFieldName
    }));
    return { mainMetricOptions, groupOptions };
  };

  const renderTopEntitiesChart = () => {
    if (isFetching || !chartData || !chartData.main || !chartData.compare) {
      return <GenericChartLoading />;
    } else {
      const cloneWidget = _cloneDeep(widget);
      cloneWidget.data.valueGroups = [groupFiled];
      cloneWidget.view.mainMetricFields = [getSelectedMetricField()];

      if (name.includes('ShareOfShelfBy')) {
        let titleOverride = '';
        const subtabTitle = _capitalize(subtab);
        let mainMetric = '';
        let num = '';
        if (topSearchResults) {
          [num] = topSearchResults.match(/\d+/);
          mainMetric = `Top ${num} Placements`;
          titleOverride = `${subtabTitle} ${mainMetric}`;
        } else {
          titleOverride = `${subtabTitle} Share of Shelf`;
        }

        cloneWidget.view.mainMetricFields = [{ ...cloneWidget.view.mainMetricFields[0], displayName: titleOverride }];
        cloneWidget.view = { ...cloneWidget.view, displayName: titleOverride };
      }

      const chartParams = buildChartProps(
        chartData,
        cloneWidget,
        retailer,
        mainTimePeriod,
        comparisonTimePeriod,
        omniRegionsFollowing,
        omniCountriesFollowing
      );
      if (!chartParams) {
        return <GenericChartLoading />;
      }
      const { chartProps, chartSeries } = chartParams;

      chartProps.exporting = {};
      if (shouldSupportGroupByToggle && shouldSupportMetricsToggle) {
        const { mainMetricOptions, groupOptions } = buildDropdownOptions();
        chartProps.exporting = { buttons: [mainMetricOptions, groupOptions] };
      } else if (shouldSupportGroupByToggle) {
        const { groupOptions } = buildDropdownOptions();
        chartProps.exporting = { buttons: groupOptions };
      } else if (shouldSupportMetricsToggle) {
        const { mainMetricOptions, groupOptions } = buildDropdownOptions();
        chartProps.exporting = { buttons: [mainMetricOptions, groupOptions] };
      }
      chartProps.exporting.enabled = true;

      const fetchAndCsvSerializeSeries = (chartSeriesData) => {
        return convertBarChartSeriesToDelimitedData(chartSeriesData, retailer);
      };
      return (
        <GenericChart
          chartSeries={chartSeries}
          chartProps={chartProps}
          convertSeriesToDelimitedData={fetchAndCsvSerializeSeries}
        />
      );
    }
  };
  return <div id={widget.name}>{renderTopEntitiesChart()}</div>;
};

export default withRouter(withBus('eventBus')(OmniTopEntitiesChart));
