import * as types from './types';

export const setFlow = (flow) => ({ type: types.SET_BUDGET_FLOW, flow });
export const setPlatform = (platform) => ({ type: types.SET_BUDGET_PLATFORM, platform });
export const setEntity = (entity) => ({ type: types.SET_BUDGET_ENTITY, entity });
export const setEntityArray = (entityArray) => ({ type: types.SET_BUDGET_ENTITY_ARRAY, entityArray });
export const setPortfolioArray = (portfolioArray) => ({ type: types.SET_BUDGET_PORTFOLIO_ARRAY, portfolioArray });
export const setCampaignArray = (campaignArray) => ({ type: types.SET_BUDGET_CAMPAIGN_ARRAY, campaignArray });
export const setTimeline = (timeline) => ({ type: types.SET_BUDGET_TIMELINE, timeline });
export const setBudgetAmount = (amount) => ({ type: types.SET_BUDGET_AMOUNT, amount });
export const setAdjustedCampaigns = (campaigns) => ({ type: types.SET_ADJUSTED_CAMPAIGNS, campaigns });
export const setMtdAdSpend = (mtdAdSpend) => ({ type: types.SET_MTD_AD_SPEND, mtdAdSpend });
export const resetBudgetEditor = () => ({ type: types.RESET_BUDGETEDITOR });
export const replaceState = (newState) => ({
  type: types.REPLACE_AD_BUDGET_EDITOR_STATE,
  newState
});
