import axios from 'axios';
import _get from 'lodash/get';

import Creators from './actions';
import parseSubCategories from './selectors';
import types from './types';

export const {
  clearSubcategories,
  receiveAllSuperUserSubcategories,
  receiveSubcategories,
  requestSubcategories,
  sortSubCategories
} = Creators;

export const maybeSortSubCategories = () => (dispatch, getState) => {
  // Check to see if we've already fetched both subcategories and sales by subcategory for the current main entity
  const { entitySearchService, subCategories } = getState();
  const salesBySubCategoryId = entitySearchService[types.SALES_BY_SUB_CATEGORY_STATE_KEY];
  if (!salesBySubCategoryId || !subCategories) {
    return;
  }

  // Build up a mapping of `{ [subCategoryId]: sales }`
  const salesBySubCategoryIdMapping = new Map();
  const data = _get(salesBySubCategoryId, ['retailSales_by_subCategoryId', 'data']);
  if (!data) {
    console.warn('Failed to sort subcategory data because none existed to sort');
    return;
  }

  data.forEach(({ entity: { subCategoryId }, value }) => salesBySubCategoryIdMapping.set(subCategoryId, value));

  dispatch(sortSubCategories(({ subCategoryId }) => salesBySubCategoryIdMapping.get(subCategoryId) || 0));
};

export function fetchSubCategories() {
  return (dispatch, getState) => {
    const appName = getState().app.apiAppName;
    const request = [
      {
        name: `${appName}-subcategory-following`,
        pageNumber: 1,
        pageSize: 20,
        doAggregation: false,
        returnDocuments: true,
        searchBy: 'parent',
        searchType: `${appName}-subcategory-following`,
        aggregations: null
      }
    ];

    dispatch(requestSubcategories());

    return axios.post(`/api/${appName}/AdvancedSearch?_id=fetchSubCategories`, request).then((response) => {
      const categories = response.data[0].documents;

      dispatch(receiveSubcategories(parseSubCategories(categories)));
      const superUserSubCategories = _get(
        response,
        ['data', '0', 'additionalResponseMetaData', 'allSuperUserSubCategories'],
        []
      );

      dispatch(receiveAllSuperUserSubcategories(parseSubCategories(superUserSubCategories)));
      // It's possible that the request to fetch the main entity completed before the request to fetch subcategories, so
      // try to sort subcategories again.
      dispatch(maybeSortSubCategories());
    });
  };
}
