import React, { Ref } from 'react';
import Radio from '@mui/material/Radio';
import styled from '@emotion/styled';
import { StacklineTheme, useStacklineTheme } from '@stackline/ui';

// Define the props for StyledRadioInner
interface StyledRadioInnerProps {
  stacklineTheme: StacklineTheme;
}

const StyledRadioInner = styled(Radio)<StyledRadioInnerProps>(({ stacklineTheme }) => ({
  svg: {
    height: '15px',
    width: '15px'
  },
  '&, &.Mui-checked': {
    color: stacklineTheme.colors.primary
  },
  '&.MuiRadio-root': {
    padding: '0px'
  }
}));

// eslint-disable-next-line react/display-name
const StyledRadio = React.forwardRef(
  (props: React.ComponentProps<typeof Radio>, ref: Ref<HTMLButtonElement>): JSX.Element => {
    const theme = useStacklineTheme();
    return <StyledRadioInner ref={ref} stacklineTheme={theme} {...props} />;
  }
);

export default StyledRadio;
