/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import axios, { CancelTokenSource } from 'axios';
import { AD_TARGETING_TYPE } from 'sl-ad-campaign-manager-data-model';
import { Entity, Conditions, TermFilter } from 'sl-api-connector/types';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

import { store } from 'src/main';
import { anyNotEq, keyNotEq } from 'src/utils/equality';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import * as adCampaignBuilderOperations from 'src/store/modules/adManager/adCampaignBuilder/operations';
import { buildAggregationConditions } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { AdCampaignBuilderCheckbox } from 'src/components/AdCampaignBuilder/Widgets/Checkbox';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import MultiLineHCF from 'src/components/EntityGrid/HeaderComponentFrameworks/MultiLineHCF';
import { DollarExactCRF, DollarRounded, DollarUnitsCRF, mkNumberFormatterCRF } from './GridFrameworks';
import { EntityColumn } from 'src/components/Grids/Data/ColumnTypes';
import './TargetEntitiesGrid.scss';
import ReduxStore from 'src/types/store/reduxStore';
import { Widget } from 'src/types/application/widgetTypes';
import { MetricField } from 'src/types/application/types';
import { WaterfallFirstColumnHCF } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import { prop, not, propEq } from 'src/utils/fp';
import { EventBus } from 'src/types/utils';
import { NoResultWithOpenFilter } from 'src/components/Grids/Data/NoResultTemplate';
import { buildAggregations } from 'src/components/AdManager/Search';

const { CancelToken } = axios;

const CheckboxColumn: React.FC<{ checked: boolean; toggleChecked: () => void }> = ({ checked, toggleChecked }) => (
  <AdCampaignBuilderCheckbox checked={checked} onChange={toggleChecked} />
);

export const SelectAllCheckbox: React.FC<{ checked: boolean; onChange: (isChecked: boolean) => void }> = ({
  checked,
  onChange
}) => (
  <div className="select-all-checkbox">
    <AdCampaignBuilderCheckbox
      checked={checked}
      onChange={(_event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => onChange(isChecked)}
      style={{ marginBottom: -48, marginLeft: 12, zIndex: 2 }}
    />
  </div>
);

/**
 * A custom column renderer that creates checkboxes for the featured products grid.  The state of the checkboxes is
 * managed via Redux.
 */
const CheckboxColumnRenderer = connect(
  (_state: ReduxStore, { data: { selected } }) => ({ checked: !!selected }),
  (
    dispatch: (action: { type: string; [key: string]: any }) => void,
    { data: { entity } }: { data: { entity: Entity; selected?: boolean } }
  ) => ({
    toggleChecked: () => dispatch(adCampaignBuilderActions.toggleTargetEntitySelected(entity))
  })
)(CheckboxColumn);

const baseColDef = {
  disableSort: true,
  width: 100,
  headerComponentFramework: MultiLineHCF
};

const getGridColumnDefinitions = (
  adTargetingType: AD_TARGETING_TYPE | undefined,
  retailerId: string,
  isAdAuditUser: boolean
) => {
  const TARGET_KEYWORDS_GRID_COLUMN_DEFINITIONS = [
    ...(isAdAuditUser
      ? []
      : [
          {
            headerName: ' ',
            field: 'selected',
            width: undefined,
            minWidth: 70,
            maxWidth: 70,
            cellRendererFramework: CheckboxColumnRenderer,
            enableRtl: true,
            cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' }
          }
        ]),
    {
      headerName: 'Keyword',
      field: 'keyword',
      minWidth: 300,
      maxWidth: 800,
      width: undefined,
      enableRtl: false,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left',
      headerComponentFramework: WaterfallFirstColumnHCF
    },
    {
      headerName: 'Start Bid',
      valueFormatter: retailerId === '16' ? DollarRounded : DollarExactCRF,
      field: 'startBid'
    },
    {
      headerName: 'Ad Clicks',
      valueFormatter: mkNumberFormatterCRF('1,000'),
      field: 'adClicks'
    },
    {
      headerName: 'Ad Spend',
      valueFormatter: DollarUnitsCRF,
      field: 'adSpend'
    },
    {
      headerName: 'Ad Sales',
      valueFormatter: DollarUnitsCRF,
      field: 'adSales'
    },
    {
      headerName: 'Incremental Sales',
      valueFormatter: DollarUnitsCRF,
      field: 'incrementalSales'
    },
    {
      headerName: 'Gross Margin',
      valueFormatter: DollarUnitsCRF,
      field: 'grossMargin'
    },
    {
      headerName: 'Incremental Margin',
      valueFormatter: DollarUnitsCRF,
      field: 'incrementalMargin'
    },
    {
      headerName: 'ROAS',
      valueFormatter: DollarExactCRF,
      field: 'returnOnAdSpend'
    },
    {
      headerName: 'Incremental ROAS',
      valueFormatter: DollarExactCRF,
      field: 'incrementalReturnOnAdSpend'
    }
  ].map(({ headerName, ...colDef }) => ({
    ...baseColDef,
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' },
    ...colDef,
    displayName: headerName
  }));

  const TARGET_PRODUCTS_GRID_COLUMN_DEFINITIONS = [
    ...(isAdAuditUser
      ? []
      : [
          {
            headerName: ' ',
            field: 'selected',
            width: undefined,
            minWidth: 70,
            maxWidth: 70,
            cellRendererFramework: CheckboxColumnRenderer,
            enableRtl: true,
            cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' }
          }
        ]),
    {
      headerName: 'Product',
      field: 'stacklineSku',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left',
      minWidth: 300,
      maxWidth: 800,
      cellRendererFramework: EntityColumn
    },
    {
      headerName: 'Start Bid',
      valueFormatter: retailerId === '16' ? DollarRounded : DollarExactCRF,
      field: 'startBid'
    },
    {
      headerName: 'Ad Clicks',
      valueFormatter: mkNumberFormatterCRF('0,0'),
      field: 'adClicks'
    },
    {
      headerName: 'Ad Spend',
      valueFormatter: DollarUnitsCRF,
      field: 'adSpend'
    },
    {
      headerName: 'Ad Sales',
      valueFormatter: DollarUnitsCRF,
      field: 'adSales'
    },
    {
      headerName: 'Incremental Sales',
      valueFormatter: DollarUnitsCRF,
      field: 'incrementalSales'
    },
    {
      headerName: 'Gross Margin',
      valueFormatter: DollarUnitsCRF,
      field: 'grossMargin'
    },
    {
      headerName: 'Incremental Margin',
      valueFormatter: DollarUnitsCRF,
      field: 'incrementalMargin'
    },
    {
      headerName: 'ROAS',
      valueFormatter: DollarExactCRF,
      field: 'returnOnAdSpend'
    },
    {
      headerName: 'Incremental ROAS',
      valueFormatter: DollarExactCRF,
      field: 'incrementalReturnOnAdSpend'
    }
  ].map(({ headerName, ...colDef }) => ({
    ...baseColDef,
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' },
    ...colDef,
    displayName: headerName
  }));
  switch (adTargetingType) {
    case AD_TARGETING_TYPE.KEYWORD_TARGETING:
      return TARGET_KEYWORDS_GRID_COLUMN_DEFINITIONS;
    default:
      return TARGET_PRODUCTS_GRID_COLUMN_DEFINITIONS;
  }
};

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, [
    'app',
    'brandsFollowing',
    'entityService',
    'entitySearchService',
    'retailer',
    'categories',
    'filters',
    'allWeekIdsByRetailerId',
    'adCampaignBuilder',
    'user'
  ]);

type Props = ReturnType<typeof mapStateToProps> & {
  widget: Widget;
  style: React.CSSProperties;
  gridStyle?: React.CSSProperties;
  eventBus: EventBus;
  brandsFollowing: any[];
  pageSize?: number;
  adGroupId?: string;
};

interface State {
  isLoading: boolean;
  dataToRender: unknown[];
  pageNumber: number;
  selectAllChecked: boolean;
  entitySearchServiceStateNamesMainEntity?: string[];
  entitySearchServiceStateNamesComparisonEntity?: string[];
  entitySearchServiceStateNamesMarketShare?: string[];
  mainMetricConfig?: { [key: string]: unknown };
  comparisonMetricConfig?: { [key: string]: unknown };
}

class TargetEntitiesGrid extends React.Component<Props> {
  public state: State = {
    selectAllChecked: false,
    isLoading: true,
    dataToRender: [],
    pageNumber: 1
  };

  private cancelSource?: CancelTokenSource;

  public componentDidMount() {
    const {
      adCampaignBuilder: {
        target: { selectedTargetEntities, targetEntitiesListFromAtlas }
      }
    } = this.props;
    this.cancelSource = CancelToken.source();

    // only fetch data if the user has not selected target entities already and we've already got Atlas data
    if (!_isEmpty(selectedTargetEntities) && !_isEmpty(targetEntitiesListFromAtlas)) {
      this.setState({ isLoading: false });
    } else {
      this.fetchData(this.props);
    }

    this.props.eventBus.on('fetchTargetEntities', this.fetchData);
  }

  public componentWillReceiveProps(nextProps: Props) {
    const propKeysToCompare = [
      'location.pathname',
      'location.search',
      'conditions',
      'entityService.mainEntity',
      'entityService.comparisonEntity',
      'filters',
      'widget',
      'adCampaignBuilder.target.filters',
      'adGroupId'
    ];

    if (anyNotEq(propKeysToCompare, this.props, nextProps)) {
      if (typeof this.cancelSource !== typeof undefined) {
        this.cancelSource!.cancel('Canceled network request');
      }
      this.cancelSource = CancelToken.source();
      this.fetchData(nextProps);
    }
  }

  public shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (!_isEqual(this.state, nextState)) {
      return true;
    }
    const { entitySearchService, adCampaignBuilder } = this.props;
    const { entitySearchService: nextEntitySearchService, adCampaignBuilder: nextAdCampaignBuilder } = nextProps;
    const { entitySearchServiceStateNamesMainEntity = [], entitySearchServiceStateNamesComparisonEntity = [] } =
      this.state;

    if (!_isEqual(adCampaignBuilder, nextAdCampaignBuilder)) {
      return true;
    }
    return !![...entitySearchServiceStateNamesMainEntity, ...entitySearchServiceStateNamesComparisonEntity].find(
      (stateName) => keyNotEq(stateName, entitySearchService, nextEntitySearchService)
    );
  }

  public componentWillUnmount() {
    if (typeof this.cancelSource !== typeof undefined) {
      this.cancelSource!.cancel('Cancel network request');
    }

    this.props.eventBus.off('fetchTargetEntities', this.fetchData);
  }

  private fetchData = (props: Props) => {
    const { app, retailer, allWeekIdsByRetailerId, widget, adCampaignBuilder, entityService, adGroupId } =
      props || this.props;

    this.setState({ isLoading: true });
    const {
      target: { filters }
    } = adCampaignBuilder;

    // TODO change this back to the sl-api-connector EMPTY_CONDITIONS when that gets fixed
    // aka we can figure out where it is being mutated.

    const conditions = { termFilters: [], rangeFilters: [] } as Conditions;
    if (filters.termFilters) {
      Object.entries(filters.termFilters).forEach(([key, termFilter]) => {
        if (!_isEmpty(termFilter.values)) {
          conditions.termFilters!.push({
            fieldName: key,
            condition: termFilter.condition,
            values: (termFilter.values || []).map(prop('i')).filter((val) => val !== undefined)
          });
        }
      });
    }

    // block out product targets of same brand
    if (entityService) {
      if (entityService.mainEntity) {
        if (entityService.mainEntity.type === 'adCampaign') {
          // @ts-ignore
          const isProductTargeting = entityService.mainEntity.extendedAttributes.campaignType === 'sponsoredProducts';
          if (isProductTargeting) {
            // add term filter for brand ids
            const brandsToExclude = this.props.brandsFollowing
              .filter((brand) => brand.brandName !== 'All Brands')
              .map((brand) => brand.id);
            const termFilter: TermFilter = {
              fieldName: 'excludedBrandId',
              condition: 'must_not',
              values: brandsToExclude
            };

            conditions.termFilters!.push(termFilter);
          }
        }
      }
    }

    const state = {
      isLoading: true,
      entitySearchServiceStateNamesMainEntity: [] as string[],
      entitySearchServiceStateNamesComparisonEntity: [] as string[],
      entitySearchServiceStateNamesMarketShare: [] as string[],
      mainMetricConfig: {},
      comparisonMetricConfig: {}
    };

    // Always fetch the past 4 weeks of metrics
    const timePeriod = (store.getState() as ReduxStore).mainTimePeriod.availableMainTimePeriods.find(
      propEq('id', '4w')
    )!;

    const promises = widget.data.groupByFields.map((groupByField: MetricField) => {
      const {
        entity,
        indexName,
        entityQueryConditions
      }: {
        entity: Entity;
        indexName: string;
        entityQueryConditions: Conditions;
      } = widget.data.configByGroupByFieldName[groupByField.name!];

      if (entityQueryConditions) {
        if (entityQueryConditions.rangeFilters) {
          entityQueryConditions.rangeFilters.forEach((filter) => conditions.rangeFilters!.push(filter));
        }
        if (entityQueryConditions.termFilters) {
          entityQueryConditions.termFilters.forEach((filter) => conditions.termFilters!.push(filter));
        }
      }
      const retailerClone = _cloneDeep(retailer);
      if (['63', '2', '25'].includes(retailer.id)) {
        retailerClone.id = '1';
      }
      const { aggregationConditions } = buildAggregationConditions(
        app,
        indexName,
        retailerClone,
        allWeekIdsByRetailerId,
        timePeriod as any,
        {} as any,
        null,
        widget.data.weekIdField,
        false,
        true
      );
      const statePropertyNameMainEntity = `${widget.name}_${app.name}_${groupByField.name}`;
      state.entitySearchServiceStateNamesMainEntity.push(statePropertyNameMainEntity);
      const aggregationItems = buildAggregations(
        widget.data.configByGroupByFieldName[groupByField.name!].aggregationFields
      );

      let derivedFields: any = null;

      const mainEntitySearchRequestOverrides = aggregationItems.map((aggregationItem) => {
        const {
          aggregations: aggregationFields,
          aggregationFieldConditions,
          derivations,
          indexName: aggregationIndexName
        } = aggregationItem;

        derivedFields = derivedFields || derivations;
        if (aggregationConditions && aggregationConditions.termFilters) {
          aggregationFieldConditions.termFilters = aggregationFieldConditions.termFilters.concat(
            aggregationConditions.termFilters
          );
        }
        if (aggregationConditions && aggregationConditions.rangeFilters) {
          aggregationFieldConditions.rangeFilters = aggregationFieldConditions.rangeFilters.concat(
            aggregationConditions.rangeFilters
          );
        }

        const aggregations = {
          groupByFieldName: groupByField.name,
          aggregationFields,
          sortDirection: null,
          sortByAggregationField: null,
          conditions: aggregationFieldConditions
        };

        return {
          name: `${statePropertyNameMainEntity}-${entity.type}-${entity.id}-${aggregationIndexName}`,
          id: `${statePropertyNameMainEntity}-${entity.type}-${entity.id}-${aggregationIndexName}`,
          indexName: `${indexName}-all`,
          conditions,
          aggregations: [aggregations],
          pageSize: this.props.pageSize ? this.props.pageSize : 1000,
          additionalRequestMetaData: {
            returnAdditionalMetaData: true
          }
        };
      });

      const requestContext = {
        entity,
        retailer: retailerClone,
        app,
        indexName,
        derivedFields,
        aggregationFields: widget.data.configByGroupByFieldName[groupByField.name!].aggregationFields
      };

      return store.dispatch(
        adCampaignBuilderOperations.fetchTargetEntities(
          statePropertyNameMainEntity,
          requestContext,
          mainEntitySearchRequestOverrides,
          this.cancelSource!.token,
          this.props.eventBus,
          adGroupId
        )
      );
    });

    this.setState({ ...state });

    Promise.all(promises)
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (!err.message.includes('Canceled network request')) {
          console.error(err);
        }
      });
  };

  private onGridReady = (params: any) => {
    params.api.sizeColumnsToFit();
    window.addEventListener('resize', () => setTimeout(() => params.api.sizeColumnsToFit()));
    params.api.sizeColumnsToFit();
  };

  private handleSelectAllChange = (isChecked: boolean) => {
    const targetEntities = this.props.adCampaignBuilder.target.selectedTargetEntities;
    if (!targetEntities) {
      return;
    }

    store.dispatch(
      adCampaignBuilderActions.bulkSetTargetEntitiesSelected(
        targetEntities.filter(not(prop('isBulkUploaded'))).map(prop('id')),
        isChecked
      )
    );
  };

  public render() {
    if (this.state.isLoading) {
      return <GridLoading />;
    }

    const {
      adCampaignBuilder: {
        target: { selectedTargetEntities, loading, targetingTypeId, uploadedTargetKeywords }
      },
      style,
      gridStyle
    } = this.props;
    const isAdAuditUser = _get(this.props.user, 'config.adAuditEnabled', false);

    const retailerId = _get(this.props.adCampaignBuilder, [
      'platformSettings',
      'entity',
      'extendedAttributes',
      'retailerId'
    ]);
    let columnDefs = getGridColumnDefinitions(targetingTypeId, _get(this.props.retailer, 'id'), isAdAuditUser);

    if ([63, 2].includes(retailerId)) {
      columnDefs = columnDefs.filter(
        (col) =>
          col.field !== 'incrementalSales' &&
          col.field !== 'incrementalMargin' &&
          col.field !== 'incrementalReturnOnAdSpend' &&
          col.field !== 'grossMargin'
      );
    }

    const isAllTargetEntitiesSelected = selectedTargetEntities!.every(prop('selected'));

    const allNonBulkUploadedTargetEntitiesSelected = uploadedTargetKeywords
      ? selectedTargetEntities!
          .filter((targetEntity) => !uploadedTargetKeywords.includes(targetEntity.keyword))
          .every(prop('selected'))
      : isAllTargetEntitiesSelected;

    const dataRows = () => (loading || !selectedTargetEntities ? [] : selectedTargetEntities);
    const height = _isEmpty(dataRows()) ? 50 : 880;
    return (
      <div className="target-keywords-grid no-flex" style={style}>
        {!isAdAuditUser && (
          <SelectAllCheckbox
            checked={isAllTargetEntitiesSelected}
            onChange={() => this.handleSelectAllChange(!allNonBulkUploadedTargetEntitiesSelected)}
          />
        )}
        <CustomAgMaterial
          onGridReady={this.onGridReady}
          onCellValueChanged={this.onGridReady}
          onModelUpdated={this.onGridReady}
          onRowValueChanged={this.onGridReady}
          onRowDataChanged={this.onGridReady}
          buildRows={dataRows}
          columnDefs={columnDefs as any}
          deltaRowDataMode
          getRowNodeId={(datum: any) => escape(datum.id)}
          domLayout="autoHeight"
          suppressNoRowsOverlay
          containerStyle={{ width: '100%', ...(gridStyle || {}), height }}
        />
        {_isEmpty(dataRows()) && <NoResultWithOpenFilter />}
      </div>
    );
  }
}

export default withRouter(
  withBus('eventBus')(connect(mapStateToProps)(TargetEntitiesGrid))
) as unknown as React.ComponentType<{
  widget: Widget;
  style?: React.CSSProperties;
  gridStyle?: React.CSSProperties;
  pageSize?: number;
  adGroupId?: string;
}>;
