import types from './types';

export const receiveOmniRetailerContent = (entity: { data: any[] }) => ({
  type: types.RECEIVE_OMNI_RETAILER_CONTENT_DATA,
  entity
});

export const startFetchRetailerContent = (entity: { data: any[] }) => ({
  type: types.START_FETCH,
  entity
});

export const endFetchRetailerContent = (entity: { data: any[] }) => ({
  type: types.END_FETCH,
  entity
});
