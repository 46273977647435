import React from 'react';
import { Box } from '@mui/system';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';
import { RightArrow } from 'src/components/SvgIcons';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

interface PlanInputContainerProps {
  /**
   * Label assigned to the left of the row of inputs
   */
  label: string;
  /**
   * First input to be rendered within the row
   */
  firstInput: React.ReactNode;
  /**
   * Second input to be rendered within the row
   */
  secondInput: React.ReactNode;
  /**
   * The width of the label container in order to better align the inputs
   */
  width?: string;
}
export const PlanInputContainer = ({ label, firstInput, secondInput, width = 'auto' }: PlanInputContainerProps) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" marginTop="42px" gap="40px">
      <Box width={width} minWidth="80px">
        <Text variant="subtitle2">{label}</Text>
      </Box>
      <Box display="flex" alignItems="center" gap="12px">
        <Flex position="relative" flexDirection="column">
          <Text sx={{ position: 'absolute', fontWeight: 'bold', fontSize: '10px', top: '-20px' }}>CURRENT</Text>
          {firstInput}
        </Flex>
        <RightArrow style={{ width: '12px', height: '12px' }} />
        <Flex position="relative" flexDirection="column">
          <Text sx={{ position: 'absolute', fontWeight: 'bold', fontSize: '10px', top: '-20px' }}>NEW</Text>
          {secondInput}
        </Flex>
        <Text variant="body2">per week</Text>
      </Box>
    </Box>
  );
};
