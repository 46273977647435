import React, { useRef, useState } from 'react';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import { EntityColumn } from 'src/components/Grids/Data/ColumnTypes';
import { ProductGridRow } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/NewToBrandTab/NewToBrandProducts/types';
import AdPaginationGroup from 'src/components/AdManager/AdPaginationGroup';
import '../../../ConnectPage.scss';
import { ColDef, GridApi } from 'ag-grid-community';

interface NewToBrandProductGridProps {
  gridRows: ProductGridRow[];
}

const PAGE_SIZE = 10;
const ROW_HEIGHT = 90;

const NewToBrandProductGrid = ({ gridRows }: NewToBrandProductGridProps) => {
  const gridRef = useRef(null);

  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);

  const onGridReady = (params: { api: GridApi }) => {
    gridRef.current = params.api;
    if (gridRef.current) {
      gridRef.current.sizeColumnsToFit();
      setMaxPages(gridRef.current.paginationGetTotalPages());
    }
  };

  const columnDef: ColDef[] = [
    {
      headerName: 'Products',
      field: 'stacklineSku',
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important', paddingRight: '120px' },
      headerClass: 'align-left',
      width: 700,
      cellRendererFramework: EntityColumn,
      cellRendererParams: {
        entityColumnTextStyles: {
          fontSize: 16,
          maxHeight: 'auto'
        }
      }
    },
    {
      headerName: 'Ad Sales',
      field: 'formattedAdSales',
      width: undefined,
      cellStyle: { 'justify-content': 'center', 'text-align': 'left' },
      headerClass: 'align-left',
      minWidth: 105
    },
    {
      headerName: 'NTB Ad Sales',
      field: 'formattedNewToBrandAdSales',
      width: undefined,
      cellStyle: { 'justify-content': 'center', 'text-align': 'left' },
      headerClass: 'align-left',
      minWidth: 105
    },
    {
      headerName: '% of Ad Sales NTB',
      field: 'formattedPercentNewToBrandAdSales',
      width: undefined,
      cellStyle: { 'justify-content': 'center', 'text-align': 'left' },
      headerClass: 'align-left',
      minWidth: 105
    }
  ];

  const goToNextPage = () => {
    const currentPage = gridRef.current.paginationGetCurrentPage();
    if (currentPage !== maxPages - 1) {
      gridRef.current.paginationGoToNextPage();
    }
  };

  const goToFirstPage = () => {
    gridRef.current.paginationGoToFirstPage();
  };

  const goToPreviousPage = () => {
    const currentPage = gridRef.current.paginationGetCurrentPage();
    if (currentPage !== 0) {
      gridRef.current.paginationGoToPreviousPage();
    }
  };

  const onPaginationChanged = () => {
    if (gridRef.current) {
      setPage(gridRef.current.paginationGetCurrentPage() + 1);
    }
  };

  return (
    <>
      <div className="amc-product-grid-container">
        <CustomAgMaterial
          onGridReady={onGridReady}
          onCellValueChanged={onGridReady}
          onModelUpdated={onGridReady}
          onRowValueChanged={onGridReady}
          onRowDataChanged={onGridReady}
          domLayout="autoHeight"
          rowHeight={ROW_HEIGHT}
          columnDefs={columnDef}
          containerStyle={{ width: '100%', marginBottom: '30px' }}
          suppressNoRowsOverlay
          buildRows={() => gridRows}
          applyColumnDefOrder
          pagination
          paginationPageSize={PAGE_SIZE}
          suppressPaginationPanel
          cacheBlockSize={100}
          cacheOverflowSize={2}
          maxBlocksInCache={4}
          alwaysShowHorizontalScroll
          headerHeight={54}
          onPaginationChanged={onPaginationChanged}
          animateRows={false}
        ></CustomAgMaterial>
        <AdPaginationGroup
          onBtFirst={() => goToFirstPage()}
          onBtNext={() => goToNextPage()}
          onBtPrevious={() => goToPreviousPage()}
          currentPage={page}
          totalPage={maxPages}
          isLoading={false}
        />
      </div>
    </>
  );
};

export default NewToBrandProductGrid;
