import React from 'react';
import { SlRow, useStacklineTheme, hexToRgba } from '@stackline/ui';
import Box from '@mui/material/Box';
import SplineChart, { SplineChartProps } from 'src/components/BeaconRedesignComponents/SplineChart/SplineChart';
import { Text } from '../Generic/Text';
import { SlColumn } from 'src/components/BeaconRedesignComponents/Generic/SlColumn';
import {
  Direction,
  getColorForMetricChangeValue,
  getIconForMetricChange
} from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import {
  BEACON_PRO_LARGE_CARD_HEIGHT,
  BEACON_PRO_CARD_WIDTH,
  FORECAST_KEY_METRICS_CARD_WIDTH
} from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

export interface MetricSummaryTrendCardProps {
  /**
   * Metric title, e.g. "Total Traffic"
   */
  title: string;
  /**
   * Formatted value of the metric, e.g. "13.58M" or "$11.48"
   */
  value: string;
  /**
   * Formatted percentage change. Should be an absolute value.
   * The sign is given through `direction`
   */
  change: string;
  /**
   * Primary and secondary data sets for the spline preview
   */
  splinePreviewProps: Omit<SplineChartProps, 'preview'>;
  /**
   * When selected is true, shows a gray background
   */
  selected: boolean;
  /**
   * If the change is positive or negative
   */
  direction: Direction;
  onClick: () => void;
  children?: React.ReactNode;
}

/**
 * The metric summary card with a spline preview for use on the Beacon summary page.
 */
const MetricSummaryTrendCard = ({
  variant,
  children,
  ...rest
}: MetricSummaryTrendCardProps & { variant: 'large' | 'small' | 'medium' }) => {
  if (variant === 'small') {
    return <MetricsSummaryTrendCardSmall {...rest} />;
  }
  return (
    <MetricSummaryTrendCardLarge variant={variant} {...rest}>
      {children}
    </MetricSummaryTrendCardLarge>
  );
};

/**
 * Large variant of the metric summary card
 */
const MetricSummaryTrendCardLarge = ({
  title,
  value,
  change,
  splinePreviewProps,
  selected,
  direction,
  onClick,
  children,
  variant
}: MetricSummaryTrendCardProps & { variant: 'large' | 'medium' }) => {
  const theme = useStacklineTheme();
  const backgroundColor = hexToRgba(theme.colors.secondaryPorcelain, 0.5);

  return (
    <Box
      sx={{
        height: children ? undefined : BEACON_PRO_LARGE_CARD_HEIGHT,
        minHeight: BEACON_PRO_LARGE_CARD_HEIGHT,
        width: variant === 'large' ? BEACON_PRO_CARD_WIDTH : FORECAST_KEY_METRICS_CARD_WIDTH,
        backgroundColor: selected ? hexToRgba(theme.colors.secondaryPorcelain, 0.5) : undefined,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor
        },
        borderRadius: theme.spacing.sm
      }}
      onClick={onClick}
      role="button"
    >
      <SlColumn spacing="sm" horizontalInset="md" verticalInset="md">
        <Text variant="subtitle3" color="secondary" transform="uppercase">
          {title}
        </Text>
        <SlRow spacing="sm" verticalPosition="center">
          <Text variant="h2">{value}</Text>
          <SlRow verticalPosition="center" spacing="xs">
            {getIconForMetricChange(null, direction)}
            <Text variant="subtitle3" color={getColorForMetricChangeValue(null, direction)}>
              {change}
            </Text>
          </SlRow>
        </SlRow>
        <SlColumn spacing="lg">
          <SplineChart {...splinePreviewProps} preview="large" width={variant === 'medium' ? 210 : undefined} />
          {children}
        </SlColumn>
      </SlColumn>
    </Box>
  );
};

/**
 * Small variant of the metric summary card
 */
const MetricsSummaryTrendCardSmall = ({
  title,
  value,
  change,
  splinePreviewProps,
  selected,
  direction,
  onClick
}: MetricSummaryTrendCardProps) => {
  const theme = useStacklineTheme();
  const backgroundColor = hexToRgba(theme.colors.secondaryPorcelain, 0.5);

  return (
    <Box
      sx={{
        height: '96px',
        width: BEACON_PRO_CARD_WIDTH,
        backgroundColor: selected ? backgroundColor : undefined,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor
        },
        borderRadius: theme.spacing.sm
      }}
      onClick={onClick}
    >
      <SlColumn horizontalInset="md" style={{ paddingTop: '13px', paddingBottom: '13px' }} widths="full">
        <SlRow>
          <SlColumn>
            <Text variant="subtitle3" color="secondary" transform="uppercase">
              {title}
            </Text>
          </SlColumn>
        </SlRow>
        <SlRow verticalInset="sm" horizontalPosition="space-between">
          <SlColumn spacing="sm">
            <Text variant="body0">{value}</Text>
            <SlRow spacing="xs" verticalPosition="center">
              {getIconForMetricChange(null, direction)}
              <Text variant="subtitle3" color={getColorForMetricChangeValue(null, direction)}>
                {change}
              </Text>
            </SlRow>
          </SlColumn>
          <Box sx={{ transform: 'translateY(-32px)' }}>
            <SplineChart preview="small" {...splinePreviewProps} />
          </Box>
        </SlRow>
      </SlColumn>
    </Box>
  );
};

export default MetricSummaryTrendCard;
