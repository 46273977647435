/* eslint-disable react/prop-types */
import React from 'react';
import Chip from '@mui/material/Chip';
import withStyles from '@mui/styles/withStyles';

import colors from 'src/utils/colors';
import { PropTypesOf } from 'src/types/utils';
import { ClassNameMap, StyleRules } from '@mui/styles';

const styles: StyleRules = {
  clickableColorPrimary: {
    color: 'white',
    background: colors.darkBlue,
    '&:hover': {
      background: colors.darkBlue
    }
  },
  clickable: {
    color: colors.darkBlue,
    background: 'white',
    border: `1px solid ${colors.darkBlue}`
  }
};

/**
 * This component creates a chip with a black border and black text
 */
const BorderedChip: React.FC<
  Omit<PropTypesOf<typeof Chip>, 'classes'> & {
    classes: ClassNameMap;
    overrideClasses: ClassNameMap;
  }
> = ({ classes, overrideClasses, ...props }) => <Chip {...props} classes={{ ...classes, ...overrideClasses }} />;

const BorderedChipWithStyles = withStyles(styles)(BorderedChip);

const EnhancedFlatChip: React.FC<{ classes?: ClassNameMap }> = ({ classes = {}, ...props }) => (
  <BorderedChipWithStyles overrideClasses={classes} {...props} />
);

export default EnhancedFlatChip;
