import React, { useMemo } from 'react';
import GenericChart from 'src/components/Charts/GenericChart';
import Highcharts from 'highcharts';
import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import _omit from 'lodash/omit';
import { ConvertSeriesToDelimitedDataFunction } from '../types';

interface ColumnWithPointChartProps {
  /**
   * Series options for the column data.
   */
  columnData?: Highcharts.SeriesColumnOptions;
  /**
   * Series options for the point data. Internally it
   * is rendered as a line since a shared tooltip is not supported
   * for scatter plots, but it will still look like a scatter
   * plot with points.
   */
  pointData?: Highcharts.SeriesLineOptions;
  /**
   * Optional chart option overrides.
   */
  chartProps?: Partial<Highcharts.Options>;
  /**
   * GenericChart prop for converting the stacked column data
   * to CSV. Should be given if `exporting.enabled` is true
   * in `chartProps`.
   */
  convertSeriesToDelimitedData?: ConvertSeriesToDelimitedDataFunction;
}

/**
 * Renders a chart that shows column bars as well as points on top of it.
 * It can also render each one individually if `columnData` or `pointData`
 * is passed in without the other.
 */
const ColumnWithPointChart = ({ columnData, pointData, chartProps, ...rest }: ColumnWithPointChartProps) => {
  const mergedChartProps: Highcharts.Options = useMemo(() => {
    const defaultOptions: Highcharts.Options = {
      chart: {
        type: 'column'
      },
      xAxis: [
        _merge(
          {
            labels: {
              formatter() {
                return `${this.value}`;
              },
              style: {
                'font-size': '14px'
              }
            }
          },
          chartProps.xAxis || {}
        )
      ],
      yAxis: [
        _merge(
          {
            labels: {
              style: {
                'font-size': '14px'
              }
            }
          },
          chartProps.yAxis ? chartProps.yAxis[0] : {}
        ),
        _merge(
          {
            labels: {
              style: {
                'font-size': '14px'
              }
            },
            opposite: true
          },
          chartProps.yAxis ? chartProps.yAxis[1] : {}
        )
      ],
      plotOptions: {
        barWidth: 130,
        // Shared tooltip isn't enabled for a scatter chart so we
        // render a line chart and make it look like scatter points
        line: {
          animation: false,
          lineWidth: 0,
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 8
          },
          states: {
            hover: {
              lineWidth: 0
            }
          }
        }
      }
    };
    return _merge({}, defaultOptions, _omit(chartProps, ['xAxis', 'yAxis']));
  }, [chartProps]);

  const columnSeries: Highcharts.SeriesColumnOptions[] = useMemo(
    () =>
      columnData
        ? [
            {
              type: 'column',
              yAxis: 0,
              ..._cloneDeep(columnData)
            }
          ]
        : [],
    [columnData]
  );

  const scatterSeries: Highcharts.SeriesLineOptions[] = useMemo(
    () =>
      pointData
        ? [
            {
              type: 'line',
              marker: {
                radius: 8
              },
              yAxis: 1,
              ..._cloneDeep(pointData)
            }
          ]
        : [],
    [pointData]
  );

  return (
    <GenericChart
      chartSeries={[...columnSeries, ...scatterSeries]}
      chartProps={_cloneDeep(mergedChartProps)}
      {...rest}
    />
  );
};

export default ColumnWithPointChart;
