import axios from 'axios';
import _get from 'lodash/get';

/**
 * Fetches a lis of approved domains for the user's division
 */
export default async function GetUserDivisionAccountConfig(activeUserEmail: string): Promise<string[]> {
  const res = await axios.get('api/user/GetUserDivisionAccountConfig', {
    params: {
      activeUserEmail
    }
  });

  const { data } = res;
  return _get(data, ['authorizedClientDomains'], []);
}
