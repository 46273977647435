import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import _isEmpty from 'lodash/isEmpty';

import { shouldUsePlatformFilters } from 'src/utils/filters';
import { INDEX_FIELDS, ENTITIES, METRICTYPE, DATATYPE } from 'src/utils/entityDefinitions';
import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import AtlasProductCellFormatter, {
  mkAtlasProductCellFormatter
} from 'src/components/EntityGrid/Table/AtlasProductCellFormatter';
import EntityGrid from 'src/components/EntityGrid';
import { mkNumeralFormatter } from 'src/utils/stringFormatting';
import CustomAgGridHeaderTemplate from 'src/components/EntityGrid/Table/CustomAgGridHeaderTemplate';
import { withProps } from 'src/utils/hoc';
import { EntityGridNoclipHeaderWrapper } from 'src/components/EntityGrid/HeaderComponentFrameworks/MultiLineHCF';
import { withManualBrHeader } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import { buildTrendWidgetConfig, buildTopEntitiesWidgetConfig } from 'src/components/Layout/LayoutUtil';
import { buildMetricValue } from 'src/utils/metrics';
import VIEWS from '../ViewDefaultConfig';
import RetailSalesPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Summary/Sales/RetailSales/RetailSalesPage';
import UnitsSoldPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Summary/Sales/UnitsSold/UnitsSoldPage';
import MarginsPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Summary/Sales/Margins/MarginsPage';
import ReturnsPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Summary/Sales/Returns/ReturnsPage';
import { shouldShowNewBeacon } from 'src/utils/app';
import { getWidgetBarChartStyles } from 'src/components/BeaconRedesignComponents/styles/styles';
import { BEACON_SUBTABS } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';
import PromotionsSummaryPage from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/Promotions/PromotionsSummaryPage';

const getCRFByFieldName = (fieldName, currencySymbol, locale) => {
  switch (fieldName) {
    case 'retailSales':
    case 'wholesaleSales':
    case 'retailerGrossMargin':
    case 'brandGrossMargin':
      return mkAtlasProductCellFormatter((rawValue) => {
        const {
          prefix = '',
          value = '',
          suffix = ''
        } = buildMetricValue(rawValue, METRICTYPE.MONEY, currencySymbol, false, DATATYPE.DECIMAL, locale);
        return `${prefix}${value}${suffix}`;
      });

    case 'unitsSold':
    case 'unitsOnHand':
      return mkAtlasProductCellFormatter(mkNumeralFormatter('√'));

    default:
      return AtlasProductCellFormatter;
  }
};

export const getRetailSalesAggregationFields = ({ app, retailer }) => {
  const aggregationFields = [
    'retailSales',
    'unitsSold',
    'wholesaleSales',
    'retailPrice',
    'instockRate',
    'unitsOnHand'
  ].map((fieldName) => INDEX_FIELDS.getField(app.name, 'sales', fieldName, 'product', 'stacklineSku'));

  const metricFields = aggregationFields.map((field) => ({
    ...field,
    cellRendererFramework: getCRFByFieldName(field.name, retailer.currencySymbol, retailer.locale),
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  }));

  return { aggregationFields, metricFields };
};

export default function getConversionPageLayout({ app, retailer, metricType, entity, filters = {} }) {
  let indexName = 'sales';

  if (!_isEmpty(filters.platform) && shouldUsePlatformFilters(app.queryParams)) {
    indexName = 'salesbyplatform';
  }
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId'));

  const dataConfig = {
    indexName,
    retailerEntity: _cloneDeep(ENTITIES.beacon.retailer),
    categoryEntity: _cloneDeep(ENTITIES.beacon.category),
    relatedEntity: _cloneDeep(ENTITIES.beacon.brand),
    weekIdField: _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'weekId')),
    retailerIdField: _cloneDeep(INDEX_FIELDS.getField(app.name, indexName, 'retailerId'))
  };

  const widgets = [];
  if (metricType === 'retailSales') {
    if (shouldShowNewBeacon()) {
      widgets.push({
        name: 'retailSales',
        CustomComponent: RetailSalesPage,
        view: {}
      });
    } else {
      // Retail Sales
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['retailSales'], weekIdField));
      // Wholesale Sales
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['wholesaleSales'], weekIdField));
      // Retail Sales by Subcategory
      const noOfTopEnitities = 50;
      widgets.push(
        buildTopEntitiesWidgetConfig(
          app,
          'sales',
          entity,
          [
            {
              groupByFieldName: 'subCategoryId',
              aggregationFieldNames: ['retailSales']
            }
          ],
          noOfTopEnitities,
          weekIdField,
          {
            replaceSubCategoryWithParent: true,
            view: {
              titleOverride: 'Retail Sales by Subcategory',
              container: {
                style: getWidgetBarChartStyles()
              },
              chartPropsOverride: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                },
                xAxis: [
                  {
                    min: 0,
                    max: 9
                  }
                ]
              }
            }
          }
        )
      );
    }

    const { aggregationFields, metricFields } = getRetailSalesAggregationFields({ app, retailer });

    if (!shouldShowNewBeacon()) {
      widgets.push({
        name: 'entityGrid',
        CustomComponent: EntityGrid,
        view: _merge({}, VIEWS.entityGrid, { gridOptions: {} }),
        data: {
          defaultSortField: INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', 'product', 'stacklineSku'),
          groupByFields: [INDEX_FIELDS.getField(app.name, 'sales', 'stacklineSku', 'product')],
          configByGroupByFieldName: {
            stacklineSku: {
              indexName: 'sales',
              entity: ENTITIES.atlas.product,
              mainMetricField: INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', 'product', 'stacklineSku'),
              aggregationFields,
              tableView: {
                metricFields
              }
            }
          }
        }
      });
    }
  } else if (metricType === BEACON_SUBTABS.PROMOTIONS) {
    widgets.push({
      name: 'promotionsSummaryPage',
      CustomComponent: PromotionsSummaryPage,
      view: {}
    });
  } else if (metricType === 'unitsSold') {
    if (shouldShowNewBeacon()) {
      widgets.push({
        name: 'unitsSold',
        CustomComponent: UnitsSoldPage,
        view: {}
      });
    } else {
      // Units Sold
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['unitsSold'], weekIdField));
      // Retail Price
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['retailPrice'], weekIdField));
      // Units Sold by Subcategory
      const noOfTopEnitities = 50;
      widgets.push(
        buildTopEntitiesWidgetConfig(
          app,
          'sales',
          entity,
          [
            {
              groupByFieldName: 'subCategoryId',
              aggregationFieldNames: ['unitsSold']
            }
          ],
          noOfTopEnitities,
          weekIdField,
          {
            replaceSubCategoryWithParent: true,
            view: {
              titleOverride: 'Units Sold by Subcategory',
              container: {
                style: getWidgetBarChartStyles()
              },
              chartPropsOverride: {
                horizontalScrolling: {
                  enabled: true,
                  step: 3
                },
                xAxis: [
                  {
                    min: 0,
                    max: 9
                  }
                ]
              }
            }
          }
        )
      );
    }

    const aggregationFields = [
      INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, 'sales', 'unitsSold', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, 'sales', 'wholesaleSales', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, 'sales', 'retailPrice', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, 'sales', 'instockRate', 'product', 'stacklineSku'),
      INDEX_FIELDS.getField(app.name, 'sales', 'unitsOnHand', 'product', 'stacklineSku')
    ];

    // Table
    const gridAggregationFields = aggregationFields.map((field) => ({
      ...field,
      cellRendererFramework: getCRFByFieldName(field.name, retailer.currencySymbol, retailer.locale),
      cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
    }));

    if (!shouldShowNewBeacon()) {
      widgets.push({
        name: 'entityGrid',
        CustomComponent: EntityGrid,
        view: _merge({}, VIEWS.entityGrid, { gridOptions: {} }),
        data: {
          defaultSortField: INDEX_FIELDS.getField(app.name, 'sales', 'unitsSold', 'product', 'stacklineSku'),
          groupByFields: [INDEX_FIELDS.getField(app.name, 'sales', 'stacklineSku', 'product')],
          configByGroupByFieldName: {
            stacklineSku: {
              indexName: 'sales',
              entity: ENTITIES.atlas.product,
              mainMetricField: INDEX_FIELDS.getField(app.name, 'sales', 'unitsSold', 'product', 'stacklineSku'),
              aggregationFields,
              tableView: {
                metricFields: gridAggregationFields
              }
            }
          }
        }
      });
    }
  } else if (metricType === 'retailerGrossMargin') {
    if (shouldShowNewBeacon()) {
      widgets.push({
        name: 'margins',
        CustomComponent: MarginsPage,
        view: {}
      });
    } else {
      // Retailer Margin
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['retailerGrossMargin'], weekIdField));

      // Retailer Margin %
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['retailerGrossMarginPercent'], weekIdField));

      // COGs per
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['brandCogs'], weekIdField));

      // Brand Margin
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['brandGrossMargin'], weekIdField));

      // Brand Margin Percent
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['brandGrossMarginPercent'], weekIdField));

      const aggregationFields = [
        'retailSales',
        'unitsSold',
        'wholesaleSales',
        'retailPrice',
        'retailerGrossMargin',
        'brandCogs',
        'brandGrossMargin'
      ].map((fieldName) => INDEX_FIELDS.getField(app.name, 'sales', fieldName, 'product', 'stacklineSku'));

      const fieldOverridesByFieldName = {
        retailerGrossMargin: {
          displayName: 'Retailer|Margin'
        }
      };

      // Table
      const gridAggregationFields = aggregationFields.map((field) => ({
        ...field,
        cellRendererFramework: getCRFByFieldName(field.name, retailer.currencySymbol, retailer.locale),
        headerComponentFramework: withManualBrHeader(
          withProps({
            sortArrowStyle: !field.name.includes('|') ? { marginTop: '1em' } : undefined
          })(CustomAgGridHeaderTemplate)
        ),
        ...(fieldOverridesByFieldName[field.name] || {}),
        cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
      }));

      widgets.push({
        name: 'entityGrid',
        CustomComponent: EntityGrid,
        view: _merge({}, VIEWS.entityGrid, {
          gridOptions: {},
          WrapperComponent: EntityGridNoclipHeaderWrapper
        }),
        data: {
          defaultSortField: INDEX_FIELDS.getField(app.name, 'sales', 'retailerGrossMargin', 'product', 'stacklineSku'),
          groupByFields: [INDEX_FIELDS.getField(app.name, 'sales', 'stacklineSku', 'product')],
          configByGroupByFieldName: {
            stacklineSku: {
              indexName: 'sales',
              entity: ENTITIES.atlas.product,
              mainMetricField: INDEX_FIELDS.getField(
                app.name,
                'sales',
                'retailerGrossMargin',
                'product',
                'stacklineSku'
              ),
              aggregationFields,
              tableView: {
                metricFields: gridAggregationFields
              }
            }
          }
        }
      });
    }
  } else if (metricType === 'returnRate') {
    if (shouldShowNewBeacon()) {
      widgets.push({
        name: 'returns',
        CustomComponent: ReturnsPage,
        view: {}
      });
    } else {
      // Return Rate %
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['returnRate'], weekIdField));
      // Return Rate - Units
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['unitsReturned'], weekIdField));
      // Return Rate - Retail Value
      widgets.push(buildTrendWidgetConfig(app, 'sales', entity, 'weekId', ['returnRetailValue'], weekIdField));

      // Table
      const aggregationFields = [
        'retailSales',
        'unitsSold',
        'wholesaleSales',
        'retailPrice',
        'returnRate',
        'unitsReturned'
      ].map((fieldName) => INDEX_FIELDS.getField(app.name, 'sales', fieldName, 'product', 'stacklineSku'));

      const fieldOverridesByFieldName = {
        unitsReturned: {
          displayName: 'Units|Returned'
        }
      };

      const gridAggregationFields = aggregationFields.map((field) => ({
        ...field,
        cellRendererFramework: getCRFByFieldName(field.name, retailer.currencySymbol, retailer.locale),
        headerComponentFramework: withManualBrHeader(
          withProps({
            sortArrowStyle: !field.name.includes('|') ? { marginTop: '1em' } : undefined
          })(CustomAgGridHeaderTemplate)
        ),
        ...(fieldOverridesByFieldName[field.name] || {}),
        cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
      }));

      widgets.push({
        name: 'entityGrid',
        CustomComponent: EntityGrid,
        view: _merge({}, VIEWS.entityGrid, {
          WrapperComponent: EntityGridNoclipHeaderWrapper,
          gridOptions: {}
        }),
        data: {
          defaultSortField: INDEX_FIELDS.getField(app.name, 'sales', 'unitsReturned', 'product', 'stacklineSku'),
          groupByFields: [INDEX_FIELDS.getField(app.name, 'sales', 'stacklineSku', 'product')],
          configByGroupByFieldName: {
            stacklineSku: {
              indexName: 'sales',
              entity: ENTITIES.atlas.product,
              mainMetricField: INDEX_FIELDS.getField(app.name, 'sales', 'unitsReturned', 'product', 'stacklineSku'),
              aggregationFields,
              tableView: {
                metricFields: gridAggregationFields
              }
            }
          }
        }
      });
    }
  }

  return {
    CustomPageContainer: GenericPageContainer,
    enableComparison: true,
    widgets,
    dataConfig
  };
}
