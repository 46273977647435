import types from './types';
import actions from './actions';
import { ValueOf } from 'sl-api-connector/types';

export interface OmniKeywordCategoriesState {
  isFetching: boolean;
  data: { keywordCategoryId: string; keywordCategoryName: string }[];
}

const initialState: OmniKeywordCategoriesState = {
  isFetching: true,
  data: []
};

const omniKeywordCategoriesService = (
  state: OmniKeywordCategoriesState = initialState,
  action: ReturnType<ValueOf<typeof actions>>
) => {
  switch (action.type) {
    case types.RECEIVE_ALL_OMNI_KEYWORD_CATEGORIES:
      return {
        isFetching: false,
        data: Object.assign([], action.data)
      };
    default:
      return state;
  }
};

export default omniKeywordCategoriesService;
