export class SearchGridDataMappers {
  public static getPortfolioGridDataMergedWithMetrics(portfolioMetadata: any, portfolioWithMetric: any) {
    const { id: idOnMetricData, entity: entityObj, ...portfolioMetrics } = portfolioWithMetric;
    const transformedPortfolio = {
      id: idOnMetricData,
      ...portfolioMetadata,
      ...portfolioMetrics,
      entity: entityObj
    };
    return transformedPortfolio;
  }

  public static getPortfolioGridDataFromMetadata(portfolioMetadata: any, portfolioIdLower: string) {
    return {
      id: portfolioIdLower,
      entity: {
        ...portfolioMetadata,
        name: portfolioMetadata.name,
        type: 'adPortfolio'
      }
    };
  }
}
