import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { withBus } from 'react-bus';
import { AppName } from 'sl-api-connector/types';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import moment, { Moment } from 'moment';
import queryString from 'qs';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import colors from 'src/utils/colors';
import fontStyle from 'src/utils/fontStyle';
import {
  computeMainTimePeriod,
  getLastWeek,
  buildCustomDateRangeDisplayName,
  getFirstWeekFirstYear,
  getWeekLastDate,
  getWeekFirstDate,
  computeComparisonTimePeriod,
  getDayIdFromDate,
  capDateToCurrentDate
} from 'src/utils/dateformatting';
import { getWeekId, getStartAndEndOfWeek } from 'src/utils/dateUtils';
import { propEq } from 'src/utils/fp';
import * as appOperations from 'src/store/modules/app/operations';
import { updateAllTimePeriods, updateMainTimePeriod } from 'src/store/modules/main-time-period/operations';
import ReduxStore from 'src/types/store/reduxStore';
import { QueryResponse } from 'src/types/application/types';
import { CalendarDisplay } from 'src/components/common/DatePicker';
import { ChevronIcon } from 'src/components/SvgIcons';
import { EventBus } from 'src/types/utils';
import _isEqual from 'lodash/isEqual';
import { isConnectDashboard } from 'src/utils/app';
import AmcDashboardDropDown from './AmcDashboardDropDown';

const mapStateToProps = (state: ReduxStore) =>
  _pick(state, ['allWeekIdsByRetailerId', 'comparisonTimePeriod', 'app', 'retailer', 'mainTimePeriod']);

type MainTimePeriodDropDownProps = RouteComponentProps &
  ReturnType<typeof mapStateToProps> & {
    labelStyle?: { color: string; fontWeight: number };
    updateAppMainTimePeriod: any;
    updateQueryParams: any;
    updateAllAppTimePeriods: any;
    eventBus: EventBus;
  };

interface MainTimePeriodDropDownState {
  isCalendarOpen: boolean;
  calendarMaxDate: Moment | null;
  calendarMinDate: Moment | null;
}

class MainTimePeriodDropDown extends Component<MainTimePeriodDropDownProps, MainTimePeriodDropDownState> {
  public static readonly defaultProps = {
    labelStyle: { color: colors.darkBlue, fontWeight: fontStyle.regularWeight }
  };

  public state = {
    isCalendarOpen: false,
    calendarMinDate: null,
    calendarMaxDate: null
  };

  // Refactor this to use allWeeksIdsByRetailerId from props instead of from user object
  public componentWillMount() {
    const { allWeekIdsByRetailerId, mainTimePeriod, retailer, updateAppMainTimePeriod, app } = this.props;
    const { availableMainTimePeriods } = mainTimePeriod;
    const selectedOption = _cloneDeep(
      availableMainTimePeriods.filter((val) => val.id === mainTimePeriod.id)[0] || availableMainTimePeriods[4]
    );
    if (selectedOption.id === 'cd') {
      const shortDisplayName = buildCustomDateRangeDisplayName(mainTimePeriod.startDayId, mainTimePeriod.endDayId);
      updateAppMainTimePeriod(
        mainTimePeriod.startWeek,
        mainTimePeriod.endWeek,
        selectedOption.id,
        shortDisplayName,
        availableMainTimePeriods,
        mainTimePeriod.startWeekStartDate,
        mainTimePeriod.startDayId,
        mainTimePeriod.endWeekEndDate,
        mainTimePeriod.endDayId
      );
    }
    const calendarMinDate = getWeekFirstDate(getFirstWeekFirstYear(allWeekIdsByRetailerId[+retailer.id]));
    let calendarMaxDate = capDateToCurrentDate(getWeekLastDate(getLastWeek(allWeekIdsByRetailerId[+retailer.id])));
    if (app.name === AppName.Advertising) {
      calendarMaxDate = moment(capDateToCurrentDate(new Date())).subtract(1, 'd');
    }
    this.setState({
      calendarMinDate: moment(calendarMinDate),
      calendarMaxDate: moment(calendarMaxDate)
    });
  }

  public shouldComponentUpdate(nextProps: MainTimePeriodDropDownProps, nextState: MainTimePeriodDropDownState) {
    const { allWeekIdsByRetailerId: preAllWeekIdsByRetailerId } = this.props;
    const { allWeekIdsByRetailerId, retailer } = nextProps;
    if (!_isEqual(preAllWeekIdsByRetailerId, allWeekIdsByRetailerId)) {
      const calendarMinDate = getWeekFirstDate(getFirstWeekFirstYear(allWeekIdsByRetailerId[+retailer.id]));
      const calendarMaxDate = capDateToCurrentDate(getWeekLastDate(getLastWeek(allWeekIdsByRetailerId[+retailer.id])));
      this.setState({
        calendarMinDate: moment(calendarMinDate),
        calendarMaxDate: moment(calendarMaxDate)
      });
    }
    if (!_isEqual(this.props, nextProps) || !_isEqual(this.state, nextState)) {
      return true;
    }
    return false;
  }

  public componentDidMount() {
    this.props.eventBus.on('setMainTimePeriod', this.handleDateRangeChange);
  }

  public componentWillUnmount() {
    this.props.eventBus.off('setMainTimePeriod', this.handleDateRangeChange);
  }

  private updateWeeks(
    mainStartWeek: number,
    mainEndWeek: number,
    mainId: string,
    shortDisplayName: string | null,
    isCustomDate: boolean,
    startDayId: number,
    endDayId: number,
    startWeekStartDate: Date,
    endWeekEndDate: Date
  ) {
    const {
      app,
      allWeekIdsByRetailerId,
      comparisonTimePeriod,
      location,
      retailer,
      updateAllAppTimePeriods,
      updateQueryParams
    } = this.props;
    const allWeekIds = allWeekIdsByRetailerId[+retailer.id];
    const { id: comparisonTimeWindow } = _cloneDeep(comparisonTimePeriod);
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    let updatedMainPeriodDisplayName = shortDisplayName;
    if (isCustomDate) {
      updatedMainPeriodDisplayName = buildCustomDateRangeDisplayName(startDayId, endDayId);
      this.setState({ isCalendarOpen: false });
    }
    const updatedMainPeriod = {
      startWeek: mainStartWeek,
      endWeek: mainEndWeek,
      startDayId,
      endDayId,
      startWeekStartDate,
      endWeekEndDate,
      id: mainId,
      shortDisplayName: updatedMainPeriodDisplayName
    };
    const updatedComparisonPeriod = computeComparisonTimePeriod(allWeekIds, updatedMainPeriod, comparisonTimeWindow);
    updatedComparisonPeriod.availableComparisonTimePeriods = comparisonTimePeriod.availableComparisonTimePeriods;
    updatedComparisonPeriod.comparisonIndex = comparisonTimePeriod.availableComparisonTimePeriods
      .map((x) => x.id)
      .indexOf(comparisonTimePeriod.id);
    updateAllAppTimePeriods(allWeekIds, updatedMainPeriod, updatedComparisonPeriod);
    updateQueryParams(app, retailer, updatedMainPeriod, updatedComparisonPeriod, queryParams).then(
      (response: QueryResponse) => {
        const { nonPersistentParams, searchParams, dropDownSelectionParams } = response.params;
        return this.props.history.push(
          `${location.pathname}${searchParams}${dropDownSelectionParams}${nonPersistentParams}`
        );
      }
    );
  }

  // This function needs to be updated
  private handleDateRangeChange = (option: string) => {
    const { allWeekIdsByRetailerId, mainTimePeriod, retailer, app } = this.props;
    const { availableMainTimePeriods } = mainTimePeriod;
    const weekIds = allWeekIdsByRetailerId[+retailer.id];
    if (option === 'cd') {
      return;
    }
    const [mainTimePeriodSelectedOption] = availableMainTimePeriods.filter(propEq('id', option));
    const newTimePeriod = computeMainTimePeriod(weekIds, mainTimePeriodSelectedOption, app);
    const { shortDisplayName, endWeek, id, startWeek, startDayId, endDayId, startWeekStartDate, endWeekEndDate } =
      newTimePeriod;
    this.updateWeeks(
      startWeek,
      endWeek,
      id,
      shortDisplayName,
      false,
      startDayId,
      endDayId,
      startWeekStartDate,
      endWeekEndDate
    );
  };

  private handleCustomDateRangeChange = ({
    startDate,
    endDate
  }: {
    startDate: string | null;
    endDate: string | null;
  }) => {
    if (startDate === null || endDate === null) {
      return;
    }

    const startWeekId = getWeekId(startDate);
    const endWeekId = getWeekId(endDate);
    let { startDate: startWeekStartDate } = getStartAndEndOfWeek(startWeekId);
    let { endDate: endWeekEndDate } = getStartAndEndOfWeek(endWeekId);

    if (this.props.app.dataFrequency === 'daily') {
      startWeekStartDate = startDate;
      endWeekEndDate = endDate;
    }

    this.updateWeeks(
      startWeekId,
      endWeekId,
      'cd',
      null,
      true,
      getDayIdFromDate(startWeekStartDate),
      getDayIdFromDate(endWeekEndDate),
      startWeekStartDate,
      endWeekEndDate
    );
  };

  private handleOutsideClick = () =>
    this.setState({
      ...this.state,
      isCalendarOpen: false
    });

  public render() {
    const { mainTimePeriod } = this.props;
    const { availableMainTimePeriods } = mainTimePeriod;
    const { isCalendarOpen, calendarMaxDate, calendarMinDate } = this.state;
    const timePeriodsToRender = availableMainTimePeriods;
    if (isConnectDashboard()) {
      return <AmcDashboardDropDown />;
    }

    if (!mainTimePeriod.id) {
      return null;
    }

    return (
      <>
        <div style={{ display: 'inline-block' }}>
          <Select
            variant="standard"
            renderValue={() => mainTimePeriod.shortDisplayName}
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              }
            }}
            value={mainTimePeriod.id}
            // The type given by Material UI disagrees with the type of the actual event.
            onChange={(evt) => this.handleDateRangeChange(evt.target.value as string)}
            input={<OutlinedInput labelWidth={0} id="outlined-age-simple" />}
            IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
          >
            {timePeriodsToRender.map((opt, index) =>
              // The connect dashboard should not do anything special to the last item in the time
              // period dropdown because for the rest of the app it is a custom date picker
              index < timePeriodsToRender.length - 1 ? (
                <MenuItem key={opt.id} value={opt.id}>
                  {opt.shortDisplayName}
                  {opt.id === 'ly' ? ` (${Math.floor(opt.startWeek / 100)})` : ''}
                </MenuItem>
              ) : null
            )}
            {/* Last item defaults to custom dropdown. With the Connect dashboard we don't have a custom dropdown yet
                TODO undo this for phase 2
            */}
            <hr className="sl-divider sl-divider--sm-margin-top sl-divider--sm-margin-bottom" />
            <MenuItem
              key={timePeriodsToRender[timePeriodsToRender.length - 1].id}
              value={timePeriodsToRender[timePeriodsToRender.length - 1].id}
              onClick={() => {
                this.setState({ isCalendarOpen: true });
              }}
            >
              {timePeriodsToRender[timePeriodsToRender.length - 1].shortDisplayName}
            </MenuItem>
          </Select>
        </div>
        {isCalendarOpen && (
          <CalendarDisplay
            handleCustomDateRangeChange={this.handleCustomDateRangeChange}
            handleOutsideClick={this.handleOutsideClick}
            maxDate={calendarMaxDate!}
            minDate={calendarMinDate!}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  updateAppMainTimePeriod: updateMainTimePeriod,
  updateAllAppTimePeriods: updateAllTimePeriods,
  updateQueryParams: appOperations.updateQueryParams
};

export default withRouter(withBus('eventBus')(connect(mapStateToProps, mapDispatchToProps)(MainTimePeriodDropDown)));
