import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';

import colors from 'src/utils/colors';
import { GearIcon } from '../../SvgIcons';

const styles = {
  accountReviewsLink: {
    float: 'right',
    color: colors.blue,
    marginTop: 60
  },
  gearIcon: {
    verticalAlign: 'middle',
    width: 32,
    height: 32
  }
};

const propTypes = {
  highRiskReviews: PropTypes.object
};

const defaultProps = {
  highRiskReviews: null
};

/**
 * This only renders the header message if no high risk reviews of the header and gear icon if they exist.
 */
const HighRiskReviews = ({ highRiskReviews }) => {
  if (!_get(highRiskReviews, ['keywords'])) {
    return (
      <div style={{ marginTop: 40 }}>
        You do not have this feature enabled for the current retailer. Upload high risk keywords{' '}
        <Link style={{ color: colors.blue }} to="/account/reviews">
          here.
        </Link>
      </div>
    );
  }

  if (_get(highRiskReviews, ['status', 'value']) === 'processing') {
    return (
      <div style={{ marginTop: 40 }}>
        We are processing your newly uploaded high risk keywords. This page will update automatically when it is done.
      </div>
    );
  }

  return (
    <div>
      <div style={{ overflow: 'hidden' }}>
        <h2 className="sl-header-text" style={{ float: 'left' }}>
          High Risk Review Trends
        </h2>
        <Link to="/account/reviews" style={styles.accountReviewsLink}>
          <GearIcon stroke={colors.darkBlue} style={styles.gearIcon} />
        </Link>
      </div>
      <hr className="sl-divider sl-divider--no-margin-top" />
    </div>
  );
};

HighRiskReviews.propTypes = propTypes;
HighRiskReviews.defaultProps = defaultProps;

export default HighRiskReviews;
