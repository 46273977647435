import { Box } from '@mui/system';
import { AppImage, Text, hexToRgba, useStacklineTheme } from '@stackline/ui';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { withBus } from 'react-bus';
import { useQueryClient } from 'react-query';
import EntityTable from 'src/components/BeaconRedesignComponents/EntityTableRefresh/EntityTable';
import { DisputeKeyMetricCard } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/DisputeKeyMetricCard';
import {
  DISPUTE_DATA_QUERY_KEY,
  DISPUTE_STAGE,
  DISPUTE_STATUS,
  DISPUTE_SUBMISSION_EVENT
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/constants';
import {
  getDisputeTableColumnDefinitions,
  keyMetricCardConfigs
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/defaultConfigs';
import DisputeFilterModal from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/modals/DisputeFilterModal';
import {
  Dispute,
  DisputeRow
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Finance/DisputeManagement/types';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import Pagination from 'src/components/BeaconRedesignComponents/Pagination/Pagination';
import StyledInput from 'src/components/BeaconRedesignComponents/common/StyledInput';
import { useFetchDisputeData } from 'src/components/ShortageDisputes/hooks';
import { EventBus } from 'src/types/utils';
import { useAppSelector, useHistory, useLocation, useSnackbar } from 'src/utils/Hooks';
import { getQueryParamValue, updateUrlQueryParams } from 'src/utils/app';
import * as serverProxy from './serverProxy';
import NoDataPlaceHolder from 'src/components/BeaconRedesignComponents/common/NoDataPlaceHolder/NoDataPlaceHolder';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { BEACON_PRO_PAGE_MARGIN_BOTTOM } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { PROCESSING_REQUEST_MESSAGE } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Team/constants';

export interface ToggleRowProps {
  toggledRow?: DisputeRow;
  toggleAll?: boolean;
}

enum SUBMISSION_TYPE {
  DISPUTE = 'dispute',
  CONTACT_US = 'contactUs'
}

/**
 * Filter keys appended as query keys to the URL
 */
enum FILTER_KEY {
  MIN_VALUE = 'minValue',
  MAX_VALUE = 'maxValue',
  STAGE = 'stage',
  STATUS = 'status',
  SEARCH = 'search'
}

/** Takes a dispute object and adds some additional properties before being added to the table data set */
const convertDataToRows = (rows, datum: Dispute, index: number): DisputeRow[] => {
  const row = { ...datum, isChecked: false, isSubmitting: false, index };
  return [...rows, row];
};

/**
 * Container for all UI components related to Dispute Management
 */
const DisputeDashboard = ({ eventBus }: { eventBus: EventBus }) => {
  const location = useLocation();
  const history = useHistory();
  const theme = useStacklineTheme();
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const retailerId = useAppSelector((state) => state.retailer.id);
  const isFilteredOnContactUs = getQueryParamValue('stage') === DISPUTE_STAGE.CONTACT_US_CASE;
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const filterParams = new URLSearchParams(location.search);

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchInput, setSearchInput] = useState(getQueryParamValue('search') || '');
  const [rowData, setRowData] = useState<DisputeRow[]>(null);

  // We change the max amount of rows to display based on the filter selected.
  // This allows us to select more Contact Us Cases for submission.
  const maxPageSize = isFilteredOnContactUs ? 50 : 20;
  const { data, isLoading } = useFetchDisputeData({ location, pageNumber, maxPageSize });

  useEffect(() => {
    const initialRows: DisputeRow[] = data ? data.combinedDataSet.reduce(convertDataToRows, []) : null;
    setRowData(initialRows);
  }, [data, isLoading]);

  useEffect(() => {
    setPageNumber(1);
  }, [location]);

  /**
   *  Updates the URL with preset filters for the table data based on which key metric was clicked.
   * @param disputeStage
   * @param disputeStatus
   */
  const handleKeyMetricClick = (disputeStage: DISPUTE_STAGE, disputeStatus: DISPUTE_STATUS) => {
    history.push(
      updateUrlQueryParams({
        [FILTER_KEY.STAGE]: disputeStage,
        [FILTER_KEY.STATUS]: disputeStatus,
        [FILTER_KEY.SEARCH]: null,
        [FILTER_KEY.MIN_VALUE]: null,
        [FILTER_KEY.MAX_VALUE]: null
      }) // Clear any additional filters if present
    );
  };

  const handleNextPageClick = () => {
    if (rowData.length === maxPageSize) {
      setPageNumber((page) => page + 1);
    }
  };
  const handlePreviousPageClick = () => {
    const previousPage = Math.max(1, pageNumber - 1);
    setPageNumber(previousPage);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.target instanceof HTMLInputElement) {
      const { key } = e;
      if (key === 'Enter') {
        history.push(updateUrlQueryParams({ [FILTER_KEY.SEARCH]: searchInput || null }));
      }
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleToggleRow = ({ toggledRow, toggleAll }: ToggleRowProps) => {
    if (rowData) {
      const rows = rowData.map((row) => ({
        ...row,
        isChecked: (() => {
          if (toggledRow) {
            const { originalInvoice: toggledRowInvoice, disputeStatus } = toggledRow;
            return row.originalInvoice === toggledRowInvoice &&
              (disputeStatus as DISPUTE_STATUS) === DISPUTE_STATUS.READY_TO_SUBMIT
              ? !row.isChecked
              : row.isChecked;
          } else if (toggleAll) {
            return (row.disputeStatus as DISPUTE_STATUS) === DISPUTE_STATUS.READY_TO_SUBMIT;
          } else {
            return false;
          }
        })()
      }));
      setRowData(rows);
    }
  };

  const handleSubmitDisputes = async () => {
    try {
      // Filter all selected rows
      const selectedRows = rowData.filter(({ isChecked }) => isChecked);
      // Split disputes by type: contact us cases and regular disputes
      const contactUsCaseRows = selectedRows.filter(
        ({ disputeStage }) => disputeStage === DISPUTE_STAGE.CONTACT_US_CASE
      );
      const newDisputeRows = selectedRows.filter(({ disputeStage }) => disputeStage === DISPUTE_STAGE.NEW_SHORTAGE);

      const contactUsCaseDisputes = contactUsCaseRows.map(
        ({ disputeId, originalInvoice, invoiceDate, disputeValue, purchaseOrder }) => ({
          disputeId,
          originalInvoiceNum: originalInvoice,
          invoiceDate,
          openShortageInvoiceAmount: disputeValue,
          purchaseOrderNum: purchaseOrder
        })
      );

      const newDisputes = newDisputeRows.map(({ originalInvoice }) => originalInvoice);

      // Only add the specific request body if there's disputes for that dispute type
      const requestCollection = [];
      if (contactUsCaseDisputes.length) {
        const contactUsCaseRequestBody = {
          beaconClientId,
          retailerId,
          submissionType: SUBMISSION_TYPE.CONTACT_US,
          invoicesToSubmitForDispute: [],
          disputesToSubmitForContactUs: contactUsCaseDisputes
        };
        requestCollection.push(contactUsCaseRequestBody);
      }

      if (newDisputes.length) {
        const newDisputeRequestBody = {
          beaconClientId,
          retailerId,
          submissionType: SUBMISSION_TYPE.DISPUTE,
          invoicesToSubmitForDispute: newDisputes,
          disputesToSubmitForContactUs: []
        };
        requestCollection.push(newDisputeRequestBody);
      }

      showSnackbar({
        type: 'info',
        message: PROCESSING_REQUEST_MESSAGE
      });

      // Submit disputes
      await serverProxy.submitDisputes({ requestCollection });

      // Trigger refetch of dispute data
      queryClient.invalidateQueries({ queryKey: [DISPUTE_DATA_QUERY_KEY] });

      // Trigger polling
      eventBus.emit(DISPUTE_SUBMISSION_EVENT, null);

      showSnackbar({ type: 'success', message: 'Processing is complete. Your dispute submission was successful.' });
    } catch (err) {
      console.error('Failed to submit disputes: ', err);
      showSnackbar({ type: 'error', message: 'Processing failed. Your dispute submission was unsuccessful.' });
    }
  };
  const { COLUMN_DEFINITIONS, EXPANDED_COLUMN_DEFINITIONS } = getDisputeTableColumnDefinitions({ handleToggleRow });

  const getActiveFilterStatus = (keys: string[], searchParams: URLSearchParams) => {
    return keys.some((key) => searchParams.has(key));
  };

  return (
    <Flex flexDirection="column" marginBottom={BEACON_PRO_PAGE_MARGIN_BOTTOM}>
      <Box marginTop="32px">
        <Text variant="h4">Key Metrics</Text>
      </Box>
      <Flex gap="md" flexWrap="wrap" marginTop="32px">
        {keyMetricCardConfigs.map((config, index) => {
          return (
            <DisputeKeyMetricCard
              onClick={() => handleKeyMetricClick(config.stage, config.status)}
              label={config.label}
              stage={config.stage}
              status={config.status}
              selected={
                getQueryParamValue(FILTER_KEY.STAGE) === config.stage &&
                getQueryParamValue(FILTER_KEY.STATUS) === config.status
              }
              key={index}
            />
          );
        })}
      </Flex>
      <Flex marginTop="64px" justifyContent="space-between">
        <Box position="relative">
          <AppImage
            src="/svg/icons/search.svg"
            alt="search-icon"
            style={{
              position: 'absolute',
              height: 13,
              width: 13,
              top: '9px',
              left: '14px'
            }}
          />
          <StyledInput
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            placeholder="Search disputes"
            value={searchInput}
            sx={{
              width: '281px',
              height: '30px',
              border: `1px solid ${theme.colors.primaryGray}`,
              fontSize: '14px',
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              borderRadius: '68px',
              paddingLeft: '38px',
              paddingRight: '9px',
              '&::placeholder': {
                color: hexToRgba(theme.colors.secondary, 0.5)
              }
            }}
          />
        </Box>

        <Flex gap="md">
          <SlButton
            active={getActiveFilterStatus(Object.values(FILTER_KEY), filterParams)}
            onClick={() => setFilterModalOpen(true)}
          >
            Filter
          </SlButton>
          <SlButton
            onClick={handleSubmitDisputes}
            disabled={!(rowData && rowData.some(({ isChecked }) => isChecked))}
            variant="contained"
          >
            Submit Disputes
          </SlButton>
        </Flex>
      </Flex>
      <Flex flexDirection="column" marginTop="50px" alignItems="flex-end">
        <EntityTable
          rowData={rowData || []}
          skeletonRows={20}
          isLoading={isLoading || !rowData}
          columnDefs={COLUMN_DEFINITIONS}
          expandRowDef={EXPANDED_COLUMN_DEFINITIONS}
          showPagination={false}
          shouldModifyColumnDefs={false}
          initialPageSize={maxPageSize}
          appTableProps={{
            noDataPlaceholder: <NoDataPlaceHolder />
          }}
        />

        <Pagination
          shouldUseInfinitePagination
          currentPage={pageNumber}
          onClickNext={handleNextPageClick}
          onClickPrevious={handlePreviousPageClick}
        />
      </Flex>

      {filterModalOpen && <DisputeFilterModal open={filterModalOpen} handleClose={() => setFilterModalOpen(false)} />}
    </Flex>
  );
};

export default withBus('eventBus')(DisputeDashboard);
