import React from 'react';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';

/**
 * @returns A generic error message + link to the support email to be displayed in a snackbar
 */
export const GenericErrorMessage = () => {
  return (
    <Flex>
      An error occurred. Please reach out to{' '}
      <a
        style={{
          marginLeft: '4px',
          marginRight: '4px',
          textDecoration: 'underline'
        }}
        href="mailto:support@stackline.com"
      >
        support@stackline.com
      </a>{' '}
      for further guidance.
    </Flex>
  );
};
