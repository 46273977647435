import React from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import Suggestions from '../../Suggestions';
import ChipsContainer from './ChipsContainer';

class BrandsAdvancedSearch extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    brands: PropTypes.array.isRequired,
    onBrandsChange: PropTypes.func.isRequired,
    autocompleteAppNameOverride: PropTypes.string
  };

  static defaultProps = {
    autocompleteAppNameOverride: null
  };

  componentWillMount() {
    this.updateBrands();
  }

  componentWillReceiveProps(nextProps) {
    if (!_isEqual(nextProps.brands, this.props.brands)) {
      this.updateBrands(nextProps);
    }
  }

  updateBrands = (nextProps) => {
    const { brands } = nextProps || this.props;
    this.setState({ brandChips: brands.map((brand, index) => ({ key: index, i: brand.i, n: brand.n })) });
  };

  handleSelectionChange = (selection, index) => {
    const { brandChips } = this.state;

    const clonedBrands = _cloneDeep(brandChips);
    const isAlreadySelected = clonedBrands.filter((brand) => brand.value === selection.value).length > 0;
    if (index !== -1 && !isAlreadySelected) {
      clonedBrands.push({ key: clonedBrands.length, i: selection.id, n: selection.value });
      this.handleBrandsChange(clonedBrands);
    }
  };

  handleRequestDelete = (key) => {
    const { brandChips } = this.state;
    const clonedBrands = _cloneDeep(brandChips);
    const chipToDelete = clonedBrands.map((chip) => chip.key).indexOf(key);
    clonedBrands.splice(chipToDelete, 1);
    this.handleBrandsChange(clonedBrands);
  };

  handleBrandsChange = (brands) => {
    const { onBrandsChange } = this.props;
    const values = brands.map((brand) => ({ i: brand.i, n: brand.n }));
    onBrandsChange({ key: 'brandId', values });
  };

  render() {
    const { app, autocompleteAppNameOverride } = this.props;

    return (
      <div className="search-form-container">
        <h4 className="sl-form-label">Brands</h4>
        <Suggestions
          autocompleteAppNameOverride={autocompleteAppNameOverride}
          apiUrl={`/api/${autocompleteAppNameOverride || app.apiAppName}/AutoCompleteSuggestions?term=`}
          className="sl-form-input"
          hintText="Refine by brands"
          type="brand"
          style={{ height: '48px' }}
          textFieldStyle={{ height: '48px' }}
          onSelectionChange={this.handleSelectionChange}
        />
        <ChipsContainer
          onDelete={(key) => this.handleRequestDelete(key)}
          chipData={this.state.brandChips}
          dataKey="n"
        />
      </div>
    );
  }
}

export default BrandsAdvancedSearch;
