/* eslint-disable react/prop-types */

import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';

interface Props {
  label: string;
  children?: ReactNode;
  onClick: () => void;
  style?: React.CSSProperties;
}

const DeleteButton: React.FC<Props> = ({ label, children, onClick, ...props }) => (
  <Button className="sl-delete-button sl-delete-button--xl" onClick={onClick} variant="contained" {...props}>
    {label || children || ''}
  </Button>
);

export default DeleteButton;
