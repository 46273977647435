import React, { useMemo, memo } from 'react';
import {
  SmartKeyMetricCardProps,
  getCardMetricType,
  getCardTitle
} from 'src/components/BeaconRedesignComponents/KeyMetricCards/SmartKeyMetricCard';
import useForecastMetricByWeekId from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useForecastMetricByWeekId';
import MetricSummaryTrendCardLoading from 'src/components/BeaconRedesignComponents/MetricSummaryTrendCard/MetricSummaryTrendCardLoading';
import MetricSummaryTrendCard from 'src/components/BeaconRedesignComponents/MetricSummaryTrendCard/MetricSummaryTrendCard';
import { useForecastPeriod } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import { ForecastPeriod } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import { getAppName, updateUrlQueryParams } from 'src/utils/app';
import { useHistory, useQueryParamValue } from 'src/utils/Hooks';
import useForecastDerivedMetricByWeekId from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useForecastDerivedMetricByWeekId';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';

interface SmartForecastKeyMetricCardProps extends SmartKeyMetricCardProps {
  children?: React.ReactNode;
  loading?: boolean;
}
/**
 * Key metric card for erived metrics
 */
function DerivedForecastKeyMetricCard({
  indexName,
  fieldName,
  variant,
  title,
  loading,
  ...rest
}: SmartKeyMetricCardProps) {
  const {
    isLoading: forecastLoading,
    primaryData,
    secondaryData,
    formattedPrimaryTotal,
    formattedPercentChange,
    percentChange,
    forecastData
  } = useForecastDerivedMetricByWeekId({ indexName, fieldName, ...rest });
  const forecastPeriod = useForecastPeriod();
  const history = useHistory();
  const selectedIndex = useQueryParamValue('indexName', 'sales');
  const selectedField = useQueryParamValue('keyMetric', 'retailSales');

  return forecastLoading || loading ? (
    <MetricSummaryTrendCardLoading variant={variant} />
  ) : (
    <MetricSummaryTrendCard
      variant={variant}
      title={title || getCardTitle(indexName, fieldName)}
      splinePreviewProps={{
        primaryData,
        secondaryData: forecastPeriod === ForecastPeriod.FULL_YEAR ? [] : secondaryData,
        forecastedData: forecastData,
        metricType: getCardMetricType(indexName, fieldName)
      }}
      value={formattedPrimaryTotal}
      change={formattedPercentChange}
      direction={percentChange > 0 ? 'increasing' : 'decreasing'}
      onClick={() => {
        history.push(
          updateUrlQueryParams({
            keyMetric: fieldName,
            indexName
          })
        );
      }}
      selected={selectedIndex === indexName && selectedField === fieldName}
      {...rest}
    />
  );
}

/**
 * Key metric card for all other metrics
 */
function ForecastKeyMetricCard({ indexName, fieldName, variant, title, loading, ...rest }: SmartKeyMetricCardProps) {
  const {
    isLoading: forecastLoading,
    primaryData,
    secondaryData,
    formattedPrimaryTotal,
    formattedPercentChange,
    percentChange,
    forecastData
  } = useForecastMetricByWeekId({ indexName, fieldName, ...rest });
  const forecastPeriod = useForecastPeriod();
  const history = useHistory();
  const selectedIndex = useQueryParamValue('indexName', 'sales');
  const selectedField = useQueryParamValue('keyMetric', 'retailSales');

  return forecastLoading || loading ? (
    <MetricSummaryTrendCardLoading variant={variant} />
  ) : (
    <MetricSummaryTrendCard
      variant={variant}
      title={title || getCardTitle(indexName, fieldName)}
      splinePreviewProps={{
        primaryData,
        secondaryData: forecastPeriod === ForecastPeriod.FULL_YEAR ? [] : secondaryData,
        forecastedData: forecastData,
        metricType: getCardMetricType(indexName, fieldName)
      }}
      value={formattedPrimaryTotal}
      change={formattedPercentChange}
      direction={percentChange > 0 ? 'increasing' : 'decreasing'}
      onClick={() => {
        history.push(
          updateUrlQueryParams({
            keyMetric: fieldName,
            indexName
          })
        );
      }}
      selected={selectedIndex === indexName && selectedField === fieldName}
      {...rest}
    />
  );
}

/**
 * Renders either normal or derived key metric card
 */
export default memo(function SmartForecastKeyMetricCard(args: SmartForecastKeyMetricCardProps) {
  const { indexName, fieldName } = args;
  const field = useMemo(() => INDEX_FIELDS.getField(getAppName(), indexName, fieldName), [indexName, fieldName]);

  if (field.aggregationFunctionType === 'derived') {
    return <DerivedForecastKeyMetricCard {...args} />;
  }

  return <ForecastKeyMetricCard {...args} />;
});
