import { FetchAdditionalDataFn } from './types';
import { EntitiesDataFetcher } from 'src/components/AdManager/Search/GridDataFetchers/Entities/EntitiesDataFetcher';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';

const useFetchEntityData = (): FetchAdditionalDataFn => {
  const dispatch = useDispatch();

  return useCallback(
    async ({ dataSet, pageNumber, fetchMetricsParams, aggregationFilters, searchBarConditionsForAdvancedSearch }) => {
      const entitiesDataFetcher = new EntitiesDataFetcher();
      const hasFilters =
        _get(aggregationFilters, ['computeFilters', 'length'], 0) > 0 ||
        _get(searchBarConditionsForAdvancedSearch, 'termFilters', []).some(
          ({ fieldName, values }) =>
            ['entityNameFuzzy', 'excludedEntityNameFuzzy'].includes(fieldName) && values.length > 0
        );

      const finalResult = await entitiesDataFetcher.fetchEntitiesData({
        dispatch,
        fetchMetricsParams,
        dataSet,
        pageNumber,
        cancelSource: undefined,
        returnEntitiesWithNoMetrics: !hasFilters // return entities with no metrics only if there are no filters
      });
      return finalResult;
    },
    [dispatch]
  );
};

export default useFetchEntityData;
