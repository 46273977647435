import axios from 'axios';
import _get from 'lodash/get';

interface StackLineMediaDetails {
  mediaId: string;
  beaconClientId: number;
  beaconClientLoginId: number;
  mediaName: string;
  mediaDescription: string;
  createdBy: string;
  createdTime: string;
  lastUpdatedBy: string;
  lastUpdatedTime: string;
}

interface WalmartMediaAsset {
  mediaId: number;
  status: string;
  details: string;
  name: string;
  thumbnail: null | string; // Add the proper type instead of null if available
  videoUrl: null | string; // Add the proper type instead of null if available
  captionUrl: null | string; // Add the proper type instead of null if available
  confidenceLevel: number;
  attached: boolean;
  autoGenerated: boolean;
  stackLineMediaDetails: StackLineMediaDetails;
}

export interface GetSponsoredVideoMediaUploadLocationsResponse {
  code: string;
  details: string;
  mediaUploadRequestId: number;
  uploadUrl: string;
  captionUrl: string;
}

export class AdWalmartService {
  /**
   * This asynchronous function is used to retrieve media assets from Walmart's ad platform.
   * It makes a POST request to the Walmart API endpoint '/apiAdManager/walmartVideo/getMedia'.
   *
   * @param {object} params - An object that contains request parameters.
   * @param {any} params.headers - The headers for the request.
   * @param {number} params.beaconClientLoginId - The Beacon Client Login Id which is required to make the request.
   * @param {number} [params.mediaId] - Optional. The Id of a specific media to retrieve.
   * @param {number} [params.mediaStatus] - Optional. The status of the media to filter the media assets. Get all with status.
   *
   * @returns {Promise<WalmartMediaAsset>} Returns a promise that resolves to WalmartMediaAsset on success.
   */
  public static getMedia = async ({
    headers,
    beaconClientLoginId,
    mediaId,
    mediaStatus
  }: {
    beaconClientLoginId: number;
    headers?: Record<string, unknown>;
    mediaId?: number;
    mediaStatus?: number;
  }) => {
    const getMediaResponse = await axios.post(
      `apiAdManager/walmartVideo/getMedia`,
      {
        beaconClientLoginId,
        mediaId,
        mediaStatus
      },
      headers
    );
    const mediaAssets = _get(getMediaResponse, 'data', []);
    return mediaAssets as WalmartMediaAsset;
  };

  /**
   * Step 1 for Video upload. Fetches media URL locations for a given beacon client login ID.
   * @param param0 An object containing `headers`, `beaconClientLoginId`, optional `mediaId`, and optional `mediaStatus`.
   * @param param0.headers The headers for the API request. This should include any required authentication or context-specific information.
   * @param param0.beaconClientLoginId The ID of the beacon client for which the media upload locations are requested.
   * @returns The response from the getMediaUploadLocations API, which includes a list of media upload locations.
   */
  public static getMediaLocations = async ({
    headers,
    beaconClientLoginId
  }: {
    beaconClientLoginId: number;
    headers?: Record<string, unknown>;
  }) => {
    const getLocationsResponse = await axios.get(`apiAdManager/walmartVideo/getMediaUploadLocations`, {
      params: {
        beaconClientLoginId
      },
      headers
    });
    const locations = _get(getLocationsResponse, 'data', []);
    return locations as GetSponsoredVideoMediaUploadLocationsResponse;
  };

  /**
   * Step 2 for Video upload. Actual video upload.
   * @param param0 An object containing `file` and optional `config`.
   * @param param0.file The file to be uploaded. This should be a media file for upload.
   * @param param0.config Optional configuration settings for the upload. These settings may override default behavior.
   * @returns The response from the uploadMedia API, which includes details about the uploaded media.
   */
  public static uploadMedia = async ({ file, config }: { file: any; config?: Record<string, unknown> }) => {
    const uploadMediaResponse = await axios.post(`apiAdManager/walmartVideo/uploadMedia`, file, config);
    const res = _get(uploadMediaResponse, 'data', []);
    return res as any;
  };

  /**
   * Step 3. Final step for video upload. Notifies the server that the media upload has been completed.
   * @param param0 An object containing `mediaName`, `mediaDescription`, `mediaUploadRequestId`, and `beaconClientLoginId`.
   * @param param0.mediaName The name of the media file that was uploaded.
   * @param param0.mediaDescription Optional description of the media file. This can be used to provide additional context or metadata.
   * @param param0.mediaUploadRequestId The unique ID associated with the specific media upload request.
   * @param param0.beaconClientLoginId The ID of the beacon client that uploaded the media.
   * @returns The response from the notifyMediaUploadComplete API, which includes details about the completed upload.
   */
  public static completeUpload = async ({
    mediaName,
    mediaDescription,
    mediaUploadRequestId,
    beaconClientLoginId
  }: {
    mediaName: string;
    mediaDescription?: string | null;
    mediaUploadRequestId: number;
    beaconClientLoginId: number;
  }) => {
    const notifyResponse = await axios.post(`apiAdManager/walmartVideo/notifyMediaUploadComplete`, {
      mediaName,
      mediaDescription,
      mediaUploadRequestId,
      beaconClientLoginId
    });

    return notifyResponse as any;
  };

  /**
   * Delete media.
   * @param param0 An object containing `mediaName`, `mediaDescription`, `mediaUploadRequestId`, and `beaconClientLoginId`.
   * @param param0.mediaId The unique ID associated with the specific media.
   * @param param0.beaconClientLoginId The ID of the beacon client that uploaded the media.
   * @returns The response from the deleteMedia API, which includes details about the completed upload.
   */
  public static deleteMedia = async ({
    mediaId,
    beaconClientLoginId
  }: {
    mediaId: number;
    beaconClientLoginId: number;
  }) => {
    const notifyResponse = await axios.post(`apiAdManager/walmartVideo/deleteMedia`, {
      mediaId,
      beaconClientLoginId
    });

    return notifyResponse as any;
  };
}
