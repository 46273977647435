import React, { Dispatch, SetStateAction } from 'react';
import { CrossIcon } from 'src/components/SvgIcons';

interface DiscoverFilterDialogHeaderProps {
  setShowFilter: Dispatch<SetStateAction<boolean>>;
  clearFilters: (isDialogHeaderClear?: boolean) => void;
  tempFilterObj: any;
  setFilterObj: any;
}
const DiscoverFilterDialogHeader = (props: DiscoverFilterDialogHeaderProps) => {
  const { setShowFilter, clearFilters, tempFilterObj, setFilterObj } = props;
  return (
    <div className="discoverFilterHeaderContainer">
      <div role="button">
        <CrossIcon
          style={{ height: '30px', width: '30px' }}
          onClick={() => {
            setShowFilter(false);
            const tempObj = JSON.parse(JSON.stringify(tempFilterObj));

            setFilterObj({ ...tempObj });
          }}
        />
      </div>
      <div>
        <p className="discoverFilterHeaderLabel">Filters</p>
      </div>
      <div
        className="clearButton"
        onClick={() => {
          clearFilters(true);
          // setFilterObj(tempFilterObj);
        }}
        role="button"
      >
        Clear
      </div>
    </div>
  );
};

export default DiscoverFilterDialogHeader;
