import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import axios from 'axios';
import queryString from 'qs';
import { Option } from 'funfix-core';
import { mergeConditions } from 'sl-api-connector/search/conditions';
import { entityOperations } from 'src/store/modules/entityService';
import { getLayoutForEntity } from 'src/components/Layout/EntityPageLayout';
import {
  buildDefaultConditions,
  buildEntityConditions,
  buildMainEntityConditions
} from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { ENTITIES } from 'src/utils/entityDefinitions';
import { mapConditionsToQuery, prefillFormData, DEFAULT_SAVED_SEARCH_ID } from 'src/utils/segments';
import Loading from 'src/components/common/Loading';
import { anyNotEq } from 'src/utils/equality';
import ConnectedAdvancedSearchSideBar from './AdvancedSearch/AdvancedSearchSideBar/ConnectedAdvancedSearchSidebar';
import './Search.scss';
import { buildBusinessUnitConditions } from '../EntityPage/Filters/businessUnitFilters';
import { prop, propEq } from 'src/utils/fp';
import { shouldShowNewBeacon } from 'src/utils/app';
import { withBus } from 'react-bus';

const { CancelToken } = axios;

class SearchContainer extends Component {
  static propTypes = {
    // Redux props
    app: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    entityService: PropTypes.object.isRequired,
    fetchMainEntity: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    eventBus: PropTypes.object.isRequired
  };

  state = {
    isLoading: true
  };

  componentDidMount() {
    this.source = CancelToken.source();
    this.buildCommonProps(this.props);

    this.props.fetchMainEntity('client', -1, this.props.retailer.id, this.props.history.push.bind(this.props.history));
  }

  componentWillReceiveProps(nextProps) {
    if (
      anyNotEq(['location.pathname', 'location.search', 'entityService.mainEntity', 'filters'], this.props, nextProps)
    ) {
      this.buildCommonProps(nextProps);
    }
  }

  componentWillUnmount() {
    this.source.cancel('Cancel network request');
  }

  buildCommonProps = (nextProps) => {
    const { app, entityService, retailer, user, categories, location, setEntity, segments } = nextProps || this.props;
    const { mainEntity } = entityService;
    const queryParams = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true, arrayLimit: 520 });
    const nextQueryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 520 });
    const entityType = nextQueryParams.entityType ? nextQueryParams.entityType : 'segment';
    const entityDefinition = ENTITIES[app.name][entityType];

    if (!queryParams.id) {
      queryParams.id = DEFAULT_SAVED_SEARCH_ID;
    }
    if (!nextQueryParams.id) {
      nextQueryParams.id = DEFAULT_SAVED_SEARCH_ID;
    }

    const formData = prefillFormData({ queryParams, appName: app.name });
    const nextFormData = prefillFormData({ queryParams: nextQueryParams, appName: app.name });
    if (
      !entityService.mainEntity ||
      !entityService.mainEntity.id ||
      `${entityService.mainEntity.id}` !== nextQueryParams.id ||
      `${entityService.mainEntity.type}` !== entityType ||
      !_isEqual(formData, nextFormData) ||
      location.pathname !== this.props.location.pathname
    ) {
      const queryValues = mapConditionsToQuery(nextFormData);
      const query = {
        ...queryValues,
        qv: 1.1,
        id: nextQueryParams.id
      };
      let modifiedMainEntity = {
        id: nextQueryParams.id,
        queryId: nextQueryParams.id,
        appName: app.name,
        entityType,
        type: entityType,
        query: JSON.stringify(query),
        name: nextQueryParams.dn || '',
        canEdit: true
      };
      if (mainEntity && modifiedMainEntity.type === mainEntity.type && modifiedMainEntity.id === mainEntity.id) {
        modifiedMainEntity = _cloneDeep(mainEntity);
        modifiedMainEntity.type = entityDefinition.type;
      }
      modifiedMainEntity.query = JSON.stringify(query);
      setEntity('mainEntity', modifiedMainEntity);
      this.setState({ isLoading: true });
    } else {
      let { tab, subtab } = nextQueryParams;
      if (mainEntity.type === 'searchtermlist') {
        if (!tab) {
          tab = 'traffic';
        }
        if (!subtab) {
          subtab = 'totalClicks';
        }
      } else {
        if (!tab) {
          tab = 'sales';
        }
        if (!subtab) {
          subtab = 'retailSales';
        }
      }

      const pageLayout = getLayoutForEntity({
        app,
        retailer,
        user,
        tab,
        metricType: subtab,
        entity: mainEntity,
        pageType: 'searchPage',
        location
      });

      const defaultConditions = buildDefaultConditions({ termFilters: [], rangeFilters: [] }, nextQueryParams);
      const entityConditions = buildEntityConditions(app, categories, defaultConditions, mainEntity);
      const mainEntityConditions = buildMainEntityConditions(
        entityConditions,
        mainEntity,
        app,
        retailer,
        nextQueryParams
      );

      // Add segment filters if we have them
      const segmentConditions = Option.of(_isEmpty(nextQueryParams.segment) ? null : nextQueryParams.segment)
        .flatMap((segmentId) =>
          Option.of([...segments.mySegments, ...segments.teamSegments].find(propEq('id', segmentId)))
        )
        .map(prop('conditions'))
        .getOrElse({ termFilters: [], rangeFilters: [] });

      // Add business unit filters if we have them
      const selectedBUFilters = Object.values(
        Option.of(_isEmpty(nextQueryParams.businessUnits) ? null : nextQueryParams.businessUnits)
          .map(JSON.parse)
          .getOrElse({})
      ).reduce((acc, ids) => [...acc, ...ids], []);
      const buConditions = buildBusinessUnitConditions(
        segments.businessUnits,
        segments.savedSearchesById,
        retailer,
        new Set(selectedBUFilters)
      );

      const comparisonConfig = {
        type: nextQueryParams.ctype,
        indexName: nextQueryParams.ctab || tab,
        metricName: nextQueryParams.csubtab || subtab,
        entityId: nextQueryParams.cid,
        entityType: nextQueryParams.ctype
      };

      this.setState({
        pageLayout,
        entityConditions,
        mainEntityConditions: mergeConditions(mergeConditions(mainEntityConditions, buConditions), segmentConditions),
        queryParams: nextQueryParams,
        comparisonConfig,
        isLoading: false
      });
    }
  };

  render() {
    const { isLoading, entityConditions, mainEntityConditions, pageLayout, comparisonConfig, queryParams } = this.state;
    const { mainEntity } = this.props.entityService;

    if (isLoading || !pageLayout || !mainEntity || !entityConditions || !mainEntityConditions) {
      if (shouldShowNewBeacon()) {
        return null;
      } else {
        return (
          <div className="spinner-wrapper">
            <Loading className="spinner__table--entity" />
          </div>
        );
      }
    }

    return (
      <div
        className="search"
        style={{
          marginTop: shouldShowNewBeacon() ? '0px' : undefined,
          justifyContent: shouldShowNewBeacon() ? 'space-between' : undefined
        }}
      >
        <ConnectedAdvancedSearchSideBar />

        <div
          className="search__content"
          style={{
            margin: shouldShowNewBeacon() ? '0px' : undefined
          }}
        >
          {pageLayout.widgets.map((widget, index) => {
            const { view, CustomComponent } = widget;

            return (
              <div
                key={`entitypagecontainer_${view.name}_${index}`}
                style={widget.view.container && widget.view.container.style ? widget.view.container.style : {}}
                className={
                  widget.view.container && widget.view.container.className ? widget.view.container.className : ''
                }
              >
                {CustomComponent ? (
                  <CustomComponent
                    key={`entitypagecontainer_${view.name}_${index}`}
                    uniqueName={`entitypagecontainer_${view.name}_${index}`}
                    conditions={entityConditions}
                    entity={mainEntity}
                    queryConditions={mainEntityConditions}
                    aggregationConditions={{ termFilters: [], rangeFilters: [] }}
                    comparisonConfig={comparisonConfig}
                    queryParams={queryParams}
                    widget={widget}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  _pick(state, ['app', 'retailer', 'user', 'categories', 'filters', 'entityService', 'segments']);

const mapDispatchToProps = {
  fetchMainEntity: entityOperations.fetchMainEntity,
  setEntity: entityOperations.setEntity
};

export default withBus('eventBus')(withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchContainer)));
