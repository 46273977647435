import { Entity } from 'sl-api-connector';

import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import AdRecommendations from 'src/components/AdManager/AdRecommendations';
import ReduxStore from 'src/types/store/reduxStore';
import { PageLayout } from 'src/types/application/widgetTypes';
import colors from 'src/utils/colors';
import AdManagerEntityHeader from 'src/components/AdManager/AdManagerEntityHeader';
import { AggregationField } from 'src/types/application/types';

const indexName = 'adCampaignAdGroupProductTargetDailyMetrics' as const;

export const getLayout = ({ app, entity }: { app: ReduxStore['app']; entity: Entity }): Partial<PageLayout> => {
  const HeaderWidget = {
    CustomComponent: AdManagerEntityHeader,
    name: 'adManagerHeader',
    view: {
      name: 'adManagerHeader',
      title: 'Ad Score',
      container: {
        style: { borderBottom: `1px solid ${colors.lightestGrey}`, marginBottom: 50 }
      }
    },
    data: {
      adScoreName: 'adScore'
    }
  };

  const groupByFieldName = 'targetingText';
  const entityName = 'adCampaignAdGroupTarget';

  const aggregationFields: AggregationField[] = [
    'clicks',
    'spend',
    'sales',
    'costPerClick',
    'returnOnAdSpend',
    'costPerAcquisition',
    'adCostOfSales'
  ].map((fieldName) => INDEX_FIELDS.getField(app.name, indexName, fieldName));

  const configByGroupByFieldName = {
    targetingText: {
      indexName,
      entity,
      mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'clicks', entityName, 'targetingText'),
      aggregationFields,
      tableView: {
        aggregationFields
      }
    },
    stacklineSku: {
      indexName,
      entity,
      mainMetricField: INDEX_FIELDS.getField(app.name, indexName, 'clicks', entityName, 'stacklineSku'),
      aggregationFields,
      tableView: {
        aggregationFields
      }
    }
  };

  const Recommendations = {
    CustomComponent: AdRecommendations,
    name: 'adManagerRecommendations',
    view: {
      name: 'adManagerRecommendations',
      gridOptions: {
        pageSize: 1000
      }
    },
    data: {
      groupByFields: [INDEX_FIELDS.getField(app.name, indexName, groupByFieldName, entityName)],
      configByGroupByFieldName,
      statePropertyName: `recommendations_for_campaign_${entity.id}`
    }
  };

  return {
    widgets: [HeaderWidget, Recommendations]
  };
};
