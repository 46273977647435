import _debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { useTableData } from 'src/serverProxy/useTableData';
import { ProductEntity } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/types';

const PAGE_SIZE = 20;

/**
 * Returns products for the compare popup
 */
export default function useProductsForCompare(searchTerm: string) {
  // We put the useTableData args inside of a single state variable
  // otherwise individual network requests will be made for searchTerm
  // and page number updates which we don't want
  const [requestPayload, setRequestPayload] = useState({
    pageNumber: 1,
    searchTerm
  });

  // Since we fetch products in pages of 20, we need to keep track of all products
  const [allProducts, setAllProducts] = useState<ProductEntity[]>([]);

  // Since we update allProducts in a useEffect after isLoading is already false,
  // we'll manually keep track of when it's updated so that the loading
  // states render correctly
  const [allProductsLoading, setAllProductsLoading] = useState(true);

  const [hasMoreItems, setHasMoreItems] = useState(true);
  const { isLoading, data: pageOfProducts } = useTableData<{ product: ProductEntity }>({
    groupByFieldName: 'stacklineSku',
    fields: [
      {
        name: 'retailSales'
      }
    ],
    indexName: 'sales',
    itemsPerPage: PAGE_SIZE,
    requestId: 'chart-compare-products',
    pageNumber: requestPayload.pageNumber,
    buildRequest: (requestBuilder) => {
      return requestBuilder
        .removeConditionTermFilters((termFilter) => termFilter.fieldName !== 'retailerId')
        .addConditionTermFilter('keyword', 'must', [requestPayload.searchTerm]);
    }
  });

  /**
   * After fetching a page of products, update the list of all products
   */
  useEffect(() => {
    if (!isLoading) {
      const parsedProducts = pageOfProducts.map((item) => item.metadata.product);

      if (requestPayload.pageNumber === 1) {
        setAllProducts(parsedProducts);
      } else {
        setAllProducts((prevProducts) => [...prevProducts, ...parsedProducts]);
      }
      setAllProductsLoading(false);

      if (pageOfProducts.length < PAGE_SIZE) {
        setHasMoreItems(false);
      }
    } else {
      setAllProductsLoading(true);
    }
  }, [isLoading, pageOfProducts, requestPayload.pageNumber]);

  const reset = useCallback((newSeachTerm?: string) => {
    setRequestPayload((prevPayload) => ({
      ...prevPayload,
      ...(newSeachTerm !== undefined ? { searchTerm: newSeachTerm } : {}),
      pageNumber: 1
    }));
    setHasMoreItems(true);
  }, []);

  /**
   * Reset after updating the search term since
   * we don't want to show previous results
   */
  useEffect(() => {
    reset(searchTerm);
  }, [searchTerm, reset]);

  const fetchNextProducts = useCallback(
    _debounce(
      () => {
        if (!isLoading && hasMoreItems) {
          setRequestPayload((prevPayload) => ({
            ...prevPayload,
            pageNumber: prevPayload.pageNumber + 1
          }));
        }
      },
      500,
      {
        leading: true
      }
    ),
    [isLoading, hasMoreItems]
  );

  return {
    products: allProductsLoading && requestPayload.pageNumber === 1 ? [] : allProducts,
    isLoading: allProductsLoading,
    fetchNextProducts,
    reset
  };
}
