/**
 * A version of `TrendChart` that allows add special style to legend.
 */
import React from 'react';
import PropTypes from 'prop-types';

import ArrowChange from 'src/components/common/ArrowChange/ArrowChange';
import _get from 'lodash/get';
import GenericChart from 'src/components/Charts/GenericChart';
import { TrendChartInner, enhanceTrendChart } from './TrendChart';
import { CustomLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';

import './AdManagerSummaryTrend.scss';

const MainLegend = ({ chartParams }) => {
  const legendTitle = _get(chartParams, ['chartProps', 'legend', 'legendTitle'], '');
  const mainLegendDisplayValue = _get(chartParams, ['chartProps', 'legend', 'mainLegendDisplayValue'], '');
  return (
    <div className="adManager_summary_trend_legend">
      <div className="header">{legendTitle}</div>
      <div className="data">{mainLegendDisplayValue}</div>
    </div>
  );
};

MainLegend.propTypes = {
  chartParams: PropTypes.object.isRequired
};

const ChangeLegend = ({ metric, chartParams }) => {
  const metricsChangePercent = _get(chartParams, ['chartProps', 'legend', 'metricsChangePercent'], '');

  return (
    <div className="adManager_summary_trend_legend_change">
      <ArrowChange metric={metric} value={metricsChangePercent} />
    </div>
  );
};

ChangeLegend.propTypes = {
  metric: PropTypes.object.isRequired,
  chartParams: PropTypes.object.isRequired
};

class AdManagerSummaryTrend extends TrendChartInner {
  handleNInOneTabs = (tab) => {
    this.setState({ selectedMetricFieldName: tab.name });
  };

  getAggregationFields(groupByField) {
    return super.getAggregationFields.call(this, groupByField);
  }

  /**
   * Returns the entity for which to construct conditions to execute the query.  Defaults to the main entity, but can be
   * overridden in extending classes.
   */
  getQueryEntity(props = this.props) {
    return props.widget.data.queryEntity;
  }

  renderTrendChart() {
    const chartParams = super.getChartParams.call(this);
    if (!chartParams) {
      return 'loading';
    }

    const metrics = this.getAggregationFields(this.props.widget.data.groupByFields[0]);

    return (
      <>
        <MainLegend chartParams={chartParams} />
        <GenericChart {...chartParams} />
        <ChangeLegend metric={metrics[0]} chartParams={chartParams} />
      </>
    );
  }

  render = () => (
    <div id={this.props.widget.name}>
      {this.shouldShowLoading() ? <CustomLoading height={237} width={300} padding={10} /> : this.renderTrendChart()}
    </div>
  );
}

const EnhanceAdManagerSummaryTrend = enhanceTrendChart(AdManagerSummaryTrend);

export default EnhanceAdManagerSummaryTrend;
