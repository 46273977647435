import React, { useState, useEffect, useRef } from 'react';
import moment, { Moment } from 'moment-timezone';
import NumberFormat from 'react-number-format';
import _pick from 'lodash/pick';
import _clone from 'lodash/clone';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import { useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import StandardSelect from 'src/components/common/DropDown/StandardDropdown';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DayPickerSingleDateController, isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import Picker from 'react-month-picker';
import Tooltip from '@mui/material/Tooltip';

import { ChevronIcon } from 'src/components/SvgIcons';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import ReduxStore from 'src/types/store/reduxStore';
import Loading from 'src/components/common/Loading';
import { PageLayout } from 'src/types/application/widgetTypes';
import { getParentEntityDisplayInfo, getPlatformDisplayInfo, updatePortfolio } from 'src/components/AdManager/AmsUtils';
import AdManagerEntityHeader from 'src/components/AdManager/AdManagerEntityHeader';
import InfoTooltip from 'src/components/AdCampaignBuilder/Widgets/InfoTooltip';
import { AdCampaignBuilderRadio } from 'src/components/AdCampaignBuilder/Widgets/Checkbox';
import colors from 'src/utils/colors';
import '../AdManagerSettings.scss';
import { IAdPortfolioAdPlatformSettingsByClient, AD_BUDGET_TYPE } from 'sl-ad-campaign-manager-data-model';
import { entityOperations } from 'src/store/modules/entityService';
import { adPortfolioOperations } from 'src/store/modules/adManager/adPortfolios';
import { PushFn } from 'src/types/application/types';
import 'src/components/Header/Header.scss';
import { yearMonthToMoment } from 'src/utils/dateUtils';
import MonthPicker from 'src/components/common/DatePicker/MonthPicker';
import CalendarClearButton from 'src/components/common/DatePicker/CalendarClearButton';
import { UTC_TIMEZONE } from 'src/utils/constants';
import CampaignSetting from './CampaignSetting';
import { muiStyles } from './utils';
import { useAppSelector } from 'src/utils/Hooks';

const adBudgetTypeDisplayName = {
  [AD_BUDGET_TYPE.DAILY]: 'Daily',
  [AD_BUDGET_TYPE.DATE_RANGE]: 'Date Range',
  [AD_BUDGET_TYPE.LIFETIME]: 'Lifetime',
  [AD_BUDGET_TYPE.MONTHLY]: 'Monthly',
  [AD_BUDGET_TYPE.MONTHLY_RECURRING]: 'Monthly Recurring'
};

export const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, ['adCampaigns', 'adPortfolios', 'retailer', 'user']),
    mainEntity: state.entityService.mainEntity
  };
};

const CampaignSettingWidget = withStyles(muiStyles)(CampaignSetting);

export const portfolioBudgetTypes = [
  { name: 'noBudget', displayName: 'No Budget Cap', matchName: undefined, fieldName: 'Budget' },
  {
    name: AD_BUDGET_TYPE.DATE_RANGE,
    displayName: adBudgetTypeDisplayName[AD_BUDGET_TYPE.DATE_RANGE],
    matchName: AD_BUDGET_TYPE.DATE_RANGE,
    fieldName: 'Date Range Budget Cap'
  },
  {
    name: AD_BUDGET_TYPE.MONTHLY_RECURRING,
    displayName: adBudgetTypeDisplayName[AD_BUDGET_TYPE.MONTHLY_RECURRING],
    matchName: AD_BUDGET_TYPE.MONTHLY_RECURRING,
    fieldName: 'Monthly Budget Cap'
  }
];

const PortfolioSetting: React.FC<{
  classes: { [key: string]: any };
  push: PushFn;
}> = ({ classes, push }) => {
  const adPlatformSettings = useAppSelector((state) => state.adPlatformSettings);
  const retailer = useAppSelector((state) => state.retailer);
  const user = useAppSelector((state) => state.user);
  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);

  const dispatch = useDispatch();
  const [portfolioName, setPortfolioName] = useState('');
  const [selectedStrategy, setStrategy] = useState({});
  const [budgetType, setBudgetType] = useState({});
  const [budgetAmount, setBudgetAmount] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const monthPickerRef = useRef<Picker>(null);

  const adStrategies = adPlatformSettings.filter((item) => item.settingType === 'campaignStrategy');
  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);
  const adAuditLimitFeatureMessage = 'This setting can be deployed in the full read/write version of Drive.';

  const initState = () => {
    if (!mainEntity || _isEmpty(adStrategies)) {
      return;
    }
    const newBudgetTypeMatch = _get(mainEntity, ['extendedAttributes', 'liveBudgetSetting', 'budgetType'], undefined);
    const newBudgetType =
      portfolioBudgetTypes.find((item) => item.matchName === newBudgetTypeMatch) || portfolioBudgetTypes[0];
    const newAmount = _get(mainEntity, ['extendedAttributes', 'liveBudgetSetting', 'amount'], null);
    const newStartDate = _get(mainEntity, ['extendedAttributes', 'liveBudgetSetting', 'startDate'], null);
    const newEndDate = _get(mainEntity, ['extendedAttributes', 'liveBudgetSetting', 'endDate'], null);
    const strategyId = _get(mainEntity, ['extendedAttributes', 'automationAttributes', 'strategyId'], 'Manual');
    const strategy = adStrategies.find((item: any) => item.id === strategyId);
    setStrategy(strategy);
    setPortfolioName(mainEntity.name);
    setBudgetType(newBudgetType);
    setBudgetAmount(newAmount);
    setStartDate(newStartDate ? moment(newStartDate).tz(retailer.processInTimezone || UTC_TIMEZONE) : null);
    setEndDate(newEndDate ? moment(newEndDate).tz(retailer.processInTimezone || UTC_TIMEZONE) : null);
  };

  const submitChanges = async () => {
    const mainEntityCloned: IAdPortfolioAdPlatformSettingsByClient = _clone(mainEntity);
    if (mainEntityCloned.extendedAttributes.name !== portfolioName) {
      mainEntityCloned.extendedAttributes.name = portfolioName;
    }
    if (!_isEqual(mainEntityCloned.extendedAttributes.automationAttributes, selectedStrategy)) {
      mainEntityCloned.extendedAttributes.automationAttributes = {
        strategyId: selectedStrategy.settingId
      };
    }

    if (!budgetType.matchName) {
      mainEntityCloned.extendedAttributes.currentMonthBudgetSetting = null;
    }

    if (budgetType.matchName) {
      if (!mainEntityCloned.extendedAttributes.currentMonthBudgetSetting) {
        mainEntityCloned.extendedAttributes.currentMonthBudgetSetting = {};
      }
      mainEntityCloned.extendedAttributes.currentMonthBudgetSetting.budgetType = budgetType.matchName;
      if (budgetAmount) {
        mainEntityCloned.extendedAttributes.currentMonthBudgetSetting.amount = budgetAmount;
      }

      if (budgetType.matchName === AD_BUDGET_TYPE.MONTHLY_RECURRING) {
        mainEntityCloned.extendedAttributes.currentMonthBudgetSetting.endDate = endDate
          ? endDate.format('YYYY-MM-DD')
          : endDate;
      }
      if (budgetType.matchName === AD_BUDGET_TYPE.DATE_RANGE) {
        mainEntityCloned.extendedAttributes.currentMonthBudgetSetting.startDate = startDate.format('YYYY-MM-DD');
        mainEntityCloned.extendedAttributes.currentMonthBudgetSetting.endDate = endDate
          ? endDate.format('YYYY-MM-DD')
          : endDate;
      }
    }
    setLoading(true);
    await updatePortfolio(mainEntityCloned);
    await dispatch(
      entityOperations.fetchMainEntity(
        mainEntityCloned.type,
        mainEntityCloned.id,
        mainEntityCloned.extendedAttributes.retailerId,
        push
      )
    );
    await dispatch(adPortfolioOperations.fetchAdPortfolios());
    setLoading(false);
  };

  useEffect(() => {
    initState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainEntity, adPlatformSettings]);

  useEffect(() => {
    if (budgetType.name === 'noBudget' || (endDate === null && budgetType.name === 'dateRange')) {
      const newStrategy = adStrategies.find((item) => item.id === 'Manual');
      setStrategy(newStrategy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetType, endDate]);

  if (!mainEntity || _isEmpty(adStrategies) || loading) {
    return (
      <div style={{ height: 800 }}>
        <Loading className="spinner" size={150} thickness={2} />
      </div>
    );
  }

  const handleStrategyChange = (strategyId: string) => {
    const newStrategy = adStrategies.find((item) => item.id === strategyId);
    setStrategy(newStrategy);
  };

  const handleBudgetTypeChange = (typeName) => {
    const newBudgetType = portfolioBudgetTypes.find((item) => item.name === typeName);
    if (typeName === AD_BUDGET_TYPE.DATE_RANGE && !startDate) {
      setStartDate(moment().tz(retailer.processInTimezone || UTC_TIMEZONE));
    }
    setBudgetType(newBudgetType);
  };

  const { id } = mainEntity;
  const { shortDisplayName: parentEntityName } = getParentEntityDisplayInfo(mainEntity.extendedAttributes);
  const { displayName: platformName } = getPlatformDisplayInfo(mainEntity.platformType);
  const { entityId } = mainEntity.extendedAttributes;

  const startDateLimit = (day) => {
    const todayDate = moment().tz(retailer.processInTimezone || UTC_TIMEZONE);

    if (!endDate) {
      // if endDate not set, use could set startDate to be any day after today.
      return !isInclusivelyAfterDay(day, todayDate);
    } else {
      // if endDate set, use could set startDate to be any day between today and endDate.
      return !isInclusivelyAfterDay(day, todayDate) || !isInclusivelyBeforeDay(day, endDate);
    }
  };
  const endDateLimit = (day) => {
    const todayDate = moment().tz(retailer.processInTimezone || UTC_TIMEZONE);

    let latestDate = todayDate;
    if (budgetType.name === AD_BUDGET_TYPE.DATE_RANGE && todayDate.isBefore(moment(startDate))) {
      latestDate = moment(startDate);
    }
    // disable all dates before today
    if (!isInclusivelyAfterDay(day, latestDate)) {
      return true;
    }

    return false;
  };

  const showPicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      setEndDatePickerOpen(true);
      monthPickerRef.current.show();
    }
  };

  const getMinMaxMonths = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    return [
      {
        year,
        month
      },
      {
        year: year + 4, // four years in future
        month
      }
    ];
  };

  const [minEndMonth, maxEndMonth] = getMinMaxMonths();
  const isMonthlyRecurring = budgetType.name === AD_BUDGET_TYPE.MONTHLY_RECURRING;
  const hasBudget = budgetType.name !== 'noBudget';

  return (
    <div className="setting_container">
      <div className="setting_row">
        <div className="label">Portfolio Name:</div>
        <div>
          <TextField
            variant="standard"
            autoComplete="off"
            id="outlined-bare"
            classes={{ root: classes.root }}
            value={portfolioName}
            InputProps={{
              disableUnderline: isAdAuditUser,
              classes: { root: classes.root, disabled: classes.disabled }
            }}
            onChange={({ target: { value } }) => setPortfolioName(value)}
            margin="normal"
            disabled={isAdAuditUser}
          />
        </div>
      </div>
      <div className="setting_row">
        <div className="label">Portfolio Id:</div>
        <div>{id}</div>
      </div>
      <div className="setting_row">
        <div className="label">Platform:</div>
        <div>{platformName}</div>
      </div>
      <div className="setting_row">
        <div className="label">Entity:</div>
        <div>{parentEntityName}</div>
      </div>
      <div className="setting_row">
        <div className="label">Entity Id:</div>
        <div>{entityId}</div>
      </div>
      <div className="setting_row" style={{ marginBottom: 0 }}>
        <div className="label">Optimization:</div>
        <div>
          <div style={hasBudget ? {} : { color: colors.red }}>
            {hasBudget ? (
              <Tooltip title={isAdAuditUser ? adAuditLimitFeatureMessage : ''} placement="top">
                <span>Select your strategy below</span>
              </Tooltip>
            ) : (
              'Portfolio budget is needed to enable Ad Automation'
            )}
          </div>
        </div>
      </div>
      <div className="setting_row">
        <div className="label"></div>
        <div className="radio-group">
          <RadioGroup
            aria-label="strategy"
            name="strategy"
            value={selectedStrategy.id}
            style={{ paddingLeft: '5px' }}
          ></RadioGroup>
          {adStrategies.map(({ id: strategyId, extendedAttributes }) => {
            const shouldDisable =
              isAdAuditUser ||
              (((endDate === null && budgetType.name === 'dateRange') || budgetType.name === 'noBudget') &&
                strategyId !== 'Manual');

            return (
              <div
                key={strategyId}
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <FormControlLabel
                  value={strategyId}
                  control={
                    <AdCampaignBuilderRadio
                      disableRipple
                      style={{ padding: 9 }}
                      checked={selectedStrategy.id === strategyId}
                      onClick={() => handleStrategyChange(strategyId)}
                      value={strategyId}
                    />
                  }
                  disabled={shouldDisable}
                  label={
                    <InfoTooltip customNode={<label className="radio-label">{extendedAttributes.name}</label>}>
                      <span className="info"> {extendedAttributes.description || ''}</span>
                    </InfoTooltip>
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="setting_row">
        <div className="label">Budget Type:</div>
        <div>
          <StandardSelect
            variant="standard"
            style={{ marginRight: 20 }}
            value={budgetType.name || null}
            onChange={({ target: { value } }) => {
              handleBudgetTypeChange(value);
            }}
            classes={{ root: classes.selectRoot, select: classes.select, disabled: classes.disabled }}
            IconComponent={() => (isAdAuditUser ? null : <ChevronIcon className="sl-header__drop-down-icon" />)}
            disabled={isAdAuditUser}
            disableUnderline={isAdAuditUser}
          >
            {portfolioBudgetTypes.map((opt, idx) => (
              <MenuItem key={idx} value={opt.name}>
                {opt.displayName}
              </MenuItem>
            ))}
          </StandardSelect>
        </div>
      </div>
      <div className="setting_row">
        <div className="label">{budgetType.fieldName}:</div>
        {hasBudget ? (
          <div>
            <NumberFormat
              value={budgetAmount}
              thousandSeparator
              prefix={retailer.currencySymbol}
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              onValueChange={({ floatValue }) => setBudgetAmount(floatValue)}
              // Mimic the style of the MUI `TextInput`
              style={{
                height: '32px',
                outline: 'none',
                border: '0',
                background: 'none',
                borderBottomColor: 'currentcolor',
                borderBottomStyle: 'none',
                borderBottomWidth: '0px',
                borderBottom: `${isAdAuditUser ? 0 : 1}px solid #eee`,
                backgroundColor: 'transparent',
                width: '100px',
                fontSize: '16px',
                color: isAdAuditUser ? colors.labelGrey : 'currentColor',
                fontFamily: 'Roboto'
              }}
              disabled={isAdAuditUser}
            />
          </div>
        ) : (
          'No Budget Cap'
        )}
        {isMonthlyRecurring ? (
          <span>
            restarts on the 1<sup>st</sup> of every month
          </span>
        ) : null}
      </div>
      <div className="setting_row">
        <div className="label">Schedule:</div>
        {budgetType.name !== 'noBudget' ? (
          <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            {budgetType.name === AD_BUDGET_TYPE.DATE_RANGE && (
              <div className="datepicker_container">
                {`Budget starts on `}&nbsp;
                <TextField
                  variant="standard"
                  autoComplete="off"
                  classes={{ root: classes.dateRoot }}
                  InputProps={{
                    disableUnderline: isAdAuditUser,
                    classes: { root: classes.dateRoot, disabled: classes.disabled }
                  }}
                  value={startDate ? moment(startDate).format('MM/DD/YYYY') : 'No Start Date'}
                  id="outlined-bare"
                  onClick={() => {
                    if (!isAdAuditUser) {
                      setStartDatePickerOpen(!startDatePickerOpen);
                    }
                  }}
                  margin="normal"
                  disabled={isAdAuditUser}
                />
                {startDatePickerOpen && (
                  <div style={{ position: 'absolute', zIndex: 1 }}>
                    <DayPickerSingleDateController
                      hideKeyboardShortcutsPanel
                      monthFormat="MMMM YYYY"
                      date={startDate} // momentPropTypes.momentObj or null
                      onDateChange={(date) => {
                        setStartDate(date);
                      }} // PropTypes.func.isRequired
                      focused
                      isOutsideRange={startDateLimit}
                      onFocusChange={() => {}} // PropTypes.func.isRequired
                      onOutsideClick={() => setStartDatePickerOpen(!startDatePickerOpen)}
                      id="adPortfolioSettingStartDatePicker"
                    />
                  </div>
                )}
              </div>
            )}

            <div className="datepicker_container">
              {isMonthlyRecurring ? <span>Recurring budget ends on&nbsp;</span> : <span> and ends on&nbsp;</span>}
              <TextField
                variant="standard"
                autoComplete="off"
                classes={{ root: classes.dateRoot }}
                InputProps={{
                  disableUnderline: isAdAuditUser,
                  classes: { root: classes.dateRoot, disabled: classes.disabled }
                }}
                value={endDate ? moment(endDate).format('MM/DD/YYYY') : 'No End Date'}
                id="outlined-bare"
                onClick={() =>
                  isAdAuditUser ? null : isMonthlyRecurring ? showPicker() : setEndDatePickerOpen(!endDatePickerOpen)
                }
                margin="normal"
                disabled={isAdAuditUser}
              />
              {!isMonthlyRecurring ? (
                endDatePickerOpen && (
                  <div style={{ position: 'absolute', zIndex: 1 }}>
                    <DayPickerSingleDateController
                      hideKeyboardShortcutsPanel
                      monthFormat="MMMM YYYY"
                      date={endDate} // momentPropTypes.momentObj or null
                      onDateChange={(date) => setEndDate(date)} // PropTypes.func.isRequired
                      focused
                      isOutsideRange={endDateLimit}
                      onFocusChange={() => {}} // PropTypes.func.isRequired
                      onOutsideClick={() => setEndDatePickerOpen(!endDatePickerOpen)}
                      renderCalendarInfo={() => (
                        <CalendarClearButton
                          hasEndDate={!!endDate}
                          handleClear={() => {
                            setEndDate(null);
                          }}
                        />
                      )}
                      id="adPortfolioSettingEndDatePicker"
                    />
                  </div>
                )
              ) : (
                <MonthPicker
                  endDate={endDate || yearMonthToMoment(minEndMonth.year, minEndMonth.month + 1)}
                  setEndDate={(val) => setEndDate(val)}
                  monthPickerRef={monthPickerRef}
                  calendarRange={{
                    min: minEndMonth,
                    max: maxEndMonth
                  }}
                  onDismiss={() => setEndDatePickerOpen(false)}
                  hasEndDate={endDatePickerOpen}
                  containerClass="portfolioSettings"
                />
              )}
            </div>
          </div>
        ) : (
          'No starting or ending dates are needed for portfolios with no budget cap'
        )}
      </div>
      <div className="control_button">
        <LargeMuiButton secondary style={{ width: 99, marginRight: 14 }} label="Cancel" onClick={initState} />
        <Tooltip title={isAdAuditUser ? adAuditLimitFeatureMessage : ''} placement="top">
          <div>
            <LargeMuiButton
              style={{ width: 99, marginRight: 14 }}
              label="Save"
              disabled={isAdAuditUser}
              onClick={submitChanges}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const PortfolioSettingWidget = withStyles(muiStyles)(PortfolioSetting);

export const getLayout = (_args: { app: ReduxStore['app']; entity: { type: string } }): Partial<PageLayout> => {
  const HeaderWidget = {
    CustomComponent: AdManagerEntityHeader,
    name: 'adManagerHeader',
    view: {
      name: 'adManagerHeader',
      title: 'Ad Score',
      container: {
        style: { borderBottom: `1px solid ${colors.lightestGrey}`, marginBottom: 50 }
      }
    },
    data: {
      adScoreName: 'adScore'
    }
  };

  const typeWidgetMapping = (entityType) =>
    ({
      adCampaign: CampaignSettingWidget,
      adPortfolio: PortfolioSettingWidget
    }[entityType]);

  const Settings = {
    CustomComponent: typeWidgetMapping(_args.entity.type),
    name: 'adManagerSetting',
    view: {
      name: 'adManagerSetting'
    },
    data: {}
  };

  return {
    widgets: [HeaderWidget, Settings]
  };
};
