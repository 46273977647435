import _merge from 'lodash/merge';
import EntityGrid from 'src/components/EntityGrid';
import MultiLineHCF, {
  EntityGridNoclipHeaderWrapper
} from 'src/components/EntityGrid/HeaderComponentFrameworks/MultiLineHCF';
import { mkAtlasProductCellFormatter } from 'src/components/EntityGrid/Table/AtlasProductCellFormatter';
import { buildWidgetGroup } from 'src/components/Layout/LayoutUtil';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import { MetricField } from 'src/types/application/types';
import { Widget } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import { ENTITIES, INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { filterNils } from 'src/utils/fp';
import { mkNumeralFormatter } from 'src/utils/stringFormatting';

export const buildSwappableDisplayAdvertisingGrid = (
  { app }: { app: ReduxStore['app'] },
  adIndexName: string,
  gridWidgetOverrides?: object
): Widget => {
  const mainMetricField = INDEX_FIELDS.getField(app.name, adIndexName, 'dpv', 'product', 'stacklineSku');
  const gridAggregationFields = ['impressions', 'dpv', 'spend', 'sales', 'unitsSold', 'returnOnAdSpend'].map(
    (fieldName) => INDEX_FIELDS.getField(app.name, adIndexName, fieldName, 'product', 'stacklineSku')
  );
  const metricFieldOverridesByFieldName: { [key: string]: Partial<MetricField> } = {
    impressions: {
      cellRendererFramework: mkAtlasProductCellFormatter(mkNumeralFormatter('0,0'))
    },
    clicks: {
      cellRendererFramework: mkAtlasProductCellFormatter(mkNumeralFormatter('0,0'))
    },
    spend: {
      cellRendererFramework: mkAtlasProductCellFormatter(mkNumeralFormatter('$0,0'))
    },
    sales: {
      cellRendererFramework: mkAtlasProductCellFormatter(mkNumeralFormatter('$10,0'))
    },
    unitsSold: {
      cellRendererFramework: mkAtlasProductCellFormatter(mkNumeralFormatter('0,0'))
    },
    returnOnAdSpend: {
      headerComponentFramework: undefined
    }
  };

  const buildBaseDataGroupNameConfig = () => ({
    beaconAdIndex: adIndexName,
    mainMetricField,
    aggregationFields: gridAggregationFields,
    tableView: {
      metricFields: filterNils([
        ...gridAggregationFields.map((field: MetricField) => ({
          ...field,
          headerComponentFramework: MultiLineHCF,
          ...(metricFieldOverridesByFieldName[field.name!] || {})
        }))
      ])
    }
  });

  const gridWidget = {
    CustomComponent: EntityGrid as any,
    name: VIEWS.entityGrid.name,
    view: _merge({}, VIEWS.entityGrid, {
      WrapperComponent: EntityGridNoclipHeaderWrapper,
      gridOptions: {
        enableGroupBy: true,
        titleOptions: {
          product: 'Products (DPV)'
        }
      }
    }),
    data: {
      groupByFields: filterNils([
        INDEX_FIELDS.getField(app.name, adIndexName, 'stacklineSku', 'product'),
        {
          ...INDEX_FIELDS.getField(app.name, adIndexName, 'orderId', 'product'),
          displayName: 'Order'
        },
        {
          ...INDEX_FIELDS.getField(app.name, adIndexName, 'lineItemId', 'product'),
          displayName: 'Line Item'
        },
        {
          ...INDEX_FIELDS.getField(app.name, adIndexName, 'creativeId', 'product'),
          displayName: 'Creative'
        }
      ]),
      configByGroupByFieldName: {
        stacklineSku: {
          ...buildBaseDataGroupNameConfig(),
          entity: ENTITIES.beacon.product
        },
        orderId: {
          ...buildBaseDataGroupNameConfig(),
          entity: ENTITIES.beacon.order
        },
        lineItemId: {
          ...buildBaseDataGroupNameConfig(),
          entity: ENTITIES.beacon.lineItem
        },
        creativeId: {
          ...buildBaseDataGroupNameConfig(),
          entity: ENTITIES.beacon.creative
        }
      }
    }
  };

  return buildWidgetGroup([gridWidgetOverrides ? _merge(gridWidget, gridWidgetOverrides) : gridWidget]) as any;
};
