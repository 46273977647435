/* eslint-disable react/prop-types */
import React, { ComponentType } from 'react';
import Tooltip from '@mui/material/Tooltip';

import { SvgElementProps } from 'src/components/SvgIcons/SvgIcons';
import { PropTypesOf } from 'src/types/utils';

interface NavIconWithTooltipProps {
  iconStyle?: React.CSSProperties;
  displayName: string;
  IconComponent: ComponentType<SvgElementProps>;
  tooltipProps?: Partial<PropTypesOf<typeof Tooltip>>;
  onClick?: () => void;
}

const NavIconWithTooltip: React.FC<NavIconWithTooltipProps> = ({
  displayName,
  IconComponent,
  iconStyle = {},
  tooltipProps = {},
  onClick
}) => {
  return (
    <Tooltip title={displayName} placement="right" {...tooltipProps}>
      <div>
        <IconComponent onClick={onClick} className="navigation__item-icon" style={iconStyle} />
      </div>
    </Tooltip>
  );
};

export default NavIconWithTooltip;
