import { Parser } from 'expr-eval';
import { FieldDefinition, DATATYPE, METRICTYPE } from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

export const BEACONPRO_PROMOTION_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  retailPriceDelta: {
    name: 'retailPriceDelta',
    displayName: 'Average Discount Numerator',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'avg',
    canBeExported: true
  },

  retailPrice: {
    name: 'retailPrice',
    displayName: 'Average Discount Denominator',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'avg',
    canBeExported: true
  },

  // ! This is a computed field
  averagePromotionDiscount: {
    name: 'averagePromotionDiscount',
    displayName: 'Average Discount',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('retailPrice > 0 ? (retailPriceDelta/retailPrice) : 0')
  },

  retailSalesDelta: {
    name: 'retailSalesDelta',
    displayName: 'Total Sales from Promotions',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum',
    canBeExported: true
  },

  // ! This is a computed field
  shareOfRetailSales: {
    name: 'shareOfRetailSales',
    displayName: 'Share of Retail Sales',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('retailSales > 0 ? (retailSalesDelta/retailSales) : 0')
  },

  unitsSoldDelta: {
    name: 'unitsSoldDelta',
    displayName: 'Share of Units Sold Numerator',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.INTEGER,
    aggregationFunction: 'sum',
    canBeExported: true
  },

  // ! This is a computed field
  shareOfUnitsSold: {
    name: 'shareOfUnitsSold',
    displayName: 'Share of Units Sold',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold > 0 ? (unitsSoldDelta/unitsSold) : 0')
  },

  promoType: {
    name: 'promoType',
    displayName: 'Promotion Type',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    canBeExported: true
  },

  retailPricePrev: {
    name: 'retailPricePrev',
    displayName: 'Old Price',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'avg',
    canBeExported: true
  },

  retailSalesPrev: {
    name: 'retailSalesPrev',
    displayName: 'Old Sales',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum',
    canBeExported: true
  }
};
