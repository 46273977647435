import axios from 'axios';
import { Option } from 'funfix-core';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _merge from 'lodash/merge';
import _pick from 'lodash/pick';
import _prop from 'lodash/property';
import _unionWith from 'lodash/unionWith';
import _difference from 'lodash/difference';
import PropTypes from 'prop-types';
import queryString from 'qs';
import React from 'react';
import { withBus } from 'react-bus';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Conditions, mergeConditions, RangeFilter, TermFilter } from 'sl-api-connector/search/conditions';
import { getEntityGridParams } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import 'src/routes/HomePage/HomePage.scss';
import * as entitySearchServiceOperations from 'src/store/modules/entitySearchService/operations';
import { Widget } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import { EventBus } from 'src/types/utils';
import { shouldShowReclassify } from 'src/utils/app';
import { anyNotEq } from 'src/utils/equality';
import { panic } from 'src/utils/mixpanel';
import { getIsBreadcrumbCategoryMismatch, getIsSkuAlreadyClassified } from '../Tiles/TileContainer';
import './EntityGrid.scss';
import EntityGridRenderer from './EntityGridRenderer';
import { addKeywordToEntityType } from '../gridUtils';
import { buildAggregations } from 'src/components/AdManager/Search';

const { CancelToken } = axios;
const NUMBER_OF_PRODUCTS_TO_DISPLAY_FOR_SUPERUSER = 100;
const DEFAULT_PAGE_SIZE_GRID = 20;

function buildSearchRequest(
  statePropertyName: string,
  requestContext: { entity: any; retailer: any; app: any; indexName: any; derivedFields?: any },
  searchRequestOverrides: [
    {
      returnDocuments?: boolean;
      doAggregation?: boolean;
      processDocuments?: boolean;
      aggregations?: any;
      conditions?: {
        condition?: 'should' | 'must' | 'must_not' | undefined;
        termFilters?: TermFilter[] | undefined;
        rangeFilters?: RangeFilter[] | undefined;
        nestedFilterConditions?: Conditions[] | undefined;
      };
      pageNumber?: any;
      pageSize?: any;
      period?: any;
      sortFilter: any;
    }
  ]
) {
  const { searchRequests } = entitySearchServiceOperations.buildSearchRequests(
    statePropertyName,
    requestContext,
    searchRequestOverrides
  );
  // Update these functions to accept searchRequestOverrides as an array
  const { entity, derivedFields } = requestContext;
  if (derivedFields) {
    const derivedFieldsJson = JSON.stringify(derivedFields);
    searchRequests[0].additionalRequestMetaData = searchRequests[0].additionalRequestMetaData || {};
    searchRequests[0].additionalRequestMetaData.derivedFieldsJson = derivedFieldsJson;
    if (searchRequests.length > 1) {
      searchRequests[1].additionalRequestMetaData = searchRequests[1].additionalRequestMetaData || {};
      searchRequests[1].additionalRequestMetaData.derivedFieldsJson = derivedFieldsJson;
    }
  }
  return searchRequests.map((searchRequest) => {
    return _merge(
      {
        name: `${statePropertyName}-${entity.type}-${entity.id}`,
        id: `${statePropertyName}-${entity.type}-${entity.id}`
      },
      {
        // The `processDocuments` flag is used to indicate that the returned documents should be parsed into
        // entity metrics directly rather than just storing the raw documents.
        processDocuments: true
      },
      searchRequest
    );
  });
}

const PAGE_SIZE_FOR_SUPERUSER = 400;

/**
 * Given the name of an entity grid widget, builds the data key in `entitySearchService` into which its data will be
 * fetched and stored.
 *
 * @param {string} uniqueName The widget's `uniqueName` attribute
 */
export const buildEntityGridDataKey = (uniqueName: string) => `entityGridMetrics${uniqueName}`;

const pickedStateKeys = [
  'app',
  'allWeekIdsByRetailerId',
  'comparisonTimePeriod',
  'mainTimePeriod',
  'retailer',
  'user',
  'filters',
  'categoriesByRetailerId'
];

const mapStateToProps = (state: ReduxStore, { widget, uniqueName }) => {
  const dataKey = widget.data.dataKeyOverride || buildEntityGridDataKey(uniqueName || 'entityGrid');
  return {
    ..._pick(state, pickedStateKeys),
    gridData: state.entitySearchService[dataKey],
    gridDataForComparison: state.entitySearchService[`${dataKey}-secondary`]
  };
};

const mapDispatchToProps = {
  fetchEntityMetrics: entitySearchServiceOperations.fetchEntityMetrics,
  clearEntitySearchService: entitySearchServiceOperations.clearEntitySearchService,
  buildSearchRequests: entitySearchServiceOperations.buildSearchRequests
};

interface EntityGridProps {
  app: ReduxStore['app'];
  gridData: any;
  allWeekIdsByRetailerId: ReduxStore['allWeekIdsByRetailerId'];
  comparisonTimePeriod: ReduxStore['comparisonTimePeriod'];
  mainTimePeriod: ReduxStore['mainTimePeriod'];
  retailer: ReduxStore['retailer'];
  user: ReduxStore['user'];
  filters: ReduxStore['filters'];
  location: any;
  groupByFieldOverride: any;
  uniqueName: string;
  widget: Widget;
  eventBus: EventBus;
  queryConditions: any;
  aggregationConditions: any;
  inverseComparisonTimePeriod: any;
  onDataFetched: any;
  customDataFetchFunction?: any;
  history: any;
  sortByMetricOverride: any;
  filterRows: any;
  sortRows: any;
  CustomLoading: JSX.Element;
  apiRequest: any;
  exportRequest: any;
  firstColumnDefOverrides?: any;
  onSortDirectionChange: any;
  childDiv: any;
}

interface EntityGridState {
  showOnlyBreadcrumbMismatches: boolean;
  sortBy: any;
  sortDirection: 'asc' | 'desc';
  currentLayout: string;
  mainMetricField: any;
  groupByField: any;
  pageNumber: number;
  fetchedPageCount: number;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  reclassifyProducts: any[];
  showPriceData: boolean;
  apiRequest: any;
  hideAlreadyClassifiedEntities: boolean;
  alreadyClassifiedStacklineSkus: any[];
  pageNumberSuperUser: number;
  childDiv: React.RefObject<HTMLSpanElement>;
}

class EntityGrid extends React.Component<
  EntityGridProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps,
  EntityGridState
> {
  private static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    uniqueName: PropTypes.string,
    widget: PropTypes.object.isRequired,
    queryConditions: PropTypes.object.isRequired,
    aggregationConditions: PropTypes.object.isRequired,
    eventBus: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onSelectItem: PropTypes.func,
    CustomLoading: PropTypes.func,
    clearEntitySearchService: PropTypes.func.isRequired,
    groupByFieldOverride: PropTypes.string,
    filterRows: PropTypes.func,
    sortRows: PropTypes.func,
    // A callback that is triggered after the sort direction is changed.  It is passed a single arugment: the field
    // name of the new sort-by field.
    onSortDirectionChange: PropTypes.func,
    // A callback that is called after the entity grid's API call has finished.  It is passed a single argument: the
    // unique name of the entity grid that can be used to read its fetched data from `entitySearchService`.
    //
    // This functions the same way as `widget.view.gridOptions.onDataFetched`.
    onDataFetched: PropTypes.func,
    // This prop doesn't actually affect sorting, but rather it overrides the `sortByMetric` prop that is passed to
    // header component frameworks.  It shoud be set to a value if a column is being sorted by manually outside of the
    // knowledge/control of the `EntityGrid`.
    sortByMetricOverride: PropTypes.string,
    // If a function is provided here, it will be used instead of the default `fetchEntityMetrics` to fetch the data
    // for the grid.  The provided function must return a promise that resolves once the request is completed and has
    // stored its data in the entity grid's data key under `entitySearchService`.  It will be provided one argument
    // which is the `uniqueName` of this grid's widget.
    customDataFetchFunction: PropTypes.func,
    // Inverse timeperiod condition for comparison,
    inverseComparisonTimePeriod: PropTypes.bool,
    childDiv: PropTypes.object,
    // From Redux
    app: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    mainTimePeriod: PropTypes.object.isRequired,
    comparisonTimePeriod: PropTypes.object,
    fetchEntityMetrics: PropTypes.func.isRequired,
    // Data for this grid fetched + stored in Redux
    gridData: PropTypes.object,
    gridDataForComparison: PropTypes.object
  };

  private static defaultProps = {
    comparisonTimePeriod: null,
    uniqueName: 'entityGrid',
    onSelectItem: () => {},
    CustomLoading: null,
    groupByFieldOverride: undefined,
    filterRows: undefined,
    sortRows: undefined,
    onSortDirectionChange: undefined,
    onDataFetched: undefined,
    sortByMetricOverride: undefined,
    customDataFetchFunction: undefined,
    inverseComparisonTimePeriod: false,
    gridData: undefined,
    gridDataForComparison: undefined,
    childDiv: undefined
  };

  private cancelSource: undefined | ReturnType<typeof CancelToken.source> = undefined;
  private childDiv: React.RefObject<HTMLSpanElement> = undefined;

  public constructor(props: EntityGridProps) {
    super(props);
    const { widget } = props;
    this.childDiv = React.createRef();
    const groupByField = widget.data.groupByFields[0];
    const queryParams = queryString.parse(this.props.location.search);
    
    if (!widget.data.configByGroupByFieldName[groupByField.name]) {
      const errMsg = `No \`configByGroupByField\` entry for group by field with name "${groupByField.name}"`;
      return panic(errMsg);
    }

    const showPriceData = (() => {
      if (shouldShowReclassify(props.app, props.user, props.location)) {
        if (_isNil(queryParams.showPriceData)) {
          return !props.user.config.isStacklineSuperUser;
        }

        return !_isEmpty(queryParams.showPriceData) && queryParams.showPriceData !== '0';
      }

      return true;
    })();

    this.state = {
      sortBy: _get(widget.data.defaultSortField, 'name', null),
      sortDirection: widget.view.gridOptions.defaultSortDirection || 'desc',
      currentLayout: widget.view.gridOptions.defaultLayout || 'tile',
      mainMetricField: widget.data.configByGroupByFieldName[groupByField.name].mainMetricField,
      groupByField,
      // The current number of rendered pages.  If `widget.view.gridConfig.viewPageSize` is specified, the number of
      // rendered rows = `viewPageSize * pageNumber`, else it is `(widget.view.gridConfig.pageSize || 20) * pageNumber`
      pageNumber: 1,
      // The number of pages that have been fetched from the API.  The number of fetched items is equal to
      // `(widget.view.gridConfig.pageSize || 20) * fetchedPageCount`.
      fetchedPageCount: 0,
      isLoading: true,
      isSelectAllChecked: false,
      reclassifyProducts: [],
      showPriceData,
      showOnlyBreadcrumbMismatches: false,
      hideAlreadyClassifiedEntities: false,
      alreadyClassifiedStacklineSkus: [],
      pageNumberSuperUser: 1
    };
  }

  public componentDidMount() {
    this.cancelSource = CancelToken.source();
    this.primaryApiCall(this.props);
    this.addEventListeners();
  }

  private toggleShowOnlyBreadcrumbMismatches = () => { 
    this.clearAllSelections();
    this.setState({ showOnlyBreadcrumbMismatches: !this.state.showOnlyBreadcrumbMismatches });
  }

  private toggleHideAlreadyClassifiedEntities = () => {
    this.clearAllSelections();
    this.setState({ hideAlreadyClassifiedEntities: !this.state.hideAlreadyClassifiedEntities });
  };

  private handlePageChangeSuperUser = (pageNum: number) => {
    this.setState({ pageNumberSuperUser: pageNum , isSelectAllChecked: false });
    this.childDiv.current.scrollIntoView();
    
  }

  public componentWillReceiveProps(nextProps: EntityGridProps) {
    const keys = [
      'retailer',
      'mainTimePeriod',
      'comparisonTimePeriod',
      'queryConditions',
      'location.search',
      'widget',
      'aggregationConditions',
      'categoriesByRetailerId'
    ];

    if (anyNotEq(keys, this.props, nextProps)) {
      const { widget } = nextProps;
      const groupByField = widget.data.groupByFields[0];

      const updatedState = {
        // sortDirection: widget.view.gridOptions.defaultSortDirection || 'desc',
        currentLayout: widget.view.gridOptions.defaultLayout || 'tile',
        mainMetricField: widget.data.configByGroupByFieldName[groupByField.name].mainMetricField,
        groupByField,
        fetchedPageCount: 0,
        pageNumber: 1,
        isLoading: true      
      };

      this.setState(updatedState, () => {
        if (typeof this.cancelSource !== typeof undefined) {
          this.cancelSource!.cancel('Canceled network request: grid2');
        }
        this.cancelSource = CancelToken.source();
        this.primaryApiCall(nextProps);
      });
    }
  }

  public componentDidUpdate(prevProps: EntityGridProps, prevState: EntityGridState) {
    if (anyNotEq(['mainMetricField', 'groupByField', 'showPriceData'], prevState, this.state)) {
      this.handleUpdateStateOnMetricChange(this.props);
    }

    if (!!prevProps.groupByFieldOverride && this.props.groupByFieldOverride !== prevProps.groupByFieldOverride) {
      this.handleGroupByChange({ target: { value: this.props.groupByFieldOverride } });
    }
  }

  public componentWillUnmount() {
    if (typeof this.cancelSource !== typeof undefined) {
      this.cancelSource!.cancel('Cancel network request: grid');
    }
    this.removeEventListeners();
  }

  private getDataKey = () => this.props.widget.data.dataKeyOverride || buildEntityGridDataKey(this.props.uniqueName);

  private getDataToRender = () => {
    // eslint-disable-next-line prefer-const
    let { gridData, gridDataForComparison, app, retailer } = this.props;
    const { groupByField, mainMetricField, alreadyClassifiedStacklineSkus } = this.state;

    gridData = {
      ...gridData,
      ...gridDataForComparison
    };

    // special case: the srcIx can't guarantee the correct value will be selected from rowData
    // here I will sort the data in contentScore and starRate according the retail sale's seq, so the srcIx can peek the correct value.
    // do more check, since some data might missing and will cause read undefined value then throw error page
    if (app.name === 'beacon' && retailer.id === '0') {
      if (
        gridData['retailSales_converted.USD_by_retailerId'] &&
        gridData.contentScore_by_retailerId &&
        gridData.contentScore_by_retailerId.data &&
        gridData.contentScore_by_retailerId.data.length > 1 &&
        gridData.stars_by_retailerId &&
        gridData.stars_by_retailerId.data &&
        gridData.stars_by_retailerId.data.length > 1
      ) {
        const { data: retailSalesData } = gridData['retailSales_converted.USD_by_retailerId'];
        // do more check, since some data might missing and will cause read undefined value then throw error page
        const retailerSeq = retailSalesData.filter((aData) => aData && aData.name).map((aData) => aData.name);
        const { data: contentScoreData } = gridData.contentScore_by_retailerId;
        const { data: starsData } = gridData.stars_by_retailerId;
        // creating two maps for contentScoreData and starsData, key is retailer id, value is corresponding contentScoreData and starsData
        const mapForContentScore = new Map();
        const mapForStarData = new Map();
        contentScoreData.forEach((aData) => {
          if (aData && aData.name) {
            mapForContentScore.set(aData.name, aData);
          }
        });
        starsData.forEach((aData) => {
          if (aData && aData.name) {
            mapForStarData.set(aData.name, aData);
          }
        });
        const newSeqContentScoreData = [];
        const newSeqStarScoreData = [];
        retailerSeq.forEach((id) => {
          newSeqContentScoreData.push(mapForContentScore.get(id));
          newSeqStarScoreData.push(mapForStarData.get(id));
        });
        gridData.contentScore_by_retailerId.data = newSeqContentScoreData;
        gridData.stars_by_retailerId.data = newSeqStarScoreData;
      }
    }

    const aggregationData =
      _get(gridData, [`${mainMetricField.name}_by_${groupByField.name}`, `data`]) || _get(gridData, ['documents']);
    const dataToRender = Array.from(aggregationData || _get(gridData, 'documentData.data') || []);
    const alreadyClassifiedUniqueSkus = new Set(alreadyClassifiedStacklineSkus);
    let maybeFilteredDataToRender = this.state.showOnlyBreadcrumbMismatches
      ? dataToRender.filter((datum: any) => getIsBreadcrumbCategoryMismatch(datum.cardView))
      : dataToRender;
    maybeFilteredDataToRender = this.state.hideAlreadyClassifiedEntities
      ? maybeFilteredDataToRender.filter(
          (dataToFilter: any) => !getIsSkuAlreadyClassified(dataToFilter.cardView, alreadyClassifiedUniqueSkus)
        )
      : maybeFilteredDataToRender;
    const viewPageSize = this.getViewPageSize();
    maybeFilteredDataToRender.forEach((dataToFilter: any) => {
      if (!getIsSkuAlreadyClassified(dataToFilter.cardView, alreadyClassifiedUniqueSkus)) {
        dataToFilter.isAlreadyClassified = false;
      } else {
        dataToFilter.isAlreadyClassified = true;
      }
    });
    if (viewPageSize) {
      return maybeFilteredDataToRender.slice(0, viewPageSize * this.state.pageNumber);
    }
    maybeFilteredDataToRender.forEach((dataItem: { pageNumber: number }, i) => {
      dataItem.pageNumber = (Math.floor(i / NUMBER_OF_PRODUCTS_TO_DISPLAY_FOR_SUPERUSER)) + 1;
    });
    return maybeFilteredDataToRender;
  };

  private addEventListeners = () => {
    const { eventListeners } = this.props.widget.view;
    const { eventBus } = this.props;
    if (eventListeners && eventListeners.length > 0) {
      eventListeners.forEach((event: { name: any }) => {
        eventBus.on(event.name, this.primaryApiCall);
      });
    }
  };

  private removeEventListeners = () => {
    const { eventListeners } = this.props.widget.view;
    if (!_isEmpty(eventListeners)) {
      eventListeners.forEach((event: { name: string }) => this.props.eventBus.off(event.name, this.primaryApiCall));
    }
  };

  /**
   * @param {string} fieldName The field name to sort by
   * @param {func?} cb A callback to be called after the sort direction is changed
   */
  private handleSortDirectionChange = (fieldName: string) => {
    const disableSortFor = _get(this.props.widget, ['data', 'disableSortFor'], []);
    if (!this.state.isLoading && !disableSortFor.includes(fieldName)) {
      const newSortDirection = this.state.sortDirection === 'desc' ? 'asc' : 'desc';

      this.setState(
        {
          sortDirection: newSortDirection,
          fetchedPageCount: 0,
          pageNumber: 1,
          sortBy: fieldName
        },
        () => this.primaryApiCall(this.props)
      );

      if (this.props.onSortDirectionChange) {
        this.props.onSortDirectionChange(fieldName, newSortDirection);
      }
    }
  };

  private handleUpdateStateOnMetricChange = (args) => {
    this.setState({ pageNumber: 1 });
    if (typeof this.cancelSource !== typeof undefined) {
      this.cancelSource!.cancel('Canceled network request: grid');
    }
    this.cancelSource = CancelToken.source();
    this.primaryApiCall(args);
  };

  private getPageSize = (props = this.props) => {
    if (shouldShowReclassify(props.app, props.user, props.location)) {
      return PAGE_SIZE_FOR_SUPERUSER;
    }
    else {
      return _get(props, 'widget.view.gridOptions.pageSize', DEFAULT_PAGE_SIZE_GRID);
    }
  }

  private getViewPageSize = (props = this.props) => _get(props, 'widget.view.gridOptions.viewPageSize');

  private handleWaypointEntered = () => {
    const disableInfiniteScroll = _get(this.props, 'widget.view.gridOptions.disableInfiniteScroll');
    if (this.state.isLoading || disableInfiniteScroll) {
      return;
    }

    const viewPageSize = this.getViewPageSize() || this.getPageSize();

    this.setState({ pageNumber: this.state.pageNumber + 1 }, () => {
      // If we do have a `viewPageSize` specified, we check to see if we already have enough data fetched to render
      // the next `viewPageSize` items from memory.  Otherwise, we make an API request for it.
      const requestedRowCount = this.state.fetchedPageCount * this.getPageSize();

      // If we've already fetched the full data set (total fetched data < total requested data), there is no more data
      // to fetch so bail out.
      const dataToRender = this.getDataToRender() || [];
      const availableRowCount = dataToRender.length;

      if (
        requestedRowCount > availableRowCount &&
        !this.state.showOnlyBreadcrumbMismatches &&
        !this.state.hideAlreadyClassifiedEntities
      ) {
        return;
      }

      const rowsNeeded = (this.state.pageNumber + 1) * viewPageSize;

      if (requestedRowCount < rowsNeeded) {
        this.primaryApiCall(this.props);
      }
    });
  };

  private handleNextPageClick = () => {
    const viewPageSize = this.getViewPageSize() || this.getPageSize();

    this.setState({ pageNumber: this.state.pageNumber + 1 }, () => {
      // If we do have a `viewPageSize` specified, we check to see if we already have enough data fetched to render
      // the next `viewPageSize` items from memory.  Otherwise, we make an API request for it.
      const requestedRowCount = this.state.fetchedPageCount * this.getPageSize();

      // If we've already fetched the full data set (total fetched data < total requested data), there is no more data
      // to fetch so bail out.
      const dataToRender = this.getDataToRender() || [];
      const availableRowCount = dataToRender.length;

      if (requestedRowCount > availableRowCount && !this.state.showOnlyBreadcrumbMismatches && !this.state.hideAlreadyClassifiedEntities) {
        return;
      }

      const rowsNeeded = (this.state.pageNumber + 1) * viewPageSize;

      if (requestedRowCount < rowsNeeded) {
        this.primaryApiCall(this.props);
      }
    });
  }

  private handlePrevPageClick = () => {
    this.setState({ pageNumber: this.state.pageNumber - 1 }, () => {
      this.getDataToRender();
    });
  }


  /**
   * Constructs a function that fetches the data for the grid using the default method.
   */
  private buildDefaultDataFetcher = (props = this.props) => {
    const {
      app,
      fetchEntityMetrics,
      widget,
      retailer,
      uniqueName,
      mainTimePeriod,
      comparisonTimePeriod,
      queryConditions,
      aggregationConditions,
      inverseComparisonTimePeriod,
      history
    } = props;
    const { pageNumber, sortDirection, mainMetricField, groupByField } = this.state;
    const { aggregationFields, entity, entityQueryConditions, disableComparisonRangeFilters, customResponseParser } =
      widget.data.configByGroupByFieldName[groupByField.name];

    // add keyword business and segment to the url when in the business and segment page
    const { location } = history;
    const { pathname } = location;

    const newEntity = addKeywordToEntityType(pathname, entity);

    const {
      data: { additionalFieldsForExport }
    } = widget;
    const { indexName } = mainMetricField;

    // Do not build aggregations if we are not fetching metrics data
    const builtAggregations = buildAggregations(aggregationFields, additionalFieldsForExport);

    const aggregations = _get(builtAggregations, '[0].aggregations');
    if (aggregations) {
      (aggregations as any).forEach((aggregationField) => {
        if (aggregationField && aggregationField.aggregateByFieldName && (aggregationField.aggregateByFieldName === 'reviewsCount' || aggregationField.aggregateByFieldName === 'reviewsRating') && app && app.name === 'atlas') {
          aggregationField.aggregateByTopHits = true;
          aggregationField.topHitsConditions = { sortByFieldName : "weekId" };
          aggregationField.function = "top_hits";
        }
      });
    }
    const aggregationFieldConditions =
      aggregationConditions || _get(builtAggregations, '[0].aggregationFieldConditions');
    const sortField = _get(
      widget,
      'view.gridOptions.sortByAggregationField',
      Option.of(aggregations)
        .map((aggs) => aggs.find((x) => x.aggregateByFieldName === this.state.sortBy))
        .getOrElse(aggregations)
    );
    const {
      entityComparisonFilters,
      entityGridAggregationConditions,
      exportComparisonFilters,
      exportAggregationConditions
    } = getEntityGridParams(
      retailer,
      inverseComparisonTimePeriod ? comparisonTimePeriod : mainTimePeriod,
      inverseComparisonTimePeriod ? mainTimePeriod : comparisonTimePeriod,
      aggregationConditions,
      mainMetricField
    );

    const queryConditionsCloned = mergeConditions(
      _cloneDeep(queryConditions),
      widget.data.additionalConditions || {
        rangeFilters: [],
        termFilters: []
      }
    );

    if (entityQueryConditions) {
      if (entityQueryConditions.rangeFilters) {
        queryConditionsCloned.rangeFilters = queryConditionsCloned.rangeFilters || [];
        entityQueryConditions.rangeFilters.forEach((filter) => queryConditionsCloned.rangeFilters.push(filter));
      }
      if (entityQueryConditions.termFilters) {
        queryConditionsCloned.termFilters = queryConditionsCloned.termFilters || [];
        entityQueryConditions.termFilters.forEach((filter) => queryConditionsCloned.termFilters.push(filter));
      }
    }
    if (aggregationFieldConditions) {
      entityGridAggregationConditions.termFilters = _unionWith(
        entityGridAggregationConditions.termFilters || [],
        _get(aggregationFieldConditions, 'termFilters', []),
        _isEqual
      );
      entityGridAggregationConditions.rangeFilters = _unionWith(
        entityGridAggregationConditions.rangeFilters || [],
        _get(aggregationFieldConditions, 'rangeFilters', []),
        _isEqual
      );
    }

    entityGridAggregationConditions.rangeFilters = _unionWith(
      entityGridAggregationConditions.rangeFilters || [],
      _get(queryConditionsCloned, 'rangeFilters', []),
      _isEqual
    );
    const requestOverrides = [
      {
        returnDocuments: !aggregations,
        doAggregation: !!aggregations,
        // The `processDocuments` flag is used to indicate that the returned documents should be parsed into
        // entity metrics directly rather than just storing the raw documents.
        processDocuments: _get(widget, ['data', 'processDocuments'], true),
        aggregations: Option.of(aggregations)
          .map((aggs) => [
            {
              aggregationFields: aggs,
              conditions: entityGridAggregationConditions,
              comparisonRangeFilters: disableComparisonRangeFilters ? null : entityComparisonFilters,
              groupByFieldName: groupByField.apiNameOverride || groupByField.name,
              sortDirection,
              sortByAggregationField: sortField
            }
          ])
          .getOrElse(aggregations),
        conditions: {
          ...queryConditionsCloned
        },
        pageNumber,
        pageSize: this.getPageSize(props),
        period: comparisonTimePeriod.id.split('-')[1],
        // searchType: !this.state.showPriceData ? 'atlas-product' : undefined,
        sortFilter: !aggregations
          ? {
              sortFields: [
                {
                  fieldName: 'salesScore',
                  direction: this.state.sortDirection || 'desc'
                }
              ]
            }
          : undefined
      }
    ];

    if (builtAggregations && builtAggregations.length > 0 && builtAggregations[0].derivations.length > 0) {
      const queryConditionsClonedForSecondaryRequest = {
        ...queryConditionsCloned
      };
      if (
        widget.data.additionalConditionFieldsForOnlyPrimaryMetric &&
        queryConditionsClonedForSecondaryRequest.termFilters
      ) {
        queryConditionsClonedForSecondaryRequest.termFilters =
          queryConditionsClonedForSecondaryRequest.termFilters.filter(
            (x) => widget.data.additionalConditionFieldsForOnlyPrimaryMetric.indexOf(x.fieldName) < 0
          );
      }
      // derived field
      requestOverrides.push({
        indexName: builtAggregations[1].indexName,
        dataFetchStrategy: builtAggregations[1].dataFetchStrategy,
        returnDocuments: !aggregations,
        doAggregation: !!aggregations,
        // The `processDocuments` flag is used to indicate that the returned documents should be parsed into
        // entity metrics directly rather than just storing the raw documents.
        processDocuments: true,
        conditions: {
          ...queryConditionsClonedForSecondaryRequest
        },
        aggregations: [
          {
            ...{
              aggregationFields: builtAggregations[1].aggregations,
              conditions: entityGridAggregationConditions,
              comparisonRangeFilters: disableComparisonRangeFilters ? null : entityComparisonFilters,
              groupByFieldName:
                builtAggregations[1].groupByFieldName || groupByField.apiNameOverride || groupByField.name,
              sortDirection,
              sortByAggregationField: builtAggregations[1].aggregations[0]
            }
          }
        ],
        pageNumber,
        pageSize: this.getPageSize(props),
        period: comparisonTimePeriod.id.split('-')[1],
        // searchType: !this.state.showPriceData ? 'atlas-product' : undefined,
        sortFilter: !aggregations
          ? {
              sortFields: [{ fieldName: 'salesScore', direction: 'desc' }]
            }
          : undefined
      });
    }
    const dataKey = buildEntityGridDataKey(uniqueName);
    const apiRequest = buildSearchRequest(dataKey, { entity: newEntity, retailer, app, indexName }, requestOverrides);

    const exportRequestOverrides = requestOverrides.map((requestOverride) => {
      const exportRequestOverride = _cloneDeep(requestOverride);
      if (exportRequestOverride.aggregations) {
        exportRequestOverride.aggregations[0].comparisonRangeFilters = exportComparisonFilters;
        exportRequestOverride.aggregations[0].conditions = exportAggregationConditions;
      }
      return exportRequestOverride;
    });

    // Removes unnecessary filters, e.g. retailPrice on buy box rate
    const exportRequestsOverridesCorrected = exportRequestOverrides.map((override) =>
      entitySearchServiceOperations.maybeCorrectConditions({ indexName }, override, app.name)
    );
    const exportRequest = buildSearchRequest(
      dataKey,
      {
        entity: newEntity,
        retailer,
        app,
        indexName,
        derivedFields: _get(builtAggregations, ['0', 'derivations'], [])
      },
      exportRequestsOverridesCorrected
    );

    this.setState({
      isLoading: true,
      apiRequest,
      exportRequest
    });
    return () =>
      fetchEntityMetrics(
        buildEntityGridDataKey(uniqueName),
        {
          entity: newEntity,
          retailer,
          app,
          indexName,
          inverseComparisonTimePeriod,
          customResponseParser,
          derivedFields: _get(builtAggregations, ['0', 'derivations'], []),
          mergeIfStatePropertyValueExists: _get(widget, ['data', 'mergeIfStatePropertyValueExists'])
        },
        requestOverrides,
        this.cancelSource!.token
      ).then((statePropertyValue: any) => ({
        pageNumber,
        statePropertyValue
      }));
  };

  private primaryApiCall = async (props = this.props) => {
    const {
      // This is a callback that will be triggered after the API requests used to fetch data for the grid have
      // completed.
      widget,
      uniqueName,
      onDataFetched: onDataFetchedProps,
      eventBus
    } = props;
    const { mainMetricField, alreadyClassifiedStacklineSkus } = this.state;

    if (!this.state.sortBy) {
      this.state.sortBy = mainMetricField.name; // why is this being directly mutated?
    }

    const customDataFetcher =
      this.props.customDataFetchFunction || _get(widget, 'view.gridOptions.customDataFetchFunction');

    const fetchData = customDataFetcher || this.buildDefaultDataFetcher(props);

    try {
      const data = await fetchData(uniqueName);
      const { pageNumber, statePropertyValue } = data;
      if (statePropertyValue.classifiedSkus) {
        const allClassifiedSkus = [...alreadyClassifiedStacklineSkus, ...statePropertyValue.classifiedSkus];
        this.setState({ alreadyClassifiedStacklineSkus: allClassifiedSkus });
      }
      
      this.setState(
        (state) => {
          const { fetchedPageCount } = state;
          return {
            ...state,
            ...(!props
              ? { reclassifyProducts: [], isSelectAllChecked: false, alreadyClassifiedStacklineSkus: [] }
              : {}),
            isLoading: false,
            fetchedPageCount: fetchedPageCount + 1
          };
        },
        () => {
          const onDataFetched = onDataFetchedProps || _get(widget, 'view.gridOptions.onDataFetched');
          if (onDataFetched) {
            onDataFetched(uniqueName, { pageNumber, statePropertyValue, eventBus });
          }

          return statePropertyValue;
        }
      );
    } catch (err) {
      if (!(err.message.includes('Canceled network request') || err.message.includes('Cancel network request'))) {
        console.error(err);
      }
    }
  };

  private handleChangeLayout = (showTableView: string) =>
    this.setState({ currentLayout: showTableView ? 'table' : 'tile' });

  private handleAggregateByChange = (evt: any) => {
    const { widget } = this.props;
    const { groupByField } = this.state;
    widget.data.configByGroupByFieldName[groupByField.name].aggregationFields.forEach((metricsField) => {
      metricsField.isSelected = metricsField.name === evt.target.value;
    });
    const mainMetricField = widget.data.configByGroupByFieldName[groupByField.name].aggregationFields.filter(
      _prop('isSelected')
    )[0];
    this.setState({ mainMetricField });
  };

  private handleGroupByChange = (evt: { target: { value: any } }) => {
    const { widget } = this.props;

    widget.data.groupByFields.forEach((metricsField: { isSelected: boolean; name: any }) => {
      metricsField.isSelected = metricsField.name === evt.target.value;
    });

    const groupByField = widget.data.groupByFields.find(_prop('isSelected'));
    this.setState({ groupByField, pageNumber: 1, fetchedPageCount: 0 });
    this.props.eventBus.emit('entityGridGroupByChange', {
      uniqueName: this.props.uniqueName,
      groupByFieldName: evt.target.value
    });
  };
  
  private handleSelectAllReclassifyCheckbox = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = evt.target;
    const { reclassifyProducts, pageNumberSuperUser } = this.state;
    const dataToRender = this.getDataToRender();
    let allSelectedItems = [];
    if (checked) {
      if (pageNumberSuperUser > 1 && reclassifyProducts.length > 0) {
        allSelectedItems.push(...reclassifyProducts);
        const indexStart = (pageNumberSuperUser - 1) * NUMBER_OF_PRODUCTS_TO_DISPLAY_FOR_SUPERUSER;
        const indexEnd = (pageNumberSuperUser) * NUMBER_OF_PRODUCTS_TO_DISPLAY_FOR_SUPERUSER;
        const additionalData = dataToRender.slice(indexStart, indexEnd);
        additionalData.forEach((item: any) => allSelectedItems.push(item.entity.stacklineSku));
      }
      else {
        const dataSelected = dataToRender.slice((pageNumberSuperUser - 1) * NUMBER_OF_PRODUCTS_TO_DISPLAY_FOR_SUPERUSER, pageNumberSuperUser * NUMBER_OF_PRODUCTS_TO_DISPLAY_FOR_SUPERUSER);
        dataSelected.forEach((item: any) => allSelectedItems.push(item.entity.stacklineSku));
      }
    }
    else {
      allSelectedItems.push(...reclassifyProducts);
      const currentPageStacklineSkus = [];
      const currentPageData = dataToRender.slice((pageNumberSuperUser - 1) * NUMBER_OF_PRODUCTS_TO_DISPLAY_FOR_SUPERUSER, pageNumberSuperUser * NUMBER_OF_PRODUCTS_TO_DISPLAY_FOR_SUPERUSER);
      currentPageData.forEach((item: {pageNumber: number }) => currentPageStacklineSkus.push(item.entity.stacklineSku));
      allSelectedItems = _difference(allSelectedItems, currentPageStacklineSkus);
    }

    this.setState({
      reclassifyProducts: allSelectedItems,
      isSelectAllChecked: checked
    });
  };

  private clearAllSelections = () => {
    const allSelectedItems = [];
    this.setState({
      reclassifyProducts: allSelectedItems,
      isSelectAllChecked: false
    })

  }

  private toggleShowPriceData = () => {
    this.clearAllSelections();
    this.setState({ pageNumberSuperUser: 1 });
    this.props.clearEntitySearchService();
    const queryParams = queryString.parse(this.props.location.search.substring(1));
    const newQueryParams = queryString.stringify({
      ...queryParams,
      showPriceData: this.state.showPriceData ? 0 : 1
    });

    this.props.history.push(`/search?${newQueryParams}`);
    this.setState(({ showPriceData }) => ({ showPriceData: !showPriceData }));
  };

  private handleReclassifyCheckboxClick = (product: any) => {
    const {
      isChecked,
      entity: { stacklineSku }
    } = product;

    this.setState(({ reclassifyProducts }) => ({
      reclassifyProducts: isChecked
        ? [...reclassifyProducts, stacklineSku]
        : reclassifyProducts.filter((item) => item !== stacklineSku)
    }));
  };

  private getLayoutToUse = (dataToRender: any) => {
    const { currentLayout } = this.state;

    if (!this.state.showPriceData) {
      return 'tile';
    }
    return !_get(dataToRender, '[0].cardView.stacklineSku') && currentLayout !== 'chip' ? 'table' : currentLayout;
  };

  public render() {
    const { app, uniqueName, widget, retailer, location, user, CustomLoading, ...props } = this.props;
    const {
      isLoading,
      isSelectAllChecked,
      groupByField,
      apiRequest,
      exportRequest,
      mainMetricField,
      pageNumber,
      reclassifyProducts,
      sortDirection
    } = this.state;
    const { entity } = widget.data.configByGroupByFieldName[groupByField.name];
    const dataToRender = this.getDataToRender();
    const layoutToUse = this.getLayoutToUse(dataToRender);
    const showReclassifyHeader = shouldShowReclassify(app, user, location);
    const title =
      (widget.view.gridOptions.titleOptions && widget.view.gridOptions.titleOptions[`${entity.type}`]) ||
      widget.view.gridOptions.title ||
      entity.displayNamePlural ||
      entity.displayName;
    const exportFileName = `${app.name}_${mainMetricField.displayName.toLowerCase()}_download.csv`;

    const viewPageSize = this.getViewPageSize();
    const rowsToRender = viewPageSize ? viewPageSize * this.state.pageNumber : undefined;
    const { hideGridHeader } = widget.view.gridOptions;
    const Loading = _get(widget, 'view.customLoading', null) || CustomLoading;
    const pageSize = this.getPageSize();
    return (
      <>
      <span ref={this.childDiv}></span>
      <EntityGridRenderer
        showReclassifyHeader={showReclassifyHeader}
        hideGridHeader={hideGridHeader}
        groupByFields={widget.data.groupByFields}
        handleChangeLayout={this.handleChangeLayout}
        handleAggregateByChange={this.handleAggregateByChange}
        handleGroupByChange={this.handleGroupByChange}
        configByGroupByFieldName={widget.data.configByGroupByFieldName}
        groupByField={groupByField}
        title={title}
        exportRequest={exportRequest}
        exportFileName={exportFileName}
        isSelectAllChecked={isSelectAllChecked}
        handleSelectAllReclassifyCheckbox={this.handleSelectAllReclassifyCheckbox}
        reclassifyProducts={reclassifyProducts}
        apiRequest={apiRequest}
        isLoading={isLoading}
        pageNumber={pageNumber}
        dataToRender={dataToRender}
        handleWaypointEntered={this.handleWaypointEntered}
        handleSortDirectionChange={this.handleSortDirectionChange}
        sortDirection={sortDirection}
        mainMetricField={mainMetricField}
        uniqueName={uniqueName}
        dataKey={this.getDataKey()}
        handleReclassifyCheckboxClick={this.handleReclassifyCheckboxClick}
        showPriceData={this.state.showPriceData}
        toggleShowPriceData={this.toggleShowPriceData}
        rowsToRender={rowsToRender}
        {...widget.view.gridOptions}
        {...props}
        firstColumnDefOverrides={
          props.firstColumnDefOverrides ||
          _get(widget.data.configByGroupByFieldName, [groupByField.name, 'firstColumnDefOverrides']) ||
          widget.view.gridOptions.firstColumnDefOverrides
        }
        // if retailer if === '0', we might in multiretailer page. in this case should unable change the layout for grid
        enableSwitchingLayouts={retailer.id !== '0'}
        layoutToUse={layoutToUse}
        filterRows={widget.view.gridOptions.filterRows || this.props.filterRows || undefined}
        sortRows={widget.view.gridOptions.sortRows || this.props.sortRows || undefined}
        sortByMetric={this.props.sortByMetricOverride || this.state.sortBy}
        CustomLoading={Loading}
        computePerRowAggregatedMetrics={widget.view.gridOptions.computePerRowAggregatedMetrics}
        disableMetricFormatting={widget.view.gridOptions.disableMetricFormatting}
        showCardBackDeleteButton={widget.view.gridOptions.showCardBackDeleteButton}
        showOnlyBreadcrumbMismatches={this.state.showOnlyBreadcrumbMismatches}
        toggleShowOnlyBreadcrumbMismatches={this.toggleShowOnlyBreadcrumbMismatches}
        hideAlreadyClassifiedEntities={this.state.hideAlreadyClassifiedEntities}
        toggleHideAlreadyClassifiedEntities={this.toggleHideAlreadyClassifiedEntities}
        widget={widget}
        handleNextPageClick={this.handleNextPageClick}
        handlePrevPageClick={this.handlePrevPageClick}
        pageSize={pageSize}
        handlePageChangeSuperUser={this.handlePageChangeSuperUser}
        clearAllSelections={this.clearAllSelections}
        numSelectedItems={this.state.reclassifyProducts.length}
      />
      </>
    );
  }
}

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withBus('eventBus'));

const EnhancedEntityGrid = enhance(EntityGrid);

export default EnhancedEntityGrid;
