/**
 * Read-only tabs indicating the user's current progress through the flow
 */

import React, { useEffect } from 'react';
import { useOnce } from 'src/utils/Hooks';
import queryString from 'qs';
import { makeStyles } from '@mui/styles';
import { withPush } from 'src/utils/hoc';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import colors from 'src/utils/colors';
import { ChevronIcon } from 'src/components/SvgIcons';
import { store } from 'src/main';
import * as adBudgetEditorActions from 'src/store/modules/adManager/adBudgetEditor/actions';

const useStyles = makeStyles({
  label: {
    '&:active': {},
    fontSize: 18,
    color: colors.lightGrey,
    fontWeight: 400
  },
  completed: { color: `${colors.darkBlue} !important` },
  active: { color: `${colors.blue} !important` }
});

const buildSubtabLink = (subtab) => {
  const curParams = queryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const newSearchString = queryString.stringify({ ...curParams, subtab });
  return `?${newSearchString}`;
};

const AdBudgetEditorTabs = ({ app, push, adBudgetEditor }) => {
  const classes = useStyles();
  const [step, setStep] = React.useState(0);
  useOnce(() => {
    store.dispatch(adBudgetEditorActions.resetBudgetEditor());
    if (!adBudgetEditor.platform.platformId) {
      push(buildSubtabLink('platformSelect'));
    }
  });
  // Next line: this result should comes from redux
  // const { platform, setup, featured, target, projections, market, creative, confirmation } = result;

  // default subtabs
  const steps = [
    { value: 'platformSelect', displayName: 'Platform' },
    // { value: 'budgetAlignment', displayName: 'Budget Alignment' },
    { value: 'budgetPeriod', displayName: 'Budget Period' },
    { value: 'budgetAmount', displayName: 'Budget Amount' },
    { value: 'budgetAllocation', displayName: 'Budget Allocation' },
    { value: 'confirmation', displayName: 'Confirmation' }
  ];

  const subtabMapping = {
    platformSelect: 0,
    // portfolioAlign: 1,
    // campaignAlign: 1,
    budgetPeriod: 1,
    budgetAmount: 2,
    budgetAllocation: 3,
    confirmation: 4,
    submit: 4
  };

  const getStepBySubtab = () => {
    const { subtab } = app.queryParams;
    return subtabMapping[subtab];
  };
  useEffect(() => {
    setStep(getStepBySubtab());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.queryParams]);

  const connector = (
    <ChevronIcon
      style={{
        transform: 'rotate(-90deg)',
        width: 30,
        height: 30,
        stroke: colors.lightGrey
      }}
    />
  );

  return (
    <div className={classes.root}>
      <Stepper activeStep={step} connector={connector}>
        {steps.map(({ displayName }) => (
          <Step key={displayName}>
            <StepLabel
              classes={{
                label: classes.label,
                active: classes.active,
                completed: classes.completed
              }}
              icon={<></>}
              className={classes.label}
            >
              {displayName}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

AdBudgetEditorTabs.propTypes = {
  adBudgetEditor: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired
};

const mapStateToProps = ({ app, adBudgetEditor }) => ({ app, adBudgetEditor });

export default connect(
  mapStateToProps,
  null
)(withPush(AdBudgetEditorTabs));
