import _merge from 'lodash/merge';
import { getChartDisplayTimePeriod } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { chartColorsLineChart, getBaseLineChartSeries, computeLastWeek } from 'src/components/EntityPage/Renderer/util';
import { getWeekLastDate, moveForwardOneWeek } from 'src/utils/dateformatting';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { buildMetricValue } from 'src/utils/metrics';
import { getWeekId } from 'src/utils/dateUtils';
import moment from 'moment';
import colors from 'src/utils/colors';
import { getTooltipDiv } from 'src/components/EntityPage/Renderer/tooltipDiv';
import { fillInMissingTimePeriods } from 'src/components/EntityPage/TrendChart/omniTrendChartUtils';

export const getTrendChartInfo = (widget, chartData, mainTimePeriod, comparisonTimePeriod) => {
  if (!chartData) {
    return null;
  }
  const { view, data } = widget;
  const groupByField = data.groupByFields[0];
  const chartDisplayTimePeriod = getChartDisplayTimePeriod(mainTimePeriod);
  const filledData = fillInMissingTimePeriods(chartDisplayTimePeriod, comparisonTimePeriod, chartData);
  const { main } = filledData;
  const { chartPropsOverride, metricFields } = view;
  const [metricField] = metricFields;
  // TODO: add the compute subtitle logic
  // TODO: currencySymbol and mainLocale shouldn't give the fixed value
  const xAxisKey = 'name';
  main.sort((a, b) => {
    if (a[xAxisKey] < b[xAxisKey]) {
      return -1;
    }
    if (a[xAxisKey] > b[xAxisKey]) {
      return 1;
    }
    return 0;
  });
  const metricsDataByWeekId = {
    data: main,
    subtitle: 'All Category',
    currencySymbol: '$',
    locale: 'en',
    dataType: metricField.dataType,
    metricType: metricField.metricType,
    aggregationFunction: metricField.aggregationFunction
  };
  const chartComparisonDisplayTimePeriod = getChartDisplayTimePeriod(comparisonTimePeriod);

  return {
    chartPropsOverride,
    chartDisplayTimePeriod,
    chartComparisonDisplayTimePeriod,
    metricsDataByWeekId,
    groupByFieldName: groupByField.name,
    widget
  };
};

const mkTooltipFormatter = (
  {
    isSameMetricComparison,
    mainCurrencySymbol,
    mainLocale,
    chartSeries,
    currencySymbols,
    locales,
    loadLastWeek,
    chartComparisonDisplayTimePeriod
  },
  chartDisplayTimePeriod,
  checkFirstDataPoint
) =>
  function formatter() {
    let tooltipStyle = '';
    let percentChangeDiv = '';
    // compute metric change, only if there are two metrics and they are of same type
    if (isSameMetricComparison && this.points.length > 1) {
      let metricsChange = { icon: '+', color: colors.green };
      let percentChange =
        this.points[0].y > 0
          ? (this.points[1].y - this.points[0].y) / this.points[0].y
          : this.points[1].y > 0
          ? 100
          : 0;
      // if the change is negative, then display in red with minus icon
      if (percentChange < 0) {
        metricsChange = { icon: '\u2212', color: colors.red };
        percentChange *= -1;
      }
      percentChange = buildMetricValue(
        percentChange,
        METRICTYPE.PERCENT,
        mainCurrencySymbol,
        true,
        null,
        mainLocale
      ).value;
      const tooltipWidth =
        100 +
        (this.points[0].x.name ? Math.min(this.points[0].x.name.length, 10) * 6 : percentChange.toString().length * 7);
      percentChangeDiv = `<div style="display:inline-block; float:right; color:${metricsChange.color};">
        ${metricsChange.icon}${percentChange}%
      </div>`;
      tooltipStyle += `width: ${tooltipWidth}px;`;
    }
    let yAxisMetricsDiv = '';
    const mainWeekId = getWeekId(this.points[0].x);
    const { lastWeekOfYear: mainLastWeekOfYear } = chartDisplayTimePeriod;
    let weekId = mainWeekId;
    if (mainWeekId > mainLastWeekOfYear && mainWeekId % 100 === 1) {
      // do 53 as the week
      weekId = +`${mainLastWeekOfYear}`;
    }
    for (let i = this.points.length - 1; i >= 0; i -= 1) {
      const value = this.points[i];

      if (checkFirstDataPoint && value.point.index === 0 && loadLastWeek) {
        percentChangeDiv = '';
      } else {
        const { color } = chartSeries[value.series.index];
        const metricValue = buildMetricValue(
          value.y,
          chartSeries[value.series.index].metricType,
          currencySymbols[value.series.index],
          true,
          null,
          locales[value.series.index]
        );
        yAxisMetricsDiv += `<div style="margin-top:5px;color:${color};">
              ${metricValue.prefix || ''}${metricValue.value}
              <span class='sl-metric__suffix'>${metricValue.suffix || ''} ${moment(
          getWeekLastDate(
            i === 0
              ? color === '#46a8f6' || chartComparisonDisplayTimePeriod.id === 'prior-period'
                ? weekId
                : weekId - 100
              : weekId
          )
        ).format('M/D')}</span>
            </div>`;
      }
    }

    return checkFirstDataPoint && yAxisMetricsDiv === ''
      ? false
      : getTooltipDiv(tooltipStyle, percentChangeDiv, yAxisMetricsDiv, this.x, undefined, weekId);
  };

export const getCommonSummaryTrendParameters = ({
  chartPropsOverride,
  chartDisplayTimePeriod,
  chartComparisonDisplayTimePeriod,
  metricsDataByWeekId
}) => {
  const { currencySymbol, locale } = metricsDataByWeekId;
  const legendDivs = [];
  let legendClassName = '';
  let legendValue = '';
  let legendChange = '';
  let legendAbsoluteChange = '';
  const chartSeries = getBaseLineChartSeries(chartDisplayTimePeriod, metricsDataByWeekId);
  let mainMetricSum = 0.0;
  let mainMetricDataPointCount = 0.0;
  let mainMetricLastValue = 0.0;
  const currencySymbols = [currencySymbol, currencySymbol];
  const locales = [locale, locale];
  // TODO: this value should calculate
  const isSameMetricComparison = true;

  const isYearOverYearComparison =
    isSameMetricComparison &&
    chartDisplayTimePeriod.endWeek - chartComparisonDisplayTimePeriod.endWeek === 100 &&
    !(chartDisplayTimePeriod.id === '52w' && chartComparisonDisplayTimePeriod.id === 'prior-period');

  const loadLastWeek = chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id !== '1w';
  const mainStartWeek = loadLastWeek
    ? computeLastWeek(chartDisplayTimePeriod.startWeek)
    : chartDisplayTimePeriod.startWeek;

  metricsDataByWeekId.data
    .filter((dataPoint) => dataPoint.weekId >= mainStartWeek && dataPoint.weekId <= chartDisplayTimePeriod.endWeek)
    .forEach((dataPoint) => {
      let { weekEnding } = dataPoint;
      weekEnding = typeof weekEnding === 'string' ? new Date(Date.parse(weekEnding)) : weekEnding;
      if (chartDisplayTimePeriod.id === '1w' || chartDisplayTimePeriod.startWeek === chartDisplayTimePeriod.endWeek) {
        chartSeries[0].zones = [{ color: chartColorsLineChart[2] }];
        if (chartDisplayTimePeriod.endWeek === dataPoint.weekId) {
          chartSeries[0].data.push({
            x: weekEnding.getTime(),
            y: dataPoint.value,
            marker: { enabled: true, radius: 3, fillColor: chartColorsLineChart[0] }
          });
        } else {
          chartSeries[0].data.push({
            x: weekEnding.getTime(),
            y: dataPoint.value
          });
        }
      } else {
        chartSeries[0].data.push([weekEnding.getTime(), dataPoint.value]);
      }
      if (
        dataPoint.weekId >= chartDisplayTimePeriod.timePeriod.startWeek &&
        dataPoint.weekId <= chartDisplayTimePeriod.timePeriod.endWeek
      ) {
        mainMetricSum += dataPoint.value;
        mainMetricDataPointCount += 1;
        mainMetricLastValue = dataPoint.value;
      }
    });
  let mainLegendValue =
    metricsDataByWeekId.aggregationFunction === 'avg' || metricsDataByWeekId.timePeriodAggregationFunction === 'avg'
      ? mainMetricSum / (mainMetricDataPointCount > 0 ? mainMetricDataPointCount : 1)
      : mainMetricSum;

  if (metricsDataByWeekId.timePeriodAggregationFunctionType === 'lastValue') {
    mainLegendValue = mainMetricLastValue;
  }
  let mainLegendMetricValue = { prefix: '', value: 0, suffix: '' };
  try {
    mainLegendMetricValue = buildMetricValue(
      mainLegendValue,
      metricsDataByWeekId.metricType,
      currencySymbol,
      true,
      metricsDataByWeekId.dataType,
      locale
    );
  } catch (e) {
    console.warn(e);
  }

  if (chartDisplayTimePeriod.showExtendedMonths) {
    for (let i = chartDisplayTimePeriod.endWeek + 1; i <= chartDisplayTimePeriod.lastWeekOfYear; i += 1) {
      const weekEnding = getWeekLastDate(i);
      if (!Number.isNaN(weekEnding.getTime())) {
        chartSeries[0].data.push([weekEnding.getTime(), null]);
      }
    }
  }

  if (chartComparisonDisplayTimePeriod) {
    let comparisonMetricSum = 0.0;
    let comparisonMetricDataPointCount = 0.0;
    let comparisonMetricLastValue = 0.0;
    chartSeries.push({
      name: chartComparisonDisplayTimePeriod.displayName,
      metricType: metricsDataByWeekId.metricType,
      data: [],
      color: chartColorsLineChart[1],
      marker: {
        lineColor: chartColorsLineChart[1],
        fillColor: chartColorsLineChart[1],
        lineWidth: 3,
        symbol: 'circle'
      }
    });
    const mainStartYear = Math.floor(chartDisplayTimePeriod.startWeek / 100);
    const mainEndYear = Math.floor(chartDisplayTimePeriod.endWeek / 100);
    const mainTimePeriodContains53 = moment([mainStartYear]).isLeapYear() && mainStartYear !== mainEndYear;

    const comparisonStartWeek = loadLastWeek
      ? computeLastWeek(chartComparisonDisplayTimePeriod.startWeek)
      : chartComparisonDisplayTimePeriod.startWeek;

    metricsDataByWeekId.data.forEach((dataPoint) => {
      if (comparisonStartWeek <= dataPoint.weekId && chartComparisonDisplayTimePeriod.endWeek >= dataPoint.weekId) {
        const shouldMoveForwardOneWeek = dataPoint.weekId < +`${mainStartYear - 1}53` && mainTimePeriodContains53;
        if (shouldMoveForwardOneWeek) {
          dataPoint = moveForwardOneWeek(dataPoint);
        }

        let weekEnding = isYearOverYearComparison ? dataPoint.weekEndingNextYear : dataPoint.weekEnding;
        weekEnding = typeof weekEnding === 'string' ? new Date(Date.parse(weekEnding)) : weekEnding;
        if (chartDisplayTimePeriod.id === '1w' || chartDisplayTimePeriod.startWeek === chartDisplayTimePeriod.endWeek) {
          chartSeries[1].zones = [{ color: chartColorsLineChart[2] }];
          if (chartComparisonDisplayTimePeriod.endWeek === dataPoint.weekId) {
            chartSeries[1].data.push({
              x: weekEnding.getTime(),
              y: dataPoint.value,
              marker: {
                enabled: true,
                radius: 3,
                fillColor: chartColorsLineChart[1]
              }
            });
          } else {
            chartSeries[1].data.push({
              x: weekEnding.getTime(),
              y: dataPoint.value
            });
          }
        } else {
          chartSeries[1].data.push([weekEnding.getTime(), dataPoint.value]);
        }
        if (
          chartComparisonDisplayTimePeriod.timePeriod.startWeek <= dataPoint.weekId &&
          chartComparisonDisplayTimePeriod.timePeriod.endWeek >= dataPoint.weekId
        ) {
          comparisonMetricSum += dataPoint.value;
          comparisonMetricDataPointCount += 1;
          comparisonMetricLastValue = dataPoint.value;
        }
      } else if (chartDisplayTimePeriod.showExtendedMonths) {
        if (
          chartComparisonDisplayTimePeriod.firstWeekOfYear <= dataPoint.weekId &&
          chartComparisonDisplayTimePeriod.lastWeekOfYear >= dataPoint.weekId
        ) {
          let weekEnding = isYearOverYearComparison ? dataPoint.weekEndingNextYear : dataPoint.weekEnding;
          weekEnding = typeof weekEnding === 'string' ? new Date(Date.parse(weekEnding)) : weekEnding;
          if (chartDisplayTimePeriod.id === '1w') {
            if (
              chartComparisonDisplayTimePeriod.id === 'prior-period' &&
              chartComparisonDisplayTimePeriod.endWeek < dataPoint.weekId
            ) {
              return;
            }
            chartSeries[1].data.push({
              x: weekEnding.getTime(),
              y: dataPoint.value
            });
          } else {
            chartSeries[1].data.push([weekEnding.getTime(), dataPoint.value]);
          }
        }
      }
    });

    let comparisonLegendValue =
      metricsDataByWeekId.aggregationFunction === 'avg' || metricsDataByWeekId.timePeriodAggregationFunction === 'avg'
        ? comparisonMetricSum / (comparisonMetricDataPointCount > 0 ? comparisonMetricDataPointCount : 1)
        : comparisonMetricSum;
    if (metricsDataByWeekId.timePeriodAggregationFunctionType === 'lastValue') {
      comparisonLegendValue = comparisonMetricLastValue;
    }
    if (isSameMetricComparison) {
      let metricsChangeIcon = { icon: '+', className: 'increase' };
      let diff = mainLegendValue - comparisonLegendValue;
      if (diff.toString().indexOf('e') !== -1) {
        diff = parseFloat(diff.toFixed(5));
      }
      let metricsChangePercent = diff / Math.abs(comparisonLegendValue);
      if (metricsChangePercent && metricsChangePercent < 0) {
        metricsChangePercent *= -1;
        metricsChangeIcon = { icon: '\u2212', className: 'decrease' };
      }

      const metricsChangeMetricValue = buildMetricValue(
        mainLegendValue - comparisonLegendValue,
        metricsDataByWeekId.metricType,
        currencySymbol,
        true,
        null,
        locale
      );
      const metricsChangePercentMetricValue = buildMetricValue(
        metricsChangePercent,
        METRICTYPE.PERCENT,
        currencySymbol,
        true,
        null,
        locale
      );

      legendClassName = `legend__percent-change-metric--${metricsChangeIcon.className}`;
      legendChange = `${metricsChangeIcon.icon}${metricsChangePercentMetricValue.value}${
        metricsChangePercentMetricValue.suffix || ''
      }`;
      legendAbsoluteChange = `${metricsChangeIcon.icon}${
        metricsChangeMetricValue.prefix || ''
      }${metricsChangeMetricValue.value.replace('-', '')}${metricsChangeMetricValue.suffix || ''}`;
    }
    legendValue = `${mainLegendMetricValue.prefix || ''}${mainLegendMetricValue.value}${
      mainLegendMetricValue.suffix || ''
    }`;
  }
  [chartSeries[0].legendDiv, chartSeries[1].legendDiv] = legendDivs;
  const xAxis = [];
  const longestSeriesData =
    chartSeries[0].data.length > chartSeries[1].data.length ? chartSeries[0].data : chartSeries[1].data;
  if (longestSeriesData.length > 0) {
    let xAxisMin = longestSeriesData[0][0];
    let xAxisMax = longestSeriesData[longestSeriesData.length - 1][0];
    if (chartDisplayTimePeriod.id === 'ytd' || chartDisplayTimePeriod.id === '1w') {
      // Always show the full year so we can show the comparison period "tail"
      xAxisMin = getWeekLastDate(chartDisplayTimePeriod.firstWeekOfYear).getTime();
      xAxisMax = getWeekLastDate(chartDisplayTimePeriod.lastWeekOfYear).getTime();
    }
    if (chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id === '1w') {
      xAxisMin = getWeekLastDate(chartComparisonDisplayTimePeriod.startWeek).getTime();
      xAxisMax = getWeekLastDate(chartDisplayTimePeriod.endWeek).getTime();
    }
    if (
      chartComparisonDisplayTimePeriod &&
      chartComparisonDisplayTimePeriod.id === 'prior-period' &&
      chartDisplayTimePeriod.id !== '1w' &&
      chartDisplayTimePeriod.startWeek !== chartDisplayTimePeriod.endWeek &&
      chartSeries[1].data.length &&
      chartSeries[0].data.length
    ) {
      [[xAxisMin]] = chartSeries[1].data;
      [xAxisMax] = chartSeries[0].data[chartSeries[0].data.length - 1];
    }

    xAxis.push({
      className:
        chartDisplayTimePeriod.id === 'ytd' || chartDisplayTimePeriod.id === '1w'
          ? 'highcharts-axis-labels highcharts-xaxis-labels-weekly-trend'
          : '',
      min: xAxisMin,
      max: xAxisMax,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        align: 'left'
      }
    });
  } else {
    xAxis.push({});
  }
  let chartProps = {
    chart: { type: 'line' },
    title: { text: metricsDataByWeekId.displayName },
    subtitle: {
      text: '',
      style: {
        borderBottom: 'none'
      }
    },
    plotOptions: {
      fillEnabled: true,
      series: {
        states: {
          hover: {
            enabled: true
          }
        }
      }
    },
    legend: {
      legendAbsoluteChange,
      legendValue,
      legendChange,
      legendClassName,
      labelFormatter() {
        return this.userOptions.legendDiv;
      }
    },
    tooltip: {
      formatter: mkTooltipFormatter(
        {
          isSameMetricComparison,
          mainCurrencySymbol: currencySymbol,
          mainLocale: locale,
          chartSeries,
          currencySymbols,
          locales,
          loadLastWeek,
          chartComparisonDisplayTimePeriod
        },
        chartDisplayTimePeriod,
        true
      ),
      positioner(labelWidth, labelHeight, point) {
        return { x: point.plotX - labelWidth + 10, y: point.plotY - labelHeight / 2 };
      }
    },
    xAxis,
    yAxis: [
      {
        labels: {
          formatter() {
            const val = buildMetricValue(
              this.value,
              metricsDataByWeekId.metricType,
              currencySymbols[0],
              true,
              null,
              'en',
              locales[0]
            );
            return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
          }
        }
      },
      {
        opposite: true,
        labels: {
          formatter() {
            const val = buildMetricValue(
              this.value,
              metricsDataByWeekId.metricType,
              currencySymbols[1],
              true,
              null,
              'en',
              locales[1]
            );
            return `${val.prefix || ''}${val.value}${val.suffix || ''}`;
          }
        }
      }
    ]
  };
  chartProps = _merge(chartProps, chartPropsOverride);
  chartSeries.push(chartSeries.shift());
  chartSeries[0].showInLegend = false;
  chartSeries[0].softThreshold = false;
  chartSeries[1].softThreshold = false;
  chartSeries[1].yAxis = 0;
  return { chartSeries, chartProps };
};
