import {
  useForecastComparisonPeriod,
  useForecastComparisonStartAndEndWeekIds,
  useForecastPeriod,
  useLatestForecastModel,
  useYtdStartAndEndWeekIdsForForecast
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/hooks';
import { useKeyMetricForecastSummaryData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useKeyMetricForecastSummaryData';
import { useMetricByWeekId, UseMetricByWeekIdArgs } from 'src/serverProxy/useMetricByWeekId';
import {
  ForecastComparisonPeriod,
  ForecastPeriod
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/types';
import { useMemo } from 'react';

/**
 * Common useMetricByWeekId builder args for forecast. Passes
 * in the forecast start/end week ids and the comparison start/end
 * week ids
 */
export function useMetricByWeekIdArgsForForecast(): Partial<UseMetricByWeekIdArgs> {
  const { endWeekId: comparisonEndWeek, startWeekId: comparisonStartWeek } = useForecastComparisonStartAndEndWeekIds();
  const { endWeekId, startWeekId } = useYtdStartAndEndWeekIdsForForecast();
  const { getSplineData } = useKeyMetricForecastSummaryData();
  const forecastPeriod = useForecastPeriod();

  return {
    requestBuilder: (builder, isComparison) => {
      if (isComparison) {
        return builder
          .clearConditionRangeFilters()
          .addConditionRangeFilter('weekId', comparisonStartWeek, comparisonEndWeek);
      }
      return builder.clearConditionRangeFilters().addConditionRangeFilter('weekId', startWeekId, endWeekId);
    },
    primaryAggregationBuilder: (builder) => {
      return builder.clearConditionRangeFilters().addConditionRangeFilter('weekId', startWeekId, endWeekId);
    },
    secondaryAggregationBuilder: (builder) => {
      return builder
        .clearConditionRangeFilters()
        .addConditionRangeFilter('weekId', comparisonStartWeek, comparisonEndWeek);
    },
    buildDataSetForMetricTotal: (defaultDataSet, indexName, fieldName, isComparison) => {
      if (!isComparison) {
        const splineData = getSplineData(indexName, fieldName);
        return forecastPeriod === ForecastPeriod.FULL_YEAR ? [...defaultDataSet, ...splineData] : splineData;
      }
      return defaultDataSet;
    },
    customFilterComparisonForTotal: () => true
  };
}

/**
 * Helps return the forecast data to display in the spline chart because
 * it can be connected to the YTD data or the prior period data
 */
export function useDisplayedForecastData({
  primaryData,
  secondaryData,
  forecastData
}: {
  primaryData: number[][];
  secondaryData: number[][];
  forecastData: number[][];
}) {
  const forecastPeriod = useForecastPeriod();
  const forecastComparison = useForecastComparisonPeriod();

  return useMemo(() => {
    if (forecastPeriod === ForecastPeriod.FULL_YEAR && primaryData.length > 0) {
      return [primaryData[primaryData.length - 1], ...forecastData];
    }
    if (forecastComparison === ForecastComparisonPeriod.PRIOR_PERIOD && secondaryData.length > 0) {
      return [secondaryData[secondaryData.length - 1], ...forecastData];
    }
    return forecastData;
  }, [forecastComparison, forecastData, forecastPeriod, primaryData, secondaryData]);
}

/**
 * Gets the spline metrics by week ID  including forecasted data
 */
export default function useForecastMetricByWeekId({
  useKeyMetricForecastSummaryDataArgs,
  forecastPeriodOverride,
  ...args
}: {
  forecastPeriodOverride?: ForecastPeriod;
  useKeyMetricForecastSummaryDataArgs?: Parameters<typeof useKeyMetricForecastSummaryData>[0];
} & UseMetricByWeekIdArgs) {
  const { loading: modelLoading } = useLatestForecastModel();
  const { getSplineData, isLoading } = useKeyMetricForecastSummaryData(useKeyMetricForecastSummaryDataArgs);
  const forecastPeriod = useForecastPeriod();

  const metricByWeekIdArgs = useMetricByWeekIdArgsForForecast();

  const metricsByWeekId = useMetricByWeekId({
    ...metricByWeekIdArgs,
    ...args,
    useGenericAdvancedSearchArgs: {
      shouldPerformFetch: !modelLoading
    }
  });

  const forecastData = useDisplayedForecastData({
    primaryData: metricsByWeekId.primaryData,
    secondaryData: metricsByWeekId.secondaryData,
    forecastData: getSplineData(args.indexName, args.fieldName)
  });

  return {
    ...metricsByWeekId,
    primaryData:
      (forecastPeriodOverride || forecastPeriod) === ForecastPeriod.FULL_YEAR ? metricsByWeekId.primaryData : [],
    isLoading: modelLoading || isLoading || metricsByWeekId.isLoading,
    forecastData
  };
}
