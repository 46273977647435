import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface LineChartProps {
  mainSeriesColor: string;
  compareSeriesColor: string;
  data: number[][];
  dataForCompare: number[][];
}

const LineChartForCardView: React.FC<LineChartProps> = ({
  mainSeriesColor,
  data,
  compareSeriesColor,
  dataForCompare
}: LineChartProps) => {
  const options = {
    chart: {
      height: 100,
      margin: [25, -1, 25, -1],
      type: 'spline'
    },
    credits: {
      enabled: false
    },
    lang: {
      noData: ''
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false
        }
      },
      series: {
        animation: false
      }
    },
    series: [
      {
        color: compareSeriesColor,
        data: dataForCompare,
        marker: {
          enabled: false
        },
        states: {
          hover: {
            enabled: false
          }
        }
      },
      {
        color: mainSeriesColor,
        data,
        marker: {
          enabled: false
        },
        states: {
          hover: {
            enabled: false
          }
        }
      }
    ],
    title: {
      text: ''
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default LineChartForCardView;
