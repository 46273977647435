import React, { Fragment, useMemo, useState } from 'react';
import { Box } from '@mui/system';
import { Text, useStacklineTheme } from '@stackline/ui';
import {
  IntegrationTab,
  IntegrationsNavigationTabs
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Integrations/SettingsIntegrationsPageLayout';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { FormControlLabel, RadioGroup } from '@mui/material';
import StyledRadio from 'src/components/BeaconRedesignComponents/common/StyledRadio';
import { useSettingsIntegrationsConfig } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Integrations/SettingsIntegrationsConfig';
import { useAppSelector } from 'src/utils/Hooks';
import _get from 'lodash/get';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';

interface AuthorizedData {
  id: string;
  name: string;
  label: string;
}

type AuthorizedRetailerInfoItem = [AuthorizedData, string[]];

const API_PAGE_WIDTH = 700;

/**
 *  Component that will be used in Settings - Integrations - API tab.
 */
const SettingsIntegrationsApi = ({ tab, setTab }: { tab: IntegrationTab; setTab: (tab: IntegrationTab) => void }) => {
  const theme = useStacklineTheme();
  const { retailersOption, customTitleName, defaultRetailer, description, targetUrl } = useSettingsIntegrationsConfig(
    IntegrationTab.API
  );
  const { config: userConfig } = useAppSelector((state) => state.user);
  const { Authorized: authorizedRetailersIds } = _get(userConfig, ['spAuthorize'], []);
  const [selectedRetailerInfo, setSelectedRetailerInfo] = useState(defaultRetailer);

  const authorizedRetailerInfo: AuthorizedRetailerInfoItem[] = useMemo(() => {
    return Object.entries(authorizedRetailersIds).reduce((result, [key, values]) => {
      const retailer = retailersOption.find((retailerInfo) => retailerInfo.id === String(key));
      if (retailer) {
        result.push([retailer, values]);
      }
      return result;
    }, []);
  }, [authorizedRetailersIds, retailersOption]);

  const handleRadioChange = (e) => {
    const retailerId = e.target.value;

    const radioSelectedRetailer = retailersOption.find((retailer) => retailer.id === retailerId);
    setSelectedRetailerInfo(radioSelectedRetailer);
  };

  /**
   * Show each slAccount's hashId if they have already integrated.
   */
  const AuthorizedSellerComponent = () => {
    return authorizedRetailerInfo.map((authorizedRetailer) => {
      const [authorizedRetailerData, authorizedHashIds] = authorizedRetailer;
      return (
        <Fragment key={`authorizedRetailer:${authorizedRetailerData.id}`}>
          {authorizedHashIds.length > 0 && (
            <>
              <Flex marginTop={theme.spacing.md}>
                <Text variant="subtitle2">{authorizedRetailerData.label}</Text>
              </Flex>
              <Flex flexDirection="column">
                {authorizedHashIds.map((hashId, index) => (
                  // Amazon US and Canada can have duplicated hashId
                  <Flex key={`${hashId}${String(index)}`} marginTop={theme.spacing.sm}>
                    <Text variant="body2">{hashId}</Text>
                  </Flex>
                ))}
              </Flex>
            </>
          )}
        </Fragment>
      );
    });
  };

  const isCurrentRetailerIntegrated = useMemo(() => {
    const currentHashIds = _get(authorizedRetailersIds, [`${Number(selectedRetailerInfo.id)}`], []);
    return currentHashIds.length > 0;
  }, [authorizedRetailersIds, selectedRetailerInfo.id]);

  const signUpLinkText = isCurrentRetailerIntegrated ? (
    <Text variant="body2">
      Add and authorize another seller account by{' '}
      <a
        href={`https://${targetUrl}/api/user/SpApiSignUp?retailerId=${selectedRetailerInfo.id}`}
        target="_blank"
        rel="noreferrer"
      >
        <Text inline underlined variant="subtitle2">
          Signing Up
        </Text>
      </a>
      .
    </Text>
  ) : (
    <Text variant="body2">
      <a
        href={`https://${targetUrl}/api/user/SpApiSignUp?retailerId=${selectedRetailerInfo.id}`}
        target="_blank"
        rel="noreferrer"
      >
        <Text inline underlined variant="subtitle2">
          Sign Up
        </Text>
      </a>{' '}
      to authorize your selling partner account.
    </Text>
  );

  return (
    <BeaconPageContainer sx={{ marginTop: '0px' }}>
      <Box maxWidth={`${API_PAGE_WIDTH}px`}>
        <Text variant="h2">Integrations</Text>
        <Box marginBottom={theme.spacing.lg}>
          <IntegrationsNavigationTabs tab={tab} setTab={setTab} />
        </Box>

        <Flex flexDirection="column">
          <Text variant="h4">{customTitleName}</Text>
        </Flex>

        <Flex marginTop={theme.spacing.sm}>{description}</Flex>

        <Flex marginTop={theme.spacing.md} marginBottom="5px">
          <RadioGroup
            value={selectedRetailerInfo.id}
            onChange={handleRadioChange}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '13px'
            }}
          >
            {retailersOption.map((retailer) => (
              <FormControlLabel
                key={`retailerOption:${retailer.id}`}
                sx={{ marginLeft: '0px' }}
                value={retailer.id}
                control={<StyledRadio sx={{ marginRight: '10px' }} />}
                label={<Text variant="body2">{retailer.label}</Text>}
              />
            ))}
          </RadioGroup>
        </Flex>

        <Flex marginTop="21px">{signUpLinkText}</Flex>

        <Flex marginTop={theme.spacing.lg}>
          <Text variant="h4">Integrated Seller Accounts</Text>
        </Flex>

        <Flex flexDirection="column">
          <AuthorizedSellerComponent />
        </Flex>
      </Box>
    </BeaconPageContainer>
  );
};

export default SettingsIntegrationsApi;
