import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';

import { getLayout as getEditPageLayout } from 'src/components/Layout/Advertising/AdScheduledActionsPageLayout/ScheduledActionPageLayout';
import { getLayout as getViewPageLayout } from 'src/components/Layout/Advertising/AdScheduledActionsPageLayout/ViewScheduledActionPageLayout';
import ReduxStore from 'src/types/store/reduxStore';
import { PageLayout } from 'src/types/application/widgetTypes';
import { EventBus } from 'src/types/utils';

const layoutGetterBySubtab: {
  [subtab: string]: (args: {
    app: ReduxStore['app'];
    entity: { type: string };
    eventBus: EventBus;
  }) => Partial<PageLayout>;
} = {
  view: getViewPageLayout as any,
  edit: getEditPageLayout as any,
  create: getEditPageLayout as any
};

export const getLayoutForEntity = ({
  ...args
}: {
  app: ReduxStore['app'];
  entity: { type: string };
  subtab: string;
  eventBus: EventBus;
}): PageLayout => {
  const basePageLayout: PageLayout = {
    containerStyle: { flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' },
    CustomPageContainer: GenericPageContainer,
    enableComparison: false,
    dataConfig: {},
    widgets: []
  };
  const layoutGetter = layoutGetterBySubtab[args.subtab] || layoutGetterBySubtab.view;
  const layout = layoutGetter ? layoutGetter(args) : {};
  return { ...basePageLayout, ...layout };
};
