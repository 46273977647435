import ReduxStore from 'src/types/store/reduxStore';
import { destructurePathName } from 'src/utils/urlParsing';
import { store } from 'src/main';
import { isArray } from 'lodash';
import isEmpty from 'lodash/isEmpty';

export interface OmniBaseRequestBody {
  retailerIds?: number[];
  instacartRetailerIds?: string[];
  includeBrandIds?: string[];
  excludeBrandIds?: string[];
  includeCategoryIds?: number[];
  excludeCategoryIds?: number[];
  includeLocationCountryCode?: string[];
  excludeLocationCountryCode?: string[];
  locationCountryCode?: string[];
  includeLocationRegionCode?: string[];
  excludeLocationRegionCode?: string[];
  locationRegionCode?: string[];
  includeSubCategoryIds?: number[];
  excludeSubCategoryIds?: number[];
  includeKeywords?: string[];
  excludeKeywords?: string[];
  productIds?: string[];
  includeLocationKeywords?: string[];
  excludeLocationKeywords?: string[];
  includeLocationCity?: string[];
  excludeLocationCity?: string[];
  includeLocationCounty?: string[];
  excludeLocationCounty?: string[];
  includeLocationCBSAName?: string[];
  excludeLocationCBSAName?: string[];
  includeLocationStateName?: string[];
  excludeLocationStateName?: string[];
  keywordCategoryIds?: string[];
  includePhrases?: string[];
  excludePhrases?: string[];
  includeStoreIds?: string[];
  excludeStoreIds?: string[];
  reviewKeywords?: string[];
  starsFilter?: string[];
  startWeekId: number;
  endWeekId: number;
  groupBy?: string;
  detailedScores?: boolean;
  docType?: string;
}

const LOCATION_PROPERTIES = [
  'excludeLocationCity',
  'excludeLocationCountryCode',
  'excludeLocationCounty',
  'excludeLocationKeywords',
  'excludeLocationStateName',
  'excludeLocationCBSAName',
  'includeLocationCity',
  'includeLocationCountryCode',
  'includeLocationCounty',
  'includeLocationKeywords',
  'includeLocationStateName',
  'includeLocationCBSAName'
];

const addSegmentFilterToTheBody = <T extends OmniBaseRequestBody>(
  segmentList: { query: { [key: string]: any[] }; name: string; queryId: string; [key: string]: any }[],
  baseBody: T,
  id: string
) => {
  const segmentObj = segmentList.find((aSegment) => aSegment.queryId === id);
  if (!segmentObj) {
    return baseBody;
  }
  const { query } = segmentObj;
  Object.keys(query).forEach((key) => {
    const filterValue = query[key].map((filterVal) => {
      if (typeof filterVal === 'object' && filterVal !== null) {
        if (key.includes('CategoryIds') || key === 'retailerIds' || key.includes('BrandIds')) {
          return Number(filterVal.value);
        }
        return filterVal.value;
      }
      return filterVal;
    });
    if (baseBody[key]) {
      baseBody[key] = [...new Set([...baseBody[key], ...filterValue])];
    } else {
      baseBody[key] = filterValue;
    }
  });
  return baseBody;
};

export const addFilterToOmniBaseReqBody = <T extends OmniBaseRequestBody>(
  baseBody: T,
  filters: ReduxStore['filters'],
  pathname: string,
  segmentQuery?: { [key: string]: any }
): T => {
  const allSegmentsData = store.getState().omniSegmentService.data;
  const keywordSegmentData = store.getState().omniKeywordSegmentService.data;
  const { queryParams } = store.getState().app;
  const { tab } = queryParams;
  const [entityType, entityId] = destructurePathName(pathname);
  const {
    region,
    country,
    brand,
    category,
    retailer,
    subcategory,
    segment,
    keywordSegment,
    keywordCategories,
    instacartRetailer
  } = filters;

  if (region) {
    baseBody.includeLocationRegionCode = [];
    baseBody.includeLocationRegionCode.push(...region.map(String));
  }
  if (country) {
    baseBody.includeLocationCountryCode = [];
    baseBody.includeLocationCountryCode.push(...country.map(String));
  }
  if (brand) {
    baseBody.includeBrandIds = [];
    baseBody.includeBrandIds.push(...brand.map(String));
  }
  if (category) {
    baseBody.includeCategoryIds = [];
    baseBody.includeCategoryIds.push(...category.map(Number));
  }
  if (retailer) {
    baseBody.retailerIds = [];
    baseBody.retailerIds.push(...retailer.map(Number));
  }
  if (subcategory) {
    baseBody.includeSubCategoryIds = [];
    baseBody.includeSubCategoryIds.push(...subcategory.map(Number));
  }
  if (keywordCategories) {
    baseBody.keywordCategoryIds = [];
    baseBody.keywordCategoryIds.push(...keywordCategories);
  }
  if (entityType === 'retailer' && entityId === '63' && tab !== 'content' && instacartRetailer) {
    baseBody.instacartRetailerIds = [];
    baseBody.instacartRetailerIds.push(...instacartRetailer);
  }

  if (entityType === 'brand') {
    baseBody.includeBrandIds = [];
    baseBody.includeBrandIds.push(entityId);
  } else if (entityType === 'category') {
    baseBody.includeCategoryIds = [];
    baseBody.includeCategoryIds.push(Number(entityId));
  } else if (entityType === 'subcategory') {
    baseBody.includeSubCategoryIds = [];
    baseBody.includeSubCategoryIds.push(Number(entityId));
  } else if (entityType === 'retailer') {
    baseBody.retailerIds = [];
    baseBody.retailerIds.push(Number(entityId));
  } else if (entityType === 'product') {
    baseBody.productIds = [];
    baseBody.productIds.push(entityId);
  } else if (entityType === 'country') {
    baseBody.includeLocationCountryCode = [];
    baseBody.includeLocationCountryCode.push(entityId);
  } else if (entityType === 'region') {
    baseBody.includeLocationRegionCode = [];
    baseBody.includeLocationRegionCode.push(entityId);
  } else if (entityType === 'state') {
    baseBody.includeLocationStateName = [];
    baseBody.includeLocationStateName.push(entityId);
  } else if (entityType === 'city') {
    baseBody.includeLocationCity = [];
    baseBody.includeLocationCity.push(entityId);
  } else if (entityType === 'county') {
    baseBody.includeLocationCounty = [];
    baseBody.includeLocationCounty.push(entityId);
  } else if (entityType === 'cbsa') {
    baseBody.includeLocationCBSAName = [];
    baseBody.includeLocationCBSAName.push(entityId);
  } else if (['segment', 'keywordSegment', 'store'].includes(entityType)) {
    if (segmentQuery) {
      // For Segment entity, content and reviews tab ((As of Jun 26) We have location Metadata in keyMetric page for OmniCommonSummaryTrend) will error out if we pass location properties since we don't have the location metadata
      // example (content, reviews are left in Walmart.com, but location does not matter since it is product level.)
      if (entityType === 'segment' && (tab === 'content' || tab === 'reviews' || tab === 'scorecard')) {
        LOCATION_PROPERTIES.forEach((prop) => delete segmentQuery[prop]);
      }
      Object.keys(segmentQuery).forEach((key) => {
        segmentQuery[key] = segmentQuery[key].map((filterVal) => {
          if (typeof filterVal === 'object' && filterVal !== null) {
            if (key.includes('CategoryIds') || key === 'retailerIds' || key.includes('BrandIds')) {
              return Number(filterVal.value);
            }
            return filterVal.value;
          }
          return filterVal;
        });
      });
      return { ...baseBody, ...segmentQuery };
    }
  }
  if (segment && isArray(segment) && !isEmpty(segment)) {
    const processedBody = addSegmentFilterToTheBody(allSegmentsData, baseBody, segment[0]);
    return processedBody;
  }
  if (keywordSegment && isArray(keywordSegment) && !isEmpty(keywordSegment)) {
    const processedBody = addSegmentFilterToTheBody(keywordSegmentData, baseBody, keywordSegment[0]);
    return processedBody;
  }

  return baseBody;
};
