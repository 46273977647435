import { UseMutationOptions, useMutation } from 'react-query';
import { UploadBulkAdjustmentPayload, UploadBulkAdjustmentResponse } from '../serverProxy/types';
import useServerProxy from './useServerProxy';

/**
 * Uploads a bulk adjustment file to the server
 */
export default function useUploadBulkAdjustment(
  options: UseMutationOptions<UploadBulkAdjustmentResponse, unknown, UploadBulkAdjustmentPayload> = {}
) {
  const { uploadBulkAdjustment } = useServerProxy();
  return useMutation(uploadBulkAdjustment, options);
}
