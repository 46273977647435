import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import queryString from 'qs';
import List from '@mui/material/List';
import _pick from 'lodash/pick';

import { getLeftNavConfig } from './LeftNavConfig';
import LeftNavSubTree from './LeftNavSubTree';

class LeftNavTree extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    retailer: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    entity: PropTypes.object.isRequired
  };

  static defaultProps = {};

  state = {
    navConfig: null
  };

  componentDidMount() {
    this.buildNavConfig();
  }

  componentWillReceiveProps(nextProps) {
    const { location, entity } = nextProps;
    if (
      location.pathname !== this.props.location.pathname ||
      location.search !== this.props.location.search ||
      entity.type !== this.props.entity.type
    ) {
      this.buildNavConfig(nextProps);
    }
  }

  buildNavConfig(nextProps) {
    const props = nextProps || this.props;
    const { location, app } = props;

    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    if (!queryParams.tab) {
      queryParams.tab = app.defaultQueryParams.tab;
    }
    if (!queryParams.subtab) {
      queryParams.subtab = app.defaultQueryParams.subtab;
    }
    const navConfig = getLeftNavConfig({ ...props, queryParams });
    this.setState({ navConfig });
  }

  render() {
    const { entity, app, retailer, user } = this.props;
    const { navConfig } = this.state;
    if (!entity || !navConfig || !navConfig.menuItems || !retailer || !user || !app || !app.queryParams) {
      return null;
    }

    return (
      <div className="nav-container" style={{ padding: '0px 15px 20px 15px' }}>
        <List className="nav-list-item-container">
          {navConfig.menuItems.map((menuItem, index) => (
            <LeftNavSubTree
              key={`LeftNavSubTree_${menuItem.name}_${index}`}
              entity={entity}
              menuItem={menuItem}
              subTreeLevel={0}
            />
          ))}
        </List>
      </div>
    );
  }
}

const mapStateToProps = (state) => _pick(state, ['app', 'retailer', 'user']);

export default withRouter(connect(mapStateToProps, null)(LeftNavTree));
