import { undoComputeLastWeek } from "./util";
import _get from 'lodash/get';
import { error, warn } from 'src/utils/mixpanel';
import { store } from 'src/main';


export const computeMainLegendValueAdjustment = ({
    mainStartWeek,
    mainMetricName,
    mainEntityMetrics,
    chartDisplayTimePeriod,
    marketShareDenominatorESSDataKey,
    chartComparisonDisplayTimePeriod
}) => {
    // need to decrement mainstartweek here if we loaded the last one above
    const loadLastWeek = chartComparisonDisplayTimePeriod.id === 'prior-period' && chartDisplayTimePeriod.id !== '1w'; // Original logic, unsure if we should remove or not
    let actualStartWeek = loadLastWeek ? undoComputeLastWeek(mainStartWeek) : mainStartWeek;

    /*
        getChartDisplayTimePeriodInner() will always set the start week in chartDisplayTimePeriod
        to be the first week in the year if the filter is set to 1-week.
        chartDisplayTimePeriod also includes a timePeriod property containing the actual start week we want.
        To avoid calculating YTD values for 1-week views, we deliberately revert the week back.
    */
    actualStartWeek = chartDisplayTimePeriod.id === '1w' ? chartDisplayTimePeriod.timePeriod.startWeek : actualStartWeek;

    const filterAndSumMetrics = (metrics) =>
        metrics
            .filter((dataPoint) => dataPoint.weekId >= actualStartWeek && dataPoint.weekId <= chartDisplayTimePeriod.endWeek)
            .reduce((acc, dataPoint) => acc + dataPoint.value, 0);
    if (!marketShareDenominatorESSDataKey) {
        error(
            "Expected `marketShareDenominatorESSDataKey` to be set for Market Share or Buy Box, but it wasn't"
        );
        return 0;
    }

    let nonMarketShareFieldName = '';
    if (mainMetricName.includes('winPercentage')) {
        nonMarketShareFieldName = 'winsByClient_by_weekId';
    } else {
        nonMarketShareFieldName = mainMetricName.replace('MarketShare', '');
    }

    /* Retrieves arrays of weekly data points to be filtered  */

    const numeratorMetrics = _get(mainEntityMetrics, [nonMarketShareFieldName, 'data']);
    const numeratorSum = filterAndSumMetrics(numeratorMetrics);

    /* Note: denominatorData is only used if we're adjusting the Market Share legend value. As far as I can tell, 
       this is the difference between looking at:
        entitySearchService.marketShareBySales_sales_weekId
        (versus)
        entitySearchService.marketShareBySales_sales_weekId_marketshare
    */
    const denominatorData = mainMetricName.includes('MarketShare')
        ? store.getState().entitySearchService[marketShareDenominatorESSDataKey]
        : null

    // Returns an array of objects representing weekly data points for either Market Share or Buy Box Win %
    const denominatorMetrics = mainMetricName.includes('winPercentage')
        ? _get(mainEntityMetrics, ['buyBoxWeighted_by_weekId', 'data'])
        : _get(denominatorData, [nonMarketShareFieldName, 'data']);

    if (!denominatorMetrics) {
        warn(
            `Denominator data expected to be in \`entitySearchService.${marketShareDenominatorESSDataKey}\` was empty; falling back to lossy market share computation...`
        );
        return null;
    }

    if (!denominatorMetrics || !numeratorMetrics) {
        return null;
    }

    const denominatorSum = filterAndSumMetrics(denominatorMetrics);
    if (denominatorSum === 0) {
        return numeratorSum > 0 ? 1 : 0;
    }

    return numeratorSum / denominatorSum;
};
