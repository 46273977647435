import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import CommonSummaryInfo from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import DisputeLifeCycle from 'src/components/ShortageDisputes/components/DisputeDetail/DisputeLifecycle';
import DisputeDetailSummaryBlock from 'src/components/ShortageDisputes/components/DisputeDetail/SummaryBlocks';
import InitialDisputeDetailsBlock from 'src/components/ShortageDisputes/components/DisputeDetail/InitialDetails';
import ContactUsCaseDetails from 'src/components/ShortageDisputes/components/DisputeDetail/ContactUsCaseDetails';
import SupportingDocuments from 'src/components/ShortageDisputes/components/DisputeDetail/SupportingDocuments';
import { useFetchDisputeDetails } from 'src/components/ShortageDisputes/hooks';
import Loading from 'src/components/common/Loading';

const HeaderForDetailSummary = { view: { title: 'Dispute Detail Summary', disableLegend: true } };
const HeaderForDisputeLifecycle = { view: { title: 'Dispute Life Cycle', disableLegend: true } };
const HeaderForInitialDetails = { view: { title: 'Initial Dispute Details', disableLegend: true } };
const HeaderForContactUs = { view: { title: 'Contact Us Case Details', disableLegend: true } };
const HeaderForSupportingDocuments = { view: { title: 'Supporting Documents', disableLegend: true } };

const DisputeDetailsPage = ({ location, queryClient }: { location: any; queryClient: any }) => {
  const searchParameters = new URLSearchParams(location.search);
  const invoiceId = searchParameters.get('invoiceId');
  const { data, isLoading } = useFetchDisputeDetails({ invoiceId });
  const ref = useRef(null);

  const autoScroll = () => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  };

  useEffect(() => {
    autoScroll();
  }, [location, isLoading]);

  return (
    <div ref={ref} style={{}}>
      <CommonSummaryInfo widget={HeaderForDetailSummary} disableLegend />
      {isLoading ? (
        <Loading
          style={{
            position: 'absolute',
            left: '55%',
            top: '30%',
            display: 'flex',
            height: '50px',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          className="spinner"
          size={200}
          thickness={2}
        />
      ) : (
        <>
          <DisputeDetailSummaryBlock rawData={data.section.disputeDetailsBlockData} />

          <CommonSummaryInfo widget={HeaderForDisputeLifecycle} disableLegend />
          <DisputeLifeCycle rawData={data.section.lifeCycleData} />

          <CommonSummaryInfo widget={HeaderForInitialDetails} />
          <InitialDisputeDetailsBlock rawData={data.section.initialDetailsData} />

          <CommonSummaryInfo widget={HeaderForContactUs} />
          <ContactUsCaseDetails invoiceId={invoiceId} queryClient={queryClient} />

          <CommonSummaryInfo widget={HeaderForSupportingDocuments} />
          <SupportingDocuments queryClient={queryClient} invoiceId={invoiceId} />
        </>
      )}
    </div>
  );
};

export default withRouter(DisputeDetailsPage);
