import React from 'react';
import BeaconMetricSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconMetricSplineChart';
import useKeywordSearchRequestBuilder from './useKeywordSearchRequestBuilder';

/**
 * Organic traffic spline chart for the keywords search page
 */
export default function KeywordSearchOrganicTrafficSpline() {
  const keywordSearchRequestBuilder = useKeywordSearchRequestBuilder('traffic');

  return (
    <BeaconMetricSplineChart
      indexName="traffic"
      fieldName="organicClicks"
      requestBuilder={keywordSearchRequestBuilder()}
    />
  );
}
