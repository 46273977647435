import types from './types';

const startFetch = () => ({
  type: types.START_FETCH,
  retailers: []
});

const receiveOmniRetailers = (retailers: any) => ({
  type: types.RECEIVE_ALL_OMNI_RETAILERS,
  retailers
});

const receiveOmniInstacartRetailers = (instacartRetailers: any) => ({
  type: types.RECEIVE_ALL_OMNI_INSTACART_RETAILERS,
  instacartRetailers
});

export default { receiveOmniRetailers, startFetch, receiveOmniInstacartRetailers };
