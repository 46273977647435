import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import makeStyles from '@mui/styles/makeStyles';
import { HeaderSearchIcon } from 'src/components/SvgIcons';
import Chip from '@mui/material/Chip';
import colors from 'src/utils/colors';
import Axios from 'axios';
import Tabs from 'src/components/common/Tabs/Tabs';

import { EntityColumn } from 'src/components/Grids/Data/ColumnTypes';
import Waypoint from 'react-waypoint';
import { connect } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import * as adCampaignBuilderActions from 'src/store/modules/adManager/adCampaignBuilder/actions';
import Dialog from '@mui/material/Dialog';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import { AdCampaignBuilderCheckbox } from 'src/components/AdCampaignBuilder/Widgets/Checkbox';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import CustomAgMaterial from 'src/components/Grids/Data/CustomAgMaterial';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import 'src/components/AdCampaignBuilder/Steps/SetupStrategy.scss';

const styles = {
  tabStyle: {
    width: 235,
    margin: '0 0 0 0',
    padding: '13px 0'
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pasteArea: {
    margin: 18,
    padding: 20,
    width: 380,
    height: 380,
    background: 'none',
    fontFamily: '"Input", "Oxygen Mono", monospace',
    resize: 'none',
    borderRadius: '10px',
    border: `2px dashed ${colors.comparison}`
  },
  row: {
    display: 'flex',
    padding: 19,
    alignItems: 'center',
    fontSize: 14,
    borderTop: `1px solid ${colors.lightestGrey}`
  },
  imgContainer: {
    height: 40,
    width: 40,
    minHeight: 40,
    minWidth: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  images: { maxWidth: 35, maxHeight: 35 },
  listContainer: {
    overflowY: 'scroll'
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: colors.darkBlue,
    background: colors.lightestGrey,
    padding: theme.spacing(0.5),
    fontSize: 14
  },
  badge: {
    right: -3,
    top: 5,
    border: '2px solid #fff',
    padding: '0 4px',
    color: '#fff'
  }
}));

const onGridReady = (params: any) => {
  params.api.sizeColumnsToFit();
};

const parseUploadedItems = (pasteContent: any) => {
  const rawContent = pasteContent;
  return rawContent
    .match(/[^\r\n]+/g) // handle windows line endings as well as normal line endings
    .map((item) => item.trim())
    .filter((item) => !_isEmpty(item));
};

export const CheckboxColumnRenderer = connect(
  ({ adCampaignBuilder }: ReduxStore, { data }: { data: any }) => {
    const { entity } = data;
    return {
      checked: adCampaignBuilder.featured.selectedFeaturedUPCs.findIndex((upc) => upc === entity.upc) >= 0
    };
  },
  (dispatch, { data }) => {
    const entity = { ...data.entity };
    const { upc } = entity;

    return {
      toggleChecked: () => dispatch(adCampaignBuilderActions.toggleOrAddUpcSelected(upc))
    };
  }
)(({ checked, toggleChecked }) => <AdCampaignBuilderCheckbox checked={checked} onChange={toggleChecked} />);

export const formatWalmartResult = (res: any) => {
  const cloneRes = _cloneDeep(res);
  if (cloneRes.data) {
    if (cloneRes.data.length === 0) {
      cloneRes.hasMoreRows = false;
    } else {
      if (cloneRes.data.length > 100) {
        cloneRes.data = cloneRes.data.slice(0, 100);
      }
      const newData = cloneRes.data.map((item: any) => {
        const { itemId, itemImageUrl, itemName, itemPageUrl } = item;
        // const { type } = item;
        return {
          id: itemId,
          entity: {
            name: itemName,
            imageUrl: itemImageUrl,
            retailerSku: itemId,
            productUrl: itemPageUrl,
            upc: itemId
          }
        };
      });
      cloneRes.data = newData;
      cloneRes.hasMoreRows = true;
    }
  }

  return cloneRes;
};

const formatInstacartResult = (res: any) => {
  const cloneRes = _cloneDeep(res);
  if (cloneRes.data) {
    if (cloneRes.data.length === 0) {
      cloneRes.hasMoreRows = false;
    } else {
      const newData = cloneRes.data.map((item: any) => {
        const {
          brand_name,
          product_id,
          product_image_url,
          product_name,
          product_size,
          product_unit_count,
          product_url,
          upc
        } = item.attributes;
        // const { type } = item;
        return {
          id: product_id,
          entity: {
            name: product_name,
            imageUrl: product_image_url,
            retailerSku: product_id,
            adBrandName: brand_name,
            productUrl: product_url,
            upc
          },
          adBrandName: brand_name,
          size: product_size,
          unit: product_unit_count
        };
      });
      cloneRes.data = newData;
      cloneRes.hasMoreRows = true;
    }
  }

  return cloneRes;
};

const SelectedProductDialog = connect(
  ({ adCampaignBuilder, retailer }: ReduxStore) => {
    return {
      retailer,
      upcs: adCampaignBuilder.featured.selectedFeaturedUPCs,
      entity: _get(adCampaignBuilder, ['platformSettings', 'entity'])
    };
  },
  (dispatch) => {
    return {
      toggleChecked: (upc) => dispatch(adCampaignBuilderActions.toggleOrAddUpcSelected(upc))
    };
  }
)(
  ({
    retailer,
    open,
    upcs,
    entity,
    setDialogOpen,
    toggleChecked
  }: {
    retailer: any;
    open: boolean;
    upcs: string[];
    entity: any;
    setDialogOpen: Function;
    toggleChecked: Function;
  }) => {
    const [productList, setProductList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTabIx, handleTabChange] = useState(0);
    const [pasteContent, setPasteContent] = useState('');
    const isWalmart = retailer.id === '2' || retailer.id === '25';
    const fetchProductForUpcs = async () => {
      setIsLoading(true);
      const UPC_API = '/apiAdManager/adCampaigns/getRetailerProductMetadata';
      const requestBody_Upc = {
        entityId: entity.id,
        platformType: entity.platformType,
        upcs
      };

      const upcResult = await Axios.post(UPC_API, requestBody_Upc);

      setProductList(upcResult.data);
      setIsLoading(false);
    };
    useEffect(() => {
      if (upcs.length === 0) {
        setProductList([]);
      } else if (open) {
        fetchProductForUpcs();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upcs, open]);

    const handleSubmitUpcClick = () => {
      const formatedUpcs = parseUploadedItems(pasteContent);
      toggleChecked(formatedUpcs);
      handleTabChange(0);
    };

    const renderProductList = () => {
      if (isLoading) {
        return <GridLoading />;
      }

      return (
        <div style={styles.listContainer}>
          {productList.map((product: any, idx: number) => {
            const productName =
              retailer.id === '2' || retailer.id === '25' ? product.itemName : product.attributes.product_name;
            const imageUrl =
              retailer.id === '2' || retailer.id === '25' ? product.itemImageUrl : product.attributes.product_image_url;
            const productUpc = retailer.id === '2' || retailer.id === '25' ? product.itemId : product.attributes.upc;
            return (
              <div key={idx} style={styles.row}>
                <div>
                  <AdCampaignBuilderCheckbox
                    checked={upcs.includes(productUpc)}
                    onChange={() => toggleChecked(productUpc)}
                  />
                </div>
                <div style={styles.imgContainer}>
                  <img alt="" src={imageUrl} style={styles.images} />
                </div>
                <div>
                  <div>{productName}</div>
                  <div>UPC: {productUpc}</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    };

    const renderUpcUpload = () => {
      return (
        <div style={{ ...styles.centered, height: '100%', flexDirection: 'column' }}>
          <textarea
            className="no-outline"
            value={pasteContent}
            placeholder={`Enter ${isWalmart ? 'upload item id' : 'UPC'}s separated by a new line`}
            onChange={(e) => {
              setPasteContent(e.target.value);
            }}
            style={styles.pasteArea}
          />
          <LargeMuiButton onClick={handleSubmitUpcClick} disabled={_isEmpty(pasteContent)}>
            Submit
          </LargeMuiButton>
        </div>
      );
    };

    const tabs = [{ displayName: 'Selected Product' }, { displayName: `Upload ${isWalmart ? 'Item Ids' : 'UPC'}` }];
    return (
      <Dialog open={open} onClose={() => setDialogOpen(false)}>
        <div>
          <Tabs
            tabStyle={styles.tabStyle}
            tabs={tabs}
            value={activeTabIx}
            onTabChange={(_evt, newTabIx) => handleTabChange(newTabIx)}
          />
        </div>
        <div className="dialog_content" style={{ height: 500, width: 470 }}>
          {activeTabIx === 0 ? renderProductList() : renderUpcUpload()}
        </div>
      </Dialog>
    );
  }
);

const InstacartProductGrid = ({
  adCampaignBuilder,
  retailer,
  mainEntity
}: {
  adCampaignBuilder: any;
  retailer: any;
  mainEntity: any;
}) => {
  const [searchKeyword, setSearchKeyword] = useState(
    retailer.id === '2' ? mainEntity.companyName : retailer.id === '25' ? ` ` : ''
  );
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataSet, setDataSet] = useState({ data: [] });
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();
  const clearKeyword = () => {
    setSearchKeyword('');
    setPageNumber(1);
  };

  const entity = _get(adCampaignBuilder, ['platformSettings', 'entity']);

  const fetchData = async (newKeyword: string, newPageNumber: number) => {
    setLoading(true);
    let END_POINT = '';
    let requestBody = {};
    if (retailer.id === '2' || retailer.id === '25') {
      if (newKeyword === '') {
        setDataSet({ data: [] });
        setLoading(false);
        return;
      } else {
        END_POINT = '/apiAdManager/adCampaigns/getRetailerProductMetadata';
        requestBody = {
          entityId: entity.id,
          platformType: entity.platformType,
          searchText: newKeyword
        };
      }
    } else if (newKeyword === '') {
      END_POINT = '/apiAdManager/adCampaigns/getRetailerProductCatalog';
      requestBody = {
        entityId: entity.id,
        platformType: entity.platformType,
        pagination: true,
        pageNumber: newPageNumber
      };
      // fetch all;
    } else {
      END_POINT = '/apiAdManager/adCampaigns/retailerProductSearch';
      requestBody = {
        entityId: entity.id,
        platformType: entity.platformType,
        query: newKeyword,
        pagination: true,
        pageNumber: newPageNumber
      };
      // fetch part
    }

    const res = await Axios.post(END_POINT, requestBody);

    const formattedRes =
      retailer.id === '2' || retailer.id === '25' ? formatWalmartResult(res) : formatInstacartResult(res);

    if (newPageNumber === 1) {
      setDataSet(formattedRes);
    } else {
      const newDataSet = [...dataSet.data, ...formattedRes.data];
      formattedRes.data = newDataSet;
      setDataSet(formattedRes);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (searchKeyword || searchKeyword === '') {
      fetchData(searchKeyword, pageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword, pageNumber]);

  const handleKeyPress = (evt: any) => {
    if (evt.key === 'Enter') {
      setPageNumber(1);
      setSearchKeyword(evt.target.value);
    }
  };

  const handleWaypointEnter = () => {
    setPageNumber(pageNumber + 1);
  };

  const { selectedFeaturedUPCs } = adCampaignBuilder.featured;

  const columnDefs = [
    {
      displayName: ' ',
      cellRendererFramework: CheckboxColumnRenderer,
      minWidth: 50,
      maxWidth: 50,
      pinned: 'left',
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' }
    },
    {
      headerName: 'Product',
      field: 'stacklineSku',
      width: undefined,
      enableRtl: true,
      cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
      headerClass: 'align-left',
      minWidth: 600,
      maxWidth: 1000,
      cellRendererFramework: EntityColumn
    }
  ];
  if (retailer.id !== '2' && retailer.id !== '25') {
    columnDefs.push(
      {
        headerName: 'Brand',
        field: 'adBrandName',
        headerClass: 'align-left',
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' }
      },
      {
        headerName: 'Size',
        field: 'size'
      }
    );
  }
  return (
    <div>
      <div className="portfolio-control-button-group">
        <div className="searchbar_container" style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <button className="sl-header__search-button">
            <HeaderSearchIcon className="sl-header__search-icon" />
          </button>
          {/* for sams club passing ` ` to fetch all products */}
          {searchKeyword && searchKeyword !== ` ` ? (
            <Chip label={searchKeyword} onDelete={clearKeyword} className={classes.root} />
          ) : (
            <input onKeyPress={(evt) => handleKeyPress(evt)} placeholder="Search" />
          )}
        </div>
        <Tooltip title="Selected Products" placement="top">
          <Badge badgeContent={selectedFeaturedUPCs.length} color="primary" classes={{ badge: classes.badge }}>
            <ShoppingCartRoundedIcon onClick={() => setDialogOpen(true)} style={{ cursor: 'pointer' }} />
          </Badge>
        </Tooltip>
        <SelectedProductDialog open={dialogOpen} setDialogOpen={setDialogOpen} />
      </div>
      {loading && pageNumber === 1 ? (
        <GridLoading />
      ) : (
        <div>
          {/* <CustomAgMaterial
              onGridReady={onGridReady}
              onCellValueChanged={onGridReady}
              onModelUpdated={onGridReady}
              onRowValueChanged={onGridReady}
              onRowDataChanged={onGridReady}
              buildRows={() => dataSet.data}
              columnDefs={columnDefs as any}
              deltaRowDataMode
              getRowNodeId={(datum: any) => escape(datum.id)}
              domLayout="normal"
              suppressNoRowsOverlay
              containerStyle={hasMatches ? { height: 600, width: '100%' } : { width: '100%' }}
            /> */}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flexGrow: '1' }}>
              <CustomAgMaterial
                onGridReady={onGridReady}
                onCellValueChanged={onGridReady}
                onModelUpdated={onGridReady}
                onRowValueChanged={onGridReady}
                onRowDataChanged={onGridReady}
                buildRows={() => dataSet.data}
                columnDefs={columnDefs}
                suppressNoRowsOverlay
                domLayout="autoHeight"
              >
                {loading && <GridLoading />}
                {retailer.id !== '2' && retailer.id !== '25' && _get(dataSet, ['hasMoreRows']) && !loading ? (
                  <Waypoint onEnter={handleWaypointEnter} />
                ) : null}
                <br />
                <br />
              </CustomAgMaterial>
              {!loading && dataSet.data.length === 0 && (
                <div className="ag_grid_no_result">
                  <div className="no_results_header">No products found</div>
                  <div className="no_results_subheader">Please update your search or try adding by UPC</div>
                </div>
              )}
            </div>
          </div>
          {/* {!hasMatches && hasRows && <NoPortfolioResultsAction />}
            {!hasMatches && !hasRows && <NoPortfolioAction />} */}
        </div>
      )}
    </div>
  );
};

export default InstacartProductGrid;
