import Snackbar from '@mui/material/Snackbar';
import React, { Dispatch, SetStateAction } from 'react';
import PropTypes from 'prop-types';

export interface AppSnackbarProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  message: string;
}

const AppSnackbar: React.FC<AppSnackbarProps> = (props) => {
  const { open, setOpen, message } = props;
  const handleClose = () => {
    setOpen(false);
  };

  return <Snackbar open={open} onClose={handleClose} message={message} />;
};

AppSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};

export default AppSnackbar;
