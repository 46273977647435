import React, { useState, useEffect } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import Select from 'src/components/common/Form/Select';
import { colors } from '@mui/material';
import { ColoredCheckbox } from 'src/components/Export/ExportDialog';

export interface OmniSelectedField {
  firstGroupByValue?: string;
  secondGroupByValue?: string;
  thirdGroupByValue?: string;
  aggByWeek?: boolean;
  selectedWeekID?: number;
  includeComparison?: boolean;
}

interface OmniExportGroupByProps {
  classes: { [key: string]: any };
  setOmniSelectedValue: (omniSelectedField: OmniSelectedField) => void;
}

const OmniExportGroupBy: React.FC<OmniExportGroupByProps> = ({ setOmniSelectedValue, classes }) => {
  const omniEntity = useSelector((state: ReduxStore) => state.omniEntity);
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const { startWeek, endWeek } = mainTimePeriod;
  const { weekIds } = omniEntity;
  const clonedWeekIDs = _cloneDeep(weekIds);
  clonedWeekIDs.sort((a, b) => {
    if (a === b) {
      return 0;
    }
    return a < b ? 1 : -1;
  });

  let weekIdToUse = clonedWeekIDs.filter((id: number) => startWeek <= id && id <= endWeek);
  if (weekIdToUse.length > 13) {
    weekIdToUse = weekIdToUse.slice(0, 13);
  }
  const weekIdsSelectOpt = weekIdToUse.map((id: number) => ({
    displayName: `${id}`,
    id: `${id}`,
    value: id
  }));
  const firstOpt = [
    {
      displayName: 'Retailer',
      id: 'retailerId',
      value: 'retailerId'
    },
    {
      displayName: 'Product',
      id: 'stacklineSku.keyword',
      value: 'stacklineSku.keyword'
    }
  ];

  const secondOpt = [
    {
      displayName: 'Product',
      id: 'stacklineSku.keyword',
      value: 'stacklineSku.keyword'
    },
    {
      displayName: 'Brand',
      id: 'brandId',
      value: 'brandId'
    },
    {
      displayName: 'Category',
      id: 'categoryId',
      value: 'categoryId'
    },
    {
      displayName: 'State',
      id: 'locationState.keyword',
      value: 'locationState.keyword'
    },
    {
      displayName: 'Store',
      id: 'locationId.keyword',
      value: 'locationId.keyword'
    }
  ];

  const secondOptWhenFirstIsProduct = [
    {
      displayName: 'State',
      id: 'locationState.keyword',
      value: 'locationState.keyword'
    },
    {
      displayName: 'Store',
      id: 'locationId.keyword',
      value: 'locationId.keyword'
    }
  ];

  const thirdOpt = [
    {
      displayName: 'Delivery',
      id: 'delivery',
      value: 'delivery'
    },
    {
      displayName: 'Pickup',
      id: 'pickUp',
      value: 'pickUp'
    },
    {
      displayName: 'Ship',
      id: 'shipping',
      value: 'shipping'
    }
  ];

  const [firstGroupByValue, setFirstGroupByValue] = useState(firstOpt[0].value);
  const [disableSecond, setDisableSecond] = useState(false);
  const [shouldUseSecondOptWhenFirstIsProduct, setShouldUseSecondOptWhenFirstIsProduct] = useState(false);
  const [secondGroupByValue, setSecondGroupByValue] = useState(secondOpt[0].value);
  const [thirdGroupByValue, setThirdGroupByValue] = useState(thirdOpt[0].value);
  const [disableThird, setDisableThird] = useState(true);
  const [aggByWeek, setAggByWeeks] = useState(true);
  const [shouldDisableAggWeek, setShouldDisableAggWeek] = useState(false);
  const [weekIdSelected, setWeekIdSelected] = useState(weekIdsSelectOpt[0].value);
  const [shouldDisableWeekIDSelect, setShouldDisableWeekIDSelect] = useState(true);

  useEffect(() => {
    setOmniSelectedValue({
      firstGroupByValue,
      secondGroupByValue,
      thirdGroupByValue,
      aggByWeek,
      selectedWeekID: weekIdSelected
    });
  }, [firstGroupByValue, secondGroupByValue, thirdGroupByValue, aggByWeek, weekIdSelected]);

  const handleGroupByChange = (
    firstValue: string,
    secondValue: string,
    thirdValue: string,
    checkAggWeek: boolean,
    weekIdSelectedToUSe: number
  ) => {
    if (firstValue === 'stacklineSku.keyword' && firstGroupByValue !== 'stacklineSku.keyword') {
      setShouldUseSecondOptWhenFirstIsProduct(true);
      setSecondGroupByValue(secondOptWhenFirstIsProduct[0].value);
      setDisableThird(false);
    } else if (firstValue === 'stacklineSku.keyword') {
      if (secondValue === 'locationId.keyword') {
        setSecondGroupByValue(secondValue);
        setThirdGroupByValue('delivery');
        setDisableThird(true);
        setShouldDisableAggWeek(true);
        setAggByWeeks(false);
        setShouldDisableWeekIDSelect(false);
        setWeekIdSelected(weekIdSelectedToUSe);
      } else {
        setDisableSecond(false);
        setDisableThird(false);
        setShouldDisableAggWeek(false);
        setShouldDisableWeekIDSelect(true);
        setSecondGroupByValue(secondValue);
        setThirdGroupByValue(thirdValue);
        setAggByWeeks(checkAggWeek);
        setWeekIdSelected(weekIdsSelectOpt[0].value);
      }
    } else if (firstValue === 'retailerId') {
      if (secondValue === 'stacklineSku.keyword' || secondValue === 'brandId' || secondValue === 'categoryId') {
        setShouldUseSecondOptWhenFirstIsProduct(false);
        setDisableSecond(false);
        setDisableThird(true);
        setShouldDisableAggWeek(false);
        setShouldDisableWeekIDSelect(true);
        setSecondGroupByValue(secondValue);
        setThirdGroupByValue(thirdValue);
        setAggByWeeks(checkAggWeek);
        setWeekIdSelected(weekIdsSelectOpt[0].value);
      } else {
        setShouldUseSecondOptWhenFirstIsProduct(false);
        setDisableSecond(false);
        setDisableThird(false);
        setShouldDisableAggWeek(false);
        setShouldDisableWeekIDSelect(true);
        setSecondGroupByValue(secondValue);
        setThirdGroupByValue(thirdValue);
        setAggByWeeks(checkAggWeek);
        setWeekIdSelected(weekIdsSelectOpt[0].value);
      }
    }

    setFirstGroupByValue(firstValue);
  };

  return (
    <>
      <div className="setting_row">
        <div className="label" style={{ width: 100 }}>
          Group by 1:
        </div>
        <div>
          <Select
            items={firstOpt}
            disabled={false}
            value={firstGroupByValue}
            onChange={(evt: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
              handleGroupByChange(
                evt.target.value as string,
                secondGroupByValue,
                thirdGroupByValue,
                aggByWeek,
                weekIdSelected
              )
            }
            style={{ width: 300 }}
            classes={classes}
          />
        </div>
      </div>
      <div className="setting_row">
        <div className="label" style={{ width: 100 }}>
          Group by 2:
        </div>
        <div>
          <Select
            items={shouldUseSecondOptWhenFirstIsProduct ? secondOptWhenFirstIsProduct : secondOpt}
            disabled={disableSecond}
            value={secondGroupByValue}
            onChange={(evt: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
              handleGroupByChange(
                firstGroupByValue,
                evt.target.value as string,
                thirdGroupByValue,
                aggByWeek,
                weekIdSelected
              )
            }
            style={{ width: 300 }}
            classes={classes}
          />
        </div>
      </div>
      <div className="setting_row">
        <div className="label" style={{ width: 100 }}>
          Fulfillment type:
        </div>
        <div>
          <Select
            items={thirdOpt}
            disabled={disableThird}
            value={thirdGroupByValue}
            onChange={(evt: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
              handleGroupByChange(
                firstGroupByValue,
                secondGroupByValue,
                evt.target.value as string,
                aggByWeek,
                weekIdSelected
              )
            }
            style={{ width: 300 }}
            classes={classes}
          />
        </div>
      </div>
      <div className="setting_row" style={{ alignItems: 'flex-start' }}>
        <div className="label" style={{ width: 100, marginTop: 7 }}>
          Customize:
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <ColoredCheckbox
            checked={aggByWeek}
            onChange={() => {
              setAggByWeeks((prevVal) => {
                handleGroupByChange(firstGroupByValue, secondGroupByValue, thirdGroupByValue, !prevVal, weekIdSelected);
                return !prevVal;
              });
            }}
            disabled={shouldDisableAggWeek}
            style={{ marginLeft: -9 }}
            id="weekId-checkbox"
          />
          <div
            style={{
              paddingTop: 9,
              color: shouldDisableAggWeek ? colors.lightGrey : undefined
            }}
          >
            Separate data by week
          </div>
        </div>
      </div>
      <div className="setting_row">
        <div className="label" style={{ width: 100 }}>
          WeekId:
        </div>
        <div>
          <Select
            items={weekIdsSelectOpt}
            disabled={shouldDisableWeekIDSelect}
            value={weekIdSelected}
            onChange={(evt: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
              handleGroupByChange(
                firstGroupByValue,
                secondGroupByValue,
                thirdGroupByValue,
                aggByWeek,
                evt.target.value as number
              )
            }
            style={{ width: 300 }}
            classes={classes}
          />
        </div>
      </div>
    </>
  );
};
export default OmniExportGroupBy;
