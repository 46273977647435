import React, { useMemo } from 'react';
import ElementCard from 'src/components/BeaconRedesignComponents/ElementCard/ElementCard';
import { BrandLogo } from 'src/components/BeaconRedesignComponents/Generic/BrandLogo';
import { useAppSelector } from 'src/utils/Hooks';
import { getClientImageUrl } from 'src/utils/image';

/**
 *
 * This is a hook for rendering bulk adjustment modal's entity card.
 * Currently we don't support topics, and product entity will be using different modal.
 */
export const useEntityInfo = () => {
  const mainEntity = useAppSelector((state) => state.entityService.mainEntity);

  const entityInfoConfig = useMemo(() => {
    return {
      client: {
        title: mainEntity.name,
        header: (
          <img
            src={getClientImageUrl(mainEntity.beaconClientId)}
            style={{ maxWidth: '72px', maxHeight: '72px' }}
            alt={mainEntity.name}
          />
        ),
        displayName: 'Organization'
      },
      brand: {
        title: mainEntity.name,
        header: (
          <BrandLogo
            brandId={mainEntity.brandId}
            displayName={mainEntity.name}
            elementCardVariant="large"
            height={72}
            width={72}
          />
        ),
        displayName: 'Brand'
      },
      category: {
        title: mainEntity.name,
        header: <ElementCard color="accentTeal">{mainEntity.name.slice(0, 2)}</ElementCard>,
        displayName: 'Category'
      },
      segment: {
        title: mainEntity.name,
        header: <ElementCard color="info">{mainEntity.name.slice(0, 2)}</ElementCard>,
        displayName: 'Segment'
      },
      subcategory: {
        title: mainEntity.name,
        header: <ElementCard color="accentTangerine">{mainEntity.name.slice(0, 2)}</ElementCard>,
        displayName: 'Subcategory'
      },
      product: {
        title: mainEntity.name,
        header: <ElementCard color="accentTangerine">{mainEntity.name.slice(0, 2)}</ElementCard>,
        displayName: 'Product'
      }
    };
  }, [mainEntity]);

  return entityInfoConfig[mainEntity.type];
};
