import axios from 'axios';

import { fetchUserMetadata } from 'src/utils/app';
import userActions from 'src/store/modules/user/actions';
import Creators from './actions';
import { parseAdCampaigns } from './selectors';
import queryString from 'qs';
import { store } from 'src/main';

export const { receiveAdCampaigns } = Creators;

export const fetchAdCampaigns = (shouldAddPlatformTypeFilter) => async (dispatch, getState) => {
  const { retailer } = getState();
  const stringifiedParams = shouldAddPlatformTypeFilter
    ? queryString.stringify({
        platformType: retailer.platformType
      })
    : '';
  try {
    const { user } = store.getState();

    // read redux
    const tagsString = user.config.TagsForGranularViews;
    const tags = tagsString ? JSON.parse(tagsString) : [];
    if (tags && tags.length > 0) {
      return axios.get(`/apiAdManager/adCampaigns?${stringifiedParams}`).then(({ data }) => {
        return dispatch(receiveAdCampaigns(parseAdCampaigns(data, retailer.id, tags)));
      });
    } else {
      return axios.get(`/apiAdManager/adCampaigns?${stringifiedParams}`).then(({ data }) => {
        return dispatch(receiveAdCampaigns(parseAdCampaigns(data, retailer.id)));
      });
    }
  } catch (error) {
    console.error('Failed to fetch company division config:', error);
  }
  return null;
};

export const fetchElasticSearchAdCampaigns = () => (dispatch, getState) => {
  const { retailer } = getState();
  const appName = getState().app.apiAppName;
  const name = `${appName}-adCampaign`;
  const requestOverrides = {
    conditions: {
      // Only fetch campaigns for the current retailer
      termFilters: [
        {
          fieldName: 'retailerId',
          condition: 'must',
          values: [retailer.id.toString()]
        }
      ],
      rangeFilter: []
    }
  };

  fetchUserMetadata('beacon', name, requestOverrides).then((res) => {
    const adCampaigns = res.data[0].documents;
    const formatedAdCampaign = adCampaigns.map((campaign) => ({
      type: 'adCampaign',
      name: campaign.name,
      displayName: campaign.name,
      ...campaign
    }));
    dispatch(userActions.saveAdCampaigns(formatedAdCampaign));
  });
};
