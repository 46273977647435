/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import {
  AppButton,
  AppDialog,
  AppDropdownMenu,
  AppInput,
  AppParamTabs,
  AppTable,
  AppTableProps,
  brandclub_colors
} from '@stackline/ui';
import axios from 'axios';
import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _pickBy from 'lodash/pickBy';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { EllipsesIcon } from 'src/components/SvgIcons';
import ReduxStore from 'src/types/store/reduxStore';
import UserTeamManagementDeleteUpdateForm from './UserTeamManagementDeleteUpdateForm';
import UserTeamManagementForm from './UserTeamManagementForm';
import { USER_ROLES as roles, USER_SCOPES as permission } from 'src/utils/constants';
import { hasPermission, roleConverter } from 'src/utils/UserManagementPermission';
import TeamPageLayout from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Team/TeamPageLayout';
import { shouldShowNewBeacon } from 'src/utils/app';

const USER_ROLES_ORDER = ['support', 'owner', 'admin', 'basic', 'user'];

export const Operations: FC<{
  user?: any;
  tab?: string;
  domain?: string;
  setAllUserInDivision?: any;
  setAllApprovedDomains?: any;
}> = ({ user, tab, setAllUserInDivision, setAllApprovedDomains, domain }) => {
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState('');

  // eslint-disable-next-line no-shadow
  const { user: userFromRedux } = useSelector(({ user }: ReduxStore) => ({ user }));
  const storeUserEmail = _get(userFromRedux, ['config', 'profile', 'email']);
  const storeUserRole = _get(userFromRedux, ['config', 'profile', 'role']);

  const loggedInUserRole = roleConverter(storeUserEmail, storeUserRole);
  const targetUserRole = tab === 'team' && roleConverter(user.email, user.role);

  const handleRowClicked = (action: string) => {
    setActionModalOpen(true);
    setModalAction(action);
  };

  const canAssignAdmins = useMemo(
    () => hasPermission(permission.canAssignAdmins, loggedInUserRole) && targetUserRole !== roles.admin,
    [loggedInUserRole, targetUserRole]
  );

  const domainTabOptions = [
    {
      itemText: 'Remove Domain',
      Icon: null,
      onClick: () => handleRowClicked(permission.canEditApprovedDomain)
    }
  ];

  const teamTabOptions = [
    ...(canAssignAdmins
      ? [
          {
            Icon: null,
            itemText: 'Assign Admin Role',
            onClick: () => handleRowClicked(permission.canAssignAdmins)
          }
        ]
      : []),
    ...(hasPermission(permission.canAddUser, loggedInUserRole) && targetUserRole === roles.admin
      ? [
          {
            Icon: null,
            itemText: 'Remove Admin Role',
            onClick: () => handleRowClicked(permission.canDeleteAdmin)
          }
        ]
      : []),

    ...(hasPermission(permission.canTransferOwnership, loggedInUserRole) && targetUserRole !== roles.owner
      ? [
          {
            Icon: null,
            itemText: 'Transfer Ownership',
            onClick: () => handleRowClicked(permission.canTransferOwnership)
          }
        ]
      : []),
    {
      Icon: null,
      itemText: 'Delete User',
      onClick: () => handleRowClicked(permission.canDeleteUsers)
    }
  ];
  return (
    <>
      <AppDropdownMenu
        options={tab === 'team' ? teamTabOptions : domainTabOptions}
        buttonContent={<EllipsesIcon className="card__flip-icon" />}
        menuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 40
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        }}
      />

      <AppDialog open={actionModalOpen} maxWidth="lg">
        {/* We will replace this to @stackline/ui AppDialogConfirm  when we can customize button component */}
        <UserTeamManagementDeleteUpdateForm
          tab={tab}
          handleClose={() => setActionModalOpen(false)}
          targetUser={user}
          action={modalAction}
          domain={domain}
          setAllUserInDivision={setAllUserInDivision}
          setAllApprovedDomains={setAllApprovedDomains}
        />
      </AppDialog>
    </>
  );
};

//  Todo jbaik :type row before deploy
const getFullName = (row: any): string => `${row.firstName || ''} ${row.lastName || ''}`;

/** Mockup data for user rows */

//  TODO jbaik:: Check where we usually store columns constant Table
export const USER_TEAM_MANAGEMENT_DIVISION_COLUMNS: AppTableProps['columns'] = [
  {
    field: 'firstName',
    headerName: 'Name',
    tableColumnProps: {
      align: 'left',
      style: {
        width: 450
      }
    },
    valueFormatter: (_str, row) => {
      return getFullName(row);
    }
  },
  {
    field: 'email',
    headerName: 'Email',
    tableColumnProps: {
      align: 'left',
      style: {
        width: 530
      }
    }
  },
  {
    field: 'role',
    headerName: 'Role',
    tableColumnProps: {
      align: 'left',
      style: {
        paddingLeft: 0,
        minWidth: 200
      }
    },
    valueFormatter: (str) => {
      return _capitalize(`${str}`);
    }
  },
  {
    field: 'userOption',
    headerName: '',
    tableColumnProps: {
      align: 'right'
    }
  }
];

export const USER_TEAM_MANAGEMENT_DOMAINS_COLUMNS: AppTableProps['columns'] = [
  {
    field: 'domain',
    headerName: 'Domain Name',
    tableColumnProps: {
      align: 'left',
      style: {
        minWidth: 260
      }
    }
  },

  {
    field: 'userOption',
    headerName: '',
    tableColumnProps: {
      align: 'right'
    }
  }
];

export const USER_TEAM_MANAGEMENT_DOMAIN_COLUMNS = 0;

type TeamUser = any;
type ApprovedDomain = any;

// Based on this array element order, we will sort the table.

const UserTeamManagement: FC = () => {
  const [currentTab, setCurrentTap] = useState<'team' | 'domain'>('team');
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddingModalOpen, setIsAddingModalOpen] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [allUserInDivision, setAllUserInDivision] = useState<TeamUser[]>([]);
  const [allApprovedDomains, setAllApprovedDomains] = useState<ApprovedDomain[]>([]);
  const [loading, setLoading] = useState(false);

  const { user: userFromRedux } = useSelector(({ user }: ReduxStore) => ({ user }));

  const storeUserEmail = _get(userFromRedux, ['config', 'profile', 'email']);
  const storeUserRole = _get(userFromRedux, ['config', 'profile', 'role']);

  const loggedInUserRole = roleConverter(storeUserEmail, storeUserRole);

  // these are the pagination variables //
  const PAGE_SIZE = 100;
  const pageCount = Math.ceil(allUserInDivision.length / PAGE_SIZE);
  const startIndex = currentPage * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const pageData = allUserInDivision.slice(startIndex, endIndex);

  const goToPageData = (cPage) => {
    setCurrentPage(Math.max(0, Math.min(cPage, pageCount - 1)));
  };

  const fetchAllUsersInDivision = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get('api/user/GetAllUsersInCurrentDivision', {
        params: {
          activeUserEmail: storeUserEmail
        }
      });

      // We don't want lower level user can change upper level user's status.
      const storeUserRoleIndex = USER_ROLES_ORDER.findIndex((element) => element === storeUserRole);

      let data = res.data as Record<string, TeamUser>;

      // clients does not need to see our stackline users.
      if (!storeUserEmail.endsWith('stackline.com')) {
        data = _pickBy(data, (_, key) => {
          return !key.endsWith('stackline.com');
        });
      }
      const users = Object.values(data).map((user): any => {
        const targetUserRoleIndex = USER_ROLES_ORDER.findIndex((element) => element === user.role);
        return {
          ...user,
          ...(user.role === 'basic' && { role: 'user' }),
          // User cannot change their own status.
          userOption: storeUserEmail !== user.email && storeUserRoleIndex < targetUserRoleIndex && (
            <Operations key={user.email} user={user} tab="team" setAllUserInDivision={setAllUserInDivision} />
          )
        };
      });
      const sortedUsers = _sortBy(users, (user) => {
        return USER_ROLES_ORDER.indexOf(user.role);
      });

      setAllUserInDivision(sortedUsers);
    } catch (error) {
      console.warn(error);
    }
    setLoading(false);
  }, [storeUserEmail]);

  const GetUserDivisionAccountConfig = useCallback(async () => {
    setLoading(true);

    try {
      const res = await axios.get('api/user/GetUserDivisionAccountConfig', {
        params: {
          activeUserEmail: storeUserEmail
        }
      });

      const { data, status } = res;
      if (status === 200) {
        const authorizedClientDomains: string[] = _get(data, ['authorizedClientDomains'], []);
        const fetchedDomains = authorizedClientDomains.map((domain) => {
          return {
            domain,
            // only support user can see the delete option.
            ...(loggedInUserRole === roles.support && {
              userOption: <Operations key={domain} domain={domain} setAllApprovedDomains={setAllApprovedDomains} />
            })
          };
        });

        setAllApprovedDomains(fetchedDomains);
      }
    } catch (error) {
      console.warn('error', error);
    }
    setLoading(false);
  }, [storeUserEmail]);

  useEffect(() => {
    setSearchTerm('');
    if (currentTab === 'team') {
      fetchAllUsersInDivision();
    } else {
      GetUserDivisionAccountConfig();
    }
  }, [GetUserDivisionAccountConfig, currentTab, fetchAllUsersInDivision]);

  const filterTableRow = (data: TeamUser[]) => {
    if (data) {
      return data.filter((row) => {
        if (currentTab === 'team') {
          return (
            row.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.firstName.toLowerCase().includes(searchTerm.toLowerCase())
          );
        } else {
          return row.domain.toLowerCase().includes(searchTerm.toLowerCase());
        }
      });
    }
    return [];
  };

  const canAddUser = hasPermission(permission.canAddUser, loggedInUserRole);
  const canViewApprovedDomains = hasPermission(permission.canViewApprovedDomainTab, loggedInUserRole);

  if (shouldShowNewBeacon()) {
    return (
      <TeamPageLayout
        users={allUserInDivision}
        canAddUser={canAddUser}
        canViewApprovedDomains={canViewApprovedDomains}
        refetchData={fetchAllUsersInDivision}
      />
    );
  }

  return (
    <div style={{ marginLeft: '30px' }}>
      <div style={{ position: 'sticky', top: '65px', zIndex: 1, background: 'white', marginLeft: '13px' }}>
        {canAddUser && currentTab === 'team' && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AppButton
              invertStyle
              sx={{
                borderRadius: '5px',
                padding: '6px 15px',
                fontWeight: 400,
                color: '#fff',
                backgroundColor: '#222155'
              }}
              onClick={() => setIsAddingModalOpen(true)}
            >
              + Add User
            </AppButton>
          </div>
        )}

        {canViewApprovedDomains && currentTab === 'domain' && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AppButton
              invertStyle
              sx={{ borderRadius: '5px', padding: '6px 15px', fontWeight: 400 }}
              onClick={() => setIsAddingModalOpen(true)}
            >
              + Add domain
            </AppButton>
          </div>
        )}

        <div className="paramAndInputContainer" style={{ position: 'relative' }}>
          <AppParamTabs
            tabs={[
              { value: 'team', label: 'Team' },
              ...(hasPermission(permission.canViewApprovedDomainTab, loggedInUserRole)
                ? [{ value: 'domain', label: 'Approved Domains' }]
                : [])
            ]}
            tab={currentTab}
            setTab={(value) => setCurrentTap(value as 'team' | 'domain')}
            parentSx={{
              paddingTop: 4,
              paddingBottom: 4,
              '& div.MuiTabs-scroller': {
                // borderBottom: `1px solid #7e8fa8`,
                '& div.MuiTabs-flexContainer': {
                  gap: '25px',
                  minHeight: 'unset',
                  marginBottom: '6.5px'
                },
                '& span.MuiTabs-indicator': {
                  backgroundColor: brandclub_colors.darkBlue,
                  borderRadius: 3
                }
              }
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0
            }}
          >
            <AppInput
              placeholder={currentTab === 'team' ? 'Search for an email or name' : 'Search for a domain name'}
              value={searchTerm}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchTerm(event.target.value);
              }}
              sx={{
                width: '300px',
                border: '#f6f9fd',
                '& .MuiInput-root': {
                  backgroundColor: '#f6f9fd',
                  border: '#f6f9fd',
                  '& input': {
                    '&::placeholder': {
                      color: '#ccc',
                      opacity: 1
                    }
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </div>
      </div>
      {currentTab === 'team' ? (
        filterTableRow(allUserInDivision).length > 0 ? (
          <AppTable
            loading={loading}
            hidePagination={false}
            disablePreviousPage={currentPage === 0}
            handlePreviousPageClick={() => goToPageData(currentPage - 1)}
            disableNextPage={currentPage === pageCount - 1}
            handleNextPageClick={() => goToPageData(currentPage + 1)}
            columns={USER_TEAM_MANAGEMENT_DIVISION_COLUMNS}
            rows={searchTerm.length > 0 ? filterTableRow(allUserInDivision) : pageData}
            pageSize={PAGE_SIZE}
            hideSideBorders
            rowStyle={{ height: '66px', fontSize: '14px' }}
          />
        ) : searchTerm.length > 0 ? (
          <p style={{ marginLeft: '13px' }}>Sorry! No users match that search criteria.</p>
        ) : null
      ) : // domain tab
      filterTableRow(allApprovedDomains).length > 0 ? (
        <AppTable
          loading={loading}
          columns={USER_TEAM_MANAGEMENT_DOMAINS_COLUMNS}
          rows={allApprovedDomains}
          hidePagination
          pageSize={500}
          hideSideBorders
        />
      ) : searchTerm.length > 0 ? (
        <p>Sorry! No domains match that search criteria.</p>
      ) : (
        <p>You don&apos;t have active approved domains yet.</p>
      )}

      <AppDialog
        open={isAddingModalOpen}
        PaperProps={{}}
        onClose={() => {
          setIsBulkUpload(false);
        }}
        maxWidth="lg"
        sx={{}}
        style={{}}
      >
        <UserTeamManagementForm
          tab={currentTab}
          isBulkUpload={isBulkUpload}
          setIsBulkUpload={setIsBulkUpload}
          setAllUserInDivision={setAllUserInDivision}
          allApprovedDomains={allApprovedDomains}
          setAllApprovedDomains={setAllApprovedDomains}
          handleCloseBtn={() => {
            setIsAddingModalOpen(false);
            setTimeout(() => setIsBulkUpload(false), 300);
          }}
        />
      </AppDialog>
    </div>
  );
};

export default UserTeamManagement;
