import React, { useState } from 'react';
import { withBus } from 'react-bus';

import { getPills, SinglePill } from 'src/components/AdManager/Search/SearchResultsHeader';
import { useBus } from 'src/utils/Hooks';
import 'src/components/AdManager/Search/SearchSideBar.scss';

const PillFilters = (props: any) => {
  const [loading, setIsLoading] = useState(!props.isLoadingFalse);
  const [apiResult, setApiResult] = useState(props.defaultApiResult || undefined);
  const [buttonText, setButtonText] = useState('+ Add Filters');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useBus(props.eventBus, 'setSearchHeader', ({ isLoading, entity: { result } }) => {
    setIsLoading(isLoading);
    setApiResult(result);
  });

  useBus(props.eventBus, 'setSearchFilterButtonText', (isOpened: boolean) => {
    setIsSidebarOpen(isOpened);
    if (isOpened) {
      setButtonText('- Hide Filters');
    } else {
      setButtonText('+ Add Filters');
    }
  });

  const pills = getPills(apiResult);

  const openFilter = () => {
    props.eventBus.emit('toggleSearchSidebar', null);
  };

  const closeFilter = () => {
    props.eventBus.emit('closeSearchSidebar', null);
  };

  const handleClick = () => {
    if (isSidebarOpen) {
      closeFilter();
    } else {
      openFilter();
    }
  };

  return (
    <div className="subheader_section">
      <>
        <div className="filter_pills">
          {!loading && (
            <div className="pill_box">
              {pills.map((pill: any[], index: number) => (
                <SinglePill key={index} pill={pill} onPillClick={openFilter} />
              ))}
            </div>
          )}
          <div className="open_sidebar" role="button" onClick={handleClick}>
            {buttonText}
          </div>
        </div>
      </>
    </div>
  );
};

export default withBus('eventBus')(PillFilters);
