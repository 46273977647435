import { AdjustmentForm } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/types';
import {
  createAdditionalProperties,
  getDerivedAdjustmentAmount
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/utils';
import { useAppSelector } from 'src/utils/Hooks';

interface DefaultParams {
  adjustmentForm: AdjustmentForm;
  retailerSku: string | number;
  saveAsDraft?: boolean;
  publishFromDraft?: boolean;
  adjustmentId?: string;
  adjustmentStatus?: string;
  cpcFromUser?: boolean;
}

/**
 * Hook that returns functions for creating request bodies for different adjustment CRUD operations
 */
export const useAdjustmentRequest = () => {
  const beaconClientId = useAppSelector((state) => state.user.config.vendor.BeaconClientId);
  const retailer = useAppSelector((state) => state.retailer);
  const userId = useAppSelector((state) => state.user.session.userId);

  const buildAdjustmentCreationRequest = ({
    adjustmentForm,
    retailerSku,
    saveAsDraft = false,
    publishFromDraft = false,
    adjustmentId = '',
    cpcFromUser = false,
    adjustmentStatus = 'Published'
  }: DefaultParams) => {
    const { title, description, planInputAmounts, planType, startDate, endDate } = adjustmentForm;
    const planAmount = getDerivedAdjustmentAmount({ planType, input: planInputAmounts });

    const additionalProperties = createAdditionalProperties(adjustmentForm, cpcFromUser);

    const creationPayload = {
      adjustmentId,
      isDraft: adjustmentStatus === 'Draft',
      beaconClientId,
      retailerId: retailer.id,
      retailerSku,
      adjustmentTitle: title,
      adjustmentDescription: description,
      planType,
      adjustmentChangeType: 'Absolute',
      adjustmentChangeValue: planAmount,
      userId,
      startWeekId: startDate,
      endWeekId: endDate,
      saveAsDraft,
      ignoreConflictWithAdjustmentIds: [adjustmentId],
      draftAdjustment: {
        adjustmentId,
        publishFromDraft
      },
      additionalProperties
    };
    return creationPayload;
  };

  return { buildAdjustmentCreationRequest };
};
