import types from './types';

interface OmniProductsServiceState {
  [chartName: string]: {
    main: any[];
    compare: any[];
    isFetching: boolean;
  };
}

const initialState: OmniProductsServiceState = {};

const omniProductService = (
  state: OmniProductsServiceState = initialState,
  action: { type: string; entity: { chartName: string; data: any[] } }
) => {
  switch (action.type) {
    case types.START_FETCH:
      return {
        ...state,
        [action.entity.chartName]: { ...state[action.entity.chartName], isFetching: true }
      };
    case types.RECEIVE_PRODUCT_DATA_FOR_MAIN:
      return {
        ...state,
        [action.entity.chartName]: { ...state[action.entity.chartName], main: action.entity.data }
      };
    case types.RECEIVE_PRODUCT_DATA_FOR_COMPARE:
      return {
        ...state,
        [action.entity.chartName]: { ...state[action.entity.chartName], compare: action.entity.data }
      };
    case types.END_FETCH:
      return {
        ...state,
        [action.entity.chartName]: { ...state[action.entity.chartName], isFetching: false }
      };
    default:
      return state;
  }
};

export default omniProductService;
