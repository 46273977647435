import { SxProps } from '@mui/system';
import { SlButtonVariant, SlMenuProps, hexToRgba, useStacklineTheme } from '@stackline/ui';
import React, { CSSProperties, useMemo } from 'react';
import { DynamicDownChevron } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/DynamicDownChevron';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';
import { SlMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlMenu';

export interface SlDropdownMenuProps extends Omit<SlMenuProps, 'open'> {
  /**
   * Label to show when nothing has been selected yet
   */
  defaultLabel: string;
  disabled?: boolean;
  /**
   * Variant for the button that is rendered for clicking
   * to display the options.
   */
  buttonVariant?: SlButtonVariant;

  /**
   * If set, we will show n number of options
   * with a "+ View More" option at the bottom
   */
  amountOfOptionsToShow?: number;

  /**
   * If true, we will not set fixed menu width with 160px,
   * so the ellipsis for slMenu won't be applied
   */
  shouldShowFullItemText?: boolean;

  /**
   * Optional styles to apply to the dropdown menu options
   */
  menuStyles?: CSSProperties;

  /**
   * Optional styles to apply to the dropdown menu items text component
   */
  menuItemTextStyles?: SxProps;

  /**
   * Optional props to pass to the SlButton component used in the SlDropdownMenu.
   * This is useful for setting a fixed width on the main SlDropdownMenu Button via the buttonSx.
   * Example: stacklineButtonProps={{ buttonSx: { width: '152px' }}
   */
  stacklineButtonProps?: React.ComponentProps<typeof SlButton>;
}

/**
 * Common dropdown menu to be used throughout the Stackline site.
 */
export const SlDropdownMenu = ({
  options,
  defaultLabel,
  selectedId,
  onChange,
  disabled,
  buttonVariant = 'normal',
  shouldShowFullItemText = false,
  amountOfOptionsToShow,
  anchorOrigin,
  transformOrigin,
  iconStyle,
  menuStyles = {},
  menuItemTextStyles = {},
  stacklineButtonProps = {}
}: SlDropdownMenuProps & { iconStyle?: React.CSSProperties }) => {
  const theme = useStacklineTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const label = useMemo(() => {
    const selectedOption = options.find((option) => option.id === selectedId);
    return selectedOption ? selectedOption.label : defaultLabel;
  }, [selectedId, options, defaultLabel]);

  return (
    <div>
      <SlButton
        onClick={handleClick}
        disabled={disabled}
        endIcon={
          buttonVariant === 'title' ? (
            <DynamicDownChevron
              strokeWidth="2px"
              color={disabled ? hexToRgba(theme.colors.secondary, 0.5) : theme.colors.primary}
              style={{ marginTop: '5px', verticalAlign: 'bottom', ...iconStyle }}
            />
          ) : (
            <DynamicDownChevron
              color={disabled ? hexToRgba(theme.colors.secondary, 0.5) : theme.colors.primary}
              style={{ verticalAlign: 'bottom', ...iconStyle }}
            />
          )
        }
        variant={buttonVariant}
        buttonSx={
          buttonVariant === 'title'
            ? {
                padding: 0
              }
            : undefined
        }
        {...stacklineButtonProps}
      >
        {label}
      </SlButton>
      <SlMenu
        menuItemTextStyles={menuItemTextStyles}
        amountOfOptionsToShow={amountOfOptionsToShow}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: 'top',
            horizontal: 'left'
          }
        }
        options={options}
        selectedId={selectedId}
        onChange={onChange}
        sx={{
          marginTop: '6px',
          '& ul': {
            boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.15)',
            paddingY: 0
          },
          '.MuiPaper-root': {
            maxHeight: 'calc(100% - 59px)',
            ...(shouldShowFullItemText ? {} : { width: 'auto' }),
            paddingRight: 0,
            boxSizing: 'border-box',
            '&::-webkit-scrollbar': { display: 'none' },
            ...menuStyles
          },
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      />
    </div>
  );
};
