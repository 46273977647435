import { ACCEPT_CAPTION_TYPES, VIDEO_SPEC } from 'src/components/Layout/Advertising/AdMediaManagementLayout/specs';
import { readVideoFile } from 'src/components/Layout/Advertising/AdMediaManagementLayout/fileUtils';
import _get from 'lodash/get';

export const validateVideoFile = async (file: File): Promise<{ isValid: boolean; message: string }> => {
  const extension = file.name.split('.').pop().toLowerCase();

  const videoSpecs = VIDEO_SPEC.find((spec) => spec[0] === 'Video Specification')[1] || [];
  const videoFormatSpec = videoSpecs.find((spec) => spec.name === 'Video format');
  if (videoFormatSpec && !(_get(videoFormatSpec, 'value.options') || []).includes(extension)) {
    return {
      isValid: false,
      message: `Video format must be ${videoFormatSpec.displayValue}`
    };
  }

  // Read video properties
  try {
    const { duration, width, height, size } = await readVideoFile(file);

    // Check video duration
    const videoDurationSpec = videoSpecs.find((spec) => spec.name === 'Video duration');
    if (
      videoDurationSpec &&
      (duration < (_get(videoDurationSpec, 'value.min') || Infinity) ||
        duration > (_get(videoDurationSpec, 'value.max') || Infinity))
    ) {
      return {
        isValid: false,
        message: `Video duration must be ${videoDurationSpec.displayValue}`
      };
    }

    // Check video dimensions
    const videoDimensionsSpec = videoSpecs.find((spec) => spec.name === 'Video dimensions');
    if (
      videoDimensionsSpec &&
      !(_get(videoDimensionsSpec, 'value.options') || []).some(([w, h]) => w === width && h === height)
    ) {
      return {
        isValid: false,
        message: `Video dimensions must be ${videoDimensionsSpec.displayValue}`
      };
    }

    // Check file size
    const fileSizeSpec = videoSpecs.find((spec) => spec.name === 'File size');
    if (fileSizeSpec && size > _get(fileSizeSpec, 'value.max')) {
      return {
        isValid: false,
        message: `File size must be ${fileSizeSpec.displayValue}`
      };
    }

    return {
      isValid: true,
      message: 'Upload complete. Your video upload was successful.'
    };
  } catch (error) {
    return {
      isValid: false,
      message: 'Failed to read video file properties'
    };
  }
};

export const validateCaptionFile = async (file: File) => {
  try {
    const extension = file.name.split('.').pop().toLowerCase();
    if (ACCEPT_CAPTION_TYPES.includes(extension || '')) {
      return {
        isValid: true,
        message: `Upload complete. Your caption upload was successful.`
      };
    } else {
      return {
        isValid: false,
        message: `Invalid file type. Please select a ${ACCEPT_CAPTION_TYPES} file.`
      };
    }
  } catch (e) {
    return {
      isValid: false,
      message: `The uploaded caption does not meet the required specifications. Please try again.`
    };
  }
};
