import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';

interface MetricComparator {
  indexName: string;
  fieldName: string;
}

interface GroupComparator {
  /**
   * E.g. 'categoryId' or 'stacklineSku'
   */
  groupByField: string;
  /**
   * E.g. '1000' or 'Q1V7GWVQJ'
   */
  groupId: string;
  /**
   * Name describing the group,
   * e.g. "Headphones"
   */
  name: string;
}

interface ChartComparisonContextValue {
  isCompareMode: boolean;
  metricComparator: MetricComparator | null;
  groupComparator: GroupComparator | null;
  setMetricComparator: React.Dispatch<React.SetStateAction<MetricComparator>>;
  setGroupComparator: React.Dispatch<React.SetStateAction<GroupComparator>>;
  clearCompare: () => void;
}

const ChartComparisonContext = createContext<ChartComparisonContextValue | null>(null);

interface ChartComparisonProviderProps {
  children: React.ReactNode;
}

/**
 * Provides context for chart comparison
 */
export default function ChartComparisonProvider({ children }: ChartComparisonProviderProps) {
  const [metricComparator, _setMetricComparator] = useState<MetricComparator | null>(null);
  const [groupComparator, _setGroupComparator] = useState<GroupComparator | null>(null);

  const isCompareMode = useMemo(() => {
    return !!metricComparator || !!groupComparator;
  }, [metricComparator, groupComparator]);

  const clearCompare = useCallback(() => {
    _setMetricComparator(null);
    _setGroupComparator(null);
  }, []);

  const setMetricComparator = useCallback(
    (newComparator: MetricComparator) => {
      clearCompare();
      _setMetricComparator(newComparator);
    },
    [clearCompare]
  );

  const setGroupComparator = useCallback(
    (newComparator: GroupComparator) => {
      clearCompare();
      _setGroupComparator(newComparator);
    },
    [clearCompare]
  );

  return (
    <ChartComparisonContext.Provider
      value={{
        clearCompare,
        isCompareMode,
        metricComparator,
        setMetricComparator,
        groupComparator,
        setGroupComparator
      }}
    >
      {children}
    </ChartComparisonContext.Provider>
  );
}

export const useChartComparison = () => {
  const context = useContext(ChartComparisonContext);
  if (!context) {
    throw new Error('useChartComparison must be used within a ChartComparisonProvider');
  }
  return context;
};
