import invariant from 'invariant';
import _flatMap from 'lodash/flatMap';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';
import React from 'react';
import { AppName, Entity } from 'sl-api-connector/types';
import ScoreBarChart from 'src/components/AdManager/ScoreBarChart';
import SingleLegend from 'src/components/AdManager/SingleLegend';
import { EnhancedDonutChartContainer } from 'src/components/Charts/Donut';
import { withPush } from 'src/utils/hoc';
import Tabs from 'src/components/common/Tabs/Tabs';
import LeftNav from 'src/components/EntityPage/LeftNav';
import CommonSummaryTrend from 'src/components/EntityPage/SummaryTrendChart/CommonSummaryTrend';
import MultiGroupByTopEntitiesChart from 'src/components/EntityPage/TopEntitiesChart/MultiGroupByTopEntitiesChart';
import TopEntitiesChart from 'src/components/EntityPage/TopEntitiesChart/TopEntitiesChart';
import MultiMetricTrendChart from 'src/components/EntityPage/TrendChart/MultiMetricTrendChart';
import MultiMetricDonutChart from 'src/components/Charts/Donut/MultiMetricFieldDonutChart';
import TrendChart from 'src/components/EntityPage/TrendChart/TrendChart';
import Widget from 'src/components/EntityPage/Widget';
import WidgetGroup from 'src/components/EntityPage/WidgetGroup/WidgetGroup';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import { AggregationField, MetricField } from 'src/types/application/types';
import { PageLayout, Widget as WidgetType, WidgetProps } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import colors from 'src/utils/colors';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { shouldShowNewBeacon } from 'src/utils/app';
import { getNewBeaconWidgetStyles } from 'src/utils/styleUtils';

/**
 * Combines many individual widgets into a group that can be rendered as a single widget.  The child widgets will be
 * rendered internally by the [[WidgetGroup]] component which defaults to just rendering them one after another inside
 * of the container component.
 *
 * @param widgets The widgets to include in the group
 * @param widgetConfigOverride An object containing extra props to be merged into the created widget group object.
 *        A common pattern for this is to include container styles for the generated widget group like this:
 *
 * @example
 * ```typescript
 * buildWidgetGroup(
 *   [...widgets],
 *   {
 *     view: {
 *       container: {
 *         style: {
 *           display: 'flex',
 *           justifyContent: 'space-around',
 *           flex: 1
 *         }
 *       }
 *     }
 *   }
 * )
 * ```
 */
export function buildWidgetGroup(widgets: WidgetType[], widgetConfigOverride?: { [key: string]: any }): WidgetType {
  const widgetConfig = {
    name: 'widget_group',
    CustomComponent: WidgetGroup,
    view: {
      name: 'widget_group'
    },
    data: {
      widgets
    }
  };

  if (!widgetConfigOverride) {
    return widgetConfig;
  }

  return _merge({}, widgetConfig, widgetConfigOverride);
}

/**
 * Constructs a `Widget` that renders a trend chart based off of the provided configuration options.
 */
export function buildTrendWidgetConfig(
  app: ReduxStore['app'],
  indexName: string,
  entity: { type: string },
  groupByFieldName: string,
  metricFieldNames: string[],
  weekIdField: MetricField,
  widgetConfigOverride?: { [key: string]: any }
): WidgetType {
  const mainMetricFields = metricFieldNames.map((metricFieldName) =>
    INDEX_FIELDS.getField(app.name, indexName, metricFieldName, entity.type, groupByFieldName)
  );
  const groupByField: AggregationField = INDEX_FIELDS.getField(app.name, indexName, groupByFieldName, entity.type);

  const widgetConfig = {
    name: `${mainMetricFields[0].name}By${groupByField.name}`,
    CustomComponent: TrendChart as any,
    view: {
      ...VIEWS.weeklyTrendChart,
      displayName: `${mainMetricFields[0].displayName}`,
      metricFields: mainMetricFields,
      container: {
        style: {
          ...getNewBeaconWidgetStyles([
            { propertyName: 'marginBottom', originalValue: '100px', newBeaconValue: '20px' }
          ]),
          verticalAlign: 'top'
        }
      },
      eventListeners: [
        {
          action: 'refreshWidget',
          name: 'reclassify'
        }
      ]
    },
    data: {
      weekIdField,
      indexName,
      groupByFields: [groupByField],
      configByGroupByFieldName: {
        [groupByField.name]: {
          indexName,
          entity,
          aggregationFields: mainMetricFields
        }
      }
    }
  };

  if (!widgetConfigOverride) {
    return widgetConfig;
  }

  return _merge({}, widgetConfig, widgetConfigOverride);
}

interface GroupByFieldConfig {
  groupByFieldName: string;
  indexName?: string;
  aggregationFieldNames: string[];
}

interface SortByAggregationField {
  aggregateByFieldDisplayName: string;
  aggregateByFieldName: string;
  function: string;
  canBeExported: boolean;
}

/**
 * Constructs a `Widget` that renders a [[TopEntitiesChart]] based off of the supplied configuration and options.
 */
export function buildTopEntitiesWidgetConfig(
  app: ReduxStore['app'],
  indexName: string,
  entity: { type: string },
  aggregationFieldConfigs: GroupByFieldConfig[],
  pageSize: number,
  weekIdField: MetricField,
  widgetConfigOverride?: { [key: string]: any },
  sortByAggregationField?: SortByAggregationField,
  timePeriodOverride?: '1w' | 'mtd' | '4w' | '13w' | '26w' | '52w' | 'ytd' | 'ly'
): WidgetType {
  const mainAggregationFieldConfig = aggregationFieldConfigs[0];
  const mainGroupByField = INDEX_FIELDS.getField(
    app.name,
    indexName,
    mainAggregationFieldConfig.groupByFieldName,
    entity.type
  );
  const mainMetricFields = mainAggregationFieldConfig.aggregationFieldNames.map((metricFieldName) =>
    INDEX_FIELDS.getField(
      app.name,
      indexName,
      metricFieldName,
      entity.type,
      mainAggregationFieldConfig.groupByFieldName
    )
  );
  const groupByFields: MetricField[] = [];
  const configByGroupByFieldName: {
    [groupByFieldName: string]: {
      pageSize: number;
      indexName: string;
      entity: { type: string };
      aggregationFields: MetricField[];
    };
  } = {};
  aggregationFieldConfigs.forEach((aggregationFieldConfig) => {
    groupByFields.push(
      INDEX_FIELDS.getField(app.name, indexName, aggregationFieldConfig.groupByFieldName, entity.type)
    );
    const aggregationFields = aggregationFieldConfig.aggregationFieldNames.map((aggregationFieldName) =>
      INDEX_FIELDS.getField(
        app.name,
        indexName,
        aggregationFieldName,
        entity.type,
        aggregationFieldConfig.groupByFieldName
      )
    );
    configByGroupByFieldName[aggregationFieldConfig.groupByFieldName] = {
      pageSize,
      indexName,
      entity,
      aggregationFields
    };
  });
  const widgetConfig = {
    name: `${mainMetricFields[0].name}ByTop${mainAggregationFieldConfig.groupByFieldName}`,
    CustomComponent: TopEntitiesChart as any,
    view: {
      ...VIEWS.comparisonBarChart,
      chartSeriesColors: [colors.comparison, colors.stacklineBlue],
      displayName: `${mainMetricFields[0].displayName} by ${mainGroupByField.displayName}`,
      metricFields: mainMetricFields,
      container: {
        style: {
          ...getNewBeaconWidgetStyles([
            { propertyName: 'marginBottom', originalValue: '100px', newBeaconValue: '0px' }
          ]),
          verticalAlign: 'top'
        }
      }
    },
    data: {
      weekIdField,
      groupByFields,
      configByGroupByFieldName,
      sortByAggregationField
    }
  };
  if (timePeriodOverride) {
    // @ts-ignore
    widgetConfig.data.timePeriodOverride = timePeriodOverride;
  }

  if (!widgetConfigOverride) {
    return widgetConfig;
  }

  return _merge({}, widgetConfig, widgetConfigOverride);
}

/**
 * Constructs a `Widget` that renders a [[TopEntitiesChart]] that supports multiple group-by fields which can be
 * switched between in the UI via a created dropdown within the chart's title.
 */
export const buildMultiGroupByTopEntitiesChartWidgetConfig = (
  app: ReduxStore['app'],
  indexName: string,
  entity: { type: string },
  groupByFieldConfigs: {
    groupByFieldName: string;
    indexName: string;
    aggregationFieldNames: string[];
  }[],
  pageSize: number,
  metricFields: MetricField[],
  weekIdField: MetricField,
  widgetConfigOverride: any = {}
): WidgetType => {
  invariant(
    !_isEmpty(groupByFieldConfigs),
    'You must supply at least one group-by field config to `buildMultiGroupByTopEntitiesChartWidgetConfig`'
  );
  invariant(
    !_isEmpty(metricFields),
    'You must supply at least one metric field to `buildMultiGroupByTopEntitiesChartWidgetConfig`'
  );

  const baseWidget = buildTopEntitiesWidgetConfig(
    app,
    indexName,
    entity,
    groupByFieldConfigs,
    pageSize,
    weekIdField,
    widgetConfigOverride
  );
  const configByGroupByFieldName = groupByFieldConfigs
    .map(({ groupByFieldName, indexName: groupByFieldIndexName }) =>
      INDEX_FIELDS.getField(app.name, groupByFieldIndexName, groupByFieldName, entity.type)
    )
    .reduce(
      (acc, field) => ({
        ...acc,
        [field.name]: {
          pageSize,
          field,
          indexName: app.name === AppName.Advertising ? field.indexName : indexName,
          entity,
          aggregationFields: metricFields
        }
      }),
      {}
    );
  const compositeWidget = {
    ...baseWidget,
    name: _get(widgetConfigOverride, ['view', 'anchorName'], 'multiGroupByTopEntitiesChart'),
    CustomComponent: MultiGroupByTopEntitiesChart
  };
  compositeWidget.data.configByGroupByFieldName = configByGroupByFieldName;
  compositeWidget.view.metricFields = metricFields;
  return compositeWidget;
};

/**
 * Constructs a `Widget` that renders a [[TopEntitiesChart]] that supports multiple metric fields which can be
 * switched between in the UI via a created dropdown within the chart's title.
 */
export const buildMultiMetricTrendChartWidgetConfig = (
  app: ReduxStore['app'],
  indexName: string,
  entity: { type: string },
  metricFieldConfigs: string[],
  groupByField: string,
  weekIdField: MetricField,
  widgetConfigOverride: any = {}
): WidgetType => {
  invariant(
    !_isEmpty(metricFieldConfigs),
    'You must supply at least one metric field config to `buildMultiMetricTrendChartWidgetConfig`'
  );
  invariant(
    !_isEmpty(metricFieldConfigs),
    'You must supply at least one metric field to `buildMultiMetricTrendChartWidgetConfig`'
  );

  const name = 'multiMetricTrendChart';
  const widgetConfig = buildTrendWidgetConfig(app, indexName, entity, groupByField, metricFieldConfigs, weekIdField, {
    CustomComponent: MultiMetricTrendChart,
    name,
    view: {
      name,
      chartPropsOverride: {
        title: { text: '' }
      },
      showOnlyMainSeries: false,
      hideSubtitle: true
    }
  });

  if (!widgetConfigOverride) {
    return widgetConfig;
  }

  return _merge({}, widgetConfig, widgetConfigOverride);
};

/**
 * Constructs a `Widget` that renders a [[DonutChart]]
 */
export const buildDonutChartWidgetConfig = (
  app: ReduxStore['app'],
  indexName: string,
  entity: { type: string },
  metricFieldNames: string[],
  groupByFieldName: string,
  weekIdField: MetricField,
  widgetConfigOverride?: { [key: string]: any }
): WidgetType => {
  const mainMetricFields = metricFieldNames.map((metricFieldName) =>
    INDEX_FIELDS.getField(app.name, indexName, metricFieldName, entity.type, groupByFieldName)
  );

  const groupByField: AggregationField = INDEX_FIELDS.getField(app.name, indexName, groupByFieldName, entity.type);

  const widgetConfig = {
    CustomComponent: EnhancedDonutChartContainer,
    name: `${mainMetricFields[0].name}By${groupByField.name}`,
    view: {
      ...VIEWS.donutChart,
      name: `${mainMetricFields[0].name}By${groupByField.name}`,
      chartPropsOverride: {
        chart: {
          type: 'pie',
          height: 350
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            }
          }
        },
        tooltip: {
          enabled: true
        },
        disablePointSelect: true,
        enableMultiSelect: false
      },
      customHeader: {
        enabled: true,
        title: mainMetricFields[0].displayName
      },
      container: {
        style: {
          minWidth: shouldShowNewBeacon() ? '440px' : '500px',
          height: '440px'
        }
      }
    },
    data: {
      indexName,
      mainMetric: mainMetricFields[0],
      chartMainField: {
        name: mainMetricFields[0].name
      },
      secondaryMetric: { ...mainMetricFields, displayName: mainMetricFields[0].displayName },
      aggregationFields: mainMetricFields,
      groupByField
    }
  };

  if (!widgetConfigOverride) {
    return widgetConfig;
  }

  return _merge({}, widgetConfig, widgetConfigOverride);
};

/**
 * Constructs a `Widget` that renders a [[DonutChart]] that supports multiple metric fields which can be
 * switched between in the UI via a created dropdown within the chart's title.
 */
export const buildMultiMetricDonutChartWidgetConfig = (
  app: ReduxStore['app'],
  indexName: string,
  entity: { type: string },
  metricFieldConfigs: string[],
  groupByField: string,
  weekIdField: MetricField,
  widgetConfigOverride: any = {}
): WidgetType => {
  invariant(
    !_isEmpty(metricFieldConfigs),
    'You must supply at least one metric field config to `buildMultiMetricDonutChartWidgetConfig`'
  );

  const metricFields = metricFieldConfigs.map((metricFieldName) =>
    INDEX_FIELDS.getField(app.name, indexName, metricFieldName, entity.type, groupByField)
  );
  const groupByFields = [INDEX_FIELDS.getField(app.name, indexName, groupByField, entity.type, groupByField)];

  const name = 'multiMetricDonutChart';
  const widgetConfig = buildDonutChartWidgetConfig(
    app,
    indexName,
    entity,
    metricFieldConfigs,
    groupByField,
    weekIdField,
    {
      CustomComponent: MultiMetricDonutChart,
      name,
      view: {
        name,
        chartPropsOverride: {
          enableSwitchingGroupBy: true,
          title: { text: '' }
        },
        showOnlyMainSeries: false,
        hideSubtitle: true,
        metricFields,
        groupByFields
      },
      data: {
        groupByFields
      }
    }
  );

  if (!widgetConfigOverride) {
    return widgetConfig;
  }

  return _merge({}, widgetConfig, widgetConfigOverride);
};

/**
 * A widget component that creates a set of tabs and then renders other widget components provided via
 * `widget.view.tabDefinitions` conditionally depending on which tab is selected.
 */
const TabbedWidget: React.FC<WidgetProps> = withPush(({ push, widget, queryParams, ...widgetProps }) => {
  // const [activeTabIx, setActiveTabIx] = useState(0);
  const tabDefinitions = widget.view.tabDefinitions as { displayName: string; widget: WidgetType }[];

  let activeTabIx = !queryParams.groupByField
    ? 0
    : tabDefinitions.findIndex(
        (item) => _get(item, ['widget', 'data', 'groupByFields', 0, 'name']) === queryParams.groupByField
      );

  if (activeTabIx === -1) {
    activeTabIx = 0;
  }

  const handleTabClick = (_event: any, newTabIx: number) => {
    const groupByField = _get(tabDefinitions, [newTabIx, 'widget', 'data', 'groupByFields', 0, 'name'], '');
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('groupByField', groupByField);
    const newParams = searchParams.toString();
    push(`?${newParams}`);
    if (widget.view.onTabChange) {
      widget.view.onTabChange(newTabIx);
    }
  };

  React.useEffect(() => {
    if (!queryParams.groupByField) {
      handleTabClick({}, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.groupByField]);

  if (queryParams.groupByField && queryParams.id && queryParams.entityType === 'segment') {
    return (
      <>
        {tabDefinitions[activeTabIx] && (
          <Widget
            {...widgetProps}
            noInnerContainer
            mainEntityConditions={widgetProps.queryConditions}
            i={0}
            widget={tabDefinitions[activeTabIx].widget}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Tabs
        tabs={tabDefinitions}
        value={activeTabIx}
        onTabChange={handleTabClick}
        tabStyle={{ paddingBottom: 8, fontSize: 18 }}
        style={widget.view.tabsStyle}
        linkCustomTab
      />
      {tabDefinitions[activeTabIx] && (
        <Widget
          {...widgetProps}
          noInnerContainer
          mainEntityConditions={widgetProps.queryConditions}
          i={0}
          widget={tabDefinitions[activeTabIx].widget}
        />
      )}
    </>
  );
});

/**
 * Creates a widget that renders a set of tabs and then conditionally renders widgets provided in `tabDefinitions`
 * depending on which tab is selected.
 */
export const buildTabbedWidget = (
  tabDefinitions: { displayName: string; widget: WidgetType }[],
  widgetOverrides: object = {},
  tabsStyle?: React.CSSProperties,
  onTabChange?: (newTabIx: number) => void,
  tabActions?: { icon: any; name: string }[],
  onTabActionClick?: (action: { icon: any; name: string }) => void
): WidgetType => {
  invariant(!_isEmpty(tabDefinitions), 'You must supply at least one tab definition');

  const name = 'tabbedWidget' as const;
  const widgetConfig = {
    name,
    CustomComponent: TabbedWidget,
    view: {
      name,
      tabDefinitions,
      tabsStyle,
      onTabChange,
      tabActions,
      onTabActionClick
    },
    data: {}
  };

  if (!widgetOverrides) {
    return widgetConfig;
  }

  return _merge(widgetConfig, widgetOverrides);
};

/**
 * Given a widget that may be a widget group, passes the widget back if it's not a widget group or returns the widget
 * group's child widgets if it is.  Also recursively flattens the children of any children that may be widget groups.
 *
 * @param widget The widget that may be a widget group to be flattened
 */
export const maybeFlattenWidgetGroup = (widget: WidgetType): WidgetType | WidgetType[] =>
  widget.name === 'widget_group' ? _flatMap(widget.data.widgets, maybeFlattenWidgetGroup) : widget;

/**
 * Given an array of widgets that may be nested (arbitrarily deeply) using widget groups, returns a new array of
 * widgets that has been fully flattened to pull all wigets out into a 1D array.
 *
 * @param widgets The array of (possibly nested) widgets to flatten
 */
export const flattenWidgets = (widgets: WidgetType[]): WidgetType[] => _flatMap(widgets, maybeFlattenWidgetGroup);

/**
 * Constructs a widget that renders the left nav component with some built-in container styling
 */
const buildLeftNavWidget = (): WidgetType => ({
  CustomComponent: LeftNav,
  name: 'leftNav',
  view: {
    name: 'leftNav',
    container: {
      style: {
        display: 'flex',
        flexBasis: 270
      }
    }
  },
  data: {}
});

/**
 * Styles that are applied to widget wrappers by default.  They are based off of the `.entity-grid-container` class.
 */
export const defaultWidgetWrapperStyle: React.CSSProperties = {
  width: '100%',
  maxWidth: 1280,
  margin: '0px auto 0px auto'
};

/**
 * Takes a `pageLayout` and returns a new one that has a `LeftNav` widget added to it.
 *
 * All of the existing widgets under `pageLayout.widgets` are moved into a `WidgetGroup` which is rendered after the
 * left nav.  A `containerStyle` prop is added to the returned layout that is designed to display the left nav
 * alongside the rest of the widgets.
 */
export const addLeftNavToLayout = (pageLayout: PageLayout) => {
  // Create a widget group out of the base widgets for the layout
  const baseWidgetsGroup = buildWidgetGroup(pageLayout.widgets, {
    view: {
      container: {
        className:
          'sl-page-container sl-page-container--retailSales sl-page-container--product sl-page-container--atlas',
        style: { ...defaultWidgetWrapperStyle, ...(pageLayout.containerStyle || {}) }
      }
    }
  });

  // Create a widget for the left nav and add it to the layout along with the base widgets group
  const leftNavWidget = buildLeftNavWidget();

  // Style the div that wraps the widgets so that they sidebar and the rest of the widgets display side by side.
  // This container is rendered around both the left nav and the widget group containing the rest of the widgets.
  const containerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  };

  return { ...pageLayout, containerStyle, widgets: [leftNavWidget, baseWidgetsGroup] };
};

export const summaryTrendStyle = {
  root: {
    flex: '1 1 100%',
    padding: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  columnContainer: {
    width: 371,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0px'
  },
  mainTrend: {
    height: '300px',
    display: 'inline-block',
    marginBottom: '0px',
    padding: '30px 16px 16px 16px'
  },
  subTrend: {
    className: 'summary_subtrend_parent',
    style: { display: 'inline-block', marginBottom: '0px' }
  }
};

export const summaryTrendView = {
  chart: {
    type: 'spline',
    height: 200,
    marginTop: 3
  },
  yAxis: [
    {
      endOnTick: true,
      visible: false,
      minPadding: 0.3
    }
  ],
  xAxis: [
    {
      visible: false
    }
  ],
  title: { text: '' },
  exporting: {
    enabled: false
  }
};

export const summarySubTrendView = {
  chart: {
    type: 'spline',
    height: `20%`,
    marginTop: 3,
    marginBottom: 3,
    backgroundColor: null
  },
  yAxis: [
    {
      maxPadding: 0.1,
      minPadding: 0.1,
      endOnTick: false,
      visible: false
    },
    {
      maxPadding: 0.1,
      minPadding: 0.1,
      endOnTick: false,
      visible: false
    }
  ],
  xAxis: [
    {
      visible: false
    }
  ],
  exporting: {
    enabled: false
  },
  title: { text: '' }
};

const trendChartColumnWidgetConfigOverride = {
  view: { container: { className: 'summary_column_container', style: { ...summaryTrendStyle.columnContainer } } }
};

/**
 * This defines one individual chart of a summary trend chart group.  It consists of either a tuple containing
 * configuration for the chart or a `Widget` to be rendered directly.
 */
export type SummaryTrendDefinition =
  | WidgetType
  // [chartIndexName, metricFieldName, legendTitle, chartTab, subtab, scrollTo, overrides, linkToRest]
  | [
      string,
      string,
      string,
      string,
      string,
      string | null | undefined,
      object | null | undefined,
      object | null | undefined
    ];

/**
 * Constructs a widget that creates a grid of small trend charts based off of the definitions in `columnDefinitions`.
 */
export const buildSummaryTrendColumnGroupsWidget = (
  { app, entity, weekIdField }: { app: ReduxStore['app']; entity: Entity; weekIdField: MetricField },
  columnDefinitions: SummaryTrendDefinition[][],
  widgetGroupOverrides?: { [key: string]: any }
) => {
  const buildSummaryTrendChart = (args: SummaryTrendDefinition, i: number) => {
    // If a non-array is passed in, we expect it to be a pre-built widget, so we pass it through as-is.
    if (!_isArray(args)) {
      return args;
    }

    const [chartIndexName, metricFieldName, legendTitle, chartTab, subtab, scrollTo, overrides, linkToRest] = args;
    const widgetConfig = buildTrendWidgetConfig(app, chartIndexName, entity, 'weekId', [metricFieldName], weekIdField, {
      view: {
        chartPropsOverride: {
          legend: { enabled: false, legendTitle },
          ...(i === 0 ? summaryTrendView : summarySubTrendView)
        },
        linkTo: { tab: chartTab, subtab, scrollTo, ...(linkToRest || {}) },
        container: i === 0 ? { style: { ...summaryTrendStyle.mainTrend } } : { ...summaryTrendStyle.subTrend }
      },
      CustomComponent: CommonSummaryTrend
    });

    if (!overrides) {
      return widgetConfig;
    }
    return _merge(widgetConfig, overrides || {});
  };

  const widgetConfig = { view: { container: { style: { ...summaryTrendStyle.root } } } };

  return buildWidgetGroup(
    columnDefinitions.map((colDefs) =>
      buildWidgetGroup(colDefs.map(buildSummaryTrendChart), trendChartColumnWidgetConfigOverride)
    ),
    widgetGroupOverrides ? _merge(widgetConfig, widgetGroupOverrides) : widgetConfig
  );
};

export const buildTopEntitiesWidgetConfigForNewBeacon = (
  app: ReduxStore['app'],
  indexName: string,
  entity: { type: string },
  groupByFieldNames: string[],
  metricFieldNames: string[],
  weekIdField: MetricField,
  widgetConfigOverride?: { [key: string]: any }
): Widget => {
  const { name: appName } = app;
  const { type: entityType } = entity;

  const mainMetricFields = metricFieldNames.map((metricFieldName) => {
    return _cloneDeep(INDEX_FIELDS.getField(appName, indexName, metricFieldName, entityType));
  });

  const groupByFields = groupByFieldNames.map((groupByFieldName) =>
    _cloneDeep(INDEX_FIELDS.getField(appName, indexName, groupByFieldName, entityType))
  );

  //  create component name
  const groupByFieldsName = groupByFields.map((g) => g.name);
  const groupByFieldName = groupByFieldsName.join('_');

  const widgetConfig = {
    name: `${mainMetricFields[0].name}By${groupByFieldName}`,
    CustomComponent: TopEntitiesChart,
    view: {
      ...VIEWS.comparisonBarChart,
      displayName: `${mainMetricFields[0].displayName} by ${groupByFieldName}`,
      mainMetricFields,
      container: {
        style: {
          marginBottom: '100px',
          verticalAlign: 'top'
        }
      }
    },
    data: {
      weekIdField,
      groupByFields
    }
  };

  if (!widgetConfigOverride) {
    return widgetConfig;
  }

  return _merge({}, widgetConfig, widgetConfigOverride);
};

/**
 * Constructs a widget that creates a grid of small trend charts based off of the definitions in `columnDefinitions`.
 */
export const buildSummaryTrendColumnGroupsWidgetForNewBeacon = (
  { app, entity, weekIdField }: { app: ReduxStore['app']; entity: Entity; weekIdField: MetricField },
  columnDefinitions: SummaryTrendDefinition[][],
  widgetGroupOverrides?: { [key: string]: any },
  trendWidgetConfigOverrides?: { [key: string]: any }
) => {
  const buildSummaryTrendChart = (args: SummaryTrendDefinition, i: number) => {
    // If a non-array is passed in, we expect it to be a pre-built widget, so we pass it through as-is.
    if (!_isArray(args)) {
      return args;
    }

    const [chartIndexName, metricFieldName, legendTitle, chartTab, subtab, scrollTo, overrides, linkToRest] = args;
    const widgetConfig = buildTrendWidgetConfig(
      app,
      chartIndexName,
      entity,
      'weekId',
      [metricFieldName],
      weekIdField,
      _merge(
        {
          view: {
            chartPropsOverride: {
              legend: { enabled: false, legendTitle },
              ...(i === 0 ? summaryTrendView : summarySubTrendView)
            },
            linkTo: { tab: chartTab, subtab, scrollTo, ...(linkToRest || {}) },
            container: { style: { marginBottom: '8px' } },
            isSubtrend: i > 0 // indicate to trend chart if it should render the large or small variant
          },
          CustomComponent: CommonSummaryTrend
        },
        trendWidgetConfigOverrides || {}
      )
    );

    if (!overrides) {
      return widgetConfig;
    }
    return _merge(widgetConfig, overrides || {});
  };

  const widgetConfig = { view: { container: { style: { ...summaryTrendStyle.root, marginTop: '16px' } } } };

  return buildWidgetGroup(
    columnDefinitions.map((colDefs) => buildWidgetGroup(colDefs.map(buildSummaryTrendChart))),
    widgetGroupOverrides ? _merge(widgetConfig, widgetGroupOverrides) : widgetConfig
  );
};

export interface AdManagerKeyMetricWidget extends WidgetType {
  view: { name: string; title: string };
  data: { indexName: string; metricField: any; groupByField: any; adScoreName?: string; mainEntity?: any };
}

export interface AdManagerKeyMetricsColumn {
  widgets: AdManagerKeyMetricWidget[];
}

/**
 * Constructs a widget that creates a grid of screBar and legends based off of the definitions in `columnDefinitions`.
 */
export const buildAdManagerColumnGroupsWidget = (
  app: ReduxStore['app'],
  columnDefinitions: AdManagerKeyMetricsColumn[],
  widgetGroupOverrides?: { [key: string]: any }
) => {
  const buildScoreBarChartWidget = (metric: { indexName: string; metricField: any; groupByField: any }) => ({
    CustomComponent: ScoreBarChart,
    name: `scoreBar-${metric.metricField.name}`,
    view: {
      name: `scoreBar-${metric.metricField.name}`,
      title: metric.metricField.displayName
    },
    data: {}
  });
  const buildSingleLegendWidget = (metric: { indexName: string; metricField: any; groupByField: any }) => ({
    CustomComponent: SingleLegend,
    name: `singleLegend-${metric.metricField.name}`,
    view: {
      name: `singleLegend-${metric.metricField.name}`,
      title: metric.metricField.displayName,
      container: {
        style: { padding: '20px 0 20px 0' }
      }
    },
    data: {
      indexName: metric.indexName,
      metricField: metric.metricField,
      groupByField: metric.groupByField
    }
  });

  const widgetConfig = { view: { container: { style: { ...summaryTrendStyle.root } } } };

  return buildWidgetGroup(
    columnDefinitions.map((columnDefinition) =>
      buildWidgetGroup(
        columnDefinition.metrics.map((metric, idx) => {
          if (idx === 0) {
            return buildScoreBarChartWidget(metric);
          }
          return buildSingleLegendWidget(metric);
        })
      )
    ),
    widgetGroupOverrides ? _merge(widgetConfig, widgetGroupOverrides) : widgetConfig
  );
};
