import types from './types';

// when data is fixed please fill interface

const startFetchOmniDynamicFilter = (dynamicFilter: any) => ({
  type: types.START_FETCH,
  dynamicFilter
});

const endFetchOmniDynamicFilter = (dynamicFilter: any) => ({
  type: types.END_FETCH,
  dynamicFilter
});

const receiveAllOmniDynamicFilter = (dynamicFilter: any) => ({
  type: types.RECEIVE_ALL_OMNI_DYNAMIC_FILTER,
  dynamicFilter
});

export default { startFetchOmniDynamicFilter, receiveAllOmniDynamicFilter, endFetchOmniDynamicFilter };
