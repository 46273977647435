import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import Truncate from 'react-truncate';
import Chip from '@mui/material/Chip';

import colors from 'src/utils/colors';
import { getBrandImageUrl } from 'src/utils/image';
import { BrandIcon } from '../../../SvgIcons';
import { isOmni } from 'src/utils/app';

class BrandSidebarCard extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    entity: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      logoAvailable: true
    };
  }

  handleImageError = () => {
    this.setState({
      logoAvailable: false
    });
  };

  render() {
    const { app, entity, match } = this.props;
    const { searchParams } = app.queryParams;
    const { logoAvailable } = this.state;
    if (_isEmpty(entity)) {
      return null;
    }
    const { brandName } = entity;
    let subtab = 'keymetrics';
    if (app.name === 'atlas') {
      subtab = 'retailSales';
    } else if (isOmni) {
      subtab = 'all';
    }
    const tab = isOmni ? 'scorecard' : 'sales';
    return (
      <div className="nav-header-container">
        <div className="sl-image-container">
          <div className="sl-image-center">
            {/* This should probably link to the key metrics page once its created */}
            <NavLink style={{ textDecoration: 'none' }} to={`${searchParams}&tab=${tab}&subtab=${subtab}`}>
              {logoAvailable && (
                <div className="brand__image-container sl-image-container">
                  <div className="brand__image-content sl-image-center">
                    <img
                      className="sl-image"
                      alt="logo"
                      src={getBrandImageUrl(match.params.id)}
                      onError={this.handleImageError}
                    />
                  </div>
                </div>
              )}
              {!logoAvailable && (
                <BrandIcon
                  style={{
                    stroke: colors.lightGrey,
                    strokeWidth: '7px',
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                />
              )}
            </NavLink>
          </div>
        </div>
        <div className="nav-name-container" title={brandName}>
          <Truncate lines={2} ellipsis="...">
            <h4 className="nav-company-name">{brandName}</h4>
          </Truncate>
        </div>

        <Chip className="entity-chip" label={_get(entity, 'id') === 0 ? 'All Brands' : 'Brand'} />
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  app
});

export default withRouter(connect(mapStateToProps, null)(BrandSidebarCard));
