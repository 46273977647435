import React, { useRef, useMemo, useState, useEffect } from 'react';
import queryString from 'qs';
import { connect } from 'react-redux';
import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';
import numeral from 'numeral';
import {
  Entity,
  Conditions,
  AdManagerAdPortfolioEntity,
  AdManagerAdCampaignEntity,
  RangeFilter,
  OptimizationHistoryItem
} from 'sl-api-connector/types';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { store } from 'src/main';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { buildAggregations } from 'src/components/AdManager/Search';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import moment from 'moment-timezone';
import { DollarExactCRF } from 'src/components/AdCampaignBuilder/Widgets/TargetEntitiesGrid/GridFrameworks';
import Tabs from 'src/components/common/Tabs/Tabs';
import './AdOptimizationHistory.scss';
import { withDisplayName, withProps } from 'src/utils/hoc';
import { Link } from 'react-router-dom';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { OptimizationFailure, ManualBiddingNoOptimizations, AdAuditNoOptimizations } from './FailureState';
import { TimePeriod } from 'src/types/store/storeTypes';
import { AGGridValueFormatter, MetricField } from 'src/types/application/types';
import ReduxStore from 'src/types/store/reduxStore';
import { WidgetProps } from 'src/types/application/widgetTypes';
import { panic } from 'src/utils/mixpanel';
import AdManagerSearchSideBar from 'src/components/AdManager/Search/SearchSideBar';
import PillFiltersOptimization from 'src/components/Search/AdvancedSearch/AdvancedSearchSideBar/PillFilters';
import { buildAdvancedSearchSideBarWidget as buildExistingSideBar } from 'src/components/Layout/Advertising/AdManagerPageLayout/SearchPageLayout';
import { useBus } from 'src/utils/Hooks';
import { AdManagerEvents } from 'src/types/application/event';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import { UTC_TIMEZONE } from 'src/utils/constants';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import _merge from 'lodash/merge';
import { AutoSizer, List } from 'react-virtualized';
import styled from '@emotion/styled';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

const PAGE_SIZE = 1000;

const custom_style = {
  tabStyle: { paddingBottom: 8, fontSize: 18 }
};

const correctEntityType = (entityType: string): string =>
  new Map(
    Object.entries({
      portfolio: 'adPortfolio',
      entity: 'adEntity',
      campaign: 'adCampaign'
    })
  ).get(entityType) || entityType;

const typeDisplayNameMapping = (type: string = 'adCampaign') =>
  ({
    adCampaign: 'campaign',
    adPortfolio: 'portfolio',
    adEntity: 'entity',
    client: 'client'
  }[type]);

const termFiltersForTab = [
  [
    // index = 0, target tab
    {
      fieldName: 'changeDataType',
      condition: 'should',
      values: ['keyword', 'target']
    },
    {
      fieldName: 'changeSource',
      condition: 'should',
      values: ['automationV2', 'scheduledAction']
    }
  ],
  [
    // index = 1, compaign tab
    {
      fieldName: 'changeDataType',
      condition: 'should',
      values: ['campaign']
    },
    {
      fieldName: 'changeSource',
      condition: 'should',
      values: ['automationV2', 'scheduledAction']
    }
  ],
  [
    // index = 2, manual/other tab
    {
      fieldName: 'changeDataType',
      condition: 'should',
      values: ['campaign', 'portfolio', 'product', 'keyword', 'target', 'adGroup']
    },
    {
      fieldName: 'changeSource',
      condition: 'should',
      values: ['manualInAdManager', 'external']
    }
  ]
];

const adAuditUserTermFiltersForTab = [
  [
    // index = 0, bid tab
    {
      fieldName: 'changeType',
      condition: 'should',
      values: ['bid']
    },
    {
      fieldName: 'changeSource',
      condition: 'should',
      values: ['external']
    }
  ],
  [
    // index = 1, budget tab
    {
      fieldName: 'changeType',
      condition: 'should',
      values: ['budget']
    },
    {
      fieldName: 'changeSource',
      condition: 'should',
      values: ['external']
    }
  ],
  [
    // index = 2, all other manual actions tab
    {
      fieldName: 'changeType',
      condition: 'must_not',
      values: ['bid', 'budget']
    },
    {
      fieldName: 'changeSource',
      condition: 'should',
      values: ['external']
    }
  ]
];

interface ChangeHistoryDoc {
  _id: string;
  date: string;
  dayId: number;
  weekId: number;
  entityId: string;
  entityIdApi: string;
  retailerId: number;
  beaconClientId: number;
  beaconClientLoginId: number;
  adGroupId?: string;
  adGroupName?: string;
  changeDataType: string;
  changeType: string;
  changeSource: string;
  changeActionDescription: string;
  changeReason: string;
  changeAction: string;
  campaignId?: string;
  campaignIdApi?: string;
  campaignType?: string;
  campaignName?: string;
  oldValue?: string;
  newValue?: string;
  bidSavings?: number;
  optimizationStrategy?: string;
  searchKeyword?: string;
  searchKeywordFuzzy?: string;
  matchingType?: string;
  portfolioId?: string;
  portfolioIdApi?: string;
  productId?: string;
  portfolioName?: string;
  targetId?: string;
  updatedTime: string;
}

const mapColumnStateToProps = ({ app }: ReduxStore) => ({ app });

const CampaignColumn = connect(mapColumnStateToProps)((props) => {
  const { searchParams, additionalParams } = props.app.queryParams;
  const parsedAdditionalParameters = queryString.parse(additionalParams);
  parsedAdditionalParameters.subtab = 'keyMetrics';
  const campaignId = _get(props, ['data', 'campaignId']);
  const link = `/adCampaign/${campaignId}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`;
  return (
    <Link to={link}>
      <Text
        variant="body2"
        truncateLines={1}
        title={props.valueFormatted}
        textAlign="left"
        sx={{ lineHeight: 'unset' }}
      >
        {props.valueFormatted}
      </Text>
    </Link>
  );
});

const TargetColumn = connect(mapColumnStateToProps)((props) => {
  const { searchParams, additionalParams } = props.app.queryParams;
  const parsedAdditionalParameters = queryString.parse(additionalParams);
  parsedAdditionalParameters.subtab = 'keyMetrics';
  const targetText = _get(props, ['valueFormatted']);
  const link = `/adTarget/${targetText}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`;
  return <Link to={link}>{props.valueFormatted}</Link>;
});

const ManualColumn = connect(mapColumnStateToProps)((props) => {
  const { searchParams, additionalParams } = props.app.queryParams;
  const parsedAdditionalParameters = queryString.parse(additionalParams);
  parsedAdditionalParameters.subtab = 'keyMetrics';
  const mappingTable = {
    campaign: {
      routeName: 'adCampaign',
      fieldName: 'campaignName',
      entityId: 'campaignId'
    },
    target: {
      routeName: 'adTarget',
      fieldName: 'searchKeyword',
      entityId: 'searchKeyword'
    },
    keyword: {
      routeName: 'adTarget',
      fieldName: 'searchKeyword',
      entityId: 'searchKeyword'
    },
    portfolio: {
      routeName: 'adPortfolio',
      fieldName: 'portfolioName',
      entityId: 'portfolioId'
    },
    product: {
      routeName: 'product',
      fieldName: 'productId',
      entityId: 'stacklineSku'
    },
    adGroup: {
      routeName: 'adGroup',
      fieldName: 'adGroupName',
      entityId: 'adGroupId'
    }
  };

  const changeDataType = _get(props, ['data', 'changeDataType'], 'campaign');

  const campaignItem = mappingTable[changeDataType];
  const entityId = _get(props, ['data', campaignItem.entityId]);
  const displayName = _get(props, ['data', campaignItem.fieldName]);
  const { routeName } = campaignItem;

  if (entityId) {
    const link = `/${routeName}/${entityId}${searchParams}&${queryString.stringify(parsedAdditionalParameters)}`;
    return <Link to={link}>{displayName || entityId}</Link>;
  } else {
    return <>{displayName || entityId}</>;
  }
});

const buildConditionsForEntity = (outerEntity: Entity): Conditions => {
  const conditionsGetterByEntityType: { [entityType: string]: (entity: any) => Conditions } = {
    adEntity: (entity) => ({
      termFilters: [{ fieldName: 'entityId', values: [entity.id] }]
    }),
    adPortfolio: (entity) => {
      const { portfolioIdApi } = (entity as AdManagerAdPortfolioEntity).extendedAttributes;
      if (_isNil(portfolioIdApi)) {
        console.error('Portfolio is missing `portfolioIdApi` which is required to fetch optimization counts: ', entity);
      }

      return {
        termFilters: [
          {
            fieldName: 'portfolioIdApi',
            condition: 'must',
            values: [portfolioIdApi || '---']
          }
        ]
      };
    },
    adCampaign: (entity) => {
      const { campaignIdApi } = (entity as AdManagerAdCampaignEntity).extendedAttributes;
      if (_isNil(campaignIdApi)) {
        console.error('Campaign is missing `campaignIdApi` which is needed to fetch optimization counts: ', entity);
      }

      return {
        termFilters: [
          {
            fieldName: 'campaignIdApi',
            values: [campaignIdApi || '---']
          }
        ]
      };
    },
    adGroup: (entity) => {
      const { adGroupIdApi } = (entity as AdManagerAdCampaignEntity).extendedAttributes;
      if (_isNil(adGroupIdApi)) {
        console.error('Campaign is missing `adGroupIdApi` which is needed to fetch optimization counts: ', entity);
      }

      return {
        termFilters: [
          {
            fieldName: 'adGroupIdApi',
            values: [adGroupIdApi || '---']
          }
        ]
      };
    },
    client: (_entity) => ({ termFilters: [], rangeFilters: [] })
  };

  const conditionsGetter = conditionsGetterByEntityType[correctEntityType(outerEntity.type)];
  if (!conditionsGetter) {
    return panic(`No handler for fetching optimization counts for entity of type "${outerEntity.type}"`);
  }
  const conditions = conditionsGetter(outerEntity);
  if (!conditions) {
    return panic(`Unable to produce ad optimization history conditions for entity of type ${outerEntity.type}`);
  }

  return conditions;
};

/**
 * Fetches the number of unique ad optimization items that exist for the supplied during the supplied time period,
 * returning a `Promise` that resolves to the number of optimization items.
 */
export const fetchAdOptimizationItemCountForEntity = (entity: Entity, timePeriod: TimePeriod) => {
  const { app, retailer, user } = store.getState();
  const dataKey = `adOptimizationItemCount-${entity.type}-${entity.id}`;
  const groupByField = INDEX_FIELDS.getField(app.name, 'adOptimizationHistory', 'beaconClientId');
  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);

  const metricFields = [
    {
      ...INDEX_FIELDS.getField(app.name, 'adOptimizationHistory', 'changeAction')
    }
  ];
  const [{ aggregations: aggregationFields }] = buildAggregations(metricFields);

  const conditions = buildConditionsForEntity(entity);

  const timePeriodRangeFilter: RangeFilter = {
    fieldName: 'dayId',
    minValue: timePeriod.startDayId,
    maxValue: timePeriod.endDayId // include current week
  };

  return new Promise((resolve) => {
    store.dispatch(
      fetchEntityMetrics(
        dataKey,
        {
          entity,
          retailer,
          app,
          indexName: 'adOptimizationHistory',
          customResponseParser: (res: any) => {
            const count = _get(
              res,
              ['apiResponse', 'data', 0, 'aggregations', `by_${groupByField.name}`, 0, 'count'],
              0
            ) as number;

            resolve(count);
            return {
              count
            };
          }
        },
        [
          {
            doAggregation: true,
            aggregations: [
              {
                aggregationFields,
                conditions: {
                  termFilters: [
                    { fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] },
                    ...(isAdAuditUser
                      ? [
                          {
                            fieldName: 'changeSource',
                            condition: 'should',
                            values: ['external']
                          }
                        ]
                      : [])
                  ],
                  rangeFilters: [timePeriodRangeFilter]
                },
                groupByFieldName: groupByField.name
              }
            ],
            conditions: {
              termFilters: conditions.termFilters,
              rangeFilters: [timePeriodRangeFilter]
            },
            pageSize: 20
          }
        ]
      )
    );
  }) as Promise<number>;
};

const mkSimpleValueFormatter =
  (formatValue: (value: any) => string): AGGridValueFormatter =>
  ({ data, colDef }) =>
    formatValue(data[colDef.name!]);

const changeSourceDescriptionMap: any = {
  manualInAdManager: 'manually through Drive',
  automationV2: 'by Drive automation',
  external: 'outside of Drive',
  scheduledAction: 'through a Drive scheduled action'
};
const changeActionDescriptionMap: any = {
  increase: 'increased',
  decrease: 'decreased',
  pause: 'paused',
  enable: 'enabled',
  changed: 'changed'
};

const buildChangeReason = ({
  changeActionDescription,
  changeReason,
  changeSource,
  changeType
}: ChangeHistoryDoc): string => {
  if (changeSource === 'automationV2') {
    return changeReason;
  }
  const origin = changeSourceDescriptionMap[changeSource] || changeSource;
  const action = changeActionDescriptionMap[changeActionDescription] || changeActionDescription;
  return `${changeType} ${action} ${origin}`;
};

const buildGridRendererFields = (app: ReduxStore['app'], indexName: string, activeTabIx: number) => {
  const { retailer } = store.getState();
  const fieldDefs: [string, Partial<MetricField>][] = [];
  const fieldDefsOpts = [
    [
      'searchKeyword',
      {
        displayName: 'Target',
        width: 300,
        cellRendererFramework: TargetColumn,
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        headerClass: 'align-left'
      }
    ],
    [
      'campaignName',
      {
        width: 300,
        gridSize: 'minmax(0, 460px)',
        cellRendererFramework: CampaignColumn,
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        headerClass: 'align-left'
      }
    ],
    [
      'campaignName',
      {
        displayName: 'Name',
        width: 300,
        gridSize: 'minmax(0, 460px)',
        cellRendererFramework: ManualColumn,
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        headerClass: 'align-left'
      }
    ]
  ];
  fieldDefs.push(fieldDefsOpts[activeTabIx]);

  if (activeTabIx === 0) {
    fieldDefs.push([
      'matchingType',
      {
        displayName: 'Match Type',
        valueFormatter: (val) => {
          if (val.data) {
            if (val.data.matchingType) {
              return _capitalize(val.data.matchingType);
            }
          }
          return '';
        },
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        headerClass: 'align-left',
        width: 80
      }
    ]);
  }

  fieldDefs.push(
    [
      'changeActionDescription',
      {
        cellRendererFramework: withDisplayName('ChangeReasonCell')(({ data }: { data: ChangeHistoryDoc }) => {
          const actionToDisplayValueMapping: { [action: string]: string } = {
            increase: 'Increase',
            decrease: 'Decrease',
            update: 'Update',
            nochange: 'Unchanged',
            enable: 'Enabled',
            pause: 'Paused',
            paused: 'Paused',
            changed: 'Changed'
          };
          return (
            <div className="text">{`${_startCase(data.changeType)} ${
              actionToDisplayValueMapping[data.changeActionDescription]
            }`}</div>
          );
        }),
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        headerClass: 'align-left',
        width: 110,
        gridSize: '0.21fr'
      }
    ],
    [
      'oldValue',
      {
        valueFormatter: (value: any) => {
          return !Number.isNaN(+value.data.oldValue) ? DollarExactCRF(value) : value.data.oldValue;
        },
        width: 100
      }
    ],
    [
      'newValue',
      {
        valueFormatter: (value: any) => {
          return !Number.isNaN(+value.data.newValue) ? DollarExactCRF(value) : value.data.newValue;
        },
        width: 80
      }
    ],
    [
      'updatedTime',
      {
        valueFormatter: mkSimpleValueFormatter((value) => {
          const retailerTimezone = _get(retailer, 'processInTimezone', UTC_TIMEZONE);
          return `${moment.tz(value, retailerTimezone).format('MM/DD/YY')}`;
        }),
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        headerClass: 'align-left',
        width: 100
      }
    ],
    [
      'changeReason',
      {
        cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
        width: 200,
        gridSize: '0.7fr',
        headerClass: 'align-left',
        cellRendererFramework: withDisplayName('ChangeReasonCell')(({ data }: { data: ChangeHistoryDoc }) => (
          <div className="change_reason_cell">
            <div className="text">{buildChangeReason(data)}</div>
            <span className="cell_tooltip">{buildChangeReason(data)} </span>
          </div>
        ))
      }
    ]
  );

  const defaultValueFormatter: AGGridValueFormatter = ({ data, colDef }) => {
    return data[colDef.name!];
  };

  return fieldDefs.map(([fieldName, fieldOverrides = {}]) => ({
    ...INDEX_FIELDS.getField(app.name, indexName, fieldName, 'adOptimizationHistoryItem'),
    valueFormatter: defaultValueFormatter,
    ...fieldOverrides
  }));
};

const mapStateToProps = ({
  app,
  entityService: { mainEntity },
  retailer,
  allWeekIdsByRetailerId,
  mainTimePeriod,
  user
}: ReduxStore) => ({
  app,
  mainEntity,
  retailer,
  allWeekIdsByRetailerId,
  mainTimePeriod,
  user
});

const GridRow = styled.div(({ style }) => ({
  display: 'grid',
  gridTemplateColumns: style.gridTemplateColumns,
  gridColumnGap: 10,
  alignContent: 'center',
  '&.sl-grid-row-root:hover': {
    backgroundColor: '#f6f9fc'
  },
  '& .sl-grid-row-root:hover': {
    backgroundColor: '#f6f9fc'
  },
  ...style
}));
const GridCell = styled('div')({
  textAlign: 'left',
  flex: 1
});

const VirtualizedGrid = ({
  gridRendererFields,
  activeTabIx,
  dataSetByTab
}: {
  activeTabIx: number;
  dataSetByTab: any[];
  gridRendererFields: any[];
}) => {
  const rowData = dataSetByTab[activeTabIx];

  const getGridTemplateColumns = () => {
    // Generate a string for grid-template-columns with width for each column
    return gridRendererFields
      .map((col) => {
        if (col && col.gridSize) {
          return `${col.gridSize}`;
        }
        if (col && col.width) {
          return `${col.width + 20}px`;
        }
        return '1fr';
      })
      .join(' ');
  };

  // Row renderer for the virtualized list
  const rowRenderer = ({ key, index, style }) => {
    const item = rowData[index];

    return (
      <GridRow
        className="sl-grid-row-root"
        key={key}
        style={{ ...style, gridTemplateColumns: getGridTemplateColumns() }}
      >
        {gridRendererFields.map((column) => {
          const valueFormatted = column.valueFormatter
            ? column.valueFormatter({ data: item, colDef: column })
            : item[column.name];
          const CellRendererFramework = column.cellRendererFramework;
          const content = CellRendererFramework ? (
            <CellRendererFramework
              style={{ width: column.width }}
              data={item}
              valueFormatted={valueFormatted}
              {...column}
            />
          ) : (
            <GridCell style={{ width: column.width }} key={column.name}>
              {valueFormatted}
            </GridCell>
          );
          return (
            <div key={column.name} style={{ ...column.cellStyle, width: column.gridSize ? 'unset' : column.width }}>
              {content}
            </div>
          );
        })}
      </GridRow>
    );
  };

  function Header() {
    return (
      <GridRow
        style={{
          fontWeight: 'bold',
          padding: '20px 0 10px 0',
          borderBottom: '1px solid #f6f9fc',
          marginBottom: 15,
          gridTemplateColumns: getGridTemplateColumns()
        }}
      >
        {gridRendererFields.map((column) => (
          <GridCell
            className={`header-cell ${column.headerClass || ''}`}
            style={{ width: column.width }}
            key={column.displayName}
          >
            {column.displayName}
          </GridCell>
        ))}
      </GridRow>
    );
  }

  return (
    <div>
      <Header />
      <AutoSizer>
        {({ width }) => (
          <List
            width={width + 10}
            height={890} // or use a dynamic height based on the container
            rowCount={rowData.length}
            rowHeight={50} // Adjust based on your row height
            rowRenderer={rowRenderer}
            overscanRowCount={10}
          />
        )}
      </AutoSizer>
    </div>
  );
};

type AdOptimizationHistoryProps = WidgetProps & ReturnType<typeof mapStateToProps> & { eventBus: EventBus };

const AdOptimizationHistory: React.FunctionComponent<AdOptimizationHistoryProps> = ({
  app,
  mainEntity,
  eventBus,
  retailer,
  mainTimePeriod,
  user
}) => {
  const indexName = 'adOptimizationHistory';

  const documentsFetched = useRef(false);
  const countFetched = useRef(false);
  const [activeTabIx, setActiveTabIx] = useState(0);
  const [counts, setCounts] = useState([0, 0]);

  const [sidebarFilters, setSidebarFilters] = useState<any[]>([]);
  const [currentFormData, setFormData] = useState({});

  const DATA_KEY = `adOptimizationHistory-grid-${activeTabIx}`;

  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);

  const typeName = typeDisplayNameMapping(mainEntity!.type);

  const subTitleText = isAdAuditUser
    ? 'The table below displays all optimization changes Stackline has identified.'
    : "The table below displays changes made by Stackline's algorithm or done manually.";

  const renderExistingFilter = () => {
    const advancedSearchSideBarWidget = buildExistingSideBar();
    advancedSearchSideBarWidget.data.useInternalState = true;
    advancedSearchSideBarWidget.data.isUnderComponent = true;
    const ExistingSearchSidebar = withProps({
      formDataOverride: currentFormData,
      widget: advancedSearchSideBarWidget,
      filterDefinitionOverride: activeTabIx === 0 ? 'optimizationTargets' : 'optimizationCampaigns'
    })(AdManagerSearchSideBar);

    if (activeTabIx === 2) {
      return null;
    }
    return (
      <>
        <ExistingSearchSidebar />
        <PillFiltersOptimization />
      </>
    );
  };

  const sideBarFilterUpdated = ({ formData: newFormData }: { formData: any }) => {
    const filters: any = [];
    let keys = ['searchKeyword', 'searchKeywordFuzzy']; // default: keys for activeTabIx === 0
    if (activeTabIx === 1) {
      keys = ['campaignName', 'campaignNameFuzzy'];
    }

    keys.forEach((key) => {
      if (newFormData.termFilters.searchKeyword) {
        filters.push({
          values: newFormData.termFilters[key].values.map((val: { i: any }) => val.i),
          condition: newFormData.termFilters[key].condition,
          fieldName: key
        });
      }
    });

    setFormData(newFormData);
    setSidebarFilters(filters);
  };

  useBus(eventBus, AdManagerEvents.ENTITY_EVENT_METRICS_GRID_FILTER_UPDATED, sideBarFilterUpdated);

  useEffect(() => {
    // Clear filters on tab change
    documentsFetched.current = false;
  }, [activeTabIx]);

  useDeepCompareEffect(() => {
    const timePeriodRangeFilter: RangeFilter = {
      fieldName: 'dayId',
      minValue: mainTimePeriod.startDayId,
      maxValue: mainTimePeriod.endDayId // include current week
    };
    const groupByFieldForOptimizationChangeType = INDEX_FIELDS.getField(
      app.name,
      'adOptimizationHistory',
      'changeDataType'
    );

    const metricFields = [
      {
        ...INDEX_FIELDS.getField(app.name, 'adOptimizationHistory', 'changeAction')
      }
    ];
    const [{ aggregations: aggregationFields }] = buildAggregations(metricFields);
    // Fetch the counts
    const fetchCounts = async () => {
      const termFiltersByUser = isAdAuditUser
        ? [
            {
              fieldName: 'changeType',
              condition: 'should',
              values: ['bid', 'budget']
            },
            {
              fieldName: 'changeSource',
              condition: 'should',
              values: ['manualInAdManager', 'external']
            }
          ]
        : [
            {
              fieldName: 'changeDataType',
              condition: 'should',
              values: ['keyword', 'target', 'campaign']
            },
            {
              fieldName: 'changeSource',
              condition: 'should',
              values: ['automationV2', 'scheduledAction']
            }
          ];
      const targetCampaignCount = await store.dispatch(
        fetchEntityMetrics(
          `${DATA_KEY}_targetCampaign`,
          {
            entity: mainEntity,
            retailer,
            app,
            indexName,
            // We simply grab the raw documents since there are no aggregations
            customResponseParser: (res: any) => {
              const countByChangeType = _get(
                res,
                ['apiResponse', 'data', 0, 'aggregations', `by_${groupByFieldForOptimizationChangeType.name}`],
                []
              ) as any[];
              const bidTargetOptimizationsAggregationResult = countByChangeType.find((x) => x.fieldId === 'target') || {
                count: 0
              };
              const bidKeywordOptimizationsAggregationResult = countByChangeType.find(
                (x) => x.fieldId === 'keyword'
              ) || {
                count: 0
              };

              const budgetOptimizationsAggregationResult = countByChangeType.find((x) => x.fieldId === 'campaign') || {
                count: 0
              };
              return [
                bidKeywordOptimizationsAggregationResult.count + bidTargetOptimizationsAggregationResult.count,
                budgetOptimizationsAggregationResult.count
              ];
            }
          },
          [
            {
              sortFilter: {
                sortFields: [
                  {
                    fieldName: 'updatedTime',
                    direction: 'desc'
                  }
                ]
              },
              conditions: {
                termFilters: [
                  ...buildConditionsForEntity(mainEntity).termFilters!,
                  ...termFiltersByUser,
                  ...sidebarFilters
                ],
                rangeFilters: [timePeriodRangeFilter]
              },
              pageSize: PAGE_SIZE,
              returnDocuments: false,
              doAggregation: true,
              aggregations: [
                {
                  aggregationFields,
                  conditions: {
                    termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
                    rangeFilters: [timePeriodRangeFilter]
                  },
                  groupByFieldName: groupByFieldForOptimizationChangeType.name
                }
              ]
            }
          ],
          null,
          true
        )
      );

      const manualCounts = await store.dispatch(
        fetchEntityMetrics(
          `${DATA_KEY}_manual`,
          {
            entity: mainEntity,
            retailer,
            app,
            indexName,
            // We simply grab the raw documents since there are no aggregations
            customResponseParser: (res: any) => {
              const countByChangeType = _get(
                res,
                ['apiResponse', 'data', 0, 'aggregations', `by_${groupByFieldForOptimizationChangeType.name}`],
                []
              ) as any[];
              let count = 0;
              countByChangeType.forEach((item) => {
                count += item.count;
              });
              return [count];
            }
          },
          [
            {
              sortFilter: {
                sortFields: [
                  {
                    fieldName: 'updatedTime',
                    direction: 'desc'
                  }
                ]
              },
              conditions: {
                termFilters: [
                  ...buildConditionsForEntity(mainEntity).termFilters!,
                  ...(isAdAuditUser ? adAuditUserTermFiltersForTab[2] : termFiltersForTab[2]),
                  ...sidebarFilters
                ],
                rangeFilters: [timePeriodRangeFilter]
              },
              pageSize: PAGE_SIZE,
              returnDocuments: false,
              doAggregation: true,
              aggregations: [
                {
                  aggregationFields,
                  conditions: {
                    termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
                    rangeFilters: [timePeriodRangeFilter]
                  },
                  groupByFieldName: groupByFieldForOptimizationChangeType.name
                }
              ]
            }
          ],
          null,
          true
        )
      );
      setCounts([...targetCampaignCount, ...manualCounts]);
    };

    fetchCounts();

    countFetched.current = false;
  }, [mainTimePeriod.startDayId, mainTimePeriod.endDayId, mainTimePeriod.id, sidebarFilters, activeTabIx]);

  const id = `${DATA_KEY}-${mainEntity.id}`;
  const searchType = `${app.apiAppName}-${indexName}`;
  const builder = new AdvancedSearchRequestBuilder(id, searchType);
  builder.setRetailerId(retailer.id).setPageNumber(1).setPageSize(1200).setPeriod('year').setSearchBy('parent');

  const request = useMemo(() => {
    const timePeriodRangeFilter: RangeFilter = {
      fieldName: 'dayId',
      minValue: mainTimePeriod.startDayId,
      maxValue: mainTimePeriod.endDayId // include current week
    };
    const groupByFieldForOptimizationChangeType = INDEX_FIELDS.getField(
      app.name,
      'adOptimizationHistory',
      'changeDataType'
    );

    const metricFields = [
      {
        ...INDEX_FIELDS.getField(app.name, 'adOptimizationHistory', 'changeAction')
      }
    ];
    const [{ aggregations: aggregationFields }] = buildAggregations(metricFields);

    const requestBody = {
      sortFilter: {
        sortFields: [
          {
            fieldName: 'updatedTime',
            direction: 'desc'
          }
        ]
      },
      conditions: {
        termFilters: [
          ...buildConditionsForEntity(mainEntity).termFilters!,
          ...(isAdAuditUser ? adAuditUserTermFiltersForTab[activeTabIx] : termFiltersForTab[activeTabIx]),
          ...sidebarFilters
        ],
        rangeFilters: [timePeriodRangeFilter]
      },
      pageSize: PAGE_SIZE,
      returnDocuments: true,
      doAggregation: false,
      aggregations: [
        {
          aggregationFields,
          conditions: {
            termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
            rangeFilters: [timePeriodRangeFilter]
          },
          groupByFieldName: groupByFieldForOptimizationChangeType.name
        }
      ]
    };

    builder.setRetailerId(retailer.id).setPageNumber(1).setPageSize(1200).setPeriod('year').setSearchBy('parent');

    return [_merge(builder.build(), requestBody)];
  }, [
    mainTimePeriod.startDayId,
    mainTimePeriod.endDayId,
    app.name,
    mainEntity,
    isAdAuditUser,
    activeTabIx,
    sidebarFilters,
    retailer.id,
    builder
  ]);

  const { data: tableDataCached, isLoading } = useGenericAdvancedSearch({
    requestId: 'adOptimizationHistory',
    requestBody: request,
    staleTime: 12 * 60 * 1000,
    // shouldPerformFetch: false,
    queryKeys: [DATA_KEY, request]
  });

  // TODO: Finish caching for counts
  // /** TARGET COUNTS */
  // const keyTargets = `${DATA_KEY}_targetCampaign`;

  // const builder1 = new AdvancedSearchRequestBuilder(id, searchType);
  // builder1.setRetailerId(retailer.id).setPageNumber(1).setPageSize(1200).setPeriod('year').setSearchBy('parent');
  // const requestBody1 = [
  //   {
  //     sortFilter: {
  //       sortFields: [
  //         {
  //           fieldName: 'updatedTime',
  //           direction: 'desc'
  //         }
  //       ]
  //     },
  //     conditions: {
  //       termFilters: [...buildConditionsForEntity(mainEntity).termFilters!, ...termFiltersByUser, ...sidebarFilters],
  //       rangeFilters: [timePeriodRangeFilter]
  //     },
  //     pageSize: PAGE_SIZE,
  //     returnDocuments: false,
  //     doAggregation: true,
  //     aggregations: [
  //       {
  //         aggregationFields,
  //         conditions: {
  //           termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
  //           rangeFilters: [timePeriodRangeFilter]
  //         },
  //         groupByFieldName: groupByFieldForOptimizationChangeType.name
  //       }
  //     ]
  //   }
  // ];
  // const requestTarget = [_merge(builder1.build(), requestBody1)];

  // const { data: targetCampaignCount } = useGenericAdvancedSearch({
  //   requestId: keyTargets,
  //   requestBody: request,
  //   staleTime: 12 * 60 * 1000,
  //   // shouldPerformFetch: false,
  //   queryKeys: [keyTargets, requestTarget]
  // });

  // const parseTargetData = (res) => {
  //   const countByChangeType = _get(
  //     res,
  //     ['apiResponse', 'data', 0, 'aggregations', `by_${groupByFieldForOptimizationChangeType.name}`],
  //     []
  //   ) as any[];
  //   const bidTargetOptimizationsAggregationResult = countByChangeType.find((x) => x.fieldId === 'target') || {
  //     count: 0
  //   };
  //   const bidKeywordOptimizationsAggregationResult = countByChangeType.find((x) => x.fieldId === 'keyword') || {
  //     count: 0
  //   };

  //   const budgetOptimizationsAggregationResult = countByChangeType.find((x) => x.fieldId === 'campaign') || {
  //     count: 0
  //   };
  //   return [
  //     bidKeywordOptimizationsAggregationResult.count + bidTargetOptimizationsAggregationResult.count,
  //     budgetOptimizationsAggregationResult.count
  //   ];
  // };

  // const targetCount = useMemo(() => {
  //   const parsed = parseTargetData(targetCampaignCount);
  //   return parsed;
  // }, [targetCampaignCount]);
  // /** TARGET END */

  const parseData = (_data) => {
    const documents = _get(_data, ['data', 0, 'documents'], []);
    return documents;
  };

  const dataForTable = useMemo(() => {
    const parsed = parseData(tableDataCached);
    return parsed;
  }, [tableDataCached]);

  const { bidPriceItems, budgetAllocationItems, manualItems, tabDefinitions } = useMemo(() => {
    // eslint-disable-next-line no-shadow
    const { bidPriceItems, budgetAllocationItems, manualItems } =
      dataForTable && Array.isArray(dataForTable)
        ? dataForTable.reduce(
            (acc, datum) => {
              if (datum.changeDataType === 'keyword') {
                acc.bidPriceItems.push(datum);
              } else if (datum.changeDataType === 'target') {
                acc.bidPriceItems.push(datum);
              } else if (datum.campaignName) {
                acc.budgetAllocationItems.push(datum);
              }
              acc.manualItems.push(datum);
              return acc;
            },
            {
              bidPriceItems: [] as OptimizationHistoryItem[],
              budgetAllocationItems: [] as OptimizationHistoryItem[],
              manualItems: [] as OptimizationHistoryItem[]
            }
          )
        : { bidPriceItems: null, budgetAllocationItems: null, manualItems: null };

    // eslint-disable-next-line no-shadow
    const tabDefinitions = [
      {
        displayName: `${isAdAuditUser ? 'Bids' : 'Targets'} (${numeral(counts[0]).format('1,000a')})`
      },
      {
        displayName: `${isAdAuditUser ? 'Budgets' : 'Campaigns'} (${numeral(counts[1]).format('1,000a')})`
      },
      {
        displayName: `${isAdAuditUser ? 'Status' : 'Manual'} (${numeral(counts[2]).format('1,000a')})`
      }
    ];

    return { tabDefinitions, bidPriceItems, budgetAllocationItems, manualItems };
  }, [dataForTable, counts, isAdAuditUser]);

  const gridRendererFields: MetricField[] = useMemo(
    () => buildGridRendererFields(app, indexName, activeTabIx),
    [app, activeTabIx]
  );

  const dataSetByTab = [bidPriceItems, budgetAllocationItems, manualItems];

  const isManual = useMemo(() => {
    if (['client', 'adEntity'].includes(_get(mainEntity, ['type']))) {
      return false;
    }
    const automationStrategy = _get(mainEntity, ['extendedAttributes', 'automationAttributes', 'strategyId']);
    return !automationStrategy || automationStrategy === 'manual';
  }, [mainEntity]);

  const renderNoOptimizationsMessage = () => {
    if (isAdAuditUser) {
      const optimizationTypes = ['bid optimizations', 'budget optimizations', 'status changes'];
      const optimizationType =
        activeTabIx < optimizationTypes.length ? optimizationTypes[activeTabIx] : 'optimizations';
      return <AdAuditNoOptimizations optimizationType={optimizationType} typeName={typeName} />;
    } else if (isManual) {
      return <ManualBiddingNoOptimizations typeName={typeName} />;
    }
    return <OptimizationFailure typeName={typeName} />;
  };

  useEffect(() => {
    documentsFetched.current = false;
    eventBus.emit('setSearchHeader', {
      isLoading: false,
      entity: {
        result: {
          documents: dataForTable,
          apiRequest: request
        }
      }
    });
  }, [eventBus, dataForTable, request]);

  return (
    <div className="recommendations_container">
      <div className="header">Optimization History</div>
      <div className="sub_header" style={{ marginBottom: 20 }}>
        {subTitleText}
      </div>
      <div className="sub_header">
        <div className="filter_area"> {renderExistingFilter()}</div>
      </div>
      <>
        <Tabs
          value={activeTabIx}
          tabStyle={custom_style.tabStyle}
          tabs={tabDefinitions}
          onTabChange={(_event, tabIx: number) => {
            setFormData({});
            setSidebarFilters([]);
            setActiveTabIx(tabIx);
          }}
        />
        <div className="ad-optimization-history">
          {isLoading ? (
            <GridLoading />
          ) : !_isEmpty(dataSetByTab[activeTabIx]) ? (
            <VirtualizedGrid
              gridRendererFields={gridRendererFields}
              activeTabIx={activeTabIx}
              dataSetByTab={dataSetByTab}
            />
          ) : (
            renderNoOptimizationsMessage()
          )}
        </div>
      </>
    </div>
  );
};

const EnhancedAdOptimizationHistory = connect(mapStateToProps)(withBus('eventBus')(AdOptimizationHistory));

export default EnhancedAdOptimizationHistory;
