import { BrandEntity, Conditions } from 'sl-api-connector/types';

import { prop } from 'src/utils/fp';

const buildAllBrandsEntity = (brands: BrandEntity[]) => ({
  brandName: 'All Brands',
  name: 'All Brands',
  brandId: 0,
  id: 0,
  displayName: `All Brands (${brands.length})`,
  conditions: {
    termFilters: [
      {
        fieldName: 'brandId',
        values: brands.map(prop('brandId'))
      }
    ]
  },
  type: 'brand'
});

const parseBrand = (brand: BrandEntity) => ({
  ...brand,
  type: 'brand',
  id: brand.brandId,
  displayName: brand.name,
  conditions: {
    termFilters: [
      {
        fieldName: 'brandId',
        values: [brand.brandId]
      }
    ]
  } as Conditions
});

const parseBrandsFollowed = (brands: BrandEntity[]) => [
  buildAllBrandsEntity(brands),
  ...brands.map(parseBrand).sort((a, b) => a.name.localeCompare(b.name))
];

export default parseBrandsFollowed;
