import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import Loading from 'src/components/common/Loading';
import { Filters } from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeManagementDashboard';
import {
  buildRequestForBoxFilterMetricsData,
  MetricRequestParameters
} from 'src/components/ShortageDisputes/requestBuilders';
import _get from 'lodash/get';
import { initialFilters, parseUrlIntoFilters } from 'src/components/ShortageDisputes/utils';
import { useAppSelector } from 'src/utils/Hooks';

interface BoxFilterProps {
  title: string;
  config: BoxFilterConfig;
  currentFilters: Filters;
  disabled: boolean;
  index: number;
  handleFilterChange: (payload: BoxFilterPayload, isBoxFilter?: boolean) => void;
}

interface BoxFilterControlProps {
  handleFilterChange: (payload: BoxFilterPayload, isBoxFilter?: boolean) => void;
  location: any;
  disabled: boolean;
}

export interface BoxFilterConfig {
  title: string;
  stage: string;
  status: string;
}

export interface BoxFilterPayload {
  stage: string;
  status: string;
}

const boxFilterConfigs: BoxFilterConfig[] = [
  { title: 'New Shortage - Need Supporting Documents', stage: 'New Shortage', status: 'Needs Supporting Documents' },
  { title: 'New Shortage - Ready to Submit', stage: 'New Shortage', status: 'Ready to Submit' },
  { title: 'Contact Us Case - Ready to Submit', stage: 'Contact Us Case', status: 'Ready to Submit' },
  { title: 'Contact Us Case - Needs Action', stage: 'Contact Us Case', status: 'Needs Action' },
  { title: 'Resolved - Won', stage: 'Resolved', status: 'Won' },
  { title: 'Resolved - Lost', stage: 'Resolved', status: 'Lost' },
  { title: 'Resolved - Partially Won', stage: 'Resolved', status: 'Partially Won' },
  { title: 'Disputes Submitted', stage: 'Initial Dispute', status: 'Pending' }
];

const styles = {
  active: {
    border: '2px solid #f6f9fd',
    backgroundColor: '#f6f9fd',
    borderRadius: '6px',
    padding: '17px',
    width: '280px',
    height: '120px'
  },
  inactive: { border: '2px solid #f6f9fd', borderRadius: '6px', padding: '17px', width: '280px', height: '120px' },
  title: {
    textTransform: 'uppercase',
    color: '#7e8fa8',
    height: '65%',
    fontWeight: '500',
    fontSize: '16px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  mainValue: {
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#052849'
  }
};

export const fetchBoxFilterMetrics = async ({ metricDataRequestParameters, requestConfig }) => {
  const metricDataRequest = buildRequestForBoxFilterMetricsData(metricDataRequestParameters);
  const metrics = await axios.post(
    `api/beacon/AdvancedSearch?_id=top-metrics-request`,
    metricDataRequest,
    requestConfig
  );
  return metrics;
};

const BoxFilter: React.FC<BoxFilterProps> = ({
  index,
  handleFilterChange,
  title,
  config,
  currentFilters,
  disabled
}) => {
  const [metricValue, setMetricValue] = useState(0);
  const retailer = useAppSelector((state) => state.retailer);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const { startWeek, endWeek } = mainTimePeriod;
  const { id } = retailer;
  const cancelSource = useRef(axios.CancelToken.source());

  const requestConfig = {
    cancelToken: cancelSource.current.token
  };

  const metricDataRequestParameters: MetricRequestParameters = {
    retailerId: id,
    metricName: 'count',
    stage: [config.stage],
    status: [config.status],
    minWeekId: startWeek,
    maxWeekId: endWeek
  };

  const { isLoading, data } = useQuery(
    ['fetchBoxFilterMetrics', index, startWeek, endWeek],
    () => fetchBoxFilterMetrics({ metricDataRequestParameters, requestConfig }),
    {
      retry: 0,
      staleTime: 60 * 10 * 1000,
      refetchOnWindowFocus: false
    }
  );

  const getMetricData = () => {
    if (data) {
      const realMetricValue = _get(data, 'data[0].aggregations.by_retailerId[0].value', 0);
      setMetricValue(realMetricValue);
    }
  };

  useEffect(() => {
    return () => {
      cancelSource.current.cancel('Request cancelled by user');
    };
  }, []);

  useEffect(() => {
    getMetricData();
  }, [data, isLoading]);

  const handleClick = (e) => {
    e.preventDefault();
    if (!disabled) {
      const payload = { stage: config.stage, status: config.status };
      handleFilterChange(payload as BoxFilterPayload, true);
    }
  };

  const [firstPart, secondPart] = title.split(' - ');
  const isSelected =
    currentFilters.stage.toLowerCase() === config.stage.toLowerCase() &&
    currentFilters.status.toLowerCase() === config.status.toLowerCase();
  return (
    <div
      className={`single_legend_cell `}
      style={isSelected ? styles.active : styles.inactive}
      role="button"
      onClick={(evt) => handleClick(evt)}
    >
      <div className="single_legend_title" style={styles.title}>
        {firstPart} {secondPart ? '-' : null} {firstPart.length > 10 ? <br /> : null}
        {secondPart}
      </div>
      <div className="single_legend_data" style={{ padding: '0' }}>
        {isLoading ? (
          <Loading
            style={{
              position: 'relative',
              display: 'flex',
              height: '35px',
              width: '35px',
              left: '0',
              transform: 'translate(0,0)',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            className="spinner"
            size={20}
            thickness={2}
          />
        ) : (
          <div className="main_value" style={styles.mainValue}>
            {metricValue}
          </div>
        )}
      </div>
    </div>
  );
};

const BoxFilterControls: React.FC<BoxFilterControlProps> = ({ handleFilterChange, location, disabled }) => {
  const searchParameters = new URLSearchParams(location.search);
  const currentFilters = parseUrlIntoFilters(searchParameters, initialFilters);

  const renderBoxFilters = useCallback(() => {
    return boxFilterConfigs.map((config, index) => (
      <BoxFilter
        index={index}
        key={index}
        title={config.title}
        handleFilterChange={handleFilterChange}
        disabled={disabled}
        config={config}
        currentFilters={currentFilters}
      />
    ));
  }, [currentFilters, disabled]);

  return (
    <>
      <div style={{ display: 'flex', width: '100%', gap: '24px', flexWrap: 'wrap', marginBottom: '130px' }}>
        {renderBoxFilters()}
      </div>
    </>
  );
};

export default BoxFilterControls;
