import { SlRow, Text, SlColumn } from '@stackline/ui';
import React from 'react';
import { styled } from '@mui/material/styles';
import { GenericStyledDialogue } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/CreateAdjustmentModal';
import { getParentBUsOfSavedSearch } from 'src/routes/HomePage/SummaryData/DeleteSavedSearchDialog';
import { useAppSelector, useHistory } from 'src/utils/Hooks';
import {
  deleteRequestedSavedSearch,
  fetchAllSavedSearch,
  updateSavedSearch
} from 'src/store/modules/segments/operations';
import { useDispatch } from 'react-redux';
import { updateUrlQueryParams } from 'src/utils/app';
import { EntityPathname } from 'src/types/application/types';
import { BusinessUnit } from 'sl-api-connector/types/savedSearch';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

const DeleteBusinessUnitDialogue = styled(GenericStyledDialogue)({
  '& .MuiPaper-root': {
    width: '500px',
    height: '180px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px `{#dedede}`',
    backgroundColor: '#fff',
    borderRadius: '8px'
  }
});

export const SegmentDeletionModal = ({
  isTopicSegment,
  segmentToDelete,
  open,
  handleClose
}: {
  isTopicSegment: boolean;
  segmentToDelete: string;
  open: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const segments = useAppSelector((state) => state.segments);

  const handleDeleteClick = async () => {
    handleClose();
    // If this segment has parent BUs that list it as a child, update them all first
    const parentBUs: BusinessUnit[] = getParentBUsOfSavedSearch(segments, segmentToDelete);
    if (parentBUs.length > 0) {
      const promises = parentBUs.map(({ id, userId, children, displayName, isTopLevel }) =>
        dispatch(
          updateSavedSearch(
            'businessunit',
            {
              children: children.filter((childId) => childId !== segmentToDelete),
              dn: displayName,
              isTopLevel
            },
            id,
            userId
          )
        )
      );
      await Promise.all(promises);
    }

    await dispatch(deleteRequestedSavedSearch('beacon', segmentToDelete));
    history.push(updateUrlQueryParams({ tab: 'summary', subtab: 'beacon' }, { path: EntityPathname.Home }));
    dispatch(fetchAllSavedSearch());
  };

  const segmentType = isTopicSegment ? 'topic' : 'segment';

  return (
    <DeleteBusinessUnitDialogue open={open} onClose={handleClose}>
      <SlColumn widths={[undefined, undefined, 'full']} spacing="md" verticalInset="mdl" horizontalInset="mdl">
        <SlRow>
          <Text variant="h4">Delete {isTopicSegment ? 'Topic' : 'Segment'}</Text>
        </SlRow>
        <Text variant="body2">
          Are you sure you want to delete this {segmentType}? Once this {segmentType} is deleted, it cannot be
          recovered.
        </Text>
        <SlRow spacing="md" horizontalPosition="end">
          <SlButton onClick={handleClose}>Cancel</SlButton>
          <SlButton onClick={handleDeleteClick} variant="contained">
            Delete
          </SlButton>
        </SlRow>
      </SlColumn>
    </DeleteBusinessUnitDialogue>
  );
};
