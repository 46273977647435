import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import queryString from 'qs';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _prop from 'lodash/property';
import './Suggestions.scss';

class Suggestions extends React.Component {
  static defaultProps = {
    className: 'suggestions',
    hintText: null,
    textFieldStyle: { height: 56 },
    autoFocus: false,
    acceptEmptySubmit: false,
    segmentCategories: [],
    autocompleteAppNameOverride: null,
    value: undefined,
    onChange: undefined
  };

  static propTypes = {
    app: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    apiUrl: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    retailer: PropTypes.object.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    hintText: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    textFieldStyle: PropTypes.object,
    autoFocus: PropTypes.bool,
    acceptEmptySubmit: PropTypes.bool,
    segmentCategories: PropTypes.array,
    autocompleteAppNameOverride: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  };

  state = {
    searchText: '',
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.suggestionAutocompleteElement = createRef();
  }

  getId = (_source, type, _id) => {
    if (type === 'brand') {
      return _get(_source, 'brandId', _id);
    }

    if (type === 'parentbrand') {
      return _get(_source, 'parentBrandId', _id);
    }

    if (type === 'product') {
      return _get(_source, 'id', _id);
    }

    return _id;
  };

  handleUpdateInput = (term) => {
    const { app, apiUrl, type, categories, retailer, segmentCategories, autocompleteAppNameOverride, onChange } =
      this.props;
    const queryStringCategories = categories.length > 50 ? [0] : categories.map(_prop('categoryId'));
    const queryStringSubCategories = segmentCategories.length > 50 ? [0] : segmentCategories.map(_prop('i'));
    const categoryIdsSelection =
      type === 'subcategory' && segmentCategories.length > 0 ? queryStringSubCategories : queryStringCategories;

    if (onChange) {
      onChange(term);
    }

    if (term === '') {
      this.setState({ searchText: '' });
      return;
    }

    this.setState({ searchText: term });
    clearTimeout(this.debounce);

    // I think we need to send subcategory id's in the call if the type is subcategory
    const requestUrl = `${apiUrl}${window.encodeURIComponent(term)}&retailerId=${
      retailer.id
    }&type=${type}&${queryString.stringify({
      categoryIds: categoryIdsSelection
    })}`;
    this.debounce = setTimeout(() => {
      axios.get(requestUrl).then((response) => {
        const appName =
          type === 'category' || type === 'subcategory' ? 'atlas' : autocompleteAppNameOverride || app.apiAppName;
        const result = response.data.suggest[`${appName}_${type}_suggest`];
        if (!_isEmpty(result)) {
          const suggestions = result[0].options.map(({ text, _source, _id }) => ({
            type,
            value: text,
            id: this.getId(_source, type, _id)
          }));

          this.setState({ suggestions });
        }
      });
    }, 200);
  };

  handleListItemSelection = (event, value) => {
    this.setState({
      searchText: '',
      suggestions: []
    });

    this.props.onSelectionChange(value, value.id);
  };

  handleKeyDown = (e) => {
    const { onSelectionChange } = this.props;
    if (e.key === 'Enter') {
      onSelectionChange(e.target.value, -1);
    }
  };

  render() {
    const { hintText, className, style } = this.props;
    const { searchText, suggestions } = this.state;

    return (
      <Autocomplete
        className={className}
        style={style}
        placeholder={hintText}
        onChange={(_, value) => {
          this.handleListItemSelection(_, value);
        }}
        fullWidth
        options={suggestions}
        filterOptions={(options) => options}
        getOptionLabel={(option) => option.value}
        clearIcon={<></>}
        popupIcon={<></>}
        clearOnEscape
        noOptionsText="Press Enter To Search"
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            InputProps={{
              ...params.InputProps,
              id: 'type-search',
              className
            }}
            type="text"
            placeholder={hintText}
            inputRef={this.suggestionAutocompleteElement}
            id={className}
            value={searchText}
            onChange={(event) => {
              this.handleUpdateInput(event.target.value);
            }}
            onKeyDown={this.handleKeyDown}
            sx={{
              '& .MuiInput-underline:before': {
                borderBottomColor: '#dedede'
              },
              '& .MuiInput-underline::hover:before': {
                borderBottomColor: '#dedede'
              },
              '& .MuiInputBase-root:before': {
                borderBottomColor: '#dedede'
              },
              '& .MuiInputBase-root::hover:before': {
                borderBottomColor: '#dedede'
              }
            }}
          />
        )}
      />
    );
  }
}

export default connect((state) => state, null, null, { forwardRef: true })(Suggestions);
