import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withBus } from 'react-bus';
import { EventBus } from 'src/types/utils';
import _get from 'lodash/get';
import { withRouter } from 'react-router';
import { Widget } from 'src/types/application/widgetTypes';
import Export from 'src/components/Export';
import { omniProductServiceOperations } from 'src/store/modules/omni/omniProductService';
import { PERIODS } from 'src/store/modules/omni/constants';
import ReduxStore from 'src/types/store/reduxStore';
import TileContainer from '../Tiles/TileContainer';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import { ListGrid } from 'src/components/Grids';
import {
  processGridData,
  processTableData,
  processContentScoreGridData,
  TableDataToRender,
  processContentScoreTableData,
  processContentAccuracyTileData,
  processContentAccuracyTableData,
  firstColumnWidth,
  EntityTableName,
  omniTableFirstColumn
} from 'src/components/EntityGrid/EntityGrid/OmniEntityGridUtils';
import { destructurePathName } from 'src/utils/urlParsing';
import { EntityColumn } from 'src/components/Grids/Data/ColumnTypes';
import CustomAgGridHeaderTemplate from 'src/components/EntityGrid/Table/CustomAgGridHeaderTemplate';
import MultiLineHCF from 'src/components/EntityGrid/HeaderComponentFrameworks/MultiLineHCF';
import { AgGridReact } from 'ag-grid-react';
import EmptyColumn from 'src/components/AdManager/Search/CustomColumns/EmptyColumn';
import SimpleColumn from 'src/components/AdManager/Search/CustomColumns/SimpleColumn';
import OmniProductCellFormatter from 'src/components/EntityGrid/Table/OmniProductCellFormatter';
import { OmniTableField, fieldForTable } from 'src/components/EntityGrid/EntityGrid/omniGridField';
import Tabs from 'src/components/common/Tabs/Tabs';
import { OmniBaseRequestBody, addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';
import Waypoint from 'react-waypoint';
import './EntityGrid.scss';
import './OmniEntityGrid.scss';
import 'src/components/EntityGrid/Table/EntityTableContainer.scss';
import _orderBy from 'lodash/orderBy';
import {} from './OmniEntityGridUtils';
import { fetchOmniRetailerContentConfigureData } from 'src/store/modules/omni/omniRetailerContentScoreConfigure/operation';
import OmniEntityGridHeader from 'src/components/EntityGrid/Header/OmniEntityGridHeader';
import { useInstacartPageFilter } from 'src/utils/Hooks';

const { fetchOmniProductServiceData } = omniProductServiceOperations;

interface OmniEntityGridProps extends RouteComponentProps {
  widget: Widget;
  eventBus: EventBus;
}
export interface OmniEntityGridRequestBody extends OmniBaseRequestBody {
  groupBy?: string;
  aggregationMetrics?: { [key: string]: string }[];
}

// Some tables that has two data types in a cell, needs extra spaces between the row -- Design team (3/31/2023)
const twoTypesOfDataCellTables = ['contentScore', 'retailPrice'];

const DefaultGridLoading = () => (
  <div className="spinner-wrapper">
    <span className="entity-grid-loading">
      <GridLoading />
    </span>
  </div>
);

interface OmniTableContainerProps {
  dataToRender: TableDataToRender[];
  omniTableViewFieldForTableView: OmniTableField[];
  extraColumnDef?: {
    name: string;
    displayName: string;
    entity: any;
    width: number;
    cellRendererFramework: JSX.Element;
    [key: string]: any;
  };
  styles: React.CSSProperties;
  groupByField: { name: string; displayName: string };
  onClickToSort: (dataKey: string) => void;
  children?: React.ReactNode;
  distanceToFirstCol?: number;
}

const customRestColumnWidth = (ele: OmniTableField, omniTableViewFieldForTableView: OmniTableField[]) => {
  let columnWidth;
  if (ele.widthForColumn) {
    columnWidth = ele.widthForColumn;
  } else if (omniTableViewFieldForTableView.length > 7) {
    columnWidth = 100;
  } else if (ele.shareOfShelfType) {
    columnWidth = 175;
  } else {
    columnWidth = 125;
  }

  return columnWidth;
};

export const OmniTableContainer: React.FC<OmniTableContainerProps> = ({
  dataToRender,
  omniTableViewFieldForTableView,
  onClickToSort,
  groupByField,
  extraColumnDef,
  children,
  styles,
  distanceToFirstCol
}: OmniTableContainerProps) => {
  // if the cell has two data, we will give more space between rows == suggested by Design team
  const hasTwoDataInACell = twoTypesOfDataCellTables.includes(
    omniTableViewFieldForTableView && omniTableViewFieldForTableView[0].metricName
  );

  const baseColumnDef = {
    field: 'custom',
    minWidth: 150
  };

  const defaultFirstColumnDef = {
    headerName: `${groupByField.displayName}`,
    field: 'name',
    minWidth: firstColumnWidth(groupByField.name).min,
    maxWidth: firstColumnWidth(groupByField.name).max,
    cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
    headerClass: 'align-left'
  };

  const firstColumnDefBase = {
    ...baseColumnDef,
    cellRendererFramework: EntityColumn,
    cellRendererParams: { omniGroupByField: groupByField },
    cellStyle: {
      'justify-content': 'flex-start',
      'text-align': 'left',
      border: 0,
      'flex-direction': 'row'
    },
    headerClass: 'align-left'
  };

  const firstColumnDefOpt = {
    retailer: { ...firstColumnDefBase, headerName: groupByField.displayName },
    brand: { ...firstColumnDefBase, headerName: groupByField.displayName },
    category: { ...firstColumnDefBase, headerName: groupByField.displayName },
    subcategory: { ...firstColumnDefBase, headerName: groupByField.displayName },
    product: { ...firstColumnDefBase, headerName: groupByField.displayName },
    keyword: { ...defaultFirstColumnDef },
    store: { ...defaultFirstColumnDef }
  }[groupByField.name];

  const firstColumnDef = {
    ...firstColumnDefOpt,
    width: distanceToFirstCol || omniTableFirstColumn(omniTableViewFieldForTableView),
    headerComponentFramework: CustomAgGridHeaderTemplate,
    name: '',
    sortByMetric: ''
  };

  const restColumnDef = omniTableViewFieldForTableView.map((ele) => {
    let { fulfillmentType } = ele;
    if (!fulfillmentType) {
      fulfillmentType = '';
    }

    return {
      ...ele,
      name: ele.dataKey,
      field: ele.dataKey,
      cellRendererFramework: OmniProductCellFormatter,
      headerComponentFramework: MultiLineHCF,
      width: customRestColumnWidth(ele, omniTableViewFieldForTableView),
      appName: 'omni',
      cellStyle: {
        'text-align': 'right',
        'padding-right': '20px',
        display: 'flex',
        'flex-direction': 'row-reverse'
      },
      displayName: undefined,
      onSortFunction: () => {
        onClickToSort(ele.dataKey);
      }
    };
  });
  const extraColumnDefsArray = extraColumnDef ? [{ ...extraColumnDef }] : [];
  const columnDefs = [{ ...firstColumnDef }, ...restColumnDef, ...extraColumnDefsArray];

  return (
    <div className="custom-ag-material">
      <div style={styles}>
        <AgGridReact
          columnDefs={columnDefs}
          domLayout="autoHeight"
          rowData={dataToRender}
          rowHeight={hasTwoDataInACell ? 70 : 56}
          headerHeight={dataToRender && dataToRender.length > 0 ? 56 : 108}
          frameworkComponents={{ emptyColumn: EmptyColumn, simpleColumn: SimpleColumn }}
          suppressHorizontalScroll
        />
        {children}
      </div>
    </div>
  );
};

const OmniEntityGrid: React.FC<OmniEntityGridProps> = ({ widget, location, eventBus }: OmniEntityGridProps) => {
  const TABS_DISABLE_DROPDOWN = ['time', 'shareOfShelf'];
  const { name } = widget;
  const {
    aggregationMetrics,
    metricData,
    omniTableViewFieldForTableView,
    aggregationMetricsForTable,
    shouldSupportTableView,
    gridPage,
    shouldDisableClick
  } = widget.data;

  const originTabs = [
    { displayName: 'Products', id: 'topSelling' },
    { displayName: 'Increasing', id: 'increasing' },
    { displayName: 'Decreasing', id: 'decreasing' }
  ];

  const singleTabs = [{ displayName: 'Products', id: 'topSelling' }];
  const { pathname } = location;
  const [entityType, entityId] = destructurePathName(pathname);
  const [tabs, setTabs] = useState(entityType === 'product' ? singleTabs : originTabs);
  const [tabValue, setTabValue] = useState(0);
  const [currentlySelectedTab, setCurrentlySelectedTab] = useState('topSelling');
  const [page, setPage] = useState(1);
  const perPageSize = 50;
  const defaultSortingKey =
    omniTableViewFieldForTableView[0] && omniTableViewFieldForTableView[0].dataKey.includes('ShareOfShelf')
      ? omniTableViewFieldForTableView[0].dataKey
      : 'cardinalityStores';
  const [currentSortingKey, setCurrentSortingKey] = useState(defaultSortingKey);
  const [descOrder, setDescOrder] = useState(true);
  const [view, setView] = useState('tile');
  const [topSearchResults, setTopSearchResults] = useState('all');
  const dispatch = useDispatch();
  const { mainEntity } = useSelector((state: ReduxStore) => state.entityService);
  const omniRetailerContentConfigureData = useSelector(
    (state: ReduxStore) => state.omniRetailerContentConfigureService.data
  );
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const comparisonTimePeriod = useSelector((state: ReduxStore) => state.comparisonTimePeriod);
  const nameForData = `${name}_${view}`;
  const gridData = useSelector((state: ReduxStore) => state.omniProductService[nameForData]);
  const filters = useSelector((state: ReduxStore) => state.filters);
  const app = useSelector((state: ReduxStore) => state.app);
  const { tab: tabInUrl, subtab } = app.queryParams;
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const { startWeek: mainStartWeek, endWeek: mainEndWeek } = mainTimePeriod;
  const { startWeek: compareStartWeek, endWeek: compareEndWeek } = comparisonTimePeriod;
  const defaultRetailers = useSelector((state: ReduxStore) => state.omniRetailers.data);
  const retailerIds = defaultRetailers.map((selectedRetailer) => selectedRetailer.retailerId);
  const isInstacartRetailerPage = useInstacartPageFilter();
  const [selectedGroupByField, setSelectedGroupByField] = useState({
    name: 'product',
    displayName: 'Product',
    isSelected: true,
    groupByKey: entityType === 'retailer' ? 'stacklineSku' : 'gtin'
  });

  const [groupByFields, setGroupByFields] = useState([
    {
      name: 'product',
      displayName: 'Product',
      isSelected: true,
      groupByKey: entityType === 'retailer' || subtab === 'promotions' ? 'stacklineSku' : 'gtin'
    },
    {
      name: 'retailer',
      displayName: 'Retailer',
      isSelected: false,
      groupByKey: 'retailerId'
    },
    {
      name: 'brand',
      displayName: 'Brand',
      isSelected: false,
      groupByKey: 'brandId'
    },
    {
      name: 'category',
      displayName: 'Category',
      isSelected: false,
      groupByKey: 'categoryId'
    },
    {
      name: 'subcategory',
      displayName: 'Subcategory',
      isSelected: false,
      groupByKey: 'subCategoryId'
    }
  ]);

  let groupBy = 'gtin';
  if (entityType === 'retailer') {
    groupBy = 'stacklineSku';
  }
  const noNeedCompare = name === EntityTableName.CONTENT_ACCURACY && view === 'table';
  const handleChangeLayout = (isTableView: boolean) => {
    if (isTableView) {
      setTabValue(0);
      setTabs(singleTabs);
      setView('table');
    } else {
      setTabs(originTabs);
      setView('tile');
    }
    setPage(1);
  };

  const onClickToSort = (dataKey: string): void => {
    setPage(1);
    if (dataKey === currentSortingKey) {
      setDescOrder(!descOrder);
    } else {
      setDescOrder(true);
      setCurrentSortingKey(dataKey);
    }
  };

  const exportFileName = `${app.name}_data_download.csv`;

  // it will be tabInUrl === "content" with full-deploy,
  // as of Apr 6th, we only support export subTab as contentAccuracy, and content Score
  // we will add contentDensity sub tab, in very near future. until we enable the export for contentDensity, condition should be subtab

  const shouldSupportExport =
    (entityType === 'segment' && tabInUrl !== 'content' && tabInUrl !== 'review') ||
    entityType === 'retailer' ||
    subtab === 'contentAccuracy' ||
    subtab === 'contentScore';

  const fetchData = useCallback(() => {
    if (topSearchResults !== 'all') {
      aggregationMetrics[0].topSearchResults = topSearchResults;
    }

    const groupByFieldData = groupByFields.find((field) => field.isSelected);
    const baseRequestBody: OmniEntityGridRequestBody = {
      startWeekId: mainStartWeek,
      endWeekId: mainEndWeek,
      retailerIds: [],
      includeBrandIds: [],
      includeCategoryIds: [],
      includeSubCategoryIds: [],
      productIds: [],
      aggregationMetrics,
      groupBy: groupByFieldData.groupByKey
    };

    const requestBody = addFilterToOmniBaseReqBody(baseRequestBody, filters, pathname, mainEntity.query);

    if (view === 'table') {
      requestBody.aggregationMetrics = aggregationMetricsForTable;
      if (gridPage === 'keyMetric') {
        requestBody.aggregationMetrics = [
          ...aggregationMetricsForTable,
          { ...fieldForTable.pickUpInStockRate },
          { ...fieldForTable.shippingInStockRate },
          { ...fieldForTable.deliveryInStockRate }
        ];
      }

      if (name === EntityTableName.CONTENT_SCORE) {
        requestBody.detailedScores = true;
        delete requestBody.aggregationMetrics;
        dispatch(fetchOmniProductServiceData(requestBody, PERIODS.MAIN, nameForData, 'contentScoreView'));
        const requestBodyForCompare = { ...requestBody, startWeekId: compareStartWeek, endWeekId: compareEndWeek };
        dispatch(fetchOmniProductServiceData(requestBodyForCompare, PERIODS.COMPARE, nameForData, 'contentScoreView'));
      } else if (name === EntityTableName.CONTENT_ACCURACY) {
        delete requestBody.aggregationMetrics;
        dispatch(fetchOmniProductServiceData(requestBody, PERIODS.MAIN, nameForData, 'contentAccuracyView'));
      } else {
        dispatch(fetchOmniProductServiceData(requestBody, PERIODS.MAIN, nameForData, 'endPointForTableView'));

        const requestBodyForCompare = { ...requestBody, startWeekId: compareStartWeek, endWeekId: compareEndWeek };

        dispatch(
          fetchOmniProductServiceData(requestBodyForCompare, PERIODS.COMPARE, nameForData, 'endPointForTableView')
        );
      }
    } else {
      // === Tile View ==
      if (name === EntityTableName.CONTENT_SCORE) {
        requestBody.detailedScores = false;
        delete requestBody.aggregationMetrics;
        dispatch(fetchOmniProductServiceData(requestBody, PERIODS.MAIN, nameForData, 'contentScoreView'));
        const requestBodyForCompare = { ...requestBody, startWeekId: compareStartWeek, endWeekId: compareEndWeek };
        dispatch(fetchOmniProductServiceData(requestBodyForCompare, PERIODS.COMPARE, nameForData, 'contentScoreView'));
      } else if (name === EntityTableName.CONTENT_ACCURACY) {
        delete requestBody.aggregationMetrics;
        dispatch(fetchOmniProductServiceData(requestBody, PERIODS.MAIN, nameForData, 'contentAccuracyView'));
        const requestBodyForCompare = { ...requestBody, startWeekId: compareStartWeek, endWeekId: compareEndWeek };
        dispatch(
          fetchOmniProductServiceData(requestBodyForCompare, PERIODS.COMPARE, nameForData, 'contentAccuracyView')
        );
      } else {
        dispatch(fetchOmniProductServiceData(requestBody, PERIODS.MAIN, nameForData, 'commonEndPointForOmniGrid'));

        const requestBodyForCompare = { ...requestBody, startWeekId: compareStartWeek, endWeekId: compareEndWeek };

        dispatch(
          fetchOmniProductServiceData(requestBodyForCompare, PERIODS.COMPARE, nameForData, 'commonEndPointForOmniGrid')
        );
      }
    }
  }, [
    mainStartWeek,
    mainEndWeek,
    compareStartWeek,
    compareEndWeek,
    dispatch,
    filters,
    aggregationMetrics,
    nameForData,
    view,
    aggregationMetricsForTable,
    pathname,
    groupBy,
    gridPage,
    topSearchResults,
    groupByFields
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleReceiveInfoFromShareOfShelf = ({ displayName }: { displayName: string }) => {
    setPage(1);
    if (displayName && (displayName.includes('3') || displayName.includes('5') || displayName.includes('10'))) {
      const num = displayName.match(/\d+/)[0];
      setTopSearchResults(`top${num}`);
    } else {
      setTopSearchResults('all');
    }
  };

  useEffect(() => {
    if (view === 'tile') {
      eventBus.on('noticeShareOfShelveMainMetricFields', handleReceiveInfoFromShareOfShelf);
      return () => {
        eventBus.off('noticeShareOfShelveMainMetricFields', handleReceiveInfoFromShareOfShelf);
      };
    } else {
      return () => {};
    }
  }, [eventBus, view]);

  // If it is contentScoreTrendingProducts we will have to fetch dynamic content column  -based on the retailers sum
  // (e.g Meijer does not have video score)

  useEffect(() => {
    if (name === EntityTableName.CONTENT_SCORE) {
      let selectedRetailer;

      if (entityType === 'retailer') {
        selectedRetailer = [entityId];
      } else if (filters.retailer) {
        selectedRetailer = filters.retailer;
      } else {
        selectedRetailer = retailerIds;
      }

      const fetchOmniRetailerContentConfigureApiBody = { retailerIds: selectedRetailer };
      dispatch(fetchOmniRetailerContentConfigureData(fetchOmniRetailerContentConfigureApiBody));
    }
  }, [filters.retailer, name]);

  let dataToRender = [];

  const groupByFieldData = groupByFields.find((field) => field.isSelected);

  if (gridData && !gridData.isFetching && gridData.main && (gridData.compare || noNeedCompare)) {
    if (view === 'tile') {
      let formattedData: { id: any; cardView: any }[] = [];

      if (name === EntityTableName.CONTENT_SCORE) {
        formattedData = processContentScoreGridData(gridData, metricData, groupBy);
      } else if (name === EntityTableName.CONTENT_ACCURACY) {
        formattedData = processContentAccuracyTileData(gridData, metricData, groupBy);
      } else {
        formattedData = processGridData(gridData, metricData, groupBy);
      }
      dataToRender = formattedData.filter((ele) => ele.cardView[`${metricData.name}CurrentValue`] !== null);
      if (currentlySelectedTab === 'increasing') {
        dataToRender = formattedData.filter((ele) => {
          const periodChange = _get(ele, ['cardView', `${metricData.name}PeriodChange`], 0);
          return periodChange > 0;
        });
        dataToRender.sort((a, b) => {
          if (a.cardView[`${metricData.name}PeriodChange`] === b.cardView[`${metricData.name}PeriodChange`]) {
            return 0;
          }
          return a.cardView[`${metricData.name}PeriodChange`] > b.cardView[`${metricData.name}PeriodChange`] ? -1 : 1;
        });
      }
      if (currentlySelectedTab === 'decreasing') {
        dataToRender = formattedData.filter((ele) => {
          const periodChange = _get(ele, ['cardView', `${metricData.name}PeriodChange`], 0);
          return periodChange < 0;
        });
        dataToRender.sort((a, b) => {
          if (a.cardView[`${metricData.name}PeriodChange`] === b.cardView[`${metricData.name}PeriodChange`]) {
            return 0;
          }
          return a.cardView[`${metricData.name}PeriodChange`] > b.cardView[`${metricData.name}PeriodChange`] ? 1 : -1;
        });
      }
    } else {
      // Table Starts
      let formattedData: TableDataToRender[] = [];

      if (name === EntityTableName.CONTENT_SCORE) {
        formattedData = processContentScoreTableData(
          gridData,
          omniTableViewFieldForTableView,
          retailer,
          groupByFieldData.groupByKey
        );
      } else if (name === EntityTableName.CONTENT_ACCURACY) {
        formattedData = processContentAccuracyTableData(
          gridData,
          omniTableViewFieldForTableView,
          retailer,
          groupByFieldData.groupByKey
        );
      } else {
        formattedData = processTableData(
          gridData,
          omniTableViewFieldForTableView,
          retailer,
          groupByFieldData.groupByKey
        );
      }

      dataToRender = formattedData;

      if (descOrder) {
        dataToRender = _orderBy(dataToRender, `${currentSortingKey}Number`, 'desc');
      } else {
        dataToRender = _orderBy(dataToRender, `${currentSortingKey}Number`, 'asc');
      }
    }
  }

  const dataToShow = dataToRender.slice(0, page * perPageSize);
  const handleInfiniteScroll = () => {
    if (page * perPageSize < dataToRender.length) {
      setPage(page + 1);
    }
  };

  const onReclassifyChecked = () => false;
  const showStatusBar = false;
  const showPriceData = true;
  const showCardBackDeleteButton = false;
  const showOnlyBreadcrumbMismatches = false;

  // For most of the part of the table in Omni, omniTableViewFieldForTableView will handle, but content score is the only table dynamic

  let filteredTableViewFieldsForContentScore = omniTableViewFieldForTableView;

  if (name === EntityTableName.CONTENT_SCORE) {
    filteredTableViewFieldsForContentScore = omniTableViewFieldForTableView.filter((field) => {
      // keys for configureData is not exact match, so if we need to add more, the case should be handled.
      // e.g) config => health, metricName => healthScore

      const fieldName = Object.keys(omniRetailerContentConfigureData).find((key) => field.metricName.startsWith(key));
      return fieldName && omniRetailerContentConfigureData[fieldName];
    });
  }

  const mergedContentScoreTableField = [omniTableViewFieldForTableView[0], ...filteredTableViewFieldsForContentScore];

  const handleGroupByChange = (event) => {
    const selectedFieldName = event.target.value;
    if (selectedFieldName !== 'product') {
      setView('table');
      handleChangeLayout(true);
    }

    const selectedField = groupByFields.find((field) => field.name === selectedFieldName);
    const updatedSelectedField = { ...selectedField, isSlected: true };
    setGroupByFields((prev) => {
      return prev.map((field) =>
        field.name === selectedFieldName ? { ...field, isSelected: true } : { ...field, isSelected: false }
      );
    });
    setSelectedGroupByField(updatedSelectedField);
  };

  const filteredGroupByFields = isInstacartRetailerPage
    ? groupByFields
    : groupByFields.filter((field) => {
        // for product entity type, we don't have to show brand, and category subCategory => it is already attached.
        // it will just have product and retailer dropdown
        if (entityType === 'product') {
          return ['product', 'retailer'].includes(field.name);
        }
        return !entityType.includes(field.name);
      });
  return (
    <div style={{ marginTop: 80 }}>
      {!gridData || gridData.isFetching || !gridData.main || (!gridData.compare && !noNeedCompare) ? (
        <DefaultGridLoading />
      ) : (
        <div style={{ position: 'relative' }}>
          <Tabs
            tabs={tabs}
            tabRootStyle={{ fontSize: 20, fontWeight: 400 }}
            onTabChange={(_event, i) => {
              setPage(1);
              setCurrentlySelectedTab(tabs[i].id);
              setTabValue(i);
            }}
            style={{ marginTop: 80, width: 500 }}
            tabStyle={{ fontSize: 20, padding: 10, fontWeight: 400 }}
            value={tabValue}
          />
          {shouldSupportExport ? (
            <Export
              exportRequest={[]}
              apiUrl={`/api/${app.name}/AdvancedSearchExport`}
              className="omni-grid-header__action-button"
              fileName={exportFileName}
            />
          ) : null}
          <OmniEntityGridHeader
            view={view}
            handleGroupByChange={handleGroupByChange}
            groupByFields={filteredGroupByFields}
            handleChangeLayout={handleChangeLayout}
            shouldSupportTableView={shouldSupportTableView}
            shouldDisableDropDown={TABS_DISABLE_DROPDOWN.includes(tabInUrl)}
            selectedGroupByField={selectedGroupByField}
          />

          {view === 'tile' ? (
            <ListGrid
              showLoadMoreButton={false}
              className="entityGrid-list-grid"
              onWaypointEntered={() => {
                handleInfiniteScroll();
              }}
            >
              {dataToShow.map((item) => {
                return (
                  <TileContainer
                    key={item.id}
                    dataItem={item}
                    reclassifyProducts={[]}
                    onReclassifyChecked={onReclassifyChecked}
                    showStatusBar={showStatusBar}
                    showPriceData={showPriceData}
                    showCardBackDeleteButton={showCardBackDeleteButton}
                    onDelete={() => {}}
                    showOnlyBreadcrumbMismatches={showOnlyBreadcrumbMismatches}
                    shouldDisableClick={shouldDisableClick}
                    shouldShowNav={entityType === 'retailer' && tabInUrl !== 'shareOfShelf'}
                  />
                );
              })}
            </ListGrid>
          ) : (
            <OmniTableContainer
              omniTableViewFieldForTableView={
                name === EntityTableName.CONTENT_SCORE ? mergedContentScoreTableField : omniTableViewFieldForTableView
              }
              dataToRender={dataToShow}
              onClickToSort={onClickToSort}
              groupByField={selectedGroupByField}
              styles={{
                width: '100%',
                height: '100%',
                marginTop: '65px'
              }}
            >
              <Waypoint onEnter={handleInfiniteScroll} />
              <br />
              <br />
            </OmniTableContainer>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(withBus('eventBus')(OmniEntityGrid));
