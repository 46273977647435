import React from 'react';
import PropTypes from 'prop-types';
import _capitalize from 'lodash/capitalize';

const propTypes = {
  entityDefinition: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  handleEntityClick: PropTypes.func.isRequired,
  segmentButtonIcon: PropTypes.object,
  subHeaderEntitySelection: PropTypes.object,
  displayedEntities: PropTypes.array.isRequired
};

const defaultProps = {
  subHeaderEntitySelection: null,
  segmentButtonIcon: null
};

function SummarySubHeader({
  entityDefinition,
  children,
  handleEntityClick,
  segmentButtonIcon,
  subHeaderEntitySelection,
  displayedEntities
}) {
  const pageTitle = _capitalize(entityDefinition.displayNamePlural || entityDefinition.displayName);
  const nonProductTitle = entityDefinition.type !== 'product' && (
    <h1 className="summary-page__title" style={{ verticalAlign: 'middle' }}>
      {pageTitle}
    </h1>
  );
  const productTitle = entityDefinition.type === 'product' && (
    <h1 className="summary-page__title" style={{ verticalAlign: 'middle' }}>
      {pageTitle}
    </h1>
  );

  return (
    <div style={{ padding: '0 30px' }}>
      <div>
        {nonProductTitle}
        {(entityDefinition.type === 'segment' || entityDefinition.type === 'searchtermlist') && segmentButtonIcon}
      </div>
      {/* Secondary tabs */}
      {/* Dropdown menu */}
      <div style={{ display: 'flex' }}>
        <div style={{ alignSelf: 'center' }}>
          {/* This is for the showing Product Title */}
          {productTitle}
          {/*  This doesn't show up when it is the main product page because selected option is null */}
          {displayedEntities.map((entity) => (
            <div // eslint-disable-line
              key={entity.displayName}
              className={`summary-page__entity ${
                entity.displayName === subHeaderEntitySelection.displayName ? 'summary-page__entity--selected' : ''
              }`}
              onClick={() => handleEntityClick(entity)}
            >
              {entity.displayName}
            </div>
          ))}
        </div>
        {/*  This is the dropdown and icons on the header on the right side */}
        {children}
      </div>
    </div>
  );
}

SummarySubHeader.propTypes = propTypes;
SummarySubHeader.defaultProps = defaultProps;

export default SummarySubHeader;
