import React, { useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { SlRow, Text, SlColumn, SlDropdownMenuOption, SlInput, useStacklineTheme } from '@stackline/ui';
import { Box, Divider, Typography } from '@mui/material';
import { CloseIcon, EqualIcon, RightArrow, UpdatedPlusIcon } from 'src/components/SvgIcons/SvgIcons';
import { EntityRowContainer } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/EntityRowContainer';
import CalendarRangePicker from 'src/components/BeaconRedesignComponents/common/CalendarRangePicker/CalendarRangePicker';
import './createAdjustmentStyles.scss';
import { getFirstWeekIdOfYear, getLastWeekIdOfYear } from 'src/utils/dateUtils';
import { SmallCancelIcon } from 'src/components/SvgIcons';
import {
  AdjustmentPlanType,
  SubmitAdjustmentParams,
  getAdjustmentAmountValue,
  getContentScoreValues,
  parseInvalidDateResponse,
  useFetchInvalidDatesForPlanType,
  useGenerateMetricsForConfirmation
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/CreateAdjustmentModal/utils';
import _get from 'lodash/get';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { SummarizedForecastMetrics } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ViewAdjustmentModal/ViewAdjustmentModal';
import { AdjustmentSummaryMetricsLoading } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ViewAdjustmentModal/AdjustmentModalLoading';
import { SlDropdownMenu } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/input';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

export const GenericStyledDialogue = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '757px',
    height: 'auto',
    maxHeight: '641px',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dedede',
    backgroundColor: '#fff',
    borderRadius: '8px'
  },
  textarea: {
    color: '#052849 !important'
  }
});

const typographyStyle = {
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '0.8px',
  textAlign: 'left',
  color: '#052849'
};

export const InvalidDateError = ({ open }: { open: boolean }) => {
  const ref = useRef(null);
  const theme = useStacklineTheme();

  const autoScroll = () => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  };

  useEffect(() => {
    autoScroll();
  }, [open]);
  return (
    <>
      {open && (
        <SlRow spacing="sm" verticalPosition="center">
          <div ref={ref} style={{ height: theme.spacing.mdl }}>
            <SmallCancelIcon style={{ width: theme.spacing.mdl, height: theme.spacing.mdl }} />
          </div>
          <Text variant="subtitle3" color="error">
            The adjustment above conflicts with an existing adjustment during the date range you selected.
          </Text>
        </SlRow>
      )}
    </>
  );
};

export enum AdjustmentModalView {
  Create = 'Create',
  Finalize_Plan = 'FinalizePlan',
  Confirm = 'Confirm',
  View = 'View',
  Edit = 'Edit'
}

const CreateAdjustmentModalHeader = ({ onClose }: { onClose: () => void }) => {
  const theme = useStacklineTheme();

  return (
    <>
      <Flex position="relative" justifyContent="center" alignItems="center" paddingX="48px" paddingY="18px">
        <div style={{ width: theme.spacing.mdl, height: theme.spacing.mdl }}></div>
        <Text variant="h2">Create Adjustment</Text>
        <CloseIcon
          onClick={onClose}
          style={{
            position: 'absolute',
            left: '707px',
            cursor: 'pointer',
            width: theme.spacing.mdl,
            height: theme.spacing.mdl
          }}
        ></CloseIcon>
      </Flex>
      <Divider />
    </>
  );
};

export const planTypeOptions: SlDropdownMenuOption[] = [
  {
    id: AdjustmentPlanType.OrganicTraffic,
    label: 'Organic Traffic'
  },
  {
    id: AdjustmentPlanType.OtherTraffic,
    label: 'Other Traffic'
  },
  {
    id: AdjustmentPlanType.PaidTraffic,
    label: 'Paid Traffic'
  },
  {
    id: AdjustmentPlanType.RetailPrice,
    label: 'Retail Price'
  },
  {
    id: AdjustmentPlanType.ContentScore,
    label: 'Content Score'
  },
  {
    id: AdjustmentPlanType.InstockRate,
    label: 'In-Stock Rate'
  },
  {
    id: AdjustmentPlanType.RatingsReviews,
    label: 'Ratings & Reviews'
  },
  {
    id: AdjustmentPlanType.BuyBox,
    label: 'Buy Box'
  }
];

export const PlanTypeDropdownMenu = ({
  setPlanType,
  planType,
  disabled = false
}: {
  setPlanType: (id: SlDropdownMenuOption['id']) => void;
  planType: SlDropdownMenuOption['id'];
  disabled?: boolean;
}) => {
  const handleChange = (e) => {
    setPlanType(e.id);
  };

  const selectedType = useMemo(() => {
    return planTypeOptions.find((option) => option.id === planType);
  }, [planType]);

  return (
    <div style={{ minWidth: '170px' }}>
      <SlColumn horizontalInset="none" spacing="sm">
        <Text variant="subtitle2" title="Plan Type">
          Plan Type
        </Text>
        <SlDropdownMenu
          disabled={disabled} // We don't allow plan type changes when editing an adjustment
          options={planTypeOptions}
          defaultLabel={selectedType.label}
          selectedId={selectedType.id}
          onChange={handleChange}
        />
      </SlColumn>
    </div>
  );
};

export const AdjustmentTitleInput = ({
  title = '',
  setAdjustmentTitle
}: {
  title?: string;
  setAdjustmentTitle: (s: string) => void;
}) => {
  const theme = useStacklineTheme();

  const handleChange = (input: string) => {
    setAdjustmentTitle(input);
  };

  return (
    <SlColumn widths="full" horizontalInset="none" spacing="sm">
      <Text variant="subtitle2" title="Title">
        Title
      </Text>
      <SlInput
        handleChange={handleChange}
        maxLength={50}
        width="451px"
        showCharacterCount
        placeholder="Name your adjustment"
        value={title}
        sx={{ input: { color: theme.colors.primary } }}
      />
    </SlColumn>
  );
};

export const AdjustmentDescriptionInput = ({
  description = '',
  setDescription
}: {
  description?: string;
  setDescription: (s: string) => void;
}) => {
  const handleChange = (s: string) => {
    setDescription(s);
  };

  return (
    <SlColumn verticalInset="sm" widths="full" horizontalInset="none" spacing="sm">
      <Text variant="subtitle2" title="Description">
        Description
      </Text>
      <SlInput
        handleChange={handleChange}
        width="100%"
        rowCount={2}
        maxLength={250}
        showCharacterCount
        placeholder="Enter a short description"
        value={description}
      />
    </SlColumn>
  );
};

export interface InputSettings {
  min: number;
  max: number | null;
  placeholder: string;
  fixedPlaces: number;
}

export const PlanDateRange = ({
  planStartDate = null,
  planEndDate = null,
  handleDateChange,
  allConflictingWeekIds
}: {
  planStartDate?: string | number | null;
  planEndDate?: string | number | null;
  handleDateChange: (dateSelection: string[] | number[]) => void;
  allConflictingWeekIds: string[];
}) => {
  return (
    <SlColumn widths="full" horizontalInset="none" spacing="sm">
      <Text variant="subtitle2" title="Plan Change">
        Date Range
      </Text>
      <CalendarRangePicker
        planStartDate={planStartDate}
        planEndDate={planEndDate}
        allConflictingWeekIds={allConflictingWeekIds}
        handleDateChange={handleDateChange}
      />
    </SlColumn>
  );
};

export enum ContentScoreFields {
  TitleScore = 'titleScore',
  BulletScore = 'bulletScore',
  ImageScore = 'imageScore',
  VideoScore = 'videoScore',
  A_Score = 'aplusScore'
}

export interface ContentScoreAmounts {
  [ContentScoreFields.TitleScore]: string;
  [ContentScoreFields.BulletScore]: string;
  [ContentScoreFields.ImageScore]: string;
  [ContentScoreFields.VideoScore]: string;
  [ContentScoreFields.A_Score]: string;
}

const DELETE_KEY_CODE = 8;

const ContentScoreInputs = ({
  handleContentScoreChange,
  contentScoreAmounts,
  summarizedForecastMetrics
}: {
  handleContentScoreChange: any;
  contentScoreAmounts: ContentScoreAmounts;
  summarizedForecastMetrics: SummarizedForecastMetrics;
}) => {
  const theme = useStacklineTheme();
  const formatMetric = useMetricFormatter();
  // Define refs for each input
  const inputRefOne = useRef();
  const inputRefTwo = useRef();
  const inputRefThree = useRef();
  const inputRefFour = useRef();
  const inputRefFive = useRef();

  // State to track inputs that have been focused
  const [inputFocused, setInputFocused] = useState({
    [ContentScoreFields.TitleScore]: false,
    [ContentScoreFields.BulletScore]: false,
    [ContentScoreFields.ImageScore]: false,
    [ContentScoreFields.VideoScore]: false,
    [ContentScoreFields.A_Score]: false
  });

  const refMap = {
    [ContentScoreFields.TitleScore]: inputRefOne,
    [ContentScoreFields.BulletScore]: inputRefTwo,
    [ContentScoreFields.ImageScore]: inputRefThree,
    [ContentScoreFields.VideoScore]: inputRefFour,
    [ContentScoreFields.A_Score]: inputRefFive
  };

  const moveCursorBeforeSuffix = (key: ContentScoreFields) => {
    const inputElement = refMap[key].current;

    if (inputElement) {
      const { length } = inputElement.value;
      inputElement.setSelectionRange(length - 1, length - 1);
      inputElement.focus();
    }
  };
  // Function to handle input blur
  const handleInputChange = (key: ContentScoreFields) => {
    setInputFocused({ ...inputFocused, [key]: true });
  };

  /**
   * This collection maps to the 5 inputs that make up content score. A 6th input found after these inputs is read only: "Total Content Score".
   */
  const inputConfig = [
    { title: 'Title Score', key: ContentScoreFields.TitleScore },
    { title: 'Bullet Score', key: ContentScoreFields.BulletScore },
    { title: 'Image Score', key: ContentScoreFields.ImageScore },
    { title: 'Video Score', key: ContentScoreFields.VideoScore },
    { title: 'A+ Score', key: ContentScoreFields.A_Score }
  ];

  // Derived (read-only) content score based on input
  // Total Content Score is defined as: title + bullet + image + video + A-score divided by 5.
  const totalContentScore = formatMetric(
    Object.values(contentScoreAmounts).reduce((total, score) => {
      if (score) {
        const numericScore = Number(score.replace('%', ''));
        const relativeValue = numericScore / 100; // Our input accepts whole numbers to represent percentages
        total += relativeValue || 0;
      }
      return total;
    }, 0) / Object.values(contentScoreAmounts).length,
    METRICTYPE.PERCENT,
    { showFullValue: false, decimalPlaces: 0 }
  );

  // Access the current projection for each content score input
  const { originalProjection } = summarizedForecastMetrics;
  const { additionalData } = originalProjection;

  return (
    <Box>
      {inputConfig.map((input) => (
        <Box key={input.title}>
          <Box marginTop="42px" alignItems="center" display="flex" flexDirection="row">
            <Box marginRight="24px" width="82px">
              <Text variant="subtitle2">{input.title}</Text>
            </Box>
            <SlColumn>
              <Box display="flex" flexDirection="row" gap="12px" alignItems="center">
                <Box position="relative" display="flex" flexDirection="column">
                  <Box position="absolute" top="-20px">
                    <Typography sx={typographyStyle} title="Current value">
                      CURRENT
                    </Typography>
                  </Box>
                  <SlInput value={additionalData[input.key].formattedValue} width="80px" handleChange={() => {}} />
                </Box>
                <RightArrow style={{ width: '12px', height: '12px' }} />
                <Box position="relative" display="flex" flexDirection="column">
                  <Box position="absolute" top="-20px">
                    <Typography sx={typographyStyle} title="Current value">
                      NEW
                    </Typography>
                  </Box>
                  <SlInput
                    inputRef={refMap[input.key]}
                    sx={{
                      input: {
                        color: inputFocused[input.key] ? theme.colors.primary : theme.colors.secondary // Change the color when focused
                      }
                    }}
                    onKeyDown={(e) => {
                      // For %-based metrics, we have to move the cursor to properly control the input during backspace actions
                      if (e.keyCode === DELETE_KEY_CODE) {
                        moveCursorBeforeSuffix(input.key);
                      }
                    }}
                    handleChange={(s) => {
                      handleContentScoreChange(s, input.key);
                      handleInputChange(input.key);
                    }}
                    placeholder={additionalData[input.key].formattedValue}
                    value={contentScoreAmounts[input.key] || ''}
                    width="80px"
                  />
                </Box>
                <Text variant="body2">per week</Text>
              </Box>
            </SlColumn>
          </Box>
        </Box>
      ))}
      <Box marginTop="52px" marginBottom="52px">
        <Divider sx={{ backgroundColor: theme.colors.primaryGray }} />
      </Box>
      <Box>
        <Box marginTop="42px" alignItems="center" display="flex" flexDirection="row">
          <Box marginRight="24px" width="132px">
            <Text variant="subtitle2">Total Content Score</Text>
          </Box>
          <SlColumn>
            <Box display="flex" flexDirection="row" gap="12px" alignItems="center">
              <Box position="relative" display="flex" flexDirection="column">
                <Box position="absolute" top="-20px">
                  <Typography sx={typographyStyle} title="Current value">
                    CURRENT
                  </Typography>
                </Box>
                <SlInput value={originalProjection.formattedValue} width="80px" handleChange={() => {}} />
              </Box>
              <RightArrow style={{ width: '12px', height: '12px' }} />
              <Box position="relative" display="flex" flexDirection="column">
                <Box position="absolute" top="-20px">
                  <Typography sx={typographyStyle} title="Current value">
                    NEW
                  </Typography>
                </Box>
                <SlInput
                  handleChange={() => {}}
                  placeholder={originalProjection.formattedValue}
                  value={totalContentScore || ''}
                  width="80px"
                />
              </Box>
              <Text variant="body2">per week</Text>
            </Box>
          </SlColumn>
        </Box>
      </Box>
    </Box>
  );
};

export interface ButtonConfig {
  primary: {
    displayName: string;
    action: () => any;
    shouldEnable: () => boolean;
  };
  secondary: {
    displayName: string;
    action: () => any;
    shouldEnable?: () => boolean;
  };
  tertiary?: {
    displayName: string;
    action: () => any;
    shouldEnable?: () => boolean;
  };
}

export const AdjustmentModalFooter = ({
  buttonConfig,
  view
}: {
  view: AdjustmentModalView;
  buttonConfig: ButtonConfig;
}) => {
  return (
    <>
      <Divider />
      {view === AdjustmentModalView.Create || view === AdjustmentModalView.Finalize_Plan ? (
        <Flex position="relative" justifyContent="space-between" alignItems="center" paddingX="48px" paddingY="18px">
          <SlButton onClick={buttonConfig.secondary.action}>{buttonConfig.secondary.displayName}</SlButton>
          <SlButton
            disabled={!buttonConfig.primary.shouldEnable()}
            onClick={buttonConfig.primary.action}
            variant="contained"
          >
            {buttonConfig.primary.displayName}
          </SlButton>
        </Flex>
      ) : null}

      {view === AdjustmentModalView.Confirm && (
        <Flex position="relative" justifyContent="space-between" alignItems="center" paddingX="48px" paddingY="18px">
          <SlButton onClick={buttonConfig.tertiary.action}>{buttonConfig.tertiary.displayName}</SlButton>
          <SlRow spacing="md" verticalPosition="center" horizontalPosition="center">
            <SlButton disabled={!buttonConfig.secondary.shouldEnable()} onClick={buttonConfig.secondary.action}>
              {buttonConfig.secondary.displayName}
            </SlButton>
            <SlButton
              disabled={!buttonConfig.primary.shouldEnable()}
              onClick={buttonConfig.primary.action}
              variant="contained"
            >
              {buttonConfig.primary.displayName}
            </SlButton>
          </SlRow>
        </Flex>
      )}
    </>
  );
};

export const AdjustmentConfirmationView = ({
  summarizedForecastMetrics,
  planType,
  verticalInset = 'md'
}: {
  summarizedForecastMetrics: SummarizedForecastMetrics;
  planType: string;
  verticalInset?: string;
}) => {
  const selectedType = useMemo(() => {
    return planTypeOptions.find((option) => option.id === planType);
  }, [planType]);

  const planTypeLabel = selectedType.label.toLocaleLowerCase();
  const { originalProjection, newProjection, netImpactProjection } = summarizedForecastMetrics;

  const originalProjectionText = useMemo(() => {
    switch (planType) {
      case AdjustmentPlanType.PaidTraffic:
      case AdjustmentPlanType.OtherTraffic:
      case AdjustmentPlanType.OrganicTraffic:
        return `Originally, this product was projected to generate ${originalProjection.formattedValue} (${originalProjection.formattedWeeklyValue} per week) in ${planTypeLabel} during the period of ${originalProjection.formattedStartDate} to ${originalProjection.formattedEndDate}.`;
      case AdjustmentPlanType.RetailPrice:
        return `Originally, this product was projected to have a retail price of ${originalProjection.formattedValue} during the period of ${originalProjection.formattedStartDate} to ${originalProjection.formattedEndDate}.`;
      case AdjustmentPlanType.ContentScore:
        return `Originally, this product was projected to have a ${originalProjection.formattedValue} content score during the period of ${originalProjection.formattedStartDate} to ${originalProjection.formattedEndDate}.`;
      case AdjustmentPlanType.BuyBox:
        return `Originally, this product was projected to have a ${originalProjection.formattedValue} buy box rate during the period of ${originalProjection.formattedStartDate} to ${originalProjection.formattedEndDate}.`;
      case AdjustmentPlanType.InstockRate:
        return `Originally, this product was projected to have a ${originalProjection.formattedValue} in-stock rate during the period of ${originalProjection.formattedStartDate} to ${originalProjection.formattedEndDate}.`;
      case AdjustmentPlanType.RatingsReviews:
        return `Originally, this product was projected to generate ${originalProjection.ratingCount.formattedValue} (${originalProjection.ratingCount.formattedWeeklyValue} per week) reviews with an average star rating of ${originalProjection.weightedRating.formattedValue} during the period of ${originalProjection.weightedRating.formattedStartDate} to ${originalProjection.weightedRating.formattedEndDate}.`;
      default:
        return `Originally, this product was projected to generate: `;
    }
  }, [planType]);

  const newProjectionText = useMemo(() => {
    switch (planType) {
      case AdjustmentPlanType.PaidTraffic:
      case AdjustmentPlanType.OtherTraffic:
      case AdjustmentPlanType.OrganicTraffic:
        return `After applying your adjustment, this product is now projected to generate ${newProjection.aggregatedFormattedValue} (${newProjection.formattedValue} per week) in ${planTypeLabel} during the period.`;
      case AdjustmentPlanType.RetailPrice:
        return `After applying your adjustment, this product is now projected to have a retail price of ${newProjection.formattedValue}
during the period.`;
      case AdjustmentPlanType.ContentScore:
        return `After applying your adjustment, this product is now projected to have a ${newProjection.formattedValue} content score during the period.`;

      case AdjustmentPlanType.BuyBox:
        return `After applying your adjustment, this product is now projected to have a ${newProjection.formattedValue} buy box rate during the period.`;

      case AdjustmentPlanType.InstockRate:
        return `After applying your adjustment, this product is now projected to have a ${newProjection.formattedValue} in-stock rate during the period.`;

      case AdjustmentPlanType.RatingsReviews:
        return `After applying your adjustment, this product is now projected to generate ${newProjection.ratingCount.aggregatedFormattedValue} (${newProjection.ratingCount.formattedWeeklyValue} per week) reviews with an average star rating of ${newProjection.weightedRating.formattedValue} during the period.`;

      default:
        return `Originally, this product was projected to generate: `;
    }
  }, [planType]);

  const salesNetImpactText = useMemo(() => {
    switch (planType) {
      case AdjustmentPlanType.RatingsReviews:
        return `This revision to your reviews plan is projected to have a ${netImpactProjection.formattedValue} units sold ${netImpactProjection.metricChangeString} ${netImpactProjection.metricChangeComparisonString} the previous sales forecast during the period.`;

      default:
        return `This revision to your ${planTypeLabel} plan is projected to have a ${netImpactProjection.formattedValue} units sold ${netImpactProjection.metricChangeString} ${netImpactProjection.metricChangeComparisonString} the previous sales forecast during the period.`;
    }
  }, [planType]);

  return (
    <>
      <SlColumn verticalInset={verticalInset}>
        <SlRow verticalInset="sm">
          <Text variant="subtitle2" title="Plan change">
            Plan Change Confirmation
          </Text>
        </SlRow>

        <SlRow>
          <SlColumn>
            <Text variant="body2">{originalProjectionText}</Text>
          </SlColumn>
        </SlRow>
        <SlRow verticalInset="sm">
          <Text variant="body2">{newProjectionText}</Text>
        </SlRow>

        <SlColumn verticalInset="sm">
          <SlRow verticalInset="sm">
            <Text variant="subtitle2" title="Net impact">
              Sales Net Impact
            </Text>
          </SlRow>
          <SlRow>
            <Text variant="body2">{salesNetImpactText}</Text>
          </SlRow>
        </SlColumn>
      </SlColumn>
    </>
  );
};

export const AdjustmentFinalizationView = ({
  handleContentScoreChange,
  contentScoreAmounts,
  summarizedForecastMetrics,
  planType,
  planChangeAmount,
  handleChange,
  handleBlur,
  inputSettings,
  additionalAverageStarRatingAmount,
  additionalReviewQuantityAmount,
  handleRatingsAndReviewsInputChange,
  reviewQuantityAmount,
  averageStarRatingAmount
}: {
  handleContentScoreChange: any;
  contentScoreAmounts: ContentScoreAmounts;
  summarizedForecastMetrics: SummarizedForecastMetrics;
  planChangeAmount: string | number;
  handleChange: () => void;
  handleBlur: () => void;
  planType: string;
  inputSettings: InputSettings;
  handleRatingsAndReviewsInputChange: (input: string, inputType: string) => void;
  reviewQuantityAmount: string;
  averageStarRatingAmount: string;
  additionalAverageStarRatingAmount: string;
  additionalReviewQuantityAmount: string;
}) => {
  const selectedType = useMemo(() => {
    return planTypeOptions.find((option) => option.id === planType);
  }, [planType]);
  const planTypeLabel = selectedType.label.toLowerCase();
  const { originalProjection } = summarizedForecastMetrics;

  const inputRef = useRef();

  const moveCursorBeforeSuffix = () => {
    const inputElement = inputRef.current;

    if (inputElement) {
      const { length } = inputElement.value;
      inputElement.setSelectionRange(length - 1, length - 1);
      inputElement.focus();
    }
  };

  const currentProjectionText = useMemo(() => {
    switch (planType) {
      case AdjustmentPlanType.PaidTraffic:
      case AdjustmentPlanType.OtherTraffic:
      case AdjustmentPlanType.OrganicTraffic:
        return `In total, this product is currently projected to generate ${originalProjection.formattedValue} in ${planTypeLabel} during the period of ${originalProjection.formattedStartDate} to ${originalProjection.formattedEndDate}.`;
      case AdjustmentPlanType.RetailPrice:
        return `This product is currently projected to have a retail price of ${originalProjection.formattedValue} during the period of ${originalProjection.formattedStartDate} to ${originalProjection.formattedEndDate}.`;
      case AdjustmentPlanType.ContentScore:
        return `This product is currently projected to have a ${originalProjection.formattedValue} content score during the period of ${originalProjection.formattedStartDate} to ${originalProjection.formattedEndDate}.`;
      case AdjustmentPlanType.BuyBox:
        return `This product is currently projected to have a ${originalProjection.formattedValue} buy box rate during the period of ${originalProjection.formattedStartDate} to ${originalProjection.formattedEndDate}.`;
      case AdjustmentPlanType.InstockRate:
        return `This product is currently projected to have a ${originalProjection.formattedValue} in-stock rate during the period of ${originalProjection.formattedStartDate} to ${originalProjection.formattedEndDate}.`;
      case AdjustmentPlanType.RatingsReviews:
        return `In total, this product is currently projected to generate ${originalProjection.ratingCount.formattedValue} reviews with an average star rating of ${originalProjection.weightedRating.formattedValue} during the period of ${originalProjection.weightedRating.formattedStartDate} to ${originalProjection.weightedRating.formattedEndDate}.`;
      default:
        return `Originally, this product was projected to generate: `;
    }
  }, [planType]);

  const currentProjectionWeeklyText = useMemo(() => {
    switch (planType) {
      case AdjustmentPlanType.PaidTraffic:
      case AdjustmentPlanType.OtherTraffic:
      case AdjustmentPlanType.OrganicTraffic:
        return `On a weekly basis, this product is currently projected to generate an average of ${originalProjection.formattedWeeklyValue} in ${planTypeLabel} per week during the period.`;
      case AdjustmentPlanType.RetailPrice:
        return ``;
      case AdjustmentPlanType.ContentScore:
        return ``;
      case AdjustmentPlanType.BuyBox:
        return ``;
      case AdjustmentPlanType.InstockRate:
        return ``;
      case AdjustmentPlanType.RatingsReviews:
        return `On a weekly basis, this product is currently projected to generate an average of ${originalProjection.ratingCount.formattedWeeklyValue} reviews per week during the period.`; // TODO:
      default:
        return `Originally, this product was projected to generate: `;
    }
  }, [planType]);

  const readOnlyValue: string | { ratingCount: string; weightedRating: string } = useMemo(() => {
    switch (planType) {
      case AdjustmentPlanType.PaidTraffic:
      case AdjustmentPlanType.OtherTraffic:
      case AdjustmentPlanType.OrganicTraffic:
        return originalProjection.formattedWeeklyValue;
      case AdjustmentPlanType.RatingsReviews:
        return {
          ratingCount: originalProjection.ratingCount.formattedWeeklyValue,
          weightedRating: originalProjection.weightedRating.formattedValue
        };
      default:
        return originalProjection.formattedValue;
    }
  }, [planType]);

  const renderAdjustmentInput = () => {
    if ([AdjustmentPlanType.ContentScore].includes(planType)) {
      return (
        <ContentScoreInputs
          summarizedForecastMetrics={summarizedForecastMetrics}
          handleContentScoreChange={handleContentScoreChange}
          contentScoreAmounts={contentScoreAmounts}
        />
      );
    } else if ([AdjustmentPlanType.RatingsReviews].includes(planType)) {
      return (
        <Box>
          <Box marginTop="52px" alignItems="center" display="flex" flexDirection="row">
            <Box marginRight="24px">
              <Text variant="subtitle2">Review Quantity</Text>
            </Box>
            <SlColumn>
              <Box display="flex" flexDirection="row" gap="12px" alignItems="center">
                <Box position="relative" display="flex" flexDirection="column">
                  <Box position="absolute" top="-20px">
                    <Typography sx={typographyStyle} title="Current value">
                      CURRENT
                    </Typography>
                  </Box>
                  <SlInput value={readOnlyValue.ratingCount} width="80px" handleChange={() => {}} />
                </Box>
                <UpdatedPlusIcon width={12} height={12} />
                <Box position="relative" display="flex" flexDirection="column">
                  <Box position="absolute" top="-20px">
                    <Typography sx={typographyStyle} title="Current value">
                      ADDITIONAL
                    </Typography>
                  </Box>
                  <SlInput
                    handleChange={(s) => handleRatingsAndReviewsInputChange(s, 'additionalReviewCount')}
                    placeholder="0"
                    value={additionalReviewQuantityAmount || ''}
                    min={inputSettings.min}
                    max={inputSettings.max}
                    width="80px"
                  />{' '}
                </Box>
                <EqualIcon style={{ width: '12px', height: '12px' }} />
                <Box position="relative" display="flex" flexDirection="column">
                  <Box position="absolute" top="-20px">
                    <Typography sx={typographyStyle} title="Current value">
                      TOTAL
                    </Typography>
                  </Box>
                  <SlInput
                    placeholder={readOnlyValue.ratingCount}
                    value={reviewQuantityAmount || ''}
                    min={inputSettings.min}
                    max={inputSettings.max}
                    width="80px"
                  />
                </Box>
                <Text variant="body2">per week</Text>
              </Box>
            </SlColumn>
          </Box>
          <Box marginTop="52px" alignItems="center" display="flex" flexDirection="row">
            <Box marginRight="24px">
              <Text variant="subtitle2">AVG Star Rating</Text>
            </Box>
            <SlColumn>
              <Box display="flex" flexDirection="row" gap="12px" alignItems="center">
                <Box position="relative" display="flex" flexDirection="column">
                  <Box position="absolute" top="-20px">
                    <Typography sx={typographyStyle} title="Current value">
                      CURRENT
                    </Typography>
                  </Box>
                  <SlInput value={readOnlyValue.weightedRating} width="80px" handleChange={() => {}} />
                </Box>
                <UpdatedPlusIcon width={12} height={12} />
                <Box position="relative" display="flex" flexDirection="column">
                  <Box position="absolute" top="-20px">
                    <Typography sx={typographyStyle} title="Current value">
                      ADDITIONAL
                    </Typography>
                  </Box>
                  <SlInput
                    handleChange={(s) => handleRatingsAndReviewsInputChange(s, 'additionalAverageStarRatingAmount')}
                    placeholder={0}
                    value={additionalAverageStarRatingAmount || ''}
                    min={inputSettings.min}
                    max={inputSettings.max}
                    width="80px"
                  />{' '}
                </Box>
                <EqualIcon style={{ width: '12px', height: '12px' }} />
                <Box position="relative" display="flex" flexDirection="column">
                  <Box position="absolute" top="-20px">
                    <Typography sx={typographyStyle} title="Current value">
                      NEW
                    </Typography>
                  </Box>
                  <SlInput
                    handleChange={(s) => handleRatingsAndReviewsInputChange(s, 'weightedRating')}
                    placeholder={readOnlyValue.weightedRating}
                    value={averageStarRatingAmount || ''}
                    min={inputSettings.min}
                    max={inputSettings.max}
                    onBlur={handleBlur}
                    width="80px"
                  />
                </Box>
                <Text variant="body2">per week</Text>
              </Box>
            </SlColumn>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box display="flex" marginTop="52px" flexDirection="row" alignItems="center">
          <Box marginRight="24px">
            <Text variant="subtitle2">{selectedType.label}</Text>
          </Box>
          <Box display="flex" flexDirection="row" gap="12px" alignItems="center">
            <Box position="relative" display="flex" flexDirection="column">
              <Box position="absolute" top="-20px">
                <Typography sx={typographyStyle} title="Current value">
                  CURRENT
                </Typography>
              </Box>
              <SlInput value={readOnlyValue} width="80px" handleChange={() => {}} />
            </Box>
            <RightArrow style={{ width: '12px', height: '12px' }} />
            <Box position="relative" display="flex" flexDirection="column">
              <Box position="absolute" top="-20px">
                <Typography sx={typographyStyle} title="Current value">
                  NEW
                </Typography>
              </Box>
              <SlInput
                inputRef={inputRef}
                onKeyDown={(e) => {
                  // For %-based metrics, we have to move the cursor to properly control the input during backspace actions
                  if (
                    e.keyCode === DELETE_KEY_CODE &&
                    [AdjustmentPlanType.BuyBox, AdjustmentPlanType.InstockRate].includes(planType)
                  ) {
                    moveCursorBeforeSuffix();
                  }
                }}
                handleChange={handleChange}
                placeholder={readOnlyValue}
                value={planChangeAmount || ''}
                min={inputSettings.min}
                max={inputSettings.max}
                onBlur={handleBlur}
                width="80px"
              />
            </Box>
            <Text variant="body2">per week</Text>
          </Box>
        </Box>
      );
    }
  };

  return (
    <>
      <SlColumn verticalInset="mdl" widths="full">
        <SlRow verticalInset="sm">
          <Text variant="subtitle2" title="Title">
            Current Projection
          </Text>
        </SlRow>
        <SlRow>
          <SlColumn>
            <Text variant="body2">{currentProjectionText}</Text>
          </SlColumn>
        </SlRow>
        <Box paddingTop="8px" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
          <Text variant="body2">{currentProjectionWeeklyText}</Text>
        </Box>
        <Box display="flex" flexDirection="column" gap="8px" marginTop="24px">
          <SlRow>
            <Text variant="subtitle2" title="Title">
              New Projection
            </Text>
          </SlRow>
          <SlRow>
            <SlColumn>
              <Text variant="body2">Adjust your weekly plan for this product using the inputs below.</Text>
            </SlColumn>
          </SlRow>
        </Box>

        {renderAdjustmentInput()}
      </SlColumn>
    </>
  );
};

const CreateAdjustmentModalInner = ({
  onClose,
  handleSuccessAdjustmentModalOpen,
  submitAdjustment
}: {
  onClose: () => void;
  handleSuccessAdjustmentModalOpen: () => void;
  submitAdjustment: (payload: SubmitAdjustmentParams) => void;
}) => {
  /**
   * View state depending on the button clicked
   */
  const [view, setView] = useState<AdjustmentModalView>(AdjustmentModalView.Create);

  /**
   * Form state: Contains all states needed to create a new adjustment for a product.
   */
  const [planType, setPlanType] = useState('OrganicTraffic');
  const [adjustmentTitle, setAdjustmentTitle] = useState('');
  const [description, setDescription] = useState('');
  const [planChangeAmount, setPlanChangeAmount] = useState(null);
  /**
   * Special state for Ratings and Reviews inputs
   */
  const [reviewQuantityAmount, setReviewQuantityAmount] = useState(null);
  const [additionalReviewQuantityAmount, setAdditionalReviewQuantityAmount] = useState(null);
  const [additionalAverageStarRatingAmount, setAdditionalAverageStarRatingAmount] = useState(null);
  const [averageStarRatingAmount, setAverageStarRatingAmount] = useState(null);
  /**
   * Special state for Content Score inputs
   */
  const [contentScoreAmounts, setContentScoreAmounts] = useState<ContentScoreAmounts>({
    [ContentScoreFields.TitleScore]: null,
    [ContentScoreFields.BulletScore]: null,
    [ContentScoreFields.ImageScore]: null,
    [ContentScoreFields.VideoScore]: null,
    [ContentScoreFields.A_Score]: null
  });
  const [planStartDate, setPlanStartDate] = useState(null);
  const [planEndDate, setPlanEndDate] = useState(null);
  const [isConflictingDateRange, setIsConflictingDateRange] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [summarizedForecastMetrics, setSummarizedForecastMetrics] = useState<SummarizedForecastMetrics>(null);
  const [awaitingConfirmationMetrics, setAwaitingConfirmationMetrics] = useState(false);
  const [allConflictingWeekIds, setAllConflictingWeekIds] = useState([]);

  const fetchInvalidDates = useFetchInvalidDatesForPlanType();

  const generateMetricsForConfirmation = useGenerateMetricsForConfirmation();

  // Get first and last week of year
  const firstWeekIdOfYear = getFirstWeekIdOfYear();
  const lastWeekIdOfYear = getLastWeekIdOfYear();

  // When creating an adjustment, we an use the main entity as the product in question
  const productEntity = useAppSelector((state) => state.entityService.mainEntity);
  const { retailerSku } = productEntity;

  /**
   * Get all disabled weeks for the entire year for use in the calendar
   */
  useEffect(() => {
    const fetchAndSetData = async () => {
      const response = await fetchInvalidDates({
        retailerSku,
        planType,
        startWeekId: firstWeekIdOfYear,
        endWeekId: lastWeekIdOfYear
      });
      if (response.data) {
        const parsedData = parseInvalidDateResponse(response);
        setAllConflictingWeekIds(parsedData);
      }
    };

    fetchAndSetData();
  }, [planType]);

  /**
   * Sets the error message to open and invalidates the form.
   */
  const handleConflictedDate = () => {
    setErrorOpen(true);
    setIsConflictingDateRange(true);
  };
  /**
   * Sets the error message to close and validates the form.
   */
  const handleClearDateErrors = () => {
    setErrorOpen(false);
    setIsConflictingDateRange(false);
  };

  /**
   * Takes a start date and an end date as weekId and updates our form.
   * @param newDates A collection of 2 weekIds
   */
  const handleDateChange = (newDates: string[] | number[]) => {
    handleClearDateErrors();
    const [startDateWeekId, endDateWeekId] = newDates;
    setPlanStartDate(startDateWeekId);
    setPlanEndDate(endDateWeekId);
  };

  /**
   * Clear certain parts of the form when the planType changes
   */
  useEffect(() => {
    setPlanChangeAmount(null);
    setPlanStartDate(null);
    setPlanEndDate(null);
  }, [planType]);

  /**
   * Change the input constraints depending on the planType selected.
   */
  const inputSettings: InputSettings = useMemo(() => {
    if (
      [AdjustmentPlanType.OrganicTraffic, AdjustmentPlanType.OtherTraffic, AdjustmentPlanType.PaidTraffic].includes(
        planType as AdjustmentPlanType
      )
    ) {
      return { min: 0, max: Number.MAX_SAFE_INTEGER, placeholder: '0', fixedPlaces: 0 };
    } else if ([AdjustmentPlanType.RetailPrice].includes(planType as AdjustmentPlanType)) {
      return { min: 0.01, max: Number.MAX_SAFE_INTEGER, placeholder: '$0.00', fixedPlaces: 2 };
    } else if ([AdjustmentPlanType.RatingsReviews].includes(planType as AdjustmentPlanType)) {
      return { min: 1.0, max: 5.0, placeholder: '4.5', fixedPlaces: 1 };
    } else {
      return { min: 1, max: 100, placeholder: '0%', fixedPlaces: 0 };
    }
  }, [planType]);

  const handleInputChange = (amount: string) => {
    const { min, max, fixedPlaces } = inputSettings;
    const value = Number(amount);
    // Traffic types
    if (
      [AdjustmentPlanType.OrganicTraffic, AdjustmentPlanType.OtherTraffic, AdjustmentPlanType.PaidTraffic].includes(
        planType as AdjustmentPlanType
      )
    ) {
      if (!Number.isNaN(value) && value >= min && value <= max) {
        const formattedAmount = value.toFixed(fixedPlaces);
        setPlanChangeAmount(formattedAmount);
      }
    } else if ([AdjustmentPlanType.RetailPrice].includes(planType as AdjustmentPlanType)) {
      // Retail Price
      /**
       * Regex: Strips any non-numeric characters except decimals.
       * Removes all but the last decimal point and anything beyond 2 characters past it.
       */
      const strippedInput = amount.replace(/[^\d.]|(\.(?=.*\.))|(?<=\.\d{2})\d+/g, '');
      setPlanChangeAmount(strippedInput);
    } else if ([AdjustmentPlanType.RatingsReviews].includes(planType as AdjustmentPlanType)) {
      if (value >= min && value <= max) {
        /**
         * Regex: Strips any non-numeric characters except decimals.
         * Removes all but the last decimal point and anything beyond 1 character past it.
         * i.e allows 5.4 but not 5.46 or 5.0000
         */
        const strippedInput = amount.replace(/[^\d.]|(\.(?=.*\.))|(?<=\.\d{1})\d+/, '');
        setPlanChangeAmount(strippedInput);
      }
    } else if (
      // All other metrics
      [AdjustmentPlanType.ContentScore, AdjustmentPlanType.BuyBox, AdjustmentPlanType.InstockRate].includes(
        planType as AdjustmentPlanType
      )
    ) {
      const val = Number(amount.replaceAll('%', ''));

      if (!Number.isNaN(val) && val >= min && val <= max) {
        const formattedAmount = val.toFixed(fixedPlaces);
        setPlanChangeAmount(`${formattedAmount}%`);
      } else if (amount === '%') {
        setPlanChangeAmount('');
      }
    }

    if (!amount) {
      setPlanChangeAmount('');
    }
  };

  const handleRatingsAndReviewsInputChange = (input: string, inputType: string) => {
    const { min, max } = inputSettings;
    const value = Number(input);

    const { originalProjection } = summarizedForecastMetrics;
    // @ts-ignore
    const baseReviewCount = originalProjection.ratingCount.rawValue;
    // @ts-ignore
    const baseStarRating = originalProjection.weightedRating.rawValue;

    if (inputType === 'weightedRating') {
      if (value >= min && value <= max) {
        /**
         * Regex: Strips any non-numeric characters except decimals.
         * Removes all but the last decimal point and anything beyond 1 character past it.
         * i.e allows 5.4 but not 5.46 or 5.0000
         */
        const strippedInput = input.replace(/[^\d.]|(\.(?=.*\.))|(?<=\.\d{1})\d+/, '');
        setAverageStarRatingAmount(strippedInput);
      } else if (!input) {
        setAverageStarRatingAmount('');
      }
    } else if (inputType === 'reviewCount') {
      // handle the additional logic
      if (!Number.isNaN(value) && value >= 0 && value <= Number.MAX_SAFE_INTEGER) {
        setReviewQuantityAmount(value);
      } else if (!input) {
        setReviewQuantityAmount('');
      }
    } else if (inputType === 'additionalReviewCount') {
      // handle the additional logic
      if (!Number.isNaN(value) && value >= 0 && value <= Number.MAX_SAFE_INTEGER) {
        const newValue = baseReviewCount + value;
        setAdditionalReviewQuantityAmount(value);
        setReviewQuantityAmount(newValue);
      } else if (!input) {
        setAdditionalReviewQuantityAmount('');
        setReviewQuantityAmount('');
      }
    } else if (inputType === 'additionalAverageStarRatingAmount') {
      // handle the additional logic
      const strippedInput = input.replace(/[^\d.]|(\.(?=.*\.))|(?<=\.\d{1})\d+/, '');
      const newValue = +baseStarRating + +strippedInput;
      const newValueFixed = newValue.toFixed(1);

      setAdditionalAverageStarRatingAmount(input);

      if (newValue >= min && newValue <= max) {
        setAverageStarRatingAmount(newValueFixed);
      } else {
        // unset the additional amount
        setAdditionalAverageStarRatingAmount('');
      }
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if ([AdjustmentPlanType.RetailPrice].includes(planType as AdjustmentPlanType)) {
      const { value } = e.target;
      const { min, fixedPlaces } = inputSettings;
      const rawNumber = Number(value);
      const fixedNumber = rawNumber.toFixed(fixedPlaces);
      if (rawNumber !== fixedNumber) {
        setPlanChangeAmount(fixedNumber);
      }
      if (rawNumber === 0) {
        setPlanChangeAmount(min);
      }
    } else if ([AdjustmentPlanType.RatingsReviews].includes(planType as AdjustmentPlanType)) {
      const { value } = e.target;
      const { fixedPlaces } = inputSettings;
      const rawNumber = Number(value);
      const fixedNumber = rawNumber.toFixed(fixedPlaces);
      if (rawNumber !== fixedNumber) {
        setAverageStarRatingAmount(fixedNumber);
      }
      if (rawNumber === 0) {
        setAverageStarRatingAmount('1.0');
      }
    }
  };

  /**
   * A final check to ensure the user has not selected a date range through invalid dates.
   * @returns false if there is a conflict, true if the adjustment is valid
   */
  const handleFinalValidation = async () => {
    try {
      const response = await fetchInvalidDates({
        retailerSku,
        planType,
        startWeekId: planStartDate,
        endWeekId: planEndDate
      });
      const isConflicted = _get(response, ['data', 'isConflicted']);
      if (isConflicted) {
        return false;
      }
    } catch (error) {
      return false;
    }
    return true;
  };

  const handleContentScoreChange = (s: string, key: string) => {
    const { min, max, fixedPlaces } = inputSettings;
    const value = Number(s.replaceAll('%', ''));

    if (!Number.isNaN(value) && value >= min && value <= max) {
      const formattedAmount = value.toFixed(fixedPlaces);

      setContentScoreAmounts({ ...contentScoreAmounts, [key]: `${formattedAmount}%` });
    }
    if (!s || s.length === 0 || s === '%') {
      setContentScoreAmounts({ ...contentScoreAmounts, [key]: '' });
    }
  };

  const isValidContentScoreInputs = () => {
    let noInvalidValues = true;
    Object.values(contentScoreAmounts).forEach((value) => {
      if (!value) {
        noInvalidValues = false;
      }
    });
    return noInvalidValues;
  };

  // For content score, this determine the plan change amount/adjustment payload
  useEffect(() => {
    const totalContentScore =
      Object.values(contentScoreAmounts).reduce((total, score) => {
        if (score) {
          const numericScore = Number(score.replace('%', ''));
          total += numericScore || 0;
        }
        return total;
      }, 0) / Object.values(contentScoreAmounts).length;

    setPlanChangeAmount(Math.round(totalContentScore));
  }, [contentScoreAmounts]);

  const validateCreateForm = (plan: AdjustmentPlanType) => {
    switch (plan) {
      case AdjustmentPlanType.RatingsReviews:
        return !!(averageStarRatingAmount && reviewQuantityAmount) && !awaitingConfirmationMetrics;
      case AdjustmentPlanType.ContentScore:
        return isValidContentScoreInputs()
          ? !!planChangeAmount && !awaitingConfirmationMetrics
          : !awaitingConfirmationMetrics;
      default:
        return !awaitingConfirmationMetrics && !!planChangeAmount;
    }
  };

  /**
   * Boolean value that is constantly checking to determine if the form is completed and valid to continue to the next page.
   */
  const isValidatedForm: boolean = useMemo(() => {
    if (
      isConflictingDateRange ||
      awaitingConfirmationMetrics ||
      !planType ||
      !adjustmentTitle ||
      !description ||
      !planStartDate ||
      !planEndDate
    ) {
      return false;
    }
    return true;
  }, [planType, adjustmentTitle, description, planStartDate, planEndDate, isConflictingDateRange]);

  /**
   * A dynamic button configuration used in the footer.
   * Returns a button display name and the desired handler function depending on the view state.
   */
  const buttonConfig: ButtonConfig = useMemo(() => {
    if (view === AdjustmentModalView.Create) {
      return {
        primary: {
          displayName: 'Next',
          action: async () => {
            if (isValidatedForm) {
              // We have a completed form but we still need to confirm a valid date range one more time
              const isValid = await handleFinalValidation();
              if (isValid) {
                setAwaitingConfirmationMetrics(true);
                setView(AdjustmentModalView.Finalize_Plan);
                const { originalProjection, newProjection, netImpactProjection } = await generateMetricsForConfirmation(
                  {
                    reviewQuantityAmount,
                    averageStarRatingAmount,
                    contentScoreAmounts: getContentScoreValues(contentScoreAmounts),
                    planType,
                    adjustmentTitle,
                    description,
                    planChangeAmount: getAdjustmentAmountValue(planType, planChangeAmount),
                    planStartDate,
                    planEndDate,
                    product: productEntity
                  }
                );

                // We carry forward the current (projection) content score values to the inputs here
                const { additionalData } = originalProjection;
                if (additionalData) {
                  const currentContentScores = Object.entries(additionalData).reduce((acc, [key, datum]) => {
                    acc[key] = datum.formattedValue;
                    return acc;
                  }, {}) as ContentScoreAmounts;
                  setContentScoreAmounts(currentContentScores);
                }

                setSummarizedForecastMetrics({ originalProjection, newProjection, netImpactProjection });
                setAwaitingConfirmationMetrics(false);
              } else {
                handleConflictedDate();
              }
            }
          },
          shouldEnable: () => {
            return isValidatedForm && !awaitingConfirmationMetrics;
          }
        },
        secondary: { displayName: 'Cancel', action: () => onClose() }
      };
    } else if (view === AdjustmentModalView.Finalize_Plan) {
      return {
        primary: {
          displayName: 'Next',
          action: async () => {
            if (isValidatedForm) {
              // We have a completed form but we still need to confirm a valid date range one more time
              const isValid = await handleFinalValidation();
              if (isValid) {
                setAwaitingConfirmationMetrics(true);
                setView(AdjustmentModalView.Confirm);
                const { originalProjection, newProjection, netImpactProjection } = await generateMetricsForConfirmation(
                  {
                    reviewQuantityAmount,
                    averageStarRatingAmount,
                    contentScoreAmounts: getContentScoreValues(contentScoreAmounts),
                    planType,
                    adjustmentTitle,
                    description,
                    planChangeAmount: getAdjustmentAmountValue(planType, planChangeAmount),
                    planStartDate,
                    planEndDate,
                    product: productEntity
                  }
                );
                setSummarizedForecastMetrics({ originalProjection, newProjection, netImpactProjection });
                setAwaitingConfirmationMetrics(false);
              } else {
                handleConflictedDate();
              }
            }
          },
          shouldEnable: () => {
            return validateCreateForm(planType as AdjustmentPlanType);
          }
        },
        secondary: {
          displayName: 'Back',
          action: () => {
            setView(AdjustmentModalView.Create);
          }
        }
      };
    } else {
      return {
        primary: {
          displayName: 'Publish',
          action: () => {
            if (isValidatedForm) {
              submitAdjustment({
                contentScoreAmounts: getContentScoreValues(contentScoreAmounts),
                reviewQuantityAmount,
                averageStarRatingAmount,
                retailerSku,
                planType,
                adjustmentTitle,
                description,
                planChangeAmount: getAdjustmentAmountValue(planType, planChangeAmount),
                planStartDate,
                planEndDate
              });
              onClose();
              handleSuccessAdjustmentModalOpen();
            }
          },
          shouldEnable: () => {
            return isValidatedForm && !awaitingConfirmationMetrics;
          }
        },
        secondary: {
          displayName: 'Save as draft',
          action: () => {
            if (isValidatedForm) {
              submitAdjustment({
                contentScoreAmounts: getContentScoreValues(contentScoreAmounts),
                reviewQuantityAmount,
                averageStarRatingAmount,
                retailerSku,
                saveAsDraft: true,
                planType,
                adjustmentTitle,
                description,
                planChangeAmount: getAdjustmentAmountValue(planType, planChangeAmount),
                planStartDate,
                planEndDate
              });
              onClose();
              handleSuccessAdjustmentModalOpen();
            }
          },
          shouldEnable: () => {
            return isValidatedForm && !awaitingConfirmationMetrics;
          }
        },
        tertiary: {
          displayName: 'Back',
          action: () => {
            setView(AdjustmentModalView.Finalize_Plan);
          }
        }
      };
    }
  }, [
    view,
    isValidatedForm,
    planType,
    adjustmentTitle,
    description,
    planChangeAmount,
    planStartDate,
    planEndDate,
    isConflictingDateRange,
    awaitingConfirmationMetrics,
    averageStarRatingAmount,
    reviewQuantityAmount,
    contentScoreAmounts,
    isValidContentScoreInputs,
    productEntity,
    validateCreateForm,
    handleFinalValidation
  ]);

  const renderView = () => {
    if (view === AdjustmentModalView.Create) {
      return (
        <SlColumn horizontalInset="xl" widths="full">
          <EntityRowContainer />
          <SlRow spacing="lg">
            <PlanTypeDropdownMenu planType={planType} setPlanType={setPlanType} />
            <AdjustmentTitleInput title={adjustmentTitle} setAdjustmentTitle={setAdjustmentTitle} />
          </SlRow>
          <AdjustmentDescriptionInput description={description} setDescription={setDescription} />
          <PlanDateRange
            allConflictingWeekIds={allConflictingWeekIds}
            planStartDate={planStartDate}
            planEndDate={planEndDate}
            handleDateChange={handleDateChange}
          />
          <InvalidDateError open={errorOpen} />
        </SlColumn>
      );
    } else if (
      view === AdjustmentModalView.Finalize_Plan &&
      (awaitingConfirmationMetrics || !summarizedForecastMetrics)
    ) {
      return <AdjustmentSummaryMetricsLoading />;
    } else if (view === AdjustmentModalView.Finalize_Plan) {
      return (
        <SlColumn horizontalInset="xl" widths="full">
          <AdjustmentFinalizationView
            handleContentScoreChange={handleContentScoreChange}
            contentScoreAmounts={contentScoreAmounts}
            summarizedForecastMetrics={summarizedForecastMetrics}
            planType={planType}
            planChangeAmount={planChangeAmount}
            handleChange={handleInputChange}
            handleBlur={handleBlur}
            additionalReviewQuantityAmount={additionalReviewQuantityAmount}
            additionalAverageStarRatingAmount={additionalAverageStarRatingAmount}
            inputSettings={inputSettings}
            handleRatingsAndReviewsInputChange={handleRatingsAndReviewsInputChange}
            reviewQuantityAmount={reviewQuantityAmount}
            averageStarRatingAmount={averageStarRatingAmount}
          />
        </SlColumn>
      );
    } else if (view === AdjustmentModalView.Confirm && (awaitingConfirmationMetrics || !summarizedForecastMetrics)) {
      return <AdjustmentSummaryMetricsLoading />;
    } else if (view === AdjustmentModalView.Confirm) {
      return (
        <SlColumn horizontalInset="xl" widths="full">
          <AdjustmentConfirmationView planType={planType} summarizedForecastMetrics={summarizedForecastMetrics} />
        </SlColumn>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <CreateAdjustmentModalHeader onClose={onClose} />
      <div className="inner-container" style={{ height: '495px', overflow: 'scroll' }}>
        {renderView()}
      </div>
      <AdjustmentModalFooter view={view} buttonConfig={buttonConfig} />
    </>
  );
};

interface CreateAdjustmentModalProps {
  open: boolean;
  onClose: () => void;
  handleSuccessAdjustmentModalOpen: () => void;
  submitAdjustment: (payload: SubmitAdjustmentParams) => void;
}
export const CreateAdjustmentModal = ({
  open,
  onClose,
  handleSuccessAdjustmentModalOpen,
  submitAdjustment
}: CreateAdjustmentModalProps) => {
  return (
    <GenericStyledDialogue open={open} onClose={onClose}>
      <CreateAdjustmentModalInner
        onClose={onClose}
        handleSuccessAdjustmentModalOpen={handleSuccessAdjustmentModalOpen}
        submitAdjustment={submitAdjustment}
      />
    </GenericStyledDialogue>
  );
};
