import types from './types';
import actions from './actions';
import { ValueOf } from 'sl-api-connector/types';

const omniBrandsFollowing = (state = [], action: ReturnType<ValueOf<typeof actions>>) => {
  switch (action.type) {
    case types.RECEIVE_ALL_OMNI_BRANDS_FOLLOWING:
      return Object.assign([], action.brands);
    default:
      return state;
  }
};

export default omniBrandsFollowing;
