import React from 'react';
import Lottie from 'react-lottie';
import * as bulkAdjustmentUploadSuccessAnimation from 'src/components/Animations/BulkAdjustmentUploadSuccessAnimation/BulkAdjustmentUploadSuccessAnimation.json';

export const BulkAdjustmentUploadSuccessAnimation = ({
  ...props
}: Omit<React.ComponentPropsWithRef<typeof Lottie>, 'options'>) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: bulkAdjustmentUploadSuccessAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Lottie
      ariaRole="presentation"
      isClickToPauseDisabled
      options={defaultOptions}
      height="100%"
      width="100%"
      {...props}
    />
  );
};
