import { useMemo } from 'react';
import { ESSRequestContext, SearchRequestOverride } from 'src/store/modules/entitySearchService/operationTypes';
import { useAppSelector } from 'src/utils/Hooks';
import useConnectFetchEntityMetrics from '../hooks/useConnectFetchEntityMetrics';
import useAmcDateRange from '../hooks/useAmcDateRange';
import { AD_MANAGER_STATE_PROPERTY_NAME } from '../constants';
import { advertisingAnalyticsAdvancedSearchResponseParser, adSpendAdvancedSearchParser } from './utils';
import { AdvertisingAnalyticsData } from './types';

/**
 * Single hook for use by ad analytics query that fetches
 * ad analytics and ad spend. It parses both advanced search responses
 * and combines them into a single object.
 */
const useAdAnalyticsQuery = (): { isLoading: boolean; data: AdvertisingAnalyticsData | null } => {
  const { startDayId, endDayId } = useAmcDateRange();
  const retailerId = useAppSelector((state) => state.retailer.id);

  const adAnalyticsSearchRequestOverrides = useMemo(() => {
    const overrides: SearchRequestOverride = {
      pageNumber: 1,
      pageSize: 50,
      doAggregation: true,
      returnDocuments: false,
      conditions: {
        condition: 'must',
        termFilters: [
          {
            fieldName: 'periodStartDate',
            condition: 'should',
            values: [`${startDayId}`]
          },
          {
            fieldName: 'retailerId',
            condition: 'should',
            values: [retailerId]
          },
          {
            fieldName: 'periodEndDate',
            condition: 'should',
            values: [`${endDayId}`]
          },
          {
            fieldName: 'segmentId',
            condition: 'should',
            values: ['DEFAULT']
          }
        ],
        computeFilters: []
      },
      aggregations: [
        {
          aggregationFields: [
            {
              aggregateByFieldName: 'retailerId',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'impressions',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'clicks',
              function: 'sum',
              canBeExported: true
            }
          ],
          groupByFieldName: 'adProductType.keyword'
        }
      ],
      processDocuments: false
    };
    return [overrides];
  }, [retailerId, startDayId, endDayId]);

  const adAnalyticsRequestContextOverrides: Partial<ESSRequestContext> = useMemo(
    () => ({
      customResponseParser: ({ apiResponse, state }) => ({
        ...state[AD_MANAGER_STATE_PROPERTY_NAME],
        advertisingAnalyticsData: advertisingAnalyticsAdvancedSearchResponseParser(apiResponse.data[0])
      })
    }),
    []
  );

  const adSpendSearchRequestOverrides = useMemo(() => {
    const override: SearchRequestOverride = {
      pageNumber: 1,
      pageSize: 50,
      doAggregation: true,
      returnDocuments: false,
      conditions: {
        condition: 'must',
        termFilters: [
          {
            fieldName: 'periodStartDate',
            condition: 'should',
            values: [`${startDayId}`]
          },
          {
            fieldName: 'retailerId',
            condition: 'should',
            values: [retailerId]
          },
          {
            fieldName: 'periodEndDate',
            condition: 'should',
            values: [`${endDayId}`]
          },
          {
            fieldName: 'segmentId',
            condition: 'should',
            values: ['DEFAULT']
          }
        ],
        computeFilters: []
      },
      aggregations: [
        {
          aggregationFields: [
            {
              aggregateByFieldName: 'retailerId',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'spend',
              function: 'sum',
              canBeExported: true
            }
          ],
          groupByFieldName: 'adProductType.keyword,currencyIsoCode.keyword'
        }
      ],
      processDocuments: false
    };
    return [override];
  }, [startDayId, endDayId, retailerId]);

  const adSpendRequestContextOverrides: Partial<ESSRequestContext> = useMemo(
    () => ({
      customResponseParser: ({ apiResponse, state }) => ({
        ...state[AD_MANAGER_STATE_PROPERTY_NAME],
        adSpendData: adSpendAdvancedSearchParser(apiResponse.data[0])
      })
    }),
    []
  );

  const { isLoading: adAnalyticsLoading, data: adAnalytics } = useConnectFetchEntityMetrics({
    indexName: 'amc-analysis-metrics',
    visualizationKey: 'advertisingAnalysis',
    searchRequestOverrides: adAnalyticsSearchRequestOverrides,
    requestContextOverrides: adAnalyticsRequestContextOverrides
  });

  const { isLoading: adSpendLoading, data: adSpend } = useConnectFetchEntityMetrics({
    indexName: 'amc-spend-metrics',
    visualizationKey: 'adSpend',
    searchRequestOverrides: adSpendSearchRequestOverrides,
    requestContextOverrides: adSpendRequestContextOverrides
  });

  return {
    isLoading: adAnalyticsLoading || adSpendLoading,
    data:
      adAnalytics && adAnalytics.advertisingAnalyticsData && adSpend && adSpend.adSpendData
        ? { ...adAnalytics.advertisingAnalyticsData, ...adSpend.adSpendData }
        : null
  };
};

export default useAdAnalyticsQuery;
