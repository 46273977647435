import React from 'react';
import PropTypes from 'prop-types';
import ViewDetails from '../../../components/ViewDetails/index';
import { Link } from 'react-router-dom';
import fontStyle from 'src/utils/fontStyle';

class SearchItem extends React.Component {
  static propTypes = {
    val: PropTypes.object.isRequired,
    selectedItem: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    queryParams: PropTypes.object.isRequired
  };

  static defaultProps = {
    selectedItem: {}
  };

  state = {};

  handleMouseOver = (isMouseOver) => {
    this.setState({
      isMouseOver
    });
  };

  buildSummaryUrl = (val) => {
    const { additionalParams, searchParams } = this.props.queryParams;
    if (val && val.id === 0) {
      // All Categories/Brands
      return `/${val.type}/0${searchParams}${additionalParams}`;
    }
    const params = new URLSearchParams(additionalParams);
    if (params.get('tab') === 'adManager') {
      params.set('subtab', 'keyMetrics');
    }
    const newParams = params.toString();
    return `/${val.type}${val.id ? `/${val.id}${searchParams}&${newParams}` : ''}`;
  };

  showViewDetails(val) {
    const { isMouseOver } = this.state;

    if (!isMouseOver) {
      return null;
    }

    return <ViewDetails url={this.buildSummaryUrl(val)} />;
  }

  showOwner = (val) => {
    if (!val.segment || val.segment.canEdit || !val.segment.owner) {
      return null;
    }

    return (
      <div style={{ fontSize: '12px', marginTop: '2px' }}>
        Created by {val.segment.owner.firstName} {val.segment.owner.lastName}
      </div>
    );
  };

  showChildEntities(val) {
    const { additionalParams, searchParams } = this.props.queryParams;
    if (!val.childEntities || val.categoryId === 0) {
      return null;
    }
    return (
      <div className="summary-page__child-search-item">
        {val.childEntities.map((childEntity) => (
          <div
            key={childEntity.id}
            style={{
              padding: '5px 5px 0px 0px'
            }}
          >
            <Link
              onClick={this.stopPropagation}
              to={`/${childEntity.type}/${childEntity.id}${searchParams}${additionalParams}`}
            >
              {childEntity.displayName}
            </Link>
          </div>
        ))}
      </div>
    );
  }

  stopPropagation = (evt) => {
    evt.stopPropagation();
  };

  render() {
    const { val, selectedItem, onClick } = this.props;
    return (
      <div
        className={`summary-page__search-item ${
          selectedItem && selectedItem.id === val.id ? 'summary-page__search-item--active' : ''
        }`}
        onMouseEnter={() => this.handleMouseOver(true)}
        onMouseLeave={() => this.handleMouseOver(false)}
        style={{ flex: '1 1', minWidth: '25%', maxWidth: '25%' }}
      >
        <div // eslint-disable-line
          style={{
            padding: '10px 5px 10px 15px',
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'space-between'
          }}
          onClick={() => onClick(val)}
        >
          <div style={{ alignSelf: 'center' }}>
            <div style={{ fontWeight: fontStyle.boldWeight }}>{val.displayName}</div>
            {this.showOwner(val)}
          </div>
          {!val.displayName.includes('All Categories') && (
            <div style={{ flex: '0 1', alignSelf: 'center', height: '30px' }}>{this.showViewDetails(val)}</div>
          )}
        </div>
        {this.showChildEntities(val)}
      </div>
    );
  }
}

export default SearchItem;
