import { useMemo } from 'react';
import { Entity } from 'sl-api-connector';
import { useAppSelector } from 'src/utils/Hooks';
import { buildMainEntityConditions } from 'src/components/EntityPage/Renderer/EntityPageRenderer';

const useBuildMainEntityConditions = () => {
  const mainEntity: Entity & { [key: string]: any } = useAppSelector((state) => state.entityService.mainEntity);
  const appName = useAppSelector((state) => state.app.name);
  const retailerId = useAppSelector((state) => state.retailer.id);

  return useMemo(
    () =>
      buildMainEntityConditions(
        { termFilters: [], rangeFilters: [] },
        {
          type: mainEntity.type,
          query: mainEntity.query,
          conditions: mainEntity.conditions,
          company: mainEntity.brandIds,
          id: mainEntity.id,
          campaignIds: mainEntity.campaignIds,
          parentCategoryId: mainEntity.parentCategoryId,
          categoryIds: mainEntity.categoryIds
        },
        { name: appName },
        { id: retailerId }
      ),
    [
      appName,
      mainEntity.brandIds,
      mainEntity.campaignIds,
      mainEntity.categoryIds,
      mainEntity.conditions,
      mainEntity.id,
      mainEntity.parentCategoryId,
      mainEntity.query,
      mainEntity.type,
      retailerId
    ]
  );
};

export default useBuildMainEntityConditions;
