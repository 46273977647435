// eslint-ignore
/* eslint-disable */
import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState, useEffect, useRef, ReactNode } from 'react';
import AdVideoPreview from 'src/components/Layout/Advertising/AdMediaManagementLayout/AdVideoPreview';
import { CheckCircle, MenuPlusIcon } from 'src/components/SvgIcons';
import { CaptionIcon, PlayVideo } from 'src/components/SvgIcons/SvgIcons';
import ClearIcon from '@mui/icons-material/Close';
interface MediaUploadProps {
  placeholderImage?: string | null;
  onFileSelected: (file: File | null) => void;
  validateFile?: (file: File) => Promise<{ isValid: boolean; message: string }>;
  children?: ReactNode;
  secondColumnBody?: ReactNode;
  isCaptions?: boolean | null;
  file: File;
}

const AdMediaUpload: React.FC<MediaUploadProps> = ({
  placeholderImage = null,
  file,
  onFileSelected,
  validateFile,
  children,
  secondColumnBody,
  isCaptions
}) => {
  const [feedback, setFeedback] = useState<{ text: string; color: string; isValid: boolean } | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [fileSrc, setFileSrc] = useState<string | null>(null);
  const fileRef = useRef<HTMLVideoElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    return () => {
      if (fileSrc) {
        URL.revokeObjectURL(fileSrc);
      }
    };
  }, [fileSrc]);

  useEffect(() => {
    if (fileSrc && !file) {
      // Clean up internal state
      cleanUpState();
    }
  }, [file]);

  const handlePlayPause = () => {
    if (fileRef.current) {
      if (isPlaying) {
        fileRef.current.pause();
      } else {
        fileRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (fileSrc) {
      URL.revokeObjectURL(fileSrc);
    }

    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      // Validate the extension
      if (validateFile) {
        const validationResult = await validateFile(file);
        if (validationResult.isValid !== true) {
          setFeedback({
            text: validationResult.message,
            color: '#f35379',
            isValid: false
          });
          event.target.value = '';
          return;
        } else {
          setFeedback({
            text: validationResult.message,
            color: '#39b3ae',
            isValid: true
          });
        }
      }

      setFileSrc(URL.createObjectURL(file));
      onFileSelected && onFileSelected(file);
    } else {
      setFileSrc(null);
      onFileSelected && onFileSelected(null);
    }

    // Clear the value of the file input. So user can reupload same file
    event.target.value = '';
  };

  const handleClickPlaceholder = () => {
    fileInputRef.current.click();
  };

  const cleanUpState = () => {
    if (fileSrc) {
      URL.revokeObjectURL(fileSrc);
    }
    setFileSrc(null);
    setFeedback(null);
    setIsPlaying(false);
  };

  const handleDelete = () => {
    cleanUpState();
    onFileSelected && onFileSelected(null);
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '2em'
      }}
    >
      <div style={{ position: 'relative', width: '560px', minHeight: '340px' }}>
        {!fileSrc ? (
          // Placeholder
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${placeholderImage})`,
              backgroundSize: 'cover',
              cursor: 'pointer',
              position: 'relative',
              borderRadius: 6,
              border: 'solid 1px #f6f9fd',
              backgroundColor: '#f6f9fd'
            }}
            onClick={handleClickPlaceholder}
          >
            <span
              style={{
                display: 'block',
                background: '#052849',
                width: 40,
                height: 40,
                objectFit: 'contain',
                borderRadius: '50%',
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <MenuPlusIcon />
            </span>
          </div>
        ) : (
          // File preview
          <>
            <AdVideoPreview hidePlay={isCaptions} videoUrl={fileSrc} />

            {!isCaptions && (
              <IconButton
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 10,
                  width: '100%',
                  height: '100%'
                }}
                onClick={handlePlayPause}
                disableRipple
              >
                {!isPlaying ? (
                  <PlayVideo style={{ width: 40 }} />
                ) : (
                  <div style={{ width: '100%', height: '60%' }}></div>
                )}
              </IconButton>
            )}

            {isCaptions && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  background: '#f7f9fd',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <CaptionIcon style={{ width: 64 }} />
              </div>
            )}

            {fileSrc && (
              <IconButton
                style={{
                  position: 'absolute',
                  zIndex: 10,
                  top: 0,
                  right: 0,
                  transform: 'translate(50%, -50%)',
                  background: '#052849',
                  color: '#fff'
                }}
                onClick={handleDelete}
                disableRipple
              >
                <Delete style={{ fill: '#fff' }} />
              </IconButton>
            )}
          </>
        )}

        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
      </div>
      <div style={{ alignSelf: 'center' }}>
        {secondColumnBody}
        <div style={{ minHeight: '2rem' }}>
          {feedback && (
            <div style={{ color: feedback.color, alignItems: 'center', display: 'flex' }}>
              <span
                style={{
                  height: '2rem',
                  width: '2rem',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {feedback.isValid ? (
                  <CheckCircle style={{ fill: '#39b3ae', width: '2.6rem' }} />
                ) : (
                  <ClearIcon
                    style={{
                      border: '1px solid #f45b7f',
                      borderRadius: '50%',
                      fill: '#f35379',
                      width: '1.3rem',
                      height: '1.3rem'
                    }}
                  />
                )}
              </span>
              {feedback.text}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdMediaUpload;
