/* eslint-disable react/prop-types */
import React from 'react';

import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { withPush } from 'src/utils/hoc';
import { buildMetricValue } from 'src/utils/metrics';
import { ESSDataKey, PushFn } from 'src/types/application/types';
import ReduxStore from 'src/types/store/reduxStore';
import { SingleLegendLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import './ScoreBarChart.scss';
import ArrowChange from 'src/components/common/ArrowChange/ArrowChange';
import { AdManagerKeyMetricWidget } from 'src/components/Layout/LayoutUtil';

const parseData = (metric: ESSDataKey, mainPropertyName: string, comparisonPropertyName: string) => {
  if (!metric.data || metric.data.length === 0) {
    return {
      mainDisplayValue: 0,
      renderChange: function renderChange() {
        return <ArrowChange metric={metric} value={0} />;
      }
    };
  }
  const mainValue = +metric.data[0][mainPropertyName];
  const comparisonValue = +metric.data[0][comparisonPropertyName];
  const { metricType, currencySymbol, dataType, locale } = metric;
  const mainDisplayData = buildMetricValue(mainValue, metricType, currencySymbol, true, dataType, locale);
  const mainDisplayValue = `${mainDisplayData.prefix || ''}${mainDisplayData.value}${mainDisplayData.suffix || ''}`;
  const metricsChangePercent = (mainValue - comparisonValue) / comparisonValue;

  if (metric.data[0][mainPropertyName] === undefined || metric.data[0][comparisonPropertyName] === undefined) {
    return {
      mainDisplayValue,
      renderChange: function renderChange() {
        return <div>--</div>;
      }
    };
  }
  return {
    mainDisplayValue,
    renderChange: function renderChange() {
      return <ArrowChange metric={metric} value={metricsChangePercent || 0} />;
    }
  };
};

const mapStateToProps = (state: ReduxStore) => ({
  ..._pick(state, ['entitySearchService']),
  mainEntity: state.entityService.mainEntity
});

const mapDispatchToProps = {};

const SingleLegend: React.FC<
  { widget: AdManagerKeyMetricWidget; push: PushFn; selectedMetric: any } & ReturnType<typeof mapStateToProps>
> = ({ widget, push, entitySearchService, selectedMetric }) => {
  const { data, view } = widget;
  let dataKey = `single_legend_container_${data.indexName}_${data.groupByField.name}`;

  const { groupByField, metricField } = data;

  if (['changeAction'].includes(metricField.name)) {
    dataKey = `single_legend_container_optimization`;
  }

  // for ad audit users
  if (['bidChanges', 'budgetChanges'].includes(widget.name)) {
    dataKey = `single_legend_container_optimization_${widget.name}`;
  }

  const { title } = view;

  const metricPropertyName = `${metricField.name}_by_${groupByField.name}`;
  const metric = _get(entitySearchService, [dataKey, metricPropertyName], {});
  const { comparisonTimePeriodRangeSuffix } = metric;
  const mainPropertyName = 'value';
  const comparisonPropertyName = `value${comparisonTimePeriodRangeSuffix}`;

  if (_isEmpty(metric) || !metric.data) {
    return (
      <div className="single_legend_cell">
        <SingleLegendLoading />
      </div>
    );
  }

  const { mainDisplayValue, renderChange } = parseData(metric, mainPropertyName, comparisonPropertyName);

  const handleClick = (event: any) => {
    event.preventDefault();
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('adSummaryMetric', metric.name);
    push(`?${searchParams.toString()}`);
  };

  const className = metric.name === _get(selectedMetric, ['data', 'metricField', 'name'], '') ? 'selected' : '';

  return (
    <div className={`single_legend_cell ${className}`} role="button" onClick={(evt) => handleClick(evt)}>
      <div className="single_legend_title">{title}</div>
      <div className="single_legend_data">
        <div className="main_value">{mainDisplayValue}</div> <div className="changed_value">{renderChange()}</div>
      </div>
    </div>
  );
};

export default withPush(connect(mapStateToProps, mapDispatchToProps)(SingleLegend));
