import { Box } from '@mui/system';
import { isArray } from 'lodash';
import _debounce from 'lodash/debounce';
import _isString from 'lodash/isString';
import queryString from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { withBus } from 'react-bus';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { BulkUploadButton, ClearFiltersButton, SaveButton } from 'src/components/Omni/OmniButton/OmniButton';
import OmniRetailerDropDownSelector from 'src/components/Omni/OmniDropDownSelector/OmniRetailerDropDownSelector';
import {
  StoreSegment,
  fillSegmentFieldInURL,
  findAvailableRetailer,
  generateQueryParamObj
} from 'src/components/Omni/OmniSegmentUtil';
import OmniClearButton from 'src/components/Omni/Search/OmniClearButton';
import OmniKeywordSearchWrapper from 'src/components/Omni/Search/OmniKeywordSearchWrapper';
import OmniSegmentNameField from 'src/components/Omni/Search/OmniSegmentNameField';
import OmniSelectorWrapper from 'src/components/Omni/Search/OmniSelectorWrapper';
import OmniBulkUpload from 'src/components/Search/AdvancedSearch/BulkUpload/OmniBulkUpload';
import KeywordsSearch from 'src/components/Search/AdvancedSearch/KeywordsSearch';
import { fetchOmniStoreListData, updateStoreListSegment } from 'src/store/modules/omni/OmniStoreList/operation';
import ReduxStore from 'src/types/store/reduxStore';
import { EventBus } from 'src/types/utils';
import { addPersistentQueryParams } from 'src/utils/browser';

const TextContent = () => (
  <p>
    Paste a list of Store ID to create a segment view. You can upload a CSV file containing the information with one per
    line, no comma or other formatting.
    <br />
    <br />
    Please note: there is a max limit of 500 records per upload and please select Location when entering Zip Codes.
  </p>
);

const wrapperComponentList = [
  {
    type: 'brand',
    CustomizeComponent: OmniSelectorWrapper
  },
  {
    type: 'category',
    CustomizeComponent: OmniSelectorWrapper
  },
  {
    type: 'subCategory',
    CustomizeComponent: OmniSelectorWrapper
  },
  {
    type: 'state',
    CustomizeComponent: OmniKeywordSearchWrapper
  },
  {
    type: 'city',
    CustomizeComponent: OmniKeywordSearchWrapper
  }
];

interface OmniStoreListSideBarProps extends RouteComponentProps {
  handleRequestChange: (
    newReqContent: { [key: string]: any },
    pageInfo: { pageNumber: number; pageSize: number }
  ) => void;
  eventBus: EventBus;
}

const OmniStoreListSideBar: React.FC<OmniStoreListSideBarProps> = ({
  handleRequestChange,
  location,
  history,
  eventBus
}: OmniStoreListSideBarProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();
  const retailer = useSelector((state: ReduxStore) => state.retailer);

  const allStoreSegData = useSelector((state: ReduxStore) => state.omniStoreSegmentService);
  const { data: allStoreSegmentData } = allStoreSegData;

  const queryParams = queryString.parse(location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: 10e8
  });
  const parsedState = fillSegmentFieldInURL(queryParams, 'store');
  const retailerFilter = findAvailableRetailer();
  if ((parsedState as StoreSegment).retailerIds.length === 0) {
    parsedState.retailerIds = retailerFilter;
  }
  const { name } = parsedState;
  const [nameForSegment, setNameForSegment] = useState(name || '');
  const app = useSelector((state: ReduxStore) => state.app);
  const mainTimePeriod = useSelector((store: ReduxStore) => store.mainTimePeriod);
  const { searchParams } = app.queryParams;

  const debounceNav = useRef(
    _debounce((queryId: string) => {
      history.push(`/store/${queryId}${searchParams}&tab=scorecard&subtab=all`);
    }, 700)
  ).current;

  const handleBulkUploadClick = () => {
    setOpenDialog((preVla) => {
      return !preVla;
    });
  };
  const handleChangeInputName = (inputValue: string) => {
    setNameForSegment(inputValue);
  };

  const handleChange = (newChange: { key: string; values: { i: string }[] }) => {
    parsedState.name = nameForSegment;
    const { key, values } = newChange;
    if (key === 'retailerIds') {
      parsedState.excludeStoreIds = [];
      parsedState.includeStoreIds = [];
    }
    const newParamObj = generateQueryParamObj(parsedState, newChange);
    parsedState[key] = [
      ...values.map((v) => (key.includes('CategoryIds') || key.includes('retailerIds') ? Number(v.i) : v.i))
    ];
    delete parsedState.name;
    delete parsedState.queryId;
    handleRequestChange(parsedState, { pageNumber: 1, pageSize: 20 });
    history.push(
      `/storeListsSearch?${addPersistentQueryParams(
        retailer,
        mainTimePeriod
      )}&entityType=segment&${queryString.stringify(newParamObj)}`
    );
  };

  const handleClear = () => {
    const { queryId } = parsedState;
    const newParamObj = generateQueryParamObj({ name, queryId }, {});
    Object.keys(parsedState).forEach((k) => {
      if (isArray(parsedState[k])) {
        parsedState[k] = [];
      }
    });
    handleRequestChange(parsedState, { pageNumber: 1, pageSize: 20 });
    history.push(
      `/storeListsSearch?${addPersistentQueryParams(
        retailer,
        mainTimePeriod
      )}&entityType=segment&${queryString.stringify(newParamObj)}`
    );
  };

  const handleClearFunction = (key: string) => {
    handleChange({ key, values: [] });
  };

  const handleExcludeInTable = (obj: { [key: string]: any }) => {
    const preData = parsedState.excludeStoreIds || [];
    handleChange({
      key: 'excludeStoreIds',
      values: [...preData, String(obj.id)].map((e) => ({
        i: e
      }))
    });
  };

  useEffect(() => {
    eventBus.on('addToExcludeSearchTerm', handleExcludeInTable);
    return () => {
      eventBus.off('addToExcludeSearchTerm', handleExcludeInTable);
      debounceNav.cancel();
    };
  }, [debounceNav, eventBus, location.search]);

  const handleSubmit = async () => {
    if (nameForSegment.length === 0) {
      return;
    }
    setIsUpdating(true);
    const { queryId } = parsedState;

    if (allStoreSegmentData.find((element) => element.name === nameForSegment && element.queryId !== queryId)) {
      alert(`You must pick a unique name for this segment; ${nameForSegment} already exists.`);
      setIsUpdating(false);
      return;
    }

    delete parsedState.name;
    delete parsedState.queryId;

    let requestBody = { name: nameForSegment, query: { ...parsedState }, segmentType: 'store' };
    if (_isString(queryId)) {
      requestBody = { ...requestBody, queryId };
    }
    const response = updateStoreListSegment(requestBody);
    response
      .then((data) => {
        setIsUpdating(false);
        const { data: newSegment } = data;
        const { queryId: newQueryId } = newSegment;
        dispatch(fetchOmniStoreListData());
        debounceNav(newQueryId);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const getIdFieldNameAndItemListKey = (filterType: string) => {
    const opt = {
      include: 'includeStoreIds',
      exclude: 'excludeStoreIds'
    };
    return opt[filterType];
  };

  return (
    <div
      className="omni-search-nav-container"
      style={{
        padding: '30px 0'
      }}
    >
      <Box>
        <form>
          <OmniSegmentNameField
            value={nameForSegment}
            handleChangeInputName={handleChangeInputName}
            errorText={nameForSegment.length === 0 ? 'This field is required' : ''}
            hintText="Name your Store List"
          />
          <KeywordsSearch
            idFieldName="includeStoreIds"
            headerDisplayName="Include"
            searchFieldHintText="Add Store ID to Include"
            keywords={parsedState.includeStoreIds.map((e) => ({ i: e }))}
            onKeywordsChange={handleChange}
            entityDefinition={{}}
            appName={app.name}
            renderClearButton={() => (
              <OmniClearButton
                data={parsedState.includeStoreIds}
                handleClearAll={() => {
                  handleClearFunction('includeStoreIds');
                }}
              />
            )}
          />
          <KeywordsSearch
            idFieldName="excludeStoreIds"
            headerDisplayName="Exclude"
            searchFieldHintText="Add Store ID to Exclude"
            keywords={parsedState.excludeStoreIds.map((e) => ({ i: e }))}
            onKeywordsChange={handleChange}
            entityDefinition={{}}
            appName={app.name}
            renderClearButton={() => (
              <OmniClearButton
                data={parsedState.excludeStoreIds}
                handleClearAll={() => {
                  handleClearFunction('excludeStoreIds');
                }}
              />
            )}
          />
          <OmniRetailerDropDownSelector parsedState={parsedState} handleChange={handleChange} />

          {wrapperComponentList.map(({ type, CustomizeComponent }, index) => (
            <CustomizeComponent
              navEndPoint="storeListsSearch"
              nameForSegment={nameForSegment}
              parsedState={parsedState}
              selectorType={type}
              retailer={retailer}
              mainTimePeriod={mainTimePeriod}
              history={history}
              handleChange={handleChange}
              key={index}
              handleRequestChange={handleRequestChange}
            />
          ))}
          <SaveButton
            style={{ marginTop: 14 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {isUpdating ? 'SAVE SEGMENT...' : 'SAVE SEGMENT'}
          </SaveButton>
          <>
            <BulkUploadButton secondary style={{ marginTop: 14 }} onClick={handleBulkUploadClick}>
              Bulk Upload
            </BulkUploadButton>
            <ClearFiltersButton secondary style={{ marginTop: 14 }} onClick={handleClear}>
              Clear Filters
            </ClearFiltersButton>

            <OmniBulkUpload
              open={openDialog}
              itemType="Store ID"
              filterOpt={['include', 'exclude']}
              title="Store ID Bulk Upload"
              onClose={handleBulkUploadClick}
              parsedState={parsedState}
              getIdFieldNameAndItemListKey={getIdFieldNameAndItemListKey}
              onKeywordsChange={(newChange: { key: string; values: { i: string }[] }) => {
                handleChange(newChange);
              }}
              TextContent={<TextContent />}
            />
          </>
        </form>
      </Box>
    </div>
  );
};

export default withBus('eventBus')(OmniStoreListSideBar);
