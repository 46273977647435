import { Widget } from 'src/types/application/widgetTypes';
import SearchResultsGridV3 from './SearchResultsGridV3';
import React from 'react';
import useSrgData from './hooks/useSrgData';
import { withRouter } from 'react-router';
import { withBus } from 'react-bus';
import { Location } from 'history';
import PortfolioColumn from 'src/components/AdManager/Search/CustomColumns/PortfolioColumn';
import BudgetColumn from 'src/components/AdManager/Search/CustomColumns/BudgetColumn';
import ProjectedBudgetColumn from 'src/components/AdManager/Search/CustomColumns/ProjectedBudgetColumn';
import useFetchPortfoliosData from './hooks/useFetchPortfoliosData';

interface PortfoliosGridProps {
  widget: Widget;
  location: Location;
}
/**
 * Display a list of Portfolios. This can be found on the Drive landing page.
 */
const PortfoliosGrid: React.FC<PortfoliosGridProps> = ({ widget, location }) => {
  const fetchPortfoliosData = useFetchPortfoliosData();

  const srgData = useSrgData({
    widget,
    location,
    fetchEntityData: fetchPortfoliosData
  });

  return (
    <SearchResultsGridV3
      columnDefs={[
        {
          headerName: 'Portfolio',
          field: 'portfolioId',
          width: undefined,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left' },
          minWidth: 300,
          maxWidth: 800,
          cellRendererFramework: PortfolioColumn,
          headerClass: 'align-left',
          pinnedRowCellRenderer: 'simpleColumn',
          pinnedRowCellRendererParams: { text: 'Total', style: { fontWeight: 'bold' } },
          pinned: 'left'
        },
        {
          headerName: 'Budget',
          field: 'projectedSpend',
          width: undefined,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important' },
          minWidth: 150,
          maxWidth: 150,
          cellRendererFramework: BudgetColumn,
          headerClass: 'align-left',
          pinned: 'left'
        },
        {
          headerName: 'Projected Spend',
          field: 'projectedSpend',
          width: undefined,
          cellStyle: { 'justify-content': 'flex-start', 'text-align': 'left !important' },
          minWidth: 150,
          maxWidth: 150,
          cellRendererFramework: ProjectedBudgetColumn,
          headerClass: 'align-left',
          pinned: 'left'
        }
      ]}
      getRowNodeId={(row) => row.id}
      widget={widget}
      {...srgData}
    />
  );
};

export default withRouter(withBus('eventBus')(PortfoliosGrid));
