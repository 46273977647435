import { useUserId } from 'src/utils/Hooks/reduxSelectors';

import { AdjustmentDataItem } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/utils';
import adjustmentDownloadServerProxy from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/serverProxy/AdjustmentDownload';

export interface ServerProxy {
  /**
   *
   * Fetch adjustments for the current user, this is also using when polling the list
   */
  fetchAdjustmentsList: (
    paginationToken: string
  ) => Promise<{ result: AdjustmentDataItem[]; paginationToken: string | null }>;
}

/**
 * Returns a server proxy for download page
 */
const useAdjustmentDownloadServerProxy = (): ServerProxy => {
  const userId = useUserId();
  const pageSize = 20;

  const proxy = adjustmentDownloadServerProxy(userId, pageSize);

  return { fetchAdjustmentsList: proxy.fetchAdjustmentsList };
};

export default useAdjustmentDownloadServerProxy;
