import React from 'react';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import ProductListContainer from 'src/components/BeaconRedesignComponents/MetricList/ProductListContainer';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { useProductEntity } from 'src/utils/Hooks/reduxSelectors';
import { removeComparisonFormatter } from 'src/components/BeaconRedesignComponents/MetricList/utils';
import MetricListContainer from 'src/components/BeaconRedesignComponents/MetricList/MetricListContainer';
import { getWeekColumnDefinition } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import { SALES_TABLE_HEADER_DISPLAY_NAMES } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Summary/Sales/constants';

const fields = [
  {
    name: 'retailSales',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.RETAIL_SALES
  },
  {
    name: 'unitsSold',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.UNITS_SOLD
  },
  {
    name: 'retailPrice',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.RETAIL_PRICE
  },
  {
    name: 'mapPrice',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.MAP_PRICE
  },
  {
    name: 'mapPriceViolation',
    displayName: SALES_TABLE_HEADER_DISPLAY_NAMES.MAP_PRICE_VIOLATION
  }
];

/**
 * Prices MAP Violations Page
 */
const PricesMAPViolationsPage = () => {
  const { isProductEntity } = useProductEntity();
  const weekEndingFields = fields.map(removeComparisonFormatter);

  return (
    <BeaconPageContainer>
      <Flex gap={BEACON_CHART_SPACING} flexDirection="column">
        <BeaconComparableSplineChart title="MAP Violations" indexName="sales" fieldName="mapPriceViolation" />
        {isProductEntity ? (
          <MetricListContainer
            entityTableHeaderProps={{
              title: 'MAP Violations by Week'
            }}
            indexName="sales"
            sortFieldName="mapPriceViolation"
            fields={weekEndingFields}
            headColumnDefinition={getWeekColumnDefinition({
              getTableColumnProps(defaults) {
                return {
                  ...defaults,
                  style: {
                    ...defaults.style,
                    width: '220px'
                  }
                };
              }
            })}
            postProcessData={(data) => {
              return data.sort((a, b) => b.weekId.localeCompare(a.weekId));
            }}
            useTableDataProps={{
              groupByFieldName: 'weekId',
              filterResponse: () => true,
              itemsPerPage: 100
            }}
            showGrid={false}
          />
        ) : (
          <ProductListContainer
            indexName="sales"
            fields={fields}
            sortFieldName="mapPriceViolation"
            entityTableHeaderProps={{
              title: 'MAP Violations by Product'
            }}
          />
        )}
      </Flex>
    </BeaconPageContainer>
  );
};

export default PricesMAPViolationsPage;
