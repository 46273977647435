import React from 'react';
import PaidSearchDoubleOptionBarChart from './PaidSearchDoubleOptionBarChart';
import PaidSearchKeyMetricCards from './PaidSearchKeyMetricCards';
import AdvertisingMultiMetricTrendChart from './AdvertisingMultiMetricTrendChart';
import PaidSearchGrid from './PaidSearchGrid';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import {
  BEACON_CHART_SPACING,
  BEACON_PRO_PAGE_MARGIN_BOTTOM
} from 'src/components/Layout/Beacon/BeaconProLayoutConsts';

/**
 * Traffic paid search tab
 */
// TODO: (Topics) Use ad-product-metrics index when available for topics
export default function PaidSearchPage() {
  return (
    <Flex flexDirection="column" gap={BEACON_CHART_SPACING} marginBottom={BEACON_PRO_PAGE_MARGIN_BOTTOM}>
      <PaidSearchKeyMetricCards />
      <PaidSearchDoubleOptionBarChart />
      <AdvertisingMultiMetricTrendChart />
      <PaidSearchGrid />
    </Flex>
  );
}
