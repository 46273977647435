import React, { FC, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { DonutChart } from 'src/components/Charts/Donut';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import { useAsyncHook } from 'src/utils/Hooks/typedHooks';
import { OmniBaseRequestBody, addFilterToOmniBaseReqBody } from 'src/components/Omni/omniRequestUtils';
import { OmniDonutAsync, OmniDonutData } from 'src/components/Omni/OmniDonutChart/OmniDonutUtil';
import 'src/components/Charts/Donut/DonutChart.scss';

interface OmniDonutChartProps extends RouteComponentProps {
  reviewKeyword?: string;
  handleStarValuesChange: (selectedPoints: Highcharts.Point[]) => void;
}

const OmniDonutChartReview: FC<OmniDonutChartProps> = ({ reviewKeyword, handleStarValuesChange, location }) => {
  const { data, loading, run } = useAsyncHook<OmniDonutData[]>([]);
  const mainTimePeriod = useSelector((state: ReduxStore) => state.mainTimePeriod);
  const filters = useSelector((state: ReduxStore) => state.filters);
  const { pathname } = location;
  const { startWeek, endWeek } = mainTimePeriod;
  const widget = {
    view: {
      name: 'donutChart',
      chartPropsOverride: {
        chart: {
          type: 'pie'
        },
        disablePointSelect: false,
        enableMultiSelect: true,
        plotOptions: {
          pie: {
            cursor: 'pointer'
          },
          series: {
            cursor: 'pointer'
          }
        }
      }
    },
    data: {
      mainMetric: {
        name: 'stars',
        displayName: 'Review Trends',
        metricType: 'DECIMAL',
        aggregationFunction: 'stats',
        dataType: 'DECIMAL',
        overrides: {
          weekId_Override: {
            timePeriodAggregationFunctionType: 'trueAvg',
            timePeriodAggregationFunction: 'avg'
          }
        },
        timePeriodAggregationFunctionType: 'trueAvg',
        timePeriodAggregationFunction: 'avg',
        appName: 'omni',
        indexName: 'reviews'
      },
      secondaryMetric: {
        name: 'stars',
        displayName: 'Avg. Star Rating',
        metricType: 'DECIMAL',
        aggregationFunction: 'stats',
        dataType: 'DECIMAL',
        overrides: {
          weekId_Override: {
            timePeriodAggregationFunctionType: 'trueAvg',
            timePeriodAggregationFunction: 'avg'
          }
        },
        appName: 'omni',
        indexName: 'reviews'
      }
    }
  };
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const mainEntityMetrics = {
    name: 'donutChart',
    data,
    title: 0
  };

  if (!loading) {
    let totalStar = 0;
    let totalCount = 0;
    if (data) {
      data.forEach((d) => {
        totalStar += d.y * d.fieldId;
        totalCount += d.y;
      });
    }
    mainEntityMetrics.title = totalStar / totalCount;
  }

  const fetchData = useCallback(() => {
    const requestBody: OmniBaseRequestBody = {
      retailerIds: [],
      includeBrandIds: [],
      excludeBrandIds: [],
      excludeCategoryIds: [],
      startWeekId: startWeek,
      endWeekId: endWeek,
      productIds: [],
      includeKeywords: [],
      excludeKeywords: [],
      groupBy: 'stars',
      reviewKeywords: reviewKeyword ? [reviewKeyword] : [],
      starsFilter: ['5', '4', '3', '2', '1']
    };
    const requestBodyAddFilter = addFilterToOmniBaseReqBody(requestBody, filters, pathname);
    run(OmniDonutAsync(requestBodyAddFilter));
  }, [startWeek, endWeek, reviewKeyword, filters, pathname]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      {loading ? null : (
        <DonutChart
          widget={widget}
          mainEntityMetrics={mainEntityMetrics}
          isStarChart
          retailer={retailer}
          onDonutChartChange={handleStarValuesChange}
        />
      )}
    </div>
  );
};

export default withRouter(OmniDonutChartReview);
