import React, { useMemo, useState } from 'react';
import DonutChartV2 from 'src/components/BeaconRedesignComponents/DonutChart/DonutChartV2';
import DonutChartLoading from 'src/components/Charts/Donut/DonutChartLoading';
import { useMetricByWeekId } from 'src/serverProxy/useMetricByWeekId';
import { useStacklineTheme } from '@stackline/ui';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import BeaconMetricSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconMetricSplineChart';
import BeaconChartWithLegend from 'src/components/BeaconRedesignComponents/BeaconChartWithLegend/BeaconChartWithLegend';
import SplineChart from 'src/components/BeaconRedesignComponents/SplineChart/SplineChart';
import SplineChartLoading from 'src/components/BeaconRedesignComponents/SplineChartLoading/SplineChartLoading';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import { getVolumeStringParts } from 'src/utils/stringFormatting';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

const SHORTAGE_INDEX_NAME = 'sd-asinDetailMetrics';

/**
 * Displays the donut chart, mini spline chart and spline chart with dropdwon for the shortage page
 */
const ShortagesSummaryPageLayout = () => {
  const theme = useStacklineTheme();
  const formatMetric = useMetricFormatter();

  const [fieldName, setFieldName] = useState('invoiceAmount');

  /** ********* Donut chart metrics ********* */
  const { primaryTotal: notYetDisputedShortageAmount, isLoading: isNotYetDisputedShortageAmountLoading } =
    useMetricByWeekId({
      indexName: SHORTAGE_INDEX_NAME,
      fieldName: 'notYetDisputedShortageAmountV2'
    });

  // Update this to disputeUnderReviewAmount
  const { primaryTotal: disputeUnderReviewShortageAmount, isLoading: isDisputeUnderReviewShortageAmountLoading } =
    useMetricByWeekId({
      indexName: SHORTAGE_INDEX_NAME,
      fieldName: 'disputeUnderReviewShortageAmountV2'
    });

  const { primaryTotal: recoveredShortageAmount, isLoading: isRecoveredShortageAmountLoading } = useMetricByWeekId({
    indexName: SHORTAGE_INDEX_NAME,
    fieldName: 'recoveredShortageAmountV2'
  });

  const { primaryTotal: lostShortageAmount, isLoading: isLostShortageAmountLoading } = useMetricByWeekId({
    indexName: SHORTAGE_INDEX_NAME,
    fieldName: 'lostShortageAmountV2'
  });

  // Used in trend chart
  const { primaryData: disputeWonAmount, isLoading: isDisputeWonAmountLoading } = useMetricByWeekId({
    indexName: SHORTAGE_INDEX_NAME,
    fieldName: 'disputeWonAmount'
  });

  const { primaryData: currentTotalShortageAmount, isLoading: isCurrentTotalShortageAmountLoading } = useMetricByWeekId(
    {
      indexName: SHORTAGE_INDEX_NAME,
      fieldName: 'updatedShortageAmountBeforeDispute'
    }
  );

  const donutLoading = useMemo(() => {
    return (
      isNotYetDisputedShortageAmountLoading ||
      isDisputeUnderReviewShortageAmountLoading ||
      isRecoveredShortageAmountLoading ||
      isLostShortageAmountLoading
    );
  }, [
    isNotYetDisputedShortageAmountLoading,
    isDisputeUnderReviewShortageAmountLoading,
    isRecoveredShortageAmountLoading,
    isLostShortageAmountLoading
  ]);

  const miniSplineLoading = useMemo(() => {
    return isDisputeWonAmountLoading || isCurrentTotalShortageAmountLoading;
  }, [isCurrentTotalShortageAmountLoading, isDisputeWonAmountLoading]);

  const primaryLegendDisplayName = useAppSelector((state) => state.mainTimePeriod.displayName);

  const renderDonut = () => {
    if (donutLoading) {
      return <DonutChartLoading />;
    }

    const formattedShortages = `${formatMetric(
      notYetDisputedShortageAmount + disputeUnderReviewShortageAmount + recoveredShortageAmount + lostShortageAmount,
      METRICTYPE.MONEY,
      {
        showFullValue: false,
        decimalPlaces: 1
      }
    )}`;

    const { value, label, currencySign } = getVolumeStringParts(formattedShortages);

    return (
      <DonutChartV2
        title={
          <Flex alignItems="center" gap="sm">
            <Text variant="h4">{currencySign}</Text>
            <Text
              sx={{
                fontSize: '48px',
                fontWeight: 'bold'
              }}
            >
              {value}
            </Text>
            <Text variant="h4">{label}</Text>
          </Flex>
        }
        getData={[
          {
            fieldId: 'notYetDisputedShortageAmountV2',
            name: 'NOT YET DISPUTED AMOUNT',
            y: notYetDisputedShortageAmount
          },
          {
            fieldId: 'disputeUnderReviewShortageAmountV2',
            name: 'DISPUTES UNDER REVIEW AMOUNT',
            y: disputeUnderReviewShortageAmount
          },
          {
            fieldId: 'recoveredShortageAmountV2',
            name: 'RECOVERED SHORTAGE AMOUNT',
            y: recoveredShortageAmount
          },
          {
            fieldId: 'lostShortageAmountV2',
            name: 'LOST SHORTAGE AMOUNT',
            y: lostShortageAmount
          }
        ]}
      />
    );
  };

  return (
    <Flex flexDirection="column" gap={BEACON_CHART_SPACING} marginTop={theme.spacing.lg}>
      <Flex flexDirection="column" gap="lg">
        <CommonSummaryInfoSubtitle title="Total Shortages" />
        <Flex justifyContent="space-between" alignItems="center">
          <div>{renderDonut()}</div>
          <div style={{ width: '624px' }}>
            <BeaconChartWithLegend
              title=""
              showTitleSummary={false}
              primaryLegendProps={{
                displayName: primaryLegendDisplayName,
                metric: 'Total Shortages'
              }}
              comparisonLegendProps={{
                displayName: primaryLegendDisplayName,
                metric: 'Disputes Won'
              }}
              loading={miniSplineLoading}
              loadingComponent={<SplineChartLoading width={530} />}
            >
              <SplineChart
                primaryData={currentTotalShortageAmount}
                secondaryData={disputeWonAmount}
                metricType={METRICTYPE.MONEY}
                chartOptionsOverride={{
                  chart: {
                    height: 230
                  }
                }}
              />
            </BeaconChartWithLegend>
          </div>
        </Flex>
      </Flex>

      <BeaconMetricSplineChart
        indexName={SHORTAGE_INDEX_NAME}
        fieldName={fieldName}
        singleDropdownProps={{
          defaultLabel: 'Invoices',
          options: [
            {
              id: 'invoiceAmount',
              label: 'Invoices'
            },
            {
              id: 'updatedShortageAmountBeforeDispute',
              label: 'Shortages'
            },
            {
              id: 'disputeSubmittedAmount',
              label: 'Disputes Submitted'
            },
            {
              id: 'stacklineSku',
              label: 'SKUs with Shortages'
            },
            {
              id: 'shortageRatio',
              label: 'Shortage Ratio'
            },
            {
              id: 'disputeNeedActionAmount',
              label: 'Disputes Needing Action'
            },
            {
              id: 'disputeUnderReviewAmount',
              label: 'Disputes Under Review'
            },
            {
              id: 'disputeWonAmount',
              label: 'Disputes Won'
            },
            {
              id: 'disputeLostAmount',
              label: 'Disputes Lost'
            }
          ],
          selectedId: fieldName,
          onChange: (option) => setFieldName(option.id)
        }}
      />
    </Flex>
  );
};

export default ShortagesSummaryPageLayout;
