import React from 'react';
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import { SxProps } from '@mui/system';
import { useStacklineTheme } from '@stackline/ui';

interface IconButtonProps extends MuiIconButtonProps {
  sxProps?: SxProps;
}

/**
 * Default Hover effect styles for IconButtons
 */
const IconButton: React.FC<IconButtonProps> = ({ sxProps, ...iconButtonProps }) => {
  const theme = useStacklineTheme();
  return (
    <MuiIconButton
      {...iconButtonProps}
      sx={{
        height: theme.spacing.mdl,
        width: theme.spacing.mdl,
        borderRadius: theme.spacing.xs,
        color: theme.colors.primary,
        ...sxProps
      }}
    />
  );
};

export default IconButton;
