import React, { useMemo } from 'react';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { NTB_PRIMARY_OVERTIME_EVENT, NTB_SECONDARY_OVERTIME_EVENT } from './constants';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import { NewToBrandOverTimeDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import GenericChart from 'src/components/Charts/GenericChart';
import { createNonComparisonLegendDivs } from 'src/utils/chartUtils';
import { getTooltipWithHeaderAndValueDiv } from 'src/components/EntityPage/Renderer/tooltipDiv';
import moment from 'moment';
import { convertNewToBrandOverTimeDataToCSV } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/NewToBrandTab/NewToBrandOverTime/utils';
import {
  NewToBrandLineChartProps,
  GenericChartParameters,
  MetricDataForTrendChart
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/NewToBrandTab/NewToBrandOverTime/types';

/**
 *
 * @returns Line chart displaying New to Brand metrics
 */
const NewToBrandLineChart = ({ data, primaryMetric, secondaryMetric, legendValue }: NewToBrandLineChartProps) => {
  const { app, mainTimePeriod, comparisonTimePeriod } = useAppSelector((state) => state);

  const formatMetric = useMetricFormatter();
  const buttons = useMemo(() => {
    return [
      [
        {
          displayName: 'New-to-Brand Ad Sales',
          eventId: NewToBrandOverTimeDropdownOption.AdSalesPercent,
          eventName: NTB_PRIMARY_OVERTIME_EVENT,
          isSelected: primaryMetric === NewToBrandOverTimeDropdownOption.AdSalesPercent,
          groupByTextOverride: 'for'
        },
        {
          displayName: 'New-to-Brand Ad Units Sold',
          eventId: NewToBrandOverTimeDropdownOption.AdUnitsSoldPercent,
          eventName: NTB_PRIMARY_OVERTIME_EVENT,
          isSelected: primaryMetric === NewToBrandOverTimeDropdownOption.AdUnitsSoldPercent,
          groupByTextOverride: 'for'
        },
        {
          displayName: 'New-to-Brand Conversions',
          eventId: NewToBrandOverTimeDropdownOption.ConversionsPercent,
          eventName: NTB_PRIMARY_OVERTIME_EVENT,
          isSelected: primaryMetric === NewToBrandOverTimeDropdownOption.ConversionsPercent,
          groupByTextOverride: 'for'
        }
      ],
      [
        {
          displayName: 'All Ads',
          eventId: NewToBrandOverTimeDropdownOption.AllAdsTypes,
          eventName: NTB_SECONDARY_OVERTIME_EVENT,
          isSelected: secondaryMetric === NewToBrandOverTimeDropdownOption.AllAdsTypes
        },
        {
          displayName: 'DSP Ads',
          eventId: NewToBrandOverTimeDropdownOption.DemandSidePlatformAds,
          eventName: NTB_SECONDARY_OVERTIME_EVENT,
          isSelected: secondaryMetric === NewToBrandOverTimeDropdownOption.DemandSidePlatformAds
        },
        {
          displayName: 'Search Ads',
          eventId: NewToBrandOverTimeDropdownOption.SearchAds,
          eventName: NTB_SECONDARY_OVERTIME_EVENT,
          isSelected: secondaryMetric === NewToBrandOverTimeDropdownOption.SearchAds
        }
      ]
    ];
  }, [primaryMetric, secondaryMetric]);

  const chartProps: Highcharts.Options = useMemo(
    () => ({
      chart: {
        type: 'areaspline',
        height: 500
      },
      title: {
        text: '',
        x: -10
      },
      subtitle: {
        text: '',
        x: -10,
        style: {
          height: '51px'
        }
      },
      plotOptions: {
        events: {
          legendItemClick() {
            // Disable legend click to avoid hiding the single series
            return false;
          }
        },
        fillEnabled: true,
        series: {
          states: {
            hover: {
              enabled: true
            }
          }
        }
      },
      legend: {
        labelFormatter() {
          const label = `${primaryMetric.replace('New-to-Brand', 'NTB')} (${formatMetric(
            legendValue,
            METRICTYPE.PERCENT,
            {
              showFullValue: true,
              decimalPlaces: 2
            }
          )})`;

          const legendDivs: string[] = [];
          createNonComparisonLegendDivs({
            chartDisplayTimePeriod: mainTimePeriod,
            mainLegendMetricValue: {
              value: label
            },
            isSameMetricComparison: true,
            metricsDataByTimeSeries: null,
            legendDivs
          });

          return legendDivs[0];
        }
      },
      tooltip: {
        enabled: true,
        shared: true,
        formatter() {
          const toolTipValue = formatMetric(this.points[0].y / 100, METRICTYPE.PERCENT, {
            showFullValue: true,
            decimalPlaces: 2
          });
          const timeStamp = moment.utc(this.points[0].x).format('MMM D');
          return getTooltipWithHeaderAndValueDiv({ headerContent: timeStamp, mainContent: toolTipValue });
        }
      },
      useUTC: false,
      xAxis: [
        {
          type: 'datetime',
          dateTimeLabelFormats: {
            day: '%b %e'
          }
        }
      ],
      yAxis: [
        {
          labels: {
            formatter() {
              return `${this.value}%`;
            }
          }
        }
      ],
      exporting: {
        buttons,
        enabled: true,
        showCompare: false
      }
    }),
    [buttons, primaryMetric, formatMetric, legendValue, mainTimePeriod]
  );

  const metricData: MetricDataForTrendChart = useMemo(
    () => ({
      metricType: 'PERCENT',
      marker: {
        enabled: false
      },
      events: {},
      color: '#46a8f6',
      point: { events: {} },
      customEvents: { series: {}, point: {} },
      data,
      name: primaryMetric
    }),
    [data, primaryMetric]
  );

  const chartParams: GenericChartParameters = {
    app,
    chartProps,
    chartSeries: [metricData],
    comparisonTimePeriod
  };

  return (
    <GenericChart
      convertSeriesToDelimitedData={() =>
        convertNewToBrandOverTimeDataToCSV({
          data,
          dateRange: mainTimePeriod.displayName,
          primaryMetric,
          secondaryMetric
        })
      }
      {...chartParams}
    />
  );
};

export default NewToBrandLineChart;
