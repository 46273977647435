import React from 'react';
import MetricListContainer, { MetricListContainerProps } from './MetricListContainer';
import { ProductColumnDefinition } from 'src/components/BeaconRedesignComponents/MetricList/headColumnDefinitions';
import ProductGrid, { ProductGridProps } from 'src/components/BeaconRedesignComponents/ProductGrid/ProductGrid';

export interface PaginationRequestOverride {
  termFilters: { fieldName: string; condition: string; values: (string | number)[] }[];
  rangeFilters: { fieldName: string; minValue: number; maxValue: number }[];
}

type ProductListContainerProps = Pick<
  MetricListContainerProps,
  | 'indexName'
  | 'fields'
  | 'sortFieldName'
  | 'mainFieldName'
  | 'entityTableHeaderProps'
  | 'showGrid'
  | 'useTableDataProps'
  | 'columnDefinitions'
  | 'postProcessData'
  | 'onChangePage'
  | 'buildRequest'
  | 'groupBy'
  | 'iconList'
> & {
  productGridProps?: Partial<ProductGridProps>;
} & Partial<Pick<MetricListContainerProps, 'headColumnDefinition' | 'loading'>>;

/**
 * Wrapper around the MetricListContainer to specifically render
 * a list of products by a given set of metrics
 */
export default function ProductListContainer({
  productGridProps,
  headColumnDefinition = ProductColumnDefinition,
  ...rest
}: ProductListContainerProps) {
  return (
    <MetricListContainer
      headColumnDefinition={headColumnDefinition}
      renderGrid={(args) => <ProductGrid {...args} {...productGridProps} />}
      {...rest}
    />
  );
}
