import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from 'react-number-format';
import StandardSelect from 'src/components/common/DropDown/StandardDropdown';

import InfoTooltip from 'src/components/AdCampaignBuilder/Widgets/InfoTooltip';
import 'src/components/Header/Header.scss';
import { ChevronIcon } from 'src/components/SvgIcons';
import '../AdManagerSettings.scss';
import { InstacartOptimizationStatus } from '../utils';
import { useSelector } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import withStyles, { WithStyles } from '@mui/styles/withStyles';
import { muiStyles } from './utils';
import Typography from '@mui/material/Typography';

interface InstacartBiddingProps extends WithStyles<typeof muiStyles> {
  /**
   * Whether Instacart optimization is enabled or disabled.
   */
  status: InstacartOptimizationStatus;
  /**
   * Callback for when the Instacart optimization status is changed.
   */
  setStatus: (newStatus: InstacartOptimizationStatus) => void;
  /**
   * Minimum RoAS for the campaign with Instacart.
   */
  minRoas: number;
  /**
   * Callback for when minimum RoAS for the campaign is changed.
   */
  setMinRoas: (newMinRoas: number) => void;
  /**
   * Red text error to display if there is an error
   */
  error?: string;

  /**
   * If true, user will not be able to adjust the status
   * or min RoAS
   */
  disabled?: boolean;

  /**
   * If true, then instacart bidding will automatically be set
   * to Disabled when the `disabled` prop is true. If this `disabled`
   * is true and `resetWhenDisabled` is false (default), then
   * the status dropdown and minimum RoAS fields will be disabled
   */
  resetWhenDisabled?: boolean;
}
/**
 * Component that allows enabling Instacart bidding
 * and setting a min RoAS when it is enabled. Currently,
 * it is used in the edit campaign and create campaign
 * pages.
 */
const InstacartBidding: React.FC<InstacartBiddingProps> = ({
  classes,
  status,
  setStatus,
  minRoas,
  setMinRoas,
  error,
  disabled,
  resetWhenDisabled
}) => {
  const { retailer } = useSelector((state: ReduxStore) => state);

  useEffect(() => {
    if (disabled && resetWhenDisabled) {
      setStatus(InstacartOptimizationStatus.Disabled);
      setMinRoas(0);
    }
  }, [disabled, resetWhenDisabled]);

  return (
    <div className="setting_row">
      <div className="label">Instacart Bidding:</div>
      <div>
        <Grid container marginBottom={2} alignItems="center">
          <Grid item>
            <StandardSelect
              style={{ marginRight: 20 }}
              value={status}
              onChange={({ target: { value } }) => {
                setStatus(value as InstacartOptimizationStatus);
              }}
              classes={{ select: classes.select, disabled: classes.disabled }}
              IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
              disabled={disabled}
            >
              <MenuItem value={InstacartOptimizationStatus.Enabled}>Enabled</MenuItem>
              <MenuItem value={InstacartOptimizationStatus.Disabled}>Disabled</MenuItem>
            </StandardSelect>
          </Grid>
          <Grid item>
            <InfoTooltip>
              Enabling Instacart Bidding will allow Instacart to automatically optimize campaign bids to maximize sales
              at lowers cost per click possible.
            </InfoTooltip>
          </Grid>
          {error && (
            <Grid item ml={2}>
              <Typography fontSize={16} color="error">
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
        {status === InstacartOptimizationStatus.Enabled && (
          <Grid container alignItems="center">
            <Grid item mr={2}>
              Minimum RoAS
            </Grid>
            <Grid item>
              <NumberFormat
                disabled={disabled}
                value={minRoas}
                thousandSeparator
                prefix={retailer.currencySymbol}
                decimalScale={2}
                fixedDecimalScale
                allowNegative={false}
                onValueChange={({ floatValue }) => setMinRoas(floatValue)}
                style={{
                  height: '32px',
                  outline: 'none',
                  border: '0',
                  borderBottomColor: 'currentcolor',
                  borderBottomStyle: 'none',
                  borderBottomWidth: '0px',
                  background: 'none',
                  borderBottom: '1px solid #eee',
                  width: '100px',
                  fontSize: '16px',
                  color: 'currentColor',
                  fontFamily: 'Roboto'
                }}
              />
            </Grid>
            <Grid item>
              <InfoTooltip>
                Optionally, enter a minimum RoAS goal for Instacart&#39;s algorithm to maintain at the campaign level.
              </InfoTooltip>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default withStyles(muiStyles)(InstacartBidding) as React.FC<Omit<InstacartBiddingProps, 'classes'>>;
