import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import ProductItem from './ProductItem';

class ListContainer extends React.Component {
  static defaultProps = {
    onSelectItem: () => {}
  };

  static propTypes = {
    dataItem: PropTypes.object.isRequired,
    onSelectItem: PropTypes.func
  };

  render() {
    const { dataItem, onSelectItem } = this.props;
    return (
      <MenuItem
        style={{ width: '100%', whiteSpace: 'normal', padding: '10px 0' }}
        key={dataItem.entity.id}
        onClick={() => onSelectItem(dataItem.entity)}
      >
        <ProductItem product={dataItem.entity} />
      </MenuItem>
    );
  }
}

export default ListContainer;
