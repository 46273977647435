import { styled, Box } from '@mui/material';
import _get from 'lodash/get';
import { SlColumn, SlRow, Text } from '@stackline/ui';
import React, { useMemo } from 'react';
import {
  AdjustmentLog,
  UserMetaData
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ViewAdjustmentModal/types';
import { formatDateStringWithSuffix, stripISODate } from 'src/utils/dateUtils';
import { useMetricFormatter } from 'src/utils/Hooks';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { generateAdjustmentRecordText } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/utils';
import AppProfileIcon from 'src/components/BeaconRedesignComponents/Header/AppProfileIcon';

/**
 * Custom elements for the timeline component
 */
const PointOuter = styled('div')({
  width: '12px',
  height: '12px',
  borderRadius: '45px',
  backgroundColor: '#05284947',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});
const Point = styled('div')({
  width: '6px',
  height: '6px',
  borderRadius: '25px',
  backgroundColor: '#052849',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center'
});
const ConnectorDown = styled('div')({
  alignSelf: 'baseline',
  width: '1px',
  height: '105px',
  backgroundColor: '#052849'
});

/**
 * Return the timeline between adjustment logs
 */
const Timeline = ({ isLast }: { isLast: boolean }) => {
  const height = isLast ? '0px' : '86px';
  return (
    <>
      <PointOuter>
        <Point>
          <ConnectorDown sx={{ height }} />
        </Point>
      </PointOuter>
    </>
  );
};

interface UserIdentifierProps {
  userId: string;
  userData: UserMetaData[keyof UserMetaData];
  isLast: boolean;
}
const UserIdentifier = ({ userId, userData, isLast }: UserIdentifierProps) => {
  const userInitials = `${_get(userData, ['firstName', 0], '')}${_get(userData, ['lastName', 0], '')}`;

  return (
    <SlRow verticalPosition="center" spacing="md">
      <Timeline isLast={isLast} />
      <AppProfileIcon userId={userId} initials={userInitials} />
      <Text variant="subtitle2">
        {userData.firstName} {userData.lastName}
      </Text>
    </SlRow>
  );
};

interface AdjustmentRecordItemProps {
  adjustmentRecord: AdjustmentLog;
  userData: UserMetaData[keyof UserMetaData];
  /**
   * Determines "Date Edited" vs "Date Created"
   */
  isFirst: boolean;
  /**
   * Determines if the timeline should extend to the next record item
   */
  isLast: boolean;
}
/**
 * Takes an adjustment log and user metadata and returns a unified record of a change made to an adjustment.
 * @returns
 */
export const AdjustmentRecordItem = ({ adjustmentRecord, userData, isFirst, isLast }: AdjustmentRecordItemProps) => {
  const formatMetric = useMetricFormatter();

  const { lastUpdatedDate, planType } = adjustmentRecord;

  // Format date timestamp for display
  const formattedDate = stripISODate(lastUpdatedDate);
  const dateForDisplay = formatDateStringWithSuffix(formattedDate);

  const planTypeText = useMemo(() => {
    return generateAdjustmentRecordText({ planType, adjustmentRecord, formatMetric });
  }, [planType, adjustmentRecord, formatMetric]);

  return (
    <div style={{ height: '85px' }}>
      <Flex gap="sm" flexDirection="column">
        <UserIdentifier userId={adjustmentRecord.createdByUserId} userData={userData} isLast={isLast} />
        <div style={{ paddingLeft: '10px' }}>
          <SlRow horizontalInset="xxl" spacing="lg">
            <Box width="90px">
              <SlColumn spacing="xs">
                <Text color={isFirst ? 'secondary' : 'primary'} variant="subtitle3">
                  Date {isFirst ? 'Created' : 'Edited'}
                </Text>
                <Text variant="body3">{dateForDisplay}</Text>
              </SlColumn>
            </Box>
            <div style={{ maxWidth: '400px' }}>
              <SlColumn spacing="xs">
                <Text color={isFirst ? 'secondary' : 'primary'} variant="subtitle3">
                  Plan Change
                </Text>
                <Text variant="body3">{planTypeText}</Text>
              </SlColumn>
            </div>
          </SlRow>
        </div>
      </Flex>
    </div>
  );
};
