import { useMemo } from 'react';
import { useAdvancedSearchRequestBuilder } from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { useAppSelector } from 'src/utils/Hooks';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import { getNextYearWeekId } from 'src/utils/dateUtils';

export const useBrandSplineChartData = () => {
  const indexName = 'beacon-sales';

  const categoryIds = useAppSelector((state) => state.entityService.mainEntity.categoryIds);
  const retailerId = useAppSelector((state) => state.retailer.id);
  const { startWeek: currentStartWeekId, endWeek: currentEndWeekId } = useAppSelector((state) => state.mainTimePeriod);
  const { startWeek: comparisonStartWeekId, endWeek: comparisonEndWeekId } = useAppSelector(
    (state) => state.comparisonTimePeriod
  );

  // current date range weekId by all brand
  const requestIdMain = 'currentAllBrandsByWeekId';
  const brandsByWeekIdRequestBuilderMain = useAdvancedSearchRequestBuilder(requestIdMain, indexName);

  const brandsByWeekIdRequestPrimary = useMemo(() => {
    const brandsByWeekIdAggregationBuilder = new AggregationBuilder('brandId,weekId');

    brandsByWeekIdAggregationBuilder.addAggregationField('retailSales', 'retailSales', 'sum', true);
    brandsByWeekIdAggregationBuilder.addConditionTermFilter('retailerId', [retailerId]);
    brandsByWeekIdAggregationBuilder.addConditionRangeFilter('weekId', currentStartWeekId, currentEndWeekId);

    brandsByWeekIdRequestBuilderMain
      .setPageNumber(1)
      .setPageSize(10000)
      .setPeriod('year')
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .addConditionTermFilter('categoryId', 'should', categoryIds)
      .setSearchBy('parent')
      .addAggregation(brandsByWeekIdAggregationBuilder.build())
      .addSortField('retailSales', 'retailSales', 'sum', true);

    return brandsByWeekIdRequestBuilderMain.build();
  }, [retailerId, currentStartWeekId, currentEndWeekId, brandsByWeekIdRequestBuilderMain, categoryIds]);

  const requestIdComparison = 'currentAllBrandsByWeekId';
  const brandsByWeekIdRequestBuilderComparison = useAdvancedSearchRequestBuilder(requestIdComparison, indexName);

  const brandsByWeekIdRequestSecondary = useMemo(() => {
    const brandsByWeekIdAggregationBuilder = new AggregationBuilder('brandId,weekId');

    brandsByWeekIdAggregationBuilder.addAggregationField('retailSales', 'retailSales', 'sum', true);
    brandsByWeekIdAggregationBuilder.addConditionTermFilter('retailerId', [retailerId]);
    brandsByWeekIdAggregationBuilder.addConditionRangeFilter('weekId', comparisonStartWeekId, comparisonEndWeekId);

    brandsByWeekIdRequestBuilderComparison
      .setPageNumber(1)
      .setPageSize(10000)
      .setPeriod('year')
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .addConditionTermFilter('categoryId', 'should', categoryIds)
      .setSearchBy('parent')
      .addAggregation(brandsByWeekIdAggregationBuilder.build())
      .addSortField('retailSales', 'retailSales', 'sum', true);

    return brandsByWeekIdRequestBuilderComparison.build();
  }, [retailerId, comparisonStartWeekId, comparisonEndWeekId, brandsByWeekIdRequestBuilderComparison, categoryIds]);

  const { data: splineChartData, isLoading: isSpineChartLoading } = useGenericAdvancedSearch({
    requestId: requestIdMain,
    queryKeys: [brandsByWeekIdRequestPrimary, brandsByWeekIdRequestSecondary],
    requestBody: [brandsByWeekIdRequestPrimary, brandsByWeekIdRequestSecondary]
  });

  const primaryData = useMemo(() => {
    if (splineChartData) {
      const { data } = splineChartData;

      const currentData = [data[0]];
      const byBrandId = currentData[0].aggregations.by_brandId;

      const formattedData = {};

      byBrandId.forEach((field) => {
        const { brandId } = field.additionalMetaData;
        const { weekId } = field.additionalMetaData;
        const currentValue = field.value;

        if (!formattedData[brandId]) {
          formattedData[brandId] = { brandId, weeklyData: [] };
        }

        formattedData[brandId].weeklyData.push({ weekId, currentValue });
      });

      const result = Object.values(formattedData);

      return { groupedCurrentData: result };
    }
    return null;
  }, [splineChartData]);

  const secondaryData = useMemo(() => {
    if (splineChartData) {
      const { data } = splineChartData;

      const currentData = data[1];
      const byBrandId = currentData.aggregations.by_brandId;

      const formattedData = {};

      byBrandId.forEach((field) => {
        const { brandId } = field.additionalMetaData;
        const { weekId } = field.additionalMetaData;
        const currentValue = field.value;

        if (!formattedData[brandId]) {
          formattedData[brandId] = { brandId, weeklyData: [] };
        }

        // Move it forward one year so we can render lines on top of each other
        formattedData[brandId].weeklyData.push({ weekId: getNextYearWeekId(+weekId), currentValue });
      });

      const result = Object.values(formattedData);

      return { groupedCurrentData: result };
    }
    return null;
  }, [splineChartData]);

  return { primaryData, secondaryData, isSpineChartLoading };
};
