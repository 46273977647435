import React from 'react';
import BeaconComparableSplineChart from 'src/components/BeaconRedesignComponents/BeaconMetricSplineChart/BeaconComparableSplineChart';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';

const aggregationBuilder = (aggBuilder: AggregationBuilder): AggregationBuilder => {
  return aggBuilder.clearConditionRangeFilters().addConditionRangeFilter('retailSales', 1);
};

export default function ProductsWithRetailSalesSplineChart() {
  return (
    <BeaconComparableSplineChart
      title="Products with Retail Sales"
      indexName={BEACON_INDEX_NAMES.SALES}
      fieldName="stacklineSku"
      primaryAggregationBuilder={aggregationBuilder}
      secondaryAggregationBuilder={aggregationBuilder}
    />
  );
}
