// This component is similar to the NAVLinkWrapper but it renders a button instead of a NavLink
/* eslint-disable react/prop-types */

import React from 'react';

interface Props {
  buttonClassName: string;
  containerClassName: string;
  primaryContent: object;
  secondaryContent: object;
  onClick: (e: React.SyntheticEvent) => void;
}

const NavButtonWrapper: React.FC<Props> = ({
  buttonClassName,
  containerClassName,
  primaryContent,
  secondaryContent,
  onClick
}) => {
  return (
    <div className={containerClassName}>
      <button className={buttonClassName} onClick={onClick}>
        {primaryContent}
        {secondaryContent}
      </button>
    </div>
  );
};

export default NavButtonWrapper;
