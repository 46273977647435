import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  currentTab: string;
  representTab: string;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, representTab, currentTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={currentTab !== representTab}
      id={`sl-tabpanel-${representTab}`}
      aria-labelledby={`sl-tab-${representTab}`}
      {...other}
    >
      {representTab === currentTab && children}
    </div>
  );
};

export default TabPanel;
