import React, { useCallback, useMemo } from 'react';
import { StacklineTheme, useStacklineTheme } from '@stackline/ui';
import { CommonSummaryInfoSubtitle } from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import DonutChartLoading from 'src/components/Charts/Donut/DonutChartLoading';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import { METRICTYPE } from 'src/utils/entityDefinitions';
import ContentAccuracyProductCompare from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Content/Accuracy/ContentAccuracyProductCompare';
import { useTableData } from 'src/serverProxy/useTableData';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { BEACON_CHART_SPACING } from 'src/components/Layout/Beacon/BeaconProLayoutConsts';
import ContentAccuracyProductGrid from './ContentAccuracyProductGrid';
import BeaconPageContainer from 'src/components/BeaconRedesignComponents/BeaconPageContainer/BeaconPageContainer';
import CircularProgressPercentage from 'src/components/common/UploadAnimation/CircularProgressPercentage';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

export enum ContentAccuracyFieldName {
  TitleAccuracy = 'titleAccuracy',
  BulletsAccuracy = 'bulletsAccuracy',
  ImageUrlsAccuracy = 'imageUrlsAccuracy',
  VideoUrlsAccuracy = 'videoUrlsAccuracy'
}

enum ContentAccuracyTitleType {
  Title = 'Title',
  Bullets = 'Bullets',
  Images = 'Images',
  Videos = 'Videos'
}

interface FieldTitle {
  fieldName: ContentAccuracyFieldName;
  title: ContentAccuracyTitleType;
}

export const contentMetrics: FieldTitle[] = [
  { fieldName: ContentAccuracyFieldName.TitleAccuracy, title: ContentAccuracyTitleType.Title },
  { fieldName: ContentAccuracyFieldName.BulletsAccuracy, title: ContentAccuracyTitleType.Bullets },
  { fieldName: ContentAccuracyFieldName.ImageUrlsAccuracy, title: ContentAccuracyTitleType.Images },
  { fieldName: ContentAccuracyFieldName.VideoUrlsAccuracy, title: ContentAccuracyTitleType.Videos }
];

export const donutChartColorSelector = (fieldName: ContentAccuracyFieldName, theme: StacklineTheme) => {
  switch (fieldName) {
    case ContentAccuracyFieldName.TitleAccuracy:
      return theme.colors.primary;
    case ContentAccuracyFieldName.BulletsAccuracy:
      return theme.colors.primaryLight;
    case ContentAccuracyFieldName.ImageUrlsAccuracy:
      return theme.colors.success;
    case ContentAccuracyFieldName.VideoUrlsAccuracy:
      return theme.colors.accentTeal;
    default:
      return theme.colors.secondaryGhost;
  }
};

interface MetricDonutChartProps {
  fieldName: ContentAccuracyFieldName;
  title: ContentAccuracyTitleType;
  isLoading: boolean;
  primaryTotal: number;
}
const MetricDonutChart = ({ fieldName, title, isLoading, primaryTotal }: MetricDonutChartProps) => {
  const formatMetric = useMetricFormatter();
  const theme = useStacklineTheme();

  return (
    <div style={{ marginLeft: '20px' }}>
      {isLoading ? (
        <DonutChartLoading size={200} />
      ) : (
        <CircularProgressPercentage
          percentage={primaryTotal}
          size="201px"
          color={donutChartColorSelector(fieldName, theme)}
          customLabel={
            <Flex gap="xxs" flexDirection="column" justifyContent="center">
              <Flex gap="xs" alignItems="center">
                <Text
                  sx={{
                    fontSize: '32px',
                    fontWeight: 'bold'
                  }}
                >
                  {formatMetric(primaryTotal * 100, METRICTYPE.DECIMAL, { decimalPlaces: 1, showFullValue: true })}
                </Text>
                <Text variant="h6">%</Text>
              </Flex>
              <Text
                variant="h6"
                sx={{
                  textAlign: 'center'
                }}
              >
                {title}
              </Text>
            </Flex>
          }
        />
      )}
    </div>
  );
};

const ContentAccuracyByProduct = () => {
  const { getValuesByFieldName, isLoading } = useTableData({
    fields: [
      {
        name: ContentAccuracyFieldName.TitleAccuracy
      },
      {
        name: ContentAccuracyFieldName.BulletsAccuracy
      },
      {
        name: ContentAccuracyFieldName.ImageUrlsAccuracy
      },
      {
        name: ContentAccuracyFieldName.VideoUrlsAccuracy
      }
    ],
    groupByFieldName: 'retailerId',
    indexName: BEACON_INDEX_NAMES.NEW_CONTENT_METRICS,
    sortFieldName: ContentAccuracyFieldName.TitleAccuracy,
    itemsPerPage: 1200,
    pageNumber: 1,
    requestId: 'weightedContentAccuracyDonuts'
  });

  const getContentAccuracy = useCallback(
    (fieldName: string) => {
      return getValuesByFieldName(fieldName)[0] || 0;
    },
    [getValuesByFieldName]
  );

  const titleAccuracy = useMemo(() => {
    return getContentAccuracy('titleAccuracy');
  }, [getContentAccuracy]);

  const bulletsAccuracy = useMemo(() => {
    return getContentAccuracy('bulletsAccuracy');
  }, [getContentAccuracy]);

  const imageAccuracy = useMemo(() => {
    return getContentAccuracy('imageUrlsAccuracy');
  }, [getContentAccuracy]);

  const videoAccuracy = useMemo(() => {
    return getContentAccuracy('videoUrlsAccuracy');
  }, [getContentAccuracy]);

  const donutMetrics = useMemo(
    () => [
      {
        fieldName: ContentAccuracyFieldName.TitleAccuracy,
        title: ContentAccuracyTitleType.Title,
        primaryTotal: titleAccuracy
      },
      {
        fieldName: ContentAccuracyFieldName.BulletsAccuracy,
        title: ContentAccuracyTitleType.Bullets,
        primaryTotal: bulletsAccuracy
      },
      {
        fieldName: ContentAccuracyFieldName.ImageUrlsAccuracy,
        title: ContentAccuracyTitleType.Images,
        primaryTotal: imageAccuracy
      },
      {
        fieldName: ContentAccuracyFieldName.VideoUrlsAccuracy,
        title: ContentAccuracyTitleType.Videos,
        primaryTotal: videoAccuracy
      }
    ],
    [bulletsAccuracy, imageAccuracy, titleAccuracy, videoAccuracy]
  );

  return (
    <BeaconPageContainer>
      <Flex flexDirection="column" gap={BEACON_CHART_SPACING}>
        <Flex flexDirection="column" gap="xl">
          <CommonSummaryInfoSubtitle title="Content Accuracy" />
          <Flex justifyContent="space-between">
            {donutMetrics.map((metricDefinition) => (
              <MetricDonutChart key={metricDefinition.fieldName} isLoading={isLoading} {...metricDefinition} />
            ))}
          </Flex>
        </Flex>
        <ContentAccuracyProductGrid />
      </Flex>
    </BeaconPageContainer>
  );
};

const ContentAccuracyPage = () => {
  const { type: entityType } = useAppSelector((state) => state.entityService.mainEntity);

  const shouldShowProductCompare = entityType === 'product';

  if (shouldShowProductCompare) {
    return <ContentAccuracyProductCompare />;
  }

  return <ContentAccuracyByProduct />;
};

export default ContentAccuracyPage;
