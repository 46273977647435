// ------------------------------------
// Constants/Types
// ------------------------------------
const UPDATE_WEEK_IDS = 'src/main-time-comparison-time-period/UPDATE_WEEK_IDS';
const CLEAR_WEEK_IDS = 'src/main-time-comparison-time-period/CLEAR_WEEK_IDS';

export default {
  UPDATE_WEEK_IDS,
  CLEAR_WEEK_IDS
};
