/* eslint-disable react/prop-types */
import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import _prop from 'lodash/property';

import { ChevronIcon } from 'src/components/SvgIcons';
import { MetricField } from 'src/types/application/types';

const HeaderChevronIcon: React.FC<{}> = () => <ChevronIcon className="sl-header__drop-down-icon" />;

const EntityMetricDropDown: React.FC<{
  metricFields: MetricField[];
  handleMetricsFieldChange: (evt: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
  value?: { name: string } | null;
  style?: React.CSSProperties;
}> = ({ handleMetricsFieldChange, metricFields, value, style }) => {
  if (!metricFields || metricFields.length === 0) {
    return null;
  }
  const selectedMetricsField = value || metricFields.find(_prop('isSelected')) || metricFields[0];

  return (
    <Select
      variant="standard"
      value={selectedMetricsField.name}
      onChange={handleMetricsFieldChange}
      input={<OutlinedInput labelWidth={0} id="outlined-age-simple" />}
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        }
      }}
      IconComponent={HeaderChevronIcon}
      style={style}
    >
      {metricFields.map(({ name, displayName }) => (
        <MenuItem key={name} value={name}>
          {displayName}
        </MenuItem>
      ))}
    </Select>
  );
};

export default EntityMetricDropDown;
