import React from 'react';
import { SlRow, useStacklineTheme } from '@stackline/ui';
import { SmallCancelIcon } from 'src/components/SvgIcons';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

export const CalendarError = ({ open, message }: { open: boolean; message: string }) => {
  const theme = useStacklineTheme();
  return (
    <>
      {open && (
        <SlRow spacing="xs" verticalPosition="center">
          <div style={{ height: theme.spacing.md }}>
            <SmallCancelIcon style={{ width: theme.spacing.md, height: theme.spacing.md }} />
          </div>
          <Text variant="subtitle4" sx={{ fontSize: '10px' }} color="error">
            {message}
          </Text>
        </SlRow>
      )}
    </>
  );
};
