import 'src/components/SummaryTile/SummaryTile.scss';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';
import _uniq from 'lodash/uniq';
import pluralize from 'pluralize';
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect } from 'react';
import { withBus } from 'react-bus';
// import Truncate from 'react-truncate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  AD_CAMPAIGN_STATUS,
  AD_STATUS_DERIVED,
  AD_UPDATE_STATUS,
  AD_UPDATE_TYPE
} from 'sl-ad-campaign-manager-data-model';
import { AdManagerAdCampaignEntity, AdManagerAdPortfolioEntity, AppName, Entity } from 'sl-api-connector/types';
import {
  buildEntityLink,
  getActiveAdGroupsNumber,
  getActiveCampaignNumber,
  getActiveEntityNumber,
  getActivePortfolioNumber,
  getCampaignDisplayStatus,
  getKeywordDisplayStatus,
  getParentCampaignDisplayInfo,
  getParentEntityDisplayInfo,
  getParentPortfolioDisplayInfo,
  getStrategyDisplayInfo
} from 'src/components/AdManager/AmsUtils';
import ScoreDonutChart from 'src/components/AdManager/ScoreDonutChart';
import LargeMuiButton from 'src/components/common/Buttons/LargeMuiButton';
import Loading from 'src/components/common/Loading';
import { mkActionCellFormatter } from 'src/components/EntityPage/WaterfallChart/Insights/CellRendererFrameworks';
import { store } from 'src/main';
import { adCampaignOperations } from 'src/store/modules/adManager/adCampaigns';
import { entityOperations } from 'src/store/modules/entityService';
import { AdManagerEvents } from 'src/types/application/event';
import {
  Delivering,
  Ended,
  Ineligible,
  MissingBuybox,
  MissingDecoration,
  MissingImage,
  NotBuyable,
  OutOfBudget,
  Paused,
  PushFn
} from 'src/types/application/types';
import { Widget as WidgetType } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import { EventBus } from 'src/types/utils';
import { AdCampaignManagerServiceClient } from 'src/utils/adCampaignManagerServiceClient';
import { withPush } from 'src/utils/hoc';
import { getBrandImageUrl, getProductImageUrl } from 'src/utils/image';
import { splitCamelCase } from 'src/utils/stringFormatting';
import Dialog from '@mui/material/Dialog';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { INELIGIBLE_FIELDS } from 'src/components/AdManager/AdIneligibleInfo';
import { getParentPlatform } from 'src/utils/browser';
import { isCriteo, shouldShowCriteo } from 'src/utils/app';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';
import { findRetailerFromAvailable } from 'src/store/modules/retailer/reducers';

const openURL = (url: string) => window.open(url, '_blank');

const isProductError = (statusReason: string) => {
  return INELIGIBLE_FIELDS.includes(statusReason);
};

const formatStatus = (statusReason: string) => {
  const errStatuses = [Ineligible, MissingDecoration, MissingImage, NotBuyable, MissingBuybox];
  let displayName = statusReason;
  errStatuses.forEach((status) => {
    if (status.value === statusReason) {
      // eslint-disable-next-line prefer-destructuring
      displayName = status.displayName;
    }
  });

  return displayName;
};

/**
 * Returns the checked state of the status toggle at the top of the page.
 * If there are child campaigns that are enabled, it will be checked.
 */
export const getChecked = (currentEntity: any, mainEntity: any) => {
  if (mainEntity) {
    if (mainEntity.type === 'product' || mainEntity.type === 'adTarget') {
      const { childEntities } = mainEntity;

      // what about the case where we have no enabled/paused campaigns?
      const hasEnabledCampaigns = childEntities.filter((ent) => ent.extendedAttributes.status === 'enabled').length > 0;

      return hasEnabledCampaigns;
    }
  }

  if (currentEntity && currentEntity.extendedAttributes) {
    return currentEntity.extendedAttributes.status === AD_CAMPAIGN_STATUS.ENABLED;
  }

  return false;
};

export const ToggleStatus = ({
  currentEntity,
  mainEntity,
  eventBus,
  disabled
}: {
  currentEntity: AdManagerAdCampaignEntity;
  eventBus: EventBus;
  mainEntity: any;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const checked = getChecked(currentEntity, mainEntity);

  const handleClick = () => {
    setOpen(true);
  };

  const toggleStatusForEntity = async () => {
    setOpen(false);
    setLoading(true);
    const currentStatus = checked ? AD_CAMPAIGN_STATUS.ENABLED : AD_CAMPAIGN_STATUS.PAUSED;
    const newStatus: AD_CAMPAIGN_STATUS =
      currentStatus === AD_CAMPAIGN_STATUS.ENABLED ? AD_CAMPAIGN_STATUS.PAUSED : AD_CAMPAIGN_STATUS.ENABLED;
    let override;

    const filterCriteria = (entity: any) => {
      if (
        (entity.extendedAttributes.statusDerived === AD_STATUS_DERIVED.ARCHIVED ||
          entity.extendedAttributes.statusDerived === AD_STATUS_DERIVED.ENDED) &&
        entity.platformType.includes('AmazonAMS')
      ) {
        return false;
      }
      return ['enabled', 'paused'].includes(entity.extendedAttributes.status);
    };

    let { beaconClientId } = mainEntity;
    const { childEntities } = mainEntity;

    if (childEntities && childEntities.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      beaconClientId = childEntities[0].beaconClientId;
    } else {
      // eslint-disable-next-line prefer-destructuring
      beaconClientId = mainEntity.beaconClientId;
    }

    if (mainEntity.type === 'product') {
      // @ts-ignore
      // On ad target and product pages, we need to wrangle data to be able to have the switch on campaigns toggle
      // @ts-ignore
      const { platformType } = mainEntity;

      // find matching
      const adProductsToPause = childEntities.filter(filterCriteria).map((match) => {
        const {
          adId,
          retailerSku,
          campaignId,
          adGroupId,
          retailerId,
          extendedAttributes: { beaconClientLoginId, campaignType, entityId }
        } = match;
        return {
          beaconClientId,
          platformType,
          campaignId,
          adGroupId,
          retailerId,
          retailerSku,
          extendedAttributes: {
            retailerId,
            beaconClientLoginId,
            entityId,
            campaignId,
            adGroupId,
            adId,
            campaignType,
            status: newStatus
          }
        };
      });

      override = {
        beaconClientId,
        platformType,
        updateType: AD_UPDATE_TYPE.UPDATE_STATUS,
        extendedAttributes: {
          status: AD_UPDATE_STATUS.NEW,
          adEntities: [],
          adPortfolios: [],
          adCampaigns: [],
          adGroups: [],
          adCampaignProducts: adProductsToPause,
          adTargets: []
        }
      };
    } else if (mainEntity.type === 'adTarget') {
      // @ts-ignore
      const { platformType } = mainEntity;
      // find matching
      const adTargetsToPause = childEntities.filter(filterCriteria).map((match) => {
        const {
          targetingClass,
          targetingText,
          extendedAttributes: {
            adGroupId,
            beaconClientLoginId,
            targetId,
            cpcMaxBidAmount,
            cpcMinBidAmount,
            entityId,
            status,
            retailerId,
            campaignId,
            campaignType
          }
        } = match;
        const newStatusForMatch = status === 'enabled' ? 'paused' : 'enabled';
        return {
          beaconClientId,
          platformType,
          campaignId,
          adGroupId,
          targetId,
          targetingClass,
          targetingText,
          extendedAttributes: {
            retailerId,
            beaconClientLoginId,
            entityId,
            campaignId,
            adGroupId,
            cpcMaxBidAmount,
            cpcMinBidAmount,
            targetId,
            campaignType,
            status: newStatusForMatch
          }
        };
      });

      override = {
        beaconClientId,
        platformType,
        updateType: AD_UPDATE_TYPE.UPDATE_STATUS,
        extendedAttributes: {
          status: AD_UPDATE_STATUS.NEW,
          adEntities: [],
          adPortfolios: [],
          adCampaigns: [],
          adGroups: [],
          adCampaignProducts: [],
          adTargets: adTargetsToPause
        }
      };
    }

    try {
      await AdCampaignManagerServiceClient.updateEntityStatus({
        beaconClientId: currentEntity.beaconClientId,
        platformType: currentEntity.platformType,
        entities: [currentEntity],
        entityType: currentEntity.type,
        updatedStatus: newStatus,
        override
      });
      eventBus.emit(AdManagerEvents.ENTITY_EVENT_UPDATE_STATUS, { entity: currentEntity });
    } catch (e) {
      console.error('Error updating entity status', e);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return (
      <div className="control_button">
        <Loading className="spinner in-place" size={30} thickness={2} />
      </div>
    );
  }

  const generateTooltipText = () => {
    if (['product', 'adTarget'].includes(mainEntity.type)) {
      const { childEntities } = mainEntity;
      const filteredChildEntities = childEntities.filter((ent) =>
        ['paused', 'enabled'].includes(ent.extendedAttributes.status)
      );
      const ableToToggleCount = filteredChildEntities.length;

      if (mainEntity.type === 'adTarget') {
        const numberOfTargets = ableToToggleCount;
        const numberOfUniqueCampaigns = _uniq(filteredChildEntities.map((n) => n.campaignId)).length;
        return `${checked ? 'Pause' : 'Turn on'} ${numberOfTargets} ${pluralize(
          'target',
          numberOfTargets
        )} in ${numberOfUniqueCampaigns} campaigns`;
      }

      return `${checked ? 'Pause' : 'Turn on'} ${splitCamelCase(
        currentEntity.type
      ).toLowerCase()} in ${ableToToggleCount} campaigns`;
    }

    return `${checked ? 'Pause' : 'Turn on'} ${splitCamelCase(currentEntity.type).toLowerCase()}`;
  };

  return (
    <Tooltip disableHoverListener={disabled} title={generateTooltipText()} placement="top">
      <div className="control_button">
        <Switch disabled={disabled} checked={checked} onClick={handleClick} color="primary" />
        <Dialog open={open} onClose={() => setOpen(false)}>
          <div className="confirm_dialog">
            <div className="confirm_title">Confirmation</div>
            <div className="confirm_body">
              Are you sure to update the status for{' '}
              <strong>{currentEntity.campaignName || currentEntity.displayName}</strong>?
            </div>
            <div className="confirm_buttons">
              <LargeMuiButton
                style={{ width: 88, height: 35, marginRight: 5 }}
                label="Confirm"
                onClick={toggleStatusForEntity}
              />
              <LargeMuiButton
                secondary
                style={{ width: 88, height: 35, marginLeft: 5, marginRight: 5 }}
                label="Cancel"
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        </Dialog>
      </div>
    </Tooltip>
  );
};

export const correctEntityTypeForURL = (type: string) =>
  ((
    {
      entity: 'adEntity',
      campaign: 'adCampaign',
      portfolio: 'adPortfolio'
    } as { [type: string]: string }
  )[type] || type);

const formatData = (
  retailer: ReduxStore['retailer'],
  entity: Entity & AdManagerAdCampaignEntity & AdManagerAdPortfolioEntity,
  adPlatforms: ReduxStore['adPlatforms'],
  parentPlatform: string
) => {
  const topLink = buildEntityLink(correctEntityTypeForURL(entity.type), entity.id);
  const subHeadersTop: { label: string; value: string; link?: string; color?: string; externalLink?: string }[] = [];
  const productSubHeader: { category: string; subcategory: string; brand: string; sku: string; skuLink: string } = {};
  const subHeadersBot: { label: string; value: string; link?: string; color?: string; externalLink?: string }[] = [];
  const singleSubHeaders: { label: string; value: string; link?: string; color?: string; externalLink?: string }[] = [];

  const platform = adPlatforms.find((item) => `${item.platformType}` === entity.platformType);

  let textHeader = null;

  let customWidth = 330;
  const aggregatedValues: any[] = [];
  let activeCampaignsCount = 0;

  let shouldShowCount = true;
  let errorStatus = '';

  if (['adTarget', 'product'].includes(entity.type)) {
    const aggregatedValuesMapByCampaignId: {
      [key: string]: { [key: AD_STATUS_DERIVED]: { value: string; color: string; count: number } };
    } = {};

    entity.childEntities.forEach((childEntity: any) => {
      const { status, statusReason, statusDerived } = childEntity.extendedAttributes;

      if (isProductError(statusReason)) {
        shouldShowCount = false;
        errorStatus = statusReason;
      }

      const { displayName: statusName, color: statusColor } = getKeywordDisplayStatus(status, statusReason);
      // check if status derived is archived or ended
      if (statusDerived === AD_STATUS_DERIVED.ARCHIVED || statusDerived === AD_STATUS_DERIVED.ENDED) {
        return;
      }
      if (!aggregatedValuesMapByCampaignId[childEntity.campaignId]) {
        aggregatedValuesMapByCampaignId[childEntity.campaignId] = {};
      }
      if (!aggregatedValuesMapByCampaignId[childEntity.campaignId][statusName]) {
        aggregatedValuesMapByCampaignId[childEntity.campaignId][statusName] = {
          value: statusName,
          color: statusColor,
          count: 1
        };
      } else {
        aggregatedValuesMapByCampaignId[childEntity.campaignId][statusName].count += 1;
      }
    });
    const aggregatedValuesMap: { [key: string]: { value: string; color: string; count: number } } = {};

    const activeCampaignIds = new Set();
    Object.entries(aggregatedValuesMapByCampaignId).forEach(([campaignId, countByStatus]) => {
      if (countByStatus[Delivering.displayName]) {
        if (!aggregatedValuesMap[Delivering.displayName]) {
          aggregatedValuesMap[Delivering.displayName] = {
            ...countByStatus[Delivering.displayName],
            count: 1
          };
        } else {
          aggregatedValuesMap[Delivering.displayName].count += 1;
        }
        activeCampaignIds.add(campaignId);
      } else if (countByStatus[Paused.displayName]) {
        if (!aggregatedValuesMap[Paused.displayName]) {
          aggregatedValuesMap[Paused.displayName] = {
            ...countByStatus[Paused.displayName],
            count: 1
          };
        } else {
          aggregatedValuesMap[Paused.displayName].count += 1;
        }
        activeCampaignIds.add(campaignId);
      } else if (countByStatus[OutOfBudget.displayName]) {
        if (!aggregatedValuesMap[OutOfBudget.displayName]) {
          aggregatedValuesMap[OutOfBudget.displayName] = {
            ...countByStatus[OutOfBudget.displayName],
            count: 1
          };
        } else {
          aggregatedValuesMap[OutOfBudget.displayName].count += 1;
        }
        activeCampaignIds.add(campaignId);
      } else if (countByStatus[Ended.displayName]) {
        if (!aggregatedValuesMap[Ended.displayName]) {
          aggregatedValuesMap[Ended.displayName] = {
            ...countByStatus[Ended.displayName],
            count: 1
          };
        } else {
          aggregatedValuesMap[Ended.displayName].count += 1;
        }
      }
    });
    activeCampaignsCount = activeCampaignIds.size;
    if (aggregatedValuesMap[Delivering.displayName]) {
      aggregatedValues.push(aggregatedValuesMap[Delivering.displayName]);
    }
    if (aggregatedValuesMap[OutOfBudget.displayName]) {
      aggregatedValues.push(aggregatedValuesMap[OutOfBudget.displayName]);
    }
    if (aggregatedValuesMap[Paused.displayName]) {
      aggregatedValues.push(aggregatedValuesMap[Paused.displayName]);
    }
    if (aggregatedValuesMap[Ended.displayName]) {
      aggregatedValues.push(aggregatedValuesMap[Ended.displayName]);
    }
  }
  if (entity.type === 'adTarget') {
    textHeader = {
      name: entity.name || entity.campaignName,
      topLink,
      pillText: 'Target'
    };

    subHeadersTop.push({ label: 'Entities', value: `${_get(entity, ['entityIds', 'length'], 0)} active` });
    subHeadersTop.push({ label: 'Campaigns', value: `${activeCampaignsCount} active` });
    subHeadersBot.push({ label: 'Portfolios', value: `${getActivePortfolioNumber(entity)} active` });
    subHeadersBot.push({ label: 'Status', aggregatedValues });
  } else if (entity.type === 'product') {
    customWidth = 200;
    textHeader = {
      name: entity.name,
      topLink,
      imageUrl: getProductImageUrl(entity.stacklineSku) || undefined,
      pillText: undefined
    };
    productSubHeader.category = entity.categoryName || '--';
    productSubHeader.subcategory = entity.subCategoryName || '--';
    productSubHeader.brand = entity.brandName || '--';
    productSubHeader.sku = entity.retailerSku || '';
    if (retailer.skuUrl) {
      productSubHeader.skuLink = `${retailer.skuUrl}${entity.retailerSku}`;
    } else {
      productSubHeader.skuLink = ``;
    }
    // For All retailer we need to find the retailer and corresponding skuUrl
    if (retailer.id === '0') {
      const firstChild = _get(entity, ['childEntities', 0]);
      if (firstChild) {
        const { retailerId } = firstChild;
        const foundedRetailer = findRetailerFromAvailable(retailerId);
        if (foundedRetailer) {
          productSubHeader.skuLink = `${foundedRetailer.skuUrl}${entity.retailerSku}`;
        }
      }
    }
    if (retailer.id === '63') {
      productSubHeader.upc = entity.upc || '';
      productSubHeader.skuLink = `https://www.instacart.com/products/${entity.retailerSku}`;
    }
    if (retailer.id === '96') {
      productSubHeader.skuLink = `${retailer.skuUrl}${entity.retailerSku}-product`;
    }

    subHeadersBot.push({ label: 'Entities', value: `${_get(entity, ['entityIds', 'length'], 0)} active` });
    subHeadersBot.push({ label: 'Campaigns', value: `${activeCampaignsCount} active` });
    subHeadersBot.push({ label: 'Portfolios', value: `${getActivePortfolioNumber(entity)} active` });
    subHeadersBot.push({ label: 'Status', aggregatedValues });
  } else if (entity.type === 'adCampaign') {
    // sbv has campaignType as sb so identify sbv using this condition
    let adCampaignType =
      _get(entity, 'extendedAttributes.amsApiModel.adFormat') === 'video' ||
      _get(entity, 'extendedAttributes.campaignType') === 'video'
        ? 'Sponsored Videos'
        : _get(entity, 'extendedAttributes.campaignType');

    if (adCampaignType === 'sba') {
      adCampaignType = 'Sponsored Brands';
    }
    textHeader = {
      name: entity.name || entity.campaignName,
      topLink,
      pillText: _startCase(adCampaignType)
    };
    const { displayName: statusName, color: statusColor } = getCampaignDisplayStatus(entity.extendedAttributes);
    const { shortDisplayName: parentPortfolioName, url: parentPortfolioUrl } = getParentPortfolioDisplayInfo(
      entity.extendedAttributes
    );
    const { shortDisplayName: parentEntityName, url: parentEntityUrl } = getParentEntityDisplayInfo(
      entity.extendedAttributes
    );
    const { displayName: strategyName } = getStrategyDisplayInfo(entity.extendedAttributes);

    // Hide for Criteo
    if (!(shouldShowCriteo() && parentPlatform && parentPlatform === PARENT_PLATFORMS.CRITEO)) {
      subHeadersTop.push({ label: 'Portfolio', value: parentPortfolioName, link: parentPortfolioUrl });
      subHeadersBot.push({ label: 'Optimization', value: strategyName });
    }

    subHeadersTop.push({ label: 'Entity', value: parentEntityName, link: parentEntityUrl });
    subHeadersBot.push({ label: 'Status', value: statusName, color: statusColor });
  } else if (entity.type === 'adGroup') {
    textHeader = {
      name: entity.extendedAttributes.adGroupName || '',
      topLink,
      pillText: isCriteo() ? 'Line Item' : 'Ad Group'
    };
    const { displayName: statusName, color: statusColor } = getCampaignDisplayStatus(entity.extendedAttributes);
    const { shortDisplayName: parentCampaignName, url: parentCampaignUrl } = getParentCampaignDisplayInfo(
      entity.extendedAttributes
    );
    const { shortDisplayName: parentEntityName, url: parentEntityUrl } = getParentEntityDisplayInfo(
      entity.extendedAttributes
    );
    subHeadersTop.push({ label: 'Entity', value: parentEntityName, link: parentEntityUrl });
    subHeadersTop.push({ label: 'Campaign', value: parentCampaignName, link: parentCampaignUrl });
    subHeadersBot.push({ label: 'Status', value: statusName, color: statusColor });
  } else if (entity.type === 'adPortfolio') {
    textHeader = {
      name: entity.name,
      topLink,
      pillText: 'Ad Portfolio'
    };
    const { shortDisplayName: parentEntityName, url: parentEntityUrl } = getParentEntityDisplayInfo(
      entity.extendedAttributes
    );
    const { displayName: statusName, color: statusColor } = getCampaignDisplayStatus(entity.extendedAttributes);
    const { displayName: strategyName } = getStrategyDisplayInfo(entity.extendedAttributes);
    // subHeaders.push({ label: 'Status', value: statusName, color: statusColor });
    subHeadersTop.push({ label: 'Entity', value: parentEntityName, link: parentEntityUrl });
    subHeadersTop.push({ label: 'Campaigns', value: `${getActiveCampaignNumber(entity)} active` });
    subHeadersBot.push({ label: 'Optimization', value: strategyName });
    subHeadersBot.push({ label: 'Status', value: statusName, color: statusColor });
  } else if (['entity', 'adEntity'].includes(entity.type)) {
    textHeader = {
      name: entity.name,
      topLink,
      pillText: 'Ad Entity'
    };
    subHeadersTop.push({ label: 'Platform', value: platform ? platform.name : '' });
    subHeadersTop.push({ label: 'Portfolios', value: `${getActivePortfolioNumber(entity)} active` });
    subHeadersBot.push({ label: 'Entity Id', value: entity.id || '' });
    subHeadersBot.push({ label: 'Campaigns', value: `${getActiveCampaignNumber(entity)} active` });
  } else if (entity.type === 'client') {
    if (shouldShowCriteo() && parentPlatform && parentPlatform === PARENT_PLATFORMS.CRITEO) {
      let pillText = 'Organization';
      const entityLabel = 'Accounts';
      const campaignLabel = 'Campaigns';
      const adGroupLabel = 'Line Items';

      subHeadersTop.push({ label: adGroupLabel, value: `${getActiveAdGroupsNumber()} active` });

      // All retailer view and single retailer view should have different pills
      if (retailer.id === '0') {
        subHeadersBot.push({ label: campaignLabel, value: `${getActiveCampaignNumber(entity)} active` });
      } else {
        pillText = 'Retailer';
      }

      textHeader = {
        name: entity.name,
        topLink,
        pillText
      };
      subHeadersTop.push({ label: entityLabel, value: `${getActiveEntityNumber(entity)} active` });
    } else {
      textHeader = {
        name: entity.name,
        topLink,
        pillText: 'Organization'
      };
      subHeadersTop.push({ label: 'Entities', value: `${getActiveEntityNumber(entity)} active` });
      subHeadersTop.push({ label: 'Portfolios', value: `${getActivePortfolioNumber(entity)} active` });
      subHeadersBot.push({ label: 'Campaigns', value: `${getActiveCampaignNumber(entity)} active` });
    }
  } else if (entity.type === 'segment') {
    textHeader = {
      name: entity.name,
      topLink,
      pillText: 'Segment'
    };
  } else if (entity.type === 'brand') {
    textHeader = {
      name: entity.name,
      imageUrl: getBrandImageUrl(entity.id),
      topLink,
      pillText: 'Brand'
    };
  }

  return {
    textHeader,
    subHeadersTop,
    subHeadersBot,
    singleSubHeaders,
    customWidth,
    productSubHeader,
    shouldShowCount,
    errorStatus
  };
};

const AdManagerEntityHeaderInner: React.FC<{
  app: any;
  mainEntity: Entity;
  customStyle?: React.CSSProperties;
  currentEntity?: Entity & AdManagerAdCampaignEntity & AdManagerAdPortfolioEntity;
  retailer: ReduxStore['retailer'];
  adPlatforms: ReduxStore['adPlatforms'];
  adCampaigns: ReduxStore['adCampaigns'];
  widget?: WidgetType;
  eventBus: EventBus;
  fetchMainEntity: Function;
  push: PushFn;
}> = ({
  app,
  retailer,
  mainEntity,
  customStyle,
  currentEntity,
  adPlatforms,
  adCampaigns,
  widget,
  eventBus,
  fetchMainEntity,
  push,
  disabled
}) => {
  const entity = currentEntity || mainEntity;
  const { user } = store.getState();
  const isAdAuditUser = _get(user, 'config.adAuditEnabled', false);

  useEffect(() => {
    if (mainEntity) {
      const refreshMainEntity = (event: { entity: AdManagerAdCampaignEntity }) => {
        if (event.entity.type === mainEntity.type && event.entity.id === mainEntity.id) {
          fetchMainEntity(entity.type, entity.id, entity.retailerId, push);
        } else if (_get(app, 'queryParams.selectedEntityName', '') === 'campaignId') {
          store.dispatch(adCampaignOperations.fetchAdCampaigns());
        }
      };
      eventBus.on(AdManagerEvents.ENTITY_EVENT_UPDATE_STATUS, refreshMainEntity);
      return () => eventBus.off(AdManagerEvents.ENTITY_EVENT_UPDATE_STATUS, refreshMainEntity);
    }
    return () => {};
  });
  if (_isEmpty(entity) || _isEmpty(adPlatforms) || _isEmpty(adCampaigns)) {
    return <div className="adManager_header_section" style={customStyle} />;
  }
  const stopPropagation = (evt: { stopPropagation: Function }) => evt.stopPropagation();

  const shouldShowToggleButton = () => {
    if (isAdAuditUser) {
      return false;
    }

    if (['adCampaign'].includes(entity.type)) {
      const { displayName } = getCampaignDisplayStatus(entity.extendedAttributes);
      return (
        ['adCampaign'].includes(entity.type) &&
        ['paused', 'enabled'].includes(_get(entity, ['extendedAttributes', 'status'], null)) &&
        !['Ended', 'Archived'].includes(displayName)
      );
    }

    if (['product', 'adTarget'].includes(entity.type)) {
      return true;
    }

    return false;
  };

  const openInApp = (appName: string) => {
    const baseURL = `https://${appName}${app.stage !== 'prod' ? `-${app.stage}` : ''}.stackline.com/${entity.type}`;
    const url = `${baseURL}/${entity.id}`;
    openURL(url);
  };

  const openInInstacart = () => {
    const url = `https://www.instacart.com/products/${entity.retailerSku}`;
    openURL(url);
  };

  const isAtlasSupported = retailer.supportedAppNames.includes(AppName.Atlas);
  const isBeaconsSupported = retailer.supportedAppNames.includes(AppName.Beacon);

  const atlasChip = {
    onClick: () => openInApp('atlas'),
    label: 'Open in Atlas'
  };

  const beaconChip = {
    onClick: () => openInApp('beacon'),
    label: 'Open in Beacon'
  };

  const instacartChip = {
    onClick: () => openInInstacart(),
    label: 'Open in Instacart'
  };

  const actionChildChipsByEntity = {
    brand: [
      ...(!['63', '100'].includes(retailer.id) && isAtlasSupported ? [atlasChip] : []),
      isBeaconsSupported ? beaconChip : null
    ].filter(Boolean), // Filters out null values
    product: ['63'].includes(retailer.id)
      ? [instacartChip]
      : [isAtlasSupported ? atlasChip : null, isBeaconsSupported ? beaconChip : null].filter(Boolean) // Filters out null values
  };

  const ActionButton = mkActionCellFormatter(
    actionChildChipsByEntity[entity.type as 'brand' | 'product'],
    {
      display: 'flex',
      position: 'relative',
      paddingTop: 4,
      width: 150
    },
    'Open',
    false,
    true
  );

  const isCriteoAllowed = shouldShowCriteo();

  const parentPlatform = getParentPlatform();

  const shouldDisableSwitch = disabled || (parentPlatform === PARENT_PLATFORMS.CRITEO && isCriteoAllowed);

  const { textHeader, subHeadersTop, subHeadersBot, customWidth, productSubHeader, shouldShowCount, errorStatus } =
    formatData(retailer, entity, adPlatforms, parentPlatform);
  return (
    <div className="adManager_header_section" style={customStyle}>
      {!['product', 'adTarget', 'adGroup', 'segment', 'brand'].includes(entity.type) &&
        parentPlatform !== PARENT_PLATFORMS.CRITEO && (
          <div className="donut_score">
            <ScoreDonutChart widget={widget} currentEntity={currentEntity} />
          </div>
        )}
      {textHeader && (
        <>
          {textHeader.imageUrl && (
            <div className="header_image_container">
              <img alt="headerImage" src={textHeader.imageUrl} onError={() => {}} />
            </div>
          )}
          <div className="header_name image_header_name">
            <Link onClick={stopPropagation} to={textHeader.topLink}>
              <div className="main_header">
                <span className="header_text">{textHeader.name}</span>{' '}
                {textHeader.pillText && <span className="type_pill">{textHeader.pillText}</span>}
              </div>
            </Link>
            {entity.type === 'product' && (
              <div style={{ display: 'flex', fontSize: 14, alignItems: 'center', marginTop: -10 }}>
                {productSubHeader.category} // {productSubHeader.subcategory} // {productSubHeader.brand}
                {productSubHeader.skuLink && <>//&nbsp;</>}
                {retailer.id === '63'
                  ? productSubHeader.upc
                  : productSubHeader.skuLink && (
                      <a rel="noopener noreferrer" target="_blank" href={productSubHeader.skuLink}>
                        {productSubHeader.sku}
                      </a>
                    )}
                &nbsp; &nbsp; &nbsp;
                <ActionButton />
              </div>
            )}
            <div>
              {subHeadersTop.length >= 1 && (
                <div className="sub_header">
                  {subHeadersTop.map((data, idx) => (
                    <div key={idx} className="main_box" style={{ width: customWidth }}>
                      <strong>{data.label}:</strong>{' '}
                      <span style={{ color: data.color }}>
                        {data.externalLink ? (
                          <a rel="noopener noreferrer" target="_blank" href={data.externalLink}>
                            {data.value}
                          </a>
                        ) : data.link ? (
                          <Link target="_blank" onClick={stopPropagation} to={data.link}>
                            {data.value}
                          </Link>
                        ) : (
                          data.value
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              )}
              {subHeadersBot.length > 0 && (
                <div className="sub_header">
                  {subHeadersBot.map((data, idx) => (
                    <div
                      key={idx}
                      className="main_box"
                      style={data.aggregatedValues ? { width: '500px' } : { width: customWidth }}
                    >
                      <strong>{data.label}:</strong>{' '}
                      {!shouldShowCount && data.label === 'Status' ? (
                        <span style={{ color: '#F35379' }}>{formatStatus(errorStatus)}</span>
                      ) : (
                        <span>
                          {data.aggregatedValues &&
                            data.aggregatedValues.map((aggregatedValue, idx1) => {
                              return (
                                <span key={idx1} style={{ color: aggregatedValue.color }}>
                                  {aggregatedValue.link ? (
                                    <Link target="_blank" onClick={stopPropagation} to={aggregatedValue.link}>
                                      {aggregatedValue.value}
                                    </Link>
                                  ) : (
                                    `${aggregatedValue.value} (${aggregatedValue.count}) `
                                  )}
                                </span>
                              );
                            })}
                          {!data.aggregatedValues && (
                            <span style={{ color: data.color }}>
                              {data.link ? (
                                <Link target="_blank" onClick={stopPropagation} to={data.link}>
                                  {data.value}
                                </Link>
                              ) : (
                                data.value
                              )}
                            </span>
                          )}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {shouldShowToggleButton() ? (
        <ToggleStatus
          disabled={shouldDisableSwitch}
          currentEntity={entity}
          eventBus={eventBus}
          mainEntity={mainEntity}
        />
      ) : null}
      {entity.type === 'brand' && (
        <div>
          <ActionButton />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: ReduxStore) => ({
  app: state.app,
  retailer: state.retailer,
  adCampaigns: state.adCampaigns,
  adPlatforms: state.adPlatforms,
  mainEntity: state.entityService.mainEntity
});

const mapDispatchToProps = {
  fetchMainEntity: entityOperations.fetchMainEntity
};

const AdManagerEntityHeader = withBus('eventBus')(
  connect(mapStateToProps, mapDispatchToProps)(withPush(AdManagerEntityHeaderInner))
);

export default AdManagerEntityHeader;
