import { Parser } from 'expr-eval';

import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

export const ATLAS_RETAILER_FIELD: {
  [key: string]: FieldDefinition;
} = {
  convertRate: {
    name: 'convertRate',
    displayName: 'Conversion Rate',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('(totalClicks > 0) ? ((unitsSold / totalClicks)) : (0)'),
    fillWithLastKnownValue: true
  }
};
