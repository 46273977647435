import React from 'react';
import { Text } from '@stackline/ui';
import { DialogProps } from '@mui/material/Dialog';
import * as serverProxy from 'src/routes/UserAccount/UserTeamManagement/serverProxy';
import { useAppSelector, useSnackbar } from 'src/utils/Hooks';
import _get from 'lodash/get';
import { settingsErrorMessage } from '../../utils';
import TeamUserOptionPopup from '../TeamUserOptionPopup';

type TransferOwnershipModalProps = Omit<DialogProps, 'children' | 'sx'> & {
  user: {
    email: string;
  };
  refetchData: () => Promise<void>;
};

/**
 * Popup for removing a user from a team
 */
export default function TransferOwnershipModal({ user, refetchData, ...rest }: TransferOwnershipModalProps) {
  const { showSnackbar } = useSnackbar();
  const currentEmail = useAppSelector((state) => state.user.config.profile.email);
  const currentRole = useAppSelector((state) => state.user.config.profile.role);

  const transferOwnership = async () => {
    try {
      // Delete the user and then refetch the users
      await serverProxy.ChangeUserRoleInCurrentDivision({
        action: 'setOwner',
        currentEmail,
        targetEmail: user.email
      });
      await refetchData();

      showSnackbar({
        message: `${user.email}'s role has been changed in the division.`,
        type: 'success'
      });
    } catch (error) {
      console.error(error);
      showSnackbar({
        message: _get(error, 'response.data', settingsErrorMessage),
        type: 'error'
      });
    }
  };

  return (
    <TeamUserOptionPopup modalTitle="Transfer Ownership" onConfirm={() => transferOwnership()} {...rest}>
      <Text variant="subtitle2" inline>
        {user.email}
      </Text>{' '}
      {`will be made the new owner of this account. ${
        currentRole === 'owner' ? "Your account's user status will be changed to Admin." : ''
      }`}
    </TeamUserOptionPopup>
  );
}
