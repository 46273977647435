import React from 'react';
import LogoIcon from '../common/Logo/LogoIcon/LogoIcon';
import Flex from 'src/components/BeaconRedesignComponents/Flex/Flex';
import { Text } from 'src/components/BeaconRedesignComponents/Generic/Text';

interface LogoContainerProps {
  title: string;
}

const logoSize = '80px';

export default function LogoContainer({ title }: LogoContainerProps) {
  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex justifyContent="center">
        <div style={{ width: logoSize, height: logoSize, maxHeight: logoSize, maxWidth: logoSize }}>
          <LogoIcon height={logoSize} width={logoSize} />
        </div>
      </Flex>
      <Text
        variant="h4"
        textAlign="center"
        transform="uppercase"
        fontWeight="bold"
        sx={{ fontSize: '24px', marginTop: '10px' }}
      >
        {title}
      </Text>
    </Flex>
  );
}
