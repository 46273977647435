import {
  NewToBrandOverTimeAdvancedSearchResponse,
  NewToBrandOverTimeDocument
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/NewToBrandTab/NewToBrandOverTime/types';
import _get from 'lodash/get';
import moment from 'moment';

/**
 * Parses advanced search response from NTB Product Grid api request
 */
export const adNewToBrandOverTimeAdvancedSearchParser = (response: NewToBrandOverTimeAdvancedSearchResponse) => {
  const newToBrandOverTimeData: NewToBrandOverTimeDocument[] = _get(
    response,
    ['aggregations', 'by_adProductType.keyword'],
    []
  );
  return newToBrandOverTimeData;
};

interface BuildRequestForNewToBrandOverTimeParameters {
  startDayId: string | number;
  endDayId: string | number;
  retailerId?: string | number;
}

export const buildRequestForNewToBrandOverTime = ({
  startDayId,
  endDayId,
  retailerId = 1
}: BuildRequestForNewToBrandOverTimeParameters) => {
  return [
    {
      name: 'new_to_brand_over_time',
      id: 'new_to_brand_over_time',
      pageNumber: 1,
      pageSize: 1000,
      doAggregation: true,
      returnDocuments: false,
      conditions: {
        condition: 'must',
        termFilters: [
          {
            fieldName: 'periodStartDate',
            condition: 'should',
            values: [startDayId]
          },
          {
            fieldName: 'periodEndDate',
            condition: 'should',
            values: [endDayId]
          },
          {
            fieldName: 'retailerId',
            condition: 'should',
            values: [retailerId]
          }
        ],
        computeFilters: []
      },
      searchBy: 'parent',
      aggregations: [
        {
          aggregationFields: [
            {
              aggregateByFieldName: 'beaconClientId',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'adSales',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'newToBrandAdSales',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'adUnitsSold',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'newToBrandAdUnitsSold',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'orders',
              function: 'sum',
              canBeExported: true
            },
            {
              aggregateByFieldName: 'newToBrandOrders',
              function: 'sum',
              canBeExported: true
            }
          ],
          groupByFieldName: 'adProductType.keyword,eventDate'
        }
      ],
      searchType: 'advertising-amc-new-to-brand-over-time',
      processDocuments: false
    }
  ];
};

export const convertNewToBrandOverTimeDataToCSV = ({ data, dateRange, primaryMetric, secondaryMetric }) => {
  const headers = ['Date', `${primaryMetric} for ${secondaryMetric}: ${dateRange}`].map((val) => `"${val}"`).join(',');

  const values = data.map((dataPoint) => {
    const date = moment.utc(dataPoint[0]).format('MMM D');
    const dataValue = dataPoint[1] / 100;
    return [`${date}`, `${dataValue}`].map((val) => `"${val}"`).join(',');
  });

  return `${headers}\n${values.join('\n')}`;
};
