import { Dispatch } from 'react';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { buildAggregations } from 'src/components/AdManager/Search/index';
import { buildFullMonthRangeFilters } from 'src/utils/dateformatting';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { SearchGridConstants } from 'src/components/AdManager/Search/GridDataFetchers/SearchGridConstants';
import _get from 'lodash/get';
import { CancelTokenSource } from 'axios';
import _ from 'lodash';

export class PortfoliosProjectedSpendFetcher {
  // eslint-disable-next-line class-methods-use-this
  public async addProjectedSpendDetails(
    mainEntity: any,
    cloneResult: any,
    app: any,
    indexName: any,
    dispatch: Dispatch<any>,
    retailer: any,
    groupByField: any,
    cancelSource: CancelTokenSource
  ) {
    const rawSpendField = INDEX_FIELDS.getField(app.name, indexName, 'spend');
    const [{ aggregations: projectedSpendAggregationFields }] = buildAggregations([rawSpendField]);
    const projectedSpendTimePeriodRangeFilters = buildFullMonthRangeFilters();
    const portfolioIds = _.uniq(cloneResult.data.map((p) => p.id));

    const queryConditions = {
      termFilters: [
        {
          fieldName: 'isProjected',
          condition: 'must',
          values: [0, 1]
        },
        {
          fieldName: 'portfolioId',
          condition: 'should',
          values: portfolioIds
        }
      ],
      rangeFilters: projectedSpendTimePeriodRangeFilters
    };
    const projectedSpendMapping = await dispatch(
      fetchEntityMetrics(
        'projectedSpendMapping_portfolios',
        {
          entity: mainEntity,
          retailer,
          app,
          indexName: 'adCampaignDailyMetrics'
        },
        [
          {
            doAggregation: true,
            aggregations: [
              {
                aggregationFields: projectedSpendAggregationFields,
                conditions: {
                  termFilters: [{ fieldName: 'retailerId', values: [+retailer.id] }],
                  rangeFilters: projectedSpendTimePeriodRangeFilters
                },
                groupByFieldName: groupByField.name
              }
            ],
            conditions: queryConditions,
            pageNumber: 1,
            pageSize: SearchGridConstants.PAGE_SIZE_10K,
            processDocuments: true,
            // TODO:
            additionalRequestMetaData: {
              returnAdditionalMetaData: false
            }
          }
        ],
        _get(cancelSource, 'token'),
        true
      )
    );

    cloneResult.data.forEach((portfolio: any) => {
      const projectedSpend = projectedSpendMapping.spend_by_portfolioId.data.find(
        (data: any) => data.entity.id === portfolio.id
      );
      portfolio.projectedSpend = projectedSpend;
    });
  }
}
