/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

import SegmentFilter from 'src/components/EntityPage/Filters/SegmentFilter';
import ReduxStore from 'src/types/store/reduxStore';

/**
 * Wraps the filter component that displays a list of segments to apply as filters and exposes an interface
 * that's compatible with that of the advanced search sidebar filters
 */

const mapStateToProps = (state: ReduxStore) => ({ mainEntity: state.entityService.mainEntity });

const ShimSegmentFilter: React.FC<
  { value: string | null; onChange: (...args: any[]) => void } & ReturnType<typeof mapStateToProps>
> = ({ value, onChange, mainEntity }) => (
  <>
    <span style={{ fontSize: 12, display: 'block', marginBottom: -12 }}>
      Segment and business unit filters will not be saved in created segments
    </span>

    <SegmentFilter value={value} onFilterChange={onChange} entity={mainEntity} showNoneSelectedItem />
  </>
);

export default connect(mapStateToProps)(ShimSegmentFilter);
