import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

export const OMNI_INVENTORY_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  inStockSummaryTrend: {
    name: 'inStockSummaryTrend',
    displayName: 'In-Stock Rate',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  inStockSummaryTopEntities: {
    name: 'inStockSummaryTopEntities',
    displayName: 'In-Stock Rate',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  avgInStockRate: {
    name: 'inStockSummaryTopEntities',
    displayName: 'Average In-Stock Rate',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  deliveryInStockRate: {
    name: 'deliveryInStockRate',
    displayName: 'Delivery In-Stock Rate',
    fulfillmentType: 'delivery',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  shippingInStockRate: {
    name: 'shippingInStockRate',
    displayName: 'Shipping In-Stock Rate',
    fulfillmentType: 'shipping',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  pickupInStockRate: {
    name: 'pickupInStockRate',
    displayName: 'Pickup In-Stock Rate',
    fulfillmentType: 'pickUp',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  storesInStock: {
    name: 'storesInStock',
    displayName: 'Stores In-Stock',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },

  avgShippingStoresInStock: {
    name: 'storesInStock',
    displayName: 'Stores In-Stock',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },

  deliveryStoresInStock: {
    name: 'storesInStock',
    displayName: 'Delivery Stores In-Stock',
    fulfillmentType: 'delivery',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  shippingStoresInStock: {
    name: 'storesInStock',
    displayName: 'Shipping Stores In-Stock',
    fulfillmentType: 'shipping',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  pickupStoresInStock: {
    name: 'storesInStock',
    displayName: 'Pickup Stores In-Stock',
    fulfillmentType: 'pickUp',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  outOfStockRateSummaryTrend: {
    name: 'outOfStockRateSummaryTrend',
    displayName: 'Out-of-Stock Rate',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  outOfStockRateSummaryTopEntities: {
    name: 'outOfStockRateSummaryTopEntities',
    displayName: 'Out-of-Stock Rate',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },

  avgOutOfStockRate: {
    name: 'outOfStockRateSummaryTopEntities',
    displayName: 'Average Out-of-Stock Rate',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },

  deliveryOutOfStockRate: {
    name: 'deliveryOutOfStockRate',
    displayName: 'Delivery Out-of-Stock Rate',
    fulfillmentType: 'delivery',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  shippingOutOfStockRate: {
    name: 'shippingOutOfStockRate',
    displayName: 'Shipping Out-of-Stock Rate',
    fulfillmentType: 'shipping',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  pickupOutOfStockRate: {
    name: 'pickupOutOfStockRate',
    displayName: 'Pickup Out-of-Stock Rate',
    fulfillmentType: 'pickUp',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },

  avgStoresOutOfStock: {
    name: 'avgStoresOutOfStock',
    displayName: 'Stores Out-of-Stock',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  deliveryStoresOutOfStock: {
    name: 'deliveryStoresOutOfStock',
    displayName: 'Delivery Stores Out-of-Stock',
    fulfillmentType: 'delivery',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  shippingStoresOutOfStock: {
    name: 'shippingStoresOutOfStock',
    displayName: 'Shipping Stores Out-of-Stock',
    fulfillmentType: 'shipping',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  pickUpStoresOutOfStock: {
    name: 'pickUpStoresOutOfStock',
    displayName: 'Pickup Stores Out-of-Stock',
    fulfillmentType: 'pickUp',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  placementRate: {
    name: 'placementRate',
    displayName: 'Placement Rate',
    aggregationFunction: 'avg',
    fulfillmentType: 'delivery',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  storesWithPlacement: {
    name: 'storesWithPlacement',
    displayName: 'Stores with placement',
    fulfillmentType: 'delivery',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  },
  availabilityRate: {
    name: 'availabilityRate',
    displayName: 'Availability Rate',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  deliveryAvailability: {
    name: 'deliveryAvailability',
    displayName: 'Delivery Availability Rate',
    fulfillmentType: 'delivery',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  shippingAvailability: {
    name: 'shippingAvailability',
    displayName: 'Shipping Availability Rate',
    fulfillmentType: 'shipping',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  pickUpAvailability: {
    name: 'pickUpAvailability',
    displayName: 'Pickup Availability Rate',
    fulfillmentType: 'pickUp',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT
  },
  storesWithAvailability: {
    name: 'storesWithAvailability',
    displayName: 'Stores with Availability',
    aggregationFunction: 'avg',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME
  }
};
