import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withBus } from 'react-bus';
import CircularProgress from '@mui/material/CircularProgress';
import { SpeedDialIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import _pick from 'lodash/pick';

import colors from 'src/utils/colors';
import MenuButton from '../common/Buttons/MenuButton';
import MenuButtonSingle from '../common/Buttons/MenuButtonSingle';
import { MenuPlusIcon } from '../SvgIcons';
import './PageContextMenu.scss';
import { shouldShowNewBeacon } from 'src/utils/app';

const styles = () => ({
  progressDarkBlue: {
    color: colors.darkBlue
  },
  progressWhite: {
    color: 'white'
  }
});

const PageContextMenu = ({ eventBus, widget, classes }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadIconOn = () => setIsDownloading(true);
  const downloadIconOff = () => setIsDownloading(false);
  useEffect(() => {
    eventBus.on('downloadIconOff', downloadIconOff);
    eventBus.on('downloadIconOn', downloadIconOn);
    return () => {
      eventBus.off('downloadIconOff', downloadIconOff);
      eventBus.off('downloadIconOn', downloadIconOn);
    };
  });

  const mainIcon = useMemo(
    () =>
      isDownloading ? (
        <CircularProgress size={40} className={classes.progressWhite} />
      ) : (
        <SpeedDialIcon icon={<MenuPlusIcon />} />
      ),
    [isDownloading, classes.progressWhite]
  );

  const subItems = widget.view.buttons.map(({ icon: Icon, eventName, text }) => ({
    icon: <Icon width="33px" />,
    text,
    onClick: () => {
      eventBus.emit(eventName);
    }
  }));

  const menuInfo = {
    mainButton: {
      icon: mainIcon,
      direction: 'up'
    },
    subItems
  };

  if (shouldShowNewBeacon()) {
    return null;
  }

  if (widget.data && widget.data.appName === 'advertising') {
    return (
      <div className="page-context-container">
        <MenuButtonSingle menuInfo={menuInfo} style={{ top: '60px ' }} />
      </div>
    );
  }

  return (
    <div className="page-context-container">
      <MenuButton menuInfo={menuInfo} style={{ top: '60px' }} />
    </div>
  );
};

PageContextMenu.propTypes = {
  widget: PropTypes.object.isRequired,
  eventBus: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => _pick(state, ['app', 'retailer', 'mainTimePeriod', 'user']);

const enhance = compose(connect(mapStateToProps), withBus('eventBus'), withStyles(styles));

const EnhancedPageContextMenu = enhance(PageContextMenu);

export default EnhancedPageContextMenu;
