import { useDispatch } from 'react-redux';
import { FetchAdditionalDataFn } from './types';
import useFetchEntityMetricsArgs from './useFetchEntityMetricsArgs';
import { useCallback } from 'react';
import { PortfoliosDataFetcher } from '../GridDataFetchers/Portfolios/PortfoliosDataFetcher';
import { DataForGroupByField } from '../Models/DataForGroupByField';
import { SearchGridConstants } from '../GridDataFetchers/SearchGridConstants';
import { GridIntermediateData } from '../Models/GridIntermediateData';

/**
 * Returns a callback function for fetching portfolios data.
 */
const useFetchPortfoliosData = (): FetchAdditionalDataFn => {
  const dispatch = useDispatch();
  const { entity, retailer, app } = useFetchEntityMetricsArgs();

  return useCallback(
    async ({
      indexName,
      pageNumber,
      searchSideBarConditions,
      groupByField,
      aggregationFilters,
      location,
      dataSet,
      fetchMetricsParams
    }) => {
      const dataForGroupByField = new DataForGroupByField();
      const portfoliosDataFetcher = new PortfoliosDataFetcher(dispatch, undefined); // TODO add cancel source
      if (groupByField.name === SearchGridConstants.GROUP_BY_PORTFOLIO_ID) {
        const gridIntermediateData: GridIntermediateData =
          portfoliosDataFetcher.fetchPortfolioDataThatMatchMetadataFilters(searchSideBarConditions, { location });
        dataForGroupByField.portfolioGridIntermediateData = gridIntermediateData;
      }

      const gridIntermediateData = dataForGroupByField.portfolioGridIntermediateData;
      const finalResult = await portfoliosDataFetcher.fetchPortfoliosData(
        { location },
        searchSideBarConditions,
        aggregationFilters,
        entity,
        app,
        indexName,
        retailer,
        groupByField,
        gridIntermediateData,
        fetchMetricsParams,
        dataSet,
        pageNumber
      );
      return finalResult;
    },
    [app, dispatch, entity, retailer]
  );
};

export default useFetchPortfoliosData;
