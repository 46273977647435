import types from './types';

export interface OmniRetailerContentConfigureServiceState {
  data: {
    title: boolean;
    image: boolean;
    video: boolean;
    bullet: boolean;
    description: boolean;
    enhanced: boolean;
    health: boolean;
  }[];
  isFetching: boolean;
}

const omniRetailerContentConfigureService = (state = { data: [], isFetching: false }, action) => {
  switch (action.type) {
    case types.START_FETCH:
      return {
        data: [],
        isFetching: true
      };

    case types.RECEIVE_OMNI_RETAILER_CONTENT_DATA:
      return {
        isFetching: false,
        data: action.entity.data
      };

    default:
      return state;
  }
};

export default omniRetailerContentConfigureService;
