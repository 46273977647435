import React from 'react';
import { withBus } from 'react-bus';
import { withPush } from 'src/utils/hoc';

import 'src/components/Grids/Data/NoResultTemplate.scss';

export const NoResultWithOpenFilter = withBus('eventBus')((props: any) => {
  const openFilter = () => {
    props.eventBus.emit('toggleSearchSidebar', null);
  };
  return (
    <div className="ag_grid_no_result">
      <div className="no_results_header">No results found</div>
      <div className="no_results_subheader">It appears there are no results for this criteria.</div>
      <div role="button" onClick={openFilter} className="open_filter">
        Edit Search Filters
      </div>
    </div>
  );
});

export const NoResultWithCustomButton = (props: any) => {
  return (
    <div className="ag_grid_no_result">
      <div className="no_results_header">No results found</div>
      <div className="no_results_subheader">It appears there are no results for this criteria.</div>
      <div role="button" onClick={() => props.handleChange('')} className="open_filter">
        Back
      </div>
    </div>
  );
};

export const NoScheduledAction = withPush((props: any) => {
  const openSummary = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', 'adScheduledActions');
    searchParams.set('subtab', 'create');
    const newParams = searchParams.toString();
    props.push(`/home?${newParams}`);
  };
  return (
    <div className="ag_grid_no_result">
      <div className="no_results_header">No scheduled actions</div>
      <div className="no_results_subheader">It appears there are no scheduled actions.</div>
      <div role="button" onClick={openSummary} className="open_filter">
        Create Scheduled Action
      </div>
    </div>
  );
});

export const CongratulationsWithOpenSummary = withPush((props: any) => {
  const openSummary = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('subtab', 'keyMetrics');
    const newParams = searchParams.toString();
    props.push(`?${newParams}`);
  };
  return (
    <div className="ag_grid_no_result">
      <div className="no_results_header">Congratulations</div>
      <div className="no_results_subheader">It appears all of the recommendations have been followed.</div>
      <div role="button" onClick={openSummary} className="open_filter">
        Return to Summary
      </div>
    </div>
  );
});

export const NoDataForIneligible = withPush((props: any) => {
  const openSummary = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', 'adManager');
    searchParams.set('subtab', 'campaignSummary');
    const newParams = searchParams.toString();
    props.push(`/home?${newParams}&selectedEntityName=campaignId`);
  };
  return (
    <div className="ag_grid_no_result">
      <div className="no_results_header">Congratulations</div>
      <div className="no_results_subheader">Currently, all of your products are eligible for advertising.</div>
      <div role="button" onClick={openSummary} className="open_filter">
        View all Campaigns
      </div>
    </div>
  );
});

export const NoDataForBudgetConstraints = withPush((props: any) => {
  const openSummary = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', 'adManager');
    searchParams.set('subtab', 'campaignSummary');
    const newParams = searchParams.toString();
    props.push(`/home?${newParams}&selectedEntityName=campaignId`);
  };
  return (
    <div className="ag_grid_no_result">
      <div className="no_results_header">No Budget Constraints</div>
      <div className="no_results_subheader">Your campaigns are rarely going out of budget.</div>
      <div className="no_results_subheader">Nice work on allocating appropriate resources to your campaigns.</div>
      <div role="button" onClick={openSummary} className="open_filter">
        View all Campaigns
      </div>
    </div>
  );
});

export const NoDataForStablePacing = withPush((props: any) => {
  const openSummary = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', 'adManager');
    searchParams.set('subtab', 'campaignSummary');
    const newParams = searchParams.toString();
    props.push(`/home?${newParams}&selectedEntityName=portfolioId`);
  };
  return (
    <div className="ag_grid_no_result">
      <div className="no_results_header">None with Stable Pacing</div>
      <div className="no_results_subheader">
        Currently, there are no portfolios on pace to spend above 80% of their budget.
      </div>
      <div role="button" onClick={openSummary} className="open_filter">
        View all Portfolios
      </div>
    </div>
  );
});

export const NoDataForUnderPacing = withPush((props: any) => {
  const openSummary = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', 'adManager');
    searchParams.set('subtab', 'campaignSummary');
    const newParams = searchParams.toString();
    props.push(`/home?${newParams}&selectedEntityName=portfolioId`);
  };
  return (
    <div className="ag_grid_no_result">
      <div className="no_results_header">Congratulations</div>
      <div className="no_results_subheader">Currently, there are no portfolios that are under pacing.</div>
      <div role="button" onClick={openSummary} className="open_filter">
        View all Portfolios
      </div>
    </div>
  );
});

export const NoDataForOutOfBudget = withPush((props: any) => {
  const openSummary = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', 'adManager');
    searchParams.set('subtab', 'campaignSummary');
    const newParams = searchParams.toString();
    props.push(`/home?${newParams}&selectedEntityName=portfolioId`);
  };
  return (
    <div className="ag_grid_no_result">
      <div className="no_results_header">Congratulations</div>
      <div className="no_results_subheader">Currently, there are no portfolios out of budget.</div>
      <div role="button" onClick={openSummary} className="open_filter">
        View all Portfolios
      </div>
    </div>
  );
});

export const NoDataForPendingStart = withPush((props: any) => {
  const openSummary = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', 'adManager');
    searchParams.set('subtab', 'campaignSummary');
    const newParams = searchParams.toString();
    props.push(`/home?${newParams}&selectedEntityName=portfolioId`);
  };
  return (
    <div className="ag_grid_no_result">
      <div className="no_results_header">No Pending Starts</div>
      <div className="no_results_subheader">Currently, there are no portfolios with a future pending start date.</div>
      <div role="button" onClick={openSummary} className="open_filter">
        View all Portfolios
      </div>
    </div>
  );
});
