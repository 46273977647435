import React from 'react';
import MetricSummaryTrendCardLoading from 'src/components/BeaconRedesignComponents/MetricSummaryTrendCard/MetricSummaryTrendCardLoading';
import MetricSummaryTrendCard, {
  MetricSummaryTrendCardProps
} from 'src/components/BeaconRedesignComponents/MetricSummaryTrendCard/MetricSummaryTrendCard';
import { useMetricByWeekId, UseMetricByWeekIdArgs } from 'src/serverProxy/useMetricByWeekId';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { getAppName } from 'src/utils/app';
import useDerivedMetricByWeekId from 'src/serverProxy/useDerivedMetricByWeekId';
import { getDirection } from 'src/components/BeaconRedesignComponents/utils/chartStyles';
import _get from 'lodash/get';

export const getCardTitle = (
  indexName: string,
  fieldName: string,
  titleOverrides?: Record<string, Record<string, string>>
) => {
  return (
    _get(titleOverrides, [indexName, fieldName]) ||
    INDEX_FIELDS.getField(getAppName(), indexName, fieldName).displayName
  );
};

export const getCardMetricType = (indexName: string, fieldName: string) => {
  return INDEX_FIELDS.getField(getAppName(), indexName, fieldName).metricType;
};

export interface SmartKeyMetricCardProps extends UseMetricByWeekIdArgs {
  variant: 'large' | 'small' | 'medium';
  title?: string;
  onClick?: () => void;
  buildMetricSummaryTrendCardProps?: (defaultProps: MetricSummaryTrendCardProps) => MetricSummaryTrendCardProps;
}

/**
 * Key metric card for a derived field
 */
const DerivedKeyMetricCard = ({
  indexName,
  fieldName,
  variant,
  title,
  onClick,
  buildMetricSummaryTrendCardProps
}: SmartKeyMetricCardProps) => {
  const { isLoading, primaryData, secondaryData, formattedPrimaryTotal, formattedPercentChange, percentChange } =
    useDerivedMetricByWeekId(indexName, fieldName);

  const getMetricSummmaryTrendCardProps = (): MetricSummaryTrendCardProps => {
    const defaultProps: MetricSummaryTrendCardProps = {
      title: title || getCardTitle(indexName, fieldName),
      splinePreviewProps: {
        metricType: getCardMetricType(indexName, fieldName),
        primaryData,
        secondaryData
      },
      value: formattedPrimaryTotal,
      change: formattedPercentChange,
      direction: getDirection(percentChange),
      selected: false,
      onClick
    };

    return buildMetricSummaryTrendCardProps ? buildMetricSummaryTrendCardProps(defaultProps) : defaultProps;
  };

  return isLoading ? (
    <MetricSummaryTrendCardLoading variant={variant} />
  ) : (
    <MetricSummaryTrendCard variant={variant} {...getMetricSummmaryTrendCardProps()} />
  );
};

/**
 * Key metric card for all fields except derived
 */
const KeyMetricCard = ({
  indexName,
  fieldName,
  variant,
  title,
  onClick,
  buildMetricSummaryTrendCardProps,
  ...rest
}: SmartKeyMetricCardProps) => {
  const {
    isLoading,
    field,
    primaryData,
    secondaryData,
    formattedPrimaryTotal,
    formattedPercentChange,
    percentChange,
    formattedLastPrimaryValue,
    formattedPercentChangeLastValues,
    percentChangeLastValues
  } = useMetricByWeekId({
    fieldName,
    indexName,
    ...rest
  });

  const useLatestWeek = field && field.timePeriodAggregationFunctionType === 'lastValue';
  const mainMetric = useLatestWeek ? formattedLastPrimaryValue : formattedPrimaryTotal;
  const rawChange = useLatestWeek ? percentChangeLastValues : percentChange;
  const change = useLatestWeek ? formattedPercentChangeLastValues : formattedPercentChange;

  const getMetricSummmaryTrendCardProps = (): MetricSummaryTrendCardProps => {
    const defaultProps: MetricSummaryTrendCardProps = {
      title: title || getCardTitle(indexName, fieldName),
      splinePreviewProps: {
        metricType: getCardMetricType(indexName, fieldName),
        primaryData,
        secondaryData
      },
      value: mainMetric,
      change,
      direction: getDirection(rawChange),
      selected: false,
      onClick
    };

    return buildMetricSummaryTrendCardProps ? buildMetricSummaryTrendCardProps(defaultProps) : defaultProps;
  };

  return isLoading ? (
    <MetricSummaryTrendCardLoading variant={variant} />
  ) : (
    <MetricSummaryTrendCard variant={variant} {...getMetricSummmaryTrendCardProps()} />
  );
};
/**
 * Given an index name and field name, it fetches the data
 * and displays it inside of a key metric summary card.
 * We call it a "smart" key metric card because it handles all
 * the logic of fetching, loading states, and display.
 */
const SmartKeyMetricCard = (props: SmartKeyMetricCardProps) => {
  const field = INDEX_FIELDS.getField(getAppName(), props.indexName, props.fieldName);

  // Back here
  return field.aggregationFunctionType === 'derived' ? (
    <DerivedKeyMetricCard {...props} />
  ) : (
    <KeyMetricCard {...props} />
  );
};

export default SmartKeyMetricCard;
