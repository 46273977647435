import { EntityGridNoclipHeaderWrapper } from 'src/components/EntityGrid/HeaderComponentFrameworks/MultiLineHCF';
import { INDEX_FIELDS, ENTITIES } from 'src/utils/entityDefinitions';
import EntityGrid from 'src/components/EntityGrid';
import _merge from 'lodash/merge';
import VIEWS from 'src/components/Layout/ViewDefaultConfig';
import AtlasProductCellFormatter from 'src/components/EntityGrid/Table/AtlasProductCellFormatter';
import { AppName } from 'sl-api-connector';

const buildConversionRateGridWidget = ({ app }) => {
  const indexName = 'traffic';
  const mainAggregationField = INDEX_FIELDS.getField(app.name, 'sales', 'purchaseRate', 'product', 'stacklineSku');
  const aggregationFields = [
    mainAggregationField,
    INDEX_FIELDS.getField(app.name, 'sales', 'retailSales', 'product', 'stacklineSku'),
    INDEX_FIELDS.getField(app.name, 'sales', 'unitsSold', 'product', 'stacklineSku'),
    INDEX_FIELDS.getField(app.name, 'sales', 'retailPrice', 'product', 'stacklineSku')
  ];
  const gridAggregationFields = aggregationFields.map((field) => ({
    ...field,
    cellRendererFramework: AtlasProductCellFormatter,
    cellStyle: { 'text-align': 'right', 'padding-right': '20px', 'flex-direction': 'row-reverse' }
  }));
  return {
    CustomComponent: EntityGrid,
    view: _merge({}, VIEWS.entityGrid, {
      anchorName: 'purchase-rate-entity-grid',
      WrapperComponent: EntityGridNoclipHeaderWrapper,
      gridOptions: {
        defaultLayout: 'table',
        title: 'Conversion Rate'
      }
    }),
    data: {
      groupByFields: [INDEX_FIELDS.getField(app.name, indexName, 'stacklineSku', 'product')],
      configByGroupByFieldName: {
        stacklineSku: {
          indexName,
          entity: ENTITIES[app.name].product,
          mainMetricField: mainAggregationField,
          aggregationFields,
          tableView: {
            metricFields: gridAggregationFields
          }
        }
      },
      additionalFieldsForExport: [
        ...([AppName.Beacon, AppName.Atlas].includes(app.name)
          ? [INDEX_FIELDS.getField(app.name, 'traffic', 'organicClicks', 'product', 'stacklineSku')]
          : [])
      ]
    }
  };
};

export default buildConversionRateGridWidget;
