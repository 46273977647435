import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  ADJUSTMENT_TABLE_QUERY,
  FORECAST_SUMMARY_KEYMETRIC_QUERY
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ForecastQueryKeys';
import {
  ADJUSTMENT_INTERVAL_DURATION_MS,
  ADJUSTMENT_POLL_DURATION_MS
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/RefactoredAdjustmentModals/constants';

/**
 *  Continuously invalidates the adjustment query keys for a limited amount of time.
 */
const useAdjustmentPolling = () => {
  const [isPolling, setIsPolling] = useState(false);
  const queryClient = useQueryClient();

  // eslint-disable-next-line
  useEffect(() => {
    if (isPolling) {
      // Polling duration in milliseconds
      const pollingDuration = ADJUSTMENT_POLL_DURATION_MS;
      // Polling interval in milliseconds
      const pollingInterval = ADJUSTMENT_INTERVAL_DURATION_MS;
      const endTime = Date.now() + pollingDuration;

      const intervalId = setInterval(() => {
        // Invalidate the adjustment query for the adjustments tab and the forecast summary key metrics
        queryClient.invalidateQueries({ queryKey: [ADJUSTMENT_TABLE_QUERY] });
        queryClient.invalidateQueries({ queryKey: [FORECAST_SUMMARY_KEYMETRIC_QUERY] });

        if (Date.now() >= endTime) {
          // Stop polling after the specified duration
          clearInterval(intervalId);
          setIsPolling(false);
        }
      }, pollingInterval);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isPolling, queryClient]);

  return { isPolling, setIsPolling };
};

export default useAdjustmentPolling;
