import { useMemo } from 'react';
import { useAdvancedSearchRequestBuilder } from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import AggregationBuilder from 'src/components/BeaconRedesignComponents/utils/AggregationBuilder';
import { useAppSelector } from 'src/utils/Hooks';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import {
  useApplyEntityFilters,
  useRemoveRetailPriceRangeFilters
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/serverProxy/useBaseMetricRequestBuilder';

export const useReviewTrendsDonutChartData = () => {
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const categories = useAppSelector((state) => state.categories);
  const applyEntityFilters = useApplyEntityFilters();
  const removeRetailPriceRangeFilters = useRemoveRetailPriceRangeFilters('reviews');

  const { startWeek, endWeek } = mainTimePeriod;
  const categoryIds = categories.map((category) => Number(category.id));

  const reviewDonutChartRequestId = 'reviewsDonutChartMetrics';

  const reviewDonutChartRequestBuilder = useAdvancedSearchRequestBuilder(reviewDonutChartRequestId, 'beacon-reviews');

  const reviewDonutChartRequest = useMemo(() => {
    const aggregationBuilder = new AggregationBuilder('stars');

    aggregationBuilder.addAggregationField('reviews', 'stars', 'sum', false);
    aggregationBuilder.addConditionTermFilter('stars', ['1', '2', '3', '4', '5']);
    aggregationBuilder.addConditionRangeFilter('weekId', startWeek, endWeek);

    reviewDonutChartRequestBuilder
      .setPageNumber(1)
      .setPageSize(104)
      .setPeriod('year')
      .setDoAggregation(true)
      .setReturnDocuments(false)
      .addConditionTermFilter('categoryId', 'should', categoryIds)
      .setSearchBy('parent')
      .addAggregation(aggregationBuilder.build())
      .addSortField('stars', 'stars', 'sum', false)
      .apply(applyEntityFilters)
      .apply(removeRetailPriceRangeFilters);

    return reviewDonutChartRequestBuilder.build();
  }, [
    applyEntityFilters,
    categoryIds,
    endWeek,
    removeRetailPriceRangeFilters,
    reviewDonutChartRequestBuilder,
    startWeek
  ]);

  const { data, isLoading } = useGenericAdvancedSearch({
    requestId: reviewDonutChartRequestId,
    queryKeys: [reviewDonutChartRequest],
    requestBody: [reviewDonutChartRequest]
  });

  const donutData = useMemo(() => {
    return data ? data.data[0].aggregations.by_stars : null;
  }, [data]);

  return { donutData, isLoading };
};
