import { useMemo } from 'react';
import AdvancedSearchRequestBuilder from 'src/components/BeaconRedesignComponents/utils/AdvancedSearchRequestBuilder';
import { useAppSelector } from 'src/utils/Hooks';
import useGenericAdvancedSearch from 'src/utils/Hooks/useGenericAdvancedSearch';
import _get from 'lodash/get';

interface ContentStats {
  approvedContentCount: number;
  matchedContentCount: number;
  extraContentCount: number;
}

export interface ContentAccuracyProductCompareData {
  bullets: string[];
  bulletsStats: ContentStats;
  imageUrls: string[];
  imageUrlsStats: ContentStats;
  videoUrls: string[];
  videoUrlsStats: ContentStats;
  title: string;
  titleStats: ContentStats;
  description: string;
  descriptionStats: ContentStats;
  aplusContent: number;
  aplusContentStats: ContentStats;
  ingredientsContentStats: ContentStats;
  detailsContentStats: ContentStats;
  nutritionFactsContentStats: ContentStats;
  warningsContentStats: ContentStats;
  directionsContentStats: ContentStats;
  featuresContentStats: ContentStats;
  descriptionContentStats: ContentStats;
  bulletContentStats: ContentStats;
  contentAccuracy: number;
  retailerId: number;
  retailerSku: string;
  timestamp: number;
  weekId: number;
  stacklineSku: string;
  sortResult: number[];
}

interface ContentAccuracyProductCompareDataReturnType {
  isLoading: boolean;
  contentAccuracyData: ContentAccuracyProductCompareData;
}
export const useContentAccuracyProductCompareData = ({
  id,
  searchType
}: {
  id: string;
  searchType: string;
}): ContentAccuracyProductCompareDataReturnType => {
  const retailer = useAppSelector((state) => state.retailer);
  const { id: productId } = useAppSelector((state) => state.entityService.mainEntity);
  const categories = useAppSelector((state) => state.categories);
  const categoryIds = categories.map((category) => category.id);

  const requestBuilder = new AdvancedSearchRequestBuilder(id, searchType);

  requestBuilder
    .setPageNumber(1)
    .setPageSize(1)
    .setPeriod('year')
    .setDoAggregation(false)
    .setReturnDocuments(true)
    .setSearchBy('child')
    .setRetailerId(retailer.id)
    .addConditionTermFilter('stacklineSku', 'should', [productId])
    .addConditionTermFilter('retailerId', 'should', [retailer.id])
    .addConditionTermFilter('categoryId', 'should', categoryIds)
    .addSortFieldWithOnlyFieldName('weekId', 'desc');

  const contentAccuracyProductRequest = requestBuilder.build();

  const { isLoading, data: contentData } = useGenericAdvancedSearch({
    requestId: searchType,
    requestBody: [contentAccuracyProductRequest],
    queryKeys: [contentAccuracyProductRequest]
  });

  const contentAccuracyData = useMemo(() => {
    return _get(contentData, 'data[0].documents[0]', { data: {} });
  }, [contentData]);

  return { isLoading, contentAccuracyData };
};
