import { ThunkDispatch } from 'redux-thunk';

import axios, { CancelToken } from 'axios';
import Creators from './actions';
import ReduxStore from 'src/types/store/reduxStore';
import { isDrive } from 'src/utils/app';

export const {
  receiveUserConfig,
  requestUserInfo,
  clearUserConfig,
  saveUserProfile,
  saveUserBilling,
  saveUserTeam,
  setTranslation,
  setAdAuditUserPreference,
  setSecondaryRetailerIds,
  requestSecondaryRetailers
} = Creators;

export const fetchUserAccount = (endpoint: string) => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
  dispatch(requestUserInfo());
  const response = await axios.get(`${endpoint}`);

  if (endpoint && endpoint.includes('Profile')) {
    return dispatch(saveUserProfile(response.data));
  } else if (endpoint && endpoint.includes('Billing')) {
    return dispatch(saveUserBilling(response.data));
  }
  return null;
};

export const enableTranslation =
  (req: { [key: string]: string[] }) => (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
    axios.post(`/api/user/EnableTranslation`, req).then((response) => {
      return dispatch(setTranslation(response.data.translationEnabled));
    });
  };

export const disableTranslation =
  (req: { [key: string]: string[] }) => (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
    axios.post(`/api/user/DisableTranslation`, req).then((response) => {
      return dispatch(setTranslation(response.data.translationEnabled));
    });
  };

export const fetchUserTeam = (endpoint: string) => (dispatch: ThunkDispatch<ReduxStore, void, any>) =>
  axios.post(`${endpoint}`).then((response) => {
    const team = Object.keys(response.data).map((key) => ({ ...response.data[key], email: key }));
    return dispatch(saveUserTeam(team));
  });

export const updateUserAccount = (endpoint: string, fields: any, cancelToken: CancelToken) => () => {
  return axios.post(`${endpoint}`, fields, { cancelToken });
};

interface AmcConfig {
  enabledRetailers: number[];
  pk: string;
  beaconClientId: string;
}
export const fetchSecondaryRetailerIds = () => async (dispatch: ThunkDispatch<ReduxStore, void, any>) => {
  dispatch(requestSecondaryRetailers());
  if (isDrive) {
    const { data } = await axios.get<AmcConfig>('/api/advertising/GetAmcConfig');
    return dispatch(
      setSecondaryRetailerIds({
        secondaryRetailerIds: data.enabledRetailers
      })
    );
  }

  return dispatch(
    setSecondaryRetailerIds({
      secondaryRetailerIds: []
    })
  );
};
