import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Chip from '@mui/material/Chip';
import { ExpandRowColumnLayout } from '@stackline/ui';
import React from 'react';
import { Row } from 'src/components/ShortageDisputes/components/DisputeManagement/DisputeDetailsTable';
import { SelectedIncludeCheckbox } from 'src/components/SvgIcons';
import { UnselectedCheckbox } from 'src/components/SvgIcons/SvgIcons';
import MenuButton from 'src/components/common/Buttons/MenuButton';
import CustomizedCheckbox from 'src/components/common/CustomizedCheckBox/CustomizedCheckBox';
import colors from 'src/utils/colors';
import './DisputeManagement.scss';

const chipColorMap = {
  'New Shortage': 'rgba(70, 168, 246, 0.2)',
  'Initial Dispute': 'rgba(254, 196, 74, 0.2)',
  'Contact Us Case': 'rgba(126, 143, 168, 0.2)',
  Resolved: 'rgba(86, 183, 136, 0.2)'
};

interface GetConnectColumnDefinitionsParams {
  toggleSelectAllForSubmit: (toggleAll: boolean) => void;
  handleDetailsPageClick: (s: string) => void;
  handleUploadFileClick: (rowData: Row) => void;
  selectRow: (rowIndex: number) => void;
  isSubmitting: boolean;
}

const getConnectedColumnDefinitions = ({
  toggleSelectAllForSubmit,
  handleDetailsPageClick,
  handleUploadFileClick,
  selectRow,
  isSubmitting
}: GetConnectColumnDefinitionsParams) => {
  const COLUMN_DEFINITIONS = [
    {
      field: '',
      headerName: (
        <CustomizedCheckbox
          styleObj={{
            width: 24,
            height: 24,
            iconBackgroundColor: 'transparent',
            iconColor: 'transparent',
            checkedIconBackgroundColor: 'transparent',
            checkedIconColor: 'transparent',
            paddingRight: '0px'
          }}
          InnerIcon={SelectedIncludeCheckbox}
          OuterIcon={UnselectedCheckbox}
          checkboxProps={{
            onClick: (e) => toggleSelectAllForSubmit(e.target.checked)
          }}
        />
      ),
      tableColumnProps: {
        align: 'left',
        padding: 'none',
        width: '60px'
      },
      valueFormatter: (str, rowData) => {
        return (
          <CustomizedCheckbox
            styleObj={{
              width: 24,
              height: 24,
              iconBackgroundColor: 'transparent',
              iconColor: 'transparent',
              checkedIconBackgroundColor: 'transparent',
              checkedIconColor: 'transparent',
              paddingRight: '0px'
            }}
            InnerIcon={SelectedIncludeCheckbox}
            OuterIcon={UnselectedCheckbox}
            checkboxProps={{
              disabled: rowData.disputeStatus !== 'Ready to Submit' || isSubmitting,
              checked: rowData.isChecked,
              onClick: () => selectRow(rowData.index)
            }}
          />
        );
      }
    },
    {
      field: 'originalInvoice',
      headerName: 'Original Invoice',
      tableColumnProps: {
        align: 'left',
        padding: 'none',
        width: '165px'
      },
      valueFormatter: (str, rowData) => {
        const handleClick = () => {
          handleDetailsPageClick(rowData.originalInvoice);
        };
        return (
          <a role="button" style={{ cursor: 'pointer' }} onClick={handleClick}>
            {rowData.originalInvoice}
          </a>
        );
      }
    },
    {
      field: 'invoiceDate',
      headerName: 'Invoice Date',
      tableColumnProps: {
        width: '150px'
      }
    },
    {
      field: 'disputeType',
      headerName: 'Dispute Type',
      tableColumnProps: {
        width: '150px',
        align: 'left'
      }
    },
    {
      field: 'disputeValue',
      headerName: 'Shortage Value',
      tableColumnProps: {
        align: 'left',
        width: '175px'
      }
    },
    {
      field: 'disputeStage',
      headerName: 'Dispute Stage',
      tableColumnProps: {
        align: 'left',
        width: '165px'
      },
      valueFormatter: (str, rowData) => (
        <Chip
          label={rowData.disputeStage}
          sx={{ fontSize: '14px', backgroundColor: chipColorMap[rowData.disputeStage] }}
        />
      )
    },
    {
      field: 'disputeStatus',
      headerName: 'Dispute Status',
      tableColumnProps: {
        align: 'left',
        padding: 'none',
        fontSize: '1rem',
        width: '160px'
      }
    },
    {
      field: 'originalInvoice',
      headerName: '',
      tableColumnProps: {
        align: 'left',
        padding: '0'
      },
      valueFormatter: (str, rowData) => {
        if (isSubmitting) {
          return <DisabledMenuDropDown />;
        }
        return <WrappedMenuDropDown str={str} rowData={rowData} />;
      }
    },
    {
      field: 'originalInvoice',
      headerName: '',
      tableColumnProps: {
        align: 'left',
        padding: 'none'
      },
      expandRow: true
    }
  ];

  const EXPANDED_COLUMN_DEFINITIONS = {
    containerClassName: 'custom_expandedRow',
    columns: {
      ratingDetails: {
        title: 'Invoice Details',
        layout: ExpandRowColumnLayout.Table,
        fields: {
          purchaseOrder: {
            title: 'PO #',
            className: 'title'
          },
          disputeId: {
            title: 'Dispute ID',
            className: 'title'
          },
          asin: {
            title: 'ASIN(s)',
            className: 'title'
          }
        }
      },
      financialDetails: {
        title: 'Financial Details',
        layout: ExpandRowColumnLayout.Table,
        fields: {
          submittedValue: {
            title: 'Submitted Value',
            className: 'title'
          },
          recoveredValue: {
            title: 'Recovered Value',
            className: 'title'
          },
          lostValue: {
            title: 'Lost Value',
            className: 'title'
          }
        }
      },
      shippingDetails: {
        title: 'Shipping Details',
        layout: ExpandRowColumnLayout.Table,
        fields: {
          trackingNumbers: {
            title: 'PRO/Tracking #',
            className: 'title'
          },
          arn: {
            title: 'ARN',
            className: 'title'
          },
          carrier: {
            title: 'Carrier',
            className: 'title'
          }
        }
      }
    }
  };

  const DetailsLink = () => {
    return <SearchIcon />;
  };

  const DisabledMenuDropDown = () => {
    const menuOptions = {
      mainButton: {
        icon: <MoreHorizIcon style={{ color: colors.darkBlue, fontSize: '25px' }} />,
        direction: 'down'
      },
      subItems: [],
      disabled: true
    };
    return (
      <>
        <div className="custom-line-chart">
          <MenuButton menuInfo={menuOptions} />
        </div>
      </>
    );
  };

  const WrappedMenuDropDown = ({ str, rowData }: { str: string; rowData: Row }) => {
    const menuOptions = {
      mainButton: {
        icon: <MoreHorizIcon style={{ color: colors.darkBlue, fontSize: '25px' }} />,
        direction: 'down'
      },
      subItems: [
        { icon: <DetailsLink />, text: 'View Details', onClick: () => handleDetailsPageClick(str) },
        { icon: <UploadFileIcon />, text: 'Upload Docs', onClick: () => handleUploadFileClick(rowData) }
      ]
    };
    return (
      <>
        <div className="custom-line-chart">
          <MenuButton menuInfo={menuOptions} />
        </div>
      </>
    );
  };
  return { COLUMN_DEFINITIONS, EXPANDED_COLUMN_DEFINITIONS };
};

export default getConnectedColumnDefinitions;
