import React, { FC } from 'react';
import ReduxStore from 'src/types/store/reduxStore';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ChevronIcon } from '../SvgIcons';
import { useSelector } from 'react-redux';

/**
 * For now, we only want to display $ USD
 * Once we have a better understanding of how Currency will be used in the application
 * we can create a handleCurrencyChange function and dynamically populate currency symbols
 */

const CurrencyDropDown: FC = () => {
  const retailer = useSelector((state: ReduxStore) => state.retailer);
  const { currencySymbol } = retailer;
  const currencySymbols = [{ id: '$', name: 'USD', displayName: '$ USD' }];
  const isMultipleCurrency = currencySymbols.length > 1;

  return (
    <Select
      variant="standard"
      value={currencySymbol}
      disabled={!isMultipleCurrency}
      input={
        <OutlinedInput
          labelWidth={0}
          id="outlined-age-simple"
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
              paddingRight: 0
            }
          }}
        />
      }
      // IconComponent={() => <ChevronIcon className="sl-header__drop-down-icon" />}
      IconComponent={() => (isMultipleCurrency ? <ChevronIcon className="sl-header__drop-down-icon" /> : <></>)}
    >
      {currencySymbols.map((val) => (
        <MenuItem key={val.name} value={val.id}>
          {val.displayName}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CurrencyDropDown;
