import { createTheme, adaptV4Theme } from '@mui/material/styles';
// import getMuiTheme from 'material-ui/styles/getMuiTheme';

// This replaces the textColor value on the palette
// and then update the keys for each component that depends on it.
// More on Colors: http://www.material-ui.com/#/customization/colors
// BEACON COLORS
import colors from 'src/utils/colors';
import fontStyle from 'src/utils/fontStyle';

// Add more things to the `buildMuiTheme()` if you need to edit the theme.
export const buildMuiTheme = () =>
  createTheme(
    adaptV4Theme({
      palette: {
        primary: { 500: colors.stacklineBlue }
      },
      overrides: {
        MuiPaper: {
          root: {
            background: 'white',
            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px'
          },
          elevation8: {
            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px'
          }
        },
        MuiTooltip: {
          popper: {
            willChange: 'unset !important'
          },
          tooltip: {
            // position: absolute;
            maxWidth: 670,
            fontWeight: 400,
            color: '#fff',
            borderRadius: 4,
            fontSize: 15,
            whiteSpace: 'normal',
            padding: '8px 18px',
            backgroundColor: colors.darkBlue
            // transform: 'scale(1.9) translateZ(0px)'
            // transform: scale(1.9) translateZ(0)!important;
          }
        },
        MuiMenuItem: {
          root: {
            fontWeight: fontStyle.regularWeight,
            color: colors.darkBlue,
            '&:hover': {
              background: colors.white
            },
            '&$selected': {
              background: 'none !important',
              color: `${colors.blue} !important`,
              '&:hover': {
                background: `${colors.white} !important`
              }
            }
          }
        },
        MuiTabs: {
          root: {
            minHeight: 0
          }
        },
        MuiTab: {
          wrapper: {
            fontWeight: 'inherit'
          }
        },
        MuiSelect: {
          select: {
            margin: '0 15px 0 15px',
            color: colors.darkBlue,
            fontWeight: fontStyle.regularWeight,
            fontSize: 16.5,
            display: 'flex',
            background: 'none'
          },
          root: {
            margin: '0 15px 0 15px',
            color: colors.darkBlue,
            fontWeight: fontStyle.regularWeight,
            fontSize: 16.5,
            display: 'flex',
            justifyContent: 'flex-end'
          },
          selectMenu: {
            display: 'flex',
            backgroundColor: 'green',
            alignItems: 'center',
            fontWeight: fontStyle.regularWeight,
            '&:focus': {
              background: 'none'
            },
            background: 'none',
            border: 'none'
          },
          outlined: {
            '&:focus': {
              background: 'none'
            }
          }
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              border: '1px solid red'
            }
          },
          root: {
            color: colors.darkBlue,
            fontSize: '16px'
          }
        },
        MuiInput: {
          underline: {
            '&&&&:hover:before': {
              borderBottom: `1px solid ${colors.lightestGrey}`
            },
            '&:before': {
              borderBottom: `1px solid ${colors.lightestGrey}`
            },
            '&:after': {
              borderBottom: `1px solid ${colors.lightestGrey}`
            }
          }
        },
        MuiOutlinedInput: {
          root: {
            backgroundColor: '#ffffffb5',
            borderRadius: 4,
            border: 0,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            '&:hover': { backgroundColor: '#ffffffd9' },
            '&$focused': { backgroundColor: '#ffffffd9' }
          },
          input: {
            minHeight: 23,
            padding: '16.5px 18px 14px 18px',
            fontWeight: '400'
          },
          notchedOutline: {
            background: 'none',
            border: 'none',
            '&:focus': {
              background: 'none'
            }
          }
        },
        MuiDialog: {
          paperWidthSm: {
            maxWidth: 'none'
          }
        },
        MuiFormControl: {
          root: {
            // width: '305px',
            borderRadius: '6px'
          }
        },
        MuiFilledInput: {
          root: {
            backgroundColor: '#ffffffb5',
            borderRadius: '6px',
            border: 0,
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            '&:hover': { backgroundColor: '#ffffffd9' },
            '&$focused': { backgroundColor: '#ffffffd9' }
          },
          underline: {
            '&:before': {
              content: 'none'
            },
            '&:after': {
              content: 'none'
            }
          }
        },
        MuiFormHelperText: {
          root: {
            color: 'white',
            fontWeight: 400
          }
        },
        MuiModal: {
          root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }
        },
        // This mimics the appearance of the MUI v0 buttons that are used in most of the application
        MuiButton: {
          root: {
            borderRadius: 2
          },
          label: {
            color: '#fff',
            fontSize: 12,
            fontWeight: 500
          }
        },
        selected: {
          color: colors.white
        }
      },

      typography: {
        useNextVariants: true
      }
    })
  );

// export const buildV0MuiTheme = () =>
//   getMuiTheme({
//     menuItem: {
//       hoverColor: colors.white,
//       height: 32
//     },
//     datePicker: {
//       selectColor: colors.stacklineBlue,
//       headerColor: colors.stacklineBlue
//     },
//     flatButton: {
//       primaryTextColor: colors.stacklineBlue,
//       buttonFilterColor: colors.lighterGrey
//     },
//     checkbox: {
//       boxColor: colors.lighterGrey,
//       checkedColor: colors.stacklineBlue
//     },
//     radioButton: {
//       borderColor: colors.lighterGrey,
//       checkedColor: colors.stacklineBlue
//     },
//     textField: {
//       floatingLabelColor: colors.darkBlue
//     }
//   });
