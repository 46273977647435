import { create } from 'zustand';

interface NotificationBadgeState {
  shouldShowNotificationBadge: boolean;
  setShowNotificationBadge: () => void;
  setRemoveNotificationBadge: () => void;
}

/** Notify user that download is completed. currently used in AppProfile Icon */
const useNotificationsBadge = create<NotificationBadgeState>((set) => ({
  shouldShowNotificationBadge: false,
  setShowNotificationBadge: () => set({ shouldShowNotificationBadge: true }),
  setRemoveNotificationBadge: () => set({ shouldShowNotificationBadge: false })
}));

export default useNotificationsBadge;
