import { Parser } from 'expr-eval';
import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

const ADVERTISING_ADENTITY_METADATA_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  entityId: {
    name: 'entityId',
    displayName: 'Entity',
    displayNamePlural: 'Entities',
    entity: {
      type: 'adEntity'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality',
    overrides: {
      searchKeyword_Override: {
        aggregationFunction: 'cardinality'
      }
    }
  }
};

const ADVERTISING_ADPORTFOLIO_METADATA_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  portfolioId: {
    name: 'portfolioId',
    displayName: 'Portfolio',
    displayNamePlural: 'Portfolios',
    entity: {
      type: 'adPortfolio'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality',
    overrides: {
      searchKeyword_Override: {
        aggregationFunction: 'cardinality'
      }
    }
  }
};

const ADVERTISING_ADCAMPAIGN_METADATA_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  campaignId: {
    name: 'campaignId',
    displayName: 'Campaign',
    displayNamePlural: 'Campaigns',
    entity: {
      type: 'campaign'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality',
    overrides: {
      searchKeyword_Override: {
        aggregationFunction: 'cardinality'
      }
    }
  },
  campaignName: {
    name: 'campaignName',
    displayName: 'Campaign Name',
    entity: {
      type: 'campaign'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality',
    overrides: {
      searchKeyword_Override: {
        aggregationFunction: 'cardinality'
      }
    }
  },
  campaignType: {
    name: 'campaignType',
    displayName: 'Ad Type',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  }
};

export const ADVERTISING_ADCAMPAIGN_ADGROUP_PRODUCT_METADATA_FIELDS: {
  [key: string]: FieldDefinition;
} = {};

export const ADVERTISING_ADCAMPAIGN_ADGROUP_TARGET_METADATA_FIELDS: {
  [key: string]: FieldDefinition;
} = {};

export const ADVERTISING_METADATA_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  ...ADVERTISING_ADENTITY_METADATA_FIELDS,
  ...ADVERTISING_ADPORTFOLIO_METADATA_FIELDS,
  ...ADVERTISING_ADCAMPAIGN_METADATA_FIELDS,
  searchKeyword: {
    name: 'searchKeyword',
    displayName: 'Keyword',
    displayNamePlural: 'Keywords',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    timePeriodAggregationFunctionType: 'lastValue',
    timePeriodAggregationFunction: 'sum',
    overrides: {
      stacklineSku_Override: {
        aggregationFunctionType: 'lastValue',
        aggregationFunction: 'sum'
      },
      dayId_Override: {
        aggregationFunction: 'cardinality',
        metricType: METRICTYPE.INTEGER
      },
      weekId_Override: {
        aggregationFunction: 'cardinality',
        metricType: METRICTYPE.INTEGER
      },
      retailerId_Override: {
        aggregationFunction: 'cardinality',
        metricType: METRICTYPE.INTEGER
      }
    }
  }
};

export const ADVERTISING_INCREMENTALITY_METRICS_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  brandTotalClicksIncrementalSales: {
    name: 'brandTotalClicksIncrementalSales',
    displayName: 'Incremental Sales',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  brandTotalClicksIncrementalROAS: {
    name: 'brandTotalClicksIncrementalROAS',
    displayName: 'Incremental ROAS',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('spend>0?(brandTotalClicksIncrementalSales/spend):0')
  },
  brandTotalClicksIncrementalClicks: {
    name: 'brandTotalClicksIncrementalClicks',
    displayName: 'Incremental Clicks',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('sales>0?(brandTotalClicksIncrementalSales/sales)*clicks:0')
  },
  brandTotalClicksIncrementalUnitsOrders: {
    name: 'brandTotalClicksIncrementalUnitsOrders',
    displayName: 'Incremental Units/Orders',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('sales>0?(brandTotalClicksIncrementalSales/sales)*unitsSold:0')
  },
  avgIncrementality: {
    name: 'avgIncrementality',
    displayName: 'Incrementality',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('sales>0?(brandTotalClicksIncrementalSales/sales):0')
  }
};

export const ADVERTISING_METRICS_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  impressions: {
    name: 'impressions',
    displayName: 'Impressions',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  clicks: {
    name: 'clicks',
    displayName: 'Ad Clicks',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  spend: {
    name: 'spend',
    isNegativeMetric: true,
    displayName: 'Ad Spend',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  sales: {
    name: 'sales',
    displayName: 'Ad Sales',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  unitsSold: {
    name: 'unitsSold',
    displayName: 'Ad Units Sold',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  conversions: {
    name: 'conversions',
    displayName: 'Orders',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  conversionRate: {
    name: 'conversionRate',
    displayName: 'Conversion Rate',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('clicks>0?unitsSold/clicks:0')
  },
  averageSellPrice: {
    name: 'averageSellPrice',
    displayName: 'Avg Sell Price',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold>0?sales/unitsSold:0')
  },
  clickThroughRate: {
    name: 'clickThroughRate',
    displayName: 'CTR %',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('impressions>0?clicks/impressions:0')
  },
  costPerClick: {
    name: 'costPerClick',
    isNegativeMetric: true,
    displayName: 'Cost per Click',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('clicks>0?spend/clicks:0')
  },
  costPerMilli: {
    name: 'costPerMilli',
    isNegativeMetric: true,
    displayName: 'CPM',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('impressions>0?(spend * 1000)/impressions:0')
  },
  advertisingCostOfSale: {
    name: 'advertisingCostOfSale',
    isNegativeMetric: true,
    displayName: 'ACOS',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('sales>0?spend/sales:0')
  },
  returnOnAdSpend: {
    name: 'returnOnAdSpend',
    displayName: 'ROAS', // 'Return on Ad Spend',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('spend>0?sales/spend:0')
  },
  costPerAcquisition: {
    isNegativeMetric: true,
    name: 'costPerAcquisition',
    displayName: 'Cost per Acquisition',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('unitsSold>0?spend/unitsSold:0')
  },
  adCostOfSales: {
    name: 'adCostOfSales',
    displayName: 'Ad Cost of Sales',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('sales>0?spend/sales:0')
  },
  // This metric allows for an imitation brand margin metric to be constructed by assuming that the margin percentage
  // is 18% and then taking that from the average ad sale price.
  brandGrossMargin: {
    name: 'brandGrossMargin',
    displayName: 'Brand Margin',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('(unitsSold>0?sales/unitsSold:0)/(50/9)')
  }
};

export const ADVERTISING_DISPLAY_METADATA_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  orderId: {
    name: 'orderId',
    displayName: 'Order',
    displayNamePlural: 'Orders',
    entity: {
      type: 'order'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  orderName: {
    name: 'orderName',
    displayName: 'Order',
    displayNamePlural: 'Orders',
    entity: {
      type: 'order'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  lineItemId: {
    name: 'lineItemId',
    displayName: 'Line Item',
    displayNamePlural: 'Line Items',
    entity: {
      type: 'lineItem'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  lineItemName: {
    name: 'lineItemName',
    displayName: 'Line Item',
    displayNamePlural: 'Line Items',
    entity: {
      type: 'lineItem'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  creativeId: {
    name: 'creativeId',
    displayName: 'Creative',
    displayNamePlural: 'Creatives',
    entity: {
      type: 'creative'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  creativeName: {
    name: 'creativeName',
    displayName: 'Creative',
    displayNamePlural: 'Creatives',
    entity: {
      type: 'creative'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  creativeType: {
    name: 'creativeType',
    displayName: 'Creative Type',
    displayNamePlural: 'Creative Types',
    entity: {
      type: 'creativeType'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  creativeSize: {
    name: 'creativeSize',
    displayName: 'Creative Size',
    displayNamePlural: 'Creative Sizes',
    entity: {
      type: 'creativeSize'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  conversionType: {
    name: 'conversionType',
    displayName: 'Conversion Type',
    displayNamePlural: 'Conversion Type',
    entity: {
      type: 'conversionType'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  }
};

export const ADVERTISING_DISPLAY_METRICS_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  dpv: {
    name: 'dpv',
    displayName: 'DPV',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  eCPDPV: {
    name: 'eCPDPV',
    displayName: 'eCPDPV',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  atc: {
    name: 'atc',
    displayName: 'ATC',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  eCPATC: {
    name: 'eCPATC',
    displayName: 'eCPATC',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  purchases: {
    name: 'purchases',
    displayName: 'Purchases',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  eCPP: {
    name: 'eCPP',
    displayName: 'eCPP',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  newToBrandSales: {
    name: 'newToBrandSales',
    displayName: 'New To Brand Sales',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  newToBrandUnitsSold: {
    name: 'newToBrandUnitsSold',
    displayName: 'New To Brand Units Sold',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  newToBrandPurchases: {
    name: 'newToBrandPurchases',
    displayName: 'New To Brand Purchases',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  sales: {
    name: 'sales',
    displayName: 'Ad Sales - Display',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  unitsSold: {
    name: 'unitsSold',
    displayName: 'Ad Units Sold - Display',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  spend: {
    name: 'spend',
    displayName: 'Ad Spend - Display',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  clicks: {
    name: 'clicks',
    displayName: 'Ad Clicks - Display',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  impressions: {
    name: 'impressions',
    displayName: 'Impressions - Display',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  viewableImpressions: {
    name: 'viewableImpressions',
    displayName: 'Viewable Impressions - Display',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  conversionRate: {
    name: 'conversionRate',
    displayName: 'Conversion Rate',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('dpv>0?unitsSold/dpv:0')
  },
  percentNewToBrand: {
    name: 'percentNewToBrand',
    displayName: 'New To Brand %',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('purchases>0?newToBrandPurchases/purchases:0')
  },
  returnOnAdSpend: {
    name: 'returnOnAdSpend',
    displayName: 'ROAS',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('spend>0?sales/spend:0')
  },
  eCPM: {
    name: 'eCPM',
    displayName: 'eCPM',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  costPerClick: {
    name: 'costPerClick',
    isNegativeMetric: true,
    displayName: 'Cost per Click',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('clicks>0?spend/clicks:0')
  },
  costPerMilli: {
    name: 'costPerMilli',
    isNegativeMetric: true,
    displayName: 'CPM',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('impressions>0?(spend * 1000)/impressions:0')
  },
  costPerAcquisition: {
    name: 'costPerAcquisition',
    isNegativeMetric: true,
    displayName: 'CPA',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('purchases>0?spend/purchases:0')
  },
  clickThroughRate: {
    name: 'clickThroughRate',
    displayName: 'CTR %',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('impressions>0?clicks/impressions:0')
  },
  detailPageViewRate: {
    name: 'detailPageViewRate',
    displayName: 'DPVR %',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('impressions>0?dpv/impressions:0')
  }
};
