/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import pluralize from 'pluralize';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import ReduxStore from 'src/types/store/reduxStore';
import Widget from 'src/components/EntityPage/Widget';
import { buildFullMonthRangeFilters } from 'src/utils/dateformatting';
import { AggregationField } from 'src/types/application/types';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { store } from 'src/main';
import { NoDataForIneligible } from 'src/components/Grids/Data/NoResultTemplate';
import { getPluralVerb } from 'src/utils/stringFormatting';
import { fetchEntityMetrics } from 'src/store/modules/entitySearchService/operations';
import { Entity } from 'sl-api-connector/types';

import { buildMainEntityConditions } from 'src/components/EntityPage/Renderer/EntityPageRenderer';
import { buildAggregations, zipMetricsResponseIntoArray } from 'src/components/AdManager/Search';
import isNaN from 'lodash/isNaN';

export function parseInput(input) {
  if (typeof input !== 'string') {
    throw new Error('Invalid input: input must be a string');
  }

  const currencySymbols = /[$¢£¤¥֏؋৲৳៛₠₡₢₣₤₥₦₧₨₩₪₫€₭₮₯₰₱₲₳₴₵₶₷₸₹₺₻₼₽₾₿Rs]/;
  const currencyAbbreviations = /(?:AUD|CAD|CHF|CNY|EUR|GBP|INR|JPY|NZD|USD)/;
  const currencyRegex = new RegExp(`${currencySymbols.source}|${currencyAbbreviations.source}`);
  const currencySign = input.match(currencyRegex) || '';
  const parsed = parseFloat(input.replace(/[^0-9.]/g, ''));

  if (isNaN(parsed)) {
    throw new Error('Invalid input: unable to parse number from string');
  }

  return { currencySign, value: parsed };
}

function formatNumber(num, currencySign = '') {
  if (typeof num !== 'number') {
    throw new Error('Invalid input: num must be a valid number');
  }

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let value = num;
  let suffixIndex = 0;

  while (Math.abs(value) >= 1000 && suffixIndex < suffixes.length - 1) {
    value /= 1000;
    suffixIndex++;
  }

  return `${currencySign}${value.toFixed(2)}${suffixes[suffixIndex]}`.trim();
}

function convertToStandardFormat(num: string, currencySign = '$') {
  if (!num) {
    return '';
  }

  const formattedNumber = formatNumber(num, currencySign);

  return formattedNumber;
}

const style = {
  marginBottom: 12
};

interface DefaultInfo {
  [key: string]: any;
  productSkus: any[];
  totalCount: number;
  ineligible: any;
  missingDecoration: any;
  notBuyable: any;
  missingBuyBox: any;
}

const defaultValue = {
  count: 0,
  stacklineSkus: [],
  sale: '-',
  spend: '-'
};

const defaultInfo: DefaultInfo = {
  productSkus: [],
  totalCount: 0,
  adPolicingSuspended: defaultValue,
  ineligible: defaultValue,
  missingDecoration: defaultValue,
  missingImage: defaultValue,
  notBuyable: defaultValue,
  missingBuyBox: defaultValue
};

export const INELIGIBLE_FIELDS = [
  'ad_policing_suspended',
  'ineligible',
  'missing_decoration',
  'missing_image',
  'not_buyable',
  'not_in_buybox'
];

const ineligibleParser = (action: any): DefaultInfo => {
  const { groupByFieldName: groupByField } = action.apiRequest[0].aggregations[0];
  const productSkus: any[] = [];
  const allStacklineSkus = new Set();
  const result = action.apiRequest.map((request: any, index: number) => {
    const data = action.apiResponse.data[index];
    const dataSet = data.aggregations[`by_${groupByField}`] || [];
    const stacklineSkus = dataSet.map((dataPoint: any) =>
      _get(dataPoint, ['additionalMetaData', 'product', 'stacklineSku'])
    );
    stacklineSkus.forEach((sku: string) => {
      allStacklineSkus.add(sku);
    });
    productSkus.push(...stacklineSkus);
    return {
      count: stacklineSkus.length,
      stacklineSkus
    };
  });
  const totalCount = allStacklineSkus.size;
  return {
    totalCount,
    productSkus,
    adPolicingSuspended: result[0],
    ineligible: result[1],
    missingDecoration: result[2],
    missingImage: result[3],
    notBuyable: result[4],
    missingBuyBox: result[5]
  };
};

export const fetchIneligibleCountForEntity = (entity: Entity): Promise<DefaultInfo> => {
  const { app, retailer } = store.getState();
  const dataKey = `inligibleCount-${entity.type}-${entity.id}`;

  const indexName = 'adCampaignAdGroupProduct';
  const entityConditions = buildMainEntityConditions({ termFilters: [], rangeFilters: [] }, entity, app, retailer);

  // const [{ aggregations: aggregationFieldsForResultCount }] = buildAggregations([productField]);

  const fields: AggregationField[] = ['stacklineSku'].map((fieldName) =>
    INDEX_FIELDS.getField(app.name, indexName, fieldName)
  );

  const [{ aggregations: aggregationFields }] = buildAggregations(fields);

  // const ineligibleServingStatuses = ['ended', 'archived', 'ENDED'];
  const searchRequestOverrides = INELIGIBLE_FIELDS.map((statusStr) => {
    return {
      doAggregation: true,
      aggregations: [
        {
          aggregationFields,
          conditions: {
            termFilters: [{ fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] }],
            rangeFilters: []
          },
          groupByFieldName: 'stacklineSku'
        }
      ],
      conditions: {
        termFilters: [
          ...entityConditions.termFilters,
          {
            fieldName: 'status',
            condition: 'must_not',
            values: ['ended', 'archived']
          },
          {
            fieldName: 'statusReason',
            condition: 'should',
            values: [statusStr]
          }
        ]
      },
      pageNumber: 1,
      pageSize: 1000,
      searchBy: 'parent',
      processDocuments: false,
      useAggregationSort: false
    };
  });

  return new Promise((resolve) => {
    store.dispatch(
      fetchEntityMetrics(
        dataKey,
        {
          entity,
          retailer,
          app,
          indexName,
          customResponseParser: (action: any) => {
            const result = ineligibleParser(action);
            resolve(result);
            return result;
          }
        },
        searchRequestOverrides,
        null,
        true
      )
    );
  }) as Promise<DefaultInfo>;
};

const mapStateToProps = ({ entityService: { mainEntity }, retailer, app }: ReduxStore) => ({
  retailer,
  mainEntity,
  app
});

const AdIneligibleInfo: React.FC<
  {
    widget: any;
  } & ReturnType<typeof mapStateToProps>
> = ({ app, mainEntity, retailer, widget, ...props }) => {
  const [ineligibleInfo, setIneligibleInfo] = useState(defaultInfo);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    const result = await fetchIneligibleCountForEntity(mainEntity);

    // fetch sale and spend data for each ineligible type
    const indexName = 'adCampaignAdGroupProductDailyMetrics';
    const { groupByFieldName } = widget.data;
    const entityConditions = buildMainEntityConditions(
      { termFilters: [], rangeFilters: [] },
      mainEntity,
      app,
      retailer
    );

    const fields: AggregationField[] = ['spend', 'sales'].map((fieldName) =>
      INDEX_FIELDS.getField(app.name, indexName, fieldName)
    );
    const [{ aggregations: aggregationFields }] = buildAggregations(fields);
    const currentMonthRangeFilters = buildFullMonthRangeFilters();

    const searchRequestOverrides = {
      doAggregation: true,
      aggregations: [
        {
          aggregationFields,
          conditions: {
            termFilters: [
              { fieldName: 'retailerId', values: [Number.parseInt(retailer.id as any, 10)] },
              {
                fieldName: 'isProjected',
                condition: 'must',
                values: [0]
              }
            ],
            rangeFilters: currentMonthRangeFilters
          },
          groupByFieldName
        }
      ],
      conditions: {
        termFilters: [] as any[],
        rangeFilters: currentMonthRangeFilters
      },
      pageNumber: 1,
      pageSize: 1000,
      processDocuments: false,
      useAggregationSort: false
    };

    const { adPolicingSuspended, ineligible, missingDecoration, missingImage, notBuyable, missingBuyBox } = result;

    const allIneligible = [
      ['adPolicingSuspended', adPolicingSuspended],
      ['ineligible', ineligible],
      ['missingDecoration', missingDecoration],
      ['missingImage', missingImage],
      ['notBuyable', notBuyable],
      ['missingBuyBox', missingBuyBox]
    ];

    const values = await Promise.all(
      allIneligible.map((x: any) => {
        if (x[1].stacklineSkus.length > 0) {
          searchRequestOverrides.conditions.termFilters = [
            ...entityConditions.termFilters,
            {
              fieldName: 'status',
              condition: 'should',
              values: ['enabled']
            },
            {
              fieldName: 'stacklineSku',
              condition: 'should',
              values: x[1].stacklineSkus
            }
          ];
          const statePropertyName = `${widget.data.statePropertyName}_${x[0]}`;
          const cloneWidget = _cloneDeep(widget);
          cloneWidget.data.statePropertyName = statePropertyName;
          return store.dispatch(
            fetchEntityMetrics(
              statePropertyName,
              {
                entity: mainEntity,
                retailer,
                app,
                indexName,
                customResponseParser: (action: any) => {
                  return zipMetricsResponseIntoArray(action, cloneWidget);
                }
              },
              [searchRequestOverrides],
              null,
              true
            )
          );
        } else {
          return [];
        }
      })
    );
    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      const isInvalidValue = Array.isArray(value) && value.length === 0;
      if (!isInvalidValue) {
        const key: string = allIneligible[i][0];
        const saleValue = _get(value, ['data', 0, 'sales', 'value'], 0);
        const spendValue = _get(value, ['data', 0, 'spend', 'value'], 0);
        result[key].sale = convertToStandardFormat(saleValue);
        result[key].spend = convertToStandardFormat(spendValue);
      }
    }
    setIneligibleInfo(result);
    setIsLoading(false);
  };

  useEffect(() => {
    if (mainEntity && retailer) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainEntity, retailer]);
  const subHeader = `There ${getPluralVerb(ineligibleInfo.totalCount, 'is', 'are')} currently ${pluralize(
    'product',
    ineligibleInfo.totalCount,
    true
  )} ineligible for advertising due to the following reasons:`;

  const adPolicingSuspendedStr = `${pluralize(
    'product',
    ineligibleInfo.adPolicingSuspended.count,
    true
  )}, which generated ${ineligibleInfo.adPolicingSuspended.spend} in ad spend and ${
    ineligibleInfo.adPolicingSuspended.sale
  } in ad sales MTD.`;

  const ineligibleStr = `${pluralize('product', ineligibleInfo.ineligible.count, true)}, which generated ${
    ineligibleInfo.ineligible.spend
  } in ad spend and ${ineligibleInfo.ineligible.sale} in ad sales MTD.`;

  const missingDecorationStr = `${pluralize(
    'product',
    ineligibleInfo.missingDecoration.count,
    true
  )}, which generated ${ineligibleInfo.missingDecoration.spend} in ad spend and ${
    ineligibleInfo.missingDecoration.sale
  } in ad sales MTD.`;

  const missingImageStr = `${pluralize('product', ineligibleInfo.missingImage.count, true)}, which generated ${
    ineligibleInfo.missingImage.spend
  } in ad spend and ${ineligibleInfo.missingImage.sale} in ad sales MTD.`;

  const notBuyableStr = `${pluralize('product', ineligibleInfo.notBuyable.count, true)}, which generated ${
    ineligibleInfo.notBuyable.spend
  } in ad spend and ${ineligibleInfo.notBuyable.sale} in ad sales MTD.`;

  const missingBuyBoxStr = `${pluralize('product', ineligibleInfo.missingBuyBox.count, true)}, which generated ${
    ineligibleInfo.missingBuyBox.spend
  } in ad spend and ${ineligibleInfo.missingBuyBox.sale} in ad sales MTD.`;

  const { productSkus } = ineligibleInfo;
  const { gridWidget } = widget.data;
  const entityConditions = [
    {
      fieldName: 'stacklineSku',
      condition: 'should',
      values: productSkus
    }
  ];
  gridWidget.data.widgets[1].data.widgets[1].view.tabDefinitions[0].widget.data.customTermFilters = entityConditions;
  if (isLoading) {
    return null;
  }
  if (ineligibleInfo.totalCount > 0) {
    return (
      <>
        <div className="recommendations_container">
          <div className="header">Ineligible Products</div>
          <div style={style}>{subHeader}</div>
          {(parseInt(ineligibleInfo.adPolicingSuspended.count, 10) > 0 ||
            ineligibleInfo.adPolicingSuspended.count === '-') && (
            <div style={style}>
              <strong>Ad Policing Suspended: </strong>
              <span>{adPolicingSuspendedStr}</span>
            </div>
          )}
          {(parseInt(ineligibleInfo.ineligible.count, 10) > 0 || ineligibleInfo.ineligible.count === '-') && (
            <div style={style}>
              <strong>Ineligible: </strong>
              <span>{ineligibleStr}</span>
            </div>
          )}
          {(parseInt(ineligibleInfo.missingDecoration.count, 10) || ineligibleInfo.missingDecoration.count === '-') && (
            <div style={style}>
              <strong>Missing Decoration: </strong>
              <span>{missingDecorationStr}</span>
            </div>
          )}
          {(parseInt(ineligibleInfo.missingImage.count, 10) || ineligibleInfo.missingImage.count === '-') && (
            <div style={style}>
              <strong>Missing Image: </strong>
              <span>{missingImageStr}</span>
            </div>
          )}
          {(parseInt(ineligibleInfo.notBuyable.count, 10) > 0 || ineligibleInfo.notBuyable.count === '-') && (
            <div style={style}>
              <strong>Not Buyable: </strong>
              <span>{notBuyableStr}</span>
            </div>
          )}
          {(parseInt(ineligibleInfo.missingBuyBox.count, 10) > 0 || ineligibleInfo.missingBuyBox.count === '-') && (
            <div style={{ marginBottom: -45 }}>
              <strong>Missing Buy Box: </strong>
              <span>{missingBuyBoxStr}</span>
            </div>
          )}
        </div>
        <Widget {...props} noInnerContainer widget={gridWidget} />
      </>
    );
  }

  return <NoDataForIneligible />;
};

export default connect(mapStateToProps)(AdIneligibleInfo);
