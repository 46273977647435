import { Parser } from 'expr-eval';
import QueryString from 'qs';
import { PARENT_PLATFORMS } from 'src/store/modules/parentPlatform/platformUtils';

import {
  DATATYPE,
  METRICTYPE,
  FIELD_PATH_SEPARATOR,
  FieldDefinition
} from 'src/utils/entityDefinitions/entityDefinitionTypes';

const expressionParser = new Parser();

export const AD_OPTIMIZATION_HISTORY_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  beaconClientId: {
    name: 'beaconClientId',
    displayName: 'Organization',
    entity: {
      type: 'beaconclient'
    },
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.KEYFIELD,
    aggregationFunction: 'cardinality'
  },
  portfolioStartDate: {
    name: 'portfoliostartdate',
    displayName: 'Portfolio Start Date',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.DATETIME
  },
  portfolioEndDate: {
    name: 'portfolioEndDate',
    displayName: 'Portfolio End Date',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.DATETIME
  },
  portfolioamount: {
    name: 'portfolioamount',
    displayName: 'Portfolio Amount',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.DECIMAL,
    aggregationFunction: 'avg'
  },
  portfolioroastarget: {
    name: 'portfolioroastarget',
    displayName: 'Portfolio ROAS Target',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.DECIMAL,
    aggregationFunction: 'avg'
  },
  updatedTime: {
    name: 'updatedTime',
    displayName: 'Change Time',
    dataType: DATATYPE.DATETIME,
    metricType: METRICTYPE.DATETIME
  },
  optimizationStrategy: {
    name: 'optimizationStrategy',
    displayName: 'Optimization Strategy',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  oldValue: {
    name: 'oldValue',
    displayName: 'Previous Value',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  newValue: {
    name: 'newValue',
    displayName: 'New Value',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  changeStep: {
    name: 'changeStep',
    displayName: 'Change Step',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  changeSource: {
    name: 'changeSource',
    displayName: 'Change Source',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  changeAction: {
    name: 'changeAction',
    displayName: 'Optimizations',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'value_count'
  },
  changeActionDescription: {
    name: 'changeActionDescription',
    displayName: 'Action',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  changeDataType: {
    name: 'changeDataType',
    displayName: 'Change Data Type',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  changeReason: {
    name: 'changeReason',
    displayName: 'Change Reason',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  portfolioName: {
    name: 'portfolioName',
    displayName: 'Portfolio Name',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  campaignName: {
    name: 'campaignName',
    displayName: 'Campaign Name',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  campaignId: {
    name: 'campaignId',
    displayName: 'Campaign',
    displayNamePlural: 'Campaigns',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  portfolioId: {
    name: 'portfolioId',
    displayName: 'Portfolio',
    displayNamePlural: 'Portfolios',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  code: {
    name: 'code',
    displayName: 'Status Code',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  adGroupName: {
    name: 'adGroupName',
    displayName: 'Ad Group Name',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  searchKeyword: {
    name: 'searchKeyword',
    displayName: 'Keyword',
    displayNamePlural: 'Keywords',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  searchKeywordFuzzy: {
    name: 'searchkeyword',
    displayName: 'Keyword',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  matchingType: {
    name: 'searchkeyword',
    displayName: 'Matching Type',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT
  },
  hoursSaved: {
    name: 'hoursSaved',
    displayName: 'Hour Savings',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunctionType: 'computed',
    // 0.5 minutes per change roughly
    aggregationFunction: expressionParser.parse('changeAction > 0 ? ((changeAction * 8) / 1000) : 0')
  },
  costsSaved: {
    name: 'costsSaved',
    displayName: 'Labor Savings',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    // 0.5 minutes per change roughly @ 30$ per hour cost
    aggregationFunction: expressionParser.parse('changeAction > 0 ? ((changeAction * 8 * 30) / 1000) : 0')
  },
  bidSavings: {
    name: 'bidSavings',
    displayName: 'Bid Savings',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  budgetSavedPercentage: {
    name: 'budgetSavedPercentage',
    displayName: '% Budget Saved',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'derived',
    aggregationFunction: expressionParser.parse(
      `adCampaignAdGroupProductTargetDailyMetrics${FIELD_PATH_SEPARATOR}spend>0?adOptimizationHistory${FIELD_PATH_SEPARATOR}costsSaved/adCampaignAdGroupProductTargetDailyMetrics${FIELD_PATH_SEPARATOR}spend:0`
    ),
    aggregationFunctionExpressionString: `adCampaignAdGroupProductTargetDailyMetrics${FIELD_PATH_SEPARATOR}spend>0?adOptimizationHistory${FIELD_PATH_SEPARATOR}costsSaved/adCampaignAdGroupProductTargetDailyMetrics${FIELD_PATH_SEPARATOR}spend:0`
  },
  bidChanges: {
    name: 'bidChanges',
    displayName: 'Bid Optimizations',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('changeAction')
  },
  budgetChanges: {
    name: 'budgetChanges',
    displayName: 'Bid Optimizations',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('changeAction')
  }
};

export const AD_INCREMENTAL_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  brandTotalClicksIncrementalSales: {
    name: 'brandTotalClicksIncrementalSales',
    displayName: 'Incremental Sales',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  brandTotalClicksIncrementalROAS: {
    name: 'brandTotalClicksIncrementalROAS',
    displayName: 'Incremental ROAS',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('spend>0?(brandTotalClicksIncrementalSales/spend):0')
  },
  brandTotalClicksIncrementalClicks: {
    name: 'brandTotalClicksIncrementalClicks',
    displayName: 'Incremental Clicks',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('sales>0?(brandTotalClicksIncrementalSales/sales)*clicks:0')
  },
  brandTotalClicksIncrementalUnitsOrders: {
    name: 'brandTotalClicksIncrementalUnitsOrders',
    displayName: 'Incremental Units/Orders',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('sales>0?(brandTotalClicksIncrementalSales/sales)*unitsSold:0')
  },
  avgIncrementality: {
    name: 'avgIncrementality',
    displayName: 'Incrementality',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('sales>0?(brandTotalClicksIncrementalSales/sales):0')
  }
};

export const AD_CAMPAIGN_DAILY_METRICS_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  budgetAmount: {
    name: 'budgetAmount',
    displayName: 'Budget Amount',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  isProjected: {
    name: 'isProjected',
    displayName: 'Projected',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  budgetPacing: {
    name: 'budgetPacing',
    displayName: 'Budget Pacing',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    aggregationFunctionType: 'computed',
    aggregationFunction: expressionParser.parse('budgetAmount>0?spend/budgetAmount:0')
  }
};

export const ADMANAGER_ADENTITY_METADATA_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  entityId: {
    name: 'entityId',
    displayName: 'Entity',
    displayNamePlural: 'Entities',
    entity: {
      type: 'adEntity'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality',
    overrides: {
      targetingText_Override: {
        aggregationFunction: 'cardinality'
      }
    }
  }
};

export const ADMANAGER_ADGROUP_METADATA_FIELDS = () => {
  const getParentPlatform = () => {
    const params = QueryString.parse(window.location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const parentPlatform = (params && params.pp) || '';
    return parentPlatform;
  };

  const isCriteo = () => {
    return getParentPlatform() === PARENT_PLATFORMS.CRITEO;
  };

  return {
    adGroupId: {
      name: 'adGroupId',
      displayName: isCriteo() ? 'Line Item' : 'Ad Group',
      displayNamePlural: isCriteo() ? 'Line Items' : 'Ad Groups',
      entity: {
        type: 'adGroup'
      },
      dataType: DATATYPE.TEXT,
      metricType: METRICTYPE.TEXT,
      aggregationFunction: 'cardinality',
      overrides: {
        targetingText_Override: {
          aggregationFunction: 'cardinality'
        }
      }
    }
  };
};

export const ADMANAGER_ADPORTFOLIO_METADATA_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  portfolioId: {
    name: 'portfolioId',
    displayName: 'Portfolio',
    displayNamePlural: 'Portfolios',
    entity: {
      type: 'adPortfolio'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality',
    overrides: {
      targetingText_Override: {
        aggregationFunction: 'cardinality'
      }
    }
  }
};

export const ADMANAGER_ADCAMPAIGN_METADATA_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  campaignId: {
    name: 'campaignId',
    displayName: 'Campaign',
    displayNamePlural: 'Campaigns',
    entity: {
      type: 'adCampaign'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality',
    overrides: {
      targetingText_Override: {
        aggregationFunction: 'cardinality'
      }
    }
  },
  campaignName: {
    name: 'campaignName',
    displayName: 'Campaign Name',
    entity: {
      type: 'campaign'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality',
    overrides: {
      targetingText_Override: {
        aggregationFunction: 'cardinality'
      }
    }
  },
  campaignType: {
    name: 'campaignType',
    displayName: 'Ad Type',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  },
  derivedCampaignType: {
    name: 'derivedCampaignType',
    displayName: 'Ad Type',
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality'
  }
};

export const ADMANAGER_ADTARGET_METADATA_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  targetingText: {
    name: 'targetingText',
    displayName: 'Target',
    displayNamePlural: 'Targets',
    entity: {
      type: 'adCampaignAdGroupTarget'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality',
    overrides: {
      targetingText_Override: {
        aggregationFunction: 'cardinality'
      },
      dayId_Override: {
        aggregationFunction: 'cardinality',
        metricType: METRICTYPE.INTEGER
      },
      weekId_Override: {
        aggregationFunction: 'cardinality',
        metricType: METRICTYPE.INTEGER
      },
      retailerId_Override: {
        aggregationFunction: 'cardinality',
        metricType: METRICTYPE.INTEGER
      },
      parentPlatform_Override: {
        aggregationFunction: 'cardinality',
        metricType: METRICTYPE.INTEGER
      }
    }
  },
  targetId: {
    name: 'targetId',
    displayName: 'Target',
    displayNamePlural: 'Targets',
    entity: {
      type: 'adCampaignAdGroupTarget'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality',
    overrides: {
      targetingText_Override: {
        aggregationFunction: 'cardinality'
      },
      dayId_Override: {
        aggregationFunction: 'cardinality',
        metricType: METRICTYPE.INTEGER
      },
      weekId_Override: {
        aggregationFunction: 'cardinality',
        metricType: METRICTYPE.INTEGER
      },
      retailerId_Override: {
        aggregationFunction: 'cardinality',
        metricType: METRICTYPE.INTEGER
      }
    }
  },
  targetingType: {
    name: 'targetingType',
    displayName: 'Match Type',
    displayNamePlural: 'Match Types',
    entity: {
      type: 'adCampaignAdGroupTarget'
    },
    dataType: DATATYPE.TEXT,
    metricType: METRICTYPE.TEXT,
    aggregationFunction: 'cardinality',
    overrides: {
      targetingText_Override: {
        aggregationFunction: 'cardinality'
      },
      dayId_Override: {
        aggregationFunction: 'cardinality',
        metricType: METRICTYPE.INTEGER
      },
      weekId_Override: {
        aggregationFunction: 'cardinality',
        metricType: METRICTYPE.INTEGER
      },
      retailerId_Override: {
        aggregationFunction: 'cardinality',
        metricType: METRICTYPE.INTEGER
      }
    }
  }
};

export const BUDGET_PACING_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  monthlyBudget: {
    name: 'monthlyBudget',
    displayName: 'Monthly Budget',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  pacing: {
    name: 'pacing',
    displayName: 'Pacing Type',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  outOfBudget: {
    name: 'outOfBudget',
    displayName: 'Out of Budget',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  pendingStart: {
    name: 'pendingStart',
    displayName: 'Pending Start',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  spendMtd: {
    name: 'spendMtd',
    displayName: 'Spend MTD',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  spendProjected: {
    name: 'spendProjected',
    displayName: 'Spend Projected',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  }
};

/**
 * Dropdown metric values for Connect advertising overlap dropdown
 */
export enum AdvertisingOverlapMetric {
  ConversionRate = 'conversionRate',
  Conversions = 'conversions',
  PercentOfConversions = 'percentOfConversions',
  UniqueShoppers = 'uniqueShoppers',
  PercentOfUniqueShoppers = 'percentOfUniqueShoppers'
}

/**
 * Dropdown values for the Connect ad frequency dropdown
 */
export enum AdFrequencyDropdownOption {
  Awareness = 'awareness',
  Engagement = 'engagement',
  Conversion = 'conversion'
}

/**
 * Dropdown values for AMC New to Brand visualization
 */
export enum NewToBrandDropdownOption {
  AdSales = 'Ad Sales',
  UnitsSold = 'Ad Units Sold',
  Conversions = 'Conversions',

  // Group by options
  AdType = 'Ad Types',
  Campaign = 'Campaign'
}

export type ConnectDropdownOption = Pick<FieldDefinition, 'displayName' | 'name'>;

/**
 * Dropdown options for AMC advertising analysis visualization
 * dropdown
 */
export enum AdvertisingAnalyticsDropdownOption {
  Awareness = 'Awareness',
  Engagement = 'Engagement',
  Spend = 'Spend'
}

export enum AdvertisingAnalyticsMetricName {
  CTR = 'CTR',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  AdSpend = 'Ad Spend'
}

/* Phase 2 Field Definitions */
export enum AdConversionPathwaysDropdownOption {
  Impressions = 'Impressions',
  AdSales = 'Ad Sales',
  Conversions = 'Conversions',
  ReturnOnAdSpend = 'Return on Ad Spend',
  NewToBrandAdSales = 'New-to-Brand Ad Sales',
  NewToBrandConversion = 'New-to-Brand Conversions'
}

/**
 * Allows for easy access to field objects using our dropdown options without disrupting the current pattern.
 * Centralizes metric/data types for determining how to format the data for display.
 * Also defines the field name on our Adv. Search response documents.
 */
export const AdConversionPathwaysFieldMapping = {
  [AdConversionPathwaysDropdownOption.Impressions]: {
    name: 'impressions',
    displayName: 'Impressions',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  [AdConversionPathwaysDropdownOption.AdSales]: {
    name: 'adSales',
    displayName: 'Ad Sales',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  [AdConversionPathwaysDropdownOption.Conversions]: {
    name: 'orders',
    displayName: 'Conversion',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  },
  [AdConversionPathwaysDropdownOption.ReturnOnAdSpend]: {
    name: null, // Derived: Ad Sales / Spend
    displayName: 'ROAS',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  [AdConversionPathwaysDropdownOption.NewToBrandAdSales]: {
    name: 'newToBrandAdSales',
    displayName: 'New-to-Brand Ad Sales',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.MONEY,
    aggregationFunction: 'sum'
  },
  [AdConversionPathwaysDropdownOption.NewToBrandConversion]: {
    name: 'newToBrandUniqueShoppersThatPurchased',
    displayName: 'New-to-Brand Conversion',
    dataType: DATATYPE.INTEGER,
    metricType: METRICTYPE.VOLUME,
    aggregationFunction: 'sum'
  }
};

export enum TimeToConversionDropdownOption {
  AllAdTypes = 'All Ads',
  DemandSidePlatformAds = 'DSP Ads',
  SearchAds = 'Search Ads'
}

export enum FirstTouchAttributionDropdownOption {
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  AdSales = 'Ad Sales',
  UnitsSold = 'Ad Units Sold',
  Conversions = 'Conversions'
}

export enum NewToBrandOverTimeDropdownOption {
  AdSalesPercent = '% of Ad Sales New-to-Brand',
  AdUnitsSoldPercent = '% of Ad Units Sold New-to-Brand',
  ConversionsPercent = '% of Conversions New-to-Brand',
  AllAdsTypes = 'All Ads',
  DemandSidePlatformAds = 'DSP Ads',
  SearchAds = 'Search Ads'
}
