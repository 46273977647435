import { useQuery, useQueryClient } from 'react-query';
import { useAppSelector } from 'src/utils/Hooks';
import axios from 'axios';
import { GET_BULK_ADJUSTMENT_UPLOADS_QUERY_KEY } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Settings/Adjustments/constants';
import { GetBulkAdjustmentStatusesResponse } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/BulkAdjustments/serverProxy/types';
import { fetchUserMetaData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ViewAdjustmentModal/utils';
import { UserMetaData } from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Forecasts/ViewAdjustmentModal/types';
import { useEffect, useState } from 'react';

/**
 * Fetches the bulk adjustment upload data for the current retailer
 */
export const useBulkAdjustmentUploadData = ({ pageSize = 20 }: { pageSize?: number } = {}) => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1); // page number starts at 1
  const [maxPage, setMaxPage] = useState<number | null>(null); // max page number starts at 1

  const [pageToToken, setPageToToken] = useState<Record<number, string | null>>({ 1: null });
  const { BeaconClientId } = useAppSelector((state) => state.user.config.vendor);
  const { id } = useAppSelector((state) => state.retailer);
  const endpoint = '/api/beaconbulkadjustment/GetBulkAdjustmentStatuses';

  useEffect(() => {
    return () => {
      // We will lose the pagination tokens so we'll clear the cache when the component is unmounted
      queryClient.removeQueries([GET_BULK_ADJUSTMENT_UPLOADS_QUERY_KEY]);
    };
  }, [queryClient]);

  const query = useQuery(
    [GET_BULK_ADJUSTMENT_UPLOADS_QUERY_KEY, BeaconClientId, id, pageToToken[page]],
    async () => {
      try {
        const response = await axios.post(endpoint, {
          beaconClientId: BeaconClientId,
          retailerId: id,
          pageSize,
          paginationToken: pageToToken[page]
        });
        const responseData = response.data as GetBulkAdjustmentStatusesResponse;
        setPageToToken({ ...pageToToken, [page + 1]: responseData.paginationToken });
        if (!responseData.paginationToken) {
          setMaxPage(page);
        }
        return responseData;
      } catch (error) {
        console.error('Failed to fetch bulk adjustment upload data in useBulkAdjustmentUploadData', error);
        throw new Error('Failed to fetch bulk adjustment upload data');
      }
    },
    {
      refetchInterval: 10000, // refetch every 10 seconds if window is focused
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // data is stale after 5 minutes
      cacheTime: 1000 * 60 * 30 // data is removed from the cache after 30 minutes
    }
  );

  const onNextPage = () => {
    if (query.isLoading) {
      return;
    }

    if (maxPage) {
      setPage(Math.min(page + 1, maxPage));
    } else {
      setPage(page + 1);
    }
  };

  const onPreviousPage = () => {
    setPage(Math.max(page - 1, 1));
  };

  return {
    ...query,
    onNextPage,
    onPreviousPage,
    page
  };
};

/**
 * Given an array of user IDs, fetches user data for each user
 */
export const useUserData = ({
  userIds,
  queryKeys,
  enabled = true
}: {
  userIds: string[];
  queryKeys: any[];
  enabled?: boolean;
}) => {
  return useQuery(
    queryKeys,
    async () => {
      try {
        const response = await fetchUserMetaData({ userIds });
        return response.data as UserMetaData;
      } catch (error) {
        console.error('Failed to fetch user data in useUserData', error);
        return {};
      }
    },
    {
      enabled,
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // data is stale after 5 minutes
      cacheTime: 1000 * 60 * 30 // data is removed from the cache after 30 minutes
    }
  );
};
