/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';

import ReduxStore from 'src/types/store/reduxStore';
import { not } from 'src/utils/fp';
import { error } from 'src/utils/mixpanel';
import { PropTypesOf } from 'src/types/utils';

const mapStateToProps = (state: ReduxStore) => ({
  mainEntity: state.entityService.mainEntity as any,
  companyName: state.user.config.vendor.CompanyName,
  ..._pick(state, ['categories', 'filters'])
});

const buildTooltipText = ({
  mainEntity,
  categories,
  filters,
  companyName
}: ReturnType<typeof mapStateToProps>): string | null => {
  if (!mainEntity) {
    return null;
  }

  const subscribedCategoryCount = categories.length;
  const mainEntityName = ['businessunit', 'businessUnit'].includes(mainEntity.type)
    ? mainEntity.displayName
    : mainEntity.shortDisplayName || mainEntity.displayName;

  const isFiltered = Object.values(filters).find(not(_isEmpty));
  if (isFiltered) {
    if (
      [
        'company',
        'client',
        'organization',
        'category',
        'subcategory',
        'segment',
        'searchtermlist',
        'businessUnit',
        'businessunit'
      ].includes(mainEntity.type)
    ) {
      return `${companyName} market share based on selected filters`;
    } else if (mainEntity.type === 'product') {
      return `Selected product's market share based on selected filters`;
    }

    return `${mainEntityName} market share based on selected filters`;
  }

  switch (mainEntity.type) {
    case 'company':
    case 'client':
    case 'organization': {
      return `${companyName} market share within ${subscribedCategoryCount} subscribed categories`;
    }
    case 'category':
    case 'subcategory': {
      return `${companyName} market share within ${mainEntityName}`;
    }
    case 'brand': {
      return `${mainEntityName} market share within ${mainEntity.categoryIds.length} relevant categories`;
    }
    case 'product': {
      return `Selected product's market share within total category`;
    }
    case 'segment':
    case 'searchtermlist':
    case 'businessUnit':
    case 'businessunit': {
      return `${companyName} market share within ${mainEntityName}`;
    }
    default: {
      error(`Unhandled entity type of "${mainEntity.type}" when creating market share tooltip`);
      return `${mainEntity.displayName} Market Share of ${subscribedCategoryCount} subscribed categories`;
    }
  }
};

const MarketShareTooltipInner: React.FC<
  { placement?: PropTypesOf<typeof Tooltip>['placement'] | null | undefined } & ReturnType<typeof mapStateToProps>
> = ({ children, placement, ...props }) => {
  const tooltipText = useMemo(() => buildTooltipText(props), [props]);

  return (
    <Tooltip title={tooltipText} placement={placement || 'top'}>
      <span>{children || ''}</span>
    </Tooltip>
  );
};

const MarketShareTooltip = connect(mapStateToProps)(MarketShareTooltipInner);

export default MarketShareTooltip;
