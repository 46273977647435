import React from 'react';
import ReactDOMServer from 'react-dom/server';
import BeaconDoubleDropdownMetricColumnChart from 'src/components/BeaconRedesignComponents/BeaconMetricColumnChart/BeaconDoubleDropdownMetricColumnChart';
import {
  PROMOTION_QUERY_PARAM,
  PromotionMetricOptions,
  promotionMetricToFieldName,
  promotionTypeToDisplayName
} from 'src/components/BeaconRedesignComponents/ExperimentalLayout/Prices/Promotions/constants';

import { GenericXAxisLabel } from 'src/components/BeaconRedesignComponents/TopEntityChartV2/GenericXAxisLabel';
import TopEntityChartV2Loading from 'src/components/BeaconRedesignComponents/TopEntityChartV2/TopEntityChartV2Loading';
import { getQueryParamValue } from 'src/utils/app';
import { BEACON_INDEX_NAMES } from 'src/utils/entityDefinitions/indexes/beaconIndexNames';

const PromotionsDealTypeColumnChart = () => {
  const selectedMetric =
    promotionMetricToFieldName[getQueryParamValue(PROMOTION_QUERY_PARAM, PromotionMetricOptions.PROMOTION_COUNT)];

  return (
    <BeaconDoubleDropdownMetricColumnChart
      xAxisFormatter={(xAxisCategory) => {
        return ReactDOMServer.renderToStaticMarkup(<GenericXAxisLabel xAxisCategory={xAxisCategory} />);
      }}
      preProcessData={(data) => {
        // We swap count for the value to determine an accurate count of promotions
        return data.map((datum) => ({
          ...datum,
          values: {
            ...datum.values,
            stacklineSku_cardinality_value: datum?.values?.stacklineSku_cardinality_count ?? 0
          }
        }));
      }}
      postProcessData={(data) => {
        if (data) {
          return data.map((row) => {
            const [promotionType, value] = row;
            const displayName = promotionTypeToDisplayName[promotionType] || promotionType;
            return [displayName, Math.abs(value)];
          });
        } else {
          return data;
        }
      }}
      beaconChartWithLegendPropsBuilder={(props) => ({
        ...props,
        loadingComponent: <TopEntityChartV2Loading />
      })}
      selectedField={selectedMetric}
      fields={[
        { fieldName: 'stacklineSku', label: 'Promotion Count' },
        { fieldName: 'averagePromotionDiscount', label: 'Average Discount' },
        { fieldName: 'shareOfRetailSales', label: 'Share of Retail Sales' },
        { fieldName: 'shareOfUnitsSold', label: 'Share of Units Sold' }
      ]}
      showSingleGroupBy
      groupByFields={[
        {
          id: 'promoType',
          label: 'Deal Type',
          indexName: BEACON_INDEX_NAMES.PROMOTIONS
        }
      ]}
    />
  );
};

export default PromotionsDealTypeColumnChart;
