import _cloneDeep from 'lodash/cloneDeep';
import { ValueOf } from 'sl-api-connector/types';

import ReduxStore from 'src/types/store/reduxStore';
import actions from './actions';
import { shouldShowCriteo } from 'src/utils/app';

export default function currentRetailer(
  state: Pick<ReduxStore['retailer'], 'availableRetailers'>,
  action: ReturnType<ValueOf<typeof actions>>
) {
  const updatedRetailer = _cloneDeep(state.availableRetailers);
  const [newRetailer] = updatedRetailer.filter((availableRetailer) => availableRetailer.id === `${action.retailerId}`);
  if (shouldShowCriteo() && action.parentPlatform) {
    newRetailer.parentPlatform = action.parentPlatform;
  }
  return newRetailer;
}
