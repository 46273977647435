import React, { useMemo } from 'react';
import VennDiagram from 'src/components/Charts/Venn/VennDiagram';
import {
  convertAdOverlapDataToVennData,
  convertAdOverlapDataToBarData,
  getLabelFromVennSets,
  convertAdOverlapDataToCsv
} from './utils';
import { AdvertisingOverlapMetric } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import { AdvertisingOverlapData } from './types';
import { useAppSelector } from 'src/utils/Hooks';

interface AdvertisingOverlapVennDiagramProps {
  metric: AdvertisingOverlapMetric;
  isLoading: boolean;
  data: AdvertisingOverlapData;
}

/**
 * Displays a Venn diagram for advertising overlap visualization
 */
const AdvertisingOverlapVennDiagram = ({ isLoading, data, metric }: AdvertisingOverlapVennDiagramProps) => {
  const retailer = useAppSelector((state) => state.retailer);
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const aggregatedVennData = useMemo(() => {
    return data ? convertAdOverlapDataToVennData(data, metric, retailer) : [];
  }, [data, metric, retailer]);

  const barData = useMemo(() => {
    return data ? convertAdOverlapDataToBarData(data, metric) : [];
  }, [data, metric]);

  return (
    <VennDiagram
      loading={isLoading}
      data={aggregatedVennData}
      convertSeriesToDelimitedData={() =>
        convertAdOverlapDataToCsv({
          vennData: aggregatedVennData,
          barData,
          dateRange: mainTimePeriod.displayName,
          metric
        })
      }
      options={{
        chart: {
          marginTop: 64
        },
        plotOptions: {
          venn: {
            borderColor: 'transparent',
            borderWidth: 0
          }
        },
        tooltip: {
          useHTML: true,
          formatter() {
            const label = getLabelFromVennSets(this.point.sets);
            return `<span class="venn-label-tooltip">${label}</span>`;
          },
          style: {
            width: 180,
            opacity: 1,
            backgroundColor: 'white'
          }
        }
      }}
    />
  );
};

export default AdvertisingOverlapVennDiagram;
