import _cloneDeep from 'lodash/cloneDeep';
import { AppName } from 'sl-api-connector/types';

import GenericPageContainer from 'src/components/EntityPage/GenericPageContainer';
import { INDEX_FIELDS } from 'src/utils/entityDefinitions';
import { PageLayout, Widget } from 'src/types/application/widgetTypes';
import ReduxStore from 'src/types/store/reduxStore';
import { buildTrendWidgetConfig } from 'src/components/Layout/LayoutUtil';
import OmniTrendChart from 'src/components/EntityPage/TrendChart/OmniTrendChart';
import { buildOmniTopEntitiesWidgetConfig } from 'src/components/EntityPage/TopEntitiesChart/OmniTopEntitesChartUtil';
import OmniFilterWrapper from 'src/components/Omni/OmniFilter/OmniFIlterWrapper';
import CommonSummaryInfo from 'src/components/EntityPage/CommonSummaryInfo/CommonSummaryInfo';
import { buildShareOfShelfTableWidget } from 'src/components/Omni/OmniShareOfShelf/shareOfShelfTableUtil';
import { omniGridField, omniTableViewField } from 'src/components/EntityGrid/EntityGrid/omniGridField';
import { buildOmniProductsWidget } from 'src/components/EntityGrid/EntityGrid/OmniEntityGridUtils';
import { ExportIcon } from 'src/components/SvgIcons';
import PageContextMenu from 'src/components/PageContextMenu';
import { store } from 'src/main';

const SHARE_OF_SHELF_INDEX_NAME = 'shareOfShelf';
export const getLayoutForEntity = (app: ReduxStore['app'], subtatb: string, entityType: string): PageLayout => {
  const widgets: Widget[] = [];

  const indexName = SHARE_OF_SHELF_INDEX_NAME;
  const weekIdField = _cloneDeep(INDEX_FIELDS.getField(AppName.Omni, indexName, 'weekId'));
  const { retailerId } = store.getState().entityService.mainEntity;

  // keywordSegment, storeSegment, segment shares entityType of 'segment'.
  const isKeywordSegment = window.location.pathname.includes('keywordSegment');

  widgets.push({
    CustomComponent: OmniFilterWrapper,
    name: 'omniFilter',
    view: {
      name: 'omniFilter',
      style: { display: 'flex', justifyContent: 'end', marginTop: 41 },
      shouldShowFilter: isKeywordSegment || !(entityType === 'segment'),
      // we do not want to show keyword segment filter if we are already in keywordSegment
      shouldShowKeywordSegmentFilter: !isKeywordSegment,
      // keywordSegmentWill have only retailer Filter
      shouldShowOnlyRetailerFilter: true
    },
    data: {}
  });

  const defaultShareOfShelvesTopEntityGroupBy = ['retailerId', 'brand', 'keyword'];

  const filteredShareOfShelvesTopEntityGroupBy = defaultShareOfShelvesTopEntityGroupBy.filter((groupName) => {
    if (entityType === 'retailer') {
      // for Instacart, we still need to show retailer dropdown, since it will have -instacart Target, instacart Walmart. etc
      if (retailerId === '63') {
        return true;
      }
      return groupName !== 'retailerId';
    }
    if (entityType === 'brand') {
      return groupName !== 'brand';
    }
    return true;
  });

  switch (subtatb) {
    case 'total': {
      widgets.push(
        buildTrendWidgetConfig(
          app,
          indexName,
          { type: entityType },
          'weekId',
          ['totalShareOfShelf', 'totalShareOfShelfByTop3', 'totalShareOfShelfByTop5', 'totalShareOfShelfByTop10'],
          weekIdField,
          {
            CustomComponent: OmniTrendChart,

            view: {
              container: {
                style: {
                  marginBottom: 100,
                  marginTop: 30,
                  verticalAlign: 'top'
                }
              }
            }
          }
        )
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          filteredShareOfShelvesTopEntityGroupBy,
          ['totalShareOfShelf'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );

      const shareOfShelfSummary = {
        CustomComponent: CommonSummaryInfo,
        name: 'shareOfShelfSummary',
        view: {
          container: {
            style: {
              marginTop: 41
            }
          },
          name: 'shareOfShelfSummary',
          buildSubTitle: () => {
            return 'All Categories';
          },
          shouldShowFilter: false,
          showComparePeriod: false
        },
        data: {
          tabs: [
            {
              name: 'shareOfShelfSummary',
              displayName: 'Brand Total Share Of Shelf'
            }
          ]
        }
      };

      if (entityType !== 'retailer') {
        if (entityType !== 'brand') {
          widgets.push(shareOfShelfSummary);
        }
        widgets.push(buildShareOfShelfTableWidget('totalShareOfShelfShards', 'total'));
      } else {
        widgets.push(
          ...buildOmniProductsWidget(
            omniGridField.totalShareOfShelf,
            omniTableViewField.totalShareOfShelf,
            true,
            entityType
          )
        );
      }
      break;
    }

    case 'organic': {
      widgets.push(
        buildTrendWidgetConfig(
          app,
          indexName,
          { type: entityType },
          'weekId',
          [
            'organicShareOfShelf',
            'organicShareOfShelfByTop3',
            'organicShareOfShelfByTop5',
            'organicShareOfShelfByTop10'
          ],
          weekIdField,
          {
            CustomComponent: OmniTrendChart,
            view: {
              container: {
                style: {
                  marginBottom: 100,
                  marginTop: 30,
                  verticalAlign: 'top'
                }
              }
            }
          }
        )
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          filteredShareOfShelvesTopEntityGroupBy,
          ['organicShareOfShelf'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );
      const shareOfShelfSummary = {
        CustomComponent: CommonSummaryInfo,
        name: 'shareOfShelfSummary',
        view: {
          container: {
            style: {
              marginTop: 41
            }
          },
          name: 'shareOfShelfSummary',
          buildSubTitle: () => {
            return 'All Categories';
          },
          shouldShowFilter: false,
          showComparePeriod: false
        },
        data: {
          tabs: [
            {
              name: 'shareOfShelfSummary',
              displayName: 'Brand Organic Share Of Shelf '
            }
          ]
        }
      };

      if (entityType !== 'retailer') {
        if (entityType !== 'brand') {
          widgets.push(shareOfShelfSummary);
        }
        widgets.push(buildShareOfShelfTableWidget('organicShareOfShelfShards', 'organic'));
      } else {
        widgets.push(
          ...buildOmniProductsWidget(
            omniGridField.organicShareOfShelf,
            omniTableViewField.organicShareOfShelf,
            true,
            entityType
          )
        );
      }
      break;
    }
    case 'paid': {
      widgets.push(
        buildTrendWidgetConfig(
          app,
          indexName,
          { type: entityType },
          'weekId',
          ['paidShareOfShelf', 'paidShareOfShelfByTop3', 'paidShareOfShelfByTop5', 'paidShareOfShelfByTop10'],
          weekIdField,
          {
            CustomComponent: OmniTrendChart,
            view: {
              container: {
                style: {
                  marginBottom: 100,
                  marginTop: 30,
                  verticalAlign: 'top'
                }
              }
            }
          }
        )
      );

      widgets.push(
        buildOmniTopEntitiesWidgetConfig(
          app,
          indexName,
          { type: entityType },
          filteredShareOfShelvesTopEntityGroupBy,
          ['paidShareOfShelf'],
          weekIdField,
          {
            view: {
              horizontalScrolling: {
                enabled: true,
                step: 3
              }
            }
          }
        )
      );
      const shareOfShelfSummary = {
        CustomComponent: CommonSummaryInfo,
        name: 'shareOfShelfSummary',
        view: {
          container: {
            style: {
              marginTop: 41
            }
          },
          name: 'shareOfShelfSummary',
          buildSubTitle: () => {
            return 'All Categories';
          },
          shouldShowFilter: false,
          showComparePeriod: false
        },
        data: {
          tabs: [
            {
              name: 'shareOfShelfSummary',
              displayName: 'Brand Paid Share Of Shelf'
            }
          ]
        }
      };

      if (entityType !== 'retailer') {
        if (entityType !== 'brand') {
          widgets.push(shareOfShelfSummary);
        }
        widgets.push(buildShareOfShelfTableWidget('paidShareOfShelfShards', 'paid'));
      } else {
        widgets.push(
          ...buildOmniProductsWidget(
            omniGridField.paidShareOfShelf,
            omniTableViewField.paidShareOfShelf,
            true,
            entityType
          )
        );
      }

      break;
    }
    default:
      break;
  }

  const subMenuItems = [];
  subMenuItems.push({
    icon: ExportIcon,
    text: 'Add to Download List',
    eventName: 'downloadOmniData'
  });

  if (entityType === 'retailer') {
    widgets.push({
      CustomComponent: PageContextMenu,
      name: 'pageContextMenu',
      view: {
        name: 'pageContextMenu',
        buttons: subMenuItems
      },
      data: {}
    });
  }

  return {
    widgets,
    CustomPageContainer: GenericPageContainer as any,
    enableComparison: false
  };
};
