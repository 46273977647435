import types from './types';

interface OmniSearchServiceState {
  [name: string]: {
    main: any[];
    compare: any[];
    isFetching: boolean;
  };
}

const initialState: OmniSearchServiceState = {};

const omniSearchService = (
  state: OmniSearchServiceState = initialState,
  action: {
    type: string;
    entity: { name: string; data: any[] };
  }
) => {
  switch (action.type) {
    case types.START_FETCH:
      return {
        ...state,
        [action.entity.name]: {
          ...state[action.entity.name],
          isFetching: true
        }
      };
    case types.RECEIVE_SEARCH_DATA_FOR_MAIN:
      return {
        ...state,
        [action.entity.name]: {
          ...state[action.entity.name],
          main: action.entity.data
        }
      };
    case types.RECEIVE_SEARCH_DATA_FOR_COMPARE:
      return {
        ...state,
        [action.entity.name]: {
          ...state[action.entity.name],
          compare: action.entity.data
        }
      };
    case types.END_FETCH:
      return {
        ...state,
        [action.entity.name]: {
          ...state[action.entity.name],
          isFetching: false
        }
      };
    default:
      return state;
  }
};

export default omniSearchService;
