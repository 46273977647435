import axios from 'axios';

/**
 * Adds an approved domain to a team
 */
export default function AddAuthorizedClientDomain(currentUserEmail: string, domain: string): Promise<void> {
  return axios.post(
    'api/user/AddAuthorizedClientDomain',
    {},
    {
      params: {
        activeUserEmail: currentUserEmail,
        domain
      }
    }
  );
}
