/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popover from '@mui/material/Popover';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import colors from 'src/utils/colors';
import { GridLoading } from 'src/components/common/Loading/PlaceHolderLoading/PlaceHolderLoading';
import Switch from '@mui/material/Switch';
import { ChevronIcon } from 'src/components/SvgIcons';
import ReduxStore from 'src/types/store/reduxStore';

const mapStateToProps = (state: ReduxStore) => {
  return {
    ..._pick(state, ['adPlatforms', 'adPlatformSettingsByClient'])
  };
};

const UserAlert: React.FC<ReturnType<typeof mapStateToProps>> = ({ adPlatforms, adPlatformSettingsByClient }) => {
  const [entityList, setEntityList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [platformDropdown, setPlatformDropdown] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const fetchList = async () => {
    setIsloading(true);
    const addAdEntities = adPlatformSettingsByClient.filter((item) => item.settingType === 'entityId');
    const adEntitiesClone = addAdEntities.map((entity: any) => {
      return {
        beaconClientId: entity.beaconClientIds,
        emailAlert: 'disabled',
        entityId: entity.id,
        entityName: entity.extendedAttributes.name,
        platformType: entity.platformType
      };
    });
    const ALERT_LIST_API = '/apiAdManager/adPlatformSettingsByClient/notifications';
    const result = await axios.get(ALERT_LIST_API);
    const list = _get(result, ['data', 'alertSettings'], []);
    const platforms: any[] = [];
    adEntitiesClone.forEach((item) => {
      const newEntity = list.find((x) => x.entityId === item.entityId);
      if (newEntity) {
        item.emailAlert = newEntity.emailAlert;
      }
      const platform = adPlatforms.find((x) => x.platformType === item.platformType);
      item.platformDisplayName = platform.name;
      if (!platforms.find((x) => x.platformType === item.platformType)) {
        platforms.push({ ...platform, selected: true });
      }
    });

    setPlatformDropdown(platforms);
    setEntityList(adEntitiesClone);
    setIsloading(false);
  };

  useEffect(() => {
    if (!_isEmpty(adPlatforms) && !_isEmpty(adPlatformSettingsByClient)) {
      fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adPlatforms, adPlatformSettingsByClient]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = async (entity) => {
    const newStatus = entity.emailAlert === 'enabled' ? 'disabled' : 'enabled';
    const EDITAPI = '/apiAdManager/adPlatformSettingsByClient/notifications';
    const result = await axios.post(EDITAPI, { ...entity, emailAlert: newStatus });
    if (result.data) {
      const entityListCopy = _cloneDeep(entityList);
      entityListCopy.forEach((item) => {
        if (item.entityId === result.data.entityId) {
          item.emailAlert = result.data.emailAlert;
        }
      });

      setEntityList(entityListCopy);
    }
  };

  const toggleSelected = (platformType) => {
    const platformCopy = _cloneDeep(platformDropdown);
    platformCopy.forEach((item) => {
      if (item.platformType === platformType) {
        item.selected = !item.selected;
      }
    });
    setPlatformDropdown(platformCopy);
  };

  const notAllSelect = platformDropdown.find((item) => !item.selected);
  const selectAll = () => {
    const platformCopy = _cloneDeep(platformDropdown);
    const newStatus = !!notAllSelect;
    platformCopy.forEach((item) => {
      item.selected = newStatus;
    });
    setPlatformDropdown(platformCopy);
  };

  const filteredEntityList = entityList.filter((item) => {
    const platform = platformDropdown.find((p) => p.platformType === item.platformType);
    return platform.selected;
  });
  return (
    <form className="profile" onSubmit={() => {}}>
      <h4 className="sl-header-text">Notifications</h4>
      <hr className="sl-divider sl-divider--no-margin-top sl-divider--lg-margin-bottom" />
      <h4 className="sl-form-label">Manage your email notifications.</h4>
      <br /> <br />
      <div
        className="alert_row"
        style={{ color: colors.labelGrey, paddingBottom: 10, borderBottom: `1px solid ${colors.lightestGrey}` }}
      >
        <div>Entity Name</div>
        <div>
          <div className="platform_col">
            <div
              role="button"
              onClick={handleClick}
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              Platform <ChevronIcon style={{ position: 'relative', width: 28, height: 28, stroke: colors.labelGrey }} />
            </div>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
            >
              <div className="platform_drowdown">
                <MenuItem onClick={() => selectAll()}>
                  <Checkbox color="primary" checked={!notAllSelect} style={{ marginLeft: -9 }} />
                  Select All
                </MenuItem>
                {platformDropdown.map((item, idx) => {
                  return (
                    <MenuItem key={idx} onClick={() => toggleSelected(item.platformType)}>
                      <Checkbox color="primary" checked={item.selected} style={{ marginLeft: -9 }} />
                      {item.name}
                    </MenuItem>
                  );
                })}
              </div>
            </Popover>
          </div>
          <div className="email_col">Emails</div>
        </div>
      </div>
      {isLoading ? (
        <GridLoading />
      ) : (
        <>
          {filteredEntityList.map((entity, idx) => {
            return (
              <div key={idx} className="alert_row">
                <div>{entity.entityName}</div>
                <div>
                  <div className="platform_col">{entity.platformDisplayName}</div>
                  <div className="email_col">
                    <Switch
                      checked={entity.emailAlert === 'enabled'}
                      onClick={() => handleToggle(entity)}
                      color="primary"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </form>
  );
};
export default connect(mapStateToProps)(UserAlert);
