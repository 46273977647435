import { COMMON_ENTITIES } from 'src/utils/entityDefinitions/entities/commonEntities';

export const BEACON_ENTITIES = {
  ...COMMON_ENTITIES,
  issueId: {
    id: -1,
    keyFieldName: 'issueId',
    nameFieldName: 'issueId',
    type: 'issueId',
    displayName: 'Issue ID',
    displayNamePlural: 'Issue IDs'
  },
  issueType: {
    id: -1,
    keyFieldName: 'issueType',
    nameFieldName: 'issueType',
    type: 'issueType',
    displayName: 'Issue Type',
    displayNamePlural: 'Issue Types'
  },
  issueTypeNormalized: {
    id: -1,
    keyFieldName: 'issueTypeNormalized',
    nameFieldName: 'issueTypeNormalized',
    type: 'issueTypeNormalized',
    displayName: 'Issue Type Normalized',
    displayNamePlural: 'Issue Types Normalized'
  },
  subTypeOfTheNonCompliance: {
    id: -1,
    keyFieldName: 'subTypeOfTheNonCompliance',
    nameFieldName: 'subTypeOfTheNonCompliance',
    type: 'subTypeOfTheNonCompliance',
    displayName: 'Sub Type Of The Non-Compliance',
    displayNamePlural: 'Sub Types Of The Non-Compliance'
  },
  status: {
    id: -1,
    keyFieldName: 'status',
    nameFieldName: 'status',
    type: 'status',
    displayName: 'Status',
    displayNamePlural: 'Statuses'
  },
  vendorCode: {
    id: -1,
    keyFieldName: 'vendorCode',
    nameFieldName: 'vendorCode',
    type: 'vendorCode',
    displayName: 'Vendor Code',
    displayNamePlural: 'Vendor Codes'
  },
  purchaseOrder: {
    id: -1,
    keyFieldName: 'purchaseOrder',
    nameFieldName: 'purchaseOrder',
    type: 'purchaseOrder',
    displayName: 'Purchase Order',
    displayNamePlural: 'Purchase Orders'
  }
};
