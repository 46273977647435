import React, { useMemo, useState } from 'react';
import { useAppSelector, useMetricFormatter } from 'src/utils/Hooks';
import {
  AdConversionPathwaysDropdownOption,
  AdConversionPathwaysFieldMapping
} from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import { AD_PATHWAYS_EVENT } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/ConversionPathways/constants';
import PathwaysMap from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/ConversionPathways/PathwaysMap';
import GenericBarChart from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/GenericBarChart';
import { AdConversionPathwaysDocument } from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/ConversionPathways/types';
import {
  calculateMetricValues,
  convertPathwaysDataToCsv
} from 'src/components/Layout/Advertising/ConnectPageLayout/visualizations/PathwaysTab/ConversionPathways/utils';
import { PathwaysLegendIcon } from 'src/components/SvgIcons/SvgIcons';
import colors from 'src/utils/colors';

interface ConversionPathwaysBarChartProps {
  metric: any;
  data: AdConversionPathwaysDocument[];
}

const LegendItem = ({ title, color }: { title: string; color: string }) => {
  return (
    <div style={{ width: 'auto', height: 'auto', display: 'flex', gap: '7px', alignItems: 'center' }}>
      {title === 'Conversion Touchpoint' ? (
        <PathwaysLegendIcon style={{ width: '10px' }} />
      ) : (
        <div
          style={{
            border: `1px solid ${color}`,
            borderRadius: '100px',
            width: '10px',
            height: '10px',
            backgroundColor: color
          }}
        ></div>
      )}
      <span style={{ fontSize: '18px' }}>{title}</span>
    </div>
  );
};

const CustomLegend = () => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        width: '580px',
        height: 'auto',
        top: '-548px',
        left: '655px',
        flexWrap: 'wrap',
        gap: '17px'
      }}
    >
      <LegendItem title="Sponsored Products" color="#9ee0d0" />
      <LegendItem title="Sponsored Brands" color="#71c184" />
      <LegendItem title="Conversion Touchpoint" color="red" />
      <LegendItem title="Sponsored Display" color="#a5cfff" />
      <LegendItem title="Display Ads" color="#16949b" />
    </div>
  );
};

/**
 *
 * @returns Bar chart displaying metric-by-pathway
 */
const ConversionPathwaysBarChart = ({ data, metric }: ConversionPathwaysBarChartProps) => {
  const mainTimePeriod = useAppSelector((state) => state.mainTimePeriod);
  const formatMetric = useMetricFormatter();
  const [dataInView, setDataInView] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]); // On load, display the first 10 elements in our pathways component

  const sortedDocuments: AdConversionPathwaysDocument[] = useMemo(() => {
    let metricFieldName = '';
    // For ROAS, we sort by Ad Sales instead of the metric value itself
    if (metric === AdConversionPathwaysDropdownOption.ReturnOnAdSpend) {
      metricFieldName = AdConversionPathwaysFieldMapping['Ad Sales'].name;
    } else {
      metricFieldName = AdConversionPathwaysFieldMapping[metric].name;
    }
    // Sort on the chosen metric field
    const sortedSeriesData = data.sort((a, b) => b[metricFieldName] - a[metricFieldName]);
    return sortedSeriesData;
  }, [data, metric]);

  /**
   * GenericChart uses a sliding window of view by changing the min/max values to create the perceptions of pages.
   * In the chartProps we set below, we listen for a change in the x-axis extremes and collect the category indices within our min/max
   * We then filter our sorted documents down to just the visible columns for use in the Pathways Map
   */
  const dataForPathways: AdConversionPathwaysDocument[] = useMemo(() => {
    if (dataInView) {
      return sortedDocuments.filter((_, index) => dataInView.includes(index));
    }
    return null;
  }, [sortedDocuments, dataInView]);

  const buttons = useMemo(() => {
    return [
      [
        {
          displayName: 'Impressions',
          eventId: AdConversionPathwaysDropdownOption.Impressions,
          eventName: AD_PATHWAYS_EVENT,
          isSelected: metric === AdConversionPathwaysDropdownOption.Impressions
        },
        {
          displayName: 'Ad Sales',
          eventId: AdConversionPathwaysDropdownOption.AdSales,
          eventName: AD_PATHWAYS_EVENT,
          isSelected: metric === AdConversionPathwaysDropdownOption.AdSales
        },
        {
          displayName: 'Conversions',
          eventId: AdConversionPathwaysDropdownOption.Conversions,
          eventName: AD_PATHWAYS_EVENT,
          isSelected: metric === AdConversionPathwaysDropdownOption.Conversions
        },
        {
          displayName: 'ROAS',
          eventId: AdConversionPathwaysDropdownOption.ReturnOnAdSpend,
          eventName: AD_PATHWAYS_EVENT,
          isSelected: metric === AdConversionPathwaysDropdownOption.ReturnOnAdSpend
        },
        {
          displayName: 'NTB Ad Sales',
          eventId: AdConversionPathwaysDropdownOption.NewToBrandAdSales,
          eventName: AD_PATHWAYS_EVENT,
          isSelected: metric === AdConversionPathwaysDropdownOption.NewToBrandAdSales
        },
        {
          displayName: 'NTB Conversions',
          eventId: AdConversionPathwaysDropdownOption.NewToBrandConversion,
          eventName: AD_PATHWAYS_EVENT,
          isSelected: metric === AdConversionPathwaysDropdownOption.NewToBrandConversion
        }
      ],
      [
        {
          isSelected: true,
          displayName: 'Conversion Pathway'
        }
      ]
    ];
  }, [metric]);

  const chartProps: Highcharts.Options = useMemo(
    () => ({
      horizontalScrolling: {
        enabled: true,
        step: 3
      },
      chart: {
        spacingLeft: 8,
        marginLeft: 100,
        height: 560
      },
      title: {
        y: 18
      },
      exporting: {
        enabled: true,
        buttons
      },
      legend: {
        enabled: false
      },
      xAxis: {
        labels: { enabled: false },
        max: 9,
        min: 0,
        events: {
          afterSetExtremes(e) {
            // Any time the extremes are set (during page changes) we extract the data in view
            const dataWithinView = [];
            this.series.forEach((series) => {
              series.data.forEach((point) => {
                if (point.x >= e.min && point.x <= e.max) {
                  dataWithinView.push(point.category); // We can use the category as an index to map our sorted data to what is in view
                }
              });
            });
            setDataInView(dataWithinView);
          }
        }
      },
      yAxis: {
        labels: {
          enabled: false
        }
      },
      plotOptions: {
        line: {
          marker: {
            lineColor: 'transparent'
          }
        },
        column: {
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            style: { color: colors.darkBlue, fontFamily: 'Roboto', fontSize: '14px' },
            formatter() {
              return formatMetric(this.y, AdConversionPathwaysFieldMapping[metric].metricType, {
                showFullValue: false
              });
            }
          }
        },
        barWidth: 65
      }
    }),
    [formatMetric, metric, buttons]
  );

  const columnSeries: Highcharts.SeriesColumnOptions = useMemo(() => {
    const sortedSeriesData = sortedDocuments.map((documentObject) => calculateMetricValues(documentObject, metric));
    return {
      type: 'column',
      name: 'conversion-pathways-chart',
      data: sortedSeriesData
    };
  }, [sortedDocuments, metric]);

  return (
    <>
      <div style={{ marginBottom: '-40px' }}>
        <GenericBarChart
          columnData={columnSeries}
          chartProps={chartProps}
          convertSeriesToDelimitedData={() =>
            convertPathwaysDataToCsv({
              seriesData: columnSeries,
              sortedDocuments,
              dateRange: mainTimePeriod.displayName,
              metric
            })
          }
        />
        <CustomLegend />
      </div>

      <PathwaysMap data={dataForPathways} />
    </>
  );
};

export default ConversionPathwaysBarChart;
