import {
  AdvertisingAnalyticsAdvancedSearchResponse,
  AdvertisingAnalyticsPartialData,
  AdSpendAdvancedSearchResponse,
  AdSpendData,
  AdvertisingAnalyticsData
} from './types';
import { AdvertisingAnalyticsDropdownOption } from 'src/utils/entityDefinitions/fields/adManagerFieldDefinitions';
import _sum from 'lodash/sum';
import _cloneDeep from 'lodash/cloneDeep';
import { padEmptyAdTypes, padEmptyData } from '../visualizationUtils';

const fieldIdToDisplayName = {
  dsp: 'DSP',
  sponsored_products: 'Sponsored Products',
  sponsored_brands: 'Sponsored Brands',
  sponsored_display: 'Sponsored Display'
} as const;

/**
 * Parses advanced search response from ad analytics request
 */
export const advertisingAnalyticsAdvancedSearchResponseParser = (
  response: AdvertisingAnalyticsAdvancedSearchResponse
): AdvertisingAnalyticsPartialData => {
  const aggregations = response.aggregations['by_adProductType.keyword'].map(({ fieldId, additionalValues }) => ({
    ...additionalValues,
    fieldId
  }));

  // Clone the response before sorting because `sort` modifies the array in place
  const impressions = _cloneDeep(aggregations).sort((a, b) => b.impressions_sum_value - a.impressions_sum_value);
  const clicks = _cloneDeep(aggregations).sort((a, b) => b.clicks_sum_value - a.clicks_sum_value);
  const clickThruRates = clicks.map(({ clicks_sum_value, impressions_sum_value }) =>
    impressions_sum_value > 0 ? clicks_sum_value / impressions_sum_value : 0
  );

  const totalImpressions = _sum(aggregations.map(({ impressions_sum_value }) => impressions_sum_value));
  const totalClicks = _sum(aggregations.map(({ clicks_sum_value }) => clicks_sum_value));
  const totalClickThruRate = totalImpressions > 0 ? totalClicks / totalImpressions : 0;

  // If there is no data for a certain ad type, it won't be returned so pad any missing
  // ad type data with a 0
  return {
    [AdvertisingAnalyticsDropdownOption.Awareness]: {
      columnMetrics: padEmptyData(impressions.map(({ impressions_sum_value }) => impressions_sum_value)),
      pointMetrics: padEmptyData(clickThruRates),
      totalClickThruRate,
      adTypes: padEmptyAdTypes(impressions.map(({ fieldId }) => fieldId)).map(
        (fieldId) => fieldIdToDisplayName[fieldId]
      )
    },
    [AdvertisingAnalyticsDropdownOption.Engagement]: {
      columnMetrics: padEmptyData(clicks.map(({ clicks_sum_value }) => clicks_sum_value)),
      pointMetrics: padEmptyData(clickThruRates),
      totalClickThruRate,
      adTypes: padEmptyAdTypes(clicks.map(({ fieldId }) => fieldId)).map((fieldId) => fieldIdToDisplayName[fieldId])
    }
  };
};

/**
 * Parses advanced search response from ad spend query
 */
export const adSpendAdvancedSearchParser = (response: AdSpendAdvancedSearchResponse): AdSpendData => {
  const aggregations = response.aggregations['by_adProductType.keyword']
    .map(({ additionalValues, fieldId }) => ({
      ...additionalValues,
      fieldId
    }))
    .sort((a, b) => b.spend_sum_value - a.spend_sum_value);

  return {
    [AdvertisingAnalyticsDropdownOption.Spend]: {
      adTypes: padEmptyAdTypes(aggregations.map(({ fieldId }) => fieldId)).map(
        (fieldId) => fieldIdToDisplayName[fieldId]
      ),
      columnMetrics: padEmptyData(aggregations.map(({ spend_sum_value }) => spend_sum_value))
    }
  };
};

interface AdvertisingAnalyticsDataToCsvArgs {
  data: AdvertisingAnalyticsData;
  dateRange: string;
  metric: AdvertisingAnalyticsDropdownOption;
}
/**
 * Convert ad analytics data to CSV
 */
export const advertisingAnalyticsDataToCsv = ({
  data,
  dateRange,
  metric
}: AdvertisingAnalyticsDataToCsvArgs): string => {
  const { adTypes, columnMetrics } = data[metric];

  const barMetric =
    metric === AdvertisingAnalyticsDropdownOption.Awareness
      ? 'Impressions'
      : metric === AdvertisingAnalyticsDropdownOption.Engagement
      ? 'Clicks'
      : 'Ad Spend';

  const headers = ['Ad Type', `${barMetric}: ${dateRange}`];
  if (metric !== AdvertisingAnalyticsDropdownOption.Spend) {
    headers.push(`Click-Thru Rate: ${dateRange}`);
  }
  const headersRow = headers.map((val) => `"${val}"`).join(',');

  const values = adTypes.map((adType, index) => {
    const row = [adType, columnMetrics[index]];
    if (metric !== AdvertisingAnalyticsDropdownOption.Spend) {
      row.push(data[metric].pointMetrics[index]);
    }

    return row.map((val) => `"${val}"`);
  });

  return `${headersRow}\n${values.join('\n')}`;
};
