import { AdManagerAdCampaignEntity } from 'sl-api-connector/types';

import types from './types';

const receiveAdGroups = (adGroups: AdManagerAdCampaignEntity[]) => ({
  type: types.RECEIVE_ADGROUPS,
  adGroups,
  receivedAt: Date.now()
});

const clearAdGroups = () => ({ type: types.CLEAR_ADGROUPS });

export default {
  clearAdGroups,
  receiveAdGroups
};
