import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import queryString from 'qs';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import './Filter.scss';
import i18n from '../../../i18n_en';
import { anyNotEq } from '../../../utils/equality';
import fontStyle from 'src/utils/fontStyle';

class SegmentFilter extends Component {
  static propTypes = {
    entity: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    segments: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    value: PropTypes.string
  };

  static defaultProps = {
    value: null
  };

  state = {
    segmentsForEntity: [],
    searchText: '',
    selectedSegment: {},
    staticSearchSegments: []
  };

  componentDidMount() {
    this.setSegments(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.filters.isClearingFilters && this.props.filters.segment !== null) ||
      anyNotEq(['entity', 'location'], this.props, nextProps)
    ) {
      this.setSegments(nextProps);
    }
  }

  setSegments = (props) => {
    const { entity, filters, location, segments, showNoneSelectedItem } = props;
    const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 });
    const parsedFilterParams = queryParams.filter && queryString.parse(JSON.parse(queryParams.filter));

    if (entity) {
      let segmentsForEntity = [];
      let selectedSegment = {};
      if (segments) {
        if (segments.mySegments) {
          segments.mySegments.forEach((val) => {
            segmentsForEntity.push(val.segment);
          });
        }

        if (segments.teamSegments) {
          segments.teamSegments.forEach((val) => segmentsForEntity.push(val.segment));
        }

        if (entity.type === 'brand') {
          segmentsForEntity = segmentsForEntity.filter(
            (val) =>
              (!val.b ||
                (val.b && val.b.filter((brand) => parseInt(brand.i, 10) === parseInt(entity.id, 10)).length > 0)) &&
              (!val.c ||
                (val.c &&
                  val.c.filter((category) => entity.categoryIds.indexOf(parseInt(category.i, 10)) !== -1).length > 0))
          );
        }

        if (showNoneSelectedItem) {
          const segment = { id: null, dn: 'No Filtering' };
          segmentsForEntity.unshift(segment);
          selectedSegment = segment;
        }

        // For persistence of segments on re load
        segmentsForEntity.forEach((segment) => {
          if (
            filters.segment !== null &&
            parsedFilterParams &&
            parsedFilterParams.sg &&
            parsedFilterParams.sg.filter((item) => item.i === segment.id).length > 0
          ) {
            selectedSegment = segment;
          }
        });
      }

      // Just limit to the first 500 segments? If we have a lot, we get speed problems
      // from react trying to keep track of all of the children (Crocs is an example)
      const MAX_SEGMENTS = 500;
      segmentsForEntity = segmentsForEntity.splice(0, MAX_SEGMENTS);

      this.setState({
        segmentsForEntity,
        selectedSegment,
        searchText: ''
      });

      if (_isEmpty(this.state.staticSearchSegments.length)) {
        this.setState({ staticSearchSegments: _cloneDeep(segmentsForEntity) });
      }
    }
  };

  handleInputChange = (event) => {
    const { value } = event.target;
    const { staticSearchSegments } = this.state;
    const updatedSegments = staticSearchSegments.filter((segment) =>
      segment.dn.toLowerCase().includes(value.toLowerCase()) ? segment : null
    );

    this.setState({
      searchText: value,
      segmentsForEntity: updatedSegments
    });
  };

  handleRadioChange = (_event, value) => {
    const { onFilterChange } = this.props;

    if (!value) {
      this.setState({ selectedSegment: { id: null } });
      onFilterChange(null, 'segment');
      return;
    }

    const { segmentsForEntity } = this.state;
    const selectedSegment = segmentsForEntity.filter((val) => val.id === value)[0];
    this.setState({ selectedSegment });
    onFilterChange(selectedSegment, 'segment');
  };

  render() {
    const { segmentsForEntity, staticSearchSegments, searchText, selectedSegment } = this.state;
    const { segments_noneFound } = i18n;

    if (staticSearchSegments && staticSearchSegments.length === 0) {
      return null;
    }

    return (
      <div className="search-form-container search-form-container--sm">
        <TextField
          variant="standard"
          className="sl-form-input"
          placeholder="Search for segments"
          type="text"
          name="keyword"
          id="keyword"
          style={{ width: '100%' }}
          value={searchText}
          onChange={this.handleInputChange}
        />
        {segmentsForEntity && segmentsForEntity.length === 0 && (
          <div style={{ height: '175px' }}>{segments_noneFound}</div>
        )}
        {!_isEmpty(segmentsForEntity) ? (
          <>
            <RadioGroup
              className="segment-filter__radio-buttons"
              aria-label="segment-filter"
              row
              name="segment"
              value={this.props.value || selectedSegment.id}
              onChange={this.handleRadioChange}
            >
              {(segmentsForEntity || []).map((segment, index) => (
                <FormControlLabel
                  value={segment.id}
                  label={
                    <span
                      style={{
                        fontWeight: fontStyle.regularWeight,
                        fontSize: '13px',
                        lineHeight: '18px',
                        alignSelf: 'center',
                        width: '100%',
                        color: '#052849'
                      }}
                    >
                      {segment.dn}
                    </span>
                  }
                  key={`segment_${segment.id}_${index}`}
                  style={{
                    alignSelf: 'center',
                    width: '100%'
                  }}
                  control={<Radio disableRipple />}
                />
              ))}
            </RadioGroup>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => _pick(state, ['retailer', 'filters', 'segments', 'entityService']);

export default withRouter(connect(mapStateToProps)(SegmentFilter));
