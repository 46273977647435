import React, { createContext, useEffect, useState, useMemo, useContext, useRef } from 'react';
import {
  ExportStatus,
  checkDownloadComplete,
  fetchExportDownloadList,
  fetchProgressItems,
  findProgressItems
} from 'src/components/BeaconRedesignComponents/common/AppExportModal/exportDataUtils';
import _cloneDeep from 'lodash/cloneDeep';

export const ExportContext = createContext(null);

export function useExportContext() {
  const context = useContext(ExportContext);
  return context;
}

interface ChangedItem {
  requestId: string;
  status: ExportStatus; // Assuming ExportStatus is an enum as previously mentioned
  fileName: string;
  error: string | null;
}

const TIME_OUT_PERIOD = 5000;
export const EXPORT_DOWNLOAD_PAGE_SIZE = 50;

/**
 * This Context holds export related functions and state
 */
const ExportContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isTriggered, setIsTriggered] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [shouldStartPolling, setShouldStartPolling] = useState(false);
  const [shouldFetchDownloadList, setShouldFetchDownloadList] = useState(false);
  const [downloadList, setDownloadList] = useState(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const prevPendingDownloadRef = useRef<DownloadItem[]>([]);
  const pendingDownloadRef = useRef<DownloadItem[]>([]);
  const [statusChangedItems, setStatusChangedItems] = useState<ChangedItem[]>([]);

  const memoizedExportValues = useMemo(() => {
    return {
      isTriggered,
      setIsTriggered,
      isFailed,
      setIsFailed,
      setShouldStartPolling,
      shouldStartPolling,
      downloadList,
      setDownloadList,
      setShouldFetchDownloadList,
      shouldFetchDownloadList,
      isDownloadLoading,
      setIsDownloadLoading,
      currentPage,
      setCurrentPage,
      statusChangedItems
    };
  }, [
    currentPage,
    downloadList,
    isDownloadLoading,
    isFailed,
    isTriggered,
    shouldFetchDownloadList,
    shouldStartPolling,
    statusChangedItems
  ]);

  // useEffect that is related to notification outside of settings (snackbar stays in /src/components/BeaconRedesignComponents/Header/Header.tsx)
  useEffect(() => {
    let pollingTimer;

    const fetchDataAndCheckCompletion = async () => {
      try {
        const { result: initialExportList } = await fetchExportDownloadList();

        // fetch data and see if there is a progress items.
        const progressItems = findProgressItems(initialExportList);

        prevPendingDownloadRef.current = pendingDownloadRef.current;
        pendingDownloadRef.current = progressItems;

        // if status changes we will have that in changedItem list -- currently we are handling only "in progress" => "failed with error" or "finished successfully"
        // but this will record every status change
        // the changed item will be removed once another iteration of fetchDataAndCheckCompletion
        const changedItems = prevPendingDownloadRef.current
          .map((prevDownload) => {
            const initialItem = initialExportList.find((item) => item.requestId === prevDownload.requestId);

            if (initialItem && initialItem.status !== prevDownload.status) {
              return {
                requestId: prevDownload.requestId,
                prevStatus: prevDownload.status,
                currentStatus: initialItem.status,
                error: initialItem.error
              };
            }
            return null;
          })
          .filter((item) => item !== null);
        setStatusChangedItems(changedItems);

        // check if everything is [completed, fail, error] -- None of the list is in queue or in progress
        if (checkDownloadComplete(initialExportList)) {
          setIsTriggered(false);
          setShouldStartPolling(false);
        } else {
          // if there is an item in queue or in progress keep polling this
          pollingTimer = setTimeout(fetchDataAndCheckCompletion, TIME_OUT_PERIOD);
        }
      } catch (error) {
        console.error('Error fetching export download list:', error);
      }
    };

    if (isTriggered) {
      if (shouldStartPolling) {
        fetchDataAndCheckCompletion();
      }
    }

    return () => {
      clearTimeout(pollingTimer);
    };
  }, [isTriggered, shouldStartPolling]);

  // This useEffect is triggered when a user navigate to download page
  useEffect(() => {
    let fetchDownloadPageTimer;
    const fetchDownloadList = async () => {
      try {
        setIsDownloadLoading(true);

        const { result: fetchedDownloadList } = await fetchExportDownloadList();

        if (!downloadList) {
          setDownloadList(fetchedDownloadList);
        } else {
          setDownloadList((prev) => [...prev, ...fetchedDownloadList]);
        }

        setIsDownloadLoading(false);
        setShouldFetchDownloadList(false);
      } catch (error) {
        console.error('Fetch export downloads is failed', error);
      } finally {
        setIsDownloadLoading(false);
      }
    };

    if (shouldFetchDownloadList) {
      fetchDownloadList();
    }
    return () => {
      clearTimeout(fetchDownloadPageTimer);
    };
  }, [shouldFetchDownloadList]);

  // This UseEffect will handle polling for pending items

  useEffect(() => {
    let fetchDownloadPageTimer;
    const fetchProgressItemsAndReplace = async () => {
      const progressItems = findProgressItems(downloadList);
      try {
        const progressItemsIds = progressItems.map((item) => item.requestId);

        if (progressItemsIds.length > 0) {
          const newlyFetchedProgressItems = await fetchProgressItems(progressItemsIds);

          const copiedDownloadList = _cloneDeep(downloadList);
          newlyFetchedProgressItems.forEach((newItem) => {
            const index = copiedDownloadList.findIndex((item) => item.requestId === newItem.requestId);
            if (index >= 0) {
              // If the item exists, replace it with the new data
              copiedDownloadList[index] = newItem;
            }
          });

          setDownloadList(copiedDownloadList);

          if (checkDownloadComplete(newlyFetchedProgressItems)) {
            return;
          } else {
            fetchDownloadPageTimer = setTimeout(fetchProgressItemsAndReplace, TIME_OUT_PERIOD);
          }
        }
      } catch (error) {
        console.error('Error on FetchProgressItemsAndReplace', error);
      }
    };

    if (downloadList) {
      const shouldPollForDownload = findProgressItems(downloadList).length > 0;
      if (shouldPollForDownload) {
        fetchProgressItemsAndReplace();
      }
    }

    return () => {
      clearTimeout(fetchDownloadPageTimer);
    };
  }, [currentPage, shouldFetchDownloadList]);

  return <ExportContext.Provider value={memoizedExportValues}>{children}</ExportContext.Provider>;
};

export default ExportContextProvider;
