/* eslint-disable */
import BrandSvg from '!svg-react-loader!./SponsoredBrands.svg';
import DisplaySvg from '!svg-react-loader!./SponsoredDisplay.svg';
import ProductSvg from '!svg-react-loader!./SponsoredProducts.svg';
import WalmartProductSvg from '!svg-react-loader!./WalmartSponsoredProducts.svg';
import WalmartSBA from '!svg-react-loader!./WalmartSponsoredBrands.svg';
import WalmartVideoSvg from '!svg-react-loader!./WalmartVideo.svg';

import SamsClubProductsSvg from '!svg-react-loader!./SamsClubProductsSvg.svg';
import SamsClubBrandsSvg from '!svg-react-loader!./SamsClubBrandsSvg.svg';

export {
  BrandSvg,
  DisplaySvg,
  ProductSvg,
  WalmartProductSvg,
  WalmartSBA,
  WalmartVideoSvg,
  SamsClubProductsSvg,
  SamsClubBrandsSvg
};
