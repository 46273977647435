//   This files handles the parsing of the subcategories being followed

export default function parseSubCategories(subCategories) {
  const parsedSubCategories = subCategories
    .map((subCategory) => ({
      ...subCategory,
      type: 'subcategory',
      displayName: subCategory.name,
      conditions: {
        termFilters: [
          {
            fieldName: 'subCategoryId',
            condition: 'should',
            values: [subCategory.subCategoryId]
          }
        ]
      },
      value: 0
    }))
    // filter out the 'Unclassified' category
    // .filter((item) => item.id !== 9999)
    .sort((a, b) => a.name.localeCompare(b.name));

  return parsedSubCategories;
}

export function buildSubCategoriesAsChildren(subcategories) {
  const subcategoriesByCategoryId = {};
  subcategories.forEach((subcategory) => {
    if (!subcategoriesByCategoryId[subcategory.parentCategoryId]) {
      subcategoriesByCategoryId[subcategory.parentCategoryId] = [];
    }
    subcategoriesByCategoryId[subcategory.parentCategoryId].push({
      ...subcategory,
      id: subcategory.subCategoryId,
      value: subcategory,
      type: 'subcategory',
      displayName: subcategory.name,
      conditions: {
        termFilters: [
          {
            fieldName: 'subCategoryId',
            condition: 'should',
            values: [subcategory.subCategoryId]
          }
        ]
      }
    });
    return null;
  });
  return subcategoriesByCategoryId;
}
