import types from './types';

// ------------------------------------
// Actions Creators
// ------------------------------------

function updateAllWeekIdsByRetailerId(weekIds) {
  return {
    type: types.UPDATE_WEEK_IDS,
    weekIds
  };
}

function clearAllWeekIds() {
  return {
    type: types.CLEAR_WEEK_IDS
  };
}

export default {
  updateAllWeekIdsByRetailerId,
  clearAllWeekIds
};
