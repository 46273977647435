import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';
import _tail from 'lodash/tail';
import _transform from 'lodash/transform';

/**
 * For each key in the provided `keys` array, the value of that key for each collection in `collections` will be
 * checked for equality.  If the value for each key is equal across all of the collections, `true` is returned.
 * Otherwise, `false` is returned.
 */
export const allEq = (keys: string[], ...collections: { [key: string]: any }[]) =>
  keys.every((key) =>
    _tail(collections).every((collection) => _isEqual(_get(collection, key), _get(collections[0], key)))
  );

/**
 * For the provided key, the value of that key for each collection in `collections` will be checked for equality.
 * If its value is not equal across all of the collections, `true` is returned.  Otherwise, `false` is returned.
 */
export const keyNotEq = (key: string, ...collections: { [key: string]: any }[]) =>
  !!_tail(collections).find((collection) => !_isEqual(_get(collection, key), _get(collections[0], key)));

/**
 * For each key in the provided `keys` array, the value of that key for each collection in `collections` will be
 * checked for equality.  If the value for any key is not equal across all of the collections, `true` is returned.
 * Otherwise, `false` is returned.
 */
export const anyNotEq = (keys: string[], ...collections: { [key: string]: any }[]) => {
  // console.log(keys.find((key) => keyNotEq(key, ...collections)));
  return !!keys.find((key) => keyNotEq(key, ...collections));
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (objectToCompare: object, baseToCompareTo: object) => {
  function changes(object: object, base: object) {
    // @ts-ignore
    return _transform(object, (result, value, key) => {
      if (!_isEqual(value, base[key])) {
        result[key] = _isObject(value) && _isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(objectToCompare, baseToCompareTo);
};
