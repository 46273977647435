import { DATATYPE, METRICTYPE, FieldDefinition } from 'src/utils/entityDefinitions/entityDefinitionTypes';

export const OMNI_SHARE_OF_SHELF_FIELDS: {
  [key: string]: FieldDefinition;
} = {
  totalShareOfShelf: {
    name: 'totalShareOfShelf',
    displayName: 'Total Share of Shelf',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    shareOfShelfType: 'total'
  },
  totalShareOfShelfByTop3: {
    name: 'totalShareOfShelfByTop3',
    displayName: 'Total Top 3 Placements',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    shareOfShelfType: 'total',
    topSearchResults: 'top3'
  },
  totalShareOfShelfByTop5: {
    name: 'totalShareOfShelfByTop5',
    displayName: 'Total Top 5 Placements',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    shareOfShelfType: 'total',
    topSearchResults: 'top5'
  },
  totalShareOfShelfByTop10: {
    name: 'shareOfShelfByTop10',
    displayName: 'Total Top 10 Placements',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    shareOfShelfType: 'total',
    topSearchResults: 'top10'
  },
  organicShareOfShelf: {
    name: 'organicShareOfShelf',
    displayName: 'Organic Share of Shelf',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    shareOfShelfType: 'organic'
  },
  organicShareOfShelfByTop3: {
    name: 'organicShareOfShelfByTop3',
    displayName: 'Organic Top 3 Placements',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    shareOfShelfType: 'organic',
    topSearchResults: 'top3'
  },
  organicShareOfShelfByTop5: {
    name: 'organicShareOfShelfByTop5',
    displayName: 'Organic Top 5 Placements',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    shareOfShelfType: 'organic',
    topSearchResults: 'top5'
  },
  organicShareOfShelfByTop10: {
    name: 'organicShareOfShelfByTop10',
    displayName: 'Organic Top 10 Placements',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    shareOfShelfType: 'organic',
    topSearchResults: 'top10'
  },

  paidShareOfShelf: {
    name: 'paidShareOfShelf',
    displayName: 'Paid Share of Shelf',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    shareOfShelfType: 'paid'
  },
  paidShareOfShelfByTop3: {
    name: 'paidShareOfShelfByTop3',
    displayName: 'Paid Top 3 Placements',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    shareOfShelfType: 'paid',
    topSearchResults: 'top10'
  },
  paidShareOfShelfByTop5: {
    name: 'paidShareOfShelfByTop5',
    displayName: 'Paid Top 5 Placements',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    shareOfShelfType: 'paid',
    topSearchResults: 'top5'
  },
  paidShareOfShelfByTop10: {
    name: 'paidShareOfShelfByTop10',
    displayName: 'Paid Top 10 Placements',
    aggregationFunction: 'avg',
    dataType: DATATYPE.DECIMAL,
    metricType: METRICTYPE.PERCENT,
    shareOfShelfType: 'paid',
    topSearchResults: 'top10'
  }
};
