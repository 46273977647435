import moment from 'moment-timezone';
import ReduxStore from 'src/types/store/reduxStore';
import { UTC_TIMEZONE } from 'src/utils/constants';
import _get from 'lodash/get';

export const getTimelineOpt = (retailer?: ReduxStore['retailer']) => {
  const retailerTimezone = _get(retailer, 'processInTimezone', UTC_TIMEZONE);
  return [
    {
      id: 0,
      label: 'Current Month',
      name: 'currentMonthBudgetSetting',
      displayName: moment().tz(retailerTimezone).format('MMMM YYYY'),
      disc: 'Update budget for the current month.'
    },
    {
      id: 1,
      label: 'Next Month',
      name: 'nextMonthBudgetSetting',
      displayName: moment().tz(retailerTimezone).add(1, 'M').format('MMMM YYYY'),
      disc: 'Update budget for the next month.'
    }
  ];
};
