import React, { useMemo, useState } from 'react';
import { useAppSelector, useHistory, useLocation } from 'src/utils/Hooks';
import { AppFilterModal } from 'src/components/BeaconRedesignComponents/common/AppFilterButton/AppFilterModal';
import { BEACON_SUBTABS } from 'src/components/BeaconRedesignComponents/GenericSidebarNav/useBeaconRoutes';
import { SlButton } from 'src/components/BeaconRedesignComponents/Header/SLDropdownMenu/SlButton';

export const AppFilterButton = () => {
  const history = useHistory();
  const location = useLocation();
  const filterParams = new URLSearchParams(location.search);
  const { subtab } = useAppSelector((state) => state.app.queryParams);

  // if we are in the plan page, the default filter for adjustmentStatus is published and draft, and we don't want to have dots.
  const isForecastAdjustmentTab =
    filterParams.has('adjustmentStatus') && subtab === BEACON_SUBTABS.FORECAST_ADJUSTMENTS;

  const [filterModalOpen, setFilterModalOpen] = useState(false);

  /**
   * Checks url for current filter
   */
  const filterActive: boolean = useMemo(() => {
    return filterParams.has('filter') || isForecastAdjustmentTab;
  }, [filterParams, isForecastAdjustmentTab]);

  const handleClose = () => {
    setFilterModalOpen(false);
  };

  return (
    <>
      <SlButton
        active={filterActive}
        variant="normal"
        onClick={() => setFilterModalOpen(true)}
        buttonSx={{
          paddingX: '9px'
        }}
      >
        Filter
      </SlButton>
      <AppFilterModal history={history} location={location} open={filterModalOpen} handleClose={handleClose} />
    </>
  );
};
