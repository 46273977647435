import React, { useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './suggestionSearch.scss';

interface SuggestionSearchProps {
  searchText: string;
  autoFocus: boolean;
  className: string;
  style: React.CSSProperties;
  textFieldStyle: React.CSSProperties;
  hintText: string;
  headerDisplayName: string;
  handleKeyDown: (value: string) => void;
  onListItemSelection: (selection: { id: string; value: string }) => void;
  handleUpdateInput: (searchWord: string) => void;
  suggestions: { type: string; value: string; id: string }[];
}

const SuggestionSearch: React.FC<SuggestionSearchProps> = ({
  className,
  hintText,
  headerDisplayName,
  onListItemSelection,
  handleUpdateInput,
  suggestions,
  searchText
}: SuggestionSearchProps) => {
  const suggestionRef = useRef<HTMLInputElement>(null);
  const handleListItemSelection = (selection: { id: string; value: string }) => {
    if (suggestionRef && suggestionRef.current) {
      suggestionRef.current.focus();
    }
    onListItemSelection(selection);
  };

  return (
    <Autocomplete
      onChange={(_, value) => {
        handleListItemSelection(value);
      }}
      clearOnBlur={false}
      options={suggestions}
      filterOptions={(options) => options}
      clearIcon={<></>}
      popupIcon={<></>}
      clearOnEscape
      getOptionLabel={(option) => option.value}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          InputProps={{
            ...params.InputProps,
            className
          }}
          type="text"
          placeholder={hintText}
          inputRef={suggestionRef}
          id={headerDisplayName}
          value={searchText}
          onChange={(event) => {
            handleUpdateInput(event.target.value);
          }}
        />
      )}
    />
  );
};
export default SuggestionSearch;
