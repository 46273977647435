import React from 'react';
import { DocumentIcon } from 'src/components/SvgIcons';
import MenuButton from 'src/components/common/Buttons/MenuButton';
import colors from 'src/utils/colors';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import saveAs from 'file-saver';

interface GridExportMenuParameters {
  exportCsvData: () => any;
  styles?: any;
}
/**
 *
 * @param exportCsvData A function that returns a stringified version of the data passed in
 * @param styles Additional styling to add to the button container
 * @returns A simple dropdown button for use anywhere
 */
const GridExportMenu = ({ styles = {}, exportCsvData }: GridExportMenuParameters) => {
  const exportGridData = async () => {
    const data = await exportCsvData();

    const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'chart_data.csv');
  };

  const exportMenu = {
    mainButton: {
      icon: <MoreHorizIcon style={{ color: colors.darkBlue }} />,
      direction: 'down'
    },
    subItems: [{ icon: <DocumentIcon />, text: 'Export CSV', onClick: () => exportGridData() }]
  };

  return (
    <div className="custom-line-chart" style={{ ...styles }}>
      <MenuButton menuInfo={exportMenu} />
    </div>
  );
};

export default GridExportMenu;
