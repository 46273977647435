import _flatten from 'lodash/flatten';
import { SavedSearch, ValueOf } from 'sl-api-connector/types';

import actions from './actions';
import types from './types';

const initialState = {
  mySegments: [],
  teamSegments: [],
  mySearchTermLists: [],
  teamSearchTermLists: [],
  businessUnits: [],
  savedSearchesById: null
};

const buildIdToSavedSearchMapping = (savedSearches: SavedSearch[][]): Map<string, SavedSearch> =>
  _flatten(savedSearches).reduce((acc, savedSearch) => {
    acc.set(savedSearch.id, savedSearch);
    return acc;
  }, new Map());

type Action = ReturnType<Extract<ValueOf<typeof actions>, (...args: any[]) => { type: string }>>;

const segmentsReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case types.RECEIVE_ALL_SAVED_SEARCH: {
      const { mySegments, teamSegments, mySearchTermLists, teamSearchTermLists, businessUnits } = action;

      const allSavedSearches = {
        mySegments,
        teamSegments,
        mySearchTermLists,
        teamSearchTermLists,
        businessUnits
      };

      const savedSearchesById = buildIdToSavedSearchMapping(Object.values(allSavedSearches));

      return {
        ...allSavedSearches,
        savedSearchesById
      };
    }
    case types.CLEAR_ALL_SAVED_SEARCH:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default segmentsReducer;
